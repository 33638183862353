import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { IndividualPerformanceEvaluationUserComponent } from './individual-performance-evaluation-user/individual-performance-evaluation-user.component';
import { IndividualPerformanceEvaluationComponent } from './individual-performance-evaluation/individual-performance-evaluation.component';
import { KpimasterComponent } from './kpimaster/kpimaster.component';
import { KPITargetReportComponent } from './KPITargetReport/KPITargetReport.component';
import { KpitreedefinationComponent } from './kpitreedefination/kpitreedefination.component';
import { LossanalysisComponent } from './lossanalysis/lossanalysis.component';
import { ManualactualentryComponent } from './manualactualentry/manualactualentry.component';
import { RacimatrixComponent } from './racimatrix/racimatrix.component';
import { YearlyscorecardComponent } from './yearlyscorecard/yearlyscorecard.component';
import { KPIIntegartionComponent } from './KPIIntegartion/KPIIntegartion.component';
import { KpiDataPointsComponent } from './KpiMasterComponents/Kpi-Data-Points/Kpi-Data-Points.component';
import { KpiTreeVersionComponent } from './KpiTreeVersion/KpiTreeVersion.component';
import { KpiWiseAnalysisReportComponent } from './KpiWiseAnalysisReport/KpiWiseAnalysisReport.component';
import { KPISplitViewComponent } from './KPISplitView/KPISplitView.component';
import { KPIGridViewComponent } from './KPIGridView/KPIGridView.component';
import { KpiRefreshComponent } from './kpi-refresh/kpi-refresh.component';
import { KpihierarchymasterComponent } from './kpihierarchymaster/kpihierarchymaster.component';

const routes: Routes = [
  {
    path: 'KPIActualEntry',
    component: ManualactualentryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KPITree',
    component: KpitreedefinationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KPIGapAnalysis',
    component: LossanalysisComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'RaciMatrix',
    component: RacimatrixComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'YearlyScorecard',
    component: YearlyscorecardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KPITargetReport',
    component: KPITargetReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'IndividualPerformanceEvaluation',
    component: IndividualPerformanceEvaluationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KPIMasterData',
    component: KpimasterComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KpiHistoricalReport',
    component: KpiWiseAnalysisReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'IndividualPerformanceEvaluationUsers',
    component: IndividualPerformanceEvaluationUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KPIIntegration',
    component: KPIIntegartionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KpiDataPoints',
    component: KpiDataPointsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KPITreeVersion',
    component: KpiTreeVersionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KPISplitView',
    component: KPISplitViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KPIGridView',
    component: KPIGridViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KPIDataRefresh',
    component: KpiRefreshComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'KPIHierarchyMaster',
    component: KpihierarchymasterComponent,
    canActivate: [AuthGuard],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KPIRoutingModule {}
