import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MachinemappingComponent } from '../screens/usermanagement/machinemapping/machinemapping.component';
import { PeopleMasterDataComponent } from '../screens/usermanagement/people-master-data/people-master/people-master-data.component';
import { UsermanagerComponent } from '../screens/usermanagement/usermanager/usermanager.component';
import { UserroleComponent } from '../screens/usermanagement/userrole/userrole.component';
import { UserrolemappingComponent } from '../screens/usermanagement/userrolemapping/userrolemapping.component';
import { AccessSettingsComponent } from './AccessSettings/AccessSettings.component';
// import { PlantModelComponent } from './home/plant-model/plant-model.component';

const routes: Routes = [
      { path: '', component: AccessSettingsComponent },
      { path: 'UserManager', component: UsermanagerComponent },
      { path: 'RoleManager', component: UserroleComponent },
      { path: 'RoleMap', component: UserrolemappingComponent },
      { path: 'MachineMap', component: MachinemappingComponent },
      { path: 'PeopleMasterDataMap', component: PeopleMasterDataComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AccessRoutingModule {}
