import { Component, OnInit, ViewChild } from '@angular/core';
import {FormGroup,FormBuilder,FormControl,Validators,} from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThemePalette } from '@angular/material/core';
import { PeopleMasterDataService } from 'src/app/services/people-master-data-service';
import { EmployeePositionMappingComponent } from '../employee-position-mapping/employee-position-mapping.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-position-master',
  templateUrl: './position-master.component.html',
  styleUrls: ['./position-master.component.scss']
})
export class PositionMasterComponent implements OnInit {

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  displayMAchineModal = false;
  pillars!: Pillar[];
  pillarId!: number;
  plants!: Plantbygroup[];
  positionForm!: FormGroup;
  formMode!: boolean;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  groupId!: number;
  plantId!: number;
  displayUserModal = false;
  pillarModelVisible = false;
  isUpdatePosition: boolean = false;
  plantsDDL!: any[];

  owners = new FormControl();
  ownerList!: any[]
  positionMasterColumns: string[] = ['PositionName', 'PositionDesc','Actions'];
  positionData = new MatTableDataSource<any>([]);
  getareaMaster: any;
  generalArea: any;
  generalDepartment: any;
  filteredUsers: any;
  filtered: any[] = [];
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private peopleService: PeopleMasterDataService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formMode = true;
    this.positionForm = this.fb.group({
      POS_Id : [''],
      PositionName: new FormControl('',[Validators.required]),
      PositionDesc: [''],
      group : [''],
      plant :['']
    });
  }
  getPaginator(): void {
    setTimeout(() => {
      this.positionData.paginator = this.paginator;
      this.positionData.sort = this.sort;
    }, 1000);
  }
 
  public checkError = (controlName: string, errorName: string) => {
    return this.positionForm.controls[controlName].hasError(errorName);
  }
 

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.positionData.filter = filterValue.trim().toLowerCase();

    if (this.positionData.paginator) {
      this.positionData.paginator.firstPage();
    }
  }
 
  opensideModal(): void {
    debugger
    if (!this.isUpdatePosition) {
      this.formMode = true;
      this.displayUserModal = true;
      this.reset();
      this.isUpdatePosition = false;
    }
    else {
      this.formMode = false;
      this.displayUserModal = true;
    }
  }

  closePositionModel(): void {
    this.displayUserModal = false;
    this.reset();
  }


  
  getPositionMasterData(key: any): void {
    debugger
    this.spinner.show();
    this.groupId = key.EGId;
    this.plantId = key.PlantId;
    this.positionForm.get('group')?.setValue(key.groupId);
    this.positionForm.get('plant')?.setValue(key.plantId);
    this.peopleService.getPositionMasterData(key).subscribe((data) => {
    this.positionData = data.positionMasterList;
    this.positionData = new MatTableDataSource<any>(data.positionMasterList);
      this.getPaginator();
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });

  }

  
  savePosition(): void {
    if (!this.isUpdatePosition && this.positionForm.valid) {
      const data = {
        TransactionType : 1,
        PositionName : this.positionForm.get('PositionName')?.value,
        PositionDesc : this.positionForm.get('PositionDesc')?.value,
        PlantId : this.plantId,
        EGId : this.groupId,
      };
      this.peopleService.addPosition(data).subscribe((response:any) => {
        this.showPopupMsg(response.ErrorMessage);
        this.reset();
        this.closePositionModel();
        const key = {
          PlantId: data.PlantId,
          EGId: data.EGId
        };
       this.getPositionMasterData(key);
      }, (error) => {
        this.toaster.error('Error', error.error.message);
      });
    } else if(this.positionForm.valid){
      const data = {
        TransactionType : 2,
        POS_Id : this.positionForm.get('POS_Id')?.value,
        PositionName : this.positionForm.get('PositionName')?.value,
        PositionDesc : this.positionForm.get('PositionDesc')?.value,
        PlantId : this.plantId,
        EGId : this.groupId,
      };
      this.peopleService.addPosition(data).subscribe((response:any) => {
        this.showPopupMsg(response.ErrorMessage);
        this.closePositionModel();
        this.isUpdatePosition = false;
        this.reset();
        const key = {
          PlantId: data.PlantId,
          EGId: data.EGId
        };
       this.getPositionMasterData(key);
       const empPosEmpMapped = new EmployeePositionMappingComponent(this.fb,this.toaster,this.spinner,this.peopleService,this.router);
       empPosEmpMapped.reloadComponent();
      }, (error) => {
        this.toaster.error('Error', error.error.message);
      })
    }

  }
 

  onClickUpdatePosition(element: any): void {
    this.isUpdatePosition = true;
    console.log(element);
    this.positionForm.get('POS_Id')?.setValue(element.POS_Id),
    this.positionForm.get('PositionName')?.setValue(element.PositionName),
    this.positionForm.get('PositionDesc')?.setValue(element.PositionDesc),
    this.positionForm.get('plant')?.setValue(this.plantId),
    this.positionForm.get('group')?.setValue(this.groupId),
    this.opensideModal();
  }

  onClickDeletePosition(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Master Position!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        var id = {
          TransactionType : 3,
          POS_Id: element.POS_Id,
          DOB : new Date(),
          DOJ : new Date(),
        }
        this.peopleService.addPosition(id).subscribe(
          (data:any) => {
            const key = {
              PlantId: this.plantId,
              EGId: this.groupId
            };
           this.getPositionMasterData(key);
            this.showPopupMsg(data.ErrorMessage);
          },
          (error:any) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Master Position is safe :)', 'error');
      }
    });
  }

  reset(): void {
    this.positionForm.reset();
    this.isUpdatePosition = false;
  }
  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
      this.toaster.success('Success', msg.substring(3, msg.length));
    }
    else {
      this.toaster.error('Error', msg.substring(3, msg.length));
    }
  }

}
