import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterShiftService {
  kpibaseUrl: string = environment.O3CoreApiUrl;
  baseUrl2: string = environment.O3CoreApiUrl;
  baseUrl: string = environment.O3CoreApiUrl;
constructor(private http: HttpClient) { }
getGroupData(data: any): Observable<any[]> {
  const token = localStorage.getItem('token');
  const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  return this.http.post<any[]>(this.baseUrl2 + 'Common/GetGroup', data, { headers: header });
}
getShift(data: any): Observable<any> {
  return this.http.post<any>(this.kpibaseUrl + 'generalshift', data);
}
getOneGeneralShift(data: any): Observable<any> {
  return this.http.post<any>(this.kpibaseUrl + 'onegeneralshift', data);
}
addGeneralShift(data: any): Observable<any[]> {
  const token = localStorage.getItem('token');
  const header = new HttpHeaders().set('Authorization',  `Bearer ${token}`);
  return this.http.post<any[]>(this.baseUrl + 'addgeneralhift', data , {headers: header});
}
getGeneralShifts(data: any): Observable<any> {
  const token = localStorage.getItem('token');
  const header = new HttpHeaders().set('Authorization',  `Bearer ${token}`);
  return this.http.post<any>(this.baseUrl + 'generalshift', data , {headers: header});
}
deleteShift(data: any): Observable<any> {
  const token = localStorage.getItem('token');
  const header = new HttpHeaders().set('Authorization',  `Bearer ${token}`);
  return this.http.post<any>(this.baseUrl + 'deletegeneralshift', data , {headers: header});
}
updateShift(data: any): Observable <any[]> {
  const token = localStorage.getItem('token');
  const header = new HttpHeaders().set('Authorization',  `Bearer ${token}`);
  return this.http.put<any[]>(this.baseUrl + 'generalshift', data , {headers: header});
}
}
