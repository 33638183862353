<h1 mat-dialog-title>Item Details</h1>
<div mat-dialog-content class="prevDialogForm">
  <mat-form-field>
    <mat-label>Item Name</mat-label>
    <input matInput required [(ngModel)]="data.ItemName">
    <mat-error>Item Name is Required </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Item Code</mat-label>
    <input matInput required [(ngModel)]="data.ItemCode">
    <mat-error>Item Code is Required </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Item Description</mat-label>
    <input matInput [(ngModel)]="data.ItemDesc">
  </mat-form-field>
</div>
<div mat-dialog-actions class="mat-dialog-actions">
  <button class="dialog-cancel-btn" (click)="onCancelClick()">Cancel</button>
  <button class="dialog-save-btn" (click)="onSaveClick()" cdkFocusInitial>Save</button>
</div>
