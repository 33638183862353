import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ProcessParameterLoginService {

  baseUrl: string = environment.O3CoreApiUrl;
  apiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getPlants(data: any): Observable<any[]> {

    return this.http.post<any[]>(this.baseUrl + 'parametergroups', data);


  }
  parametergroupsforSampling(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SamplingInterval/GetParameterGroupsForProcessSampling', data);
  }
  getDataApplyFilter(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SamplingInterval/GetParameterLogging', data);
  }

  DeleteSelected(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SamplingInterval/Deleteparameterlogging', data);
  }

  AddParametersLogging(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SamplingInterval/Addparameterlogging', data);
  }
  UpdateParameterGroup(data: any): Observable<any[]> {

    return this.http.put<any[]>(this.baseUrl + 'parametergroups', data);

  }
  ChangeParameter(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SamplingInterval/Updateparameterlogging', data);
  }
  GetGroupType(): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + 'Variable/GetGroupType');
  }
  GetVariableGroups(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SamplingInterval/GetVariableGroups', data);
  }

}

