import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debug } from 'console';
import { ToastrService } from 'ngx-toastr';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import Swal from 'sweetalert2';
import { MasterareaService } from "../../services/masterarea.service";
import { MasterDepartmentService } from 'src/app/services/master-department.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
@Component({
  selector: 'app-general-area',
  templateUrl: './general-area.component.html',
  styleUrls: ['./general-area.component.scss']
})
export class GeneralAreaComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  areaForm!: FormGroup;
  groupId!: number;
  plantId!: number;
  displayAreaModal = false;
  departmentList: any;
  plantList!: any[];
  areaColumns: string[] = ['Actions','plant', 'area', ];
  areaId: any;
  isUpdateArea: boolean = false;
  areaData!: MatTableDataSource<any>;
  formMode!: boolean;
  egId: any;
  IsAccess:any;
  IsWrite:any;
  constructor(private machineService: MachineMappingService ,
    private platModelService: EnterPriseGroupService,
    private fb: FormBuilder,private toaster: ToastrService,private areaService: MasterareaService, private departmentService: MasterDepartmentService) { }

  ngOnInit() {
    this.GetMenuAccess();
    this.areaForm = this.fb.group({
      areaname: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      group: [''],
      plant: [''],
      department: [''],

    });

    //this.getGroupsData();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
      // localStorage.setItem('GetMenuAccess', this.permisson);
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.areaData.filter = filterValue.trim().toLowerCase();

    if (this.areaData.paginator) {
      this.areaData.paginator.firstPage();
    }
  }
  
  getPaginator(): void {
    setTimeout(() => {
      this.areaData.paginator = this.paginator;
      this.areaData.sort = this.sort;
    }, 1000);
  }
  opensideModal(): void {
    
    this.formMode = true;
    this.areaForm.get('group')?.setValue(this.groupId);
    
     this.getGroupsData();
     
    this.areaForm.get('department')?.setValue(this.departmentList[0].departmentId);
    
    this.displayAreaModal = true;

  }
  opensideModal1(): void {
     this.getGroupsData();
    this.formMode = false;

    this.displayAreaModal = true;

  }

  reset(): void {
    this.areaForm.reset();
    this.isUpdateArea = false;
  }
  closeAreaModel(): void {
    this.displayAreaModal = false;
  }
  closeaddNewAreaModel(): void {
    this.displayAreaModal = false;
    this.reset();
  }
 
  getGroupsData(): void {
    const key = {
      GroupId: this.groupId,
    };
   
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.plantList = data.DepartList;
      this.areaForm.get('plant')?.setValue(this.plantId);
      this.getdepartment();
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        //  this.spinner.hide();
      }
    );
  }

 getdepartment(): void {
   const key = {
    Id: this.areaForm.value.plant
   };
   //Need to map department from plant modal
   //this.departmentService.getMasterDepartment(key).subscribe((response: any) => {
   this.machineService.getDepartmentData(key).subscribe((response: any) => {
     this.departmentList = response.masterList;
     // this.areaForm.get('department')?.setValue(this.departmentList[0].departmentId);
   }, (error: any) => {
     this.toaster.error('Error', error.message);
   });
 }
  saveArea(): void {
    const area = {
      egId: this.areaForm.value.group,
      plantId : this.areaForm.value.plant,
      name: this.areaForm.value.areaname,
      userId: JSON.parse(localStorage.user).UserId,
      departmentId: this.areaForm.value.department
    };

    this.areaService.addArea(area).subscribe(
      (response) => {
        this.toaster.success('Success', 'Area Added Successfully');
        this.closeaddNewAreaModel();
        this.getareaList(this.egId);

      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  Deletearea(element: any): void {
    // if (this.isDelete) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete area!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        var id = {
          areaId: element.areaId
        }
        this.areaService.deleteArea(id).subscribe((response) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your selected area has been deleted.',
              icon: 'success',
              timer: 800,
              showConfirmButton: false,
            });
            this.getareaList(this.egId);
          },
          (error) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected area is safe :)', 'error');
      }
    });

  }


  // Deletearea(element: any): void {
  //   console.log(element);
  //   const id = {
  //     areaId: element.areaId
  //   };
  //   this.areaService.deleteArea(id).subscribe((response) => {
  //     this.toaster.success('Success', 'Area Deleted Successfully');
  //     this.getareaList(this.egId);
  //   }, (error) => {
  //     this.toaster.error('Error', error.error.message);

  //   });
  // }


  updateArea(): void {
    debugger
    const key = {
      areaId: this.areaId,
      egId: this.areaForm.value.group,
      plantId : this.areaForm.value.plant,
      name: this.areaForm.value.areaname,
      userId: JSON.parse(localStorage.user).UserId,
      departmentId: this.areaForm.value.department
    };

    this.areaService.updateArea(key).subscribe(
      (response) => {
        this.toaster.success('Success', 'Area Updated Successfully');
        this.closeaddNewAreaModel();
        this.getareaList(this.egId);
        this.isUpdateArea = false;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);

      }
    );
  }


 
  editAreaModel(element: any): void {
    this.opensideModal1();
    const key = {
      areaId: element.areaId
    };

    this.areaService.GetOneMasterArea(key).subscribe((Response) => {
      this.areaForm.get('group')?.setValue(this.groupId);
      this.areaForm.get('plant')?.setValue(this.plantId);
      this.areaForm.get('areaname')?.setValue(element.areaName);
      this.areaForm.get('department')?.setValue(Response[0].departmentId);
      this.areaId = element.areaId;
    }, (error) => {
      this.toaster.error('Error', error.error.message);
    })
    //  this.departmentrowId= element.departmentId


  }
  getareaList(key:any): void {
    
    this.groupId = key.egId;
    this.egId = key;
    this.plantId = key.plantId;
    this.areaService.GetMasterArea(key).subscribe((data) => {
      this.areaData = new MatTableDataSource<any>(data);
      this.getPaginator();

    },
      (error) => {
        this.toaster.error(error.error.message);

      });

  }
}

