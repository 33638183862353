import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AtRoutingModule } from './at-routing.module';
import { At6w2hComponent } from './at6w2h/at6w2h.component';
import { FishboneComponent } from './fishbone/fishbone.component';
import { CauseEffectComponent } from './cause-effect/cause-effect.component';
import { FiveWhyComponent } from './five-why/five-why.component';
import { SwotComponent } from './swot/swot.component';
import { AtHeaderComponent } from './at-header/at-header.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  key => antDesignIcons[key]
);

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material-module';
import { FivewhyActionplanComponent } from './fivewhy-actionplan/fivewhy-actionplan.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StageDashboardComponent } from './stage-dashboard/stage-dashboard/stage-dashboard.component';
import { SwotpestDashboardComponent } from './swotpest-dashboard/swotpest-dashboard/swotpest-dashboard.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as drilldown from 'highcharts/modules/drilldown.src';
import { AgGridModule } from 'ag-grid-angular';
import { BtnRenderer } from './btn-renderer.component';
import { BtnRendererActualEntry } from '../btn-rendererActualEntry.component copy';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { HistoricalTableComponent } from './historical-table/historical-table.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { AtRcaDashboardComponent } from './at-rca-dashboard/at-rca-dashboard.component';
import { AtStages2Component } from './at-stages2/at-stages2.component';
import { NcrMangementComponent } from '../quality-management/ncr-ccr-management/ncr-management/ncr-mangement.component';
import { AtStagesNcrComponent } from './at-stages-ncr/at-stages-ncr.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    At6w2hComponent,
    FishboneComponent,
    CauseEffectComponent,
    FiveWhyComponent,
    SwotComponent,
    AtHeaderComponent,
    FivewhyActionplanComponent,
    StageDashboardComponent,
    SwotpestDashboardComponent,
    BtnRenderer,
    BtnRendererActualEntry,
    ImageDialogComponent,
    HistoricalTableComponent,
    AtRcaDashboardComponent,
    AtStages2Component,
    NcrMangementComponent,
    AtStagesNcrComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true,
    }),
    AtRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatRadioModule,
    MatCardModule,
    MatDatepickerModule,
    NgxSpinnerModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatTooltipModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    ChartModule,
    AgGridModule.withComponents([BtnRenderer, BtnRendererActualEntry]),
    NzDatePickerModule,
    MatExpansionModule,
    MatButtonModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, drilldown] },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons }, // add as factory to your providers
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AtModule { }
