
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-select-renderer',
  template: `
  <style>
  .row-action-btns {
    min-width: 70px;
    }
  .row-action-btns .mat-icon-button:not(:last-child) {
    margin-right: 10px;
    }
    .row-action-btns .mat-icon-button.edit-btn {
        background: #3aafe4;
    }
    .row-action-btns .mat-icon-button {
        width: 34px;
        height: 34px;
        line-height: 34px;
        border-radius: 10px;
        visibility: visible;
        transition: all .4s;
        top:5px
    }
  </style>

   
    <div class="row-action-btns">
    <button mat-icon-button class="edit-btn" aria-label="Edit Row"
    (click)="onClick($event)">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"
        fill="white" />
    </svg>
  </button>
  </div>
    `
})

export class ButtonSelectRendererComponent implements ICellRendererAngularComp {

  params:any;
  label!: string;

  agInit(params:any): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event:any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(this.params);

    }
  }
}