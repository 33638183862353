import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditexecutionService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }


  addAuditExecution(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'AuditExecution/AddAuditExecution', data);
  }
  checkArea(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'AuditExecution/CheckArea', data);
  }
  getCheckList(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + 'qualitychecklists');
  }
  get5sAuditExecution(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'AuditExecution/GetExecution', data);
  }
  getPreviousAuditExecution(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'AuditExecution/PreviousAuditExecution', data);
  }
}
