import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { LicenseManager } from 'ag-grid-enterprise';
import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { NcrService } from 'src/app/services/ncr.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { truncate } from 'lodash';

@Component({
  selector: 'app-ncr-approval',
  templateUrl: './ncr-approval.component.html',
  styleUrls: ['./ncr-approval.component.scss'],
})
export class NcrApprovalComponent implements OnInit {
  columnDefs: any;
  rowData: any;
  dataArray!: [];
  gridApi!: any;
  ncrForm!: FormGroup;
  cellData!: any;
  gridColumnApi: any;
  breadcrumList: any;
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
    },
  };
  IsAccess: any;
  IsWrite: any;
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private ncrService: NcrService,
    private kpiMaster: KpimasterService,
    private usermanagementService: UserManagementService,
    private router: Router,
    private spinner: NgxSpinnerService,

    private formBuilder: FormBuilder
  ) {
    // LicenseManager.setLicenseKey('kazaz');
    this.columnDefs = [
      { field: 'puDesc', headerName: 'Machine', sortable: true, resizable: true, },
      { field: 'operationName', headerName: 'NCR', sortable: true, resizable: true, },

      { field: 'prodDesc', headername: 'Process Name', sortable: true, resizable: true, },
      { field: 'quantityWaste', headerName: 'Waste Qty', sortable: true, resizable: true, },
      {
        field: 'Status', headerName: 'Status', filter: true, resizable: true, wrapText: true,
        editable: truncate,
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellStyle: {
          color: 'black',
          'background-color': '#EDF1E5',
        },
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Approved', 'Rejected', 'Pending', 'In Progress', 'Closed'],
        },
      },

      { field: 'quantityHold', headerName: 'Quality Hold Qty', sortable: true, resizable: true, },
      { field: 'NoOfMisses', sortable: true, filter: true, resizable: true, wrapText: true },
      {
        field: 'TargetDate', sortable: true,
        resizable: true,
        wrapText: true,
        editable: true,
        filter: 'agDateColumnFilter',
        cellEditor: 'primeCellEditor',
        filterParams: {
          comparator: myDateComparator,
        },
        cellStyle: {
          color: 'black',
          'background-color': '#EDF1E5',
        },
      },
      {
        field: 'NewTargetDate', sortable: true,
        resizable: true,
        wrapText: true,
        editable: true,
        filter: 'agDateColumnFilter',
        cellEditor: 'primeCellEditor',
        filterParams: {
          comparator: myDateComparator,
        },
        cellStyle: {
          color: 'black',
          'background-color': '#EDF1E5',
        },
      },
      {
        field: 'imagePath',
        headerName: 'Picture View', sortable: true, resizable: true,
        cellRenderer: (params: any): any => {
          return params.value !== null
            ? '<img style="height:40px;width:40px" src="' +
            params.value +
            '" />'
            : '<img style="height:40px;width:40px" src="assets/images/img_avatar.png" />';
        },
      },
      { field: 'capaNum', headerName: 'Qpl Use Detail', sortable: true, filter: true, resizable: true },
      { field: 'failureMode', headerName: 'Failure Mode', sortable: true, filter: true, resizable: true },
      {
        field: 'CompletionDate', sortable: true,
        resizable: true,
        wrapText: true,
        editable: true,
        filter: 'agDateColumnFilter',
        cellEditor: 'primeCellEditor',
        filterParams: {
          comparator: myDateComparator,
        },
        cellStyle: {
          color: 'black',
          'background-color': '#EDF1E5',
        },
      },
    ];


  }

  ngOnInit() {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.ncrForm = this.fb.group({
      endDate: ['', Validators.required],
      startDate: ['', Validators.required],
    });

  }




  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  getAllNCR(): void {

    const key = {
      startDate: getSqlDate(this.ncrForm.value.startDate),
      endDate: getSqlDate(this.ncrForm.value.endDate)
    }
    this.spinner.show();

    this.ncrService.getNcrApproval(key).subscribe(
      (dataArray: any) => {

        this.dataArray = dataArray.Obj1;
        this.spinner.hide();

      },
      (error: any) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();

      }
    );


  }

  onCellValueChanged(params: any): void {
    const col = params.colDef.field;

    if (col === 'Status') {
      this.cellData = {
        status: params.data.Status,
        ncrId: params.data.ncrId,
        reqType: 0,
      };
      this.ncrService.changeNcrRecord(this.cellData).subscribe((data): any => {
        this.toaster.success("Status Successfuly added")
        this.getAllNCR();
      }, error => {
        this.toaster.error("Error", error.message)
      })
    }
    if (col === 'TargetDate') {
      const dateString = params.data.TargetDate === null ? null : params.data.TargetDate;
      let dateObject = '';
      if (dateString != null) {
        dateObject = this.convertDate(params.data.TargetDate);
      }
      this.cellData = {
        targetDate: dateObject,
        ncrId: params.data.ncrId,
        reqType: 2,
      };

      this.ncrService.changeNcrRecord(this.cellData).subscribe((data): any => {
        this.toaster.success("Target Date Successfuly added")

        this.getAllNCR();
      }, error => {
        this.toaster.error("Error", error.message)
      })
    }
    if (col === 'CompletionDate') {
      if (params.data.Status === "Rejected") {
        this.toaster.warning("Status Is Rejected")
      }
      else {
        const dateString = params.data.CompletionDate === null ? null : params.data.CompletionDate;
        let dateObject = '';
        if (dateString != null) {
          dateObject = this.convertDate(params.data.CompletionDate);
        }

        const completionDate = new Date(dateObject);
        this.cellData = {
          observationStatus: params.data.ObservationStatus,
          completionDate: dateObject,
          ncrId: params.data.ncrId,
          reqType: 3,
        };



        this.ncrService.changeNcrRecord(this.cellData).subscribe((data): any => {
          this.toaster.success("Completion Date Successfuly added")
          this.getAllNCR();

        }, error => {
          this.toaster.error("Error", error.message)
        })

        params.data.Status = 'Closed';
        params.node.setDataValue('Status', params.data.Status);
      }
    }
    if (col === 'NewTargetDate') {
      let dateObject;
      if (params.data.Status === "Rejected") {
        this.toaster.warning("Status Is Rejected")
      }
      else if (params.data.Status === "Closed") {
        this.toaster.warning("Status Is Closed")
      }
      else {
        params.data.Status = 'In Progress';
        params.node.setDataValue('Status', params.data.Status);

        if (params.data.NewTargetDate) {
          dateObject = this.convertDate(params.data.NewTargetDate);
        }


        this.cellData = {
          newTargetDate: dateObject,
          ncrId: params.data.ncrId,
          reqType: 1,
        };



        this.ncrService.changeNcrRecord(this.cellData).subscribe((data): any => {
          this.toaster.success("New Target Date Successfuly added")
          this.getAllNCR();

        }, error => {
          this.toaster.error("Error", error.message)
        })
      }

    }

  }

  convertDate(date: any): any {
    const dateParts = date.split('/');
    return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
  }
  //breadCrumb

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;

    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }

}

function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}

function getSqlDate(value: any): any {
  return (
    value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate()
  );
}

function GenderCellRenderer(): void { }

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};

