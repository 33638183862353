<div class="production-db-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
      </ul>
    </ul>
    <div class="title-row-flex-both-end">
      <h1 class="main-title">OEE DashBoard</h1>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  <form [formGroup]="oeeDashboardForm">
    <div class="content-top-row content-top-row-order-processing">
      <!-- GROUP -->
      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <!-- <mat-label>Status</mat-label> -->
        <mat-select formControlName="UserGroup1" (selectionChange)="onChangeGroup($event)" placeholder="Group"
          panelClass="testClass" name="Status">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
            (filteredReturn)="filteredGroups = $event"></mat-select-filter>
          <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
            {{group.EG_Desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-select formControlName="UserPlant1" (selectionChange)="onChangePlant($event)" placeholder="Plant"
          panelClass="testClass" name="Status">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList1"
            (filteredReturn)="filteredPlants = $event"></mat-select-filter>
          <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
            {{plant.PT_Desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-select formControlName="line" (selectionChange)="onChangeLine($event)" placeholder="Line"
          panelClass="testClass" name="line">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
            (filteredReturn)="filteredLines = $event"></mat-select-filter>
          <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
            {{line.PL_Desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-select formControlName="unit" placeholder="Unit" panelClass="testClass" name="unit"
          (selectionChange)="onSubmit()">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="unitList"
            (filteredReturn)="filteredUnits = $event"></mat-select-filter>
          <mat-option *ngFor="let unit of filteredUnits" [value]="unit.PU_Id">
            {{unit.PU_Desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Dropdown -->
    <div class="content-top-row content-top-row-order-processing">
      <div class="inputField"> 
        <nz-date-picker  class="calender-input"  id="startPicker" formControlName="dateStart" nzFormat="dd/MM/yyyy HH:mm"  
        [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
        <span class="floating-text">Start Date</span>   
      </div>
      <div class="inputField">
        <nz-date-picker  class="calender-input"   id="endPicker" formControlName="dateEnd" nzFormat="dd/MM/yyyy HH:mm" 
        [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
        <span class="floating-text">End Date</span>   
      </div>
      <div class="btn-position">
        <button type="submit" class="icon-btn"  matTooltipClass="custom-tooltip" matTooltip="Apply Filter" (click)="onSubmit()" tooltipPosition="top">
          <img src="assets/images/search.png" alt="" width="20px" height="20px">
      </button>
      </div>
      <!-- <div class="btn-position">
        <button mat-raised-button color="accent" class="btn-accent oee-button-w" (click)="today()">Today  </button>
      </div>
      <div class="btn-position">
        <button mat-raised-button color="accent" class="btn-accent" (click)="yesterday()">Yesterday</button>
      </div>
      <div class="btn-position">
        <button mat-raised-button color="accent" class="btn-accent" (click)="lastWeek()">Last Week</button>
      </div>
      <div class="btn-position">
        <button mat-raised-button color="accent" class="btn-accent" (click)="lastMonth()">Last Month</button>
      </div> -->
      <div class="d-flex" style="margin: 10px;">
        <div class="mr-10" style="margin: inherit;">
            <p class="topBtn mx-2" (click)="today();showPhase(1)" [class.phase-active]="activeButton === 1" matTooltipClass="custom-tooltip" matTooltip="Today(Current Shift)">T</p>
        </div>
        <div class="mr-10" style="margin: inherit;">
            <p class="topBtn mx-2" (click)="yesterday();showPhase(2)" [class.phase-active]="activeButton === 2"  matTooltipClass="custom-tooltip" matTooltip="Yesterday(Last Shift)">1D</p>
        </div>
        <div class="mr-10" style="margin: inherit;">
            <p class="topBtn mx-2" (click)="lastWeek();showPhase(3)" [class.phase-active]="activeButton === 3"  matTooltipClass="custom-tooltip" matTooltip="Last Week">1W</p>
        </div>
        <div class="mr-10" style="margin: inherit;">
            <p class="topBtn mx-2" (click)="lastMonth();showPhase(4)" [class.phase-active]="activeButton === 4"  matTooltipClass="custom-tooltip" matTooltip="Last Month">1M</p>
        </div>
    </div>
    </div>

    <div class="card-wrapper-list production-db">
      <div class="card-item card-item-five">
        <div class="card">
          <o3-percentage-graph [barProperties]="capacityUtilizationAnalysis"></o3-percentage-graph>
          <p class="graph-title">Capacity Utilization</p>
        </div>
      </div>
      <div class="card-item card-item-five">
        <div class="card">
          <o3-percentage-graph [barProperties]="oeeAnalysis"></o3-percentage-graph>
          <p class="graph-title">OEE</p>
        </div>
      </div>

      <div class="card-item card-item-five-group">
        <div class="card">

          <o3-percentage-graph [barProperties]="availabilityAnalysis"></o3-percentage-graph>
          <p class="graph-title">Availability</p>
        </div>
      </div>
      <div class="card-item card-item-five-group">
        <div class="card">

          <o3-percentage-graph [barProperties]="performanceAnalysis"></o3-percentage-graph>
          <p class="graph-title">Performance</p>
        </div>
      </div>
      <div class="card-item card-item-five-group">
        <div class="card">

          <o3-percentage-graph [barProperties]="qualityAnalysis"></o3-percentage-graph>
          <p class="graph-title">Quality</p>
        </div>
      </div>
    </div>

    <!-- Card -->
    <div class="menu-card-group">
     
    
      <div class="menu-card-item mat-card-height">
        <p class="title-text">Total Planned Hours</p>
        <p class="data-text">
          {{PlannedHours}}
          <span class="data-text small-data-text">hrs</span>
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuBlueIconUrl" alt="{{ menuBlueIconUrl }}" />
        </div>
      </div>
      <div class="menu-card-item mat-card-height">
        <p class="title-text">Total Running Hours</p>
        <p class="data-text">
          {{RunningHourse}}
          <span class="data-text small-data-text">hrs</span>
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuBlueIconUrl" alt="{{ menuBlueIconUrl }}" />
        </div>
      </div>
      <div class="menu-card-item mat-card-height">
        <p class="title-text">Shutdown Hours</p>
        <p class="data-text">
          {{ShutdownHours}}
          <span class="data-text small-data-text">hrs</span>
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuBlueIconUrl" alt="{{ menuBlueIconUrl }}" />
        </div>
      </div>
    </div>

    <div class="menu-card-group">
      <div class="menu-card-item mat-card-height">
        <p class="title-text">Ideal Production</p>
        <p class="data-text">
          {{idealProduction}}
          <span class="data-text small-data-text"></span>
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
        </div>
      </div>
      <div class="menu-card-item mat-card-height">
        <p class="title-text">Total Output</p>
        <p class="data-text">
          {{GrossProduce}}
          <span class="data-text small-data-text"></span>
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
        </div>
      </div>
      <div class="menu-card-item mat-card-height">
        <p class="title-text">Net Production</p>
        <p class="data-text">
          {{ netProduction}}
          <span class="data-text small-data-text"></span>
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
        </div>
      </div>
    </div>

    <div class="menu-card-group">
      <div class="menu-card-item mat-card-height">
        <p class="title-text">Total Waste</p>
        <p class="data-text">
          {{Waste}}
          <span class="data-text small-data-text"></span>
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuRedIconUrl" alt="{{ menuRedIconUrl }}" />
        </div>
      </div>
     
      <div class="menu-card-item mat-card-height">
        <p class="title-text">Waste Kg</p>
        <p class="data-text">
          {{wasteKG}}
          <span class="data-text small-data-text"></span>
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuRedIconUrl" alt="{{ menuRedIconUrl }}" />
        </div>
      </div>
      <div class="menu-card-item mat-card-height">
        <p class="title-text">Total Available Hours</p>
        <p class="data-text">
          {{AvailableHours}}
          <span class="data-text small-data-text">hrs</span>
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuRedIconUrl" alt="{{ menuRedIconUrl }}" />
        </div>
      </div>
    </div>
    <div></div>

    <div class="chart-group">
      <div class="chart-items">
        <div class="chart-title">OEE Losses</div>
        <div [chart]="chartMonthlyOeeDrill"></div>
      </div>
      <div class="chart-items">
        <div class="chart-title">Output Losses</div>
          <div [chart]="chartOutputLossWaterfall"></div>
      </div>
    </div>

    <!-- Charts  [plugins]="barChartPluginsMonthlyOEE" // add later-->
    <div class="chart-group">
      <div class="chart-items">
        <div class="chart-title">OEE Trend</div>
        <div [chart]="chartMonthlyOeeTrend"></div>
      </div>
      <div class="chart-items">
        <div class="chart-title">Volume Output</div>
        <div [chart]="chartMonthlyOutput"></div>
      </div>
    </div>
  </form>
  <!-- list -->
  <section>
    <div class="example-button-row">
      <button type="button" class="btn btn-success" (click)="csvExport()" style="margin-top: 30px;"><img src="assets/images/csv-file-format.png" ></button>
      <button type="button" class="btn btn-success" (click)="excelExport()" style="margin-top: 30px; margin-left: 10px;"><i class="fa fa-file-excel-o icon-white"  aria-hidden="true"></i></button>
    </div>
  </section>
  <mat-card-content>
    <ag-grid-angular style="width: 100%; height: 24vw;" class="ag-theme-alpine" [rowData]="rowData"
        [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
      </ag-grid-angular>
  </mat-card-content>
</div>