import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-machine-oee-analysis',
  templateUrl: './machine-oee-analysis.component.html',
  styleUrls: ['./machine-oee-analysis.component.scss']
})
export class MachineOeeAnalysisComponent implements OnInit {

  filteredLines: any = [];
  lineList: any = [];
  machineWiseoeeData: any = [];
  machineOEEChart: Chart[] = [];
  machineStops!: Chart;
  ytd!: Chart;
  downtime!: Chart;
  downtimeOccurences!: Chart;
  analysisHeaderForm = this.formBuilder.group({
    shift: ['', Validators.required],
    week: ['', Validators.required],
    month: ['', Validators.required],
    quarter: ['', Validators.required],
    line: ['', Validators.required],
    area: [new Date(), Validators.required]
  });
  IsAccess: boolean = false;
  IsWrite: boolean = false;
  breadcrumList: any = [];

  constructor(private formBuilder: FormBuilder, private kpiMaster: KpimasterService, private toaster: ToastrService, private usermanagementService: UserManagementService, private productivityservice: ProductivityService,
    public router: Router) { }

  ngOnInit(): void {
    this.drawMachines();
    this.drawChart();
  }

  drawMachines() {
    this.machineWiseoeeData = [
      {
        title: 'OEE Analysis for Techne-07', categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        data: [
          { y: 23, color: '#dc3545' }, { y: 52, color: '#dc3545' }, { y: 24, color: '#dc3545' }, { y: 8, color: '#dc3545' },
          { y: 0, name: '%', color: '#dc3545' }, { y: 37, color: '#dc3545' }, { y: 57, color: '#dc3545' }, { y: 53, color: '#dc3545' }, { y: 41, color: '#dc3545' }, { y: 44, name: '%', color: '#dc3545' }, { y: 36, color: '#dc3545' }, { y: 68, color: '#dc3545' }, { y: 59, color: '#dc3545' }, { y: 56, color: '#dc3545' },
          { y: 21, name: '%', color: '#dc3545' }, { y: 51, color: '#dc3545' }, { y: 14, color: '#dc3545' }, { y: 64, color: '#dc3545' }, { y: 72, color: '#dc3545' }, { y: 32, name: '%', color: '#dc3545' }]
      },
      {
        title: 'OEE Analysis for Techne-09', categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        data: [
          { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 20, color: '#dc3545' },
          { y: 40, name: '%', color: '#dc3545' }, { y: 56, color: '#dc3545' }, { y: 54, color: '#dc3545' }, { y: 58, color: '#dc3545' }, { y: 68, color: '#dc3545' }, { y: 87, name: '%', color: '#28a745' }, { y: 79, color: '#ffc107' }, { y: 79, color: '#ffc107' }, { y: 29, color: '#dc3545' }, { y: 50, color: '#dc3545' },
          { y: 61, name: '%', color: '#dc3545' }, { y: 30, color: '#dc3545' }, { y: 12, color: '#dc3545' }, { y: 68, color: '#dc3545' }, { y: 33, color: '#dc3545' }, { y: 53, name: '%', color: '#dc3545' }]
      },
      {
        title: 'OEE Analysis for Techne-11', categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        data: [
          { y: 85, color: '#28a745' }, { y: 63, color: '#dc3545' }, { y: 83, color: '#ffc107' }, { y: 74, color: '#dc3545' },
          { y: 77, name: '%', color: '#ffc107' }, { y: 68, color: '#dc3545' }, { y: 72, color: '#dc3545' }, { y: 76, color: '#ffc107' }, { y: 81, color: '#ffc107' }, { y: 75, name: '%', color: '#ffc107' }, { y: 61, color: '#dc3545' }, { y: 63, color: '#dc3545' }, { y: 65, color: '#dc3545' }, { y: 65, color: '#dc3545' },
          { y: 50, name: '%', color: '#dc3545' }, { y: 28, color: '#dc3545' }, { y: 11, color: '#dc3545' }, { y: 64, color: '#dc3545' }, { y: 37, color: '#dc3545' }, { y: 50, name: '%', color: '#dc3545' }]
      },
      {
        title: 'OEE Analysis for Techne-13', categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        data: [
          { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' },
          { y: 0, name: '%', color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, name: '%', color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' },
          { y: 0, name: '%', color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, name: '%', color: '#dc3545' }]
      },
      {
        title: 'OEE Analysis for Uniloy-01', categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        data: [
          { y: 50, color: '#dc3545' }, { y: 27, color: '#dc3545' }, { y: 42, color: '#dc3545' }, { y: 58, color: '#dc3545' },
          { y: 54, name: '%', color: '#dc3545' }, { y: 25, color: '#dc3545' }, { y: 47, color: '#dc3545' }, { y: 46, color: '#dc3545' }, { y: 53, color: '#dc3545' }, { y: 42, name: '%', color: '#dc3545' }, { y: 57, color: '#dc3545' }, { y: 47, color: '#dc3545' }, { y: 49, color: '#dc3545' }, { y: 50, color: '#dc3545' },
          { y: 61, name: '%', color: '#dc3545' }, { y: 70, color: '#dc3545' }, { y: 67, color: '#dc3545' }, { y: 81, color: '#dc3545' }, { y: 47, color: '#ffc107' }, { y: 50, name: '%', color: '#dc3545' }]
      },
      {
        title: 'OEE Analysis for Uniloy-02', categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        data: [
          { y: 47, color: '#dc3545' }, { y: 61, color: '#dc3545' }, { y: 61, color: '#dc3545' }, { y: 64, color: '#dc3545' },
          { y: 0, name: '%', color: '#dc3545' }, { y: 37, color: '#dc3545' }, { y: 57, color: '#dc3545' }, { y: 53, color: '#dc3545' }, { y: 41, color: '#dc3545' }, { y: 44, name: '%', color: '#dc3545' }, { y: 36, color: '#dc3545' }, { y: 68, color: '#dc3545' }, { y: 59, color: '#dc3545' }, { y: 56, color: '#dc3545' },
          { y: 21, name: '%', color: '#dc3545' }, { y: 51, color: '#dc3545' }, { y: 14, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 9, color: '#dc3545' }, { y: 21, name: '%', color: '#dc3545' }]
      },
      {
        title: 'OEE Analysis for Uniloy-03', categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        data: [
          { y: 63, color: '#dc3545' }, { y: 67, color: '#dc3545' }, { y: 63, color: '#dc3545' }, { y: 2, color: '#dc3545' },
          { y: 24, name: '%', color: '#dc3545' }, { y: 16, color: '#dc3545' }, { y: 1, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 14, color: '#dc3545' }, { y: 48, name: '%', color: '#dc3545' }, { y: 65, color: '#dc3545' }, { y: 53, color: '#dc3545' }, { y: 31, color: '#dc3545' }, { y: 52, color: '#dc3545' },
          { y: 43, name: '%', color: '#dc3545' }, { y: 42, color: '#dc3545' }, { y: 42, color: '#dc3545' }, { y: 1, color: '#dc3545' }, { y: 12, color: '#dc3545' }, { y: 20, name: '%', color: '#dc3545' }]
      },
      {
        title: 'OEE Analysis for Uniloy-04', categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        data: [
          { y: 90, color: '#28a745' }, { y: 92, color: '#28a745' }, { y: 96, color: '#28a745' }, { y: 96, color: '#28a745' },
          { y: 92, name: '%', color: '#28a745' }, { y: 33, color: '#dc3545' }, { y: 76, color: '#ffc107' }, { y: 90, color: '#28a745' }, { y: 87, color: '#28a745' }, { y: 26, name: '%', color: '#dc3545' }, { y: 92, color: '#28a745' }, { y: 68, color: '#dc3545' }, { y: 80, color: '#ffc107' }, { y: 71, color: '#dc3545' },
          { y: 83, name: '%', color: '#ffc107' }, { y: 90, color: '#28a745' }, { y: 92, color: '#28a745' }, { y: 82, color: '#ffc107' }, { y: 73, color: '#dc3545' }, { y: 82, name: '%', color: '#ffc107' }]
      },
      {
        title: 'OEE Analysis for Techne-15', categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        data: [
          { y: 75, color: '#ffc107' }, { y: 29, color: '#28a745' }, { y: 0, color: '#28a745' }, { y: 0, color: '#28a745' },
          { y: 0, name: '%', color: '#28a745' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#ffc107' }, { y: 44, color: '#28a745' }, { y: 11, color: '#28a745' }, { y: 58, name: '%', color: '#dc3545' }, { y: 76, color: '#ffc107' }, { y: 75, color: '#ffc107' }, { y: 74, color: '#ffc107' }, { y: 68, color: '#dc3545' },
          { y: 72, name: '%', color: '#ffc107' }, { y: 22, color: '#28a745' }, { y: 19, color: '#28a745' }, { y: 81, color: '#ffc107' }, { y: 66, color: '#dc3545' }, { y: 81, name: '%', color: '#ffc107' }]
      },
      {
        title: 'OEE Analysis for Techne-08', categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        data: [
          { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' },
          { y: 0, name: '%', color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, name: '%', color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' },
          { y: 0, name: '%', color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 44, color: '#dc3545' }, { y: 80, color: '#ffc107' }, { y: 68, name: '%', color: '#dc3545' }]
      },
      {
        title: 'OEE Analysis for Techne-16', categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        data: [
          { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' },
          { y: 0, name: '%', color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 0, name: '%', color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 29, color: '#dc3545' }, { y: 29, color: '#dc3545' }, { y: 0, color: '#dc3545' },
          { y: 0, name: '%', color: '#dc3545' }, { y: 14, color: '#dc3545' }, { y: 0, color: '#dc3545' }, { y: 1, color: '#dc3545' }, { y: 13, color: '#ffc107' }, { y: 16, name: '%', color: '#dc3545' }]
      }
    ];
    if (this.machineWiseoeeData && this.machineWiseoeeData.length) {
      for (const machineOEE of this.machineWiseoeeData) {
        const chart = new Chart({
          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 45
            }
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: machineOEE.categories,
            crosshair: true
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              borderWidth: 0,
              dataLabels: {
                enabled: true,
              },
              point: {
                events: {
                  click: this.getYTDHeaderBasedValues.bind(this)
                }
              }
            }
          },
          series: [
            {
              name: machineOEE.title,
              type: 'column',
              data: machineOEE.data
            }
          ]
        });
        this.machineOEEChart.push(chart);
      }
    }
  }

  drawChart() {

    const deptColumns = ['Dep 1', 'Dep 2', 'Dep 3', 'Dep 4']
    this.machineStops = new Chart({
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: deptColumns,
        crosshair: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getYTDHeaderBasedValues.bind(this)
            }
          }
        }
      },
      series: [
        {
          name: 'Column A',
          type: 'column',
          data: [5, 2, 1, 3],
          "color": "#0f0fd1"
        },
        {
          name: 'Column B',
          type: 'column',
          data: [2, 3, 4, 2],
          "color": "#10ce9c"
        },
        {
          name: 'Column C',
          type: 'column',
          data: [3, 4, 5, 5],
          "color": "#17a2b8"
        },
        {
          name: 'Column D',
          type: 'column',
          data: [1, 1, 3, 3],
          "color": "#dc3545"
        }
      ],

    });

    this.ytd = new Chart({
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        crosshair: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getYTDHeaderBasedValues.bind(this)
            }
          }
        }
      },
      series: [
        {
          type: 'column',
          data: [{ y: 1.20, color: '#17a2b8' }]
        }
      ]
    });

    this.downtime = new Chart({

      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
      },
      xAxis: {
        categories: ['Startup', 'Machine Under', 'Cutting Ring', 'QC Approval', 'Label Jam', 'Disturbance in ', 'Machine On &', 'Blow Pin Tip Da', 'Preventive Maintenance', 'Carriage manufacturing'],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Duration (Min)',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        valueSuffix: ' millions'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'bar',
        data: [{ color: '#dc3545', y: 29 }, { color: '#c19614', y: 21 }, { color: '#c19614', y: 21 }, { color: '#c19614', y: 21 }, { color: '#ffc107', y: 19 }, { color: '#ffc107', y: 18 }, { color: '#ffc107', y: 13 }, { color: '#6acb80', y: 9 }, { color: '#6acb80', y: 9 }, { color: '#10ce9c', y: 7 }]
      }]
    });

    this.downtimeOccurences = new Chart({
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
      },
      xAxis: {
        categories: ['QC Approval', 'Startup', 'Label Jam', 'QC Approval', 'Label Jam', 'Machine On & ', 'Machine Under &', 'QC Approval', 'Cavity Dumpin', 'Carriage manufacturing'],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Count of Fault',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        valueSuffix: ' millions'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'bar',
        data: [{ color: '#dc3545', y: 145 }, { color: '#c19614', y: 142 }, { color: '#c19614', y: 134 }, { color: '#c19614', y: 104 }, { color: '#ffc107', y: 78 }, { color: '#ffc107', y: 62 }, { color: '#ffc107', y: 47 }, { color: '#6acb80', y: 40 }, { color: '#6acb80', y: 40 }, { color: '#10ce9c', y: 40 }]
      }]

    });
  }

  getYTDHeaderBasedValues() { }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  getLine(id: any) {
    const key = {
      PT_ID: 0,
      userId: 206
    };

    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      if (this.lineList.length > 0) {
        const lineDefault = this.lineList[0];
        this.analysisHeaderForm.controls.line.patchValue(lineDefault.PL_Id);
        this.filteredLines = this.lineList.slice();
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

}
