import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CitiesService } from 'src/app/services/Cities.service';
import { CountriesService } from 'src/app/services/Countries.service';

@Component({
  selector: 'app-CitiesAndCountries',
  templateUrl: './CitiesAndCountries.component.html',
  styleUrls: ['./CitiesAndCountries.component.scss']
})
export class CitiesAndCountriesComponent implements OnInit {
  citiesRes: any= [];
  countries =[];
  name: string = "";
  isActive: boolean = true;
  isDeleted: number = 0;
  createdBy: string = "";
  createdOn = new Date();
  updatedBy: String = "";
  updatedOn = new Date();
  countriesList = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefsCountry!: any[];
  rowDataCountry!: any[];
  rowDataCities!: any[];
  columnDefsCities!: any[];
  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },

    paginationPageSize: 10,
  };
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  constructor(private CountryService: CountriesService, private fb: FormBuilder, 
    private cityService: CitiesService,private toaster: ToastrService,
  ) { 

    this.columnDefsCities = [
      {
        field: 'cityId',
        sortable: true,
        filter: true,
        headerName: 'CityId',
      },
        // {
        //   field: 'countryId',
        //   sortable: true,
        //   filter: true,
        //   headerName: 'countryId',
        // },
        // {
        //   field: 'createdBy',
        //   sortable: true,
        //   filter: true,
        //   headerName: 'createdBy',
        // }, {
        //   field: 'createdOn',
        //   sortable: true,
        //   filter: true,
        //   headerName: 'createdOn',
        // }, {
        //   field: 'isActive',
        //   sortable: true,
        //   filter: true,
        //   headerName: 'isActive',
        // }, {
        //   field: 'isDeleted',
        //   sortable: true,
        //   filter: true,
        //   headerName: 'isDeleted',
        // }, {
        //   field: 'name',
        //   sortable: true,
        //   filter: true,
        //   headerName: 'name',
        // }, {
        //   field: 'updatedBy',
        //   sortable: true,
        //   filter: true,
        //   headerName: 'updatedBy',
        // }, {
        //   field: 'updateOn',
        //   sortable: true,
        //   filter: true,
        //   headerName: 'updateOn',
        // },
      {
        field: 'name',
        sortable: true,
        filter: true,
        headerName: 'Name',
       }
    ];
    this.columnDefsCountry = [
      {
        field: 'countryId',
        sortable: true,
        filter: true,
        headerName: 'CountryId',
      },
      {
        field: 'name',
        sortable: true,
        filter: true,
        headerName: 'Name',
       }
    ];
  }

  ngOnInit() {
    debugger
    this.getAllCountries();
    this.getAllCities();
    this.countryDataForm = this.fb.group({
      countryName: new FormControl('', [Validators.required]),
    });

    this.cityDataForm = this.fb.group({
      Id: new FormControl('', []),
      cityName: new FormControl('', [Validators.required]),
      countryId: new FormControl('', [Validators.required]),
    });
  }
  panelOpenState = false;
  countryDataForm!: FormGroup;
  cityDataForm!: FormGroup
  countryPriorityformMode: boolean = true;
  IsWrite!: any;

  getAllCountries() {
    debugger
    const data = this.CountryService.getAllCountries().subscribe(
      (data: any) => {
        this.countries = data;
      });
  }
  getAllCities() {
    debugger
    this.cityService.getAllCity().subscribe(
      (data: any) => {
        this.citiesRes = data;
      });
  }
  addCountryResponse: any;
  addNewCountry() {
    const val = [];
    const key = {
      name:this.countryDataForm.value.countryName,
      isActive:true,
      isDeleted:false,
      createdOn:new Date(),
      createdBy:2
    };
    val.push(key);
    const key2 = {
      data: val
    };
    this.CountryService.addNewCountry(key2).subscribe(
      (data:any) => {
        this.addCountryResponse=data.message;
        this.toaster.success('Success',this.addCountryResponse);
      },
      (error) => {
        this.toaster.error('Error', this.addCountryResponse);
      });
      this.reset();
      this.getAllCountries();
  }
  updateCountry() {
    const val = [];
    const key = {
      name:this.countryDataForm.value.countryName,
      isActive:true,
      isDeleted:false,
      updateOn:new Date(),
      updateBy:2
    };
    val.push(key);
    const key2 = {
      data: val
    };
    this.CountryService.updateCountry(key2).subscribe(
      (data:any) => {
        this.addCountryResponse=data.message;
        this.toaster.success('Success',this.addCountryResponse);
      },
      (error) => {
        this.toaster.error('Error', this.addCountryResponse);
      });
      this.reset();
      this.getAllCountries();
  }
  addNewCity() {
    debugger
    const val = [];
    const key = {
      name:this.cityDataForm.value.cityName,
      country:this.cityDataForm.value.countryId,
      isActive:true,
      isDeleted:false,
      createdOn:new Date(),
      createdBy:2
    };
    val.push(key);
    const key2 = {
      data: val
    };
    this.cityService.addNewCity(key2).subscribe(
      (data:any) => {
        this.addCountryResponse=data.message;
        this.toaster.success('Success',this.addCountryResponse);
      },
      (error) => {
        this.toaster.error('Error', this.addCountryResponse);
      });
      this.reset();
      this.getAllCountries();
  }
  updateCity() {
    debugger
    const val = [];
    const key = {
      name:this.cityDataForm.value.cityName,
      country:this.cityDataForm.value.countryId,
      isActive:true,
      isDeleted:false,
      updatedOn:new Date(),
      updatedBy:2
    };
    val.push(key);
    const key2 = {
      data: val
    };
    this.cityService.updateCity(key2).subscribe(
      (data:any) => {
        this.addCountryResponse=data.message;
        this.toaster.success('Success',this.addCountryResponse);
      },
      (error) => {
        this.toaster.error('Error', this.addCountryResponse);
      });
      this.reset();
      this.getAllCountries();
 
  }
  deleteCountry() {
    const key = {
      // plantId: this.checklistDataForm.value.plant,
    };
  }
  public checkError = (controlName: string, errorName: string) => {
    return this.countryDataForm.controls[controlName].hasError(errorName);
  }

  public checkErrorCity =  (controlName: string, errorName: string) => {
    return this.cityDataForm.controls[controlName].hasError(errorName);
  }
  reset() {
    this.countryDataForm.get('countryName')?.reset();
  }
  resetCity(){
    this.cityDataForm.get('countryId')?.reset();
    this.cityDataForm.get('cityName')?.reset();
  }
}
