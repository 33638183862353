<ngx-spinner template="<img src='assets/images/spinner.gif'/>"></ngx-spinner>
<section class="home-content">
    <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
    </ul>
    <mat-card>
        <form [formGroup]=" wholeSomeFoodForm">
            <div style="padding:10px;background-color: #0F0FD1;">
                <div class="p-grid p-fluid">
                    <div class="p-col-2">
                        <mat-card-title style="color: white; font-size: medium;">Wholesome Food Report</mat-card-title>
                    </div>
                    <div class="p-col-2">
                        <p-dropdown [filter]="true" [options]="GroupList" formControlName="UserGroup1"
                            placeholder="Select a Group" optionLabel="EG_Desc" optionValue="EG_Id" (onChange)="onChangeGroup($event)"
                            [showClear]="true"></p-dropdown>
                    </div>
                    <div class="p-col-2">
                        <p-dropdown [filter]="true" [options]="PlantList1" formControlName="UserPlant1" (onChange)="onChangePlant($event)"
                            placeholder="Select a Plant" optionLabel="PT_Desc" optionValue="PT_Id"
                            [showClear]="true"></p-dropdown>
                    </div>
                    <div class="p-col-2">
                        <p-dropdown [filter]="true" [options]="lineList" formControlName="line"  (onChange)="onChangeLine($event)"
                            placeholder="Select a Line" optionLabel="PL_Desc" optionValue="PL_Id"
                            [showClear]="true"></p-dropdown>
                    </div>
                    <div class="p-col-2">
                        <p-dropdown [filter]="true" [options]="unitList" formControlName="unit" (onChange)="getProductSpecification()"
                            placeholder="Select a Unit" optionLabel="PU_Desc" optionValue="PU_Id"
                            [showClear]="true"></p-dropdown>
                    </div>
                    <!-- <div class="p-col-2">
                        <p-dropdown [filter]="true" [options]="productCodeList" formControlName="productCode"
                            placeholder="Select a Product code" optionLabel="prodDesc" optionValue="prodCode"
                            [showClear]="true"></p-dropdown>
                    </div> -->
                </div>
            </div>
            <section style="margin-left: 10px;">
                <div class="example-button-row">
                  <button type="button" class="btn btn-success" (click)="csvExport()" style="margin-top: 30px;"><img src="assets/images/csv-file-format.png" ></button>
                  <button type="button" class="btn btn-success" (click)="excelExport()" style="margin-top: 30px; margin-left: 10px;"><i class="fa fa-file-excel-o icon-white"  aria-hidden="true"></i></button>
                </div>
              </section>
            <mat-card-content>
                <ag-grid-angular #agGrid style="width: 100%; height: 20vw" id="myGrid" class="ag-theme-alpine"
                    [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [masterDetail]="true" [defaultExportParams]="defaultExportParams"  [excelStyles]="excelStyles"
                    [detailCellRendererParams]="detailCellRendererParams" [rowData]="rowData"  [animateRows]="true"
                    (firstDataRendered)="onFirstDataRendered($event)" (gridReady)="onGridReady($event)">
                </ag-grid-angular>
            </mat-card-content>
        </form>
    </mat-card>
</section>