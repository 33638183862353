import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CcrHistoryComponent } from '../ccr-history/ccr-history.component';
import { CustomerComplaintComponent } from '../customer-complaint/customer-complaint.component';
import { AuthGuard } from '../services/auth.guard';
import { CustomercomplaintmanagementComponent } from './customercomplaintmanagement/customercomplaintmanagement.component';

const routes: Routes = [
  // { path: '', component: CustomerComplaintComponent },
  { path: 'CCPortal', component: CustomercomplaintmanagementComponent },
  { path: 'ccr-history', component: CcrHistoryComponent },

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerComplaintRoutingModule { }
