import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  baseUrl: string = environment.O3CoreApiUrl;
  kpiUrl: string = environment.O3CoreApiImageUrl;
  apiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  addnotification(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Roles/addnotificationsrolewise', data);
  }

  usernotifications(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Users/Usernotifications', data);
  }

  updatenotificationstatus(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Notification/updateNotification', data);
  }
  addnotificationuserwise(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'notification/AddUserWiseNotifications', data);
  }
  addnotificationmappeduserwise(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'UserNotification/addNotificationsMappeduserwise', data)
  }
  addnotificationdepartmentwise(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'notification/AddDepartmentWiseNotifications', data);
  }

  ClearNotification(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.apiUrl + 'clearnotification', data);
  }


}
