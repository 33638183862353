import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CostdictionaryService } from 'src/app/services/costdictionary.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ThemePalette } from '@angular/material/core';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { MatDialog } from '@angular/material/dialog';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { Frequency } from 'src/app/models/frequency';
import { CostService } from 'src/app/services/cost.service';
import { CostDictionary } from 'src/app/models/cost_dictionaries/cost-dictionary';
import { BtnCellRendererQxMatrix } from 'src/app/btn-cell-rendererQxMatrix';
import Swal from 'sweetalert2';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { OleService } from 'src/app/services/ole.service';
import { KpiDataPointService } from 'src/app/services/kpiDataPoint.service';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';
import { CurrencyService } from 'src/app/services/currency.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslaterService } from 'src/app/services/translater.service';
import { MatSelect } from '@angular/material/select';
import { KpitreeService } from 'src/app/services/kpitree.service';

@Component({
  selector: 'app-costdictionary',
  templateUrl: './costdictionary.component.html',
  styleUrls: ['./costdictionary.component.scss'],
})
export class CostdictionaryComponent implements OnInit {
  @ViewChild('ImportDialog') ImportDialog!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('costElement') costElement!: MatSelect;
  @ViewChild('displayCostDictionaryModal')
  private displayCostDictionaryModal!: TemplateRef<any>;
  formControl = new FormControl(['angular']);
  eventID!: any;
  index!: number;
  unitList!: any[];
  unitListTemp!: any[];
  currencyList!: any[];
  puDesc!: any;
  CurrencyName!: any;
  KPIsList: any;
  fileSave!: any;
  arrayBuffer!: any;
  filelist!: any[];
  inputEmpty = true;
  excelData: any;
  Kpi: any;
  KpiTemp: any;
  glAccount: any;
  detailData: any;
  Currency: any;
  CurrencyTemp: any;
  CurrencyId: any;
  calcList: any[] = [];
  PrevIndex: any;
  currentIndex: any;
  result: string;
  kpisTempList: any[] = [];
  isDisabled: boolean = false;
  kpiList: any[] = [];
  kpiListTempPlantWise: any[] = [];
  moduleId: any;
  pageId: any;
  langObj: any;
  languageDir = 'ltr'
  ExportCostElement: any[];
  ExportKPI: any[];

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private costService: CostdictionaryService,
    private enterpriseGroupService: EnterPriseGroupService,
    private dialog: MatDialog,
    private kpiService: KpimasterService,
    private costelementService: CostService,
    private oleService: OleService,
    private kpiDataPointService: KpiDataPointService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private currencyservice: CurrencyService,
    private kpiTreeservice: KpitreeService,
    private translater: TranslaterService,
  ) {
    LicenseManager.setLicenseKey('kazaz');
  }

  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  CostSearchForm!: FormGroup;
  CostDictionaryForm!: FormGroup;
  CostAddUpdateForm!: FormGroup;
  groups!: any;
  plants!: any;
  plantsTemp!: any;
  groupListDDL!: any[];
  filteredGroupListDDL!: any[];
  glAccountList!: any[];
  indexArray: any[] = [];
  duplicateArray: any[] = [];
  tempData!: any[];
  UnitName!: string;
  varUnitName!: string;
  isPlantGroupModelVisible = false;
  UnitNode: any;
  unitid!: any;
  Id!: any;
  plantid!: number;
  frequencyid!: number;
  kpiid!: number;
  breadcrumList: any;
  puid!: number;
  rowData: any = [];
  EmptyData: any;
  gridApi: any;
  ButtonType!: any;
  costDictionaryId!: number;
  defaultColDef: any;
  IsAccess!: any;
  IsWrite!: any;
  columnDefs!: any;
  dialogRef: any;
  costDictionaryData: any[];
  gridOptions: any = {
    resizable: true,
    filter: 'agTextColumnFilter',
    defaultColDef: { flex: 1 },
    masterDetail: true,
    isRowMaster: (dataItem: any) => {
      return dataItem.Detail ? true : false;
    },
    detailCellRendererParams: {
      detailGridOptions: {
        columnDefs: [
          { field: 'lacDdetailId', hide: true, headerValueGetter: this.localizeHeader.bind(this) },
          { field: 'costElementId', hide: true, headerValueGetter: this.localizeHeader.bind(this) },
          {
            field: 'costElementName',
            headerName: 'Cost Element',
            minWidth: 150,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          { field: 'Cost', headerName: 'Cost', minWidth: 100 , headerValueGetter: this.localizeHeader.bind(this),
            valueFormatter: (params: any) => {
              if (!params.value) {
                return 0;
              } else {
                const num = params.value;
                return num.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                });
              }
          } },
          { field: 'Budget', headerName: 'Budget', minWidth: 100  , headerValueGetter: this.localizeHeader.bind(this),
            valueFormatter: (params: any) => {
              if (!params.value) {
                return 0;
              } else {
                const num = params.value;
                return num.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                });
              }
          }},
          { field: 'GLAccount', headerName: 'GL Account', minWidth: 100, headerValueGetter: this.localizeHeader.bind(this) },
          {
            field: 'lossAnalysisCostDicId',
            hide: true,
            headerValueGetter: this.localizeHeader.bind(this)
          }
        ],

        defaultColDef: { flex: 1 },
        isRowMaster: (dataItem: any) => {
          return dataItem.Detail ? true : false;
        },
      },
      getDetailRowData: (params: any) => {
        params.successCallback(params.data.Detail);
      },
    },
    getDetailRowData: (params: any) => {
      params.successCallback(params.data.Detail);
    },
    frameworkComponents: {
      btnCellRendererQxMatrix: BtnCellRendererQxMatrix,
    },
  };
  gridColumnApi: any;
  setRowData!: any[];
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  frequencies!: Frequency[];
  Kpis!: any;
  KpisTemp!: any;
  costElements!: any[];
  costElementsTemp!: any[];
  selectedCostElements: any[] = [];
  selectedCostElementsTemp: any[] = [];
  costDictionaryArray!: FormArray;
  costDictionaries!: CostDictionary[];
  formMode = false;
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.CostSearchForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.CostDictionaryForm = this.fb.group({
      UnitNameList: [''],
      CurrencyName: [''],
      frequency: [''],
      periodFrom: new Date(),
      periodTo: new Date(),
      kpi: [''],
      period: ['', Validators.required],
      kpiValue: [''],
      fromDate: [''],
      toDate: [''],
    });
    this.CostAddUpdateForm = this.fb.group({
      formula: [
        this.calcList.map((c) => c.dpid).join(''),
        Validators.pattern(
          /^(?:\d|\+|\-|\/|\*|\(|\)|\.|\,|AVG|SUM|MAX|MIN|V\d+| )*$/gm
        ),
      ],
      // unitname: [''],
      //  frequency: [''],
      //  kpi: [''],
      // period: ['', Validators.required],
      // costDictionaries: this.fb.array([this.createCostElementArray()]),
      costDictionaries: this.fb.array([this.createCostElementArray()]),
    });
    this.costDictionaryArray = this.CostAddUpdateForm.get(
      'costDictionaries'
    ) as FormArray;
    this.getGroupData();
    this.GetBreadcrumList();
    this.GetMenuAccess();
  }
  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value:string){
    let val;
    if(this.langObj && this.langObj[value]){
      val = this.langObj[value];
    }
    return val?val:value;
 }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if(this.langObj){
        this.gridApi.refreshHeader();
      }
    });
  }
  
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        // this.IsAccess = data.IsAccess;

        this.IsWrite = data.IsWrite;
        // localStorage.setItem('GetMenuAccess', this.permisson);

        this.columnDefs = [
          {
            field: 'Actions',
            cellRenderer: 'btnCellRendererQxMatrix',
            cellRendererParams: {
              clicked: (field: any) => { },
            },
            sortable: true,

            resizable: true,
            filter: true,
            width: 250,
            wrapText: true,
            hide: !this.IsWrite,
            cellStyle: { textAlign: 'center' },
            maxWidth: 170,
            minWidth: 150,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          { field: 'LossAnalysisCostDicId', hide: true, headerValueGetter: this.localizeHeader.bind(this) },
          { field: 'plantId', hide: true, headerValueGetter: this.localizeHeader.bind(this) },
          {
            field: 'plantName',
            resizable: true,
            headerName: 'Plant',
            filter: true,
            cellRenderer: 'agGroupCellRenderer',
            width: 30,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          { field: 'puId', hide: true, headerValueGetter: this.localizeHeader.bind(this) },
          { field: 'puName', headerName: 'Unit/Machine' ,  resizable: true, filter:true, headerValueGetter: this.localizeHeader.bind(this)},
          { field: 'frequencyId', hide: true,  resizable: true, filter: true, headerValueGetter: this.localizeHeader.bind(this)},
          { field: 'frequencyName', headerName: 'Frequency',  resizable: true, filter: true, headerValueGetter: this.localizeHeader.bind(this)},
          { field: 'kpiCode', hide: true,  resizable: true, filter: true, headerValueGetter: this.localizeHeader.bind(this) },
          { field: 'kpiName', headerName: 'KPI',  resizable: true, filter:true, headerValueGetter: this.localizeHeader.bind(this) },
          { field: 'CurrencyName', headerName: 'Currency',  resizable: true, filter:true, headerValueGetter: this.localizeHeader.bind(this) },
          {
            field: 'Period',
            headerName: 'Date',
            resizable: true,
            filter: true,
            cellRenderer: (params: any) => {
              return (params.data?.FromDate || params.data?.ToDate) ? 
              moment(new Date(params.data?.FromDate)).format('DD-MM-YYYY') +' - '+
              moment(new Date(params.data?.ToDate)).format('DD-MM-YYYY') 
                : '';
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'value', headerName: 'Unit Cost', headerValueGetter: this.localizeHeader.bind(this)
            },
        ];
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.costService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.filteredGroupListDDL = this.groupListDDL.slice();
        this.CostSearchForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
        this.GetCurrencyData();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  // cost dictionary operations starts
  get repeateCostElementForm(): any {
    return this.CostAddUpdateForm.get('costDictionaries') as FormArray;
  }
  createCostElementArray(): FormGroup {
    return this.fb.group({
      costDictionaryDetailId: [null],
      costElementId: [''],
      cost: [''],
      glAccount: [''],
      budget: [''],
    });
  }

  addNewRow(): any {
    if (
      this.costElements.length ===
      this.CostAddUpdateForm.value.costDictionaries.length
    ) {
      this.toaster.warning('All Cost Center Elements are selected');
      return;
    }
    this.costDictionaryArray.push(this.createCostElementArray());
  }

  removeRepeatCostElement(index: any): void {

    this.detailData.splice(index, 1);
    const cost =
      this.CostAddUpdateForm.value.costDictionaries[index];
    this.duplicateArray = this.duplicateArray.filter(
      (x) => x.costElementId !== cost.costElementId
    );
    this.costDictionaryArray.removeAt(index);
    // this.selectedCostElements = [];
    // this.detailData?.forEach((element) => {
    //   const value = this.costElements?.find((x: any) => x.costId === element);
    //   this.selectedCostElementsTemp?.push(value);
    // });
    this.selectedCostElements = [];
    this.selectedCostElementsTemp?.forEach((x: any, indx) => {
      if (x.costId === cost.costElementId && x.type === 'cost') {
        this.selectedCostElementsTemp?.splice(indx, 1)
      }
    })
    this.selectedCostElements = this.selectedCostElementsTemp.slice();
  }

  ImportToExcel() {
    this.dialogRef = this.dialog.open(this.ImportDialog, { width: '500px' });
    this.dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  getFrequencies(): void {
    this.formReset();
    const key = {
      plantId: parseInt(this.CostSearchForm.value.plant, 10),
    };
    this.kpiService.getMasterFrequencies(key).subscribe(
      (frequency) => {
        this.frequencies = frequency;
        this.CostDictionaryForm.get('frequency').setValue(
          frequency[0].frequencyId
        );
        this.getAllCostDictionaries();
        this.onFrequencyChange();
        this.getCostElement();
        this.GetUnitsByPlant();
        this.getKpiByPlant();
        if (!this.CostDictionaryForm.get('frequency').value) {
          this.isDisabled = true;
        } else {
          this.isDisabled = false;
        }
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  onkpiChange(event: any): void {
    if (!this.CostDictionaryForm.value.kpi) {
      this.isDisabled = true;
    } else {
      const find = this.Kpis.find(x => x.kpiCode === this.CostDictionaryForm.value.kpi);
      this.isDisabled = false;
      this.selectedCostElementsTemp = this.selectedCostElementsTemp?.filter(x => x.type !== 'cost-kpi')
      const key = {
        costId: find.kpiCode,
        name: find.name,
        type: 'cost-kpi'
      }
      this.selectedCostElementsTemp.push(key);
      this.selectedCostElements = this.selectedCostElementsTemp.slice();
      this.calcList?.forEach((elem: any, i) => {
        if (elem.type === 'cost-kpi') {
          if (elem.type === 'cost-kpi') {
            this.calcList[i].dpName = find.name
            this.calcList[i].dpid = find.kpiId
          }
        }
      })
    }
  }

  onFrequencyChange(): void {
    const key = {
      plantId: parseInt(this.CostSearchForm.value.plant, 10),
      frequencyId: parseInt(this.CostDictionaryForm.value.frequency, 10),
    };

    this.kpiService.getKpiFrequency(key).subscribe(
      (kpis) => {
        this.KpisTemp = kpis;
        this.Kpis = this.KpisTemp.slice();
        if (this.KpisTemp.length > 0) {
          this.CostDictionaryForm.get('kpi')?.setValue(this.KpisTemp[0].kpiCode);
          const key = {
            costId:  this.KpisTemp[0].kpiCode,
            name: kpis[0].name,
            type: 'kpi'
          }
          this.selectedCostElementsTemp.push(key);
          this.selectedCostElements = this.selectedCostElementsTemp.slice();
        }
        if (!this.CostDictionaryForm.value.kpi) {
          this.isDisabled = true;
        } else {
          this.isDisabled = false;
        }

        //   this.CostDictionaryForm.get('kpi').setValue(kpis[0].kpiId)
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetCurrencyData() {
    this.currencyservice.GetCurrencyData().subscribe((res: any) => {
      this.CurrencyTemp = res;
      this.Currency = this.CurrencyTemp.slice();
      this.CostDictionaryForm.get('CurrencyName').setValue(res[0].CurrencyId);
    });
  }

  onPeriodToChange(event: any): void {
    const { periodFrom } = this.CostDictionaryForm.value;
    if (periodFrom !== '') {
      const periodF = new Date(periodFrom).getDate();
      const periodT = new Date(event.target.value).getDate();
      if (periodT <= periodF) {
        this.CostDictionaryForm.get('periodTo')?.setValue('');
        this.toaster.warning(
          'Period To Date Should be greater than Period From Date'
        );
      }
    } else {
      this.CostDictionaryForm.get('periodTo')?.setValue('');
      this.toaster.warning('Please Select Period From Date First');
    }
  }

  onCostElementChange(event: any, index: number): any {
    debugger;
    this.index = index;
    // this.resetCalculation();
    const costCenter = this.detailData?.find((x: any) => x === event.value);
    if (costCenter) {
      this.toaster.warning('Cost Element is already defined!');
      // this.costDictionaryArray.removeAt(index);
      if (index === this.detailData.length) {
        this.costDictionaryArray.value[index].costElementId = null;
      } else {
        this.costDictionaryArray.value[index].costElementId =
          this.detailData[index];
      }
      // this.costDictionaryArray.value[index].glAccount = null;
      const copyArray = [...this.costDictionaryArray.value];
      this.CostAddUpdateForm.get('costDictionaries')?.setValue(copyArray);
      // this.GetGLAccountByCostElementId(index);
      return;
    } else {
      this.detailData[index] = event.value;
    }
    // this.selectedCostElementsTemp.forEach((elem , i)=> {
    //   if(elem.type == 'cost')
    //   {
    //     this.selectedCostElementsTemp.splice(i , 1)
    //   }
    // })
    this.selectedCostElementsTemp = this.selectedCostElementsTemp?.filter(x => x.type !== 'cost')
    this.detailData?.forEach((element) => {
      const value = this.costElements?.find((x: any) => x.costId === element);
      const key = {
        costId: value.costId,
        name: value.name,
        type: 'cost'
      }
      this.selectedCostElementsTemp?.push(key);
    });
    this.selectedCostElements = [];
    this.selectedCostElements = this.selectedCostElementsTemp.slice();
    // this.calcList?.forEach((elem :any , i) => {
    //   if(elem.type === 'cost' && elem.dpid === event.value)
    //   {
    //        const value = this.costElements?.find((x: any) => x.costId === event.value);
    //        this.calcList[i].dpid = value.costId;
    //        this.calcList[i].dpName = value.name;
    //   }
    // })
    this.GetGLAccountByCostElementId(index);
  }
  getCostElement(): void {
    const key = {
      plantId: parseInt(this.CostSearchForm.value.plant, 10),
    };
    this.costelementService.GetCostElement(key).subscribe(
      (res: any) => {
        this.costElementsTemp = res;
        this.costElements = this.costElementsTemp.slice();
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }

  GetGLAccountByCostElementId(index: number): void {
    const key = {
      costelementid:
        this.CostAddUpdateForm.value.costDictionaries[index].costElementId,
    };
    this.costService.GetGLAccountByCostElementId(key).subscribe((res: any) => {
      const costelement = this.CostAddUpdateForm.value.costDictionaries[index];
      if (costelement) {
        this.CostAddUpdateForm.value.costDictionaries[index].glAccount =
          res[0].GLAccount;
        this.CostAddUpdateForm.get('costDictionaries')?.setValue(
          this.CostAddUpdateForm.value.costDictionaries
        );
        this.duplicateArray = this.CostAddUpdateForm.value.costDictionaries;
      }
    });
  }

  getAllCostDictionaries(): void {
    const data = {
      plantId: parseInt(this.CostSearchForm.value.plant, 10),
      // fromdate: moment(this.CostDictionaryForm.value.periodFrom).format('YYYY-MM-DD'),
      // todate: moment(this.CostDictionaryForm.value.periodTo).format('YYYY-MM-DD')
    };
    this.costService.getAllCostDictionary(data).subscribe(
      (res) => {
        debugger;
        this.costDictionaries = res;
        this.costDictionaryData = res;
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }

  changeUnit(event: any): void {
    this.puDesc = this.unitList.find(
      (x: any) => x.puId === event.value
    )?.puDesc;
    this.unitid = event.value;
  }
  changecurrency(event): void {
    this.CurrencyName = this.Currency.find(
      (x: any) => x.CurrencyId === event.value
    )?.CurrencyName;
    this.CurrencyId = event.value;
  }

  addCostDictionaryPopup(): void {
    this.formMode = false;
    const { UnitNameList, frequency, periodFrom, periodTo, kpi } =
      this.CostDictionaryForm.value;
    if (frequency !== '' && kpi !== '') {
      // this.CostDictionaryForm.get('UnitNameList')?.setValue(this.puid);
      this.CostDictionaryForm.get('frequency')?.setValue(
        this.frequencies.find((x) => x.frequencyId === parseInt(frequency, 10))
          ?.frequencyId
      );
      this.frequencyid = parseInt(frequency, 10);
      this.CostDictionaryForm.get('kpi')?.setValue(
        this.Kpis.find((x: { kpiCode: any }) => x.kpiCode === kpi)
          ?.kpiCode
      );

      this.kpiid = parseInt(kpi, 10);
      this.CostDictionaryForm.get('period')?.reset();
      this.getCostElement();
      this.costDictionaryArray.clear();
      this.duplicateArray = [];
      this.detailData = [];
      this.selectedCostElements = [];
      this.selectedCostElementsTemp = [];
      const find2 = this.Kpis?.find((x: any) => x.kpiCode === this.CostDictionaryForm.value.kpi)
      const key2 = {
        costId: this.Kpis.length!=0? find2?.kpiCode:'',
        name: find2?.name,
        type: 'cost-kpi'
      }
      this.CostDictionaryForm.get('kpiValue').setValue('')
      this.selectedCostElementsTemp?.push(key2);
      this.selectedCostElements = this.selectedCostElementsTemp.slice();
      this.openCostDictionaryModal();
      this.resetCalculation();
      this.addNewRow();
    } else {
      this.toaster.warning('Please Make Sure, All Fields are Selected Above');
    }
  }

  openCostDictionaryModal(): void {
    // if (
    //   this.CostAddUpdateForm.value.frequency !== undefined &&
    //   this.CostAddUpdateForm.value.kpi !== undefined
    // ) {
    this.dialog.open(this.displayCostDictionaryModal, {
      height: '90%',
      width: '100%',
      disableClose: true,
    });
    // } else {
    //   this.toaster.error('Please Select Frequency and Kpi');
    // }
  }

  formReset(): void {
    this.Kpis = [];
    this.kpisTempList = [];
    this.puDesc = '';
    this.CostDictionaryForm.get('frequency')?.reset();
    this.CostDictionaryForm.get('kpi')?.reset();
    this.CostDictionaryForm.get('UnitNameList')?.reset();
    this.CostDictionaryForm.get('CurrencyName')?.reset();
    this.CostDictionaryForm.get('unitname')?.reset();
    this.CostDictionaryForm.get('frequency')?.reset();
    this.CostDictionaryForm.get('kpi')?.reset();
  }

  saveCostDictionary(): void {
    const { period } = this.CostDictionaryForm.value;
    const costelements = this.CostAddUpdateForm.value.costDictionaries;
    const withdataelements = costelements.filter(
      (x: any) => x.costElementId !== ''
    );
    this.calcList?.forEach((elem: any) => {
      if (elem.type === 'cost' || elem.type === 'cost-kpi') {
      }
    })
    let sum = 0;
    withdataelements?.forEach((elem2: any) => {
      sum = sum + (elem2.cost ? Number(elem2.cost) : 0);
    });
    this.calcList?.forEach((elem: any, index) => {
      if (elem.type === 'kpi') {
        this.calcList[index].cost = '1'
      }
      withdataelements?.forEach((elem2: any) => {
        if (elem.dpid === elem2.costElementId) {
          this.calcList[index].cost = elem2.cost ? elem2.cost : '0.000';
        }
      });
    });
    let formvalidate = '';
    let formula = '';

    this.calcList.forEach((elem, index) => {
      if (elem.type === 'Operator') {
        formvalidate = formvalidate + elem.dpid;
        formula = formula + elem.dpid;
      } else {
        formvalidate = formvalidate + '1';
        formula = formula + elem.dpid;
      }
    });
    try {
      if (eval(formvalidate)) {
        this.CostAddUpdateForm.get('formula')?.setValue(formvalidate);
        this.result = Number(eval(formvalidate)).toFixed(2);
      }
    } catch {
      this.toaster.error('error', 'This formula is not valid')
      return
      this.result = '';
    }
    if (withdataelements.length > 0) {
      const data = {
        plantId: parseInt(this.CostSearchForm.value.plant, 10),
        puId: this.unitid,
        frequencyId: parseInt(this.CostDictionaryForm.value.frequency, 10),
        kpiId: this.CostDictionaryForm.value.kpi,
        period,
        currencyId: this.CostDictionaryForm.value.CurrencyName,
        formula: JSON.stringify(this.calcList),
        value: this.CostDictionaryForm.value.kpiValue?.toString(),
        detail: withdataelements.map((element: any, index: number) => {
          return {
            costElementId: element.costElementId,
            cost: element.cost ? parseFloat(element.cost) : 0,
            budget: element.budget ? parseFloat(element.budget) : 0,
            glAccount: element.glAccount,
          };
        }),
        fromDate: new Date(this.CostDictionaryForm.value.fromDate),
        toDate: new Date(this.CostDictionaryForm.value.toDate)
      };
      const arraydata = [];
      arraydata.push(data);
      this.costService.addCostDictionary(arraydata).subscribe(
        (res: any) => {
          if (res.message === 'KPI Already Defined') {
            this.toaster.error(res.message);
            this.getAllCostDictionaries();
          }
          else {
            this.toaster.success(res.message);
            this.costDictionaryArray.clear();
            this.addNewRow();
            this.dialog.closeAll();
            this.getAllCostDictionaries();
            this.formMode = false;
          }


        },
        (error: any) => {
          this.toaster.error(error.error);
        }
      );
    } else {
      this.toaster.warning('Please Select atleast on cost element');
    }
  }

  exportAsXLSXForMain(): void {
    if (
      this.CostSearchForm.value.plant !== null &&
      this.CostDictionaryForm.value.frequency !== null &&
      this.CostDictionaryForm.value.kpi !== null &&
      this.CostDictionaryForm.value.periodFrom !== null &&
      this.CostDictionaryForm.value.periodTo !== null
    ) {
      this.tempData = [];
      for (let i = 0; i < this.costDictionaries.length; i++) {
        this.tempData[i] = {
          frequencyId: this.costDictionaries[i].frequencyId,
          kpiId: this.costDictionaries[i].kpiId,
          fromDate: moment(this.CostSearchForm.value.periodFrom).format(
            'DD-MM-YYYY ss:mm:HH'
          ),
          toDate: moment(this.CostSearchForm.value.periodTo).format(
            'DD-MM-YYYY ss:mm:HH'
          ),
        };
      }
      this.oleService.exportAsExcelFile(
        this.tempData,
        'CostDictionaryTemplate'
      );
    } else {
      this.toaster.error('Error', 'Please select mandatory fields');
    }
  }

  uploadFile(event: any): void {
    if (event.target.files.length > 0) {
      this.fileSave = event.target.files[0];
    }
  }

  saveUploadedFile(): void {
    // this.file= event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.fileSave);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      // tslint:disable-next-line:variable-name
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      this.filelist = [];
      this.filelist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.inputEmpty = false;
    };
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  exportAsXLSX(): void {
    const { UnitNameList, frequency, periodFrom, periodTo, kpi } =
      this.CostDictionaryForm.value;
    const costelements = this.CostAddUpdateForm.value.costDictionaries;
    const withdataelements = costelements.filter(
      (x: any) => x.costElementId !== ''
    );
    if (frequency !== '' && kpi !== '') {
      this.tempData = [];
      for (let i = 0; i < withdataelements.length; i++) {
        this.tempData[i] = {
          Kpis: this.CostDictionaryForm.value.kpi,
          CostElementName: this.CostDictionaryForm.value.costElementId,
          Budget: '',
          Cost: '',
        };
      }
      this.oleService.exportAsExcelFile(this.tempData, 'AttendanceTemplate');
    } else {
      this.toaster.error('Error', 'Please select mandatory fields');
    }
  }

  exportToExcel(): void {
    debugger;
    let excelDataArray = [];
    for (let master of this.costDictionaryData) {
      const childData = this.costDictionaryData.find(
        (x) => x.LossAnalysisCostDicId == master.LossAnalysisCostDicId
      );
      if (childData.Detail) {
        for (let child of childData.Detail) {
          const obj = {
            Plant: master.plantName,
            Unit: master.puName,
            Frequency: master.frequencyName,
            KPI: master.kpiName,
            Date: master.Period,
            CostElement: child.costElementName,
            Cost: child.Cost,
            Budget: child.Budget,
            GLAccount: child.GLAccount ? child.GLAccount : '',
          };
          excelDataArray.push(obj);
        }
      } else {
        const obj = {
          plantName: master.plantName,
          puName: master.puName,
          frequencyName: master.frequencyName,
          kpiName: master.kpiName,
          Period: master.Period,
        };
        excelDataArray.push(obj);
      }
    }
    this.oleService.exportAsExcelFile(excelDataArray, 'Cost Dictionary');
    // const excelParams = {
    //   headerRowHeight: 50,
    //   sheetName: 'CostDic',
    //   fileName: 'Cost Dictionary',
    //   allColumns: false,
    //   columnKeys: excelDataArray
    // };
    // this.gridApi.exportDataAsExcel(excelParams);
  }

  setNewValue(): void {
    const { UnitNameList, frequency, periodFrom, periodTo, kpi } =
      this.CostAddUpdateForm.value;
    if (
      frequency !== '' &&
      periodFrom !== '' &&
      periodTo !== '' &&
      kpi !== ''
    ) {
      this.plantid = parseInt(this.CostSearchForm.value.plant, 10);
      this.unitid = this.puid;
      this.CostAddUpdateForm.get('unitname')?.setValue(
        this.CostAddUpdateForm.value.unitname
      );
      // this.CostAddUpdateForm.get('frequency')?.setValue(this.frequencies.find(x => x.frequencyId === parseInt(frequency, 10))?.name);
      this.CostAddUpdateForm.get('frequency')?.setValue(
        this.CostAddUpdateForm.value.frequency
      );
      this.frequencyid = parseInt(frequency, 10);
      // this.CostAddUpdateForm.get('kpi')?.setValue(this.Kpis.find((x: { kpiId: number; }) => x.kpiId === parseInt(kpi, 10))?.name);
      this.CostAddUpdateForm.get('kpi')?.setValue(
        this.CostAddUpdateForm.value.kpi
      );
      this.kpiid = parseInt(kpi, 10);
      this.CostAddUpdateForm.get('periodfrom')?.setValue(
        new Date(periodFrom).toISOString()
      );
      this.CostAddUpdateForm.get('periodTo')?.setValue(
        new Date(periodTo).toISOString()
      );
    } else {
      this.toaster.warning('Please Make Sure, All Fields are Selected Above');
    }
  }

  cellClicked(params: any): void {
    const col = params.colDef.field;
    this.costDictionaryId = params.data.LossAnalysisCostDicId;
    this.ButtonType = '';
    if (col === 'Actions') {
      this.ButtonType = params.event.target.textContent;
      if (this.ButtonType === 'edit') {
        const key = {
          plantId: parseInt(this.CostSearchForm.value.plant, 10),
          frequencyId: params.data?.frequencyId,
        };
        const fromDate = new Date(params.data.FromDate + 'Z');
        const toDate = new Date(params.data.ToDate + 'Z');
        this.CostDictionaryForm.get('fromDate').setValue(fromDate)
        this.CostDictionaryForm.get('toDate').setValue(toDate)
        this.kpiService.getKpiFrequency(key).subscribe(
          (kpis) => {
            this.KpisTemp = kpis;
            this.Kpis = this.KpisTemp.slice();
            if (this.KpisTemp.length > 0) {
              this.CostDictionaryForm.get('kpi')?.setValue(this.KpisTemp[0].kpiCode);
            }
            if (!this.CostDictionaryForm.value.kpi) {
              this.isDisabled = true;
            } else {
              this.isDisabled = false;
            }
            this.detailData = [];
            this.formMode = true;
            this.ButtonType = '';
            this.plantid = params.data?.plantId;
            this.unitid = params.data?.puId;
            this.frequencyid = params.data?.frequencyId;
            this.kpiid = params.data?.kpiCode;
            this.CurrencyId = params.data?.CurrencyId;
            this.CostDictionaryForm.get('frequency')?.setValue(
              params.data?.frequencyId
            );
            this.CostDictionaryForm.get('kpi')?.setValue(params.data.kpiCode);
            this.CostDictionaryForm.get('kpiValue')?.setValue(params.data?.value);
            // this.onFrequencyChange();
            this.CostDictionaryForm.get('UnitNameList')?.setValue(
              params.data?.puId
            );
            this.CostDictionaryForm.get('CurrencyName')?.setValue(
              params.data?.CurrencyId
            );
            // this.CostAddUpdateForm.get('')

            this.CostDictionaryForm.get('period')?.setValue(
              params.data?.Period
            );
            // this.CostAddUpdateForm.get('periodTo')?.setValue(params.data?.PeriodTo);
            this.costDictionaryArray.clear();
            // const detailsArray: { costElementId: number; cost: any; glAccount: any; }[] = [];
            const detailsArray: { costElementId: number }[] = [];

            if (params.data?.Detail == undefined) {
              const detail = {
                costDictionaryDetailId: null,
                costElementId: null,
                cost: null,
                budget: null,
                glAccount: null,
              };
              detailsArray.push(detail);
              this.addNewRow();
            } else {
              this.detailData = params.data?.Detail.map((x) => x.costElementId);
              params.data?.Detail.forEach((element: any) => {
                const detail = {
                  costDictionaryDetailId: element.lacDdetailId,
                  costElementId: element.costElementId,
                  cost: element.Cost,
                  budget: element.Budget,
                  glAccount: element.GLAccount,
                };
                detailsArray.push(detail);
                this.addNewRow();
              });
            }
            this.CostAddUpdateForm.get('costDictionaries')?.setValue(
              detailsArray
            );
            this.selectedCostElements = [];
            this.selectedCostElementsTemp = [];
            this.detailData?.forEach((element) => {
              const value = this.costElements?.find(
                (x: any) => x.costId === element
              );
              if (value?.costId) {
                const key = {
                  costId: value.costId,
                  name: value.name,
                  type: 'cost'
                }
                this.selectedCostElementsTemp?.push(key);
              }
            });
            const find2 = this.Kpis.find((x: any) => x.kpiCode === params.data?.kpiCode)
            const key2 = {
              costId: find2?.kpiCode,
              name: find2?.name,
              type: 'cost-kpi'
            }
            this.selectedCostElementsTemp?.push(key2);
            this.selectedCostElements = this.selectedCostElementsTemp.slice();
            this.calcList = [];
            this.result = '0';
            if (params.data.formula) {
              this.calcList = JSON.parse(params.data.formula);
            }
            if (params.data.value) {
              this.result = params.data.value;
            }
            // this.calcList?.forEach((elem) => {
            //   if(elem.type === 'cost-kpi')
            //   {
            //     const key = {
            //       costId: elem.dpid,
            //       name: elem.dpName,
            //       type: 'cost-kpi'
            //     }
            //     this.selectedCostElements.push(key);
            //   }
            // })
            this.openCostDictionaryModal();
            //   this.CostDictionaryForm.get('kpi').setValue(kpis[0].kpiId)
          },
          (error) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (this.ButtonType === 'delete') {
        this.ButtonType = '';
        this.deleteCostDictionary(this.costDictionaryId);
      }
    }
  }

  updateCostDictionary(): void {
    const { period, costDictionaries } = this.CostAddUpdateForm.value;
    const withdataelements = costDictionaries.filter(
      (x: any) => x.costElementId !== ''
    );
    let sum = 0;
    withdataelements?.forEach((elem2: any) => {
      sum = sum + (elem2.cost ? Number(elem2.cost) : 0);
    });
    this.calcList?.forEach((elem: any, index) => {
      if (elem.type === 'kpi') {
        this.calcList[index].cost = '1'
      }
      withdataelements?.forEach((elem2: any) => {
        if (elem.dpid === elem2.costElementId) {
          this.calcList[index].cost = elem2.cost ? elem2.cost : '0.000';
        }
      });
    });
    let formvalidate = '';
    let formula = '';
    this.calcList.forEach((elem, index) => {
      if (elem.type === 'Operator') {
        formvalidate = formvalidate + elem.dpid;
        formula = formula + elem.dpid;
      } else {
        formvalidate = formvalidate + '1';
        formula = formula + elem.dpid;
      }
    });
    try {
      if (eval(formvalidate)) {
        this.CostAddUpdateForm.get('formula')?.setValue(formvalidate);
        this.result = Number(eval(formvalidate)).toFixed(2);
      }
    } catch {
      this.result = '';
      this.toaster.error('error', 'Formula is not valid')
      return
    }

    if (withdataelements.length > 0) {
      const data = {
        lossAnalysisCostDicId: this.costDictionaryId,
        plantId: this.plantid,
        puId: this.CostDictionaryForm.get('UnitNameList').value,
        frequencyId: this.CostDictionaryForm.get('frequency').value,
        kpiId: this.CostDictionaryForm.get('kpi').value,
        currencyId: this.CostDictionaryForm.get('CurrencyName').value,
        formula: JSON.stringify(this.calcList),
        value: this.CostDictionaryForm.value.kpiValue?.toString(),
        period: this.CostDictionaryForm.get('period').value,
        fromDate: new Date(this.CostDictionaryForm.value.fromDate),
        toDate: new Date(this.CostDictionaryForm.value.toDate),
        detail: withdataelements.map((element: any, index: number) => {
          return {
            lacDdetailId: element.costDictionaryDetailId,
            costElementId: element.costElementId,
            cost: parseFloat(
              element.cost == null
                ? element.cost
                : element.cost.toString().replace(/,/gi, '')
            ),
            budget: parseFloat(
              element.budget == null
                ? element.budget
                : element.budget.toString().replace(/,/gi, '')
            ),
            glAccount: element.glAccount,
          };
        }),
      };
      this.costService.updateCostDictionary(data).subscribe(
        (res) => {
          if (res.message === 'KPI Already Defind') {
            this.toaster.error('KPI Already Defind')
          }
          else {
            this.toaster.success(res.message);
            this.costDictionaryArray.clear();
            this.addNewRow();
            this.dialog.closeAll();
            this.CostDictionaryForm.reset();
            this.getAllCostDictionaries();
            this.formMode = false;
            if (this.KpisTemp.length > 0) {
              this.CostDictionaryForm.get('kpi')?.setValue(this.KpisTemp[0].kpiCode);
            }
          }

        },
        ({ error }) => {
          this.toaster.error(error.message);
        }
      );
    } else {
      this.toaster.warning('Please Select atleast on cost element');
    }
  }

  deleteCostDictionary(id: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Cost Dictionary!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          lossAnalysisCostDicId: id,
        };
        this.costService.deleteCostDictionary(key).subscribe(
          (success) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your selected Cost Dictionary has been deleted.',
              icon: 'success',
              timer: 800,
              showConfirmButton: false,
            });
            this.getAllCostDictionaries();
          },
          (error: any) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      }
    });
  }
  // cost dictionary operations end
  // Icon
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.CostSearchForm.get('group')?.value,
    };
    // this.MachineId = null;
    this.costService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plantsTemp = data.DepartList;
        this.plants = this.plantsTemp.slice();
        this.CostSearchForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getKpiByPlant();
        this.getFrequencies();
        this.getCostElement();
        this.getAllCostDictionaries();
        this.GetUnitsByPlant();
        this.GetCurrencyData();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  // Get Units By Plant
  GetUnitsByPlant(): void {
    const data = {
      ptId: parseInt(this.CostSearchForm.value.plant, 10),
    };
    this.kpiDataPointService.GetUnitsByPlant(data).subscribe((res: any) => {
      var obj = { puId: 0, puDesc: 'None' };
      res.unshift(obj);
      this.unitListTemp = res;
      this.unitList = this.unitListTemp.slice();
      this.CostDictionaryForm.get('UnitNameList')?.setValue(
        this.unitList[0].puId
      );
    });
  }

  openUnit(): any {
    this.isPlantGroupModelVisible = true;
    const key = {
      EGId: this.CostSearchForm.value.group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.UnitNode = filterData(this.plantGroupSource.data, 'Unit');
        this.expandAllNode();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );

    // tslint:disable-next-line:typedef
    function filterData(data: PlantGroupElement[], title: string) {
      const r = data.filter((o: any) => {
        Object.keys(o).forEach((e: any) => {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
  }
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  onClickUnit(node: any): void {
    this.isPlantGroupModelVisible = false;
    this.UnitName = node.text;
    this.unitid = node.PrimaryId;
    this.puid = node.PrimaryId;
    this.CostDictionaryForm.get('UnitNameList')?.setValue(node.text);
    this.rowData = this.EmptyData;
  }
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }
  getDetailRowData(params: any): void {
    setTimeout(() => {
      params.successCallback(params.data.callRecords);
    }, 1000);
  }
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout('autoHeight');
    const e: HTMLElement = document.querySelector('#eGrid') as HTMLElement;
    if (e !== null) {
      e.style.height = '';
    }
  }
  onFirstDataRendered(params: any): void {
    setTimeout(() => {
      const node1 = params.api.getDisplayedRowAtIndex(1);
      const node2 = params.api.getDisplayedRowAtIndex(2);
      node1.setExpanded(true);
      node2.setExpanded(true);
    }, 0);
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  openFile() {
    this.fileInput.nativeElement.click();
  }
  uploadedFile(event: any) {
    if (this.CostSearchForm.invalid) {
      this.dialogRef.close();
      this.toaster.error(`Group And Plant Fields Required`, 'Error', {
        timeOut: 2000,
      });
    } else {
      this.dialogRef.close();
      this.excelData = [];
      this.spinner.show();
      this.excelData = event.target.files[0];
      const fileName = this.excelData.name;
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(this.excelData);
      fileReader.onload = (e) => {
        let arrayBuffer: any = fileReader.result;
        var data = new Uint8Array(arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join('');
        var workbook = XLSX.read(bstr, { type: 'binary' });
        var sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[sheet_name];
        this.excelData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        this.validateSheet();
      };
    }
  }
  async validateSheet() {
    debugger;
    try {
      let CostDictRow = 1;
      var keydata = [];

      if (this.excelData) {
        for (let obj of this.excelData) {
          
           if (!obj['KPI Code *']) {
            if (!obj['KPI Code *']) {
              this.toaster.error(
                `KPI Code can't be empty on row ${CostDictRow} on CostDict Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            this.excelData = [];
            this.spinner.hide();
            return;
          } else if (!obj['KPI Value *']) {
            if (!obj['KPI Value *']) {
              this.toaster.error(
                `KPI Value can't be empty on row ${CostDictRow} on CostDict Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            this.excelData = [];
            this.spinner.hide();
            return;
          } else if (!obj['Cost *']) {
            if (!obj['Cost *']) {
              this.toaster.error(
                `Cost can't be empty on row ${CostDictRow} on CostDict Sheet`,
                'Error',
                {
                  timeOut: 3000,
                }
              );
            }
            this.excelData = [];
            this.spinner.hide();
            return;
          } else if (!obj['Cost Element *']) {
            if (!obj['Cost Element *']) {
              this.toaster.error(
                `Cost Element can't be empty on row ${CostDictRow} on CostDict Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            this.excelData = [];
            this.spinner.hide();
            return;
          } else if (!obj['GL Account *']) {
            if (!obj['GL Account *']) {
              this.toaster.error(
                `GL Account can't be empty on row ${CostDictRow} on CostDict Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            this.excelData = [];
            this.spinner.hide();
            return;
          } 
          else if (!obj['Budget *']) {
            if (!obj['Budget *']) {
              this.toaster.error(
                `Budget can't be empty on row ${CostDictRow} on CostDict Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            this.excelData = [];
            this.spinner.hide();
            return;
          } 
          else {
            let plantobj: any;
            let unitobj: any;
            let KPICodeobj: any;
            let kpiobj: any;
            let costelementobj: any;

            var data = {
              plantId: '',
              puId: '',
              KPICode: '',
              kpiValue: '',
              period: '',
              detail: [],
              currency:'',
              FromDate:'',
              ToDate:'',
            };

            var data1 = {
              costElementId: '',
              cost: '',
              budget: '',
              glAccount: '',
            };
            data.plantId = this.CostSearchForm.value.plant;
            unitobj = this.unitList.find((unit: any) => {
              const a = obj['Unit']?obj['Unit'].toLowerCase():'';
              const b = unit.puDesc.toLowerCase();
              return a == b;
            });
            if (true) {
              data.puId = unitobj?  unitobj.puId:null;
              KPICodeobj = this.kpiList?.find((kpicode1: any) => {
                const a = obj['KPI Code *'].toLowerCase();
                const b = kpicode1.kpiCode.toLowerCase();
                return a == b;
              });
              if (KPICodeobj) {
                data.KPICode = KPICodeobj.kpiCode;
                // const key = {
                //   plantId: parseInt(this.CostSearchForm.value.plant, 10),
                //   frequencyId: parseInt(KPICodeobj.kpiCode, 10),
                // };
                // this.kpiService.getKpiFrequency(key).subscribe(
                //   (kpis) => {
                //     this.Kpi = kpis;
                //   },
                //   (error) => {
                //     this.toaster.error('Error', error.error.message);
                //   }
                // );
               
                // kpiobj = this.Kpi.find((kpi: any) => {
                //   const a = obj['KPI'].toLowerCase();
                //   const b = kpi.name.toLowerCase();
                //   return a === b;
                // });
                 kpiobj = KPICodeobj.kpiCode;
                
                if (kpiobj) {
                  if (keydata.length) {
                    costelementobj = this.costElements.find((costelm: any) => {
                      const a = obj['Cost Element *']?.toLowerCase();
                      const b = costelm.name?.toLowerCase();
                      return a == b;
                    });
                    let lengt1 =keydata.length;
                    for (let i = 0; i < lengt1; i++) {
                      if (
                        keydata[i].KPICode == kpiobj 
                        //&&
                        // keydata[i].period ==
                        // new Date(
                        //   Math.round((obj['Date'] - 25569) * 86400 * 1000)
                        // ).toString()
                      ) {
                        for (let d of keydata[i].detail) {
                          if (d.costElementId == costelementobj.costId) {
                            this.toaster.error(
                              `Same Data is Repeated on row ${CostDictRow} on CostDict Sheet`,
                              'Error',
                              {
                                timeOut: 2000,
                              }
                            );
                            this.excelData = [];
                            this.spinner.hide();
                            return;
                          }
                        }
                      }
                    }
                  }
                  data.kpiValue =obj['KPI Value *'] ;
                  // data.period = this.datepipe.transform(
                  //   new Date(
                  //     Math.round((obj['Date'] - 25567 + 1) * 86400 * 1000)
                  //   ).toString(),
                  //   'yyyy-MM-dd HH:mm:ss'
                  // );
                  kpiobj = null;
                  if (this.costDictionaries) {
                    kpiobj = this.costDictionaries?.find((kpi: any) => {
                      const a = obj['KPI Code *'].toLowerCase();
                      const b = kpi.kpiCode.toLowerCase();
                      const c = kpi.puName? kpi.puName.toLowerCase():"";
                      const d = obj['Unit']? obj['Unit'].toLowerCase():"";
                      // const e = new Date(
                      //   Math.round((obj['Date'] - 25567 + 1) * 86400 * 1000)
                      // ).getMonth();
                      // const f = new Date(kpi.Period).getMonth();
                     // return a === b && c === d && e === f;
                     return a === b || c === d ;
                    });
                  }
                  if (kpiobj) {
                    // var excelmonth = new Date(
                    //   Math.round((obj['Date'] - 25567 + 1) * 86400 * 1000)
                    // ).getMonth();
                    // var excelyear = new Date(
                    //   Math.round((obj['Date'] - 25567 + 1) * 86400 * 1000)
                    // ).getFullYear();
                    // var gridmonth = new Date(kpiobj.Period).getMonth();
                    // var gridyear = new Date(kpiobj.Period).getFullYear();
                    //if (excelmonth == gridmonth && excelyear == gridyear) {
                      if ( kpiobj.Detail &&
                        kpiobj.Detail[0].costElementName?.toLowerCase() ==
                        obj['Cost Element *']?.toLowerCase()
                      ) {
                        this.toaster.error(
                          `CostElement ${obj['Cost Element *']} already exist in database on row ${CostDictRow} on CostDict Sheet`,
                          'Error',
                          {
                            timeOut: 2000,
                          }
                        );
                        this.excelData = [];
                        this.spinner.hide();
                        return;
                      } else {
                        costelementobj = this.costElements.find(
                          (costelm: any) => {
                            const a = obj['Cost Element *']?.toLowerCase();
                            const b = costelm.name.toLowerCase();
                            return a == b;
                          }
                        );
                        if (costelementobj) {
                          data1.costElementId = costelementobj.costId;
                          // const key = { costelementid: costelementobj.costId };
                          // this.costService
                          //   .GetGLAccountByCostElementId(key)
                          //   .subscribe((res: any) => {
                          //     this.glAccount = res[0].GLAccount;
                          //   });

                       let glAccountObj= this.costElements.find((a)=> a.costId== costelementobj.costId);
                       this.glAccount = glAccountObj? glAccountObj.GLAccount:null;
                          this.delay(1000);
                          if (this.glAccount == obj['GL Account *']) {
                            data1.glAccount = this.glAccount;
                            data1.cost = obj['Cost *'] ? obj['Cost *'] : '0.000';
                            data1.budget = obj['Budget *']
                              ? obj['Budget *']
                              : '0.000';
                            data.detail.push(data1);
                            
                          } else {
                            this.toaster.error(
                              `GL Account ${obj['GL Account *']} not found in database on row ${CostDictRow} on CostDict Sheet`,
                              'Error',
                              {
                                timeOut: 2000,
                              }
                            );
                            this.excelData = [];
                            this.spinner.hide();
                            return;
                          }
                        } else {
                          this.toaster.error(
                            `Cost Element ${obj['Cost Element *']} not found in database on row ${CostDictRow} on CostDict Sheet`,
                            'Error',
                            {
                              timeOut: 2000,
                            }
                          );
                          this.excelData = [];
                          this.spinner.hide();
                          return;
                        }
                      }
                    //} 
                  //  else {
                      costelementobj = this.costElements.find(
                        (costelm: any) => {
                          const a = obj['Cost Element *']?.toLowerCase();
                          const b = costelm.name?.toLowerCase();
                          return a == b;
                        }
                      );
                      if (costelementobj) {
                        data1.costElementId = costelementobj.costId;
                        const key = { costelementid: costelementobj.costId };
                        // this.costService
                        //   .GetGLAccountByCostElementId(key)
                        //   .subscribe((res: any) => {
                        //     this.glAccount = res[0].GLAccount;
                        //   });
                        let glAccountObj= this.costElements.find((a)=> a.costId== costelementobj.costId);
                        this.glAccount = glAccountObj? glAccountObj.GLAccount:null;
                          this.delay(1000);
                        if (this.glAccount == obj['GL Account *']) {
                          data1.glAccount = this.glAccount;
                          data1.cost = obj['Cost *'] ? obj['Cost *'] : '0.000';
                          data1.budget = obj['Budget *']
                            ? obj['Budget *']
                            : '0.000';
                          data.detail.push(data1);
                        } else {
                          this.toaster.error(
                            `GL Account ${obj['GL Account *']} not found in database on row ${CostDictRow} on CostDict Sheet`,
                            'Error',
                            {
                              timeOut: 2000,
                            }
                          );
                          this.excelData = [];
                          this.spinner.hide();
                          return;
                        }
                      } else {
                        this.toaster.error(
                          `Cost Element ${obj['Cost Element *']} not found in database on row ${CostDictRow} on CostDict Sheet`,
                          'Error',
                          {
                            timeOut: 2000,
                          }
                        );
                        this.excelData = [];
                        this.spinner.hide();
                        return;
                      }
                    //}
                  } else {
                    costelementobj = this.costElements.find((costelm: any) => {
                      const a = obj['Cost Element *']?.toLowerCase();
                      const b = costelm.name?.toLowerCase();
                      return a == b;
                    });
                    if (costelementobj) {
                      data1.costElementId = costelementobj.costId;
                      // const key = { costelementid: costelementobj.costId };
                      // this.costService
                      //   .GetGLAccountByCostElementId(key)
                      //   .subscribe((res: any) => {
                      //     this.glAccount = res[0].GLAccount;
                      //   });
                      let glAccountObj= this.costElements.find((a)=> a.costId== costelementobj.costId);
                      this.glAccount = glAccountObj? glAccountObj.GLAccount:null;
                   this.delay(1000);
                      if (this.glAccount == obj['GL Account *']) {
                        data1.glAccount = this.glAccount;
                        data1.cost = obj['Cost *'] ? obj['Cost *'] : '0.000';
                        data1.budget = obj['Budget *'] ? obj['Budget *'] : '0.000';
                        data.detail.push(data1);
                      } else {
                        this.toaster.error(
                          `GL Account ${obj['GL Account *']} not found in database on row ${CostDictRow} on CostDict Sheet`,
                          'Error',
                          {
                            timeOut: 2000,
                          }
                        );
                        this.excelData = [];
                        this.spinner.hide();
                        return;
                      }
                    } else {
                      this.toaster.error(
                        `Cost Element ${obj['Cost Element *']} not found in database on row ${CostDictRow} on CostDict Sheet`,
                        'Error',
                        {
                          timeOut: 2000,
                        }
                      );
                      this.excelData = [];
                      this.spinner.hide();
                      return;
                    }
                  }
                } else {
                  this.toaster.error(
                    `KPI Value ${obj['KPI Value *']} not found in database on row ${CostDictRow} on CostDict Sheet`,
                    'Error',
                    {
                      timeOut: 2000,
                    }
                  );
                  this.excelData = [];
                  this.spinner.hide();
                  return;
                }
              } else {
                this.toaster.error(
                  `KPI Code ${obj['KPI Code']} not found in database on row ${CostDictRow} on CostDict Sheet`,
                  'Error',
                  {
                    timeOut: 2000,
                  }
                );
                this.excelData = [];
                this.spinner.hide();
                return;
              }
            }
            //  else {
            //   this.toaster.error(
            //     `Unit ${obj['Machine']} not found in database on row ${CostDictRow} on CostDict Sheet`,
            //     'Error',
            //     {
            //       timeOut: 2000,
            //     }
            //   );
            //   this.excelData = [];
            //   this.spinner.hide();
            //   return;
            // }
            // }else {
            //   this.toaster.error(`Plant ${obj['Plant']} not found in database on row ${CostDictRow} on CostDict Sheet`, 'Error', {
            //     timeOut: 2000,
            //   });
            //   this.excelData = [];
            //   this.spinner.hide();
            //   return;
            // }
            if (keydata.length > 0) {
             let lengt2 =keydata.length;
              for (let i = 0; i < lengt2; i++) {
                if (
                  keydata[i].period === data.period &&
                  keydata[i].kpiValue === data.kpiValue &&
                  keydata[i].KPICode === data.KPICode &&
                  keydata[i].puId === data.puId
                ) {
                 
                  keydata[i].detail.push(data.detail[0]);
                } else {
                  let currency = this.Currency.find((x) => x.CurrencyName === obj['Currency']);

                  let mappedResult = currency ? currency.CurrencyId : null;
                  data.currency = mappedResult;
                    
                
                  // data.FromDate = obj['From Date (DD/MM/YYYY)']?   this.datepipe.transform(
                  //   new Date(
                  //    obj['From Date (DD/MM/YYYY)'] 
                  //   ).toString(),
                  //   'yyyy-MM-dd HH:mm:ss'
                  // ):null ; 
              if(obj['From Date (YYYY-MM-DD)']){
                let dateMomentObject = moment(obj['From Date (YYYY-MM-DD)'], 'YYYY-MM-DD'); // 1st argument - string, 2nd argument - format
                // let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                  // dateObject.toString()
                  data.FromDate =moment(dateMomentObject).format('YYYY-MM-DD');
                }
                if(obj['To Date (YYYY-MM-DD)']){
                  let dateMomentObject1 = moment(obj['To Date (YYYY-MM-DD)'], 'YYYY-MM-DD'); // 1st argument - string, 2nd argument - format
                  // let dateObject1 = dateMomentObject1.toDate(); // convert moment.js object to Date object
                  // dateObject1.toString()
                    // data.ToDate =this.datepipe.transform(dateObject1, 'YYYY-MM-DD');
                    data.ToDate =moment(dateMomentObject1).format('YYYY-MM-DD');
                    }
                  keydata.push(data);
                }
              }
            } else {
              let currency = this.Currency.find((x) => x.CurrencyName === obj['Currency']);

              let mappedResult = currency ? currency.CurrencyId : null;
              data.currency = mappedResult;
              // data.FromDate = obj['From Date (YYYY-MM-DD)']?   this.datepipe.transform(
              //   new Date(
              //    obj['From Date (YYYY-MM-DD)'] 
              //   ).toString(),
              //   'YYYY-MM-DD HH:mm:ss'
              // ):null ; 

              if(obj['From Date (YYYY-MM-DD)']){
              let dateMomentObject = obj['From Date (YYYY-MM-DD)']; // 1st argument - string, 2nd argument - format
              // let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                // dateObject.toString()
                // data.FromDate =this.datepipe.transform(dateObject, 'YYYY-MM-DD');
                data.FromDate =moment(dateMomentObject).format('YYYY-MM-DD');
              }
              if(obj['To Date (YYYY-MM-DD)']){
                let dateMomentObject1 = moment(obj['To Date (YYYY-MM-DD)'], 'YYYY-MM-DD'); // 1st argument - string, 2nd argument - format
                // let dateObject1 = dateMomentObject1.toDate(); // convert moment.js object to Date object
                // dateObject1.toString()
                  // data.ToDate =this.datepipe.transform(dateObject1, 'YYYY-MM-DD');
                  data.ToDate =moment(dateMomentObject1).format('YYYY-MM-DD');

              }

              // data.ToDate = obj['To Date (DD/MM/YYYY)']? this.datepipe.transform(
              //   new Date(
              //     Math.round((obj['To Date (MM/DD/YYYY)'] - 25567 + 1) * 86400 * 1000)
              //   ).toString(),
              //   'yyyy-MM-dd HH:mm:ss'
              // ):null ;

              keydata.push(data);
            }
          }
          CostDictRow++;
        }
      }
      let upload = 0;
      this.costService.CostDictionaryFileUploding(keydata).subscribe(
        (res) => {
          this.getAllCostDictionaries();
          this.formMode = false;
          if (res.message === 'Added Successfully') {
            this.toaster.success('Success, Excel Sheet Uploaded Successfuly');
          }
        },
        ({ error }) => {
          this.toaster.error('error');
        }
      );
      this.spinner.hide();
    } catch (err: any) {
      this.toaster.error(err, 'Error');
      this.spinner.hide();
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  addOperator(oper: any): void {
    const key = {
      dpid: oper,
      dpName: oper,
      type: 'Operator',
    };
    this.calcList.push(key);
    const costelements = this.CostAddUpdateForm.value.costDictionaries;
    const withdataelements = costelements.filter(
      (x: any) => x.costElementId !== ''
    );
    this.calcList?.forEach((elem: any, index) => {
      withdataelements?.forEach((elem2: any) => {
        if (elem.dpid === elem2.costElementId) {
          this.calcList[index].cost = elem2.cost ? elem2.cost : '0.000';
        }
      });
    });
    let formvalidate = '';
    this.calcList.forEach((elem, index) => {
      if (elem.type === 'Operator') formvalidate = formvalidate + elem.dpid;
      else {
        formvalidate = formvalidate + elem.cost;
      }
    });
    try {
      if (eval(formvalidate)) {
        this.CostAddUpdateForm.get('formula')?.setValue(formvalidate);
        this.result = Number(eval(formvalidate)).toFixed(2);
      }
    } catch {
      this.result = '0';
    }
  }

  resetCalculation(): void {
    this.calcList = [];
    this.result = '0';
    if (this.costElement != undefined) {
      this.costElement.value = 0;
    }
  }

  onSelectedCostElementChange(event: any) {
    const find = event.value
    if (find.type === 'cost') {
      const key = {
        dpid: find.costId,
        dpName: find.name,
        type: 'cost',
      };
      this.calcList.push(key);
      const costelements = this.CostAddUpdateForm.value.costDictionaries;
      const withdataelements = costelements.filter(
        (x: any) => x.costElementId !== ''
      );
      this.calcList?.forEach((elem: any, index) => {
        withdataelements?.forEach((elem2: any) => {
          if (elem.dpid === elem2.costElementId) {
            this.calcList[index].cost = elem2.cost ? elem2.cost : '0.000';
          }
        });
      });
    }
    else if (find.type === 'cost-kpi') {
      const key = {
        dpid: find.costId,
        dpName: find.name,
        type: 'cost-kpi',
        cost: this.CostDictionaryForm.value.kpiValue?.toString()
      };
      this.calcList.push(key)
    }
    let formvalidate = '';
    this.calcList.forEach((elem, index) => {
      if (elem.type === 'Operator') formvalidate = formvalidate + elem.dpid;
      else {
        formvalidate = formvalidate + elem.cost;
      }
    });
    try {
      if (eval(formvalidate)) {
        this.CostAddUpdateForm.get('formula')?.setValue(formvalidate);
        this.result = Number(eval(formvalidate)).toFixed(2);
      }
    } catch {
      this.result = '0';
    }
  }

  dragStart(index: any): void {
    this.PrevIndex = index;
  }

  mouseEnter(index: any): void {
    this.currentIndex = index;
  }

  removeKeyword(index: any): void {
    this.calcList.splice(index, 1);
    const costelements = this.CostAddUpdateForm.value.costDictionaries;
    const withdataelements = costelements.filter(
      (x: any) => x.costElementId !== ''
    );
    this.calcList?.forEach((elem: any, index) => {
      withdataelements?.forEach((elem2: any) => {
        if (elem.dpid === elem2.costElementId) {
          this.calcList[index].cost = elem2.cost ? elem2.cost : '0.000';
        }
      });
    });
    let formvalidate = '';
    this.calcList.forEach((elem, index) => {
      if (elem.type === 'Operator') formvalidate = formvalidate + elem.dpid;
      else {
        formvalidate = formvalidate + elem.cost;
      }
    });
    try {
      if (eval(formvalidate)) {
        this.CostAddUpdateForm.get('formula')?.setValue(formvalidate);
        this.result = Number(eval(formvalidate)).toFixed(2);
      }
    } catch {
      this.result = '0';
    }
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.calcList, this.PrevIndex, this.currentIndex);
    const costelements = this.CostAddUpdateForm.value.costDictionaries;
    const withdataelements = costelements.filter(
      (x: any) => x.costElementId !== ''
    );
    this.calcList?.forEach((elem: any, index) => {
      withdataelements?.forEach((elem2: any) => {
        if (elem.dpid === elem2.costElementId) {
          this.calcList[index].cost = elem2.cost ? elem2.cost : '0.000';
        }
      });
    });
    let formvalidate = '';
    this.calcList.forEach((elem, index) => {
      if (elem.type === 'Operator') formvalidate = formvalidate + elem.dpid;
      else {
        formvalidate = formvalidate + elem.cost;
      }
    });
    try {
      if (eval(formvalidate)) {
        this.CostAddUpdateForm.get('formula')?.setValue(formvalidate);
        this.result = Number(eval(formvalidate)).toFixed(2);
      }
    } catch {
      this.result = '0';
    }
  }

  costChange(): void {
    const costelements = this.CostAddUpdateForm.value.costDictionaries;
    const withdataelements = costelements.filter(
      (x: any) => x.costElementId !== ''
    );
    this.calcList?.forEach((elem: any, index) => {
      withdataelements?.forEach((elem2: any) => {
        if (elem.dpid === elem2.costElementId) {
          this.calcList[index].cost = elem2.cost ? elem2.cost : '0.000';
        }
      });
    });
    let formvalidate = '';
    this.calcList.forEach((elem, index) => {
      if (elem.type === 'Operator') formvalidate = formvalidate + elem.dpid;
      else {
        formvalidate = formvalidate + elem.cost;
      }
    });
    try {
      if (eval(formvalidate)) {
        this.CostAddUpdateForm.get('formula')?.setValue(formvalidate);
        this.result = Number(eval(formvalidate)).toFixed(2);
      }
    } catch {
      this.result = '0';
    }
  }

  getKpiByPlant() {
    const key = { ptId: parseInt(this.CostSearchForm.value.plant, 10) };
    this.kpiTreeservice.getKpisByPlant(key).subscribe(
      (res: any) => {
        this.kpiListTempPlantWise = res;
        this.kpiList = this.kpiListTempPlantWise.slice();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onChangeKpi(event: any) {
    const find = this.kpiListTempPlantWise?.find(
      (x: any) => x.kpiId === event.value
    );

    const key = {
      dpid: event.value,
      dpName: find.name,
      type: 'kpi',
    };
    this.calcList.push(key);
  }

  kpiValueChange() {
    this.calcList?.forEach((elem: any, index) => {
      if (elem.type === 'cost-kpi') {
        this.calcList[index].cost = this.CostDictionaryForm.value.kpiValue?.toString();
      }
    });
  }
  testDate() {

  }


  ExportTOExcelKPICostElement() {
    
    const workBook = XLSX.utils.book_new(); 
    this.ExportKPI =[]
    this.ExportCostElement = []
    for (const type of this.kpiList) {
      const ExportData = { ['KPI Name']: type.name, ['KPI Code']: type.kpiCode, ['Frequency']: type.frequencyName };
      this.ExportKPI.push(ExportData);
    }
    for (const tool of this.costElements) {
      const ExportData = { ['Cost Element Name']: tool.name, ['Cost Center Name']: tool.costCenterName ,['GL Account']: tool.GLAccount};
      this.ExportCostElement.push(ExportData);
    }

      const KPIS = XLSX.utils.json_to_sheet(this.ExportKPI);
      const CostElement = XLSX.utils.json_to_sheet(this.ExportCostElement);
       
      XLSX.utils.book_append_sheet(workBook, KPIS, 'KPIS');
      XLSX.utils.book_append_sheet(workBook, CostElement, 'Cost Element');
      
      XLSX.writeFile(workBook, `Export_KPI_CostElements.xlsx`); // initiate a file   
    
   
  }
  
}
