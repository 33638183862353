import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DayOff } from 'src/app/models/dayOff';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import { UserManu } from 'src/app/models/userManu';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import Swal from 'sweetalert2';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-dayoff',
  templateUrl: './dayoff.component.html',
  styleUrls: ['./dayoff.component.scss'],
})
export class DayoffComponent implements OnInit {
  @Input() dayPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<DayOff>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  plants!: Plantbygroup[];
  dayOffForm!: FormGroup;
  formMode!: boolean;
  plantId!: number;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  dayOffId!: number;

  dayOffModelVisible = false;

  dayOffColumns: string[] = [ 'dayOffActions','plant', 'dayOff',];
  dayOffData!: MatTableDataSource<DayOff>;
  IsAccess: any;
  IsWrite: any;

  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!:any;
  breadcrumList: any;
  constructor(
    private kpiService: KpimasterService,
    private toaster: ToastrService,
    private platModelService: EnterPriseGroupService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private translater: TranslaterService,
    private usermanagementService: UserManagementService,
  ) {
  }
    ngOnInit(): void {
    this.GetBreadcrumList();
    //this.getLangWords();
    this.GetMenuAccess();

    this.isRead = this.dayPermissions.IsAccess;
    this.isWrite = this.dayPermissions.IsWrite;
    this.isUpdate = this.dayPermissions.IsWrite;
    this.isDelete = this.dayPermissions.IsWrite;
    this.dayOffForm = this.fb.group({
      dayOff: ['', Validators.required],
      plant: ['', Validators.required],
    });
  }

  

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  
//menu and breadcrumb
GetBreadcrumList(): any {
  const obj = {
    MenuId:
      localStorage.getItem('O3RightMenuId') === null
        ? 1
        : localStorage.getItem('O3RightMenuId'),
  };
  // tslint:disable-next-line: align
  this.usermanagementService.GetBreadcrumList(obj).subscribe(
    (data: any) => {
      this.breadcrumList = data.BreadcrumList;
      this.moduleId = this.breadcrumList[0].id;
      this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      this.getLangWords();
    },
    (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    }
  );
}
  
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getPaginator(): void {
    setTimeout(() => {
      this.dayOffData.paginator = this.paginator;
      this.dayOffData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dayOffData.filter = filterValue.trim().toLowerCase();

    if (this.dayOffData.paginator) {
      this.dayOffData.paginator.firstPage();
    }
  }
  getDayOffData(): void {
    this.spinner.show();

    const key = {
      plantId: this.plantId,
    };
    this.kpiService.getMasterDayOff(key).subscribe(
      (data) => {
    this.spinner.hide();

        this.dayOffData = new MatTableDataSource<DayOff>(data);
        this.getPaginator();
      },
      (error) => {
    this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }
  openModal(): void {
    if (this.isWrite) {
      this.resetForm();
      this.dayOffForm.get('plant')?.setValue(this.plantId);
      this.dayOffModelVisible = true;
      this.formMode = true;
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  GetMasterDayOffData(key: any): any {
    this.spinner.show();
    this.plantId = key.plantId;
    this.kpiService.getMasterDayOff(key).subscribe(
      (data) => {
        this.dayOffData = new MatTableDataSource<DayOff>(data);
        this.getPlants();
        this.getPaginator();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  getPlants(): void {
    this.kpiService.getAllPlants().subscribe(
      (plants) => {
        this.plants = plants;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  updateForm(): void {
    this.spinner.show();

    const dayOff = {
      dayOffId: this.dayOffId,
      plantId: this.dayOffForm.value.plant,
      dayOff: this.dayOffForm.value.dayOff,
    };
    this.kpiService.updateDayOff(dayOff).subscribe(
      (response) => {
    this.spinner.hide();

        this.toaster.success('Success', 'Day Off Updated Successfully');
        this.getDayOffData();
        this.closeDayOffModel();
        this.resetForm();
      },
      (error) => {
    this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }
  submitForm(): void {
    this.spinner.show();

    const data = {
      plantId: this.dayOffForm.value.plant,
      dayOff: this.dayOffForm.value.dayOff,
    };
    this.kpiService.addDayOff(data).subscribe(
      () => {
    this.spinner.hide();

        this.toaster.success('success', 'Day Off Added Successfully');
        this.getDayOffData();
        this.closeDayOffModel();
        this.resetForm();
      },
      (error) => {
    this.spinner.hide();

        this.toaster.error('error', error.error.message);
      }
    );
  }
  deleteDayOff(dayOffId: number): void {
    if (this.isDelete) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete dayOff!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          const key = {
            dayOffId,
          };
          this.kpiService.deleteDayOff(key).subscribe(
            (success) => {
              Swal.fire({
                title: 'Deleted!',
                text: 'Your selected dayOff has been deleted.',
                icon: 'success',
                timer: 800,
                showConfirmButton: false,
              });
          this.spinner.hide();

              this.getDayOffData();
            },
            (error) => {
          this.spinner.hide();

              this.toaster.error('Error', error.error.message);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your dayOff is safe :)', 'error');
        }
      });
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  resetForm(): void {
    this.dayOffForm.reset();
  }
  getOneDayOff(dayOffId: number): void {
    if (this.isUpdate) {
      const key = {
        dayOffId,
      };
      this.dayOffId = dayOffId;
      this.kpiService.getOneDayOff(key).subscribe(
        (data) => {
          this.dayOffId = dayOffId;
          this.plants = [];
          this.getPlants();
          this.dayOffForm.get('plant')?.setValue(this.plantId);
          this.dayOffForm.get('dayOff')?.setValue(data.dayOff);
          this.formMode = false;
          this.dayOffModelVisible = true;
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  closeDayOffModel(): void {
    this.dayOffModelVisible = false;
  }
}
