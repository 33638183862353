import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Material Imports
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTreeModule } from '@angular/material/tree';
import { MaterialModule } from 'src/app/material-module';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatTabsModule } from '@angular/material/tabs';


//Ngx Imports
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxSpinnerModule } from 'ngx-spinner';

import { SkillMatrixRoutingModule } from './skill-matrix-routing.module';
import { TechScoreComponent } from './tech-score/tech-score.component';
import { TechScoreFormComponent } from './tech-score-form/tech-score-form.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    TechScoreComponent,
    TechScoreFormComponent
  ],
  imports: [
    CommonModule,
    
TranslateModule.forChild({
  extend: true,
}),

    SkillMatrixRoutingModule,
    FormsModule,
    ReactiveFormsModule,

    //Material Imports
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MaterialModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatTimepickerModule,
    MatTabsModule,
    //Ngx Imports
    NgxSpinnerModule,
    NgxMaterialTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
  ]
})
export class SkillMatrixModule { }
