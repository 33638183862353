import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { UserManu } from 'src/app/models/userManu';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ProcessAuditCategoryService } from 'src/app/services/process-audit-category.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
@Component({
  selector: 'app-process-audit-categories',
  templateUrl: './process-audit-categories.component.html',
  styleUrls: ['./process-audit-categories.component.scss']
})
export class ProcessAuditCategoriesComponent implements OnInit {

  @Input() aPermissions!: UserManu;
  @Input() IsWrite!: boolean;

  @ViewChild(MatTable) table!: MatTable<Pillar>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayMAchineModal = false;
  pillars!: Pillar[];
  pillarId!: number;
  plants!: Plantbygroup[];
  masterCategoriesDDL!: any[];
  auditCategoryForm!: FormGroup;
  formMode!: boolean;
  plantId!: number;
  userId!: number;
  groupId!: number;
  plantsDDL!: any[];
  categoryTypeDDL: any[] = [{ id: 'Option', name: 'Option' }, { id: 'Score', name: 'Score' }];

  displayUserModal = false;
  pillarModelVisible = false;
  isUpdateAuditCategory = false;

  auditcatColumns: string[] = ['plant', 'auditName', 'score', 'areaActions'];
  auditCategoryData = new MatTableDataSource<any>([]);
  getareaMaster: any;
  filterArray!: any[];
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private machineService: MachineMappingService,
    private spinner: NgxSpinnerService,
    private processauditCategoryService: ProcessAuditCategoryService,
  ) {}

  ngOnInit(): void {
    this.getMasterCategories();
    this.formMode = true;
    this.auditCategoryForm = this.fb.group({
      plantId: ['', Validators.required],
      processMasterCategoryId: ['', Validators.required],
      categoryTypeId: ['', Validators.required],
      processCatId: [''],
      group: ['']
    });  
  }

  getPaginator(): void {
    setTimeout(() => {
      this.auditCategoryData.paginator = this.paginator;
      this.auditCategoryData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.auditCategoryData.filter = filterValue.trim().toLowerCase();

    if (this.auditCategoryData.paginator) {
      this.auditCategoryData.paginator.firstPage();
    }
  }


  getPlantsByGroup(): void {
    const key = {
      GroupId: this.groupId
    };
    this.machineService.getPlantsData(key).subscribe(
      (plants: any) => {
        this.plantsDDL = plants.DepartList;
        this.auditCategoryForm.get('plantId')?.setValue(this.plantId);
        this.getMasterCategories();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getMasterCategories(): void {
    debugger
    const data = { plantId: this.plantId };
    this.processauditCategoryService.getMasterCategories(data).subscribe((data) => {
      this.masterCategoriesDDL = data;
    },
      (error) => {
        this.toaster.error('Error', error.error.Message);
      });
  }

  getAuditMaster(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.groupId = key.groupId;
    this.auditCategoryForm.get('group')?.setValue(key.groupId);
    const data = {
      plantId: key.plantId
    };
    this.processauditCategoryService.GetAllCategories(data).subscribe((data) => {
      this.auditCategoryData.data = data;
      this.getPaginator();
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });

  }

  opensideModal(): void {
    this.getPlantsByGroup();
    this.formMode = true;
    this.isUpdateAuditCategory = false;
    this.reset();
    this.displayUserModal = true;

    const listArray = this.masterCategoriesDDL;
    const tableArray = this.auditCategoryData.data;
    const missingArray = listArray.filter((elem) => !tableArray.find(({ masterCategoryId }) => elem.masterProcessCategoryId === masterCategoryId))
    this.filterArray = missingArray;
  }

  closeaddNewPlantItemModel(): void {
    this.displayUserModal = false;
  }

  saveAudit(): void {
    if (!this.isUpdateAuditCategory) {
      const data = {
        masterCategoryId: this.auditCategoryForm.get('processMasterCategoryId')?.value,
        catType: this.auditCategoryForm.get('categoryTypeId')?.value,
        userId: JSON.parse(localStorage.user).UserId,
        plantId: this.auditCategoryForm.get('plantId')?.value
      };
      this.processauditCategoryService.addCategory(data).subscribe((response: any) => {
        if(response.message == 'Categories is already taken'){
          this.toaster.warning('warning',response.message);
        }
        else{
          this.toaster.success('Success', 'Area Added Successfully');
          this.reset();
          this.closeaddNewPlantItemModel();
          const key = {
            groupId: this.groupId,
            plantId: data.plantId
          };
          this.getAuditMaster(key);
        }
      }, (error) => {
        this.toaster.error('Error', error.error.Message);
      });
    } else {
      const data = {
        processCatId: this.auditCategoryForm.get('processCatId')?.value,
        masterCategoryId: this.auditCategoryForm.get('processMasterCategoryId')?.value,
        catType: this.auditCategoryForm.get('categoryTypeId')?.value,
        userId: JSON.parse(localStorage.user).UserId,
        plantId: this.auditCategoryForm.get('plantId')?.value
      };
      this.processauditCategoryService.updateCategory(data).subscribe((response: any) => {
        if(response.message == 'Categories is already taken'){
          this.toaster.warning('warning',response.message);
        }
        else{
          this.toaster.success('Success', 'Area Updated Successfully');
          this.closeaddNewPlantItemModel();
          this.isUpdateAuditCategory = false;
          this.reset();
          const key = {
            groupId: this.groupId,
            plantId: data.plantId
          };
          this.getAuditMaster(key);
        }
      }, (error) => {
        this.toaster.error('Error', error.error.Message);
      });
    }
  }

  onClickUpdateAuditCategory(element: any): void {
    this.getPlantsByGroup();
    this.isUpdateAuditCategory = true;
    this.formMode = false;
    this.auditCategoryForm.get('processMasterCategoryId')?.setValue(element.masterCategoryId),
    this.auditCategoryForm.get('categoryTypeId')?.setValue(element.catType),
    this.auditCategoryForm.get('plantId')?.setValue(element.plantId);
    this.auditCategoryForm.get('processCatId')?.setValue(element.processCatId);
    this.displayUserModal = true;

    const listArray = this.masterCategoriesDDL;
    const tableArray = this.auditCategoryData.data;
    const missingArray = listArray.filter((elem) => !tableArray.find(({ masterCategoryId  }) => {
      return (masterCategoryId  !== element.masterCategoryId  && elem.masterProcessCategoryId  === masterCategoryId );
    }));
    this.filterArray = missingArray;
  }

  onClickDeleteAuditCategory(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Master ProcessCate!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const id = {
          processCatId: element.processCatId
        };
        this.processauditCategoryService.deleteCategory(id).subscribe(
          (data:any) => {
            if(data.message == "Process Audit Category can't delete due to used in Process Confirmation"){
              this.toaster.warning('warning',data.message);
            }
            else{
              const key = {
                plantId: element.plantId,
                groupId: this.groupId
              };
              this.getAuditMaster(key);
              this.toaster.success(data.message);
            }
          },
          (error:any) => {
            this.toaster.error('Error', error.error.Message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Master ProcessCate is safe :)', 'error');
      }
    }); 
  }

  reset(): void {
    this.auditCategoryForm.reset();
  }
}
