import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoginUser } from 'src/app/models/loginUser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CardItem } from 'src/app/models/uimodels/cardItem';
import { UserManagementService } from 'src/app/services/usermanagement.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() isMobMenuActive = false;
  @Output() menuChangeEvent = new EventEmitter();
  isMenuOpen = true;
  siebarList!: CardItem[];
  user!: LoginUser;
  constructor(public router: Router , private userManagementService: UserManagementService, private toaster: ToastrService) {}


  ngOnInit(): void {
    const u = localStorage.getItem('user');
    this.user = u !== null ? JSON.parse(u) : {};
    this.getMenulist();
  }

  menuToggler(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.menuChangeEvent.emit(this.isMenuOpen);
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([url]));
  }
  getMenulist(): any{
    const obj = {};
    this.userManagementService.GetMenulist(obj).subscribe((data: any) => {
      this.siebarList = data.mennulist;
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
}
