<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
  <p style="color: white">Fetching Data...</p>
</ngx-spinner>
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="displayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">OPMS Hot End Shift Reports </h1>
    </div>
  </div>
  <form [formGroup]="opmsShiftReportForm">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row content-top-row-order-processing">
        <div style="display: flex; width: 70%;">
          <div class="inputField">
            <nz-date-picker class="calender-input" id="picker"
              formControlName="startDate" [nzDisabledDate]="" nzFormat="yyyy-MM-dd"></nz-date-picker>
            <span class="floating-text" style="margin-left: 10px;">Start Date</span>
          </div>
          <div class="inputField">
            <nz-date-picker class="calender-input" id="picker" formControlName="endDate"
              [nzDisabledDate]="" nzFormat="yyyy-MM-dd"></nz-date-picker>
            <span class="floating-text" style="margin-left: 10px;">End Date</span>
          </div>
  
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Shift</mat-label>
            <mat-select panelClass="testClass" formControlName="shift" name="Shift">
              <mat-option value="0">ALL</mat-option>
              <mat-option *ngFor="let s of shiftList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
            </mat-select>
          </mat-form-field>
  
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Crew</mat-label>
            <mat-select panelClass="testClass" formControlName="crew" name="Crew">
              <mat-option value="0">ALL</mat-option>
              <mat-option *ngFor="let s of crewList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
            </mat-select>
          </mat-form-field>

          <button (click)="getReports()" class="icon-btn" style="margin-left: 15px; margin-top: 5px;" pTooltip="Apply Filter"
            tooltipPosition="top">
            <img src="assets/images/search.png" alt="" width="25px" height="25px">
          </button>
        </div>
        <div class="redirectOPMS">
          <button  class="opms" (click)="getMenuAccessForHotEnd()">Hot End Log Entry</button>
        </div>
      </div>
    </div>
  </form>
  <br><br>
  <mat-accordion  style="margin-top: 20px;">
    <!-- Start Shift Performance -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc1 disable_ripple_acc1">
        <mat-panel-title>
          Shift Performance
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="width: 100%; margin-top: 20px;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowShiftPerformance" 
            [columnDefs]="columnDefsShiftPerformance" [defaultColDef]="defaultColDef"
            [pagination]="true" [paginationPageSize]="10" [loadingOverlayComponent]="loadingOverlayComponent"
            [gridOptions]="gridOptions" (gridReady)="onGridReadyShiftPerformance($event)">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>
    <!-- End Shift Performance -->
    <!-- Start Tin Bath Quality Parameters -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc2 disable_ripple_acc2">
        <mat-panel-title>
          Tin Bath Quality Parameters
        </mat-panel-title>
      </mat-expansion-panel-header>
        <div style="width: 100%; margin-top: 20px;">
          <mat-card-content>
            <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowTinBathQualityParameters" 
              [columnDefs]="columnDefsTinBathQualityParameters" [defaultColDef]="defaultColDef" (cellValueChanged)="onCellValueChangedTinBathQualityParameters($event)"
              [pagination]="true" [paginationPageSize]="10" [loadingOverlayComponent]="loadingOverlayComponent"
              [gridOptions]="gridOptions" (gridReady)="onGridReadyTinBathQualityParameters($event)">
            </ag-grid-angular>
          </mat-card-content>
        </div>
    </mat-expansion-panel>
    <!-- End Tin Bath Quality Parameters -->
    <!-- Start Furnace Combustion -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc3 disable_ripple_acc3">
        <mat-panel-title>
          Furnace Combustion
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="width: 100%; margin-top: 20px;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowFurnaceCombustion" 
            [columnDefs]="columnDefsFurnaceCombustion" [defaultColDef]="defaultColDef" (cellValueChanged)="onCellValueChangedFurnaceCombustion($event)"
            [pagination]="true" [paginationPageSize]="10" [loadingOverlayComponent]="loadingOverlayComponent"
            [gridOptions]="gridOptions" (gridReady)="onGridReadyFurnaceCombustion($event)">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>
    <!-- End Furnace Combustion -->
    <!-- Start Furnace Temperatures -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc4 disable_ripple_acc4">
        <mat-panel-title>
          Furnace Temperatures
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="width: 100%; margin-top: 20px;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowFurnaceTemperatures" 
            [columnDefs]="columnDefsFurnaceTemperatures" [defaultColDef]="defaultColDef" (cellValueChanged)="onCellValueChangedFurnaceTemperatures($event)"
            [pagination]="true" [paginationPageSize]="10" [loadingOverlayComponent]="loadingOverlayComponent"
            [gridOptions]="gridOptions" (gridReady)="onGridReadyFurnaceTemperatures($event)">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>
    <!-- End Furnace Temperatures -->
    <!-- Start Hot End Equipmets -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc5 disable_ripple_acc5">
        <mat-panel-title>
          Hot End Equipmets
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="width: 100%; margin-top: 20px;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowHotEndEquipmets" 
            [columnDefs]="columnDefsHotEndEquipmets" [defaultColDef]="defaultColDef" (cellValueChanged)="onCellValueChangedHotEndEquipmets($event)"
            [pagination]="true" [paginationPageSize]="10" [loadingOverlayComponent]="loadingOverlayComponent"
            [gridOptions]="gridOptions" (gridReady)="onGridReadyHotEndEquipmets($event)">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>
    <!-- End Hot End Equipmets -->
    <!-- Start Tin Bath Process parameters -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc3 disable_ripple_acc3">
        <mat-panel-title>
          Tin Bath Process Parameters
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="width: 100%; margin-top: 20px;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowTinBathProcessParameters" 
            [columnDefs]="columnDefsTinBathProcessParameters" [defaultColDef]="defaultColDef" (cellValueChanged)="onCellValueChangedTinBathProcessParameters($event)"
            [pagination]="true" [paginationPageSize]="10" [loadingOverlayComponent]="loadingOverlayComponent"
            [gridOptions]="gridOptions" (gridReady)="onGridReadyTinBathProcessParameters($event)">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>
    <!-- End Tin Bath Process parameters -->
    <!-- Start Tin Bath Energy  -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc2 disable_ripple_acc2">
        <mat-panel-title>
          Tin Bath Energy 
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="width: 100%; margin-top: 20px;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowTinBathEnergy" 
            [columnDefs]="columnDefsTinBathEnergy" [defaultColDef]="defaultColDef" (cellValueChanged)="onCellValueChangedTinBathEnergy($event)"
            [pagination]="true" [paginationPageSize]="10" [loadingOverlayComponent]="loadingOverlayComponent"
            [gridOptions]="gridOptions" (gridReady)="onGridReadyTinBathEnergy($event)">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>
    <!-- End Tin Bath Energy  -->
    <!-- Start Hot End Events -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc1 disable_ripple_acc1">
        <mat-panel-title>
          Hot End Events
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="width: 100%; margin-top: 20px;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowHotEndEvents" 
            [columnDefs]="columnDefsHotEndEvents" [defaultColDef]="defaultColDefHotEndEvents" 
            [pagination]="true" [paginationPageSize]="10" [loadingOverlayComponent]="loadingOverlayComponent"
            [gridOptions]="gridOptions" (gridReady)="onGridReadyHotEndEvents($event)">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>
    <!-- End Hot End Events -->
  </mat-accordion>
