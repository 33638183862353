import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OverallActionPlanService {
  O3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) {}

  GetActionPlan(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'OverallActionPlan/GetActionPlan',
      data
    );
  }
  getNewModule(){
    return this.http.get<any[]>(this.O3CoreApiUrl + 'OverallActionPlan/getModule')
   }
  addActionCompleted(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'OverallActionPlan/AddActionCompleted',
      data
    );
  }
  addNewTargetDate(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'OverallActionPlan/AddNewTargetDate',
      data
    );
  }

  addRevisedDate(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'OverallActionPlan/AddRevisedDate',
      data
    );
  }
  AddApprovedAction(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'OverallActionPlan/AddRevisedDate',
      data
    );
  }

editActionPlan(data: any): Observable<any[]> {
  return this.http.post<any[]>( this.O3CoreApiUrl + 'OverallActionPlan/UpdateActonPlan',
  data );
}

sendMail(data: any): Observable<any[]> {
  return this.http.post<any>( this.O3CoreApiUrl + 'EdgeData/SendEmail',
  data );
}
addActionPlan(data: any): Observable<any[]> {
  return this.http.post<any[]>( this.O3CoreApiUrl + 'OverallActionPlan/AddActionPlan',
  data );
}
AddActionUserAction(data: any): Observable<any[]> {
  return this.http.post<any[]>( this.O3CoreApiUrl + 'OverallActionPlan/AddActionUserAction',
  data )}
}
