import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { MeetingmasterService } from 'src/app/services/meetingmaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import Swal from 'sweetalert2';
import { TranslaterService } from 'src/app/services/translater.service';

@Component({
  selector: 'app-meetingmastersettings',
  templateUrl: './meetingmastersettings.component.html',
  styleUrls: ['./meetingmastersettings.component.scss'],
})
export class MeetingmastersettingsComponent implements OnInit {
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('displayGroupModal') private displayGroupModal!: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  MeetingForm!: FormGroup;
  MeetingAreaForm!: FormGroup;
  MeetingDetailList!: FormArray;
  plantId!: number;
  groupId!: number;
  IsAccess: any;
  IsWrite: any;
  groupListDDL!: any[];
  plantList!: any[];
  AreaList: string[] = [];
  formMode!: boolean;
  meetingtarget = true;
  itemColumns: string[] = ['itemActions', 'checkdetail', 'areaname', 'weight'];
  counter = 1;
  meetingId!: number;
  MeetingData = new MatTableDataSource<any>([]);
  MeetingListDetails!: MatTableDataSource<any>;
  meetingmasterColumns: string[] = ['areaActions', 'name', 'type'];
  isCheckListType = true;
  checkListTypes = [
    { name: 'Evaluation Check List' },
    { name: 'Evaluation Level' },
  ];
  filteredCheckListTypes: any[] = this.checkListTypes.slice();
  meetingByIdObject!: any;
  meetingCheckType = '';
  isLoading = false;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  breadcrumList: any;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private userService: UserManagementService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private meetingMasterService: MeetingmasterService,
    private platModelService: EnterPriseGroupService,
    private machineService: MachineMappingService,
    public translater: TranslaterService,
  ) {
  }

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.formMode = true;
    this.MeetingForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      meetingname: ['', Validators.required],
      type: ['', Validators.required],
      target: [''],
      meetingdetails: this.fb.array([this.createMeetingDetailsFormGroup()]),
    });
    this.MeetingAreaForm = this.fb.group({
      areaName: ['', Validators.required],
    });
    this.getGroupData();
    this.getPlantByGroup();
    this.GetMenuAccess();
    this.MeetingDetailList = this.MeetingForm.get(
      'meetingdetails'
    ) as FormArray;
    this.MeetingForm.get('type')?.valueChanges.subscribe((val) => {
      if (val === 'Evaluation Check List') {
        this.MeetingForm.controls.target.setValidators([
          Validators.required,
        ]);
      } else {
        this.MeetingForm.controls.target.clearValidators();
      }
      this.MeetingForm.controls.target.updateValueAndValidity();
    });
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

//menu and breadcrumb
GetBreadcrumList(): any {
  const obj = {
    MenuId:
      localStorage.getItem('O3RightMenuId') === null
        ? 1
        : localStorage.getItem('O3RightMenuId'),
  };
  // tslint:disable-next-line: align
  this.userService.GetBreadcrumList(obj).subscribe(
    (data: any) => {
      this.breadcrumList = data.BreadcrumList;
      this.moduleId = this.breadcrumList[0].id;
      this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      this.getLangWords();
    },
    (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    }
  );
}

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        // localStorage.setItem('GetMenuAccess', this.permisson);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  SaveArea(): void {
    const key = this.MeetingAreaForm.get('areaName')?.value;
    this.AreaList.push(key);
    this.MeetingAreaForm.reset();
    this.toaster.success('Success', 'Area added successfully');
  }
  getPaginator(): void {
    setTimeout(() => {
      this.MeetingData.paginator = this.paginator;
    }, 1000);
  }

  // create dynamic table row

  createMeetingDetailsFormGroup(): FormGroup {
    return this.fb.group({
      checkdetail: [''],
      areaname: [''],
      weight: [0],
    });
  }

  addChecklistRow(): void {
    const checkdetailArray = this.MeetingForm.value.meetingdetails;
    const checkdetail = checkdetailArray?.find(
      (x: { checkdetail: string }) => x.checkdetail === ''
    );
    const areaname = checkdetailArray?.find(
      (x: { areaname: string }) => x.areaname === ''
    );
    if (checkdetail || areaname) {
      this.toaster.warning('Please Fill Empty fields');
    } else {
      this.MeetingDetailList.push(this.createMeetingDetailsFormGroup());
      this.MeetingListDetails = new MatTableDataSource(
        this.MeetingForm.value.meetingdetails
      );
    }
  }
  deleteRow(element: any, index: number): void {
    this.MeetingDetailList.removeAt(index);
    this.MeetingListDetails = new MatTableDataSource(
      this.MeetingDetailList.controls
    );
  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.MeetingData.filter = filterValue.trim().toLowerCase();
    if (this.MeetingData.paginator) {
      this.MeetingData.paginator.firstPage();
    }
  }
  getMeetingMaster(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.groupId = key.groupId;
    this.meetingMasterService.GetAllMeetingMaster(key).subscribe(
      (data) => {
        this.MeetingData.data = data;
        this.getPaginator();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      }
    );
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  EditMeetingMaster(meetingId: number, displayRoleModal: any): void {
    this.resetForm();
    this.meetingId = meetingId;
    this.getGroupData();
    this.getMeetingMasterById(meetingId);
    this.dialog.open(displayRoleModal, {
      width: '80vw !important',
      height: '90vh',
      minWidth: '80vw',
    });
    this.formMode = false;
  }

  getMeetingMasterById(meetingId: number): void {
    this.getPlantByGroup();
    this.spinner.show();
    const meeting = {
      meetingId,
    };
    this.meetingMasterService.getMeetingMasterById(meeting).subscribe(
      (response: any) => {
        this.meetingByIdObject = response;
        this.MeetingForm.get('group')?.setValue(response?.egId);
        this.MeetingForm.get('plant')?.setValue(response?.ptId);
        this.MeetingForm.get('meetingname')?.setValue(response?.name);
        this.MeetingForm.get('type')?.setValue(response?.type);
        this.MeetingForm.get('target')?.setValue(response?.target);
        this.meetingCheckType = response?.type;
        this.isCheckListType =
          response?.type === 'Evaluation Check List' ? true : false;
        const arealist: string[] = response?.meetingDetails?.map(
          (item: any) => item.areaname
        );
        const meetingdetailArray: any[] = [];
        this.MeetingDetailList.clear();

        response.meetingDetails.forEach((element: any) => {
          meetingdetailArray.push({
            checkdetail: element.name,
            areaname: element.areaname,
            weight: element.weight,
          });
          this.MeetingDetailList.push(this.createMeetingDetailsFormGroup());
        });


         this.MeetingForm.get('meetingdetails')?.setValue(meetingdetailArray);
        this.AreaList = [...new Set(arealist)];
        this.MeetingListDetails = new MatTableDataSource<any>(
          this.MeetingForm.value.meetingdetails
        );
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  onClickDeleteMeetingMaster(meetingId: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Meeting Master!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const id = {
          meetingId,
        };
        this.meetingMasterService.deleteMeetingMaster(id).subscribe(
          (data: any) => {
            const key = {
              plantId: this.plantId,
              groupId: this.groupId,
            };
            this.getMeetingMaster(key);
            this.toaster.success(data[0].Message);
          },
          (error: any) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Meeting Master is safe :)', 'error');
      }
    });
  }
  resetForm(): void {
    this.AreaList = [];
    this.MeetingListDetails = new MatTableDataSource<any>(
      this.MeetingDetailList.controls
    );
    this.MeetingForm.reset();
  }

  addCheckList(): any {
    this.isLoading = true;
    const checklistarray = this.MeetingForm.value.meetingdetails;
    // this.MeetingData.data.forEach(element => {
    //  if(this.MeetingForm.value.meetingname === element.name){
    //     this.toaster.warning("Meeting Name Already exists");
    //     this.isLoading = false;
    //     return;
    //   }
    //  });
    for (let data of this.MeetingData.data) {
      if(this.MeetingForm.value.meetingname === data.name){
        this.toaster.warning("Meeting Name Already exists");
        this.isLoading = false;
        return;
      }

    }
    if (checklistarray.length > 0) {
      const name = checklistarray.find(
        (x: { checkdetail: string }) => x.checkdetail === ''
      );
      const areaName = checklistarray.find(
        (x: { areaName: string }) => x.areaName === ''
      );
      if (name || areaName) {
        this.toaster.warning('Please Fill Or Remove Empty Fields');
        this.isLoading = false;
        return;
      }
    } else {
      this.toaster.warning('Fill Data Correctly');
      this.isLoading = false;
      return;
    }
    const meeting = {
      userId: JSON.parse(localStorage.user).UserId,
      name: this.MeetingForm.value.meetingname,
      plantId: this.MeetingForm.value.plant,
      groupId: this.MeetingForm.value.group,
      type: this.MeetingForm.value.type,
      target: this.MeetingForm.value.target,
      meetingDetails: checklistarray?.map(
        (item: {
          checkdetail: any;
          areaname: any;
          weight: any;
          totalWeight: any;
        }) => {
          return {
            checkdetail: item?.checkdetail,
            areaname: item.areaname,
            weight: this.isCheckListType ? item?.weight : 0,
          };
        }
      ),
    };
    this.meetingMasterService.addMeeting(meeting).subscribe(
      (response: any) => {
        if (response.message == 'Weight must be equal to target') {
          this.toaster.warning(response.message);
        } else {
          this.toaster.success(response.message);
        }

        const key = {
          plantId: this.plantId,
          groupId: this.groupId,
        };
        this.getMeetingMaster(key);
        this.MeetingDetailList.clear();
        this.dialog.closeAll();
        this.resetForm();
        this.isLoading = false;
      },
      ({ error }: any) => {
        this.toaster.error(error.message);
        this.isLoading = false;
      }
    );
  }
  updateCheckList(): any {
    this.isLoading = true;
    const checklistArray = this.MeetingForm.value.meetingdetails;
   let filterMeeting =  this.MeetingData.data.filter(item =>item.meetingId !=this.meetingId) 
    for (let data of filterMeeting) {

      if(this.MeetingForm.value.meetingname === data.name){
        this.toaster.warning("Meeting Name Already exists");
        this.isLoading = false;
        return;
      }

    }
    if (checklistArray.length > 0) {
      const name = checklistArray.find(
        (x: { checkdetail: string }) => x.checkdetail === ''
      );
      const areaname = checklistArray.find(
        (x: { areaname: string }) => x.areaname === ''
      );
      if (name || areaname) {
        this.toaster.warning('Please Fill Or Remove Empty Fields');
        return;
      }
    } else {
      this.toaster.warning('Fill Data Correctly');
      return;
    }
    const meeting = {
      meetingId: this.meetingId,
      userId: JSON.parse(localStorage.user).UserId,
      name: this.MeetingForm.value.meetingname,
      plantId: this.MeetingForm.value.plant,
      groupId: this.MeetingForm.value.group,
      type: this.MeetingForm.value.type,
      target: this.MeetingForm.value.target,
      meetingDetails: checklistArray.map(
        (item: { checkdetail: any; areaname: any; weight: any }) => {
          return {
            checkdetail: item?.checkdetail,
            areaname: item.areaname,
            weight: this.isCheckListType ? item?.weight : 0,
          };
        }
      ),
    };
    this.meetingMasterService.updateMeeting(meeting).subscribe(
      (response: any) => {
        this.toaster.success('Meeting Updated Successfuly');
        const key = {
          plantId: this.plantId,
          groupId: this.groupId,
        };
        this.getMeetingMaster(key);
        this.MeetingDetailList.clear();
        this.dialog.closeAll();
        this.resetForm();
        this.isLoading = false;
      },
      ({ error }: any) => {
        this.toaster.error('Error', error.message);
        this.isLoading = false;
      }
    );
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.groupId,
    };
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plantList = data.DepartList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  addGroupModal(): void {
    this.dialog.open(this.displayGroupModal, { height: '31%', width: '40%' });
    this.MeetingAreaForm.reset();
  }

  changeCheckType(event: any): void {
    if (this.MeetingForm.get('type')?.value === 'Evaluation Check List') {
      this.meetingtarget = true;
    } else {
      this.meetingtarget = false;
    }
    this.AreaList = [];
    this.MeetingDetailList.clear();
    this.MeetingDetailList.push(this.createMeetingDetailsFormGroup());
    this.MeetingListDetails = new MatTableDataSource(
      this.MeetingForm.value.meetingdetails
    );
    this.isCheckListType =
      event?.value === 'Evaluation Check List' ? true : false;
    if (!this.formMode && event?.value === this.meetingCheckType) {
      this.setDataInitially();
    }
  }

  setDataInitially(): void {
    const arealist: string[] =
      this.meetingByIdObject?.meetingMasterDetails?.map(
        (item: any) => item.areaName
      );
    const meetingdetailArray: any[] = [];
    this.MeetingDetailList.clear();
    this.meetingByIdObject?.meetingMasterDetails?.forEach((element: any) => {
      meetingdetailArray.push({
        checkdetail: element.name,
        areaname: element.areaName,
        weight: element.weight,
      });
      this.MeetingDetailList.push(this.createMeetingDetailsFormGroup());
    });
    this.MeetingForm.get('meetingdetails')?.setValue(meetingdetailArray);
    this.AreaList = [...new Set(arealist)];
    this.MeetingListDetails = new MatTableDataSource<any>(
      this.MeetingForm.value.meetingdetails
    );
  }

  openModal(displayRoleModal: any): void {
    this.getGroupData();
    this.resetForm();
    this.MeetingDetailList.clear();
    this.addChecklistRow();
    this.MeetingForm.get('group')?.setValue(this.groupId);
    this.getPlantByGroup();
    this.MeetingForm.get('plant')?.setValue(this.plantId);
    this.MeetingForm.get('type')?.setValue('Evaluation Check List');
    this.isCheckListType = true;
    this.formMode = true;
    this.dialog.open(this.displayRoleModal, {
      width: '80vw !important',
      height: '90vh',
      minWidth: '80vw',
    });
  }
}
