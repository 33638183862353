<section class="home-content">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  
  <mat-card style=" background-color:#0F0FD1 !important;">

    <form [formGroup]="searchMasterForm">
      <div class="p-grid p-fluid">

        <div class="p-col-4">
          <mat-card-title>NCR Actions Master Settings</mat-card-title>
        </div>
        <div class="p-col-4">
          <p-dropdown [filter]="true" (onChange)="getPlants()" [ngClass]="{
                        'is-invalid':
                        searchMasterForm.get('group')?.errors &&
                        searchMasterForm.get('group')?.touched
                      }" [options]="groupList" formControlName="group" placeholder="Select Group"
            optionLabel="TypeName" [showClear]="true"></p-dropdown>
          <p class="invalid-feedback" *ngIf="
                      searchMasterForm.get('group')?.touched &&
                      searchMasterForm.get('group')?.hasError('required')
                      ">
            *Please Select Group
          </p>
        </div>
        <div class="p-col-4">

          <p-dropdown [filter]="true" (onChange)="getMasterData($event)" [ngClass]="{
            'is-invalid':
            searchMasterForm.get('plant')?.errors &&
            searchMasterForm.get('plant')?.touched
          }" [options]="plants" formControlName="plant" placeholder="Select Plant" optionLabel="DeptDesc"
            [showClear]="true"></p-dropdown>
          <p class="invalid-feedback" *ngIf="
         searchMasterForm.get('plant')?.touched &&
         searchMasterForm.get('plant')?.hasError('required')
        ">
            *Please Select Plant
          </p>

        </div>

      </div>
    </form>
  </mat-card>

  <mat-card class="p-mt-2 p-p-0">
    <!-- Table -->
    <div class="p-grid p-fluid">
      <div class="p-xl-6 p-lg-6 p-md-12 p-col-12" >
        <app-PlantWiseImmediateNcrActions></app-PlantWiseImmediateNcrActions>
      </div>
    </div>
  </mat-card>
</section>
