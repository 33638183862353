import { Component, OnInit ,TemplateRef,ViewChild} from '@angular/core';

@Component({
  selector: 'app-ncr-ccr-management',
  templateUrl: './ncr-ccr-management.component.html',
  styleUrls: ['./ncr-ccr-management.component.scss']
})
export class NcrCcrManagementComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }

}
