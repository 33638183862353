<ngx-spinner
  bdColor="rgba(0, 0, 0, 0)"
  template=" <img src='assets/images/spinner.gif' />"
>
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>
<div class="cause-effect-model-content">
  <div
    style="overflow-y: scroll; border-radius: 5px; height: 600px"
    *ngIf="fiveWhyActionPlanDataSource !== undefined"
  >
    <table>
      <thead>
        <tr>
          <th
            *ngFor="let column of causeEffectModelColumns"
            style="color: #0f0fd1"
          >
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let yData of fiveWhy; let i = index">
          <td
            *ngIf="!yData.spanRow"
            [attr.rowspan]="yData.rowSpan ? yData.rowSpan : 1"
          >
            <p *ngIf="levelId != 0 && levelId != 5">{{ yData.RootCause }}</p>
            <mat-form-field
              class="w-200px"
              *ngIf="levelId == 0 || levelId == 5"
            >
              <textarea
                matInput
                type="text"
                [readonly]="yData.Status == 'Closed'"
                [(ngModel)]="yData.RootCause"
                name="RootCause{{ i }}"
              ></textarea>
            </mat-form-field>
          </td>
          <td>
            <div title="{{ yData.Action }}">
              <mat-form-field class="w-200px">
                <textarea
                  matInput
                  type="text"
                  [readonly]="yData.Status == 'Closed'"
                  [(ngModel)]="yData.Action"
                  name="Action_{{ i }}"
                ></textarea>
              </mat-form-field>
            </div>
          </td>
          <td nowrap>
            <div>
              <mat-radio-group
                [disabled]="yData.Status == 'Closed'"
                [(ngModel)]="yData.ActionType"
                name="ActionType_{{ i }}"
                class="d-grid"
              >
                <mat-radio-button value="Preventive"
                  >Preventive</mat-radio-button
                >
                <mat-radio-button value="Corrective"
                  >Corrective</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </td>
          <td>
            <div class="imgflex" style="position: relative">
              <div title="image" style="display: flex">
                <img
                  *ngIf="yData.FileSrc || yData.UploadFile"
                  (click)="imageZoomModal(i)"
                  [src]="yData.FileSrc || blobStorageUrl + yData.UploadFile"
                  alt="image"
                  width="60px"
                  height="40px"
                />
                <!-- <span *ngIf="yData.UploadFile" style="align-items: end;transform: rotate(90deg);display: flex;padding-bottom: 5px;width: 50px;justify-content: center;">{{ getFileTypeName(ImageUrl+yData.UploadFile) }}</span> -->
              </div>
              <img
                *ngIf="yData.FileSrc || yData.UploadFile"
                src="assets/images/delete.png"
                (click)="deleteFile(i, 'pic')"
                class="imgabsol"
                alt=""
                width="15px"
                height="15px"
                style="position: absolute; left: 0; top: 0"
              />
              <button
                mat-flat-button
                color="accent"
                type="button"
                class="upload"
                (click)="openFile(i, 'pic')"
                [disabled]="yData.Status == 'Closed'"
              >
                <mat-icon>attach_file</mat-icon>
              </button>
              <input
                type="file"
                (change)="readFile($event, 'pic')"
                (click)="$event.target.value = null"
                class="d-none"
                #fileInput
                accept="image/*"
              />
            </div>
          </td>
          <td>
            <div class="imgflex" style="position: relative">
              <div title="pdf" style="display: flex">
                <a
                  *ngIf="yData.DocSrc || yData.UploadDoc"
                  [href]="yData.DocSrc || blobStorageUrl + yData.UploadDoc"
                  target="_blank"
                  download
                  ><img src="assets/images/file_img.png" alt="pdf" width="60px"
                /></a>
                <!-- <span *ngIf="yData.UploadDoc" style="align-items: end;transform: rotate(90deg);display: flex;padding-bottom: 5px;width: 50px;justify-content: center;">{{ getFileTypeName(yData.UploadDoc) }}</span> -->
              </div>
              <img
                *ngIf="yData.DocSrc || yData.UploadDoc"
                src="assets/images/delete.png"
                (click)="deleteFile(i, 'file')"
                class="imgabsol"
                alt=""
                width="15px"
                height="15px"
                style="position: absolute; left: 0; top: 0"
              />
              <button
                mat-flat-button
                color="accent"
                type="button"
                class="upload"
                (click)="openFile(i, 'file')"
                [disabled]="yData.Status == 'Closed'"
              >
                <mat-icon>attach_file</mat-icon>
              </button>
              <input
                type="file"
                (change)="readFile($event, 'file')"
                (click)="$event.target.value = null"
                class="d-none"
                #fileInputFile
                accept="application/pdf,application/vnd.ms-excel"
              />
            </div>
          </td>
          <td>
            <div class="d-flex">
              <mat-form-field>
                <mat-label>Select Assignee</mat-label>
                <mat-select
                  [(ngModel)]="yData.AssignTo"
                  [disabled]="yData.Status == 'Closed' || !yData.Action"
                  name="AssignTo_{{ i }}"
                  (selectionChange)="setAssignDate(yData)"
                >
                  <mat-select-filter
                    [placeholder]="'Search User'"
                    [displayMember]="'FullName'"
                    [array]="newUserList[i]"
                    (filteredReturn)="newUserFiltList[i] = $event"
                  >
                  </mat-select-filter>
                  <!-- <input (keyup)="searchValues($event, i, 'assignTo')" class="form-control"
                    placeholder="Search User"> -->
                  <mat-option
                    *ngFor="let user of newUserFiltList[i]"
                    [value]="user.EmpInfo_Id"
                  >
                    {{ user.FirstName }} {{ user.lastName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </td>

          <td class="text-center">
            {{ yData.AssignedDate ? (yData.AssignedDate | date) : "" }}
          </td>
          <ng-container *ngIf="showRemainingColumns()">
            <td style="position: relative">
              <div class="d-flex" style="width: 130px">
                <span class="span-date-name">Target Date</span>
                <nz-date-picker
                  class="date-picker"
                  [nzInputReadOnly]="true"
                  [nzShowTime]="false"
                  nzFormat="dd-MMM-yyyy"
                  [nzDisabledDate]="getMinDate(yData, 'Target')"
                  [(ngModel)]="yData.TargetDate"
                  [disabled]="yData.Status != 'Open' || !yData.AssignTo"
                >
                </nz-date-picker>
              </div>
            </td>
            <td style="position: relative">
              <div class="d-flex" style="width: 130px">
                <span class="span-date-name">Revised Date</span>
                <nz-date-picker
                  class="date-picker"
                  [nzInputReadOnly]="true"
                  [nzShowTime]="false"
                  nzFormat="dd-MMM-yyyy"
                  [nzDisabledDate]="getMinDate(yData, 'Revised')"
                  [nzAllowClear]="
                    yData.Status == 'InProgress' || yData.Status == 'ReOpened'
                  "
                  [(ngModel)]="yData.RevisedDate"
                  (ngModelChange)="onRevisedDateChange(yData)"
                  [disabled]="
                    (yData.Status != 'InProgress' && yData.Status == 'Open') ||
                    yData.Status == 'Complete' ||
                    yData.Status == 'Closed'
                  "
                >
                </nz-date-picker>
              </div>
            </td>
            <td class="text-center">
              {{ yData.NoOfMisses }}
            </td>
            <td>
              <div class="d-flex">
                <mat-form-field>
                  <!-- <mat-label>Select Status</mat-label> -->
                  <mat-select
                    [(ngModel)]="yData.ActionCompleted"
                    name="ActionCompleted_{{ i }}"
                    (selectionChange)="ActionCompletedChange(yData)"
                    [disabled]="
                      yData.Status == 'Closed' ||
                      !yData.TargetDate ||
                      yData.Status == 'Complete'
                    "
                  >
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="d-grid text-center">
                <p>
                  {{
                    yData.ActionCompletedBy
                      ? getUserName(yData, "actionBy")
                      : ""
                  }}
                </p>
                <p>
                  {{
                    yData.ActionCompletedOn
                      ? (yData.ActionCompletedOn | date)
                      : ""
                  }}
                </p>
              </div>
            </td>
            <td>
              <div class="d-flex">
                <mat-form-field>
                  <mat-label>Select Verification</mat-label>
                  <mat-select
                    [(ngModel)]="yData.Verification"
                    name="Verification_{{ i }}"
                    (selectionChange)="VerificationChange(yData)"
                    [disabled]="
                      yData.Status == 'Closed' || yData.ActionCompleted != 'Yes'
                    "
                  >
                    <mat-option value="">None</mat-option>
                    <mat-option value="Approved">Approved</mat-option>
                    <mat-option value="Disapproved">Disapproved</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="d-grid text-center" style="width: 100%">
                <p>
                  {{
                    yData.VerificationBy
                      ? getUserName(yData, "verificationBy")
                      : ""
                  }}
                </p>
                <p>
                  {{
                    yData.VerificationOn ? (yData.VerificationOn | date) : ""
                  }}
                </p>
              </div>
            </td>
            <td>
              <div class="d-flex">
                <mat-form-field>
                  <mat-label>Select New Assignee</mat-label>
                  <mat-select
                    [(ngModel)]="yData.NewAssignee"
                    [disabled]="
                      yData.Status == 'Closed' ||
                      yData.Verification != 'Disapproved'
                    "
                    name="NewAssignee_{{ i }}"
                  >
                    <mat-select-filter
                      [placeholder]="'Search User'"
                      [displayMember]="'FullName'"
                      [array]="newOrgUserList[i]"
                      (filteredReturn)="newOrgUserFiltList[i] = $event"
                    >
                    </mat-select-filter>

                    <mat-option
                      *ngFor="let user of newOrgUserFiltList[i]"
                      [value]="user.EmpInfo_Id"
                    >
                      {{ user.FirstName }} {{ user.lastName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>

            <td>
              <div class="d-grid text-center">
                <p>{{ yData.ClosedDate ? (yData.ClosedDate | date) : "" }}</p>
              </div>
            </td>
            <td>
              <div class="d-flex" title="{{ yData.Remarks }}">
                <mat-form-field class="w-200px">
                  <mat-label>Remarks</mat-label>
                  <!-- <textarea matInput [disabled]="yData.Status == 'Closed'" [(ngModel)]="yData.Remarks" name="Remarks_{{i}}"></textarea> -->
                  <input
                    matInput
                    [disabled]="yData.Status == 'Closed'"
                    [(ngModel)]="yData.Remarks"
                    name="Remarks_{{ i }}"
                  />
                </mat-form-field>
              </div>
            </td>

            <td class="text-center">
              {{ yData.Status }}
            </td>
            <td style="display: flex">
              <button
                mat-icon-button
                class="action-plan"
                (click)="addRow(yData, i, 'sub')"
                matTooltip="Sub Row"
              >
                <mat-icon class="menu subrow">add_circle</mat-icon>
                <mat-icon class="menu subrow subwidth">add_circle</mat-icon>
              </button>

              <button
                mat-icon-button
                class="action-plan"
                [disabled]="!yData.MainRowShow"
                (click)="addRow(yData, i, 'main')"
                *ngIf="
                  !rcaId && (levelId == 0 || levelId == 5) && !yData.spanRow
                "
                matTooltip="New Row"
              >
                <mat-icon class="menu mainrow">add_circle</mat-icon>
              </button>

              <button
                mat-icon-button
                class="del-button"
                (click)="deleteRow(i)"
                *ngIf="yData.FromActionPlan"
                matTooltip="Delete Row"
              >
                <mat-icon class="menu">delete</mat-icon>
              </button>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</div>
