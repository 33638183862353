<section class="home-content" dir="{{languageDir}}">
    <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
    </ul>
    <mat-card>
        <form [formGroup]=" actionAnalysisForm">
            <div style="padding:10px;background-color: #0F0FD1;">
                <div class="p-grid p-fluid">
                    <div class="p-col-4">
                        <mat-card-title style="color: white;">
                            {{langObj ? langObj?.IndividualPerformanceEvaluationUser :
                            ('IndividualPerformanceEvaluationUser' | translate)}}</mat-card-title>
                    </div>
                    <div class="p-col-4">
                        <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                            'is-invalid':
                            actionAnalysisForm.get('group')?.errors &&
                            actionAnalysisForm.get('group')?.touched
                          }" [options]="groupList" formControlName="group"
                            placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}"
                            optionLabel="TypeName" [showClear]="true"></p-dropdown>
                        <p class="invalid-feedback" *ngIf="
                          actionAnalysisForm.get('group')?.touched &&
                          actionAnalysisForm.get('group')?.hasError('required')
                          ">
                            * {{langObj ? langObj?.pleaseselectgroup : ('pleaseselectgroup' | translate)}}
                        </p>
                    </div>
                    <div class="p-col-4">
                        <p-dropdown [ngClass]="{
                                  'is-invalid':
                                  actionAnalysisForm.get('plant')?.errors &&
                                  actionAnalysisForm.get('plant')?.touched
                                }" [options]="searchPlants" formControlName="plant"
                            placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}"
                            optionLabel="DeptDesc" [showClear]="true" [filter]="true"
                            (onChange)="getMasterData($event)"></p-dropdown>
                        <p class="invalid-feedback" *ngIf="
                               actionAnalysisForm.get('plant')?.touched &&
                               actionAnalysisForm.get('plant')?.hasError('required')
                              ">
                            * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
                        </p>
                    </div>
                </div>
            </div>
            <mat-card-content>
                <div class="p-grid p-fluid">

                    <div class="p-col-10 p-md-5 p-lg-2">
                        <label>{{langObj ? langObj?.Frequency : ('Frequency' | translate)}}</label>
                        <p-dropdown [options]="frequencies" formControlName="frequency"
                            defaultLabel="Select a Frequency"
                            placeholder="{{langObj ? langObj?.SelectFrequency : ('SelectFrequency' | translate)}}"
                            scrollHeight="300px" name="frequency" optionLabel="name">

                        </p-dropdown>
                    </div>
                    <div class="p-col-10 p-md-5 p-lg-2">
                        <label>{{langObj ? langObj?.From : ('From' | translate)}}</label>
                        <p-calendar [locale]="calendar_en" dateFormat="dd/mm/yy" [(ngModel)]="startYear" appendTo="body"
                            formControlName="fromDate" [firstDayOfWeek]=0 [showWeek]="true" [monthNavigator]="true"
                            [yearNavigator]="true" yearRange="2000:2030" inputId="navigators"></p-calendar>
                    </div>
                    <div class="p-col-10 p-md-5 p-lg-2">
                        <label>{{langObj ? langObj?.ToDate : ('ToDate' | translate)}}</label>
                        <p-calendar [locale]="calendar_en" dateFormat="dd/mm/yy" [(ngModel)]="endYear" appendTo="body"
                            [firstDayOfWeek]=0 [showWeek]="true" [monthNavigator]="true" [yearNavigator]="true"
                            yearRange="2000:2030" inputId="navigators" formControlName="toDate"></p-calendar>
                    </div>
                    <div class="p-col-10 p-md-5 p-lg-2">
                        <label>{{langObj ? langObj?.Role : ('Role' | translate)}}</label>
                        <p-dropdown formControlName="role" [filter]="true" [options]="roles"
                            defaultLabel="Select a role"
                            placeholder="{{langObj ? langObj?.SelectRole : ('SelectRole' | translate)}}"
                            scrollHeight="300px" name="role" optionLabel="PositionName" (onChange)="getUserList()">
                        </p-dropdown>
                    </div>
                    <div class="p-col-10 p-md-5 p-lg-2">
                        <label>{{langObj ? langObj?.User : ('User' | translate)}}</label>
                        <p-multiSelect [options]="users" [filter]="true" formControlName="user"
                            defaultLabel="{{langObj ? langObj?.SelectUser : ('SelectUser' | translate)}}"
                            optionLabel="UserName" placeholder="Select a User" scrollHeight="300px" name="UserName">
                        </p-multiSelect>
                    </div>

                    <div class="p-col-10 p-md-5 p-lg-2">
                        <button style="margin-top: 15px;" type="button" mat-raised-button color="accent"
                            (click)="getIPEGrid()" [disabled]="!actionAnalysisForm.valid">{{langObj ? langObj?.Search :
                            ('Search' | translate)}}</button>
                    </div>

                </div>

                <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="dataArray"
                    [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [gridOptions]="gridOptions"
                    [icons]="icons" [pagination]="true" [paginationPageSize]="10"> 
                </ag-grid-angular>



            </mat-card-content>
        </form>
    </mat-card>
</section>