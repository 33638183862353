import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CILMasterService {
  // tslint:disable-next-line:typedef
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/GetEnterpriseGroupDDL', data);
  }
  getPlantsData(data: any): Observable<any[]> {
    let params = new HttpParams().set('id', data);
    return this.http.get<any[]>(this.o3CoreApiUrl + 'CIL/GetPlantByEgDDL', { params: params });
  }
  //==========================================Master Type ===========================//
  insertType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/InsertCILMasterType', data);
  }
  updateType(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/UpdateCILMasterType', data, { headers: header });
  }

  deleteType(data: any): Observable<any[]> {
    const httpParams = new HttpParams().append('id', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/DeleteCILMasterType?' + httpParams, httpParams);
  }

  getCILTypeData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/GetCILTypeMasterData', data);
  }

  //==============================================End Type========================================//

  //===============================================Master Tool===================================//
  insertTool(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/InsertCILMasterTool', data);
  }
  updateTool(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/UpdateCILMasterTool', data);
  }
  deleteTool(data: any): Observable<any[]> {
    const httpParams = new HttpParams().append('id', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/DeleteCILMasterTool?' + httpParams, httpParams);
  }

  getCILToolData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/GetCILToolsMasterData', data);
  }

  //===============================================Master Frequency ===================================//
  insertFrequency(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/InsertCILMasterFrequency', data);
  }
  updateFrequency(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/UpdateCILMasterFrequency', data);
  }
  deleteCILTaskData(data: any): Observable<any[]> {
    const httpParams = new HttpParams().append('id', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/DeleteCILTaskData?' + httpParams, httpParams);
  }

  getCILFrequencyData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/GetCILFrequencyMaster', data);
  }

  //====================================== Get Type List ===================================//
  getCILTypeList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/GetCilTypeDDL', data);
  }
  //====================================== Get Tool List ===================================//
  getCILToolList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/GetCilToolDDL', data);
  }

  //====================================== Get Frequency List ===================================//
  getCILFrequencyList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/GetCilFrequencyDDL', data);
  }

  //============================================== Task Master ==================================//
  insertTask(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/InsertCILTask', data);
  }
  getCILTaskData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/GetCILTaskData', data);
  }
  updateTask(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/UpdateCILTask', data);
  }
  deleteFrequency(data: any): Observable<any[]> {
    const httpParams = new HttpParams().append('id', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/DeleteCILMasterFrequency?' + httpParams, httpParams);
  }
  updateTaskStatus(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/UpdateTaskStatus', data);
  }
}