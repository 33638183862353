import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChecklistService } from 'src/app/services/checklist.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';

@Component({
  selector: 'app-checklist-report-configuration',
  templateUrl: './checklist-report-configuration.component.html',
  styleUrls: ['./checklist-report-configuration.component.scss']
})
export class ChecklistReportConfigurationComponent implements OnInit {
  subheadersFormGroup: FormGroup;
  headersFormGroup: FormGroup;
  IsWrite: any;
  plantId: any;
  Headers: any;
  subHeaders: any;
  constructor(
    private toaster: ToastrService, 
    private fb: FormBuilder, 
    private kpiMaster: KpimasterService, 
    private spinner: NgxSpinnerService, 
    private Checkervice: ChecklistService,) { }

  ngOnInit() {
    this.headersFormGroup = this.fb.group({
      Headers: this.fb.array([]),
    });
    this.subheadersFormGroup = this.fb.group({
      SubHeaders: this.fb.array([])
    });
    this.GetMenuAccess();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getkeys(key: any) {
    this.plantId = key.plantId
    this.GetHearderAndSubHeader();
  }

  GetHearderAndSubHeader() {
    this.Headers = [];
    this.subHeaders = [];
    const obj = {
      pt_Id: this.plantId
    }
    this.Checkervice.getHearderAndSubHeader(obj).subscribe((data: any) => {
      const findUnique = (arr, predicate) => {
        var found = {};
        arr.forEach(d => {
          found[predicate(d)] = d;
        });
        return Object.keys(found).map(key => found[key]);
      }
      data = findUnique(data, d => d.h_Id);
      data.forEach((elem, i) => {
        if (data[i].category == 1) {
          const hObj = {
            name: elem.name,
            checked: JSON.parse(elem.checked),
            h_Id: elem.h_Id,
          }
          this.Headers.push(hObj);
        } else {

          const sObj = {
            name: elem.name,
            checked: JSON.parse(elem.checked),
            h_Id: elem.h_Id,
          }
          this.subHeaders.push(sObj);
        }
      });
    })
  }

  onChangeHeaders(event) {
    const Headers = <FormArray>this.headersFormGroup.get('Headers') as FormArray;
    if (event.checked) {
      Headers.push(new FormControl(event.source.value))
    }
  }

  onChangeSubHeaders(event) {
    const SubHeaders = <FormArray>this.subheadersFormGroup.get('SubHeaders') as FormArray;
    if (event.checked) {
      SubHeaders.push(new FormControl(event.source.value))
    }
  }

  submitHeaders() {
    this.spinner.show();
    const headers = this.Headers.map((x) => {
      if (x.checked && x.h_Id) {
        return x.h_Id
      }
    }).filter(y => y);
    const subHeaders = this.subHeaders.map((x) => {
      if (x.checked && x.h_Id) {
        return x.h_Id
      }
    }).filter(y => y);
    const commaSplittedString = [...headers, ...subHeaders];
    const data = {
      headers: commaSplittedString,
      pt_Id: this.plantId
    }
    this.Checkervice.UpdateChecklistHeaderSubHeader(data).subscribe((data: any) => {
      this.toaster.success('Success', 'Header/SubHeader Added Successfully');
      this.spinner.hide()
    }, error => {
      this.toaster.error('Error', error.error.message);
      this.spinner.hide();
    });
  }
}
