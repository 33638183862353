import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductdetailComponent } from 'src/app/screens/productdetail/productdetail.component';
import { KpimasterComponent } from '../KPIs Management/kpimaster/kpimaster.component';
import { ReactiveMaintenanceConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/reactive-maintenance-configuration/reactive-maintenance-configuration.component';
import { KpiMastersDashboardComponent } from './kpi-masters-dashboard/kpi-masters-dashboard.component';
import { ManagerExecutionFormComponent } from './manager-execution-form/manager-execution-form.component';
import { ManagerExecutionComponent } from './manager-execution/manager-execution.component';
import { OperatorExecutionFormComponent } from './operator-execution-form/operator-execution-form.component';
import { OperatorExecutionComponent } from './operator-execution/operator-execution.component';
import { PmDashboardComponent } from './pm-dashboard/pm-dashboard.component';
import { PrevTaskFormComponent } from './preventive-maintenance/prev-task-form/prev-task-form.component';
import { RmDashboardCloneComponent } from './rm-dashboard-clone/rm-dashboard-clone.component';
import { RmDashboardComponent } from './rm-dashboard/rm-dashboard.component';
import { TechnisionHistoryComponent } from './skill-matrix/technision-history/technision-history.component';
import { TechnicianExecutionListComponent } from './technician-execution-list/technician-execution-list.component';
import { TechnicianExecutionComponent } from './technician-execution/technician-execution.component';
// import { PlantModelComponent } from './home/plant-model/plant-model.component';

const routes: Routes = [
  { path: 'ProductDetail', component: ProductdetailComponent },
  { path: 'operator', component: OperatorExecutionComponent },
  { path: 'operator-form', component: OperatorExecutionFormComponent },
  { path: 'technician', component: TechnicianExecutionListComponent },
  { path: 'technician-form', component: TechnicianExecutionComponent },
  { path: 'manager', component: ManagerExecutionComponent },
  { path: 'manager-form', component: ManagerExecutionFormComponent },
  { path: 'RMMaster', component: ReactiveMaintenanceConfigurationComponent },
  { path: '', component: ReactiveMaintenanceConfigurationComponent },
  { path: 'RM/Dashboard', component: RmDashboardComponent },
  { path: 'main-kpi-dashboard', component: KpiMastersDashboardComponent },
  { path: 'pm-dashboard', component: PmDashboardComponent },
  { path: 'RM/DashboardClone', component: RmDashboardCloneComponent },
  { path: 'TechnisionHistoryComponent', component: TechnisionHistoryComponent },
  {
    path: 'prevMaintenance',
    loadChildren: () =>
      import('./preventive-maintenance/preventive-maintenance.module').then(
        (m) => m.PreventiveMaintenanceModule
      ),
  },
  {
    path: 'skill-matrix',
    loadChildren: () =>
      import('./skill-matrix/skill-matrix.module').then(
        (m) => m.SkillMatrixModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PmRoutingModule {}
