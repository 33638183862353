import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TechnicianService } from 'src/app/services/technician.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-technision-history',
  templateUrl: './technision-history.component.html',
  styleUrls: ['./technision-history.component.scss']
})
export class TechnisionHistoryComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  breadcrumList: any;
  completeTime : any;
  score: any = {
    Group: '',
    Plant: '',
    Machine: '',
    TechId: '',
    WOType: '',
    Score: '',
    Picture: '',
    CreatedBy: '',
    userId : '',
    techId  :"",
    ID: ''
  }
  constructor( 
    private spinner: NgxSpinnerService, 
    private technicianService: TechnicianService,
    private toaster: ToastrService,
    private userManagementService: UserManagementService,
    private router: Router,) { }
  technicianHistory :any = [];
  listArray : any;
  taskArray : any;
  spinnerText : string = 'Fetching Technician Data';
  technicianColumns: string[] = ['S.No','Start Time','Complete Time','CreatedAt','CreatedBy','Picture'];
  technicianDataSource = new MatTableDataSource();

  ngOnInit(): void {
    const techData = this.technicianService.getSelTech();
    if(techData) {
      this.getTechnicianList(techData.UserId);
    }
   
    this.GetBreadcrumList();
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.spinnerText = 'Loading Menu Data';
    this.spinner.show();
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.spinner.hide();
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  getTechnicianList(userId : any): void {

    this.spinner.show();
    // this.spinnerText = "Fetching Technician Data";
    this.technicianService.getTechnisionHistory(userId).subscribe((res: any) => {
      this.spinner.hide();
      this.technicianHistory = res.data;
     console.log(this.technicianHistory);
      this.technicianDataSource = new MatTableDataSource(this.technicianHistory);
      this.technicianDataSource.sort = this.sort;
      this.technicianDataSource.paginator = this.paginator;
    },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.error.message);
      }
    );
  }
    
}
