import { NestedTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderProcessingElement } from 'src/app/interface/data-source';
import { PlantGroupElement } from 'src/app/interface/plant-model';
import { AreaService } from 'src/app/services/area.service';
import { ChecklistService } from 'src/app/services/checklist.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { KpitreeService } from '../../services/kpitree.service'
import Swal from 'sweetalert2';
import { MatOption } from '@angular/material/core';
import { BtnCellRendererCilMaster } from 'src/app/btn-cell-rendererCILMaster.component';
import * as _ from 'lodash';
import { BtnCellRendererCheckListConfiguration } from 'src/app/btn-cellRenderer-quality-checklist-configuration';
import { GridConfiguration } from 'src/app/interface/grid-model';
import { GridService } from 'src/app/services/grid.service';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-check-List',
  templateUrl: './check-List.component.html',
  styleUrls: ['./check-List.component.scss'],
})

export class CheckListComponent implements OnInit {
  currentImagePath!: any;
  pageId!: any;
  fileType!: string;
  doc!: any;
  executionList: any[];
  configTypes: any[] = [];
  gridApi: any;
  tempArray = [];
  gridColumnApi: any;
  rowData: any[] = [];
  columnDefs: any[];
  frameworkComponents: any;
  checklistIdRowId: number;
  ButtonType: any;
  chf_Id: any;
  tableIndex: any;

  checkListdetailId: any;
  gridOptionsCheckList: any = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      wrapText: true,
      filter: true,
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      actionCellRenderer: BtnCellRendererCheckListConfiguration,
      customLoadingOverlay: CustomLoadingOverlay,
    },
  };
  ColumnDefsCheckList: any = [
    { field: 'action', headerName: 'Action', filter: false, cellRenderer: 'actionCellRenderer', width: 200,  },
    {
      field: 'listName', headerName: 'CheckList Name',  tooltipValueGetter: function (params) {
        return params.value;
      },
    },
    {
      field: 'AreaName', headerName: 'Area Name',  tooltipValueGetter: function (params) {
        return params.value;
      },
    },
    {
      field: 'listType', headerName: 'Checklist Type',  tooltipValueGetter: function (params) {
        return params.value;
      },
    },
    {
      field: 'ExecutionType', headerName: 'Execution Type',  tooltipValueGetter: function (params) {
        return params.value;
      },
    },
    {
      field: 'Pu_Desc', headerName: 'Units',  tooltipValueGetter: function (params) {
        return params.value;
      },
    },

    // Add other columns
  ];
  checkListRowData: any;
  gridApiCheckList: any;
  gridColumnCheckListApi: any;
  loadingOverlayComponent: string;
  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private ChecklistService: ChecklistService,
    private userService: UserManagementService,
    private kpiMaster: KpimasterService,
    private kpiservice: KpitreeService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private AreaServices: AreaService,
    private enterpriseGroupService: EnterPriseGroupService,
    private machineService: MachineMappingService,
    private gridService: GridService,
  ) {
    this.frameworkComponents = {
      btnRenderer: BtnCellRendererCilMaster
    };
    this.columnDefs = [
      {
        headerName: 'Action',
        cellRenderer: (params: any): any => {
          return `
            <button mat-mini-fab="" data-toggle="tooltip" data-placement="top" title="Edit"  color="primary" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" data-toggle="tooltip" data-placement="top" title="Delete"  color="warn" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn" ng-reflect-color="warn"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">delete</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>`;
        },
        field: 'action',
        minWidth: 100,
        cellRendererParams: {
        },
      },
      {
        headerName: 'Order',
        minWidth: 200,
        field: 'order',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Name',
        minWidth: 200,
        field: 'name',
        sortable: true,
        filter: true
      },
      {
        headerName: 'Value',
        minWidth: 160,
        field: 'value',
        sortable: true,
        filter: true
      },
      {
        headerName: 'Type',
        minWidth: 200,
        field: 'type',
        sortable: true,
        filter: true,
        cellRenderer: (params: any) => {
          if (params.data.type === '1') {
            return `
            Header`;
          } else {
            return `
           Footer
          `;
          }
        },
      }

    ];
    this.loadingOverlayComponent = 'customLoadingOverlay';
  }
  ChecklistDataForm!: FormGroup;
  CheckListRowData!: FormGroup;
  ConfigurationForm!: FormGroup;
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  goodImgArray: File[] = [];
  badImgArray: File[] = [];
  UnitArray: any[] = [];
  UnitArrayName: any[] = [];
  UnitText!: any;
  fileSave!: File;
  plantList!: any[];
  UnitName: any;
  MachineId!: any;
  formMode!: boolean;
  chfformMode: boolean = true;
  itemName!: string;
  itemType!: any;
  itemGroup!: any;
  goodExample!: any;
  badExample!: any;
  Shiftdata!: any;
  shiftList!: any[];
  displayMaximizable = false;
  unitsArray: any = [];
  goodImgSrc!: string;
  badImgSrc!: string;
  updateOrderprocessingData = false;
  images: any[] = [];
  imageIndexOne = 0;
  products = new FormControl();
  productList: string[] = ['Product 1', 'Product 2', 'Product 3'];
  fileDesc: any;
  itemColumns: string[] = [
    'itemActions',
    'items',
    'ListName',
    'ItemType',
    'standard',
    'minimum',
    'maximum',
    'good',
    'bad',

  ];
  displayedColumns: string[] = ['index', 'value', 'Action'];

  OrderProcessingElementData: OrderProcessingElement[] = [];
  customerManagementColumns: string[] = [
    'action',
    'listName',
    'Area',
    'createdOn',
    'executionType',
    'units',
  ];

  adduserColumns: string[] = ['name', 'email', 'password', 'products'];
  NCRDataForm!: FormGroup;
  AddGroupForm!: FormGroup;
  AddDropDownForm!: FormGroup;
  customerManagementDataSource = new MatTableDataSource([]);
  CheckListAddModel!: MatTableDataSource<any>;
  DropdownElemenet !: MatTableDataSource<any>;
  isaddNewChecklistModeIVisible = false;
  isPlantGroupModelVisible = false;
  AreaFiltered: any;
  shiftFiltered: any;
  items!: any[];
  areas!: any[];
  types!: any[];
  fileCount!: number;
  GroupAdded: string[] = [];
  DropDownAdded!: any[];
  addtabledata: any[] = [];
  adddropdowndata: Array<{
    id: number;
    value: string;
  }> = [];
  AreaFilter: any;
  ListType: any;
  ItemType: any;
  checklistId!: number;
  counter = 1;
  groupListDDL!: any[];
  groupId!: number;
  plantId!: number;
  checklist: any[];
  shift!: any;
  isExecuted!: any;
  IsWrite: any;
  headersFormGroup: FormGroup;
  DuplicateCheckListForm: FormGroup;
  subheadersFormGroup: FormGroup;
  Headers: any;
  subHeaders: any;
  selected: any;
  imageSrc: string;
  @ViewChild('allSelectedAreaAdd') private allSelectedAreaAdd: MatOption;
  @ViewChild('allSelectedUnitAdd') private allSelectedUnitAdd: MatOption;
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('displayDuplicateModal') private displayDuplicateModal!: TemplateRef<any>;
  @ViewChild('displayGroupModal') private displayGroupModal!: TemplateRef<any>;
  @ViewChild('displayDropDownModal') private displayDropDownModal!: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChildren(MatPaginator) paginator1 = new QueryList<MatPaginator>();
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('configurationModal') private configurationModal!: TemplateRef<any>;
  @ViewChildren(MatSort) sort1 = new QueryList<MatSort>();
  viewKpiInformation!: boolean;

  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  public drop!: string;
  public bad!: string;
  public good!: string;
  public item!: string;
  public itemTypeid!: number;
  public id!: number;
  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
  };
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;


  ngOnInit(): void {
    this.imageSrc = environment.O3CoreApiImageUrl;
    this.fileCount = 0;
    this.formMode = true;

    this.AddGroupForm = this.fb.group({
      dropname: ['', Validators.required],
    });
    this.DuplicateCheckListForm = this.fb.group({
      name: ['', Validators.required],
    });

    this.AddDropDownForm = this.fb.group({
      dropdown: ['', Validators.required]
    })

    this.ChecklistDataForm = this.fb.group({
      checktype: ['', Validators.required],
      area: [''],
      plant: ['', Validators.required],
      group: ['', Validators.required],
      checkname: ['', Validators.required],
      itemname: [''],
      drop: [''],
      itemtype: [''],
      executionType: ['', Validators.required],
      good: [''],
      bad: [''],
      Unit: [''],
    });
    this.CheckListRowData = this.fb.group({
      itemName: [''],
      goodExample: [],
      standard: [],
      badExample: [''],
      itemGroup: [''],
      itemType: [''],

    });
    this.ConfigurationForm = this.fb.group({
      name: ['', [Validators.required]],
      value: ['', Validators.required],
      type: ['', Validators.required],
      order: ['', Validators.required],
    });
    this.executionList = [
      { executionTypeId: 2, executionType: 'Checklist Execution' },
    ]
    this.configTypes = [
      { Id: '1', name: 'Header' },
      { Id: '2', name: 'Footer' }
    ]
    this.getGroupData();
    this.getPlantByGroup();
    this.GetMenuAccess();
    this.GetBreadcrumList();
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.pageId = data.BreadcrumList[data.BreadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  EditdeletedataTable(fullrow: any): void {
    this.getGroupData();
    this.getArea();
    this.getListType();
    this.getItemType();
    this.formMode = false;
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.groupId,
    };
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plantList = data.DepartList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  deleteRow(element: any, index: number): void {
    for (let i = 0; i < this.addtabledata.length; i++) {
      if (this.addtabledata[i].id === element.id) {
        this.addtabledata.splice(i, 1);
        this.goodImgArray.splice(i, 1);
        this.badImgArray.splice(i, 1);
      }
    }

    this.CheckListAddModel = new MatTableDataSource<any>(this.addtabledata);
    this.getPaginatorForSecond(this.CheckListAddModel, 1);
  }

  deletedataTable(column: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete CheckList!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        this.addtabledata = this.addtabledata.filter((obj) => obj !== column);
        this.CheckListAddModel = new MatTableDataSource<any>(this.addtabledata);
        (success: any) => {
          Swal.fire(
            'Deleted!',
            'Your selected Row has been deleted.',
            'success'
          );
        };
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected row is safe :)', 'error');
      }
    });
  }

  addTable(): void {
    if (this.addtabledata.length > 0) {
      const chkItem = this.addtabledata.find((x) => x.item === '');
      const chkGroup = this.addtabledata.find((x) => x.drop === '');
      const chkType = this.addtabledata.find((x) => x.itemtype === 0);
      if (chkGroup && chkItem && chkType) {
        this.toaster.error('Error', 'Please Add Group, Item and Itemtype');
        return;
      }
      else if (chkGroup) {
        this.toaster.error('Error', 'Please Add Group');
        return;
      }
      else if (chkItem) {
        this.toaster.error('Error', 'Please Add Item');
        return;
      }
      else if (chkType) {
        this.toaster.error('Error', 'Please Add Item Type');
        return;
      }
    }

    const row = {
      id: this.counter,
      item: this.CheckListRowData.value.itemName,
      itemtype: 0,
      drop: this.CheckListRowData.value.itemGroup,
      standard: this.CheckListRowData.value.standard,
      checkListDetailId: 0,
      goodImage: '',
      badImage: '',
      goodExampleImageName: '',
      badExampleImageName: '',
      dropDown: [],
      displaybtn: false
    };

    const f = new File([''], 'filename');

    this.addtabledata.push(row);
    this.goodImgArray.push(f);
    this.badImgArray.push(f);

    this.CheckListAddModel = new MatTableDataSource<any>(this.addtabledata);
    this.getPaginatorForSecond(this.CheckListAddModel, 1); // already 3

    this.counter = this.counter + 1;
  }

  SaveGroup(): void {
    const key = this.AddGroupForm.get('dropname')?.value;

    if (!this.GroupAdded.includes(key.replace(/\s+/g, ''))) {
      this.GroupAdded.push(key);
      this.toaster.success('Success', 'Group added successfully');
    }
    else {
      this.toaster.warning('warning', 'Group already exist')
    }
    this.AddGroupForm.reset();
  }

  removefromList(i): void {
    this.adddropdowndata.splice(i, 1);
    this.DropdownElemenet = new MatTableDataSource<any>(this.adddropdowndata);
  }

  editDropdownItem(data, i): void {
    this.AddDropDownForm.get('dropdown').setValue(data.value);
  }

  SaveDropDown(): void {
    const key = {
      id: 0,
      value: this.AddDropDownForm.get('dropdown')?.value,
      checklistdetailId: this.checkListdetailId
    }
    if (!this.adddropdowndata.some(el => el.value === key.value.replace(/\s+/g, ''))) {
      if (this.checkListdetailId !== 0 && this.checkListdetailId !== undefined) {
        this.ChecklistService.savedropdownitem(key).subscribe((res) => {
          this.toaster.success('Success', ' saved successfully');
          this.getDropdown(this.checkListdetailId);
        })
      }
      else {
        this.adddropdowndata.push(key);
        this.addtabledata[this.tableIndex].dropDown.push(key)
        this.toaster.success('Success', 'Element added successfully');
      }
    }
    else {
      this.toaster.warning('warning', 'Value Should not Duplicated');
    }
    this.DropdownElemenet = new MatTableDataSource<any>(this.adddropdowndata);
    this.AddDropDownForm.reset();
  }

  getDropdown(params: any) {
    const key = {
      DetailId: params
    }
    this.ChecklistService.getdropdowndatabyId(key).subscribe((res) => {
      this.adddropdowndata = res;
      this.DropdownElemenet = new MatTableDataSource<any>(this.adddropdowndata);
    })
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.customerManagementDataSource.filter = filterValue.trim().toLowerCase();
    if (this.customerManagementDataSource.paginator) {
      this.customerManagementDataSource.paginator.firstPage();
    }
  }

  getPaginator(): void {
    setTimeout(() => {
      this.customerManagementDataSource.paginator = this.paginator;
      this.customerManagementDataSource.sort = this.sort;
    }, 1000);
  }

  getPaginatorForSecond(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator1.toArray()[index];
      data.sort = this.sort1.toArray()[index];
    }, 1000);
  }

  getArea(): any {
    const key = {
      egId: this.groupId,
      plantId: this.plantId,
    };
    this.AreaServices.getMasterArea(key).subscribe(
      (areadata: any) => {
        this.AreaFilter = areadata;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  getListType(): any {
    const key = {
      plantId: this.plantId,
      groupId: this.groupId,
    }
    this.ChecklistService.getTypesByPlant(key).subscribe(
      (areadata: any) => {
        this.types = areadata;
        this.ListType = this.types.slice();
      },
      (error: any) => {
        this.toaster.error('Error', error?.error?.message);
        this.spinner.hide();
      }
    );
  }

  getItemType(): any {
    this.ChecklistService.getItemTypes().subscribe(
      (areadata: any) => {
        this.items = areadata;
        this.ItemType = this.items.slice();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  getlist(key: any): void {
    this.gridApiCheckList.showLoadingOverlay();
    this.groupId = key.groupId;
    this.plantId = key.plantId;
    this.ChecklistDataForm.get('group')?.setValue(key.groupId);
    this.ChecklistDataForm.get('plant')?.setValue(key.plantId);
    this.getplantUnit();
    this.ChecklistService.getCheckList(key).subscribe(
      (checklist: any) => {
        this.checkListRowData = checklist;
        setTimeout(() => {
          this.autoSizeAllColumns();
        }, 1000);
        this.gridOptionsCheckList.api.hideOverlay();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.gridOptionsCheckList.api.hideOverlay();
      }
    );
  }

  autoSizeAllColumns = () => {
    const allColumnIds = [];
    this.gridColumnCheckListApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    this.gridColumnCheckListApi.autoSizeColumns(allColumnIds);
  };

  resetForm(): void {
    this.addtabledata = [];
    this.GroupAdded = [];
    this.DropDownAdded = [];
    this.addtabledata.length = 0;
    this.CheckListAddModel = new MatTableDataSource<any>(this.addtabledata);
    this.goodImgArray = [];
    this.badImgArray = [];
    this.ChecklistDataForm.reset();
  }

  popupMaxIndex(): void {
    setTimeout(() => {
      var overlayEl = document.getElementsByClassName('cdk-overlay-container');
      for (var i = 0; i < overlayEl.length; i++) {

        (overlayEl[i] as HTMLElement).style.zIndex = '500';
      }
    }, 50);
  }

  addGroupModal(): void {
    this.dialog.open(this.displayGroupModal, { height: '31%', width: '40%', disableClose: true });
    this.popupMaxIndex();
    this.AddGroupForm.reset();
  }

  updateCheckList(): any {
    if (this.addtabledata.length > 0) {
      const chkItem = this.addtabledata.find((x) => x.item === '');
      const chkGroup = this.addtabledata.find((x) => x.drop === '');
      const chkType = this.addtabledata.find((x) => x.itemtype === 0);
      if (chkItem || chkGroup || chkType) {
        this.toaster.error('Error', 'Fill Data Correctly');
        return;
      }
    } else {
      this.toaster.error('Error', 'Fill Data Correctly');
      return;
    }

    const formdata = new FormData();
    for (let index = 0; index < this.goodImgArray.length; index++) {
      formdata.append('goodImages', this.goodImgArray[index]);
    }
    for (let index = 0; index < this.badImgArray.length; index++) {
      formdata.append('badImages', this.badImgArray[index]);
    }
    console.log(this.addtabledata);

    this.tempArray.forEach(element => {
      var foundIndex = this.addtabledata.findIndex(x => x.id == element.Index + 1);
      if (foundIndex != -1) {
        this.addtabledata[foundIndex].dropDown = element.arrayData;
      }

    });

    const checkList = {
      checkListId: this.checklistId,
      listType: this.ChecklistDataForm.value.checktype,
      qualityAreaId: this.ChecklistDataForm.value.area,
      listName: this.ChecklistDataForm.value.checkname,
      executionType: this.ChecklistDataForm.value.executionType,
      groupId: this.ChecklistDataForm.value.group,
      qualityCheckListDetails: this.addtabledata,
      units: this.ChecklistDataForm.value.Unit,
    };
    formdata.append('checkList', JSON.stringify(checkList));

    this.ChecklistService.updateCheckListdata(formdata).subscribe(
      (response: any) => {
        const key = {
          plantId: this.plantId,
          groupId: this.groupId,
        };
        this.getlist(key);
        this.toaster.success('Success', 'Check List Updated Successfuly');
        this.closeCheckListModel();

        this.resetForm();
        this.resetchecklistDataForm();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  addDropDownModal(element, i): void {
    this.tableIndex = i;
    this.adddropdowndata = []
    if (this.formMode) {
      this.checkListdetailId = 0;
      this.DropdownElemenet = new MatTableDataSource<any>(this.addtabledata[i].dropDown);
      this.dialog.open(this.displayDropDownModal, { height: 'auto', width: '60%', maxHeight: '90vh', disableClose: true })
      this.popupMaxIndex();
    }
    else {
      if (element.checkListDetailId !== 0) {
        this.getDropdown(element.checkListDetailId);
      }
      else {
        var NotSavedArray = this.tempArray.filter(obj => obj.Index == this.tableIndex);
        if (NotSavedArray.length > 0) {
          if (NotSavedArray[0].arrayData.length > 0) {
            this.DropdownElemenet = new MatTableDataSource<any>(NotSavedArray[0].arrayData);
            this.adddropdowndata = NotSavedArray[0].arrayData;
          }
          else {
            this.DropdownElemenet = new MatTableDataSource<any>();
          }
        }
        else {
          this.DropdownElemenet = new MatTableDataSource<any>();
        }
      }
      this.checkListdetailId = element.checkListDetailId;
      let dialogRef = this.dialog.open(this.displayDropDownModal, { height: '', width: '60%', disableClose: true })
      this.popupMaxIndex();
      dialogRef.afterClosed().subscribe((result) => {
        var obj = {
          Index: this.tableIndex,
          arrayData: []
        };
        this.adddropdowndata.forEach(element => {
          obj.arrayData.push(element);
        });
        var foundIndex = this.tempArray.findIndex(x => x.Index == this.tableIndex);
        if (foundIndex != -1) {
          this.tempArray.splice(foundIndex, 1);
        }
        this.tempArray.push(obj);
      });
    }
  }

  resetCheckList(): void {
    this.addtabledata = [];
    this.addtabledata.length = 0;
    this.CheckListAddModel = new MatTableDataSource<any>(this.addtabledata);
  }

  addCheckList(): any {
    this.spinner.show();
    if (this.addtabledata.length > 0) {
      const chkItem = this.addtabledata.find((x) => x.item === '');
      const chkGroup = this.addtabledata.find((x) => x.drop === '');
      const chkType = this.addtabledata.find((x) => x.itemtype === 0);
      if (chkGroup && chkItem && chkType) {
        this.spinner.hide();
        this.toaster.error('Error', 'Please Add Group, Item and Itemtype');
        return;
      }
      else if (chkGroup) {
        this.spinner.hide();
        this.toaster.error('Error', 'Please Add Group');
        return;
      }
      else if (chkItem) {
        this.spinner.hide();
        this.toaster.error('Error', 'Please Add Item');
        return;
      }
      else if (chkType) {
        this.spinner.hide();
        this.toaster.error('Error', 'Please Add Item Type');
        return;
      }
    } else {
      this.spinner.hide();
      this.toaster.error('Error', 'Fill Data Correctly');
      return;
    }
    const formdata = new FormData();
    for (let index = 0; index < this.goodImgArray.length; index++) {
      formdata.append('goodImages', this.goodImgArray[index]);
    }
    for (let index = 0; index < this.badImgArray.length; index++) {
      formdata.append('badImages', this.badImgArray[index]);
    }
    const checkList = {
      userId: JSON.parse(localStorage.user).UserId,
      listType: this.ChecklistDataForm.value.checktype,
      qualityAreaId: this.ChecklistDataForm.value.area,
      listName: this.ChecklistDataForm.value.checkname,
      plantId: this.ChecklistDataForm.value.plant,
      groupId: this.ChecklistDataForm.value.group,
      executionType: this.ChecklistDataForm.value.executionType,
      qualityCheckListDetails: this.addtabledata,
      units: this.ChecklistDataForm.value.Unit,
    };
    formdata.append('checkList', JSON.stringify(checkList));

    this.ChecklistService.addCheckListdata(formdata).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.toaster.success('CheckList Added Successfuly');
        const key = {
          plantId: this.plantId,
          groupId: this.groupId,
        };
        this.getlist(key);
        this.goodImgArray = [];
        this.badImgArray = [];
        this.resetForm();
        this.resetCheckList();
        this.closeCheckListModel();
        this.ChecklistDataFormReset();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getCheckListById(chkListId: number): void {
    this.getPlantByGroup();
    this.getArea();
    this.spinner.show();
    const checkList = {
      checkListId: chkListId,
    };
    this.goodImgArray = [];
    this.badImgArray = [];
    this.ChecklistService.getCheckListById(checkList).subscribe(
      (response: any) => {
        this.ChecklistDataForm.get('group')?.setValue(this.groupId);
        this.ChecklistDataForm.get('plant')?.setValue(this.plantId);
        this.ChecklistDataForm.get('checktype')?.setValue(
          response.chklists.listType.qualityTypeId
        );
        this.ChecklistDataForm.get('checkname')?.setValue(
          response.chklists.listName
        );
        let a = response.chklists.qualityArea?.map((c: any) => c.areaId);
        this.ChecklistDataForm.get('area')?.setValue(
          a
        );
        this.ChecklistDataForm.get('executionType')?.setValue(response.chklists.executionType);
        const productlist = response.unit;
        const p = response.unit?.map((c: any) => c.puId);
        this.ChecklistDataForm.get('Unit')?.setValue(p);
        if (response.chklists.qualityCheckListDetails != undefined) {
          response.chklists.qualityCheckListDetails.forEach((element: any, index) => {
            const row = {
              id: this.counter,
              item: element.itemName,
              itemtype: element.itemType,
              drop: element.itemGroup,
              checkListDetailId: element.checkListDetailId,
              standard: element.standard,
              minValue: element.minValue,
              maxValue: element.maxValue,
              goodImage: this.imageSrc + element.goodExampleImage,
              badImage: this.imageSrc + element.badExampleImage,
              goodExampleImageName: element.goodExampleImageName,
              badExampleImageName: element.badExampleImageName,
              dropDown: element.dropDown,
              displaybtn: false
            };
            this.addtabledata.push(row);
            this.counter = this.counter + 1;
            this.GroupAdded.push(element.itemGroup);
            const f = new File([''], 'filename');
            this.goodImgArray.push(f);
            this.badImgArray.push(f);
            this.getExecutionTypeByUnit();
          });
        }
        this.GroupAdded = this.uniqueBy(this.GroupAdded, JSON.stringify);
        this.addtabledata.forEach((elem, index) => {
          if (elem.itemtype == 6) {
            this.addtabledata[index].displaybtn = true
          }
          else {
            this.addtabledata[index].displaybtn = false

          }
        })
        this.CheckListAddModel = new MatTableDataSource<any>(this.addtabledata);
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  uniqueBy(a: any, key: any): any {
    const seen: any = {};
    return a.filter((item: any) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  OpenEditCheckList(element: any, displayRoleModal: any): void {
    this.checklistId = element.qualityCheckListId;
    this.isExecuted = element.isExecuted;
    this.resetForm();
    this.getGroupData();
    this.getListType();
    this.getItemType();
    this.getCheckListById(element.qualityCheckListId);
    this.dialog.open(displayRoleModal, {
      width: '98vw !important',
      height: '90vh',
      minWidth: '98vw',
      disableClose: true
    });
    this.popupMaxIndex();
    this.formMode = false;
  }

  refreshUnit(): void {
    this.ChecklistDataForm.get('Unit')?.reset();
    this.UnitArray = [];
    this.UnitArrayName = [];
    this.MachineId = undefined;
    this.UnitText = this.UnitArrayName.toString();
  }

  deletedropDownById(data, i): void {
    if (data.id == 0) {
      this.adddropdowndata.splice(i, 1);
      this.DropdownElemenet = new MatTableDataSource<any>(this.adddropdowndata);
      var foundIndex = this.tempArray.findIndex(x => x.Index == i);
      if (foundIndex != -1) {
        this.tempArray.splice(foundIndex, 1)
      }
    }
    else {
      this.ChecklistService.deleteItemdropdown(data).subscribe(
        (res) => {
          this.adddropdowndata.splice(i, 1);
          this.DropdownElemenet = new MatTableDataSource<any>(this.adddropdowndata);
          this.toaster.success(" Element deleted");
        })
    }
  }

  deletedata(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete CheckList!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          checkListId: element.qualityCheckListId,
        };

        this.ChecklistService.deletechecklist(key).subscribe(
          (success: any) => {
            Swal.fire(
              'Deleted!',
              'Your selected CheckList has been deleted.',
              'success'
            );
            const key1 = {
              plantId: this.plantId,
              groupId: this.groupId,
            };
            this.getlist(key1);
          },
          (error: any) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected role is safe :)', 'error');
      }
    });
  }

  ChecklistDataFormReset(): void {
    this.ChecklistDataForm.reset();
  }

  resetchecklistDataForm(): void {
    this.ChecklistDataForm.reset();
  }

  openModal(displayRoleModal: any): void {
    this.ChecklistDataForm.get('Unit').setValue('')
    this.executionList = [];
    this.getExecutionTypeByUnit();
    this.getGroupData();
    this.resetchecklistDataForm();
    this.resetForm();
    this.addTable();
    this.ChecklistDataForm.get('group')?.setValue(this.groupId);
    this.getPlantByGroup();
    this.ChecklistDataForm.get('plant')?.setValue(this.plantId);
    this.getListType();
    this.formMode = true;
    this.getItemType();
    this.getArea();
    this.ChecklistDataForm.get('executionType')?.setValue(this.executionList[0].executionType);
    this.dialog.open(displayRoleModal, {
      width: '98vw !important',
      height: '90vh',
      minWidth: '98vw',
      disableClose: true
    });
    this.popupMaxIndex();
  }

  openTreeModal(event: any): void {
    this.updateOrderprocessingData = true;
    this.UnitArrayName = [];
    this.UnitArray = [];
    this.ChecklistDataForm.get('Unit')?.reset();

    const key = {
      EGId: this.ChecklistDataForm.value.group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.isPlantGroupModelVisible = true;
        this.expandAllNode();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onNodeSelect(node: any, event: any): void {
    if (event.target.checked === true) {
      this.UnitArray.push(node.PrimaryId);
      this.UnitArrayName.push(node.text);
      this.MachineId = node.PrimaryId;
      this.UnitText = this.UnitArrayName.toString();
      this.ChecklistDataForm.get('Unit')?.setValue(this.UnitText);
    } else if (event.target.checked === false) {
      for (let i = 0; i < this.UnitArray.length; i++) {
        if (this.UnitArray[i] === node.PrimaryId) {
          this.UnitArray.splice(i, 1);
          this.UnitArrayName.splice(i, 1);
          this.UnitText = this.UnitArrayName.toString();
          this.ChecklistDataForm.get('Unit')?.setValue(this.UnitText);
        }
      }
    }
  }

  SelectUnitName(unitname: any): void {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
    }
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }

  closePlantGroupModel(): void {
    this.updateOrderprocessingData = false;
  }

  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  closeCheckListModel(): void {
    this.dialog.closeAll();
  }

  columnEvent(event: any, data: any): void {
    this.itemName = event.target.value;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.addtabledata.length; i++) {
      if (this.addtabledata[i].id === data.id) {
        this.addtabledata[i].item = event.target.value;
      }
    }
  }
  standardEvent(event: any, data: any): void {
    for (let i = 0; i < this.addtabledata.length; i++) {
      if (this.addtabledata[i].id === data.id) {
        this.addtabledata[i].standard = event.target.value;
      }
    }
  }

  minEvent(event: any, data: any): void {
    for (let i = 0; i < this.addtabledata.length; i++) {
      if (this.addtabledata[i].id === data.id) {
        let val = event.target.value;
        let floatNum = parseFloat(val).toFixed(2);
        let num;
        if (floatNum.toString().split('.')[1] == '0') {
          num = parseInt(floatNum);
        }
        else {
          num = parseFloat(val);
        }
        this.addtabledata[i].minValue = num;
        console.log(this.addtabledata[i].minValue)
      }
    }
  }
  maxEvent(event: any, data: any): void {
    debugger
    for (let i = 0; i < this.addtabledata.length; i++) {
      if (this.addtabledata[i].id === data.id) {
        let val = event.target.value;
        let floatNum = parseFloat(val).toFixed(2);
        let num;
        if (floatNum.toString().split('.')[1] == '0') {
          num = parseInt(floatNum);
        }
        else {
          num = parseFloat(val);
        }
        this.addtabledata[i].maxValue = num;
        console.log(this.addtabledata[i].maxValue)
      }
    }
  }

  imageZoomModal(imagePath: string): void {
    this.currentImagePath = imagePath.indexOf('/null') > -1 ? null : imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%', disableClose: true });
    this.popupMaxIndex();
  }


  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  columnItemEvent(event: any, data: any, i: number): void {
    this.itemType = event.value;
    if (event.value == 5) {
      this.addtabledata[i].minValue = null;
      this.addtabledata[i].maxValue = null;
      this.CheckListAddModel.data.forEach((elem, index) => {
        if (elem.id === data.id) {
          this.CheckListAddModel.data[index].displaybtn = true;
          this.CheckListAddModel = new MatTableDataSource<any>(this.CheckListAddModel.data);
        }

      })
    }
    else {
      this.addtabledata[i].minValue = null;
      this.addtabledata[i].maxValue = null;
      this.CheckListAddModel.data.forEach((elem, index) => {
        if (elem.id === data.id) {
          this.CheckListAddModel.data[index].displaybtn = false;
          this.CheckListAddModel = new MatTableDataSource<any>(this.CheckListAddModel.data);
        }

      })
    }



    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.addtabledata.length; i++) {
      if (this.addtabledata[i].id === data.id) {
        this.addtabledata[i].itemtype = event.value;
      }
    }
  }

  columnGroupEvent(event: any, data: any): void {
    this.drop = event.value;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.addtabledata.length; i++) {
      if (this.addtabledata[i].id === data.id) {
        this.addtabledata[i].drop = event.value;
      }
    }
  }

  uploadedFile(event: any, index: number): void {
    const found = this.goodImgArray[index];

    if (found) {
      this.goodImgArray[index] = event.target.files[0];
      this.addtabledata[index].goodExampleImageName =
        event.target.files[0].name;
      this.addtabledata[index].goodImage = event.target.files[0];
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (e: any) => {
        // called once readAsDataURL is completed
        this.addtabledata[index].goodImage = e.target.result;
      };
    } else {
      if (event.target.files.length > 0) {
        this.fileSave = event.target.files[0];
        this.addtabledata[index].goodExampleImageName =
          event.target.files[0].name;
        const reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onload = (e: any) => {
          // called once readAsDataURL is completed
          this.addtabledata[index].goodImage = e.target.result;
        };
        this.goodImgArray.push(this.fileSave);
      }
    }
    console.log(this.goodImgArray);
    this.addtabledata[index].HideViewIcon = true;

  }

  uploadedFile2(event: any, index: number): void {
    const found = this.badImgArray[index];
    if (found) {
      this.badImgArray[index] = event.target.files[0];
      this.addtabledata[index].badExampleImageName = event.target.files[0].name;
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (e: any) => {
        // called once readAsDataURL is completed
        this.addtabledata[index].badImage = e.target.result;
      };
    } else {
      if (event.target.files.length > 0) {
        this.fileSave = event.target.files[0];
        this.addtabledata[index].badExampleImageName =
          event.target.files[0].name;
        const reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onload = (e: any) => {
          // called once readAsDataURL is completed
          this.addtabledata[index].badImage = e.target.result;
        };
        this.badImgArray.push(this.fileSave);
      }
    }
    this.addtabledata[index].HideViewIcon1 = true;
  }

  getplantUnit(): void {
    const data = {
      ptId: this.plantId,
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.kpiservice.plantUnit(data).subscribe((res) => {
      this.unitsArray = res;
    },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  openFilePopUp(elem: any, index: number, n: number): void {
    if (n === 1) {
      const array = elem.goodImage.split('.');
      const fileType = array[array.length - 1];
      this.doc = elem.goodImage;
      console.log("doc");
      console.log(this.doc);
      if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif' || fileType == 'webp') {
        this.images = [];
        this.fileType = 'img';
        this.images.push(this.doc);
      }
      else {
        this.fileType = 'doc';
        this.images.push(this.doc);
      }
      const file = this.goodImgArray[index];
      this.displayMaximizable = true;
    }
    else if (n === 2) {
      const array = elem.badImage.split('.');
      const fileType = array[array.length - 1];
      this.doc = elem.badImage;
      if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif' || fileType == 'webp') {
        this.images = [];
        this.fileType = 'img';
        this.images.push(this.doc);
      } else {
        this.fileType = 'doc';
      }
      const file = this.goodImgArray[index];
      this.displayMaximizable = true;
      setTimeout(() => {
        var el = document.getElementById("ngx-fs-btn") as HTMLElement;
        console.log("sass");
        console.log(el);
        el.style.border = 'none';
        el.style.marginTop = "0.3rem";
      }, 500);
    }

  }

  toggleAllSelectionUnitAdd(): void {
    if (this.allSelectedUnitAdd.selected) {
      this.ChecklistDataForm.controls.Unit.patchValue([...this.unitsArray.map(x => x.puId), 0]);
      this.allSelectedUnitAdd.select();
      this.getExecutionTypeByUnit();
    } else {
      this.ChecklistDataForm.controls.Unit.patchValue([]);
      this.getExecutionTypeByUnit();

    }

  }

  toggleAllSelectionAreaAdd(): void {
    if (this.allSelectedAreaAdd.selected) {
      this.ChecklistDataForm.controls.area.patchValue([...this.AreaFilter.map(x => x.areaId), 0]);
    } else {
      this.ChecklistDataForm.controls.area.patchValue([]);
    }
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
    this.gridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#agGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }
  onGridReadyCheckList(params: any): any {
    this.gridApiCheckList = params.api;
    this.gridColumnCheckListApi = params.columnApi;

    this.gridApiCheckList.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector('#agGridCheckList') as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
    // params.api.sizeColumnsToFit();
  }

  cellClicked(params: any) {
    const col = params.colDef.field;
    if (col === 'action') {
      this.ButtonType = params.event.target.textContent;
      if (this.ButtonType === 'delete') {
        this.DeleteChecklistConfigured(params);
      }
      if (this.ButtonType === 'edit') {
        this.EditChecklistConfiguration(params);
      }
    }
  }

  OpenConfiguration(id: number): void {
    this.ConfigurationForm.reset();
    this.chfformMode = true;
    this.rowData = [];
    this.checklistIdRowId = id;
    this.getAllCheckListConfigured();
    this.dialog.open(this.configurationModal, { height: 'auto', width: '90%', maxHeight: '90vh', disableClose: true });
    setTimeout(() => {
      var overlayEl = document.getElementsByClassName('cdk-overlay-container');
      for (var i = 0; i < overlayEl.length; i++) {

        (overlayEl[i] as HTMLElement).style.zIndex = '50';

      }
    }, 50);

  }

  SaveCheckListAPI(): void {
    const key = {
      chf_Id: 0,
      clId: this.checklistIdRowId,
      name: this.ConfigurationForm.value.name,
      value: this.ConfigurationForm.value.value,
      type: this.ConfigurationForm.value.type,
      order: this.ConfigurationForm.value.order,
      transactionType: 1
    };
    console.log(key)
    this.ChecklistService.CheckList_CRUD_HeaderFooterConfiguration(key).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.toaster.success('Data Added Successfuly');
        this.ConfigurationForm.reset();
        this.getAllCheckListConfigured();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  SaveCheckListConfiguration(): any {
    this.spinner.show();
    const data = this.rowData;
    const headerArr = data.filter(obj => obj.type.includes("1")).map(obj => ({ "chf_Id": obj.chf_Id, "clId": obj.clId, "name": obj.name, "order": obj.order, "type": obj.type, "value": obj.value }));
    const FooterArr = data.filter(obj => obj.type.includes("2")).map(obj => ({ "chf_Id": obj.chf_Id, "clId": obj.clId, "name": obj.name, "order": obj.order, "type": obj.type, "value": obj.value }));
    var hname = headerArr.some(x => x.name == this.ConfigurationForm.value.name && x.chf_Id != this.chf_Id);
    var fname = FooterArr.some(x => x.name == this.ConfigurationForm.value.name && x.chf_Id != this.chf_Id);

    var Headerresult = data.filter(obj => {
      return obj.type === '1'
    });
    var Headerlength = Headerresult.length;
    var Footerresult = data.filter(obj => {
      return obj.type === '2'
    });
    var Footerlength = Footerresult.length;
    const type = this.ConfigurationForm.value.type;
    if (type === '1') {
      if (hname == true) {
        this.spinner.hide();
        this.toaster.warning('Name Already Exists');
        return
      }
      else {
        this.SaveCheckListAPI();
      }
    }
    if (type === '2') {
      if (fname == true) {
        this.spinner.hide();
        this.toaster.warning('Name Already Exists');
        return
      }
      else {
        this.SaveCheckListAPI();
      }
    }

  }

  UpdateCheckListAPI(): void {
    const key = {
      chf_Id: this.chf_Id,
      clId: this.checklistIdRowId,
      name: this.ConfigurationForm.value.name,
      value: this.ConfigurationForm.value.value,
      type: this.ConfigurationForm.value.type,
      order: this.ConfigurationForm.value.order,
      transactionType: 2
    };
    console.log(key)
    this.ChecklistService.CheckList_CRUD_HeaderFooterConfiguration(key).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.toaster.success('Data Updated Successfuly');
        this.ConfigurationForm.reset();
        this.getAllCheckListConfigured();
        this.chfformMode = true;
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  UpdateCheckListConfiguration(): any {
    this.spinner.show();
    const data = this.rowData;
    const headerArr = data.filter(obj => obj.type.includes("1")).map(obj => ({ "chf_Id": obj.chf_Id, "clId": obj.clId, "name": obj.name, "order": obj.order, "type": obj.type, "value": obj.value }));
    const FooterArr = data.filter(obj => obj.type.includes("2")).map(obj => ({ "chf_Id": obj.chf_Id, "clId": obj.clId, "name": obj.name, "order": obj.order, "type": obj.type, "value": obj.value }));
    var hname = headerArr.some(x => x.name == this.ConfigurationForm.value.name && x.chf_Id != this.chf_Id);
    var fname = FooterArr.some(x => x.name == this.ConfigurationForm.value.name && x.chf_Id != this.chf_Id);

    var Headerresult = data.filter(obj => {
      return obj.type === '1'
    });
    var Headerlength = Headerresult.length;
    var Footerresult = data.filter(obj => {
      return obj.type === '2'
    });
    var Footerlength = Footerresult.length;
    const type = this.ConfigurationForm.value.type;
    if (type === '1') {
      if (hname == true) {
        this.spinner.hide();
        this.toaster.warning('Name Already Exists');
        return
      }
      else {
        this.UpdateCheckListAPI();
      }
    }
    if (type === '2') {
      if (fname == true) {
        this.spinner.hide();
        this.toaster.warning('Name Already Exists');
        return
      }
      else {
        this.UpdateCheckListAPI();
      }
    }

  }
  resetheaderFooter() {
    this.chfformMode = true;
    this.ConfigurationForm.reset();
  }

  EditChecklistConfiguration(params: any): any {
    this.chf_Id = params.data.chf_Id;
    this.ConfigurationForm.get('name').setValue(params.data.name);
    this.ConfigurationForm.get('value').setValue(params.data.value);
    this.ConfigurationForm.get('type').setValue(params.data.type);
    this.ConfigurationForm.get('order').setValue(params.data.order);
    this.chfformMode = false;
  }

  getAllCheckListConfigured(): any {
    this.spinner.show();
    const key = {
      chf_Id: 0,
      clId: this.checklistIdRowId,
      name: '',
      value: '',
      type: '',
      order: 0,
      transactionType: 3
    };
    this.ChecklistService.CheckList_CRUD_HeaderFooterConfiguration(key).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.rowData = response;
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  DeleteChecklistConfigured(params: any): any {
    const id = params.data.chf_Id;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete CheckList!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        const key = {
          chf_Id: id,
          clId: this.checklistIdRowId,
          name: '',
          value: '',
          type: '',
          order: 0,
          transactionType: 4
        };
        this.ChecklistService.CheckList_CRUD_HeaderFooterConfiguration(key).subscribe(
          (response: any) => {
            this.spinner.hide();
            this.getAllCheckListConfigured();
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
        (success: any) => {
          Swal.fire(
            'Deleted!',
            'Your selected Row has been deleted.',
            'success'
          );
        };
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected row is safe :)', 'error');
      }
    });


  }
  OpenDuplicateChecklist(element: any, displayDuplicateModal: any): void {
    this.checklistId = element.qualityCheckListId;



    this.dialog.open(displayDuplicateModal, {
      width: '40vw !important',
      height: '30vh',
      minWidth: '40vw',
      disableClose: true
    });
    this.popupMaxIndex();
    this.formMode = false;
  }

  DuplicateChecklist(): void {
    let result = this.customerManagementDataSource.data.find(item => item.listName == this.DuplicateCheckListForm.value.name);

    if (!result) {
      const key = {
        name: this.DuplicateCheckListForm.value.name,
        checkListId: this.checklistId,
      };
      console.log(key)
      this.ChecklistService.DuplicateChecklist(key).subscribe(
        (response: any) => {
          this.toaster.success('Checklist Duplicated Successfuly');
          this.DuplicateCheckListForm.reset();
          const key = {
            groupId: this.groupId,
            plantId: this.plantId,
          }
          this.getlist(key);
          this.dialog.closeAll();
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error('Error', error?.error?.message);
        }
      );
    }
    else {
      this.toaster.error('Error', 'Duplicate Name Not Allowed');
    }
  }

  getExecutionTypeByUnit(): any {
    if (this.ChecklistDataForm.value.Unit != null && this.ChecklistDataForm.value.Unit.length > 0) {
      if (this.ChecklistDataForm.value.Unit.includes(0)) {
        this.ChecklistDataForm.value.Unit.shift();
      }
      this.executionList = [
        { executionTypeId: 1, executionType: 'Both' },
        { executionTypeId: 2, executionType: 'Checklist Execution' },
        { executionTypeId: 3, executionType: 'SFDC' }
      ]
    }
    else {
      this.executionList = [
        { executionTypeId: 2, executionType: 'Checklist Execution' },
      ]
      this.ChecklistDataForm.get('executionType')?.setValue(this.executionList[0].executionType)

    }
    this.cd.detectChanges();
  }

  onCellClicked(params: any): void {
    if (params.colDef.field === 'action' && this.IsWrite) {
      // Handle action button clicks
      const buttonType = params.event.target.textContent;
      if (buttonType === 'edit') {
        this.OpenEditCheckList(params.data, this.displayRoleModal);
      }
      else if (buttonType === 'delete') {
        this.deletedata(params.data);
      }
      else if (buttonType === 'file_copy') {
        this.OpenDuplicateChecklist(params.data, this.displayDuplicateModal);
      }
      else if (buttonType === 'add') {
        this.OpenConfiguration(params.data.checkListId);
      }
    }
  }

  onSaveGridState() {
    const savedState = this.gridColumnCheckListApi?.getColumnState();
    const data: GridConfiguration = {
      UserId: JSON.parse(localStorage.getItem('user')).UserId,
      PageId: this.pageId,
      Configuration: JSON.stringify(savedState),
      GridId: 'CheckListConf-vTLU37IVZb'
    };
    if (!data.Configuration) return;
    this.gridService.saveGridConfiguration(data).subscribe((res: any) => {
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    )
  }

  applyState(gridId: string) {
    const data: GridConfiguration = {
      UserId: JSON.parse(localStorage.getItem('user')).UserId,
      PageId: this.pageId,
      GridId: gridId
    }
    this.gridService.getGridConfiguration(data).subscribe((res: any) => {
      if (res.Table.length) {
        this.gridColumnCheckListApi.applyColumnState({ state: JSON.parse(res.Table[0].Configuration), applyOrder: true, });
      }
      else {
        this.gridColumnCheckListApi.resetColumnState();
      }
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      })
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onSaveHeaderGridState() {
    const savedState = this.gridColumnApi?.getColumnState();
    const data: GridConfiguration = {
      UserId: JSON.parse(localStorage.getItem('user')).UserId,
      PageId: this.pageId,
      Configuration: JSON.stringify(savedState),
      GridId: 'CheckListHeaderFooter-vTLU37IVZb'
    }
    if (!data.Configuration) return;
    this.gridService.saveGridConfiguration(data).subscribe((res: any) => {
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    )
  }

  applyHeaderFooterState(gridId: string) {
    const data: GridConfiguration = {
      UserId: JSON.parse(localStorage.getItem('user')).UserId,
      PageId: this.pageId,
      GridId: gridId
    }
    this.gridService.getGridConfiguration(data).subscribe((res: any) => {
      if (res.Table.length) {
        this.gridColumnApi.applyColumnState({ state: JSON.parse(res.Table[0].Configuration), applyOrder: true, });
      }
      else {
        this.gridColumnApi.resetColumnState();
      }
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      })
  }

  onFirstDataRenderedHF(params) {
    params.api.sizeColumnsToFit();
  }
}
