import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { OpmsGlassService } from '../../services/opms-glass.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BtnCellRendererQxMatrix } from 'src/app/btn-cell-rendererQxMatrix';

@Component({
  selector: 'app-opms-box-receiving',
  templateUrl: './opms-box-receiving.component.html',
  styleUrls: ['./opms-box-receiving.component.scss']
})
export class OpmsBoxReceivingComponent implements OnInit {
  @ViewChild('boxReceivingModel') private boxReceivingModel!: TemplateRef<any>;
  @ViewChild('boxReceivingMasterDataModel') private boxReceivingMasterDataModel!: TemplateRef<any>;

  frameworkComponents: any;
  rowBoxReceiving: any = [];
  // rowBoxReceivingModel: any = [];
  MasterData!: any[];
  BoxRecievingGroup!: FormGroup;
  MasterGroup!: FormGroup;
  panelOpenState = false;
  ButtonType!: any;
  shiftId = 0;
  TransactionTypeId = 1;
  boxType: any;
  gridApi: any;
  columnApi: any;
  rowBoxReceivingMasterDataModel: any = [];
  defaultColDefBoxReceiving: any = {
    resizable: true,
    sortable: true,
    filter: true,
  };
  columnDefsBoxReceiving: ColDef[] = [
    { headerName: 'Date', field: 'Date', valueFormatter: this.dateFormatter},
    { headerName: 'Item Code', field: 'ItemCode'},
    { headerName: 'Box Type', field: 'BoxType'},
    { headerName: 'Length', field: 'Length'},
    { headerName: 'Width', field: 'Width'},
    { headerName: 'Depth', field: 'Depth'},
    { headerName: 'Supplier', field: 'Supplier'},
    { headerName: 'Receive', field: 'Receive'},
    { headerName: 'Price', field: 'Price'},
    { headerName: 'Created On', field: 'CreatedOn' , valueFormatter: this.dateFormatter},
    { headerName: 'Created By', field: 'CreatedBy'},
  ];
  columnDefsBoxReceivingModel: ColDef[] = [
    { headerName: 'Date', field: 'Date',valueFormatter: this.dateFormatter, width: 120 },
    { headerName: 'Item Code', field: 'ItemCode', width: 120 },
    { headerName: 'Box Type', field: 'BoxType', width: 120 },
    { headerName: 'Length', field: 'Length', width: 120 },
    { headerName: 'Width', field: 'Width', width: 120 },
    { headerName: 'Depth', field: 'Depth', width: 120 },
    { headerName: 'Supplier', field: 'Supplier', width: 120 },
    { headerName: 'Receive', field: 'Receive', width: 120 },
    { headerName: 'Price', field: 'Price', width: 120 },
    { headerName: 'Created On', field: 'CreatedOn' , valueFormatter: this.dateFormatter, width: 120 },
    { headerName: 'Created By', field: 'CreatedBy', width: 120 },
    {
      headerName: 'Action',
      field: 'Actions',
      width: 120,
      cellRenderer: 'btnCellRendererQxMatrix',
      editable: false,
      cellRendererParams: {
        clicked: (field: any) => {
        }
      }
    },
  ];
  columnDefsBoxReceivingMasterDataModel: ColDef[] = [
    { headerName: 'Item Code', field: 'ItemCode', width: 120 },
    { headerName: 'Length', field: 'Length', width: 120 },
    { headerName: 'Width', field: 'Width', width: 120 },
    { headerName: 'Depth', field: 'Depth', width: 120 },
    { headerName: 'Box Type', field: 'BoxType', width: 120 },
    { headerName: 'Created On', field: 'CreatedOn', valueFormatter: this.dateFormatter, width: 120 },
    { headerName: 'Created By', field: 'CreatedBy', width: 120 },
    {
      headerName: 'Action',
      field: 'Actions',
      width: 120,
      cellRenderer: 'btnCellRendererQxMatrix',
      editable: false,
      cellRendererParams: {
        clicked: (field: any) => {
        }
      }, sortable: true, filter: true, resizable: true, wrapText: true
    }
  ];

  gridOptions: any = {
    frameworkComponents: {
      btnCellRendererQxMatrix : BtnCellRendererQxMatrix,
    }
  };

  // onGridReady(params: any){
  //   this.gridApi = params.api;
  //   this.columnApi = params.columnApi;
  // }

  // onFirstDataBR(params: any){
  //   // const allColumnIds: any[] = [];
  //   //   (params.columnApi).getAllColumns().forEach((column: any) => {
  //   //     allColumnIds.push(column.colId);
  //   //   });
  //   //   (params.columnApi).autoSizeColumns(allColumnIds, false);
  //    (params.api).sizeColumnsToFit();
  //   // this.onFirstDataRendered(params.columnApi);
  // }
  onFirstDataRendered(params: any){
    // const allColumnIds: any[] = [];
    // (params.columnApi).getAllColumns().forEach((column: any) => {
    //   allColumnIds.push(column.colId);
    // });
    // (params.columnApi).autoSizeColumns(allColumnIds, false);
    params.api.sizeColumnsToFit();
  }

  onGridReadyBRM(params: any){
    const allColumnIds: any[] = [];
      (params.columnApi).getAllColumns().forEach((column: any) => {
        allColumnIds.push(column.colId);
      });
      (params.columnApi).autoSizeColumns(allColumnIds, false);
      // (params.api).sizeColumnsToFit();
    // this.onFirstDataRendered(params.columnApi);
  }

  onGridReadyMasterData(params: any){
    // const allColumnIds: any[] = [];
    //   (params.columnApi).getAllColumns().forEach((column: any) => {
    //     allColumnIds.push(column.colId);
    //   });
    //   (params.columnApi).autoSizeColumns(allColumnIds, false);
      (params.api).sizeColumnsToFit();
    // this.onFirstDataRendered(params.columnApi);
  }

  constructor(
    private dialog: MatDialog,
    private glassServiceObj: OpmsGlassService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService
  ) {
  }
  boxTypeData(){
    this.glassServiceObj.getBoxtypeDropdown().subscribe(
      (response: any)=>{
        this.boxType = response.DropDownList;
      },
    )
  }

  ngOnInit(): void {
    this.BoxRecievingGroup = this.fb.group({
      Date: [this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm')],
      ItemCode: [''],
      BoxType: [''],
      Length: [''],
      Width: [''],
      Depth: [''],
      Supplier: [''],
      Receive: [''],
      Price: ['']
    });
    this.MasterGroup = this.fb.group({

      ItemCode: [''],
      BoxType: [''],
      Length: [''],
      Width: [''],
      Depth: [''],
    });
    this.getBR();
    this.getMaster();
    this.boxTypeData();
  }

  getBR(): void
  {
    const key = {
      ShiftBRId: 0,
      UserId: JSON.parse(localStorage.user).UserId,
      IsHistory: 0
    };
    this.glassServiceObj.getBR(key).subscribe(
      (response: any) => {
      this.spinner.show();
      this.rowBoxReceiving = response.GetBRList;
      // this.rowBoxReceivingModel = response.GetBRList;
      this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  saveBR(): any
  {
    const data = {
      TransactionTypeId: this.TransactionTypeId,
      ShiftReportOPMSId: 0,
      ShiftBRId: this.shiftId,
      Date: this.BoxRecievingGroup.value.Date,
      ItemCode: this.BoxRecievingGroup.value.ItemCode,
      BoxType: this.BoxRecievingGroup.value.BoxType,
      Length: this.BoxRecievingGroup.value.Length,
      Width: this.BoxRecievingGroup.value.Width,
      Depth: this.BoxRecievingGroup.value.Depth,
      Supplier: this.BoxRecievingGroup.value.Supplier,
      Receive: this.BoxRecievingGroup.value.Receive,
      Price: this.BoxRecievingGroup.value.Price,
      UserId: JSON.parse(localStorage.user).UserId
    };
    this.glassServiceObj.saveBR(data).subscribe(
      (response: any) => {
        this.spinner.show();
        if ( this.TransactionTypeId === 1)
        {
          this.toaster.success( 'Box Receiving Saved Successfully!');
        }
        else if ( this.TransactionTypeId === 2)
        {
          this.toaster.success( 'Box Receiving Updated Successfully!');
        }
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error('Error', error.error.message);
        }
    );

    this.BoxRecievingGroup.reset();
    setTimeout(() => {
      this.getBR();
      this.TransactionTypeId = 1;
      this.shiftId = 0;
    }, 1000);
  }

  onBlurMethod(): void
  {
    const data = {
      ShiftMDId: 0,
      UserId: JSON.parse(localStorage.user).UserId,
      IsHistory: 3,
      ItemCode: this.BoxRecievingGroup.value.ItemCode
    };
    this.glassServiceObj.getMaster(data).subscribe(
      (response: any) => {
        this.MasterData = response.GetMDList;
        this.BoxRecievingGroup.get('BoxType')?.setValue(this.MasterData[0].BoxType);
        this.BoxRecievingGroup.get('Length')?.setValue(this.MasterData[0].Length);
        this.BoxRecievingGroup.get('Width')?.setValue(this.MasterData[0].Width);
        this.BoxRecievingGroup.get('Depth')?.setValue(this.MasterData[0].Depth);
        this.BoxRecievingGroup.get('Supplier')?.setValue(this.MasterData[0].Supplier);
        this.BoxRecievingGroup.get('Receive')?.setValue(this.MasterData[0].Receive);
        this.BoxRecievingGroup.get('Price')?.setValue(this.MasterData[0].Price);
        this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error('Error', error.error.message);
        }
    );
  }

  getMaster(): void
  {
    const data = {
      UserId: JSON.parse(localStorage.user).UserId,
      IsHistory: 1,
      ShiftMDId: 0
    };
    this.glassServiceObj.getMaster(data).subscribe(
      (response: any) => {
        this.spinner.show();
        this.rowBoxReceivingMasterDataModel = response.GetMDList;
        this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error('Error', error.error.message);
        }
    );
  }

  saveMaster(): void
  {

    const data = {
      TransactionTypeId: this.TransactionTypeId ,
      ShiftMDId: this.shiftId,
      ItemCode: this.MasterGroup.value.ItemCode,
      BoxType: this.MasterGroup.value.BoxType,
      Length: this.MasterGroup.value.Length,
      Width: this.MasterGroup.value.Width,
      Depth: this.MasterGroup.value.Depth,
      UserId: JSON.parse(localStorage.user).UserId
    };
    this.glassServiceObj.saveMaster(data).subscribe(
      (response: any) => {
        this.spinner.show();
        if ( this.TransactionTypeId === 1)
        {
          this.toaster.success( 'Master Data Saved Successfully!');
        }
        else if ( this.TransactionTypeId === 2)
        {
          this.toaster.success('Master Data Updated Successfully!');
        }
        this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error('Error', error.error.message);
        }
    );
    this.MasterGroup.reset();
    setTimeout(() => {
      this.getMaster();
      this.TransactionTypeId = 1;
      this.shiftId = 0;
    }, 1000);
  }

  cellClickedBR(params: any): void {
    const col = params.colDef.field;
    // this.costDictionaryId = params.data.LossAnalysisCostDicId;
    this.ButtonType = '';
    if (col === 'Actions') {
      this.ButtonType = params.event.target.textContent;
      if (this.ButtonType === 'edit') {
        this.BoxRecievingGroup.get('Date')?.setValue(this.datePipe.transform(params.data?.Date, 'yyyy-MM-dd hh:mm'));
        this.BoxRecievingGroup.get('ItemCode')?.setValue(params.data?.ItemCode);
        this.BoxRecievingGroup.get('BoxType')?.setValue(params.data?.BoxType);
        this.BoxRecievingGroup.get('Length')?.setValue(params.data?.Length);
        this.BoxRecievingGroup.get('Width')?.setValue(params.data?.Width);
        this.BoxRecievingGroup.get('Depth')?.setValue(params.data?.Depth);
        this.BoxRecievingGroup.get('Price')?.setValue(params.data?.Price);
        this.BoxRecievingGroup.get('Receive')?.setValue(params.data?.Receive);
        this.BoxRecievingGroup.get('Supplier')?.setValue(params.data?.Supplier);
        this.TransactionTypeId = 2;
        this.shiftId = params.data?.ShiftBRId;
        this.dialog.closeAll();
      }
      else if (this.ButtonType === 'delete') {
        this.ButtonType = '';
        const data = {
          TransactionTypeId: 3,
          UserId: JSON.parse(localStorage.user).UserId,
          Depth: params.data.Depth,
          Date: this.datePipe.transform(params.data?.Date, 'yyyy-MM-dd hh:mm'),
          ItemCode: params.data.ItemCode,
          Length: params.data.Length,
          ShiftBRId: params.data.ShiftBRId,
          Width: params.data.Width,
          Price: params.data.Price,
          Receive: params.data.Receive,
          ShiftReportOPMSId: params.data.ShiftReportOPMSId
        };
        this.glassServiceObj.saveBR(data).subscribe(
          (response: any) => {
            this.spinner.show();
            this.toaster.success('Success', 'Box Receiving Deleted Successfully!');
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.error.message);
            }
        );
        this.dialog.closeAll();
      }
      setTimeout(() => {
        this.getBR();
      }, 1000);
    }
  }

  cellClickedMaster(params: any): void {
    const col = params.colDef.field;
    this.ButtonType = '';
    if (col === 'Actions') {
      this.ButtonType = params.event.target.textContent;
      if (this.ButtonType === 'edit') {
        this.MasterGroup.get('ItemCode')?.setValue(params.data?.ItemCode);
        this.MasterGroup.get('BoxType')?.setValue(params.data?.BoxType);
        this.MasterGroup.get('Length')?.setValue(params.data?.Length);
        this.MasterGroup.get('Width')?.setValue(params.data?.Width);
        this.MasterGroup.get('Depth')?.setValue(params.data?.Depth);
        this.TransactionTypeId = 2;
        this.shiftId = params.data?.ShiftMDId;
      }
      else if (this.ButtonType === 'delete') {
        this.ButtonType = '';
        const data = {
          TransactionTypeId: 3,
          UserId: JSON.parse(localStorage.user).UserId,
          BoxType: params.data.BoxType,
          Depth: params.data.Depth,
          ItemCode: params.data.ItemCode,
          Length: params.data.Length,
          ShiftMDId: params.data.ShiftMDId,
          Width: params.data.Width,
        };
        this.glassServiceObj.saveMaster(data).subscribe(
          (response: any) => {
            this.spinner.show();
            this.toaster.success('Success', 'Master Data Deleted Successfully!');
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.error.message);
            }
        );
        setTimeout(() => {
          this.getMaster();
        }, 1000);
      }

    }
  }


  openRecievingModel(): void {
    this.dialog.open(this.boxReceivingModel, {
      width: '90vw !important',
      height: '90vh', minWidth: '90vw'
    });
  }

  openMasterModal(): void {
    this.dialog.open(this.boxReceivingMasterDataModel, {
      width: '90vw !important',
      height: '90vh', minWidth: '90vw'
    });
  }

  resetForm(n: number): any
  {
    if (n === 1)
      {
        this.BoxRecievingGroup.reset();
        this.TransactionTypeId = 1;
      }
    else if (n === 2)
    {
      this.MasterGroup.reset();
      this.TransactionTypeId = 1;
    }
  }

  dateFormatter(params: any): any {
    return moment(params.value).format('MM/DD/YYYY HH:mm') ;
  }
}
