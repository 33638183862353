
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-view-renderer',
  template: `
  <style>
  .row-action-btns .mat-icon-button.tree-view-btn {
    background: #824aed;
  }
  .row-action-btns .mat-icon-button {
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 10px;
    visibility: visible;
    transition: all .4s;
    top:5px;
  }
  .mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
  }

  </style>

    <div class="row-action-btns">
      <button  class="mat-icon-button" (click)="onClick($event)" aria-label="View Row Tree" style="background-color: lightseagreen;">
      <span class="mat-button-wrapper">
      <svg width="12" height="12" class="svg-icon" viewBox="0 0 20 20">
      <path fill="white"
        d="M19.471,8.934L18.883,8.34c-2.096-2.14-4.707-4.804-8.903-4.804c-4.171,0-6.959,2.83-8.996,4.897L0.488,8.934c-0.307,0.307-0.307,0.803,0,1.109l0.401,0.403c2.052,2.072,4.862,4.909,9.091,4.909c4.25,0,6.88-2.666,8.988-4.807l0.503-0.506C19.778,9.737,19.778,9.241,19.471,8.934z M9.98,13.787c-3.493,0-5.804-2.254-7.833-4.3C4.182,7.424,6.493,5.105,9.98,5.105c3.536,0,5.792,2.301,7.784,4.332l0.049,0.051C15.818,11.511,13.551,13.787,9.98,13.787z">
      </path>
      <circle fill="white" cx="9.98" cy="9.446" r="1.629"></circle>
    </svg>
      </span>
      <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
      <span class="mat-button-focus-overlay"></span></button>    
     </div>
    `
})

export class ButtonViewRendererComponent implements ICellRendererAngularComp {

  params:any;
  label!: string;

  agInit(params:any): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event:any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(this.params);

    }
  }
}