<div class="order-processing-content">
    <div class="content-top-box ">
      <ul class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="breadcrumb-link" routerLink="/Access">Access</a>
        </li>
      </ul>
      <div class="top-box-content">
        <h1 class="main-title">Machine Mapping</h1>
      </div>
    </div>
    <form [formGroup]="userRoleForm">
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-top-row content-top-row-order-processing">
          <mat-form-field appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select formControlName="userGroup" required [(ngModel)]="GroupId" (selectionChange)="getUserslist()" placeholder="Select Group">
              <mat-select-filter
                [placeholder]="'Search'"
                [displayMember]="'TypeName'"
                [array]="GroupList"
                (filteredReturn)="filteredGroups = $event"
              ></mat-select-filter>
              <mat-option *ngFor="let group of filteredGroups" [value]="group.TypeId" >
                {{ group.TypeName }}</mat-option
              >
            </mat-select>   
          </mat-form-field>

          <mat-form-field appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="UserName" required [(ngModel)]="UserId" (selectionChange)="getMachineData()" placeholder="User Name">
            <mat-select-filter
              [placeholder]="'Search'"
              [displayMember]="'UserName'"
              [array]="UsersList"
              (filteredReturn)="filteredUsers = $event"
            ></mat-select-filter>
            <mat-option *ngFor="let user of filteredUsers" [value]="user.UserId" >
              {{user.UserName}}</mat-option
            >
          </mat-select>  
        </mat-form-field>
        <button   type="submit" class="add-btn" style="margin-left: 10px;" pTooltip="Add Machine" tooltipPosition="top" (click)="openModal()">
          <img src="assets/images/add.png" alt="" width="20px" height="20px">
        </button>
        <mat-form-field appearance="fill" class="field-fill" style="display: flex;
        position: relative;
        text-align: left;
        justify-content: flex-end;">
          <mat-label >Search</mat-label>
            <input  matInput class="input-text" (keyup)="applyFilter($event)" autocomplete="off"/>
        </mat-form-field>  

        </div>
        <table mat-table [dataSource]="machineData" class="basic-table plant-model-table" matSort>
          <ng-container matColumnDef="MachineRoleId">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
              <p class="header-text">MachineRoleId</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.MachineMapId}} </td>
          </ng-container>
          <!-- Item Code Column -->
          <ng-container matColumnDef="ProductUnit">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
              <p class="header-text">ProductUnit</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.PuDesc}} </td>
          </ng-container>
          <!-- PO Target Column -->
          <ng-container matColumnDef="CreatedOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">CreatedOn</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.CreatedOn | date: "dd/MM/yyyy HH:mm:ss" }} </td>
          </ng-container>
          <ng-container matColumnDef="CreatedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">CreatedBy</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.CreatedBy}} </td>
          </ng-container>
          <!-- orderProcessingActions Column -->
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef>
                <p class="header-text">Actions</p>
            </th>
            <td mat-cell *matCellDef="let element" class="row-action-btns-column">
              <div class="row-action-btns">
                <button mat-icon-button class="tree-view-btn" aria-label="View Row Tree" (click)="DeleteMachine()" pTooltip="Delete" tooltipPosition="top">
                  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                      fill="#F85439" />
                    <circle cx="17" cy="17" r="14" fill="#F85439" />
                    <path
                      d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                      fill="white" />
                  </svg>
                </button>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="MachineMappingColumns" ></tr>
          <tr mat-row *matRowDef="let row; columns: MachineMappingColumns;" (click)="highlight(row)"></tr>
        </table>
        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>

      </div>
    </form>

    <!-- popup -->
      <ng-template #displayRoleModal>
        <form [formGroup]="plantDataForm">
        <div mat-dialog-title>
            <div class="d-flex justify-content-between align-items-center">
                <h2>Add Machine</h2>
                <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
                    <i class="fa fa-times" style="color: #fff;"></i>
                </button>
            </div>
        </div>
        <mat-dialog-content>
            <div class="wrapper-box wrapper-box-shadow table-wrapper">
                <div class="content-top-row content-top-row-order-processing">
                  <mat-form-field  appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 25%;"> 
                    <mat-select formControlName="plant" placeholder="Select Plant"  (selectionChange)="getproducts($event)">
                      <mat-option *ngFor="let dept of PlantList" [value]="dept.DeptId">{{dept.DeptDesc}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field  appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 25%;">
                    <mat-select  formControlName="productLine" placeholder="Select ProductLine"  (selectionChange)="getUnits($event)">
                      <mat-option [value]="-1">ALL</mat-option>
                      <mat-option *ngFor="let prod of ProductList" [value]="prod.PLId">{{prod.PLDesc}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field  appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 25%;">
                    <mat-select formControlName="productUnit" placeholder="Select ProductUnit" >
                      <mat-option [value]="-1">ALL</mat-option>
                      <mat-option *ngFor="let unit of UnitsList" [value]="unit.PUId">{{unit.PUDesc}}</mat-option>
                  </mat-select>
                  </mat-form-field>
                  <button   type="submit" class="add-btn" style="margin-left: 10px;" pTooltip="Save" tooltipPosition="top" (click)="onSubmit($event)">
                    <img src="assets/images/save.png" alt="" width="20px" height="20px">
                  </button>
                </div>
              </div>
        </mat-dialog-content>
      </form>
    </ng-template>

  </div>

