import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
//import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { TreeModule } from 'primeng/tree';
import { BtnCellRenderer } from '../btn-cell-renderer.component';
import { BtnCellRendererActualEntry } from '../btn-cell-rendererActualEntry.component';
import { CustomDateComponent } from '../date-components/custom-date-component.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { TreeTableModule } from 'primeng/treetable';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MessageService } from 'primeng/api';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmationService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { MaterialModule } from 'src/app/material-module';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { MatSelectFilterModule } from 'mat-select-filter';
import { PerfomranceManagementRoutingModule } from './prmanagement-route.module';
import { ScorecardComponent } from './scorecard/scorecard.component';
import { ActionanalysisComponent } from './actionanalysis/actionanalysis.component';
import { MeetingDefinationComponent } from './meeting-defination/meeting-defination.component';
import { MeetingMatrixComponent } from './meeting-matrix/meeting-matrix.component';
import { PerformancemastersettingsComponent } from './Performance Master Setting/performancemastersettings/performancemastersettings.component';
import { MeetingmastersettingsComponent } from './Performance Master Setting/performancemastersettings/MeetingMasterSettings/meetingmastersettings/meetingmastersettings.component';
import { ActionDashboardComponent } from './action-dashboard/action-dashboard.component';
import { MeetingActionComponent } from './meeting-action/meeting-action.component';
import { MeetingSaturationComponent } from './meeting-saturation/meeting-saturation.component';
import { MeetingDashboardComponent } from './meeting-dashboard/meeting-dashboard.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';

import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMatSelectConfigs, NgxMatSelectModule, NGX_MAT_SELECT_CONFIGS } from 'ngx-mat-select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MeetingMenusComponent } from './Performance Master Setting/performancemastersettings/meeting-menus/meeting-menus.component';
import { MeetingEffectivenessChecklistComponent } from './Performance Master Setting/performancemastersettings/meeting-effectiveness-checklist/meeting-effectiveness-checklist.component';
import { KPIMilestoneComponent } from './KPI-milestone/KPI-milestone.component';
import { MeetingMatrixV2Component } from './meeting-matrixV2/meeting-matrixV2.component';
import { Full_CalendarComponent } from './meeting-matrixV2/full-calendar/full-calendar.component';
import { MeetingRescheduleDialogComponent } from './meeting-defination/meeting-reschedule-dialog/meeting-reschedule-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';

// tslint:disable-next-line:typedef
export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more];
}
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);
@NgModule({
  imports: [
    ChartModule,
    TranslateModule.forChild({
      extend: true,
    }),
    CommonModule,
    MatSlideToggleModule,
    // MatButtonToggleModule,
    PerfomranceManagementRoutingModule,
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    NgApexchartsModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([]),
    TreeModule,
    ColorPickerModule,
    RadioButtonModule,
    TooltipModule,
    SliderModule,
    TableModule,
    OrganizationChartModule,
    TreeTableModule,
    ToastModule,
    PanelModule,
    ContextMenuModule,
    ButtonModule,
    SidebarModule,
    InputTextModule,
    DropdownModule,
    MultiSelectModule,
    TieredMenuModule,
    DialogModule,
    CalendarModule,
    InputSwitchModule,
    InputNumberModule,
    CardModule,
    NgxPanZoomModule,
    MatSelectFilterModule,
    NzDatePickerModule,
    NgxMatSelectSearchModule,
    NgxSpinnerModule,
    FullCalendarModule,
    NgxDaterangepickerMd.forRoot(),
    NgxMatSelectModule.forRoot({
      maxWidthForMobileView: 600,
      inFirstLoadCallSearcher: true,
      inFirstLoadSearcherValue: '',
      emptyLabel: 'no entry found',
      noMoreResultLabel: 'no more found',
      useInfiniteScroll: false,
      searchBoxPlaceholder: 'Search',
      maximumResultForShow: 4000,
      useMobileView: false,
      valueMember: 'key',
      displayMember: 'value',
      mobileViewType: 'FullScreen'
    })
  ],
  declarations: [
    ActionanalysisComponent,
    ScorecardComponent,
    MeetingDefinationComponent,
    MeetingMatrixComponent,
    ActionDashboardComponent,
    MeetingActionComponent,
    MeetingSaturationComponent,
    MeetingDashboardComponent,
    PerformancemastersettingsComponent,
    MeetingmastersettingsComponent,
    ActionDashboardComponent,
    MeetingMenusComponent,
    MeetingEffectivenessChecklistComponent,
    KPIMilestoneComponent,
    MeetingMatrixV2Component,
    Full_CalendarComponent,
    MeetingRescheduleDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules }, // add as factory to your providers
  ]
})
export class PerformanceManagementModule { }
