import { Component, Input, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ProductDetail } from 'src/app/models/ProductModels/productDetail';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DataSourceService } from 'src/app/services/dataSource.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { CardItem } from 'src/app/models/uimodels/cardItem';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { ProductBulkUploadService } from 'src/app/services/product-bulk-upload.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-productdetail',
  templateUrl: './productdetail.component.html',
  styleUrls: ['./productdetail.component.scss'],
})
export class ProductdetailComponent implements OnInit {
  panelOpenState = false;
  @ViewChild('visiblecharactersticsModal') private visiblecharactersticsModal!: TemplateRef<any>;
  @ViewChild('visibleRateSpecsModal') private visibleRateSpecsModal!: TemplateRef<any>;
  @ViewChild('visibleMappingModal') private visibleMappingModal!: TemplateRef<any>;
  @ViewChild('addDialogModel') private addDialogModel!: TemplateRef<any>;

  @ViewChild('visibleMachineMappingModal') private visibleMachineMappingModal!: TemplateRef<any>;
  @ViewChild('visiblePropertyMappingModal') private visiblePropertyMappingModal!: TemplateRef<any>;

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  // @ViewChild(MatSort) sort!: any;

  homeCardList!: CardItem[];
  breadcrumList!: CardItem[];
  PropertyMappingList!: any[];
  addNewProductForm!: FormGroup;
  productsForm!: FormGroup;
  popupMode = true;
  addNewFamilyForm!: FormGroup;
  addNewCharactersticsForm!: FormGroup;
  addNewRateSpecsForm!: FormGroup;
  ProductMappingForm!: FormGroup;
  addNewMappingForm1!: FormGroup;
  propertymappingform!: FormGroup;
  CharId!: number;
  ProductId!: number;
  ProductUnitId!: number;
  addNewMappingForm!: FormGroup;
  PropertyMode = true;
  MachineMode = true;
  UnitText!: any;
  addNewMachineMappingForm!: FormGroup;

  formMode!: boolean;
  Product!: string;
  variableGroup: string[] = ['Plant 1', 'Plant 2'];
  GroupList!: any[];
  DepartList!: any[];
  TreeList!: any[];
  TypeId!: number;
  DeptId!: number;
  FamilyId!: number;
  MachineId!: number;
  UnitName!: string;
  date1!: any;
  CharList!: any[];
  ProductPropertyId!: number;
  PropertyMappingMode = true;
  SpecificationId!: number;
  SpecificationList!: any[];
  PPList!: any[];
  CharacteristicsMode = true;
  SpecMode = true;
  MappingMode = true;
  MappingArrayOne!: any[];
  isPlantGroupModelVisible = false;
  CharacteristicsList!: any[];
  ProductUnitDropDownList!: any[];
  ProductPropertyForm!: FormGroup;
  formMode1 = true;
  // plant data started
  updateOrderprocessingData = false;
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  plantDataForm!: FormGroup;
  treeControl = new NestedTreeControl<PlantGroupElement>(node => node.children);
  // plant data ended
  ProductFamilyList!: MatTableDataSource<any>;
  ProductFamilyLists!: any[];
  FamilyListForAddNewProduct!: any[];
  UnitArray!: any[];
  UnitArrayName!: any[];
  DataTypeValue!: any;
  DataTypeList: any[] = [{
    id: 1, name: 'String'
  },
  { id: 2, name: 'Int' },
  { id: 3, name: 'Float' }
  ];
  masterData!: any;

  // tslint:disable-next-line:max-line-length
  productdetailDataSource!: MatTableDataSource<any>; // = [{ID: '1', Group: 'A', Plant: 'Plant A', Family: 'Fam', ProductCode: 'P-1118', ProductDescription: 'Test Product', Actions: ''}];
  productdetailHeaderColumns: string[] = ['Actions', 'Id', 'Group', 'Plant', 'Family', 'ProductCode', 'ProductDescription',];

  familyDetailHeader: string[] = ['Actions', 'Id', 'Family', 'Group', 'Plant',];
  familyDetailDataSource: any[] = [{ ID: '1', Family: 'Family', Group: 'A', Plant: 'Plant A', Actions: '' }];

  // tslint:disable-next-line:max-line-length
  productPropertyColumns: string[] = ['Actions', 'Id', 'ProductDescription', 'Group', 'Plant', 'Characteristics', 'RateSpecs', 'ProductPropertyMapping',];
  // tslint:disable-next-line:max-line-length
  productPropertyDataSource!: MatTableDataSource<any>; // = [{ID: '1', ProductProperty: 'Molding', Group: 'A', Plant: 'Plant A', Characterstics: '', RateSpecs: '', ProductPropertyMapping: '', Actions: ''}];

  productDetailHeaderCh: string[] = ['Actions', 'Id', 'Characteristic',];
  productCharactersticsDataSource!: MatTableDataSource<any>; // = [{ID: '1', Characterstics: 'OFC-Mold1', Actions: ''}];

  specRateHeader: string[] = ['Actions', 'Id', 'Specification', 'DataType', 'EngineeringUoM', 'Precision',];
  // tslint:disable-next-line:max-line-length
  specRatedataSource!: MatTableDataSource<any>; // [{ID: '1', Specification: 'Molding_Pcs/Hr', DataType: 'Bool', EngineeringUom: 'Pcs/Hr', Precision: '0.8', Actions: ''}];

  // tslint:disable-next-line:max-line-length
  mappingDataSource!: MatTableDataSource<any>; // [{ID: '1', Characterstics: 'OFC-Mold1', Specification: 'Molding_Pcs/Hr', LRL: '17', LWL: '23', Target: '1233', Optimum: '222', UWL: '1213', URL: '12', EffectiveDate: '12-2-2021', Actions: ''}];
  // tslint:disable-next-line:max-line-length
  PropertyMappingDataSource!: MatTableDataSource<any>; // = [{ID: '1', Characterstics: 'OFC-Mold1', Product: 'Molding_Pcs/Hr', ProductProperty: 'Gluing', ProductUnit: 'IPBM',  Actions: ''}];
  // tslint:disable-next-line:max-line-length
  mappingHeader: string[] = ['Actions', 'Id', 'Characteristic', 'Specification', 'LRL', 'LWL', 'TargetSpeed', 'OptimumSpeed', 'UWL', 'URL', 'EffectiveDate',];
  // tslint:disable-next-line:max-line-length
  PropertyMappingHeader: any[] = ['Actions', 'Id', 'Characteristic', 'Product', 'ProductProperty', 'ProductUnit',];

  // tslint:disable-next-line:max-line-length
  specMappingDataSource!: MatTableDataSource<any>; // = [{ID: '1', Family: 'Folding And Gluing', ProductCode:  'OFC-001', ProductDescription: 'CA/TIDE/PERSIL/Tea-6000', Group: 'OIG', Plant: 'OFC', MachineMapping: '', PropertyMapping: ''}];
  // tslint:disable-next-line:max-line-length
  specMappingHeader: string[] = ['Id', 'Family', 'ProductCode', 'ProductDescription', 'Group', 'Plant', 'MachineMapping', 'PropertyMapping'];

  machineMappingDataSource!: MatTableDataSource<any>; // = [{ID: '', ProductCode: '', ProductDescription: '', Machine: '', Actions: ''}];
  machineMappingHeader: string[] = ['Actions', 'Id', 'ProductCode', 'ProductDescription', 'Machine',];

  isaddNewProductModeIVisible = false;
  isAddNewFamily = false;
  isProductPropertyMappingVisible = false;
  isCharactersticsVisible = false;
  IsAccess: any;
  IsWrite:boolean = false;
  excelData: any = [];

  //================================================ Product Barcode Detail code here ============================//
  productBarcodeDetailHeaderColumns: string[] = ['Actions', 'Group', 'Plant', 'ItemCode', 'Barcode'];
  isaddNewProductBarcodeModeIVisible: boolean = false;
  barcodeFormMode: boolean = true;
  addNewProductBarcodeForm!: FormGroup;
  productBarcodeDetailDataSource!: MatTableDataSource<any>;
  productBarcodeDetailId: number;
  //================================================ End ================================================//

  constructor(
    private roleService: DataSourceService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    public router: Router,
    private userManagementService: UserManagementService,
    private enterpriseGroupService: EnterPriseGroupService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private productBulkUploadService: ProductBulkUploadService
  ) { }
  hasChild = (_: number, node: PlantGroupElement) => !!node.children && node.children.length > 0;
  ngOnInit(): void {
    this.GetMenuChildList();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroups();
    this.date1 = new Date();
    this.GetCharacteristics();
    this.GetProductUnitsDropDown();
    this.GetSpecifications(this.ProductPropertyId);
    this.UnitArray = [];
    this.UnitArrayName = [];
    this.addNewProductForm = this.fb.group({
      Id: [],
      family: ['', Validators.required],
      productCode: [''],
      productDescription: [''],
    });

    this.productsForm = new FormGroup({
      Id: new FormControl(),
      group: new FormControl(),
      plant: new FormControl(),
    });

    this.ProductPropertyForm = this.fb.group({
      Id: [],
      ProductProperty: ['', Validators.required]
    });
    this.addNewFamilyForm = this.fb.group({
      Id: [],
      productFamily: ['', Validators.required],
    });

    this.addNewCharactersticsForm = this.fb.group({
      Id: [],
      productCharacterstics: ['', Validators.required]
    });

    this.addNewRateSpecsForm = new FormGroup({
      Id: new FormControl(),
      Specification: new FormControl(),
      DataType: new FormControl(),
      EngineeringUoM: new FormControl(),
      Precision: new FormControl()
    });
    this.ProductMappingForm = new FormGroup({
      Id: new FormControl(),
      Characterstics: new FormControl(),
      Specifications: new FormControl(),
      LRL: new FormControl(),
      LWL: new FormControl(),
      Target: new FormControl(),
      Optimum: new FormControl(),
      UWL: new FormControl(),
      URL: new FormControl(),
    });
    this.addNewMappingForm1 = new FormGroup({
      Id: new FormControl(),
      ProductProperty: new FormControl(),
      Group: new FormControl(),
      Plant: new FormControl(),
      Characteristics: new FormControl(),
      RateSpecs: new FormControl(),
      ProductPropertyMapping: new FormControl(),
    });
    this.addNewMachineMappingForm = new FormGroup({
      Id: new FormControl(),
      ProductCode: new FormControl(),
      ProductDescription: new FormControl(),
      Unit: new FormControl(),
      Group: new FormControl(),
      Plant: new FormControl(),
      Family: new FormControl()
    });
    this.propertymappingform = this.fb.group({
      Id: [],
      CharId: [],
      ProductId: [],
      ProductUnitId: [],
      ProductCode: [],
      ProductDescription: [],
      ProductProperty: ['', Validators.required],
      Characterstics: ['', Validators.required],
      Machine: ['', Validators.required],
    });
    this.getProductMasterData();

    this.addNewProductBarcodeForm = this.fb.group({
      itemCode: ['',Validators.required],
      barcode: ['',Validators.required],
    });
  }



  ngAfterViewInit(): void {
    // this.machineParameterVariableGroupData.paginator = this.paginator.toArray()[0];
    // this.machineParameterVariableGroupData.sort = this.sort.toArray()[0];
    // this.machineParameterVariableData.paginator = this.paginator.toArray()[1];
    // this.machineParameterVariableData.sort = this.sort.toArray()[1];
  }
  // get groups
  getGroups(): void {
    const key = {
      Id: 0
      // TypeId: this.productForm.value.group
    };
    this.roleService.getGroupData(key).subscribe((res: any) => {
      // console.log(res);
      this.GroupList = res.masterList;
      this.productsForm.get('group')?.setValue(this.GroupList[0].TypeId);
      this.getPlants();
      // this.onGroupChange();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  // get plants
  getPlants(): void {
    const key = {
      UserId: 0,
      GroupId: this.productsForm.value.group
      // GroupId: 1
    };
    this.roleService.getPlantData(key).subscribe((res: any) => {
      // console.log(res);
      this.DepartList = res.DepartList;
      this.productsForm.get('plant')?.setValue(this.DepartList[0].DeptId);
      this.getProductList();
      this.GetProductProperties();
      this.GetProductUnits();
      this.getProductFamily();
      this.getProductBarcodeDetail();
    }
    );
  }

  // get product families
  getProductFamily(): void {
    this.spinner.show();

    const key = {
      // GroupId: this.productsForm.value.group,
      PlantId: this.productsForm.value.plant,
      // PlantId: 1,
      Option: 'Plant',
    };
    this.roleService.getProductFamilyData(key).subscribe((res: any) => {
      this.spinner.hide();
      this.FamilyListForAddNewProduct = res.ProductFamilyList;
      this.ProductFamilyList = new MatTableDataSource<any>(res.ProductFamilyList);
      this.getPaginator(this.ProductFamilyList, 3);
    },
    );
  }
  getPlantChange(): void {
    const key = {
      PlantId: this.productsForm.value.plant,
      Option: 'Plant',
    };
    this.roleService.getProductFamilyData(key).subscribe((res: any) => {
      this.FamilyListForAddNewProduct = res.ProductFamilyList;
      this.getProductList();
      this.GetProductProperties();
      this.GetProductUnits();
      this.getProductFamiliesDropDown();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getProductFamiliesDropDown(): void {
    const key = {
      PlantId: this.addNewProductForm.value.plant,
      Option: 'Plant'
    };
    this.roleService.GetProductFamiliesDropDown(key).subscribe((res: any) => {
      // debugger;
      // console.log(res);
      this.ProductFamilyLists = res.FamilyList;
    }
    );
  }

  getProductList(): void {
    this.spinner.show();
    const key = {
      // PlantId: 1
      // PlantId: this.addNewProductForm.value.family
      PlantId: this.productsForm.value.plant
    };
    this.roleService.GetProducts(key).subscribe((res: any) => {
      this.spinner.hide();
      this.productdetailDataSource = new MatTableDataSource<any>(res.ProductList);
      this.getPaginator(this.productdetailDataSource, 0);

    }
    );
    this.isaddNewProductModeIVisible = false;
  }

  GetProductProperties(): void {
    this.spinner.show();
    const key = {
      Option: 'Plant',
      GroupId: this.productsForm.value.plant
    };
    this.roleService.GetProductProperties(key).subscribe((res: any) => {
      this.spinner.hide();
      this.productPropertyDataSource = new MatTableDataSource<any>(res.ProductPropertyList);
      this.getPaginator(this.productPropertyDataSource, 1);  // already 1
    }
    );
  }

  DeleteProductFamily(element: any): void {
    const key = {
      ProductFamilyId: element.Id
    };
    this.roleService.DeleteProductFamily(key).subscribe((res: any) => {
      this.AddNewFamily();
      this.toaster.success('Success', 'Data deleted Successfully!');
    }
    );
  }

  DeleteProductPropertyeteProduct(element: any): void {
    const key = {
      UpdateId: element.Id
    };
    this.roleService.DeleteProductPropertyeteProduct(key).subscribe((res: any) => {
      this.GetProductProperties();
      this.toaster.success('Success', 'Data deleted Successfully!');
    }
    );
  }

  onGroupChange(event: any): void {
    // console.log(event.target.value);
    // const key = {
    //   FamilyId: this.
    // };
    // this.roleService.getPlantData(key).subscribe((res: any) => {
    //   this.DepartList = res.DepartList;
    // },
    // (error) => {
    //   this.toaster.error('Error', error.error.message);
    // }
    // );
  }

  openAddProductModel(): void {
    // this.addNewProductForm.reset();
    // console.log(this.ProductFamilyList);
    this.addNewProductForm.reset();
    this.getProductFamiliesDropDown();
    this.isaddNewProductModeIVisible = true;
    this.formMode = true;
  }
  openEditProductModel(element: any): void {
    this.formMode = false;
    this.isaddNewProductModeIVisible = true;
    this.TypeId = element.Id;
    this.addNewProductForm.get('family')?.setValue(this.FamilyListForAddNewProduct.find(x => x.Family === element.Family).Id);
    this.addNewProductForm.get('productCode')?.setValue(element.ProductCode);
    this.addNewProductForm.get('productDescription')?.setValue(element.ProductDescription);
  }
  closeAddProductModel(): void {
    this.isaddNewProductModeIVisible = false;
  }

  addNewProductModelFormSubmit(): void {
    const key = {
      ProductFamilyId: this.addNewProductForm.value.family,
      ProductCode: this.addNewProductForm.value.productCode,
      ProductDescription: this.addNewProductForm.value.productDescription,
      PlantId: this.productsForm.value.plant,
      GroupId: this.productsForm.value.group
    };
    this.roleService.InsertProduct(key).subscribe((res: any) => {
      this.isaddNewProductModeIVisible = false;
      this.getProductList();
      this.GetProductUnits();
      // this.showPopupMsg(res.ErrorMessage);
      this.toaster.success('Success', 'Data entered Successfully!');
    },
      (error) => {
        this.toaster.error('Error', error.error.err.message);
      }
    );

  }
  UpdateProduct(): void {
    const key = {
      UpdateId: this.TypeId,
      ProductFamilyId: this.addNewProductForm.value.family,
      ProductCode: this.addNewProductForm.value.productCode,
      ProductDescription: this.addNewProductForm.value.productDescription
    };
    this.roleService.UpdateProduct(key).subscribe((res: any) => {
      this.getProductList();
      this.GetProductUnits();
      this.toaster.success('Success', 'Data updated Successfully!');
    }
    );
  }

  DeletePropertyMapping(element: any): void {
    const key = {
      UpdateId: element.Id
    };
    this.roleService.DeletePropertyMapping(key).subscribe((res: any) => {
      this.GetPropertyMapping();
      this.toaster.success('Success', 'Data deleted Successfully!');
    }
    );
  }

  DeleteProduct(element: any): void {
    const key = {
      UpdateId: element.Id
    };
    this.roleService.DeleteProduct(key).subscribe((res: any) => {
      this.getProductList();
      this.GetProductUnits();
      this.toaster.success('Success', 'Data deleted Successfully!');
    },
      (error) => {
        this.toaster.error('Error', error.error.err.message);
      }
    );
  }

  editProductModeIFormSubmit(): void {
    const key = {
      UpdateId: this.addNewFamilyForm.value.Id,
      ProductDescription: this.addNewFamilyForm.value.productFamily
    };
    this.roleService.updateProductFamily(key).subscribe((res: any) => {
      this.getProductFamily();
      this.addNewFamilyForm.reset();
      this.formMode1 = true;
      this.toaster.success('Success', 'Data updated Successfully!');
      //  this.showPopupMsg(res.ErrorMessage);
    }
    );
  }

  showPopupMsg(msg: any): any {
    debugger;
    if (msg !== null) {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
        // this.isaddNewUserModeItemModelVisible = false;
      } else {
        this.toaster.error('Error', msg.substring(3, msg.length));
      }
    }
  }
  AddNewFamily(): void {
    this.isAddNewFamily = true;
    this.formMode1 = true;
    this.getProductFamily();
  }
  closeAddFamilyModel(): void {
    this.isAddNewFamily = false;
    this.addNewFamilyForm.reset();
  }
  addNewFamilyModelFormSubmit(): void {
    this.AddNewFamily();
    this.InsertProductFamily();
    this.addNewFamilyForm.reset();
  }

  InsertProductFamily(): void {
    if (this.ProductFamilyList.filteredData.filter(c => c.Family === this.addNewFamilyForm.value.productFamily).length > 0) {
      this.toaster.error('Error', 'Family Name Already Added');
      return;
    } else {
      const key = {
        ProductFamily: this.addNewFamilyForm.value.productFamily,
        GroupId: this.productsForm.value.group,
        PlantId: this.productsForm.value.plant
      };
      this.roleService.InsertProductFamily(key).subscribe((res: any) => {
        this.ProductFamilyList = new MatTableDataSource<any>(res.ProductFamilyList);
        this.getProductFamily();
        this.getPaginator(this.ProductFamilyList, 8);
        this.toaster.success('Success', 'Data added Successfully!');
        // this.showPopupMsg(res.ErrorMessage);
      }
      );
    }
  }
  // tslint:disable-next-line:typedef
  editFamily(element: any) {
    this.formMode1 = false;
    const family = element.Family;
    this.addNewFamilyForm.get('Id')?.setValue(element.Id);
    this.addNewFamilyForm.get('productFamily')?.setValue(family);
    // this.editProductModeIFormSubmit();
  }
  // tslint:disable-next-line:typedef
  deleteFamily(element: any) {
    // console.log('delete family');
    this.toaster.error('Error', 'Data deleted Successfully!');
  }
  AddNewProductProperty(): void {
    // console.log('add');
  }
  addNewProductPropertyFormSubmit(): void {
    const key = {
      GroupId: this.productsForm.value.group,
      PlantId: this.productsForm.value.plant,
      ProductPropertyDescription: this.ProductPropertyForm.value.ProductProperty
    };
    this.roleService.InsertProductProperty(key).subscribe((res: any) => {
      this.GetProductProperties();
      this.ProductPropertyForm.reset();
      this.toaster.success('Success', 'Data entered Successfully!');
      // this.showPopupMsg(res.ErrorMessage);
    }
    );
    // this.ProductPropertyForm.get('ProductProperty')?.setValue((x: { ProductProperty: any; }) => x.ProductProperty);
  }
  editProductProperty(element: any): void {
    this.PropertyMode = false;
    this.MappingMode = false;
    // this.ProductPropertyId = element.productPropertyId,
    // this.ProductPropertyForm.get('productPropertyId')?.setValue(element.Pro);
    this.ProductPropertyForm.get('Id')?.setValue(element.Id);
    this.ProductPropertyForm.get('ProductProperty')?.setValue(element.ProductDescription);
  }
  editProductPropertyFormSubmit(): void {
    const key = {
      UpdateId: this.ProductPropertyForm.value.Id,
      ProductPropertyDescription: this.ProductPropertyForm.value.ProductProperty
    };
    this.roleService.UpdateProductProperty(key).subscribe((res: any) => {
      this.GetProductProperties();
      this.PropertyMode = true;
      this.ProductPropertyForm.reset();
      this.toaster.success('Success', 'Data updated Successfully!');
    }
    );
  }

  GetCharacteristics(): void {
    this.spinner.show();
    const key = {
      ProductPropertyId: this.Product
    };
    // debugger;
    this.roleService.GetCharacteristics(key).subscribe((res: any) => {
      this.CharacteristicsList = res.CharacteristicsList;
      this.spinner.hide();
      this.productCharactersticsDataSource = new MatTableDataSource<any>(res.CharacteristicsList);
      this.getPaginator(this.productCharactersticsDataSource, 4);   // already 3
    },
      (error: any) => {
        this.spinner.hide();
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  GetCharacteristicsOfDropdown(event: any): void {
    // debugger;
    const key = {
      ProductPropertyId: event.value
    };
    this.roleService.GetCharacteristics(key).subscribe((res: any) => {
      this.CharacteristicsList = res.CharacteristicsList;
    }
    );
  }

  GetCharacteristicsByProductProperties(): void {
    // debugger;
    const key = {
      Id: this.ProductPropertyId
    };
    this.roleService.GetCharacteristicsByProductProperties(key).subscribe((res: any) => {
      console.log(res);
      this.CharacteristicsList = res.CharList;
    }
    );
  }

  GetSpecificationsByProductProperties(): void {
    const key = {
      Id: this.ProductPropertyId
    };
    this.roleService.GetSpecificationsByProductProperties(key).subscribe((res: any) => {
      this.SpecificationList = res.SpecList;
    }
    );
  }

  InsertCharacteristics(): void {
    const key = {
      ProductPropertyId: this.Product,  // got id from product
      CharacteristicDescription: this.addNewCharactersticsForm.value.productCharacterstics
    };
    this.roleService.InsertCharacteristics(key).subscribe((res: any) => {
      this.GetCharacteristics();
      this.toaster.success('Success', 'Data entered Successfully!');
      // this.showPopupMsg(res.ErrorMessage);
    },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
    this.addNewCharactersticsForm.reset();
  }

  popupDialog(): void {
    this.popupMode = true;
    this.date1 = new Date();
    this.dialog.open(this.addDialogModel, { id: 'message-pop-up', panelClass: 'custom-dialog-container', height: '40%', width: '40%' });
  }

  updatepopupDialog(): void {
    this.popupMode = false;
    this.date1 = new Date();
    this.dialog.open(this.addDialogModel, { id: 'message-pop-up', panelClass: 'custom-dialog-container', height: '40%', width: '40%' });
  }

  InsertMapping(): void {
    const key = {
      CharId: this.ProductMappingForm.value.Characterstics,
      SpecId: this.ProductMappingForm.value.Specifications,
      LReject: this.ProductMappingForm.value.LRL,
      LWarning: this.ProductMappingForm.value.LWL,
      Target: this.ProductMappingForm.value.Target,
      Optimum: this.ProductMappingForm.value.Optimum,
      UReject: this.ProductMappingForm.value.UWL,
      UWarning: this.ProductMappingForm.value.URL
    };
    this.roleService.InsertMapping(key).subscribe((res: any) => {
      // this.GetActiveSpecifications();
      // this.ProductMappingForm.reset();
      if (res.ErrorMessage == null) {
        // this.toaster.success('Success', 'Data entered Successfully!');
        this.GetActiveSpecifications();
        this.ProductMappingForm.reset();
        this.toaster.success('Success', 'Data entered Successfully!');
        this.dialog.getDialogById('message-pop-up')?.close();
      } else {
        this.showPopupMsg(res.ErrorMessage);
      }
      // this.GetProductMapping();
    },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  saveMapping(): void {
    this.GetActiveSpecifications();
    this.ProductMappingForm.reset();
    this.toaster.success('Success', 'Data entered Successfully!');
    this.dialog.getDialogById('message-pop-up')?.close();
  }

  cancelMapping(): void {
    this.dialog.getDialogById('message-pop-up')?.close();
  }

  UpdateMapping(): void {
    this.popupMode = false;
    const key = {
      UpdateId: this.ProductMappingForm.value.Id,
      CharId: this.ProductMappingForm.value.Characterstics,
      SpecId: this.ProductMappingForm.value.Specifications,
      LReject: this.ProductMappingForm.value.LRL,
      LWarning: this.ProductMappingForm.value.LWL,
      Target: this.ProductMappingForm.value.Target,
      Optimum: this.ProductMappingForm.value.Optimum,
      UReject: this.ProductMappingForm.value.UWL,
      UWarning: this.ProductMappingForm.value.URL,
    };
    this.roleService.UpdateMapping(key).subscribe((res: any) => {
      console.log(res);
      this.GetActiveSpecifications();
      this.MappingMode = true;
      this.ProductMappingForm.reset();
      this.dialog.getDialogById('message-pop-up')?.close();
      if (res.ErrorMessage == null) {
      } else {
        this.showPopupMsg(res.ErrorMessage);
      }
      // this.GetProductMapping();
      // this.toaster.success('Success', 'Data updated Successfully!');
    }
    );
  }

  DeleteMapping(element: any): void {
    // debugger;
    const key = {
      // UpdateId: this.ProductMappingForm.value.Id
      UpdateId: element.Id
    };
    this.roleService.DeleteMapping(key).subscribe((res: any) => {
      // this.showPopupMsg(res.ErrorMessage);
      this.GetProductMapping();
      this.GetActiveSpecifications();
      this.toaster.success('Success', 'Data deleted Successfully!');
    }
    );
  }

  UpdateCharacteristics(): void {
    const key = {
      UpdateId: this.addNewCharactersticsForm.value.Id,
      CharacteristicDescription: this.addNewCharactersticsForm.value.productCharacterstics
    };
    this.roleService.UpdateCharacteristics(key).subscribe((res: any) => {
      this.GetCharacteristics();
      this.showPopupMsg(res.ErrorMessage);
      this.CharacteristicsMode = true;  // new
      this.addNewCharactersticsForm.reset(); // new
      this.toaster.success('Success', 'Data updated Successfully!');
    },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  GetSpecifications(element: any): void {
    this.spinner.show();
    const key = {
      ProductPropertyId: this.ProductPropertyId
    };
    this.roleService.GetSpecifications(key).subscribe((res: any) => {
      this.spinner.hide();
      this.specRatedataSource = new MatTableDataSource<any>(res.SpecificationList);
      this.getPaginator(this.specRatedataSource, 4);
    }
    );
  }

  DeleteSpecifications(element: any): void {
    const key = {
      UpdateId: element.Id
    };
    this.roleService.DeleteSpecifications(key).subscribe((res: any) => {
      this.GetSpecifications(element);
      this.toaster.success('Success', 'Data deleted Successfully!');
    }
    );
  }

  GetDataTypes(element: any): void {
    this.SpecMode = true;
    this.addNewRateSpecsForm.reset();
    this.ProductPropertyId = element.Id,
      this.dialog.open(this.visibleRateSpecsModal, { panelClass: 'custom-dialog-container', height: '90%', width: '100%' });
    this.GetSpecifications(element);
  }

  GetProductMapping(): void {
    const key = {
      Id: this.ProductPropertyId
    };
    this.roleService.GetActiveSpecifications(key).subscribe((res: any) => {
      // console.log(res);
      // debugger;
      this.mappingDataSource = new MatTableDataSource<any>(res.ActiveSpecificationsList);
      this.getPaginator(this.mappingDataSource, 3);
      // this.ProductPropertyId = element.Id;
      this.GetCharacteristicsByProductProperties();
      this.GetSpecificationsByProductProperties();
      // this.ProductPropertyForm.reset();
    }
    );
  }

  // GetActiveSpecificationss(element: any): void {
  //   this.ProductPropertyForm.reset();
  //   this.PropertyMode = true;
  //   this.dialog.open(this.visibleMappingModal  , {panelClass: 'custom-dialog-container' , height: '90%', width: '100%'});
  //   const key = {
  //     Id: element.Id
  //   };
  //   this.roleService.GetActiveSpecifications(key).subscribe((res: any) => {
  //     this.mappingDataSource = new MatTableDataSource<any>(res.ActiveSpecificationsList);
  //     this.getPaginator(this.mappingDataSource, 3);
  //     this.ProductPropertyId = element.Id;
  //   }
  //   );
  // }
  GetActiveSpecificationsOnClick(element: any): void {
    debugger;
    this.ProductPropertyForm.reset();
    this.PropertyMode = true;
    this.MappingMode = true;
    // this.ProductMappingForm.get('Characterstics')?.setValue()
    this.dialog.open(this.visibleMappingModal, { panelClass: 'custom-dialog-container', height: '90%', width: '100%' });
    this.ProductPropertyId = element.Id;
    this.GetActiveSpecifications();
  }

  resetProductProperty(): void {
    this.ProductPropertyForm.get('ProductProperty')?.reset();
  }

  GetActiveSpecifications(): void {

    this.ProductMappingForm.reset();
    this.MappingMode = true;
    this.spinner.show();
    const key = {
      Id: this.ProductPropertyId
    };
    this.roleService.GetActiveSpecifications(key).subscribe((res: any) => {
      this.spinner.hide();
      this.mappingDataSource = new MatTableDataSource<any>(res.ActiveSpecificationsList);
      this.getPaginator(this.mappingDataSource, 4);  // already 3
      this.GetCharacteristicsByProductProperties();
      this.GetSpecificationsByProductProperties();
      // this.ProductPropertyForm.reset();
    }
    );
  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
      data.sort = this.sort.toArray()[index];
      // data.sort = this.sort;
    }, 1000);
  }


  // Characterstics
  onClickCharacterstics(element: any): void {
    this.CharacteristicsMode = true;
    this.addNewCharactersticsForm.reset();
    this.dialog.open(this.visiblecharactersticsModal, { panelClass: 'custom-dialog-container', height: '90%', width: '100%' });
    this.Product = element.Id;
    this.GetCharacteristics();
  }
  closeCharactersticsModel(): void {

  }

  DeleteCharacteristics(element: any): void {
    const key = {
      UpdateId: element.Id
    };
    this.roleService.DeleteCharacteristics(key).subscribe((res: any) => {
      this.GetCharacteristics();
      this.toaster.success('Success', 'Data deleted Successfully!');
    }
    );
  }
  addNewCharactersticsFormSubmit(): void {
    // this.InsertCharacteristics();
  }
  // tslint:disable-next-line:typedef
  onClickEditcharacterstics(element: any) {
    this.addNewCharactersticsForm.get('Id')?.setValue(element.Id);
    this.addNewCharactersticsForm.get('productCharacterstics')?.setValue(element.Characteristic);
    this.CharacteristicsMode = false;
  }
  // onClickRateSpecs(element)
  onClickRateSpecs(element: any): void {
    this.dialog.open(this.visibleRateSpecsModal, { panelClass: 'custom-dialog-container', height: '90%', width: '100%' });
    // this.GetDataTypes();
    this.Product = element.ProductProperty;
  }

  UpdateSpecifications(): void {
    const key = {
      UpdateId: this.addNewRateSpecsForm.value.Id,
      // DataTypeId: this.addNewRateSpecsForm.value.DataType,
      DataTypeId: this.DataTypeValue, // this.DataTypeList.find(x => x.id === this.DataTypeValue).name,
      ProductPropertyId: this.ProductPropertyId, // this.addNewRateSpecsForm.value.
      SpecificationDescription: this.addNewRateSpecsForm.value.Specification,
      EngUOM: this.addNewRateSpecsForm.value.EngineeringUoM,
      Precision: this.addNewRateSpecsForm.value.Precision
    };
    this.roleService.UpdateSpecifications(key).subscribe((res: any) => {
      this.SpecMode = true;
      this.addNewRateSpecsForm.reset();
      this.DataTypeValue = '';
      this.GetSpecifications(this.ProductPropertyId);
      this.toaster.success('Success', 'Data updated Successfully!');
      // this.showPopupMsg(res.ErrorMessage);
    },
      (error) => {
        // this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  InsertSpecifications(): void {
    const key = {
      ProductPropertyId: this.ProductPropertyId,
      // ProductPropertyId: this.ProductPropertyForm.value.Id,
      DataTypeId: this.addNewRateSpecsForm.value.DataType,
      SpecificationDescription: this.addNewRateSpecsForm.value.Specification,
      EngUOM: this.addNewRateSpecsForm.value.EngineeringUoM,
      Precision: this.addNewRateSpecsForm.value.Precision
    };
    this.roleService.InsertSpecifications(key).subscribe((res: any) => {
      this.GetSpecifications(this.ProductPropertyId);
      this.addNewRateSpecsForm.reset();
      this.toaster.success('Success', 'Data entered Successfully!');
    },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  // GetTreeModal(): void {
  //   const key = {
  //     // GroupId: this.productsForm.value.group
  //     EGId: this.productsForm.value.group,
  //     Level: 'Unit',
  //     IsOptional: 0
  //   };
  //   this.roleService.GetTreeModal(key).subscribe((res: any) => {
  //     // this.specMappingDataSource = new MatTableDataSource<any>(res.TreeModalList);
  //     this.TreeList = res.

  //   }
  //   );
  // }

  GetProductUnits(): void {
    // debugger;
    // this.addNewMachineMappingForm.get('Id')?.setValue(this.ProductPropertyId);
    const key = {
      PlantId: this.productsForm.value.plant
    };
    this.roleService.GetProducts(key).subscribe((res: any) => {
      // console.log(res);
      this.specMappingDataSource = new MatTableDataSource<any>(res.ProductList);
      this.getProductList();
      this.getPaginator(this.specMappingDataSource, 2);
    });
  }
  onClickEditSpecRate(element: any): void {
    // debugger;
    this.SpecMode = false;
    this.DataTypeValue = this.DataTypeList.find(c => c.name === element.DataType).id;
    this.addNewRateSpecsForm.get('Id')?.setValue(element.Id);
    this.addNewRateSpecsForm.get('Specification')?.setValue(element.Specification);
    // this.addNewRateSpecsForm.get('DataType')?.setValue(this.DataTypeList[0].id);
    // tslint:disable-next-line:max-line-length
    // this.addNewRateSpecsForm.get('DataType')?.setValue(this.DataTypeList.find(x => x.DataType. === element.DataType).DataType);
    this.addNewRateSpecsForm.get('EngineeringUoM')?.setValue(element.EngineeringUoM);
    this.addNewRateSpecsForm.get('Precision')?.setValue(element.Precision);


  }

  // Mapping
  onClickProductProperty(element: any): void {
    this.ProductMappingForm.reset();
    this.MappingMode = true;
    this.dialog.open(this.visibleMappingModal, { panelClass: 'custom-dialog-container', height: '90%', width: '100%' });

  }
  addNewMappingFormSubmit(): void {
    // this.InsertMapping();
    this.InsertMapping();
    this.GetActiveSpecifications();
  }

  GetProductPropertiesForMappings(): void {
    this.spinner.show();
    const key = {
      ProductId: this.ProductPropertyId
    };
    this.roleService.GetProductUnits(key).subscribe((res: any) => {
      this.spinner.hide();
      this.machineMappingDataSource = new MatTableDataSource<any>(res.ProductUnitList);
      this.getPaginator(this.machineMappingDataSource, 4);
    }
    );
  }
  // new
  GetProductPropertiesForMapping(): void {
    // debugger;
    const key = {
      GroupId: this.productsForm.value.group
    };
    this.roleService.GetProductPropertiesForMappings(key).subscribe((res: any) => {
      console.log(res);
      this.PropertyMappingDataSource = new MatTableDataSource<any>(res.PPList);
      this.getPaginator(this.PropertyMappingDataSource, 3);
    }
    );
  }

  InsertProductUnit(): void {
    this.spinner.show();
    const key = {
      ProductId: this.ProductPropertyId,
      MachineIdArr: this.UnitArray
    };
    this.roleService.InsertProductUnit(key).subscribe((res: any) => {
      this.spinner.hide();
      this.machineMappingDataSource = new MatTableDataSource<any>(res.ProductList);
      this.GetProductPropertiesForMappings();
      this.addNewMachineMappingForm.get('Unit')?.reset();
      this.UnitArray = [];
      this.UnitArrayName = [];
      this.UnitText = '';
      this.getPaginator(this.machineMappingDataSource, 4);
      this.toaster.success('Success', 'Data entered Successfully!');
      // if (res.ErrorMessage != null)
      // {
      //   this.showPopupMsg(res.ErrorMessage);
      // }
      // else
      // {
      //   // tslint:disable-next-line:no-unused-expression
      //   (error: any) => {
      //     this.showPopupMsg(error.ErrorMessage);
      //   };
      // }
    },
    );
  }

  DeleteProductUnit(element: any): void {
    const key = {
      UpdateId: element.Id
    };
    this.roleService.DeleteProductUnit(key).subscribe((res: any) => {
      this.GetProductPropertiesForMappings();
      this.toaster.success('Success', 'Data deleted Successfully!');
    }
    );
  }

  GetProductUnitsDropDown(): void {
    // debugger;
    const key = {
      ProductId: this.ProductPropertyId
    };
    this.roleService.GetProductUnitsDropDown(key).subscribe((res: any) => {
      this.ProductUnitDropDownList = res.UnitList;
    }
    );
  }

  applyFilterOne(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ProductFamilyList.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForProduct(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.productdetailDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForProductProperty(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.productPropertyDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForProductSpecs(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.specMappingDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForChar(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.productCharactersticsDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForRateSpecs(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.specRatedataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForProductMapping(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.mappingDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForMachineMapping(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.machineMappingDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForPropertyMapping(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.PropertyMappingDataSource.filter = filterValue.trim().toLowerCase();
  }



  GetPropertyMapping(): void {
    // tslint:disable-next-line:no-debugger
    this.spinner.show();
    const key = {
      Id: this.ProductPropertyId
    };
    this.roleService.GetPropertyMapping(key).subscribe((res: any) => {
      this.spinner.hide();
      this.PropertyMappingDataSource = new MatTableDataSource<any>(res.PropertyMappingList);
      this.PropertyMappingList = res.PropertyMappingList;
      this.getPaginator(this.PropertyMappingDataSource, 4);
    }
    );
  }
  onClickEditMapping(element: any): void {
    // debugger;
    this.ProductMappingForm.reset();
    this.MappingMode = false;
    this.ProductMappingForm.get('Id')?.setValue(element.Id);
    // tslint:disable-next-line:max-line-length
    if (element.Characteristic === null) {
      // tslint:disable-next-line:max-line-length
      this.ProductMappingForm.get('Characterstics')?.setValue('');
    } else {
      // tslint:disable-next-line:max-line-length
      this.ProductMappingForm.get('Characterstics')?.setValue(this.CharacteristicsList.find((x: any) => x.TypeName === element.Characteristic).TypeId);
    }
    if (element.Specification === null) {
      // tslint:disable-next-line:max-line-length
      this.ProductMappingForm.get('Specifications')?.setValue('');
    } else {
      // tslint:disable-next-line:max-line-length
      this.ProductMappingForm.get('Specifications')?.setValue(this.SpecificationList.find((x: any) => x.TypeName === element.Specification).TypeId);
    }
    // tslint:disable-next-line:max-line-length
    // this.ProductMappingForm.get('ProductProperty')?.setValue(this.PPList.find((x: any) => x.TypeName === element.ProductProperty).TypeId);
    this.ProductMappingForm.get('LRL')?.setValue(element.LRL);
    this.ProductMappingForm.get('LWL')?.setValue(element.LWL);
    this.ProductMappingForm.get('Target')?.setValue(element.TargetSpeed);
    this.ProductMappingForm.get('Optimum')?.setValue(element.OptimumSpeed);
    this.ProductMappingForm.get('UWL')?.setValue(element.UWL);
    this.ProductMappingForm.get('URL')?.setValue(element.URL);
  }

  clearReset(): void {
    this.addNewMachineMappingForm.get('Unit')?.reset();
    this.UnitArray = [];
    this.UnitArrayName = [];
    this.UnitText = '';
  }

  onClickMachineMapping(element: any): void {
    this.addNewMachineMappingForm.get('Unit')?.reset();
    this.UnitArray = [];
    this.UnitArrayName = [];
    this.UnitText = '';
    this.addNewMachineMappingForm.get('Id')?.setValue(element.Id);
    this.dialog.open(this.visibleMachineMappingModal, { panelClass: 'custom-dialog-container', height: '90%', width: '100%' });
    this.addNewMachineMappingForm.get('ProductCode')?.setValue(element.ProductCode);
    this.addNewMachineMappingForm.get('ProductDescription')?.setValue(element.ProductDescription);
    this.ProductPropertyId = element.Id;
    this.GetProductPropertiesForMappings();
  }

  resetProperty(): void {
    this.propertymappingform.get('ProductProperty')?.reset();
    this.propertymappingform.get('Characterstics')?.reset();
    this.propertymappingform.get('Machine')?.reset();
    this.PropertyMappingMode = true;
  }
  onClickPropertyMapping(element: any): void {
    // debugger;
    // this.propertymappingform.reset();
    this.propertymappingform.get('ProductProperty')?.reset();
    this.propertymappingform.get('Characterstics')?.reset();
    this.propertymappingform.get('Machine')?.reset();
    this.PropertyMappingMode = true;
    this.dialog.open(this.visiblePropertyMappingModal, { panelClass: 'custom-dialog-container', height: '90%', width: '100%' });
    this.propertymappingform.get('ProductCode')?.setValue(element.ProductCode);
    this.propertymappingform.get('ProductDescription')?.setValue(element.ProductDescription);
    // this.propertymappingform.get('ProductProperty')?.setValue(element.ProductProperty);
    // this.propertymappingform.get('Characterstics')?.setValue(element.Characteristic);
    // this.propertymappingform.get('Machine')?.setValue(element.Machine);
    this.ProductPropertyId = element.Id;
    // this.Product = element.Id;
    this.GetPropertyMapping();
    this.ProductProperty();
    this.GetProductUnitsDropDown();
    // this.GetCharacteristics();
    // this.GetProductPropertiesForMapping();
    // this.GetProductPropertiesForMapping();
  }

  onClickPropertyMappingEdit(element: any): void {
    debugger;
    this.PropertyMappingMode = false;
    // this.GetCharacteristicsByProductProperties();
    this.ProductProperty();
    // this.GetProductPropertiesForMapping();
    this.GetCharacteristics();
    this.GetProductUnitsDropDown();
    this.propertymappingform.get('ProductProperty')?.setValue(this.PPList.find((x: any) => x.TypeName === element.ProductProperty).TypeId);
    const productProperty = this.PPList.find(x => x.TypeName === element.ProductProperty).TypeId;
    // tslint:disable-next-line:max-line-length
    this.propertymappingform.get('Characterstics')?.setValue(this.CharacteristicsList.find((x: any) => x.Characteristic === element.Characteristic).Id);
    // tslint:disable-next-line:max-line-length
    this.propertymappingform.get('Machine')?.setValue(this.ProductUnitDropDownList.find((x: any) => x.TypeName === element.ProductUnit).TypeId);
  }

  InsertPropertyMapping(): void {
    // debugger;
    this.MappingArrayOne = [
      {
        CharId: this.propertymappingform.value.Characterstics,
        ProductPropertyId: this.propertymappingform.value.ProductProperty,
        ProductUnitId: this.propertymappingform.value.Machine
      },
    ];
    const result = this.MappingArrayOne.filter(o1 => this.PropertyMappingDataSource.filteredData.some(o2 => o1.CharId === o2.CharId));
    const key = {
      CharId: this.propertymappingform.value.Characterstics,
      ProductId: this.ProductPropertyId,
      ProductPropertyId: this.propertymappingform.value.ProductProperty,
      ProductUnitId: this.propertymappingform.value.Machine
    };
    this.roleService.InsertPropertyMapping(key).subscribe((res: any) => {
      this.GetPropertyMapping();
      this.toaster.success('Success', 'Data entered Successfully!');
      this.propertymappingform.get('ProductProperty')?.reset();
      this.propertymappingform.get('Characterstics')?.reset();
      this.propertymappingform.get('Machine')?.reset();
    }
    );
  }

  UpdatePropertyMapping(): void {
    debugger;
    const key = {
      UpdateId: this.ProductPropertyId,
      CharId: this.propertymappingform.value.Characterstics,
      ProductId: this.ProductPropertyId,
      ProductPropertyId: this.propertymappingform.value.ProductProperty,
      ProductUnitId: this.propertymappingform.value.Machine
    };
    this.roleService.UpdatePropertyMapping(key).subscribe((res: any) => {
      this.GetPropertyMapping();
      this.toaster.success('Success', 'Data updated Successfully!');
      this.propertymappingform.get('ProductProperty')?.reset();
      this.propertymappingform.get('Characterstics')?.reset();
      this.propertymappingform.get('Machine')?.reset();
      this.PropertyMappingMode = true;
      // this.getPaginator()
    }
    );
  }
  // search
  onClickSearch(): void {
    // open dialog
  }

  ProductProperty(): void {
    // debugger;
    const key = {
      GroupId: this.productsForm.value.group
    };
    this.roleService.GetProductPropertiesForMappings(key).subscribe((res: any) => {
      this.PPList = res.PPList;
      // this.GetCharacteristics();
    }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }
  GetMenuChildList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetMenuChildList(obj).subscribe((data: any) => {
      this.homeCardList = data.BreadcrumList;
      // console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      // console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }



  // plant data started
  openModal(): void {
    this.updateOrderprocessingData = true;
    const key = {
      EGId: this.productsForm.value.group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe((data: any) => {
      const plantGroupData: PlantGroupElement[] = data.TreeModalList;
      this.plantGroupSource.data = plantGroupData;
      this.treeControl.dataNodes = this.plantGroupSource.data;
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  closePlantGroupModel(): void {
    this.updateOrderprocessingData = false;
  }

  onNodeSelect(node: any): void {
    // debugger;
    this.updateOrderprocessingData = false;
    // tslint:disable-next-line: align
    this.MachineId = node.PrimaryId;
    // this.GetProductData();
    if (this.UnitArray.includes(node.PrimaryId)) {
      this.toaster.error('Error', 'Unit Already Added');
    } else {
      this.UnitArray.push(node.PrimaryId);
      this.UnitArrayName.push(node.text);
      this.UnitText = this.UnitArrayName.toString();
      this.addNewMachineMappingForm.get('Unit')?.setValue(this.UnitText);
    }
    // this.operationForm.get('Machine')?.setValue(node.text);
  }

  SelectUnitName(unitname: any): void {
    if (unitname.title === 'UNT') {
      this.UnitName = unitname.name;
      this.isPlantGroupModelVisible = false;
    }
  }

  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }

  getProductMasterData() {
    this.spinner.show();
    this.productBulkUploadService.getProductMasterData().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status)
        this.masterData = res.data;
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', err.message);
    })
  }

  onReadExcel(event: any) {
    this.excelData = event.addedFiles[0];
    const fileName = this.excelData.name;
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.excelData);
    fileReader.onload = (e) => {
      let arrayBuffer: any = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.excelData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.validateSheet();
    }
  }

  validateSheet() {
    try {
      let user: any = localStorage.getItem('user');
      user = JSON.parse(user);
      let familyMasterData: any = [];
      let productMasterData: any = [];
      let productUnitMasterData: any = [];
      let productPropertyMasterData: any = [];
      let characteristicsMasterData: any = [];
      let specificationMasterData: any = [];
      let activeSpecMasterData: any = [];
      let prodCharacteristicsMasterData: any = [];

      if (this.excelData) {
        let index = 0;
        for (let data of this.excelData) {
          if (!data['Group']) {
            this.toaster.warning(`Please Enter Group on line ${index + 1}`, 'Warning');
            this.excelData = [];
            return;
          }
          else if (!data['Plant']) {
            this.toaster.warning(`Please Enter Plant on line ${index + 1}`, 'Warning');
            this.excelData = [];
            return;
          }
          else if (!data['FamilyName']) {
            this.toaster.warning(`Please Enter FamilyName on line ${index + 1}`, 'Warning');
            this.excelData = [];
            return;
          }
          else {
            const group = this.GroupList.find((grp: any) => grp.TypeName == data['Group'] || grp.TypeName.toLowerCase() == data['Group'].toLowerCase());
            if (!group) {
              this.toaster.warning(`Group ${data['Group']} doesn't exist in database on line ${index + 1}`, 'Warning');
              this.excelData = [];
              return;
            }
            const plant = this.masterData.plant.find((plt: any) => plt.ptDesc == data['Plant'] || plt.ptDesc.toLowerCase() == data['Plant'].toLowerCase());
            if (!plant) {
              this.toaster.warning(`Plant ${data['Plant']} doesn't exist in database on line ${index + 1}`, 'Warning');
              this.excelData = [];
              return;
            }
            const family = this.masterData.productFamily.find((fam: any) => fam.pfDesc == data['FamilyName'] || fam.pfDesc.toLowerCase() == data['FamilyName'].toLowerCase())
            if (!family) {
              const familyData = { eg: group.TypeId, pt: plant.ptId, pfDesc: data['FamilyName'], createdBy: user.UserId };
              const chkFamExist = familyMasterData.find((fam: any) => fam.pfDesc == data['FamilyName'] || fam.pfDesc.toLowerCase() == data['FamilyName'].toLowerCase());
              if (!chkFamExist)
                familyMasterData.push(familyData);
            }
            if (data['Prod_Code']) {
              const product = this.masterData.products.find((prod: any) => prod.prodCode == data['Prod_Code'] || prod.prodCode.toString().toLowerCase() == data['Prod_Code'].toString().toLowerCase());
              if (!product) {
                let productData;
                if (family)
                  productData = { eg: group.TypeId, pt: plant.ptId, pf: family.pfId, prodCode: data['Prod_Code'], prodDesc: data['Prod_Desc'], createdBy: user.UserId };
                else {
                  const famIndex = familyMasterData.findIndex((fam: any) => fam.pfDesc == data['FamilyName'] || fam.pfDesc.toLowerCase() == data['FamilyName'].toLowerCase());
                  productData = { eg: group.TypeId, pt: plant.ptId, pfIndex: famIndex, prodCode: data['Prod_Code'], prodDesc: data['Prod_Desc'], createdBy: user.UserId };
                }
                const chkProdExist = productMasterData.find((prod: any) => prod.prodCode == data['Prod_Code'] || prod.prodCode.toString().toLowerCase() == data['Prod_Code'].toString().toLowerCase());
                if (!chkProdExist)
                  productMasterData.push(productData);
              }
              if (data['Machine']) {
                const machine = this.masterData.units.find((mcne: any) => mcne.puDesc == data['Machine'] || mcne.puDesc.toLowerCase() == data['Machine'].toLowerCase());
                if (!machine) {
                  this.toaster.warning(`Machine ${data['Machine']} doesn't exist in database on line ${index + 1}`, 'Warning')
                  this.excelData = [];
                  return;
                }
                else {
                  const prodIndex = productMasterData.findIndex((prod: any) => prod.prodCode == data['Prod_Code'] || prod.prodCode.toString().toLowerCase() == data['Prod_Code'].toString().toLowerCase());
                  const productUnit = this.masterData.productUnit.find((prodUnit: any) => (prodUnit.pu.puDesc == data['Machine'] || prodUnit.pu.puDesc.toLowerCase() == data['Machine'].toLowerCase()) && (prodUnit.prod.prodDesc == data['Prod_Code'] || prodUnit.prod.prodDesc.toLowerCase() == data['Prod_Code'].toString().toLowerCase()));
                  if (!productUnit) {
                    let productUnitData;
                    let chkProdUnitExist;
                    if (product) {
                      productUnitData = { prod: product.prodId, pu: machine.puId, createdBy: user.UserId };
                      chkProdUnitExist = productUnitMasterData.find((prodUnit: any) => prodUnit.prod == product.prodId && prodUnit.pu == machine.puId);
                      if (!chkProdUnitExist)
                        productUnitMasterData.push(productUnitData);
                    }
                    else {
                      productUnitData = { prodIndex: prodIndex, pu: machine.puId, createdBy: user.UserId };
                      chkProdUnitExist = productUnitMasterData.find((prodUnit: any) => prodUnit.prodIndex == prodIndex && prodUnit.pu == machine.puId);
                      if (!chkProdUnitExist)
                        productUnitMasterData.push(productUnitData);
                    }
                  }
                  if (data['Property']) {
                    const property = this.masterData.productProperties.find((prodProp: any) => prodProp.ppDesc == data['Property'] || prodProp.ppDesc.toLowerCase() == data['Property'].toLowerCase());
                    if (!property) {
                      let PropertyData: any = { ppDesc: data['Property'], createdBy: user.UserId, eg: group.TypeId, pt: plant.ptId };
                      const chkPropExist = productPropertyMasterData.find((prod: any) => prod.ppDesc == PropertyData.ppDesc || prod.ppDesc.toLowerCase() == PropertyData.ppDesc.toLowerCase());
                      if (!chkPropExist)
                        productPropertyMasterData.push(PropertyData);
                    }
                    let characteristics: any;
                    if (data['Characteristic']) {
                      characteristics = this.masterData.characteristics.find((char: any) => char.charDesc == data['Characteristic']);
                      if (!characteristics) {
                        let characteristicsData;
                        if (property)
                          characteristicsData = { charDesc: data['Characteristic'], createdBy: user.UserId, pp: property.ppId };
                        else {
                          const propIndex = productPropertyMasterData.findIndex((prop: any) => prop.ppDesc == data['Property'] || prop.ppDesc.toLowerCase() == data['Property'].toLowerCase());
                          characteristicsData = { charDesc: data['Characteristic'], createdBy: user.UserId, ppIndex: propIndex };
                        }
                        const chkCharExist = characteristicsMasterData.find((char: any) => char.charDesc == data['Characteristic']);
                        if (!chkCharExist)
                          characteristicsMasterData.push(characteristicsData);
                      }
                      // else {
                      let chkProdCharExist;
                      let prodChar;
                      let charIndex = characteristicsMasterData.findIndex((char: any) => char.charDesc == data['Characteristic']);
                      if (property) {
                        chkProdCharExist = this.masterData.productCharacteristics.find((prodChar: any) => (((prodChar.char && characteristics && prodChar.char.charId == characteristics.charId) || (prodChar.char && characteristicsMasterData[charIndex] && prodChar.char.charDesc == characteristicsMasterData[charIndex].charDesc)) || (prodChar.char && prodChar.char.charDesc == data['Characteristic'])) && (product ? prodChar.prod.prodId == product.prodId : prodChar.prod.prodCode == data['Prod_Code']) && prodChar.pp.ppId == property.ppId && prodChar.pu.puId == machine.puId);
                        prodChar = { createdBy: user.UserId, char: characteristics ? characteristics.charId : '', charIndex: charIndex, prod: product ? product.prodId : '', prodIndex: prodIndex, pp: property.ppId, pu: machine.puId };
                      }
                      else {
                        chkProdCharExist = this.masterData.productCharacteristics.find((prodChar: any) => ((prodChar.char && characteristics && prodChar.char.charId == characteristics.charId) || (prodChar.char && characteristicsMasterData[charIndex] && prodChar.char.charDesc == characteristicsMasterData[charIndex].charDesc)) && (product ? prodChar.prod.prodId == product.prodId : prodChar.prod.prodCode == data['Prod_Code']) && (prodChar.pp.ppDesc == data['Property'] || prodChar.pp.ppDesc.toLowerCase() == data['Property'].toLowerCase()) && prodChar.pu.puId == machine.puId);
                        const propIndex = productPropertyMasterData.findIndex((prodProp: any) => prodProp.ppDesc == data['Property'] || prodProp.ppDesc.toLowerCase() == data['Property'].toLowerCase());
                        prodChar = { createdBy: user.UserId, char: characteristics ? characteristics.charId : '', charIndex: charIndex, prod: product ? product.prodId : '', prodIndex: prodIndex, ppIndex: propIndex, pu: machine.puId };
                      }

                      if (!chkProdCharExist)
                        prodCharacteristicsMasterData.push(prodChar);
                      // }
                    }
                    if (data['Specification']) {
                      const activeSpec = this.masterData.activeSpecs.find((actSpec: any) => (actSpec.char.charDesc == data['Characteristic'] || actSpec.char.charDesc.toLowerCase() == data['Characteristic'].toLowerCase()) && (actSpec.spec.specDesc == data['Specification'] || actSpec.spec.specDesc.toLowerCase() == data['Specification'].toLowerCase()));
                      const specification = this.masterData.specifications.find((spec: any) => spec.specDesc == data['Specification'] || spec.specDesc.toLowerCase() == data['Specification'].toLowerCase());
                      if (!activeSpec && data['Characteristic']) {
                        if (data['Specification']) {
                          const charIndex = characteristicsMasterData.findIndex((char: any) => char.charDesc == data['Characteristic']);
                          let specIndex;
                          if (specificationMasterData && specificationMasterData.length) {
                            specIndex = specificationMasterData.findIndex((spec: any) => spec.specDesc == data['Specification']);
                            if (!specificationMasterData[specIndex])
                              specIndex = specificationMasterData.length
                          }
                          else specIndex = 0;
                          const activeSpecData = { char: characteristics ? characteristics.charId : '', charIndex: charIndex, spec: specification ? specification.specId : '', specIndex: specIndex, createdBy: user.UserId, target: data['Speed'] };
                          let chkActiveSpecExist = activeSpecMasterData.find((actSpec: any) => (activeSpecData.char == actSpec.charId || activeSpecData.charIndex == actSpec.charIndex) && (activeSpecData.spec == actSpec.spec || activeSpecData.specIndex == actSpec.specIndex));
                          if (!chkActiveSpecExist)
                            activeSpecMasterData.push(activeSpecData);
                        }
                        else {
                          this.toaster.warning(`Please Enter Speed on line ${index + 1}`, 'Warning');
                          this.excelData = [];
                          return;
                        }
                      }
                      else if (activeSpec) {
                        const activeSpecData = { asId: activeSpec.asId, target: data['Speed'] };
                        const chkActiveSpeceExist = activeSpecMasterData.find((actSpec: any) => actSpec.asId == activeSpec.asId);
                        if (!chkActiveSpeceExist)
                          activeSpecMasterData.push(activeSpecData);
                      }
                      if (!data['UOM']) {
                        this.toaster.warning(`Please Enter UOM on line ${index + 1}`, 'Warning');
                        this.excelData = [];
                        return;
                      }
                      else if (!data['Datatype']) {
                        this.toaster.warning(`Please Enter Datatype on line ${index + 1}`, 'Warning');
                        this.excelData = [];
                        return;
                      }
                      else if (!data['Precision']) {
                        this.toaster.warning(`Please Enter Precision on line ${index + 1}`, 'Warning');
                        this.excelData = [];
                        return;
                      }
                      else {
                        let dataType = this.masterData.dataType.find((dataTyp: any) => dataTyp.dtDesc == data['Datatype'] || dataTyp.dtDesc.toLowerCase() == data['Datatype'].toLowerCase())
                        if (!dataType) {
                          this.toaster.warning(`Datatype ${data['Datatype']} doesn't exist in database on line ${index + 1}`, 'Warning');
                          this.excelData = [];
                          return;
                        }
                        else {
                          if (!specification) {
                            let specificationData;
                            if (property)
                              specificationData = { specDesc: data['Specification'], engineeringUoM: data['UOM'], precision: data['Precision'], createdBy: user.UserId, dt: dataType.dtId, pp: property.ppId };
                            else {
                              const propIndex = productPropertyMasterData.findIndex((prop: any) => prop.ppDesc == data['Property'] || prop.ppDesc.toLowerCase() == data['Property'].toLowerCase());
                              specificationData = { specDesc: data['Specification'], engineeringUoM: data['UOM'], precision: data['Precision'], createdBy: user.UserId, dt: dataType.dtId, ppIndex: propIndex };
                            }
                            const chkSpecExist = specificationMasterData.find((spec: any) => spec.specDesc == data['Specification'] || spec.specDesc.toLowerCase() == data['Specification'].toLowerCase());
                            if (!chkSpecExist)
                              specificationMasterData.push(specificationData);
                            // else {
                            //   this.toaster.warning(`Specification ${data['Specification']} already exist on line ${index + 1}`, 'Warning');
                            //   this.excelData = [];
                            //   return;
                            // }
                          }
                        }
                      }
                    }
                  }
                }
              }
              else {
                this.toaster.warning(`Prodduct ${data['Prod_Code']} on line ${index + 1} already exist in database`, 'Warning');
                this.excelData = [];
                return;
              }

            }
            else {
              this.toaster.warning(`Family ${data['FamilyName']} on line ${index + 1} already exist in database`, 'Warning');
              this.excelData = [];
              return;
            }
          }
          index++;
        }
        const masterData = { familyMasterData: familyMasterData, productMasterData: productMasterData, productUnitMasterData: productUnitMasterData, productPropertyMasterData: productPropertyMasterData, characteristicsMasterData: characteristicsMasterData, specificationMasterData: specificationMasterData, activeSpecMasterData: activeSpecMasterData, prodCharacteristicsMasterData: prodCharacteristicsMasterData };
        this.uploadMasterData(masterData);
      }
      else
        this.toaster.warning('No Data Found', 'Warning')
    }
    catch (err: any) {
      this.toaster.error(err, 'Error')
    }
  }

  uploadMasterData(masterData: any) {
    this.spinner.show();
    this.productBulkUploadService.uploadMasterData(masterData).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status)
        this.toaster.success(res.message, 'Success');
      this.getProductMasterData();
    },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error(err.message, 'Error')
      })
  }

  //================================================ Product Barcode Detail code here ============================//
  openAddProductBarcodeModel(): void {
    this.addNewProductBarcodeForm.reset();
    this.isaddNewProductBarcodeModeIVisible = true;
    this.barcodeFormMode = true;
  }

  addNewProductBarcodeDetail(): void {
    if(
      this.productBarcodeDetailDataSource.data.find((item: any) => {
        return item.ItemCode == this.addNewProductBarcodeForm.value.itemCode
      })
    ){
      this.toaster.warning('Warning!','Item Code Already Exist');
      return;
    }
    const key = {
      ItemCode: this.addNewProductBarcodeForm.value.itemCode,
      Barcode: this.addNewProductBarcodeForm.value.barcode,
      PlantId: this.productsForm.value.plant,
      TransactionId: 0
    };
    this.roleService.AddOrUpdateProductBarcodeDetail(key).subscribe(
      (res: any) => {
        if(!res.HasError){
          if(res.data.length){
            this.toaster.success('Success',res.data[0].Msg);
            this.isaddNewProductBarcodeModeIVisible = false;
            this.getProductBarcodeDetail();
          }
        }
        else{
          this.toaster.error('Error',res.data[0].Msg);
        }
      },
      (error: any) => {
        this.toaster.error(error.error)
        this.spinner.hide();
      }
    );
  }

  getProductBarcodeDetail(): void {
    this.spinner.show();
    const key = {
      GroupId: this.productsForm.value.group,
      PlantId: this.productsForm.value.plant
    };
    this.roleService.GetProductBarcodeDetail(key).subscribe(
      (res: any) => {
        if(!res.HasError){
          if(res.ProductBarcodeDetail.length){
            this.productBarcodeDetailDataSource = new MatTableDataSource<any>(res.ProductBarcodeDetail);
            this.getPaginator(this.productBarcodeDetailDataSource, 3);
          }
        }
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error(error.error)
        this.spinner.hide();
      }
    );
  }

  openEditProductBarcodeModel(element: any): void {
    this.barcodeFormMode = false;
    this.isaddNewProductBarcodeModeIVisible = true;
    this.productBarcodeDetailId = element.Id;
    this.addNewProductBarcodeForm.get('itemCode')?.setValue(element.ItemCode);
    this.addNewProductBarcodeForm.get('barcode')?.setValue(element.Barcode);
  }

  UpdateProductBarcodeDetail(): void {
    if(
      this.productBarcodeDetailDataSource.data.find((item: any) => {
        return item.ItemCode == this.addNewProductBarcodeForm.value.itemCode &&  this.productBarcodeDetailId != item.Id
      })
    ){
      this.toaster.warning('Warning!','Item Code Already Exist');
      return;
    }
    const key = {
      ItemCode: this.addNewProductBarcodeForm.value.itemCode,
      Barcode: this.addNewProductBarcodeForm.value.barcode,
      PlantId: this.productsForm.value.plant,
      ProductBarcodeDetailId: this.productBarcodeDetailId,
      TransactionId: 1
    };
    this.roleService.AddOrUpdateProductBarcodeDetail(key).subscribe(
      (res: any) => {
        if(!res.HasError){
          if(res.data.length){
            this.toaster.success('Success',res.data[0].Msg);
            this.isaddNewProductBarcodeModeIVisible = false;
            this.getProductBarcodeDetail();
          }
        }
        else{
          this.toaster.error('Error',res.data[0].Msg);
        }
      },
      (error: any) => {
        this.toaster.error(error.error)
        this.spinner.hide();
      }
    );
  }

  applyFilterForProductBarcode(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.productBarcodeDetailDataSource.filter = filterValue.trim().toLowerCase();
  }

  DeleteProductBarcodeDetail(element: any): void {
    const key = {
      ItemCode: element.ItemCode,
      Barcode: element.Barcode,
      PlantId: element.PlantId,
      ProductBarcodeDetailId: element.Id,
      TransactionId: 2
    };
    this.roleService.AddOrUpdateProductBarcodeDetail(key).subscribe(
      (res: any) => {
        if(!res.HasError){
          if(res.data.length){
            this.toaster.success('Success',res.data[0].Msg);
            this.getProductBarcodeDetail();
          }
        }
        else{
          this.toaster.error('Error',res.data[0].Msg);
        }
      },
      (error: any) => {
        this.toaster.error(error.error)
        this.spinner.hide();
      }
    );
  }
  //================================================ End ================================================//

}
