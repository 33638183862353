import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ManagerExecutionService } from '../../services/manager.service';
import { DatePipe } from '@angular/common';
import { OperatorExecutionService } from 'src/app/services/operator-execution.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { EditBtnRenderer } from 'src/app/edit-action-btn-renderer.component';
import { DeleteBtnRenderer } from 'src/app/delete-action-btn-renderer.component';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CILMasterService } from 'src/app/services/cil-master-service';
import { TechnicianService } from 'src/app/services/technician.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';
import { ActionEditDeleteViewBtnRenderer } from 'src/app/action-edit-delete-view-btn-renderer.component';
@Component({
  selector: 'app-manager-execution',
  templateUrl: './manager-execution.component.html',
  styleUrls: ['./manager-execution.component.scss'],
})
export class ManagerExecutionComponent implements OnInit {
  woStatus: any = [];
  opratorList: any = [];
  spinnerText: string = '';
  WOStatus: any = '';
  userList: any;
  userListReq: any;
  IsWrite: boolean = false;
  breadcrumList!: any;
  HeaderForm!: FormGroup;
  GroupList: any = [];
  PlantList: any = [];
  GroupTempList: any = [];
  PlantTempList: any = [];
  filterTechnicianList: any = [];
  filterFrequencyList: any = [];
  filterTaskTypeList: any = [];
  FreqDuration: any;
  ExcelTechnicianList: any = [];
  ExcelDepartmentList: any = [];

  ExportWOType: any = [];
  ExportTool: any = [];
  ExportFrequency: any = [];
  ExportTaskType: any = [];
  ExportTask: any = [];
  ExportTechnician: any = [];

  HeaderData: any = { Group: '', Plant: '' };
  technicianList: any = [];
  GroupPlantData: any;
  frmDate: any;
  tDate: any;

  dateFilter: any = { From: '', To: '' };
  frameworkComponents: any;
  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;

  gridOptions: any = {
    defaultColDef: {
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true, // <-- HERE
      autoHeight: true, // <-- HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
    },
    popupParent: document.querySelector('body'),
  };
  ExportSupervisorList: any = [];
  rowData1: any[];

  constructor(
    private fb: FormBuilder,
    private technicianService: TechnicianService,
    public router: Router,
    private cilMasterService: CILMasterService,
    private enterpriseGroupService: EnterPriseGroupService,
    public managerExecutionService: ManagerExecutionService,
    private operatorExecutionService: OperatorExecutionService,
    private spinner: NgxSpinnerService,
    private userManagementService: UserManagementService,
    public toaster: ToastrService,
    private commonService: CommonService
  ) {
    this.frameworkComponents = {
      editRenderer: EditBtnRenderer,
      deleteRenderer: DeleteBtnRenderer,
      actionEditDeleteViewBtnRenderer: ActionEditDeleteViewBtnRenderer,
    };
    this.columnDefs = [
      {
        headerName: 'Action',
        field: 'Action',
        cellRenderer: 'actionEditDeleteViewBtnRenderer',
        minWidth: 140,
        cellRendererParams: {
          onClickEdit: this.editRow.bind(this),
          onClickDelete: this.deleteRow.bind(this),
          onClickView: this.viewButtonClicked.bind(this),
        },
        
      },
      {
        headerName: 'WO Status',
        field: 'WOStatus',
        filter: 'agTextColumnFilter',
        minWidth: 120,
        cellStyle: function (params: any) {
          if (params.value == 'Complete' || params.value == 'Close') {
            return { color: 'white', backgroundColor: 'green' };
          } else if (params.value == 'In Progress') {
            return { color: 'black', backgroundColor: 'orange' };
          } else {
            params.value = params.value == 'ReOpen' ? 'ReOpened' : params.value;
            return { color: 'white', backgroundColor: 'red' };
          }
        },
        
      },
      {
        headerName: 'WO Id',
        field: 'displayWOId',
        minWidth: 80,
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'WO Detail',
        minWidth: 200,
        field: 'Remark',
        filter: 'agTextColumnFilter',
        wrapText: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Line/Area',
        field: 'LineId',
        filter: 'agTextColumnFilter',
        minWidth: 250,
        wrapText: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'WO Creation Stamp',
        field: 'TimeStamp',
        filter: 'agTextColumnFilter',
        minWidth: 180,
        cellRenderer: function (params: any) {
          let html = params && params.value;
          return (html = moment(html).format('DD/MM/YYYY hh:mm a'));
        },
        
      },
      {
        headerName: 'AssignTechnicianTimeStamp',
        field: 'managerExc.AssignTechnicianTimeStamp',
        filter: 'agTextColumnFilter',
        minWidth: 180,
        cellRenderer: function (params: any) {
          if (params) {
            let html = params && params.value;
            return (html = moment(html).format('DD/MM/YYYY hh:mm a'));
          } else {
            return '';
          }
        },
        
      },
      {
        headerName: 'WO Type',
        field: 'WOType.Name',
        filter: 'agTextColumnFilter',
        minWidth: 130,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Failure Mode',
        field: 'FailureMode.Name',
        filter: 'agTextColumnFilter',
        minWidth: 130,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Spare Parts',
        field: 'SpareParts',
        filter: 'agTextColumnFilter',
        minWidth: 120,
      },
      {
        headerName: 'Assigned Technician',
        field: 'assignedUserName',
        minWidth: 220,
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Technician Status',
        field: 'TechStatus',
        filter: 'agTextColumnFilter',
        minWidth: 220,
        cellStyle: function (params: any) {
          if (params.value == 'Completed') {
            return { color: 'white', backgroundColor: 'green' };
          } else if (params.value == 'InProgress') {
            return { color: 'black', backgroundColor: 'orange' };
          } else {
            return { color: 'white', backgroundColor: 'red' };
          }
        },
        
      },
      {
        headerName: 'Requested By',
        field: 'requestedUserName',
        minWidth: 140,
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Technician StartDate',
        field: 'techExc.StartTime',
        minWidth: 220,
        filter: 'agTextColumnFilter',
        valueFormatter: function (params: any) {
          if(params.value){
          return moment(params.value).format('DD/MM/YYYY hh:mm a');
        }else{
          return 
        }
        },
        
      },
      {
        headerName: 'Technician CompleteDate',
        field: 'techExc.CompleteTime',
        minWidth: 220,
        filter: 'agTextColumnFilter',
        valueFormatter: function (params: any) {
          if (params.value) {
            return moment(params.value).format('DD/MM/YYYY hh:mm a');
          } 
          else {
          }
        },
        
      },
    ];
  }

  ngOnInit(): void {
    this.woStatus = this.operatorExecutionService.getWoStatusList();
    this.HeaderForm = this.fb.group({
      group: new FormControl(null, [Validators.required]),
      plant: new FormControl(null, [Validators.required]),
      fromDate: new FormControl(null),
      toDate: new FormControl(null),
    });
    const stDT = new Date();
    stDT.setDate(stDT.getDate());
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate() + 1);
    this.dateFilter = {
      From: new Date(stDT),
      To: new Date(edDT),
    };
    this.frmDate = this.managerExecutionService.getFromDate();
    this.tDate = this.managerExecutionService.getToDate();
    if (this.frmDate) {
      this.dateFilter.From = this.frmDate;
    }
    if (this.tDate) {
      this.dateFilter.To = this.tDate;
    }
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroups();
    this.getFilterData();
    let managerHeaderData = this.managerExecutionService.getManagerHeaderData();
    if (managerHeaderData) {
      if (managerHeaderData.Group)
        this.HeaderData.Group = managerHeaderData.Group;
      if (managerHeaderData.Plant)
        this.HeaderData.Plant = managerHeaderData.Plant;
      if (managerHeaderData.FromDate)
        this.dateFilter.From = managerHeaderData.FromDate;
      if (managerHeaderData.ToDate)
        this.dateFilter.To = managerHeaderData.ToDate;
    }
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  getUserList() {
    this.spinnerText = 'Fetching User Data';
    this.spinner.show();
    const data = {
      EGId: this.HeaderData.Group,
      PlantId: this.HeaderData.Plant,
    };
    this.commonService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        if (res.employeeInfoList && res.employeeInfoList.length) {
          this.userListReq = res.employeeInfoList;
          this.userList = res.employeeInfoList.filter(
            (emp: any) =>
              emp.UserId && emp.Dept_Desc && emp.Dept_Desc == 'Maintenance'
          );
          if (
            this.technicianList &&
            this.technicianList.length &&
            this.userList &&
            this.userList.length
          ) {
            for (let index = 0; index < this.userList.length; index++) {
              const findUserIndex = this.technicianList.find(
                (emp: any) =>
                  this.userList[index].UserId == emp.UserId && emp.RMPM == 'RM'
              );
              if (!findUserIndex) {
                this.userList.splice(index, 1);
                index--;
              }
            }
          }
          this.getOperatorData();
        } else {
          this.userList = [];
          this.spinner.hide();
          // this.setUserName();
        }
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  setUserName() {
    if (
      this.userList &&
      this.userList.length &&
      this.opratorList &&
      this.opratorList.length
    ) {
      for (let operator of this.opratorList) {
        const reqUser = this.userListReq.find(
          (usr: any) => usr.UserId == operator.RequestedBy
        ); // this changes by Mr. Najam 15-03-2022
        const assignedUser = this.userList.find(
          (usr: any) => usr.UserId == operator.assignUser
        );
        operator.requestedUserName = reqUser
          ? reqUser.fullName
          : operator.RequestedBy === 100001
          ? 'Admin'
          : '';
        operator.assignedUserName = assignedUser
          ? assignedUser.fullName
          : 'Not Assigned Yet';
      }
    }
  }

  getFilterData() {
    if (!this.HeaderData.Group || !this.HeaderData.Plant) return;
    else {
      this.getOperatorData();
    }
  }

  getOperatorData() {
    this.spinner.show();
    const obj = {
      UserId: '',
      WOId: '',
      From: this.dateFilter.From,
      To: this.dateFilter.To,
      groupId: this.HeaderData.Group,
      plantId: this.HeaderData.Plant,
    };
    this.managerExecutionService.getOperatorData(obj).subscribe(
      (res: any) => {
        if (res.MEWo) {
          this.opratorList = res.MEWo;
         

          this.woStatus = this.operatorExecutionService.getWoStatusList();
          for (let i = 0; i < this.opratorList.length; i++) {
            this.WOStatus = this.woStatus.find(
              (element: any) => element.id == parseInt(res.MEWo[i].WOStatus)
            );
            this.opratorList[i].WOStatus =
              this.WOStatus && this.WOStatus.title ? this.WOStatus.title : '';
            this.opratorList[i].WOType = this.opratorList[i].WOType;
            this.opratorList[i].FailureMode = this.opratorList[i].FailureMode;
            this.opratorList[i].RequestedBy = this.opratorList[i].createdBy;
            this.opratorList[i].CreatedAt = this.opratorList[i].CreatedAt;
            this.opratorList[i].ClosedAt = this.opratorList[i].ClosedAt;

            this.opratorList[i].assignUser =
              this.opratorList[i].managerExc &&
              this.opratorList[i].managerExc.assignUser
                ? this.opratorList[i].managerExc.assignUser
                : '';
            this.opratorList[i].UploadPicture = res.MEWo[i].UploadPicture
              ? res.MEWo[i].UploadPicture
              : '';
            var line = res.MEWo[i].line ? res.MEWo[i].line?.plDesc : '';
            var unit = res.MEWo[i].units ? res.MEWo[i].units?.puDesc : '';
            var assem = res.MEWo[i].assembly
              ? res.MEWo[i].assembly?.assblyDesc
              : '';
            var subaseem = res.MEWo[i].subAssembly
              ? res.MEWo[i].subAssembly?.sAsblyDesc
              : '';
            var com = res.MEWo[i].component
              ? res.MEWo[i].component?.componentName
              : '';
            this.opratorList[i].LineId =
              line + '-' + unit + '-' + assem + '-' + subaseem + '-' + com;
            this.opratorList[i].displayWOId = res.MEWo[i].WOId;
            this.opratorList[i].WOId = res.MEWo[i].ID;
            this.opratorList[i].TimeStamp = this.opratorList[i].TimeStamp;

            if (this.opratorList[i].managerExc!=null) {
              this.opratorList[i].managerExc.AssignTechnicianTimeStamp = this
                .opratorList[i].managerExc.AssignTechnicianTimeStamp ?this
                .opratorList[i].managerExc.AssignTechnicianTimeStamp:'';
            } else {
              
            }

            if (this.opratorList[i].techExc) {
              this.opratorList[i].techExc.StartTime=this.opratorList[i].techExc.StartTime;
              this.opratorList[i].techExc.CompleteTime=this.opratorList[i].techExc.CompleteTime;
              if (
                this.opratorList[i].techExc.StartTime &&
                this.opratorList[i].techExc.CompleteTime
              ) {
                if (this.opratorList[i].WOStatus == 'ReOpen')
                  this.opratorList[i].TechStatus = 'InProgress';
                else this.opratorList[i].TechStatus = 'Completed';
              } else if (this.opratorList[i].techExc.CompleteTime)
                this.opratorList[i].TechStatus = 'Completed';
              else if (this.opratorList[i].techExc.StartTime)
                this.opratorList[i].TechStatus = 'InProgress';
              else this.opratorList[i].TechStatus = 'InProgress';
            } else this.opratorList[i].TechStatus = 'Not Started';
          } 
          this.rowData = this.opratorList; 
          this.rowData.map((row: any) => {
            if (row.WOStatus == 'Close')
             row.IsWrite = false;
            else 
            row.IsWrite = this.IsWrite;
            if(row.SpareParts == 1){
              row.SpareParts='Required'
            }
            else{
              row.SpareParts='Not Required'
            }
          });
          this.setUserName();
          this.spinner.hide();
        }
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  getGroups() {
    const key = {
      UserId: '',
    };
    const id = {
      value: 0,
    };
    this.cilMasterService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.dropDownList;
        this.GroupTempList = data.dropDownList;
        if (this.GroupTempList && this.GroupTempList.length) {
          if (this.HeaderData.Group) {
            const find = this.GroupTempList.find(
              (grp: any) => grp.id == this.HeaderData.Group
            );
            this.HeaderData.Group = find ? find.id : this.GroupList[0].id;
          } else this.HeaderForm.get('group')?.setValue(this.GroupList[0].id);
          this.getPlants(this.HeaderData.Group);
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(id: any): void {
    this.cilMasterService.getPlantsData(id).subscribe(
      (data: any) => {
        this.PlantList = data.dropDownList;
        this.PlantTempList = data.dropDownList;
        if (this.PlantTempList && this.PlantTempList.length) {
          if (this.HeaderData.Plant) {
            const find = this.PlantTempList.find(
              (pnt: any) => pnt.id == this.HeaderData.Plant
            );
            this.HeaderData.Plant = find ? find.id : this.PlantList[0].id;
          } else this.HeaderForm.get('plant')?.setValue(this.PlantList[0].id);
          this.getTechnicianList();
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onGroupKey(value: any) {
    this.GroupList = this.searchGroupKey(value.value);
  }

  searchGroupKey(value: string) {
    let filter = value;
    return this.GroupTempList.filter((grp: any) => {
      let lowerCase = grp.name.toLocaleLowerCase();
      return grp.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  onPlantKey(value: any) {
    this.PlantList = this.searchPlantKey(value.value);
  }

  searchPlantKey(value: string) {
    let filter = value;
    return this.PlantTempList.filter((grp: any) => {
      let lowerCase = grp.name.toLocaleLowerCase();
      return grp.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  getTechnicianList(): void {
    this.spinner.show();
    this.spinnerText = 'Fetching Technician Data';
    this.technicianService.getTechnicianList(this.HeaderData).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.technicianList = [];
        if (res.data && res.data.length) {
          for (let user of res.data) {
            const usr = this.technicianList.find(
              (usr: any) => usr.UserId == user.UserId && usr.RMPM == user.RMPM
            );
            if (!usr) this.technicianList.push(user);
          }
        }
        this.getUserList();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.error.message);
      }
    );
  }

  ExportTOExcel() {
    this.ExportSupervisorList = [];
    if (this.rowData && this.rowData.length) {
      for (const row of this.rowData) {
        const ExportData = {
          ['WO Status']: row && row.WOStatus ? row.WOStatus : '',
          ['WO ID']: row && row.WOId ? row.WOId : '',
          ['WO Detail']: row && row.Remark ? row.Remark : '',
          ['WO Creation Date']:
            row && row.TimeStamp
              ? moment(row.TimeStamp).format('DD/MM/YYYY hh:mm a')
              : '',
          ['WO Type']: row && row.WOType ? row.WOType.Name : '',
          ['Failure Mode']: row && row.FailureMode ? row.FailureMode.Name : '',
          ['Group']: row && row.Group.egDesc ? row.Group.egDesc : '',
          ['Line/Area']: row && row.LineId ? row.LineId : '',
          ['Spare Parts']: row && row.SpareParts == 'Required' ? 'Required' : 'Not Required',
          ['Spare Parts Code']:
            row && row.managerExc && row.managerExc.SparePartCode
              ? row.managerExc.SparePartCode
              : '',
          ['Priority']:
            row && row.managerExc && row.managerExc.Priority
              ? row.managerExc.Priority.Name
              : '',
          ['Supervisor Remarks']:
            row && row.managerExc && row.managerExc.ManagerRemarks
              ? row.managerExc.ManagerRemarks
              : '',
          ['Assigned Technician']:
            row && row.assignedUserName ? row.assignedUserName : '',
          ['Requested By']:
            row && row.requestedUserName ? row.requestedUserName : '',
          ['Technician Status']: row && row.TechStatus ? row.TechStatus : '',
          ['Technician StartDate']:row && row.techExc?.StartTime ?  moment(row.techExc?.StartTime).format('DD/MM/YYYY hh:mm a'):'',
          ['Technician CompleteDate']:row && row.techExc?.CompleteTime ?  moment(row.techExc?.CompleteTime).format('DD/MM/YYYY hh:mm a'):'',

        };
        this.ExportSupervisorList.push(ExportData);
      }
    } else {
      this.ExportSupervisorList = [
        {
          ['WO Status']: '',
          ['WO ID']: '',
          ['WO Detail']: '',
          ['WO Creation Date']: '',
          ['WO Type']: '',
          ['Failure Mode']: '',
          ['Group']: '',
          ['Line/Area']: '',
          ['Spare Parts']: '',
          ['Spare Parts Code']: '',
          ['Priority']: '',
          ['Supervisor Remarks']: '',
          ['Assigned Technician']: '',
          ['Requested By']: '',
          ['Technician Status']: '',
          ['Technician StartDate']:'',
          ['Technician CompleteDate']:'',

        },
      ];
    }
    const workBook = XLSX.utils.book_new();
    const WOSheet = XLSX.utils.json_to_sheet(this.ExportSupervisorList);
    XLSX.utils.book_append_sheet(workBook, WOSheet, 'Supervisor');
    XLSX.writeFile(workBook, `Supervisor List.xlsx`);
  }

  editRow(element: any) {
    this.managerExecutionService.setManagerData(element.rowData);
    this.managerExecutionService.setUserData(this.userList);
    const data = {
      Group: this.HeaderData.Group,
      Plant: this.HeaderData.Plant,
      FromDate: this.dateFilter.From,
      ToDate: this.dateFilter.To,
    };
    this.managerExecutionService.setManagerHeaderData(data);
    this.router.navigate(['/PM/manager-form']);
  }
  viewButtonClicked(element: any) {
    element.rowData.isviewButton = true;

    this.managerExecutionService.setManagerData(element.rowData);
    this.managerExecutionService.setUserData(this.userList);
    const data = {
      Group: this.HeaderData.Group,
      Plant: this.HeaderData.Plant,
      FromDate: this.dateFilter.From,
      ToDate: this.dateFilter.To,
    };
    this.managerExecutionService.setManagerHeaderData(data);
    this.router.navigate(['/PM/manager-form']);
  }
  deleteRow(i: number) {}

  onFilterChanged(event: any) {
    this.gridOptions.api.setQuickFilter(event.value);
  }

  /// AG Grid
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
