import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AtHeader } from 'src/app/models/analytical-toolsModels/atHeader';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FishboneService } from 'src/app/services/fishbone.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from 'src/app/analytical-tools/image-dialog/image-dialog.component';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fishbone',
  templateUrl: './fishbone.component.html',
  styleUrls: ['./fishbone.component.scss'],
})
export class FishboneComponent implements OnInit {
  @Input() _headerData: EventEmitter<any> = new EventEmitter();
  @Input() headerID: any;
  @Input() isAtNcr = false;
  @Input() _permission: any;
  @Output() _emitHeaderData: EventEmitter<any> = new EventEmitter();
  stageName = 'FishBone';
  mType: any = {
    machinery: [],
    material: [],
    man: [],
    method: [],
    motherNature: [],
    management: [],
  };
  mList: any = {
    machineryList: [],
    materialList: [],
    manList: [],
    methodList: [],
    motherNatureList: [],
    managementList: [],
  };
  machineryList: any = [];
  materialList: any = [];
  manList: any = [];
  methodList: any = [];
  motherNatureList: any = [];
  managementList: any = [];
  orgMachineryList: any = [];
  orgMaterialList: any = [];
  orgManList: any = [];
  orgMethodList: any = [];
  orgMotherNatureList: any = [];
  orgManagementList: any = [];
  stageId: number = 2;
  breadcrumList!: any;
  submitted = false;
  spinnerText: string = '';
  headerData: AtHeader = {
    ID: 0,
    Group: '',
    Plant: '',
    Dept: '',
    Team: '',
    TeamLeader: '',
    InitProbStatement: '',
    NoOfM: 6,
    stageId: 2,
    uniqueCode: '',
    CreatedAt: new Date(),
    CreatedBy: '',
  };
  refocusedProbStatment: string = '';
  selectedItems: any = [];
  user: any;
  fishBoneId: number = 0;
  rcaId: number = 0;
  levelId: number = 2;
  isReset: boolean = false;

  @ViewChild('MachineryToggle') MachineryToggle: any;
  @ViewChild('MaterialToggle') MaterialToggle: any;
  @ViewChild('ManToggle') ManToggle: any;
  @ViewChild('MethodToggle') MethodToggle: any;
  @ViewChild('MotherNatureToggle') MotherNatureToggle: any;
  @ViewChild('ManagementToggle') ManagementToggle: any;
  @Input() onSubmit: Observable<any>;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Input() fishBone_ = new EventEmitter();

  MachineryChecked: boolean = false;
  MaterialChecked: boolean = false;
  ManChecked: boolean = false;
  MethodChecked: boolean = false;
  MotherNatureChecked: boolean = false;
  ManagementChecked: boolean = false;
  updateStatus: boolean = false;
  permisson!: any;
  IsWrite: boolean = false;
  IsAccess!: any;

  CheckedCount: number = 0;

  @ViewChild('fileInput') fileInput!: ElementRef;
  imageFile: any;
  imageSrc: any;
  // ImageUrl: any;
  blobStorageUrl: any;
  UploadFile: any;
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;

  isCauseMType1: boolean = false;
  isCauseMType2: boolean = false;
  isCauseMType3: boolean = false;
  isCauseMType4: boolean = false;
  isCauseMType5: boolean = false;
  isCauseMType6: boolean = false;

  causeEffectData: any = [];
  isStageId: any;
  FishBoneMType: any = [];
  PlantCheck: boolean = true;
  FishBoneData: any = [];
  sub: Subscription;

  constructor(
    private analyticalToolsService: AnalyticalToolsService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router,
    private fishboneService: FishboneService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.permisson = this._permission;
    this.IsWrite = this.permisson.IsWrite;
    // this.ImageUrl = environment.O3CoreApiImageUrl;
    this.blobStorageUrl = environment.O3CoreApiImageUrl;
    this.headerData.ID = this.headerID;
    this.rcaId = this.analyticalToolsService.getRcaId();
    this.getFishBone(this.headerData.ID, '');
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.headerData.CreatedBy = this.user.UserId;
    const userGroup = this.analyticalToolsService.getUserGroup();
    if (userGroup) this.headerData.Group = userGroup;

    this.sub = this.fishBone_.subscribe((event: any) => {
      this.saveFishBone(event);
    });
  }

  MToggle(e: any, type: any) {
    if (type == 'Machinery') {
      if (this.MachineryToggle.checked) this.MachineryChecked = true;
      else {
        if (this.updateStatus && this.isCauseMType1) {
          setTimeout(() => {
            this.MachineryChecked = true;
            this.MachineryToggle.checked = true;
            this.toaster.warning('Warning', `You Can't Turn Off This Toggle`);
            return;
          }, 250);
        } else {
          this.MachineryChecked = false;
          this.mType.machinery = [];
          this.mList.machineryList = this.mList.machineryList.filter(
            (machine: any) => machine.ID
          );
        }
      }
    } else if (type == 'Material') {
      if (this.MaterialToggle.checked) this.MaterialChecked = true;
      else {
        if (this.updateStatus && this.isCauseMType2) {
          setTimeout(() => {
            this.MaterialChecked = true;
            this.MaterialToggle.checked = true;
            this.toaster.warning('Warning', `You Can't Turn Off This Toggle`);
            return;
          }, 250);
        } else {
          this.MaterialChecked = false;
          this.mType.material = [];
          this.mList.materialList = this.mList.materialList.filter(
            (material: any) => material.ID
          );
        }
      }
    } else if (type == 'Man') {
      if (this.ManToggle.checked) this.ManChecked = true;
      else {
        if (this.updateStatus && this.isCauseMType3) {
          setTimeout(() => {
            this.ManChecked = true;
            this.ManToggle.checked = true;
            this.toaster.warning('Warning', `You Can't Turn Off This Toggle`);
            return;
          }, 250);
        } else {
          this.ManChecked = false;
          this.mType.man = [];
          this.mList.manList = this.mList.manList.filter((man: any) => man.ID);
        }
      }
    } else if (type == 'Method') {
      if (this.MethodToggle.checked) this.MethodChecked = true;
      else {
        if (this.updateStatus && this.isCauseMType4) {
          setTimeout(() => {
            this.MethodChecked = true;
            this.MethodToggle.checked = true;
            this.toaster.warning('Warning', `You Can't Turn Off This Toggle`);
            return;
          }, 250);
        } else {
          this.MethodChecked = false;
          this.mType.method = [];
          this.mList.methodList = this.mList.methodList.filter(
            (method: any) => method.ID
          );
        }
      }
    } else if (type == 'MotherNature') {
      if (this.MotherNatureToggle.checked) this.MotherNatureChecked = true;
      else {
        if (this.updateStatus && this.isCauseMType5) {
          setTimeout(() => {
            this.MotherNatureChecked = true;
            this.MotherNatureToggle.checked = true;
            this.toaster.warning('Warning', `You Can't Turn Off This Toggle`);
            return;
          }, 250);
        } else {
          this.MotherNatureChecked = false;
          this.mType.motherNature = [];
          this.mList.motherNatureList = this.mList.motherNatureList.filter(
            (motherNature: any) => motherNature.ID
          );
        }
      }
    } else if (type == 'Management') {
      if (this.ManagementToggle.checked) this.ManagementChecked = true;
      else {
        if (this.updateStatus && this.isCauseMType6) {
          setTimeout(() => {
            this.ManagementChecked = true;
            this.ManagementToggle.checked = true;
            this.toaster.warning('Warning', `You Can't Turn Off This Toggle`);
            return;
          }, 250);
        } else {
          this.ManagementChecked = false;
          this.mType.management = [];
          this.mList.managementList = this.mList.managementList.filter(
            (management: any) => management.ID
          );
        }
      }
    }
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  getFishBone(headerId: number, reset: any) {
    this.spinnerText = 'Fetching Dependencies Data';
    this.spinner.show();
    this.fishboneService.getFishBoneData(headerId).subscribe(
      (res: any) => {
        const url = this.router.url;

        if (
          !url.includes('/at/at-stages') &&
          !url.includes('/at/NcrManagement')
        ) {
          this._emitHeaderData.emit(res?.fishbone);
        }
        this.spinner.hide();
        this.FishBoneData = res.fishbone;
        this.FishBoneMType = res.mType;
        this.resetList(this.FishBoneMType);
        if (reset == 'reset' && !headerId) this.resetData();
        if (res && res.fishbone) {
          this.analyticalToolsService.setTeamLeaderList(res.fishbone.Team);
          this.rcaId = res.fishbone.rcaId;
          this.isStageId = res.fishbone.stageId ? res.fishbone.stageId : 0;
          this.levelId =
            res && res.fishbone && res.fishbone.stageId
              ? res.fishbone.stageId
              : this.stageId;
          this.updateStatus =
            res && res.fishbone && res.fishbone.fishBone ? true : false;
          delete res.fishbone.rcaId;
          if (res.fishbone)
            res.fishbone.ProblemStatement =
              res &&
              res.fishbone &&
              res.fishbone.fishBone &&
              res.fishbone.fishBone.ProblemStatement
                ? res.fishbone.fishBone.ProblemStatement
                : '';
          this.headerData = res.fishbone;
          this.analyticalToolsService.setHeaderId(this.headerData.ID);
          this.analyticalToolsService.setRcaId(this.rcaId);
          this.analyticalToolsService.setLevelId(this.levelId);
          this.analyticalToolsService.setStatus(this.updateStatus);
          this.refocusedProbStatment = res.fishbone.refocusedProbStatment
            ? res.fishbone.refocusedProbStatment
            : res.fishbone.fishBone.ProblemStatement;
          if (res.fishbone.fishBone) {
            this.fishBoneId = res.fishbone.fishBone.ID;
            this.mType.machinery = res.fishbone.fishBone.Machinery
              ? res.fishbone.fishBone.Machinery.split(',').map(
                  (num: any) => (num = parseInt(num))
                )
              : [];
            this.mType.material = res.fishbone.fishBone.Material
              ? res.fishbone.fishBone.Material.split(',').map(
                  (num: any) => (num = parseInt(num))
                )
              : [];
            this.mType.man = res.fishbone.fishBone.Man
              ? res.fishbone.fishBone.Man.split(',').map(
                  (num: any) => (num = parseInt(num))
                )
              : [];
            this.mType.method = res.fishbone.fishBone.Method
              ? res.fishbone.fishBone.Method.split(',').map(
                  (num: any) => (num = parseInt(num))
                )
              : [];
            this.mType.motherNature = res.fishbone.fishBone.MotherNature
              ? res.fishbone.fishBone.MotherNature.split(',').map(
                  (num: any) => (num = parseInt(num))
                )
              : [];
            this.mType.management = res.fishbone.fishBone.Management
              ? res.fishbone.fishBone.Management.split(',').map(
                  (num: any) => (num = parseInt(num))
                )
              : [];

            this.MachineryChecked = res.fishbone.fishBone.Machinery
              ? true
              : false;
            this.MaterialChecked = res.fishbone.fishBone.Material
              ? true
              : false;
            this.ManChecked = res.fishbone.fishBone.Man ? true : false;
            this.MethodChecked = res.fishbone.fishBone.Method ? true : false;
            this.MotherNatureChecked = res.fishbone.fishBone.MotherNature
              ? true
              : false;
            this.ManagementChecked = res.fishbone.fishBone.Management
              ? true
              : false;
          }

          if (
            res &&
            res.fishbone &&
            res.fishbone.causeAndEffects &&
            res.fishbone.causeAndEffects.length
          ) {
            for (const ce of res.fishbone.causeAndEffects) {
              if (ce.MType.MType == 1) this.isCauseMType1 = true;
              else if (ce.MType.MType == 2) this.isCauseMType2 = true;
              else if (ce.MType.MType == 3) this.isCauseMType3 = true;
              else if (ce.MType.MType == 4) this.isCauseMType4 = true;
              else if (ce.MType.MType == 5) this.isCauseMType5 = true;
              else if (ce.MType.MType == 6) this.isCauseMType6 = true;
            }
            this.causeEffectData = res.fishbone.causeAndEffects;
          }
        }
        if (res && res.fishbone && res.fishbone.fishBone) {
          this.setListValues();
          this.UploadFile = res.fishbone?.fishBone?.UploadFile;
        }
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  resetList(mtype: any) {
    this.headerData = {
      ID: 0,
      Group: this.headerData.Group ? this.headerData.Group : '',
      Plant: this.headerData.Plant ? this.headerData.Plant : '',
      Dept: this.headerData.Dept ? this.headerData.Dept : '',
      Team: '',
      TeamLeader: '',
      InitProbStatement: '',
      NoOfM: 6,
      stageId: 2,
      uniqueCode: '',
      CreatedAt: new Date(),
      CreatedBy: this.user.UserId,
    };
    this.refocusedProbStatment = '';
    this.mList = {
      machineryList: [],
      materialList: [],
      manList: [],
      methodList: [],
      motherNatureList: [],
      managementList: [],
    };
    this.mType = {
      machinery: [],
      material: [],
      man: [],
      method: [],
      motherNature: [],
      management: [],
    };
    this.machineryList = [];
    this.materialList = [];
    this.manList = [];
    this.methodList = [];
    this.motherNatureList = [];
    this.managementList = [];

    const plant =
      this.headerData && this.headerData.Plant
        ? this.headerData.Plant
        : this.FishBoneData && this.FishBoneData.Plant
        ? this.FishBoneData.Plant
        : '';

    this.orgMachineryList,
      this.orgMaterialList,
      this.orgManList,
      this.motherNatureList,
      this.orgMethodList,
      (this.orgManagementList = []);

    this.mList.machineryList = mtype.filter(
      (m: any) => m.MType == 1 && !m.Plant
    );
    let machineListByPlant = mtype.filter(
      (m: any) => m.MType == 1 && m.Plant && m.Plant == plant
    );
    this.mList.machineryList =
      this.mList.machineryList.concat(machineListByPlant);
    this.orgMachineryList = [...this.mList.machineryList];
    this.orgMachineryList.map((machinery: any) =>
      this.machineryList.push([...this.mList.machineryList])
    );

    this.mList.materialList = mtype.filter(
      (m: any) => m.MType == 2 && !m.Plant
    );
    let materialListByPlant = mtype.filter(
      (m: any) => m.MType == 2 && m.Plant && m.Plant == plant
    );
    this.mList.materialList =
      this.mList.materialList.concat(materialListByPlant);
    this.orgMaterialList = [...this.mList.materialList];
    this.orgMaterialList.map((material: any) =>
      this.materialList.push([...this.mList.materialList])
    );

    this.mList.manList = mtype.filter((m: any) => m.MType == 3 && !m.Plant);
    let manListByPlant = mtype.filter(
      (m: any) => m.MType == 3 && m.Plant && m.Plant == plant
    );
    this.mList.manList = this.mList.manList.concat(manListByPlant);
    this.orgManList = [...this.mList.manList];
    this.orgManList.map((man: any) =>
      this.manList.push([...this.mList.manList])
    );

    this.mList.methodList = mtype.filter((m: any) => m.MType == 4 && !m.Plant);
    let methodListByPlant = mtype.filter(
      (m: any) => m.MType == 4 && m.Plant && m.Plant == plant
    );
    this.mList.methodList = this.mList.methodList.concat(methodListByPlant);
    this.orgMethodList = [...this.mList.methodList];
    this.orgMethodList.map((man: any) =>
      this.methodList.push([...this.mList.methodList])
    );

    this.mList.motherNatureList = mtype.filter(
      (m: any) => m.MType == 5 && !m.Plant
    );
    let motherListByPlant = mtype.filter(
      (m: any) => m.MType == 5 && m.Plant && m.Plant == plant
    );
    this.mList.motherNatureList =
      this.mList.motherNatureList.concat(motherListByPlant);
    this.orgMotherNatureList = [...this.mList.motherNatureList];
    this.orgMotherNatureList.map((motherNature: any) =>
      this.motherNatureList.push([...this.mList.motherNatureList])
    );

    this.mList.managementList = mtype.filter(
      (m: any) => m.MType == 6 && !m.Plant
    );
    let managementListByPlant = mtype.filter(
      (m: any) => m.MType == 6 && m.Plant && m.Plant == plant
    );
    this.mList.managementList = this.mList.managementList.concat(
      managementListByPlant
    );
    this.orgManagementList = [...this.mList.managementList];
    this.orgManagementList.map((management: any) =>
      this.managementList.push([...this.mList.managementList])
    );

    // this.analyticalToolsService.setHeaderId(this.headerData.ID);
  }

  resetData() {
    this.headerData = {
      ID: 0,
      Group: '',
      Plant: '',
      Dept: '',
      Team: '',
      TeamLeader: '',
      InitProbStatement: '',
      NoOfM: 6,
      stageId: 2,
      uniqueCode: '',
      CreatedAt: new Date(),
      CreatedBy: this.user.UserId,
    };
    this.isReset = true;

    this.MachineryChecked = false;
    this.MaterialChecked = false;
    this.ManChecked = false;
    this.MethodChecked = false;
    this.MotherNatureChecked = false;
    this.ManagementChecked = false;

    this.analyticalToolsService.setRca(false);
    this.analyticalToolsService.setRcaId(0);
    this.analyticalToolsService.setHeaderId(0);
    this.analyticalToolsService.setFilteredIndex(0);
    this.analyticalToolsService.setFilteredData([]);
    this.analyticalToolsService.setTeamLeaderList([]);
    this.getFishBone(0, '');
  }

  setListValues() {
    let index = 0;
    let filteredList = [];
    for (let machinery of this.machineryList) {
      filteredList = machinery.filter(
        (mac: any) => !this.mType.machinery.includes(mac.ID)
      );
      const currentMachinery = machinery.find(
        (mac: any) => this.mType.machinery[index] == mac.ID
      );
      currentMachinery && filteredList.push(currentMachinery);
      this.machineryList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
    for (let material of this.materialList) {
      filteredList = material.filter(
        (mac: any) => !this.mType.material.includes(mac.ID)
      );
      const currentMaterial = material.find(
        (mac: any) => this.mType.material[index] == mac.ID
      );
      currentMaterial && filteredList.push(currentMaterial);
      this.materialList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
    for (let man of this.manList) {
      filteredList = man.filter((mac: any) => !this.mType.man.includes(mac.ID));
      const currentMan = man.find(
        (mac: any) => this.mType.man[index] == mac.ID
      );
      currentMan && filteredList.push(currentMan);
      this.manList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
    for (let method of this.methodList) {
      filteredList = method.filter(
        (mac: any) => !this.mType.method.includes(mac.ID)
      );
      const currentMethod = method.find(
        (mac: any) => this.mType.method[index] == mac.ID
      );
      currentMethod && filteredList.push(currentMethod);
      this.methodList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
    for (let motherNature of this.motherNatureList) {
      filteredList = motherNature.filter(
        (mac: any) => !this.mType.motherNature.includes(mac.ID)
      );
      const currentMotherNature = motherNature.find(
        (mac: any) => this.mType.motherNature[index] == mac.ID
      );
      currentMotherNature && filteredList.push(currentMotherNature);
      this.motherNatureList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
    for (let management of this.managementList) {
      filteredList = management.filter(
        (mac: any) => !this.mType.management.includes(mac.ID)
      );
      const currentManagement = management.find(
        (mac: any) => this.mType.management[index] == mac.ID
      );
      currentManagement && filteredList.push(currentManagement);
      this.managementList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
  }

  setSearchData(event: any) {
    if (event && event.fishBone && event.fishBone.ID) {
      this.headerData = event;

      if (event.stageId == 1) {
        if (
          (event.Status6W2H && event.Status6W2H == 'InProgress') ||
          !event.Status6W2H ||
          event.Status6W2H == 'Open'
        ) {
          this.router.navigate(['/at/sixwtwoh']);
        } else if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
          this.getFishBone(event.ID, '');
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/sixwtwoh']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 6W2h');
        }
      } else if (event.stageId == 2) {
        if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
          this.getFishBone(event.ID, '');
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fishbone']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Fishbone'
          );
        }
      } else if (event.stageId == 3) {
        if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/causeEffect']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Cause & Effect'
          );
        }
      } else if (event.stageId == 4) {
        if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fivewhy']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 5Why');
        }
      }
    } else {
      if (event.stageId == 1) {
        if (
          (event.Status6W2H && event.Status6W2H == 'InProgress') ||
          !event.Status6W2H ||
          event.Status6W2H == 'Open'
        ) {
          this.router.navigate(['/at/sixwtwoh']);
        } else if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
          this.getFishBone(event.ID, '');
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/sixwtwoh']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 6W2h');
        }
      } else if (event.stageId == 2) {
        if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
          this.getFishBone(event.ID, '');
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fishbone']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Fishbone'
          );
        }
      } else if (event.stageId == 3) {
        if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/causeEffect']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Cause & Effect'
          );
        }
      } else if (event.stageId == 4) {
        if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fivewhy']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 5Why');
        }
      }
      // this.toaster.info('Info', 'Fishbone Data Not Found For This Header ID');
    }
  }

  saveFishBone(e: any) {
    let event = e.headerData;
    let integrationData = e.integrationData;
    delete event?.stageID;
    delete event?.FyActionFileCount;
    delete event?.CauseFileCount;

    this.CheckedCount = 0;
    if (this.MachineryChecked) this.CheckedCount++;
    else this.mType.machinery = [];

    if (this.MaterialChecked) this.CheckedCount++;
    else this.mType.material = [];

    if (this.ManChecked) this.CheckedCount++;
    else this.mType.man = [];

    if (this.MethodChecked) this.CheckedCount++;
    else this.mType.method = [];

    if (this.MotherNatureChecked) this.CheckedCount++;
    else this.mType.motherNature = [];

    if (this.ManagementChecked) this.CheckedCount++;
    else this.mType.management = [];

    if (this.MachineryChecked && !this.mType.machinery.length) {
      this.toaster.warning(
        'Warning',
        'Please Enter Machine Data OR Turn Off That Toggle'
      );
      return;
    } else if (this.MaterialChecked && !this.mType.material.length) {
      this.toaster.warning(
        'Warning',
        'Please Enter Material Data OR Turn Off That Toggle'
      );
      return;
    } else if (this.ManChecked && !this.mType.man.length) {
      this.toaster.warning(
        'Warning',
        'Please Enter Man Data OR Turn Off That Toggle'
      );
      return;
    } else if (this.MethodChecked && !this.mType.method.length) {
      this.toaster.warning(
        'Warning',
        'Please Enter Method Data OR Turn Off That Toggle'
      );
      return;
    } else if (this.MotherNatureChecked && !this.mType.motherNature.length) {
      this.toaster.warning(
        'Warning',
        'Please Enter MotherNature Data OR Turn Off That Toggle'
      );
      return;
    } else if (this.ManagementChecked && !this.mType.management.length) {
      this.toaster.warning(
        'Warning',
        'Please Enter Management Data OR Turn Off That Toggle'
      );
      return;
    } else {
      let data: any = {
        fishBoneId: this.fishBoneId,
        ProblemStatement: this.refocusedProbStatment,
        createdAt: new Date(),
        createdBy: this.headerData.CreatedBy,
        header: event,
        Machinery: [],
        newMachinery: [],
        Material: [],
        newMaterial: [],
        Man: [],
        newMan: [],
        Method: [],
        newMethod: [],
        MotherNature: [],
        newMotherNature: [],
        Management: [],
        newManagement: [],
        isRefocusedProblemImage: this.imageFile && this.imageSrc ? true : false,
      };
      for (let machinery of this.mType.machinery) {
        if (machinery) {
          if (isNaN(machinery)) {
            const obj = {
              MType: 1,
              Name: machinery,
              CreatedAt: new Date(),
              CreatedBy: this.user.UserId,
            };
            data.newMachinery.push(obj);
          } else data.Machinery.push(machinery);
        }
      }
      for (let material of this.mType.material) {
        if (material) {
          if (isNaN(material)) {
            const obj = {
              MType: 2,
              Name: material,
              CreatedAt: new Date(),
              CreatedBy: this.user.UserId,
            };
            data.newMaterial.push(obj);
          } else data.Material.push(material);
        }
      }
      for (let man of this.mType.man) {
        if (man) {
          if (isNaN(man)) {
            const obj = {
              MType: 3,
              Name: man,
              CreatedAt: new Date(),
              CreatedBy: this.user.UserId,
            };
            data.newMan.push(obj);
          } else data.Man.push(man);
        }
      }
      for (let method of this.mType.method) {
        if (method) {
          if (isNaN(method)) {
            const obj = {
              MType: 4,
              Name: method,
              CreatedAt: new Date(),
              CreatedBy: this.user.UserId,
            };
            data.newMethod.push(obj);
          } else data.Method.push(method);
        }
      }
      for (let motherNature of this.mType.motherNature) {
        if (motherNature) {
          if (isNaN(motherNature)) {
            const obj = {
              MType: 5,
              Name: motherNature,
              CreatedAt: new Date(),
              CreatedBy: this.user.UserId,
            };
            data.newMotherNature.push(obj);
          } else data.MotherNature.push(motherNature);
        }
      }
      for (let management of this.mType.management) {
        if (management) {
          if (isNaN(management)) {
            const obj = {
              MType: 6,
              Name: management,
              CreatedAt: new Date(),
              CreatedBy: this.user.UserId,
            };
            data.newManagement.push(obj);
          } else data.Management.push(management);
        }
      }
      if (this.CheckedCount == 0) data.header.StatusFishbone = 'Open';
      else if (this.CheckedCount < 4) data.header.StatusFishbone = 'InProgress';
      else data.header.StatusFishbone = 'Completed';

      delete data.header.uniqueCode;
      delete data.causeAndEffects;
      delete data.header.causeAndEffects;
      // data.header.StatusFishbone = this.CheckedCount < 4 ? 'InProgress' : 'Completed';
      this.spinnerText = this.headerData.ID
        ? 'Updating FishBone Data'
        : 'Creating FishBone Data';
      this.spinner.show();
      data.header.stageId = this.levelId;
      const formData = new FormData();

      if (!this.imageFile && this.UploadFile) {
        data.UploadFile = this.UploadFile;
      }
      formData.append('data', JSON.stringify(data));
      if (event.UploadFile) {
        formData.append('UploadFile', event.UploadFile);
      }

      if (this.imageFile && this.imageSrc) {
        formData.append('UploadFile', this.imageFile);
      }

      let route = this.router.url;
      if (!route.includes('at/at-stages')) {
        formData.append('integrationData', JSON.stringify(integrationData));
      }

      if (this.headerData.ID && this.fishBoneId) {
        this.fishboneService.updateFishBoneData(formData).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.toaster.success('Success', res.message);
            this.analyticalToolsService.setRcaId(res.data.rcaId);
            this._emitHeaderData.emit(res.data);

            this.headerData.ID = res.data.headerId;
            if (res.data.rcaId) {
              this.analyticalToolsService.setHeaderId(res.data.headerId);
              // this.router.navigate(['/at/causeEffect']);
            } else {
              this.analyticalToolsService.setHeaderId(res.data.headerId);
              this.analyticalToolsService.setStatus(this.updateStatus);
              // this.resetList(res.data);
              // this.isReset = true;
            }
            this.onSuccess.emit('fishBone');

            this.getFishBone(this.headerData.ID, '');
          },
          (err: any) => {
            this.spinner.hide();
          }
        );
      } else {
        this.fishboneService.addFishBoneData(formData).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.toaster.success('Success', res.message);
            this.analyticalToolsService.setHeaderId(res.data.headerId);
            this.headerData.ID = res.data.headerId;
            this.analyticalToolsService.setRcaId(res.data.rcaId);
            if (res.data.rcaId) {
              this.analyticalToolsService.setHeaderId(res.data.headerId);
              this._emitHeaderData.emit(res.data);

              // this.router.navigate(['/at/causeEffect']);
            } else {
              this.analyticalToolsService.setHeaderId(res.data.headerId);

              this.updateStatus = true;
              this.analyticalToolsService.setStatus(this.updateStatus);
            }
            this.onSuccess.emit('fishBone');

            this.getFishBone(this.headerData.ID, '');
          },
          (err: any) => {
            this.spinner.hide();
          }
        );
      }
    }
  }

  updateMTypeList(type: number, index: number) {
    let tempArr: any = [];
    let existingSelected: any = [];
    if (type == 1) {
      tempArr = this.machineryList[index].filter(
        (machinery: any) => machinery.ID != this.mType.machinery[index]
      );
      if (tempArr.length)
        this.machineryList.map((machinery: any, ind: number) => {
          if (index != ind) {
            const existingSelectedMachinery = this.mType.machinery.filter(
              (mat: any) =>
                this.machineryList[ind].find((m: any) => m.ID == mat) &&
                mat != this.mType.machinery[index]
            );
            this.machineryList[ind] = [...tempArr];
            for (const mate of existingSelectedMachinery) {
              if (!existingSelected.find((ins: any) => ins == mate)) {
                this.machineryList[ind].push(
                  this.mList.machineryList.find((mat: any) => mat.ID == mate)
                );
                existingSelected.push(mate);
              }
            }
          }
        });
    } else if (type == 2) {
      tempArr = this.materialList[index].filter(
        (material: any) => material.ID != this.mType.material[index]
      );
      if (tempArr.length)
        this.materialList.map((material: any, ind: number) => {
          if (index != ind) {
            const existingSelectedMaterial = this.mType.material.filter(
              (mat: any) =>
                this.materialList[ind].find((m: any) => m.ID == mat) &&
                mat != this.mType.material[index]
            );
            this.materialList[ind] = [...tempArr];
            for (const mate of existingSelectedMaterial) {
              if (!existingSelected.find((ins: any) => ins == mate)) {
                this.materialList[ind].push(
                  this.mList.materialList.find((mat: any) => mat.ID == mate)
                );
                existingSelected.push(mate);
              }
            }
          }
        });
    } else if (type == 3) {
      tempArr = this.manList[index].filter(
        (man: any) => man.ID != this.mType.man[index]
      );
      if (tempArr.length)
        this.manList.map((man: any, ind: number) => {
          if (index != ind) {
            const existingSelectedMan = this.mType.man.filter(
              (mat: any) =>
                this.manList[ind].find((m: any) => m.ID == mat) &&
                mat != this.mType.man[index]
            );
            this.manList[ind] = [...tempArr];
            for (const mate of existingSelectedMan) {
              if (!existingSelected.find((ins: any) => ins == mate)) {
                this.manList[ind].push(
                  this.mList.manList.find((mat: any) => mat.ID == mate)
                );
                existingSelected.push(mate);
              }
            }
          }
        });
    } else if (type == 4) {
      tempArr = this.methodList[index].filter(
        (method: any) => method.ID != this.mType.method[index]
      );
      if (tempArr.length)
        this.methodList.map((method: any, ind: number) => {
          if (index != ind) {
            const existingSelectedMethod = this.mType.method.filter(
              (mat: any) =>
                this.methodList[ind].find((m: any) => m.ID == mat) &&
                mat != this.mType.method[index]
            );
            this.methodList[ind] = [...tempArr];
            for (const mate of existingSelectedMethod) {
              if (!existingSelected.find((ins: any) => ins == mate)) {
                this.methodList[ind].push(
                  this.mList.methodList.find((mat: any) => mat.ID == mate)
                );
                existingSelected.push(mate);
              }
            }
          }
        });
    } else if (type == 5) {
      tempArr = this.motherNatureList[index].filter(
        (motherNature: any) => motherNature.ID != this.mType.motherNature[index]
      );
      if (tempArr.length)
        this.motherNatureList.map((motherNature: any, ind: number) => {
          if (index != ind) {
            const existingSelectedMotherNature = this.mType.motherNature.filter(
              (mat: any) =>
                this.motherNatureList[ind].find((m: any) => m.ID == mat) &&
                mat != this.mType.motherNature[index]
            );
            this.motherNatureList[ind] = [...tempArr];
            for (const mate of existingSelectedMotherNature) {
              if (!existingSelected.find((ins: any) => ins == mate)) {
                this.motherNatureList[ind].push(
                  this.mList.motherNatureList.find((mat: any) => mat.ID == mate)
                );
                existingSelected.push(mate);
              }
            }
          }
        });
    } else {
      tempArr = this.managementList[index].filter(
        (management: any) => management.ID != this.mType.management[index]
      );
      if (tempArr.length)
        this.managementList.map((management: any, ind: number) => {
          if (index != ind) {
            const existingSelectedManagement = this.mType.management.filter(
              (mat: any) =>
                this.managementList[ind].find((m: any) => m.ID == mat) &&
                mat != this.mType.management[index]
            );
            this.managementList[ind] = [...tempArr];
            for (const mate of existingSelectedManagement) {
              if (!existingSelected.find((ins: any) => ins == mate)) {
                this.managementList[ind].push(
                  this.mList.managementList.find((mat: any) => mat.ID == mate)
                );
                existingSelected.push(mate);
              }
            }
          }
        });
    }
  }

  searchValues(filter: any, type: number, index: number) {
    filter = filter.target.value;
    if (type == 1)
      this.machineryList[index] = this.orgMachineryList.filter(
        (machinery: any) => {
          let lowerCase = machinery.Name.toLocaleLowerCase();
          return (
            machinery.Name.startsWith(filter) || lowerCase.startsWith(filter)
          );
        }
      );
    else if (type == 2)
      this.materialList[index] = this.orgMaterialList.filter(
        (material: any) => {
          let lowerCase = material.Name.toLocaleLowerCase();
          return (
            material.Name.startsWith(filter) || lowerCase.startsWith(filter)
          );
        }
      );
    else if (type == 3)
      this.manList[index] = this.orgManList.filter((man: any) => {
        let lowerCase = man.Name.toLocaleLowerCase();
        return man.Name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    else if (type == 4)
      this.methodList[index] = this.orgMethodList.filter((method: any) => {
        let lowerCase = method.Name.toLocaleLowerCase();
        return method.Name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    else if (type == 5)
      this.motherNatureList[index] = this.orgMotherNatureList.filter(
        (motherNature: any) => {
          let lowerCase = motherNature.Name.toLocaleLowerCase();
          return (
            motherNature.Name.startsWith(filter) || lowerCase.startsWith(filter)
          );
        }
      );
    else
      this.managementList[index] = this.orgManagementList.filter(
        (management: any) => {
          let lowerCase = management.Name.toLocaleLowerCase();
          return (
            management.Name.startsWith(filter) || lowerCase.startsWith(filter)
          );
        }
      );
  }

  addRow(type: number) {
    if (type == 1) {
      // this.mList.machineryList.push({ MType: type, Name: '' });
      this.mList.machineryList.unshift({ MType: type, Name: '' });
      this.mType.machinery.unshift('');
      this.machineryList.unshift([{ MType: type, Name: '' }]);
      const filtered = this.mList.machineryList.filter(
        (item: any) => !this.mType.machinery.includes(item.ID)
      );
      this.machineryList.push([...filtered]);
    } else if (type == 2) {
      // this.mList.materialList.push({ MType: type, Name: '' });
      this.mList.materialList.unshift({ MType: type, Name: '' });
      this.mType.material.unshift('');
      this.materialList.unshift([{ MType: type, Name: '' }]);
      const filtered = this.mList.materialList.filter(
        (item: any) => !this.mType.material.includes(item.ID)
      );
      this.materialList.push([...filtered]);
    } else if (type == 3) {
      // this.mList.manList.push({ MType: type, Name: '' });
      this.mList.manList.unshift({ MType: type, Name: '' });
      this.mType.man.unshift('');
      this.manList.unshift([{ MType: type, Name: '' }]);
      const filtered = this.mList.manList.filter(
        (item: any) => !this.mType.man.includes(item.ID)
      );
      this.manList.push([...filtered]);
    } else if (type == 4) {
      // this.mList.methodList.push({ MType: type, Name: '' });
      this.mList.methodList.unshift({ MType: type, Name: '' });
      this.mType.method.unshift('');
      this.methodList.unshift([{ MType: type, Name: '' }]);
      const filtered = this.mList.methodList.filter(
        (item: any) => !this.mType.method.includes(item.ID)
      );
      this.methodList.push([...filtered]);
    } else if (type == 5) {
      // this.mList.motherNatureList.push({ MType: type, Name: '' });
      this.mList.motherNatureList.unshift({ MType: type, Name: '' });
      this.mType.motherNature.unshift('');
      this.motherNatureList.unshift([{ MType: type, Name: '' }]);
      const filtered = this.mList.methodList.filter(
        (item: any) => !this.mType.method.includes(item.ID)
      );
      this.methodList.push([...filtered]);
    } else {
      // this.mList.managementList.push({ MType: type, Name: '' });
      this.mList.managementList.unshift({ MType: type, Name: '' });
      this.mType.management.unshift('');
      this.managementList.unshift([{ MType: type, Name: '' }]);
      const filtered = this.mList.managementList.filter(
        (item: any) => !this.mType.management.includes(item.ID)
      );
      this.managementList.push([...filtered]);
    }
  }

  deleteRow(type: number, i: number) {
    if (type == 1) {
      this.mList.machineryList.splice(i, 1);
      this.mType.machinery.splice(i, 1);
      this.machineryList.splice(i, 1);
    } else if (type == 2) {
      this.mList.materialList.splice(i, 1);
      this.mType.material.splice(i, 1);
      this.materialList.splice(i, 1);
    } else if (type == 3) {
      this.mList.manList.splice(i, 1);
      this.mType.man.splice(i, 1);
      this.manList.splice(i, 1);
    } else if (type == 4) {
      this.mList.methodList.splice(i, 1);
      this.mType.method.splice(i, 1);
      this.methodList.splice(i, 1);
    } else if (type == 5) {
      this.mList.motherNatureList.splice(i, 1);
      this.mType.motherNature.splice(i, 1);
      this.motherNatureList.splice(i, 1);
    } else {
      this.mList.managementList.splice(i, 1);
      this.mType.management.splice(i, 1);
      this.managementList.splice(i, 1);
    }
  }

  updateMType(event: number) {
    this.headerData.NoOfM = event;
  }

  checkDuplicate(MName: string, name: any, index: number) {
    if (MName == 'machinery') {
      let probExist = this.orgMachineryList.find(
        (orgMech: any, ind: number) => orgMech.Name == name
      );
      if (probExist) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.machinery[index] = '';
      }

      let probExistNew = this.mType.machinery.find(
        (orgMech: any, ind: number) => index != ind && orgMech == name
      );
      if (probExistNew) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.machinery[index] = '';
      }
    }
    if (MName == 'material') {
      let probExist = this.orgMaterialList.find(
        (orgMat: any, ind: number) => orgMat.Name == name
      );
      if (probExist) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.material[index] = '';
      }

      let probExistNew = this.mType.material.find(
        (orgMat: any, ind: number) => index != ind && orgMat == name
      );
      if (probExistNew) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.material[index] = '';
      }
    }
    if (MName == 'man') {
      let probExist = this.orgManList.find(
        (orgMan: any, ind: number) => orgMan.Name == name
      );
      if (probExist) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.man[index] = '';
      }

      let probExistNew = this.mType.man.find(
        (orgMan: any, ind: number) => index != ind && orgMan == name
      );
      if (probExistNew) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.man[index] = '';
      }
    }
    if (MName == 'method') {
      let probExist = this.orgMethodList.find(
        (orgMethod: any, ind: number) => orgMethod.Name == name
      );
      if (probExist) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.method[index] = '';
      }

      let probExistNew = this.mType.method.find(
        (orgMethod: any, ind: number) => index != ind && orgMethod == name
      );
      if (probExistNew) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.method[index] = '';
      }
    }
    if (MName == 'motherNature') {
      let probExist = this.orgMotherNatureList.find(
        (orgMotherNat: any, ind: number) => orgMotherNat.Name == name
      );
      if (probExist) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.motherNature[index] = '';
      }

      let probExistNew = this.mType.motherNature.find(
        (orgMotherNat: any, ind: number) => index != ind && orgMotherNat == name
      );
      if (probExistNew) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.motherNature[index] = '';
      }
    }
    if (MName == 'management') {
      let probExist = this.orgManagementList.find(
        (orgManage: any, ind: number) => orgManage.Name == name
      );
      if (probExist) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.management[index] = '';
      }

      let probExistNew = this.mType.management.find(
        (orgManage: any, ind: number) => index != ind && orgManage == name
      );
      if (probExistNew) {
        this.toaster.warning(
          'Warning',
          name + ' Value already exist in' + MName
        );
        this.mType.management[index] = '';
      }
    }
  }

  openFile() {
    this.fileInput.nativeElement.click();
  }

  readFile(event: any) {
    this.imageFile = event.target.files[0];
    // this.imageFileName = this.imageFile.name;

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  deleteFile(i: any) {
    this.imageSrc = '';
    this.UploadFile = '';
    this.imageFile = '';
  }

  imageZoomModal(): void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      height: '90%',
      width: '90%',
      data: this.imageSrc ? this.imageSrc : this.blobStorageUrl + this.UploadFile,
    });
    // this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  isDisabledField(id: any) {
    let isExist = this.causeEffectData.find((ce: any) => ce.MType.ID == id);
    if (isExist) return true;
    else return false;
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
