import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Login } from 'src/app/models/login';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  leftBannerImgurl = 'assets/images/login-banner.png';
  loginForm!: FormGroup;
  loginmodel!: Login;
  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('user') === null) {
      this.router.navigate(['/login']);
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  getGroup(UserId: any) {
    this.authService.getUserGroup({ userId: UserId }).subscribe((res) => {
      localStorage.setItem('egId', JSON.stringify(res?.egId));
    }, (error) => {
      this.toaster.error('Error', error.error.message);
    });
  }

  login(): void {
    this.loginmodel = this.loginForm.value;
    const data = {
      Username: this.loginForm.value.email,
      Passowrd: this.loginForm.value.password
    };
    this.authService.login(data).subscribe((user: any) => {
      let userdataJson: any = localStorage.getItem('user');
      let userdata: any = JSON.parse(userdataJson);
      this.getGroup(userdata.UserId);
      this.authService.getUserRoles({ userId: userdata.UserId }).subscribe((res) => {
        localStorage.setItem('roleName', JSON.stringify(res.map((c: any) => c.roleName)));
      }, (error) => {
        this.toaster.error('Error', error.error.message);
      });
      this.router.navigate(['/landingPage']);
      this.spinner.hide();
    },
    (error: any) => {
      this.toaster.error('Error', error.error.message);
      this.spinner.hide();
    });
  }
  resetForm(): void {
    this.loginForm.reset();
  }
}
