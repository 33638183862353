import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { DtServiceService } from 'src/app/services/dtService.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManagerExecutionService } from 'src/app/services/manager.service';
import { MatDialog } from "@angular/material/dialog";
import * as _moment from 'moment';
import { Chart } from 'angular-highcharts';
import * as  Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import { BtnRenderer } from 'src/app/btn-renderer.component';
import { CustomTooltip } from 'src/app/custom-tooltip.component';
// import { MatOption } from '@angular/material/select';

const moment = _moment;

More(Highcharts);
import Drilldown from 'highcharts/modules/drilldown';
import { VariableBinding } from '@angular/compiler';
import { CommonService } from 'src/app/services/common.service';
Drilldown(Highcharts);
Exporting(Highcharts);

@Component({
  selector: 'app-stage-dashboard',
  templateUrl: './stage-dashboard.component.html',
  styleUrls: ['./stage-dashboard.component.scss']
})
export class StageDashboardComponent implements OnInit {

  spinnerText: string = '';
  stageName: string = 'RCA Full Cycle -4 Stages';
  breadcrumList!: any;
  groupList: any = [];
  groupTempList: any = [];
  Group: any;
  Plant: any;
  plantList: any = [];
  plantTempList: any = [];
  deptName = "";
  dataNotFoundForDept: boolean = false;
  stageBydeptshow: boolean = false;
  stageByUserList: any = [];
  satagesList: any = [];
  deptList: any = [];
  deptTempList: any = [];
  stage: string = "";
  stage_name: string = "";
  rca_stage: string = "";
  userId: any;
  username: any;
  headerIds: any = [];
  userIds: any = [];
  graphTableShow: boolean = false;
  userList: any;
  stageDetailsModal: any = [];
  stageDetailsHeader: any;
  stageDetailscolumnNames: any = [];
  chart: any;
  stageGraph: any;
  chartDeptWise: any;
  actionPlanchart: any;
  showDeptViseCountGraph: any = [];
  otherStagesListColumns: any;
  stageByUserDataArray: any = [];
  rcaListDataArray: any = [];
  otherStagesListDataArray: any;
  stageDetailsDataArray: any;
  paginationPageSize!: any;
  lastwlastmdate: any = {};
  nzFormat = "dd-MM-yyyy HH:mm:ss";
  chartStage!: Chart;

  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
      tooltipComponent: 'ag-tooltip',
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      BtnRenderer: BtnRenderer,
    },
    components: {
      customTooltip: CustomTooltip,
    },
  };
  gridApi!: any;
  gridColumnApi!: any;

  @ViewChild('content') private content!: TemplateRef<any>;
  @ViewChild('fiveaction') private fiveaction!: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('allSelected') private allSelected: any;
  searchDefectForm = new FormGroup({
    group: new FormControl(null, [Validators.required]),
    plant: new FormControl(null, [Validators.required]),
    department: new FormControl(null),
    fromDate: new FormControl(null),
    toDate: new FormControl(null,),

  });
  stageByUserColumnDefs = [{ field: 'user', headerName: 'Name' }, { field: 'dept', headerName: 'Dept' },
  { field: 'rcaMember', headerName: 'RCA Membership' }, { field: 'rcaTeamLeader', headerName: 'RCA Leadership' },
  {
    headerName: '',
    cellRenderer: 'BtnRenderer',
    cellRendererParams: {
      onClick: this.getStageDetails.bind(this, 'rca'),
    },
  },
  { field: 'sixw2hMember', headerName: '6W2H Member' }, { field: 'sixw2hTeamLeader', headerName: '6W2H TeamLeader' },
  {
    headerName: '',
    cellRenderer: 'BtnRenderer',
    cellRendererParams: {
      onClick: this.getStageDetails.bind(this, 'swth'),
    },
  },
  { field: 'fishboneMember', headerName: 'FishBone Member' }, { field: 'fishboneTeamLeader', headerName: 'FishBone TeamLeader' },
  {
    headerName: '',
    cellRenderer: 'BtnRenderer',
    cellRendererParams: {
      onClick: this.getStageDetails.bind(this, 'fishBone'),
    },
  },
  { field: 'causeeffectMember', headerName: 'Cause & Effect Member' }, { field: 'causeeffectTeamLeader', headerName: 'Cause & Effect TeamLeader' },
  {
    headerName: '',
    cellRenderer: 'BtnRenderer',
    cellRendererParams: {
      onClick: this.getStageDetails.bind(this, 'causeAndEffects'),
    },
  },
  { field: 'fivewhyMember', headerName: '5 Why Member' }, { field: 'fivewhyTeamLeader', headerName: '5 Why TeamLeader' },
  {
    headerName: '',
    cellRenderer: 'BtnRenderer',
    cellRendererParams: {
      onClick: this.getStageDetails.bind(this, 'fiveWhy'),
    },
  }

  ];

  rcaListDataColumns = [
    { field: 'InitProbStatement', headerName: 'Problem Statement' },
    { field: 'Dept', headerName: 'Dept' },
    { field: 'Team', headerName: 'Team' },
    { field: 'TeamLeader', headerName: 'TeamLeader' },
    {
      field: 'rca',
      headerName: 'RCA',
      cellRenderer: 'BtnRenderer',
      cellRendererParams: {
        onClick: this.openModal.bind(this, 'rca'),
      },
      suppressExcelExport: true
    },
    {
      field: 'rca',
      headerName: '5 why Action Plan',
      cellRenderer: 'BtnRenderer',
      cellRendererParams: {
        onClick: this.openFiveWhyActionModal.bind(this),
      },
      suppressExcelExport: true
    },
    // {
    //   field: 'swth',
    //   headerName: '6W2H',
    //   cellRenderer: 'BtnRenderer',
    //   width: 90,
    //   cellRendererParams: {
    //     onClick: this.openModal.bind(this, 'swth'),
    //   },
    //   suppressExcelExport: true
    // },
    // {
    //   field: 'fishBone',
    //   headerName: 'FishBone',
    //   cellRenderer: 'BtnRenderer',
    //   width: 90,
    //   cellRendererParams: {
    //     onClick: this.openModal.bind(this, 'fishBone'),
    //   },
    // },
    // {
    //   field: 'causeAndEffects',
    //   headerName: 'Cause & Effect',
    //   cellRenderer: 'BtnRenderer',
    //   width: 90,
    //   cellRendererParams: {
    //     onClick: this.openModal.bind(this, 'causeAndEffects'),
    //   },
    // },
    // {
    //   field: 'fiveWhy',
    //   headerName: '5 Why',
    //   cellRenderer: 'BtnRenderer',
    //   width: 90,
    //   cellRendererParams: {
    //     onClick: this.openModal.bind(this, 'fiveWhy'),
    //   },
    // },
    {
      field: 'CreatedAt',
      headerName: 'Creation Date', cellRenderer: function (params: any) {
        let html = params && params.value ? params.value : '';
        return html != '' ? _moment(html).format('MM/DD/YYYY HH:mm:ss') : '';
      }
    }
  ];
  SWTHcolumnNames: any = [];
  SWTHDataArray: any = [];
  FISHBONEcolumnNames: any = [];
  FISHBONEDataArray: any = [];
  FIVEWHYDataArray: any = [];
  FIVEWHYcolumnNames: any = [];
  CAUSEANDEFFECTDataArray: any = [];
  CAUSEANDEFFECTcolumnNames: any = [];
  FiveActionColumnNames: any = [];
  FiveActionDataArray: any = [];
  drilldownData: any;
  tempStageByUserDataArray: any;
  isStageDataByUser: boolean = false;
  isRca: boolean = false;
  isActionPlanChart: boolean = false;
  nofm: any;
  teams: any = [];

  constructor(private userManagementService: UserManagementService, private spinner: NgxSpinnerService,
    private toaster: ToastrService, private analyticalToolsService: AnalyticalToolsService,
    private router: Router, private sfdService: ShopFloorDataService, private dtService: DtServiceService,
    public managerExecutionService: ManagerExecutionService, private dialog: MatDialog, private commonService: CommonService) {


  }

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.getGroups();
    this.getUserList();
    this.getAllDepartment();
  }

  makeDataLastWeek(): void {
    const stDT = new Date();
    console.log(stDT);
    stDT.setDate(stDT.getDate() - 6);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = this.dateAsYYYYMMDDHHNNSS(stDT);
    const endDt = this.dateAsYYYYMMDDHHNNSS(edDT);

    this.lastwlastmdate = {
      dtStartDate: new Date(startDt),
      dtEndDate: new Date(endDt),

    };

  }

  makeDataLastMonth(): void {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 29);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = this.dateAsYYYYMMDDHHNNSS(stDT);
    const endDt = this.dateAsYYYYMMDDHHNNSS(edDT);

    this.lastwlastmdate = {
      dtStartDate: new Date(startDt),
      dtEndDate: new Date(endDt),
    };
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.spinner.show();
    this.spinnerText = 'Loading Menu Data';
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.spinner.hide();
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }
  lastWeek(): void {
    this.makeDataLastWeek();
    this.onSubmit();
  }

  lastMonth(): void {
    this.makeDataLastMonth();
    this.onSubmit();
  }
  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.spinner.show();
    this.spinnerText = 'Fetching Groups Data';
    this.sfdService.getGroupData(key).subscribe((res: any) => {
      this.spinner.hide();
      this.groupList = res.masterList;
      this.groupTempList = res.masterList;
        this.searchDefectForm.get('group')?.setValue(this.groupList[0].TypeId)
        this.getDeptData(this.searchDefectForm.value.group);
    },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getDeptData(value: any): void {
    const key = {
      GroupId: value
    };
    this.spinner.show();
    this.spinnerText = 'Fetching Department Data'; 
    this.dtService.getPlantData(key).subscribe((res: any) => {
      this.spinner.hide();
      this.plantList = res.DepartList;
      this.plantTempList = res.DepartList;
        this.searchDefectForm.get('plant')?.setValue(this.plantList[0].DeptId)
      if (!res.DepartList.length)
        this.dataNotFoundForDept = true;
      else
        this.dataNotFoundForDept = false;
      if (this.plantList.length == 1)
        this.Plant = parseInt(this.plantList[0].TypeId);
      // this.getAllDepartment();
    },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getUserList() {
    this.spinner.show();
    this.spinnerText = "Fetching User Data";
    this.managerExecutionService.getUserList().subscribe((res: any) => {
      console.log(res);
      this.userList = res.UserList;
      this.spinner.hide();
    }, (err: any) => {
      console.log(err);
      this.spinner.hide();
    });
  }
  getTeamList() {
    this.spinnerText = "Fetching Team Data";
    this.spinner.show();
    const data = {
      EGId: this.searchDefectForm.value.group,
      PlantId: this.searchDefectForm.value.plant
    }
    this.commonService.getPeopleMasterData(data).subscribe((res: any) => {
      this.teams = res.employeeInfoList;
      this.spinner.hide();
    }, (err: any) => {
      console.log(err);
      this.spinner.hide();
    });
  }
  getAllDepartment() {
    this.spinner.show();
    this.spinnerText = "Fetching All Departments Data";
    // const key = {
    //   egId: this.Group,
    //   plantId: this.Plant
    // };

    const key = {
      ptId: this.searchDefectForm.value.plant
    };
    this.spinner.show();
    this.dtService.getDeptData(key).subscribe((res: any) => {
      this.deptList = res;
      this.deptTempList = res;
      this.spinner.hide();
    });
  }

  tosslePerOne(all: any) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return;
    }
    if (this.searchDefectForm.controls.department.value.length == this.deptList.length)
      this.allSelected.select();

  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.searchDefectForm.controls.department
        .patchValue([...this.deptList.map((item: any) => item.TypeId), 0]);
      // this.allSelected.select();
    } else {
      this.searchDefectForm.controls.department.patchValue([]);
    }
  }


  //get dashboard api
  onSubmit() {
    this.graphTableShow = false;
    this.isStageDataByUser = false;
    if (this.searchDefectForm.valid) {
      this.spinner.show();
      const key = {
        ptId: this.searchDefectForm.value.plant
      };
      console.log(key);
      this.dtService.getDeptData(key).subscribe((res: any) => {
        this.deptList = res;
        console.log(res);
      });

      this.analyticalToolsService.getStageTeamCountByUser(this.searchDefectForm.value).subscribe((res: any) => {
        this.spinner.hide();
        this.stageByUserDataArray = res.data.stagesbyuserList;
        this.stageByUserList = res.data.stagesbyuserList;
        if (res.data.stageObj) {
          let DepartmentDataRCA: any = [];
          let DepartmentData6W2H: any = [];
          let DepartmentDataFISHBONE: any = [];
          let DepartmentDataCAUSE: any = [];
          let DepartmentDataFIVEWHY: any = [];
          let DepartmentSeries: any = [];
          //rca
          if (res.data.stageObj && res.data.stageObj.rca) {
            if (this.deptList && this.deptList.length) {
              for (const dept of this.deptList) {
                let deptObj = res.data.stageObj.rca.array.filter((arr: any) => arr.Dept == dept.TypeId);
                if (deptObj && deptObj.length) {
                  dept.Count = deptObj.length;
                  DepartmentDataRCA.push([
                    dept.TypeName,
                    parseFloat(dept.Count),
                  ]);
                }
              }
              DepartmentSeries.push({
                name: 'Departments',
                id: 'rca',
                data: DepartmentDataRCA
              })
            }
          }

          //6w2h
          if (res.data.stageObj && res.data.stageObj.sixw2h) {
            if (this.deptList && this.deptList.length) {
              for (const dept of this.deptList) {
                let deptObj = res.data.stageObj.sixw2h.array.filter((arr: any) => arr.Dept == dept.TypeId);
                if (deptObj && deptObj.length) {
                  dept.Count = deptObj.length;
                  DepartmentData6W2H.push([
                    dept.TypeName,
                    parseFloat(dept.Count),
                  ]);
                }
              }
              DepartmentSeries.push({
                name: 'Departments',
                id: '6w2h',
                data: DepartmentData6W2H
              })
            }
          }

          //fishbone
          if (res.data.stageObj && res.data.stageObj.fishbone) {
            if (this.deptList && this.deptList.length) {
              for (const dept of this.deptList) {
                let deptObj = res.data.stageObj.fishbone.array.filter((arr: any) => arr.Dept == dept.TypeId);
                if (deptObj && deptObj.length) {
                  dept.Count = deptObj.length;
                  DepartmentDataFISHBONE.push([
                    dept.TypeName,
                    parseFloat(dept.Count),
                  ]);
                }
              }
              DepartmentSeries.push({
                name: 'Departments',
                id: 'fishbone',
                data: DepartmentDataFISHBONE
              })
            }
          }

          //cause
          if (res.data.stageObj && res.data.stageObj.cause) {
            if (this.deptList && this.deptList.length) {
              for (const dept of this.deptList) {
                let deptObj = res.data.stageObj.cause.array.filter((arr: any) => arr.Dept == dept.TypeId);
                if (deptObj && deptObj.length) {
                  dept.Count = deptObj.length;
                  DepartmentDataCAUSE.push([
                    dept.TypeName,
                    parseFloat(dept.Count),
                  ]);
                }
              }
              DepartmentSeries.push({
                name: 'Departments',
                id: 'cause',
                data: DepartmentDataCAUSE
              })
            }
          }

          //fivewhy
          if (res.data.stageObj && res.data.stageObj.fivewhy) {
            if (this.deptList && this.deptList.length) {
              for (const dept of this.deptList) {
                let deptObj = res.data.stageObj.fivewhy.array.filter((arr: any) => arr.Dept == dept.TypeId);
                if (deptObj && deptObj.length) {
                  dept.Count = deptObj.length;
                  DepartmentDataFIVEWHY.push([
                    dept.TypeName,
                    parseFloat(dept.Count),
                  ]);
                }
              }
              DepartmentSeries.push({
                name: 'Departments',
                id: 'fivewhy',
                data: DepartmentDataFIVEWHY
              })
            }
          }

          this.drilldownData = DepartmentSeries;
          this.getStageChart(res.data.stageObj);
        }
        for (let stagedata of this.stageByUserList) {

          // const user = this.userList.find((user: any) => user.UserId == stagedata.user);
          // if (user != undefined)
          //   stagedata.user = user.UserName;

          const user = this.teams.find((user: any) => user.UserId == stagedata.user);
          if (user != undefined)
            stagedata.user = user.FirstName + ' ' + user.lastName;

          //department
          const department = this.deptList.find((dept: any) => dept.TypeId == stagedata.dept);
          if (department != undefined)
            stagedata.dept = department.TypeName;
        }

        this.headerIds = res.data.headersId;
        this.userIds = res.data.usersId;
      },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  getStageChart(val: any) {
    let StageLabel: any[] = new Array();
    let StageData: any[] = new Array();
    if (val) {
      // val.forEach((element: any) => {
      //   MachineLabel.push(element.puDesc);
      //   MachineData.push({
      //     name: element.puDesc,
      //     y: parseFloat(element.WOCount),
      //     color: '#0f0fd1',
      //     drilldown: element.puId
      //   });
      // });
      if (val.sixw2h) {
        StageLabel.push(val.sixw2h.name);
        StageData.push({
          name: val.sixw2h.name,
          y: parseFloat(val.sixw2h.array.length),
          color: '#0f0fd1',
          drilldown: '6w2h'
        });
      }

      if (val.fishbone) {
        StageLabel.push(val.fishbone.name);
        StageData.push({
          name: val.fishbone.name,
          y: parseFloat(val.fishbone.array.length),
          color: '#0f0fd1',
          drilldown: 'fishbone'
        });
      }

      if (val.cause) {
        StageLabel.push(val.cause.name);
        StageData.push({
          name: val.cause.name,
          y: parseFloat(val.cause.array.length),
          color: '#0f0fd1',
          drilldown: 'cause'
        });
      }

      if (val.fivewhy) {
        StageLabel.push(val.fivewhy.name);
        StageData.push({
          name: val.fivewhy.name,
          y: parseFloat(val.fivewhy.array.length),
          color: '#0f0fd1',
          drilldown: 'fivewhy'
        });
      }

      if (val.rca) {
        StageLabel.push(val.rca.name);
        StageData.push({
          name: val.rca.name,
          y: parseFloat(val.rca.array.length),
          color: '#0f0fd1',
          drilldown: 'rca'
        });
      }
    }

    this.stageGraph = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category'
      },
      plotOptions: {
        series: {
          events: {
            click: this.getDepartmentData.bind(this)
          },
        }
      },
      tooltip: {
        pointFormat: 'Count: {point.y}'
      },
      series: [{
        name: 'Stages',
        data: StageData
      }],
      drilldown: {
        series: this.drilldownData
      }
    };
    let chart = new Chart(this.stageGraph);
    this.chartStage = chart;
  }

  getDepartmentData(event: any) {
    this.isStageDataByUser = true;
  }

  getStageDetails(type: string, e: any) {
    this.stageBydeptshow = false;
    this.isActionPlanChart = false;
    let index = e.rowData.key;
    this.spinner.show();
    this.spinnerText = "Fetching Stages Data";
    this.graphTableShow = true;

    let membercount = 0
    let leadercount = 0;
    this.stage = type;
    this.userId = this.userIds[index];
    // const user = this.userList.find((user: any) => user.UserId == this.userId);
    // if (user != undefined)
    //   this.username = user.UserName;

    const user = this.teams.find((user: any) => user.UserId == this.userId);
    if (user != undefined)
      this.username = user.FirstName + ' ' + user.lastName;

    let requestData = {
      stage: type,
      headersId: this.headerIds,
      userId: this.userIds[index]
    }
    if (type == 'rca') {
      this.stage_name = "RCA Full Cycle - 4 Stages";
      membercount = this.stageByUserList[index].rcaMember;
      leadercount = this.stageByUserList[index].rcaTeamLeader;
    } else if (type == 'swth') {
      this.stage_name = "6W2H";
      membercount = this.stageByUserList[index].sixw2hMember;
      leadercount = this.stageByUserList[index].sixw2hTeamLeader;
    } else if (type == 'fishBone') {
      this.stage_name = "FishBone";
      membercount = this.stageByUserList[index].fishboneMember;
      leadercount = this.stageByUserList[index].fishboneTeamLeader;
    } else if (type == 'causeAndEffects') {
      this.stage_name = "Cause & Effects";
      membercount = this.stageByUserList[index].causeeffectMember;
      leadercount = this.stageByUserList[index].causeeffectTeamLeader;
    } else if (type == 'fiveWhy') {
      this.stage_name = "FiveWhy";
      membercount = this.stageByUserList[index].fivewhyMember;
      leadercount = this.stageByUserList[index].fivewhyTeamLeader;
    }

    this.chart = new Chart({

      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: this.stage_name + ' Stage Member Count'
      },
      xAxis: {
        type: 'category'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getStagesDeptGraphList.bind(this)
            }
          }
        }
      },

      series: [
        {
          name: this.stage_name,
          type: 'column',
          data: [
            {
              "name": "Member of Team",
              "y": membercount,
              "color": "#0f0fd1",
              drilldown: 'deptvicecount'
            },
            {
              "name": "Teamleads",
              "y": leadercount,
              "color": "#10ce9c",
              drilldown: 'deptvicecount'
            }
          ]
        }
      ]
    });


    if (this.stage == 'fiveWhy') {
      this.otherStagesListColumns = [
        {
          field: 'status', headerName: 'Status',
          wrapText: true,
          cellStyle: (params: { value: string; }) => {
            if (params.value === 'Open') {
              return { color: 'white', backgroundColor: '#ff0000', 'font-weight': 'bold' };
            } else {
              params.value = 'Closed'
              return { color: 'white', backgroundColor: '#0cc500', 'font-weight': 'bold' };
            }
          }
        },
        { field: 'InitProbStatement', headerName: 'Problem Statement' },
        { field: 'Dept', headerName: 'Dept' },
        { field: 'Team', headerName: 'Team' },
        { field: 'TeamLeader', headerName: 'TeamLeader' },
        {
          field: 'action',
          headerName: 'Action',
          cellRenderer: 'BtnRenderer',
          width: 90,
          cellRendererParams: {
            onClick: this.openModal.bind(this, this.stage),
          },
        },
        {
          field: 'action',
          headerName: '5 Why Action Plan',
          cellRenderer: 'BtnRenderer',
          width: 90,
          cellRendererParams: {
            onClick: this.openFiveWhyActionModal.bind(this),
          },
        },

      ];
    } else {
      this.otherStagesListColumns = [
        { field: 'InitProbStatement', headerName: 'Problem Statement' },
        { field: 'Dept', headerName: 'Dept' },
        { field: 'Team', headerName: 'Team' },
        { field: 'TeamLeader', headerName: 'TeamLeader' },
        {
          field: 'CreatedAt',
          headerName: 'Creation Date',
          cellRenderer: (params: any) => {

            return moment(params.value).format('Y-MM-DD hh:mm:ss a');
          }
        },
        {
          field: 'action',
          headerName: 'Action',
          cellRenderer: 'BtnRenderer',
          width: 90,
          cellRendererParams: {
            onClick: this.openModal.bind(this, this.stage),
          },
        }

      ];
    }
    //get stage details table view 
    this.analyticalToolsService.getStagesDetailTableView(requestData).subscribe((res: any) => {
      this.spinner.hide();
      if (type == 'rca') {
        // this.rcaListDataSource = new MatTableDataSource(res.data.stagesList);
        this.rcaListDataArray = res.data.stagesList;
        this.satagesList = res.data.stagesList;

      } else {
        // this.otherStagesListDataSource = new MatTableDataSource(res.data.stagesList);
        this.otherStagesListDataArray = res.data.stagesList;
        this.satagesList = res.data.stagesList;

      }

      for (let stagedata of this.satagesList) {
        let teamusers: any = [];
        stagedata.Team.split(',').map((team: any) => {
          // const teamuser = this.userList.find((user: any) => user.UserId == team);
          // if (teamuser != undefined)
          //   teamusers.push(teamuser.UserName);
          const teamuser = this.teams.find((user: any) => user.UserId == team);
          if (teamuser != undefined)
            teamusers.push(teamuser.FirstName + ' ' + teamuser.lastName);
        });
        stagedata.Team = teamusers;
        // const teamlead = this.userList.find((user: any) => user.UserId == stagedata.TeamLeader);
        // if (teamlead != undefined)
        //   stagedata.TeamLeader = teamlead.UserName;

        const teamlead = this.teams.find((user: any) => user.UserId == stagedata.TeamLeader);
        if (teamlead != undefined)
          stagedata.TeamLeader = teamlead.FirstName + ' ' + teamlead.lastName;

        //department
        const department = this.deptList.find((dept: any) => dept.TypeId == stagedata.dept);
        if (department != undefined)
          stagedata.dept = department.TypeName;
      }

    },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getStagesDeptGraphList(e: any) {
    this.stageBydeptshow = true;
    this.spinner.show();
    this.spinnerText = "Fetching Stages Department Data";
    let requestData = {
      stage: this.stage,
      headersId: this.headerIds,
      userId: this.userId,
      departments: this.deptList
    }

    this.analyticalToolsService.getStagesDetailGraphView(requestData).subscribe((res: any) => {
      this.spinner.hide();
      let deptColumns: any = []; let swthArr = []; let fishboneArr = []; let causeeffArr = []; let fivewhyAr = [];
      let stageDataArray: any = []; let commonStageArr: any = [];
      if (res.data.deptvisecount.length != 0) {
        for (let deptvise of res.data.deptvisecount) {
          var Obj = {};
          deptColumns.push(deptvise.name);

          if (this.stage == 'rca') {
            swthArr.push(deptvise.swth);
            fishboneArr.push(deptvise.fishbone);
            causeeffArr.push(deptvise.causeeffect);
            fivewhyAr.push(deptvise.fivewhy);
          } else {
            Obj = {
              name: deptvise.name,
              y: deptvise.count,
            }
          }
          commonStageArr.push(Obj);
        }

      }
      if (this.stage == 'rca') {
        stageDataArray = [
          {
            name: '6W2H',
            y: '6W2H',
            type: 'column',
            data: swthArr,
            "color": "#0f0fd1",
          },
          {
            name: 'FishBone',
            y: 'FishBone',
            type: 'column',
            data: fishboneArr,
            "color": "#000000b8",
          },
          {
            name: 'Cause & Effect',
            y: 'Cause & Effect',
            type: 'column',
            data: causeeffArr,
            "color": "#10ce9c",
          },
          {
            name: '5 Why',
            y: 'fivewhy',
            type: 'column',
            data: fivewhyAr,
            "color": "#8a1f1f",
          },
        ];
      } else {
        stageDataArray = [
          {
            name: this.stage_name,
            type: 'column',
            data: commonStageArr,
            "color": "#0f0fd1",
          },
        ];
      }

      //chart
      this.chartDeptWise = new Chart({


        chart: {
          type: 'column',
          options3d: {
            enabled: true,
            alpha: 45
          }
        },
        title: {
          text: this.stage_name + ' Department Wise Count'
        },
        xAxis: {
          categories: deptColumns,
          crosshair: true
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
            point: {
              events: {

                click: this.getfiveWhyCountByDept.bind(this)
              }
            }
          }
        },

        series: stageDataArray,
      });
    },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getfiveWhyCountByDept(e: any) {
    if (this.stage == 'rca' || this.stage == 'fiveWhy') {
      this.isActionPlanChart = (e.point.series.userOptions.y == 'fivewhy') || (this.stage == 'fiveWhy') ? true : false;
      this.deptName = e.point.category;
      this.spinner.show();
      this.spinnerText = "Fetching FiveWhy Count By Department";
      let dept = 0;
      const department = this.deptList.find((dept: any) => dept.TypeName == e.point.category);
      if (department != undefined)
        dept = department.TypeId;

      let type = e.point.series.name.toLocaleLowerCase();

      type = type == '5 why' ? 'rca' : type;

      let requestData = {
        type: type,
        deptId: dept,
        headersId: this.headerIds
      }
      this.analyticalToolsService.getGraphDeptViseActionPlan(requestData).subscribe((res: any) => {

        this.spinner.hide();
        let fivewhyColumns = []; let openCount = []; let closeCount = []; let fivewhyActionplanCount: any = [];
        if (res.data.opencloseCount.length) {
          for (let [i, actionplan] of res.data.opencloseCount.entries()) {
            fivewhyColumns.push(i + 1);
            openCount.push(actionplan.openCount);
            closeCount.push(actionplan.closeCont);
          }
        }
        fivewhyActionplanCount = [
          {
            name: 'Open',
            type: 'column',
            data: openCount,
            "color": "#0f0fd1",
          },
          {
            name: 'Close',
            type: 'column',
            data: closeCount,
            "color": "#10ce9c",
          },
        ]
        //chart
        this.actionPlanchart = new Chart({


          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 45
            }
          },
          title: {
            text: this.deptName + ' Department Wise ActionPlan'
          },
          xAxis: {
            categories: fivewhyColumns,
            crosshair: true
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              borderWidth: 0,
              dataLabels: {
                enabled: true,
              }
            }
          },

          series: fivewhyActionplanCount,
        });
      },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );
    }
    else {
      this.stageBydeptshow = false;
    }
  }
  setTableDataSWTH(key: any) {
    let SWTH: any = [];
    SWTH.push(this.satagesList[key].swth);
    this.SWTHcolumnNames = [{ field: 'ProblemStatement', headerName: 'New Refocused Problem Statement', width: 1000, tooltipField: 'ProblemStatement', }];
    this.SWTHDataArray = SWTH;
  }
  setTableDataFISHBONE(key: any) {
    let FISHBONE: any = [];
    this.FISHBONEcolumnNames = [
      { field: 'Machinery', headerName: 'Machine', suppressSizeToFit: true, },
      { field: 'Material', headerName: 'Material', suppressSizeToFit: true, },
      { field: 'Man', headerName: 'Man', suppressSizeToFit: true, },
      { field: 'Method', headerName: 'Method', suppressSizeToFit: true, },
      { field: 'MotherNature', headerName: 'Mother Nature', suppressSizeToFit: true, },
      { field: 'Management', headerName: 'Management', suppressSizeToFit: true, },
    ];
    this.FISHBONEDataArray = [];
    if (this.satagesList[key].fishBone != null) {
      this.spinner.show();
      this.spinnerText = "Fetching Fishbone Data";
      this.analyticalToolsService.getStageDataById('fishBone', this.satagesList[key].fishBone.ID).subscribe((res: any) => {
        this.spinner.hide();
        this.nofm = this.satagesList && this.satagesList[0] && this.satagesList[0].NoOfM ? this.satagesList[0].NoOfM + ' M' : ''
        FISHBONE.push(res.data.response);

        this.FISHBONEDataArray = FISHBONE;
      },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );
    }
  }
  setTableDataCAUSEANDEFFECT(key: any) {
    this.spinner.show();
    this.spinnerText = "Fetching Cause&Effect Data";
    this.analyticalToolsService.getStageDataById('causeAndEffects', this.satagesList[key].ID).subscribe((res: any) => {
      this.CAUSEANDEFFECTcolumnNames = [
        { field: 'contributtingsource', headerName: 'Contributing Source' },
        { field: 'Priority', headerName: 'Priority' },
        { field: 'ProbableCause', headerName: 'Probable Cause' },
        { field: 'Weight', headerName: 'Weight' },
        { field: 'ValidationResult', headerName: 'Validation Result' },
        { field: 'CauseIdentified', headerName: 'Cause Identified (Yes/No)' },
        { field: 'Remark', headerName: 'Remarks' },
      ];
      res.data.response.map((cause: any) => {

        if (cause.contributtingsource == 1)
          cause.contributtingsource = 'Machine';
        else if (cause.contributtingsource == 2)
          cause.contributtingsource = 'Material';
        else if (cause.contributtingsource == 3)
          cause.contributtingsource = 'Man';
        else if (cause.contributtingsource == 4)
          cause.contributtingsource = 'Method';
        else if (cause.contributtingsource == 5)
          cause.contributtingsource = 'Mother Nature';
        else
          cause.contributtingsource = 'Management';

        if (cause.CauseIdentified == 1)
          cause.CauseIdentified = 'Yes';
        else
          cause.CauseIdentified = 'No';
      });
      this.CAUSEANDEFFECTDataArray = res.data.response;
    },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }
  setTableDataFIVEWHY(key: any) {
    let FIVEWHY: any = [];
    let stageColumnArr: any = [];
    this.spinner.show();
    this.spinnerText = "Fetching Fivewhy Data";
    this.analyticalToolsService.getStageDataById('fiveWhy', this.satagesList[key].ID).subscribe((res: any) => {
      this.FIVEWHYcolumnNames = [
        { field: 'Status', headerName: 'Status' },
        { field: 'contributtingsource', headerName: 'Contributing Source' },
        { field: 'Priority', headerName: 'Priority' },
        { field: 'ProbableCause', headerName: 'Probable Cause' },
        { field: 'Why1', headerName: 'Why 1' },
        { field: 'Why 2', headerName: 'Why 2' },
        { field: 'Why 3', headerName: 'Why 3' },
        { field: 'Why 4', headerName: 'Why 4' },
        { field: 'Why 5', headerName: 'Why 5' },
        { field: 'RootCause', headerName: 'Root Cause' },
      ];
      res.data.response.map((cause: any) => {

        if (cause.contributtingsource == 1)
          cause.contributtingsource = 'Machine';
        else if (cause.contributtingsource == 2)
          cause.contributtingsource = 'Material';
        else if (cause.contributtingsource == 3)
          cause.contributtingsource = 'Man';
        else if (cause.contributtingsource == 4)
          cause.contributtingsource = 'Method';
        else if (cause.contributtingsource == 5)
          cause.contributtingsource = 'Mother Nature';
        else
          cause.contributtingsource = 'Management';

      });
      this.FIVEWHYDataArray = res.data.response;
    },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
    this.FIVEWHYcolumnNames = stageColumnArr;
    this.FIVEWHYDataArray = FIVEWHY;
  }

  openFiveWhyActionModal(e: any) {
    let fiveWhyActionPlan: any = [];
    for (const fivewhy of e.rowData.fiveWhy) {
      for (const fiveaction of fivewhy.fivewhyaction) {
        fiveaction.Cause = fivewhy.ValidationFromCauseEffects;
        // let AssignUser = this.userList.find((user: any) => {
        //   return fiveaction.AssignTo == user.UserId
        // });
        // let VerifyUser = this.userList.find((user: any) => {
        //   return fiveaction.VerifiedBy == user.UserId
        // });
        let AssignUser = this.teams.find((user: any) => {
          return fiveaction.AssignTo == user.UserId
        });
        let VerifyUser = this.teams.find((user: any) => {
          return fiveaction.VerifiedBy == user.UserId
        });
        if (AssignUser) {
          fiveaction.AssignTo = AssignUser.FirstName + ' ' + AssignUser.lastName;
        }
        if (VerifyUser) {
          fiveaction.VerifiedBy = VerifyUser.FirstName + ' ' + VerifyUser.lastName;;
        }
        fiveWhyActionPlan.push(fiveaction);
      }
    }
    console.log(fiveWhyActionPlan);

    this.dialog.open(this.fiveaction, { height: '90%', width: '90%' });

    this.FiveActionColumnNames = [
      { field: 'Cause', headerName: 'Probable Cause' },
      { field: 'Action', headerName: 'Action' },
      { field: 'ActionType', headerName: 'Action Type' },
      { field: 'AssignTo', headerName: 'Assign To' },
      { field: 'Status', headerName: 'Status' },
      {
        field: 'TargetDate', headerName: 'Target Date', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? _moment(html).format('MM/DD/YYYY HH:mm:ss') : '';
        }
      },
      { field: 'VerifiedBy', headerName: 'Verified By' },
    ];
    this.FiveActionDataArray = fiveWhyActionPlan;
  }

  openModal(type: string, e: any): void {

    let key = e.index;
    this.dialog.open(this.content, { height: '90%', width: '90%' });
    let stageArr: any = [];
    let stageColumnArr: any = [];
    this.stageDetailscolumnNames = [];
    let teamusers: any = [];
    this.stageDetailsHeader = {};
    let CAUSEANDEFFECT: any = [];

    if (type == 'rca') {
      this.rca_stage = "RCA Full Cycle - 4 Stages";

    } else if (type == 'swth') {
      this.rca_stage = "6W2H";

    } else if (type == 'fishBone') {
      this.rca_stage = "FishBone";

    } else if (type == 'causeAndEffects') {
      this.rca_stage = "Cause & Effects";

    } else if (type == 'fiveWhy') {
      this.rca_stage = "FiveWhy";

    }
    this.stageDetailsHeader = {
      probStat: this.satagesList[key].InitProbStatement,
      Team: this.satagesList[key].Team,
      teamLeader: this.satagesList[key].TeamLeader
    }

    if (type == 'rca') {
      this.isRca = true;
      //swth
      this.setTableDataSWTH(key);
      //fishbone
      this.setTableDataFISHBONE(key);
      //causeandeffect
      this.setTableDataCAUSEANDEFFECT(key);
      //fivewhy
      this.setTableDataFIVEWHY(key);
    }
    else if (type == 'swth') {
      this.isRca = false;
      this.stageDetailsDataArray = [];
      stageArr.push(this.satagesList[key].swth);
      this.stageDetailscolumnNames = [{ field: 'ProblemStatement', headerName: 'New Refocused Problem Statement', width: 1000 }];
      this.stageDetailsDataArray = stageArr;

    } else if (type == 'fishBone') {
      this.isRca = false;
      this.stageDetailsDataArray = [];
      this.stageDetailscolumnNames = [
        { field: 'Machinery', headerName: 'Machine', suppressSizeToFit: true, },
        { field: 'Material', headerName: 'Material', suppressSizeToFit: true, },
        { field: 'Man', headerName: 'Man', suppressSizeToFit: true, },
        { field: 'Method', headerName: 'Method', suppressSizeToFit: true, },
        { field: 'MotherNature', headerName: 'Mother Nature', suppressSizeToFit: true, },
        { field: 'Management', headerName: 'Management', suppressSizeToFit: true, },
      ];
      if (this.satagesList[key].fishBone != null) {
        this.spinner.show();
        this.spinnerText = "Fetching Fishbone Data";
        this.analyticalToolsService.getStageDataById('fishBone', this.satagesList[key].fishBone.ID).subscribe((res: any) => {
          this.spinner.hide();
          stageArr.push(res.data.response);

          this.stageDetailsDataArray = stageArr;
        },
          (error) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          }
        );
      }

    } else if (type == 'causeAndEffects') {
      this.isRca = false;
      this.stageDetailsDataArray = [];
      this.spinner.show();
      this.spinnerText = "Fetching Fishbone Data";
      this.analyticalToolsService.getStageDataById('causeAndEffects', this.satagesList[key].ID).subscribe((res: any) => {
        this.stageDetailscolumnNames = [
          { field: 'contributtingsource', headerName: 'Contributing Source' },
          { field: 'Priority', headerName: 'Priority' },
          { field: 'ProbableCause', headerName: 'Probable Cause' },
          { field: 'Weight', headerName: 'Weight' },
          { field: 'ValidationResult', headerName: 'Validation Result' },
          { field: 'CauseIdentified', headerName: 'Cause Identified (Yes/No)' },
          { field: 'Remark', headerName: 'Remarks' },
        ];
        res.data.response.map((cause: any) => {

          if (cause.contributtingsource == 1)
            cause.contributtingsource = 'Machinery';
          else if (cause.contributtingsource == 2)
            cause.contributtingsource = 'Material';
          else if (cause.contributtingsource == 3)
            cause.contributtingsource = 'Man';
          else if (cause.contributtingsource == 4)
            cause.contributtingsource = 'Method';
          else if (cause.contributtingsource == 5)
            cause.contributtingsource = 'Mother Nature';
          else
            cause.contributtingsource = 'Management';

          if (cause.CauseIdentified == 1)
            cause.CauseIdentified = 'Yes';
          else
            cause.CauseIdentified = 'No';
        });
        this.stageDetailsDataArray = res.data.response;
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );

    } else {
      this.isRca = false;
      this.stageDetailsDataArray = [];
      this.spinner.show();
      this.spinnerText = "Fetching Fishbone Data";
      this.analyticalToolsService.getStageDataById('fiveWhy', this.satagesList[key].ID).subscribe((res: any) => {
        this.stageDetailscolumnNames = [
          { field: 'Status', headerName: 'Status' },
          { field: 'contributtingsource', headerName: 'Contributing Source' },
          { field: 'Priority', headerName: 'Priority' },
          { field: 'ProbableCause', headerName: 'Probable Cause' },
          { field: 'Why1', headerName: 'Why 1' },
          { field: 'Why 2', headerName: 'Why 2' },
          { field: 'Why 3', headerName: 'Why 3' },
          { field: 'Why 4', headerName: 'Why 4' },
          { field: 'Why 5', headerName: 'Why 5' },
          { field: 'RootCause', headerName: 'Root Cause' },

        ];
        res.data.response.map((cause: any) => {

          if (cause.contributtingsource == 1)
            cause.contributtingsource = 'Machine';
          else if (cause.contributtingsource == 2)
            cause.contributtingsource = 'Material';
          else if (cause.contributtingsource == 3)
            cause.contributtingsource = 'Man';
          else if (cause.contributtingsource == 4)
            cause.contributtingsource = 'Method';
          else if (cause.contributtingsource == 5)
            cause.contributtingsource = 'Mother Nature';
          else
            cause.contributtingsource = 'Management';

        });
        this.stageDetailsDataArray = res.data.response;
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );
      this.stageDetailscolumnNames = stageColumnArr;
      this.stageDetailsDataArray = stageArr;
    }

  }
  reset(): void {
    this.searchDefectForm.reset();
    this.lastwlastmdate = {};
    this.graphTableShow = false;
    this.stage = "";
    this.stage_name = "";
    this.stageByUserDataArray = [];
  }
  //date formatter
  dateAsYYYYMMDDHHNNSS(date: any): string {
    return date.getFullYear()
      + '-' + this.leftpad(date.getMonth() + 1, 2)
      + '-' + this.leftpad(date.getDate(), 2)
      + ' ' + this.leftpad(date.getHours(), 2)
      + ':' + this.leftpad(date.getMinutes(), 2)
      + ':' + this.leftpad(date.getSeconds(), 2);
  }
  leftpad(val: any, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
      + String(val)).slice(String(val).length);
  }
  onKey(value: any, type: any) {

    if (type == 'group') {
      this.groupList = this.searchKey(value.value, type);
    } else if (type == 'plant') {
      this.plantList = this.searchKey(value.value, type);
    } else if (type == 'department') {
      this.deptList = this.searchKey(value.value, type);
    }
  }

  searchKey(value: string, type: string) {
    let filter = value;
    if (type == 'group') {
      return this.groupTempList.filter((dep: any) => {
        let lowerCase = dep.TypeName.toLocaleLowerCase();
        return dep.TypeName.startsWith(filter) || lowerCase.startsWith(filter);
      });
    } else if (type == 'plant') {
      return this.plantTempList.filter((pla: any) => {
        let lowerCase = pla.DeptDesc.toLocaleLowerCase();
        return pla.DeptDesc.startsWith(filter) || lowerCase.startsWith(filter);
      });
    } else if (type == 'department') {
      return this.deptTempList.filter((dep: any) => {
        let lowerCase = dep.departmentName.toLocaleLowerCase();
        return dep.departmentName.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
  }
  onBtnExportCSV(name: string): void {

    let filename = "";
    if (name == 'namewiselist')
      filename = "Member Wise Team & TeamLeaders List";
    else if (name == 'rca') {
      filename = "RCA Full Cycle - 4 Stages List";
      this.gridOptions.columnApi.setColumnsVisible(['swth', 'fishBone', 'causeAndEffects', 'fiveWhy'], false);
    } else if (name == 'other')
      filename = this.stage_name + ' Wise List';
    else
      filename = this.stage_name + ' Data List';


    const params = {
      fileName: filename,
    }
    this.gridOptions.api.exportDataAsCsv(params);
    this.gridOptions.columnApi.setColumnsVisible(['swth', 'fishBone', 'causeAndEffects', 'fiveWhy'], true);
  }


  onBtnExportExcel(name: string): void {

    let filename = "";
    if (name == 'namewiselist')
      filename = "Member Wise Team & TeamLeaders List";
    else if (name == 'rca') {
      filename = "RCA Full Cycle - 4 Stages List";
      this.gridOptions.columnApi.setColumnsVisible(['swth', 'fishBone', 'causeAndEffects', 'fiveWhy'], false);
    } else if (name == 'other')
      filename = this.stage_name + ' Wise List';
    else
      filename = this.stage_name + ' Data List';


    const params = {
      fileName: filename,
    }
    this.gridOptions.api.exportDataAsExcel(params, getParams());
    this.gridOptions.columnApi.setColumnsVisible(['swth', 'fishBone', 'causeAndEffects', 'fiveWhy'], true);
  }


  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
const getRows = () => [
  [],
  [
    {
      data: {
        value: 'Here is a comma, and a some "quotes".',
        type: 'String',
      },
    },
  ],
  [
    {
      data: {
        value:
          'They are visible when the downloaded file is opened in Excel because custom content is properly escaped.',
        type: 'String',
      },
    },
  ],
  [
    {
      data: {
        value: 'this cell:',
        type: 'String',
      },
      mergeAcross: 1,
    },
    {
      data: {
        value: 'is empty because the first cell has mergeAcross=1',
        type: 'String',
      },
    },
  ],
  [],
];
const getParams = () => ({
  prependContent: getRows()
});