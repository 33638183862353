import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { OpmsGlassService } from '../../services/opms-glass.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-opms-monthly-stock',
  templateUrl: './opms-monthly-stock.component.html',
  styleUrls: ['./opms-monthly-stock.component.scss']
})
export class OpmsMonthlyStockComponent implements OnInit {

  panelOpenState = false;
  gridApi: any;
  gridColumnApi: any;
  rowMonthlyStock: any = [];
  monthlyStockGroup!: FormGroup;
  // uploadFile!: any;
  // tempData!: any[];
  // columnDefsMonthlyStock!: ColDef[];
  defaultColDefMonthlyStock: any =  {
    resizable: true,
    sortable: true,
    filter: true,
  };

  constructor(
    private glassServiceObj: OpmsGlassService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private  datePipe: DatePipe,
    private spinner: NgxSpinnerService
    ) { }

    columnDefsMonthlyStock: ColDef[] = [
      {headerName: 'Box Type', field: 'BoxType'},
      {headerName: 'Length', field: 'Length'},
      {headerName: 'Width', field: 'Width'},
      {headerName: 'Opening', field: 'Opening'},
      {headerName: 'Received', field: 'Received'},
      {headerName: 'Consume', field: 'Consume'},
      {headerName: 'Remaining Stock', field: 'Stock'},
    ];

  ngOnInit(): void {
    var firstDate = new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1);
    this.monthlyStockGroup = this.fb.group({
      startDate: [this.datePipe.transform(firstDate, 'yyyy-MM-dd hh:mm')],
      endDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm')],
    });
    this.getMonthlyStock(0);
  }

  // saveFile(event: any): void {
  //   if (event.target.files.length > 0) {
  //     this.uploadFile = event.target.files[0];
  //   }
  // }

  // uploadedFile(): any
  // {
  //   const formdata = new FormData();
  //   formdata.append('UploadedImage', this.uploadFile);
  //   const key = {
  //     UserId: JSON.parse(localStorage.user).UserId,
  //     ShiftId: null,
  //     UploadedImage:  this.uploadFile
  //   };
  //   formdata.append('ShiftId', '');
  //   formdata.append('UserId', JSON.parse(localStorage.user).UserId);
  //   this.glassServiceObj.uploadFile(formdata).subscribe(
  //     (response: any) => {
  //       this.spinner.hide();
  //       if (response === 'Not Uploaded')
  //       {
  //         this.toaster.error('Not Uploaded');
  //       }
  //       else
  //       {
  //         this.toaster.success('Uploaded Successfully');
  //         this.getMonthlyStock(0);
  //       }
  //     },
  //     (error) => {
  //       this.spinner.hide();

  //       this.toaster.error('Error', 'An error has occurred.');
  //     }
  //   );
  // }

  onGridReady(params: any){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onFirstData(params: any){
    // const allColumnIds: any[] = [];
    //   (params.columnApi).getAllColumns().forEach((column: any) => {
    //     allColumnIds.push(column.colId);
    //   });
    //   (params.columnApi).autoSizeColumns(allColumnIds, false);
    this.gridApi.sizeColumnsToFit();
    // this.onFirstDataRendered(params.columnApi);
  }

  getMonthlyStock(n: number): void
  {

    const key = {
      UserId: JSON.parse(localStorage.user).UserId,
      StartDate: moment(this.monthlyStockGroup.value.startDate).format(
          'YYYY-MM-DD'
        ),
        EndDate: moment(this.monthlyStockGroup.value.endDate).format(
        'YYYY-MM-DD'
      ),
       IsHistory: n
                 };
    this.glassServiceObj.getMonthlyStock(key).subscribe(
                  (response: any) => {
                    this.rowMonthlyStock = response.GetMSList;
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                  }
                );
  }
  // exportTemplateAsXLSX(): void {

  //       this.tempData = [{
  //         ItemCode: 'abc',
  //         Length: 0,
  //         Width: 0,
  //         Depth: 0,
  //         BoxType: 'abc',
  //         Stock: 0
  //       }];
  //       // this.tempData = ['EmployeeName', 'EmployeeName'];
  //       this.glassServiceObj.exportAsExcelFile(this.tempData, 'MonthlyStock Template');
  //   }
    exportAsXLSX(): void{
      var params = {
        columnKeys: ['BoxType', 'Length', 'Width', 'Opening', 'Received', 'Consume', 'Stock'],
        fileName: 'Export MonthlyStock'
    };
      this.gridApi.exportDataAsExcel(params);
    }

    dateFormatter(params: any): any {
      return moment(params.value).format('MM/DD/YYYY HH:mm') ;
    }
}
