import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AtHeader } from 'src/app/models/analytical-toolsModels/atHeader';
import { causeEffectModelElement } from 'src/app/models/analytical-toolsModels/cause-effectmodel';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { CauseEffectService } from 'src/app/services/cause-effect.service';
import { environment } from 'src/environments/environment';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
@Component({
  selector: 'app-cause-effect',
  templateUrl: './cause-effect.component.html',
  styleUrls: ['./cause-effect.component.scss'],
})
export class CauseEffectComponent implements OnInit {
  @Input() isAtNcr = false;
  newGroupItemParent!: causeEffectModelElement;
  @Input() _headerData: EventEmitter<any> = new EventEmitter();
  @Output() _emitHeaderData: EventEmitter<any> = new EventEmitter();

  @Input() headerID: any;
  @Input() _permission: any;
  causeEffect: any = [];
  Weigths = [
    { value: 2, checked: true },
    { value: 5, checked: false },
    { value: 8, checked: false },
  ];
  stageName: string = 'Cause & Effect';
  stageId: number = 3;
  breadcrumList!: any;
  isReset: boolean = false;
  spinnerText: string = '';
  headerData: AtHeader = {
    ID: 0,
    Group: '',
    Plant: '',
    Dept: '',
    Team: '',
    TeamLeader: '',
    InitProbStatement: '',
    NoOfM: 6,
    stageId: 3,
    uniqueCode: '',
    CreatedAt: new Date(),
    CreatedBy: '',
  };
  user: any;
  rcaId: number = 0;
  updateStatus: boolean = false;
  permisson!: any;
  IsWrite: boolean = false;
  IsAccess!: any;
  @Input() onSubmit: Observable<any>;
  @Input() causeAndEffects_ = new EventEmitter();

  causeEffectModelColumns: string[] = [
    'MType',
    'Priority',
    'ProbableCause',
    'Weight',
    'ValidationResult',
    'CauseIdentified',
    'Image',
    'Remark',
    'Actions',
  ];
  causeEffectModelDataSource = new MatTableDataSource();
  levelId: number = 0;
  causeSrcData: any = [];
  imageFile: any;
  imageSrc: any;
  // ImageUrl: any;
  blobStorageUrl: any;
  @ViewChild('fileInput') fileInput!: ElementRef;
  ind: any;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  sub: Subscription;
  constructor(
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private analyticalToolsService: AnalyticalToolsService,
    private causeEffectService: CauseEffectService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.permisson = this._permission;
    this.IsWrite = this.permisson.IsWrite;
    // this.ImageUrl = environment.ImageUrl;
    this.blobStorageUrl = environment.O3CoreApiImageUrl;
    this.headerData.ID = this.headerID;
    this.rcaId = this.analyticalToolsService.getRcaId();
    this.getCauseEffectData(this.headerData.ID, '');
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.headerData.CreatedBy = this.user.UserId;
    const userGroup = this.analyticalToolsService.getUserGroup();
    if (userGroup) this.headerData.Group = userGroup;

    this.sub = this.causeAndEffects_.subscribe((event: any) => {
      this.saveCauseEffect(event);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  getCauseValue(val: any) {
    if (val.length > 40) {
      let text = val.substring(0, 40); //cuts to 1024
      let last = text.lastIndexOf(' '); //gets last space (to avoid cutting the middle of a word)
      text = text.substring(0, 40); //cuts from last space (to avoid cutting the middle of a word)
      text = text + ` ...`; //adds (...) at the end to show that it's cut
      return text;
    } else return val;
  }

  getFileTypeName(val: any) {
    if (val && typeof val === 'string') {
      let arr = val.split('.');
      if (arr && arr.length && arr.length != 1)
        return `( ${arr[arr.length - 1]} )`;
      // else if (arr.length == 1) {
      //   arr = val.split(';');
      //   arr = arr[0].split('/');
      //   return `( ${arr[arr.length - 1]} )`;
      // }
      else return ``;
    }
  }

  updateMType(event: number) {
    if (event == 4) {
      const itemsToRemove = this.causeEffect.filter(
        (cause: any) => cause.M == 5 || cause.M == 6
      );
      for (var i = itemsToRemove.length - 1; i >= 0; i--)
        this.causeEffect.splice(itemsToRemove[i].index, 1);
    } else if (event == 5) {
      const causeData = {
        CauseIdentified: 0,
        M: 5,
        Priority: 1,
        ProbableCause: '',
        Remark: '',
        ValidationResult: '',
        displayMType: 'Mother Nature',
        indModule: true,
      };
      const motherNatureCheck = this.causeEffect.find(
        (motherNature: any) => motherNature.M == 5
      );
      if (!motherNatureCheck) this.causeEffect.push(causeData);
      const itemsToRemove = this.causeEffect.filter(
        (cause: any) => cause.M == 6
      );
      for (var i = itemsToRemove.length - 1; i >= 0; i--)
        this.causeEffect.splice(itemsToRemove[i].index, 1);
    } else {
      const motherNatureData = {
        CauseIdentified: 0,
        M: 5,
        Priority: 1,
        ProbableCause: '',
        Remark: '',
        ValidationResult: '',
        displayMType: 'Mother Nature',
        indModule: true,
      };
      const ManagementData = {
        CauseIdentified: 0,
        M: 6,
        Priority: 1,
        ProbableCause: '',
        Remark: '',
        ValidationResult: '',
        displayMType: 'Management',
        indModule: true,
      };
      const motherNatureCheck = this.causeEffect.find(
        (motherNature: any) => motherNature.M == 5
      );
      if (!motherNatureCheck) this.causeEffect.push(motherNatureData);
      const managementCheck = this.causeEffect.find(
        (motherNature: any) => motherNature.M == 6
      );
      if (!managementCheck) this.causeEffect.push(ManagementData);
    }
    this.headerData.NoOfM = event;
    this.setIndex();
    this.setSort();
    this.causeEffectModelDataSource = new MatTableDataSource(this.causeEffect);
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  addRow(index: number, type: string) {
    let obj: any = Object.assign({}, this.causeEffect[index]);
    obj.ValidationResult = '';
    obj.CreatedAt = new Date();
    obj.Remark = '';
    obj.indModule = false;
    obj.ID = 0;
    obj.sub = type == 'sub' ? true : false;
    obj.ProbableCause = obj.fishBoneDataNotInCause ? obj.ProbableCause : '';
    let arr: any;
    if (type == 'sub') {
      const parentObject = this.causeEffect.find(
        (cause: any) => cause.Priority == Math.floor(obj.Priority)
      );
      obj.MType = parentObject.MType;
      obj.sub = true;
      const val = obj.Priority % 1 === 0 ? 1 : 0;
      arr = this.causeEffect.filter((data: any) => {
        return data.M == obj.M && data.Priority < Math.ceil(obj.Priority) + val;
      });
      if (arr.length) {
        var x = arr[arr.length - 1].Priority;
        var decimals = x - Math.floor(x);
        let check = decimals.toFixed(1); //Returns 0.2
        if (check == '0.9') {
          this.toaster.warning('Warning', "You Can't Add More Line");
          return;
        } else {
          obj.Priority = parseFloat(
            (arr[arr.length - 1].Priority + 0.1).toFixed(1)
          );
        }
      } else obj.Priority += 0.1;
    } else {
      delete obj.MType;
      arr = this.causeEffect.filter((data: any) => data.M == obj.M);
      obj.Priority = Math.floor(arr[arr.length - 1].Priority) + 1;
    }
    let spliceIndex;
    if (arr.length) spliceIndex = arr[arr.length - 1].index + 1;
    else spliceIndex = obj.index + 1;
    this.causeEffect.splice(spliceIndex, 0, obj);
    this.setIndex();
    this.setSort();
    this.causeEffectModelDataSource = new MatTableDataSource(this.causeEffect);
  }

  deleteRow(index: number) {
    if (this.causeEffect[index].Priority % 1 === 0) {
      const isHaveSub = this.causeEffect.find(
        (cause: any) =>
          cause.Priority > this.causeEffect[index].Priority &&
          cause.Priority < this.causeEffect[index].Priority + 1
      );
      if (isHaveSub) {
        this.toaster.warning(
          'Warning',
          `Remove sub values for ${this.causeEffect[index].displayMType} on ${this.causeEffect[index].Priority}`
        );
        return;
      }
    }
    this.causeEffect.splice(index, 1);
    let previousM = '';
    let subPriority = 0.1;
    let Priority = 1;
    for (const cause of this.causeEffect) {
      if (cause.M != previousM) {
        subPriority = 0.1;
        Priority = 1;
      }
      if (cause.Priority % 1 !== 0) {
        cause.Priority =
          Math.floor(cause.Priority) +
          parseFloat(subPriority.toFixed(1).split('0')[1]);
        subPriority += 0.1;
      } else {
        cause.Priority = Priority;
        Priority += 1;
      }
      previousM = cause.M;
    }
    this.setIndex();
    this.setSort();
    this.causeEffectModelDataSource = new MatTableDataSource(this.causeEffect);
  }

  setIndex() {
    this.causeEffect.map((cause: any, index: number) => {
      cause.index = index;
      // cause.Priority = Math.floor(cause.Priority);
    });
  }

  setSort() {
    this.causeEffect.sort(function (a: any, b: any) {
      return a.Priority - b.Priority;
    });
    this.causeEffect.sort(function (a: any, b: any) {
      return a.M - b.M;
    });
  }

  getCauseEffectData(headerId: number, reset: any): any {
    this.spinnerText = 'Fetching Cause & Effect Data';
    this.spinner.show();
    this.causeEffectService.getCauseEffectData(headerId).subscribe(
      (res: any) => {
        const url = this.router.url;

        if (
          !url.includes('/at-at-stages') &&
          !url.includes('/at/NcrManagement')
        ) {
          this._emitHeaderData.emit(res.data);
        }
        this.spinner.hide();
        if (res.status) {
          this.causeEffect = [...res.data.causeEffect];
          this.causeSrcData = [...res.data.causeEffect];
          delete res.data.causeEffect;
          if (res.data) {
            this.rcaId = res.data.rcaId;
            this.levelId =
              res && res.data && res.data.stageId ? res.data.stageId : 0;
            delete res.data.rcaId;
            this.headerData = res.data.ID
              ? Object.assign({}, res.data)
              : this.headerData;
          }
          // this.analyticalToolsService.setHeaderId(this.headerData.ID);
          this.analyticalToolsService.setRcaId(this.rcaId);
          this.analyticalToolsService.setLevelId(this.levelId);
          this.analyticalToolsService.setTeamLeaderList(res.data.Team);
          this.updateStatus = res.updateStatus;
          this.analyticalToolsService.setStatus(this.updateStatus);
          for (let ce of this.causeEffect) {
            if (ce.Priority % 1 === 0) ce.sub = false;
            else ce.sub = true;
          }
          this.setIndex();
          this.setSort();
          if (reset == 'reset' && !headerId) this.resetData();
          if (this.causeEffect && this.causeEffect.length) {
            if (res.data.fiveWhy && res.data.fiveWhy.length) {
              for (const ce of this.causeEffect) {
                if (ce.ID) {
                  let isExist = res.data.fiveWhy.find(
                    (fy: any) =>
                      fy &&
                      fy.CauseAndEffectsId &&
                      fy.CauseAndEffectsId.ID == ce.ID
                  );
                  ce.isFiveY = isExist ? true : false;
                }
              }
            }
          }
          this.causeEffectModelDataSource = new MatTableDataSource(
            this.causeEffect
          );
        } else this.toaster.error('Error', res.message);
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  checkProbCauseExist(ProbableCause: string, index: number) {
    let probExist = this.causeEffect.find(
      (cause: any, ind: number) =>
        index != ind && cause.ProbableCause == ProbableCause
    );
    if (probExist) {
      this.toaster.warning('Warning', 'Value already exist');
      this.causeEffect[index].ProbableCause = '';
    }
  }

  updateRemark(index: number) {
    if (this.causeEffect[index].CauseIdentified)
      this.causeEffect[index].Remark = '';
    else {
      if (this.updateStatus && this.causeEffect[index].isFiveY) {
        setTimeout(() => {
          this.causeEffect[index].CauseIdentified = true;
          this.toaster.warning('Warning', `You Can't Turn Off This Toggle`);
          return;
        }, 250);
      }
    }
  }

  saveCauseEffect(e: any): void {
    let event = e.headerData;
    let integrationData = e.integrationData;
    delete event?.stageID;
    delete event?.FyActionFileCount;
    let causeEffectChildren: any = [],
      index = 0,
      statusCount = 0,
      causeOrg: any = [];
    causeOrg = [...this.causeEffect];
    let M1 = this.causeEffect.filter((causeEff: any) => causeEff.M == 1);

    let M2 = this.causeEffect.filter((causeEff: any) => causeEff.M == 2);

    let M3 = this.causeEffect.filter((causeEff: any) => causeEff.M == 3);

    let M4 = this.causeEffect.filter((causeEff: any) => causeEff.M == 4);

    let M5 = this.causeEffect.filter((causeEff: any) => causeEff.M == 5);

    let M6 = this.causeEffect.filter((causeEff: any) => causeEff.M == 6);

    let enteredMvalue = 0;
    let MCount = 0;
    let enteredM1valueCounted = false;
    let enteredM2valueCounted = false;
    let enteredM3valueCounted = false;
    let enteredM4valueCounted = false;
    let enteredM5valueCounted = false;
    let enteredM6valueCounted = false;

    if (M1 && M1.length) {
      for (const m1 of M1) {
        if (
          (m1.ProbableCause ||
            m1.ValidationResult ||
            m1.CauseIdentified ||
            m1.Remark) &&
          !enteredM1valueCounted
        ) {
          enteredMvalue += 1;
          enteredM1valueCounted = true;
        }
      }
      MCount += 1;
    }
    if (M2 && M2.length) {
      for (const m2 of M2) {
        if (
          (m2.ProbableCause ||
            m2.ValidationResult ||
            m2.CauseIdentified ||
            m2.Remark) &&
          !enteredM2valueCounted
        ) {
          enteredMvalue += 1;
          enteredM2valueCounted = true;
        }
      }
      MCount += 1;
    }
    if (M3 && M3.length) {
      for (const m3 of M3) {
        if (
          (m3.ProbableCause ||
            m3.ValidationResult ||
            m3.CauseIdentified ||
            m3.Remark) &&
          !enteredM3valueCounted
        ) {
          enteredMvalue += 1;
          enteredM3valueCounted = true;
        }
      }
      MCount += 1;
    }
    if (M4 && M4.length) {
      for (const m4 of M4) {
        if (
          (m4.ProbableCause ||
            m4.ValidationResult ||
            m4.CauseIdentified ||
            m4.Remark) &&
          !enteredM4valueCounted
        ) {
          enteredMvalue += 1;
          enteredM4valueCounted = true;
        }
      }
      MCount += 1;
    }
    if (M5 && M5.length) {
      for (const m5 of M5) {
        if (
          (m5.ProbableCause ||
            m5.ValidationResult ||
            m5.CauseIdentified ||
            m5.Remark) &&
          !enteredM5valueCounted
        ) {
          enteredMvalue += 1;
          enteredM5valueCounted = true;
        }
      }
      MCount += 1;
    }
    if (M6 && M6.length) {
      for (const m6 of M6) {
        if (
          (m6.ProbableCause ||
            m6.ValidationResult ||
            m6.CauseIdentified ||
            m6.Remark) &&
          !enteredM6valueCounted
        ) {
          enteredMvalue += 1;
          enteredM6valueCounted = true;
        }
      }
      MCount += 1;
    }

    // if (enteredMvalue < 1) {
    //   this.toaster.warning('Warning', `Please Fill Atleast 1 Type Of M`)
    //   return;
    // }
    // else {
    if (enteredM1valueCounted) {
      for (const m1 of M1) {
        if (!m1.ProbableCause) {
          this.toaster.warning(
            'Warning',
            `Probable Cause doesn't empty on ${m1.displayMType} priority ${m1.Priority}`
          );
          return;
        }
        // if (!m1.ValidationResult) {
        //   this.toaster.warning('Warning', `Validation Result doesn't empty on ${m1.displayMType} priority ${m1.Priority}`)
        //   return;
        // }
        // if (!m1.CauseIdentified && !m1.Remark) {
        //   this.toaster.warning('Warning', `Remark doesn't empty on ${m1.displayMType} priority ${m1.Priority}`)
        //   return;
        // }
      }
      let checkStatus = M1.every(
        (mtype1: any) =>
          mtype1.ProbableCause &&
          mtype1.ValidationResult &&
          (mtype1.CauseIdentified || mtype1.Remark)
      );
      if (checkStatus) statusCount += 1;
    } else {
      for (const m1 of M1) {
        let ind = this.causeEffect.findIndex(
          (causeEff: any) =>
            causeEff.M == m1.M && causeEff.Priority == m1.Priority
        );
        this.causeEffect.splice(ind, 1);
      }
    }
    if (enteredM2valueCounted) {
      for (const m2 of M2) {
        if (!m2.ProbableCause) {
          this.toaster.warning(
            'Warning',
            `Probable Cause doesn't empty on ${m2.displayMType} priority ${m2.Priority}`
          );
          return;
        }
        //   if (!m2.ValidationResult) {
        //     this.toaster.warning('Warning', `Validation Result doesn't empty on ${m2.displayMType} priority ${m2.Priority}`)
        //     return;
        //   }
        // if (!m2.CauseIdentified && !m2.Remark) {
        //   this.toaster.warning('Warning', `Remark doesn't empty on ${m2.displayMType} priority ${m2.Priority}`)
        //   return;
        // }
      }
      let checkStatus = M2.every(
        (mtype2: any) =>
          mtype2.ProbableCause &&
          mtype2.ValidationResult &&
          (mtype2.CauseIdentified || mtype2.Remark)
      );
      if (checkStatus) statusCount += 1;
    } else {
      for (const m2 of M2) {
        let ind = this.causeEffect.findIndex(
          (causeEff: any) =>
            causeEff.M == m2.M && causeEff.Priority == m2.Priority
        );
        this.causeEffect.splice(ind, 1);
      }
    }
    if (enteredM3valueCounted) {
      for (const m3 of M3) {
        if (!m3.ProbableCause) {
          this.toaster.warning(
            'Warning',
            `Probable Cause doesn't empty on ${m3.displayMType} priority ${m3.Priority}`
          );
          return;
        }
        //   if (!m3.ValidationResult) {
        //     this.toaster.warning('Warning', `Validation Result doesn't empty on ${m3.displayMType} priority ${m3.Priority}`)
        //     return;
        //   }
        // if (!m3.CauseIdentified && !m3.Remark) {
        //   this.toaster.warning('Warning', `Remark doesn't empty on ${m3.displayMType} priority ${m3.Priority}`)
        //   return;
        // }
      }
      let checkStatus = M3.every(
        (mtype3: any) =>
          mtype3.ProbableCause &&
          mtype3.ValidationResult &&
          (mtype3.CauseIdentified || mtype3.Remark)
      );
      if (checkStatus) statusCount += 1;
    } else {
      for (const m3 of M3) {
        let ind = this.causeEffect.findIndex(
          (causeEff: any) =>
            causeEff.M == m3.M && causeEff.Priority == m3.Priority
        );
        this.causeEffect.splice(ind, 1);
      }
    }
    if (enteredM4valueCounted) {
      for (const m4 of M4) {
        if (!m4.ProbableCause) {
          this.toaster.warning(
            'Warning',
            `Probable Cause doesn't empty on ${m4.displayMType} priority ${m4.Priority}`
          );
          return;
        }
        //   if (!m4.ValidationResult) {
        //     this.toaster.warning('Warning', `Validation Result doesn't empty on ${m4.displayMType} priority ${m4.Priority}`)
        //     return;
        //   }
        // if (!m4.CauseIdentified && !m4.Remark) {
        //   this.toaster.warning('Warning', `Remark doesn't empty on ${m4.displayMType} priority ${m4.Priority}`)
        //   return;
        // }
      }
      let checkStatus = M4.every(
        (mtype4: any) =>
          mtype4.ProbableCause &&
          mtype4.ValidationResult &&
          (mtype4.CauseIdentified || mtype4.Remark)
      );
      if (checkStatus) statusCount += 1;
    } else {
      for (const m4 of M4) {
        let ind = this.causeEffect.findIndex(
          (causeEff: any) =>
            causeEff.M == m4.M && causeEff.Priority == m4.Priority
        );
        this.causeEffect.splice(ind, 1);
      }
    }
    if (enteredM5valueCounted) {
      for (const m5 of M5) {
        if (!m5.ProbableCause) {
          this.toaster.warning(
            'Warning',
            `Probable Cause doesn't empty on ${m5.displayMType} priority ${m5.Priority}`
          );
          return;
        }
        //   if (!m5.ValidationResult) {
        //     this.toaster.warning('Warning', `Validation Result doesn't empty on ${m5.displayMType} priority ${m5.Priority}`)
        //     return;
        //   }
        // if (!m5.CauseIdentified && !m5.Remark) {
        //   this.toaster.warning('Warning', `Remark doesn't empty on ${m5.displayMType} priority ${m5.Priority}`)
        //   return;
        // }
      }
      let checkStatus = M5.every(
        (mtype5: any) =>
          mtype5.ProbableCause &&
          mtype5.ValidationResult &&
          (mtype5.CauseIdentified || mtype5.Remark)
      );
      if (checkStatus) statusCount += 1;
    } else {
      for (const m5 of M5) {
        let ind = this.causeEffect.findIndex(
          (causeEff: any) =>
            causeEff.M == m5.M && causeEff.Priority == m5.Priority
        );
        this.causeEffect.splice(ind, 1);
      }
    }
    if (enteredM6valueCounted) {
      for (const m6 of M6) {
        if (!m6.ProbableCause) {
          this.toaster.warning(
            'Warning',
            `Probable Cause doesn't empty on ${m6.displayMType} priority ${m6.Priority}`
          );
          return;
        }
        //   if (!m6.ValidationResult) {
        //     this.toaster.warning('Warning', `Validation Result doesn't empty on ${m6.displayMType} priority ${m6.Priority}`)
        //     return;
        //   }
        // if (!m6.CauseIdentified && !m6.Remark) {
        //   this.toaster.warning('Warning', `Remark doesn't empty on ${m6.displayMType} priority ${m6.Priority}`)
        //   return;
        // }
      }
      let checkStatus = M6.every(
        (mtype6: any) =>
          mtype6.ProbableCause &&
          mtype6.ValidationResult &&
          (mtype6.CauseIdentified || mtype6.Remark)
      );
      if (checkStatus) statusCount += 1;
    } else {
      for (const m6 of M6) {
        let ind = this.causeEffect.findIndex(
          (causeEff: any) =>
            causeEff.M == m6.M && causeEff.Priority == m6.Priority
        );
        this.causeEffect.splice(ind, 1);
      }
    }
    // }

    // for (const cause of this.causeEffect) {
    //   if (!cause.ProbableCause) {
    //     this.toaster.warning('Warning', `Probable Cause doesn't empty on ${cause.displayMType} priority ${cause.Priority}`)
    //     return;
    //   }
    //   if (!cause.ValidationResult) {
    //     this.toaster.warning('Warning', `Validation Result doesn't empty on ${cause.displayMType} priority ${cause.Priority}`)
    //     return;
    //   }
    //   if (!cause.CauseIdentified && !cause.Remark) {
    //     this.toaster.warning('Warning', `Remark doesn't empty on ${cause.displayMType} priority ${cause.Priority}`)
    //     return;
    //   }
    //   index++;
    // }

    index = 0;
    for (const cause of this.causeEffect) {
      delete cause.isFiveY;
      delete cause.FileSrc;
      if (!cause.sub && cause.Priority % 1 === 0) {
        let children = [];
        // if (this.rcaId) {
        //   children = this.causeEffect.filter((causeEff: any) => cause.MType == causeEff.MType && cause.Priority < Math.ceil(causeEff.Priority) && causeEff.sub);
        // }
        // else
        children = this.causeEffect.filter(
          (causeEff: any) =>
            cause.M == causeEff.M &&
            cause.Priority == Math.floor(causeEff.Priority) &&
            causeEff.sub
        );

        if (children && children.length) {
          children.map((child: any) => {
            child.parentIndex = index;
            causeEffectChildren.push(child);
          });
        }
        index++;
      }
      // else{
      //   this.causeEffect.splice(index, 1);
      // }

      if (cause.Priority % 1 !== 0 && cause.CauseIdentified) {
        let val = Math.floor(cause.Priority);
        let obj = this.causeEffect.find(
          (causeEff: any) => causeEff.Priority == val && causeEff.M == cause.M
        );
        if (!obj.CauseIdentified) {
          this.toaster.warning(
            'Warning',
            `Row ${obj.displayMType} priority ${obj.Priority} On Must Be Identified`
          );
          return;
        }
      }
    }
    // let i = 0;
    // for(i=0;i<this.causeEffect.length;i++){
    //   if (!this.causeEffect[i].sub && this.causeEffect[i].Priority % 1 === 0) {
    //     i++;
    //   }
    //   else {
    //     this.causeEffect.splice(i, 1);
    //     i--;
    //   }
    // }
    for (const cause of this.causeEffect) {
      let obj = this.causeEffect.findIndex(
        (causeEff: any) => causeEff.Priority % 1 !== 0
      );
      if (obj != -1) {
        this.causeEffect.splice(obj, 1);
      }
      delete cause.fishBoneDataNotInCause;
      delete cause.fiveWhy;
    }
    delete event.fiveWhy;
    delete event.uniqueCode;
    // const data = {
    //   header: event,
    //   data: this.causeEffect,
    //   causeEffectChildren: causeEffectChildren
    // };

    if (event.stageId == 3) {
      if (statusCount == 0) {
        if (
          enteredM1valueCounted ||
          enteredM2valueCounted ||
          enteredM3valueCounted ||
          enteredM4valueCounted ||
          enteredM5valueCounted ||
          enteredM6valueCounted
        )
          event.StatusCauseEffect = 'InProgress';
        else event.StatusCauseEffect = 'Open';
      } else if (statusCount < 4) event.StatusCauseEffect = 'InProgress';
      else event.StatusCauseEffect = 'Completed';

      // event.StatusCauseEffect = statusCount < 4 ? 'InProgress' : 'Completed';
    } else {
      if (statusCount == 0) {
        if (
          enteredM1valueCounted ||
          enteredM2valueCounted ||
          enteredM3valueCounted ||
          enteredM4valueCounted ||
          enteredM5valueCounted ||
          enteredM6valueCounted
        )
          event.StatusCauseEffect = 'InProgress';
        else event.StatusCauseEffect = 'Open';
      } else if (statusCount < MCount) event.StatusCauseEffect = 'InProgress';
      else event.StatusCauseEffect = 'Completed';

      // event.StatusCauseEffect = statusCount < MCount ? 'InProgress' : 'Completed';
    }

    // if (event.stageId == 3)
    //   event.StatusCauseEffect = statusCount < 4 ? 'InProgress' : 'Completed';
    // else
    //   event.StatusCauseEffect = statusCount < MCount ? 'InProgress' : 'Completed';
    let iCount = 0;
    event.stageId = this.levelId ? this.levelId : this.stageId;
    const formData = new FormData();
    for (const file of this.causeEffect) {
      if (file.UploadFile && typeof file.UploadFile === 'object') {
        formData.append('UploadFile', file.UploadFile);
        file.UploadFile = true;
        iCount++;
      }
      if (file.UploadDoc && typeof file.UploadDoc === 'object') {
        formData.append('UploadDoc', file.UploadDoc);
        file.UploadDoc = true;
        iCount++;
      }
    }
    for (const file of causeEffectChildren) {
      if (file.UploadFile && typeof file.UploadFile === 'object') {
        formData.append('UploadFile', file.UploadFile);
        file.UploadFile = true;
        iCount++;
      }
      if (file.UploadDoc && typeof file.UploadDoc === 'object') {
        formData.append('UploadDoc', file.UploadDoc);
        file.UploadDoc = true;
        iCount++;
      }
    }
    event.CauseFileCount = iCount;
    formData.append('UploadFile', event.UploadFile);
    formData.append('header', JSON.stringify(event));
    formData.append('data', JSON.stringify(this.causeEffect));
    formData.append('causeEffectChildren', JSON.stringify(causeEffectChildren));

    let route = this.router.url;
    if (!route.includes('at/at-stages')) {
      formData.append('integrationData', JSON.stringify(integrationData));
    }

    this.spinnerText = this.updateStatus
      ? 'Updating Cause & Effect Data'
      : 'Creating Cause & Effect Data';
    this.spinner.show();
    if (this.headerData.ID && this.updateStatus) {
      this.causeEffectService.updateCauseEffectData(formData).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toaster.success('Success', res.message);
          this.onSuccess.emit('causeAndEffects');
          this.analyticalToolsService.setRcaId(res.data.rcaId);
          this._emitHeaderData.emit(res.data);

          if (res.data.rcaId) {
            this.analyticalToolsService.setHeaderId(res.data.headerId);
            // this.router.navigate(['/at/fivewhy']);
          } else {
            this.analyticalToolsService.setHeaderId(res.data.headerId);
            this.analyticalToolsService.setStatus(this.updateStatus);
          }
          this.onSuccess.emit('causeAndEffects');
          this.headerData.ID = this.headerID;
          this.getCauseEffectData(this.headerData.ID, '');
        },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      this.causeEffectService.addCauseEffectData(formData).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toaster.success('Success', res.message);
          this._emitHeaderData.emit(res.data);

          this.analyticalToolsService.setRcaId(res.data.rcaId);
          if (res.data.rcaId) {
            this.analyticalToolsService.setHeaderId(res.data.headerId);
            // this.router.navigate(['/at/fivewhy']);
          } else {
            this.analyticalToolsService.setHeaderId(res.data.headerId);
            this.updateStatus = true;
            this.analyticalToolsService.setStatus(this.updateStatus);
            // this.resetCauseData(res);
          }
          this.headerData.ID = res.data.headerId;
          this.onSuccess.emit('causeAndEffects');

          this.getCauseEffectData(this.headerData.ID, '');
        },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  resetCauseData(res: any) {
    this.headerData = {
      ID: 0,
      Group: '',
      Plant: '',
      Dept: '',
      Team: '',
      TeamLeader: '',
      InitProbStatement: '',
      NoOfM: 6,
      stageId: 3,
      CreatedAt: new Date(),
      uniqueCode: '',
      CreatedBy: this.user.UserId,
    };
    this.updateStatus = false;
    // this.causeEffect = res && res.data && res.data.causeEffect && res.data.causeEffect.length ? [...res.data.causeEffect] : [...this.causeSrcData];
    this.setIndex();
    this.setSort();
    // for(const cs of this.causeEffect){
    //   cs.CauseIdentified = false;
    //   cs.ProbableCause = '';
    //   cs.ValidationResult = '';
    // }
    // this.causeEffectModelDataSource = new MatTableDataSource(this.causeEffect);
    this.isReset = true;
    this.analyticalToolsService.setHeaderId(this.headerData.ID);
  }

  resetData() {
    this.headerData = {
      ID: 0,
      Group: '',
      Plant: '',
      Dept: '',
      Team: '',
      TeamLeader: '',
      InitProbStatement: '',
      NoOfM: 6,
      stageId: 3,
      uniqueCode: '',
      CreatedAt: new Date(),
      CreatedBy: this.user.UserId,
    };
    this.isReset = true;
    this.analyticalToolsService.setRca(false);
    this.analyticalToolsService.setRcaId(0);
    this.analyticalToolsService.setHeaderId(0);
    this.analyticalToolsService.setFilteredIndex(0);
    this.analyticalToolsService.setFilteredData([]);
    this.analyticalToolsService.setTeamLeaderList([]);
    this.getCauseEffectData(0, '');
  }

  readFile(event: any, type: any) {
    this.imageFile = event.target.files[0];
    if (type == 'pic') this.causeEffect[this.ind].UploadFile = this.imageFile;
    else this.causeEffect[this.ind].UploadDoc = this.imageFile;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        if (type == 'pic') this.causeEffect[this.ind].FileSrc = this.imageSrc;
        else this.causeEffect[this.ind].DocSrc = this.imageSrc;
      };
    }
  }

  openFile(i: any, type: any) {
    if (type == 'pic') this.fileInput.nativeElement.click();
    // else
    //   this.fileInputFile.nativeElement.click()
    this.ind = i;
  }

  deleteFile(i: any, type: any) {
    if (type == 'pic') {
      this.causeEffect[i].FileSrc = '';
      this.causeEffect[i].UploadFile = '';
    } else {
      this.causeEffect[i].DocSrc = '';
      this.causeEffect[i].UploadDoc = '';
    }
    // this.fiveWhy[this.ind].UploadFile = '';
  }

  imageZoomModal(i: any): void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      height: '90%',
      width: '75%',
      data:
        this.causeEffect[i].FileSrc ||
        this.blobStorageUrl + this.causeEffect[i].UploadFile,
    });
    // this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  setSearchData(event: any) {
    if (event && event.causeAndEffects && event.causeAndEffects.length) {
      this.headerData = event;
      // if (event.StatusCycle && event.StatusCycle == 'Completed') {
      //   if (event.stageId == 1)
      //     this.toaster.info('Info', 'This Cycle Has Been Completed From 6W2H');
      //   else if (event.stageId == 2)
      //     this.toaster.info('Info', 'This Cycle Has Been Completed From Fishbone');
      //   else if (event.stageId == 3)
      //     this.toaster.info('Info', 'This Cycle Has Been Completed From Cause & Effect');
      //   else if (event.stageId == 4)
      //     this.toaster.info('Info', 'This Cycle Has Been Completed From 5Why');
      // }
      // else {
      if (event.stageId == 1) {
        if (
          (event.Status6W2H && event.Status6W2H == 'InProgress') ||
          !event.Status6W2H ||
          event.Status6W2H == 'Open'
        ) {
          this.router.navigate(['/at/sixwtwoh']);
        } else if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
          this.getCauseEffectData(event.ID, '');
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/sixwtwoh']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 6W2H');
        }
      } else if (event.stageId == 2) {
        if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
          this.getCauseEffectData(event.ID, '');
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fishbone']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Fishbone'
          );
        }
      } else if (event.stageId == 3) {
        if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
          this.getCauseEffectData(event.ID, '');
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/causeEffect']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Cause & Effect'
          );
        }
      } else if (event.stageId == 4) {
        if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fivewhy']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 5Why');
        }
      }
      // this.toaster.info('Info', 'Cause & Effect Data Not Found For This Header ID');
      // }
      // this.getCauseEffectData(this.headerData.ID, '');

      // this.causeEffect = [...event.causeAndEffects];
      // this.updateStatus = true;
      // this.rcaId = event.rcaId;
      // delete event.causeAndEffects;
      // delete event.fishBone;
      // delete event.fiveWhy;
      // delete event.rca;
      // delete event.rcaId;
      // delete event.swth;
      // this.headerData = event;
      // this.analyticalToolsService.setHeaderId(this.headerData.ID);
      // this.analyticalToolsService.setStatus(this.updateStatus);
      // this.analyticalToolsService.setLevelId(this.levelId);
      // this.analyticalToolsService.setRcaId(this.rcaId);
      // this.analyticalToolsService.setRca(false);
      // this.headerData.CreatedBy = this.user.UserId;
      // for (let ce of this.causeEffect) {
      //   if (ce.Priority % 1 === 0)
      //     ce.sub = false;
      //   else
      //     ce.sub = true;
      // }
      // this.setIndex();
      // this.setSort();
      // this.causeEffectModelDataSource = new MatTableDataSource(this.causeEffect);
    } else {
      if (event.stageId == 1) {
        if (
          (event.Status6W2H && event.Status6W2H == 'InProgress') ||
          !event.Status6W2H ||
          event.Status6W2H == 'Open'
        ) {
          this.router.navigate(['/at/sixwtwoh']);
        } else if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
          this.getCauseEffectData(event.ID, '');
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/sixwtwoh']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 6W2H');
        }
      } else if (event.stageId == 2) {
        if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
          this.getCauseEffectData(event.ID, '');
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fishbone']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Fishbone'
          );
        }
      } else if (event.stageId == 3) {
        if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
          this.getCauseEffectData(event.ID, '');
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/causeEffect']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Cause & Effect'
          );
        }
      } else if (event.stageId == 4) {
        if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fivewhy']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 5Why');
        }
      }
      // this.toaster.info('Info', 'Cause & Effect Data Not Found For This Header ID');
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
