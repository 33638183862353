<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul> 
    <div class="tab-content-top-box">
      <h1 class="main-title">Customer Complaint Management</h1>
    </div>
  </div>


    <div class="wrapper-box wrapper-box-shadow grid-container">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

      <form [formGroup]="CCRFilterForm">
        <!-- <div  class="d-flex justify-content-between align-items-center">
         
        </div> -->
        <div class="content-top-row ">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label >Group</mat-label>
            <mat-select formControlName="group"  (selectionChange)="onChangeGroup($event)" required>                
              <mat-option *ngFor="let group of groups" [value]="group.TypeId" >{{group.TypeName}}</mat-option>             
            </mat-select>
            <mat-error >Please select group</mat-error>
          </mat-form-field>
          <mat-form-field  appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label >Select Plant</mat-label>
            <mat-select formControlName="plant" required  (selectionChange)="onChangePlant($event)">                
              <mat-option *ngFor="let plant of plants" [value]="plant.DeptId">
                {{ plant.DeptDesc }}
              </mat-option>           
            </mat-select>
            <mat-error >Please select plant</mat-error>
          </mat-form-field>
          <mat-form-field
          appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Select Customer Name</mat-label>
            <mat-select   formControlName="customer" placeholder="Department" panelClass="testClass">
              <mat-option [value]="0">All</mat-option>
              <mat-option *ngFor="let customer of Customers" [value]="customer.customerName">
                {{customer.customerName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field appearence="fill"  class="top-row-first-filed-item top-row-first-filed-item-order-processing" >
            <mat-label>Unit</mat-label>
            <mat-select formControlName="machine">
              <mat-option *ngFor="let k of units" [value]="k.puId">
                {{ k.puDesc }}</mat-option>
            </mat-select>
          </mat-form-field> -->
          <div class="inputField">
            <nz-date-picker class="calender-input"  id="startPicker" formControlName="start" nzFormat="dd/MM/yyyy HH:mm"
            [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
            <span class="floating-text">Start Date</span>   
          </div> 
          <!-- <mat-form-field
          appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <input
              matInput
              formControlName="start" readonly ="true"
              [ngxMatDatetimePicker]="startPicker"
              placeholder="Start Date" required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(startPicker)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #startPicker> </ngx-mat-datetime-picker>
          </mat-form-field> -->
        </div>
        <div class="content-top-row content-top-row-order-processing">
          <div class="inputField">
            <nz-date-picker class="calender-input"  id="endPicker" formControlName="end" nzFormat="dd/MM/yyyy HH:mm"
            [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
            <span class="floating-text">End Date</span>   
          </div>     
          <!-- <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <input
            formControlName="end" readonly ="true"
              matInput
              [ngxMatDatetimePicker]="endPicker"
              placeholder="End Date" required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(endPicker)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #endPicker> </ngx-mat-datetime-picker>
          </mat-form-field> -->
          <button mat-mini-fab mat-button class="search-icon-btn"
          (click)="onSearchClick()"
          pTooltip="Filter" tooltipPosition="top"
          style="margin-left: 20px;">
          <i class="fa fa-search" style="color: #fff"></i>
        </button>

        </div>
          
      </form>
    <ag-grid-angular
      style="width: 100%; height: 25vw"
      class="ag-theme-alpine"
      
      [rowData]="dataArray"
      [columnDefs]="columnDefs" (cellClicked)="cellClicked($event,false)"
      (cellClicked)="imageZoomModal($event)"  [gridOptions]="gridOptions" 
      (gridReady)="onGridReady($event)"   (cellValueChanged)="onCellValueChanged($event)"
      [icons]= "icons" [pagination]= "true" [paginationPageSize]="paginationPageSize">
    </ag-grid-angular>


    </div>

    <!-- <ag-grid-angular
    #agGrid
    style="width: 100%; height: 300px; margin-top: 30px;"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs1"
    [rowData]="rowData1"
    [gridOptions]="gridOptions"
  ></ag-grid-angular> -->

  <ng-template #imageZoomModel>
    <div style="display: flex;justify-content: flex-end;margin-bottom: 20px;">
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close" >
      <i class="fa fa-times" style="color: #fff"></i>
    </button>
    </div>
    <!-- <img  [src]="currentImagePath !== 'includes('/null')' ? currentImagePath : 'assets/images/no-image.png' " style="height: 78%;width: 100%; overflow: hidden;" /> -->
    <img [src]="currentImagePath" style="height: 98%;width: 100%; overflow: hidden;" />
    
  
  </ng-template>
  
  
  <ng-template #displayRoleModal>
    <form [formGroup]="NoteForm">
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>Add Notes</h2>

          <button
            mat-mini-fab
            mat-button
            mat-dialog-close
            color="warn"
            title="close"
            (click)="onSearchClick()"
          >
            <i class="fa fa-times" style="color: #fff"></i>

          </button>
        </div>
      </div>

      <mat-dialog-content>


        <mat-form-field appearance="fill" class="field-fill inputs" style="width: 400px;"  >
          <mat-label>Notes</mat-label>
          <textarea matInput  formControlName="note" ></textarea>
        </mat-form-field>
        <button   type="submit" (click)= "saveNotes()" class="icon-btn" style="margin-left: 10px;" matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above" >
          <img src="assets/images/save.png" alt="" width="20px" height="20px">
        </button>
  

      </mat-dialog-content>
      
      <!-- <div mat-dialog-actions style="float: right; margin-top: 10px">
        <button type="submit" (click)= "saveNotes()" color="accent" mat-flat-button cdkFocusInitial>
          Save & Close
        </button>
      </div> -->
    </form>
  </ng-template>

 

  
  <ng-template #displayRoleModal1>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>Action Detail</h2>
        <button
              mat-mini-fab
              mat-button
              mat-dialog-close
              color="warn"
              title="close"
            >
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
      </div>
    </div>
    <mat-dialog-content>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  
      <form [formGroup]="ActionForm" (ngSubmit)="formMode ? saveUserAction() : updateccrAction()">
        <div class="wrapper-box wrapper-box-shadow ">
        
          <div class="content-top-row "  >
            <!-- <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 20%;">
              <mat-label >Group</mat-label>
              <mat-select formControlName="group"  required>                
                <mat-option *ngFor="let group of groups" [value]="group.TypeId" >{{group.TypeName}}</mat-option>             
              </mat-select>
              <mat-error >Please select group</mat-error>
            </mat-form-field> -->
            <mat-form-field style="width: 20%;" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Analytical Tool</mat-label>
              <mat-select formControlName="toolname"  (selectionChange)="onToolChange($event)" required>
                <mat-option *ngFor="let toollist of toolslist" [value]="toollist.toolId">{{toollist.name}}</mat-option>
              </mat-select>
            </mat-form-field>
                 <button type="button"  *ngIf="hide" style="background-color: #fff; border: #fff;">
                 <a href="{{toolPath}}{{toolName}}" target="_blank" mat-flat-button color="accent" type="button" style="margin-left: 24px;" class="btn-accent" [download] *ngIf="toolPath !== undefined && toolName !== undefined && toolPath !== null && toolName !== null && toolName !== ''&& !ActionDetail" > Execute Tool</a>
                </button>
        </div>
        <div class="content-top-row" style="display: flex; ">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 20%;">
            <mat-label>CCR Detail</mat-label>
            <input matInput  formControlName="desc" autocomplete="off"  required/>
          </mat-form-field>
          <div class="inputField">
            <nz-date-picker class="calender-input"  id="startDate" formControlName="date" (ngModelChange)="selectdate()" [nzDisabledDate]="disabledDate" nzFormat="dd/MM/yyyy"
             [nzInputReadOnly]="true" ></nz-date-picker>
            <span class="floating-text">Action Target Date</span>   
          </div>
          <!-- <mat-form-field   appearence="fill"  class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 20%;"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label>Action Target Date</mat-label>
        <input matInput formControlName="date" readonly="true" [matDatepicker]="startDate" (dateChange)="selectdate()" placeholder="Start Date">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field> -->

      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" >
        <mat-select  formControlName="owner" placeholder="Accountable" multiple>
          <mat-select-filter
          [placeholder]="'Search Accountable'"
          [array]="raci"
          (filteredReturn)="filterdraci = $event"
          [displayMember]="'fullName'"
          ></mat-select-filter>
          <mat-option *ngFor="let Own of filterdraci" [value]="Own.EmpInfo_Id">{{Own.fullName}}</mat-option>
        </mat-select>  
      </mat-form-field>

      <!-- <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 20%;">
        <mat-label>Accountable</mat-label>
        <mat-select formControlName="owner"  multiple >    
          <mat-select-filter [placeholder]="'Search'" [color]="'#F9FAFF'"  [array]="raci" (filteredReturn)="filterdraci=$event" [displayMember]="'fullName'"
          [displayMember]="'fullName'">
        </mat-select-filter>                   
          <mat-option *ngFor="let field of filterdraci" value="{{field.accountableUser}}" [value]="field.userId" [class.hide]="!isFiltered(field)">{{field.fullName}}</mat-option>             
        </mat-select>      
      </mat-form-field> -->
           
               
                 <button   type="submit" class="icon-btn" style="margin-left: 10px;" matTooltip="Update" matTooltipClass="custom-tooltip" matTooltipPosition="above" *ngIf="!formMode">
                  <img src="assets/images/edit.png" alt="" width="20px" height="20px">
                </button>
                <button   type="submit" class="icon-btn" style="margin-left: 10px;" matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above" *ngIf="formMode">
                  <img src="assets/images/save.png" alt="" width="20px" height="20px">
                </button>
          </div>
  
        </div>
      
      </form>
    <div class="content-row-flex-both-end">
      <!--table-->
      <table mat-table [dataSource]="ccrActionDataSource" class="basic-table plant-model-table" matSort>
        <!-- userManagerActions Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef><p class="header-text">Actions</p></th>
          <td
            mat-cell
            *matCellDef="let element"
            class="row-action-btns-column">
            <div class="row-action-btns">
              <button
              mat-icon-button
              class="edit-btn"
              aria-label="Edit Row" 
              (click)="getccractionbyId(element)"
              matTooltip="Edit" matTooltipClass="custom-tooltip" matTooltipPosition="above"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                  fill="white"
                />
              </svg>
            </button>
              <button 
                mat-icon-button
                class="tree-view-btn"
                aria-label="View Row Tree"
                (click)="deleteccrAction(element.actionId)"
                matTooltip="Delete" matTooltipClass="custom-tooltip" matTooltipPosition="above"
              >
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                    fill="#F85439"
                  />
                  <circle cx="17" cy="17" r="14" fill="#F85439" />
                  <path
                    d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                    fill="white"
                  />
                </svg>
              </button>
             
              <!-- <svg width="15" height="15" (click)="opencategorymappingModal(element.Id)" viewBox="0 0 10 9" fill="#10ce9c" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.18143 5.4235H5.6525V8.9235H4.47619V5.4235H0.947266V4.25684H4.47619V0.756836H5.6525V4.25684H9.18143V5.4235Z"
                  fill="#10ce9c" />
              </svg> -->
            </div>
          </td>
        </ng-container>

        <!-- Start Time Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Description</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.actionDesc}}</td>
        </ng-container>
  
        <!-- End Time Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Action Target Date</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.actionDate | date: "y/MM/dd" }}</td>
        </ng-container>
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Owner</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.responsibleName}}</td>
        </ng-container>
        <ng-container matColumnDef="tools">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Tools</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.toolName}}</td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="ccrDetailColumns"
        ></tr> 
        <tr
          mat-row
          *matRowDef="let row; columns: ccrDetailColumns"
        ></tr>
        <tr class="mat-row" *matNoDataRow style="text-align: center;">
          <td class="mat-cell" colspan="7">No data Found</td>
        </tr>
      </table>
    </div>
    <mat-paginator
    class="basic-paginataor"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons></mat-paginator>
     
        
    </mat-dialog-content>
  </ng-template>


  <ng-template #capaActionHistoryModel>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2> Implement CAPA</h2>
        <button
              mat-mini-fab
              mat-button
              mat-dialog-close
              color="warn"
              title="close"
            >
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
      </div>
    </div>
    <mat-dialog-content>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  
    <div class="">
      <table mat-table [dataSource]="ccrActionDataSource" class="basic-table plant-model-table" matSort>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Description</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.actionDesc}}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Date</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.actionDate | date: "y/MM/dd" }}</td>
        </ng-container>
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Owner</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.responsibleName}}</td>
        </ng-container>
        <ng-container matColumnDef="tools">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Tools</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.toolName}}</td>
        </ng-container>
  
        <ng-container matColumnDef="capaData">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Capa Date</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <!-- <div class="inputField">
              <nz-date-picker class="calender-input"  id="startDate" [(ngModel)]="OnChangeDate($event,element)" [value]="element.implementCapaDate" [nzDisabledDate]="disabledDate" nzFormat="dd/MM/yyyy"
               [nzInputReadOnly]="true" ></nz-date-picker>
              <span class="floating-text">Start Date</span>   
            </div> -->
              <mat-form-field   appearence="fill"  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="startDate" readonly  placeholder="Start Date" (dateInput)="OnChangeDate($event,element)" [value]="element.implementCapaDate">
             <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
          </td>
  
        </ng-container>
  
        <ng-container matColumnDef="isChecked">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Is Checked</p>
          </th>
          <td mat-cell *matCellDef="let element"> <input type="checkbox" (change)="ccrActionCheckbox($event,element)" [checked]="element.isChecked"></td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="capaActionColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: capaActionColumns"
        ></tr>
        <tr class="mat-row" *matNoDataRow style="text-align: center;">
          <td class="mat-cell" colspan="7">No data Found</td>
        </tr>
      </table>
      
  
    </div>
    
    <mat-paginator
    class="basic-paginataor"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons></mat-paginator>

    </mat-dialog-content>
    <div mat-dialog-actions style="float: right; margin-top: 10px">
      <!-- <button  type="submit" (click)= "ccrcapaaction()" *ngIf="hideButton==true"   color="accent" mat-flat-button cdkFocusInitial>
        Save & Close
      </button> -->
      <button type="submit" (click)="ccrcapaaction()" class="icon-btn" *ngIf="hideButton==true"  matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"style="margin-top:30px;">
        <img src="assets/images/save.png" alt="" width="20px" height="20px">
      </button>
    </div>
  </ng-template>
  

</div>
