import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { ChecklistService } from 'src/app/services/checklist.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { BtnRendererCheckListType } from 'src/app/btn-RenderercheckListType';
import { GridConfiguration } from 'src/app/interface/grid-model';
import { GridService } from 'src/app/services/grid.service';
@Component({
  selector: 'app-check-list-type',
  templateUrl: './check-list-type.component.html',
  styleUrls: ['./check-list-type.component.scss'],
})
export class CheckListTypeComponent implements OnInit {
  @Input() plant: number;
  @Output() qualityTypeChanged = new EventEmitter<boolean>();
  checkListType!: FormGroup;
  
  actionId = 0;
  id: any;
  data: any;
  gridApi: any;
  gridColumnApi: any;
  IsWrite: any;
  pageId!: any;
  CheckList_Quality: any[];
  checkListTypeParam: any = { plantId: 0, groupId: 0 };
  columnDefsCheckListType: any;

  constructor(
    private fb: FormBuilder,
    private userService: UserManagementService,
    private kpiMaster: KpimasterService,
    private ChecklistService: ChecklistService,
    private toaster: ToastrService,
    private gridService: GridService,
  ) {}

  gridOptions: any = {
    frameworkComponents: {
      buttonCellRenderer: BtnRendererCheckListType
    },
  };

  defaultColDefCheckListType: any = {
    resizable: true,
    sortable: true,
  };

  rowCheckListType: any = [];

  oncellclicked(params: any): any {
    const col = params.colDef.field;
    let buttonType = '';
    if (col === 'Action') {
      buttonType = params.event.target.textContent;
      if (buttonType === 'edit') {
        this.actionId = 1;
        this.id = params.data.qualityTypeId;
        this.checkListType.get('listType')?.setValue(params.data.name);
      } else if (buttonType === 'delete') {

        this.data = {
          qualityTypeId: params.data.qualityTypeId,
          name: params.data.name,
        };
        this.ChecklistService.deleteCheckListType(this.data).subscribe(
          (response: any) => {
            if (
              response.message ==
              'Delete corresponding data in quality checklist'
            ) {
              this.toaster.error(
                'Check list Table having data against this type'
              );
            } else {
              this.toaster.success(response.message);
              this.getCheckListType(this.checkListTypeParam);
            }
          },
          (error) => {
            this.toaster.error('Error', error.message);
          }
        );

      }
    }
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.pageId = data.BreadcrumList[data.BreadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  getCheckListType(obj: any) {
    this.plant = obj.plantId;
    this.checkListTypeParam.plantId = obj.plantId;
    this.checkListTypeParam.groupId = obj.groupId;
    this.ChecklistService.getTypesByPlant(obj).subscribe(
      (response: any) => {
        this.ChecklistService.getCheckListforQualityType(this.checkListTypeParam).subscribe(
          (checklist: any) => {
            this.CheckList_Quality = checklist;
            response.forEach(data => {
              data.matchedByClist = this.CheckList_Quality.some(x => x.qualityListType == data.qualityTypeId);
            });
            this.rowCheckListType = response;
          },
          (error: any) => {
          }
        );
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  RefreshCheckList(): any {
    this.actionId = 0;
    this.checkListType.get('listType')?.setValue('');
  }

  saveCheckListType() {
    if (this.actionId == 0) {
      if (!this.rowCheckListType.some(e => e.name === this.checkListType.value.listType.replace(/\s+/g, ''))) {
        this.data = {
          qualityTypeId: null,
          name: this.checkListType.value.listType,
          plantId: this.plant
        };
        this.ChecklistService.saveCheckListType(this.data).subscribe(
          (response: any) => {
            this.toaster.success(response.message);
            this.getCheckListType(this.checkListTypeParam);
            this.RefreshCheckList();
          },
          (error) => {
            this.toaster.error('Error', error.message);
          }
        );
      }
      else {
        this.toaster.warning("CheckList Type already Exist")
      }



    } else if (this.actionId == 1) {
      let filtertype = this.rowCheckListType.filter(item => item.qualityTypeId != this.id);
      if (!filtertype.some(e => e.name === this.checkListType.value.listType.replace(/\s+/g, ''))) {
        this.data = {
          qualityTypeId: this.id,
          name: this.checkListType.value.listType,
        };
        this.ChecklistService.editCheckListType(this.data).subscribe(
          (response: any) => {
            this.toaster.success(response.message, "Updated Successfully");
            this.getCheckListType(this.checkListTypeParam);
            this.RefreshCheckList();
            this.qualityTypeChanged.emit(true);
          },
          (error) => {
            this.toaster.error('Error', error.message);
          }
        );
      }
      else {
        this.toaster.warning('checklist typeName Already Exist')
      }
    }
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#agGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }

  ngOnInit(): void {
    this.GetMenuAccess();
    this.GetBreadcrumList();
    this.checkListType = this.fb.group({
      listType: ['', Validators.required],
    });
    this.columnDefsCheckListType = [
      { headerName: 'CheckListType Id', field: 'qualityTypeId', hide: true },
      {
        field: 'Action',
        cellRenderer: 'buttonCellRenderer',
        editable: false,
        hide: this.IsWrite,
        cellRendererParams: { clicked: (field: any) => { } },
        maxWidth: 150,
        minWidth: 100,
        filter: false,
        cellStyle: { textAlign: 'center' },
      },
      { headerName: 'Name', field: 'name', filter: true },
      { headerName: 'plantId', field: 'plantId', hide: true },
      { headerName: 'matchedByClist', field: 'matchedByClist', hide: true }
    ];
    this.getCheckListType(this.checkListTypeParam);
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  
  dateFormatter(params: any): any {
    return moment(params.value).format('MM/DD/YYYY HH:mm');
  }

  onSaveHeaderGridState() {
    const savedState = this.gridColumnApi?.getColumnState();
    const data: GridConfiguration = {
      UserId: JSON.parse(localStorage.getItem('user')).UserId,
      PageId: this.pageId,
      Configuration: JSON.stringify(savedState),
      GridId: 'CheckListType-vTLU37IVZb'
    }
    if (!data.Configuration) return;
    this.gridService.saveGridConfiguration(data).subscribe((res: any) => {
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    )
  }

  applyHeaderFooterState(gridId: string) {
    const data: GridConfiguration = {
      UserId: JSON.parse(localStorage.getItem('user')).UserId,
      PageId: this.pageId,
      GridId: gridId
    }
    this.gridService.getGridConfiguration(data).subscribe((res: any) => {
      if (res.Table.length) {
        this.gridColumnApi.applyColumnState({ state: JSON.parse(res.Table[0].Configuration), applyOrder: true, });
      }
      else {
        this.gridColumnApi.resetColumnState();
      }
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      })
  }
}
