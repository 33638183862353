import { Component, OnInit, ValueSansProvider, ViewChild } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { PlantGroupElement, PlantModelElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { OeeConfigurationService } from 'src/app/services/oee-configuration.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-oee-configuration',
  templateUrl: './oee-configuration.component.html',
  styleUrls: ['./oee-configuration.component.scss']
})
export class OeeConfigurationComponent implements OnInit {
 
  GroupList!: any[];
  RateList!: any[]; 
  OEETypeList!: any[];
  OutputTypeList!: any[];
  VariableGroupList!: any[];
  VariableList!: any[];
  DowntimeCategoryList!: any[];
  FaultByUnitlist!: any[];
  WasteCategoryList!: any[];
  OEEInfolist!: any;
  unitId!: any;

  
  unitDetailForm!: FormGroup;
  searchForm!: FormGroup;

  Group!: string;
  ParameterGrpDescs!: string;
  selectedValue!: string;
  UnitName!: string;
  parameter!: string;
  Rate!: string;
  output!: string;
  OEEType!: string;
  dtCategory!: string;
  wasteCategory!: string;
  unitDetailFormFormSubmitted: boolean = false;

  UnitNode: any;

  isPlantGroupModelVisible: boolean = false;
  breadcrumList!: any; 
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;

  // plantTitleName: string;
  @ViewChild(MatTable) table!: MatTable<PlantModelElement>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(private formBuilder: FormBuilder,
   private toaster: ToastrService,
   private oeeConfigurationService: OeeConfigurationService,
   public router: Router, 
   private userManagementService: UserManagementService,

   private platModelService: EnterPriseGroupService,
   ) { }

  ngOnInit(): void {
    this.GetMenuAccess();
    this.GetBreadcrumList(); 
    this.getGroup();
    this.getRate();
    this.getOEECalculationList();
    this.getOutputTypelist();
   
    //Unit detail form
    this.unitDetailForm = this.formBuilder.group({
      Id: [],
      groupList: ['', Validators.required],
      UnitDataList: ['', Validators.required],
      ParameterGrpDescsList: ['',Validators.required],
      ParameterList: ['',Validators.required],
      RateList: ['',Validators.required],
      OutputList: ['',Validators.required],
      OEEType: ['',Validators.required],
      dtCategoryList: [],
      wasteCategory: [''],
      autoDtFault: [''],
      isShoto:[''],
      PUId: [0]
    });
    // this.setData();
   
  }

  // form unitDetailForm fields controls
  // tslint:disable-next-line: typedef
  get unitDetailFormControls() { return this.unitDetailForm.controls; }
 
  // plant group view
  // tslint:disable-next-line: member-ordering
  treeControl = new NestedTreeControl<PlantGroupElement>(node => node.children);
  // tslint:disable-next-line: member-ordering
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();

  // parent node
  hasChild = (_: number, node: PlantGroupElement) => !!node.children && node.children.length > 0;

  // open model
  openPlantGroupModel(): void {
    this.isPlantGroupModelVisible = true;
    this.plantGroupSource.data = plantGroupData;
    this.treeControl.dataNodes = this.plantGroupSource.data;
    this.expandAllNode(); 
  }
   setData(): void {

    const obj = [{TypeId: 0, TypeName: 'None'}]
    const obj2 = [{FaultId : 0, FaultName: 'None'}]
    this.DowntimeCategoryList = obj;
    this.WasteCategoryList = obj;
    // this.FaultByUnitlist = obj2;
 
    this.unitDetailForm.get('dtCategoryList')?.setValue(this.DowntimeCategoryList[0].TypeId);
    this.unitDetailForm.get('wasteCategory')?.setValue(this.WasteCategoryList[0].TypeId);
    this.unitDetailForm.get('autoDtFault')?.setValue(this.FaultByUnitlist[0].FaultId);

   }
  // close model
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }

  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  //dropdowns
  getGroup(): void{
    const key = {
      Id : 0
    };
    this.oeeConfigurationService.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.masterList; 
      this.unitDetailForm.get('groupList')?.setValue(data.masterList[0].TypeId);
     }, (error: any) => {
       this.toaster.error('Error', error.message);
     });
  }
  getRate(): void{
    this.oeeConfigurationService.getRate().subscribe((data: any) => {
      this.RateList = data.masterList; 
      this.unitDetailForm.get('RateList')?.setValue(data.masterList[0].TypeId);
      // this.RateList.push({TypeId:0,TypeName:'None'});

     }, (error: any) => {
       this.toaster.error('Error', error.message);
     });
  }
  getOEECalculationList(): void{
    this.oeeConfigurationService.getOEECalculationMethod().subscribe((data: any) => { 
      this.OEETypeList = data.masterList; 
      this.unitDetailForm.get('OEEType')?.setValue(data.masterList[0].TypeId);
      // this.OEETypeList.push({TypeId:0,TypeName:'None'});

     }, (error: any) => {
       this.toaster.error('Error', error.message);
     });
  }
  getOutputTypelist(): void{
    this.oeeConfigurationService.getOutputType().subscribe((data: any) => { 
      this.OutputTypeList = data.masterList; 
      this.unitDetailForm.get('OutputList')?.setValue(data.masterList[0].TypeId);
      // this.OutputTypeList.push({TypeId:0,TypeName:'None'});

     }, (error: any) => {
       this.toaster.error('Error', error.message);
     });
  }
  GetVariableGroup(data: any){
    debugger
    this.oeeConfigurationService.getVariableGroup(data).subscribe((data: any) => { 
      this.VariableGroupList = data.masterList;  
      this.unitDetailForm.get('ParameterGrpDescsList')?.setValue(this.VariableGroupList[0].TypeId);
      // this.VariableGroupList.push({TypeId:0,TypeName:'None'});

     }, (error: any) => {
       this.toaster.error('Error', error.message);
     });
  }
  GetVariable(data: any){
    this.oeeConfigurationService.getVariable(data).subscribe((data: any) => { 
      this.VariableList = data.masterList; 
      // this.VariableList.push({TypeId:0,TypeName:'None'});

     }, (error: any) => {
       this.toaster.error('Error', error.message);
     });
  }

  GetVariables(): void {
    debugger;
    const key = {
      Id: this.unitDetailForm.get('ParameterGrpDescsList')?.value,
      CreatedBy: 0
    };
    this.oeeConfigurationService.getVariable(key).subscribe((data: any) => { 
      this.VariableList = data.masterList; 
      this.unitDetailForm.get('ParameterList')?.setValue(this.VariableList[0].TypeId);
     }, (error: any) => {
       this.toaster.error('Error', error.message);
     });
  }
  GetDowntimeCategory (data: any): void{
    this.oeeConfigurationService.getDowntimeCategory(data).subscribe((data: any) => { 
      this.DowntimeCategoryList = data.masterList; 
      //this.unitDetailForm.get('dtCategoryList')?.setValue(data.masterList[0].TypeId);
       this.DowntimeCategoryList.push({TypeId: 0, TypeName: 'None'});
     
     }, (error: any) => {
       this.toaster.error('Error', error.message);
     });
  }
  GetFaultByUnit(val: any): void{
    debugger;
    const key = {
      PUId: this.unitId,
      CategoryId: this.unitDetailForm.value.dtCategoryList // val.value
    };
    this.oeeConfigurationService.getFaultByUnit(key).subscribe((data: any) => {  
      this.FaultByUnitlist = [];
      this.FaultByUnitlist =  data.FaultList; 
      this.FaultByUnitlist.push({FaultId: 0, FaultName: 'None'});
     }, (error: any) => {
       this.toaster.error('Error', error.message);
     });
  }
  GetWasteCategory(data: any): void {
    this.oeeConfigurationService.getWasteCategory(data).subscribe((data: any) => {  
      this.WasteCategoryList = data.masterList; 
      this.WasteCategoryList.push({TypeId: 0, TypeName: 'None'});
     }, (error: any) => {
       this.toaster.error('Error', error.message);
     });
  }
  GetOEEInfo (key: any): void{
    this.oeeConfigurationService.getOEEInfo(key).subscribe((data: any) => {  
      this.OEEInfolist = data; 
      var id = {id: this.OEEInfolist.VarGroupId};
      var getfault = {PUId: this.OEEInfolist.PUID, CategoryId: this.OEEInfolist.ERCID};
      // this.GetVariable(id); 
      this.unitId = key.PUID;
     
      // this.GetWasteCategory(key);
      // this.GetFaultByUnit(key);  
      // this.unitDetailForm.get('ParameterGrpDescsList')?.setValue(this.OEEInfolist.VarGroupId); 
      this.unitDetailForm.get('OEEType')?.setValue(this.OEEInfolist.OEId ); 
      this.unitDetailForm.get('ParameterList')?.setValue(this.OEEInfolist.VarId); 
      this.unitDetailForm.get('RateList')?.setValue(this.OEEInfolist.PRTUID ); 
      this.unitDetailForm.get('OutputList')?.setValue(this.OEEInfolist.OCMId); 
      this.unitDetailForm.get('dtCategoryList')?.setValue(this.OEEInfolist.ERCID); 
      this.unitDetailForm.get('wasteCategory')?.setValue(this.OEEInfolist.WSCTID); 
      this.unitDetailForm.get('autoDtFault')?.setValue(this.OEEInfolist.AutoFault);  
      this.unitDetailForm.get('isShoto')?.setValue(this.OEEInfolist.IsShoto);
      this.GetFaultByUnit(getfault);
      this.GetVariables();
     }, (error: any) => {
       this.toaster.error('Error', error.message);
     });
  }

  //Unit
    //open tree structure for unit
    openUnit() {
      this.isPlantGroupModelVisible = true; 
      const key = {
        EGId : this.unitDetailForm.value.groupList,
        level : 'Unit',
        CreatedBy : 0,
        IsOptional : 0
       };
       this.oeeConfigurationService.getTreeModal(key).subscribe((data: any) => {
         
        this.plantGroupSource.data = data.TreeModalList;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.UnitNode = filterData(this.plantGroupSource.data, 'Unit');
       });
     // this.plantGroupSource.data = plantGroupData;
      
      function filterData(data: PlantGroupElement[], title: string) { 
        var r = data.filter(function (o: any) {
          Object.keys(o).forEach(function (e: any) {
            if (Array.isArray(o[e])) o[e] = filterData(o[e], title);
          });
          return o.title != title;
        });
        return r;
      } 
      // this.treeControl.expandAll();
      // this.treeControl.collapseDescendants(this.UnitNode);
    }
  
    SelectUnitName(unitname: any) {
      if (unitname.title === 'UNT') {
        this.UnitName = unitname.name;
        this.isPlantGroupModelVisible = false;
      }
    }

    changeGroup(): void {
      this.unitDetailForm.get('UnitDataList')?.reset();
    }

  onClickUnit(node: any){
    this.isPlantGroupModelVisible = false;  
    var id = {
      id: node.PrimaryId
    };
    var key = {
      PUID: node.PrimaryId
    };
    this.unitDetailForm.get('UnitDataList')?.setValue(node.text); 
    this.unitDetailForm.get('PUId')?.setValue(node.PrimaryId);
    this.GetVariableGroup(id); 
    this.GetDowntimeCategory(id);  
    this.GetWasteCategory(id);
    this.GetOEEInfo(key); 
  }
  //unit data form submit
  unitDetailFormFormSubmit() {
    var key = {
      PUID: this.unitDetailForm.get('PUId')?.value,
      VarId: this.unitDetailForm.get('ParameterList')?.value,
      OCMID: this.unitDetailForm.get('OutputList')?.value,
      OEID: this.unitDetailForm.get('OEEType')?.value,
      PRTUID: this.unitDetailForm.get('RateList')?.value,
      ERCID: this.unitDetailForm.get('dtCategoryList')?.value,
      WSCTID: this.unitDetailForm.get('wasteCategory')?.value,
      AutoFault: this.unitDetailForm.get('autoDtFault')?.value, 
      IsShoto: this.unitDetailForm.get('isShoto')?.value

    };
    this.unitDetailFormFormSubmitted = true;
    // stop here if form is invalid
    if (this.unitDetailForm.invalid) {
      return;
    }
    this.oeeConfigurationService.submitOEEInfo(key).subscribe((data: any) => {    
      this.showPopupMsg(data.ErrorMessage);
    }, (error: any) => {
      this.showPopupMsg(error.ErrorMessage);
      // this.toaster.error('Error', error.message);
    });
  }

  showPopupMsg(msg: any): any {
    if (msg !== null) {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
       // this.isaddNewUserModeItemModelVisible = false;
      }else {
       this.toaster.error('Error', msg.substring(3, msg.length));
     }
    }
  }

  replaceIcon(value: any): any{
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  //access and breadcrumb
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
      // localStorage.setItem('GetMenuAccess', this.permisson);
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any{
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([url]));
    // return false;
  }
}
const plantGroupData: PlantGroupElement[] = [];
