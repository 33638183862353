import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BatchReportService } from 'src/app/services/batch-report-service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ProductivityService } from 'src/app/services/productivity-service';
@Component({
  selector: 'app-wholesome-food-report',
  templateUrl: './wholesome-food-report.component.html',
  styleUrls: ['./wholesome-food-report.component.scss']
})
export class WholesomeFoodReportComponent implements OnInit {

  public gridApi!: any;
  public gridColumnApi!: any;
  public defaultExportParams!:any;
  public columnDefs!: any;
  public defaultColDef!: any;
  public detailCellRendererParams!: any;
  public rowData!: any;
  public excelStyles!:any;
  constructor(private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private batchReportService: BatchReportService,
    private productivityservice: ProductivityService,
    public router: Router,
    private userService: UserManagementService,
    private enterpriseGroupService: EnterPriseGroupService,
    private spinner: NgxSpinnerService) {
      this.defaultExportParams = {
        getCustomContentBelowRow: function (params:any) {
          return [
            [
              cell('',''),
              cell('groupName', 'header'),
              cell('VariableDesc', 'header'),
              cell('LSL', 'header'),
              cell('TGT', 'header'),
              cell('USL', 'header'),
              cell('LCL', 'header'),
              cell('LWL', 'header'),
              cell('UWL', 'header'),
              cell('UCL', 'header')
            ],
          ].concat(
            params.node.data.productSpec.map(function (record:any) {
              return [
                cell('',''),
                cell(record.groupName, 'body'),
                cell(record.VariableDesc, 'body'),
                cell(record.LSL, 'body'),
                cell(record.TGT, 'body'),
                cell(record.USL, 'body'),
                cell(record.LCL, 'body'),
                cell(record.LWL, 'body'),
                cell(record.UWL, 'body'),
                cell(record.UCL, 'body')
              ];
            }),
            [[]]
          );
        },
        columnWidth: 120,
      };
    this.columnDefs = [
      {
        field: 'productCode',
        cellRenderer: 'agGroupCellRenderer',
      },
      { field: 'productDesc' }
    ];
    this.defaultColDef = { flex: 1 };
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { field: 'groupName', filter: true, resizable: true, wrapText: true  },
          { field: 'VariableDesc', filter: true, resizable: true, wrapText: true  },
          { field: 'LSL', headerName: 'LRL', filter: true, resizable: true, wrapText: true  },
          { field: 'LCL', headerName: 'LSL', filter: true, resizable: true, wrapText: true  },
          { field: 'LWL', headerName: 'LWL', filter: true, resizable: true, wrapText: true  },
          { field: 'TGT', headerName: 'TGT', filter: true, resizable: true, wrapText: true  },
          { field: 'UWL', headerName: 'UWL', filter: true, resizable: true, wrapText: true  },
          { field: 'UCL', headerName: 'USL', filter: true, resizable: true, wrapText: true  },
          { field: 'USL', headerName: 'URL', filter: true, resizable: true, wrapText: true  },

        ],
       defaultColDef: { flex: 1 },
      },
      getDetailRowData: function (params: any) {
      params.successCallback(params.data.productSpec);
      },
    };
    this.excelStyles = [
      {
        id: 'header',
        interior: {
          color: '#aaaaaa',
          pattern: 'Solid',
        },
      },
      {
        id: 'body',
        interior: {
          color: '#dddddd',
          pattern: 'Solid',
        },
      },
    ];
  }
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  breadcrumList!: any;
  wholeSomeFoodForm!: FormGroup;
  productCodeList!: any[];
  wholesomeFoodDatasource!: MatTableDataSource<any>;
  ngOnInit(): void {
    this.initform();
    this.datasource();
    this.GetBreadcrumList();
    this.GetMenuAccess();
  }

  initform(){
    this.wholeSomeFoodForm = this.formBuilder.group({
      productCode: ['', []],
      UserGroup1: ['', ],
      UserPlant1: ['', ],
      unit: ['', ],
      line: ['', ],
    });
  };

  onChangeGroup(data : any):void{
    this.getPlants(data.value.EG_Id);
  }
  onChangeLine(data:any):void{
    this.getUnit(data.value.PL_Id);
  }
  onChangePlant(data:any):void{
    this.getLine(data.value.PT_Id);
  }
  datasource(): void {
    const key = {
      UserId: 206     
      };
  
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      const id = data.lstEG[0].EG_Id;
      this.wholeSomeFoodForm.get('UserGroup1')?.patchValue(data.lstEG[0]);
      this.wholeSomeFoodForm.get('UserPlant1')?.patchValue(data.lstEG[0].EG_Id);
      this.getPlants(id);
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(id: any): void {
    const key = {
      EG_ID: id,
      userId: 206
    };

    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      const id = data.lstPlant[0].PT_Id;
      this.wholeSomeFoodForm.get('UserPlant1')?.patchValue(data.lstPlant[0]);
      this.getLine(id);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getLine(id: any) {
    const key = {
      PT_ID: id,
      userId: 206
    };

    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      this.getUnit(this.lineList[0].PL_Id);
      if(this.lineList !=null){
        this.wholeSomeFoodForm.controls.line.patchValue(this.lineList[0]);
      }  
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUnit(id: any) {
    const key = {
      PL_ID: id,
      userId: 206
    };

    this.productivityservice.getUnitData(key).subscribe((data: any) => {
      this.unitList = data.lstUnit;
      this.wholeSomeFoodForm.controls.unit.patchValue(this.unitList[0]);
      this.getProductSpecification();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });

  }

  getProductSpecification(): void {
    const key = {
      EGID: this.wholeSomeFoodForm.value.UserGroup1.EG_Id,
      PTID: this.wholeSomeFoodForm.value.UserPlant1.PT_Id,
      PUID: this.wholeSomeFoodForm.value.unit.PU_Id
    };
    this.batchReportService.getProductSpecification(key).subscribe((data: any) => {
      this.wholesomeFoodDatasource = data.completeProductSpecList;
      this.rowData = data.completeProductSpecList;
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }
  onFirstDataRendered(params: any) {
    setTimeout(function () {
      params.api.getDisplayedRowAtIndex(1).setExpanded(true);
    }, 0);
  }
  excelExport() {
    this.gridApi.exportDataAsExcel();
  }
  csvExport(){
    this.gridApi.exportDataAsCsv();
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}

function cell(text:any, styleId:any) {
  return {
    styleId: styleId,
    data: {
      type: /^\d+$/.test(text) ? 'Number' : 'String',
      value: String(text),
    },
  };
}

