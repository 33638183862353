import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CostCenterService {
  
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }
  AddCostCenter(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CostCenter/AddCostCenter', data);
  }
  GetCostCenter(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CostCenter/GetCostCenter', data);
  }
  deleteCost(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'CostCenter/DeleteCostCenter', data);
  }
  updateCost(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.o3CoreApiUrl + 'CostCenter/UpdateCostCenter', data);
  }
  uploadExcelFile(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CostCenter/UploadFile', data);
  }

}
