import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MeetingActionService } from 'src/app/services/meeting-action.service';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
import { SideBarDef } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from 'src/app/analytical-tools/image-dialog/image-dialog.component';
import { DatePipe } from '@angular/common';
import { differenceInCalendarDays } from 'date-fns';
import { OverallActionPlanService } from 'src/app/services/overall-actionPlan.service';
import { DatepickerService } from 'src/app/services/Datepicker.service';

@Component({
  selector: 'app-meeting-action',
  templateUrl: './meeting-action.component.html',
  styleUrls: ['./meeting-action.component.scss'],
})
export class MeetingActionComponent implements OnInit {
  @ViewChild('previewModal') private previewModal!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileInputFile') fileInputFile!: ElementRef;
  rowData: any;
  ButtonType!: any;
  meetingActionId: any;
  breadcrumList: any;
  MeetingActionForm!: FormGroup;
  groupListDDL!: any[];
  filteredGroupListDDL: any[];
  plants!: any;
  filteredPlants!: any;
  IsAccess: any;
  IsWrite: any;
  date = null;
  cellData: any;
  filteredUsers!: any[];
  Responsible: any;
  toastrService: any;
  UserDDL: any;
  allSelected: boolean = true;
  filteredParticipants: any;
  bankMultiCtrl: FormControl = new FormControl();
  public bankMultiFilterCtrl: FormControl = new FormControl();
  public filteredBanksMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('selectAll') selectAll!: MatSelect;
  @ViewChild('multiSelect', { static: true }) multiSelect:
    | MatSelect
    | undefined;
  languageDir = 'ltr';
  langObj: any = {};
  protected _onDestroy = new Subject<void>();
  moduleId!: any;
  pageId!: any;
  defaultFromDate: Date;
  defaultToDate: Date;
  firstDayOfWeek: any;
  locale: { format: string; firstDay: any; };
  showdiv: boolean = false;
  actionscolumnDefs: any;
  defaultColDef: any;
  loadingOverlayComponent: any;
  gridOptions: any = {
    frameworkComponents: {
      customLoadingOverlay: CustomLoadingOverlay,
      primeCellEditor: PrimeCellEditorComponent,
    },
  };
  gridApi: any;
  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: false,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
    defaultToolPanel: 'columns',
  };
  imageFile: any;
  imageSrc: any;  
  doc: string;
  images: any[];
  fileType: string;
  fileDesc: any;
  displayMaximizable: boolean;
  imageIndexOne = 0;
  gridColumnApi: any;
  editActionPlanForm!: FormGroup;
  fileSave: any;
  ImageSave: any;
  ModuleName: any;
  data: any;
  disableVerification = false;
  disableNewAssigne = true;
  currentAssigneeEmail: string = null;
  currentResponsibleEmail: any = null;
  currentNodeDate: any = null;
  UniqueCode: any;
  HeaderId: any;
  closefile: any;
  targetDate: any;
  showinput: boolean;
  RevisedDate: Date;
  Status: any;
  userList: any = [];
  blobStorageUrl: string;
  constructor(
    private kpiservice: KpitreeService,
    private fb: FormBuilder,
    private usermanagementService: UserManagementService,
    private meetingService: MeetingActionService,
    private platModelService: EnterPriseGroupService,
    private toaster: ToastrService,
    public router: Router,
    public translater: TranslaterService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private actionplanService: OverallActionPlanService,
    private datepickerService: DatepickerService,
  ) {
    this.defaultColDef = {
      editable: true,
      enableRowGroup: true,
      enableValue: true,
    };

    this.actionscolumnDefs = [
      {
        field: 'Actions',
        headerName: 'Actions',
        resizable: true,

        cellRenderer: (params: any): any => {
          if (params?.data.Status !== 'Closed') {
            return `
          <button mat-mini-fab="" matTooltip="Edit Physical Test" tooltipPosition="top" color="primary" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          `;
          }
        },
        width: 100,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      // {
      //   field: 'Actions',
      //   headerName: 'Actions',
      //   sortable: true,
      //   resizable: true,
      //   filter: true,
      //   editable: false,
      //   width: 100,
      // },
      {
        field: 'Module',
        headerName: 'Module',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        width: 100,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'HeaderId',
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'UniqueCode',
        headerName: 'Unique Code',
        sortable: true,
        resizable: true,
        filter: true,
        wrapText: true,
        editable: false,
        width: 130,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Action',
        headerName: 'Actions (RCA & others)',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Responsible',
        headerName: 'AssignTo / Responsible',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Status',
        headerName: 'Status',
        sortable: true,
        width: 100,
        resizable: true,
        filter: true,
        editable: false,
        cellStyle: function (params: any): any {
          if (params.value == 'Open')
            return { color: 'white', backgroundColor: 'gray' };
          else if (params.value == 'InProgress')
            return { color: 'white', backgroundColor: 'orange' };
          else if (params.value == 'Complete')
            return { color: 'white', backgroundColor: '#1cd51c' };
          else if (params.value == 'Closed')
            return { color: 'white', backgroundColor: 'green' };
          else if (params.value == 'Delayed')
            return { color: 'white', backgroundColor: 'brown' };
          else if (params.value == 'Rejected')
            return { color: 'white', backgroundColor: 'red' };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ObservedDate',
        headerName: 'Observed Date',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Department',
        headerName: 'Department',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        width: 120,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Observation',
        headerName: 'Initial Problem /Observation / Root Cause Problem',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'picturebefore',
        headerName: 'Picture Before',
        sortable: true,
        resizable: true,
        filter: false,
        hide: true,
        cellRenderer: function (params: any) {
          let html = `<img style="width: 50px; height: 40px;" src="assets/images/no-image.png"/>`;
          if (params && params.value) {
            html = '';
            html = `<img style="width: 50px; height: 40px;" src="${params.value}"/>`;
          }
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },

      {
        field: 'UploadDoc',
        headerName: 'Attachments',
        editable: false,
        hide: true,
        cellRenderer: function (params: any) {
          let html = '';
          if (params && params.value) {
            html = `<a href = "${params.value}" ><i class="fa fa-download"></i></a>`;
          }
          return html;
        },
        cellStyle: (params: any) => {
          // let html = 'view';
          return {
            color: 'blue',
            'text-decoration': 'underline',
            cursor: 'pointer',
          };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'RootCause',
        headerName: 'Root Cause Problem',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },

      {
        field: 'ActionType',
        headerName: 'Action Type (Corrective/Preventive/Other)',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },

      {
        field: 'ClosedFile',
        headerName: 'Closed File',
        sortable: true,
        resizable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = '';
          if (params && params?.data?.closedFile) {
            html = `<button mat-mini-fab="" matTooltip="View document" tooltipPosition="top" color="primary" class="viewDocument mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="viewDocument mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon></span><span matripple="" class="viewDocument mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            `;
          }
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'AssignedDate',
        headerName: 'Assigned Date',
        sortable: true,
        resizable: true,
        filter: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'TargetDate',
        headerName: 'Target Date',
        sortable: true,
        resizable: true,
        filter: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'RevisedDate',
        headerName: 'New/Revised Target Date',
        sortable: true,
        resizable: true,
        filter: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'NoOfMisses',
        headerName: 'No of Misses',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ActionCompleted',
        headerName: 'Action Completed ',
        sortable: true,
        resizable: true,
        filter: true,
        wrapText: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Yes', 'No'],
        },
        editable: (params: any) => params.value === 'No',
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ActionCompletedByName',
        headerName: 'Action Completed By',
        sortable: true,
        resizable: true,
        filter: true,
        wrapText: true,
        editable: (params: any) => params.data.ActionCompleted === 'No',
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ActionCompletedOn',
        headerName: 'Action Completed On',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Verification',
        headerName: 'Verification',
        sortable: true,
        resizable: true,
        filter: true,
        editable: (params: any) =>
          params.value === 'Disapproved' || params.value === '',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Approved', 'Disapproved'],
        },
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'VerificationByName',
        headerName: 'Verified By',
        sortable: true,
        resizable: true,
        filter: true,
        wrapText: true,
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'VerificationOn',
        headerName: 'Verified On',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'NewAssignee',
        headerName: 'New Assignee',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ClosedDate',
        headerName: 'Closed Date',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'closedFile',
        headerName: 'Closed File',
        sortable: true,
        resizable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = '';
          if (params && params.value) {
            html = `<a href = "${params.value}" ><i class="fa fa-download"></i></a>`;
          }
          return html;
        },
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Remarks',
        headerName: 'Remarks',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
    ];
    this.loadingOverlayComponent = 'customLoadingOverlay';
    // tslint:disable-next-line: max-line-length
  }

  ngOnInit(): void {
    this.blobStorageUrl = environment.O3CoreApiImageUrl;
    this.getGroupData();
    this.GetBreadcrumList();

    // this.getAllMeetingAction();
    this.MeetingActionForm = this.fb.group({
      group: [''],
      plant: [''],
      responsible: [''],
      datePicker: [''],
      startDate: [''],
      endDate: [''],
    });
    this.editActionPlanForm = this.fb.group({
      rootcause: [''] ,
      id: [''],
      remarks: [''],
      revisedDate: [''],
      actions: [''],
      assignTo: [''],
      completed: [''],
      verification: [''],
      actionType: [''],
      targetDate: [''],
      newAssignee: [''],
    });
    this.GetMenuAccess();
    this.defaultDate();
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const d = date.getDate();
    const firstDay = new Date(y, m - 1, d);
    const lastDay = new Date(y, m, d);
    this.MeetingActionForm.get('startDate').setValue(firstDay);
    this.MeetingActionForm.get('endDate').setValue(lastDay);
  }

  defaultDate() {

    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const selected = {
      startDate: date,
      endDate: new Date(),
    };
    this.MeetingActionForm.get('datePicker')?.setValue(selected);
  }

  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value: string) {
    let val;
    if (this.langObj && this.langObj[value]) {
      val = this.langObj[value];
    }
    return val ? val : value;
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if (this.langObj) {
        this.gridApi.refreshHeader();
      }
    });
  }


  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  onPageSizeChanged() {
    const value: HTMLElement = document.getElementById(
      'page-size'
    ) as HTMLElement;
    // const value = document.getElementById('page-size').value;
    // console.log(e.value)
  }
  filterBanksMulti() {
    if (!this.filteredParticipants) {
      return;
    }

    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.filteredBanksMulti.next(this.filteredParticipants);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanksMulti.next(
      this.filteredParticipants.filter(
        (bank: any) =>
          (bank.FirstName + ' ' + bank.lastName).toLowerCase().indexOf(search) >
          -1
      )
    );
    this.getAllMeetingAction();
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.meetingService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.filteredGroupListDDL = this.groupListDDL.slice();
        this.MeetingActionForm.get('group')?.setValue(
          this.groupListDDL[0].TypeId
        );
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlantByGroup(): void {
    this.rowData = [];
    const key = {
      GroupId: this.MeetingActionForm.get('group')?.value,
    };
    // this.MachineId = null;
    this.meetingService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.filteredPlants = this.plants.slice();
        this.MeetingActionForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getUserList();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getAllMeetingAction(): void {
    const startDate = moment(this.MeetingActionForm.value.startDate).utc();
    if(!this.MeetingActionForm.value.startDate || !this.MeetingActionForm.value.endDate) {
      return;
    }
    const endDate = new Date(
      new Date(this.MeetingActionForm.value.endDate).setHours(23, 59, 59, 59)
    );
    const key = {
      responsible: (this.MeetingActionForm.value.responsible.map(
        (c: any) => c.EmpInfo_Id
      )).toString(),
      plantId: this.MeetingActionForm.get('plant')?.value,
      fromDate: startDate,
      toDate: endDate
    };
    this.meetingService.getAllMeetingActions(key).subscribe(
      (data) => {
        data?.map((row) => {
          row.picturebefore = environment.O3CoreApiImageUrl + row.picturebefore;
          row.UploadDoc = environment.O3CoreApiImageUrl + row.UploadDoc
          row.closedFile = environment.O3CoreApiImageUrl + row.closedFile
        })
        this.rowData = data;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onChangePlant(): void {
    this.setFirstWeek();
    this.getUserList();
  }
  SetFirstDayofWeek(): void {

    const dept = this.plants.find(item => item.DeptId === this.MeetingActionForm.value.plant);
    this.firstDayOfWeek = dept ? dept.firstDayOfWeek : 0;
    this.locale = { format: 'DD-MM-YYYY', firstDay: this.firstDayOfWeek };
    this.showdiv = false;
    setTimeout(() => {
      this.showdiv = true;
    }, 10);
  }

  getUserList(): void {
    this.SetFirstDayofWeek();
    this.rowData = [];
    this.bankMultiCtrl.setValue('');
    const key = {
      EGId: this.MeetingActionForm.get('group')?.value,
      PlantId: this.MeetingActionForm.get('plant')?.value,
    };

    this.kpiservice.getEmployeeMasterData(key).then(
      (users: any) => {
        this.UserDDL = users.employeeInfoList.filter((x: any) => {
          return x.EmpInfo_Id;
        });
        this.UserDDL.forEach((element: any) => {
          element.fullName = element.FirstName + ' ' + element.lastName;
        });
        this.MeetingActionForm.get('responsible')?.setValue(this.UserDDL);
        this.getAllMeetingAction();
        this.userList = users.employeeInfoList;
          this.userList.map(
            (user: any) =>
            (user.FullName =
              user.FirstName.trim() + ' ' + user.lastName.trim())
          );
        this.filterBanksMulti();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  


  deleteMeetingAction(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          meetingActionId: this.meetingActionId,
        };
        this.meetingService.deleteMeetingAction(key).subscribe(
          (res: any) => {
            Swal.fire(
              'Deleted!',
              'Your selected Meeting action has been deleted.',
              'success'
            );
            const key = {
              responsible: (this.MeetingActionForm.value.responsible.map(
                (c: any) => c.EmpInfo_Id
              )).toString(),
              plantId: this.MeetingActionForm.get('plant')?.value,
              fromDate: moment(this.MeetingActionForm.value.datePicker.startDate).format('YYYY-MM-DD'),
              toDate: moment(this.MeetingActionForm.value.datePicker.endDate).format('YYYY-MM-DD')
            };
            this.meetingService.getAllMeetingActions(key).subscribe((data) => {
              data?.map((row) => {
                row.picturebefore = environment.O3CoreApiImageUrl + row.picturebefore;
                row.UploadDoc = environment.O3CoreApiImageUrl + row.UploadDoc
                row.closedFile = environment.O3CoreApiImageUrl + row.closedFile
              })
              this.rowData = data;
            });
          },
          (error: any) => {
            this.toastrService.error('Error', error.message);
          }
        );
      }
    });
  }

  toggleAllSelection() {
    this.allSelected = !this.allSelected;

    if (this.allSelected) {
      const filterdArray = this.filteredParticipants?.map(
        (item: any) => item?.EmpInfo_Id
      );
      this.MeetingActionForm.get('bankMultiCtrl')?.setValue([
        ...filterdArray,
        '0',
      ]);
    } else {
      this.MeetingActionForm.get('bankMultiCtrl')?.setValue([]);
    }

    // if (this.allSelected) {
    //   this.selectAll.options.forEach((item: MatOption) => item.select());
    //    const filteredMachine =  this.filteredParticipants  ?.map((item: any) => item?.EmpInfo_Id);

    //   this.MeetingActionForm.get('bankMultiCtrl')?.setValue([...filteredMachine]);
    // } else {
    //   this.selectAll.options.forEach((item: MatOption) => item.deselect());
    //   this.MeetingActionForm.get('bankMultiCtrl')?.setValue([]);
    // }
  }



  convertDate(date: any): any {
    const dateParts = date.split('/');
    return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  onCellValueChanged(params: any): void {
  }

  cellClicked(params: any): void {
    const ButtonType = params.event.target.textContent;
    const col = params.colDef.field;
    let classList = params.event.target.classList;

    if (ButtonType === 'edit') {
      this.EditRow(params);
    }

    if (col === 'picturebefore') {
      this.imageZoomModal1(params.value);
    }

    if (col === 'ClosedFile') {
      if (classList.contains('viewDocument')) {
        this.doc = params.data.closedFile;
        const fileType = params.data.closedFile.split('.').pop();
        if (
          fileType === 'jpeg' ||
          fileType === 'jpg' ||
          fileType === 'png' ||
          fileType === 'gif'
        ) {
          this.images = [];
          this.fileType = 'img';
          this.images.push(this.doc);
        } else {
          this.fileType = 'doc';
        }
        this.fileDesc = params.data?.KPIName;
        this.displayMaximizable = true;
      }
    }

  }
  

  imageZoomModal1(data): void {
    if (data == '') {
      let html = 'assets/images/no-image.png';
      let dialogRef = this.dialog.open(ImageDialogComponent, {
        height: '90%',
        width: '90%',
        data: html,
      });
    } else {
      let dialogRef = this.dialog.open(ImageDialogComponent, {
        height: '90%',
        width: '90%',
        data: data,
      });
    }

    // this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }
  
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#mainGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    var columnState = JSON.parse(
      localStorage.getItem('userActions-OverAllActionsColumnState')
    );
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }

  onFilterChanged(params): any {
    localStorage.setItem(
      'ag-grid-persistane-filtering',
      JSON.stringify(params.api.getFilterModel())
    );
  }

  onFirstDataRendered(params): void {
    const filterModel = localStorage.getItem('ag-grid-persistane-filtering');
    params.api.setFilterModel(JSON.parse(filterModel));
  }

  onColumnPivotChanged(e: any) {
    var ColumnState = JSON.stringify(this.gridColumnApi.getColumnState());
    localStorage.setItem('userActions-OverAllActionsColumnState', ColumnState);
  }
  
  saveState() {
    var ColumnState = JSON.stringify(this.gridColumnApi.getColumnState());
    localStorage.setItem('userActions-OverAllActionsColumnState', ColumnState);
  }
  
  EditRow(params: any): void {
    if (params) {
      this.data = [];
      this.ImageSave = null;
      this.fileSave = null;
      this.closefile = null;
      this.data = params.node.data;
      this.ModuleName = params.node.data.Module;
      this.UniqueCode = params.node.data.UniqueCode;
      this.HeaderId = params.node.data.HeaderId;
      this.currentAssigneeEmail = params.node.data.AssigneEmail;
      this.currentResponsibleEmail = params.node.data.ParticpantEmails;
      this.currentNodeDate = params.node.data.ObservedDate;
      this.editActionPlanForm.get('actions').setValue(params.node.data.Action);
      this.editActionPlanForm
        .get('assignTo')
        .setValue(params.node.data.AssignTo);
      this.editActionPlanForm
        .get('completed')
        .setValue(params.node.data.ActionCompleted);
      this.editActionPlanForm.get('remarks').setValue(params.node.data.Remarks);
      this.editActionPlanForm.get('remarks').setValue(params.node.data.Remarks);
      this.editActionPlanForm
        .get('newAssignee')
        .setValue(params.node.data.NewAssigneeId);
      if (params.node.data.ActionCompleted === 'No') {
        this.disableVerification = true;
      }
      if (params.node.data.Verification === 'Disapproved') {
        this.disableNewAssigne = false;
      }
      this.editActionPlanForm
        .get('verification')
        .setValue(params.node.data.Verification);
      this.editActionPlanForm
        .get('actionType')
        .setValue(params.node.data.ActionType);
      if (params.node.data.RevisedDate !== null) {
        this.editActionPlanForm
          .get('revisedDate')
          .setValue(
            this.datePipe.transform(
              new Date(params.node.data.RevisedDate),
              'yyyy-MM-dd'
            )
          );
      }
      if (params.node.data.TargetDate !== null) {
        this.editActionPlanForm
          .get('targetDate')
          .setValue(
            this.datePipe.transform(
              new Date(params.node.data.TargetDate),
              'yyyy-MM-dd'
            )
          );
      }
      this.targetDate = params.node.data.TargetDate;
      this.editActionPlanForm.get('rootcause')?.setValue(params.node.data.RootCause);
      // this.editActionPlanForm.get('targetDate').setValue(params.data.TargetDate) ;
      this.dialog.open(this.previewModal, { height: 'auto', width: '100%' });
    }
  }
  
  getMinDate(type: any): any {
    if (type === 'Revised') {
      const date = this.data.RevisedDate
        ? this.data.RevisedDate
        : this.data.TargetDate;
      const currentDate = new Date(date);

      const minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate() + 1)
      );

      return (current: Date): boolean =>
        differenceInCalendarDays(current, minDateStartFrom) < 0;
    } else if (type === 'Target') {
      const currentDate = new Date();
      const minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate())
      );

      return (current: Date): boolean =>
        differenceInCalendarDays(current, minDateStartFrom) < 0;
    }
  }
  
  imageZoomModal(): void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      height: '90%',
      width: '90%',
      data: this.data.FileSrc || environment.O3CoreApiImageUrl + this.data.UploadFile,
    });
    // this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  deleteFile(type: any): void {
    if (type === 'pic') {
      this.data.FileSrc = '';
      this.data.UploadFile = '';
      this.ImageSave = new File([''], 'filename');
    } else {
      this.data.DocSrc = '';
      this.data.UploadDoc = '';
      this.fileSave = new File([''], 'filename');
    }
  }

  onChangeComplete(): void {
    // console.log( 'compelete' , this.editActionPlanForm.value.completed);
    if (this.editActionPlanForm.value.completed === 'Yes') {
      this.disableVerification = false;
      this.editActionPlanForm.get('verification').setValue('');
    }
    else {
      this.disableVerification = true;
    }
  }

  onChangeVerification(): void {
    if (this.editActionPlanForm.value.verification === 'Disapproved') {
      this.disableNewAssigne = false;
    } else if (this.editActionPlanForm.value.verification !== 'Disapproved') {
      this.disableNewAssigne = true;
    }
    if (this.editActionPlanForm.value.verification === 'Approved') {
      this.showinput = true;
    } else {
      this.showinput = false;
    }
  }

  targetChange(): void {
  }
  
  UpdateActionPlan(): void {
    this.Status = '';
    // console.log(this.data , 'daadajsdjadjasjd');
    this.Status =
      this.editActionPlanForm.value.targetDate &&
        !this.editActionPlanForm.value.revisedDate
        ? 'InProgress'
        : this.editActionPlanForm.value.targetDate &&
          this.editActionPlanForm.value.revisedDate
          ? 'Delayed'
          : 'Open';

    if (this.editActionPlanForm.value.targetDate) {
      this.targetDate = this.editActionPlanForm.value.targetDate;
      this.targetDate = new Date(this.targetDate);
      this.targetDate.setHours(new Date().getHours());
      this.targetDate.setMinutes(new Date().getMinutes());
      this.targetDate.setSeconds(new Date().getSeconds());
    }
    if (this.editActionPlanForm.value.revisedDate) {
      this.RevisedDate = this.editActionPlanForm.value.revisedDate;
      this.RevisedDate = new Date(this.RevisedDate);
      this.RevisedDate.setHours(new Date().getHours());
      this.RevisedDate.setMinutes(new Date().getMinutes());
      this.RevisedDate.setSeconds(new Date().getSeconds());
    }
    if (this.editActionPlanForm.value.completed === 'Yes') {
      this.Status = 'Complete';
      var templateBody = null;
      var plantName = null;
      var plant = this.plants.find(x => x.DeptId == this.MeetingActionForm.controls.plant.value);
      plantName = plant.DeptDesc;
      var date = null;
      var user = this.userList.find(x => x.EmpInfo_Id == this.editActionPlanForm.value.assignTo);
    }
      if (this.editActionPlanForm.controls.revisedDate.value > this.editActionPlanForm.controls.targetDate.value) {
        date = this.editActionPlanForm.controls.revisedDate.value;
      }
      else {
        date = this.editActionPlanForm.controls.targetDate.value;
      }
    if (this.editActionPlanForm.value.verification === 'Approved') {
      this.Status = 'Closed';
      // fiveWhy.ClosedDate = new Date();
    } else if (this.editActionPlanForm.value.verification === 'Disapproved') {
      this.Status = 'ReOpened';
      // fiveWhy.ActionCompleted = 'No';
      this.editActionPlanForm.get('completed').setValue('No');
    }

    const formdata = new FormData();
    formdata.append('file', this.fileSave);
    formdata.append('image', this.ImageSave);
    formdata.append('closefile', this.closefile);

    const key = {
      actionId: this.data.ActionId,
      actionName: this.editActionPlanForm.value.actions,
      actionType: this.editActionPlanForm.value.actionType,
      responsible: this.editActionPlanForm.value.assignTo,
      targetDate: this.targetDate ? this.targetDate : null,
      revisedDate: this.RevisedDate ? this.RevisedDate : null,
      actionCompleted: this.editActionPlanForm.value.completed,
      Verification: this.editActionPlanForm.value.verification,
      remarks: this.editActionPlanForm.value.remarks,
      newAssignee: this.editActionPlanForm.value.newAssignee
        ? this.editActionPlanForm.value.newAssignee
        : null,
      status: this.Status,
      userId: JSON.parse(localStorage.user).UserId,
      headerId: this.HeaderId,
      rootcause : this.editActionPlanForm.value.rootcause
    };
    formdata.append('values', JSON.stringify(key));
    this.actionplanService.editActionPlan(formdata).subscribe((res: any) => {
      this.getAllMeetingAction();
      this.dialog.closeAll();
      this.showinput = false;
      if (res[0].Column1 === 'Updated Successfully') {
        this.toaster.success('Success', `Updated Sucessfully `);
      }
    });
  }

  readFile(event: any, type: any): void {
    this.imageFile = event.target.files[0];
    if (type === 'pic') {
      this.data.UploadFile = this.imageFile;
      this.ImageSave = event.target.files[0];
    } else {
      this.data.UploadDoc = this.imageFile;
      this.fileSave = event.target.files[0];
    }
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        if (type === 'pic') {
          this.data.FileSrc = this.imageSrc;
        } else {
          this.data.DocSrc = this.imageSrc;
        }
      };
    }
  }

  downloadPDF() {
    // [href]=" yData.DocSrc || blobStorageUrl + yData.UploadDoc"
    if (this.data.DocSrc) {
      const base64 = this.data.DocSrc;
      var a = document.createElement("a"); //Create <a>
      a.href = base64; //Image Base64 Goes here
      a.target = '_blank'
      a.download = "file.pdf"; //File name Here
      a.click();
    }
    else {
      var a = document.createElement("a"); //Create <a>
      a.href = this.data.UploadDoc; //Image Base64 Goes here
      a.download = "file.pdf";
      a.target = '_blank'//File name Here
      a.click();
    }
  }

  openFile(type: any): void {
    if (type === 'pic') {
      this.fileInput.nativeElement.click();
    } else {
      this.fileInputFile.nativeElement.click();
    }
  }

  setFirstWeek(): void {
    const dept = this.plants.find(item => item.DeptId === this.MeetingActionForm.value.plant);
    this.firstDayOfWeek = dept ? dept.firstDayOfWeek : 0;
    this.setWeekValue(this.firstDayOfWeek);
  }

  setWeekValue(weekDay: number): void {
    if (weekDay == undefined || weekDay == null || Number.isNaN(weekDay))
      weekDay = 0
    sessionStorage.removeItem('weekDay');
    sessionStorage.setItem('weekDay', weekDay.toString());
    this.datepickerService.getFirstDayOfWeek()
  }

  onTDateChange(): void {
        this.getAllMeetingAction();
  }

  uploadedFile(event: any): void {
    if (event.target.files.length > 0) {
      this.closefile = event.target.files[0];
    }
  }
  
}

function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}

function GenderCellRenderer(): void { }

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};
