
<ngx-spinner
bdColor="rgba(0, 0, 0, 0)"
template="<img src='assets/images/spinner.gif' />"
>
 <p style="color: white;">Loading...</p>  
</ngx-spinner>

<div class="machine-parameter-content" dir="{{languageDir}}">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a
          class="breadcrumb-link"
          [routerLink]="breadcrumItem.Url"
          [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
          >{{breadcrumItem.text}}</a
        >
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">{{langObj ? langObj?.GapAnalysis : ('GapAnalysis' | translate)}}</h1>
    </div>
  </div>
  <form [formGroup]="gapSearchForm">
    <div
      class="content-top-row"
      style="display: flex; justify-content: flex-end"
    >
      <mat-form-field
        appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      >
        <mat-label>{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}</mat-label>
        <mat-select
          panelClass="testClass"
          name="group"
          formControlName="group"
          (selectionChange)="getPlantByGroup()"
        >
        <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groupListDDL"
        (filteredReturn)="filteredGroupListDDL = $event"></mat-select-filter>
          <mat-option *ngFor="let g of filteredGroupListDDL" [value]="g.TypeId">
            {{ g.TypeName }}
          </mat-option>
        </mat-select>
        <mat-error>{{langObj ? langObj?.Pleaseselectgroup : ('Pleaseselectgroup' | translate)}}</mat-error>
      </mat-form-field>
      <mat-form-field
        appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      >
        <mat-label>{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}</mat-label>
        <mat-select
          panelClass="testClass"
          name="plant"
          formControlName="plant"
          (selectionChange)="getFrequencies()"
        >
        <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plants"
        (filteredReturn)="filteredPlants = $event"></mat-select-filter>
          <mat-option *ngFor="let p of filteredPlants" [value]="p.DeptId">
            {{ p.DeptDesc }}
          </mat-option>
        </mat-select>
        <mat-error>{{langObj ? langObj?.Pleaseselectplant : ('Pleaseselectplant' | translate)}}</mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="wrapper-box wrapper-box-shadow grid-container">
    <form [formGroup]="GapAnalysisForm">
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-label>{{langObj ? langObj?.SelectFrequency : ('SelectFrequency' | translate)}}</mat-label>
          <mat-select
            formControlName="frequency"
            (selectionChange)="onFrequencyChange()"
          >
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="frequencies"
          (filteredReturn)="filteredFrequencies = $event"></mat-select-filter>
            <mat-option
              *ngFor="let frequency of filteredFrequencies"
              [value]="frequency.frequencyId"
              >{{ frequency?.name }}
            </mat-option>
          </mat-select>
          <mat-error>{{langObj ? langObj?.Pleaseselectfrequency : ('Pleaseselectfrequency' | translate)}}</mat-error>
        </mat-form-field>
        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-label>{{langObj ? langObj?.kpi : ('kpi' | translate)}}</mat-label>
          <mat-select
            formControlName="kpi"
            (selectionChange)="selectAllIKpis()"
          >
            <mat-select-filter
              [placeholder]="'Search KPIs'"
              [displayMember]="'name'"
              [array]="kpisList"
              (filteredReturn)="kpiListTemp = $event"
            >
            </mat-select-filter>
            <mat-option (click)="selectAllIKpis()" value="0">All</mat-option>

            <mat-option *ngFor="let kpi of kpiListTemp" [value]="kpi.kpiId">{{
              kpi?.name
            }}</mat-option>
          </mat-select>
          <mat-error>{{langObj ? langObj?.PleaseselectKPI : ('PleaseselectKPI' | translate)}}</mat-error>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label> {{langObj ? langObj?.daterange : ('daterange' | translate)}}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="pickerForScorecard">
          <input matStartDate formControlName="fromDate" placeholder="From Date" [readOnly]="true" />
          <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="pickerForScorecard">
        </mat-datepicker-toggle>
        <mat-date-range-picker #pickerForScorecard (closed)="GetAllGapAnalysis()">
        </mat-date-range-picker>
      </mat-form-field>
        <!-- <div class="inputField">
          <nz-range-picker
            class="calender-input"
            [nzInputReadOnly]="true"
            nzMode="month"
            formControlName="datePicker"
            nzFormat="MM/yyyy"
            (ngModelChange)="GetAllGapAnalysis()"
          ></nz-range-picker>
          <span class="floating-text">{{langObj ? langObj?.DateRange : ('DateRange' | translate)}}</span>
        </div> -->
        <!-- <div class="inputField">
          <nz-date-picker class="calender-input" id="startPicker" formControlName="dateStart"
            nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true"></nz-date-picker>
          <span class="floating-text">Period From</span>
        </div> -->

        <!-- <div class="inputField">
          <nz-date-picker class="calender-input" id="endPicker" formControlName="dateEnd" nzFormat="dd/MM/yyyy HH:mm"
            [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true"></nz-date-picker>
          <span class="floating-text">Period To</span>
        </div> -->

        <button
          mat-mini-fab
          mat-button
          mat-dialog-close
          color="accent"
          (click)="openGapAnalysisModal()"
          pTooltip="Add KPI"
          tooltipPosition="top"
          style="margin-left: 30px; margin-top: 5px"
          *ngIf="IsWrite"
        >
          <i class="fa fa-plus" style="color: #fff"></i>
        </button>
        <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="primary"
        (click)="openPotentialProjectModal()"
        pTooltip="View Potential Project Details"
        tooltipPosition="top"
        style="margin-left: 10px; margin-top: 5px"
        *ngIf="IsWrite"
      >
        <i class="fa fa-eye" style="color: #fff"></i>
      </button>
      <mat-spinner-button [options]="spinnerButtonOptions" (btnClick)="onClickAnalysisKPI()"
      class="loader" id="selectedRows" style="margin: 6px 10px;">
    </mat-spinner-button>
    <mat-spinner-button [options]="spinnerButtonOptions1"   (btnClick)="onClickComparisionKPI()"
      class="loader" id="selectedRows" style="margin: 6px 10px;">
    </mat-spinner-button>
      </div>
    </form>

    <div class="menu-card-group">
      <div class="menu-card-item mat-card-height">
        <p class="title-text">{{langObj ? langObj?.PlannedSavingVsIdentifiedLoss : ('PlannedSavingVsIdentifiedLoss' | translate)}}</p>
        <p class="data-text" *ngIf="sumTotalLoss > 0">{{sumTotalSaving * 100 /sumTotalLoss | number : '1.2-2'}}%</p> 
        <p class="data-text" *ngIf="sumTotalLoss <= 0">0.00 %</p> 
        <div class="menu-bottom-icon" [ngClass]="{'rtlImage': languageDir == 'rtl'}">
          <img class="img" [src]="menuBlueIconUrl" alt="{{ menuBlueIconUrl }}" />
        </div>
      </div>
      <div class="menu-card-item mat-card-height">
        <p class="title-text">{{langObj ? langObj?.ActualSavingVsPlannedSaving : ('ActualSavingVsPlannedSaving' | translate)}}</p>
        <p class="data-text">{{sumTotalActual * 100 /sumTotalSaving  | number : '1.2-2'}}%</p>
        <div class="menu-bottom-icon" [ngClass]="{'rtlImage': languageDir == 'rtl'}">
          <img class="img" [src]="menuBlueIconUrl" alt="{{ menuBlueIconUrl }}" />
        </div>
      </div>
    </div>
 
    <ag-grid-angular
      style="width: 100%; height: 25vw; margin-top: 10px;"
      class="ag-theme-alpine"
      [rowData]="dataArray"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptionsParent"
      (gridReady)="onGridReady($event)"
      [pagination]="true"
      [paginationPageSize]="10"
      (cellValueChanged)="onCellValueChanged($event)"
      (cellClicked)="cellClicked($event)"
      (modelUpdated)="onModelUpdated()"
    >
    </ag-grid-angular>

    <div class="chart-group">
      <div class="chart-items">
        <div class="chart-title">{{langObj ? langObj?.TotalIdentified : ('TotalIdentified' | translate)}}</div>
        <div [chart]="totalIdentified"></div>
    </div>
    </div>
    <div class="chart-group">
      <div class="chart-items" style="width: 100%; align-items: center">
        <!-- <div class="chart-title">Financial Gap Analysis</div>
        <canvas id="POCompleted"></canvas>
      <div class="chart-items"> -->
        <!-- <div class="chart-title">Financial Gap Analysis</div>
        <canvas id="POCompleted"></canvas> -->
        <div class="chart-title">
          {{langObj ? langObj?.FinancialGapAnalysis : ('FinancialGapAnalysis' | translate)}}
          <div class="d-flex" style="float: right">
            <div>
              <p
                class="topBtn mr-2"
                (click)="sortData(10000); showPhase(1)"
                [class.phase-active]="activeButton === 1"
                matTooltipClass="custom-tooltip"
                matTooltip="ALL"
              >
                {{langObj ? langObj?.All : ('All' | translate)}}
              </p>
            </div>
            <div>
              <p
                class="topBtn mr-2"
                (click)="sortData(3); showPhase(2)"
                [class.phase-active]="activeButton === 2"
                matTooltipClass="custom-tooltip"
                matTooltip="Top 3"
              >
                Top 3
              </p>
            </div>
            <div>
              <p
                class="topBtn mr-2"
                (click)="sortData(5); showPhase(3)"
                [class.phase-active]="activeButton === 3"
                matTooltipClass="custom-tooltip"
                matTooltip="Top 5"
              >
                Top 5
              </p>
            </div>
            <div>
              <p
                class="topBtn mr-2"
                (click)="sortData(10); showPhase(4)"
                [class.phase-active]="activeButton === 4"
                matTooltipClass="custom-tooltip"
                matTooltip="Top 10"
              >
                Top10
              </p>
            </div>
          </div>
        </div>
        <div [chart]="POCompleted"></div>
      </div>
      <!-- </div> -->
    </div>
    <div class="chart-group">
      <div class="chart-items"   style="width: 100%; align-items: center">
          <div class="chart-title content-top-row content-top-row-order-processing"> {{langObj ? langObj?.FinancialGapAnalysisByKPI : ('FinancialGapAnalysisByKPI' | translate)}}   
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing w-50-dd">
              <mat-label>{{langObj ? langObj?.SelectKPI : ('SelectKPI' | translate)}}</mat-label>
              <mat-select multiple #select  (selectionChange)="onChangeKPI($event.value)" #select1>
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'KPIName'" [array]="selectAlldataArray" *ngIf="select1.focused"
                (filteredReturn)="filteredModules = $event"></mat-select-filter>
                <div class="select-all">
                  <mat-checkbox [(ngModel)]="allSelected"
                                  [ngModelOptions]="{standalone: true}"
                                  (change)="toggleAllSelection()">Select All</mat-checkbox>
              </div>
                <mat-option *ngFor="let kpi of filteredModules"  (click)="optionClick()" [value]="kpi">{{kpi.KPIName}}</mat-option>
              </mat-select>
              <mat-error>{{langObj ? langObj?.PleaseselectKPI : ('PleaseselectKPI' | translate)}}</mat-error>
            </mat-form-field>
          </div>
          <div [chart]="FinancialGapKPI"></div>
      </div>
    </div>
  
    
    <!--side panel to add/update kpi-->
    <div
      class="sidepanel-model-wrapper edit-plant-row-model"
      [ngClass]="{ active: gapAnalysisModelVisible }"
    >
      <div class="sidepanel-close" (click)="closeGapAnalysisModel()"></div>
      <div class="model-content">
        <div class="model-top">
          <h2 class="model-title">
            {{ addGapAnalysisForm ? "Add" : "Update" }} {{langObj ? langObj?.KPI : ('KPI' | translate)}}
          </h2>
          <button
            mat-icon-button
            class="model-close-btn"
            aria-label="Close model"
            (click)="closeGapAnalysisModel()"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div class="model-body">
          <form [formGroup]="addGapAnalysisForm">
            <div class="form-group">
              <mat-form-field appearance="fill" class="field-fill">
                <mat-label class="required">{{langObj ? langObj?.SelectFrequency : ('SelectFrequency' | translate)}}<span
                  style="color: red;">*</span></mat-label>
                <mat-select
                  name="plant"
                  formControlName="frequency"
                  (selectionChange)="onFrequencyChangeValue()"
                >
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="frequencies"
                (filteredReturn)="filteredFrequencies = $event"></mat-select-filter>
                  <mat-option
                    *ngFor="let frequency of filteredFrequencies"
                    [value]="frequency.frequencyId"
                    >{{ frequency?.name }}
                  </mat-option>
                </mat-select>
                <mat-error>{{langObj ? langObj?.Frequencyisrequired : ('Frequencyisrequired' | translate)}}</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="fill" class="field-fill">
                <mat-label class="required">{{langObj ? langObj?.kpi : ('kpi' | translate)}}<span
                  style="color: red;">*</span></mat-label>

                <mat-select name="plant" [multiple]="true" formControlName="kpi">
                  <mat-select-filter
                    [placeholder]="'Search KPI'"
                    [displayMember]="'name'"
                    [array]="kpisList2"
                    (filteredReturn)="kpiListTemp2 = $event"
                  >
                  </mat-select-filter>
                  <mat-option
                    *ngFor="let kpi of kpiListTemp2"
                    [value]="kpi.kpiId"
                    >{{ kpi?.name }}</mat-option
                  >
                </mat-select>
                <mat-error>{{langObj ? langObj?.KPIisrequired : ('KPIisrequired' | translate)}}</mat-error>
              </mat-form-field>
            </div>

            <!-- <div class="inputField">
          <nz-range-picker class="calender-input" [nzInputReadOnly]="true" nzMode="month" formControlName="datePicker"
            nzFormat="MM/yyyy" style="width: 100%; margin-left: 0px !important;"></nz-range-picker>
          <span class="floating-text">Date Range</span>
        </div> -->

            <!-- <div class="content-top-row inputField">
          <nz-date-picker style="width: 100%; margin-left: 0px !important;" class="calender-input" id="addstartPicker"
            formControlName="startDate" nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }"
            [nzInputReadOnly]="true"></nz-date-picker>
          <span class="floating-text" style="padding-left: 0px;">Period From</span>
        </div>

        <div class="content-top-row inputField">
          <nz-date-picker style="width: 100%; margin-left: 0px !important;" class="calender-input" id="addendPicker"
            formControlName="endDate" nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }"
            [nzInputReadOnly]="true"></nz-date-picker>
          <span class="floating-text" style="padding-left: 0px;">Period To</span>
        </div> -->

            <div class="model-form-actions">
              <button
                (click)="closeGapAnalysisModel()"
                type="button"
                mat-mini-fab
                mat-button
                mat-dialog-close
                color="warn"
                pTooltip="Cancel"
                tooltipPosition="top"
              >
                <i class="fa fa-close" style="color: #fff"></i>
              </button>
              <button
                type="submit"
                *ngIf="!GapAnalysisFormMode"
                [disabled]="!addGapAnalysisForm.valid"
                mat-mini-fab
                mat-button
                mat-dialog-close
                color="primary"
                pTooltip="Update"
                tooltipPosition="top"
                (click)="UpdateGapAnalysis()"
              >
                <i class="fa fa-pencil" style="color: #fff"></i>
              </button>
              <button
                type="submit"
                *ngIf="GapAnalysisFormMode"
                [disabled]="!addGapAnalysisForm.valid"
                mat-mini-fab
                mat-button
                mat-dialog-close
                color="primary"
                pTooltip="Save"
                tooltipPosition="top"
                (click)="AddGapAnalysis()"
              >
                <i class="fa fa-floppy-o" style="color: #fff"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Potential popup -->
    <ng-template #displayPotentialModal>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{langObj ? langObj?.PotentialSavings : ('PotentialSavings' | translate)}}</h2>
          <button
            mat-mini-fab
            mat-button
            mat-dialog-close
            color="warn"
            title="close"
          >
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>
      <mat-dialog-content class="popup" style="height: 85%">
        <form [formGroup]="potentialForm">
          <div class="content-top-row" *ngIf="!isClosing">
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <mat-label class="required">{{langObj ? langObj?.ProjectName : ('ProjectName' | translate)}}</mat-label>
              <input matInput formControlName="projectName" required />
            </mat-form-field>

            <div class="inputField">
              <nz-date-picker
                class="calender-input"
                nzMode="month"
                id="startPicker"
                formControlName="projectDuration"
                nzFormat="MM/yyyy"
                [required]="true"
              >
              </nz-date-picker>
              <span class="floating-text" style="margin-left: 10px"
                >{{langObj ? langObj?.ProjectDuration : ('ProjectDuration' | translate)}}</span
              >
            </div>
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <mat-label class="required">{{langObj ? langObj?.Responsible : ('Responsible' | translate)}}</mat-label>
              <mat-select
                ngxMatSelect
                [hasSearchBox]="true"
                [useMobileView]="true"
                mobileViewType="BottomSheet"
                [formControlName]="'responsible'"
                [multiple]="false"
                name="fullName"
                [required]="true"
                [source]="UserDDL"
                [displayMember]="'fullName'"
                [placeholder]="'Responsible Name'"
                [valueMember]="'EmpInfo_Id'"
                #sf2="ngxMatSelect"
              >
                <mat-option
                  [value]="item.EmpInfo_Id"
                  *ngFor="let item of sf2.filteredSource"
                >
                  {{ item.fullName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <mat-label class="required">{{langObj ? langObj?.ProjectPotentialSavings : ('ProjectPotentialSavings' | translate)}}</mat-label>
              <input
                matInput
                type="number"
                formControlName="projectSaving"
                required
              />
            </mat-form-field>

            <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-label class="required">{{langObj ? langObj?.ProjectCost : ('ProjectCost' | translate)}}</mat-label>
            <input
              matInput
              type="number"
              formControlName="projectCost"
              required
            />
          </mat-form-field>

            <button
              type="submit"
              *ngIf="potentialformMode"
              mat-mini-fab
              mat-button
              color="primary"
              (click)="savePotential()"
              matTooltip="Save"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              style="margin-left: 10px; margin-bottom: 5px"
              [disabled]="!potentialForm.valid"
            >
              <mat-icon>save</mat-icon>
            </button>

            <button
              type="submit"
              *ngIf="!potentialformMode"
              mat-mini-fab
              mat-button
              color="primary"
              (click)="updatePotential()"
              matTooltip="Save"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              style="margin-left: 10px; margin-bottom: 5px"
              [disabled]="!potentialForm.valid"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <div class="content-top-row" *ngIf="isClosing">
            <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>{{langObj ? langObj?.ActualSavings : ('ActualSavings' | translate)}}</mat-label>
              <input matInput type="number" formControlName="actualSaving">
            </mat-form-field>
    
            <div class="inputField">
              <nz-date-picker class="calender-input"  id="startPickerClosing" formControlName="closingDate"
                nzFormat="dd/MM/yyyy">
              </nz-date-picker>
              <span class="floating-text" style="margin-left: 10px;">{{langObj ? langObj?.ClosingDate : ('ClosingDate' | translate)}}</span>
            </div>
    
            <button type="submit"  mat-mini-fab mat-button color="primary"
              (click)="updatePotential()" matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"
              style="margin-left:10px;margin-bottom: 5px;">
              <mat-icon>save</mat-icon>
            </button>
          </div>
        </form>
        <ag-grid-angular
          style="width: 100%; margin-top: 10px"
          id="potentialGrid"
          class="ag-theme-alpine"
          [rowData]="potentialArray"
          (cellClicked)="potentialCellClicked($event)"
          [columnDefs]="potentialColumnDefs"
          (gridReady)="onPotentialGridReady($event)"
          [pagination]="true"
          [paginationPageSize]="10"
          [gridOptions]="gridOptions"
        >
        </ag-grid-angular>
      </mat-dialog-content>
    </ng-template>

    <!-- popup potential project details -->
    <ng-template #displayPotentialProjectModal>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{langObj ? langObj?.PotentialProjectDetails : ('PotentialProjectDetails' | translate)}}</h2>
          <button
            mat-mini-fab
            mat-button
            mat-dialog-close
            color="warn"
            title="close"
          >
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>
      <mat-dialog-content class="popup" style="height: 85%">
        
        <ag-grid-angular
          style="width: 100%; margin-top: 10px"
          id="potentialGrid"
          class="ag-theme-alpine"
          [rowData]="potentialProjectArray"
          [columnDefs]="potentialProjectColumnDefs"
          (gridReady)="onPotentialGridReady($event)"
          [pagination]="true"
          [paginationPageSize]="10"
          [gridOptions]="gridOptions"
        >
        </ag-grid-angular>
      </mat-dialog-content>
    </ng-template>
    <ng-template #visibleYearlyTarget style="padding-bottom: 0px !important;">
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{langObj ? langObj?.KPIAnalysis : ('KPIAnalysis' | translate)}}</h2>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff;"></i>
          </button>
        </div>
      </div>
      <mat-dialog-content class="chartpopup">
        <!-- <canvas  style="width: 600px ;padding: 0px;" id="POClosed"></canvas>   -->
        <div *ngFor="let item of chartArray">
          <div [id]="item.id" [chart]="item.chartOption"></div>
           <!-- <div [id]="item.id" [chart]="stock"></div> -->
        </div>
       
      </mat-dialog-content>
    </ng-template>
    <ng-template #visibleKPIComparison style="padding-bottom: 0px !important;">
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{langObj ? langObj?.KPIComparison : ('KPIComparison' | translate)}}</h2>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff;"></i>
          </button>
        </div>
      </div>
      <mat-dialog-content class="chartpopup">
        <div [chart]="chart"></div>
      </mat-dialog-content>
    </ng-template>
  </div>
</div>
