import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { KpimasterService } from '../../services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { UserPlant } from 'src/app/models/userPlant';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
import { TranslaterService } from 'src/app/services/translater.service';
@Component({
  selector: 'app-racimatrix',
  templateUrl: './racimatrix.component.html',
  styleUrls: ['./racimatrix.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RacimatrixComponent implements OnInit, AfterViewInit {
  IsAccess: any;
  breadcrumList: any;
  IsWrite: any;
  groupList: any;
  loadingOverlayComponent: any;
  frameworkComponents: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  constructor(
    private toaster: ToastrService,
    private kpiMaster: KpimasterService,
    private fb: FormBuilder,
    private usermanagementService: UserManagementService,
    public router: Router,
    private translater: TranslaterService,
  ) {
    this.loadingOverlayComponent = 'customLoadingOverlay';
  }
  RaciMatrixForm!: FormGroup;
  rowData!: any[];
  columnDefs!: any[];
  searchPlants!: UserPlant[];
  plants!: UserPlant[];
  dataArray!: [];
  gridApi: any;
  components!: any;
  gridApiEdit: any;
  dataArraykpi!: [];
  gridColumnApi: any;
  gridColumnApiEdit: any;
  paginationPageSize!: any;
  rowDefinations!: any[];
  totalR = 0;
  totalA = 0;
  totalC = 0;
  totalI = 0;
  gridOptions: any = {
    paginationPageSize: 10,
    defaultColDef: {
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true, // <-- HERE
      autoHeight: true, // <-- & HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    frameworkComponents: {
      customLoadingOverlay: CustomLoadingOverlay,
    },
  };
  ngAfterViewInit(): void {
    this.gridOptions.api.hideOverlay();
  }
  ngOnInit(): void {
    this.RaciMatrixForm = this.fb.group({
      plant: [''],
      group: [''],
    });
    this.getGroupData();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    //this.getLangWords();
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        const obj = this.groupList[0];
        this.RaciMatrixForm.get('group')?.setValue(obj);
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlants(): void {
    const key = {
      GroupId: this.RaciMatrixForm.value.group.TypeId,
    };
    this.usermanagementService.getDepartments(key).subscribe(
      (res: any) => {
        this.searchPlants = res.DepartList;
        this.plants = res.DepartList;
        const plantObject = this.searchPlants[0];
        this.RaciMatrixForm.get('plant')?.setValue(plantObject);
        this.onPlantChange();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }
  headerHeightSetter(): void {
    const padding = 20;
    const height = headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }
  generateColumns(data: any[]): any {
    let columnDefinitions: any[] = [];

    data.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key.toUpperCase(),
          headerClass: {},
          field: key,
          width: 170,
          filter: true,
          // height: 200,
          // suppressSizeToFit: true,
          cellStyle: {},
        };

        mappedColumn.headerClass = (params: any): any => {
          if (
            key !== 'Driver' &&
            key !== 'Pillar' &&
            key !== 'KPI Name' &&
            key !== 'Frequency' &&
            key !== 'R' &&
            key !== 'A' &&
            key !== 'C' &&
            key !== 'I'
          ) {
            return 'header-style';
          }
        };

        if (
          key !== 'Driver' &&
          key !== 'Pillar' &&
          key !== 'KPI Name' &&
          key !== 'Frequency'
        ) {
          mappedColumn.width = 120;
        }

        mappedColumn.cellStyle = (params: any): any => {
          if (
            key !== 'Driver' &&
            key !== 'Pillar' &&
            key !== 'KPI Name' &&
            key !== 'Frequency'
          ) {
            const styleObj = {
              'font-weight': 'bold',
              'background-color': '',
              color: '',
            };
            const raciValue = params.value;
            if (raciValue === 'R') {
              styleObj['background-color'] = '#FFCC99';
              styleObj.color = 'white';
            } else if (raciValue === 'A') {
              styleObj['background-color'] = '#BFBFBF';
              styleObj.color = 'white';
            } else if (raciValue === 'C') {
              styleObj['background-color'] = '#00B050';
              styleObj.color = 'white';
            } else if (raciValue === 'I') {
              styleObj['background-color'] = '#4472C4';
              styleObj.color = 'white';
            }

            return styleObj;
          }
        };
        columnDefinitions.push(mappedColumn);
      });
    });

    // for (let i = 0; i < 4; i++) {
    //   const Arr = ['R', 'A', 'C', 'I'];
    //   const RaciData = { headerName: Arr[i], field: Arr[i], width: 100 };
    //   columnDefinitions.push(RaciData);
    // }

    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );

    return columnDefinitions;
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#myGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }
  onGridReadyEdit(params: any): any {
    this.gridApiEdit = params.api;
    this.gridColumnApiEdit = params.columnApi;
  }

  // getAllPlants(): void {
  //   const key = {
  //     userId: JSON.parse(localStorage.user).UserId,
  //   };
  //   this.kpiMaster.getUserPlants(key).subscribe(
  //     (plants) => {
  //       this.searchPlants = plants;
  //       this.plants = plants;
  //       if (this.searchPlants.length === 1) {
  //         const plantObject = this.searchPlants[0];
  //         this.RaciMatrixForm.get('plant')?.setValue(plantObject);
  //         this.onPlantChange();
  //       }
  //     },
  //     (error) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }
  onPlantChange(): any {
    const plant = {
      plantId: this.RaciMatrixForm.value.plant.DeptId,
    };
    this.gridApi.showLoadingOverlay();
    this.kpiMaster.getRaciMatrix(plant).subscribe(
      (data: any) => {
        this.gridOptions.api.hideOverlay();
        if (data !== undefined) {
          this.rowData = data;
          if (this.rowData) {
            this.columnDefs = this.generateColumns(this.rowData);
            setTimeout(() => {
              this.gridOptions.api.columnController.resetColumnState();
            }, 100);
          }
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
function headerHeightGetter(): any {
  const columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');

  const columnHeaderTextsArray: Element[] = [];

  columnHeaderTexts.forEach((node) => columnHeaderTextsArray.push(node));

  const clientHeights = columnHeaderTextsArray.map(
    (headerText) => headerText.clientHeight
  );
  const tallestHeaderTextHeight = Math.max(...clientHeights);
  return tallestHeaderTextHeight;
}
