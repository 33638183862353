import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

const moment = _moment;


@Component({
  selector: 'app-performance-analysis',
  templateUrl: './performance-analysis.component.html',
  styleUrls: ['./performance-analysis.component.scss']
})
export class PerformanceAnalysisComponent implements OnInit {


  dropDownForm!: FormGroup;
  //Drop-down Variables
  yearList = ['2015', '2016', '2017', '2018', '2019', '2020', '2021'];
  monthList = ['ALL', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  weekList = ['ALL', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  dayList = ['ALL', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  shiftList = ['ALL', 'Morning', 'Evening', 'Night'];
  machineList = ['ALL', 'RTM1', 'RTM2'];

  // Date Time Range
  range = new FormGroup({
    startDate: new FormControl(moment()),
    endDate: new FormControl(moment())
  });

  // Charts

  // OEE Loss
  oeeLoss = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    barChartData: [
      { data: [1.4, [1.2, 1.4], 1.2, 0, 0, 0, 0, 0], label: '  Target OEE' },
      { data: [0, 0, 0, [1, 1.2], [0.9, 1.2], [0.7, 0.9], [0.6, 0.7], 0], label: '  Losses' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0.6], label: '  Total' },
    ],
    barChartColors: [
      { backgroundColor: "#040496" },
      { backgroundColor: "#F85439" },
      { backgroundColor: "#10CE9C" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 0.4,
            }
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 40,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  };

  // Performance Loses
  performanceLoses = {
    barChartLabels: ['Performance', 'Rate Loss 1', 'Rate Loss 2', 'Target'],
    barChartData: [
      { data: [62, [62, 80], [80, 100.1], 0], label: '  Achieved' },
      { data: [0, 0, 0, 0], label: '  Losses' },
      { data: [0, 0, 0, 100.1], label: '  Target' },
    ],
    barChartColors: [
      { backgroundColor: "#F85439" },
      { backgroundColor: "#10CE9C" },
      { backgroundColor: "#040496" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 20,
              callback: function (value: any, index: any): any {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            },
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 60,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  };

  // Rate Loss
  rateLoss = {
    barChartLabels: ['RL1', 'RL2', 'RL3'],
    barChartData: [
      { data: [15, 8, 4], label: '  Lorem Ipsem' }
    ],
    barChartColors: [
      { backgroundColor: "#F85439" },
    ],
    barChartOptions: {
      labelString: "  Percentage",
      tooltips: {
        callbacks: {
          label: function (tooltipItem: any, data: any): any {
            return data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display:false,
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              callback: function (value: any, index: any): any {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }
        ],
        xAxes: [{
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  };

  // Actual Speed vs Target Speed
  speedActualTarget = {
    barChartLabels: ['PET1', 'PET2', 'PET3', 'PET4'],
    barChartData: [
      { data: [135, 120, 100, 120], label: '  Actual Speed' },
      { data: [152, 125, 110, 125, 86, 27, 90], label: '  Target Speed' }
    ],
    barChartColors: [
      { backgroundColor: "#10CE9C" },
      { backgroundColor: "#040496" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 50,
              beginAtZero: true
            }
          }
        ],
        xAxes: [{
          barThickness: 30,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  };

  // Production Orders - Actual vs Target
  productionOrdersSpeedActualTarget = {
    barChartLabels: ['PO-1', 'PO-2', 'PO-3', 'PO-4'],
    barChartData: [
      { data: [62, 55, 30, 35], label: '  Actual' },
      { data: [70, 60, 35, 39], label: '  Plan' }
    ],
    barChartColors: [
      { backgroundColor: "#040496" },
      { backgroundColor: "#10CE9C" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 20,
              beginAtZero: true
            }
          }
        ],
        xAxes: [{
          barThickness: 30,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  };

  // output - Actual vs Ideal
  outputActualIdeal = {
    barChartLabels: ['Actual Output', 'Actual Output'],
    barChartData: [
      { data: [70, 0], label: '  Plan' },
      { data: [0, 60], label: '  Actual' }
    ],
    barChartColors: [
      { backgroundColor: "#10CE9C" },
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 20,
              beginAtZero: true
            }
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 70,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  };

  // Actual Speed Trend
  actualSpeedTrend = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11'],
    barChartData: [
      { data: [4, 2.9, 4.8, 4.4, 2.8, 4.4, 3.6, 3.2, 4.4, 4.8, 4.4], label: '  Target' },
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  };
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  constructor(
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    Chart.defaults.global.legend.labels.usePointStyle = true;
    this.dropDownForm = this.formBuilder.group({
      groupList: [''],
      UnitDataList: [''],
    });

    this.GetMenuAccess();
    this.GetBreadcrumList();
  }

    //breadCrumb

    GetMenuAccess(): void {
      const key = {
        Id: localStorage.getItem('O3RightMenuId')
      };
      this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
  
      },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    }
    GetBreadcrumList(): any{
      const obj = {
        MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
      };
      // tslint:disable-next-line: align
      this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
        this.breadcrumList = data.BreadcrumList;
        console.log(data.BreadcrumList);
      }, (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      });
    }
    DisplayRightmenu(menuId: any, url: any): any {
      localStorage.setItem('O3RightMenuId', menuId);
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([url]));
      // return false;
    }
  
  

}





