import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import { Uom } from 'src/app/models/uom';
import { UserManu } from 'src/app/models/userManu';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import Swal from 'sweetalert2';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
@Component({
  selector: 'app-uom',
  templateUrl: './uom.component.html',
  styleUrls: ['./uom.component.scss'],
})
export class UomComponent implements OnInit {
  @Input() uPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<Uom>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  uoms!: Uom[];
  uomId!: number;
  plants!: Plantbygroup[];
  uomForm!: FormGroup;
  formMode!: boolean;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  plantId!: number;

  uomModelVisible = false;

  uomColumns: string[] = ['uomActions', 'plant', 'name', ];
  uomData!: MatTableDataSource<Uom>;
  IsAccess: any;
  IsWrite: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!:any;
  breadcrumList: any;

  constructor(
    private kpiService: KpimasterService,
    private toaster: ToastrService,
    private platModelService: EnterPriseGroupService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private translater: TranslaterService,
    private usermanagementService: UserManagementService,
    ) {
    }
  ngOnInit(): void {
    //this.getLangWords();
    this.GetMenuAccess();
    this.GetBreadcrumList();
    this.isRead = this.uPermissions.IsAccess;
    this.isWrite = this.uPermissions.IsWrite;
    this.isUpdate = this.uPermissions.IsWrite;
    this.isDelete = this.uPermissions.IsWrite;
    this.formMode = true;
    this.uomForm = this.fb.group({
      uomname: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      plant: ['', Validators.required],
    });
  }
 
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getPaginator(): void {
    setTimeout(() => {
      this.uomData.paginator = this.paginator;
      this.uomData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.uomData.filter = filterValue.trim().toLowerCase();

    if (this.uomData.paginator) {
      this.uomData.paginator.firstPage();
    }
  }

  getPlants(): void {
    this.kpiService.getAllPlants().subscribe(
      (plants) => {
        this.plants = plants;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  openModal(): void {
    if (this.isWrite) {
      this.resetForm();
      this.uomForm.get('plant')?.setValue(this.plantId);
      this.uomModelVisible = true;
      this.formMode = true;
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  getMasterUoms(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.kpiService.getMasterUoms(key).subscribe(
      (uoms) => {
        this.uomData = new MatTableDataSource<Uom>(uoms);
        this.getPaginator();
        this.getPlants();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  getUoms(): void {
    this.spinner.show();

    const key = {
      plantId: this.plantId,
    };
    this.kpiService.getMasterUoms(key).subscribe(
      (uoms) => {
        this.spinner.hide();

        this.uomData = new MatTableDataSource<Uom>(uoms);
        this.getPaginator();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getOneUom(uomId: number): void {
    if (this.isUpdate) {
      const key = {
        uomId,
      };
      this.kpiService.getOneUom(key).subscribe(
        (uom) => {
          this.uomId = uomId;
          this.uomForm.get('uomname')?.setValue(uom.name);
          this.plants = [];
          this.getPlants();
          this.uomForm.get('plant')?.setValue(this.plantId);
          this.formMode = false;
          this.uomModelVisible = true;
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  submitForm(): void {
    this.spinner.show();

    const uom = {
      plant: this.uomForm.value.plant,
      name: this.uomForm.value.uomname,
    };
    this.kpiService.addUom(uom).subscribe(
      (response) => {
        this.spinner.hide();

        this.toaster.success('Success', 'Uom Added Successfully');
        this.getUoms();
        this.closeUomModel();
        this.resetForm();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }

  updateForm(): void {
    this.spinner.show();

    const uom = {
      uomId: this.uomId,
      plant: this.uomForm.value.plant,
      name: this.uomForm.value.uomname,
    };
    this.kpiService.updateUom(uom).subscribe(
      (response) => {
        this.spinner.hide();

        this.toaster.success('Success', 'Uom Updated Successfully');
        this.getUoms();
        this.closeUomModel();
        this.resetForm();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }

  deleteUom(uomId: number): void {
    if (this.isDelete) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete unit of measure!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          const key = {
            uomId,
          };
          this.kpiService.deletUom(key).subscribe(
            (success) => {
              Swal.fire({
                title: 'Deleted!',
                text: 'Your selected Unit Of Measure has been deleted.',
                icon: 'success',
                timer: 800,
                showConfirmButton: false,
              });
          this.spinner.hide();

              this.getUoms();
            },
            (error) => {
          this.spinner.hide();

              this.toaster.error('Error', error.error.message);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your unit of measure is safe :)', 'error');
        }
      });
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  resetForm(): void {
    this.uomForm.reset();
  }
  closeUomModel(): void {
    this.uomModelVisible = false;
  }
}
