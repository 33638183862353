import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms'
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { OpmsGlassService } from '../../services/opms-glass.service';
import "ag-grid-enterprise";
import { MatDialog } from '@angular/material/dialog';
import { BtnCellRendererQxMatrix } from 'src/app/btn-cell-rendererQxMatrix';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

// Thickness*LehrSpeed*GrossWidth*0.00006
enum nominal {
 "'3'" = 2.85,
 "'3.2'" = 3.15,
 "'3.5'" = 3.35,
 "'4'" = 3.75,
 "'5'" = 4.75,
 "'5.5'" = 5.25,
 "'6'" = 5.75,
 "'8'" = 7.65,
 "'10'" = 9.65,
 "'12'" = 11.55,
 "'12.12'" = 12.1,
 "'3(EN)'" = 2.95,
 "'3.2(EN)'" = 3.2,
 "'3.5(EN)'" = 3.4,
 "'4(EN)'" = 3.9,
 "'5(EN)'" = 4.9,
 "'5.5(EN)'" = 5.35,
 "'6(EN)'" = 5.9,
 "'8(EN)'" = 7.85,
 "'10(EN)'" = 9.85,
 "'12(EN)'" = 11.85,
 "'3.9'" = 3.75,
 "'5.4'" = 5.25,
 "'5.9'" = 5.75,
 "'9.6'" = 9.65,
 "'3 ASTM'"= 3.1,
 "'3.2 ASTM'"= 3.2,
 "'3.5 ASTM'"= 3.5,
 "'4 ASTM'"= 3.95,
 "'5 ASTM'"= 4.8,
 "'6 ASTM'"= 5.75,
 "'8 ASTM'"= 7.65,
 "'10 ASTM'"= 10.1,
 "'12 ASTM'"= 12.1,
}
@Component({
  selector: 'app-opms-data',
  templateUrl: './opms-data.component.html',
  styleUrls: ['./opms-data.component.scss']
})
export class OpmsDataComponent implements OnInit {

  @ViewChild('shiftModel') private shiftModel!: TemplateRef<any>;
  @ViewChild('hourlyDataModel') private hourlyDataModel!: TemplateRef<any>;
  @ViewChild('productionLogModel') private productionLogModel!: TemplateRef<any>;
  @ViewChild('yieldLossesModel') private yieldLossesModel!: TemplateRef<any>;
  @ViewChild('repackingModel') private repacking!: TemplateRef<any>;
  @ViewChild('eventModel') private eventModel!: TemplateRef<any>;

  gridApishiftModel: any;
  gridColApishiftModel: any;
  panelOpenState = false;
  frameworkComponents: any;
  ShiftOPMS!: FormGroup;
  HourlyData!: FormGroup;
  ProductionLog!: FormGroup;
  Event!: FormGroup;
  data:any;
  shiftList: any;
  crewList: any;
  thicknessList: any;
  shiftManagerList: any;
  ShiftReportId = 0;
  HdTransTypeId = 1;
  HdId = 0;
  EventTrandTypeId = 1;
  EventId = 0;
  buttonType: any;
  hdPullYieldLosses: any;
  productionTransId = 1;
  pLId = 0;
  yieldlossInfoId = 0;
  yieldTransId = 1;
  boxType: any;
  qualityMenu: any;
  stackerMenu: any;
  destinationMenu: any;
  box_select: any;
  quality_select: any;
  stacker_select: any;
  destination_select: any;
  thickness: any;
  nominal_Thick = 0;
  box_weight!: number;
  yieldForm = new FormGroup({});
  yieldParameters: any[] = [];
  yield_Loss: any;
  total_Loss: any;
  hours: any;
  productionHours: any = [];
  yieldHours: any = [];
  oldHourlyTime: any;
  oldHourlyThickness: any;
  oldHourlyPullYL: any;
  yieldByPlUpdation = 0
  day = [
    {htime: '08:00:00', hvalue: '08.00'},
    {htime: '09:00:00', hvalue: '09.00'},
    {htime: '10:00:00', hvalue: '10.00'},
    {htime: '11:00:00', hvalue: '11.00'},
    {htime: '12:00:00', hvalue: '12.00'},
    {htime: '13:00:00', hvalue: '13.00'},
    {htime: '14:00:00', hvalue: '14.00'},
    {htime: '15:00:00', hvalue: '15.00'},
  ]
  evening = [
    {htime: '16:00:00', hvalue: '16.00'},
    {htime: '17:00:00', hvalue: '17.00'},
    {htime: '18:00:00', hvalue: '18.00'},
    {htime: '19:00:00', hvalue: '19.00'},
    {htime: '20:00:00', hvalue: '20.00'},
    {htime: '21:00:00', hvalue: '21.00'},
    {htime: '22:00:00', hvalue: '22.00'},
    {htime: '23:00:00', hvalue: '23.00'},
  ]
  night = [
    {htime: '00:00:00', hvalue: '00.00'},
    {htime: '01:00:00', hvalue: '01.00'},
    {htime: '02:00:00', hvalue: '02.00'},
    {htime: '03:00:00', hvalue: '03.00'},
    {htime: '04:00:00', hvalue: '04.00'},
    {htime: '05:00:00', hvalue: '05.00'},
    {htime: '06:00:00', hvalue: '06.00'},
    {htime: '07:00:00', hvalue: '07.00'},
  ]
  gridOptions: any = {
    frameworkComponents: {
      buttonCellRenderer : BtnCellRendererQxMatrix,
    }
  };
  oldHour: any;
  oldPlHour: any;
  YieldExistWithNewTime: any;
  YieldExistWithOldTime: any;
  yieldNew: boolean = false;
  oldYieldtime: any = '';
  oldActualThickness: any;
  isHotEndUser: boolean = true;
  hotEndAreaList: any;
  isOPMSUser: boolean = true;
  eventPannelTitle: string = 'OPMS Event';
  disableFutureDates = (current: Date): boolean => {
    // Disable dates after tomorrow
    return current && current > new Date((new Date()).setDate((new Date).getDate() + 1));
  };

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private opmsGlassservice: OpmsGlassService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
  ) {}

   LoaddefaultFeildOPMS(){
     this.GetHourlyData();
     this.GetEvent();
     this.getRepacking();
     this.getProductionLog();
     this.getYieldLoss();
     this.RefreshHourlyData();
     this.refreshProductionLog();
     this.refreshYield();
     this.resetRepackingGroup();
     this.RefreshEvent();
   }

   RefreshshiftinfoOPMS(){
    this.ShiftReportId = 0;
    this.ShiftOPMS.get("date")?.setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm'));
    this.ShiftOPMS.get("shift")?.setValue("");
    this.ShiftOPMS.get("crew")?.setValue("");
    this.RefreshHourlyData();
    this.refreshProductionLog();
    this.refreshYield();
    this.resetRepackingGroup();
    this.RefreshEvent();
    this.rowHourlyData = [];
    this.rowHourlyDataModel = [];
    this.rowProductionLog = [];
    this.rowProductionLogModel = [];
    this.rowYieldLosses = [];
    this.rowRepacking = [];
    this.rowRepackingModel = [];
    this.rowEvent = [];
    this.rowEventModel = [];
    this.rowProductionReporting = [];
    this.hours = [];
   }

// Start Shift Data
   LoadBaseDetails(){
     this.data = {
      UserId: JSON.parse(localStorage.user).UserId
     }
     this.opmsGlassservice.loadBaseDetails(this.data).subscribe(
       (response: any)=>{
         this.shiftList = response.ShiftList;
         this.crewList = response.CrewList;
         this.thicknessList = response.ThicknessList;
         this.shiftManagerList = response.ShiftMAnagerList;
         if(this.isOPMSUser && this.isHotEndUser){
          this.hotEndAreaList = response.HotEndAreaList;
         }
         else if(this.isOPMSUser && !this.isHotEndUser){
          this.hotEndAreaList = response.HotEndAreaList.filter((option: any) => {
                                  return option.TypeName != 'Hot End Log';
                                });
         }
         else{
          this.hotEndAreaList = response.HotEndAreaList.filter((option: any) => {
                                  return option.TypeName == 'Hot End Log';
                                });
         }
         this.ShiftOPMS.get("shift")?.setValue(response.ShiftList[0].TypeId);
         this.ShiftOPMS.get("crew")?.setValue(response.CrewList[0].TypeId);
         this.ShiftOPMS.get('manager')?.setValue(response.ShiftMAnagerList[0].TypeId);
       },
     )

     this.opmsGlassservice.getBoxtypeDropdown().subscribe(
      (response: any)=>{
        this.boxType = response.DropDownList;
      },
     )

     this.opmsGlassservice.getQualityDropdown().subscribe(
      (response: any)=>{
        this.qualityMenu = response.DropDownList;
      },
     )

     this.opmsGlassservice.getStackerDropdown().subscribe(
      (response: any)=>{
        this.stackerMenu = response.DropDownList;
      },
     )

     this.opmsGlassservice.getDestinationDropdown().subscribe(
      (response: any)=>{
        this.destinationMenu = response.DropDownList;
      },
     )

   }

   SubmitshiftOPMSinfo(){
    if(this.ShiftOPMS.value.shift && this.ShiftOPMS.value.crew){
      this.data = {
      TransactionTypeId: (this.ShiftReportId==0) ? 1 : 2,
      ShiftReportId: this.ShiftReportId,
      ShiftTypeId: this.ShiftOPMS.value.shift,
      CrewTypeId: this.ShiftOPMS.value.crew,
      ShiftManagerTypeId: this.ShiftOPMS.value.manager,
      Date: this.ShiftOPMS.value.date,
      CreatedBy: JSON.parse(localStorage.user).UserId
     }
     this.opmsGlassservice.submitshiftOPMSinfo(this.data).subscribe(
       (response: any)=>{
         if(!response.HasError){
           if(response.ErrorMessage!=="C1-Shift Report already exists!"){
             if(this.ShiftReportId==0){
              this.toaster.success("Shift Report Saved Successfuly");
             }
             else{
              this.toaster.success("Shift Report Updated Successfuly");
             }
              this.ShiftReportId = response.ShiftReportId;
              this.LoaddefaultFeildOPMS();
          }
          else{
            this.toaster.error("Shift Report Already Exist");
            this.RefreshshiftinfoOPMS(); 
          }
          if(this.ShiftOPMS.value.shift == 1){
            this.hours = this.day;
          }
          else if(this.ShiftOPMS.value.shift == 2){
            this.hours = this.evening;
          }
          else{
            this.hours = this.night;
          }
         }
         else{
          this.toaster.error('Error', response.ErrorMessage);
         }
       }
     )
    }
    else{
      this.toaster.warning('Waring','Please Select Shift and Crew');
    }
   }
  // Start Shift Model
  columnDefsShiftModel = [ 
    {headerName: 'ShiftId', field: 'ShiftReportId'},
    {headerName: 'Date', field: 'Date', valueFormatter: this.dateFormatter},
    {headerName: 'Shift', field: 'Shift'},
    {headerName: 'Shift', field: 'ShiftId', hide: true},
    {headerName: 'Crew', field: 'Crew'},
    {headerName: 'Crew', field: 'CrewId', hide: true},
    {headerName: 'ShiftManager', field: 'ShiftManager'},
    {headerName: 'Created On', field: 'CreatedOn', valueFormatter: this.dateFormatter},
    {headerName: 'Created By', field: 'CreatedBy'},
    {
      field: 'Action', cellRenderer: 'buttonCellRenderer', editable: false,
      cellRendererParams: { clicked: (field: any) => {} }
    }
  ]
  defaultColDefShiftModel: any =  {
    sortable: true,
    filter: true,
    resizable: true,
  }
  rowShiftModel: any = []

  LoadshiftOPMSHistory(){
    this.rowShiftModel = [];
    this.data = {
      ShiftReportId: 0,
      UserId: JSON.parse(localStorage.user).UserId,
    }
    this.opmsGlassservice.loadshiftOPMSHistory(this.data).subscribe(
      (response : any)=>{
        this.rowShiftModel = response.ShiftReportList;
      },

    )
    this.dialog.open(this.shiftModel, {
      width: '90vw !important',
      height: '90vh', minWidth: '90vw'
    });
  }
  
  oncellclicked(params: any): void {
    const col = params.colDef.field;
    this.ShiftReportId = params.data.ShiftReportId;
    this.buttonType = '';
    if (col === 'Action') {
      this.buttonType = params.event.target.textContent;
      if(this.buttonType === 'edit'){
        this.data = {
          ShiftReportId: params.data.ShiftReportId,
          Date: this.datePipe.transform(params.data.Date, "yyyy-MM-dd hh:mm a"),
          ShiftId: params.data.ShiftId,
          CrewId: params.data.CrewId,
          ShiftManagerId: params.data.ShiftManagerId
        }
        this.EditshiftOPMSReport(this.data);
      }
      else if(this.buttonType === 'delete' && this.isOPMSUser){
        this.data = {
          TransactionTypeId: 3,
          ShiftReportId: params.data.ShiftReportId,
          Date: this.datePipe.transform(params.data.Date, 'yyyy-MM-dd hh:mm a'),
          ShiftTypeId: params.data.ShiftId,
          CrewTypeId: params.data.CrewId,
          CreatedBy: JSON.parse(localStorage.user).UserId,
        }
        this.DeleteshiftOPMSReport(this.data,params.data);
      }
      else{
        this.toaster.warning('Warning',"User Does not have access to perform this action");
      }
    }
  }

  EditshiftOPMSReport(data: any){
    this.ShiftOPMS.get('date')?.setValue(data.Date);
    this.ShiftOPMS.get('shift')?.setValue(data.ShiftId);
    this.ShiftOPMS.get('crew')?.setValue(data.CrewId);
    this.ShiftOPMS.get('manager')?.setValue(data.ShiftManagerId);
    if(data.ShiftId == 1){
      this.hours = this.day;
    }
    else if(data.ShiftId == 2){
      this.hours = this.evening;
    }
    else{
      this.hours = this.night;
    }
    this.isOPMSUser ? this.LoaddefaultFeildOPMS() : this.GetEvent();
    this.dialog.closeAll();
  }

  DeleteshiftOPMSReport(data: any,params){
    data.pageId = localStorage.getItem('O3RightMenuId');
    data.deletedData = JSON.stringify(params);
    this.opmsGlassservice.deleteShiftOPMSReport(this.data).subscribe(
      (response: any)=>{
        if(!response.HasError){
          if(response.ErrorMessage && response.ErrorMessage != 'Error'){
            this.toaster.success("Shift Report Deleted Successfuly");
            this.RefreshshiftinfoOPMS();
            this.dialog.closeAll();
          }
          else if(response.ErrorMessage == 'Error'){
            this.toaster.error('Error','Something is wrong while deleting Data!');
          }
          else{
            this.toaster.warning('Warning',"Master Shift Can not be Deleted due to using in Child Sections!");
          }
        }
        else{
          this.toaster.error(response.ErrorMessage);
        }
      },
    )
  }

  onGridReadyShift(params :any){
    (params.api).sizeColumnsToFit();
  }

  // End Shift Model

// End Shift Data
//Start HourlyData


  columnDefsHourlyData = [
  {headerName: 'ShiftHdId', field: 'ShiftHDId', hide: true},
  {headerName: 'Time', field: 'HourlyDataTime',aggFunc: (params: any) => {
    return "Cummulative";
  },
  cellStyle: (params: any) => params.value == 'Cummulative' ? { color: 'white', background: '#4040f2'} : { color: 'black' }
  },
  {headerName: 'Gross width', field: 'GrossWidth'},
  {headerName: 'Cut Width', field: 'CutWidth'},
  {headerName: 'Lehr Speed', field: 'LehrSpeed', aggFunc: this.hourlyCustomAvgFunction},
  {headerName: 'Pull', field: 'Pull', aggFunc: this.hourlyCustomAvgFunction },
  {headerName: 'Thickness', field: 'Thickness'},
  {headerName: 'Actual Thickness', field: 'ActualThickness'},
  {headerName: 'Production Time', field: 'ProductionTime', aggFunc: this.myCustomSumFunction },
  {headerName: 'Change Over Time', field: 'ChangeOverTime', aggFunc: this.myCustomSumFunction },
  {headerName: 'Hourly Pull', field: 'PullYieldLosses', aggFunc: this.myCustomSumFunction },
  {headerName: 'Created On', field: 'CreatedOn', valueFormatter: this.dateFormatter},
  {headerName: 'Created By', field: 'CreatedBy'},
  ]
  defaultColDefHourlyData: any =  {
  resizable: true,
  sortable: true,
  filter: true,
  }
  rowHourlyData: any = []
  columnDefsHourlyDataModel =[
    {headerName: 'ShiftHdId', field: 'ShiftHDId', hide: true},
    {headerName: 'Time', field: 'HourlyDataTime'},
    {headerName: 'Gross width', field: 'GrossWidth'},
    {headerName: 'Cut Width', field: 'CutWidth'},
    {headerName: 'Lehr Speed', field: 'LehrSpeed'},
    {headerName: 'Pull', field: 'Pull'},
    {headerName: 'Thickness', field: 'Thickness'},
    {headerName: 'Actual Thickness', field: 'ActualThickness'},
    {headerName: 'Production Time', field: 'ProductionTime'},
    {headerName: 'Change Over Time', field: 'ChangeOverTime'},
    {headerName: 'Hourly Pull', field: 'PullYieldLosses'},
    {headerName: 'Created On', field: 'CreatedOn', valueFormatter: this.dateFormatter,},
    {headerName: 'Created By', field: 'CreatedBy'},
    {
      field: 'Action', cellRenderer: 'buttonCellRenderer', editable: false,
      cellRendererParams: { clicked: (field: any) => {} }
    }
  ]
  rowHourlyDataModel: any = []

  hourlyCustomAvgFunction(values: any) {
    var sum = 0;
    values.forEach( function(value: any) {sum += Number(value);} );
    return ((sum/values.length).toFixed(4));
  }

  RefreshHourlyData(){
    this.HdTransTypeId = 1;
    this.HourlyData.reset();
  }

  GetHourlyData(){
    this.rowHourlyData = [];
    this.rowHourlyDataModel = [];
    this.data = {
      ShiftHDId: 0,
      ShiftReportOPMSId: this.ShiftReportId,
      IsHistory: 0,
      UserId: JSON.parse(localStorage.user).UserId
    }
    this.opmsGlassservice.getHourlyData(this.data).subscribe(
      (response: any)=>{
        if(!response.HasError){
          this.rowHourlyData = response.GetHDList;
          this.rowHourlyDataModel = response.GetHDList;
          this.productionHours = [];
          if(response.GetHDList.length){
            let time = response.GetHDList.map(x => x.HourlyDataTime).sort();
            for(let i=0; i<time.length; i++){
              this.productionHours.push(
                {htime: time[i], hvalue: time[i].split(':')[0]+'.00'}
              )
            }
          }else{
            this.productionHours = [];
          }
        }
        else{
          this.toaster.error('Error', response.ErrorMessage);
        }
      }
    )
  }
  

  SubmitHourlyData(){
    if(this.ShiftReportId == 0){
      this.toaster.warning('Warning', 'Please Fill Shift Details')
    }
    else{
        var HourlyDataExist = this.rowHourlyData.find((data: any) => {
          const a = data.HourlyDataTime;
          const b = this.HourlyData.value.hdTime;
          return a == b;
        });
        if(HourlyDataExist && this.HdTransTypeId != 2){
          this.toaster.warning("Hourly Data Already Exist For this Hour")
        }else if(HourlyDataExist && this.HdTransTypeId == 2 && this.oldHour != this.HourlyData.value.hdTime){
          this.toaster.warning("Hourly Data Already Exist For this Hour")
        }else{
          var thickness = this.thicknessList.find((thick: any) => {
            const a = thick.TypeId;
            const b = this.HourlyData.value.hdThickness;
            return a == b;
          });
          this.data = {
            TransactionTypeId: this.HdTransTypeId,
            ShiftReportOPMSId: this.ShiftReportId,
            ShiftHDId: this.HdId,
            HourlyDataTime: this.HourlyData.value.hdTime,
            GrossWidth: this.HourlyData.value.hdGrossWidth,
            CutWidth: this.HourlyData.value.hdCutWidth,
            LehrSpeed: this.HourlyData.value.hdLehrSpeed,
            Pull: this.HourlyData.value.hdPull,
            Thickness: thickness.TypeName,
            ActualThickness: this.HourlyData.value.hdActualThickness,
            ProductionTime: this.HourlyData.value.hdProduction,
            ChangeOverTime: this.HourlyData.value.hdChangeOverTime,
            PullYieldLosses: this.HourlyData.value.hdPullYieldLosses,
            UserId: JSON.parse(localStorage.user).UserId
          }
          this.opmsGlassservice.submitHourlyData(this.data).subscribe(
            (response: any)=>{
              if(!response.HasError){
                if(this.HdTransTypeId==1){
                  this.toaster.success("Hourly Data Saved Successfuly");
                  this.RefreshHourlyData();
                }
                else if(this.HdTransTypeId==2){
                  this.toaster.success("Hourly Data Updated Successfuly");
                  if(this.HourlyData.value.hdPullYieldLosses == this.oldHourlyPullYL && 
                     this.HourlyData.value.hdTime == this.oldHourlyTime && 
                     thickness.TypeName == this.oldHourlyThickness && 
                     this.HourlyData.value.hdActualThickness == this.oldActualThickness){
                    this.RefreshHourlyData();
                  }else{
                    var productionExist = this.rowProductionLog.find((productionData: any) => {
                      const a = this.oldHourlyTime;
                      const b = productionData.PLTime;
                      return a == b;
                    });
                    var YieldExist = this.rowYieldLosses.find((yielddata: any) => {
                      const a = this.oldHourlyTime;
                      const b = yielddata.Time;
                      return a == b;
                    });
                    if(this.HourlyData.value.hdPullYieldLosses == this.oldHourlyPullYL && 
                       this.HourlyData.value.hdTime !== this.oldHourlyTime && 
                       thickness.TypeName == this.oldHourlyThickness && 
                       this.HourlyData.value.hdActualThickness == this.oldActualThickness){
                      var dataPL;
                      var dataYL;
                      if(YieldExist && productionExist){
                        this.spinner.show();
                          dataPL = {
                            TransactionTypeId: 4,
                            PLTime: this.HourlyData.value.hdTime,
                            ShiftReportOPMSId: this.ShiftReportId,
                            OldTime: this.oldHourlyTime,
                            UserId: JSON.parse(localStorage.user).UserId,
                          }
                          dataYL = {
                            ShiftYieldlossInfoId: YieldExist.ShiftYieldlossInfoId,
                            UserId: JSON.parse(localStorage.user).UserId,
                            time: this.HourlyData.value.hdTime
                          }
                          this.productionTransId  = 4;
                          this.editProductionLogByHorlyData(dataPL);
                          this.makeYieldForm();
                          this.editYieldLossByPL(dataYL);
                      }else if(productionExist){
                        this.spinner.show();
                        dataPL = {
                          TransactionTypeId: 4,
                          PLTime: this.HourlyData.value.hdTime,
                          ShiftReportOPMSId: this.ShiftReportId,
                          OldTime: this.oldHourlyTime,
                          UserId: JSON.parse(localStorage.user).UserId,
                        }
                        this.editProductionLogByHorlyData(dataPL);
                      }else{
                        this.RefreshHourlyData();
                      }
                    }
                    else{
                      if(thickness.TypeName != this.oldHourlyThickness){
                        if(productionExist){
                          this.spinner.show();
                          dataPL = {
                            TransactionTypeId: 5,
                            PLTime: this.HourlyData.value.hdTime,
                            ShiftReportOPMSId: this.ShiftReportId,
                            OldTime: this.oldHourlyTime,
                            UserId: JSON.parse(localStorage.user).UserId,
                            nominalthick: thickness.TypeName
                          }
                          this.editProductionLogByHorlyData(dataPL);
                        }
                      }else{
                        if(this.HourlyData.value.hdTime !== this.oldHourlyTime || 
                           this.HourlyData.value.hdActualThickness != this.oldActualThickness ||
                           this.HourlyData.value.hdPullYieldLosses != this.oldHourlyPullYL){
                          if(productionExist && this.HourlyData.value.hdTime !== this.oldHourlyTime){
                            this.spinner.show();
                            dataPL = {
                              TransactionTypeId: 4,
                              PLTime: this.HourlyData.value.hdTime,
                              ShiftReportOPMSId: this.ShiftReportId,
                              OldTime: this.oldHourlyTime,
                              UserId: JSON.parse(localStorage.user).UserId,
                            }
                            this.editProductionLogByHorlyData(dataPL);
                          }
                          if(YieldExist && (this.HourlyData.value.hdTime !== this.oldHourlyTime || 
                             this.HourlyData.value.hdActualThickness != this.oldActualThickness || 
                             this.HourlyData.value.hdPullYieldLosses != this.oldHourlyPullYL)){
                            this.spinner.show();
                            dataYL = {
                              ShiftYieldlossInfoId: YieldExist.ShiftYieldlossInfoId,
                              UserId: JSON.parse(localStorage.user).UserId,
                              time: this.HourlyData.value.hdTime
                            }
                            this.ProductionLog.get('PLTime')?.setValue(this.HourlyData.value.hdTime)
                            this.productionTransId  = 4;
                            this.makeYieldForm();
                            this.editYieldLossByPL(dataYL);
                          }else{
                            this.RefreshHourlyData();
                          }
                        }else{
                          this.RefreshHourlyData();
                        }
                      }
                    }
                  }
                }
                 this.GetHourlyData();
              }
              else{
                this.toaster.error('Error', response.ErrorMessage);
               }
            }
          )
        }
    }
  }

  pullYieldLosses(data: any){
    if(data == 0){
      if(this.HourlyData.value.hdProduction !== ""){
        this.hdPullYieldLosses = (((this.HourlyData.value.hdPull/24)/60)*(this.HourlyData.value.hdProduction));
        this.HourlyData.get('hdPullYieldLosses')?.setValue(this.hdPullYieldLosses.toFixed(4));
      }
    }
    else if(data == 1){
      if(this.HourlyData.value.hdPull !== ""){
        this.hdPullYieldLosses = (((this.HourlyData.value.hdPull/24)/60)*(this.HourlyData.value.hdProduction));
        this.HourlyData.get('hdPullYieldLosses')?.setValue(this.hdPullYieldLosses.toFixed(4));
      }
    }
  }

  onGridReadyHD(params: any){
    const allColumnIds: any[] = [];
    (params.columnApi).getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    (params.columnApi).autoSizeColumns(allColumnIds, false);
  }
  
  // Start HourlyDataModel
  LoadHourlyDataHistory(){
    this.dialog.open(this.hourlyDataModel, {
      width: '90vw !important',
      height: '90vh', minWidth: '90vw'
    });
  }

  oncellclickedHD(params: any): void{
    this.oldHourlyTime =params.data.HourlyDataTime;
    this.oldHourlyThickness = params.data.Thickness;
    this.oldHourlyPullYL = params.data.PullYieldLosses;
    this.oldActualThickness = params.data.ActualThickness;
    const col = params.colDef.field;
    this.buttonType = '';
    if (col === 'Action') {
      this.buttonType = params.event.target.textContent;
      if(this.buttonType === 'edit'){
        this.data = {
          HdId: params.data.ShiftHDId,
          time: params.data.HourlyDataTime,
          GrossWidth: params.data.GrossWidth,
          CutWidth: params.data.CutWidth,
          LehrSpeed: params.data.LehrSpeed,
          Pull: params.data.Pull,
          Thickness: params.data.ThicknessId,
          ActualThickness: params.data.ActualThickness,
          ProductionTime: params.data.ProductionTime,
          ChangeOverTime: params.data.ChangeOverTime,
          PullYieldLosses: params.data.PullYieldLosses,
        }
        this.EditHourlyData(this.data);
      }
      else if(this.buttonType === 'delete'){
        this.DeleteHourlyData(params);
      }
    }
  }

  EditHourlyData(data: any){
    this.HdTransTypeId = 2;
    this.HdId = data.HdId;
    this.oldHour = data.time;
    this.HourlyData.get('hdTime')?.setValue(data.time);
    this.HourlyData.get('hdGrossWidth')?.setValue(data.GrossWidth);
    this.HourlyData.get('hdCutWidth')?.setValue(data.CutWidth);
    this.HourlyData.get('hdLehrSpeed')?.setValue(data.LehrSpeed);
    this.HourlyData.get('hdPull')?.setValue(data.Pull);
    this.HourlyData.get('hdThickness')?.setValue(data.Thickness);
    this.HourlyData.get('hdActualThickness')?.setValue(data.ActualThickness);
    this.HourlyData.get('hdProduction')?.setValue(data.ProductionTime);
    this.HourlyData.get('hdChangeOverTime')?.setValue(data.ChangeOverTime);
    this.HourlyData.get('hdPullYieldLosses')?.setValue(data.PullYieldLosses)
    this.dialog.closeAll();
  }

  DeleteHourlyData(params: any){
    this.data = {
      TransactionTypeId: 3,
      ShiftReportOPMSId: this.ShiftReportId,
      ShiftHDId: params.data.ShiftHDId,
      HourlyDataTime: params.data.HourlyDataTime,
      GrossWidth: params.data.GrossWidth,
      CutWidth: params.data.CutWidth,
      LehrSpeed: params.data.LehrSpeed,
      Pull: params.data.Pull,
      Thickness: params.data.Thickness,
      ActualThickness: params.data.ActualThickness,
      ProductionTime: params.data.ProductionTime,
      ChangeOverTime: params.data.ChangeOverTime,
      PullYieldLosses: params.data.PullYieldLosses,
      UserId: JSON.parse(localStorage.user).UserId
    }
    var productionExist = this.rowProductionLog.find((productionData: any) => {
      const a = this.oldHourlyTime;
      const b = productionData.PLTime;
      return a == b;
    });
    if(productionExist){
      var YieldExist = this.rowYieldLosses.find((yielddata: any) => {
        const a = this.oldHourlyTime;
        const b = yielddata.Time;
        return a == b;
      });
      if(YieldExist){
        this.toaster.warning('Warning', 'HorlyData can not delete due to using in ProductionLog and YieldLosses');
      }else{
        this.toaster.warning('Warning', 'HorlyData can not delete due to using in ProductionLog');
      }
    }else{
      this.saveDeletedDataForOPMS(params,'Hourly Data',0);
    }
  }

  // End HourlyDataModel

//End HourlyData
// Start Production Log
  columnDefsProductionLog =[
    {headerName: 'Time', field: 'PLTime', aggFunc: (params: any) => {
      return "Cummulative";
    },
    cellStyle: (params: any) => params.value == 'Cummulative' ? { color: 'white', background: '#4040f2'} : { color: 'black' }  
    },
    {headerName: 'Order No', field: 'OrderNo'},
    {headerName: 'Quality', field: 'Quality'},
    {headerName: 'Stacker', field: 'Stacker'},
    {headerName: 'Length', field: 'Length'},
    {headerName: 'Width', field: 'Width'},
    {headerName: 'Lites', field: 'Lites', aggFunc: this.myCustomSumFunction },
    {headerName: 'Boxes', field: 'Boxes', aggFunc: this.myCustomSumFunction },
    {headerName: 'Box Type', field: 'BoxType'},
    {headerName: 'Hold', field: 'Hold', aggFunc: this.myCustomSumFunction },
    {headerName: 'Destination', field: 'Destination'},
    {headerName: 'Box Weight', field: 'BoxWeight', aggFunc: this.myCustomSumFunction },
    {headerName: 'Total Packed', field: 'TotalPacked', aggFunc: this.myCustomSumFunction },
    {headerName: 'Created On', field: 'CreatedOn', valueFormatter: this.dateFormatter},
    {headerName: 'Created By', field: 'CreatedBy'},
  ]
  rowProductionLog: any = []
  defaultColDefProductionLog: any =  {
    resizable: true,
    sortable: true,
    filter: true,
  }
  rowProductionLogModel: any = []
  columnDefsProductionLogModel =[
    {headerName: 'Time', field: 'PLTime'},
    {headerName: 'Order No', field: 'OrderNo'},
    {headerName: 'Quality', field: 'Quality'},
    {headerName: 'Stacker', field: 'Stacker'},
    {headerName: 'Length', field: 'Length'},
    {headerName: 'Width', field: 'Width'},
    {headerName: 'Lites', field: 'Lites'},
    {headerName: 'Boxes', field: 'Boxes'},
    {headerName: 'Box Type', field: 'BoxType'},
    {headerName: 'Hold', field: 'Hold'},
    {headerName: 'Destination', field: 'Destination'},
    {headerName: 'Box Weight', field: 'BoxWeight'},
    {headerName: 'Total Packed', field: 'TotalPacked'},
    {headerName: 'Created On', field: 'CreatedOn', valueFormatter: this.dateFormatter},
    {headerName: 'Created By', field: 'CreatedBy'},
    {
      field: 'Action', cellRenderer: 'buttonCellRenderer', editable: false,
      cellRendererParams: { clicked: (field: any) => {} }
    }
  ]

  refreshProductionLog(){
    this.productionTransId = 1;
    this.ProductionLog.reset();
  }

  getProductionLog(){
    this.rowProductionLog = [];
    this.rowProductionLogModel = [];
    this.rowProductionReporting = [];
    this.data = {
      ShiftPLId: 0,
      ShiftReportOPMSId: this.ShiftReportId,
      IsHistory: 0,
      UserId: JSON.parse(localStorage.user).UserId
    }
    this.opmsGlassservice.getProductionLog(this.data).subscribe(
      (response: any)=>{
        if(!response.HasError){
          this.rowProductionLog = response.GetPLList;
          this.rowProductionLogModel = response.GetPLList;
          this.rowProductionReporting = response.GetPLSummary;
          this.yieldHours = [];
          if(response.GetPLList.length){
            let time = response.GetPLList.map(x => x.PLTime).sort();
            time = [...new Set(time)];
            for(let i=0; i<time.length; i++){
              this.yieldHours.push(
                {htime: time[i], hvalue: time[i].split(':')[0]+'.00'}
              )
            }
          }else{
            this.yieldHours = [];
          }
        }
        else{
         this.toaster.error('Error', response.ErrorMessage);
        }
      }
    )
  }

  submitProductionLog(){
    if(this.ShiftReportId == 0){
      this.toaster.warning('Warning', 'Please Fill Shift Details')
    }
    else{
      this.data = {
        TransactionTypeId: this.productionTransId,
        ShiftReportOPMSId: this.ShiftReportId,
        ShiftPLId: this.pLId,
        PLTime: this.ProductionLog.value.PLTime,
        OrderNo: this.ProductionLog.value.OrderNo,
        Quality: this.ProductionLog.value.Quality,
        Stacker: this.ProductionLog.value.Stacker,
        Length: this.ProductionLog.value.Length,
        Width: this.ProductionLog.value.Width,
        Lites: this.ProductionLog.value.Lites,
        Boxes: this.ProductionLog.value.Boxes,
        BoxType: this.ProductionLog.value.BoxType,
        Hold: this.ProductionLog.value.Hold,
        Destination: this.ProductionLog.value.Destination,
        BoxWeight: this.ProductionLog.value.BoxWeight,
        TotalPacked: this.ProductionLog.value.TotalPacked,
        NominalThick: this.ProductionLog.value.nominalThick,
        UserId: JSON.parse(localStorage.user).UserId
      }
      this.opmsGlassservice.submitProductionLog(this.data).subscribe(
        (response: any)=>{
          if(!response.HasError){
            if(this.productionTransId==1){
              this.toaster.success("Production Log Saved Successfuly");
              let yieldExist = this.rowYieldLosses.find((yielddata: any) => {
                const a = this.ProductionLog.value.PLTime;
                const b = yielddata.Time;
                return a == b;
              }); 
              if(yieldExist){
                let dataYL = {
                  ShiftYieldlossInfoId: yieldExist.ShiftYieldlossInfoId,
                  UserId: JSON.parse(localStorage.user).UserId,
                  time: this.ProductionLog.value.PLTime
                }
                this.makeYieldForm();
                this.editYieldLossByPL(dataYL);
              }else{
                this.refreshProductionLog();
              }
            }
            else if(this.productionTransId==2){
              this.toaster.success("Production Log Updated Successfuly");
              if(this.oldPlHour != this.ProductionLog.value.PLTime){
                this.YieldExistWithOldTime = this.rowYieldLosses.find((yielddata: any) => {
                  const a = this.oldPlHour;
                  const b = yielddata.Time;
                  return a == b;
                }); 
                this.YieldExistWithNewTime = this.rowYieldLosses.find((yielddata: any) => {
                  const a = this.ProductionLog.value.PLTime;
                  const b = yielddata.Time;
                  return a == b;
                }); 
              }else{
                this.YieldExistWithNewTime = this.rowYieldLosses.find((yielddata: any) => {
                  const a = this.ProductionLog.value.PLTime;
                  const b = yielddata.Time;
                  return a == b;
                }); 
              }
              if(this.YieldExistWithOldTime || this.YieldExistWithNewTime){
                this.yieldByPlUpdation = 1;
                if(this.YieldExistWithOldTime){
                  this.yieldNew = true;
                  this.spinner.show();
                  this.makeYieldForm();
                  var data = {
                    ShiftYieldlossInfoId: this.YieldExistWithOldTime.ShiftYieldlossInfoId,
                    UserId: JSON.parse(localStorage.user).UserId,
                    time: this.ProductionLog.value.PLTime
                  }
                  this.editYieldLossByPL(data);
                }
                else if(this.YieldExistWithNewTime){
                  this.yieldNew  = false;
                    this.spinner.show();
                    this.makeYieldForm();
                    var data = {
                      ShiftYieldlossInfoId: this.YieldExistWithNewTime.ShiftYieldlossInfoId,
                      UserId: JSON.parse(localStorage.user).UserId,
                      time: this.oldPlHour
                    }
                    this.editYieldLossByPL(data);
                }
              }
              else{
                this.refreshProductionLog()
              }
            }
             this.getProductionLog();
          }
          else{
            this.toaster.error('Error', response.ErrorMessage);
           }
        }
      )
    }
  }

  editProductionLogByHorlyData(data: any){
    if(data.TransactionTypeId == 4){
      this.opmsGlassservice.submitProductionLog(data).subscribe(
        (response: any)=>{
          if(!response.HasError){
            this.toaster.success(response.ErrorMessage)
            this.getProductionLog();
            this.refreshProductionLog();
            this.RefreshHourlyData();
            if(this.productionTransId !== 4){
              this.spinner.hide();
            }
          }
          else{
            this.toaster.error('Error', response.ErrorMessage);
            this.spinner.hide()
          }
        }
      )
    }else if(data.TransactionTypeId == 5){
      var thick = data.nominalthick;
      thick = "'"+thick+"'";
      thick = nominal[thick];
      var nominal_Thick = thick;
      var rows =  this.rowProductionLog.filter(
       (r) => r.PLTime == data.OldTime
      )
      var ids = [];
      for(var i=0; i<rows.length; i++){
        ids.push(rows[i].ShiftPLId);
      }
      var dataPL = {
        TransactionTypeId: 5,
        PLTime: this.HourlyData.value.hdTime,
        ShiftReportOPMSId: this.ShiftReportId,
        OldTime: this.oldHourlyTime,
        UserId: JSON.parse(localStorage.user).UserId,
        Ids: ids.toString(),
        NominalThick: nominal_Thick
      }
      this.opmsGlassservice.submitProductionLog(dataPL).subscribe(
        (response: any)=>{
          if(!response.HasError){
            this.toaster.success(response.ErrorMessage);
            this.getProductionLog();
            var YieldExist = this.rowYieldLosses.find((yielddata: any) => {
              const a = this.oldHourlyTime;
              const b = yielddata.Time;
              return a == b;
            });
            if(YieldExist){
              var dataYL = {
                ShiftYieldlossInfoId: YieldExist.ShiftYieldlossInfoId,
                UserId: JSON.parse(localStorage.user).UserId,
                time: this.HourlyData.value.hdTime
              }
              this.makeYieldForm();
              this.editYieldLossByPL(dataYL);
            }else{
              this.spinner.hide();
              this.RefreshHourlyData();
            }
          }else{
            this.toaster.error('Error', response.ErrorMessage);
            this.spinner.hide()
           }
        }
      )
    }
  }

  onGridReadyPL(params: any){
    const allColumnIds: any[] = [];
    (params.columnApi).getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    (params.columnApi).autoSizeColumns(allColumnIds, false);
  }

  // Start Production Log Model
  ProductionLogModel(){
    this.dialog.open(this.productionLogModel, {
      width: '90vw !important',
      height: '90vh', minWidth: '90vw'
    });
  }

  oncellclickedPL(params: any): void {
    const col = params.colDef.field;
    this.buttonType = '';
    if (col === 'Action') {
      this.buttonType = params.event.target.textContent;
      if(this.buttonType === 'edit'){
        this.oldPlHour = params.data.PLTime
        this.productionTransId = 2;
        this.pLId = params.data.ShiftPLId;
        this.ProductionLog.get('PLTime')?.setValue(params.data.PLTime);
        this.ProductionLog.get('OrderNo')?.setValue(params.data.OrderNo);
        this.ProductionLog.get('Quality')?.setValue(params.data.Quality);
        this.ProductionLog.get('Stacker')?.setValue(params.data.Stacker);
        this.ProductionLog.get('Length')?.setValue(params.data.Length);
        this.ProductionLog.get('Width')?.setValue(params.data.Width);
        this.ProductionLog.get('Lites')?.setValue(params.data.Lites);
        this.ProductionLog.get('Boxes')?.setValue(params.data.Boxes);
        this.ProductionLog.get('BoxType')?.setValue(params.data.BoxType);
        this.ProductionLog.get('Hold')?.setValue(params.data.Hold);
        this.ProductionLog.get("BoxType")?.setValue(params.data.BoxType);
        this.ProductionLog.get('Destination')?.setValue(params.data.Destination);
        this.ProductionLog.get('BoxWeight')?.setValue(params.data.BoxWeight);
        this.ProductionLog.get('TotalPacked')?.setValue(params.data.TotalPacked);
        this.ProductionLog.get('nominalThick')?.setValue(params.data.NominalThick);
        this.nominal_Thick = params.data.NominalThick;
        this.dialog.closeAll();
      }
      if(this.buttonType === 'delete'){
        this.ProductionLog.get('PLTime')?.setValue(params.data.PLTime);
        this.data = {
          TransactionTypeId: 3,
          ShiftPLId: params.data.ShiftPLId,
          PLTime: this.ProductionLog.value.PLTime,
          OrderNo: params.data.OrderNo,
          Quality: params.data.Quality,
          Stacker: params.data.Stacker,
          Length: params.data.Length,
          Width: params.data.Width,
          Lites: params.data.Lites,
          Boxes: params.data.Boxes,
          BoxType: params.data.BoxType,
          Hold: params.data.Hold,
          Destination: params.data.Destination,
          BoxWeight: params.data.BoxWeight,
          TotalPacked: params.data.TotalPacked,
          NominalThick: params.data.NominalThick,
          UserId: JSON.parse(localStorage.user).UserId,
        }
        let productionLogCount = this.rowProductionLog.filter((row) => {
          return row.PLTime == params.data.PLTime;
        });
        var YieldExist = this.rowYieldLosses.find((yielddata: any) => {
          const a = params.data.PLTime;
          const b = yielddata.Time;
          return a == b;
        });
        if(productionLogCount.length > 1 || (productionLogCount.length == 1 && !YieldExist)){
          this.saveDeletedDataForOPMS(params,'Production Log',0)
        }
        else{
          this.toaster.warning('Warning','ProductionLog can not delete due to using in YieldLosses');
        }
      }
    }
  }
 
  // End Production Log Model

  nominalThick(){
    var HourlyDataExist = this.rowHourlyData.find((time: any) => {
      const a  = time.HourlyDataTime;
      const b = this.ProductionLog.value.PLTime;
      return a == b;
    })
    if(HourlyDataExist){
      this.thickness = HourlyDataExist.Thickness;
      this.thickness = "'"+this.thickness+"'";
      this.thickness = nominal[this.thickness];
      this.nominal_Thick = this.thickness;
      if(this.nominal_Thick){
        this.ProductionLog.get('nominalThick')?.setValue(this.nominal_Thick);
      }
      else{
        this.ProductionLog.get('nominalThick')?.setValue(0);
      }
      this.boxWeight();
    }
    else{
      this.nominal_Thick = 0;
      this.ProductionLog.get('nominalThick')?.setValue(this.nominal_Thick);
    }
  }

  boxWeight(){
    if(this.ProductionLog.value.Width && this.ProductionLog.value.Lites && this.ProductionLog.value.Length){
      this.box_weight = ((this.ProductionLog.value.Width*this.ProductionLog.value.Lites*this.ProductionLog.value.Length*this.nominal_Thick*2.5)/1000000000);
      this.ProductionLog.get('BoxWeight')?.setValue(this.box_weight.toFixed(4));
      this.totalPacked();
    }
    else{
      this.ProductionLog.get('BoxWeight')?.setValue(0);
      this.totalPacked();
    }
  }

  totalPacked(){
    if(this.ProductionLog.value.BoxWeight !== 0 && this.ProductionLog.value.Boxes){
      const totalPacked = (this.ProductionLog.value.Boxes)*(this.ProductionLog.value.BoxWeight);
      this.ProductionLog.get("TotalPacked")?.setValue(totalPacked.toFixed(4));
    }
    else{
      this.ProductionLog.get("TotalPacked")?.setValue(0);
    }
  }

  productionByOrderNo(o: any){
    var data = {
      OrderNo: o.value,
    }
    this.opmsGlassservice.getProductionLogByOrderNo(data).subscribe(
      (response: any)=>{
        if(!response.HasError){
          this.ProductionLog.get('nominalThick')?.setValue(0);
          this.ProductionLog.get('PLTime')?.setValue('');
          this.nominalThick();
          if(response.GetPLList.length>0){
            this.ProductionLog.get("OrderNo")?.setValue(response.GetPLList[0].OrderNo);
            this.ProductionLog.get("Quality")?.setValue(response.GetPLList[0].Quality);
            this.ProductionLog.get("Stacker")?.setValue(response.GetPLList[0].Stacker);
            this.ProductionLog.get("Length")?.setValue(response.GetPLList[0].Length);
            this.ProductionLog.get("Width")?.setValue(response.GetPLList[0].Width);
            this.ProductionLog.get("Lites")?.setValue(response.GetPLList[0].Lites);
            this.ProductionLog.get("Boxes")?.setValue(response.GetPLList[0].Boxes);
            this.ProductionLog.get("BoxType")?.setValue(response.GetPLList[0].BoxType);
            this.ProductionLog.get("Hold")?.setValue(response.GetPLList[0].Hold);
            this.ProductionLog.get("Destination")?.setValue(response.GetPLList[0].Destination);
            this.ProductionLog.get("BoxWeight")?.setValue(response.GetPLList[0].BoxWeight);
            this.ProductionLog.get("TotalPacked")?.setValue(response.GetPLList[0].TotalPacked);
            this.boxWeight();
        }
          else{
            this.ProductionLog.get("Quality")?.setValue("");
            this.ProductionLog.get("Stacker")?.setValue("");
            this.ProductionLog.get("Length")?.setValue("");
            this.ProductionLog.get("Width")?.setValue("");
            this.ProductionLog.get("Lites")?.setValue("");
            this.ProductionLog.get("Boxes")?.setValue("");
            this.ProductionLog.get("BoxType")?.setValue('');
            this.ProductionLog.get("Hold")?.setValue("");
            this.ProductionLog.get("Destination")?.setValue("");
            this.ProductionLog.get("BoxWeight")?.setValue("");
            this.ProductionLog.get("TotalPacked")?.setValue("");
            this.boxWeight();
          }
        }
      }
    )
  }
  rowYieldLosses: any = [];

// End Production Log
// Start Yield Looses
  getYieldLoss(){
    this.rowYieldLosses = [];
    this.data = {
      ShiftReportOPMSId: this.ShiftReportId,
      UserId: JSON.parse(localStorage.user).UserId
    }
    this.opmsGlassservice.getYieldLoss(this.data).subscribe(
      (response: any)=>{
        if(!response.HasError){
          if(response.ShiftYeildLossList.length>1){
            this.rowYieldLosses = response.ShiftYeildLossList;
          }
          this.spinner.hide();
        }
        else{
          this.toaster.error(response.ErrorMessage);
        }
      }
    )
  }

  columnDefsYieldLosses =[
    {headerName: '', field: 'ShiftYieldlossInfoId', hide: true},
    {headerName: 'Hourly Time', field: 'Time',
    cellStyle: (params: any) => params.value == 'Cummulative' ? { color: 'white', background: '#4040f2'} : { color: 'black' },
    width: 122
    },
    {headerName: 'Yield Loss', field: 'YieldLoss'},
    {headerName: 'Total Loss', field: 'TotalLoss',
    cellStyle: (params: any) =>  (params.data && params.data.YieldLoss !== params.data.TotalLoss) ? { color: 'white', background: '#FF0000'} : { color: 'black' },},
    {headerName: 'Thickness', field: 'Thickness'},
    {headerName: 'Pull', field: 'Pull'},
    {headerName: 'Total Packed', field: 'TotalPacked'},
    {headerName: 'WIP_MT', field: 'WIP_MT'},
    {headerName: 'Yield%', field: 'Yield'},
    {headerName: 'WIP%', field: 'WIP'},
    {headerName: 'Loss%', field: 'Loss'},
    {headerName: 'Trimming Loss%', field: 'TrimmingLoss'},
    {headerName: 'Sampling Loss%', field: 'SamplingLoss'},
    {headerName: 'Cutting Loss%', field: 'CuttingLoss'},
    {headerName: 'Breakage Loss%', field: 'BreakageLoss'},
    {headerName: 'ChangeOver Loss%', field: 'ChangeOverLoss'},
    {headerName: 'Preff Loss%', field: 'PreffLoss'},
    {headerName: 'Float Loss%', field: 'FloatLoss'},
    {headerName: 'Equipment Loss%', field: 'EquipmentLoss'},
    {headerName: 'Saturation Loss%', field: 'SaturationLoss'},
    {headerName: 'Handling Loss%', field: 'HandlingLoss'},
    {headerName: 'Rework Loss%', field: 'ReworkLoss'},
    {headerName: 'Warehouse Loss%', field: 'LineLoss'},
    {headerName: 'Profile Loss%', field: 'ProfileLoss'},
    {headerName: 'Defect Loss%', field: 'DefectLoss'},
    {headerName: 'CreatedOn', field: 'Date', valueFormatter: this.dateFormatterYL, width: 115},
    {
      field: 'Action', cellRenderer: 'buttonCellRenderer',
      editable: false,
      cellRendererParams: { clicked: (field: any) => {} },
      width: 110
    }
  ]
  defaultColDefYieldLosses =  {
    resizable: true,
    sortable: false,
    filter: true,
    width: 100,
    wrapText: true,
    autoHeight: true,
  }

  myCustomSumFunction(values: any) {
    var sum = 0;
    values.forEach( function(value: any) {sum += Number(value);} );
    return sum.toFixed(4);
  }

  oncellclickedYL(params: any): void{
    const col = params.colDef.field;
    this.buttonType = '';
    if (col === 'Action') {
      if(params.data.ShiftYieldlossInfoId !== 0){
        this.buttonType = params.event.target.textContent;
      if(this.buttonType === 'edit'){
        this.data = {
          ShiftYieldlossInfoId: params.data.ShiftYieldlossInfoId,
          UserId: JSON.parse(localStorage.user).UserId,
        }
        this.editYieldLoss(this.data);
      }
      else if(this.buttonType === 'delete'){
        var processvalues = [];
        var items = {
            "YieldLossId": 0,
            "Value1": "",
            "Value2": ""
        }
        processvalues.push(items);
        this.data = {
          ShiftYieldlossList: processvalues,
          ShiftYieldlossInfoId: params.data.ShiftYieldlossInfoId,
          TransactionTypeId: 3,
          YieldLoss: 0,
          TotalLoss: 0,
          ShiftReportOPMSId: this.ShiftReportId,
          UserId: JSON.parse(localStorage.user).UserId
          }
          this.saveDeletedDataForOPMS(params,'Yield Losses',0);
        }
      }
    }
  }

  editYieldLossByPL(data: any){
    this.spinner.show();
    this.yieldTransId = 2;
    this.yieldlossInfoId = data.ShiftYieldlossInfoId;
    this.opmsGlassservice.yieldLossParameters(data).subscribe(
      (response: any)=>{
        if(!response.HasError){
          this.yield_Loss = response.Losses.YieldLoss;
          this.total_Loss = response.Losses.TotalLoss;
          this.yieldParameters = response.YeildLossList;
          for(const val of this.yieldParameters){
            this.yieldForm.get((val.YieldLoss)+"|Value1")?.setValue(val.Value1);
            this.yieldForm.get((val.YieldLoss)+"|Value2")?.setValue(val.Value2);
          }
          this.yieldTimeByPL(data.time);
          this.productionTransId = 4;
          this.spinner.show();
        }
        else{
          this.toaster.error("Error", response.ErrorMessage);
        }
      }
    )
  }

  editYieldLoss(data: any){
    this.yieldTransId = 2;
    this.yieldlossInfoId = data.ShiftYieldlossInfoId;
    this.opmsGlassservice.yieldLossParameters(data).subscribe(
      (response: any)=>{
        if(!response.HasError){
          this.yield_Loss = response.Losses.YieldLoss;
          this.total_Loss = response.Losses.TotalLoss;
          this.yieldParameters = response.YeildLossList;
          for(const val of this.yieldParameters){
            this.yieldForm.get((val.YieldLoss)+"|Value1")?.setValue(val.Value1);
            this.yieldForm.get((val.YieldLoss)+"|Value2")?.setValue(val.Value2);
          }
          this.oldYieldtime = this.yieldForm.get("Time|Value1")?.value
        }
        else{
          this.toaster.error("Error", response.ErrorMessage);
        }
        }
    )
    this.dialog.open(this.yieldLossesModel, {
      width: '90vw !important',
      height: '100%', minWidth: '90vw',
      disableClose: true
    });
  }

  onGridReadyYL(params: any){
  }
// Start Yield Losses Model

  yieldLossesInfoModel(){
    if(this.ShiftReportId == 0){
      this.toaster.warning('Warning', 'Please Fill Shift Details')
    }else{
      this.refreshYield();
      this.dialog.open(this.yieldLossesModel, {
        width: '90vw !important',
        height: '100%', minWidth: '90vw',
        disableClose: true
      });
    }
  } 

  yieldTimeByPL(time: any){
    this.total_Loss = "";
    this.yield_Loss = "";
    this.yieldForm.get("Thickness|Value1")?.setValue('');
    this.yieldForm.get("Pull|Value1")?.setValue('');
    this.yieldForm.get("ProfileLoss%|Value1")?.setValue('');
    this.yieldForm.get('TotalPacked|Value1')?.setValue('');
    this.data = {
      Time: time,
      ShiftReportOPMSId: this.ShiftReportId
    }
    this.oldYieldtime = time;
    this.yieldForm.get("Time|Value1")?.setValue(time);
    this.opmsGlassservice.getYieldHoulyDataByTime(this.data).subscribe(
      (response: any)=>{
        if(!response.HasError){
          if(response.GetHDList.length>0){
            var thick = response.GetHDList[0].Thickness;
            thick = "'"+thick+"'";
            thick = nominal[thick];
            var nominal_Thick = thick;
            var profileLoss = ((response.GetHDList[0].ActualThickness-nominal_Thick)/nominal_Thick)*100
            this.yieldForm.get("Thickness|Value1")?.setValue(response.GetHDList[0].Thickness);
            this.yieldForm.get("Pull|Value1")?.setValue(response.GetHDList[0].PullYieldLosses);
            this.yieldForm.get("ProfileLoss%|Value1")?.setValue(profileLoss.toFixed(4));
            if(response.TotalPacked !== null){
              this.yieldForm.get('TotalPacked|Value1')?.setValue((response.TotalPacked).toFixed(4));
            }
            this.weightMT('ProfileLoss%')
          }
          else{
            this.yieldForm.get("Thickness|Value1")?.setValue('');
            this.yieldForm.get("Pull|Value1")?.setValue('');
            this.yieldForm.get('TotalPacked|Value1')?.setValue('');
            this.yieldForm.get("ProfileLoss%|Value1")?.setValue('');
            this.yield_Loss = "";
            this.total_Loss = "";
            this.yieldNumber();
          }
        }
        else{
          this.toaster.error("Error", response.ErrorMessage);
        }
        }
    )
  }

  yieldTime(t: any){
    this.yieldForm.reset();
    this.total_Loss = "";
    this.yield_Loss = "";
    this.data = {
      Time: t.value,
      ShiftReportOPMSId: this.ShiftReportId
    }
    this.yieldForm.get("Time|Value1")?.setValue(t.value);
    this.opmsGlassservice.getYieldHoulyDataByTime(this.data).subscribe(
      (response: any)=>{
        if(!response.HasError){
          if(response.GetHDList.length>0){
            var thick = response.GetHDList[0].Thickness;
            thick = "'"+thick+"'";
            thick = nominal[thick];
            var nominal_Thick = thick;
            var profileLoss = ((response.GetHDList[0].ActualThickness-nominal_Thick)/nominal_Thick)*100
            this.yieldForm.get("Thickness|Value1")?.setValue(response.GetHDList[0].Thickness);
            this.yieldForm.get("Pull|Value1")?.setValue(response.GetHDList[0].PullYieldLosses);
            this.yieldForm.get("ProfileLoss%|Value1")?.setValue(profileLoss.toFixed(4));
            if(response.TotalPacked !== null){
              this.yieldForm.get('TotalPacked|Value1')?.setValue((response.TotalPacked).toFixed(4));
            }
            this.weightMT('ProfileLoss%')
          }
          else{
            this.yieldForm.get("Thickness|Value1")?.setValue('');
            this.yieldForm.get("Pull|Value1")?.setValue('');
            this.yieldForm.get('TotalPacked|Value1')?.setValue('');
            this.yieldForm.get("ProfileLoss%|Value1")?.setValue('');
            this.yield_Loss = "";
            this.total_Loss = "";
            this.yieldNumber();
          }
        }
        else{
          this.toaster.error("Error", response.ErrorMessage);
        }
        }
    )
  }

  async yieldNumber(){
    var defectLosCalculation = 0;
    var pull, totalPack, yieldLoss;
    pull = this.yieldForm.get("Pull|Value1")?.value;
    totalPack = this.yieldForm.get("TotalPacked|Value1")?.value;
    yieldLoss = (totalPack / pull) * 100;
    this.yieldForm.get("Yield%|Value1")?.setValue(yieldLoss.toFixed(4));
    if (yieldLoss) {
      var yloss = 100 - yieldLoss;
      this.yieldForm.get("DefectLoss%|Value1")?.setValue(yloss.toFixed(4));
      const resultMT = ((this.yieldForm.get("DefectLoss%|Value1")?.value)*pull)/100;
      this.yieldForm.get("DefectLossMT|Value1")?.setValue(resultMT.toFixed(4));
      this.yield_Loss = yloss.toFixed(4);
      defectLosCalculation = yloss;
    }
    var t_Loss = 0;
    for(const val of this.yieldParameters){
      if(val.YieldLossId > 7 && val.YieldLossId < 35){
        if(val.YieldLoss.charAt(val.YieldLoss.length-1)=='%'){
          if(this.yieldForm.get(val.YieldLoss+"|Value1")?.value){
            t_Loss = t_Loss + parseFloat(this.yieldForm.get(val.YieldLoss+"|Value1")?.value)
            if (defectLosCalculation > 0) { 
              var defect = defectLosCalculation - parseFloat(this.yieldForm.get(val.YieldLoss+"|Value1")?.value);
              if(defect > 0){
                defectLosCalculation = defect;
              }
            } 
          }
        }
      }
    }
    this.total_Loss = (t_Loss + defectLosCalculation).toFixed(4);
    this.yieldForm.get("DefectLoss%|Value1")?.setValue(defectLosCalculation.toFixed(4));
    const resultMT = ((this.yieldForm.get("DefectLoss%|Value1")?.value)*pull)/100;
    this.yieldForm.get("DefectLossMT|Value1")?.setValue(resultMT.toFixed(4));  
      if(this.productionTransId != 1 && this.yieldByPlUpdation == 0){
        await this.delay(1500);
        this.submitYieldLoss();
      }else if(this.yieldByPlUpdation == 1){
        await this.delay(1500);
        this.submitYieldLossByPL();
      }
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  weightMT(data: any){
        if(data.charAt(data.length-1)=='%'){
          const pull = this.yieldForm.get("Pull|Value1")?.value;
          const field = data.split('%');
          const resultMT = ((this.yieldForm.get(data+"|Value1")?.value)*pull)/100;
          this.yieldForm.get(field[0]+"MT|Value1")?.setValue(resultMT.toFixed(4));
            this.yieldNumber();
        }
        else if(data.charAt(data.length-1)=='T'){
          const pull = this.yieldForm.get("Pull|Value1")?.value;
          const field = data.split('MT');
          const resultP = ((this.yieldForm.get(data+"|Value1")?.value)*100)/pull;
          this.yieldForm.get(field[0]+"%|Value1")?.setValue(resultP.toFixed(4));
            this.yieldNumber();
        }
  }

  CheckValidation(data: any, n: any){
    if(data!=='ProfileLoss%' && data!=='ProfileLossMT' && data!=='ChangeOverLoss%' && data!=='ChangeOverLossMT'){
      if(n.key=='-'){
        this.yieldForm.get(data+"|Value1")?.setValue('');
      }
    }
  }

  submitYieldLoss(){
    var YieldExist = this.rowYieldLosses.find((yielddata: any) => {
      const a =  this.yieldForm.get("Time|Value1").value;
      const b = yielddata.Time;
      return a == b;
    })
    if(YieldExist && (this.yieldTransId == 1 || (this.yieldTransId == 2 && this.oldYieldtime != this.yieldForm.get("Time|Value1").value))){
      this.toaster.warning("Yield loss details Already Exist For this Hour!")
    }else{
      var value = this.yieldForm.value;
      var processvalues = [];
      for(const val of this.yieldParameters){
        var data = {
          YieldLossId: val.YieldLossId,
          Value1: value[(val.YieldLoss)+"|Value1"],
          Value2: value[(val.YieldLoss)+"|Value2"],
        }
        processvalues.push(data);
      }
      this.data = {
        ShiftYieldlossList: processvalues,
        TransactionTypeId: this.yieldTransId,
        ShiftYieldlossInfoId: this.yieldlossInfoId,
        ShiftReportOPMSId: this.ShiftReportId,
        YieldLoss: this.yield_Loss ? this.yield_Loss : 0,
        TotalLoss: this.total_Loss ? this.total_Loss : 0,
        UserId: JSON.parse(localStorage.user).UserId
      }
      this.opmsGlassservice.submitYieldLoss(this.data).subscribe(
        (response: any)=>{
          if(!response.HasError){
            if(response.ErrorMessage == "C0-Yield loss details Saved Successfully !"){
              this.toaster.success("Yield Losses Saved Successfuly");
            }
            else if(response.ErrorMessage == "C0-Yield loss details Updated Successfully !"){
              if(this.productionTransId != 1){
                this.toaster.success("Yield Losses Also Updated Successfuly For this Hour");
                this.refreshProductionLog();
                this.RefreshHourlyData();
              }
              else{
                this.toaster.success("Yield Losses Updated Successfuly");
              }
            }
            this.getYieldLoss();
          }
          else{
            this.toaster.error(response.ErrorMessage);
            this.spinner.hide();
          }
        }
      )
      this.dialog.closeAll();
      this.refreshYield();
    }
  }

  submitYieldLossByPL(){
      var value = this.yieldForm.value;
      var processvalues = [];
      for(const val of this.yieldParameters){
        var data = {
          YieldLossId: val.YieldLossId,
          Value1: value[(val.YieldLoss)+"|Value1"],
          Value2: value[(val.YieldLoss)+"|Value2"],
        }
        processvalues.push(data);
      }
      this.data = {
        ShiftYieldlossList: processvalues,
        TransactionTypeId: this.yieldTransId,
        ShiftYieldlossInfoId: this.yieldlossInfoId,
        ShiftReportOPMSId: this.ShiftReportId,
        YieldLoss: this.yield_Loss ? this.yield_Loss : 0,
        TotalLoss: this.total_Loss ? this.total_Loss : 0,
        UserId: JSON.parse(localStorage.user).UserId
      }
      this.opmsGlassservice.submitYieldLoss(this.data).subscribe(
        (response: any)=>{
          if(!response.HasError){
            if(response.ErrorMessage == "C0-Yield loss details Saved Successfully !"){
              this.toaster.success("Yield Losses Saved Successfuly");
            }
            else if(response.ErrorMessage == "C0-Yield loss details Updated Successfully !"){
              if(this.productionTransId != 1){
                this.toaster.success("Yield Losses Also Updated Successfuly For this Hour");
                if(this.YieldExistWithNewTime && this.yieldNew == true){
                  this.yieldByPlUpdation = 1;
                  this.spinner.show();
                  this.makeYieldForm();
                  var data = {
                    ShiftYieldlossInfoId: this.YieldExistWithNewTime.ShiftYieldlossInfoId,
                    UserId: JSON.parse(localStorage.user).UserId,
                    time: this.oldPlHour
                  }
                  this.editYieldLossByPL(data);
                  this.yieldNew = false;
                }else{
                  this.yieldNew = false;
                  this.yieldByPlUpdation = 0;
                  this.refreshProductionLog();
                  this.RefreshHourlyData();
                }
              }
              else{
                this.toaster.success("Yield Losses Updated Successfuly");
              }
            }
            else if(response.ErrorMessage == "Yield loss details Already Exist For this Hour!"){
              this.toaster.warning(response.ErrorMessage);
            }
            this.getYieldLoss();
          }
          else{
            this.toaster.error(response.ErrorMessage);
            this.spinner.hide();
          }
        }
      )
      this.dialog.closeAll();
      this.refreshYield();
  }

  makeYieldForm(): any {
    this.data = {
      ShiftYieldlossInfoId: 0,
      UserId: JSON.parse(localStorage.user).UserId
    }
    this.opmsGlassservice.yieldLossParameters(this.data).subscribe(
      (response: any)=>{
        this.yieldParameters = response.YeildLossList;
        }
    ) 
    if(this.yieldParameters.length != 0){
      for (const val of this.yieldParameters){
        this.yieldForm.addControl((val.YieldLoss)+"|Value1", new FormControl(val.Value1));
        this.yieldForm.addControl((val.YieldLoss)+"|Value2", new FormControl(val.Value2));
      }
    }
  }

  refreshYield(){
    for(const val of this.yieldParameters){
      this.yieldForm.get((val.YieldLoss)+"|Value1")?.setValue("");
      this.yieldForm.get((val.YieldLoss)+"|Value2")?.setValue("");
    }
    this.yieldTransId = 1;
    this.total_Loss = "";
    this.yield_Loss = "";
  }

// End Yield Losses Model

// End Yield Losses

// Start Repacking
columnDefsRepacking  = [
  {headerName: 'Thickness', field: 'Thickness'},
  {headerName: 'Length', field: 'Length'},
  {headerName: 'Width', field: 'Width'},
  {headerName: 'Lites', field: 'Lites'},
  {headerName: 'Boxes', field: 'Boxes'},
  {headerName: 'Box Type', field: 'BoxType'},
  {headerName: 'Reason', field: 'Reason'},
  {headerName: 'Created On', field: 'CreatedOn' , valueFormatter: this.dateFormatter,},
  {headerName: 'Created By', field: 'CreatedBy'},
]
rowRepacking: any = []
defaultColDefRepacking: any =  {
  filter: true,
  sortable: true,
}

onGridReadyRP(params: any){
    (params.api).sizeColumnsToFit();
}

// Start Repacking Model
RepackingModel(){
  this.dialog.open(this.repacking, {
    width: '90vw !important',
    height: '90vh', minWidth: '90vw'
  })
}
rowRepackingModel: any = []
columnDefsRepackingModel =[
  {headerName: 'Thickness', field: 'Thickness'},
  {headerName: 'Length', field: 'Length'},
  {headerName: 'Width', field: 'Width'},
  {headerName: 'Lites', field: 'Lites'},
  {headerName: 'Boxes', field: 'Boxes'},
  {headerName: 'Box Type', field: 'BoxType'},
  {headerName: 'Reason', field: 'Reason'},
  {headerName: 'Created On', field: 'CreatedOn' , valueFormatter: this.dateFormatter,},
  {headerName: 'Created By', field: 'CreatedBy'},
 {
    headerName: "Action", field: 'Action', cellRenderer: 'buttonCellRenderer', resizable: false, width: 200, editable: false,
    cellRendererParams: { clicked: (field: any) => {} }
  }
]

RepackingGroup!: FormGroup;
TransactionIdRepack=1;
ShiftRepackId = 0;
saveRepacking(): any
{
  if (this.ShiftReportId !== 0)
  {
    const key = {
      TransactionTypeId: this.TransactionIdRepack,
      ShiftReportOPMSId: this.ShiftReportId,
      ShiftRepackId: this.ShiftRepackId,
      Thickness: this.RepackingGroup.value.Thickness,
      Length: this.RepackingGroup.value.Length,
      Width: this.RepackingGroup.value.Width,
      Lites: this.RepackingGroup.value.Lites,
      Boxes: this.RepackingGroup.value.Boxes,
      BoxType: this.RepackingGroup.value.BoxType,
      Reason: this.RepackingGroup.value.Reason,
      UserId: JSON.parse(localStorage.user).UserId
    };
    this.opmsGlassservice.saveRepacking(key).subscribe(
      (response: any) => {
        this.getRepacking();
        if(response.ErrorMessage=="C0-Repacking Saved Successfully!"){
          this.toaster.success("Repacking Data Saved Successfuly");
        }
        else if(response.ErrorMessage=="C0-Repacking Updated Successfully!"){
          this.toaster.success("Repacking Data Updated Successfuly");
        }
        this.resetRepackingGroup();
      }
    );
    this.TransactionIdRepack = 1;
    this.ShiftRepackId = 0;
  }
  else
  {
    this.toaster.warning('Warning', 'Please Fill Shift Details');
  }
}

getRepacking(): any
{
  this.rowRepacking = [];
  this.rowRepackingModel = [];
  const key = {
    ShiftRepackId: 0,
    UserId: JSON.parse(localStorage.user).UserId,
    IsHistory: 0,
    ShiftReportOPMSId: this.ShiftReportId
  };
  this.opmsGlassservice.getRepacking(key).subscribe(
    (response: any) => {
      if(!response.HasError){
        this.rowRepacking = response.GetRepackList;
        this.rowRepackingModel = response.GetRepackList;
      }
      else{
        this.toaster.error('Error', response.ErrorMessage);
      }
      
      }
  )
}

oncellclickedRepack(params: any): void{
  const col = params.colDef.field;
  this.buttonType = '';
  if (col === 'Action') {
    this.buttonType = params.event.target.textContent;
    if (this.buttonType === 'edit'){
      this.RepackingGroup.get('Thickness')?.setValue(params.data?.Thickness);
      this.RepackingGroup.get('Length')?.setValue(params.data?.Length);
      this.RepackingGroup.get('Width')?.setValue(params.data?.Width);
      this.RepackingGroup.get('Lites')?.setValue(params.data?.Lites);
      this.RepackingGroup.get('Boxes')?.setValue(params.data?.Boxes);
      this.RepackingGroup.get('Reason')?.setValue(params.data?.Reason);
      this.RepackingGroup.get('BoxType')?.setValue(params.data?.BoxType);
      this.TransactionIdRepack = 2;
      this.ShiftRepackId = params.data?.ShiftRepackId;
      this.dialog.closeAll();
    }
    else if (this.buttonType === 'delete'){
      this.saveDeletedDataForOPMS(params,'Repacking',0);
    }
  }
}

resetRepackingGroup(): any
{
 this.RepackingGroup.reset();
 this.TransactionIdRepack = 1;
}

  // End Repacking Model

// End Repacking
// Start Event
  columnDefsEvent =[
    {headerName: 'Time', field: 'Time'},
    {headerName: 'Hot End Area', field: 'HotEndArea'},
    {headerName: 'Event', field: 'Event', wrapText: true, autoHeight: true},
    {headerName: 'Created On', field: 'CreatedOn', valueFormatter: this.dateFormatter},
    {headerName: 'Created By', field: 'CreatedBy'},
  ]
  rowEvent: any = []
  defaultColDefEvent: any =  {
    resizable: true,
    sortable: true,
    filter: true,
  }
  rowEventModel: any = []
  columnDefsEventModel =[
    {headerName: 'ShiftEventId', field: 'ShiftEventId', hide: true},
    {headerName: 'Time', field: 'Time'},
    {headerName: 'Hot End Area', field: 'HotEndArea'},
    {headerName: 'Event', field: 'Event', wrapText: true, autoHeight: true},
    {headerName: 'Created On', field: 'CreatedOn', valueFormatter: this.dateFormatter},
    {headerName: 'Created By', field: 'CreatedBy'},
    {
      field: 'Action', cellRenderer: 'buttonCellRenderer', editable: false,
      cellRendererParams: { clicked: (field: any) => {} }
    }
  ]

  RefreshEvent(){
    this.EventTrandTypeId = 1;
    this.Event.reset();
  }

  GetEvent(){
    this.rowEvent = [];
    this.rowEventModel = [];
    this.data = {
      ShiftReportOPMSId: this.ShiftReportId,
      IsHotEndUser: this.isHotEndUser,
      IsOPMSUser: this.isOPMSUser,
    }
    this.opmsGlassservice.getEvent(this.data).subscribe(
      (response: any)=>{
        if(!response.HasError){
          this.rowEvent = response.GetEventList;
          this.rowEventModel = response.GetEventList;
        }
        else{
         this.toaster.error('Error', response.ErrorMessage);
        }
      }
    )
  }

  SubmitEvent(){
    if(this.ShiftReportId == 0){
      this.toaster.warning('Warning', 'Please Fill Shift Details')
    }
    else{
      this.data = {
        TransactionTypeId: this.EventTrandTypeId,
        ShiftReportOPMSId: this.ShiftReportId,
        ShiftEventId: this.EventId,
        EventTime: this.Event.value.etime,
        HotEndArea: this.Event.value.hotEndArea,
        Event: this.Event.value.event,
        UserId: JSON.parse(localStorage.user).UserId
      }
      this.opmsGlassservice.submitEvent(this.data).subscribe(
        (response: any)=>{
          if(!response.HasError){
            if(this.EventTrandTypeId==1){
              this.toaster.success("Event Saved Successfuly");
            }
            else if(this.EventTrandTypeId==2){
              this.toaster.success("Event Updated Successfuly");
            }
             this.GetEvent();
             this.RefreshEvent();
          }
          else{
            this.toaster.error(response.ErrorMessage);
           }
        }
      )
    }
  }

  onGridReadyE(params: any){
    (params.api).sizeColumnsToFit();
  }

  // Start Event Model
  LoadEventHistory(){
    this.dialog.open(this.eventModel, {
      width: '90vw !important',
      height: '90vh', minWidth: '90vw'
    })
  }

  oncellclickedEvent(params: any){
    const col = params.colDef.field;
    this.buttonType = '';
    if (col === 'Action') {
      this.buttonType = params.event.target.textContent;
      if(this.buttonType === 'edit'){
        this.data = {
          ShiftEventId: params.data.ShiftEventId,
          etime: params.data.Time,
          HotEndAreaId: params.data.HotEndAreaId,
          event: params.data.Event,
        }
        this.EditEvent(this.data);
      }
      else if(this.buttonType === 'delete'){
        this.data = {
          TransactionTypeId: 3,
          ShiftEventId: params.data.ShiftEventId,
          ShiftReportOPMSId: this.ShiftReportId,
          EventTime: params.data.Time,
          Event: params.data.Event,
          UserId: JSON.parse(localStorage.user).UserId
        }
        this.saveDeletedDataForOPMS(params,'OPMS & HotEnd Events',0);
      }
    }
  }

  EditEvent(data: any){
    this.EventId = data.ShiftEventId;
    this.EventTrandTypeId = 2;
    this.Event.get('etime')?.setValue(data.etime);
    this.Event.get('hotEndArea')?.setValue(data.HotEndAreaId)
    this.Event.get('event')?.setValue(data.event);
    this.dialog.closeAll();
  }

  // End Event Model

//  End Event
// Start Production Log Report
columnDefsProductionReporting =[
  {headerName: 'Thickness', field: 'Thickness', aggFunc: (params: any) => {
    return "Cummulative";
  },
  cellStyle: (params: any) => params.value == 'Cummulative' ? { color: 'white', background: '#4040f2'} : { color: 'black' }  
  },
  {headerName: 'Quality', field: 'Quality'},
  {headerName: 'Stacker', field: 'Stacker'},
  {headerName: 'Packing', field: 'BoxType'},
  {headerName: 'Length', field: 'Length'},
  {headerName: 'Width', field: 'Width'},
  {headerName: 'Lites', field: 'Lites'},
  {headerName: 'Boxes', field: 'Boxes', aggFunc: this.myCustomSumFunction },
  {headerName: 'Total Packed', field: 'TotalPacked', aggFunc: this.myCustomSumFunction },
]
rowProductionReporting: any = []
defaultColDefProductionReporting: any =  {
  resizable: true,
  sortable: true,
  filter: true,
}

refreshProductionReport(){
  this.getProductionLog();
}

onGridReadyPR(params: any){
  (params.api).sizeColumnsToFit();
}

// End Production Log Report

  ngOnInit(): void {
    const roles = JSON.parse(localStorage.getItem('roleName'));
    if(roles.length){
      const isAdmin = roles.find((fre: any) => {
                        return (fre == 'Glass - Admin');
                      })
      if(isAdmin){
        this.isHotEndUser = true;
        this.isOPMSUser = true;
        this.eventPannelTitle = 'OPMS & HotEnd Events';
      }
      else{
        this.isHotEndUser = roles.find((fre: any) => {
                              return (fre == 'Hot End Diary');
                            })
        if(this.isHotEndUser && roles.length == 1){
          this.isHotEndUser = true;
          this.isOPMSUser = false;
          this.eventPannelTitle = 'HotEnd Events';
        }
        else if(this.isHotEndUser && roles.length > 1){
          this.isHotEndUser = true;
          this.isOPMSUser = true;
          this.eventPannelTitle = 'OPMS & HotEnd Events';
        }
        else{
          this.isHotEndUser = false;
          this.isOPMSUser = true;
          this.eventPannelTitle = 'OPMS Events';
        }
      }
    }
    else{
      this.isHotEndUser = true;
      this.isOPMSUser = true;
      this.eventPannelTitle = 'OPMS & HotEnd Events';
    }
    this.ShiftOPMS = this.fb.group({
      date: [this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm a')],
      shift: [''],
      crew: [''],
      manager: ['']
    })
    this.HourlyData = this.fb.group({
      hdTime: [''],
      hdGrossWidth: [''],
      hdCutWidth: [''],
      hdLehrSpeed: [''],
      hdPull: [''],
      hdThickness: [''],
      hdActualThickness: [''],
      hdProduction: [''],
      hdChangeOverTime: [''],
      hdPullYieldLosses: [''],
    })
    this.ProductionLog = this.fb.group({
      PLTime: [''],
      OrderNo: [''],
      Quality: [''],
      Stacker: [''],
      Length: [''],
      Width: [''],
      Lites: [''],
      Boxes: [''],
      BoxType: [''],
      Hold: [''],
      Destination: [''],
      nominalThick: [''],
      BoxWeight: [''],
      TotalPacked: [],
    })
    this.Event = this.fb.group({
      etime: [''],
      hotEndArea: [''],
      event: [''],
    });

    this.RepackingGroup = this.fb.group({
      Thickness: [''],
      Length: [''],
      Width: [''],
      Lites: [''],
      Boxes : [''],
      BoxType: [''],
      Reason: [''],
    });
    this.LoadBaseDetails();
    this.isOPMSUser ? this.makeYieldForm() : '';
  }
  dateFormatter(params: any): any {
    if(params.value){
      return moment(new Date(params.value)).format('MM/DD/YYYY hh:mm a');
    }
  }
  dateFormatterYL(params: any): any {
    if(params.data.ShiftYieldlossInfoId !== 0){
      if(params.value){
        return moment(new Date(params.value)).format('MM/DD/YYYY hh:mm a');
      }
    }else{
      return '';
    }
  }
  saveDeletedDataForOPMS(params: any,description: string,id: any){
    const data = {
      pageId: id ? 0 : localStorage.getItem('O3RightMenuId'),
      dataDescription: description,
      deletedData: JSON.stringify(params.data),
      deletedBy: JSON.parse(localStorage.user).UserId,
      id: id
    }
    this.opmsGlassservice.SaveDeletedDataForOPMS(data).subscribe(
      (response: any) => {
        if(!response.HasError){
          if(description){
            if(description == 'Hourly Data'){
              this.opmsGlassservice.deleteHourlyData(this.data).subscribe(
                (response1: any)=>{
                  if(!response1.HasError){
                    this.toaster.success("Hourly Data Deleted Successfuly");
                    this.GetHourlyData();
                    this.RefreshHourlyData();
                    this.dialog.closeAll();
                  }
                  else{
                    this.toaster.error('Error', response1.ErrorMessage);
                    this.saveDeletedDataForOPMS(params,'',response.result[0].id);
                  }
                },
              )
            }
            if(description == 'Production Log'){
              const YieldExist = this.rowYieldLosses.find((yielddata: any) => {
                const a = params.data.PLTime;
                const b = yielddata.Time;
                return a == b;
              });
              this.opmsGlassservice.submitProductionLog(this.data).subscribe(
                (response1: any)=>{
                  if(!response1.HasError){
                    this.toaster.success("Production Log Deleted Successfuly");
                    this.getProductionLog();
                    if(YieldExist){
                      this.productionTransId = 2;
                      this.spinner.show();
                      this.makeYieldForm();
                      let dataYL = {
                        ShiftYieldlossInfoId: YieldExist.ShiftYieldlossInfoId,
                        UserId: JSON.parse(localStorage.user).UserId,
                        time: this.ProductionLog.value.PLTime
                      }
                      this.editYieldLossByPL(dataYL);
                    }
                    else{
                      this.refreshProductionLog()
                    }
                    this.dialog.closeAll();
                  }
                  else{
                    this.toaster.error('Error', response1.ErrorMessage);
                    this.saveDeletedDataForOPMS(params,'',response.result[0].id);
                   }
                }
              );
            }
            if(description == 'Yield Losses'){
              this.opmsGlassservice.submitYieldLoss(this.data).subscribe(
                (response1: any)=>{
                  if(!response1.HasError){
                    this.toaster.success("Shift Yield Loss deleted successfuly");
                    this.getYieldLoss();
                  }
                  else{
                    this.toaster.error(response1.ErrorMessage);
                    this.saveDeletedDataForOPMS(params,'',response.result[0].id);
                  }
                }
              )
            }
            if(description == 'Repacking'){
              const key = {
                TransactionTypeId: 3,
                ShiftReportOPMSId: this.ShiftReportId,
                ShiftRepackId: params.data.ShiftRepackId,
                Thickness: params.data.Thickness,
                Length: params.data.Length,
                Width: params.data.Width,
                Lites: params.data.Lites,
                Boxes: params.data.Boxes,
                BoxType: params.data.BoxType,
                Reason: params.data.Reason,
                UserId: JSON.parse(localStorage.user).UserId,
              };
              this.opmsGlassservice.saveRepacking(key).subscribe(
                (response1: any) => {
                  if(!response1.HasError){
                    this.toaster.success("Repacking Deleted Successfuly");
                    this.getRepacking();
                    this.dialog.closeAll();
                  }
                  else{
                    this.toaster.error(response1.ErrorMessage);
                    this.saveDeletedDataForOPMS(params,'',response.result[0].id);
                  }
                }
              );
            }
            if(description == 'OPMS & HotEnd Events'){
              this.opmsGlassservice.deleteEvent(this.data).subscribe(
                (response1: any)=>{
                  if(!response1.HasError){
                    this.toaster.success("Event Deleted Successfuly");
                    this.GetEvent();
                    this.RefreshEvent();
                    this.dialog.closeAll();
                  }
                  else{
                    this.toaster.error(response1.ErrorMessage);
                    this.saveDeletedDataForOPMS(params,'',response.result[0].id);
                  }
                }
              )
            }
          }
        }else{
          if(description){
            this.toaster.error('Error','Someting is Wrong while Deleting '+ description);
          }
          else{
            this.toaster.error(response.result[0].Msg);
          }
        }
      },
      (error: any) => {
        this.toaster.error('Error','Someting is Wrong while Deleting ' + description);
      }
    )
  }
}


