import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { OperatorExecutionService } from 'src/app/services/operator-execution.service';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { ManagerExecutionService } from 'src/app/services/manager.service';
import { environment } from 'src/environments/environment';
import { PrevConfigurationService } from 'src/app/services/prev-configuration.service';
import * as moment from 'moment';
import { DtServiceService } from 'src/app/services/dtService.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-operator-execution-form',
  templateUrl: './operator-execution-form.component.html',
  styleUrls: ['./operator-execution-form.component.scss'],
})
export class OperatorExecutionFormComponent implements OnInit {
  spinnerText: string = '';
  breadcrumList!: any;
  groups!: any[];
  filteredGroups!: any[];
  plants!: any[];
  filteredPlants!: any[];
  @ViewChild('operationimageViewModal') private operationimageViewModal!: TemplateRef<any>;
  @ViewChild('technicianimageViewModal') private technicianimageViewModal!: TemplateRef<any>;
  operatorForm = new FormGroup({
    group: new FormControl('', [Validators.required]),
    plant: new FormControl('', [Validators.required]),
    line: new FormControl('', [Validators.required]),
    unit: new FormControl('', [Validators.required]),
    assembly: new FormControl(''),
    subAssembly: new FormControl(''),
    component: new FormControl(''),
    status: new FormControl('', [Validators.required]),
    woType: new FormControl('', [Validators.required]),
    failMode: new FormControl('', [Validators.required]),
    spare: new FormControl('', [Validators.required]),
    remark: new FormControl('', [Validators.required]),
    TimeStamp: new FormControl('', [Validators.required]),
    StartTime: new FormControl(''),
    CompleteTime: new FormControl(''),
    TechRemark: new FormControl(''),
  });
  formDirective: any;
  user: any;
  woStatus: any = [];
  woType: any = [];
  filteredWoType: any = [];
  failMode: any = [];
  filteredFailMode: any = [];
  operator: any = {
    line: '',
    units: '',
    assembly: '',
    subAssembly: '',
    component: '',
    Group: '',
    Plant: '',
    TimeStamp: new Date(),
    WOStatus: 1,
    WOType: '',
    FailureMode: '',
    SpareParts: false,
    Remark: '',
    UploadPicture: [],
    createdBy: '',
    RequestedBy: '',
    lineName: '',
    unitName: '',
    assemblyName: '',
    subAssemblyName: '',
    componentName: '',
    displayMachine: '',
  };
  techEntryValues: any;
  userGroup: any = 0;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('picker') picker: any;
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  updateOrderprocessingData = false;
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;
  index: any;
  isEdit: boolean = false;
  operatorObj: any;

  imageSrc = new Array<string>();
  fileType: any;
  FileData: any = [];
  FileList: any = [];
  EditFileData: any = [];
  IsWrite: boolean = false;
  blobStorageUrl: string = '';
  isView: boolean = false;

  LineList: any = [];
  LineFilterList: any = [];
  LineAllList: any = [];

  UnitList: any = [];
  UnitFilterList: any = [];
  UnitAllList: any = [];

  AssemblyList: any = [];
  AssemblyFilterList: any = [];
  AssemblyAllList: any = [];

  SubAssemblyList: any = [];
  SubAssemblyFilterList: any = [];
  SubAssemblyAllList: any = [];

  ComponentList: any = [];
  ComponentFilterList: any = [];
  ComponentAllList: any = [];

  constructor(
    private userManagementService: UserManagementService,
    private toaster: ToastrService,
    private router: Router,
    private enterpriseGroupService: EnterPriseGroupService,
    private analyticalToolsService: AnalyticalToolsService,
    private operatorExecutionService: OperatorExecutionService,
    private sfdService: ShopFloorDataService,
    private managerExecutionService: ManagerExecutionService,
    private prevConfigurationService: PrevConfigurationService,
    private spinner: NgxSpinnerService,
    private dtService: DtServiceService,
    private dialog:MatDialog,
  ) { }

  ngOnInit(): void {
    this.getGroups();
    this.blobStorageUrl = environment.O3CoreApiImageUrl;
    this.woStatus = this.operatorExecutionService.getWoStatusList();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.operatorObj = this.managerExecutionService.getManagerData();
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.operator.createdBy = this.user.UserId;
    let woHeaderData = this.operatorExecutionService.getWOHeaderData();
    this.userGroup = this.analyticalToolsService.getUserGroup();
    if (woHeaderData) {
      if (woHeaderData.Group) this.operator.Group = woHeaderData.Group;
      if (woHeaderData.Plant) this.operator.Plant = woHeaderData.Plant;
    } else if (this.userGroup) this.operator.Group = this.userGroup;
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  // img popup
  imgview(e:any){
    let dialogRef = this.dialog.open(this.operationimageViewModal, {
      height: '90%',
      width: '90%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  oncloseimg(){
    this.dialog.closeAll();
  }
  imgview1(e:any){
    let dialogRef = this.dialog.open(this.technicianimageViewModal, {
      height: '90%',
      width: '90%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  oncloseimg1(){
    this.dialog.closeAll();
  }
  // img popup

  findOperatorData() {
    this.spinnerText = 'Fetching Manager Data';
    this.spinner.show();
    this.operatorExecutionService
      .findOperatorData(this.operatorObj.WOId)
      .subscribe(
        (res: any) => {
          if (res.result) {
            this.operator.Group = parseInt(res.result.Group.egId);
            this.operator.Plant = parseInt(res.result.Plant.ptId);
            this.getTreeMasterData('edit',res);
            if (res.result.techExc) {
              this.techEntryValues = res.result.techExc;
              this.techEntryValues.StartTime = this.techEntryValues.StartTime
                ? moment(this.techEntryValues.StartTime).format(
                    'DD/MM/YYYY hh:mm a'
                  )
                : '';
              this.techEntryValues.CompleteTime = this.techEntryValues
                .CompleteTime
                ? moment(this.techEntryValues.CompleteTime).format(
                    'DD/MM/YYYY hh:mm a'
                  )
                : '';
              const chkReOpen = this.woStatus.find((stat: any) => stat.id == 6);
              if (!chkReOpen) this.woStatus.push({ id: 6, title: 'ReOpen' });
            }
            this.operator.ID = res.result.ID;
            
            this.operator.Remark = res.result.Remark;
            this.operator.WOType = parseInt(res.result.WOType.ID);
            this.operator.FailureMode = parseInt(res.result.FailureMode.ID);
            this.operator.SpareParts = parseInt(res.result.SpareParts);
            this.operator.WOStatus = parseInt(res.result.WOStatus);
            this.operator.displayMachine = '';
            this.operator.displayMachine += res.result.line
              ? res.result.line.plDesc
              : '';

            if (res.result.units)
              this.operator.displayMachine += '/' + res.result.units.puDesc;
            if (res.result.assembly) {
              this.operator.displayMachine +=
                '/' + res.result.assembly.assblyDesc;
              // this.operator.assembly = res.result.assembly.assblyId;
            }
            if (res.result.subAssembly) {
              this.operator.displayMachine +=
                '/' + res.result.subAssembly.sAsblyDesc;
              // this.operator.subAssembly = res.result.subAssembly.sAssblyId;
            }
            if (res.result.component) {
              this.operator.displayMachine +=
                '/' + res.result.component.componentName;
              // this.operator.component = res.result.component.componentId;
            }

            let OperatorUploadFiles: any = [];
            if (res.result.UploadPicture) {
              OperatorUploadFiles = res.result.UploadPicture.split(',');
              for (let i = 0; i < OperatorUploadFiles.length; i++) {
                this.EditFileData.push({ ImageSrc: OperatorUploadFiles[i] });
              }
            }

            this.woType = res.WOTypes;
            this.filteredWoType = this.woType.slice();
            this.failMode = res.failureModes;
            this.filteredFailMode = this.failMode.slice();
          }
          this.spinner.hide();
        },
        (err: any) => {
          this.spinner.hide();
        }
      );
  }

  getOperatorMasterData(type: any) {
    this.spinnerText = 'Fetching Master Data';
    this.spinner.show();
    const data = {
      WOType: this.operator.WOType,
      group: this.operator.Group,
      plant: this.operator.Plant,
    };
    this.operatorExecutionService.getOperatorMasterData(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          if (type != 'change') {
            if (res.WOTypes && res.WOTypes.length) {
              this.woType = res.WOTypes;
              this.filteredWoType = this.woType.slice();
              this.operator.WOType = this.woType[0].ID;
              this.getFailureData('not change');
            }
          } else {
            this.failMode = res.failureModes;
            this.filteredFailMode = this.failMode.slice();
            if (this.filteredFailMode && this.filteredFailMode.length) {
              this.operator.FailureMode = this.failMode[0].ID
                ? this.failMode[0].ID
                : '';
            }
          }
        } else this.toaster.error('Error', res.message);
      },
      (err) => {
        this.spinner.hide();
        this.toaster.error('Error', err.message);
      }
    );
  }
  getFailureData(type: any) {
    this.spinnerText = 'Fetching Master Data';
    this.spinner.show();
    const data = {
      WOType: this.operator.WOType,
      group: this.operator.Group,
      plant: this.operator.Plant,
    };
    this.operatorExecutionService.getOperatorMasterData(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          if (type != 'change') {
            if (res.WOTypes && res.WOTypes.length) {
              this.woType = res.WOTypes;
              this.filteredWoType = this.woType.slice();
              this.operator.WOType = this.woType[0].ID;
            }
            if (res.failureModes && res.failureModes.length) {
              this.failMode = res.failureModes;
              this.filteredFailMode = this.failMode.slice();
              this.operator.FailureMode = this.failMode[0].ID
                ? this.failMode[0].ID
                : '';
            }
          } else {
            this.failMode = res.failureModes;
            this.filteredFailMode = this.failMode.slice();
            if (this.filteredFailMode && this.filteredFailMode.length) {
              this.operator.FailureMode = this.failMode[0].ID
                ? this.failMode[0].ID
                : '';
            }
          }

          if (this.operator.WOType && this.failMode && !this.failMode.length)
            this.toaster.warning(
              'Warning',
              'Failure Mode Data Not Found For This WO Type'
            );
        } else this.toaster.error('Error', res.message);
      },
      (err) => {
        this.spinner.hide();
        this.toaster.error('Error', err.message);
      }
    );
  }
  openTimePicker(timepicker: { open: () => void }) {
    timepicker.open();
  }

  get of() {
    return this.operatorForm.controls;
  }

  openFile() {
    this.fileInput.nativeElement.click();
  }

  readFile(event: any) {
    const files = event.target.files;
    if (files) {
      for (let file of files) {
        if (file.type.match('image.*')) {
          this.fileType = 'image';
          this.FileList.push(file);
        } else {
          this.toaster.warning('Warning', 'File Format Not Valid ');
          return;
        }
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageSrc = e.target.result;
          this.FileData.push({
            FileType: this.fileType,
            ImageSrc: this.imageSrc,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }

  removeFile(i: any) {
    this.FileData.splice(i, 1);
    this.FileList.splice(i, 1);
  }

  deleteFile(i: any) {
    this.EditFileData.splice(i, 1);
    this.operator.UploadPicture.push(i);
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  onSubmit(formDirective: FormGroupDirective) {
    if (this.operatorForm.invalid) {
      this.toaster.warning('Please Fill All Required Field', 'Error', {
        timeOut: 3000,
      });
      return;
    }
    else {
      if (this.operator.SpareParts == true) {
        this.operator.SpareParts = 1;
      } else {
        this.operator.SpareParts = 0;
      }
      this.formDirective = formDirective;
      const formData = new FormData();
      for (const file of this.FileList)
        formData.append('image', file, file.name);
      this.operator.RequestedBy = this.operator.createdBy;
      formData.append('data', JSON.stringify(this.operator));
      this.spinnerText = 'Creating Work Order';
      this.spinner.show();
      if (!this.isEdit) {
        this.operatorExecutionService.createWorkOrder(formData).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.formDirective.resetForm();
            this.operatorForm.reset();
            this.router.navigate(['/PM/operator']);
            if (res.status) {
              this.toaster.success('Success', res.message);
            } else this.toaster.error('Error', res.message);
          },
          (error) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          }
        );
      } else {
        this.operatorExecutionService.updateWorkOrder(formData).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.isEdit = false;
            this.formDirective.resetForm();
            this.operatorForm.reset();
            this.router.navigate(['/PM/operator']);
            if (res.status) {
              this.operator.UploadPicture = [];
              this.toaster.success('Success', res.message);
            } else this.toaster.error('Error', res.message);
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          }
        );
      }
    }
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.sfdService.getGroupData(key).subscribe((res: any) => {
      this.groups = res.masterList;
      this.filteredGroups = this.groups.slice();
      if (this.filteredGroups && this.filteredGroups.length) {
        if (this.operator.Group) {
          const find = this.filteredGroups.find(
            (grp: any) => grp.TypeId == this.operator.Group
          );
          this.operator.Group = find ? find.TypeId : this.groups[0].TypeId;
        } else this.operatorForm.get('group')?.setValue(this.groups[0].TypeId);
        this.getPlants();
      }
    });
  }

  getPlants(): void {
    const key = {
      GroupId: this.operator.Group,
    };
    this.spinner.show();
    this.dtService.getPlantData(key).subscribe(
      (res: any) => {
        this.plants = res.DepartList;
        this.filteredPlants = this.plants.slice();
        if (this.filteredPlants && this.filteredPlants.length) {
          if (this.operator.Plant) {
            const find = this.filteredPlants.find(
              (pnt: any) => pnt.DeptId == this.operator.Plant
            );
            this.operator.Plant = find ? find.DeptId : this.plants[0].DeptId;
          } else
            this.operatorForm.get('plant')?.setValue(this.plants[0].DeptId);
            if (this.operatorObj) {
              this.isEdit = true;
              this.isView = this.operatorObj.isView;
              this.findOperatorData();
            } else {
              this.woStatus.pop();
              this.getTreeMasterData('notChange','');
            }
          if (!this.isEdit) this.getOperatorMasterData('');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getTreeMasterData(type: string,editData: any) {
    const data = { GroupID: this.operator.Group, PlantID: this.operator.Plant };
    this.spinner.show();
    this.prevConfigurationService.GetTreeMasterData(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.LineAllList = [];
        this.LineList = [];
        this.LineFilterList = [];
        this.UnitAllList = [];
        this.UnitList = [];
        this.UnitFilterList = [];
        this.AssemblyAllList = [];
        this.AssemblyList = [];
        this.AssemblyFilterList = [];
        this.SubAssemblyAllList = [];
        this.SubAssemblyList = [];
        this.SubAssemblyFilterList = [];
        this.ComponentAllList = [];
        this.ComponentList = [];
        this.ComponentFilterList = [];

        this.LineAllList = res.Line;
        this.LineList = res.Line;
        this.UnitAllList = res.Unit;
        this.AssemblyAllList = res.Assembly;
        this.SubAssemblyAllList = res.SubAssembly;
        this.ComponentAllList = res.Component;

        this.LineFilterList = this.LineList.slice();
        if (this.LineAllList && this.LineFilterList.length && type != 'edit') {
          this.operator.line = this.LineList[0].PL_Id
            ? this.LineList[0].PL_Id
            : '';
        }
        if(type == 'notChange'){
          this.setUnitList('notChange');
          this.setAssemblyList('notChange');
          this.setSubAssemblyList('notChange');
          this.setComponentList('notChange');
        }
        else{
          this.operator.line = editData.result.line ? editData.result.line.plId : '';
          this.setUnitList('edit');
          this.operator.units = editData.result.units ? editData.result.units.puId : '';
          this.setAssemblyList('edit');
          this.operator.assembly = editData.result.assembly ? editData.result.assembly.assblyId : '';
          this.setSubAssemblyList('edit');
          this.operator.subAssembly = editData.result.subAssembly ? editData.result.subAssembly.sAssblyId : '';
          this.setComponentList('edit');
          this.operator.component = editData.result.component ? editData.result.component.componentId : '';
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  setUnitList(type: any) {
    this.UnitList = [];
    this.UnitFilterList = [];
    this.AssemblyList = [];
    this.AssemblyFilterList = [];
    this.SubAssemblyList = [];
    this.SubAssemblyFilterList = [];
    this.ComponentList = [];
    this.ComponentFilterList = [];

    let unitList = this.UnitAllList.filter(
      (unit: any) => unit.PL_Id == this.operator.line
    );
    if(type == 'edit'){
      this.UnitList = unitList;
      this.UnitFilterList = this.UnitList.slice();
      return;
    }
    if (type == 'change') {
      this.operator.units = '';
      this.operator.assembly = '';
      this.operator.subAssembly = '';
      this.operator.component = '';
    } else {
      if (unitList && unitList.length) {
        this.UnitList = unitList;
        this.UnitFilterList = this.UnitList.slice();
        this.operator.units = this.UnitFilterList[0].PU_Id
          ? this.UnitFilterList[0].PU_Id
          : '';
      }
    }
    if (unitList && unitList.length) {
      this.UnitList = unitList;
      this.UnitFilterList = this.UnitList.slice();
      this.operator.units = this.UnitFilterList[0].PU_Id
        ? this.UnitFilterList[0].PU_Id
        : '';
      if (type == 'change') {
        // this.operator.units = this.UnitFilterList[0].PU_Id
        //   ? this.UnitFilterList[0].PU_Id
        //   : '';
        this.setAssemblyList('change');
      }
    }
  }

  setAssemblyList(type: any) {
    this.AssemblyList = [];
    this.AssemblyFilterList = [];
    this.SubAssemblyList = [];
    this.SubAssemblyFilterList = [];
    this.ComponentList = [];
    this.ComponentFilterList = [];
    let assemblyList = this.AssemblyAllList.filter(
      (assembly: any) => assembly.PU_Id == this.operator.units
    );
    if(type == 'edit'){
      this.AssemblyList = assemblyList;
      this.AssemblyFilterList = this.AssemblyList.slice();
      return;
    }
    if (type == 'change') {
      this.operator.assembly = '';
      this.operator.subAssembly = '';
      this.operator.component = '';
    } else {
      if (assemblyList && assemblyList.length) {
        this.AssemblyList = assemblyList;
        this.AssemblyFilterList = this.AssemblyList.slice();
        this.operator.assembly = this.AssemblyList[0].Assbly_Id
          ? this.AssemblyFilterList[0].Assbly_Id
          : '';
      }
    }

    if (assemblyList && assemblyList.length) {
      this.AssemblyList = assemblyList;
      this.AssemblyFilterList = this.AssemblyList.slice();
      if (type == 'change' && assemblyList.length) {
        this.operator.assembly = this.AssemblyList[0].Assbly_Id
          ? this.AssemblyFilterList[0].Assbly_Id
          : '';
        this.setSubAssemblyList('change');
      }
    }
  }

  setSubAssemblyList(type: any) {
    this.SubAssemblyList = [];
    this.SubAssemblyFilterList = [];
    this.ComponentList = [];
    this.ComponentFilterList = [];
    let subassemblyList = this.SubAssemblyAllList.filter(
      (subassembly: any) => subassembly.Assbly_Id == this.operator.assembly
    );
    if(type == 'edit'){
      this.SubAssemblyList = subassemblyList;
      this.SubAssemblyFilterList = this.SubAssemblyList.slice();
      return;
    }
    if (type == 'change') {
      this.operator.subAssembly = '';
      this.operator.component = '';
    } else {
      if (subassemblyList && subassemblyList.length) {
        this.SubAssemblyList = subassemblyList;
        this.SubAssemblyFilterList = this.SubAssemblyList.slice();
        this.operator.subAssembly = this.SubAssemblyList[0].SAssbly_Id
          ? this.SubAssemblyList[0].SAssbly_Id
          : '';
      }
    }

    if (subassemblyList && subassemblyList.length) {
      this.SubAssemblyList = subassemblyList;
      this.SubAssemblyFilterList = this.SubAssemblyList.slice();
      if (type == 'change' && subassemblyList.length) {
        this.operator.subAssembly = this.SubAssemblyList[0].SAssbly_Id
          ? this.SubAssemblyList[0].SAssbly_Id
          : '';
        this.setComponentList('change');
      }
    } else {
    }
  }

  setComponentList(type: any) {
    this.ComponentList = [];
    this.ComponentFilterList = [];
    let componentList = this.ComponentAllList.filter(
      (component: any) => component.SAssbly_Id == this.operator.subAssembly
    );
    if(type == 'edit'){
      this.ComponentList = componentList;
      this.ComponentFilterList = this.ComponentList.slice();
      return;
    }
    if (type == 'change') {
      this.operator.component = '';
      if (componentList && componentList.length)
        this.operator.component = componentList[0].ComponentId
          ? componentList[0].ComponentId
          : '';
    } else {
      if (componentList && componentList.length) {
        this.ComponentList = componentList;
        this.ComponentFilterList = this.ComponentList.slice();
        this.operator.component = this.ComponentList[0].ComponentId
          ? this.ComponentList[0].ComponentId
          : '';
      }
    }

    if (componentList && componentList.length) {
      this.ComponentList = componentList;
      this.ComponentFilterList = this.ComponentList.slice();
      if (type == 'change' && componentList.length) {
        this.operator.component = this.ComponentList[0].ComponentId
          ? this.ComponentList[0].ComponentId
          : '';
      }
    } else {
    }
  }

  searchValues(filter: any, type: string) {
    filter = filter.target.value;
    if (type === 'group')
      this.filteredGroups = this.groups.filter((user: any) => {
        let lowerCase = user.TypeName.toLocaleLowerCase();
        return user.TypeName.startsWith(filter) || lowerCase.startsWith(filter);
      });
    else if (type === 'woType')
      this.filteredWoType = this.woType.filter((wo: any) => {
        let lowerCase = wo.Name.toLocaleLowerCase();
        return wo.Name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    else if (type === 'failMode')
      this.filteredFailMode = this.failMode.filter((fm: any) => {
        let lowerCase = fm.Name.toLocaleLowerCase();
        return fm.Name.startsWith(filter) || lowerCase.startsWith(filter);
      });
  }

  openTreeModal(): void {
    this.updateOrderprocessingData = true;
    const key = {
      EGId: this.operator.Group,
      Level: 'Component',
      CreatedBy: 0,
      IsOptional: 0,
    };
    this.spinnerText = 'Fetching Line / Area Data';
    this.spinner.show();
    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        this.spinner.hide();
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.udpateNodeParentDetails();
        this.treeControl.dataNodes = this.plantGroupSource.data;
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  udpateNodeParentDetails() {
    if (this.plantGroupSource.data && this.plantGroupSource.data.length) {
      this.plantGroupSource.data.forEach((group) => {
        if (group.children && group.children.length) {
          group.children.forEach((plant) => {
            plant.groupName = group.text;
            plant.groupId = group.PrimaryId;
            if (plant.children && plant.children.length) {
              plant.children.forEach((division: any) => {
                division.plantId = plant.PrimaryId;
                division.plantName = plant.text;
                division.groupName = group.text;
                division.groupId = group.PrimaryId;
                if (division.children && division.children.length) {
                  division.children.forEach((department: any) => {
                    department.divisionId = division.PrimaryId;
                    department.divisionName = division.text;
                    department.plantId = plant.PrimaryId;
                    department.plantName = plant.text;
                    department.groupId = group.PrimaryId;
                    department.groupName = group.text;
                    if (department.children && department.children.length) {
                      department.children.forEach((line: any) => {
                        line.departmentId = department.PrimaryId;
                        line.departmentName = department.text;
                        line.divisionId = division.PrimaryId;
                        line.divisionName = division.text;
                        line.plantId = plant.PrimaryId;
                        line.plantName = plant.text;
                        line.groupId = group.PrimaryId;
                        line.groupName = group.text;
                        if (line.children && line.children.length) {
                          line.children.forEach((unit: any) => {
                            unit.lineId = line.PrimaryId;
                            unit.lineName = line.text;
                            unit.departmentId = department.PrimaryId;
                            unit.departmentName = department.text;
                            unit.divisionId = division.PrimaryId;
                            unit.divisionName = division.text;
                            unit.plantId = plant.PrimaryId;
                            unit.plantName = plant.text;
                            unit.groupId = group.PrimaryId;
                            unit.groupName = group.text;
                            if (unit.children && unit.children.length) {
                              unit.children.forEach((assembly: any) => {
                                assembly.unitName = unit.text;
                                assembly.unitId = unit.PrimaryId;
                                assembly.lineId = line.PrimaryId;
                                assembly.lineName = line.text;
                                assembly.departmentId = department.PrimaryId;
                                assembly.departmentName = department.text;
                                assembly.divisionId = division.PrimaryId;
                                assembly.divisionName = division.text;
                                assembly.plantId = plant.PrimaryId;
                                assembly.plantName = plant.text;
                                assembly.groupId = group.PrimaryId;
                                assembly.groupName = group.text;
                                if (
                                  assembly.children &&
                                  assembly.children.length
                                ) {
                                  assembly.children.forEach(
                                    (subAssembly: any) => {
                                      subAssembly.assemblyName = assembly.text;
                                      subAssembly.assemblyId =
                                        assembly.PrimaryId;
                                      subAssembly.unitName = unit.text;
                                      subAssembly.unitId = unit.PrimaryId;
                                      subAssembly.lineId = line.PrimaryId;
                                      subAssembly.lineName = line.text;
                                      subAssembly.departmentId =
                                        department.PrimaryId;
                                      subAssembly.departmentName =
                                        department.text;
                                      subAssembly.divisionId =
                                        division.PrimaryId;
                                      subAssembly.divisionName = division.text;
                                      subAssembly.plantId = plant.PrimaryId;
                                      subAssembly.plantName = plant.text;
                                      subAssembly.groupId = group.PrimaryId;
                                      subAssembly.groupName = group.text;
                                      if (
                                        subAssembly.children &&
                                        subAssembly.children.length
                                      ) {
                                        subAssembly.children.forEach(
                                          (component: any) => {
                                            component.subAssemblyName =
                                              subAssembly.text;
                                            component.subAssemblyId =
                                              subAssembly.PrimaryId;
                                            component.assemblyName =
                                              assembly.text;
                                            component.assemblyId =
                                              assembly.PrimaryId;
                                            component.unitName = unit.text;
                                            component.unitId = unit.PrimaryId;
                                            component.lineId = line.PrimaryId;
                                            component.lineName = line.text;
                                            component.departmentId =
                                              department.PrimaryId;
                                            component.departmentName =
                                              department.text;
                                            component.divisionId =
                                              division.PrimaryId;
                                            component.divisionName =
                                              division.text;
                                            component.plantId = plant.PrimaryId;
                                            component.plantName = plant.text;
                                            component.groupId = group.PrimaryId;
                                            component.groupName = group.text;
                                          }
                                        );
                                      }
                                    }
                                  );
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  closePlantGroupModel(): void {
    this.updateOrderprocessingData = false;
  }

  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  onNodeSelect(node: any): void {
    this.updateOrderprocessingData = false;
    if (
      node.Level == 'Unit' ||
      node.Level == 'Assembly' ||
      node.Level == 'SubAssembly' ||
      node.Level == 'Component'
    ) {
      if (node.Level == 'Unit') {
        this.operator.line = node.lineId;
        this.operator.lineName = node.lineName;
        this.operator.units = node.PrimaryId;
        this.operator.unitName = node.text;
        this.operator.displayMachine = `${node.lineName}/${node.text}`;
        this.operator.Plant = node.plantId;
      } else if (node.Level == 'Assembly') {
        this.operator.assembly = node.PrimaryId;
        this.operator.assemblyName = node.text;
        this.operator.units = node.unitId;
        this.operator.unitName = node.unitName;
        this.operator.line = node.lineId;
        this.operator.lineName = node.lineName;
        this.operator.displayMachine = `${node.lineName}/${node.unitName}/${node.text}`;
        this.operator.Plant = node.plantId;
      } else if (node.Level == 'SubAssembly') {
        this.operator.subAssembly = node.PrimaryId;
        this.operator.subAssemblyName = node.text;
        this.operator.assembly = node.assemblyId;
        this.operator.assemblyName = node.assemblyName;
        this.operator.units = node.unitId;
        this.operator.unitName = node.unitName;
        this.operator.line = node.lineId;
        this.operator.lineName = node.lineName;
        this.operator.displayMachine = `${node.lineName}/${node.unitName}/${node.assemblyName}/${node.text}`;
        this.operator.Plant = node.plantId;
      } else if (node.Level == 'Component') {
        this.operator.component = node.PrimaryId;
        this.operator.componentName = node.text;
        this.operator.subAssembly = node.subAssemblyId;
        this.operator.subAssemblyName = node.subAssemblyName;
        this.operator.assembly = node.assemblyId;
        this.operator.assemblyName = node.assemblyName;
        this.operator.units = node.unitId;
        this.operator.unitName = node.unitName;
        this.operator.line = node.lineId;
        this.operator.lineName = node.lineName;
        this.operator.displayMachine = `${node.lineName}/${node.unitName}/${node.assemblyName}/${node.subAssemblyName}/${node.text}`;
        this.operator.Plant = node.plantId;
      }
      this.getOperatorMasterData('');
    } else this.toaster.warning('Select From Unit Level', 'Warning');
  }
}
