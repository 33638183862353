import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TaskService {
  kpiBaseUrl: string = environment.O3CoreApiUrl;
  baseUrl : string = environment.O3CoreApiUrl;
constructor(private http: HttpClient) { }
getPlantsData(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Common/GetDepartments', data);
}
getMasterProject(data:any):Observable<any[]>{
  return this.http.post<any[]>(this.kpiBaseUrl +'masterproject' ,data)
}
getMasterTopic(data:any):Observable<any[]>{
  return this.http.post<any[]>(this.kpiBaseUrl +'mastertopic' ,data)
}
addTask(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.kpiBaseUrl + 'addtask', data );
}
getMasterTask(data:any):Observable<any[]>{
  return this.http.post<any[]>(this.kpiBaseUrl +'mastertask' ,data)
}
getMasterFrequencies(data: any): Observable<any[]> {
  return this.http.get<any[]>(this.kpiBaseUrl +'taskfrequency' )
}
GetOneMasterTask(data: any): Observable<any> {
  return this.http.post<any>(this.kpiBaseUrl + 'task', data );
}
deleteTask(data: any): Observable<any> {
  return this.http.post<any>(this.kpiBaseUrl + 'deletetask', data );
}
updateTask(data: any): Observable <any[]> {;
  return this.http.put<any[]>(this.kpiBaseUrl + 'task', data );
}
getTpoicProject(data: any): Observable<any[]> {

  return this.http.post<any[]>(this.kpiBaseUrl + 'getAllTopicByProject', data);
}
}
