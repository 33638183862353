import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckpointsService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }


  getCheckpoints(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'CheckPoint/GetMasterCheckPoints', data);
  }

  getCheckpointsbyCategory(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'CheckPoint/GetMasterCheckPointsByAuditCategory', data);
  }

  GetCheck(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'CheckPoint/GetCheckPoints', data);
  }

  addCheckpoint(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CheckPoint/AddCheckPoint', data);
  }
  updateCheckpoint(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.o3CoreApiUrl + 'CheckPoint/UpdateCheckPoint', data);
  }
  deleteCheckpoint(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'CheckPoint/DeleteCheckPoint', data);
  }


// Process Checkpoints starts here

getProcessCheckpoints(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'masterprocesscheckpoint', data);
}
getProcessCheckpointsbyCategory(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'ProcessCheckPoint/GetMasterCheckPointsByAuditCategory', data);
}

addProcessCheckpoint(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'processcheckpoint', data);
}
updateProcessCheckpoint(data: any): Observable<any[]> {
  return this.http.put<any[]>(this.baseUrl + 'processcheckpoint', data);
}
deleteProcessCheckpoint(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'deleteprocesscheckpoint', data);
}




}
