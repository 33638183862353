import { Component, OnInit, ViewChild } from '@angular/core';
import { DefectService } from 'src/app/services/defect.service';
import { ToastrService } from 'ngx-toastr';
import { MatTable, MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-qx-upload',
  templateUrl: './qx-upload.component.html',
  styleUrls: ['./qx-upload.component.scss']
})
export class QxUploadComponent implements OnInit {
  fileSave!: any;
  @ViewChild(MatTable) table!: MatTable<any>;
  fileColumns: string[] = ['plant', 'defect', 'unit', 'component'];
  fileData!: MatTableDataSource<any>;
  constructor(
    private defectService: DefectService ,
    private toaster : ToastrService
  ) { }

  ngOnInit() {
  }

  addFile(event: any): void{
    const formData = new FormData;
    formData.append("file", this.fileSave);

    this.defectService.addDefectFile(formData).subscribe(data => {
      this.fileData = new MatTableDataSource<any>(data);
    },(error: any) => {
      this.toaster.error("Error", error.message)
    })
  }
  uploadedFile(event: any): void {
    if (event.target.files.length > 0) {
        this.fileSave = event.target.files[0];
    }
}

}
