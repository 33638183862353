import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { ButtonEditRendererComponent } from 'src/app/components/button-edit-render.components';
import { DatePickerComponent } from 'src/app/components/date-picker.components';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { ProductCostConfigurationService } from 'src/app/services/product-cost-configuration.service';

@Component({
  selector: 'app-product-cost-configuration',
  templateUrl: './product-cost-configuration.component.html',
  styleUrls: ['./product-cost-configuration.component.scss']
})
export class ProductCostConfigurationComponent implements OnInit {

 
  sfdcnewform!: FormGroup;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  productList!: any[];
  uomList!: any[];
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  filteredUnits: any;
  filteredProducts:any;
  filteredUOMs:any;
  constructor(
    private formBuilder: FormBuilder,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private productivityservice: ProductivityService,
    private spinner: NgxSpinnerService,
    private service: ProductCostConfigurationService,
    public router: Router,
  ) {
   
  }

  ngOnInit(): void {
    this.initForm();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.datasource();
    this.getUOMList();
  }

  //Onchange methods 
  onChangeGroup(data: any): void {
    this.getPlants(data);
  }
  onChangeLine(data: any): void {
    this.getUnit(data.value);
  }
  onChangePlant(data: any): void {
    this.getLine(data.value);
  }

  onChangeUnit(): void {
    this.getProductsByInit();
  }

  initForm() {
    const dateStart = new Date();
    //dateStart.setDate(dateStart.getDate() - 183);
    dateStart.setHours(6);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);


    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate());

    this.sfdcnewform = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
      product: ['', []],
      cost: [''],
      uom: ['', []],
      weight:['',[]]
    });
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  datasource(): void {
    const key = {
      UserId: 206     //later it will be change this is only for testing
    };
    const id = {
      value: 0
    };
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      if (this.GroupList.length > 0) {
        id.value = data.lstEG[0].EG_Id;
        this.sfdcnewform.get('UserGroup1')?.setValue(data.lstEG[0].EG_Id);
        this.sfdcnewform.get('UserPlant1')?.setValue(data.lstEG[0].EG_Id);
        this.filteredGroups = this.GroupList.slice();
        this.getPlants(id);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: 206
    };
    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      if (this.PlantList1.length > 0) {
        const id = data.lstPlant[0].PT_Id;
        this.sfdcnewform.get('UserPlant1')?.patchValue(id);
        this.filteredPlants = this.PlantList1.slice();
        this.getLine(id);
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getLine(id: any) {
    const key = {
      PT_ID: id,
      userId: 206
    };

    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      if (this.lineList.length > 0) {
        const lineDefault = this.lineList[0];
        this.sfdcnewform.controls.line.patchValue(lineDefault.PL_Id);
        this.filteredLines = this.lineList.slice();
        this.getUnit(lineDefault.PL_Id);
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUnit(id: number) {
    const key = {
      PL_ID: id,
      userId: 206
    };

    this.productivityservice.getUnitData(key).subscribe((data: any) => {
      this.unitList = data.lstUnit;
      if (this.unitList.length > 0) {
        const unitDefault = this.unitList[0];
        this.sfdcnewform.controls.unit.patchValue(unitDefault.PU_Id);
        this.filteredUnits = this.unitList.slice();
      }
      this.getProductsByInit();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
 

 
  getProductsByInit(): void {
    const key = {
      Id: this.sfdcnewform.get('unit')?.value,
    };
    this.service.getProductDDByUnit(key).subscribe((data: any) => {
      this.productList = data.masterList;
      this.filteredProducts = this.productList.slice();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUOMList(): void {
    const key = {};
    this.service.getUOMList(key).subscribe((data: any) => {
      this.uomList = data.masterList;
      this.filteredUOMs = this.uomList.slice();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  dateAsYYYYMMDDHHNNSS(date: any): string {
    return date.getFullYear()
      + '-' + this.leftpad(date.getMonth() + 1, 2)
      + '-' + this.leftpad(date.getDate(), 2)
      + ' ' + this.leftpad(date.getHours(), 2)
      + ':' + this.leftpad(date.getMinutes(), 2)
      + ':' + this.leftpad(date.getSeconds(), 2);
  }
  leftpad(val: any, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
      + String(val)).slice(String(val).length);
  }
  

}
