import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cil-report',
  templateUrl: './cil-report.component.html',
  styleUrls: ['./cil-report.component.scss']
})
export class CilReportComponent implements OnInit {

  dataSource=[{machine:'1', component:'2'}];

  cilreportColumns: string[] = [
    'machine',
    'component',
    'taskname',
    'type',
    'status',
    'timestamp',
    'starttime',
    'endtime',
    'actualtime',
    'estimatedtime',
    'remarks',
    'task',
    'taskimg',
    'sop',
    'username',

  ];
  constructor() { }

  ngOnInit() {
  }

}
