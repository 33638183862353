<div dir="{{languageDir}}">
<div class="content-top-box">
    <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a
      >
    </li>
  </ul>
  <div class="tab-content-top-box">
    <h1 class="main-title">{{langObj ? langObj?.ActionDashboard : ('ActionDashboard' | translate)}}</h1>
  </div>
  <form [formGroup]="ActionForm" >
    <div class="content-top-row">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
      <mat-label >{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}</mat-label>
      <mat-select panelClass="testClass" name="group" formControlName="group"
      (selectionChange)="getPlantByGroup()">
      <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groupListDDL"
      (filteredReturn)="filteredGroupListDDL = $event"></mat-select-filter>
      <mat-option *ngFor="let g of filteredGroupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
    </mat-select>
      <mat-error >{{langObj ? langObj?.group : ('group' | translate)}}</mat-error>
    </mat-form-field>
    <mat-form-field  appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
      <mat-label >{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}</mat-label>
      <mat-select panelClass="testClass" name="plant" formControlName="plant"
       (selectionChange)="getActionsData()">
       <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plants"
       (filteredReturn)="filteredPlants = $event"></mat-select-filter>
        <mat-option *ngFor="let p of filteredPlants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
      </mat-select>
      <mat-error >{{langObj ? langObj?. plant : (' plant' | translate)}}</mat-error>
    </mat-form-field>
    <div class="inputField">
      <nz-date-picker (ngModelChange)="getActionsData()" class="calender-input"  id="startPicker" formControlName="startTime" nzFormat="dd/MM/yyyy"
      [nzInputReadOnly]="true" ></nz-date-picker>
      <span class="floating-text">{{langObj ? langObj?.StartTime : ('StartTime' | translate)}}</span>   
    </div>
    <div class="inputField">
      <nz-date-picker (ngModelChange)="getActionsData()" class="calender-input"  id="endPicker" formControlName="endTime" nzFormat="dd/MM/yyyy"
     [nzInputReadOnly]="true" ></nz-date-picker>
      <span class="floating-text">{{langObj ? langObj?.EndTime : ('EndTime' | translate)}}</span>   
    </div>
    </div>

  </form>
</div>

<div class="p-grid p-fluid">
  <div class="p-col-12 p-md-6 p-lg-6 p-xl-6">
    <table mat-table [dataSource]="StatusdataSource" class="mat-elevation-z8" style="height: 40%;">
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> {{langObj ? langObj?.Status : ('Status' | translate)}} </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="no">
        <th mat-header-cell *matHeaderCellDef> # </th>
        <td mat-cell *matCellDef="let element"> {{element.no}} </td>
      </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="percent">
        <th mat-header-cell *matHeaderCellDef> % </th>
        <td mat-cell *matCellDef="let element"> {{element.percent}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="dbStatusColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: dbStatusColumns;"></tr>
    </table>
    <mat-paginator 
    class="basic-paginataor" 
    [pageSizeOptions]="[5, 5, 10, 20]"
    showFirstLastButtons>
  </mat-paginator>
  </div>
  <div class="p-col-12 p-md-6 p-lg-6 p-xl-6">
     <div id="container"></div> 

  </div>
</div>
<div class="p-grid p-fluid">
  <div class="p-col-12 p-md-12 p-lg-12 p-xl-12">
    <table mat-table [dataSource]="ResponsibledataSource" class="mat-elevation-z8" >
      <ng-container matColumnDef="responsible">
        <th mat-header-cell *matHeaderCellDef>  {{langObj ? langObj?.Responsible : ('Responsible' | translate)}} </th>
        <td mat-cell *matCellDef="let element"> {{element.responsible}} </td>
      </ng-container>
    
      <ng-container matColumnDef="overallaction">
        <th mat-header-cell *matHeaderCellDef> {{langObj ? langObj?.OverallActions : ('OverallActions' | translate)}} </th>
        <td mat-cell *matCellDef="let element"> {{element.overallaction}} </td>
      </ng-container>
    
      <ng-container matColumnDef="dueaction">
        <th mat-header-cell *matHeaderCellDef> {{langObj ? langObj?.DueActions : ('DueActions' | translate)}} </th>
        <td mat-cell *matCellDef="let element"> {{element.dueaction}} </td>
      </ng-container>
    
      <ng-container matColumnDef="closedaction">
        <th mat-header-cell *matHeaderCellDef> {{langObj ? langObj?.ClosedActions : ('ClosedActions' | translate)}} </th>
        <td mat-cell *matCellDef="let element"> {{element.closedaction}} </td>
      </ng-container>
    
      <ng-container matColumnDef="completion">
        <th mat-header-cell *matHeaderCellDef> {{langObj ? langObj?.OverallCompletion : ('OverallCompletion' | translate)}} </th>
        <td mat-cell *matCellDef="let element"> {{element.completion}} </td>
      </ng-container>
    
      <ng-container matColumnDef="uptodate">
        <th mat-header-cell *matHeaderCellDef> {{langObj ? langObj?.uptodate : ('uptodate' | translate)}} </th>
        <td mat-cell *matCellDef="let element"> {{element.uptodate}} </td>
      </ng-container>
    
      <ng-container matColumnDef="meetings">
        <th mat-header-cell *matHeaderCellDef> {{langObj ? langObj?.noofmeetings : ('noofmeetings' | translate)}} </th>
        <td mat-cell *matCellDef="let element"> {{element.meetings}} </td>
      </ng-container>
    
      <ng-container matColumnDef="saturation">
        <th mat-header-cell *matHeaderCellDef> {{langObj ? langObj?.Saturation : ('Saturation' | translate)}} </th>
        <td mat-cell *matCellDef="let element"> {{element.saturation}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="dbResponsibleColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: dbResponsibleColumns;"></tr>
    
    
    </table>
    <mat-paginator 
    class="basic-paginataor" 
    [pageSizeOptions]="[50, 100, 150, 200]"
    showFirstLastButtons>
    </mat-paginator>
  </div>
</div>


    <div class="chart-group" >
      <div class="chart-items">
        <div class="chart-title">A{{langObj ? langObj?.actionplan : ('actionplan' | translate)}}</div>
        <div id="plancontainer"></div>
      </div>
      <div class="chart-items">
        <div class="chart-title">{{langObj ? langObj?.peoplesaturation : ('peoplesaturation' | translate)}}</div>
        <div id="contributioncontainer"></div>
      </div>
    </div>
  </div>