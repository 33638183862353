import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'toggle-btn-cell-renderer',
  template: ` <mat-slide-toggle
    (change)="onChange($event)"
    [checked]="isChecked(params)"
    *ngIf="params?.node?.uiLevel === 1"
    >{{ label }}</mat-slide-toggle
  >`,
})
export class ToggleBtnCellRenderer implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams): any {
    // throw new Error('Method not implemented.');
  }
  label: string;
  params: any;

  agInit(params: any): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  isChecked(params): boolean {
    const value = params.node.key;
    const arr = params.node.allLeafChildren;
    const checked = arr[0].data.isMilestoneLocked;

    return checked;
  }
  onChange(event: any) {
    let checked = event.checked;

    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }
}
