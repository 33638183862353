import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ButtonViewRendererComponent } from 'src/app/components/button-view-render.components';
import { CILDashboardService } from 'src/app/services/cil-dashboard-service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
// import { environment } from 'src/environments/environment';
import { Chart } from 'angular-highcharts';
// import { Options } from 'highcharts';
// import { DtServiceService } from 'src/app/services/dtService.service';
// import { data } from 'jquery';
import { differenceInCalendarDays } from 'date-fns';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-new-cil-dashboard',
  templateUrl: './new-cil-dashboard.component.html',
  styleUrls: ['./new-cil-dashboard.component.scss'],
})
export class NewCilDashboardComponent implements OnInit {
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('popupViewModal') private popupViewModal!: TemplateRef<any>;
  todayDate = new Date();
  disabledDate = (current: Date): boolean =>
  // Can not select days before today and today
  differenceInCalendarDays(current, this.todayDate) > 0;
  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  filteredUnits: any;
  filteredComponents: any;
  breadcrumList: any;
  moduleId!: any;
  pageId!: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  componentList!: any;
  CLLDashboardForm!: FormGroup;
  TotalDefects!: any;
  TotalDefecteSolved!: any;
  CILAdherence!: any;
  TotalDefectsPerc!: any;
  CILTask!: any;
  key: any;
  responseLineData!: any;
  responseUnitData!: any;
  linepercentageGraphData: any = [];
  unitpercentageGraphData: any = [];
  dashboardData: any;

  chartCILTask!: Chart;
  chartCILType!: Chart;
  chartCILTool!: Chart;
  chartLinePercentage!: Chart;
  options!: any;
  image!: any;
  currentImagePath!: any;
  defaultExcelExportParams: any;
  frameworkComponents: any;

  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;

  gridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true, // <-- HERE
      autoHeight: true, // <-- & HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      customLoadingOverlay: CustomLoadingOverlay,
    },
    // floatingFilter: true,
  };
  // iconUrl
  menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';
  dashboarUnitData: any;
  dashboardLineData: any;
  deparmentList: any;
  deparmentFiltList: any;
  chartUnitPercentage: Chart;
  completeUnitList : any ;
  completeDepartmentList: any;
  completeLineList: any ;
  completeCompentList: any;
  overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
    loadingOverlayComponent: any;
  imageUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private productivityservice: ProductivityService,
    private cilService: CILDashboardService,
    private toaster: ToastrService,
    public router: Router,
    private userService: UserManagementService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    // private dtService: DtServiceService
  ) {
  }

  ngOnInit(): void {
    this.imageUrl = environment.O3CoreApiImageUrl;
    this.loadingOverlayComponent = 'customLoadingOverlay';
    this.frameworkComponents = {
      buttonRenderer: ButtonViewRendererComponent,
    };
    this.columnDefs = [
      { headerName: 'Line', field: 'Pl_Desc', filter: 'agTextColumnFilter' },
      {
        headerName: 'Task Name',
        field: 'taskName',
        filter: 'agTextColumnFilter',
      },
      { headerName: 'Machine', field: 'Machine', filter: 'agTextColumnFilter' },
      {
        headerName: 'Component',
        field: 'Component',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Task Status',
        field: 'taskStatus',
        filter: 'agTextColumnFilter',
      },
      { headerName: 'Type', field: 'Type', filter: 'agTextColumnFilter' },
      {
        headerName: 'Start Time',
        field: 'StartTime',
        filter: 'agDateColumnFilter',
        valueFormatter: function (params: any) {
          return params.value !== null
            ? moment(params.value).format('MM/DD/YYYY HH:mm:ss')
            : '';
        },
      },
      {
        headerName: 'End Time',
        field: 'EndTime',
        filter: 'agDateColumnFilter',
        valueFormatter: function (params: any) {
          return params.value !== null
            ? moment(params.value).format('MM/DD/YYYY HH:mm:ss')
            : '';
        },
      },
      {
        headerName: 'Estimated Time',
        field: 'EstimatedTime',
        filter: 'agTextColumnFilter',
      },
      { headerName: 'Remarks', field: 'Remarks', filter: 'agTextColumnFilter' },
      {
        headerName: 'Frequency',
        field: 'Frequency',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'User Name',
        field: 'UserName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'PreImage',
        field: 'PreImageFilePath',
        cellRenderer: (params: any) => {
          return `<img style="width:40px;height:40px ;cursor:pointer" alt="${
            params.value
          }" src="${
            params.value !== ''
              ? this.imageUrl + params.value
              : 'assets/images/no-image.png'
          }">`;
        },
      },
      {
        headerName: 'PostImage',
        field: 'PostimageFilePath',
        cellRenderer: (params: any) => {
          return `<img style="width:40px;height:40px ;cursor:pointer" alt="${
            params.value
          }" src="${
            params.value !== ''
              ? this.imageUrl + params.value
              : 'assets/images/no-image.png'
          }">`;
        },
      },
    ];
    this.defaultExcelExportParams = {
      addImageToCell: function (
        rowIndex: number,
        col: any,
        value: any,
        callback: any
      ) {
        if (col.colId === 'PreImage' || col.colId === 'PostImage') {
        } else {
          return;
        }
      },
    };
    this.initform();
    this.CLLDashboardForm.controls.unit.patchValue('0');
    this.CLLDashboardForm.controls.components.patchValue('0');
    this.CLLDashboardForm.controls.line.patchValue('0');
    this.CLLDashboardForm.controls.Departments.patchValue('0');

    this.datasource();
    this.GetBreadcrumList();
  }

  setData1() {
    if(this.dashboardData !== null){
      this.TotalDefects = this.dashboardData.totalCount;
      this.TotalDefecteSolved = this.dashboardData.doneCount;
      this.TotalDefectsPerc = this.dashboardData.NotExecutedCount;
      if((parseInt(this.dashboardData.doneCount)/parseInt(this.dashboardData.totalCount))){
        this.CILAdherence = ((parseInt(this.dashboardData.doneCount)/parseInt(this.dashboardData.totalCount))*100).toFixed(2) + ' %'
      }
      else{
        this.CILAdherence = 0 + '%'
      }

      this.CILTask = this.dashboardData.NotDoneCount;
      // this.drawCILTaskChart();
      // this.drawCilTypeChart();
      // this.drawCilToolChart();
      this.rowData = this.dashboardData.dtData;
    }
  }

  initform() {
    const dateStart = new Date(moment().subtract(1, 'd').toString());
    //dateStart.setDate(dateStart.getDate() - 183);
    // dateStart.setHours(6);
    // dateStart.setMinutes(0);
    // dateStart.setSeconds(0);

    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate());
    this.CLLDashboardForm = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      Departments: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      components: ['', Validators.required],
      datePicker : [[dateStart , dateEnd]] ,
      // dateStart: [new Date(), Validators.required],
      // dateEnd: [new Date(), Validators.required],
      
      shift: ['', []],
    });
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId =
          this.breadcrumList && this.breadcrumList.length
            ? this.breadcrumList[0].id
            : '';
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  onChangeLine(data: any): void {
    if (parseInt(data.value) === 0) {
      this.filteredLines?.forEach(elem2 => {
        this.completeUnitList.filter(elem => elem.PL_Id === elem2.PL_Id)?.forEach(element => {
          this.unitList.push(element)
        });
      });
      this.filteredUnits = this.unitList.slice();
      // this.filteredUnits?.length = 0;
      // this.filteredComponents?.length = 0;
      this.CLLDashboardForm?.controls?.unit.patchValue('0');
      this.CLLDashboardForm.controls.components.patchValue('0');
      // this.getUnit(parseInt(data.value));
     

    } else {
      this.unitList =this.completeUnitList.filter(elem => elem.PL_Id === data.value);
      this.filteredUnits = this.unitList.slice();
      this.CLLDashboardForm?.controls?.unit.patchValue('0');
      this.CLLDashboardForm.controls.components.patchValue('0');
      // this.getUnit(data.value);

    }
  }
  onChangePlant(data: any) {
    this.CLLDashboardForm?.controls?.line.patchValue('0');
    this.CLLDashboardForm?.controls?.unit.patchValue('0');
    // this.getDepartments(data.value);
    this.getAllListCilDasboard();
  }

  onChangeDepartments(data: any) {
    // this.getLine(data.value);
    if(data.value != 0)
    {
      this.lineList = this.completeLineList.filter(elem => elem.TypeId === data.value);
      this.filteredLines = this.lineList.slice();
      this.unitList = [];
      this.componentList = [];
      this.filteredUnits = []
      this.lineList?.forEach(elem2 => {
      this.completeUnitList.filter(elem => elem.PL_Id === elem2.PL_Id)?.forEach(element => {
        this.unitList.push(element)
      });
      });

      this.filteredUnits = this.unitList.slice();
      this.CLLDashboardForm?.controls?.line.patchValue('0');
      this.CLLDashboardForm?.controls?.unit.patchValue('0');
      this.CLLDashboardForm.controls.components.patchValue('0');
      // this.unitList?.forEach(elem2 => {
      //   this.componentList =this.completeCompentList.filter(elem => elem.PU_Id = elem2.PU_Id);
      // })
      // console.log(this.componentList , 'c')
    }
    if(data.value == 0)
    {
      this.lineList = this.completeLineList;
      this.filteredLines = this.lineList.slice();
      this.unitList = this.completeUnitList;
      this.filteredUnits = this.unitList.slice();
      this.componentList = this.completeCompentList;
      this.filteredComponents = this.completeCompentList;
      this.CLLDashboardForm?.controls?.line.patchValue('0');
      this.CLLDashboardForm?.controls?.unit.patchValue('0');
      this.CLLDashboardForm.controls.components.patchValue('0');

    }
  }

  onChangeUnit(data: any): void {
    this.componentList =this.completeCompentList.filter(elem => elem.PU_Id = data.value);
    const comDefault = this.componentList[0];
    this.componentList.length !== 0
      ? this.CLLDashboardForm.controls.components.patchValue(
          comDefault.ComponentId
        )
      : this.CLLDashboardForm.controls.components.patchValue('0');
    this.filteredComponents = this.componentList.slice();
    // this.getComponent(data.value);
  }
  datasource(): void {
    const key = {
      UserId: JSON.parse(localStorage.user).UserId,
    };
    const id = {
      value: 0,
    };
    this.productivityservice.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.lstEG;
        id.value = data.lstEG[0].EG_Id;
        this.CLLDashboardForm.get('UserGroup1')?.setValue(data.lstEG[0].EG_Id);
        this.CLLDashboardForm.get('UserPlant1')?.setValue(data.lstEG[0].EG_Id);
        this.filteredGroups = this.GroupList.slice();
        this.getPlants(id);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: JSON.parse(localStorage.user).UserId,
    };

    this.productivityservice.getPlantsData(key).subscribe(
      (data: any) => {
        this.PlantList1 = data.lstPlant;
        const id = data.lstPlant[0].PT_Id;
        this.CLLDashboardForm.get('UserPlant1')?.patchValue(id);
        this.filteredPlants = this.PlantList1.slice();
        this.getAllListCilDasboard();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onSubmit(): void {
    this.spinner.show();
    this.makeData();
    this.getAMCILDashboard();
    // this.getCILDashboard();
  }

  getAMCILDashboard() {
    this.spinner.show();
    this.dashboarUnitData = [];
    this.dashboardLineData = [];
    this.dashboardData = [];
    this.linepercentageGraphData = [];
    this.unitpercentageGraphData = [];
    this.key = {
      groupId: this.CLLDashboardForm.value.UserGroup1,
      PTID: this.CLLDashboardForm.value.UserPlant1,  
      DeptId: this.CLLDashboardForm.value.Departments,
      PUID: this.CLLDashboardForm.value.unit,
      PLID: this.CLLDashboardForm.value.line,
      dateStart: this.CLLDashboardForm.get('datePicker').value[0],
      dateEnd: this.CLLDashboardForm.get('datePicker').value[1],
    };
    this.cilService.getNewCILDashboard(this.key).subscribe(
      (data: any) => {
        this.responseLineData = data.CILDashLineList;
        this.responseUnitData = data.CILDashUnitList;
        this.dashboardData = data;
        //this.dashboardData = this.dashboardData;
        this.spinner.hide();
        this.setData();
        this.getCILDashboard()
      },
      (error: any) => {
        this.toaster.error('error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
    
  }
  getCILDashboard() {
    // this.spinner.show()
    this.gridApi.showLoadingOverlay();
    this.key = {
      groupId:this.CLLDashboardForm.value.UserGroup1,
      PT_ID: this.CLLDashboardForm.value.UserPlant1,
      UnitId: this.CLLDashboardForm.value.unit,
      ComponentId: this.CLLDashboardForm.value.components,
      dtStartDate:  this.CLLDashboardForm.get('datePicker').value[0],
      dtEndDate: this.CLLDashboardForm.get('datePicker').value[0],
      Status: '*',
      userId: JSON.parse(localStorage.getItem('user')).UserId,
    };
    
    
    this.cilService.getCILDashboard(this.key).subscribe((data: any) => {
      this.dashboardData = data;
      // this.spinner.hide();
      this.gridOptions.api.hideOverlay();
      this.setData1();
    }, (error: any) => {
      this.toaster.error('error', error.message);
      this.spinner.hide();
    });
  }

  setData() {
    if (this.dashboardData !== null) {
      const LineData = [];
      let data = [];
      let machineName = '';
      if (this.dashboardData.CILDashDeptList && this.dashboardData.CILDashDeptList.length) {
        this.dashboardData.CILDashDeptList.map(
          (plant: any, plantindex: number) => {
            data = [];
            const ID = this.dashboardData.CILDashDeptList[plantindex].Id;
            const done = this.dashboardData.CILDashDeptList[plantindex].Done;
            const totalCount =
              this.dashboardData.CILDashDeptList[plantindex].TotalCount;

            machineName = this.dashboardData.CILDashDeptList[plantindex].Name;
            const percentage = (
              (parseInt(done) / parseInt(totalCount)) *
              100
            ).toFixed(2);
            data.push(totalCount);
            data.push(done);
            this.linepercentageGraphData.push({
              name: machineName,
              type: 'column',

              data: [parseInt(percentage)],
            });
            LineData.push({
              name: machineName,
              type: 'column',
              data: data,
            });
          }
        );
      }

      this.dashboardLineData = LineData;

      if (this.dashboardData.CILDashUnitList && this.dashboardData.CILDashUnitList.length) {
        const unitdata = [];
        let dataUnit = [];
        let machineNameUnit = '';
        this.dashboardData.CILDashUnitList.map(
          (plant: any, plantindex: number) => {
            dataUnit = [];
            const ID = this.dashboardData.CILDashUnitList[plantindex].Id;

            machineNameUnit =
              this.dashboardData.CILDashUnitList[plantindex].Name;
            const done = this.dashboardData.CILDashUnitList[plantindex].Done;
            const totalCount =
              this.dashboardData.CILDashUnitList[plantindex].TotalCount;
            const percentage = (
              (parseInt(done) / parseInt(totalCount)) *
              100
            ).toFixed(2);
            dataUnit.push(totalCount);
            dataUnit.push(done);
            this.unitpercentageGraphData.push({
              name: machineNameUnit,
              type: 'column',
              data: [parseInt(percentage)],
            });

            unitdata.push({
              name: machineNameUnit,
              type: 'column',
              data: dataUnit,
            });
          }
        );
        this.dashboarUnitData = unitdata;
      }

      if (
        this.unitpercentageGraphData.length > 10 &&
        this.options &&
        this.options.plotOptions.column.pointWidth
      ) {
        this.options.plotOptions.column.pointWidth = 10;
        this.options.plotOptions.column.pointPadding = 0.2;
        const width = document.querySelectorAll('div.chart-items-w30-u');
        if (width && width.length) {
          width.forEach((node: any) => (node.style.width = '99%'));
        }

        const widthTwo = document.querySelectorAll('div.chart-items-u');
        if (widthTwo && widthTwo.length) {
          widthTwo.forEach((node: any) => (node.style.width = '99%'));
        }
      } else {
        const width = document.querySelectorAll('div.chart-items-w30-u');
        if (width && width.length) {
          width.forEach((node: any) => (node.style.width = '49%'));
        }

        const widthTwo = document.querySelectorAll('div.chart-items-u');
        if (widthTwo && widthTwo.length) {
          widthTwo.forEach((node: any) => (node.style.width = '44%'));
        }
      }

      this.drawCILUnitChart();
      this.drawCilLineChart();
    }
  }

  makeData() {
    const startDt =  moment(this.CLLDashboardForm?.get('datePicker')?.value[0]).utc();
    const endDt =  moment(this.CLLDashboardForm?.get('datePicker')?.value[1]).utc();
    this.key = {
      groupId: this.CLLDashboardForm.value.UserGroup1,
      PTID: this.CLLDashboardForm.value.UserPlant1,
      DeptId: this.CLLDashboardForm.value.Departments,
      PUID: this.CLLDashboardForm.value.unit,
      PLID: this.CLLDashboardForm.value.line,
      dateStart: startDt,
      dateEnd: endDt,
      userId: JSON.parse(localStorage.getItem('user')).UserId,
      
    };
  }

  drawCILUnitChart() {
    this.options = {
      chart: {
        type: 'column',
      },

      title: {
        text: 'CIL Task Plan - Unit Wise',
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: ['Task Found', 'Task Fixed', 'Adherence %'],
        labels: {
          style: {
            color: 'black',
            fontSize: '15px',
          },
        },
      },

      yAxis: {
        allowDecimals: false,
        min: 0,
      },
      plotOptions: {
        column: {
          // pointPadding: 4,
          // groupPadding: 12,
          pointWidth: 50,
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
          },
        },
      },

      series: this.dashboarUnitData,
    };

    let chart = new Chart(this.options);
    this.chartCILTask = chart;
    if (
      this.dashboarUnitData.length > 10 &&
      this.options &&
      this.options.plotOptions.column.pointWidth
    ) {
      this.options.plotOptions.column.pointWidth = 10;
      this.options.plotOptions.column.pointPadding = 0.1;
    }
    this.drawCILUnitPercentage();
  }
  drawCILLinePercentage() {
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'CIL Adherence - Department Wise %',
      },

      xAxis: {
        categories: ['Adherence in %'],
        title: {
          text: null,
        },
      },
      credits: {
        enabled: false
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
        labels: {
          overflow: 'justify',
        },
      },

      plotOptions: {
        column: {
          pointWidth: 50,
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            format: '{y} %',
          },
        },
      },

      series: this.linepercentageGraphData,
    };

    if (
      this.linepercentageGraphData.length > 10 &&
      this.options &&
      this.options.plotOptions.column.pointWidth
    ) {
      this.options.plotOptions.column.pointWidth = 10;
      this.options.plotOptions.column.pointPadding = 0.2;

      const width = document.querySelectorAll('div.chart-items-w30-l');
      if (width && width.length) {
        width.forEach((node: any) => (node.style.width = '100%'));
      }

      const widthTwo = document.querySelectorAll('div.chart-items-l');
      if (widthTwo && widthTwo.length) {
        widthTwo.forEach((node: any) => (node.style.width = '100%'));
      }
    } else {
      const width = document.querySelectorAll('div.chart-items-w30-l');
      if (width && width.length) {
        width.forEach((node: any) => (node.style.width = '47%'));
      }

      const widthTwo = document.querySelectorAll('div.chart-items-l');
      if (widthTwo && widthTwo.length) {
        widthTwo.forEach((node: any) => (node.style.width = '48%'));
      }
    }

    let chart = new Chart(this.options);
    this.chartLinePercentage = chart;
  }
  drawCILUnitPercentage() {
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'CIL Adherence - Unit Wise %',
      },

      xAxis: {
        categories: ['Adherence in %'],
        title: {
          text: null,
        },
      },
      credits: {
        enabled: false
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
        labels: {
          overflow: 'justify',
        },
      },

      plotOptions: {
        column: {
          pointWidth: 50,
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            format: '{y} %',
          },
        },
      },

      series: this.unitpercentageGraphData,
    };

    if (
      this.unitpercentageGraphData.length > 10 &&
      this.options &&
      this.options.plotOptions.column.pointWidth
    ) {
      this.options.plotOptions.column.pointWidth = 10;
      this.options.plotOptions.column.pointPadding = 0.2;

      const width = document.querySelectorAll('div.chart-items-w30-u');
      if (width && width.length) {
        width.forEach((node: any) => (node.style.width = '100%'));
      }

      const widthTwo = document.querySelectorAll('div.chart-items-u');
      if (widthTwo && widthTwo.length) {
        widthTwo.forEach((node: any) => (node.style.width = '100%'));
      }
    } else {
      const width = document.querySelectorAll('div.chart-items-w30-u');
      if (width && width.length) {
        width.forEach((node: any) => (node.style.width = '49%'));
      }

      const widthTwo = document.querySelectorAll('div.chart-items-u');
      if (widthTwo && widthTwo.length) {
        widthTwo.forEach((node: any) => (node.style.width = '44%'));
      }
    }
    let chart = new Chart(this.options);
    this.chartUnitPercentage = chart;
  }
  drawCilLineChart() {
    this.options = {
      chart: {
        type: 'column',
      },

      title: {
        text: 'CIL Task Plan - Department Wise',
      },

      xAxis: {
        categories: ['Task Found', 'Task Fixed', 'Adherence %'],
        labels: {
          style: {
            color: 'black',
            fontSize: '15px',
          },
        },
      },
      credits: {
        enabled: false
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
      },
      plotOptions: {
        column: {
          // pointPadding: 4,
          // groupPadding: 12,
          pointWidth: 50,
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
          },
        },
      },

      series: this.dashboardLineData,
    };
    let chart = new Chart(this.options);
    this.chartCILType = chart;
    this.drawCILLinePercentage();
  }

  /// AG Grid
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.getCILDashboard();
  }

  excelExport() {
    this.gridApi.exportDataAsExcel();
  }
  csvExport() {
    this.gridApi.exportDataAsCsv();
  }

  cellClicked(params: any): void {
    const col = params.colDef.field;
    if (col === 'Postimage') {
      this.image = this.imageUrl + params.data.PostimageFilePath;
      this.openPictureBeforeModal();
    }
    if (col === 'PreTaskImage') {
      this.image = this.imageUrl + params.data.PreImageFilePath;
      this.openPictureBeforeModal();
    }
  }

  openPictureBeforeModal(): void {
    this.currentImagePath =
      this.image === ''
        ? 'assets/images/no-image.png'
        : this.image;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  imageZoomModal(imagePath: String): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  getAllListCilDasboard()
  {
    const key ={
      plantId : this.CLLDashboardForm.value.UserPlant1
    }
    this.cilService.getAllListCilDasboard(key).subscribe((res)=> {
            if(res)
            {
              this.CLLDashboardForm?.controls?.line.patchValue('0');
              this.CLLDashboardForm?.controls?.unit.patchValue('0');
              this.CLLDashboardForm.controls.components.patchValue('0');
              this.getUnitFromList(res);
              this.getLineFromList(res);
              this.getDepartmentFromList(res);
              this.onSubmit()
            }

    } ,
    (error) => {
      this.toaster.error('error' , error.error.Message)
    });
  }
  getUnitFromList(res: any): void {
    let array  = [];
    const arrayId = [...new Set(res?.map((elem) => {
      return elem.PU_ID     
    }))]
    arrayId?.forEach((elem)=> {
      const obj = res?.find((elem2:any) =>  {
        if(elem2.PU_ID === elem)
        {
          return {
                PU_ID: elem2.PU_ID ,
                PU_Desc: elem2.PU_Desc
              }
        }
      })
      array.push(obj)
    })
    const UnitArray = array.map((elem) => {
      return {
            PU_Id: elem.PU_ID ,
            PU_Desc: elem.PU_Desc ,
            PL_Id: elem.PL_Id ,
          }
    })
    this.unitList =UnitArray;
    this.completeUnitList = UnitArray
    this.filteredUnits = this.unitList.slice();
  }

  getLineFromList(res: any): void {
    let array  = [];
    const arrayId = [...new Set(res?.map((elem) => {
      return elem.PL_Id     
    }))]
    arrayId?.forEach((elem)=> {
      const obj = res?.find((elem2:any) =>  {
        if(elem2.PL_Id === elem)
        {
          return {
                PL_Id: elem2.PL_Id ,
                PL_Desc: elem2.PL_Desc ,
              }
        }
      })
      array.push(obj)
    })
    const LineArray = array.map((elem) => {
      return {
            PL_Id: elem.PL_Id ,
            PL_Desc: elem.PL_Desc ,
            TypeId: elem.Dept_Id ,
          }
    })
    this.lineList = LineArray;
    this.completeLineList = this.lineList;
    this.filteredLines = this.lineList.slice();
  }

  getDepartmentFromList(res): void {
    let array  = [];
    const arrayId = [...new Set(res?.map((elem) => {
      return elem.Dept_Id     
    }))]
    arrayId?.forEach((elem)=> {
      const obj = res?.find((elem2:any) =>  {
        if(elem2.Dept_Id === elem)
        {
          return {
                TypeId: elem2.Dept_Id ,
                TypeName: elem2.Dept_Desc
              }
        }
      })
      array.push(obj)
    })
    const DepaertmentArray = array.map((elem) => {
      return {
           TypeId: elem.Dept_Id ,
           TypeName: elem.Dept_Desc
          }
    })
    this.deparmentList = DepaertmentArray;
    this.completeDepartmentList = this.deparmentList;
    this.deparmentFiltList = this.deparmentList.slice();
  }

  getComponentFromList(res: any): void {
    let array  = [];
    const arrayId = [...new Set(res?.map((elem) => {
      return elem.ComponentID     
    }))]
    arrayId?.forEach((elem)=> {
      const obj = res?.find((elem2:any) =>  {
        if(elem2.ComponentID === elem)
        {
          return {
                ComponentID: elem2.ComponentID ,
                ComponentName: elem2.ComponentName
              }
        }
      })
      array.push(obj)
    })
    const ComponentArray = array.map((elem) => {
      return {
            ComponentID: elem.ComponentID ,
            ComponentName: elem.ComponentName ,
            PU_Id: elem.PU_ID
          }
    })
    this.componentList =ComponentArray;
    this.completeCompentList = this.componentList
    const comDefault = this.componentList[0];
    this.componentList.length !== 0
      ? this.CLLDashboardForm.controls.components.patchValue(
          comDefault.ComponentId
        )
      : this.CLLDashboardForm.controls.components.patchValue('0');
    this.filteredComponents = this.componentList.slice();
  }
}
