import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class CcrHistoryService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) {}

  getAllccr(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'ccr', data);
  }
  getCCR(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + 'allccr');
  }

  addccr(ccr: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'addccr', ccr);
  }
  updateCcr(ccrupdate: any): any {
    return this.http.put(this.baseUrl + 'updateCcr', ccrupdate);
  }

  deleteccr(ccrId: any): any {
    return this.http.post(this.baseUrl + 'deleteccr', ccrId);
  }

  getProductsByUserId(data: any): any {
    return this.http.post(this.baseUrl + 'customerproducts', data);
  }
  getClassification(): any {
    return this.http.get(this.baseUrl + 'ccrclassification');
  }

  onSearch(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'ccrsearch', data);
  }
  getCustomers(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'allcustomers', data);
  }
  onSearchByCustomer(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'customerccr', data);
  }

  updateCCRfromCCRManagement(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.baseUrl + 'ccr', data);
  }

  getTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + 'ccrtypes');
  }
  getCourier(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + 'ccrsamples');
  }
  getStatus(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'ccrstatus', data);
  }
  // *** Core Api Method *** //
  getStatus1(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CCR/AddCCRStatus', data);
  }
  ccrcapaaction(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'ccrcapaaction', data);
  }

  ccrAction(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'ccraction', data);
  }
  allccrAction(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'allccractions', data);
  }
  deleteccraction(data: any): any {
    return this.http.post(this.baseUrl + 'deleteccraction', data);
  }

  updateCcraction(data: any): any {
    return this.http.put(this.baseUrl + 'ccraction', data);
  }
  tools(): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'tools', {});
  }
  mastertools(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Tools/GetMasterTools', data);
  }
  addNotes(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'addnotes', data);
  }
  updateClassification(data: any): any {
    return this.http.post<any[]>(this.baseUrl + 'updateClassifcation', data);
  }
  addccrmachine(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'addccrmachine', data);
  }
}
