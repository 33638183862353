<div class="production-db-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a
            class="breadcrumb-link"
            [routerLink]="breadcrumItem.Url"
            [id]="breadcrumItem.id"
            (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
            >{{ breadcrumItem.text }}</a
          >
        </li>
      </ul>
    </ul>

    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      template=" <img src='assets/images/spinner.gif' />"
    ></ngx-spinner>
  </div>

  <div>
    <h1>S & Gemba DashBoard</h1>
  </div>
  <form [formGroup]="FiveSDashboardForm" (ngSubmit)="onSubmit()">
    <div class="headerForm d-flex">
      <mat-form-field appearence="fill">
        <mat-label>SelectGroup</mat-label>
        <mat-select formControlName="group" (selectionChange)="getPlants()">
          <mat-select-filter
            [placeholder]="'Search Group'"
            [displayMember]="'TypeName'"
            [array]="groupList"
            (filteredReturn)="groupFiltList = $event"
          >
          </mat-select-filter>
          <mat-option
            *ngFor="let group of groupFiltList"
            [value]="group.TypeId"
          >
            {{ group.TypeName }}
          </mat-option>
        </mat-select>
        <mat-error>Group is required</mat-error>
      </mat-form-field>
      <mat-form-field appearence="fill">
        <mat-label>SelectPlant </mat-label>
        <mat-select formControlName="plant">
          <mat-select-filter
            [placeholder]="'Search Plant'"
            [displayMember]="'DeptDesc'"
            [array]="plantList"
            (filteredReturn)="plantFiltList = $event"
          >
          </mat-select-filter>
          <mat-option
            *ngFor="let plant of plantFiltList"
            [value]="plant.DeptId"
          >
            {{ plant.DeptDesc }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="inputField">
        <nz-range-picker
          class="calender-input"
          [nzInputReadOnly]="true"
          [nzShowTime]="{ nzFormat: 'HH:mm' }"
          nzFormat="yyyy-MM-dd HH:mm"
          [nzDisabledDate]="disabledDate"
          formControlName="datepicker"
          (ngModelChange)="onChange($event)"
        ></nz-range-picker>
        <span class="floating-text">Date Range</span>
      </div>

      <button type="submit" class="search" tooltipPosition="top">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3609 18.2168L14.6008 13.2662C15.8247 11.8113 16.4953 9.98069 16.4953 8.07499C16.4953 3.62251 12.8728 0 8.4203 0C3.96782 0 0.345306 3.62251 0.345306 8.07499C0.345306 12.5275 3.96782 16.15 8.4203 16.15C10.0918 16.15 11.6847 15.6458 13.0466 14.6888L17.8428 19.677C18.0432 19.8852 18.3129 20 18.6018 20C18.8753 20 19.1348 19.8957 19.3317 19.7061C19.7502 19.3034 19.7636 18.6357 19.3609 18.2168ZM8.4203 2.10652C11.7114 2.10652 14.3888 4.78391 14.3888 8.07499C14.3888 11.3661 11.7114 14.0435 8.4203 14.0435C5.12921 14.0435 2.45183 11.3661 2.45183 8.07499C2.45183 4.78391 5.12921 2.10652 8.4203 2.10652Z"
            fill="#10123D"
          />
        </svg>
      </button>
      <mat-card class="prev-btn"  (click) = "getFiveSDashboardDataPrev(2)">
        <b>Previous</b>
      </mat-card>
      <mat-card class="current-btn"  (click) = "getFiveSDashboardDataPrev(1)">
        <b>Current</b>
      </mat-card>
     
    </div>
  </form>

  <!-- chart -->
  <div class="divider">
    <div class="overall-gauge">
      <div id="overall-chart-gauge"></div>
    </div>
    <div class="gauge-bar-divider" id="gauge-bar-divider-id">
      <p class="sub-heading">S Area Wise Score</p>
      <div class="gauge-divider" *ngFor="let item of values; let i = index">
        <div [id]="'chart-gauge-' + i" class="chart-gauge"></div>
      </div>
    </div>

    <div class="bar-divider">
      <div [chart]="ORPGraphData"></div>
    </div>
  </div>
</div>
<!-- <button type="button" (click)="onBtnExportCSV()" style="margin-top: 30px;">Download CSV export file</button>
<button type="button" (click)="onBtnExportExcel()" style="margin-top: 30px; margin-left: 10px;">Download Excel export file</button> -->
<section>
  <div class="example-button-row">
    <button type="button" class="btn btn-success" (click)="onBtnExportCSV()" style="margin-top: 30px;"><img
        src="assets/images/csv-file-format.png"></button>
    <button type="button" class="btn btn-success" (click)="onBtnExportExcel()"
      style="margin-top: 30px; margin-left: 10px;"><i class="fa fa-file-excel-o icon-white"
        aria-hidden="true"></i></button>
  </div>
</section>
<div style="margin-top: 10px;height: 200px;">
  <ag-grid-angular style="width: 88%; height:100%;" class="ag-theme-alpine" [columnDefs]="columnDefs"
    [rowData]="dataArray" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"></ag-grid-angular>
</div>


<ng-template #5SPoup>
  <div mat-dialog-title class="mat-title">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="main-title"></h1>
      <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
    <div class="five-s-divider">
      <!-- <div
        *ngFor="let item of fiveSList; let i = index"
        [id]="'five-s-chart-' + i"
        class="chart-gauge-five-s"
      ></div> -->
      <div [chart]="chart" class="five-s"></div>
    </div>
  </div>
</ng-template>
