<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">Customer Management </h1>
    </div>
  </div>

  <form>

    <div class="wrapper-box wrapper-box-shadow grid-container">

      <div>
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
        <form [formGroup]="SearchCustomerForm">
        <div class="content-top-row d-flex">
          <mat-form-field appearence="fill"class="top-row-first-filed-item top-row-filed-rem-item">
            <mat-label class="placeholder-title">Group</mat-label>
            <mat-select formControlName="groupList" panelClass="testClass" name="Group"  (selectionChange)="onChangeSearchDataGroup($event)" >
              <mat-option *ngFor="let Group of GroupList" [value]="Group.TypeId">
                {{Group.TypeName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-filed-rem-item">
            <mat-label class="placeholder-title">Select Plant</mat-label>
            <mat-select panelClass="testClass" name="Plant" formControlName="plant" (selectionChange)="onChangePlant($event)" required>
              <mat-option *ngFor="let plant of plantsDDL" [value]="plant.DeptId">
                {{ plant.DeptDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <button (click)="onSearchClick()" class="icon-btn" pTooltip="Search" tooltipPosition="right" >
            <img src="assets/images/search-icon.svg" alt="" width="20px" height="20px">
          </button> -->

        </div>

      </form>
    </div>




      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
      <div class="content-row-flex-both-end content-top-row-order-processing">
          <button *ngIf="IsWrite" (click)="addNewCustomer()" class="icon-btn" pTooltip="Add New Customer" tooltipPosition="right">
            <img src="assets/images/add.png" alt="" width="20px" height="20px">
          </button>


        <!--Second Row  Search Box -->
        <form class="search-form">

          <input type="text" class="form-control" placeholder="Search" (keyup)="applyFilter($event)"  />
          <button class="search-btn" mat-icon-button aria-label="">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.125 0.25C6.41793 0.25 7.65791 0.763614 8.57215 1.67785C9.48639 2.59209 10 3.83207 10 5.125C10 6.3325 9.5575 7.4425 8.83 8.2975L9.0325 8.5H9.625L13.375 12.25L12.25 13.375L8.5 9.625V9.0325L8.2975 8.83C7.4425 9.5575 6.3325 10 5.125 10C3.83207 10 2.59209 9.48639 1.67785 8.57215C0.763614 7.65791 0.25 6.41793 0.25 5.125C0.25 3.83207 0.763614 2.59209 1.67785 1.67785C2.59209 0.763614 3.83207 0.25 5.125 0.25ZM5.125 1.75C3.25 1.75 1.75 3.25 1.75 5.125C1.75 7 3.25 8.5 5.125 8.5C7 8.5 8.5 7 8.5 5.125C8.5 3.25 7 1.75 5.125 1.75Z"
                fill="black"
              />
            </svg>
          </button>
        </form>
      </div>
      <div style="overflow-x: scroll;">
        <table mat-table [dataSource]="customerListDataSource" class="basic-table plant-model-table"  matSort>
        <!-- Plant Column -->
        <ng-container matColumnDef="plant">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Plant</p>
          </th>
          <td mat-cell *matCellDef="let element" style="padding: 13px 10px;">{{element.ptDesc }}</td>
        </ng-container>

        <!-- Region Column -->
        <ng-container matColumnDef="region">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Region</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.region}}</td>
        </ng-container>

        <!-- Customer Name Column -->
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            mat-sort-header-pointer-middle
            *matHeaderCellDef
            mat-sort-header
            class="header-min-width"
          >
            <p class="header-text">Customer Name</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
        </ng-container>

        <!-- Customer Code Column -->
        <ng-container matColumnDef="code">
          <th
            mat-header-cell
            mat-sort-header-pointer-middle
            *matHeaderCellDef
            mat-sort-header
            class="header-min-width"
          >
            <p class="header-text">Customer Code</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.customerCode}}</td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th
            mat-header-cell
            mat-sort-header-pointer-middle
            *matHeaderCellDef
            mat-sort-header
            class="header-min-width"
          >
            <p class="header-text">Email</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.email}}</td>
        </ng-container>

        <!--Contact No Column -->
        <ng-container matColumnDef="contact">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Contact No</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.contactNo}}</td>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Country</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.countryName}}</td>
        </ng-container>
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">City</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.cityName}}</td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Address</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.address}}</td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Actions</p>
          </th>
          <td mat-cell *matCellDef="let element"style="padding: 13px 0px;">
              <div class="row-action-btns">
                <!-- <button (click)="openUserModal(element)" class="adduser-btn" pTooltip="Add User" tooltipPosition="top">
            <img src="assets/images/add.png" alt="" width="20px" height="20px">
          </button> -->
          <button mat-icon-button *ngIf="IsWrite" class="edit-btn" aria-label="Edit Row"  (click)="openUserModal(element)"
              pTooltip="Add User" tooltipPosition="top" style="background-color: cornflowerblue;">
              <svg width="20" height="20"class="svg-icon" viewBox="0 0 20 20">
                <path fill="white" d="M13.68,9.448h-3.128V6.319c0-0.304-0.248-0.551-0.552-0.551S9.448,6.015,9.448,6.319v3.129H6.319
                  c-0.304,0-0.551,0.247-0.551,0.551s0.247,0.551,0.551,0.551h3.129v3.129c0,0.305,0.248,0.551,0.552,0.551s0.552-0.246,0.552-0.551
                  v-3.129h3.128c0.305,0,0.552-0.247,0.552-0.551S13.984,9.448,13.68,9.448z M10,0.968c-4.987,0-9.031,4.043-9.031,9.031
                  c0,4.989,4.044,9.032,9.031,9.032c4.988,0,9.031-4.043,9.031-9.032C19.031,5.012,14.988,0.968,10,0.968z M10,17.902
                  c-4.364,0-7.902-3.539-7.902-7.903c0-4.365,3.538-7.902,7.902-7.902S17.902,5.635,17.902,10C17.902,14.363,14.364,17.902,10,17.902
                  z"></path>
              </svg>
              </button>
          <button mat-icon-button *ngIf="IsWrite" class="edit-btn" aria-label="Edit Row"  (click)="getCustomerbyId(element.customerId)"
              pTooltip="Edit Customer" tooltipPosition="top">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>

              <button mat-icon-button *ngIf="IsWrite" class="node-control-btn node-delete" aria-label="delete item"
              pTooltip="Delete Customer" tooltipPosition="top"   (click)="deleteCustomer(element.customerId)">
             <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                 d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                 fill="white" />
             </svg>
       </button>
            </div>

          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="customerManagementColumns"></tr>
        <tr mat-row
          *matRowDef="let row; columns: customerManagementColumns"
        ></tr>
        <tr class="mat-row" *matNoDataRow style="text-align: center;">
          <td class="mat-cell" colspan="8">No data matching</td>
        </tr>
      </table>
      
      </div>
      <mat-paginator
      class="basic-paginataor"
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons>
    </mat-paginator>
    </div>
  </form>

  <ng-template #displayRoleModal>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

    <form [formGroup]="CustomerDataForm"  (ngSubmit)="formMode ? addCustomerForm() : updateCustomerForm()">
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2> {{formMode ? 'Add' : 'Update'}} Customer</h2>

          <button
            mat-mini-fab
            mat-button
            mat-dialog-close
            color="warn"
            title="close"
          >
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>

      <mat-dialog-content>
        <mat-form-field  appearance="fill" class="field-fill inputs">
          <mat-label >Group</mat-label>
          <mat-select formControlName="group" (selectionChange)="onChangeGroup($event)" required [disabled]="true">
            <mat-option *ngFor="let group of groups" [value]="group.TypeId" >{{group.TypeName}}</mat-option>
          </mat-select>
          <mat-error >Please select group</mat-error>
        </mat-form-field>
        <mat-form-field  appearance="fill" class="field-fill inputs">
          <mat-label >Select Plant</mat-label>
          <mat-select formControlName="plant" required [disabled]="true">
            <mat-option *ngFor="let plant of plants" [value]="plant.DeptId">
              {{ plant.DeptDesc }}
            </mat-option>
          </mat-select>
          <mat-error >Please select plant</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label>Region</mat-label>
          <input matInput placeholder="Enter Region" formControlName="region"  autocomplete="off" required/>
          <mat-error >Please enter your region</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label >Customer Name</mat-label>
          <input matInput placeholder="Enter Customer Name"formControlName="name" required  autocomplete="off"/>
          <mat-error >Please enter customer name</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label>Customer Code</mat-label>
          <input matInput placeholder="Enter Customer Code" formControlName="code"   autocomplete="off"/>
        </mat-form-field>
        <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label >Email</mat-label>
          <input matInput placeholder="Enter Email" formControlName="email" required  autocomplete="off" />
          <mat-error *ngIf="CustomerDataForm.get('email')?.hasError('required')"> Email is required!</mat-error>
          <mat-error  *ngIf="CustomerDataForm.get('email')?.hasError('email')"> Email is not valid!</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label>Contact Person Name</mat-label>
          <input matInput placeholder="Enter Contact Person Name" formControlName="cperson"  autocomplete="off"/>
        </mat-form-field>
        <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label >Contact No</mat-label>
          <input matInput placeholder="Enter Contact No" formControlName="cno" required autocomplete="off" />
          <mat-error *ngIf="CustomerDataForm.get('cno')?.hasError('pattern')">Enter Valid Contact No!</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label >Select Country</mat-label>
          <mat-select  name="country" formControlName="country" (selectionChange)="getCities()" required>
            <mat-select-filter
                [placeholder]="'Search'"
                [displayMember]="'name'"
                [array]="countries"
                (filteredReturn)="filteredCountries = $event"
              ></mat-select-filter>
            <mat-option *ngFor="let country of filteredCountries" [value]="country.countryId" >{{country.name}}</mat-option> 
          </mat-select>
          <mat-error >Please select country</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label >Select City</mat-label>
          <mat-select  name="city" formControlName="city" required>
            <mat-select-filter
            [placeholder]="'Search'"
            [displayMember]="'name'"
            [array]="cities"
            (filteredReturn)="filteredCities = $event"
          ></mat-select-filter>
            <mat-option *ngFor="let city of filteredCities" [value]="city.cityId">{{city.name}}</mat-option>
          </mat-select>
          <mat-error>Please select city</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label>Address</mat-label>
          <input matInput placeholder="Enter Address" formControlName="address" required/>
          <mat-error >Please enter your address</mat-error>
        </mat-form-field>
        <button type="submit" class="icon-btn"  [class.spinner]="loading" [disabled]="loading" matTooltip="Update" matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 15px;" *ngIf="!formMode"  >
          <img src="assets/images/edit.png" alt="" width="20px" height="20px">
        </button>
        <button type="submit" class="icon-btn" style="margin-left: 15px;" matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"  [class.spinner]="loading" [disabled]="loading" *ngIf="formMode" [disabled]="!CustomerDataForm.valid">
          <img src="assets/images/save.png" alt="" width="20px" height="20px">
        </button>

      </mat-dialog-content>

    </form>
  </ng-template>

<ng-template #displayRoleModal1>

      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2> {{formMode ? 'Add' : 'Update'}} User</h2>

          <button
            mat-mini-fab
            mat-button
            mat-dialog-close
            color="warn"
            title="close"
          >
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>

      <mat-dialog-content>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

        <form [formGroup]="userDataForm" (ngSubmit)="formMode ? addUserForm() : updateUserForm()" >

        <mat-form-field   appearance="fill" class="field-fill inputs">
          <mat-label>User Name</mat-label>
          <input matInput placeholder="Enter User Name" formControlName="name" />
          <mat-error *ngIf="userDataForm.get('name')?.hasError('required')"> User name is required!</mat-error>
        </mat-form-field>

        <mat-form-field  appearance="fill" class="field-fill inputs">
          <mat-label >Email</mat-label>
          <input matInput placeholder="Enter Email" formControlName="email" type="email" required/>
          <mat-error *ngIf="userDataForm.get('email')?.hasError('required')"> Email is required!</mat-error>
          <mat-error  *ngIf="userDataForm.get('email')?.hasError('email')"> Email is not valid!</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label>Password</mat-label>
          <input matInput placeholder="Enter Password" formControlName="password"/>
        </mat-form-field>
          <mat-form-field appearance="fill" class="field-fill inputs">
            <mat-label>Select Products</mat-label>
            <mat-select  multiple formControlName="products">
              <mat-select-filter
                [placeholder]="'Search'"
                [displayMember]="'prod'"
                [array]="products"
                (filteredReturn)="filterProducts = $event"
              ></mat-select-filter>
              <mat-option *ngFor="let product of filterProducts" [value] = "product.prodId">{{product.prod}}</mat-option>
            </mat-select> 
          </mat-form-field>
  
        <button  type="submit" class="icon-btn" matTooltip="Update" matTooltipClass="custom-tooltip" matTooltipPosition="above"  [class.spinner]="loading" [disabled]="loading" style="margin-left: 15px;" *ngIf="!formMode && IsWrite" >
          <img src="assets/images/edit.png" alt="" width="20px" height="20px">
        </button>
        <button  type="submit" class="icon-btn" matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 15px;"  [class.spinner]="loading" [disabled]="loading" *ngIf="formMode && IsWrite" [disabled]="!userDataForm.valid">
          <img src="assets/images/save.png" alt="" width="20px" height="20px">
        </button>
        <button  type="button" (click)="resetUserFields()" class="icon-btn" matTooltip="Reset" matTooltipClass="custom-tooltip" matTooltipPosition="above">
          <img src="assets/images/refresh1.png" alt="" width="20px" height="20px">
        </button>
      </form>

     

       
     
      </mat-dialog-content>

<table mat-table   [dataSource]="userListDataSource" class="basic-table plant-model-table"  matSort>
        <!-- UserName Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">User Name</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.userName}}</td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            <p class="header-text">Email</p>
          </th>
          <td mat-cell style="padding: 13px 10px;" *matCellDef="let element" >{{element.email}}</td>
        </ng-container>



        <!-- Products Column -->
        <ng-container matColumnDef="products">
          <th
            mat-header-cell
            mat-sort-header-pointer-middle
            *matHeaderCellDef
            mat-sort-header
            class="header-min-width"
          >
            <p class="header-text">Products</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.prodDesc}}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Actions</p>
          </th>
          <td mat-cell *matCellDef="let element">
              <div class="row-action-btns">

          <button mat-icon-button *ngIf="IsWrite" class="edit-btn" aria-label="Edit Row"  (click)="getUserbyId(element)"
          matTooltip="Edit" matTooltipClass="custom-tooltip" matTooltipPosition="above">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>

              <button mat-icon-button   *ngIf="IsWrite" class="node-control-btn node-delete" aria-label="delete item"
              matTooltip="Delete" matTooltipClass="custom-tooltip" matTooltipPosition="above"  (click)="deleteUser(element.customerUserId)">
             <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                 d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                 fill="white" />
             </svg>
       </button>
            </div>



          </td>
        </ng-container>

        <!-- <div style="text-align: center;" *ngIf="userListDataSource.filteredData.length === 0">No records found</div> -->
        <tr mat-header-row *matHeaderRowDef="adduserColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: adduserColumns"
        ></tr>
        <tr class="mat-row" *matNoDataRow style="text-align: center;">
          <td class="mat-cell" colspan="8">No data matching</td>
        </tr>
      </table>
      <mat-paginator
        class="basic-paginataor"
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons>
      </mat-paginator>



  </ng-template>

</div>
