import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
baseUrl: any = environment.O3CoreApiUrl;

constructor(private http: HttpClient) { }

GetGroups(data: any): Observable<any[]> {
 return this.http.post<any[]>(this.baseUrl + 'Common/GetGroup', data);
}

GetPlants(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Common/GetDepartments', data);
}

GetItemInfo(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Quality/GetItemInfo', data);
}

GetProcessOrder(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Quality/GetProcessOrder', data);
}

GetWHPalletInfoList(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Quality/GetWHPalletInfoList', data);
}

GetPalletInfo(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Pallet/GetPalletInfo', data);
}

GetWHPalletInfoHistoryList(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Quality/GetWHPalletInfoHistoryList', data);
}

SubmitWHPallet(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Quality/SubmitWHPallet', data);
}

}
