<section class="home-content" dir="{{languageDir}}">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <mat-card>
    <form [formGroup]="KpiversionForm">
      <div style="padding:10px;background-color: #0F0FD1;">
        <div class="p-grid p-fluid">
          <div class="p-col-6">
            <mat-card-title style="color: white;">KPI Change Request</mat-card-title>
          </div>
 
          <div class="p-col-3">
            <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                          'is-invalid':
                          KpiversionForm.get('group')?.errors &&
                          KpiversionForm.get('group')?.touched
                        }" [options]="groupList" formControlName="group" placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}"
              optionLabel="TypeName" [showClear]="true"></p-dropdown>
            <p class="invalid-feedback" *ngIf="
                        KpiversionForm.get('group')?.touched &&
                        KpiversionForm.get('group')?.hasError('required')
                        ">
              * {{langObj ? langObj?.pleaseselectgroup : ('pleaseselectgroup' | translate)}}
            </p>
          </div>
          <div class="p-col-3">
            <p-dropdown  [filter]="true" [ngClass]="{'is-invalid': KpiversionForm.get('plant')?.errors && KpiversionForm.get('plant')?.touched}" [options]="searchPlants" formControlName="plant" placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}"
              optionLabel="DeptDesc" [showClear]="true" (onChange)="changePlant()" ></p-dropdown>
            <p class="invalid-feedback" *ngIf="KpiversionForm.get('plant')?.touched && KpiversionForm.get('plant')?.hasError('required')">
              * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
            </p>
          </div>
        </div>
      </div>
      <div class="content-top-row">
        <!-- <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label class="required">{{langObj ? langObj?.CRNo : ('CRNo' | translate)}}</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.CRNo : ('CRNo' | translate)}}" formControlName="crNo">
        </mat-form-field> -->
        <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label class="required">Description</mat-label>
          <input matInput placeholder="Description" formControlName="description">
        </mat-form-field>
       
        <mat-form-field style="margin-left:5px" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Select CR</mat-label>
          <mat-select  class="required" formControlName="type" (selectionChange)=" ChangeCRType()" >
            <mat-option *ngFor="let type of CRDropDownArray" [value]="type.Id">
              {{ type?.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field>        
            <mat-form-field style="margin-left:5px" *ngIf="ShowApplicableDate" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>{{langObj ? langObj?.ApplicableDate : ('ApplicableDate' | translate)}}</mat-label>
              <input matInput [matDatepicker]="picker"   readonly placeholder="MM/DD/YYYY" formControlName="applicable"
            >
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        <div class="btns-row" style="padding: 14px;">

          <button mat-mini-fab mat-button color="accent" (click)="ResetChangeRequest()"  style="margin-left: 20px;"
            pTooltip="Reset" tooltipPosition="top" *ngIf="IsWrite">
            <mat-icon>refresh</mat-icon>
          </button>
          <button mat-mini-fab mat-button color="primary" (click)="AddChangeRequest()"  style="margin-left:10px;"
            pTooltip="Save" tooltipPosition="top" [disabled]="!KpiversionForm.valid || isClicked" *ngIf="IsWrite && !IsEdit" >
            <mat-icon>save</mat-icon>
          </button>
          <button mat-mini-fab mat-button color="primary" (click)="UpdateChangeRequest()"  style="margin-left:10px;"
            pTooltip="Edit" tooltipPosition="top"  [disabled]="!KpiversionForm.valid" *ngIf="IsWrite && IsEdit">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </form>
        <ag-grid-angular #agGrid id="myGrid" style="width: 100%; height: 35vw; margin-top: 30px;" class="ag-theme-alpine"
          [columnDefs]="columnDefs" [rowData]="rowData" (gridReady)="onGridReady($event)" 
          [pagination]="true" [gridOptions]="gridOptions" (cellClicked)='cellClicked($event)' 
          [paginationPageSize]="paginationPageSize"></ag-grid-angular>


      <h2 style="margin-top:25px">KPI Version</h2>

        <ag-grid-angular #VersionGrid  style="width: 100%; margin-top: 30px;" class="ag-theme-alpine"
          [columnDefs]="VersioncolumnDefs" [rowData]="VersionData" (gridReady)="VersiononGridReady($event)" 
          [pagination]="true"  (cellClicked)='VersioncellClicked($event)' 
          [paginationPageSize]="10"></ag-grid-angular>

  
  </mat-card>
</section>



<ng-template #StatusModal>
  <div mat-dialog-title style="margin-bottom:0px">
    <div class="d-flex justify-content-between align-items-center">
      <h2 style="margin-bottom:0px"> Change Status </h2>
      <button   mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>


  <div class="content-row-wrap" style="margin-top:0px;">
    <form [formGroup]="ChangeStatusFormGroup">
      <mat-form-field style="height: 45px;    width: 250px;"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing">

        <mat-label class="required">Status</mat-label>
        <mat-select panelClass="testClass" name="plant" formControlName="status">

          <mat-option *ngFor="let value of statuses" [value]="value.value">
            {{value.name}}
          </mat-option>
        </mat-select>

      </mat-form-field>
      <mat-form-field style="height: 45px;    width: 250px;"
      class="top-row-first-filed-item top-row-first-filed-item-order-processing">

      <mat-label class="required">Remarks</mat-label>
      <input matInput placeholder="Remarks" formControlName="remarks">

    </mat-form-field>
    </form>
  </div>





  <button *ngIf=" IsWrite" mat-mini-fab mat-button color="primary" style="margin: 15px;float: right;"
    pTooltip="Save" (click)="CRChangeStatus()" tooltipPosition="top"
    [disabled]="!ChangeStatusFormGroup.valid">
    <mat-icon>save</mat-icon>
  </button>
  
   

</ng-template>
