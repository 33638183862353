import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Responsemodel } from '../models/responsemodel';
import * as _moment from 'moment';
import Swal from 'sweetalert2';
import { CcrHistoryService } from '../services/ccr-history.service';
import { debug } from 'console';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { data, error, event } from 'jquery';
import { differenceInCalendarDays } from 'date-fns';
import { NotificationService } from '../services/notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

const moment = _moment;

interface UserInfo {
  customerUserId: number;
  customerName: string;
  customerId: number;
  region: string;
}

@Component({
  selector: 'app-ccr-history',
  templateUrl: './ccr-history.component.html',
  styleUrls: ['./ccr-history.component.scss'],
})
export class CcrHistoryComponent implements OnInit {
  maxDate = new Date();
  loading = false;
  // TypeControl = new FormControl('', [Validators.required]);
  ccrhistoryDataForm!: FormGroup;
  ccrDataForm!: FormGroup;
  selectedStaus = false;
  ccrData!: MatTableDataSource<any>;
  responseModel!: Responsemodel;
  formMode!: boolean;
  imageSrc!: string;
  products!: any[];
  types!: any[];
  couriers!: any[];
  fileSave!: any;
  classifications!: any;
  classificationId!: any;
  customerId!: number;
  path!: string;
  savedPath!: string;
  user!: any;
  currentImagePath!: any;
  promisedDeliveryDate = true;
  Delay = true;
  productiondate = false;
  testDate = false;
  sampleSentBy = false;
  today = new Date();
  typevalue!: any;
  PDtooltip: any;
  CustomerbyProduct: any;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private ccrServices: CcrHistoryService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) { }
  ccrHistoryColumns: string[] = [
    'ccrActions',
    'ID',
    'type',
    'classification',
    'region',
    'product',
    'batch',
    'Qty',
    'description',
    'production',

    'delivery',
    'pdelivery',
    'run',
    'deliveryNote',
    'otherNotes',
    'imagePath',
    'delay',
    'sample',
    'authStatus',
    'status',
    'remarks',
  ];
  // machineParameterVariableGroupData = new MatTableDataSource<MachineParameterElement>(MachineParameterElementData);
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  // @ViewChild(MatSort) sort!: MatSort;
  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  ngOnInit() {
    const ccrUser: any = localStorage.getItem('ccrUser');
    this.user = JSON.parse(ccrUser);
    this.formMode = true;
    this.ccrhistoryDataForm = this.fb.group({
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],
      classification: ['', Validators.required],
    });
    this.ccrDataForm = this.fb.group({
      ID: [''],
      Customer: ['', Validators.required],
      type: [''],
      classification: ['', Validators.required],
      region: ['', Validators.required],
      address: ['', Validators.required],
      desc: ['', Validators.required],
      product: ['', Validators.required],
      batch: ['', Validators.required],
      quantity: ['', Validators.required],
      run: [''],
      delivery: [''],
      pdate: [''],
      pdelivery: [''],
      dnote: [''],
      delay: [''],
      courier: [''],
      notes: [''],
      image: [''],
      ccrId: [''],
    });

    const d = new Date();
    d.setDate(d.getDate() - 7);
    this.ccrhistoryDataForm.get('fromdate')?.setValue(d);
    this.ccrhistoryDataForm.get('todate')?.setValue(new Date());
    this.getClassification();
    this.getCcr();
    this.getProductsByUserId();
    this.getTypes();
    this.getCouries();
  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
      data.sort = this.sort.toArray()[index];
    }, 1000);
  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ccrData.filter = filterValue.trim().toLowerCase();

    if (this.ccrData.paginator) {
      this.ccrData.paginator.firstPage();
    }
  }

  getCcrbyId(element: any): void {
    debugger;
    this.formMode = false;

    this.dialog.open(this.displayRoleModal, { height: '55%', width: '90%' });

    // this.customerId=element;
    this.ccrDataForm.get('Customer')?.setValue(this.user.customerName);
    this.ccrDataForm.get('type')?.setValue(element.typeId);

    this.ccrDataForm.get('classification')?.setValue(element.classficationId);
    this.ccrDataForm.get('ccrId')?.setValue(element.ccrId);
    this.ccrDataForm.get('region')?.setValue(this.user.region);
    this.ccrDataForm.get('address')?.setValue(element.address);
    this.ccrDataForm.get('desc')?.setValue(element.ccrDesc);
    this.ccrDataForm.get('product')?.setValue(element.prodId);
    this.PDtooltip = this.products.find(
      (c) => c.prodId === element.prodId
    ).prodDesc;
    this.ccrDataForm.get('batch')?.setValue(element.batch);
    this.ccrDataForm.get('quantity')?.setValue(element.quantity);
    this.ccrDataForm.get('run')?.setValue(element.runDate);
    this.ccrDataForm.get('delivery')?.setValue(element.deliveryDate);
    this.ccrDataForm.get('pdate')?.setValue(element.productionDate);
    this.ccrDataForm.get('pdelivery')?.setValue(element.promisedDeliveryDate);
    this.ccrDataForm.get('notes')?.setValue(element.otherNotes);
    this.ccrDataForm.get('delay')?.setValue(element.ccrDelay);
    this.ccrDataForm.get('courier')?.setValue(element.sampleId);
    this.ccrDataForm.get('dnote')?.setValue(element.deliveryNote);
    this.ccrDataForm.get('image')?.setValue(element.image);
    this.imageSrc = this.savedPath + element.imagePath;
    if (element.typeId === 2) {
      this.promisedDeliveryDate = false;
      this.Delay = false;
      this.productiondate = true;
      this.sampleSentBy = true;
      this.testDate = true;
    } else if (element.typeId === 1) {
      this.productiondate = false;
      this.sampleSentBy = false;
      this.testDate = false;
      this.promisedDeliveryDate = true;
      this.Delay = true;
    } else {
      this.productiondate = true;
      this.sampleSentBy = true;
      this.testDate = true;
      this.promisedDeliveryDate = true;
      this.Delay = true;
    }
  }

  onFileChange(event: any): any {
    if (event.target.files.length > 0) {
      this.fileSave = event.target.files[0];
    }
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.path = '';
        this.imageSrc = reader.result as string;
        this.ccrDataForm.patchValue({
          fileSource: reader.result,
        });
      };
    }
  }
  getCcr(): void {
    const id = {
      customerId: this.user.customerUserId,
      egId: this.user.egId,
    };
    this.spinner.show();
    this.ccrServices.getAllccr(id).subscribe(
      (listData: any) => {
        this.spinner.hide();
        this.loading = false;
        this.ccrData = new MatTableDataSource<any>(listData.Result);
        this.path = listData.path;
        this.savedPath = listData.path;

        this.getPaginator(this.ccrData, 0);
        this.loading = false;
      },
      (error) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }
  CCRDataFormReset(): void {
    this.ccrDataForm.reset();
    this.ccrDataForm.get('region')?.setValue('');
    this.ccrDataForm.get('address')?.setValue('');
    this.ccrDataForm.get('desc')?.setValue('');
    this.ccrDataForm.get('batch')?.setValue('');
    this.ccrDataForm.get('quantity')?.setValue('');
    this.ccrDataForm.get('run')?.setValue('');
    this.ccrDataForm.get('delivery')?.setValue('');
    this.ccrDataForm.get('pdate')?.setValue('');
    this.ccrDataForm.get('pdelivery')?.setValue('');
    this.ccrDataForm.get('notes')?.setValue('');
    this.ccrDataForm.get('delay')?.setValue('');
    // this.ccrDataForm.get('courier')?.setValue('');
    this.ccrDataForm.get('dnote')?.setValue('');
    this.ccrDataForm.get('image')?.setValue('');
  }
  sendNotificationGroup(descr: any): void {
    debugger;
    const key = {
      roleName: 'QC leader',
      egId: this.user.egId,
      createdBy: this.user.customerUserId,
      desc: descr,
      title: 'CCR History',
    };
    this.notificationService.addnotification(key).subscribe(
      (response: any) => {
        this.toaster.success('Notification Sent');
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  addCcr(): any {
    debugger;
    const formdata = new FormData();
    formdata.append('file', this.fileSave);
    this.loading = true;
    const Qty: number = this.ccrDataForm.value.quantity;
    const delay: any = this.ccrDataForm.value.delay;
    const cDate = moment(new Date()).format('YYYY-MM-DD');
    const ccr = {
      customerName: this.ccrDataForm.value.Customer,
      customerUser: this.user.customerUserId,
      type: this.ccrDataForm.value.type,
      classificationId: this.ccrDataForm.value.classification,
      region: this.ccrDataForm.value.region,
      address: this.ccrDataForm.value.address,
      Desc: this.ccrDataForm.value.desc,
      prodId: this.ccrDataForm.value.product,
      batch: this.ccrDataForm.value.batch,
      quantity: Qty,
      machine: this.CustomerbyProduct,
      runDate:
        this.ccrDataForm.value.run === '' ||
          this.ccrDataForm.value.run === null ||
          this.ccrDataForm.value.run === undefined
          ? undefined
          : moment(this.ccrDataForm.value.run).format('YYYY-MM-DD'),
      deliveryDate:
        // tslint:disable-next-line:max-line-length
        this.ccrDataForm.value.delivery === '' ||
          this.ccrDataForm.value.delivery === null ||
          this.ccrDataForm.value.delivery === undefined
          ? undefined
          : moment(this.ccrDataForm.value.delivery).format('YYYY-MM-DD'),
      productionDate:
        this.ccrDataForm.value.pdate === '' ||
          this.ccrDataForm.value.pdate === null ||
          this.ccrDataForm.value.pdate === undefined
          ? undefined
          : moment(this.ccrDataForm.value.pdate).format('YYYY-MM-DD'),
      pdDate:
        // tslint:disable-next-line:max-line-length
        this.ccrDataForm.value.pdelivery === '' ||
          this.ccrDataForm.value.pdelivery === null ||
          this.ccrDataForm.value.pdelivery === undefined
          ? undefined
          : moment(this.ccrDataForm.value.pdelivery).format('YYYY-MM-DD'),
      deliveryNote: this.ccrDataForm.value.dnote,
      ccrDelay: this.ccrDataForm.value.delay === '' ? null : delay,
      sampleSentBy:
        this.ccrDataForm.value.courier === ''
          ? null
          : this.ccrDataForm.value.courier,
      otherNotes: this.ccrDataForm.value.notes,
    };
    // tslint:disable-next-line:max-line-length
    if (
      this.ccrDataForm.value.type === 2 &&
      this.ccrDataForm.value.pdate <= this.ccrDataForm.value.delivery &&
      this.ccrDataForm.value.pdate <= this.ccrDataForm.value.run
    ) {
      formdata.append('ccr', JSON.stringify(ccr));
      this.ccrServices.addccr(formdata).subscribe(
        (response: any) => {
          this.toaster.success('CCR Added Successfuly');
          this.sendNotificationGroup(
            'The Complaint has been raised by Customer ' +
            this.ccrDataForm.value.Customer
          );
          this.loading = false;
          this.fileSave = null;
          this.getCcr();
          this.closeCcrModel();
          this.CCRDataFormReset();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
          this.loading = false;
        }
      );
    } else if (this.ccrDataForm.value.type === 1) {
      formdata.append('ccr', JSON.stringify(ccr));
      this.ccrServices.addccr(formdata).subscribe(
        (response: any) => {
          this.toaster.success('CCR Added Successfuly');
          this.sendNotificationGroup(
            'The Complaint has been raised by Customer ' +
            this.ccrDataForm.value.Customer +
            ' against Complaint Id ' +
            this.ccrDataForm.value.customerUserId
          );
          this.loading = false;
          this.fileSave = null;
          this.getCcr();
          this.closeCcrModel();
          this.CCRDataFormReset();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
          this.loading = false;
        }
      );
    } else if (
      this.ccrDataForm.value.type === 3 &&
      this.ccrDataForm.value.pdate <= this.ccrDataForm.value.delivery &&
      this.ccrDataForm.value.pdate <= this.ccrDataForm.value.run &&
      this.ccrDataForm.value.pdate <= this.ccrDataForm.value.pdelivery
      // tslint:disable-next-line: align
    ) {
      formdata.append('ccr', JSON.stringify(ccr));
      this.ccrServices.addccr(formdata).subscribe(
        (response: any) => {
          this.toaster.success('CCR Added Successfuly');
          this.sendNotificationGroup(
            'The Complaint has been raised by Customer ' +
            this.ccrDataForm.value.Customer
          );
          this.loading = false;
          this.fileSave = null;
          this.getCcr();
          this.closeCcrModel();
          this.CCRDataFormReset();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
          this.loading = false;
        }
      );
    } else {
      this.toaster.error('Production date should be lower then all dates');
      this.loading = false;
    }
  }

  updateCcr(): void {
    debugger;
    const formdata = new FormData();
    formdata.append('file', this.fileSave);
    this.loading = true;
    const CDate = moment(new Date()).format('YYYY-MM-DD');
    // this.ccrData  = this.ccrDataForm.value;
    const Qty: number = this.ccrDataForm.value.quantity;
    const delay: any = this.ccrDataForm.value.delay;
    const ccr = {
      ccrId: this.ccrDataForm.value.ccrId,
      customerName: this.ccrDataForm.value.Customer,
      customerUser: this.user.customerUserId,
      type: this.ccrDataForm.value.type,
      classificationId: this.ccrDataForm.value.classification,
      region: this.ccrDataForm.value.region,
      address: this.ccrDataForm.value.address,
      Desc: this.ccrDataForm.value.desc,
      prodId: this.ccrDataForm.value.product,
      batch: this.ccrDataForm.value.batch,
      machine: this.CustomerbyProduct,
      quantity: Qty,
      runDate:
        this.ccrDataForm.value.run === '' ||
          this.ccrDataForm.value.run === null ||
          this.ccrDataForm.value.run === undefined
          ? undefined
          : moment(this.ccrDataForm.value.run).format('YYYY-MM-DD'),
      deliveryDate:
        this.ccrDataForm.value.delivery === '' ||
          this.ccrDataForm.value.delivery === null ||
          this.ccrDataForm.value.delivery === undefined
          ? undefined
          : moment(this.ccrDataForm.value.delivery).format('YYYY-MM-DD'),
      productionDate:
        this.ccrDataForm.value.pdate === '' ||
          this.ccrDataForm.value.pdate === null ||
          this.ccrDataForm.value.pdate === undefined
          ? undefined
          : moment(this.ccrDataForm.value.pdate).format('YYYY-MM-DD'),
      pdDate:
        this.ccrDataForm.value.pdelivery === '' ||
          this.ccrDataForm.value.pdelivery === null ||
          this.ccrDataForm.value.pdelivery === undefined
          ? undefined
          : moment(this.ccrDataForm.value.pdelivery).format('YYYY-MM-DD'),
      deliveryNote: this.ccrDataForm.value.dnote,
      ccrDelay: this.ccrDataForm.value.delay === '' ? null : delay,
      sampleSentBy:
        this.ccrDataForm.value.courier === ''
          ? null
          : this.ccrDataForm.value.courier,
      otherNotes: this.ccrDataForm.value.notes,
    };
    if (
      this.ccrDataForm.value.type === 2 &&
      new Date(this.ccrDataForm.value.pdate) <=
      new Date(this.ccrDataForm.value.delivery) &&
      new Date(this.ccrDataForm.value.pdate) <=
      new Date(this.ccrDataForm.value.run)
    ) {
      formdata.append('ccr', JSON.stringify(ccr));
      this.ccrServices.updateCcr(formdata).subscribe(
        (response: any) => {
          this.toaster.success('CCR Update Successfuly');
          this.sendNotificationGroup(
            this.ccrDataForm.value.desc + ' has Updated Successfully'
          );
          this.loading = false;
          this.fileSave = null;
          this.getCcr();
          this.closeCcrModel();
          this.CCRDataFormReset();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
          this.loading = false;
        }
      );
    } else if (this.ccrDataForm.value.type === 1) {
      formdata.append('ccr', JSON.stringify(ccr));
      this.ccrServices.updateCcr(formdata).subscribe(
        (response: any) => {
          this.toaster.success('CCR Update Successfuly');
          this.sendNotificationGroup(
            this.ccrDataForm.value.desc + ' has Updated Successfully'
          );
          this.loading = false;
          this.fileSave = null;
          this.getCcr();
          this.closeCcrModel();
          this.CCRDataFormReset();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
          this.loading = false;
        }
      );
    } else if (
      this.ccrDataForm.value.type === 3 &&
      new Date(this.ccrDataForm.value.pdate) <=
      new Date(this.ccrDataForm.value.delivery) &&
      new Date(this.ccrDataForm.value.pdate) <=
      new Date(this.ccrDataForm.value.run) &&
      new Date(this.ccrDataForm.value.pdate) <=
      new Date(this.ccrDataForm.value.pdelivery)
    ) {
      formdata.append('ccr', JSON.stringify(ccr));
      this.ccrServices.updateCcr(formdata).subscribe(
        (response: any) => {
          this.toaster.success('CCR Update Successfuly');
          this.sendNotificationGroup(
            this.ccrDataForm.value.desc + 'has Updated Successfully'
          );
          this.loading = false;
          this.fileSave = null;
          this.getCcr();
          this.closeCcrModel();
          this.CCRDataFormReset();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
          this.loading = false;
        }
      );
    } else {
      this.toaster.error('Pdoduction date should be lower then all dates');
      this.loading = false;
    }
  }
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  countdelay(): void {
    const datedely = new Date(
      moment(this.ccrDataForm.value.pdelivery).format('YYYY-MM-DD')
    );
    const datePdate = new Date(
      moment(this.ccrDataForm.value.delivery).format('YYYY-MM-DD')
    );
    const Time = datedely.getTime() - datePdate.getTime();
    const Delay = Time / (1000 * 3600 * 24);
    if (Delay >= 0) {
      // this.toaster.error('Promise date should be greater then production date');
      this.ccrDataForm.get('delay')?.setValue(0);
    } else if (Delay < 0) {
      const PositiveDelay = Delay * -1;
      this.ccrDataForm.get('delay')?.setValue(PositiveDelay);
    }
  }

  changedate1(): void {
    if (this.ccrDataForm.value.run >= this.ccrDataForm.value.delivery) {
      this.ccrDataForm
        .get('delivery')
        ?.setValue(this.ccrDataForm.value.delivery);
      this.ccrDataForm.get('run')?.setValue(this.ccrDataForm.value.run);
    } else {
      this.toaster.error(
        'Test date should not be lower than delivery date & production date'
      );
      this.ccrDataForm.get('run')?.setValue('');
    }
  }

  onChangeDataType(): void {
    if (this.ccrDataForm.get('type')?.value === 2) {
      this.ccrDataForm.get('type')?.setValue(2);
      this.promisedDeliveryDate = false;
      this.Delay = false;
      this.productiondate = true;
      this.sampleSentBy = true;
      this.testDate = true;
    } else if (this.ccrDataForm.get('type')?.value === 1) {
      this.productiondate = false;
      this.sampleSentBy = false;
      this.testDate = false;
      this.promisedDeliveryDate = true;
      this.Delay = true;
      this.ccrDataForm.get('type')?.setValue(1);
    } else {
      this.productiondate = true;
      this.sampleSentBy = true;
      this.testDate = true;
      this.promisedDeliveryDate = true;
      this.Delay = true;
    }
  }

  delete(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete role!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          ccrId: element.ccrId,
        };
        this.ccrServices.deleteccr(key).subscribe(
          (success: any) => {
            Swal.fire(
              'Deleted!',
              'Your selected ccr has been deleted.',
              'success'
            );
            // this.getCcr();
            this.onSearch();
          },
          (error: any) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected ccr is safe :)', 'error');
      }
    });
  }

  getClassification(): void {
    this.ccrServices.getClassification().subscribe(
      (res: any) => {
        this.classifications = res;
        this.ccrDataForm
          .get('classification')
          ?.setValue(this.classifications[0].classificationId);
        this.ccrhistoryDataForm.get('classification')?.setValue(0);
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  openModal(): void {
    this.CCRDataFormReset();
    //  this.getClassification();
    this.ccrDataForm
      .get('classification')
      ?.setValue(this.classifications[0].classificationId);
    this.ccrDataForm.get('Customer')?.setValue(this.user.customerName);
    this.ccrDataForm.get('region')?.setValue(this.user.region);
    this.ccrDataForm.get('address')?.setValue(this.user.address);
    this.ccrDataForm.get('product')?.setValue(this.products[0].prodId);

    this.ccrDataForm.get('type')?.setValue(this.types[0].typeId);
    this.ccrDataForm.get('courier')?.setValue(this.couriers[0].courierId);
    this.PDtooltip = this.products.find(
      (c) => c.prodId === this.products[0].prodId
    ).prodDesc;
    this.CustomerbyProduct = this.products.find(
      (c) => c.prodId === this.products[0].prodId
    ).puDesc;
    this.path = '';
    this.formMode = true;
    this.imageSrc = '';
    this.dialog.open(this.displayRoleModal, { height: '55%', width: '90%' });
    this.onChangeDataType();
  }
  getProductsByUserId(): void {
    debugger;
    const id = {
      customerUserId: this.user.customerUserId,
    };
    this.ccrServices.getProductsByUserId(id).subscribe(
      (res: any) => {
        this.products = res;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getTypes(): void {
    this.ccrServices.getTypes().subscribe(
      (res: any) => {
        this.types = res;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getCouries(): void {
    this.ccrServices.getCourier().subscribe(
      (res: any) => {
        this.couriers = res;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  imageZoomModal(imagePath: string, displayRoleModal: any): void {
    this.currentImagePath = imagePath.indexOf('null') > -1 ? null : imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  closeCcrModel(): void {
    this.dialog.closeAll();
  }
  onSearch(): void {
    const data = {
      customerId: this.user.customerUserId,
      classificationId:
        this.ccrhistoryDataForm.value.classification !== 0
          ? this.ccrhistoryDataForm.value.classification
          : null,

      startDate: moment(this.ccrhistoryDataForm.value.fromdate).format(
        'YYYY-MM-DD'
      ),
      endDate: moment(this.ccrhistoryDataForm.value.todate).format(
        'YYYY-MM-DD'
      ),
    };
    if (this.ccrhistoryDataForm.invalid) {
      this.toaster.error('Error', 'Select all fields');
      return;
    }
    this.ccrServices.onSearch(data).subscribe(
      (res: any) => {
        this.ccrData = new MatTableDataSource<any>(res);
        this.getPaginator(this.ccrData, 0);
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.clear();
    this.toaster.info('logged out');
    this.router.navigate(['/login']);
  }
  tooltipChange(element: any): void {
    debugger;
    this.PDtooltip = this.products.find(
      (c) => c.prodId === element.value
    ).prodDesc;
    this.CustomerbyProduct = this.products.find(
      (c) => c.prodId === element.value
    ).puDesc;
  }
}
