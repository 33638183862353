<div class="plant-model-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{ breadcrumItem.text }}</a>
      </li>
    </ul>
    <div class="top-box-content">
      <h1 class="main-title">Check List History</h1>
    </div>

    <form [formGroup]="groupDataForm">
      <div class="content-top-row content-top-row-order-processing" style="margin-left:-12px">
        <mat-form-field appearence="fill" class="
            top-row-first-filed-item top-row-first-filed-item-order-processing
          ">
          <mat-select formControlName="group" placeholder="Group" panelClass="testClass"
            (selectionChange)="getPlants()">
            <mat-option *ngFor="let group of Groups" [value]="group.TypeId">
              {{ group.TypeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="
            top-row-first-filed-item top-row-first-filed-item-order-processing
          ">
          <mat-select formControlName="plant" placeholder="Plant" panelClass="testClass" name="plant"
            (selectionChange)="getCheckListHistoryByPlant()">
            <mat-option *ngFor="let plant of Plants" [value]="plant.DeptId">
              {{ plant.DeptDesc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div style="width: auto !important" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="width: auto; min-width: 18rem;overflow: hidden;">
          <label class="floating-label">Date Range</label>
          <input type="text" matInput ngxDaterangepickerMd formControlName="datePicker" [timePicker]="true"
            [timePicker24Hour]="true" [showDropdowns]="true" [closeOnAutoApply]="true"
            (datesUpdated)="getCheckListHistory()" readonly [locale]="{format: 'DD/MM/YYYY HH:mm'}"
            style="margin-left: 10px" />
        </div>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select ngxMatSelect [hasSearchBox]="true" [useMobileView]="true" mobileViewType="BottomSheet"
            [formControlName]="'Unit'" name="puDesc" [required]="false" [source]="unitsArray"
            (selectionChange)="onUnitChange()" [displayMember]="'puDesc'" [placeholder]="'Select Units'"
            [valueMember]="'puId'" #sf="ngxMatSelect">
            <mat-option [value]="item.puId" *ngFor="let item of sf.filteredSource">
              {{item.puDesc}}
            </mat-option>
          </mat-select>
          <mat-error>Select Units</mat-error>
        </mat-form-field>
        <button mat-mini-fab color="primary" style="margin-left: 10px;" pTooltip="Reset" tooltipPosition="top"
          (click)="onclickReset()" type="button">
          <mat-icon>refresh</mat-icon>
        </button>

        <button mat-mini-fab color="primary" style="margin-left: 10px;" pTooltip="Report Configuration"
          tooltipPosition="top" (click)="configModal()" type="button">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </form>

  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  <div id="reportContainer"></div>
  <div id="executedImagesreport"></div>
  <div class="content-top-row" style="display: flex; justify-content: flex-end;">
    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
      <input matInput id="filter-text-box" placeholder="Search" autocomplete="off" (input)="onFilterTextBoxChanged()">
    </mat-form-field>
  </div>
  <ag-grid-angular #agGridCheckList style="width: 100%; height: 100%;" class="ag-theme-alpine"
    [rowData]="checkListRowData" [columnDefs]="ColumnDefsCheckList" (gridReady)="onGridReadyCheckList($event)"
    (cellClicked)="onCellClicked($event)" [gridOptions]="gridOptionsCheckList" [pagination]="true" [sideBar]="sideBar"
    [paginationPageSize]="10" [loadingOverlayComponent]="loadingOverlayComponent"
    (firstDataRendered)="applyState('CheckListHistory-vTLU37IVZb')" (columnMoved)="onSaveGridState()"
    (columnVisible)="onSaveGridState()" (columnPinned)="onSaveGridState()"
    (displayedColumnsChanged)="onSaveGridState()"></ag-grid-angular>

  <div id="contentToConvert" style="display: none;">

    <div class="d-flex  w-100">
      <div class="w-70 d-flex flex-wrap" *ngFor="let element of reportMainData">
        <div class="w-30 d-flex border justify-content-center items-center padding-x">
          <img style="width: 70px;" src="../../../assets/images/o3.png">
        </div>
        <div class="d-flex w-70 flex-column">
          <div class="d-flex justify-content-center items-center h-50 border ">
            <p>{{element.listName}}</p>
          </div>
          <div class="d-flex justify-content-center items-center h-50 border">
            <p>{{element.plantName}}</p>
          </div>
        </div>
        <div class="padding-x border w-40 text-center">
          <p>Group: {{element.groupName}}</p>
        </div>
        <div class="padding-x border w-40 text-center">
          <p>Plant: {{element.plantName}}</p>
        </div>
        <div class="padding-x border w-20 text-center">
          <p>{{element.confirmedStatus}}</p>
        </div>
        <div class="padding-x border w-40 text-center">
          <p>Line: {{element.lineName}}</p>
        </div>
        <div class="padding-x border w-40 text-center">
          <p>Unit: {{element.unitName}}</p>
        </div>
        <div class="padding-x border w-20 text-center">
          <p>{{element.verifiedStatus}}</p>
        </div>


        <div class="padding-x border w-40 text-center">
          <p>Area: {{element.areaName}}</p>
        </div>
        <div class="padding-x border w-20 text-center">
          <p>Shift: {{element.shiftName}}</p>
        </div>
        <div class="padding-x border w-40 text-center">
          <p>Executed Date:{{element.executionDate}}</p>
        </div>

      </div>
      <div class="w-30 border">
        <div class="padding-x border" *ngFor="let header of headerArr">
          <p>
            {{header.name}}: {{header.value}}
          </p>
        </div>
      </div>
    </div>
    <table class="w-100  text-center" id="seperateHeader">
      <tr class="p-10 border dynamicHeaderEl" *ngFor="let r of reportProdData; let i = index"
        style="display: inline-block;width: auto; min-width: 16.66%; white-space: nowrap;">
        {{r.name}}: {{r.value}}
      </tr>
    </table>
    <table cellspacing="0">
      <thead>
        <tr>
          <th colspan="6">Group: Printing & Coding Verification Form</th>
        </tr>
        <tr>
          <th *ngIf="Item_tf">Item/Parameters</th>
          <th *ngIf="GoodExample_tf">Good Example</th>
          <th *ngIf="BadExample_tf">Bad Example</th>
          <th *ngIf="Standard_tf">Standard</th>
          <th *ngIf="Result_tf">Result</th>
          <th *ngIf="Remarks_tf">Remarks</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of reportDetail">
          <td *ngIf="Item_tf">{{d.item}}</td>
          <!-- <td *ngIf="GoodExample_tf"><img style="width: 30px;" src="../../../assets/images/o3.png"></td> -->
          <td *ngIf="GoodExample_tf">{{d.goodExampleImageName}}</td>
          <td *ngIf="BadExample_tf">{{d.badExampleImageName}}</td>
          <td *ngIf="Standard_tf">{{d.standard}}</td>
          <td *ngIf="Result_tf">{{d.result}}</td>
          <td *ngIf="Remarks_tf">{{d.remarks}}</td>
        </tr>

      </tbody>
      <tfoot class="blue">
        <tr *ngFor="let footer of FooterArr">
          <td colspan="12">{{footer.name}}: {{footer.value}}</td>
]        </tr>
      </tfoot>
    </table>
  </div>
</div>

<ng-template #displayRoleModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h1 mat-dialog-title>Check List Execution History</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div id="pdfTable" #pdfTable>
    <form [formGroup]="checklistDataForm">
      <mat-dialog-content>
        <div class="content-top-row content-top-row-order-processing">
          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Group</mat-label>
            <input matInput formControlName="group" placeholder="Enter group" readonly />
          </mat-form-field>

          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Plant</mat-label>
            <input matInput formControlName="plant" placeholder="Enter plant" readonly />
          </mat-form-field>

          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Check List Type</mat-label>
            <input matInput formControlName="checktype" placeholder="Enter checktype" readonly />
          </mat-form-field>
          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Check List Name</mat-label>
            <input matInput formControlName="Listname" placeholder="Enter List Name" readonly />
          </mat-form-field>
          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Area</mat-label>
            <input matInput formControlName="area" placeholder="Enter area" readonly />
          </mat-form-field>
        </div>
        <div class="content-top-row content-top-row-order-processing">

          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Date</mat-label>
            <input matInput formControlName="executionDate" placeholder="Enter executionDate" readonly />
          </mat-form-field>

          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Shift</mat-label>
            <input matInput formControlName="shift" placeholder="Shift" [readonly]="true" />
          </mat-form-field>

          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Unit</mat-label>
            <input matInput formControlName="Unit" placeholder="Select Unit" [readonly]="true" />
          </mat-form-field>
          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Current Process Order No</mat-label>
            <input matInput formControlName="currentProcessOrder" placeholder="Select currentProcessOrder"
              [readonly]="true" />
          </mat-form-field>
          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Current Product Description</mat-label>
            <input matInput placeholder="Product Description" formControlName="currentProductDescription"
              [readonly]="true" />
          </mat-form-field>
        </div>
        <div class="content-top-row content-top-row-order-processing">


          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label>Previous Process Order No</mat-label>
            <input matInput placeholder="previous ProcessOrder" formControlName="previousProcessOrder"
              [readonly]="true" />
          </mat-form-field>

          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label>Previous Product Description</mat-label>
            <input matInput placeholder="Product Description" formControlName="previousProductDescription"
              [readonly]="true" />
          </mat-form-field>
          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label>Target Order Quantity</mat-label>
            <input matInput placeholder="Target Order Quantity" formControlName="targetOrderQuantity"
              [readonly]="true" />
          </mat-form-field>

          <mat-form-field appearence="fill" class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label>Produced Quantity</mat-label>
            <input matInput placeholder="Produced Quantity" formControlName="producedQuantity" [readonly]="true" />
          </mat-form-field>
          <mat-form-field *ngIf="completedRole"
            [disabled]="VerifiedStatus === 'Verified' || VerifiedStatus === 'Rejected'" appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select [disabled]="VerifiedStatus === 'Verified' || VerifiedStatus === 'Rejected'"
              formControlName="con" placeholder="Confirmed" panelClass="testClass"
              (selectionChange)="rejectPopup($event)">
              <mat-option *ngFor="let confirmed of confirmedlist" [value]="confirmed.name">
                {{ confirmed?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="content-top-row content-top-row-order-processing">
          <mat-form-field *ngIf="closedRole" [disabled]="ConfirmedStatus === 'Not Confirmed'" appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select [disabled]="ConfirmedStatus === 'Not Confirmed'" formControlName="ver" placeholder="Verified"
              panelClass="testClass" (selectionChange)="rejectPopup($event)">
              <mat-option *ngFor="let verified of verifiedlist" [value]="verified.name">
                {{ verified?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <mat-divider></mat-divider>
      <div style="margin-top: 10px"></div>

      <div class="wrapper-box wrapper-box-shadow table-wrapper" *ngIf="dataSource !== undefined">
        <table mat-table [dataSource]="dataSource" class="basic-table plant-model-table popup" matSort>
          <ng-container matColumnDef="items">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Items</p>
            </th>
            <td mat-cell *matCellDef="let element" class="td-text">
              {{ element.ItemName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="good">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 0px; width: 12%">
              <p class="header-text">Good Example</p>
            </th>
            <td mat-cell *matCellDef="let element" style="width: 10%">
              <button mat-mini-fab color="accent" *ngIf="element.GoodExampleImage!='' && element.GoodExampleImage!=null"
                (click)="openFilePopUp2(element , i , 1)" style="background-color: #8f0fd1;">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="bad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 0px; width: 12%">
              <p class="header-text">Bad Example</p>
            </th>
            <td mat-cell *matCellDef="let element" style="width: 10%">
              <button mat-mini-fab color="accent" *ngIf="element.BadExampleImage!='' && element.BadExampleImage!=null"
                (click)="openFilePopUp2(element , i , 2)" style="background-color: #8f0fd1;">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="standard">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Standard</p>
            </th>
            <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%;">
              {{element.standard}}
            </td>
          </ng-container>

          <ng-container matColumnDef="minimum">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Min</p>
            </th>
            <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%; background-color: #edf1e5;">
              {{element.minValue}}
            </td>
          </ng-container>

          <ng-container matColumnDef="maximum">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Max</p>
            </th>
            <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%; background-color: #edf1e5;">
              {{element.maxValue}}
            </td>
          </ng-container>

          <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 56px">
              <p class="header-text">Result</p>
            </th>
            <td mat-cell *matCellDef="let element" class="td-text" style="padding-left: 80px">

              <textarea *ngIf="element.ItemTypeId === 1" pTooltip="{{element.CatType}}" class="wrapped-text" matInput
                [value]="element.CatType" [readonly]="true"></textarea>
              <textarea *ngIf="element.ItemTypeId === 2" pTooltip="{{element.CatType}}" class="wrapped-text" matInput
                [value]="element.CatType" [readonly]="true"></textarea>
              <input *ngIf="element.ItemTypeId === 3" matInput [value]="element.CatType | date: 'dd-MM-YYYY'"
                [readonly]="true" />

              <img
                *ngIf="element.ItemTypeId === 4 && element.filetype === 'jpg' || element.ItemTypeId === 4 && element.filetype === 'png'"
                [src]="element.CatType!== '' ? element.CatType:'assets/images/no-image.png'" width="30px" height="30px"
                (click)="imageZoomModal(element.CatType!== '' ? element.CatType :'assets/images/no-image.png')">
              <mat-form-field apearence="fill" class="field-fill" *ngIf="element.ItemTypeId === 5" style="width: 100%;">
                <mat-label style="background-color:#F4F6FD">Selected option</mat-label>
                <mat-select [value]="element.CatType" pTooltip="{{element.CatType}}" [disabled]="true"
                  style="background-color:#F4F6FD">
                  <mat-option *ngFor="let data of dropdowData" [value]="data.ElementValue">{{data.ElementValue}}</mat-option>
                </mat-select>
              </mat-form-field>

              <div
                *ngIf="element.filetype==='pdf' || element.filetype === 'txt' || element.filetype === 'xlsx' || element.filetype === 'xsl' || element.filetype ==='mp4' || element.filetype === 'wmv' || element.filetype==='csv' ">
                <button type="button" mat-mini-fab color="primary" style="margin-top: 2px;"
                  *ngIf="element.CatType != null && element.CatType != ''">
                  <a target="_blank" href="{{element.CatType}}">
                    <mat-icon>cloud_download</mat-icon>
                  </a>
                </button>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="remarks">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Remarks</p>
            </th>
            <td mat-cell *matCellDef="let element">
              <textarea class="wrapped-text" matInput [value]="element.Remarks" [readonly]="true"></textarea>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="itemColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: itemColumns"></tr>
          <tr class="mat-row" *matNoDataRow style="text-align: center;">
            <td class="mat-cell" style="text-align: center;" colspan="7">No Data Match</td>
          </tr>

          <ng-container matColumnDef="groupHeader">
            <th (click)="groupHeaderClick(group)" colspan="999" mat-header-cell *matCellDef="let group"
              class="group-header">
              <div class="d-flex align-items-center">
                <mat-icon *ngIf="group.expanded">arrow_drop_down</mat-icon>
                <mat-icon *ngIf="!group.expanded">arrow_right</mat-icon>
                <span style="font-size: 20px;" class="table-group-title">{{group[groupByColumns[group.level-1]]}}</span>
              </div>
            </th>
          </ng-container>
          <tr mat-header-row *matRowDef="let group; columns: ['groupHeader'];when: isGroup"></tr>
          <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </mat-row>
        </table>
        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[50,100]" showFirstLastButtons></mat-paginator>
      </div>
      <div mat-dialog-actions style="float: right; margin-top: 10px">
      </div>
    </form>
  </div>
</ng-template>
<ng-template #displayVerifiedModel>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h1 mat-dialog-title>Verified Information</h1>
      <button mat-mini-fab mat-button color="warn" title="close" (click)="closeVerifiedModal()">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <form [formGroup]="ConfirmForm">
    <div class="content-top-row content-top-row-order-processing">
      <mat-form-field appearence="fill" class="
        top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 30%;">
        <mat-label class="required">Remarks</mat-label>
        <input matInput formControlName="remarks" placeholder="Enter remarks" required />
      </mat-form-field>
      <div style="width: fit-content; margin-left: 10px;" class="
    top-row-first-filed-item top-row-first-filed-item-order-processing">
        <input formControlName="image" placeholder="Enter remarks" id="file" type="file" (change)="uploadedFile($event)"
          style="margin-top: 15px; margin-left: 5px;" />
      </div>
      <button mat-mini-fab mat-button mat-dialog-close color="primary" type="submit" pTooltip="Save"
        tooltipPosition="top" style="margin-left: 40px;"
        (click)="roleMode ? confirmedChecklist($event) : verifiedChecklist($event)">
        <i class="fa fa-floppy-o" style="color: #fff"></i>
      </button>
    </div>
  </form>
</ng-template>
<ng-template #displayConfirmModel>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h1 mat-dialog-title>Confirm Information</h1>
      <button mat-mini-fab mat-button color="warn" title="close" (click)="closeConfirmModal()">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <form [formGroup]="ConfirmForm">
    <div class="content-top-row content-top-row-order-processing">
      <mat-form-field appearence="fill" class="
        top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 30%;">
        <mat-label class="required">Remarks</mat-label>
        <input matInput formControlName="remarks" placeholder="Enter remarks" required />
      </mat-form-field>
      <div style="width: fit-content; margin-left: 10px;" class="
    top-row-first-filed-item top-row-first-filed-item-order-processing">
        <input formControlName="image" placeholder="Enter remarks" id="file" type="file" (change)="uploadedFile($event)"
          style="margin-top: 15px; margin-left: 5px;" />
      </div>
      <button mat-mini-fab mat-button mat-dialog-close color="primary" type="submit" pTooltip="Save"
        tooltipPosition="top" style="margin-left: 40px;"
        (click)="roleMode ? confirmedChecklist($event) : verifiedChecklist($event)">
        <i class="fa fa-floppy-o" style="color: #fff"></i>
      </button>
    </div>
  </form>
</ng-template>

<ng-template #displayRejectModel>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h1 mat-dialog-title>Reject Information</h1>
      <button mat-mini-fab mat-button color="warn" title="close" (click)="closeRejectModal()">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <form [formGroup]="RejectForm">
    <div class="content-top-row content-top-row-order-processing">
      <mat-form-field appearence="fill" class="
        top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 30%;">
        <mat-label class="required">Remarks</mat-label>
        <input matInput formControlName="remarks" placeholder="Enter remarks" required />
      </mat-form-field>
      <div style="width: fit-content; margin-left: 10px;" class="
    top-row-first-filed-item top-row-first-filed-item-order-processing">
        <input formControlName="image" placeholder="Enter remarks" id="file" type="file" (change)="uploadedFile($event)"
          style="margin-top: 15px; margin-left: 5px;" />
      </div>
      <button mat-mini-fab mat-button mat-dialog-close color="primary" type="submit" pTooltip="Save"
        tooltipPosition="top" style="margin-left: 40px;"
        (click)="roleMode ? RejectQualityChecklist($event) : verifiedChecklist($event)" [disabled]="!RejectForm.valid">
        <i class="fa fa-floppy-o" style="color: #fff"></i>
      </button>
    </div>
  </form>
</ng-template>

<ng-template #imageZoomModel>
  <img *ngIf="currentImagePath" [src]="currentImagePath" style="height: 98%;width: 100%; overflow: hidden;" />
  <div *ngIf="!currentImagePath" class="popup-text-border">
    <img src="assets/images/no-image.png">
  </div>
</ng-template>
<ng-template #imageZoomModel2>
  <img [src]="currentImagePath" style="height: 98%; width: 100%; overflow: hidden" />
  <div *ngIf="!currentImagePath" class="popup-text-border">
    <img src="assets/images/no-image.png">
  </div>
</ng-template>


<ng-template #configurationModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h3>Report Configuration</h3>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>

  <mat-accordion style="margin-top: 20px;">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc2" class="disable_ripple_acc2">
        <mat-panel-title>
          Sub-Header Columns
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ag-grid-angular #subheaderGrid style="width: 100%;margin-top: 15px;" class="ag-theme-alpine"
        [rowData]="subheaderRowData" [columnDefs]="subheadercolumnDefs" (gridReady)="onGridReadysubheader($event)"
        [pagination]="true" [paginationPageSize]="5" [rowSelection]="rowSelection"
        [frameworkComponents]="frameworkComponents" (rowDataChanged)="onRowDataChangedSubHeader($event)"
        [loadingOverlayComponent]="loadingOverlayComponent"
        (firstDataRendered)="onFirstDataRenderedForSubHeader($event)" [suppressRowClickSelection]="true"
        (selectionChanged)="onSelectionChangedsubheader($event)" [defaultColDef]="defaultColDefPopup">
      </ag-grid-angular>

      <div class="btn-end">
        <button mat-mini-fab mat-button color="primary" (click)="onSaveSubHeaders()"
          pTooltip="Save" tooltipPosition="top">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc3" class="disable_ripple_acc2">
        <mat-panel-title>
          Result Columns
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ag-grid-angular #resultGrid style="width: 100%;margin-top: 15px;" class="ag-theme-alpine"
        [rowData]="resultRowData" [columnDefs]="resultcolumnDefs" (gridReady)="onGridReadyresult($event)"
        [pagination]="true" [paginationPageSize]="5" [rowSelection]="rowSelection" [suppressRowClickSelection]="true"
        [frameworkComponents]="frameworkComponents" (rowDataChanged)="onRowDataChangedResultColumns($event)"
        (selectionChanged)="onSelectionChangedresult($event)" [loadingOverlayComponent]="loadingOverlayComponent"
        (firstDataRendered)="onFirstDataRenderedForResultColumns($event)" [defaultColDef]="defaultColDefPopup">
      </ag-grid-angular>
      <div class="btn-end">
        <button mat-mini-fab mat-button color="primary" (click)="onSaveResultColumns()"
          pTooltip="Save" tooltipPosition="top">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

</ng-template>

<p-dialog [closeOnEscape]="false" header="File Detail" [(visible)]="displayMaximizable" [modal]="true"
  [style]="{width: '90vw'}" [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ngx-doc-viewer *ngIf="fileType==='doc'" [url]="doc" viewer="google" style="width:100%;height:90vh;"></ngx-doc-viewer>
  <angular-image-viewer *ngIf="fileType==='img'" [src]="images" [(index)]="imageIndexOne"></angular-image-viewer>
</p-dialog>

<p-dialog [closeOnEscape]="false" header="File Detail" [(visible)]="displayMaximizable2" [modal]="true"
  [style]="{width: '90vw'}" [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ngx-doc-viewer *ngIf="fileType==='doc'" [url]="doc" viewer="google" style="width:100%;height:90vh;"></ngx-doc-viewer>
  <angular-image-viewer *ngIf="fileType==='img'" [src]="images" [(index)]="imageIndexOne"></angular-image-viewer>
</p-dialog>
<ng-template #displayEditRoleModal>
  <div mat-dialog-title style="margin-bottom: 0px;">
    <div class="d-flex justify-content-between align-items-center">
      <h1 mat-dialog-title>Checklist Execution</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <form [formGroup]="checklistEditDataForm">
    <mat-dialog-content>
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="margin-left: 0px;">
          <mat-label class="required">Group</mat-label>
          <mat-select panelClass="testClass" name="group" formControlName="group" [disabled]="true">
            <mat-option *ngFor="let group of Groups" [value]="group.TypeId">
              {{ group.TypeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label class="required">Plant</mat-label>
          <mat-select name="list" formControlName="plant" [disabled]="true" (selectionChange)="getArea()">
            <mat-option *ngFor="let plant of Plants" [value]="plant.DeptId">
              {{ plant.DeptDesc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label class="required">Checklist Type</mat-label>
          <input matInput formControlName="checktype" placeholder="Enter List Type" readonly />
          <!-- <mat-select name="list" formControlName="checktype" [disabled]="true">
            <mat-option *ngFor="let o of listTypeDDL" [value]="o.name">{{o.name}}</mat-option>
          </mat-select> -->
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label class="required">Checklist Name</mat-label>
          <input matInput formControlName="checkname" placeholder="Enter List Name" readonly />
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Area</mat-label>
          <input matInput formControlName="area" placeholder="Enter area Name" readonly />
        </mat-form-field>
      </div>
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field style="margin-left: 0px;" appearence="fill" class="
        top-row-first-filed-item top-row-first-filed-item-order-processing
      ">
          <mat-label class="required">Date</mat-label>
          <input matInput formControlName="executionDate" placeholder="Enter executionDate" readonly />
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Shift</mat-label>
          <input matInput formControlName="shiftName" readonly />

        </mat-form-field>
        <input matInput formControlName="shift" style="display: none;" readonly placeholder="Shift" />

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Unit Name</mat-label>
          <input matInput formControlName="unitName" readonly placeholder="Enter Unit Name" />
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Current Process Order No</mat-label>
          <input matInput formControlName="currentProcessOrder" [readonly]="isAuto" />
          <input matInput formControlName="currentProcessOrderId" style="display: none;" [readonly]="isAuto" />

        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Current Product Name</mat-label>
          <input matInput placeholder="Product Description" formControlName="currentProductName" [readonly]="preAuto" />
        </mat-form-field>
      </div>
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="margin-left: 0px;">
          <mat-label>Previous Process Order No</mat-label>
          <input matInput formControlName="previousProcessOrder" [readonly]="isAuto2" />
          <input matInput formControlName="previousProcessOrderId" style="display: none;" [readonly]="isAuto2" />

        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Previous Product Name</mat-label>
          <input matInput placeholder="Product Description" formControlName="previousProductName"
            [readonly]="preAuto2" />
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Target Order Quantity</mat-label>
          <input matInput placeholder="Target Order Quantity" formControlName="targetOrderQuantity" />
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Produced Quantity</mat-label>
          <input matInput placeholder="Produced Quantity" formControlName="producedQuantity" />
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-divider></mat-divider>
    <div style="margin-top: 10px;"></div>
    <table mat-table [dataSource]="checkListEditModel" class="basic-table plant-model-table popup" matSort>
      <ng-container matColumnDef="items">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Items</p>
        </th>
        <td mat-cell *matCellDef="let element" class="td-text" style="padding-left: 80px; width: 30%;">
          {{element.itemName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="good">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style=" padding-left: 0px;">
          <p class="header-text">Good Example</p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" style="width: 10%;">
          <button mat-mini-fab color="accent" *ngIf="element.goodExampleImage!='' && element.goodExampleImage!=null"
            (click)="openFilePopUp(element , i , 1)" style="background-color: #8f0fd1;">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>


      <ng-container matColumnDef="bad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 0px;">
          <p class="header-text">Bad Example</p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" style="width: 10%;">
          <button mat-mini-fab color="accent" *ngIf="element.badExampleImage!='' && element.badExampleImage!=null"
            (click)="openFilePopUp(element , i , 2)" style="background-color: #8f0fd1;">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="standard">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Standard</p>
        </th>
        <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%;">
          {{element.standard}}
        </td>
      </ng-container>

      <ng-container matColumnDef="minimum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Min</p>
        </th>
        <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%; background-color: #edf1e5;">
          {{element.minValue}}
        </td>
      </ng-container>

      <ng-container matColumnDef="maximum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Max</p>
        </th>
        <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%; background-color: #edf1e5;">
          {{element.maxValue}}
        </td>
      </ng-container>

      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Result</p>
        </th>
        <td mat-cell *matCellDef="let element" style="width: 20%; background-color: #edf1e5;">

          <mat-radio-group required aria-label="Select an option" [value]="element.catType"
            (change)="scoreUpdate($event, element); onChangeradio($event) " *ngIf="element.itemType === 1">
            <mat-radio-button *ngFor="let p of categoryTypeDDL" [value]="p.id" style="margin-left: 10px;">{{p.name}}
            </mat-radio-button>
          </mat-radio-group>

          <mat-form-field appearance="fill" class="field-fill-table" *ngIf="element.itemType === 2"
            style="width: 180px;">
            <input matInput autocomplete="off" (change)="scoreUpdate($event, element)" [value]="element.catType">
          </mat-form-field>
          <div appearance="fill" class="field-fill" *ngIf="element.itemType === 4"
            style="width: 180px;display:flex;align-items:center;">
            <input placeholder="Upload File" type="file" (change)="uploadFileEdit($event, element.checkListDetailId)"
              class="form-control" />

            <a style="padding-left:5px" target="_blank" href="{{imageSrc + element.catType}}"
              *ngIf="element.catType != null && element.catType != ''"><i class="fa fa-download"></i></a>
          </div>
          <mat-form-field appearence="fill" class="field-fill" *ngIf="element.itemType === 3">
            <mat-label class="placeholder-title">From Date</mat-label>
            <input matInput [matDatepicker]="pickerspoedit" (dateInput)="onDateChange($event, element)"
              style="width: 180px;" [value]="element.catType">
            <mat-datepicker-toggle matSuffix [for]="pickerspoedit"></mat-datepicker-toggle>
            <mat-datepicker #pickerspoedit></mat-datepicker>
          </mat-form-field>
          <mat-form-field apearence="fill" class="field-fill" *ngIf="element.itemType === 5">
            <mat-label>Select an option</mat-label>
            <mat-select (selectionChange)="OnDropdownUpdate($event,element)" [value]="element.catType">

              <mat-option *ngFor="let data of element.dropDown" [value]="data.ElementValue">{{data.ElementValue}}
              </mat-option>

            </mat-select>

          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="remarks">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Remarks</p>
        </th>
        <td mat-cell *matCellDef="let element" style="width: 30%; background-color: #edf1e5;">
          <mat-form-field appearance="fill" class="field-fill-table" style="width: 100%;">
            <input matInput placeholder="Remarks" autocomplete="off" (change)="remarksUpdate($event, element)"
              [value]="element.remarks">
          </mat-form-field>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="itemColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: itemColumns"></tr>
      <tr class="mat-row" *matNoDataRow style="text-align: center;">
        <td class="mat-cell" style="text-align: center;" colspan="7">No data matching</td>
      </tr>
      <!-- Group header -->
      <ng-container matColumnDef="groupHeaderEdit">
        <th (click)="groupHeaderEdit(editgroup)" colspan="999" mat-header-cell *matCellDef="let editgroup"
          class="group-header">
          <div class="d-flex align-items-center">
            <mat-icon *ngIf="editgroup.expanded">arrow_drop_down</mat-icon>
            <mat-icon *ngIf="!editgroup.expanded">arrow_right</mat-icon>
            <span class="table-group-title"
              style="font-size: 20px;font-weight: bold;">{{editgroup[groupByColumnsEdit[editgroup.level-1]]}}</span>
          </div>
        </th>
      </ng-container>
      <tr mat-header-row *matRowDef="let editgroup; columns: ['groupHeaderEdit'];when: isGroup"></tr>
      <mat-row *matRowDef="let row; columns: ['groupHeaderEdit']; when: isGroup"> </mat-row>
    </table>

  </form>
  <div mat-dialog-actions style="float: right; margin-top: 10px">

    <button mat-mini-fab mat-button color="primary" [disabled]="!checklistDataForm.valid && disableEdit"
      (click)="EditCheckListExecution()" *ngIf="IsWrite" matTooltip="Edit Execution" matTooltipClass="custom-tooltip"
      matTooltipPosition="above" tooltipPosition="top">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</ng-template>