import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FteService {
  baseUrl = environment.O3CoreApiUrl;
  kpiBaseUrl: string = environment.O3CoreApiUrl;
constructor(private http: HttpClient) { }

getGroupData(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Common/GetGroup', data);
}

getPlantsData(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Common/GetDepartments', data);
}

getMasterFrequencies(data: any): Observable<any[]> {
  return this.http.get<any[]>(this.kpiBaseUrl +'taskfrequency' )
}

getMasterProject(data:any):Observable<any[]>{
  return this.http.post<any[]>(this.kpiBaseUrl +'masterproject' ,data)
}

getMasterTopic(data:any):Observable<any[]>{
  return this.http.post<any[]>(this.kpiBaseUrl +'getAllTopicByProject' ,data)
}

GetTaskByTopicId(data:any):Observable<any[]>{
  return this.http.post<any[]>(this.kpiBaseUrl +'getAllTaskByTopic' ,data)
}

AddFTE(data:any):Observable<any[]>{
  return this.http.post<any[]>(this.kpiBaseUrl +'addFTE' ,data)
}

GetAllFTE(data:any):Observable<any[]>{
  return this.http.post<any[]>(this.kpiBaseUrl +'getAllFTE' ,data)
}

GetFTEById(data:any):Observable<any>{
  return this.http.post<any[]>(this.kpiBaseUrl +'getFTEById' ,data)
}

DeleteFTE(data:any):Observable<any[]>{
  return this.http.post<any[]>(this.kpiBaseUrl +'deleteFTE' ,data)
}

UpdateFTE(data: any): Observable <any[]> {
  return this.http.put<any[]>(this.kpiBaseUrl + 'updateFTE', data );
}
}
