import { AfterViewInit, Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MatDialog } from '@angular/material/dialog';
import { DefectManagementMasterService } from 'src/app/services/defect-management-master-service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-defect-management-master',
  templateUrl: './defect-management-master.component.html',
  styleUrls: ['./defect-management-master.component.scss']
})
export class DefectManagementMasterComponent implements OnInit, AfterViewInit {

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  panelOpenState = false;
  defecttypecolumns: string[] = ['plant', 'defect', 'order', 'action'];
  subdefectcolumns: string[] = ['plant', 'type', 'action'];
  defectstatuscolumns: string[] = ['defect'];
  defectprioritycolumns: string[] = ['plant', 'defect', 'order', 'action'];
  defectclassificationcolumns: string[] = ['plant', 'defect', 'order', 'action'];
  defectrolecolumns: string[] = ['plant', 'role', 'type', 'action'];
  rolegroupcolumns: string[] = ['plant', 'role', 'user', 'action'];

  imageSrc!: string;
  defectmainDataForm!: FormGroup;
  defecttypeDataForm!: FormGroup;
  subdefectDataForm!: FormGroup;
  defectstatusDataForm!: FormGroup;
  defectpriorityDataForm!: FormGroup;
  defectclassificationDataForm!: FormGroup;
  defecttyperoleDataForm!: FormGroup;
  mapDataForm!: FormGroup;
  rolegroupDataForm!: FormGroup;
  updateOrderprocessingData = false;
  DefectTypeformMode = true;
  SubDefectTypeformMode = true;
  defectPriorityformMode = true;
  defectClassificationformMode = true;
  defecttyperoleformMode = true;
  groupDataForm!: FormGroup;
  MachineId!: any;
  defectTypeList!: MatTableDataSource<any>;
  subDefectTypeList!: MatTableDataSource<any>;
  defectTypeDDLList!: any[];
  subDefectTypeDDLList!: any[];
  defectPriorityList = new MatTableDataSource<any>([]);
  defectClassificationList!: MatTableDataSource<any>;
  defectStatusList!: MatTableDataSource<any>;
  defectRoleGroupList!: MatTableDataSource<any>;
  defectRoleGroupsList!: any[];
  defectRoleGroupUsersList!: any[];
  defectRoleGroupsUsersList!: MatTableDataSource<any>;
  subDefectIds: any[] = new Array();
  groupUsersIds: any[] = new Array();
  breadcrumList!: any;
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  parameterType!: any[];
  moduleId!: any;
  pageId!: any;
  // Node
  UnitNode: any;


  constructor(private userManagementService: UserManagementService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    public router: Router,
    private dialog: MatDialog,
    private defectService: DefectManagementMasterService,
    private platModelService: EnterPriseGroupService,
    private spinner: NgxSpinnerService,
  ) {}

  @ViewChild('displayDefectMapping') private displayDefectMapping!: TemplateRef<any>;
  GroupList!: any[];
  PlantList!: any[];
  TapeNameDDL!: any[];
  UnitName!: string;

  isPlantGroupModelVisible = false;
  // parent node
  hasChild = (_: number, node: PlantGroupElement) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.defectmainDataForm = this.fb.group({
      group: [''],
      plant: ['']
    });

    this.defecttypeDataForm = new FormGroup({
      id: new FormControl(),
      type: new FormControl('', [Validators.required]),
      typeorder: new FormControl('', [Validators.required])
    });

    this.subdefectDataForm = this.fb.group({
      id: new FormControl(),
      type: new FormControl('', [Validators.required])
    });
    this.defectstatusDataForm = this.fb.group({
      dstatus: [''],
      dstatusorder: ['']
    });
    this.defectpriorityDataForm = this.fb.group({
      id: new FormControl('', []),
      dpriority: new FormControl('', [Validators.required]),
      dpriorityorder: new FormControl('', [Validators.required]),
    });
    this.defectclassificationDataForm = this.fb.group({
      id: new FormControl('', []),
      classification: new FormControl('', [Validators.required]),
      classificationorder: new FormControl('', [Validators.required]),
    });
    this.rolegroupDataForm = this.fb.group({
      id: new FormControl('', []),
      role: new FormControl('', [Validators.required]),
      user: new FormControl('', [Validators.required]),
    });
    this.mapDataForm = this.fb.group({
      defect: new FormControl('', [Validators.required]),
      subdefect: new FormControl('', [Validators.required]),
    });
    this.defecttyperoleDataForm = this.fb.group({
      id: new FormControl('', []),
      master: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),
    });
    this.GetMenuAccess();
    this.GetBreadcrumList();
    this.getGroups();
  }

  // ===========================Form Validation code here ====================//
  public checkError = (controlName: string, errorName: string) => {
    return this.defecttypeDataForm.controls[controlName].hasError(errorName);
  }
  public checkErrorSub = (controlName: string, errorName: string) => {
    return this.subdefectDataForm.controls[controlName].hasError(errorName);
  }

  public checkErrorSubDefectMap = (controlName: string, errorName: string) => {
    return this.mapDataForm.controls[controlName].hasError(errorName);
  }
  public checkErrorDefectPriority = (controlName: string, errorName: string) => {
    return this.defectpriorityDataForm.controls[controlName].hasError(errorName);
  }

  public checkErrorDefectClassification = (controlName: string, errorName: string) => {
    return this.defectclassificationDataForm.controls[controlName].hasError(errorName);
  }
  public checkErrorDefectTypeRole = (controlName: string, errorName: string) => {
    return this.defecttyperoleDataForm.controls[controlName].hasError(errorName);
  }
  public checkErrorDefectRoleGroup = (controlName: string, errorName: string) => {
    return this.rolegroupDataForm.controls[controlName].hasError(errorName);
  }

  getGroups(): void {
    const key = {
      UserId: ''
    };
    this.defectService.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.dropDownList;
      this.defectmainDataForm.controls.group.patchValue(this.GroupList[0].id);
      this.getPlants(this.GroupList[0].id);
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(id: any): void {
    this.defectService.getPlantsData(id).subscribe((data: any) => {
      this.PlantList = data.dropDownList;
      this.defectmainDataForm.controls.plant.patchValue(data.dropDownList[0].id);
      this.onPlantChange();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  onPlantChange(): void {
    this.spinner.show();
    this.getTypeList();
    this.getSubTypeList();
    this.getDefectDDL();
    this.getSubDefectDDL();
    this.getDefectPriorityList();
    // this.getDefectClassificationList();
    // this.getDefectRoleList();
    // this.getRoleGroups();
    // this.getRoleGroupUsers();
    // this.getRoleGroupUsersList();
    // this.getDefectStatusData();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }

  // ================================== Defect Type ======================================== //

  saveDefectType(): any {
    const key = {
      id: this.defecttypeDataForm.value.id,
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant,
      name: this.defecttypeDataForm.value.type,
      order: this.defecttypeDataForm.value.typeorder,
      userId: 0
    };
    this.spinner.show();
    if (this.defecttypeDataForm.value.id === 0 || this.defecttypeDataForm.value.id === null && this.defecttypeDataForm.valid) {
      this.defectService.insertDefectType(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.toaster.success('Defect Type Master save successfully');
          this.defecttypeDataForm.reset();
          this.getTypeList();
          this.spinner.hide();
        }
        else{
          this.spinner.hide();
          this.toaster.warning('Warning', 'DefectType Already Exists!')
        }
      }, (error: any) => {
        this.spinner.hide();
        this.toaster.error('error', error.ErrorMessage);
      });
    } else if (this.defecttypeDataForm.valid) {
      this.defectService.updateDefectType(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.toaster.success('Defect Type Master updated successfully');
          this.defecttypeDataForm.reset();
          this.getTypeList();
          this.spinner.hide();
        }
      }, (error: any) => {
        this.toaster.error('error', error.message);
      });
    }
    this.DefectTypeformMode = true;
  }
  resetDefectType(): void {
    this.defecttypeDataForm.reset();
    this.DefectTypeformMode = true;
  }
  saveSubDefectMappingType() {
    const key = {
      defectTypeId: this.mapDataForm.value.defect,
      subDefectTypeIds: this.subDefectIds,
      userId: 0
    };
    if (this.mapDataForm.valid) {
      this.defectService.insertMappingSubDefectType(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.toaster.success('Mapping Defect Type Master save successfully');
          this.mapDataForm.reset();
        }
      }, (error: any) => {
        this.toaster.error('error', error.message);
      });
    }
  }

  getTypeList() {
    const key = {
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant
    };
    this.defectService.getDefectTypeData(key).subscribe((response: any) => {
      if (response.HasError === false) {
        this.defectTypeList = response.GetDefectTypeList;
        this.defectTypeList = new MatTableDataSource<any>(response.GetDefectTypeList);
        this.getPaginator(this.defectTypeList, 0);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  editDefctType(element: any) {
    this.DefectTypeformMode = false;
    this.defecttypeDataForm.get('id')?.setValue(element.id);
    this.defecttypeDataForm.get('type')?.setValue(element.name);
    this.defecttypeDataForm.get('typeorder')?.setValue(element.order);
  }

  deleteDefctType(element: any) {
    this.defectService.deleteDefectType(element.id).subscribe((response: any) => {
      if (response.HasError === false) {
        this.showPopupMsg(response.ErrorMessage)
        this.getTypeList();
        this.resetDefectType();
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  // ============================================================ Defect Sub type===================================//
  saveSubDefectType() {
    const key = {
      id: this.subdefectDataForm.value.id,
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant,
      name: this.subdefectDataForm.value.type,
      userId: 0
    };
    const chkFrequency = this.subDefectTypeList.data.find((fre: any) => {
      const a = fre.name.trim().toLowerCase();
      const b = this.subdefectDataForm.value.type.trim().toLowerCase();
      return a == b;
    });
    if (chkFrequency) {
      this.toaster.warning('Warning', 'This Sub Defect Already Exist');
      return;
    }
    if (this.subdefectDataForm.value.id === 0 || this.subdefectDataForm.value.id === null && this.subdefectDataForm.valid) {
      this.defectService.insertSubDefectType(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.toaster.success('Sub Defect Type Master save successfully');
          this.subdefectDataForm.reset();
          this.getSubTypeList();
        }
      }, (error: any) => {
        this.toaster.error('error', error.message);
      });
    } else if (this.subdefectDataForm.valid) {
      this.defectService.updateSubDefectType(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.toaster.success('Sub Defect Type Master updated successfully');
          this.subdefectDataForm.reset();
          this.getSubTypeList();
        }
      }, (error: any) => {
        this.toaster.error('error', error.message);
      });
    }
    this.SubDefectTypeformMode = true;
  }
  resetsubDefect(): void {
    this.subdefectDataForm.reset();
    this.SubDefectTypeformMode = true;
  }
  getSubTypeList(): void {
    const key = {
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant
    };
    this.defectService.getDefectSubTypeData(key).subscribe((response: any) => {
      if (response.HasError === false) {
        this.subDefectTypeList = response.GetDefectTypeList;
        this.subDefectTypeList = new MatTableDataSource<any>(response.GetDefectTypeList);
        this.getPaginator(this.subDefectTypeList, 1);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  editDefctSubType(element: any) {
    this.SubDefectTypeformMode = false;
    this.subdefectDataForm.get('id')?.setValue(element.id);
    this.subdefectDataForm.get('type')?.setValue(element.name);
  }

  deleteDefctSubType(element: any) {
    this.spinner.show();
    this.defectService.deleteDefectSubType(element.id).subscribe((response: any) => {
      if (response.HasError === false) {
        this.spinner.hide();
        this.showPopupMsg(response.ErrorMessage);
        this.getSubTypeList();
        this.resetsubDefect();
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
      this.spinner.hide();
    });
  }

  // ============================================================ Defect Priority ===================================//
  saveDefectPriority() {
    this.spinner.show();
    const key = {
      id: this.defectpriorityDataForm.value.id,
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant,
      name: this.defectpriorityDataForm.value.dpriority,
      order: this.defectpriorityDataForm.value.dpriorityorder,
      userId: 0
    };
    if (this.defectpriorityDataForm.value.id === 0 ||
      this.defectpriorityDataForm.value.id === '' || this.defectpriorityDataForm.value.id === null && this.defectpriorityDataForm.valid) {
      if (this.defectPriorityList.data.length < 5) {
        this.defectService.insertDefectPriority(key).subscribe((response: any) => {
          if (response.HasError === false) {
            this.spinner.hide();
            this.toaster.success('Defect Priority Master data save successfully');
            this.defectpriorityDataForm.reset();
            this.getDefectPriorityList();
          }
          else{
            this.spinner.hide();
            this.toaster.warning('Warning','Priority Already Exists!')
          }
        }, (error: any) => {
          this.toaster.error('error', error.message);
          this.spinner.hide();
        });
      }
      else {
        this.toaster.error('error', 'Cannot add more than 5 priority');
      }
    }
    else if (this.defectpriorityDataForm.valid) {
      this.defectService.updatePriority(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.spinner.hide();
          this.toaster.success('defect Priority Master data updated successfully');
          this.defectpriorityDataForm.reset();
          this.getDefectPriorityList();
        }
      }, (error: any) => {
        this.spinner.hide();
        this.toaster.error('error', error.message);
      });
    }
    this.defectPriorityformMode = true;
  }

  resetDefectpriority(): void {
    this.defectpriorityDataForm.reset();
    this.defectPriorityformMode = true;
  }

  getDefectPriorityList(): void {
    const key = {
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant
    };
    this.defectService.getDefectPriorityData(key).subscribe((response: any) => {
      if (response.HasError === false) {
        this.defectPriorityList.data = response.GetDefectTypeList;
        this.getPaginator(this.defectPriorityList, 2);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  ngAfterViewInit(): void {
    this.defectPriorityList.paginator = this.paginator.toArray()[3];
    this.defectPriorityList.sort = this.sort.toArray()[3];
  }

  editDefctPriority(element: any) {
    this.defectPriorityformMode = false;
    this.defectpriorityDataForm.get('id')?.setValue(element.id);
    this.defectpriorityDataForm.get('dpriority')?.setValue(element.name);
    this.defectpriorityDataForm.get('dpriorityorder')?.setValue(element.order);
  }

  deleteDefctPriority(element: any) {
    this.defectService.deletePriority(element.id).subscribe((response: any) => {
      if (response.HasError === false) {
        this.showPopupMsg(response.ErrorMessage);
        this.getDefectPriorityList();
        this.resetDefectpriority();
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  // ===============================================End Status Start =================================//
  getDefectStatusData() {
    const key = {
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant,
    };
    this.defectService.getDefectStatusData(key).subscribe((response: any) => {
      if (response.HasError === false) {
        this.defectStatusList = response.GetDefectTypeList;
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  // ============================================================ Defect classification ===================================//
  saveDefectClassification() {
    const key = {
      id: this.defectclassificationDataForm.value.id,
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant,
      name: this.defectclassificationDataForm.value.classification,
      order: this.defectclassificationDataForm.value.classificationorder,
      userId: 0
    };

    if (this.defectclassificationDataForm.value.id === '' || this.defectclassificationDataForm.value.id === 0 || this.defectclassificationDataForm.value.id === null && this.defectclassificationDataForm.valid) {
      this.defectService.insertDefectClassification(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.toaster.success('Defect Classification Master data save successfully');
          this.defectclassificationDataForm.reset();
          this.getDefectClassificationList();
        }
      }, (error: any) => {
        this.toaster.error('error', error.message);
      });
    } else if (this.defectclassificationDataForm.valid) {
      this.defectService.updateClassification(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.toaster.success('defect classification Master data updated successfully');
          this.defectclassificationDataForm.reset();
          this.getDefectClassificationList();
        }
      }, (error: any) => {
        this.toaster.error('error', error.message);
      });
    }
    this.defectClassificationformMode = true;
  }
  resetDefectclassification(): void {
    this.defectclassificationDataForm.reset();
    this.defectClassificationformMode = true;
  }
  getDefectClassificationList() {
    const key = {
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant
    };
    this.defectService.getDefectClassificationData(key).subscribe((response: any) => {
      if (response.HasError === false) {
        this.defectClassificationList = response.GetDefectTypeList;
        this.defectClassificationList = new MatTableDataSource<any>(response.GetDefectTypeList);
        this.getPaginator(this.defectClassificationList, 7);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  editDefctClassification(element: any) {
    this.defectClassificationformMode = false;
    this.defectclassificationDataForm.get('id')?.setValue(element.id);
    this.defectclassificationDataForm.get('classification')?.setValue(element.name);
    this.defectclassificationDataForm.get('classificationorder')?.setValue(element.order);
  }

  deleteDefctClassification(element: any) {
    this.defectService.deleteClassification(element.id).subscribe((response: any) => {
      if (response.HasError === false) {
        this.toaster.success('Defect Classification deleted successfully');
        this.getDefectClassificationList();
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  // ===============================================End =================================//

  // ============================================================ Defect Type Role Group ===================================//
  saveDefectTypeRoleGroup() {
    const key = {
      id: this.defecttyperoleDataForm.value.id,
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant,
      defectTypeId: this.defecttyperoleDataForm.value.master,
      name: this.defecttyperoleDataForm.value.role,
      userId: 0,
      masterTypeName: ''
    };

    // tslint:disable-next-line:max-line-length
    if (this.defecttyperoleDataForm.valid && this.defecttyperoleDataForm.value.id === '' || this.defecttyperoleDataForm.value.id === 0 || this.defecttyperoleDataForm.value.id === null) {
      this.defectService.insertDefectRoleGroup(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.toaster.success('Defect Role group data save successfully');
          this.defecttyperoleDataForm.reset();
          this.getDefectRoleList();
        }
      }, (error: any) => {
        this.toaster.error('error', error.message);
      });
    } else if (this.defecttyperoleDataForm.valid) {
      this.defectService.updateDefectRoleGroup(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.showPopupMsg(response.ErrorMessage);
          // this.toaster.success('defect Role group data updated successfully');
          this.defecttyperoleDataForm.reset();
          this.getDefectRoleList();
        }
      }, (error: any) => {
        this.toaster.error('error', error.message);
      });
    }
    this.defecttyperoleformMode = true;
  }
  resetDefecttyperole(): void {
    this.defecttyperoleDataForm.reset();
    this.defecttyperoleformMode = true;
  }
  getDefectRoleList() {
    const key = {
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant
    };
    this.defectService.getDefectRoleData(key).subscribe((response: any) => {
      if (response.HasError === false) {
        this.defectRoleGroupList = response.GetDefectTypeList;
        this.defectRoleGroupList = new MatTableDataSource<any>(response.GetDefectTypeList);
        this.getPaginator(this.defectRoleGroupList, 3);
      }
      this.getRoleGroups();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  editDefctRole(element: any) {
    this.defecttyperoleformMode = false;
    this.defecttyperoleDataForm.get('id')?.setValue(element.id);
    this.defecttyperoleDataForm.get('master')?.setValue(element.mdTypeId);
    this.defecttyperoleDataForm.get('role')?.setValue(element.roleName);
  }

  deleteDefctRole(element: any) {
    this.defectService.deleteDefectRoleGroup(element.id).subscribe((response: any) => {
      if (response.HasError === false) {
        this.toaster.success('Defect Role group deleted successfully');
        this.getDefectRoleList();
        this.getRoleGroups();
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  // ===============================================End =================================//

  // ============================================================ Defect Role Group users  ===================================//

  deleteMappingRoleGroupMasterData(element: any): void {
    this.defectService.deleteMappingRoleGroupMasterData(element.roleGroupId).subscribe((response: any) => {
      if (response.HasError === false) {
        this.toaster.success('Defect Role group deleted successfully');
        this.getRoleGroupUsersList();
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getRoleGroups() {
    const key = {
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant
    };
    this.defectService.getDefectRoleGroups(key).subscribe((response: any) => {
      if (response.HasError === false) {
        this.defectRoleGroupsList = response.dropDownList;
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getRoleGroupUsers() {
    const key = {
      egId: this.defectmainDataForm.value.group,
    };
    this.defectService.getDefectRoleGroupUsers(key.egId).subscribe((response: any) => {
      if (response.HasError === false) {
        this.defectRoleGroupUsersList = response.dropDownList;
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  saveRoleGroupUsers() {
    debugger
    const key = {
      id: this.rolegroupDataForm.value.id,
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant,
      roleGroupId: this.rolegroupDataForm.value.role,
      userIds: this.rolegroupDataForm.value.user,
      // userIds : this.groupUsersIds,
      userId: 0
    };

    // tslint:disable-next-line:max-line-length
    if (this.rolegroupDataForm.valid && this.rolegroupDataForm.value.id === '' || this.rolegroupDataForm.value.id === 0 || this.rolegroupDataForm.value.id === null) {
      this.defectService.insertDefectRoleGroupUser(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.toaster.success('Defect Role group data save successfully');
          this.defecttyperoleDataForm.reset();
          this.getRoleGroupUsersList();
        }
      }, (error: any) => {
        this.toaster.error('error', error.message);
      });
    } else if (this.defecttyperoleDataForm.valid) {
      this.defectService.updateDefectRoleGroup(key).subscribe((response: any) => {
        if (response.HasError === false) {
          this.toaster.success('defect Role group data updated successfully');
          this.defecttyperoleDataForm.reset();
          this.getDefectRoleList();
        }
      }, (error: any) => {
        this.toaster.error('error', error.message);
      });
    }
  }
  resetroleGroup(): void {
    this.defecttyperoleDataForm.reset();
  }

  getRoleGroupUsersList() {
    const key = {
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant
    };
    this.defectService.getDefectRoleGroupData(key).subscribe((response: any) => {
      if (response.HasError === false) {
        this.defectRoleGroupsUsersList = response.GetRoleGroupUsersList;
        this.defectRoleGroupsUsersList = new MatTableDataSource<any>(response.GetRoleGroupUsersList);
        this.getPaginator(this.defectRoleGroupsUsersList, 4);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  editRoleGroupUsers(element: any) {
    this.defecttyperoleDataForm.get('id')?.setValue(element.id);
    this.defecttyperoleDataForm.get('master')?.setValue(element.mdType);
    this.defecttyperoleDataForm.get('role')?.setValue(element.roleName);
  }

  deleteDefctRoleGroupUsers(element: any) {
    this.defectService.deleteClassification(element.id).subscribe((response: any) => {
      if (response.HasError === false) {
        this.toaster.success('Defect Role group deleted successfully');
        this.getRoleGroupUsersList();
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  // ===============================================End =================================//
  getDefectDDL() {
    const key = {
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant
    };
    this.defectService.getDefectTypeDDL(key).subscribe((response: any) => {
      if (response.HasError === false) {
        this.defectTypeDDLList = response.dropDownList;
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getSubDefectDDL() {
    const key = {
      egId: this.defectmainDataForm.value.group,
      plantId: this.defectmainDataForm.value.plant
    };
    this.defectService.getSubDefectTypeDDL(key).subscribe((response: any) => {
      if (response.HasError === false) {
        this.subDefectTypeDDLList = response.dropDownList;
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  // multiple select
  change(event: any) {
    if (event.source.selected) {
      this.subDefectIds.push(event.source.value.toString());
    } else {
      this.subDefectIds.forEach((obj: any, index: number) => {
        if (obj === event.source.value) {
          this.subDefectIds.splice(index, 1);
        }
      });
    }
  }

  changeUsers(event: any) {
    if (event.source.selected) {
      this.groupUsersIds.push(event.source.value.toString());
    } else {
      this.groupUsersIds.forEach((obj: any, index: number) => {
        if (obj === event.source.value) {
          this.groupUsersIds.splice(index, 1);
        }
      });
    }
  }
  // group dropdown



  openModal(): void {
    this.dialog.open(this.displayDefectMapping, { height: '50%', width: '60%' });
    this.getDefectDDL();
    this.getSubDefectDDL();
  }



  // close model
  closePlantGroupModel() {
    this.isPlantGroupModelVisible = false;
  }

  // Waste HtmlLable



  // menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }


  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }

  // common
  getPaginator(dataSource: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataSource.paginator = this.paginator.toArray()[index];
      dataSource.sort = this.sort.toArray()[index];
    }, 1000);
  }

  applyFilterForDefectType(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.defectTypeList.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForSubDefectType(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.subDefectTypeList.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForDefectPriorityType(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.defectPriorityList.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForDefectClassification(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.defectClassificationList.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForDefectRoleGroup(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.defectRoleGroupList.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForDefectRoleGroupUser(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.defectRoleGroupsUsersList.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForDefectStatus(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.defectStatusList.filter = filterValue.trim().toLowerCase();
  }

  resetForm(form: FormGroup, value: any) {
    form.get(value)?.setValue('');
  }
  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
      this.toaster.success('Success', msg.substring(3, msg.length));
    }
    else {
      this.toaster.error('Error', msg.substring(3, msg.length));
    }
  }
  deleteRecord(element: any, panelType: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete ' + panelType + ' Record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        if (panelType === 'Defect Type') {
          this.deleteDefctType(element);
        }
        else if (panelType === 'Defect Sub Type') {
          this.deleteDefctSubType(element);
        }
        else if (panelType === 'Defect Priority') {
          this.deleteDefctPriority(element);
        }
        else if (panelType === 'Classifcation') {
          this.deleteDefctClassification(element);
        }
        else if (panelType === 'Defect Role') {
          this.deleteDefctRole(element);
        }
        else if (panelType === 'Role Users') {
          this.deleteMappingRoleGroupMasterData(element);
        }

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Record is safe :)', 'error');
      }
    });
  }
}
