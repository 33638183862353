import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';

import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { AgGridModule } from 'ag-grid-angular';
import { MatSelectFilterModule } from 'mat-select-filter';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ToastModule } from 'primeng/toast';
import { CheckboxRenderer } from '../CheckboxRenderer';
import { MatCheckboxRendererComponent } from '../mat-checkbox-renderer';
import { MatSelectRendererComponent } from '../mat-selectlist-renderer';
import { MaterialModule } from '../material-module';
import { OpmsQualityRoutingModule } from './opm-quality-routing.module';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TooltipModule } from 'primeng/tooltip';
import { OpmsBoxReceivingComponent } from '../OPMS-Glass/opms-box-receiving/opms-box-receiving.component';
import { OpmsDailyConsuptionComponent } from '../OPMS-Glass/opms-daily-consuption/opms-daily-consuption.component';
import { OpmsDataComponent } from '../OPMS-Glass/opms-data/opms-data.component';
import { OPMSMainComponent } from '../OPMS-Glass/opms-main/opms-main.component';
import { OpmsMonthlyStockComponent } from '../OPMS-Glass/opms-monthly-stock/opms-monthly-stock.component';
import { OpmsDbComponent } from './opms-db/opms-db.component';
import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';
import solidGauge from 'highcharts/modules/solid-gauge.src';
import highchartsTreemap from 'highcharts/modules/treemap.src';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartsModule } from 'ng2-charts';
import { HomeModule } from '../screens/home.module';
import { OpmsReportsComponent } from '../OPMS-Glass/opms-reports/opms-reports.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { GlassPpcLiveStackerdataComponent } from './glass-ppc-live-stackerdata/glass-ppc-live-stackerdata.component';
import { OpmsShiftReportsComponent } from './opms-shift-reports/opms-shift-reports.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { StringToArray } from '../CustomPipes/stringToArray.pipe';
import { YieldDetailComponent } from './opms-db/YieldDetail/yield-detail/yield-detail.component';

export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more, solidGauge, highchartsTreemap];
}
@NgModule({
  declarations: [
    OpmsDbComponent,
    OPMSMainComponent,
    OpmsDataComponent,
    OpmsDailyConsuptionComponent,
    OpmsReportsComponent,
    OpmsMonthlyStockComponent,
    OpmsBoxReceivingComponent,
    GlassPpcLiveStackerdataComponent,
    OpmsShiftReportsComponent,
    StringToArray,
    YieldDetailComponent
  ],
  imports: [
    NgxSpinnerModule,
    MatSelectFilterModule,
    CommonModule,
    OpmsQualityRoutingModule,
    MaterialModule,
    MatMenuModule,
    MatRadioModule,
    MatButtonModule,
    MatCheckboxModule,
    NzTimePickerModule,
    FlexLayoutModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    ChartsModule,
    HomeModule,
    AgGridModule.withComponents([
      CheckboxRenderer,
      MatCheckboxRendererComponent,
      MatSelectRendererComponent,
    ]),
    MatGridListModule,
    DropdownModule,
    CardModule,
    InputNumberModule,
    InputSwitchModule,
    CalendarModule,
    DialogModule,
    TieredMenuModule,
    MultiSelectModule,
    InputTextModule,
    SidebarModule,
    ButtonModule,
    ContextMenuModule,
    PanelModule,
    ToastModule,
    OrganizationChartModule,
    TableModule,
    SliderModule,
    TooltipModule,
    RadioButtonModule,
    ColorPickerModule,
    MatTooltipModule,
    MatProgressButtonsModule.forRoot(),
    NzDatePickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules }, // add as factory to your providers
  ],
})
export class OpmsQualityModule {}
