export const rolesForMaterialConsumptionScreen = {
    GlassManufacturingRole: 'tager_fg',
    GlassLaminationRole: 'tager_lm',
    GlassOfflineRole: 'tager_ol',
    RoleCanDeleteData: 'tag-delet',
    RoleCanPushDataToSaned: 'tag-push to saned'
    // GlassManufacturingRole: 'glass - admin',
    // GlassLaminationRole: 'section manager',
    // GlassOfflineRole: 'glass-am'
}
export const PVBColorTypes = {
    PVBColor: 'PVBColor',
    CoatedGlassColor: 'CoatedGlassColor'
}
export const GlassPOType = 'fg';
export const LaminationPOType = 'lm';
export const OfflinePOType = 'ol' 
export const GlassStandards = {
    JIS: 'JIS',
    EN: 'EN',
    ASTM: 'ASTM'
}