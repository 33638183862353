import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  maintenanceData: any = [];
  breakdownData: any = [];
  ShortStopsData: any = [];
  ShortStopsOEEChart: Chart[] = [];
  breakdownOEEChart: Chart[] = [];
  maintenanceOEEChart: Chart[] = [];
  breadcrumList: any = [];
  constructor(private toaster: ToastrService, private usermanagementService: UserManagementService,
    public router: Router) { }

  ngOnInit(): void {
    this.drawMaintenance();
    this.drawBreakdown();
    this.drawShortStops();
  }

  drawMaintenance() {
    this.maintenanceData = [
      {
        title: 'Breakdowns', categories: ['Process Failure', 'Breakdown', 'Short Stops'],
        data: [
          { y: 7691, color: '#14b1ff' }, { y: 3800, color: '#14b1ff' }, { y: 1152, color: '#14b1ff' }]
      },
      {
        title: 'Operational Waterfall', categories: ['QC...', 'Labe...', 'Start...', 'Mac...', 'Qc...', 'Cavit...', 'Labe...', 'Cont...', 'Labe...', 'Start...', 'Botti...', 'Cutti...', 'Die...', 'Labe...', 'Id,O...', 'Die...', 'Oval...', 'Che...', 'Labe...', 'Side...', 'Start...', 'Hard...', 'Paris...', 'Purg...', 'No...', 'Top...', 'Swin...'],
        opacity: 0.2,
        data: [
          { y: 145, color: '#dc3545',opacity: 0.2 }, { y: 134, color: '#dc3545',opacity: 0.2 }, { y: 131, color: '#dc3545',opacity: 0.2 }, { y: 104, color: '#dc3545',opacity: 0.2 },
          { y: 62, name: '%', color: '#dc3545', opacity: 0.2 }, { y: 47, color: '#dc3545',opacity: 0.2 }, { y: 40, color: '#dc3545',opacity: 0.2 }, { y: 39, color: '#dc3545',opacity: 0.2 }, { y: 38, color: '#dc3545',opacity: 0.2 }, { y: 37, name: '%', color: '#dc3545',opacity: 0.2 }, { y: 34, color: '#dc3545',opacity: 0.2 }, { y: 34, color: '#dc3545',opacity: 0.2 }, { y: 34, color: '#dc3545',opacity: 0.2 }, { y: 34, color: '#dc3545',opacity: 0.2 },
          { y: 33, name: '%', color: '#dc3545',opacity: 0.2 }, { y: 32, color: '#dc3545',opacity: 0.2 }, { y: 28, color: '#dc3545',opacity: 0.2 }, { y: 27, color: '#dc3545',opacity: 0.2 }, { y: 25, color: '#dc3545',opacity: 0.2 }, { y: 23, name: '%', color: '#dc3545',opacity: 0.2 }, { y: 22, name: '%', color: '#dc3545',opacity: 0.2 }, { y: 20, name: '%', color: '#dc3545',opacity: 0.2 }, { y: 20, name: '%', color: '#dc3545',opacity: 0.2 }, { y: 20, name: '%', color: '#dc3545',opacity: 0.2 },
          { y: 19, name: '%', color: '#dc3545',opacity: 0.2 }, { y: 19, name: '%', color: '#dc3545',opacity: 0.2 }, { y: 17, name: '%', color: '#dc3545',opacity: 0.2 }]
      }
    ];
    if (this.maintenanceData && this.maintenanceData.length) {
      for (const maintenanceOEE of this.maintenanceData) {
        const chart = new Chart({
          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 45
            }
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: maintenanceOEE.categories,
            crosshair: true
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              borderWidth: 0,
              dataLabels: {
                enabled: true,
              },
              point: {
                events: {
                  click: this.getYTDHeaderBasedValues.bind(this)
                }
              }
            }
          },
          series: [
            {
              name: maintenanceOEE.title,
              type: 'column',
              data: maintenanceOEE.data
            }
          ]
        });
        this.maintenanceOEEChart.push(chart);
      }
    }
  }

  drawBreakdown() {
    this.breakdownData = [
      {
        title: 'Breakdown Categories', categories: ['OPERA...', 'TSD', 'Mecha...', 'Electrical', 'Tool Te...', 'Chang...', 'Utility...', 'No Raw...'],
        data: [
          { y: 1500, color: '#f5c80f' }, { y: 1100, color: '#f5c80f' }, { y: 500, color: '#f5c80f' }, { y: 300, color: '#f5c80f' },
          { y: 200, color: '#f5c80f' }, { y: 100, color: '#f5c80f' }, { y: 0, color: '#f5c80f' }, { y: 0, color: '#f5c80f' }]
      },
      {
        title: 'Mechanical Waterfall', categories: ['Mac...', 'Leak...', 'Botti...', 'Und...', 'Upp...', 'Air b...', 'Paris...', 'Hydrn...', 'Cutti...', 'Trim...', 'Hydrn...', 'Labe...', 'Labe...', 'Con...', 'Bag...', 'Mou...', 'Labe...', 'Low...', 'Botti...', 'Hydrn...', 'Labe...', 'Pre...', 'Strip...', 'Grin...', 'Bag...', 'Grin...', 'Mac...'],
        data: [
          { y: 78, color: 'rgba(255,0,0,0.2)' }, { y: 28, color: '#dc3545' }, { y: 23, color: '#dc3545' }, { y: 23, color: '#dc3545' },
          { y: 22, name: '%', color: '#dc3545' }, { y: 21, color: '#dc3545' }, { y: 20, color: '#dc3545' }, { y: 19, color: '#dc3545' }, { y: 18, color: '#dc3545' }, { y: 17, name: '%', color: '#dc3545' }, { y: 16, color: '#dc3545' }, { y: 14, color: '#dc3545' }, { y: 13, color: '#dc3545' },
          { y: 12, name: '%', color: '#dc3545' }, { y: 11, color: '#dc3545' }, { y: 10, color: '#dc3545' }, { y: 9, color: '#dc3545' }, { y: 9, name: '%', color: '#dc3545' }, { y: 8, name: '%', color: '#dc3545' }, { y: 8, name: '%', color: '#dc3545' }, { y: 8, name: '%', color: '#dc3545' }, { y: 8, name: '%', color: '#dc3545' },
          { y: 8, name: '%', color: '#dc3545' }, { y: 7, name: '%', color: '#dc3545' }, { y: 6, name: '%', color: '#dc3545' }, { y: 6, color: '#dc3545' }, { y: 6, color: '#dc3545' }]
      }
    ];
    if (this.breakdownData && this.breakdownData.length) {
      for (const breakdownOEE of this.breakdownData) {
        const chart = new Chart({
          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 45
            }
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: breakdownOEE.categories,
            crosshair: true
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              borderWidth: 0,
              dataLabels: {
                enabled: true,
              },
              point: {
                events: {
                  click: this.getYTDHeaderBasedValues.bind(this)
                }
              }
            }
          },
          series: [
            {
              name: breakdownOEE.title,
              type: 'column',
              data: breakdownOEE.data
            }
          ]
        });
        this.breakdownOEEChart.push(chart);
      }
    }
  }

  drawShortStops() {
    this.ShortStopsData = [
      {
        title: 'Short Stops', categories: ['OD', 'TSD', 'Mac', 'Ele', 'Clr', 'Tax', 'Utility'],
        data: [
          { y: 811.10, color: '#14b1ff' }, { y: 413.0, color: '#0f0fd1' }, { y: 84.0, color: '#f79945' }, { y: 61.0, color: '#8c0697' },
          { y: 37.0, color: '#f863ec' }, { y: 12.0, color: '#be5ef8' }, { y: 4.0, color: '#f5e10a' }]
      },
      {
        title: 'Electric Waterfall', categories: ['Mac...', 'Leak...', 'Botti...', 'Und...', 'Upp...', 'Air b...', 'Paris...', 'Hydrn...', 'Cutti...', 'Trim...', 'Hydrn...', 'Labe...', 'Labe...', 'Con...', 'Bag...', 'Mou...', 'Labe...', 'Low...', 'Botti...', 'Hydrn...', 'Labe...', 'Pre...', 'Strip...', 'Grin...', 'Bag...', 'Grin...', 'Mac...'],
        data: [
          { y: 40, color: '#dc3545' }, { y: 26, color: '#dc3545' }, { y: 19, color: '#dc3545' }, { y: 19, color: '#dc3545' },
          { y: 15, name: '%', color: '#dc3545' }, { y: 15, color: '#dc3545' }, { y: 15, color: '#dc3545' }, { y: 14, color: '#dc3545' }, { y: 12, color: '#dc3545' }, { y: 12, name: '%', color: '#dc3545' }, { y: 12, color: '#dc3545' }, { y: 10, color: '#dc3545' }, { y: 9, color: '#dc3545' }, { y: 8, color: '#dc3545' },
          { y: 8, name: '%', color: '#dc3545' }, { y: 7, color: '#dc3545' }, { y: 6, color: '#dc3545' }, { y: 6, color: '#dc3545' }, { y: 5, name: '%', color: '#dc3545' }, { y: 5, name: '%', color: '#dc3545' }, { y: 5, name: '%', color: '#dc3545' }, { y: 5, name: '%', color: '#dc3545' }, { y: 4, name: '%', color: '#dc3545' },
          { y: 4, name: '%', color: '#dc3545' }, { y: 4, name: '%', color: '#dc3545' }, { y: 4, name: '%', color: '#dc3545' }, { y: 3, color: '#dc3545' }]
      }
    ];
    if (this.ShortStopsData && this.ShortStopsData.length) {
      for (const shortStopsOEE of this.ShortStopsData) {
        const chart = new Chart({
          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 45
            }
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: shortStopsOEE.categories,
            crosshair: true
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              borderWidth: 0,
              dataLabels: {
                enabled: true,
              },
              point: {
                events: {
                  click: this.getYTDHeaderBasedValues.bind(this)
                }
              }
            }
          },
          series: [
            {
              name: shortStopsOEE.title,
              type: 'column',
              data: shortStopsOEE.data
            }
          ]
        });
        this.ShortStopsOEEChart.push(chart);
      }
    }
  }

  getYTDHeaderBasedValues() { }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

}
