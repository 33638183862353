<section class="home-content">
  <ul class="breadcrumb">
    <li class="breadcrumb-item">
      <a class="breadcrumb-link" routerLink="/home">Home</a>
    </li>
    <li class="breadcrumb-item">
      <a class="breadcrumb-link" routerLink="/administration"
        >03 Administration</a
      >
    </li>
    <!-- <li class="breadcrumb-item">
        <a class="breadcrumb-link" routerLink="/kpisadmin">KPI's Admin</a>
      </li> 
      <li class="breadcrumb-item">
        <a class="breadcrumb-link" routerLink="/kpimaster">KPI's Master</a>
      </li>  -->
  </ul>
  <mat-card>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-6 p-lg-8 p-xl-8">
        <mat-card-title>Master Data Settings</mat-card-title>
      </div>
      <div class="p-col-12 p-md-6 p-lg-4 p-xl-4">
        <!-- <form [formGroup]="searchMasterForm" style="float: right">
          <p-dropdown
            [filter]="true"
            (onChange)="getMasterData($event)"
            [ngClass]="{
              'is-invalid':
                searchMasterForm.get('plant')?.errors &&
                searchMasterForm.get('plant')?.touched
            }"
            [options]="plants"
            formControlName="plant"
            placeholder="Select a plant"
            optionLabel="ptDesc"
            [showClear]="true"
          ></p-dropdown>
          <p
            class="invalid-feedback"
            *ngIf="
              searchMasterForm.get('plant')?.touched &&
              searchMasterForm.get('plant')?.hasError('required')
            "
          >
            * please select plant
          </p>
        </form> -->
      </div>
    </div>
  </mat-card>
  <mat-card class="p-mt-2 p-p-0">
    <!-- Table -->
    <div class="p-grid p-fluid">
      <div class="p-xl-12 p-lg-12 p-md-12 p-col-12"  >
        <mat-accordion class="accordian-wrapper" multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header class="disable-ripple">
              <mat-panel-title class="header-title">
                Product Detail
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-productdetail [pDetail]="productdetail"></app-productdetail>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="p-xl-12 p-lg-12 p-md-12 p-col-12"  >
        <app-productproperty [pProperty]="productproperty"></app-productproperty>
      </div>
      <div class="p-xl-12 p-lg-12 p-md-12 p-col-12" >
        <app-productspecs [pSpecs]="productspecs"></app-productspecs>
      </div>
    </div>
  </mat-card>
</section>
