import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Department } from '../models/department';
import { Driver } from '../models/driver';
import { Frequency } from '../models/frequency';
import { Group } from '../models/group';
import { DayOff } from '../models/dayOff';
import { Mastershift } from '../models/mastershift';
import { Kpi } from '../models/kpi';
import { Kpireason } from '../models/kpireason';
import { MasterFrequency } from '../models/MasterFrequency';
import { OneFrequency } from '../models/oneFrequency';
import { OnePillar } from '../models/onePillar';
import { OneTool } from '../models/oneTool';
import { OneTrend } from '../models/oneTrend';
import { OneUom } from '../models/oneUom';
import { Pillar } from '../models/pillar';
import { Plant } from '../models/plant';
import { Plantbygroup } from '../models/plantbygroup';
import { Reason } from '../models/reason';
import { Success } from '../models/success';
import { SuccessMessage } from '../models/successMessage';
import { Tool } from '../models/tool';
import { Trend } from '../models/trend';
import { Uom } from '../models/uom';
import { FourM } from '../models/fourM';
import { Assembly } from '../models/assembly';
import { SubAssembly } from '../models/subAssembly';
import { Components } from '../models/component';
import { UserPlant } from '../models/userPlant';
import { IKey } from 'selenium-webdriver';
import { OneDriver } from '../models/models2/oneDriver';
import { ProductLine } from '../models/models2/ProductLine';
import { ProductUnit } from '../models/models2/ProductUnit';


@Injectable({
  providedIn: 'root',
})
export class KpimasterService {  
  O3CoreApiUrl: string = environment.O3CoreApiUrl;
  dayOffList: number[] = [];

  constructor(private http: HttpClient) { }

  getGroups(): Observable<Group[]> {
    return this.http.get<Group[]>(this.O3CoreApiUrl + 'Common/GetAllGroups');
  }

  getAllPlants(): Observable<Plant[]> {

    return this.http.get<Plant[]>(this.O3CoreApiUrl + 'Plant/GetAllPlants');
  }
  async getuser(data: any): Promise<any> {
    return await this.http.post<any>(this.O3CoreApiUrl + 'UserRole/GetUsersByRoles', data).toPromise();
  }
  GetMenuAccess(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'Account/GetMenuAccess', data);
  }
  // getPlants(groupId: any): Observable<Plantbygroup[]> {

  //   return this.http.post<Plantbygroup[]>(
  //     this.kpibaseUrl + 'plantsbygroup',
  //     groupId,
  //   );
  // }
  getmasterline(data: any): Observable<ProductLine[]> {

    return this.http.post<ProductLine[]>(this.O3CoreApiUrl + 'Machine/GetMasterLines', data);
  }
  getProductUnit(data: any): Observable<ProductUnit[]> {

    return this.http.post<ProductUnit[]>(this.O3CoreApiUrl + 'GetUnitsByLine', data);
  }
  getAssembly(data: any): Observable<Assembly[]> {

    return this.http.post<Assembly[]>(this.O3CoreApiUrl + 'assemblyunit', data);
  }
  getSubAssembly(data: any): Observable<SubAssembly[]> {

    return this.http.post<SubAssembly[]>(this.O3CoreApiUrl + 'subassembly', data);
  }
  getComponent(data: any): Observable<Components[]> {

    return this.http.post<Components[]>(
      this.O3CoreApiUrl + 'machinecomponent',
      data
    );
  }
  getPillarMasterData(data: any): Observable<Pillar[]> {
    // return this.http.post<Pillar[]>(this.kpibaseUrl + 'masterpillars', data);
    return this.http.post<Pillar[]>(this.O3CoreApiUrl + 'Pillars/GetMasterPillars', data);
  }
  getPillars(): Observable<Pillar[]> {
    //return this.http.get<Pillar[]>(this.kpibaseUrl + 'pillars');
    return this.http.get<Pillar[]>(this.O3CoreApiUrl + 'Pillars/GetAllPillars');
  }

  addPillar(pillar: any): Observable<Success> {
    //return this.http.post<Success>(this.kpibaseUrl + 'pillar', pillar);
    return this.http.post<Success>(this.O3CoreApiUrl + 'Pillars/AddPillar', pillar);
  }
  getOnePillar(data: any): Observable<OnePillar> {
    //return this.http.post<OnePillar>(this.kpibaseUrl + 'onepillar', data);
    return this.http.post<OnePillar>(this.O3CoreApiUrl + 'Pillars/GetOnePillar', data);
  }

  updatePillar(pillar: any): Observable<Success> {
    //return this.http.put<Success>(this.kpibaseUrl + 'pillar', pillar);
    return this.http.put<Success>(this.O3CoreApiUrl + 'Pillars/UpdatePillar', pillar);
  }
  updateScore(score: any): Observable<Success> {
    //return this.http.put<Success>(this.kpibaseUrl + 'score', score);
    return this.http.put<Success>(this.O3CoreApiUrl + 'Score/UpdateScore', score);
  }
  deletPillar(data: any): Observable<SuccessMessage> {
    //return this.http.post<SuccessMessage>(this.kpibaseUrl + 'deletepillar', data);
    return this.http.post<SuccessMessage>(this.O3CoreApiUrl + 'Pillars/DeletePillar', data);
  }
  deleteScore(data: any): Observable<SuccessMessage> {
    // return this.http.post<SuccessMessage>(this.kpibaseUrl + 'deletescore', data);
    return this.http.post<SuccessMessage>(this.O3CoreApiUrl + 'Score/DeleteScore', data);
  }

  getMasterDrivers(data: any): Observable<Driver[]> {

    // return this.http.post<Driver[]>(this.kpibaseUrl + 'masterdrivers', data);
    return this.http.post<Driver[]>(this.O3CoreApiUrl + 'Driver/GetMasterDrivers', data);
  }

  getDrivers(): Observable<Driver[]> {

    // return this.http.get<Driver[]>(this.kpibaseUrl + 'drivers');
    return this.http.get<Driver[]>(this.O3CoreApiUrl + 'Driver/GetAllDrivers');
  }

 
  // getDepartments(): Observable<Department[]> {

  //   return this.http.get<Department[]>(this.kpibaseUrl + 'departments');
  // }

  getOneDriver(data: any): Observable<OneDriver> {

    //return this.http.post<OneDriver>(this.kpibaseUrl + 'onedriver', data);
    return this.http.post<OneDriver>(this.O3CoreApiUrl + 'Driver/GetOneDriver', data);
  }

  addDriver(driver: any): Observable<Success> {

    //return this.http.post<Success>(this.kpibaseUrl + 'driver', driver);
    return this.http.post<Success>(this.O3CoreApiUrl + 'Driver/AddDriver', driver);
  }

  updateDriver(driver: any): Observable<Success> {

    //return this.http.put<Success>(this.kpibaseUrl + 'driver', driver);
    return this.http.put<Success>(this.O3CoreApiUrl + 'Driver/UpdateDriver', driver);
  }

  deletDriver(data: any): Observable<SuccessMessage> {

    // return this.http.post<SuccessMessage>(this.kpibaseUrl + 'deletedriver', data);
    return this.http.post<SuccessMessage>(this.O3CoreApiUrl + 'Driver/DeleteDriver', data);
  }

  getMasterTools(data: any): Observable<Tool[]> {

    //return this.http.post<Tool[]>(this.kpibaseUrl + 'mastertools', data);
    return this.http.post<Tool[]>(this.O3CoreApiUrl + 'Tools/GetMasterTools', data);
  }

  //NOT IN USE
  // getTools(): Observable<Tool[]> {

  //   return this.http.get<Tool[]>(this.kpibaseUrl + 'tools');
  // }

  getOneTool(data: any): Observable<OneTool> {
    //return this.http.post<OneTool>(this.kpibaseUrl + 'tool', data);
    return this.http.post<OneTool>(this.O3CoreApiUrl + 'Tools/GetOneTool', data);
  }

  addTool(tool: any): Observable<Success> {

    //return this.http.post<Success>(this.kpibaseUrl + 'addtool', tool);
    return this.http.post<Success>(this.O3CoreApiUrl + 'Tools/AddTools', tool);
  }

  updateTool(tool: any): Observable<Success> {
    //return this.http.put<Success>(this.kpibaseUrl + 'tool', tool);
    return this.http.put<Success>(this.O3CoreApiUrl + 'Tools/UpdateTools', tool);
  }

  deletTool(data: any): Observable<SuccessMessage> {
    // return this.http.post<SuccessMessage>(this.kpibaseUrl + 'deletetool', data);
    return this.http.post<SuccessMessage>(this.O3CoreApiUrl + 'Tools/DeleteTool', data);
  }

  //NOT IN USE
  // getMasterTrends(data: any): Observable<Trend[]> {

  //   return this.http.post<Trend[]>(this.kpibaseUrl + 'mastertrends', data);
  // }

  getTrends(): Observable<Trend[]> {
    //return this.http.get<Trend[]>(this.kpibaseUrl + 'trends');
    return this.http.get<Trend[]>(this.O3CoreApiUrl + 'Trends/GetAllTrends');
  }

  //NOT IN USE
  // getOneTrend(data: any): Observable<OneTrend> {

  //   return this.http.post<OneTrend>(this.kpibaseUrl + 'onetrend', data);
  // }

  //NOT IN USE
  // addTrend(trend: any): Observable<Success> {

  //   return this.http.post<Success>(this.kpibaseUrl + 'trend', trend);
  // }

  //NOT IN USE
  // updateTrend(trend: any): Observable<Success> {

  //   return this.http.put<Success>(this.kpibaseUrl + 'trend', trend);
  // }


  //NOT IN USE
  // deletTrend(data: any): Observable<SuccessMessage> {

  //   return this.http.post<SuccessMessage>(this.kpibaseUrl + 'deletetrend', data);
  // }

  getMasterFrequency(): Observable<MasterFrequency[]> {

    return this.http.get<MasterFrequency[]>(this.O3CoreApiUrl + 'MasterFrequency/GetMasterFrequency');
  }

  // getOneLossAnalysis(data: any): Observable<any> {

  //   return this.http.post<any>(this.kpibaseUrl + 'getlossanalysis', data);
  // }

  getMasterFrequencies(data: any): Observable<Frequency[]> {

    return this.http.post<Frequency[]>(this.O3CoreApiUrl + 'Frequencies/GetMasterFrequencies', data);
  }
  getMasterFrequencies2(data: any): Observable<Frequency[]> {

    return this.http.post<Frequency[]>(this.O3CoreApiUrl + 'MasterFrequency/GetMasterFrequency', data);
  }

  getFrequencies(): Observable<Frequency[]> {

    return this.http.get<Frequency[]>(this.O3CoreApiUrl + 'Frequencies/GetAllFrequencies');
  }

  getOneFrequency(data: any): Observable<OneFrequency> {

    return this.http.post<OneFrequency>(this.O3CoreApiUrl + 'Frequencies/GetOneFrequency', data);
  }
//TO Do
  addFrequency(frequency: any): Observable<Success> {

    return this.http.post<Success>(this.O3CoreApiUrl + 'Frequencies/AddFrequency', frequency);
  }
//TO Do
  updateFrequency(frequency: any): Observable<Success> {

    return this.http.put<Success>(this.O3CoreApiUrl + 'Frequencies/UpdateFrequency', frequency);
  }

  // deletfrequency(data: any): Observable<SuccessMessage> {

  //   return this.http.post<SuccessMessage>(
  //     this.kpibaseUrl + 'deletefrequency',
  //     data
  //   );
  // }

  getMasterUoms(data: any): Observable<Uom[]> {

    // return this.http.post<Uom[]>(this.kpibaseUrl + 'masterunitofmeasures', data);
    return this.http.post<Uom[]>(this.O3CoreApiUrl + 'UnitOfMeasure/GetMasterUnitOfMeasures', data);

  }
  getUoms(): Observable<Uom[]> {

    // return this.http.get<Uom[]>(this.kpibaseUrl + 'unitofmeasures');
    return this.http.get<Uom[]>(this.O3CoreApiUrl + 'UnitOfMeasure/GetAllUnitOfMeasures');

  }

  getOneUom(data: any): Observable<OneUom> {

    // return this.http.post<OneUom>(this.kpibaseUrl + 'oneunitofmeasure', data);
    return this.http.post<OneUom>(this.O3CoreApiUrl + 'UnitOfMeasure/GetOneUnitOfMeasure', data);
  }

  addUom(uom: any): Observable<Success> {

    // return this.http.post<Success>(this.kpibaseUrl + 'unitofmeasure', uom);
    return this.http.post<Success>(this.O3CoreApiUrl + 'UnitOfMeasure/AddUnitOfMeasure', uom);

  }

  updateUom(uom: any): Observable<Success> {

    // return this.http.put<Success>(this.kpibaseUrl + 'unitofmeasure', uom);
    return this.http.put<Success>(this.O3CoreApiUrl + 'UnitOfMeasure/UpdateUnitOfMeasure', uom);

  }

  deletUom(data: any): Observable<SuccessMessage> {

    // return this.http.post<SuccessMessage>(
    //   this.kpibaseUrl + 'deleteunitofmeasure',
    //   data
    // );
    return this.http.post<SuccessMessage>(
      this.O3CoreApiUrl + 'UnitOfMeasure/DeleteUnitOfMeasure',
      data
    );
  }


  //NOT IN USE
  // getAllMasterData(data: any): Observable<any> {

  //   return this.http.post<any>(this.kpibaseUrl + 'allmasterdata', data);
  // }

  getMasterDepartments(data: any): Observable<Department[]> {

    return this.http.post<Department[]>(
      this.O3CoreApiUrl + 'Department/GetMasterDepartments',
      data
    );
  }
  addKpiReason(data: any): Observable<Success> {

    return this.http.post<Success>(this.O3CoreApiUrl + 'KpiReason/addKpiReason', data);
  }
  // getKpi(data: any): Observable<Kpi[]> {

  //   return this.http.post<Kpi[]>(this.kpibaseUrl + 'kpis', data);
  // }
  getKpiFrequency(data: any): Observable<any[]> {

    return this.http.post<Kpi[]>(this.O3CoreApiUrl + 'KPI/GetKpiByFrequency', data);
  }
  async getKpiFrequencyAsync(data: any): Promise<any> {
    return await this.http.post<any[]>(this.O3CoreApiUrl + 'KPI/GetKpiByFrequency', data).toPromise();
  }
  getMasterKpiReason(data: any): Observable<Kpireason[]> {

    return this.http.post<Kpireason[]>(this.O3CoreApiUrl + 'KpiReason/KpiMasterReason', data);
  }
  getKpiReason(data: any): Observable<any> {

    return this.http.post<any>(this.O3CoreApiUrl + 'KpiReason/getOneKpiReason', data);
  }
  updateKpiReason(data: any): Observable<Success> {

    return this.http.post<Success>(this.O3CoreApiUrl + 'KpiReason/updateKpiReasonData', data);
  }

  //NOT IN USE
  // getMasterReasons(data: any): Observable<Reason[]> {

  //   return this.http.post<Reason[]>(this.kpibaseUrl + 'masterreason', data);
  // }

  deleteKpiReason(data: any): Observable<SuccessMessage> {

    return this.http.post<SuccessMessage>(
      this.O3CoreApiUrl + 'KpiReason/deleteKpiReasonData',
      data
    );
  }
  // getOneDepartment(data: any): Observable<Department> {

  //   return this.http.post<Department>(this.kpibaseUrl + 'department', data);
  // }

  // addDepartment(data: any): Observable<Success> {

  //   return this.http.post<Success>(this.kpibaseUrl + 'adddepartment', data);
  // }

  // updateDepartment(data: any): Observable<Success> {

  //   return this.http.put<Success>(this.kpibaseUrl + 'department', data);
  // }

  // deletDepartment(data: any): Observable<SuccessMessage> {

  //   return this.http.post<SuccessMessage>(
  //     this.kpibaseUrl + 'deletedepartment',
  //     data
  //   );
  // }


  //NOT IN USE
  // getMasterKpis(data: any): Observable<Kpi[]> {

  //   return this.http.post<Kpi[]>(this.kpibaseUrl + 'masterkpis', data);
  // }

  addFourM(data: any): Observable<Success> {

    //return this.http.post<Success>(this.kpibaseUrl + 'addfourm', data);
    return this.http.post<Success>(this.O3CoreApiUrl + 'Fourm/AddFourM', data);
  }

  updateFourM(data: any): Observable<Success> {

    // return this.http.put<Success>(this.kpibaseUrl + 'fourm', data);
    return this.http.put<Success>(this.O3CoreApiUrl + 'Fourm/UpdateFourM', data);
  }

  getActionAnalysis(data: any): Observable<any> {

    return this.http.post<any>(this.O3CoreApiUrl + 'ActionAnalysis/GetActionAnalysis', data);
  }

  deleteFourM(data: any): Observable<SuccessMessage> {

    //return this.http.post<SuccessMessage>(this.kpibaseUrl + 'deletefourm', data);
    return this.http.post<SuccessMessage>(this.O3CoreApiUrl + 'Fourm/DeleteFourM', data);
  }

  getOneFourM(data: any): Observable<FourM> {

    //return this.http.post<FourM>(this.kpibaseUrl + 'fourm', data);
    return this.http.post<FourM>(this.O3CoreApiUrl + 'Fourm/GetOneFourM', data);
  }

  getMasterFourM(data: any): Observable<FourM[]> {

    //return this.http.post<FourM[]>(this.kpibaseUrl + 'masterfourm', data);
    return this.http.post<FourM[]>(this.O3CoreApiUrl + 'Fourm/MasterFourM', data);
  }

  //TO Do
  setTargetDate(data: any): Observable<any> {

    return this.http.post<any>(this.O3CoreApiUrl + 'LossAnalysis/NewLossAnalysisTarget', data);
  }
//TO Do
  setCompletionDate(data: any): Observable<any> {

    return this.http.post<any>(this.O3CoreApiUrl + 'LossAnalysis/SetCompletionDate', data);
  }


  //NOT IN USE
  // getAllFourM(): Observable<any> {
  //   return this.http.get<any>(this.kpibaseUrl + 'fourm');
  // }

  getRaciMatrix(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'RACIMatrix/GetRaciMatrixReport', data);
  }

  addDayOff(data: any): Observable<any> {
    // return this.http.post<any>(this.kpibaseUrl + 'adddayoff', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'Dayoff/addDayOff', data);
  }

  getMasterDayOff(data: any): Observable<DayOff[]> {
    //return this.http.post<DayOff[]>(this.kpibaseUrl + 'masterdayoff', data);
    return this.http.post<DayOff[]>(this.O3CoreApiUrl + 'Dayoff/getMasterDayOff', data);
  }
  getDayOfflist(data: any): any {
    this.dayOffList = [];
    data.forEach((days: any) => {
      if (days.dayOff === 'Monday') {
        this.dayOffList.push(1);
      } else if (days.dayOff === 'Tuesday') {
        this.dayOffList.push(2);
      } else if (days.dayOff === 'Wednesday') {
        this.dayOffList.push(3);
      } else if (days.dayOff === 'Thursday') {
        this.dayOffList.push(4);
      } else if (days.dayOff === 'Friday') {
        this.dayOffList.push(5);
      } else if (days.dayOff === 'Saturday') {
        this.dayOffList.push(6);
      } else if (days.dayOff === 'Sunday') {
        this.dayOffList.push(0);
      }
    });
    return this.dayOffList;
  }
  deleteDayOff(data: any): Observable<any> {
    // return this.http.post<any>(this.kpibaseUrl + 'deletedayoff', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'Dayoff/deleteDayOff', data);
  }

  updateDayOff(data: any): Observable<any> {
    // return this.http.put<any>(this.kpibaseUrl + 'dayoff', data);
    return this.http.put<any>(this.O3CoreApiUrl + 'Dayoff/updateDayOff', data);
  }
  getOneDayOff(data: any): Observable<any> {
    //return this.http.post<any>(this.kpibaseUrl + 'onedayoff', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'Dayoff/getOneDayOff', data);
  }

  addMasterShift(data: any): Observable<any> {
    // return this.http.post<any>(this.kpibaseUrl + 'addmastershift', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'MasterShift/addMasterShift', data);
  }
  updatemastershift(data: any): Observable<any> {
    // return this.http.put<any>(this.kpibaseUrl + 'mastershift', data);
    return this.http.put<any>(this.O3CoreApiUrl + 'MasterShift/updateShiftData', data);
  }
  getMasterShifts(data: any): Observable<Mastershift[]> {

    // return this.http.post<Mastershift[]>(this.kpibaseUrl + 'mastershift', data);
    return this.http.post<Mastershift[]>(this.O3CoreApiUrl + 'MasterShift/getMasterShifts', data);
  }

  getOneMasterShift(data: any): Observable<any> {

    // return this.http.post<any>(this.kpibaseUrl + 'onemastershift', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'MasterShift/getOneMasterShift', data);
  }
  deleteMasterShift(data: any): Observable<any> {
    // return this.http.post<any>(this.kpibaseUrl + 'deletemastershift', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'MasterShift/deleteShiftRepo', data);
  }
  // getUserPlants(data: any): Observable<UserPlant[]> {
  //   return this.http.post<UserPlant[]>(this.kpibaseUrl + 'userplant', data);
  // }
  isMenuEnabled(flatArray: any, name: any): any {
    let isDisabled = true;
    const node = flatArray.find(
      (x: any) => x.data.MenuName === name
    );
    const checkIsLeafChildSelected = (children: any) => {
      const isAnyLeafSelected = children.find((child: any) => (child.children.length === 0 && child.data.IsRead));

      if (isAnyLeafSelected) {
        isDisabled = false;
      }
      else {
        const childsContainingNodes = children.filter((child: any) => child.children.length);
        if (childsContainingNodes.length) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < childsContainingNodes.length; i++) {
            const childNodeChildrens = childsContainingNodes[i].children;
            checkIsLeafChildSelected(childNodeChildrens);
          }
        }
      }
    };
    if (node.children.length) {
      checkIsLeafChildSelected(node.children);
    } else {
      if (node.data.IsRead === true) {
        isDisabled = false;
      }
    }
    return isDisabled;
  }
  getKpiuploadefFileData(data: any): any {
    return this.http.post(this.O3CoreApiUrl + 'KpiFileUploading/FetchData', data);
  }
  uploadKpiFile(data: any): any {
    return this.http.post(this.O3CoreApiUrl + 'KpiFileUploading/SaveFile', data);
  }
  UploadKPIFiles(data: any): any {
    return this.http.post(this.O3CoreApiUrl + 'KpiFileUploading/SaveCsvFile', data);
  }
  KPIRACIInformation(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'ScoreCardMeetingMatrix/KPIRACIInformation',data);
  }

  KPIActualData(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPI/getCalculatedData',data);
  }
  saveKPIRefreshData(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPI/saveKPIRefreshData',data);
  }
}
