import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditDashboard {
  baseUrl: string = environment.O3CoreApiUrl;
  kpibaseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  get5sCompletion(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'AdminDashboard/get5scompletion', data);
  }

  Get5sOpen(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'AdminDashboard/get5sopen', data);
  }

  NoOf5sFindingOverall(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'AdminDashboard/noOf5sFindingOverall', data);
  }

  NoOf5sFindingByArea(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'AdminDashboard/noOf5sFindingByArea', data);
  }

  HeatMapData(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'AdminDashboard/heatmapdata', data);
  }

  GetAllExportData(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'AdminDashboard/GetAllExportData', data);
  }
}
