import { Component, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent {
  @Input() chartData: any;
  @Input('datasets') lineChartData: any;
  @Input('labels') lineChartLabels: any;
  @Input('colors') lineChartColors: any;
  @Input('options') lineChartOptions: any;


  constructor() { }

  lineChartType: ChartType = 'line';
  lineChartLegend = true;
  lineChartPlugins = [];

}

