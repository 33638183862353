import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OpmsHotEndShiftReportService {

  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  GetHotEndShiftPerformanceReport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/GetHotEndShiftPerformanceReport', data);
  }
  GetHotEndTinBathQualityParametersReport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/GetHotEndTinBathQualityParametersReport', data);
  }
  SaveHotEndTinBathQualityParametersManualEntry(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/SaveHotEndTinBathQualityParametersManualEntry', data);
  }
  GetHotEndFurnaceCombustionReport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/GetHotEndFurnaceCombustionReport', data);
  }
  SaveHotEndFurnaceCombustionManualEntry(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/SaveHotEndFurnaceCombustionManualEntry', data);
  }
  GetHotEndFurnaceTemperaturesReport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/GetHotEndFurnaceTemperaturesReport', data);
  }
  SaveHotEndFurnaceTemperaturesManualEntry(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/SaveHotEndFurnaceTemperaturesManualEntry', data);
  }
  GetHotEndEquipmetsReport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/GetHotEndEquipmetsReport', data);
  }
  SaveHotEndEquipmetsManualEntry(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/SaveHotEndEquipmetsManualEntry', data);
  }
  GetHotEndTinBathProcessParametersReport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/GetHotEndTinBathProcessParametersReport', data);
  }
  SaveHotEndTinBathProcessParametersManualEntry(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/SaveHotEndTinBathProcessParametersManualEntry', data);
  }
  GetHotEndTinBathEnergyReport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/GetHotEndTinBathEnergyReport', data);
  }
  SaveHotEndTinBathEnergyManualEntry(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/SaveHotEndTinBathEnergyManualEntry', data);
  }
  GetHotEndEventsReport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OPMSHotEndShiftReports/GetHotEndEventsReport', data);
  }
  GetMenuAccessForHotEnd(): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + 'Account/GetMenuAccessForHotEnd');
  }
}
