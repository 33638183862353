import { Component, OnInit, ViewChild, OnDestroy  } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import * as Chart from 'chart.js';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle
} from "ng-apexcharts";

import * as _moment from 'moment';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';

const moment = _moment;


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-safety-db',
  templateUrl: './safety-db.component.html',
  styleUrls: ['./safety-db.component.scss']
})
export class SafetyDbComponent implements OnInit {

  // @ViewChild('chart', {static: false})
  chartComponent!: ChartComponent;
  title = "Route 1 with Chart";


  dropDownForm!: FormGroup;
  //Drop-down Variables
  yearList = ['2015', '2016', '2017', '2018', '2019', '2020', '2021'];
  monthList = ['ALL', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  weekList = ['ALL', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  dayList = ['ALL', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  shiftList = ['ALL', 'Morning', 'Evening', 'Night'];
  machineList = ['ALL', 'RTM1', 'RTM2'];

  // Date Time Range
  range = new FormGroup({
    startDate: new FormControl(moment()),
    endDate: new FormControl(moment())
  });

  //iconUrl
  menuRedIconUrl: string = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl: string = 'assets/images/menu-bottom-green-icon.png';

  startDate = new FormControl(moment());
  endDate = new FormControl(moment());

  // Charts

  // # of Accidents
  accidents = {
    barChartLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    barChartData: [
      { data: [0, 0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 0], label: '  Target  ' }
    ],
    barChartColors: [
      { backgroundColor: "#040496" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 0.2,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // # of Near Misses & Unsafe Conditions
  nearMissesUnsafeConditions = {
    barChartLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    barChartData: [
      { data: [17, 35, 24, 38, 22, 16, 19, 14, 34, 15, 42, 16], label: '  Target  ' }
    ],
    barChartColors: [
      { backgroundColor: "#040496" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 5,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // BOS Score
  bosScore = {
    barChartLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    barChartData: [
      { data: [2.5, 1.5, 2.2, 4.1, 2.2, 1.6, 1.9, 1.4, 3.4, 1.5, 3.2, 1.6], label: '  Target  ' }
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // # of Accidents by Area
  accidentsByArea = {
    barChartLabels: ["1", "2", "3", "4", "5", "6"],
    barChartData: [
      { data: [20, 36, 25, 29, 20, 30], label: '  Target  ' }
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 5,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // # of Near Misses & Unsafe Conditions by Area
  nearMissesUnsafeConditionsByArea = {
    barChartLabels: ["1", "2", "3", "4", "5", "6"],
    barChartData: [
      { data: [30, 26, 20, 22, 36, 30], label: '  Target  ' }
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 5,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

chart = {
    barChartLabels: ["1", "2", "3", "4", "5", "6"],
    barChartData: [
      { data: [20, 36, 25, 29, 20, 30], label: '  Target  ' }
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 5,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;

  constructor(
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private formBuilder: FormBuilder
  ) { 
    
  }

  ngOnInit(): void {
    Chart.defaults.global.legend.labels.usePointStyle = true;
    this.dropDownForm = this.formBuilder.group({
      groupList: [''],
      UnitDataList: [''],
    });

    this.GetMenuAccess();
    this.GetBreadcrumList();
  }
  //Breadcrum

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;

    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any{
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([url]));
    // return false;
  }
}
