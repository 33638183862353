
<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center">
      <mat-card-title class="p-ml-2">Area</mat-card-title>
      <div class="" >
        <mat-form-field>
          <input matInput  placeholder="Search...." autocomplete="off" (keyup)="applyFilter($event)">
        </mat-form-field>
      </div>
      <div class="" >
        <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="accent"
        (click)="opensideModal()" *ngIf="IsWrite"
        pTooltip="Add Area" tooltipPosition="top"
      >
        <i class="fa fa-plus" style="color: #fff"></i>
      </button>
      </div>
  </div>
  <mat-card-content>
    <ngx-spinner
        [fullScreen]="false"
        bdColor="#fff"
        size="medium"
        color="#050a4f"
        type="ball-clip-rotate-multiple"
        >
        <p style="font-size: 20px; color: #050a4f">Fetching Area Data...</p>
  </ngx-spinner>
  <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1" >
    <table mat-table [dataSource]="areaData" class="basic-table plant-model-table" matSort >

      <ng-container matColumnDef="plant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Plant</p>
        </th>
        <td mat-cell *matCellDef="let element" > {{element.ptDesc}} </td>
      </ng-container>
      <!-- remarks Column  -->
    <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Area</p>
        </th>
        <td mat-cell *matCellDef="let element">{{element.areaName}}</td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>
          <p class="header-text">Action</p>
        </th>
        <td mat-cell *matCellDef="let element" class="row-action-btns-column">
          <div class="row-action-btns">
            <button mat-icon-button class="edit-btn" pTooltip="Edit Area" tooltipPosition="top" aria-label="Edit Row" *ngIf="IsWrite" (click)="editAreaModel(element)">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                  fill="white" />
              </svg>
            </button>
            <button mat-icon-button class="tree-view-btn" pTooltip="Delete Area" tooltipPosition="top" aria-label="View Row Tree" *ngIf="IsWrite" (click)="Deletearea(element)">
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                  fill="#F85439" />
                <circle cx="17" cy="17" r="14" fill="#F85439" />
                <path
                  d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                  fill="white" />
              </svg>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="areaColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: areaColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching</td>
      </tr>
      <!-- <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching</td>
      </tr> -->
    </table>
    <mat-paginator
      class="basic-paginataor"
      [pageSizeOptions]="[2, 5, 10, 20]"
      showFirstLastButtons>
    </mat-paginator>
  </div>
  </mat-card-content>
</mat-card>

<!--side bar modal code-->
<div
  class="sidepanel-model-wrapper edit-plant-row-model"
  [ngClass]="{ active: displayAreaModal }"
>
  <div class="sidepanel-close" (click)="closeAreaModel()"></div>
  <div class="model-content">
    <div class="model-top">
      <h2 class="model-title">{{formMode ? 'Add' : 'Update'}} Area</h2>

      <button
        mat-icon-button
        class="model-close-btn"
        aria-label="Close model"
        (click)="closeaddNewAreaModel()"
      >
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
            fill="black"
          />
        </svg>
      </button>
    </div>
    <div class="model-body">
  <form [formGroup]="areaForm" (ngSubmit)="formMode ? saveArea() : updateArea()">
      <div class="form-group">
      <!-- <mat-form-field  appearance="fill" class="field-fill">
        <mat-label class="required">Group</mat-label>
        <mat-select panelClass="testClass" name="group" formControlName="group" [disabled]="true">
          <mat-option *ngFor="let group of groupList" [value]="group.TypeId">{{group.egDesc}}</mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field> -->
      <mat-form-field  appearance="fill" class="field-fill">
        <mat-label>Plant</mat-label>
        <mat-select formControlName="plant" [disabled]="true">
          <mat-option *ngFor="let plant of plantList" [value]="plant.DeptId">{{plant.DeptDesc}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
    <div class="form-group">
      <mat-form-field appearance="fill" class="field-fill">
        <mat-label>Department</mat-label>
        <mat-select name="department"  placeholder="Departments" formControlName="department">
          <mat-option *ngFor="let pt of departmentList" [value]="pt.TypeId">  {{pt.TypeName}} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field  appearance="fill" class="field-fill">
        <mat-label  class="required">Area</mat-label>
        <input matInput placeholder="Area" name="area" formControlName="areaname">
        <mat-error>Required</mat-error>
      </mat-form-field>
    </div>
    <div class="model-form-actions">
        <!-- <button type="button" mat-flat-button class="btn-cancel" (click)="closeaddNewPlantItemModel()" > Cancel
        </button> -->
        <button
        type="button"
        (click)="closeaddNewAreaModel()"
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        pTooltip="Cancel" tooltipPosition="top" 
      >
        <i class="fa fa-close" style="color: #fff"></i>
      </button>
      <button
          (click)="updateArea()"
          type="submit"
          *ngIf="!formMode"
          [disabled]="!areaForm.valid"
          mat-mini-fab
          mat-button
          mat-dialog-close
          color="primary"
          pTooltip="Update" tooltipPosition="top"
        >
          <i class="fa fa-pencil" style="color: #fff"></i>
        </button>
        <button
          (click)="saveArea()"
          type="submit"
          *ngIf="formMode"
          [disabled]="!areaForm.valid"
          mat-mini-fab
          mat-button
          mat-dialog-close
          color="primary"
          pTooltip="Save" tooltipPosition="top" 
        >
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
        <!-- <button mat-flat-button color="accent" class="btn-accent" [disabled]="!areaForm.valid" (click)="saveArea()">{{isUpdateArea ? 'Update' : 'Add'}}</button> -->
      </div>
  </form>
</div>
  </div>
</div>
