import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { MatDialog } from '@angular/material/dialog';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { ButtonViewRendererComponent } from 'src/app/components/button-view-render.components';
import { RMDashboardService } from '../../services/rm-dashboard.service';
import Drilldown from 'highcharts/modules/drilldown';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import { ManagerExecutionService } from 'src/app/services/manager.service';
import { Options } from 'highcharts';
import { DtServiceService } from 'src/app/services/dtService.service';
import { PrevConfigurationService } from 'src/app/services/prev-configuration.service';
import * as _ from 'lodash';
More(Highcharts);
Drilldown(Highcharts);
Exporting(Highcharts);
@Component({
  selector: 'app-kpi-masters-dashboard',
  templateUrl: './kpi-masters-dashboard.component.html',
  styleUrls: ['./kpi-masters-dashboard.component.scss'],
})
export class KpiMastersDashboardComponent implements OnInit {
  @ViewChild('RepeatedBD') private RepeatedBD!: TemplateRef<any>;
  @ViewChild('MTTR') private MTTR!: TemplateRef<any>;
  @ViewChild('MTBF') private MTBF!: TemplateRef<any>;
  @ViewChild('MinorStops') private MinorStops!: TemplateRef<any>;
  @ViewChild('MajorBD') private MajorBD!: TemplateRef<any>;

  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public color: ThemePalette = 'primary';
  filteredGroups: any;
  breadcrumList: any;
  GroupList!: any[];
  key: any;
  defaultExcelExportParams: any;
  frameworkComponents: any;

  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;
  gridOptions: any = {
    defaultColDef: {
      rowHeight: 45,
      rowStyle: {
        'border-bottom': 'white 10px solid',
        'border-top': 'white 10px solid',
        'max-height': '50px',
      },
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true, // <-- HERE
      autoHeight: true, // <-- HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
  };
  unitPickerIsOpen: boolean = false;
  isPickFromUnit: boolean = false;
  
  HeaderData: any = {
    Group: '',
    Plant: '',
    Line: '',
    displayMachine: '',
    StartDate: new Date(
      moment().subtract(1, 'd').set('hour', 6).set('minutes', 0).toString()
    ),
    EndDate: new Date(),
  };
  HeaderForm: any;
  TreeData: any = [];

  MajorBDValues: any = 0;
  MTTRValues: any = 0;
  MTBFValues: any = 0;
  minorStopsValues: any = 0;
  repeatedBDValues: any = 0;

  spinnerText: string = '';
  userList: any = [];
  options: Options;
  mttrMonthly: any;
  mttrTechinicianLevel: any;
  mttrCategory: any;
  mttr: any;
  mtbfMonthly: any;
  mtbfMachineLevel: any;
  mtbfCategory: any;
  mtbf: any;
  KPIMasterCardData: any;
  majorBDMachineLevel: any;
  bdCategory: any;
  totalBreakdowns: any;
  majorBDSubAssemblyLevel: any;
  totalBDComponentLevel: Chart;
  plantList: any;
  plantTempList: any;
  majorBDMachineLevelData: any = [];
  ComponentName: any = [];
  FailureName: any[];
  bdCategoryData: any[] = [];
  totalBreakdownsoptions: any;
  bdCategoryoptions: any;
  majorBDMachineLeveloptions: any;
  majorBDSubAssemblyLeveloptions: any;
  totalBDComponentLeveloptions: any;
  rawmajorBDMachineLevelData: any[];
  rawsubAssemblyData: any[];
  rawComponentName: any[];
  rawFailureName: any[];
  rawbdCategoryData: any[];
  majorBDMachineLevelLabelData: any = [];
  LineList: any = [];
  LineFilterList: any = [];
  majorBDSubAssemblyLevelLabel: any = [];
  majorBDSubAssemblyLevelData: any = [];
  totalBDComponentLevelLabel: any = [];
  totalBDComponentLevelData: any = [];
  RepeatedBDOptions: any;
  repeatedBDChart: Chart;
  mttrMonthlyValues: any = [];
  mttrCategorySumValue: any = [];
  minorCategoryValues: any = [];
  minorMonthlyValues: any = [];
  minorStopsCategoryChart: Chart;
  minorStopsMonthlyChart: Chart;
  repeatedBDLabels: any = [];
  repeatedBDData: any = [];
  constructor(
    private productivityservice: ProductivityService,
    private toaster: ToastrService,
    public router: Router,
    private userService: UserManagementService,
    private spinner: NgxSpinnerService,
    private pmDashboardService: RMDashboardService,
    private fb: FormBuilder,
    public managerExecutionService: ManagerExecutionService,
    private dialog: MatDialog,
    private dtService: DtServiceService,
    private prevConfigurationService: PrevConfigurationService
  ) {
    this.defaultExcelExportParams = {
      addImageToCell: function (
        rowIndex: number,
        col: any,
        value: any,
        callback: any
      ) {
        if (col.colId === 'PreImage' || col.colId === 'PostImage') {
        } else {
          return;
        }
      },
    };
    this.frameworkComponents = {
      buttonRenderer: ButtonViewRendererComponent,
    };

    this.columnDefs = [
      { headerName: 'Operation Excution WOID', field: 'OperatorExecutionWOID',  },
      { headerName: 'WO Name', field: 'WOName',  },
      { headerName: 'Line', field: 'PL_Desc',  },
      { headerName: 'Unit', field: 'PU_Desc',  },
      { headerName: 'Assembly', field: 'Assbly_Desc',  },
      { headerName: 'Sub Assembly', field: 'SAsbly_Desc',  },
      { headerName: 'Component', field: 'ComponentName',  },
      { headerName: 'Created AT',field: 'CreatedAt',},
      { headerName: 'Closed At',field: 'ClosedAt',},
      { headerName: 'Failure Name', field: 'FailureName',  },
    ];
    this.defaultExcelExportParams = {
      addImageToCell: function (
        rowIndex: number,
        col: any,
        value: any,
        callback: any
      ) {
        if (col.colId === 'PreImage' || col.colId === 'PostImage') {
        } else {
          return;
        }
      },
    };
  }

  ngOnInit(): void {
    this.HeaderForm = this.fb.group({
      Group: ['', Validators.required],
      plant: ['', Validators.required],
      line: ['', Validators.required],
      displayMachine: ['', Validators.required],
      Date: ['', Validators.required],
      StartDate: [new Date(), Validators.required],
      EndDate: [new Date(), Validators.required],
    });
    this.initform();
    this.GetBreadcrumList();
    this.getGroup();
    this.getUserList();
  }
  initform() {
    this.HeaderData.Date = 'Today';
    this.HeaderData.ChartUnit = 0;
  }
  getUserList() {
    this.spinnerText = 'Fetching User Data';
    this.spinner.show();
    this.managerExecutionService.getUserList().subscribe(
      (res: any) => {
        this.userList = res.UserList;
        this.spinner.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }
  getTreeMasterData() {
    const data = {
      GroupID: this.HeaderData.Group,
      PlantID: this.HeaderData.Plant,
    };
    this.spinner.show();
    this.prevConfigurationService.GetTreeMasterData(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.LineList = res.Line;
        this.LineFilterList = this.LineList.slice();
        this.HeaderForm.get('line')?.setValue(this.LineList[0].PL_Id);
        this.getKPIMastersData();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  getGroup(): void {
    const key = {
      UserId: JSON.parse(localStorage.user).UserId,
    };
    const id = {
      value: 0,
    };
    this.productivityservice.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.lstEG;
        if (this.GroupList && this.GroupList.length) {
          this.HeaderForm.get('Group')?.setValue(this.GroupList[0].EG_Id);
          this.getDeptData();
        }
        this.filteredGroups = this.GroupList.slice();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getDeptData(): void {
    const key = {
      GroupId: this.HeaderData.Group,
    };
    this.spinner.show();
    this.dtService.getPlantData(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.plantList = res.DepartList;
        this.plantTempList = this.plantList.slice();
        this.HeaderForm.get('plant')?.setValue(this.plantList[0].DeptId);
        this.getTreeMasterData();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getMajorBD() {
    this.totalBreakdownsoptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Total BD',
      },
      colors: ['#ff914d'],
      xAxis: {
        categories: ['Total BD', 'Minor BD', 'Moderate BD', 'Major BD'],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [{ showInLegend: false, data: [49.9, 71.5, 106, 4.4] }],
    } as any;
    let chart = new Chart(this.totalBreakdownsoptions);
    this.totalBreakdowns = chart;
    this.bdCategoryoptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'BD Category',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
        },
      },
      series: [
        {
          name: '',
          colorByPoint: true,
          data: this.bdCategoryData,
        },
      ],
    } as any;
    chart = new Chart(this.bdCategoryoptions);
    this.bdCategory = chart;
    this.majorBDMachineLeveloptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'BD Machine Level',
      },
      subtitle: {
        text: '',
      },
      xAxis: {
        categories: this.majorBDMachineLevelLabelData,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          pointWidth: 50,
        },
      },
      series: [
        {
          color: '#1212d1',
          showInLegend: false,
          data: this.majorBDMachineLevelData,
        },
      ],
    } as any;
    chart = new Chart(this.majorBDMachineLeveloptions);
    this.majorBDMachineLevel = chart;

    this.majorBDSubAssemblyLeveloptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'BD Sub Assembly Level',
      },
      subtitle: {
        text: '',
      },
      xAxis: {
        categories: this.majorBDSubAssemblyLevelLabel,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          pointWidth: 50,
        },
      },
      series: [
        {
          color: '#6ce5e8',
          showInLegend: false,
          data: this.majorBDSubAssemblyLevelData,
        },
      ],
    } as any;
    chart = new Chart(this.majorBDSubAssemblyLeveloptions);
    this.majorBDSubAssemblyLevel = chart;

    this.totalBDComponentLeveloptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'BD Component Level',
      },
      subtitle: {
        text: '',
      },
      colors: ['#08b051'],
      xAxis: {
        categories: this.totalBDComponentLevelLabel,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'MTTR Technican',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          pointWidth: 50,
        },
      },
      series: [
        {
          showInLegend: false,
          data: this.totalBDComponentLevelData,
        },
      ],
    } as any;
    chart = new Chart(this.totalBDComponentLeveloptions);
    this.totalBDComponentLevel = chart;
  }
  getMTBF() {
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'MTBF Total',
      },
      colors: ['#ff914d'],
      xAxis: {
        categories: ['Total BD', 'Minor BD', 'Moderate BD', 'Major BD'],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [{ showInLegend: false, data: [49.9, 71.5, 106, 4.4] }],
    } as any;
    let chart = new Chart(this.options);
    this.mtbf = chart;
    this.options = {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: 'MTBF Monthly',
      },
      subtitle: {
        text: '',
      },
      xAxis: [
        {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          crosshair: true,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value}',
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          title: {
            text: '',
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
        },
        {
          // Secondary yAxis
          title: {
            text: '',
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          labels: {
            format: '{value} ',
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        x: 120,
        verticalAlign: 'top',
        y: 100,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || // theme
          'rgba(255,255,255,0.25)',
      },
      series: [
        {
          name: '',
          color: '#94a9de',
          showInLegend: false,
          type: 'column',
          yAxis: 1,
          data: this.mttrMonthlyValues,
          tooltip: {
            valueSuffix: ' ',
          },
        },
      ],
    } as any;
    chart = new Chart(this.options);
    this.mtbfMonthly = chart;
    this.options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'MTBF Category',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: 'Brands',
          colorByPoint: true,
          data: this.mttrCategorySumValue,
        },
      ],
    } as any;
    chart = new Chart(this.options);
    this.mtbfCategory = chart;

    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'MTBF Technican Level',
      },
      subtitle: {
        text: '',
      },
      colors: ['#08b051'],
      xAxis: {
        categories: [
          'C12',
          'C12-2',
          'C15',
          'HB2',
          'Presto 1',
          'Presto 2',
          'Lithoman 1',
          'LMN 2',
          'R702',
        ],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          showInLegend: false,
          data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4],
        },
      ],
    } as any;
    chart = new Chart(this.options);
    this.mtbfMachineLevel = chart;
  }
  getMinorStops() {
    this.options = {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: 'Minor Monthly',
      },
      subtitle: {
        text: '',
      },
      xAxis: [
        {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          crosshair: true,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value}',
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          title: {
            text: '',
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
        },
        {
          // Secondary yAxis
          title: {
            text: '',
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          labels: {
            format: '{value} ',
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        x: 120,
        verticalAlign: 'top',
        y: 100,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || // theme
          'rgba(255,255,255,0.25)',
      },
      series: [
        {
          showInLegend: false,
          name: '',
          color: '#94a9de',
          type: 'column',
          yAxis: 1,
          data: this.minorMonthlyValues,
          tooltip: {
            valueSuffix: ' ',
          },
        },
      ],
    } as any;
    let chart = new Chart(this.options);
    this.minorStopsMonthlyChart = chart;
    this.options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Minor Category',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: '',
          colorByPoint: true,
          data: this.minorCategoryValues,
        },
      ],
    } as any;
    chart = new Chart(this.options);
    this.minorStopsCategoryChart = chart;
  }
  getRepeatedBD() {
    this.RepeatedBDOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Repeated BreakDowns',
      },
      subtitle: {
        text: '',
      },
      colors: ['#08b051'],
      xAxis: {
        categories: this.repeatedBDLabels,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          pointWidth: 50,
        },
      },
      series: [
        {
          showInLegend: false,
          data: this.repeatedBDData,
        },
      ],
    };
    let chart = new Chart(this.RepeatedBDOptions);
    this.repeatedBDChart = chart;
  }
  getRepeatedDefects() { }

  getMTTR() {
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'MTTR Total',
      },
      colors: ['#ff914d'],
      xAxis: {
        categories: ['Total BD', 'Minor BD', 'Moderate BD', 'Major BD'],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [{ showInLegend: false, data: [49.9, 71.5, 106, 4.4] }],
    } as any;
    let chart = new Chart(this.options);
    this.mttr = chart;
    this.options = {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: 'MTTR Monthly',
      },
      subtitle: {
        text: '',
      },
      xAxis: [
        {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          crosshair: true,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value}',
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          title: {
            text: '',
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
        },
        {
          // Secondary yAxis
          title: {
            text: '',
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          labels: {
            format: '{value} ',
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        x: 120,
        verticalAlign: 'top',
        y: 100,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || // theme
          'rgba(255,255,255,0.25)',
      },
      series: [
        {
          showInLegend: false,
          name: '',
          color: '#94a9de',
          type: 'column',
          yAxis: 1,
          data: this.mttrMonthlyValues,
          tooltip: {
            valueSuffix: ' ',
          },
        },
      ],
    } as any;
    chart = new Chart(this.options);
    this.mttrMonthly = chart;
    this.options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'MTTR Category',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: 'Brands',
          colorByPoint: true,
          data: this.mttrCategorySumValue,
        },
      ],
    } as any;
    chart = new Chart(this.options);
    this.mttrCategory = chart;

    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      colors: ['#08b051'],
      xAxis: {
        categories: [
          'C12',
          'C12-2',
          'C15',
          'HB2',
          'Presto 1',
          'Presto 2',
          'Lithoman 1',
          'LMN 2',
          'R702',
        ],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'MTTR Technican',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          showInLegend: false,
          data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4],
        },
      ],
    } as any;
    chart = new Chart(this.options);
    this.mttrTechinicianLevel = chart;
  }

  openMajorBD() {
    if (this.MajorBDValues === 0 && this.rowData.length == 0) {
      return;
    } else {
      let dialogRef = this.dialog.open(this.MajorBD, {
        width: '80%',
        height: '90%',
      });
      dialogRef.afterOpened().subscribe((result) => {
        this.getMajorBD();
      });
      dialogRef.afterClosed().subscribe((result) => { });
    }
  }
  openMTBF() {
    if(this.MTBFValues == 0)
    return
    let dialogRef = this.dialog.open(this.MTBF, {
      width: '80%',
      height: '90%',
    });
    dialogRef.afterOpened().subscribe((result) => {
      this.getMTBF();
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  openMinorStops() {
    if(this.minorStopsValues == 0)
    return
    let dialogRef = this.dialog.open(this.MinorStops, {
      width: '80%',
      height: '70%',
    });
    dialogRef.afterOpened().subscribe((result) => {
      this.getMinorStops();
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  openRepeatedBD() {
    if (this.repeatedBDValues == 0) {
      return;
    } else {
      let dialogRef = this.dialog.open(this.RepeatedBD, {
        width: '80%',
        height: '70%',
      });
      dialogRef.afterOpened().subscribe((result) => {
        this.getRepeatedBD();
      });
      dialogRef.afterClosed().subscribe((result) => { });
    }
  }

  openMTTR() {
    if (this.MTTRValues == 0)
      return
    let dialogRef = this.dialog.open(this.MTTR, {
      width: '80%',
      height: '90%',
    });
    dialogRef.afterOpened().subscribe((result) => {
      this.getMTTR();
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  /// AG Grid
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  excelExport() {
    this.gridApi.exportDataAsExcel();
  }

  csvExport() {
    this.gridApi.exportDataAsCsv();
  }
  getKPIMastersData() {
    if (this.HeaderData.Line == '' || this.HeaderData.Plant == '') {
      return;
    } else {
      const data = {
        PLID: this.HeaderData.Line,
        PTID: this.HeaderData.Plant,
        dateStart: this.HeaderData.StartDate,
        dateEnd: this.HeaderData.EndDate,
      };

      this.spinner.show();
      this.rawsubAssemblyData = [];
      this.rawComponentName = [];
      this.rawFailureName = [];
      this.rawbdCategoryData = [];

      this.pmDashboardService.getMainKPIDashboard(data).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res) {
            this.KPIMasterCardData = res;
          }
          if (res.dtData && res.dtData.length>0) {
            this.rowData = res.dtData;
          }
          else{
            this.rowData=[];
          }
          if (this.rowData && this.rowData.length) {
            this.rawmajorBDMachineLevelData = this.findOcc(this.rowData,'PU_Desc');
            this.rawsubAssemblyData = this.findOcc(this.rowData, 'SAsbly_Desc');
            this.rawComponentName = this.findOcc(this.rowData, 'ComponentName');
            this.rawFailureName = this.findOcc(this.rowData, 'FailureName');
            this.rawbdCategoryData = this.findOcc(this.rowData, 'FailureName');
            this.setData();
          }
          
          this.MajorBDValues = this.KPIMasterCardData.TotalBD;
          this.MTTRValues = this.KPIMasterCardData.MTTR;
          this.MTBFValues = this.KPIMasterCardData.MTBF;
          this.minorStopsValues = this.KPIMasterCardData.MinorStops;
          this.repeatedBDValues = this.KPIMasterCardData.RepeatedBD;
        },
        (err: any) => {
          this.toaster.error('error', err.message);
          this.spinner.hide();
        }
      );
    }
  }

  findOcc(arr, key) {
    let arr2 = [];

    arr.forEach((x) => {
      if (
        arr2.some((val) => {
          return val[key] == x[key];
        })
      ) {
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k['occurrence']++;
          }
        });
      } else {
        let a = {};
        a[key] = x[key];
        a['occurrence'] = 1;
        arr2.push(a);
      }
    });

    return arr2;
  }

  setData() {
    if (this.rawbdCategoryData && this.rawbdCategoryData.length) {
      this.bdCategoryData = [];
      this.rawbdCategoryData.map((res: any) => {
        this.bdCategoryData.push({ y: res.occurrence, name: res.FailureName });
      });
    }
    if (
      this.rawmajorBDMachineLevelData &&
      this.rawmajorBDMachineLevelData.length
    ) {
      this.majorBDMachineLevelData = [];
      this.majorBDMachineLevelLabelData = [];
      this.rawmajorBDMachineLevelData.map((res: any) => {
        this.majorBDMachineLevelData.push(res.occurrence);
        this.majorBDMachineLevelLabelData.push(res.PU_Desc);
      });
    }
    if (this.rawsubAssemblyData && this.rawsubAssemblyData.length) {
      this.majorBDSubAssemblyLevelData = [];
      this.majorBDSubAssemblyLevelLabel = [];

      this.rawsubAssemblyData.map((res: any) => {
        this.majorBDSubAssemblyLevelData.push(res.occurrence);
        this.majorBDSubAssemblyLevelLabel.push(res.SAsbly_Desc);
      });
    }
    if (this.rawComponentName && this.rawComponentName.length) {
      this.totalBDComponentLevelData = [];
      this.totalBDComponentLevelLabel = [];
      this.rawComponentName.map((res: any) => {
        this.totalBDComponentLevelData.push(res.occurrence);
        this.totalBDComponentLevelLabel.push(res.ComponentName);
      });
    }
    if (this.rawFailureName && this.rawFailureName.length) {
      this.repeatedBDLabels = [];
      this.repeatedBDData = [];

      this.rawFailureName.map((res: any) => {
        if (res.occurrence > 1) {
          this.repeatedBDLabels.push(res.FailureName);
          this.repeatedBDData.push(res.occurrence);
        }
      });
    }
    this.mttrMonthlyValues = [];
    const monthName = (item) =>
      moment(item.CreatedAt, 'YYYY-MM-DD').format('MMM');
    const result = _.groupBy(this.rowData, monthName);
    if (result.Jan && result.Jan.length) {
      const sum = result.Jan.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.Jan.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }
    if (result.Feb && result.Feb.length) {
      const sum = result.Feb.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.Feb.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }
    if (result.Mar && result.Mar.length) {
      const sum = result.Mar.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.Mar.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }
    if (result.Apr && result.Apr.length) {
      const sum = result.Apr.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.Apr.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }
    if (result.May && result.May.length) {
      const sum = result.May.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.May.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }
    if (result.Jun && result.Jun.length) {
      const sum = result.Jun.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.Jun.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }
    if (result.Jul && result.Jul.length) {
      const sum = result.Jul.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.Jul.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }
    if (result.Aug && result.Aug.length) {
      const sum = result.Aug.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.Aug.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }
    if (result.Sep && result.Sep.length) {
      const sum = result.Sep.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.Sep.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }
    if (result.Oct && result.Oct.length) {
      const sum = result.Oct.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.Oct.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }
    if (result.Nov && result.Nov.length) {
      const sum = result.Nov.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.Nov.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }
    if (result.Dec && result.Dec.length) {
      const sum = result.Dec.reduce((accumulator, object) => {
        return accumulator + object.diffMins;
      }, 0);
      this.mttrMonthlyValues.push(sum / result.Dec.length);
    } else {
      this.mttrMonthlyValues.push(0);
    }

    const mttrCategoryGroup = _.mapValues(
      _.groupBy(this.rowData, 'WOName'),
      (clist) => clist.map((car) => _.omit(car, 'make'))
    );
    let x: any;
    this.mttrCategorySumValue = [];
    for (x in mttrCategoryGroup) {
      const mttrcategorysum = mttrCategoryGroup[x].reduce(
        (accumulator, object) => {
          return accumulator + object.diffMins;
        },
        0
      );

      this.mttrCategorySumValue.push({ name: x, y: mttrcategorysum });
    }

    this.setMinorData();
  }
  setMinorData() {
    const monthName = (item) =>
      moment(item.CreatedAt, 'YYYY-MM-DD').format('MMM');
    this.minorMonthlyValues = [];
    const minorvalues = [];
    if (this.rowData && this.rowData.length) {
      this.rowData.map((res: any) => {
        res.CreatedAt = moment(res.CreatedAt).format('DD/MM/YYYY hh:mm a');
        res.ClosedAt = res.ClosedAt ? moment(res.ClosedAt).format('DD/MM/YYYY hh:mm a') : res.ClosedAt; 
        if (res.diffMins < 720) {
          minorvalues.push(res);
        }
      });
    }
    if (minorvalues && minorvalues.length) {
      const result = _.groupBy(minorvalues, monthName);
      if (result.Jan && result.Jan.length) {
        const sum = result.Jan.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.Jan.length);
      } else {
        this.minorMonthlyValues.push(0);
      }
      if (result.Feb && result.Feb.length) {
        const sum = result.Feb.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.Feb.length);
      } else {
        this.minorMonthlyValues.push(0);
      }
      if (result.Mar && result.Mar.length) {
        const sum = result.Mar.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.Mar.length);
      } else {
        this.minorMonthlyValues.push(0);
      }
      if (result.Apr && result.Apr.length) {
        const sum = result.Apr.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.Apr.length);
      } else {
        this.minorMonthlyValues.push(0);
      }
      if (result.May && result.May.length) {
        const sum = result.May.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.May.length);
      } else {
        this.minorMonthlyValues.push(0);
      }
      if (result.Jun && result.Jun.length) {
        const sum = result.Jun.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.Jun.length);
      } else {
        this.minorMonthlyValues.push(0);
      }
      if (result.Jul && result.Jul.length) {
        const sum = result.Jul.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.Jul.length);
      } else {
        this.minorMonthlyValues.push(0);
      }
      if (result.Aug && result.Aug.length) {
        const sum = result.Aug.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.Aug.length);
      } else {
        this.minorMonthlyValues.push(0);
      }
      if (result.Sep && result.Sep.length) {
        const sum = result.Sep.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.Sep.length);
      } else {
        this.minorMonthlyValues.push(0);
      }
      if (result.Oct && result.Oct.length) {
        const sum = result.Oct.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.Oct.length);
      } else {
        this.minorMonthlyValues.push(0);
      }
      if (result.Nov && result.Nov.length) {
        const sum = result.Nov.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.Nov.length);
      } else {
        this.minorMonthlyValues.push(0);
      }
      if (result.Dec && result.Dec.length) {
        const sum = result.Dec.reduce((accumulator, object) => {
          return accumulator + object.diffMins;
        }, 0);
        this.minorMonthlyValues.push(sum / result.Dec.length);
      } else {
        this.minorMonthlyValues.push(0);
      }

      const mttrCategoryGroup = _.mapValues(
        _.groupBy(minorvalues, 'WOName'),
        (clist) => clist.map((car) => _.omit(car, 'make'))
      );
      let x: any;
      this.minorCategoryValues = [];
      for (x in mttrCategoryGroup) {
        const mttrcategorysum = mttrCategoryGroup[x].reduce(
          (accumulator, object) => {
            return accumulator + object.diffMins;
          },
          0
        );

        this.minorCategoryValues.push({ name: x, y: mttrcategorysum });
      }
    }
  }
}
