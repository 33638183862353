import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DefectActionService {
  baseUrl: string = environment.O3CoreApiUrl;
  O3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

addDefectActions(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'DefectAction/AddDefectActions', data);
}

getDefectActions(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'DefectAction/getAllDefectActions', data);
}

getDefectActions2(data: any): Observable<any> {
  return this.http.post<any[]>(this.o3CoreApiUrl + 'Defect/GetDefectActions', data);
}

deleteDefectActions(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'DefectAction/DeleteDefectAction', data);
}

setNewTargetDates(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'DefectAction/SetNewTargetDate', data);
}

setUsersActionsCompleted(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'DefectAction/SetUserActionsCompleted', data);
}

setUsersActionsRejected(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'DefectAction/setUserActionsRejected', data);
}

setUsersActionsVerified(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'DefectAction/setUserActionsVerified', data);
}

setActionRemarks(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'DefectAction/setDefectActionRemarks', data);
}
setGembaActionImage(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'DefectAction/DefectActionImage', data);
}

getDepartmentsMachine(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'DMS/GetDepartmentsMachine', data);
}
}
