import { NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { PlantGroupElement } from 'src/app/interface/plant-model';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { CrewScheduleService } from 'src/app/services/crewSchedule.service';
import { QualityParameterService } from 'src/app/services/quality-parameter.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import * as moment from 'moment';
import { OleService } from 'src/app/services/ole.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

interface IStaffAttendence {
  PuId: number;
  ShiftId: number;
  // RoleId: number;
  EmployeeId: number;
  ProductionDate: string;
  TimeIn: string;
  TimeOut: string;
  UserId: number;
}

@Component({
  selector: 'app-staff-attendence',
  templateUrl: './staff-attendence.component.html',
  styleUrls: ['./staff-attendence.component.scss'],
})
export class StaffAttendenceComponent implements OnInit {
  @ViewChild('displayAttendenceModal')
  private displayAttendenceModal!: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('myInput')
  public myInputVariable!: ElementRef;
  public inputEmpty = true;
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  breadcrumList!: any;
  currentImagePath: any;
  UnitName!: any;
  MachineId!: any;
  maxDate!: Date;
  group!: any[];
  shift!: any[];
  Plants!: any[];
  Roles!: any[];
  Users!: any[];
  tempData!: any[];
  fileSave!: any;
  arrayBuffer!: any;
  filelist!: any[];
  today = new Date();
  shiftId!: number;
  updateOrderprocessingData = false;
  isPlantGroupModelVisible = false;

  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );

  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  _alldata!: any[];
  attendenceForm!: FormGroup;
  attendenceViewForm!: FormGroup;
  staffAttendenceDataSource = new MatTableDataSource<any>([]);
  staffAttendenceColumns: any[] = [
    'actions',
    'productiondate',
    'line',
    'machine',
    // 'role',
    'employee',
    'timein',
    'timeout',
    'shift',

  ];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private platModelService: EnterPriseGroupService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private userManagementService: UserManagementService,
    private enterpriseGroupService: EnterPriseGroupService,
    private qualityParameterService: QualityParameterService,
    private oleService: OleService,
    private crewService: CrewScheduleService,
    public router: Router,
    private machineService: MachineMappingService,
    private kpiMaster: KpimasterService
  ) { }

  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    const d = new Date();
    d.setDate(d.getDate() - 7);
    this.attendenceForm = this.fb.group({
      group: [''],
      plant: [''],
      unit: [''],
      shiftDate: [new Date()],
      shift: [''],
      role: [''],
      employee: [''],
      productiondate: [new Date()],
      fromDate: [d],
      toDate: [new Date()],
    });
    this.maxDate = new Date();
    this.maxDate.setHours(23, 59, 0, 0);
    this.attendenceViewForm = this.fb.group({
      group: [''],
      unit: [''],
      shift: [''],
      role: [''],
      employee: [''],
      productiondate: ['', [Validators.required]],
      timein: ['', Validators.required],
      timeout: ['', Validators.required],
      line: [''],
    });
    this.getGroup();
    // this.getStaffAttendence();
  }

  exportAsXLSX(): void {
    if (
      this.attendenceForm.value.plant != null &&
      this.attendenceForm.value.shift != null &&
      // this.attendenceForm.value.role != null &&
      this.UnitName !== undefined &&
      this.attendenceForm.value.employee != null
    ) {
      this.tempData = [];
      const shift = this.shift.find(x => x.CrewId === this.shiftId)
      for (let i = 0; i < this.Users.length; i++) {
        this.tempData[i] = {
          EmployeeName: this.Users[i].UserName,
          ProductionDate: this.attendenceForm.value.productiondate,
          // TimeIn:this.attendenceForm.get('timein')?.setValue(this.shift[0].ShiftStart),
          // TimeOut:this.attendenceForm.get('timeout')?.setValue(this.shift[0].ShiftEnd),

          TimeIn: (new Date(shift.ShiftStart)),
          TimeOut: (new Date(shift.ShiftEnd)),
        };
      }
      this.oleService.exportAsExcelFile(this.tempData, 'AttendanceTemplate');
    } else {
      this.toaster.error('Error', 'Please select mandatory fields');
    }
  }

  getGroup(): void {
    const key = {
      Id: 0,
    };
    this.qualityParameterService.getGroupData(key).subscribe(
      (data: any) => {
        this.group = data.masterList;
        this.attendenceForm.get('group')?.setValue(this.group[0].TypeId);
        this.getPlants();
        // this.getRoles();
      },
      (error: any) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  onChangeGroupGetMachine(): void {
    this.UnitName = ' ';
    this.getPlants(); // this.getRoles();
    this.staffAttendenceDataSource.data = [];
  }

  onChangePlant(data: any): void {
    // this.getShifts();
    this.getStaffAttendence();
  }

  onChangeShift(data: any): void {
    this.shiftId = data.value;
    this.getUsers();
  }

  onChangeRole(data: any): void {
    // this.getUserList();
  }

  onChangeRoleFromPage(): void {
    // this.getUsers();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getShifts(): void {
    const key = {
      PUID: this.attendenceForm.value.unit,
      Date: (this.attendenceForm.value.shiftDate),
    };
    this.crewService.GetCrewScheduleList(key).then((data: any) => {
      this.shift = data.CrewScheduleList;
      this.attendenceForm.get('shift')?.setValue(this.shift[0].CrewId);
      this.shiftId = this.shift[0].CrewId;
      this.getUsers();
      this.getStaffAttendence();
    });
  }

  onChangeUser(): void {
    this.getStaffAttendence();
  }

  // Roles and User
  // getRoles(): any {
  //   const key = {
  //     egId: this.attendenceForm.value.group
  //   };
  //   this.userManagementService.masterroles(key).subscribe(
  //     (data: any) => {
  //       this.Roles = data;
  //       this.attendenceViewForm.get('role')?.setValue(this.Roles[0].roleId);
  //       this.attendenceForm.get('role')?.setValue(this.Roles[0].roleId);
  //       // this.getUserList();
  //     },
  //     (error: any) => {
  //       this.toaster.error('Error', error.error.message);
  //     }
  //   );
  // }

  getUsers(): any {
    const key = {
      CrewId: this.attendenceForm.value.shift,
      CSDId: 0,
    };
    this.crewService.GetCSUserList(key).subscribe(
      (res: any) => {
        this.Users = res.CSUsersList;
        this.attendenceViewForm.get('employee')?.setValue(this.Users[0].UserId);
        this.attendenceForm.get('employee')?.setValue(this.Users[0].UserId);
        this.getStaffAttendence();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getUserList(): any {
    const key = {
      roleId: this.attendenceViewForm.value.role,
    };
    this.kpiMaster.getuser(key).then(
      (users: any) => {
        this.Users = users;
        this.attendenceViewForm.get('employee')?.setValue(this.Users[0].UserId);
        this.attendenceForm.get('employee')?.setValue(this.Users[0].UserId);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onFromDateChange(): void {
    this.getStaffAttendence();
  }

  onShiftDateChange(): void {
    this.getShifts();
  }

  onToDateChange(): void {
    this.getStaffAttendence();
  }

  // STAFF ATTENDENCE CRUD
  getStaffAttendence(): void {
    this.spinner.show();
    const data = {
      shiftId: this.attendenceForm.value.shift,
      unitId: this.attendenceForm.value.unit,
      fromDate: this.attendenceForm.value.fromDate,
      toDate: this.attendenceForm.value.toDate,
      employeeId: this.attendenceForm.value.employee,
    };

    this.oleService.getStaffAttendence(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        // res.TimeIn = moment(new Date(res.TimeIn)).format('HH:mm:ss');
        // res.TimeOut = moment(new Date(res.TimeOut)).format('HH:mm:ss');
        this.staffAttendenceDataSource = new MatTableDataSource<any>(res);
        console.log()
        setTimeout(() => {
          this.staffAttendenceDataSource.paginator = this.paginator;
          this.staffAttendenceDataSource.sort = this.sort;
        }, 1000);
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  saveStaffAttendence(): void {
    this.spinner.show();

    // const strTime = moment(this.attendenceViewForm.value.timein).utc().format('HH:mm:ss');
    const strTime = this.attendenceViewForm.value.timein
    const endTime = this.attendenceViewForm.value.timeout

    const data: IStaffAttendence = {
      PuId: this.attendenceForm.value.unit,
      ShiftId: this.attendenceForm.value.shift,
      // RoleId: this.attendenceForm.value.role,
      EmployeeId: this.attendenceViewForm.value.employee,
      ProductionDate: this.attendenceForm.value.productiondate,
      TimeIn: strTime,
      TimeOut: endTime,
      UserId: JSON.parse(localStorage.user).UserId,
    };
    if (this.attendenceViewForm.invalid) {
      this.spinner.hide();
      return;
    }
    this.oleService.addStaffAttendence(data).subscribe(
      (res: any) => {
        this.dialog.closeAll();
        this.spinner.hide();
        if (res.message.toLowerCase() === 'successful') {
          this.toaster.success('Added Successfully');
        } else {
          this.toaster.error(res.message);
        }
        this.getStaffAttendence();
        this.resetFields();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  addFile(event: any): void {
    debugger;
    if (
      this.attendenceForm.value.plant != null &&
      this.attendenceForm.value.shift != null &&
      // this.attendenceForm.value.role != null &&
      this.UnitName !== undefined
    ) {
      // tslint:disable-next-line:new-parens
      const formData = new FormData();
      formData.append('file', this.fileSave);

      this.filelist.forEach((element) => {
        element.ProductionDate = element.ProductionDate;
        // element.TimeIn = element.TimeIn;
        // element.TimeOut = element.TimeOut;
        // element.TimeIn = (element.TimeIn);
        // // console.log('Element.timein', element.TimeIn)
        // element.TimeOut = new Date(element.TimeOut);
        // console.log('Element.timeOut', element.TimeOut)
        element.PuId = this.attendenceForm.value.unit;
        // element.RoleId = this.attendenceForm.value.role;
        element.ShiftId = this.attendenceForm.value.shift;
        element.EmployeeId = 0;
        this.Users.forEach((ele) => {
          if (
            ele.UserName.toLowerCase() === element.EmployeeName.toLowerCase()
          ) {
            element.EmployeeId = ele.UserId;
          }
        });
      });
      const data = {
        userId: JSON.parse(localStorage.user).UserId,
        excelData: this.filelist,
      };
      this.spinner.show();
      this.oleService.addAttendenceFile(data).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.getStaffAttendence();
          this.myInputVariable.nativeElement.value = '';
          this.inputEmpty = true;
          if (res.message.toLowerCase() === 'successful') {
            this.toaster.success('Added Successfully');
          } else {
            this.toaster.error(res.message);
          }
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      this.toaster.error('Error', 'Please select mandatory fields');
    }
  }

  uploadedFile(event: any): void {
    if (event.target.files.length > 0) {
      this.fileSave = event.target.files[0];
    }
    // this.file= event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.fileSave);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      // tslint:disable-next-line:variable-name
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      this.filelist = [];
      this.filelist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.inputEmpty = false;
    };
  }

  deleteStaffAttendence(data: any): void {
    this.spinner.show();
    const key = {
      staffId: data.Id,
    };
    this.oleService.deleteStaffAttendence(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.toaster.success('Deleted Successfully');
        this.getStaffAttendence();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  // TREE MODAL
  openTreeModal(): void {
    this.updateOrderprocessingData = true;
    const key = {
      EGId: this.attendenceForm.value.group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        // tslint:disable-next-line:no-shadowed-variable
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.isPlantGroupModelVisible = true;
        this.expandAllNode();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  openAttendenceModal(): void {
    if (
      this.UnitName !== undefined &&
      this.UnitName !== '' &&
      this.attendenceForm.value.shift !== ''
    ) {
      this.dialog.open(this.displayAttendenceModal, {
        height: '50%',
        width: '60%',
        disableClose: true,
      });
      this.bindfieldsOnOpenModal();
    } else {
      this.toaster.error('Error', 'Please select Unit and Shift ');
    }
  }

  getPlants(): void {
    const key = {
      GroupId: this.attendenceForm.value.group,
    };

    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.Plants = data.DepartList;
        this.attendenceForm.get('plant')?.setValue(this.Plants[0].DeptId);
        // this.getShifts();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  SelectUnitName(unit: any): void {
    if (unit.Level === 'Unit') {
      this.UnitName = unit.text;
      this.staffAttendenceDataSource.data = [];
      this.attendenceForm.get('unit')?.setValue(unit.PrimaryId);
      this.getStaffAttendence();
    }
  }

  onNodeSelect(node: any): void {
    if (node.Level === 'Unit') {
      this.MachineId = node.PrimaryId;
      this.attendenceForm.get('unit')?.setValue(node.PrimaryId);
      this.UnitName = node.text;
      this.updateOrderprocessingData = false;
      // this.staffAttendenceDataSource.data = [];
      this.getStaffAttendence();
      this.getShifts();
    }

  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }

  closePlantGroupModel(): void {
    this.updateOrderprocessingData = false;
  }

  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  // Private function
  bindfieldsOnOpenModal(): void {
    this.attendenceViewForm.get('unit')?.setValue(this.UnitName);
    this.attendenceViewForm
      .get('shift')
      ?.setValue(this.attendenceForm.value.shift);
    this.attendenceViewForm
      .get('employee')
      ?.setValue(this.attendenceForm.value.employee);
    // this.attendenceViewForm
    //   .get('role')
    //   ?.setValue(this.attendenceForm.value.role);
    this.attendenceViewForm
      .get('productiondate')
      ?.setValue(this.attendenceForm.value.productiondate);
    // this.getRoles();
  }

  resetFields(): void {
    this.attendenceViewForm.get('productiondate')?.setValue('');
    this.attendenceViewForm.get('timein')?.setValue('');
    this.attendenceViewForm.get('timeout')?.setValue('');
    // this.attendenceViewForm.get('')?.setValue();
  }

  reset(): void {
    this.UnitName = '';
    this.attendenceForm.get('unit')?.reset();
    this.attendenceForm.get('shift')?.reset();
    this.attendenceForm.get('role')?.reset();
    this.attendenceForm.get('employee')?.reset();
    this.attendenceForm.get('productiondate')?.setValue(new Date());
    this.attendenceForm.get('fromDate')?.setValue(new Date());
    this.attendenceForm.get('toDate')?.setValue(new Date());
    this.attendenceForm.get('shiftDate')?.setValue(new Date());

    this.getGroup();
  }
}
