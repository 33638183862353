import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TechnicianService } from 'src/app/services/technician.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CILMasterService } from 'src/app/services/cil-master-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-tech-score',
  templateUrl: './tech-score.component.html',
  styleUrls: ['./tech-score.component.scss']
})
export class TechScoreComponent implements OnInit {

  technicianList: any = []
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('imageViewModal') private imageViewModal!: TemplateRef<any>;
  ImageUrl: string = "";
  blobUrl: string = "";
  spinnerText: string = "";
  technicianColumns: string[] = ['UserId', 'AverageScore', 'GroupName', 'PlantName', 'Picture', 'Actions'];
  technicianDataSource = new MatTableDataSource();
  userList: any = [];
  IsWrite: boolean = false;
  HeaderForm!: FormGroup;
  HeaderData: any = { Group: '', Plant: '' };
  GroupList: any = [];
  filteredGroupList: any = [];
  PlantList: any = [];
  filteredPlantList: any = [];
  breadcrumList: any;
  img: any;
  constructor( 
    private technicianService: TechnicianService, 
    private enterpriseGroupService: EnterPriseGroupService, 
    private router: Router, 
    private toaster: ToastrService, 
    private spinner: NgxSpinnerService, 
    private fb: FormBuilder, 
    private cilMasterService: CILMasterService, 
    private userManagementService: UserManagementService,
    private commonService: CommonService,
    private dialog:MatDialog) {
    this.HeaderForm = this.fb.group({
      Group: new FormControl(null, [Validators.required]),
      Plant: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.blobUrl = environment.O3CoreApiImageUrl;
    this.GetBreadcrumList();
    this.GetMenuAccess();
    let TechnicianSkillMatrixHeaderData = this.technicianService.getSkillMatrixHeaderData();
    if (TechnicianSkillMatrixHeaderData && TechnicianSkillMatrixHeaderData.Group) {
      this.HeaderData.Group = TechnicianSkillMatrixHeaderData.Group;
      this.HeaderData.Plant = TechnicianSkillMatrixHeaderData.Plant;
    }
    this.getGroups();
  }

  searchValues(filter: any, type: string) {
    filter = filter.target.value;
    if (type == 'group') {
      this.filteredGroupList = this.GroupList.filter((group: any) => {
        let lowerCase = group.name.toLocaleLowerCase();
        return group.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
    else if (type == 'plant') {
      this.filteredPlantList = this.PlantList.filter((plant: any) => {
        let lowerCase = plant.name.toLocaleLowerCase();
        return plant.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  imgview(e:any){
   this.img=e.target.src;
   let dialogRef = this.dialog.open(this.imageViewModal, {
    height: '90%',
    width: '90%',
  });
  dialogRef.afterClosed().subscribe((result) => {});
  }
  oncloseimg(){
    this.dialog.closeAll();
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  getGroups() {
    const key = {
      UserId: ''
    };
    const id = {
      value: 0
    };
    this.cilMasterService.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.dropDownList;
      this.filteredGroupList = [...this.GroupList];
      if (this.filteredGroupList && this.filteredGroupList.length) {
        if (this.HeaderData.Group) {
          const find = this.filteredGroupList.find((grp: any) => grp.id == this.HeaderData.Group);
          this.HeaderData.Group = find ? find.id : this.filteredGroupList[0].id
        }
        else
          this.HeaderForm.get('Group')?.setValue(this.filteredGroupList[0].id);
        this.getPlants(this.HeaderData.Group)
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(id: any): void {
    this.cilMasterService.getPlantsData(id).subscribe((data: any) => {
      this.PlantList = data.dropDownList;
      this.filteredPlantList = [...this.PlantList];
      if (this.filteredPlantList && this.filteredPlantList.length) {
        if (this.HeaderData.Plant) {
          const find = this.filteredPlantList.find((pnt: any) => pnt.id == this.HeaderData.Plant);
          this.HeaderData.Plant = find ? find.id : this.filteredPlantList[0].id
        }
        else
          this.HeaderForm.get('Plant')?.setValue(this.PlantList[0].id);
        this.getTechnicianList();
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUserList() {
    this.spinnerText = "Fetching User Data";
    this.spinner.show();
    const data = { EGId: this.HeaderForm.value.Group, PlantId: this.HeaderForm.value.Plant };
    this.commonService.getPeopleMasterData(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.employeeInfoList && res.employeeInfoList.length)
        this.userList = res.employeeInfoList.filter((emp: any) => emp.Dept_Desc == 'Maintenance' && emp.UserId);

      if (this.userList && this.userList.length) {
        for (let tech of this.technicianList) {
          const user = this.userList.find((usr: any) => usr.UserId == tech.UserId);
          if (user)
            tech.UserName = user.fullName;
        }
      }
      else
        this.technicianList = []

      if (this.technicianList && this.technicianList.length)
        this.technicianList = this.technicianList.filter((emp: any) => emp.UserName);

      this.technicianDataSource = new MatTableDataSource(this.technicianList);
      this.technicianDataSource.sort = this.sort;
      this.technicianDataSource.paginator = this.paginator;

    }, (err: any) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  getTechnicianList(): void {
    this.spinner.show();
    this.spinnerText = "Fetching Technician Data";
    this.technicianService.getTechnicianList(this.HeaderData).subscribe((res: any) => {
      this.spinner.hide();
      this.technicianList = [];
      if (res.data && res.data.length) {
        for (let user of res.data) {
          const usr = this.technicianList.find((usr: any) => usr.UserId == user.UserId);
          if (!usr)
            this.technicianList.push(user)
        }
      }
      this.technicianDataSource = new MatTableDataSource(this.technicianList);
      this.technicianDataSource.sort = this.sort;
      this.technicianDataSource.paginator = this.paginator;
      this.getUserList();
    },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.error.message);
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.technicianDataSource.filter = filterValue.trim().toLowerCase();

    if (this.technicianDataSource.paginator) {
      this.technicianDataSource.paginator.firstPage();
    }
  }

  editTechician(techinican: any, imageUrl: string) {
    this.technicianService.setTechnicianId(techinican);
    this.technicianService.setTechnicianImage(imageUrl);
    const data = {
      Group: this.HeaderData.Group,
      Plant: this.HeaderData.Plant,
    }
    this.technicianService.setSkillMatrixHeaderData(data);
    this.router.navigate(['/PM/skill-matrix/score-form']);
  }

  editTechicianHistory(selTech: any) {
    this.technicianService.setSelTech(selTech);
    this.router.navigate(['/PM/skill-matrix/technision-history']);
  }

}
