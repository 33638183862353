import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ScoreTableService } from 'src/app/services/score-table.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
@Component({
  selector: 'app-score-table',
  templateUrl: './score-table.component.html',
  styleUrls: ['./score-table.component.scss']
})
export class ScoreTableComponent implements OnInit {

  @Input() IsWrite!: boolean;

  @ViewChild(MatTable) table!: MatTable<Pillar>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayMAchineModal = false;
  pillars!: Pillar[];
  pillarId!: number;
  plants!: Plantbygroup[];
  scoreform!: FormGroup;
  formMode!: boolean;
  plantId!: number;
  groupId!: number;
  displayUserModal = false;
  isUpdateScore: boolean = false;
  plantsDDL!: any[];
  scoreTypeDDL!: any[];
  moduleId!: any;
  pageId!: any;
  breadcrumList: any;
  auditcatColumns: string[] = ['ptDesc', 'score', 'name', 'areaActions'];
  scoreData = new MatTableDataSource<any>([]);
  getareaMaster: any;
  constructor(
    private fb: FormBuilder,
    private machineService: MachineMappingService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private sccoreService: ScoreTableService,
  ) {}

  ngOnInit(): void {
    this.formMode = true;
    this.scoreform = this.fb.group({
      score: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      plantId: ['', Validators.required],
      scoreId: [''],
      group: ['']
    });
  }

  getPaginator(): void {
    setTimeout(() => {
      this.scoreData.paginator = this.paginator;
      this.scoreData.sort = this.sort;
    }, 1000);
  }


  getAllPlants(): void {
    const key = {
      GroupId: this.groupId
    };
    this.machineService.getPlantsData(key).subscribe(
      (plants: any) => {
        this.plantsDDL = plants.DepartList;
        this.scoreform.get('plantId')?.setValue(this.plantId);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.scoreData.filter = filterValue.trim().toLowerCase();

    if (this.scoreData.paginator) {
      this.scoreData.paginator.firstPage();
    }
  }

  getScoreMaster(key: any): void {
    this.spinner.show();
    this.groupId = key.groupId;
    this.plantId = key.plantId;
    this.scoreform.get('group')?.setValue(key.groupId);
    this.sccoreService.getScores(key).subscribe((data) => {
      this.scoreData.data = data;
      this.getPaginator();
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });
  }

  getScoreType(): void {
    this.spinner.show();
    this.sccoreService.getScoreTypes().subscribe((data) => {
      this.scoreTypeDDL = data;
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error(error.error.Message);
        this.spinner.hide();
      });
  }

  opensideModal(): void {

    this.getAllPlants();
    this.getScoreType();

    this.formMode = true;
    this.reset();
    this.isUpdateScore = false;
    this.displayUserModal = true;
  }
  closeaddNewPlantItemModel(): void {
    this.displayUserModal = false;
  }

  saveScore(): void {
    const name = this.scoreTypeDDL.find(x => x.score == this.scoreform.value.score).name;
    if (!this.isUpdateScore) {
      let data = {
        plantId: this.scoreform.get('plantId')?.value,
        score: this.scoreform.value.score,
        name: name,
      }
      this.sccoreService.addScore(data).subscribe((response: any) => {
        if(response.message == 'Name' + data.name + ' is already taken'){
          this.toaster.warning('warning',response.message);
        }
        else{
          this.toaster.success('Success', 'Score Added Successfully');
          this.reset();
          this.closeaddNewPlantItemModel();
          const key = {
            groupId: this.groupId,
            plantId: data.plantId
          };
          this.getScoreMaster(key);
        }
      }, (error) => {
        this.toaster.error('Error', error.error.message);
      });
    } else {
      let data = {
        plantId: this.scoreform.get('plantId')?.value,
        score: this.scoreform.value.score,
        name: name,
        scoreId: this.scoreform.get('scoreId')?.value
      }
      this.sccoreService.updateScore(data).subscribe((response: any) => {
        if(response.message == 'Name' + data.name + ' is already taken'){
          this.toaster.warning('warning',response.message)
        }
        else{
          this.toaster.success('Success', 'Score Updated Successfully');
          this.closeaddNewPlantItemModel();
          this.isUpdateScore = false;
          this.reset();
          const key = {
            groupId: this.groupId,
            plantId: data.plantId
          };
          this.getScoreMaster(key);
        }
      }, (error) => {
        this.toaster.error('Error', error.error.message);
      })
    }
  }
  onClickUpdateScore(element: any): void {
    this.getAllPlants();
    this.getScoreType();
    this.isUpdateScore = true;
    this.formMode = false;
    this.scoreform.get('score')?.setValue(element.score);
    this.scoreform.get('plantId')?.setValue(element.ptId);
    this.scoreform.get('scoreId')?.setValue(element.scoreId);
    this.displayUserModal = true;
  }

  onClickDeleteScore(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Master Score!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        var id = {
          scoreId: element.scoreId
        }
        this.sccoreService.deleteScore(id).subscribe(
          (data: any) => {
            const key = {
              plantId: element.ptId,
              groupId: this.groupId
            };
            this.getScoreMaster(key);
            this.toaster.success(data.message);
          },
          (error: any) => {
            this.toaster.error('Error', error.error.Message);
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Master Score is safe :)', 'error');
      }
    });
  }

  reset(): void {
    this.scoreform.reset();
  }
}
