import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MicrotaskService {
  o3ApiUrl = environment.O3CoreApiUrl;
  baseUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }
  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3ApiUrl + 'Common/GetDepartments', data);
  }
  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3ApiUrl + 'Common/GetGroup', data);
  }
  getFTEMicroTasksData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'getmicrotask', data);
  }
  addFTEMicroTasksData(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'insertmicrotask', data);
  }
}
