<ngx-spinner
  bdColor="rgba(0, 0, 0, 0)"
  template=" <img src='assets/images/spinner.gif' />"
>
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>

<div class="content-top-box">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a
        class="breadcrumb-link"
        [routerLink]="breadcrumItem.Url"
        [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
        >{{ breadcrumItem.text }}</a
      >
    </li>
  </ul>
</div>

<div class="header-tab">
  <mat-tab-group
    #tabHeaderChange
    [selectedIndex]="selectedHeaderIndex"
    (selectedTabChange)="tabHeaderChanged($event)"
  >
    <mat-tab label="RCA"> </mat-tab>
    <mat-tab label="SWOT & PEST"> </mat-tab>
  </mat-tab-group>
</div>

<div class="container" style="margin-top: 25px">
  <form
    name="historyHeaderForm"
    [formGroup]="historyHeaderForm"
    #formDirective="ngForm"
    (ngSubmit)="onSearch()"
  >
    <div class="d-flex-field headerForm">
      <mat-form-field appearence="fill" class="w-22">
        <mat-label>Group</mat-label>
        <mat-select
          [(ngModel)]="historyHeaderData.Group"
          formControlName="group"
          (selectionChange)="getPlants()"
        >
          <mat-select-filter
            [placeholder]="'Search Group'"
            [displayMember]="'TypeName'"
            [array]="groupList"
            (filteredReturn)="groupFiltList = $event"
          >
          </mat-select-filter>
          <mat-option
            *ngFor="let group of groupFiltList"
            [value]="group.TypeId"
          >
            {{ group.TypeName }}
          </mat-option>
        </mat-select>
        <mat-error>Group is required</mat-error>
      </mat-form-field>

      <mat-form-field appearence="fill" class="w-22">
        <mat-label>Plant</mat-label>
        <mat-select
          [(ngModel)]="historyHeaderData.Plant"
          formControlName="plant"
          (selectionChange)="getDepartments(); getTeamList()"
        >
          <mat-select-filter
            [placeholder]="'Search Plant'"
            [displayMember]="'DeptDesc'"
            [array]="plantList"
            (filteredReturn)="plantFiltList = $event"
          >
          </mat-select-filter>
          <mat-option
            *ngFor="let plant of plantFiltList"
            [value]="plant.DeptId"
          >
            {{ plant.DeptDesc }}
          </mat-option>
        </mat-select>
        <mat-error>Plant is required</mat-error>
      </mat-form-field>

      <mat-form-field appearence="fill" class="w-22">
        <mat-label>Department</mat-label>
        <mat-select
          [(ngModel)]="historyHeaderData.Department"
          formControlName="department"
          (selectionChange)="getUniqueCode()"
        >
          <mat-select-filter
            [placeholder]="'Search Department'"
            [displayMember]="'TypeName'"
            [array]="deparmentList"
            (filteredReturn)="deparmentFiltList = $event"
          >
          </mat-select-filter>
          <mat-option value="ALL">ALL</mat-option>
          <mat-option
            *ngFor="let dept of deparmentFiltList"
            [value]="dept.TypeId"
          >
            {{ dept.TypeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearence="fill" class="w-22">
        <mat-label>Team Leader</mat-label>
        <mat-select
          #selectTeamLeader
          [(ngModel)]="historyHeaderData.TeamLeader"
          formControlName="teamLeader"
          (selectionChange)="setTeamLeaderChange(historyHeaderData.TeamLeader)"
          multiple
        >
          <mat-select-filter
            #selectTeamLeaderFilter
            *ngIf="
              selectTeamLeader.focused ? true : setTeam();
            "
            [placeholder]="'Search User'"
            [displayMember]="'FullName'"
            [array]="teamList"
            (keyup)="keyUp()"
            (filteredReturn)="teamFiltTeams = $event"
          >
          </mat-select-filter>
          <mat-option #allSelected (click)="toggleAllSelection()" value="ALL"
            >ALL</mat-option
          >
          <mat-option
            *ngFor="let user of teamFiltTeams"
            [value]="user.EmpInfo_Id"
            (click)="tosslePerOne(); setTeamAndValidate(user.EmpInfo_Id)"
          >
            {{ user.FirstName }} {{ user.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field appearence="fill" class="w-22">
        <mat-label>RCA Code</mat-label>
        <mat-select [(ngModel)]="historyHeaderData.RCACode" formControlName="rcaCode">
          <mat-select-filter [placeholder]="'Search RCA Code'" [displayMember]="'FullName'"
                        [array]="uniqueCodeList" (filteredReturn)="uniqueCodeFiltList = $event">
                    </mat-select-filter>
          <mat-option *ngFor="let code of uniqueCodeFiltList" [value]="code.ID">
            AM - RCA - {{code.ID}} {{code.ID}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- <mat-form-field appearence="fill" class="w-22">
        <mat-label>Module</mat-label>
        <mat-select [(ngModel)]="historyHeaderData.Module" formControlName="module">
          <mat-option value="AnalyticalTools">Analytical Tools</mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- <mat-form-field appearence="fill" class="w-70" style="width: 32%;">
        <mat-label>Initial Problem Statement</mat-label>
        <input matInput [(ngModel)]="historyHeaderData.InitProbStatement" formControlName="initProbStat">
      </mat-form-field> -->

      <mat-form-field appearence="fill" class="w-22">
        <mat-label>Status</mat-label>
        <mat-select
          [(ngModel)]="historyHeaderData.Status"
          formControlName="status"
        >
          <mat-option value="ALL">ALL</mat-option>
          <mat-option value="Open">Open</mat-option>
          <mat-option value="InProgress">InProgress</mat-option>
          <mat-option value="NotClosed">Complete</mat-option>
          <mat-option value="Completed">Closed</mat-option>
          <mat-option value="Delayed">Delayed</mat-option>
          <mat-option value="Rejected">Rejected</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-22">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            [(ngModel)]="historyHeaderData.FromDate"
            formControlName="fromDate"
            placeholder="From Date"
          />
          <input
            matEndDate
            [(ngModel)]="historyHeaderData.ToDate"
            formControlName="toDate"
            placeholder="To Date"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field appearence="fill" class="w-32">
        <mat-label>Search</mat-label>
        <input matInput type="text" (keyup)="onFilterChanged($event.target)" />
      </mat-form-field>

      <div class="head-btn">
        <button
          type="submit"
          class="save"
          tooltipPosition="top"
          matTooltip="Search"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.3609 18.2168L14.6008 13.2662C15.8247 11.8113 16.4953 9.98069 16.4953 8.07499C16.4953 3.62251 12.8728 0 8.4203 0C3.96782 0 0.345306 3.62251 0.345306 8.07499C0.345306 12.5275 3.96782 16.15 8.4203 16.15C10.0918 16.15 11.6847 15.6458 13.0466 14.6888L17.8428 19.677C18.0432 19.8852 18.3129 20 18.6018 20C18.8753 20 19.1348 19.8957 19.3317 19.7061C19.7502 19.3034 19.7636 18.6357 19.3609 18.2168ZM8.4203 2.10652C11.7114 2.10652 14.3888 4.78391 14.3888 8.07499C14.3888 11.3661 11.7114 14.0435 8.4203 14.0435C5.12921 14.0435 2.45183 11.3661 2.45183 8.07499C2.45183 4.78391 5.12921 2.10652 8.4203 2.10652Z"
              fill="#10123D"
            />
          </svg>
        </button>
        <button
          type="button"
          class="save"
          [ngClass]="{
            'rca-btn-wid': selectedHeaderIndex == 0,
            'swpe-btn-wid': selectedHeaderIndex == 1
          }"
          (click)="
            setHeaderID(0);
            selectedHeaderIndex == 0
              ? createNewRCAstagesScreen()
              : createNewSWOTPESTScreen()
          "
          tooltipPosition="top"
        >
          <!-- <mat-icon>add</mat-icon> -->
          <p *ngIf="selectedHeaderIndex == 0">Create New RCA</p>
          <p *ngIf="selectedHeaderIndex == 1">Create New SWOT & PEST</p>
        </button>
        <!-- <button type="button" class="save"
          [ngClass]="{'rca-btn-wid' : selectedHeaderIndex == 0 , 'swpe-btn-wid' : selectedHeaderIndex == 1}"
          (click)="selectedHeaderIndex == 0 ? createNewRCAScreen() : createNewSWOTPESTScreen()" tooltipPosition="top">
          <p *ngIf="selectedHeaderIndex == 0">Create New RCA</p>
          <p *ngIf="selectedHeaderIndex == 1">Create New SWOT & PEST</p>
        </button> -->
        <button
          type="button"
          class="save"
          style="width: 100px; font-weight: 500"
          tooltipPosition="top"
          (click)="
            selectedHeaderIndex == 0
              ? dashboardRCAScreen()
              : dashboardSWOTPESTScreen()
          "
        >
          <!-- <mat-icon>add</mat-icon> -->
          Dashboard
        </button>
      </div>
    </div>
  </form>

  <div *ngIf="selectedHeaderIndex == 0" class="menu-card-group">
    <div class="menu-card-item-four mat-card-height">
      <p class="title-text">
        Total RCA's - {{ rowData && rowData.length ? rowData.length : "0" }}
      </p>
      <!-- <p class="data-text primary-color"> Open : {{OpenRca.Count}} - {{OpenRca.Percentage}}% </p>
      <p class="data-text primary-color"> InProgress : {{InprogressRca.Count}} - {{InprogressRca.Percentage}}% </p>
      <p class="data-text primary-color"> Closed : {{ClosedRca.Count}} - {{ClosedRca.Percentage}}% </p> -->
      <!-- <div class="dash-table">
        <table>
          <tbody>
            <tr>
              <td class="text-left"> Open : </td>
              <td class="text-left"> {{OpenRca.Count}} - {{OpenRca.Percentage}}% </td>
            </tr>
            <tr>
              <td class="text-left"> InProgress : </td>
              <td class="text-left"> {{InprogressRca.Count}} - {{InprogressRca.Percentage}}% </td>
            </tr>
            <tr>
              <td class="text-left"> Complete : </td>
              <td class="text-left"> {{CompletedRca.Count}} - {{CompletedRca.Percentage}}% </td>
            </tr>
            <tr>
              <td class="text-left"> Delayed : </td>
              <td class="text-left"> {{DelayedRca.Count}} - {{DelayedRca.Percentage}}% </td>
            </tr>
            <tr>
              <td class="text-left"> Closed : </td>
              <td class="text-left"> {{ClosedRca.Count}} - {{ClosedRca.Percentage}}% </td>
            </tr>
          </tbody>
        </table>
      </div> -->

      <div class="dash-table">
        <table>
          <tbody>
            <tr>
              <td class="text-center">Open</td>
              <td class="text-center">InProgress</td>
              <td class="text-center">Complete</td>
            </tr>
            <tr style="border-bottom: 10px solid transparent">
              <td class="text-center">
                {{ OpenRca.Count }} - {{ OpenRca.Percentage }}%
              </td>
              <td class="text-center">
                {{ InprogressRca.Count }} - {{ InprogressRca.Percentage }}%
              </td>
              <td class="text-center">
                {{ CompletedRca.Count }} - {{ CompletedRca.Percentage }}%
              </td>
            </tr>
            <tr>
              <td class="text-center">Delayed</td>
              <td class="text-center">Closed</td>
              <td class="text-center">Rejected</td>
            </tr>
            <tr>
              <td class="text-center">
                {{ DelayedRca.Count }} - {{ DelayedRca.Percentage }}%
              </td>
              <td class="text-center">
                {{ ClosedRca.Count }} - {{ ClosedRca.Percentage }}%
              </td>
              <td class="text-center">
                {{ RejectedRca.Count }} - {{ RejectedRca.Percentage }}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="menu-bottom-icon">
        <img class="img" [src]="menuBlueIconUrl" alt="{{ menuBlueIconUrl }}" />
      </div>
    </div>
    <div class="menu-card-item-four mat-card-height">
      <p class="title-text">
        Total Actions -
        {{ TotalAction && TotalAction.length ? TotalAction.length : "0" }}
      </p>
      <!-- <p class="data-text primary-color"> Open : {{OpenRca.Count}} - {{OpenRca.Percentage}}% </p>
      <p class="data-text primary-color"> InProgress : {{InprogressRca.Count}} - {{InprogressRca.Percentage}}% </p>
      <p class="data-text primary-color"> Closed : {{ClosedRca.Count}} - {{ClosedRca.Percentage}}% </p> -->
      <!-- <div class="dash-table">
        <table>
          <tbody>
            <tr>
              <td class="text-left"> Open : </td>
              <td class="text-left"> {{OpenAction.Count}} - {{OpenAction.Percentage}}% </td>
            </tr>
            <tr>
              <td class="text-left"> InProgress : </td>
              <td class="text-left"> {{InprogressAction.Count}} - {{InprogressAction.Percentage}}% </td>
            </tr>
            <tr>
              <td class="text-left"> Complete : </td>
              <td class="text-left"> {{CompleteAction.Count}} - {{CompleteAction.Percentage}}% </td>
            </tr>
            <tr>
              <td class="text-left"> Delayed : </td>
              <td class="text-left"> {{DelayedAction.Count}} - {{DelayedAction.Percentage}}% </td>
            </tr>
            <tr>
              <td class="text-left"> Closed : </td>
              <td class="text-left"> {{ClosedAction.Count}} - {{ClosedAction.Percentage}}% </td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <div class="dash-table">
        <table>
          <tbody>
            <tr>
              <td class="text-center">Open</td>
              <td class="text-center">InProgress</td>
              <td class="text-center">Complete</td>
            </tr>
            <tr style="border-bottom: 10px solid transparent">
              <td class="text-center">
                {{ OpenAction.Count }} - {{ OpenAction.Percentage }}%
              </td>
              <td class="text-center">
                {{ InprogressAction.Count }} -
                {{ InprogressAction.Percentage }}%
              </td>
              <td class="text-center">
                {{ CompleteAction.Count }} - {{ CompleteAction.Percentage }}%
              </td>
            </tr>
            <tr>
              <td class="text-center">Delayed</td>
              <td class="text-center">Closed</td>
              <td class="text-center">ReOpened</td>
            </tr>
            <tr>
              <td class="text-center">
                {{ DelayedAction.Count }} - {{ DelayedAction.Percentage }}%
              </td>
              <td class="text-center">
                {{ ClosedAction.Count }} - {{ ClosedAction.Percentage }}%
              </td>
              <td class="text-center">
                {{ ReOpenedAction.Count }} - {{ ReOpenedAction.Percentage }}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="menu-bottom-icon">
        <img class="img" [src]="menuBlueIconUrl" alt="{{ menuBlueIconUrl }}" />
      </div>
    </div>
    <div class="menu-card-item-two mat-card-height">
      <p class="title-text">RCA By Stages</p>
      <!-- <p class="data-text primary-color"> Open : {{OpenRca.Count}} - {{OpenRca.Percentage}}% </p>
      <p class="data-text primary-color"> InProgress : {{InprogressRca.Count}} - {{InprogressRca.Percentage}}% </p>
      <p class="data-text primary-color"> Closed : {{ClosedRca.Count}} - {{ClosedRca.Percentage}}% </p> -->
      <div class="dash-table" style="width: unset">
        <table style="width: 90%">
          <thead>
            <tr>
              <th class="text-center"></th>
              <th class="text-center">6W2H</th>
              <th class="text-center">FishBone</th>
              <th class="text-center">Cause&Effect</th>
              <th class="text-center">5Why</th>
              <th class="text-center">Action Plan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-right">Open :</td>
              <td class="text-center">{{ Dash6W2H.Open }}</td>
              <td class="text-center">{{ DashFishBone.Open }}</td>
              <td class="text-center">{{ DashCauseEffect.Open }}</td>
              <td class="text-center">{{ Dash5Why.Open }}</td>
              <td class="text-center">{{ DashActionPlan.Open }}</td>
            </tr>
            <tr>
              <td class="text-right">InProgress :</td>
              <td class="text-center">{{ Dash6W2H.InProgress }}</td>
              <td class="text-center">{{ DashFishBone.InProgress }}</td>
              <td class="text-center">{{ DashCauseEffect.InProgress }}</td>
              <td class="text-center">{{ Dash5Why.InProgress }}</td>
              <td class="text-center">{{ DashActionPlan.InProgress }}</td>
            </tr>
            <tr>
              <td class="text-right">Complete :</td>
              <td class="text-center">{{ Dash6W2H.Completed }}</td>
              <td class="text-center">{{ DashFishBone.Completed }}</td>
              <td class="text-center">{{ DashCauseEffect.Completed }}</td>
              <td class="text-center">{{ Dash5Why.Completed }}</td>
              <td class="text-center">{{ DashActionPlan.Completed }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="menu-bottom-icon">
        <img class="img" [src]="menuBlueIconUrl" alt="{{ menuBlueIconUrl }}" />
      </div>
    </div>
  </div>

  <div *ngIf="selectedHeaderIndex == 0" style="margin-top: 20px">
    <ag-grid-angular
      style="width: 100%"
      class="ag-theme-alpine"
      [rowData]="rowData"
      [columnDefs]="columnDefsRCA"
      [domLayout]="domLayout"
      (gridReady)="onGridReady($event)"
      (cellClicked)="cellClicked($event)"
      [gridOptions]="gridOptions"
      [pagination]="true"
      [paginationPageSize]="10"
      [frameworkComponents]="frameworkComponents"
      [suppressRowClickSelection]="true"
    >
    </ag-grid-angular>
  </div>
  <!-- <div *ngIf="selectedHeaderIndex == 1" style="margin-top: 20px;">
    <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [rowData]="historicalDataList"
      [columnDefs]="columnDefsSWOTPEST" [domLayout]="domLayout" (gridReady)="onGridReady($event)"
      (cellClicked)="cellClicked($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10"
      [frameworkComponents]="frameworkComponents" [suppressRowClickSelection]="true">
    </ag-grid-angular>
  </div> -->
</div>

<ng-template #participantsListModal>
  <!-- <div mat-dialog-title class="mat-title">
        <div class="d-flex justify-content-between align-items-center">
            <h1 class="main-title">Participants List</h1>
            <button mat-mini-fab mat-button mat-dialog-close color="warn" [disabled]="!IsWrite" title="close">
                <i class="fa fa-times" style="color: #fff"></i>
            </button>
        </div>
    </div> -->
  <div class="table-div">
    <table>
      <thead>
        <tr>
          <th>Participants</th>
          <!-- <th>Positions / Role</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let participants of dialogParticipantsList; let i = index">
          <td>
            {{ participants.fullName }}
            <span *ngIf="participants.isLeader"> (Leader)</span>
          </td>
          <!-- <td>QC Leader</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #viewAtStages>
  <div class="d-flex justify-content-end">
    <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
      <i class="fa fa-times" style="color: #fff; margin-bottom: 6px"></i>
    </button>
  </div>
  <mat-tab-group
    #tabChange
    [selectedIndex]="selectedIndex"
    (selectedTabChange)="tabChanged($event)"
  >
    <mat-tab [disabled]="isDisabled('6W2H')" label="6W2H">
      <ng-template mat-tab-label>
        6W2H
        <span>{{
          isDisabled("6W2H")
            ? "-"
            : viewStageData && viewStageData.Status6W2H
            ? viewStageData.Status6W2H
            : "Open"
        }}</span>
      </ng-template>
      <!-- 6W2H -->
      <div style="width: 100%; height: 75vh; overflow: auto">
        <div style="width: 100%; display: flex; align-items: baseline">
          <div class="box">
            <div class="box-title">
              <h4>What <span>*</span></h4>
              <p>Describe the phenomenon. What Do You see?</p>
            </div>
            <div
              title="{{
                viewStageData && viewStageData.swth && viewStageData.swth.What
              }}"
            >
              <mat-form-field class="sixwtwoh-w-100">
                <textarea
                  matInput
                  readonly
                  [(ngModel)]="
                    viewStageData &&
                    viewStageData.swth &&
                    viewStageData.swth.What
                  "
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="box">
            <div class="box-title">
              <h4>Where <span>*</span></h4>
              <p>The transformations where the loss occurs (Where not)</p>
            </div>
            <div
              title="{{
                viewStageData && viewStageData.swth && viewStageData.swth.Where
              }}"
            >
              <mat-form-field class="sixwtwoh-w-100">
                <textarea
                  matInput
                  readonly
                  [(ngModel)]="
                    viewStageData &&
                    viewStageData.swth &&
                    viewStageData.swth.Where
                  "
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div style="width: 100%; display: flex; align-items: baseline">
          <div class="box">
            <div class="box-title">
              <h4>When <span>*</span></h4>
              <p>Startup, Normal Operation: when did the problem start</p>
            </div>
            <div
              title="{{
                viewStageData && viewStageData.swth && viewStageData.swth.When
              }}"
            >
              <mat-form-field class="sixwtwoh-w-100">
                <textarea
                  matInput
                  readonly
                  [(ngModel)]="
                    viewStageData &&
                    viewStageData.swth &&
                    viewStageData.swth.When
                  "
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="box">
            <div class="box-title">
              <h4>Which <span>*</span></h4>
              <p>Brands, SKU'S, Formats, Material's affected (which not)</p>
            </div>
            <div
              title="{{
                viewStageData && viewStageData.swth && viewStageData.swth.Which
              }}"
            >
              <mat-form-field class="sixwtwoh-w-100">
                <textarea
                  matInput
                  readonly
                  [(ngModel)]="
                    viewStageData &&
                    viewStageData.swth &&
                    viewStageData.swth.Which
                  "
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div style="width: 100%; display: flex; align-items: baseline">
          <div class="box">
            <div class="box-title">
              <h4>Who <span>*</span></h4>
              <p>Variation among teams, people, other...</p>
            </div>
            <div
              title="{{
                viewStageData && viewStageData.swth && viewStageData.swth.Who
              }}"
            >
              <mat-form-field class="sixwtwoh-w-100">
                <textarea
                  matInput
                  readonly
                  [(ngModel)]="
                    viewStageData &&
                    viewStageData.swth &&
                    viewStageData.swth.Who
                  "
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="box">
            <div class="box-title">
              <h4>How <span>*</span></h4>
              <p>Circumstances of the loss; Failure Module</p>
            </div>
            <div
              title="{{
                viewStageData && viewStageData.swth && viewStageData.swth.How
              }}"
            >
              <mat-form-field class="sixwtwoh-w-100">
                <textarea
                  matInput
                  readonly
                  [(ngModel)]="
                    viewStageData &&
                    viewStageData.swth &&
                    viewStageData.swth.How
                  "
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div style="width: 100%; display: flex; align-items: baseline">
          <div class="box">
            <div class="box-title">
              <h4>How Much</h4>
              <p>Magnitude Number, Frequency amount...</p>
            </div>
            <div
              title="{{
                viewStageData &&
                  viewStageData.swth &&
                  viewStageData.swth.HowMuch
              }}"
            >
              <mat-form-field class="sixwtwoh-w-100">
                <textarea
                  matInput
                  readonly
                  [(ngModel)]="
                    viewStageData &&
                    viewStageData.swth &&
                    viewStageData.swth.HowMuch
                  "
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="box">
            <div class="box-title">
              <h4>To Whom</h4>
              <p>Lines, Systems, Operations affected:</p>
            </div>
            <div
              title="{{
                viewStageData && viewStageData.swth && viewStageData.swth.ToWhom
              }}"
            >
              <mat-form-field class="sixwtwoh-w-100">
                <textarea
                  matInput
                  readonly
                  [(ngModel)]="
                    viewStageData &&
                    viewStageData.swth &&
                    viewStageData.swth.ToWhom
                  "
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div style="width: 100%; display: flex">
          <div class="box" style="width: 98%">
            <div class="bg-primary">
              <h4>New Refocused Problem Statement <span>*</span></h4>
            </div>
            <div
              title="{{
                viewStageData &&
                  viewStageData.swth &&
                  viewStageData.swth.ProblemStatement
              }}"
            >
              <mat-form-field class="sixwtwoh-w-100">
                <textarea
                  matInput
                  readonly
                  [(ngModel)]="
                    viewStageData &&
                    viewStageData.swth &&
                    viewStageData.swth.ProblemStatement
                  "
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab [disabled]="isDisabled('FishBone')" label="FishBone">
      <ng-template mat-tab-label>
        FishBone
        <span>{{
          isDisabled("FishBone")
            ? "-"
            : viewStageData && viewStageData.StatusFishbone
            ? viewStageData.StatusFishbone
            : "Open"
        }}</span>
      </ng-template>
      <!-- FishBone -->
      <div style="height: 75vh; overflow: auto">
        <div class="fishbone">
          <div class="fishbone-left">
            <div class="fishbone-top-3m">
              <div class="box">
                <div class="box-title">
                  <div>
                    <h4>Machine</h4>
                    <mat-slide-toggle
                      disabled
                      #MachineryToggle
                      class="example-margin"
                      [checked]="MachineryChecked"
                    >
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="box-body">
                  <mat-form-field
                    appearence="fill"
                    *ngFor="let machinery of mList.machineryList; let i = index"
                  >
                    <mat-select
                      [(ngModel)]="mType.machinery[i]"
                      disabled
                      *ngIf="machinery.ID"
                    >
                      <mat-option
                        [ngClass]="{ 'd-none': !machinery.ID }"
                        *ngFor="let machinery of machineryList[i]"
                        [value]="machinery.ID"
                      >
                        {{ machinery.Name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="box">
                <div class="box-title">
                  <div>
                    <h4>Material</h4>
                    <mat-slide-toggle
                      disabled
                      #MaterialToggle
                      class="example-margin"
                      [checked]="MaterialChecked"
                    >
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="box-body">
                  <mat-form-field
                    appearence="fill"
                    *ngFor="let material of mList.materialList; let i = index"
                  >
                    <mat-select
                      [(ngModel)]="mType.material[i]"
                      disabled
                      *ngIf="material.ID"
                    >
                      <mat-option
                        [ngClass]="{ 'd-none': !material.ID }"
                        *ngFor="let material of materialList[i]"
                        [value]="material.ID"
                      >
                        {{ material.Name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="box">
                <div class="box-title">
                  <div>
                    <h4>Man</h4>
                    <mat-slide-toggle
                      disabled
                      #ManToggle
                      class="example-margin"
                      [checked]="ManChecked"
                    >
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="box-body">
                  <mat-form-field
                    appearence="fill"
                    *ngFor="let man of mList.manList; let i = index"
                  >
                    <mat-select
                      [(ngModel)]="mType.man[i]"
                      disabled
                      *ngIf="man.ID"
                    >
                      <mat-option
                        [ngClass]="{ 'd-none': !man.ID }"
                        *ngFor="let man of manList[i]"
                        [value]="man.ID"
                      >
                        {{ man.Name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="fishbone-center"></div>
            <div class="fishbone-bottom-3m">
              <div class="box">
                <div class="box-title">
                  <div>
                    <h4>Method</h4>
                    <mat-slide-toggle
                      disabled
                      #MethodToggle
                      class="example-margin"
                      [checked]="MethodChecked"
                    >
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="box-body">
                  <mat-form-field
                    appearence="fill"
                    *ngFor="let method of mList.methodList; let i = index"
                  >
                    <mat-select
                      [(ngModel)]="mType.method[i]"
                      disabled
                      *ngIf="method.ID"
                    >
                      <mat-option
                        [ngClass]="{ 'd-none': !method.ID }"
                        *ngFor="let method of methodList[i]"
                        [value]="method.ID"
                      >
                        {{ method.Name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="box">
                <div class="box-title">
                  <div>
                    <h4>Mother Nature</h4>
                    <mat-slide-toggle
                      disabled
                      #MotherNatureToggle
                      class="example-margin"
                      [checked]="MotherNatureChecked"
                    ></mat-slide-toggle>
                  </div>
                </div>
                <div class="box-body">
                  <mat-form-field
                    appearence="fill"
                    *ngFor="
                      let motherNature of mList.motherNatureList;
                      let i = index
                    "
                  >
                    <mat-select
                      [(ngModel)]="mType.motherNature[i]"
                      disabled
                      *ngIf="motherNature.ID"
                    >
                      <mat-option
                        [ngClass]="{ 'd-none': !motherNature.ID }"
                        *ngFor="let motherNature of motherNatureList[i]"
                        [value]="motherNature.ID"
                      >
                        {{ motherNature.Name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="box">
                <div class="box-title">
                  <div>
                    <h4>Management</h4>
                    <mat-slide-toggle
                      disabled
                      #ManagementToggle
                      class="example-margin"
                      [checked]="ManagementChecked"
                    >
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="box-body">
                  <mat-form-field
                    appearence="fill"
                    *ngFor="
                      let management of mList.managementList;
                      let i = index
                    "
                  >
                    <mat-select
                      [(ngModel)]="mType.management[i]"
                      disabled
                      *ngIf="management.ID"
                    >
                      <mat-option
                        [ngClass]="{ 'd-none': !management.ID }"
                        *ngFor="let management of managementList[i]"
                        [value]="management.ID"
                      >
                        {{ management.Name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div
            class="fishbone-right"
            title="{{
              viewStageData &&
                viewStageData.fishBone &&
                viewStageData.fishBone.ProblemStatement
            }}"
          >
            <h4>New Refocused Problem Statement</h4>
            <mat-form-field class="fish-w-100">
              <textarea
                matInput
                rows="20"
                cols="10"
                [(ngModel)]="
                  viewStageData &&
                  viewStageData.fishBone &&
                  viewStageData.fishBone.ProblemStatement
                "
                readonly
              ></textarea>
            </mat-form-field>
            <img
              *ngIf="RefocusedUploadFile"
              (click)="imageZoomModal('', 'refocusedImage')"
              [src]="ImageUrl + RefocusedUploadFile"
              alt=""
              width="195px"
              height="130px"
            />
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab [disabled]="isDisabled('Cause & Effect')" label="Cause & Effect">
      <ng-template mat-tab-label>
        Cause & Effect
        <span>{{
          isDisabled("Cause & Effect")
            ? "-"
            : viewStageData && viewStageData.StatusCauseEffect
            ? viewStageData.StatusCauseEffect
            : "Open"
        }}</span>
      </ng-template>
      <!-- Cause & Effect -->
      <div
        class="table-wrapper"
        style="margin: 1% 0; height: 75vh; overflow: auto"
      >
        <table
          mat-table
          [dataSource]="causeEffectModelDataSource"
          class="basic-table cause-effect-model-table"
          matSort
        >
          <ng-container matColumnDef="MType">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-min-width"
            >
              <p class="header-text">Contributing Source</p>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="m-type"
              [ngClass]="{
                'bg-salmon': element.M == 1,
                'bg-cadetblue': element.M == 2,
                'bg-cornflowerblue': element.M == 3,
                'bg-slategray': element.M == 4,
                'bg-burlywood': element.M == 5,
                'bg-coral': element.M == 6
              }"
            >
              {{
                element && element.displayMType
                  ? element.displayMType
                  : element.MType
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Priority">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Priority</p>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="priority"
              style="width: 75px"
            >
              {{ element.Priority }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ProbableCause">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Probable Cause</p>
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <div title="{{ element.ProbableCause }}">
                {{
                  element.ID || element.fishBoneDataNotInCause
                    ? getCauseValue(element.ProbableCause)
                    : ""
                }}
              </div>
              <mat-form-field
                *ngIf="!element.ID && !element.fishBoneDataNotInCause"
                class="w-100"
                style="padding: 0 15px"
              >
                <input
                  matInput
                  readonly
                  type="text"
                  [(ngModel)]="element.ProbableCause"
                />
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="Weight">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Weight</p>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              style="margin: 0 10px 0 10px; width: 175px"
              nowrap
            >
              <mat-radio-group
                disabled
                aria-label="Select an option"
                controlName="Weight"
                [(ngModel)]="element.Weight"
              >
                <mat-radio-button
                  *ngFor="let chk of Weigths"
                  [value]="chk.value"
                  [checked]="chk.checked"
                  >{{ chk.value }}
                </mat-radio-button>
              </mat-radio-group>
            </td>
          </ng-container>
          <ng-container matColumnDef="ValidationResult">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="grp-order"
            >
              <p class="header-text">Validation Result</p>
            </th>
            <td mat-cell *matCellDef="let element">
              <div title="{{ element.ValidationResult }}">
                <mat-form-field class="w-100" style="padding: 0 15px">
                  <input
                    matInput
                    type="text"
                    readonly
                    [(ngModel)]="element.ValidationResult"
                  />
                </mat-form-field>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="Image">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-min-width"
            >
              <p class="header-text">Image</p>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              class="cause-identified"
            >
              <div class="imgflex" style="position: relative; display: flex">
                <img
                  *ngIf="element.FileSrc || element.UploadFile"
                  (click)="imageZoomModal(i, 'causeEffect')"
                  [src]="element.FileSrc || ImageUrl + element.UploadFile"
                  alt="image"
                  width="60px"
                  height="40px"
                />
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="CauseIdentified">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-min-width"
            >
              <p class="header-text">Cause Identified(Yes/No)</p>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              class="cause-identified"
            >
              <mat-slide-toggle disabled [(ngModel)]="element.CauseIdentified">
              </mat-slide-toggle>
            </td>
          </ng-container>
          <ng-container matColumnDef="Remark">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-min-width"
            >
              <p class="header-text">Remarks</p>
            </th>
            <td mat-cell *matCellDef="let element">
              <div title="{{ element.Remark }}">
                <mat-form-field class="w-100" style="padding: 0 15px">
                  <input
                    matInput
                    type="text"
                    readonly
                    [(ngModel)]="element.Remark"
                  />
                </mat-form-field>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="causeEffectModelColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: causeEffectModelColumns"
          ></tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab [disabled]="isDisabled('5 Why')" label="5 Why">
      <ng-template mat-tab-label>
        5 Why
        <span>{{
          isDisabled("5 Why")
            ? "-"
            : viewStageData && viewStageData.StatusFiveWhy
            ? viewStageData.StatusFiveWhy
            : "Open"
        }}</span>
      </ng-template>
      <!-- 5 Why -->
      <div style="margin: 1% 0; height: 75vh; overflow: auto">
        <table>
          <thead>
            <tr>
              <th
                *ngFor="let column of fiveWhyColumns"
                style="color: #0f0fd1; padding: 1% 10px"
              >
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let yData of this.viewStageData.fiveWhy; let i = index">
              <td
                *ngIf="this.viewStageData.stageId != 4 && !yData.spanRow"
                [attr.rowspan]="yData.rowSpan ? yData.rowSpan : 1"
                [ngClass]="{
                  'bg-salmon': yData.MType == 1,
                  'bg-cadetblue': yData.MType == 2,
                  'bg-cornflowerblue': yData.MType == 3,
                  'bg-slategray': yData.MType == 4,
                  'bg-burlywood': yData.MType == 5,
                  'bg-coral': yData.MType == 6
                }"
                style="color: white; padding: 0 80px 0 10px"
              >
                {{
                  yData && yData.displayMType
                    ? yData.displayMType
                    : yData.MType == 1
                    ? "Machine"
                    : yData.MType == 2
                    ? "Material"
                    : yData.MType == 3
                    ? "Man"
                    : yData.MType == 4
                    ? "Method"
                    : yData.MType == 5
                    ? "Mother Nature"
                    : "Management"
                }}
              </td>
              <td
                *ngIf="this.viewStageData.stageId != 4 && !yData.spanRow"
                [attr.rowspan]="yData.rowSpan ? yData.rowSpan : 1"
              >
                {{ yData.Priority }}
              </td>
              <td
                *ngIf="!yData.spanRow"
                [attr.rowspan]="yData.rowSpan ? yData.rowSpan : 1"
                [ngClass]="
                  this.viewStageData.stageId != 4
                    ? 'causeField1'
                    : 'causeField2'
                "
              >
                <mat-form-field
                  class="w-250px"
                  *ngIf="
                    !yData.ValidationEditFalse &&
                    !yData.sub &&
                    (!yData.ID || !yData.causeNotEmpty)
                  "
                >
                  <textarea
                    matInput
                    type="text"
                    [(ngModel)]="yData.ValidationFromCauseEffects"
                    readonly
                  ></textarea>
                </mat-form-field>
                <div title="{{ yData.ValidationFromCauseEffects }}">
                  <span
                    *ngIf="
                      yData.ValidationEditFalse ||
                      yData.sub ||
                      (yData.ID && yData.causeNotEmpty)
                    "
                    >{{
                      yData.ValidationFromCauseEffects
                        ? getShortData(yData.ValidationFromCauseEffects)
                        : ""
                    }}</span
                  >
                </div>
              </td>
              <td
                *ngIf="this.viewStageData.stageId != 4 && !yData.spanRow"
                [attr.rowspan]="yData.rowSpan ? yData.rowSpan : 1"
              >
                <span *ngIf="!yData.sub">{{ yData.Weight }}</span>
                <span *ngIf="yData.sub">{{ yData.Weight }}</span>
              </td>
              <td>
                <div
                  class="d-flex align-center w-350px"
                  *ngIf="!yData.parentCheck || yData.parentCheck <= 1"
                  title="{{ yData.Why1 }}"
                >
                  <mat-form-field class="w-300px">
                    <textarea
                      matInput
                      readonly
                      type="text"
                      value="{{ yData.Why1 }}"
                      [(ngModel)]="yData.Why1"
                      name="Why1_{{ i }}"
                    ></textarea>
                  </mat-form-field>
                  <mat-slide-toggle
                    [(ngModel)]="yData.check1"
                    name="check1_{{ i }}"
                    disabled
                  >
                  </mat-slide-toggle>
                </div>
              </td>
              <td>
                <div
                  class="d-flex align-center w-350px"
                  *ngIf="!yData.parentCheck || yData.parentCheck <= 2"
                  title="{{ yData.Why2 }}"
                >
                  <mat-form-field class="w-300px">
                    <textarea
                      matInput
                      readonly
                      type="text"
                      value="{{ yData.Why2 }}"
                      [(ngModel)]="yData.Why2"
                      name="Why2_{{ i }}"
                    ></textarea>
                  </mat-form-field>
                  <mat-slide-toggle
                    [(ngModel)]="yData.check2"
                    name="check2_{{ i }}"
                    disabled
                  >
                  </mat-slide-toggle>
                </div>
              </td>
              <td>
                <div
                  class="d-flex align-center w-350px"
                  *ngIf="!yData.parentCheck || yData.parentCheck <= 3"
                  title="{{ yData.Why3 }}"
                >
                  <mat-form-field class="w-300px">
                    <textarea
                      matInput
                      readonly
                      type="text"
                      value="{{ yData.Why3 }}"
                      [(ngModel)]="yData.Why3"
                      name="Why3_{{ i }}"
                    ></textarea>
                  </mat-form-field>
                  <mat-slide-toggle
                    [(ngModel)]="yData.check3"
                    name="check3_{{ i }}"
                    disabled
                  >
                  </mat-slide-toggle>
                </div>
              </td>
              <td>
                <div
                  class="d-flex align-center w-350px"
                  *ngIf="!yData.parentCheck || yData.parentCheck <= 4"
                  title="{{ yData.Why4 }}"
                >
                  <mat-form-field class="w-300px">
                    <textarea
                      matInput
                      readonly
                      type="text"
                      value="{{ yData.Why4 }}"
                      [(ngModel)]="yData.Why4"
                      name="Why4_{{ i }}"
                    ></textarea>
                  </mat-form-field>
                  <mat-slide-toggle
                    [(ngModel)]="yData.check4"
                    name="check4_{{ i }}"
                    disabled
                  >
                  </mat-slide-toggle>
                </div>
              </td>
              <td>
                <div
                  class="d-flex align-center w-350px"
                  *ngIf="!yData.parentCheck || yData.parentCheck <= 5"
                  title="{{ yData.Why5 }}"
                >
                  <mat-form-field class="w-300px">
                    <textarea
                      matInput
                      readonly
                      type="text"
                      value="{{ yData.Why5 }}"
                      [(ngModel)]="yData.Why5"
                      name="Why5_{{ i }}"
                    ></textarea>
                  </mat-form-field>
                  <mat-slide-toggle
                    [(ngModel)]="yData.check5"
                    name="check5_{{ i }}"
                    disabled
                  >
                  </mat-slide-toggle>
                </div>
              </td>
              <td>
                <div title="{{ yData.RootCause }}">
                  <mat-form-field class="w-300px">
                    <textarea
                      matInput
                      readonly
                      type="text"
                      value="{{ yData.RootCause }}"
                      [(ngModel)]="yData.RootCause"
                      name="RootCause_{{ i }}"
                    ></textarea>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div>
                  <mat-form-field class="w-130px m-select">
                    <mat-select [(ngModel)]="yData.TypeOfM" disabled>
                      <mat-option value="Machine">Machine</mat-option>
                      <mat-option value="Material">Material</mat-option>
                      <mat-option value="Man">Man</mat-option>
                      <mat-option value="Method">Method</mat-option>
                      <mat-option value="MotherNature">MotherNature</mat-option>
                      <mat-option value="Management">Management</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <!-- <td>
                          <div class="row-action-btns" style="min-width: 85px;">
                            <button mat-icon-button class="button" [disabled]="!IsWrite" (click)="addRow(i, 'sub')"
                              tooltipPosition="top" matTooltip="Sub Row">
                              <mat-icon class="menu subrow">add_circle</mat-icon>
                              <mat-icon class="menu subrow subwidth">add_circle</mat-icon>
                            </button>
                            <button mat-icon-button class="btn-main" [disabled]="!yData.MainRowShow || !IsWrite"
                              (click)="addRow(i, 'main')"
                              *ngIf="(!rcaId && (levelId == 0 || levelId == 4 || levelId == 5)) && !yData.sub" tooltipPosition="top"
                              matTooltip="New Row">
                              <mat-icon class="menu mainrow">add_circle </mat-icon>
                            </button>
                            <button mat-icon-button class="del-button" [disabled]="!IsWrite" (click)="deleteRow(i)"
                              *ngIf="yData.delete" tooltipPosition="top" matTooltip="Delete Row">
                              <mat-icon class="menu">delete</mat-icon>
                            </button>
                          </div>
                        </td>
                        <td>
                          <div class="row-action-btns" *ngIf="yData.readyForAP">
                            <button mat-icon-button class="action-plan" (click)="addActionPlan(yData.ID,this.headerData)"
                              tooltipPosition="top" matTooltip="5 Why Action Plan">
                              {{'>'}}
                            </button>
                          </div>
                        </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>
    <mat-tab
      [disabled]="isDisabled('5 Why Action Plan')"
      label="5 Why Action Plan"
    >
      <ng-template mat-tab-label>
        5 Why Action Plan
        <span>{{
          isDisabled("5 Why Action Plan")
            ? "-"
            : viewStageData && viewStageData.StatusFiveWhyAction
            ? viewStageData.StatusFiveWhyAction
            : "Open"
        }}</span>
      </ng-template>
      <!-- 5 Why Action Plan -->
      <div style="margin: 1% 0; height: 75vh; overflow: auto">
        <table class="fy-action-table">
          <thead>
            <tr>
              <th
                *ngFor="let column of fiveWhyActionColumns"
                style="color: #0f0fd1; padding: 1% 10px"
              >
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let yData of this.viewStageData.fiveWhyActionPlan;
                let i = index
              "
            >
              <td
                style="min-width: 200px"
                *ngIf="!yData.spanRow"
                [attr.rowspan]="yData.rowSpan ? yData.rowSpan : 1"
              >
                <p>{{ yData.RootCause }}</p>
              </td>
              <td>
                <div title="{{ yData.Action }}">
                  <mat-form-field class="w-200px">
                    <textarea
                      matInput
                      type="text"
                      readonly
                      [(ngModel)]="yData.Action"
                      name="Action_{{ i }}"
                    ></textarea>
                  </mat-form-field>
                </div>
              </td>
              <td nowrap>
                <div class="pre-cor-class">
                  <mat-radio-group
                    disabled
                    [(ngModel)]="yData.ActionType"
                    name="ActionType_{{ i }}"
                    class="d-grid"
                  >
                    <mat-radio-button value="Preventive" style="padding: 5px 0"
                      >Preventive</mat-radio-button
                    >
                    <mat-radio-button value="Corrective" style="padding: 5px 0"
                      >Corrective</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </td>
              <td>
                <div class="imgflex" style="position: relative">
                  <img
                    *ngIf="yData.FileSrc || yData.UploadFile"
                    (click)="imageZoomModal(i, 'fiveAction')"
                    [src]="yData.FileSrc || ImageUrl + yData.UploadFile"
                    alt=""
                    width="60px"
                    height="40px"
                  />
                </div>
              </td>
              <td>
                <div class="imgflex" style="position: relative">
                  <a
                    *ngIf="yData.DocSrc || yData.UploadDoc"
                    [href]="yData.DocSrc || ImageUrl + yData.UploadDoc"
                    target="_blank"
                    download
                    ><img src="assets/images/file_img.png" alt="" width="60px"
                  /></a>
                </div>
              </td>
              <td>
                <div class="d-flex">
                  <mat-form-field class="w-200px">
                    <mat-label>Select Assignee</mat-label>
                    <mat-select
                      [(ngModel)]="yData.AssignTo"
                      disabled
                      name="AssignTo_{{ i }}"
                    >
                      <mat-select-filter
                        [placeholder]="'Search User'"
                        [displayMember]="'lastName'"
                        [array]="teamList"
                        (filteredReturn)="teamFiltTeams = $event"
                      >
                      </mat-select-filter>
                      <mat-option
                        *ngFor="let user of teamFiltTeams"
                        [value]="user.EmpInfo_Id"
                      >
                        {{ user.FirstName }} {{ user.lastName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td class="text-center">
                {{ yData.AssignedDate ? (yData.AssignedDate | date) : "" }}
              </td>
              <td>
                <div class="d-flex">
                  <mat-form-field class="w-100px">
                    <mat-label>Target Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker"
                      [(ngModel)]="yData.TargetDate"
                      name="TargetDate_{{ i }}"
                      readonly
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                      #picker
                      [disabled]="yData.Status != 'Open'"
                    ></mat-datepicker>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="d-flex">
                  <mat-form-field class="w-100px">
                    <mat-label>Revised Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="revisePicker"
                      [(ngModel)]="yData.RevisedDate"
                      [min]="getMinDate(yData.TargetDate)"
                      name="RevisedDate_{{ i }}"
                      readonly
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="revisePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #revisePicker disabled></mat-datepicker>
                  </mat-form-field>
                </div>
              </td>
              <td class="text-center">
                {{ yData.NoOfMisses }}
              </td>
              <td>
                <div class="d-flex">
                  <mat-form-field class="w-100px">
                    <mat-select
                      [(ngModel)]="yData.ActionCompleted"
                      name="ActionCompleted_{{ i }}"
                      disabled
                    >
                      <mat-option value="Yes">Yes</mat-option>
                      <mat-option value="No">No</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="d-grid text-center">
                  <p>
                    {{
                      yData.ActionCompletedBy
                        ? getUserName(yData, "actionBy")
                        : ""
                    }}
                  </p>
                  <p>
                    {{
                      yData.ActionCompletedOn
                        ? (yData.ActionCompletedOn | date)
                        : ""
                    }}
                  </p>
                </div>
              </td>
              <td>
                <div class="d-flex">
                  <mat-form-field class="w-150px">
                    <mat-label>Select Verification</mat-label>
                    <mat-select
                      [(ngModel)]="yData.Verification"
                      name="Verification_{{ i }}"
                      disabled
                    >
                      <mat-option value="Approved">Approved</mat-option>
                      <mat-option value="Disapproved">Disapproved</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="d-grid text-center w-120px">
                  <p>
                    {{
                      yData.VerificationBy
                        ? getUserName(yData, "verificationBy")
                        : ""
                    }}
                  </p>
                  <p>
                    {{
                      yData.VerificationOn ? (yData.VerificationOn | date) : ""
                    }}
                  </p>
                </div>
              </td>
              <td>
                <div class="d-flex">
                  <mat-form-field class="w-200px">
                    <mat-label>Select New Assignee</mat-label>
                    <mat-select
                      [(ngModel)]="yData.NewAssignee"
                      disabled
                      name="NewAssignee_{{ i }}"
                    >
                      <mat-select-filter
                        [placeholder]="'Search User'"
                        [displayMember]="'lastName'"
                        [array]="teamList"
                        (filteredReturn)="teamFiltTeams = $event"
                      >
                      </mat-select-filter>
                      <mat-option
                        *ngFor="let user of teamFiltTeams"
                        [value]="user.EmpInfo_Id"
                      >
                        {{ user.FirstName }} {{ user.lastName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="d-grid text-center w-100px">
                  <p>{{ yData.ClosedDate ? (yData.ClosedDate | date) : "" }}</p>
                </div>
              </td>
              <!-- <td>
                <div class="d-flex">
                  <mat-form-field class="w-100px">
                    <mat-label>Closed Date</mat-label>
                    <input matInput [matDatepicker]="closedPicker" [(ngModel)]="yData.ClosedDate"
                      name="ClosedDate_{{i}}" readonly>
                    <mat-datepicker-toggle matSuffix [for]="closedPicker"></mat-datepicker-toggle>
                    <mat-datepicker #closedPicker disabled></mat-datepicker>
                  </mat-form-field>
                </div>
              </td> -->
              <td>
                <div class="d-flex" title="{{ yData.Remarks }}">
                  <mat-form-field class="w-200px">
                    <mat-label>Remarks</mat-label>
                    <input
                      matInput
                      readonly
                      [(ngModel)]="yData.Remarks"
                      name="Remarks_{{ i }}"
                    />
                  </mat-form-field>
                </div>
              </td>
              <!-- <td>
                <section class="example-section" style="text-align: center;">
                  <mat-checkbox disabled [checked]="yData.VerifiedBy ? true : false" class="example-margin">
                  </mat-checkbox>
                </section>
              </td>
              <td>
                {{yData.VerifiedBy ? getUserName(yData) : ''}}
              </td> -->
              <!-- <td>
                <div class="d-flex">
                  <mat-form-field style="width: 200px;">
                    <mat-label>Select Status</mat-label>
                    <mat-select [(ngModel)]="yData.Status" disabled name="Status_{{i}}">
                      <mat-option value="Open">Open</mat-option>
                      <mat-option value="InProgress">InProgress</mat-option>
                      <mat-option value="Closed">Closed</mat-option>
                      <mat-option value="Delay">Delay</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td> -->
              <td class="text-center">
                {{ yData.Status }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>
    <!-- <mat-tab label="Close">
      <ng-template mat-tab-label>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </ng-template>
    </mat-tab> -->
  </mat-tab-group>
</ng-template>

<ng-template #SwotPestModal>
  <mat-tab-group>
    <mat-tab label="SWOT">
      <div style="width: 100%; height: 75vh; overflow: auto">
        <div class="swot-layout">
          <!-- <div class="subject">
            <h2> SWOT SUBJECT </h2>
            <textarea name="SwotSubject" [(ngModel)]="SwotSubject" matInput
              placeholder="Enter SWOT Subject Here"></textarea>
          </div> -->
          <!-- <div class="menu-card-group">


            <div class="margin-auto">

              <div style="flex:1;margin: 50px 0 0 0;">
                <h1 align="center"
                  style="writing-mode: tb-rl;height:395px;
                transform: rotate(-180deg);background-color:#919191;color: white;margin-bottom: 0 !important;padding: 5px;">
                  INTERNAL <br><span style="font-size: 18px;color: white;">(within organisation)</span></h1>
              </div>

              <div class="menu-card-item gr header">
                <div style="flex:1;margin: 0 5px 0 5px;">
                  <h1 align="center"
                    style="background-color:#7da556;color: white;margin-bottom: 0 !important;padding: 10px;">
                    HELPFUL <span></span></h1>
                </div>

                <div class="swotBox" style="background-color: #bdec8e">

                  <div style="display: flex;padding: 25px 20px 0 20px;">
                    <div style="flex:1;">
                      <h2>Strength <span>*</span></h2>
                    </div>
                    <div class="icon">
                      <button type="button" [disabled]="!IsWrite" class="add-btn"
                        style="background: transparent;border: none; cursor: pointer;" pTooltip="Add New Group"
                        tooltipPosition="bottom" (click)="addSwotRow(1)">
                        <img src="assets/images/add.png" alt="" width="20px" height="20px">
                      </button>
                    </div>

                  </div>
                  <div class="strength-fields box-inner-content">
                    <div style="width: 100%;">
                      <mat-form-field appearence="fill" class="w-22"
                        *ngFor="let strength of s_w_o_t.strength; let i=index">
                        <div class="d-flex justify-content-between">
                          <input matInput [(ngModel)]="newSWOT.strength[i]"
                            (change)="checkDuplicate('strength',newSWOT.strength[i], i)" name="strength_{{i}}"
                            *ngIf="!strength.ID">
                          <button class="add-btn deleteBtn" (click)="deleteSwotRow(1, i)" *ngIf="!strength.ID">
                            <mat-icon class="menu">delete</mat-icon>
                          </button>
                        </div>
                        <mat-select [(ngModel)]="SWOT.strength[i]" (selectionChange)="updateSWOTList(1, i)" #mySelect
                          name="strength_{{i}}" *ngIf="strength.ID">
                          <input (keyup)="searchValues($event, 1, i)" class="form-control"
                            placeholder="Search Strength">
                          <mat-option *ngFor="let strength of strengthList[i]" [value]="strength.ID">
                            {{strength.Name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                  </div>

                  <div class="swot-label">
                    <h1 class="swot-label-text">S</h1>
                  </div>

                  <div style="flex:1">
                    <h2 align="center" style="background-color:#0f0fd1;color: white;height:60px"
                      class="d-flex justify-content-center align-items-center">Advantages <span></span></h2>
                  </div>
                </div>

              </div>

              <div class="menu-card-item or">
                <div style="flex:1;margin: 0 5px 0 5px;">
                  <h1 align="center"
                    style="background-color:#bb824a;color: white;margin-bottom: 0 !important;padding: 10px">
                    HARMFUL <span></span></h1>
                </div>
                <div class="swotBox" style="background-color: #fec995;">
                  <div style="display: flex;padding: 25px 20px 0 20px;">
                    <div style="flex:1;">
                      <h2>Weakness <span>*</span></h2>
                    </div>
                    <div class="icon">
                      <button type="button" [disabled]="!IsWrite" class="add-btn"
                        style="background: transparent;border: none; cursor: pointer;" pTooltip="Add New Group"
                        tooltipPosition="bottom" (click)="addSwotRow(2)">
                        <img src="assets/images/add.png" alt="" width="20px" height="20px">
                      </button>

                    </div>
                  </div>
                  <div class="weakness-fields box-inner-content">
                    <div style="width: 100%;">
                      <mat-form-field appearence="fill" class="w-22"
                        *ngFor="let weakness of s_w_o_t.weakness; let i=index">
                        <div class="d-flex justify-content-between">
                          <input matInput [(ngModel)]="newSWOT.weakness[i]"
                            (change)="checkDuplicate('weakness',newSWOT.weakness[i], i)" name="weakness_{{i}}"
                            *ngIf="!weakness.ID">
                          <button class="add-btn deleteBtn" (click)="deleteSwotRow(2, i)" *ngIf="!weakness.ID">
                            <mat-icon class="menu">delete</mat-icon>
                          </button>
                        </div>
                        <mat-select [(ngModel)]="SWOT.weakness[i]" (selectionChange)="updateSWOTList(2, i)"
                          name="weakness_{{i}}" *ngIf="weakness.ID">
                          <input (keyup)="searchValues($event, 2, i)" class="form-control"
                            placeholder="Search Weakness">
                          <mat-option *ngFor="let weakness of weakNessList[i]" [value]="weakness.ID">
                            {{weakness.Name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="swot-label">
                    <h1 class="swot-label-text">W</h1>
                  </div>
                  <div style="flex:1;">
                    <h2 align="center" style="background-color:#0f0fd1;color: white;height:60px"
                      class="d-flex justify-content-center align-items-center">Disadvantages <span></span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="menu-card-group ">
            <div class="margin-auto">
              <div style="flex:1;margin: 0 0 0 0;">
                <h1 align="center"
                  style="writing-mode: tb-rl;height:395px;
                transform: rotate(-180deg);background-color:#919191;color: white;margin-bottom: 0 !important;padding: 5px;">
                  EXTERNAL <br><span style="font-size: 18px;color: white;">(outside organisation)</span></h1>
              </div>
              <div class="menu-card-item bl">
                <div class="swotBox" style="background-color: #bbd3f5;">
                  <div style="display: flex;padding: 25px 20px 0 20px;">
                    <div style="flex:1;">
                      <h2>Opportunities <span>*</span></h2>
                    </div>
                    <div class="icon">
                      <button type="button" [disabled]="!IsWrite" class="add-btn"
                        style="background: transparent;border: none; cursor: pointer;" pTooltip="Add New Group"
                        tooltipPosition="bottom" (click)="addSwotRow(3)">
                        <img src="assets/images/add.png" alt="" width="20px" height="20px">
                      </button>

                    </div>
                  </div>
                  <div class="weakness-fields box-inner-content">
                    <div style="width: 100%;">
                      <mat-form-field appearence="fill" class="w-22"
                        *ngFor="let opportunities of s_w_o_t.opportunities; let i=index">
                        <div class="d-flex justify-content-between">
                          <input matInput [(ngModel)]="newSWOT.opportunities[i]"
                            (change)="checkDuplicate('opportunities',newSWOT.opportunities[i], i)"
                            name="opportunities_{{i}}" *ngIf="!opportunities.ID">
                          <button class="add-btn deleteBtn" (click)="deleteSwotRow(3, i)" *ngIf="!opportunities.ID">
                            <mat-icon class="menu">delete</mat-icon>
                          </button>
                        </div>
                        <mat-select [(ngModel)]="SWOT.opportunities[i]" (selectionChange)="updateSWOTList(3, i)"
                          name="opportunities{{i}}" *ngIf="opportunities.ID">
                          <input (keyup)="searchValues($event, 3, i)" class="form-control"
                            placeholder="Search Opportunities">
                          <mat-option *ngFor="let opportunities of opportunitiesList[i]" [value]="opportunities.ID">
                            {{opportunities.Name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="swot-label">
                    <h1 class="swot-label-text">O</h1>
                  </div>

                  <div style="flex:1;">
                    <h2 align="center" style="background-color:#0f0fd1;color: white;height:60px"
                      class="d-flex justify-content-center align-items-center">Chance to Improve Perfomance
                      <span></span>
                    </h2>
                  </div>


                </div>
              </div>
              <div class="menu-card-item rd">
                <div class="swotBox" style="background-color: #ffb3b3;">
                  <div style="display: flex;padding: 25px 20px 0 20px;">
                    <div style="flex:1;">
                      <h2>Threats <span>*</span></h2>
                    </div>
                    <div class="icon">
                      <button type="button" [disabled]="!IsWrite" class="add-btn"
                        style="background: transparent;border: none; cursor: pointer;" pTooltip="Add New Group"
                        tooltipPosition="bottom" (click)="addSwotRow(4)">
                        <img src="assets/images/add.png" alt="" width="20px" height="20px">
                      </button>
                    </div>
                  </div>
                  <div class="threats-fields box-inner-content">
                    <div style="width: 100%;">
                      <mat-form-field appearence="fill" class="w-22"
                        *ngFor="let threats of s_w_o_t.threats; let i=index">
                        <div class="d-flex justify-content-between">
                          <input matInput [(ngModel)]="newSWOT.threats[i]"
                            (change)="checkDuplicate('threats',newSWOT.threats[i], i)" name="threats_{{i}}"
                            *ngIf="!threats.ID">
                          <button class="add-btn deleteBtn" (click)="deleteSwotRow(4, i)" *ngIf="!threats.ID">
                            <mat-icon class="menu">delete</mat-icon>
                          </button>
                        </div>
                        <mat-select [(ngModel)]="SWOT.threats[i]" (selectionChange)="updateSWOTList(4, i)"
                          name="threats_{{i}}" *ngIf="threats.ID">
                          <input (keyup)="searchValues($event, 4 , i)" class="form-control"
                            placeholder="Search Threats">
                          <mat-option *ngFor="let threats of threatsList[i]" [value]="threats.ID">
                            {{threats.Name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="swot-label">
                    <h1 class="swot-label-text">T</h1>
                  </div>

                  <div style="flex:1;">
                    <h2 align="center" style="background-color:#0f0fd1;color: white;height:60px"
                      class="d-flex justify-content-center align-items-center">External trouble for the
                      business <br> What your competitors are doing<span></span></h2>
                  </div>


                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </mat-tab>
    <mat-tab label="PEST">
      <div style="width: 100%; height: 75vh; overflow: auto"></div>
    </mat-tab>
  </mat-tab-group>
</ng-template>
