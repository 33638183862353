import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-master-data-upload',
  templateUrl: './master-data-upload.component.html',
  styleUrls: ['./master-data-upload.component.scss']
})
export class MasterDataUploadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
