import { Component, OnInit, ViewChild, TemplateRef, Injectable, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { UserRole } from 'src/app/models/userRole';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ToastrService } from 'ngx-toastr';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';

interface RoleNode {
  name: string;
  text: string;
  id?: string;
  indeterminate?: boolean;
  children?: RoleNode[];
  ischecked: boolean;
}
@Component({
  selector: 'app-userrole',
  templateUrl: './userrole.component.html',
  styleUrls: ['./userrole.component.scss']
})
export class UserroleComponent implements OnInit {
  treeControl = new NestedTreeControl<RoleNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<RoleNode>();
  @ViewChild(MatTable) table: MatTable<UserRole> | undefined;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayRoleModal!: boolean;
  userRoleForm!: FormGroup;
  userGroupForm!: FormGroup;
  groupList!: any;
  formMode = false;
  RoleId!: number;
  GroupId!: number;
  flatArray!: any;
  userRoleGroupColumns: string[] = ['RoleId', 'RoleName', 'CreatedBy', 'CreatedOn', 'userroleActions'];
  userRolesData = new MatTableDataSource<UserRole>();
  breadcrumList: any;
  filteredGroups: any;
  constructor(private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    public router: Router,
    private roleService: UserManagementService,
  ) {
  }
  hasChild = (_: number, node: RoleNode) =>
    !!node.children && node.children.length > 0
  setParent(data: any, parent: any): any {
    data.parent = parent;
    if (data.children) {
      data.children.forEach((x: any) => {
        this.setParent(x, data);
      });
    }
  }

  ngOnInit(): void {
    this.userRoleForm = this.formBuilder.group({
      role: ['', Validators.required],
      transactionTypeId: [''],
    });
    this.userGroupForm = this.formBuilder.group({
      userGroup: ['']
    });
    this.getGroupsData();
  }

  checkAllParents(node: any): any {
    if (node.parent) {
      const descendants = this.treeControl.getDescendants(node.parent);
      node.parent.ischecked = descendants.every(child => child.ischecked);
      node.parent.indeterminate = descendants.some(child => child.ischecked);
      this.checkAllParents(node.parent);
    }
  }

  todoItemSelectionToggle(checked: any, node: any): any {
    node.ischecked = checked;
    if (node.children) {
      node.children.forEach((x: any) => {
        this.todoItemSelectionToggle(checked, x);
      });
    }
    this.checkAllParents(node);
  }



  submit(): void {
    let result: any[] = [];
    this.dataSource.data.forEach(node => {
      result = result.concat(
        this.treeControl
          .getDescendants(node)
          .filter(x => x.ischecked && x.id && x.id.indexOf('-') > 0)
          // .map(x => x.id)
          .map(o => ({ RoleName: this.userRoleForm.value.role, MenuId: o.id?.split('-')[0], ALId: o.id?.split('-')[1] }))
      );
    });
    const key = {
      GroupId: this.userGroupForm.value.userGroup,
      UserId: 0,
      RoledetailsList: result,
      TransactionTypeId: this.userRoleForm.value.transactionTypeId,
    };
    this.roleService.SumbitRoleDetails(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.showPopupMsg(response.ErrorMessage);
        this.onGroupChange();
        this.displayRoleModal = false;
      }
      else {
        this.toaster.error('Error', response.ErrorMessage);
      }
    },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }
  deleteRole(element: any): void {
    const key = {
      RoleId: element.RoleId,
      UserId: 0,
      TransactionTypeId: 3,
    };
    this.roleService.SumbitRoleDetails(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.showPopupMsg(response.ErrorMessage);
        this.onGroupChange();
      }
      else {
        this.toaster.error('Error', response.ErrorMessage);
      }
    },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  update(): void {
    let result: any[] = [];
    this.dataSource.data.forEach(node => {
      result = result.concat(
        this.treeControl
          .getDescendants(node)
          .filter(x => x.ischecked && x.id && x.id.indexOf('-') > 0)
          // .map(x => x.id)
          .map(o => ({ RoleName: this.userRoleForm.value.role, MenuId: o.id?.split('-')[0], ALId: o.id?.split('-')[1] }))
      );
    });
    const key = {
      GroupId: this.userGroupForm.value.userGroup,
      UserId: 0,
      RoledetailsList: result,
      TransactionTypeId: this.userRoleForm.value.transactionTypeId,
      RoleId: this.userRoleForm.value.transactionTypeId === 1 ? 0 : this.RoleId,

    };
    this.roleService.SumbitRoleDetails(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.showPopupMsg(response.ErrorMessage);
        this.onGroupChange();
        this.displayRoleModal = false;
      }
      else {
        this.toaster.error('Error', response.ErrorMessage);
      }
    },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }
  /** Whether part of the descendants are selected */

  openModal(): void {
    this.displayRoleModal = true;
    this.userRoleForm.reset();
    this.formMode = true;
    this.userRoleForm.get('transactionTypeId')?.setValue(1);
    this.getRoleMenuList();
  }
  openEditModal(element: any): void {
    this.dataSource.data = [];
    this.displayRoleModal = true;
    this.userRoleForm.reset();
    this.formMode = false;
    this.userRoleForm.get('transactionTypeId')?.setValue(2);
    const key = {
      UserId: 0,
      RoleId: element.RoleId,
    };

    this.roleService.getRoleMenuList(key).subscribe((data: any) => {
      this.dataSource.data = data.mennulist;
      Object.keys(this.dataSource.data).forEach((x: any) => {
        this.setParent(this.dataSource.data[x], null);
      });
      this.flatArray = [];
      for (let i = 0; i < data.mennulist.length; i++) {
        this.roleFlatArray(data.mennulist[i]);
      }
      this.checkAll();
      this.userRoleForm.get('role')?.setValue(element.RoleName);
      this.RoleId = element.RoleId;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  roleFlatArray(menuList: any): any {
    if (menuList.children.length) {
      this.flatArray = this.flatArray.concat(menuList.children);
      for (let i = 0; i < menuList.children.length; i++) {
        this.roleFlatArray(menuList.children[i]);
      }
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.userRolesData.filter = filterValue.trim().toLowerCase();

    if (this.userRolesData.paginator) {
      this.userRolesData.paginator.firstPage();
    }
  }
  checkAll(): any {
    for (let i = 0; i < this.flatArray.length; i++) {
      if (this.flatArray[i].ischecked) {
        this.todoItemSelectionToggle(true, this.flatArray[i]);
      }
      this.treeControl.expand(this.flatArray[i]);
    }
  }
  getRoleList(): void {
    this.spinner.show();
    const key = {
      GroupId: this.GroupId,
      UserId: 0
    };
    this.roleService.getRoleList(key).subscribe((data: any) => {
      this.userRolesData = new MatTableDataSource<any>(data.rolelist);
      this.getPaginator();
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  async getGroupsData(): Promise<void> {
    const key = {
      Id: 0,
    };

    this.roleService.getGroupData(key).subscribe((data: any) => {
      this.groupList = data.masterList;
      this.GroupId = this.groupList[0].TypeId;
      this.filteredGroups = this.groupList.slice();
      this.getRoleList();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  close(): void {
    this.displayRoleModal = false;
  }
  onGroupChange(): any {
    const key = {
      GroupId: this.userGroupForm.value.userGroup,
    };
    this.roleService.getRoleList(key).subscribe((data: any) => {
      this.userRolesData = new MatTableDataSource<any>(data.rolelist);
      this.getPaginator();
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  getPaginator(): void {
    setTimeout(() => {
      this.userRolesData.paginator = this.paginator;
      this.userRolesData.sort = this.sort;
    }, 1000);
  }
  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
      this.toaster.success('Success', msg.substring(3, msg.length));
      this.dialog.closeAll();
    }
    else {
      this.toaster.error('Error', msg.substring(3, msg.length));
    }
  }
  getRoleMenuList(): void {
    this.dataSource.data = [];
    const key = {
      GroupId: 1,
      UserId: 0
    };
    this.roleService.getRoleMenuList(key).subscribe((data: any) => {
      this.dataSource.data = data.mennulist;
      this.getPaginator();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
}
