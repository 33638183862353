<ngx-spinner
  bdColor="rgba(0, 0, 0, 0)"
  template=" <img src='assets/images/spinner.gif' />"
>
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>
<div class="container">
  <p
    style="margin: 0; font-family: system-ui; color: #0f0fd1; font-weight: 500"
  >
    Minimum 4 M's Are Mandatory <span class="text-danger">*</span>
  </p>
  <div class="fishbone">
    <div class="fishbone-left">
      <div class="fishbone-top-3m">
        <div
          class="box"
          [ngClass]="{ 'disabled-box': MachineryChecked != true }"
        >
          <div class="box-title">
            <div>
              <h4>Machine</h4>
              <mat-slide-toggle
                #MachineryToggle
                class="example-margin"
                [checked]="MachineryChecked"
                (change)="MToggle($event, 'Machinery')"
              ></mat-slide-toggle>
            </div>
            <button
              class="add-btn"
              pTooltip="Add New Group"
              tooltipPosition="bottom"
              (click)="addRow(1)"
              [disabled]="!MachineryChecked || !IsWrite"
            >
              <img
                src="assets/images/add.png"
                alt=""
                width="20px"
                height="20px"
              />
            </button>
          </div>
          <div class="box-body">
            <mat-form-field
              appearence="fill"
              *ngFor="let machinery of mList.machineryList; let i = index"
            >
              <div class="d-flex justify-content-between">
                <input
                  matInput
                  [(ngModel)]="mType.machinery[i]"
                  (change)="checkDuplicate('machinery', mType.machinery[i], i)"
                  *ngIf="!machinery.ID"
                />
                <button
                  class="add-btn deleteBtn"
                  [disabled]="!IsWrite"
                  (click)="deleteRow(1, i)"
                  *ngIf="!machinery.ID"
                >
                  <mat-icon class="menu">delete</mat-icon>
                </button>
              </div>
              <mat-select
                [(ngModel)]="mType.machinery[i]"
                *ngIf="machinery.ID"
                (selectionChange)="updateMTypeList(1, i)"
                [disabled]="
                  !MachineryChecked || isDisabledField(mType.machinery[i])
                "
              >
                <input
                  (keyup)="searchValues($event, 1, i)"
                  class="form-control"
                  placeholder="Search Machine"
                />
                <mat-option
                  [ngClass]="{ 'd-none': !machinery.ID }"
                  *ngFor="let machinery of machineryList[i]"
                  [value]="machinery.ID"
                >
                  {{ machinery.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div
          class="box"
          [ngClass]="{ 'disabled-box': MaterialChecked != true }"
        >
          <div class="box-title">
            <div>
              <h4>Material</h4>
              <mat-slide-toggle
                #MaterialToggle
                class="example-margin"
                [checked]="MaterialChecked"
                (change)="MToggle($event, 'Material')"
              ></mat-slide-toggle>
            </div>
            <button
              class="add-btn"
              pTooltip="Add New Group"
              tooltipPosition="bottom"
              (click)="addRow(2)"
              [disabled]="!MaterialChecked || !IsWrite"
            >
              <img
                src="assets/images/add.png"
                alt=""
                width="20px"
                height="20px"
              />
            </button>
          </div>
          <div class="box-body">
            <mat-form-field
              appearence="fill"
              *ngFor="let material of mList.materialList; let i = index"
            >
              <div class="d-flex justify-content-between">
                <input
                  matInput
                  [(ngModel)]="mType.material[i]"
                  (change)="checkDuplicate('material', mType.material[i], i)"
                  *ngIf="!material.ID"
                />
                <button
                  class="add-btn deleteBtn"
                  [disabled]="!IsWrite"
                  (click)="deleteRow(2, i)"
                  *ngIf="!material.ID"
                >
                  <mat-icon class="menu">delete</mat-icon>
                </button>
              </div>
              <mat-select
                [(ngModel)]="mType.material[i]"
                (selectionChange)="updateMTypeList(2, i)"
                *ngIf="material.ID"
                [disabled]="
                  !MaterialChecked || isDisabledField(mType.material[i])
                "
              >
                <input
                  (keyup)="searchValues($event, 2, i)"
                  class="form-control"
                  placeholder="Search Material"
                />
                <mat-option
                  [ngClass]="{ 'd-none': !material.ID }"
                  *ngFor="let material of materialList[i]"
                  [value]="material.ID"
                >
                  {{ material.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="box" [ngClass]="{ 'disabled-box': ManChecked != true }">
          <div class="box-title">
            <div>
              <h4>Man</h4>
              <mat-slide-toggle
                #ManToggle
                class="example-margin"
                [checked]="ManChecked"
                (change)="MToggle($event, 'Man')"
              ></mat-slide-toggle>
            </div>
            <button
              class="add-btn"
              pTooltip="Add New Group"
              tooltipPosition="bottom"
              (click)="addRow(3)"
              [disabled]="!ManChecked || !IsWrite"
            >
              <img
                src="assets/images/add.png"
                alt=""
                width="20px"
                height="20px"
              />
            </button>
          </div>
          <div class="box-body">
            <mat-form-field
              appearence="fill"
              *ngFor="let man of mList.manList; let i = index"
            >
              <div class="d-flex justify-content-between">
                <input
                  matInput
                  [(ngModel)]="mType.man[i]"
                  (change)="checkDuplicate('man', mType.man[i], i)"
                  *ngIf="!man.ID"
                />
                <button
                  class="add-btn deleteBtn"
                  [disabled]="!IsWrite"
                  (click)="deleteRow(3, i)"
                  *ngIf="!man.ID"
                >
                  <mat-icon class="menu">delete</mat-icon>
                </button>
              </div>
              <mat-select
                [(ngModel)]="mType.man[i]"
                *ngIf="man.ID"
                (selectionChange)="updateMTypeList(3, i)"
                [disabled]="!ManChecked || isDisabledField(mType.man[i])"
              >
                <input
                  (keyup)="searchValues($event, 3, i)"
                  class="form-control"
                  placeholder="Search Man"
                />
                <mat-option
                  [ngClass]="{ 'd-none': !man.ID }"
                  *ngFor="let man of manList[i]"
                  [value]="man.ID"
                >
                  {{ man.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="fishbone-center"></div>
      <div class="fishbone-bottom-3m">
        <div class="box" [ngClass]="{ 'disabled-box': MethodChecked != true }">
          <div class="box-title">
            <div>
              <h4>Method</h4>
              <mat-slide-toggle
                #MethodToggle
                class="example-margin"
                [checked]="MethodChecked"
                (change)="MToggle($event, 'Method')"
              ></mat-slide-toggle>
            </div>
            <button
              class="add-btn"
              pTooltip="Add New Group"
              tooltipPosition="bottom"
              (click)="addRow(4)"
              [disabled]="!MethodChecked || !IsWrite"
            >
              <img
                src="assets/images/add.png"
                alt=""
                width="20px"
                height="20px"
              />
            </button>
          </div>
          <div class="box-body">
            <mat-form-field
              appearence="fill"
              *ngFor="let method of mList.methodList; let i = index"
            >
              <div class="d-flex justify-content-between">
                <input
                  matInput
                  [(ngModel)]="mType.method[i]"
                  (change)="checkDuplicate('method', mType.method[i], i)"
                  *ngIf="!method.ID"
                />
                <button
                  class="add-btn deleteBtn"
                  [disabled]="!IsWrite"
                  (click)="deleteRow(4, i)"
                  *ngIf="!method.ID"
                >
                  <mat-icon class="menu">delete</mat-icon>
                </button>
              </div>
              <mat-select
                [(ngModel)]="mType.method[i]"
                *ngIf="method.ID"
                (selectionChange)="updateMTypeList(4, i)"
                [disabled]="!MethodChecked || isDisabledField(mType.method[i])"
              >
                <input
                  (keyup)="searchValues($event, 4, i)"
                  class="form-control"
                  placeholder="Search Method"
                />
                <mat-option
                  [ngClass]="{ 'd-none': !method.ID }"
                  *ngFor="let method of methodList[i]"
                  [value]="method.ID"
                >
                  {{ method.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div
          class="box"
          [ngClass]="{ 'disabled-box': MotherNatureChecked != true }"
        >
          <div class="box-title">
            <div>
              <h4>Mother Nature</h4>
              <mat-slide-toggle
                #MotherNatureToggle
                class="example-margin"
                [checked]="MotherNatureChecked"
                (change)="MToggle($event, 'MotherNature')"
              ></mat-slide-toggle>
            </div>
            <button
              class="add-btn"
              pTooltip="Add New Group"
              tooltipPosition="bottom"
              (click)="addRow(5)"
              [disabled]="!MotherNatureChecked || !IsWrite"
            >
              <img
                src="assets/images/add.png"
                alt=""
                width="20px"
                height="20px"
              />
            </button>
          </div>
          <div class="box-body">
            <mat-form-field
              appearence="fill"
              *ngFor="let motherNature of mList.motherNatureList; let i = index"
            >
              <div class="d-flex justify-content-between">
                <input
                  matInput
                  [(ngModel)]="mType.motherNature[i]"
                  (change)="
                    checkDuplicate('motherNature', mType.motherNature[i], i)
                  "
                  *ngIf="!motherNature.ID"
                />
                <button
                  class="add-btn deleteBtn"
                  [disabled]="!IsWrite"
                  (click)="deleteRow(5, i)"
                  *ngIf="!motherNature.ID"
                >
                  <mat-icon class="menu">delete</mat-icon>
                </button>
              </div>
              <mat-select
                [(ngModel)]="mType.motherNature[i]"
                *ngIf="motherNature.ID"
                (selectionChange)="updateMTypeList(5, i)"
                [disabled]="
                  !MotherNatureChecked || isDisabledField(mType.motherNature[i])
                "
              >
                <input
                  (keyup)="searchValues($event, 5, i)"
                  class="form-control"
                  placeholder="Search Mother Nature"
                />
                <mat-option
                  [ngClass]="{ 'd-none': !motherNature.ID }"
                  *ngFor="let motherNature of motherNatureList[i]"
                  [value]="motherNature.ID"
                >
                  {{ motherNature.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div
          class="box"
          [ngClass]="{ 'disabled-box': ManagementChecked != true }"
        >
          <div class="box-title">
            <div>
              <h4>Management</h4>
              <mat-slide-toggle
                #ManagementToggle
                class="example-margin"
                [checked]="ManagementChecked"
                (change)="MToggle($event, 'Management')"
              ></mat-slide-toggle>
            </div>
            <button
              class="add-btn"
              pTooltip="Add New Group"
              tooltipPosition="bottom"
              (click)="addRow(6)"
              [disabled]="!ManagementChecked || !IsWrite"
            >
              <img
                src="assets/images/add.png"
                alt=""
                width="20px"
                height="20px"
              />
            </button>
          </div>
          <div class="box-body">
            <mat-form-field
              appearence="fill"
              *ngFor="let management of mList.managementList; let i = index"
            >
              <div class="d-flex justify-content-between">
                <input
                  matInput
                  [(ngModel)]="mType.management[i]"
                  (change)="
                    checkDuplicate('management', mType.management[i], i)
                  "
                  *ngIf="!management.ID"
                />
                <button
                  class="add-btn deleteBtn"
                  [disabled]="!IsWrite"
                  (click)="deleteRow(6, i)"
                  *ngIf="!management.ID"
                >
                  <mat-icon class="menu">delete</mat-icon>
                </button>
              </div>
              <mat-select
                [(ngModel)]="mType.management[i]"
                *ngIf="management.ID"
                (selectionChange)="updateMTypeList(6, i)"
                [disabled]="
                  !ManagementChecked || isDisabledField(mType.management[i])
                "
              >
                <input
                  (keyup)="searchValues($event, 6, i)"
                  class="form-control"
                  placeholder="Search Management"
                />
                <mat-option
                  [ngClass]="{ 'd-none': !management.ID }"
                  *ngFor="let management of managementList[i]"
                  [value]="management.ID"
                >
                  {{ management.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="fishbone-right" title="{{ refocusedProbStatment }}">
      <h4>New Refocused Problem Statement</h4>
      <mat-form-field class="w-100">
        <textarea
          matInput
          rows="20"
          cols="10"
          [(ngModel)]="refocusedProbStatment"
          value="test"
          [readonly]="isStageId == 1 ? true : false"
        ></textarea>
      </mat-form-field>

      <div class="imgflex">
        <img
          *ngIf="imageFile && imageSrc"
          (click)="imageZoomModal()"
          [src]="imageSrc"
          alt=""
          width="195px"
          height="130px"
        />
        <img
          *ngIf="!imageSrc && UploadFile"
          (click)="imageZoomModal()"
          [src]="blobStorageUrl + UploadFile"
          alt=""
          width="195px"
          height="130px"
        />
        <button
          mat-flat-button
          color="accent"
          type="button"
          class="upload"
          (click)="openFile()"
        >
          <mat-icon>attach_file</mat-icon>
        </button>
        <input
          type="file"
          (change)="readFile($event)"
          class="d-none"
          #fileInput
          accept="image/*"
          (click)="$event.target.value = null"
        />

        <img
          *ngIf="(imageFile && imageSrc) || UploadFile"
          src="assets/images/delete.png"
          (click)="deleteFile(i)"
          class="imgabsol"
          alt=""
          width="15px"
          height="15px"
          style="position: absolute; left: 0; top: 0"
        />
      </div>
    </div>
  </div>
</div>
