import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { UserManu } from 'src/app/models/userManu';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ScoreServiceService } from 'src/app/services/score-service.service';
import { Score } from 'src/app/models/score';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-ipe-master-data',
  templateUrl: './ipe-master-data.component.html',
  styleUrls: ['./ipe-master-data.component.scss']
})
export class IpeMasterDataComponent implements OnInit {
  @Input() pPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<Pillar>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  pillars!: Pillar[];
  pillarId!: number;
  plants!: Plantbygroup[];
  scoreForm!: FormGroup;
  formMode!: boolean;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  plantId!: number;

  pillarModelVisible = false;
  rangeValues!: number[];
  pillarColumns: string[] = ['ipeActions','plant', 'Level', 'Evolution', 'Score', ];
  scoreData!: MatTableDataSource<Score>;
  evaluationList: any;
  filteredEvaluationList: any;
  levelList: any;
  filteredLevelList: any;
  scoreId: any;
  IsAccess: any;
  IsWrite: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!:any;
  breadcrumList: any;
  constructor(
    private fb: FormBuilder,
    private kpiService: KpimasterService,
    private levelService: ScoreServiceService,
    private scoreService: ScoreServiceService,
    private evaluatuionService: ScoreServiceService,
    private platModelService: EnterPriseGroupService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private translater: TranslaterService,
    private usermanagementService: UserManagementService,
  ) { 
  }
  ngOnInit(): void {
    this.GetBreadcrumList();
    //this.getLangWords();
    this.GetMenuAccess();

    this.isRead = this.pPermissions.IsAccess;
    this.isWrite = this.pPermissions.IsWrite;
    this.isUpdate = this.pPermissions.IsWrite;
    this.isDelete = this.pPermissions.IsWrite;
    this.getEvaluation();
    this.getLevel();
    this.formMode = true;
    this.scoreForm = this.fb.group({
      // score: ['', Validators.required],
      plant: ['', Validators.required],
      level: ['', Validators.required],
      evaluation: ['', Validators.required],
      scoreId: ['']
    });
    this.rangeValues = [20, 80];
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getPaginator(): void {
    setTimeout(() => {
      this.scoreData.paginator = this.paginator;
      this.scoreData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.scoreData.filter = filterValue.trim().toLowerCase();

    if (this.scoreData.paginator) {
      this.scoreData.paginator.firstPage();
    }
  }
  getPlants(): void {
    this.kpiService.getAllPlants().subscribe(
      (plants) => {
        this.plants = plants;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getScore(data: any): void {
    const key = {
      plantId: data,
    };
    this.scoreService.addScore(key).subscribe((abc: any) => {

    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      });
  }
  getScorebyId(element: any): void {
    this.formMode = false;
    this.pillarModelVisible = true;
    const key = {
      scoreId: element,
    };
    this.scoreForm.get('plant')?.setValue(this.plantId);
    this.scoreService.getScorebyId(key).subscribe((data: any) => {
      this.scoreForm.get('level')?.setValue(this.levelList.find((x: any) => x.levelName === data.levelName).levelId);
      this.scoreForm.get('evaluation')?.setValue(this.evaluationList.find((x: any) => x.evaluationName === data.evaluationName).evaluationId);
      this.scoreForm.get('score')?.setValue(data.scoreName);
      this.rangeValues = [data.minScore, data.maxScore];
      this.scoreId = element;
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      });
  }
  getMasterScoreData(data: any): void {
    const key = {
      plantId: data.plantId,
    };
    this.plantId = data.plantId;
    this.scoreForm.get('plant')?.setValue(data.plantId);
    this.getPlants();
    this.spinner.show();
    this.scoreService.getMasterScore(key).subscribe((levels: any) => {
      this.scoreData = new MatTableDataSource<any>(levels);
      this.getPaginator();
      this.spinner.hide();
    },
      (error: any) => {
        this.toaster.error('Error', error.message);

      });
    this.spinner.hide();
  }

  openModal(): void {
    if (this.isWrite) {
      this.resetForm();
      this.scoreForm.get('plant')?.setValue(this.plantId);
      this.formMode = true;
      this.pillarModelVisible = true;
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  getEvaluation(): void {
    this.evaluatuionService.getEvaluation().subscribe(
      (evaluation: any) => {
        this.evaluationList = evaluation;
        this.filteredEvaluationList = this.evaluationList.slice();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getLevel(): void {
    this.levelService.getLevel().subscribe(
      (levels: any) => {

        this.levelList = levels;
        this.filteredLevelList = this.levelList.slice();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      })
  }


  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  getscore(scoreId: number): void {
    if (this.isUpdate) {
      const key = {
        scoreId,
      };
      this.kpiService.deleteScore(key).subscribe(
        (Score) => {
          this.pillarId = scoreId;
          this.scoreForm.get('plant')?.setValue(this.plantId);
          this.plants = [];
          this.getPlants();
          this.scoreForm.get('plant')?.setValue(this.plantId);
          this.formMode = false;
          this.pillarModelVisible = true;
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  submitForm(): void {
    const scoreData = this.scoreData.filteredData;
    const minfilter = scoreData.filter((c: any) => (c.minScore >= this.rangeValues[0] && c.minScore <= this.rangeValues[1]) || (c.maxScore >= this.rangeValues[0] && c.maxScore <= this.rangeValues[1]));
    if (minfilter.length > 0) {
      this.toaster.error('Error', 'Score Range Already Exist');
      return;
    }
    const level = scoreData.filter((c: any) => c.levelId === this.scoreForm.value.level);
    if (level.length > 0) {
      this.toaster.error('Error', 'Level  Already Exist');
      return;
    }
    const evalution = scoreData.filter((c: any) => c.evaluationId === this.scoreForm.value.evaluation);
    if (evalution.length > 0) {
      this.toaster.error('Error', 'Evalution Already Exist');
      return;
    }
    const data = {
      plantId: this.scoreForm.value.plant,
      scoreName: this.scoreForm.value.score,
      minScore: this.rangeValues[0],
      maxScore: this.rangeValues[1],
      level: this.scoreForm.value.level,
      evaluation: this.scoreForm.value.evaluation
    };
    this.spinner.show();


    this.scoreService.addScore(data).subscribe(
      (response: any) => {
        this.spinner.hide();

        this.toaster.success('Success', 'Score Added Successfully');

        this.getMasterScoreData({ plantId: data.plantId }),
          this.closeScoreModel();
          


      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();

      }
    );
  }

  updateForm(): void {
    const scoreData = this.scoreData.filteredData.filter((c: any) => c.scoreId !== this.scoreId);
    const minfilter = scoreData.filter((c: any) => (c.minScore >= this.rangeValues[0] && c.minScore <= this.rangeValues[1]) || (c.maxScore >= this.rangeValues[0] && c.maxScore <= this.rangeValues[1]));
    if (minfilter.length > 0) {
      this.toaster.error('Error', 'Score Range Already Exist');
      return;
    }
    const level = scoreData.filter((c: any) => c.levelId === this.scoreForm.value.level);
    if (level.length > 0) {
      this.toaster.error('Error', 'Level  Already Exist');
      return;
    }
    const evalution = scoreData.filter((c: any) => c.evaluationId === this.scoreForm.value.evaluation);
    if (evalution.length > 0) {
      this.toaster.error('Error', 'Evalution Already Exist');
      return;
    }
    const score = {
      scoreId: this.scoreId,
      plantId: this.scoreForm.value.plant,
      scoreName: this.scoreForm.value.score,
      level: this.scoreForm.value.level,
      evaluation: this.scoreForm.value.evaluation,
      minScore: this.rangeValues[0],
      maxScore: this.rangeValues[1]
    };
    this.spinner.show();

    this.kpiService.updateScore(score).subscribe(
      (response) => {
        this.toaster.success('Success', 'IPE MasterData Updated Successfully');
        this.getMasterScoreData({ plantId: score.plantId }),
          this.closeScoreModel();
        this.resetForm();
        this.spinner.hide();

      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();

      }
    );
  }

  deletePillar(scoreId: number): void {

    if (this.isDelete) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete IPE Level Setting',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          const key = {
            scoreId,
          };
          this.kpiService.deleteScore(key).subscribe(

            (success) => {
              this.getMasterScoreData({ plantId: this.plantId }),
                Swal.fire({
                  title: 'Deleted!',
                  text: 'Your selected IPE Level Setting has been deleted.',
                  icon: 'success',
                  timer: 800,
                  showConfirmButton: false,
                });
          this.spinner.hide();


            },
            (error) => {
              this.toaster.error('Error', error.error.message);
          this.spinner.hide();

            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your selected IPE Level Setting is safe :)', 'error');
        }
      });
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  resetForm(): void {
    this.scoreForm.reset();
  }
  closeScoreModel(): void {
    this.pillarModelVisible = false;
  }
}
