import { Component, OnInit, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import * as  Highcharts from 'highcharts';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { ButtonViewRendererComponent } from 'src/app/components/button-view-render.components';
import { DatePipe } from '@angular/common';
import { RMDashboardService } from '../../services/rm-dashboard.service';

import Drilldown from 'highcharts/modules/drilldown';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import { ManagerExecutionService } from 'src/app/services/manager.service';

More(Highcharts);
Drilldown(Highcharts);
Exporting(Highcharts);

@Component({
  selector: 'app-rm-dashboard-clone',
  templateUrl: './rm-dashboard-clone.component.html',
  styleUrls: ['./rm-dashboard-clone.component.scss']
})
export class RmDashboardCloneComponent implements OnInit {

  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('popupViewModal') private popupViewModal!: TemplateRef<any>;
  @ViewChild('breakDownDialog') private breakDownDialog!: TemplateRef<any>;

  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  filteredUnits: any;
  filteredComponents: any
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  componentList!: any;
  permisson!: any;
  CLLDashboardForm!: FormGroup;
  TotalDefects!: any;
  TotalDefecteSolved!: any;
  CILAdherence!: any;
  TotalDefectsPerc!: any;
  CILTask!: any;
  key: any;
  dashboardData!: any;
  chartCILTask!: Chart;
  chartCILType!: Chart;
  chartCILTool!: Chart;
  highCharts: any;
  image!: any;
  currentImagePath!: any;
  defaultExcelExportParams: any;
  frameworkComponents: any;

  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;


  gridOptions: any = {
    defaultColDef: {
      rowHeight: 45,
      rowStyle: {
        'border-bottom': 'white 10px solid',
        'border-top': 'white 10px solid',
        "max-height": "50px",
      },
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
  };
  // iconUrl
  menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';
  unitPickerIsOpen: boolean = false;
  isPickFromUnit: boolean = false;
  HeaderData: any = { Group: '', displayMachine: '', StartDate: '', EndDate: '' };
  HeaderForm: any;
  TotalWO: any = 0;
  ClosedWO: any = 0;
  CompletedWO: any = 0;
  OpenedWO: any = 0;
  MTTRDays: any = 0;
  MTTRHours: any = 0;
  MTTRMinutes: any = 0;
  MTBFDays: any = 0;
  MTBFHours: any = 0;
  MTBFMinutes: any = 0;
  UsedSpareParts: any = 0;
  TreeData: any = [];

  Minor: any = 0;
  Moderate: any = 0;
  Major: any = 0;
  ResponseTime: any = 0;
  TroubleShootTime: any = 0;
  WaitingSPTime: any = 0;
  MDTTime: any = 0;
  UnitsInGroup: any = [];
  PlantsInGroup: any = [];
  UnitsByLine: any = [];
  ComponentByUnit: any = [];

  chartMachine!: Chart;
  chartComponent!: Chart;
  isGreenMark!: boolean;
  selectedDate = 'Today'
  filteredDate: any[];
  drilldownData: any;
  spinnerText: string = "";
  userList: any = [];
  ChartName: any;
  isGreenOpenMark!: boolean;
  isGreenCompleteMark!: boolean;
  isGreenCloseMark!: boolean;
  openedPercentage: any;
  completedPercentage: any;
  closedPercentage: any;
  minorArr: any = [];
  moderateArr: any = [];
  majorArr: any = [];
  chartMinor!: Chart;
  chartModerate!: Chart;
  chartMajor!: Chart;
  isMinorChart:boolean = false;
  isModerateChart:boolean = false;
  isMajorChart:boolean = false;

  constructor(private productivityservice: ProductivityService,
    private toaster: ToastrService,
    public router: Router,
    private userService: UserManagementService,
    private enterpriseGroupService: EnterPriseGroupService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public datePipe: DatePipe,
    private pmDashboardService: RMDashboardService,
    private fb: FormBuilder,
    public managerExecutionService: ManagerExecutionService) {

    this.filteredDate = [
      { value: 'Today' },
      { value: 'Yesterday' },
      { value: 'Last Week' },
      { value: 'Last Month' },
    ]

    this.frameworkComponents = {
      buttonRenderer: ButtonViewRendererComponent,
    }
    this.columnDefs = [
      {
        headerName: 'Line/Machine', field: 'units.puDesc', cellRenderer: function (params: any) {
          let html = params && params.data ? params.data.line.plDesc + '/' + params.data.units.puDesc : '';
          return html;
        }
      },
      { headerName: 'WO Id', field: 'WOId', filter: 'agTextColumnFilter' },
      { headerName: 'WO Type', field: 'WOType.Name', filter: 'agTextColumnFilter' },
      { headerName: 'Failure Mode', field: 'FailureMode.Name', filter: 'agTextColumnFilter' },
      { headerName: 'WO Status', field: 'WOStatus', filter: 'agTextColumnFilter' },
      { headerName: 'Opearator Remark', field: 'Remark', filter: 'agTextColumnFilter' },
      {
        headerName: 'WO Raise Time', field: 'CreatedAt', filter: 'agTextColumnFilter', valueFormatter: function (params: any) { return params.value !== null ? moment(params.value).format('MM/DD/YYYY HH:mm:ss') : '' }
      },
      {
        headerName: 'Operator Picture', field: 'UploadPicture', cellRenderer: function (params: any) {
          let html = '';
          if (params.value) {
            html = `<img style="width:40px;height:40px ;cursor:pointer" alt="${params.value}" src="${environment.O3CoreApiImageUrl + params.value}">`
          }
          return html;
        }
      },
      {
        headerName: 'Manager Assign Time', field: 'managerExc.CreatedAt', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? moment(html).format('MM/DD/YYYY HH:mm:ss') : '';
        }
      },
      {
        headerName: 'Technician', field: 'managerExc.assignUser.UserName', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'Priority', field: 'managerExc.Priority.Name', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'SparePartCode', field: 'managerExc.SparePartCode', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'Manager Remark', field: 'managerExc.ManagerRemarks', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'Technician Start Time', field: 'techExc.StartTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? moment(html).format('MM/DD/YYYY HH:mm:ss') : '';
        }
      },
      {
        headerName: 'SP Request Time', field: 'techExc.SPRequestTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? moment(html).format('MM/DD/YYYY HH:mm:ss') : '';
        }
      },
      {
        headerName: 'SP Received Time', field: 'techExc.SPReceivedTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? moment(html).format('MM/DD/YYYY HH:mm:ss') : '';
        }
      },
      {
        headerName: 'Technician Complete Time', field: 'techExc.CompleteTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? moment(html).format('MM/DD/YYYY HH:mm:ss') : '';
        }
      },
      {
        headerName: 'Technician Remark', field: 'techExc.Remarks', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'Technician Picture', field: 'techExc.AfterPicture', cellRenderer: function (params: any) {
          let html = '';
          if (params && params.value) {
            html = `<img style="width:40px;height:40px ;cursor:pointer" alt="${params.value}" src="${environment.O3CoreApiImageUrl + params.value}">`
          }
          return html;
        }
      },
      // {
      //   headerName: 'MTTR', field: 'MTTRTime', cellRenderer: function (params: any) {
      //     let html = params && params.value ? params.value+' '+'min' : '';
      //     return html;
      //   }
      // },
      // {
      //   headerName: 'MTBF', field: 'MTBFTime', cellRenderer: function (params: any) {
      //     let html = params && params.value ? params.value+' '+'min' : '';
      //     return html;
      //   }
      // },
      // {
      //   headerName: 'MDT', field: 'MDTTime', cellRenderer: function (params: any) {
      //     let html = params && params.value ? params.value+' '+'min' : '';
      //     return html;
      //   }
      // },
      {
        headerName: 'Response Time', field: 'ResponseTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value + ' ' + 'min' : '';
          return html;
        }
      },
      {
        headerName: 'TroubleShoot Time', field: 'TroubleShootTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value + ' ' + 'min' : '';
          return html;
        }
      },
      {
        headerName: 'Waiting SP Time', field: 'WaitingSPTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value + ' ' + 'min' : '';
          return html;
        }
      },
    ];

    this.defaultExcelExportParams = {
      addImageToCell: function (rowIndex: number, col: any, value: any, callback: any) {
        if (col.colId === 'PreImage' || col.colId === 'PostImage') {

        } else {
          return;
        }
      },
    }

  }

  ngOnInit(): void {
    this.HeaderForm = this.fb.group({
      Group: ['', Validators.required],
      displayMachine: ['', Validators.required],
      Date: ['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
    });
    this.getGroup();
    this.getUserList();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.initform();
  };

  initform() {
    this.HeaderData.StartDate = new Date();
    this.HeaderData.StartDate.setHours(6);
    this.HeaderData.StartDate.setMinutes(0);
    this.HeaderData.StartDate.setSeconds(0);
    this.HeaderData.EndDate = new Date();
    this.HeaderData.EndDate.setDate(this.HeaderData.EndDate.getDate());
    this.HeaderData.Date = 'Today';
    this.HeaderData.ChartUnit = 0;
  };

  getUserList() {
    this.spinnerText = "Fetching User Data";
    this.spinner.show();
    this.managerExecutionService.getUserList().subscribe((res: any) => {
      this.userList = res.UserList;
      this.spinner.hide();
      this.onChangeDate();
    }, (err: any) => {
      console.log(err);
      this.spinner.hide();
    });
  };

  onChangeDate() {
    if (this.HeaderData.Date == 'Today')
      this.today();
    else if (this.HeaderData.Date == 'Yesterday')
      this.yesterday();
    else if (this.HeaderData.Date == 'Last Week')
      this.lastWeek();
    else if (this.HeaderData.Date == 'Last Month')
      this.lastMonth();
  };

  openModal() {
    this.unitPickerIsOpen = true;
    this.isPickFromUnit = true;
  };

  setUnit(event: any) {
    this.unitPickerIsOpen = false;
    this.HeaderData.ChartUnit = 0;
    this.HeaderData.Line = 0;
    this.HeaderData.Unit = 0;
    this.HeaderData.Assembly = 0;
    this.HeaderData.SubAssembly = 0;
    this.HeaderData.Component = 0;
    this.HeaderData.displayMachine = '';

    this.HeaderData.displayMachine = event.displayMachine;
    if (event.Level == 'Line') {
      this.HeaderData.Line = event.line;
    }
    else if (event.Level == 'Unit') {
      this.HeaderData.Line = event.line;
      this.HeaderData.Unit = event.units;
    }
    else if (event.Level == 'Assembly') {
      this.HeaderData.Line = event.line;
      this.HeaderData.Unit = event.units;
      this.HeaderData.Assembly = event.assembly;
    }
    else if (event.Level == 'SubAssembly') {
      this.HeaderData.Line = event.line;
      this.HeaderData.Unit = event.units;
      this.HeaderData.Assembly = event.assembly;
      this.HeaderData.SubAssembly = event.subAssembly;
    }
    else if (event.Level == 'Component') {
      this.HeaderData.Line = event.line;
      this.HeaderData.Unit = event.units;
      this.HeaderData.Assembly = event.assembly;
      this.HeaderData.SubAssembly = event.subAssembly;
      this.HeaderData.Component = event.component;
    }
    setTimeout(() => {
      this.getRMDashboard();
    }, 500);
  };

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.userService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  };

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  };

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  };

  getGroup(): void {
    const key = {
      UserId: 206
    };
    const id = {
      value: 0
    };
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      // this.HeaderData.Group = data.lstEG[0].EG_Id;
      this.filteredGroups = this.GroupList.slice();
      // this.getTreeData();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  };

  getTreeData(): void {
    const key = {
      EGId: this.HeaderData.Group,
      level: 'Component',
      CreatedBy: 0,
      IsOptional: 0
    };
    this.spinner.show();
    this.enterpriseGroupService.getTreeModal(key).subscribe((data: any) => {
      this.spinner.hide();
      this.TreeData = data.TreeModalList;
      this.udpateNodeParentDetails();
      let TreeGroup = this.TreeData && this.TreeData[0] ? this.TreeData[0] : '';
      let TreePlant = TreeGroup && TreeGroup.children && TreeGroup.children[0] ? TreeGroup.children[0] : '';
      let TreeDivision = TreePlant && TreePlant.children && TreePlant.children[0] ? TreePlant.children[0] : '';
      let TreeDepartment = TreeDivision && TreeDivision.children && TreeDivision.children[0] ? TreeDivision.children[0] : '';
      let TreeLine = TreeDepartment && TreeDepartment.children && TreeDepartment.children[0] ? TreeDepartment.children[0] : '';
      let TreeUnit = TreeLine && TreeLine.children && TreeLine.children[0] ? TreeLine.children[0] : '';
      let TreeAssembly = TreeUnit && TreeUnit.children && TreeUnit.children[0] ? TreeUnit.children[0] : '';
      let TreeSubAssembly = TreeAssembly && TreeAssembly.children && TreeAssembly.children[0] ? TreeAssembly.children[0] : '';
      let TreeComponent = TreeSubAssembly && TreeSubAssembly.children && TreeSubAssembly.children[0] ? TreeSubAssembly.children[0] : '';

      this.HeaderData.Unit = TreeUnit ? TreeUnit.PrimaryId : '';
      this.HeaderData.Assembly = TreeAssembly ? TreeAssembly.PrimaryId : '';
      this.HeaderData.SubAssembly = TreeSubAssembly ? TreeSubAssembly.PrimaryId : '';
      this.HeaderData.Component = TreeComponent ? TreeComponent.PrimaryId : '';
      this.HeaderData.displayMachine = `${TreeLine ? TreeLine.text : ''}${TreeUnit ? '/' + TreeUnit.text : ''}${TreeAssembly ? '/' + TreeAssembly.text : ''}${TreeSubAssembly ? '/' + TreeSubAssembly.text : ''}${TreeComponent ? '/' + TreeComponent.text : ''}`;

      if (TreeLine && TreeLine.PrimaryId)
        this.getMachinesByLine(TreeLine.PrimaryId);

      setTimeout(() => {
        this.getRMDashboard();
      }, 500);
    }, (error: any) => {
      this.spinner.hide();
      this.toaster.error('Error', error.message);
    });
  };

  getMachinesByLine(lineId: any) {
    const data = {
      LineId: lineId,
      StartDate: this.HeaderData.StartDate,
      EndDate: this.HeaderData.EndDate
    }
    this.spinner.show();
    this.pmDashboardService.getMachinesByLine(data).subscribe((res: any) => {
      this.UnitsByLine = res.data;
      let ComponentData: any = [], ComponentSeries: any = [];
      for (const unit of this.UnitsByLine) {
        if (unit.Component && unit.Component.length) {
          unit.Component.forEach((element: any) => {
            ComponentData.push([
              element.componentName,
              parseFloat(element.WOCount),
            ]);
          });
          ComponentSeries.push({
            name: 'Components',
            id: unit.puId,
            data: ComponentData
          })
        }
      }
      this.drilldownData = ComponentSeries;
      this.drawMachineChart();
      let dummydata = {
        point: {
          options: {
            drilldown: '-1'
          }
        }
      }
      this.getTableData(dummydata, lineId);
      this.spinner.hide();
    }, (error: any) => {
      this.toaster.error('error', error.message);
      this.spinner.hide();
    });
  };

  udpateNodeParentDetails() {
    if (this.TreeData && this.TreeData.length) {
      this.TreeData.forEach((group: any) => {
        if (group.children && group.children.length) {
          group.children.forEach((plant: any) => {
            plant.groupName = group.text;
            plant.groupId = group.PrimaryId;
            if (plant.children && plant.children.length) {
              plant.children.forEach((division: any) => {
                division.plantId = plant.PrimaryId;
                division.plantName = plant.text;
                division.groupName = group.text;
                division.groupId = group.PrimaryId;
                if (division.children && division.children.length) {
                  division.children.forEach((department: any) => {
                    department.divisionId = division.PrimaryId;
                    department.divisionName = division.text;
                    department.plantId = plant.PrimaryId;
                    department.plantName = plant.text;
                    department.groupId = group.PrimaryId;
                    department.groupName = group.text;
                    if (department.children && department.children.length) {
                      department.children.forEach((line: any) => {
                        line.departmentId = department.PrimaryId;
                        line.departmentName = department.text;
                        line.divisionId = division.PrimaryId;
                        line.divisionName = division.text;
                        line.plantId = plant.PrimaryId;
                        line.plantName = plant.text;
                        line.groupId = group.PrimaryId;
                        line.groupName = group.text;
                        if (line.children && line.children.length) {
                          line.children.forEach((unit: any) => {
                            unit.lineId = line.PrimaryId;
                            unit.lineName = line.text;
                            unit.departmentId = department.PrimaryId;
                            unit.departmentName = department.text;
                            unit.divisionId = division.PrimaryId;
                            unit.divisionName = division.text;
                            unit.plantId = plant.PrimaryId;
                            unit.plantName = plant.text;
                            unit.groupId = group.PrimaryId;
                            unit.groupName = group.text;
                            if (unit.children && unit.children.length) {
                              unit.children.forEach((assembly: any) => {
                                assembly.unitName = unit.text;
                                assembly.unitId = unit.PrimaryId;
                                assembly.lineId = line.PrimaryId;
                                assembly.lineName = line.text;
                                assembly.departmentId = department.PrimaryId;
                                assembly.departmentName = department.text;
                                assembly.divisionId = division.PrimaryId;
                                assembly.divisionName = division.text;
                                assembly.plantId = plant.PrimaryId;
                                assembly.plantName = plant.text;
                                assembly.groupId = group.PrimaryId;
                                assembly.groupName = group.text;
                                if (assembly.children && assembly.children.length) {
                                  assembly.children.forEach((subAssembly: any) => {
                                    subAssembly.assemblyName = assembly.text;
                                    subAssembly.assemblyId = assembly.PrimaryId;
                                    subAssembly.unitName = unit.text;
                                    subAssembly.unitId = unit.PrimaryId;
                                    subAssembly.lineId = line.PrimaryId;
                                    subAssembly.lineName = line.text;
                                    subAssembly.departmentId = department.PrimaryId;
                                    subAssembly.departmentName = department.text;
                                    subAssembly.divisionId = division.PrimaryId;
                                    subAssembly.divisionName = division.text;
                                    subAssembly.plantId = plant.PrimaryId;
                                    subAssembly.plantName = plant.text;
                                    subAssembly.groupId = group.PrimaryId;
                                    subAssembly.groupName = group.text;
                                    if (subAssembly.children && subAssembly.children.length) {
                                      subAssembly.children.forEach((component: any) => {
                                        component.subAssemblyName = subAssembly.text;
                                        component.subAssemblyId = subAssembly.PrimaryId;
                                        component.assemblyName = assembly.text;
                                        component.assemblyId = assembly.PrimaryId;
                                        component.unitName = unit.text;
                                        component.unitId = unit.PrimaryId;
                                        component.lineId = line.PrimaryId;
                                        component.lineName = line.text;
                                        component.departmentId = department.PrimaryId;
                                        component.departmentName = department.text;
                                        component.divisionId = division.PrimaryId;
                                        component.divisionName = division.text;
                                        component.plantId = plant.PrimaryId;
                                        component.plantName = plant.text;
                                        component.groupId = group.PrimaryId;
                                        component.groupName = group.text;
                                      })
                                    }
                                  })
                                }
                              })
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        }
      })
    }
  };

  today(): void {
    this.HeaderData.ChartUnit = 0;
    this.makeDatatoDay();
    this.getRMDashboard();
  };

  yesterday(): void {
    this.HeaderData.ChartUnit = 0;
    this.makeDataYesterDay();
    this.getRMDashboard();
  };

  lastWeek(): void {
    this.HeaderData.ChartUnit = 0;
    this.makeDataLastWeek();
    this.getRMDashboard();
  };

  lastMonth(): void {
    this.HeaderData.ChartUnit = 0;
    this.makeDataLastMonth();
    this.getRMDashboard();
  };

  getRMDashboard() {
    // if (!this.HeaderForm.valid) {
    //   this.toaster.warning('Warning', 'Please Fill All Fields');
    // }
    // else {
    this.spinner.show();
    this.pmDashboardService.getRMDashboardData(this.HeaderData).subscribe((res: any) => {
      this.dashboardData = res;
      this.spinner.hide();
      let dummydata = {
        point: {
          options: {
            drilldown: '-1'
          }
        }
      }
      if (this.HeaderData.Line && this.HeaderData.ChartUnit == 0)
        this.getMachinesByLine(this.HeaderData.Line);
      else
        this.getTableData(dummydata, '');
      this.setData();
    }, (error: any) => {
      this.toaster.error('error', error.message);
      this.spinner.hide();
    });
    // }
  };

  setData() {
    if (this.dashboardData !== null) {
      this.TotalWO = this.dashboardData.TotalWO.length;
      this.ClosedWO = this.dashboardData.ClosedWO.length;
      this.CompletedWO = this.dashboardData.CompletedWO.length;
      this.OpenedWO = this.dashboardData.OpenedWO.length;
      this.UsedSpareParts = this.dashboardData.UsedSpareParts.length;
      this.MTTRDays = 0; this.MTTRHours = 0; this.MTTRMinutes = 0;
      this.MTBFDays = 0; this.MTBFHours = 0; this.MTBFMinutes = 0;
      this.Minor = 0; this.Moderate = 0; this.Major = 0;
      this.minorArr = []; this.moderateArr = []; this.majorArr = [];
      if (this.TotalWO) {
        let averageTotal = this.TotalWO / 2;
        this.isGreenOpenMark = this.OpenedWO <= averageTotal ? true : false;
        this.isGreenCompleteMark = this.CompletedWO >= averageTotal ? true : false;
        this.isGreenCloseMark = this.ClosedWO >= averageTotal ? true : false;
        this.openedPercentage = Math.floor((100 * this.OpenedWO) / this.TotalWO);
        this.completedPercentage = Math.floor((100 * this.CompletedWO) / this.TotalWO);
        this.closedPercentage = Math.floor((100 * this.ClosedWO) / this.TotalWO);
      }
      else {
        this.isGreenOpenMark = true;
        this.isGreenCompleteMark = true;
        this.isGreenCloseMark = true;
      }
      if (this.dashboardData.CompletedWO && this.dashboardData.CompletedWO.length) {
        let totalFixedMin: any = 0, startDate: any, completeDate: any;
        for (const row of this.dashboardData.CompletedWO) {
          startDate = row.CreatedAt;
          completeDate = row.techExc.CompleteTime;
          startDate = new Date(startDate);
          completeDate = new Date(completeDate);
          const diffTime = Math.abs(completeDate - startDate);
          const diffMin = Math.floor(diffTime / 60000);
          totalFixedMin += diffMin;
          const obj = { timeMin: diffMin , woID: row.WOId , machine: row.units.puDesc }
          if (diffMin <= 30) {
            this.Minor++;
            this.minorArr.push(obj)
          }
          else if (diffMin > 30 && diffMin <= 600) {
            this.Moderate++;
            this.moderateArr.push(obj)
          }
          else if (diffMin > 600) {
            this.Major++;
            this.majorArr.push(obj)
          }
        }
        let mttr = Math.floor(totalFixedMin / this.CompletedWO);
        this.MTTRDays = 0 //Math.floor((mttr / 60) / 24)
        this.MTTRHours = 0 //Math.floor((mttr / 60) % 24)
        this.MTTRMinutes = mttr // 
      }
      else{
        this.MTTRMinutes = 0;
      }
      if (this.dashboardData.TotalWO && this.dashboardData.TotalWO.length) {
        let i = 0, totalBreakMin: any = 0, totalResponseTime: any = 0, totalTroubleShootTime: any = 0, totalWaitingSPTime: any = 0, totalMDTTime: any = 0, ResponseTimeLength: any = 0, TroubleShootTimeLength: any = 0, WaitingSPTimeLength: any = 0, MDTLength: any = 0;
        for (i = 0; i < this.dashboardData.TotalWO.length - 1; i++) {
          let fromDate: any = this.dashboardData.TotalWO[i].CreatedAt;
          let toDate: any = this.dashboardData.TotalWO[i + 1].CreatedAt;
          fromDate = new Date(fromDate);
          toDate = new Date(toDate);
          const diffTime = Math.abs(toDate - fromDate);
          const diffMin = Math.floor(diffTime / 60000);
          totalBreakMin += diffMin;
        }
        let mtbf = Math.floor(totalBreakMin / (this.TotalWO - 1));
        this.MTBFDays = 0 //Math.floor((mtbf / 60) / 24)
        this.MTBFHours = 0 //Math.floor((mtbf / 60) % 24)
        this.MTBFMinutes = mtbf // % 60;
        for (const row of this.dashboardData.TotalWO) {
          if (row.techExc) {
            let woRaiseTime: any = row.CreatedAt;
            let startJobTime: any = row.techExc.StartTime;
            woRaiseTime = new Date(woRaiseTime);
            startJobTime = new Date(startJobTime);
            const diffResponseTime = Math.abs(startJobTime - woRaiseTime);
            const diffResponseMin = Math.floor(diffResponseTime / 60000);
            totalResponseTime += diffResponseMin;
            ResponseTimeLength++;
          }
          if (row.techExc && row.techExc.SPRequestTime) {
            let jobStartTime: any = row.techExc.StartTime;
            let spRequestTime: any = row.techExc.SPRequestTime;
            jobStartTime = new Date(jobStartTime);
            spRequestTime = new Date(spRequestTime);
            const diffTroubleShootTime = Math.abs(spRequestTime - jobStartTime);
            const diffTroubleShootMin = Math.floor(diffTroubleShootTime / 60000);
            totalTroubleShootTime += diffTroubleShootMin;
            TroubleShootTimeLength++;
          }
          if (row.techExc && row.techExc.SPRequestTime && row.techExc.SPReceivedTime) {
            let RequestSPTime: any = row.techExc.SPRequestTime;
            let spReceivedTime: any = row.techExc.SPReceivedTime;
            RequestSPTime = new Date(RequestSPTime);
            spReceivedTime = new Date(spReceivedTime);
            const diffWaitingSPTime = Math.abs(spReceivedTime - RequestSPTime);
            const diffWaitingSPMin = Math.floor(diffWaitingSPTime / 60000);
            totalWaitingSPTime += diffWaitingSPMin;
            WaitingSPTimeLength++;
          }
          if (row.ClosedAt) {
            let JobStartTime: any = row.CreatedAt;
            let JobSolvedTime: any = row.ClosedAt;
            JobStartTime = new Date(JobStartTime);
            JobSolvedTime = new Date(JobSolvedTime);
            const diffMDTTime = Math.abs(JobSolvedTime - JobStartTime);
            const diffMDTMin = Math.floor(diffMDTTime / 60000);
            totalMDTTime += diffMDTMin;
            MDTLength++;
          }
        }
        this.ResponseTime = Math.floor(totalResponseTime / ResponseTimeLength);
        this.TroubleShootTime = Math.floor(totalTroubleShootTime / TroubleShootTimeLength);
        this.WaitingSPTime = Math.floor(totalWaitingSPTime / WaitingSPTimeLength);
        this.MDTTime = Math.floor(totalMDTTime / MDTLength);
      }
      else{
        this.ResponseTime = 0;
        this.TroubleShootTime = 0;
        this.WaitingSPTime = 0;
        this.MDTTime = 0;
        this.MTBFMinutes = 0;
      }
    }
  };

  makeDatatoDay() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate());
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    this.HeaderData.StartDate = stDT;
    this.HeaderData.EndDate = edDT;
  };

  makeDataYesterDay() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 1);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    this.HeaderData.StartDate = stDT;
    this.HeaderData.EndDate = edDT;
  };

  makeDataLastWeek() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 6);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    this.HeaderData.StartDate = stDT;
    this.HeaderData.EndDate = edDT;
  };

  makeDataLastMonth() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 30);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    this.HeaderData.StartDate = stDT;
    this.HeaderData.EndDate = edDT;
  };

  drawMachineChart() {
    let MachineLabel: any[] = new Array();
    let MachineData: any[] = new Array();
    if (this.UnitsByLine && this.UnitsByLine.length) {
      this.UnitsByLine.forEach((element: any) => {
        MachineLabel.push(element.puDesc);
        MachineData.push({
          name: element.puDesc,
          y: parseFloat(element.WOCount),
          color: '#0f0fd1',
          drilldown: element.puId
        });
      });
    }

    this.highCharts = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category'
      },
      plotOptions: {
        series: {
          events: {
            click: this.getTableData.bind(this)
          },
        }
      },
      tooltip: {
        pointFormat: 'WO Count: {point.y}'
      },
      series: [{
        name: 'Machines',
        data: MachineData
      }],
      drilldown: {
        series: this.drilldownData
      }
    };
    let chart = new Chart(this.highCharts);
    this.chartMachine = chart;
  };

  getMinerChart() {
    this.isMinorChart = true;
    this.isModerateChart = false;
    this.isMajorChart = false;
    let minorLabel: any[] = new Array();
    let dialogRef = this.dialog.open(this.breakDownDialog, { disableClose: true, height: 'unset', width: '90%' });
    let minorData: any[] = new Array();

      this.minorArr.forEach((element: any) => {
      minorLabel.push('WO ID : ' + element.woID);
      minorData.push({
        name: element.woID,
        y: parseFloat(element.timeMin),
        machine: element.machine,
        color: '#0f0fd1'
      });
    });
    let minorBreakdown:any = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: minorLabel,
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter(this: any) {
          return 'Minutes : '+this.point.y + '<br/>Machine : '+ this.point.machine 
        }
     },
      series: [{
        name: 'Minor Breakdown',
        type: 'column',
        data: minorData
      }]
    };
    let chart = new Chart(minorBreakdown);
    this.chartMinor = chart;
  };

  getModerateChart() {
    this.isMinorChart = false;
    this.isModerateChart = true;
    this.isMajorChart = false;
    let moderateLabel: any[] = new Array();
    let dialogRef = this.dialog.open(this.breakDownDialog, { disableClose: true, height: 'unset', width: '90%' });
    let moderateData: any[] = new Array();
    this.moderateArr.forEach((element: any) => {
      moderateLabel.push('WO ID : ' + element.woID);
      moderateData.push({
        name: element.woID,
        y: parseFloat(element.timeMin),
        machine: element.machine,
        color: '#0f0fd1'
      });
    });
    let moderateBreakdown:any = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: moderateLabel,
      },
      tooltip: {
        formatter(this: any) {
          return 'Minutes : '+this.point.y + '<br/>Machine : '+ this.point.machine 
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Moderate Breakdown',
        type: 'column',
        data: moderateData
      }]
    };
    let chart = new Chart(moderateBreakdown);
    this.chartModerate = chart;
  };

  getMajorChart() {
    this.isMinorChart = false;
    this.isModerateChart = false;
    this.isMajorChart = true;
    let majorLabel: any[] = new Array();
    let dialogRef = this.dialog.open(this.breakDownDialog, { disableClose: true, height: 'unset', width: '90%' });
    let majorData: any[] = new Array();
    this.majorArr.forEach((element: any) => {
      majorLabel.push('WO ID : ' + element.woID);
      majorData.push({
        name: element.woID,
        y: parseFloat(element.timeMin),
        machine: element.machine,
        color: '#0f0fd1'
      });
    });
    let majorBreakdown:any = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: majorLabel,
      },
      tooltip: {
        formatter(this: any) {
          return 'Minutes : '+this.point.y + '<br/>Machine : '+ this.point.machine 
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Major Breakdown',
        type: 'column',
        data: majorData
      }]
    };
    let chart = new Chart(majorBreakdown);
    this.chartMajor = chart;
  };

  getTableData(event: any, line: any) {
    if (event.point.options.drilldown) {
      let data = {
        StartDate: this.HeaderData.StartDate,
        EndDate: this.HeaderData.EndDate,
        ChartUnit: event.point.options.drilldown,
        ChartLine: event.point.options.drilldown != '-1' ? '' : line
      }
      this.spinner.show();
      this.pmDashboardService.getTableData(data).subscribe((res: any) => {
        this.rowData = res.TableData && res.TableData.length ? res.TableData : [];
        if (this.rowData && this.rowData.length) {
          let i = 0;
          for (const row of this.rowData) {
            if (row.managerExc) {
              let userObj = this.userList.find((user: any) => {
                return user.UserId == row.managerExc.assignUser
              });
              if (userObj)
                row.managerExc.assignUser = userObj
            }
            if (row.techExc) {
              let techImages = row.techExc.AfterPicture.split(',');
              row.techExc.AfterPicture = techImages[techImages.length - 1];

              let woRaiseTime: any = row.CreatedAt;
              let startJobTime: any = row.techExc.StartTime;
              woRaiseTime = new Date(woRaiseTime);
              startJobTime = new Date(startJobTime);
              const diffResponseTime = Math.abs(startJobTime - woRaiseTime);
              const diffResponseMin = Math.floor(diffResponseTime / 60000);
              row.ResponseTime = diffResponseMin;
            }

            if (row.techExc && row.techExc.SPRequestTime) {
              let jobStartTime: any = row.techExc.StartTime;
              let spRequestTime: any = row.techExc.SPRequestTime;
              jobStartTime = new Date(jobStartTime);
              spRequestTime = new Date(spRequestTime);
              const diffTroubleShootTime = Math.abs(spRequestTime - jobStartTime);
              const diffTroubleShootMin = Math.floor(diffTroubleShootTime / 60000);
              row.TroubleShootTime = diffTroubleShootMin;
            }

            if (row.techExc && row.techExc.SPRequestTime && row.techExc.SPReceivedTime) {
              let RequestSPTime: any = row.techExc.SPRequestTime;
              let spReceivedTime: any = row.techExc.SPReceivedTime;
              RequestSPTime = new Date(RequestSPTime);
              spReceivedTime = new Date(spReceivedTime);
              const diffWaitingSPTime = Math.abs(spReceivedTime - RequestSPTime);
              const diffWaitingSPMin = Math.floor(diffWaitingSPTime / 60000);
              row.WaitingSPTime = diffWaitingSPMin;
            }

            if (row.ClosedAt) {
              let JobStartTime: any = row.CreatedAt;
              let JobSolvedTime: any = row.ClosedAt;
              JobStartTime = new Date(JobStartTime);
              JobSolvedTime = new Date(JobSolvedTime);
              const diffMDTTime = Math.abs(JobSolvedTime - JobStartTime);
              const diffMDTMin = Math.floor(diffMDTTime / 60000);
              row.MDTTime = diffMDTMin;
            }

            if (row.WOStatus == '2' || row.WOStatus == '3') {
              let startDate: any = row.CreatedAt;
              let completeDate: any = row.techExc.CompleteTime;
              startDate = new Date(startDate);
              completeDate = new Date(completeDate);
              const diffTime = Math.abs(completeDate - startDate);
              const diffMin = Math.floor(diffTime / 60000);
              row.MTTRTime = diffMin;
            }

            if ((this.rowData.length - 1) > i) {
              let fromDate: any = this.rowData[i].CreatedAt;
              let toDate: any = this.rowData[i + 1].CreatedAt;
              fromDate = new Date(fromDate);
              toDate = new Date(toDate);
              const diffTime = Math.abs(toDate - fromDate);
              const diffMin = Math.floor(diffTime / 60000);
              row.MTBFTime = diffMin;
            }

            row.WOStatus = row.WOStatus == '1' ? 'Open' : row.WOStatus == '2' ? 'Close' : row.WOStatus == '3' ? 'Complete' : row.WOStatus == '4' ? 'Delay' : 'In Progress';
            let operatorImages = row.UploadPicture.split(',');
            row.UploadPicture = operatorImages[operatorImages.length - 1];
            i++;
          }
        }
        this.spinner.hide();
      }, (error: any) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();
      });
    }
  };

  // getComponentList(event: any) {
  //   let UnitObj = this.UnitsByLine.find((unit: any) => {
  //     return unit.puDesc == event.point.category
  //   });
  //   if (UnitObj)
  //     this.getComponentByMachine(UnitObj.puId)
  // }

  // getComponentByMachine(unitId: any) {
  //   this.spinner.show();
  //   const data = {
  //     UnitId: unitId,
  //     StartDate: this.HeaderData.StartDate,
  //     EndDate: this.HeaderData.EndDate
  //   }
  //   this.pmDashboardService.getComponentByMachine(data).subscribe((res: any) => {
  //     this.ComponentByUnit = res.Component;
  //     this.spinner.hide();
  //   }, (error: any) => {
  //     this.toaster.error('error', error.message);
  //     this.spinner.hide();
  //   });
  // }

  drawComponentChart() {
    let componentLabel: any[] = new Array();
    let componentData: any[] = new Array();
    if (this.ComponentByUnit && this.ComponentByUnit.length) {
      this.ComponentByUnit.forEach((element: any) => {
        componentLabel.push(element.componentName);
        componentData.push([element.componentName, parseFloat(element.WOCount)]);
      });
    }
    this.highCharts = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: componentLabel,
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Components',
        type: 'column',
        data: componentData
      }]
    };
    let chart = new Chart(this.highCharts);
    this.chartComponent = chart;
  };

  /// AG Grid 
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  excelExport() {
    this.gridApi.exportDataAsExcel();
  };

  csvExport() {
    this.gridApi.exportDataAsCsv();
  };

  cellClicked(params: any): void {
    const col = params.colDef.field;
    if (col == 'UploadPicture' || col == 'techExc.AfterPicture') {
      this.image = params && params.value ? params.value : '';
      if (this.image != '')
        this.openPictureBeforeModal();
    }
  };

  openPictureBeforeModal(): void {
    this.currentImagePath = this.image === ''
      ? 'assets/images/no-image.png'
      : environment.O3CoreApiImageUrl + this.image;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  };

  imageZoomModal(imagePath: String): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  };

  //date formatter
  dateAsYYYYMMDDHHNNSS(date: any): string {
    return date.getFullYear()
      + '-' + this.leftpad(date.getMonth() + 1, 2)
      + '-' + this.leftpad(date.getDate(), 2)
      + ' ' + this.leftpad(date.getHours(), 2)
      + ':' + this.leftpad(date.getMinutes(), 2)
      + ':' + this.leftpad(date.getSeconds(), 2);
  };

  leftpad(val: any, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
      + String(val)).slice(String(val).length);
  };

}
