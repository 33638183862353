import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { ScorecardService } from 'src/app/services/scorecard.service';
import { UserPlant } from 'src/app/models/userPlant';
import * as _moment from 'moment';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslaterService } from 'src/app/services/translater.service';

const moment = _moment;
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-KPITargetReport',
  templateUrl: './KPITargetReport.component.html',
  styleUrls: ['./KPITargetReport.component.scss'],
})
export class KPITargetReportComponent implements OnInit {
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;
  groupList: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  calendar_en = {
    closeText: 'Close',
    prevText: 'Previous',
    nextText: 'Next',
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    weekHeader: 'Week',
    weekNumberTitle: 'W',
    firstDayOfWeek: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: '',
    timeOnlyTitle: 'Only Time',
    timeText: 'Time',
    hourText: 'Hour',
    minuteText: 'Minute',
    secondText: 'Second',
    currentText: 'Current Date',
    ampm: false,
    year: 'Year',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    list: 'Agenda',
    allDayText: 'All Day',
    moreLinkText: 'More...',
    noEventsText: 'No Events',
    today: 'Today',
    clear: 'Clear',
  };
  TotalKPIsWihtoutRACI: string;
  constructor(
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private scorecardservice: ScorecardService,
    private fb: FormBuilder,
    private usermanagementService: UserManagementService,
    private spinner: NgxSpinnerService,
    private translater: TranslaterService,
    public router: Router
  ) {
    this.columnDefs = [
      {
        field: 'FrequencyName',
        headerName: 'Frequency',
        sortable: true,
        resizable: true,
        wrapText: true,
        filter: true,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: '#ofKPIsCreated',
        headerName: '# of KPI’s Created',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: '#KpisWithTarget',
        headerName: '# KPI’s With Targets',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        headerValueGetter: this.localizeHeader.bind(this)
      }, 
      {
        field: 'Gap',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        cellStyle: (params: { value: number }) => {
          if (params.value === 0) {
            return {
              color: 'black',
              backgroundColor: '#00FF00',
              'font-weight': 'bold',
            };
          } else {
            return {
              color: 'white',
              backgroundColor: '#FF0000',
              'font-weight': 'bold',
            };
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: '#KpisWithActual',
        headerName: '# KPI’s With Actual',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ActualGap',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        cellStyle: (params: { value: number }) => {
          if (params.value === 0) {
            return {
              color: 'black',
              backgroundColor: '#00FF00',
              'font-weight': 'bold',
            };
          } else {
            return {
              color: 'white',
              backgroundColor: '#FF0000',
              'font-weight': 'bold',
            };
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
    ];
    this.columnDefsTwo = [
      {
        field: 'FrequencyName',
        headerName: 'Frequency',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'KPIName',
        headerName: 'KPI’s',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'WithTargetSetting',
        headerName: 'With Target Setting',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        cellStyle: (params: { value: string }) => {
          if (params.value === 'Yes') {
            return {
              color: 'black',
              backgroundColor: '#00FF00',
              'font-weight': 'bold',
            };
          } else {
            return {
              color: 'white',
              backgroundColor: '#FF0000',
              'font-weight': 'bold',
            };
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'WithActualSetting',
        headerName: 'With Actual Setting',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        cellStyle: (params: { value: string }) => {
          if (params.value === 'Yes') {
            return {
              color: 'black',
              backgroundColor: '#00FF00',
              'font-weight': 'bold',
            };
          } else {
            return {
              color: 'white',
              backgroundColor: '#FF0000',
              'font-weight': 'bold',
            };
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Responsible',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Accountable',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Consult',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Informed',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
    ];
    this.columnDefsThree = [
      {
        field: 'Accountable',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Responsible',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'TotalKPIs',
        hide: true,
        filter: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'AccountableKPIs',
        headerName: 'Accountable KPIs',
        filter: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'TotalKPIsWithoutTargets',
        hide: true,
        filter: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'KPIsWihoutTargetSettings',
        headerName: 'KPI’s Without Target Setting',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        width: '300px',
        cellStyle: (params: { value: string }) => {
          return {
            color: 'white',
            backgroundColor: '#FF0000',
            'font-weight': 'bold',
          };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'KPIsWihoutActualSettings',
        headerName: 'KPI’s Without Actual Setting',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        width: '300px',
        cellStyle: (params: { value: string }) => {
          return {
            color: 'white',
            backgroundColor: '#FF0000',
            'font-weight': 'bold',
          };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
    ];
  }
  targetReportOne!: FormGroup;
  targetReportTwo!: FormGroup;
  targetReportThree!: FormGroup;
  searchPlants!: UserPlant[];
  columnDefs!: any;
  columnDefsTwo!: any;
  columnDefsThree!: any;
  rowData: any;
  gridApi: any;
  gridColumnApi: any;
  gridApiTwo: any;
  gridColumnApiTwo: any;
  gridApiThree: any;
  gridColumnApiThree: any;
  startYear: any;
  endYear: any;
  statusBar!: any;
  TotalKPIsGridThree!: any;
  TotalKPIsWihtoutTargetGridThree!: any;
  TotalKPIsWihtoutActualGridThree!: any;
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
    floatingFilter: true,
  };
  ngOnInit(): any {
    //this.getLangWords();
    this.targetReportOne = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      fromDate: [''],
      toDate: [''],
    });
    this.targetReportTwo = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      fromDate: [''],
      toDate: [''],
    });
    this.targetReportThree = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      fromDate: [''],
      toDate: [''],
    });
    this.getGroupData();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    const today = new Date().getFullYear();
    this.startYear = new Date(today, 0);
    this.endYear = new Date(today, 11, 31);
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReadyTwo(params: any): any {
    this.gridApiTwo = params.api;
    this.gridColumnApiTwo = params.columnApi;
  }
  onGridReadyThree(params: any): any {
    this.gridApiThree = params.api;
    this.gridColumnApiThree = params.columnApi;
  }
  onFirstDataRendered(event: any): any {
    event.api.addCellRange({
      rowStartIndex: 1,
      rowEndIndex: 1,
      columns: ['TotalKPIs'],
    });
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        const obj = this.groupList[0];
        this.targetReportOne.get('group')?.setValue(obj);
        this.targetReportTwo.get('group')?.setValue(obj);
        this.targetReportThree.get('group')?.setValue(obj);
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value: string) {
    let val;
    if (this.langObj && this.langObj[value]) {
      val = this.langObj[value];
    }
    return val ? val : value;
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if (this.langObj) {
        this.gridApi.refreshHeader();
      }
    });
  }
  getPlants(): void {
    const key = {
      GroupId: this.targetReportOne.value.group.TypeId,
    };
    this.usermanagementService.getDepartments(key).subscribe(
      (res: any) => {
        this.searchPlants = res.DepartList;
        const plantObject = this.searchPlants[0];

        this.targetReportOne.get('plant')?.setValue(plantObject);
        this.targetReportTwo.get('plant')?.setValue(plantObject);
        this.targetReportThree.get('plant')?.setValue(plantObject);
        this.onPlantChange();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // getAllPlants(): void {
  //   const key = {
  //     userId: JSON.parse(localStorage.user).UserId,
  //   };
  //   this.kpiMaster.getUserPlants(key).subscribe(
  //     async (plants) => {
  //       this.searchPlants = plants;
  //       if (this.searchPlants.length === 1) {
  //         const plantObject = this.searchPlants[0];
  //         this.targetReportOne.get('plant')?.setValue(plantObject);
  //         this.targetReportTwo.get('plant')?.setValue(plantObject);
  //         this.targetReportThree.get('plant')?.setValue(plantObject);
  //         this.onPlantChange();
  //         this.onPlantChangeTwo();
  //         this.onPlantChangeThree();
  //       }
  //     },
  //     (error) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }
  // Start Target Summary One
  onDateChange(value: any): any {
    const getdata = {
      ptId: this.targetReportOne.value.plant.DeptId,
      fromDate: moment(this.targetReportOne.value.fromDate).format(
        'YYYY-MM-DD'
      ),
      toDate: moment(this.targetReportOne.value.toDate).format('YYYY-MM-DD'),
    };
    if (getdata.ptId === undefined || !getdata.fromDate) {
      this.toaster.warning('Warning', 'Please Select Plant And Dates');
      return;
    }
    this.scorecardservice.getKpiTargetSummaryOne(getdata).subscribe(
      (response: any) => {
        this.gridApi.setRowData(response);
        // if (this.rowData) {
        //   this.columnDefs = this.generateColumns(this.rowData);
        // }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onPlantChange(): any {
    const date = new Date();
    const getdata = {
      ptId: this.targetReportOne.value.plant.DeptId,
      fromDate: moment(new Date(date.getFullYear(), 0, 1)).format('YYYY-MM-DD'),
      toDate: moment(new Date(date.getFullYear(), 11, 31)).format('YYYY-MM-DD'),
    };
    this.spinner.show();
    if (getdata.ptId === undefined || !getdata.fromDate) {
      this.toaster.warning('Warning', 'Please Select Plant And Dates');
      this.spinner.hide();

      return;
    }
    this.scorecardservice.getKpiTargetSummaryOne(getdata).subscribe(
      (response: any) => {
        this.gridApi.setRowData(response);
        this.spinner.hide();

        this.onPlantChangeTwo();
        // if (this.rowData) {
        //   this.columnDefs = this.generateColumns(this.rowData);
        // }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }
  // End Target Summary One
  // Start Target Summary Two
  onPlantChangeTwo(): any {
    const date = new Date();
    const getdata = {
      ptId: this.targetReportTwo.value.plant.DeptId,
      fromDate: moment(new Date(date.getFullYear(), 0, 1)).format('YYYY-MM-DD'),
      toDate: moment(new Date(date.getFullYear(), 11, 31)).format('YYYY-MM-DD'),
    };
    this.spinner.show();

    if (getdata.ptId === undefined || !getdata.fromDate) {
      this.toaster.warning('Warning', 'Please Select Plant And Dates');
      this.spinner.hide();

      return;
    }
    this.scorecardservice.getKpiTargetSummaryTwo(getdata).subscribe(
      (response: any) => {
        this.gridApiTwo.setRowData(response);
        this.spinner.hide();

        this.onPlantChangeThree();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }
  onDateChangeTwo(value: any): any {
    const getdata = {
      ptId: this.targetReportTwo.value.plant.DeptId,
      fromDate: moment(this.targetReportTwo.value.fromDate).format(
        'YYYY-MM-DD'
      ),
      toDate: moment(this.targetReportTwo.value.toDate).format('YYYY-MM-DD'),
    };
    if (getdata.ptId === undefined || !getdata.fromDate) {
      this.toaster.warning('Warning', 'Please Select Plant And Dates');
      return;
    }
    this.scorecardservice.getKpiTargetSummaryTwo(getdata).subscribe(
      (response: any) => {
        this.gridApiTwo.setRowData(response);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  // END Target Summary Two
  // Start Target Summary Three
  onPlantChangeThree(): any {
    debugger;
    const date = new Date();
    const getdata = {
      ptId: this.targetReportThree.value.plant.DeptId,
      fromDate: moment(new Date(date.getFullYear(), 0, 1)).format('YYYY-MM-DD'),
      toDate: moment(new Date(date.getFullYear(), 11, 31)).format('YYYY-MM-DD'),
    };
    this.spinner.show();

    if (getdata.ptId === undefined || !getdata.fromDate) {
      this.toaster.warning('Warning', 'Please Select Plant And Dates');
      this.spinner.hide();

      return;
    }
    this.scorecardservice.getKpiTargetSummaryThree(getdata).subscribe(
      (response: any) => {
        this.gridApiThree.setRowData(response.TargetReport3);
        this.spinner.hide();

        this.TotalKPIsGridThree =
          response.TargetReport3.length > 0
            ? 'Total KPI’s : ' + response.TargetReport3[0].TotalKPIs
            : 'Total KPI’s : ' + 0;
        // tslint:disable-next-line: max-line-length
        this.TotalKPIsWihtoutTargetGridThree =
          response.TargetReport3.length > 0
            ? 'Total KPI’s Wihtout Target : ' +
            response.TargetReport3[0].TotalKPIsWithoutTargets
            : 'Total KPI’s Wihtout Target : ' + 0;
        this.TotalKPIsWihtoutActualGridThree =
          response.TargetReport3.length > 0
            ? 'Total KPI’s Wihtout Actual : ' +
            response.TargetReport3[0].TotalKPIsWithoutActual
            : 'Total KPI’s Wihtout Actual : ' + 0;
        this.TotalKPIsWihtoutRACI =
          response.KPIsWithoutRACI  ? 'Total KPI’s Wihtout RACI : ' + response.KPIsWithoutRACI
            : 'Total KPI’s Without RACI : ' + 0;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }
  onDateChangeThree(value: any): any {
    const getdata = {
      ptId: this.targetReportThree.value.plant.DeptId,
      fromDate: moment(this.targetReportThree.value.fromDate).format(
        'YYYY-MM-DD'
      ),
      toDate: moment(this.targetReportThree.value.toDate).format('YYYY-MM-DD'),
    };
    if (getdata.ptId === undefined || !getdata.fromDate) {
      this.toaster.warning('Warning', 'Please Select Plant And Dates');
      return;
    }
    this.scorecardservice.getKpiTargetSummaryThree(getdata).subscribe(
      (response: any) => {
        this.gridApiThree.setRowData(response.TargetReport3);
        this.TotalKPIsGridThree =
          response.TargetReport3.length > 0
            ? 'Total KPI’s : ' + response.TargetReport3[0].TotalKPIs
            : 'Total KPI’s : ' + 0;
        // tslint:disable-next-line: max-line-length
        this.TotalKPIsWihtoutTargetGridThree =
          response.TargetReport3.length > 0
            ? 'Total KPI’s Wihtout Target : ' +
            response.TargetReport3[0].TotalKPIsWithoutTargets
            : 'Total KPI’s Wihtout Target : ' + 0;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // END Target Summary Three

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
