
import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-renderer',
  template: `
  <nz-date-picker   [(ngModel)]="inputValue"  (keydown)="onKeyDown($event)"   (ngModelChange) = "onChange($event)" nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
    `
})

export class DatePickerComponent implements ICellEditorAngularComp  {

  inputValue: any;
  params: any;
  ctx: any;
  agInit(params: any): void {
    this.params = params;
    // this.api = params.api;
    // this.downFormatter = params.downFormatter;
    // this.valueFormatter = params.valueFormatter;
    console.log(params);
    this.inputValue = params.value;
    this.ctx = params.context;

}

ngAfterViewInit() {
  // this.picker.nzOpen = true;
}
isPopup(): boolean {
   return false; 
}
onClick(param:any){
this.inputValue =this.dateAsYYYYMMDDHHNNSS(new Date(param));
this.params.value = this.inputValue;
 //this.picker.nzOpen = false;
}

getValue(): any {
    let result;
    if (!this.inputValue) {
        result = '';
        this.params.value = '';
    } else {
        result = this.inputValue;
        this.params.value = this.inputValue;
    }
    return result;
}

onKeyDown(event:any): void {
let key = event.which || event.keyCode;
if (key === 38 || key === 40) {
  this.preventDefaultAndPropagation(event);
}else if (key == 13 || key == 9) { // enter
  // perform some validation on enter - in this example we assume all inputs are mandatory
  // in a proper application you'd probably want to inform the user that an input is blank'
  /**/
  this.inputValue = event.target.value
  this.params.value = event.target.value;
}
}

onChange(param:any){
this.inputValue = this.dateAsYYYYMMDDHHNNSS(new Date(param));
this.params.value = this.inputValue;
//this.picker.nzOpen = false;
this.params.stopEditing();
}
private preventDefaultAndPropagation(event:any) {
event.preventDefault();
event.stopPropagation();
}

dateAsYYYYMMDDHHNNSS(date: any): string {
return date.getFullYear()
  + '-' + this.leftpad(date.getMonth() + 1, 2)
  + '-' + this.leftpad(date.getDate(), 2)
  + ' ' + this.leftpad(date.getHours(), 2)
  + ':' + this.leftpad(date.getMinutes(), 2)
  + ':' + this.leftpad(date.getSeconds(), 2);
}
leftpad(val: any, resultLength = 2, leftpadChar = '0'): string {
return (String(leftpadChar).repeat(resultLength)
  + String(val)).slice(String(val).length);
}
}