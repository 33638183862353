<app-toolbar></app-toolbar>

<div class="machine-parameter-content">

  <div class="content-top-box">
    <div class="tab-content-top-box" style=" display: flex; justify-content:flex-end;">
      <button mat-flat-button color="accent" class="btn-accent" (click)="logout()"
        style="margin-right: 70px;">Logout</button>
    </div>
  </div>
  <!-- <button mat-flat-button color="accent" class="btn-accent " (click)="onSearch()">Search</button> -->

  <div class="plant-model-content">
    <ul class="breadcrumb" style="margin-left: 70px;">
      <li class="breadcrumb-item">
        <a class="breadcrumb-link" routerLink="/CustomerComplaint/CCPortal">Customer Portal</a>
      </li>
    </ul>
    <div class="wrapper-box wrapper-box-shadow grid-container" style="overflow: hidden;">
      <h1 class="main-title">Customer Complaint Management Portal</h1>
      <div class="mat-form-field-wrapper">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
        <form [formGroup]="ccrhistoryDataForm">
          <div class="grid-box">
            <!-- <div class="inputField" style="width: 30%;">
                  <nz-date-picker class="calender-input" id="fromPicker" formControlName="fromdate" #fromPicker nzFormat="dd/MM/yyyy"
                  [nzInputReadOnly]="true" style="width:100%;"></nz-date-picker>
                  <span class="floating-text">From Date</span>   
                </div> -->
            <mat-form-field appearence="fill" class="grid-box-filed-item">
              <mat-label class="placeholder-title">From Date</mat-label>
              <input class="placeholder-title" readonly="true" matInput class="placeholder-title" matInput
                readonly="true" formControlName="fromdate" [matDatepicker]="fromPicker" />
              <mat-datepicker-toggle matSuffix [for]="$any(fromPicker)"></mat-datepicker-toggle>
              <mat-datepicker #fromPicker></mat-datepicker>
            </mat-form-field>
            <!-- <div class="inputField" style="width: 30%;">
          <nz-date-picker class="calender-input" id="toPicker" formControlName="todate" #toPicker nzFormat="dd/MM/yyyy"
           [nzInputReadOnly]="true" style="width: 100%;"></nz-date-picker>
          <span class="floating-text">To Date</span>   
        </div> -->
            <mat-form-field appearence="fill" class="grid-box-filed-item">
              <mat-label class="placeholder-title">To Date</mat-label>
              <input class="placeholder-title" matInput readonly="true" formControlName="todate"
                [matDatepicker]="toPicker" />
              <mat-datepicker-toggle matSuffix [for]="$any(toPicker)"></mat-datepicker-toggle>
              <mat-datepicker #toPicker></mat-datepicker>

            </mat-form-field>
            <mat-form-field appearence="fill" class="grid-box-filed-item">
              <mat-label class="placeholder-title">Classification</mat-label>
              <mat-select formControlName="classification" (selectionChange)="onSearch()">
                <mat-option [value]="0">All</mat-option>
                <mat-option *ngFor="let classification of classifications" [value]="classification.classificationId">
                  {{classification.classificationName}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </form>

        <div class="content-row-flex-both-end content-top-row  ccr_content-top-row-order-processing">
          <div>
            <button mat-mini-fab mat-button color="accent" (click)="openModal()" pTooltip="Add New Customer Complaint"
              tooltipPosition="top">
              <i class="fa fa-plus" style="color: #fff"></i>
            </button>
            <!-- <button (click)="openModal()" class="add-btn" pTooltip="Add New CCR" tooltipPosition="right">
                <img src="assets/images/add.png" alt="" width="20px" height="20px">
              </button> -->
            <!-- <button mat-mini-fab mat-button class="search-icon-btn"
              (click)="onSearch()"
              pTooltip="Search" tooltipPosition="top"
              style="margin-left: 20px;">
              <i class="fa fa-search" style="color: #fff"></i>
            </button> -->
          </div>
          <form class="search-form">

            <input type="text" class="form-control" placeholder="Search" (keyup)="applyFilter($event)" />
            <button class="search-btn" mat-icon-button aria-label="">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.125 0.25C6.41793 0.25 7.65791 0.763614 8.57215 1.67785C9.48639 2.59209 10 3.83207 10 5.125C10 6.3325 9.5575 7.4425 8.83 8.2975L9.0325 8.5H9.625L13.375 12.25L12.25 13.375L8.5 9.625V9.0325L8.2975 8.83C7.4425 9.5575 6.3325 10 5.125 10C3.83207 10 2.59209 9.48639 1.67785 8.57215C0.763614 7.65791 0.25 6.41793 0.25 5.125C0.25 3.83207 0.763614 2.59209 1.67785 1.67785C2.59209 0.763614 3.83207 0.25 5.125 0.25ZM5.125 1.75C3.25 1.75 1.75 3.25 1.75 5.125C1.75 7 3.25 8.5 5.125 8.5C7 8.5 8.5 7 8.5 5.125C8.5 3.25 7 1.75 5.125 1.75Z"
                  fill="black" />
              </svg>
            </button>
          </form>
        </div>
        <div style="overflow-x: scroll;">
          <table mat-table [dataSource]="ccrData" class="basic-table plant-model-table" matSort
            style="margin-top: 20px;">
            <ng-container matColumnDef="ccrActions">
              <th mat-header-cell *matHeaderCellDef>
                <p class="header-text">Actions</p>
              </th>
              <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                <div class="row-action-btns" style="min-width: 80px;">
                  <button mat-icon-button class="edit-btn" aria-label="Edit Row" (click)="getCcrbyId(element)"
                    *ngIf='element.ccrStatus === "Pending"' pTooltip="Edit" tooltipPosition="top">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                        fill="white" />
                    </svg>
                  </button>
                  <button mat-icon-button class="tree-view-btn" aria-label="View Row Tree" (click)="delete(element)"
                    pTooltip="Delete" tooltipPosition="top" *ngIf='element.ccrStatus === "Pending"' pTooltip="Delete"
                    tooltipPosition="top">

                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                        fill="#F85439" />
                      <circle cx="17" cy="17" r="14" fill="#F85439" />
                      <path
                        d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                        fill="white" />
                    </svg>
                  </button>
                </div>
              </td>
            </ng-container>
            <!-- groupID Column -->
            <ng-container matColumnDef="ID">
              <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header
                class="header-min-width">
                <p class="header-text">ID</p>
              </th>
              <td mat-cell *matCellDef="let element">{{ element.ccrId }}</td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Type</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.typeName }}
              </td>
            </ng-container>

            <!-- classification Column -->
            <ng-container matColumnDef="classification">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Classification</p>
              </th>
              <td mat-cell *matCellDef="let element">{{ element.classificationName}}</td>
            </ng-container>

            <ng-container matColumnDef="region">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Region</p>
              </th>
              <td mat-cell *matCellDef="let element">{{ element.region}}</td>
            </ng-container>

            <!-- product Column -->
            <ng-container matColumnDef="product">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="grp-order">
                <p class="header-text">Product</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.prodDesc}}
              </td>
            </ng-container>

            <!-- batch Column -->
            <ng-container matColumnDef="batch">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
                <p class="header-text">Batch #</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.batch }}
              </td>
            </ng-container>

            <!-- Qty Column -->
            <ng-container matColumnDef="Qty">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Qty</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.quantity }}
              </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Description</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.ccrDesc}}
              </td>
            </ng-container>
            <ng-container matColumnDef="production">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Production Date</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.productionDate | date: "y/MM/dd" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="delivery">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Delivery Date</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.deliveryDate | date: "y/MM/dd" }}
              </td>
            </ng-container>

            <!-- run Column -->

            <ng-container matColumnDef="run">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Test Date</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.runDate | date: "y/MM/dd" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="pdelivery">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Promised Delivery Date</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.promisedDeliveryDate | date: "y/MM/dd" }}
              </td>
            </ng-container>

            <!-- delivery Column -->


            <!-- delay Column -->

            <!-- batch Column -->
            <ng-container matColumnDef="delay">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
                <p class="header-text">Delay</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.ccrDelay }}
              </td>
            </ng-container>


            <ng-container matColumnDef="sample">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
                <p class="header-text">SampleSentBy Courier</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.sampleSentBy }}
              </td>
            </ng-container>


            <ng-container matColumnDef="deliveryNote">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Delivery Notes</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.deliveryNote}}
              </td>
            </ng-container>

            <ng-container matColumnDef="otherNotes">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Other Notes</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.otherNotes}}
              </td>
            </ng-container>
            <ng-container matColumnDef="imagePath">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Image</p>
              </th>
              <td mat-cell *matCellDef="let element">
                <img [src]="element.imagePath !== null ?   savedPath + element.imagePath : 'assets/images/no-image.png'"
                  style="width: 40px;display: block;border-radius: 50%;"
                  (click)="imageZoomModal(savedPath + element.imagePath , displayRoleModal)" />
              </td>
            </ng-container>

            <ng-container matColumnDef="authStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Authenticity Status</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.ccrStatus}}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Status</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.status}}
              </td>
            </ng-container>
            <ng-container matColumnDef="remarks">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
                <p class="header-text">Remarks</p>
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.remarks}}
              </td>
            </ng-container>

            <!-- plantModelActions Column -->

            <tr mat-header-row *matHeaderRowDef="ccrHistoryColumns"></tr>
            <tr mat-row *matRowDef="
                let row;
                columns: ccrHistoryColumns
              "></tr>
          </table>

        </div>
        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[ 5,  10]" showFirstLastButtons>
        </mat-paginator>

      </div>
    </div>
  </div>



  <ng-template #displayRoleModal>
    <form [formGroup]="ccrDataForm" (ngSubmit)="formMode ? addCcr():updateCcr()">
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2> {{formMode ? 'Add New' : 'Update'}} Customer Complaint</h2>

          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>
      <mat-dialog-content>
        <div class="content-row-wrap ">
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Customer Name</mat-label>
            <input matInput placeholder="Enter Customer" formControlName="Customer" readonly />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Region</mat-label>
            <input matInput placeholder="Enter Region" formControlName="region" readonly required />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Address</mat-label>
            <input matInput placeholder="Enter Address" formControlName="address" readonly required />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Classification</mat-label>
            <mat-select name="classification" formControlName="classification">
              <mat-option *ngFor="let classification of classifications" [value]="classification.classificationId">
                {{classification.classificationName}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Type</mat-label>
            <mat-select name="type" formControlName="type" (selectionChange)="onChangeDataType()">
              <mat-option *ngFor="let type of types" [value]="type.typeId"> {{type.typeName}} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Enter description" formControlName="desc" required />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Product</mat-label>
            <mat-select name="product" formControlName="product" matTooltip="{{PDtooltip}}"
              matTooltipClass="custom-tooltip" matTooltipPosition="above" (selectionChange)="tooltipChange($event)">
              <mat-option *ngFor="let product of products" [value]="product.prodId"> {{product.prodDesc}} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Batch #</mat-label>
            <input matInput placeholder="Enter batch" formControlName="batch" required />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Quantity</mat-label>
            <input matInput type="number" placeholder="Enter quantity" formControlName="quantity" required />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Delivery Date</mat-label>
            <input matInput [matDatepicker]="deliveryPicker" [max]="maxDate" readonly="true" placeholder="Delivery Date"
              formControlName="delivery" (dateChange)="countdelay()" required>
            <mat-datepicker-toggle matSuffix [for]="deliveryPicker"></mat-datepicker-toggle>
            <mat-datepicker #deliveryPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field *ngIf="promisedDeliveryDate"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Promise Delivery Date</mat-label>
            <input matInput [matDatepicker]="productiondpicker" readonly="true" placeholder="Delivery date"
              formControlName="pdelivery" (dateChange)="countdelay()">
            <mat-datepicker-toggle matSuffix [for]="productiondpicker"></mat-datepicker-toggle>
            <mat-datepicker #productiondpicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Delivery Note</mat-label>
            <input matInput placeholder="Enter Delivery Note" formControlName="dnote" />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Other Notes</mat-label>
            <input matInput placeholder="Enter notes" formControlName="notes" />
          </mat-form-field>
          <mat-form-field *ngIf="Delay" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Delay</mat-label>
            <input matInput placeholder="Enter Delay" formControlName="delay" type="number" readonly />
          </mat-form-field>
          <mat-form-field *ngIf="productiondate"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Production Date</mat-label>
            <input matInput [matDatepicker]="productionpicker" [max]="maxDate" readonly="true"
              placeholder="Production Date" formControlName="pdate" required>
            <mat-datepicker-toggle matSuffix [for]="productionpicker"></mat-datepicker-toggle>
            <mat-datepicker #productionpicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field *ngIf="testDate" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Test Date</mat-label>
            <input matInput [matDatepicker]="runPicker" [max]="maxDate" readonly="true" placeholder="Run Date"
              formControlName="run" (dateChange)="changedate1()" required>
            <mat-datepicker-toggle matSuffix [for]="runPicker"></mat-datepicker-toggle>
            <mat-datepicker #runPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field *ngIf="sampleSentBy"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Sample sent by Courier</mat-label>
            <mat-select name="courier" formControlName="courier">
              <mat-option *ngFor="let courier of couriers" [value]="courier.sampleId">{{courier.sampleSentBy}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <input pInputText formControlName="image" accept="image/x-png,image/gif,image/jpeg" id="file" type="file"
            class="form-control imageInput" (change)="onFileChange($event)"
            style="height: 50px; margin-top: 15px; width: 22%; margin-left: 25px;">
          <button [disabled]="!ccrDataForm.valid" type="submit" *ngIf="!formMode" mat-mini-fab mat-button
            color="primary" matTooltip="Update" matTooltipClass="custom-tooltip" matTooltipPosition="above"
            style="margin-left: 20px; margin-top: 20px;">
            <i class="fa fa-pencil" style="color: #fff"></i>
          </button>
          <button type="submit" *ngIf="formMode" [disabled]="!ccrDataForm.valid" mat-mini-fab mat-button color="primary"
            matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"
            style="margin-left: 20px; margin-top: 20px;">
            <i class="fa fa-floppy-o" style="color: #fff"></i>
          </button>
        </div>

        <div class="p-field p-col-3">
          <img [src]="path !== null ?imageSrc:path+imageSrc" *ngIf="imageSrc" style="height: 100px; width:250px">
        </div>
      </mat-dialog-content>
    </form>
  </ng-template>
  <ng-template #imageZoomModel>
    <div mat-dialog-title>
      <div style="display: flex;justify-content: flex-end;margin-bottom: 20px;">
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>

    <img *ngIf="currentImagePath" [src]="currentImagePath" style="height: 78%;width: 100%; overflow: hidden;" />
    <div *ngIf="!currentImagePath" class="popup-text-border">
      <img src="assets/images/no-image.png">
    </div>
  </ng-template>