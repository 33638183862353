<section class="welcome-page">
    <app-welcome-banner [bgImage]="leftBannerImgurl"></app-welcome-banner>
    <div class="welcome-content">
      <div class="welcome-top-bar">
        <ul class="top-list d-flex justify-content-end align-items-center">
          <li>
            <a class="top-list-text">Already have an account?</a>
          </li>
          <li>
            <a mat-flat-button color="accent" class="btn-accent" routerLink="/login">Login</a>
          </li>
        </ul>
      </div>
      <div class="welcome-form-area">
        <h2 class="welcome-title">Sign up</h2>
        <form [formGroup]="SignupForm" (ngSubmit)="onSubmit()">
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-6"> 
            <label class="label" for="username">First Name</label>
            <input [ngClass]="{
                'is-invalid':
                  SignupForm.get('firstName')?.errors &&
                  SignupForm.get('firstName')?.touched
              }" type="text" formControlName="firstName" class="form-control"
               placeholder="Enter your first name "/>
               <p class="invalid-feedback" *ngIf="
               SignupForm.get('firstName')?.touched &&
               SignupForm.get('firstName')?.hasError('required')
             ">
                 * first name is required
               </p>
            </div>
            <div class="p-col-12 p-md-6">
            <label class="label" for="lastName">Last Name</label>
            <input [ngClass]="{
                'is-invalid':
                  SignupForm.get('lastName')?.errors &&
                  SignupForm.get('lastName')?.touched
              }" type="text" formControlName="lastName" class="form-control"
               placeholder="Enter your last name "/>
               <p class="invalid-feedback" *ngIf="
               SignupForm.get('lastName')?.touched &&
               SignupForm.get('lastName')?.hasError('required')
             ">
                 * last name is required
               </p>
          </div>
        </div>
          <div class="form-group">
            <label class="label" for="groupname">Enterprise Group</label>
            <input [ngClass]="{
                'is-invalid':
                  SignupForm.get('enterpriseGroup')?.errors &&
                  SignupForm.get('enterpriseGroup')?.touched
              }" type="text" formControlName="enterpriseGroup" class="form-control"
               placeholder="Enter your Enterprise Group name "/>
               <p class="invalid-feedback" *ngIf="
               SignupForm.get('enterpriseGroup')?.touched &&
               SignupForm.get('enterpriseGroup')?.hasError('required')
             ">
                 * Enterprise Group name is required
               </p>
          </div>

          <!-- <div class="form-group">
            <label class="label" for="activitydomain">Domain of Activity</label>
            <input [ngClass]="{
                'is-invalid':
                  SignupForm.get('domainActivity')?.errors &&
                  SignupForm.get('domainActivity')?.touched
              }" type="text" formControlName="domainActivity" class="form-control"
               placeholder="Enter your Domain Of Activity"/>
               <p class="invalid-feedback" *ngIf="
               SignupForm.get('domainActivity')?.touched &&
               SignupForm.get('domainActivity')?.hasError('required')
             ">
                 * Domain Of Activity is required
               </p>
          </div> -->

            <div class="form-group">
             
                <mat-label>Activity Domain</mat-label>
                <mat-select formControlName="domainActivity" [ngClass]="{
                  'is-invalid':
                  SignupForm.get('domainActivity')?.errors &&
                  SignupForm.get('domainActivity')?.touched
                }" class="form-control" placeholder="Please select Activity Domain">                
                  <mat-option value="Finance">Finance</mat-option>
                  <mat-option value="Accounts">Accounts</mat-option>
                  <mat-option value="Admin">Admin</mat-option>
                  <mat-option value="Registrar" >Registrar</mat-option>
                  <mat-option value="Others" >Others</mat-option>            
                </mat-select>
          </div>

          <div class="form-group">
            <label class="label" for="groupsize">Company/Group Size</label>
            <input [ngClass]="{
                'is-invalid':
                  SignupForm.get('groupSize')?.errors &&
                  SignupForm.get('groupSize')?.touched
              }" type="number" formControlName="groupSize" class="form-control"
               placeholder="Enter your Company/Group Size"/>
               <p class="invalid-feedback" *ngIf="
               SignupForm.get('groupSize')?.touched &&
               SignupForm.get('groupSize')?.hasError('required')
             ">
                 * Size is required
               </p>
          </div>
          <div class="form-group">
            <label class="label" for="emailAddress">Email address</label>
            <input [ngClass]="{
                'is-invalid':
                  SignupForm.get('emailAddress')?.errors &&
                  SignupForm.get('emailAddress')?.touched
              }" type="email" formControlName="emailAddress" class="form-control"
              placeholder="Enter your email address "/>
              <p class="invalid-feedback" *ngIf="
              SignupForm.get('emailAddress')?.touched &&
              SignupForm.get('emailAddress')?.hasError('required')
            ">
                * invalid email
              </p>
              <p
              class="invalid-feedback"
              *ngIf="
                SignupForm.get('emailAddress')?.hasError('email') &&
                SignupForm.get('emailAddress')?.touched
              "
            >
              * invalid email
            </p>
          </div>
          <div class="form-group">
            <label class="label" for="emailAddress">Re Enter: Email address</label>
            <input [ngClass]="{
                'is-invalid':
                  SignupForm.get('secondEmail')?.errors &&
                  SignupForm.get('secondEmail')?.touched
              }" type="email" formControlName="secondEmail" class="form-control"
              placeholder="Re:Enter your email address "/>
              <p class="invalid-feedback" *ngIf="
              SignupForm.get('secondEmail')?.touched &&
              SignupForm.get('secondEmail')?.hasError('required')
            ">
                * invalid email
              </p>
              <p
              class="invalid-feedback"
              *ngIf="
                SignupForm.get('secondEmail')?.hasError('email') &&
                SignupForm.get('secondEmail')?.touched
              "
            >
              * invalid email
            </p>
          </div>
          <div class="form-group">
            <label class="label" for="phonenumber">Phone Number</label>
            <input [ngClass]="{
                'is-invalid':
                  SignupForm.get('phoneNumber')?.errors &&
                  SignupForm.get('phoneNumber')?.touched
              }" type="number" formControlName="phoneNumber" class="form-control" 
              placeholder="Enter your phone number"/>
              <p class="invalid-feedback" *ngIf="
              SignupForm.get('phoneNumber')?.touched &&
              SignupForm.get('phoneNumber')?.hasError('required')
            ">
                * Phone Number is required
              </p>
              <p class="invalid-feedback" *ngIf="
              SignupForm.get('phoneNumber')?.touched &&
              SignupForm.get('phoneNumber')?.hasError('minLength')
            ">
                * Phone Number is atleast 11 characters
              </p>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <mat-checkbox color="accent"> I agree to the O3 terms and condition </mat-checkbox>
            <button type="button" mat-button class="btn-base btn-simple" routerLink="/forgotpassword">Forgot
              Password</button>
          </div>
          <button type="submit" mat-flat-button color="accent" [disabled]="!SignupForm.valid" class="btn-accent btn-lg submit-btn">
            Sign up
          </button>
        </form>
      </div>
    </div>
  </section>