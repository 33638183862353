import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { map, share, takeUntil } from "rxjs/operators";
import * as moment from 'moment';

@Component({
  selector: 'app-kpi-refresh',
  templateUrl: './kpi-refresh.component.html',
  styleUrls: ['./kpi-refresh.component.scss']
})
export class KpiRefreshComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  public valueSearchCtrl: FormControl = new FormControl();
  public filteredValueMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  KPIRefreshForm!: FormGroup;
  GroupList!: any[];
  PlantList1!: any[];
  frequencies!: any[];
  kpis!: any[];
  filteredGroups: any;
  filteredPlants: any;
  filteredKPIs: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  filteredDynamicValues: any;
  gridOptions: any = {
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true, // <-- HERE
      autoHeight: true, // <-- & HERE
      // minWidth: 225,
      filter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    }
  };
  gridOptionstarget: any = {
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true, // <-- HERE
      autoHeight: true, // <-- & HERE
      // minWidth: 225,
      filter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    }
  };
  columnDefs: any;
  columnTargetDefs: any;
  rowData: any[] = [];
  rowDataTarget: any[] = [];
  gridApi!: any;
  gridColumnApi: any;
  gridApitarget!: any;
  gridColumnApitarget: any;
  constructor(
    private formBuilder: FormBuilder, 
    private productivityservice: ProductivityService, 
    private toaster: ToastrService, 
    public router: Router,
    private translater: TranslaterService,
    private usermanagementService: UserManagementService, 
    private kpiMaster: KpimasterService,) 
    { }

  ngOnInit(): void {
    this.initForm();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.datasource();

    this.valueSearchCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterValue();
      });

    this.columnDefs = [
      { headerName: 'KPI Name', field: 'Name', headerValueGetter: this.localizeHeader.bind(this), },
      { headerName: 'KPI Code', field: 'KPICode', headerValueGetter: this.localizeHeader.bind(this), },
      { headerName: 'Value', field: 'Value', headerValueGetter: this.localizeHeader.bind(this), },
      {
        headerName: 'Date', field: 'DATE', valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
        headerValueGetter: this.localizeHeader.bind(this),
      },
      {
        headerName: 'CreatedOn', field: 'CreatedOn', valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
        headerValueGetter: this.localizeHeader.bind(this),
      }
    ]

    this.columnTargetDefs = [
      { headerName: 'KPI', field: 'Name', headerValueGetter: this.localizeHeader.bind(this), },
      { headerName: 'Code', field: 'KPICode', headerValueGetter: this.localizeHeader.bind(this), },
      { headerName: 'Result', field: 'Value', headerValueGetter: this.localizeHeader.bind(this), },
      {
        headerName: 'Date', field: 'DATE', valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
        headerValueGetter: this.localizeHeader.bind(this),
      },
      {
        headerName: 'CreatedOn', field: 'CreatedOn', valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
        headerValueGetter: this.localizeHeader.bind(this),
      }
    ]
  }

  filterValue(): void {
    if (!this.filteredDynamicValues) {
      return;
    }
    let search = this.valueSearchCtrl.value;
    if (!search) {
      this.filteredValueMulti.next(this.filteredDynamicValues);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredValueMulti.next(
      this.filteredDynamicValues.filter(
        (value: any) => value.fullCodeName.toLowerCase().indexOf(search) > -1
      )
    );
  }
  initForm() {
    const dateStart = new Date();
    dateStart.setDate(dateStart.getDate() - 60);
    dateStart.setHours(6);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);

    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate());
    this.KPIRefreshForm = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
      frequencies: [],
      kpi: []
    });
  }

  datasource(): void {
    const key = {
      UserId: 0,
    };
    const id = {
      value: 0,
    };
    this.productivityservice.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.lstEG;
        this.KPIRefreshForm.get('UserGroup1')?.setValue(this.GroupList[0].EG_Id);
        this.filteredGroups = this.GroupList.slice();
        this.getPlants(id);

      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(event: any): void {
    const key = {
      EG_ID: this.KPIRefreshForm.value.UserGroup1,
      userId: 206,
    };
    this.productivityservice.getPlantsData(key).subscribe(
      (data: any) => {
        this.PlantList1 = data.lstPlant;
        if (this.PlantList1.length > 0) {
          const id = data.lstPlant[0].PT_Id;
          this.KPIRefreshForm.get('UserPlant1')?.setValue(id);
          this.filteredPlants = this.PlantList1.slice();
          this.getFrequencies();
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value: string) {
    let val;
    if (this.langObj && this.langObj[value]) {
      val = this.langObj[value];
    }
    return val ? val : value;
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if (this.langObj) {
        //this.gridApi.refreshHeader();
      }
    });
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  onChangeGroup(data: any): void {
    this.getPlants(data);
  }

  onChangePlant(data: any): void {
    this.getFrequencies();
  }

  getFrequencies(): void {
    const key = {
      plantId: this.KPIRefreshForm.value.UserPlant1,
    };
    this.kpiMaster.getMasterFrequencies(key).subscribe(
      (frequencies) => {
        this.frequencies = frequencies;
        this.KPIRefreshForm.get('frequencies').patchValue(this.frequencies[0].frequencyId); // setting default
        const obj = {
          value: this.frequencies[0].frequencyId
        }
        this.onFrequencyChange(obj);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onFrequencyChange(event: any): any {
    const key = {
      plantId: this.KPIRefreshForm.value.UserPlant1,
      frequencyId: event.value,
    };

    this.kpiMaster.getKpiFrequency(key).subscribe(
      (kpis) => {
        this.kpis = kpis;
        this.filteredDynamicValues = this.kpis?.slice();
        this.filteredValueMulti.next(this.filteredDynamicValues);
        this.filterValue();
        this.getKPIActualData();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getKPIActualData() {
    const key = {
      plantId: this.KPIRefreshForm.value.UserPlant1,
      frequencyId: this.KPIRefreshForm.value.frequencies,
      startDate: this.KPIRefreshForm.value.dateStart,
      endDate: this.KPIRefreshForm.value.dateEnd,
      kpiIds: this.KPIRefreshForm.value.kpi !== null ? this.KPIRefreshForm.value.kpi.toString() : '0'
    };
    this.kpiMaster.KPIActualData(key).subscribe(
      (kpis) => {
        this.rowData = kpis.Table;
        this.rowDataTarget = kpis.Table1;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onSubmit() {
    const key = {
      plantId: this.KPIRefreshForm.value.UserPlant1,
      startDate: this.KPIRefreshForm.value.dateStart,
      endDate: this.KPIRefreshForm.value.dateEnd,
      kpiIds: this.KPIRefreshForm.value.kpi !== null ? (this.KPIRefreshForm.value.kpi).toString() : ''
    };
    this.kpiMaster.saveKPIRefreshData(key).subscribe(
      (response) => {
        this.toaster.success(response.ErrorMessage);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );

  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout('autoHeight');
    const e: HTMLElement = document.querySelector('#myGrid') as HTMLElement;
    if (e !== null) {
      e.style.height = '';
    }
  }

}
