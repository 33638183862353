<section class="welcome-page">
  <app-welcome-banner [bgImage]="leftBannerImgurl"></app-welcome-banner>
  <div class="welcome-content">

    <div class="welcome-form-area">
      <h2 class="welcome-title">Customer Login</h2>
      <form [formGroup]="LoginForm">
        <div class="form-group">
          <label class="label" for="email">Email</label>
          <input type="email" class="form-control form-control-lg" placeholder="Email" required autocomplete="off"
            formControlName="email">

        </div>
        <div class="form-group">
          <label class="label" for="password">Password</label>
          <input type="password" class="form-control form-control-lg" placeholder="Password" required autocomplete="off"
            formControlName="password">

        </div>
        <div class="d-flex justify-content-between align-items-center">
          <mat-checkbox color="accent"> Remember Me </mat-checkbox>
          <button type="button" mat-button class="btn-base btn-simple" routerLink="/forgotpassword">Forgot
            Password</button>
        </div>
        <button mat-flat-button color="accent" class="btn-accent btn-lg submit-btn" (click)="Login()">
          Login
        </button>
        <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
          <p style="font-size: 20px; color: #050a4f">Authenticating...</p>
        </ngx-spinner>
      </form>
    </div>
  </div>
</section>