<div class="availability-analysis-content">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="title-row-flex-both-end">
      <h1 class="main-title">Availability Analysis</h1>
      <div class="basic-mat-input">
        <span class="date-label">Last 30 days</span>
        <mat-form-field appearence="fill">
          <!-- <mat-label>Choose date range</mat-label> -->
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="startDate">
            <input matEndDate formControlName="endDate">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- Dropdown -->
  <form [formGroup]="dropDownForm">
    <div class="drop-down-group">
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Year</mat-label>
          <mat-select name="Year">
            <mat-option *ngFor="let Year of yearList" [value]="Year">
              {{Year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Month</mat-label>
          <mat-select name="Month">
            <mat-option *ngFor="let Month of monthList" [value]="Month">
              {{Month}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Week</mat-label>
          <mat-select name="Week">
            <mat-option *ngFor="let Week of weekList" [value]="Week">
              {{Week}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Day</mat-label>
          <mat-select name="Day">
            <mat-option *ngFor="let Day of dayList" [value]="Day">
              {{Day}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Shift</mat-label>
          <mat-select name="Shift">
            <mat-option *ngFor="let Shift of shiftList" [value]="Shift">
              {{Shift}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Machine</mat-label>
          <mat-select name="Machine">
            <mat-option *ngFor="let Machine of machineList" [value]="Machine">
              {{Machine}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <!-- Charts -->
  <div class="chart-group">
    <!-- OEE Loses -->
    <div class="chart-items">
      <div class="chart-title">OEE Loses</div>
      <canvas id="myChart"></canvas>
    </div>
    
    <!-- Availability Loses -->
    <div class="chart-items">
      <div class="chart-title">Availability Loses</div>
      <canvas id="myChart1"></canvas>
    </div>

    <!-- Planned Downtime -->
    <div class="chart-items">
      <div class="chart-title"> Planned Downtime</div>
      <canvas id="myChart2"></canvas>
    </div>

    <!-- Unplanned Downtime -->
    <div class="chart-items">
      <div class="chart-title"> Unplanned Downtime</div>
      <canvas id="myChart3"></canvas>
    </div>

    <!-- Changeovers DT -->
    <div class="chart-items">
      <div class="chart-title"> Changeovers DT</div>
      <canvas id="myChart4"></canvas>
    </div>

    <!-- Mechanical Downtime -->
    <div class="chart-items">
      <div class="chart-title"> Mechanical Downtime</div>
      <canvas id="myChart5"></canvas>
    </div>

    <!-- # of Changeovers -->
    <div class="chart-items">
      <div class="chart-title"> # of Changeovers</div>
      <canvas id="myChart6"></canvas>
    
    </div>

    <!-- Short Stops -->
    <div class="chart-items">
      <div class="chart-title"> Short Stops</div>
      <canvas id="myChart7"></canvas>
    </div>

    <!-- Preventive Maint. DT -->
    <div class="chart-items">
      <div class="chart-title"> Preventive Maint. DT</div>
      <canvas id="myChart8"></canvas>
    </div>

    <!-- Electrical Downtime -->
    <div class="chart-items">
      <div class="chart-title"> Electrical Downtime</div>
      <canvas id="myChart9"></canvas>
    </div>

    <!-- Autonomous Maint. DT -->
    <div class="chart-items">
      <div class="chart-title"> Autonomous Maint. DT</div>
      <canvas id="myChart10"></canvas>
    </div>

    <!-- Utilities Downtime -->
    <div class="chart-items">
      <div class="chart-title"> Utilities Downtime</div>
      <canvas id="myChart11"></canvas>
    </div>

  </div>
