<div class="production-db-content">
    <div class="content-top-box">
        <ul class="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                    <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
                </li>
            </ul>
        </ul>
        <div class="title-row-flex-both-end">
            <h1 class="main-title">Product Cost Configuration</h1>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <form [formGroup]="sfdcnewform">
        <div class="content-top-row content-top-row-order-processing">
            <!-- GROUP -->
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <!-- <mat-label>Status</mat-label> -->
                <mat-select formControlName="UserGroup1" (selectionChange)="onChangeGroup($event)" placeholder="Group"
                    panelClass="testClass" name="Status">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
                        (filteredReturn)="filteredGroups = $event"></mat-select-filter>
                    <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
                        {{group.EG_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="UserPlant1" (selectionChange)="onChangePlant($event)" placeholder="Plant"
                    panelClass="testClass" name="Status">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList1"
                        (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                    <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
                        {{plant.PT_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="line" (selectionChange)="onChangeLine($event)" placeholder="Line"
                    panelClass="testClass" name="line">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
                        (filteredReturn)="filteredLines = $event"></mat-select-filter>
                    <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
                        {{line.PL_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="unit" placeholder="Unit" panelClass="testClass" name="unit"
                    (selectionChange)="onChangeUnit()">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="unitList"
                        (filteredReturn)="filteredUnits = $event"></mat-select-filter>
                    <mat-option *ngFor="let unit of filteredUnits" [value]="unit.PU_Id">
                        {{unit.PU_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="product" placeholder="Products" panelClass="testClass" name="product">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="productList"
                        (filteredReturn)="filteredProducts = $event"></mat-select-filter>
                    <mat-option *ngFor="let product of filteredProducts" [value]="product.TypeId">
                        {{product.TypeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="content-top-row content-top-row-order-processing">
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <input matInput type="text" formControlName="cost" placeholder="Product Cost">
            </mat-form-field>
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="uom" placeholder="uom" panelClass="testClass" name="uom">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="uomList"
                        (filteredReturn)="filteredUOMs = $event"></mat-select-filter>
                    <mat-option *ngFor="let uom of filteredUOMs" [value]="uom.TypeId">
                        {{uom.TypeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <input matInput type="text" formControlName="weight" placeholder="Product weight">
        </mat-form-field>
        </div>
      
        <!-- Dropdown -->
        <div class="content-top-row content-top-row-order-processing">
            <div class="inputField">
                <nz-date-picker class="calender-input" id="startPicker" formControlName="dateStart"
                    nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true">
                </nz-date-picker>
                <span class="floating-text">Start Date</span>
            </div>
            <div class="inputField">
                <nz-date-picker class="calender-input" id="endPicker" formControlName="dateEnd"
                    nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true">
                </nz-date-picker>
                <span class="floating-text">End Date</span>
            </div>
            <div class="btn-position">
                <button mat-raised-button color="accent" class="btn-accent" >Save</button>
            </div>
        </div>
    </form>
</div>