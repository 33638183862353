<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center">
    <mat-card-title class="p-ml-2">{{langObj ? langObj?.MeetingEffectivenessChecklist : ('MeetingEffectivenessChecklist' | translate)}}
    </mat-card-title>
    <div class="">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" type="text" class="form-control" placeholder="{{langObj ? langObj?.Search : ('Search' | translate)}}" />
      </mat-form-field>
    </div>
    <div class="">
      <button mat-mini-fab mat-button mat-dialog-close color="accent" *ngIf="IsWrite"
      [disabled]="!displaybtn"
        (click)="openModal(displayRoleModal)" pTooltip="Add New Effectiveness" tooltipPosition="top">
        <i class="fa fa-plus" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-card-content>
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
      <table mat-table class="basic-table plant-model-table" matSort [dataSource]="MeetingData">

        <ng-container matColumnDef="areaActions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-text">{{langObj ? langObj?.Actions : ('Actions' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <button mat-icon-button class="edit-btn" aria-label="Edit Row"
                (click)="EditMeetingMaster(element.MEC_Id, displayRoleModal)" pTooltip="Edit Meeting"
                tooltipPosition="top" *ngIf="IsWrite">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button class="node-control-btn node-delete" aria-label="delete meeting"
                (click)="onClickDeleteMeetingMaster(element.MEC_Id)" pTooltip="Delete Meeting" tooltipPosition="top"
                *ngIf="IsWrite">
                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                    fill="white" />
                </svg>
              </button>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="display: none;">
            <p class="header-text">{{langObj ? langObj?.Id : ('Id' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element" style="display: none;">{{element.MEC_Id}} </td>
        </ng-container>
        <ng-container matColumnDef="plant">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.Plant : ('Plant' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.plant}} </td>
        </ng-container>
        <ng-container matColumnDef="effectiveness">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.Effectiveness : ('Effectiveness' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.effectiveness}} </td>
        </ng-container>




        <tr mat-header-row *matHeaderRowDef="meetingmasterColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: meetingmasterColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3">{{langObj ? langObj?.nodatamatch : ('nodatamatch' | translate)}}
          </td>
        </tr>
      </table>
    </div>
    <mat-paginator class="basic-paginataor" [pageSizeOptions]="[2, 5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
  </mat-card-content>
</mat-card>

<ng-template #displayRoleModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between">
      <h1 mat-dialog-title style="margin-bottom: 0px;">{{ formMode ? "Add" : "Update" }} Effectiveness Checklist</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <form [formGroup]="MeetingForm" (ngSubmit)="formMode ? addCheckList() : updateCheckList()">

    <div class="content-top-row" style="margin-top: 0px;">
      <mat-form-field class="
          top-row-first-filed-item top-row-first-filed-item-order-processing
        " style="display: none;">
        <mat-label class="required">{{langObj ? langObj?.Group : ('Group' | translate)}}</mat-label>
        <mat-select panelClass="testClass" name="group" formControlName="group" (selectionChange)="getPlantByGroup()"
          [disabled]="true">
          <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId">
            {{ g.TypeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        style="margin-left: 0px;">
        <mat-label class="required">{{langObj ? langObj?.Plant : ('Plant' | translate)}}</mat-label>
        <mat-select name="list" formControlName="plant" [disabled]="true">
          <mat-option *ngFor="let o of plantList" [value]="o.DeptId">{{ o.DeptDesc }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label>{{langObj ? langObj?.EffectivenessTitle : ('EffectivenessTitle' | translate)}}</mat-label>
        <input matInput formControlName="effectivenessname" />
      </mat-form-field>

      <button mat-mini-fab mat-button type="button" color="accent" (click)="addGroupModal()" [matTooltip]="'Add Area'"
        matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px; margin-top: 5px;">
        <i class="fa fa-plus" style="color: #fff"></i>
      </button>
      <button mat-mini-fab mat-button type="button" color="accent" (click)="addChecklistRow()" matTooltip="Add Row"
        matTooltipClass="custom-tooltip" matTooltipPosition="above"
        style="margin-left: 20px; background-color: #19ACAB;margin-top: 5px;">
        <i class="fa fa-calendar-plus-o" style="color: #fff"></i>
      </button>
    </div>


    <table mat-table [dataSource]="MeetingListDetails" matSort formArrayName="meetingdetails">
      <ng-container matColumnDef="itemActions">
        <th mat-header-cell *matHeaderCellDef>
          <p class="header-text">{{langObj ? langObj?.Actions : ('Actions' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="row-action-btns-column">
          <div class="row-action-btns">
            <button mat-icon-button type="button" class="tree-view-btn" aria-label="View Row Tree"
              (click)="deleteRow(element, i)" matTooltip="Delete" matTooltipClass="custom-tooltip"
              matTooltipPosition="above">
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                  fill="#F85439" />
                <circle cx="17" cy="17" r="14" fill="#F85439" />
                <path
                  d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                  fill="white" />
              </svg>
            </button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="question">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">{{langObj ? langObj?.Questions : ('Questions' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex">
          <input matInput formControlName="question" placeholder="{{langObj ? langObj?.Question : ('Question' | translate)}}" />
        </td>
      </ng-container>

      <ng-container matColumnDef="areaname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 18%; padding-left: 0px">
          <p class="header-text">{{langObj ? langObj?.Area : ('Area' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex"
          style="padding: 13px 1px">
          <mat-select placeholder="{{langObj ? langObj?.AreaName : ('AreaName' | translate)}}" formControlName="areaname"
            (selectionChange)="CheckAreaType($event, rowIndex)">
            <mat-option *ngFor="let data of AreaList" [value]="data.areaname">{{ data.areaname }}
            </mat-option>
          </mat-select>
        </td>
      </ng-container>


      <ng-container matColumnDef="areaorder">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="display: none;">
          <p class="header-text">{{langObj ? langObj?.AreaNo : ('AreaNo' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex" style="display: none;">
          <input matInput [disabled]="true" formControlName="areaorder" />
        </td>
      </ng-container>

      <ng-container matColumnDef="areapercent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="display: none;">
          <p class="header-text">{{langObj ? langObj?.AreaPercent : ('AreaPercent' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex" style="display: none;">
          <input matInput [disabled]="true" formControlName="areapercent" />
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="itemColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: itemColumns"></tr>
    </table>
    <mat-divider></mat-divider>
    <div style="margin-top: 10px"></div>
    <button [style]="'float:right'" type="submit" mat-mini-fab mat-button color="primary"
      [disabled]="!MeetingForm.valid" [matTooltip]="formMode ? 'Save' : 'Update'"
      matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;cursor: pointer;">
      <i *ngIf="!isLoading" [class]="formMode ? 'fa fa-floppy-o' : 'fa fa-pencil'" style="color: #fff"></i>
      <!-- <mat-spinner color="accent" *ngIf="isLoading" diameter="20">
      </mat-spinner> -->
    </button>
  </form>
</ng-template>




<ng-template #displayGroupModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>{{langObj ? langObj?.AddAreaDetails : ('AddAreaDetails' | translate)}}</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>

  <form [formGroup]="MeetingAreaForm">
    <mat-dialog-content>
      <div class="content-top-row" style=" margin-top: 0px;">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="width: 25%; margin-left: 0px;">
          <mat-label class="required">{{langObj ? langObj?.AreaName : ('AreaName' | translate)}}</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterAreaName : ('EnterAreaName' | translate)}}" formControlName="areaname" />
        </mat-form-field>

        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 25%;">
          <mat-label class="required">{{langObj ? langObj?.AreaOrder : ('AreaOrder' | translate)}}</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterAreaOrder : ('EnterAreaOrder' | translate)}}" type="number" min="0" oninput="validity.valid||(value='');"
            formControlName="areaorder" />
        </mat-form-field>

        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 25%;">
          <mat-label class="required">{{langObj ? langObj?.AreaPercentage : ('AreaPercentage' | translate)}}</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterAreaPercentage : ('EnterAreaPercentage' | translate)}}" type="number" min="0"
            oninput="validity.valid||(value='');" formControlName="areapercent" />
        </mat-form-field>

        <button *ngIf="formAreaMode" type="submit" mat-mini-fab mat-button color="primary" (click)="SaveArea()"
          [disabled]="!MeetingAreaForm.valid" matTooltip="Save" matTooltipClass="custom-tooltip"
          matTooltipPosition="above" style="margin-left: 20px;margin-top: 5px;">
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
        <button   *ngIf="!formAreaMode" type="submit" mat-mini-fab mat-button color="primary" (click)="UpdateArea()"
        [disabled]="!MeetingAreaForm.valid" matTooltip="Update" matTooltipClass="custom-tooltip"
        matTooltipPosition="above" style="margin-left: 20px;margin-top: 5px;">
        <i class="fa fa-floppy-o" style="color: #fff"></i>
      </button>
      </div>

    </mat-dialog-content>
  </form>
<table mat-table [dataSource]="this.Areas" class="mat-elevation-z8 demo-table">
  <ng-container matColumnDef="Action">
    <th mat-header-cell *matHeaderCellDef>
      <p class="header-text">{{langObj ? langObj?.Actions : ('Actions' | translate)}}</p>
    </th>
    <td mat-cell *matCellDef="let element; let i = index" class="row-action-btns-column">
      <div class="row-action-btns">
        <button mat-icon-button type="button" class="tree-view-btn" aria-label="View Row Tree"
          (click)="deleteArea(element, i)" matTooltip="Delete" matTooltipClass="custom-tooltip"
          matTooltipPosition="above">
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
              fill="#F85439" />
            <circle cx="17" cy="17" r="14" fill="#F85439" />
            <path
              d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
              fill="white" />
          </svg>
         
        </button> 
        <button mat-icon-button type="button" class="tree-view-btn" aria-label="Edit Area"
        (click)="EditArea(element, i)" matTooltip="Edit" matTooltipClass="custom-tooltip">
        <mat-icon>edit</mat-icon>
      </button>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="areaId">
    <th mat-header-cell *matHeaderCellDef>{{langObj ? langObj?.No : ('No' | translate)}}.</th>
    <td mat-cell *matCellDef="let element;let i = index;">{{element.areaId}}</td>
  </ng-container>
  <ng-container matColumnDef="areaname">
    <th mat-header-cell *matHeaderCellDef>{{langObj ? langObj?.AreaName : ('AreaName' | translate)}}</th>
    <td mat-cell *matCellDef="let element">{{element.areaname}}</td>
  </ng-container>
  <ng-container matColumnDef="areaorder">
    <th mat-header-cell *matHeaderCellDef>{{langObj ? langObj?.AreaOrder : ('AreaOrder' | translate)}}</th>
    <td mat-cell *matCellDef="let element">{{element.areaorder}}</td>
  </ng-container>
  <ng-container matColumnDef="areapercent">
    <th mat-header-cell *matHeaderCellDef>{{langObj ? langObj?.AreaPercentage : ('AreaPercentage' | translate)}}</th>
    <td mat-cell *matCellDef="let element">{{element.areapercent}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="areaColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: areaColumns;"></tr>
</table>

</ng-template>