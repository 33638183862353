<div class="production-db-content">
    <div class="content-top-box">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
      </ul>
      <div class="title-row-flex-both-end">
        <h1 class="main-title">Gemba walk Dashboard</h1>
       
      </div>
    </div>
  
    <!-- Dropdown -->
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <form [formGroup]="auditDashboardForm">
      <div class="drop-down-group">
        <div class="drop-down-element">
          <mat-form-field>
            <mat-label appearance="fill" class="field-fill">Group</mat-label>
            <mat-select name="group" formControlName="group" (selectionChange)="getPlantByGroup()">
              <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
            </mat-select>
          </mat-form-field>
  
        </div>
        <div class="drop-down-element">
          <mat-form-field>
            <mat-label>Plant</mat-label>
            <mat-select name="plant" formControlName="plant" (selectionChange)="getAuditAreaByPlant()">
              <!-- <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="plantsDDL" (filteredReturn)="filterdplants =$event"></mat-select-filter>             -->
              <mat-option *ngFor="let p of filterdplants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="drop-down-element">
          <mat-form-field>
            <mat-label appearance="fill" class="field-fill">Area</mat-label>
            <mat-select name="area" formControlName="area" (selectionChange)="auditdashboardData()">
              <mat-option value="-1">All</mat-option>
              <mat-option *ngFor="let a of areaDDL" [value]="a.areaId"> {{a.areaName}} </mat-option>
  
            </mat-select>
          </mat-form-field>
        </div>

        <!-- <div class="drop-down-element">
          <mat-form-field>
            <mat-label appearance="fill" class="field-fill">{{langObj ? langObj?.Year : ('Year' | translate)}}</mat-label>
            <mat-select name="Year" formControlName="year" (selectionChange)="auditdashboardData()">
               <mat-option value="-1">All</mat-option>
              <mat-option *ngFor="let Year of yearList" [value]="Year">
                {{ Year }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div class="drop-down-element">
          <mat-form-field>
            <mat-label appearance="fill" class="field-fill">{{langObj ? langObj?.Month : ('Month' | translate)}}</mat-label>
            <mat-select name="Month" formControlName="month" (selectionChange)="auditdashboardData()">
              <mat-option value="-1">All</mat-option>
              <mat-option *ngFor="let Month of monthList" [value]="Month.value">
                {{ Month.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div class="drop-down-element">
          <mat-form-field>
            <mat-label appearance="fill" class="field-fill">{{langObj ? langObj?.Week : ('Week' | translate)}}</mat-label>
            <mat-select name="Week" formControlName="week" (selectionChange)="auditdashboardData()">
              <mat-option value="-1">All</mat-option>
              <mat-option *ngFor="let Week of weekList" [value]="Week">
                {{ Week }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div class="drop-down-element">
          <mat-form-field>
            <mat-label appearance="fill" class="field-fill">{{langObj ? langObj?.Day : ('Day' | translate)}}</mat-label>
            <mat-select name="Day" formControlName="day" (selectionChange)="auditdashboardData()">
              <mat-option value="-1">All</mat-option>
              <mat-option *ngFor="let Day of dayArray" [value]="Day">
                {{ Day }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div class="drop-down-element">
          <mat-form-field>
            <mat-label appearance="fill" class="field-fill">{{langObj ? langObj?.Shift : ('Shift' | translate)}}</mat-label>
            <mat-select name="Shift" formControlName="shift" (selectionChange)="auditdashboardData()">
              <mat-option value="-1">All</mat-option>
              <mat-option *ngFor="let Shift of shiftList" [value]="Shift">
                {{ Shift }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
     
       
          <!-- <div class="drop-down-element top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 18em;">
            <label class="floating-label">Date Range</label>  
            <input type="text" matInput ngxDaterangepickerMd formControlName="datePicker" [timePicker]="false"
              [timePicker24Hour]="false" [showDropdowns]="true" [closeOnAutoApply]="true"
              [locale]="{format: 'DD/MM/YYYY'}" [autoApply]="true" [readonly]="true" (change)="auditdashboardData()" />
          </div> -->
          <div class="inputField top-row-first-filed-item top-row-first-filed-item-order-processing">
            <nz-range-picker class="calender-input" [nzInputReadOnly]="true" formControlName="datePicker"
              nzFormat="dd/MM/YYYY" (ngModelChange)="auditdashboardData()"></nz-range-picker>
            <span class="floating-text">Choose Date Range</span>
          </div>
       
     
      </div>
     
     
    </form>
  
  
  </div>
  <!-- Charts -->
  <div class="chart-group">
    <!-- <div class="chart-items">
      <div class="chart-title">{{langObj ? langObj?.findoverall : ('findoverall' | translate)}}</div>
      <canvas id="POCompleted"></canvas>
    </div> -->
    <div class="chart-items">
      <div class="chart-title">Gemba walk Action Completion</div>
      <canvas id="POCompleted1"></canvas>
    </div>
    <div class="chart-items">
        <div class="chart-title">Gemba walk Action Open</div>
        <canvas id="POCompleted3"></canvas>
      </div>
  </div>
  
  <div class="chart-group">
    <!-- <div class="chart-items">
      <div class="chart-title">{{langObj ? langObj?.findbyarea : ('findbyarea' | translate)}}</div>
      <canvas id="POCompleted2"></canvas>
    </div> -->
    <!-- <div class="chart-items">
      <div class="chart-title">{{langObj ? langObj?.actionOpen : ('actionOpen' | translate)}}</div>
      <canvas id="POCompleted3"></canvas>
    </div> -->
  </div>
  <!-- <div class="chart-group">
  
<div style="display: flex; justify-content: center;">
      <div style="display: flex; height: 50px;">
        <div class="number" style="background-color: #00A94E;height: 70px; width: 20%"></div>
        <div class="text" style="font-size: large ; height:70px;padding: 20px!important;">{{langObj ? langObj?.TotalCompliance : ('TotalCompliance' | translate)}}</div>
      </div>
      <div style="display: flex; margin-left: 100px;">
        <p class="number text-center" style="background-color: #FFBE00; height: 70px; width: 20%"></p>
        <p class="text" style="font-size: large ; height:70px;padding: 20px!important;">{{langObj ? langObj?.SomeCompliance : ('SomeCompliance' | translate)}}</p>
      </div>
      <div style="display: flex; margin-left: 100px;">
        <p class="number text-center" style="background-color: #F41B35; height: 70px; width: 20%"></p>
        <p class="text" style="font-size: large ; height:70px;padding: 20px!important;">{{langObj ? langObj?.NoCompliance : ('NoCompliance' | translate)}}</p>
      </div>
      <div style="display: flex; margin-left: 100px;">
        <p class="number text-center" style="background-color: #A6A6A6; height: 70px; width: 20%"></p>
        <p class="text" style="font-size: large ; height:70px;padding: 20px!important;">{{langObj ? langObj?.NotExecution : ('NotExecution' | translate)}}</p>
      </div>
    </div>
    <div class="chart-items full-chart">
      <div class="chart-title">{{langObj ? langObj?.HeatMap : ('HeatMap' | translate)}}</div>
  
      <div id="chart">
        <apx-chart [series]="chartOptionsHeatMap.series" [chart]="chartOptionsHeatMap.chart"
          [dataLabels]="chartOptionsHeatMap.dataLabels" [plotOptions]="chartOptionsHeatMap.plotOptions"
          [tooltip]="chartOptionsHeatMap.tooltip" [colors]="chartOptionsHeatMap.colors"></apx-chart>
      </div>
    </div>
    
  
  </div> -->
  <!-- <highcharts-chart
     [Highcharts] = "highcharts"
     [options] = "options"
     style = "width: 100%; height: 400px; display: block;" id="heatmap">
  </highcharts-chart> -->
  
  <!-- <button type="button" (click)="onBtnExportCSV()" style="margin-top: 30px;">{{langObj ? langObj?.Downloadexportfile : ('Downloadexportfile' | translate)}}</button>
  <button type="button" (click)="onBtnExportExcel()" style="margin-top: 30px; margin-left: 10px;">{{langObj ? langObj?.DownloadExcelexportfile : ('DownloadExcelexportfile' | translate)}}</button>
   -->
  <!--ag-grid-->
  <!-- <div style="margin-top: 10px;height: 200px;">
    <ag-grid-angular style="width: 88%; height:100%;" class="ag-theme-alpine" [columnDefs]="columnDefs"
      [rowData]="dataArray" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"></ag-grid-angular>
  </div> -->
