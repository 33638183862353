import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlantGroupElement } from '../models/O3AdministrationModels/plant-model';
import { Plantbygroup } from '../models/plantbygroup';
import { ProductLine } from '../models/ProductLine';

@Injectable({
  providedIn: 'root'
})
export class DynamicDashboardService {
 

  O3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Common/GetGroup', data);
  }
  getTreeModal(data: any): Observable<PlantGroupElement[]> {
    return this.http.post<PlantGroupElement[]>(this.O3CoreApiUrl + 'Common/GetTreeModal', data);
  }
  // getGroups(): Observable<any[]> {
  //   return this.http.get<any[]>(this.kpiUrl + 'groups');
  // }

  // getPlants(groupId: any): Observable<Plantbygroup[]> {
  //   return this.http.post<Plantbygroup[]>(this.kpiUrl + 'plantsbygroup', groupId);
  // }

  getmasterline(data: any): Observable<ProductLine[]> {
    return this.http.post<ProductLine[]>(this.O3CoreApiUrl + 'Machine/GetMasterLines', data);
  }
  async getMachineByLine(data: any): Promise<any> {
    return await this.http.post<any[]>(this.O3CoreApiUrl + 'Machine/GetUnitsByLine', data).toPromise();
  }
  getProductsByUnit(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'NCR/Productsbyunit', data);
  }

  getPeopleMasterData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'PeopleMaster/GetEmployeeMasterInfoData', data);
  }
  getPeopleMasterEmpData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'PeopleMaster/GetEmployeeMasterInfo', data);
  }
  getLineList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Dashboard/GetLineList', data);
  }

  getUnitList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Dashboard/GetUnitList', data);
  }

  productionOutputByBarcode(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'BarcodePrint/ProductionOutputByBarcode', data);
  }

  GetFullNameByUserId(data): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Common/GetFullNameByUserId',data);
  }

  saveFavorite(data): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Common/SaveFavorite',data);
  }

  getFavoriteList(): Observable<any> {
    return this.http.get<any[]>(this.O3CoreApiUrl + 'Common/GetFavoriteList');
  }

  getCardList(data): Promise<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/GetUserDashboardCardList',data).toPromise();
  }

  getLeftCardList(data:any): Promise<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/GetUserDashboardLeftCardList',data).toPromise();
  }

  getRightCardList(data:any): Promise<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/GetUserDashboardRightCardList',data).toPromise();
  }
 
  getDataPointList(data:any): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/GetDataPointList',data);
  }

  saveDashboardCard(data): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/SaveDashboardCard', data);
  }

  getUnitDataByMultipleLines(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Dashboard/GetUnitListByMultipleLine', data);

  }
  
  getResponse(data: any): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Common/getOPenAIData',data);
  }

  getResponseVectara(data: any): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/GetVectaraAI',data);
  }

  getLotDetails(data:any): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Common/GetProductionLotDetails',data);
  }

  saveUserFilterData(data): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/saveUserFilterData',data);
  }
  getFilterData(): Observable<any> {
    return this.http.get<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/getFilterData');
  }

  getTopCardData(data:any): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/GetUserDashboardTopCardList',data);
  }

  saveTabsData(data:any): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/AddTab',data);
  }

  getTabsData(): Observable<any> {
    return this.http.get<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/getTabsData');
  }

  getChartList(data:any): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/GetCharts',data)
  }

  getDataPointSourceData(): Observable<any> {
    return this.http.get<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/getDataSourceData');
  }

  getDDBySourceList(params:any): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/GetDropdownByDataSourceName',params);
  }

  getAggregatesList(): Observable<any> {
    return this.http.get<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/getAllAggregated');
  }

  getUserList(params:any): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/GetUsersByPlantAndMenu',params);
  }

  saveShareTab(params:any): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/saveSharedTab',params);
  }
  getSharedTabs(data): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/mangeUserAccess',data);
  }
  getSharedAndIndividualTabs(): Observable<any> {
    return this.http.get<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/getSharedTabsData');
  }

  deleteSavedFilter(): Observable<any> {
    return this.http.get<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/deleteSavedFilter');
  }

  saveLayout(data:any): Observable<any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/saveLayout',data);
  }

  saveAnomalyCSVFileToBlobStorageAsync(data:any): Promise<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/saveAnomalyCSV',data).toPromise();
  }

  getPersonalDashboardData(data:any):Observable <any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/GetPersonalDBDetail',data);
  }

  deleteModels(data:any):Observable <any> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'DynamicDashboard/deleteModelInO3',data);
  }
 
}
