import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KpitreeService } from 'src/app/services/kpitree.service';
import * as moment from 'moment';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { MeetingMatrixService } from 'src/app/services/meeting-matrix.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { BtnDeleteCellRenderer } from 'src/app/btn-deletecell-renderer';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
import { MatDialog } from '@angular/material/dialog';
import { Options } from 'highcharts';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Chart } from 'angular-highcharts';
import { StockChart } from 'angular-highcharts';

@Component({
  selector: 'app-KpiWiseAnalysisReport',
  templateUrl: './KpiWiseAnalysisReport.component.html',
  styleUrls: ['./KpiWiseAnalysisReport.component.scss']
})
export class KpiWiseAnalysisReportComponent implements OnInit {
  @ViewChild('analysisChart') private analysisChart!: TemplateRef<any>;
  @ViewChild('visibleParameterComparison') private visibleParameterComparison!: TemplateRef<any>;
  @ViewChild('RACIdataModel') private RACIdataModel!: TemplateRef<any>;
  searchPlantForm!: FormGroup;
  groupList: any;
  plantList!: any[];
  versionList: any;
  frequencyList!: any[];
  kpiList!: any[];
  minDate: Date;
  maxDate: Date;
  dateRangeDisable: boolean = false;
  kpiSelectedCodeList: string = '';
  decimalPlacesForDridData: number = 4;
  frameworkComponents: any;
  kpiWiseAnalysisReportGridOptions: any = {
    defaultColDef: {
      sortable: true,
      floatingFilter: true,
      filter: 'agMultiColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    context: {
      thisComponent: this,
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRenderer: BtnDeleteCellRenderer,
      customLoadingOverlay: CustomLoadingOverlay,
    },
  };
  defaultColDef: {
    sortable: true;
    floatingFilter: true;
    filter: 'agMultiColumnFilter';
    headerHeight: 50;
    floatingFilterComponentParams: {
      suppressFilterButton: false;
    };
    filterParams: { newRowsAction: 'keep' };
  };
  rowSelection = 'multiple';
  kpiWiseAnalysisReportGridApi: any;
  kpiWiseAnalysisReportGridColumnApi: any;
  loadingOverlayForkpiWiseAnalysisReportGrid: any;
  gridcolumnDefs: any;
  rowDataForGrid: any;
  enableGlidePath: boolean = false;
  languageDir = 'ltr';
  langObj: any = {};
  breadcrumList!: any;
  moduleId!: any;
  pageId!: any;
  IsAccess!: any;
  IsWrite!: any;
  isSaur = false;
  chartType: boolean = false; // false column true line
  KPIAnalysisForm: FormGroup;
  chartArray: any[] = [];
  chartArrayForAnalysis: any[] = [];
  options: Options;
  options2: Options;
  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Analysis',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptions1: MatProgressButtonOptions = {
    active: false,
    text: 'Comparison',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptions3: MatProgressButtonOptions = {
    active: false,
    text: 'RACI',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  KPIComparisonForm: FormGroup;
  uniqueUoms: any[] = [];
  individualUom: any;
  compData: any[] = [];
  highChartsColor = [
    '#00008B',
    '#34A56F',
    '#F80000',
    '#2D0A77',
    '#8C001A',
    '#FF69B4',
    '#FA4A08',
    '#5453A6',
    '#8B008B',
    '#3090C7',
    '#F1C40F',
    '#CB4335',
    '#36454F',
    '#A569BD',
    '#FFA500',
    '#179fcc',
    '#093323',
    '#08fa03',
    '#048b8e',
    '#9637A4',
    '#F39C12',
    '#0b774e',
    '#F5B7B1',
    '#0d98da',
    '#512E5F',
    '#C471A5',
    '#0034bb',
    '#06573d',
    '#0d6e54',
    '#0692aa',
    '#611745',
    '#F80000',
    '#FFDF00',
    '#6D81A8',
    '#08FAC7',
    '#D208FA',
    '#FAEB08',
  ];
  stock: any;
  RACIRowData:any;
  RACIColumnDef:any;
  RACIGridOption: any = {
    defaultColDef: {
      sortable: true,
      floatingFilter: true,
      filter: 'agMultiColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
  };
  RACIGridApi: any;
  RACIGridColumnApi: any;
  KPIIdsArrayRACI:any[]= [];
  constructor(
    private usermanagementService: UserManagementService,
    private kpiservice: KpitreeService,
    private fb: FormBuilder,
    private translater: TranslaterService,
    private toaster: ToastrService,
    public router: Router,
    private kpiMaster: KpimasterService,
    private spinner: NgxSpinnerService,
    private meetingService: MeetingMatrixService,
    private dialog: MatDialog,
  ) {
    this.loadingOverlayForkpiWiseAnalysisReportGrid = 'customLoadingOverlay';
    this.RACIColumnDef = [
      {
        headerName: 'KpiId' ,
        field : 'KpiId' ,
        hide: true,
        resizable: true ,
      } ,
      {
        headerName: 'KPI Code' ,
        field : 'KPICode',
        resizable: true
      } ,
      {
        headerName: 'Responsible' ,
        field : 'R',
        resizable: true
      } ,
      {
        headerName: 'Accountable' ,
        field : 'A',
        resizable: true
      },
      {
        headerName: 'Consult' ,
        field : 'C',
        resizable: true
      },
      {
        headerName: 'Informed' ,
        field : 'I',
        resizable: true
      }];
  }

  ngOnInit() {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.searchPlantForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      kpis: ['', Validators.required],
      VersionList: ['', Validators.required],
      Frequency: ['', Validators.required],
      FromDate: [''],
      ToDate: ['']
    });
    var date = new Date();
    var firstDayfincialGap = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7
    );
    var lastDayfincialGap = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 2, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.searchPlantForm.get('FromDate').setValue(firstDayfincialGap);
    this.searchPlantForm.get('ToDate').setValue(lastDayfincialGap);
    this.KPIAnalysisForm = this.fb.group({
      fromDate: [firstDay, Validators.required],
      toDate: [lastDay, Validators.required],
    });
    this.KPIComparisonForm = this.fb.group({
      fromDate: [firstDay, Validators.required],
      toDate: [lastDay, Validators.required],
    });
    this.getGroupData();
    // this.KPIRACIInformation();
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        this.searchPlantForm.get('group')?.setValue(this.groupList[0]);
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(): void {
    const key = {
      GroupId: this.searchPlantForm.value.group.TypeId,
    };
    this.usermanagementService.getDepartments(key).subscribe(
      (res: any) => {
        this.plantList = res.DepartList;
        this.searchPlantForm.get('plant')?.setValue(this.plantList[0]);
        this.changeSelectList();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  changeSelectList(): void {
    this.versionList = [];
    this.frequencyList = [];
    this.getKPIVersion();
    this.getFrequencies();
  }

  getKPIVersion(): any {
    const key = {
      Plant: this.searchPlantForm.value.plant.DeptId
    }
    this.kpiservice.GetKPIVersion(key).subscribe(
      (data: any) => {
        let tempArr = [];
        const lockedData = data.filter(item => item.IsLocked === true);
        lockedData.forEach(element => {
          if (element.IsLocked == true) {
            const obj = {
              Id: element.Id,
              name: "Version " + element.VersionNo + "(" + moment(new Date(element.ApplicableFromDate)).format("DD/MM/YYYY") + "-" + moment(new Date(element.ApplicableToDate)).format("DD/MM/YYYY") + ")",
              IsLocked: element.IsLocked,
              FromDate: new Date(element.ApplicableFromDate),
              ToDate: element.ApplicableToDat ? new Date(element.ApplicableToDate) : new Date()
            }
            tempArr.push(obj);
          }

        });
        let firstElement = tempArr.find(element => element.IsLocked);
        let index = tempArr.findIndex(element => element.IsLocked);
        if (tempArr.length > 0) {
          if (index != -1) {
            tempArr[index].name = "Version " + data[index].VersionNo + "(" + moment(new Date(data[index].ApplicableFromDate)).format("DD/MM/YYYY") + "-" + (data[index].ApplicableToDate ? moment(new Date(data[index].ApplicableToDate)).format("DD/MM/YYYY") : "Continued") + ") (LIVE)";
          }
        }
        this.versionList = tempArr;
        this.searchPlantForm.get("VersionList").setValue(firstElement ? firstElement : tempArr[0]);
        this.setDateRangeFromVersion();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    )
  }

  setDateRangeFromVersion(): void {
    debugger
    if (this.searchPlantForm.value.VersionList) {
      this.dateRangeDisable = false;
      this.minDate = new Date(this.searchPlantForm.value.VersionList.FromDate);
      this.maxDate = new Date(this.searchPlantForm.value.VersionList.ToDate == 'Drafted' ? new Date() : this.searchPlantForm.value.VersionList.ToDate)
      // this.miDateDisable = new Date(this.searchPlantForm.value.VersionList.FromDate);
      // this.maxDateDisable = new Date(this.searchPlantForm.value.VersionList.name == 'Drafted'
      //   ? new Date() : this.searchPlantForm.value.VersionList.ToDate);
      this.searchPlantForm.get('FromDate').setValue(this.minDate);
      this.searchPlantForm.get('ToDate').setValue(this.maxDate);
      if (this.frequencyList.length) {
        this.getKpiList();
      }
    }
    else {
      this.toaster.warning('KPI Version List not available', 'Warning');
      this.searchPlantForm.get('FromDate').setValue('');
      this.searchPlantForm.get('ToDate').setValue('');
      this.dateRangeDisable = true;
      this.kpiList = [];
    }
  }

  getFrequencies(): void {
    this.spinner.show();
    const key = {
      plantId: this.searchPlantForm.value.plant.DeptId,
    };
    this.kpiMaster.getMasterFrequencies(key).subscribe(
      (frequency) => {
        this.spinner.hide();
        this.frequencyList = frequency;
        this.searchPlantForm.controls.Frequency.setValue(this.frequencyList[0]);
        this.getKpiList();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getKpiList(): void {
    if (this.versionList.length && this.frequencyList.length) {
      this.kpiList = [];
      const key = {
        ptId: this.searchPlantForm.value.plant.DeptId,
        versionId: this.searchPlantForm.value.VersionList.Id,
        freqId: this.searchPlantForm.value.Frequency.frequencyId,
      };
      this.kpiservice.GetAllKpisByPlantWithVersionAndFrequency(key).subscribe(
        (res: any) => {
          this.kpiList = res;
          this.searchPlantForm.controls.kpis.setValue(this.kpiList);
          if (this.kpiList.length) {
            this.onChangeKpiSelection();
          }
          else {
            this.kpiWiseAnalysisReportGridApi.hideOverlay();
            this.kpiWiseAnalysisReportGridApi.setRowData([]);
            this.onGridFirstDataRendered();
            this.toaster.warning('No KPI Available', 'Warning!');
          }
        },
        (error) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
    else {
      this.toaster.warning('All Fields are Required', 'Warning!');
    }
  }

  onChangeKpiSelection(): void {
    const kpiSelectedList = this.searchPlantForm.value.kpis;
    if (kpiSelectedList.length) {
      this.kpiSelectedCodeList = kpiSelectedList.map((kpi: any) => {
        return kpi.kpiCode;
      }).toString();
      if (this.kpiList.length == kpiSelectedList.length) {
        this.getKpiWiseAnalysisReportData();
      }
    }
    else {
      this.kpiSelectedCodeList = '';
    }
  }

  getKpiWiseAnalysisReportData(): void {
    this.kpiWiseAnalysisReportGridApi.showLoadingOverlay();
    const key = {
      frequencyId: this.searchPlantForm.value.Frequency.frequencyId,
      kpiCode: this.kpiSelectedCodeList,
      frequencyName: this.searchPlantForm.value.Frequency.name,
      plantId: this.searchPlantForm.value.plant.DeptId,
      fromDate: this.searchPlantForm.value.FromDate,
      toDate: new Date(new Date(this.searchPlantForm.value.ToDate).setHours(23, 59, 59, 0)),
    };
    this.meetingService.GetKpiWiseAnalysisReportData(key).subscribe(
      (data: any) => {
        this.createGridData(data);
        this.kpiWiseAnalysisReportGridColumnApi.resetColumnState();
        this.autoSizeAll();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  createGridData(data: any) {
    const tempGridData = JSON.parse(JSON.stringify(data));
    if (!tempGridData || tempGridData.length === 0) {
      this.kpiWiseAnalysisReportGridApi.hideOverlay();
      this.kpiWiseAnalysisReportGridApi.setRowData([]);
      this.onGridFirstDataRendered();
      return;
    }
    Object.entries(tempGridData).forEach((row: any) => {
      Object.keys(row[1]).map((key) => {
        if (
          key !== 'Type' &&
          key !== 'Indicator' &&
          key !== 'Trend' &&
          key !== 'Department Name' &&
          key !== 'Owner' &&
          key !== 'Responsible' &&
          key !== 'Last Month' &&
          key !== 'KPICode' &&
          key !== 'Previous' &&
          key !== 'MTD' &&
          key !== 'YTD' &&
          key !== 'KPIId' &&
          key !== 'meetingDefId' &&
          key !== 'meetingDefName' &&
          key !== 'Line' &&
          key !== 'Unit' &&
          key !== 'UoM' &&
          key !== 'KPI Name' &&
          key !== 'KPI Code' &&
          key !== 'Line' &&
          key !== 'FinancialGap' &&
          key !== 'Target Date'
        ) {
          if (row[1][key] !== undefined && row[1][key] !== null) {
            if (!isNaN(row[1][key]) && this.isDecimal(row[1][key])) {
              row[1][key] = Number(row[1][key]).toFixed(this.decimalPlacesForDridData);
            }
            row[1][key] = `${Number(row[1][key]).toFixed(this.decimalPlacesForDridData)} (${row[1].UoM})`;
          }
        }
        else {
          return key;
        }
      });
    });
    this.rowDataForGrid = tempGridData;
    this.kpiWiseAnalysisReportGridApi.showLoadingOverlay();
    this.kpiWiseAnalysisReportGridApi.setRowData(tempGridData);
    if (this.rowDataForGrid) {
      this.rowDataForGrid.forEach((row) => {
        delete row?.Owner;
        delete row?.Responsible;
      });
      this.gridcolumnDefs = this.generateColumnsForGrid(this.rowDataForGrid);
      const colorElements = document.getElementsByClassName('ag-body-viewport') as HTMLCollectionOf<HTMLElement>;
      colorElements[0].style.height = '25vw';
      colorElements[0].style.overflowY = 'scroll';
      setTimeout(() => {
        if (this.rowDataForGrid.length) {
          this.customStyleForGridHeaders();
          this.kpiWiseAnalysisReportGridColumnApi.resetColumnState();
          this.autoSizeAll();
        }
      }, 1000);
      this.kpiWiseAnalysisReportGridApi.hideOverlay();
      this.onGridFirstDataRendered();
    }
    else {
      this.kpiWiseAnalysisReportGridApi.hideOverlay();
    }
  }

  isDecimal(isNumber: any) {
    var status = isNumber - Math.floor(isNumber) !== 0;
    if (status) return true;
    else return false;
  }

  generateColumnsForGrid(rowDataForGrid: any[]): any {
    let columnDefinitions: any[] = [];
    const hideColumns = [
      'Unit',
      'Previous',
      'Trend',
      'Department Name',
      'Responsible',
      'Type',
      'UoM',
      'YTD',
      'MTD',
      'FinancialGap',
      'Target Date',
      'KPIId',
      'Line',
      // 'KPICode',
      'meetingDefId',
      'meetingDefName',
      'Owner'
    ];
    const cellClassColumns = [
      'Type',
      'Indicator',
      'Responsible',
      'Last Month',
      'KPICode',
      'MTD',
      'YTD',
      'KPIId',
      'meetingDefId',
      'meetingDefName',
      // 'Line',
      'UoM',
      'KPI Name',
      'Owner',
      'FinancialGap',
      'Target Date',
    ];
    const multiFilter = ['KPICode', 'KPI Name'];
    rowDataForGrid.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key === 'FinancialGap' ? 'Financial Gap' : key, // .toUpperCase()
          field: key,
          headerCheckboxSelection: key === 'KPI Name' ? true : false,
          checkboxSelection: key === 'KPI Name' ? true : false,
          showDisabledCheckboxes: key === 'KPI Name' ? true : false,
          resizable: true,
          hide: hideColumns.includes(key),
          cellStyle: {},
          sortable: true,
          autoHeight: true,
          headerClass: {},
          floatingFilter: multiFilter.includes(key) ? true : false,
          filter: multiFilter.includes(key) ? 'agMultiColumnFilter' : true,
          onCellClicked: {},
          cellClass: !cellClassColumns.includes(key)
            ? 'ag-grid-dynamic-cell'
            : '',
        };
        mappedColumn.cellStyle = (params: any): any => {
          const col = params.colDef.field;
          if (
            key !== 'Type' &&
            key !== 'Indicator' &&
            key !== 'Responsible' &&
            key !== 'Last Month' &&
            key !== 'KPICode' &&
            key !== 'MTD' &&
            key !== 'YTD' &&
            key !== 'KPIId' &&
            key !== 'meetingDefId' &&
            key !== 'meetingDefName' &&
            key !== 'Line' &&
            key !== 'UoM' &&
            key !== 'KPI Name' &&
            key !== 'Owner' &&
            key !== 'FinancialGap' &&
            key !== 'Target Date'
          ) {
            const styleObj = {
              'font-weight': 'bold',
              'background-color': '',
              color: 'black',
              textAlign: 'right'
            };
            if (params.data.Type === 'Actual') {
              let currentActualValue = params.value;
              this.kpiWiseAnalysisReportGridOptions.api.forEachLeafNode(
                (rowNode, index) => {
                  if (
                    rowNode.data.KPIId === params.data.KPIId &&
                    rowNode.data.Type === 'Glidepath'
                  ) {
                    if (params.api.getRowNode(index) !== undefined) {
                      const rowGlidepath = params.api.getRowNode(index).data;
                      if (
                        rowGlidepath[params.colDef.field] !== (undefined || null) &&
                        parseFloat(currentActualValue) > parseFloat(rowGlidepath[params.colDef.field]) &&
                        currentActualValue && rowGlidepath.Type === 'Glidepath'
                      ) {
                        if (params.data?.Trend === 'Up') {
                          styleObj['background-color'] = 'green';
                          styleObj.color = 'white';
                        } else {
                          styleObj['background-color'] = 'red';
                          styleObj.color = 'white';
                        }
                      }
                      else if (
                        rowGlidepath[params.colDef.field] !== (undefined || null) &&
                        parseFloat(currentActualValue) < parseFloat(rowGlidepath[params.colDef.field]) &&
                        currentActualValue && rowGlidepath.Type === 'Glidepath'
                      ) {
                        if (params.data?.Trend === 'Down') {
                          styleObj['background-color'] = 'green';
                          styleObj.color = 'white';
                        } else {
                          styleObj['background-color'] = 'red';
                          styleObj.color = 'white';
                        }
                      } else if (
                        rowGlidepath[params.colDef.field] !== (undefined || null) &&
                        parseFloat(currentActualValue) === 0 && parseFloat(rowGlidepath[params.colDef.field]) === 0 &&
                        rowGlidepath.Type === 'Glidepath'
                      ) {
                        styleObj['background-color'] = 'green';
                        styleObj.color = 'white';
                      } else if (
                        rowGlidepath[params.colDef.field] !== (undefined || null) &&
                        parseFloat(currentActualValue) === parseFloat(rowGlidepath[params.colDef.field]) &&
                        rowGlidepath.Type === 'Glidepath'
                      ) {
                        styleObj['background-color'] = 'green';
                        styleObj.color = 'white';
                      }
                    }
                  }
                }
              );
            } else {
              styleObj['background-color'] = 'yellow';
            }
            return styleObj;
          }
          if (col === 'KPI Name') {
            const styleObj = {
              'font-weight': 'bold',
            };
            return styleObj;
          }
          if (key === 'Type' && params.data.Type === 'Actual') {
            return { 'font-weight': 'bold', color: '#009688' };
          } else if (key === 'Type' && params.data.Type === 'Glidepath') {
            return { 'font-weight': 'bold', color: '#bf360c' };
          }
        };
        columnDefinitions.push(mappedColumn);
      });
    });
    // Remove duplicate columns
    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    return columnDefinitions;
  }

  customStyleForGridHeaders(): void {
    setTimeout(() => {
      var headerCell = document.getElementsByClassName('ag-header-cell-label') as HTMLCollectionOf<HTMLElement>;
      var headerCellText = document.getElementsByClassName('ag-header-cell-text');
      for (var i = 0; i < headerCellText.length; i++) {
        if (String((headerCellText[i] as HTMLElement).innerHTML).includes("W -")) {
          headerCell[i].style.display = 'flex';
          headerCell[i].style.justifyContent = 'center';
          var oldTxt = String(headerCellText[i].innerHTML);
          if (!oldTxt.includes("<br>")) {
            var oldTxtArr = oldTxt.split('(');
            if (!oldTxtArr[1].includes("<span>")) {
              oldTxtArr[1] = "<span style='font-size: 11px; color: #0f0fd1;'>" + "(" + oldTxtArr[1] + "</span>";
            }
            var newTxt = oldTxtArr[0] + "<br> " + oldTxtArr[1];
            headerCellText[i].innerHTML = newTxt;
          }
          var headerLabel = document.getElementsByClassName('ag-header-cell-label') as HTMLCollectionOf<HTMLElement>;
          headerLabel[i].style.textAlign = 'center';
        }
      }
    }, 1000);
  }

  kpiWiseAnalysisReportGridReady(params: any): void {
    this.kpiWiseAnalysisReportGridApi = params.api;
    this.kpiWiseAnalysisReportGridColumnApi = params.columnApi;
    this.kpiWiseAnalysisReportGridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#wrapper2grid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }

  onGridFirstDataRendered() {
    this.enableGlidePath = false;
    this.enableGlidePathChange({ checked: false });
  }

  enableGlidePathChange(event: any): void {
    const instance = this.kpiWiseAnalysisReportGridApi.getFilterInstance('Type');
    let Type = event.checked ? ['Glidepath', 'Actual'] : ['Actual'];
    let myArray = Type;
    instance?.setModel({
      values: myArray.length ? myArray : [],
    });
    this.kpiWiseAnalysisReportGridApi?.onFilterChanged();
  }

  autoSizeAll() {
    var allColIds = this.kpiWiseAnalysisReportGridOptions.columnApi
      .getAllColumns()
      .map((column) => column.colId);
    this.kpiWiseAnalysisReportGridOptions.columnApi.autoSizeColumns(allColIds);
    this.kpiWiseAnalysisReportGridOptions.columnApi.autoSizeAllColumns();
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      const obj1 = this.searchPlantForm.get('group').value;
      this.searchPlantForm.get('group')?.setValue(obj1);
      if (this.langObj) {
        // this.gridApi?.refreshHeader();
      }
    });
  }

  getTransalatedValue(value: string) {
    let val;
    if (this.langObj && this.langObj[value]) {
      val = this.langObj[value];
    }
    return val ? val : value;
  }

  onClickParamterAnalysis(isTrue) {
    //checking saur plant for leak detection
    const plant = this.plantList.find(
      (p) => p.DeptId === this.searchPlantForm.get('plant')?.value.DeptId
    );
    plant.DeptDesc === 'Secteur Hérault-Sud 1'
      ? (this.isSaur = true)
      : (this.isSaur = false);

    this.chartType = false;
    // this.KPIAnalysisForm.reset();
    const selectedRows = this.kpiWiseAnalysisReportGridApi.getSelectedRows();
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name']
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'] },
      ];
    }, []);
    const KPIName = distinctRows;
    this.KPIAnalysisForm.get('fromDate').setValue(this.searchPlantForm.value.FromDate);
    this.KPIAnalysisForm.get('toDate').setValue(this.searchPlantForm.value.ToDate);
    let dialog = this.dialog.open(this.analysisChart, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '100vh',
      minWidth: '95vw',
      maxHeight: '100vh',
    });

    dialog.afterClosed().subscribe((result) => {
      this.chartArray = [];
      this.chartArrayForAnalysis = [];
    });

    distinctRows.forEach((element) => {
      const columns = this.gridcolumnDefs
        .map((c) => c.field)
        .filter(
          (c) =>
            c !== 'Trend' &&
            c !== 'Type' &&
            c !== 'Indicator' &&
            c !== 'Responsible' &&
            c !== 'Last Month' &&
            c !== 'KPICode' &&
            c !== 'MTD' &&
            c !== 'YTD' &&
            c !== 'KPIId' &&
            c !== 'meetingDefId' &&
            c !== 'meetingDefName' &&
            c !== 'Line' &&
            c !== 'UoM' &&
            c !== 'KPI Name' &&
            c !== 'Owner' &&
            c !== 'FinancialGap' &&
            c !== 'Target Date' &&
            c !== 'Unit' &&
            c !== 'Previous' &&
            c !== 'Department Name'
        );
      const analysisDataTarget = [];
      const analysisData = [];
      const analysisLabel = [];
      const title = element['KPI Name'];
      // this.meetingTitle = title;
      const kpiid = element.KPIId;
      const chartObj = { id: '', chartOption: {} };
      const chartObj2 = { id: '', chartOption: {} };
      for (const col of columns) {
        this.kpiWiseAnalysisReportGridOptions.api.forEachLeafNode((rowNode, index) => {
          if (
            rowNode.data.KPICode === element.KPICode &&
            rowNode.data.Type === 'Glidepath'
          ) {
            const kpi = this.kpiWiseAnalysisReportGridApi.getRowNode(rowNode.id);
            const value = this.kpiWiseAnalysisReportGridApi.getValue(col, kpi);
            analysisLabel.push(col);
            analysisDataTarget.push(
              value ? parseFloat(value.replace(`(${element.UoM})`, '')) : 0
            );
          }
          if (
            rowNode.data.KPICode === element.KPICode &&
            rowNode.data.Type === 'Actual'
          ) {
            const kpi = this.kpiWiseAnalysisReportGridApi.getRowNode(rowNode.id);
            const value = this.kpiWiseAnalysisReportGridApi.getValue(col, kpi);
            if (analysisLabel.indexOf(col) !== -1) {
            } else {
              analysisLabel.push(col);
            }
            analysisData.push(
              value ? parseFloat(value.replace(`(${element.UoM})`, '')) : 0
            );
          }
        });
      }

      this.options = {
        title: {
          text: title,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: analysisLabel,
        },
        yAxis: {},
        tooltip: {
          valueDecimals: 2,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: (event) => {
                },
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y;
              },
            },
          },
        },
        series: [
          {
            type: 'column',
            name: this.langObj ? this.langObj?.Target : 'Target',
            data: analysisDataTarget,
            color: '#10ce9c',
          },
          {
            type: 'column',
            name: this.langObj ? this.langObj?.Actual : 'Actual',
            data: analysisData,
            color: 'blue',
          },
        ],
      };
      this.options2 = {
        title: {
          text: title,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: analysisLabel,
        },
        yAxis: {},
        tooltip: {
          valueDecimals: 2,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: (event) => {

                },
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y;
              },
            },
          },
        },
        series: [
          {
            type: 'line',
            name: this.langObj ? this.langObj?.Target : 'Target',
            data: analysisDataTarget,
            color: '#10ce9c',
          },
          {
            type: 'line',
            name: this.langObj ? this.langObj?.Actual : 'Actual',
            data: analysisData,
            color: 'blue',
          },
        ],
      };
      let chart = new Chart(this.options);
      let chart2 = new Chart(this.options2);
      chartObj.chartOption = chart;
      chartObj2.chartOption = chart2;
      this.chartArray.push(chartObj);
      this.chartArrayForAnalysis.push(chartObj2);
    });
  }


  onDateChangeInKPIAnaModal() {
    if (this.searchPlantForm.value.FromDate && this.searchPlantForm.value.ToDate) {
    }
    else {
    }
    this.chartArray = [];
    this.chartArrayForAnalysis = [];
    this.chartType = false;
    const selectedRows = this.kpiWiseAnalysisReportGridApi.getSelectedRows();
    const KPIs = selectedRows.map((row) => row.KPICode);
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name']
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'] },
      ];
    }, []);
    const KPIName = distinctRows;
    distinctRows.forEach((element) => {
      const columns = this.gridcolumnDefs
        .map((c) => c.field)
        .filter(
          (c) =>
            c !== 'Trend' &&
            c !== 'Type' &&
            c !== 'Indicator' &&
            c !== 'Responsible' &&
            c !== 'Last Month' &&
            c !== 'KPICode' &&
            c !== 'MTD' &&
            c !== 'YTD' &&
            c !== 'KPIId' &&
            c !== 'meetingDefId' &&
            c !== 'meetingDefName' &&
            c !== 'Line' &&
            c !== 'UoM' &&
            c !== 'KPI Name' &&
            c !== 'Owner' &&
            c !== 'FinancialGap' &&
            c !== 'Target Date' &&
            c !== 'Unit' &&
            c !== 'Previous' &&
            c !== 'Department Name'
        );
      const analysisDataTarget = [];
      const analysisData = [];
      const analysisLabel = [];
      const title = element['KPI Name'];
      // this.meetingTitle = title;
      const kpiid = element.KPIId;
      const chartObj = { id: '', chartOption: {} };
      const chartObj2 = { id: '', chartOption: {} };
      for (const col of columns) {
        this.kpiWiseAnalysisReportGridOptions.api.forEachLeafNode((rowNode, index) => {
          if (
            rowNode.data.KPICode === element.KPICode &&
            rowNode.data.Type === 'Glidepath'
          ) {
            const kpi = this.kpiWiseAnalysisReportGridApi.getRowNode(rowNode.id);
            const value = this.kpiWiseAnalysisReportGridApi.getValue(col, kpi);
            analysisLabel.push(col);
            analysisDataTarget.push(
              value ? parseFloat(value.replace(`(${element.UoM})`, '')) : 0
            );
          }
          if (
            rowNode.data.KPICode === element.KPICode &&
            rowNode.data.Type === 'Actual'
          ) {
            const kpi = this.kpiWiseAnalysisReportGridApi.getRowNode(rowNode.id);
            const value = this.kpiWiseAnalysisReportGridApi.getValue(col, kpi);
            if (analysisLabel.indexOf(col) !== -1) {
            } else {
              analysisLabel.push(col);
            }
            analysisData.push(
              value ? parseFloat(value.replace(`(${element.UoM})`, '')) : 0
            );
          }
        });
      }

      this.options = {
        title: {
          text: title,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: analysisLabel,
        },
        yAxis: {},
        tooltip: {
          valueDecimals: 2,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: (event) => {
                },
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y;
              },
            },
          },
        },
        series: [
          {
            type: 'column',
            name: this.langObj ? this.langObj?.Target : 'Target',
            data: analysisDataTarget,
            color: '#10ce9c',
          },
          {
            type: 'column',
            name: this.langObj ? this.langObj?.Actual : 'Actual',
            data: analysisData,
            color: 'blue',
          },
        ],
      };
      this.options2 = {
        title: {
          text: title,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: analysisLabel,
        },
        yAxis: {},
        tooltip: {
          valueDecimals: 2,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: (event) => {

                },
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y;
              },
            },
          },
        },
        series: [
          {
            type: 'line',
            name: this.langObj ? this.langObj?.Target : 'Target',
            data: analysisDataTarget,
            color: '#10ce9c',
          },
          {
            type: 'line',
            name: this.langObj ? this.langObj?.Actual : 'Actual',
            data: analysisData,
            color: 'blue',
          },
        ],
      };
      let chart = new Chart(this.options);
      let chart2 = new Chart(this.options2);
      chartObj.chartOption = chart;
      chartObj2.chartOption = chart2;
      this.chartArray.push(chartObj);
      this.chartArrayForAnalysis.push(chartObj2);
    });
  }

  onClickParamterComparsion(calledByModal) {
    debugger
    setTimeout(() => {
      var chartDatetimeEl = document.getElementsByClassName(
        'highcharts-input-group'
      )[0] as HTMLElement;
      if (!(chartDatetimeEl as HTMLElement).classList.contains('d-none')) {
        (chartDatetimeEl as HTMLElement).classList.add('d-none');
      }
    }, 800);
    const selectedRows = this.kpiWiseAnalysisReportGridApi.getSelectedRows();
    this.spinnerButtonOptions1.active = true;
    // tslint:disable-next-line:max-line-length
    if (!calledByModal) {
      this.KPIComparisonForm.get('fromDate').setValue(this.searchPlantForm.value.FromDate);
      this.KPIComparisonForm.get('toDate').setValue(this.searchPlantForm.value.ToDate);
      this.dialog.open(this.visibleParameterComparison, {
        panelClass: 'custom-dialog-container',
        width: '95vw !important',
        height: '100vw',
        minWidth: '95vw',
        maxHeight: '100vh',
      });
    }

    const seletedRowsUOm = selectedRows.map(
      (value: any) => value.UoM
    );
    this.uniqueUoms = ['ALL', ...new Set(seletedRowsUOm)];
    this.individualUom = [];
    this.individualUom = this.uniqueUoms[0];
    this.comparisonMainData();
    this.spinnerButtonOptions1.active = false;
  }

  comparisonMainData(): void {
    let values = '';
    const selectedRows = this.kpiWiseAnalysisReportGridApi.getSelectedRows();
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name'] && o.UoM == el.UoM
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'], UoM: el.UoM },
      ];
    }, []);
    const latestdate = new Date(this.searchPlantForm.value.ToDate).setHours(
      23,
      59,
      59,
      59
    );
    values = distinctRows.map((value: any) => value.KPICode).toString();
    let fromDate = true ? this.KPIComparisonForm.value.fromDate : this.searchPlantForm.value.FromDate;
    let toDate = true ? moment(this.KPIComparisonForm.value.toDate).utc() : moment(latestdate).utc();
    const obj = {
      frequencyName:
        this.searchPlantForm.value.Frequency.name,
      plantId: this.searchPlantForm.value.plant.DeptId,
      fromDate,
      toDate,
      kpiId: values,
    };
    this.spinner.show();
    this.meetingService.getStockChartData(obj).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.compData = data;
        this.changeComparisonData();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      });
  }

  changeComparisonData(): void {
    const array: any[] = [];
    const yAxisArr: any[] = [];
    const selectedRows = this.kpiWiseAnalysisReportGridApi.getSelectedRows();
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name'] && o.UoM == el.UoM
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'], UoM: el.UoM },
      ];
    }, []);
    const seletedGroups = selectedRows.map(
      (value: any) => value.ParameterGroup
    );
    const uniqueGroups = [...new Set(seletedGroups)];
    let values = '';
    let valueNames = [];
    const matchingKPINames = [];
    const latestdate = new Date(this.searchPlantForm.value.ToDate).setHours(
      23,
      59,
      59,
      59
    );
    distinctRows.forEach(item => {
      if (item.UoM === this.individualUom) {
        matchingKPINames.push(item['KPI Name']);
      }
    });
    if (this.individualUom == 'ALL') {
      values = distinctRows.map((value: any) => value.KPICode).toString();
      valueNames = distinctRows.map((value: any) => value['KPI Name']);
      for (let i = 0; i < valueNames.length; i++) {
        const dataArr = [];
        for (let j = 0; j < this.compData.length; j++) {
          if (valueNames[i] === this.compData[j].KPIName) {
            dataArr.push({
              x: new Date(this.compData[j].Date).getTime(),
              y: this.compData[j].Target ? parseFloat(this.compData[j].Target) : 0,
              label: valueNames[i],
            });
          }
        }
        let negativeArr = [];
        negativeArr = dataArr.map((c) => c.y);
        const obj = {
          tooltip: {
            valueDecimals: 2,
          },
          name: valueNames[i],
          type: 'line',
          data: dataArr,
          color: this.getRandomHexColor(),
          yAxis: i,
        };
        array.push(obj);
        yAxisArr.push({
          lineWidth: 1,
          visibe: true,
          opposite: false,
          title: {
            text: valueNames[i],
            style: {
              color: this.highChartsColor[i],
            },
          },
          labels: {
            style: {
              color: this.highChartsColor[i],
            },
          },
        });
      }
      yAxisArr.forEach((yAxis) => {
        yAxis.visible = false;
      });
      array.forEach(element => {
        element.yAxis = 0
      });
      this.stock = new StockChart({
        chart: {
          height: 730,
        },
        plotOptions: {
          series: {
            turboThreshold: 50000000,
            events: {
              hide(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
              show(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: uniqueGroups.toString(),
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        yAxis: [
          {
            "lineWidth": 1,
            "opposite": false,
            "title": {
              "text": "",
            },
            "visible": true
          },
        ],
        series: array,
      });
    }
    else {
      const valuesNotInArray2 = distinctRows.filter(x => x.UoM == this.individualUom);
      values = valuesNotInArray2.map((value: any) => value.KPICode).toString();
      valueNames = matchingKPINames;

      const filteredArr = this.compData.filter(obj => obj.UOM === this.individualUom);
      for (let i = 0; i < valueNames.length; i++) {
        const dataArr = [];
        for (let j = 0; j < filteredArr.length; j++) {
          if (valueNames[i] === filteredArr[j].KPIName) {
            dataArr.push({
              x: new Date(filteredArr[j].Date).getTime(),
              y: filteredArr[j].Target ? parseFloat(filteredArr[j].Target) : 0,
              label: valueNames[i],
            });
          }
        }
        let negativeArr = [];
        negativeArr = dataArr.map((c) => c.y);
        const obj = {
          tooltip: {
            valueDecimals: 2,
          },
          name: valueNames[i],
          type: 'line',
          data: dataArr,
          color: this.getRandomHexColor(),
          yAxis: i,
        };
        array.push(obj);
        yAxisArr.push({
          lineWidth: 1,
          visibe: true,
          opposite: false,
          title: {
            text: valueNames[i],
            style: {
              color: this.highChartsColor[i],
            },
          },
          labels: {
            style: {
              color: this.highChartsColor[i],
            },
          },
        });
      }
      yAxisArr.forEach((yAxis) => {
        yAxis.visible = false;
      });
      array.forEach(element => {
        element.yAxis = 0
      });
      this.stock = new StockChart({
        chart: {
          height: 730,
        },
        plotOptions: {
          series: {
            turboThreshold: 50000000,
            events: {
              hide(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
              show(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: uniqueGroups.toString(),
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        yAxis: [
          {
            "lineWidth": 1,
            "opposite": false,
            "title": {
              "text": "",
            },
            "visible": true
          },
        ],
        series: array,
      });
    }
  }

  getRandomHexColor(): string {
    const max = 0xffffff;
    const rand = Math.floor(Math.random() * max).toString(16);
    const zeros = Array.from({ length: 6 - rand.length }, () => 0).join('');
    return '#' + zeros + rand;
  }

  dateChangeFromModelComp(obj) {
    if (obj.target._model.selection.start && obj.target._model.selection.end) {
      this.KPIComparisonForm.get('fromDate').setValue(obj.target._model.selection.start);
      this.KPIComparisonForm.get('toDate').setValue(obj.target._model.selection.end);
      this.comparisonMainData();
    }
  }

  GetRawMaterialParameters(element: any): void {
    this.individualUom = [];
    this.individualUom = element;
    this.changeComparisonData();
  }


  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  onSelectionChanged(params: any) {

    let filterArray = params?.api?.rowModel?.rowsToDisplay.filter((node: any) => node.isSelected());

    this.KPIIdsArrayRACI = [];
    filterArray.forEach((element: any) => {
      this.KPIIdsArrayRACI?.push(element?.data?.KPIId)
    });
  }
  
  KPIRACIInformation(params:any): void {
    if (params?.length > 0) {
      const key = {
        kpiIds: params?.toString()
      }
      this.kpiMaster.KPIRACIInformation(key).subscribe((res: any) => {
        this.RACIRowData = res?.data;
        console.log(this.RACIRowData, 'list');
        this.openRACIModal();
      },
      (error: any) => {
        this.toaster.error('Error');
      });
    } else {
      this.toaster.warning('Warning', 'There is no selected Row');
    }

  }
  
  openRACIModal(): void {
    let dialog = this.dialog.open(this.RACIdataModel, {
      width: '70vw !important',
      height: '60vh',
      minWidth: '70vw',
      maxHeight: '60vh',
    });
  }

  RACIOnGridReady(params: any): void {
    this.RACIGridApi = params.api;
    this.RACIGridColumnApi = params.columnApi;
    this.RACIGridApi.sizeColumnsToFit();
    this.RACIGridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#rciGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }

  getContextMenuItems = (params: any) => {
    let result = [];
    result = [
      {
        // custom item
        name: 'RACI Information',
        action: () => {
          this.KPIRACIInformation([params?.node.data.KPIId]);
        }
      },
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'export',
      // {
      //   name: 'Copy',
      // },
      // {
      //   name: 'Copy with Headers',
      // },
      // {
      //   name: 'Paste',
      // },
      // {
      //   name: 'Export',
      // },
    ];
    return result;
  }

  getRCAInfo() : void {
    // let array = tis.kp
   this.KPIRACIInformation(this.KPIIdsArrayRACI);
  }
}
