import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BOSReportingComponent } from './bos-reporting/bos-reporting.component';
import { IncidentReportingComponent } from './incident-reporting/incident-reporting.component';
import { SafetyTrendsComponent } from './safety-trends/safety-trends.component';
const routes: Routes = [
  { path: 'BOSReporting', component: BOSReportingComponent },
  { path: 'IncidentReporting', component: IncidentReportingComponent },
  { path: 'SafetyTrends', component: SafetyTrendsComponent },
];
@NgModule({
imports: [
RouterModule.forChild(routes)
],
exports: [
RouterModule
]
})
export class SafetyRoutingModule {}
