import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerManagementService } from '../services/customer-management.service';
import { ToastrService } from 'ngx-toastr';
import { constants } from 'os';

@Component({
  selector: 'app-customer-complaint',
  templateUrl: './customer-complaint.component.html',
  styleUrls: ['./customer-complaint.component.scss']
})
export class CustomerComplaintComponent implements OnInit {
  leftBannerImgurl = 'assets/images/login-banner.png';

  LoginForm!: FormGroup;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private customerService: CustomerManagementService
  ) { }

  ngOnInit(): any {
    this.LoginForm = this.fb.group({
      email: [''],
      password: [''],
    });
  }


  Login(): void {
    const data = {
      email: this.LoginForm.get('email')?.value,
      password: this.LoginForm.get('password')?.value,
    };

    this.customerService.customerLogin(data).subscribe((res: any) => {
      localStorage.setItem('ccrUser', JSON.stringify(res));
      console.log(res);
      localStorage.setItem('isAuth', 'true');
      this.router.navigate(['/CustomerComplaint/CCPortal']);
    }, (error: any) => {
      localStorage.setItem('isAuth', 'false');
      this.toaster.error('Error', error.error.message);
    });

  }
}
