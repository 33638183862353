import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
  PlantGroupElement,
} from 'src/app/models/O3AdministrationModels/plant-model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MachineMappingService } from '../../../services/machinemapping.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { DefectService } from 'src/app/services/defect.service';
import { CheckboxRenderer } from 'src/app/CheckboxRenderer';
import { BtnCellRendererQxMatrix } from 'src/app/btn-cell-rendererQxMatrix';
import { Router } from '@angular/router';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BtnDeleteCellRenderer } from 'src/app/btn-deletecell-renderer';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-qx-matrix',
  templateUrl: './qx-matrix.component.html',
  styleUrls: ['./qx-matrix.component.scss'],
})
export class QxMatrixComponent implements OnInit {
  fileSave!: any;
  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },

    //  paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      checkboxRenderer: CheckboxRenderer,
      btnCellRendererQxMatrix: BtnCellRendererQxMatrix,
      btnDeleteCellRenderer: BtnDeleteCellRenderer,
      // btnCellRendererActualEntry: BtnCellRendererActualEntry,
      // btnCellRenderer: BtnCellRenderer,
    },
  };
  gridOptionsAction: any = {
    defaultColDef: {
      resizable: true,
      filter: true,
      // headerCheckboxSelection: this.isFirstColumn,
      // checkboxSelection: this.isFirstColumn,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    rowSelection: 'multiple',
    //  paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      checkboxRenderer: CheckboxRenderer,
      btnCellRendererQxMatrix: BtnCellRendererQxMatrix,
      btnDeleteCellRenderer: BtnDeleteCellRenderer,
      // btnCellRendererActualEntry: BtnCellRendererActualEntry,
      // btnCellRenderer: BtnCellRenderer,
    },
  };
  LineNode: any;
  varId!: any;
  defectId!: any;
  groupList!: any;
  plantList!: any;
  parameterType!: any;
  productList: any = [];
  showButton = false;
  unitComponent: any = [];
  componentList: any = [];
  disableDlt = false;
  unitlist: any = [];
  IsNotScroll = true;
  prodId!: any;
  distinctarray: any = [];
  ComponentName!: any;
  isPlantGroupModelVisible = false;
  formMode!: boolean;
  excelRowData!: any;
  displayUserModal = false;
  columnDefs: any;
  deleteMachineCheck = true;
  parameterArr: any = [];
  check: any = [];
  columnDefs1: any;
  ButtonType!: any;
  defaultColDef: any;
  rowData: any;
  showPlants!: boolean;
  showGroups!: boolean;
  productId!: any;
  parameterList!: any;
  ComponentId!: any;
  filteredRows!: any;
  filteredRowsData: any = [];
  prodList: any = [];
  showAction = true;
  qxmatrixDataForm!: FormGroup;
  machineDataForm!: FormGroup;
  rowData1: any = [];
  unitId: any;
  varUnitName!: string;
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  fileColumns: string[] = ['Action', 'defect', 'unit', 'component'];
  fileData!: MatTableDataSource<any>;
  filteredProduct: any;
  filteredParameteList: any;
  gridApi: any;
  gridColumnApi: any;
  gridApiAction: any;
  gridColumnApiAction: any;
  moduleId!: any;
  pageId!:any;
  lang: any;
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private userService: UserManagementService,
    private defectService: DefectService,
    private enterpriseGroupService: EnterPriseGroupService,
    private kpiMaster: KpimasterService,
    private machineService: MachineMappingService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
  ) {
    this.columnDefs1 = [
      {
        field: 'Delete',
        filter: true,
        hide: true,
        // (params: any) =>
        //   params.data.defectPMId === null ||
        //   params.data.defectPMId === undefined ||
        //   this.IsWrite,
        headerName: 'Delete',
        cellRenderer: 'l',
        // cellRendererParams: {
        //   onClick: this.openModal.bind(this),
        // },
        minWidth: 150,
        
      },
      {
        field: 'Action',
        minWidth: 50,
        headerName: 'Action',
        // cellRenderer: 'checkboxRenderer',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        
        cellRenderer: (params: any) => {
          if (params.data.Action) {
            //  if (this.IsNotScroll)
            //  {
            params.node.setSelected(true);
            //  }
            // return `<input type='checkbox' ${params.value ? 'checked' : ''} />`;
          }
        },
      },
      // tslint:disable-next-line:max-line-length
      {
        field: 'Product',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        
      },
      { field: 'ParameterType', wrapText: true, resizable: true,  },
      { field: 'Parameter', wrapText: true, resizable: true,  },
      { field: 'LRL', headerName: 'LRL',  },
      { field: 'LSL', headerName: 'LSL',  },
      { field: 'LWL',  },
      { field: 'TGT',  },
      { field: 'UWL',  },
      { field: 'USL', headerName: 'USL',  },
      { field: 'URL', headerName: 'URL',  },
      { field: 'defectPMId', hide: true,  },
      { field: 'productId', hide: true,  },
      { field: 'parameterId', hide: true,  },
      { field: 'psId', hide: true,  },
    ];

    this.formMode = true;
    this.columnDefs = [
      {
        field: 'Action',
        cellRenderer: 'btnCellRendererQxMatrix',
        editable: false,
        hide: this.IsWrite,
        cellRendererParams: {
          clicked(field: any) { },
        },
        sortable: true,
        filter: true,
        resizable: true,
        width: 250,
        wrapText: true,
        
      },
      {
        field: 'defectmode',
        headerName: 'Failure Mode Detail',
        editable: true,
        cellStyle: { color: 'black', 'background-color': '#bdccdd' },
        
      },
      { field: 'department', headerName: 'Department',  },
      { field: 'Line', headerName: 'Line',  },
      { field: 'Unit', headerName: 'Unit',  },
      { field: 'assembly', headerName: 'Assembly',  },
      { field: 'subassembly', headerName: 'Sub-Assembly',  },
      { field: 'component', headerName: 'Component',  },
    ];

    this.qxmatrixDataForm = this.fb.group({
      group: ['', Validators.required],
      defect: ['', Validators.required],
      component: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.machineDataForm = this.fb.group({
      product: ['', Validators.required],
      parameter: ['', Validators.required],
      Param: ['', Validators.required],

    });
  }
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('displayQxuploadRoleModal')
  private displayQxuploadRoleModal!: TemplateRef<any>;
  ngOnInit(): void {
    this.showButton = false;
    this.datasource();
    this.GetMenuAccess();
    this.GetBreadcrumList();
  }
  // breadCrumb

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
 
//menu and breadcrumb
GetBreadcrumList(): any {
  const obj = {
    MenuId:
      localStorage.getItem('O3RightMenuId') === null
        ? 1
        : localStorage.getItem('O3RightMenuId'),
  };
  // tslint:disable-next-line: align
  this.userService.GetBreadcrumList(obj).subscribe(
    (data: any) => {
      this.breadcrumList = data.BreadcrumList;
      this.moduleId = this.breadcrumList[0].id;
      this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
    },
    (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    }
  );
}

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReadyAction(params: any): any {
    this.gridApiAction = params.api;
    // this.gridApiAction.forEachNode((node: any) =>  node.setSelected(true));
    // params?.api?.rowModel?.rowsToDisplay.filter((node: any) => node.setSelected(true));
    this.gridColumnApiAction = params.columnApi;
  }
  // onRowDataChanged(): void {
  //   // this.gridOptions.api.forEachNode((node: any)  =>  node.setSelected(true));
  // }
  // onBodyScroll(): any
  // {
  //   return false;
  // }
  opensideModal(): void {
    this.formMode = true;
    this.displayUserModal = true;
  }
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;
  closeaddNewPlantItemModel(): void {
    this.displayUserModal = false;
  }

  addProducts(event: any): void {
    this.prodId = event.value;
    this.machineParameterGroup();
  }
  onclickReset(): void {
    this.qxmatrixDataForm.get('defect')?.reset();
    this.qxmatrixDataForm.get('component')?.reset();
  }
  openModal(): void {
    this.prodList = [];
    this.prodList.length = 0;
    this.parameterList = [];
    this.parameterList = 0;
    this.machineDataForm.reset();
    this.prodId = 0;
    this.getAllDefectReports();
    this.dialog.open(this.displayRoleModal, { height: '85%', width: '90%' });
  }
  openQxuploadModal(): void {
    const data: any = [];
    this.fileData = new MatTableDataSource<any>(data);
    this.dialog.open(this.displayQxuploadRoleModal, {
      height: 'auto',
      width: '90%',
    });
  }
  getAllDefectReports(): void {
    this.spinner.show();
    const key = {
      defectId: this.defectId,
    };

    this.defectService.getAllDefectsReports(key).subscribe(
      (data: any) => {
        this.rowData1 = data;
        this.spinner.hide();

      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  addFile(event: any): void {
    const formData = new FormData();
    formData.append('file', this.fileSave);

    this.spinner.show();
    this.defectService.addDefectFile(formData).subscribe(
      (data) => {
        this.fileData = new MatTableDataSource<any>(data);
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }
  uploadedFile(event: any): void {
    if (event.target.files.length > 0) {
      this.fileSave = event.target.files[0];
    }
  }
  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }
  SelectVarUnitName(unitname: any): any {
    if (unitname.title === 'UNT') {
      this.varUnitName = unitname.name;
      this.isPlantGroupModelVisible = false;
    }
  }

  showDefects(): void {
    this.spinner.show();
    const key = {
      ptId: this.qxmatrixDataForm.value.plant,
    };

    this.defectService.getDefects(key).subscribe(
      (data: any) => {
        this.rowData = data;
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  getProductsData(): void {
    const key = {
      unitId: this.unitId,
    };
    this.defectService.getProducts(key).subscribe(
      (data: any) => {
        this.productList = data;
        this.filteredProduct = this.productList.slice();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  highlight(data: any): void {
    this.excelRowData = data;
  }

  deleteExcel(event: any): void {
    const data = this.fileData.filteredData;
    setTimeout(() => {
      for (let x = 0; x < data.length; x++) {
        // tslint:disable-next-line:max-line-length
        if (
          (data[x].unitId === this.excelRowData.unitId &&
            data[x].Defect === this.excelRowData.Defect) ||
          (data[x].componentId === this.excelRowData.componentId &&
            data[x].Defect === this.excelRowData.Defect)
        ) {
          data.splice(x, 1);
          this.fileData = new MatTableDataSource<any>(data);
        }
      }
    }, 10);
  }

  getParameterGroup(): void {
    const key = {
      puId: this.unitId,
      vgTypeId: this.machineDataForm.value.Param
    };

    this.defectService.getParameters(key).subscribe(
      (data: any) => {
        this.parameterList = data;
        this.filteredParameteList = this.parameterList.slice();
        this.parameterType = 'Process Parameter';
        this.machineDataForm.get('parameter')?.setValue(data ? data[0].vgId : "");
        this.machineDataForm.get('product')?.setValue('0');
        this.machineParameterGroup();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getQualityParameterGroup(): void {
    const key = {
      puId: this.unitId,
    };

    this.defectService.getQualityParameters(key).subscribe(
      (data: any) => {
        this.parameterList = data;
        this.filteredParameteList = this.parameterList.slice();
        this.parameterType = 'Quality Parameter';
        this.machineDataForm.get('parameter')?.setValue(data ? data[0].vgId : "");
        this.machineDataForm.get('product')?.setValue('0');
        this.machineParameterGroup();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  machineCellClicked(event: any): void {
    const col = event.colDef.field;
    const check = event.data.Action;

    if (col === 'Action') {
      if (
        check === false &&
        event.data.productId !== null &&
        event.data.defectPMId !== null
      ) {
        const key = {
          type: false,
          defectPMId: event.data.defectPMId,
        };
        // tslint:disable-next-line: no-unused-expression
        this.defectService.DeleteDefectProducts(key).subscribe(),
          (error: any) => {
            this.toaster.error('Error', error.error.message);
          };
      }
    }

    if (col === 'Delete') {
      if (
        event.data.defectPMId !== undefined &&
        event.data.defectPMId !== null
      ) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to delete Defect Parameter!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
        }).then((result) => {
          if (result.value) {
            const key = {
              // type: false,
              defectPMId: event.data.defectPMId,
            };

            this.defectService.DeleteDefectProducts(key).subscribe(
              (success) => {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'Your selected Defect Parameter has been deleted.',
                  icon: 'success',
                  timer: 800,
                  showConfirmButton: false,
                });
                // this.machineParameterGroup();
                this.getAllDefectReports();
              },
              (error: any) => {
                this.toaster.error('Error', error.error.message);
              }
            );
          }
        });
      } else {
        this.toaster.warning('Only Checked Data Can Be Deleted');
      }
    }
  }

  machineParameterGroup(): void {
    this.disableDlt = false;
    const paramType = this.parameterType === 'Process Parameter' ? 0 : 1;
    const key = {
      prodId: this.prodId === null ? 0 : this.prodId,
      vgId: this.machineDataForm.value.parameter,
      defectId: this.defectId,
      parameterType: paramType,
      ptId: this.qxmatrixDataForm.value.plant,
    };
    this.showButton = true;

    if (key.vgId && key.defectId) {
      this.spinner.show();
      this.defectService.machineParameters(key).subscribe(
        (data: any) => {
          this.showAction = false;
          this.rowData1 = data;

          this.deleteMachineCheck = false;
          this.spinner.hide();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
          this.spinner.hide();
        }
      );
    }
  }
  cellClicked(params: any): void {
    const col = params.colDef.field;
    this.defectId = params.data.DefectId;
    // if (col === 'machineparameter') {

    //     this.parameterArr = [];
    //     this.parameterArr.length = 0;
    this.ButtonType = '';
    this.unitId = params.data.UnitId;
    this.defectId = params.data.DefectId;
    this.getProductsData();
    // }
    if (col === 'Action') {
      this.ButtonType = params.event.target.textContent;
      if (this.ButtonType === 'fa-fa-align-justify') {
        this.ButtonType = '';
        this.openModal();
      }
      if (this.ButtonType === 'edit') {
        this.ButtonType = '';
        this.openModal();
      } else if (this.ButtonType === 'delete') {
        this.ButtonType = '';
        this.deleteDefect();
      }
    }
  }
  onSelectionChanged(params: any) {
    debugger;
    this.filteredRowsData = [];
    this.filteredRows = [];
    this.filteredRows = params?.api?.rowModel?.rowsToDisplay.filter((node: any) => node.isSelected());
    const unSelectedRows = params?.api?.rowModel?.rowsToDisplay.filter((node: any) => !node.isSelected());
    this.filteredRows.forEach((element: any) => {
      this.filteredRowsData.push(element.data);
    });
    if (unSelectedRows) {
      if (unSelectedRows.length === this.rowData1.length) {
        this.rowData1.forEach((elem: any) => {
          elem.Action = false;
        });
      }
    }

    this.disableDlt = false;

    this.filteredRowsData.forEach((el: any) => {
      if (el.defectPMId !== null && el.defectPMId !== undefined && this.filteredRowsData.length >= 1) {
        this.disableDlt = true;
      }
      // else{
      //   this.disableDlt = false;
      // }
    });
    //   if(params.defectPMId !== null && params.defectPMId !== undefined &&  this.filteredRows.length >=1){
    //    }
    // else
    // this.disableDlt = false;
  }
  defectModal(): void { }

  deleteDefect(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Defect!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          defectId: this.defectId,
        };
        this.defectService.deleteDefect(key).subscribe(
          (success) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your selected Defect has been deleted.',
              icon: 'success',
              timer: 800,
              showConfirmButton: false,
            });
            this.showDefects();
          },
          (error: any) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      }
    });
  }
  isFirstColumn(params: any, event: any) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    // const checked = event.target.checked;
    // const colId = params.column.colId;
    // params.node.setDataValue(colId, thisIsFirstColumn, checked);
    return thisIsFirstColumn;
  }
  onCellChanged(params: any): void {
    const col = params.colDef.field;
  }

  resetMachineParameter(): void {
    this.rowData1 = [];
    this.rowData1.length = 0;
    this.parameterArr = [];
    this.parameterArr.length = 0;
  }


  deleteMultiPoints(): void {
    const selectedRows = this.gridApi.getSelectedRows();
    debugger
    const array: any[] = [];
    this.filteredRowsData = [];
    this.filteredRows.forEach((element: any) => {
      console.log(element.data);
      this.filteredRowsData.push(element.data);
    });
    this.filteredRowsData.forEach((el: any) => {
      if (el.defectPMId !== null && el.defectPMId !== undefined && this.filteredRows.length>0) {
        array.push(el.defectPMId);
        const key = {
          defectPMId: array,
        };
        this.disableDlt = true;
        this.defectService.DeleteDefectProducts(key).subscribe(
          (success) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your selected Defect Parameter has been deleted.',
              icon: 'success',
              timer: 800,
              showConfirmButton: false,
            });
            // this.machineParameterGroup();
            this.getAllDefectReports();
          },
          (error: any) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      }
      else {
        Swal.fire({
          title: 'Cannot be deleted!',
          text: 'The Selected Defect Parameter must have an Id.',
          icon: 'error',
          // timer: 800,
          showConfirmButton: true,
          confirmButtonText: 'OK'
        });
      }
    });
  }


  addParameter(): void {
    debugger;
    this.productId = this.machineDataForm.value.product;
    this.parameterArr = [];
    // this.filteredRows.forEach((element: any) => {
    //   console.log(element.data);
    //   this.filteredRowsData.push(element.data);
    // });
    // const selectedRows = this.gridApiAction.getSelectedRows();
    /*selectedRows*/
    this.filteredRowsData.map((x: any) => {
      // if (x.Action === true) {
      // if (x.Product) {

      const Obj = {
        prodId: x.productId,
        pmId: x.parameterId,
        psId: x.psId,
        userId: JSON.parse(localStorage.user).UserId,
      };
      this.parameterArr.push(Obj);

      // array =this.parameterArr.reduce((unique :any,item :any)=> unique.includes(item) ? unique : [...unique , item], []);

      // }
      // else if (this.prodList.length >= 1) {
      //   const products = this.prodList[this.prodList.length - 1];
      //   products.map((prod: any) => {
      //     const Obj = {
      //       prodId: prod,
      //       pmId: x.parameterId,
      //       psId: x.psId,
      //       userId: JSON.parse(localStorage.user).UserId,
      //     }
      //     this.parameterArr.push(Obj);
      //   })
      // }
      // }
    });
    const result = [];
    const map = new Map();
    for (const item of this.parameterArr) {
      if (!map.has(item.psId)) {
        map.set(item.psId, true); // set any value to Map
        result.push({
          psId: item.psId,
          pmId: item.pmId,
          prodId: item.prodId,
          userId: item.userId,
        });
      }
    }
    // this.distinctarray = [...new Set(this.parameterArr)];
    if (this.parameterArr.length > 0) {
      const paramType = this.parameterType === 'Process Parameter' ? 0 : 1;
      const key = {
        prodId: this.machineDataForm.value.product,
        defectId: this.defectId,
        vgId: this.machineDataForm.value.parameter,
        parameters: result,
        parameterType: paramType,
      };
      this.spinner.show();
      this.defectService.addMachineParameters(key).subscribe(
        (data: any) => {
          this.toaster.success('success', data.message);
          this.spinner.hide();
          this.dialog.closeAll();
          this.parameterArr = [];
          this.parameterArr.length = 0;
          // this.machineDataForm.reset();
          // this.resetMachineParameter();
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
          this.spinner.hide();
        }
      );
    } else {
      this.toaster.error('First select to add data');
    }
  }

  onCellValueChanged(params: any): void {
    const col = params.colDef.field;
    this.IsNotScroll = false;
    if (col === 'defectmode') {
      const key = {
        defectId: params.data.DefectId,
        defectName: params.data.defectmode,
      };

      this.defectService.updateDefects(key).subscribe(
        (data: any) => {
          this.toaster.success('Name Successfully Added');
          this.showDefects();
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  resetTreeData(): void {
    this.qxmatrixDataForm.get('component')?.setValue('');
    this.componentList = [];
    this.unitlist = [];
  }

  selectParameters(event: any): void {
    this.rowData1 = [];
    this.rowData1.length = 0;
    this.disableDlt = false;
    if (event.value === 1) {
      this.getParameterGroup();
    } else if (event.value === 2) {
      // this.getQualityParameterGroup();
    }
  }

  onSubmit(): void {
    const key = {
      components: this.componentList,
      unit: this.unitlist,
      plantId: this.qxmatrixDataForm.value.plant,
      name: this.qxmatrixDataForm.value.defect,
    };
    this.spinner.show();

    if (key.components && key.name) {
      this.defectService.addDefects(key).subscribe(
        (data: any) => {
          this.toaster.success('Qx Matrix added successfuly');
          this.spinner.hide();
          this.resetField();
          this.resetTreeData();
          this.showDefects();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
          this.spinner.hide();
        }
      );
    } else {
      this.toaster.warning('Please Enter Name And Unit');
      this.spinner.hide();
    }
  }

  datasource(): void {
    const key = {
      Id: 0,
    };

    // tslint:disable-next-line: no-debugger
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        this.qxmatrixDataForm.get('group')?.setValue(data.masterList[0].TypeId);
        this.showGroups = this.groupList.length < 1 ? false : true;
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(): void {
    const key = {
      GroupId: this.qxmatrixDataForm.value.group,
    };

    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plantList = data.DepartList;
        this.qxmatrixDataForm.get('plant')?.setValue(data.DepartList[0].DeptId);
        this.showPlants = this.plantList.length < 1 ? false : true;

        this.showDefects();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  resetField(): void {
    this.qxmatrixDataForm.get('defect')?.setValue('');
  }
  resetComponent(): void {
    this.unitComponent = [];
    this.unitComponent.length = 0;
    this.qxmatrixDataForm.get('component')?.setValue(this.unitComponent);
  }

  openUnit(): any {
    this.resetTreeData();
    this.qxmatrixDataForm.get('component')?.setValue('');
    this.resetComponent();
    this.isPlantGroupModelVisible = true;
    const key = {
      EGId: this.qxmatrixDataForm.value.group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        // this.getStatus();   // new
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }

  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  SelectUnitName(unitname: any): void {
    if (unitname.title === 'Component') {
      this.ComponentName = unitname.name;
      this.isPlantGroupModelVisible = false;
    }
  }

  onNodeSelect(node: any, event: any): void {
    if (event.target.checked === true) {
      this.unitComponent.push(node.text);
      this.unitComponent.toString();
      this.qxmatrixDataForm.get('component')?.setValue(this.unitComponent);

      if (node.Level === 'Component') {
        this.componentList.push(node.PrimaryId);
        this.ComponentId = node.PrimaryId;
      } else if (node.Level === 'Unit') {
        this.unitlist.push(node.PrimaryId);
      }
    } else if (event.target.checked === false) {
      for (let i = 0; i < this.unitComponent.length; i++) {
        if (this.unitComponent[i] === node.text) {
          this.unitComponent.splice(i, 1);
          this.unitComponent.toString();
          this.qxmatrixDataForm.get('component')?.setValue(this.unitComponent);
        }
      }

      if (node.Level === 'Component') {
        for (let i = 0; i < this.componentList.length; i++) {
          if (this.componentList[i] === node.PrimaryId) {
            this.componentList.splice(i, 1);
          }
        }

        this.ComponentId = node.PrimaryId;
      } else if (node.Level === 'Unit') {
        for (let i = 0; i < this.unitlist.length; i++) {
          if (this.unitlist[i] === node.PrimaryId) {
            this.unitlist.splice(i, 1);
          }
        }
      }
    }
  }
  excelExport(): void {
    const params = {
      fileName: 'Qx Matrix ' + new Date(),
    };
    this.gridOptions.api.exportDataAsExcel(params);
    // this.gridApi.exportDataAsExcel();
  }
  csvExport(): void {
    const params = {
      fileName: 'Qx Matrix ' + new Date(),
    };
    this.gridOptions.api.exportDataAsCsv(params);
  }
}
