import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Material Imports
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTreeModule } from '@angular/material/tree';
import { MaterialModule } from 'src/app/material-module';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatTooltipModule } from '@angular/material/tooltip';

//Ngx Imports
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMatSelectModule } from './../../ngx-mat-select/ngx-mat-select.module';
import { PreventiveMaintenanceRoutingModule } from './preventive-maintenance-route.module';
import { PrevTaskFormComponent } from './prev-task-form/prev-task-form.component';
import { PrevTaskDialogComponent } from './prev-task-dialog/prev-task-dialog.component';
import { ScheduleTaskComponent } from './schedule-task/schedule-task.component';
import { PreventiveMaintananceConfigurationComponent } from './../../O3AdministrationComponents/ManufacturingAdminComponents/preventive-maintanance-configuration/preventive-maintanance-configuration.component';
import { PreventiveDialogComponent } from './../../O3AdministrationComponents/ManufacturingAdminComponents/preventive-maintanance-configuration/preventive-dialog/preventive-dialog.component';
import { ScheduleTaskDialogComponent } from './schedule-task/schedule-task-dialog/schedule-task-dialog.component';
import { SafetyModule } from '../../safety/safety.module';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { BtnRenderer } from './../../btn-renderer.component';
import { BtnRendererActualEntry } from './../../btn-rendererActualEntry.component copy';

@NgModule({
  declarations: [PrevTaskFormComponent, PrevTaskDialogComponent, ScheduleTaskComponent, PreventiveMaintananceConfigurationComponent, PreventiveDialogComponent, ScheduleTaskDialogComponent,],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true,
    }),
    PreventiveMaintenanceRoutingModule,
    FormsModule,
    ReactiveFormsModule,

    //Material Imports
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MaterialModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatTimepickerModule,
    MatTooltipModule,
    //Ngx Imports
    NgxSpinnerModule,
    NgxMaterialTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    SafetyModule,
    AgGridModule.withComponents([BtnRenderer, BtnRendererActualEntry]),
    NgxMatSelectModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PreventiveMaintenanceModule { }
