import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CilDashboardComponent } from 'src/app/CIL/cil-dashboard/cil-dashboard.component';
import { FiveSDashboardComponent } from 'src/app/CIL/five-s-dashboard/five-s-dashboard.component';
import { NewCilDashboardComponent } from 'src/app/CIL/new-cil-dashboard/new-cil-dashboard.component';
import { NewDefectDashboardComponent } from 'src/app/CIL/new-defect-dashboard/new-defect-dashboard.component';
import { DefectDashboardComponent } from 'src/app/DMS/defect-dashboard/defect-dashboard.component';
import { AuthGuard } from 'src/app/services/auth.guard';
import { DashboardComponent } from './dashboard.component';
import { MaintenanceBoardComponent } from './maintenance-board/maintenance-board.component';
import { OeeDashboardComponent } from './oee-dashboard/oee-dashboard.component';
import { ProductionQualityDashboardComponent } from './production-quality-dashboard/production-quality-dashboard.component';
import { DynamicDashboardComponent } from './dynamic-dashboard/dynamic-dashboard.component';
import { QuotidianDashboardComponent } from './quotidian-dashboard/quotidian-dashboard.component';

const routes: Routes = [
      {path: '', component: DashboardComponent , canActivate: [AuthGuard] },
      {path: 'Dashboard', component: DashboardComponent  , canActivate: [AuthGuard]},
      { path: 'myDashboard', component: DynamicDashboardComponent, canActivate: [AuthGuard] },
      {path: 'Maintenance', component: MaintenanceBoardComponent },
      {path: 'CIL',component : CilDashboardComponent},
      {path: 'Defects',component : DefectDashboardComponent},
      {path: 'OEE', component: OeeDashboardComponent },
      {path: 'ProductionQuality', component: ProductionQualityDashboardComponent },
      {path: 'quotidianOEE', component: QuotidianDashboardComponent },
      {path: 'NewCILDashboard', component: NewCilDashboardComponent },
      {path: 'NewDefectDashboard', component: NewDefectDashboardComponent },
      {path: 'Five-S-Dashboard', component: FiveSDashboardComponent },
    ];
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardRoutingModule {}
