import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TopicService {
  kpiBaseUrl: string = environment.O3CoreApiUrl;
  baseUrl : string = environment.O3CoreApiUrl;
constructor(private http: HttpClient) { }
getPlantsData(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Common/GetDepartments', data);
}
getMasterProject(data:any):Observable<any[]>{

  return this.http.post<any[]>(this.kpiBaseUrl +'masterproject' ,data)
}
addTopic(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.kpiBaseUrl + 'addtopic', data );
}
deleteTopic(data: any): Observable<any> {
  return this.http.post<any>(this.kpiBaseUrl + 'deletetopic', data );
}
getMasterTopic(data:any):Observable<any[]>{
  return this.http.post<any[]>(this.kpiBaseUrl +'mastertopic' ,data)
}
GetOneMasterTopic(data: any): Observable<any> {
  return this.http.post<any>(this.kpiBaseUrl + 'topic', data );
}
updateTopic(data: any): Observable <any[]> {;
  return this.http.put<any[]>(this.kpiBaseUrl + 'topic', data );
}
}
