import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  // NgxMatDateAdapter,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TreeModule } from 'primeng/tree';
import { DropdownModule } from 'primeng/dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HomeRoutingModule } from './home-routing.module';
import { MaterialModule } from 'src/app/material-module';
import { HomeComponent } from './home/home.component';
import { PlantModelComponent } from './home/plant-model/plant-model.component';
import { OeeConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/oee-configuration/oee-configuration.component';
import { OrderProcessingComponent } from '../ProductionOrderComponents/order-processing/order-processing.component';
import { ShopFloorDataComponent } from '../ProductionOrderComponents/shop-floor-data/shop-floor-data.component';
import { SfdcConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/sfdc-configuration/sfdc-configuration.component';
import { MachineboardComponent } from './analytics/machineboard/machineboard.component';
import { MachineParametersComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/machine-parameters/machine-parameters.component';
import { CrewSchedulingComponent } from '../O3AdministrationComponents/GeneralSettingComponents/crew-scheduling/crew-scheduling.component';
import { UseraccessComponent } from './home/useraccess/useraccess.component';
import { AvailabilityAnalysisComponent } from './availability-analysis/availability-analysis.component';
import { LandingPageComponent } from './landing-page/landing-page/landing-page.component';
import { QualityAnalysisComponent } from './quality-analysis/quality-analysis.component';
import { SafetyDbComponent } from './safety-db/safety-db.component';
import { CuAnalysisComponent } from './cu-analysis/cu-analysis.component';
import { GeneralAreaComponent } from '../General Category/general-area/general-area.component';
import { GeneralDepartmentComponent } from '../General Category/general-department/general-department.component';
import { GeneralShiftComponent } from '../General Category/general-shift/general-shift.component';
import { QualityDbComponent } from './quality-db/quality-db.component';
import { AutonomousMaintananceDbComponent } from '../O3AdministrationComponents/autonomous-maintanance-db/autonomous-maintanance-db.component';
import { ProductionDbComponent } from '../O3AdministrationComponents/production-db/production-db.component';
import { AuditDashboardComponent } from '../O3AdministrationComponents/audit-dashboard/audit-dashboard.component';
import { O3AreaChartComponent } from '../components/o3-area-chart/o3-area-chart.component';
import { O3ChartsComponent } from '../components/o3-charts/o3-charts.component';
import { ChartsModule } from 'ng2-charts';
import { LineChartComponent } from '../components/line-chart/line-chart.component';
import { PerformanceAnalysisComponent } from './performance-analysis/performance-analysis.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { O3PercentageGraphComponent } from '../components/o3-percentage-graph/o3-percentage-graph.component';
import { GeneralCategoryComponent } from '../General Category/general-category/general-category.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { AgGridModule } from 'ag-grid-angular';
import { TooltipModule } from 'primeng/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BatchReportComponent } from './batch-report/batch-report.component';
import { WholesomeFoodReportComponent } from './wholesome-food-report/wholesome-food-report.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ButtonEditRendererComponent } from '../components/button-edit-render.components';
import { ButtonDeleteRendererComponent } from '../components/button-delete-render.components';
import { ButtonSelectRendererComponent } from '../components/button-select-render.components';
import { NewSfdcComponent } from '../ProductionOrderComponents/new-sfdc/new-sfdc.component';
import { ButtonViewRendererComponent } from '../components/button-view-render.components';
import { ButtonPrintRendererComponent } from '../components/button-print-render.components';
import { EventConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/event-configuration/event-configuration/event-configuration.component';
import { BatchReportOutputComponent } from './batch-report-output/batch-report-output.component';
import { TranslateModule } from '@ngx-translate/core';
import { SfdcNewComponent } from '../ProductionOrderComponents/sfdc-new/sfdc-new.component';
import { DatePickerComponent } from '../components/date-picker.components';
import { CustomLoadingCellRenderer } from '../aggrid custom/custom-loading-cell-renderer.component';
import { LanguageMasterComponent } from './language-master/language-master.component';
import { ButtonSaveRendererComponent } from '../components/button-save-render.components';
import { PalletComponent } from './pallet/pallet.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';
import { ProductCostConfigurationComponent } from './product-cost-configuration/product-cost-configuration.component';
import { CitiesAndCountriesComponent } from '../O3AdministrationComponents/CitiesAndCountries/CitiesAndCountries.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import { MatTableExporterModule } from 'mat-table-exporter';
import { NgxPrintElementModule } from 'ngx-print-element';
// import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectModule } from 'ngx-mat-select';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AngularImageViewerModule } from 'angular-x-image-viewer';
import { GembaWalkDashboardComponent } from '../O3AdministrationComponents/gemba-walk-dashboard/gemba-walk-dashboard.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ButtonStackerRendererComponent } from '../components/button-Stacker-render.components';
import { ImmediateActionComponent } from '../quality-management/ncr-ccr-management/immediate-action/immediate-action.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { UserActionsComponent } from '../user-actions/user-actions.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { DynamicDashboardComponent } from './dashboard/dynamic-dashboard/dynamic-dashboard.component';
import * as Highcharts from 'highcharts';
// import { SlickCarouselModule } from 'ngx-slick-carousel';



export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more];
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true,
    }),
    NgxDropzoneModule,
    ChartsModule,
    ChartModule,
    NgApexchartsModule,
    HomeRoutingModule,
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    AgGridModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    TreeModule,
    DropdownModule,
    NgxSpinnerModule,
    TooltipModule,
    MatTooltipModule,
    HighchartsChartModule,
    NzDatePickerModule,
    MatCheckboxModule,
    NgxPrintElementModule,
    NgxDocViewerModule,
    AngularImageViewerModule,
    NgxMatSelectSearchModule,
    NgxMatSelectModule,
    MatSelectFilterModule,
    MatSlideToggleModule,
    MultiSelectModule,
    // tslint:disable-next-line:max-line-length
    AgGridModule.withComponents([
      ButtonEditRendererComponent,
      ButtonDeleteRendererComponent,
      ButtonSelectRendererComponent,
      ButtonViewRendererComponent,
      ButtonPrintRendererComponent,
      ButtonStackerRendererComponent,
      DatePickerComponent,
      CustomLoadingCellRenderer,
      ButtonSaveRendererComponent,
    ]),
  ],
  declarations: [
    ButtonEditRendererComponent,
    ButtonDeleteRendererComponent,
    ButtonSelectRendererComponent,
    ButtonViewRendererComponent,
    ButtonPrintRendererComponent,
    ButtonStackerRendererComponent,
    DatePickerComponent,
    ButtonSaveRendererComponent,
    HomeComponent,
    PlantModelComponent,
    OeeConfigurationComponent,
    OrderProcessingComponent,
    GeneralAreaComponent,
    GeneralDepartmentComponent,
    GeneralShiftComponent,
    GeneralCategoryComponent,
    ShopFloorDataComponent,
    SfdcConfigurationComponent,
    MachineboardComponent,
    MachineParametersComponent,
    CrewSchedulingComponent,
    AvailabilityAnalysisComponent,
    CuAnalysisComponent,
    QualityAnalysisComponent,
    UseraccessComponent,
    QualityDbComponent,
    SafetyDbComponent,
    AutonomousMaintananceDbComponent,
    ProductionDbComponent,
    AuditDashboardComponent,
    GembaWalkDashboardComponent,
    O3AreaChartComponent,
    O3ChartsComponent,
    LineChartComponent,
    PerformanceAnalysisComponent,
    O3PercentageGraphComponent,
    BatchReportComponent,
    BatchReportOutputComponent,
    WholesomeFoodReportComponent,
    NewSfdcComponent,
    LandingPageComponent,
    EventConfigurationComponent,
    SfdcNewComponent,
    CustomLoadingCellRenderer,
    LanguageMasterComponent,
    PalletComponent,
    ProductCostConfigurationComponent,
    CitiesAndCountriesComponent,
    ImmediateActionComponent,
    UserActionsComponent,
    ConfirmationDialogComponent,
    DynamicDashboardComponent
  ],
  exports: [O3PercentageGraphComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules }, // add as factory to your providers
  ],
})
export class HomeModule {}
