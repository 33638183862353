import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QualityOdsService {

  ODSApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getReportTypes(data: any): Observable<any> {
    return this.http.post<any>(this.ODSApiUrl + 'QA/GetReportsForQA', data);
  }
  getBaseDetails(data: any): Observable<any> {
    return this.http.post<any>(this.ODSApiUrl + 'MES/GetBaseDetail', data);
  }
  getThickness(data: any): Observable<any> {
    return this.http.post<any>(this.ODSApiUrl + 'QA/GetThickness', data);
  }

  getLastCommonAttribute(data: any): Observable<any> {
    return this.http.post<any>(
      this.ODSApiUrl + 'QA/GetLastEnteredCommonAttributes',
      data
    );
  }
  getDefectName(data: any){
    return this.http.post<any>(this.ODSApiUrl + 'QA/GetDefectNames', data);
  }
  getDefects(data: any){
    return this.http.post<any>(this.ODSApiUrl + 'QA/GetDefects', data);
  }
  insertCommonAttribute(data: any): Observable<any> {
    return this.http.post<any>(
      this.ODSApiUrl + 'QA/InsertCommonAttributes',
      data
    );
  }
  updateCommonAttribute(data: any): Observable<any> {
    return this.http.post<any>(this.ODSApiUrl + 'QA/UpdateCommonAttributes', data);
  }
  getCommonAttributes(data: any): Observable<any> {
    return this.http.post<any>(this.ODSApiUrl + 'QA/GetCommonAttributes', data);
  }
  deleteCommonAttribute(data: any): Observable<any> {
    return this.http.post<any>(
      this.ODSApiUrl + 'QA/DeleteCommonAttributes',
      data
    );
  }
  getQAProperties(data: any): Observable<any> {
    return this.http.post<any>(this.ODSApiUrl + 'QA/GetQAProperties', data);
  }

  getQAEntries(data: any): Observable<any> {
    return this.http.post<any>(this.ODSApiUrl + 'QA/GetQAEntries', data);
  }
  insertQAData(data: any): Observable<any> {
    return this.http.post<any>(this.ODSApiUrl + 'QA/InsertQAData', data);
  }
  updateQAData(data: any): Observable<any> {
    return this.http.post<any>(this.ODSApiUrl + 'QA/UpdateQAData', data);
  }
  deleteQAData(data: any): Observable<any> {
    return this.http.post<any>(this.ODSApiUrl + 'QA/DeleteQAEntries', data);
  }
  getQAReport(data: any): Observable<any> {
    return this.http.post<any>(this.ODSApiUrl + 'QA/GetReport', data);
  }
  GetLimitValues(): Observable<any> {
    return this.http.get<any>(this.ODSApiUrl + 'QA/GetLimitValues');
  }
}
