import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { UserManu } from 'src/app/models/userManu';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AreaService } from 'src/app/services/area.service';
import { GlobalStandardService } from 'src/app/services/global-standard.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-gloabal-standard',
  templateUrl: './gloabal-standard.component.html',
  styleUrls: ['./gloabal-standard.component.scss'],
})
export class GloabalStandardComponent implements OnInit {
  @Input() aPermissions!: UserManu;
  @Input() IsWrite: boolean;

  @ViewChild(MatTable) table!: MatTable<Pillar>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayMAchineModal = false;
  pillars!: Pillar[];
  pillarId!: number;
  plants!: Plantbygroup[];
  globalForm!: FormGroup;
  formMode!: boolean;
  plantId!: number;
  imageSrc!: any;
  hidetrue = false;
  imageSrcName!: any;

  groupId!: number;
  displayUserModal = false;
  fileSave!: any;
  plantsDDL!: any[];
  areaData!: any[];
  isUpdateGlobalStandard = true;
  auditcatColumns: string[] = [
    'ptDesc',
    'area',
    'typeName',
    'name',
    'areaActions',
  ];
  globalStandardData = new MatTableDataSource<any>([]);
  getareaMaster: any;
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private areaService: AreaService,
    private globalStandardService: GlobalStandardService,
    private machineService: MachineMappingService,
    private senitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.formMode = true;
    this.globalForm = this.fb.group({
      plant: ['', Validators.required],
      area: ['', Validators.required],
      type: ['', Validators.required],
      name: ['', Validators.required],
      uploadFile: ['', Validators.required],
      add: [''],
      globalId: [''],
      group: [''],
    });
  }

  getPaginator(): void {
    setTimeout(() => {
      this.globalStandardData.paginator = this.paginator;
      this.globalStandardData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.globalStandardData.filter = filterValue.trim().toLowerCase();
    if (this.globalStandardData.paginator) {
      this.globalStandardData.paginator.firstPage();
    }
  }

  getArea(key: any): void {
    this.areaService.getArea(key).subscribe(
      (data) => {
        this.areaData = data;
      },
      (error) => {
        this.toaster.error(error.error.message);
      }
    );
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.groupId,
    };
    this.machineService.getPlantsData(key).subscribe(
      (plants: any) => {
        this.plantsDDL = plants.DepartList;
        this.globalForm.get('plant')?.setValue(this.plantId);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getGlobalStandardMaster(key: any): void {
    this.spinner.show();
    this.groupId = key.groupId;
    this.plantId = key.plantId;
    this.globalForm.get('group')?.setValue(key.groupId);
    this.getPlantByGroup();
    this.globalStandardService.getGlobalStandard(key).subscribe(
      (data) => {
        this.globalStandardData.data = data;
        this.getPaginator();
        this.spinner.hide();
        this.globalForm.get('plant')?.setValue(key.plantId);
      },
      (error) => {
        this.toaster.error(error.error.Message);
        this.spinner.hide();
      }
    );
  }

  opensideModal(): void {
    this.hidetrue = false;
    this.getPlantByGroup();
    const plant = this.plantId;
    const key = {
      plantId: plant,
    };
    this.getArea(key);
    this.formMode = true;
    this.reset();
    this.isUpdateGlobalStandard = false;
    this.displayUserModal = true;
    this.globalForm.get('uploadFile').setValidators([Validators.required]);
    this.globalForm.get('uploadFile').updateValueAndValidity();
  }
  closeaddNewPlantItemModel(): void {
    this.displayUserModal = false;
  }

  uploadedFile(event: any): void {
    if (event.target.files.length > 0) {
      this.fileSave = event.target.files[0];
    }
  }

  saveGlobalStandard(): void {
    if (!this.isUpdateGlobalStandard) {
      const areas = this.globalForm.value.area;
      areas.forEach((element: any) => {
        const formdata = new FormData();
        formdata.append('file', this.fileSave);
        const Data = {
          name: this.globalForm.get('name')?.value,
          plant: this.globalForm.get('plant')?.value,
          typeName: this.globalForm.get('type')?.value,
          areaId: element,
        };
        formdata.append('Data', JSON.stringify(Data));
        this.globalStandardService.addGlobalStandard(formdata).subscribe(
          (response) => {
            this.toaster.success('Success', 'Added Successfully');
            this.reset();
            this.isUpdateGlobalStandard = true;
            this.closeaddNewPlantItemModel();
            const key = {
              groupId: this.groupId,
              plantId: Data.plant,
            };
            this.getGlobalStandardMaster(key);
          },
          (error) => {
            this.toaster.error('Error', error.error.Message);
          }
        );
      });
    } else {
      const formdata = new FormData();
      formdata.append('file', this.fileSave);
      const Data = {
        name: this.globalForm.get('name')?.value,
        plant: this.globalForm.get('plant')?.value,
        typeName: this.globalForm.get('type')?.value,
        areaId: this.globalForm.get('area')?.value,
        globalId: this.globalForm.get('globalId')?.value,
      };
      formdata.append('Data', JSON.stringify(Data));
      this.globalStandardService.updateGlobalStandard(formdata).subscribe(
        (response) => {
          this.toaster.success('Success', 'Updated Successfully');
          this.closeaddNewPlantItemModel();
          this.isUpdateGlobalStandard = true;
          this.reset();
          const key = {
            groupId: this.groupId,
            plantId: Data.plant,
          };
          this.getGlobalStandardMaster(key);
        },
        (error) => {
          this.toaster.error('Error', error.error.Message);
        }
      );
    }
  }
  onClickUpdateGlobalStandard(element: any): void {
    this.isUpdateGlobalStandard = true;
    setTimeout(() => {
      this.displayUserModal = true;
      this.hidetrue = true;
      this.formMode = false;
      const key = {
        plantId: element.ptId,
      };
      this.getArea(key);
      this.getPlantByGroup();
      this.globalForm.get('name')?.setValue(element.name);
      this.globalForm.get('plant')?.setValue(element.ptId);
      this.globalForm.get('area')?.setValue(element.areaId);
      this.globalForm.get('type')?.setValue(element.typeName);
      this.globalForm.get('globalId')?.setValue(element.globalId);
      this.imageSrc = environment.O3CoreApiImageUrl + element.filename;
      this.imageSrcName = element.filename;
      this.globalForm.get('uploadFile').clearValidators();
      this.globalForm.get('uploadFile').updateValueAndValidity();
    }, 500);
  }

  onClickDeleteGlobalStandard(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Global Standard!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        var id = {
          globalId: element.globalId,
        };
        this.globalStandardService.deleteGlobalStandard(id).subscribe(
          (data: any) => {
            const key = {
              plantId: element.ptId,
              groupId: this.groupId,
            };
            this.getGlobalStandardMaster(key);
            this.toaster.success(data.message);
          },
          (error: any) => {
            this.toaster.error('Error', error.error.Message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Global Standard is safe :)', 'error');
      }
    });
  }

  reset(): void {
    this.globalForm.get('area')?.setValue('');
    this.globalForm.get('type')?.setValue('');
    this.globalForm.get('name')?.setValue('');
    this.globalForm.get('uploadFile')?.setValue('');
  }

  transform(base64: any) {
    return this.senitizer.bypassSecurityTrustResourceUrl(base64);
  }
  downloadMyFile(): void {
    const link = document.createElement('a');
    link.setAttribute('type', 'download');
    link.setAttribute(
      'href',
      this.imageSrc !== undefined ? this.imageSrc : undefined || null
    );
    link.setAttribute('download', 'Gloabal.png');
    link.setAttribute('target','_blank')
    document.body.appendChild(link);
    link.click();
  }
}
