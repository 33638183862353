import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProcessCheckpointService {

  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }


  getMasterCheckPoints(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'ProcessCheckPoint/GetMasterCheckPoints', data);
  }

  getMasterCheckPointsbyAuditCategory(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'processcheckpointbycategory', data);
  }

  addCheckPoint(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ProcessCheckPoint/AddCheckPoint', data);
  }
  updateCheckPoint(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.o3CoreApiUrl + 'ProcessCheckPoint/UpdateCheckPoint', data);
  }
  deleteCheckPoint(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'ProcessCheckPoint/DeleteCheckPoint', data);
  }
}
