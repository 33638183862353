import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CostDictionary } from '../models/cost_dictionaries/cost-dictionary';
@Injectable({
  providedIn: 'root'
})
export class CostdictionaryService {  
  
  NetApiUrl = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }
  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.NetApiUrl + 'Common/GetDepartments', data);
  }
  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.NetApiUrl + 'Common/GetGroup', data);
  }
  
  // getMaxCavity(data: any): Observable<any[]> {
  //   return this.http.post<any[]>(this.baseUrl + 'maxcavity', data);
  // }

  addCostDictionary(data: any): Observable<any> {
    return this.http.post<any>(this.NetApiUrl + 'LossAnalysisCostDictionary/addLossAnalysisCostDictionary', data);
  }
  CostDictionaryFileUploding(data: any): Observable<any> {
    return this.http.post<any>(this.NetApiUrl + 'LossAnalysisCostDictionary/CostDictionaryFileUploding', data);
  }
    updateCostDictionary(data: any): Observable<any> {
    return this.http.put<any>(this.NetApiUrl + 'LossAnalysisCostDictionary/UpdateLossAnalysisCostDictionary', data);
  }
  deleteCostDictionary(data: any): Observable<any> {
    return this.http.post<any>(this.NetApiUrl + 'LossAnalysisCostDictionary/DeleteLossAnalysisCostDictionary', data);
  }
  getAllCostDictionary(data: any): Observable<CostDictionary[]> {
    return this.http.post<CostDictionary[]>(this.NetApiUrl + 'LossAnalysisCostDictionary/GetAllLossAnalysisCostDictionary', data);
  }

  GetGLAccountByCostElementId(data: any): Observable<CostDictionary[]> {
    return this.http.post<CostDictionary[]>(this.NetApiUrl + 'LossAnalysisCostDictionary/GetGLAccountByCostElementId', data);
  }

}
