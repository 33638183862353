import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProductBulkUploadService {

    baseUrl: string = environment.O3CoreApiUrl;
    o3CoreApiUrl: string = environment.O3CoreApiUrl;
    constructor(private http: HttpClient) { }

    getProductMasterData(): Observable<any[]> {
        return this.http.get<any[]>(this.o3CoreApiUrl + 'Product/GetProductMasterData');
    }

    uploadMasterData(data: any): Observable<any[]> {
        return this.http.post<any[]>(this.o3CoreApiUrl + 'Product/UploadMasterData', data);
    }

    getCilMasterData() {
        return this.http.get<any[]>(this.baseUrl + 'getCilMasterData');
    }

    uploadCilMasterData(data: any): Observable<any[]> {
      return this.http.post<any[]>(this.o3CoreApiUrl + 'cil_bulkupload/UploadCilMasterData', data);
    }

}
