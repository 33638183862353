import { NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { AnyRecord } from 'dns';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { AreaService } from 'src/app/services/area.service';
import { ChecklistService } from 'src/app/services/checklist.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { NcrService } from 'src/app/services/ncr.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QualityexecutionService } from 'src/app/services/qualityexecution.service';
import { QualityShiftService } from 'src/app/services/qualityshift.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { QualityCheckHistoryService } from 'src/app/services/quality-check-history.service';
import { GridConfiguration } from 'src/app/interface/grid-model';
import { GridService } from 'src/app/services/grid.service';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-qualitycheckexecution',
  templateUrl: './qualitycheckexecution.component.html',
  styleUrls: ['./qualitycheckexecution.component.scss'],
})
export class QualitycheckexecutionComponent implements OnInit {
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('displayRoleModalView') private displayRoleModalView!: TemplateRef<any>;
  unitsArray: any = [];
  detailPrentId!: any;
  goodImgArray: File[] = [];
  moduleId!: any;
  pageId!: any;
  disableAdd = false;
  dropdowData: any[];
  filename: string;
  currencDropdown: any[];
  gridColumnCheckListApi: any;
  loadingOverlayComponent: string;
  gridApiCheckList: any;
  gridOptionsCheckList: any = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      wrapText: true,
      filter: true,
      suppressColumnVirtualisation: true,
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      customLoadingOverlay: CustomLoadingOverlay,
    },
  };
  checkListRowData: any;
  ColumnDefsCheckList: any = [
    {
      field: 'Action',
      headerName: 'Action',
      filter: false,
      cellRenderer: (params: any): any => {
        let actionBtns;
        if (this.IsWrite) {
          actionBtns = `
          <div>
          <button mat-mini-fab="" data-toggle="tooltip" data-placement="top" title="List Execution"  color="accent" class="mat-focus-indicator mat-mini-fab mat-button-base mat-accent" ng-reflect-color="accent"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">add</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          <button mat-mini-fab="" data-toggle="tooltip" data-placement="top" title="CheckList History"  color="primary" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          </div>
          `
          return actionBtns;
        }
      },
      width: 140,
      
    },
    { field: 'listName', headerName: 'CheckList Name',  },
    { field: 'AreaName', headerName: 'Area Name',  },
    { field: 'Pu_Desc', headerName: 'Units',  },
    { field: 'listType', headerName: 'Checklist Type',  },
    { field: 'noOfExecution', headerName: 'Total No Of Executions', cellStyle: { textAlign: 'right' },  },
    {
      field: 'lastExecutionDate', headerName: 'Last Execution Date', valueFormatter: function (params) {
        var date = params.value;
        if (date) {
          return new Date(date).toLocaleString('en-GB', { hour12: false });
        } else {
          return '';
        }
      },
    },
  ];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private ChecklistService: ChecklistService,
    private QualityCheckService: QualityCheckHistoryService,
    private toaster: ToastrService,
    private qualityService: QualityShiftService,
    private userService: UserManagementService,
    private machineService: MachineMappingService,
    private AreaServices: AreaService,
    private qualityexecutionService: QualityexecutionService,
    private ncrService: NcrService,
    public router: Router,
    private enterpriseGroupService: EnterPriseGroupService,
    private notificationService: NotificationService,
    private gridService: GridService
  ) {
    this.loadingOverlayComponent = 'customLoadingOverlay';
  }
  customerManagementDataSource = new MatTableDataSource<any>([]);
  dataSourceView = new MatTableDataSource<any | GroupView>([]);
  customerManagementColumns: string[] = [
    'action',
    'listName',
    'Area',
    'Machine',
    'createdOn',
    'TotalExec',
    'LastexecDate',
  ];
  itemColumns: string[] = ['items', 'good', 'bad', 'standard', 'minimum', 'maximum', 'score', 'remarks'];
  itemColumnsView: string[] = ['items', 'good', 'bad', 'standard', 'score', 'remarks'];
  addtabledata: Array<{
    id: number;
    itemtype: number;
    drop: string;
    item: string;
    checkListDetailId: number;
    score: number;
    remarks: string;
    catType: string;
    goodImage: string;
    badImage: string;
  }> = [];
  categoryTypeDDL: any[] = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
  ];
  groupListDDL!: any[];
  plantDDL!: any[];
  listTypeDDL!: any[];
  itemTypeDDL!: any[];
  Groups!: any[];
  groupDataForm!: FormGroup;
  Plants!: any[];
  currentImagePath!: any;
  areaDDL!: any[];
  ShiftDDL!: any[];
  UnitNode: any;
  checklistDataForm!: FormGroup;
  checklistDataFormView!: FormGroup;
  checklistDatatableForm!: FormGroup;

  formMode!: boolean;
  checkListAddModel = new MatTableDataSource<any | Group>([]);
  counter = 1;
  checklistId!: number;
  updateOrderprocessingData = false;
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  isPlantGroupModelVisible = false;
  MachineId!: any;
  breadcrumList: any;
  IsAccess: any;
  shiftId!: any;
  shiftName!: any;
  shiftTime!: any;
  IsWrite: any;
  ProcessOrder!: any[];
  PreviousProcessOrder!: any[];
  _alldata!: any[];
  alldataView!: any[];
  columns!: any[];
  displayedColumns!: string[];
  groupByColumns: string[] = [];
  UnitId!: any;
  treeType = 1;
  yes = true;
  no = true;
  valueCheck!: any;
  valueCheck2!: any;
  isAuto = false;
  isAuto2 = false;
  preAuto = false;
  preAuto2 = false;
  preCheck!: any;
  preCheck2!: any;
  GoodExampleImage = false;
  BadExampleImage = false;
  executeRole!: any;
  userId!: number;
  hidetrue = false;
  varUnitName: any;
  UnitName: any;
  unitid: any;
  fileSave!: any;
  path!: string;
  imageSrc!: string;
  fileType!: string;
  doc!: any;
  images: any[] = [];
  imageIndexOne = 0;
  displayMaximizable = false;
  public enableMeridian = true;
  groupByColumnsView: string[] = [];
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('imageZoomModel2') private imageZoomModel2!: TemplateRef<any>;
  @ViewChild('imageZoomModelShow')
  private imageZoomModelShow!: TemplateRef<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren(MatPaginator) paginatorView = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sortView = new QueryList<MatSort>();
  @ViewChild(MatSort) sort!: MatSort;
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.imageSrc = environment.O3CoreApiImageUrl;
    this.GetBreadcrumList();
    this.GetMenuAccess();
    const role = JSON.parse(localStorage.roleName);
    this.executeRole = role.includes('Operator');
    this.groupDataForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      unitName: ['', Validators.required],
    });

    this.GetGroup();
    this.checklistDataForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      checktype: ['', Validators.required],
      checkname: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      area: [''],
      executionDate: ['', Validators.required],
      shift: [''],
      shiftName: [''],
      unitName: [''],
      currentProcessOrder: [''],
      currentProcessOrderId: [''],
      currentProductName: [''],
      previousProcessOrder: [''],
      previousProcessOrderId: [''],
      previousProductName: [''],
      targetOrderQuantity: [''],
      producedQuantity: [''],
    });

    this.checklistDataFormView = this.fb.group({
      group: [''],
      plant: [''],
      checktype: [''],
      Listname: [''],
      area: [''],
      executionDate: [''],
      shift: [''],
      Unit: [''],
      currentProcessOrder: [''],
      currentProductDescription: [''],
      previousProcessOrder: [''],
      previousProductDescription: [''],
      targetOrderQuantity: [''],
      producedQuantity: [''],
      con: [''],
      ver: [''],
    });
    this.checklistDatatableForm = this.fb.group({
      dropdownvalue: ['', Validators.required],
      scorevalue: ['', Validators.required],
    });
    this.columns = [
      {
        field: 'Items',
      },
      {
        field: 'Name',
      },
      {
        field: 'Remarks',
      },
      {
        field: 'Department',
      },
      {
        field: 'Description',
      },
    ];
    this.displayedColumns = this.columns.map((column) => column.field);
    this.groupByColumns = ['itemGroup'];
    this.groupByColumnsView = ['itemGroup'];
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  sendNotification(): void {
    const key = {
      roleName: 'Line Leader',
      createdBy: JSON.parse(localStorage.user).UserId,
      egId: this.checklistDataForm.value.group,
      title: 'CheckList Execution',
      desc:
        'Execution has been created in check list ' +
        this.checklistDataForm.value.checkname +
        ' by ' +
        JSON.parse(localStorage.user).UserName,
    };
    this.notificationService.addnotification(key).subscribe(
      (response: any) => { },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error: any) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  GetGroup(): void {
    const key = {
      Id: 0,
    };
    this.userService.getGroupData(key).subscribe((res: any) => {
      this.Groups = res.masterList;
      this.groupDataForm.get('group')?.setValue(res.masterList[0].TypeId);
      this.groupDataForm.get('unitName')?.reset();
      this.getPlants();
    });
  }

  getPlants(): void {
    const key = {
      GroupId: this.groupDataForm.value.group,
    };
    this.userService.getDepartments(key).subscribe((res: any) => {
      this.Plants = res.DepartList;
      this.groupDataForm.get('plant')?.setValue(this.Plants[0].DeptId);
      this.getplantUnit();
      this.groupDataForm.get('unitName')?.reset();
      this.UnitId = null;
      this.customerManagementDataSource.data = [];
      this.getAllQualityCheckList();
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridApiCheckList.setQuickFilter(filterValue);
  }

  splitTree(node: any): any {
    if (node !== undefined) {
      return node.split('-')[0];
    }
  }

  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }

  getPaginator(): void {
    setTimeout(() => {
      this.customerManagementDataSource.paginator = this.paginator;
      this.customerManagementDataSource.sort = this.sort;
    }, 1000);
  }

  groupHeaderClick(row: any): void {
    row.expanded = !row.expanded;
    this.checkListAddModel.filter = performance.now().toString(); // bug here need to fix
  }

  isGroup(index: any, item: any): boolean {
    return item.level;
  }

  getAllQualityCheckList(): void {
    this.customerManagementDataSource.data = [];
    this.spinner.show();
    const key = {
      plantId: this.groupDataForm.value.plant,
      puId: this.UnitId,
      type: "Checklist Execution"
    };
    if (this.executeRole === true) {
      this.hidetrue = true;
    } else {
      this.hidetrue = false;
    }
    this.qualityexecutionService.getCheckListExec(key).subscribe(
      (checklist: any) => {
        checklist.dropDown = this.getDropdown(checklist.CheckListDetailId);
        this.checkListRowData = checklist;
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  autoSizeAllColumns() {
    const allColumnIds = [];
    this.gridColumnCheckListApi?.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnCheckListApi.autoSizeColumns(allColumnIds);
  }

  getAllQualityCheckListByPlant(): void {
    this.customerManagementDataSource.data = [];
    this.spinner.show();
    this.groupDataForm.get('unitName')?.reset();
    const key = {
      plantId: this.groupDataForm.value.plant,
      puId: null,
    };
    if (this.executeRole === true) {
      this.hidetrue = true;
    } else {
      this.hidetrue = false;
    }
    this.qualityexecutionService.getCheckList1(key).subscribe(
      (checklist: any) => {
        this.getplantUnit();
        this.customerManagementDataSource.data = checklist;
        this.getPaginator();
        this.spinner.hide();
        this.groupDataForm.get('unitName')?.reset();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {
    return data instanceof Group ? data.visible : this.getDataRowVisible(data);
  }

  customFilterPredicateView(data: any | Group, filter: string): boolean {
    return data instanceof Group ? data.visible : this.getDataRowVisibleView(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.checkListAddModel.data.filter((row) => {
      if (!(row instanceof Group)) {
        return false;
      }
      let match = true;
      this.groupByColumns.forEach((column: any) => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  getDataRowVisibleView(data: any): boolean {
    const groupRows = this.dataSourceView.data.filter((row) => {
      if (!(row instanceof Group)) {
        return false;
      }
      let match = true;
      this.groupByColumnsView.forEach((column: any) => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });
    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(
    data: any[],
    level: number,
    groupByColumns: string[],
    parent: Group
  ): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map((row) => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );
    const currentColumn = groupByColumns[level];
    let subGroups: any[] = [];
    groups.forEach((group: any) => {
      const rowsInGroup = data.filter(
        (row) => group[currentColumn] === row[currentColumn]
      );
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(
        rowsInGroup,
        level + 1,
        groupByColumns,
        group
      );
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a: any, key: any): any {
    const seen: any = {};
    return a.filter((item: any) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  onNodeSelect(node: any): void {
    if (node.Level === 'Unit') {
      this.updateOrderprocessingData = false;
      this.MachineId = node.PrimaryId;
      this.groupDataForm.get('unitName')?.setValue(node.text);
      this.UnitId = node.PrimaryId;
      this.getAllQualityCheckList();
    }
  }

  getDateShifts(event: any): void {
    this.timeDif(event);
    if (!this.UnitId) {
      return;
    }
    const key = {
      executionDate: this.checklistDataForm.value.executionDate,
      puId: this.UnitId,
    };
    this.checklistDataForm.get('shift')?.reset();
    this.checklistDataForm.get('shiftName')?.reset();
    this.qualityexecutionService.CheckListShiftsByDate(key).subscribe(
      (response: any) => {
        if (response.length > 0) {
          this.checklistDataForm.get('shift')?.setValue(response[0].ShiftId);
          this.checklistDataForm.get('shiftName')?.setValue(response[0].Name);
        } 
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  closePlantGroupModel(): void {
    this.updateOrderprocessingData = false;
  }

  onNodeSelectReson(node: any): void {
    this.updateOrderprocessingData = false;
    this.MachineId = node.id;
    this.groupDataForm.get('unitName')?.setValue(node.text);
    this.UnitId = node.id;
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.checklistDataForm.value.group,
    };
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plantDDL = data.DepartList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getListType(): any {
    var typesObj = {
      groupId: this.groupDataForm.value.group,
      plantId: this.checklistDataForm.value.plant
    }
    this.ChecklistService.getTypesByPlant(typesObj).subscribe(
      (areadata: any) => {
        this.listTypeDDL = areadata;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  getItemType(): any {
    this.ChecklistService.getItemTypes().subscribe(
      (areadata: any) => {
        this.itemTypeDDL = areadata;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  getArea(): any {
    const key = {
      plantId: this.checklistDataForm.value.plant,
    };
    this.AreaServices.getQualityArea(key).subscribe(
      (areadata: any) => {
        this.areaDDL = areadata;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  getShifts(): any {
    const key = {
      plantId: this.checklistDataForm.value.plant,
    };
    this.qualityService.getQualityMasterShifts(key).subscribe(
      (areadata: any) => {
        this.ShiftDDL = areadata;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  imageZoomModal(imagePath: string, displayRoleModal: any): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%',});
  }

  imageZoomModalShow(imagePath: string): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.imageZoomModelShow, { height: '90%', width: '90%' });
  }

  OpenEditCheckList(checkListId: any, displayRoleModal: any): void {
    this.shiftName = null;
    this.checklistDataForm.get('shift')?.reset();
    this.checklistId = checkListId.checkListId;
    this.itemColumns = ['items', 'good', 'bad', 'standard', 'minimum', 'maximum', 'score', 'remarks'];
    this.getGroupData();
    this.getListType();
    this.getItemType();
    this.getCheckListById(checkListId);
    this.resetForm();
    this.goodImgArray = [];
    this.dialog.open(displayRoleModal, {
      width: '90vw !important',
      height: 'auto',
      maxHeight: '90vh',
      minWidth: '90vw',
      disableClose: true
    });
    const d = new Date();
    this.checklistDataForm.get('executionDate')?.setValue(d);
    this.formMode = false;
  }

  OpenViewCheckList(checkListId: any, displayRoleModalView: any): void {
    if (checkListId.lastExecutionId !== null) {
      this.spinner.show();
      this.shiftName = null;
      this.checklistDataForm.get('shift')?.reset();
      this.checklistId = checkListId.checkListId;
      this.itemColumns = ['items', 'good', 'bad', 'standard', 'minimum', 'maximum', 'score', 'remarks'];
      this.getGroupData();
      this.getListType();
      this.getItemType();
      this.getCheckListHistorybyId(checkListId.lastExecutionId, checkListId);
      this.resetForm();
      this.goodImgArray = [];
      this.dialog.open(displayRoleModalView, {
        width: '90vw !important',
        height: 'auto',
        maxHeight: '90vh',
        minWidth: '90vw',
        disableClose: true
      });
      const d = new Date();
      this.checklistDataForm.get('executionDate')?.setValue(d);
      this.formMode = false;
    }
    else {
      this.toaster.error('Error', 'No Execution History');
    }
  }

  imageZoomModal2(imagePath: string): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.imageZoomModel2, { height: '90%', width: '90%' });
  }

  resetForm(): void {
    this.checklistDataForm.reset();
    this.dialog.closeAll();

    this.addtabledata = [];
    this.addtabledata.length = 0;
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  onClickUnit(node: any): void {
    this.isPlantGroupModelVisible = false;
    this.UnitName = node.text;
    this.unitid = node.PrimaryId;
  }

  SelectUnitName(unitname: any): void {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
    }
  }

  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  SelectVarUnitName(unitname: any): any {
    if (unitname.title === 'UNT') {
      this.varUnitName = unitname.name;
      this.isPlantGroupModelVisible = false;
    }
  }

  openTreeModal(): any {
    this.updateOrderprocessingData = true;
    this.isPlantGroupModelVisible = true;
    const key = {
      EGId: this.groupDataForm.value.group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };
    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.UnitNode = filterData(this.plantGroupSource.data, 'UNT');
        this.expandAllNode();
        this.isPlantGroupModelVisible = true;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
    function filterData(data: PlantGroupElement[], title: string): any {
      const r = data.filter((o: any) => {
        Object.keys(o).forEach((e: any) => {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
  }

  closeParamterValuesModelVisible(): void {
    this.isPlantGroupModelVisible = false;
    this.updateOrderprocessingData = false;
  }

  OnDropdownUpdate(event: any, data: any): void {
    const checkListDetailId = parseInt(data.checkListDetailId, 10);
    const d = this._alldata.find(
      (x) => x.checkListDetailId === checkListDetailId
    );
    if (data.itemType === 5) {
      const v = event.value;
      d.catType = v;
    }
  }

  scoreUpdate(event: any, data: any): void {
    this.detailPrentId = data.checkListDetailId;
    const checkListDetailId = parseInt(data.checkListDetailId, 10);
    const d = this._alldata.find(
      (x) => x.checkListDetailId === checkListDetailId
    );
    if (data.itemType === 1) {
      const v = event.value;
      d.catType = v;
    } else {
      const score = event.target.value === '' ? '' : event.target.value;
      d.score = score;
      d.catType = score;
    }
    const result = this.checkListAddModel;
  }

  onDateChange(event: any, element: any): void {
    this.getDateShifts(event);
    const checkListDetailId = parseInt(element.checkListDetailId, 10);
    const d = this._alldata.find(
      (x) => x.checkListDetailId === checkListDetailId
    );
    d.catType = event.target.value;
  }

  remarksUpdate(event: any, data: any): void {
    const remarks = event.target.value;
    const checkListDetailId = parseInt(data.checkListDetailId, 10);
    const m = this._alldata.find(
      (x) => x.checkListDetailId === checkListDetailId
    );
    m.remarks = remarks;
    const result = this.checkListAddModel;
  }

  setShift(event: AnyRecord): void {
  }

  getCheckListById(element: any): void {
    const checkList = {
      checkListId: element.qualityCheckListId,
      unitId: this.UnitId,
    };
    this.spinner.show();
    this.qualityexecutionService
      .getCheckListByIdForNewExecution(checkList)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          this.checklistDataForm
            .get('group')
            ?.setValue(this.groupDataForm.get('group')?.value);
          this.checklistDataForm
            .get('plant')
            ?.setValue(this.groupDataForm.get('plant')?.value);
          this.checklistDataForm.get('unitName')?.setValue(element.Pu_Desc);
          this.checklistDataForm
            .get('checktype')
            ?.setValue(response[0]?.listType);
          this.checklistDataForm
            .get('checkname')
            ?.setValue(response[0]?.listName);
          this.checklistDataForm
            .get('currentProcessOrder')
            ?.setValue(
              response[0].currentProcessOrder === null
                ? ''
                : response[0].currentProcessOrder
            );
          this.checklistDataForm
            .get('currentProcessOrderId')
            ?.setValue(response[0].currentProcessOrderId);
          this.isAuto = response.currentProcessOrder;
          this.valueCheck = this.checklistDataForm.value.currentProcessOrderId;
          if (this.valueCheck) {
            this.isAuto = true;
          } else {
            this.isAuto = false;
          }

          this.checklistDataForm
            .get('currentProductName')
            ?.setValue(
              response[0].currentProduct === null
                ? ''
                : response[0].currentProduct
            );
          this.preCheck = this.checklistDataForm.value.currentProductName;
          if (this.preCheck) {
            this.preAuto = true;
          } else {
            this.preAuto = false;
          }
          this.checklistDataForm
            .get('previousProcessOrder')
            ?.setValue(
              response[0].prevProcessOrder === null
                ? ''
                : response[0].prevProcessOrder
            );
          this.checklistDataForm
            .get('previousProcessOrderId')
            ?.setValue(response[0].prevProcessOrderId);
          this.valueCheck2 =
            this.checklistDataForm.value.previousProcessOrderId;
          if (this.valueCheck2) {
            this.isAuto2 = true;
          } else {
            this.isAuto2 = false;
          }
          this.checklistDataForm
            .get('previousProductName')
            ?.setValue(
              response[0].prevProduct === null ? '' : response[0].prevProduct
            );
          this.checklistDataForm
            .get('targetOrderQuantity')
            ?.setValue(response[0].targetOrderQuantity);
          this.checklistDataForm
            .get('producedQuantity')
            ?.setValue(response[0].producedQuantity);
          this.preCheck2 = this.checklistDataForm.value.previousProductName;
          if (this.preCheck2) {
            this.preAuto2 = true;
          } else {
            this.preAuto2 = false;
          }
          this.getDateShifts(event);
          this.checklistDataForm.get('area')?.setValue(element.AreaName);
          response.forEach((ele: any) => {
            JSON.parse(ele.dropDown)
            const row = {
              id: this.counter,
              item: ele.itemName,
              itemtype: ele.itemType,
              drop: ele.itemGroup,
              checkListDetailId: ele.checkListDetailId,
              remarks: '',
              score: 0,
              catType: '',
              goodImage: this.imageSrc + ele.goodExampleImage,
              badImage: this.imageSrc + ele.badExampleImage,
              dropDown: ele.dropDown
            };
            this.addtabledata.push(row);
            const f = new File([''], 'filename');
            this.goodImgArray.push(f);
          });
          response.forEach((item: any, index: any) => {
            item.id = index + 1;
            item.remarks = '';
            item.score = '';
            item.catType = '';
          });
          this._alldata = response;
          this._alldata.forEach((ele: any) => {
            ele.dropDown = JSON.parse(ele.dropDown)
          })
          this.checkListAddModel.data = this.addGroups(
            this._alldata,
            this.groupByColumns
          );
          this.checkListAddModel.filterPredicate =
            this.customFilterPredicate.bind(this);
          this.checkListAddModel.filter = performance.now().toString();

          const goodImage = response.filter((element: any) => {
            if (element.goodExampleImage != "") {
              element.goodExampleImage = this.imageSrc + element.goodExampleImage;
              return element;
            }
          });
          if (goodImage.length != "") {
            this.GoodExampleImage = true;
          } else {
            this.removeImagesColumns('good');
          }
          const badImage = response.filter((element: any) => {
            if (element.badExampleImage != "") {
              element.badExampleImage = this.imageSrc + element.badExampleImage;
              return element;
            }
          });
          if (badImage.length != "") {
            this.BadExampleImage = true;
          } else {
            this.removeImagesColumns('bad');
          }
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error('Error', error.error.message);
        }
      );
  }

  getProcessOrder(): void {
    const key = {
      plantId: this.checklistDataForm.get('plant')?.value,
    };
    this.ncrService.getProcessOrder(key).subscribe(
      (res) => {
        this.ProcessOrder = res;
        this.PreviousProcessOrder = res;
        this.checklistDataForm
          .get('currentProcessOrder')
          ?.setValue(this.ProcessOrder[0].processId);
        this.checklistDataForm
          .get('currentProductName')
          ?.setValue(this.ProcessOrder[0].prodDesc);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onChangeOrder(data: any): void {
    const PO = this.ProcessOrder.find((c: any) => c.processId === data.value);
    this.checklistDataForm.get('currentProductName')?.setValue(PO.prodDesc);
  }

  onChangePreviousOrder(data: any): void {
    const currentproc = this.checklistDataForm.get(
      'currentProcessOrder'
    )?.value;
    if (currentproc === data.value) {
      this.toaster.warning(
        'Current proccess order no and Previous proccess order no can not be same.'
      );
      this.checklistDataForm.get('previousProcessOrder')?.setValue('');
      this.checklistDataForm.get('previousProductName')?.setValue('');
    } else {
      const PO = this.PreviousProcessOrder.find(
        (c: any) => c.processId === data.value
      );
      this.checklistDataForm.get('previousProductName')?.setValue(PO.prodDesc);
    }
  }

  onclickReset(): void {
    this.groupDataForm.get('unitName')?.reset();
    this.customerManagementDataSource.data = [];
    this.UnitId = null;
    this.getAllQualityCheckList();
  }

  onChangeradio(value: any): void {
    if (value.value === 'Yes') {
      this.yes;
    } else {
      this.no;
    }
  }

  addCheckListExecution(): void {
    this.disableAdd = true;
    const result = this._alldata;
    const executionDetails: any[] = [];
    result.forEach((element, i) => {
      const v = {
        checkListDetailId: element.checkListDetailId,
        remarks: element.remarks,
        acutalValue: element.score,
        goodImage: element.goodImage,
        badImage: element.badImage,
        itemTypeId: element.itemType,
        catType:
          element.itemType !== 4
            ? element.catType
            : this.goodImgArray[i].name === 'filename'
              ? ''
              : this.goodImgArray[i].name,
      };
      executionDetails.push(v);
    });
    const found = executionDetails.some((el) => el.catType !== '');
    if (!found) {
      this.toaster.warning('Please select any result value');
      return;
    }
    const formdata: any = new FormData();
    for (let index = 0; index < this.goodImgArray.length; index++) {
      formdata.append('goodImages', this.goodImgArray[index]);
    }
    const key = {
      userId: JSON.parse(localStorage.user).UserId,
      checkListId: this.checklistId,
      execDate: this.checklistDataForm.get('executionDate')?.value,
      shift: this.checklistDataForm.get('shift')?.value,
      unitId: this.UnitId,
      processOrder: this.checklistDataForm
        .get('currentProcessOrder')
        ?.value.toString(),
      previousProcessOrder: this.checklistDataForm.get('previousProcessOrder')?.value,
      productName: this.checklistDataForm.get('currentProductName')?.value,
      previousProductName: this.checklistDataForm.get('previousProductName')
        ?.value,
      targetOrderQuantity: this.checklistDataForm.get('targetOrderQuantity')
        ?.value,
      producedQuantity: this.checklistDataForm.get('producedQuantity')?.value,
      executionDetails,
    };
    formdata.append('checkListexecution', JSON.stringify(key));
    this.qualityexecutionService.addNewCheckListExecution(formdata).subscribe(
      (response) => {
        this.disableAdd = false;
        this.dialog.closeAll();
        this.toaster.success('Success', 'Audit Execution Added Successfully');
        this.sendNotification();
        this.resetForm();
        this.getAllQualityCheckList();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  uploadedFile(event: any, checkListDetailId: number): void {
    const ind = this.addtabledata.findIndex(
      (c: any) => c.checkListDetailId === checkListDetailId
    );
    const found = this.goodImgArray[ind];
    if (found) {
      this.goodImgArray[ind] = event.target.files[0];
      this.addtabledata[ind].catType = event.target.files[0]?.name;
    } else {
      if (event.target.files.length > 0) {
        this.fileSave = event.target.files[0];
        this.addtabledata[ind].catType = event.target.files[0].name;
        this.goodImgArray.push(this.fileSave);
      }
    }
  }

  getDropdown(params: any) {
    const key = {
      CheckListDetailId: params
    }
    this.qualityexecutionService.getdropdown(key).subscribe((res) => {
      this.currencDropdown = res;
    })
  }

  getDropdown1(params: any) {
    const key = {
      CheckListDetailId: params
    }
    this.qualityexecutionService.getdropdown(key).subscribe((res) => {
      this.dropdowData = res;
    })
  }

  removeImagesColumns(name: string): void {
    const removeIndex = this.itemColumns.findIndex((i: any) => {
      return i === name;
    });
    if (removeIndex >= 0) {
      const good = this.itemColumns.splice(removeIndex, 1);
    }
  }

  timeDif(event: any): any {
    const today = new Date();
    const startDate = moment(this.checklistDataForm.value.executionDate);
    const endDate = moment(today);
    const timeDiff = endDate.diff(startDate, 'hours');
    if (timeDiff > 24 || timeDiff < -24) {
      this.toaster.warning('Error', 'Select Date Range between 24 hours');
      return;
    }
  }

  OnChangeDate(): any {
    const startDate = this.checklistDataForm.value.processDate;
    const startTime = '15:10:10';
    const endTime = '22:30:00';
    const h = startDate.split(':')[0];
    const m = startDate.split(':')[1];
    const s = startDate.split(':')[2];
    const am = s.split(' ')[1];
    if (h > '7' && am === 'pm') {
      this.checklistDataForm.get('shift')?.setValue('Night');
    } else if (h > '7' && am === 'am') {
      this.checklistDataForm.get('shift')?.setValue('Day');
    } else if (h < '7' && am === 'pm') {
      this.checklistDataForm.get('shift')?.setValue('Day');
    } else if (h < '7' && am === 'am') {
      this.checklistDataForm.get('shift')?.setValue('Night');
    }
  }

  groupHeaderClickView(row: any): any {
    row.expanded = !row.expanded;
    this.dataSourceView.filter = performance.now().toString(); // bug here need to fix
  }

  groupByView(event: any, column: any): void {
    event.stopPropagation();
    this.checkGroupByColumnView(column.field, true);
    this.dataSourceView.data = this.addGroupsView(this.alldataView, this.groupByColumnsView);
    this.dataSourceView.filter = performance.now().toString();
  }

  checkGroupByColumnView(field: any, add: any): any {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumnsView.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumnsView.splice(found, 1);
      }
    } else {
      if (add) {
        this.groupByColumns.push(field);
      }
    }
  }

  addGroupsView(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevelView(data: any[], level: number, groupByColumns: string[], parent: Group
  ): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueByView(
      data.map((row) => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );
    const currentColumn = groupByColumns[level];
    let subGroups: any[] = [];
    groups.forEach((group: any) => {
      const rowsInGroup = data.filter(
        (row) => group[currentColumn] === row[currentColumn]
      );
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevelView(
        rowsInGroup,
        level + 1,
        groupByColumns,
        group
      );
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueByView(a: any, key: any): any {
    const seen: any = {};
    return a.filter((item: any) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  getCheckListHistorybyId(ExecutionId: number, element: any): void {
    const checkListHistory = {
      executionId: ExecutionId,
    };
    if (ExecutionId !== null) {
      this.QualityCheckService.getCheckListHistoryById(
        checkListHistory
      ).subscribe(
        (data: any) => {
          data.forEach(element => {
            if (element.dropDown) {
              if (element.ItemType == 5) {
                element.dropDown = JSON.parse(element.dropDown);
              }
            }
            element.typefile = ''
            if (element.ItemType === 4) {
              let x = element.CatType;
              element.CatType = this.imageSrc + element.CatType;
              element.typefile = x.split('.').pop();
            }
          });
          this.spinner.hide();
          this.checklistDataFormView.get('group')?.setValue(data[0].EG_Desc);
          this.checklistDataFormView.get('plant')?.setValue(data[0].PT_Desc);
          this.checklistDataFormView.get('checktype')?.setValue(data[0].QualityType);
          this.checklistDataFormView.get('Listname')?.setValue(data[0].ListName);
          this.checklistDataFormView.get('area')?.setValue(element.AreaName);
          this.checklistDataFormView
            .get('executionDate')
            ?.setValue(moment(data[0].ExecDate).format('DD-MM-YYYY'));
          this.checklistDataFormView.get('shift')?.setValue(data[0].ShiftName);
          this.checklistDataFormView.get('Unit')?.setValue(data[0].PU_Desc);
          this.checklistDataFormView.get('currentProcessOrder')?.setValue(data[0].ProcessOrder !== null ? data[0].ProcessOrder : '');
          this.checklistDataFormView
            .get('currentProductDescription')
            ?.setValue(data[0].ProductName !== 'null' ? data[0].ProductName : '');
          this.checklistDataFormView
            .get('previousProcessOrder')
            ?.setValue(
              data[0].PreviousProcessOrder !== null
                ? data[0].PreviousProcessOrder
                : ''
            );
          this.checklistDataFormView
            .get('previousProductDescription')
            ?.setValue(data[0].PreviousProductName);
          this.checklistDataFormView
            .get('targetOrderQuantity')
            ?.setValue(data[0].targetOrderQuantity);
          this.checklistDataFormView
            .get('producedQuantity')
            ?.setValue(data[0].producedQuantity);
          this.spinner.show();
          this.alldataView = data;
          this.dataSourceView.data = this.addGroups(
            this.alldataView,
            this.groupByColumnsView
          );
          this.dataSourceView.filterPredicate = this.customFilterPredicateView.bind(this);
          this.dataSourceView.filter = performance.now().toString();
          const goodImage = data.filter((element: any) => {
            if (element.GoodExampleImage) {
              if (element.GoodExampleImage != "") {
                element.GoodExampleImage = this.imageSrc + element.GoodExampleImage;
                return element;
              }
            }
          });
          if (goodImage.length > 0) {
            this.GoodExampleImage = true;
          } else {
            this.removeImagesColumns('good');
          }
          const badImage = data.filter((element: any) => {
            if (element.BadExampleImage) {
              if (element.BadExampleImage != "") {
                element.BadExampleImage = this.imageSrc + element.BadExampleImage;
                return element;
              }
            }
          });
          if (badImage.length > 0) {
            this.BadExampleImage = true;
          } else {
            this.removeImagesColumns('bad');
          }
          this.getPaginatorView(this.dataSourceView, 1);
          this.spinner.hide();
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
          this.spinner.hide();
        }
      );
    }
    else {
      this.toaster.error('Error', 'No Execution History');
    }
  }

  getPaginatorView(dataSource: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataSource.paginator = this.paginatorView.toArray()[index];
      dataSource.sort = this.sortView.toArray()[index];
    }, 1000);
  }

  getplantUnit(): void {
    const data = {
      ptId: this.groupDataForm.value.plant,
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.QualityCheckService.plantUnit(data).subscribe((res) => {
      this.unitsArray = res;
    },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onUnitChange(): void {
    this.MachineId = this.groupDataForm.value.unitName;
    this.updateOrderprocessingData = false;
    this.UnitId = this.groupDataForm.value.unitName;
    this.getAllQualityCheckList();
  }

  openFilePopUpView(elem: any, index: number, n: number): void {
    if (n === 1) {
      const array = elem.GoodExampleImage.split('.');
      const fileType = array[array.length - 1];
      this.doc = elem.GoodExampleImage;
      if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif') {
        this.images = [];
        this.fileType = 'img';
        this.images.push(this.doc);
      } else {
        this.fileType = 'doc';
      }
      const file = this.goodImgArray[index];
      this.displayMaximizable = true;
    }
    else if (n === 2) {
      const array = elem.BadExampleImage.split('.');
      const fileType = array[array.length - 1];
      this.doc = elem.BadExampleImage;
      if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif') {
        this.images = [];
        this.fileType = 'img';
        this.images.push(this.doc);
      } else {
        this.fileType = 'doc';
      }
      const file = this.goodImgArray[index];
      this.displayMaximizable = true;
    }
  }

  openFilePopUp(elem: any, index: number, n: number): void {
    if (n === 1) {
      const array = elem.goodExampleImage.split('.');
      const fileType = array[array.length - 1];
      this.doc = elem.goodExampleImage;
      if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif') {
        this.images = [];
        this.fileType = 'img';
        this.images.push(this.doc);
      } else {
        this.fileType = 'doc';
      }
      const file = this.goodImgArray[index];
      this.displayMaximizable = true;
    }
    else if (n === 2) {
      const array = elem.badExampleImage.split('.');
      const fileType = array[array.length - 1];
      this.doc = elem.badExampleImage;
      if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif') {
        this.images = [];
        this.fileType = 'img';
        this.images.push(this.doc);
      } else {
        this.fileType = 'doc';
      }
      const file = this.goodImgArray[index];
      this.displayMaximizable = true;
    }
  }

  onSaveGridState() {
    const savedState = this.gridColumnCheckListApi?.getColumnState();
    const data: GridConfiguration = {
      UserId: JSON.parse(localStorage.getItem('user')).UserId,
      PageId: this.pageId,
      Configuration: JSON.stringify(savedState),
      GridId: 'CheckListExecution-vTLU37IVZb'
    }
    if (!data.Configuration) {
      return;
    }
    this.gridService.saveGridConfiguration(data).subscribe((res: any) => {
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    )
  }

  applyState(gridId: string) {
    const data: GridConfiguration = {
      UserId: JSON.parse(localStorage.getItem('user')).UserId,
      PageId: this.pageId,
      GridId: gridId
    }
    this.gridService.getGridConfiguration(data).subscribe((res: any) => {
      if (res.Table.length) {
        this.gridColumnCheckListApi.applyColumnState({ state: JSON.parse(res.Table[0].Configuration), applyOrder: true, });
      }
      else {
        this.gridColumnCheckListApi.resetColumnState();
      }
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      })
  }

  onFilterTextBoxChanged() {
    this.gridApiCheckList.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  onGridReadyCheckList(params: any): any {
    this.gridApiCheckList = params.api;
    this.gridColumnCheckListApi = params.columnApi;
    this.gridApiCheckList.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector('#agGridCheckList') as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }

  onCellClicked(params: any): void {
    if (params.colDef.field === 'Action' && this.IsWrite) {
      const buttonType = params.event.target.textContent;
      if (buttonType === 'add') {
        this.OpenEditCheckList(params.data, this.displayRoleModal);
      }
      else if (buttonType === 'visibility') {
        this.OpenViewCheckList(params.data, this.displayRoleModalView);
      }
    }
  }
}
export class Group {
  [key: string]: any;
  level = 0;
  parent!: Group; 
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}
export class GroupView {
  [key: string]: any;
  level = 0;
  parent!: Group; 
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}