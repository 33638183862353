import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from 'process';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RMDashboardService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }
  // *** Core Api Method *** //
  getRMDashboardData(data: any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'RMDashboard/GetDashboardRM', { params: data });
  }

  getMachinesByLine(data: any): Observable<any[]> {
    return this.http.get<any>(this.baseUrl + 'get-rm-machines', { params: data });
  }
  // *** Core Api Method *** //
  getTableData(data: any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'RMDashboard/getTableUnit', { params: data });
  }

  getComponentByMachine(data: any): Observable<any[]> {
    return this.http.get<any>(this.baseUrl + 'get-rm-component', { params: data });
  }
    
  getMainKPIDashboard(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Dashboard/GetMainKPIDash', {...data,});
  }
  // *** Core Api Method *** //
  getPMTaskDetailsDash(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Dashboard/GetPMTaskDetailsDash', data);
  }
}
