import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BtnCellRendererQxMatrix } from 'src/app/btn-cell-rendererQxMatrix';
import { GeneralAreaComponent } from 'src/app/General Category/general-area/general-area.component';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import { Tool } from 'src/app/models/tool';
import { UserManu } from 'src/app/models/userManu';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpiDataPointService } from 'src/app/services/kpiDataPoint.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { MasterDepartmentService } from 'src/app/services/master-department.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import Swal from 'sweetalert2';
import { TranslaterService } from 'src/app/services/translater.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-Kpi-Data-Points',
  templateUrl: './Kpi-Data-Points.component.html',
  styleUrls: ['./Kpi-Data-Points.component.scss'],
})
export class KpiDataPointsComponent implements OnInit {
  @Input() tPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<Tool>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(GeneralAreaComponent) GeneralAreaComponent!: GeneralAreaComponent;
  @ViewChild('selectAll') selectAll!: MatSelect;

  searchMasterForm!: FormGroup;
  plants!: Plantbygroup[];
  DataPointsForm!: FormGroup;
  formMode = false;
  rowData: any;
  dataPointsModelVisible = false;
  kpiDataPointSource!: MatTableDataSource<any>;
  kpiDataPointColumns: string[] = [
    'Actions',
    'DataSource',
    'Area',
    'Machine',
    'DataPoint',
  ];
  Radioselection = false;
  radioDefault = true;
  radioDefault1 = false;
  disableDlt = false;
  kpiDataPointsList!: any[];
  IsAccess: any;
  IsWrite!: boolean;
  plantId!: any;
  unitList!: any[];
  OEEList!: any[];
  DirectQueryList!: any[];
  dataPointMode = true;
  puDesc!: any;
  oeeDesc!: any;
  directQueryDesc!: any;
  dsDesc!: any;
  kpiDpId!: any;
  areaList!: any[];
  filteredareas!: any[];
  filteredOEE!: any[];
  filteredDQ!: any[];
  filterdArray: any[] = [];
  generalArray: any[] = [];
  datasourceList: any;
  datasourceId: any;
  gridColumnApi: any;
  columnDefs!: any;
  gridApi!: any;
  favoriteSeason: any;
  filteredDataSource: any;
  dsId: any;
  dsName!: any;
  groupList: any;
  plantList: any;
  breadcrumList: any;
  directQueryId: any;
  puId: any;
  rowSelection: any;
  selectedStatus: any;
  showgeneral!: boolean;
  showMachine = true;
  showSingle!: boolean;
  showMulti!: boolean;
  Showmultiselect!: boolean;
  gridOptions: any = {
    frameworkComponents: {
      buttonCellRenderer: BtnCellRendererQxMatrix,
    },
  };
  buttonType: any;
  selectedRows: any;
  newidArr: any;
  teamLeaderData: any;
  dataPointDesc = false;
  queryType: any;
  selectedValues: any[] = [];
  allSelected = false;
  Filteredowners: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!:any;
  type:number;
  //Lines: import("/Users/abdulmateen/Projects/O3 Dev Azure/O3_App/src/app/models/ProductLine").ProductLine[];
  Lines:any=[];
  plId: any;
  constructor(
    private toaster: ToastrService,
    private kpiDataPointService: KpiDataPointService,
    private fb: FormBuilder,
    private platModelService: EnterPriseGroupService,
    private roleService: MasterDepartmentService,
    private machineService: MachineMappingService,
    private router: Router,
    private userManagementService: UserManagementService,
    private translater: TranslaterService,
    private commonService: CommonService,
  ) {
    this.GetMenuAccess();
    this.rowSelection = 'multiple';
  }

  ngOnInit(): void {
    this.formMode = true;
    this.DataPointsForm = this.fb.group({
      area2: ['', Validators.required],
      directQuery: [''],
      oeeParameterId: [''],
      status: [''],
      machine: ['', Validators.required],
      dataSource: [''],
      dataPoints: ['', Validators.required],
    });
    this.searchMasterForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    // this.DataPointsForm.get('directQuery')?.valueChanges.subscribe((x) => {
    //   if (x.length > 1) {
    //     // this.isDeleted = true;
    //     console.log(x);
    //   } else {
    //     // this.isDeleted = false;
    //     console.log(x);
    //   }
    // });
    this.getDataSource();
    this.getGroupsData();
    this.GetBreadcrumList();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        // localStorage.setItem('GetMenuAccess', this.permisson);
        this.columnDefs = [
          {
            field: 'Action',
            cellRenderer: 'buttonCellRenderer',
            editable: false,
            hide: !this.IsWrite,
            cellRendererParams: { clicked: (field: any) => { } },
            width: 120,
            resizable: true,
            headerValueGetter: this.localizeHeader.bind(this)
            // cellStyle: { textAlign: 'center' },
          },
          {
            field: 'dsName',
            headerName: 'Data Source',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'areaName',
            headerName: 'Area',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: true,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'datapointType',
            headerName: 'Data Point Type',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: true,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'puDesc',
            headerName: 'Data Point Location',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: true,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'directData',
            headerName: 'Direct Data',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: true,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'dataPointDesc',
            headerName: 'Data Point Desc',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: true,
            headerValueGetter: this.localizeHeader.bind(this)
          },
        ];
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        }, 500);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value:string){
    let val;
    if(this.langObj && this.langObj[value]){
      val = this.langObj[value];
    }
    return val?val:value;
 }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if(this.langObj){
        this.gridApi.refreshHeader();
      }
    });
  }

  getGroupsData(): void {
    const key = {
      Id: 0,
    };

    this.roleService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        this.searchMasterForm.get('group')?.setValue(this.groupList[0].TypeId);
        this.getPlantData();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getPlantData(): any {
    const key = {
      GroupId: this.searchMasterForm.value.group,
    };
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plantList = data.DepartList;
        this.searchMasterForm.get('plant')?.setValue(data.DepartList[0].DeptId);
        this.GetAllDataPoints();
        this.getDataSource();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  onChangeRadio(): void {
    this.Radioselection = !this.Radioselection;
    this.radioDefault = false;
    this.radioDefault1 = true;
  }

  // // Get Units By Plant
  // GetUnitsByPlant(key: any): void {
  //   this.plantId = key.plantId;
  //   const data = {
  //     ptId: key.plantId,
  //   };
  //   this.kpiDataPointService.GetUnitsByPlant(data).subscribe((res: any) => {
  //     this.unitList = res;
  //     // this.unitList.push({ puId: 0, puDesc: 'General' });
  //     // const firstItem = 'General';
  //     // this.unitList.sort((x, y) => {
  //     //   return x.puDesc === firstItem ? -1 : y.puDesc === firstItem ? 1 : 0;
  //     // });
  //     this.DataPointsForm.get('Machine')?.setValue(res[0].puId);
  //     this.puDesc = res[0].puDesc;
  //     this.GetAllDataPoints();
  //   });
  // }

  // changeUnit(event: any): void {
  //   // this.puDesc = this.unitList.find((x) => x.puId === event.value).puDesc;
  //   this.getDataSource();
  //   this.getOEEList();
  // }
  deleteMultiPoints(element: any): void {
    const selectedRows = this.gridApi.getSelectedRows();
    const array: any[] = [];
    selectedRows.forEach((el: any) => {
      array.push(el.kpiDpId);
    });
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Data Points!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          kpiDpId: array,
          ptId: this.searchMasterForm.value.plant,
        };
        this.kpiDataPointService.DeleteDataPoints(key).subscribe(
          (res: any) => {
            if (res.message === 'Data Points Used In Integration') {
              this.toaster.warning(res.message);
            } else {
              this.toaster.success(res.message);
            }
            this.GetAllDataPoints();
            this.dataPointsModelVisible = false;
            this.disableDlt = false;
          },
          (error) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected area is safe :)', 'error');
      }
    });
  }
  GetUnitByPlant(): void {
    const data = {
      ptId: this.searchMasterForm.value.plant,
    };
    this.kpiDataPointService.GetUnitsByPlant(data).subscribe((res: any) => {
      this.unitList = res;
      if (this.formMode === true) {
        // this.DataPointsForm.get('machine')?.setValue(this.unitList[0]);
        let setValueArray=[]
        // set firstorDefault value for field
        setValueArray.push(this.unitList[0])
        this.DataPointsForm.get('machine')?.setValue(setValueArray);
      } else {
        this.DataPointsForm.get('machine')?.setValue(
          this.unitList?.find((x) => x.puId === this.puId)
        );
      }
    });
  }

  GetAllDataPoints(): void {
    const data = {
      plantId: this.searchMasterForm.value.plant,
    };
    this.kpiDataPointService.GetAllDataPoints(data).subscribe((res: any) => {
      this.kpiDataPointSource = new MatTableDataSource<any>(res);

      for (var i = 0; i < res.length; i++) {
        if (res[i].puDesc == '') {
          res[i].puDesc = 'General';
        }
      }
      this.rowData = res;
      this.kpiDataPointSource.paginator = this.paginator;
    });
    this.getDataSource();
  }

  openDataPointModal(): void {
    //  this.gridApi.sizeColumnsToFit();
    this.formMode = true;
    this.showSingle = false;
    this.showMulti = false;
    this.Radioselection = false;
    this.radioDefault1 = false;
    this.radioDefault = true;
    this.formMode = true;
    this.dataPointMode = true;
    this.dataPointsModelVisible = true;
    this.filteredDQ = [];
    this.DirectQueryList = [];
    this.filteredareas = [];
    this.DataPointsForm.reset();
    this.getDataSource();
    // this.GetUnitByPlant();
  }

  AddDataPoints(): void {
    debugger;
  
    let arr = [];
    if (this.selectedValues.length <= 1) {
      const values = {
        Area: this.DataPointsForm.value.dataPoints,
        DirectQueryId: this.DataPointsForm.value.directQuery[0],
      };
      arr.push(values);
    } else {
      arr = this.selectedValues;
    }
    const key = {
      area2: this.DataPointsForm.value.area2,
      plantId: this.searchMasterForm.value.plant,
      puId: this.DataPointsForm.value.machine.map((c: any) => c.puId),
      puDesc: this.puDesc,
      dataPoints: arr,
      ds: this.DataPointsForm.value.dataSource,
      directQuery: this.DataPointsForm.value.directQuery,
      type:this.type
    };

    this.kpiDataPointService.AddDataPoints(key).subscribe((response: any) => {
      this.showPopupMsg(response.message);
      this.GetAllDataPoints();
      this.dataPointsModelVisible = false;
    });
    this.DataPointsForm.reset();
  }
  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
      this.toaster.success('Success', msg.substring(3, msg.length));
    } else if (msg.substring(2, 1) === '2') {
      this.toaster.success('Warning', msg.substring(3, msg.length));
    } else {
      this.toaster.error('Error', msg.substring(3, msg.length));
    }
  }
  async editDataPointModal(element: any): Promise<void> {
    this.formMode = false;
    this.dataPointMode = false;
    this.dataPointsModelVisible = true;
  
    this.kpiDpId = element.kpiDpId;
    this.plantId = element.ptId;
    this.directQueryId = element.directQueryId;
    this.puId = element.puId;
    this.plId = element.plId;
    this.type = element.type;
  
    await this.getDataSource();
  
    this.DataPointsForm.get('dataSource')?.setValue(element.dsId);
    this.datasourceId = element.dsId;
    await this.geto3Area();
  
    this.DataPointsForm.get('area2')?.setValue(element.areaId);
  
    if (element.type === 1) {
      this.unitList = [{ puId: 0, puDesc: 'General' }];
      this.setQueryTypeAndStatus(element);
    } else if (element.type === 2) {
      await this.GetUnitByPlant();
      this.setQueryTypeAndStatus(element);
      // await this.setMachineValue(element);
    } else if (element.type === 3) {
      await this.getLines();
      this.setQueryTypeAndStatus(element);
      // await this.setMachineValue(element);
    }
  
    this.DataPointsForm.get('dataPoints')?.setValue(element.dataPointDesc);
    await this.getDirectQueryListForEdit();
  }
  
  // async setMachineValue(element: any): Promise<void> {
  //   const pu = this.unitList?.find((c) => c.puId === (element.type === 2 ? element.puId : element.plId));
  //   this.DataPointsForm.get('machine')?.setValue(pu);
  //   console.log(pu,'pu');
    
  // }
  
  
  private setQueryTypeAndStatus(element: any): void {
    if (element.type === 1) {
      this.showSingle = true;
      this.showMulti = false;
      this.queryType = 1;
      this.selectedStatus = '1';
    } else if (element.type === 2 || element.type === 3) {
      this.showSingle = true;
      this.showMulti = false;
      this.queryType = element.type === 2 ? 2 : 3;
      this.selectedStatus = element.type === 2 ? '2' : '3';
    }
  }
  
  
  
  

  toggleAllSelection(): void {
    if (this.allSelected) {
      this.selectAll.options.forEach((item: MatOption) => item.select());
      this.filterdArray = this.filteredDQ?.map(
        (item: any) => item?.DirectQueryId
      );
      const x = this.DataPointsForm.get('directQuery')?.value;
      // const selectedarray = this.filteredDQ
      //   ?.map((item: any) => item?.Area)
      //   .toString();
      // this.DataPointsForm.get('dataPoints')?.setValue(selectedarray);
      // this.DataPointsForm.get('directQuery')?.setValue([...this.filterdArray]);
      this.dataPointDesc = true;
      const selectedarray = this.filteredDQ.filter((z) =>
        x.includes(Number(z.DirectQueryId))
      );
      this.selectedValues = selectedarray;
      const concatValues = selectedarray.map((c) => c.Area).toString();
      this.DataPointsForm.get('dataPoints')?.setValue(concatValues);
      this.dataPointDesc = true;
    } else {
      this.selectAll.options.forEach((item: MatOption) => item.deselect());
      this.DataPointsForm.get('dataPoints')?.setValue('');
      this.DataPointsForm.get('directQuery')?.setValue([]);
      this.dataPointDesc = false;
    }
  }
  optionClick(): any {
    const x = this.DataPointsForm.get('directQuery')?.value;
    // const selectedarray = this.filteredDQ?.find(
    //   (item: any) => item?.DirectQueryId === item.Area_id
    // );
    if (x.length > 1) {
      this.dataPointDesc = true;
      const selectedarray = this.filteredDQ.filter((z) =>
        x.includes(Number(z.DirectQueryId))
      );
      this.selectedValues = selectedarray;
      const concatValues = selectedarray.map((c) => c.Area).toString();
      this.DataPointsForm.get('dataPoints')?.setValue(concatValues);
    } else if (x.length === 1) {
      this.dataPointDesc = false;
      const selectedarray = this.filteredDQ.filter((z) =>
        x.includes(Number(z.DirectQueryId))
      );
      this.selectedValues = selectedarray;
      const concatValues = selectedarray.map((c) => c.Area).toString();
      this.DataPointsForm.get('dataPoints')?.setValue(concatValues);
    } else {
      this.dataPointDesc = false;
      this.DataPointsForm.get('dataPoints')?.setValue('');
    }
    let newStatus = true;
    this.selectAll.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  // OnclickMachine(): void {
  //   const x = this.DataPointsForm.get('machine')?.value;
  //   const selecetedArray = this.DataPointsForm.get('machine')?.setValue(x);
  //   console.log(selecetedArray);
  // }

  UpdateDataPoints(): void {
    const key = {
      kpiDpId: this.kpiDpId,
      area2: this.DataPointsForm.value.area2,
      plantId: this.searchMasterForm.value.plant,
      puId: this.DataPointsForm.value.machine?.puId,
      puDesc: this.puDesc,
      dataPoints: this.DataPointsForm.value.dataPoints,
      ds: this.DataPointsForm.value.dataSource,
      directQuery: this.DataPointsForm.value.directQuery, // dq,
      type: this.type,
    };
    this.kpiDataPointService.UpdateDataPoints(key).subscribe(
      (res: any) => {
        // this.toaster.success(res.message);
        this.showPopupMsg(res.message);
        this.GetAllDataPoints();
        this.dataPointsModelVisible = false;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  DeleteDataPoints(element: any): void {
    const key = {
      kpiDpId: [element.kpiDpId],
      ptId: this.searchMasterForm.value.plant,
    };
    this.kpiDataPointService.DeleteDataPoints(key).subscribe(
      (res: any) => {
        if (res.message === 'Data Points Used In Integration') {
          this.toaster.warning(res.message);
        } else {
          this.toaster.success(res.message);
        }
        this.GetAllDataPoints();
        this.dataPointsModelVisible = false;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getDataSource(): void {
    const key = {
      plantId: this.searchMasterForm.value.plant,
    };
    this.kpiDataPointService.getKPIDataSource(key).subscribe(
      (res) => {
        this.datasourceList = res;
        this.filteredDataSource = this.datasourceList.slice();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.kpiDataPointSource.filter = filterValue.trim().toLowerCase();
  }

  geto3Area(): void {
    const key = {
      plantId: this.searchMasterForm.value.plant,
      dsId: this.datasourceId,
    };
    this.kpiDataPointService.getKPIO3Areas(key).subscribe(
      (res) => {
        this.areaList = res;
        this.DataPointsForm.get('DataSource')?.setValue(res[0].dsId);
        this.filteredareas = this.areaList.slice();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onChangeDataSource(event: any): void {
    this.datasourceId = event.value;
    this.DataPointsForm.get('dataPoints')?.reset();
    this.dsName = this.datasourceList.find(
      (x: any) => x.dsDesc === event.dsDesc
    ).dsName;
    this.DataPointsForm.get('area2')?.setValue(0);
    this.geto3Area();
    this.DataPointsForm.get('status')?.setValue('');
    this.unitList = [];
    this.filteredDQ = [];
    this.DirectQueryList = [];
  }

  getOEEList(): void {
    const data = {
      puId: this.DataPointsForm.value.machine,
    };
    this.kpiDataPointService.getOEEList(data).subscribe((res: any) => {
      this.OEEList = res;
      this.filteredOEE = this.OEEList.slice();
      this.oeeDesc = res.TypeName;
    });
  }

  onChangeOEEList(element: any): void {
    this.oeeDesc = this.OEEList.find(
      (x) => x.TypeId === element.value
    ).TypeName;
  }

  getDirectQueryList(): void {
    this.DataPointsForm.get('dataPoints')?.reset();
    const data = {
      areaId: this.DataPointsForm.value.area2,
      queryType: this.queryType,
    };
    this.kpiDataPointService.getDirectQueryList(data).subscribe((res: any) => {
      this.DirectQueryList = res;
      this.filteredDQ = this.DirectQueryList.slice();
      this.directQueryDesc = res.Area;
      // const filterDQ1:any[]=[];
      // const Querylist:any[]=this.DirectQueryList;
      // for(let i=0;i<this.rowData.length;i++)
      // {
      //   for(let j=0;j<Querylist.length;j++)
      //      {
      //               if(this.rowData[i].dq.directQueryId === Querylist[j].DirectQueryId)
      //               {
      //                 filterDQ1.push(Querylist[j]);
      //                 Querylist.splice(j,1);
      //               }
      //      }

      // }
      // this.filteredDQ=Querylist.slice();
    });
  }
  changeStatus(event: any): void {
    this.type = event.value;
    this.DataPointsForm.get('dataPoints')?.reset();
    this.DataPointsForm.get('directQuery')?.reset();
    if (event.value === '1') {
      // this.queryType = event.value;
      this.unitList = [];
      const pu = {
        puId: 0,
        puDesc: 'General',
      };
      this.unitList.push(pu);
      // this.unitList.filter((unit) => unit.puId === 0);
      this.DataPointsForm.get('machine')?.setValue(this.unitList);
    } else if(event.value === '2') {
      this.showSingle = false;
      this.GetUnitByPlant();
    }
    else if(event.value === '3') {
      this.showSingle = false;
      this.getLines();
    }
    this.queryType = event.value;
    this.showMulti = this.formMode ? true : false;
    this.showSingle = this.formMode === false ? true : false;
    this.getDirectQueryList();
  }
  getLines(): void {
    const key = {
      plantId: this.searchMasterForm.value.plant,
    };
  
    this.commonService.getmasterline(key).subscribe(
      (lines) => {
        this.Lines = lines;
  
        // Convert db field name into array object field
        this.unitList = this.Lines.map((line) => ({
          puId: line.plId,
          puDesc: line.plDesc
        }));
        // Set firstOrDefault value for field
        if (this.formMode === true) {
          const defaultValue = this.unitList.length > 0 ? [this.unitList[0]] : [];
          this.DataPointsForm.get('machine')?.setValue(defaultValue);
          
        } else {
          const defaultValue = this.unitList.find((unit) => unit.puId === this.plId);
          this.DataPointsForm.get('machine')?.setValue(defaultValue);
        }
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  
  getDirectQueryListForEdit(): void {
    const data = {
      areaId: this.DataPointsForm.value.area2,
      queryType: this.queryType,
    };
    this.kpiDataPointService.getDirectQueryList(data).subscribe((res: any) => {
      this.DirectQueryList = res;
      this.filteredDQ = this.DirectQueryList.slice();
      this.directQueryDesc = res.Area;
      this.DataPointsForm.get('directQuery')?.setValue(
        this.DirectQueryList?.find(
          (x) => x.DirectQueryId === this.directQueryId
        )?.DirectQueryId
      );
    });
  }

  closeToolModel(): void {
    this.dataPointsModelVisible = false;
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  oncellclicked(params: any) {
    const col = params.colDef.field;
    this.buttonType = '';
    if (col === 'Action') {
      this.buttonType = params.event.target.textContent;
      if (this.buttonType === 'edit' && this.IsWrite) {
        this.editDataPointModal(params.data);
      }
      if (this.buttonType === 'delete' && this.IsWrite) {
        this.DeleteDataPoints(params.data);
      }
    }
    if (col) {
      const selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows.length > 1) {
        this.disableDlt = true;
      } else {
        this.disableDlt = false;
      }
    }
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#myGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }
  onRowSelected(event: any): any {
    // window.alert(
    //   'row ' +
    //   event.node.data.athlete +
    //   ' selected = ' +
    //   event.node.isSelected()
    // );
  }

  onSelectionChanged(event: any): any {
    const rowCount = event.api.getSelectedNodes().length;
    // window.alert('selection changed, ' + rowCount + ' rows selected');
    if (rowCount > 1) {
      this.disableDlt = true;
    } else {
      this.disableDlt = false;
    }
  }
}
