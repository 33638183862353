import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {
  breadcrumList: any;
  groupListDDL!: any[];
  plants!: any;
  ReportingForm!: FormGroup;
  columnDefs: any;
  rowData: any;
  columnDefs1: any;
  rowData1: any;
  constructor( private usermanagementService: UserManagementService, public router: Router,private toaster: ToastrService,
               private machineService: MachineMappingService, private fb: FormBuilder) 
               { 
                this.columnDefs = [
                  {
                    headerName: 'Currency: SAR',
                    children: [
                      {
                        headerName: 'Pillar Leader / Responsible',
                        children:[{
                          headerName: '11/12/20',
                          field: 'date'
                        }]
                      }
                    ]
                  },
                  {
                    headerName: 'PS Pillar',
                    children: [
                      {
                        headerName: '',
                        children:[{
                          headerName: 'Expected',
                          field: 'expected_ps'
                        }, {
                          headerName: 'Actual',
                          field: 'actual_ps'
                        }]
                      }
                    ]
                  },
                  {
                    headerName: 'FI Pillar',
                    children: [
                      {
                        headerName: '',
                        children:[{
                          headerName: 'Expected',
                          field: 'expected_fi'
                        }, {
                          headerName: 'Actual',
                          field: 'actual_fi'
                        }]
                      }
                    ]
                  },
                  {
                    headerName: 'PM Pillar',
                    children: [
                      {
                        headerName: '',
                        children:[{
                          headerName: 'Expected',
                          field: 'expected_pm'
                        }, {
                          headerName: 'Actual',
                          field: 'actual_pm'
                        }]
                      }
                    ]
                  },
                  {
                    headerName: 'AM Pillar',
                    children: [
                      {
                        headerName: '',
                        children:[{
                          headerName: 'Expected',
                          field: 'expected_am'
                        }, {
                          headerName: 'Actual',
                          field: 'actual_am'
                        }]
                      }
                    ]
                  },
                  {
                    headerName: 'PQ Pillar',
                    children: [
                      {
                        headerName: '',
                        children:[{
                          headerName: 'Expected',
                          field: 'expected_pq'
                        }, {
                          headerName: 'Actual',
                          field: 'actual_pq'
                        }]
                      }
                    ]
                  },
                  {
                    headerName: 'HSE Pillar',
                    children: [
                      {
                        headerName: '',
                        children:[{
                          headerName: 'Expected',
                          field: 'expected_hse'
                        }, {
                          headerName: 'Actual',
                          field: 'actual_hse'
                        }]
                      }
                    ]
                  },
                  {
                    headerName: 'Total',
                    children: [
                      {
                          headerName: 'Expected',
                          field: 'expected_hse'
                        }, {
                          headerName: 'Actual',
                          field: 'actual_hse'
                      }
                    ]
                  },
                ];
                this.rowData = [
                  { date: 'Cost Element'}
                ];
               }

  ngOnInit() {
    this.GetBreadcrumList();
    this.getGroupData();
    this.ReportingForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
  }
  getGroupData(): void {
    const key = {
      Id: 0
    };
    this.usermanagementService.getGroupData(key).subscribe((data: any) => {
      this.groupListDDL = data.masterList;
      this.ReportingForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
      this.getPlantByGroup();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.ReportingForm.get('group')?.value
    };
    // this.MachineId = null;
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.plants = data.DepartList;
      this.ReportingForm.get('plant')?.setValue(this.plants[0].DeptId);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  GetBreadcrumList(): any{
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([url]));
    // return false;
  }
}
