export const environment = {
  production: false,
  //O3CoreApiUrl: 'https://localhost:44321/api/',
  //O3CoreApiUrl: 'https://o392.ogf-yanbu.com/api/',
  // O3CoreApiUrl: 'https://o3-glass-api.azurewebsites.net/api/',
        // For Cloud
  O3CoreApiUrl: 'https://o3-live-glass-api.azurewebsites.net/api/',
  // O3CoreApiUrl: 'https://glass-stage-api.azurewebsites.net/api/',

  //O3CoreApiImageUrl: 'https://localhost:44321/',
  //O3CoreApiImageUrl: 'https://o392.ogf-yanbu.com/',
  // O3CoreApiImageUrl: 'https://o3-glass-api.azurewebsites.net/',
        // For Cloud
  O3CoreApiImageUrl: 'https://o3-live-glass-api.azurewebsites.net/',
  // O3CoreApiImageUrl: 'https://glass-stage-api.azurewebsites.net/',
  // workFLowApi: 'https://localhost:7028/api/',
  workFLowApi: 'https://o3-uat-workflowengine-new.azurewebsites.net/api/',
  // companyKey: '03182ffc-c62e-44e6-9004-ae70599a2950',
  companyKey: '03182ffc-c62e-44e6-9004-ae70599a2951',
};
