import { AuditDashboard } from './../../services/audit-dashboard.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AreaService } from 'src/app/services/area.service';
import * as Highcharts from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap.js';

// import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// import highchartsHeatmap from 'highcharts/modules/heatmap';
Heatmap(Highcharts);

const moment = _moment;
@Component({
  selector: 'app-gemba-walk-dashboard',
  templateUrl: './gemba-walk-dashboard.component.html',
  styleUrls: ['./gemba-walk-dashboard.component.scss']
})
export class GembaWalkDashboardComponent implements OnInit {
  auditDashboardForm!: FormGroup;
  highcharts: typeof Highcharts = Highcharts;
  options: Highcharts.Options = {};
  chartOptionsHeatMap: any;
  // Drop-down Variables
  monthfirstDay = new Date(new Date().getFullYear(), new Date().getMonth());
  machineList = ['ALL', 'RTM1', 'RTM2'];
  // ag-grid
  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
  };
  dataArray!: any[];
  columnDefs: any;
  rowData: any;
  plantList = ['P1', 'P2'];
  canvas1: any;
  ctx1: any;
  canvas2: any;
  ctx2: any;
  canvas3: any;
  ctx3: any;
  canvas4: any;
  ctx4: any;
  canvas5: any;
  ctx5: any;
  canvas6: any;
  ctx6: any;
  canvas7: any;
  moduleId!: any;
  pageId!: any;
  oeeAnalysis = {
    barType: 'radial',
    color: '#10CE9C',
    secondColor: '#E5E5E5',
    progress: 85,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      },
    },
  };
  capacityUtilizationAnalysis = {
    barType: 'radial',
    color: '#3AAFE4',
    secondColor: '#E5E5E5',
    progress: 55,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      },
    },
  };
  availabilityAnalysis = {
    barType: 'radial',
    color: '#F85439',
    secondColor: '#E5E5E5',
    progress: 45,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#F85439',
      },
    },
  };
  performanceAnalysis = {
    barType: 'radial',
    color: '#3AAFE4',
    secondColor: '#E5E5E5',
    progress: 65,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      },
    },
  };
  qualityAnalysis = {
    barType: 'radial',
    color: '#F85439',
    secondColor: '#E5E5E5',
    progress: 45,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#F85439',
      },
    },
  };
  // iconUrl
  menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';
  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  audicategories!: any[];
  filterdplants!: any[];
  areaDDL!: any[];
  groupListDDL!: any[];
  dayArray!: any[];
  breadcrumList: any;

  POCompleted: any;
  POCompleted1: any;
  POCompleted2: any;
  POCompleted3: any;
  heatmap: any;
  gridApi!: any;
  gridColumnApi!: any;
    // Month Filter
  
  constructor(
    private auditDashboardService: AuditDashboard,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private fb: FormBuilder,
    private machineService: MachineMappingService,
    private areaService: AreaService,
    private spinner: NgxSpinnerService,
  ) { 
    this.columnDefs = [
      { field: 'EG_Desc', headerName: 'Group' },
      { field: 'PT_Desc', headerName: 'Plant' },
      { field: 'AreaName', headerName: 'Area Name' },
      { field: 'AuditCategory', headerName: 'Audit Category' },
      { field: 'Finding', headerName: 'Finding' },
    ];
    // this.columnDefs = [{field: 'test'}, {field: 'test1'}];
    // this.rowData = [{test: '123', test1: '1234'}];
    this.chartOptionsHeatMap = {
      series: [
        {
          data: [
            {
              x: 'Area A',
              y: 0,
              fillColor: '#F85439',
              // strokeColor:'#F85439'
            },
          ],
        },
      ],
      legend: {
        show: false,
      },
      // dataLabels
      // : {
      //   show: false
      // },
      chart: {
        height: 450,
        type: 'treemap',
        toolbar: {
          show: false,
        },
        fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
        foreColor: '#000000',
        animations: {
          enabled: false,
        },
      },
      title: {
        text: 'Distibuted Treemap (different color for each cell)',
        align: 'center',
      },
      colors: [
        // "#F85439",
        // "#10CE9C",
        // "#3AAFE4",
        // "#040496",
      ],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
    };
    this.options = {
      chart: {
        type: 'heatmap',
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1,
      },
      title: {
        text: 'Heat Map',
      },
      accessibility: {
        point: {},
      },
      tooltip: {
        // formatter: function() {
        //   return (
        //     "<b>" +
        //     getPointCategoryName(this.point, "x") +
        //     "</b> sold <br><b>" +
        //     this.point.value +
        //     "</b> items on <br><b>" +
        //     getPointCategoryName(this.point, "y") +
        //     "</b>"
        //   );
        // }
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'HeatMap',
          borderWidth: 0,
          type: 'heatmap',
          data: [],
          dataLabels: {
            enabled: true,
            color: '#000000',
          },
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              yAxis: {
                labels: {},
              },
            },
          },
        ],
      },
    };

    // LicenseManager.setLicenseKey('kazaz');
  }

  // here are ag-grid functions

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.auditDashboardForm = this.fb.group({
      datePicker: ['',Validators.required],
      area: [''],
      plant: [''],
      group: [''],
    });
    this.auditDashboardForm.get('datePicker')?.setValue([
      this.monthfirstDay,
      new Date(),
    ]);
    Chart.defaults.global.legend.labels.usePointStyle = true;
    this.getGroupData();
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.auditDashboardForm
          .get('group')
          ?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.auditDashboardForm.value.group,
    };
    // this.MachineId = null;
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.filterdplants = data.DepartList;
        this.auditDashboardForm
          .get('plant')
          ?.setValue(this.filterdplants[0].DeptId);
        this.audicategories = [];
        this.areaDDL = [];
        this.getAuditAreaByPlant();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getAuditAreaByPlant(): void {
    // tslint:disable-next-line:no-debugger
    const plant = this.auditDashboardForm.get('plant')?.value;
    const key = {
      plantId: plant,
    };

    this.areaService.getArea(key).subscribe(
      (data) => {
        this.areaDDL = data;
        this.auditDashboardForm.get('area')?.setValue('-1');
        
        this.auditdashboardData();
      },
      (error) => {
        this.toaster.error(error.error.message);
      }
    );
  }

  

  get5sCompletion(key: any): void {
    if (this.POCompleted1) {
      this.POCompleted1.destroy();
    }
    this.auditDashboardService.get5sCompletion(key).subscribe(
      (res: any) => {
        const fiveSname: any[] = [];
        const completionTotal: any[] = [];
        res.forEach((element: any) => {
          fiveSname.push(element.Name);
          completionTotal.push(element.Total);
        });
        // this.searchPlants = res;
        // const plantObject = this.searchPlants[0];
        this.canvas5 = document.getElementById('POCompleted1');
        this.canvas5.height = 400;
        this.canvas5.width = 500;
        this.ctx1 = this.canvas5.getContext('2d');
        // this.ctx.height = 400;
        this.POCompleted1 = new Chart(this.ctx1, {
          type: 'bar',
          data: {
            labels: fiveSname,
            datasets: [
              {
                label: '',
                data: completionTotal,
                backgroundColor: 'rgba(4,4,150,1)',
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: false,
            maintainAspectRatio: false,
            display: true,
            tooltips: {
              callbacks: {
                label(tooltipItem: any, data: any): any {
                  return data.datasets[0].data[tooltipItem.index];
                },
              },
            },
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                padding: 20,
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    borderDash: [8, 4],
                  },
                  ticks: {
                    // min: 0,
                    // max: 40,
                    stepSize: 1,
                    beginAtZero: true,
                  },
                },
              ],
            },
          },
        });
        this.get5sOpen(key);
      },
      (error) => {
        this.toaster.error('Error', error.error.Message);
      }
    );
  }
  get5sOpen(key: any): void {
    if (this.POCompleted3) {
      this.POCompleted3.destroy();
    }
    this.auditDashboardService.Get5sOpen(key).subscribe(
      (res: any) => {
        const fiveSnameOpen: any[] = [];
        const OPenTotal: any[] = [];
        res.forEach((element: any) => {
          fiveSnameOpen.push(element.Name);
          OPenTotal.push(element.Total);
        });
        // this.searchPlants = res;
        // const plantObject = this.searchPlants[0];
        this.canvas5 = document.getElementById('POCompleted3');
        this.canvas5.height = 400;
        this.canvas5.width = 500;
        this.ctx2 = this.canvas5.getContext('2d');
        // this.ctx.height = 400;
        this.POCompleted3 = new Chart(this.ctx2, {
          type: 'bar',
          data: {
            labels: fiveSnameOpen,
            datasets: [
              {
                label: '',
                data: OPenTotal,
                backgroundColor: 'rgba(4,4,150,1)',
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: false,
            maintainAspectRatio: false,
            display: true,
            tooltips: {
              callbacks: {
                label(tooltipItem: any, data: any): any {
                  return data.datasets[0].data[tooltipItem.index];
                },
              },
            },
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                padding: 20,
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    borderDash: [8, 4],
                  },
                  ticks: {
                    // min: 0,
                    // max: 35,
                    stepSize: 1,
                    beginAtZero: true,
                  },
                },
              ],
            },
          },
        });
        // this.getFindingOverall(key);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // getFindingOverall(key: any): void {
  //   if (this.POCompleted) {
  //     this.POCompleted.destroy();
  //   }
  //   this.auditDashboardService.NoOf5sFindingOverall(key).subscribe(
  //     (res: any) => {
  //       const fiveSFindingOverallName: any[] = [];
  //       const FindingOverallTotal: any[] = [];
  //       res.forEach((element: any) => {
  //         fiveSFindingOverallName.push(element.Name);
  //         FindingOverallTotal.push(element.Finding);
  //       });
  //       // this.searchPlants = res;
  //       // const plantObject = this.searchPlants[0];
  //       this.canvas5 = document.getElementById('POCompleted');
  //       this.canvas5.height = 400;
  //       this.canvas5.width = 500;
  //       this.ctx3 = this.canvas5.getContext('2d');
  //       // this.ctx.height = 400;
  //       this.POCompleted = new Chart(this.ctx3, {
  //         type: 'bar',
  //         data: {
  //           labels: fiveSFindingOverallName,
  //           datasets: [
  //             {
  //               label: '',
  //               data: FindingOverallTotal,
  //               backgroundColor: 'rgba(4,4,150,1)',
  //               borderWidth: 1,
  //             },
  //           ],
  //         },
  //         options: {
  //           responsive: false,
  //           maintainAspectRatio: false,
  //           display: true,
  //           tooltips: {
  //             callbacks: {
  //               label(tooltipItem: any, data: any): any {
  //                 return data.datasets[0].data[tooltipItem.index];
  //               },
  //             },
  //           },
  //           legend: {
  //             display: true,
  //             position: 'bottom',
  //             labels: {
  //               padding: 20,
  //             },
  //           },
  //           scales: {
  //             xAxes: [
  //               {
  //                 gridLines: {
  //                   display: false,
  //                 },
  //               },
  //             ],
  //             yAxes: [
  //               {
  //                 gridLines: {
  //                   borderDash: [8, 4],
  //                 },
  //                 ticks: {
  //                   // min: 0,
  //                   // max: 40,
  //                   stepSize: 1,
  //                   beginAtZero: true,
  //                 },
  //               },
  //             ],
  //           },
  //         },
  //       });
  //        this.get5sFindingByArea(key);
  //     },
  //     (error) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }
  // get5sFindingByArea(key: any): void {
  //   if (this.POCompleted2) {
  //     this.POCompleted2.destroy();
  //   }
  //   this.auditDashboardService.NoOf5sFindingByArea(key).subscribe(
  //     (res: any) => {
  //       const fiveSFindingByArea: any[] = [];
  //       const FindingTotalByArea: any[] = [];
  //       res.forEach((element: any) => {
  //         fiveSFindingByArea.push(element.Name);
  //         FindingTotalByArea.push(element.FindingByArea);
  //       });
  //       // this.searchPlants = res;
  //       // const plantObject = this.searchPlants[0];
  //       this.canvas5 = document.getElementById('POCompleted2');
  //       this.canvas5.height = 400;
  //       this.canvas5.width = 500;
  //       this.ctx4 = this.canvas5.getContext('2d');
  //       // this.ctx.height = 400;
  //       this.POCompleted2 = new Chart(this.ctx4, {
  //         type: 'bar',
  //         data: {
  //           labels: fiveSFindingByArea,
  //           datasets: [
  //             {
  //               label: '',
  //               data: FindingTotalByArea,
  //               backgroundColor: 'rgba(4,4,150,1)',
  //               borderWidth: 1,
  //             },
  //           ],
  //         },
  //         options: {
  //           responsive: false,
  //           maintainAspectRatio: false,
  //           display: true,
  //           tooltips: {
  //             callbacks: {
  //               label(tooltipItem: any, data: any): any {
  //                 return data.datasets[0].data[tooltipItem.index];
  //               },
  //             },
  //           },
  //           legend: {
  //             display: true,
  //             position: 'bottom',
  //             labels: {
  //               padding: 20,
  //             },
  //           },
  //           scales: {
  //             xAxes: [
  //               {
  //                 gridLines: {
  //                   display: false,
  //                 },
  //               },
  //             ],
  //             yAxes: [
  //               {
  //                 gridLines: {
  //                   borderDash: [8, 4],
  //                 },
  //                 ticks: {
  //                   // min: 0,
  //                   // max: 35,
  //                   stepSize: 1,
  //                   beginAtZero: true,
  //                 },
  //               },
  //             ],
  //           },
  //         },
  //       });
  //       // this.getHeatMap(key);
  //     },
  //     (error) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }
 

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  auditdashboardData(): void {
    
    // Get Day dropdown.......
    let day = 0;
    const month = this.auditDashboardForm.get('month')?.value;
    if (month) {
      const d = new Date();
      const year = d.getFullYear();
      // tslint:disable-next-line:variable-name
      const number = new Date(year, month, 0).getDate();
      this.dayArray = [];
      for (let index = 0; index < number; index++) {
        day++;
        this.dayArray.push(day);
      }
    }
    // tslint:disable-next-line:no-debugger
    const key = {
      egId: this.auditDashboardForm.value.group,
      ptId: this.auditDashboardForm.value.plant,
    
      fromDate: this.auditDashboardForm.get('datePicker')?.value[0],
      toDate: this.auditDashboardForm.get('datePicker')?.value[1],
    
      areaId:
        this.auditDashboardForm.value.area === '-1'
          ? this.areaDDL.map((element) => element.areaId).toString()
          : this.auditDashboardForm.value.area,
      };

    this.spinner.show();
    this.get5sCompletion(key);
    this.spinner.hide();
  }

  // breadCrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  

}
