import { Component } from '@angular/core';
import { ILoadingCellRendererAngularComp } from 'ag-grid-angular';
import { ILoadingCellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-loading-cell-renderer',
  template: `
    <div
      class="ag-custom-loading-cell"
      style="padding-left: 10px; line-height: 25px;"
    >
      <i class="fas fa-spinner fa-pulse"></i>
      <span> {{ this.params.loadingMessage }} </span>
    </div>
  `,
})
// tslint:disable-next-line:component-class-suffix
export class CustomLoadingCellRenderer
  implements ILoadingCellRendererAngularComp {
  params: any;

  agInit(params: ILoadingCellRendererParams): void {
    this.params = params;
  }
}
