import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OeeConfigurationService } from 'src/app/services/oee-configuration.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventConfigurationService } from 'src/app/services/event-configuration.service';
import { ButtonDeleteRendererComponent } from 'src/app/components/button-delete-render.components';

@Component({
  selector: 'app-event-configuration',
  templateUrl: './event-configuration.component.html',
  styleUrls: ['./event-configuration.component.scss'],
})
export class EventConfigurationComponent implements OnInit {
  panelOpenState = false;

  isDataAvailable: boolean = false;

  breadcrumList!: any;
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  parameterType!: any[];

  // Forms
  unitDetailForm!: FormGroup;
  eventForm!: FormGroup;

  // Node
  UnitNode: any;

  //AG Grid Prop
  rowDataTags!: any[];
  columnDefsTags!: any[];
  gridApiTags: any;
  rowSelection: any;

  rowDataTEData!: any[];
  columnDefsTEData!: any[];
  gridApiTEData: any;

  rowDataAlias!: any[];
  columnDefsAlias!: any[];
  gridApiAlias: any;

  gridColumnApi: any;
  frameworkComponents: any;
  gridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 1,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 225,
      height: 400,
    },
    popupParent: document.querySelector('body'),
    floatingFilter: false,
  };

  //show hide variables for downtime and fault
  showDowntime: boolean = false;
  showFault: boolean = false;
  showHistory: boolean = true;
  downtimeTitle: string = 'Expression for downtime';

  //default selected modal is first item i-e: Downtime
  selectedModal: string = '';

  constructor(
    private userManagementService: UserManagementService,
    private toaster: ToastrService,
    public router: Router,
    private oeeConfigurationService: OeeConfigurationService,
    private eventService: EventConfigurationService,
    private platModelService: EnterPriseGroupService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {
    this.frameworkComponents = {
      buttonRendererDelete: ButtonDeleteRendererComponent,
    };
    this.columnDefsAlias = [
      {
        headerName: 'Action',
        cellRenderer: 'buttonRendererDelete',
        cellRendererParams: {
          onClick: this.deleteAlias.bind(this),
          label: 'Delete',
        },
        maxWidth: 100,
        suppressMenu: true,
      },
      {
        headerName: 'Alias',
        field: 'Alias',
        filter: 'agTextColumnFilter',
        maxWidth: 80,
      },
      { headerName: 'Parameter', field: 'Tag', filter: 'agTextColumnFilter' },
    ];

    this.columnDefsTags = [
      { headerName: 'Tags', field: 'TypeName', filter: 'agTextColumnFilter' },
    ];

    this.columnDefsTEData = [
      {
        headerName: 'Start_Time',
        field: 'Start_Time',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'End_Time',
        field: 'End_Time',
        filter: 'agTextColumnFilter',
      },
      { headerName: 'UnitId', field: 'PU_Id', filter: 'agTextColumnFilter' },
      {
        headerName: 'TEFault_Id',
        field: 'TEFault_Id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'TEStatus_Id',
        field: 'TEStatus_Id',
        filter: 'agTextColumnFilter',
      },
      { headerName: 'Uptime', field: 'Uptime', filter: 'agTextColumnFilter' },
      {
        headerName: 'Duration',
        field: 'Duration',
        filter: 'agTextColumnFilter',
      },
      { headerName: 'Remarks', field: 'Remarks', filter: 'agTextColumnFilter' },
      {
        headerName: 'CreatedOn',
        field: 'CreatedOn',
        filter: 'agTextColumnFilter',
      },
      { headerName: 'Status', field: 'Status', filter: 'agTextColumnFilter' },
    ];

    this.rowSelection = 'multiple';
  }

  GroupList!: any[];
  ModalList!: any[];
  AliasTagsList!: any[];

  UnitName!: string;
  unitId!: number;
  Model!: string;

  isPlantGroupModelVisible = false;

  // plant group view
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();

  // parent node
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.GetMenuAccess();
    this.GetBreadcrumList();
    this.getGroup();

    this.unitDetailForm = this.formBuilder.group({
      groupList: ['', Validators.required],
      parameterType: ['', Validators.required],
    });

    this.eventForm = this.formBuilder.group({
      expressionForDowntime: [''],
      expressionForDowntimeFault: [''],
    });
  }

  // group dropdown
  getGroup(): void {
    const key = {
      Id: 0,
    };
    this.oeeConfigurationService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.masterList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getModal(): void {
    this.isDataAvailable = false;

    const key = {
      Id: 0,
    };

    this.eventService.getModal(key).subscribe(
      (data: any) => {
        this.ModalList = data.masterList;
        if (this.ModalList && this.ModalList.length > 1) {
          // asim
          // this.ModalList[0].TypeName;
          // this.unitDetailForm.get('parameterType')?.setValue(this.ModalList[0].TypeName);
          // this.selectedModal = this.ModalList[0].TypeName;
          //unitDetailForm and parameterType
          // const toSelect = this.ModalList.find(c => c.Id == 1);

          this.unitDetailForm
            .get('parameterType')
            ?.setValue(this.ModalList[0].TypeName);
          var temp = { value: this.ModalList[0].TypeName };
          this.onChangeModal(temp);
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onChangeModal(data: any): void {
    this.Model = data.value;
    if (data && data.value) {
      if (data.value == 'Downtime') {
        this.showDowntime = true;
        this.showFault = true;
        this.showHistory = true;
        this.downtimeTitle = 'Expression for downtime';
      } else if ((data.value = 'Production')) {
        this.showFault = false;
        this.showHistory = false;
        this.downtimeTitle = 'Expression for production';
      }
    }
    this.loadAddedParameters(this.unitId, this.Model);
    this.LoadScript(this.unitId, this.Model);
    this.LoadScriptFault(this.unitId, this.Model);
    this.LoadDesktopFunctions();
    this.LoadTedList(this.unitId);
  }

  MoveToRight(): void {
    let selectedNodes = this.gridApiTags.getSelectedNodes();
    //let selectedData = selectedNodes.map(node => node.data);

    this.AliasTagsList = [];
    this.gridApiAlias.setRowData(this.rowDataAlias); // Refresh grid

    debugger;
    for (let i = 0; i < selectedNodes.length; i++) {
      this.AliasTagsList.push({
        Alias: 'A' + (i + 1).toString(),
        Tag: selectedNodes[i].data.TypeName,
      });
    }

    this.rowDataAlias = this.AliasTagsList;
    this.gridApiAlias.setRowData(this.rowDataAlias); // Refresh grid

    this.gridApiAlias.sizeColumnsToFit();
  }

  evaluateExpression(isSave: number): void {
    const input = {
      UserId: 0,
      Model: this.Model,
      Script: this.eventForm.value.expressionForDowntime,
      AliasTagsList: this.AliasTagsList,
      UnitId: this.unitId,
      saveBit: isSave,
    };

    this.eventService.EvaluateExpression(input).subscribe(
      (data: any) => {
        this.spinner.show();
        let result: string = data.CompilationResult;

        if (result.includes('Invalid')) {
          this.toaster.error('Error', result);
        } else {
          this.toaster.success('Success', result);
        }

        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  evaluateExpressionFault(isSave: number): void {
    const input = {
      UserId: 0,
      Model: this.Model,
      Script: this.eventForm.value.expressionForDowntimeFault,
      AliasTagsList: this.AliasTagsList,
      UnitId: this.unitId,
      saveBit: isSave,
    };

    this.eventService.EvaluateExpressionFault(input).subscribe(
      (data: any) => {
        this.spinner.show();
        let result: string = data.CompilationResult;

        if (result.includes('Invalid')) {
          this.toaster.error('Error', result);
        } else {
          this.toaster.success('Success', result);
        }

        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  LoadTedList(unitId: any): void {
    const input = {
      UserId: 0,
      UnitId: unitId,
    };

    this.eventService.GetTEDList(input).subscribe(
      (data: any) => {
        this.spinner.show();

        debugger;

        this.rowDataTEData = data.TEDList;

        //this.gridApiAlias.sizeColumnsToFit();

        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  LoadDesktopFunctions(): void {
    const input = {
      UserId: 0,
      Id: 0,
    };

    this.eventService.getDesktopFunctionsHistory(input).subscribe(
      (data: any) => {
        this.spinner.show();
        this.rowDataTags = data.masterList;

        //this.gridApiAlias.sizeColumnsToFit();

        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  loadAddedParameters(unitId: any, Model: any): void {
    const input = {
      UnitId: unitId,
      Model: Model,
    };

    this.eventService.loadAddedParameters(input).subscribe(
      (data: any) => {
        this.spinner.show();
        this.AliasTagsList = data.AliasTagsList;

        this.isDataAvailable = true;

        this.rowDataAlias = this.AliasTagsList;

        this.spinner.hide();

        // this.getLotsCodes();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  LoadScript(unitId: any, Model: any): void {
    const input = {
      UnitId: unitId,
      Model: Model,
    };

    this.eventService.loadScript(input).subscribe(
      (data: any) => {
        this.spinner.show();
        this.eventForm.get('expressionForDowntime')?.setValue(data.Grammer);

        this.spinner.hide();

        // this.getLotsCodes();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  LoadScriptFault(unitId: any, Model: any): void {
    const input = {
      UnitId: unitId,
      Model: Model,
    };

    this.eventService.loadScriptFault(input).subscribe(
      (data: any) => {
        this.spinner.show();
        this.eventForm
          .get('expressionForDowntimeFault')
          ?.setValue(data.Grammer);

        this.spinner.hide();

        // this.getLotsCodes();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  deleteAlias(key: any) {
    //debugger;
    const index = this.AliasTagsList.indexOf(key.data, 0);

    if (index > -1) {
      this.AliasTagsList.splice(index, 1);

      this.rowDataAlias = this.AliasTagsList;

      this.gridApiAlias.setRowData(this.rowDataAlias); // Refresh grid
    }
  }

  onGridReadyTags(params: any): any {
    this.gridApiTags = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApiTags.sizeColumnsToFit();
  }

  onGridReadyAlias(params: any): any {
    this.gridApiAlias = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  onGridReadyTEData(params: any): any {
    this.gridApiTEData = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApiTEData.sizeColumnsToFit();
  }

  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  // close model
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }

  // Unit
  openUnit(): void {
    this.isPlantGroupModelVisible = true;
    //debugger;
    const key = {
      EGId: this.unitDetailForm.value.groupList,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };
    this.oeeConfigurationService.getTreeModal(key).subscribe((data: any) => {
      this.plantGroupSource.data = data.TreeModalList;
      this.treeControl.dataNodes = this.plantGroupSource.data;
      this.UnitNode = filterData(this.plantGroupSource.data, 'UNT');
      this.treeControl.expandAll();
      this.treeControl.collapseDescendants(this.UnitNode);
    });
    // this.plantGroupSource.data = plantGroupData;

    function filterData(data: PlantGroupElement[], title: string) {
      const r = data.filter(function (o: any) {
        Object.keys(o).forEach(function (e: any) {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
  }

  onClickUnit(node: any): void {
    this.isPlantGroupModelVisible = false;
    this.UnitName = node.text;
    this.unitId = node.PrimaryId;
    this.getModal();
  }

  SelectUnitName(unitname: any): void {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
    }
  }

  showPopupMsg(msg: any): any {
    // debugger;
    if (msg !== null) {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
        // this.isaddNewUserModeItemModelVisible = false;
      } else {
        this.toaster.error('Error', msg.substring(3, msg.length));
      }
    }
  }

  // Icon
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  // menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        // localStorage.setItem('GetMenuAccess', this.permisson);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  unitNotSelect(form: FormGroup): void {
    for (const el in form.controls) {
      if (form.controls[el].errors && el === 'PU_ID') {
        this.toaster.error('Error', 'Unit is required');
        this.spinner.hide();
        return;
      }
    }
  }

  resetForm(form: FormGroup, value: any): void {
    form.get(value)?.setValue('');
  }
}
