import { CellClassRules } from '@ag-grid-community/core';
import { DatePipe } from '@angular/common';

import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
import { ColDef, ColGroupDef, ColSpanParams, SideBarDef } from 'ag-grid-community';
import { Chart, StockChart } from 'angular-highcharts';
import {
  differenceInCalendarDays,
  endOfMonth,
  startOfMonth,
  startOfYesterday,
} from 'date-fns';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Accessibility from 'highcharts/modules/accessibility';
import ExportData from 'highcharts/modules/export-data';
import Exporting from 'highcharts/modules/exporting';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexMarkers,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { HIGHCHARTCOLORS } from 'src/app/@constants/constants';
import { AtStagesNcrComponent } from 'src/app/analytical-tools/at-stages-ncr/at-stages-ncr.component';
import { ImageDialogComponent } from 'src/app/analytical-tools/image-dialog/image-dialog.component';
import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { BtnDeleteCellRenderer } from 'src/app/btn-deletecell-renderer';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { MeetingMatrixFrequency } from 'src/app/models/frequency';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { HighchartsService } from 'src/app/services/highcharts.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { MeetingActionService } from 'src/app/services/meeting-action.service';
import { MeetingDefinationService } from 'src/app/services/meeting-defination.service';
import { OverallActionPlanService } from 'src/app/services/overall-actionPlan.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { PrimeCellEditorComponent } from '../../editors/prime-cell-editor.component';
import { MeetingMatrixService } from '../../services/meeting-matrix.service';
import { MeetingRescheduleDialogComponent } from '../meeting-defination/meeting-reschedule-dialog/meeting-reschedule-dialog.component';
import { Full_CalendarComponent } from './full-calendar/full-calendar.component';
import { Subscription, timer } from 'rxjs';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { CommonService } from 'src/app/services/common.service';
import { CustomTooltip } from 'src/app/custom-tooltip.component';
import { DatepickerService } from 'src/app/services/Datepicker.service';
More(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};
@Component({
  selector: 'app-meeting-matrixV2',
  templateUrl: './meeting-matrixV2.component.html',
  styleUrls: [
    './meeting-matrixV2.component.scss',
    '../../user-actions/user-actions.component.scss',
  ],
})
export class MeetingMatrixV2Component implements OnInit {
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('displayUserModal') private displayUserModal!: TemplateRef<any>;
  @ViewChild('agendasViewSheet') private viewAgendasSheet!: TemplateRef<any>;
  @ViewChild('notesAndAgenda') private viewNotesAgenda!: TemplateRef<any>;
  @ViewChild('actionPlanModal') private actionPlanModal!: TemplateRef<any>;
  @ViewChild('ExtendMeeting') private ExtendMeeting!: TemplateRef<any>;

  @ViewChild('fileInputFile') fileInputFile!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('stackChart') public stackBarChart: ElementRef;
  @ViewChild('stackChartPercentage') public stackBarPercentageChart: ElementRef;
  @ViewChild('lineChartKPITrend') public lineChartKPITrend: ElementRef;
  @ViewChild('visibleParameterComparison')
  private visibleParameterComparison!: TemplateRef<any>;
  @ViewChild('displaytierMeetingModal')
  private displaytierMeetingModal!: TemplateRef<any>;
  @ViewChild('displayMeetingPendingModal')
  private displayMeetingPendingModal!: TemplateRef<any>;
  @ViewChild(Full_CalendarComponent, { static: true })
  fullCalendarComponent: Full_CalendarComponent;
  @ViewChild('addActionModal') private addActionModal!: TemplateRef<any>;
  @ViewChild('fileInputAdd') fileInputAdd!: ElementRef;
  @ViewChild('imageInputAdd') imageInputAdd!: ElementRef;
  ranges = {
    Today: [new Date(), new Date()],
    Yesterday: [startOfYesterday(), new Date()],

    'This Month': [startOfMonth(new Date()), endOfMonth(new Date())],
  };

  startOfTheMonth: any = new Date(
    new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm')).setHours(
      0,
      0,
      0,
      0
    )
  );
  selectedTabIndex = 0;
  menuPermission = {
    Scorecard: false,
    Talksheet: false,
    Dashboard: false,
    'Safety & ENV': false,
  };
  imageIndexOne = 0;
  MeetingStartTime: any;
  MeetingEndTime: any;
  MeetingDuration: any;
  decimalPlaces:number=4;
  ScoreCardDataTemp:any;
  Highcharts: typeof Highcharts = Highcharts;
  highChartsColor = HIGHCHARTCOLORS;
  isExpanded: boolean = false;
  scorecardData: any = {};
  rowSelection = 'multiple';
  defaultfrequencies: any;
  @ViewChild('extendEndTimeModal') extendEndTimeModal!: TemplateRef<any>
  @ViewChild('qualityBoolListModal')
  private qualityBoolListModal!: TemplateRef<any>;
  @ViewChild('displayLastMeetingModal')
  private displayLastMeetingModal!: TemplateRef<any>;
  @ViewChild('suggestedActionModal')
  private suggestedActionModal!: TemplateRef<any>;
  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;
  @ViewChild('suggestedPaginator', { static: true })
  suggestedPaginator!: MatPaginator;
  @ViewChildren('inputRef') inputAgendRefs!: QueryList<ElementRef>;
  @ViewChild('analysisChart') private analysisChart!: TemplateRef<any>;

  public filteredBanksMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public bankMultiFilterCtrl: FormControl = new FormControl();
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public kpiId: any;
  hideScore: boolean = true;
  firstDayfincialGap = new Date();
  lastDayfincialGap = new Date();
  chartcounter: number = 0;

  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  stock: any;
  sales: Chart;
  attendancecontainer: Chart;
  effectivenesscontainer: Chart;
  schedulecontainer: Chart;
  progresscontainer: Chart;
  piechart: Chart;
  lineChart: Chart;
  PopUpOccured = 0;
  mxDate = new Date();
  calendarMeetingId: any;
  calendarPlantId: any;
  mnDate = new Date();
  ButtonType!: any;
  StackedBarChart: boolean = false;
  public stepHour = 1;
  public stepMinute = 1;
  search = '';
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  inputFormControl = new FormControl({ value: null, disabled: true });
  public hideRuleContent: boolean[] = [];
  public buttonName: any = 'Expand';
  MeetingMatrixForm!: FormGroup;
  ExtendMeetingForm!: FormGroup;
  KPIComparisonForm: FormGroup;
  KPIAnalysisForm: FormGroup;
  financialGapList: any;
  LeaderId: number;
  meetingStatus: any;
  columnDefs3: any;
  rowData3: any[] = [];
  meetingAttendId: number;
  Special: boolean = false;

  rangeFinacialGap = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });

  chartrange = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });
  talksheet = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });

  MatrixForm!: FormGroup;
  ManualParticepantForm!: FormGroup;
  meetingSuggestedActionForm!: FormGroup;
  editActionPlanForm!: FormGroup;
  groupListDDL!: any[];
  filteredGroupListDDL!: any[];
  Email!: any[];
  plants!: any;
  filteredPlants!: any[];
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  columnDefs: any;
  rowData: any;
  pausebutton = false;
  columnDefsSuggested: any;
  tab1: boolean = true;
  tab2: boolean = false;
  MeetingReset: boolean = false;
  tierMeetingColumnDefs1: any;
  tierMeetingRowData: any[] = [];
  tierMeetingColumnDefs2: any;
  tierMeetingRowData2: any[] = [];
  enableGlidePath: boolean = false;
  enableGlidePathTalkSheet: boolean = false;
  showinput: boolean;
  public tabVisible: boolean = false;
  columnDefs1: any[] = [];
  frequencies!: MeetingMatrixFrequency[];
  rowData1: any;
  treeLevelList: any;
  level2 = false;
  hide = false;
  hide1 = false;
  isShow = false;
  isShowreverse = true;
  SideModelVisible = false;
  date!: Date;
  UserDDL: any;
  filteredUsers: any;

  gridApi: any;
  gridColumnApi: any;
  startYear!: Date;
  endYear!: Date;
  gridApiEdit: any;
  selectmeeting!: any;
  gridColumnApiEdit: any;
  meetingOriginalArray: any[] = [];
  meetingsArray: any[] = [];
  scoreArray: any[] = [];
  scoreData: any;
  cellData: any;
  timer = '00:00:00';

  interval: any;
  meetingStartTime: any;
  meetingEndTime: any;
  meetingStarted = false;
  enabledopenTierMeeting = false;
  meetingStoped = false;
  minutes = 0;
  hours = 0;
  seconds = 0;
  meetingParticipants: any[] = [];
  selectedAll = false;
  chartOptions!: ChartOptions;
  meetingEvalutionColumns: string[] = ['Date', 'Score', 'Target', 'Level'];
  meetingEvaluationData!: MatTableDataSource<any>;
  meetingAgendas!: any[];
  isKpiAddToAction = true;
  meetingSuggestedActions!: any;
  toastrService: any;
  meetingActionId: any;
  meetingDefId: any;
  meetingDefId2: any;
  AddAgendaForm!: FormGroup;
  AddNotesForm!: FormGroup;
  MeetingMatrixFrequencyForm!: FormGroup;
  agendaFormArray!: FormArray;
  filteredParticipants: any;
  meetingAgendasArray!: any[];
  meetingNotes!: any[];
  timeLeft: any;
  protected _onDestroy = new Subject<void>();
  Accountable: FormControl = new FormControl();
  shiftData: any[] = [];
  filtereMeetings!: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  participantsByPlants: any = [];
  columnDefscorecard: any;
  rowDatasscoreCard: any[] = [];
  ScoregridColumnApi: any;
  ScoregridApi: any;
  columnDefskpi: any;
  rowDatakpi: any[] = [];
  kpigridApi: any;
  kpigridColumnApi: any;
  columnDefsallAction: any;
  rowDataallAction: any[] = [];
  currentMeetingActions: any = [];
  actionChartOptions: any;
  actionscolumnDefs: any;
  actionsrowData: any[] = [];
  tabEvent: any;
  chartRef: Highcharts.Chart;
  chart: Chart;
  options: any;
  scheduleOptions: any;

  qualityBoolVal!: string;
  boolList = [];
  boolRowIndex: any;
  boolColId: any;
  Cdata: any[] = [];
  CCategorydata: any[] = [];
  public showMore: boolean[] = [];
  lines: any[] = [];
  selectedLine: number;
  questionData: any[];
  selectedKpi: any;
  topsales: Chart;
  MeetingDate: any;
  bar: Chart;
  DataSave = [];
  currentMeetingID: any;
  currentKPIId: any;
  nodeHeaderId: any = 0;
  overallMeetingActions: any[];
  chartData: any = {};
  hasFrequency: boolean;
  hasDateRange: boolean;
  frequencyId: any;
  feedBackColumnDefs: any;
  feedBackRowData: any[] = [];
  feedbackgridApi: any;
  selectedFrequency: MeetingMatrixFrequency;
  KPITrend: any = [];
  Tier2gridApi: any;
  TiergridApi: any;
  imageFile: any;
  data: any;
  ImageSave: any;
  fileSave: any;
  imageSrc: string;
  targetDate: any;
  RevisedDate: any;
  Status: any;
  ModuleName: any;
  UniqueCode: any;  
  blobStorageUrl: string = environment.O3CoreApiImageUrl;
  userList: any = [];
  disableVerification: boolean;
  disableNewAssigne: boolean;
  timerSubscription: Subscription;
  ShowlateValue: boolean = false;
  GRDData = new MatTableDataSource();


  public sideBar: SideBarDef | string | string[] | boolean | null = {

    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: false,
          suppressValues: true,
          suppressPivots: false,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
          suppressSyncLayoutWithGrid: true,

          // prevents columns being reordered from the columns tool panel
          suppressColumnMove: true,
        },
      },
    ],
    defaultToolPanel: 'columns',

  };




  //  public sideBar: SideBarDef | string | string[] | boolean | null = 'columns';

  buttons = [
    { text: 'HOPE' },
    { text: 'Line 1' },
    { text: 'Line 2' },
    { text: 'Line 3' },
    { text: 'Line 4' },
    { text: 'Line 5' },
    { text: 'Line 6' },
    { text: 'Line 7' },
    { text: 'Line 8' },
    { text: 'Line 9' },
    { text: 'Line 10' },
    { text: 'Line 11' },
  ];

  defaultColDef: {
    sortable: true;
    floatingFilter: true;
    filter: 'agMultiColumnFilter';
    headerHeight: 50;
    floatingFilterComponentParams: {
      suppressFilterButton: false;
    };
    filterParams: { newRowsAction: 'keep' };
  };
  scoreCardGridOptions: any = {
    defaultColDef: {
      sortable: true,
      floatingFilter: true,
      filter: 'agMultiColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be aggregated
      enableValue: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,

    },
    context: {
      thisComponent: this,
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRenderer: BtnDeleteCellRenderer,
      customLoadingOverlay: CustomLoadingOverlay,
    },

  };
  gridOptions1: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
    },
  };
  gridOptionsKPI: any = {
    defaultColDef: {
      flex: 1,
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },

      // isExternalFilterPresent(): any {
      //   return true;
      // },
      // doesExternalFilterPass(rowNode): any {
      //   return rowNode.data?.Type === 'Actual';
      // },
    },

    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
      customTooltip: CustomTooltip,
    },
  };

  selectedMeeting: any = {
    meetingId: 0,
    meetingName: '',
    meetingType: '',
  };

  results = [
    { Id: 1, name: 'test1', cTag: 'tag1' },
    { Id: 2, name: 'test2', cTag: 'tag2' },
    { Id: 3, name: 'test3', cTag: 'tag3' },
    { Id: 4, name: 'test4', cTag: 'tag4' },
  ];

  chartOptionss: Highcharts.Options = {
    series: [
      {
        type: 'line',
        pointInterval: 24 * 3600 * 1000,
        data: [1, 2, 3, 4, 5],
      },
    ],
  };
  defaultDateRange: any = [
    new Date(startOfYesterday().setHours(23, 59, 59, 0)),
    new Date(new Date().setHours(23, 59, 59, 0)),
  ];

  stackbarChartOptions = {
    chart: {
      // type: 'bar',
      type: 'column',
    },
    title: {
      text: 'Total Actions',
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        point: {
          events: {
            click: (e) => {
              //
              // alert(e.point.category);
            },
          },
        },
      },
    },
    series: [
      {
        name: 'Open',
        data: [5, 3, 4, 7, 2],
        color: '#0f04dd',
      },
      {
        name: 'Closed',
        data: [2, 2, 3, 2, 1],
        color: '#e85236',
      },
    ],
  };
  stackbarPercentageChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Total Actions (%)',
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: 'percent',
      },
    },
    series: [
      {
        name: 'Open',
        data: [5, 3, 4, 7, 2],
        color: '#0f04dd',
      },
      {
        name: 'Closed',
        data: [2, 2, 3, 2, 1],
        color: '#e85236',
      },
    ],
  };
  headerId: any;
  rowDataCopy: any = [];
  scoreCardGridApi: any;
  scoreCardGridColumnApi: any;
  loadingOverlayComponent: any;

  range = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    FGapfromDate: new FormControl(),
    FGaptoDate: new FormControl(),
  });
  frameworkComponents: any;
  attendanceChartOptions: any;
  sort: string = 'asc';
  TierMeetingData: any[] = [];
  filterAreaName: any[];
  totalpercentage: number;
  countarray: any[] = [];
  score: number = 0;
  totalscore: number;
  selectedIndex: number = 0;
  MeetingStatus: any[];

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Analysis',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptions1: MatProgressButtonOptions = {
    active: false,
    text: 'Comparison',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  chartArray: any[] = [];
  chartArrayForAnalysis: any[] = [];
  KPIChartOptions1: any = {};
  KPIChartOptions2: any = {};
  chartType: boolean;
  hasGlidePath: false;
  ButtonTypeedit: any;
  filterForm: FormGroup;
  closefile: any;
  doc: string;
  images: any[] = [];
  fileType: string;
  displayMaximizable: boolean;
  fileDesc: any;
  formMode: boolean;
  MeetingMatrixTalkSheetForm: FormGroup;
  isLoadingKPIAnalysis: boolean;
  isLoadingKPIComparision: boolean;
  currentNodeDate: any;
  currentAssigneeEmail: any;
  currentResponsibleEmail: any;
  hasEnteredMeeting: any;
  uniqueUoms: any;
  individualUom: any;
  compData: any;
  addActionForm!: FormGroup;
  imageAddActionPlan: any;
  fileAddActionPlan: any;
  addImageSrc: any;
  addFileSrc: any;
  kpiName: string = '';
  firstDayOfWeek: any;
  currentDialog: any;
  constructor(
    private kpiservice: KpitreeService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private meetingService: MeetingMatrixService,
    private usermanagementService: UserManagementService,
    private hcs: HighchartsService,
    private meetingDefinition: MeetingDefinationService,
    public router: Router,
    private kpiMaster: KpimasterService,
    private meetingaction: MeetingActionService,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    public translater: TranslaterService,
    private spinner: NgxSpinnerService,
    private analyticalToolsService: AnalyticalToolsService,
    private actionplanService: OverallActionPlanService,
    private renderer: Renderer2,
    private datePipe: DatePipe,
    private commonService: CommonService,
    private datepickerService: DatepickerService
  ) {
    this.loadingOverlayComponent = 'customLoadingOverlay';

    this.columnDefscorecard = [
      {
        field: 'area',
        wrapText: true,
        resizable: true,
        filter:true,
        cellRenderer: (params: any) => {
          if (params.value) {
            return '<div class="area-name">' + params.value + '' + '</div>';
          }
        },
        rowSpan: this.rowSpan,
        // cellClassRules: { 'show-cell': 'value !== undefined' },
        cellStyle: (params: any) => {
          if (params.value) {
            return {
              background: '#49FFB9',
              border: '1px solid #333',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            };
          } else {
            return { background: 'white' };
          }
        },
        width: '195',
      },
      { field: 'questions', headerName: 'Questions', width: '350' ,
      filter:true, },
      {
        // field: 'unit',
        // headerName: 'Unit',
        // width: '120',
        // editable: true,
        // cellRenderer: 'agCellRenderer',
        // cellEditor: 'agRichSelectCellEditor',
        // cellEditorParams: {
        //   cellRenderer: 'genderCellRenderer',
        //   values: ['Yes', 'No'],
        // },

        field: 'unit',
        headerName: 'Unit',
        width: '130',
        filter:true,
      },
      {
        // field: 'target',
        // headerName: 'Target',
        // width: '120',
        // editable: false,
        // cellRenderer: 'agCellRenderer',
        // cellEditor: 'agRichSelectCellEditor',
        // cellEditorParams: {
        //   cellRenderer: 'genderCellRenderer',
        //   values: ['Yes', 'No'],
        // },

        field: 'target',
        headerName: 'Target',
        width: '120',
        filter:true,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: '150',
        editable: true,
        filter:true,
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Yes', 'No'],

          //value:['Yes','No'],
        },
      },
      { field: 'remarks',filter:true, headerName: 'Remarks', width: '150', editable: true },
      { field: 'Action', filter:true,headerName: 'Action', width: '150' },
      { field: 'unitId',filter:true, headerName: 'Unit ID', width: '100', hide: true },
    ];

    this.columnDefs3 = [
      {
        field: 'meetingDefId',
        headerName: 'Meeting Def ID',
        hide: true,
      },
      {
        field: 'meetingAttendId',
        headerName: 'Meeting Attend ID',
        hide: true,
      },
      {
        field: 'meetingStartTime',
        headerName: 'Meeting Date',
      },
      {
        field: 'meetingStatus',
        headerName: 'Meeting Status',
        cellStyle: (params: { value: string }) => {
          if (params.value === 'Pending') {
            return {
              // backgroundColor: 'white',
              color: '#eb3d3d',

              'font-weight': 'bold',
            };
          } else if (params.value === 'Completed') {
            return {
              // backgroundColor: 'white',
              color: '#228B22',
              'font-weight': 'bold',
            };
          }
        },
        // width: 50,
      },
    ];

    this.columnDefskpi = [
      {
        field: 'KPIName',
        headerName: 'KPI Name',
        resizable: true,
        filter:true,
      },
      {
        field: 'Target',
        headerName: 'Target',
        resizable: true,
        filter:true,
      },
      {
        field: 'PreviousValue',
        headerName: 'Previous Value',
        resizable: true,
        filter:true,
      },
      {
        field: 'ActualValue',
        headerName: 'Actual Value',
        resizable: true,
        filter:true,
      },
    ];

    this.columnDefsallAction = [
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        resizable: true,
        filter:true,
        cellRenderer: (params: any): any => {
          if (params.data.Status !== 'Closed') {
            return `
            <div>
            <button mat-mini-fab="" 
            color="primary"
            ptooltip="Action Delete" 
            title="Edit Action" 
            tooltipposition="top" 
            class="viewRCA mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
             </div>
          `;
          }
        },
      },
      {
        field: 'RootCause',
        headerName: 'Root Cause',
        resizable: true,
        filter:true,
      },
      {
        field: 'Action',
        headerName: 'Meeting Action',
        resizable: true,
        filter:true,
        width: 150,
      },
      {
        field: 'KPIName',
        headerName: 'KPI Name',
        resizable: true,
        filter:true,
      },
      {
        field: 'Status',
        headerName: 'Status',
        resizable: true,
        filter:true,
        width: 100,
        cellStyle: function (params: any): any {
          if (params.value == 'Open')
            return { color: 'white', backgroundColor: 'gray' };
          else if (params.value == 'InProgress')
            return { color: 'white', backgroundColor: 'orange' };
          else if (params.value == 'Complete')
            return { color: 'white', backgroundColor: '#1cd51c' };
          else if (params.value == 'Closed')
            return { color: 'white', backgroundColor: 'green' };
          else if (params.value == 'Delayed')
            return { color: 'white', backgroundColor: 'brown' };
          else if (params.value == 'Rejected')
            return { color: 'white', backgroundColor: 'red' };
        },
      },
      {
        field: 'AssignedDate',
        headerName: 'Assigned Date',
        resizable: true,
        filter:true,
        valueFormatter: function (params) {
          return params.value
            ? moment(params.value).format('MMMM d, YYYY')
            : params.value;
        },
        width: 150,
      },
      {
        field: 'AssingedBy',
        headerName: 'Assigned By',
        resizable: true,
        width: 150,
        filter:true,
      },
      {
        field: 'TargetDate',
        headerName: 'Target Date',
        resizable: true,
        filter:true,
        valueFormatter: function (params) {
          return params.value
            ? moment(params.value).format('MMMM d, YYYY')
            : params.value;
        },
        width: 150,
      },
      {
        field: 'NoOfMisses',
        headerName: 'No of Misses',
        resizable: true,
        filter:true,
      },
      {
        field: 'RevisedDate',
        headerName: 'New Target Date',
        resizable: true,
        filter:true,
        valueFormatter: function (params) {
          return params.value
            ? moment(params.value).format('MMMM d, YYYY')
            : params.value;
        },
        width: 150,
      },
      {
        field: 'ActionCompleted',
        headerName: 'Completed',
        filter:true,
        resizable: true,
        width: 130,
      },
      {
        field: 'ClosedFile',
        headerName: 'Closed File',
        sortable: true,
        resizable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = '';
          if (params && params.value) {
            html = `<button mat-mini-fab=""
             color="primary" 
             ptooltip="View document" 
             title="View document" 
             tooltipposition="top" 
             class="viewDocument mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="viewDocument mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon></span><span matripple="" class="viewDocument mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            `;
          }
          return html;
        },
      },
      {
        field: 'Remarks',
        headerName: 'Remarks',
        resizable: true,
        filter:true,
      },
    ];
    this.tierMeetingColumnDefs1 = [
      {
        field: 'areapercent',
        headerName: 'Area %',
        wrapText: true,
        resizable: true,
        cellRenderer: (params: any) => {
          if (params.value) {
            return '<div class="area-percent">' + params.value + '' + '</div>';
          }
        },
        rowSpan: this.rowSpantier,
        cellStyle: (params: any) => {
          if (params.value) {
            return {
              background: '#49FFB9',
              border: '1px solid #333',
              alignItems: 'top',
              justifyContent: 'top',
              display: 'flex',
            };
          } else {
            return { background: 'white' };
          }
        },
        width: '50',
      },
      {
        headerName: 'No',
        type: 'leftAligned',
        resizable: true,
        width: 50,
        field: 'no',
        cellStyle: (params: ColSpanParams) => {
          if (this.isQuarterRow(params)) {
            return { background: '#002060', color: '#fff' };
          }
        },
        colSpan: (params: ColSpanParams) => {
          if (this.isQuarterRow(params)) {
            return 1;
          } else {
            return 1;
          }
        },
        cellClassRules: cellClassRules,
      },
      {
        headerName: 'Area',
        field: 'area',
        resizable: true,
        width: 340,
        cellStyle: (params: ColSpanParams) => {
          if (this.isQuarterRow(params)) {
            return { background: '#002060', color: '#fff' };
          }
        },
        colSpan: (params: ColSpanParams) => {
          if (this.isQuarterRow(params)) {
            return 2;
          } else {
            return 1;
          }
        },
        cellClassRules: cellClassRules,
      },
      {
        headerName: 'Actions',
        field: 'auditScope',
        resizable: true,
        width: 50,
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        autoHeight: true,
        onCellValueChanged: (params: any) => this.scorePercent(params),
        cellEditorParams: {
          cellRenderer: ' ',
          values: ['Yes', 'No'],
        },
        cellStyle: (params: ColSpanParams) => {
          if (this.isQuarterRow(params)) {
            return {
              background: '#002060',
              color: '#fff',
              'white-space': 'normal',
            };
          } else {
            return {
              background: '#dfdbdb',
              'white-space': 'normal',
            };
          }
        },
        colSpan: (params: ColSpanParams) => {
          if (this.isQuarterRow(params)) {
            return 1;
          } else {
            return 1;
          }
        },

        cellClassRules: cellClassRules,
      },
      {
        field: 'score',
        headerName: 'Score %',
        wrapText: true,
        resizable: true,
        width: 70,
        cellRenderer: (params: any) => {
          if (params.value) {
            return '<div class="score-name">' + params.value + '' + '</div>';
          }
        },
        rowSpan: this.rowSpantier,
        // cellClassRules: { 'show-cell': 'value !== undefined' },
        cellStyle: (params: any) => {
          if (params.value) {
            return {
              background: '#0e2060',
              color: '#fff',
              border: '1px solid #fff',
              alignItems: 'top',
              justifyContent: 'top',
              display: 'flex',
            };
          } else {
            return { background: 'white' };
          }
        },
        // width: '50',
      },
      {
        field: 'areaAchivedPerc',
        headerName: 'Area Achived %',
        wrapText: true,
        hide: true,
        resizable: true,
        cellRenderer: (params: any) => {
          if (params.value) {
            return '<div class="score-name">' + params.value + '' + '</div>';
          }
        },
        rowSpan: this.rowSpantier,
        // cellClassRules: { 'show-cell': 'value !== undefined' },
        cellStyle: (params: any) => {
          if (params.value) {
            return {
              background: '#0e2060',
              color: '#fff',
              border: '1px solid #fff',
              alignItems: 'top',
              justifyContent: 'top',
              display: 'flex',
            };
          } else {
            return { background: 'white' };
          }
        },
        width: '50',
      },
    ];

    // this.tierMeetingRowData = [
    //   {
    //     areapercent: '20',
    //     section: 'quarters',
    //     no: '1',
    //     area: 'Equipment and preparation ',
    //     actions: '',
    //     score: '80%',
    //   },
    //   {
    //     areapercent: '',
    //     no: '1.1',
    //     area: 'A Meeting agenda is visually displayed',
    //     question: 'A Meeting agenda is visually displayed',
    //     actions: '',
    //     score: '',
    //   },
    //   {
    //     areapercent: '',
    //     no: '1.2',
    //     area: '',
    //     question: 'Meeting rules are defined and visually displayed (e.g. no phone, on time, no "inside meeting")',
    //     actions: '',
    //     score: '',
    //   },
    //   {
    //     areapercent: '',
    //     no: '1.3',
    //     area: '',
    //     question:'Participants are well prepared (bring relevant data and samples with them into the meeting)',
    //     actions: '',
    //     score: '',
    //   },
    //   {
    //     areapercent: '',
    //     no: '1.4',
    //     area: '',
    //     question: 'Charts are updated prior to the beginning of the meeting',
    //     actions: '',
    //     score: '',
    //   },
    //   {
    //     areapercent: '',
    //     no: '1.5',
    //     area: '',
    //     question: 'Relevant KPI has an owner and reliable source (who, what is defined)',
    //     actions: '',
    //     score: '',
    //   },

    //   {
    //     areapercent: '40',
    //     section: 'quarters',
    //     no: '1',
    //     area: 'A Meeting agenda is visually displaye ',
    //     actions: '',
    //     score: '100%',
    //   },
    //   {
    //     areapercent: '',
    //     no: '1.1',
    //     area: '',
    //     question: 'A Meeting agenda is visually displayed',
    //     actions: '',
    //     score: '',
    //   },
    //   {
    //     areapercent: '',
    //     no: '1.2',
    //     area: '',
    //     question: 'Meeting rules are defined and visually displayed (e.g. no phone, on time, no "inside meeting")',
    //     actions: '',
    //     score: '',
    //   },
    //   {
    //     areapercent: '',
    //     no: '1.3',
    //     area: '',
    //     question:'Participants are well prepared (bring relevant data and samples with them into the meeting)',
    //     actions: '',
    //     score: '',
    //   },
    //   {
    //     areapercent: '',
    //     no: '1.4',
    //     area: '',
    //     question: 'Charts are updated prior to the beginning of the meeting',
    //     actions: '',
    //     score: '',
    //   },

    //   {
    //     areapercent: '',
    //     no: '1.5',
    //     area: '',
    //     question: 'The Reaction-on-Red process (5-Step Analyse) visually displayed and known by the participant',
    //     actions: '',
    //     score: '',
    //   },

    // ];
    this.tierMeetingColumnDefs2 = [
      {
        field: 'areapercent',
        headerName: 'Area %',
        wrapText: true,
        resizable: true,
        cellRenderer: (params: any) => {
          if (params.value) {
            return '<div class="area-percent">' + params.value + '' + '</div>';
          }
        },
        rowSpan: this.rowSpantier2,
        cellStyle: (params: any) => {
          if (params.value) {
            return {
              background: '#49FFB9',
              border: '1px solid #333',
              alignItems: 'center',
              // justifyContent: 'center',
              // display: 'flex',
            };
          } else {
            return { background: 'white' };
          }
        },
        width: '90',
      },
      {
        field: 'problemDesc',
        headerName: 'Problem Description',
        editable: true,
        resizable: true,
        sortable: true,
        width: '200',
        cellStyle: (params: any) => {
          if (params) {
            return {
              background: '#dfdbdb',
            };
          }
        },
      },
      {
        field: 'repetetive',
        headerName: 'Repetetive',
        editable: true,
        resizable: true,
        sortable: true,
        width: '90',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Yes', 'No'],
        },
        cellStyle: (params: any) => {
          if (params) {
            return {
              background: '#dfdbdb',
            };
          }
        },
      },
      {
        field: 'rootCause',
        headerName: 'Root Cause',
        width: '90',
        editable: true,
        resizable: true,
        sortable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Yes', 'No'],
        },
        cellStyle: (params: any) => {
          if (params) {
            return {
              background: '#dfdbdb',
            };
          }
        },
      },
      {
        field: 'corrective',
        headerName: 'Corrective Action',
        editable: true,
        resizable: true,
        width: '90',
        sortable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Yes', 'No'],
        },
        cellStyle: (params: any) => {
          if (params) {
            return {
              background: '#dfdbdb',
            };
          }
        },
      },
      {
        field: 'preventive',
        headerName: 'Preventive Action',
        editable: true,
        width: '90',
        resizable: true,
        sortable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Yes', 'No'],
        },
        cellStyle: (params: any) => {
          if (params) {
            return {
              background: '#dfdbdb',
            };
          }
        },
      },
      {
        field: 'result',
        headerName: 'Result',
        resizable: true,
        sortable: true,
        width: '90',
      },
      {
        field: 'score',
        headerName: 'Score %',
        wrapText: true,
        resizable: true,
        cellRenderer: (params: any) => {
          if (params.value) {
            return '<div class="score-name">' + params.value + '' + '</div>';
          }
        },
        rowSpan: this.rowSpantier2,
        // cellClassRules: { 'show-cell': 'value !== undefined' },
        cellStyle: (params: any) => {
          if (params.value) {
            return {
              background: '#0e2060',
              color: '#fff',
              border: '1px solid #333',
              alignItems: 'center',
              // justifyContent: 'center',
              // display: 'flex',
            };
          } else {
            return { background: 'white' };
          }
        },
        width: '90',
      },
    ];
    this.tierMeetingRowData2 = [
      {
        areapercent: '20',
        problemDesc: '',
        repetetive: '',
        preventive: '',
        corrective: '',
        rootCause: '',
        score: '33%',
      },
    ];
    this.feedBackColumnDefs = [
      {
        field: 'feedBack',
        headerName: 'Feedback',
        editable: true,
        resizable: true,
        sortable: true,
        cellStyle: (params: any) => {
          if (params) {
            return {
              background: '#dfdbdb',
            };
          }
        },
      },
    ];
    this.feedBackRowData = [
      {
        feedBack: '',
      },
    ];

  }
  cellClicked(params: any, showButton: boolean): void {
    const col = params.colDef.field;
    this.meetingActionId = params.data.meetingActionId;

    this.ButtonType = '';
    this.meetingActionId = params.data.meetingActionId;

    if (col === 'xyz') {
      this.ButtonType = params.event.target.classList[1];

      if (this.ButtonType === 'fa-trash') {
        this.ButtonType = '';
        this.deleteMeetingAction();
      }
    }
  }
  cellClickedMeetinAction(params: any, showButton: boolean): void {
    const col = params.colDef.field;
    this.meetingActionId = params.data.meetingActionId;
    this.formMode = false;
    this.ButtonType = '';
    this.meetingActionId = params.data.meetingActionId;

    if (col === 'xyz') {
      this.ButtonType = params.event.target.classList[1];

      if (this.ButtonType === 'fa-edit') {
        this.ButtonType = '';
        this.formMode = true;

        this.MatrixForm.get('meetingDefName').setValue(
          params.data?.meetingDefName
        );
        this.MatrixForm.get('action').setValue(params.data?.action);
        this.MatrixForm.get('responsible').setValue(
          parseInt(params.data?.responsibleId)
        );
        // this.MatrixForm.get('targetdate').setValue("2022-09-23")
        const str = params.data?.targetdate;
        const [day, month, year] = str.split('/');
        const data = new Date(+year, +month - 1, +day);
        // let latest_date =this.datepipe.transform(date, 'yyyy-MM-dd');
        //let latest_date = new Date(date);
        this.MatrixForm.get('targetdate').setValue(data);
        this.MatrixForm.valid === true;
        this.MatrixForm.controls['targetdate'].disable();
      }
      if (this.ButtonType === 'fa-trash') {
        this.ButtonType = '';
        this.deleteMeetingAction();
      }
    }
  }
  oncellclickedMeetinActions(params) { }
  scorePercent(params: any) {
    let data = params.data.level;
    let counter = 0;
    const filteredlevel = this.tierMeetingRowData.filter(
      (ob) => ob.level === data
    );
    const totallength = filteredlevel.length - 1;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < filteredlevel.length; i++) {
      if (filteredlevel[i].auditScope == undefined) {
        counter = counter;
      } else if (filteredlevel[i].auditScope === 'Yes') counter++;

      this.score = (counter / totallength) * 100;

      const a = this.tierMeetingRowData.findIndex(
        (c) => c.area === filteredlevel[0].level
      );

      const rowNode = this.TiergridApi.getRowNode(a)!;
      rowNode.setDataValue('score', Math.round(this.score).toFixed(2) + '%');
      rowNode.setDataValue(
        'areaAchivedPerc',
        (counter / totallength) * parseFloat(rowNode.data.areapercent)
      );

      // for(let i = 0;i<this.tierMeetingRowData.length;)
    }
    // this.countTotalpercent();
    this.TotalCountScore();

    // rowNode.setDataValue('score', this.score.toFixed(1) + '%');

    // for(let i = 0;i<this.tierMeetingRowData.length;)

    // this.countTotalpercent();
  }

  TotalCountScore() {
    let totalPer = 0;
    this.TiergridApi.forEachLeafNode((node: any) => {
      if (node.data.areaAchivedPerc) {
        totalPer += node.data.areaAchivedPerc;
      }
    });
    this.totalscore = totalPer;
  }
  onTierGridCellDoubleClicked(params) {
    const col = params?.column?.colDef?.field;
    if (col == 'auditScope' && params.node?.lastChild) {
      this.setLastRowHeight(params?.data?.area);
    }
  }
  onTierGridCellClicked(params) {
    this.TiergridApi.forEachNode(function (rowNode) {

      if (rowNode.rowHeight == 100) {
        rowNode.setRowHeight(null);
      }
    });
    this.TiergridApi.onRowHeightChanged();
  }
  setLastRowHeight(area) {
    // this is used next time resetRowHeights is called
    this.TiergridApi.forEachNode(function (rowNode) {
      if (rowNode.data && rowNode.data.area === area) {
        rowNode.setRowHeight(100);
      }
    });
    this.TiergridApi.onRowHeightChanged();
  }
  onTierGridCellValueChanged(params) {
    const col = params?.column?.colDef?.field;

    if (col == 'auditScope') {
      this.TiergridApi.forEachNode(function (rowNode) {

        if (rowNode.rowHeight == 100) {
          rowNode.setRowHeight(null);
        }
      });
    }
  }
  getTierGridRowHeight(params: any): number | undefined | null {
    return params.data.rowHeight;
  }
  countTotalpercent() {
    const unique = [
      ...new Set(this.tierMeetingRowData.map((item) => item.level)),
    ];
    const templength = unique.length;
    let count = 0;
    const ex = this.tierMeetingRowData.length;
    const tempex = this.tierMeetingRowData.length - templength;
    for (let i = 0; i < ex; i++) {
      if (this.tierMeetingRowData[i].auditScope == undefined) {
        count = count;
      } else {
        if (this.tierMeetingRowData[i].auditScope === 'Yes') {
          count++;
          this.totalscore = (count / tempex) * 100;
        }
      }
    }

    this.timerSubscription.unsubscribe();
  }

  // scorePercent2(params: any): void {
  //   let data = params.data.level;
  //   let counter = 0;
  //   const filteredlevel = this.tierMeetingRowData.filter(
  //     (ob) => ob.level === data
  //   );
  //   const totallength = filteredlevel.length - 1;
  //   // tslint:disable-next-line:prefer-for-of
  //   for (let i = 0; i < filteredlevel.length; i++) {
  //     if (filteredlevel[i].auditScope == undefined) {
  //       counter = counter;
  //     } else if (filteredlevel[i].auditScope === 'Yes') {
  //       counter++;

  //       this.score = (counter / totallength) * 100;
  //     }
  //     //const itemArr:any[] = []
  //     // this.TiergridApi.forEachLeafNode((rowNode,index)=>{
  //     // })
  //     this.TiergridApi.forEachLeafNode((node: any) => {
  //     });
  //     // const filterIndex: any = itemArr.filter(
  //     //   (c) => c.rowIndex === 0
  //     // );
  //     // const rowNode = this.gridApi.getRowNode(filterIndex[0].score);
  //     // rowNode.setDataValue('score',this.score);
  //     //  const a = this.tierMeetingRowData.findIndex(c => c.area === data);
  //     // params.node.setDataValue('score', this.score);

  //     // // tslint:disable-next-line:no-non-null-assertion
  //     //  const rowNode = this.TiergridApi.getRowNode(a)!;

  //     //rowNode.setDataValue('score', this.score + '%');

  //     // for(let i = 0;i<this.tierMeetingRowData.length;)
  //   }
  //   const a = this.tierMeetingRowData.findIndex((c) => c.area === data);
  //   // params.node.setDataValue('score', this.score);

  //   // // tslint:disable-next-line:no-non-null-assertion
  //   const rowNode = this.TiergridApi.getRowNode(a)!;
  //   if (rowNode != undefined) {
  //     rowNode.setDataValue('score', this.score + '%');
  //   }

  //   this.countTotalpercent();
  // }
  saveAction() {
    const arr = [];
    let count = 0;
    this.tierMeetingRowData.forEach((elem, i) => {
      if (this.tierMeetingRowData[i].auditScope == undefined) {
        count = count;
      } else {
        arr.push(elem);
      }
    });

    const key = {
      meetingAttendId: this.meetingAttendId,
      meetingDefId: this.selectedMeeting.meetingId,
      totalpercent: this.totalscore.toFixed(2),
      createdBy: JSON.parse(localStorage.user).UserId,
      data: arr,
    };

    this.meetingService.AddTierMeetingChecklist_Data(key).subscribe((res) => {
      this.toaster.success('Success', 'Data Saved Successfuly');

      this.dialog.closeAll();

      this.TierMeetingData = [];
      this.tierMeetingRowData = [];
      this.renderEffectivenessChart(
        this.chartrange.value.fromDate,
        this.chartrange.value.toDate
      );
      this.spinner.show();
      setTimeout(() => {
        // this.GetTierMeetingChecklist_Data();
        this.spinner.hide();
      }, 2000);
    });
  }

  cellClickedKPITable(params: any) {
    let col = params.colDef.field;
    let data = params.data;
    this.currentKPIId = data.KPIId;
    this.kpiName = data['KPI Name'];
    let classList = params.event.target.classList;
    if (col === 'headerId') {
      if (classList.contains('fa-eye') || classList.contains('viewRCA')) {
        params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, params.event)

        // this.openRcaDue(params);
      }
    }
  }
  cellClickedActionTable(params: any) {
    let col = params.colDef.field;

    let classList = params.event.target.classList;
    if (col === 'actions') {
      if (
        classList.contains('mat-icon-no-color') ||
        classList.contains('viewRCA')
      ) {
        this.EditRow(params);
      }
    }
    if (col === 'ClosedFile') {
      if (classList.contains('viewDocument')) {
        this.doc =
          this.blobStorageUrl +
          params.data.ClosedFile;
        const fileType = params.data.ClosedFile.split('.').pop();
        if (
          fileType === 'jpeg' ||
          fileType === 'jpg' ||
          fileType === 'png' ||
          fileType === 'gif'
        ) {
          this.images = [];
          this.fileType = 'img';
          this.images.push(this.doc);
        } else {
          this.fileType = 'doc';
        }
        this.fileDesc = params.data?.KPIName;
        this.displayMaximizable = true;
        // setTimeout(() => {
        //   var containerFullScreenElement = document.getElementById(
        //     'ngx-fs-btn'
        //   ) as HTMLElement;
        //   for (var i = 0; i < containerFullScreenElement.children.length; i++) {
        //     if (
        //       containerFullScreenElement.children[i].classList.contains(
        //         'fa-arrows-alt'
        //       )
        //     ) {
        //       containerFullScreenElement.style.left = '1rem';
        //       containerFullScreenElement.style.top = '18px';
        //       (
        //         document.getElementsByClassName(
        //           'p-dialog-title'
        //         )[0] as HTMLElement
        //       ).style.marginLeft = '2.5rem';
        //       break;
        //     }
        //   }
        // }, 300);
      }
    }
  }

  onRowsSelection(e: any) {
    const length = this.kpigridApi.getSelectedRows().length;
    const KPINameAId = this.kpigridApi.getSelectedRows().map((kpi) => {
      return {
        KPIId: kpi.KPIId,
        KPIName: kpi['KPI Name'],
      };
    });
    if (length === 1) {
      let KPIIds = this.kpigridApi.getSelectedRows().map((row) => row.KPIId);
      KPIIds = KPIIds.join(',');
      this.getKPIsTrend(KPIIds, length);
    }
    if (length > 1) {
      let KPIIds = this.kpigridApi.getSelectedRows().map((row) => row.KPIId);
      KPIIds = KPIIds.join(',');
      this.getKPIsTrend(KPIIds, length, KPINameAId);
    }
  }

  openRcaDue(params: any) {
    // if (!this.meetingStarted) {
    //   this.toaster.error('Meeting not started yet');
    //   // this.toastrService.warning('Meeting not started yet');
    //   return;
    // }
    // this.analyticalToolsService.setHeaderId(params.data.headerId || 0);

    const key = {
      EGId: this.MeetingMatrixForm.get('group')?.value,
      PlantId: this.MeetingMatrixForm.get('plant')?.value,
    };

    this.kpiservice.getEmployeeMasterData(key).then(
      (users: any) => {
        this.UserDDL = users.employeeInfoList.filter((x: any) => {
          return x.EmpInfo_Id;
        });
        this.UserDDL.forEach((element: any) => {
          element.fullName = element.FirstName + ' ' + element.lastName;
        });
        this.filteredUsers = this.UserDDL.slice();
        this.filteredParticipants = this.UserDDL.slice();
        this.filterBanksMulti();
        const dialogRef = this.dialog.open(AtStagesNcrComponent, {
          height: '100%',
          width: '100%',
          panelClass: 'full-screen-modal-RCA',

          data: {
            tableId: this.selectedMeeting?.meetingId,
            KPIId: this.currentKPIId,
            KPIName: params.data?.['KPI Name'],
            isKPI: true,
            MeetingAttendId: this.currentMeetingID,
            moduleId: 3,
            moduleName: 'MeetingMatrix',
            componentName: 'meeting-matrixV2',
            patchHeader: params?.data?.headerId === 0 ? false : true,
            headerId: 0,
            InitProbStatement: params.data?.['KPI Name'],
            meetingName: this.selectedMeeting?.meetingName,
            Group: this.MeetingMatrixForm.get('group')?.value,
            Plant: this.MeetingMatrixForm.get('plant')?.value,
            hideTabs: true,
            leaderId: this.selectedMeeting?.leaderId,
            participants: this.meetingParticipants,
            rcaLayoutType: params?.layout

          },
        });

        dialogRef.afterClosed().subscribe((data) => {

          this.GetKPITargetActual();
          this.getCurrentMeetingActions();
          this.getOverallMeetingActions();
        });
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  openRcaForActions() {
    // if (!this.meetingStarted) {
    //   this.toastrService.warning('Meeting not started yet');

    //   return;
    // }

    const key = {
      EGId: this.MeetingMatrixForm.get('group')?.value,
      PlantId: this.MeetingMatrixForm.get('plant')?.value,
    };

    this.kpiservice.getEmployeeMasterData(key).then((users: any) => {
      this.UserDDL = users.employeeInfoList.filter((x: any) => {
        return x.EmpInfo_Id;
      });
      this.UserDDL.forEach((element: any) => {
        element.fullName = element.FirstName + ' ' + element.lastName;
      });
      this.filteredUsers = this.UserDDL.slice();
      this.filteredParticipants = this.UserDDL.slice();
      this.filterBanksMulti();

      let data = {
        tableId: this.selectedMeeting.meetingId,
        MeetingAttendId: this.currentMeetingID,
        headerId: this.nodeHeaderId,
        patchHeader: this.nodeHeaderId ? true : false,
        moduleId: 3,
        isActions: true,
        moduleName: 'MeetingMatrix',
        componentName: 'meeting-matrixV2',
        hideTabs: true,
        meetingName: this.selectedMeeting.meetingName,
        Group: this.MeetingMatrixForm.get('group')?.value,
        Plant: this.MeetingMatrixForm.get('plant')?.value,
        leaderId: this.selectedMeeting.leaderId,
        participants: this.meetingParticipants,
      };
      if (!this.nodeHeaderId) {
        this.analyticalToolsService.setHeaderId(0);
        const dialogRef = this.dialog.open(AtStagesNcrComponent, {
          height: '100%',
          width: '90%',
          panelClass: 'full-screen-modal-RCA',
          data,
        });

        dialogRef.afterClosed().subscribe((data) => {
          this.nodeHeaderId = this.analyticalToolsService.getHeaderId();
          this.getCurrentMeetingActions();
          this.getOverallMeetingActions();
        });
      } else {
        this.analyticalToolsService.setHeaderId(this.nodeHeaderId);
        const dialogRef = this.dialog.open(AtStagesNcrComponent, {
          height: '100%',
          width: '90%',
          panelClass: 'full-screen-modal-RCA',
          data,
        });

        dialogRef.afterClosed().subscribe((data) => {
          this.getCurrentMeetingActions();
          this.getOverallMeetingActions();
        });
      }
    });
  }
  openActionPlan(params: any) {
    this.editActionPlanForm.patchValue(params.data);
    const dialogRef = this.dialog.open(this.actionPlanModal, {
      data: {
        params,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.getOverallMeetingActions();
    });
  }

  getContextMenuItems = (params: any) => {
    const col = params.column.colId;
    if (col !== 'headerId') return;
    this.analyticalToolsService.setHeaderId(0);

    const result = [
      {
        // custom item
        name: 'Action',
        action: () => {
          // this.openRcaDue({ data: params.node.data, layout: 'Action' })
          this.openModal();
        },
        cssClasses: ['redFont', 'bold'],
      },
      {
        // custom item
        name: 'RCA',
        action: () => {
          this.currentKPIId = params.node.data?.KPIId;

          this.openRcaDue({ data: params.node.data, layout: 'RCA' })

        },
        cssClasses: ['redFont', 'bold'],
      },


    ];
    return result;
  }

  getContextMenuItems2 = (params: any) => {
    const col = params.column.colId;

    this.analyticalToolsService.setHeaderId(0);

    const result = [

      {
        // custom item
        name: 'RCA',
        action: () => {
          this.currentKPIId = params.node.data?.KPIId;

          this.openRcaDue({ data: params.node.data, layout: 'RCA' })

        },
        cssClasses: ['redFont', 'bold'],
      },


    ];
    return result;
  }

  deleteMeetingAction(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          meetingActionId: this.meetingActionId,
        };
        this.meetingaction.deleteMeetingAction(key).subscribe(
          (res: any) => {
            Swal.fire(
              'Deleted!',
              'Your selected Meeting action has been deleted.',
              'success'
            );
            const key = {
              meetingDefId: this.selectedMeeting.meetingId,
            };
            this.meetingService.getMeetingAction(key).subscribe((data) => {
              for (var d of data) {
                if (d.newtargetdate != null && d.newtargetDate != '') {
                  d.newtargetdate = moment(d.newtargetdate).format(
                    'DD/MM/YYYY HH:mm:ss'
                  );
                }
                if (d.completiondate != null && d.completiondate != '') {
                  d.completiondate = moment(d.completiondate).format(
                    'DD/MM/YYYY HH:mm:ss'
                  );
                }
              }
              this.rowData1 = data;
              this.MatrixForm.get('meetingDefName')?.setValue(
                this.selectedMeeting.meetingName
              );
              this.MatrixForm.get('meetingDefId')?.setValue(
                this.selectedMeeting.meetingId
              );
            });
          },
          (error: any) => {
            this.toastrService.success('Error', error.message);
          }
        );
      }
    });
  }

  ngOnInit(): void {
    this.getGroupData();
    this.GetBreadcrumList();
    this.initialdisplay();
    this.GetMenuAccess();
    this.createEditActionPlanForm();
    this.selectedIndex = 0;
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 2, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.KPIAnalysisForm = this.fb.group({
      fromDate: [firstDay, Validators.required],
      toDate: [lastDay, Validators.required],
    });
    var firstDayfincialGap = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7
    );
    var lastDayfincialGap = new Date();
    this.rangeFinacialGap.get('fromDate').setValue(firstDayfincialGap);
    this.rangeFinacialGap.get('toDate').setValue(lastDayfincialGap);
    this.chartrange.get('fromDate').setValue(firstDayfincialGap);
    this.chartrange.get('toDate').setValue(lastDayfincialGap);
    this.range;
    this.firstDayfincialGap = firstDayfincialGap;
    this.lastDayfincialGap = lastDayfincialGap;
    this.talksheet.get('fromDate').setValue(firstDayfincialGap);
    this.talksheet.get('toDate').setValue(lastDayfincialGap);

    //Today: [new Date(), new Date()],
    //Yesterday: [startOfYesterday(), new Date()],

    this.KPIComparisonForm = this.fb.group({
      fromDate: [firstDay, Validators.required],
      toDate: [lastDay, Validators.required],
    });

    // this.getKPIAnalysisFormChanges();
    this.getKPIComparisonFormChanges();



    // this.rangeFinacialGap.valueChanges.subscribe((res) => {
    //   if (res.fromDate && res.toDate) {
    //     this.getFinancialGapList(res.fromDate, res.toDate);
    //     this.KPIAnalysisForm.patchValue({
    //       fromDate: moment(
    //         new Date(new Date(res.fromDate).setHours(0, 0, 0, 0))
    //       ).format('YYYY-MM-DD HH:mm:ss'),
    //       toDate: moment(
    //         new Date(new Date(res.toDate).setHours(0, 0, 0, 0))
    //       ).format('YYYY-MM-DD HH:mm:ss'),
    //     }, {
    //       emitEvent: false
    //     });

    //     // this.getGridDataByFrequency();x
    //   }
    // });
    this.chartrange.valueChanges.subscribe((res) => {
      if (res.fromDate && res.toDate) {
        this.renderprogressChart(res.fromDate, res.toDate);
      }
    });

    this.ManualParticepantForm = this.fb.group({
      participant: [[], Validators.required],

    });




    this.MeetingMatrixFrequencyForm = this.fb.group({
      frequency: ['', Validators.required],
      dateRange: [this.defaultDateRange, Validators.required],
      tsfrequency: ['', Validators.required],
      fromDate: new FormControl(),
      toDate: new FormControl(),
    });

    this.ExtendMeetingForm = this.fb.group({
      duration: ['', Validators.required],
      reason: ['', Validators.required],
    });
    this.MeetingMatrixTalkSheetForm = this.fb.group({
      frequency: ['', Validators.required],
      dateRange: [this.defaultDateRange, Validators.required],
      tsfrequency: ['', Validators.required],
      fromDate: new FormControl(),
      toDate: new FormControl(),
    });

    this.MeetingMatrixForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      search: [''],
    });
    this.MatrixForm = this.fb.group({
      meetingDefId: ['', Validators.required],
      meetingDefName: ['', Validators.required],
      action: [''],
      kpi: [''],
      responsible: ['', Validators.required],
      // Accountable: ['', Validators.required],
      targetdate: ['', Validators.required],
    });

    // this.AddAgendaForm = this.fb.group({
    //   addagendas: this.fb.array([this.createAgendaForm()]),
    // });
    this.AddAgendaForm = this.fb.group({
      agenda: ['', Validators.required],
    });
    this.AddNotesForm = this.fb.group({
      notes: ['', Validators.required],
    });
    this.agendaFormArray = this.AddAgendaForm.get('addagendas') as FormArray;

    this.meetingSuggestedActionForm = this.fb.group({
      action: ['', Validators.required],
      completionDate: ['', Validators.required],
      responsibleId: ['', Validators.required],
    });
    const today = new Date().getFullYear();
    this.startYear = new Date(today, 0);
    this.endYear = new Date(today, 11, 31);
    var date = new Date();

    this.range.get('fromDate').setValue(firstDayfincialGap);
    this.range.get('toDate').setValue(lastDayfincialGap);
    const days = 7;
    var firstDaytalkSheet = new Date(
      date.getTime() - days * 24 * 60 * 60 * 1000
    );
    this.MeetingMatrixTalkSheetForm.get('fromDate').setValue(firstDaytalkSheet);
    this.MeetingMatrixTalkSheetForm.get('toDate').setValue(new Date());

    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      // colors: [this.getRandomColor(), this.getRandomColor()],
      colors: [
        '#00008B',
        '#34A56F',
        '#F80000',
        '#2D0A77',
        '#8C001A',
        '#FF69B4',
        '#FA4A08',
        '#5453A6',
        '#8B008B',
        '#3090C7',
        '#F1C40F',
        '#CB4335',
        '#36454F',
        '#A569BD',
        '#FFA500',
        '#179fcc',
        '#093323',
        '#08fa03',
        '#048b8e',
      ],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        text: 'Evaluation',
        align: 'left',
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: [],
        title: {
          text: 'Meeting Evalutions',
        },
      },
      yaxis: {
        title: {
          text: 'Total',
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    };
    this.filteredBanksMulti.next(this.filteredParticipants);
    this.filterBanksMulti();
    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
      });

    this.sales;

    this.calendarMeetingId = 0;
    this.calendarPlantId = 1;
    this.createAddActionPlanForm();
  }

  ngAfterViewInit() {
    this.range.valueChanges.subscribe((res) => {
      if (res.fromDate && res.toDate) {
        // this.getGridDataByFrequency();
        this.synchronizeDateWithAnalysisAndComparisonForm(
          res.fromDate,
          res.toDate,
          false
        );
      }
    });
    this.MeetingMatrixFrequencyForm.valueChanges.subscribe((res) => {
      if (res.fromDate && res.toDate) {
        this.synchronizeDateWithAnalysisAndComparisonForm(
          res.fromDate,
          res.toDate,
          false
        );
      }
    });
  }

  createEditActionPlanForm() {
    this.editActionPlanForm = this.fb.group({
      id: [''],
      remarks: [''],
      closefile: [''],
      revisedDate: [''],
      actions: [''],
      assignTo: [''],
      completed: ['No'],
      verification: [''],
      actionType: [''],
      targetDate: [''],
      NewAssignee: [''],
      rootcause: ['']
    });
  }

  createStockChart(data: any = []) {
    this.stock = new StockChart({
      chart: {
        reflow: true,
      },

      rangeSelector: {
        selected: 1,
      },
      title: {
        text: '',
      },
      series: [
        {
          tooltip: {
            valueDecimals: 2,
          },
          name: 'AAPL',
          type: 'line',
          data: data,
        },
      ],
    });
  }

  getKPIComparisonFormChanges() {

  }
  public checkedMeetingStatus() {
    const key = {
      MeetingDefId: this.selectedMeeting?.meetingId,
    };

    this.meetingService.CheckedMeetingStatus(key).subscribe((response: any) => {
      this.currentMeetingID = response[0].MeetingAttendId;
      this.getCurrentMeetingActions();

      if (response[0].MeetingEndTime === null) {
        if (response[0].Second > 60) this.minutes = this.minutes + 1;
        else this.seconds = response[0].Second;
        if (response[0].Minute > 60) this.minutes = this.minutes + 1;
        else this.minutes = response[0].Minute;
        this.hours = response[0].Hour;

        this.interval = setInterval(() => {
          this.seconds = this.seconds + 1;
          if (this.seconds === 60) {
            this.minutes = this.minutes + 1;
            this.seconds = 0;
          }
          if (this.minutes === 60) {
            this.hours = this.hours + 1;
            this.minutes = 0;
            this.seconds = 0;
          }
          this.timer =
            ('0' + this.hours).slice(-2) +
            ':' +
            ('0' + this.minutes).slice(-2) +
            ':' +
            ('0' + this.seconds).slice(-2);



        }, 1000);

        this.pausebutton = true;
        this.meetingStarted = true;
        if (this.LeaderId > 0) {
          this.enabledopenTierMeeting = true;
        }

        // this.enabledopenTierMeeting = true;
        this.meetingStartTime = response[0].MeetingStartTime;
      } // EndDate Close
    }); // Api Close
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1,
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  getParticipantsOnPlant() {
    let data = {
      EGId: this.MeetingMatrixForm.value.group,
      PlantId: this.MeetingMatrixForm.value.plant,
    };

    this.commonService.getPeopleMasterData(data).subscribe(
      (response: any) => {
        if (!response.HasError) {
          // this.orgMachineryList.map((machinery: any) => this.machineryList.push([...this.mList.machineryList]));
          this.participantsByPlants = response.employeeInfoList;
          this.participantsByPlants.map(
            (user: any) =>
            (user.FullName =
              user.FirstName.trim() + ' ' + user.lastName.trim())
          );
          this.userList = this.participantsByPlants.slice();
        }
      },
      (err: any) => {

      }
    );
  }

  getRandomColor() {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    //document.body.style.backgroundColor = "#" + randomColor;
    var color = '#' + randomColor;
    return color;
  }

  get repeatAgendaForm(): any {
    return this.AddAgendaForm.get('addagendas') as FormArray;
  }

  chartCallback: Highcharts.ChartCallbackFunction = (chart: any): void => {
    this.chartRef = chart;
    setTimeout(() => {
      this.chartRef.reflow();
    }, 0);
  };

  getActionChart(): void {
    this.options = {
      chart: {
        type: 'column',
        renderTo: 'actionChart',
        events: {
          redraw: function (e) {
            this.options?.reflow();
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: 'Open Vs Closed Actions',
      },
      xAxis: {
        categories: this.chartData.categories,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Action',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },

      series: this.chartData.series,
    };
    let chart = new Chart(this.options);
    this.chart = chart;
  }

  getsalesChart(): void {
    this.options = {
      chart: {
        type: 'column',
        renderTo: 'actionChart',
        events: {
          redraw: function (e) {
            this.options.reflow();
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: 'Top 5 Sales By Representative',
      },
      xAxis: {
        categories: ['javed', 'riaz', 'mateen', 'farrukh', 'usman'],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Action',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: 'Quantity',
          data: [84385, 71454, 66222, 50643, 14467],
          color: '#0f04dd',
        },
      ],
    };
    let sales = new Chart(this.options);
    this.sales = sales;
  }
  getpieChart(): void {
    this.options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Sales Yearly Performance',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
        },
      },
      series: [
        {
          name: 'Countries',
          colorByPoint: true,
          data: [
            {
              name: 'Europe',
              y: 61.41,
              sliced: true,
              selected: true,
            },
            {
              name: 'Asia',
              y: 11.84,
            },
            {
              name: 'North America',
              y: 10.85,
            },
          ],
        },
      ],
    };
    let pie = new Chart(this.options);
    this.piechart = pie;
  }
  getlinechart(): void {
    this.options = {
      title: {
        text: 'Sales-Yearly Performance',
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: 2010,
        },
      },

      series: [
        {
          name: 'Sales',
          data: [43934, 52503, 57177, 69658],
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
    let line = new Chart(this.options);
    this.lineChart = line;
  }
  gettopsalesChart(): void {
    this.options = {
      chart: {
        type: 'column',
        renderTo: 'actionChart',
        events: {
          redraw: function (e) {
            this.options.reflow();
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: 'Top 5 Sales',
      },
      xAxis: {
        categories: ['Usman', 'Ahmad', 'Mateen', 'Farukh', 'Riaz'],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Action',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: 'Quantity',
          data: [84385, 71454, 66222, 50643, 14467],
          color: '#1bf98e',
        },
      ],
    };
    let topsales = new Chart(this.options);
    this.topsales = topsales;
  }

  getbarChart(): void {
    this.options = {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: ['Africa', 'America', 'Asia', 'Europe', 'Oceania'],
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Population (millions)',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
      },
      tooltip: {
        valueSuffix: ' millions',
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Year 2020',
          data: [107, 31, 635, 203, 2],
        },
      ],
    };
    let bar = new Chart(this.options);
    this.bar = bar;
  }

  getMasterShift(): void {
    const key = {
      plantId: this.MeetingMatrixForm.get('plant')?.value,
    };
    this.meetingService.getMasterShifts(key).subscribe(
      (data) => {
        this.shiftData = data;
        // this.shiftData.forEach((c=>))
        this.shiftData.forEach((element) => {
          element.startTime = new Date().setTime(element.startTime);
          element.endTime = new Date().setTime(element.endTime);
        });
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  createAgendaForm(): FormGroup {
    return this.fb.group({
      agenda: ['', Validators.required],
    });
  }

  GetMeetingQuestionLine(): void {
    const key = {
      MeetingId: this.selectedMeeting.meetingId,
    };
    this.meetingService.GetMeetingQuestionLine(key).subscribe(
      (res) => {
        this.lines = res;
        this.GetMeetingQuestionByLine();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetMeetingStatus(): void {
    const key = {
      meetingDefId: this.selectedMeeting?.meetingId,
    };
    this.meetingService.getMeetingStatus(key).subscribe((res) => {
      this.MeetingStatus = res;
    });
  }

  GetMeetingQuestionByLine(): void {
    this.rowDatasscoreCard = [];

    const key = {
      MeetingId: this.selectedMeeting.meetingId,
      LineId: !this.selectedLine ? this.lines[0]?.PL_Id : this.selectedLine,
    };
    this.meetingService.GetMeetingQuestionByLine(key).subscribe(
      (res) => {
        this.questionData = res;

        // getting thte unique areas from response
        const uniqueAreas = [
          ...new Set(
            res?.map((item: any) => {
              return item.Area;
            })
          ),
        ];

        // formate data according to the ag grid
        const formattedArray: any[] = [];
        uniqueAreas.forEach((area) => {
          const filteredArray = res?.filter((x: any) => x?.Area === area);
          if (filteredArray.length > 0) {
            filteredArray.forEach((meeting: any) => {
              const areafound = formattedArray.findIndex((item: any) => {
                return item?.area === area;
              });
              if (areafound === -1) {
                formattedArray.push({
                  area: area,
                  questions: meeting?.Question,
                  unit: meeting?.UnitName,
                  target: meeting?.Target,
                  status: meeting?.Status,
                  remarks: meeting?.Remarks,
                  Action: meeting?.Action,
                  unitId: meeting?.Unit,
                  totalcount: filteredArray?.length,
                });
              } else {
                formattedArray.push({
                  questions: meeting?.Question,
                  unit: meeting?.UnitName,
                  target: meeting?.Target,
                  status: meeting?.Status,
                  remarks: meeting?.Remarks,
                  Action: meeting?.Action,
                  unitId: meeting?.Unit,
                });
              }
            });
          }
        });
        // setting formatted data in ag grid
        this.rowDatasscoreCard = formattedArray;
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }

  GetKPITargetActual(): void {
    const plantId = this.MeetingMatrixForm.get('plant')?.value;
    const frequencyId = this.frequencies[0].frequencyId;

    const key = {
      KPIId: this.selectedKpi,
      meetingAttendId: this.currentMeetingID,
      fromDate: this.f.fromDate.value,
      toDate: this.f.toDate.value,
      plantId,
      frequencyId,
      meetingDefId: this.selectedMeeting.meetingId,
    };


    this.meetingService.getScorecardMeetingMatrix(key).subscribe((res: any) => {

      this.rowDatakpi = res.map((x: any) => {
        return { ...x, headerId: x.headerId };
      });
      // this.columnDefskpi = this.generateColumnsV2(res);
      setTimeout(() => {
        this.enableGlidePathChangeV2({ checked: false });
        this.headerBreaker();
        this.kpigridApi.resetColumnState();
        this.autoSizeAllColumns(this.kpigridColumnApi);
      }, 1000);
    });
  }

  enableGlidePathChangeV2(event: any): void {

    const instance = this.kpigridApi.getFilterInstance('Type');

    let Type = event.checked ? ['Glidepath', 'Actual'] : ['Actual'];

    let myArray = Type;
    instance?.setModel({
      values: myArray.length ? myArray : [],
    });
    this.kpigridApi?.onFilterChanged();
    this.enableGlidePathTalkSheet = event.checked;
  }

  onSearchScorecard() {

    if (
      this.MeetingMatrixTalkSheetForm.controls.dateRange.status === 'INVALID'
    ) {
      this.toaster.error('Please select a valid date range');
      return;
    }
    if (
      this.MeetingMatrixTalkSheetForm.controls.tsfrequency.value.frequencyId ===
      undefined
    ) {
      this.toaster.error('Please select a valid frequency');
      return;
    }
    this.scorecardData.fromDate = moment(new Date(new Date(this.f.fromDate.value).setHours(0, 0, 0, 0))).utc();

    this.scorecardData.toDate = moment(new Date(new Date(this.f.toDate.value).setHours(23, 59, 59, 0))).utc();
    this.scorecardData.plantId = this.MeetingMatrixForm.get('plant')?.value;
    this.scorecardData.kpiId = this.selectedKpi;
    this.scorecardData.frequencyId =
      this.MeetingMatrixTalkSheetForm.controls.tsfrequency.value.frequencyId;
    this.scorecardData.meetingAttendId = this.meetingStoped
      ? 0
      : this.currentMeetingID;
    this.scorecardData.meetingDefId = this.selectedMeeting.meetingId;
    //this.MeetingMatrixFrequencyForm.value.stfrequency.frequencyId;
    this.meetingService.getScorecardMeetingMatrix(this.scorecardData).subscribe(
      (response: any) => {
        this.rowDatakpi = response.map((x: any) => {
          return { ...x, headerId: x.headerId };
        });
        this.rowDatakpi.forEach((element) => {
          element!.Line = !element?.Line ? 'UnNamed' : element?.Line;
        });
        this.columnDefskpi = this.generateColumnsV2(this.rowDatakpi);
        setTimeout(() => {
          // this.kpigridApi.getDisplayedRowAtIndex(0)?.setSelected(true);
          this.enableGlidePathChangeV2({ checked: false });
          this.kpigridColumnApi.resetColumnState();
          this.autoSizeAllColumns(this.kpigridColumnApi);
        }, 1000);
      },
      (err: any) => {
      }
    );

  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.meetingService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.filteredGroupListDDL = this.groupListDDL.slice();
        this.MeetingMatrixForm.get('group')?.setValue(
          this.groupListDDL[0].TypeId
        );
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  rowSpan(params: any): any {
    if (params.data.area) {
      return params.data.totalcount;
    } else {
      return 1;
    }
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.MeetingMatrixForm.get('group')?.value,
    };
    this.meetingService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList.filter((p: any) => p.DeptDesc);
        this.filteredPlants = this.plants.slice();
        this.MeetingMatrixForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getFrequencyOnMeeting();
        this.getMasterShift();

        // this.GetTierMeetingChecklist_Data();
        this.calendarPlantId = this.plants[0].DeptId;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getFrequency(): void {
    this.scoreCardGridApi?.showLoadingOverlay();
    const key = {
      meetingDefId: this.meetingDefId2,
    };
    this.meetingService.getFrequency(key).subscribe((res) => {
      this.frequencies = res;
      const freqObj = this.frequencies[0];
      this.MeetingMatrixFrequencyForm.get('tsfrequency').setValue(freqObj);
      this.MeetingMatrixFrequencyForm.get('frequency')?.setValue(freqObj);
      this.MeetingMatrixTalkSheetForm.get('tsfrequency')?.setValue(freqObj);

      this.date = new Date();
      // const latestdate = this.date;
      const latestdate = new Date(this.range.value.toDate).setHours(
        23,
        59,
        59,
        59
      );
      const key = {
        frequencyId:
          this.MeetingMatrixFrequencyForm.value.frequency.frequencyId,
        kpiId: this.MeetingMatrixFrequencyForm.value.frequency.kpiId,
        frequencyName:
          this.MeetingMatrixFrequencyForm.value.frequency.frequencyName,
        plantId: this.MeetingMatrixForm.get('plant')?.value,
        // date: latestdate,
        fromDate: moment(this.range.value.fromDate).utc(),
        toDate: moment(latestdate).utc(),
        meetingDefId: this.selectedMeeting.meetingId,
      };
      this.scoreCardGridApi.showLoadingOverlay();


      this.meetingService.getScoreCardMeetingMatrix(key).subscribe(
        (data: any) => {
          this.ScoreCardDataTemp = data;
          this.createScoreCardData(this.ScoreCardDataTemp);
          this.onSearchScorecard();
        },
        (error: any) => {
          this.toastrService.error(error.message);
        }
      );
      //
    });
  }

  setFirstWeek(): void {
    const dept = this.plants.find(item => item.DeptId === this.MeetingMatrixForm.value.plant);
    this.firstDayOfWeek = dept ? dept.firstDayOfWeek : 0;
    this.setWeekValue(this.firstDayOfWeek);
  }

  getFrequencyOnMeeting(): void {
    this.calendarPlantId = this.MeetingMatrixForm.get('plant')?.value;
    this.setFirstWeek();
    // this.fullCalendarComponent.GetMeetingRecurrence();

    // const key = {
    //   plantId: this.MeetingMatrixForm.get('plant')?.value,
    // };
    // this.meetingService.getMeetingByFrequency(key).subscribe((data: any) => {
    //   this.meetingOriginalArray = data;
    //   const frequencies = [
    //     ...new Set(data.map((c: { frequencyName: any }) => c.frequencyName)),
    //   ];
    //   const formattedMeetingsArray: any = [];
    //   frequencies.forEach((freq: any) => {
    //     const obj = {
    //       frequencyName: freq,

    //       meetings: data
    //         ?.filter((x: any) => x.frequencyName === freq)
    //         .map((item: any) => {
    //           return {
    //             meetingId: item?.meetingId,
    //             meetingName: item?.meetingName,
    //             kpiName: item?.kpiName,
    //             frequencyId: item?.frequencyId,
    //             frequencyName: item?.frequencyName,
    //             meetingMasterId: item?.meetingMasterId,
    //             meetingType: item?.type,
    //             headerId: item.headerId,
    //           };
    //         }),
    //     };
    //     formattedMeetingsArray.push(obj);
    //   });
    //   this.meetingsArray = formattedMeetingsArray;
    //   this.filtereMeetings = this.meetingsArray;
    // });
    // this.search = '';
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        this.columnDefs1 = [
          {
            field: 'xyz',
            sortable: true,
            filter: true,
            headerName: 'Actions',
            hide: !this.IsWrite,
            //  cellRenderer: 'btnCellRendererNCR',
            cellRenderer: (params: any): any => {
              this.meetingActionId = params.data.meetingActionId;

              if (params.data.Status === 'open') {
                return `
            <div>
           
             <button mat-mini-fab="" color="primary" ptooltip="Edit" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary"  ng-reflect-color="info" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-edit" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
           
            <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
           
           </div>
           
           `;
              }
            },
            cellRendererParams: {
              clicked(field: any) { },
            },
            maxWidth: 100,
            minWidth: 80,
            cellStyle: { textAlign: 'center' },
          },
          {
            field: 'action',
            headerName: 'Action',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'responsible',
            headerName: 'Responsible',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          // {
          //   field: 'accountable',
          //   headerName: 'Accountable',
          //   sortable: true,
          //   resizable: true,
          //   wrapText: true,
          // },
          {
            field: 'targetdate',
            headerName: 'Target Date',
            editable: true,
            sortable: true,
            resizable: true,
            wrapText: true,
            cellRenderer: (data: any) => {
              return data.value
                ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
                : '';
            },
          },
          {
            field: 'newtargetdate',
            headerName: 'New Target Date',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: 'agDateColumnFilter',
            cellEditor: 'primeCellEditor',
            editable: this.IsWrite
              ? (params: any) => params.data.Status !== 'Closed'
              : false,
            filterParams: {
              comparator: myDateComparator,
            },
            cellStyle: {
              color: 'black',
              'background-color': '#EDF1E5',
            },
          },
          {
            field: 'misses',
            headerName: '# Misses',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'completiondate',
            headerName: 'Completion Date',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: 'agDateColumnFilter',
            cellEditor: 'primeCellEditor',
            editable: this.IsWrite
              ? (params: any) => params.data.Status !== 'Closed'
              : false,
            filterParams: {
              comparator: myDateComparator,
            },
            cellStyle: {
              color: 'black',
              'background-color': '#EDF1E5',
            },
          },
          {
            field: 'Status',
            sortable: true,
            filter: true,
            resizable: true,
            wrapText: true,
            editable: this.IsWrite
              ? (params: any) => params.data.Status !== 'Closed'
              : false,
            cellRenderer: 'agCellRenderer',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              cellRenderer: 'genderCellRenderer',
              values: ['Closed', 'In Progress'],
            },
            cellStyle: (params: { value: string }) => {
              if (params.value === 'Closed') {
                return {
                  color: 'black',
                  backgroundColor: '#38cf36',
                  'font-weight': 'bold',
                };
              } else if (params.value === 'In Progress') {
                return {
                  color: 'black',
                  backgroundColor: 'yellow',
                  'font-weight': 'bold',
                };
              } else if (params.value === 'Delayed') {
                return {
                  color: 'black',
                  backgroundColor: '#eb3d3d',
                  'font-weight': 'bold',
                };
              } else {
                return {
                  color: 'black',
                  backgroundColor: '#11ced1',
                  'font-weight': 'bold',
                };
              }
            },
          },
        ];

        this.actionscolumnDefs = [
          {
            field: 'Module',
            headerName: 'Module',
            sortable: true,
            resizable: true,
            filter: true,
          },
          {
            field: 'ActionId',
            headerName: 'Unique Code',
            sortable: true,
            resizable: true,
            filter: true,
            wrapText: true,
            cellRenderer: (params: any) => {
              if (params) {
                return `<div style="margin-top: 10px;">
            ${params.data.Module} -
                ${params.value}
              </div>`;
              }
            },
          },
          {
            field: 'CreatedOn',
            headerName: 'Observed Date',
            sortable: true,
            resizable: true,
            filter: true,
          },
          {
            field: 'Observation',
            headerName: 'Observation',
            sortable: true,
            resizable: true,
            filter: true,
          },
          {
            // field: 'ObservationStatus',
            field: 'Status',
            headerName: 'Status',
            sortable: true,
            resizable: true,
            filter: true,
            editable: true,
            cellRenderer: 'agCellRenderer',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              cellRenderer: 'genderCellRenderer',
              values: ['Open', 'Delayed', 'Inprogress', 'Closed', 'ReOpened'],
            },
          },
          {
            // field: 'ActionName',
            field: 'action',
            headerName: 'Actions (RCA & others)',
            sortable: true,
            resizable: true,
            filter: true,
          },
          {
            // field: 'ResponsibleName',
            field: 'responsible',
            headerName: 'Responsible',
            sortable: true,
            resizable: true,
            filter: true,
          },
          {
            field: 'CreatedOn',
            headerName: 'Assigned Date',
            sortable: true,
            resizable: true,
            filter: true,
            cellRenderer: (data: any) => {
              return data.value
                ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
                : '';
            },
          },
          {
            // field: 'TargetDate',
            field: 'targetdate',
            headerName: 'Target Date',
            sortable: true,
            resizable: true,
            filter: true,
            cellRenderer: (data: any) => {
              return data.value
                ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
                : '';
            },
          },
          {
            // field: 'NewTargetDate',
            field: 'newtargetdate',
            headerName: 'New Target Date',
            sortable: true,
            resizable: true,
            filter: true,
          },
          {
            // field: 'NoOfMisses',
            field: 'misses',
            headerName: 'No of Misses',
            sortable: true,
            resizable: true,
            filter: true,
          },
          {
            field: 'CompletedBy',
            headerName: 'Action Completed By & On',
            sortable: true,
            resizable: true,
            filter: true,
            wrapText: true,
            cellRenderer: (params: any) => {
              if (params) {
                return `<div style="margin-top: 10px;">
                ${params.value ? params.value : ''} -
                ${params.data.CompletedOn ? params.data.CompletedOn : ''} 
              </div>`;
              }
            },
          },

          {
            field: 'IsVerified',
            headerName: 'Verification',
            sortable: true,
            resizable: true,
            filter: true,
            // editable: true,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: ['Approved', 'Disapproved'],
            },
            cellRenderer: (params: any) => {
              if (params) {
                if (params.value === true) {
                  return 'Approved';
                }
                if (params.value === false) {
                  return 'Disapproved';
                }
              }
            },
          },
          {
            field: 'VerifiedBy',
            headerName: 'Verified By & Date',
            sortable: true,
            resizable: true,
            filter: true,
            wrapText: true,
            cellRenderer: (params: any) => {
              if (params) {
                return `<div style="margin-top: 10px;">
                ${params.value} -
                ${params.data.VerifiedOn} 
              </div>`;
              }
            },
          },
        ];
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  openSideModal(): void {
    this.SideModelVisible = true;
  }
  saveMeetingAction(): void {
    var meetingActionId = this.meetingActionId;

    // if (this.meetingStarted === true) {
    const MeetingMatrix = {
      meetingDefId: this.MatrixForm.value.meetingDefId,
      action: this.MatrixForm.value.action,
      responsible: this.MatrixForm.value.responsible,
      targetdate: moment(this.MatrixForm.value.targetdate).format(
        'YYYY-MM-DD'
      ),
      meetingActionId,
      status: 'open',
      kpi: this.isKpiAddToAction ? this.kpiId : null,
      // Accountable: this.MatrixForm.value.Accountable,
    };
    if (this.formMode === true) {
      this.meetingService.updateMeetingAction(MeetingMatrix).subscribe(
        (res) => {
          this.toaster.success(
            'success',
            'Meeting Action Updated Successfully'
          );
          this.resetForm();
          this.formMode = false;
        },
        (error) => {
          this.toaster.error('error', error.error.ErrorMessage);
        }
      );
    } else {
      this.meetingService.addMeetingAction(MeetingMatrix).subscribe(
        (res) => {
          this.toaster.success(
            'success',
            'Meeting Action Added Successfully'
          );
          this.resetForm();
        },
        (error) => {
          this.toaster.error('error', error.console.error.ErrorMessage);
        }
      );
    }
    // } else {
    //   this.toaster.warning('First Start Meeting');
    // }
  }
  GetTierMeetingChecklist_Data(event: any): void {
    this.scoreArray = [];
    this.meetingAttendId = event.data.meetingAttendId;
    const key = {
      plantId: this.MeetingMatrixForm.get('plant')?.value,
      meetingAttendId: this.meetingAttendId,
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.meetingService
      .GetTierMeetingChecklist_Data(key)
      .subscribe((res: any) => {
        //this.TierMeetingData = res;

        res.forEach((elem) => {
          const data = elem ? JSON.parse(elem.Detail) : [];
          data.forEach((element) => {
            this.TierMeetingData.push(element);
          });
        });
        const array = this.TierMeetingData;

        const newarr = [...new Set(array.map((item) => item.area))];
        const arr = [];
        const filteredData = array.filter(
          (value, index, self) =>
            self.findIndex(
              (v) => v.area === value.area && v.areapercent != '0.00'
            ) === index
        );

        newarr.forEach((elem, i) => {
          filteredData.forEach((element, j) => {
            var area_obj = {
              areapercent: Math.round(element.areapercent).toString(),
              section: 'quarters',
              no: (i + 1).toString(),
              area: elem,
              actions: '',
              score: '0',
              level: elem,
            };
            if (elem == element.area) {
              arr.push(area_obj);
              let count = 1;
              array.forEach((data, k) => {
                var question_obj1 = {
                  areapercent: '',
                  no: i + 1 + '.' + count,
                  area: data.AreaQuestion,
                  actions: '',
                  auditScope: data.Actions,
                  score: '',
                  level: elem,
                  QuestionId: data.MECD_Id,
                };
                if (data.area == elem) {
                  arr.push(question_obj1);
                  count = count + 1;
                }
              });
            }
          });
        });
        this.tierMeetingRowData = arr;

        newarr.forEach((elem, i) => {
          let data = {
            data: { level: elem },
          };
          const key = {
            level: elem,
          };

          this.scoreArray.push(key);
          //  this.scorePercent2(data);
          //  this.scorePercent(data)
        });

        this.FunctionExutiontime();
      }); //
  }
  FunctionExutiontime() {
    this.timerSubscription = timer(0, 10000)
      .pipe(
        map(() => {
          for (let i = 0; i < this.scoreArray.length; i++) {
            let data = {
              data: { level: this.scoreArray[i].level },
            };
            this.scorePercent(data);
          }
        })
      )
      .subscribe();
  }

  resetForm(): void {
    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
    };
    this.getUserList();
    this.meetingService.getMeetingAction(key).subscribe((data) => {
      for (var d of data) {
        if (d.newtargetdate != null && d.newtargetDate != '') {
          d.newtargetdate = moment(d.newtargetdate).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        }
        if (d.completiondate != null && d.completiondate != '') {
          d.completiondate = moment(d.completiondate).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        }
      }
      this.rowData1 = data;
      this.MatrixForm.get('meetingDefName')?.setValue(
        this.selectedMeeting.meetingName
      );
      this.MatrixForm.get('meetingDefId')?.setValue(
        this.selectedMeeting.meetingId
      );
    });
    this.MatrixForm.get('action')?.setValue('');
  }
  closeSideModel(): void {
    this.SideModelVisible = false;
  }
  initialdisplay(): void {
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.add('content-show');
    wrapper1?.classList.remove('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
  }
  toggleDisplay(
    frequencyId: any,
    kpiName: any,
    kpiId: number,
    frequencyName: any,
    meetingId: number,
    meetingName: string,
    meetingType: any,
    meetingMasterId: number,
    headerId: any,
    meeting: any,
    LeaderId: any,
    MeetingStartTime: any,
    MeetingEndTime: any,
    Duration: any,
    MeetingDate: any
  ): void {
    this.selectedMeeting = {
      meetingId,
      meetingName,
      meetingMasterId,
      meetingType,
      kpis: kpiName,
      leaderId: LeaderId,

      kpiId,
    };

    this.MeetingStartTime = MeetingStartTime;
    this.MeetingEndTime = MeetingEndTime;
    this.MeetingDuration = Duration;
    this.MeetingDate = MeetingDate;
    this.rowData = [];
    this.rowDatakpi = [];
    this.hasEnteredMeeting = true;
    this.meetingDefId2 = meetingId;
    this.selectedKpi = this.selectedMeeting.kpis;
    this.analyticalToolsService.setHeaderId(headerId || 0);
    this.nodeHeaderId = meeting.headerId;
    this.getFrequency();
    this.selectmeeting = this.selectedMeeting.meetingName;
    // get meeting participants
    this.getMeetingParticipants();
    this.getAllMeetingAgendas();
    this.resetMeeting();
    this.getParticipantsOnPlant();
    //  ui rendere with animations
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.add('content-show');
    wrapper2?.classList.remove('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    this.GetMeetingQuestionLine();

    this.getsalesChart();
    this.getpieChart();
    this.getlinechart();
    this.gettopsalesChart();
    this.getbarChart();
    this.checkedMeetingStatus();
    this.getMenuPermissions();

    this.getOverallMeetingActions();
    this.getKPIsTrend(this.selectedKpi);
    this.alertBeforeMeetingEnd()


    // Swal.fire({
    //   title: 'Create Meeting Recurrence?',
    //   text: 'Do you want to Create Meeting Recurrence?',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: 'Yes',
    //   cancelButtonText: 'No',
    // }).then((result) => {

    //   if(result.isConfirmed){
    //     this.openMeetingRescheduler();
    //   }

    // });
  }

  onClickLines(PL_Id: number): void {
    this.selectedLine = PL_Id;
    this.GetMeetingQuestionByLine();
  }
  onclickExpand(index: number) {
    this.showMore[index] = true;
    const wrapper1 = document.querySelector('#rowWrap1');
    const wrapper2 = document.querySelector('#rowWrap2');
    const wrapper3 = document.querySelector('#rowWrap3');
    const wrapper4 = document.querySelector('#rowWrap4');
    if (index === 1) {
      wrapper1?.classList.remove('w-49');
      wrapper1?.classList.add('w-100');
    }
    if (index === 2) {
      wrapper2?.classList.remove('w-49');
      wrapper2?.classList.add('w-100');
    }
    if (index === 3) {
      wrapper3?.classList.remove('w-49');
      wrapper3?.classList.add('w-100');
    }
    if (index === 4) {
      wrapper4?.classList.remove('w-49');
      wrapper4?.classList.add('w-100');
    }
  }
  onclickCollapse(index: number) {
    this.showMore[index] = false;
    const wrapper1 = document.querySelector('#rowWrap1');
    const wrapper2 = document.querySelector('#rowWrap2');
    const wrapper3 = document.querySelector('#rowWrap3');
    const wrapper4 = document.querySelector('#rowWrap4');
    if (index === 1) {
      wrapper1?.classList.add('w-49');
      wrapper1?.classList.remove('w-100');
    }
    if (index === 2) {
      wrapper2?.classList.add('w-49');
      wrapper2?.classList.remove('w-100');
    }
    if (index === 3) {
      wrapper3?.classList.add('w-49');
      wrapper3?.classList.remove('w-100');
    }
    if (index === 4) {
      wrapper4?.classList.add('w-49');
      wrapper4?.classList.remove('w-100');
    }
  }

  getGridDataByFrequency(): any {
    this.scoreCardGridApi.showLoadingOverlay();
    this.date = new Date();
    const latestdate = new Date(this.range.value.toDate).setHours(
      23,
      59,
      59,
      59
    );
    const key = {
      frequencyId: this.MeetingMatrixFrequencyForm.value.frequency.frequencyId,
      kpiId: this.MeetingMatrixFrequencyForm.value.tsfrequency.kpiId,
      frequencyName:
        this.MeetingMatrixFrequencyForm.value.frequency.frequencyName,
      plantId: this.MeetingMatrixForm.get('plant')?.value,
      fromDate: moment(this.range.value.fromDate).utc(),
      toDate: moment(latestdate).utc(),
      meetingDefId: this.selectedMeeting.meetingId,
    };


    this.meetingService
      .getScoreCardMeetingMatrix(key)
      .subscribe((data: any) => {
        this.ScoreCardDataTemp =data;
        this.createScoreCardData(this.ScoreCardDataTemp);
      });
  }
  createScoreCardData(res) {
    const temp = JSON.parse(JSON.stringify(res)); 
    if (!temp || temp.length === 0) {
      this.scoreCardGridApi.hideOverlay();
      this.scoreCardGridApi.setRowData([]);
      this.onScoreCardGridFirstDataRendered();

      this.hasGlidePath = false;

      return;
    }
    let glidePath = temp.filter((element) => {
      return element.Type === 'Glidepath';
    });
    let actual = temp.filter((element) => {
      return element.Type === 'Actual';
    });

    glidePath = _.sortBy(glidePath, ['KPIId']);
    actual = _.sortBy(actual, ['KPIId']);

    Object.entries(temp).forEach((score: any) => {
      Object.keys(score[1]).map((key) => {
        if (
          key !== 'Type' &&
          key !== 'Indicator' &&
          key !== 'Trend' &&
          key !== 'Department Name' &&
          key !== 'Owner' &&
          key !== 'Responsible' &&
          key !== 'Last Month' &&
          key !== 'KPICode' &&
          key !== 'Previous' &&
          key !== 'MTD' &&
          key !== 'YTD' &&
          key !== 'KPIId' &&
          key !== 'meetingDefId' &&
          key !== 'meetingDefName' &&
          key !== 'Line' &&
          key !== 'Unit' &&
          key !== 'UoM' &&
          key !== 'KPI Name' &&
          key !== 'KPI Code' &&
          key !== 'Line' &&
          key !== 'FinancialGap' &&
          key !== 'Target Date'
        ) {
          if (score[1][key] !== undefined && score[1][key] !== null) {
            if (!isNaN(score[1][key]) && this.isDecimal(score[1][key])) {
              score[1][key] = Number(score[1][key]).toFixed(this.decimalPlaces);
            }

            score[1][key] = `${Number(score[1][key]).toFixed(this.decimalPlaces)} (${score[1].UoM})`;
          }
        } else {
          return key;
        }
      });
    });

    this.rowData = temp;
    // this.rowData = [];

    this.scoreCardGridApi.showLoadingOverlay();
    this.scoreCardGridApi.setRowData(temp);

    if (this.rowData) {
      this.rowData.forEach((element) => {
        element!.Line = !element?.Line ? '' : element?.Line;
        delete element?.Owner;
        delete element?.Responsible;
      });

      this.rowDataCopy = this.rowData;

      this.columnDefs = this.generateColumns(this.rowData);

      setTimeout(() => {
        //reset column state

        if (this.rowData.length) {
          this.headerBreaker();
          this.scoreCardGridColumnApi.resetColumnState();
          this.autoSizeAllColumns(this.scoreCardGridColumnApi);
        }
      }, 1000);
      this.hasGlidePath = this.rowData.some((row) => row.Type === 'Glidepath');

      this.scoreCardGridApi.hideOverlay();
    }
    this.onScoreCardGridFirstDataRendered();

    this.scoreCardGridApi.hideOverlay();
  }

  isDecimal(n) {
    var result = n - Math.floor(n) !== 0;

    if (result) return true;
    else return false;
  }
  onScoreCardGridFirstDataRendered(e?: any) {
    this.enableGlidePath = false;
    this.enableGlidePathChange({ checked: false });
    this.headerBreaker();
    const columns = this.scoreCardGridColumnApi.getAllDisplayedColumns();
  }
  generateColumns(data: any[]): any {

    let columnDefinitions: any[] = [];
    const hideColumns = [
      'Unit',
      'Previous',
      'Trend',
      'Department Name',
      'Responsible',
      'Type',
      'UoM',
      'YTD',
      'MTD'
    ];
    const cellClassColumns = [
      'Type',
      'Indicator',
      'Responsible',
      'Last Month',
      'KPICode',
      'MTD',
      'YTD',
      'KPIId',
      'meetingDefId',
      'meetingDefName',
      'Line',
      'UoM',
      'KPI Name',
      'Owner',
      'FinancialGap',
      'Target Date',
    ];
    const multiFilter = ['KPI Name'];
    const tooltipValueGetter = (params: any) => ({ value: params.value });
    data.map((object) => {

      Object.keys(object).map((key) => {
        let isGroupExpanded = false;
        // debugger
        const mappedColumn = {
          headerName: key === 'FinancialGap' ? 'Financial Gap' : key, // .toUpperCase()
          field: key,
          headerCheckboxSelection: key === 'KPI Name' ? true : false,
          checkboxSelection: key === 'KPI Name' ? true : false,
          showDisabledCheckboxes: key === 'KPI Name' ? true : false,
          rowGroup: false,
          // pinned: key === 'Line' ? 'left' : false,
          // lockPinned: key === 'Line' ? true : false,
          resizable: true,
          hide: hideColumns.includes(key),
          cellStyle: {},
          // sortable: sortAble.includes(key),
          sortable: true,
          autoHeight: true,
          // width: 100,
          floatingFilter: multiFilter.includes(key) ? true : false,
          // suppressMenu: true,
          filter: multiFilter.includes(key) ? 'agMultiColumnFilter' : true,
          onCellClicked: {},
          cellClass: !cellClassColumns.includes(key)
            ? 'ag-grid-dynamic-cell'
            : '',
          // rowGroupOpened: (params) => {
          //   isGroupExpanded = params.expanded;
          // },
        };
        mappedColumn.cellStyle = (params: any): any => {
          const col = params.colDef.field;
          if (!params.data) {
            params.data = params?.node?.allLeafChildren[0]?.data;
          }
          if (
            key !== 'Type' &&
            key !== 'Indicator' &&
            key !== 'Responsible' &&
            key !== 'Last Month' &&
            key !== 'KPICode' &&
            key !== 'MTD' &&
            key !== 'YTD' &&
            key !== 'KPIId' &&
            key !== 'meetingDefId' &&
            key !== 'meetingDefName' &&
            key !== 'Line' &&
            key !== 'UoM' &&
            key !== 'KPI Name' &&
            key !== 'Owner' &&
            key !== 'FinancialGap' &&
            key !== 'Target Date'
          ) {
            const styleObj = {
              'font-weight': 'bold',
              'background-color': '',
              color: 'black',
              textAlign: 'right',
            };

            if (col === 'KPI Name') {
              styleObj['font-weight'] = '700';
            }
            if (params.data.Type === 'Actual') {
              let currentActualValue = params.value;

              this.scoreCardGridOptions.api.forEachLeafNode(

                (rowNode, index) => {
                  if (
                    rowNode.data.KPIId === params.data.KPIId &&
                    rowNode.data.Type === 'Glidepath'
                  ) {
                    if (params.api.getRowNode(index) !== undefined) {
                      // const previousGildepath = params.api.getRowNode(params.node.rowIndex - 1);
                      const rowGlidepath = params.api.getRowNode(index).data;
                      if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        parseFloat(currentActualValue) >
                        parseFloat(rowGlidepath[params.colDef.field]) &&
                        currentActualValue && // && parseInt(currentActualValue, 10) !== 0
                        rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                      ) {
                        if (params.data?.Trend === 'Up') {
                          styleObj['background-color'] = 'green';
                          styleObj.color = 'white';

                        } else {
                          styleObj['background-color'] = 'red';
                          styleObj.color = 'white';

                        }
                        // tslint:disable-next-line:max-line-length
                      } else if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        // tslint:disable-next-line:max-line-length
                        parseFloat(currentActualValue) <
                        parseFloat(rowGlidepath[params.colDef.field]) &&
                        currentActualValue && // && parseInt(currentActualValue, 10) !== 0
                        rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                      ) {
                        if (params.data?.Trend === 'Down') {
                          styleObj['background-color'] = 'green';
                          styleObj.color = 'white';

                        } else {
                          styleObj['background-color'] = 'red';
                          styleObj.color = 'white';

                        }
                      } else if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        parseFloat(currentActualValue) === 0 &&
                        parseFloat(rowGlidepath[params.colDef.field]) === 0 &&
                        rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                      ) {
                        styleObj['background-color'] = 'green';
                        styleObj.color = 'white';

                      } else if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        parseFloat(currentActualValue) ===
                        parseFloat(rowGlidepath[params.colDef.field]) &&
                        rowGlidepath.Type === 'Glidepath'
                      ) {
                        styleObj['background-color'] = 'green';
                        styleObj.color = 'white';

                      }
                    }
                  }
                }
              );
            } else {
              styleObj['background-color'] = 'yellow';
            }
            return styleObj;
          }

          if (key !== 'Type' &&
            key !== 'Indicator' &&
            key !== 'Responsible' &&
            key !== 'Last Month' &&
            key !== 'KPICode' &&
            key !== 'KPIId' &&
            key !== 'meetingDefId' &&
            key !== 'meetingDefName' &&
            key !== 'Line' &&
            key !== 'UoM' &&
            key !== 'KPI Name' &&
            key !== 'Owner' &&
            key !== 'Target Date') {
            return { 'text-align': 'right' }
          }
          if (col === 'KPI Name') {
            const styleObj = {
              'font-weight': 'bold',
            };
            return styleObj;
          }

          if (key === 'Type' && params.data.Type === 'Actual') {
            return { 'font-weight': 'bold', color: '#009688' };
          } else if (key === 'Type' && params.data.Type === 'Glidepath') {
            return { 'font-weight': 'bold', color: '#bf360c' };
          }

        };

        if (
          key !== 'Type' &&
          key !== 'Indicator' &&
          key !== 'Responsible' &&
          key !== 'Last Month' &&
          key !== 'KPICode' &&
          key !== 'MTD' &&
          key !== 'YTD' &&
          key !== 'KPIId' &&
          key !== 'meetingDefId' &&
          key !== 'meetingDefName' &&
          key !== 'Line' &&
          key !== 'UoM' &&
          key !== 'KPI Name'
        ) {
          // mappedColumn.width = 150;
        }
        if (
          key === 'KPIId' ||
          key === 'KPICode' ||
          key === 'meetingDefId' ||
          key === 'meetingDefName' ||
          key === 'Owner'
        ) {
          mappedColumn.hide = true;
        }
        // if (key === 'KPI Name') {
        //   mappedColumn.width = 400;
        // }
        columnDefinitions.push(mappedColumn);
      });
    });

    // Remove duplicate columns
    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    this.headerBreaker();
    return columnDefinitions;
  }
  uploadedFile(event: any): void {
    if (event.target.files.length > 0) {
      this.closefile = event.target.files[0];
    }
  }

  generateColumnsV2(data: any[]): any {
    debugger
    if (data.length) {
      data.forEach((x) => {
        Object.keys(x).forEach((key) => {
          if (
            key !== 'Type' &&
            key !== 'Indicator' &&
            key !== 'Trend' &&
            key !== 'Department Name' &&
            key !== 'Owner' &&
            key !== 'Responsible' &&
            key !== 'Last Month' &&
            key !== 'KPICode' &&
            key !== 'Previous' &&
            key !== 'MTD' &&
            key !== 'YTD' &&
            key !== 'KPIId' &&
            key !== 'meetingDefId' &&
            key !== 'meetingDefName' &&
            key !== 'Line' &&
            key !== 'Unit' &&
            key !== 'UoM' &&
            key !== 'KPI Name' &&
            key !== 'KPI Code' &&
            key !== 'Line' &&
            key !== 'FinancialGap' &&
            key !== 'Target Date'
          ) {
            if (x[key] && !isNaN(x[key]) && this.isDecimal(x[key])) {
              x[key] = Number(x[key]).toFixed(2);
            }
          }
        });
      });
    }
    const hideColumns = ['Trend', 'DataTypeId', 'Type', 'Line'];
    const cellClassColumns = [
      'Type',
      'Indicator',
      'Responsible',
      'Last Month',
      'KPICode',
      'MTD',
      'YTD',
      'KPIId',
      'meetingDefId',
      'meetingDefName',
      'Line',
      'UoM',
      'KPI Name',
      'Owner',
      'FinancialGap',
      'Target Date',
    ];
    // this.columnDefs = [];
    let columnDefinitions = [];
    //  this.scorecardGridOptions.columnApi.setColumnsVisible('KPIId', false);
    const multiFilter = ['KPI Name'];
    data.map((object) => {
      Object.keys(object).map((key) => {
        let isGroupExpanded = false;
        if (key !== 'KPIId' && key !== 'KPICode') {
          const mappedColumn = {
            field: key,
            headerCheckboxSelection: key === 'KPI Name' ? true : false,
            checkboxSelection: key === 'KPI Name' ? true : false,
            showDisabledCheckboxes: key === 'KPI Name' ? true : false,
            headerName: key === 'headerId' ? 'Actions' : key, // .toUpperCase()
            rowGroup: key === 'Line' ? true : false,
            pinned: key === 'Line' ? 'left' : false,
            lockPinned: key === 'Line' ? true : false,
            resizable: true,
            hide: hideColumns.includes(key),
            cellStyle: {},
            sortable: true,
            autoHeight: true,
            enableRowGroup: true,
            floatingFilter: multiFilter.includes(key) ? true : false,
            onCellClicked: {},
            cellClass: !cellClassColumns.includes(key)
              ? 'ag-grid-dynamic-cell'
              : '',

            rowGroupOpened: (params) => {
              isGroupExpanded = params.expanded;
            },
            headerClass: {},
            wrapText: true,

            filter: true,
            cellRenderer: (params: any): any => {
              if (key === 'headerId') {
                return `
                  <div>
                  <button mat-mini-fab="" 
                  ptooltip="Action Delete" 
                  title="Action" tooltipposition="top" 
                  class="viewRCA mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #20b2aa;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
                  </div>
                  `;
              } else {
                return params.value;
              }
            },
          };

          mappedColumn.cellStyle = (params: any): any => {
            const col = params.colDef.field;
            if (!params.data) {
              params.data = params?.node?.allLeafChildren[0]?.data;
            }
            if (
              key !== 'Type' &&
              key !== 'KPICode' &&
              key !== 'KPIId' &&
              key !== 'headerId' &&
              key !== 'KPI Name' &&
              key !== 'Line'
            ) {
              const styleObj = {
                'font-weight': 'bold',
                'background-color': '',
                color: 'black',
                textAlign: 'right'
              };

              if (col === 'KPI Name') {
                styleObj['font-weight'] = '700';
              }
              if (params.data.Type === 'Actual') {
                let currentActualValue = params.value;
                this.kpigridApi.forEachNode((rowNode, index) => {
                  if (
                    rowNode?.data?.KPIId === params?.data?.KPIId &&
                    rowNode?.data?.Type === 'Glidepath'
                  ) {
                    // rowNode.data.Gaps = 10;
                    if (params.api.getRowNode(index) !== undefined) {
                      // const previousGildepath = params.api.getRowNode(params.node.rowIndex - 1);
                      const rowGlidepath = params.api.getRowNode(index).data;
                      if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        // tslint:disable-next-line:max-line-length
                        parseFloat(currentActualValue) >
                        parseFloat(rowGlidepath[params.colDef.field]) &&
                        currentActualValue && // && parseInt(currentActualValue, 10) !== 0
                        rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                      ) {
                        if (params.data?.Trend === 'Up') {
                          styleObj['background-color'] = 'green';
                          styleObj.color = 'white';
                        } else {
                          styleObj['background-color'] = 'red';
                          styleObj.color = 'white';
                        }
                        // tslint:disable-next-line:max-line-length
                      } else if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        // tslint:disable-next-line:max-line-length
                        parseFloat(currentActualValue) <
                        parseFloat(rowGlidepath[params.colDef.field]) &&
                        currentActualValue && // && parseInt(currentActualValue, 10) !== 0
                        rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                      ) {
                        if (params.data?.Trend === 'Down') {
                          styleObj['background-color'] = 'green';
                          styleObj.color = 'white';
                        } else {
                          styleObj['background-color'] = 'red';
                          styleObj.color = 'white';
                        }
                      } else if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        parseFloat(currentActualValue) === 0 &&
                        parseFloat(rowGlidepath[params.colDef.field]) === 0 &&
                        rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                      ) {
                        styleObj['background-color'] = 'green';
                        styleObj.color = 'white';
                      } else if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        parseFloat(currentActualValue) ===
                        parseFloat(rowGlidepath[params.colDef.field]) &&
                        rowGlidepath.Type === 'Glidepath'
                      ) {
                        styleObj['background-color'] = 'green';
                        styleObj.color = 'white';
                      }
                    }
                  }
                });
              } else {
                styleObj['background-color'] = 'yellow';
              }
              return styleObj;
            }
            if (col === 'KPI Name') {
              const styleObj = {
                'font-weight': 'bold',
              };
              return styleObj;
            }
            if (key === 'Type' && params.data.Type === 'Actual') {
              return { 'font-weight': 'bold', color: '#009688' };
            } else if (key === 'Type' && params.data.Type === 'Glidepath') {
              return { 'font-weight': 'bold', color: '#bf360c' };
            }
          };

          columnDefinitions.push(mappedColumn);

        }
      });
    });

    // Remove duplicate columns
    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    this.headerBreaker();
    columnDefinitions.forEach((element: any, index: any) => {
      if (element.field === 'headerId') {
        var key = columnDefinitions[index]
        columnDefinitions.splice(index, 1);
        columnDefinitions.splice(1, 0, key);
      }
    });
    return columnDefinitions;
  }


  toggleDisplayreverse(): void {
    if (this.meetingStarted === false) {
      this.selectedMeeting = {
        meetingId: 0,
        meetingName: '',
        meetingType: '',
      };
      const wrapper2 = document.querySelector('#wrapper2');
      wrapper2?.classList.remove('content-show');
      wrapper2?.classList.add('content-hide');
      const wrapper3 = document.querySelector('#wrapper3');
      wrapper3?.classList.remove('content-show');
      wrapper3?.classList.add('content-hide');
      const wrapper1 = document.querySelector('#wrapper1');
      wrapper1?.classList.add('content-show');
      wrapper1?.classList.remove('content-hide');
      this.hasEnteredMeeting = false;
    } else {
      this.toaster.warning('Meeting in Progress');
    }
    this.StackedBarChart == true;
  }
  hideTargetLevel = false;

  getMeetingEvaluations(): void {
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.add('content-show');
    wrapper3?.classList.remove('content-hide');
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');

    // chart data binding and getting from apis
    const key = {
      meetingDefId: this.selectedMeeting?.meetingId,
      meetingtype: this.selectedMeeting?.meetingType,
    };
    this.meetingService.getMeetingEvalationDashboard(key).subscribe(
      (response) => {
        // const targetArray = [];
        // const scoreArray = [];
        // const levelArray = [];
        // const datesArray = [];
        // const tableData = [];
        // let uniquesdate: any = [];
        // let uniqueLevels: any = [];
        var targetArray = [];
        var scoreArray = [];
        var levelArray = [];
        var datesArray = [];
        var tableData = [];
        var uniquesdate: any = [];
        var uniqueLevels: any = [];
        if (key.meetingtype === 'Evaluation Level') {
          this.hideTargetLevel = true;
          this.hideScore = false;
          // target?.classList.add('hide');
          // target?.classList.remove('show');
          for (const iterator of response) {
            targetArray.push(iterator?.Target);
            scoreArray.push(iterator?.Score);
            levelArray.push(iterator?.EvalutionLevel);
            datesArray.push(
              moment(iterator?.Date).format('DD/MM/YYYY HH:mm:ss')
            );
            uniqueLevels = [...new Set(levelArray)];
            uniquesdate = [...new Set(datesArray)];
            tableData.push({
              Date: new Date(iterator?.Date),
              Score: iterator?.Score,
              Level: iterator?.EvalutionLevel,
            });
          }
          this.chartOptions.xaxis = {
            categories: uniquesdate,
            title: {
              text: 'Meeting Evalutions',
            },
          };
          let seriesScoreArr = [];
          for (const value of uniqueLevels) {
            seriesScoreArr = response
              .filter((res: any) => value === res.EvalutionLevel)
              .map((m: any) => m.Score);
            const seriesObj = {
              name: value,
              data: seriesScoreArr,
            };
            this.chartOptions.series.push(seriesObj);
          }
          this.meetingEvaluationData = new MatTableDataSource(tableData);
          this.meetingEvaluationData.paginator = this.paginator;
        } else if (key.meetingtype === 'Evaluation Check List') {
          this.hideTargetLevel = false;
          this.hideScore = true;
          // level?.classList.add('hide');
          // level?.classList.remove('show');
          for (const iterator of response) {
            targetArray.push(iterator?.Target);
            scoreArray.push(iterator?.Score);
            datesArray.push(
              moment(iterator?.Date).format('DD/MM/YYYY HH:mm:ss')
            );
            tableData.push({
              Date: new Date(iterator?.Date),
              Score: iterator?.Score,
              Target: iterator?.Target,
            });
          }
          this.chartOptions.xaxis = {
            categories: datesArray,
            title: {
              text: 'Meeting Evalutions',
            },
          };
          this.chartOptions.series = [
            {
              name: 'Score',
              data: scoreArray,
            },
            {
              name: 'Traget',
              data: targetArray,
            },
          ];
          this.meetingEvaluationData = new MatTableDataSource(tableData);
          this.meetingEvaluationData.paginator = this.paginator;
        } else {
          for (const iterator of response) {
            targetArray.push(iterator?.Target);
            scoreArray.push(iterator?.Score);
            datesArray.push(
              moment(iterator?.Date).format('DD/MM/YYYY HH:mm:ss')
            );
            tableData.push({
              Date: new Date(iterator?.Date),
              Score: iterator?.Score,
              Target: iterator?.Target,
            });
          }
          this.chartOptions.xaxis = {
            categories: datesArray,
            title: {
              text: 'Meeting Evalutions',
            },
          };
          this.chartOptions.series = [
            {
              name: 'Score',
              data: scoreArray,
            },
            {
              name: 'Traget',
              data: targetArray,
            },
          ];
          this.meetingEvaluationData = new MatTableDataSource(tableData);
          this.meetingEvaluationData.paginator = this.paginator;
        }
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
    this.chartOptions.series = [];
  }


  toggleMeetingEvaluationReverse(): void {
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.add('content-show');
    wrapper2?.classList.remove('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
  }

  filterBanksMulti() {
    if (!this.filteredParticipants) {
      return;
    }

    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.filteredBanksMulti.next(this.filteredParticipants);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanksMulti.next(
      this.filteredParticipants.filter(
        (bank: any) =>
          (bank.FirstName + ' ' + bank.lastName).toLowerCase().indexOf(search) >
          -1
      )
    );
  }
  getUserList(): void {
    const key = {
      EGId: this.MeetingMatrixForm.get('group')?.value,
      PlantId: this.MeetingMatrixForm.get('plant')?.value,
    };

    this.kpiservice.getEmployeeMasterData(key).then(
      (users: any) => {
        this.UserDDL = users.employeeInfoList.filter((x: any) => {
          return x.EmpInfo_Id;
        });
        this.UserDDL.forEach((element: any) => {
          element.fullName = element.FirstName + ' ' + element.lastName;
        });
        this.filteredUsers = this.UserDDL.slice();
        this.filteredParticipants = this.UserDDL.slice();
        this.filterBanksMulti();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  onScoreCardCellClicked(params: any): void {

    const classList =
      params.event.target.classList;
    const col = params.colDef.field;
    if (
      col === 'Trend' ||
      col === 'Type' ||
      col === 'Indicator' ||
      col === 'Responsible' ||
      col === 'Last Month' ||
      col === 'KPICode' ||
      col === 'MTD' ||
      col === 'YTD' ||
      col === 'KPIId' ||
      col === 'meetingDefId' ||
      col === 'meetingDefName' ||
      col === 'Line' ||
      col === 'UoM' ||
      col === 'KPI Name' ||
      col === 'Owner' ||
      col === 'FinancialGap' ||
      col === 'Target Date' ||
      col === 'Unit' ||
      col === 'Previous' ||
      col === 'Department Name'
    ) {
      return;
    }

    const key1 = {
      kpiId: params.data.KPIId,
    };
    // this.meetingaction.getResponsibleOnKpiId(key1).subscribe((res) => {
    //   this.filteredUsers = res.slice();
    // });
    this.getUserList();
    this.isKpiAddToAction = true;

    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
    };
    if (params.colDef.field !== 'na') {
      this.meetingService.getMeetingAction(key).subscribe((data) => {
        for (var d of data) {
          if (d.newtargetdate != null && d.newtargetDate != '') {
            d.newtargetdate = moment(d.newtargetdate).format(
              'DD/MM/YYYY HH:mm:ss'
            );
          }
          if (d.completiondate != null && d.completiondate != '') {
            d.completiondate = moment(d.completiondate).format(
              'DD/MM/YYYY HH:mm:ss'
            );
          }
        }
        this.rowData1 = data;
        this.MatrixForm.get('meetingDefName')?.setValue(
          this.selectedMeeting.meetingName
        );
        this.selectmeeting = this.MatrixForm.get('meetingDefName')?.setValue(
          this.selectedMeeting.meetingName
        );
        this.MatrixForm.get('meetingDefId')?.setValue(
          this.selectedMeeting.meetingId
        );
        this.kpiId = params.data.KPIId;
        this.MatrixForm.get('kpi')?.setValue(params.data['KPI Name']);
        this.currentKPIId = this.kpiId;
        this.kpiName = params.data['KPI Name'];
        this.openModal();
      });
    }

  }

  onScoreCardGridReady(params: any): void {
    this.scoreCardGridApi = params.api;
    this.scoreCardGridColumnApi = params.columnApi;

    this.scoreCardGridApi.setDomLayout('autoHeight');

    const element: HTMLElement = document.querySelector(
      '#scorcardgrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
    this.headerBreaker();
    let colName = 'KPI Name';
    // this.scoreCardGridApi.setColumnsVisible([colName], false);

  }

  getCurrentMeetingActions() {
    const data = {
      meetingId: this.selectedMeeting.meetingId,

      meetingAttendId: this.currentMeetingID,
    };
    this.meetingService.getCurrentMeetingActions(data).subscribe(
      (response) => {
        this.currentMeetingActions = response;
        this.GetMeetingStatus();
      },
      (err: any) => {

      }
    );
  }
  getOverallMeetingActions() {
    const data = {
      meetingId: this.selectedMeeting.meetingId,
    };
    this.meetingService.getAllMeetingActions(data).subscribe(
      (response) => {
        this.rowData1 = response;
        this.overallMeetingActions = response;
        this.createLineAndStackChart(response);
      },
      (err: any) => {

      }
    );
  }
  getKPIsTrend(kpiId: any, numOfSelectedRow?: any, KPIs?: any) {
    this.meetingService.getKPITrend({ kpiId: kpiId }).subscribe(
      (response: any) => {
        this.KPITrend = response;
        if (numOfSelectedRow === 1) {
          this.createDataForKPITrendChart(response);
        }
        if (numOfSelectedRow > 1) {
          this.createDataForKPITrendChartV2(response, KPIs);
        }
      },
      (error: any) => {

      }
    );
  }
  createDataForKPITrendChart(data: any) {
    let series = [];
    let yAxis = [];
    let glideData = [];

    let actualData = [];
    for (let i = 0; i < data.length; i++) {
      const type = data[i].Type;
      const date = data[i].Date;
      const target = data[i].Target;

      if (type === 'Actual') {
        actualData.push({
          x: +new Date(date),
          y: parseFloat(target),
          label: 'Actual',
        });
      }
      if (type === 'Glidepath') {
        glideData.push({
          x: +new Date(date),
          y: parseFloat(target),
          label: 'GlidePath',
        });
      }
    }

    yAxis.push(
      {
        lineWidth: 1,
        opposite: false,
        title: {
          text: 'Actual',
          style: {
            color: '#00008B',
          },
        },
        labels: {
          style: {
            color: '#00008B',
          },
        },
      },
      {
        lineWidth: 1,
        opposite: false,
        title: {
          text: 'GlidePath',
          style: {
            color: '#34A56F',
          },
        },
        labels: {
          style: {
            color: '#34A56F',
          },
        },
      }
    );

    series.push(
      {
        tooltip: {
          valueDecimals: 2,
        },
        name: 'Actual',
        type: 'line',
        data: actualData,
        color: '#00008B',
        yAxis: 0,
      },
      {
        tooltip: {
          valueDecimals: 2,
        },
        name: 'Glidepath',
        type: 'line',
        data: glideData,
        color: '#34A56F',
        yAxis: 1,
      }
    );
    this.createStockChartForSingleInstance(yAxis, series);
  }
  createDataForKPITrendChartV2(data: any, KPIs: any[]) {
    let series = [];
    let yAxis = [];
    for (let i = 0; i < KPIs.length; i++) {
      let nameWiseData = [];
      yAxis.push({
        lineWidth: 1,
        opposite: false,
        title: {
          text: KPIs[i].KPIName,
          style: {
            color: this.highChartsColor[i],
          },
        },
        labels: {
          style: {
            color: this.highChartsColor[i],
          },
        },
      });
      for (let j = 0; j < data.length; j++) {
        const value = data[j].Value;
        const date = data[j].Date;
        const KPIId = data[j].KPIId;
        if (KPIId === KPIs[i].KPIId) {
          nameWiseData.push({
            x: +new Date(date),
            y: value,
            label: data[j],
          });
        }
      }
      series.push({
        tooltip: {
          valueDecimals: 2,
        },
        name: KPIs[i].KPIName,
        type: 'line',
        data: nameWiseData,
        color: this.highChartsColor[i],
        yAxis: i,
      });
    }
    this.createStockChartForSingleInstance(yAxis, series);
  }
  createStockChartForSingleInstance(yAxis: any, series: any): void {
    this.stock = new StockChart({
      chart: {
        height: 730,
      },
      plotOptions: {
        series: {
          turboThreshold: 50000000,
          events: {
            hide(): any {
              // this.yAxis.axisTitle.hide();
              this.yAxis.update({
                visible: false,
              });
            },
            show(): any {
              this.yAxis.update({
                visible: true,
              });
            },
          },
        },
      },
      rangeSelector: {
        selected: 1,
      },
      title: {
        text: 'KPI Trend', // uniqueGroups.toString(),
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        valueDecimals: 2,
      },
      yAxis: yAxis,
      series: series,
    });
  }
  createLineAndStackChart(overAllMeetingActions: any) {
    let actions = overAllMeetingActions;
    let assingedBy: any = _.uniq(_.map(actions, 'AssingedBy'));

    let assignByWithStatus = _.map(actions, (o) =>
      _.pick(o, ['AssingedBy', 'Status'])
    );
    let _open = [],
      _close = [],
      _pending = [];
    // for (let i = 0; i < assingedBy.length; i++) {

    //   let open = _.filter(assignByWithStatus, {
    //     AssingedBy: assingedBy[i],
    //     Status: 'Open',
    //   }).length;
    //   let close = _.filter(assignByWithStatus, {
    //     AssingedBy: assingedBy[i],
    //     Status: 'Closed',
    //   }).length;
    //   let pending = _.filter(assignByWithStatus, {
    //     AssingedBy: assingedBy[i],
    //     Status: 'InProgress',
    //   }).length;
    //   let delayed = _.filter(assignByWithStatus, {
    //     AssingedBy: assingedBy[i],
    //     Status: 'Delayed',
    //   }).length;
    //   let rejected = _.filter(assignByWithStatus, {
    //     AssingedBy: assingedBy[i],
    //     Status: 'Rejected',
    //   }).length;
    //   let reOpened = _.filter(assignByWithStatus, {
    //     AssingedBy: assingedBy[i],
    //     Status: 'ReOpened',
    //   }).length;
    //   let complete = _.filter(assignByWithStatus, {
    //     AssingedBy: assingedBy[i],
    //     Status: 'Complete',
    //   }).length;
    //   if (open == 10) {  }
    //   if (open != 0) _open.push(open);
    //   if (pending != 0) _open.push(pending);
    //   if (delayed != 0) _open.push(delayed);
    //   if (reOpened != 0) _open.push(reOpened);
    //   if (close != 0) _close.push(close);
    //   if (complete != 0) _close.push(complete);
    //   if (rejected != 0) _close.push(rejected);
    //   if (assingedBy[i] == null) 
    // }

    for (let i = 0; i < assingedBy.length; i++) {
      const openActions = assignByWithStatus.filter(
        (x) =>
          x.AssingedBy == assingedBy[i] &&
          (x.Status === 'Open' ||
            x.Status === 'InProgress' ||
            x.Status === 'Delayed' ||
            x.Status === 'ReOpened')
      );
      _open.push(openActions.length);
      const closedActions = assignByWithStatus.filter(
        (x) =>
          x.AssingedBy == assingedBy[i] &&
          (x.Status === 'Closed' ||
            x.Status === 'Rejected' ||
            x.Status === 'Complete')
      );
      _close.push(closedActions.length);
      // assignByWithStatus.filter((x) => {
      //   if (x.AssignedBy == assingedBy[i]) {
      //     if (x.Status == 'Open' || x.Status == 'InProgress' || x.Status == 'Delayed' || x.Status == 'ReOpened') {
      //       _open.push();
      //     }
      //     else {
      //       _close.push(x);
      //     }
      //   }
      // })
    }
    this.chartData.series = [
      {
        name: 'Open',
        data: _open,
        color: '#0f04dd',
      },

      {
        name: 'Closed',
        data: _close,
        color: '#e85236',
      },
    ];
    this.chartData.categories = assingedBy;
    this.getActionChart();

    //stackBar Chart percentage

    this.stackbarPercentageChartOptions.xAxis.categories = assingedBy;
    this.stackbarPercentageChartOptions.series[0].data = _open;
    this.stackbarPercentageChartOptions.series[1].data = _close;

    //stack chart data
    this.stackbarChartOptions.xAxis.categories = assingedBy;
    this.stackbarChartOptions.series[0].data = _open;
    this.stackbarChartOptions.series[1].data = _close;

    // this.createStackedBarPercentageChart(this.stackbarPercentageChartOptions);
  }

  createStackedBarChart(myOpts: Object) {
    this.destoryChart(this.stackBarChart.nativeElement);
    this.hcs.createChart(this.stackBarChart.nativeElement, myOpts);
  }

  destoryChart(container) {
    this.renderer.setProperty(container, 'innerHTML', '');
  }

  createStackedBarPercentageChart(myOpts: Object, fromDate: any, toDate: any) {
    this.destoryChart(this.stackBarPercentageChart.nativeElement);
    this.hcs.createChart(
      this.stackBarPercentageChart.nativeElement,
      this.stackbarPercentageChartOptions
    );
    this.renderprogressChart(fromDate, toDate);
    this.createStackedBarChart(this.stackbarChartOptions);
  }
  tabGroup(i) {
    this.selectedIndex = i?.index;
    this.changeDateIndexWise(i);
    if (i.index == 0) {
      if (this.rowData.length) {

        this.autoSizeAllColumns(this.scoreCardGridColumnApi);
      }
    }
    if (i.index == 1) {
      if (this.columnDefskpi.length) {
        this.autoSizeAllColumns(this.kpigridColumnApi);
      }
    }

    if (i.index === 2) {
      setTimeout(() => {
        this.createStackedBarPercentageChart(
          this.stackbarPercentageChartOptions,
          this.chartrange.value.fromDate,
          this.chartrange.value.toDate
        );
      }, 1000);
    }
  }

  renderprogressChart(fromDate: any, toDate: any): void {
    fromDate = new Date(new Date(fromDate).setHours(0, 0, 0, 0));
    toDate = new Date(new Date(toDate).setHours(23, 59, 59, 0));
    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
      fromDate: fromDate,
      toDate: toDate,
    };

    this.meetingDefinition.GetMeetingStatus(key).subscribe((res: any) => {
      let data = [10, 0, 63, 0, 2, 0];
      let NotDonedata = [];
      let categoriesData = [];

      data = [];
      categoriesData = [];
      this.Cdata = [];
      NotDonedata = [];
      this.CCategorydata = [];

      res.forEach(function (e: any): any {
        if (e.Status === 'Done') {
          data.push(parseFloat(e.Mint));
        } else {
          data.push(0);

          NotDonedata.push(parseFloat(e.Mint));
        }
        //  data.push(parseFloat(e.Hour))

        categoriesData.push(moment(e.StartDate).format('DD/MM/YYYY hh:mm a'));
      });
      this.Cdata = data;
      this.CCategorydata = categoriesData;

      this.options = {
        chart: {
          // type: 'bar',
          type: 'column',
          renderTo: 'container',
          events: {
            redraw: function (e) {
              this.options?.reflow();
            },
          },
        },
        title: {
          text: 'Meeting Status',
        },
        xAxis: {
          categories: this.CCategorydata,
        },
        yAxis: {},
        tooltip: {
          shared: true,
          pointFormat: '{series.name}: {point.y}(min)<br>',
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
          },
        },

        series: [
          {
            name: 'Not Done',
            data: NotDonedata,

            color: '#f71d3e',
          },
          {
            name: 'Done',
            // data: data,
            data: this.Cdata,
            color: '#281df6',
          },
        ],
      };

      let progress = new Chart(this.options);
      this.progresscontainer = progress;
      this.renderAttendanceChart(
        this.chartrange.value.fromDate,
        this.chartrange.value.toDate
      );
    }); //API End Point
  }

  renderAttendanceChart(fromDate: any, toDate: any): void {
    fromDate = new Date(new Date(fromDate).setHours(0, 0, 0, 0));
    toDate = new Date(new Date(toDate).setHours(23, 59, 59, 0));

    let data = [];
    let categoriesData = [];

    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
      fromDate: fromDate,
      toDate: toDate,
    };
    this.meetingDefinition
      .GetMeetingAttendanceRate(key)
      .subscribe((res: any) => {
        data = [];
        categoriesData = [];
        res.forEach(function (e: any): any {
          data.push(e.TotalPer);
          categoriesData.push(moment(e.StartDate).format('DD/MM/YYYY hh:mm a'));
        });
        this.options = {
          chart: {
            // type: 'bar',
            type: 'column',
          },
          title: {
            text: 'Meeting Attendance Rate',
          },
          xAxis: {
            categories: categoriesData,

            title: {
              text: null,
            },
          },
          yAxis: {
            min: 0,
            title: {
              text: null,
              align: 'high',
            },
            labels: {
              overflow: 'justify',
            },
          },

          tooltip: {
            valueSuffix: '%',
          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
              },
            },
          },
          legend: {
            enabled: true,
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: 'Attandance Ratio',
              //  data: [10, 31, 63, 20, 2, 100],
              data: data,

              color: '#7cfca8',
            },
          ],
        };
        let attendance = new Chart(this.options);
        this.attendancecontainer = attendance;
        this.renderEffectivenessChart(
          this.chartrange.value.fromDate,
          this.chartrange.value.toDate
        );
      });
  }
  //
  renderEffectivenessChart(fromDate: any, toDate: any): void {
    fromDate = new Date(new Date(fromDate).setHours(0, 0, 0, 0));
    toDate = new Date(new Date(toDate).setHours(23, 59, 59, 0));

    let data = [];
    let categoriesData = [];
    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
      fromDate: fromDate,
      toDate: toDate,
    };

    this.meetingDefinition
      .GetMeetingEffectivenessChart(key)
      .subscribe((res: any) => {
        data = [];
        categoriesData = [];
        res.forEach(function (e: any): any {
          data.push(parseFloat(e.TotalPer));
          categoriesData.push(moment(e.StartDate).format('DD/MM/YYYY hh:mm a'));
        });

        this.options = {
          chart: {
            // type: 'bar',
            type: 'column',
            renderTo: 'actionChart',
            events: {
              redraw: function (e) {
                this.options.reflow();
              },
            },
          },
          title: {
            text: 'Meeting Effectiveness Rate',
          },
          xAxis: {
            categories: categoriesData,

            title: {
              text: null,
            },
          },
          yAxis: {
            min: 0,
            title: {
              text: null,
              align: 'high',
            },
            labels: {
              overflow: 'justify',
            },
          },
          tooltip: {
            valueSuffix: '%',
          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
              },
            },
          },
          legend: {
            enabled: true,
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: 'Effectiveness Ratio',
              data: data, //[25, 10, 20, 18, 29, 13],
              color: '#e9694c',
            },
          ],
        };
        let effectiveness = new Chart(this.options);
        this.effectivenesscontainer = effectiveness;
        this.renderScheduleChart(
          this.chartrange.value.fromDate,
          this.chartrange.value.toDate
        );
      });
  }
  renderScheduleChart(fromDate: any, toDate: any): void {

    const key = {
      plantId: this.MeetingMatrixForm.value.plant,
      meetingDefId: this.selectedMeeting.meetingId,
      fromDate: fromDate,
      toDate: toDate,

    };

    this.meetingDefinition.GetMeetingScheduleChart(key).subscribe((res: any) => {
      let Planneddata = [];
      let categoriesData = [];
      let data = [];
      categoriesData = [];
      this.Cdata = [];
      let name = 'Value';
      let color = '';
      this.CCategorydata = [];
      res.forEach(function (e: any): any {

        Planneddata.push(parseFloat(e.Duration));

        if (e.Duration > e.Value) {
          //  name ='Acutal'
          color = '#27AE60'
          data.push({ y: e.Value, color: '#27AE60' });//Green
        } else {
          //name ='Exceeded'
          color = '#f71d3e'
          data.push({ y: e.Value, color: '#f71d3e' });//Red
        }

        categoriesData.push((moment(e.Date).format('DD/MM/YYYY hh:mm a')) + '<br>' + e.Createdby);
      });
      this.Cdata = data;
      this.CCategorydata = categoriesData;

      this.scheduleOptions = {
        chart: {
          // type: 'bar',
          type: 'column',
          renderTo: 'container',
          events: {
            redraw: function (e) {
              this.options?.reflow();
            },
          },
        },
        title: {
          text: 'Meeting Status',
        },
        xAxis: {
          categories: this.CCategorydata,
        },
        yAxis: {},
        tooltip: {
          shared: true,
          pointFormat: '{series.name}: {point.y}(min)<br>',
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },

        series: [

          {
            name: name,
            data: this.Cdata,
            color: color,
          },
          {
            name: 'Planned',
            data: Planneddata,
            color: '#3498DB',
          }


        ],
      };


      let progress = new Chart(this.scheduleOptions);
      this.schedulecontainer = progress;

    });
  }
  removeAgendaRow(value: any): void {
    if (this.meetingStarted === true) {
      this.meetingAgendasArray.forEach((element, index) => {
        if (element.agenda == value) this.meetingAgendasArray.splice(index, 1);
      });
    } else {
      this.toaster.warning('First Start Meeting');
    }
  }

  actionAddClick(): void {
    this.isKpiAddToAction = false;
    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
    };
    this.getUserList();
    this.meetingService.getMeetingAction(key).subscribe((data) => {
      for (var d of data) {
        if (d.newtargetdate != null && d.newtargetDate != '') {
          d.newtargetdate = moment(d.newtargetdate).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        }
        if (d.completiondate != null && d.completiondate != '') {
          d.completiondate = moment(d.completiondate).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        }
      }
      this.rowData1 = data;
      this.MatrixForm.get('meetingDefName')?.setValue(
        this.selectedMeeting.meetingName
      );
      this.MatrixForm.get('meetingDefId')?.setValue(
        this.selectedMeeting.meetingId
      );
      this.openModal();
    });
  }

  meetingSearch(): void {
    const searchedArray: any = [];
    this.meetingOriginalArray.forEach((element) => {
      if (
        element.meetingName.toLowerCase().includes(this.search.toLowerCase())
      ) {
        searchedArray.push(element);
      }
    });
    const frequencies = [
      ...new Set(
        searchedArray.map((c: { frequencyName: any }) => c.frequencyName)
      ),
    ];
    const formattedMeetingsArray: any = [];
    frequencies.forEach((freq) => {
      const obj = {
        frequencyName: freq,
        meetings: searchedArray
          ?.filter((x: any) => x.frequencyName === freq)
          .map((item: any) => {
            return {
              meetingId: item?.meetingId,
              meetingName: item?.meetingName,
              kpiName: item?.kpiName,
              frequencyId: item?.frequencyId,
              frequencyName: item?.frequencyName,
              meetingMasterId: item?.meetingMasterId,
              meetingType: item?.type,
            };
          }),
      };
      formattedMeetingsArray.push(obj);
    });
    this.filtereMeetings = formattedMeetingsArray;
  }
  onCellValueChanged(params: any): void {
    const col = params.colDef.field;
    if (col === 'Status') {
      const compDate = new Date();
      this.cellData = {
        status: params.data.Status,
        meetingActionId: params.data.meetingActionId,
        createdBy: JSON.parse(localStorage.user).userId,
        completionDate: moment(compDate).format('MM/DD/YYYY'),
      };
      this.meetingService.setCompletionDate(this.cellData).subscribe(
        (response: any) => {
          this.toaster.success('Success', 'Data Added Successfully');
          const key = {
            meetingDefId: this.selectedMeeting.meetingId,
          };
          this.meetingService.getMeetingAction(key).subscribe((data) => {
            for (var d of data) {
              if (d.newtargetdate != null && d.newtargetDate != '') {
                d.newtargetdate = moment(d.newtargetdate).format(
                  'DD/MM/YYYY HH:mm:ss'
                );
              }
              if (d.completiondate != null && d.completiondate != '') {
                d.completiondate = moment(d.completiondate).format(
                  'DD/MM/YYYY HH:mm:ss'
                );
              }
            }
            this.rowData1 = data;
            this.MatrixForm.get('meetingDefName')?.setValue(
              this.selectedMeeting.meetingName
            );
            this.MatrixForm.get('meetingDefId')?.setValue(
              this.selectedMeeting.meetingId
            );
          });
        },
        (error: any) => {
          return this.toaster.error('Error', error.message);
        }
      );
    } else if (col === 'completiondate') {
      const dateString =
        params.data.completiondate === null ? null : params.data.completiondate;
      let dateObject = ''; // Oct 23
      if (dateString != null) {
        dateObject = this.convertDate(params.data.completiondate);
      }
      const targetDate = new Date(
        this.convertDate(params.data.targetdate).replace(/-/g, '/')
      );
      const completionDate = new Date(
        this.convertDate(params.data.completiondate).replace(/-/g, '/')
      );
      const newTargetDate = params.data.newtargetdate;
      // if (completionDate >= targetDate) {
      // params.data.Status = 'Closed';
      this.cellData = {
        status: 'Closed',
        completionDate: dateObject,
        meetingActionId: params.data.meetingActionId,
        createdBy: JSON.parse(localStorage.user).userId,
      };
      this.meetingService.setCompletionDate(this.cellData).subscribe(
        (response: any) => {
          this.toaster.success('Success', col + 'Status Added Successfully');
          params.node.setDataValue('Status', params.data.Status);
        },
        (error: any) => {
          return this.toaster.error('Error', error.error.message);
        }
      );
      this.gridOptions1.suppressClickEdit = false;
      return;
      // }
      // else {
      //   this.toaster.error('Completion Date should be grater than target date');
      //   // params.node.setDataValue('completiondate', '');
      //   const key = {
      //     meetingDefId: this.selectedMeeting.meetingId,
      //   };
      //   this.meetingService.getMeetingAction(key).subscribe((data) => {
      //     this.rowData1 = data;
      //   });
      //   // return params.oldValue;
      // }
    } else if (col === 'newtargetdate') {
      let dateObject;
      if (params.data.newtargetdate) {
        dateObject = this.convertDate(params.data.newtargetdate);
      }

      //removed check for new target date less then or equal to prev target date and new lines below
      const oldTargetDate = this.convertDate(
        moment(params.data.targetdate).format('DD/MM/YYYY')
      );
      const newTargetDate = dateObject;
      params.data.Status = 'Delayed';
      params.data.misses = params.data.misses + 1;
      this.cellData = {
        newTargetDate: dateObject,
        status: params.data.Status,
        completionDate: null,
        meetingActionId: params.data.meetingActionId,
      };
      var newDateArr = newTargetDate.split('-');
      var oldDateArr = oldTargetDate.split('-');
      if (
        parseInt(newDateArr[0]) >= parseInt(oldDateArr[0]) &&
        parseInt(newDateArr[1]) >= parseInt(oldDateArr[1]) &&
        parseInt(newDateArr[2]) >= parseInt(oldDateArr[2])
      ) {
        this.meetingService.setTargetDate(this.cellData).subscribe(
          (response: any) => {
            this.toaster.success('Success', 'Data Added Successfully');
            params.node.setDataValue('misses', params.data.misses);
            params.node.setDataValue('Status', 'Delayed');
          },
          (error: any) => {
            return this.toaster.error('Error', error.message);
          }
        );
      } else {
        this.toaster.error('New Target Date should be grater than Target Date');
        const key = {
          meetingDefId: this.selectedMeeting.meetingId,
        };
        this.meetingService.getMeetingAction(key).subscribe((data) => {
          for (var d of data) {
            if (d.newtargetdate != null && d.newtargetDate != '') {
              d.newtargetdate = moment(d.newtargetdate).format(
                'DD/MM/YYYY HH:mm:ss'
              );
            }
            if (d.completiondate != null && d.completiondate != '') {
              d.completiondate = moment(d.completiondate).format(
                'DD/MM/YYYY HH:mm:ss'
              );
            }
          }
          this.rowData1 = data;
        });
        return;
      }

      //new lines end

      /*if (newTargetDate >= oldTargetDate) {
        params.data.Status = 'Delayed';
        params.data.misses = params.data.misses + 1;
        this.cellData = {
          newTargetDate: dateObject,
          status: params.data.Status,
          completionDate: null,
          meetingActionId: params.data.meetingActionId,
        };
        this.meetingService.setTargetDate(this.cellData).subscribe(
          (response: any) => {
            this.toaster.success('Success', 'Data Added Successfully');
            params.node.setDataValue('misses', params.data.misses);
            params.node.setDataValue('Status', 'Delayed');
          },
          (error: any) => {
            return this.toaster.error('Error', error.message);
          }
        );
      } else {
        this.toaster.error('New Target Date should be grater than Target Date');
        const key = {
          meetingDefId: this.selectedMeeting.meetingId,
        };
        this.meetingService.getMeetingAction(key).subscribe((data) => {
          this.rowData1 = data;
        });
        return;
      }
      */
    }
    return;
  }
  onClicktab1(): void {
    this.tab1 = true;
    this.tab2 = false;
  }
  onClicktab2(): void {
    this.tab1 = false;
    this.tab2 = true;
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onCellClickedMeeting(params: any) {
    this.boolRowIndex = params.rowIndex;
    this.boolColId = params.colId;
    let str;
    if (params.colDef.field === 'status') {
      str = params.data.unit;
      this.boolList = str.split('/');
      this.openBoolList();
    }
  }

  saveStatus(qualityBoolVal: any): void {
    const itemArr: any[] = [];
    this.ScoregridApi.forEachLeafNode((node: any) => {
      itemArr.push(node);
    });
    const filterIndex: any = itemArr.filter(
      (c) => c.rowIndex === this.boolRowIndex
    );

    const rowNode = this.ScoregridApi.getRowNode(filterIndex[0].id);
    rowNode.setDataValue('status', qualityBoolVal);
    this.dialog.closeAll();
  }
  clear(): void {
    this.dialog.closeAll();
    this.TierMeetingData = [];
    this.tierMeetingRowData;
  }

  SaveMeeting() {
    this.DataSave = [];

    let area;
    let HoldArea;
    for (let i = 0; i < this.rowDatasscoreCard.length; i++) {
      if (this.rowDatasscoreCard[i].area == undefined) {
        area = HoldArea;
      } else {
        area = this.rowDatasscoreCard[i].area;
        HoldArea = this.rowDatasscoreCard[i].area;
      }
      const SaveRecord = {
        AttainMeetingQuestionId: 0,
        ParticipantAttendId: this.lines[0].PL_Id,
        MeetingId: this.selectedMeeting?.meetingId,
        Area: area,
        Question: this.rowDatasscoreCard[i].questions,
        Unit: this.rowDatasscoreCard[i].unitId,
        Target: this.rowDatasscoreCard[i].target,
        Status: this.rowDatasscoreCard[i].status,
        Remarks: this.rowDatasscoreCard[i].remarks,
      };

      this.DataSave.push(SaveRecord);
      this.meetingService.SaveMeeting(this.DataSave).subscribe(
        (data) => {
          this.toaster.success('Success', 'Record Saved successfully');
        },
        (error: any) => {
          this.toaster.error('Error', 'Record not Saved successfully');
        }
      );
    }
  }

  onScoreGridReady(params: any): any {
    this.ScoregridApi = params.api;
    this.ScoregridColumnApi = params.columnApi;
    this.ScoregridApi.sizeColumnsToFit();
    this.ScoregridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#ScoreGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }
  onGridReadyEdit(params: any): any {
    this.gridApiEdit = params.api;
    this.gridColumnApiEdit = params.columnApi;
  }

  openNotesAgendaModal() {
    this.dialog.open(this.viewNotesAgenda, {
      panelClass: 'meeting-notes-container',
      height: 'auto',
      width: '70%',
    });
    this.getCurrentMeetingNotes();
  }

  viewMeetingsAgendas(): void {
    this.dialog.open(this.viewAgendasSheet, { height: '50vh', width: '70%' });
    for (const x of this.meetingAgendas) {
      const tempAgenda = this.meetingAgendasArray.filter(
        (meet: any) => x.MeetingAgendaName === meet.agenda
      );
      if (tempAgenda.length === 0) {
        this.meetingAgendasArray.push({ agenda: x.MeetingAgendaName });
      }
    }
  }
  onkpiGridReady(params: any): void {
    this.kpigridApi = params.api;
    this.kpigridColumnApi = params.columnApi;
  }

  onFirstDataRendered(params) {
    // this.kpigridApi.getDisplayedRowAtIndex(0).setSelected(true);
  }
  // onScoreCardGridFirstDataRendered(params: any) {
  //   this.enableGlidePathChange({ checked: false });
  // }
  getAllMeetingAgendas(): void {
    const key = {
      meetingDefId: this.selectedMeeting?.meetingId,
    };
    this.meetingService.getAllMeetingAgendas(key).subscribe(
      (response) => {
        this.meetingAgendas = response;
        this.meetingAgendas.forEach((x: any) => {
          this.meetingAgendasArray.push({ agenda: x.MeetingAgendaName });
        });
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }
  closeSheet(event: MouseEvent): void {
    this.dialog.closeAll();
    event.preventDefault();
  }
  // meeting suggested actions
  openMeetingSuggestedModal(): void {
    this.getUserList();
    this.dialog.open(this.suggestedActionModal, {
      height: '90%',
      width: '90%',
    });
    this.getSuggestedActions();
  }

  enableGlidePathChange(event: any): void {
    const instance = this.scoreCardGridApi.getFilterInstance('Type');

    let Type = event.checked ? ['Glidepath', 'Actual'] : ['Actual'];

    let myArray = Type;
    instance?.setModel({
      values: myArray.length ? myArray : [],
    });
    this.scoreCardGridApi?.onFilterChanged();
  }
  enableGlidePathChangeTalkSheet(event: any) {
    const instance = this.kpigridApi.getFilterInstance('Type');

    let Type = event.checked ? ['Glidepath', 'Actual'] : ['Actual'];

    let myArray = Type;
    instance?.setModel({
      values: myArray.length ? myArray : [],
    });
    this.kpigridApi?.onFilterChanged();
  }
  getSuggestedActions(): void {
    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
    };
    this.meetingService.getMeetingSuggestedActions(key).subscribe(
      (response) => {
        this.columnDefsSuggested = [
          {
            field: 'meetingSuggestedActionId',
            headerName: 'Actions',
            sortable: true,
            resizable: true,
            wrapText: true,
            width: 75,
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
              onClick: this.deleteMeetingSuggested.bind(this),
              label: 'Delete',
            },
          },
          {
            field: 'action',
            headerName: 'Suggested Action',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'meetingName',
            headerName: 'Meeting Name',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'responsibleName',
            headerName: 'Responsible',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'completionDate',
            headerName: 'Completion Date',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: 'agDateColumnFilter',
            cellEditor: 'primeCellEditor',
            editable: (params: any) => params.data.Status !== 'Closed',
            filterParams: {
              comparator: myDateComparator,
            },
            cellStyle: {
              color: 'black',
              'background-color': '#EDF1E5',
            },
          },
        ];
        this.meetingSuggestedActions = response;
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }

  addSuggestedAction(): void {
    if (this.meetingStarted === true) {
      const { action, completionDate, responsibleId } =
        this.meetingSuggestedActionForm.value;
      const data = {
        meetingDefId: this.selectedMeeting.meetingId,
        action,
        completionDate,
        responsibleId,
      };
      this.meetingService.addMeetingSuggestedAction(data).subscribe(
        (response) => {
          this.toaster.success('Meeting suggested action added successfully');
          this.getSuggestedActions();
        },
        ({ error }) => {
          this.toaster.error(error.message);
        }
      );
    } else {
      this.toaster.warning('First Start Meeting');
    }
  }

  deleteMeetingSuggested({ rowData }: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Suggested Action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          meetingSuggestedActionId: rowData.meetingSuggestedActionId,
        };
        this.meetingService.deleteMeetingSuggestedAction(key).subscribe(
          (response) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your selected Action has been deleted.',
              icon: 'success',
              timer: 800,
              showConfirmButton: false,
            });
            this.getSuggestedActions();
          },
          ({ error }) => {
            this.toaster.error('Error', error.message);
          }
        );
      }
    });
  }

  onCellSuggestedValueChanged({ data }: any): void {
    const { meetingSuggestedActionId, completionDate } = data;
    const key = {
      meetingSuggestedActionId,
      completionDate: this.convertDate(completionDate),
    };

    // this.meetingService.updateSuggestedCompletionDate(key).subscribe(
    //   (response) => {
    //     this.toaster.success('Completion Date updated successfully');
    //   },
    //   ({ error }) => {
    //     this.toaster.error(error.message);
    //   }
    // );
  }

  // meeting participants

  updatedgetmeeting() {
    const key = {
      meetingId: this.selectedMeeting?.meetingId,
    };
    this.meetingService.getMeetingParticipants(key).subscribe(
      (res) => {

        this.meetingParticipants = res?.map(
          ({ participantId, participantName, reason, isAttended }) => {
            return {
              participantId,
              participantName,
              isAttended,
              reason,
              EmpInfo_Id: participantId,
            };
          }
        );
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }
  getMeetingParticipants(): void {
    const key = {
      meetingDefId: this.selectedMeeting?.meetingId,
    };
    this.meetingService.getMeetingParticipants(key).subscribe(
      (res) => {
        this.meetingParticipants = res?.map(
          ({ participantId, participantName, reason, isAttended, lateValue, isSpecial }) => {
            return {
              participantId,
              participantName,
              isAttended,
              reason,
              EmpInfo_Id: participantId,
              lateValue,
              isSpecial
            };
          }
        );
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }
  addManualPartcipent(): void {
    this.Special = true;
    const filterdata = this.meetingParticipants;
    const parti = filterdata.filter(
      (p: any) =>
        p.participantId === this.ManualParticepantForm.value.participant
    );
    if (parti.length > 0) {
      this.toaster.error('Error', 'Participant  Already Exist');
      return;
    }



    if (this.meetingStarted === true) {
      const key = {
        meetingId: this.selectedMeeting?.meetingId,
        responsible: this.ManualParticepantForm.get('participant')?.value,
      };
      this.toaster.success('Meeting participants added successfully');
      const participantName = this.filteredParticipants.find(
        (x: any) => x.EmpInfo_Id === key.responsible
      )?.fullName + '  (Guest)';



      this.meetingParticipants.push({
        participantId: key.responsible,
        participantName,
        isAttended: false,
        reason: '',
        lateValue: '',
        Special: true

      });
      if (this.selectedAll === true) {
        this.selectedAll = false;
      }
    } else {
      this.toaster.warning('First Start Meeting');
    }

  }

  RemoveSpecialPartcipent(event: number) {
    let index = this.meetingParticipants.findIndex(x => x.participantId == event);
    this.meetingParticipants.splice(index, 1);
  }

  onColumnResized(params: any) {
  }

  openUserModal(): void {
    this.ManualParticepantForm.get('participant').setValue('');
    this.dialog.open(this.displayUserModal, {
      panelClass: 'participants-container',
      height: 'auto',
      width: '70%',
    });
    this.getUserList();
  }
  openLastMeetingrModal(): void {
    this.isKpiAddToAction = false;
    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
    };
    this.getUserList();

    this.meetingService.getMeetingAction(key).subscribe((data) => {
      this.actionsrowData = data;

      this.MatrixForm.get('meetingDefName')?.setValue(
        this.selectedMeeting.meetingName
      );
      this.MatrixForm.get('meetingDefId')?.setValue(
        this.selectedMeeting.meetingId
      );
      this.dialog.open(this.displayLastMeetingModal, {
        height: 'auto',
        width: '80%',
      });
    });
  }

  participantAttended(event: any, id: number): void {
    const participant = this.meetingParticipants.find(
      (x) => x.participantId === id
    );
    if (participant) {
      // participant.isAttended = !participant.isAttended;
      // if (event.value === 'ontime' || event.value === 'late') {
      participant.isAttended = true;
      // } else {
      //   participant.isAttended = false;
      // }
    }


  }

  selectAllParticipants(event: any): void {
    this.selectedAll = event?.checked;
    this.meetingParticipants = this.meetingParticipants?.map(
      ({ participantId, participantName, reason }) => {
        return {
          participantId,
          participantName,
          isAttended: event?.checked,
          reason: event?.checked ? 'ontime' : '',
          EmpInfo_Id: participantId,
        };
      }
    );
  }

  saveMeetingAgenda(): void {
    if (this.meetingStarted === true) {
      const key = {
        agenda: this.AddAgendaForm.value.agenda,
      };
      if (this.AddAgendaForm.value.agenda !== '') {
        this.meetingAgendasArray.push(key);
        this.AddAgendaForm.reset();
      }
    } else {
      this.toaster.warning('First Start Meeting');
    }
  }
  saveMeetingNotes(): void {
    if (!this.meetingStarted) {
      this.toaster.warning('First Start Meeting');
      return;
    }
    let data = {
      meetingAttendId: this.currentMeetingID,
      ...this.AddNotesForm.value,
      createdBy: JSON.parse(localStorage.getItem('user')).UserId,
    };
    this.meetingService.addMeetingNotes(data).subscribe(
      (response: any) => {
        this.AddNotesForm.reset();
        this.getCurrentMeetingNotes();
      },
      (err: any) => {
        this.getCurrentMeetingNotes();
      }
    );
  }

  getCurrentMeetingNotes() {
    this.meetingService
      .getCurrentMeetingNotes({ meetingAttendId: this.currentMeetingID })
      .subscribe(
        (response: any) => {
          if (response.length) {
            this.meetingNotes = response;
          }
        },
        (err: any) => {
        }
      );
  }
  startMeeting(): any {
    this.PopUpOccured = 0;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.LeaderId = Number(localStorage.getItem('leaderId'));
    this.nodeHeaderId = 0;

    this.DataSave = [];
    const key = {
      meetingDefId: this.selectedMeeting?.meetingId,
    };
    if (this.meetingStarted === false) {
      this.meetingService.getMeetingFrequencyStatus(key).subscribe(
        (response) => {
          if (response?.length > 0) {
            const heldMeeting = new Date(response[0]?.MeetingStartTime);
            const currentDate = new Date();
            const date = new Date();
            const quarter = Math.floor(currentDate.getMonth() / 3);
            const quarterFirstDate = new Date(
              currentDate.getFullYear(),
              quarter * 3,
              1
            );
            const quarterEndDate = new Date(
              quarterFirstDate.getFullYear(),
              quarterFirstDate.getMonth() + 3,
              0
            );
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const firstDayOfBiMonth =
              currentDate.getDay() > 15
                ? new Date(currentYear, currentMonth, 16)
                : new Date(currentYear, currentMonth, 1);
            const lastDayBiOfMonth =
              currentDate.getDay() > 15
                ? new Date(currentYear, currentMonth + 1, 0)
                : new Date(currentYear, currentMonth, 15);
            const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
            const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
            const firstDayOfWeek = new Date(
              date.setDate(date.getDate() - date.getDay())
            );
            const lastDayOfWeek = new Date(
              date.setDate(date.getDate() - date.getDay() + 7)
            );
            const frequency = response[0]?.Name;
          }
          this.meetingStarted = true;

          if (this.LeaderId > 0) {
            this.enabledopenTierMeeting = true;
          }
          // else{
          //   this.enabledopenTierMeeting= false;
          // }

          // this.enabledopenTierMeeting= true;
          this.pausebutton = true;
          this.meetingStoped = false;
          this.meetingStartTime = new Date();

          this.interval = setInterval(() => {
            this.seconds = this.seconds + 1;
            if (this.seconds === 60) {
              this.minutes = this.minutes + 1;
              this.seconds = 0;
            }
            if (this.minutes === 60) {
              this.hours = this.hours + 1;
              this.minutes = 0;
              this.seconds = 0;
            }
            this.timer =
              ('0' + this.hours).slice(-2) +
              ':' +
              ('0' + this.minutes).slice(-2) +
              ':' +
              ('0' + this.seconds).slice(-2);

            let meetingDuration = this.MeetingDuration;
            let Minutes = new Date("01/01/2011 " + this.timer).getMinutes();
            let hours = new Date("01/01/2011 " + this.timer).getHours();
            //var Days  = Time / (1000 * 3600 * 24);
            let mint = Number(Minutes) + (Number(hours) * 60);

            let TimeLeft = Number(meetingDuration) - Number(mint);
            // this.rescheduleForm.get('duration').setValue();
            if (TimeLeft <= 5 && this.PopUpOccured == 0) {
              this.PopUpOccured = 1;
              const dialogRef = this.dialog.open(this.ExtendMeeting, {
                height: 'auto',
                width: '38%',
                disableClose: true,
              });

            }
            if (TimeLeft <= 0) {
              clearInterval(this.interval);
              this.meetingEndTime = new Date();
              this.meetingStarted = false;

              this.enabledopenTierMeeting = false;
              this.meetingStoped = true;
              this.nodeHeaderId = 0;
              const domainUrl = document.location.origin;
              const meetingId = this.selectedMeeting?.meetingId;
              const meetingName = this.selectedMeeting?.meetingName;
              const groupId = parseInt(this.MeetingMatrixForm.value.group, 10);
              const plantId = parseInt(this.MeetingMatrixForm.value.plant, 10);
              const meetingType = this.selectedMeeting?.meetingType;
              // this.meetingOriginalArray.find((x) => x.meetingId === meetingId)?.type;

              const data = {
                timezone: timezone,
                meetingAttendId: this.currentMeetingID,
                MeetingDefId: this.selectedMeeting?.meetingId,
                meetingName: this.selectedMeeting?.meetingName,
                EvaluationUrl:
                  meetingType === 'Evaluation Check List'
                    ? domainUrl +
                    `/meeting-evaluation?plant=${plantId}&meeting=${meetingId}&group=${groupId}`
                    : domainUrl +
                    `/meeting-evaluation-level?plant=${plantId}&meeting=${meetingId}`,

                MeetingStartTime: this.meetingStartTime,
                MeetingEndTime: this.meetingEndTime,
                MeetingName: this.selectedMeeting?.meetingName,
                transactionType: 'EDIT',
                CreatedBy: JSON.parse(localStorage.user).UserId,
                UpdatedBy: JSON.parse(localStorage.user).UserId,

                agendaDetail: this.meetingAgendasArray,
                participantsAttended: this.meetingParticipants
                  ?.filter((x) => x.isAttended === true)
                  ?.map((item: any) => {
                    return {
                      participantId: item.participantId,
                      AttendanceStatus: item?.reason,
                      LateValue: item.lateValue
                    };
                  }),
              };

              this.spinner.show();
              let msg;
              this.meetingService.stopMeetingAndSendEmail(data).subscribe(
                (res) => {
                  msg = res;
                  this.spinner.show();
                  if (msg.message == 'Process Failed! Try again')
                    this.toaster.error(msg.message);
                  else
                    this.toaster.success(
                      msg.message
                      // 'Meeting Has been Ended And also mail have been sent to meeting attended'
                    );
                  this.resetMeeting();
                  this.renderprogressChart(
                    this.chartrange.value.fromDate,
                    new Date()
                  );
                  this.meetingNotes = [];
                  this.uncheckAllParticipents();
                  this.spinner.hide();
                },
                ({ error }) => {
                  this.toaster.error(error.message);
                  this.spinner.hide();
                }
              );
            }
          }, 1000);

          const data = {
            timezone: timezone,
            meetingAttendId: this.currentMeetingID,
            MeetingDefId: this.selectedMeeting?.meetingId,
            MeetingStartTime: new Date(),
            CreatedBy: JSON.parse(localStorage.user).UserId,
            transactionType: 'ADD',
          };

          this.meetingService.stopMeetingAndSendEmail(data).subscribe(
            (res: any) => {
              this.currentMeetingID = res[0].MeetingAttendId;
              this.analyticalToolsService.setHeaderId(0);
            },
            ({ error }) => {
              this.toaster.error(error.message);
            }
          );
        },
        ({ error }) => {
          this.toaster.error(error.message);
        }
      );
    } else {
      this.toaster.warning('Meeting has been started already');
    }
  }

  handleChange(event) {
    this.tabEvent = event;
  }
  onTDateChange() { }

  AddParticipantAttendMeeting() {
    let participantsAttendedObj = this.meetingParticipants
      ?.filter((x) => x.isAttended === true)
      ?.map((item: any) => {
        return {
          participantId: item.participantId,
          AttendanceStatus: item?.reason,
          LateValue: item.lateValue,
          isSpecial: item.Special,
        };
      })

    if (participantsAttendedObj.length == 0) {
      this.toaster.warning('Please Select Participants Status');
      return false;
    }
    else {
      const obj = {
        CreatedBy: JSON.parse(localStorage.user).UserId,
        participantsAttended: participantsAttendedObj,
        MeetingAttendId: this.currentMeetingID,
      }

      this.meetingService.addParticipantAttendMeeting(obj).subscribe(
        (res: any) => {
          if (res.isError == true)
            this.toaster.error(res.message)
          else
            this.toaster.success(res.message)
        });
    }
  }


  stopMeeting(): any {

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (
      this.meetingParticipants.filter((x) => x.isAttended === true).length > 0
    ) {
      if (this.meetingStarted === true) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to Stop the Meeting Right Now!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Stop it!',
          cancelButtonText: 'No, keep it',
        }).then((result) => {
          if (result.value) {
            clearInterval(this.interval);
            this.meetingEndTime = new Date();
            this.meetingStarted = false;

            this.enabledopenTierMeeting = false;
            this.meetingStoped = true;
            this.nodeHeaderId = 0;
            const domainUrl = document.location.origin;
            const meetingId = this.selectedMeeting?.meetingId;
            const meetingName = this.selectedMeeting?.meetingName;
            const groupId = parseInt(this.MeetingMatrixForm.value.group, 10);
            const plantId = parseInt(this.MeetingMatrixForm.value.plant, 10);
            const meetingType = this.selectedMeeting?.meetingType;
            // this.meetingOriginalArray.find((x) => x.meetingId === meetingId)?.type;

            const data = {
              timezone: timezone,
              meetingAttendId: this.currentMeetingID,
              MeetingDefId: this.selectedMeeting?.meetingId,
              meetingName: this.selectedMeeting?.meetingName,
              EvaluationUrl:
                meetingType === 'Evaluation Check List'
                  ? domainUrl +
                  `/meeting-evaluation?plant=${plantId}&meeting=${meetingId}&group=${groupId}`
                  : domainUrl +
                  `/meeting-evaluation-level?plant=${plantId}&meeting=${meetingId}`,

              MeetingStartTime: this.meetingStartTime,
              MeetingEndTime: this.meetingEndTime,
              MeetingName: this.selectedMeeting?.meetingName,
              transactionType: 'EDIT',
              CreatedBy: JSON.parse(localStorage.user).UserId,
              UpdatedBy: JSON.parse(localStorage.user).UserId,

              agendaDetail: this.meetingAgendasArray,
              participantsAttended: this.meetingParticipants
                ?.filter((x) => x.isAttended === true)
                ?.map((item: any) => {
                  return {
                    participantId: item.participantId,
                    AttendanceStatus: item?.reason,
                    LateValue: item.lateValue
                  };
                }),
            };

            this.spinner.show();
            let msg;
            this.meetingService.stopMeetingAndSendEmail(data).subscribe(
              (res) => {
                msg = res;
                this.spinner.show();
                if (msg.message == 'Process Failed! Try again')
                  this.toaster.error(msg.message);
                else
                  this.toaster.success(
                    msg.message
                    // 'Meeting Has been Ended And also mail have been sent to meeting attended'
                  );
                this.resetMeeting();
                this.renderprogressChart(
                  this.chartrange.value.fromDate,
                  new Date()
                );
                this.meetingNotes = [];
                this.uncheckAllParticipents();
                this.spinner.hide();
              },
              ({ error }) => {
                this.toaster.error(error.message);
                this.spinner.hide();
              }
            );
          }
        });
        return;
      }
      this.toaster.warning('Please start the meeting first');
    } else {
      this.toaster.warning('Please make sure Attendence first');
    }
  }
  openMeetingRescheduler() {
    sessionStorage.setItem('ptid', this.MeetingMatrixForm.value.plant);
    sessionStorage.setItem('MeetingName', this.selectmeeting);

    const dialogRef = this.dialog.open(MeetingRescheduleDialogComponent, {
      height: '90%',
      width: '90%',
      disableClose: false,
    });
    dialogRef.componentInstance.meetingDefId = this.meetingDefId2;
    dialogRef.afterClosed().subscribe((data) => { });
  }
  getFinancialGapList(fromDate: any, toDate: any) {

    const data = {
      fromDate: fromDate,
      toDate: toDate,
      meetingDefId: this.selectedMeeting.meetingId,
      frequencyId: this.MeetingMatrixFrequencyForm.value.frequency.frequencyId,
    };
    this.meetingService.GetKPIFinanicalGap(data).subscribe(
      (res: any) => {
        this.financialGapList = res;
        if (this.financialGapList.length) {
          this.financialGapList.forEach((e: any) => {
            const itemArr: any[] = [];
            this.scoreCardGridApi.forEachLeafNode((rowNode: any) => {
              if (
                rowNode.data.KPIId === e.KPIId &&
                rowNode.data.Type === 'Actual'
              ) {
                const record = this.scoreCardGridApi.getRowNode(rowNode.id);
                if (record) {
                  record.setDataValue('FinancialGap', e.FinanicalGap);
                }
              }
            });
          });
        }
      },
      (err: any) => {

      }
    );
  }

  resetMeeting(): void {
    clearInterval(this.interval);
    this.timer = '00:00:00';
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    this.meetingStartTime = undefined;
    this.meetingEndTime = undefined;
    this.meetingStarted = false;
    this.enabledopenTierMeeting == false;
    this.meetingAgendasArray = [];

    if (this.pausebutton == true && this.meetingStoped == false) {
      this.ResetMeeting();
    }

    this.pausebutton = false;
    this.meetingStoped = false;
  }

  ResetMeeting() {
    const Restkey = {
      MeetingDefId: this.selectedMeeting?.meetingId,
    };

    this.meetingService.ResetMeeting(Restkey).subscribe((res) => {
      this.nodeHeaderId = 0;
    });
  }

  convertDate(date: any): any {
    const dateParts = date.split('/');
    return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
  }
  openBoolList(): void {
    this.dialog.open(this.qualityBoolListModal, {
      height: 'auto',
      width: '45%',
    });
  }

  openTierMeeting(event: any): void {
    this.TierMeetingData = [];

    this.GetTierMeetingChecklist_Data(event);
    this.dialog.open(this.displaytierMeetingModal, {
      width: '95vw !important',
      height: '100%',
      minWidth: '95vw',
      maxHeight: '100%',
    });
  }
  oncellclicked(event: any) {
    if (event.data.meetingStatus === 'Completed') {
      this.enabledopenTierMeeting = false;
    } else {
      this.enabledopenTierMeeting = true;
    }
    this.openTierMeeting(event);
  }
  openMeetingCPStatus(): void {
    const obj = {
      meetingDefId: this.selectedMeeting.meetingId,
    };
    this.meetingDefinition.GetMeetingCPStatus(obj).subscribe((res: any) => {
      res.forEach(function (e: any): any {
        e.meetingStartTime = moment(e.meetingStartTime).format(
          'DD/MM/YYYY hh:mm a'
        );
      });

      this.rowData3 = res;
      this.dialog.open(this.displayMeetingPendingModal, {
        panelClass: 'custom-dialog-container',
        width: '30vw !important',
        height: '80vh',
        minWidth: '30vw',
        maxHeight: '80vh',
      });
    });
    // GetMeetingCPStatus
    //displaytierMeetingPendingModal
  }

  rowSpantier(params: any): any {
    if (params.data.areapercent && params.data.score) {
      return 9;
    } else {
      return 1;
    }
  }
  rowSpantier2(params: any): any {
    if (params.data.areapercent && params.data.score) {
      return 15;
    } else {
      return 1;
    }
  }
  isQuarterRow(params: ColSpanParams) {
    return params.data.section === 'quarters';
  }
  onTierGridReady(params: any): void {
    this.TiergridApi = params.api;
    this.TiergridApi.sizeColumnsToFit();
    this.TiergridApi.setDomLayout('autoHeight');
    const e: HTMLElement = document.querySelector('#tierGrid') as HTMLElement;
    if (e !== null) {
      e.style.height = '';
    }
  }

  onTier2GridReady(params: any): void {
    this.Tier2gridApi = params.api;
    this.Tier2gridApi.sizeColumnsToFit();
    this.Tier2gridApi.setDomLayout('autoHeight');
    const e: HTMLElement = document.querySelector('#tierGrid2') as HTMLElement;
    if (e !== null) {
      e.style.height = '';
    }
  }
  onFeedbackGridReady(params: any): void {
    this.feedbackgridApi = params.api;
    this.feedbackgridApi.sizeColumnsToFit();
    this.feedbackgridApi.setDomLayout('autoHeight');
    const e: HTMLElement = document.querySelector(
      '#feedBackGrid'
    ) as HTMLElement;
    if (e !== null) {
      e.style.height = '';
    }
  }
  onAddRowGrid() {
    this.Tier2gridApi.updateRowData({
      add: [
        {
          areapercent: '',
          problemDesc: '',
          repetetive: '',
          preventive: '',
          corrective: '',
          rootCause: '',
          score: '',
        },
      ],
    });
  }
  onAddRowFeedBackGrid() {
    this.feedbackgridApi.updateRowData({
      add: [
        {
          feedBack: '',
        },
      ],
    });
  }
  get f() {
    return this.MeetingMatrixTalkSheetForm.controls;
  }

  UpdateActionPlan(): void {
    this.Status = '';
    this.Status =
      this.editActionPlanForm.value.targetDate &&
        !this.editActionPlanForm.value.revisedDate
        ? 'InProgress'
        : this.editActionPlanForm.value.targetDate &&
          this.editActionPlanForm.value.revisedDate
          ? 'Delayed'
          : 'Open';

    if (this.editActionPlanForm.value.targetDate) {
      this.targetDate = this.editActionPlanForm.value.targetDate;
      this.targetDate = new Date(this.targetDate);
      this.targetDate.setHours(new Date().getHours());
      this.targetDate.setMinutes(new Date().getMinutes());
      this.targetDate.setSeconds(new Date().getSeconds());
    }
    if (this.editActionPlanForm.value.revisedDate) {
      this.RevisedDate = this.editActionPlanForm.value.revisedDate;
      this.RevisedDate = new Date(this.RevisedDate);
      this.RevisedDate.setHours(new Date().getHours());
      this.RevisedDate.setMinutes(new Date().getMinutes());
      this.RevisedDate.setSeconds(new Date().getSeconds());
    }
    if (this.editActionPlanForm.value.completed === 'Yes') {
      this.Status = 'Complete';
      var templateBody = null;
      var plantName = null;

      var plant = this.plants.find(
        (x) => x.DeptId == this.MeetingMatrixForm.value.plant
      );
      plantName = plant.DeptDesc;
      var date = null;
      var user = this.userList.find(
        (x) => x.EmpInfo_Id == this.editActionPlanForm.value.assignTo
      );
      if (
        this.editActionPlanForm.controls.revisedDate.value >
        this.editActionPlanForm.controls.targetDate.value
      ) {
        date = this.editActionPlanForm.controls.revisedDate.value;
      } else {
        date = this.editActionPlanForm.controls.targetDate.value;
      }
      if (this.editActionPlanForm.controls.verification.value == 'Approved') {
        templateBody =
          `<div style="font-family: Helvetica; display: flex; flex-direction: column;">
        <div style="display: flex;background-color:#1d4ed8; color: white; padding: 2rem">
            <h2 style="margin-right: auto">O3 SMART MANUFACTURING</h2>
            <img src="https://res.cloudinary.com/durdmvneg/image/upload/v1629181792/logo-full_ku1lp4.png" style="display: block; border: 0px; object-fit:contain ;" />
        </div>
        <h2 style="margin-left: auto; margin-right: auto; margin-top: 1rem; font-weight: 800">Action Plan Detail</h2>
        <div style="background-color: #f3f4f6; padding: 1rem; display: flex;">
            <div style="width: 20%;font-weight: 800;">Plant: </div><div style="width: 80%;font-weight: 800;">` +
          plantName +
          `</div>
        </div>
        <table style="margin-top: 1.5rem;">
            <tr>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Action Plan</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Created Date</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Target Date</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Responsible</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Status</th>
            </tr>
            <tr>
                <td style='text-align: center'>` +
          this.editActionPlanForm.controls.actions.value +
          `</td>
                <td style='text-align: center'>` +
          moment(this.currentNodeDate).format('DD/MM/YYYY hh:mm:ss') +
          `</td>
                <td style='text-align: center'>` +
          moment(date).format('DD/MM/YYYY hh:mm:ss') +
          `</td>
                <td style='text-align: center'>` +
          user.FullName +
          `</td>
                <td style='text-align: center'>Closed</td>
            </tr>
        </table>
    </div>`;
      } else {
        templateBody =
          `<div style="font-family: Helvetica; display: flex; flex-direction: column;">
        <div style="display: flex;background-color:#1d4ed8; color: white; padding: 2rem">
            <h2 style="margin-right: auto">O3 SMART MANUFACTURING</h2>
            <img src="https://res.cloudinary.com/durdmvneg/image/upload/v1629181792/logo-full_ku1lp4.png" style="display: block; border: 0px; object-fit:contain ;" />
        </div>
        <h2 style="margin-left: auto; margin-right: auto; margin-top: 1rem; font-weight: 800">Action Plan Detail</h2>
        <div style="background-color: #f3f4f6; padding: 1rem; display: flex;">
            <div style="width: 20%;font-weight: 800;">Plant: </div><div style="width: 80%;font-weight: 800;">` +
          plantName +
          `</div>
        </div>
        <table style="margin-top: 1.5rem;">
            <tr>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Action Plan</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Created Date</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Target Date</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Responsible</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Status</th>
            </tr>
            <tr>
                <td style='text-align: center'>` +
          this.editActionPlanForm.controls.actions.value +
          `</td>
                <td style='text-align: center'>` +
          moment(this.currentNodeDate).format('DD/MM/YYYY hh:mm:ss') +
          `</td>
                <td style='text-align: center'>` +
          moment(date).format('DD/MM/YYYY hh:mm:ss') +
          `</td>
                <td style='text-align: center'>` +
          user.FullName +
          `</td>
                <td style='text-align: center'>Completed</td>
            </tr>
        </table>
    </div>`;
      }
      var emailParam = {
        Body: templateBody,
        ToEmailAddress: this.currentAssigneeEmail,
        CCEmails: this.currentResponsibleEmail,
        Subject: 'Action Completion',
      };
      this.actionplanService.sendMail(emailParam).subscribe(
        (res) => {
          // this.toaster.success("Mail sent", "Success");
        },
        ({ error }) => {
          this.toaster.error('Cannot Send Mail', 'Error');
        }
      );
    }
    if (this.editActionPlanForm.value.verification === 'Approved') {
      this.Status = 'Closed';
      // fiveWhy.ClosedDate = new Date();
    } else if (this.editActionPlanForm.value.verification === 'Disapproved') {
      this.Status = 'ReOpened';
      // fiveWhy.ActionCompleted = 'No';
      this.editActionPlanForm.get('completed').setValue('No');
    }

    const formdata = new FormData();
    formdata.append('file', this.fileSave);
    formdata.append('image', this.ImageSave);
    formdata.append('closefile', this.closefile);
    const key = {
      actionId: this.data.ID,
      actionName: this.editActionPlanForm.value.actions,
      actionType: this.editActionPlanForm.value.actionType,
      responsible: this.editActionPlanForm.value.assignTo,
      targetDate: this.targetDate ? this.targetDate : null,
      revisedDate: this.RevisedDate ? this.RevisedDate : null,
      ActionCompleted: this.editActionPlanForm.value.completed,
      Verification: this.editActionPlanForm.value.verification,
      remarks: this.editActionPlanForm.value.remarks,
      NewAssignee: this.editActionPlanForm.value.NewAssignee
        ? this.editActionPlanForm.value.NewAssignee
        : null,
      status: this.Status,
      userId: JSON.parse(localStorage.user).UserId,
      rootCause: this.editActionPlanForm.value.rootcause
    };
    formdata.append('values', JSON.stringify(key));
    this.actionplanService.editActionPlan(formdata).subscribe((res: any) => {
      if (res[0].Column1 === 'Updated Successfully') {
        this.toaster.success('Success', `Updated Sucessfully `);
        this.getOverallMeetingActions();
        this.currentDialog.close();
        this.showinput = false;
      }
    });
    // }
  }

  imageZoomModal(): void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      height: '90%',
      width: '90%',
      data:
        this.data.FileSrc || environment.O3CoreApiImageUrl + this.data.UploadFile,
    });
    // this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  deleteFile(type: any): void {
    if (type === 'pic') {
      this.data.FileSrc = '';
      this.data.UploadFile = '';
      this.ImageSave = new File([''], 'filename');
    } else {
      this.data.DocSrc = '';
      this.data.UploadDoc = '';
      this.fileSave = new File([''], 'filename');
    }
  }
  openFile(type?: any): void {
    if (type === 'pic') {
      this.fileInput.nativeElement.click();
    } else {
      this.fileInputFile.nativeElement.click();
    }
  }

  readFile(event: any, type: any): void {
    this.imageFile = event.target.files[0];
    if (type === 'pic') {
      this.data.UploadFile = this.imageFile;
      this.ImageSave = event.target.files[0];
    } else {
      this.data.UploadDoc = this.imageFile;
      this.fileSave = event.target.files[0];
    }
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        if (type === 'pic') {
          this.data.FileSrc = this.imageSrc;
        } else {
          this.data.DocSrc = this.imageSrc;
        }
      };
    }
  }

  downloadPDF() {
    // [href]=" yData.DocSrc || blobStorageUrl + yData.UploadDoc"
    if (this.data.DocSrc) {
      const base64 = this.data.DocSrc;
      var a = document.createElement('a'); //Create <a>
      a.href = base64; //Image Base64 Goes here
      a.target = '_blank';
      a.download = 'file.pdf'; //File name Here
      a.click();
    } else {
      var a = document.createElement('a'); //Create <a>
      a.href = this.blobStorageUrl + this.data.UploadDoc; //Image Base64 Goes here
      a.download = 'file.pdf';
      a.target = '_blank'; //File name Here
      a.click();
    }
  }

  getMinDate(type: any): any {
    if (type === 'Revised') {
      const date = this.data.RevisedDate
        ? this.data.RevisedDate
        : this.data.TargetDate;
      const currentDate = new Date(date);

      const minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate() + 1)
      );

      return (current: Date): boolean =>
        differenceInCalendarDays(current, minDateStartFrom) < 0;
    } else if (type === 'Target') {
      const currentDate = new Date();
      const minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate())
      );

      return (current: Date): boolean =>
        differenceInCalendarDays(current, minDateStartFrom) < 0;
    }
  }

  targetChange(): void {
  }

  onChangeComplete(): void {
    if (this.editActionPlanForm.value.completed === 'Yes') {
      this.disableVerification = false;
      this.editActionPlanForm.get('verification').setValue('');
    }
  }

  onChangeVerification(): void {
    if (this.editActionPlanForm.value.verification === 'Disapproved') {
      this.disableNewAssigne = false;
    } else if (this.editActionPlanForm.value.verification !== 'Disapproved') {
      this.disableNewAssigne = true;
    }
    if (this.editActionPlanForm.value.verification === 'Approved') {
      this.showinput = true;
    } else {
      this.showinput = false;
    }
  }

  EditRow(params: any): void {
    this.headerId = params?.data?.HeaderId;
    if (params) {
      this.data = [];
      this.ImageSave = null;
      this.fileSave = null;
      this.closefile = null;
      this.currentNodeDate = params.node.data.ObservedDate;
      this.currentAssigneeEmail = params.node.data.AssigneEmail;
      this.currentResponsibleEmail = params.node.data.ParticpantEmails;
      this.data = params.node.data;
      this.ModuleName = params.node.data.Module;
      this.UniqueCode = params.node.data.UniqueCode;
      this.editActionPlanForm.get('actions').setValue(params.node.data.Action);
      this.editActionPlanForm.get('rootcause').setValue(params.node.data.RootCause);
      this.editActionPlanForm
        .get('assignTo')
        .setValue(params.node.data.AssignTo);
      this.editActionPlanForm
        .get('completed')
        .setValue(params.node.data.ActionCompleted);
      this.editActionPlanForm.get('remarks').setValue(params.node.data.Remarks);
      this.editActionPlanForm.get('remarks').setValue(params.node.data.Remarks);
      this.editActionPlanForm
        .get('NewAssignee')
        .setValue(params.node.data.NewAssigneeId);
      if (params.node.data.ActionCompleted === 'No') {
        this.disableVerification = true;
      }
      if (params.node.data.Verification === 'Disapproved') {
        this.disableNewAssigne = false;
      }
      this.editActionPlanForm
        .get('verification')
        .setValue(params.node.data.Verification);
      this.editActionPlanForm
        .get('actionType')
        .setValue(params.node.data.ActionType);
      if (params.node.data.RevisedDate !== null) {
        this.editActionPlanForm
          .get('revisedDate')
          .setValue(
            this.datePipe.transform(
              new Date(params.node.data.RevisedDate),
              'yyyy-MM-dd'
            )
          );
      }
      if (params.node.data.TargetDate !== null) {
        this.editActionPlanForm
          .get('targetDate')
          .setValue(
            this.datePipe.transform(
              new Date(params.node.data.TargetDate),
              'yyyy-MM-dd'
            )
          );
      }
      this.targetDate = params.node.data.TargetDate;

      if (params.data.UploadFile) {
        this.data.UploadFile = params.data.UploadFile;
      }
      // this.editActionPlanForm.get('targetDate').setValue(params.data.TargetDate) ;
      this.currentDialog = this.dialog.open(this.actionPlanModal, { height: 'auto', width: '90%' });
    }
  }

  openFullCalendar() {
    let dialogRef = this.dialog.open(Full_CalendarComponent, {
      height: '100vh',
      width: '100%',
    });

    dialogRef.componentInstance.plantId = this.MeetingMatrixForm.value.plant;
  }

  OpenFullCalendarByMeetingId() {
    let dialogRef = this.dialog.open(Full_CalendarComponent, {
      height: '100vh',
      width: '100%',
    });

    dialogRef.componentInstance.plantId = this.MeetingMatrixForm.value.plant;
    dialogRef.componentInstance.MeetingId = this.meetingDefId2;
  }

  get af() {
    return this.editActionPlanForm.controls;
  }

  toggleView() {
    this.isExpanded = !this.isExpanded;
  }
  sortGapColumn(type: any) {
    if (type === 'asc') {
      this.sort = 'desc';
    } else if (type === 'desc') {
      this.sort = null;
    } else {
      this.sort = 'asc';
    }

    this.scoreCardGridColumnApi.applyColumnState({
      state: [{ colId: 'Gap', sort: type }],
      defaultState: { sort: null },
    });
  }

  toggleDisplayFromChild(element: any): any {

    this.toggleDisplay(
      element.frequencyId,
      element.kpiName,
      element.kpiId,
      element.frequencyName,
      element.meetingId,
      element.meetingName,
      element.Type,
      element.meetingMasterId,
      element.headerId,
      element,
      element.LeaderId,
      element.MeetingStartTime,
      element.MeetingEndTime,
      element.Duration,
      element.MeetingDate
    );
  }

  onClickParamterAnalysis() {
    if (!this.ifRowSelected()) {
      return;
    }
    this.chartType = false;
    // this.KPIAnalysisForm.reset();
    if (this.selectedIndex == 0) {
      const { fromDate, toDate } = this.range.value;
      this.synchronizeDateWithAnalysisAndComparisonForm(
        fromDate,
        toDate,
        false
      );
    }
    if (this.selectedIndex == 1) {
      const { fromDate, toDate } = this.MeetingMatrixFrequencyForm.value;
      this.synchronizeDateWithAnalysisAndComparisonForm(
        fromDate,
        toDate,
        false
      );
    }
    const selectedRows = this.scoreCardGridApi.getSelectedRows();
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name']
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'] },
      ];
    }, []);
    let dialog = this.dialog.open(this.analysisChart, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '100vh',
      minWidth: '95vw',
      maxHeight: '100vh',
    });

    dialog.afterClosed().subscribe((result) => {
      this.chartArray = [];
      this.chartArrayForAnalysis = [];
    });
    distinctRows.forEach((element) => {
      const columns = this.columnDefs
        .map((c) => c.field)
        .filter(
          (c) =>
            c !== 'Trend' &&
            c !== 'Type' &&
            c !== 'Indicator' &&
            c !== 'Responsible' &&
            c !== 'Last Month' &&
            c !== 'KPICode' &&
            c !== 'MTD' &&
            c !== 'YTD' &&
            c !== 'KPIId' &&
            c !== 'meetingDefId' &&
            c !== 'meetingDefName' &&
            c !== 'Line' &&
            c !== 'UoM' &&
            c !== 'KPI Name' &&
            c !== 'Owner' &&
            c !== 'FinancialGap' &&
            c !== 'Target Date' &&
            c !== 'Unit' &&
            c !== 'Previous' &&
            c !== 'Department Name'
        );
      const analysisDataTarget = [];
      const analysisData = [];
      const analysisLabel = [];
      const title = element['KPI Name'];
      const kpiid = element.KPIId;
      const chartObj = { id: '', chartOption: {} };
      const chartObj2 = { id: '', chartOption: {} };

      for (const col of columns) {
        this.scoreCardGridOptions.api.forEachLeafNode((rowNode, index) => {
          if (
            rowNode.data.KPICode === element.KPICode &&
            rowNode.data.Type === 'Glidepath'
          ) {
            const kpi = this.scoreCardGridApi.getRowNode(rowNode.id);
            const value = this.scoreCardGridApi.getValue(col, kpi);
            analysisLabel.push(col);
            analysisDataTarget.push(
              value ? parseFloat(value.replace(`(${element.UoM})`, '')) : 0
            );
          }
          if (
            rowNode.data.KPICode === element.KPICode &&
            rowNode.data.Type === 'Actual'
          ) {
            const kpi = this.scoreCardGridApi.getRowNode(rowNode.id);
            const value = this.scoreCardGridApi.getValue(col, kpi);
            if (analysisLabel.indexOf(col) !== -1) {
            } else {
              analysisLabel.push(col);
            }
            analysisData.push(
              value ? parseFloat(value.replace(`(${element.UoM})`, '')) : 0
            );
          }
        });
      }
      this.KPIChartOptions1 = {
        title: {
          text: title,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: analysisLabel,
        },
        yAxis: {},
        tooltip: {
          valueDecimals: 2,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: (event) => {
                  const key = {
                    meetingDefId: this.selectedMeeting.meetingId,
                  };
                  this.meetingService
                    .getMeetingAction(key)
                    .subscribe((data) => {
                      for (var d of data) {
                        if (d.newtargetdate != null && d.newtargetDate != '') {
                          d.newtargetdate = moment(d.newtargetdate).format(
                            'DD/MM/YYYY HH:mm:ss'
                          );
                        }
                        if (
                          d.completiondate != null &&
                          d.completiondate != ''
                        ) {
                          d.completiondate = moment(d.completiondate).format(
                            'DD/MM/YYYY HH:mm:ss'
                          );
                        }
                      }
                      this.getUserList();
                      this.rowData1 = data;
                      this.MatrixForm.get('meetingDefName')?.setValue(
                        this.selectedMeeting.meetingName
                      );
                      this.selectmeeting = this.MatrixForm.get(
                        'meetingDefName'
                      )?.setValue(this.selectedMeeting.meetingName);
                      this.MatrixForm.get('meetingDefId')?.setValue(
                        this.selectedMeeting.meetingId
                      );
                      this.kpiId = kpiid;
                      this.MatrixForm.get('kpi')?.setValue(title);
                      this.openModal();
                    });
                },
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y;
              },
            },
          },
        },
        series: [
          {
            type: 'column',
            name: 'Target',
            data: analysisDataTarget,
            color: '#10ce9c',
          },
          {
            type: 'column',
            name: 'Actual',
            data: analysisData,
            color: 'blue',
          },
        ],
      };
      this.KPIChartOptions2 = {
        title: {
          text: title,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: analysisLabel,
        },
        yAxis: {},
        tooltip: {
          valueDecimals: 2,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: (event) => {
                  const key = {
                    meetingDefId: this.selectedMeeting.meetingId,
                  };
                  this.meetingService
                    .getMeetingAction(key)
                    .subscribe((data) => {
                      for (var d of data) {
                        if (d.newtargetdate != null && d.newtargetDate != '') {
                          d.newtargetdate = moment(d.newtargetdate).format(
                            'DD/MM/YYYY HH:mm:ss'
                          );
                        }
                        if (
                          d.completiondate != null &&
                          d.completiondate != ''
                        ) {
                          d.completiondate = moment(d.completiondate).format(
                            'DD/MM/YYYY HH:mm:ss'
                          );
                        }
                      }
                      this.getUserList();
                      this.rowData1 = data;
                      this.MatrixForm.get('meetingDefName')?.setValue(
                        this.selectedMeeting.meetingName
                      );
                      this.selectmeeting = this.MatrixForm.get(
                        'meetingDefName'
                      )?.setValue(this.selectedMeeting.meetingName);
                      this.MatrixForm.get('meetingDefId')?.setValue(
                        this.selectedMeeting.meetingId
                      );
                      this.kpiId = kpiid;
                      this.MatrixForm.get('kpi')?.setValue(title);
                      this.resetActionModal();
                      this.openModal();
                    });
                },
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y;
              },
            },
          },
        },
        series: [
          {
            type: 'line',
            name: 'Target',
            data: analysisDataTarget,
            color: '#10ce9c',
          },
          {
            type: 'line',
            name: 'Actual',
            data: analysisData,
            color: 'blue',
          },
        ],
      };
      let chart = new Chart(this.KPIChartOptions1);
      let chart2 = new Chart(this.KPIChartOptions2);
      chartObj.chartOption = chart;
      chartObj2.chartOption = chart2;
      this.chartArray.push(chartObj);
      this.chartArrayForAnalysis.push(chartObj2);

    });
  }
  //calledByModal set to true when a call is initiated through KPI Comparison modal date range
  onClickParamterComparsion(calledByModal) {
    if (!this.ifRowSelected()) {
      return;
    }

    let selectedRows;
    if (this.selectedIndex == 0) {
      selectedRows = this.scoreCardGridApi.getSelectedRows();
      if (!calledByModal) {
        const { fromDate, toDate } = this.range.value;
        this.synchronizeDateWithAnalysisAndComparisonForm(
          fromDate,
          toDate,
          false
        );
      }
    }
    if (this.selectedIndex == 1) {
      selectedRows = this.kpigridApi.getSelectedRows();
      if (!calledByModal) {
        const { fromDate, toDate } = this.MeetingMatrixTalkSheetForm.value;
        this.synchronizeDateWithAnalysisAndComparisonForm(
          fromDate,
          toDate,
          false
        );
      }
    }

    this.spinnerButtonOptions1.active = true;
    if (!calledByModal) {
      this.dialog.open(this.visibleParameterComparison, {
        panelClass: 'custom-dialog-container',
        width: '95vw !important',
        height: '100vw',
        minWidth: '95vw',
        maxHeight: '100vh',
      });
    }
    const seletedRowsUOm = selectedRows.map(
      (value: any) => value.UoM
    );
    this.uniqueUoms = ['ALL', ...new Set(seletedRowsUOm)];
    this.individualUom = [];
    this.individualUom = this.uniqueUoms[0];


    if (
      !this.KPIComparisonForm.value.fromDate ||
      !this.KPIComparisonForm.value.toDate
    ) {
      return;
    }


    this.comparisonMainData();
    this.spinnerButtonOptions1.active = false;
  }

  comparisonMainData(): void {
    let values = '';
    let selectedRows;
    selectedRows = this.scoreCardGridApi.getSelectedRows();
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name'] && o.UoM == el.UoM
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'], UoM: el.UoM },
      ];
    }, []);
    const latestdate = new Date(this.range.value.toDate).setHours(
      23,
      59,
      59,
      59
    );
    values = distinctRows.map((value: any) => value.KPICode).toString();
    let fromDate = this.KPIComparisonForm.value.fromDate;
    let toDate = moment(this.KPIComparisonForm.value.toDate).utc();
    const obj = {
      frequencyName:
        this.selectedIndex === 0
          ? this.MeetingMatrixFrequencyForm.value.frequency.frequencyName
          : this.MeetingMatrixTalkSheetForm.value.frequency.frequencyName,
      plantId: this.MeetingMatrixForm.get('plant')?.value,
      fromDate,
      toDate,
      kpiId: values,
    };
    this.spinner.show();
    this.meetingService.getStockChartData(obj).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.compData = data;
        this.changeComparisonData();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      });
  }

  GetRawMaterialParameters(element: any): void {
    this.individualUom = [];
    this.individualUom = element;
    this.changeComparisonData();
  }

  changeComparisonData(): void {
    const array: any[] = [];
    let values = '';
    let valueNames = [];
    let selectedRows;
    selectedRows = this.scoreCardGridApi.getSelectedRows();
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name'] && o.UoM == el.UoM
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'], UoM: el.UoM },
      ];
    }, []);
    const matchingKPINames = [];
    distinctRows.forEach(item => {
      if (item.UoM === this.individualUom) {
        matchingKPINames.push(item['KPI Name']);
      }
    });
    if (this.individualUom == 'ALL') {
      debugger
      values = distinctRows.map((value: any) => value.KPICode).toString();
      valueNames = distinctRows.map((value: any) => value['KPI Name']);
      for (let i = 0; i < valueNames.length; i++) {
        const dataArr = [];
        for (let j = 0; j < this.compData.length; j++) {
          if (valueNames[i] === this.compData[j].KPIName) {
            dataArr.push({
              x: new Date(this.compData[j].Date).getTime(),
              y: isNaN(this.compData[j].Target) ? '' : parseFloat(this.compData[j].Target),
              label: valueNames[i],
            });
          }
        }
        let negativeArr = [];
        negativeArr = dataArr.map((c) => c.y);
        const obj = {
          tooltip: {
            valueDecimals: 2,
          },
          name: valueNames[i],
          type: 'line',
          data: dataArr,
          color: this.highChartsColor[i],
          yAxis: i,
        };
        array.push(obj);

      }
      array.forEach(element => {
        element.yAxis = 0
      });
      this.stock = new StockChart({
        chart: {
          height: 730,
        },
        plotOptions: {
          series: {
            turboThreshold: 50000000,
            events: {
              hide(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
              show(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
            },
          },
        },
        rangeSelector: {
          selected: 0,
          inputEnabled: false,
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        yAxis: [
          {
            "lineWidth": 1,
            "opposite": false,
            "title": {
              "text": "",
            },
            "visible": true
          },
        ],
        series: array,
      });
    }
    else {
      const valuesNotInArray2 = distinctRows.filter(x => x.UoM == this.individualUom);
      values = valuesNotInArray2.map((value: any) => value.KPICode).toString();
      valueNames = matchingKPINames;
      const filteredArr = this.compData.filter(obj => obj.UOM === this.individualUom);
      for (let i = 0; i < valueNames.length; i++) {
        const dataArr = [];
        for (let j = 0; j < filteredArr.length; j++) {
          if (valueNames[i] === filteredArr[j].KPIName) {
            dataArr.push({
              x: new Date(filteredArr[j].Date).getTime(),
              y: isNaN(filteredArr[j].Target) ? '' : parseFloat(filteredArr[j].Target),
              label: valueNames[i],
            });
          }
        }
        let negativeArr = [];
        negativeArr = dataArr.map((c) => c.y);
        const obj = {
          tooltip: {
            valueDecimals: 2,
          },
          name: valueNames[i],
          type: 'line',
          data: dataArr,
          color: this.highChartsColor[i],
          yAxis: i,
        };
        array.push(obj);

      }
      array.forEach(element => {
        element.yAxis = 0
      });
      this.stock = new StockChart({
        chart: {
          height: 730,
        },
        plotOptions: {
          series: {
            turboThreshold: 50000000,
            events: {
              hide(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
              show(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
            },
          },
        },
        rangeSelector: {
          selected: 0,
          inputEnabled: false,
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        yAxis: [
          {
            "lineWidth": 1,
            "opposite": false,
            "title": {
              "text": "",
            },
            "visible": true
          },
        ],
        series: array,
      });
    }

  }

  onClickParamterComparsionTalkSheet(calledByModal) {
    if (!this.ifRowSelected()) {
      return;
    }

    if (this.selectedIndex == 0) {
      const { fromDate, toDate } = this.range.value;
      this.synchronizeDateWithAnalysisAndComparisonForm(
        fromDate,
        toDate,
        false
      );
    }
    if (this.selectedIndex == 1) {
      const { fromDate, toDate } = this.MeetingMatrixTalkSheetForm.value;
      this.synchronizeDateWithAnalysisAndComparisonForm(
        fromDate,
        toDate,
        false
      );
    }
    const selectedRows = this.kpigridApi.getSelectedRows();
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name']
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'] },
      ];
    }, []);
    this.spinnerButtonOptions1.active = true;
    // tslint:disable-next-line:max-line-length
    if (!calledByModal) {
      this.dialog.open(this.visibleParameterComparison, {
        panelClass: 'custom-dialog-container',
        width: '95vw !important',
        height: '100vw',
        minWidth: '95vw',
        maxHeight: '100vh',
      });
    }

    const array: any[] = [];
    const yAxisArr: any[] = [];
    const values = distinctRows.map((value: any) => value.KPIId).toString();
    const valueNames = distinctRows.map((value: any) => value['KPI Name']);
    const seletedGroups = selectedRows.map(
      (value: any) => value.ParameterGroup
    );
    const uniqueGroups = [...new Set(seletedGroups)];
    const dataArrName: any[] = [];
    const latestdate = new Date(this.range.value.toDate).setHours(
      23,
      59,
      59,
      59
    );

    let fromDate = calledByModal
      ? moment(this.KPIComparisonForm.value.fromDate).utc() : moment(this.range.value.fromDate).utc();
    let toDate = calledByModal ? moment(this.KPIComparisonForm.value.toDate).utc() : moment(latestdate).utc();
    const obj = {
      frequencyName:
        this.MeetingMatrixTalkSheetForm.value.frequency.frequencyName,
      plantId: this.MeetingMatrixForm.get('plant')?.value,
      fromDate,
      toDate,
      kpiId: values,
    };
    // this.spinner.show();
    this.isLoadingKPIComparision = true;

    this.meetingService.getStockChartData(obj).subscribe(
      (data: any) => {
        this.isLoadingKPIComparision = false;

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < valueNames.length; i++) {
          const dataArr = [];
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < data.length; j++) {
            // const arr = [];
            if (valueNames[i] === data[j].KPIName) {
              dataArr.push({
                x: new Date(data[j].Date).getTime(),
                y: isNaN(data[j].Target) ? '' : parseFloat(data[j].Target),
                label: valueNames[i],
              });
            }
          }
          let negativeArr = [];
          negativeArr = dataArr.map((c) => c.y);
          const obj = {
            tooltip: {
              valueDecimals: 2,
            },
            name: valueNames[i],
            type: 'line',
            data: dataArr,
            color: this.highChartsColor[i],
            yAxis: i,
          };
          array.push(obj);
          yAxisArr.push({
            lineWidth: 1,
            visibe: true,
            // opposite: negativeArr.some((v) => v < 0) ? true : false,
            opposite: false,
            title: {
              text: valueNames[i],
              style: {
                color: this.highChartsColor[i],
              },
            },
            labels: {
              style: {
                color: this.highChartsColor[i],
              },
            },
          });
        }

        yAxisArr.forEach((yAxis) => {
          yAxis.visible = false;
        });
        this.stock = new StockChart({
          chart: {
            height: 730,
          },
          plotOptions: {
            series: {
              turboThreshold: 50000000,
              events: {
                hide(): any {
                  // this.yAxis.axisTitle.hide();
                  this.yAxis.update({
                    visible: false,
                  });
                },
                show(): any {
                  this.yAxis.update({
                    visible: false,
                  });
                },
              },
            },
          },
          rangeSelector: {
            selected: 0,
            inputEnabled: false,
          },
          title: {
            text: uniqueGroups.toString(),
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: true,
          },
          tooltip: {
            formatter(): any {
              // let s = '<b>' + StockChart.dateFormat('%A, %b %e, %Y', this.x) + '</b>';
              let s = '';
              this.points?.forEach((point: any) => {
                s +=
                  '<br/> <span style="color: ' +
                  point.series.color +
                  '">' +
                  point.series.name +
                  ' : ' +
                  point.y +
                  '<span><br/>' +
                  '<br/><span style="color: ' +
                  point.series.color +
                  '">KPI Name : ' +
                  point.point.label +
                  '<span><br/>';
              });
              return s;
            },
          },
          yAxis: yAxisArr,
          series: array,
        });
        // this.spinner.hide();
      },
      (error: any) => {
        this.isLoadingKPIComparision = false;

        this.toaster.error('Error', error.error.message);
        // this.spinner.hide();
      }
    );
    this.spinnerButtonOptions1.active = false;
  }
  onDateChangeInKPIAnaModal() {
    this.chartType = false;
    let selectedRows;
    if (this.selectedIndex === 0) {
      selectedRows = this.scoreCardGridApi.getSelectedRows();
    } else if (this.selectedIndex === 1) {
      selectedRows = this.kpigridApi.getSelectedRows();
    }
    //const KPIs = selectedRows.map((row) => row.KPIId);
    const KPIs = selectedRows.map((row) => row.KPICode);
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name']
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'] },
      ];
    }, []);

    const key = {
      frequencyId:
        this.selectedIndex === 0
          ? this.MeetingMatrixFrequencyForm.value.frequency.frequencyId
          : this.MeetingMatrixTalkSheetForm.value.tsfrequency.frequencyId,
      kpiId: KPIs.join(','),
      frequencyName:
        this.selectedIndex === 0
          ? this.MeetingMatrixFrequencyForm.value.frequency.frequencyName
          : this.MeetingMatrixTalkSheetForm.value.tsfrequency.frequencyName,
      plantId: this.MeetingMatrixForm.get('plant')?.value,
      fromDate: moment(this.KPIAnalysisForm.value.fromDate).utc(),
      toDate: moment(this.KPIAnalysisForm.value.toDate).utc(),
      meetingDefId: this.selectedMeeting.meetingId,
    };


    this.meetingService.getScoreCardMeetingMatrix(key).subscribe(
      (data: any) => {
       
        this.chartArray = [];
        this.chartArrayForAnalysis = [];
        let columns = [
          ...data.reduce(
            (s, o) => (Object.keys(o).forEach((k) => s.add(k)), s),
            new Set()
          ),
        ];
        this.isLoadingKPIAnalysis = false;
        columns = columns.filter(
          (c) =>
            c !== 'Trend' &&
            c !== 'Type' &&
            c !== 'Indicator' &&
            c !== 'Responsible' &&
            c !== 'Last Month' &&
            c !== 'KPICode' &&
            c !== 'MTD' &&
            c !== 'YTD' &&
            c !== 'KPIId' &&
            c !== 'meetingDefId' &&
            c !== 'meetingDefName' &&
            c !== 'Line' &&
            c !== 'UoM' &&
            c !== 'KPI Name' &&
            c !== 'Owner' &&
            c !== 'FinancialGap' &&
            c !== 'Target Date' &&
            c !== 'Unit' &&
            c !== 'Previous' &&
            c !== 'Department Name'
        );
        distinctRows.forEach((element, ind) => {
          let obj = [];
          const analysisDataTarget = [];
          const analysisData = [];
          const analysisLabel = [];
          const title = element['KPI Name'];
          const chartObj = { id: '', chartOption: {} };
          const chartObj2 = { id: '', chartOption: {} };

          for (const col of columns) {
            data.forEach((rowNode, index) => {
              if (
                rowNode.KPICode === element.KPICode &&
                rowNode.Type === 'Glidepath'
              ) {
                // const kpi = this.scoreCardGridApi.getRowNode(rowNode.id);
                const value = rowNode[col];
                analysisLabel.push(col);
                analysisDataTarget.push(parseFloat(value ? value : 0));
              }
              if (
                rowNode.KPICode === element.KPICode &&
                rowNode.Type === 'Actual'
              ) {
                if (analysisLabel.indexOf(col) !== -1) {
                } else {
                  // var objectPropertyName = col;
                  analysisLabel.push(col);
                }
                const value = rowNode[col];

                analysisData.push(parseFloat(value ? value : 0));
              }
            });
          }
          this.KPIChartOptions1 = {
            title: {
              text: title,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              categories: analysisLabel,
            },
            yAxis: {},
            tooltip: {
              valueDecimals: 2,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                    return this.y;
                  },
                },
              },
            },
            series: [
              {
                type: 'column',
                name: 'Target',
                data: analysisDataTarget,
                color: '#10ce9c',
              },
              {
                type: 'column',
                name: 'Actual',
                data: analysisData,
                color: 'blue',
              },
            ],
          };

          this.KPIChartOptions2 = {
            title: {
              text: title,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              categories: analysisLabel,
            },
            yAxis: {},
            tooltip: {
              valueDecimals: 2,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                    return this.y;
                  },
                },
              },
            },
            series: [
              {
                type: 'line',
                name: 'Target',
                data: analysisDataTarget,
                color: '#10ce9c',
              },
              {
                type: 'line',
                name: 'Actual',
                data: analysisData,
                color: 'blue',
              },
            ],
          };
          let chart = new Chart(this.KPIChartOptions1);
          let chart2 = new Chart(this.KPIChartOptions2);
          chartObj.chartOption = chart;
          chartObj2.chartOption = chart2;
          this.chartArray.push(chartObj);
          this.chartArrayForAnalysis.push(chartObj2);
        });
      },
      (error: any) => {
        this.isLoadingKPIAnalysis = false;

        this.toastrService.error(error.message);
      }
    );
  }
  alertBeforeMeetingEnd() {
    // const sameDay = (d1, d2) => {
    //   return d1.getDate() === d2.getDate() &&
    //     d1.getFullYear() === d2.getFullYear() &&
    //     d1.getMonth() === d2.getMonth()
    // }
    // const checkDiffInMinutes = (EndTime) => {
    //   return (new Date(`Thu Dec 08 2022 ${EndTime}`).getTime() - new Date().getTime()) / 60000;



    // }
    // const key = {
    //   plantId: this.MeetingMatrixForm.get('plant')?.value
    //   ,
    //   meetingId: this.meetingAttendId,
    // };
    // this.meetingService.GetMeetingRecurrence(key).subscribe((res: any) => {
    //   const { StartTime, EndTime, StartDate, EndDate } = res[0];
    //   // const isSameDay = sameDay(new Date(StartDate), new Date());
    //   const hasRequiredData = StartTime && EndTime;
    //   let diff: number;
    //   if (hasRequiredData && this.hasEnteredMeeting) {
    //     const timer = setInterval(() => {
    //       const diff = checkDiffInMinutes('12:48:00');
    //       if (diff < 2) {
    //         this.dialog.open(this.extendEndTimeModal, { width: '500px' })
    //         // this.toastrService.warning('Meeting is about to end');
    //         this.timeLeft = diff;
    //         clearInterval(timer);

    //       }
    //     }, 1000)


    //   }
    //   //check time difference in mintues;

    // })


  }
  onDateChangeInKPICompModal() {
    this.onClickParamterComparsion(true);
  }
  dateRangeChange(e) {
  }
  //
  onDateChangeInKPIAnaModalForTalkSheet() {
    if (!this.ifRowSelected()) {
      return;
    }

    if (this.selectedIndex == 0) {
      const { fromDate, toDate } = this.range.value;
      this.synchronizeDateWithAnalysisAndComparisonForm(
        fromDate,
        toDate,
        false
      );
    }
    if (this.selectedIndex == 1) {
      const { fromDate, toDate } = this.MeetingMatrixTalkSheetForm.value;
      this.synchronizeDateWithAnalysisAndComparisonForm(
        fromDate,
        toDate,
        false
      );
    }
    this.chartType = false;
    const selectedRows = this.kpigridApi.getSelectedRows();
    const KPIs = selectedRows.map((row) => row.KPIId);
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name']
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'] },
      ];
    }, []);
    let dialog = this.dialog.open(this.analysisChart, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '100vh',
      minWidth: '95vw',
      maxHeight: '100vh',
    });

    const key = {
      frequencyId:
        this.MeetingMatrixTalkSheetForm.value.tsfrequency.frequencyId,
      kpiId: KPIs.join(','),
      frequencyName:
        this.MeetingMatrixTalkSheetForm.value.tsfrequency.frequencyName,
      plantId: this.MeetingMatrixForm.get('plant')?.value,
      // date: latestdate,
      fromDate: moment(this.KPIAnalysisForm.value.fromDate).utc(),
      toDate: moment(this.KPIAnalysisForm.value.toDate).utc(),
      meetingDefId: this.selectedMeeting.meetingId,
    };


    this.meetingService.getScoreCardMeetingMatrix(key).subscribe(
      (data: any) => {
        this.chartArray = [];
       
        this.chartArrayForAnalysis = [];
        let columns = [
          ...data.reduce(
            (s, o) => (Object.keys(o).forEach((k) => s.add(k)), s),
            new Set()
          ),
        ];
        columns = columns.filter(
          (c) =>
            c !== 'Trend' &&
            c !== 'Type' &&
            c !== 'Indicator' &&
            c !== 'Responsible' &&
            c !== 'Last Month' &&
            c !== 'KPICode' &&
            c !== 'MTD' &&
            c !== 'YTD' &&
            c !== 'KPIId' &&
            c !== 'meetingDefId' &&
            c !== 'meetingDefName' &&
            c !== 'Line' &&
            c !== 'UoM' &&
            c !== 'KPI Name' &&
            c !== 'Owner' &&
            c !== 'FinancialGap' &&
            c !== 'Target Date' &&
            c !== 'Unit' &&
            c !== 'Previous' &&
            c !== 'Department Name'
        );
        distinctRows.forEach((element, ind) => {
          let obj = [];

          const analysisDataTarget = [];
          const analysisData = [];
          const analysisLabel = [];
          const title = element['KPI Name'];
          const chartObj = { id: '', chartOption: {} };
          const chartObj2 = { id: '', chartOption: {} };

          for (const col of columns) {
            data.forEach((rowNode, index) => {
              if (
                rowNode.KPICode === element.KPICode &&
                rowNode.Type === 'Glidepath'
              ) {
                // const kpi = this.scoreCardGridApi.getRowNode(rowNode.id);
                const value = rowNode[col];
                analysisLabel.push(col);
                analysisDataTarget.push(parseFloat(value ? value : 0));
              }
              if (
                rowNode.KPICode === element.KPICode &&
                rowNode.Type === 'Actual'
              ) {
                if (analysisLabel.indexOf(col) !== -1) {
                } else {
                  // var objectPropertyName = col;
                  analysisLabel.push(col);
                }
                const value = rowNode[col];

                analysisData.push(parseFloat(value ? value : 0));
              }
            });
          }
          this.KPIChartOptions1 = {
            title: {
              text: title,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              categories: analysisLabel,
            },
            yAxis: {},
            tooltip: {
              valueDecimals: 2,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                    return this.y;
                  },
                },
              },
            },
            series: [
              {
                type: 'column',
                name: 'Target',
                data: analysisDataTarget,
                color: '#10ce9c',
              },
              {
                type: 'column',
                name: 'Actual',
                data: analysisData,
                color: 'blue',
              },
            ],
          };

          this.KPIChartOptions2 = {
            title: {
              text: title,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              categories: analysisLabel,
            },
            yAxis: {},
            tooltip: {
              valueDecimals: 2,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                    return this.y;
                  },
                },
              },
            },
            series: [
              {
                type: 'line',
                name: 'Target',
                data: analysisDataTarget,
                color: '#10ce9c',
              },
              {
                type: 'line',
                name: 'Actual',
                data: analysisData,
                color: 'blue',
              },
            ],
          };
          let chart = new Chart(this.KPIChartOptions1);
          let chart2 = new Chart(this.KPIChartOptions2);
          chartObj.chartOption = chart;
          chartObj2.chartOption = chart2;
          this.chartArray.push(chartObj);
          this.chartArrayForAnalysis.push(chartObj2);
        });
      },
      (error: any) => {
        this.toastrService.error(error.message);
      }
    );
  }
  GetProductionPlanList() { }
  changeDateIndexWise(index) {
    if (index == 0) {
      const { fromDate, toDate } = this.range.value;
      this.synchronizeDateWithAnalysisAndComparisonForm(
        fromDate,
        toDate,
        false
      );
    }
    if (index == 1) {
      const { fromDate, toDate } = this.MeetingMatrixFrequencyForm.value;
      this.synchronizeDateWithAnalysisAndComparisonForm(
        fromDate,
        toDate,
        false
      );
    }
  }
  synchronizeDateWithAnalysisAndComparisonForm(fromDate, toDate, emitEvent) {

    fromDate = moment(new Date(new Date(fromDate).setHours(0, 0, 0, 0))).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    toDate = moment(new Date(new Date(toDate).setHours(23, 59, 59, 0))).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    this.KPIAnalysisForm.patchValue(
      {
        fromDate,
        toDate,
      },
      { emitEvent: emitEvent }
    );
    this.KPIComparisonForm.patchValue(
      {
        fromDate,
        toDate,
      },
      { emitEvent: emitEvent }
    );
  }
  getData(): void {
    if (this.f.fromDate.value && this.f.toDate.value) {
      // this.getFinancialGapList(res.fromDate, res.toDate);

      if (
        this.MeetingMatrixTalkSheetForm.value.tsfrequency.frequencyId !==
        undefined
      ) {
        this.onSearchScorecard();
      }
    }
  }
  autoSizeAllColumns(params) {
    const allColumnIds: string[] = [];
    params.getAllColumns()!.forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.autoSizeColumns(allColumnIds, false);
  }

  ifRowSelected(): boolean {
    if (this.selectedIndex == 0) {
      if (this.scoreCardGridApi.getSelectedRows().length > 0) return true;
    }
    if (this.selectedIndex == 1) {
      if (this.kpigridApi.getSelectedRows().length > 0) return true;
    }
    this.toaster.warning('No row selected', 'Please select at least 1 row');
    return false;
  }
  uncheckAllParticipents() {
    this.selectedAll = false;
    for (let participant of this.meetingParticipants) {
      participant.isAttended = false;
      participant.reason = '';
    }
  }
  onFinancialGapRangeChange() {
    const { fromDate, toDate } = this.rangeFinacialGap.value;
    this.getFinancialGapList(fromDate, toDate);
    this.KPIAnalysisForm.patchValue({
      fromDate: moment(
        new Date(new Date(fromDate).setHours(0, 0, 0, 0))
      ).format('YYYY-MM-DD HH:mm:ss'),
      toDate: moment(new Date(new Date(toDate).setHours(0, 0, 0, 0))).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
    });
  }
  onKPIAnalysisRangeChange() {
    this.isLoadingKPIAnalysis = true;
    this.onDateChangeInKPIAnaModal();
  }
  onModelUpdated() {
    this.autoSizeAll();
  }

  autoSizeAll() {

    var allColIds = this.scoreCardGridOptions.columnApi
      .getAllColumns()
      .map((column) => column.colId);
    this.scoreCardGridOptions.columnApi.autoSizeColumns(allColIds);

    // If you want to resize all columns
    this.scoreCardGridOptions.columnApi.autoSizeAllColumns();
  }

  getMenuPermissions() {
    const { group, plant } = this.MeetingMatrixForm.value;
    const data = {
      plantId: plant,
      groupId: group,
    };
    this.meetingService.GetPlantAllowedMenu(data).subscribe(
      (response) => {
        if (response.length) {
          this.menuPermission.Scorecard = response.some(
            (item) => item.meetingDesc === 'ScoreCard'
          );
          this.menuPermission.Talksheet = response.some(
            (item) => item.meetingDesc === 'Talk Sheet'
          );
          this.menuPermission.Dashboard = response.some(
            (item) => item.meetingDesc === 'Dashboard'
          );
          this.menuPermission['Safety & ENV'] = response.some(
            (item) => item.meetingDesc === 'Safety & ENV'
          );
        } else {
          this.menuPermission.Scorecard = true;
          this.menuPermission.Talksheet = true;
          this.menuPermission.Dashboard = true;
          this.menuPermission['Safety & ENV'] = true;
        }

        let permissions = this.menuPermission;
        if (permissions.Scorecard) {
          this.selectedTabIndex = 0;
          return;
        }
        if (permissions.Talksheet) {
          this.selectedTabIndex = 1;
          return;
        }
        if (permissions.Dashboard) {
          this.selectedTabIndex = 2;
          return;
        }
        if (permissions['Safety & ENV']) this.selectedTabIndex = 3;
      },
      (error) => {

      }
    );
  }

  headerBreaker(): void {
    setTimeout(() => {
      const fullscreen = document.getElementsByClassName('fullscreen') as HTMLCollectionOf<HTMLElement>;
      if (fullscreen.length > 0) {
        const colorElements = document.getElementsByClassName(
          'ag-body-viewport'
        ) as HTMLCollectionOf<HTMLElement>;
        colorElements[0].style.height = '70vh';
        colorElements[0].style.overflowY = 'scroll';
      }
      else {
        const colorElements = document.getElementsByClassName(
          'ag-body-viewport'
        ) as HTMLCollectionOf<HTMLElement>;
        colorElements[0].style.height = '25vw';
        colorElements[0].style.overflowY = 'scroll';
      }

      var headerCenter = document.getElementsByClassName('ag-header-cell-label') as HTMLCollectionOf<HTMLElement>;
      var headerEl = document.getElementsByClassName('ag-header-cell-text');
      for (var i = 0; i < headerEl.length; i++) {
        if (String((headerEl[i] as HTMLElement).innerHTML).includes("W -")) {
          headerCenter[i].style.display = 'flex';
          headerCenter[i].style.justifyContent = 'center';
          var txt = String(headerEl[i].innerHTML);
          if (!txt.includes("<br>")) {
            var txtArr = txt.split('(');
            if (!txtArr[1].includes("<span>")) {
              txtArr[1] = "<span style='font-size: 11px; color: #0f0fd1;'>" + "(" + txtArr[1] + "</span>";
            }
            var newTxt = txtArr[0] + "<br> " + txtArr[1];
            headerEl[i].innerHTML = newTxt;
          }
          var headerLabel = document.getElementsByClassName('ag-header-cell-label') as HTMLCollectionOf<HTMLElement>;
          headerLabel[i].style.textAlign = 'center';
        }
      }

    }, 1000);
  }

  AddMeetingException() {
    const MeetingExtendedTime = moment(new Date("01/01/1999 " + this.MeetingEndTime)).add(this.ExtendMeetingForm.value.duration, 'm').format("HH:mm:ss");
    this.MeetingDuration = Number(this.MeetingDuration) + Number(this.ExtendMeetingForm.value.duration)
    const key = {
      MeetingId: this.selectedMeeting?.meetingId,
      MeetingDate: this.MeetingDate,
      ExtendedEndTime: MeetingExtendedTime,
      Reason: this.ExtendMeetingForm.value.reason
    }
    this.meetingService.AddMeetingException(key).subscribe((res) => {
      this.toaster.success('Duration Extended Successfully');
      this.PopUpOccured = 0;
      this.dialog.closeAll();

    },
      (error) => {
        this.toaster.error('error', error.message);
      });
  }

  expandAll() {
    this.kpigridApi.expandAll();
  }

  collapseAll() {
    this.kpigridApi.collapseAll();
  }


  columnRowGroupChanged(e) {
    let colName = '';

    for (let i = 0; i <= e.columns.length - 1; i++) {
      colName = e.columns[i].colDef.field;
      this.scoreCardGridColumnApi.getColumn(colName).visible = false;

      if (this.scoreCardGridColumnApi.columnController.displayedColumns[i].colDef.showRowGroup === colName) {
        this.scoreCardGridColumnApi.columnController.displayedColumns[i].colDef.headerName = colName;
      }
    }
  }

  createAddActionPlanForm() {
    this.addActionForm = this.fb.group({
      id: [''],
      action: ['', Validators.required],
      actionType: ['', Validators.required],
      assignTo: ['', Validators.required],
      targetDate: ['', Validators.required],
      kpi: [''],
    });
  }

  openModal() {
    // this.addActionForm.reset();
    this.addActionForm.reset();
    this.addActionForm.get('actionType')?.setValue('Corrective');
    this.addActionForm.get('kpi')?.setValue(this.kpiName);
    this.dialog.open(this.addActionModal, { height: 'auto', width: '90%' });
  }

  onChangeCompleteAdd(): void {
    if (this.addActionForm.value.completed === 'Yes') {
      this.disableVerification = false;
      this.addActionForm.get('verification').setValue('');
    }
  }

  readFileAdd(event: any, type: any): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        let src = reader.result as string;
        if (type === 'pic') {
          this.imageAddActionPlan = event.target.files[0];
          this.addImageSrc = src;
        } else {
          this.fileAddActionPlan = event.target.files[0];
          this.addFileSrc = src;
        }
      };
    }
  }

  deleteFileAddActionModal(type: any): void {
    if (type === 'pic') {
      this.addImageSrc = null;
      this.imageAddActionPlan = new File([''], 'filename');;
    } else {
      this.fileAddActionPlan = new File([''], 'filename');
      this.addFileSrc = null;
    }
  }

  openFileAddActionPlan(type?: any): void {
    if (type === 'pic') {
      this.imageInputAdd.nativeElement.click();
    } else {
      this.fileInputAdd.nativeElement.click();
    }
  }

  resetActionModal(): void {
    this.addActionForm?.reset();
    this.addFileSrc = null;
    this.addImageSrc = null;
    this.imageAddActionPlan = null;
    this.fileAddActionPlan = null;
    this.addActionForm.get('actionType')?.setValue('Preventive');
  }

  addActionPlan(): void {
    let status =
      this.addActionForm.value.targetDate &&
        !this.addActionForm.value.revisedDate
        ? 'InProgress'
        : this.addActionForm.value.targetDate &&
          this.addActionForm.value.revisedDate
          ? 'Delayed'
          : 'Open';
          if (this.addActionForm.value.verification === 'Approved') {
            status = 'Closed';
            // fiveWhy.ClosedDate = new Date();
          } else if (this.addActionForm.value.verification === 'Disapproved') {
            status = 'ReOpened';
            // fiveWhy.ActionCompleted = 'No';
            this.addActionForm.get('completed').setValue('No');
          }
    this.targetDate = this.addActionForm.value.targetDate;
    this.targetDate = new Date(this.targetDate);
    this.targetDate.setHours(new Date().getHours());
    this.targetDate.setMinutes(new Date().getMinutes());
    this.targetDate.setSeconds(new Date().getSeconds());

    const key = {
      actionName: this.addActionForm.value.action,
      actionType: this.addActionForm.value.actionType,
      responsible: this.addActionForm.value.assignTo,
      targetDate: this.targetDate,
      status: status,
      userId: JSON.parse(localStorage.user).UserId,
      plantId: this.MeetingMatrixForm.value.plant,
      meetingStatus: this.meetingStarted,
      meetingId: this.selectedMeeting.meetingId ,
      kpiId: this.currentKPIId
    };
    this.spinner.show();
    this.actionplanService.addActionPlan(key).subscribe(({
      next: (res: any) => {
        this.spinner.hide();
        if (res?.data[0]?.message === 'Add Successfully') {
          this.toaster.success('Sucess', 'Added Successfully');
          this.getOverallMeetingActions();
          this.addActionForm.reset();
          this.addActionForm.get('actionType')?.setValue('Corrective');
          this.addActionForm.get('kpi')?.setValue(this.kpiName);
        }
      },
      error: ({ error }) => {
        this.spinner.hide();
        this.toaster.error('Error', '');
      }
    }));
  }

  setWeekValue(weekDay: number): void {
    if (weekDay == undefined || weekDay == null || Number.isNaN(weekDay))
      weekDay = 0
    sessionStorage.removeItem('weekDay');
    sessionStorage.setItem('weekDay', weekDay.toString());
    this.datepickerService.getFirstDayOfWeek()
  }
  
  decreaseDecimalPlaces():any{
    if(this.decimalPlaces>0){
    this.decimalPlaces = this.decimalPlaces-1;
  }
    this.createScoreCardData(this.ScoreCardDataTemp);
  }
  IncreaseDecimalPlaces():any{
    if(this.decimalPlaces<4)
    {
    this.decimalPlaces = this.decimalPlaces+1;
    }
    this.createScoreCardData(this.ScoreCardDataTemp);
  }
}

function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }



}

function GenderCellRenderer(): void { }

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};

var cellClassRules: CellClassRules = {
  quarters: 'data.section === "quarters"',
};

const tooltipValueGetter = (params: any) => ({ value: params.value });
