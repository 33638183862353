import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MachineBoardService {
  baseUrl: string = environment.O3CoreApiUrl;
constructor(private http: HttpClient) { }

getGroupList(data: any): Observable<any[]> {
  return this.http.post<any[]>(
    this.baseUrl + 'Common/GetGroup', data
  );
}

GetMachineDashboard(data: any): Observable<any[]> {
  return this.http.post<any[]>(
    this.baseUrl + 'SDFC/GetMachineDashboard', data
  );
}

}
