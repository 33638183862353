import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefectConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/defect-configuration/defect-configuration.component';

import { AuthGuard } from '../services/auth.guard';
import { DefectDashboardComponent } from './defect-dashboard/defect-dashboard.component';
import { DefectManagementMasterComponent } from './defect-management-master/defect-management-master.component';

const routes: Routes = [
      { path: 'DefectManagement', component: DefectConfigurationComponent , canActivate: [AuthGuard] },
      { path: 'DefectManagementMaster', component: DefectManagementMasterComponent , canActivate: [AuthGuard] },
     
];
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DMSRoutingModule {}
