import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import { TranslaterService } from 'src/app/services/translater.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
@Component({
  selector: 'app-dashboard-loss',
  templateUrl: './dashboard-loss.component.html',
  styleUrls: ['./dashboard-loss.component.scss']
})
export class DashboardLossComponent implements OnInit {

  breadcrumList: any;
  groupListDDL!: any[];
  filteredGroupListDDL!: any[];
  plants!: any[];
  filteredPlants!: any[];
  SKPIList!: any[];
  parentList!: any[];
  childList!: any[];
  treeIdList!: any[];
  gapSearchForm!: FormGroup;
  activeChipsCat!: {};
  chartLossAnalysis!: Chart;
  options: any;
  languageDir = 'ltr';
  moduleId!: any;
  pageId!: any;
  langObj: any = {};
  IsAccess: any;
  IsWrite: any;
  constructor(
    private userManagementService: UserManagementService,
    private kpiMaster: KpimasterService,
    private fb: FormBuilder,
    public router: Router,
    private toaster: ToastrService,
    private kpiTreeService: KpitreeService,
    private translater: TranslaterService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.gapSearchForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      dateStart: [new Date()],
      dateEnd: [new Date()],
    });
    this.GetBreadcrumList();
    this.getGroupData();
    this.GetMenuAccess();
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.userManagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.filteredGroupListDDL = this.groupListDDL.slice();
        this.gapSearchForm.get('group')?.setValue(this.groupListDDL[0]?.TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.gapSearchForm.get('group')?.value,
    };
    // this.MachineId = null;
    this.userManagementService.getDepartments(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList.filter((p: any) => p.DeptDesc);
        this.filteredPlants = this.plants.slice();
        this.gapSearchForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getStretegicKPIByPlant();

      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getStretegicKPIByPlant() {
    const key = {
      plantId: this.gapSearchForm.get('plant')?.value
    };
    // this.MachineId = null;
    this.kpiTreeService.getStrategicKpiByPlant(key).subscribe(
      (data: any) => {
        this.SKPIList = data;
        this.gettree();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  gettree(): void {
    let dateStart = this.gapSearchForm.get('dateStart')?.value, dateEnd = this.gapSearchForm.get('dateEnd')?.value
    if(dateStart && dateEnd){
      this.spinner.show();
      const key = {
        startDate: moment(this.gapSearchForm.get('dateStart')?.value).format('YYYY-MM-DD'),
        endDate: moment(this.gapSearchForm.get('dateEnd')?.value).format('YYYY-MM-DD'),
        plantId: this.gapSearchForm.get('plant')?.value
      };
      this.kpiTreeService.getKPILossAnalysisDBData(key).subscribe(
        (data: any) => {
          this.parentList = data;
          this.drawChart();
          this.spinner.hide();
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
          this.spinner.hide();
        }
      );
    }
  }

  drawChart() {
    const parent = [];
    let child = [];
    const drillData = [];

    this.parentList.forEach(value => {
      this.parentList.forEach(val => {
        if (value.ParentId === null && value.id === parseInt(val.ParentId) && val.SKPIId > 0) {
          const data = {
            name: value.Name,
            y: value.financialGap,
            drilldown: val.id,
            uom: value.UOM !== null ? value.UOM : 'Not set',
            selfValue: value.beforeSum === null ? 0 : value.beforeSum
          }
          parent.push(data);
        }

      })
    })

    // console.log('parent data : ',parent); 

    this.parentList.forEach((element) => {
      this.parentList.forEach(val => {
        if (element.id === parseInt(val.ParentId) && val.KPIID >= 0  && element.ParentId !== null) {
          const dataChild = {
            name: element.Name,
            y: element.financialGap,
            drilldown: val.id,
            uom: element.UOM !== null ? element.UOM : 'Not set',
            selfValue: element.beforeSum === null ? 0 : element.beforeSum
          }
          child.push(dataChild);
        }
      })
      const keyData = {
        id: element.id,
        name: element.Name,
        type: 'column',
        data: child.length > 0 ? child : [{ name: element.Name, y: element.financialGap, drilldown: '', uom: element.UOM !== null ? element.UOM : 'Not set', selfValue: element.beforeSum === null ? 0 : element.beforeSum }]

      }
      drillData.push(keyData);
      child = [];

    })

    //console.log("child data : ",drillData.slice(1));

    this.options = {
      chart: {
        type: 'column',
      },
      lang: {
        decimalPoint: '.',
        thousandsSep: ', '
       },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        labels: {
          format: '{value}'
        },
        title: {
          text: 'Financial Looses'
        }
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:,.2f}'
          }
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        valueDecimals: 2,
        formatter: function () {
          // Do not use regular expression as O3 wont work in iPad Safari
          //return 'Name : ' + this.key.toString() + '</br>' + 'Total Loss : ' + this.point.options.y.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + '</br> Currency : ' + this.point.options.uom + '</br>KPI Self Loss : ' + this.point.options.selfValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          return 'Name : ' + this.key.toString() + '</br>' + 'Total Loss : ' + this.point.options.y.toString() + '</br> Currency : ' + this.point.options.uom + '</br>KPI Self Loss : ' + this.point.options.selfValue.toString();
        }
      },
      series: [{
          name: 'Strategic KPI',
          type: 'column',
          colorByPoint: true,
          data: parent,
        }
      ],
      drilldown: {
        "allowPointDrilldown": false,
        activeAxisLabelStyle: {
          textDecoration: 'none',
          fontStyle: 'normal'
        },
        activeDataLabelStyle: {
          textDecoration: 'none',
          fontStyle: 'normal'
        },
        series: drillData.slice(1),
        drillUpButton: {
          relativeTo: 'spacingBox',
          position: {
            y: 0,
            x: 0
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'blue',
            r: 0,
            states: {
              hover: {
                fill: '#ffffff'
              },
              select: {
                stroke: '#039',
                fill: '#0f02fa'
              }
            }
          }

        },
      }
    };
    let chart = new Chart(this.options);
    this.chartLossAnalysis = chart;
  }
}
