<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>

  </div>
  <div class="tab-content-top-box">
    <h1 class="p-ml-2" class="main-title">Check List Execution</h1>
  </div>
  <div class="wrapper-box wrapper-box-shadow grid-container">
    <div class="content-row-flex-both-end" style="margin-top: 0px;">
    </div>

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

    <form [formGroup]="groupDataForm">
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field style="margin-left :0px !important" appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="group" placeholder="Group" panelClass="testClass"
            (selectionChange)="getPlants()">
            <mat-option *ngFor="let group of Groups" [value]="group.TypeId">
              {{group.TypeName}}
            </mat-option>
          </mat-select>
          <mat-error>Group is Required</mat-error>
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="plant" placeholder="Plant" panelClass="testClass" name="plant"
            (selectionChange)="getAllQualityCheckListByPlant()">
            <mat-option *ngFor="let plant of Plants" [value]="plant.DeptId">
              {{plant.DeptDesc}}
            </mat-option>
          </mat-select>
          <mat-error>Plant is Required</mat-error>
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select ngxMatSelect [hasSearchBox]="true" [useMobileView]="true" mobileViewType="BottomSheet"
            [formControlName]="'unitName'" name="puDesc" [required]="false" [source]="unitsArray"
            (selectionChange)="onUnitChange()" [displayMember]="'puDesc'" [placeholder]="'Select Units'"
            [valueMember]="'puId'" #sf="ngxMatSelect">
            <mat-option [value]="item.puId" *ngFor="let item of sf.filteredSource">
              {{item.puDesc}}
            </mat-option>
          </mat-select>
          <mat-error>Select Unit</mat-error>
        </mat-form-field>

        <button style="margin-left :7px !important" mat-mini-fab color="primary" pTooltip="Reset" tooltipPosition="top"
          class="reset" (click)="onclickReset()">
          <mat-icon>refresh</mat-icon>
        </button>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="margin-left:15em">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Search" formControlName="targetOrderQuantity" />
        </mat-form-field>
      </div>
    </form>
    
    <ag-grid-angular #agGridCheckList style="width: 100%; height: 100%;" class="ag-theme-alpine"
      [rowData]="checkListRowData" [columnDefs]="ColumnDefsCheckList" (gridReady)="onGridReadyCheckList($event)"
      (cellClicked)="onCellClicked($event)" [gridOptions]="gridOptionsCheckList" [pagination]="true"
      [paginationPageSize]="10" [loadingOverlayComponent]="loadingOverlayComponent"
      (firstDataRendered)="applyState('CheckListExecution-vTLU37IVZb')" (columnMoved)="onSaveGridState()"
      (columnVisible)="onSaveGridState()" (columnPinned)="onSaveGridState()"
      (displayedColumnsChanged)="onSaveGridState()"></ag-grid-angular>
  </div>
</div>



<ng-template #displayRoleModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h1 mat-dialog-title>Check List Execution</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>

  <form [formGroup]="checklistDataForm">
    <mat-dialog-content style="margin-left: 1px;">
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="margin-left: 0px;">
          <mat-label class="required">Group</mat-label>
          <mat-select panelClass="testClass" name="group" formControlName="group" (selectionChange)="getPlantByGroup()"
            [disabled]="true">
            <mat-option *ngFor="let g of Groups" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label class="required">Plnt</mat-label>
          <mat-select name="list" formControlName="plant" (selectionChange)="getArea()" [disabled]="true">
            <mat-option *ngFor="let o of Plants" [value]="o.DeptId">{{o.DeptDesc}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label class="required">Check List Type</mat-label>
          <input matInput formControlName="checktype" placeholder="Enter List Type" readonly />
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label class="required">Check List Name</mat-label>
          <input matInput formControlName="checkname" placeholder="Enter List Name" readonly />
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Area</mat-label>
          <input matInput formControlName="area" placeholder="Enter area Name" readonly />
        </mat-form-field>
      </div>

      <div class="content-top-row content-top-row-order-processing">
        <div class="inputField" style="width: 18%;">
          <nz-date-picker style="width: 100%;" (ngModelChange)="getDateShifts($event)" class="calender-input"
            formControlName="executionDate" id="timePicker1" nzFormat="dd/MM/yyyy HH:mm"
            [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true">
          </nz-date-picker>
          <span class="floating-text">Date</span>
        </div>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Shift</mat-label>
          <input matInput formControlName="shiftName" readonly />
        </mat-form-field>

        <input matInput formControlName="shift" style="display: none;" readonly placeholder="Shift" />

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Unit Name</mat-label>
          <input matInput formControlName="unitName" readonly placeholder="Enter Unit Name" />
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Current Process Order No</mat-label>
          <input matInput formControlName="currentProcessOrder" [readonly]="isAuto" />
          <input matInput formControlName="currentProcessOrderId" style="display: none;" [readonly]="isAuto" />
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Current Product Name</mat-label>
          <input matInput placeholder="Product Description" formControlName="currentProductName" [readonly]="preAuto" />
        </mat-form-field>
      </div>

      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="margin-left: 0px;">
          <mat-label>Previous Process Order No</mat-label>
          <input matInput formControlName="previousProcessOrder" [readonly]="isAuto2" />
          <input matInput formControlName="previousProcessOrderId" style="display: none;" [readonly]="isAuto2" />
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Previous Product Name</mat-label>
          <input matInput placeholder="Product Description" formControlName="previousProductName"
            [readonly]="preAuto2" />
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Target Order Quantity</mat-label>
          <input matInput placeholder="Target Order Quantity" formControlName="targetOrderQuantity" />
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Produced Quantity</mat-label>
          <input matInput placeholder="Produced Quantity" formControlName="producedQuantity" />
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-divider></mat-divider>
    <div style="margin-top: 10px;"></div>
    <table mat-table [dataSource]="checkListAddModel" class="basic-table plant-model-table popup" matSort>
      <ng-container matColumnDef="items">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text Item-header">Items</p>
        </th>
        <td mat-cell *matCellDef="let element" class="td-text" style="padding-left: 80px; width: 30%;">
          {{element.itemName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="good">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style=" padding-left: 0px;">
          <p class="header-text">Good Example</p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" style="width: 10%;">
          <button mat-mini-fab *ngIf="!formMode && element.goodExampleImage!='' && element.goodExampleImage!=null"
            color="accent" (click)="openFilePopUp(element , i , 1)" style="background-color: #8f0fd1;">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
         </td>
      </ng-container>


      <ng-container matColumnDef="bad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 0px;">
          <p class="header-text">Bad Example</p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" style="width: 10%;">
          <button mat-mini-fab *ngIf="!formMode && element.badExampleImage!='' && element.badExampleImage!=null"
            color="accent" (click)="openFilePopUp(element , i , 2)" style="background-color: #8f0fd1;">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="standard">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Standard</p>
        </th>
        <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%; background-color: #edf1e5;">
          {{element.standard}}
        </td>
      </ng-container>


      <ng-container matColumnDef="minimum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Min</p>
        </th>
        <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%; background-color: #edf1e5;">
          {{element.minValue}}
        </td>
      </ng-container>

      <ng-container matColumnDef="maximum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Max</p>
        </th>
        <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%; background-color: #edf1e5;">
          {{element.maxValue}}
        </td>
      </ng-container>

      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Result</p>
        </th>
        <td mat-cell *matCellDef="let element" style="width: 20%; background-color: #edf1e5;">

          <mat-radio-group required aria-label="Select an option" [value]="element.score"
            (change)="scoreUpdate($event, element); onChangeradio($event) " *ngIf="element.itemType === 1">
            <mat-radio-button *ngFor="let p of categoryTypeDDL" [value]="p.id" style="margin-left: 10px;">{{p.name}}
            </mat-radio-button>
          </mat-radio-group>

          <mat-form-field appearance="fill" class="field-fill-table" *ngIf="element.itemType === 2"
            style="width: 100%;">
            <input matInput autocomplete="off" (change)="scoreUpdate($event, element)" [value]="element.score">
          </mat-form-field>

          <div appearance="fill" class="field-fill" *ngIf="element.itemType === 4" style="width: 180px;">
            <input placeholder="Upload File" type="file" (change)="uploadedFile($event, element.checkListDetailId)"
              class="form-control" />
          </div>

          <mat-form-field appearence="fill" class="field-fill" *ngIf="element.itemType === 3" style="width: 100%;">
            <mat-label class="placeholder-title">From Date</mat-label>
            <input matInput readonly [matDatepicker]="pickerspoedit" (dateInput)="onDateChange($event, element)">
            <mat-datepicker-toggle matSuffix [for]="pickerspoedit"></mat-datepicker-toggle>
            <mat-datepicker #pickerspoedit></mat-datepicker>
          </mat-form-field>

          <mat-form-field apearence="fill" class="field-fill" *ngIf="element.itemType === 5" style="width: 100%;">
            <mat-label>Select an option</mat-label>
            <mat-select disableRipple [value]="element.CatType" (selectionChange)="OnDropdownUpdate($event,element)">
              <mat-option *ngFor="let data of element.dropDown"
                [value]="data.ElementValue">{{data.ElementValue}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="remarks">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Remarks</p>
        </th>

        <td mat-cell *matCellDef="let element" style="width: 30%; background-color: #edf1e5;">
          <mat-form-field appearance="fill" class="field-fill-table" style="width: 100%;">
            <input matInput placeholder="Remarks" autocomplete="off" (change)="remarksUpdate($event, element)"
              [value]="element.remarks">
          </mat-form-field>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="itemColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: itemColumns"></tr>
      <tr class="mat-row" *matNoDataRow style="text-align: center;">
        <td class="mat-cell" style="text-align: center;" colspan="7">No Data Match</td>
      </tr>

      <!-- Group header -->
      <ng-container matColumnDef="groupHeader">
        <th (click)="groupHeaderClick(group)" colspan="999" mat-header-cell *matCellDef="let group"
          class="group-header">
          <div class="d-flex align-items-center">
            <mat-icon *ngIf="group.expanded">arrow_drop_down</mat-icon>
            <mat-icon *ngIf="!group.expanded">arrow_right</mat-icon>
            <span class="table-group-title">{{group[groupByColumns[group.level-1]]}}</span>
          </div>
        </th>
      </ng-container>
      <tr mat-header-row *matRowDef="let group; columns: ['groupHeader'];when: isGroup"></tr>
      <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </mat-row>
    </table>
  </form>

  <div mat-dialog-actions style="float: right; margin-top: 10px">
    <button mat-mini-fab mat-button color="primary" [disabled]="!checklistDataForm.valid && disableAdd"
      (click)="addCheckListExecution()" *ngIf="IsWrite" pTooltip="Add Execution" tooltipPosition="top">
      <i class="fa fa-floppy-o" style="color: #fff"></i>
    </button>
  </div>
</ng-template>




<ng-template #imageZoomModel>
  <img src="{{currentImagePath}}" style="height: 98%;width: 100%; overflow: hidden;">
</ng-template>

<ng-template #imageZoomModelShow>
  <img [src]="currentImagePath" style="height: 98%; width: 100%; overflow: hidden" />
</ng-template>

<ng-template #displayRoleModalView>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h1 mat-dialog-title>Check List Execution</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div id="pdfTable" #pdfTable>
    <form [formGroup]="checklistDataFormView">
      <mat-dialog-content>
        <div class="content-top-row content-top-row-order-processing">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Group</mat-label>
            <input matInput formControlName="group" placeholder="Enter group" readonly />
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Plant</mat-label>
            <input matInput formControlName="plant" placeholder="Enter plant" readonly />
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Check List Type</mat-label>
            <input matInput formControlName="checktype" placeholder="Enter checktype" readonly />
          </mat-form-field>
          
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Check List Name</mat-label>
            <input matInput formControlName="Listname" placeholder="Enter List Name" readonly />
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Area</mat-label>
            <input matInput formControlName="area" placeholder="Enter area" readonly />
          </mat-form-field>
        </div>
        <div class="content-top-row content-top-row-order-processing">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing ">
            <mat-label>Date</mat-label>
            <input matInput formControlName="executionDate" placeholder="Enter executionDate" readonly />
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Shift</mat-label>
            <input matInput formControlName="shift" placeholder="Shift" [readonly]="true" />
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Select Unit</mat-label>
            <input matInput formControlName="Unit" placeholder="Select Unit" [readonly]="true" />
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Current Process Order No</mat-label>
            <input matInput formControlName="currentProcessOrder" placeholder="Select currentProcessOrder"
              [readonly]="true" />
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Current Product Name</mat-label>
            <input matInput placeholder="Product Description" formControlName="currentProductDescription"
              [readonly]="true" />
          </mat-form-field>
        </div>
        <div class="content-top-row content-top-row-order-processing">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Previous Process Order No</mat-label>
            <input matInput placeholder="previous ProcessOrder" formControlName="previousProcessOrder"
              [readonly]="true" />
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Previous Product Name</mat-label>
            <input matInput placeholder="Product Description" formControlName="previousProductDescription"
              [readonly]="true" />
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Target Order Quantity</mat-label>
            <input matInput placeholder="Target Order Quantity" formControlName="targetOrderQuantity"
              [readonly]="true" />
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Product Quantity</mat-label>
            <input matInput placeholder="Produced Quantity" formControlName="producedQuantity" [readonly]="true" />
          </mat-form-field>

          <mat-form-field *ngIf="completedRole" [disabled]="VerifiedStatus === 'Verified' || VerifiedStatus === 'Reject'" appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select [disabled]="VerifiedStatus === 'Verified' || VerifiedStatus === 'Reject'" formControlName="con"
              placeholder="Confirmed" panelClass="testClass" (selectionChange)="rejectPopup($event)">
              <mat-option *ngFor="let confirmed of confirmedlist" [value]="confirmed.name">
                {{ confirmed?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-dialog-content>
      <mat-divider></mat-divider>

      <div style="margin-top: 10px"></div>

      <div class="wrapper-box wrapper-box-shadow table-wrapper" *ngIf="dataSourceView !== undefined">
        <table mat-table [dataSource]="dataSourceView" class="basic-table plant-model-table popup" matSort>
          <ng-container matColumnDef="items">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Items</p>
            </th>
            <td mat-cell *matCellDef="let element" class="td-text">
              {{ element.ItemName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="good">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 0px; width: 12%">
              <p class="header-text">Good Example</p>
            </th>
            <td mat-cell *matCellDef="let element" style="width: 10%">
              <button mat-mini-fab *ngIf="!formMode && element.GoodExampleImage!='' && element.GoodExampleImage!=null"
                color="accent" (click)="openFilePopUpView(element , i , 1)" style="background-color: #8f0fd1;">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
              </td>
          </ng-container>

          <ng-container matColumnDef="bad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 0px; width: 12%">
              <p class="header-text">Bad Example</p>
            </th>
            <td mat-cell *matCellDef="let element" style="width: 10%">
              <button mat-mini-fab color="accent"
                *ngIf="!formMode && element.BadExampleImage!='' && element.BadExampleImage!=null"
                (click)="openFilePopUpView(element , i , 2  )" style="background-color: #8f0fd1;">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="standard">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Standard</p>
            </th>
            <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%;">
              {{element.standard}}
            </td>
          </ng-container>

          <ng-container matColumnDef="minimum">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Min</p>
            </th>
            <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%; background-color: #edf1e5;">
              {{element.minValue}}
            </td>
          </ng-container>

          <ng-container matColumnDef="maximum">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Max</p>
            </th>
            <td mat-cell *matCellDef="let element" class="wrapped-text" style="width: 10%; background-color: #edf1e5;">
              {{element.maxValue}}
            </td>
          </ng-container>

          <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 56px">
              <p class="header-text">Result</p>
            </th>
            <td mat-cell *matCellDef="let element;let i = index" class="td-text wrapped-text"
              style="padding-left: 80px">

              <textarea *ngIf="element.ItemTypeId === 1" pTooltip="{{element.CatType}}" class="wrapped-text" matInput
                [value]="element.CatType" [readonly]="true"></textarea>
              <textarea *ngIf="element.ItemTypeId === 2" pTooltip="{{element.CatType}}" class="wrapped-text" matInput
                [value]="element.CatType" [readonly]="true"></textarea>
              <input *ngIf="element.ItemTypeId === 3" matInput [value]="element.CatType | date: 'dd-MM-YYYY'"
                [readonly]="true" />

              <img
                *ngIf="element.ItemTypeId === 4  && element.typefile === 'jpg' ||element.ItemTypeId === 4 && element.typefile === 'png' "
                [src]="element.CatType!== '' ?element.CatType:'assets/images/no-image.png'" width="30px" height="30px"
                (click)="imageZoomModal(element.CatType!== '' ? element.CatType:'assets/images/no-image.png')">
              <textarea *ngIf="element.ItemTypeId === 5" class="wrapped-text" matInput [value]="element.CatType"
                [readonly]="true"></textarea>

              <div
                *ngIf="element.typefile ==='pdf' || element.typefile === 'txt' || element.typefile === 'xlsx' || element.typefile === 'xsl' || element.typefile === 'mp4' || element.typefile === 'wmv' || element.typefile === 'csv' ">
                <button type="button" mat-mini-fab color="primary" style="margin-top: 2px;"
                  *ngIf="element.CatType != null && element.CatType != ''">
                  <a href="{{element.CatType}}">
                    <mat-icon>cloud_download</mat-icon>
                  </a>
                </button>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="remarks">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Remarks</p>
            </th>

            <td mat-cell *matCellDef="let element" class="wrapped-text">
              {{element.Remarks}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="itemColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: itemColumns"></tr>
          <tr class="mat-row" *matNoDataRow style="text-align: center;">
            <td class="mat-cell" style="text-align: center;" colspan="7">No Data Match</td>
          </tr>

          <ng-container matColumnDef="groupHeader">
            <th (click)="groupHeaderClickView(group)" colspan="999" mat-header-cell *matCellDef="let group"
              class="group-header">
              <div class="d-flex align-items-center">
                <mat-icon *ngIf="group.expanded">arrow_drop_down</mat-icon>
                <mat-icon *ngIf="!group.expanded">arrow_right</mat-icon>
                <span style="font-size: 20px;" class="table-group-title">{{group[groupByColumns[group.level-1]]}}</span>
              </div>
            </th>
          </ng-container>
          <tr mat-header-row *matRowDef="let group; columns: ['groupHeader'];when: isGroup"></tr>
          <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </mat-row>
        </table>

        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[50,100]" showFirstLastButtons></mat-paginator>
      </div>
      <div mat-dialog-actions style="float: right; margin-top: 10px">
      </div>
    </form>
  </div>
</ng-template>
<ng-template #imageZoomModel2>
  <img [src]="currentImagePath" style="height: 98%; width: 100%; overflow: hidden" />
  <div *ngIf="!currentImagePath" class="popup-text-border">
    <img src="assets/images/no-image.png">
  </div>
</ng-template>




<p-dialog [closeOnEscape]="false" header="File Detail" [(visible)]="displayMaximizable" [modal]="true"
  [style]="{width: '90vw'}" [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ngx-doc-viewer *ngIf="fileType==='doc'" [url]="doc" viewer="google"
    style="width:100%;height:90vh;margin-top:10px"></ngx-doc-viewer>
  <angular-image-viewer *ngIf="fileType==='img'" [src]="images" style="width:100%;height:90vh;margin-top:10px"
    [(index)]="imageIndexOne"></angular-image-viewer>
</p-dialog>