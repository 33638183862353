import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TechScoreFormComponent } from './tech-score-form/tech-score-form.component';
import { TechScoreComponent } from './tech-score/tech-score.component';

const routes: Routes = [
  { path: '', component: TechScoreComponent },
  { path: 'score', component: TechScoreComponent },
  { path: 'score-form', component: TechScoreFormComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SkillMatrixRoutingModule { }
