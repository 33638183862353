import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  NgxMatDateAdapter,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableExporterModule } from 'mat-table-exporter';

import { MaterialModule } from 'src/app/material-module';
import { CilManagementComponent } from './cil-management/cil-management.component';
import { CILRoutingModule } from './cil-routing.module';
import { CilConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/cil-configuration/cil-configuration.component';
import { CilReportComponent } from './cil-report/cil-report.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TooltipModule } from 'primeng/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { AmCilDashboardComponent } from './am-cil-dashboard/am-cil-dashboard.component';
import { CilDashboardComponent } from './cil-dashboard/cil-dashboard.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatSelectFilterModule } from 'mat-select-filter';
import { ChartModule } from 'angular-highcharts';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NewCilDashboardComponent } from './new-cil-dashboard/new-cil-dashboard.component';
import { NewDefectDashboardComponent } from './new-defect-dashboard/new-defect-dashboard.component';
import { FiveSDashboardComponent } from './five-s-dashboard/five-s-dashboard.component';
import { NgxMatSelectModule } from '../ngx-mat-select/ngx-mat-select.module';
@NgModule({
  imports: [
    CommonModule,
    CILRoutingModule,
    NgxSpinnerModule,
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MaterialModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableExporterModule,
    TooltipModule,
    MatTooltipModule,
    NzDatePickerModule,
    AgGridModule,
    MatSelectFilterModule,
    ChartModule,
    NgxDropzoneModule,
    MaterialFileInputModule,
    NgxMatSelectModule,
  ],
  declarations: [
    CilManagementComponent,
    CilConfigurationComponent,
    CilReportComponent,
    AmCilDashboardComponent,
    CilDashboardComponent,
    NewCilDashboardComponent,
    NewDefectDashboardComponent,
    FiveSDashboardComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CILModule {}
