import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { TechScoreService } from 'src/app/services/tech-score.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatAccordion } from '@angular/material/expansion';
import { TechnicianService } from 'src/app/services/technician.service';
import { environment } from 'src/environments/environment';
import { CILMasterService } from 'src/app/services/cil-master-service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-tech-score-form',
  templateUrl: './tech-score-form.component.html',
  styleUrls: ['./tech-score-form.component.scss']
})
export class TechScoreFormComponent implements OnInit {

  scoreForm = new FormGroup({
    group: new FormControl(''),
    plant: new FormControl(''),
    machine: new FormControl(''),
    tech: new FormControl('', [Validators.required])
  });
  score: any = {
    Group: '',
    Plant: '',
    Machine: '',
    TechId: '',
    WOType: '',
    Score: '',
    Picture: '',
    CreatedBy: '',
    ID: ''
  }
  starDescList: any = [
    { value: 5, title: 'Can teach and work' },
    { value: 4, title: 'Can apply under any conditions' },
    { value: 3, title: 'Can apply under standard conditions' },
    { value: 2, title: 'Theoretically fit' },
    { value: 1, title: 'Not Qualified' }
  ]
  technicianList: any = [];
  technicianFiltList: any = [];
  userList: any = [];
  userFiltList: any = [];
  techScoreList: any = [];
  techMachineList: any = [];
  selectedMachineList: any = [];
  spinnerText: string = '';
  breadcrumList!: any;
  IsWrite: boolean = false;
  groups!: any[];
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('rating10') rating10!: ElementRef;
  @ViewChild('rating9') rating9!: ElementRef;
  @ViewChild('rating8') rating8!: ElementRef;
  @ViewChild('rating7') rating7!: ElementRef;
  @ViewChild('rating6') rating6!: ElementRef;
  @ViewChild('rating5') rating5!: ElementRef;
  @ViewChild('rating4') rating4!: ElementRef;
  @ViewChild('rating3') rating3!: ElementRef;
  @ViewChild('rating2') rating2!: ElementRef;
  @ViewChild('rating1') rating1!: ElementRef;
  image: any = { name: '', src: '', file: '' };
  techScoreColumns: string[] = ['WOType', 'Score'];
  techMachineColumns: string[] = ['Plant', 'Division', 'Department', 'Line', 'Machine'];
  dataSource = new MatTableDataSource();
  scoreStarListUpdated: boolean = false;
  currentTechName: string = '';
  AverageScore: number = 0;
  blobUrl: string = "";

  PlantList: any = [];
  techobj: any;
  @ViewChild(MatAccordion) accordion: MatAccordion = new MatAccordion;
  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @ViewChild('techscoreformimageViewModal') private techscoreformimageViewModal!: TemplateRef<any>;

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.failureMode && node.failureMode.length > 0,
      name: node.Name,
      level: level,
      index: node.index
    };
  }

  treeControl = new FlatTreeControl<any>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.failureMode);

  treeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: any) => node.expandable;
  filterTechnicianList: any = [];

  constructor(
    private spinner: NgxSpinnerService, 
    private enterpriseGroupService: EnterPriseGroupService, 
    private toaster: ToastrService, 
    private router: Router, 
    private userManagementService: UserManagementService, 
    private sfdService: ShopFloorDataService, 
    private analyticalToolsService: AnalyticalToolsService, 
    private techScoreService: TechScoreService, 
    private technicianService: TechnicianService, 
    private cilMasterService: CILMasterService, 
    private commonService: CommonService, 
    private dialog:MatDialog) { }

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.getGroups();
    this.setUser();
    this.GetMenuAccess();
    const userGroup = this.analyticalToolsService.getUserGroup();
    this.techobj = this.technicianService.getTechnicianId();
    this.blobUrl = environment.O3CoreApiImageUrl;
    if (this.techobj.UserId) {
      this.score.TechId = this.techobj.UserId;
      this.getTechScoreList();
      if (userGroup) {
        this.score.Group = userGroup;
        this.getPlants(this.score.Group);
      }
    }
    else
      this.router.navigate(['/PM/skill-matrix/score']);
  }

  setUser() {
    let user: any = localStorage.getItem('user');
    user = JSON.parse(user);
    this.score.CreatedBy = user.UserId;
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getUserList() {
    this.spinnerText = "Fetching User Data";
    this.spinner.show();
    const data = { EGId: this.score.Group, PlantId: this.score.Plant };
    this.commonService.getPeopleMasterData(data).subscribe((res: any) => {
      if (res.employeeInfoList && res.employeeInfoList.length) {
        this.userList = res.employeeInfoList.filter((emp: any) => emp.Dept_Desc == 'Maintenance' && emp.UserId);
        this.userFiltList = this.userList.slice();

        for (let tech of this.technicianFiltList) {
          const user = this.userList.find((usr: any) => usr.UserId == tech.UserId);
          if (user)
            tech.UserName = user.fullName;
        }
      }
      this.spinner.hide();
    }, (err: any) => {
      this.spinner.hide();
    });
  }

  getPlants(id: any): void {
    this.cilMasterService.getPlantsData(id).subscribe((data: any) => {
      this.PlantList = data.dropDownList;
      this.score.Plant = this.techobj.PlantID;
      this.getTechnicianList();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  imgview(e:any){
    let dialogRef = this.dialog.open(this.techscoreformimageViewModal, {
     height: '90%',
     width: '90%',
   });
   dialogRef.afterClosed().subscribe((result) => {});
   }
   oncloseimg(){
     this.dialog.closeAll();
   }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.spinnerText = 'Loading Menu Data';
    this.spinner.show();
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.spinner.hide();
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  updateAverageScore() {
    if (this.AverageScore) {
      this.AverageScore = parseFloat(this.AverageScore.toFixed(2));
      let avg = this.AverageScore - Math.floor(this.AverageScore);
      avg = parseFloat(avg.toFixed(2));
      if (Math.ceil(this.AverageScore) == 5) {
        if (avg > .5 || avg == 0)
          this.rating10.nativeElement.checked = this.rating9.nativeElement.checked = this.rating8.nativeElement.checked = this.rating7.nativeElement.checked = this.rating6.nativeElement.checked = this.rating5.nativeElement.checked = this.rating4.nativeElement.checked = this.rating3.nativeElement.checked = this.rating2.nativeElement.checked = this.rating1.nativeElement.checked = true;
        else {
          this.rating10.nativeElement.checked = false;
          this.rating9.nativeElement.checked = this.rating8.nativeElement.checked = this.rating7.nativeElement.checked = this.rating6.nativeElement.checked = this.rating5.nativeElement.checked = this.rating4.nativeElement.checked = this.rating3.nativeElement.checked = this.rating2.nativeElement.checked = this.rating1.nativeElement.checked = true;
        }
      }
      else if (Math.ceil(this.AverageScore) == 4) {
        if (avg > .5 || avg == 0) {
          this.rating10.nativeElement.checked = this.rating9.nativeElement.checked = false;
          this.rating8.nativeElement.checked = this.rating7.nativeElement.checked = this.rating6.nativeElement.checked = this.rating5.nativeElement.checked = this.rating4.nativeElement.checked = this.rating3.nativeElement.checked = this.rating2.nativeElement.checked = this.rating1.nativeElement.checked = true;
        }
        else {
          this.rating10.nativeElement.checked = this.rating9.nativeElement.checked = this.rating8.nativeElement.checked = false;
          this.rating7.nativeElement.checked = this.rating6.nativeElement.checked = this.rating5.nativeElement.checked = this.rating4.nativeElement.checked = this.rating3.nativeElement.checked = this.rating2.nativeElement.checked = this.rating1.nativeElement.checked = true;
        }
      }
      else if (Math.ceil(this.AverageScore) == 3) {
        if (avg > .5 || avg == 0) {
          this.rating10.nativeElement.checked = this.rating9.nativeElement.checked = this.rating8.nativeElement.checked = this.rating7.nativeElement.checked = false;
          this.rating6.nativeElement.checked = this.rating5.nativeElement.checked = this.rating4.nativeElement.checked = this.rating3.nativeElement.checked = this.rating2.nativeElement.checked = this.rating1.nativeElement.checked = true;
        }
        else {
          this.rating10.nativeElement.checked = this.rating9.nativeElement.checked = this.rating8.nativeElement.checked = this.rating7.nativeElement.checked = this.rating6.nativeElement.checked = false;
          this.rating5.nativeElement.checked = this.rating4.nativeElement.checked = this.rating3.nativeElement.checked = this.rating2.nativeElement.checked = this.rating1.nativeElement.checked = true;
        }
      }
      else if (Math.ceil(this.AverageScore) == 2) {
        if (avg > .5 || avg == 0) {
          this.rating10.nativeElement.checked = this.rating9.nativeElement.checked = this.rating8.nativeElement.checked = this.rating7.nativeElement.checked = this.rating6.nativeElement.checked = this.rating5.nativeElement.checked = false;
          this.rating4.nativeElement.checked = this.rating3.nativeElement.checked = this.rating2.nativeElement.checked = this.rating1.nativeElement.checked = true;
        }
        else {
          this.rating10.nativeElement.checked = this.rating9.nativeElement.checked = this.rating8.nativeElement.checked = this.rating7.nativeElement.checked = this.rating6.nativeElement.checked = this.rating5.nativeElement.checked = this.rating4.nativeElement.checked = false;
          this.rating3.nativeElement.checked = this.rating2.nativeElement.checked = this.rating1.nativeElement.checked = true;
        }
      }
      else if (Math.ceil(this.AverageScore) == 1) {
        if (avg > .5 || avg == 0) {
          this.rating10.nativeElement.checked = this.rating9.nativeElement.checked = this.rating8.nativeElement.checked = this.rating7.nativeElement.checked = this.rating6.nativeElement.checked = this.rating5.nativeElement.checked = this.rating4.nativeElement.checked = this.rating3.nativeElement.checked = false;
          this.rating2.nativeElement.checked = this.rating1.nativeElement.checked = true;
        }
        else {
          this.rating10.nativeElement.checked = this.rating9.nativeElement.checked = this.rating8.nativeElement.checked = this.rating7.nativeElement.checked = this.rating6.nativeElement.checked = this.rating5.nativeElement.checked = this.rating4.nativeElement.checked = this.rating3.nativeElement.checked = this.rating2.nativeElement.checked = false;
          this.rating1.nativeElement.checked = true;
        }
      }
    }
  }

  getTechScoreList() {
    this.spinnerText = 'Fetching Score Data';
    this.spinner.show();
    this.scoreStarListUpdated = false;
    const data = { techId: this.score.TechId }
    if (this.technicianList && this.technicianList.length) {
      const currentTechName = this.technicianList.find((tech: any) => tech.UserId == this.score.TechId);
      this.currentTechName = currentTechName.UserName;
    }
    this.techScoreService.getTechScoreList(data).subscribe((res: any) => {
      this.spinner.show();
      this.AverageScore = res.data.technician.AverageScore;
      this.updateAverageScore();
      this.techobj.Picture = res.data.technician.Picture;
      this.score.Picture = res.data.technician.Picture;
      this.techScoreList = res.data.woTypeList;
      
      if (this.techScoreList && this.techScoreList.length) {
        this.techScoreList.map((techScore: any, index: number) => {
          techScore.index = index;
          if (techScore.failureMode && techScore.failureMode.length)
            techScore.failureMode.map((failMode: any, failIndex: number) => failMode.index = index + 'f' + failIndex)
        })
      }
      this.treeDataSource.data = this.techScoreList;
      this.dataSource = new MatTableDataSource(this.techScoreList);
      this.dataSource.sort = this.sort;
      setTimeout(() => {
        if (!this.scoreStarListUpdated)
          this.updateScoreStarList();
      }, 300);
      this.spinner.hide();
    },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error(err.message, 'Error')
      }
    )
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.sfdService.getGroupData(key).subscribe((res: any) => {
      this.groups = res.masterList;
      if (this.techobj.GroupID && this.techobj.PlantID) {
        this.score.Group = this.techobj.GroupID;
        this.getPlants(this.score.Group);
      }
    });
  }

  getTechnicianList() {
    this.spinnerText = 'Fetching Technician Data';
    this.spinner.show();
    const data = {
      Group: this.score.Group,
      Plant: this.score.Plant
    }
    this.technicianService.getTechnicianList(data).subscribe((res: any) => {
     
      if (res.status) {
        this.technicianFiltList = [];
        if (res.data && res.data.length) {
          this.spinner.show();
          for (let user of res.data) {
            const usr = this.technicianFiltList.find((usr: any) => usr.UserId == user.UserId);
            if (!usr)
              this.technicianFiltList.push(user)
          }
        }
        this.getUserList();
        this.spinner.hide();
      }
    },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error('Error', err.message)
      }
    )
  }

  openFile() {
    this.fileInput.nativeElement.click()
  }

  readFile(event: any) {
    const file = event.target.files[0];
    this.image.file = file;
    this.image.name = file.name;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image.src = reader.result as string;
      };
    }
  }

  updateFailModeScore(index: number) {
    let failModeIndex = 0;
    if (this.techScoreList[index].failureMode && this.techScoreList[index].failureMode.length) {
      for (let failMode of this.techScoreList[index].failureMode) {
        if (failMode.Score) {
          const failrating5: any = document.querySelector(`#rating-list-5${index}f${failModeIndex}`);
          const failrating4: any = document.querySelector(`#rating-list-4${index}f${failModeIndex}`);
          const failrating3: any = document.querySelector(`#rating-list-3${index}f${failModeIndex}`);
          const failrating2: any = document.querySelector(`#rating-list-2${index}f${failModeIndex}`);
          const failrating1: any = document.querySelector(`#rating-list-1${index}f${failModeIndex}`);
          if (failrating5 && failrating4 && failrating3 && failrating2 && failrating1) {
            if (failMode.Score == 5) {
              failrating5.checked = failrating4.checked = failrating3.checked = failrating2.checked = failrating1.checked = true;
            }
            else if (failMode.Score == 4) {
              failrating5.checked = false;
              failrating4.checked = failrating3.checked = failrating2.checked = failrating1.checked = true;
            }
            else if (failMode.Score == 3) {
              failrating5.checked = failrating4.checked = false;
              failrating3.checked = failrating2.checked = failrating1.checked = true;
            }
            else if (failMode.Score == 2) {
              failrating5.checked = failrating4.checked = failrating3.checked = false;
              failrating2.checked = failrating1.checked = true;
            }
            else if (failMode.Score == 1) {
              failrating5.checked = failrating4.checked = failrating3.checked = failrating2.checked = false;
              failrating1.checked = true;
            }
          }
        }
        failModeIndex++;
      }
    }
  }

  updateScoreStarList() {
    let index = 0;
    for (const wo of this.techScoreList) {
      if (wo.Score) {
        wo.Score = parseFloat(wo.Score.toFixed(2));
        let decimalScore = wo.Score - Math.floor(wo.Score);
        decimalScore = parseFloat(decimalScore.toFixed(2));

        const rating10: any = document.querySelector('#rating-list-10' + index);
        const rating9: any = document.querySelector('#rating-list-9' + index);
        const rating8: any = document.querySelector('#rating-list-8' + index);
        const rating7: any = document.querySelector('#rating-list-7' + index);
        const rating6: any = document.querySelector('#rating-list-6' + index);
        const rating5: any = document.querySelector('#rating-list-5' + index);
        const rating4: any = document.querySelector('#rating-list-4' + index);
        const rating3: any = document.querySelector('#rating-list-3' + index);
        const rating2: any = document.querySelector('#rating-list-2' + index);
        const rating1: any = document.querySelector('#rating-list-1' + index);
        if (rating10 && rating9 && rating8 && rating7 && rating6 && rating5 && rating4 && rating3 && rating2 && rating1) {
          if (Math.ceil(wo.Score) == 5) {
            if (decimalScore > .5 || decimalScore == 0)
              rating10.checked = rating9.checked = rating8.checked = rating7.checked = rating6.checked = rating5.checked = rating4.checked = rating3.checked = rating2.checked = rating1.checked = true;
            else {
              rating10.checked = false;
              rating9.checked = rating8.checked = rating7.checked = rating6.checked = rating5.checked = rating4.checked = rating3.checked = rating2.checked = rating1.checked = true;
            }
          }
          else if (Math.ceil(wo.Score) == 4) {
            if (decimalScore > .5 || decimalScore == 0) {
              rating10.checked = rating9.checked = false;
              rating8.checked = rating7.checked = rating6.checked = rating5.checked = rating4.checked = rating3.checked = rating2.checked = rating1.checked = true;
            }
            else {
              rating10.checked = rating9.checked = rating8.checked = false;
              rating7.checked = rating6.checked = rating5.checked = rating4.checked = rating3.checked = rating2.checked = rating1.checked = true;
            }
          }
          else if (Math.ceil(wo.Score) == 3) {
            if (decimalScore > .5 || decimalScore == 0) {
              rating10.checked = rating9.checked = rating8.checked = rating7.checked = false;
              rating6.checked = rating5.checked = rating4.checked = rating3.checked = rating2.checked = rating1.checked = true;
            }
            else {
              rating10.checked = rating9.checked = rating8.checked = rating7.checked = rating6.checked = false;
              rating5.checked = rating4.checked = rating3.checked = rating2.checked = rating1.checked = true;
            }
          }
          else if (Math.ceil(wo.Score) == 2) {
            if (decimalScore > .5 || decimalScore == 0) {
              rating10.checked = rating9.checked = rating8.checked = rating7.checked = rating6.checked = rating5.checked = false;
              rating4.checked = rating3.checked = rating2.checked = rating1.checked = true;
            }
            else {
              rating10.checked = rating9.checked = rating8.checked = rating7.checked = rating6.checked = rating5.checked = rating4.checked = false;
              rating3.checked = rating2.checked = rating1.checked = true;
            }
          }
          else if (Math.ceil(wo.Score) == 1) {
            if (decimalScore > .5 || decimalScore == 0) {
              rating10.checked = rating9.checked = rating8.checked = rating7.checked = rating6.checked = rating5.checked = rating4.checked = rating3.checked = false;
              rating2.checked = rating1.checked = true;
            }
            else {
              rating10.checked = rating9.checked = rating8.checked = rating7.checked = rating6.checked = rating5.checked = rating4.checked = rating3.checked = false;
              rating2.checked = rating1.checked = true;
            }
          }
        }
        this.scoreStarListUpdated = true;
      }
      index++;
    }
  }

  updateScore(index: any, score: number) {
    const rating5: any = document.querySelector('#rating-list-5' + index);
    const rating4: any = document.querySelector('#rating-list-4' + index);
    const rating3: any = document.querySelector('#rating-list-3' + index);
    const rating2: any = document.querySelector('#rating-list-2' + index);
    const rating1: any = document.querySelector('#rating-list-1' + index);
    if (rating5 && rating4 && rating3 && rating2 && rating1) {
      if (score == 5) {
        rating5.checked = rating4.checked = rating3.checked = rating2.checked = rating1.checked = true;
      }
      else if (score == 4) {
        rating5.checked = false;
        rating4.checked = rating3.checked = rating2.checked = rating1.checked = true;
      }
      else if (score == 3) {
        rating5.checked = rating4.checked = false;
        rating3.checked = rating2.checked = rating1.checked = true;
      }
      else if (score == 2) {
        rating5.checked = rating4.checked = rating3.checked = false;
        rating2.checked = rating1.checked = true;
      }
      else if (score == 1) {
        rating5.checked = rating4.checked = rating3.checked = rating2.checked = false;
        rating1.checked = true;
      }
    }
    let indexs = index.split('f');
    const woTypeIndex = indexs[0];
    const failModeIndex = indexs[1];
    this.techScoreList[woTypeIndex].failureMode[failModeIndex].Score = score;
    let avg = 0;
    this.techScoreList[woTypeIndex].failureMode.map((fMode: any) => avg += fMode.Score ? fMode.Score : 0)
    this.techScoreList[woTypeIndex].Score = avg / this.techScoreList[woTypeIndex].failureMode.length;
    this.techScoreList[woTypeIndex].Score = parseFloat(this.techScoreList[woTypeIndex].Score.toFixed(2));
    let decimalScore = this.techScoreList[woTypeIndex].Score - Math.floor(this.techScoreList[woTypeIndex].Score);
    decimalScore = parseFloat(decimalScore.toFixed(2));
    const avgRating10: any = document.querySelector('#rating-list-10' + woTypeIndex);
    const avgRating9: any = document.querySelector('#rating-list-9' + woTypeIndex);
    const avgRating8: any = document.querySelector('#rating-list-8' + woTypeIndex);
    const avgRating7: any = document.querySelector('#rating-list-7' + woTypeIndex);
    const avgRating6: any = document.querySelector('#rating-list-6' + woTypeIndex);
    const avgRating5: any = document.querySelector('#rating-list-5' + woTypeIndex);
    const avgRating4: any = document.querySelector('#rating-list-4' + woTypeIndex);
    const avgRating3: any = document.querySelector('#rating-list-3' + woTypeIndex);
    const avgRating2: any = document.querySelector('#rating-list-2' + woTypeIndex);
    const avgRating1: any = document.querySelector('#rating-list-1' + woTypeIndex);

    if (avgRating5 && avgRating4 && avgRating3 && avgRating2 && avgRating1) {
      if (Math.ceil(this.techScoreList[woTypeIndex].Score) == 5) {
        if (decimalScore > .5 || decimalScore == 0)
          avgRating10.checked = avgRating9.checked = avgRating8.checked = avgRating7.checked = avgRating6.checked = avgRating5.checked = avgRating4.checked = avgRating3.checked = avgRating2.checked = avgRating1.checked = true;
        else {
          avgRating10.checked = false;
          avgRating9.checked = avgRating8.checked = avgRating7.checked = avgRating6.checked = avgRating5.checked = avgRating4.checked = avgRating3.checked = avgRating2.checked = avgRating1.checked = true;
        }
      }
      else if (Math.ceil(this.techScoreList[woTypeIndex].Score) == 4) {
        if (decimalScore > .5 || decimalScore == 0) {
          avgRating10.checked = avgRating9.checked = false;
          avgRating8.checked = avgRating7.checked = avgRating6.checked = avgRating5.checked = avgRating4.checked = avgRating3.checked = avgRating2.checked = avgRating1.checked = true;
        }
        else {
          avgRating10.checked = avgRating9.checked = avgRating8.checked = false;
          avgRating7.checked = avgRating6.checked = avgRating5.checked = avgRating4.checked = avgRating3.checked = avgRating2.checked = avgRating1.checked = true;
        }
      }
      else if (Math.ceil(this.techScoreList[woTypeIndex].Score) == 3) {
        if (decimalScore > .5 || decimalScore == 0) {
          avgRating10.checked = avgRating9.checked = avgRating8.checked = avgRating7.checked = false;
          avgRating6.checked = avgRating5.checked = avgRating4.checked = avgRating3.checked = avgRating2.checked = avgRating1.checked = true;
        }
        else {
          avgRating10.checked = avgRating9.checked = avgRating8.checked = avgRating7.checked = avgRating6.checked = false;
          avgRating5.checked = avgRating4.checked = avgRating3.checked = avgRating2.checked = avgRating1.checked = true;
        }
      }
      else if (Math.ceil(this.techScoreList[woTypeIndex].Score) == 2) {
        if (decimalScore > .5 || decimalScore == 0) {
          avgRating10.checked = avgRating9.checked = avgRating8.checked = avgRating7.checked = avgRating6.checked = avgRating5.checked = false;
          avgRating4.checked = avgRating3.checked = avgRating2.checked = avgRating1.checked = true;
        }
        else {
          avgRating10.checked = avgRating9.checked = avgRating8.checked = avgRating7.checked = avgRating6.checked = avgRating5.checked = avgRating4.checked = false;
          avgRating3.checked = avgRating2.checked = avgRating1.checked = true;
        }
      }
      else if (Math.ceil(this.techScoreList[woTypeIndex].Score) == 1) {
        if (decimalScore > .5 || decimalScore == 0)
        {
          avgRating10.checked = avgRating9.checked = avgRating8.checked = avgRating7.checked = avgRating6.checked = avgRating5.checked = avgRating4.checked = avgRating3.checked = false;
          avgRating2.checked = avgRating1.checked = true;
        }
        else{
          avgRating10.checked = avgRating9.checked = avgRating8.checked = avgRating7.checked = avgRating6.checked = avgRating5.checked = avgRating4.checked = avgRating3.checked =  avgRating2.checked = false;
          avgRating1.checked = true;
        }
          
      }
    }
    let sumScore = 0;
    this.techScoreList.map((woScore: any) => sumScore += woScore.Score);
    let techScore = this.techScoreList.filter((tech: any) => {
      return tech.Score != 0;
    })
    this.AverageScore = sumScore / techScore.length;
    this.updateAverageScore();
  }

  onSubmit() {
    if (this.scoreForm.invalid)
      return;
    else {
      for (const score of this.techScoreList) {
        score.TechId = this.score.TechId;
        score.WOTypeId = score.ID;
        score.CreatedBy = this.score.CreatedBy;
        score.UpdatePic = this.techobj.Picture ? this.techobj.Picture : null;
        delete score.RMPM;
      }
      const formData = new FormData();
      formData.append('Image', this.image.file);
      formData.append('data', JSON.stringify(this.score));
      formData.append('techScoreList', JSON.stringify(this.techScoreList));
      this.spinnerText = 'Submitting Score Data';
      this.spinner.show();
      this.techScoreService.createTechScore(formData).subscribe((res: any) => {
        this.getTechScoreList();
        this.toaster.success(res.message, 'Success');
        this.spinner.hide();
      },
        (err: any) => {
          this.spinner.hide();
          this.toaster.error(err.message, 'Error')
        }
      )
    }
  }

}
