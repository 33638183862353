import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  PlantModelElement,
  PlantGroupElement,
} from '../models/O3AdministrationModels/plant-model';

@Injectable({
  providedIn: 'root',
})
export class EnterPriseGroupService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getGroupList(data: any): Observable<PlantModelElement[]> {
    return this.http.post<PlantModelElement[]>(this.o3CoreApiUrl + 'PlantModal/GetGroupList', data);
  }
  submitGroup(data: any): Observable<PlantModelElement[]> {
    return this.http.post<PlantModelElement[]>(this.o3CoreApiUrl + 'PlantModal/SumbitGroup', data);
  }
  getTreeModal(data: any): Observable<PlantGroupElement[]> {
    return this.http.post<PlantGroupElement[]>(this.o3CoreApiUrl + 'Common/GetTreeModal', data);
  }
  getTreeModalComponent(data: any): Observable<PlantGroupElement[]> {
    return this.http.post<PlantGroupElement[]>(this.o3CoreApiUrl + 'CIL/GetCompModelTreeForCIL', data);
  }
  addPlantModalTree(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PlantModal/SumbitChildModel', data);
  }
  getPlantModalById(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PlantModal/GetChildModel', data);
  }
  GetMenuAccess(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Account/GetMenuAccess', data);
  }
  getExcel(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PlantModal/GetPlantModelExcel',data);
  }
  uploadMasterDataPlantModel(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + "PlantModal/GetPlantMasterData",data);
  }
}
