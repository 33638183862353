import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Histogram from 'highcharts/modules/histogram-bellcurve';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { QualityMachineTrendDashboardService } from 'src/app/services/quality-machine-trend-dashboard.service';
import { OpmsQualityService } from 'src/app/services/opms-quality.service';
import { OpmsGlassService } from 'src/app/services/opms-glass.service';
HighchartsMore(Highcharts);
Histogram(Highcharts);
@Component({
  selector: 'app-quality-parameter-trend',
  templateUrl: './quality-parameter-trend.component.html',
  styleUrls: ['./quality-parameter-trend.component.scss'],
})
export class QualityParameterTrendComponent implements OnInit {
  columnDefsTask: any[] = [];
  breadcrumList: any[] = [];
  tableData: any = [];
  qualityType: any = [];
  paramsType: any = [];
  filteredParamsTypeArray: any;
  parameterArray: any[] = [];
  productArray: any[] = [];
  filteredParameterArray: any;
  filteredProductArray: any;
  histogramChart!: any;
  controlSummary: any[] = [];
  constructor(
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private trendDashboardService: QualityMachineTrendDashboardService,
    private opmsQualityService: OpmsQualityService,
    private opmsGlassservice: OpmsGlassService,
  ) {
    this.qualityType = [
      {
        qualityTypeId: 1,
        qualityTypeDesc: 'Chemical Lab'
      },
      {
        qualityTypeId: 2,
        qualityTypeDesc: 'Physical Lab'
      }
    ]
    this.columnDefsTask = [
      {
        headerName: 'Description',
        field: 'description',
        minWidth: 100,
        filter: true,
      },
      {
        headerName: 'Value',
        field: 'value',
        minWidth: 100,
        filter: true,
      },
    ];
  }

  qualityParameterTrendDashboardForm = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
    qualityType: new FormControl(),
    paramsType: new FormControl(),
    parameter: new FormControl(),
    product: new FormControl(),
  });

  ngOnInit() {
    this.GetBreadcrumList();
    const date = new Date();
    let firstDayfincialGap = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7
    );
    let lastDayfincialGap = new Date();

    firstDayfincialGap = new Date(
      new Date(firstDayfincialGap).setHours(0, 0, 0, 0)
    );
    lastDayfincialGap = new Date(
      new Date(lastDayfincialGap).setHours(23, 59, 59, 59)
    );
    this.qualityParameterTrendDashboardForm
      .get('startDate')
      ?.setValue(firstDayfincialGap);
    this.qualityParameterTrendDashboardForm
      .get('endDate')
      ?.setValue(lastDayfincialGap);
    this.qualityParameterTrendDashboardForm
      .get('qualityType')
      ?.setValue(this.qualityType[0].qualityTypeId);
    this.getQualityParamsType();
  }

  getQualityParamsType(){
    this.paramsType = [];
    this.filteredParamsTypeArray = [];
    this.parameterArray = [];
    this.filteredParameterArray = [];
    if(this.qualityParameterTrendDashboardForm.value.qualityType == 1){
      this.opmsQualityService.getMaterialsForChemical({}).subscribe(
        (res: any) => {
          this.paramsType = res.MaterialList;
          this.qualityParameterTrendDashboardForm.get('paramsType')?.setValue(this.paramsType[0].TypeId);
          this.getParameterDropdown();
          this.filteredParamsTypeArray = res.MaterialList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    }
    else{
      this.trendDashboardService.getReportTypeForPhysicalLab().subscribe(
          (res: any) => {
            this.paramsType = res?.reportType;
            this.qualityParameterTrendDashboardForm.get('paramsType')?.setValue(this.paramsType[0].TypeId);
            this.filteredParameterArray = res.reportType;
            this.getParameterDropdown();
          },
          ({ error }) => {
            this.toaster.error(error);
          },
        );
      this.getProductsDropdown();
    }
  }

  getParameterDropdown() {
    this.parameterArray = [];
    this.filteredParameterArray = [];
    const key = {
      ParamTypeId: this.qualityParameterTrendDashboardForm.value.paramsType,
      QualityType: this.qualityParameterTrendDashboardForm.value.qualityType
    };
    this.trendDashboardService
      .getQualityParametersDropdownByQualityParamsTypeId(key)
      .subscribe({
        next: (res: any) => {
          if(this.qualityParameterTrendDashboardForm.value.qualityType == 1){
            this.parameterArray = res;
            this.qualityParameterTrendDashboardForm.get('parameter')?.setValue(res[0].id);
            this.filteredParameterArray = res;
          }
          else{
            this.transformParameterDataForPhysicalLab(res);
          }
        },
        error: ({ error }) => {
          this.toaster.error(error);
        },
      });
  }

  transformParameterDataForPhysicalLab(params: any){
    let parameterArray = [];
    for(let sectionIndex = 0; sectionIndex < params.length; sectionIndex++){
      var obj = {
        SectionName: '',
        Options: []
      }
      obj.SectionName = params[sectionIndex].SectionName;
      let actualColumn = params[sectionIndex].ActualColumnName.split(',*,');
      let userColumn = params[sectionIndex].UserColumnName.split(',*,');
      for(let optionIndex = 0; optionIndex < actualColumn.length; optionIndex++){
        obj.Options.push(
          {ActualColumnName: actualColumn[optionIndex]+',*,'+
                             params[sectionIndex].TableName+',*,'+
                             params[sectionIndex].Id+',*,'+
                             params[sectionIndex].ProductColumnName, 
           UserColumnName: userColumn[optionIndex]
          }
        )
      }
      parameterArray.push(obj);
    }
    this.parameterArray = parameterArray;
    this.qualityParameterTrendDashboardForm.get('parameter')?.setValue(parameterArray[0].Options[0].ActualColumnName);
    // this.filteredParameterArray = parameterArray;
  }
 
  getProductsDropdown() {
    var data = {
      UserId: JSON.parse(localStorage.user).UserId,
    };
    this.opmsGlassservice.loadBaseDetails(data).subscribe((res: any) => {
      this.productArray = res.ThicknessList;
      this.qualityParameterTrendDashboardForm
        .get('product')
        ?.setValue(res.ThicknessList[0].TypeId);
      this.filteredProductArray = res.ThicknessList;
    });
  }

  getChemicalLabSPCQualityChartData() {
    if (
      this.qualityParameterTrendDashboardForm.get('startDate').value != null &&
      this.qualityParameterTrendDashboardForm.get('endDate').value != null
    ) {
      let fromDate = new Date(
        new Date(this.qualityParameterTrendDashboardForm.get('startDate').value).setHours(0,0,0,0)
      );
      let toDate = new Date(
        new Date(
          this.qualityParameterTrendDashboardForm.get('endDate').value).setHours(23, 59, 59, 59)
      );
      if (fromDate && toDate) {
        this.spinner.show();
        if(this.qualityParameterTrendDashboardForm.value.qualityType == 1){
          const key = {
            fromDate: fromDate,
            toDate: toDate,
            parameterId: this.qualityParameterTrendDashboardForm.value.parameter,
            paramTypeId: this.qualityParameterTrendDashboardForm.value.paramsType
          };
          this.trendDashboardService.getChemicalLabSPCQualityChartData(key).subscribe({
            next: (res) => {
              this.spinner.hide();
              if (res) {
                this.histogramChart = res?.histogramChart;
                this.tableData = res?.tableData;
                this.controlSummary = res?.controlSummary;
                this.historainChart();
                this.areaRangeChart();
                this.controlChart();
              }
            },
            error: ({ error }) => {
              this.spinner.hide();
              this.toaster.error('Something Went Wrong!');
            },
          });
        }
        else{
          const key = {
            fromDate: fromDate,
            toDate: toDate,
            parameterId: this.qualityParameterTrendDashboardForm.value.parameter.split(',*,')[2],
            ParamTableName: this.qualityParameterTrendDashboardForm.value.parameter.split(',*,')[1],
            ParamColumnName: this.qualityParameterTrendDashboardForm.value.parameter.split(',*,')[0],
            ParamProductColumnName: this.qualityParameterTrendDashboardForm.value.parameter.split(',*,')[3],
            ParamProduct: this.qualityParameterTrendDashboardForm.value.product
          };
          this.trendDashboardService.getPhysicalLabSPCQualityChartData(key).subscribe({
            next: (res) => {
              this.spinner.hide();
              if (res) {
                this.histogramChart = res?.histogramChart;
                this.tableData = res?.tableData;
                this.controlSummary = res?.controlSummary;
                this.historainChart();
                this.areaRangeChart();
                this.controlChart();
              }
            },
            error: ({ error }) => {
              this.spinner.hide();
              this.toaster.error('Something Went Wrong!');
            },
          });
        }
      }
    }
  }

  private historainChart(): void {
    const data = [];

    const minmum =
      this.histogramChart.lowerRejectLimit != ''
        ? Number(this.histogramChart.lowerRejectLimit)
        : null;
    const maximum =
      this.histogramChart.upperRejectLimit != ''
        ? Number(this.histogramChart.upperRejectLimit)
        : null;
    // const minWarn =
    //   this.histogramChart.lowerWarningLimit != ''
    //     ? Number(this.histogramChart.lowerWarningLimit)
    //     : null;
    // const maxWarn =
    //   this.histogramChart.upperWarningLimit != ''
    //     ? Number(this.histogramChart.upperWarningLimit)
    //     : null;
    // const minSpec =
    //   this.histogramChart.lowerSpecificationLimit != ''
    //     ? Number(this.histogramChart.lowerSpecificationLimit)
    //     : null;
    // const maxSpec =
    //   this.histogramChart.upperSpecificationLimit != ''
    //     ? Number(this.histogramChart.upperSpecificationLimit)
    //     : null;
    let varMin = 0,varMax = 0
    if (
      this.histogramChart.detail != null &&
      this.histogramChart.detail.length > 0
    ) {
      this.histogramChart.detail.forEach((value) => {
        data.push(value.actualValue);
        if (Number(value.actualValue) > varMax) {
          varMax = Number(value.actualValue);
        }
        if (Number(value.actualValue) < varMin) {
          varMin = Number(value.actualValue);
        }
      });
    }
    if(varMax < maximum)
      varMax = maximum;
    if(varMin > minmum)
      varMin = minmum;
    Highcharts.chart('historianChart', {
      title: {
        text: 'Histogram',
      },
      xAxis: [
        {
          title: { text: 'Data' },
          alignTicks: false,
        },
        {
          title: { text: 'Histogram' },
          alignTicks: false,
          opposite: true,
        },
      ],

      yAxis: [
        {
          title: { text: 'Data' },
          max: varMax,
          min: varMin,
          plotLines: [
            {
              color: '#FF5252',
              width: 2,
              value: minmum,
              label: {
                text: 'LRL. ' + minmum,
                style: {
                  color: '#FF5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: 10,
                zIndex: 1,
              },
            },
            {
              color: '#FF5252',
              width: 2,
              value: maximum,
              label: {
                text: 'URL. ' + maximum,
                style: {
                  color: '#FF5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: -5,
                zIndex: 1,
              },
            },
            // {
            //   color: '#0000FF',
            //   width: 2,
            //   value: minWarn,
            //   label: {
            //     text: 'LWL. ' + minWarn,
            //     style: {
            //       color: '#0000FF',
            //       fontWeight: 'bold',
            //     },
            //     textAlign: 'left',
            //     y: 10,
            //     zIndex: 1,
            //   },
            // },
            // {
            //   color: '#0000FF',
            //   width: 2,
            //   value: maxWarn,
            //   label: {
            //     text: 'UWL. ' + maxWarn,
            //     style: {
            //       color: '#0000FF',
            //       fontWeight: 'bold',
            //     },
            //     textAlign: 'left',
            //     y: -5,
            //     zIndex: 1,
            //   },
            // },
            // {
            //   color: '#3BB143',
            //   width: 2,
            //   value: minSpec,
            //   label: {
            //     text: 'LSL. ' + minSpec,
            //     style: {
            //       color: '#3BB143',
            //       fontWeight: 'bold',
            //     },
            //     textAlign: 'left',
            //     y: 10,
            //     zIndex: 1,
            //   },
            // },
            // {
            //   color: '#3BB143',
            //   width: 2,
            //   value: maxSpec,
            //   label: {
            //     text: 'USL. ' + maxSpec,
            //     style: {
            //       color: '#3BB143',
            //       fontWeight: 'bold',
            //     },
            //     textAlign: 'left',
            //     y: -5,
            //     zIndex: 1,
            //   },
            // },
          ],
        },
        {
          title: { text: 'Histogram' },
          opposite: true,
        },
      ],
      plotOptions: {
        histogram: {
          accessibility: {
            point: {
              valueDescriptionFormat:
                '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.',
            },
          },
        },
      },
      series: [
        {
          name: 'Histogram',
          type: 'histogram',
          xAxis: 1,
          yAxis: 1,
          baseSeries: 's1',
          zIndex: -1,
        },
        {
          name: 'Data',
          type: 'scatter',
          data: data,
          id: 's1',
          marker: {
            radius: 1.5,
          },
        },
      ],
    } as any);
  }

  private areaRangeChart(): void {
    const labels: any[] = [];
    const dataArray = [];
    const minmum =
      this.histogramChart.lowerRejectLimit != ''
        ? Number(this.histogramChart.lowerRejectLimit)
        : null;
    const maximum =
      this.histogramChart.upperRejectLimit != ''
        ? Number(this.histogramChart.upperRejectLimit)
        : null;
    let varMin = minmum;
    let varMax = maximum;
    if (
      this.histogramChart.detail != null &&
      this.histogramChart.detail.length > 0
    ) {
      this.histogramChart.detail.forEach((value) => {
        dataArray.push(value.actualValue);
        labels.push(moment(value.filterDate).format('YYYY-MM-DD HH:mm'));
        if (Number(value.actualValue) > varMax) {
          varMax = Number(value.actualValue);
        }
        if (Number(value.actualValue) < varMin) {
          varMin = Number(value.actualValue);
        }
      });
    }
    const areaChart = Highcharts.chart('areaRangeChart', {
      title: {
        text: 'Area Range Chart',
      },

      subtitle: {
        text:
          'Source: ' +
          '<a href="https://www.yr.no/nb/historikk/graf/1-113585/Norge/Viken/Nesbyen/Nesbyen?q=2022-07"' +
          'target="_blank">YR</a>',
      },
      xAxis: {
        startOnTick: false,
        title: {
          text: 'Date',
        },
        labels: {
          skew3d: true,
          rotation: -10,
          formatter: function () {
            return labels[this.pos];
          },
          style: {
            fontSize: '12px',
            margin: '10px',
            padding: '10px',
          },
        },
        endOnTick: false,
      },

      yAxis: {
        title: {
          text: null,
        },
        max: varMax,
        min: varMin,
        plotLines: [
          {
            color: '#FF5252',
            width: 2,
            value: minmum,
            label: {
              text: 'LRL. ' + minmum,
              style: {
                color: '#FF5252',
                fontWeight: 'bold',
              },
              textAlign: 'left',
              y: 10,
              zIndex: 1,
            },
          },
          {
            color: '#FF5252',
            width: 2,
            value: maximum,
            label: {
              text: 'URL. ' + maximum,
              style: {
                color: '#FF5252',
                fontWeight: 'bold',
              },
              textAlign: 'left',
              y: -5,
              zIndex: 1,
            },
          },
          // {
          //   color: '#3BB143',
          //   width: 2,
          //   value: maximum,
          //   label: {
          //     text: 'USL. ' + maximum,
          //     style: {
          //       color: '#3BB143',
          //       fontWeight: 'bold',
          //     },
          //     textAlign: 'left',
          //     y: -5,
          //   },
          // },
          // {
          //   color: '#3BB143',
          //   width: 2,
          //   value: minmum,
          //   label: {
          //     text: 'LSL. ' + minmum,
          //     style: {
          //       color: '#3BB143',
          //       fontWeight: 'bold',
          //     },
          //     textAlign: 'left',
          //     y: 10,
          //   },
          // },
        ],
      },
      tooltip: {
        headerFormat: '',
        pointFormat: `<div>{series.name}: {point.y}</div>`,
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        line: {
          label: {
            enabled: false,
          },
          pointStart: 0,
          pointEnd: 30,
        },
        allowPointSelect: false,
      },
      series: [
        {
          name: 'Value',
          data: dataArray,
        },
      ],
    } as any);
  }

  private controlChart(): void {
    const category = [];
    const data = [];
    this.controlSummary.forEach((x) => {
      category.push(x.description);
      data.push(x.value);
    });
    const controlChart = Highcharts.chart('conrolChart', {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Control Summary',
      },
      subtitle: {
        text:
          'Source: ' +
          '<a href="https://www.ssb.no/en/statbank/table/08940/" ' +
          'target="_blank">SSB</a>',
      },
      xAxis: {
        categories: category,
        crosshair: false,
      },
      yAxis: {
        title: {
          useHTML: true,
          text: 'Percentage',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<td style="padding:0"><b>{point.y:.1f}%</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: '',
          data: data,
        },
      ],
    } as any);
  }

  onGridReady(params: any): any {
    params.api.sizeColumnsToFit();
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
}
