import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PeopleMasterDataService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getEmployeeMasterData(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PeopleMaster/GetEmployeeMasterInfoData', data);
  }

  getPositionMasterData(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PeopleMaster/GetPositionMasterData', data);
  }

  getEmployeePositionMappedMasterData(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PeopleMaster/GetEmployeePositionMappedData', data);
  }

  getUserList(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PeopleMaster/GetUserManagerList', data);
  }

  getUserListForUpdate(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Account/GetUserManagerList', data);
  }

  addEmployee(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PeopleMaster/SubmitEmployeeMasterInfo', data);
  }

  addPosition(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PeopleMaster/SubmitPositionMaster', data);
  }

  addEmployeePositionMapped(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PeopleMaster/SubmitEmployeePositionMap', data);
  }
  getEmployeeMasterList(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PeopleMaster/GetEmployeeInfoDD', data);
  }
  getPositionDDList(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PeopleMaster/GetPositionDD', data);
  }
  getAllDepartmentList(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'PeopleMaster/GetDepartmentDD', data);
  }
  getDepartmentData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetDepartmentByPlant', data);
  }

}