import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RawMaterialService {
baseUrl: any = environment.O3CoreApiUrl;

constructor(private http: HttpClient) { }

// Raw Material
GetAllRawMaterials(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'getallrawmaterials', data);
}

AddRawMaterial(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'addrawmaterial', data);
}

UpdateRawMaterial(data: any): Observable<any> {
  return this.http.put<any>(this.baseUrl + 'updaterawmaterials', data);
}

DeleteRawMaterial(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'deleterawmaterials', data);
}


// Raw Material Parameters

GetAllRawMaterialsParameters(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'getallrawmaterialparameters', data);
}

AddRawMaterialsParameter(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'addrawmaterialparameters', data);
}

UpdateRawMaterialsParameter(data: any): Observable<any> {
  return this.http.put<any>(this.baseUrl + 'updaterawmaterialparameters', data);
}

DeleteRawMaterialsParameter(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'deleterawmaterialparameters', data);
}

GetAllRMInspectionMasterData(): Observable<any> {
  return this.http.get<any>(this.baseUrl + 'getallrminspectionmasterdata');
}

AddRawMaterialInspectionHeader(data: any): Observable<any> {
  return this.http.post<any>(this.baseUrl + 'addrawmaterialinspectionheader', data);
}

GetAllRawMaterialsParameterUnits(): Observable<any> {
  return this.http.get<any>(this.baseUrl + 'getallrmparameterunits');
}

}
