import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProcessConfirmationService {

    baseUrl: string = environment.O3CoreApiUrl;
    o3ApiUrl: string = environment.O3CoreApiUrl;
    o3CoreApiUrl: string = environment.O3CoreApiUrl;
    constructor(private http: HttpClient) { }

    processConfirmationByArea1(data: any): Observable<any[]> {
        return this.http.post<any[]>(this.o3CoreApiUrl + 'ProcessConfirmationDb/processConfirmationByArea1', data);
    }
    processConfirmationByUsers(data: any): Observable<any[]> {
        return this.http.post<any[]>(this.o3CoreApiUrl + 'ProcessConfirmationDb/processConfirmationByUsers', data);
    }
    processConfirmationByDate(data: any): Observable<any[]> {
        return this.http.post<any[]>(this.o3CoreApiUrl + 'ProcessConfirmationDb/processConfirmationByDate', data);
    }

    GetProcessConfirmationExportData(data: any): Observable<any[]> {
        return this.http.post<any[]>(this.o3CoreApiUrl + 'ProcessConfirmationDb/GetAllProcessConfirmationExportData', data);
    }

}

