<section class="home-content" dir="{{languageDir}}">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <mat-card>
    <form [formGroup]=" RaciMatrixForm">
      <div style="padding:10px;background-color: #0F0FD1;">
        <div class="p-grid p-fluid">
          <div class="p-col-4">
            <mat-card-title style="color: white;">{{langObj ? langObj?.RACIMatrix : ('RACIMatrix' | translate)}}
            </mat-card-title>
          </div>
          <div class="p-col-4">
            <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                          'is-invalid':
                          RaciMatrixForm.get('group')?.errors &&
                            RaciMatrixForm.get('group')?.touched
                        }" [options]="groupList" formControlName="group" placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}"
              optionLabel="TypeName" [showClear]="true"></p-dropdown>
            <p class="invalid-feedback" *ngIf="
                        RaciMatrixForm.get('group')?.touched &&
                        RaciMatrixForm.get('group')?.hasError('required')
                        ">
              * {{langObj ? langObj?.pleaseselectgroup : ('pleaseselectgroup' | translate)}}
            </p>
          </div>
          <div class="p-col-4">
            <p-dropdown [ngClass]="{
                                  'is-invalid':
                                  RaciMatrixForm.get('plant')?.errors &&
                                  RaciMatrixForm.get('plant')?.touched
                                }" [options]="searchPlants" formControlName="plant" placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}"
              optionLabel="DeptDesc" [showClear]="true" [filter]="true" (onChange)="onPlantChange()"></p-dropdown>
            <p class="invalid-feedback" *ngIf="
                    RaciMatrixForm.get('plant')?.touched &&
                    RaciMatrixForm.get('plant')?.hasError('required')
                              ">
              * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
            </p>
          </div>
        </div>
      </div>

      <mat-card-content>



        <ag-grid-angular id="myGrid" style="width: 100%;" class="ag-theme-alpine" [rowData]="rowData"
          [columnDefs]="columnDefs" [loadingOverlayComponent]="loadingOverlayComponent"
          [frameworkComponents]="frameworkComponents" [enableColResize]="true" (gridReady)="onGridReady($event)"
          [pagination]="true"
          [paginationPageSize]="paginationPageSize"
          (firstDataRendered)="headerHeightSetter()" (columnResized)="headerHeightSetter()" [gridOptions]="gridOptions">
        </ag-grid-angular>



      </mat-card-content>
    </form>
  </mat-card>
</section>