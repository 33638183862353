import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatSelectFilterModule } from 'mat-select-filter';
import { AccessRoutingModule } from './access-route.module';
import { MaterialModule } from 'src/app/material-module';
import { AccessSettingsComponent } from './AccessSettings/AccessSettings.component';
import { UsermanagerComponent } from '../screens/usermanagement/usermanager/usermanager.component';
import { MachinemappingComponent } from '../screens/usermanagement/machinemapping/machinemapping.component';
import { UserroleComponent } from '../screens/usermanagement/userrole/userrole.component';
import { UserrolemappingComponent } from '../screens/usermanagement/userrolemapping/userrolemapping.component';
import { PeopleMasterDataComponent } from '../screens/usermanagement/people-master-data/people-master/people-master-data.component';
import { EmployeeMasterComponent } from '../screens/usermanagement/people-master-data/employee-master/employee-master.component';
import { PositionMasterComponent } from '../screens/usermanagement/people-master-data/position-master/position-master.component';
import { EmployeePositionMappingComponent } from '../screens/usermanagement/people-master-data/employee-position-mapping/employee-position-mapping.component';



@NgModule({
  imports: [
    CommonModule,
    AccessRoutingModule,
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MaterialModule,
    MatSelectFilterModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NgxSpinnerModule,
  ],
  declarations: [
    AccessSettingsComponent,
    UsermanagerComponent,
    MachinemappingComponent,
    UserroleComponent,
    UserrolemappingComponent,
    PeopleMasterDataComponent,
    EmployeeMasterComponent,
    PositionMasterComponent,
    EmployeePositionMappingComponent

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccessModule {}
