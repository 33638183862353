<ngx-spinner template="<img src='assets/images/spinner.gif'/>"></ngx-spinner>
<div class="order-processing-content">
    <div class="content-top-box ">
        <ul class="breadcrumb">
            <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                    (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
            </li>
        </ul>
        <div class="top-box-content">
            <h1 class="main-title">Safety Trends</h1>
        </div>
    </div>

    <form [formGroup]="safetyTrendsForm">
        <div class="content-top-row content-top-row-order-processing">
            <!-- GROUP -->
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                *ngIf="length">
                <mat-select formControlName="UserGroup1" (selectionChange)="getPlants($event)" placeholder="Group"
                    panelClass="testClass" name="Status">
                    <mat-option *ngFor="let group of GroupList" [value]="group.EG_Id">
                        {{group.EG_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                *ngIf="length">
                <mat-select formControlName="UserPlant1" placeholder="Plant" panelClass="testClass" name="plant">
                    <mat-option *ngFor="let plant of PlantList1" [value]="plant.PT_Id">
                        {{plant.PT_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="inputField">
                <nz-date-picker class="calender-input" id="startPicker" formControlName="startDate"
                    nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true">
                </nz-date-picker>
                <span class="floating-text">Start Date</span>
            </div>
            <div class="inputField">
                <nz-date-picker class="calender-input" id="endPicker" formControlName="endDate"
                    nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true">
                </nz-date-picker>
                <span class="floating-text">End Date</span>
            </div>
            <div class="btn-position btn-position-order-processing">
                <button mat-mini-fab color="accent" class="fab-button"  matTooltipClass="custom-tooltip" matTooltip="Apply Filter">
                    <i class="fa fa-search" style="color: #fff"></i>
                </button>
                <button mat-mini-fab color="accent" class="fab-button" matTooltipClass="custom-tooltip" matTooltip="History View" (click)="openPopupSafetyPyramidDetail($event,'history')">
                    <i class="fa fa-history" style="color: #fff"></i>
                </button>
                <button mat-mini-fab color="accent" class="fab-button" matTooltipClass="custom-tooltip" matTooltip="Add" (click)="openPopupSafetyPyramidDetail($event,'add')">
                    <i class="fa fa-plus" style="color: #fff"></i>
                </button>      
            </div>
        </div>
        <div class="chart-group">
            <div class="chart-items">
                <div class="chart-title">
                   
                </div>
                <div style="min-width: 600px; max-width: 700px; margin: 0 auto" [chart]="chartPyramid"></div>
            </div>
            <div class="chart-items">
                <div class="chart-title">
                </div>
                <div>
                  <tr>
                    <td>
                        <div><button class="m-top-btn" mat-raised-button >Last time Injury</button></div>
                        <div><button class="m-top-btn" mat-raised-button>Medical Treatment Case</button></div>
                        <div><button class="m-top-btn" mat-raised-button>First Aid</button></div>
                        <div><button class="m-top-btn" mat-raised-button>Fire Incident</button></div>
                        <div><button class="m-top-btn" mat-raised-button>Near Miss</button></div>
                        <div><button class="m-top-btn" mat-raised-button>Unsafe Situation[Hazard]</button></div>
                    </td>
                    <td>
                        <div>
                            <mat-card class="m-top-card" style="text-align: center; background-color: lightsalmon">
                                <mat-card-title style="color: white; font-weight: bold; font-size: 18px;">
                                    TRIR YTD
                                </mat-card-title>
                                <mat-card-content style="margin: 10px;">
                                        <span style="font-weight: bold; float: left; color: blue;">0.00</span>
                                        <span style="font-weight: bold; float: right; color: blue;">0.12</span><br>
                                    <span  style="font-weight: bold; float: left; color: white;">YTD</span>
                                    <span  style="font-weight: bold; float: right; color: white;">Target</span>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div>
                            <mat-card class="m-top-card" style="text-align: center; background-color: lightsalmon">
                                <mat-card-title style="color: white; font-weight: bold; font-size: 18px;">
                                    WORKING MAN HOURS
                                </mat-card-title>
                                <mat-card-content style="margin: 10px;">
                                        <span style="font-weight: bold; float: center; color: blue;">946,028 HOURS</span><br>
                                    <span  style="font-weight: bold; float: center; color: white;">WITHOUT RECORDABLE CASES</span>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div>
                            <mat-card class="m-top-card" style="text-align: center; background-color: lightsalmon">
                                <mat-card-title style="color: white; font-weight: bold; font-size: 18px;">
                                    THE BEST RECORDED MAN HOURS
                                </mat-card-title>
                                <mat-card-content style="margin: 10px;">
                                        <span style="font-weight: bold; float: center; color: blue;">3,033,721  HOURS</span><br>
                                    <span style="font-weight: bold; float: center; color: white;">DO YOUR PART TO HELP MAKE A NEW RECORD</span>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </td>
                  </tr>
                </div>
            </div>
           
        </div>
    </form>
</div>
<ng-template #safetyPyramidModal>
    <div>
        <div mat-dialog-title>
            <div class="d-flex justify-content-between align-items-center">
                <h2>Safety Pyramid Details</h2>
                <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
                    <i class="fa fa-times" style="color: #fff"></i>
                </button>
            </div>
        </div>
        <form [formGroup]="SafetyPyramidDetailForm" (ngSubmit)="saveSafetyDetail()" *ngIf="!history">
                    <div class="form-group" class="content-top-row content-top-row-order-processing">
                        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 30%;">
                            <mat-label>TRIR YTD</mat-label>
                            <input matInput type="text" formControlName="trirytd"/>
                        </mat-form-field>
    
                        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 30%;">
                            <mat-label>TRIR Target</mat-label>
                            <input matInput type="text" formControlName="trirtarget"/>
                        </mat-form-field>
                    </div>
                    <div class="content-top-row content-top-row-order-processing">
                        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 30%;">
                            <mat-label>Working Man Hours</mat-label>
                            <input matInput type="text" formControlName="workingmanhour"/>
                        </mat-form-field>
    
                        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 30%;">
                            <mat-label>The Best recorded man hours</mat-label>
                            <input matInput type="text" formControlName="bestrecordman"/>
                        </mat-form-field>
                    </div>
                    <div>
                        <button mat-flat-button color="accent" class="btn-accent" style="margin-left: 25px;">Save</button>
                    </div>
            <!-- History grid -->
           

        </form>
        <mat-card-content *ngIf="history">
            <ag-grid-angular style="width: 100%; height: 24vw;" class="ag-theme-alpine" [rowData]="rowData"
            [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" 
             [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
          </ag-grid-angular>
            </mat-card-content>
    </div>
  
</ng-template>
<ng-template #safetyPyramidAddFatalAccident>
    <div class="viewChecklist">
        <div mat-dialog-title>
            <div class="d-flex justify-content-between align-items-center">
                <h2>Add Fatal Accident</h2>
                <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
                    <i class="fa fa-times" style="color: #fff"></i>
                </button>
            </div>
        </div>
        <form [formGroup]="AddFatalAccidentForm">
            <div class="content-top-row content-top-row-order-processing">
              <mat-form-field  appearence="fill"  
                class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 30%;">
                <input matInput formControlName="department" placeholder="Department">
              </mat-form-field>
              <div class="inputField">
                <nz-date-picker class="calender-input" id="startPicker" formControlName="eventDate"  nzFormat="dd-MMM-yyyy HH:mm:ss" style="width: 100%;"
               [nzShowTime]="{ nzFormat: 'HH:mm:ss' }" required></nz-date-picker>
                <span class="floating-text">Event Date</span>
              </div>
            </div>
            <div class="content-top-row content-top-row-order-processing">
                <mat-form-field appearence="fill" 
                  class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 100%; height: fit-content;">
                  <textarea matInput formControlName="description" rows="5" placeholder="Description"></textarea>
                </mat-form-field>
              </div>
            <div>
                <button mat-flat-button color="accent" class="btn-accent" style="float: right; margin-top: 50px;">Add</button>
            </div>
          </form>
    </div>
</ng-template>