import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MeetingDefinationService } from '../../services/meeting-defination.service';
import { Frequency } from 'src/app/models/frequency';
import { Kpi } from 'src/app/models/kpi';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { MeetingmasterService } from 'src/app/services/meetingmaster.service';
import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';

import { takeUntil } from 'rxjs/operators';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslaterService } from 'src/app/services/translater.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { parseInt } from 'lodash-es';
import { MONTHS, WEEKDAYS, WEEKSCOUNT } from 'src/app/@constants/constants';
import { MeetingMatrixService } from 'src/app/services/meeting-matrix.service';

export class Survey {
  constructor(
    public ID: number,
    public Type: string,
    public Title: string,
    public IsDeleted: boolean,
    public IsAnonymous: boolean,
    // tslint:disable-next-line:no-shadowed-variable
    public Question: Question[]
  ) { }
}

export class Question {
  constructor(
    public ID: number,
    public Type: string,
    public Text: string,
    public options: Option[],
    public Required: boolean,
    public Remarks: string,
    public hasRemarks: boolean
  ) { }
}
export interface AddQuestion {
  MeetingQuesId: number;
  MeetingId: number;
  Area: string;
  Question: string;
  LineId: number;
}

export class Option {
  constructor(
    public ID: number,
    public OptionText: string,
    public OptionColor: string,
    public hasRemarks: boolean
  ) { }
}

@Component({
  selector: 'app-meeting-defination',
  templateUrl: './meeting-defination.component.html',
  styleUrls: ['./meeting-defination.component.scss'],
})
export class MeetingDefinationComponent implements OnInit {
  private Injetor: Injector
  @ViewChild('displayAgendaModal') private displayAgendaModal!: any;
  @ViewChild('KpiAndParticepants') private KpiAndParticepants!: any;
  @ViewChild('questionModal') private questionModal!: any;
  @ViewChild('displayGroupModal') private displayGroupModal!: TemplateRef<any>;
  @ViewChild('KPIOrderListModal') private KPIOrderListModal!: TemplateRef<any>;
  @ViewChild('displayRescheduleModal')
  private displayRescheduleModal!: TemplateRef<any>;

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  @ViewChild(MatPaginator) paginator2: any;
  @ViewChild(MatSort) sort2: any;

  // @ViewChild(MatPaginator) paginator3: any;
  @ViewChild(MatSort) sort3: any;

  @ViewChild(MatPaginator, { static: true }) paginator3: any;

  @ViewChildren('inputRef') inputAgendRefs!: QueryList<ElementRef>;
  MeetingDefForm!: FormGroup;

  AddAgendaForm!: FormGroup;
  agendaFormArray!: FormArray;
  meetingColumns: string[] = [
    'actions',
    'id',
    'meetingname',
    'startTime',
    'endTime',
    'leadername',
    'frequency',
    // 'kpiName',
    'meetingMasterName',
    'level',
  ];
  QuestionColumns = ['id', 'Meeting', 'Area', 'Line', 'Question', 'Delete'];
  agendaColumns: string[] = ['actions', 'agendaName'];
  groups!: any;
  name!: any;
  meetingId!: any;
  particpant!: any;
  today = new Date();
  plants!: any;
  filteredPlants!: any;
  FormValid = false;
  MeetingDefFormMode = true;
  groupListDDL!: any[];
  filteredGroupListDDL!: any[];
  frequencies!: Frequency[];
  filteredFrequencies!: Frequency[];
  lineList!: any[];
  departments!: any[];
  pillars!: any[];
  plantId!: number;
  breadcrumList: any;
  Kpisfilter!: any;
  ParticipantSelectedList!: any;
  departmenthide = false;
  pillarhide = false;
  planthide = false;
  UserDDL: any;
  Participants: any;
  MeetingList!: any[];
  MeetingQusList: any[] = [];
  MeetingQusID: number;
  MeetingData!: MatTableDataSource<any>;
  agendaData!: MatTableDataSource<any>;
  // QuestionData!: MatTableDataSource<any>;
  QuestionData: any;
  UsersList: any;
  filteredUsers: any;
  filteredParticipants: any;
  MeetingEvaluations!: any;
  filteredMeetingEvaluations!: any;
  meetingDefId!: number;
  FullName!: any;
  LeaderName!: any;
  toastrService: any;
  meetingAgendaId: any;
  selectedParticepent!: any[];
  GroupAdded: string[] = [];
  IsAccess: any;
  IsWrite: any;
  selectedOption = [];
  index!: number;
  DataSave = [];
  QuestionTitle: string;
  KPIOrderList: any = [];
  KPIOrderColumns: any = [
    { field: 'KpiName', headerName: 'KPI Name', resizable: true, width: 300 },
    {
      field: 'frequency',
      headerName: 'Frequency',
      resizable: true,
      width: 150,
    },
    {
      field: 'kpiOrder',
      headerName: 'KPI Order',
      editable: true,
      resizable: true,
    },
  ];
  bankMultiCtrl: FormControl = new FormControl();
  public bankMultiFilterCtrl: FormControl = new FormControl();
  public kpiSearchCtrl: FormControl = new FormControl();
  public filteredBanksMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredKpisMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('multiSelect', { static: true }) multiSelect:
    | MatSelect
    | undefined;

  // tslint:disable-next-line:variable-name
  protected _onDestroy = new Subject<void>();
  kpisArray!: Kpi[];
  LeaderId!: any;
  previousSelectedLeader!: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  Index: number = 0;
  questionForm: FormGroup;
  AddGroupForm!: FormGroup;
  surveyForm: FormGroup;
  MeetingQuestionArray!: FormArray;
  meetingQuestion!: AddQuestion[];
  meetingQuestionItem!: any[];
  duplicateArray: any[] = [];
  rescheduleMeetingObj: any;
  rescheduleForm!: FormGroup;
  isLoading = false;
  endAfter: boolean = true;
  endBy: boolean = true;
  dailypattern: boolean = false;
  weeklypattern: boolean = false;
  monthlypattern: boolean = false;
  yearlypattern: boolean = false;
  nofdays: boolean = true;
  monthsselect: boolean = true;
  monthsday: boolean = true;
  weekscount = WEEKSCOUNT;
  months = MONTHS;
  weekdays: any;
  inputAdd: boolean = true;
  select: boolean = true;
  KPIOrderGridApi: any;
  KPIOrderGridColumnApi: any;
  CheckedArr: any[] = [];
  Agendas: any;
  selectedGroup: string;
  Question: string;
  constructor(
    private meetingMatrixService: MeetingMatrixService,
    private fb: FormBuilder,
    private meetingService: MeetingDefinationService,
    private toaster: ToastrService,
    private machineMappingService: MachineMappingService,
    private kpimaster: KpimasterService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private dialog: MatDialog,
    private platModelService: EnterPriseGroupService,
    private meetingMasterService: MeetingmasterService,
    private kpiservice: KpitreeService,
    private spinner: NgxSpinnerService,
    public translater: TranslaterService,
    private productivityservice: ProductivityService,

  ) {
  }

  ngOnInit(): void {

    this.getGroupData();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.MeetingDefForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      meetingDefId: [0],
      meeting: ['', Validators.required],
      frequency: ['', Validators.required],
      level: ['', Validators.required],
      department: [''],
      pillar: [''],
      kpi: [''],
      leader: ['', Validators.required],
      meetingEvaluation: ['', Validators.required],
      participants: [''],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
    });

    this.MeetingDefForm.get('level')?.setValue('Plant');
    this.AddAgendaForm = this.fb.group({
      addagendas: this.fb.array([this.createAgendaForm()]),
    });
    this.agendaFormArray = this.AddAgendaForm.get('addagendas') as FormArray;
    this.filteredBanksMulti.next(this.filteredParticipants);
    this.filterBanksMulti();
    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
      });
    this.kpiSearchCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterKpi();
      });

    this.questionForm = this.fb.group({
      // MeetingId: ['', Validators.required],
      // Area: ['', Validators.required],
      // Question: ['', Validators.required],
      // LineId: ['', Validators.required],
      GRD: this.fb.array([this.createMeetingQuestionArray()]),
    });
    this.MeetingQuestionArray = this.questionForm.get('GRD') as FormArray;

    this.AddGroupForm = this.fb.group({
      dropname: ['', Validators.required],
    });
    this.rescheduleForm = this.fb.group({
      meetingName: [''],
      meetingSubject: [''],
      meetingParticipants: [''],
      rescheduleStartTime: ['', Validators.required],
      rescheduleEndTime: ['', Validators.required],
      duration: ['', Validators.required],
      RecurrneceType: ['', Validators.required],
      RecurEveryWeekDay: [''],
      RecurIntervalDayDaily: [''],
      RecurIntervalWeekly: [''],
      RecurDaysOfWeekWeekly: this.fb.array([WEEKDAYS]),
      RecurringInDaysOfMonthly: [''],
      RecurDayOfMonthMonthly: [''],
      RecurIntervalOfMonthMontly: [''],
      RecurWeekOfMonthMontly: [''],
      RecurIntervalOfMonthMontlyRelative: [''],
      RecurIntervalofYearsYearly: [''],
      RecurAbsoluteYearly: [''],
      RecurMonthYearly: [''],
      RecurDayOfMonthYearly: [''],
      RecurWeekOfMonthYearly: [''],
      RecurDaysOfWeekYearly: [''],
      RecurringMonthYearly: [''],
      recurrencOccurrences: [''],
      recurrencEndDateStatus: ['', Validators.required],
      recurrenctStartDate: ['', Validators.required],
      recurrencEndDate: [''],
      RecurDaysOfWeekMonthly: [''],

      message: [''],
    });

    this.initForm();
    this.weekdays = WEEKDAYS;
  }

  ngAfterViewInit() {
    // this.rescheduleForm.valueChanges.subscribe((res) => {
    //   console.log('range Change', res);
    //   if (res.rescheduleStartTime && res.rescheduleEndTime) {
    //     // this.getGridDataByFrequency();
    //     var startTime = new Date(this.rescheduleForm.value.rescheduleStartTime);
    //     var endTime = new Date(this.rescheduleForm.value.rescheduleEndTime);
    //     var Time = endTime.getTime() - startTime.getTime();
    //     //var Days = Time / (1000 * 3600 * 24);
    //     var mint = Time / 1000 / 60;
    //     this.rescheduleForm.get('duration').setValue(mint.toFixed(0));
    //   }
    // });
  }
  // cost dictionary operations starts
  get repeateMeetingQuestionForm(): any {
    return this.questionForm.get('GRD') as FormArray;
  }

  get realTimeDuration() {
    var startTime = new Date(this.rescheduleForm.value.rescheduleStartTime);
    var endTime = new Date(this.rescheduleForm.value.rescheduleEndTime);

    var Time = endTime.getTime() - startTime.getTime();
    //var Days = Time / (1000 * 3600 * 24);
    var mint = Time / 1000 / 60;
    // this.rescheduleForm.get('duration').setValue();
    this.rescheduleForm.patchValue({
      duration: mint.toFixed(0),
    });
    return mint.toFixed(0);
  }
  createMeetingQuestionArray(): FormGroup {
    return this.fb.group({
      MeetingQuesId: [0],
      MeetingId: [this.MeetingQusID, Validators.required],
      Area: ['', Validators.required],
      Question: ['', Validators.required],
      LineId: [0, Validators.required],
      IsDeleted: [false],
    });
  }

  SaveSchedule() {
    debugger;
    var WeekDaysString = this.CheckedArr.toString();
    console.log(WeekDaysString);
    const key = {
      MeetingId: this.meetingId,
      RecurrneceType: this.rescheduleForm.value.RecurrneceType,
      RecurEveryWeekDay: this.rescheduleForm.value.RecurEveryWeekDay,
      RecurIntervalDayDaily: this.rescheduleForm.value.RecurIntervalDayDaily,
      RecurIntervalWeekly: this.rescheduleForm.value.RecurIntervalWeekly,
      RecurDaysOfWeekWeekly: WeekDaysString,
      RecurringInDaysOfMonthly:
        this.rescheduleForm.value.RecurringInDaysOfMonthly,
      RecurDayOfMonthMonthly: this.rescheduleForm.value.RecurDayOfMonthMonthly,
      RecurIntervalOfMonthMontly:
        this.rescheduleForm.value.RecurIntervalOfMonthMontly,
      RecurWeekOfMonthMontly: this.rescheduleForm.value.RecurWeekOfMonthMontly,
      RecurIntervalOfMonthMontlyRelative:
        this.rescheduleForm.value.RecurIntervalOfMonthMontlyRelative,
      RecurIntervalofYearsYearly:
        this.rescheduleForm.value.RecurIntervalofYearsYearly,
      RecurAbsoluteYearly: this.rescheduleForm.value.RecurAbsoluteYearly,
      RecurMonthYearly: this.rescheduleForm.value.RecurMonthYearly,
      RecurDayOfMonthYearly: this.rescheduleForm.value.RecurDayOfMonthYearly,
      RecurWeekOfMonthYearly: this.rescheduleForm.value.RecurWeekOfMonthYearly,
      RecurDaysOfWeekYearly: this.rescheduleForm.value.RecurDaysOfWeekYearly,
      RecurringWeekDay: this.rescheduleForm.value.RecurringWeekDay,
      recurrencOccurrences: this.rescheduleForm.value.recurrencOccurrences,
      recurrencEndDateStatus: this.rescheduleForm.value.recurrencEndDateStatus,
      recurrenctStartDate: this.rescheduleForm.value.recurrenctStartDate,
      recurrencEndDate: this.rescheduleForm.value.recurrencEndDate,
      RecurDaysOfWeekMonthly: this.rescheduleForm.value.RecurDaysOfWeekMonthly,
      RecurringMonthYearly: this.rescheduleForm.value.RecurringMonthYearly,
      rescheduleStartTime: new Date(
        this.rescheduleForm.value.rescheduleStartTime
      ),
      rescheduleEndTime: new Date(this.rescheduleForm.value.rescheduleEndTime),
      duration: this.rescheduleForm.value.duration,
    };

    this.meetingService.SaveMeetingReschedule(key).subscribe(
      (data) => {
        this.toaster.success('Saved Successfully');
        this.dialog.closeAll();
        // this.QuestionData = new MatTableDataSource<any>(data);
        // this.getPaginator(this.QuestionData, 1);
      },
      (error: any) => {
        this.toastrService.error('Error');
      }
    );
  }
  getActualIndex(index: number): any {
    this.Index = index;
    return index;
  }
  public getLangWords(): any {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      const obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  private initForm(): void {
    const surveyTitle = '';
    const surveyType = '';
    const surveyQuestions = new FormArray([]);

    this.surveyForm = new FormGroup({
      surveyTitle: new FormControl(surveyTitle, [Validators.required]),
      surveyType: new FormControl(surveyType, [Validators.required]),
      surveyQuestions,
      IsAnonymous: new FormControl(false, [Validators.required]),
    });

    //  this.onAddQuestion();
  }

  addNewRow(): any {
    var Compvalue = (
      (this.questionForm.get('GRD') as FormArray).at(this.Index) as FormGroup
    ).get('Area');

    if (Compvalue.value === '') {
      this.toaster.error('Error', 'Please Fill the Empty Row First');
    } else {
      this.MeetingQuestionArray.push(this.createMeetingQuestionArray());
      this.QuestionData.data = this.MeetingQuestionArray.controls;
      // this.QuestionData = new MatTableDataSource(this.QuestionData.data);
      // this.getPaginator(this.QuestionData, 1);
    }
  }

  SaveGroup(): void {
    const key = this.AddGroupForm.get('dropname')?.value;

    if (this.GroupAdded.indexOf(key) === -1) {
      this.GroupAdded.push(key);
      this.AddGroupForm.reset();
      this.toaster.success('Success', 'Group added successfully');
    } else {
      this.toaster.error('Error', 'Group already Exist');
    }
  }

  onCostElementChange(event: any, index: number): any {
    this.index = index;
    const meetingQues = this.duplicateArray.find(
      (x: any) => x.MeetingId === event.value
    );
    if (meetingQues) {
      this.toaster.warning('Duplicate Entry is not allowed');
      return;
    }
    this.GetMettingQuestionId(index);
  }

  GetMettingQuestionId(index: number): void {
    const key = {
      meetingId: this.questionForm.value.GRD[index].MeetingId,
    };
  }
  removeRepeatEntry(index: any): void {
    const Data = (
      (this.questionForm.get('GRD') as FormArray).at(index) as FormGroup
    ).get('MeetingQuesId');
    const MeetingQuesId = parseInt(Data.value, 10);

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Question!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        // Remove from Formarray and Mat Table
        this.MeetingQuestionArray.removeAt(index);
        this.QuestionData.data = this.MeetingQuestionArray.controls;
        if (this.MeetingQuestionArray.length === 0) {
          this.dialog.closeAll();
        }
        // Remove from DB
        if (MeetingQuesId > 0) {
          const key = {
            MeetingQuesId, // Delete Record
            MeetingId: this.questionForm.value.GRD.meetingId, // Get Updated Record
          };
          this.meetingService.DeleteMeetingQuestion(key).subscribe(
            (data) => {
              Swal.fire(
                'Deleted!',
                'Your selected Meeting Question has been deleted.',
                'success'
              );
              // this.QuestionData = new MatTableDataSource<any>(data);
              // this.getPaginator(this.QuestionData, 1);
            },
            (error: any) => {
              this.toastrService.error('Error');
            }
          );
        }
      }
    });
  }

  saveMeetingQuestion(): void {
    const count = 0;
    const meetingQuestion = this.questionForm.value.GRD;
    const saveMeetingQuestion = meetingQuestion.filter(
      (x: any) => x.MeetingId !== ''
    );
    if (saveMeetingQuestion.length > 0) {
      this.DataSave = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.questionForm.value.GRD.length; i++) {
        const SaveRecord = {
          MeetingQuesId: this.questionForm.value.GRD[i].MeetingQuesId,
          MeetingId: this.questionForm.value.GRD[i].MeetingId,
          Area: this.questionForm.value.GRD[i].Area,
          Question: this.questionForm.value.GRD[i].Question,
          LineId: this.questionForm.value.GRD[i].LineId,
          isDeleted: false,
        };
        this.DataSave.push(SaveRecord);
      }

      this.meetingService.AddMeetingQuestion(this.DataSave).subscribe(
        (data) => {
          this.toaster.success('Success', 'Record Saved successfully');
          this.dialog.closeAll();
        },
        (error: any) => {
          this.toaster.error('Error', 'Record not Saved successfully');
        }
      );
    }
  }

  resetquestionForm(): void {
    this.questionForm.reset();
  }

  CloseQuestion(): void {
    this.MeetingQuestionArray.clear();
    this.MeetingQuestionArray.push(this.createMeetingQuestionArray());
  }
  onChangedPageSize(Index: any): void {
    this.QuestionData = new MatTableDataSource(
      this.MeetingQuestionArray.controls
    );
    this.QuestionData.paginator = this.paginator3;
    this.QuestionData.sort = this.sort3;
  }

  ViewQuestion(): void {
    this.spinner.show();
    this.QuestionTitle = 'Meeting Questions';
    this.DataSave = [];
    this.MeetingQuestionArray.clear();
    this.GroupAdded = [];

    const key = {
      MeetingQuesId: 0,
      MeetingId: this.MeetingQusID,
      LineId: this.questionForm.value.GRD.LineId,
    };

    this.meetingService.GetMeetingQuestion(key).subscribe(
      (data) => {
        this.DataSave = data;

        if (
          typeof this.DataSave !== 'undefined' &&
          this.DataSave.length !== 0
        ) {
          let Area;
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.DataSave.length; i++) {
            Area = this.DataSave[i].Area; // Fill GroupAdded Array

            const row = this.fb.group({
              MeetingQuesId: this.DataSave[i].MeetingQuesId,
              MeetingId: this.DataSave[i].MeetingId,
              Area: this.DataSave[i].Area, // For show Area
              Question: this.DataSave[i].Question,
              LineId: this.DataSave[i].LineId,
              isDeleted: this.DataSave[i].isDeleted,
            });
            this.MeetingQuestionArray.push(row);
            this.QuestionData = new MatTableDataSource(
              this.MeetingQuestionArray.controls
            );
            if (this.GroupAdded.indexOf(Area) === -1) {
              this.GroupAdded.push(Area);
            }
            this.spinner.hide();
          }
          // console.log(this.DataSave)
          // this.QuestionData.paginator = this.paginator3;
          // this.QuestionData.sort = this.sort3
          //  this.getPaginator(this.QuestionData, 1);
        } else {
          this.MeetingQuestionArray.push(this.createMeetingQuestionArray());
          this.QuestionData = new MatTableDataSource(
            this.MeetingQuestionArray.controls
          );
          // this.QuestionData.paginator = this.paginator3;
          // this.QuestionData.sort = this.sort3
          //  this.getPaginator(this.QuestionData, 1);
          this.spinner.hide();
        }
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  onAddQuestion(): void {
    const row = this.fb.group({
      MeetingId: this.questionForm.value.MeetingId,
      Area: this.questionForm.value.Area,
      Question: this.questionForm.value.Question,
      LineId: this.questionForm.value.LineId,
    });

    // this.questionFormArray.push(row);
    // console.log(this.questionFormArray.value)

    //   Get GetMeetingQuestion
    //

    // const surveyQuestionItem = new FormGroup({
    //   questionTitle: new FormControl('', Validators.required),
    //   questionType: new FormControl('', Validators.required),
    //   questionGroup: new FormGroup({}),
    // });

    // (<FormArray>this.surveyForm.get('surveyQuestions')).push(
    //   surveyQuestionItem
    // );
  }

  onRemoveQuestion(index): void {
    this.surveyForm.controls.surveyQuestions['controls'][
      index
    ].controls.questionGroup = new FormGroup({});
    this.surveyForm.controls.surveyQuestions['controls'][
      index
    ].controls.questionType = new FormControl({});

    (this.surveyForm.get('surveyQuestions') as FormArray).removeAt(index);
    this.selectedOption.splice(index, 1);
  }

  onSeletQuestionType(questionType, index): void {
    if (questionType === 'Single choice' || questionType === 'Multi choice') {
      this.addOptionControls(questionType, index);
    }
  }

  addOptionControls(questionType, index): void {
    const options = new FormArray([]);
    const showRemarksBox = new FormControl(false);

    this.surveyForm.controls.surveyQuestions['controls'][
      index
    ].controls.questionGroup.addControl('options', options);
    this.surveyForm.controls.surveyQuestions['controls'][
      index
    ].controls.questionGroup.addControl('showRemarksBox', showRemarksBox);

    this.clearFormArray(
      this.surveyForm.controls.surveyQuestions['controls'][index].controls
        .questionGroup.controls.options as FormArray
    );

    this.addOption(index);
    this.addOption(index);
  }

  private clearFormArray(formArray: FormArray): void {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  addOption(index): void {
    const optionGroup = new FormGroup({
      optionText: new FormControl('', Validators.required),
    });
    (
      this.surveyForm.controls.surveyQuestions['controls'][index].controls
        .questionGroup.controls.options as FormArray
    ).push(optionGroup);
  }

  removeOption(questionIndex, itemIndex): void {
    (
      this.surveyForm.controls.surveyQuestions['controls'][questionIndex]
        .controls.questionGroup.controls.options as FormArray
    ).removeAt(itemIndex);
  }

  postSurvey(): void {
    const formData = this.surveyForm.value;

    const ID = 0;
    const Type = formData.surveyType;
    const Title = formData.surveyTitle;
    const IsDeleted = false;
    const IsAnonymous = formData.IsAnonymous;
    //  let Question: Question[] = [];
    const Questions = [];

    const surveyQuestions = formData.surveyQuestions;
    const optionArray =
      formData.surveyQuestions[0].questionGroup.options[0].optionText;
    const survey = new Survey(
      ID,
      Type,
      Title,
      IsDeleted,
      IsAnonymous,
      Questions
    );

    surveyQuestions.forEach((question, index, array) => {
      const questionItem = {
        ID: 0,
        Type: question.questionType,
        Text: question.questionTitle,
        options: [],
        Required: false,
        Remarks: '',
        hasRemarks: false,
      };
      if (question.questionGroup.hasOwnProperty('showRemarksBox')) {
        questionItem.hasRemarks = question.questionGroup.showRemarksBox;
      }

      if (question.questionGroup.hasOwnProperty('options')) {
        question.questionGroup.options.forEach((option) => {
          const optionItem: Option = {
            ID: 0,
            OptionText: option.optionText,
            OptionColor: '',
            hasRemarks: false,
          };
          questionItem.options.push(optionItem);
        });
      }

      survey.Question.push(questionItem);
    });
  }

  onSubmit(): void {
    this.postSurvey();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        // localStorage.setItem('GetMenuAccess', this.permisson);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  get repeatAgendaForm(): any {
    return this.AddAgendaForm.get('addagendas') as FormArray;
  }

  createAgendaForm(): FormGroup {
    return this.fb.group({
      agenda: ['', Validators.required],
    });
  }

  addNewAgendaRow(event: any, index: number): void {
    const arrayagendas = this.AddAgendaForm.get('addagendas')?.value.filter(
      (x: any) => x.agenda === ''
    );
    if (arrayagendas?.length === 0) {
      this.agendaFormArray.push(this.createAgendaForm());
      setTimeout(() => {
        const lastinput: HTMLInputElement = this.inputAgendRefs.last
          .nativeElement.children[0].children[0].children[0].children[0]
          .children[0] as HTMLInputElement;
        lastinput.focus();
      }, 300);
    } else {
      this.toaster.warning('Please Fill Or Remove Empty Row First');
    }
  }

  deleteMeetibgAgenda(elem: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete agenda!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          meetingAgendaId: elem.MeetingAgendaId,
        };
        this.meetingService.deleteAgenda(key).subscribe(
          (res: any) => {
            Swal.fire(
              'Deleted!',
              'Your selected Meeting agenda has been deleted.',
              'success'
            );
            this.getmeetingAgenda();
          },
          (error: any) => {
            this.toastrService.error('Error');
          }
        );
      }
    });
  }
  removeAgendaRow(event: any, index: number): void {
    const found = this.AddAgendaForm.get('addagendas')?.value[index];
    if (found?.agenda !== '') {
      if (this.agendaFormArray.length > 1) {
        this.agendaFormArray.removeAt(index);
      } else if (this.agendaFormArray.length === 1) {
        this.AddAgendaForm.reset();
      }
    }
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.meetingService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.filteredGroupListDDL = this.groupListDDL.slice();
        this.MeetingDefForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
        this.getUserList();
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.MeetingDefForm.get('group')?.value,
    };
    this.meetingService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList.filter((p: any) => p.DeptDesc);
        this.filteredPlants = this.plants.slice();
        this.MeetingDefForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.MeetingDefForm.get('startTime')?.setValue(new Date());
        this.MeetingDefForm.get('endTime')?.setValue(new Date());
        this.MeetingEvaluations = [];
        this.getUserList();
        this.getMeetingMaster();
        this.getFrequencies();
        this.getlines();
        // this.getKpiByFrequency();
        this.getDepartment();
        this.getPillar();
        this.getMeetingList();
        this.getKpiByPlant();
        // this.getMeetingMaster();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // getFrequencies(): void {
  //   const key = {
  //     plantId: this.MeetingDefForm.value.plant,
  //   };
  //   this.meetingService.getMasterFrequencies(key).subscribe(
  //     (frequencies) => {
  //       this.frequencies = frequencies.filter(
  //         (x) =>
  //           x.name === 'Yearly' ||
  //           x.name === 'Monthly' ||
  //           x.name === 'Weekly' ||
  //           x.name === 'Daily'
  //       );
  //       this.MeetingDefForm.get('frequency')?.setValue('');
  //       this.getKpiByFrequency();
  //       this.getMeetingList();
  //       this.MeetingEvaluations = [];
  //       this.getMeetingMaster();
  //       this.getUserList();
  //     },
  //     (error) => {
  //       this.toaster.error('Error', error.error.message);
  //     }
  //   );
  //   this.getDepartment();
  //   this.getPillar();
  // }

  getFrequencies(): void {
    this.meetingService.getFrequency().subscribe(
      (frequency) => {
        this.frequencies = frequency;
        this.filteredFrequencies = this.frequencies.slice();
      },
      (error) => {
        this.toaster.error('Error', error?.message);
      }
    );
  }

  getlines(): void {
    const key = {
      // PT_ID: this.MeetingDefForm.value.UserPlant1,
      PT_ID: 1,
      userId: JSON.parse(localStorage.user).UserId,
    };

    this.productivityservice.getLineData(key).subscribe(
      (data: any) => {
        this.lineList = data.lstLine;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  setAutoParticepent(): void {
    this.LeaderId = [this.MeetingDefForm.value.leader];
    if (!this.MeetingDefForm.value.participants) {
      this.MeetingDefForm.get('participants')?.setValue(this.LeaderId);
      this.previousSelectedLeader = this.MeetingDefForm.value.leader;
    } else {
      const arr = this.MeetingDefForm.value.participants;
      const index = arr.indexOf(this.previousSelectedLeader);
      if (index > -1) {
        // array.splice(index, 1); // 2nd parameter means remove one item only
        arr.splice(index, 1);
      }
      const newItem = this.MeetingDefForm.value.leader;
      arr.indexOf(newItem) === -1
        ? arr.push(newItem)
        : '';
      // this.MeetingDefForm.value.participants.push(this.MeetingDefForm.value.leader);
      this.previousSelectedLeader = newItem;
      this.MeetingDefForm.get('participants')?.setValue(arr);
    }
  }

  remainLeader(value: any, id: any): any {
    if (id === this.LeaderId) {
      this.bankMultiCtrl.setValue(this.LeaderId);
    }
  }

  getDepartment(): void {
    const key = {
      ptId: this.MeetingDefForm.value.plant,
    };
    this.machineMappingService.getDepartmenBytPlant(key).subscribe(
      (response: any) => {
        this.departments = response;
        this.MeetingDefForm.get('department')?.setValue(
          this.departments[0].TypeId
        );
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getPillar(): void {
    const key = {
      plantId: this.MeetingDefForm.value.plant,
    };

    this.meetingService.getMasterPillars(key).subscribe(
      (response) => {
        this.pillars = response;
        this.MeetingDefForm.get('pillar')?.setValue(this.pillars[0].pillarId);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  onChangeLevel({ value }: any): void {
    if (value === 'Department') {
      this.getDepartment();
      this.MeetingDefForm.get('level')?.setValue('Department');
      this.departmenthide = true;
      this.pillarhide = false;
    } else if (value === 'Pillar') {
      this.getPillar();
      this.MeetingDefForm.get('level')?.setValue('Pillar');
      this.pillarhide = true;
      this.departmenthide = false;
    } else {
      this.pillarhide = false;
      this.departmenthide = false;
    }
  }

  filterBanksMulti(): any {
    if (!this.filteredParticipants) {
      return;
    }
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.filteredBanksMulti.next(this.filteredParticipants);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanksMulti.next(
      this.filteredParticipants.filter(
        (bank: any) => bank.empFullName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  filterKpi(): void {
    if (!this.Kpisfilter) {
      return;
    }
    let search = this.kpiSearchCtrl.value;
    if (!search) {
      this.filteredKpisMulti.next(this.Kpisfilter);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredKpisMulti.next(
      this.Kpisfilter.filter(
        (value: any) => value.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  getUserList(): void {
    const key = {
      EGId: this.MeetingDefForm.get('group')?.value,
      PlantId: this.MeetingDefForm.get('plant')?.value,
    };
    this.meetingService.getParticipantsOnPlant(key).subscribe(
      (users: any) => {
        this.UserDDL = users;
        this.UserDDL.forEach((elem: any) => {
          elem.empFullName = elem.empFullName;
          elem.empFullName2 = elem.empFullName.toLowerCase();
        });

        // this.filteredUsers = this.UserDDL.slice();
        // this.filteredParticipants = this.UserDDL.slice();
        // this.filterBanksMulti();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  onFrequencyChange(event: any): any {
    this.getKpiByFrequency();
  }

  getKpiByPlant(): any {
    this.getUserList();
    this.resetForm();
    const key = {
      ptId: this.MeetingDefForm.value.plant,
    };
    this.kpiservice.getKpisByPlant(key).subscribe(
      (kpis) => {
        this.Kpisfilter = kpis.slice();
        this.kpisArray = kpis;
        this.filteredKpisMulti.next(this.Kpisfilter);
        this.filterKpi();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
    this.getMeetingMaster();
    this.getMeetingList();

  }

  getKpiByFrequency(): void {
    const key = {
      plantId: this.MeetingDefForm.value.plant,
      frequencyId: this.MeetingDefForm.value.frequency,
    };
    this.kpimaster.getKpiFrequency(key).subscribe(
      (kpis) => {
        this.Kpisfilter = kpis.slice();
        this.kpisArray = kpis;
        // console.log(kpis, 'response');
        this.filteredKpisMulti.next(this.Kpisfilter);
        this.filterKpi();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  addAgendaModal(param: any): void {
    this.meetingDefId = param?.meetingDefId;
    this.dialog.open(this.displayAgendaModal, { height: '80%', width: '40%' });
    this.getmeetingAgenda();
  }

  openViewModal(param: any): void {
    this.dialog.open(this.KpiAndParticepants, { height: '80%', width: '80%' });
    this.name = [];

    this.name = param.kpiName?.split(',');
    this.FullName = [];
    this.FullName = JSON.parse(param.MeetingParticpant);
    this.LeaderName = [];
    this.LeaderName = param.Name?.split();
  }
  addGroupModal(): void {
    this.dialog.open(this.displayGroupModal, { height: '31%', width: '40%' });
    this.AddGroupForm.reset();
  }

  openQuestionModel(data: any): void {
    this.MeetingQusList = [];

    const key = {
      meetingDefId: data.meetingDefId,
      meetingName: data.meetingName,
    };
    if (this.MeetingQusList.indexOf(key) === -1) {
      this.MeetingQusList.push(key);
      this.MeetingQusID = data.meetingDefId;
    }
    this.selectedGroup = "";
    this.Question = "";
    this.dialog.open(this.questionModal, { height: 'auto', width: '80%' });
    this.ViewQuestion();
  }

  saveMeetingAgenda(): void {
    const agendas = {
      meetingDefId: this.meetingDefId,
      agendas: this.AddAgendaForm.value.addagendas?.filter(
        (x: any) => x.agenda !== ''
      ),
    };

    this.meetingService.AddMeetingAgenda(agendas).subscribe(
      (data) => {
        this.toaster.success('Success', 'Meeting Agenda Added Successfully');
        this.agendaFormArray.clear();
        this.agendaFormArray.push(this.createAgendaForm());
        this.dialog.closeAll();
        this.getMeetingList();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) < 0;

  getMeetingMaster(): void {
    this.MeetingEvaluations = [];
    const key = {
      groupId: parseInt(this.MeetingDefForm.value.group, 10),
      plantId: parseInt(this.MeetingDefForm.value.plant, 10),
    };
    this.meetingMasterService.GetAllMeetingMaster(key).subscribe(
      (data) => {
        this.MeetingEvaluations = data;
        this.filteredMeetingEvaluations = this.MeetingEvaluations.slice();
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );

    // console.log(this.MeetingEvaluations)
  }

  getmeetingAgenda(): void {
    const key = {
      meetingDefId: this.meetingDefId,
    };
    this.meetingService.GetAllMeetingAgenda(key).subscribe(
      (data) => {
        this.agendaData = new MatTableDataSource<any>(data);
        this.getPaginator(this.agendaData, 1);
      },

      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }

  getMeetingList(): void {
    this.MeetingData = new MatTableDataSource<any>([]);
    this.spinner.show();
    const key = {
      plantId: parseInt(this.MeetingDefForm.value.plant, 10),
    };
    this.meetingService.GetAllMeetingDefination(key).subscribe(
      (data) => {
        this.MeetingData = new MatTableDataSource<any>(data);
        this.MeetingList = data;

        this.getPaginator(this.MeetingData, 0);
        this.spinner.hide();
      },
      ({ error }) => {
        this.toaster.error(error.message);
        this.spinner.hide();
      }
    );
  }
  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
      data.sort = this.sort.toArray()[index];
    }, 1000);
  }

  saveMeetingDef(): void {
    const value = this.MeetingDefForm.value.meetingDefId;
    if (value === 0) {
      this.saveMeetingDefination();
      this.MeetingDefFormMode = true;
    } else {
      this.updateMeetingDef();
    }
  }

  editMeetingDef(elem: any): void {
    const key = {
      meetingDefId: elem.meetingDefId,
    };
    this.meetingService.GetMeetingDefById(key).subscribe(
      (Response) => {
        this.MeetingDefForm.get('meetingDefId')?.setValue(elem.meetingDefId);
        this.MeetingDefForm.get('plant')?.setValue(Response?.plantId);
        this.MeetingDefForm.get('meeting')?.setValue(elem.meetingName);
        this.MeetingDefForm.get('frequency')?.setValue(Response?.frequencyId);
        this.MeetingDefForm.get('meetingEvaluation')?.setValue(
          Response?.meetingMasterId
        );
        this.MeetingDefForm.get('participants')?.setValue(
          Response?.participants?.map((particpant: any) => {
            return particpant.participantId;
          })
        );

        debugger;
        const kpikey = {
          ptId: this.MeetingDefForm.value.plant,
        };
        this.kpiservice.getKpisByPlant(kpikey).subscribe(
          (kpis) => {
            debugger;
            this.Kpisfilter = kpis.slice();
            this.kpisArray = kpis;
            this.filteredKpisMulti.next(this.Kpisfilter);
            // if (Response.kpiName) {
            //   this.MeetingDefForm.get('kpi')?.setValue(
            //     Response?.kpiName
            //       ?.split(',')
            //       ?.map((name: any) => parseInt(name, 10))
            //   );
            // }
            this.GetMeetingDefinitionSelectedKpi(elem.meetingDefId);
            this.MeetingDefFormMode = false;
          },
          (error) => {
            debugger;
            this.toaster.error('Error', error.error.message);
          }
        );
        this.MeetingDefForm.get('department')?.setValue(Response?.departmentId);
        this.MeetingDefForm.get('pillar')?.setValue(elem.pillarId);
        this.MeetingDefForm.get('leader')?.setValue(Response?.userId);
        this.previousSelectedLeader = Response?.userId;
        this.MeetingDefForm.get('startTime')?.setValue(
          new Date(Response?.startTime)
        );
        this.MeetingDefForm.get('endTime')?.setValue(
          new Date(Response?.endTime)
        );
        if (Response?.levelName === 'Department') {
          this.MeetingDefForm.get('level')?.setValue(Response?.levelName);
          this.departmenthide = true;
          this.pillarhide = false;
        } else if (Response?.levelName === 'Pillar') {
          this.MeetingDefForm.get('level')?.setValue(Response?.levelName);
          this.pillarhide = true;
          this.departmenthide = false;
        } else {
          this.MeetingDefForm.get('level')?.setValue(Response?.levelName);
          this.pillarhide = false;
          this.departmenthide = false;
        }
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  updateMeetingDef(): void {
    if (
      moment(this.MeetingDefForm.value.startTime).format(
        'YYYY-MM-DD HH:mm:ss'
      ) <=
      moment(this.MeetingDefForm.value.endTime).format('YYYY-MM-DD HH:mm:ss')
    ) {
      const MeetDef = {
        meetingDefId: this.MeetingDefForm.value.meetingDefId,
        plantId: this.MeetingDefForm.value.plant,
        name: this.MeetingDefForm.value.meeting,
        frequencyId: this.MeetingDefForm.value.frequency,
        kpiName: this.MeetingDefForm.value.kpi?.toString(),
        level: this.MeetingDefForm.value.level,
        departmentId: this.MeetingDefForm.value.department,
        pillarId: this.MeetingDefForm.value.pillar,
        leaderId: this.MeetingDefForm.value.leader,
        meetingId: parseInt(this.MeetingDefForm.value.meetingEvaluation, 10),
        participants: this.MeetingDefForm.value.participants?.map(
          (id: number) => {
            return {
              participantId: id,
            };
          }
        ),
        startTime: this.MeetingDefForm.value.startTime,
        endTime: this.MeetingDefForm.value.endTime,
      };
      this.meetingService.UpdateMeetingDef(MeetDef).subscribe(
        (response) => {
          this.toaster.success('Meeting Defination Updated Successfully');
          this.getMeetingList();
          this.resetForm();
          this.MeetingDefFormMode = true;
          this.MeetingDefForm.get('meetingDefId')?.setValue(0);
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      this.toaster.error('End date should not be lower than start date');
      this.MeetingDefForm.get('endTime')?.setValue('');
    }
  }

  deleteMeetingDef(elem: any): void {
    this.MeetingDefFormMode = true;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete defination!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const id = {
          meetingDefId: elem.meetingDefId,
        };

        this.meetingService.DeleteMeetingDef(id).subscribe(
          (response: any) => {
            this.toaster.success(response[0].data);
            this.getMeetingList();
          },
          (error) => {
            if (error.error.text === 'deleted') {
              this.toaster.success(error.error.text);
              this.getMeetingList();
            } else {

              this.toaster.success(error.error.text);

            }
          }
        );
      } else {
        Swal.fire('Cancelled', 'Your selected meeting is safe :)');
      }
    });
  }

  inActiveMeeting(param: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to InActive Meeting!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, InActive !',
      cancelButtonText: 'No',
    }).then(
      (result) => {
        if (result.value) {
          const key = {
            meetingDefId: param.meetingDefId,
          };
          this.meetingService.inActiveMeeting(key).subscribe((res) => {
            this.getMeetingList();
          });
        } else {
          Swal.fire('Cancelled', 'Your selected meeting is safe :)');
          this.getMeetingList();
        }
      },
      (dismiss: any) => {
        if (dismiss === 'cancel') {
          // function when cancel button is clicked
          this.getMeetingList();
        }
      }
    );
  }

  onCheckboxChange(checkBoxStatus: any, param: any): void {
    if (checkBoxStatus.checked) {
      this.activeMeeting(param);
    } else {
      this.inActiveMeeting(param);
    }
  }
  activeMeeting(param: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Active Meeting!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Active !',
      cancelButtonText: 'No',
    }).then(
      (result) => {
        if (result.value) {
          const key = {
            meetingDefId: param.meetingDefId,
          };
          this.meetingService.ActiveMeeting(key).subscribe((res) => {
            this.getMeetingList();
          });
        } else {
          Swal.fire('Cancelled', 'Your selected meeting is safe :)');
          this.getMeetingList();
        }
      },
      (dismiss: any) => {
        if (dismiss === 'cancel') {
          // function when cancel button is clicked
          this.getMeetingList();
        }
      }
    );
  }

  saveMeetingDefination(): void {
    if (
      moment(this.MeetingDefForm.value.startTime).format(
        'YYYY-MM-DD HH:mm:ss'
      ) <=
      moment(this.MeetingDefForm.value.endTime).format('YYYY-MM-DD HH:mm:ss')
    ) {
      this.MeetingDefForm.get('endTime')?.setValue(
        moment(this.MeetingDefForm.value.endTime).format('YYYY-MM-DD HH:mm:ss')
      );
      const MeetDef = {
        plantId: this.MeetingDefForm.value.plant,
        name: this.MeetingDefForm.value.meeting,
        frequencyId: this.MeetingDefForm.value.frequency,
        kpiName: this.MeetingDefForm.value.kpi?.toString(),
        level: this.MeetingDefForm.value.level,
        departmentId: this.MeetingDefForm.value.department,
        pillarId: this.MeetingDefForm.value.pillar,
        leaderId: this.MeetingDefForm.value.leader,
        meetingId: parseInt(this.MeetingDefForm.value.meetingEvaluation, 10),

        participants: this.MeetingDefForm.value.participants?.map(
          (id: number) => {
            return {
              participantId: id,
            };
          }
        ),
        startTime: this.MeetingDefForm.value.startTime,
        endTime: this.MeetingDefForm.value.endTime,
      };

      this.meetingService.AddMeetingDef(MeetDef).subscribe(
        (response) => {
          this.toaster.success(
            'Success',
            'Meeting Defination Added Successfully'
          );
          this.resetForm();
          this.getMeetingList();

          this.MeetingDefForm.get('meetingDefId')?.setValue(0);
        },
        (error) => {
          this.toaster.warning('Already Defined', error.error.message);
        }
      );
    } else {
      this.toaster.error('End date should not be lower than start date');
      this.MeetingDefForm.get('endTime')?.setValue('');
    }
  }

  resetForm(): void {
    this.MeetingDefForm.get('startTime')?.setValue(new Date());
    this.MeetingDefForm.get('endTime')?.setValue(new Date());
    this.MeetingDefForm.get('meeting')?.reset();
    this.MeetingDefForm.get('frequency')?.setValue('');
    this.MeetingDefForm.get('kpi')?.reset();
    this.MeetingDefForm.get('meetingEvaluation')?.setValue('');
    this.MeetingDefForm.get('leader')?.setValue('');
    this.MeetingDefForm.get('participants')?.setValue('');
    this.MeetingDefForm.get('level')?.setValue('Plant');
    this.pillarhide = false;
    this.departmenthide = false;
    this.bankMultiCtrl.setValue('');
    this.MeetingDefForm.get('meetingDefId')?.setValue('');
    this.MeetingDefForm.get('meetingDefId')?.setValue(0);
    this.MeetingDefFormMode = true;
    // this.MeetingDefFormMode = true;
    // this.MeetingDefForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
    // this.MeetingDefForm.get('plant')?.setValue(this.plants[0].DeptId);
  }

  applyFilter(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.MeetingData.filter = filterValue.trim().toLowerCase();
  }

  closePopUpModal(): void {
    this.dialog.closeAll();
  }

  // reschedule meeting section
  rescheduleMeeting(elem: any): void {
    debugger;

    this.meetingId = elem.meetingDefId;
    const participants = elem ? JSON.parse(elem.MeetingParticpant) : [];
    const meetParts = filterParticipantsArray(this.UserDDL, participants);
    const Agenda = elem.MeetingAgenda ? JSON.parse(elem.MeetingAgenda) : [];
    this.CheckedArr = [];
    this.weekdays.forEach((element) => {
      element.Checked = false;
    });
    // (formArray: FormArray) => {
    //   while (formArray.length !== 0) {
    //     formArray.removeAt(0)
    //   }
    // }
    //     var  MultiDataPoints =[];

    //       meetParts.forEach((item: any) => {
    //          MultiDataPoints.push(item.KPIDbAreaDatapointId)
    //       });

    // // this.DataPoints = res;
    this.ParticipantSelectedList = participants;

    this.Agendas = Agenda;

    this.rescheduleForm.reset();
    this.rescheduleForm.get('meetingParticipants')?.setValue([...meetParts]);
    this.rescheduleMeetingObj = elem;
    this.rescheduleForm.get('meetingName')?.setValue(elem?.meetingName);

    const key = {
      plantId: elem.plantId,
      meetingId: elem.meetingDefId,
    };
    this.meetingMatrixService.GetMeetingRecurrence(key).subscribe((res) => {
      if (res != null && res.length > 0) {
        this.rescheduleForm
          .get('rescheduleStartTime')
          ?.setValue(
            moment(new Date()).format('YYYY-MM-DD') +
            ' ' +
            res[0].StartTime +
            ' UTC'
          );
        this.rescheduleForm
          .get('rescheduleEndTime')
          ?.setValue(
            moment(new Date()).format('YYYY-MM-DD') +
            ' ' +
            res[0].EndTime +
            ' UTC'
          );
        this.rescheduleForm.get('duration')?.setValue(res[0].Duration);
        this.rescheduleForm
          .get('recurrenctStartDate')
          ?.setValue(res[0].StartDate);
        this.rescheduleForm
          .get('recurrencEndDateStatus')
          ?.setValue(res[0].Type);
        this.rescheduleForm
          .get('recurrencOccurrences')
          ?.setValue(res[0].NoOfOccurences);
        this.rescheduleForm.get('recurrencEndDate')?.setValue(res[0].EndDate);

        if (res[0].TypeId == 1) {
          const event = {
            value: 'Daily',
          };
          this.changePattern(event);
          this.rescheduleForm.get('RecurrneceType')?.setValue('Daily');

          if (res[0].Interval == 1) {
            this.rescheduleForm.get('RecurEveryWeekDay')?.setValue('true');
            const changeDays = {
              value: 'false',
            };
            this.changedaysNumber(changeDays);
          } else {
            this.rescheduleForm.get('RecurEveryWeekDay')?.setValue('false');
            this.rescheduleForm
              .get('RecurIntervalDayDaily')
              ?.setValue(res[0].Interval);
            const changeDays = {
              value: 'Noofdays',
            };
            this.changedaysNumber(changeDays);
          }
        }
        if (res[0].TypeId == 2) {
          const event = {
            value: 'Weekly',
          };
          this.changePattern(event);
          this.rescheduleForm.get('RecurrneceType')?.setValue('Weekly');
          this.rescheduleForm
            .get('RecurIntervalWeekly')
            ?.setValue(res[0].Interval);

          var ArrayOfDays = res[0].DaysOfWeek.split(',');
          this.CheckedArr = [];
          ArrayOfDays.forEach((element) => {
            this.CheckedArr.push(element);
          });

          this.weekdays.forEach((element) => {
            ArrayOfDays.forEach((el1) => {
              if (element.name == el1) {
                element.Checked = true;
              }
            });

          });
        }
        if (res[0].TypeId == 3) {
          const event = {
            value: 'Monthly',
          };
          this.changePattern(event);

          this.rescheduleForm.get('RecurrneceType')?.setValue('Monthly');

          this.rescheduleForm
            .get('RecurDayOfMonthMonthly')
            ?.setValue(res[0].DayOfMonth);
          this.rescheduleForm.get('RecurringInDaysOfMonthly')?.setValue('true');
          this.rescheduleForm
            .get('RecurIntervalOfMonthMontly')
            ?.setValue(res[0].Interval);

          const events = {
            value: 'true',
          };
          this.changeMonthlyNumbers(events);
        }
        if (res[0].TypeId == 4) {
          const event = {
            value: 'Monthly',
          };
          this.changePattern(event);
          this.rescheduleForm.get('RecurrneceType')?.setValue('Monthly');
          this.rescheduleForm
            .get('RecurringInDaysOfMonthly')
            ?.setValue('false');
          this.rescheduleForm
            .get('RecurWeekOfMonthMontly')
            ?.setValue(Number(res[0].WeekOfMonth));
          this.rescheduleForm
            .get('RecurDaysOfWeekMonthly')
            ?.setValue(res[0].DaysOfWeek);
          this.rescheduleForm
            .get('RecurIntervalOfMonthMontlyRelative')
            ?.setValue(res[0].Interval);

          const events = {
            value: 'false',
          };
          this.changeMonthlyNumbers(events);
        }
        if (res[0].TypeId == 5) {
          const event = {
            value: 'Yearly',
          };
          this.changePattern(event);
          this.rescheduleForm.get('RecurrneceType')?.setValue('Yearly');
          this.rescheduleForm.get('RecurAbsoluteYearly')?.setValue('true');
          this.rescheduleForm
            .get('RecurIntervalofYearsYearly')
            ?.setValue(res[0].Interval);

          this.rescheduleForm.get('RecurMonthYearly')?.setValue(res[0].Month);
          this.rescheduleForm
            .get('RecurDayOfMonthYearly')
            ?.setValue(res[0].DayOfMonth);

          const changeyear = {
            value: 'true',
          };
          this.changeYearlyNumbers(changeyear);
        }
        if (res[0].TypeId == 6) {
          const event = {
            value: 'Yearly',
          };
          this.changePattern(event);
          this.rescheduleForm.get('RecurrneceType')?.setValue('Yearly');
          this.rescheduleForm.get('RecurAbsoluteYearly')?.setValue('false');
          this.rescheduleForm
            .get('RecurIntervalofYearsYearly')
            ?.setValue(res[0].Interval);
          this.rescheduleForm
            .get('RecurWeekOfMonthYearly')
            ?.setValue(Number(res[0].WeekOfMonth));
          this.rescheduleForm
            .get('RecurDaysOfWeekYearly')
            ?.setValue(res[0].DaysOfWeek);
          this.rescheduleForm
            .get('RecurringMonthYearly')
            ?.setValue(res[0].Month);

          const changeyear = {
            value: 'false',
          };
          this.changeYearlyNumbers(changeyear);
        }

        const event = {
          value: res[0].Type,
        };

        this.changerange(event);
      }
    });

    this.dialog.open(this.displayRescheduleModal, {
      height: '100%',
      width: '90%',
      disableClose: false,
      panelClass: 'full-screen-modal-Md',
    });
  }

  getParticipantsForm(control): any {
    return this.rescheduleForm.get(control)?.value;
  }
  onParticipantsRemoved(user: any): void {
    const parts = this.rescheduleForm.value.meetingParticipants;
    this.removeFirst(parts, user);
    this.rescheduleForm.get('meetingParticipants').setValue(parts);
  }
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
  changerange(event: any): void {
    if (event.value === 'endafter') {
      this.endAfter = false;
      this.endBy = true;
    } else if (event.value === 'endby') {
      this.endAfter = true;
      this.endBy = false;
    } else {
      this.endAfter = true;
      this.endBy = true;
    }
    this.ValidatingField();
  }
  changePattern(event: any): void {
    debugger;
    if (event.value == 'Daily') {
      this.dailypattern = true;
      this.weeklypattern = false;
      this.monthlypattern = false;
      this.yearlypattern = false;
    } else if (event.value == 'Weekly') {
      this.dailypattern = false;
      this.weeklypattern = true;
      this.monthlypattern = false;
      this.yearlypattern = false;
    } else if (event.value == 'Monthly') {
      this.dailypattern = false;
      this.weeklypattern = false;
      this.monthlypattern = true;
      this.yearlypattern = false;
    } else if (event.value == 'Yearly') {
      this.dailypattern = false;
      this.weeklypattern = false;
      this.monthlypattern = false;
      this.yearlypattern = true;
    }

    this.ValidatingField();
  }
  changedaysNumber(event: any): void {
    if (event.value === 'Noofdays') {
      this.nofdays = false;
    } else {
      this.nofdays = true;
    }
    this.ValidatingField();
  }
  changeMonthlyNumbers(event: any): void {
    if (event.value === 'true') {
      this.monthsday = false;
      this.monthsselect = true;
    } else if (event.value === 'false') {
      this.monthsselect = false;
      this.monthsday = true;
    }
    this.ValidatingField();
  }
  changeYearlyNumbers(event: any): void {
    if (event.value === 'true') {
      this.select = true;
      this.inputAdd = false;
    } else if (event.value === 'false') {
      this.select = false;
      this.inputAdd = true;
    }
    this.ValidatingField();
  }
  // menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        // console.log(data.BreadcrumList);
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetMeetingDefinitionSelectedKpi(element): any {
    const key = {
      meetingDefId: element,
    };

    debugger;
    this.meetingService.GetMeetingDefinitionSelectedKpi(key).subscribe(
      (data: any) => {
        debugger;
        this.MeetingDefForm.get('kpi')?.setValue(
          data.dataArray?.split(',')?.map((name: any) => (name))
        );
      },
      (error) => {
        debugger;
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  openKPIOrderList(meeting: any): void {
    const meetingDefId = meeting?.meetingDefId;
    this.meetingService.GetMeetingKPIOrder({ meetingDefId }).subscribe(
      (response: any) => {
        this.KPIOrderList = response;
        const dialog = this.dialog.open(this.KPIOrderListModal, {
          height: '80%',
          width: '70%',
        });
      },
      (err: any) => {
        this.toastrService.error(err.ErrorMessage);
      }
    );
  }
  onChange(val: any): void {

    const index: number = this.CheckedArr.indexOf(val);
    if (index !== -1) {
      this.CheckedArr.splice(index, 1);
    } else {
      this.CheckedArr.push(val);
    }
    this.ValidatingField();
  }
  onKPIOrderGridReady(params: any) {
    this.KPIOrderGridApi = params.api;
    this.KPIOrderGridColumnApi = params.columnApi;
  }
  onKPIOrderCellValueChanged(event: any) {
    debugger;
    const data = event.data;
    const col = event.colDef.field;
    const cellValue = data?.kpiOrder;
    if (col === 'kpiOrder') {
      const key = {
        kpiorder: cellValue,
        kpiOrderId: data?.kpiOrderId,
      };

      const errors = [null, '', undefined];
      if (errors.includes(cellValue)) {
        this.toaster.error('Please enter a valid number');
        return;
      }
      if (isNaN(cellValue)) {
        this.toaster.error('Please enter a valid number');
        return;
      }
      this.meetingService
        .UpdateMeetingKPIOrder(key)
        .subscribe((response: any) => {
          this.toaster.success('KPI Order Updated Successfully');
        });
    }
  }

  ValidatingField() {
    debugger;
    if (this.rescheduleForm.value.RecurrneceType == 'Daily') {
      this.rescheduleForm.controls['RecurEveryWeekDay'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'RecurEveryWeekDay'
      ].updateValueAndValidity();

      ////Weekly Validators clearing
      this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekWeekly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalWeekly'
      ].updateValueAndValidity();

      ///Monthly Validators clearing
      this.rescheduleForm.controls['RecurDayOfMonthMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthMontly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].updateValueAndValidity();

      //Yearly Validators clearing
      this.rescheduleForm.controls['RecurAbsoluteYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurAbsoluteYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurMonthYearly'].clearValidators();
      this.rescheduleForm.controls['RecurMonthYearly'].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDayOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurringMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurringMonthYearly'
      ].updateValueAndValidity();

      if (this.rescheduleForm.value.RecurEveryWeekDay == 'false') {
        this.rescheduleForm.controls['RecurIntervalDayDaily'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurIntervalDayDaily'
        ].updateValueAndValidity();
      } else {
        this.rescheduleForm.controls['RecurIntervalDayDaily'].clearValidators();
        this.rescheduleForm.controls[
          'RecurIntervalDayDaily'
        ].updateValueAndValidity();
      }
    }
    if (this.rescheduleForm.value.RecurrneceType == 'Weekly') {
      //Clearing Validations Of Daily///
      this.rescheduleForm.controls['RecurEveryWeekDay'].clearValidators();
      this.rescheduleForm.controls[
        'RecurEveryWeekDay'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalDayDaily'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalDayDaily'
      ].updateValueAndValidity();
      ///////////////////
      ///Monthly Validators clearing
      this.rescheduleForm.controls['RecurDayOfMonthMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthMontly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].updateValueAndValidity();

      //Yearly Validators clearing
      this.rescheduleForm.controls['RecurAbsoluteYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurAbsoluteYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurMonthYearly'].clearValidators();
      this.rescheduleForm.controls['RecurMonthYearly'].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDayOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurringMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurringMonthYearly'
      ].updateValueAndValidity();

      this.rescheduleForm.controls['RecurIntervalWeekly'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'RecurIntervalWeekly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'RecurDaysOfWeekWeekly'
      ].updateValueAndValidity();
      debugger;
      if (this.CheckedArr.length <= 0) {
        this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].setErrors({
          incorrect: true,
        });
      } else {
        this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].setErrors(null);
      }
    }
    if (this.rescheduleForm.value.RecurrneceType == 'Monthly') {
      //Clearing Validations Of Daily///
      this.rescheduleForm.controls['RecurEveryWeekDay'].clearValidators();
      this.rescheduleForm.controls[
        'RecurEveryWeekDay'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalDayDaily'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalDayDaily'
      ].updateValueAndValidity();
      ///////////////////
      ////Weekly Validators clearing
      this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekWeekly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalWeekly'
      ].updateValueAndValidity();

      //Yearly Validators clearing
      this.rescheduleForm.controls['RecurAbsoluteYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurAbsoluteYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurMonthYearly'].clearValidators();
      this.rescheduleForm.controls['RecurMonthYearly'].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDayOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurringMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurringMonthYearly'
      ].updateValueAndValidity();

      this.rescheduleForm.controls['RecurringInDaysOfMonthly'].setValidators([
        Validators.required,
      ]);

      if (this.rescheduleForm.value.RecurringInDaysOfMonthly == 'true') {
        this.rescheduleForm.controls['RecurDayOfMonthMonthly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurDayOfMonthMonthly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontly'
        ].setValidators([Validators.required]);
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontly'
        ].updateValueAndValidity();

        this.rescheduleForm.controls[
          'RecurWeekOfMonthMontly'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurWeekOfMonthMontly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls[
          'RecurDaysOfWeekMonthly'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurDaysOfWeekMonthly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontlyRelative'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontlyRelative'
        ].updateValueAndValidity();
      } else {
        this.rescheduleForm.controls[
          'RecurDayOfMonthMonthly'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurDayOfMonthMonthly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontly'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontly'
        ].updateValueAndValidity();

        this.rescheduleForm.controls['RecurWeekOfMonthMontly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurWeekOfMonthMontly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurDaysOfWeekMonthly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurDaysOfWeekMonthly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontlyRelative'
        ].setValidators([Validators.required]);
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontlyRelative'
        ].updateValueAndValidity();
      }
    }
    if (this.rescheduleForm.value.RecurrneceType == 'Yearly') {
      //Clearing Validations Of Daily///
      this.rescheduleForm.controls['RecurEveryWeekDay'].clearValidators();
      this.rescheduleForm.controls[
        'RecurEveryWeekDay'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalDayDaily'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalDayDaily'
      ].updateValueAndValidity();
      ///////////////////
      ////Weekly Validators clearing
      this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekWeekly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalWeekly'
      ].updateValueAndValidity();

      ///Monthly Validators clearing
      this.rescheduleForm.controls['RecurDayOfMonthMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthMontly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].updateValueAndValidity();

      this.rescheduleForm.controls['RecurAbsoluteYearly'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'RecurAbsoluteYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalofYearsYearly'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].updateValueAndValidity();

      if (this.rescheduleForm.value.RecurAbsoluteYearly == 'true') {
        this.rescheduleForm.controls[
          'RecurWeekOfMonthYearly'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurWeekOfMonthYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurDaysOfWeekYearly'].clearValidators();
        this.rescheduleForm.controls[
          'RecurDaysOfWeekYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurringMonthYearly'].clearValidators();
        this.rescheduleForm.controls[
          'RecurringMonthYearly'
        ].updateValueAndValidity();

        this.rescheduleForm.controls['RecurMonthYearly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurMonthYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurDayOfMonthYearly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurDayOfMonthYearly'
        ].updateValueAndValidity();
      } else {
        this.rescheduleForm.controls['RecurMonthYearly'].clearValidators();
        this.rescheduleForm.controls[
          'RecurMonthYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurDayOfMonthYearly'].clearValidators();
        this.rescheduleForm.controls[
          'RecurDayOfMonthYearly'
        ].updateValueAndValidity();

        this.rescheduleForm.controls['RecurWeekOfMonthYearly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurWeekOfMonthYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurDaysOfWeekYearly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurDaysOfWeekYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurringMonthYearly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurringMonthYearly'
        ].updateValueAndValidity();
      }
    }

    if (this.rescheduleForm.value.recurrencEndDateStatus == 'endby') {
      this.rescheduleForm.controls['recurrencEndDate'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls['recurrencEndDate'].updateValueAndValidity();
      this.rescheduleForm.controls['recurrencOccurrences'].clearValidators();
      this.rescheduleForm.controls[
        'recurrencOccurrences'
      ].updateValueAndValidity();
    } else if (this.rescheduleForm.value.recurrencEndDateStatus == 'endafter') {
      this.rescheduleForm.controls['recurrencEndDate'].clearValidators();
      this.rescheduleForm.controls['recurrencEndDate'].updateValueAndValidity();

      this.rescheduleForm.controls['recurrencOccurrences'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'recurrencOccurrences'
      ].updateValueAndValidity();
    } else if (
      this.rescheduleForm.value.recurrencEndDateStatus == 'noenddate'
    ) {
      this.rescheduleForm.controls['recurrencEndDate'].clearValidators();
      this.rescheduleForm.controls['recurrencEndDate'].updateValueAndValidity();
      this.rescheduleForm.controls['recurrencOccurrences'].clearValidators();
      this.rescheduleForm.controls[
        'recurrencOccurrences'
      ].updateValueAndValidity();
    }
  }
  minAdded(min = 1) {
    debugger;
    const validator: ValidatorFn = (formArray: FormArray) => {
      const minAdded = formArray.controls.map(
        (control) => control.value.RecurDaysOfWeekWeekly
      ).length;
      return minAdded >= min ? null : { required: true };
    };
    return validator;
  }
}

const filterParticipantsArray = (arr1: any[], arr2: any[]) => {
  const filtered: any[] = arr1.filter((el: any) => {
    return arr2.find((el2: any) => {
      return el2?.MeetingParticipantId === el?.EmpInfo_Id;
    });
  });
  return filtered;
};
