<div dir="{{languageDir}}">
<div class="content-top-box">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <div class="tab-content-top-box">
    <h1 class="main-title">{{langObj ? langObj?.KPIDataPoints : ('KPIDataPoints' | translate)}}</h1>
  </div>
</div>
<mat-card class="p-mt-0 p-pt-0" style="padding-top: 5px !important;">

  <div class="p-d-flex p-jc-between p-ac-center p-ai-center"
    style="background-color: #0F0FD1;margin: 0px;height: 70px;border-radius: 5px;">
    <div class="">
      <button mat-mini-fab mat-button mat-dialog-close color="accent" (click)="openDataPointModal()" pTooltip="Add"
        *ngIf="IsWrite" tooltipPosition="top" style="margin-left: 10px;">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" *ngIf="disableDlt" pTooltip="Delete"
        (click)="deleteMultiPoints($event)" tooltipPosition="top" style="margin-left: 10px;">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-5 p-xl-5" style="display: flex; justify-content: flex-end;">

      <form [formGroup]="searchMasterForm" style="float: right;margin-top: 15px;">
        <mat-form-field appearance="fill" class="field-fill-custom-master" style="margin-right: 10px;
        ">
          <mat-label class="required">{{langObj ? langObj?.Group : ('Group' | translate)}}</mat-label>
          <mat-select panelClass="testClass" name="group" formControlName="group" (selectionChange)="getPlantData()">
            <mat-option *ngFor="let group of groupList" [value]="group.TypeId">{{group.TypeName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="field-fill-custom-master">
          <mat-label class="required">{{langObj ? langObj?.Plant : ('Plant' | translate)}}</mat-label>
          <mat-select panelClass="testClass" name="plant" formControlName="plant"
            (selectionChange)="GetAllDataPoints()">
            <mat-option *ngFor="let plant of plantList" [value]="plant.DeptId">{{plant.DeptDesc}}</mat-option>
          </mat-select>
        </mat-form-field>

      </form>
    </div>
  </div>
  <mat-card-content>
    <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
      <p style="font-size: 20px; color: #050a4f;">{{langObj ? langObj?.FetchingToolsData : ('FetchingToolsData' | translate)}}</p>
    </ngx-spinner>
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
      <ag-grid-angular #agGrid id="myGrid" class="ag-theme-alpine" [rowData]="rowData" [columnDefs]="columnDefs"
        [suppressRowClickSelection]="true" [rowSelection]="rowSelection" [pagination]="true"
        (cellClicked)='oncellclicked($event)' [gridOptions]="gridOptions" [rowMultiSelectWithClick]="true"
        (rowSelected)="onRowSelected($event)" (selectionChanged)="onSelectionChanged($event)"
        (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="10">
      </ag-grid-angular>
    </div>
  </mat-card-content>
</mat-card>


<!--Side Panel for KPI Data Points-->
<div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{'active': dataPointsModelVisible}">
  <div class="sidepanel-close" (click)="closeToolModel()"></div>
  <div class="model-content">
    <div class="model-top">
      <h2 class="model-title"> {{ formMode ? 'Add' : 'Update' }} {{langObj ? langObj?.KPIDataPoints : ('KPIDataPoints' | translate)}}</h2>
      <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeToolModel()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
            fill="black" />
        </svg>
      </button>
    </div>
    <div class="model-body">
      <form [formGroup]="DataPointsForm">
        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill inputs" style="margin-top: 20px;">
            <mat-label>{{langObj ? langObj?.SelectDataSource : ('SelectDataSource' | translate)}}</mat-label>
            <mat-select name="dataSource" formControlName="dataSource" (selectionChange)="onChangeDataSource($event)"
              required>
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'dsName'" [array]="datasourceList"
                (filteredReturn)="filteredDataSource = $event"></mat-select-filter>
              <mat-option *ngFor="let datasource of filteredDataSource" [value]="datasource.dsId">
                {{datasource.dsName}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="field-fill inputs" style="margin-top: 20px;">
            <mat-label>{{langObj ? langObj?.SelectAreainO3 : ('SelectAreainO3' | translate)}}</mat-label>
            <mat-select (selectionChange)="getDirectQueryList()" name="area" formControlName="area2" required>
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'areaName'" [array]="areaList"
                (filteredReturn)="filteredareas = $event"></mat-select-filter>
              <mat-option *ngFor="let area of filteredareas" [value]="area.areaId">{{area.areaName}}</mat-option>
            </mat-select>
          </mat-form-field>



          <mat-radio-group formControlName="status" (change)="changeStatus($event)" [(ngModel)]="selectedStatus"
            style="padding-bottom: 20px;">
            <mat-radio-button value="1">Plant</mat-radio-button>
            <mat-radio-button value="3">Line</mat-radio-button>  <!-- Line is Added Later That's why it is adding on no 3 -->
            <mat-radio-button value="2">Machine</mat-radio-button>
          </mat-radio-group>
          <br />
          <div *ngIf="showSingle">
            <div>
              <label class="floating-label">{{langObj ? langObj?.DataPointLocation : ('DataPointLocation' | translate)}}</label>
              <p-dropdown inputId="dropdown" [filter]="true" formControlName="machine" [options]="unitList"
                optionLabel="puDesc"></p-dropdown>
            </div>
          </div>
          <div *ngIf="showMulti">
            <div>
              <label class="floating-label">{{langObj ? langObj?.DataPointLocation : ('DataPointLocation' | translate)}}</label>
              <p-multiSelect inputId="multiselect" formControlName="machine" [options]="unitList" optionLabel="puDesc"
                [filter]="true">
              </p-multiSelect>

            </div>
          </div>

          <mat-form-field appearance="fill" class="field-fill inputs" style="margin-top: 20px;" *ngIf="!dataPointMode">
            <mat-label>{{langObj ? langObj?.SelectDataPoints : ('SelectDataPoints' | translate)}}</mat-label>
            <mat-select name="directQuery" formControlName="directQuery" required>
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'Area'" [array]="DirectQueryList"
                (filteredReturn)="filteredDQ = $event"></mat-select-filter>
              <mat-option *ngFor="let dq of filteredDQ" [value]="dq.DirectQueryId">{{dq.Area}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="field-fill inputs" style="margin-top: 20px;" *ngIf="dataPointMode">
            <mat-label>{{langObj ? langObj?.SelectDataPoints : ('SelectDataPoints' | translate)}}</mat-label>
            <mat-select id="submit" formControlName="directQuery" #selectAll multiple (selectionChange)="optionClick()">
              <!-- name="directQuery" formControlName="directQuery" required -->
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'Area'" [array]="DirectQueryList"
                (filteredReturn)="filteredDQ = $event"></mat-select-filter>
              <!-- (click)="selectAllItems()" -->
              <div class="select-all selectAllItems">
                <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                  (change)="toggleAllSelection()">Select All</mat-checkbox>
              </div>
              <!-- <mat-option  value="0">Select All</mat-option>  (click)="optionClick($event)"-->
              <mat-option *ngFor="let dq of filteredDQ" [value]="dq.DirectQueryId">
                {{dq.Area}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="field-fill" style="margin-top: 20px;">
            <mat-label>{{langObj ? langObj?.dataPointDesc : ('dataPointDesc' | translate)}}</mat-label>
            <input matInput placeholder="{{langObj ? langObj?.EnterDataPointDesc : ('EnterDataPointDesc' | translate)}}" formControlName="dataPoints" [disabled]="dataPointDesc"
              [required]="true">
          </mat-form-field>


          <div class="model-form-actions">
            <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close" matTooltip="Update"
              (click)="closeToolModel()">
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
            <button type="submit" mat-mini-fab mat-button color="primary" *ngIf="!dataPointMode"
              (click)="UpdateDataPoints()" [disabled]="DataPointsForm.invalid" matTooltip="Update"
              matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;">
              <mat-icon>save</mat-icon>
            </button>

            <button type="submit" mat-mini-fab mat-button color="primary" *ngIf="dataPointMode"
              (click)="AddDataPoints()" [disabled]="DataPointsForm.invalid" matTooltip="Save"
              matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;">
              <mat-icon>save</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</div>