import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AreaService } from 'src/app/services/area.service';
import { AuditCategoryService } from 'src/app/services/audit-category.service';
import { AuditexecutionService } from 'src/app/services/auditexecution.service';
import { CheckpointsService } from 'src/app/services/checkpoints.service';
import { GlobalStandardService } from 'src/app/services/global-standard.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { ScoreTableService } from 'src/app/services/score-table.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import * as moment from 'moment';
import 'moment-timezone';
import { GembaWalkService } from 'src/app/services/gemba-walk.service';

export interface Example {
  label: any;
  content: any;
}



@Component({
  selector: 'app-audit-execution',
  templateUrl: './audit-execution.component.html',
  styleUrls: ['./audit-execution.component.scss'],
})
export class AuditExecutionComponent implements OnInit {
  asyncTabs: any[] = [];
  tab: any[];
  searchAuditMasterForm!: FormGroup;
  GlobalData!: MatTableDataSource<any>;
  auditForm!: FormGroup;
  obj: any
  plants!: any;
  category: string;
  selectedscore: any[];
  arr = []
  totalPercentage = 0;
  notificationarray: any
  plantsDDL!: any[];
  areaDDL!: any[];
  filterCheck: any[];
  scoreData: any[] = [];
  compList: any[] = [];
  categoryTypeDDL: any[] = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
  ];

  globalColumns: string[] = ['plant', 'area', 'type', 'name', 'file'];

  // AEDetails!: any[];
  audicategories: [];
  checkPoint = new MatTableDataSource<any>([]);

  //@ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChildren(MatPaginator) paginator1 = new QueryList<MatPaginator>();
  @ViewChild('displayObservationModal')
  private displayObservationModal!: TemplateRef<any>;
  @ViewChildren(MatSort) sort1 = new QueryList<MatSort>();
  auditexeColumns: string[] = ['srno', 'name', 'score'];
  filterdplants!: any[];
  groupListDDL!: any[];
  nonCompList: any[] = [];
  breadcrumList: any;
  pointsData: []
  Categorytype: any;
  Categorytype2!: any;
  disabled: boolean = false;
  moduleId!: any;
  pageId!: any;
  IsEmpty = false;
  loginUserId: any;
  createdBy: any;
  dayStartDate!: any;
  dayEndDate!: any;
  collectValue!: any;
  observationDataForm!: FormGroup;
  AreaName!: any;
  OwnerName!: any;
  auditClassifications!: any[];
  files: File[] = [];
  image: any;
  AreaId!: any;
  filteredOwners!: any[];
  owners!: any[];
  area!: any[];
  rows!: FormArray;
  observationform!: FormGroup;
  generalCategoryList: any;
  isSameUser: boolean;
  PreviousExecution: any;
  totalPrevPercentage: number;
  overallPrevPercentage: number = 0;
  selectedIndex: number;
  maxScore: number = 0;
  fileType!: string;
  FileData: any = [];
  DisableSave = true ;
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private areaService: AreaService,
    private sccoreService: ScoreTableService,
    private spinner: NgxSpinnerService,
    private auditCategoryService: AuditCategoryService,
    private checkpointService: CheckpointsService,
    private auditexecutionService: AuditexecutionService,
    private globalService: GlobalStandardService,
    private userService: UserManagementService,
    private machineService: MachineMappingService,
    public router: Router,
    private gembaService: GembaWalkService,
  ) {
  }
  @ViewChild('displayRoleModal1') private displayRoleModal1!: TemplateRef<any>;

  ngOnInit(): void {
    this.dayStartDate = new Date();
    this.dayEndDate = new Date();
    this.dayStartDate.setHours(0, 0, 0, 0);
    this.dayEndDate.setHours(23, 59, 59, 999);
    this.loginUserId = JSON.parse(localStorage.user).UserId;
    this.searchAuditMasterForm = this.fb.group({
      plant: ['', Validators.required],
      area: ['', Validators.required],
      group: ['', Validators.required],
    });
    this.auditForm = this.fb.group({
      sort: [''],
      order: [''],
      shine: [''],
      standardize: [''],
      sustain: [''],
      group: [''],
    });
    this.observationform = this.fb.group({
      actionsArray: this.fb.array([this.ObservationForm()]),
    });
    this.rows = this.observationform.get('actionsArray') as FormArray;
    this.observationDataForm = this.fb.group({
      observe: ['', Validators.required],
      fivesclassification: ['', Validators.required],
      owner: [''],
      generalCategories: ['', Validators.required],
      image: [''],
      save: [''],
    });
    this.GetBreadcrumList();
    this.getGroupData();
  }

  get5sAuditExecution(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const area = this.searchAuditMasterForm.get('area')?.value;
    const timezone = moment.tz.guess();
    var start = new Date();
    var end = new Date();
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    const key = {
      plantId: plant,
      areaId: area,
      fromDate: moment(start).utc(),
      toDate: moment(end).utc(),
    };
    this.auditexecutionService.get5sAuditExecution(key).subscribe(
      (data) => {

        this.createdBy = data ? data[0].createdBy ? data[0].createdBy : JSON.parse(localStorage.user).UserId : undefined;
        this.Categorytype = data ? data[0].category : undefined;
        if (this.createdBy == JSON.parse(localStorage.user).UserId) {
          this.isSameUser = true;
        }
        let temparr = [];
        data.forEach((e: any) => {
          e.score = e.score.split(',').sort();

        });

        for (const pu of data) {
          let totalPercentage = 0;
          const obj = { ...pu, totalPercentage };
          temparr.push(obj);
        }


        this.asyncTabs = temparr;
        let counter = 0;
        let totallength = 0;
        this.DisableSave = true ;
        this.asyncTabs?.forEach((elem) => {
          if(elem?.data)
          {
            totallength = totallength +  elem?.data?.length;
          }
            elem?.data?.forEach((elem1) => {
              if(elem1.value && elem1.value != '' )
              {
               counter ++;
              }
            });
        });
        if(totallength === counter && counter && counter != 0)
        {
          this.DisableSave = false ;
        }
        this.asyncTabs.forEach((elem) => {
          elem.gainPercentage = 0;
        });
        let totalgain = 0;
        let total = 0;
        if (this.asyncTabs) {
          let counter = 0;
          for (let i = 0; i < this.asyncTabs?.length; i++) {
            if (this.asyncTabs[i].catType === 'Score') {
              // this.maxScore = Math.max(...(this.asyncTabs[i].score.map(Number)));
              this.asyncTabs[i].totalPercentage = this.asyncTabs[i]?.data?.length * Math.max(...(this.asyncTabs[i].score.map(Number)));
              let gainmarks = 0;
              if (this.asyncTabs[i]?.data !== null) {
                counter++;
                for (let j = 0; j < this.asyncTabs[i]?.data?.length; j++) {
                  gainmarks = (this.asyncTabs[i]?.data[j].value == '' ? 0 : parseInt(this.asyncTabs[i]?.data[j].value)) + gainmarks

                }
                this.asyncTabs[i].gainPercentage = gainmarks / this.asyncTabs[i].totalPercentage * 100
              }

            }
            else if (this.asyncTabs[i].catType === 'Option') {
              this.asyncTabs[i].totalPercentage = this.asyncTabs[i]?.data?.length * this.maxScore;
              let gainmarks = 0;
              if (this.asyncTabs[i]?.data !== null) {
                counter++;
                for (let j = 0; j < this.asyncTabs[i]?.data?.length; j++) {
                  gainmarks = (this.asyncTabs[i]?.data[j].value == '' ? 0 : this.asyncTabs[i]?.data[j].value == 'Yes' ? this.maxScore : 0) + gainmarks;

                }
                this.asyncTabs[i].gainPercentage = gainmarks / this.asyncTabs[i].totalPercentage * 100;
              }
            }

            totalgain = this.asyncTabs[i].gainPercentage + totalgain;
          }
          if (counter !== 0) {
            this.totalPercentage = totalgain / counter;
          }
          else{
            this.totalPercentage = 0
          }
        }
        this.selectedIndex = 0;
        this.getpreviousExecution();
      }
    );

  }

  showWarning(notificationarray): void {
    if (notificationarray != null) {
      this.toaster.warning(notificationarray);
    }
  }
  getAuditCategoryMaster(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const area = this.searchAuditMasterForm.get('area')?.value;
    const key = {
      plantId: plant,
      areaId: area
    };
    this.auditCategoryService.getAuditCategory(key).subscribe(
      (data) => {
        this.audicategories = data;
      },
      (error) => {
        this.toaster.error(error.error.Message);
      }
    );
  }
  onTabChange(event: any): any {
    const recordSingleTab = this.asyncTabs.find(c => c.category === event.tab.textLabel);
    this.Categorytype = event.tab.textLabel;
    this.Categorytype2 = recordSingleTab.catType;
    this.prevSocre();
  }

  changeArea(): void {
    this.get5sAuditExecution();
  }
  getAuditAreaByPlant(): void {
    debugger;
    this.DisableSave = true ;
    this.asyncTabs = [];
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const key = {
      plantId: plant, //.ptId,
    };

    this.getScoreByPlant();

    this.areaService.getAuditCheckpoint(key).subscribe(
      (data) => {
        this.areaDDL = data;
        if (this.areaDDL.length) {
          this.searchAuditMasterForm.get('area')?.setValue(this.areaDDL[0].areaId);
          this.getAuditCategoryMaster();
          this.get5sAuditExecution();
          // this.getpreviousExecution();
        } else {
          this.toaster.warning('warning', 'Area Not found against this plant');
        }
      },
      (error) => {
        this.toaster.error(error.error.message);
      }
    );
  }

  getPaginator(): void {
    setTimeout(() => {

      this.checkPoint.paginator = this.paginator;
      this.checkPoint.sort = this.sort;
    }, 1000);
  }
  resetButtonColor(): void {
    const ele = Array.from(
      document.getElementsByClassName(
        'btnGreen'
      ) as HTMLCollectionOf<HTMLElement>
    );
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < ele.length; i++) {
      ele[i].style.backgroundColor = '#10ce9c';
    }
  }
  GetCheckPoints(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const area = this.searchAuditMasterForm.get('area')?.value;
    const key = {
      plantId: plant,
      areaId: area,
    };
    this.checkpointService.GetCheck(key).subscribe(
      (res) => {
        this.pointsData = res;
      }
    );
  }
  getpreviousExecution(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const area = this.searchAuditMasterForm.get('area')?.value;
    const start = new Date();
    const end = new Date();
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    const key = {
      plantId: plant,
      areaId: area,
      fromDate: moment(start).utc(),
      toDate: moment(end).utc(),
    };

    this.auditexecutionService.getPreviousAuditExecution(key).subscribe(
      (res) => {
        this.PreviousExecution = res;
        this.prevSocre();
      }
    );
  }
  prevSocre(): void {
    this.overallPrevPercentage = 0;
    const questionByCategory = this.PreviousExecution.filter(c => c.AuditMasterCategory === this.Categorytype);
    if (questionByCategory.length) {
      const totalq = questionByCategory.length * this.maxScore;
      const scoresum = questionByCategory.map(c => c.PrevScore).reduce((partialSum, a) => partialSum + a, 0);
      this.totalPrevPercentage = (scoresum / totalq) * 100;
    } else {
      this.totalPrevPercentage = 0;
    }
    const overAlltotalq = this.PreviousExecution.length * this.maxScore;
    const overAllscoresum = this.PreviousExecution.map(c => c.PrevScore).reduce((partialSum, a) => partialSum + a, 0);
    this.overallPrevPercentage = (overAllscoresum / (overAlltotalq == 0 ? 1 : overAlltotalq)) * 100;
  }

  getCheckpointsByCategory(data: any, index: any): void {

    this.resetButtonColor();
    const ele = Array.from(
      document.getElementsByClassName(
        'btnGreen'
      ) as HTMLCollectionOf<HTMLElement>
    );
    ele[index].style.backgroundColor = '#33b5e5';
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const area = this.searchAuditMasterForm.get('area')?.value;

    if (area !== '') {
      const key = {
        plantId: plant,
        auditCatId: data.auditCatId,
        areaId: area,
      };
      this.spinner.show();
      this.checkpointService.getCheckpointsbyCategory(key).subscribe(
        (res) => {
          res.forEach((element: any) => {
            element.score = null;
            this.spinner.hide();
          });
        },
        (error) => {
          this.toaster.error(error.error.Message);
          this.spinner.hide();
        }
      );
    } else {
      this.toaster.error('Select Area First..!  ');
      this.spinner.hide();
    }
  }

  getScoreByPlant(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const key = {
      plantId: plant, //.ptId,
    };
    this.sccoreService.getScores(key).subscribe(
      (data) => {
        this.nonCompList = [];
        this.compList = [];
        this.scoreData = data;
        this.maxScore = 0

        data.map((element: any) => {
          const score = element.score;
          if(score>this.maxScore){
            this.maxScore = score;
          }
          if (score < 4) {
            this.nonCompList.push(element);
          } else {
            this.compList.push(element);
          }
        });
      },
      (error) => {
        this.toaster.error(error.error.Message);
      }
    );
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.searchAuditMasterForm
          .get('group')
          ?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlantByGroup(): void {
    this.DisableSave = true;
    const key = {
      GroupId: this.searchAuditMasterForm.value.group,
    };
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.filterdplants = data.DepartList;
        if (this.filterdplants.length > 0) {
          this.searchAuditMasterForm
            .get('plant')
            ?.setValue(this.filterdplants[0].DeptId);

        } else {
          this.searchAuditMasterForm.get('plant')?.setValue(null);
        }
        this.getAuditAreaByPlant();


        this.audicategories = [];
        //   this.checkPoint = new MatTableDataSource<any>([]);
        this.scoreData = [];
        // this.categoryTypeDDL = [];
        this.areaDDL = [];
        //   this.checkPoint = new MatTableDataSource<any>([]);
        this.getPaginator();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  openGlobalModal(): void {
    this.dialog.open(this.displayRoleModal1, { height: '80%', width: '90%' });
    this.getGlobalStandards();
  }

  getGlobalStandards(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const key = {
      plantId: plant,
    };
    this.spinner.show();

    this.globalService.getGlobalStandard(key).subscribe(
      (data) => {
        this.GlobalData = new MatTableDataSource<any>(data);
        this.spinner.hide();

        this.getGlobalPaginator(this.GlobalData, 1);
      },
      (error: any) => {
        this.toaster.error('Error', error.error.Message);
        this.spinner.hide();

      }
    );
  }

  getGlobalPaginator(dataa: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataa.paginator = this.paginator1.toArray()[index];
    }, 1000);
  }

  scoreUpdate(event: any, data: any, catId: number, checkPointId: number): void {



    this.asyncTabs?.forEach((elem) => {

      let collectValue = [];
      if (elem.auditCatId === catId) {
        elem.data.forEach((elem1) => {

          collectValue.push(elem1.value)
          if (elem1.checkPointId === checkPointId) {
            elem1.value = event.value;
          }

        });
      }

      if (this.asyncTabs) {
        let counter = 0;
        let totalgain = 0;
        for (let i = 0; i < this.asyncTabs?.length; i++) {
          if (this.asyncTabs[i].catType === "Score") {
            const totalmarks = this.asyncTabs[i]?.data?.length * Math.max(...(this.asyncTabs[i].score.map(Number)));
            let gainmarks = 0;
            if (this.asyncTabs[i]?.data !== null) {
              counter++;
              for (let j = 0; j < this.asyncTabs[i]?.data?.length; j++) {
                gainmarks = (this.asyncTabs[i]?.data[j].value == '' ? 0 : parseInt(this.asyncTabs[i]?.data[j].value)) + gainmarks

              }
              this.asyncTabs[i].gainPercentage = gainmarks / totalmarks * 100;
            }

          }
          else if (this.asyncTabs[i].catType === "Option") {
            let totalmarks = this.asyncTabs[i]?.data?.length * this.maxScore;
            let gainmarks = 0;
            if (this.asyncTabs[i]?.data !== null) {
              counter++
              for (let j = 0; j < this.asyncTabs[i]?.data?.length; j++) {
                gainmarks = (this.asyncTabs[i]?.data[j].value == '' ? 0 : this.asyncTabs[i]?.data[j].value == 'Yes' ? this.maxScore : 0) + gainmarks

              }
              // this.asyncTabs[i]?.data?.forEach((elem2) => {
              //   });
              this.asyncTabs[i].gainPercentage = gainmarks / totalmarks * 100;
            }

          }
          totalgain = this.asyncTabs[i].gainPercentage + totalgain
        }
        this.totalPercentage = totalgain / counter;
      }

      // this.foundPercent(collectValue,elem.score)
       

    });
    let counter = 0;
    let totallength = 0;
    this.asyncTabs?.forEach((elem) => {
      if(elem?.data)
      {
        totallength = totallength +  elem?.data?.length;
      }
        elem?.data?.forEach((elem1) => {
          if(elem1.value)
          {
           counter ++;
          }
        });
    });
    if(totallength === counter && counter && counter != 0)
    {
      this.DisableSave = false ;
    }
  }

  foundPercent(collectValue, score) {
    if (collectValue.length != 0) {
      const totalQ = collectValue.length;
      const highvalue = score.length;
      const temp = totalQ * highvalue;
      let sum = 0

      for (var i = 0; i < collectValue.length; i++) {

        if (collectValue[i] !== '') {
          sum += parseInt(collectValue[i])

        }

      }
      // console.log("sum of selected value",sum)
      // const sumofvalue = sum;
      this.asyncTabs.forEach(element => {
        element.totalPercentage = ((sum / temp) * 100).toFixed(2), "%";
      });


    }

  }
  saveAuditExecution(catId: number): void {
    debugger;
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const area = this.searchAuditMasterForm.get('area')?.value;
    const createdOn = moment().toDate();
    const executionDetails = [];
    var execData: any;

    this.IsEmpty = false;
    this.asyncTabs.forEach(element => {
      if (element.data != undefined) {
        element.data.forEach((data, i) => {
          if (data.value === '' || data.value == null || data.value == undefined) {
            this.IsEmpty = true;
            this.notificationarray = [];
            this.notificationarray.push(`CheckPoint Missing At Category  ${element.category} on Sr No: ${i + 1}`);
          }
        });
      }
    });
    this.showWarning(this.notificationarray);
    if (!this.IsEmpty) {
      const key = {
        plantId: plant,
        areaId: area,
        userId: JSON.parse(localStorage.user).UserId,
        createdOn,
        AEDetails: this.asyncTabs,
        DayStartDate: moment(this.dayStartDate).utc(),
        DayEndDate: moment(this.dayEndDate).utc(),
      };
      this.auditexecutionService.addAuditExecution(key).subscribe(
        (response) => {
          this.toaster.success('Success', 'Audit Execution Added Successfully');
          this.get5sAuditExecution();
          this.clearScoreData();
        },
        (error) => {
          this.toaster.error('Error', error.error.Message);
        }
      );
    }
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  clearScoreData(): void {
    // this.checkPoint.data.forEach((element: any) => {
    //   element.CatTypeDetail = null;
    // });
    // this.checkPoint.data.forEach((element: any) => {
    //   element.score = null;
    // });
  }
  add(): void {
  }

  getFiveSClassification(): void {
    this.userService.GetAuditClassifications().subscribe(
      (data: any) => {
        this.auditClassifications = data;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  uploadedFile(event: any): void {
    // const reader = new FileReader();

    // if (event.target.files.length > 0) {
    //   this.files = event.target.files;
    //   const file = event.target.files[0];
    //   reader.readAsDataURL(file);
    //   reader.onload = (e: any) => {
    //     // called once readAsDataURL is completed
    //     this.image = e.target.result;
    //   };
    // }
    const files = event.target.files;
    if (files) {
      for (const file of files) {
        if (file.type.match('image.*')) {
          this.fileType = 'image';
          this.files.push(file);
        }
        else {
          this.toaster.warning('Warning', 'File Format Not Valid ');
          return;
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
          // this.imageSrc = e.target.result;
          this.FileData.push({ FileType: this.fileType, ImageSrc: e.target.result });
        };
        reader.readAsDataURL(file);
      }

    }
  }

  openModal(): void {

    this.checkArea();
  }

  checkArea(): void {
    const key = {
      AreaId: this.searchAuditMasterForm.value.area
    };
    this.auditexecutionService.checkArea(key).subscribe((res: any) => {
      if (res?.message === 'Area Exist') {
        this.observationDataForm.reset();
        this.dialog.open(this.displayObservationModal, {
          height: '55%',
          width: '85%',
        });
        this.files = [];
        this.FileData = [];
        this.areaDDL.forEach((elem) => {
          if (elem.areaId === this.searchAuditMasterForm.value.area) {
            this.AreaName = elem.areaName;
          }
        });
        this.AreaId = res.AreaId;
        this.getOwner();
        this.getFiveSClassification();
        this.getGeneralCategories();
      }
      else {
        this.toaster.warning('Area Owner Not Found');
      }
    },
      (error) => {
        this.toaster.error('Error', error.error.Message);
      });
  }

  getOwner(): void {
    const key = {
      egId: this.searchAuditMasterForm.value.group,
      areaId: this.AreaId,
      ptId: this.searchAuditMasterForm.value.plant,
      areaIds: [0],
    };

    this.userService.GetUsersByArea(key).subscribe(
      (data: any): any => {
        this.owners = data;
        this.filteredOwners = this.owners.slice();
        this.observationDataForm.get('owner').setValue(this.filteredOwners[0].userId);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  ObservationForm(): FormGroup {
    return this.fb.group({
      // gembaActionId   : [d && d.actionName ? d.actionName : null, []],
      actionName: [],
      targetDate: [],
      noOfMisses: [],
      responsibleId: [],
      newTargetDate: [],
      completed: [],
      rejected: [],
      isRequiredPictureAfter: [],
      verified: [],
      remarks: [],
      gembaActionId: [],
      department: [],
    });
  }

  onSubmit(): void {
    const formData = new FormData();
    for (let i = 0; i < this.files.length; i++) {
      formData.append('files', this.files[i]);
    }
    const gembaWalk = {
      userId: JSON.parse(localStorage.user).UserId,
      plantId: this.searchAuditMasterForm.value.plant,
      areaId: this.AreaId,
      ownerId: this.observationDataForm.value.owner,
      observation: this.observationDataForm.value.observe,
      classificationId: this.observationDataForm.value.classification,
      generalCategoryId: this.observationDataForm.value.generalCategories,
      fiveSClassificationId: this.observationDataForm.value.fivesclassification,
    };
    this.spinner.show();
    formData.append('gembaWalk', JSON.stringify(gembaWalk));
    this.gembaService.addGembaWalk(formData).subscribe(
      (data: any) => {
        this.toaster.success('Data Added Successfuly');
        this.dialog.closeAll();
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  getGeneralCategories(): void {
    this.gembaService.getGembaCatagories().subscribe(
      (res: any) => {
        this.generalCategoryList = res;
        if (this.generalCategoryList.length) {
          const record = this.generalCategoryList.find(c => c.categoryName === this.Categorytype);
          this.observationDataForm.get('generalCategories').setValue(record.ID);
        }
      }
    );
  }

  deleteFile(i: number): void {
    this.files.splice(i, 1);
    this.FileData.splice(i, 1);

  }
}


