<div *ngIf="data.formName == 'incident'">
    <div class="dialog-header">
        <h2 style="flex: 1;margin: 0;">Add Incident Report</h2>
        <i class="fa fa-close" aria-hidden="true" (click)="onCancelClick()"></i>
    </div>
    <form [formGroup]="IncidentForm" (ngSubmit)="onSaveIncident()">
        <div>
            <div style="overflow-x: scroll;" class="incidentForm">
                <div class="incident-d_flex">
                    <mat-form-field appearence="fill">
                        <mat-label>Incident Type</mat-label>
                        <mat-select [(ngModel)]="IncidentData.IncidentType" formControlName="IncidentType">
                            <mat-option *ngFor="let incidentType of IncidentTypeList"
                                [value]="incidentType.JobBagStatusId">
                                {{incidentType.JobBagStatusName}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Incident Type is Required</mat-error>
                    </mat-form-field>

                    <div class="inputField">
                        <nz-date-picker readonly class="calender-input formCalender" id="startPicker"
                            [nzShowTime]="true" nzFormat="dd-MMM-yyyy HH:mm:ss" [(ngModel)]="IncidentData.IncidentOn"
                            formControlName="IncidentOn"></nz-date-picker>
                        <span class="floating-text">Incident On</span>
                    </div>
                </div>

                <div class="incident-d_flex">
                    <mat-form-field appearence="fill">
                        <mat-label>Incident Classification</mat-label>
                        <mat-select [(ngModel)]="IncidentData.IncidentClassification"
                            formControlName="IncidentClassification" placeholder="Line">
                            <mat-option *ngFor="let incidentClassification of IncidentClassificationList"
                                [value]="incidentClassification.JobBagStatusId">
                                {{incidentClassification.JobBagStatusName}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Incident Classification is Required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearence="fill">
                        <mat-label>Incident Person</mat-label>
                        <input matInput type="text" formControlName="IncidentPerson"
                            [(ngModel)]="IncidentData.IncidentPerson" />
                        <mat-error>Incident Person is Required</mat-error>
                    </mat-form-field>
                </div>

                <div class="incident-upload">
                    <mat-form-field class="fill">
                        <mat-label>Detail Description</mat-label>
                        <input matInput type="text" [(ngModel)]="IncidentData.Description"
                            formControlName="Description" />
                        <mat-error>Detail Description is Required</mat-error>
                    </mat-form-field>
                </div>
                <div class="dropzone">
                    <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" [multiple]="false" (change)="onSelect($event)">
                        <ngx-dropzone-label>
                            <div>
                                <h2>Upload Image</h2>
                            </div>
                        </ngx-dropzone-label>
                        <div *ngIf="isImage">
                            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files"
                                [file]="f" [removable]="true" (removed)="onRemove(f)">
                                <ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
                            </ngx-dropzone-image-preview>
                        </div>
                    </div>
                </div>
            </div>
            <div mat-dialog-actions class="mat-dialog-actions">
                <button type="submit" class="dialog-save-btn" cdkFocusInitial> Save </button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="data.formName == 'bos'">
    <div class="dialog-header">
        <h2 style="flex: 1;margin: 0;">Add BOS Report</h2>
        <i class="fa fa-close" aria-hidden="true" (click)="onCancelClick()"></i>
    </div>
    <div>
        <form [formGroup]="BOSForm" (ngSubmit)="onSaveBOS()" #scrollMe>
            <div style="overflow-x: scroll; max-height: 440px; overflow-y: scroll;">
                <table mat-table class="basic-table plant-model-table" matSort [dataSource]="addBOSReportDataSource">
                    <ng-container matColumnDef="Description">
                        <th mat-header-cell *matHeaderCellDef>
                            <p class="header-text">Description</p>
                        </th>
                        <td mat-cell *matCellDef="let element"> Work Environment : <br> {{element.Description}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef>
                            <p class="header-text">Status</p>
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index" class="incidentForm"
                            formArrayName="statusArray">
                            <mat-form-field appearence="fill">
                                <mat-label>Select Status</mat-label>
                                <mat-select [(ngModel)]="element.Status" [formControlName]="i"
                                    (selectionChange)="setValidator(element,i)">
                                    <mat-option *ngFor="let status of StatusList" [value]="status.value">
                                        {{status.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Remarks">
                        <th mat-header-cell *matHeaderCellDef>
                            <p class="header-text">Remarks</p>
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index" class="incidentForm"
                            formArrayName="remarkArray">
                            <mat-form-field appearence="fill">
                                <mat-label>Remarks</mat-label>
                                <input matInput type="text" [(ngModel)]="element.Remarks" [formControlName]="i" />
                                <mat-error>Remarks is Required</mat-error>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="addBOSReportColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: addBOSReportColumns"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching</td>
                    </tr>
                </table>
            </div>
            <div mat-dialog-actions class="mat-dialog-actions">
                <button type="submit" class="dialog-save-btn" cdkFocusInitial>Save</button>
            </div>
        </form>
    </div>
</div>