import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PrimeCellEditorComponent } from '../../editors/prime-cell-editor.component';
import { CustomDateComponent } from '../../date-components/custom-date-component.component';
import { BtnCellRendererActualEntry } from '../../btn-cell-rendererActualEntry.component';
import { BtnCellRenderer } from '../../btn-cell-renderer.component';
import { Kpi } from '../../models/kpi';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { Frequency } from 'src/app/models/frequency';
import { Driver } from 'src/app/models/driver';
import { Router } from '@angular/router';
import { Pillar } from 'src/app/models/pillar';
import { Department } from 'src/app/models/department';
import { UserPlant } from 'src/app/models/userPlant';
import * as _moment from 'moment';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { TranslaterService } from 'src/app/services/translater.service';
const moment = _moment;
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { UserManu } from 'src/app/models/userManu';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-actionanalysis',
  templateUrl: './actionanalysis.component.html',
  styleUrls: ['./actionanalysis.component.scss'],
})
export class ActionanalysisComponent implements OnInit {
  actionAnalysisForm!: FormGroup;

  userManu!: UserManu[];
  usermanagements!: any[];
  dataArray!: [];
  plants!: UserPlant[];
  searchPlants!: any[];
  kpifrequencies!: Frequency[];
  kpidata!: Kpi[];
  components!: any;
  pillars!: Pillar[];
  gridColumnApiEdit: any;
  autoGroupColumnDef!: any;
  kpis!: Kpi[];
  frequencies!: Frequency[];
  kpiDriver!: Driver[];
  kpidepartments: string[] = [];
  departments!: Department[];
  columnDefsShift: any;
  dataArraykpi!: [];
  kpiPillar: string[] = [];
  targetActualEntryDate!: Date;
  kpifrequency!: string;
  kpiTitle!: string;
  drivers!: Driver[];
  kpiUom!: string;
  gridApi: any;
  actionDate!: Date;
  gridApiEdit: any;
  gridColumnApi: any;
  actionPermission!: UserManu;
  visibleVisionTarget = false;
  visibleYearlyTarget = false;
  visibleQutarlyTarget = false;
  visibleMonthlyTarget = false;
  visibleWeeklyTarget = false;
  visibleDailyTarget = false;
  visibleShiftlyTarget = false;
  columnDefs: any;
  startYear: any;
  icons!: any;
  endYear: any;
  completionDate = true;
  cellData: any;
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
    },
  };
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;
  groupList: any;
  moduleId!: any;
  pageId!: any;
  languageDir = 'ltr';
  langObj: any = {};
  _localeCal: any;
  fday: number;
  calendar_en: any;
  firstDayOfWeek: any;
  constructor(
    private toaster: ToastrService,
    private fb: FormBuilder,
    public router: Router,
    private kpiMaster: KpimasterService,
    private spinner: NgxSpinnerService,
    private usermanagementService: UserManagementService,
    public translater: TranslaterService,
  ) {
    this.columnDefs = [
      {
        field: 'KPIName', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'AnalysisDate',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        // rowGroup: true,
        // hide: true,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      { field: 'Action', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'Frequency', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'RootCause', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this) },
      {
        headerName: 'Analytical Tools',
        field: 'Tool',
        sortable: true,
        filter: true, resizable: true, wrapText: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Status',
        sortable: true,
        filter: true, resizable: true, wrapText: true,
        // editable: true,
        editable: (params: any) => params.data.Status !== 'Closed' && this.IsWrite,
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Closed', 'Open', 'In Progress', 'Delayed'],
        },
        cellStyle: (params: { value: string; }) => {
          if (params.value === 'Closed') {
            return { color: 'black', backgroundColor: '#38cf36', 'font-weight': 'bold' };
          } else if (params.value === 'In Progress') {
            return { color: 'black', backgroundColor: 'yellow', 'font-weight': 'bold' };
          } else if (params.value === 'Delayed') {
            return { color: 'black', backgroundColor: '#eb3d3d', 'font-weight': 'bold' };
          } else {
            return { color: 'black', backgroundColor: '#11ced1', 'font-weight': 'bold' };
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      { field: 'Cause Classification(4M/5M/6M)', sortable: true, filter: true, resizable: true, wrapText: true, minWidth: 270, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'TargetDate', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this) },

      {
        field: 'lossAnalysisDetailId',
        sortable: true,
        filter: true, resizable: true, wrapText: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      { field: 'Remarks', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this) },
      {
        field: 'CompletionDate',
        sortable: true,
        resizable: true,
        wrapText: true,
        filter: 'agDateColumnFilter',
        // editable: true,
        cellEditor: 'primeCellEditor',
        editable: (params: any) => params.data.Status !== 'Closed' && this.IsWrite,
        filterParams: {
          comparator: myDateComparator,
        },
        cellStyle: {
          color: 'black',
          'background-color': '#EDF1E5',
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      { field: '#OfMisses', sortable: true, filter: true, resizable: true, wrapText: true.valueOf, headerValueGetter: this.localizeHeader.bind(this) },
      {
        field: 'NewTargetDate',
        sortable: true,
        resizable: true,
        wrapText: true,
        editable: (params: any) => params.data.Status !== 'Closed' && this.IsWrite,
        filter: 'agDateColumnFilter',
        cellEditor: 'primeCellEditor',
        filterParams: {
          comparator: myDateComparator,
        },
        cellStyle: {
          color: 'black',
          'background-color': '#EDF1E5',
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      { field: 'Pillar', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'Department', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'Driver', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'Responsible', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this) },
      { field: 'Accountable', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this) },
    ];
    // this.autoGroupColumnDef = {
    //   headerName: 'Action Plan',
    //   resizable: true,
    //    wrapText: true,
    //    minWidth: 300,
    //    cellStyle: {'font-weight': 'bold'}
    // };


  }

  // getAllPlants(): void {
  //   const key = {
  //     userId: JSON.parse(localStorage.user).UserId,
  //   };
  //   this.kpiMaster.getUserPlants(key).subscribe((plants) => {
  //     this.searchPlants = plants;
  //     this.plants = plants;
  //     if (this.searchPlants.length === 1) {
  //       const plantObject = this.searchPlants[0];
  //       this.actionAnalysisForm.get('plant')?.setValue(plantObject);
  //       this.getMasterData(plantObject);
  //     }
  //   },
  //     (error) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }
  ngOnInit(): void {
    this.actionAnalysisForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      toDate: ['', Validators.required],
      fromDate: ['', Validators.required],
    });
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroupData();


    const listmenu = localStorage.getItem('result');
    const permissions = listmenu !== null ? JSON.parse(listmenu) : [];
    this.actionPermission = permissions.find(
      (x: { ParentName: string; MenuName: string; Actions: string }) =>
        x.ParentName === 'Performance Management' &&
        x.MenuName === 'Action Plan' &&
        x.Actions === 'Action Plan'
    );
  }

  ActionAnalysisData(): void {
    const getData = {
      fromDate: moment(this.actionAnalysisForm.value.fromDate).format('YYYY-MM-DD'),
      toDate: moment(this.actionAnalysisForm.value.toDate).format('YYYY-MM-DD'),
      plantId: this.actionAnalysisForm.value.plant.DeptId,
    };
    this.spinner.show();

    this.kpiMaster.getActionAnalysis(getData).subscribe(
      (data) => {
        this.gridApi.setRowData(data);
        this.spinner.hide();

      },
      (error) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();

      }
    );
  }

  onDateChange(value: any): any {
    const getdata = {
      toDate: this.actionAnalysisForm.value.toDate,
      fromDate: this.actionAnalysisForm.value.fromDate,
      plantId: this.actionAnalysisForm.value.plant.DeptId,
    };

    this.kpiDriver = [];
    this.kpiPillar = [];
    this.kpidepartments = [];
    if (getdata.plantId === undefined) {
      this.toaster.warning('Warning', 'Please Select Plant');
      return;
    }
    this.kpiMaster.getActionAnalysis(getdata).subscribe(
      (response: any) => {
        this.gridApi.setRowData(response);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getGroupData(): void {
    const key = {
      Id: 0
    };

    this.usermanagementService.getGroupData(key).subscribe((data: any) => {
      this.groupList = data.masterList;
      const obj = this.groupList[0];
      this.actionAnalysisForm.get('group')?.setValue(obj);
      this.getPlants();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlants(): void {
    const key = {
      GroupId: this.actionAnalysisForm.value.group.TypeId
    };
    this.usermanagementService.getDepartments(key).subscribe((res: any) => {
      this.searchPlants = res.DepartList;
      const plantObject = this.searchPlants[0];
      this.actionAnalysisForm.get('plant')?.setValue(plantObject);
      this.getMasterData(plantObject);
    },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  SetFirstDayofWeek(): void {
    const dept = this.searchPlants.find(item => item.DeptId === this.actionAnalysisForm.value.plant.DeptId);
    this.firstDayOfWeek = dept ? dept.firstDayOfWeek : 0;
    this.calendar_en = {
      closeText: 'Close',
      prevText: 'Previous',
      nextText: 'Next',
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      weekHeader: 'Week',
      weekNumberTitle: 'W',
      firstDayOfWeek: this.firstDayOfWeek,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: '',
      timeOnlyTitle: 'Only Time',
      timeText: 'Time',
      hourText: 'Hour',
      minuteText: 'Minute',
      secondText: 'Second',
      currentText: 'Current Date',
      ampm: false,
      year: 'Year',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      list: 'Agenda',
      allDayText: 'All Day',
      moreLinkText: 'More...',
      noEventsText: 'No Events',
      today: 'Today',
      clear: 'Clear',

    };
    const today = new Date().getFullYear();
    this.startYear = new Date(today, 0);
    this.endYear = new Date(today, 11, 31);
  }

  getMasterData(event: any): any {
    this.kpifrequencies = [];
    this.kpiDriver = [];
    this.kpiPillar = [];
    this.kpidepartments = [];
    this.kpidata = [];
    // this.getFrequencies();
    this.dataArray = [];
    this.SetFirstDayofWeek();
    const key = {
      plantId: this.actionAnalysisForm.value.plant.DeptId,
    };
    const getData = {
      plantId: this.actionAnalysisForm.value.plant.DeptId,
      toDate: this.actionAnalysisForm.value.toDate.length == 0 ? this.endYear : this.actionAnalysisForm.value.toDate,
      fromDate: this.actionAnalysisForm.value.fromDate.length == 0 ? this.startYear : this.actionAnalysisForm.value.fromDate,
    };
    this.spinner.show();
    this.kpiMaster.getActionAnalysis(getData).subscribe(
      (data) => {
        this.gridApi.setRowData(data);
        this.spinner.hide();

      },
      (error) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();

      }
    );
  }

  onCellValueChanged(params: any): void {
    if (this.IsWrite) {
      const col = params.colDef.field;
      if (col === 'Status') {
        this.cellData = {
          status: params.data.Status,
          lossAnalysisDetailId: params.data.lossAnalysisDetailId,
          createdBy: JSON.parse(localStorage.user).userId,
        };
        this.kpiMaster.setTargetDate(this.cellData).subscribe(
          (response: any) => {
            this.toaster.success('Success', 'Data Added Successfully');
          },
          (error: any) => {
            return this.toaster.error('Error', error.message);
          }
        );
      }
      else if (col === 'CompletionDate') {
        const dateString = params.data.CompletionDate === null ? null : params.data.CompletionDate;
        let dateObject = ''; // Oct 23
        if (dateString != null) {
          dateObject = this.convertDate(params.data.CompletionDate);
        }
        const analysisDate = new Date(this.convertDate(params.data.AnalysisDate).replace(/-/g, '/'));
        const completionDate = new Date(this.convertDate(params.data.CompletionDate).replace(/-/g, '/'));
        if (completionDate < analysisDate) {
          this.toaster.error('Completion Date should be grater than & equal analysis date');
          return params.oldValue;
        }
        params.data.Status = 'Closed';
        this.cellData = {
          status: params.data.Status,
          completionDate: dateObject,
          lossAnalysisDetailId: params.data.lossAnalysisDetailId,
          createdBy: JSON.parse(localStorage.user).userId,
        };
        this.kpiMaster.setCompletionDate(this.cellData).subscribe(
          (response: any) => {
            this.toaster.success('Success', col + 'Status Added Successfully');
            params.node.setDataValue('Status', params.data.Status);
          },
          (error: any) => {
            return this.toaster.error('Error', error.error.message);
          }
        );
      }
      else if (col === 'NewTargetDate') {
        let dateObject;
        if (params.data.NewTargetDate) {
          // const dateString = params.data.NewTargetDate; // Oct 23
          // const dateParts = dateString.split('/');
          dateObject = this.convertDate(params.data.NewTargetDate); // dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
        }
        const oldTargetDate = new Date(this.convertDate(params.data.TargetDate));
        const newTargetDate = new Date(dateObject);
        if (newTargetDate <= oldTargetDate) {
          this.toaster.error('New Target Date should be grater than Traget Date');
          return;
        }
        params.data.Status = 'Delayed';
        params.data['#OfMisses'] = params.data['#OfMisses'] + 1;
        this.cellData = {
          newTargetDate: dateObject,
          status: params.data.Status,
          lossAnalysisDetailId: params.data.lossAnalysisDetailId,
          createdBy: JSON.parse(localStorage.user).userId,
        };
        this.kpiMaster.setTargetDate(this.cellData).subscribe(
          (response: any) => {
            this.toaster.success('Success', 'Data Added Successfully');
            params.node.setDataValue('#OfMisses', params.data['#OfMisses']);
            params.node.setDataValue('Status', 'Delayed');
          },
          (error: any) => {
            return this.toaster.error('Error', error.message);
          }
        );
      }
    }
  }
  convertDate(date: any): any {
    const dateParts = date.split('/');
    return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReadyEdit(params: any): any {
    this.gridApiEdit = params.api;
    this.gridColumnApiEdit = params.columnApi;
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;

    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value: string) {
    let val;
    if (this.langObj && this.langObj[value]) {
      val = this.langObj[value];
    }
    return val ? val : value;
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if (this.langObj) {
        this.gridApi.refreshHeader();
      }
    });
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        console.log(data.BreadcrumList);
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
}
function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}

function GenderCellRenderer(): void { }

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};
