import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { ToastrService } from 'ngx-toastr';
import { DtServiceService } from 'src/app/services/dtService.service';
import { Router } from '@angular/router';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { CommonService } from 'src/app/services/common.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import * as  Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import Drilldown from 'highcharts/modules/drilldown';

More(Highcharts);
Drilldown(Highcharts);
Exporting(Highcharts);
@Component({
  selector: 'app-at-rca-dashboard',
  templateUrl: './at-rca-dashboard.component.html',
  styleUrls: ['./at-rca-dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ left: '10px', height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ left: '10px', height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class AtRcaDashboardComponent implements OnInit {

  DashboardHeaderForm = new FormGroup({
    group: new FormControl(null, [Validators.required]),
    plant: new FormControl(null, [Validators.required]),
    fromDate: new FormControl(null),
    toDate: new FormControl(null),
  });

  ImageUrl: any;

  formDirective: any;
  spinnerText: string = '';
  permisson!: any;
  IsWrite: boolean = false;
  IsAccess!: any;
  breadcrumList!: any;

  groupList: any = [];
  groupFiltList: any = [];
  plantList: any = [];
  plantFiltList: any = [];
  departmentList: any = [];
  RCADashboardData: any = [];

  RcaByDepartmentChart: any;
  RcaByPeopleChart: any;
  RcaToolsByDepartmentChart: any;
  RCADeptDataByPlant: any = [];
  stock: any;
  ProbableValidRootCauseChart: any;
  MsCountChart: any;
  EmployeeList: any = [];
  PeopleParticipantsDataByPlant: any = [];
  MsCauseDataByPlant: any = [];

  constructor(
    private spinner: NgxSpinnerService, 
    private sfdService: ShopFloorDataService, 
    private toaster: ToastrService, 
    private dtService: DtServiceService, 
    private router: Router,
    private analyticalToolsService: AnalyticalToolsService, 
    private enterpriseGroupService: EnterPriseGroupService, 
    private commonService: CommonService, 
    private userManagementService: UserManagementService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    //this.ImageUrl = environment.ImageUrl;
    this.ImageUrl = environment.O3CoreApiImageUrl;
    this.GetMenuAccess();
    this.GetBreadcrumList();
    this.makeDataLastWeek();
    this.getGroups();

    let historicalHeaderValues = this.analyticalToolsService.getHistoricalHeaderData() ? this.analyticalToolsService.getHistoricalHeaderData() : 0;
    if (historicalHeaderValues) {
      this.DashboardHeaderForm.get('group')?.setValue(historicalHeaderValues.Group);
      this.DashboardHeaderForm.get('plant')?.setValue(historicalHeaderValues.Plant);
    }
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.spinnerText = 'Loading Menu Data';
    this.spinner.show();
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.spinner.hide();
      this.breadcrumList = data.BreadcrumList;
    }, (error: any) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  makeDataLastWeek() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 30);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    this.DashboardHeaderForm.get('fromDate')?.setValue(stDT);
    this.DashboardHeaderForm.get('toDate')?.setValue(edDT);
  };

  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.spinnerText = 'Fetching Groups Data';
    this.spinner.show();
    this.sfdService.getGroupData(key).subscribe((res: any) => {
      this.spinner.hide();
      this.groupList = res.masterList;
      this.groupFiltList = this.groupList.slice();
      if (this.groupFiltList && this.groupFiltList.length) {
        if (this.DashboardHeaderForm.value.group) {
          const find = this.groupList.find((grp: any) => grp.TypeId == this.DashboardHeaderForm.value.group);
          if (find)
            this.DashboardHeaderForm.get('group')?.setValue(find.TypeId);
          else
            this.DashboardHeaderForm.get('group')?.setValue(this.groupList[0].TypeId);
        }
        else
          this.DashboardHeaderForm.get('group')?.setValue(this.groupList[0].TypeId);
        this.getPlants();
      }
    },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getPlants(): void {
    const key = {
      GroupId: this.DashboardHeaderForm.value.group
    };
    this.spinner.show();
    this.dtService.getPlantData(key).subscribe((res: any) => {
      this.spinner.hide();
      this.plantList = res.DepartList;
      this.plantFiltList = this.plantList.slice();
      if (this.plantFiltList && this.plantFiltList.length) {
        if (this.DashboardHeaderForm.value.plant) {
          const find = this.plantList.find((pnt: any) => pnt.DeptId == this.DashboardHeaderForm.value.plant);
          if (find)
            this.DashboardHeaderForm.get('plant')?.setValue(find.DeptId);
          else
            this.DashboardHeaderForm.get('plant')?.setValue(this.plantList[0].DeptId);
        }
        else
          this.DashboardHeaderForm.get('plant')?.setValue(this.plantList[0].DeptId);
        this.getDepartments();
      }
    },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getDepartments() {
    const key = {
      ptId: this.DashboardHeaderForm.value.plant
    };
    this.spinner.show();
    this.dtService.getDeptData(key).subscribe((res: any) => {
      this.departmentList = res;
      this.spinner.hide();
      this.getEmployeeList();
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Error', error.message);
    });
  }

  getEmployeeList() {
    this.spinnerText = "Fetching User Data";
    this.spinner.show();
    const data = { EGId: this.DashboardHeaderForm.value.group, PlantId: this.DashboardHeaderForm.value.plant };
    this.commonService.getPeopleMasterData(data).subscribe((res: any) => {
      this.EmployeeList = res.employeeInfoList;
      this.spinner.hide();
      this.getRCADashboardData();
    }, (err: any) => {
      this.spinner.hide();
    });
  }

  getRCADashboardData() {
    const data = {
      Group: this.DashboardHeaderForm.value.group,
      Plant: this.DashboardHeaderForm.value.plant,
      FromDate: this.DashboardHeaderForm.value.fromDate,
      ToDate: this.DashboardHeaderForm.value.toDate,
      headerTabIndex: 0,
      Department: '',
      Module: 'AnalyticalTools',
      RCACode: '',
      InitProbStatement: '',
      TeamLeader: ['ALL'],
      Status: 'ALL',
    };

    this.spinner.show();
    this.analyticalToolsService.getHistoricalData(data).subscribe((res: any) => {
      this.spinner.hide();
      this.RCADashboardData = res.data.length ? res.data : [];

      // M's By Cause Chart Code Start
      this.MsCauseDataByPlant = [];
      if(this.RCADashboardData && this.RCADashboardData.length){
        const ProbableCauseData = this.RCADashboardData.filter((dashdata: any) => dashdata.StatusCycle && dashdata.causeAndEffects && dashdata.causeAndEffects.length);
        const ValidRootCauseData = this.RCADashboardData.filter((dashdata: any) => dashdata.StatusCycle && dashdata.fiveWhy && dashdata.fiveWhy.length);
        this.MsCauseDataByPlant = this.MsCauseDataByPlant.concat(ProbableCauseData, ValidRootCauseData);
      }
      this.setMsCauseByPlant();
      // M's By Cause Chart Code End


      // M's By RootCause Chart Code Start
      this.setMsRootCauseByPlant();
      // M's By RootCause Chart Code End


      // RCA By Department Chart Code Start
      this.RCADeptDataByPlant = [];
      if(this.RCADashboardData && this.RCADashboardData.length){
        let deptArr = [...new Set(this.RCADashboardData.map(val => val.Dept))];
        if(deptArr && deptArr.length){
          for (const dept of deptArr) {
            const deptData = this.RCADashboardData.filter((dashdata: any) => dashdata.Dept == dept && dashdata.StatusCycle);
            if (deptData && deptData.length) {
              const deptName = this.departmentList.find((deptdata: any) => deptdata.TypeId == dept);
              const deptStatusOpen = deptData.filter((row: any) => row.StatusCycle == 'Open');
              const deptStatusInProgress = deptData.filter((row: any) => row.StatusCycle == 'InProgress');
              const deptStatusClosed = deptData.filter((row: any) => row.StatusCycle == 'Completed');
              const deptStatusDelayed = deptData.filter((row: any) => row.StatusCycle == 'Delayed');
              const deptStatusCompleted = deptData.filter((row: any) => row.StatusCycle == 'NotClosed');
              const deptStatusRejected = deptData.filter((row: any) => row.StatusCycle == 'Rejected');
              const deptTools6w2h = deptData.filter((row: any) => row.Status6W2H);
              const deptToolsFishBone = deptData.filter((row: any) => row.StatusFishbone);
              const deptToolsCauseEffect = deptData.filter((row: any) => row.StatusCauseEffect);
              const deptTools5Why = deptData.filter((row: any) => row.StatusFiveWhy);
              const deptTools5WhyAction = deptData.filter((row: any) => row.StatusFiveWhyAction);
              if (deptName)
                this.RCADeptDataByPlant.push({
                  Name: deptName.TypeName,
                  TotalCount: deptStatusOpen.length + deptStatusInProgress.length + deptStatusClosed.length + deptStatusDelayed.length + deptStatusCompleted.length + deptStatusRejected.length,
                  OpenStatus: deptStatusOpen,
                  InProgressStatus: deptStatusInProgress,
                  ClosedStatus: deptStatusClosed,
                  DelayedStatus: deptStatusDelayed,
                  CompletedStatus: deptStatusCompleted,
                  RejectedStatus: deptStatusRejected,
                  SixWTwoH: deptTools6w2h,
                  FishBone: deptToolsFishBone,
                  CauseAndEffect: deptToolsCauseEffect,
                  FiveWhy: deptTools5Why,
                  FiveWhyAction: deptTools5WhyAction
                })
            }
          }
        }
      }
      this.setRcaByDepartments();
      // RCA By Department Chart Code End


      // RCA By People Chart Code Start
      this.PeopleParticipantsDataByPlant = [];
      if(this.RCADashboardData && this.RCADashboardData.length){
        const peopleData = this.RCADashboardData.filter((dashdata: any) => dashdata.StatusCycle);
        let uniquePeopleArrOfRCA = []
        let uniquePeopleArrOfAction = []
        let PeopleArrOfBoth = []
        let uniquePeopleArrOfBoth = []
        if (peopleData && peopleData.length) {
          let peopleArrOfRCA = [];
          let peopleArrOfAction = [];
          for (const people of peopleData) {
            peopleArrOfRCA = peopleArrOfRCA.concat(people.Team.split(','));
            if (people.fiveWhyActionPlan && people.fiveWhyActionPlan.length) {
              for (const action of people.fiveWhyActionPlan) {
                if (action.NewAssignee || action.AssignTo) {
                  const assignee = action.NewAssignee ? action.NewAssignee : action.AssignTo;
                  peopleArrOfAction.push(assignee.toString())
                }
              }
              uniquePeopleArrOfAction = [...new Set(peopleArrOfAction.map(val => val))];
            }
          }
          uniquePeopleArrOfRCA = [...new Set(peopleArrOfRCA.map(val => val))];
          PeopleArrOfBoth = PeopleArrOfBoth.concat(uniquePeopleArrOfRCA, uniquePeopleArrOfAction);
          uniquePeopleArrOfBoth = [...new Set(PeopleArrOfBoth.map(val => val))];
          for (const uniqpeople of uniquePeopleArrOfBoth) {
            const userobj = this.EmployeeList.find((emp: any) => emp.EmpInfo_Id == uniqpeople);
            if (userobj) {
              let leadRCAArr = [];
              let participantRCAArr = [];
              let totalRCAArr = [];
              let actionArr = [];
              for (const people of peopleData) {
                if (people.TeamLeader == uniqpeople) {
                  leadRCAArr.push(people)
                }
                else if (people.Team.split(',').find((team: any) => team == uniqpeople)) {
                  participantRCAArr.push(people)
                }
  
                if (people.fiveWhyActionPlan && people.fiveWhyActionPlan.length) {
                  let fyaArr = people.fiveWhyActionPlan.filter((data: any) => (data.NewAssignee && data.NewAssignee == uniqpeople) || (data.AssignTo && data.AssignTo == uniqpeople));
                  if (fyaArr && fyaArr.length)
                    fyaArr.map((row: any) => row.InitProbStatement = people.InitProbStatement);
                  actionArr = actionArr.concat(fyaArr);
                }
              }
              totalRCAArr = totalRCAArr.concat(leadRCAArr, participantRCAArr);
              this.PeopleParticipantsDataByPlant.push({
                UserName: userobj.fullName,
                UserId: userobj.EmpInfo_Id,
                LeadRCA: leadRCAArr,
                PartcipantRCA: participantRCAArr,
                TotalRCA: totalRCAArr,
                Actions: actionArr
              });
            }
          }
        }
      }
      this.setRcaByPeoples();
      // RCA By People Chart Code Ens


      // RCA Tools By Department Chart Code Start
      this.setRcaToolsByDepartment();
      // RCA Tools By Department Chart Code End
    },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  setMsCauseByPlant() {
    let PCdata = [];
    let VCdata = [];
    let RCdata = [];

    let PCM1Data = []; let PCM2Data = []; let PCM3Data = []; let PCM4Data = []; let PCM5Data = []; let PCM6Data = [];
    let VCM1Data = []; let VCM2Data = []; let VCM3Data = []; let VCM4Data = []; let VCM5Data = []; let VCM6Data = [];
    let RCM1Data = []; let RCM2Data = []; let RCM3Data = []; let RCM4Data = []; let RCM5Data = []; let RCM6Data = [];

    for (const row of this.MsCauseDataByPlant) {
      if (row.causeAndEffects && row.causeAndEffects.length) {
        const pcm1 = row.causeAndEffects.filter((causedata: any) => causedata.MType.MType == 1);
        PCM1Data = PCM1Data.concat(pcm1);

        const pcm2 = row.causeAndEffects.filter((causedata: any) => causedata.MType.MType == 2);
        PCM2Data = PCM2Data.concat(pcm2);

        const pcm3 = row.causeAndEffects.filter((causedata: any) => causedata.MType.MType == 3);
        PCM3Data = PCM3Data.concat(pcm3);

        const pcm4 = row.causeAndEffects.filter((causedata: any) => causedata.MType.MType == 4);
        PCM4Data = PCM4Data.concat(pcm4);

        const pcm5 = row.causeAndEffects.filter((causedata: any) => causedata.MType.MType == 5);
        PCM5Data = PCM5Data.concat(pcm5);

        const pcm6 = row.causeAndEffects.filter((causedata: any) => causedata.MType.MType == 6);
        PCM6Data = PCM6Data.concat(pcm6);
      }

      if (row.fiveWhy && row.fiveWhy.length) {
        if (row.stageId < 4) {
          const vcm1 = row.fiveWhy.filter((fydata: any) => fydata.MType == 1);
          VCM1Data = VCM1Data.concat(vcm1);

          const vcm2 = row.fiveWhy.filter((fydata: any) => fydata.MType == 2);
          VCM2Data = VCM2Data.concat(vcm2);

          const vcm3 = row.fiveWhy.filter((fydata: any) => fydata.MType == 3);
          VCM3Data = VCM3Data.concat(vcm3);

          const vcm4 = row.fiveWhy.filter((fydata: any) => fydata.MType == 4);
          VCM4Data = VCM4Data.concat(vcm4);

          const vcm5 = row.fiveWhy.filter((fydata: any) => fydata.MType == 5);
          VCM5Data = VCM5Data.concat(vcm5);

          const vcm6 = row.fiveWhy.filter((fydata: any) => fydata.MType == 6);
          VCM6Data = VCM6Data.concat(vcm6);
        }

        const rcm1 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "Machine");
        RCM1Data = RCM1Data.concat(rcm1);

        const rcm2 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "Material");
        RCM2Data = RCM2Data.concat(rcm2);

        const rcm3 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "Man");
        RCM3Data = RCM3Data.concat(rcm3);

        const rcm4 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "Method");
        RCM4Data = RCM4Data.concat(rcm4);

        const rcm5 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "MotherNature");
        RCM5Data = RCM5Data.concat(rcm5);

        const rcm6 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "Management");
        RCM6Data = RCM6Data.concat(rcm6);
      }
    }

    PCdata = [PCM1Data.length, PCM2Data.length, PCM3Data.length, PCM4Data.length, PCM5Data.length, PCM6Data.length];

    VCdata = [VCM1Data.length, VCM2Data.length, VCM3Data.length, VCM4Data.length, VCM5Data.length, VCM6Data.length];

    RCdata = [RCM1Data.length, RCM2Data.length, RCM3Data.length, RCM4Data.length, RCM5Data.length, RCM6Data.length];

    this.ProbableValidRootCauseChart = new Chart({
      colors: ['#70ff70', '#7171ff', '#ff6e6e'],
      chart: {
        type: 'column',
        inverted: true,
        polar: true,
        backgroundColor: 'white',
      },
      title: {
        text: '<b>Classification Of Probable Causes , Valid Causes & Root Causes</b>'
      },
      tooltip: {
        outside: true
      },
      pane: {
        size: '85%',
        innerSize: '20%',
        endAngle: 270
      },
      credits: {
        enabled: false
      },
      xAxis: {
        tickInterval: 1,
        labels: {
          align: 'right',
          useHTML: true,
          allowOverlap: true,
          step: 1,
          y: 3,
          style: {
            fontSize: '13px'
          }
        },
        // lineColor:'#777777',
        // gridLineColor:'#777777',
        lineWidth: 0,
        categories: [
          '<b>Machine</b> <span class="f16"><span id="flag" class="flag no">' +
          '</span></span>',
          '<b>Material</b> <span class="f16"><span id="flag" class="flag us">' +
          '</span></span>',
          '<b>Man</b> <span class="f16"><span id="flag" class="flag de">' +
          '</span></span>',
          '<b>Method</b> <span class="f16"><span id="flag" class="flag ca">' +
          '</span></span>',
          '<b>Mother Nature</b> <span class="f16"><span id="flag" class="flag at">' +
          '</span></span>',
          '<b>Management</b> <span class="f16"><span id="flag" class="flag at">' +
          '</span></span>'
        ]
      },
      yAxis: {
        crosshair: {
          // enabled: true,
          color: '#333'
        },
        // lineColor:'#777777',
        // gridLineColor:'#777777',
        lineWidth: 0,
        tickInterval: 25,
        reversedStacks: false,
        endOnTick: true,
        showLastLabel: true
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          borderWidth: 0,
          pointPadding: 0,
          groupPadding: 0.15
        }
      },
      series: [{
        name: 'Probable Cause',
        type: 'column',
        data: PCdata
      }, {
        name: 'Valid Cause',
        type: 'column',
        data: VCdata
      }, {
        name: 'Root Cause',
        type: 'column',
        data: RCdata
      }]
    });

  }

  setMsRootCauseByPlant() {
    let RCdata = [];

    let RCM1Data = []; let RCM2Data = []; let RCM3Data = []; let RCM4Data = []; let RCM5Data = []; let RCM6Data = [];

    for (const row of this.MsCauseDataByPlant) {
      if (row.fiveWhy && row.fiveWhy.length) {
        const rcm1 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "Machine");
        RCM1Data = RCM1Data.concat(rcm1);

        const rcm2 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "Material");
        RCM2Data = RCM2Data.concat(rcm2);

        const rcm3 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "Man");
        RCM3Data = RCM3Data.concat(rcm3);

        const rcm4 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "Method");
        RCM4Data = RCM4Data.concat(rcm4);

        const rcm5 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "MotherNature");
        RCM5Data = RCM5Data.concat(rcm5);

        const rcm6 = row.fiveWhy.filter((fydata: any) => fydata.TypeOfM == "Management");
        RCM6Data = RCM6Data.concat(rcm6);
      }
    }

    RCdata = [
      {
        y: RCM1Data.length,
        color: 'orange'
      },
      {
        y: RCM2Data.length,
        color: 'brown'
      },
      {
        y: RCM3Data.length,
        color: 'red'
      },
      {
        y: RCM4Data.length,
        color: 'blue'
      },
      {
        y: RCM5Data.length,
        color: 'green'
      },
      {
        y: RCM6Data.length,
        color: 'black'
      },
    ];

    this.MsCountChart = new Chart({
      chart: {
        polar: true,
        type: 'line',
        backgroundColor: 'white',
        zoomType: 'xy',
        borderColor: 'black'
        // zoomKey: 'z',

        // scrollablePlotArea: {
        //   minHeight: 768,
        //   minWidth: 768
        // }
      },
      title: {
        text: '<b>Classification Of Root Causes</b>',
        align: 'center',
        // textOverflow: 'ellipsis',
      },
      pane: {
        size: '90%'
      },
      xAxis: {
        labels: {
          reserveSpace: true,
          /*align: 'center',*/
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'black'
          }
        },
        categories: ['Machine', 'Material', 'Man', 'Method', 'Mother Nature', 'Management'],
        tickmarkPlacement: 'on',
        lineWidth: 0,
        lineColor: '#777777',
        gridLineColor: '#777777'
      },
      yAxis: {
        gridLineInterpolation: 'polygon',
        // tickInterval: 50,
        // minorTickInterval: 25,
        // lineWidth: 0,
        // min: 0,
        // max: 100
        lineColor: '#777777',
        gridLineColor: '#777777'
      },
      tooltip: {
        headerFormat: '<b>{point.x} : {point.y}</b><br/>',
        pointFormat: ""
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: '',
          // color: '#88a682',
          type: 'area',
          data: RCdata,
          pointPlacement: 'on'
        }
      ],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom'
            },
            pane: {
              size: '70%'
            }
          }
        }]
      }
    });

  }

  setRcaByDepartments() {
    let RcaDepartmentChart: any = [];
    let RcaCodeChart: any = [];
    let RootCauseChart = [];
    let ActionChart = [];
    let RCACodeAndRootCauseAndAction = [];

    let drillDownLevel: any = 0;

    let StatusArr = ['Open', 'InProgress', 'Closed', 'Delayed', 'Completed', 'Rejected'];

    let DeptNameForBreadCrum = '';

    if (this.RCADeptDataByPlant && this.RCADeptDataByPlant.length) {
      for (const stat of StatusArr) {
        const dataDepartment = [];
        for (const rcaDept of this.RCADeptDataByPlant) {
          DeptNameForBreadCrum = rcaDept.Name;
          let StatusArr: any = [];
          let DrilldownNameOfDeptStatus: any;
          const dataRCACode = [];
          if (stat == 'Open')
            StatusArr = rcaDept.OpenStatus;
          else if (stat == 'InProgress')
            StatusArr = rcaDept.InProgressStatus;
          else if (stat == 'Closed')
            StatusArr = rcaDept.ClosedStatus;
          else if (stat == 'Delayed')
            StatusArr = rcaDept.DelayedStatus;
          else if (stat == 'Completed')
            StatusArr = rcaDept.CompletedStatus;
          else if (stat == 'Rejected')
            StatusArr = rcaDept.RejectedStatus;

          DrilldownNameOfDeptStatus = rcaDept.Name.trim().toLowerCase() + stat.trim().toLowerCase();
          dataDepartment.push({
            name: rcaDept.Name,
            y: StatusArr.length,
            status: stat,
            drilldown: DrilldownNameOfDeptStatus
          });

          for (const stArr of StatusArr) {
            let DrilldownNameOfRCACode: any;
            const dataRootCause = [];
            if (stArr.fiveWhy && stArr.fiveWhy.length) {
              const isRootCauseFilter = stArr.fiveWhy.filter((row: any) => row.RootCause);
              if (isRootCauseFilter && isRootCauseFilter.length) {
                DrilldownNameOfRCACode = 'AT-RCA-'.trim().toLowerCase() + stArr.ID;
                dataRCACode.push({
                  name: 'AT-RCA-' + stArr.ID,
                  y: isRootCauseFilter.length,
                  initialproblem: stArr.InitProbStatement,
                  drilldown: DrilldownNameOfRCACode
                })
              }

              for (const root of isRootCauseFilter) {
                const dataActions = [];
                if (root.fiveWhyActionPlan && root.fiveWhyActionPlan.length) {
                  const isActionFilter = root.fiveWhyActionPlan.filter((row: any) => row.Action);
                  if (isActionFilter && isActionFilter.length) {
                    dataRootCause.push({
                      name: root.RootCause,
                      y: isActionFilter.length,
                      drilldown: stArr.ID + root.RootCause.trim().toLowerCase()
                    });
                  }
                  else {
                    dataRootCause.push({
                      name: root.RootCause,
                      y: 0.5,
                      color: 'lightgray',
                    });
                  }

                  for (const action of isActionFilter) {
                    const empId = action.NewAssignee ? action.NewAssignee : action.AssignTo ? action.AssignTo : '';
                    let empObj: any = '';

                    if (empId)
                      empObj = this.EmployeeList.find((row: any) => row.EmpInfo_Id == empId);

                    dataActions.push({
                      name: action.Action,
                      y: 1,
                      status: action.Status,
                      assignTo: empObj ? empObj.fullName : ''
                    })
                  }

                  ActionChart.push({
                    id: stArr.ID + root.RootCause.trim().toLowerCase(),
                    name: rcaDept.Name + ' - ' + stat + ' - ' + 'AT-RCA-' + stArr.ID + ' - ' + root.RootCause,
                    type: 'column',
                    data: dataActions
                  });
                }
                else
                  dataRootCause.push({
                    name: root.RootCause,
                    y: 0.5,
                    color: 'lightgray',
                    // drilldown: root.RootCause.trim().toLowerCase()
                  })
              }

            }
            else if (stArr.fiveWhyActionPlan && stArr.fiveWhyActionPlan.length) {
              let rootcauseWithSub = stArr.fiveWhyActionPlan.filter((row: any) => row.RootCause);
              const isRootCauseFilter: any = []
              for (const line of rootcauseWithSub) {
                if (isRootCauseFilter && isRootCauseFilter.length) {
                  const check = isRootCauseFilter.find((row: any) => row.RootCause == line.RootCause && row.FyActionId == line.FyActionId);
                  if (!check)
                    isRootCauseFilter.push(line)
                }
                else
                  isRootCauseFilter.push(line)
              }
              // const isRootCauseFilter:any = [...new Set(rootcauseWithSub.map(val => val.RootCause && val.FyActionId))];
              for (const line of isRootCauseFilter) {
                const filterRoot = rootcauseWithSub.filter((row: any) => row.RootCause == line.RootCause && row.FyActionId == line.FyActionId);
                line.ActionFilter = filterRoot
              }
              if (isRootCauseFilter && isRootCauseFilter.length) {
                DrilldownNameOfRCACode = 'AT-RCA-'.trim().toLowerCase() + stArr.ID;
                dataRCACode.push({
                  name: 'AT-RCA-' + stArr.ID,
                  y: isRootCauseFilter.length,
                  initialproblem: stArr.InitProbStatement,
                  drilldown: DrilldownNameOfRCACode
                })
              }

              for (const root of isRootCauseFilter) {
                const dataActions = [];
                if (isRootCauseFilter && isRootCauseFilter.length) {
                  const isActionFilter = root.ActionFilter.filter((row: any) => row.Action);
                  if (isActionFilter && isActionFilter.length) {
                    dataRootCause.push({
                      name: root.RootCause,
                      y: isActionFilter.length,
                      drilldown: stArr.ID + root.RootCause.trim().toLowerCase()
                    });
                  }
                  else {
                    dataRootCause.push({
                      name: root.RootCause,
                      y: 0.5,
                      color: 'lightgray',
                    });
                  }

                  for (const action of isActionFilter) {
                    const empId = action.NewAssignee ? action.NewAssignee : action.AssignTo ? action.AssignTo : '';
                    let empObj: any = '';

                    if (empId)
                      empObj = this.EmployeeList.find((row: any) => row.EmpInfo_Id == empId);

                    dataActions.push({
                      name: action.Action,
                      y: 1,
                      status: action.Status,
                      assignTo: empObj ? empObj.fullName : ''
                    })
                  }

                  ActionChart.push({
                    id: stArr.ID + root.RootCause.trim().toLowerCase(),
                    name: rcaDept.Name + ' - ' + stat + ' - ' + 'AT-RCA-' + stArr.ID + ' - ' + root.RootCause,
                    type: 'column',
                    data: dataActions
                  });
                }
                else
                  dataRootCause.push({
                    name: root.RootCause,
                    y: 0.5,
                    color: 'lightgray',
                    // drilldown: root.RootCause.trim().toLowerCase()
                  })
              }
            }
            else {
              DrilldownNameOfRCACode = 'AT-RCA-'.trim().toLowerCase() + stArr.ID;
              dataRCACode.push({
                name: 'AT-RCA-' + stArr.ID,
                y: 0.5,
                color: 'lightgray',
                initialproblem: stArr.InitProbStatement
                // drilldown: DrilldownNameOfRCACode
              })
            }

            RootCauseChart.push({
              id: DrilldownNameOfRCACode,
              name: rcaDept.Name + ' - ' + stat + ' - ' + 'AT-RCA-' + stArr.ID,
              type: 'column',
              data: dataRootCause
            })
          }

          RcaCodeChart.push({
            id: DrilldownNameOfDeptStatus,
            name: rcaDept.Name + ' - ' + stat,
            type: 'column',
            data: dataRCACode
          });

        }
        RcaDepartmentChart.push({
          name: stat,
          type: 'column',
          data: dataDepartment
        });
      }

      RCACodeAndRootCauseAndAction = RCACodeAndRootCauseAndAction.concat(RcaCodeChart, RootCauseChart, ActionChart)
    }
    let dataLabel: any;
    let assignToList: any = []
    let topLabelCount: number;

    let ChartOfRcaByDepartment: any = {
      chart: {
        type: 'column',
        events: {
          drillup: function (e: any) {
            drillDownLevel--;
            if (drillDownLevel == 3) {
              topLabelCount = 0;
              assignToList = e.seriesOptions.data
            }
            else
              assignToList = []
          },
          drilldown: function (e: any) {
            drillDownLevel++;
            if (drillDownLevel == 3) {
              topLabelCount = 0;
              assignToList = e.seriesOptions.data
            }
            else
              assignToList = []
          }
        },
        backgroundColor: 'white',
      },
      title: { text: '<b>RCAs By Departments</b>' },
      xAxis: { type: 'category' },
      yAxis: {
        allowDecimals: false,
        min: 0,
        tickInterval: 1,
        title: {
          text: ''
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: 'black',
          },
          formatter: function () {
            let topLabel = this;
            if (drillDownLevel == 1) { return '' }
            else if (drillDownLevel == 2) { return '' }
            else if (drillDownLevel == 3) {
              topLabelCount++;
              if (assignToList && assignToList.length >= topLabelCount && topLabel.total)
                return assignToList[topLabelCount - 1].assignTo
            }
            else { return '' }
          }
        },
        gridLineColor: 'transparent'
      },
      legend: {
        align: 'right',
        x: -40,
        verticalAlign: 'top',
        y: 20,
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          let ToolTip: any = this;
          if (drillDownLevel == 1) {
            if (ToolTip.total == 0.5)
              return `InitProbStatement : ${ToolTip.options.initialproblem}`;
            else
              return `Total RootCause : ${ToolTip.total}<br/>InitProbStatement : ${ToolTip.options.initialproblem}`;
          } else if (drillDownLevel == 2) {
            if (ToolTip.total == 0.5)
              return `Root Cause : ${ToolTip.options.name}`;
            else
              return `Total Actions : ${ToolTip.total}<br/>Root Cause : ${ToolTip.options.name}`;
          } else if (drillDownLevel == 3) {
            if (ToolTip.options.assignTo)
              return `Action : ${ToolTip.options.name}<br/>Action Status : ${ToolTip.options.status}<br/>Assign To : ${ToolTip.options.assignTo}`;
            else
              return `Action Status : ${ToolTip.options.status}`;
          } else {
            return `${ToolTip.status} RCA : ${ToolTip.options.y}<br/>Total RCA : ${ToolTip.total}`;
          }
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            formatter: function () {
              dataLabel = this
              if (drillDownLevel == 1) { return '' }
              else if (drillDownLevel == 2) { return '' }
              else if (drillDownLevel == 3) { return `${dataLabel.point.options.status}` }
              else { if (dataLabel.point.options.y) return dataLabel.point.options.status }
            },
          }
        }
      },
      series: RcaDepartmentChart,
      drilldown: {
        // breadcrumbs: {
        //   buttonTheme: {
        //     fill: '#f7f7f7',
        //     padding: 8,
        //     stroke: '#cccccc',
        //     'stroke-width': 1
        //   },
        //   floating: true,
        //   position: {
        //     align: 'right'
        //   },
        //   showFullPath: true
        // },
        series: RCACodeAndRootCauseAndAction
      }
    }
    this.RcaByDepartmentChart = new Chart(ChartOfRcaByDepartment);

  }

  setRcaByPeoples() {
    let PeopleParticipatedChart: any = [];
    let CategoryName: any = [];
    let CategoryOrgName: any = [];
    let RCAData: any = []
    let ActionData: any = []
    let drillDownLevel = 0;

    let BothDrilldownData: any = []

    for (const peopleData of this.PeopleParticipantsDataByPlant) {
      let RCADrilldownData: any = []
      let ActionDrilldownData: any = []

      let LeadAndParticipatedDrilldownDataRCA: any = []

      let StatusDrilldownDataRCA: any = []
      let StatusDrilldownDataAction: any = []

      let ledStatusDrilldownDataRCA: any = []
      let participatedStatusDrilldownDataRCA: any = []

      RCAData.push({
        name: 'Statuses',
        y: peopleData.TotalRCA.length,
        lead: peopleData.LeadRCA.length,
        participant: peopleData.PartcipantRCA.length,
        drilldown: peopleData.UserName.trim().toLowerCase() + 'rca'
      });

      ActionData.push({
        name: 'Statuses',
        y: peopleData.Actions.length,
        drilldown: peopleData.UserName.trim().toLowerCase() + 'action'
      })


      // for (const totRca of peopleData.TotalRCA) {
      //   let color: any;
      //   if (totRca.StatusCycle == 'Open')
      //     color = '#808080'
      //   else if (totRca.StatusCycle == 'InProgress')
      //     color = '#ffa500'
      //   else if (totRca.StatusCycle == 'NotClosed' || totRca.StatusCycle == 'Complete') {
      //     color = '#1cd51c'
      //     totRca.StatusCycle = 'Complete'
      //   }
      //   else if (totRca.StatusCycle == 'Completed' || totRca.StatusCycle == 'Closed') {
      //     color = '#008000'
      //     totRca.StatusCycle = 'Closed'
      //   }
      //   else if (totRca.StatusCycle == 'Delayed')
      //     color = '#a52a2a'
      //   else if (totRca.StatusCycle == 'Rejected')
      //     color = '#ff0000'

      //   RCADrilldownData.push({
      //     name: 'AT-RCA-' + totRca.ID,
      //     color: color,
      //     y: 1,
      //     status: totRca.StatusCycle,
      //     role: totRca.TeamLeader == peopleData.UserId ? 'Lead' : 'Participant'
      //   });
      // }

      let RCALedStatusArr = [...new Set(peopleData.LeadRCA.map(val => val.StatusCycle))];

      const rcaLedStatusOpen = peopleData.LeadRCA.filter((row: any) => row.StatusCycle == 'Open');
      const rcaLedStatusInProgress = peopleData.LeadRCA.filter((row: any) => row.StatusCycle == 'InProgress');
      const rcaLedStatusComplete = peopleData.LeadRCA.filter((row: any) => row.StatusCycle == 'Complete' || row.StatusCycle == 'NotClosed');
      const rcaLedStatusClosed = peopleData.LeadRCA.filter((row: any) => row.StatusCycle == 'Closed' || row.StatusCycle == 'Completed');
      const rcaLedStatusDelayed = peopleData.LeadRCA.filter((row: any) => row.StatusCycle == 'Delayed');
      const rcaLedStatusRejected = peopleData.LeadRCA.filter((row: any) => row.StatusCycle == 'Rejected');


      let RCAParticipatedStatusArr = [...new Set(peopleData.PartcipantRCA.map(val => val.StatusCycle))];

      const rcaParticipatedStatusOpen = peopleData.PartcipantRCA.filter((row: any) => row.StatusCycle == 'Open');
      const rcaParticipatedStatusInProgress = peopleData.PartcipantRCA.filter((row: any) => row.StatusCycle == 'InProgress');
      const rcaParticipatedStatusComplete = peopleData.PartcipantRCA.filter((row: any) => row.StatusCycle == 'Complete' || row.StatusCycle == 'NotClosed');
      const rcaParticipatedStatusClosed = peopleData.PartcipantRCA.filter((row: any) => row.StatusCycle == 'Closed' || row.StatusCycle == 'Completed');
      const rcaParticipatedStatusDelayed = peopleData.PartcipantRCA.filter((row: any) => row.StatusCycle == 'Delayed');
      const rcaParticipatedStatusRejected = peopleData.PartcipantRCA.filter((row: any) => row.StatusCycle == 'Rejected');


      for (const status of RCALedStatusArr) {
        let statusCount = 0;
        let color = ''
        // let RCAStatusDrilldownData: any = []

        let RCALedStatusDrilldownData: any = []

        if (status == 'Open') {
          statusCount = rcaLedStatusOpen && rcaLedStatusOpen.length ? rcaLedStatusOpen.length : 0;
          color = '#808080';
          if (rcaLedStatusOpen && rcaLedStatusOpen.length) {
            for (const rcastopen of rcaLedStatusOpen) {
              RCALedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastopen.ID,
                y: 1,
                color: color,
                initialproblem: rcastopen.InitProbStatement,
                role: rcastopen.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }
        else if (status == 'InProgress') {
          statusCount = rcaLedStatusInProgress && rcaLedStatusInProgress.length ? rcaLedStatusInProgress.length : 0;
          color = '#ffa500';
          if (rcaLedStatusInProgress && rcaLedStatusInProgress.length) {
            for (const rcastinprogress of rcaLedStatusInProgress) {
              RCALedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastinprogress.ID,
                y: 1,
                color: color,
                initialproblem: rcastinprogress.InitProbStatement,
                role: rcastinprogress.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }
        else if (status == 'Complete' || status == 'NotClosed') {
          statusCount = rcaLedStatusComplete && rcaLedStatusComplete.length ? rcaLedStatusComplete.length : 0;
          color = '#1cd51c';
          if (rcaLedStatusComplete && rcaLedStatusComplete.length) {
            for (const rcastcomplete of rcaLedStatusComplete) {
              RCALedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastcomplete.ID,
                y: 1,
                color: color,
                initialproblem: rcastcomplete.InitProbStatement,
                role: rcastcomplete.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }
        else if (status == 'Closed' || status == 'Completed') {
          statusCount = rcaLedStatusClosed && rcaLedStatusClosed.length ? rcaLedStatusClosed.length : 0;
          color = '#008000';
          if (rcaLedStatusClosed && rcaLedStatusClosed.length) {
            for (const rcastclosed of rcaLedStatusClosed) {
              RCALedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastclosed.ID,
                y: 1,
                color: color,
                initialproblem: rcastclosed.InitProbStatement,
                role: rcastclosed.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }
        else if (status == 'Delayed') {
          statusCount = rcaLedStatusDelayed && rcaLedStatusDelayed.length ? rcaLedStatusDelayed.length : 0;
          color = '#a52a2a';
          if (rcaLedStatusDelayed && rcaLedStatusDelayed.length) {
            for (const rcastdelayed of rcaLedStatusDelayed) {
              RCALedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastdelayed.ID,
                y: 1,
                color: color,
                initialproblem: rcastdelayed.InitProbStatement,
                role: rcastdelayed.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }
        else if (status == 'Rejected') {
          statusCount = rcaLedStatusRejected && rcaLedStatusRejected.length ? rcaLedStatusRejected.length : 0;
          color = '#ff0000';
          if (rcaLedStatusRejected && rcaLedStatusRejected.length) {
            for (const rcastrejected of rcaLedStatusRejected) {
              RCALedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastrejected.ID,
                y: 1,
                color: color,
                initialproblem: rcastrejected.InitProbStatement,
                role: rcastrejected.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }


        ledStatusDrilldownDataRCA.push({
          name: status,
          color: color,
          y: statusCount,
          totalrca: peopleData.TotalRCA.length,
          totalleadrca: peopleData.LeadRCA.length,
          drilldown: peopleData.UserName.trim().toLowerCase() + 'rca led ' + status
        });

        const RCALedStatusDrillObj = {
          id: peopleData.UserName.trim().toLowerCase() + 'rca led ' + status,
          name: status + ' - RCA',
          type: 'column',
          color: '#ffffff',
          data: RCALedStatusDrilldownData
        }

        BothDrilldownData.push(RCALedStatusDrillObj)
      }
      for (const status of RCAParticipatedStatusArr) {
        let statusCount = 0;
        let color = ''
        // let RCAStatusDrilldownData: any = []

        let RCAParticipatedStatusDrilldownData: any = []

        if (status == 'Open') {
          statusCount = rcaParticipatedStatusOpen && rcaParticipatedStatusOpen.length ? rcaParticipatedStatusOpen.length : 0;
          color = '#808080';
          if (rcaParticipatedStatusOpen && rcaParticipatedStatusOpen.length) {
            for (const rcastopen of rcaParticipatedStatusOpen) {
              RCAParticipatedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastopen.ID,
                y: 1,
                color: color,
                initialproblem: rcastopen.InitProbStatement,
                role: rcastopen.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }
        else if (status == 'InProgress') {
          statusCount = rcaParticipatedStatusInProgress && rcaParticipatedStatusInProgress.length ? rcaParticipatedStatusInProgress.length : 0;
          color = '#ffa500';
          if (rcaParticipatedStatusInProgress && rcaParticipatedStatusInProgress.length) {
            for (const rcastinprogress of rcaParticipatedStatusInProgress) {
              RCAParticipatedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastinprogress.ID,
                y: 1,
                color: color,
                initialproblem: rcastinprogress.InitProbStatement,
                role: rcastinprogress.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }
        else if (status == 'Complete' || status == 'NotClosed') {
          statusCount = rcaParticipatedStatusComplete && rcaParticipatedStatusComplete.length ? rcaParticipatedStatusComplete.length : 0;
          color = '#1cd51c';
          if (rcaParticipatedStatusComplete && rcaParticipatedStatusComplete.length) {
            for (const rcastcomplete of rcaParticipatedStatusComplete) {
              RCAParticipatedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastcomplete.ID,
                y: 1,
                color: color,
                initialproblem: rcastcomplete.InitProbStatement,
                role: rcastcomplete.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }
        else if (status == 'Closed' || status == 'Completed') {
          statusCount = rcaParticipatedStatusClosed && rcaParticipatedStatusClosed.length ? rcaParticipatedStatusClosed.length : 0;
          color = '#008000';
          if (rcaParticipatedStatusClosed && rcaParticipatedStatusClosed.length) {
            for (const rcastclosed of rcaParticipatedStatusClosed) {
              RCAParticipatedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastclosed.ID,
                y: 1,
                color: color,
                initialproblem: rcastclosed.InitProbStatement,
                role: rcastclosed.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }
        else if (status == 'Delayed') {
          statusCount = rcaParticipatedStatusDelayed && rcaParticipatedStatusDelayed.length ? rcaParticipatedStatusDelayed.length : 0;
          color = '#a52a2a';
          if (rcaParticipatedStatusDelayed && rcaParticipatedStatusDelayed.length) {
            for (const rcastdelayed of rcaParticipatedStatusDelayed) {
              RCAParticipatedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastdelayed.ID,
                y: 1,
                color: color,
                initialproblem: rcastdelayed.InitProbStatement,
                role: rcastdelayed.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }
        else if (status == 'Rejected') {
          statusCount = rcaParticipatedStatusRejected && rcaParticipatedStatusRejected.length ? rcaParticipatedStatusRejected.length : 0;
          color = '#ff0000';
          if (rcaParticipatedStatusRejected && rcaParticipatedStatusRejected.length) {
            for (const rcastrejected of rcaParticipatedStatusRejected) {
              RCAParticipatedStatusDrilldownData.push({
                name: 'AT-RCA-' + rcastrejected.ID,
                y: 1,
                color: color,
                initialproblem: rcastrejected.InitProbStatement,
                role: rcastrejected.TeamLeader == peopleData.UserId ? 'Led' : 'Participated'
              })
            }
          }
        }


        participatedStatusDrilldownDataRCA.push({
          name: status,
          color: color,
          y: statusCount,
          totalrca: peopleData.TotalRCA.length,
          totalparticipantrca: peopleData.PartcipantRCA.length,
          drilldown: peopleData.UserName.trim().toLowerCase() + 'rca participated ' + status
        });

        const RCAParticipatedStatusDrillObj = {
          id: peopleData.UserName.trim().toLowerCase() + 'rca participated ' + status,
          name: status + ' - RCA',
          type: 'column',
          color: '#ffffff',
          data: RCAParticipatedStatusDrilldownData
        }

        BothDrilldownData.push(RCAParticipatedStatusDrillObj)
      }

      const RCALedDrillObj = {
        id: peopleData.UserName.trim().toLowerCase() + 'rca led',
        name: 'Statuses - RCA',
        type: 'column',
        color: '#ffffff',
        data: ledStatusDrilldownDataRCA
      }
      const RCAParticipatedDrillObj = {
        id: peopleData.UserName.trim().toLowerCase() + 'rca participated',
        name: 'Statuses - RCA',
        type: 'column',
        color: '#ffffff',
        data: participatedStatusDrilldownDataRCA
      }

      BothDrilldownData.push(RCALedDrillObj)
      BothDrilldownData.push(RCAParticipatedDrillObj)

      // BothDrilldownData.push(ledStatusDrilldownDataRCA)
      // BothDrilldownData.push(participatedStatusDrilldownDataRCA)

      // for (const action of peopleData.Actions) {
      //   let color: any;
      //   if (action.Status == 'Open')
      //     color = '#808080'
      //   else if (action.Status == 'InProgress')
      //     color = '#ffa500'
      //   else if (action.Status == 'Complete')
      //     color = '#1cd51c'
      //   else if (action.Status == 'Closed')
      //     color = '#008000'
      //   else if (action.Status == 'Delayed' || action.Status == 'Delay')
      //     color = '#a52a2a'
      //   else if (action.Status == 'ReOpened')
      //     color = '#ff0000'

      //   ActionDrilldownData.push({
      //     name: 'AT-RCA-' + action.HeaderId,
      //     color: color,
      //     y: 1,
      //     status: action.Status,
      //     action: action.Action
      //   });
      // }

      let ActionStatusArr = [...new Set(peopleData.Actions.map(val => val.Status))];

      const actionStatusOpen = peopleData.Actions.filter((row: any) => row.Status == 'Open');
      const actionStatusInProgress = peopleData.Actions.filter((row: any) => row.Status == 'InProgress');
      const actionStatusComplete = peopleData.Actions.filter((row: any) => row.Status == 'Complete');
      const actionStatusClosed = peopleData.Actions.filter((row: any) => row.Status == 'Closed');
      const actionStatusDelayed = peopleData.Actions.filter((row: any) => row.Status == 'Delayed' || row.Status == 'Delay');
      const actionStatusReopened = peopleData.Actions.filter((row: any) => row.Status == 'ReOpened');

      for (const status of ActionStatusArr) {
        let statusCount = 0;
        let color = ''
        let ActionStatusDrilldownData: any = []

        if (status == 'Open') {
          statusCount = actionStatusOpen && actionStatusOpen.length ? actionStatusOpen.length : 0;
          color = '#808080';
          if (actionStatusOpen && actionStatusOpen.length) {
            for (const acstopen of actionStatusOpen) {
              ActionStatusDrilldownData.push({
                name: 'AT-RCA-' + acstopen.HeaderId,
                y: 1,
                action: acstopen.Action,
                initialproblem: acstopen.InitProbStatement,
                status: status
              })
            }
          }
        }
        else if (status == 'InProgress') {
          statusCount = actionStatusInProgress && actionStatusInProgress.length ? actionStatusInProgress.length : 0;
          color = '#ffa500';
          if (actionStatusInProgress && actionStatusInProgress.length) {
            for (const acstinprogress of actionStatusInProgress) {
              ActionStatusDrilldownData.push({
                name: 'AT-RCA-' + acstinprogress.HeaderId,
                y: 1,
                action: acstinprogress.Action,
                initialproblem: acstinprogress.InitProbStatement,
                status: status
              })
            }
          }
        }
        else if (status == 'Complete') {
          statusCount = actionStatusComplete && actionStatusComplete.length ? actionStatusComplete.length : 0;
          color = '#1cd51c';
          if (actionStatusComplete && actionStatusComplete.length) {
            for (const acstcomplete of actionStatusComplete) {
              ActionStatusDrilldownData.push({
                name: 'AT-RCA-' + acstcomplete.HeaderId,
                y: 1,
                action: acstcomplete.Action,
                initialproblem: acstcomplete.InitProbStatement,
                status: status
              })
            }
          }
        }
        else if (status == 'Closed') {
          statusCount = actionStatusClosed && actionStatusClosed.length ? actionStatusClosed.length : 0;
          color = '#008000';
          if (actionStatusClosed && actionStatusClosed.length) {
            for (const acstclosed of actionStatusClosed) {
              ActionStatusDrilldownData.push({
                name: 'AT-RCA-' + acstclosed.HeaderId,
                y: 1,
                action: acstclosed.Action,
                initialproblem: acstclosed.InitProbStatement,
                status: status
              })
            }
          }
        }
        else if (status == 'Delayed' || status == 'Delay') {
          statusCount = actionStatusDelayed && actionStatusDelayed.length ? actionStatusDelayed.length : 0;
          color = '#a52a2a';
          if (actionStatusDelayed && actionStatusDelayed.length) {
            for (const acstdelayed of actionStatusDelayed) {
              ActionStatusDrilldownData.push({
                name: 'AT-RCA-' + acstdelayed.HeaderId,
                y: 1,
                action: acstdelayed.Action,
                initialproblem: acstdelayed.InitProbStatement,
                status: status
              })
            }
          }
        }
        else if (status == 'ReOpened') {
          statusCount = actionStatusReopened && actionStatusReopened.length ? actionStatusReopened.length : 0;
          color = '#ff0000';
          if (actionStatusReopened && actionStatusReopened.length) {
            for (const acstreopened of actionStatusReopened) {
              ActionStatusDrilldownData.push({
                name: 'AT-RCA-' + acstreopened.HeaderId,
                y: 1,
                action: acstreopened.Action,
                initialproblem: acstreopened.InitProbStatement,
                status: status
              })
            }
          }
        }

        StatusDrilldownDataAction.push({
          name: status,
          color: color,
          y: statusCount,
          totalaction: peopleData.Actions.length,
          drilldown: peopleData.UserName.trim().toLowerCase() + status + 'action'
        });

        const ActionStatusDrillObj = {
          id: peopleData.UserName.trim().toLowerCase() + status + 'action',
          name: status + ' - Action',
          color: '#ff0000',
          type: 'column',
          data: ActionStatusDrilldownData
        }

        BothDrilldownData.push(ActionStatusDrillObj)

      }

      const RcaDrillObj = {
        id: peopleData.UserName.trim().toLowerCase() + 'rca',
        name: 'Led / Participated' + ' - RCA',
        color: '#ffffff',
        type: 'column',
        data: [
          {
            name: 'Led',
            color: '#8085e9',
            y: peopleData.LeadRCA.length,
            totalrca: peopleData.TotalRCA.length,
            drilldown: peopleData.UserName.trim().toLowerCase() + 'rca led'
          },
          {
            name: 'Participated',
            color: '#f15c80',
            y: peopleData.PartcipantRCA.length,
            totalrca: peopleData.TotalRCA.length,
            drilldown: peopleData.UserName.trim().toLowerCase() + 'rca participated'
          }
        ]
      };
      const ActionDrillObj = {
        id: peopleData.UserName.trim().toLowerCase() + 'action',
        name: 'Statuses' + ' - Action',
        color: '#ffffff',
        type: 'column',
        data: StatusDrilldownDataAction
      };

      BothDrilldownData.push(RcaDrillObj)
      BothDrilldownData.push(ActionDrillObj)
    }

    CategoryName = this.PeopleParticipantsDataByPlant.map((row: any) => row.UserName);
    CategoryOrgName = this.PeopleParticipantsDataByPlant.map((row: any) => row.UserName);
    let drillDownStatus: boolean = false;
    let drillDownName: any;

    let ChartOfRcaByPeople: any = {
      chart: {
        type: 'column',
        events: {
          drillup: function (e: any) {
            drillDownLevel--;
            drillDownStatus = false;
            if (drillDownLevel == 1 || drillDownLevel == 2 || drillDownLevel == 3) {
              if (e.point) {
                let CategoryObj = BothDrilldownData.find((row: any) => row.id == e.point.drilldown);
                CategoryName = CategoryObj.data.map((row: any) => row.name);
              }
              else if (e.seriesOptions) {
                CategoryName = e.seriesOptions.data.map((row: any) => row.name);
              }
            }
            else if (drillDownLevel == 0) {
              CategoryName = CategoryOrgName
            }
            for (let index = 0; index < CategoryName.length; index++)
              this.xAxis[0].categories[index] = CategoryName[index]

          },
          drilldown: function (e: any) {
            drillDownLevel++;
            drillDownStatus = true;
            if (drillDownLevel == 1 || drillDownLevel == 2 || drillDownLevel == 3) {
              if (e.point) {
                let CategoryObj = BothDrilldownData.find((row: any) => row.id == e.point.drilldown);
                CategoryName = CategoryObj.data.map((row: any) => row.name);
              }
              else if (e.seriesOptions) {
                CategoryName = e.seriesOptions.data.map((row: any) => row.name);
              }
            }
            else if (drillDownLevel == 0) {
              CategoryName = CategoryOrgName
            }
            for (let index = 0; index < CategoryName.length; index++)
              this.xAxis[0].categories[index] = CategoryName[index]

          },
        },
        backgroundColor: 'white',
      },
      title: {
        text: '<b>People Involvement</b>'
      },
      xAxis: {
        categories: CategoryName,
        events: {

        },
        title: {
          text: null
        },
      },
      yAxis: {
        allowDecimals: false,
        tickInterval: 1,
        min: 0,
        title: {
          text: '',
        },
        labels: {
          overflow: 'justify'
        },
        gridLineColor: 'transparent'
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          let ToolTip: any = this;
          if (drillDownLevel == 1) {
            let check = ToolTip.series.options.name.split('-');
            if (check[1] && check[1].trim() == 'Action')
              return `Total Actions : ${ToolTip.options.totalaction}</br>Total ${ToolTip.options.name} : ${ToolTip.options.y}`;
            else
              return `Total RCAs : ${ToolTip.options.totalrca}</br>Total ${ToolTip.options.name} RCAs : ${ToolTip.options.y}`;
          }
          else if (drillDownLevel == 2) {
            let check = ToolTip.series.options.name.split('-');
            if (check[1] && check[1].trim() == 'Action')
              return `${ToolTip.options.name}<br/>Action : ${ToolTip.options.action}<br/>InitProbStatement : ${ToolTip.options.initialproblem}`;
            else {
              if (ToolTip.options.totalleadrca) {
                return `Total RCAs : ${ToolTip.options.totalrca}</br>Total Led RCAs : ${ToolTip.options.totalleadrca}</br>Total ${ToolTip.options.name} : ${ToolTip.options.y} `;
              }
              else {
                return `Total RCAs : ${ToolTip.options.totalrca}</br>Total Participated RCAs : ${ToolTip.options.totalparticipantrca}</br>Total ${ToolTip.options.name} : ${ToolTip.options.y} `;
              }
            }
          }
          else if (drillDownLevel == 3) {
            let check = ToolTip.series.options.name.split('-');
            if (check[1] && check[1].trim() == 'Action')
              return ``;
            else
              return `${ToolTip.options.name}<br/>InitProbStatement : ${ToolTip.options.initialproblem}`;
          }
          else if (drillDownLevel == 0) {
            if (ToolTip.options.participant || ToolTip.options.lead)
              return `Total RCAs : ${ToolTip.options.y}<br/>Total Participated RCAs : ${ToolTip.options.participant}<br/>Total Led RCAs : ${ToolTip.options.lead}`;
            else
              return `Total Actions : ${ToolTip.options.y}`;
          }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            formatter: function () {
              let ToolTip: any = this;
              if (drillDownLevel == 1) {
                let check = ToolTip.series.name.split('-');
                if (check[1] && check[1].trim() == 'Action')
                  return `${ToolTip.point.options.y}`;
                else
                  return `${ToolTip.point.options.y}`;
              }
              else if (drillDownLevel == 2) {
                let check = ToolTip.series.name.split('-');
                if (check[1] && check[1].trim() == 'Action')
                  return ``;
                else
                  return `${ToolTip.point.options.y}`;
              }
              else if (drillDownLevel == 3) {
                let check = ToolTip.series.name.split('-');
                if (check[1] && check[1].trim() == 'Action')
                  return ``;
                else
                  return ``;
              }
              else if (drillDownLevel == 0) {
                let check = ToolTip.series.name;
                if (check == 'Actions')
                  return `${ToolTip.point.options.y}`;
                else
                  return `${ToolTip.point.options.y}`;
              }
            },
          }
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 20,
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      series: [{
        name: 'Total RCAs',
        type: 'column',
        data: RCAData
      },
      {
        name: 'Total Actions',
        type: 'column',
        data: ActionData,
      }],
      drilldown: {
        series: BothDrilldownData
      }
    }
    this.RcaByPeopleChart = new Chart(ChartOfRcaByPeople);

  }

  setRcaToolsByDepartment() {
    let RcaToolsDepartmentChart: any = [];
    let RcaToolsStatusChart: any = [];

    let drillDownLevel: any = 0;

    let ToolsArr = ['6W2H', 'Fishbone', 'Cause&Effect', '5Why', 'Action Plan'];
    for (const tool of ToolsArr) {
      const dataDepartment = [];
      for (const rcaDept of this.RCADeptDataByPlant) {
        let ToolArr: any = [];
        let Status: any;
        let DrilldownNameOfDeptTools: any;
        let StatusDrilldownDataTool: any = []

        const dataRCATool: any = [];

        if (tool == '6W2H')
          ToolArr = rcaDept.SixWTwoH;
        else if (tool == 'Fishbone')
          ToolArr = rcaDept.FishBone;
        else if (tool == 'Cause&Effect')
          ToolArr = rcaDept.CauseAndEffect;
        else if (tool == '5Why')
          ToolArr = rcaDept.FiveWhy;
        else if (tool == 'Action Plan')
          ToolArr = rcaDept.FiveWhyAction;

        DrilldownNameOfDeptTools = rcaDept.Name.trim().toLowerCase() + tool.trim().toLowerCase();
        dataDepartment.push({
          name: rcaDept.Name,
          y: ToolArr.length,
          tool: tool,
          drilldown: DrilldownNameOfDeptTools
        });

        let ToolStatusArr: any = []
        let toolStatusOpen: any = []
        let toolStatusInProgress: any = []
        let toolStatusCompleted: any = []

        if (tool == '6W2H') {
          ToolStatusArr = [...new Set(ToolArr.map(val => val.Status6W2H))];

          toolStatusOpen = ToolArr.filter((row: any) => row.Status6W2H == 'Open');
          toolStatusInProgress = ToolArr.filter((row: any) => row.Status6W2H == 'InProgress');
          toolStatusCompleted = ToolArr.filter((row: any) => row.Status6W2H == 'Completed');
        }
        else if (tool == 'Fishbone') {
          ToolStatusArr = [...new Set(ToolArr.map(val => val.StatusFishbone))];

          toolStatusOpen = ToolArr.filter((row: any) => row.StatusFishbone == 'Open');
          toolStatusInProgress = ToolArr.filter((row: any) => row.StatusFishbone == 'InProgress');
          toolStatusCompleted = ToolArr.filter((row: any) => row.StatusFishbone == 'Completed');
        }
        else if (tool == 'Cause&Effect') {
          ToolStatusArr = [...new Set(ToolArr.map(val => val.StatusCauseEffect))];

          toolStatusOpen = ToolArr.filter((row: any) => row.StatusCauseEffect == 'Open');
          toolStatusInProgress = ToolArr.filter((row: any) => row.StatusCauseEffect == 'InProgress');
          toolStatusCompleted = ToolArr.filter((row: any) => row.StatusCauseEffect == 'Completed');

        }
        else if (tool == '5Why') {
          ToolStatusArr = [...new Set(ToolArr.map(val => val.StatusFiveWhy))];

          toolStatusOpen = ToolArr.filter((row: any) => row.StatusFiveWhy == 'Open');
          toolStatusInProgress = ToolArr.filter((row: any) => row.StatusFiveWhy == 'InProgress');
          toolStatusCompleted = ToolArr.filter((row: any) => row.StatusFiveWhy == 'Completed');

        }
        else if (tool == 'Action Plan') {
          ToolStatusArr = [...new Set(ToolArr.map(val => val.StatusFiveWhyAction))];

          toolStatusOpen = ToolArr.filter((row: any) => row.StatusFiveWhyAction == 'Open');
          toolStatusInProgress = ToolArr.filter((row: any) => row.StatusFiveWhyAction == 'InProgress');
          toolStatusCompleted = ToolArr.filter((row: any) => row.StatusFiveWhyAction == 'Completed');
        }


        for (let status of ToolStatusArr) {
          let statusCount = 0;
          let color = ''
          let ToolStatusDrilldownData: any = []

          if (status == 'Open') {
            statusCount = toolStatusOpen && toolStatusOpen.length ? toolStatusOpen.length : 0;
            color = '#808080';
            if (toolStatusOpen && toolStatusOpen.length) {
              for (const tolstopen of toolStatusOpen) {
                ToolStatusDrilldownData.push({
                  name: 'AT-RCA-' + tolstopen.ID,
                  y: 1,
                  initialproblem: tolstopen.InitProbStatement,
                })
              }
            }
          }
          else if (status == 'InProgress') {
            statusCount = toolStatusInProgress && toolStatusInProgress.length ? toolStatusInProgress.length : 0;
            color = '#ffa500';
            if (toolStatusInProgress && toolStatusInProgress.length) {
              for (const tolstinprogress of toolStatusInProgress) {
                ToolStatusDrilldownData.push({
                  name: 'AT-RCA-' + tolstinprogress.ID,
                  y: 1,
                  initialproblem: tolstinprogress.InitProbStatement,
                })
              }
            }
          }
          else if (status == 'Completed') {
            statusCount = toolStatusCompleted && toolStatusCompleted.length ? toolStatusCompleted.length : 0;
            color = '#1cd51c';
            if (toolStatusCompleted && toolStatusCompleted.length) {
              for (const tolstcomplete of toolStatusCompleted) {
                ToolStatusDrilldownData.push({
                  name: 'AT-RCA-' + tolstcomplete.ID,
                  y: 1,
                  initialproblem: tolstcomplete.InitProbStatement,
                })
              }
            }
          }
          // else if (status == 'Closed' || status == 'Completed') {
          //   statusCount = toolStatusClosed && toolStatusClosed.length ? toolStatusClosed.length : 0;
          //   color = '#008000';
          //   if (toolStatusClosed && toolStatusClosed.length) {
          //     for (const tolstclosed of toolStatusClosed) {
          //       ToolStatusDrilldownData.push({
          //         name: 'AT-RCA-' + tolstclosed.ID,
          //         y: 1,
          //         initialproblem: tolstclosed.InitProbStatement,
          //       })
          //     }
          //   }
          //   status = 'Closed';
          // }
          // else if (status == 'Delayed') {
          //   statusCount = toolStatusDelayed && toolStatusDelayed.length ? toolStatusDelayed.length : 0;
          //   color = '#a52a2a';
          //   if (toolStatusDelayed && toolStatusDelayed.length) {
          //     for (const tolstdelayed of toolStatusDelayed) {
          //       ToolStatusDrilldownData.push({
          //         name: 'AT-RCA-' + tolstdelayed.ID,
          //         y: 1,
          //         initialproblem: tolstdelayed.InitProbStatement,
          //       })
          //     }
          //   }
          // }
          // else if (status == 'Rejected') {
          //   statusCount = toolStatusRejected && toolStatusRejected.length ? toolStatusRejected.length : 0;
          //   color = '#ff0000';
          //   if (toolStatusRejected && toolStatusRejected.length) {
          //     for (const tolstrejected of toolStatusRejected) {
          //       ToolStatusDrilldownData.push({
          //         name: 'AT-RCA-' + tolstrejected.ID,
          //         y: 1,
          //         initialproblem: tolstrejected.InitProbStatement,
          //       })
          //     }
          //   }
          // }


          StatusDrilldownDataTool.push({
            name: status,
            color: color,
            y: statusCount,
            tool: tool,
            totaltool: ToolArr.length,
            drilldown: rcaDept.Name.trim().toLowerCase() + tool.trim().toLowerCase() + status
          });

          const ToolStatusDrillObj = {
            id: rcaDept.Name.trim().toLowerCase() + tool.trim().toLowerCase() + status,
            name: rcaDept.Name.trim() + ' - ' + tool.trim() + ' - ' + status,
            type: 'column',
            data: ToolStatusDrilldownData
          }

          RcaToolsStatusChart.push(ToolStatusDrillObj)

        }

        // for (const tol of ToolArr) {
        //   let toolStatus: any;
        //   let toolFrom: any;

        //   if (tool == '6W2H')
        //     toolStatus = tol.Status6W2H;
        //   else if (tool == 'Fishbone')
        //     toolStatus = tol.StatusFishbone;
        //   else if (tool == 'Cause&Effect')
        //     toolStatus = tol.StatusCauseEffect;
        //   else if (tool == '5Why')
        //     toolStatus = tol.StatusFiveWhy;
        //   else if (tool == 'Action Plan')
        //     toolStatus = tol.StatusFiveWhyAction;

        //   if (tol.stageId == 1)
        //     toolFrom = '6W2H';
        //   else if (tol.stageId == 2)
        //     toolFrom = 'FishBone';
        //   else if (tol.stageId == 3)
        //     toolFrom = 'Cause & Effect';
        //   else if (tol.stageId == 4)
        //     toolFrom = '5 Why';
        //   else if (tol.stageId == 5)
        //     toolFrom = 'Action Plan';

        //   dataRCATool.push({
        //     name: 'AT-RCA-' + tol.ID,
        //     y: 1,
        //     status: toolStatus,
        //     from: toolFrom
        //   });


        // }

        RcaToolsStatusChart.push({
          id: DrilldownNameOfDeptTools,
          name: tool + ' - ' + 'Statuses',
          type: 'column',
          data: StatusDrilldownDataTool
        });
      }
      RcaToolsDepartmentChart.push({
        name: tool,
        type: 'column',
        data: dataDepartment
      });
    }
    let dataLabel: any;
    let topLabelCount: any;
    let toolCountArr: any;

    let ChartOfRcaToolsByDepartment: any = {
      chart: {
        type: 'column',
        events: {
          drillup: function (e: any) {
            drillDownLevel--;
            if (drillDownLevel == 1) {
              topLabelCount = 0;
              toolCountArr = e.seriesOptions.data
            }
            else
              toolCountArr = []
          },
          drilldown: function (e: any) {
            drillDownLevel++;
            if (drillDownLevel == 1) {
              topLabelCount = 0;
              toolCountArr = e.seriesOptions.data
            }
            else
              toolCountArr = []
          }
        },
        backgroundColor: 'white',
      },
      title: { text: '<b>RCA Tools By Departments</b>' },
      // subtitle: {
      //   text: '* Footnote aligned right',
      //   align: 'left',
      //   x: 15
      // },
      xAxis: { type: 'category' },
      yAxis: {
        allowDecimals: false,
        tickInterval: 1,
        min: 0,
        title: {
          text: ''
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: 'black',
          },
          formatter: function () {
            let topLabel = this;
            if (drillDownLevel == 1) {
              topLabelCount++;
              if (toolCountArr && toolCountArr.length >= topLabelCount && topLabel.total)
                return toolCountArr[topLabelCount - 1].y
            }
            else if (drillDownLevel == 2) { return '' }
            else if (drillDownLevel == 3) { return '' }
            else { return '' }
          }
        },
        gridLineColor: 'transparent'
      },
      legend: {
        align: 'right',
        x: -40,
        verticalAlign: 'top',
        y: 20,
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      credits: {
        enabled: false
      },
      // tooltip: {
      //   // headerFormat: '<b>{point.x}</b><br/>',
      //   pointFormat: "{series.name}RCA: {point.y}<br/>TotalRCA: {point.stackTotal}"
      // },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          let ToolTip: any = this;
          if (drillDownLevel == 1) {
            return `Total ${ToolTip.options.tool} : ${ToolTip.options.totaltool}</br>Total ${ToolTip.options.name} : ${ToolTip.options.y}`;
          }
          else if (drillDownLevel == 2) {
            return `InitProbStatement : ${ToolTip.options.initialproblem}`;
          }
          else if (drillDownLevel == 0) {
            return `${ToolTip.tool} RCA : ${ToolTip.options.y}<br/>Total RCA By Stages : ${ToolTip.total}`;
          }
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            // color: 'red',
            // style: {
            //   fontWeight: 'bold',
            // },
            formatter: function () {
              dataLabel = this
              if (drillDownLevel == 1) { return `` }
              else if (drillDownLevel == 2) { return '' }
              else if (drillDownLevel == 3) { return `` }
              else { if (dataLabel.point.options.y) return `${dataLabel.point.options.tool}` }
            },
          },
        },
      },
      series: RcaToolsDepartmentChart,
      drilldown: {
        // breadcrumbs: {
        //   buttonTheme: {
        //     fill: '#f7f7f7',
        //     padding: 8,
        //     stroke: '#cccccc',
        //     'stroke-width': 1
        //   },
        //   floating: true,
        //   position: {
        //     align: 'right'
        //   },
        //   showFullPath: true
        // },
        series: RcaToolsStatusChart
      }
    }
    this.RcaToolsByDepartmentChart = new Chart(ChartOfRcaToolsByDepartment);

  }

}
