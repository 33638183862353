<mat-card class="p-mt-0 p-pt-0">
    <div class="p-d-flex p-jc-between p-ai-center">
        <mat-card-title class="p-ml-2">Classification</mat-card-title>
        <div class="" >
          <mat-form-field>
            <input matInput  placeholder="Search...." autocomplete="off">
          </mat-form-field>
        </div>
        <div class="" >
          <button
          mat-mini-fab
          mat-button
          mat-dialog-close
          color="accent"
          (click)="opensideModal()"
          pTooltip="Add Clasification" tooltipPosition="top"
          style="margin-left: 16px;"
        >
          <i class="fa fa-plus" style="color: #fff"></i>
        </button>
          <!-- <button mat-flat-button class="btn-add-item" (click)="opensideModal()" pTooltip="Add Clasification" tooltipPosition="top">
            <span class="btn-icon">
              <svg width="40" height="36" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.18143 5.4235H5.6525V8.9235H4.47619V5.4235H0.947266V4.25684H4.47619V0.756836H5.6525V4.25684H9.18143V5.4235Z"
                  fill="white" />
              </svg>
            </span>
          </button> -->
        </div>
    </div>
    <mat-card-content>
      <ngx-spinner
          [fullScreen]="false"
          bdColor="#fff"
          size="medium"
          color="#050a4f"
          type="ball-clip-rotate-multiple"
          >
          <p style="font-size: 20px; color: #050a4f">Fetching Pillars Data...</p>
    </ngx-spinner>
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1" >
      <table mat-table  class="basic-table plant-model-table" matSort>

        <!-- groupID Column -->
        <!-- <ng-container matColumnDef="pillarId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text">Pillar ID</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.pillarId}} </td>
        </ng-container> -->

        <!-- Sort Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Name</p>
          </th>
          <td mat-cell value="0" >1 </td>
        </ng-container>



        <!-- plantModelActions Column -->
        <ng-container matColumnDef="areaActions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell  class="row-action-btns-column">
            <div class="row-action-btns">
              <button mat-icon-button class="edit-btn" aria-label="Edit Row"
              pTooltip="Edit Pillar" tooltipPosition="top">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button class="node-control-btn node-delete" aria-label="delete item"
                     pTooltip="Delete Pillar" tooltipPosition="top">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                        fill="white" />
                    </svg>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="auditcatColumns"></tr>
        <tr mat-row ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching</td>
        </tr>
      </table>
      <mat-paginator
        class="basic-paginataor"
        [pageSizeOptions]="[2, 5, 10, 20]"
        showFirstLastButtons>
      </mat-paginator>
    </div>
    </mat-card-content>
</mat-card>

 <!--side bar modal code-->
  <div
    class="sidepanel-model-wrapper edit-plant-row-model"
    [ngClass]="{ active: displayUserModal }"
  >
    <div class="sidepanel-close" (click)="closeaddNewPlantItemModel()"></div>
    <div class="model-content">
      <div class="model-top">
        <h2 class="model-title">Add Classification</h2>

        <button
          mat-icon-button
          class="model-close-btn"
          aria-label="Close model"
          (click)="closeaddNewPlantItemModel()"
        >
          <svg
            width="14"
            height="12"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
       <div class="model-body">
    <form>
      <div class="form-group">
        <mat-form-field  appearance="fill" class="field-fill">
          <mat-label class="required">Classification Name</mat-label>
          <input matInput placeholder="Classification Name" >
        </mat-form-field>

      </div>
<div class="model-form-actions">
          <button type="button" mat-flat-button class="btn-cancel" (click)="closeaddNewPlantItemModel()"> Cancel
          </button>
          <button mat-flat-button color="accent" class="btn-accent">Add</button>
        </div>


    </form>
  </div>
    </div>
  </div>
