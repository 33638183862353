<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
  <p style="color: white;">{{ spinnerText }}</p>
</ngx-spinner>
<div class="container">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
  </div>
  <div class="sticky-header">
    <app-at-header [stageName]="stageName" [isReset]="isReset" [stageId]="stageId" [headerData]="headerData"
      (save)="saveSwotPest()" (emitSearchData)="setSearchData($event)">
    </app-at-header>
  </div>

  <!-- <form name="feedback_form"> -->
  <div class="swot-layout">
    <div class="subject">
      <h2> SWOT SUBJECT </h2>
      <textarea name="SwotSubject" [(ngModel)]="SwotSubject" matInput placeholder="Enter SWOT Subject Here"></textarea>
    </div>
    <div class="menu-card-group">


      <div class="margin-auto">

        <div style="flex:1;margin: 50px 0 0 0;">
          <h1 align="center" style="writing-mode: tb-rl;height:395px;
          transform: rotate(-180deg);background-color:#919191;color: white;margin-bottom: 0 !important;padding: 5px;">
            INTERNAL <br><span style="font-size: 18px;color: white;">(within organisation)</span></h1>
        </div>

        <div class="menu-card-item gr header">
          <div style="flex:1;margin: 0 5px 0 5px;">
            <h1 align="center" style="background-color:#7da556;color: white;margin-bottom: 0 !important;padding: 10px;">
              HELPFUL <span></span></h1>
          </div>

          <div class="swotBox" style="background-color: #bdec8e">

            <div style="display: flex;padding: 25px 20px 0 20px;">
              <div style="flex:1;">
                <h2>Strength <span>*</span></h2>
              </div>
              <div class="icon">
                <button type="button" [disabled]="!IsWrite" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="addSwotRow(1)">
                  <img src="assets/images/add.png" alt="" width="20px" height="20px">
                </button>
                <!-- <button type="button" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="removeSwotRow(1)">
                  <img src="assets/images/cancel.png" alt="" width="23px" style="margin-left: 10px">
                </button> -->
              </div>

            </div>
            <div class="strength-fields box-inner-content">
              <div style="width: 100%;">
                <mat-form-field appearence="fill" class="w-22" *ngFor="let strength of s_w_o_t.strength; let i=index">
                  <!-- <mat-label>Select Strength</mat-label> -->
                  <div class="d-flex justify-content-between">
                    <input matInput [(ngModel)]="newSWOT.strength[i]"
                      (change)="checkDuplicate('strength',newSWOT.strength[i], i)" name="strength_{{i}}"
                      *ngIf="!strength.ID">
                    <button class="add-btn deleteBtn" (click)="deleteSwotRow(1, i)" *ngIf="!strength.ID">
                      <mat-icon class="menu">delete</mat-icon>
                    </button>
                  </div>
                  <mat-select [(ngModel)]="SWOT.strength[i]" (selectionChange)="updateSWOTList(1, i)" #mySelect
                    name="strength_{{i}}" *ngIf="strength.ID">
                    <input (keyup)="searchValues($event, 1, i)" class="form-control" placeholder="Search Strength">
                    <mat-option *ngFor="let strength of strengthList[i]" [value]="strength.ID">
                      {{strength.Name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>

            <div class="swot-label">
              <h1 class="swot-label-text">S</h1>
            </div>

            <div style="flex:1">
              <h2 align="center" style="background-color:#0f0fd1;color: white;height:60px" class="d-flex justify-content-center align-items-center">Advantages <span></span></h2>
            </div>
          </div>

        </div>

        <div class="menu-card-item or">
          <div style="flex:1;margin: 0 5px 0 5px;">
            <h1 align="center" style="background-color:#bb824a;color: white;margin-bottom: 0 !important;padding: 10px">
              HARMFUL <span></span></h1>
          </div>
          <div class="swotBox" style="background-color: #fec995;">
            <div style="display: flex;padding: 25px 20px 0 20px;">
              <div style="flex:1;">
                <h2>Weakness <span>*</span></h2>
              </div>
              <div class="icon">
                <button type="button" [disabled]="!IsWrite" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="addSwotRow(2)">
                  <img src="assets/images/add.png" alt="" width="20px" height="20px">
                </button>

                <!-- <button type="button" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="removeSwotRow(2)">
                  <img src="assets/images/cancel.png" alt="" width="23px" style="margin-left: 10px">
                </button> -->
              </div>
            </div>
            <div class="weakness-fields box-inner-content">
              <div style="width: 100%;">
                <mat-form-field appearence="fill" class="w-22" *ngFor="let weakness of s_w_o_t.weakness; let i=index">
                  <!-- <mat-label>Select Weakness</mat-label> -->
                  <div class="d-flex justify-content-between">
                    <input matInput [(ngModel)]="newSWOT.weakness[i]"
                      (change)="checkDuplicate('weakness',newSWOT.weakness[i], i)" name="weakness_{{i}}"
                      *ngIf="!weakness.ID">
                    <button class="add-btn deleteBtn" (click)="deleteSwotRow(2, i)" *ngIf="!weakness.ID">
                      <mat-icon class="menu">delete</mat-icon>
                    </button>
                  </div>
                  <mat-select [(ngModel)]="SWOT.weakness[i]" (selectionChange)="updateSWOTList(2, i)"
                    name="weakness_{{i}}" *ngIf="weakness.ID">
                    <input (keyup)="searchValues($event, 2, i)" class="form-control" placeholder="Search Weakness">
                    <mat-option *ngFor="let weakness of weakNessList[i]" [value]="weakness.ID">
                      {{weakness.Name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="swot-label">
              <h1 class="swot-label-text">W</h1>
            </div>
            <div style="flex:1;">
              <h2 align="center" style="background-color:#0f0fd1;color: white;height:60px" class="d-flex justify-content-center align-items-center">Disadvantages <span></span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="menu-card-group ">
      <div class="margin-auto">
        <div style="flex:1;margin: 0 0 0 0;">
          <h1 align="center" style="writing-mode: tb-rl;height:395px;
          transform: rotate(-180deg);background-color:#919191;color: white;margin-bottom: 0 !important;padding: 5px;">
            EXTERNAL <br><span style="font-size: 18px;color: white;">(outside organisation)</span></h1>
        </div>
        <div class="menu-card-item bl">
          <div class="swotBox" style="background-color: #bbd3f5;">
            <div style="display: flex;padding: 25px 20px 0 20px;">
              <div style="flex:1;">
                <h2>Opportunities <span>*</span></h2>
              </div>
              <div class="icon">
                <button type="button" [disabled]="!IsWrite" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="addSwotRow(3)">
                  <img src="assets/images/add.png" alt="" width="20px" height="20px">
                </button>

                <!-- <button type="button" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="removeSwotRow(3)">
                  <img src="assets/images/cancel.png" alt="" width="23px" style="margin-left: 10px">
                </button> -->
              </div>
            </div>
            <div class="weakness-fields box-inner-content">
              <div style="width: 100%;">
                <mat-form-field appearence="fill" class="w-22"
                  *ngFor="let opportunities of s_w_o_t.opportunities; let i=index">
                  <!-- <mat-label>Select Opportunities</mat-label> -->
                  <div class="d-flex justify-content-between">
                    <input matInput [(ngModel)]="newSWOT.opportunities[i]"
                      (change)="checkDuplicate('opportunities',newSWOT.opportunities[i], i)" name="opportunities_{{i}}"
                      *ngIf="!opportunities.ID">
                    <button class="add-btn deleteBtn" (click)="deleteSwotRow(3, i)" *ngIf="!opportunities.ID">
                      <mat-icon class="menu">delete</mat-icon>
                    </button>
                  </div>
                  <mat-select [(ngModel)]="SWOT.opportunities[i]" (selectionChange)="updateSWOTList(3, i)"
                    name="opportunities{{i}}" *ngIf="opportunities.ID">
                    <input (keyup)="searchValues($event, 3, i)" class="form-control" placeholder="Search Opportunities">
                    <mat-option *ngFor="let opportunities of opportunitiesList[i]" [value]="opportunities.ID">
                      {{opportunities.Name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="swot-label">
              <h1 class="swot-label-text">O</h1>
            </div>

            <div style="flex:1;">
              <h2 align="center" style="background-color:#0f0fd1;color: white;height:60px" class="d-flex justify-content-center align-items-center">Chance to Improve Perfomance
                <span></span>
              </h2>
            </div>


          </div>
        </div>
        <div class="menu-card-item rd">
          <div class="swotBox" style="background-color: #ffb3b3;">
            <div style="display: flex;padding: 25px 20px 0 20px;">
              <div style="flex:1;">
                <h2>Threats <span>*</span></h2>
              </div>
              <div class="icon">
                <button type="button" [disabled]="!IsWrite" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="addSwotRow(4)">
                  <img src="assets/images/add.png" alt="" width="20px" height="20px">
                </button>
                <!-- <button type="button" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="removeSwotRow(4)">
                  <img src="assets/images/cancel.png" alt="" width="23px" style="margin-left: 10px">
                </button> -->
              </div>
            </div>
            <div class="threats-fields box-inner-content">
              <div style="width: 100%;">
                <mat-form-field appearence="fill" class="w-22" *ngFor="let threats of s_w_o_t.threats; let i=index">
                  <!-- <mat-label>Select Threats</mat-label> -->
                  <div class="d-flex justify-content-between">
                    <input matInput [(ngModel)]="newSWOT.threats[i]"
                      (change)="checkDuplicate('threats',newSWOT.threats[i], i)" name="threats_{{i}}"
                      *ngIf="!threats.ID">
                    <button class="add-btn deleteBtn" (click)="deleteSwotRow(4, i)" *ngIf="!threats.ID">
                      <mat-icon class="menu">delete</mat-icon>
                    </button>
                  </div>
                  <mat-select [(ngModel)]="SWOT.threats[i]" (selectionChange)="updateSWOTList(4, i)"
                    name="threats_{{i}}" *ngIf="threats.ID">
                    <input (keyup)="searchValues($event, 4 , i)" class="form-control" placeholder="Search Threats">
                    <mat-option *ngFor="let threats of threatsList[i]" [value]="threats.ID">
                      {{threats.Name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="swot-label">
              <h1 class="swot-label-text">T</h1>
            </div>

            <div style="flex:1;">
              <h2 align="center" style="background-color:#0f0fd1;color: white;height:60px" class="d-flex justify-content-center align-items-center">External trouble for the
                business <br> What your competitors are doing<span></span></h2>
            </div>


          </div>
        </div>
      </div>
    </div>
    <br><br><br>
    <hr><br>
    <div class="subject">
      <h2> PEST SUBJECT </h2>
      <textarea [(ngModel)]="PestSubject" matInput placeholder="Enter SWOT Subject Here"></textarea>
    </div>
    <div class="menu-card-group">
      <div class="margin-auto">
        <div class="menu-card-item bl">
          <div class="swotBox" style="background-color: #a65ade;">
            <div style="display: flex;padding: 25px 20px 0 20px;">
              <div style="flex:1;">
                <h2>Political Factors <span>*</span></h2>
              </div>
              <div class="icon">
                <button type="button" [disabled]="!IsWrite" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="addPestRow(1)">
                  <img src="assets/images/add.png" alt="" width="20px" height="20px">
                </button>
              </div>
            </div>
            <div class="weakness-fields box-inner-content">
              <div style="width: 100%;margin-top: 5px;">
                <div *ngFor="let political of p_e_s_t.political; let i=index" [ngClass]="{'new-row': !political.ID}">

                  <mat-checkbox type="checkbox" [checked]="orgPoliticalListnewCheck[i]==1 ? true : false"
                    [(ngModel)]="orgPoliticalListnewCheck[i]" class="mat-checkbox-new" *ngIf="!political.ID">
                  </mat-checkbox>

                  <div class="d-flex justify-content-between new-input-row">
                    <input matInput [(ngModel)]="newPEST.political[i]"
                      (change)="checkDuplicate('political',newPEST.political[i], i)" name="political_{{i}}"
                      class="pest-input new-input" *ngIf="!political.ID"
                      (keyup)="checkTrue(newPEST.political[i],i,'political')">
                    <button class="add-btn deleteBtn" (click)="deletePestRow(1, i)" *ngIf="!political.ID">
                      <mat-icon class="menu">delete</mat-icon>
                    </button>
                  </div>
                  <mat-checkbox class="example-margin" [(ngModel)]="orgPoliticalList[i]" #checkbox="matCheckbox"
                    *ngIf="political.ID" (change)="somethingClick(checkbox, political)">{{political.Name}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="swot-label">
              <h1 class="swot-label-text">P</h1>
            </div>
          </div>
        </div>
        <div class="menu-card-item rd">
          <div class="swotBox" style="background-color: #58eac5;">
            <div style="display: flex;padding: 25px 20px 0 20px;">
              <div style="flex:1;">
                <h2>Economic Factors <span>*</span></h2>
              </div>
              <div class="icon">
                <button type="button" [disabled]="!IsWrite" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="addPestRow(2)">
                  <img src="assets/images/add.png" alt="" width="20px" height="20px">
                </button>
              </div>
            </div>
            <div class="threats-fields box-inner-content">
              <div style="width: 100%;margin-top: 5px;">
                <div *ngFor="let economic of p_e_s_t.economic; let i=index" [ngClass]="{'new-row': !economic.ID}">
                  <mat-checkbox type="checkbox" [checked]="orgEconomicListnewCheck[i]==1 ? true : false"
                    [(ngModel)]="orgEconomicListnewCheck[i]" class="mat-checkbox-new" *ngIf="!economic.ID">
                  </mat-checkbox>
                  <div class="d-flex justify-content-between new-input-row">
                    <input matInput [(ngModel)]="newPEST.economic[i]"
                      (change)="checkDuplicate('economic',newPEST.economic[i], i)" name="economic_{{i}}"
                      class="pest-input new-input" *ngIf="!economic.ID"
                      (keyup)="checkTrue(newPEST.economic[i],i,'economic')">
                    <button class="add-btn deleteBtn" (click)="deletePestRow(2, i)" *ngIf="!economic.ID">
                      <mat-icon class="menu">delete</mat-icon>
                    </button>
                  </div>
                  <mat-checkbox class="example-margin" [(ngModel)]="orgEconomicList[i]" #checkbox="matCheckbox"
                    *ngIf="economic.ID" (change)="somethingClick(checkbox, economic)">{{economic.Name}}</mat-checkbox>
                </div>
              </div>
            </div>
            <div class="swot-label">
              <h1 class="swot-label-text">E</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="menu-card-group">
      <div class="margin-auto">
        <div class="menu-card-item bl">
          <div class="swotBox" style="background-color: #9cea46;">
            <div style="display: flex;padding: 25px 20px 0 20px;">
              <div style="flex:1;">
                <h2>Social Factors <span>*</span></h2>
              </div>
              <div class="icon">
                <button type="button" [disabled]="!IsWrite" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="addPestRow(3)">
                  <img src="assets/images/add.png" alt="" width="20px" height="20px">
                </button>
              </div>
            </div>
            <div class="weakness-fields box-inner-content">
              <div style="width: 100%;margin-top: 5px;">
                <div *ngFor="let social of p_e_s_t.social; let i=index" [ngClass]="{'new-row': !social.ID}">
                  <mat-checkbox type="checkbox" [checked]="orgSocialListnewCheck[i]==1 ? true : false"
                    [(ngModel)]="orgSocialListnewCheck[i]" class="mat-checkbox-new" *ngIf="!social.ID"></mat-checkbox>
                  <div class="d-flex justify-content-between new-input-row">
                    <input matInput [(ngModel)]="newPEST.social[i]"
                      (change)="checkDuplicate('social',newPEST.social[i], i)" name="social_{{i}}"
                      class="pest-input new-input" *ngIf="!social.ID" (keyup)="checkTrue(newPEST.social[i],i,'social')">
                    <button class="add-btn deleteBtn" (click)="deletePestRow(3, i)" *ngIf="!social.ID">
                      <mat-icon class="menu">delete</mat-icon>
                    </button>
                  </div>
                  <mat-checkbox class="example-margin" [(ngModel)]="orgSocialList[i]" #checkbox="matCheckbox"
                    *ngIf="social.ID" (change)="somethingClick(checkbox, social)">{{social.Name}}</mat-checkbox>
                </div>
              </div>
            </div>
            <div class="swot-label">
              <h1 class="swot-label-text">S</h1>
            </div>
          </div>
        </div>
        <div class="menu-card-item rd">
          <div class="swotBox" style="background-color: #eaf155;">
            <div style="display: flex;padding: 25px 20px 0 20px;">
              <div style="flex:1;">
                <h2>Technological Factors <span>*</span></h2>
              </div>
              <div class="icon">
                <button type="button" [disabled]="!IsWrite" class="add-btn" style="background: transparent;border: none; cursor: pointer;"
                  pTooltip="Add New Group" tooltipPosition="bottom" (click)="addPestRow(4)">
                  <img src="assets/images/add.png" alt="" width="20px" height="20px">
                </button>
              </div>
            </div>
            <div class="threats-fields box-inner-content">
              <div style="width: 100%;margin-top: 5px;">
                <div *ngFor="let technology of p_e_s_t.technology; let i=index" [ngClass]="{'new-row': !technology.ID}">
                  <mat-checkbox type="checkbox" [checked]="orgTechnologyListnewCheck[i]==1 ? true : false"
                    [(ngModel)]="orgTechnologyListnewCheck[i]" class="mat-checkbox-new" *ngIf="!technology.ID">
                  </mat-checkbox>
                  <div class="d-flex justify-content-between new-input-row">
                    <input matInput [(ngModel)]="newPEST.technology[i]"
                      (change)="checkDuplicate('technology',newPEST.technology[i], i)" name="technology_{{i}}"
                      class="pest-input new-input" *ngIf="!technology.ID"
                      (keyup)="checkTrue(newPEST.technology[i],i,'technology')">
                    <button class="add-btn deleteBtn" (click)="deletePestRow(4, i)" *ngIf="!technology.ID">
                      <mat-icon class="menu">delete</mat-icon>
                    </button>
                  </div>
                  <mat-checkbox class="example-margin" [(ngModel)]="orgTechnologyList[i]" #checkbox="matCheckbox"
                    *ngIf="technology.ID" (change)="somethingClick(checkbox, technology)">{{technology.Name}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="swot-label">
              <h1 class="swot-label-text">T</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="sub">
      <button type="submit" class="subm">Submit</button>
    </div> -->
  <!-- </form> -->
</div>