import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { UserManu } from 'src/app/models/userManu';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ProcessCheckpointService } from 'src/app/services/process-checkpoint.service';
import { AreaService } from 'src/app/services/area.service';
import { ProcessAuditCategoryService } from 'src/app/services/process-audit-category.service';
@Component({
  selector: 'app-process-checkpoint',
  templateUrl: './process-checkpoint.component.html',
  styleUrls: ['./process-checkpoint.component.scss'],
})
export class ProcessCheckpointComponent implements OnInit {
  @Input() aPermissions!: UserManu;
  @Input() IsWrite: boolean;

  @ViewChild(MatTable) table!: MatTable<Pillar>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  labelPosition: 'before' | 'after' = 'after';
  displayMAchineModal = false;
  isUpdateCheckpoint: boolean = false;
  pillars!: Pillar[];
  pillarId!: number;
  plants!: Plantbygroup[];
  checkpointForm!: FormGroup;
  formMode!: boolean;
  plantId!: number;
  displayUserModal = false;
  pillarModelVisible = false;
  auditCategoryData!: any[];
  areaData!: any[];
  auditcatColumns: string[] = [
    'areaName',
    'processCatName',
    'name',
    'areaActions',
  ];
  checkpointData = new MatTableDataSource<any>([]);
  getareaMaster: any;

  constructor(
    private fb: FormBuilder,
    private kpiService: KpimasterService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private processcheckpointService: ProcessCheckpointService,
    private areaService: AreaService,
    private processauditCategoryService: ProcessAuditCategoryService,
  ) {}

  ngOnInit(): void {
    this.formMode = true;
    this.checkpointForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      areaId: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      plantId: ['', Validators.required],
      processCat: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      type: [''],
      checkpointId: [''],
    });
  }

  getPaginator(): void {
    setTimeout(() => {
      this.checkpointData.paginator = this.paginator;
      this.checkpointData.sort = this.sort;
    }, 1000);
  }

  getPlants(): void {
    this.kpiService.getAllPlants().subscribe(
      (plants) => {
        this.plants = plants;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getArea(key: any): void {
    this.areaService.getMasterArea(key).subscribe(
      (data) => {
        this.areaData = data;
      },
      (error) => {
        this.toaster.error(error.error.message);
      }
    );
  }

  getCategory(key: any): void {
    this.processauditCategoryService.GetAllCategories(key).subscribe(
      (data) => {
        this.auditCategoryData = data;
      },
      (error) => {
        this.toaster.error(error.error.message);
      }
    );
  }

  getCheckpointMaster(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.processcheckpointService.getMasterCheckPoints(key).subscribe(
      (data) => {
        this.checkpointData.data = data;
        this.getPaginator();
        this.spinner.hide();
        this.checkpointForm.get('plantId')?.setValue(key.plantId);
      },
      (error) => {
        this.toaster.error(error.error.Message);
        this.spinner.hide();
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.checkpointData.filter = filterValue.trim().toLowerCase();

    if (this.checkpointData.paginator) {
      this.checkpointData.paginator.firstPage();
    }
  }

  opensideModal(): void {
    this.reset();
    const plant = this.plantId; // checkpointForm.get('plantId')?.value;
    const key = {
      plantId: plant,
    };
    this.getArea(key);
    this.getCategory(key);
    this.isUpdateCheckpoint = false;
    this.formMode = true;
    this.checkpointForm.get('plantId')?.setValue(this.plantId);
    this.displayUserModal = true;
  }

  closeaddNewPlantItemModel(): void {
    this.displayUserModal = false;
  }

  saveCheckpoint(): void {
    if (!this.isUpdateCheckpoint) {
      const areas = this.checkpointForm.get('areaId')?.value;
      areas.forEach((element: any) => {
        const data = {
          name: this.checkpointForm.get('name')?.value,
          plantId: this.checkpointForm.get('plantId')?.value,
          type: this.checkpointForm.get('type')?.value,
          processCatId: this.checkpointForm.get('processCat')?.value,
          areaId: element,
          userId: JSON.parse(localStorage.user).UserId,
        };
        this.processcheckpointService.addCheckPoint(data).subscribe(
          (response: any) => {
            if(response.message == 'Name '  + data.name +  ' is already taken')
            {
              this.toaster.warning('warning',response.message);
            }
            else
            {
              this.toaster.success('Success', 'Area Added Successfully');
              this.reset();
              this.closeaddNewPlantItemModel();
              const key = {
                plantId: data.plantId,
              };
              this.getCheckpointMaster(key);
              this.auditCategoryData.splice(
                this.auditCategoryData.findIndex(
                  (i) => i.categoryName === data.processCatId
                ),
                1
              );
            }
          },
          (error) => {
            this.toaster.error('Error', error.error.Message);
          }
        );
      });
    } else {
      debugger;
      const data = {
        name: this.checkpointForm.get('name')?.value,
        plantId: this.checkpointForm.get('plantId')?.value,
        type: this.checkpointForm.get('type')?.value,
        processCatId: this.checkpointForm.get('processCat')?.value,
        areaId: this.checkpointForm.get('areaId')?.value,
        userId: JSON.parse(localStorage.user).UserId,
        checkPointId: this.checkpointForm.get('checkpointId')?.value,
      };
      this.processcheckpointService.updateCheckPoint(data).subscribe(
        (response: any) => {
          if(response.message == 'Name '  + data.name +  ' is already taken'){
            this.toaster.warning('warning',response.message);
          }
          else{
            this.toaster.success('Success', 'Area Updated Successfully');
            this.closeaddNewPlantItemModel();
            this.isUpdateCheckpoint = false;
            this.reset();
            const key = {
              plantId: data.plantId,
            };
            this.getCheckpointMaster(key);
          }
        },
        (error) => {
          this.toaster.error('Error', error.error.Message);
        }
      );
    }
  }

  onClickUpdateCheckpoint(element: any): void {
    this.formMode = false;
    const plant = this.checkpointForm.get('plantId')?.value;
    const key = {
      plantId: plant,
    };
    this.getArea(key);
    this.getCategory(key);
    this.isUpdateCheckpoint = true;
    this.checkpointForm.get('name')?.setValue(element.name),
      this.checkpointForm.get('plantId')?.setValue(element.plantId),
      this.checkpointForm.get('type')?.setValue(element.type),
      this.checkpointForm.get('processCat')?.setValue(element.processCatId),
      this.checkpointForm.get('areaId')?.setValue(element.areaId),
      this.checkpointForm.get('checkpointId')?.setValue(element.checkPointId);
    this.displayUserModal = true;
  }

  onClickDeleteCheckpoint(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Master Process CheckPoint!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        var id = {
          checkPointId: element.checkPointId,
        };
        this.processcheckpointService.deleteCheckPoint(id).subscribe(
          (data: any) => {
            const key = {
              plantId: element.plantId,
            };
            this.getCheckpointMaster(key);
            this.toaster.success(data.message);
          },
          (error: any) => {
            this.toaster.error('Error', error.error.Message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Master Process CheckPoint is safe :)',
          'error'
        );
      }
    });
  }

  reset(): void {
    this.checkpointForm.get('name')?.setValue(''),
      this.checkpointForm.get('type')?.setValue(''),
      this.checkpointForm.get('processCat')?.setValue(''),
      this.checkpointForm.get('areaId')?.setValue(''),
      this.checkpointForm.get('checkpointId')?.setValue('');
  }
}
