import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  PlantModelElement,
  PlantGroupElement,
} from '../models/O3AdministrationModels/plant-model';

@Injectable({
  providedIn: 'root'
})
export class CILDashboardService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getAMCIL(data : any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetAMDashboard',data);
  }
  getCILDashboard(data : any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetCILDashboard',data);
  }
  getNewCILDashboard(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetNewCILDashboard',data);
  }
  getDefectCILDashboard(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetNewDefectDashboard',data);
  }
  getAllListCilDasboard(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetAllListCilDasboard',data);
  }
  getFiveSDashboardData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetAreaWithScore',data);
  }

  getFiveSDashboardDataPrev(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetLastAuditEecutionWithPer',data);
  }
  GetNewGembaWalkDash(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetNewGembaWalkDash',data);
  }

  getNoOf5sFindingOverall(data) {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'AdminDashboard/NoOf5sFindingOverall',data);
  }
  getNoOf5sFindingOverallPrev(data) {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'AdminDashboard/NoOf5sFindingOverallPrev',data);
  }
}