import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlantGroupElement } from '../models/O3AdministrationModels/plant-model';

@Injectable({
  providedIn: 'root',
})
export class OeeConfigurationService {
  baseUrl: string = environment.O3CoreApiUrl;
  baseUrl2: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) {}

  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetGroup', data);
  }
  //Enterprise Group
  getTreeModal(data: any): Observable<PlantGroupElement[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<PlantGroupElement[]>(
      this.o3CoreApiUrl + 'Common/GetTreeModal',
      data
    );
  }
  //Get Rate
  getRate(): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OEEConfig/GetRate', {});
  }
  //
  getOEECalculationMethod(): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'OEEConfig/GetOEECalculationMethod',
      {}
    );
  }
  //
  getOutputType(): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OEEConfig/GetOutputType', {});
  }
  //
  getVariableGroup(id: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'OEEConfig/GetVariableGroup',
      id
    );
  }

  //
  getVariable(id: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OEEConfig/GetVariable', id);
  }
  //GetDowntimeCategory ==> id=5
  getDowntimeCategory(id: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'OEEConfig/GetDowntimeCategory',
      id
    );
  }
  //GetFaultByUnit parameter data = {PUId:5,CategoryId:1}
  getFaultByUnit(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetFaultByUnit', data);
  }

  //GetWasteCategory id=5
  getWasteCategory(id: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'OEEConfig/GetWasteCategory',
      id
    );
  }

  //GetOEEInfo
  getOEEInfo(id: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'OEEConfig/GetOEEInfo', id);
  }

  //SubmitOEEInfo
  submitOEEInfo(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'OEEConfig/SubmitOEEInfo',
      data
    );
  }

  //getFailureMode
  // getFailureModeDetails(data: any): Observable<any[]> {
  //   return this.http.post<any[]>(this.baseUrl2 + 'unitdefects', data);
  // }

  getFailureModeDetails(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl2 + 'getfailureMode', data);
  }
}
