<!-- radial bar -->
<div *ngIf="barProperties.barType == 'radial'" class="set-size charts-container"
  [style.font-size.px]="barProperties.radial.size">
  <div class="charts-bg">
    <div class="pie-wrapper progress-radial">
      <span *ngIf="barProperties.radial.label.enable && barProperties.progress >= 0" class="label">
        {{barProperties.progress}}
        <span *ngIf="!barProperties.progress">0</span>
        <span class="smaller">%</span>
        </span>
      <div class="pie" >
        <div class="half-circle left-side" [style.border-color]='barProperties.color' 
        [style.transform]="'rotate(' + (barProperties.progress * 3.6) + 'deg)'"
        [style.border]="barProperties.progress > 0 ? barProperties.color +' '+ barProperties.radial.depth + 'px solid' : barProperties.secondColor +' '+ (barProperties.radial.depth + 1) + 'px solid'"
        >
        </div>
        <div class="half-circle" [ngClass]="{'right-side': barProperties.progress <=50,'right-side2': barProperties.progress >50 }"
          [style.border-color]='barProperties.progress > 50 ? barProperties.color : barProperties.secondColor'
          [style.border]="barProperties.progress > 50 ? barProperties.color +' '+ barProperties.radial.depth + 'px solid' : barProperties.secondColor +' '+ (barProperties.radial.depth + 1) + 'px solid'"
          ></div>
      </div>
      <div class="shadow" [style.border]="barProperties.secondColor+' '+ barProperties.radial.depth + 'px solid'"></div>
    </div>
  </div>
</div>
