
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-renderer',
  template: `
  <style>
  .row-action-btns .mat-icon-button.tree-view-btn {
    background: #824aed;
  }
  .row-action-btns .mat-icon-button {
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 10px;
    visibility: visible;
    transition: all .4s;
    top:5px;
  }
  .mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
  }
  .mat-mini-fab .mat-button-wrapper{
    padding: 0;
  }
  </style>

   
    <div class="row-action-btns">
      <button (click)="onClick($event)" mat-mini-fab="" color="primary" ptooltip="Action Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: red;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff; margin-bottom=10px;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
  </div>
    `
})

export class ButtonDeleteRendererComponent implements ICellRendererAngularComp {

   // <button  class="mat-icon-button" (click)="onClick($event)" aria-label="View Row Tree" pTooltip="Delete" tooltipPosition="top">
      // <span class="mat-button-wrapper">
      // <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      // <path  d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z" fill="#F85439"></path>
      // <circle  cx="17" cy="17" r="14" fill="#F85439"></circle>
      // <path  d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z" fill="white"></path></svg>
      // </span>
      // <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
      // <span class="mat-button-focus-overlay"></span></button>
  params:any;
  label!: string;

  agInit(params:any): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event:any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(this.params);

    }
  }
}