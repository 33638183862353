<div class="production-db-content">
    <div class="content-top-box">
        <ul class="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                    <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
                </li>
            </ul>
        </ul>
        <div class="title-row-flex-both-end">
            <h1 class="main-title">Fault Analysis</h1>
            <div>
                <button mat-raised-button color="accent" class="btn-accent mr-15-px">Live</button>
                <button mat-raised-button color="accent" class="btn-accent">Yesterday</button>
            </div>
        </div>
    </div>

    <app-dashboard-header></app-dashboard-header>

    <div class="d-flex justify-content-around w-100 my-5px">
        <div class="chart-items w-50">
            <div class="chart-title">Fault Occurence</div>
            <div [chart]="faultOccurance"></div>
        </div>
        <div class="chart-items w-35 float-left" style="margin-left: 1%;" [ngClass]="{'mr-1': i%2==0, 'w-100': last}"
            *ngFor="let oee of faultByShiftOEEChart; let i=index; let last = last;">
            <div class="chart-title">{{faultByShiftData[i].title}}</div>
            <div [chart]="oee"></div>
        </div>
    </div>
</div>