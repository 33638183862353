import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexXAxis,
  ApexYAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexFill,
  ApexLegend
} from 'ng-apexcharts';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ThemePalette } from '@angular/material/core';
import { MicroLevelSaturationService } from 'src/app/services/micro-level-saturation.service';
import * as _moment from 'moment';
import { MatSelect } from '@angular/material/select';
const moment = _moment;

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxix: ApexYAxis,
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};
export type ChartOptions1 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxix: ApexYAxis,
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};
export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxix: ApexYAxis,
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};
export type ChartOptions3 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxix: ApexYAxis,
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};
export type ChartOptions4 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

export interface PeriodicElement {
  Operator: string;
  Working: string;
  Waiting: string;
  Loading: string;
}
@Component({
  selector: 'app-micro-level-saturation',
  templateUrl: './micro-level-saturation.component.html',
  styleUrls: ['./micro-level-saturation.component.scss']
})
export class MicroLevelSaturationComponent implements OnInit {
  @ViewChild('paginator1', {static: true}) paginator1!: MatPaginator;
  @ViewChild('paginator2', {static: true}) paginator2!: MatPaginator;
  @ViewChild('paginator3', {static: true}) paginator3!: MatPaginator;
  @ViewChild('selectAll') selectAll!: MatSelect;
  SaturationDbForm!: FormGroup;
  groupListDDL!: any[];
  filterdplants!: any[];
  ownerList: any;
  groupId!: number;
  breadcrumList!: any;
  IsAccess!: any;
  IsWrite!: any;
  permisson!: any;
  displayedColumns1: string[] = ['Operators', 'Working', 'Waiting'];
  displayedColumns2: string[] = ['Operators', 'Working', 'Waiting', 'Loading'];
  displayedColumns3: string[] = ['Operators', 'Working', 'Waiting', 'Loading'];
  dataSource1!: MatTableDataSource<PeriodicElement>;
  dataSource2!: MatTableDataSource<PeriodicElement>;
  dataSource3!: MatTableDataSource<PeriodicElement>;
  public chartOptions: any;
  public chartOptions1: any;
  public chartOptions2: any;
  public chartOleTotalHoursWorkedOptions: any;
  public totalHoursWorked: any[] = [];
  Filteredowners: any;
  isSelected = false;
  constructor(private fb: FormBuilder, private toaster: ToastrService,
              private usermanagementService: UserManagementService,
              private machineService: MachineMappingService,
              public router: Router, private masterOleService: EnterPriseGroupService,
              private microLevelService: MicroLevelSaturationService) { 

                this.chartOleTotalHoursWorkedOptions = {
                  series: [ ],
                  chart: {
                    type: 'bar',
                    height: 500
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: '55%',
                      endingShape: 'rounded'
                    }
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                  },
                  xaxis: {
                    categories: [ ]
                  },
                  yaxis: {
                    title: {
                      text: 'Hours'
                    },
                  },
                  fill: {
                    opacity: 1
                  },
                  tooltip: {
                    y: {
                      formatter: (val: string) => {
                        return val + ' Hrs';
                      }
                    }
                  },
                  legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    offsetX: 40,
                    markers: {
                      width: 12,
                      height: 12,
                      strokeWidth: 0,
                      strokeColor: '#fff',
                      radius: 12,
                      customHTML: undefined,
                      onClick: undefined,
                      offsetX: 0,
                      offsetY: 0
                    }
                  }
                };
              }
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroupData();
    this.SaturationDbForm = this.fb.group({
      employee: [[]],
      plant: [''],
      group: [''],
      dateStart: [''],
      dateEnd: [''],
      beforeStartDate: [''],
      beforeEndDate: [''],
    });
    this.chartOptions = {
      series: [],
      chart: {
        type: 'bar',
        height: 300,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: []
      },
      yaxix: {
        labels : {
          style : {
              colors : ['#0000FF'],
              fontSize: ['12px']
          }
        }
      },
      tooltip: {
        y: {
          formatter: (val: string) => {
            return val + '%';
          }
        }
      },
      fill: {
        opacity: 1,
        colors: ['#00b050', '#ff0000']
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetX: 40,
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: ['#00b050', '#ff0000'],
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        }
      }
    };

    this.chartOptions1 = {
      series: [],
      chart: {
        type: 'bar',
        height: 300,
        stacked: true
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: []
      },
      yaxix: {
        labels : {
          style : {
              colors : ['#0000FF'],
              fontSize: ['12px']
          }
        }
      },
      tooltip: {
        y: {
          formatter: (val: string) => {
            return val + '%';
          }
        }
      },
      fill: {
        opacity: 1,
        colors: ['#00b050', '#ff0000', '#ffff00']
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetX: 40,
        labels: {
          colors: ['#00b050', '#ff0000', '#ffff00']
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: ['#00b050', '#ff0000', '#ffff00'],
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        }
      },
      dataLabels: {
        style: {
          colors: ['#ffffff', '#ffffff', '#000000']
        }
      }
    };

    this.chartOptions2 = {
      series: [],
      chart: {
        type: 'bar',
        height: 300,
        stacked: true
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: []
      },
      yaxix: {
        labels : {
          style : {
              colors : ['#0000FF'],
              fontSize: ['12px']
          }
        }
      },
      tooltip: {
        y: {
          formatter: (val: string) => {
            return val + '%';
          }
        }
      },
      fill: {
        opacity: 1,
        colors: ['#00b050', '#ff0000', '#ffff00']
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetX: 40,
        labels: {
          colors: ['#00b050', '#ff0000', '#ffff00']
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: ['#00b050', '#ff0000', '#ffff00'],
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        }
      },
      dataLabels: {
        style: {
          colors: ['#ffffff', '#ffffff', '#000000']
        }
      }
    };
  }
  getGroupData(): void {
    const key = {
      Id: 0
    };
    this.usermanagementService.getGroupData(key).subscribe((data: any) => {
      this.groupListDDL = data.masterList;
      this.SaturationDbForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
      this.getPlantByGroup();
      this.getUsers();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.SaturationDbForm.value.group
    };
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.filterdplants = data.DepartList;
      this.SaturationDbForm.get('plant')?.setValue(this.filterdplants[0].DeptId);
      this.SaturationDbForm.get('dateStart')
      ?.setValue(new Date(
        new Date().getFullYear(), 0, 1, 1).toISOString()
        );
      this.SaturationDbForm.get('dateEnd')?.setValue(new Date(new Date().getFullYear(), 11, 31, 1).toISOString());
      this.SaturationDbForm.get('beforeStartDate')?.setValue(new Date(new Date().getFullYear(), 0, 1, 1).toISOString());
      this.SaturationDbForm.get('beforeEndDate')?.setValue(new Date(new Date().getFullYear(), 11, 31, 1).toISOString());
      this.ownerList = [];
      this.getUsers();
      this.getMicrolLevelSaturations();
      this.getBeforeMicrolSaturations();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  onChangePlant(): void {
    this.getUsers();
    this.getMicrolLevelSaturations();
    this.getBeforeMicrolSaturations();
  }
  getUsers(): void {
    const key = {
      egId: this.SaturationDbForm.value.group
    };
    this.usermanagementService.getRaciUsers(key).subscribe((data) => {
         this.ownerList = data;
         this.getMicrolLevelSaturations();
         this.getBeforeMicrolSaturations();
         this.SaturationDbForm.get('employee')?.setValue([this.ownerList[0].userId]);
         this.Filteredowners = this.ownerList.slice();
    }, (error) => {
        this.toaster.error(error.error.message);
    });
  }
  onChangeUser(event: any): void {
      this.getMicrolLevelSaturations();
      this.getBeforeMicrolSaturations();
  }

  selectAllItems(): void {
    this.isSelected = !this.isSelected;

    if (this.isSelected) {
      const filterdArray = this.Filteredowners?.map((item: any) => item?.userId);
      this.SaturationDbForm.get('employee')?.setValue([...filterdArray, '0']);
    } else {
      this.SaturationDbForm.get('employee')?.setValue([]);
    }
    this.getMicrolLevelSaturations();
    this.getBeforeMicrolSaturations();
  }

  // get charts and tables data from api start
  getMicrolLevelSaturations(): void {
  
    const {dateStart, dateEnd, plant, employee} = this.SaturationDbForm.value;
    const key = {
      fromDate: dateStart !== '' ? moment(dateStart).utc().format('YYYY-MM-DD') : new Date(),
      toDate: dateEnd !== '' ? moment(dateEnd).utc().format('YYYY-MM-DD') :new Date(),
      plantId: parseInt(plant, 10),
      employees: employee?.filter((x: any) => x !== 'selectAll').toString()
    };
    this.microLevelService.getMicroLevelSaturationData(key).subscribe(res => {
      this.getChart1Data(res);
      this.getChart2Data(res);
    }, ({error}) => {
      this.toaster.error(error.message);
    });

    // Get Ole Total Work / Overtime Hours
    this.microLevelService.getOleTotalHoursWorked(key)
      .pipe()
        .subscribe((res: any) => {
      this.totalHoursWorked = res;
      this.getTotalHoursWorkedChartData(res);
    }, ({error}) => {
      this.toaster.error(error.message);
    });
  }
  getBeforeMicrolSaturations(): void {
    const {beforeStartDate, beforeEndDate, plant, employee} = this.SaturationDbForm.value;
    const key = {
      fromDate: beforeStartDate !== '' ? moment(beforeStartDate).utc().format('YYYY-MM-DD') :new Date(),
      toDate: beforeEndDate !== '' ? moment(beforeEndDate).utc().format('YYYY-MM-DD') :new Date(),
      plantId: parseInt(plant, 10),
      employees: employee?.filter((x: any) => x !== 'selectAll').toString()
    };
    this.microLevelService.getMicroLevelSaturationData(key).subscribe(res => {
      this.getChart3Data(res);
    }, ({error}) => {
      this.toaster.error(error.message);
    });
  }
  getChart1Data(res: any[]): void {
    const workingdata: any[] = [];
    const waitingdata: any[] = [];
    const Operators: any[] = [];
    const tableData: PeriodicElement[] = [];
    res.forEach((element) => {
      workingdata.push((element?.WorkingPercent + element?.LoadingPercent).toFixed(2));
      waitingdata.push(element?.WaitingPercent.toFixed(2));
      Operators.push(element?.UserName);
      const singleObject: PeriodicElement = {
        Operator: element?.UserName,
        Working: ((element?.WorkingPercent + element?.LoadingPercent).toFixed(2)) + '%',
        Waiting: element?.WaitingPercent.toFixed(2) + '%',
        Loading: element?.LoadingPercent
      };
      tableData.push(singleObject);
    });
    this.chartOptions.series = [
      {
        name: 'Working',
        data: workingdata
      },
      {
        name: 'Waiting',
        data: waitingdata
      }
    ];
    this.chartOptions.xaxis = {
      categories: Operators
    };
    this.dataSource1 = new MatTableDataSource<PeriodicElement>(tableData);
    this.dataSource1.paginator = this.paginator1;
  }
  getChart2Data(res: any[]): void {
    const workingdata: any[] = [];
    const waitingdata: any[] = [];
    const loadunloaddata: any[] = [];
    const Operators: any[] = [];
    const tableData: PeriodicElement[] = [];
    res.forEach((element) => {
      workingdata.push(element?.WorkingPercent.toFixed(2));
      waitingdata.push(element?.WaitingPercent.toFixed(2));
      loadunloaddata.push(element?.LoadingPercent.toFixed(2));
      Operators.push(element?.UserName);
      const singleObject: PeriodicElement = {
        Operator: element?.UserName,
        Working: element?.WorkingPercent.toFixed(2) + '%',
        Waiting: element?.WaitingPercent.toFixed(2) + '%',
        Loading: element?.LoadingPercent.toFixed(2) + '%'
      };
      tableData.push(singleObject);
    });
    this.chartOptions1.series = [
      {
        name: 'Value Added',
        data: workingdata
      },
      {
        name: 'Semi Value Added',
        data: waitingdata
      },
      {
        name: 'Non Value Added',
        data: loadunloaddata
      }
    ];
    this.chartOptions1.xaxis = {
      categories: Operators
    };
    this.dataSource2 = new MatTableDataSource<PeriodicElement>(tableData);
    this.dataSource2.paginator = this.paginator2;
  }
  getChart3Data(res: any[]): void {
    const workingdata: any[] = [];
    const waitingdata: any[] = [];
    const loadunloaddata: any[] = [];
    const Operators: any[] = [];
    const tableData: PeriodicElement[] = [];
    res.forEach((element) => {
      workingdata.push(element?.WorkingPercent.toFixed(2));
      waitingdata.push(element?.WaitingPercent.toFixed(2));
      loadunloaddata.push(element?.LoadingPercent.toFixed(2));
      Operators.push(element?.UserName);
      const singleObject: PeriodicElement = {
        Operator: element?.UserName,
        Working: element?.WorkingPercent.toFixed(2) + '%',
        Waiting: element?.WaitingPercent.toFixed(2) + '%',
        Loading: element?.LoadingPercent.toFixed(2) + '%'
      };
      tableData.push(singleObject);
    });
    this.chartOptions2.series = [
      {
        name: 'Working',
        data: workingdata
      },
      {
        name: 'Waiting',
        data: waitingdata
      },
      {
        name: 'Loading',
        data: loadunloaddata
      }
    ];
    this.chartOptions2.xaxis = {
      categories: Operators
    };
    this.dataSource3 = new MatTableDataSource<PeriodicElement>(tableData);
    this.dataSource3.paginator = this.paginator3;
  }

  public getTotalHoursWorkedChartData(res: any): void {
    const employeeData: any[] = [];
    const workingHours: any[] = [];
    const overtime: any[] = [];
    res.forEach((element: any) => {
      employeeData.push(element?.UserName);
      workingHours.push(element?.WorkingHours);
      overtime.push(element?.OverTime);
    });
    this.chartOleTotalHoursWorkedOptions.series = [
      {
        name: 'Working Hours',
        data: workingHours
      },
      {
        name: 'OverTime',
        data: overtime
      },
    ];
    this.chartOleTotalHoursWorkedOptions.xaxis = {
      categories: employeeData
    };
  }
  // get charts and tables data from api end
  GetBreadcrumList(): any {
      const obj = {
        MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
      };
      this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
        this.breadcrumList = data.BreadcrumList;
      }, (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.masterOleService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
}
