import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActionDbService } from '../../services/action-db.service';
import * as Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import More from 'highcharts/highcharts-more';
import ExportData from 'highcharts/modules/export-data';
import Accessibility from 'highcharts/modules/accessibility';
More(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { TranslaterService } from 'src/app/services/translater.service';

@Component({
  selector: 'app-action-dashboard',
  templateUrl: './action-dashboard.component.html',
  styleUrls: ['./action-dashboard.component.scss'],
})
export class ActionDashboardComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  groupListDDL!: any[];
  filteredGroupListDDL!: any[];
  breadcrumList: any;
  plants!: any;
  filteredPlants!: any;
  ActionForm!: FormGroup;
  dbStatusColumns: string[] = ['status', 'no', 'percent'];
  StatusdataSource!: MatTableDataSource<any[]>;
  dbResponsibleColumns: string[] = [
    'responsible',
    'overallaction',
    'dueaction',
    'closedaction',
    'completion',
    'uptodate',
    'meetings',
    'saturation',
  ];
  ResponsibledataSource!: MatTableDataSource<any[]>;
  actionStatusChartOptions: any;
  planChartOptions: any;
  contributionChartOptions: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!:any;
  constructor(
    private actionService: ActionDbService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    public translater: TranslaterService,
  ) {
  }
  ngOnInit(): void {
    this.GetBreadcrumList();

    this.ActionForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      startTime: [''],
      endTime: [''],
    });
    this.ActionForm.get('startTime')?.setValue(
      new Date().setDate(new Date().getDate() - 30)
    );
    this.ActionForm.get('endTime')?.setValue(new Date());
    this.getGroupData();
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }


  // get data for this page start
  getActionsData(): void {
    const { plant, startTime, endTime } = this.ActionForm.value;
    const newDate = moment(new Date()).format('YYYY-MM-DD');
    const key = {
      plantId: plant,
      startTime:
        startTime !== '' ? moment(startTime).format('YYYY-MM-DD') : newDate,
      endTime: endTime !== '' ? moment(endTime).format('YYYY-MM-DD') : newDate,
    };
    this.getMeetingActionsStatus(key);
    this.getMeetingActionDashboard(key);
  }

  getMeetingActionsStatus(key: any): void {
    this.actionService.getActionByStatus(key).subscribe(
      (response) => {
        const chartData: any = [];
        const statusTableData: any[] = [];
        let totalStatusValue = 0;
        let totalPercentage = 0;
        response?.forEach((item) => {
          chartData.push({
            name: item?.Status,
            y: item?.TotalPer,
          });
          statusTableData.push({
            status: item?.Status,
            no: item?.StatusValue,
            percent: item?.TotalPer + '%',
          });
          totalStatusValue += item?.StatusValue;
          totalPercentage += item?.TotalPer;
        });
        statusTableData.push({
          status: 'Total',
          no: totalStatusValue,
          percent: totalPercentage.toFixed(2) + '%',
        });
        this.StatusdataSource = new MatTableDataSource(statusTableData);
        this.actionStatusChartOptions = {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            type: 'pie',
            height: '40%',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            pointFormat: '{series.name}: {point.percentage:.1f}%',
          },
          accessibility: {
            point: {
              valueSuffix: '%',
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              size: '100%',
              height: '100%',
              dataLabels: {
                enabled: true,
                format: '{point.name}: {point.percentage:.1f} %',
              },
            },
          },
          series: [
            {
              name: 'Percentage',
              colorByPoint: true,
              data: chartData,
            },
          ],
        };
        this.getPaginator(this.StatusdataSource, 0);
        Highcharts.chart('container', this.actionStatusChartOptions);
      },
      (error) => {
        this.toaster.error(error?.message);
      }
    );
  }
  // getPaginator(): void {
  //   setTimeout(() => {
  //     this.ResponsibledataSource.paginator = this.paginator;
  //     this.ResponsibledataSource.sort = this.sort;
  //   }, 1000);
  // }

  // geetPaginator(): void {
  //   setTimeout(() => {
  //     this.StatusdataSource.paginator = this.paginator;
  //     this.StatusdataSource.sort = this.sort;
  //   }, 1000);
  // }
  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
      data.sort = this.sort.toArray()[index];
    }, 1000);
  }
  getMeetingActionDashboard(key: any): void {
    this.actionService.getActionDashboard(key).subscribe(
      (res) => {
        let totalOverAllAction = 0;
        let totalDueAction = 0;
        let totalClosedAction = 0;
        let totalCompletion = 0;
        let totalUptodate = 0;
        let totalMeetings = 0;
        let totalStaturation = 0;
        const overAllActionsStatus: any[] = [];

        res?.forEach((item) => {
          overAllActionsStatus.push({
            responsible: item?.Responsible,
            overallaction: item?.OverallActions,
            dueaction: item?.DueActions,
            closedaction: item?.ClosedActions,
            completion: item?.OverallCompletion + '%',
            uptodate: item?.UpToDateCompletion + '%',
            meetings: item?.NoOfMeetings,
            saturation: item?.Saturation + '%',
          });
          totalOverAllAction += item?.OverallActions;
          totalDueAction += item?.DueActions;
          totalClosedAction += item?.ClosedActions;
          totalCompletion += item?.OverallCompletion;
          totalUptodate += item?.UpToDateClosedActions;
          totalMeetings += item?.NoOfMeetings;
          totalStaturation += item?.Saturation;
        });
        overAllActionsStatus.push({
          responsible: 'Total',
          overallaction: totalOverAllAction,
          dueaction: totalDueAction,
          closedaction: totalClosedAction,
          completion: totalCompletion.toFixed(2) + '%',
          uptodate: ((totalUptodate/totalOverAllAction) *100).toFixed(2) + '%',
          meetings: totalMeetings,
          saturation: totalStaturation.toFixed(2) + '%',
        });
        this.ResponsibledataSource = new MatTableDataSource(
          overAllActionsStatus
        );
        this.renderPlanCompletionChart(res);
        this.renderContributionCompletionChart(res);
        this.getPaginator(this.ResponsibledataSource, 1);
      },
      (error) => {
        this.toaster.error(error?.message);
      }
    );
  }

  renderPlanCompletionChart(data: any[]): void {
    const categories: any[] = [];
    const dataArray: any[] = [];
    for (const element of data) {
      categories.push(element?.Responsible);
      dataArray.push(element?.OverallCompletion);
    }
    this.planChartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total Completions %',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: 'OverAll Completions',
          data: dataArray,
          color: '#0f0fd1',
        },
      ],
    };

    Highcharts.chart('plancontainer', this.planChartOptions);
  }
  renderContributionCompletionChart(data: any[]): void {
    const categories: any[] = [];
    const dataArray: any[] = [];
    for (const element of data) {
      categories.push(element?.Responsible);
      dataArray.push(element?.Saturation);
    }
    this.planChartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total Saturation %',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: 'People Saturation',
          data: dataArray,
          color: '#0f0fd1',
        },
      ],
    };

    Highcharts.chart('contributioncontainer', this.planChartOptions);
  }
  // get data for this page end
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.actionService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.filteredGroupListDDL = this.groupListDDL.slice();
        this.ActionForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.ActionForm.get('group')?.value,
    };
    this.actionService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList.filter((p: any) => p.DeptDesc);
        this.filteredPlants = this.plants.slice();
        this.ActionForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getActionsData();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

//menu and breadcrumb
GetBreadcrumList(): any {
  const obj = {
    MenuId:
      localStorage.getItem('O3RightMenuId') === null
        ? 1
        : localStorage.getItem('O3RightMenuId'),
  };
  // tslint:disable-next-line: align
  this.usermanagementService.GetBreadcrumList(obj).subscribe(
    (data: any) => {
      this.breadcrumList = data.BreadcrumList;
      this.moduleId = this.breadcrumList[0].id;
      this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      this.getLangWords();
    },
    (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    }
  );
}
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
