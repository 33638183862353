import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
    selector: 'checkbox-renderer',
    template: '<input type="checkbox" (click)="checkedHandler($event)" [checked]="params.value"/>',
})
// tslint:disable-next-line: component-class-suffix
export class CheckboxRenderer implements ICellRendererAngularComp {
    params: any;
    refresh(params: any): boolean {
        return true;
    }

    agInit(params: any): void {
        this.params = params;
    }
    checkedHandler(event: any): void {
        const checked = event.target.checked;
        const colId = this.params.column.colId;
        this.params.node.setDataValue(colId, checked);
    }
}
























