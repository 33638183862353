<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center">
      <mat-card-title class="p-ml-2">{{langObj ? langObj?.Trends : (' Trends' | translate)}}</mat-card-title>
      <div class="" *ngIf="trendData !== undefined">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event)" placeholder="{{langObj ? langObj?.Search : (' Search' | translate)}}">
        </mat-form-field>
      </div>          
  </div>
  <mat-card-content>
    <ngx-spinner
        [fullScreen]="false"
        bdColor="#fff"
        size="medium"
        color="#050a4f"
        type="ball-clip-rotate-multiple"
        >
        <p style="font-size: 20px; color: #050a4f">{{langObj ? langObj?.FetchingTrendsData : (' FetchingTrendsData' | translate)}}</p>
  </ngx-spinner>
  <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1" *ngIf="trendData !== undefined">
    <table mat-table [dataSource]="trendData" class="basic-table plant-model-table" matSort>

      <!-- groupID Column -->
      <!-- <ng-container matColumnDef="trendId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
          <p class="header-text">Trend ID</p>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.trendId}} </td>
      </ng-container> -->

      <!-- groupDescription Column -->
      <ng-container matColumnDef="plant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">{{langObj ? langObj?.Plant : (' Plant' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.plant}} </td>
      </ng-container>

      <!-- remarks Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">{{langObj ? langObj?.Trend : (' Trend' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="trendColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: trendColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{langObj ? langObj?.Nodatamatching : (' Nodatamatching' | translate)}}</td>
      </tr>
    </table>  
    <mat-paginator 
      class="basic-paginataor" 
      [pageSizeOptions]="[2, 5, 10, 20]"
      showFirstLastButtons>
    </mat-paginator>
  </div>
  </mat-card-content>    
</mat-card>
