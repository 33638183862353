
import { Component, OnInit } from '@angular/core';
import { PrimeCellEditorComponent } from '../../editors/prime-cell-editor.component';
import { CustomDateComponent } from '../../date-components/custom-date-component.component';
import { BtnCellRendererActualEntry } from '../../btn-cell-rendererActualEntry.component';
import { BtnCellRenderer } from '../../btn-cell-renderer.component';
import { Kpi } from '../../models/kpi';
import { ToastrService } from 'ngx-toastr';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { Frequency } from 'src/app/models/frequency';
import { Driver } from 'src/app/models/driver';
import { Router } from '@angular/router';
import { Pillar } from 'src/app/models/pillar';
import { Department } from 'src/app/models/department';
import { UserPlant } from 'src/app/models/userPlant';
import * as _moment from 'moment';
import { TranslaterService } from 'src/app/services/translater.service';
const moment = _moment;
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { UserManu } from 'src/app/models/userManu';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ScorecardService } from 'src/app/services/scorecard.service';

@Component({
  selector: 'app-individual-performance-evaluation-user',
  templateUrl: './individual-performance-evaluation-user.component.html',
  styleUrls: ['./individual-performance-evaluation-user.component.scss']
})
export class IndividualPerformanceEvaluationUserComponent implements OnInit {
  actionAnalysisForm!: FormGroup;

  userManu!: UserManu[];
  usermanagements!: any[];
  dataArray!: [];
  plants!: UserPlant[];
  searchPlants!: UserPlant[];
  kpifrequencies!: Frequency[];
  kpidata!: Kpi[];
  components!: any;
  pillars!: Pillar[];
  gridColumnApiEdit: any;
  autoGroupColumnDef!: any;
  kpis!: Kpi[];
  frequencies!: Frequency[];
  kpiDriver!: Driver[];
  roles!: any;
  users!: any;
  kpidepartments: string[] = [];
  departments!: Department[];
  columnDefsShift: any;
  dataArraykpi!: [];
  kpiPillar: string[] = [];
  targetActualEntryDate!: Date;
  kpifrequency!: string;
  kpiTitle!: string;
  drivers!: Driver[];
  kpiUom!: string;
  gridApi: any;
  actionDate!: Date;
  gridApiEdit: any;
  gridColumnApi: any;
  actionPermission!: UserManu;
  visibleVisionTarget = false;
  visibleYearlyTarget = false;
  visibleQutarlyTarget = false;
  visibleMonthlyTarget = false;
  visibleWeeklyTarget = false;
  visibleDailyTarget = false;
  visibleShiftlyTarget = false;
  columnDefs: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  startYear: any;
  calendar_en = {
    closeText: 'Close',
    prevText: 'Previous',
    nextText: 'Next',
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    weekHeader: 'Week',
    weekNumberTitle: 'W',
    firstDayOfWeek: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: '',
    timeOnlyTitle: 'Only Time',
    timeText: 'Time',
    hourText: 'Hour',
    minuteText: 'Minute',
    secondText: 'Second',
    currentText: 'Current Date',
    ampm: false,
    year: 'Year',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    list: 'Agenda',
    allDayText: 'All Day',
    moreLinkText: 'More...',
    noEventsText: 'No Events',
    today: 'Today',
    clear: 'Clear',
  };
  icons!: any;
  endYear: any;
  completionDate = true;
  cellData: any;
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
    },
  };
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;
  groupList: any;
  constructor(
    private scoreService: ScorecardService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private userService: UserManagementService,
    public router: Router,
    private kpiservice: KpitreeService,
    private kpiMaster: KpimasterService,
    private translater: TranslaterService,
  ) {
    this.columnDefs = [
      {
        field: 'Name', sortable: true, filter: true, resizable: true, wrapText: true, headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Roles',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        // rowGroup: true,
        // hide: true,
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Image', sortable: true, filter: true, resizable: true, wrapText: true,
        cellRenderer: (params: any): any => {
          return (params.value)
            ? '<img style="height:40px;width:40px" src="' +
            params.value +
            '" />'
            : '<img style="height:40px;width:40px" src="assets/images/no-image.png" />';
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      // { field: 'Type', sortable: true, filter: true, resizable: true, wrapText: true },
      {
        field: 'Total',
        sortable: true,
        filter: true, resizable: true, wrapText: true,
        valueFormatter: this.bracketsFormatter,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'TotalWeight', headerName: 'Total Weight %',
        sortable: true,
        filter: true, resizable: true, wrapText: true,
        valueFormatter: this.bracketsFormatter,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Evaluation', sortable: true, filter: true, resizable: true, wrapText: true, minWidth: 270,
        cellStyle: (params: { value: string; }) => {
          return { color: 'black', backgroundColor: 'yellow', 'font-weight': 'bold' };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Level', sortable: true, filter: true, resizable: true, wrapText: true,
        cellStyle: (params: { value: string; }) => {
          return { color: 'black', backgroundColor: 'yellow', 'font-weight': 'bold' };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
    ];
  }
  bracketsFormatter(params: any): any {
    if (params.data.Type === 'Achivement' || params.data.Type === 'Weight') {
      if (params.value < 0 && params.value != null) {
        return -1 * (params.value.toFixed(2)) + ' %';
      } else if (params.value > 0) {
        return params.value.toFixed(2) + ' %';
      }
    }
  }
  getMasterData(event: any): any {
    this.getRoles();
    this.actionAnalysisForm.get('user')?.reset();
    const key = {
      plantId: this.actionAnalysisForm.value.plant.DeptId
    };
    this.kpiMaster.getMasterFrequencies(key).subscribe(
      (frequency) => {
        this.frequencies = frequency;
        this.actionAnalysisForm.get('frequency')?.setValue(
          this.frequencies.find((d) => d.name === 'Yearly')
        );
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
    // setTimeout(() => {
    //   this.onFrequencyChange();
    // }, 1000);
  }
  getRoles(): any {
    const key = {
      EGId: this.actionAnalysisForm.value.group.TypeId,
      PlantId: this.actionAnalysisForm.value.plant.DeptId
    };
    this.kpiservice.getPositionMasterData(key).then((responsibleroles: any) => {
      this.roles = responsibleroles.positionMasterList;
    },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getGroupData(): void {
    const key = {
      Id: 0
    };
    // tslint:disable-next-line: no-debugger
    this.userService.getGroupData(key).subscribe((data: any) => {
      this.groupList = data.masterList;
      const obj = this.groupList[0];
      this.actionAnalysisForm.get('group')?.setValue(obj);
      this.getPlants();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlants(): void {
    const key = {
      GroupId: this.actionAnalysisForm.value.group.TypeId
    };
    this.userService.getDepartments(key).subscribe((res: any) => {
      this.searchPlants = res.DepartList;
      const plantObject = this.searchPlants[0];
      this.actionAnalysisForm.get('plant')?.setValue(plantObject);
      this.getMasterData(plantObject);

    },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // getAllPlants(): void {
  //   const key = {
  //     userId: JSON.parse(localStorage.user).UserId,
  //   };
  //   this.kpiMaster.getUserPlants(key).subscribe((plants) => {
  //     this.searchPlants = plants;
  //     this.plants = plants;
  //     if (this.searchPlants.length === 1) {
  //       const plantObject = this.searchPlants[0];
  //       this.actionAnalysisForm.get('plant')?.setValue(plantObject);
  //       this.getMasterData(plantObject);
  //     }
  //   },
  //     (error) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }
  // getUserList(): any {
  //   const key = {
  //     roleId: this.actionAnalysisForm.value.role.roleId
  //   };
  //   this.kpiMaster.getuser(key).then(
  //     (users: any) => {
  //       this.users = users;
  //     },
  //     (error: any) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  //   setTimeout(() => {
  //   }, 1000);

  // }
  getUserList(): any {
    const key = {
      EGId: this.actionAnalysisForm.value.group.TypeId,
      PlantId: this.actionAnalysisForm.value.plant.DeptId,
      roleId: this.actionAnalysisForm.value.role.POS_Id
    };
    this.kpiservice.getPositionMappedUsers(key).then((users: any) => {
      this.users = users;
    },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
    setTimeout(() => {
    }, 1000);

  }
  ngOnInit(): void {
    //this.getLangWords();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroupData();
    this.actionAnalysisForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      toDate: ['', Validators.required],
      fromDate: ['', Validators.required],
      role: [''],
      user: ['', Validators.required],
      frequency: ['', Validators.required],
    });
    const today = new Date().getFullYear();
    this.startYear = new Date(today, 0);
    this.endYear = new Date(today, 11, 31);
    const listmenu = localStorage.getItem('result');
    const permissions = listmenu !== null ? JSON.parse(listmenu) : [];


  }
  convertDate(date: any): any {
    const dateParts = date.split('/');
    return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReadyEdit(params: any): any {
    this.gridApiEdit = params.api;
    this.gridColumnApiEdit = params.columnApi;
  }
  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value: string) {
    let val;
    if (this.langObj && this.langObj[value]) {
      val = this.langObj[value];
    }
    return val ? val : value;
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if (this.langObj) {
        this.gridApi.refreshHeader();
      }
    });
  }

  getIPEGrid(): any {
    const userArray: any[] = [];
    if (this.actionAnalysisForm.value.user !== null) {
      this.actionAnalysisForm.value.user.forEach((user: { EmpInfo_Id: any }) => {
        userArray.push(user.EmpInfo_Id);
      });
    }
    const key = {
      ptId: this.actionAnalysisForm.value.plant.DeptId,
      userId: userArray ? userArray.toString() : '',
      fromDate: moment(this.actionAnalysisForm.value.fromDate).format('YYYY-MM-DD'),
      toDate: moment(this.actionAnalysisForm.value.toDate).format('YYYY-MM-DD'),
      frequencyId: this.actionAnalysisForm.value.frequency.frequencyId
    };

    this.scoreService.individualPerformanceEvaluationUsers(key).subscribe(
      (data: any) => {
        if (data !== undefined) {
          this.dataArray = data;
        }
      },
      (error: any) => {
        this.toaster.error('error', error.error.message);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;

    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }

}



function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}

function GenderCellRenderer(): void { }

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};
