import { getLocaleDayNames } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventApi,
  EventInput,
} from '@fullcalendar/angular';
import { count } from 'console';
import { el } from 'date-fns/locale';
import * as moment from 'moment';
import { MeetingMatrixService } from 'src/app/services/meeting-matrix.service';

import { MeetingMatrixV2Component } from '../meeting-matrixV2.component';
import { INITIAL_EVENTS, createEventId } from './event-utils';
@Component({
  selector: 'app-full-calendar',
  templateUrl: './full-calendar.component.html',
  styleUrls: ['./full-calendar.component.scss'],
})
export class Full_CalendarComponent implements OnInit {
  eventsArray: any[] = [];
  @Input() plantId: any;
  @Input() MeetingId: any = 0;
  @Output() toggleDisplay = new EventEmitter<any>();
  @ViewChild(MeetingMatrixV2Component)
  MeetingMatrixV2Component!: MeetingMatrixV2Component;
  events: EventInput[] = [];
  _plantId: any;

  newEvents: EventInput[] = [];
  MeetingList: any;
  calendarVisible = true;
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    initialView: 'dayGridMonth',
    //initialEvents:this.GetMeetingRecurrence.bind(this), // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    // select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    // eventsSet: this.handleEvents.bind(this),
  };
  constructor(
    private meetingService: MeetingMatrixService,
    private cd: ChangeDetectorRef
  ) {}
  currentEvents: EventApi[] = [];
  calendarEvents: EventInput[] = [];

  ngOnInit() {
    this.eventsArray = [];
    
    this.events = this.eventsArray;

    // this.GetMeetingRecurrence();

  }

  ngOnChanges() {
   
    this.plantId.valueChanges.subscribe((plantId) => {
      if (plantId) {
        this._plantId = plantId;
        this.GetMeetingRecurrence();
      }
    });
  }
  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  // handleDateSelect(selectInfo: DateSelectArg) {
  //   console.log(selectInfo);
  //   const title = prompt('Please enter a new title for your event');
  //   const calendarApi = selectInfo.view.calendar;

  //   calendarApi.unselect(); // clear date selection

  //   if (title) {
  //     calendarApi.addEvent({
  //       id: createEventId(),
  //       title,
  //       start: selectInfo.startStr,
  //       end: selectInfo.endStr,
  //       allDay: selectInfo.allDay,
  //     });
  //   }
  // }

  handleEventClick(clickInfo: EventClickArg) {
    

    const key = {
      plantId: this._plantId,
      meetingId: Number(clickInfo.event.id),
    };
    this.meetingService
      .getMeetingFrequencyByMeetingId(key)
      .subscribe((meeting: any) => {
        const key = meeting[0];
        this.toggleDisplay.emit(key);
        localStorage.setItem('leaderId',meeting[0].LeaderId);
        // this.MeetingMatrixV2Component.toggleDisplay(  meeting[0].frequencyId,
        //   meeting[0].kpiName,
        //   meeting[0].kpiId,
        //   meeting[0].frequencyName,
        //   meeting[0].meetingId,
        //   meeting[0].meetingName,
        //   meeting[0].type,
        //   meeting[0].meetingMasterId,
        //   meeting[0].headerId,
        //   meeting[0]);
      });

    // if (
    //   confirm(
    //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
    //   )
    // ) {
    //   clickInfo.event.remove();
    // }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  GetMeetingRecurrence() {
    const key = {
      plantId: this._plantId , 
      meetingId:this.MeetingId,
    };
    this.eventsArray = [];
    this.meetingService.GetMeetingRecurrence(key).subscribe((res) => {
      if (res.length) {
        let newEvents: any[] = [];
        this.MeetingList = res;
        this.MeetingList.forEach((element) => {
          if (element.TypeId == 1) {
            if (element.Type == 'endby') {
              //Days between Start Date and End Date

              var start = element.StartDate;
              var end = new Date(element.EndDate);

              var loop = new Date(start);
              while (loop <= end) {
                var obj = {
                  id: element.MeetingId,
                  title: element.Name,
                  start: new Date(
                    moment(new Date(loop)).format('YYYY-MM-DD') +
                      ' ' +
                      element.StartTime +
                      ' UTC'
                  ),
                };
                this.eventsArray.push(obj);

                var newDate = loop.setDate(loop.getDate() + element.Interval);

                loop = new Date(newDate);
              }
            } else if (element.Type == 'endafter') {
              //No of occurrences from start date
              var start = element.StartDate;
              var end = new Date(element.EndDate);

              var count = 1;
              var loop = new Date(start);
              while (count <= element.NoOfOccurences) {
                var obj = {
                  id: element.MeetingId,
                  title: element.Name,
                  start: new Date(
                    moment(new Date(loop)).format('YYYY-MM-DD') +
                      ' ' +
                      element.StartTime +
                      ' UTC'
                  ),
                };
                this.eventsArray.push(obj);

                var newDate = loop.setDate(loop.getDate() + element.Interval);

                count++;
                loop = new Date(newDate);
              }
            } else {
              // To be discussed

              let start = new Date(element.StartDate);
              let year = new Date(element.StartDate).getFullYear();
              let month = start.getMonth();
              let day = start.getDate();
              let end = new Date(year + 10, month, day);

              let loop = new Date(start);
              while (loop <= end) {
                var obj = {
                  id: element.MeetingId,
                  title: element.Name,
                  start: new Date(
                    moment(new Date(loop)).format('YYYY-MM-DD') +
                      ' ' +
                      element.StartTime +
                      ' UTC'
                  ),
                };
                this.eventsArray.push(obj);

                var newDate = loop.setDate(loop.getDate() + element.Interval);

                loop = new Date(newDate);
              }
            }
          } else if (element.TypeId == 2) {
            var interval = 7 * Number(element.Interval);
            if (element.Type == 'endby') {
              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.EndDate));
              var arrayOfDays = element.DaysOfWeek.split(',');
              //Loop Of Days string Start
              arrayOfDays.forEach((el) => {
                var arr = [];
                // Get "next" monday

                let tmp = start1.clone().day(this.GetDayNo(el));

                var count1 = 0;
                while (tmp.isBefore(end1)) {
                  arr.push(tmp.format('YYYY-MM-DD'));

                  var obj = {
                    id: element.MeetingId,
                    title: element.Name,
                    start: new Date(
                      tmp.format('YYYY-MM-DD') +
                        ' ' +
                        element.StartTime +
                        ' UTC'
                    ),
                  };
                  this.eventsArray.push(obj);

                  tmp.add(interval, 'days');
                }
                //Loop of Day String End
              });
            } else if (element.Type == 'endafter') {
              //No of occurrences from start date

              var interval = 7 * Number(element.Interval);

              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.EndDate));
              var arrayOfDays = element.DaysOfWeek.split(',');
              //Loop Of Days string Start
              arrayOfDays.forEach((el) => {
                var arr = [];
                // Get "next" monday

                let tmp = start1.clone().day(this.GetDayNo(el));

                var count1 = 1;
                while (count1 <= element.NoOfOccurences) {
                  arr.push(tmp.format('YYYY-MM-DD'));

                  var obj = {
                    id: element.MeetingId,
                    title: element.Name,
                    start: new Date(
                      tmp.format('YYYY-MM-DD') +
                        ' ' +
                        element.StartTime +
                        ' UTC'
                    ),
                  };
                  this.eventsArray.push(obj);

                  tmp.add(interval, 'days');

                  count1++;
                }
                //Loop of Day String End
              });
            } else {
              // To be discussed

              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.StartDate)).add(10, 'y');

              var arrayOfDays = element.DaysOfWeek.split(',');
              //Loop Of Days string Start
              arrayOfDays.forEach((el) => {
                var arr = [];
                // Get "next" monday

                let tmp = start1.clone().day(this.GetDayNo(el));

                var count1 = 0;
                while (tmp.isBefore(end1)) {
                  arr.push(tmp.format('YYYY-MM-DD'));

                  var obj = {
                    id: element.MeetingId,
                    title: element.Name,
                    start: new Date(
                      tmp.format('YYYY-MM-DD') +
                        ' ' +
                        element.StartTime +
                        ' UTC'
                    ),
                  };
                  this.eventsArray.push(obj);

                  tmp.add(interval, 'days');
                }
                //Loop of Day String End
              });
            }
          } else if (element.TypeId == 3) {
            if (element.Type == 'endby') {
              //Days between Start Date and End Date
              var arr = [];

              var start = element.StartDate;
              var end = new Date(element.EndDate);

              var count = 0;
              var loop = new Date(start);
              while (loop <= end) {
                var Date1 = moment(loop);
                var startOfMonth = moment(new Date(loop)).startOf('month');
                var dayDate = startOfMonth.add(element.DayOfMonth - 1, 'days');
                var DayDateFormated = dayDate.format('YYYY-MM-DD');

                if (
                  new Date(DayDateFormated) >= new Date(start) &&
                  new Date(DayDateFormated) <= new Date(end)
                ) {
                  arr.push(DayDateFormated);

                  var obj = {
                    id: element.MeetingId,
                    title: element.Name,
                    start: new Date(
                      moment(new Date(DayDateFormated)).format('YYYY-MM-DD') +
                        ' ' +
                        element.StartTime +
                        ' UTC'
                    ),
                  };
                  this.eventsArray.push(obj);
                }

                var dateString = Date1.add(element.Interval, 'M').toString();

                loop = new Date(dateString);
              }
            } else if (element.Type == 'endafter') {
              //No of occurrences from start date
              var arr = [];

              var start = element.StartDate;
              var end = new Date(element.EndDate);

              var count2 = 1;
              var loop = new Date(start);
              while (count2 <= element.NoOfOccurences) {
                var Date1 = moment(loop);
                var startOfMonth = moment(new Date(loop)).startOf('month');
                var dayDate = startOfMonth.add(element.DayOfMonth - 1, 'days');
                var DayDateFormated = dayDate.format('YYYY-MM-DD');

                if (new Date(DayDateFormated) >= new Date(start)) {
                  arr.push(DayDateFormated);

                  var obj = {
                    id: element.MeetingId,
                    title: element.Name,
                    start: new Date(
                      moment(new Date(DayDateFormated)).format('YYYY-MM-DD') +
                        ' ' +
                        element.StartTime +
                        ' UTC'
                    ),
                  };
                  this.eventsArray.push(obj);
                }

                var dateString = Date1.add(element.Interval, 'M').toString();

                loop = new Date(dateString);
                count2++;
              }
            } else {
              // To be discussed
              var arr = [];

              var start = element.StartDate;
              var year = element.StartDate.getFullYear();
              var month = start.getMonth();
              var day = start.getDate();
              var end = new Date(year + 10, month, day);
              //var end = new Date(element.EndDate);

              var count = 0;
              var loop = new Date(start);
              while (loop <= end) {
                var Date1 = moment(loop);
                var startOfMonth = moment(new Date(loop)).startOf('month');
                var dayDate = startOfMonth.add(element.DayOfMonth - 1, 'days');
                var DayDateFormated = dayDate.format('YYYY-MM-DD');

                if (
                  new Date(DayDateFormated) >= new Date(start) &&
                  new Date(DayDateFormated) <= new Date(end)
                ) {
                  arr.push(DayDateFormated);
                  var obj = {
                    id: element.MeetingId,
                    title: element.Name,
                    start: new Date(
                      moment(new Date(DayDateFormated)).format('YYYY-MM-DD') +
                        ' ' +
                        element.StartTime +
                        ' UTC'
                    ),
                  };
                  this.eventsArray.push(obj);
                }

                var dateString = Date1.add(element.Interval, 'M').toString();

                loop = new Date(dateString);
              }
            }
          } else if (element.TypeId == 4) {
            var interval = Number(element.Interval);
            if (element.Type == 'endby') {
              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.EndDate));
              var arrayOfDays = element.DaysOfWeek.split(',');
              //Loop Of Days string Start
              arrayOfDays.forEach((el1) => {
                var arr = [];

                let tmp = start1.clone().day(this.GetDayNo(el1));

                var count1 = 0;
                while (tmp.isBefore(end1)) {
                  // If condition for week no
                  var week = this.weekOfMonth(tmp);
                  if (week == element.WeekOfMonth) {
                    arr.push(tmp.format('YYYY-MM-DD'));

                    var obj = {
                      id: element.MeetingId,
                      title: element.Name,
                      start: new Date(
                        tmp.format('YYYY-MM-DD') +
                          ' ' +
                          element.StartTime +
                          ' UTC'
                      ),
                    };
                    this.eventsArray.push(obj);

                    var newStart = tmp.add(interval, 'M').startOf('month');
                    tmp = newStart.clone().day(this.GetDayNo(el1));
                    continue;
                  }

                  tmp.add(7, 'days');
                }

                //Loop of Day String End
              });
            } else if (element.Type == 'endafter') {
              //No of occurrences from start date
              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.EndDate));
              var arrayOfDays = element.DaysOfWeek.split(',');
              //Loop Of Days string Start
              arrayOfDays.forEach((el2) => {
                var arr = [];

                let tmp = start1.clone().day(this.GetDayNo(el2));

                var count4 = 1;
                while (count4 <= element.NoOfOccurences) {
                  // If condition for week no
                  var week = this.weekOfMonth(tmp);
                  if (week == element.WeekOfMonth) {
                    arr.push(tmp.format('YYYY-MM-DD'));

                    var obj = {
                      id: element.MeetingId,
                      title: element.Name,
                      start: new Date(
                        tmp.format('YYYY-MM-DD') +
                          ' ' +
                          element.StartTime +
                          ' UTC'
                      ),
                    };
                    this.eventsArray.push(obj);

                    var newStart = tmp.add(interval, 'M').startOf('month');
                    tmp = newStart.clone().day(this.GetDayNo(el2));
                    count4++;
                    continue;
                  }

                  tmp.add(7, 'days');
                }

                //Loop of Day String End
              });
            } else {
              // To be discussed
              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.StartDate)).add(10, 'y');
              var arrayOfDays = element.DaysOfWeek.split(',');
              //Loop Of Days string Start
              arrayOfDays.forEach((el1) => {
                var arr = [];

                let tmp = start1.clone().day(this.GetDayNo(el1));

                var count1 = 0;
                while (tmp.isBefore(end1)) {
                  // If condition for week no
                  var week = this.weekOfMonth(tmp);
                  if (week == element.WeekOfMonth) {
                    arr.push(tmp.format('YYYY-MM-DD'));

                    var obj = {
                      id: element.MeetingId,
                      title: element.Name,
                      start: new Date(
                        tmp.format('YYYY-MM-DD') +
                          ' ' +
                          element.StartTime +
                          ' UTC'
                      ),
                    };

                    this.eventsArray.push(obj);

                    var newStart = tmp.add(interval, 'M').startOf('month');
                    tmp = newStart.clone().day(this.GetDayNo(el1));
                    continue;
                  }

                  tmp.add(7, 'days');
                }

                //Loop of Day String End
              });
            }
          } else if (element.TypeId == 5) {
            if (element.Type == 'endby') {
              //Days between Start Date and End Date

              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.EndDate));

              var arr = [];
              // Get "next" monday
              var startOfYear = start1.startOf('year');
              var MonthOfYear = startOfYear.add(element.Month - 1, 'M');
              var DayOfMonth = MonthOfYear.startOf('month').add(
                element.DayOfMonth - 1,
                'days'
              );

              var count1 = 0;
              while (DayOfMonth.isBefore(end1)) {
                if (DayOfMonth.isBefore(end1) && DayOfMonth.isAfter(start1)) {
                  arr.push(DayOfMonth.format('YYYY-MM-DD'));

                  var obj = {
                    id: element.MeetingId,
                    title: element.Name,
                    start: new Date(
                      DayOfMonth.format('YYYY-MM-DD') +
                        ' ' +
                        element.StartTime +
                        ' UTC'
                    ),
                  };
                  this.eventsArray.push(obj);
                }

                DayOfMonth.add(element.Interval, 'y');
              }

            } else if (element.Type == 'endafter') {
              //No of occurrences from start date

              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.EndDate));

              var arr = [];
              // Get "next" monday
              var startOfYear = start1.startOf('year');
              var MonthOfYear = startOfYear.add(element.Month - 1, 'M');
              var DayOfMonth = MonthOfYear.startOf('month').add(
                element.DayOfMonth - 1,
                'days'
              );

              var count1 = 0;
              while (count1 < element.NoOfOccurences) {
                if (DayOfMonth.isAfter(start1)) {
                  arr.push(DayOfMonth.format('YYYY-MM-DD'));

                  var obj = {
                    id: element.MeetingId,
                    title: element.Name,
                    start: new Date(
                      DayOfMonth.format('YYYY-MM-DD') +
                        ' ' +
                        element.StartTime +
                        ' UTC'
                    ),
                  };

                  this.eventsArray.push(obj);

                  count1++;
                }

                DayOfMonth.add(element.Interval, 'y');
              }

            } else {
              // To be discussed

              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.StartDate)).add(10, 'y');

              var arr = [];
              // Get "next" monday
              var startOfYear = start1.startOf('year');
              var MonthOfYear = startOfYear.add(element.Month - 1, 'M');
              var DayOfMonth = MonthOfYear.startOf('month').add(
                element.DayOfMonth - 1,
                'days'
              );

              var count1 = 0;
              while (DayOfMonth.isBefore(end1)) {
                if (DayOfMonth.isBefore(end1) && DayOfMonth.isAfter(start1)) {
                  arr.push(DayOfMonth.format('YYYY-MM-DD'));

                  var obj = {
                    id: element.MeetingId,
                    title: element.Name,
                    start: new Date(
                      DayOfMonth.format('YYYY-MM-DD') +
                        ' ' +
                        element.StartTime +
                        ' UTC'
                    ),
                  };
                  this.eventsArray.push(obj);
                }

                DayOfMonth.add(element.Interval, 'y');
              }

            }
          } else if (element.TypeId == 6) {
            if (element.Type == 'endby') {
              //Days between Start Date and End Date

              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.EndDate));
              var arrayOfDays = element.DaysOfWeek.split(',');
              //Loop Of Days string Start
              arrayOfDays.forEach((el6) => {
                var arr = [];

                var tmp1 = start1.clone().day(this.GetDayNo(el6));

                var count1 = 0;
                while (tmp1.isBefore(end1)) {
                  var tmpFormatMonth = tmp1.format('M');
                  if (tmpFormatMonth == element.Month) {
                    // If condition for week no
                    var week = this.weekOfMonth(tmp1);
                    if (week == element.WeekOfMonth) {
                      arr.push(tmp1.format('YYYY-MM-DD'));

                      var obj = {
                        id: element.MeetingId,
                        title: element.Name,
                        start: new Date(
                          tmp1.format('YYYY-MM-DD') +
                            ' ' +
                            element.StartTime +
                            ' UTC'
                        ),
                      };
                      this.eventsArray.push(obj);
                      var newStart = tmp1
                        .add(element.Interval, 'y')
                        .startOf('year');
                      tmp1 = newStart.clone().day(this.GetDayNo(el6));
                      continue;
                    }

                    tmp1.add(7, 'days');
                  } else {
                    var newDate = tmp1.add(1, 'M').toString();
                    var newDateStart = moment(new Date(newDate))
                      .startOf('month')
                      .toString();
                    var valueOfDate = new Date(newDateStart);

                    var value = valueOfDate.setDate(
                      valueOfDate.getDate() +
                        ((((7 - valueOfDate.getDay()) % 7) +
                          this.GetDayNo(el6)) %
                          7)
                    );
                    tmp1 = moment(value);
                  }
                }

                //Loop of Day String End
              });
            } else if (element.Type == 'endafter') {
              //No of occurrences from start date
              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.EndDate));
              var arrayOfDays = element.DaysOfWeek.split(',');
              //Loop Of Days string Start
              arrayOfDays.forEach((el6) => {
                var arr = [];

                var tmp1 = start1.clone().day(this.GetDayNo(el6));

                var count1 = 0;
                while (count1 < element.NoOfOccurences) {
                  var tmpFormatMonth = tmp1.format('M');
                  if (tmpFormatMonth == element.Month) {
                    // If condition for week no
                    var week = this.weekOfMonth(tmp1);
                    if (week == element.WeekOfMonth) {
                      arr.push(tmp1.format('YYYY-MM-DD'));

                      var obj = {
                        id: element.MeetingId,
                        title: element.Name,
                        start: new Date(
                          tmp1.format('YYYY-MM-DD') +
                            ' ' +
                            element.StartTime +
                            ' UTC'
                        ),
                      };
                      this.eventsArray.push(obj);

                      var newStart = tmp1
                        .add(element.Interval, 'y')
                        .startOf('year');
                      tmp1 = newStart.clone().day(this.GetDayNo(el6));

                      count1++;
                      continue;
                    }

                    tmp1.add(7, 'days');
                  } else {
                    var newDate = tmp1.add(1, 'M').toString();
                    var newDateStart = moment(new Date(newDate))
                      .startOf('month')
                      .toString();
                    var valueOfDate = new Date(newDateStart);
                    // tmp1=newDateStart.clone().day(1);
                    var value = valueOfDate.setDate(
                      valueOfDate.getDate() +
                        ((((7 - valueOfDate.getDay()) % 7) +
                          this.GetDayNo(el6)) %
                          7)
                    );
                    tmp1 = moment(value);
                  }
                }

                //Loop of Day String End
              });
            } else {
              // To be discussed
              var start1 = moment(new Date(element.StartDate));
              var end1 = moment(new Date(element.StartDate)).add(10, 'y');
              var arrayOfDays = element.DaysOfWeek.split(',');
              //Loop Of Days string Start
              arrayOfDays.forEach((el6) => {
                var arr = [];

                var tmp1 = start1.clone().day(this.GetDayNo(el6));

                var count1 = 0;
                while (tmp1.isBefore(end1)) {
                  var tmpFormatMonth = tmp1.format('M');
                  if (tmpFormatMonth == element.Month) {
                    // If condition for week no
                    var week = this.weekOfMonth(tmp1);
                    if (week == element.WeekOfMonth) {
                      arr.push(tmp1.format('YYYY-MM-DD'));

                      var obj = {
                        id: element.MeetingId,
                        title: element.Name,
                        start: new Date(
                          tmp1.format('YYYY-MM-DD') +
                            ' ' +
                            element.StartTime +
                            ' UTC'
                        ),
                      };
                      this.eventsArray.push(obj);

                      var newStart = tmp1
                        .add(element.Interval, 'y')
                        .startOf('year');
                      tmp1 = newStart.clone().day(this.GetDayNo(el6));
                      continue;
                    }

                    tmp1.add(7, 'days');
                  } else {
                    var newDate = tmp1.add(1, 'M').toString();
                    var newDateStart = moment(new Date(newDate))
                      .startOf('month')
                      .toString();
                    var valueOfDate = new Date(newDateStart);

                    var value = valueOfDate.setDate(
                      valueOfDate.getDate() +
                        ((((7 - valueOfDate.getDay()) % 7) +
                          this.GetDayNo(el6)) %
                          7)
                    );
                    tmp1 = moment(value);
                  }
                }

                //Loop of Day String End
              });
            }
          }

          //  var asd = { title: element.Name , start: new Date() }

      
        });
       setTimeout(() => {
        this.events = [];
        this.events = this.eventsArray;
        this.calendarOptions = {
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          },
          initialView: 'dayGridMonth',
          //initialEvents:this.GetMeetingRecurrence.bind(this), // alternatively, use the `events` setting to fetch from a feed
          weekends: true,
          editable: true,
          selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          // select: this.handleDateSelect.bind(this),
          eventClick: this.handleEventClick.bind(this),
          eventsSet: this.handleEvents.bind(this),
          events: this.events,
        };
       }, 1000);
      }else {
          this.events = [];
          this.eventsArray = [];
          // this.events = this.eventsArray;
          this.calendarOptions = {
            headerToolbar: {
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
            },
            initialView: 'dayGridMonth',
            //initialEvents:this.GetMeetingRecurrence.bind(this), // alternatively, use the `events` setting to fetch from a feed
            weekends: true,
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,
            // select: this.handleDateSelect.bind(this),
            eventClick: this.handleEventClick.bind(this),
            eventsSet: this.handleEvents.bind(this),
            events: this.events,
          };
      }
    });
  }

  GetDayNo(day: string) {
    switch (day) {
      case 'Monday':
        return 1;
        break;
      case 'Tuesday':
        return 2;
      case 'Wednesday':
        return 3;

      case 'Thursday':
        return 4;
      case 'Friday':
        return 5;
      case 'Saturday':
        return 6;
      case 'Sunday':
        return 7;
      default:
        return 0;
    }
  }

  weekOfMonth(m) {
    return m.week() - moment(m).startOf('month').week() + 1;
  }

  detectChange() {
    this.cd.detectChanges();
  }
}
