<ngx-spinner template="<img src='assets/images/spinner.gif'/>"></ngx-spinner>
<div class="order-processing-content">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="top-box-content">
      <h1 class="main-title">Batch Output Report</h1>
    </div>
  </div>

  <form [formGroup]="batchForm">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <!-- <div class="content-top-box-content">
        <h1 class="content-title">Process Order</h1>
      </div> -->
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="queryCriteria" (selectionChange)="criteriaChange($event)"
            placeholder="Query Criteria" panelClass="testClass" name="Status">
            <mat-option value="lastshift">Last Shift</mat-option>
            <mat-option value="yesterday">Yesterday</mat-option>
            <mat-option value="today" selected>Today</mat-option>
            <mat-option value="custom">Custom</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="inputField">
          <nz-date-picker  class="calender-input" [nzDisabled]="disabled" id="startPicker" formControlName="startDate" nzFormat="dd/MM/yyyy HH:mm"
          [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
          <span class="floating-text">Start Date</span>   
        </div>
        <div class="inputField">
          <nz-date-picker  class="calender-input" [nzDisabled]="disabled"  id="endPicker" formControlName="endDate" nzFormat="dd/MM/yyyy HH:mm"
          [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
          <span class="floating-text">End Date</span>   
        </div>
        <!-- GROUP -->
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" *ngIf="length">
          <mat-select formControlName="UserGroup1" (selectionChange)="getPlants($event)" placeholder="Group"
            panelClass="testClass" name="Status">
            <mat-option *ngFor="let group of GroupList" [value]="group.EG_Id">
              {{group.EG_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" *ngIf="length">
          <mat-select formControlName="UserPlant1" placeholder="Plant" panelClass="testClass" name="plant">
            <mat-option *ngFor="let plant of PlantList1" [value]="plant.PT_Id">
              {{plant.PT_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="shift"  placeholder="Shift" 
                    panelClass="testClass" name="Status">
                    <mat-option  value="all">ALL</mat-option>
                    <mat-option  value="day">Day</mat-option>
                    <mat-option  value="night">Night</mat-option>
                </mat-select>
            </mat-form-field>

      </div>
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="ItemCode" (selectionChange)="onItemCodeChange($event)" placeholder="Item Code"
            panelClass="testClass" name="itemCode">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'ItemCode'" [array]="itemList"
            (filteredReturn)="filteredItemCodes = $event"></mat-select-filter>
            <mat-option *ngFor="let item of filteredItemCodes" [value]="item.ItemCode" matTooltipClass="custom-tooltip" matTooltip="{{item.ItemDesc}}" matTooltipposition="right">
              {{item.ItemCode}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="line" placeholder="Line" panelClass="testClass" name="line">
            <mat-option *ngFor="let line of lineList" [value]="line.PL_Id">
              {{line.PL_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="unit" placeholder="Unit" panelClass="testClass" name="unit">
            <mat-option *ngFor="let unit of unitList" [value]="unit.PU_Id">
              {{unit.PU_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="po" placeholder="Process Order" (selectionChange)="onPOChange($event)"
            panelClass="testClass" name="po">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="processOrderList"
            (filteredReturn)="filteredPOs = $event"></mat-select-filter>
            <mat-option value="0">ALL</mat-option>
            <mat-option *ngFor="let po of filteredPOs" [value]="po.TypeName">
              {{po.TypeName}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="batchType" placeholder="Batch Type" panelClass="testClass" name="Status">
            <mat-option value="0">ALL</mat-option>
            <mat-option value="1">Rework</mat-option>
            <mat-option value="2">Normal</mat-option>
          </mat-select>
        </mat-form-field> -->
        <div class="btn-position btn-position-order-processing">
          <button mat-flat-button color="accent" class="btn-accent" (click)="onSubmit()">Apply Filter</button>
        </div>
      </div>
      <div class="menu-card-group">
        <div class="menu-card-item">
          <p class="title-text">Total Number Pallets</p>
          <p class="data-text">
            {{NB}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
          </div>
        </div>
        <div class="menu-card-item">
          <p class="title-text">Number of Process Order</p>
          <p class="data-text">
            {{NPO}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
          </div>
        </div>
        <div class="menu-card-item">
          <p class="title-text">Total Actual Quantity</p>
          <p class="data-text">
            {{TAQ}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
          </div>
        </div>
      </div>
      <section>
        <div class="example-button-row">
          <button type="button" class="btn btn-success" (click)="csvExport()" style="margin-top: 30px;"><img src="assets/images/csv-file-format.png" ></button>
          <button type="button" class="btn btn-success" (click)="excelExport()" style="margin-top: 30px; margin-left: 10px;"><i class="fa fa-file-excel-o icon-white"  aria-hidden="true"></i></button>
        </div>
      </section>
        <mat-card-content>
          <!-- <ag-grid-angular style="width: 100%; height: 24vw;margin-top: 10px;" class="ag-theme-alpine" [rowData]="rowData"
            [columnDefs]="columnDefs" (gridReady)="onGridReady($event)"  [frameworkComponents]="frameworkComponents"
             [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="50">
          </ag-grid-angular> -->
          <ag-grid-angular #agGrid style="width: 100%; height: 25vw" id="myGrid" class="ag-theme-alpine"
          [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [masterDetail]="true" [defaultExportParams]="defaultExportParams"  [excelStyles]="excelStyles"
          [detailCellRendererParams]="detailCellRendererParams" [rowData]="rowData"  [animateRows]="true"
          (firstDataRendered)="onFirstDataRendered($event)" (gridReady)="onGridReady($event)"  [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="50">
      </ag-grid-angular>
        </mat-card-content>    
      
    </div>
  </form>
</div>
<ng-template #batchViewModal>
  <div class="viewChecklist">
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h3>CheckList History View</h3>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <div [ngClass]="batchReportViewDatasource !== undefined ? 'table-overflow': ''">
      <table [dataSource]="batchReportViewDatasource" mat-table class="basic-table plant-model-table theight"
        matSort>
        <!-- Checklist Column -->
        <ng-container matColumnDef="checklist">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header>
            <p class="header-text">Checklist</p>
          </th>
          <td mat-cell *matCellDef="let element" >{{element?.Name}}</td>
        </ng-container>

        <!-- Values Column -->
        <ng-container matColumnDef="values">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Values</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <input *ngIf="element.DataType === 'bool' && element.BatchCheckListValue === 'true'" [checked]="true"
                type="checkbox" color="primary"
                [readonly]="true">
              <input *ngIf="element.DataType === 'bool' && element.BatchCheckListValue !== 'true'" type="checkbox"
                 color="primary" [disabled]="true">
              <input *ngIf="element.DataType === 'string'" type="text"
                [value]="element.BatchCheckListValue === undefined ? '' : element.BatchCheckListValue"
                [disabled]="true">
              <input *ngIf="element.DataType === 'number'" type="number" [value]="element.BatchCheckListValue"
              [disabled]="true">
              <input type="text" appearance="fill" class="field-fill" style="width: 180px;"
                *ngIf="element.DataType === 'dropdown'" [value]="element.BatchCheckListValue" [disabled]="true">
              <mat-form-field appearance="fill" class="field-fill" *ngIf="element.DataType === 'date'"
                style="width: 180px;">
                <input matInput [matDatepicker]="pickers" [value]="element.BatchCheckListValue"
                [disabled]="true">
                <mat-datepicker-toggle matSuffix [for]="pickers"></mat-datepicker-toggle>
                <mat-datepicker #pickers></mat-datepicker>
              </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="productionColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: productionColumns"></tr>
        <tr class="mat-row" *matNoDataRow style="text-align: center;">
          <td class="mat-cell" colspan="8" >No data Found</td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>