<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  template=" <img src='assets/images/spinner.gif' />"
>
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>

<div class="sfdc-configuration-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a
          class="breadcrumb-link"
          [routerLink]="breadcrumItem.Url"
          [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
          >{{ breadcrumItem.text }}</a
        >
      </li>
    </ul>
  </div>
  <div class="header-title">
    <h1 class="main-title">
      TechnicianExecution
    </h1>
  </div>
</div>

<div class="w-100">
  <form [formGroup]="technicianForm" (ngSubmit)="onSubmit()">
    <div class="w-100 techForm">
      <mat-form-field class="w-18">
        <mat-label>Line Area</mat-label>
        <input
         required
          matInput
          type="text"
          formControlName="line_area"
          [(ngModel)]="technician.LineArea"
          
        />
        <mat-error *ngIf="f.line_area.hasError('required')"
          >Line Area Required
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-18">
        <mat-label>WOId</mat-label>
        <input
          required
          matInput
          type="text"
          formControlName="wo_id"
          [(ngModel)]="technician.DisplayWOId"
         
        />
        <mat-error *ngIf="f.wo_id.hasError('required')"
          >WO is Required
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-18">
        <mat-label>WO Status</mat-label>
        <input
          required
          matInput
          formControlName="wo_status"
          [ngClass]="{
            'text-green': technician.WOStatus == 3 || technician.WOStatus == 2,
            'text-red':
              technician.WOStatus == 1 ||
              technician.WOStatus == 4 ||
              technician.WOStatus == 5,
            'text-orange': technician.WOStatus == 5
          }"
          [(ngModel)]="technician.WOStatusDisplay"
        />
        <mat-error *ngIf="f.wo_status.hasError('required')">WO Status Required</mat-error
        >
      </mat-form-field>

      <mat-form-field class="w-18">
        <mat-label>WO Type</mat-label>
        <mat-select
           required
          [(ngModel)]="technician.WOType"
          formControlName="wo_type"
          (selectionChange)="getWOTypeFailureData()"
          [disabled]="isviewMode"
        >
          <mat-option *ngFor="let type of woType" [value]="type.ID">
            {{ type.Name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.wo_type.hasError('required')">WO Type Required</mat-error
        >
      </mat-form-field>
      <mat-form-field class="w-18">
        <input
          formControlName="wo_creation_time"
          [(ngModel)]="technician.WOCreationTime"
          readonly
          matInput
          placeholder="Creation Time"
          required
        />
        <mat-error *ngIf="f.wo_creation_time.hasError('required')">
          WO Creation Time Is Required</mat-error
        >
      </mat-form-field>
      <mat-form-field class="w-18">
        <mat-label>Failure Mode</mat-label>
        <mat-select
          required
          [(ngModel)]="technician.FailureMode"
          formControlName="faliure_mode"
          [disabled]="isviewMode"
        >
          <mat-option *ngFor="let mode of failMode" [value]="mode.ID">
            {{ mode.Name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.faliure_mode.hasError('required')"
          >Failure Mode is Required
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-18">
        <mat-label>Priority</mat-label>
        <mat-select
          required
          [(ngModel)]="technician.Priority"
          formControlName="priority"
          [disabled]="isviewMode"
        >
          <mat-option
            *ngFor="let Priority of PriorityList"
            [value]="Priority.ID"
          >
            {{ Priority.Name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.priority.hasError('required')"
          >Priority is Required
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-18">
        <mat-label>Spare Parts</mat-label>
        <mat-select
          [(ngModel)]="technician.SpareParts"
          formControlName="spare_parts"
          (selectionChange)="setValidateSparePartsCode()"
          [disabled]="isviewMode"
          required
        >
          <mat-option *ngFor="let sp of spareParts" [value]="sp.ID">
            {{ sp.Name }}
          </mat-option>
        </mat-select>
        <!-- <input matInput formControlName="spare_parts" [(ngModel)]="technician.SpareParts" /> -->
        <mat-error *ngIf="f.spare_parts.hasError('required')"
          >Spare Parts Required
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-18">
        <mat-label>Spare Part Code</mat-label>
        <input
          matInput
          formControlName="spare_part_code"
          [(ngModel)]="technician.SparePartCode"
          [disabled]="isviewMode"
          required
        />
        <mat-error *ngIf="f.spare_part_code.hasError('required')">Spare Part Code Required</mat-error>
      </mat-form-field>

      <mat-form-field class="w-18">
        <input
          formControlName="wo_closed_time"
          [(ngModel)]="technician.WOClosedTime"
          readonly
          matInput
          placeholder="Closed Time"
        />
        <mat-error *ngIf="f.wo_closed_time.hasError('required')">
          WO Closed Time Is Required</mat-error
        >
      </mat-form-field>

      <mat-form-field class="w-48" style="margin-right: 1%">
        <mat-label>Operator Remark</mat-label>
        <textarea
          matInput
          [(ngModel)]="technician.Remark"
          formControlName="remarks"
          required
        ></textarea>
        <mat-error *ngIf="f.remarks.hasError('required')">Operator Remark Required</mat-error
        >
      </mat-form-field>

      <mat-form-field class="w-48">
        <mat-label>Supervisor Remark</mat-label>
        <textarea
          matInput
          [(ngModel)]="technician.SupervisorRemark"
          formControlName="visorremarks"
          required
        ></textarea>
        <mat-error *ngIf="f.visorremarks.hasError('required')">
          Supervisor Remark Is Required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="w-100 techForm main-form">
      <mat-form-field class="w-22 date-time">
        <mat-label>Start Time</mat-label>
        <input
          matInput
          readonly
          [ngxMatDatetimePicker]="picker"
          formControlName="start_time"
          [(ngModel)]="technician.StartTime"
          [disabled]="isviewMode" (dateChange)="dateChanged('starttime')"
          required
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="$any(picker)"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker
          [enableMeridian]="true"
        ></ngx-mat-datetime-picker>
        <mat-error *ngIf="f.start_time.hasError('required')">Start Time is Required}</mat-error>
      </mat-form-field>

      <mat-form-field class="w-22 date-time">
        <mat-label>SP Request Time</mat-label>
        <input
          matInput
          readonly
          [ngxMatDatetimePicker]="picker3"
          formControlName="sp_req_time"
          [(ngModel)]="technician.SPRequestTime"
          [disabled]="isviewMode"
          (dateChange)="dateChanged('spRequest')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="$any(picker3)"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker3
          [enableMeridian]="true"
        ></ngx-mat-datetime-picker>
        <mat-error *ngIf="f.sp_req_time.hasError('required') && technician.SparePartCode=='No'">SP Request Time is Required</mat-error>
      </mat-form-field>

      <mat-form-field class="w-22 date-time">
        <mat-label>SP Received Time</mat-label>
        <input
          matInput
          readonly
          [ngxMatDatetimePicker]="picker4"
          formControlName="sp_rec_time"
          [(ngModel)]="technician.SPReceivedTime"
          [disabled]="isviewMode"
          (dateChange)="dateChanged('spReceived')"
        />
        <mat-datepicker-toggle
          matSuffix
          [readonly]="isviewMode"
          [for]="$any(picker4)"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker4
          [enableMeridian]="true"
        ></ngx-mat-datetime-picker>
        <mat-error *ngIf="f.sp_rec_time.hasError('required')">SP Received Time is Required</mat-error>
      </mat-form-field>

      <mat-form-field class="w-22 date-time">
        <mat-label>Complete Time</mat-label>
        <input
          matInput
          readonly
          [ngxMatDatetimePicker]="picker2"
          formControlName="complete_time"
          [(ngModel)]="technician.CompleteTime"
          [disabled]="isviewMode" (dateChange)="dateChanged('completetime')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="$any(picker2)"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker2
          [enableMeridian]="true"
        ></ngx-mat-datetime-picker>
        <mat-error *ngIf="f.complete_time.hasError('required')">Technician Execution</mat-error>
      </mat-form-field>

      <mat-form-field class="w-50">
        <mat-label>Work Order Details</mat-label>
        <textarea
          matInput
          [(ngModel)]="technician.Remarks"
          formControlName="remarks_manual"
          [readonly]="isviewMode"
          required
        ></textarea>
      </mat-form-field>

      <button
        mat-flat-button
        color="accent"
        [disabled]="!IsWrite"
        type="button"
        class="upload w-25"
        (click)="openFile()"
        *ngIf="!isviewMode"
      >
       After Picture
      </button>
      <input
        type="file"
        (change)="readFile($event)"
        class="d-none"
        #fileInput
        accept="image/*"
        multiple
      />

      <h2
        *ngIf="
          (FileData && FileData.length) || (EditFileData && EditFileData.length)
        "
      >
        Technician Images
      </h2>
      <div *ngIf="FileData && FileData.length" class="imgflex">
        <div *ngFor="let file of FileData; let i = index" class="relative">
          <div>
            <img
              *ngIf="file.FileType == 'image'"
              [src]="file.ImageSrc"
              alt=""
              width="250px"
              height="200px"
            />
          </div>

          <img
            src="assets/images/remove.png"
            (click)="removeFile(i)"
            class="absolute"
            alt=""
            width="20px"
            height="20px"
          />
        </div>
      </div>

            <div *ngIf="EditFileData && EditFileData.length" class="imgflex">
                <div *ngFor="let file of EditFileData; let i = index" class="relative">
                    <div>
                        <img [src]="blobStorageUrl+file.ImageSrc" alt="" width="250px" height="200px" (click)="imgview()">
          </div>

          <img
            *ngIf="!isviewMode"
            src="assets/images/delete.png"
            (click)="deleteFile(i)"
            class="absolute"
            alt=""
            width="20px"
            height="20px"
          />
        </div>
      </div>

            <h2 *ngIf="OperatorFiles && OperatorFiles.length">Operator Images</h2>
            <div *ngIf="OperatorFiles && OperatorFiles.length" class="imgflex">
                <div *ngFor="let file of OperatorFiles; let i = index" class="relative">
                    <div>
                        <img [src]="blobStorageUrl+file.ImageSrc" alt="" width="250px" height="200px"  (click)="imgview1()">
          </div>
        </div>
      </div>
    </div>

    <div class="center w-100 my-2">
      <button
        class="submit"
        [disabled]="!IsWrite"
        type="submit float-right"
        *ngIf="!isviewMode"
      >
        Submit
      </button>
      <button
        class="cancel"
        routerLink="/PM/technician"
        [disabled]="!IsWrite"
        type="button"
      >
        Cancel
      </button>
    </div>
  </form>
</div>

 <!-- img popup -->
 <ng-template #technicianimageViewModal>
  <button mat-mini-fab color="warn" (click)="oncloseimg()"><mat-icon>close</mat-icon></button>
  <mat-carousel timings="250ms ease-in" [autoplay]="false" interval="5000" color="accent" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
    [useMouseWheel]="true" orientation="ltr">
    <mat-carousel-slide #matCarouselSlide *ngFor="let file of EditFileData; let i = index" [image]="blobStorageUrl+file.ImageSrc"
      overlayColor="#00000040" [hideOverlay]="false"></mat-carousel-slide>
  </mat-carousel>
</ng-template>

<ng-template #operatorimageViewModal>
  <button mat-mini-fab color="warn" (click)="oncloseimg1()"><mat-icon>close</mat-icon></button>
  <mat-carousel timings="250ms ease-in" [autoplay]="false" interval="5000" color="accent" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
    [useMouseWheel]="true" orientation="ltr">
    <mat-carousel-slide #matCarouselSlide *ngFor="let file of OperatorFiles; let i = index" [image]="blobStorageUrl+file.ImageSrc"
      overlayColor="#00000040" [hideOverlay]="false"></mat-carousel-slide>
  </mat-carousel>
</ng-template>
<!-- end img popup -->
