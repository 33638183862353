
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-renderer',
  template: `
  <style>
  .row-action-btns {
    min-width: 70px;
    }
  .row-action-btns .mat-icon-button:not(:last-child) {
    margin-right: 10px;
    }
    .row-action-btns .mat-icon-button.edit-btn {
        background: #3aafe4;
    }
    .row-action-btns .mat-icon-button {
        width: 34px;
        height: 34px;
        line-height: 34px;
        border-radius: 10px;
        visibility: visible;
        transition: all .4s;
        top:5px
    }
    .icon-white{
      color:white;
    }
  </style>

   
    <div class="row-action-btns">
    <button mat-icon-button class="edit-btn" aria-label="Save Row" (click)="onClick($event)" pTooltip="Save" tooltipPosition="top">
    <i class="fa fa-save icon-white"  aria-hidden="true"></i>
    </button>
  </div>
    `
})

export class ButtonSaveRendererComponent implements ICellRendererAngularComp {

  params:any;
  label!: string;

  agInit(params:any): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event:any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(this.params);

    }
  }
}