import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Success } from '../models/success';
import * as XLSX from 'xlsx';
const Excel_Type =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const Excel_Extension = '.xlsx';
let FileSaver = require('file-saver');
​
@Injectable({
  providedIn: 'root',
})
export class OpmsGlassService {
  baseUrl = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) {}
  loadBaseDetails(data: any): any {
    return this.http.post(this.baseUrl + 'MES/GetBaseDetail', data);
  }
  submitshiftOPMSinfo(data: any): any {
    return this.http.post(this.baseUrl + 'OPMS/SaveShiftInfoOPMS', data);
  }
  GetDailyConsumption(data: any): any {
    return this.http.post(this.baseUrl + 'OPMS/GetDailyConsumption', data);
  }
​
  getMonthlyStock(data: any): any {
    return this.http.post(this.baseUrl + 'OPMS/GetMonthlyStock', data);
  }
​
  uploadFile(data: any): Observable<Success> {
    return this.http.post<Success>(this.baseUrl + 'OPMS/UploadFile', data);
  }
​
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
​
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: Excel_Type });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + Excel_Extension
    );
  }
​
  getBR(data: any): any {
    return this.http.post(this.baseUrl + 'OPMS/GetBR', data);
  }
​
  saveBR(data: any): any {
    return this.http.post(this.baseUrl + 'OPMS/SaveBR', data);
  }
​
  getMaster(data: any): any {
    return this.http.post(this.baseUrl + 'OPMS/GetMasterData', data);
  }
  saveMaster(data: any): any {
    return this.http.post(this.baseUrl + 'OPMS/SaveMasterData', data);
  }
  loadshiftOPMSHistory(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMS/GetShiftOPMSReportHistory',
      data
    );
  }
  deleteShiftOPMSReport(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/SaveShiftInfoOPMS', data);
  }
  getHourlyData(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/GetHourlyData', data);
  }
  loadHourlyData(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/GetHourlyData', data);
  }
  submitHourlyData(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/SaveHourlyData', data);
  }
  deleteHourlyData(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/SaveHourlyData', data);
  }
  getEvent(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/GetEvent', data);
  }
  submitEvent(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/SaveEvent', data);
  }
​
  deleteEvent(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/SaveEvent', data);
  }
​
  getBoxtypeDropdown() {
    return this.http.get(this.baseUrl + 'OPMS/GetBoxTypeDropdown');
  }
​
  getQualityDropdown() {
    return this.http.get(this.baseUrl + 'OPMS/GetQualityDropdown');
  }
​
  getStackerDropdown() {
    return this.http.get(this.baseUrl + 'OPMS/GetStackerDropdown');
  }
​
  getDestinationDropdown() {
    return this.http.get(this.baseUrl + 'OPMS/GetDestinationDropdown');
  }
​
  submitProductionLog(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/SaveProductionLog', data);
  }
​
  getProductionLog(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/GetProductionLog', data);
  }
​
  getProductionLogByOrderNo(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMS/GetProductionLogByOrderNo',
      data
    );
  }
​
  saveRepacking(data: any): any {
    return this.http.post(this.baseUrl + 'OPMS/SaveRepacking', data);
  }
​
  getRepacking(data: any): any {
    return this.http.post(this.baseUrl + 'OPMS/GetRepacking', data);
  }
​
  getYieldLoss(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/GetShiftYeildLossOPMS', data);
  }
​
  yieldLossParameters(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/GetKpiParametersOPMS', data);
  }
​
  getYieldHoulyDataByTime(data: any) {
    return this.http.post(this.baseUrl + 'OPMS/GetHourlyDataByTime', data);
  }
​
  submitYieldLoss(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMS/SumbitShiftParametersOPMS',
      data
    );
  }

  GetPullAndAvgPull(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPullAndAvgPull',
      data
    );
  }

  getPackAndAvgPack(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPackAndAvgPack',
      data
    );
  }

  getChangeOverAndNetChange(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetChangeOverAndNetChange',
      data
    );
  }
​
  getMonthlyReviewData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetMonthlyReviewData',
      data
    );
  }

  getPhysicalData(data: any){
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPhysicalLabData',
      data
    );
  }
​
  GetYieldPercentageByCrewData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetYieldPercentageByCrew',
      data
    );
  }
​
  GetLossClassificationData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetLossClassification',
      data
    );
  }
​
  GetQuantityByThicknessData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetQuantityByThickness_Ton',
      data
    );
  }
​
  GetAvgTrimLossByThicknessData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetAvgTrimLossByThickness',
      data
    );
  }
​
  GetAvgTrimLossByCrewNameData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetAvgTrimLossByCrewName',
      data
    );
  }
​
  GetAvgYieldByThicknessData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetAvgYieldByThickness',
      data
    );
  }
  GetQualityByTonData(data: any) {
    return this.http.post(this.baseUrl + 'OPMSDashboard/GetQualityByTon', data);
  }
  GetThicknessWisePackingInTonsData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetThicknessWisePackingInTons',
      data
    );
  }
​
  GetDeffectIdentificationData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetDeffectIdentification',
      data
    );
  }
​
  GetTon_BoxTypeData(data: any) {
    return this.http.post(this.baseUrl + 'OPMSDashboard/GetTon_BoxType', data);
  }
​
  GetLosseVsPacked_TonData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetLosseVsPacked_Ton',
      data
    );
  }
​
  GetWaterFallChartForYTDData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetWaterFallChartForYTD',
      data
    );
  }
​
  GetSizeWiseReportData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetSizeWiseReport',
      data
    );
  }
​
  GetThicknessWiseReportData(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetThicknessWiseReport',
      data
    );
  }
  GetDayWiseReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetDayWiseReport',
      data
    );
  }
  GetCrewWiseReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetCrewWiseReport',
      data
    );
  }
  GetShiftWiseReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetShiftWiseReport',
      data
    );
  }
  GetShiftdetailReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetShiftWiseDetailReport',
      data
    );
  }
  GetPrintProductionReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintProductionReport',
      data
    );
  }
  GetPrintYieldLossesReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintYieldLossesReport',
      data
    );
  }
  GetPrintYieldLossesCommentsReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintYieldLossesCommentsReport',
      data
    );
  }
  GetPrintBloomZebraReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintBloomZebraReport',
      data
    );
  }
  GetPrintMuStrainReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintMuStrainReport',
      data
    );
  }
  GetPrintCorrugationGradeReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintCorrugationGradeReport',
      data
    );
  }
  GetPrintOtherQualityReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintOtherQualityReport',
      data
    );
  }
  GetPrintEventsReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintEventsReport',
      data
    );
  }
  GetPrintDefectsReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintDefectsReport',
      data
    );
  }
  GetPrintDefectsCountReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintDefectsCountReport',
      data
    );
  }
  GetPrintManualDefectsReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintManualDefectsReport',
      data
    );
  }
  GetPrintManualDefectsCountReport(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetPrintManualDefectsCountReport',
      data
    );
  }
  GetFilterParams(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetFilterParams',
      data
    );
  }
  GetNcrTypeWise(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetNcrTypeWise',
      data
    );
  }
  GetNcrStatusWise(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetNcrStatusWise',
      data
    );
  }
  SaveDeletedDataForOPMS(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMS/SaveDeletedDataForOPMS',
      data
    );
  }
  GetYieldDetailDayWise(data: any) {
    return this.http.post(
      this.baseUrl + 'OPMSDashboard/GetYieldDetailDayWise',
      data
    );
  }
}