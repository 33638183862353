import { QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CardItem } from 'src/app/models/uimodels/cardItem';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { PlanGroupComponent } from 'src/app/models/O3AdministrationModels/plant-model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
// import { DefectConfigurationService } from 'src/app/services/defect-configuration.service';
import { CilConfigurationService } from 'src/app/services/cil-configuration.service';
import { CILManagementElement } from 'src/app/models/CILManagementElement';
import { flatten } from 'lodash-es';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
// import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import * as _moment from 'moment';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { environment } from 'src/environments/environment';
const moment = _moment;
@Component({
  selector: 'app-cil-management',
  templateUrl: './cil-management.component.html',
  styleUrls: ['./cil-management.component.scss'],
})
export class CilManagementComponent implements OnInit {
  filteredGroups!: any[];
  filteredPlant!: any[];
  formMode!: boolean;
  hideBtn = true;
  hidesidePanel = false;
  displayMAchineModal = false;
  displayComponentModal = false;
  displayActionModal = false;
  homeCardList!: CardItem[];
  breadcrumList!: CardItem[];
  IsWrite: any;
  IsAccess: any;
  CilManagDataForm!: FormGroup;
  CilActionDataForm!: FormGroup;
  performCILItemForm!: FormGroup;
  flattenArr: any;
  hideImage = false;
  disableRow = false;
  plant: any;
  ImageUrl!: any;
  currentImagePath!: any;
  units: any;
  filteredUnits: any;
  components: any;
  filteredcomponents: any;
  isButtonEnable = true;
  Line: any;
  filteredLine: any;
  assembly: any;
  filteredAssembly: any;
  moduleId!: any;
  pageId!: any;
  cilManagementExecuted: MatTableDataSource<CILManagementElement>;
  AssignedUsers: any;
  searchValue = '';
  constructor(
    private spinner: NgxSpinnerService,
    public router: Router,
    private userManagementService: UserManagementService,
    private platModelService: EnterPriseGroupService,
    private toaster: ToastrService,
    private cilService: CilConfigurationService,
    private fb: FormBuilder,
    private machineService: MachineMappingService,
    private dialog: MatDialog,
  ) {
    this.selection.changed.subscribe((item) => {
      this.isButtonEnable =
        this.selection.selected.filter((c: any) => c.submitted === false)
          .length < 2;
    });
  }

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('displayResponsibleModal') private displayResponsibleModal!: TemplateRef<any>;
  treeControl = new NestedTreeControl<any>((node) => node.children);
  plantGroupSource = new MatTreeNestedDataSource<any>();
  compTreeSource = new MatTreeNestedDataSource<any>();
  machineTreeSource = new MatTreeNestedDataSource<any>();
  plantGroupComponentSource = new MatTreeNestedDataSource<PlanGroupComponent>();
  treeControlComponent = new NestedTreeControl<PlanGroupComponent>(
    (node) => node.children
  );

  // Add new variable Group Form
  isEditCILModelVisible = false;

  // Drop-down Variables
  selected: string | undefined;
  selectedMachine: string | undefined;
  selectedComponent: string | undefined;
  selectedGroup!: string;

  // upload file name
  uploadFileInfo!: File;
  file: File | undefined = undefined;

  // Dropdown Values
  groups!: any[];
  plants!: any[];
  lineList!: any[];
  machineList!: any[];
  componentList!: any[];
  componentId!: any;
  machineId!: any;
  selectedTaskId!: any;
  selectedTaskComp!: any;
  show = false;
  plantId: any;
  // Date-Time Picker Variable
  startTime!: any;
  public date!: moment.Moment;
  public color: ThemePalette = 'primary';
  public showSpinners = true;
  // Icon Url
  grpImgurl = 'assets/images/grp-icon.png';
  pltImgurl = 'assets/images/plt-icon.png';
  divImgurl = 'assets/images/div-icon.png';
  dptImgurl = 'assets/images/dpt-icon.png';
  linImgurl = 'assets/images/lin-icon.png';
  untImgurl = 'assets/images/unt-icon.png';
  asmImgurl = 'assets/images/asm-icon.png';
  samImgurl = 'assets/images/sam-icon.png';
  copImgurl = 'assets/images/cop-icon.png';
  collapseAllImgurl = 'assets/images/collapse-all-icon.png';
  expandAllImgurl = 'assets/images/expand-all-icon.png';
  downloadIconurl = 'assets/images/download-image-icon.png';
  uploadIconurl = 'assets/images/upload-icon.png';
  editIconurl = 'assets/images/edit-icon.png';
  deleteIconurl = 'assets/images/delete-icon.png';
  messageIconurl = 'assets/images/message-icon.png';
  

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
  });

  CILManagementElementData: CILManagementElement[] = [];
  cilmanagementColumns: string[] = [
    'isChecked',
    'action',
    'task',
    'type',
    'unit',
    'component',
    'frequency',
    'nexttime',
    'image',
    'remarks',
    'postImage',
    'postRemarks',
    'sop',
    
  ];
  executedCilmanagementColumns: string[] = [
    'isChecked',
    'task',
    'type',
    'unit',
    'component',
    'frequency',
    'nexttime',
    'image',
    'remarks',
    'postImage',
    'postRemarks',
    'sop',
    'lastExecutedDate'
  ];
  cilManagementVariableGroupData!: MatTableDataSource<CILManagementElement>;
  selection = new SelectionModel<CILManagementElement>(true, []);

  expandedElement: any;

  ngOnInit(): void {
    this.ImageUrl = environment.O3CoreApiImageUrl;
    this.CilManagDataForm = this.fb.group({
      id: [''],
      line: new FormControl('', []),
      unit: new FormControl('', []),
      assembly: new FormControl('', []),
      group: new FormControl('', []),
      plant: new FormControl('', []),
      component: new FormControl('', []),
      machine: new FormControl('', []),
      machineId: new FormControl('', []),
    });

    this.performCILItemForm = this.fb.group({
      // id :[''],
      option: new FormControl('', [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      Remarks: new FormControl('', [Validators.required]),
      postImage: new FormControl(''),
    });
    this.GetMenuAccess();
    this.GetMenuChildList();
    this.GetBreadcrumList();
    this.getGroups();
    this.performCILItemForm.get('option')?.valueChanges.subscribe((val) => {
      if (val === 'NotDone') {
        this.performCILItemForm.controls.Remarks.setValidators([
          Validators.required,
        ]);
      } else {
        this.performCILItemForm.controls.Remarks.clearValidators();
      }
      this.performCILItemForm.controls.Remarks.updateValueAndValidity();
    });
    // this.getDefectMgmtTableData();
    // this.CilManagDataForm.get('machine')?.setValue(this.machineList[0].machineId);
  }

  public checkErrorType = (controlName: string, errorName: string) => {
    return this.performCILItemForm.controls[controlName].hasError(errorName);
  };

  openActionModal(): void {
    this.formMode = true;
    this.onRemove(this.file);
    this.displayActionModal = true;
  }
  closeActionModal(): void {
    this.displayActionModal = false;
  }

  closeaddNewPlantItemModel(): void {
    this.displayMAchineModal = false;
    this.displayComponentModal = false;
  }

  closeaddNewVariableModel(): void { }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  GetMenuChildList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetMenuChildList(obj).subscribe(
      (data: any) => {
        this.homeCardList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.cilService.getGroupData(key).subscribe(
      (res: any) => {
        this.groups = res.masterList;
        this.CilManagDataForm.controls.group.setValue(this.groups[0].TypeId);
        this.selectedGroup = this.groups[0].TypeId;
        this.GetPlantByEgDDL();
        if (this.groups.length > 0) {
          this.filteredGroups = this.groups.slice();
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  GetPlantByEgDDL(): void {
    const key = {
      GroupId: this.selectedGroup,
    };
    this.searchValue = '';
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.CilManagDataForm.get('plant')?.setValue(data.DepartList[0].DeptId);
        this.getLine();
        if (this.plants.length > 0) {
          this.filteredPlant = this.plants.slice();
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getLine(): void {
    this.spinner.show();
    this.searchValue = ''
    const key = {
      PT_ID: this.CilManagDataForm.value.plant,
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.Line = [];
    this.filteredLine = [];
    this.units = [];
    this.filteredUnits = [];
    // this.UnitList = [];
    this.assembly = [];
    this.filteredAssembly = [];
    this.components = [];
    this.filteredcomponents = [];
    // this.ComponentList = [];
    this.machineService.getLineList(key).subscribe(
      (data: any) => {
        this.Line = data.lstLine;
        this.filteredLine = this.Line.slice();
        this.CilManagDataForm.get('line')?.setValue('0');
        this.getUnits();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getUnits(): void {
    var selectedLine: any[] = [];
    if (this.CilManagDataForm.value?.line == 0) {
      for (var i = 0; i < this.Line?.length; i++) {
        selectedLine.push(this.Line[i].PL_Id);
      }
    }
    else {
      selectedLine.push(this.CilManagDataForm.value?.line)
    }
    const key = {
      PL_ID: selectedLine.toString(),
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.units = [];
    this.filteredUnits = [];
    // this.UnitList = [];
    this.assembly = [];
    this.filteredAssembly = [];
    this.components = [];
    this.filteredcomponents = [];
    // this.ComponentList = [];
    this.machineService.getUnitList(key).subscribe(
      (data: any) => {
        this.units = data.lstUnit;
        // this.UnitList = data.lstUnit;
        // for(const unit of this.UnitList)
        //  this.getComponentsList(unit.puId)
        this.filteredUnits = this.units.slice();
        this.CilManagDataForm.get('machine')?.setValue('0');
        // if(this.task.length !== 0 && id == 2){
        //   this.CiltaskDataForm.get('unit')?.setValue(this.task.unitId);
        //   this.getAssembly(2);
        // }
        // else{
        //   this.getAssembly(1);
        // }
        this.getAssembly();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getAssembly(): void {
    var selectedUnit: any[] = [];
    if (this.CilManagDataForm.value?.machine == 0) {
      for (var i = 0; i < this.units?.length; i++) {
        selectedUnit.push(this.units[i].PU_Id);
      }
    }
    else {
      selectedUnit.push(this.CilManagDataForm.value?.machine)
    }
    const key = {
      PU_ID: selectedUnit.toString(),
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.assembly = [];
    this.filteredAssembly = [];
    this.components = [];
    this.filteredcomponents = [];
    // this.ComponentList = [];
    this.machineService.getAssemblyList(key).subscribe(
      (data: any) => {
        this.assembly = data.lstasmb;
        this.filteredAssembly = this.assembly.slice();
        this.CilManagDataForm.get('assembly')?.setValue('0');
        // if(this.task.length !== 0 && id == 2){
        //   this.CiltaskDataForm.get('assembly')?.setValue(this.task.Assbly_Id);
        //   this.getComponents(2);
        // }
        // else{
        //   this.getComponents(1);
        // }
        this.getComponents();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getComponents(): void {
    var selectedAssemby: any[] = [];
    if (this.CilManagDataForm.value?.assembly == 0) {
      for (var i = 0; i < this.assembly?.length; i++) {
        selectedAssemby.push(this.assembly[i].Asmb_Id);
      }
    }
    else {
      selectedAssemby.push(this.CilManagDataForm.value?.assembly)
    }
    const key = {
      A_ID: selectedAssemby.toString(),
    };
    this.components = [];
    this.filteredcomponents = [];
    // this.ComponentList = [];
    this.machineService.getComponentListByAssembly(key).subscribe(
      (data: any) => {
        this.components = data.lstComponent;
        this.CilManagDataForm.get('component')?.setValue('0');
        // this.ComponentList = data.lstComponent;
        this.filteredcomponents = this.components.slice();
        // if (this.taskFormMode) {
        //   this.CiltaskDataForm.get('component')?.setValue(this.components[0].ComponentId);
        // }
        // if(this.task.length !== 0 && id == 2){
        //   this.CiltaskDataForm.get('component')?.setValue(this.task.componentId);
        // }
        this.getDefectMgmtTableData();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getDefectMgmtTableData(): void {
    this.spinner.show();
    this.selection.clear();
    const key = {
      egId: this.selectedGroup,
      plantId: this.CilManagDataForm.value.plant,
      compId: this.CilManagDataForm.value.component === '0' ? 0 : this.CilManagDataForm.value.component,
      lineId: this.CilManagDataForm.value.line === '0' ? 0 : this.CilManagDataForm.value.line,
      assemblyId: this.CilManagDataForm.value.assembly === '0' ? 0 : this.CilManagDataForm.value.assembly,
      machineId: this.CilManagDataForm.value.machine === '0' ? 0 : this.CilManagDataForm.value.machine,
    };
    this.cilService.GetCILData(key).then(
      (res: any) => {
        debugger;
        const list = res.GetCILList
        list.forEach((elem: any) => {
             if(elem.submitted === false)
             {
               elem.expiryDate = new Date(new Date(elem.nextTime).getTime()-60000)    
             }
        });
        if (list && list.length) {
          this.cilManagementVariableGroupData =
            new MatTableDataSource<CILManagementElement>(list.filter(c => c.submitted === false));
          this.cilManagementExecuted =
            new MatTableDataSource<CILManagementElement>(list.filter(c => c.submitted));
        } else {
          this.cilManagementVariableGroupData = new MatTableDataSource<CILManagementElement>([]);
          this.cilManagementExecuted = new MatTableDataSource<CILManagementElement>([]);
        }
        this.getPaginator(this.cilManagementVariableGroupData, 0);
        // this.getPaginator(this.cilManagementExecuted, 0);
        setTimeout(() => {
          this.cilManagementExecuted.paginator = this.paginator.toArray()[1];
          this.cilManagementExecuted.sort = this.sort.toArray()[1];
        }, 1000);
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      },
      (error: any) => {
        throw error;
      }
    );
    this.selection.selected.length = 0;
    this.isButtonEnable = true;
    // this.masterToggle();
  }

  SelectUnitName(machine: any): void {
    if (machine.text !== null) {
      this.CilManagDataForm.get('machine')?.setValue(machine.text);
      this.hideBtn = false;
      this.machineId = machine.PrimaryId;
      this.displayMAchineModal = false;
      this.startTime = new Date();
      //  this.performCILItemForm.get('startDate')?.setValue(s);
      this.getDefectMgmtTableData();
    }
  }

  SelectComponent(component: any): void {
    if (component.text !== null) {
      this.CilManagDataForm.get('component')?.setValue(component.text);
      this.componentId = component.id;
      this.displayComponentModal = false;
      this.getDefectMgmtTableData();
    }
  }

  onNodeSelect(node: any): void {
    // debugger;
  }

  onNodeSelectForComp(node: any): void {
    // debugger;
    if (node.Level === 'Component') {
      //
    }
  }

  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }
  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  collapseAllNodeComp(): void {
    this.treeControl.collapseAll();
  }

  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  expandAllNodeComp(): void {
    this.treeControl.expandAll();
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  // parent node
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  // open edit CIL variable group model
  openEditCILGroupModel(element: CILManagementElement): void {
    // this.editGroupItemParent = element;
    console.log('editGroupItemParent:', '');
  }
  openCILExecformModel(): void {
    this.formMode = true;
    this.performCILItemForm.reset();
    this.isEditCILModelVisible = true;
    const t = new Date();

    this.performCILItemForm.get('endDate')?.setValue(t);
    this.performCILItemForm.get('startDate')?.setValue(t);
    this.performCILItemForm.get('postImage')?.reset();
    //  if(this.CilManagDataForm.get('startDate')?.value !== null){
    //    this.hidesidePanel = true
    //  }else{
    //    this.hidesidePanel = false
    //  }
    //  this.selectedTaskId = element.id;
    //  const showInput = false;
    //   this.hideImage = showInput;
  }

  openCILPerformModel(element: CILManagementElement): void {
    this.performCILItemForm.reset();
    this.formMode = false;
    this.isEditCILModelVisible = true;
    this.selectedTaskId = element.id;
    this.selectedTaskComp = element.componentId;
    console.log('openCILPerformModel:', '');
    this.onRemove(this.file);
    const t = new Date();
    this.performCILItemForm.get('endDate')?.setValue(t);
    this.performCILItemForm.get('startDate')?.setValue(t);
    this.performCILItemForm.get('postImage')?.reset();
    if (this.CilManagDataForm.get('machine')?.value === '') {
      this.toaster.error('please select machine first');
      this.isEditCILModelVisible = false;
    }
  }
  // openCilActionFormModel(element: CILManagementElement): void {
  //   this.isEditCILModelVisible = true;
  //   this.selectedTaskId = element.id;
  //   this.selectedTaskComp = element.componentId;
  //   console.log('openCILPerformModel:', '');

  // }

  saveCilAction(): void {
    debugger;
    const arr: any[] = [];
    const data = this.selection.selected.filter(
      (c: any) => c.submitted === false
    );
    data.forEach((element) => {
      const si = {
        taskId: element.id, // this.taskForm.value.plantId,
        rbDone: this.performCILItemForm.value.option,
        startTime: this.performCILItemForm.value.startDate,
        endTime: new Date(), // this.performCILItemForm.value.endDate,
        userId: JSON.parse(localStorage.user).UserId,
        remarks: this.performCILItemForm.value.Remarks,
        componentId: this.CilManagDataForm.value.component,
        taskImage: '',
        taskImageName: '',
      };
      arr.push(si);
    });
    this.cilService.performCilAction(arr).subscribe(
      (res: any) => {
        debugger;
        this.toaster.success('Action Saved Successfully');
        this.selection.clear();
        this.getDefectMgmtTableData();
        this.isEditCILModelVisible = false;
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  SavePerformTask(): void {
    const formData: any = new FormData();
    formData.append('Imagefile', this.file);
    formData.append(
      'obj',
      JSON.stringify({
        taskId: this.selectedTaskId, // this.taskForm.value.plantId,
        rbDone: this.performCILItemForm.value.option,
        startTime: this.performCILItemForm.value.startDate,
        endTime: new Date(), // this.performCILItemForm.value.endDate,
        userId: JSON.parse(localStorage.user).UserId,
        remarks: this.performCILItemForm.value.Remarks,
        componentId: this.CilManagDataForm.value.component,
        taskImage: '',
        taskImageName: '',
      })
    );
    this.spinner.show();
    this.cilService.PerformCilTask(formData).subscribe(
      (res: any) => {
        if (res.HasError === false) {
          this.toaster.success('Task Performed Successfully');
          this.selection.clear();
          this.closeCILPerformModel();
          this.performCILItemForm.reset();
          this.getDefectMgmtTableData();
          this.spinner.hide();
        } else {
          this.toaster.error('error', res.ErrorMessage);
        }
      },
      (error: any) => {
        this.toaster.error('error', error.ErrorMessage);
      }
    );
  }
  // onFileSelect(event: any): void {
  //   debugger;
  //   this.postImageFileInfo = event.target.files[0];
  //   this.uploadFileInfo = event.target.files[0]
  //     ? event.target.files[0].name
  //     : 'No Image';
  // }
  onFileSelect(event: any): void {
    this.file = undefined;
    this.file = event.addedFiles[0];
  }
  onRemove(event: any): void {
    this.file = undefined;
  }
  getEndTime(): void {
    const d = new Date();
  }

  // close machine parameter variable  model
  closeCILPerformModel(): void {
    this.isEditCILModelVisible = false;
    this.performCILItemForm.reset();
    // this.addNewVariableItemlForm.reset();
  }

  reset(): void {
    this.CilManagDataForm.reset();
    this.getDefectMgmtTableData();
  }

  deleteCIL(element: any): void { }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
      data.sort = this.sort.toArray()[index];
    }, 1000);
  }

  applyFilter(event: any): void {
    // this.spinner.show();
    const filterValue = (event.target as HTMLInputElement).value;
    this.cilManagementVariableGroupData.filter = filterValue
      .trim()
      .toLowerCase();

    if (this.cilManagementVariableGroupData.paginator) {
      this.cilManagementVariableGroupData.paginator.firstPage();
    }
  }

  // onFileSelect(input: any): void {
  //   const file = input.files[0];
  //   this.uploadFileInfo = `${file.name}`;
  // }

  isAllSelected(): any {
    if (this.selection.selected.length > 1) {
      this.show = true;
    } else {
      this.show = false;
    }

    const numSelected = this.selection.selected.length;
    const numRows = this.cilManagementVariableGroupData.data.filter((x: any) => {
      return x.Executable === true;
    }).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected()
      ? (this.selection.clear(), this.checkBoxCount())
      : this.cilManagementVariableGroupData.data.forEach((row: any) => row.Executable === true ?  this.selection.select(row) : ''
      );
  }

  checkBoxCount(): void {
    this.show = false;
  }

  imageZoomModal(imagePath: String): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  ViewResponsible(elem: any): void {
    this.AssignedUsers = [];
    this.getTaskAssignedUsers(elem);
  }
  getTaskAssignedUsers(param: any): void {
    this.spinner.show();
    const key = {
      PlantId: this.CilManagDataForm.value.plant ,
      TaskId : param.id
    };
    this.cilService.getTaskAssignedUsers(key).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.dialog.open(this.displayResponsibleModal, { height: 'auto', width: '45%' , maxHeight : '50vh' });
        this.AssignedUsers = response?.data;
        // this.getTaskList();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error',error.error.Message);
      }
      );
  }
}
