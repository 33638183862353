import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProcessControlService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  async getParameterProcessControl(data: any): Promise<any> {
    return await this.http
      .post<any>(this.o3CoreApiUrl + 'Quality/ProcessControl', data)
      .toPromise();
  }
  async getProcessControl(data: any): Promise<any> {
    return await this.http.post<any>(this.baseUrl + 'getProcessControl', data).toPromise();
  }
  saveProessControl(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'Quality/SaveProcessControl', data, {
      headers: header,
    });
  }
  getProcessParameterAnalysis(data: any): any{
    return this.http
      .post<any>(this.o3CoreApiUrl + 'ProcessControl/AnalysisChart', data)
  }
  async getQualityProcessControl(data: any): Promise<any> {
    return await this.http
      .post<any>(this.o3baseUrl + 'Quality/QualityProcessControl', data)
      .toPromise();
  }
  saveQualityProcessControl(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(
      this.baseUrl + 'saveQualityProcessControl',
      data,
      { headers: header }
    );
  }
  async getQualityProcessParameterAnalysis(data: any): Promise<any> {
    return await this.http
      .post<any>(this.baseUrl + 'getQualityProcessParameterAnalysis', data)
      .toPromise();
  }
  async getQualityProcessParameterComparison(data: any): Promise<any> {
    return await this.http
      .post<any>(this.baseUrl + 'getQualityProcessParameterComparison', data)
      .toPromise();
  }
  async getParameterControlComparison(data: any): Promise<any> {
    return await this.http
      .post<any>(this.o3CoreApiUrl + 'ProcessControl/ComparisonChart', data)
      .toPromise();
  }
  processparamaterdefects(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'ProcessControl/GetParameterDefect', data, {
      headers: header,
    });
  }
  processparameterspecs(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'ProcessControl/GetParameterSpecification', data, {
      headers: header,
    });
  }
  getQualityControlProcessOrder(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(
      this.o3baseUrl + 'ProcessControl/GetPOList',
      data,
      { headers: header }
    );
  }
  getMachineParameterProcessType(data: any): any {
    return this.http.post<any>(
      this.o3CoreApiUrl + 'ProcessControl/getMachineParameterProcessType',
      data
    );
  }
  getProcessControlInterval(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'ProcessControl/GetParameterIntervals',
      data
    );
  }
  getQualityGroupEvents(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'getQualityGroupEvents', data);
  }
  getVariableGroupEvents(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ProcessControl/GetVariableGroupEvents', data);
  }
  getParamterGroupList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ProcessControl/GetParameterGroupList', data);
  }
}
