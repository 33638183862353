<div mat-dialog-title>
  <div class="d-flex justify-content-between align-items-center">
    <h2>Reschedule Meeting</h2>
    <button
      mat-mini-fab
      mat-button
      mat-dialog-close
      color="warn"
      title="close"
    >
      <i class="fa fa-times" style="color: #fff"></i>
    </button>
  </div>
</div>
<form [formGroup]="rescheduleForm">
  <div class="content-row-wrap">
    <mat-form-field
      class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      style="width: 100%"
    >
      <mat-label>Meeting Name*</mat-label>
      <input rows="4" matInput formControlName="meetingName" readonly />
      <mat-error>meeting name required</mat-error>
    </mat-form-field>
    <mat-form-field
      class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      style="height: auto; width: 100%"
    >
    <mat-label>Meeting Subject*</mat-label>
      <mat-chip-list #chipList1 formControlName="meetingSubject">
          <mat-chip
            *ngFor="let agenda of Agendas"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(participant)">
            {{agenda.MeetingAgenda}}
          </mat-chip>
        </mat-chip-list>
      
      <mat-error>meeting subject required</mat-error>
    </mat-form-field>
    <mat-form-field
      class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      style="width: 100%; height: auto"
    >
      <mat-label>Meeting Participants</mat-label>
      <mat-chip-list #chipList formControlName="meetingParticipants">
        <mat-chip
          *ngFor="let participant of ParticipantSelectedList"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(participant)">
          {{participant.participantName}}
         
        </mat-chip>
        
      </mat-chip-list>
      <!-- <mat-select formControlName="meetingParticipants" multiple>
        <mat-select-trigger>
          <mat-chip-list>
            <mat-chip
              *ngFor="
                let user of getParticipantsForm('meetingParticipants')
              "
              [removable]="true"
              (removed)="onParticipantsRemoved(user)"
            >
              {{ user?.empFullName }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </mat-select-trigger>
        <mat-option *ngFor="let user of UserDDL" [value]="user">{{
          user?.empFullName
        }}</mat-option>
      </mat-select> -->
    </mat-form-field>
  </div>
  <h3 class="subheading">Meeting Time:</h3>
  <div class="content-row-wrap">
    <div class="inputField">
      <nz-time-picker 
        style="width: 100%; margin-left: 0px !important; height: 50px"
        class="calender-input"
        nzFormat="HH:mm:ss "
        formControlName="rescheduleStartTime"
        
      ></nz-time-picker>
      <span class="floating-text" style="padding-left: 0px"
        >Start Time</span
      >
    </div>

    <div class="inputField">
      <nz-time-picker
        nzFormat="HH:mm:ss "
        style="width: 100%; margin-left: 0px !important; height: 50px"
        class="calender-input"
        formControlName="rescheduleEndTime"
        (ngModelChange)="TimeChange($event)"
      ></nz-time-picker>
      <span class="floating-text" style="padding-left: 0px" >End Time</span>
    </div>
    <mat-form-field
      class="top-row-first-filed-item top-row-first-filed-item-order-processing" 
      
    >
      <mat-label>Duration:</mat-label>
      <input matInput formControlName="duration" readonly="true" />
    </mat-form-field>
  </div>
  <h3 class="subheading">Recurrence Pattern:</h3>
  <div class="d-flex justify-content-between">
    <div style="width: 15%">
      <mat-radio-group
        style="
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        "
        required
        aria-label="Select an option"
        (change)="changePattern($event)"
        formControlName="RecurrneceType"
      >
        <mat-radio-button value="Daily"  >Daily</mat-radio-button>
        <mat-radio-button value="Weekly" >Weekly</mat-radio-button>
        <mat-radio-button value="Monthly" >Monthly</mat-radio-button>
        <mat-radio-button value="Yearly" >Yearly</mat-radio-button>
      </mat-radio-group>
    </div>
    <div style="width: 80%">
      <div *ngIf="dailypattern" style="display: flex">
        <div>
          <mat-radio-group
            style="
              display: flex;
              justify-content: space-between;
              flex-direction: column;
            "
            aria-label="Select an option"
            formControlName="RecurEveryWeekDay"
            (change)="changedaysNumber($event)"
          >
            <mat-radio-button value="false">No of Days</mat-radio-button>
            <mat-radio-button value="true"
              >Every Weekday</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div class="content-top-row" style="margin-top: 0px">
          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 80%"
          >
            <mat-label>Day(s)</mat-label>
            <input type="number"   formControlName="RecurIntervalDayDaily" matInput [disabled]="this.nofdays" />
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="weeklypattern">
        <div class="content-row-wrap">
          <mat-label class="field-side-label">Recur Every</mat-label>
          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="margin-top: 0px; margin-left: 25px"
          >
            <mat-label>week(s)</mat-label>
            <input formControlName="RecurIntervalWeekly" (keyup)="ValidatingField()" type="number" matInput />
          </mat-form-field>
          <mat-label class="field-side-label">on:</mat-label>
        </div>
        <div class="content-row-wrap">
          <!-- <label *ngFor="let days of weekdays; let i = index">
          <mat-checkbox (change)='onChange(days.name)'  formControlName="RecurDaysOfWeekWeekly" class="checkbox-list" 
          [checked]="days.Checked"
          >{{ days.name }}
          </mat-checkbox>
           <input (change)='onChange(days.name)' type="checkbox" formControlName="RecurDaysOfWeekWeekly" class="form-check-input" 
         [checked]='true'
          />{{ days.name }} 
          
        </label> -->
        <div formArrayName="RecurDaysOfWeekWeekly"  
          *ngFor="let days of weekdays; let i = index;">  
          <mat-checkbox (change)='onChange(days.name)'    class="checkbox-list" 
          [checked]="days.Checked"
          >{{ days.name }}
          </mat-checkbox>
          </div>    
        </div>
      </div>
      <div *ngIf="monthlypattern">
        <div class="d-flex">
          <div>
            <mat-radio-group
              style="
                display: flex;
                justify-content: space-between;
                flex-direction: column;
              "
              aria-label="Select an option"
              (change)="changeMonthlyNumbers($event)"
              formControlName="RecurringInDaysOfMonthly"
            >
              <mat-radio-button class="select-radio" value="true"
                >Day</mat-radio-button
              >
              <mat-radio-button class="select-radio" value="false"
                >The</mat-radio-button
              >
            </mat-radio-group>
          </div>
          <div>
            <div class="content-top-row">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 40%"
              >
                <mat-label>Day(s)</mat-label>
                <input formControlName="RecurDayOfMonthMonthly" type="number" matInput [disabled]="this.monthsday" />
              </mat-form-field>
              <mat-label
                style="
                  width: 20%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                of every</mat-label
              >
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 40%"
              >
                <mat-label>Month(s)</mat-label>
                <input formControlName="RecurIntervalOfMonthMontly" type="number" matInput [disabled]="this.monthsday" />
              </mat-form-field>
            </div>
            <div class="content-top-row">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 30%"
              >
                <mat-label>Week</mat-label>
                <mat-select formControlName="RecurWeekOfMonthMontly" [disabled]="this.monthsselect">
                  <mat-option
                    *ngFor="let week of weekscount"
                    [value]="week.countId"
                  >
                    {{ week.count }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 30%"
              >
                <mat-label>Week Day</mat-label>
                <mat-select formControlName="RecurDaysOfWeekMonthly" [disabled]="this.monthsselect">
                  <mat-option
                    *ngFor="let week of weekdays"
                    [value]="week.Id"
                  >
                    {{ week.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-label
                style="
                  width: 20%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                of every</mat-label
              >
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 20%"
              >
                <mat-label>Month(s)</mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="RecurIntervalOfMonthMontlyRelative"
                  [disabled]="this.monthsselect"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="yearlypattern">
        <div class="content-top-row" style="margin-top: 0px">
          <mat-label
            style="
              width: 10%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            Rercur every:</mat-label
          >
          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 20%"
          >
            <mat-label>Year(s)</mat-label>
            <input formControlName="RecurIntervalofYearsYearly" type="number" matInput />
          </mat-form-field>
        </div>
        <div class="d-flex">
          <div>
            <mat-radio-group
              style="
                display: flex;
                justify-content: space-between;
                flex-direction: column;
              "
              formControlName="RecurAbsoluteYearly"
              aria-label="Select an option"
              (change)="changeYearlyNumbers($event)"
            >
              <mat-radio-button class="select-radio" value="true"
                >On</mat-radio-button
              >
              <mat-radio-button class="select-radio" value="false"
                >on The</mat-radio-button
              >
            </mat-radio-group>
          </div>
          <div>
            <div class="content-top-row">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 20%"
              >
                <mat-label>Month</mat-label>
                <mat-select  formControlName="RecurMonthYearly" [disabled]="this.inputAdd">
                  <mat-option
                    *ngFor="let month of months"
                    [value]="month.Id"
                   
                  >
                    {{ month.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 20%"
              >
                <mat-label>Date</mat-label>
                <input formControlName="RecurDayOfMonthYearly" type="number" matInput [disabled]="this.inputAdd" />
              </mat-form-field>
            </div>
            <div class="content-top-row">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 20%"
              >
                <mat-label>Week</mat-label>
                <mat-select formControlName="RecurWeekOfMonthYearly" [disabled]="this.select">
                  <mat-option
                    *ngFor="let week of weekscount"
                    [value]="week.countId"
                  >
                    {{ week.count }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 20%"
              >
                <mat-label>Week Day</mat-label>
                <mat-select formControlName="RecurDaysOfWeekYearly"  [disabled]="this.select">
                  <mat-option
                    *ngFor="let week of weekdays"
                    [value]="week.Id"
                  >
                    {{ week.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-label
                style="
                  width: 20%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                of</mat-label
              >
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 20%"
              >
                <mat-label>Month(s)</mat-label>
                <mat-select formControlName="RecurringMonthYearly" [disabled]="this.select">
                  <mat-option
                    *ngFor="let month of months"
                    [value]="month.Id"
                  >
                    {{ month.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h3 class="subheading">Range of recurrence:</h3>
  <div class="d-flex justify-content-between">
    <div class="w-15">
      <div class="inputField" style="width: 100%">
        <nz-date-picker
          style="width: 100%; margin-left: 0px !important; height: 50px"
          class="calender-input"
          id="startPicker"
          nzFormat="dd/MM/yyyy"
          [nzInputReadOnly]="true"
          formControlName="recurrenctStartDate"
        >
        </nz-date-picker>
        <span class="floating-text" style="padding-left: 0px"
          >Start Date</span
        >
      </div>
    </div>
    <div class="w-20">
      <mat-radio-group
        (change)="changerange($event)"
        style="
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
        "
        aria-label="Select an option"
        formControlName="recurrencEndDateStatus"
      >
        <mat-radio-button value="noenddate"       >No end date</mat-radio-button>
        <mat-radio-button value="endafter"  >End after:</mat-radio-button>
        <mat-radio-button value="endby"  >End by:</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="w-50">
      <div style="padding: 12px"></div>
      <div class="content-top-row">
        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="width: 48%;margin-left: 35px;"
        >
          <mat-label>Occurences:</mat-label>
          <input type="number"   formControlName="recurrencOccurrences" matInput [disabled]="this.endAfter" />
        </mat-form-field>
      </div>
      <div style="padding: 12px">
        <div class="inputField" style="width: 50%">
          <nz-date-picker
            style="width: 100%; margin-left: 0px !important; height: 50px"
            class="calender-input"
            id="startPicker"
            nzFormat="dd/MM/yyyy"
            [nzInputReadOnly]="true"
            [nzDisabled]="this.endBy"
            formControlName="recurrencEndDate"
          >
          </nz-date-picker>
          <span class="floating-text" style="padding-left: 0px">Date</span>
        </div>
      </div>
    </div>
  </div>
  <button
      type="submit"
      mat-mini-fab
      (click)="SaveSchedule()"
    [disabled]="!rescheduleForm.valid"
      mat-button
     color ="primary"
      
      tooltipPosition="top"
    
      style="margin-left: 20px; cursor: pointer;float:right;"
    >
      <i
        class="fa fa-floppy-o"
        style="color: #fff"  
        
      ></i>
    </button>
</form>