import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrewScheduleService {
  baseUrl = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getGroups(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetGroup', data);
  }

  SubmitCrewSchedule(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CrewSchedule/SubmitCrewSchedule', data);
  }

  async GetCrewScheduleList(data: any): Promise<any[]> {
    return await this.http.post<any[]>(this.o3CoreApiUrl + 'CrewSchedule/GetCrewScheduleList', data).toPromise();
  }

  GetUserManagerList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Account/GetUserManagerList', data);
  }

  CrewUserType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CrewSchedule/CrewUserType', data);
  }

  GetCSUserList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CrewSchedule/GetCSUserList', data);
  }

  SubmitUserList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CrewSchedule/SubmitUserList', data);
  }

  SubmitRepeatCrewSchedule(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CrewSchedule/SubmitRepeatCrewSchedule', data);
  }

}
