<section class="home-content" dir="{{languageDir}}">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <mat-card>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

    <form [formGroup]="searchScorecardForm">
      <div style="padding: 10px; background-color: #0f0fd1">
        <div class="p-grid p-fluid">
          <div class="p-col-4">
            <mat-card-title style="color: white">{{langObj ? langObj?.Scorecard : ("Scorecard" |
              translate)}}</mat-card-title>
          </div>
          <div class="p-col-4">
            <p-dropdown *ngIf="groupList?.length > 0" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                  'is-invalid':
                    searchScorecardForm.get('group')?.errors &&
                    searchScorecardForm.get('group')?.touched
                }" [options]="groupList" formControlName="group"
              placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}" optionLabel="TypeName"
              [showClear]="true"></p-dropdown>
            <p class="invalid-feedback" *ngIf="
                  searchScorecardForm.get('group')?.touched &&
                  searchScorecardForm.get('group')?.hasError('required')
                ">
              {{ langObj ? langObj?.grp : ("grp" | translate) }}
            </p>
          </div>
          <div class="p-col-4">
            <p-dropdown [ngClass]="{
                  'is-invalid':
                    searchScorecardForm.get('plant')?.errors &&
                    searchScorecardForm.get('plant')?.touched
                }" [options]="searchPlants" formControlName="plant"
              placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}" optionLabel="DeptDesc"
              [showClear]="true" [filter]="true" (onChange)="getMasterData($event)"></p-dropdown>
            <p class="invalid-feedback" *ngIf="
                  searchScorecardForm.get('plant')?.touched &&
                  searchScorecardForm.get('plant')?.hasError('required')
                ">
              {{ langObj ? langObj?.plt : ("plt" | translate) }}
            </p>
          </div>
        </div>
      </div>
      <mat-card-content>
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-6 p-lg-4">
            <label class="required">{{
              langObj ? langObj?.Frequency : ("Frequency" | translate)
              }}</label>
            <p-dropdown [ngClass]="{
                  'is-invalid':
                    searchScorecardForm.get('frequency')?.errors &&
                    searchScorecardForm.get('frequency')?.touched
                }" [options]="frequencies" formControlName="frequency" defaultLabel="Select a Frequency"
              placeholder="{{langObj ? langObj?.SelectFrequency : ('SelectFrequency' | translate)}}"
              scrollHeight="300px" name="frequency" [filter]="true" optionLabel="name" (onChange)="onFrequencyChange()">
            </p-dropdown>
          </div>
          <div class="p-col-12 p-md-6 p-lg-4">
            <label>{{ langObj ? langObj?.Date : ("Date" | translate) }}</label>
            <p-calendar [ngClass]="{
                  'is-invalid':
                    searchScorecardForm.get('scorecardDate')?.errors &&
                    searchScorecardForm.get('scorecardDate')?.touched
                }" dateFormat="dd/mm/yy" formControlName="scorecardDate" [ngModel]="currentDate" appendTo="body"
              [locale]="calendar_en" [showWeek]="true" [monthNavigator]="true" [yearNavigator]="true"
              yearRange="2000:2030" inputId="navigators" (onSelect)="onDateChange($event)">
            </p-calendar>
          </div>
        </div>

        <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="rowData"
          [columnDefs]="columnDefs" [gridOptions]="gridOptions" [getContextMenuItems]="getContextMenuItems" [pagination]="true" [paginationPageSize]="10">
        </ag-grid-angular>
      </mat-card-content>
    </form>

    <div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{ active: addAnalysisModelVisible }">
      <div class="sidepanel-close" (click)="closeFrequencyModel()"></div>
      <div class="model-content" style="width: 1000px">
        <div class="model-top">
          <h2 class="model-title">
            {{ langObj ? langObj?.addaction : ("addaction" | translate) }}
          </h2>
          <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeFrequencyModel()">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
                fill="black" />
            </svg>
          </button>
        </div>
        <div class="model-body">
          <form [formGroup]="LossAnalysisForm" (ngSubmit)="onSubmit()">
            <div class="p-grid p-fluid">
              <div class="p-field p-col-3">
                <mat-form-field class="field-fill" style="margin-top: 10px">
                  <mat-label>{{
                    langObj
                    ? langObj?.AnalysisDate
                    : ("AnalysisDate" | translate)
                    }}</mat-label>
                  <input matInput [matDatepicker]="analysispicker2"
                    placeholder="{{langObj ? langObj?.AnalysisDate : ('AnalysisDate' | translate)}}"
                    formControlName="lossAnalysisDate" />
                  <mat-datepicker-toggle matSuffix [for]="analysispicker2"></mat-datepicker-toggle>
                  <mat-datepicker #analysispicker2></mat-datepicker>
                </mat-form-field>
              </div>
              <!-- <div class="p-field p-col-3">
                <label class="required">{{
                  langObj ? langObj?.kpigap : ("kpigap" | translate)
                  }}</label>
                <p-dropdown [ngClass]="{
                      'is-invalid':
                        LossAnalysisForm.get('reasonname')?.errors &&
                        LossAnalysisForm.get('reasonname')?.touched
                    }" [options]="reasonslist" formControlName="reasonname" optionLabel="name"
                  placeholder="{{langObj ? langObj?.PleaseSelect : ('PleaseSelect' | translate)}}">
                </p-dropdown>
              </div> -->
              <div class="p-field p-col-3">
                <label>{{
                  langObj ? langObj?.Remarks : ("Remarks" | translate)
                  }}</label>
                <input type="text" pInputText formControlName="remarks"
                  placeholder="{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}" />
              </div>
              <!-- <div class="p-field p-col-3">
                <label class="required">{{
                  langObj
                  ? langObj?.AnalyticalTool
                  : ("AnalyticalTool" | translate)
                  }}</label>
                <p-dropdown [ngClass]="{
                      'is-invalid':
                        LossAnalysisForm.get('toolname')?.errors &&
                        LossAnalysisForm.get('toolname')?.touched
                    }" [options]="toolslist" (onChange)="onToolChange($event)" formControlName="toolname"
                  optionLabel="name" placeholder="{{langObj ? langObj?.PleaseSelect : ('PleaseSelect' | translate)}}">
                </p-dropdown>
              </div> -->
            </div>
            <div class="p-grid p-fluid">
              <!-- <div class="p-field p-col-4">
                <label class="required">{{
                  langObj
                  ? langObj?.CauseClassification
                  : ("CauseClassification" | translate)
                  }}</label>
                <p-dropdown [ngClass]="{
                      'is-invalid':
                        LossAnalysisForm.get('fourm')?.errors &&
                        LossAnalysisForm.get('fourm')?.touched
                    }" [options]="fourMList" formControlName="fourm" optionLabel="name"
                  placeholder="{{langObj ? langObj?.PleaseSelect : ('PleaseSelect' | translate)}}">
                </p-dropdown>
              </div> -->

              <div class="p-field p-col-4">
                <label>{{
                  langObj ? langObj?.RootCause : ("RootCause" | translate)
                  }}</label>
                <input type="text" formControlName="rootcause" pInputText
                  placeholder="{{langObj ? langObj?.RootCause : ('RootCause' | translate)}}" />
              </div>
            </div>
            <div class="p-field p-col-4">
              <div mt-3><label></label></div>
              <a href="{{ toolPath }}{{ toolName }}" mat-flat-button color="accent" type="button" class="btn-accent"
                [download] *ngIf="
                    toolPath !== undefined &&
                    toolName !== undefined &&
                    toolPath !== null &&
                    toolName !== null &&
                    toolName !== ''
                  ">
                {{
                langObj ? langObj?.ExecuteTool : ("ExecuteTool" | translate)
                }}</a>
            </div>
            <table id="tab1" class="basic-table plant-model-table">
              <thead>
                <tr>
                  <th class="text-center align-middle required">
                    {{
                    langObj
                    ? langObj?.ActionPerform
                    : ("ActionPerform" | translate)
                    }}
                  </th>
                  <th class="text-center align-middle required">
                    {{ langObj ? langObj?.Targdate : ("Targdate" | translate) }}
                  </th>
                  <th class="text-center align-middle">
                    {{
                    langObj
                    ? langObj?.KPIReponsible
                    : ("KPIReponsible" | translate)
                    }}
                  </th>
                  <th class="text-center align-middle">
                    {{
                    langObj
                    ? langObj?.ActionAccountable
                    : ("ActionAccountable" | translate)
                    }}
                  </th>
                  <th class="text-center align-middle">
                    <button mat-mini-fab color="accent" (click)="addFieldValue()" type="button">
                      <i class="fa fa-plus" style="color: #fff"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let field of actionlist; let i = index">
                  <td>
                    <mat-form-field appearance="fill" class="field-fill">
                      <input matInput value="{{ field.action }}" class="form-control"
                        (change)="fielArrayValueChange($event, i)" type="text" name="{{ field.action }}" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="fill" class="field-fill">
                      <input matInput [matDatepicker]="picker" (dateInput)="addEvent('input', $event, i)"
                        value="{{ field.targetDate }}" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="fill" class="field-fill">
                      <input matInput [disabled]="true" class="form-control" value="{{ resposibleUsers }}"
                        type="text" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="fill" class="field-fill">
                      <mat-select multiple (selectionChange)="accountbleChange($event, i)">
                        <mat-select-filter [placeholder]="'Search'" [color]="'#F9FAFF'" [array]="raci"
                          (filteredReturn)="filterdraci = $event" [displayMember]="'fullName'">
                        </mat-select-filter>
                        <mat-option *ngFor="let field of raci" value="{{ field.accountableUser }}"
                          [value]="field.EmpInfo_Id" [class.hide]="!isFiltered(field)">{{ field.fullName }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td>
                    <button mat-mini-fab color="warn" (click)="deleteFieldValue(i)">
                      <i class="fa fa-times" style="color: #fff"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <div class="p-grid p-fluid">
              <div class="p-col-6">
                <label>{{
                  langObj ? langObj?.Recommend : ("Recommend" | translate)
                  }}</label>

                <p-dropdown [options]="toolslist" optionLabel="name"
                  placeholder="{{langObj ? langObj?.PleaseSelect : ('PleaseSelect' | translate)}}"></p-dropdown>
              </div>
            </div>
            <div class="model-form-actions">
              <button type="button" mat-flat-button class="btn-cancel" (click)="closeFrequencyModel()">
                {{ langObj ? langObj?.Cancel : ("Cancel" | translate) }}
              </button>
              <button mat-flat-button color="accent" type="submit" [disabled]="!LossAnalysisForm.valid"
                class="btn-accent">
                {{ langObj ? langObj?.Add : ("Add" | translate) }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{ active: editAnalysisModelVisible }">
      <div class="sidepanel-close" (click)="closeEditAnalysisModal()"></div>
      <div class="model-content" style="width: 1000px">
        <div class="model-top">
          <h2 class="model-title">
            {{ langObj ? langObj?.updateaction : ("updateaction" | translate) }}
          </h2>
          <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeEditAnalysisModal()">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
                fill="black" />
            </svg>
          </button>
        </div>
        <div class="model-body">
          <form [formGroup]="LossAnalysisForm" (ngSubmit)="updateForm()">
            <div class="p-grid p-fluid">
              <div class="p-field p-col-4">
                <div class="basic-mat-input" style="margin-top: auto">
                  <!-- <span class="date-label">Last 30 days</span> -->
                  <mat-form-field appearence="fill" appearance="fill">
                    <mat-label>{{
                      langObj ? langObj?.choosedate : ("choosedate" | translate)
                      }}</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker2">
                      <input matStartDate formControlName="start" [formControl]="startDate" />
                      <input matEndDate formControlName="end" [formControl]="startDate" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker2">
                      <mat-icon matDatepickerToggleIcon>
                        <mat-select></mat-select>
                      </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-date-range-picker #picker2></mat-date-range-picker>
                  </mat-form-field>
                </div>
              </div>
              <div class="p-field p-col-3">
                <button style="margin-top: 10px" mat-flat-button color="accent" type="button" class="btn-accent"
                  (click)="searchLossAnalysis()">
                  {{
                  langObj
                  ? langObj?.SearchAnalysis
                  : ("SearchAnalysis" | translate)
                  }}
                </button>
                <!-- <a href="{{toolPath}}{{toolName}}" mat-flat-button color="accent" type="button" class="btn-accent" [download] *ngIf="toolPath !== undefined && toolName !== undefined && toolPath !== null && toolName !== null && toolName !== ''"> Execute Tool</a> -->
              </div>
              <div class="p-field p-col-4">
                <label class="required">{{
                  langObj ? langObj?.GapAnalysis : ("GapAnalysis" | translate)
                  }}</label>
                <p-dropdown [options]="lossAnalysisList" [filter]="true" optionLabel="name" [showClear]="true"
                  placeholder="{{langObj ? langObj?.PleaseSelect : ('PleaseSelect' | translate)}}"
                  (onChange)="getOneLossAnalysis($event)">
                </p-dropdown>
              </div>
            </div>
            <hr />
            <h2>
              {{
              langObj
              ? langObj?.AnalysisInformation
              : ("AnalysisInformation" | translate)
              }}
            </h2>
            <div class="p-grid p-fluid">
              <div class="p-field p-col-3">
                <mat-form-field class="field-fill" style="margin-top: 10px">
                  <mat-label>{{
                    langObj
                    ? langObj?.AnalysisDate
                    : ("AnalysisDate" | translate)
                    }}</mat-label>
                  <input matInput [matDatepicker]="editAnalysispicker"
                    placeholder="{{langObj ? langObj?.AnalysisDate : ('AnalysisDate' | translate)}}"
                    formControlName="lossAnalysisDate" />
                  <mat-datepicker-toggle matSuffix [for]="editAnalysispicker"></mat-datepicker-toggle>
                  <mat-datepicker #editAnalysispicker></mat-datepicker>
                </mat-form-field>
              </div>
              <!-- <div class="p-field p-col-3">
                <label class="required">{{
                  langObj ? langObj?.kpigap : ("kpigap" | translate)
                  }}</label>
                <p-dropdown [ngClass]="{
                      'is-invalid':
                        LossAnalysisForm.get('reasonname')?.errors &&
                        LossAnalysisForm.get('reasonname')?.touched
                    }" [options]="reasonslist" formControlName="reasonname" optionLabel="name"
                  placeholder="{{langObj ? langObj?.PleaseSelect : ('PleaseSelect' | translate)}}">
                </p-dropdown>
              </div> -->
              <div class="p-field p-col-3">
                <label>{{
                  langObj ? langObj?.Remarks : ("Remarks" | translate)
                  }}</label>
                <input type="text" pInputText formControlName="remarks"
                  placeholder="{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}" />
              </div>
              <!-- <div class="p-field p-col-3">
                <label class="required">{{
                  langObj
                  ? langObj?.AnalyticalTool
                  : ("AnalyticalTool" | translate)
                  }}</label>
                <p-dropdown [ngClass]="{
                      'is-invalid':
                        LossAnalysisForm.get('toolname')?.errors &&
                        LossAnalysisForm.get('toolname')?.touched
                    }" [options]="toolslist" (onChange)="onToolChange($event)" formControlName="toolname"
                  optionLabel="name" placeholder="{{langObj ? langObj?.PleaseSelect : ('PleaseSelect' | translate)}}">
                </p-dropdown>
              </div> -->
            </div>
            <div class="p-grid p-fluid">
              <!-- <div class="p-field p-col-4">
                <label class="required">{{
                  langObj
                  ? langObj?.CauseClassification
                  : ("CauseClassification" | translate)
                  }}</label>
                <p-dropdown [ngClass]="{
                      'is-invalid':
                        LossAnalysisForm.get('fourm')?.errors &&
                        LossAnalysisForm.get('fourm')?.touched
                    }" [options]="fourMList" formControlName="fourm" optionLabel="name"
                  placeholder="{{langObj ? langObj?.PleaseSelect : ('PleaseSelect' | translate)}}">
                </p-dropdown>
              </div> -->

              <div class="p-field p-col-4">
                <label>{{
                  langObj ? langObj?.RootCause : ("RootCause" | translate)
                  }}</label>
                <input type="text" formControlName="rootcause" pInputText
                  placeholder="{{langObj ? langObj?.RootCause : ('RootCause' | translate)}}" />
              </div>
            </div>
            <div class="p-field p-col-4">
              <div mt-3><label></label></div>
              <a href="{{ toolPath }}{{ toolName }}" mat-flat-button color="accent" type="button" class="btn-accent"
                [download] *ngIf="
                    toolPath !== undefined &&
                    toolName !== undefined &&
                    toolPath !== null &&
                    toolName !== null &&
                    toolName !== ''
                  ">
                {{
                langObj ? langObj?.ExecuteTool : ("ExecuteTool" | translate)
                }}</a>
            </div>
            <table id="tab1" class="basic-table">
              <thead>
                <tr>
                  <th class="text-center align-middle">
                    {{
                    langObj
                    ? langObj?.ActionPerform
                    : ("ActionPerform" | translate)
                    }}
                  </th>
                  <th class="text-center align-middle">
                    {{ langObj ? langObj?.Targdate : ("Targdate" | translate) }}
                  </th>
                  <th class="text-center align-middle">
                    {{
                    langObj
                    ? langObj?.KPIReponsible
                    : ("KPIReponsible" | translate)
                    }}
                  </th>
                  <th class="text-center align-middle">
                    {{
                    langObj
                    ? langObj?.ActionAccountable
                    : ("ActionAccountable" | translate)
                    }}
                  </th>
                  <th class="text-center align-middle">
                    <button mat-mini-fab color="accent" (click)="addFieldValue()">
                      <i class="fa fa-plus" style="color: #fff"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let field of actionlist; let i = index">
                  <td>
                    <mat-form-field class="field-fill" appearance="fill">
                      <input matInput value="{{ field.action }}" class="form-control"
                        (change)="fielArrayValueChange($event, i)" type="text" name="{{ field.action }}" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field class="field-fill" appearance="fill">
                      <input matInput [matDatepicker]="picker3" (dateInput)="addEvent('input', $event, i)"
                        value="{{ field.targetDate }}" />
                      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                      <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>

                    <!-- <div class="example-events">
                        <div *ngFor="let e of events">{{e}}</div>
                      </div> -->
                  </td>
                  <td>
                    <mat-form-field appearance="fill" class="field-fill">
                      <input matInput [disabled]="true" class="form-control" value="{{ resposibleUsers }}"
                        type="text" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="fill" class="field-fill">
                      <!-- <mat-select multiple  (selectionChange)="accountbleChange($event, i)" [compareWith]="compareFn" [(value)]="field.accountableUser">
                          <mat-option *ngFor="let r of raci"  [value]="r.userId" >{{r.fullName}}</mat-option>          
                        </mat-select>                      -->
                      <mat-select multiple (selectionChange)="accountbleChange($event, i)" [compareWith]="compareFn"
                        [(value)]="field.accountableUser">
                        <mat-select-filter [placeholder]="'Search'" [color]="'#F9FAFF'" [array]="raci"
                          (filteredReturn)="filterdraci = $event" [displayMember]="'fullName'">
                        </mat-select-filter>
                        <mat-option *ngFor="let r of raci" [value]="r.EmpInfo_Id" [class.hide]="!isFiltered(r)">
                          {{ r.fullName }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>

                  <td>
                    <button mat-mini-fab color="warn" (click)="deleteFieldValue(i)">
                      <i class="fa fa-times" style="color: #fff"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <div class="p-grid p-fluid">
              <div class="p-col-6">
                <label>{{
                  langObj ? langObj?.Recommend : ("Recommend" | translate)
                  }}</label>

                <p-dropdown [options]="toolslist" optionLabel="name"
                  placeholder="{{langObj ? langObj?.PleaseSelect : ('PleaseSelect' | translate)}}"></p-dropdown>
              </div>
            </div>
            <div class="model-form-actions">
              <button type="button" mat-flat-button class="btn-cancel" (click)="closeEditAnalysisModal()">
                {{ langObj ? langObj?.Cancel : ("Cancel" | translate) }}
              </button>
              <button mat-flat-button color="accent" type="submit" [disabled]="!LossAnalysisForm.valid"
                class="btn-accent">
                {{ langObj ? langObj?.Update : ("Update" | translate) }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-card>
</section>