<div class="production-db-content">
    <div class="content-top-box">
        <ul class="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                    <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
                </li>
            </ul>
        </ul>
        <div class="title-row-flex-both-end">
            <h1 class="main-title">Area Summary</h1>
            <div>
                <button mat-raised-button color="accent" class="btn-accent mr-15-px">Live</button>
                <button mat-raised-button color="accent" class="btn-accent">Yesterday</button>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <form [formGroup]="analysisHeaderForm">
        <div style="width: 210%;" class="content-top-row content-top-row-order-processing">
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing m-0">
                <mat-select formControlName="shift" placeholder="Shift" panelClass="testClass" name="label">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="shiftList"
                        (filteredReturn)="filteredShiftList = $event"></mat-select-filter>
                    <mat-option value="">All</mat-option>
                    <mat-option *ngFor="let shift of filteredShiftList" [value]="shift.EG_Id">
                        {{shift.EG_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="line" placeholder="Line" panelClass="testClass" name="line">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
                        (filteredReturn)="filteredLines = $event"></mat-select-filter>
                    <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
                        {{line.PL_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="area" placeholder="Area" panelClass="testClass" name="area">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="areaList"
                        (filteredReturn)="filteredAreaList = $event"></mat-select-filter>
                    <mat-option *ngFor="let area of filteredAreaList" [value]="area.PU_Id">
                        {{area.PU_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>

    <div class="d-flex">

        <div class="w-100">
            <div class="d-flex justify-content-around ">
                <div class="chart-items w-95">
                    <div class="chart-title">Live OEE</div>
                    <div [chart]="liveOee"></div>
                </div>
                <div class="d-flex justify-content-around  my-5px">
                    <div class="chart-items w-95">
                        <div class="chart-title">Live Output</div>
                        <div [chart]="liveOutput"></div>
                    </div>
                    <div class="d-flex justify-content-around  my-5px">
                        <div class="chart-items w-95">
                            <div class="chart-title">Live Pallets</div>
                            <div [chart]="livePallets"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="chart-items w-60">
            <ag-grid-angular style="width: 100%; height: 434px;" class="ag-theme-alpine" [rowData]="areaSummary"
                [columnDefs]="areaSummaryCol" headerHeight="32">
            </ag-grid-angular>
            <div style="margin-top: 2%;">
                <ag-grid-angular style="width: 100%; height: 442px;" class="ag-theme-alpine" [rowData]="areaSummary1"
                    [columnDefs]="areaSummaryCol1" headerHeight="32">
                </ag-grid-angular>
            </div>
        </div>

    </div>
</div>