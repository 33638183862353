import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { MasterDepartmentService } from 'src/app/services/master-department.service';
import { RawMaterialService } from 'src/app/services/rawMaterial.service';
import { RawMaterialExecutionService } from 'src/app/services/rawMaterialExecution.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-rawMaterialExecution',
  templateUrl: './rawMaterialExecution.component.html',
  styleUrls: ['./rawMaterialExecution.component.scss'],
})
export class RawMaterialExecutionComponent implements OnInit {
  breadcrumList!: any;
  rawMaterialExecutionForm!: FormGroup;
  rawMaterialInspectionHeaderForm!: FormGroup;
  rawMaterialParameterForm!: FormGroup;
  rawMaterialParameterArray!: FormArray;
  headersArray: any[] = [];
  groupList!: any;
  plantList!: any;
  materialList!: any;
  RMDataArray: any[] = [];
  headerMode = false;
  panelOpenState = false;
  showHideProperty = false;
  radioShowHideProperty = false;
  readOnlyProperty = false;
  btnShowHideProperty = true;
  labBtnShowHideProperty = false;
  labSpecHideShow = false;
  langObj: any = {};
  paramertersList: any[] = [];
  phySpecList: any[] = [];
  labSpecList: any[] = [];
  specArray: any[] = [];
  updatedSpecArray: any[] = [];
  radioTypeList: any[] = [
    { name: 'YES', value: 'true' },
    { name: 'NO', value: 'false' },
  ];
  valueList: any[] = [
    { id: 1, name: '25 C' },
    { id: 2, name: '45 C' },
  ];
  typeList: any[] = [
    { id: 1, name: 'Physical Test' },
    { id: 2, name: 'Laboratory Test' },
  ];
  parametersHeaderDataSource!: MatTableDataSource<any>;
  parametersHeaderColumns: any[] = [
    'paramName',
    'paramUnitName',
    'normalRange',
    'typicalValue',
  ];
  labParametersColumns: any[] = [
    'paramName',
    'paramUnitName',
    'normalRange',
    'typicalValue',
  ];
  phySpecDataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  paramTypeList: any[] = [];
  headerIDsArray: any[] = [];
  labArray: any[] = [];

  constructor(
    private toaster: ToastrService,
    private router: Router,
    private userManagementService: UserManagementService,
    private fb: FormBuilder,
    private roleService: MasterDepartmentService,
    private rawMaterialService: RawMaterialService,
    private machineService: MachineMappingService,
    private rawMaterialExecutionService: RawMaterialExecutionService
  ) {}

  ngOnInit(): void {
    this.rawMaterialExecutionForm = this.fb.group({
      group: [''],
      plant: [''],
      material: [''],
      type: [''],
    });
    this.rawMaterialParameterForm = this.fb.group({
      paramHeaders: this.fb.array([this.createRawMaterialParameterFormGroup()]),
    });
    this.rawMaterialParameterArray = this.rawMaterialParameterForm.get(
      'paramHeaders'
    ) as FormArray;
    this.GetBreadcrumList();
    this.getGroupsData();
  }

  getGroupsData(): void {
    const key = {
      Id: 0,
    };
    this.roleService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        this.rawMaterialExecutionForm
          .get('group')
          ?.setValue(this.groupList[0].TypeId);
        this.getPlantData();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getPlantData(): any {
    const key = {
      GroupId: this.rawMaterialExecutionForm.value.group,
    };
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plantList = data.DepartList;
        this.rawMaterialExecutionForm
          .get('plant')
          ?.setValue(data.DepartList[0].DeptId);
        this.GetAllRawMaterials();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  GetAllRawMaterials(): void {
    const key = {
      plantId: this.rawMaterialExecutionForm.value.plant,
    };
    this.rawMaterialService.GetAllRawMaterials(key).subscribe((res: any) => {
      this.materialList = res;
      if (this.materialList.length > 0) {
        this.rawMaterialExecutionForm
          .get('material')
          ?.setValue(res[0].rawMaterialId);
        this.GetAllRMInspectionMasterData('');
      } else {
        this.toaster.error('No Raw Material is Found');
        this.headersArray = [];
        this.paramertersList = [];
        this.headerMode = false;
      }
    });
  }

  GetAllRMInspectionMasterData(event: any): void {
    this.rawMaterialService
      .GetAllRMInspectionMasterData()
      .subscribe((res: any) => {
        this.RMDataArray = res;
        this.radioShowHideProperty = true;
        this.showHideProperty = false;
        this.labBtnShowHideProperty = false;
        if (event === '') {
          this.rawMaterialExecutionForm
            .get('type')
            ?.setValue(this.typeList[0].id);
        } else {
          this.rawMaterialExecutionForm
            .get('type')
            ?.setValue(this.typeList[0].id);
        }
        this.GetRawMaterialInspectionReportHeader();
        this.GetAllRawMaterialsParameterUnits();
        this.GetRawMaterialParameters();
      });
  }

  GetRawMaterialInspectionReportHeader(): void {
    this.headerMode = false;
    this.headersArray = [];
    const key = {
      rmDefinitionMasterId: this.rawMaterialExecutionForm.value.material,
    };
    this.rawMaterialExecutionService
      .GetRawMaterialInspectionReportHeader(key)
      .subscribe((res: any) => {
        this.headerIDsArray = [];
        if (res.length > 0) {
          this.headerMode = true;
          const obj: any = {};
          res.forEach((el: any) => {
            this.headersArray.push(el.rawMaterialId);
            this.headerIDsArray.push({
              id: el.rawMaterialId.rmMasterId,
              value: el.rawMaterialId.rmName,
            });
          });
          this.headersArray.forEach((el) => {
            obj[el.rmName] = [''];
          });
          this.rawMaterialInspectionHeaderForm = this.fb.group(obj);
        } else {
          this.toaster.error('No Data is Configured');
        }
      });
  }

  // Get Execution Header
  GetRawMaterialExecutionMasterHeader(): void {
    const key = {
      rawMaterialId: this.rawMaterialExecutionForm.value.material,
    };
    this.rawMaterialExecutionService
      .GetRawMaterialExecutionMasterHeader(key)
      .subscribe((res: any) => {
        const obj: any = {};
        for (const el of res) {
          const rmName = this.headersArray.find(
            (x: any) => x.rmMasterId === el.headerId
          ).rmName;
          obj[rmName] = [el.headerValue];
        }
        this.rawMaterialInspectionHeaderForm = this.fb.group(obj);
        this.GetRawMaterialPhysicalSpecifications();
      });
  }

  // Get Execution Header
  GetRawMaterialPhysicalSpecifications(): void {
    const key = {
      rawMaterialId: this.rawMaterialExecutionForm.value.material,
    };
    this.rawMaterialExecutionService
      .GetRawMaterialPhysicalSpecifications(key)
      .subscribe((res: any) => {
        this.specArray = [];
        this.specArray = res;
        this.phySpecDataSource = new MatTableDataSource<any>(this.specArray);
      });
  }

  // Add Execution Header as a Master
  AddRawMaterialExecutionMasterHeader(): void {
    const resArr: any[] = [];
    const headerValues = this.rawMaterialInspectionHeaderForm.value;
    for (const el of this.headerIDsArray) {
      const obj = {};
      for (const [key1, value] of Object.entries(headerValues)) {
        if (el.value === key1) {
          Object.assign(obj, { [el.id]: value });
        }
      }
      if (obj) {
        resArr.push(obj);
      }
    }
    const key = {
      rawMaterialId: this.rawMaterialExecutionForm.value.material,
      resArr,
      specArray: this.specArray,
    };
    this.rawMaterialExecutionService
      .AddRawMaterialExecutionMasterHeader(key)
      .subscribe((res: any) => {
        this.toaster.success('Data is saved Successfully');
        this.rawMaterialInspectionHeaderForm.reset();
      });
  }

  // get lab specifications
  GetRawMaterialExecutionLabSpecifications(): void {
    const key = {
      rawMaterialId: this.rawMaterialExecutionForm.value.material,
    };
    this.rawMaterialExecutionService
      .GetRawMaterialExecutionLabSpecifications(key)
      .subscribe((res: any) => {
        this.labArray = [];
        this.labArray = res;
        this.parametersHeaderDataSource = new MatTableDataSource<any>(
          this.labArray
        );
        console.log(this.labArray);
      });
  }

  // add lab specifications
  AddRawMaterialExecutionLabSpecifications(): void {
    const key = {
      labArray: this.labArray,
    };
    this.rawMaterialExecutionService
      .AddRawMaterialExecutionLabSpecifications(key)
      .subscribe((res: any) => {
        console.log(res);
        this.toaster.success('Lab Specifications is Saved successfully');
      });
  }

  // change lab spec typical values
  changeLabTypicalValues(event: any, ele: any): void {
    this.labArray = [];
    const obj = {
      param: ele.ParamId,
      typicalValue:
        ele.paramUnitType === 'Input'
          ? event.target.value
          : ele.paramUnitType === 'Radio'
          ? event.value
          : ele.paramUnitType === 'Dropdown'
          ? event.value
          : '',
      rawMaterial: this.rawMaterialExecutionForm.value.material,
      createdOn: new Date(),
    };
    this.labArray.push(obj);
    console.log(this.labArray);
  }

  changeTypicalvalues(event: any, ele: any): void {
    this.specArray = [];
    const obj = {
      param: ele.paramId,
      typicalValue:
        ele.paramUnitType === 'Input'
          ? ele.paramId
          : ele.paramUnitType === 'Radio'
          ? event.value
          : ele.paramUnitType === 'Dropdown'
          ? event.value
          : '',
      rawMaterial: this.rawMaterialExecutionForm.value.material,
      createdOn: new Date(),
    };
    this.specArray.push(obj);
  }

  GetRawMaterialParameters(): void {
    this.refresh();
    const key = {
      rawMaterialId: this.rawMaterialExecutionForm.value.material,
    };
    this.rawMaterialService
      .GetAllRawMaterialsParameters(key)
      .subscribe((res: any) => {
        this.paramertersList = res;
        this.phySpecList = [];
        this.labSpecList = [];
        this.paramertersList.forEach((el: any) => {
          if (el.paramType === 'Physical Test') {
            this.phySpecList.push(el);
          }
          if (el.paramType === 'Laboratory Test') {
            this.labSpecList.push(el);
          }
        });
        if (this.phySpecList.length > 0) {
          this.headerMode = true;
          this.phySpecDataSource = new MatTableDataSource<any>([
            ...new Set(this.phySpecList),
          ]);
        }
        if (this.labSpecList.length > 0 && this.showHideProperty === true) {
          this.headerMode = true;
          this.parametersHeaderDataSource = new MatTableDataSource<any>([
            ...new Set(this.labSpecList),
          ]);
        }
      });
  }

  refresh(): void {
    this.headerMode = false;
    this.paramertersList = [];
    this.parametersHeaderDataSource = new MatTableDataSource<any>(
      this.paramertersList
    );
  }

  // Get Raw Material Parameter Units
  GetAllRawMaterialsParameterUnits(): void {
    this.rawMaterialService
      .GetAllRawMaterialsParameterUnits()
      .subscribe((res: any) => {
        this.paramTypeList = res;
      });
  }

  changeParamUnitType(event: any): void {
    if (event.value === 1) {
      this.labSpecHideShow = false;
      this.showHideProperty = false;
      this.labBtnShowHideProperty = false;
      this.radioShowHideProperty = false;
      this.readOnlyProperty = false;
      this.btnShowHideProperty = true;
      this.GetRawMaterialInspectionReportHeader();
      this.GetRawMaterialParameters();
    } else {
      this.labSpecHideShow = true;
      this.labBtnShowHideProperty = true;
      this.showHideProperty = true;
      this.radioShowHideProperty = true;
      this.readOnlyProperty = true;
      this.btnShowHideProperty = false;
      this.GetRawMaterialParameters();
      this.GetRawMaterialExecutionMasterHeader();
      this.GetRawMaterialExecutionLabSpecifications();
    }
  }

  get repeatRawMaterialParameterFormGroup(): any {
    return this.rawMaterialParameterForm.get('paramHeaders') as FormArray;
  }

  createRawMaterialParameterFormGroup(): FormGroup {
    return this.fb.group({
      paramId: [''],
      paramName: [''],
      normalRange: [''],
      paramType: [''],
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
}
