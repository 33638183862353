import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BtnCellRendererQxMatrix } from 'src/app/btn-cell-rendererQxMatrix';
import { BtnCellRendererRawMaterial } from 'src/app/btn-cellRenderer-RawMaterial';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { MasterDepartmentService } from 'src/app/services/master-department.service';
import { RawMaterialService } from 'src/app/services/rawMaterial.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-rawMaterialDefinition',
  templateUrl: './rawMaterialDefinition.component.html',
  styleUrls: ['./rawMaterialDefinition.component.scss']
})
export class RawMaterialDefinitionComponent implements OnInit {
  @ViewChild('rawMaterialPropertiesTemplate') rawMaterialPropertiesTemplate !: TemplateRef<any>;
  @ViewChild('configurationHeaderTemplate') configurationHeaderTemplate!: TemplateRef<any>;
  @ViewChild('displayGroupModal') displayGroupModal!: TemplateRef<any>;
  rawMaterialForm!: FormGroup;
  configurationHeaderForm!: FormGroup;
  groupForm!: FormGroup;
  groupList!: any;
  plantList!: any;
  breadcrumList!: any;
  rowData!: any;
  templateRowData!: any;
  columnsDefs!: any;
  templateColumnsDefs!: any;
  gridApi!: any;
  buttonType!: any;
  mode = true;
  materialMode = true;
  gridOptions: any = {
    frameworkComponents: {
      btnCellRenderer: BtnCellRendererRawMaterial
    }
  };
  templateGridOptions: any = {
    frameworkComponents: {
      btnCellRenderer: BtnCellRendererQxMatrix
    }
  };
  formMode = true;
  rawMaterialPropertiesForm!: FormGroup;
  TypeList: any = [
    { id: 1, name: 'Physical Test' },
    { id: 2, name: 'Laboratory Test' },
  ];
  rawMaterialId!: any;
  templateGridApi!: any;
  ramId!: any;
  templateButtonType!: string;
  paramId!: any;
  typeName!: any;
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  RMDataArray: any[] = [];
  updatedRMDataArray: any[] = [];
  rawMaterialArray: any[] = [];
  paramUnitList: any[] = [];
  groupsArray: any[] = [];

  constructor(private fb: FormBuilder, private machineService: MachineMappingService,
              private toaster: ToastrService,  private roleService: MasterDepartmentService,
              private router: Router, private userManagementService: UserManagementService,
              private rawMaterialService: RawMaterialService, private dialog: MatDialog,
              private enterpriseGroupService: EnterPriseGroupService,
    ) {
      this.GetColumnsAccess();
    }

  ngOnInit(): void {
    this.rawMaterialForm = this.fb.group({
      group: [''],
      plant: [''],
      name: [''],
      code: ['']
    });
    this.rawMaterialPropertiesForm = this.fb.group({
      name: [''],
      unit: [''],
      range: [''],
      type: [''],
    });
    this.configurationHeaderForm = this.fb.group({
      type: ['']
    });
    this.groupForm = this.fb.group({
      group: ['']
    });
    this.getGroupsData();
    this.GetBreadcrumList();
    this.GetMenuAccess();
  }

  getGroupsData(): void {
    const key = {
      Id: 0,
    };
    this.roleService.getGroupData(key).subscribe((data: any) => {
      this.groupList = data.masterList;
      this.rawMaterialForm.get('group')?.setValue(this.groupList[0].TypeId);
      this.getPlantData();
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getPlantData(): any {
    const key = {
      GroupId : this.rawMaterialForm.value.group
    };
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.plantList = data.DepartList;
      this.rawMaterialForm.get('plant')?.setValue(data.DepartList[0].DeptId);
      this.GetAllRawMaterials();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  onCellClicked(params: any): void {
    const col = params.colDef.field;
    this.ramId = params.data.rawMaterialId;
    this.rawMaterialArray = [];
    this.buttonType = '';
    if (col === 'Action') {
      this.buttonType = params.event.target.textContent;
      if (this.buttonType === 'add') {
        this.dialog.open(this.rawMaterialPropertiesTemplate, { height: '85%', width: '100%' });
        this.resetRawMaterialParameters();
        this.GetTemplateColumnsAccess();
        this.GetAllRawMaterialsParameters();
        this.GetAllRawMaterialsParameterUnits();
      }
      if (this.buttonType === 'add_circle_outline') {
        this.openConfigurationModal();
      }
      if (this.buttonType === 'edit') {
        this.editRawMaterial(params.data);
      }
      if (this.buttonType === 'delete') {
        this.DeleteRawMaterial(params.data);
      }
    }
  }

  templateOnCellClicked(params: any): void {
    const col = params.colDef.field;
    this.templateButtonType = '';
    if (col === 'Action') {
      this.templateButtonType = params.event.target.textContent;
      if (this.templateButtonType === 'edit') {
        this.editRawMaterialParameter(params.data);
      }
      if (this.templateButtonType === 'delete') {
        this.DeleteRawMaterialsParameter(params.data);
      }
    }
  }

  changeType(event: any): void {
    this.typeName = this.TypeList.find((x: any) => x.name === event.value).name;
  }

  changeUnitType(event: any): void {

  }

  // get all raw materials
  GetAllRawMaterials(): void {
    const key = {
      plantId: this.rawMaterialForm.value.plant
    };
    this.rawMaterialService.GetAllRawMaterials(key).subscribe((res: any) => {
      this.rowData = res;
      this.GetColumnsAccess();
    });
  }

  // get all raw materials
  GetAllRawMaterialsParameterUnits(): void {
    this.rawMaterialService.GetAllRawMaterialsParameterUnits().subscribe((res: any) => {
      this.paramUnitList = res;
    });
  }

  GetAllRawMaterialsParameters(): void {
    const key = {
      rawMaterialId: this.ramId
    };
    this.rawMaterialService.GetAllRawMaterialsParameters(key).subscribe((res: any) => {
      this.templateRowData = res;
    });
  }

  GetColumnsAccess(): void {
    this.columnsDefs = [
      {
        field: 'rawMaterialCode',
        headerName: 'Material Code',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
      },
      {
        field: 'rawMaterialName',
        headerName: 'Material Name',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        pagination: true
      },
      {
        field: 'ptDesc',
        headerName: 'Plant',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        pagination: true
      },
      {
        field: 'Action',
        editable: false,
        hide: this.IsWrite ? false : true,
        cellRenderer: 'btnCellRenderer',
        cellRendererParams: { clicked: (field: any) => {} }
      }
    ];
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 500);
  }

  GetTemplateColumnsAccess(): void {
    this.templateColumnsDefs = [
      {
        field: 'paramName',
        headerName: 'Parameter Name',
        sortable: true,
        editable: false,
        resizable: true,
        wrapText: true,
        filter: true,
        pagination: true
      },
      {
        field: 'paramUnitName',
        headerName: 'Parameter Unit',
        sortable: true,
        editable: false,
        resizable: true,
        wrapText: true,
        filter: true,
        pagination: true
      },
      {
        field: 'normalRange',
        headerName: 'Normal Range',
        sortable: true,
        editable: false,
        resizable: true,
        wrapText: true,
        filter: true,
        pagination: true
      },
      {
        field: 'paramType',
        headerName: 'Parameter Type',
        sortable: true,
        editable: false,
        resizable: true,
        wrapText: true,
        filter: true,
        pagination: true
      },
      {
        field: 'Action',
        editable: false,
        cellRenderer: 'btnCellRenderer',
        cellRendererParams: { clicked: (field: any) => {} }
      }
    ];
  }

  openConfigurationModal(): void {
    this.updatedRMDataArray = [];
    this.dialog.open(this.configurationHeaderTemplate, { height: '70%', width: '70%' });
    this.GetAllRMInspectionMasterData();
  }

  openModal(): void {
    this.groupsArray = [];
    this.resetGroupName();
    this.dialog.open(this.displayGroupModal,  { id: 'message-pop-up', panelClass: 'custom-dialog-container',
                     width: '40%', height: '35%' });
  }

  GetAllRMInspectionMasterData(): void {
    this.rawMaterialService.GetAllRMInspectionMasterData().subscribe((res: any) => {
      this.RMDataArray = res;
    });
  }

  changeRMInspectionMasterData(event: any): void {
    const eleName = this.RMDataArray.find((x: any) => x.rmMasterId === event.value).rmName;
    const obj = {
      id: event.value,
      name: eleName
    };
    const index = this.updatedRMDataArray.findIndex((x: any) => x.id === event.value);
    if (index === -1) {
      this.updatedRMDataArray.push(obj);
      this.rawMaterialArray = [];
      this.updatedRMDataArray.map(el => {
        this.rawMaterialArray.push(el.id);
      });
      this.configurationHeaderForm.get('type')?.reset();
    }
  }

  removeRMInspectionMasterData(event: any): void {
    const ind = this.updatedRMDataArray.findIndex((x: any) => x.name === event.chip._value);
    this.updatedRMDataArray.splice(ind, 1);
    this.rawMaterialArray = [];
    this.updatedRMDataArray.map(el => {
      this.rawMaterialArray.push(el.id);
    });
    this.configurationHeaderForm.get('type')?.reset();
  }

  AddRawMaterialInspectionHeader(): void {
    const key = {
      rmMaster: this.ramId,
      rawMaterial: this.rawMaterialArray
    };
    this.rawMaterialService.AddRawMaterialInspectionHeader(key).subscribe((res: any) => {
      this.toaster.success('Raw Material Header is successfully added');
      this.updatedRMDataArray = [];
    });
  }

  // add raw material data
  AddRawMaterial(): void {
    const key = {
      plantId: this.rawMaterialForm.value.plant,
      rawMaterialCode: this.rawMaterialForm.value.code,
      rawMaterialName: this.rawMaterialForm.value.name,
    };
    this.rawMaterialService.AddRawMaterial(key).subscribe((res: any) => {
      this.toaster.success('Raw Material is added successfully');
      this.GetAllRawMaterials();
      this.resetRawMaterial();
    });
  }

  AddGroupName(event: any): void {
    const groupValue = this.groupForm.value.group;
    if (groupValue !== '') {
      this.groupsArray.push(groupValue);
      this.resetGroupName();
    } else {
      this.toaster.error('Enter the group name');
    }
  }

  AddRawMaterialsParameter(): void {
    const key = {
      rawMaterialId: this.ramId,
      paramName: this.rawMaterialPropertiesForm.value.name,
      paramUnitId: this.rawMaterialPropertiesForm.value.unit,
      normalRange: this.rawMaterialPropertiesForm.value.range,
      paramType: this.typeName,
    };
    this.rawMaterialService.AddRawMaterialsParameter(key).subscribe((res: any) => {
      this.toaster.success('Raw Material Parameter is added successfully');
      this.GetAllRawMaterialsParameters();
      this.resetRawMaterialParameters();
    });
  }

  editRawMaterial(element: any): void {
    this.mode = false;
    this.rawMaterialId = element.rawMaterialId;
    this.rawMaterialForm.get('code')?.setValue(element.rawMaterialCode);
    this.rawMaterialForm.get('name')?.setValue(element.rawMaterialName);
  }

  editRawMaterialParameter(element: any): void {
    this.materialMode = false;
    this.paramId = element.paramId;
    this.rawMaterialPropertiesForm.get('name')?.setValue(element.paramName);
    this.rawMaterialPropertiesForm.get('unit')?.setValue(this.paramUnitList.find((x: any) =>
                                              x.paramUnitId === element.paramUnitId).paramUnitId);
    this.rawMaterialPropertiesForm.get('range')?.setValue(element.normalRange);
    this.rawMaterialPropertiesForm.get('type')?.setValue(this.TypeList.find((x: any) => x.name === element.paramType).name);
  }

  UpdateRawMaterial(): void {
    const key = {
      rawMaterialId: this.rawMaterialId,
      plantId: this.rawMaterialForm.value.plant,
      rawMaterialCode: this.rawMaterialForm.value.code,
      rawMaterialName: this.rawMaterialForm.value.name,
    };
    this.rawMaterialService.UpdateRawMaterial(key).subscribe((res: any) => {
      this.toaster.success('Raw Material is updated successfully');
      this.GetAllRawMaterials();
      this.resetRawMaterial();
    });
  }

  UpdateRawMaterialsParameter(): void {
    const key = {
      paramId:  this.paramId,
      paramName: this.rawMaterialPropertiesForm.value.name,
      paramUnits: this.rawMaterialPropertiesForm.value.unit,
      normalRange: this.rawMaterialPropertiesForm.value.range,
      paramType: this.typeName,
    };
    this.rawMaterialService.UpdateRawMaterialsParameter(key).subscribe((res: any) => {
      this.toaster.success('Raw Material Parameter is updated successfully');
      this.GetAllRawMaterialsParameters();
      this.resetRawMaterialParameters();
    });
  }

  DeleteRawMaterial(element: any): void {
    const key = {
      rawMaterialId: element.rawMaterialId
    };
    this.rawMaterialService.DeleteRawMaterial(key).subscribe((res: any) => {
      this.toaster.success('Raw Material is deleted successfully');
      this.GetAllRawMaterials();
    }, (error) => {
      this.toaster.error(error.error.message);
    });
  }

  DeleteRawMaterialsParameter(element: any): void {
    const key = {
      paramId: element.paramId
    };
    this.rawMaterialService.DeleteRawMaterialsParameter(key).subscribe((res: any) => {
      this.toaster.success('Raw Material Parameter is deleted successfully');
      this.GetAllRawMaterialsParameters();
      this.resetRawMaterialParameters();
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  templateOnGridReady(params: any): void {
    this.templateGridApi = params.api;
    this.templateGridApi.sizeColumnsToFit();
  }

  closeToolModel(): void {
    this.dialog.closeAll();
  }

  resetRawMaterial(): void {
    this.mode = true;
    this.rawMaterialForm.get('code')?.reset();
    this.rawMaterialForm.get('name')?.reset();
  }

  resetRawMaterialParameters(): void {
    this.materialMode = true;
    this.rawMaterialPropertiesForm.get('name')?.reset();
    this.rawMaterialPropertiesForm.get('unit')?.reset();
    this.rawMaterialPropertiesForm.get('range')?.reset();
    this.rawMaterialPropertiesForm.get('type')?.reset();
  }

  resetGroupName(): void {
    this.groupForm.get('group')?.reset();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  closeGroupPopup(): void {
    this.dialog.getDialogById('message-pop-up')?.close();
  }

}
