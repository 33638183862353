import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { DefectService } from 'src/app/services/defect.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
@Component({
  selector: 'app-qx-matrix-report',
  templateUrl: './qx-matrix-report.component.html',
  styleUrls: ['./qx-matrix-report.component.scss'],
})
export class QxMatrixReportComponent implements OnInit {
  qxmatrixreportDataForm!: FormGroup;
  icons!: any;
  groupList!: any;
  Plants!: any[];
  columnDefs: any;
  rowData: any;
  parameterType!: any;
  breadcrumList: any;
  IsWrite: any;
  IsAccess: any;
  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
  };
  gridApi: any;
  gridColumnApi: any;
  constructor(
    private fb: FormBuilder,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private userService: UserManagementService,
    private defectService: DefectService,
    private machineService: MachineMappingService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private commonService: CommonService
  ) {
    this.columnDefs = [
      {
        field: 'NCRId',
        headerName: 'NCR Id',
        sortable: true,
        width: 110,
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        field: 'QualityDefects',
        headerName: 'Failure Mode',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        field: 'NcrType',
        headerName: 'NCR Type',
        sortable: true,
        filter: true,
        resizable: true,
        width: 130,
        wrapText: true,
      },
      {
        field: 'NcrAutoOrManual',
        headerName: 'Auto or Manual',
        sortable: true,
        filter: true,
        width: 143,
        resizable: true,
        wrapText: true,
      },
      {
        field: 'QuatlityDesc',
        headerName: 'Description',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        width: 530,
        cellRenderer: (params: any) => {
          if (params.data.NcrType === 'ALERT') {
            return `<div>
                <span style="color: #f49203; font-weight:bold;">Watch Out!</span>
                ${params.value}
              </div>`;
          } else if (params.data.NcrType === 'NCR') {
            return `<div>
            <span style="color: #ff2121; font-weight:bold;">Out of Specification Limits!</span>
              ${params.value}
            </div>`;
          }
          return;
        },
      },
      {
        field: 'ProcessOrder',
        headerName: 'Process Order',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
      },
      { field: 'Line' },
      { field: 'Unit' },
      { field: 'Component' },
      {
        field: 'DateTime',
        headerName: 'Created On',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },
      },
      {
        field: 'UpdatedOn',
        headerName: 'Updated On',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },
      },
      { field: 'Parameter', headerName: 'Parameter' },
      {
        field: 'Result',
        headeName: 'Value',
        width: 120,
        type: 'rightAligned',
        cellStyle: (params: any) => {
          if (
            params.data.NcrType === 'ALERT' &&
            (params.data.DataType.toLowerCase() === 'int' ||
              params.data.DataType.toLowerCase() === 'float')
          ) {
            return {
              color: '#f49203',
              backgroundColor: '#bdccdd',
              'font-weight': 'bold',
            };
            // tslint:disable-next-line:max-line-length
          } else if (
            params.data.NcrType === 'NCR' &&
            (params.data.DataType.toLowerCase() === 'int' ||
              params.data.DataType.toLowerCase() === 'float')
          ) {
            return {
              color: '#ff2121',
              backgroundColor: '#bdccdd',
              'font-weight': 'bold',
            };
          } else {
            return {
              color: 'black',
              backgroundColor: '#bdccdd',
              'font-weight': 'bold',
            };
          }
        },
      },
      { field: 'LRL', width: 120 },
      { field: 'LSL', width: 120 },
      { field: 'LWL', width: 120 },
      { field: 'TGT', width: 120 },
      { field: 'UWL', width: 120 },
      { field: 'USL', width: 120 },
      { field: 'URL', width: 120 },
    ];
    this.qxmatrixreportDataForm = this.fb.group({
      group: [''],
      plant: [''],
      parameter: [''],
    });
  }

  ngOnInit(): void {
    this.qxmatrixreportDataForm.get('parameter')?.setValue(0);
    this.GetMenuAccess();
    this.GetBreadcrumList();
    this.datasource();
  }

  dateFormatter(params: any): any {
    return moment(params.value).format('MM/DD/YYYY hh:mm');
  }

  getDefectReports(): void {
    this.spinner.show();
    const key = {
      parameterType: this.qxmatrixreportDataForm.value.parameter,
      plantId: this.qxmatrixreportDataForm.value.plant,
    };

    this.defectService.getDefectReports(key).subscribe(
      (data: any) => {
        this.rowData = data;
        this.spinner.hide();
        // this.autoSizeAll(true);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  selectParameters(event: any): void {
    this.parameterType = event.value;
    this.getDefectReports();
  }

  datasource(): void {
    const key = {
      Id: 0,
    };

    // tslint:disable-next-line: no-debugger
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        this.qxmatrixreportDataForm
          .get('group')
          ?.setValue(data.masterList[0].TypeId);
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  getPlants(): void {
    const key = {
      GroupId: this.qxmatrixreportDataForm.value.group,
    };

    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.Plants = data.DepartList;

        this.qxmatrixreportDataForm
          .get('plant')
          ?.setValue(this.Plants[0].DeptId);

        this.getDefectReports();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onChangeGroup(data: any): void {
    this.getPlants();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        console.log(data.BreadcrumList);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  autoSizeAll(skipHeader: any): any {
    const allColumnIds: any[] = [];
    this.gridOptions.columnApi.getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }
}
