import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { MasterDepartmentService } from 'src/app/services/master-department.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-general-department',
  templateUrl: './general-department.component.html',
  styleUrls: ['./general-department.component.scss']
})
export class GeneralDepartmentComponent implements OnInit {
  groupList!: any[];
  groupId!: number;
  departmentrowId!: number;
  departmentForm!: FormGroup;
  departmentModelVisible = false;
  formMode!: boolean;
  GroupId!: number;
  isUpdateDepartment: boolean = false;
  departmentColumns: string[] = ['group', 'department', 'departmentActions'];
  departmentData!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  departmentId: any;
  egId: any;
  constructor(private fb: FormBuilder, private roleService: MasterDepartmentService, private toaster: ToastrService) { }

  ngOnInit() {
   
    this.departmentForm = this.fb.group({
      departmentname: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      group: ['', Validators.required],
    });
    this.getGroupsData();

  }
  openModal(): void {
    this.formMode = true;
    this.departmentForm.get('group')?.setValue(this.groupId);

    this.departmentModelVisible = true;

  }
  openModal1(): void {
    // this.getGroupsData();
    this.formMode = false;

    this.departmentModelVisible = true;

  }
  closeDepartmentModel(): void {
    this.departmentModelVisible = false;
  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.departmentData.filter = filterValue.trim().toLowerCase();

    if (this.departmentData.paginator) {
      this.departmentData.paginator.firstPage();
    }
  }
  getdepartmentList(key: any): void {
    this.groupId = key.egId;
    this.egId = key;
    this.roleService.getMasterDepartment(key).subscribe((data) => {
      this.departmentData = new MatTableDataSource<any>(data);
      // console.log(data);
      // this.getPlants();
      this.getPaginator();
      // this.spinner.hide();
    },
      (error) => {
        this.toaster.error(error.error.message);
        // this.spinner.hide();
      });

  }

  getGroupsData(): void {
    const key = {
      Id: 0,
    };

    this.roleService.getGroupData(key).subscribe((data: any) => {
      this.groupList = data.masterList;
      this.departmentForm.get('group')?.setValue(this.groupList[0].TypeId);
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        //  this.spinner.hide();
      }
    );
  }
  saveDepartment(): void {

    const area = {
      egId: this.departmentForm.value.group,
      name: this.departmentForm.value.departmentname,
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.roleService.addDepartment(area).subscribe(
      (response) => {
        this.toaster.success('Success', 'Department Added Successfully');
        this.closeadddepartmentModel();
        this.getdepartmentList(this.egId)

      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  updateDepartment(): void {

    const area = {
      departmentId: this.departmentId,
      egId: this.departmentForm.value.group,
      name: this.departmentForm.value.departmentname,
      userId: JSON.parse(localStorage.user).UserId,
    };

    this.roleService.updateDepartment(area).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'Area Updated Successfully');
        this.closeadddepartmentModel();
        this.getdepartmentList(this.egId);
        this.isUpdateDepartment = false;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);

      }
    );
  }

  UpdateDepartment(element: any): void {
    const key = {
      departmentId: element.departmentId
    }
    this.roleService.GetOneMasterDepartment(key).subscribe((Response) => {
      this.openModal1()
      this.departmentForm.get('departmentname')?.setValue(element.departmentName);
      this.departmentForm.get('group')?.setValue(this.groupId);
      this.departmentId = element.departmentId
    }, (error) => {
      this.toaster.error('Error', error.error.message);
    })
    //  this.departmentrowId= element.departmentId


  }
  Deletedepartment(element: any): void {
    // if (this.isDelete) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete department!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        debugger
        console.log(element);
        var id = {
          departmentId: element.departmentId
        }
        this.roleService.deleteDepartment(id).subscribe((response) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your selected department has been deleted.',
              icon: 'success',
              timer: 800,
              showConfirmButton: false,
            });
            this.getdepartmentList(this.egId)
          },
          (error) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected department is safe :)', 'error');
      }
    });

  }

  reset(): void {
    this.departmentForm.reset();
    this.isUpdateDepartment = false;
  }
  closeadddepartmentModel(): void {
    this.departmentModelVisible = false;
    this.reset();
  }
  getPaginator(): void {
    setTimeout(() => {
      this.departmentData.paginator = this.paginator;
      this.departmentData.sort = this.sort;
    }, 1000);
  }
}
