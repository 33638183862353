import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class QualityParameterLoggingService {
  baseUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }


  getPlants(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'qualityparametergroups', data);
  }
  getCavity(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'qualityparametergroups', data);
  }

  getDataApplyFilter(data: any): Observable<any[]> {

    return this.http.post<any[]>(this.baseUrl + 'qualityparameterlogging', data);

  }

  DeleteSelected(data: any): Observable<any[]> {

    return this.http.post<any[]>(this.baseUrl + 'deletequalityparameterlogging', data);

  }

  addqualityparameterlogging(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'addqualityparameterlogging', data);

  }
  UpdateParameterGroup(data: any): Observable<any[]> {

    return this.http.put<any[]>(this.baseUrl + 'qualityparameterlogging', data);

  }
  ChangeParameter(data: any): Observable<any[]> {

    return this.http.post<any[]>(this.baseUrl + 'changequalityparameters', data);

  }


}
