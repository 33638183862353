<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>

<div class="sfdc-configuration-content">
    <div class="content-top-box">
        <ul class="breadcrumb">
          <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
              (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
          </li>
        </ul>
      </div>
    <div class="header-title">
        <h1 class="main-title">Manager Execution</h1>
    </div>
</div>

<div class="w-100 manaForm" style="display: flow-root;">
    <mat-form-field class="w-22">
        <mat-label>Line/Area</mat-label>
        <input required matInput [(ngModel)]="operator.LineId" readonly>
    </mat-form-field>
    <mat-form-field class="w-22">
        <mat-label>WO Time Stamp</mat-label>
        <input required matInput [(ngModel)]="operator.TimeStamp" readonly>
    </mat-form-field>
    <mat-form-field class="w-22">
        <mat-label>WO Id</mat-label>
        <input required matInput [(ngModel)]="operator.WOIdDisplay" readonly>
    </mat-form-field>
    <mat-form-field class="w-22">
        <mat-label>WO Status</mat-label>
        <input required matInput
            [ngClass]="{'text-green' : (operator.WOStatus == 'Complete' || operator.WOStatus == 'Close'), 'text-red' : (operator.WOStatus == 'Open' || operator.WOStatus == 'Delay'), 'text-orange' : (operator.WOStatus == 'In Progress')}"
            [(ngModel)]="operator.WOStatus" readonly>
    </mat-form-field>
    <mat-form-field class="w-22">
        <mat-label>WO Type</mat-label>
        <mat-select required [(ngModel)]="operator.WOType" 
        (selectionChange)="getWOTypeFailureData()" [disabled]="techResponded" [disabled]="isviewMode">
            <mat-option *ngFor="let type of woType" [value]="type.ID">
                {{type.Name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w-22">
        <mat-label>Failure Mode</mat-label>
        <mat-select required [(ngModel)]="operator.FailureMode" [disabled]="techResponded"  [disabled]="isviewMode"
        >
            <mat-option *ngFor="let mode of failMode" [value]="mode.ID"> {{mode.Name}} </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w-22">
        <mat-label>Requested By</mat-label>
        <input required matInput [value]="operator.requestedUserName" [disabled]="isviewMode"
            readonly>
    </mat-form-field>

    <div class="toggle">
        <label>Spare Parts</label>
        <mat-slide-toggle [(ngModel)]="operator.SparePartsToggle" (change)="SpareToggle($event)" [disabled]="isviewMode"></mat-slide-toggle>
    </div>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="w-100 manaForm">
        <mat-form-field class="w-22">
            <mat-label>Spare Part Code</mat-label>
            <input required matInput [(ngModel)]="manager.SparePartCode" formControlName="SparePartCode" [readonly]="isviewMode">
            <mat-error>Spare Part Code is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-22">
            <mat-label>Priority</mat-label>
            <mat-select required [(ngModel)]="manager.Priority" formControlName="Priority" [disabled]="techResponded" [disabled]="isviewMode">
                <mat-option *ngFor="let Priority of PriorityList" [value]="Priority.ID">
                    {{Priority.Name}}
                </mat-option>
            </mat-select>
            <mat-error>Priority is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-48">
            <mat-label>Assign Technician</mat-label>
            <mat-select required [(ngModel)]="manager.assignUser" formControlName="assignUser" [disabled]="techResponded" [disabled]="isviewMode">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'fullName'" [array]="userList"
                (filteredReturn)="userFiltList = $event">
              </mat-select-filter>
                <mat-option *ngFor="let user of userFiltList" [value]="user.UserId">
                    {{user.fullName}} <span>( {{woTypeName}} - {{user && user.Score ? user.Score : '0'}} , Avg - {{user && user.AverageScore ? user.AverageScore : '0'}} )</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="row">
            <mat-form-field class="remarks">
                <mat-label>Manager Remarks</mat-label>
                <textarea required matInput [(ngModel)]="manager.ManagerRemarks" formControlName="ManagerRemarks" [readonly]="isviewMode"></textarea>
                <mat-error>Manager Remarks is required</mat-error>
            </mat-form-field>
            <mat-form-field appearence="fill" class="remarks">
                <mat-label>Work Order Details</mat-label>
                <textarea required matInput [(ngModel)]="operator.Remark" formControlName="remark" [readonly]="isviewMode"></textarea>
                <mat-error>Work Order Details is required</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="dummy w-22">
        <input type="hidden" name="">
    </div>

    <h2 *ngIf="OperatorFiles && OperatorFiles.length">Operator Images</h2>
    <div *ngIf="OperatorFiles && OperatorFiles.length" class="imgflex">
        <div *ngFor="let file of OperatorFiles; let i = index" class="relative">
            <div>
                <img [src]="blobStorageUrl+file.ImageSrc" alt="" width="250px"
                    height="200px" (click)="imgview($event)">
            </div>
        </div>
    </div>

    <h4 *ngIf="TechnicianFiles && TechnicianFiles.length">Technician Images</h4>
    <div *ngIf="TechnicianFiles && TechnicianFiles.length" class="imgflex m-0">
        <div *ngFor="let file of TechnicianFiles; let i = index" class="relative">
            <div>
                <img [src]="blobStorageUrl+file.ImageSrc" alt="" width="250px" height="200px" (click)="imgview1($event)">
            </div>
        </div>
    </div>
    <div class="action">
        <button class="cancel"       
        routerLink="/PM/manager" [disabled]="!IsWrite" type="button">Cancel</button>
        <button class="submit" [disabled]="!IsWrite" type="submit"         *ngIf="!isviewMode"
        >Submit</button>
    </div>
</form>
<!-- img popup -->
<ng-template #manageroperatorimageViewModal>
    <button mat-mini-fab color="warn" (click)="oncloseimg()"><mat-icon>close</mat-icon></button>
    <mat-carousel timings="250ms ease-in" [autoplay]="false" interval="5000" color="accent" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
      [useMouseWheel]="true" orientation="ltr">
      <mat-carousel-slide #matCarouselSlide *ngFor="let file of OperatorFiles; let i = index" [image]="blobStorageUrl+file.ImageSrc"
        overlayColor="#00000040" [hideOverlay]="false"></mat-carousel-slide>
    </mat-carousel>
  </ng-template>
  <ng-template #managertechnicianimageViewModal>
    <button mat-mini-fab color="warn" (click)="oncloseimg1()"><mat-icon>close</mat-icon></button>
    <mat-carousel timings="250ms ease-in" [autoplay]="false" interval="5000" color="accent" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
      [useMouseWheel]="true" orientation="ltr">
      <mat-carousel-slide #matCarouselSlide *ngFor="let file of TechnicianFiles ; let i = index" [image]="blobStorageUrl+file.ImageSrc"
        overlayColor="#00000040" [hideOverlay]="false"></mat-carousel-slide>
    </mat-carousel>
  </ng-template>
  <!-- end img popup -->