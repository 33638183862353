import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WorkFlowService {
  workFlowUrl: string = environment.workFLowApi;
  o3CorebaseUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }
  // work flow api routes
  getWorkFlow(key: any): Observable<any> {
    return this.http.post<any>(
      this.workFlowUrl + 'WorkFlow/getWorkFlowByScreenName',
      key
    );
  }

  createBulkInstances(data: any): Observable<any> {
    return this.http.post<any>(
      this.workFlowUrl + 'Instance/createBulkInstances',
      data
    );
  }
  createSingleInstances(data: any): Observable<any> {
    return this.http.post<any>(
      this.workFlowUrl + 'Instance/createInstance',
      data
    );
  }
  setBulkInstance(data: any): Observable<any> {
    return this.http.post<any>(
      this.workFlowUrl + 'Instance/setBulkInstance',
      data
    );
  }
  setSingleInstance(data: any): Observable<any> {
    return this.http.post<any>(this.workFlowUrl + 'Instance/setInstance', data);
  }
  updateInstance(data: any): Observable<any> {
    return this.http.post<any>(this.workFlowUrl + 'Instance/updateInstance', data);
  }

  setNCRInstanceKey(data: any): Observable<any> {
    return this.http.post<any>(
      this.o3CorebaseUrl + 'NCR/SetNCRInstanceKey',
      data
    );
  }
  getInstanceCurrentState(key: any): Observable<any> {
    return this.http.get<any>(
      this.workFlowUrl + 'Instance/getCurrentInstanceState/' + key
    );
  }
  getInstanceCurrentStateByNCR(key: any): Observable<any> {
    return this.http.post<any>(
      this.workFlowUrl + 'Instance/getCurrentInstanceStateByNCR', key
    );
  }
  getWorkflowStatuses(key: any): Observable<any> {
    return this.http.get<any>(
      this.workFlowUrl + `WorkFlow/getWorkFlowStates/${key}`
    );
  }
  deleteBulkInstances(data: string[]): Observable<any> {
    return this.http.post<any>(
      this.workFlowUrl + 'Instance/deleteBulkInstance',
      data
    );
  }
  deleteSingleInstance(instance) {
    return this.http.delete<any>(
      this.workFlowUrl + 'Instance/deleteInstance/' +
      instance
    );
  }
  discardInstance(key: any): Observable<any> {
    return this.http.post<any>(
      this.workFlowUrl + 'Instance/discardInstance',
      key
    );
  }
  getWorkFlowPermission(keys: any): Observable<any> {
    return this.http.post<any>(
      this.workFlowUrl + 'Instance/getUserPermission',
      keys
    );
  }
  getUserPermissionByNCR(keys: any): Observable<any> {
    return this.http.post<any>(
      this.workFlowUrl + 'Instance/getUserPermissionByNCR',
      keys
    );
  }



  resetWorkflowInstanceHeirarchy(data): Observable<any> {
    return this.http.post<any>(
      this.workFlowUrl + 'WorkFlow/ResetWorkflowInstanceHeirarchy',
      data
    );
  }

  getUserPermissionByTransition(data: any): Observable<any> {
    return this.http.post<any>(
      this.workFlowUrl + 'Instance/getUserPermissionByTransition',
      data
    )
  }
}
