 
import { Component, OnInit, ViewChild, ViewChildren, QueryList, TemplateRef } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { MatTable, MatTableDataSource } from '@angular/material/table';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';

import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ToastrService } from 'ngx-toastr';

import { UserManagementService } from 'src/app/services/usermanagement.service';
import { OrderProcessingElement } from 'src/app/models/O3AdministrationModels/orderProcessingElement';

import { Router } from '@angular/router';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { UserAccessService } from 'src/app/services/userAccess.service';
import { UserAccess } from 'src/app/models/userAccess';
import { arrayToTree } from 'performant-array-to-tree';
import { TreeNode } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';




@Component({
  selector: 'app-useraccess',
  templateUrl: './useraccess.component.html',
  styleUrls: ['./useraccess.component.scss'],
})
export class UseraccessComponent implements OnInit {
  selectedMachine!: any[];
  machineTree: any;
  displayMachineDailog = false;
  userId!: number;
  machineForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private userService: UserAccessService,
    private breadcum: UserManagementService,
    private enterpriseGroupService: EnterPriseGroupService,
    private spinner: NgxSpinnerService,

    public router: Router
  ) {}
 
  userAccessListDataSource!: MatTableDataSource<any>;
  userAccessListColumns: any[] = [
    'action',
    'userid',
    'username',
    'name',
    'email',
    'mobile',
    'isgroupadmin',
    'createdby',
    'createdon',
    // 'userActions'
  ];
  userAccessListData = new MatTableDataSource<UserAccess>();
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  orderForm!: FormGroup;
  isSarchFormVisible = false;
  breadcrumList!: any;
  FormMode!: boolean;
  Groups!: any[];
  Plants!: any[];
  Departs!: any[];
  Users!: any[];
  groupDataForm!: FormGroup;
  TypeName!: string;
  @ViewChild(MatTable) table: MatTable<UserAccess> | undefined;
  
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;



  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();

    this.getGroups();
  
    this.groupDataForm = this.fb.group({
      Id: [''],
      group: [''],
      plant: [''],
      department: [''],
      image: [''],
    });
    this.machineForm = this.fb.group({
      plant: [''],
      productLine: [''],
      productUnit: [''],
      assembly: [''],
      subassembly: [''],
      component: [''],
      userId: [''],
    });
  }
 

  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.userService.getGroupData(key).subscribe((res: any)  => {
      this.Groups = res.masterList;
   
        this.groupDataForm.get('group')?.setValue( res.masterList[0].TypeId);
     
      // this.groupDataForm;
    
      this. getPlants();
     
    });
  }

  getPlants(): void {
    const key = {
      //GroupId: 1,
      GroupId: this.groupDataForm.value.group,
    };

    this.userService.getPlantData(key).subscribe((res: any) => {
      this.Plants = res.DepartList;
 
        this.groupDataForm.get('plant')?.setValue(this.Plants[0]?.DeptId);
 this. getDeparts();
    });
  }

  getDeparts(): void {
   
    const key = {
      
      Id: this.groupDataForm.value.plant,
    };
    this.userService.getDepartmentData(key).subscribe((res: any) => {
      this.Departs = res.masterList;
      //this.groupDataForm.get('group')?.setValue(this.Group[0]);
    });
  }

  getUsers(): void {
    const key = {
      GroupId: this.groupDataForm.value.group,
      PlantId: this.groupDataForm.value.plant,
    };
    this.spinner.show();

    this.userService.getUserList(key).subscribe((res: any) => {
      this.userAccessListDataSource = new MatTableDataSource<any>(res.UserList);

      this.getPaginator();
    this.spinner.hide();

    });
  }


  applyFilter(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.userAccessListDataSource.filter = filterValue.trim().toLowerCase();

  }

  getPaginator(): void {
    debugger;
    setTimeout(() => {
      this.userAccessListDataSource.paginator = this.paginator;
      this.userAccessListDataSource.sort = this.sort;
    }, 1000);
  }


  nodeUnselectTree(event: any) {
    let result: any = [];
    result = [event.node].reduce(function iter(r: any, o: any) {
      r.push(o);
      return (o.children || []).reduce(iter, r);
    }, []);
    let arr1= this.selectedMachine;
    let arr2= result;
    var data = arr1.filter((objFromA: any) => !arr2.find((objFromB: any) => objFromA.id === objFromB.id));
    this.selectedMachine=[];
    this.selectedMachine = this.selectedMachine.concat(data);
  }
  private expandRecursive(node: TreeNode, isExpand: boolean): any {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }
  expandAll(): any {
    this.machineTree.forEach((node: any) => {
      this.expandRecursive(node, true);
    });
  }

  collapseAll(): any {
    this.machineTree.forEach((node: any) => {
      this.expandRecursive(node, false);
    });
  }
  userMachineAccess(): void {
    let componentdata: any[] = []; let plantdata: any[] = [];
    let divisondata: any[] = []; let departmentdata: any[] = []; let unitdata: any[] = []; let assemblydata: any[] = [];
    let subassemblydata: any[] = []; let linedata: any[] = [];
    componentdata = this.selectedMachine.filter((e: any) => e.Level === 'Component').map((x: any) => x.TableId);
    plantdata = this.selectedMachine.filter((e: any) => e.Level === 'Plant').map((x: any) => x.TableId);
    divisondata = this.selectedMachine.filter((e: any) => e.Level === 'Division').map((x: any) => x.TableId);
    departmentdata = this.selectedMachine.filter((e: any) => e.Level === 'Department').map((x: any) => x.TableId);
    unitdata = this.selectedMachine.filter((e: any) => e.Level === 'Unit').map((x: any) => x.TableId);
    assemblydata = this.selectedMachine.filter((e: any) => e.Level === 'Assembly').map((x: any) => x.TableId);
    subassemblydata = this.selectedMachine.filter((e: any) => e.Level === 'SubAssembly').map((x: any) => x.TableId);
    linedata = this.selectedMachine.filter((e: any) => e.Level === 'Line').map((x: any) => x.TableId);

   
    const json = {
      componentdata:  componentdata.filter((n: any, i: any) => componentdata.indexOf(n) === i).toString(),
      plantdata : plantdata.filter((n: any, i: any) => plantdata.indexOf(n) === i).toString(),
      divisondata :  divisondata.filter((n: any, i: any) => divisondata.indexOf(n) === i).toString(),
      departmentdata :  departmentdata.filter((n: any, i: any) => departmentdata.indexOf(n) === i).toString(),
      unitdata :  unitdata.filter((n: any, i: any) => unitdata.indexOf(n) === i).toString(),
      assemblydata :  assemblydata.filter((n: any, i: any) => assemblydata.indexOf(n) === i).toString(),
      subassemblydata :  subassemblydata.filter((n: any, i: any) => subassemblydata.indexOf(n) === i).toString(),
      linedata :  linedata.filter((n: any, i: any) => linedata.indexOf(n) === i).toString(),
      userId: this.userId,
      createdBy:  JSON.parse(localStorage.user).userId,
    };
    // this.userService.saveMachineAccess(json).subscribe((respose: any) => {
    //     this.machineForm.reset();
    //     this.displayMachineDailog = false;
    //     this.toaster.success('Success', 'User Machine Permission Successfull');
    //   },
    //   (error: any) => {
    //     this.toaster.error('Error', error.message);
    //   }
    // );
  }
  
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.breadcum.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error: any) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
}
