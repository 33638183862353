<div class="container pt-100">
    <!-- Table -->
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header border-0 bg-white">
              <div class="row">
                  <div class="col-9 p-1">
                      <h4 class="mb-0 ml-2">Define Strategic KPI/Key Management Indicators(KMI) of the Organization</h4>
                  </div>
                  <div class="col-3 p-1">
                      <a class="btn btn-gradient-success btn-rounded btn-sm btn-fw text-white float-right">
                        <i class="fa fa-plus mr-1"></i> Add KMI
                      </a>
                  </div>
              </div>          
          </div>
          <div class="table-responsive mt-3">
            <table class="table align-items-center table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">Select Vision</th>
                  <th scope="col">KMI</th>
                  <th scope="col">Quantify</th>
                  <th scope="col">Remarks</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <div class="media align-items-center">                    
                      <div class="media-body">
                        <span class="mb-0 text-sm">Vision1</span>
                      </div>
                    </div>
                  </th>
                  <td>KMI 1</td>
                  <td>1 Yr to achieve</td>
                  <td>
                      <i class="fa fa-circle text-warning mr-4"></i> 
                      no remarks
                  </td>
                  <td>
                    <div>
                      <a
                      class="btn btn btn-gradient-warning btn-rounded  btn-sm text-white"
                      placement="top"
                      ngbTooltip="Edit"
                    >
                      <i class="fa fa-edit"></i>
                    </a>
                    <a
                      class="btn btn btn-gradient-danger btn-rounded  btn-sm text-white"
                      placement="top"
                      ngbTooltip="Delete"
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="media align-items-center">                    
                      <div class="media-body">
                        <span class="mb-0 text-sm">Vision2</span>
                      </div>
                    </div>
                  </th>
                  <td>KMI 2</td>
                  <td>1 Yr to achieve</td>
                  <td>
                    <i class="fa fa-circle text-primary mr-4"></i> 
                    no remarks
                  </td>
                  <td>
                    <div>
                      <a
                        class="btn btn btn-gradient-warning btn-rounded  btn-sm text-white"
                        placement="top"
                        ngbTooltip="Edit"
                      >
                        <i class="fa fa-edit"></i>
                      </a>
                      <a
                        class="btn btn btn-gradient-danger btn-rounded  btn-sm text-white"
                        placement="top"
                        ngbTooltip="Delete"
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="media align-items-center">                    
                      <div class="media-body">
                        <span class="mb-0 text-sm">Vision3</span>
                      </div>
                    </div>
                  </th>
                  <td>KMI 3</td>
                  <td>1 Yr to achieve</td>
                  <td>
                    <i class="fa fa-circle text-warning mr-4"></i> 
                      no remarks
                  </td>
                  <td>
                    <div>
                      <a
                        class="btn btn btn-gradient-warning btn-rounded  btn-sm text-white"
                        placement="top"
                        ngbTooltip="Edit"
                      >
                        <i class="fa fa-edit"></i>
                      </a>
                      <a
                        class="btn btn btn-gradient-danger btn-rounded  btn-sm text-white"
                        placement="top"
                        ngbTooltip="Delete"
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="media align-items-center">                    
                      <div class="media-body">
                        <span class="mb-0 text-sm">Vision4</span>
                      </div>
                    </div>
                  </th>
                  <td>KMI 4</td>
                  <td>1 Yr to achieve</td>
                  <td>
                    <i class="fa fa-circle text-danger mr-4"></i> 
                      no remarks
                  </td>
                  <td>
                    <div>
                      <a
                      class="btn btn btn-gradient-warning btn-rounded  btn-sm text-white"
                      placement="top"
                      ngbTooltip="Edit"
                    >
                      <i class="fa fa-edit"></i>
                    </a>
                    <a
                      class="btn btn btn-gradient-danger btn-rounded  btn-sm text-white"
                      placement="top"
                      ngbTooltip="Delete"
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="media align-items-center">                    
                      <div class="media-body">
                        <span class="mb-0 text-sm">Vision5</span>
                      </div>
                    </div>
                  </th>
                  <td>KMI 5</td>
                  <td>1 Yr to achieve</td>
                  <td>
                    <i class="fa fa-circle text-success mr-4"></i> 
                    no remarks
                  </td>
  
                  <td>
                    <div>
                      <a
                      class="btn btn btn-gradient-warning btn-rounded  btn-sm text-white"
                      placement="top"
                      ngbTooltip="Edit"
                    >
                      <i class="fa fa-edit"></i>
                    </a>
                    <a
                      class="btn btn btn-gradient-danger btn-rounded  btn-sm text-white"
                      placement="top"
                      ngbTooltip="Delete"
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>         
        </div>
      </div>
    </div>
  </div>
  
  
  
