import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';


@Component({
  selector: 'tooltip-component',
  template: ` <div class="custom-tooltip-Control" *ngIf="isHeader">
  <div [ngClass]="'panel panel-primary'">
    <div class="panel-heading">
      <!-- <h3 class="panel-title">{{ params.value }}</h3> -->
    </div>
    <div class="panel-body">
      <!-- <h4 [ngStyle]="{ whiteSpace: 'nowrap' }">{{ params.value }}</h4> -->
      <p>{{ pono }}</p>
      <p style="font-weight: bold">{{ code }}</p>
      <p style="font-weight: bold">{{ name }}</p>
    </div>
  </div>
</div> `,
  styles: [
    `
     :host {
        position: absolute;
        overflow: visible;
        pointer-events: none;
        transition: opacity 1s;
        max-width: 200px;
       
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip-Control p {
        margin: 5px;
        white-space: nowrap;
      
      }

      .custom-tooltip-Control p:first-of-type {
        font-weight: bold;
        max-width: 200px;
      }
      
    `,
  ],
})
export class CustomTooltip implements ITooltipAngularComp {
  
  params!: any;
  valueToDisplay!: string;
  isHeader!: boolean;
  isGroupedHeader!: boolean;
  type = '';
  pono!:any;
  code!:any;
  name!:any;

  agInit(params: any): void {


    this.params = params;

    this.pono = this.params?.value?.split('~')[0];
    this.code= this.params?.value?.split('~')[1];
    this.name= this.params?.value?.split('~')[2];

    this.isHeader = params.rowIndex === undefined;
    this.type = 'success';

    // this.isGroupedHeader = !!params.colDef.children;
    // this.valueToDisplay = params.value ? params.value : '- Missing -';
  }
}
