import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { Chart } from 'angular-highcharts';


const moment = _moment;
//drilldown js method declaration
declare function setDatasource(data: any): any;
declare function setDatasourceMonthlyOutLoss(data: any): any;


@Component({
  selector: 'app-oee-analysis',
  templateUrl: './oee-analysis.component.html',
  styleUrls: ['./oee-analysis.component.scss']
})
export class OeeAnalysisComponent implements OnInit {

  filteredLines: any = [];
  lineList: any = [];
  filteredAreaList: any = [];
  areaList: any = [];
  ytdHeader!: Chart;
  machineStops!: Chart;
  oee!: Chart;
  ytd!: Chart;
  downtime!: Chart;
  downtimeOccurences!: Chart;

  analysisHeaderForm = this.formBuilder.group({
    shift: ['', Validators.required],
    week: ['', Validators.required],
    month: ['', Validators.required],
    quarter: ['', Validators.required],
    line: ['', Validators.required],
    area: [new Date(), Validators.required]
  });
  IsAccess: boolean = false;
  IsWrite: boolean = false;
  breadcrumList: any = [];

  constructor(private formBuilder: FormBuilder, private kpiMaster: KpimasterService, private toaster: ToastrService, private usermanagementService: UserManagementService, private productivityservice: ProductivityService,
    public router: Router) { }

  ngOnInit(): void {
    this.drawChart();
  }

  drawChart() {
    this.ytdHeader = new Chart({
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        crosshair: true
      },
      // yAxis: {
      //   categories: ['0%', '20%', '40%', '60%', '80%', '100'],
      //   crosshair: true
      // },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getYTDHeaderBasedValues.bind(this)
            }
          }
        }
      },
      series: [
        {
          name: 'CIL Type',
          type: 'column',
          data: [{ y: 69, color: '#dc3545' }, { y: 67, color: '#dc3545' }, { y: 71, color: '#dc3545' }, { y: 53, color: '#dc3545' }, { y: 95, name: '%', color: '#10ce9c' }],
        }
      ],

    });

    const deptColumns = ['Dep 1', 'Dep 2', 'Dep 3', 'Dep 4']

    this.machineStops = new Chart({

      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: deptColumns,
        crosshair: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getYTDHeaderBasedValues.bind(this)
            }
          }
        }
      },

      series: [
        {
          name: 'Column A',
          type: 'column',
          data: [5, 2, 1, 3],
          "color": "#0f0fd1",

        },
        {
          name: 'Column B',
          type: 'column',
          data: [2, 3, 4, 2],
          "color": "#10ce9c",
        },
        {
          name: 'Column C',
          type: 'column',
          data: [3, 4, 5, 5],
          "color": "#17a2b8",

        },
        {
          name: 'Column D',
          type: 'column',
          data: [1, 1, 3, 3],
          "color": "#dc3545",
        }
      ],

    });

    this.ytd = new Chart({

      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Preforms', 'Krones', 'Injection Caps', 'HDPE', 'CBOB-CAPS'],
        crosshair: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getYTDHeaderBasedValues.bind(this)
            }
          }
        }
      },

      series: [
        {
          // name: '',
          // color: '17a2b8',
          type: 'column',
          data: [{ y: 5, color: '#17a2b8' }, { y: 2, color: '#17a2b8' }, { y: 1, color: '#17a2b8' }, { y: 3, color: '#17a2b8' }],
        }
      ],

    });

    let ranges = [
      [1246406400000, 14.3, 27.7],
      [1246492800000, 14.5, 27.8],
      [1246579200000, 15.5, 29.6],
      [1246665600000, 16.7, 30.7],
      [1246752000000, 16.5, 25.0],
      [1246838400000, 17.8, 25.7],
      [1246924800000, 13.5, 24.8],
      [1247011200000, 10.5, 21.4],
      [1247097600000, 9.2, 23.8],
      [1247184000000, 11.6, 21.8],
      [1247270400000, 10.7, 23.7],
      [1247356800000, 11.0, 23.3],
      [1247443200000, 11.6, 23.7],
      [1247529600000, 11.8, 20.7],
      [1247616000000, 12.6, 22.4],
      [1247702400000, 13.6, 19.6],
      [1247788800000, 11.4, 22.6],
      [1247875200000, 13.2, 25.0],
      [1247961600000, 14.2, 21.6],
      [1248048000000, 13.1, 17.1],
      [1248134400000, 12.2, 15.5],
      [1248220800000, 12.0, 20.8],
      [1248307200000, 12.0, 17.1],
      [1248393600000, 12.7, 18.3],
      [1248480000000, 12.4, 19.4],
      [1248566400000, 12.6, 19.9],
      [1248652800000, 11.9, 20.2],
      [1248739200000, 11.0, 19.3],
      [1248825600000, 10.8, 17.8],
      [1248912000000, 11.8, 18.5],
      [1248998400000, 10.8, 16.1]
    ],
      averages = [
        [1246406400000, 21.5],
        [1246492800000, 22.1],
        [1246579200000, 23],
        [1246665600000, 23.8],
        [1246752000000, 21.4],
        [1246838400000, 21.3],
        [1246924800000, 18.3],
        [1247011200000, 15.4],
        [1247097600000, 16.4],
        [1247184000000, 17.7],
        [1247270400000, 17.5],
        [1247356800000, 17.6],
        [1247443200000, 17.7],
        [1247529600000, 16.8],
        [1247616000000, 17.7],
        [1247702400000, 16.3],
        [1247788800000, 17.8],
        [1247875200000, 18.1],
        [1247961600000, 17.2],
        [1248048000000, 14.4],
        [1248134400000, 13.7],
        [1248220800000, 15.7],
        [1248307200000, 14.6],
        [1248393600000, 15.3],
        [1248480000000, 15.3],
        [1248566400000, 15.8],
        [1248652800000, 15.2],
        [1248739200000, 14.8],
        [1248825600000, 14.4],
        [1248912000000, 15],
        [1248998400000, 13.6]
      ];

    this.oee = new Chart({

      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },

      xAxis: {
        type: 'datetime',
        accessibility: {
          rangeDescription: 'Range: Jul 1st 2009 to Jul 31st 2009.'
        }
      },

      yAxis: {
        title: {
          text: null
        }
      },

      tooltip: {
        // crosshairs: true,
        shared: true,
        valueSuffix: '°C'
      },

      series: [{
        name: 'Temperature',
        type: 'line',
        data: averages,
        zIndex: 1,
        // marker: {
        //   fillColor: 'white',
        //   lineWidth: 2,
        //   lineColor: Chart.getOptions().colors[0]
        // }
      }, {
        name: 'Range',
        data: ranges,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        // color: Highcharts.getOptions().colors[0],
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false
        }
      }]

    });

    this.downtime = new Chart({

      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
      },
      xAxis: {
        categories: ['Startup', 'Machine Under', 'Cutting Ring', 'QC Approval', 'Label Jam', 'Disturbance in ', 'Machine On &', 'Blow Pin Tip Da', 'Preventive Maintenance', 'Carriage manufacturing'],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Duration (Min)',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        valueSuffix: ' millions'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [{
        // name: 'Year 1800',
        type: 'bar',
        data: [{ color: '#dc3545', y: 800 }, { color: '#c19614', y: 700 }, { color: '#c19614', y: 700 }, { color: '#c19614', y: 700 }, { color: '#ffc107', y: 600 }, { color: '#ffc107', y: 600 }, { color: '#ffc107', y: 600 }, { color: '#6acb80', y: 550 }, { color: '#6acb80', y: 550 }, { color: '#10ce9c', y: 520 }]
      }]

    });

    this.downtimeOccurences = new Chart({

      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
      },
      xAxis: {
        categories: ['QC Approval', 'Startup', 'Label Jam', 'QC Approval', 'Label Jam', 'Machine On & ', 'Machine Under &', 'QC Approval', 'Cavity Dumpin', 'Carriage manufacturing'],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Count of Fault',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        valueSuffix: ' millions'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [{
        // name: 'Year 1800',
        type: 'bar',
        data: [{ color: '#dc3545', y: 800 }, { color: '#c19614', y: 700 }, { color: '#c19614', y: 700 }, { color: '#c19614', y: 700 }, { color: '#ffc107', y: 600 }, { color: '#ffc107', y: 600 }, { color: '#ffc107', y: 600 }, { color: '#6acb80', y: 550 }, { color: '#6acb80', y: 550 }, { color: '#10ce9c', y: 520 }]
      }]

    });
  }

  getYTDHeaderBasedValues() { }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }

  getLine(id: any) {
    const key = {
      PT_ID: 0,
      userId: 206
    };

    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      if (this.lineList.length > 0) {
        const lineDefault = this.lineList[0];
        this.analysisHeaderForm.controls.line.patchValue(lineDefault.PL_Id);
        this.filteredLines = this.lineList.slice();
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

}


