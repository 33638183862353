import { Component, OnInit,TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThemePalette } from '@angular/material/core';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { Options } from 'highcharts';
import { Chart} from 'angular-highcharts';
import * as Highcharts from 'highcharts/highcharts';
import * as moment from 'moment';
declare var require: any;
require('highcharts/modules/funnel')(Highcharts);


@Component({
  selector: 'app-safety-trends',
  templateUrl: './safety-trends.component.html',
  styleUrls: ['./safety-trends.component.scss']
})
export class SafetyTrendsComponent implements OnInit {
  @ViewChild('safetyPyramidModal') private safetyPyramidModal!: TemplateRef<any>;
  @ViewChild('safetyPyramidAddFatalAccident') private safetyPyramidAddFatalAccident!: TemplateRef<any>;

  constructor(
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private userService: UserManagementService,
    private dialog: MatDialog,
    private productivityservice: ProductivityService,
    private enterpriseGroupService: EnterPriseGroupService,
    public router: Router,
    private spinner: NgxSpinnerService
  ) { 
    this.columnDefs = [
      { headerName: 'Event Date', field: 'StartTime', filter: 'agDateColumnFilter', valueFormatter: function (params: any) {return params.value !== null ?  moment(params.value).format('MM/DD/YYYY HH:mm:ss') :'' } },
      { headerName: 'YTD', field: 'Remarks', filter: 'agTextColumnFilter' },
      { headerName: 'Target', field: 'Frequency', filter: 'agTextColumnFilter' },
      { headerName: 'W.Manhours', field: 'UserName', filter: 'agTextColumnFilter' },
      { headerName: 'BR Manhours', field: 'PreTaskImage',filter: 'agTextColumnFilter'},
    ]
  }

  // form unitDetailForm fields controls
  public showSpinners = true;
  GroupList!: any[];
  PlantList1!: any[];
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  breadcrumList!: any;
  isPopupMachine = false;
  history = false
  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;
 

  gridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
  };
  menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';
  chartPyramid!: Chart;
  options!: Options;
  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
  });
  
  safetyTrendsForm!: FormGroup;
  SafetyPyramidDetailForm!: FormGroup;
  AddFatalAccidentForm!:FormGroup;
  UnitNode: any;
  Group: string | undefined;
  length : boolean = false;
  inputReadonly = true;
  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    // // this.spinner.show(); 
    this.initFormSafetyTrendsForm();
    this.initFormSafetyDetails();
    this.initFormAddFatalAccident();

    this.datasource();
    this.drawPyramid();
  }
  initFormAddFatalAccident() {
    this.AddFatalAccidentForm = this.formBuilder.group({
      department: [],
      description: [],
      eventDate : [],
    });
  }
 
  initFormSafetyTrendsForm() {
    this.safetyTrendsForm = this.formBuilder.group({
      UserGroup1: [],
      UserPlant1: [],
      startDate : [],
      endDate: [],
    });
  }

  initFormSafetyDetails() {
    this.SafetyPyramidDetailForm = this.formBuilder.group({
      trirytd: [],
      trirtarget: [],
      workingmanhour : [],
      bestrecordman: [],
    });
  }
;

  ngAfterViewInit(): any {
  
  }
  datasource(): void {
    const key = {
      Id: 0
    };
    const id = {
      value: 0
    };
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      id.value = data.lstEG[0].EG_Id;
      this.length = this.GroupList.length >1 ? true : false;
      this.safetyTrendsForm.get('UserGroup1')?.patchValue(data.lstEG[0].EG_Id);
      this.safetyTrendsForm.get('UserPlant1')?.patchValue(data.lstEG[0].EG_Id);
      this.getPlants(id);
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  onSubmit(): void {
  
  
  }
  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: 206
    };

    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      const id = data.lstPlant[0].PT_Id;
      this.safetyTrendsForm.get('UserPlant1')?.patchValue(id);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  openPopupSafetyPyramidDetail(event:any , type :string): void {
    type === 'history' ? this.history = true : this.history = false;
    this.dialog?.open(this.safetyPyramidModal, {
      width: '50vw !important',
        height: 'auto',
        minWidth: '50vw',
    });
  }

  openPopupSafetyPyramidAddFatalAccident(value : any){
    if(value.point.name === 'Unsafe Situation[Hazard]'){
      this.dialog?.open(this.safetyPyramidAddFatalAccident, {
        width: '50vw !important',
          height: 'auto',
          minWidth: '50vw',
      });
    }  
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }
  //===================================================== Save Safety Pyramid Details==========================//
  saveSafetyDetail():void{

  }

  //================================================== Pyramid Chart=============================================//
  drawPyramid() {
    // let dtLabel: any[] = new Array();
    // let dtData: any[] = new Array();
    // if (data !== null) {
    //   data.forEach((element: any) => {
    //     dtLabel.push(element.Month);
    //     dtData.push([element.Month, parseFloat(element.AvgMonthlyOEE)]);
    //   });
    // }
    this.options = {
      chart: {
        type: 'pyramid',
        marginRight: 100
    },
    credits:{
        enabled:false
    },
    title: {
        text: '',
        x: -50
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b> ({point.y:,.0f})'
            },
            cursor: 'pointer',
            events: {
                click: this.openPopupSafetyPyramidAddFatalAccident.bind(this)
            }
        },
        
    },
    legend: {
        enabled: false
    },
    series: [{
        name: 'Safety pyramid',
        type:'pyramid',
        data: [
            ['Fatal Accident', 120],
            ['Last time Injury', 100],
            ['Medical Treatment Case', 60],
            ['First Aid',80],
            ['Fire Incident',100],
            ['Near Miss',120],
            ['Unsafe Situation[Hazard]',140],
        ]
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 600
            },
            chartOptions: {
                plotOptions: {
                    series: {
                        dataLabels: {
                            inside: true
                        },
                        
                    }
                }
            }
        }]
    }

    };
    let chart = new Chart(this.options);
    this.chartPyramid = chart;
  }
  //============================================================= AG Grid==================================================//
   onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  excelExport() {
    this.gridApi.exportDataAsExcel();
  }
  csvExport(){
    this.gridApi.exportDataAsCsv();
  }
}

