import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { OeeConfigurationService } from 'src/app/services/oee-configuration.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { CheckListComponent } from 'src/app/screens/check-List/check-List.component';
import { CheckListTypeComponent } from '../check-list-type/check-list-type.component';
import { ChecklistReportConfigurationComponent } from '../checklist-report-configuration/checklist-report-configuration.component';

@Component({
  selector: 'app-quality-master-data',
  templateUrl: './quality-master-data.component.html',
  styleUrls: ['./quality-master-data.component.scss']
})
export class QualityMasterDataComponent implements OnInit {

  @ViewChild(CheckListComponent) checkListComponent!: CheckListComponent;
  @ViewChild(ChecklistReportConfigurationComponent) reportConfig!: ChecklistReportConfigurationComponent;
  @ViewChild(CheckListTypeComponent) checkListTypeComponent!: CheckListTypeComponent;

  searchQualityMasterForm!: FormGroup;
  plants!: any[];
  plantId!: number;
  GroupList!: any[];
  areaForm!: FormGroup;
  breadcrumList: any;
  plant: number;
  constructor(
    private fb: FormBuilder,
    private userService: UserManagementService,
    private toaster: ToastrService,
    public router: Router,
    private oeeConfigurationService: OeeConfigurationService,
    private machineService: MachineMappingService, 
    private toastrService: ToastrService,) 
    {}

  ngOnInit() {
    this.searchQualityMasterForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
    });
    this.datasource();
    this.GetBreadcrumList();
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  datasource(): void {
    const key = {
      Id: 0
    };
    this.oeeConfigurationService.getGroupData(key).subscribe((data: any) => {

      this.GroupList = data.masterList;
      this.searchQualityMasterForm.get('group')?.setValue(this.GroupList[0].TypeId);
      this.getPlants();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getPlants(): void {
    const key = {
      GroupId: this.searchQualityMasterForm.value.group
    };
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.plants = data.DepartList;
      this.searchQualityMasterForm.get('plant')?.setValue(this.plants[0].DeptId);
      this.plant = this.plants[0].DeptId;
      const key = {
        plantId: this.plants[0].DeptId,
        groupId: this.searchQualityMasterForm.value.group
      };
      this.checkListComponent.getlist(key);
      this.checkListTypeComponent.getCheckListType(key);
      this.reportConfig?.getkeys(key);

    }, (error: any) => {
      this.toastrService.error('Error', error.message);
    });
  }

  onChangeGroup(data: any): void {
    this.searchQualityMasterForm.get('group')?.setValue(data.value);
    this.getPlants();
  }

  onChangePlant(data: any): void {
    this.plant = this.plants[0].DeptId;
    const key = {
      plantId: this.searchQualityMasterForm.value.plant,
      groupId: this.searchQualityMasterForm.value.group
    };
    this.checkListComponent.getlist(key);
    this.checkListTypeComponent.getCheckListType(key);
    this.reportConfig?.getkeys(key);
  }

  renderCheckList(c: boolean) {
    if (c == true) {
      const key = {
        plantId: this.searchQualityMasterForm.value.plant,
        groupId: this.searchQualityMasterForm.value.group
      };
      this.checkListComponent.getlist(key);
      this.reportConfig?.getkeys(key);
    }
  }
}