<section class="home-content">
    <ul class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="breadcrumb-link" routerLink="/Access">Access</a>
      </li>
    </ul>
    <mat-card>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6 p-lg-7 p-xl-7">
          <mat-card-title >People Master Data</mat-card-title>
        </div>
  
        <div class="p-col-12 p-md-6 p-lg-5 p-xl-5" style="display: flex; justify-content: flex-end;">
          <form [formGroup]="searchMasterForm" style="float: right;">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label class="required">Group</mat-label>
              <mat-select panelClass="testClass" name="group" formControlName="group" (selectionChange)="getPlantData()">
                <mat-option *ngFor="let group of groupList" [value]="group.TypeId">{{group.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>
  
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label class="required">Plant</mat-label>
              <mat-select panelClass="testClass" name="plant" formControlName="plant" (selectionChange)="getMasterData()">
                <mat-option *ngFor="let plant of plantList" [value]="plant.DeptId">{{plant.DeptDesc}}</mat-option>
              </mat-select>
            </mat-form-field>
  
          </form>
        </div>
      </div>
    </mat-card>
    <mat-card class="p-mt-2 p-p-0">
      <!-- Table -->
      <div class="p-grid p-fluid">
        
        <div class="p-xl-12 p-lg-12 p-md-12 p-col-12">
          <app-employee-master></app-employee-master>
        </div>
        <div class="p-xl-6 p-lg-6 p-md-12  p-col-12">
          <app-position-master></app-position-master>
        </div>
        <div class="p-xl-6 p-lg-6 p-md-12  p-col-12">
          <app-employee-position-mapping></app-employee-position-mapping>
        </div>
      </div>
    </mat-card>
  </section>
