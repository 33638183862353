<ngx-spinner bdColor="rgba(0, 0, 0, 0)" template=" <img src='assets/images/spinner.gif' />">
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>

<div class="content-top-box">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
</div>

<div class="container" style="margin-top: 25px;">
    <form name="dashboardHeaderForm" [formGroup]="DashboardHeaderForm" #formDirective="ngForm" (ngSubmit)="getRCADashboardData()">
      <div class="d-flex-field headerForm">
        <mat-form-field appearence="fill" class="w-20">
          <mat-label>Group</mat-label>
          <mat-select formControlName="group" (selectionChange)="getPlants()">
            <mat-select-filter [placeholder]="'Search Group'" [displayMember]="'TypeName'" [array]="groupList"
              (filteredReturn)="groupFiltList = $event">
            </mat-select-filter>
            <mat-option *ngFor="let group of groupFiltList" [value]="group.TypeId">
              {{group.TypeName}}
            </mat-option>
          </mat-select>
          <mat-error>Group is required</mat-error>
        </mat-form-field>
  
        <mat-form-field appearence="fill" class="w-20">
          <mat-label>Plant</mat-label>
          <mat-select formControlName="plant" (selectionChange)="getDepartments()">
            <mat-select-filter [placeholder]="'Search Plant'" [displayMember]="'DeptDesc'" [array]="plantList"
              (filteredReturn)="plantFiltList = $event">
            </mat-select-filter>
            <mat-option *ngFor="let plant of plantFiltList" [value]="plant.DeptId">
              {{plant.DeptDesc}}
            </mat-option>
          </mat-select>
          <mat-error>Plant is required</mat-error>
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="w-25">
          <mat-label>Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="fromDate"
              placeholder="From Date">
            <input matEndDate formControlName="toDate" placeholder="To Date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
  
        <!-- <mat-form-field appearence="fill" class="w-32">
          <mat-label>Search</mat-label>
          <input matInput type="text" (keyup)="onFilterChanged($event.target)">
        </mat-form-field> -->
  
        <div class="head-btn">
          <button type="submit" class="save" tooltipPosition="top" matTooltip="Search">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.3609 18.2168L14.6008 13.2662C15.8247 11.8113 16.4953 9.98069 16.4953 8.07499C16.4953 3.62251 12.8728 0 8.4203 0C3.96782 0 0.345306 3.62251 0.345306 8.07499C0.345306 12.5275 3.96782 16.15 8.4203 16.15C10.0918 16.15 11.6847 15.6458 13.0466 14.6888L17.8428 19.677C18.0432 19.8852 18.3129 20 18.6018 20C18.8753 20 19.1348 19.8957 19.3317 19.7061C19.7502 19.3034 19.7636 18.6357 19.3609 18.2168ZM8.4203 2.10652C11.7114 2.10652 14.3888 4.78391 14.3888 8.07499C14.3888 11.3661 11.7114 14.0435 8.4203 14.0435C5.12921 14.0435 2.45183 11.3661 2.45183 8.07499C2.45183 4.78391 5.12921 2.10652 8.4203 2.10652Z"
                fill="#10123D" />
            </svg>
          </button>
        </div>
      </div>
    </form>

    <div class="double-chart">
      <div [chart]="ProbableValidRootCauseChart"></div>
      <div [chart]="MsCountChart"></div>
    </div>

    <!-- <div [chart]="stock"></div> -->

    <!-- <div id="bread"></div> -->

    <div class="single-chart" [chart]="RcaByDepartmentChart"></div>

    <div class="single-chart" [chart]="RcaByPeopleChart"></div>

    <div class="single-chart" [chart]="RcaToolsByDepartmentChart"></div>

  </div>