import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { Chart } from 'angular-highcharts';


@Component({
  selector: 'app-fault-analysis',
  templateUrl: './fault-analysis.component.html',
  styleUrls: ['./fault-analysis.component.scss']
})
export class FaultAnalysisComponent implements OnInit {
  faultByShiftData: any = [];
  breakdownData: any = [];
  ShortStopsData: any = [];
  ShortStopsOEEChart: Chart[] = [];
  breakdownOEEChart: Chart[] = [];
  faultByShiftOEEChart: Chart[] = [];
  breadcrumList: any = [];
  faultOccurance!: Chart;
  constructor(private toaster: ToastrService, private usermanagementService: UserManagementService,
    public router: Router) { }

  ngOnInit(): void {
    this.drawChart();
    this.drawMaintenance();
  }

  drawChart() {
    this.faultOccurance = new Chart({

      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
      },
      xAxis: {
        categories: ['Bagging 1 Malfunctio...', 'Bagging Stopped/Ma...', 'The discgarge convey...', 'Bottles have jammed...', 'Backup Discharge: A...', 'Tailback: A Sensor de... ', 'A major preform gap...', 'The preform feed unit...', 'No Orders', 'Turning ON required:',
          'Held:Machine is sto...', 'Production Stopped...', 'Mould Protection is a...', 'Jammed bottles in th...', 'No demand/no plan', 'Bottle backup from ai...', 'Label Jam', 'Mould has not been...', 'The blowing pressure...', 'Rejected bottles colle...', 'Malfunction preform',
          'Preforms are jammed', 'Recurring faluty stretc...', 'Limit value exceeded...'],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Duration (Min)',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        valueSuffix: ' millions'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'bar',
        data: [{ color: '#dc3545', y: 146000 }, { color: '#e8ff22', y: 68000 }, { color: '#c2ff61', y: 58000 }, { color: '#98ff38', y: 48000 }, { color: '#96ff75', y: 27000 }, { color: '#96ff75', y: 24000 }, { color: '#96ff75', y: 23000 }, { color: '#96ff75', y: 14000 }, { color: '#5bff29', y: 8000 }, { color: '#5bff29', y: 6000 }, { color: '#5bff29', y: 3000 }, { color: '#5bff29', y: 3000 },
        { color: '#5bff29', y: 2000 }, { color: '#5bff29', y: 2000 }, { color: '#5bff29', y: 2000 }, { color: '#5bff29', y: 1000 }, { color: '#5bff29', y: 1000 }, { color: '#5bff29', y: 1000 }, { color: '#5bff29', y: 1000 }, { color: '#5bff29', y: 1000 }, { color: '#5bff29', y: 1000 }, { color: '#5bff29', y: 1000 },
        { color: '#5bff29', y: 1000 }, { color: '#5bff29', y: 1000 }]
      }]
    });
  }

  drawMaintenance() {
    this.faultByShiftData = [
      {
        title: 'Fault By Shift',
        data: [
          { y: 241000, color: '#f5c80f' }, { y: 231000, color: '#808080' }]
      }
    ];
    if (this.faultByShiftData && this.faultByShiftData.length) {
      for (const faultByShift of this.faultByShiftData) {
        const chart = new Chart({
          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 45
            }
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: faultByShift.categories,
            crosshair: true
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              borderWidth: 0,
              dataLabels: {
                enabled: true,
              },
              point: {
                events: {
                  click: this.getYTDHeaderBasedValues.bind(this)
                }
              }
            }
          },
          series: [
            {
              name: faultByShift.title,
              type: 'column',
              data: faultByShift.data
            }
          ]
        });
        this.faultByShiftOEEChart.push(chart);
      }
    }
  }

  getYTDHeaderBasedValues() { }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

}
