import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrevConfigurationService {
  baseUrl: string = environment.O3CoreApiUrl;
  NotificationUrl:string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }
  
  getTaskType(headerData: any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMTaskType/getPMTaskType', { params: headerData });
  }
  // *** Core Api Method *** //
  addTaskType(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTaskType/addPMTaskType', data);
  }
  // *** Core Api Method *** //
  updateTaskType(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTaskType/updatePMTaskType', data);
  }
  // *** Core Api Method *** //
  deleteTaskType(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + `PMTaskType/deletePMTaskType`,data);
  }
  // *** Core Api Method *** //
  getTool(headerData: any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMTool/getPMTool', { params: headerData });
  }
  // *** Core Api Method *** //
  addTool(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTool/addPMTool', data);
  }
  // *** Core Api Method *** //
  updateTool(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTool/updatePMTool', data);
  }
  // *** Core Api Method *** //
  deleteTool(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + `PMTool/deletePMTool`,data);
  }
  getPrevFrequency(headerData: any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMFrequency/GetPMFrequency', { params: headerData });
  }
  // *** Core Api Method *** //
  addPrevFrequency(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMFrequency/addPMFrequency', data);
  }
  // *** Core Api Method *** //
  updatePrevFrequency(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMFrequency/updatePMFrequency', data);
  }
  // *** Core Api Method *** //
  deletePrevFrequency(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + `PMFrequency/deletePMFrequency`,data);
  }
  // *** Core Api Method *** //
  getPrevTask(headerData: any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMTask/GetPMTask', { params: headerData });
  }
  addPrevTask(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTask/addPMTask', data);
  }
  // *** Core Api Method *** //
  updatePrevTask(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTask/updatePMTask', data);
  }
  // *** Core Api Method *** //
  deletePrevTask(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + `PMTask/DeletePMTask`,data);
  }
  // *** Core Api Method *** //
  PMMasterUpload(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMConfiguration/addMasterPM', data);
  }
  addUserWiseNotification(data:any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'notification/AddUserWiseNotifications', data);
  }

  addRoleWiseNotification(data:any): Observable<any[]> {
    return this.http.post<any>(this.NotificationUrl + 'add-notifications-rolewise', data);
  }

  addDepartmentWiseNotification(data:any): Observable<any[]> {
    return this.http.post<any>(this.NotificationUrl + 'add-notifications-departmentwise', data);
  }

  GetTreeMasterData(data:any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Common/GetTreeMasterData', data);
  } 

  // getUnitData(data: any): Observable<any[]> {
  //   return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetUnitList', data);
  // }

}
