import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AreaService {
  baseUrl: string = environment.O3CoreApiUrl;
  O3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getArea(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Area/GetMasterAuditDetails', data);
  }
  addArea(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Area/AddAuditArea', data);
  }
  updateArea(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.o3CoreApiUrl + 'Area/UpdateAuditArea', data);
  }
  deleteArea(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Area/DeleteAuditDetail', data);
  }
  getQualityArea(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'QualityArea/GetQualityAreaByPlantId', data);
  }
  getQualityMasterArea(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'qualityareas');
  }
  deleteQulaityMasterArea(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'deletequalityarea', data);
  }
  updateQualityMasterArea(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.baseUrl + 'updatequalityarea', data);
  }
  getQualityMasterAreaById(data: any): any {
    return this.http.post<any>(this.baseUrl + 'qualityarea', data);
  }
  getMasterArea(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Area/masterareabygroup', data);
  }
  getMasterAreaByDepartment(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Master/GetMasterAreaByDepartmentId', data);
  }
  getSectioMmanagerbyArea(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Area/GetSectionManagerByArea', data);
  }
  getAuditCheckpoint(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'AMAuditCheckPoint/GetAuditAreaByPlant', data);
  }
  getProcessCheckpoint(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'AmProcessCheckPoint/GetProcessAreaByPlant', data);
  }
}