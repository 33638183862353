<form [formGroup]="analysisHeaderForm">
    <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing m-0">
            <mat-select formControlName="shift" placeholder="Shift" panelClass="testClass" name="label">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="shiftList"
                    (filteredReturn)="filteredShiftList = $event"></mat-select-filter>
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let shift of filteredShiftList" [value]="shift.EG_Id">
                    {{shift.EG_Desc}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select formControlName="week" placeholder="Week" panelClass="testClass" name="label">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="weekList"
                    (filteredReturn)="filteredWeekList = $event"></mat-select-filter>
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let week of filteredWeekList" [value]="week.EG_Id">
                    {{week.EG_Desc}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select formControlName="month" placeholder="month" panelClass="testClass" name="label">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="monthList"
                    (filteredReturn)="filteredMonthList = $event"></mat-select-filter>
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let month of filteredMonthList" [value]="month.EG_Id">
                    {{month.EG_Desc}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select formControlName="quarter" placeholder="quarter" panelClass="testClass" name="label">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="quarterList"
                    (filteredReturn)="filteredQuarterList = $event"></mat-select-filter>
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let quarter of filteredQuarterList" [value]="quarter.EG_Id">
                    {{quarter.EG_Desc}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select formControlName="line" placeholder="Line" panelClass="testClass" name="line">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
                    (filteredReturn)="filteredLines = $event"></mat-select-filter>
                <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
                    {{line.PL_Desc}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select formControlName="area" placeholder="Area" panelClass="testClass" name="area">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="areaList"
                    (filteredReturn)="filteredAreaList = $event"></mat-select-filter>
                <mat-option *ngFor="let area of filteredAreaList" [value]="area.PU_Id">
                    {{area.PU_Desc}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>