<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center">
    <mat-card-title class="p-ml-2">Employee Master</mat-card-title>
    <div class="">
      <mat-form-field>
        <input matInput placeholder="Search...." autocomplete="off" (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>
    <div class="">
      <button mat-mini-fab mat-button mat-dialog-close color="accent" (click)="opensideModal()" pTooltip="Add Employee"
        tooltipPosition="top" style="margin-left: 16px;">
        <i class="fa fa-plus" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-card-content>
    <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
      <p style="font-size: 20px; color: #050a4f">Fetching Employee Data...</p>
    </ngx-spinner>
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
        <table mat-table class="basic-table plant-model-table" [dataSource]="employeeData" matSort>
        <ng-container matColumnDef="EmployeeNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Employee No</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.EmployeeNo}} </td>
        </ng-container>


        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Name</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.FirstName}} {{element.lastName}}</td>
        </ng-container>


        <ng-container matColumnDef="Email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Email</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.Email}}</td>
        </ng-container>

        <ng-container matColumnDef="Grade">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Grade</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.Grade}}</td>
        </ng-container>

        <ng-container matColumnDef="DOB">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">DOB</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.DOB | date:'shortDate'}}</td>
        </ng-container>

        <ng-container matColumnDef="DOJ">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">DOJ</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.DOJ | date:'shortDate'}}</td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-text">Action</p>
          </th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <button mat-icon-button class="edit-btn" pTooltip="Edit Employee" tooltipPosition="top"
                aria-label="Edit Row" (click)="onClickUpdateEmployee(element)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button class="tree-view-btn" pTooltip="Delete Employee" tooltipPosition="top"
                aria-label="View Row Tree" (click)="onClickDeleteEmployee(element)">
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                    fill="#F85439" />
                  <circle cx="17" cy="17" r="14" fill="#F85439" />
                  <path
                    d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                    fill="white" />
                </svg>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="employeeMasterColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: employeeMasterColumns"></tr>
      </table>
      <mat-paginator class="basic-paginataor" [pageSizeOptions]="[2, 5, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

<!--side bar modal code-->
<div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{ active: displayEmployeeModal }">
  <div class="sidepanel-close" (click)="closeEmployeeModel()"></div>
  <div class="model-content">
    <div class="model-top">
      <h2 class="model-title">{{formMode ? 'Add' : 'Update'}} Employee</h2>

      <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeEmployeeModel()">
        <svg width="14" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
            fill="black" />
        </svg>
      </button>
    </div>
    <div class="model-body">
      <form [formGroup]="employeeForm">

        <div class="form-group">
          <section class="example-section">
            <mat-checkbox class="example-margin" [checked]="isappUser" (change)="onCheckedClick($event,'appUser')">Application User</mat-checkbox>
            <mat-checkbox class="example-margin" [checked]="isnonAppUser" (change)="onCheckedClick($event,'nonAppUser')">Non-Application User</mat-checkbox>
          </section>
        </div>

        <div class="form-group" *ngIf="isappUser">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label class="required">Login Id</mat-label>
            <mat-select panelClass="testClass" name="user" formControlName="user" [disabled]="isUpdateEmpMaster && isNonToapp" (selectionChange)="setUserInfo($event)">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'UserName'" [array]="userList"
              (filteredReturn)="filteredUserList = $event"></mat-select-filter>
              <mat-option *ngFor="let user of filteredUserList" [value]="user.UserId">{{user.UserName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>Employee No</mat-label>
            <input matInput formControlName="EmployeeNo" name="EmployeeNo" />
          </mat-form-field>
          <mat-error class="error-msg" *ngIf="checkError('EmployeeNo', 'required')">Employee No is required</mat-error>
        </div>

        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="FirstName" name="FirstName" />
          </mat-form-field>
          <mat-error class="error-msg" *ngIf="checkError('FirstName', 'required')">First Name is required</mat-error>
        </div>

        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" name="lastName" />
          </mat-form-field>
          <mat-error class="error-msg" *ngIf="checkError('lastName', 'required')">Last Name is required</mat-error>
        </div>

        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>Email</mat-label>
            <input matInput formControlName="Email" name="Email" />
          </mat-form-field>
          <mat-error class="error-msg" *ngIf="checkError('Email', 'email')">Email must be a valid email address</mat-error>
        </div>

        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>Grade</mat-label>
            <input matInput formControlName="Grade" name="Grade" />
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
          <mat-label>Choose DOB</mat-label>
          <input matInput [matDatepicker]="dobdatepicker" formControlName="DOB" [min]="minDate" [max]="maxDate" readonly>
          <mat-datepicker-toggle matSuffix [for]="dobdatepicker"></mat-datepicker-toggle>
          <mat-datepicker #dobdatepicker></mat-datepicker>
        </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>Choose DOJ</mat-label>
            <input matInput [matDatepicker]="dojPicker"  formControlName="DOJ" readonly>
            <mat-datepicker-toggle matSuffix [for]="dojPicker"></mat-datepicker-toggle>
            <mat-datepicker #dojPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>Nationality</mat-label>
            <mat-select panelClass="testClass" name="Nationality" formControlName="Nationality">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="countryCodes"
                (filteredReturn)="filteredCountryList = $event"></mat-select-filter>
              <mat-option *ngFor="let con of filteredCountryList" [value]="con.name">[{{con.dial_code}}] {{con.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error class="error-msg" *ngIf="checkError('Nationality', 'required')">Please select nationality</mat-error>
        </div>

        <div class="model-form-actions">
          <button type="button" class="icon-btn" style="margin-left: 10px;" pTooltip="Cancel" tooltipPosition="top" 
            (click)="closeEmployeeModel()">
            <img src="assets/images/cancel.png" alt="" width="20px" height="20px">
          </button>
          <button type="submit" class="icon-btn" style="margin-left: 10px;" pTooltip="Update" tooltipPosition="top"
            *ngIf="isUpdateEmpMaster" (click)="saveEmployee()">
            <img src="assets/images/edit.png" alt="" width="20px" height="20px">
          </button>
          <button type="submit" class="icon-btn" style="margin-left: 10px;" pTooltip="Save" tooltipPosition="top"
            *ngIf="!isUpdateEmpMaster" (click)="saveEmployee()">
            <img src="assets/images/save.png" alt="" width="20px" height="20px">
          </button>
        </div>
      </form>
    </div>
  </div>
</div>