import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const excelExtension = '.xlsx';
const fileSaver = require('file-saver');

@Injectable({
  providedIn: 'root'
})
export class TdcElementService {
  O3CoreApiUrl: string = environment.O3CoreApiUrl;
  
  constructor(private http: HttpClient) { }
  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Common/GetDepartments', data);
  }
  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Common/GetGroup', data);
  }

  GetAllTDCElements(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'LossAnalysisTDC/Getalltdcelements', data);
  }

  AddTDCElement(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'TDC/addtdcelement', data);
  }

  UpdateTDCElement(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.O3CoreApiUrl + 'TDC/updatetdcelement', data);
  }

  DeletedTDCElement(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'TDC/deletetdcelement', data);
  }

  GetcostGroupList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'CostCenter/GetCostCenterByParentId', data);
  }

  AddTDCElementDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'TDC/addtdcelementdetail', data);
  }

  GetAllTDCElementDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'TDC/gettdcelementdetail', data);
  }

  UpdateTDCElementDetail(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.O3CoreApiUrl + 'TDC/updatetdcelementdetail', data);
  }

  DeleteTDCElementDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'TDC/deletetdcelementdetail', data);
  }

   // Excel Download

   public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: excelType });
    fileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + excelExtension);
  }

}
