import { Component,  OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { CILDashboardService } from 'src/app/services/cil-dashboard-service';

@Component({
  selector: 'app-am-cil-dashboard',
  templateUrl: './am-cil-dashboard.component.html',
  styleUrls: ['./am-cil-dashboard.component.scss']
})
export class AmCilDashboardComponent implements OnInit {

  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  filteredGroups: any;
  filteredPlants : any;
  filteredLines : any;
  filteredUnits : any;
  breadcrumList: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  AMCLLDashboardForm!: FormGroup;
  TotalDefects!: any;
  TotalDefecteSolved!:any;
  TotalDefectsPerc!:any;
  CILTask!: any;
  CILTime!:any;
  CILAdherence!: any;
  key: any;
  dashboardData!: any;
  chart!: Chart;
  chartDCPer! : Chart;
  chartDT! :Chart;
  chartCILAD! : Chart;
  chartCILTasks!:Chart;
  chartCILTaskByOp! :Chart;
  chartCILTimeByOp! : Chart;
  chartCILTimeTrend!:Chart;
  chartCILTimeByMachine!:Chart;
  options!: Options;
   // iconUrl
   menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
   menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';

  constructor(private formBuilder: FormBuilder,
    private productivityservice: ProductivityService,
    private cilService : CILDashboardService,
    private toaster: ToastrService,
    public router: Router,
    private userService: UserManagementService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.initform();
    this.datasource();
    this.GetBreadcrumList();
  }

  initform(){
    const dateStart = new Date();
    //dateStart.setDate(dateStart.getDate() - 183);
    dateStart.setHours(6);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);


    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate());
    this.AMCLLDashboardForm = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
      shift: ['', []],
    });
  };

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }

  onChangeGroup(data : any):void{
    this.getPlants(data.value);
  }
  onChangeLine(data:any):void{
    this.getUnit(data.value.PL_Id);
  }
  onChangePlant(data:any):void{
    this.getLine(data.value);
  }
  datasource(): void {
    const key = {
      UserId: 206 
    };
    const id = {
      value: 0
    };
    // tslint:disable-next-line: no-debugger
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      id.value = data.lstEG[0].EG_Id;
      this.AMCLLDashboardForm.get('UserGroup1')?.setValue(data.lstEG[0].EG_Id);
      this.AMCLLDashboardForm.get('UserPlant1')?.setValue(data.lstEG[0].EG_Id);
      this.filteredGroups = this.GroupList.slice();
      this.getPlants(id);
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: 206
    };

    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      const id = data.lstPlant[0].PT_Id;
      this.AMCLLDashboardForm.get('UserPlant1')?.patchValue(id);
      this.filteredPlants = this.PlantList1.slice();
      this.getLine(id);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getLine(id: any) {
    const key = {
      PT_ID: id,
      userId: 206
    };

    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      const lineDefault = this.lineList[0];
      this.AMCLLDashboardForm.controls.line.patchValue(lineDefault.PL_Id);
      this.getUnit(lineDefault.PL_Id);
      this.filteredLines = this.lineList.slice();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUnit(id: number) {
    const key = {
      PL_ID: id,
      userId: 206
    };

    this.productivityservice.getUnitData(key).subscribe((data: any) => {
      this.unitList = data.lstUnit;
      const unitDefault = this.unitList[0];
      this.AMCLLDashboardForm.controls.unit.patchValue(unitDefault.PU_Id);
      this.filteredUnits = this.unitList.slice();
      this.onSubmit();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  onSubmit(): void {
    this.makeData();
    this.getAMCILDashboard();
  }

  today(): void {
    this.makeDatatoDay();
    this.getAMCILDashboard();
  }

  yesterday(): void {
    this.makeDataYesterDay();
    this.getAMCILDashboard();
  }

  lastWeek(): void {
    this.makeDataLastWeek();
    this.getAMCILDashboard();
  }

  lastMonth(): void {
    this.makeDataLastMonth();
    this.getAMCILDashboard();
  }
  
  getAMCILDashboard() {
    this.spinner.show();
    this.cilService.getAMCIL(this.key).subscribe((data: any) => {
      this.dashboardData = data;
      this.spinner.hide();
      this.setData();
    }, (error: any) => {
      this.toaster.error('error', error.ErrorMessage);
      this.spinner.hide();
    });
  }

  setData() {
    if(this.dashboardData !== null){
      this.TotalDefects = this.dashboardData.TotalDefects;
      this.TotalDefecteSolved = this.dashboardData.TotalDefecteSolved;
      this.TotalDefectsPerc = this.dashboardData.TotalDefectsPerc;
      this.CILTask = this.dashboardData.CILTask;
      this.CILTime = this.dashboardData.CILTime;
      this.CILAdherence = this.dashboardData.CILAdherence;
      this.openDefectsByArea();
      this.defectsClosingPercent();
      this.defectsByType();
      this.CILAdherencePercent();
      this.CILTaskPercent();
      this.CILTaskByOperatorPercent();
      this.CILTimeByOperatorPercent();
      this.CILTimeTrendPercent();
      this.CILTimeByMachinePercent();
    }
  }

  makeData() {
    const startDt = this.AMCLLDashboardForm.value.dateStart;
    const endDt = this.AMCLLDashboardForm.value.dateEnd;

    this.key = {
      //PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      //PU_id: this.MachineId,
      //UserId: JSON.parse(localStorage.user).UserId,
      GroupId: this.AMCLLDashboardForm.value.UserGroup1,
      PlantId: this.AMCLLDashboardForm.value.UserPlant1,
      UnitId: this.AMCLLDashboardForm.value.unit,
      LineId: this.AMCLLDashboardForm.value.line,
      shift: '*'

      //PSId: this.orderForm.value.StatusName
    };

  }

  makeDatatoDay(){
    const stDT = new Date();
    stDT.setDate(stDT.getDate());
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    const startDt = stDT;
    const endDt = edDT;
    this.AMCLLDashboardForm.get('dateStart')?.patchValue(stDT);
    this.AMCLLDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      //PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      //PU_id: this.MachineId,
      //UserId: JSON.parse(localStorage.user).UserId,
      GroupId: this.AMCLLDashboardForm.value.UserGroup1,
      PlantId: this.AMCLLDashboardForm.value.UserPlant1,
      UnitId: this.AMCLLDashboardForm.value.unit,
      LineId: this.AMCLLDashboardForm.value.line,
      shift: '*'

      //PSId: this.orderForm.value.StatusName
    };
   
  }
  makeDataYesterDay() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 1);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt =edDT;
    this.AMCLLDashboardForm.get('dateStart')?.patchValue(stDT);
    this.AMCLLDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      //PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      //PU_id: this.MachineId,
      //UserId: JSON.parse(localStorage.user).UserId,
      GroupId: this.AMCLLDashboardForm.value.UserGroup1,
      PlantId: this.AMCLLDashboardForm.value.UserPlant1,
      UnitId: this.AMCLLDashboardForm.value.unit,
      LineId: this.AMCLLDashboardForm.value.line,
      shift: '*'
      //PSId: this.orderForm.value.StatusName
    };
  }

  makeDataLastWeek() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 6);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.AMCLLDashboardForm.get('dateStart')?.patchValue(stDT);
    this.AMCLLDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      //PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      //PU_id: this.MachineId,
      //UserId: JSON.parse(localStorage.user).UserId,
      GroupId: this.AMCLLDashboardForm.value.UserGroup1,
      PlantId: this.AMCLLDashboardForm.value.UserPlant1,
      UnitId: this.AMCLLDashboardForm.value.unit,
      LineId: this.AMCLLDashboardForm.value.line,
      shift: '*'
      //PSId: this.orderForm.value.StatusName
    };
  }

  makeDataLastMonth() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 30);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.AMCLLDashboardForm.get('dateStart')?.patchValue(stDT);
    this.AMCLLDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      //PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      //PU_id: this.MachineId,
      //UserId: JSON.parse(localStorage.user).UserId,
      GroupId: this.AMCLLDashboardForm.value.UserGroup1,
      PlantId: this.AMCLLDashboardForm.value.UserPlant1,
      UnitId: this.AMCLLDashboardForm.value.unit,
      LineId: this.AMCLLDashboardForm.value.line,
      shift: '*'

      //PSId: this.orderForm.value.StatusName
    };

  }

  openDefectsByArea(){
    let defectAreaLabel : any[] = new Array();
    let defectAreaData: any[] = new Array();
    this.dashboardData.lstAMOpenDefectByArea.forEach((element:any )=> {
      defectAreaLabel.push(element.AreaName);
      defectAreaData.push([element.AreaName,parseFloat(element.Percent)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {  
        categories: defectAreaLabel, 
    },
    yAxis: {
      labels: {
        format: '{value}%'
      }
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defects',
        type : 'column',
        data: defectAreaData
      }]
    };
    let chart = new Chart(this.options);
    this.chart = chart;
  };

  defectsClosingPercent(){
    let defectPerLabel : any[] = new Array();
    let defectPerData: any[] = new Array();
    this.dashboardData.lstAMDefectsClosingPercent.forEach((element:any )=> {
      defectPerLabel.push(element.Month);
      defectPerData.push([element.Month,parseFloat(element.Percent)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {  
        categories: defectPerLabel, 
    },
    yAxis: {
      labels: {
        format: '{value}%'
      }
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defects Closed',
        type : 'column',
        data: defectPerData
      }]
    };
    let chart = new Chart(this.options);
    this.chartDCPer = chart;
  }

  defectsByType(){
    let defectLabel : any[] = new Array();
    let defectData: any[] = new Array();
    this.dashboardData.lstAMDefectsByType.forEach((element:any )=> {
      defectLabel.push(element.TypeName);
      defectData.push([element.TypeName,parseFloat(element.Percent)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {  
        categories: defectLabel, 
    },
    yAxis: {
      labels: {
        format: '{value}%'
      }
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defects',
        type : 'column',
        data: defectData
      }]
    };
    let chart = new Chart(this.options);
    this.chartDT = chart;
  }

  CILAdherencePercent(){
    let defectCILADLabel : any[] = new Array();
    let defectCILADData: any[] = new Array();
    this.dashboardData.lstAMCILAdherencePercent.forEach((element:any )=> {
      defectCILADLabel.push(element.Name);
      defectCILADData.push([element.Name,parseFloat(element.Percent)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {  
        categories: defectCILADLabel, 
    },
    yAxis: {
      labels: {
        format: '{value}%'
      }
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defects',
        type : 'column',
        data: defectCILADData
      }]
    };
    let chart = new Chart(this.options);
    this.chartCILAD = chart;
  }

  CILTaskPercent(){
    let defectCILTaskLabel : any[] = new Array();
    let defectCILTaskData: any[] = new Array();
    this.dashboardData.lstAMCILTaskPercent.forEach((element:any )=> {
      defectCILTaskLabel.push(element.Name);
      defectCILTaskData.push([element.Name,parseFloat(element.Percent)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {  
        categories: defectCILTaskLabel, 
    },
    yAxis: {
      labels: {
        format: '{value}%'
      }
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defects',
        type : 'column',
        data: defectCILTaskData
      }]
    };
    let chart = new Chart(this.options);
    this.chartCILTasks = chart;
  }

  CILTaskByOperatorPercent(){
    let defectCILTaskByOPLabel : any[] = new Array();
    let defectCILTaskByOPData: any[] = new Array();
    this.dashboardData.lstAMCILTaskPercent.forEach((element:any )=> {
      defectCILTaskByOPLabel.push(element.Name);
      defectCILTaskByOPData.push([element.Name,parseFloat(element.Percent)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {  
        categories: defectCILTaskByOPLabel, 
    },
    yAxis: {
      labels: {
        format: '{value}%'
      }
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defects',
        type : 'column',
        data: defectCILTaskByOPData
      }]
    };
    let chart = new Chart(this.options);
    this.chartCILTaskByOp = chart;
  }

  CILTimeByOperatorPercent(){
    let defectCILTaskByTimeLabel : any[] = new Array();
    let defectCILTaskByTimeData: any[] = new Array();
    this.dashboardData.lstAMCILTimeByOperatorPercent.forEach((element:any )=> {
      defectCILTaskByTimeLabel.push(element.Name);
      defectCILTaskByTimeData.push([element.Name,parseFloat(element.Percent)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {  
        categories: defectCILTaskByTimeLabel, 
    }, 
    yAxis: {
      labels: {
        format: '{value}%'
      }
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defects',
        type : 'column',
        data: defectCILTaskByTimeData
      }]
    };
    let chart = new Chart(this.options);
    this.chartCILTimeByOp = chart;
  }

  CILTimeTrendPercent(){
    let defectCILTimeTrendLabel : any[] = new Array();
    let defectCILTimeTrendData: any[] = new Array();
    this.dashboardData.lstAMCILTimeTrendPercent.forEach((element:any )=> {
      defectCILTimeTrendLabel.push(element.Name);
      defectCILTimeTrendData.push([element.Name,parseFloat(element.Percent)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {  
        categories: defectCILTimeTrendLabel, 
    }, yAxis: {
      labels: {
        format: '{value}%'
      }
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defects',
        type : 'column',
        data: defectCILTimeTrendData
      }]
    };
    let chart = new Chart(this.options);
    this.chartCILTimeTrend = chart;
  }

  CILTimeByMachinePercent(){
    let defectCILTimeByMachineLabel : any[] = new Array();
    let defectCILTimeByMachineData: any[] = new Array();
    this.dashboardData.lstAMCILTimeByMachinePercent.forEach((element:any )=> {
      defectCILTimeByMachineLabel.push(element.Name);
      defectCILTimeByMachineData.push([element.Name,parseFloat(element.Percent)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {  
        categories: defectCILTimeByMachineLabel, 
    },
    yAxis: {
      labels: {
        format: '{value}%'
      }
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defects',
        type : 'column',
        data: defectCILTimeByMachineData
      }]
    };
    let chart = new Chart(this.options);
    this.chartCILTimeByMachine = chart;
  }

}
