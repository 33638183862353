import { Pipe, PipeTransform } from '@angular/core';
import { strict } from 'assert';

@Pipe({name: 'StringToArray'})

export class StringToArray implements PipeTransform {

  transform(value: string, character = ','): any {
    if(character == '`*`' || character == 'loss'){
      return character == '`*`' ? value.split(character)[0] : value.split('`*`')[1].split(',');
    }
    else{
      return value.split(character);
    }
  }
}
