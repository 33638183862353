import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Department } from '../models/department';
import { Frequency } from '../models/frequency';
import { Pillar } from '../models/pillar';

@Injectable({
  providedIn: 'root',
})
export class MeetingDefinationService {
  
  NetApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) {}

  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'Common/GetDepartments',
      data
    );
  }

  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.NetApiUrl + 'Common/GetGroup', data);
  }

  getMasterFrequencies(data: any): Observable<Frequency[]> {
    return this.http.post<Frequency[]>(
      this.NetApiUrl + 'Frequencies/GetMasterFrequencies',
      data
    );
  }

  getFrequency(): Observable<any> {
    // return this.http.get(this.kpibaseUrl + 'masterfrequency');
    return this.http.get(this.NetApiUrl + 'MasterFrequency/GetMasterFrequency');
  }

  getMasterDepartments(data: any): Observable<Department[]> {
    return this.http.post<Department[]>(
      this.NetApiUrl + 'Department/GetMasterDepartments',
      data
    );
  }

  getMasterPillars(data: any): Observable<Pillar[]> {
    //return this.http.post<Pillar[]>(this.kpibaseUrl + 'masterpillars', data);
    return this.http.post<Pillar[]>(
      this.NetApiUrl + 'Pillars/GetMasterPillars',
      data
    );
  }

  GetAllMeetingAgenda(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.kpibaseUrl + 'getAllMeetingAgenda', data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetAllMeetingAgenda',
      data
    );
  }
  deleteAgenda(data: any): Observable<any> {
    //return data ;
    // return this.http.post<any[]>(this.kpibaseUrl + 'deleteAgenda', data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/deleteAgenda',
      data
    );
    
  }
  AddMeetingAgenda(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.kpibaseUrl + 'addMeetingAgenda' , data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/AddMeetingAgenda',
      data
    );
  }
/*
  AddMeetingDefination(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.kpibaseUrl + 'addMeetingDefination',
      datay
    );
  }
  */

  getParticipantsOnPlant(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.kpibaseUrl + 'GetParticipantsOnPlant' , data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetParticipantsOnPlant',
      data
    );
  }

  GetAllMeetingDefination(key: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetAllMeetingDefination',
      key
    );
  }

  DeleteMeetingDef(data: any): Observable<any[]> {
    //  return this.http.post<any[]>(this.kpibaseUrl + 'deleteMeetingDefination' , data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/DeleteMeetingDefination',
      data
    );
  }

  inActiveMeeting(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/UpdateMeetingInActive',
      data
    );
  }

  ActiveMeeting(data: any): Observable<any[]> {
    return this.http.post<any[]>( this.NetApiUrl + 'MeetingDefination/UpdateMeetingActive', data);
  }
  AddMeetingDef(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.kpibaseUrl + 'addMeetingDefination' , data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/AddMeetingDefination',
      data
    );
  }

  UpdateMeetingDef(data: any): Observable<any[]> {
    // return this.http.put<any[]>(this.kpibaseUrl + 'updateMeetingDefination', data );
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/UpdateMeetingDefination',
      data
    );
  }

  GetMeetingDefById(data: any): Observable<any> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeetingDefinationById',
      data
    );
  }

  GetMeetingQuestion(data: any) {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeetingQuestion',
      data
    );
  }
  AddMeetingQuestion(data: any) {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/AddMeetingQuestion',
      data
    );
  }

  DeleteMeetingQuestion(data: any) {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/DeleteMeetingQuestion',
      data
    );
  }

  GetMeetingDefinitionSelectedKpi(data: any) {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeetingDefinitionSelectedKpi',
      data
    );
  }

  GetMeetingKPIOrder(data: any) {
    return this.http.post(
      this.NetApiUrl + 'MeetingDefination/GetMeetingKPIOrder',
      data
    );
  }

  UpdateMeetingKPIOrder(data: any) {
    return this.http.post(
      this.NetApiUrl + 'MeetingDefination/UpdateMeetingKPIOrder',
      data
    );
  }

  SaveMeetingReschedule(data: any) {
    return this.http.post(
      this.NetApiUrl + 'MeetingDefination/SaveMeetingReschedule',
      data
    );
  }

  GetMeetingStatus(data: any) {
    return this.http.post(this.NetApiUrl + 'MeetingDefination/GetChartMeetingStatus',data);
  }

  GetMeetingCPStatus(data: any) {
    return this.http.post(this.NetApiUrl + 'MeetingDefination/GetMeetingCPStatus',data);
  }
 
  GetMeetingAttendanceRate(data: any) {
    return this.http.post(this.NetApiUrl + 'MeetingDefination/GetMeetingAttendanceRate',data);
  }
  GetMeetingEffectivenessChart(data: any) {
    return this.http.post(this.NetApiUrl + 'MeetingDefination/GetMeetingEffectivenessChart',data);
  }
  GetMeetingScheduleChart(data: any) {
    return this.http.post(this.NetApiUrl + 'MeetingDefination/GetMeetingScheduleChart',data);
  }
}
