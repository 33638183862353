<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
    <p style="color: white">Fetching Data...</p>
</ngx-spinner>
<div class="machine-parameter-content">
    <div class="content-top-box">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="displayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
      </ul>
      <div class="tab-content-top-box">
        <h1 class="main-title">QA Data Entry</h1>
      </div>
        <form [formGroup]="qaMasterForm">
            <div class="wrapper-box wrapper-box-shadow table-wrapper">
                <div class="content-top-row">
                    <div class="inputField" style="width: 245px;">
                        <nz-date-picker class="calender-input" style="width: 240px;"
                        formControlName="date" nzFormat="dd/MM/yyyy"
                        [nzDisabled]="isUpdated || disable" 
                        [nzInputReadOnly]="true" ></nz-date-picker>
                        <span class="floating-text">Select Date</span>   
                    </div>    
                    <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label>Select Report Type</mat-label>
                        <mat-select formControlName="reportType" panelClass="testClass" name="reportType">
                        <mat-option *ngFor="let rt of reportTypes" [value]="rt.TypeId">{{rt.TypeName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label>Select Crew</mat-label>
                        <mat-select formControlName="crew" panelClass="testClass" name="crew" [disabled]="disable">
                        <mat-option *ngFor="let cl of crewList" [value]="cl.TypeId">{{cl.TypeName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label>Select Shift</mat-label>
                        <mat-select formControlName="shift" panelClass="testClass" name="shift" [disabled]="disable">
                        <mat-option *ngFor="let sl of shiftList" [value]="sl.TypeId">{{sl.TypeName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field appearence="fill"  class="top-row-first-filed-item top-row-first-filed-item-order-processing" >     
                        <mat-label class="placeholder-title">Enter Quality</mat-label>
                        <input formControlName="quality" class="input-text"  matInput [readonly]="disable">
                    </mat-form-field> -->
                    <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                      <mat-label>Quality</mat-label>
                      <mat-select panelClass="testClass" formControlName = "quality"  name="production_quality" multiple>
                        <mat-option *ngFor="let q of qualityMenu" [value]="q.Name">{{q.Name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
                <div class="content-top-row">
                    <mat-form-field appearence="fill"  style="margin-left: 20px;"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing" >     
                        <mat-label class="placeholder-title">Enter Standard</mat-label>
                        <input formControlName="standard" class="input-text"  matInput [readonly]="disable">
                    </mat-form-field>
                    <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label>Select Thickness</mat-label>
                        <mat-select formControlName="thickness" panelClass="testClass" name="shift" [disabled]="disable">
                        <mat-option *ngFor="let ts of thicknessList" [value]="ts.TypeName">{{ts.TypeName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing" >     
                        <mat-label class="placeholder-title">Enter Gross Width</mat-label>
                        <input formControlName="grossWidth" class="input-text"  matInput [readonly]="disable">
                    </mat-form-field>
                    <mat-form-field appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing" >     
                        <mat-label class="placeholder-title">Enter Net Available</mat-label>
                        <input formControlName="netAvailable" class="input-text"  matInput [readonly]="disable">
                    </mat-form-field>
                    <mat-form-field appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing" >     
                        <mat-label class="placeholder-title">Enter Net Cut</mat-label>
                        <input formControlName="netCut" class="input-text"  matInput [readonly]="disable">
                    </mat-form-field>
                </div>
                <div class="content-top-row">
                    <button type="button" *ngIf="!isUpdated" (click)="saveMasterEntry()" mat-mini-fab mat-button color="primary" 
                    pTooltip="Save" tooltipPosition="top" 
                    style="margin-left: 10px;">
                        <mat-icon>save</mat-icon>
                    </button>
                    <button type="button" *ngIf="isUpdated" (click)="updateMasterEntry()" mat-mini-fab mat-button color="primary" 
                    pTooltip="Update" tooltipPosition="top" 
                    style="margin-left: 10px;">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button type="button" mat-mini-fab mat-button  color="accent"  pTooltip="Reset" (click)="resetQaMasterForm()"  tooltipPosition="top" style="margin-left: 10px;">
                    <mat-icon>refresh</mat-icon>
                    </button>
                    <button type="button" mat-mini-fab mat-button  color="primary" 
                    pTooltip="History" (click)="openCommonAttributeHistoryModel()" tooltipPosition="top" style="margin-left: 10px;">
                    <mat-icon>history</mat-icon>
                    </button>
                    <button *ngIf="isLoadingData" type="button" 
                    mat-button mat-flat-button color="accent" 
                    pTooltip="Load" tooltipPosition="top" style="margin-left: 10px;">
                    ↺⟳LOADING...
                    </button>
                    <button *ngIf="!isLoadingData" type="button" (click)="loadReportForm()" 
                    mat-button mat-mini-fab color="warn" 
                    pTooltip="Load" tooltipPosition="top" style="margin-left: 10px;">
                    <mat-icon>sync</mat-icon>
                    </button>
                    <button type="button" mat-button mat-mini-fab color="accent" 
                    (click)="getReports()"
                    pTooltip="Get Report" tooltipPosition="top" style="margin-left: 10px;">
                    <mat-icon>print</mat-icon>
                    </button>
                </div>
            </div>
        </form>
        <div *ngIf="!isReportMode" style="margin-top: 20px;">
          <form *ngIf="formPropertiesArray.length > 0" [formGroup]="qaDynamicForm">
            <div class="wrapper-box wrapper-box-shadow table-wrapper">
              <div class="content-top-row">
                <div class="inputField" style="width: 250px;">
                  <nz-time-picker class="calender-input" style="width: 235px;"
                  formControlName="Time" nzFormat="HH:mm" (ngModelChange)="consoleTime()"
                  [nzInputReadOnly]="true" ></nz-time-picker>
                  <span class="floating-text">Select Time</span>   
              </div>  
            </div>
              <div *ngFor="let qaform of formPropertiesArray">
                <h3 style="font-weight: bold;">{{qaform.TypeName}}</h3>
                <div class="content-row-wrap" *ngIf="qaform.TypeName !== 'Zebra' && qaform.TypeName !== 'Bloom / FLAT' && qaform.TypeName !== 'THICKNESS'">
                    <mat-form-field *ngFor="let control of qaform.properties" 
                    appearence="fill"
                    style="margin-bottom: 10px;" [style.display]="control.DisplayName=== 'CommonAttributeId'? 'none' : 'block'"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing" >     
                        <mat-label class="placeholder-title">{{control.DisplayName}}</mat-label>
                        <input [formControlName]="control.Name"  matInput *ngIf="control.DisplayName !== 'Defect type (scanner)- ISRA' 
                          && control.DisplayName !== 'Defect Name (Microscope)' && control.DisplayName !== 'Nt .Wt. for seed count(of 460mm)'
                          && control.DisplayName !== 'Edgelight seed count(<0.3mm)' && qaform.TypeName !== 'ISRA Information'
                          && control.DisplayName !== 'Core size(mm)'">
                        <input [formControlName]="control.Name" type="number"  matInput *ngIf="control.DisplayName == 'Nt .Wt. for seed count(of 460mm)'
                          || control.DisplayName == 'Edgelight seed count(<0.3mm)'">
                        <input [formControlName]="control.Name" type="number" (keyup)="validateNumberFields($event.key,control.Name)" matInput 
                          *ngIf="control.DisplayName == 'Core size(mm)' || (qaform.TypeName == 'ISRA Information' && control.DisplayName == 'Class')">
                        <mat-select [formControlName]="control.Name" panelClass="testClass" *ngIf="control.DisplayName == 'Defect type (scanner)- ISRA'">
                          <mat-option *ngFor="let DN of defectName" [value]="DN.TypeName">{{DN.TypeName}}</mat-option>
                        </mat-select>
                        <mat-select [formControlName]="control.Name" panelClass="testClass" *ngIf="control.DisplayName == 'Defect Name (Microscope)'">
                          <mat-option *ngFor="let DT of defectType" [value]="DT.TypeName">{{DT.TypeName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="content-row-wrap" *ngIf="qaform.TypeName == 'Zebra' || qaform.TypeName == 'Bloom / FLAT' || qaform.TypeName == 'THICKNESS'">
                  <mat-form-field *ngFor="let control of qaform.properties" 
                    appearence="fill"
                    style="margin-bottom: 10px;" [style.display]="control.DisplayName=== 'CommonAttributeId'? 'none' : 'block'"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing" >     
                      <mat-label class="placeholder-title">{{control.DisplayName}}</mat-label>
                      <input [formControlName]="control.Name" type="number"  matInput>
                  </mat-form-field>
                </div>
              </div>
              <div class="content-top-row">
                <button *ngIf="isMaterialDataMode" type="button" (click)="saveQAData()" mat-mini-fab mat-button color="primary" 
                pTooltip="Save" tooltipPosition="top"
                style="margin-left: 10px;">
                    <mat-icon>save</mat-icon>
                </button>
                <button *ngIf="!isMaterialDataMode" type="button" (click)="updateQAData()" 
                mat-mini-fab mat-button color="primary" 
                pTooltip="Update" tooltipPosition="top"
                style="margin-left: 10px;">
                    <mat-icon>edit</mat-icon>
                </button>
                <button style="margin-left: 10px" type="button" mat-mini-fab mat-button color="accent" pTooltip="Reset" (click)="resetForm()" tooltipPosition="top">
                  <mat-icon>refresh</mat-icon>
                </button>
                <button type="button" mat-mini-fab mat-button 
                (click)="openMaterialDataHistoryModel()" 
                color="primary" 
                pTooltip="History" tooltipPosition="top" style="margin-left: 10px;">
                <mat-icon>history</mat-icon>
                </button>
              </div>
            <div style="overflow-x: scroll;">
              <table mat-table [dataSource]="dataSource" class="basic-table plant-model-table">              
                <!-- All Columns -->
                <ng-container [matColumnDef]="headerName" *ngFor="let headerName of headerData">
                  <div *ngIf="headerName !== 'Actions'">
                    <th mat-header-cell *matHeaderCellDef><p class="header-text"> {{headerName}} </p></th>
                    <td mat-cell *matCellDef="let element"> {{element[headerName]}} </td>
                  </div>
                  <div *ngIf="headerName === 'Actions'">
                    <th mat-header-cell *matHeaderCellDef>
                      <p class="header-text">Actions</p>
                    </th>
                    <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                      <div class="row-action-btns" style="min-width: 80px;">
                        <button mat-icon-button class="edit-btn" aria-label="Edit Row"   (click)="editTableEntry(element)" pTooltip="Edit" tooltipPosition="top">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                              fill="white" />
                          </svg>
                        </button>
                        <button mat-icon-button class="tree-view-btn" aria-label="View Row Tree" (click)="deleteTableEntry(element)" pTooltip="Delete" tooltipPosition="top" pTooltip="Delete" tooltipPosition="top">
                          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                              fill="#F85439" />
                            <circle cx="17" cy="17" r="14" fill="#F85439" />
                            <path
                              d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                              fill="white" />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </div>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="headerData"></tr>
                <tr mat-row *matRowDef="let row; columns: headerData;"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching</td>
                </tr>
              </table>
            </div>
            </div>
          </form>
        </div>
        <div *ngIf="isReportMode" style="margin-top: 20px;">
          <div class="d-flex justify-content-between items-center" style="margin: 20px 0 20px 0;">
            <!-- <button type="button" 
            mat-button mat-flat-button color="warn" 
            (click)="openPDFExportModel()"
            pTooltip="Load" tooltipPosition="top" style="margin-left: 10px;">
            PDF Print
            </button> -->
            <button type="button" mat-button mat-flat-button color="accent" 
            (click)="exportToExcel()"
            pTooltip="Get Report" tooltipPosition="top" style="margin-left: 10px;">
             Export To Excel
            </button>
          </div>
          <mat-divider></mat-divider>
          <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <div class="d-flex justify-content-between items-center">
              <h2 style="font-weight: bold;">Physical - {{reportType}} Report</h2>
              <h4 style="font-weight: bold;">{{currentDate | date: 'MM/dd/yy, H:mm:ss'}}</h4>
            </div>
            <mat-divider style="margin-bottom: 10px;"></mat-divider>
            <ag-grid-angular
            style="height: 50vh; width: 100%;"
            class="ag-theme-alpine"
            [rowData]="rowDataReport"
            [columnDefs]="columnDefsReport"
            [gridOptions]="gridOptionsReports"
            (firstDataRendered)="onFirstDataRendered($event)"
            (gridReady)="onGridReady($event)" [headerHeight]="headerHeight"
            [pagination]="true" [paginationPageSize]="10"
            >
            </ag-grid-angular>            
          </div>
        </div>
    </div>
</div>


<!--common attribute history modal template-->
<ng-template #displayCommonAttributeHistoryModal>    
    <div class="d-flex justify-content-between align-items-center">
        <h1 mat-dialog-title>Common Attributes</h1>
        <mat-form-field  appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing">     
            <mat-label class="placeholder-title">Search</mat-label>
            <input class="input-text" matInput (keyup)="applyFilter($event)">
        </mat-form-field>
        <button
          type="button"
          mat-mini-fab
          mat-button
          mat-dialog-close
          color="warn"
          title="close"
          (click)="dialog.closeAll()"
        >
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
    </div>
    <hr>
    <form [formGroup]="commonAttributesDataForm">
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-top-row">
          <div class="inputField">
            <nz-date-picker class="calender-input" id="picker"formControlName="startDate" [nzDisabledDate]="" 
            nzFormat="yyyy-MM-dd"></nz-date-picker>
            <span class="floating-text" style="margin-left: 10px;">Start Date</span>
          </div>

          <div class="inputField">
            <nz-date-picker class="calender-input" id="picker" formControlName="endDate" [nzDisabledDate]="" 
            nzFormat="yyyy-MM-dd"></nz-date-picker>
            <span class="floating-text" style="margin-left: 10px;">End Date</span>
          </div>

          <button type="button" mat-button mat-mini-fab color="primary" pTooltip="Search Data" 
              tooltipPosition="top" style="margin-left: 10px;" (click)="getCommonAttributes()">
              <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </form>
    <div class="wrapper-box wrapper-box-shadow table-wrapper" *ngIf="commonAttributesData !== undefined">
        <table mat-table [dataSource]="commonAttributesData" class="basic-table plant-model-table" matSort>
    
          <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
              <p class="header-text">ID</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
          </ng-container>
    
          <ng-container matColumnDef="Shift">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Shift</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.Shift}} </td>
          </ng-container>
          <ng-container matColumnDef="Crew">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Crew</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.Crew}} </td>
          </ng-container>
    
          <ng-container matColumnDef="Quality">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="grp-order">
              <p class="header-text">Quality</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.Quality}} </td>
          </ng-container>
          <ng-container matColumnDef="Standard">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
              <p class="header-text">Standard</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.Standard}} </td>
          </ng-container>
        <ng-container matColumnDef="Thickness">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text">Thickness</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.Thickness}} </td>
        </ng-container>
        <ng-container matColumnDef="GrossWidth">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-By">
            <p class="header-text">Gross Width</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.GrossWidth}}  </td>
        </ng-container>
          <ng-container matColumnDef="NetAvailable">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
              <p class="header-text">Net Available</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element.NetAvailable}} </td>
          </ng-container>
          <ng-container matColumnDef="NetCut">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
              <p class="header-text">Net Cut</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element.NetCut}} </td>
          </ng-container>
          <ng-container matColumnDef="CreatedOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
              <p class="header-text">Date</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element.CreatedOn | date: 'dd-MM-yyyy'}} </td>
          </ng-container>
    
          <!-- plantModelActions Column -->
          <ng-container matColumnDef="Actions" >
            <th mat-header-cell *matHeaderCellDef> <p class="header-text">Actions</p></th>
           
            <td  mat-cell *matCellDef="let element" class="row-action-btns-column">
              <div class="row-action-btns" style="min-width: 78px;">
                <button type="button" mat-icon-button class="edit-btn" 
                (click)="editCommonAttribute(element.Id)"
                aria-label="Edit" 
                pTooltip="Edit" tooltipPosition="top">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                      fill="white" />
                  </svg>
                </button>
                <button type="button" mat-icon-button class="node-control-btn node-delete" 
                aria-label="delete item"
                     pTooltip="Delete" (click)="deleteCommonAttribute(element.Id)" tooltipPosition="top">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" 
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                        fill="white" />
                    </svg>
                 </button>
              </div>
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="commonAttributesColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: commonAttributesColumns;"></tr>
        </table>  
        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[7, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</ng-template>


<ng-template #displayMaterialDataHistoryModal>    
    <div class="d-flex justify-content-between align-items-center">
        <h1 mat-dialog-title>Material Data</h1>
        <button
          type="button"
          mat-mini-fab
          mat-button
          mat-dialog-close
          color="warn"
          title="close"
          (click)="dialog.closeAll()"
        >
          <i class="fa fa-times" style="color: #fff"></i>
        </button>

      </div>
      <div style="overflow-x: scroll;">
        <table mat-table [dataSource]="dataSource" class="basic-table plant-model-table">              
          <!-- All Columns -->
          <ng-container [matColumnDef]="headerName" *ngFor="let headerName of headerData">
            <div *ngIf="headerName !== 'Actions'">
              <th mat-header-cell *matHeaderCellDef><p class="header-text">{{headerName}}</p></th>
              <td mat-cell *matCellDef="let element">{{element[headerName]}}</td>
            </div>
            <div *ngIf="headerName === 'Actions'">
              <th mat-header-cell *matHeaderCellDef>
                <p class="header-text">Actions</p>
              </th>
              <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                <div class="row-action-btns" style="min-width: 80px;">
                  <button mat-icon-button class="edit-btn" aria-label="Edit Row"   (click)="editTableEntry(element)" pTooltip="Edit" tooltipPosition="top">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                        fill="white" />
                    </svg>
                  </button>
                  <button mat-icon-button class="tree-view-btn" aria-label="View Row Tree" (click)="deleteTableEntry(element)" pTooltip="Delete" tooltipPosition="top" pTooltip="Delete" tooltipPosition="top">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                        fill="#F85439" />
                      <circle cx="17" cy="17" r="14" fill="#F85439" />
                      <path
                        d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                        fill="white" />
                    </svg>
                  </button>
                </div>
              </td>
            </div>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headerData"></tr>
          <tr mat-row *matRowDef="let row; columns: headerData;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching</td>
          </tr>
        </table>
      </div>
      <mat-paginator
        class="basic-paginataor"
        [pageSizeOptions]="[7, 10, 20]"
        showFirstLastButtons>
      </mat-paginator>
</ng-template>
<ng-template #displayPDFExportModal>    
    <div class="d-flex justify-content-between align-items-center">
        <h1 mat-dialog-title>Print Physical QA Report To PDF </h1>
        <button
          type="button"
          mat-mini-fab
          mat-button
          mat-dialog-close
          color="warn"
          title="close"
          (click)="dialog.closeAll()"
        >
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
      <app-pdf-export-panel
      [agGridApi]="gridApi"
      [agColumnApi]="gridColumnApi"
    ></app-pdf-export-panel>
      
</ng-template>


