import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { TdcElementService } from 'src/app/services/tdcElement.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import Swal from 'sweetalert2';
import { TranslaterService } from 'src/app/services/translater.service';

@Component({
  selector: 'app-tdcelement',
  templateUrl: './tdcelement.component.html',
  styleUrls: ['./tdcelement.component.scss'],
})
export class TdcelementComponent implements OnInit {
  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('displayTDCElementModal')
  private displayTDCElementModal!: TemplateRef<any>;
  costModelVisible = false;
  TDCformMode = true;
  TDCDetailformMode = true;
  costForm!: FormGroup;
  addTDCForm!: FormGroup;
  TDCDetailForm!: FormGroup;
  plantId!: number;
  groupId!: number;
  plantsDDL!: any[];
  parentsDDL!: any[];
  costCenterId!: number;
  costElementDataSource!: MatTableDataSource<any>;
  costColumns: any[] = ['costActions', 'name', 'costCenter',];
  TDCDetailDataSource!: MatTableDataSource<any>;
  tdcColumns: any[] = ['tdcActions', 'tdcValue', 'tdcBudget', 'date',];
  ptId!: any;
  costId!: any;
  pt!: any;
  tdcElementId!: any;
  detailId!: any;
  tdcElementDetailId!: any;
  costCenterArray: any[] = [];
  tdcElementArray: any[] = [];
  tempData: any[] = [];
  IsAccess:any;
  IsWrite:any;
  breadcrumList: any;
  moduleId: any;
  pageId: any;
  langObj: any;
  languageDir = 'ltr';

  constructor(
    private fb: FormBuilder,
    private tdcService: TdcElementService,
    private toaster: ToastrService,
    private userManagementService: UserManagementService,
    private platModelService: EnterPriseGroupService,
    private dialog: MatDialog,
    private translater: TranslaterService,
  ) {
  }

  ngOnInit(): void {
    this.addTDCForm = this.fb.group({
      tdcelement: [''],
      costCenterId: [''],
      date: ['']
    });
    this.TDCDetailForm = this.fb.group({
      tdcvalue: ['', Validators.required],
      tdcBudget: ['', Validators.required],
      date: ['', Validators.required],
    });
    this.GetMenuAccess();
    this.GetBreadcrumList();
    // this.GetAllTDCElements(1);
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        console.log(data.BreadcrumList);
        // this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
      // localStorage.setItem('GetMenuAccess', this.permisson);
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetAllTDCElements(key: any): void {
    this.tdcService.GetAllTDCElements(key).subscribe((data: any) => {
      this.costElementDataSource = new MatTableDataSource<any>(data);
      // this.costElementDataSource.paginator = this.paginator;
      this.getPaginator(this.costElementDataSource, 0);
      this.tdcElementArray = data;

      this.plantId = key.plantId;
      this.getCenterGroupList(this.plantId);
    });
  }
  getCenterGroupList(k: any): void {
    const key = {
      plantId: k
      // plantId: k.plantId,
    };
    this.tdcService.GetcostGroupList(key).subscribe(
      (data: any) => {
        this.parentsDDL = data;
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  costCenters(event: any): void {
    this.costCenterArray = event.value;
    this.costCenterArray.join(',');
  }

  AddTDCElement(): void {
    const key = {
      tdcElementName: this.addTDCForm.value.tdcelement,
      costCenterId: this.costCenterArray, // this.addTDCForm.value.glaccount,
      pt: this.plantId,
    };
    // const costData = this.costElementDataSource.filteredData;
    // const cost = costData.filter(
    //   (c: any) => c.name === this.addTDCForm.value.tdcelement
    // );
    // if (cost.length > 0) {
    //   this.toaster.error('Error', 'Cost Element Name Already Exist');
    //   return;
    // }
    this.tdcService.AddTDCElement(key).subscribe((res: any) => {
      this.toaster.success('success', 'Element is added successfully...');
      this.GetAllTDCElements({ plantId: this.plantId });
    });
    this.TDCformMode = true;
    this.addTDCForm.reset();
  }

  EditTDCElement(element: any): void {
    this.TDCformMode = false;
    this.addTDCForm.get('tdcelement')?.setValue(element.tdcElementName);
    const array: any[] = [];
    array.push(element);
    const ele = this.tdcElementArray.find(x => x.costCenterName === array[0].costCenterName).costCenterId;
    const split = ele.split(',').map(Number);
    this.addTDCForm.get('costCenterId')?.setValue(split);
    this.costCenterId = element.costCenterId;
    this.costId = element.costId;
    this.pt = element.plantId;
    this.tdcElementId = element.tdcElementId;
  }

  UpdateTDCElement(): void {
    const key = {
      tdcElementId: this.tdcElementId,
      // pt: this.pt,
      tdcElementName: this.addTDCForm.value.tdcelement,
      costCenterId: this.costCenterArray // this.addTDCForm.value.glaccount,
      // costId: this.costId
    };
    // const costData = this.costElementDataSource.filteredData;
    // const cost = costData.filter(
    //   (c: any) =>
    //     // c.name === this.addCostForm.value.name &&
    //     c.costId !== this.costId
    // );
    // if (cost.length > 0) {
    //   this.toaster.error('Error', 'Cost Element Name Already Exist');
    //   return;
    // }
    this.tdcService.UpdateTDCElement(key).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'Cost Element Updated Successfully');
        this.GetAllTDCElements({ plantId: this.pt });
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
    this.TDCformMode = true;
    this.addTDCForm.reset();
  }

  DeletedTDCElement(element: any): void {
    Swal.fire({
      title: 'Please make sure!',
      text: 'Are you want to delete TDC element ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          tdcElementId: element.tdcElementId,
        };
        this.tdcService.DeletedTDCElement(key).subscribe((res: any) => {
          Swal.fire({
            title: 'Deleted!',
            text: 'Your selected TDC element has been deleted.',
            icon: 'success',
            timer: 800,
            showConfirmButton: false,
          });
          this.GetAllTDCElements({ plantId: this.plantId });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your TDC element is safe :)', 'error');
      }
    });
  }

  TDCElementDetailPopup(element: any): void {
    this.TDCDetailForm.reset();
    this.detailId = element.tdcElementId;
    this.dialog.open(this.displayTDCElementModal, {
      height: '90%',
      width: '80%',
      disableClose: true,
    });
    this.GetAllTDCElementDetail();
  }

  GetAllTDCElementDetail(): void {
    const key = {
      tdcElementId: this.detailId,
    };
    this.tdcService.GetAllTDCElementDetail(key).subscribe((res: any) => {
      this.TDCDetailDataSource = new MatTableDataSource<any>(res);
      this.getPaginator(this.TDCDetailDataSource, 1);
    });
  }

  AddTDCElementDetail(): void {
    const formMonth = new Date(this.TDCDetailForm.value.date);
    const selectDate =
      formMonth.getFullYear() + '-' + (formMonth.getMonth() + 1);
    const tableMonth = this.TDCDetailDataSource.filteredData.findIndex((x) => {
      return (
        new Date(x.date).getFullYear() +
          '-' +
          (new Date(x.date).getMonth() + 1) ===
        selectDate
      );
    });
    if (tableMonth !== -1) {
      this.toaster.error(
        'Error',
        'You cannot add duplicate entry in same month'
      );
    } else {
      const key = {
        tdcElementId: this.detailId,
        tdcValue: this.TDCDetailForm.value.tdcvalue,
        tdcBudget: this.TDCDetailForm.value.tdcBudget,
        date: this.TDCDetailForm.value.date,
      };
      this.tdcService.AddTDCElementDetail(key).subscribe((res: any) => {
        this.toaster.success(
          'Success',
          'TDC Element Detail is successfully...'
        );
        this.GetAllTDCElementDetail();
        this.TDCDetailForm.reset();
      });
    }
  }

  EditTDCElementDetail(element: any): void {
    this.TDCDetailformMode = false;
    (this.tdcElementDetailId = element.tdcElementDetailId),
      this.TDCDetailForm.get('tdcvalue')?.setValue(element.tdcValue);
    this.TDCDetailForm.get('tdcBudget')?.setValue(element.tdcBudget);
    this.TDCDetailForm.get('date')?.setValue(element.date);
  }

  UpdateTDCElementDetail(): void {
    const key = {
      tdcElementDetailId: this.tdcElementDetailId,
      tdcValue: this.TDCDetailForm.value.tdcvalue,
      tdcBudget: this.TDCDetailForm.value.tdcBudget,
      date: this.TDCDetailForm.value.date,
    };
    this.tdcService.UpdateTDCElementDetail(key).subscribe((res: any) => {
      this.toaster.success(
        'Success',
        'TDC Element Detail is updated successfully...'
      );
      this.GetAllTDCElementDetail();
      this.TDCDetailForm.reset();
      this.TDCDetailformMode = true;
    });
  }

  DeleteTDCElementDetail(element: any): void {
    Swal.fire({
      title: 'Please make sure!',
      text: 'Are you want to delete TDC element detail ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          tdcElementDetailId: element.tdcElementDetailId,
        };
        this.tdcService.DeleteTDCElementDetail(key).subscribe((res: any) => {
          Swal.fire({
            title: 'Deleted!',
            text: 'Your selected TDC element detail has been deleted.',
            icon: 'success',
            timer: 800,
            showConfirmButton: false,
          });
          this.GetAllTDCElementDetail();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your TDC element detail is safe :)', 'error');
      }
    });
  }


  exportAsXLSX(): void {
    if (
      this.plantId !== null &&
      this.addTDCForm.value.tdcelement !== null &&
      this.addTDCForm.value.date !== ''
    ) {
      this.tempData = [];
      for (let i = 0; i < this.tdcElementArray.length; i++) {
        this.tempData[i] = {
          plantId: this.tdcElementArray[i].plantId.ptId,
          tdcElementName: this.tdcElementArray[i].tdcElementName,
          date: moment(this.addTDCForm.value.date).format('DD-MM-YYYY ss:mm:HH'),
          tdcValue: '',
          tdcBudget: ''
        };
      }
      this.tdcService.exportAsExcelFile(this.tempData, 'TDCElementTemplate');
    } else {
      this.toaster.error('Error', 'Please select date');
    }
  }

  applyFilter(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.costElementDataSource.filter = filterValue.trim().toLowerCase();
  }

  RefreshTDCElement(): void {
    this.addTDCForm.reset();
    this.TDCformMode = true;
  }

  RefreshTDCElementDetail(): void {
    this.TDCDetailForm.reset();
    this.TDCDetailformMode = true;
  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
    }, 1000);
  }
}
