<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>

<div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
  </div>
  
<div class="tab-content-top-box">
  <h1 class="main-title">Technision History</h1>
</div>

<div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
  <div class="table-div">
      <table mat-table class="basic-table plant-model-table" matSort [dataSource]="technicianDataSource">

          <ng-container matColumnDef="S.No">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <p class="header-text">S.NO</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
          </ng-container>

          <ng-container matColumnDef="Start Time">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <p class="header-text">Start Time</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.StartTime | date:'MMM d, y, h:mm:ss a'}}</td>
          </ng-container>

          <ng-container matColumnDef="Complete Time">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <p class="header-text">Complete Time</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.FinishTime | date:'MMM d, y, h:mm:ss a'}}</td>
          </ng-container>

          <ng-container matColumnDef="CreatedAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <p class="header-text">CreatedAt</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.CreatedAt | date:'MMM d, y, h:mm:ss a'}}</td>
          </ng-container>

          <ng-container matColumnDef="CreatedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <p class="header-text">CreatedBy</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.CreatedBy}}</td>
          </ng-container>

          <ng-container matColumnDef="Picture">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Picture</p>
            </th>
            <td mat-cell *matCellDef="let element">
                <img [src]="element.AfterPicture" class="img">
            </td>
        </ng-container>

          <tr mat-header-row *matHeaderRowDef="technicianColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: technicianColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching</td>
          </tr>
      </table>
  </div>

</div>