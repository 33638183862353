<ul class="breadcrumb">
  <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
    <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
      (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
  </li>
</ul>


<div class="split-view-container" [class.fullscreen]="isExpanded">
  <div class="toggle-buttons">
    <button *ngIf="!isExpanded" mat-mini-fab mat-button mat-dialog-close color="accent" pTooltip="Expand Grid"
      tooltipPosition="top" (click)="toggleView()" style="margin-bottom: 4px">
      <mat-icon>fullscreen</mat-icon>
    </button>
    <button mat-mini-fab mat-button mat-dialog-close color="accent" pTooltip="Collapse Grid" tooltipPosition="top"
      (click)="toggleView()" *ngIf="isExpanded" style="margin-bottom: 4px">
      <mat-icon>fullscreen_exit</mat-icon>
    </button>
    <button mat-mini-fab mat-button mat-dialog-close color="accent" pTooltip="Vertical View" tooltipPosition="top"
      (click)="rotateView()" *ngIf="!isRotate && grids.length<=2" style="margin-bottom: 4px">
      <mat-icon>sync</mat-icon>
    </button>
    <button mat-mini-fab mat-button mat-dialog-close color="accent" pTooltip="Split View" tooltipPosition="top"
      (click)="rotateView()" *ngIf="isRotate && grids.length<=2" style="margin-bottom: 4px">
      <mat-icon>autorenew</mat-icon>
    </button>
    <button mat-mini-fab mat-button mat-dialog-close color="primary" pTooltip="Add Grid" tooltipPosition="top"
      (click)="onAddGrid()" style="margin-bottom: 4px">
      <mat-icon>add</mat-icon>
    </button>

  </div>
  <div class="splitter" [ngClass]="isRotate ? 'flex-column': grids.length>2 ? 'flex-column':'flex-row'">
    <ng-container *ngFor="let grid of grids; let i =index">
      <section [ngClass]="isRotate ? 'w-100 h-72' :  grids.length>2 ?  'w-100 h-72' :'w-50'" class="grid">
        <button id="x" (click)="onDeleteGrid(i)">
          X
        </button>
        <app-kpitreedefination></app-kpitreedefination>
      </section>
      <div style="margin:5px;"></div>
    </ng-container>
    <!-- <section [ngClass]="isRotate ? 'w-100 h-72' : 'w-50'">
      <app-kpitreedefination></app-kpitreedefination>
    </section>
    <div style="margin:5px;"></div>
    <section [ngClass]="isRotate ? 'w-100 h-72' : 'w-50'">
      <app-kpitreedefination></app-kpitreedefination>
    </section> -->
  </div>
</div>