import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DTRoutingModule } from './dt-routing.module';
import { MaterialModule } from 'src/app/material-module';
import { DtConfigurationComponent } from './dt-configuration/dt-configuration.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { TooltipModule } from 'primeng/tooltip';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        DTRoutingModule,
        MatMenuModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatTreeModule,
        MatCardModule,
        MatSelectModule,
        MatNativeDateModule,       
        MatDatepickerModule,
        MatDialogModule,
        NgxMaterialTimepickerModule,        
        MaterialModule,
        MatExpansionModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        FormsModule,       
        NgxSpinnerModule,
        ReactiveFormsModule,
        TooltipModule,
        MatTooltipModule
    
    ],
    declarations: [
        DtConfigurationComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DTModule { }
