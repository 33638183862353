import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { prevTaskService } from 'src/app/services/prev-task.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MatDialog } from '@angular/material/dialog';
import { TechnicianService } from '../../../services/technician.service';

import { PrevTaskDialogComponent } from '../prev-task-dialog/prev-task-dialog.component';

@Component({
  selector: 'app-prev-task-form',
  templateUrl: './prev-task-form.component.html',
  styleUrls: ['./prev-task-form.component.scss']
})
export class PrevTaskFormComponent implements OnInit {

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('technicianimageModal') private technicianimageModal!: TemplateRef<any>;
  breadcrumList: any;
  edited: boolean = false;
  preTaskForm: any;
  user: any;
  blobStorageUrl: string = "";
  spinnerText: string = "";

  preTask: any = {
    MachineName: '',
    TaskName: '',
    FrequencyName: '',
    TaskDesc: '',
    Duration: '',
    Status: '',
    StartTime: new Date(),
    FinishTime: '',
    Remarks: '',
    ItemCode: '',
    ItemName: '',
    ItemDesc: '',
    MeterReading: '',
    SparePartsUsed: '',
    WorkLog: '',
    UploadPicture: [],
    CreatedAt: '',
    CreatedBy: 0,
    ID: 0
  }

  EditItemDetails = {
    ItemName: '',
    ItemCode: '',
    ItemDesc: '',
  };
  TaskFiles: any = [];
  toggleChecked: boolean = false;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild("toggleElement") ref: any;

  imageSrc = new Array<string>();
  fileType: any;
  FileData: any = [];
  FileList: any = [];
  EditFileData: any = [];
  isviewMode: any;

  constructor(
    private userManagementService: UserManagementService, 
    private toaster: ToastrService, 
    private fb: FormBuilder, 
    private router: Router,  
    private spinner: NgxSpinnerService, 
    private prevTaskService: prevTaskService,
    public dialog: MatDialog, 
    private technicianService: TechnicianService) {

    this.preTaskForm = this.fb.group({
      MachineName: [{ value: '', disabled: true }],
      TaskName: [{ value: '', disabled: true }],
      FrequencyName: [{ value: '', disabled: true }],
      TaskDesc: [{ value: '', disabled: true }],
      Duration: [{ value: '', disabled: true }],
      status: [{ value: '', disabled: true }, Validators.required],
      startTime: [{ value: '' }, Validators.required],
      finishTime: [''],
      remarks: [''],
      itemname: [''],
      itemcode: [''],
      itemdesc: [''],
      meterreading: [''],
      sparepartsused: [''],
      worklog: [''],
    });
  }

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.blobStorageUrl = environment.O3CoreApiImageUrl;
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.getTechnicianTaskValues();
  }

  imgview(data:any){
    let dialogRef = this.dialog.open(this.technicianimageModal, {
      height: '90%',
      width: '90%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  oncloseimg(){
    this.dialog.closeAll();
  }

  getTechnicianTaskValues() {
    var data = this.technicianService.getTechnicianTaskValues();
    if (data) {
      this.edited = true;
      this.isviewMode =data.isviewButton
      let TaskUploadFiles: any = [];
      let TaskUploadFilesType: any = [];
      let UploadFiles: any = [];
      this.preTask.ID = data.ID;
      this.preTask.StartTime = data.StartTime ? data.StartTime : new Date();
      this.preTask.FinishTime = data.FinishTime;
      this.preTask.Remarks = data.Remarks;
      this.preTask.ItemCode = data.ItemCode;
      this.preTask.ItemName = data.ItemName;
      this.preTask.ItemDesc = data.ItemDesc;
      this.preTask.Status = data.Status;
      // this.preTask.MachineName = data.Task.units.puDesc;
      this.preTask.TaskName = data.Task.WOType.Name;
      this.preTask.FrequencyName = data.Task.Frequency.Name;
      this.preTask.TaskDesc = data.Task.Description;
      this.preTask.Duration = data.Task.Duration;

      this.preTask.MeterReading = data.MeterReading;
      this.preTask.SparePartsUsed = data.SparePartsUsed;
      this.preTask.WorkLog = data.WorkLog;

      this.preTask.MachineName = '';
      this.preTask.MachineName += data.Task.line ? data.Task.line.plDesc : '';
      if (data.Task.units)
        this.preTask.MachineName += '/' + data.Task.units.puDesc;
      if (data.Task.assembly) {
        this.preTask.MachineName += '/' + data.Task.assembly.assblyDesc;
      }
      if (data.Task.subAssembly) {
        this.preTask.MachineName += '/' + data.Task.subAssembly.sAsblyDesc;
      }
      if (data.Task.component) {
        this.preTask.MachineName += '/' + data.Task.component.componentName;
      }

      if (data.Task.UploadFile && data.Task.FileType) {
        TaskUploadFiles = data.Task.UploadFile.split(',');
        TaskUploadFilesType = data.Task.FileType.split(',');
        for (let i = 0; i < TaskUploadFiles.length; i++) {
          this.TaskFiles.push({ FileType: TaskUploadFilesType[i], ImageSrc: TaskUploadFiles[i] })
        }
      }

      if (data.UploadPicture) {
        UploadFiles = data.UploadPicture.split(',');
        for (let i = 0; i < UploadFiles.length; i++) {
          this.EditFileData.push({ ImageSrc: UploadFiles[i] })
        }
      }

      if (this.preTask.ItemName && this.preTask.ItemCode) {
        setTimeout(() => {
          this.ref.checked = true;
        }, 100);

      }
    }
    else
      this.router.navigateByUrl('/PM/technician');
  }

  openFile() {
    this.fileInput.nativeElement.click()
  }



  onToggleChange(event: any) {
    if (event.checked) {
      this.preTask.ItemName = this.EditItemDetails.ItemName;
      this.preTask.ItemCode = this.EditItemDetails.ItemCode;
      this.preTask.ItemDesc = this.EditItemDetails.ItemDesc;
      this.openDialog();
      this.preTaskForm = this.fb.group({
        status: [this.preTask.Status, Validators.required],
        startTime: [new Date(), Validators.required],
        finishTime: [this.preTask.FinishTime],
        remarks: [this.preTask.Remarks],
        itemname: [this.preTask.ItemName, Validators.required],
        itemcode: [this.preTask.ItemCode, Validators.required],
        itemdesc: [this.preTask.ItemDesc],
        meterreading: [this.preTask.MeterReading],
        sparepartsused: [this.preTask.SparePartsUsed],
        worklog: [this.preTask.WorkLog],
      });
    }
    else {
      this.EditItemDetails.ItemName = this.preTask.ItemName;
      this.EditItemDetails.ItemCode = this.preTask.ItemCode;
      this.EditItemDetails.ItemDesc = this.preTask.ItemDesc;
      this.preTask.ItemName = '';
      this.preTask.ItemCode = '';
      this.preTask.ItemDesc = '';
      this.preTaskForm = this.fb.group({
        status: [this.preTask.Status, Validators.required],
        startTime: [new Date(), Validators.required],
        finishTime: [this.preTask.FinishTime],
        remarks: [this.preTask.Remarks],
        itemname: [this.preTask.ItemName],
        itemcode: [this.preTask.ItemCode],
        itemdesc: [this.preTask.ItemDesc],
        meterreading: [this.preTask.MeterReading],
        sparepartsused: [this.preTask.SparePartsUsed],
        worklog: [this.preTask.WorkLog],
      });
    }

  }

  openDialog(): void {
    let dialogRef = this.dialog.open(PrevTaskDialogComponent, {
      disableClose: true,
      width: '500px',
      data: { ItemName: this.preTask.ItemName, ItemCode: this.preTask.ItemCode, ItemDesc: this.preTask.ItemDesc }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.ref.checked = false;
        this.preTask.ItemName = '';
        this.preTask.ItemCode = '';
        this.preTask.ItemDesc = '';
        this.preTaskForm = this.fb.group({
          status: [this.preTask.Status, Validators.required],
          startTime: [new Date(), Validators.required],
          finishTime: [this.preTask.FinishTime],
          remarks: [this.preTask.Remarks],
          itemname: [this.preTask.ItemName],
          itemcode: [this.preTask.ItemCode],
          itemdesc: [this.preTask.ItemDesc],
          meterreading: [this.preTask.MeterReading],
          sparepartsused: [this.preTask.SparePartsUsed],
          worklog: [this.preTask.WorkLog],
        });
      }
      else {
        this.preTask.ItemName = result.ItemName;
        this.preTask.ItemCode = result.ItemCode;
        this.preTask.ItemDesc = result.ItemDesc;

        this.preTaskForm = this.fb.group({
          status: [this.preTask.Status, Validators.required],
          startTime: [new Date(), Validators.required],
          finishTime: [this.preTask.FinishTime],
          remarks: [this.preTask.Remarks],
          itemname: [this.preTask.ItemName, Validators.required],
          itemcode: [this.preTask.ItemCode, Validators.required],
          itemdesc: [this.preTask.ItemDesc],
          meterreading: [this.preTask.MeterReading],
          sparepartsused: [this.preTask.SparePartsUsed],
          worklog: [this.preTask.WorkLog],
        });
      }

    });
  }

  readFile(event: any) {
    const files = event.target.files;
    if (files) {
      for (let file of files) {
        if (file.type.match('image.*')) {
          this.fileType = 'image';
          this.FileList.push(file);
        }
        else {
          this.toaster.warning('Warning', 'File Format Not Valid ');
          return;
        }

        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageSrc = e.target.result;
          this.FileData.push({ FileType: this.fileType, ImageSrc: this.imageSrc })
        }
        reader.readAsDataURL(file);
      }
    }
  }

  removeFile(i: any) {
    this.FileData.splice(i, 1);
    this.FileList.splice(i, 1);
  }

  deleteFile(i: any) {
    this.EditFileData.splice(i, 1);
    this.preTask.UploadPicture.push(i);
  }

  OnSubmit() {
    if (this.preTaskForm.invalid) {
      this.toaster.warning('Warning', 'Please Fill All Required Fields');
      return;
    }
    else {
      this.preTask.CreatedAt = new Date();
      this.preTask.CreatedBy = this.user.UserId;
      this.preTask.TaskBy = this.user.UserId;

      if (!this.preTask.StartTime)
        delete this.preTask.StartTime
      if (!this.preTask.FinishTime)
        delete this.preTask.FinishTime

      delete this.preTask.MachineName;
      delete this.preTask.TaskName;
      delete this.preTask.FrequencyName;
      delete this.preTask.TaskDesc;
      delete this.preTask.Duration;
      this.preTask.Status = this.preTask.Status == 'Done' ? 1 : 0;
      if (!this.edited) {
        const Formvalues = new FormData();
        for (const file of this.FileList)
          Formvalues.append('Image', file, file.name);
        Formvalues.append('Data', JSON.stringify(this.preTask));
        this.spinner.show();
        this.prevTaskService.addPrevTask(Formvalues).subscribe((res: any) => {
          this.spinner.hide();
          this.edited = false;
          if (res.status) {
            this.toaster.success('Date Added Successfully', res.message);
            this.router.navigateByUrl('/PM/technician');
          }
        },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          });
      } else {
        delete this.preTask.TaskUploadFile;
        delete this.preTask.TaskUploadFileType;
        const Formvalues = new FormData();
        for (const file of this.FileList)
          Formvalues.append('Image', file, file.name);
        Formvalues.append('Data', JSON.stringify(this.preTask));
        this.spinner.show();
        this.prevTaskService.updatePrevTask(Formvalues).subscribe((res: any) => {
          this.spinner.hide();
          this.edited = false;
          if (res.status) {
            this.preTask.UploadPicture = [];
            this.toaster.success('Date Updated Successfully', res.message);
            this.router.navigateByUrl('/PM/technician');
          }
        },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          });
      }
    }
  }

  GetBreadcrumList(): any {
    const obj = { MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId') };
    this.spinnerText = 'Loading Menu Data';
    this.spinner.show();
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.spinner.hide();
      this.breadcrumList = data.BreadcrumList;
    }, (error: any) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }
  dateChanged(val){
    if(val=='finishTime'){
      if (new Date(this.preTask.FinishTime) < new Date(this.preTask.StartTime)) {
        this.toaster.warning('Finish Time Should Not Be Prior To Start Time', 'Warning', {
          timeOut: 3000,
        });
        this.preTask.FinishTime='';
        this.preTaskForm.patchValue({finishTime:null}) 
        return;
      }      
  }
}
}

