<div dir="{{languageDir}}">
<div class="content-top-box">
    <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
    </ul>
    <div class="tab-content-top-box">
        <div class="p-col-12 p-md-6 p-lg-7 p-xl-7">
            <mat-card-title>{{langObj ? langObj?.MeetingSaturation : ('MeetingSaturation' | translate)}}</mat-card-title>
        </div>
            <form [formGroup]="searchSaturationForm" >
                <div class="content-top-row">
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-label class="required">{{langObj ? langObj?.Group : ('Group' | translate)}}</mat-label>
                    <mat-select panelClass="testClass" name="group" formControlName="group" (selectionChange)="getPlantByGroup()">
                        <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groupListDDL"
                        (filteredReturn)="filteredGroupListDDL = $event"></mat-select-filter>
                        <mat-option *ngFor="let g of filteredGroupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-label class="required">{{langObj ? langObj?.Plant : ('Plant' | translate)}}</mat-label>
                    <mat-select panelClass="testClass" name="plant" formControlName="plant" (selectionChange)="onPlantChange()">
                        <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plants"
                        (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                        <mat-option *ngFor="let p of filteredPlants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="inputField">
                    <nz-date-picker (ngModelChange)="onPlantChange()" class="calender-input"  id="startPicker" formControlName="startTime" nzFormat="dd/MM/yyyy"
                    [nzInputReadOnly]="true" ></nz-date-picker>
                    <span class="floating-text">{{langObj ? langObj?.StartTime : ('StartTime' | translate)}}</span>   
                  </div>
                  <div class="inputField">
                    <nz-date-picker (ngModelChange)="onPlantChange()" class="calender-input"  id="endPicker" formControlName="endTime" nzFormat="dd/MM/yyyy"
                   [nzInputReadOnly]="true" ></nz-date-picker>
                    <span class="floating-text">{{langObj ? langObj?.EndTime : ('EndTime' | translate)}}</span>   
                  </div>
                </div>
            </form>
        </div>
</div>
<div class="p-grid p-fluid">
    <div class="p-col-12 p-md-6 p-lg-6 p-xl-6">
        <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [rowData]="rowData" (gridReady)="onGridReady($event)" [columnDefs]="columnDefs" [gridOptions]="gridOptions" [rowSelection]="rowSelection" (selectionChanged)="onSelectionChanged($event)" [pagination]="true">
        </ag-grid-angular>
    </div>
    <div class="p-col-12 p-md-6 p-lg-6 p-xl-6">
        <div id="container"></div>
    </div>
</div>
<div class="chart-group">
    <div class="chart-items" style="width: 100%;">
        <div [chart]="meetingsData"></div>
        <!-- <highcharts-chart [chart]="dateChart" ></highcharts-chart> -->
    </div>
</div>
<div class="chart-group">
    <div class="chart-items" style="width: 100%;">
        <div [chart]="meetingDrillChart"></div>
        <!-- <highcharts-chart [chart]="dateChart" ></highcharts-chart> -->
    </div>
</div>
</div>