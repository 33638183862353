import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OpmsQualityService } from 'src/app/services/opms-quality.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-chemical-lab',
  templateUrl: './chemical-lab.component.html',
  styleUrls: ['./chemical-lab.component.scss'],
})
export class ChemicalLabComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private subscriptions = new Subscription();
  public breadcrumList: any;
  public bRedoxDetails!: any[];
  public chemicalCompDetails!: any[];
  public chemicalDataForm = new FormGroup({});
  public chemicalDetails!: any[];
  public chemicalLabForm!: FormGroup;
  public chemicalMaterialColumns!: any[];
  public editMode = false;
  public grainDetails!: any[];
  public grainDistDetails!: any[];
  public gRedoxDetails!: any[];
  public historyDataHeaders: any;
  public historyDataSource = new MatTableDataSource<any>([]);
  public isLoaded = false;
  public isUpdated = false;
  public matEntryDetails!: any[];
  public materialEntriesValues!: any[];
  public materialEntriesColumns!: any[];
  public materialList!: any[];
  public materialVal: any;
  public openReport = false;
  public oxideDetails!: any[];
  public paginationPageSize: any;
  public physicalDetails!: any[];
  public physicalInspectionDetails!: any[];
  public reportValues!: any[];
  public sampleDetails!: any[];
  public supplierDetails!: any[];
  public tcmDetails!: any[];
  public types!: any[];
  public updatedId: any;
  public rowSelection = 'single';
  public ButtonType!: any;
  public isReportSwitch = false;
  public reportData: any[] = [];
  public excelExportData: any[] = [];
  public reportType: any;
  public today: any;
  @ViewChild('chemicalHistoryModal')
  private chemicalHistoryModal!: TemplateRef<any>;
  @ViewChild('reportContent') reportContent!: HTMLElement;

  constructor(
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private opmsQualityService: OpmsQualityService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {}

  alignColumns(chemicalMaterialColumns: any[]): any {
    this.types = chemicalMaterialColumns.map((x) => x.Type);
    this.types = [...new Set(this.types)];
    this.supplierDetails = this.types.includes('SupplierDetails')
      ? this.chemicalMaterialColumns.filter(
          (v) => v.Type === 'SupplierDetails' && v.Parameter !== 'Date'
        )
      : [];
    this.oxideDetails = this.types.includes('Oxide Content')
      ? this.chemicalMaterialColumns.filter((v) => v.Type === 'Oxide Content')
      : [];
    this.sampleDetails = this.types.includes('Sample Details')
      ? this.chemicalMaterialColumns.filter((v) => v.Type === 'Sample Details')
      : [];
    this.gRedoxDetails = this.types.includes('Glass Redox Calculation')
      ? this.chemicalMaterialColumns.filter(
          (v) => v.Type === 'Glass Redox Calculation'
        )
      : [];
    this.bRedoxDetails = this.types.includes('Batch Redox')
      ? this.chemicalMaterialColumns.filter((v) => v.Type === 'Batch Redox')
      : [];
    this.tcmDetails = this.types.includes('Tin Count Measurment "CPS"')
      ? this.chemicalMaterialColumns.filter(
          (v) => v.Type === 'Tin Count Measurment "CPS"'
        )
      : [];
    this.matEntryDetails = this.types.includes('MaterialEntry')
      ? this.chemicalMaterialColumns.filter((v) => v.Type === 'MaterialEntry')
      : [];
    this.grainDetails = this.types.includes('Grain Size Analysis')
      ? this.chemicalMaterialColumns.filter(
          (v) => v.Type === 'Grain Size Analysis'
        )
      : [];
    this.physicalDetails = this.types.includes('Physical Analysis')
      ? this.chemicalMaterialColumns.filter(
          (v) => v.Type === 'Physical Analysis'
        )
      : [];
    this.chemicalDetails = this.types.includes('Chemical Analysis')
      ? this.chemicalMaterialColumns.filter(
          (v) => v.Type === 'Chemical Analysis'
        )
      : [];
    this.physicalInspectionDetails = this.types.includes('Physical Inspection')
      ? this.chemicalMaterialColumns.filter(
          (v) => v.Type === 'Physical Inspection'
        )
      : [];
    this.chemicalCompDetails = this.types.includes('Chemical Composition')
      ? this.chemicalMaterialColumns.filter(
          (v) => v.Type === 'Chemical Composition'
        )
      : [];
    this.grainDistDetails = this.types.includes('Grain Size Distribution')
      ? this.chemicalMaterialColumns.filter(
          (v) => v.Type === 'Grain Size Distribution'
        )
      : [];
  }

  closeModal(): void {
    this.dialog.closeAll();
  }

  deleteMaterialData(elementData: any): any {
    const Id = elementData.Id;
    this.spinner.show();
    const key = {
      MaterialId: this.materialVal,
      MaterialEntryId: Id,
    };
    const deleteSub = this.opmsQualityService
      .deleteChemicalData(key)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (response: any) => {
          this.toaster.success('Data Deleted Successfully', response.message);
          this.dialog.openDialogs.length
            ? this.getMaterialEnteries(true)
            : this.getMaterialEnteries(false);
          this.resetForm();
        },
        (error) => {
          this.toaster.error('Error', error.message);
        }
      );
    this.subscriptions.add(deleteSub);
  }

  emptyAllColumnArrays(): any {
    this.supplierDetails = [];
    this.oxideDetails = [];
    this.sampleDetails = [];
    this.gRedoxDetails = [];
    this.bRedoxDetails = [];
    this.tcmDetails = [];
    this.matEntryDetails = [];
    this.grainDetails = [];
    this.chemicalDetails = [];
    this.physicalDetails = [];
    this.physicalInspectionDetails = [];
    this.chemicalCompDetails = [];
    this.grainDistDetails = [];
  }

  displayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  getBreadcrumList(): any {
    this.spinner.show();
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    const breadCrumSub = this.usermanagementService
      .GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(breadCrumSub);
  }

  getChemicalMaterialProperties(): void {
    this.spinner.show();
    this.isLoaded = true;
    this.isReportSwitch = false;
    const data = {
      MaterialId: this.materialVal,
    };
    const matProperties = this.opmsQualityService
      .getChemicalMaterialProperties(data)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (res: any) => {
          this.chemicalMaterialColumns = res.MaterialPropertyList;
          this.makeChemicalDataForm(this.chemicalMaterialColumns);
          this.alignColumns(this.chemicalMaterialColumns);
          this.getMaterialEnteries(false);
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(matProperties);
  }

  getMaterialEnteries(isModalOpen: any): any {
    this.spinner.show();
    let data;
    if (isModalOpen) {
      data = {
        MaterialId: this.materialVal,
        TransactionType: 2,
        DateValue: this.chemicalLabForm.value.dateTime,
      };
    } else {
      data = {
        MaterialId: this.materialVal,
        TransactionType: 1,
        DateValue: this.chemicalLabForm.value.dateTime,
      };
    }
    const materialEntriesSub = this.opmsQualityService
      .GetMaterialEntries(data)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (res: any) => {
          this.materialEntriesValues = res;
          this.materialEntriesColumns = res.Headers;
          this.historyDataHeaders = this.materialEntriesColumns;
          this.chemicalDataForm.controls.Date?.setValue(
            this.chemicalLabForm.value.dateTime
          );
          const dataArray = res?.DataList;
          const historyTableData = dataArray.map((row: any[]) => {
            return row.reduce((result: any, field: any, index: number) => {
              result[this.historyDataHeaders[index]] = field;
              return result;
            }, {});
          });
          this.historyDataSource = new MatTableDataSource(historyTableData);
          this.getPaginator();
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(materialEntriesSub);
  }

  getMaterialList(): any {
    this.spinner.show();
    const data = {};
    const materialSub = this.opmsQualityService
      .getMaterialsForChemical(data)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (res: any) => {
          this.materialList = res.MaterialList;
          this.chemicalLabForm.controls.materials.setValue(
            this.materialList[0].TypeId
          );
          this.materialVal = this.materialList[0].TypeId;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(materialSub);
  }

  getPaginator(): void {
    setTimeout(() => {
      this.historyDataSource.paginator = this.paginator;
      this.historyDataSource.sort = this.sort;
    }, 1000);
  }

  getSelectedMaterialDataForEdit(elementData: any): any {
    this.isUpdated = true;
    const elementArray = elementData;
    this.updatedId = elementArray.Id;
    let i = 0;
    for (const key in elementArray) {
      if (Object.prototype.hasOwnProperty.call(elementArray, key)) {
        if (
          key.split(' |')[0] === 'Entry Date' ||
          key.split(' |')[0] === 'Date'
        ) {
          this.chemicalDataForm.get('Date')?.setValue(elementArray[key]);
        } else if (key.split(' |')[0] === 'Location') {
          this.chemicalDataForm
            .get(key.split(' |')[0] + '-' + i)
            ?.setValue(elementArray[key]);
          i++;
        } else {
          this.chemicalDataForm.controls[key.split(' |')[0]]?.setValue(
            elementArray[key]
          );
        }
      }
    }
    // tslint:disable-next-line:no-unused-expression
    this.dialog.openDialogs.length ? this.closeModal() : '';
  }

  makeChemicalDataForm(chemicalMaterialColumns: any[]): any {
    this.resetForm();
    const dateFilter = chemicalMaterialColumns.filter(
      (val) => val.Parameter === 'Date'
    );
    let newChemicalMaterialColumns: any = chemicalMaterialColumns;
    newChemicalMaterialColumns = dateFilter
      ? chemicalMaterialColumns
      : [
          {
            Id: 0,
            Parameter: 'Date',
            Target: null,
            Type: 'MaterialEntry',
          },
        ].concat(newChemicalMaterialColumns);
    let i = 0;
    for (const val of newChemicalMaterialColumns) {
      if (val.Parameter === 'Location') {
        this.chemicalDataForm.addControl(
          val.Parameter + '-' + i,
          new FormControl('')
        );
        i++;
      } else {
        this.chemicalDataForm.addControl(val.Parameter, new FormControl(''));
      }
    }
    this.chemicalDataForm.controls.Date?.setValue(
      this.chemicalLabForm.value.dateTime
    );
    this.chemicalMaterialColumns = newChemicalMaterialColumns;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.chemicalLabForm = this.fb.group({
      dateTime: [
        this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm'),
        Validators.required,
      ],
      materials: ['', Validators.required],
    });
    this.getBreadcrumList();
    this.getMaterialList();
  }

  openHistoryModal(): void {
    this.getMaterialEnteries(true);
    const dialogRef = this.dialog.open(this.chemicalHistoryModal, {
      height: '80%',
      width: '90%',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getMaterialEnteries(false);
    });
  }

  resetForm(): void {
    this.chemicalDataForm.reset();
    // this.getMaterialEnteries(false);
    this.isUpdated = false;
  }

  saveForm(): any {
    this.spinner.show();
    const data: any[] = [];
    const forNcr: any[] = [];
    let createNCR = false;
    let ncrFieldsname = [];
    let obj;
    const formValueObj = this.chemicalDataForm.value;
    for (const key in formValueObj) {
      if (
        Object.prototype.hasOwnProperty.call(formValueObj, key) &&
        key !== 'Date' &&
        key !== 'Location-0' &&
        key !== 'Location-1' &&
        key !== 'Location-2'
      ) {
        data.push({
          Id: this.chemicalMaterialColumns.find((x) => x.Parameter === key)
            .Id,
          Name: key,
          Value: formValueObj[key],
          Type: this.chemicalMaterialColumns.find((x) => x.Parameter === key)
            .Type,
        });
        const target = this.chemicalMaterialColumns.find((x) => x.Parameter === key).Target ?
                        this.chemicalMaterialColumns.find((x) => x.Parameter === key).Target.toLowerCase() :
                        '';
        if((target.indexOf('m') != -1 || target.indexOf('%') != -1 || target.indexOf('±') != -1) &&
            target.indexOf('z') == -1 && target != 'referance' && target != '0.00% max.'){
          forNcr.push({
            Id: this.chemicalMaterialColumns.find((x) => x.Parameter === key)
              .Id,
            Name: key,
            Value: formValueObj[key],
            Type: this.chemicalMaterialColumns.find((x) => x.Parameter === key)
              .Type,
            Target: this.chemicalMaterialColumns.find((x) => x.Parameter === key).Target
          })
        }
      } else if (
        (Object.prototype.hasOwnProperty.call(formValueObj, key) &&
          key === 'Location-0') ||
        key === 'Location-1' ||
        key === 'Location-2'
      ) {
        const Value = this.chemicalMaterialColumns.find((x) => {
          if (key === 'Location-0') {
            return x.Parameter === key.split('-')[0] && x.Target === 'L';
          } else if (key === 'Location-1') {
            return x.Parameter === key.split('-')[0] && x.Target === 'C';
          } else {
            return x.Parameter === key.split('-')[0] && x.Target === 'R';
          }
        });
        data.push({
          Id: Value.Id,
          Name: key.split('-')[0],
          Value: formValueObj[key],
          Type: Value.Type,
        });
      } else {
        data.push({
          Id: this.chemicalMaterialColumns.find((x) => x.Parameter === key)
            .Id,
          Name: key,
          Value: this.chemicalLabForm.value.dateTime,
          Type: this.chemicalMaterialColumns.find((x) => x.Parameter === key)
            .Type,
        });
      }
    }
    for(var i=0; i<forNcr.length; i++){
      let targetValue;
      let enteredValue = parseFloat(forNcr[i].Value);
      enteredValue = isNaN(enteredValue) ? 0 : enteredValue;
      if(forNcr[i].Target.toLowerCase().indexOf('m') != -1){
        if(forNcr[i].Target.toLowerCase()[0] == 'm'){
          if(forNcr[i].Target.toLowerCase().indexOf('max.') != -1){
            if(forNcr[i].Target.slice(-1) == '%'){
              targetValue = forNcr[i].Target.split(' ')[1].split('%')[0];
              if(enteredValue>parseFloat(targetValue)){
                createNCR = true;
              }
            }
            else{
              targetValue = forNcr[i].Target.split(' ')[1].split('PPM')[0];
              if(enteredValue>parseFloat(targetValue)){
                createNCR = true;
              }
            }
          }
          else{
            if(forNcr[i].Target.slice(-1) == '%'){
              targetValue = forNcr[i].Target.split(' ')[1].split('%')[0];
              if(enteredValue<parseFloat(targetValue)){
                createNCR = true;
              }
            }
            else{
              targetValue = forNcr[i].Target.split(' ')[1].split('PPM')[0];
              if(enteredValue<parseFloat(targetValue)){
                createNCR = true;
              }
            }
          }
        }
        else{
          if(forNcr[i].Target.split('%')[1].trim().toLowerCase() == 'max.'){
            targetValue = forNcr[i].Target.split('%')[0];
            if(enteredValue>parseFloat(targetValue)){
              createNCR = true;
            }
          }
          else{
            targetValue = forNcr[i].Target.split('%')[0];
            if(enteredValue<parseFloat(targetValue)){
              createNCR = true;
            }
          }
        }
      }
      else if(forNcr[i].Target.toLowerCase().indexOf('±') != -1){
        let value = forNcr[i].Target.split('%')[0].split('±')[0];
        let limits = forNcr[i].Target.split('%')[0].split('±')[1];
        let maxValue = parseFloat((parseFloat(value)+parseFloat(limits)).toFixed(2));
        targetValue = parseFloat((parseFloat(value)-parseFloat(limits)).toFixed(2));
        if(enteredValue > maxValue || enteredValue < targetValue){
          createNCR = true;
        }
      }
      if(createNCR == true){
        ncrFieldsname.push(forNcr[i].Name);
        createNCR = false;
      }
    }
    if (this.isUpdated) {
      obj = {
        MaterialId: this.materialVal,
        UserId: JSON.parse(localStorage.user).UserId,
        UpdateId: this.updatedId,
        MaterialDataList: data,
        UpdatedOn: this.chemicalLabForm.value.dateTime,
        CreateNCR: ncrFieldsname.length ? true : false,
        MaterialName: this.materialList.find((x) => x.TypeId === this.materialVal).TypeName,
        NcrFieldsName: ncrFieldsname.length > 5 ? 'Many' : ncrFieldsname.toString()
      };
      this.updateForm(obj);
    } else {
      obj = {
        MaterialId: this.materialVal,
        UserId: JSON.parse(localStorage.user).UserId,
        MaterialDataList: data,
        CreatedOn: this.chemicalLabForm.value.dateTime,
        CreateNCR: ncrFieldsname.length ? true : false,
        MaterialName: this.materialList.find((x) => x.TypeId === this.materialVal).TypeName,
        NcrFieldsName: ncrFieldsname.length > 5 ? 'Many' : ncrFieldsname.toString()
      };
      const insertSub = this.opmsQualityService
        .insertChemicalData(obj)
        .pipe(
          finalize(() => {
          })
        )
        .subscribe(
          (res: any) => {
            this.toaster.success('Data Saved Successfully', res.message);
            this.resetForm();
            this.getMaterialEnteries(false);
            this.spinner.hide();
          },
          (error) => {
            this.toaster.error('Error', error.message);
            this.spinner.hide();
          }
        );
      this.subscriptions.add(insertSub);
    }
  }

  setMaterialOnChange(value: any): any {
    this.materialVal = value;
    this.isLoaded = false;
    this.isUpdated = false;
    this.resetForm();
    this.emptyAllColumnArrays();
    this.chemicalDataForm = new FormGroup({});
  }

  updateForm(key: any): any {
    this.spinner.show();
    const updateSub = this.opmsQualityService
      .updateChemicalData(key)
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (response: any) => {
          this.isUpdated = false;
          this.toaster.success('Data Updated Successfully', response.message);
          this.resetForm();
          this.getMaterialEnteries(false);
          this.spinner.hide();
        },
        (error) => {
          this.toaster.error('Error', error.message);
          this.spinner.hide();
        }
      );
    this.subscriptions.add(updateSub);
  }

  // get report section start
  getReport(): void {
    this.spinner.show();
    this.today = this.chemicalLabForm.value.dateTime;
    this.reportType = this.search(
      this.chemicalLabForm.value.materials,
      this.materialList
    );
    this.isReportSwitch = true;
    const { materials, dateTime } = this.chemicalLabForm.value;
    const data = {
      MaterialId: materials,
      TransactionType: 2,
      DateValue: dateTime,
    };
    this.opmsQualityService.getReport(data).subscribe(
      (response) => {
        this.reportData = [];
        let headers: string[] = [];
        const array: any[] = [];
        const dataArray: any[] = [];
        if (response) {
          for (const item of response?.Tables) {
            const obj = {
              type: item.Type,
              data: item.DataList.map((row: any[]) => {
                return row.reduce((result: any, field: any, index: number) => {
                  result[item.Headers[index]] = field;
                  return result;
                }, {});
              }),
              headers: item.Headers,
            };
            this.reportData.push(obj);
            headers = [...headers, ...item.Headers];
            dataArray.push(item.DataList);
          }
        }
        for (const dat of dataArray) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < dat.length; i++) {
            if (array.length < dat.length) {
              array.push([]);
            }
            array[i] = [...array[i], ...dat[i]];
          }
        }
        this.excelExportData = array.map((row: any[]) => {
          return row.reduce((result: any, field: any, index: number) => {
            result[headers[index]] = field;
            return result;
          }, {});
        });
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error?.message);
        this.spinner.hide();
      }
    );
  }

  exportToExcel(): void {
    const fileName = 'Chemical Quality Lab - Report';
    const data: any[] = this.excelExportData;
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    worksheet['!cols'] = this.getColumnsAutoWidth(data);
    const workbook: XLSX.WorkBook = {
      Sheets: {
        'Quality Report': worksheet,
      },
      SheetNames: ['Quality Report'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
  }
  // get report section end

  getColumnsAutoWidth(data: any[]): any[] {
    const objectMaxLength: any[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      const value = Object.keys(data[i]) as any;
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] === 'number') {
          objectMaxLength[j] = 10;
        } else {
          objectMaxLength[j] =
            objectMaxLength[j] >= value[j].length
              ? objectMaxLength[j] + 2
              : value[j].length + 2;
        }
      }
    }
    const wscols = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < objectMaxLength.length; i++) {
      wscols.push({ width: objectMaxLength[i] });
    }
    return wscols;
  }
  search(nameKey: any, myArray: string | any[]) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].TypeId === nameKey) {
        return myArray[i].TypeName;
      }
    }
  }
}
