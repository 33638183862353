<div class="machine-parameter-content" dir="{{languageDir}}">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">{{langObj ? langObj?.KPITargetReport : ('KPITargetReport' | translate)}}</h1>
    </div>
  </div>

  <!-- Tab option -->
  <mat-tab-group animationDuration="0ms">

    <mat-tab label="{{langObj ? langObj?.TargetSettingsProgress : ('Target Settings - Progress' | translate)}}">
      <!-- plant group view model -->
      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

      <form [formGroup]="targetReportOne">
        <div style="padding:10px;background-color: #0F0FD1;">
          <div class="p-grid p-fluid">
            <div class="p-col-4">
              <mat-card-title style="color: white;">{{langObj ? langObj?.TargetSettingsProgress : ('Target Settings -
                Progress' | translate)}}</mat-card-title>
            </div>
            <div class="p-col-4">
              <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                  'is-invalid':
                  targetReportOne.get('group')?.errors &&
                    targetReportOne.get('group')?.touched
                }" [options]="groupList" formControlName="group"
                placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}" optionLabel="TypeName"
                [showClear]="true"></p-dropdown>
              <p class="invalid-feedback" *ngIf="
                targetReportOne.get('group')?.touched &&
                  targetReportOne.get('group')?.hasError('required')
                ">
                * {{langObj ? langObj?.pleaseselectgroup : ('pleaseselectgroup' | translate)}}
              </p>
            </div>
            <div class="p-col-4">
              <p-dropdown [ngClass]="{
                        'is-invalid':
                        targetReportOne.get('plant')?.errors &&
                        targetReportOne.get('plant')?.touched
                      }" [options]="searchPlants" formControlName="plant" [filter]="true"
                placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}" optionLabel="DeptDesc"
                [showClear]="true" (onChange)="onPlantChange()">
              </p-dropdown>
              <p class="invalid-feedback" *ngIf="
                    targetReportOne.get('plant')?.touched &&
                    targetReportOne.get('plant')?.hasError('required')
                    ">
                * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
              </p>
            </div>
          </div>
        </div>
        <mat-card-content>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-6 p-lg-4">
              <label>{{langObj ? langObj?.FromDate : ('FromDate' | translate)}}</label>
              <p-calendar [locale]="calendar_en" [ngClass]="{
                'is-invalid':
                targetReportOne.get('fromDate')?.errors &&
                targetReportOne.get('fromDate')?.touched
              }" dateFormat="dd/mm/yy" [(ngModel)]="startYear" formControlName="fromDate" appendTo="body"
                [firstDayOfWeek]=0 [monthNavigator]="true" [yearNavigator]="true" yearRange="2015:2050"
                inputId="navigators">
              </p-calendar>
            </div>
            <div class="p-col-12 p-md-6 p-lg-4">
              <label>{{langObj ? langObj?.ToDate : ('ToDate' | translate)}}</label>
              <p-calendar [locale]="calendar_en" [ngClass]="{
                'is-invalid':
                targetReportOne.get('toDate')?.errors &&
                targetReportOne.get('toDate')?.touched
              }" dateFormat="dd/mm/yy" [(ngModel)]="endYear" formControlName="toDate" appendTo="body"
                [firstDayOfWeek]=0 [monthNavigator]="true" [yearNavigator]="true" yearRange="2015:2050"
                inputId="navigators">
              </p-calendar>
            </div>
            <div class="p-col-12 p-md-6 p-lg-4">
              <button mat-flat-button color="accent" type="submit" class="btn-accent" (click)="onDateChange($event)"
                [disabled]="!targetReportOne.valid">
                {{langObj ? langObj?.Search : ('Search' | translate)}}</button>
            </div>
          </div>
          <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="rowData"
            [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="10">
          </ag-grid-angular>

        </mat-card-content>
      </form>
    </mat-tab>
    <!-- Second Tab Starts -->
    <mat-tab
      label="{{langObj ? langObj?.TargetSettingsPendingbyKPI : ('Target Settings - Pending by KPI' | translate)}}">
      <!-- plant group view model -->
      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

      <form [formGroup]="targetReportTwo">
        <div style="padding:10px;background-color: #0F0FD1;">
          <div class="p-grid p-fluid">
            <div class="p-col-4">
              <mat-card-title style="color: white;">{{langObj ? langObj?.TargetSettingsPendingbyKPI : ('Target Settings
                - Pending by KPI' | translate)}}</mat-card-title>
            </div>
            <div class="p-col-4">
              <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                  'is-invalid':
                  targetReportTwo.get('group')?.errors &&
                  targetReportTwo.get('group')?.touched
                }" [options]="groupList" formControlName="group"
                placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}" optionLabel="TypeName"
                [showClear]="true"></p-dropdown>
              <p class="invalid-feedback" *ngIf="
                targetReportTwo.get('group')?.touched &&
                targetReportTwo.get('group')?.hasError('required')
                ">
                * {{langObj ? langObj?.pleaseselectgroup : ('pleaseselectgroup' | translate)}}
              </p>
            </div>
            <div class="p-col-4">
              <p-dropdown [ngClass]="{
                        'is-invalid':
                        targetReportTwo.get('plant')?.errors &&
                        targetReportTwo.get('plant')?.touched
                      }" [options]="searchPlants" formControlName="plant" [filter]="true"
                placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}" optionLabel="DeptDesc"
                [showClear]="true" (onChange)="onPlantChangeTwo()">
              </p-dropdown>
              <p class="invalid-feedback" *ngIf="
              targetReportTwo.get('plant')?.touched &&
              targetReportTwo.get('plant')?.hasError('required')
                    ">
                * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
              </p>
            </div>
          </div>
        </div>
        <mat-card-content>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-6 p-lg-4">
              <label>{{langObj ? langObj?.FromDate : ('FromDate' | translate)}}</label>
              <p-calendar [locale]="calendar_en" [ngClass]="{
                'is-invalid':
                targetReportTwo.get('fromDate')?.errors &&
                targetReportTwo.get('fromDate')?.touched
              }" dateFormat="dd/mm/yy" [(ngModel)]="startYear" formControlName="fromDate" appendTo="body"
                [firstDayOfWeek]=0 [monthNavigator]="true" [yearNavigator]="true" yearRange="2015:2050"
                inputId="navigators">
              </p-calendar>
            </div>
            <div class="p-col-12 p-md-6 p-lg-4">
              <label>{{langObj ? langObj?.ToDate : ('ToDate' | translate)}}</label>
              <p-calendar [locale]="calendar_en" [ngClass]="{
                'is-invalid':
                targetReportTwo.get('toDate')?.errors &&
                targetReportTwo.get('toDate')?.touched
              }" dateFormat="dd/mm/yy" [(ngModel)]="endYear" formControlName="toDate" appendTo="body"
                [firstDayOfWeek]=0 [monthNavigator]="true" [yearNavigator]="true" yearRange="2015:2050"
                inputId="navigators">
              </p-calendar>
            </div>
            <div class="p-col-12 p-md-6 p-lg-4">
              <button mat-flat-button color="accent" type="submit" class="btn-accent" (click)="onDateChangeTwo($event)"
                [disabled]="!targetReportTwo.valid"> {{langObj ? langObj?.Search : ('Search' | translate)}} </button>
            </div>
          </div>
          <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="rowData"
            [columnDefs]="columnDefsTwo" (gridReady)="onGridReadyTwo($event)" [pagination]="true" [paginationPageSize]="10">
          </ag-grid-angular>

        </mat-card-content>
      </form>
    </mat-tab>
    <mat-tab
      label="{{langObj ? langObj?.TargetSettingsPendingbyOwner : ('Target Settings - Pending by Owner' | translate)}}">
      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

      <form [formGroup]="targetReportThree">
        <div style="padding:10px;background-color: #0F0FD1;">
          <div class="p-grid p-fluid">
            <div class="p-col-4">
              <mat-card-title style="color: white;">{{langObj ? langObj?.TargetSettingsPendingbyOwner : ('Target
                Settings - Pending by Owner' | translate)}}</mat-card-title>
            </div>
            <div class="p-col-4">
              <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                  'is-invalid':
                  targetReportThree.get('group')?.errors &&
                  targetReportThree.get('group')?.touched
                }" [options]="groupList" formControlName="group" placeholder="Select a group" optionLabel="TypeName"
                [showClear]="true"></p-dropdown>
              <p class="invalid-feedback" *ngIf="
                targetReportThree.get('group')?.touched &&
                targetReportThree.get('group')?.hasError('required')
                ">
                * {{langObj ? langObj?.pleaseselectgroup : ('pleaseselectgroup' | translate)}}
              </p>
            </div>
            <div class="p-col-4">
              <p-dropdown [ngClass]="{
                        'is-invalid':
                        targetReportThree.get('plant')?.errors &&
                        targetReportThree.get('plant')?.touched
                      }" [options]="searchPlants" formControlName="plant" [filter]="true"
                placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}" optionLabel="DeptDesc"
                [showClear]="true" (onChange)="onPlantChangeThree()">
              </p-dropdown>
              <p class="invalid-feedback" *ngIf="
              targetReportThree.get('plant')?.touched &&
              targetReportThree.get('plant')?.hasError('required')
                    ">
                * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
              </p>
            </div>
          </div>
        </div>
        <mat-card-content>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-6 p-lg-3">
              <label>{{langObj ? langObj?.FromDate : ('FromDate' | translate)}}</label>
              <p-calendar [locale]="calendar_en" [ngClass]="{
                'is-invalid':
                targetReportThree.get('fromDate')?.errors &&
                targetReportThree.get('fromDate')?.touched
              }" dateFormat="dd/mm/yy" [(ngModel)]="startYear" formControlName="fromDate" appendTo="body"
                [firstDayOfWeek]=0 [monthNavigator]="true" [yearNavigator]="true" yearRange="2015:2050"
                inputId="navigators">
              </p-calendar>
            </div>
            <div class="p-col-12 p-md-6 p-lg-3">
              <label>{{langObj ? langObj?.ToDate : ('ToDate' | translate)}}</label>
              <p-calendar [locale]="calendar_en" [ngClass]="{
                'is-invalid':
                targetReportThree.get('toDate')?.errors &&
                targetReportThree.get('toDate')?.touched
              }" dateFormat="dd/mm/yy" [(ngModel)]="endYear" formControlName="toDate" appendTo="body"
                [firstDayOfWeek]=0 [monthNavigator]="true" [yearNavigator]="true" yearRange="2015:2050"
                inputId="navigators">
              </p-calendar>
            </div>
            <div class="p-col-12 p-md-6 p-lg-1">
              <button mat-flat-button color="accent" type="submit" class="btn-accent"
                (click)="onDateChangeThree($event)" [disabled]="!targetReportThree.valid"> Search </button>
            </div>
          <div class="p-col-12 p-md-6 p-lg-5 kpi_tag">
            <div class="p-grid">
              <button mat-flat-button style="font-weight: bold;background-color: #0F0FD1;">
                <span style="color: white;">{{TotalKPIsGridThree}}</span>
              </button>
              <button mat-flat-button style="font-weight: bold;background-color: #FF0000;">
                <span style="color: white;">{{TotalKPIsWihtoutRACI}}</span>
              </button>
            </div>
            <div class="p-grid">
              <button mat-flat-button style="font-weight: bold;background-color: #FF0000;">
                <span style="color: white;">{{TotalKPIsWihtoutTargetGridThree}}</span>
              </button>
              <button mat-flat-button style="font-weight: bold;background-color: #FF0000;">
                <span style="color: white;">{{TotalKPIsWihtoutActualGridThree}}</span>
              </button>
            </div>
          </div>
          </div>
          <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="rowData"
            [columnDefs]="columnDefsThree" (gridReady)="onGridReadyThree($event)"
            (firstDataRendered)="onFirstDataRendered($event)" [pagination]="true" [paginationPageSize]="10">
          </ag-grid-angular>
        </mat-card-content>
      </form>

    </mat-tab>
  </mat-tab-group>

</div>