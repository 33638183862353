import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { OpmsQualityService } from 'src/app/services/opms-quality.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { finalize } from 'rxjs/operators';
import * as Highcharts from 'highcharts';
import { GlassQaDashboardService } from 'src/app/services/glass-qa-dashboard.service';

@Component({
  selector: 'app-glass-chemical-qa-dashboard',
  templateUrl: './glass-chemical-qa-dashboard.component.html',
  styleUrls: ['./glass-chemical-qa-dashboard.component.scss']
})
export class GlassChemicalQaDashboardComponent implements OnInit {

  public breadcrumList: any;
  private subscriptions = new Subscription();
  chemicalQaDashboardForm!: FormGroup;
  public materialList!: any[];
  chartsLoop: any[];
  chartOptions: any;
  highChartsColor = [
    '#3b2fb5',
    '#0034bb',
    '#00a8a8',
    '#00adad',
    '#512E5F',
    '#C471A5',
    '#06573d',
    '#0d6e54',
    '#6D81A8',
    '#611745',
    '#00a800',
    '#0b774e',
    '#570000',
    '#00002e',
    '#FFDF00',
    '#0692aa',
    '#ff5252',
    '#08FAC7',
    '#D208FA',
  ];

  constructor(
    private spinner: NgxSpinnerService,
    private usermanagementService: UserManagementService,
    private toaster: ToastrService,
    public router: Router,
    private fb: FormBuilder,
    private opmsQualityService: OpmsQualityService,
    private datePipe: DatePipe,
    private glassQaDashboard: GlassQaDashboardService,
  ) { }

  getMaterialList(): any {
    this.spinner.show();
    const data = {};
    this.opmsQualityService.getMaterialsForChemical(data).subscribe(
      (res: any) => {
        this.materialList = res.MaterialList;
        this.chemicalQaDashboardForm.get('materialtype')?.setValue(this.materialList[0].TypeId);
        this.loadDashBoard();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  loadDashBoard() {
    this.spinner.show();
    this.chartsLoop = [];
    const data = {
      StartDate: this.chemicalQaDashboardForm.get('fromdate')?.value,
      EndDate: this.chemicalQaDashboardForm.get('todate')?.value,
      MaterialId: this.chemicalQaDashboardForm.get('materialtype')?.value,
    }
    this.glassQaDashboard.GetChemicalLabDashboard(data).subscribe(
      (response: any) => {
        if(response.result.length){
          if(!response.HasError){
            this.chartsLoop = response.result;
            setTimeout(() => {
              this.plotChart();
            }, 500);
          }else{
            this.toaster.error(response.result[0].Msg);
            this.spinner.hide();
          }
        }
        else{
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.spinner.hide();
      }
    )
  }

  plotChart(){
    for(let i = 0; i<this.chartsLoop.length; i++){
      let target, targetposition;
      let plotlines = [];
      if(this.chartsLoop[i].Target && this.chartsLoop[i].Target[0] == 'M'){
        if(this.chartsLoop[i].Target.split(" ")[1][(this.chartsLoop[i].Target.split(" ")[1]).length-1] == '%'){
          target = parseFloat(this.chartsLoop[i].Target.split(" ")[1].split('%')[0]);
        }
        else{
          target = parseFloat(this.chartsLoop[i].Target.split(" ")[1])
        }
        if(this.chartsLoop[i].Target.split(" ")[0] == 'Max.'){
          targetposition = -20;
        }
        if(this.chartsLoop[i].Target.split(" ")[0] == 'Min.'){
          targetposition = 20;
        }
        plotlines.push(
          {
            color: '#ff5252',
            width: 2,
            value: target,
            label: {
              text: this.chartsLoop[i].Target,
              style: {
                color: '#ff5252',
                fontWeight: 'bold',
              },
              textAlign: 'left',
              y: targetposition
            },
          }
        )
      }
      if(this.chartsLoop[i].Target && this.chartsLoop[i].Target[0] !== 'M'){
        let lines = this.chartsLoop[i].Target.split("±");
        if(lines.length == 1){
          lines = this.chartsLoop[i].Target.split("+");
          target = parseFloat(lines[0]);
          let text = parseFloat(target+parseFloat(lines[1].split('%')[0])).toFixed(2);
          plotlines.push(
            {
              color: '#ff5252',
              width: 2,
              value: target+parseFloat(lines[1].split('%')[0]),
              label: {
                text: 'Max. '+text+'%',
                style: {
                  color: '#ff5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: -20
              },
            }
          );
          text = (target).toString();
          plotlines.push(
            {
              color: '#ff5252',
              width: 2,
              value: target-parseFloat(lines[1].split('%')[0]),
              label: {
                text: 'Min. '+text+'%',
                style: {
                  color: '#ff5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: 20
              },
            }
          );
        }
        else{
          target = parseFloat(lines[0]);
          let text = parseFloat(target+parseFloat(lines[1].split('%')[0])).toFixed(2);
          plotlines.push(
            {
              color: '#ff5252',
              width: 2,
              value: target+parseFloat(lines[1].split('%')[0]),
              label: {
                text: 'Max. '+text+'%',
                style: {
                  color: '#ff5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: -20
              },
            }
          );
          text = parseFloat((target-parseFloat(lines[1].split('%')[0])).toString()).toFixed(2);
          plotlines.push(
            {
              color: '#ff5252',
              width: 2,
              value: target-parseFloat(lines[1].split('%')[0]),
              label: {
                text: 'Min. '+text+'%',
                style: {
                  color: '#ff5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: 20
              },
            }
          );
        }
      }
      let data = this.chartsLoop[i].Value.split(',').map(str => Number(str));
      let limit = data.slice();
      this.chartOptions = {
        chart: {
            type: 'spline'
        },
        credits: {
          enabled: false,
        },
        title: {
            text: this.chartsLoop[i].Parameter
        },
        xAxis: {
            categories: this.chartsLoop[i].CreatedOn.split(',')
        },
        yAxis: {
          title: {
            text: "Target - "+this.chartsLoop[i].Target
          },
          min: limit.sort((a,b)=>a-b)[0]-2,
          max: limit.sort((a,b)=>a-b)[limit.length-1]+2,
          plotLines: plotlines
        },
        plotOptions: {
            spline: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: true
            }
        },
        legend: {
          enabled: false
        },
        series: [{
            data: data,
            color: this.highChartsColor[i]
        }]
      }
      Highcharts.chart(this.chartsLoop[i].Parameter.toString(), this.chartOptions);
    }
    this.spinner.hide();
  }

  getBreadcrumList(): any {
    this.spinner.show();
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    const breadCrumSub = this.usermanagementService.GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      ).subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(breadCrumSub);
  }

  displayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  ngOnInit(): void {
    var firstDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    this.chemicalQaDashboardForm = this.fb.group({
      fromdate: [this.datePipe.transform(firstDate, 'yyyy-MM-dd')],
      materialtype: [''],
      todate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')]
    });
    this.getBreadcrumList();
    this.getMaterialList();
  }

}
