import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { QualityOdsService } from 'src/app/services/quality-ods.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import * as _moment from 'moment';
import { PDFExportRenderer } from 'src/app/pdf-export-renderer.component';
import { OpmsQualityService } from 'src/app/services/opms-quality.service';

@Component({
  selector: 'app-glass-chemical-qa-report',
  templateUrl: './glass-chemical-qa-report.component.html',
  styleUrls: ['./glass-chemical-qa-report.component.scss']
})
export class GlassChemicalQaReportComponent implements OnInit {

  public breadcrumList: any;
  private subscriptions = new Subscription();
  chemicalQaReportForm!: FormGroup;
  public materialList!: any[];
  public reportType: any;
  rowDataReport: any[] = [];
  columnDefsReport: any[] = [];
  gridApi: any;
  gridColumnApi: any;
  defaultColDef: any =  {
    filter: true,
    resizable: true,
    wrapText: true,
    width: 120,
    autoHeight: true,
  };
  exportExcelColumns: any[] = [];
  chemicalMaterialProperties: any[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private usermanagementService: UserManagementService,
    private toaster: ToastrService,
    public router: Router,
    private fb: FormBuilder,
    private opmsQualityService: OpmsQualityService,
    private datePipe: DatePipe,
  ) { }

  getMaterialList(): any {
    this.spinner.show();
    const data = {};
    this.opmsQualityService.getMaterialsForChemical(data).subscribe(
      (res: any) => {
        this.materialList = res.MaterialList;
        this.chemicalQaReportForm.get('reportType')?.setValue(this.materialList[0].TypeId);
        this.getChemicalMaterialProperties(true);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  getChemicalMaterialProperties(status: boolean): void {
    const data = {
      MaterialId: this.chemicalQaReportForm.value.reportType,
    };
    const matProperties = this.opmsQualityService
      .getChemicalMaterialProperties(data)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (res: any) => {
          this.chemicalMaterialProperties = res.MaterialPropertyList;
          status ? this.getReports() : '';
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(matProperties);
  }

  getReports(){
    this.spinner.show();
    this.columnDefsReport = [];
    this.rowDataReport = [];
    this.reportType = this.search(this.chemicalQaReportForm.value.reportType,this.materialList);
    const {reportType, fromdate, todate} = this.chemicalQaReportForm.value;
    const data = {
      MaterialId: reportType,
      TransactionType: 3,
      DateValue: fromdate,
      ToDateValue: todate
    };
    this.opmsQualityService.getReport(data).subscribe(
      (response) => {
        if(!response.HasError){
          if(response.Tables.length>0){
            let reportData = [];
            let columns = [];
            for(let rows = 0; rows<response.Tables[0].DataList.length; rows++){
              const obj = {};
              let index = 1;
              for(let i=0; i<response.Tables.length; i++){
                const childrenColumn = [];
                for(let j=0; j<response.Tables[i].Headers.length; j++){
                  const target = this.chemicalMaterialProperties.find(
                                      (row: any) => row.Parameter == response.Tables[i].Headers[j].split('|')[0].trim() && 
                                      row.Target != null)?.Target;
                  if(target && (target.indexOf('m') != -1 || target.indexOf('%') != -1 || target.indexOf('±') != -1) &&
                      target.indexOf('z') == -1 && target != 'referance' && target != '0.00% max.' &&
                      target != 'L' && target != 'C' && target != 'R'){
                    childrenColumn.push({
                      headerName: response.Tables[i].Headers[j],
                      field: 'key'+index,
                      cellStyle: (params: any) => this.validateData(params) ? { color: 'red', background: '#e5dede'} : { color: 'black' }
                    })
                  }
                  else{
                    childrenColumn.push({
                      headerName: response.Tables[i].Headers[j],
                      field: 'key'+index,
                    })
                  }
                  obj['key'+index] = response.Tables[i].DataList[rows][j];
                  this.exportExcelColumns.push('key'+index);
                  index++;
                }
                if(rows == 0){
                  columns.push({
                    headerName: response.Tables[i].Type,
                    marryChildren: true,
                    children: childrenColumn
                  })
                }
              }
              reportData.push(obj);
              if(rows == 0){
                this.columnDefsReport = columns;
              }
              else{
                columns = [];
              }
            }
            this.rowDataReport = reportData;
            reportData = [];
          }
          else{
            this.rowDataReport = [];
            this.columnDefsReport = [];
          }
        }
        else{
          this.toaster.error(response.ErrorMessage);
        }
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error?.message);
        this.spinner.hide();
      }
    );
  }

  validateData(params: any){
    let target
    let targetValue;
    let enteredValue;
    if(isNaN(params.value)){
      enteredValue = 0;
    }
    else{
      enteredValue = params.value;
    }
    target = this.chemicalMaterialProperties.find(
                    (row: any) => row.Parameter == params.colDef.headerName.split('|')[0].trim() && 
                                  row.Target != null)?.Target
    if(target.toLowerCase().indexOf('m') != -1){
      if(target.toLowerCase()[0] == 'm'){
        if(target.toLowerCase().indexOf('max.') != -1){
          if(target.slice(-1) == '%'){
            targetValue = target.split(' ')[1].split('%')[0];
            if(enteredValue>parseFloat(targetValue)){
              return true;
            }
          }
          else{
            targetValue = target.split(' ')[1].split('PPM')[0];
            if(enteredValue>parseFloat(targetValue)){
              return true;
            }
          }
        }
        else{
          if(target.slice(-1) == '%'){
            targetValue = target.split(' ')[1].split('%')[0];
            if(enteredValue<parseFloat(targetValue)){
              return true;
            }
          }
          else{
            targetValue = target.split(' ')[1].split('PPM')[0];
            if(enteredValue<parseFloat(targetValue)){
              return true;
            }
          }
        }
      }
      else{
        if(target.split('%')[1].trim().toLowerCase() == 'max.'){
          targetValue = target.split('%')[0];
          if(enteredValue>parseFloat(targetValue)){
            return true;
          }
        }
        else{
          targetValue = target.split('%')[0];
          if(enteredValue<parseFloat(targetValue)){
            return true;
          }
        }
      }
    }
    else if(target.toLowerCase().indexOf('±') != -1){
      let value = target.split('%')[0].split('±')[0];
      let limits = target.split('%')[0].split('±')[1];
      let maxValue = parseFloat((parseFloat(value)+parseFloat(limits)).toFixed(2));
      targetValue = parseFloat((parseFloat(value)-parseFloat(limits)).toFixed(2));
      if(enteredValue > maxValue || enteredValue < targetValue){
        return true;
      }
    }
  }

  exportToExcel(): void {
    const excelParams = {
      headerRowHeight: 50,
      sheetName: 'Chemical - '+this.reportType,
      fileName: 'Chemical - '+this.reportType,
      allColumns: false,
      columnKeys: this.exportExcelColumns
    };
    this.gridApi.exportDataAsExcel(excelParams);
  }

  search(nameKey: any, myArray: string | any[]) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].TypeId === nameKey) {
        return myArray[i].TypeName;
      }
    }
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getBreadcrumList(): any {
    this.spinner.show();
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    const breadCrumSub = this.usermanagementService.GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      ).subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(breadCrumSub);
  }

  displayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  ngOnInit(): void {
    var firstDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    this.chemicalQaReportForm = this.fb.group({
      fromdate: [this.datePipe.transform(firstDate, 'yyyy-MM-dd')],
      reportType: [''],
      todate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')]
    });
    this.getBreadcrumList();
    this.getMaterialList();
  }

}
