<div class="order-processing-content" >
    <div class="content-top-box ">
      <ul class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="breadcrumb-link" routerLink="/Access">Access</a>
        </li>
      </ul>
      <div class="top-box-content">
        <h1 class="main-title">User Role Mapping</h1>
      </div>
    </div>
  
    <form [formGroup]="userRoleForm" >
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-top-row content-top-row-order-processing" style="display: flex;">        
          <mat-form-field appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing" >
            <mat-select formControlName="userGroup" required [(ngModel)]="GroupId" (selectionChange)="getUserslist()" placeholder="Group Name">
              <mat-select-filter
                [placeholder]="'Search'"
                [displayMember]="'TypeName'"
                [array]="GroupList"
                (filteredReturn)="filteredGroups = $event"
              ></mat-select-filter>
              <mat-option *ngFor="let group of filteredGroups" [value]="group.TypeId" >
                {{ group.TypeName }}</mat-option
              >
            </mat-select>         
          </mat-form-field>

          <mat-form-field appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select formControlName="UserName" required (selectionChange)="getUserRoles()" placeholder="User Name">
              <mat-select-filter
                [placeholder]="'Search'"
                [displayMember]="'UserName'"
                [array]="UsersList"
                (filteredReturn)="filteredUsers = $event"
              ></mat-select-filter>
              <mat-option *ngFor="let user of filteredUsers" [value]="user.UserId" >
                {{user.UserName}}</mat-option
              >
            </mat-select>  
          </mat-form-field>

          <button type="button" (click)="onSubmit()"  class="add-btn1" pTooltip="Save" tooltipPosition="top" style="margin-left: 10px;" >
            <img src="assets/images/save.png" alt="" width="20px" height="20px">
          </button>
          <mat-form-field appearance="fill" class="field-fill" style="display: flex;
          position: relative;
          text-align: left;
          justify-content: flex-end;">
            <mat-label >Search</mat-label>
              <input [readonly]="false" matInput class="input-text" (keyup)="applyFilter($event)" autocomplete="off"/>
          </mat-form-field>  

        </div>
    
        <table mat-table [dataSource]="userRoleData" class="basic-table plant-model-table" matSort>
  
          <ng-container matColumnDef="RoleId">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
              <p class="header-text"> RoleId</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.RoleId}} </td>
          </ng-container>
  
          <!-- Item Code Column -->
          <ng-container matColumnDef="RoleName">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
              <p class="header-text"> Role Name</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.RoleName}} </td>
          </ng-container>
  
          <!-- PO Target Column -->
          <ng-container matColumnDef="Access">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">Access</p>
            </th>
            
            <td mat-cell *matCellDef="let element"> <mat-checkbox class="example-margin" [checked]="element.IsRoleMap" (change)="setAll($event.checked)">
                
            </mat-checkbox> </td>
          </ng-container>
   
          <tr mat-header-row *matHeaderRowDef="userRoleGroupColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: userRoleGroupColumns;" (click)="highlight(row)" [ngClass]="{'highlightTableColor': selectedRowIndex == row.position}"></tr>
        </table>
        
        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        <div class="btn-position btn-position-order-processing" align='end'>
          </div>
      </div>
    </form>
  
  
  </div>
  