import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OeeConfigurationService } from 'src/app/services/oee-configuration.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { SfdcConfigurationService } from 'src/app/services/Sfdcconfiguration.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';

interface Group {
  value: string;
  viewValue: string;
}

interface Select {
  value: string;
  viewValue: string;
}

interface MapDuration {
  value: string;
  Name: string;
}

interface DataType {
  value: string;
  viewValue: string;
}

interface TypeName {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-sfdc-configuration',
  templateUrl: './sfdc-configuration.component.html',
  styleUrls: ['./sfdc-configuration.component.scss'],
})
export class SfdcConfigurationComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('displayDropdownModal')
  private displayDropdownModal!: TemplateRef<any>;
  panelOpenState = false;
  Group!: string;
  KpiSelect!: string;
  LotCode!: string;
  DataType!: any;
  TypeName!: string;
  codeLists: any[] = [
    {
      id: 1,
      name: 'YES',
    },
    { id: 2, name: 'NO' },
  ];
  MapDuration!: any;

  parameterList!: MatTableDataSource<any>;
  lotCodeList!: MatTableDataSource<any>;
  productOutputList!: MatTableDataSource<any>;
  TapeNameDDLList!: MatTableDataSource<any>;
  MapFaultList!: MatTableDataSource<any>;
  MapDurationList!: MatTableDataSource<any>;
  dropdownDataSource!: MatTableDataSource<any>;
  dropDownColumns: any = ['actions', 'id', 'name'];

  parameterHeader: string[] = ['Actions', 'Name'];
  lotCodeHeader: string[] = ['Actions', 'Name'];
  productOutputHeader: string[] = ['Actions', 'Name'];
  TapeNameDDLHeader: string[] = ['Actions', 'Result', 'Name', 'UoM'];

  breadcrumList!: any;
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  parameterType!: any[];

  isUpdateParameter = false;
  isUpdatelotcode = false;
  isUpdateProductOutput = false;
  isUpdateWaste = false;
  // Forms
  unitDetailForm!: FormGroup;
  searchForm!: FormGroup;
  centerlineParameterForm!: FormGroup;
  LotCodeForm!: FormGroup;
  productOutputForm!: FormGroup;
  TapeNameDDLForm!: FormGroup;
  shortMapDefaultForm!: FormGroup;
  mapShortStopDurationForm!: FormGroup;
  dropdownForm!: FormGroup;

  // Node
  UnitNode: any;
  dropDownProp = false;
  Id!: any;

  constructor(
    private userManagementService: UserManagementService,
    private toaster: ToastrService,
    public router: Router,
    private oeeConfigurationService: OeeConfigurationService,
    private platModelService: EnterPriseGroupService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private sfdcConfigurationService: SfdcConfigurationService
  ) {}

  GroupList!: any[];
  TapeNameDDL!: any[];
  UnitName!: string;

  isPlantGroupModelVisible = false;

  // dummy data
  lotcode!: any[]; // LotCode[]= [{value:true,viewValue:'Yes'},{value:false,viewValue:'No'}]
  MapDurationddl: MapDuration[] = [
    { value: '1', Name: '1' },
    { value: '2', Name: '2' },
    { value: '3', Name: '3' },
    { value: '4', Name: '4' },
    { value: '5', Name: '5' },
    { value: '6', Name: '6' },
    // tslint:disable-next-line:max-line-length
    { value: '7', Name: '7' },
    { value: '8', Name: '8' },
    { value: '9', Name: '9' },
    { value: '10', Name: '10' },
    { value: '11', Name: '11' },
    { value: '12', Name: '12' },
    { value: '13', Name: '13' },
    { value: '14', Name: '14' },
    { value: '15', Name: '15' },
    { value: '16', Name: '16' },
    { value: '17', Name: '17' },
    { value: '18', Name: '18' },
    { value: '19', Name: '19' },
    { value: '20', Name: '20' },
    { value: '21', Name: '21' },
    { value: '22', Name: '22' },
    { value: '23', Name: '23' },
    { value: '24', Name: '24' },
    { value: '25', Name: '25' },
    { value: '26', Name: '26' },
    { value: '27', Name: '27' },
    { value: '28', Name: '28' },
    { value: '29', Name: '29' },
    { value: '30', Name: '30' },
  ];

  datatype: DataType[] = [
    { value: 'bool', viewValue: 'Bool' },
    { value: 'string', viewValue: 'String' },
    { value: 'number', viewValue: 'Number' },
    { value: 'date', viewValue: 'Date' },
    { value: 'dropdown', viewValue: 'Dropdown' },
  ];

  select: Select[] = [
    { value: 'MachineParameters', viewValue: 'MachineParameters' },
    { value: 'MaterialParameters', viewValue: 'MaterialParameters' },
    { value: 'ClearanceParameters', viewValue: 'ClearanceParameters' },
  ];

  typename: TypeName[] = [
    { value: 'Batch Input', viewValue: 'Batch Input' },
    { value: 'Batch Output', viewValue: 'Batch Output' },
    { value: 'Waste', viewValue: 'Waste' },
  ];
  sfdcColumns: string[] = ['sfdcactions', 'name'];
  SFDCColumns: string[] = ['SFDCactions', 'result', 'name', 'uom', 'action'];
  // plant group view
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();

  // parent node
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.parameterType = [
      { Name: 'Machine Parameters', Id: 1 },
      { Name: 'Material Paramters', Id: 2 },
      { Name: 'Clearance Parameters', Id: 3 },
    ];
    this.GetMenuAccess();
    this.GetBreadcrumList();
    this.getGroup();
    this.getTapeNameDDL();

    this.unitDetailForm = this.formBuilder.group({
      groupList: ['', Validators.required],
    });
    this.centerlineParameterForm = this.formBuilder.group({
      question: ['', Validators.required],
      parameterType: [1, Validators.required],
      PU_ID: ['', Validators.required],
      Id: [''],
    });
    this.LotCodeForm = this.formBuilder.group({
      PU_ID: ['', Validators.required],
      IsLotCodeAuto: [''],
      LotCodeFormat: [''],
      Id: [],
    });
    this.productOutputForm = this.formBuilder.group({
      PU_ID: ['', Validators.required],
      DataType: ['bool', Validators.required],
      Name: [''],
      Id: [''],
    });
    this.TapeNameDDLForm = this.formBuilder.group({
      PU_ID: ['', Validators.required],
      Name: ['', Validators.required],
      Result: ['', Validators.required],
      UoM: ['', Validators.required],
      TN_ID: [''],
      UserId: [''],
      BatchListId: [''],
      Id: [''],
    });

    this.shortMapDefaultForm = this.formBuilder.group({
      PU_ID: ['', Validators.required],
      mapDefault: ['', Validators.required],
    });
    this.mapShortStopDurationForm = this.formBuilder.group({
      PU_ID: ['', Validators.required],
      mapDuration: ['', Validators.required],
    });
    this.dropdownForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }
  // group dropdown
  getGroup(): void {
    const key = {
      Id: 0,
    };
    this.oeeConfigurationService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.masterList;
        this.unitDetailForm
          .get('groupList')
          ?.setValue(this.GroupList[0].TypeId);
        // this.getLotsCodes();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getTapeNameDDL(): void {
    this.sfdcConfigurationService.GetTapeNameDDL().subscribe(
      (data: any) => {
        this.TapeNameDDL = data.TapNameList;
        this.TapeNameDDLForm.get('TN_ID')?.setValue(this.TapeNameDDL[0].Id);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  // close model
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }

  // Unit
  openUnit(): void {
    this.isPlantGroupModelVisible = true;
    const key = {
      EGId: this.unitDetailForm.value.groupList,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };
    this.oeeConfigurationService.getTreeModal(key).subscribe((data: any) => {
      this.plantGroupSource.data = data.TreeModalList;
      this.treeControl.dataNodes = this.plantGroupSource.data;
      this.UnitNode = filterData(this.plantGroupSource.data, 'UNT');
      this.treeControl.expandAll();
      this.treeControl.collapseDescendants(this.UnitNode);  
    });
    // this.plantGroupSource.data = plantGroupData;

    function filterData(data: PlantGroupElement[], title: string) {
      const r = data.filter(function (o: any) {
        Object.keys(o).forEach(function (e: any) {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
  }

  onClickUnit(node: any): void {
    if (node.Level === 'Unit') {
      this.centerlineParameterForm.get('PU_ID')?.setValue(node.PrimaryId);
      this.LotCodeForm.get('PU_ID')?.setValue(node.PrimaryId);
      this.productOutputForm.get('PU_ID')?.setValue(node.PrimaryId);
      this.TapeNameDDLForm.get('PU_ID')?.setValue(node.PrimaryId);
      this.shortMapDefaultForm.get('PU_ID')?.setValue(node.PrimaryId);
      this.mapShortStopDurationForm.get('PU_ID')?.setValue(node.PrimaryId);

      this.isPlantGroupModelVisible = false;
      this.UnitName = node.text;
      const id = {
        PU_ID: node.PrimaryId,
        KPT_ID: this.centerlineParameterForm.get('parameterType')?.value,
      };
      this.getLotsCodes();
      this.getKpiData(id);
      this.getLotCode({ PU_ID: node.PrimaryId });
      this.getProductOutput({ PU_ID: node.PrimaryId });
      this.getHtmlLable();
      this.GetMapFault();
      this.getMapFaultList();
      this.getMapShortDurationList();
    }
  }

  SelectUnitName(unitname: any): void {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
    }
  }
  // parameter
  onClickSaveParameter(): void {
    if (!this.isUpdateParameter) {
      const data = {
        PU_ID: this.centerlineParameterForm.get('PU_ID')?.value,
        KPT_ID: this.centerlineParameterForm.get('parameterType')?.value,
        Name: this.centerlineParameterForm.get('question')?.value,
      };
      if (this.centerlineParameterForm.invalid) {
        this.unitNotSelect(this.centerlineParameterForm);
        return;
      }
      this.sfdcConfigurationService.SubmitKpiParameter(data).subscribe(
        (response: any) => {
          if (response.HasError) {
            this.toaster.error('Error', response.ErrorMessage);
          } else {
            this.toaster.success('Saved successfully');

            const id = { PU_ID: data.PU_ID, KPT_ID: data.KPT_ID };
            this.getKpiData(id);
            this.centerlineParameterForm.get('question')?.reset();
          }
        },
        (error) => {
          this.showPopupMsg(error.ErrorMessage);
        }
      );
    } else {
      const data = {
        PU_ID: this.centerlineParameterForm.get('PU_ID')?.value,
        KPT_ID: this.centerlineParameterForm.get('parameterType')?.value,
        Name: this.centerlineParameterForm.get('question')?.value,
        Id: this.centerlineParameterForm.get('Id')?.value,
      };
      this.sfdcConfigurationService.UpdateKpiParameter(data).subscribe(
        (response: any) => {
          if (response.HasError) {
            this.toaster.error('Error', response.ErrorMessage);
          } else {
            this.isUpdateParameter = false;
            this.toaster.success('Updated successfully');
            const id = { PU_ID: data.PU_ID, KPT_ID: data.KPT_ID };
            this.getKpiData(id);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }
  onClickUpdateParamter(element: any): void {
    // debugger
    this.isUpdateParameter = true;
    this.centerlineParameterForm.get('Id')?.setValue(element.Id);
    this.sfdcConfigurationService
      .GetKpiParam({ KpiParameterId: element.Id })
      .subscribe(
        (response: any) => {
          if (response.HasError) {
            this.toaster.error('Error', response.ErrorMessage);
          } else {
            this.centerlineParameterForm
              .get('question')
              ?.setValue(response.KpiParamTypeList[0].Name);
            this.centerlineParameterForm
              .get('parameterType')
              ?.setValue(response.KpiParamTypeList[0].KpiParameterTypeId);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    this.centerlineParameterForm.get('question')?.reset();
  }

  onClickDeleteParamter(element: any): void {
    this.sfdcConfigurationService.DeleteKpiParam(element.Id).subscribe(
      (data: any) => {
        const key = {
          PU_ID: this.centerlineParameterForm.get('PU_ID')?.value,
          KPT_ID: this.centerlineParameterForm.get('parameterType')?.value,
        };
        this.getKpiData(key);
        this.toaster.success('Deleted successfully');
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getKpiData(data: any): void {
    // debugger;
    this.sfdcConfigurationService.GetKpiParam(data).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.parameterList = new MatTableDataSource<any>(data.KpiParamTypeList);
        this.getPaginator(this.parameterList, 0);
        this.centerlineParameterForm.get('question')?.reset();
      },
      (error: any) => {
        this.spinner.hide();

        this.toaster.error('Error', error.message);
      }
    );
  }
  onChangeFetchParameter(data: any): void {
    this.isUpdateParameter = false;
    const id = {
      PU_ID: this.centerlineParameterForm.get('PU_ID')?.value,
      KPT_ID: data.value,
    };
    this.getKpiData(id);
  }

  showPopupMsg(msg: any): any {
    // debugger;
    if (msg !== null) {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
        // this.isaddNewUserModeItemModelVisible = false;
      } else {
        this.toaster.error('Error', msg.substring(3, msg.length));
      }
    }
  }
  // Lotcode

  getLotsCodes(): void {
    // debugger;
    const key = {
      PU_ID: this.LotCodeForm.get('PU_ID')?.value,
      IsLotCodeAuto: this.LotCodeForm.get('IsLotCodeAuto')?.value ? this.LotCodeForm.get('IsLotCodeAuto')?.value : false,
    };
    this.sfdcConfigurationService
      .GETLotcodeMachineMapping(key)
      .subscribe((res: any) => {
        this.lotCodeList = new MatTableDataSource<any>(res.LotcodeMachineList);
        this.LotCodeForm.get('IsLotCodeAuto')?.setValue(
          res.LotcodeMachineList.length == 0 || res.LotcodeMachineList[0].IsLotCodeAuto.toString() === undefined
            ? ''
            : res.LotcodeMachineList[0]?.IsLotCodeAuto.toString()
        );
        this.getPaginator(this.lotCodeList, 1);
      });
  }

  onChangeGroup() {
    this.centerlineParameterForm.get('PU_ID')?.setValue('');
    this.LotCodeForm.get('PU_ID')?.setValue('');
    this.productOutputForm.get('PU_ID')?.setValue('');
    this.TapeNameDDLForm.get('PU_ID')?.setValue('');
    this.shortMapDefaultForm.get('PU_ID')?.setValue('');
    this.mapShortStopDurationForm.get('PU_ID')?.setValue('');
    this.lotCodeList = new MatTableDataSource<any>([]);
    this.productOutputList = new MatTableDataSource<any>([]);
    this.dropdownDataSource = new MatTableDataSource<any>([]);
    this.MapDurationList = new MatTableDataSource<any>([]);
    this.MapFaultList = new MatTableDataSource<any>([]);
    this.TapeNameDDLList = new MatTableDataSource<any>([]);
    this.UnitName = '';
  }
  getLotCode(data: any): void {
    // debugger;
    data.IsLotCodeAuto = this.LotCodeForm.get('IsLotCodeAuto')?.value;
    this.sfdcConfigurationService.GETLotcodeMachineMapping(data).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.isUpdatelotcode = false;
        this.lotCodeList = new MatTableDataSource<any>(data.LotcodeMachineList);
        this.getPaginator(this.lotCodeList, 1);
      },
      (error: any) => {
        this.spinner.hide();

        this.toaster.error('Error', error.message);
      }
    );
  }

  onClickSaveLotCode(): void {
    this.spinner.show();
    if (!this.isUpdatelotcode) {
      const data = {
        PU_ID: this.LotCodeForm.value.PU_ID,
        IsLotCodeAuto: this.LotCodeForm.value.IsLotCodeAuto,
        LotCodeFormate: this.LotCodeForm.value.LotCodeFormat,
      };
      if (this.LotCodeForm.invalid) {
        this.unitNotSelect(this.LotCodeForm);
        return;
      }
      this.sfdcConfigurationService.SubmitLotcodeMachineMapping(data).subscribe(
        (response: any) => {
          this.spinner.hide();

          this.lotCodeList = new MatTableDataSource<any>(
            response.LotcodeMachineList
          );
          if (response.HasError) {
            this.spinner.hide();

            this.toaster.error('Error', response.ErrorMessage);
          } else {
            this.spinner.hide();

            const id = { PU_ID: data.PU_ID };
            this.getLotCode(id);
            this.LotCodeForm.get('LotCodeFormat')?.reset();
            this.toaster.success('Saved successfully');
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      const data = {
        PU_ID: this.LotCodeForm.get('PU_ID')?.value,
        IsLotCodeAuto: this.LotCodeForm.get('IsLotCodeAuto')?.value,
        LotCodeFormate: this.LotCodeForm.get('LotCodeFormat')?.value,
        Id: this.LotCodeForm.get('Id')?.value,
      };
      this.sfdcConfigurationService.updateLotcodeMachineMapping(data).subscribe(
        (response: any) => {
          if (response.HasError) {
            this.toaster.error('Error', response.ErrorMessage);
          } else {
            this.isUpdatelotcode = false;
            this.toaster.success('Updated successfully');
            const id = { PU_ID: data.PU_ID };
            this.getLotCode(id);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
    this.LotCodeForm.get('LotCodeFormat')?.reset();
  }

  onClickUpdateLotCode(element: any): void {
    // debugger;
    this.isUpdatelotcode = true;
    this.LotCodeForm.get('Id')?.setValue(element.Id);
    this.sfdcConfigurationService
      .GETLotcodeMachineMapping({ Id: element.Id })
      .subscribe(
        (response: any) => {
          // this.LotCodeForm.get('IsLotCodeAuto')?.setValue(response.LotcodeMachineList[0].IsLotCodeAuto);
          // tslint:disable-next-line:max-line-length
          this.LotCodeForm.get('IsLotCodeAuto')?.setValue(
            response.LotcodeMachineList[0].IsLotCodeAuto.toString()
          );
          this.LotCodeForm.get('LotCodeFormat')?.setValue(
            response.LotcodeMachineList[0].Name
          );
          if (response.HasError) {
            this.toaster.error('Error', response.ErrorMessage);
          } else {
            // this.LotCodeForm.get('IsLotCodeAuto')?.setValue(response.LotcodeMachineList[0].IsLotCodeAuto);
            // this.LotCodeForm.get('LotCodeFormat')?.setValue(response.LotcodeMachineList[0].Name);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    this.LotCodeForm.get('LotCodeFormat')?.reset();
  }

  onClickDeleteLotCode(element: any): void {
    this.sfdcConfigurationService.DeleteLotCode(element.Id).subscribe(
      (data: any) => {
        const key = {
          PU_ID: this.LotCodeForm.get('PU_ID')?.value,
        };
        this.getLotCode(key);
        this.toaster.success('Deleted successfully');
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onChangeFetchLotCode(): void {
    this.isUpdatelotcode = false;
    const id = {
      PU_ID: this.LotCodeForm.get('PU_ID')?.value,
    };
    this.getLotCode(id);
  }

  // product options
  getProductOutput(data: any): void {
    this.dropDownProp = false;
    data.DataType = this.productOutputForm.get('DataType')?.value;
    this.sfdcConfigurationService.GETBatchCheckListType(data).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.isUpdateProductOutput = false;
        if (data.BatchCheckListList[0]?.DataType === 'dropdown') {
          this.dropDownProp = true;
        }
        this.productOutputList = new MatTableDataSource<any>(
          data.BatchCheckListList
        );
        this.getPaginator(this.productOutputList, 2);
      },
      (error: any) => {
        this.spinner.hide();

        this.toaster.error('Error', error.message);
      }
    );
  }

  DropDownPopup(element: any): void {
    this.Id = element.Id;
    this.dialog.open(this.displayDropdownModal, {
      height: '90%',
      width: '80%',
      disableClose: true,
    });
    this.GetDropDownBatchCheckList();
  }

  SubmitBatchdropdownListType(): void {
    const key = {
      Id: 0,
      Name: this.dropdownForm.value.name,
      BCLT_Id: this.Id,
    };
    this.sfdcConfigurationService
      .SubmitBatchdropdownListType(key)
      .subscribe((res: any) => {
        this.showPopupMsg(res.ErrorMessage);
        this.GetDropDownBatchCheckList();
        this.dropdownForm.get('name')?.reset();
      });
  }

  GetDropDownBatchCheckList(): void {
    const key = {
      Id: this.Id,
    };
    this.sfdcConfigurationService
      .GetDropDownBatchCheckList(key)
      .subscribe((res: any) => {
        this.dropdownDataSource = new MatTableDataSource<any>(res.masterList);
        this.getPaginator(this.dropdownDataSource, 6);
      });
  }

  DeleteDropdownCheckList(element: any): void {
    const key = {
      Id: element.TypeId,
    };
    this.sfdcConfigurationService
      .SubmitBatchdropdownListType(key)
      .subscribe((res: any) => {
        this.showPopupMsg(res.ErrorMessage);
        this.GetDropDownBatchCheckList();
      });
  }

  applyFilterForDropdown(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dropdownDataSource.filter = filterValue.trim().toLowerCase();
  }

  onClickSaveProductOutput(): void {
    if (!this.isUpdateProductOutput) {
      const data = {
        PU_ID: this.productOutputForm.get('PU_ID')?.value,
        DataType: this.productOutputForm.get('DataType')?.value,
        Name: this.productOutputForm.get('Name')?.value,
      };
      if (this.productOutputForm.invalid) {
        this.unitNotSelect(this.productOutputForm);
        return;
      }
      this.sfdcConfigurationService.SubmitBatchCheckListType(data).subscribe(
        (response: any) => {
          if (response.HasError) {
            this.toaster.error('Error', response.ErrorMessage);
          } else {
            const id = { PU_ID: data.PU_ID };
            this.getProductOutput(id);
            this.resetForm(this.productOutputForm, 'Name');
            this.toaster.success('Saved successfully');
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      const data = {
        PU_ID: this.productOutputForm.get('PU_ID')?.value,
        DataType: this.productOutputForm.get('DataType')?.value,
        Name: this.productOutputForm.get('Name')?.value,
        Id: this.productOutputForm.get('Id')?.value,
      };
      this.sfdcConfigurationService.updateBatchCheckListType(data).subscribe(
        (response: any) => {
          if (response.HasError) {
            this.toaster.error('Error', response.ErrorMessage);
          } else {
            this.isUpdateProductOutput = false;
            this.toaster.success('Updated successfully');
            const id = { PU_ID: data.PU_ID };
            this.getProductOutput(id);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
    this.productOutputForm.get('Name')?.reset();
  }

  onClickUpdateProductOutput(element: any): void {
    this.isUpdateProductOutput = true;
    this.productOutputForm.get('Id')?.setValue(element.Id);
    const dt = this.productOutputForm.get('DataType')?.value;

    this.sfdcConfigurationService
      .GETBatchCheckListType({ Id: element.Id, DataType: dt })
      .subscribe(
        (response: any) => {
          if (response.HasError) {
            this.toaster.error('Error', response.ErrorMessage);
          } else {
            this.productOutputForm
              .get('DataType')
              ?.setValue(response.BatchCheckListList[0].DataType);
            this.productOutputForm
              .get('Name')
              ?.setValue(response.BatchCheckListList[0].Name);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
  }

  onClickDeleteProductOutput(element: any): void {
    this.sfdcConfigurationService
      .DeleteBatchCheckListType(element.Id)
      .subscribe(
        (data: any) => {
          const key = {
            PU_ID: this.productOutputForm.get('PU_ID')?.value,
          };
          this.getProductOutput(key);
          this.toaster.success('Deleted successfully');
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
  }
  onChangeFetchProductOutput(): void {
    this.isUpdateProductOutput = false;
    const id = {
      PU_ID: this.productOutputForm.get('PU_ID')?.value,
    };
    this.getProductOutput(id);
  }

  // short stop map duration

  onClickSaveMapShortDuration(): void {
    const data = {
      PU_ID: this.mapShortStopDurationForm.get('PU_ID')?.value,
      SSD_ID: this.mapShortStopDurationForm.get('mapDuration')?.value,
    };
    if (this.mapShortStopDurationForm.invalid) {
      this.unitNotSelect(this.mapShortStopDurationForm);
      return;
    }
    // debugger;
    this.sfdcConfigurationService.SaveMapShortDuration(data).subscribe(
      (response: any) => {
        if (response.HasError) {
          this.toaster.error('Error', response.ErrorMessage);
        } else {
          // let id = { PU_ID: data.PU_ID,TapName:data.TN_ID}
          this.getMapShortDurationList();

          this.resetForm(this.mapShortStopDurationForm, 'mapDuration');
          this.toaster.success('Saved successfully');
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getMapShortDurationList(): void {
    this.spinner.show();

    const key = {
      PUId: this.mapShortStopDurationForm.get('PU_ID')?.value,
    };

    this.sfdcConfigurationService.getMapShortDurationList(key).subscribe(
      (data: any) => {
        this.spinner.hide();

        this.MapDurationList = new MatTableDataSource<any>(
          data.ShortStopsValueList
        );
        this.getPaginator(this.MapDurationList, 5); // already 3
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onClickDeleteMapDuration(element: any): void {
    this.sfdcConfigurationService.DeleteMapDuration(element.Id).subscribe(
      (data: any) => {
        this.getMapShortDurationList();
        this.toaster.success('Deleted successfully');
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  // short stop map fault

  GetMapFault(): void {
    const key = {
      PUId: this.shortMapDefaultForm.get('PU_ID')?.value,
    };
    this.sfdcConfigurationService.GetMapFault(key).subscribe(
      (data: any) => {
        this.lotcode = data.FaultList;
        // this.TapeNameDDLForm.get('TN_ID')?.setValue(this.TapeNameDDL[0].Id);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onClickSaveMapFault(): void {
    const data = {
      PU_ID: this.shortMapDefaultForm.get('PU_ID')?.value,
      Fault_ID: this.shortMapDefaultForm.get('mapDefault')?.value,
    };
    if (this.shortMapDefaultForm.invalid) {
      this.unitNotSelect(this.shortMapDefaultForm);
      return;
    }
    // debugger;
    this.sfdcConfigurationService.SaveMapFault(data).subscribe(
      (response: any) => {
        if (response.HasError) {
          this.toaster.error('Error', response.ErrorMessage);
        } else {
          // let id = { PU_ID: data.PU_ID,TapName:data.TN_ID}
          this.getMapFaultList();

          this.resetForm(this.shortMapDefaultForm, 'mapDefault');
          this.toaster.success('Saved successfully');
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getMapFaultList(): void {
    this.spinner.show();
    const key = {
      PUId: this.shortMapDefaultForm.get('PU_ID')?.value,
    };
    debugger;
    this.sfdcConfigurationService.getMapFaultList(key).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.MapFaultList = new MatTableDataSource<any>(
          data.GetShortStopsFault
        );
        this.getPaginator(this.MapFaultList, 4); // already 3
      },
      (error: any) => {
        this.spinner.hide();

        this.toaster.error('Error', error.message);
      }
    );
  }

  onClickDeleteMapFault(element: any): void {
    this.sfdcConfigurationService.DeleteMapFault(element.Id).subscribe(
      (data: any) => {
        this.getMapFaultList();
        this.toaster.success('Deleted successfully');
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  // Waste HtmlLable

  getHtmlLable(): void {
    this.spinner.show();
    const data = {
      PU_ID: this.TapeNameDDLForm.get('PU_ID')?.value,
      TapName: this.TapeNameDDLForm.get('TN_ID')?.value,
    };
    this.sfdcConfigurationService.GETHtmlLable(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.isUpdateWaste = false;
        this.TapeNameDDLList = new MatTableDataSource<any>(res.HtmlLabelList);
        this.getPaginator(this.TapeNameDDLList, 3);
      },
      (error: any) => {
        this.spinner.hide();

        this.toaster.error('Error', error.message);
      }
    );
  }

  onClickSaveHtmlLable(): void {
    if (!this.isUpdateWaste) {
      const data = {
        PU_ID: this.TapeNameDDLForm.get('PU_ID')?.value,
        Name: this.TapeNameDDLForm.get('Name')?.value,
        Result: this.TapeNameDDLForm.get('Result')?.value,
        UoM: this.TapeNameDDLForm.get('UoM')?.value,
        TN_ID: this.TapeNameDDLForm.get('TN_ID')?.value,
      };
      if (this.TapeNameDDLForm.invalid) {
        this.unitNotSelect(this.TapeNameDDLForm);
        return;
      }
      this.sfdcConfigurationService.SubmitHtmlLable(data).subscribe(
        (response: any) => {
          if (response.HasError) {
            this.toaster.error('Error', response.ErrorMessage);
          } else {
            const id = { PU_ID: data.PU_ID, TapName: data.TN_ID };
            this.getHtmlLable();
            this.resetForm(this.TapeNameDDLForm, 'Name');
            this.resetForm(this.TapeNameDDLForm, 'Result');
            this.resetForm(this.TapeNameDDLForm, 'UoM');
            this.toaster.success('Saved successfully');
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      const data = {
        PU_ID: this.TapeNameDDLForm.get('PU_ID')?.value,
        Name: this.TapeNameDDLForm.get('Name')?.value,
        Result: this.TapeNameDDLForm.get('Result')?.value,
        UoM: this.TapeNameDDLForm.get('UoM')?.value,
        TN_ID: this.TapeNameDDLForm.get('TN_ID')?.value,
        Id: this.TapeNameDDLForm.get('Id')?.value,
      };
      this.sfdcConfigurationService.UpdateHtmlLable(data).subscribe(
        (response: any) => {
          if (response.HasError) {
            this.toaster.error('Error', response.ErrorMessage);
          } else {
            this.isUpdateProductOutput = false;
            this.toaster.success('Updated successfully');
            // const id = { PU_ID: data.PU_ID, TapName: data.TN_ID };
            this.getHtmlLable();
            this.resetForm(this.TapeNameDDLForm, 'Name');
            this.resetForm(this.TapeNameDDLForm, 'Result');
            this.resetForm(this.TapeNameDDLForm, 'UoM');
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  onClickUpdateHtmlLable(element: any): void {
    // debugger;
    this.isUpdateWaste = true;
    this.TapeNameDDLForm.get('Id')?.setValue(element.Id);
    const key = {
      Id: element.Id,
      TapName: this.TapeNameDDLForm.get('TN_ID')?.value,
    };
    this.sfdcConfigurationService.GETHtmlLable(key).subscribe(
      (response: any) => {
        if (response.HasError) {
          this.toaster.error('Error', response.ErrorMessage);
        } else {
          this.TapeNameDDLForm.get('Name')?.setValue(
            response.HtmlLabelList[0].Name
          );
          this.TapeNameDDLForm.get('Result')?.setValue(
            response.HtmlLabelList[0].Result
          );
          this.TapeNameDDLForm.get('UoM')?.setValue(
            response.HtmlLabelList[0].UoM
          );
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onClickDeleteHtmlLable(element: any): void {
    this.sfdcConfigurationService.DeleteHtmlLable(element.Id).subscribe(
      (data: any) => {
        const key = {
          PU_ID: this.TapeNameDDLForm.get('PU_ID')?.value,
          TapName: this.TapeNameDDLForm.get('TN_ID')?.value,
        };
        this.getProductOutput(key);
        this.getHtmlLable();
        this.toaster.success('Deleted successfully');
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onChangeFetchHtmlLable(element: any): void {
    this.isUpdateProductOutput = false;
    this.getHtmlLable();
  }

  // Icon
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  // menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        // localStorage.setItem('GetMenuAccess', this.permisson);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  // common
  getPaginator(dataSource: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataSource.paginator = this.paginator.toArray()[index];
      dataSource.sort = this.sort.toArray()[index];
    }, 1000);
  }

  unitNotSelect(form: FormGroup): void {
    for (const el in form.controls) {
      if (form.controls[el].errors && el === 'PU_ID') {
        this.toaster.error('Error', 'Unit is required');
        this.spinner.hide();
        return;
      }
    }
  }

  resetForm(form: FormGroup, value: any): void {
    form.get(value)?.setValue('');
  }
}
