<div >
  <div class="machine-parameter-content">
    <div class="content-top-box">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a
            class="breadcrumb-link"
            [routerLink]="breadcrumItem.Url"
            [id]="breadcrumItem.id"
            (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
            >{{breadcrumItem.text}}</a
          >
        </li>
      </ul>
      <div class="tab-content-top-box">
        <h1 class="main-title">
          Non-Conformance Report
        </h1>
      </div>
    </div>
  
    <div class="wrapper-box wrapper-box-shadow grid-container">
      <div>
        <ngx-spinner
          bdColor="rgba(0, 0, 0, 0.1)"
          template=" <img src='assets/images/spinner.gif' />"
        ></ngx-spinner>
        <form [formGroup]="NCRDataForm">
          <div class="content-top-row">
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 20%"
            >
              <mat-label class="placeholder-title">Select Group</mat-label>
              <mat-select
                formControlName="groupList"
                panelClass="testClass"
                name="Group"
                (selectionChange)="onChangeGroup($event)"
              >
                <mat-option
                  *ngFor="let Group of GroupList"
                  [value]="Group.TypeId"
                >
                  {{ Group.TypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 20%"
            >
              <mat-label class="placeholder-title">Select Plant</mat-label>
              <mat-select
                panelClass="testClass"
                name="Plant"
                formControlName="plant"
                (selectionChange)="onChangePlant($event)"
                required
              >
                <mat-option *ngFor="let plant of Plants" [value]="plant.DeptId">
                  {{ plant.DeptDesc }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 20%"
            >
              <mat-label>Select Line</mat-label>
              <mat-select
                panelClass="testClass"
                name="Line"
                formControlName="line"
                (selectionChange)="onChangeLine($event)"
                required
              >
                <mat-option [value]="0">Other</mat-option>
                <mat-option [value]="-1"> All </mat-option>
                <mat-option *ngFor="let line of Lines" [value]="line.PL_Id">
                  {{ line.PL_Desc }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 20%"
            >
              <mat-label class="placeholder-title">Select Machine</mat-label>
              <mat-select
                panelClass="testClass"
                name="Machine"
                formControlName="machine"
                (selectionChange)="onChangeMachine($event)"
              >
                <mat-option *ngIf="!lineOtherSelected" [value]="-1">ALL</mat-option>
                <mat-option
                  *ngIf="lineOtherSelected"
                  [value]="NCRDataForm.value.machine"
                  >Other</mat-option
                >
                <div *ngIf="!lineOtherSelected">
                  <mat-option
                    *ngFor="let machine of Machines"
                    [value]="machine.PU_Id"
                  >
                    {{ machine.PU_Desc }}
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
            <button
              mat-mini-fab
              mat-button
              color="accent"
              *ngIf="IsWrite"
              (click)="openModal()"
              pTooltip="Add New NCR"
              tooltipPosition="top"
              style="margin-left: 10px; margin-top: 10px"
              [disabled]="f.line.value == -1 || f.machine.value == -1"
            >
              <i class="fa fa-plus" style="color: #fff"></i>
            </button>
          </div>
          <div class="content-top-row">
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 20%"
            >
              <mat-label>NCR Catagory</mat-label>
              <mat-select
                [disabled]="disabledColumns"
                formControlName="ncrcategory"
                (selectionChange)="onChangeOption($event)"
              >
                <mat-option *ngFor="let cat of ncrCategories" [value]="cat.id">{{
                  cat.name
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="inputField" style="width: 20%; margin-left: 25px">
              <nz-date-picker
                class="calender-input"
                style="margin-left: 0px !important; width: 100%"
                id="startDate"
                formControlName="start"
                nzFormat="dd/MM/yyyy"
                [nzInputReadOnly]="true"
              ></nz-date-picker>
              <span class="floating-text">Start Date</span>
            </div>
  
            <div class="inputField" style="width: 20%; margin-left: 25px">
              <nz-date-picker
                class="calender-input"
                style="margin-left: 0px !important; width: 100%"
                id="endDate"
                formControlName="end"
                nzFormat="dd/MM/yyyy"
                [nzInputReadOnly]="true"
              ></nz-date-picker>
              <span class="floating-text">End Date</span>
            </div>
            <button
              mat-mini-fab
              mat-button
              class="search-icon-btn"
              (click)="onSearchClick()"
              pTooltip="Filter"
              tooltipPosition="top"
              style="margin-left: 20px; margin-top: 10px"
            >
              <i class="fa fa-search" style="color: #fff"></i>
            </button>
            <div
              class="example-button-row"
              style="display: inline-flex; margin-top: 10px"
            >
              <button
                mat-mini-fab
                mat-button
                (click)="csvExport()"
                pTooltip="CSV Export"
                tooltipPosition="top"
                style="margin-left: 20px"
              >
                <img src="assets/images/csv-file-format.png" />
              </button>
              <button
                mat-mini-fab
                mat-button
                (click)="excelExport()"
                pTooltip="Excel Export"
                tooltipPosition="top"
                style="margin-left: 20px"
              >
                <i
                  class="fa fa-file-excel-o"
                  style="color: #fff"
                  aria-hidden="true"
                ></i>
              </button>
              <button mat-raised-button color="warn" [disabled]="bulkNcrIdsForDelete.length == 0" style="margin-left: 10px"
                (click)="deleteMultipleNcrs()">
                Delete Selected NCR's
              </button>
            </div>
          </div>
        </form>
      </div>
  
      <ag-grid-angular
        style="width: 100%; height: 25vw; margin-top: 20px"
        class="ag-theme-alpine"
        [columnDefs]="columnDefs"
        (gridReady)="onGridReady($event)"
        [rowData]="dataArray"
        [gridOptions]="gridOptions"
        (cellClicked)="cellClicked($event)"
        (cellClicked)="imageZoomModal($event)"
        (cellValueChanged)="onCellValueChanged($event)"
        rowSelection="multiple"
        [icons]="icons"
        [pagination]="true"
        [getContextMenuItems]="getContextMenuItems"
        [paginationPageSize]="paginationPageSize"
        (firstDataRendered)="onFirstDataRendered($event)"
        [suppressRowClickSelection]="true"
        (selectionChanged)="rowSelectionForProductionOutput($event)"
      >
      </ag-grid-angular>
    </div>
  
    <ng-template #displayRoleModal>
      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.8)"
        template=" <img src='assets/images/spinner.gif' />"
      ></ngx-spinner>
  
      <form [formGroup]="AddNCRDataForm">
        <div mat-dialog-title>
          <div class="d-flex justify-content-between align-items-center">
            <h2>{{ !isUpdateNcr ? "Add New NCR" : "Update NCR" }}</h2>
  
            <button
              mat-mini-fab
              mat-button
              (click)="closeModal()"
              color="warn"
              title="close"
            >
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
          </div>
        </div>
  
        <mat-dialog-content>
          <div class="content-row-wrap">
            <div class="inputField" style="width: 22%; margin-left: 25px;margin-top: 17px;">
              <nz-date-picker
                class="calender-input"
                style="margin-left: 0px !important; width: 100%"
                formControlName="incidentDate"
                nzFormat="yyyy-MM-dd"
                [nzInputReadOnly]="true"
                (ngModelChange)="getBaseDetailsForManualNCR(false)"
                required
              ></nz-date-picker>
              <span class="floating-text" style="left: -8px;">Date of Incident *</span>
            </div>
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 46%; background-color: #f2f2f2;"
            >
              <mat-label>Problem Description</mat-label>
              <textarea
                matInput
                placeholder="Enter Problem Desc"
                formControlName="qdesc"
                required cdkTextareaAutosize
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3"
              ></textarea>
            </mat-form-field>
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="height: 50px;"
            >
              <mat-label>Type of NCR</mat-label>
              <mat-select
                [disabled]="disabledColumns"
                name="type"
                formControlName="type"
              >
                <mat-option
                  *ngFor="let type of NcrTypes"
                  [value]="type.ncrTypeId"
                >
                  {{ type.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Shift</mat-label>
              <mat-select panelClass="testClass" formControlName="shift" name="Shift" required (selectionChange)="getBaseDetailsForManualNCR(true)">
                <mat-option *ngFor="let s of shiftListForManualEntry" [value]="s.TypeId">{{s.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>
    
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Crew</mat-label>
              <mat-select panelClass="testClass" formControlName="crew" name="Crew" required>
                <mat-option *ngFor="let s of crewListForManualEntry" [value]="s.TypeId">{{s.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <mat-label>NCR Catagory</mat-label>
              <mat-select
                [disabled]="disabledColumns"
                name="process"
                formControlName="process"
                required
                (selectionChange)="onChangeProduct($event)"
              >
                <mat-option [value]="'Process'">Process</mat-option>
                <mat-option [value]="'Product'">Product</mat-option>
                <mat-option [value]="'Material'">Material</mat-option>
                <mat-option [value]="'Quality'">Quality</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <mat-label>Cause of Incident</mat-label>
              <mat-select
                name="causeOfIncident"
                formControlName="causeOfIncident"
                required
              >
                <mat-option [value]="'Human'">Human</mat-option>
                <mat-option [value]="'Equipment'">Equipment</mat-option>
                <mat-option [value]="'Procedure'">Procedure</mat-option>
                <mat-option [value]="'System'">System</mat-option>
              </mat-select>
            </mat-form-field>
            <ng-container>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 46%; background-color: #f2f2f2;"
              >
                <mat-label>Incident Description</mat-label>
                <textarea
                  required
                  matInput
                  placeholder="Enter Incident Desc"
                  formControlName="processOrder"  cdkTextareaAutosize
                  cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3"
                ></textarea>
              </mat-form-field>
            </ng-container>
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 46%; background-color: #f2f2f2;"
            >
              <mat-label>Explain Cause of Incident</mat-label>
              <textarea
                matInput
                placeholder="Enter Explanation"
                formControlName="itemDesc"
                required cdkTextareaAutosize
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3"
              ></textarea>
            </mat-form-field>
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 46%; background-color: #f2f2f2;"
            >
              <mat-label>Corrective Action Taken</mat-label>
              <textarea
                required
                matInput
                placeholder="Enter Action Taken"
                formControlName="desc" cdkTextareaAutosize
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3"
              ></textarea>
            </mat-form-field>
            <mat-form-field
              style="width: 46%; background-color: #f2f2f2;"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <mat-label>Incurred Losses</mat-label>
              <textarea
                matInput
                placeholder="Enter Losses"
                formControlName="manualOperationName"
                required cdkTextareaAutosize
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3"
              ></textarea>
            </mat-form-field>
            <mat-form-field
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <mat-label>Area</mat-label>
              <mat-select
                placeholder="Chose Area"
                formControlName="area"
                name="area"
              >
                <mat-option
                  *ngFor="let area of qualityArea"
                  [value]="area.areaId"
                >
                  {{ area.areaName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="inputField" style="width: 22%; margin-left: 25px;margin-top: 17px;">
              <nz-date-picker
                class="calender-input"
                style="margin-left: 0px !important; width: 100%"
                formControlName="startDateTime"
                nzFormat="yyyy-MM-dd HH:mm"
                [nzInputReadOnly]="true"
                nzShowTime
                required
              ></nz-date-picker>
              <span class="floating-text" style="left: -8px;">Start Date Time *</span>
            </div>
            <div class="inputField" style="width: 22%; margin-left: 25px;margin-top: 17px;">
              <nz-date-picker
                class="calender-input"
                style="margin-left: 0px !important; width: 100%"
                formControlName="endDateTime"
                nzFormat="yyyy-MM-dd HH:mm"
                [nzInputReadOnly]="true"
                nzShowTime
                required
              ></nz-date-picker>
              <span class="floating-text" style="left: -8px;">End Date Time *</span>
            </div>
            <mat-form-field
              [ngStyle]="{ visibility: !isUpdateNcr ? 'visible' : 'hidden' }"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <ngx-mat-file-input
                #myInput
                formControlName="image"
                placeholder="Add NCR Files"
                [accept]="
                  'image/x-png,image/gif,image/jpeg,application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                "
                (change)="onFileChange($event)"
                multiple
              >
                <mat-icon ngxMatFileInputIcon>folder</mat-icon>
              </ngx-mat-file-input>
            </mat-form-field>
          </div>
          <div class="d-flex justify-content-end" style="margin-bottom: 10px">
            <button
              type="submit"
              (click)="onClickSaveNcr()"
              *ngIf="!isUpdateNcr && IsWrite"
              [class.spinner]="loading"
              [disabled]="!AddNCRDataForm.valid || loading"
              mat-mini-fab
              mat-button
              color="primary"
              matTooltip="Save"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
            >
              <i class="fa fa-floppy-o" style="color: #fff"></i>
            </button>
  
            <button
              (click)="onClickSaveNcr()"
              [class.spinner]="loading"
              [disabled]="!AddNCRDataForm.valid || loading"
              type="submit"
              *ngIf="isUpdateNcr && IsWrite"
              mat-mini-fab
              mat-button
              color="primary"
              matTooltip="Update"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
            >
              <i class="fa fa-pencil" style="color: #fff"></i>
            </button>
          </div>
        </mat-dialog-content>
      </form>
    </ng-template>
  </div>
  
  <ng-template #imageZoomModel>
    <div style="display: flex; justify-content: flex-end; margin-bottom: 20px">
      <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
        (click)="closePopUpModal()"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
    <img
      [src]="
        currentImagePath !== 'null'
          ? currentImagePath
          : 'assets/images/no-image.png'
      "
      style="height: 98%; width: 100%; overflow: hidden"
    />
  </ng-template>
  
  <ng-template #ncrRemarksModal>
    <form [formGroup]="remarksForm">
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>
            Add Remarks
          </h2>
  
          <button
            mat-mini-fab
            mat-button
            mat-dialog-close
            color="warn"
            *ngIf="IsWrite"
            title="close"
            (click)="closePopUpModal()"
          >
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>
  
      <mat-dialog-content>
        <mat-form-field
          appearance="fill"
          class="field-fill inputs"
          style="width: 450px"
        >
          <mat-label>Remarks</mat-label>
          <textarea matInput formControlName="remarks"></textarea>
        </mat-form-field>
      </mat-dialog-content>
  
      <div mat-dialog-actions style="float: right; margin-top: 10px">
        <button
          type="submit"
          *ngIf="IsWrite"
          (click)="saveNotes(params)"
          mat-mini-fab
          mat-button
          color="primary"
          matTooltip="Save"
          matTooltipClass="custom-tooltip"
          matTooltipPosition="above"
        >
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
      </div>
    </form>
  </ng-template>
  
  <ng-template #capaActionHistoryModel>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>
          Implement CAPA
        </h2>
        <button
          mat-mini-fab
          mat-button
          mat-dialog-close
          color="warn"
          title="close"
          (click)="closePopUpModal()"
        >
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <mat-dialog-content>
      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.8)"
        template=" <img src='assets/images/spinner.gif' />"
      ></ngx-spinner>
  
      <div class="content-row-flex-both-end">
        <table
          mat-table
          [dataSource]="ncrActionHistoryDataSource"
          class="basic-table plant-model-table"
          matSort
        >
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">
                Description
              </p>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.actionDesc }}</td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">
                Date
              </p>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.actionDate | date: "y/MM/dd" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">
                Owner
              </p>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.TeamLead }}
            </td>
          </ng-container>
          <!-- <ng-container matColumnDef="tools">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">
                {{ langObj ? langObj?.Tools : ("Tools" | translate) }}
              </p>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.toolName }}</td>
          </ng-container> -->
  
          <!-- <ng-container matColumnDef="capaData">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">
                {{ langObj ? langObj?.CapaDate : ("CapaDate" | translate) }}
              </p>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field
                appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <mat-label>{{
                  langObj ? langObj?.Date : ("Date" | translate)
                }}</mat-label>
                <input
                  matInput
                  [matDatepicker]="startDate"
                  placeholder="Start Date"
                  (dateInput)="OnChangeDate($event, element)"
                  readonly
                  [value]="element.implementCapaDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
              </mat-form-field>
            </td>
          </ng-container> -->
  
          <ng-container matColumnDef="isChecked">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">
                Is Checked
              </p>
            </th>
            <td mat-cell *matCellDef="let element">
              <input
                type="checkbox"
                (change)="ncrActionCheckbox($event, element)"
                [checked]="element?.isChecked"
              />
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="capaActionColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: capaActionColumns"></tr>
          <tr class="mat-row" *matNoDataRow style="text-align: center">
            <td class="mat-cell" colspan="7">
              No Data Found
            </td>
          </tr>
        </table>
      </div>
      <mat-paginator
        class="basic-paginataor"
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
      ></mat-paginator>
  
      <div mat-dialog-actions style="float: right; margin-top: 10px">
        <button
          type="submit"
          (click)="ncractionexecutionUpdate()"
          class="icon-btn"
          *ngIf="hideButton == true && IsWrite"
          matTooltip="Save Implement CAPA"
          matTooltipClass="custom-tooltip"
          matTooltipPosition="above"
        >
          <img src="assets/images/save.png" alt="" width="20px" height="20px" />
        </button>
      </div>
    </mat-dialog-content>
  </ng-template>
  
  <ng-template #displayRoleModal2>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>
          Parameters Specification
        </h2>
  
        <button
          mat-mini-fab
          mat-button
          mat-dialog-close
          color="warn"
          title="close"
        >
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      template=" <img src='assets/images/spinner.gif' />"
    ></ngx-spinner>
  
    <div class="wrapper-box wrapper-box-shadow grid-container">
      <ag-grid-angular
        style="width: 100%; height: 25vw"
        class="ag-theme-alpine"
        [rowData]="rowData1"
        [columnDefs]="columnDefs1"
        (gridReady)="onGridReadyAction($event)"
        [gridOptions]="gridOptionsAction"
      >
      </ag-grid-angular>
    </div>
  </ng-template>
  
  <ng-template #previewModal>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>NCR Files</h2>
        <h2>({{ ncrIdDesc }})</h2>
        <button
          mat-mini-fab
          mat-button
          mat-dialog-close
          color="warn"
          title="close"
        >
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <mat-dialog-content>
      <form [formGroup]="filesForm" novalidate>
        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <ngx-mat-file-input
            #myInput
            formControlName="file"
            placeholder="Add NCR Files"
            [accept]="
              'image/x-png,image/gif,image/jpeg,application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            "
            (change)="saveNCRFiles($event)"
            multiple
          >
            <mat-icon ngxMatFileInputIcon>folder</mat-icon>
          </ngx-mat-file-input>
        </mat-form-field>
      </form>
  
      <div class="mat-elevation-z8">
        <table mat-table class="preview_table" [dataSource]="previewerData">
          <ng-container matColumnDef="Desc">
            <th mat-header-cell *matHeaderCellDef>
              File Description
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.fileDescription }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef>
              Download
            </th>
            <td mat-cell *matCellDef="let element">
              <a target="_blank" href="{{ imageUrl + element.fileDescription }}"><i class="fa fa-download"></i></a>
            </td>
          </ng-container>
          <ng-container matColumnDef="Remove">
            <th mat-header-cell *matHeaderCellDef>
              <button mat-icon-button>
                <!-- (click)="removeAll()" matTooltip="Remove all" -->
                <mat-icon>clear_all</mat-icon>
              </button>
            </th>
            <td mat-cell *matCellDef="let element; let rem = index">
              <button
                mat-icon-button
                matTooltip="Remove this File"
                (click)="removeFile(element)"
              >
                <!-- (click)="removeAt(i)" -->
                <mat-icon>clear</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="previewerColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: previewerColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="3">
              No Data Found
            </td>
          </tr>
        </table>
        <mat-paginator
          #paginator="matPaginator"
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </mat-dialog-content>
  </ng-template>

  <div class="dialog_scrim" *ngIf="openMenu"></div>

  <button style="position: fixed; right: 1rem; bottom: 1rem; z-index: 100;" mat-fab color="primary"
    (click)="openMenu = !openMenu;" class="menu-button" [class.mat-elevation-z2]="!isOver"
    [class.mat-elevation-z8]="isOver" (mouseover)="isOver = true" (mouseleave)="isOver = false">
    <mat-icon style="color: #fff;" *ngIf="!openMenu">menu</mat-icon>
    <mat-icon style="color: #fff;" *ngIf="openMenu">close</mat-icon>
  </button>

  <div style="display: flex; flex-direction: column; position: fixed; right: 1.5rem;bottom: 9rem; z-index: 100;">
    <!-- menu button -->
    <button mat-mini-fab class="action-button mat-elevation-z8" pTooltip="Sub-Header/Result" *ngIf="openMenu"
      color="basic" (click)="configModal()">
      <i class="material-icons">check_box</i>
    </button>

    <button mat-mini-fab class="action-button mat-elevation-z8" pTooltip="Headers/Footers" *ngIf="openMenu"
      color="basic" (click)="OpenHeaderConfiguration()">
      <i class="material-icons">list</i>
    </button>

    <button mat-mini-fab class="action-button mat-elevation-z8" pTooltip="Report Header Name" *ngIf="openMenu"
      color="basic" (click)="GetNcrReportHeaderName()">
      <i class="material-icons">border_outer</i>
    </button>
  </div>

  <ng-template #ncrHeaderModal>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h1 mat-dialog-title>NCR Report Header Name</h1>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <form [formGroup]="reportHeaderForm">
      <mat-dialog-content style="overflow: hidden;">
        <div class="content-top-row">
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Name</mat-label>
            <input matInput formControlName="name" placeholder="Enter Name" />
          </mat-form-field>
          <button *ngIf="IsWrite" mat-mini-fab mat-button color="accent" (click)="ResetHeaderName()" matTooltip="Reset"
            matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;">
            <mat-icon>refresh</mat-icon>
          </button>
          <button *ngIf="chfformMode && IsWrite" type="submit" mat-mini-fab mat-button color="primary"
            (click)="saveNCRReportName()" [disabled]="!reportHeaderForm.valid" matTooltip="Save"
            matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </mat-dialog-content>
    </form>
  </ng-template>

  <ng-template #HeadersModal>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h1 mat-dialog-title>Configure NCR Header/Footer</h1>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <form [formGroup]="ConfigurationForm">
      <mat-dialog-content style="overflow: hidden;">
        <div class="content-top-row">
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Name</mat-label>
            <input matInput formControlName="name" placeholder="Enter Name" />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Value</mat-label>
            <input matInput formControlName="value" placeholder="Enter Value" />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Type</mat-label>
            <mat-select panelClass="testClass" name="type" formControlName="type">
              <mat-option *ngFor="let t of configTypes" [value]="t.Id">
                {{ t.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Order</mat-label>
            <input matInput type="number" formControlName="order" placeholder="Enter Order" min="0"
              oninput="validity.valid||(value='');" />
          </mat-form-field>
          <button *ngIf="IsWrite" mat-mini-fab mat-button color="accent" (click)="ResetCheckListConfiguration()"
            matTooltip="Reset" matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;">
            <mat-icon>refresh</mat-icon>
          </button>
          <button *ngIf="chfformMode && IsWrite" type="submit" mat-mini-fab mat-button color="primary"
            (click)="SaveCheckListConfiguration()" [disabled]="!ConfigurationForm.valid" matTooltip="Save"
            matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;">
            <mat-icon>save</mat-icon>
          </button>
          <button *ngIf="!chfformMode && IsWrite" type="submit" mat-mini-fab mat-button color="primary"
            (click)="UpdateCheckListConfiguration()" [disabled]="!ConfigurationForm.valid" matTooltip="Update"
            matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;">
            <mat-icon>edit</mat-icon>
          </button>

        </div>
      </mat-dialog-content>
    </form>
    <ag-grid-angular #agGrid style="width: 100%; margin-top: 20px;" class="ag-theme-alpine" [rowData]="rowData2"
      [columnDefs]="columnDefs2" (gridReady)="onGridReady1($event)" (cellClicked)="cellClickedForConfiguration($event)"
      [gridOptions]="gridOptionsHeaderFooter" [frameworkComponents]="frameworkComponents" [pagination]="true"
      [paginationPageSize]="10">
    </ag-grid-angular>
  </ng-template>

  <ng-template #configurationModal>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h3>Configuration</h3>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <!-- Accordian -->
    <mat-accordion>
      <!---------- Sub Headers Grid ------------->
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="acc1" class="disable_ripple_acc1">
          <mat-panel-title>
            Sub-Headers Columns
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ag-grid-angular #agGrid style="width: 100%; margin-top: 20px;" class="ag-theme-alpine"
          [rowData]="subHeaderDataSource" [columnDefs]="subHeaderColumnDefs" (gridReady)="onGridReadySubHeader($event)"
          (selectionChanged)="cellClickedForSubHeader($event)" [gridOptions]="subHeaderGridOptions" [pagination]="true"
          [suppressRowClickSelection]="true" [rowSelection]="rowSelection" [defaultColDef]="defaultColDef"
          [paginationPageSize]="10" (firstDataRendered)="onFirstDataRenderedForSubHeader($event)">
        </ag-grid-angular>
        <div class="btn-end">
          <button mat-mini-fab mat-button color="primary" pTooltip="Save" tooltipPosition="top"
            (click)="submitConfiguration(1)">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
      <h2 class="headings">Result Columns</h2>
      <!---------- 6W2h Grid ------------->
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="acc2" class="disable_ripple_acc2">
          <mat-panel-title>
            6W2H
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ag-grid-angular #agGrid style="width: 100%; margin-top: 20px;" class="ag-theme-alpine"
          [rowData]="w2HDataSource" [columnDefs]="w2HColumnDefs" (gridReady)="onGridReady6W2h($event)"
          (selectionChanged)="cellClickedForSubHeader($event)" [gridOptions]="W2hGridOptions" [pagination]="true"
          [suppressRowClickSelection]="true" [rowSelection]="rowSelection" [defaultColDef]="defaultColDef"
          [paginationPageSize]="10" (firstDataRendered)="onFirstDataRenderedForSubHeader($event)">
        </ag-grid-angular>
        <div class="btn-end">
          <button mat-mini-fab mat-button color="primary" pTooltip="Save" tooltipPosition="top"
            (click)="submitConfiguration(2)">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
      <!---------- Fishbone Grid ------------->
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="acc3" class="disable_ripple_acc3">
          <mat-panel-title>
            Fishbone
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ag-grid-angular #agGrid style="width: 100%; margin-top: 20px;" class="ag-theme-alpine"
          [rowData]="fishBoneDataSource" [columnDefs]="fishBoneColumnDefs" (gridReady)="onGridReadyFishbone($event)"
          (selectionChanged)="cellClickedForSubHeader($event)" [gridOptions]="fishBoneGridOptions" [pagination]="true"
          [suppressRowClickSelection]="true" [rowSelection]="rowSelection" [defaultColDef]="defaultColDef"
          [paginationPageSize]="10" (firstDataRendered)="onFirstDataRenderedForSubHeader($event)">
        </ag-grid-angular>
        <div class="btn-end">
          <button mat-mini-fab mat-button color="primary" pTooltip="Save" tooltipPosition="top"
            (click)="submitConfiguration(3)">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
      <!---------- Cause & Effect Grid ------------->
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="acc4" class="disable_ripple_acc4">
          <mat-panel-title>
            Cause & Effect
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ag-grid-angular #agGrid style="width: 100%; margin-top: 20px;" class="ag-theme-alpine"
          [rowData]="causeEffectDataSource" [columnDefs]="causeEffectColumnDefs"
          (gridReady)="onGridReadyCauseEffect($event)" (selectionChanged)="cellClickedForSubHeader($event)"
          [gridOptions]="causeEffectGridOptions" [pagination]="true" [suppressRowClickSelection]="true"
          [rowSelection]="rowSelection" [defaultColDef]="defaultColDef" [paginationPageSize]="10"
          (firstDataRendered)="onFirstDataRenderedForSubHeader($event)">
        </ag-grid-angular>
        <div class="btn-end">
          <button mat-mini-fab mat-button color="primary" pTooltip="Save" tooltipPosition="top"
            (click)="submitConfiguration(4)">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
      <!---------- 5 Why Grid ------------->
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="acc5" class="disable_ripple_acc5">
          <mat-panel-title>
            5 Why
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ag-grid-angular #agGrid style="width: 100%; margin-top: 20px;" class="ag-theme-alpine"
          [rowData]="why5DataSource" [columnDefs]="why5ColumnDefs" (gridReady)="onGridReady5Why($event)"
          (selectionChanged)="cellClickedForSubHeader($event)" [gridOptions]="why5GridOptions" [pagination]="true"
          [suppressRowClickSelection]="true" [rowSelection]="rowSelection" [defaultColDef]="defaultColDef"
          [paginationPageSize]="10" (firstDataRendered)="onFirstDataRenderedForSubHeader($event)">
        </ag-grid-angular>
        <div class="btn-end">
          <button mat-mini-fab mat-button color="primary" pTooltip="Save" tooltipPosition="top"
            (click)="submitConfiguration(5)">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
      <!---------- Action PLan Grid ------------->
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="acc6" class="disable_ripple_acc6">
          <mat-panel-title>
            5 Why Action Plan
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ag-grid-angular #agGrid style="width: 100%; margin-top: 20px;" class="ag-theme-alpine"
          [rowData]="actionPlanDataSource" [columnDefs]="actionPlanColumnDefs"
          (gridReady)="onGridReadyActionPlan($event)" (selectionChanged)="cellClickedForSubHeader($event)"
          [gridOptions]="actionPlanGridOptions" [pagination]="true" [suppressRowClickSelection]="true"
          [rowSelection]="rowSelection" [defaultColDef]="defaultColDef" [paginationPageSize]="10"
          (firstDataRendered)="onFirstDataRenderedForSubHeader($event)">
        </ag-grid-angular>
        <div class="btn-end">
          <button mat-mini-fab mat-button color="primary" pTooltip="Save" tooltipPosition="top"
            (click)="submitConfiguration(6)">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

  </ng-template>
  <div id="reportContainer"></div>
  </div>
  <!-- <div style="display: none;">
    <div id="NcrCreatemailTemplate">
      <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tr>
           <td align="center" bgcolor="#f2f2f2">
              <table align="center" border="0 
                 " cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                 <tr>
                    <td align="center" valign="top" style="font-size: 0; padding: 20px;" bgcolor="#0f0fd1">
                       <table align="left" border="
                          0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                          <tr>
                             <td align="left" valign="top"
                                style="                       font-family: Open Sans, Hel
                                vetica, Arial, sans-serif;                       font-size: 36px;                       font-weight: 800;                       line-height: 48px;                     "
                                class="mobile-center">
                                <h1
                                   style="                
                                   font-size: 20px;                         font-weight: 800;                         margin: 0;                         color: #ffffff;                       ">
                                   O3 Smart Manufacturing
                                </h1>
                             </td>
                             <td align="left" valign="top"> <img
                                src="https://res.cloudinary.com/durdmvneg/image/upload/v1629181792/logo-full_ku1lp4.png"
                                width="170" style="display: block; border: 0px;" /> </td>
                          </tr>
                       </table>
                    </td>
                 </tr>
                 <tr>
                    <td align="center" style="padding: 10px 35px 20px 35px; background-color: #ffffff;"
                       bgcolor="#ffffff">
                       <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                          style="max-width: 800px;">
                          <tr>
                             <td align="left"
                                style="                       font-family: Open Sans, Helvetica, Arial, sans-serif;                       font-size: 16
                                px;                       font-weight: 400;                       line-height: 24px;                       padding-top: 10px;                       padding-bottom: 20px;                     ">
                                <h2
                                   style="               
                                   font-size: 25px;                         font-weight: 700;                         line-height: 30px;                         color: #ff0015;                         margin: 0;                       ">
                                </h2>
                                <p><strong>Dear O3 User,</strong></p>
                                <p> NCR Has been Created Below are the Details Please take appropriate Action
                                </p>
                                <p style="color:red"><strong>Next Step approve/reject</strong></p>
                                &&&&
                                <div *ngFor="let row of emailData">
                                  <p *ngIf="row.key == 'NCR #'">{{row.key}} : <strong>{{row.value}}</strong></p>
                                  <p *ngIf="row.key != 'NCR #'">{{row.key}} : {{row.value}}</p>
                                </div>
                                <p><strong>Regards,</strong></p>
                                <p>O3 Support</p>
                                <p style="font-size:10px">*Note- This is system generated email please do not
                                   reply.
                                </p>
                                <p></p>
                             </td>
                          </tr>
                       </table>
                    </td>
                 </tr>
                 <tr>
                    <td bgcolor="#f4f4f4" height="100" align="center" style="padding: 0;"> </td>
                 </tr>
              </table>
           </td>
        </tr>
     </table>
    </div>
  </div> -->