import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OpmsGlassService } from 'src/app/services/opms-glass.service';
import { OpmsHotEndShiftReportService } from 'src/app/services/opms-hot-end-shift-report.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
import 'ag-grid-enterprise';

@Component({
  selector: 'app-opms-shift-reports',
  templateUrl: './opms-shift-reports.component.html',
  styleUrls: ['./opms-shift-reports.component.scss']
})
export class OpmsShiftReportsComponent implements OnInit {

  private subscriptions = new Subscription();
  public breadcrumList: any;
  opmsShiftReportForm!: FormGroup;
  panelOpenState = false;
  shiftList: any;
  crewList: any;
  defaultColDef =  {
    resizable: true,
    sortable: true,
    filter: true,
    width: 100,
    wrapText: true,
    autoHeight: true,
  }
  defaultColDefHotEndEvents =  {
    resizable: true,
    sortable: true,
    filter: true,
    wrapText: true,
    autoHeight: true,
  }
  gridOptions: any = {
    frameworkComponents: {
      customLoadingOverlay: CustomLoadingOverlay,
    },
  };
  loadingOverlayComponent: any;
  columnDefsShiftPerformance = [
    {headerName: 'Date', field: 'date', minWidth: 110},
    {headerName: 'Shift', field: 'shift', maxWidth: 90},
    {headerName: 'Crew', field: 'crew', maxWidth: 85},
    {headerName: 'Yield (%)', field: 'yield'},
    {headerName: 'Trim Loss (%)', field: 'trimLoss'},
    {headerName: 'Defect Loss (%)', field: 'defectLoss'},
    {headerName: 'Lehr Loss (%)', field: 'lehrLoss'},
    {headerName: 'Sampling Loss (%)', field: 'samplingLoss', minWidth: 110},
    {headerName: 'Float Loss (%)', field: 'floatLoss'},
    {headerName: 'Profile Loss (%)', field: 'profileLoss'},
    {headerName: 'Change Over Loss (%)', field: 'changeOverLoss', minWidth: 110},
    {headerName: 'Preferential Loss (%)', field: 'preffLoss'},
    {headerName: 'Cutting Loss (%)', field: 'cuttingLoss'},
    {headerName: 'Equipment Loss (%)', field: 'equipmentLoss', minWidth: 130},
    {headerName: 'Warehouse Loss (%)', field: 'wareHouseLoss', minWidth: 110},
  ]
  rowShiftPerformance: Array<{}> = [];
  gridApiShiftPerformance: any
  columnDefsTinBathQualityParameters = [
    {headerName: 'Date', field: 'date', minWidth: 110},
    {headerName: 'Shift', field: 'shift', maxWidth: 90},
    {headerName: 'Crew', field: 'crew', maxWidth: 85},
    {headerName: 'Thick ness (mm)', field: 'thickness'},
    {headerName: 'Avg. Thick (mm)', field: 'avgThickness',valueFormatter: this.valueFormatter},
    {headerName: 'TSV', field: 'tsv',valueFormatter: this.valueFormatter},
    {headerName: 'Ribbon Width (mm)', field: 'grossWidth',valueFormatter: this.valueFormatter},
    {headerName: 'Usable Width (mm)', field: 'usableWidth',valueFormatter: this.valueFormatter},
    {headerName: 'Net Cut Width (mm)', field: 'netCutWidth',valueFormatter: this.valueFormatter},
    {headerName: 'Lehr Speed', field: 'lehrSpeed',valueFormatter: this.valueFormatter},
    {headerName: 'Zebra LE (Deg)', field: 'zebraLE',valueFormatter: this.valueFormatter},
    {headerName: 'Zebra LC (Deg)', field: 'zebraLC',valueFormatter: this.valueFormatter},
    {headerName: 'Zebra RC (Deg)', field: 'zebraRC',valueFormatter: this.valueFormatter},
    {headerName: 'Zebra RE (Deg)', field: 'zebraRE',valueFormatter: this.valueFormatter},
    {headerName: 'Tin Ball', field: 'tinBall',editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: ['','YES', 'NO'],
      },
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
    {headerName: 'Seed count (Nos/kg)', field: 'seedCount',valueFormatter: this.valueFormatter},
    {headerName: 'Edge distortion L', field: 'edgeDistortionL', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Edge distortion R', field: 'edgeDistortionR', minWidth: 110,valueFormatter: this.valueFormatter},
  ]
  rowTinBathQualityParameters: Array<{}> = [];
  gridApiTinBathQualityParameters: any
  columnDefsFurnaceCombustion = [
    {headerName: 'Date', field: 'date', minWidth: 110},
    {headerName: 'Shift', field: 'shift', maxWidth: 90},
    {headerName: 'Crew', field: 'crew', maxWidth: 85},
    {headerName: 'Quality Pull (TPD)', field: 'qualityPull',valueFormatter: this.valueFormatter},
    {headerName: 'Cullet (%)', field: 'cullet', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
    {headerName: 'No of Batches(Nos)', field: 'noOfBatches', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Batch Charger Moisture(%)', field: 'batchChargerMoisture', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 125
    },
    {headerName: 'Batch Charger Speed(%)', field: 'batchChargerSpeed', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 110
    },
    {headerName: 'Max Batch Line', field: 'maxBatchLine',editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: ['','2C', '3','3A','3B','3C','4','4A','4B','4C'],
      },
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
    {headerName: 'Foam Line', field: 'foamLine',editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: ['','5','5A','5B','5C','6','6A','6B','6C','NO FOAM'],
        cellEditorPopup: true,
      },
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
    {headerName: 'Port 1 GasFlow (Nos)', field: 'port1GasFlow', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Port 2 GasFlow (Nos)', field: 'port2GasFlow', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Port 3 GasFlow (Nos)', field: 'port3GasFlow', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Port 4 GasFlow (Nos)', field: 'port4GasFlow', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Port 5 GasFlow (Nos)', field: 'port5GasFlow', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Port 6 GasFlow (Nos)', field: 'port6GasFlow', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Port 7 GasFlow (Nos)', field: 'port7GasFlow', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'NGFlow meter (Nm3/Hr)', field: 'nGFlowMeter', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'TOTAL NG PORT FLOW(Nm3/Hr)', field: 'totalNGPortFlow', minWidth: 145,valueFormatter: this.valueFormatter},
    {headerName: 'CV meter Reading (KWh/m3)', field: 'cVmeterReading', minWidth: 120,valueFormatter: this.valueFormatter},
    {headerName: 'Daily NG Consumption (Nm3/Day)', field: 'dailyNGConsumption', minWidth: 135,valueFormatter: this.valueFormatter},
    {headerName: 'Calculated CV (Kcal/m3)', field: 'calculatedCV', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Total Energy (Kcal/m3)', field: 'totalEnergy', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Port 1 Distri- bution (%)', field: 'port1Distribution', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Port 2 Distri- bution (%)', field: 'port2Distribution', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Port 3 Distri- bution (%)', field: 'port3Distribution', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Port 4 Distri- bution (%)', field: 'port4Distribution', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Port 5 Distri- bution (%)', field: 'port5Distribution', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Port 6 Distri- bution (%)', field: 'port6Distribution', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Port 7 Distri- bution (%)', field: 'port7Distribution', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'RHS Port 1 ExcessAir (%)', field: 'rHSPort1ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'RHS Port 2 ExcessAir (%)', field: 'rHSPort2ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'RHS Port 3 ExcessAir (%)', field: 'rHSPort3ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120  
    },
    {headerName: 'RHS Port 4 ExcessAir (%)', field: 'rHSPort4ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'RHS Port 5 ExcessAir (%)', field: 'rHSPort5ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'RHS Port 6 ExcessAir (%)', field: 'rHSPort6ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'RHS Port 7 ExcessAir (%)', field: 'rHSPort7ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'LHS Port 1 ExcessAir (%)', field: 'lHSPort1ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'LHS Port 2 ExcessAir (%)', field: 'lHSPort2ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'LHS Port 3 ExcessAir (%)', field: 'lHSPort3ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'LHS Port 4 ExcessAir (%)', field: 'lHSPort4ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'LHS Port 5 ExcessAir (%)', field: 'lHSPort5ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'LHS Port 6 ExcessAir (%)', field: 'lHSPort6ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'LHS Port 7 ExcessAir (%)', field: 'lHSPort7ExcessAir',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120 
    },
    {headerName: 'NOx (mg/m3)', field: 'NOX',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
    {headerName: 'Dust (mg/m3)', field: 'dust',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 110 
    },
    {headerName: 'SOx (mg/m3)', field: 'SOX',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 110 
    },
  ]
  rowFurnaceCombustion: Array<{}> = [];
  gridApiFurnaceCombustion: any
  columnDefsFurnaceTemperatures = [
    {headerName: 'Date', field: 'date', minWidth: 110},
    {headerName: 'Shift', field: 'shift', maxWidth: 90},
    {headerName: 'Crew', field: 'crew', maxWidth: 85},
    {headerName: 'Hot Spot Optical  Port-4 LHS (DegC)', field: 'hotSpotOpticalPort4LHS', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 140
    },
    {headerName: 'Hot Spot Optical Port-4 RHS (DegC)', field: 'hotSpotOpticalPort4RHS', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 140
    },
    {headerName: 'Refiner Optical (DegC)', field: 'refinerOptical', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      },
    },
    {headerName: 'Melter bottom -1 (DegC)', field: 'melterbottom1', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Melter bottom -4 (DegC)', field: 'melterbottom4', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Melter bottom -5 (DegC)', field: 'melterbottom5', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Melter bottom -6 (DegC)', field: 'melterbottom6', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Melter bottom -8 (DegC)', field: 'melterbottom8', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Waist btm 1 (DegC)', field: 'waistBtm1',valueFormatter: this.valueFormatter},
    {headerName: 'Waist btm 2 (DegC)', field: 'waistBtm2',valueFormatter: this.valueFormatter},
    {headerName: 'WE Bottom - 1 (DegC)', field: 'wEBottom1', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'WE Bottom - 2 (DegC)', field: 'wEBottom2', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'WE Exit Left (DegC)', field: 'wEExitLeft',valueFormatter: this.valueFormatter},
    {headerName: 'WE Exit Right (DegC)', field: 'wEExitRight',valueFormatter: this.valueFormatter},
    {headerName: 'Chimney Bottom Temp(DegC)', field: 'chimneyBottomTemp', minWidth: 127,valueFormatter: this.valueFormatter},
    {headerName: 'Flue chimney Temp(DegC)', field: 'fluechimneyTemp', minWidth: 127,valueFormatter: this.valueFormatter},
    {headerName: 'Melter Crown-1(DegC)', field: 'melterCrown1',valueFormatter: this.valueFormatter},
    {headerName: 'Melter Crown-2(DegC)', field: 'melterCrown2',valueFormatter: this.valueFormatter},
    {headerName: 'Melter Crown-3(DegC)', field: 'melterCrown3',valueFormatter: this.valueFormatter},
    {headerName: 'Melter Crown-4(DegC)', field: 'melterCrown4',valueFormatter: this.valueFormatter},
    {headerName: 'Melter Crown-5(DegC)', field: 'melterCrown5',valueFormatter: this.valueFormatter},
    {headerName: 'Melter Crown-6(DegC)', field: 'melterCrown6',valueFormatter: this.valueFormatter},
    {headerName: 'Melter Crown-7(DegC)', field: 'melterCrown7',valueFormatter: this.valueFormatter},
    {headerName: 'Melter Crown-8(DegC)', field: 'melterCrown8',valueFormatter: this.valueFormatter},
    {headerName: 'Melter Crown-9(DegC)', field: 'melterCrown9',valueFormatter: this.valueFormatter},
    {headerName: 'WE Crown - 1(DegC)', field: 'wECrown1',valueFormatter: this.valueFormatter},
    {headerName: 'WE Crown - 2(DegC)', field: 'wECrown2',valueFormatter: this.valueFormatter},
    {headerName: 'Back Wall Pressure (pa)', field: 'backWallPressure', minWidth: 115,valueFormatter: this.valueFormatter},
    {headerName: 'Melter Pressure (pa)', field: 'melterPressure', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Working End Pressure (pa)', field: 'workingEndPressure', minWidth: 130,valueFormatter: this.valueFormatter},
    {headerName: 'Dil Air (DegC)', field: 'dilAir', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Canal Optical (DegC)', field: 'canalOptical',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 110
    },
    {headerName: 'Canal Temperature (DegC)', field: 'canalTemperature',editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 130
    },
  ]
  rowFurnaceTemperatures: Array<{}> = [];
  gridApiFurnaceTemperatures: any
  columnDefsHotEndEquipmets = [
    {headerName: 'Date', field: 'date', minWidth: 110},
    {headerName: 'Shift', field: 'shift', maxWidth: 90},
    {headerName: 'Crew', field: 'crew', maxWidth: 85},
    {headerName: 'Waist Cooler # (No)', field: 'waistCooler', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
    {headerName: 'Combustion Fan (No)', field: 'combustionFan', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 110
    },
    {headerName: 'Dilution Fan (No)', field: 'dilutionFan', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
    {headerName: 'Dungs NG Flow (Nm3/Hr)', field: 'dungsNGFlow', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 110
    },
    {headerName: 'Dungs Air Presure (mbar)', field: 'dungsAirPresure', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 115
    },
    {headerName: 'Stirrer RPM (No)', field: 'stirrerRPM', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      },
    },
    {headerName: 'Venting Speed (%)', field: 'ventingSpeed', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
    {headerName: 'HEAD COOLER - 1', field: 'headCooler1',editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['','0', '+1000','+500','-500','-1000','-1500','-2000','OUT'],
      },
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 110
    },
    {headerName: 'HEAD COOLER - 2', field: 'headCooler2',editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['','0', '+1000','+500','-500','-1000','-1500','-2000','OUT'],
      },
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 110
    },
    {headerName: 'HEAD COOLER - 3', field: 'headCooler3',editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['','0', '+1000','+500','-500','-1000','-1500','-2000','OUT'],
      },
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 110
    },
    {headerName: 'HEAD COOLER - 4', field: 'headCooler4',editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['','0', '+1000','+500','-500','-1000','-1500','-2000','OUT'],
      },
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 110
    },
    {headerName: 'SHOULDER COOLER', field: 'shoulderCooler',editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['','IN','OUT'],
      },
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 120
    },
    {headerName: 'BAY#12 COOLER', field: 'bay12Cooler',editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['','IN','OUT'],
      },
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 110
    },
    {headerName: 'No of End coolers IN (Nos)', field: 'noOfEndCoolersIN', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 115
    },
    {headerName: 'Linear Motor Speed LHS(V)', field: 'linearMotorSpeedLHS', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 130
    },
    {headerName: 'Linear Motor Speed RHS(V)', field: 'linearMotorSpeedRHS', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, minWidth: 130
    },
    {headerName: 'Exit Curtain-1', field: 'exitCurtain1', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
    {headerName: 'Exit Curtain-2', field: 'exitCurtain2', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
    {headerName: 'Exit Curtain-3', field: 'exitCurtain3', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
    {headerName: 'Exit Curtain-4', field: 'exitCurtain4', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }, 
    },
  ]
  rowHotEndEquipmets: Array<{}> = [];
  gridApiHotEndEquipmets: any
  columnDefsTinBathProcessParameters = [
    {headerName: 'Date', field: 'date', minWidth: 110},
    {headerName: 'Shift', field: 'shift', maxWidth: 90},
    {headerName: 'Crew', field: 'crew', maxWidth: 85},
    {headerName: 'Front Tweel counter(mm)', field: 'frontTweelCounter', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      },  minWidth: 131
    },
    {headerName: 'Back Tweel counter(mm)', field: 'backTweelCounter', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      },  minWidth: 131
    },
    {headerName: 'Top roll-1 Speed (cm/min)', field: 'topRoll1Speed', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-2 Speed (cm/min)', field: 'topRoll2Speed', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-3 Speed (cm/min)', field: 'topRoll3Speed', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-4 Speed (cm/min)', field: 'topRoll4Speed', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-5 Speed (cm/min)', field: 'topRoll5Speed', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-6 Speed (cm/min)', field: 'topRoll6Speed', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-7 Speed (cm/min)', field: 'topRoll7Speed', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-1 Angles (Deg)', field: 'topRoll1Angles', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-2 Angles (Deg)', field: 'topRoll2Angles', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-3 Angles (Deg)', field: 'topRoll3Angles', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-4 Angles (Deg)', field: 'topRoll4Angles', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-5 Angles (Deg)', field: 'topRoll5Angles', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-6 Angles (Deg)', field: 'topRoll6Angles', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'Top roll-7 Angles (Deg)', field: 'topRoll7Angles', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'TIN TEMP BAY 0   LHS(DegC)', field: 'tinTempBay0LHS', minWidth: 118,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 2   LHS(DegC)', field: 'tinTempBay2LHS', minWidth: 118,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 6   LHS(DegC)', field: 'tinTempBay6LHS', minWidth: 118,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 11   LHS(DegC)', field: 'tinTempBay11LHS', minWidth: 118,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 14   LHS(DegC)', field: 'tinTempBay14LHS', minWidth: 118,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 18   LHS(DegC)', field: 'tinTempBay18LHS', minWidth: 118,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 21   LHS(DegC)', field: 'tinTempBay21LHS', minWidth: 118,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 0   RHS(DegC)', field: 'tinTempBay0RHS', minWidth: 119,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 2   RHS(DegC)', field: 'tinTempBay2RHS', minWidth: 119,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 6   RHS(DegC)', field: 'tinTempBay6RHS', minWidth: 119,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 11   RHS(DegC)', field: 'tinTempBay11RHS', minWidth: 119,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 14   RHS(DegC)', field: 'tinTempBay14RHS', minWidth: 119,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 18   RHS(DegC)', field: 'tinTempBay18RHS', minWidth: 119,valueFormatter: this.valueFormatter},
    {headerName: 'TIN TEMP BAY 21   RHS(DegC)', field: 'tinTempBay21RHS', minWidth: 119,valueFormatter: this.valueFormatter},
    {headerName: 'RHS EXIT Temperature (DegC)', field: 'rHSExitTemperature', minWidth: 130,valueFormatter: this.valueFormatter},
    {headerName: 'Centre EXIT Temperature (DegC)', field: 'centreExitTemperature', minWidth: 130,valueFormatter: this.valueFormatter},
    {headerName: 'LHS EXIT Temperature (DegC)', field: 'lHSExitTemperature', minWidth: 130,valueFormatter: this.valueFormatter},
    {headerName: 'Shoulder Temperature (DegC)', field: 'shoulderTemperature', minWidth: 130,valueFormatter: this.valueFormatter},
    {headerName: 'L.O.R Temperature (DegC)', field: 'lORTemperature', minWidth: 130,valueFormatter: this.valueFormatter},
    {headerName: 'Bay 0 Pressure (Pa)', field: 'bay0Pressure', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Shoulder Pressure (Pa)', field: 'shoulderPressure', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Exit Pressure (Pa)', field: 'exitPressure', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'H2 Flow (Nm3/Hr)', field: 'h2Flow', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'N2 Flow (Nm3/Hr)', field: 'n2Flow', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'SO2-N2  flow (Nm3/Hr)', field: 'sO2N2Flow', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
    {headerName: 'SO2 flow (LPM)', field: 'sO2Flow', editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      }
    },
  ]
  rowTinBathProcessParameters: Array<{}> = [];
  gridApiTinBathProcessParameters: any
  columnDefsTinBathEnergy = [
    {headerName: 'Date', field: 'date', minWidth: 110},
    {headerName: 'Shift', field: 'shift', maxWidth: 90},
    {headerName: 'Crew', field: 'crew', maxWidth: 85},
    {headerName: 'Heat Power Zone# 6(Kw)', field: 'heatPowerZone6', minWidth: 122,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 5(Kw)', field: 'heatPowerZone5', minWidth: 122,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 7(Kw)', field: 'heatPowerZone7', minWidth: 122,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 8(Kw)', field: 'heatPowerZone8', minWidth: 122,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 9(Kw)', field: 'heatPowerZone9', minWidth: 122,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 10(Kw)', field: 'heatPowerZone10', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 11(Kw)', field: 'heatPowerZone11', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 12(Kw)', field: 'heatPowerZone12', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 13(Kw)', field: 'heatPowerZone13', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 14(Kw)', field: 'heatPowerZone14', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 15(Kw)', field: 'heatPowerZone15', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 16(Kw)', field: 'heatPowerZone16', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 17(Kw)', field: 'heatPowerZone17', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 18(Kw)', field: 'heatPowerZone18', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 19(Kw)', field: 'heatPowerZone19', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 20(Kw)', field: 'heatPowerZone20', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 21(Kw)', field: 'heatPowerZone21', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Heat Power Zone# 31(Kw)', field: 'heatPowerZone31', minWidth: 124,valueFormatter: this.valueFormatter},
    {headerName: 'Total Power (kw)', field: 'totalPower',valueFormatter: this.valueFormatter},
    {headerName: 'Exit heat Left(kw)', field: 'exitHeatLeft', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Exit heat Center(kw)', field: 'exitHeatCenter', minWidth: 118,valueFormatter: this.valueFormatter},
    {headerName: 'Exit heat Right(kw)', field: 'exitHeatRight', minWidth: 110,valueFormatter: this.valueFormatter},
    {headerName: 'Exit heat Total(kw)', field: 'exitHeatTotal', minWidth: 110, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      },
    },
    {headerName: 'Power Wide Section(kw)', field: 'powerWideSection', minWidth: 125, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      },
    },
    {headerName: 'Power Narrow Section(kw)', field: 'powerNarrowSection', minWidth: 125, editable: true,
      cellStyle: {
        color: 'black',
        'background-color': '#e5dede',
      },
    },
  ]
  rowTinBathEnergy: Array<{}> = [];
  gridApiTinBathEnergy: any
  columnDefsHotEndEvents = [
    {headerName: 'Date', field: 'date', maxWidth: 110},
    {headerName: 'Shift', field: 'shift', maxWidth: 100},
    {headerName: 'Crew', field: 'crew', maxWidth: 100},
    {headerName: 'Time', field: 'time', maxWidth: 100},
    {headerName: 'Hot End Area', field: 'HotEndArea', maxWidth: 120},
    {headerName: 'Events', field: 'event'},
  ]
  rowHotEndEvents: Array<{}> = [];
  gridApiHotEndEvents: any
  requestParams = {
    StartDate: '',
    EndDate: '',
    shift: '',
    crew: ''
  }
  constructor(
    private spinner: NgxSpinnerService,
    private usermanagementService: UserManagementService,
    private toaster: ToastrService,
    public router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private opmsGlassservice: OpmsGlassService,
    private opmsHotEndShiftReportService: OpmsHotEndShiftReportService
  ) 
  {
    this.loadingOverlayComponent = 'customLoadingOverlay';
  }

  ngOnInit(): void {
    this.loadBaseDetails();
    var firstDate = new Date((new Date()).setDate((new Date().getDate())-1));
    this.opmsShiftReportForm = this.fb.group({
      startDate: [this.datePipe.transform(firstDate, 'yyyy-MM-dd')],
      endDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      shift: [''],
      crew: [''],
    });
    this.getBreadcrumList();
  }

  loadBaseDetails(){
    const data = {
      UserId: JSON.parse(localStorage.user).UserId
     }
     this.opmsGlassservice.loadBaseDetails(data).subscribe(
       (response: any)=>{
        this.shiftList = response.ShiftList;
        this.crewList = response.CrewList;
        this.opmsShiftReportForm.get('shift')?.setValue("0");
        this.opmsShiftReportForm.get('crew')?.setValue("0");
        this.getReports();
       },
     )
  }
  getReports(){
    this.getFiltersParams();
    setTimeout(() => {
      this.getHotEndShiftPerformanceReport();
      this.getHotEndTinBathQualityParametersReport();
      this.getHotEndFurnaceCombustionReport();
      this.getHotEndFurnaceTemperaturesReport();
      this.getHotEndEquipmetsReport();
      this.getHotEndTinBathProcessParametersReport();
      this.getHotEndTinBathEnergyReport();
      this.getHotEndEventsReport();
    }, 1000);
  }
  getFiltersParams(){
    let shift: any = [];
    let crew: any = [];
    if (this.opmsShiftReportForm.get('shift')?.value == 0) {
      for (var i = 0; i < this.shiftList.length; i++) {
        shift.push(this.shiftList[i].TypeId);
      }
    } else {
      shift.push(this.opmsShiftReportForm.get('shift')?.value);
    }
    if (this.opmsShiftReportForm.get('crew')?.value == 0) {
      for (var i = 0; i < this.crewList.length; i++) {
        crew.push(this.crewList[i].TypeId);
      }
    } else {
      crew.push(this.opmsShiftReportForm.get('crew')?.value);
    }
    this.requestParams = {
      StartDate: this.opmsShiftReportForm.get('startDate')?.value,
      EndDate: this.opmsShiftReportForm.get('endDate')?.value,
      shift: shift.toString(),
      crew: crew.toString()
    }
  }
  getHotEndShiftPerformanceReport(){
    this.gridApiShiftPerformance.showLoadingOverlay();
    this.opmsHotEndShiftReportService.GetHotEndShiftPerformanceReport(this.requestParams).subscribe(
      (response: any) => {
        this.gridApiShiftPerformance.hideOverlay();
        this.rowShiftPerformance = [];
        if(!response.HasError){
          if(response.result.length){
            this.rowShiftPerformance = response.result;
          }
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.gridApiShiftPerformance.hideOverlay();
        this.rowShiftPerformance = [];
        this.toaster.error(error.error)
      }
    )
  }
  onGridReadyShiftPerformance(params: any): void {
    this.gridApiShiftPerformance = params.api;
  }
  getHotEndTinBathQualityParametersReport(){
    this.gridApiTinBathQualityParameters.showLoadingOverlay();
    this.opmsHotEndShiftReportService.GetHotEndTinBathQualityParametersReport(this.requestParams).subscribe(
      (response: any) => {
        this.gridApiTinBathQualityParameters.hideOverlay();
        this.rowTinBathQualityParameters = [];
        if(!response.HasError){
          if(response.result.length){
            this.rowTinBathQualityParameters = response.result;
          }
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.gridApiTinBathQualityParameters.hideOverlay();
        this.rowTinBathQualityParameters = [];
        this.toaster.error(error.error);
      }
    )
  }
  onGridReadyTinBathQualityParameters(params: any): void {
    this.gridApiTinBathQualityParameters = params.api;
  }
  onCellValueChangedTinBathQualityParameters(params: any){
    if(params.newValue !== params.oldValue && params.newValue !== null){
      if((params.newValue == '' && params.oldValue !== null) || params.newValue !== ''){
        this.gridApiTinBathQualityParameters.showLoadingOverlay();
        const saveRequestParams = {
          TransactionId: params.data.TinBathQualityId ? params.data.TinBathQualityId : 0,
          date: params.data.date,
          shift: params.data.ShiftTypeId,
          crew: params.data.CrewTypeId,
          thickness: params.data.thickness,
          tinBall: params.data.tinBall
        }
        this.opmsHotEndShiftReportService.SaveHotEndTinBathQualityParametersManualEntry(saveRequestParams).subscribe(
          (response: any) => {
            if(!response.HasError){
              if(response.result.length){
                this.toaster.success(response.result[0].Msg)
                this.getHotEndTinBathQualityParametersReport();
              }
            }else{
              this.toaster.error(response.result[0].Msg);
              this.getHotEndTinBathQualityParametersReport();
            }
          },
          (error: any) => {
            this.toaster.error(error.error);
            this.getHotEndTinBathQualityParametersReport();
          }
        )
      }
      else{
        const col = params.colDef.field;
        params.node.setDataValue(col,null);
      }
    }
  }
  getHotEndFurnaceCombustionReport(){
    this.gridApiFurnaceCombustion.showLoadingOverlay();
    this.opmsHotEndShiftReportService.GetHotEndFurnaceCombustionReport(this.requestParams).subscribe(
      (response: any) => {
        this.gridApiFurnaceCombustion.hideOverlay();
        this.rowFurnaceCombustion = [];
        if(!response.HasError){
          if(response.result.length){
            this.rowFurnaceCombustion = response.result;
          }
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.gridApiFurnaceCombustion.hideOverlay();
        this.rowFurnaceCombustion = [];
        this.toaster.error(error.error);
      }
    )
  }
  onGridReadyFurnaceCombustion(params: any): void {
    this.gridApiFurnaceCombustion = params.api;
  }
  onCellValueChangedFurnaceCombustion(params: any){
    if(params.newValue !== null && (params.newValue !== params.oldValue)){
      const col = params.colDef.field;
      if(col !== 'maxBatchLine' && col !== 'foamLine'){
        if(isNaN(params.newValue) || (params.newValue == '' && params.oldValue == null)){
          this.toaster.warning('Warning','Please Enter Valid Numeric Input');
          params.node.setDataValue(col,null);
          return;
        }
      }
      else if(params.newValue == '' && params.oldValue == null){
        params.node.setDataValue(col,null);
        return;
      }
      this.gridApiFurnaceCombustion.showLoadingOverlay();
      const saveRequestParams = {
        TransactionId: params.data.FurnaceCombustionId ? params.data.FurnaceCombustionId : 0,
        date: params.data.date,
        shift: params.data.ShiftTypeId,
        crew: params.data.CrewTypeId,
        cullet: params.data.cullet ? Number(params.data.cullet) : null,
        noOfBatches: params.data.noOfBatches ? Number(params.data.noOfBatches) : null,
        batchChargerMoisture: params.data.batchChargerMoisture ? Number(params.data.batchChargerMoisture) : null,
        batchChargerSpeed: params.data.batchChargerSpeed ? Number(params.data.batchChargerSpeed) : null,
        maxBatchLine: params.data.maxBatchLine,
        foamLine: params.data.foamLine,
        rHSPort1ExcessAir: params.data.rHSPort1ExcessAir ? Number(params.data.rHSPort1ExcessAir) : null,
        rHSPort2ExcessAir: params.data.rHSPort2ExcessAir ? Number(params.data.rHSPort2ExcessAir) : null,
        rHSPort3ExcessAir: params.data.rHSPort3ExcessAir ? Number(params.data.rHSPort3ExcessAir) : null,
        rHSPort4ExcessAir: params.data.rHSPort4ExcessAir ? Number(params.data.rHSPort4ExcessAir) : null,
        rHSPort5ExcessAir: params.data.rHSPort5ExcessAir ? Number(params.data.rHSPort5ExcessAir) : null,
        rHSPort6ExcessAir: params.data.rHSPort6ExcessAir ? Number(params.data.rHSPort6ExcessAir) : null,
        rHSPort7ExcessAir: params.data.rHSPort7ExcessAir ? Number(params.data.rHSPort7ExcessAir) : null,
        LHSPort1ExcessAir: params.data.lHSPort1ExcessAir ? Number(params.data.lHSPort1ExcessAir) : null,
        LHSPort2ExcessAir: params.data.lHSPort2ExcessAir ? Number(params.data.lHSPort2ExcessAir) : null,
        LHSPort3ExcessAir: params.data.lHSPort3ExcessAir ? Number(params.data.lHSPort3ExcessAir) : null,
        LHSPort4ExcessAir: params.data.lHSPort4ExcessAir ? Number(params.data.lHSPort4ExcessAir) : null,
        LHSPort5ExcessAir: params.data.lHSPort5ExcessAir ? Number(params.data.lHSPort5ExcessAir) : null,
        LHSPort6ExcessAir: params.data.lHSPort6ExcessAir ? Number(params.data.lHSPort6ExcessAir) : null,
        LHSPort7ExcessAir: params.data.lHSPort7ExcessAir ? Number(params.data.lHSPort7ExcessAir) : null,
        NOX: params.data.NOX ? Number(params.data.NOX) : null,
        dust: params.data.dust ? Number(params.data.dust) : null,
        SOX: params.data.SOX ? Number(params.data.SOX) : null
      }
      this.opmsHotEndShiftReportService.SaveHotEndFurnaceCombustionManualEntry(saveRequestParams).subscribe(
        (response: any) => {
          if(!response.HasError){
            if(response.result.length){
              this.toaster.success(response.result[0].Msg)
              this.getHotEndFurnaceCombustionReport();
            }
          }else{
            this.toaster.error(response.result[0].Msg);
            this.getHotEndFurnaceCombustionReport();
          }
        },
        (error: any) => {
          this.toaster.error(error.error);
          this.getHotEndFurnaceCombustionReport();
        }
      )
    }
  }
  getHotEndFurnaceTemperaturesReport(){
    this.gridApiFurnaceTemperatures.showLoadingOverlay();
    this.opmsHotEndShiftReportService.GetHotEndFurnaceTemperaturesReport(this.requestParams).subscribe(
      (response: any) => {
        this.gridApiFurnaceTemperatures.hideOverlay();
        this.rowFurnaceTemperatures = [];
        if(!response.HasError){
          if(response.result.length){
            this.rowFurnaceTemperatures = response.result;
          }
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.gridApiFurnaceTemperatures.hideOverlay();
        this.rowFurnaceTemperatures = [];
        this.toaster.error(error.error);
      }
    )
  }
  onGridReadyFurnaceTemperatures(params: any): void {
    this.gridApiFurnaceTemperatures = params.api;
  }
  onCellValueChangedFurnaceTemperatures(params: any){
    if(params.newValue !== undefined && (params.newValue !== params.oldValue)){
      const col = params.colDef.field;
      if(isNaN(params.newValue)){
        this.toaster.warning('Warning','Please Enter Valid Numeric Input');
        params.node.setDataValue(col,undefined);
        return;
      }
      this.gridApiFurnaceTemperatures.showLoadingOverlay();
      const saveRequestParams = {
        TransactionId: params.data.FurnaceTemperaturesId ? params.data.FurnaceTemperaturesId : 0,
        date: params.data.date,
        shift: params.data.ShiftTypeId,
        crew: params.data.CrewTypeId,
        hotSpotOpticalPort4LHS: params.data.hotSpotOpticalPort4LHS ? Number(params.data.hotSpotOpticalPort4LHS) : null,
        hotSpotOpticalPort4RHS: params.data.hotSpotOpticalPort4RHS ? Number(params.data.hotSpotOpticalPort4RHS) : null,
        refinerOptical: params.data.refinerOptical ? Number(params.data.refinerOptical) : null,
        canalOptical: params.data.canalOptical ? Number(params.data.canalOptical) : null,
        canalTemperature: params.data.canalTemperature ? Number(params.data.canalTemperature) : null,
      }
      this.opmsHotEndShiftReportService.SaveHotEndFurnaceTemperaturesManualEntry(saveRequestParams).subscribe(
        (response: any) => {
          if(!response.HasError){
            if(response.result.length){
              this.toaster.success(response.result[0].Msg);
              this.getHotEndFurnaceTemperaturesReport();
            }
          }else{
            this.toaster.error(response.result[0].Msg);
            this.getHotEndFurnaceTemperaturesReport();
          }
        },
        (error: any) => {
          this.toaster.error(error.error);
          this.getHotEndFurnaceTemperaturesReport();
        }
      )
    }
  }
  getHotEndEquipmetsReport(){
    this.gridApiHotEndEquipmets.showLoadingOverlay();
    this.opmsHotEndShiftReportService.GetHotEndEquipmetsReport(this.requestParams).subscribe(
      (response: any) => {
        this.gridApiHotEndEquipmets.hideOverlay();
        this.rowHotEndEquipmets = [];
        if(!response.HasError){
          if(response.result.length){
            this.rowHotEndEquipmets = response.result;
          }
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.gridApiHotEndEquipmets.hideOverlay();
        this.rowHotEndEquipmets = [];
        this.toaster.error(error.error);
      }
    )
  }
  onGridReadyHotEndEquipmets(params: any): void {
    this.gridApiHotEndEquipmets = params.api;
  }
  onCellValueChangedHotEndEquipmets(params: any){
    if(params.newValue !== null && (params.newValue !== params.oldValue)){
      const col = params.colDef.field;
      if(col !== 'headCooler1' && col !== 'headCooler2' 
        && col !== 'headCooler3' && col !== 'headCooler4'
        && col !== 'shoulderCooler' && col !== 'bay12Cooler'){
        if(isNaN(params.newValue)){
          this.toaster.warning('Warning','Please Enter Valid Numeric Input');
          params.node.setDataValue(col,null);
          return;
        }
      }
      else if(params.newValue == '' && params.oldValue == null){
        params.node.setDataValue(col,null);
        return;
      }
      this.gridApiHotEndEquipmets.showLoadingOverlay();
      const saveRequestParams = {
        TransactionId: params.data.HotEndEquipmetsId ? params.data.HotEndEquipmetsId : 0,
        date: params.data.date,
        shift: params.data.ShiftTypeId,
        crew: params.data.CrewTypeId,
        waistCooler: params.data.waistCooler ? Number(params.data.waistCooler) : null,
        combustionFan: params.data.combustionFan ? Number(params.data.combustionFan) : null,
        dilutionFan: params.data.dilutionFan ? Number(params.data.dilutionFan) : null,
        dungsNGFlow: params.data.dungsNGFlow ? Number(params.data.dungsNGFlow) : null,
        dungsAirPresure: params.data.dungsAirPresure ? Number(params.data.dungsAirPresure) : null,
        stirrerRPM: params.data.stirrerRPM ? Number(params.data.stirrerRPM) : null,
        ventingSpeed: params.data.ventingSpeed ? Number(params.data.ventingSpeed) : null,
        headCooler1: params.data.headCooler1,
        headCooler2: params.data.headCooler2,
        headCooler3: params.data.headCooler3,
        headCooler4: params.data.headCooler4,
        shoulderCooler: params.data.shoulderCooler,
        bay12Cooler: params.data.bay12Cooler,
        noOfEndCoolersIN: params.data.noOfEndCoolersIN ? Number(params.data.noOfEndCoolersIN) : null,
        linearMotorSpeedLHS: params.data.linearMotorSpeedLHS ? Number(params.data.linearMotorSpeedLHS) : null,
        linearMotorSpeedRHS: params.data.linearMotorSpeedRHS ? Number(params.data.linearMotorSpeedRHS) : null,
        exitCurtain1: params.data.exitCurtain1 ? Number(params.data.exitCurtain1) : null,
        exitCurtain2: params.data.exitCurtain2 ? Number(params.data.exitCurtain2) : null,
        exitCurtain3: params.data.exitCurtain3 ? Number(params.data.exitCurtain3) : null,
        exitCurtain4: params.data.exitCurtain4 ? Number(params.data.exitCurtain4) : null,
      }
      this.opmsHotEndShiftReportService.SaveHotEndEquipmetsManualEntry(saveRequestParams).subscribe(
        (response: any) => {
          if(!response.HasError){
            if(response.result.length){
              this.toaster.success(response.result[0].Msg);
              this.getHotEndEquipmetsReport();
            }
          }else{
            this.toaster.error(response.result[0].Msg);
            this.getHotEndEquipmetsReport();
          }
        },
        (error: any) => {
          this.toaster.error(error.error)
          this.getHotEndEquipmetsReport();
        }
      )
    }
  }
  getHotEndTinBathProcessParametersReport(){
    this.gridApiTinBathProcessParameters.showLoadingOverlay();
    this.opmsHotEndShiftReportService.GetHotEndTinBathProcessParametersReport(this.requestParams).subscribe(
      (response: any) => {
        this.gridApiTinBathProcessParameters.hideOverlay();
        this.rowTinBathProcessParameters = [];
        if(!response.HasError){
          if(response.result.length){
            this.rowTinBathProcessParameters = response.result;
          }
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.gridApiTinBathProcessParameters.hideOverlay();
        this.rowTinBathProcessParameters = [];
        this.toaster.error(error.error);
      }
    )
  }
  onGridReadyTinBathProcessParameters(params: any): void {
    this.gridApiTinBathProcessParameters = params.api;
  }
  onCellValueChangedTinBathProcessParameters(params: any){
    if(params.newValue !== undefined && (params.newValue != params.oldValue)){
      const col = params.colDef.field;
      if(isNaN(params.newValue)){
        this.toaster.warning('Warning','Please Enter Valid Numeric Input');
        params.node.setDataValue(col,undefined);
        return;
      }
      this.gridApiTinBathProcessParameters.showLoadingOverlay();
      const saveRequestParams = {
        TransactionId: params.data.HotEndTinBathProcessParametersId ? params.data.HotEndTinBathProcessParametersId : 0,
        date: params.data.date,
        shift: params.data.ShiftTypeId,
        crew: params.data.CrewTypeId,
        frontTweelCounter: params.data.frontTweelCounter ? Number(params.data.frontTweelCounter) : null,
        backTweelCounter: params.data.backTweelCounter ? Number(params.data.backTweelCounter) : null,
        topRoll1Speed: params.data.topRoll1Speed ? Number(params.data.topRoll1Speed) : null,
        topRoll2Speed: params.data.topRoll2Speed ? Number(params.data.topRoll2Speed) : null,
        topRoll3Speed: params.data.topRoll3Speed ? Number(params.data.topRoll3Speed) : null,
        topRoll4Speed: params.data.topRoll4Speed ? Number(params.data.topRoll4Speed) : null,
        topRoll5Speed: params.data.topRoll5Speed ? Number(params.data.topRoll5Speed) : null,
        topRoll6Speed: params.data.topRoll6Speed ? Number(params.data.topRoll6Speed) : null,
        topRoll7Speed: params.data.topRoll7Speed ? Number(params.data.topRoll7Speed) : null,
        topRoll1Angles: params.data.topRoll1Angles ? Number(params.data.topRoll1Angles) : null,
        topRoll2Angles: params.data.topRoll2Angles ? Number(params.data.topRoll2Angles) : null,
        topRoll3Angles: params.data.topRoll3Angles ? Number(params.data.topRoll3Angles) : null,
        topRoll4Angles: params.data.topRoll4Angles ? Number(params.data.topRoll4Angles) : null,
        topRoll5Angles: params.data.topRoll5Angles ? Number(params.data.topRoll5Angles) : null,
        topRoll6Angles: params.data.topRoll6Angles ? Number(params.data.topRoll6Angles) : null,
        topRoll7Angles: params.data.topRoll7Angles ? Number(params.data.topRoll7Angles) : null,
        sO2N2Flow: params.data.sO2N2Flow ? Number(params.data.sO2N2Flow) : null,
        sO2Flow: params.data.sO2Flow ? Number(params.data.sO2Flow) : null,
      }
      this.opmsHotEndShiftReportService.SaveHotEndTinBathProcessParametersManualEntry(saveRequestParams).subscribe(
        (response: any) => {
          if(!response.HasError){
            if(response.result.length){
              this.toaster.success(response.result[0].Msg)
            }
            this.getHotEndTinBathProcessParametersReport();
          }else{
            this.toaster.error(response.result[0].Msg);
            this.getHotEndTinBathProcessParametersReport();
          }
        },
        (error: any) => {
          this.toaster.error(error.error)
          this.getHotEndTinBathProcessParametersReport();
        }
      )
    }
  }
  getHotEndTinBathEnergyReport(){
    this.gridApiTinBathEnergy.showLoadingOverlay();
    this.opmsHotEndShiftReportService.GetHotEndTinBathEnergyReport(this.requestParams).subscribe(
      (response: any) => {
        this.gridApiTinBathEnergy.hideOverlay();
        this.rowTinBathEnergy = [];
        if(!response.HasError){
          if(response.result.length){
            this.rowTinBathEnergy = response.result;
          }
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.gridApiTinBathEnergy.hideOverlay();
        this.rowTinBathEnergy = [];
        this.toaster.error(error.error);
      }
    )
  }
  onGridReadyTinBathEnergy(params: any): void {
    this.gridApiTinBathEnergy = params.api;
  }
  onCellValueChangedTinBathEnergy(params: any){
    if(params.newValue !== undefined && (params.newValue !== params.oldValue)){
      const col = params.colDef.field;
      if(isNaN(params.newValue)){
        this.toaster.warning('Warning','Please Enter Valid Numeric Input');
        params.node.setDataValue(col,undefined);
        return;
      }
      this.gridApiTinBathEnergy.showLoadingOverlay();
      const saveRequestParams = {
        TransactionId: params.data.HotEndTinBathEnergyId ? params.data.HotEndTinBathEnergyId : 0,
        date: params.data.date,
        shift: params.data.ShiftTypeId,
        crew: params.data.CrewTypeId,
        exitHeatTotal: params.data.exitHeatTotal ? Number(params.data.exitHeatTotal) : null,
        powerWideSection: params.data.powerWideSection ? Number(params.data.powerWideSection) : null,
        powerNarrowSection: params.data.powerNarrowSection ? Number(params.data.powerNarrowSection) : null,
      }
      this.opmsHotEndShiftReportService.SaveHotEndTinBathEnergyManualEntry(saveRequestParams).subscribe(
        (response: any) => {
          if(!response.HasError){
            if(response.result.length){
              this.toaster.success(response.result[0].Msg);
              this.getHotEndTinBathEnergyReport();
            }
          }else{
            this.toaster.error(response.result[0].Msg);
            this.getHotEndTinBathEnergyReport();
          }
        },
        (error: any) => {
          this.toaster.error(error.error);
          this.getHotEndTinBathEnergyReport();
        }
      )
    }
  }
  getHotEndEventsReport(){
    this.gridApiHotEndEvents.showLoadingOverlay();
    this.opmsHotEndShiftReportService.GetHotEndEventsReport(this.requestParams).subscribe(
      (response: any) => {
        this.gridApiHotEndEvents.hideOverlay();
        this.rowHotEndEvents = [];
        if(!response.HasError){
          if(response.result.length){
            this.rowHotEndEvents = response.result;
          }
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.gridApiHotEndEvents.hideOverlay();
        this.rowHotEndEvents = [];
        this.toaster.error(error.error);
      }
    )
  }
  onGridReadyHotEndEvents(params: any){
    this.gridApiHotEndEvents = params.api;
    this.gridApiHotEndEvents.sizeColumnsToFit();
  }

  getBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    const breadCrumSub = this.usermanagementService
      .GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
          this.spinner.hide();
        }
      );
    this.subscriptions.add(breadCrumSub);
  }

  displayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  getMenuAccessForHotEnd(){
    this.opmsHotEndShiftReportService.GetMenuAccessForHotEnd().subscribe(
      (response: any) => {
        localStorage.setItem('O3RightMenuId', response.menuId);
        this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['opmsQuality/OpmsGlass']));
      },
      (error: any) => {
        this.toaster.error(error.error);
      }
    )
  }

  dateFormatter(params: any): any {
    if(params.value){
      return moment(new Date(params.value)).format('MM/DD/YYYY');
    }
  }

  valueFormatter(params: any){
    if(params.value == 0){
      return '';
    }
    else{
      return params.value;
    }
  }

}
