<ngx-spinner
  bdColor="rgba(0, 0, 0, 0)"
  template=" <img src='assets/images/spinner.gif' />"
>
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>
<div class="container">
  <form class="row" name="form" [formGroup]="form">
    <div style="width: 100%; display: flex; align-items: baseline">
      <div class="box">
        <div class="box-title">
          <h4>What <span>*</span></h4>
          <p>Describe the phenomenon. What Do You see?</p>
        </div>
        <div title="{{ sixwtwoh?.What }}">
          <mat-form-field class="w-100">
            <textarea
              (input)="concatProblemStatement()"
              matInput
              formControlName="what"
              [(ngModel)]="sixwtwoh.What"
              class="textarea-input"
            ></textarea>
            <mat-error>What is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="box">
        <div class="box-title">
          <h4>Where <span>*</span></h4>
          <p>The transformations where the loss occurs (Where not)</p>
        </div>
        <div title="{{ sixwtwoh?.Where }}">
          <mat-form-field class="w-100">
            <textarea
              (input)="concatProblemStatement()"
              matInput
              formControlName="where"
              [(ngModel)]="sixwtwoh.Where"
              class="textarea-input"
            ></textarea>
            <mat-error>Where is required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div style="width: 100%; display: flex; align-items: baseline">
      <div class="box">
        <div class="box-title">
          <h4>When <span>*</span></h4>
          <p>Startup, Normal Operation: when did the problem start</p>
        </div>
        <div title="{{ sixwtwoh?.When }}">
          <mat-form-field class="w-100">
            <textarea
              (input)="concatProblemStatement()"
              matInput
              formControlName="when"
              [(ngModel)]="sixwtwoh.When"
              class="textarea-input"
            ></textarea>
            <mat-error>When is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="box">
        <div class="box-title">
          <h4>Which <span>*</span></h4>
          <p>Brands, SKU'S, Formats, Material's affected (which not)</p>
        </div>
        <div title="{{ sixwtwoh?.Which }}">
          <mat-form-field class="w-100">
            <textarea
              (input)="concatProblemStatement()"
              matInput
              formControlName="which"
              [(ngModel)]="sixwtwoh.Which"
              class="textarea-input"
            ></textarea>
            <mat-error>Which is required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div style="width: 100%; display: flex; align-items: baseline">
      <div class="box">
        <div class="box-title">
          <h4>Who <span>*</span></h4>
          <p>Variation among teams, people, other...</p>
        </div>
        <div title="{{ sixwtwoh?.Who }}">
          <mat-form-field class="w-100">
            <textarea
              (input)="concatProblemStatement()"
              matInput
              formControlName="who"
              [(ngModel)]="sixwtwoh.Who"
              class="textarea-input"
            ></textarea>
            <mat-error>Who is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="box">
        <div class="box-title">
          <h4>How <span>*</span></h4>
          <p>Circumstances of the loss; Failure Module</p>
        </div>
        <div title="{{ sixwtwoh?.How }}">
          <mat-form-field class="w-100">
            <textarea
              (input)="concatProblemStatement()"
              matInput
              formControlName="how"
              [(ngModel)]="sixwtwoh.How"
              class="textarea-input"
            ></textarea>
            <mat-error>How is required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div style="width: 100%; display: flex; align-items: baseline">
      <div class="box">
        <div class="box-title">
          <h4>How Much</h4>
          <p>Magnitude Number, Frequency amount...</p>
        </div>
        <div title="{{ sixwtwoh?.HowMuch }}">
          <mat-form-field class="w-100">
            <textarea
              (input)="concatProblemStatement()"
              matInput
              type="number"
              formControlName="howMuch"
              [(ngModel)]="sixwtwoh.HowMuch"
              class="textarea-input"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="box">
        <div class="box-title">
          <h4>To Whom</h4>
          <p>Lines, Systems, Operations affected:</p>
        </div>
        <div title="{{ sixwtwoh?.ToWhom }}">
          <mat-form-field class="w-100">
            <textarea
              (input)="concatProblemStatement()"
              matInput
              formControlName="whom"
              [(ngModel)]="sixwtwoh.ToWhom"
              class="textarea-input"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div style="width: 100%; display: flex">
      <div class="box" style="width: 98%">
        <div class="bg-primary">
          <h4>New Refocused Problem Statement <span>*</span></h4>
        </div>
        <div title="{{ sixwtwoh?.ProblemStatement }}">
          <mat-form-field class="w-100">
            <textarea
              matInput
              formControlName="problemStatement"
              [(ngModel)]="sixwtwoh.ProblemStatement"
              class="textarea-input"
            ></textarea>
            <mat-error>Problem Statement is required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
