import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FishboneService {

  baseUrl: string = environment.O3CoreApiUrl;
  o3coreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) {
  }

  // *** Core Api Method *** //
  getFishBoneData(headerId: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.get<any[]>(this.baseUrl + 'find-fishbone', { headers: header, params: { headerId: headerId } });
    return this.http.get<any[]>(this.o3coreApiUrl + 'ATFishbone/GetFishbone', { headers: header, params: { headerId: headerId } });
  }

  // *** Core Api Method *** //
  addFishBoneData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.post<any[]>(this.baseUrl + 'add-fishbone', data, { headers: header });
   return this.http.post<any[]>(this.o3coreApiUrl + 'ATFishBone/AddFishBone', data, { headers: header });
  }

  // *** Core Api Method *** //
  updateFishBoneData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.put<any[]>(this.baseUrl + 'update-fishbone', data, { headers: header });
    return this.http.post<any[]>(this.o3coreApiUrl + 'ATFishBone/UpdateFishBone', data, { headers: header });
  }

}
