import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserAccessService {
  baseUrl: string = environment.O3CoreApiUrl;
  kpibaseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetGroup', data);
  }

  getPlantData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetDepartments', data);
  }

  getDepartmentData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetDepartmentByPlant', data);
  }

  getUserList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Account/GetUserAccessList', data);
  }
  getMachineAccess(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'machineaccess', data);
  }
  saveMachineAccess(data: any): any {
    return this.http.post(this.kpibaseUrl + 'addmachineaccess', data);
  }
  getSelectedNodesMachineFlatArray(treeNode: any): any {
    const flatArray: any = [];
    const treeToFlat = (node: any): any => {
      if (node.partialSelected) {
        flatArray.push(node);
      }

      if (node.children.length) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < node.children.length; i++) {
          treeToFlat(node.children[i]);
        }
      }
    };

    treeToFlat(treeNode);

    return flatArray;
  }
  getSelectedNodesFlatArray(treeNode: any): any {
    const flatArray: any = [];
    const treeToFlat = (node: any): any => {
      if (node.data.IsSelect) {
        flatArray.push(node);
      }

      if (node.children.length) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < node.children.length; i++) {
          treeToFlat(node.children[i]);
        }
      }
    };

    treeToFlat(treeNode);

    return flatArray;
  }
}
