import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MeetingActionService {
  
  O3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }
  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Common/GetDepartments', data);
  }
  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.O3CoreApiUrl + 'Common/GetGroup', data);
  }
  getAllMeetingActions(data: any): Observable<any> {

    //  return this.http.post<any>(this.kpibaseUrl + "getAllMeetingActionsByPlant",data);

    return this.http.post<any>(this.O3CoreApiUrl + 'ScoreCardMeetingMatrix/getAllMeetingActionsByPlant', data);

  }
  setTargetDate(data: any): Observable<any> {
    // return this.http.post<any>(this.kpibaseUrl + 'newActionTargetDate', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'ScoreCardMeetingMatrix/NewActionTargetDate', data);
  }
  setCompletionDate(data: any): Observable<any> {
    //return this.http.post<any>(this.kpibaseUrl + 'newActionCompletionDate', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'ScoreCardMeetingMatrix/NewActionCompletionDate', data);
  }
  deleteMeetingAction(data: any): Observable<any> {
    // return this.http.post<any>(this.kpibaseUrl + "deleteMeetingAction",data);
    return this.http.post<any>(this.O3CoreApiUrl + "ScoreCardMeetingMatrix/DeleteMeetingAction", data);
  }

  //Not Shifted On Stage 
  getResponsibleOnKpiId(data: any): Observable<any> {
    // return this.http.post<any>(this.kpibaseUrl + 'getResponsibleOnKpiId', data);
    return this.http.post<any>(this.O3CoreApiUrl + "ScoreCardMeetingMatrix/GetResponsibleOnKpiId", data);
  }
}
