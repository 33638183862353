import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { ToastrService } from 'ngx-toastr';
import { DtServiceService } from 'src/app/services/dtService.service';
import { Router } from '@angular/router';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManagerExecutionService } from '../../services/manager.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from 'src/app/analytical-tools/image-dialog/image-dialog.component';
import { environment } from 'src/environments/environment';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { CommonService } from 'src/app/services/common.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import * as moment from 'moment';
import { EditBtnRenderer } from 'src/app/edit-action-btn-renderer.component';
import { MatTableDataSource } from '@angular/material/table';
import { CustomTooltip } from 'src/app/custom-tooltip.component';
import { DeleteBtnRenderer } from 'src/app/delete-action-btn-renderer.component';
import Swal from 'sweetalert2';
import { ActionEditDeleteBtnRenderer } from 'src/app/action-edit-delete-btn-renderer.component';

@Component({
  selector: 'app-historical-table',
  templateUrl: './historical-table.component.html',
  styleUrls: ['./historical-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({
          left: '10px',
          height: '0px',
          minHeight: '0',
          visibility: 'hidden',
        })
      ),
      state(
        'expanded',
        style({ left: '10px', height: '*', visibility: 'visible' })
      ),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class HistoricalTableComponent implements OnInit {
  @ViewChild('participantsListModal')
  private participantsListModal!: TemplateRef<any>;
  @ViewChild('viewAtStages') private viewAtStages!: TemplateRef<any>;
  @ViewChild('SwotPestModal') private SwotPestModal!: TemplateRef<any>;
  @ViewChild('tabChange') tabChange: any;
  @ViewChild('tabHeaderChange') tabHeaderChange: any;

  @ViewChild('MachineryToggle') MachineryToggle: any;
  @ViewChild('MaterialToggle') MaterialToggle: any;
  @ViewChild('ManToggle') ManToggle: any;
  @ViewChild('MethodToggle') MethodToggle: any;
  @ViewChild('MotherNatureToggle') MotherNatureToggle: any;
  @ViewChild('ManagementToggle') ManagementToggle: any;
  MachineryChecked: boolean = false;
  MaterialChecked: boolean = false;
  ManChecked: boolean = false;
  MethodChecked: boolean = false;
  MotherNatureChecked: boolean = false;
  ManagementChecked: boolean = false;
  mType: any = {
    machinery: [],
    material: [],
    man: [],
    method: [],
    motherNature: [],
    management: [],
  };
  mList: any = {
    machineryList: [],
    materialList: [],
    manList: [],
    methodList: [],
    motherNatureList: [],
    managementList: [],
  };
  machineryList: any = [];
  materialList: any = [];
  manList: any = [];
  methodList: any = [];
  motherNatureList: any = [];
  managementList: any = [];
  orgMachineryList: any = [];
  orgMaterialList: any = [];
  orgManList: any = [];
  orgMethodList: any = [];
  orgMotherNatureList: any = [];
  orgManagementList: any = [];

  causeEffectModelColumns: string[] = [
    'MType',
    'Priority',
    'ProbableCause',
    'Weight',
    'ValidationResult',
    'CauseIdentified',
    'Image',
    'Remark',
    // 'Actions'
  ];
  causeEffectModelDataSource = new MatTableDataSource();
  Weigths = [
    { value: 2, checked: true },
    { value: 5, checked: false },
    { value: 8, checked: false },
  ];

  fiveWhyColumns: any;
  fiveWhyDataSource = new MatTableDataSource();

  // fiveWhyActionColumns: string[] = ['RootCause', 'Action', 'ActionType', 'Picture', 'File', 'AssignTo', 'TargetDate', 'RevisedDate', 'ClosedDate', 'Remarks', 'Verified', 'VerifiedBy', 'Status'];

  fiveWhyActionColumns: string[] = [
    'Root Cause',
    'Action',
    'Action Type',
    'Picture',
    'File',
    'Assign To',
    'Assigned Date',
    'Target Date',
    'Revised Date',
    'No Of Misses',
    'Action Completed (Yes/No)',
    'Action Completed (By & On)',
    'Verification (Approved / Disapproved)',
    'Verified (By & On)',
    'New Assignee',
    'Closed Date',
    'Remarks',
    'Status',
  ];
  fiveWhyActionPlanDataSource = new MatTableDataSource();

  historyHeaderForm = new FormGroup({
    group: new FormControl(null, [Validators.required]),
    plant: new FormControl(null, [Validators.required]),
    department: new FormControl(null),
    module: new FormControl(null),
    rcaCode: new FormControl(null),
    initProbStat: new FormControl(null),
    teamLeader: new FormControl(null),
    status: new FormControl(null),
    fromDate: new FormControl(null),
    toDate: new FormControl(null),
  });

  historyHeaderData: any = {
    ID: 0,
    Group: '',
    Plant: '',
    Department: '',
    Module: 'AnalyticalTools',
    RCACode: '',
    InitProbStatement: '',
    TeamLeader: ['ALL'],
    Status: 'ALL',
    FromDate: '',
    ToDate: '',
  };

  formDirective: any;
  spinnerText: string = '';
  permisson!: any;
  IsWrite: boolean = false;
  IsAccess!: any;
  breadcrumList!: any;

  groupList: any = [];
  groupFiltList: any = [];
  plantList: any = [];
  plantFiltList: any = [];
  deparmentList: any = [];
  deparmentFiltList: any = [];
  uniqueCodeList: any = [];
  uniqueCodeFiltList: any = [];
  teamList: any = [];
  teamFiltTeams: any = [];
  historicalDataList: any = [];

  frameworkComponents: any;
  rowData: any = [];
  columnDefsRCA!: any[];
  columnDefsSWOTPEST!: any[];
  gridApi: any;
  gridColumnApi: any;

  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
  };
  domLayout = 'autoHeight';

  dialogParticipantsList: any = [];
  viewStageData: any;
  historicalMType: any = [];
  userAllList: any = [];
  ImageUrl: any;
  selectedIndex: any = 0;
  selectedHeaderIndex: any = 0;
  userList: any = [];

  OpenRca: any = { Count: 0, Percentage: 0 };
  InprogressRca: any = { Count: 0, Percentage: 0 };
  ClosedRca: any = { Count: 0, Percentage: 0 };
  CompletedRca: any = { Count: 0, Percentage: 0 };
  DelayedRca: any = { Count: 0, Percentage: 0 };
  RejectedRca: any = { Count: 0, Percentage: 0 };

  OpenAction: any = { Count: 0, Percentage: 0 };
  InprogressAction: any = { Count: 0, Percentage: 0 };
  ClosedAction: any = { Count: 0, Percentage: 0 };
  CompleteAction: any = { Count: 0, Percentage: 0 };
  DelayedAction: any = { Count: 0, Percentage: 0 };
  ReOpenedAction: any = { Count: 0, Percentage: 0 };

  Dash6W2H: any = { Open: 0, InProgress: 0, Completed: 0 };
  DashFishBone: any = { Open: 0, InProgress: 0, Completed: 0 };
  DashCauseEffect: any = { Open: 0, InProgress: 0, Completed: 0 };
  Dash5Why: any = { Open: 0, InProgress: 0, Completed: 0 };
  DashActionPlan: any = { Open: 0, InProgress: 0, Completed: 0 };

  menuBlueIconUrl = 'assets/images/menu-bottom-blue-icon.png';
  TotalActionCount: any;
  TotalAction: any;

  @ViewChild('allSelected') private allSelected: any;
  teamLeaderArr: any = [];
  @ViewChild('selectTeamLeader') selectTeamLeader: any;
  @ViewChild('selectTeamLeaderFilter') selectTeamLeaderFilter: any;

  SwotSubject: any = '';
  ATMenuList: any = [];
  AllMenuList: any = [];
  RefocusedUploadFile: any;
  constructor(
    public managerExecutionService: ManagerExecutionService,
    private spinner: NgxSpinnerService,
    private sfdService: ShopFloorDataService,
    private toaster: ToastrService,
    private dtService: DtServiceService,
    private router: Router,
    private analyticalToolsService: AnalyticalToolsService,
    private dialog: MatDialog,
    private enterpriseGroupService: EnterPriseGroupService,
    private commonService: CommonService,
    private userManagementService: UserManagementService
  ) {
    this.frameworkComponents = {
      editRenderer: EditBtnRenderer,
      deleteRenderer: DeleteBtnRenderer,
      customTooltip: CustomTooltip,
      actionRenderer: ActionEditDeleteBtnRenderer,
    };
    this.columnDefsRCA = [
      {
        headerName: 'Action',
        cellRenderer: 'actionRenderer',
        minWidth: 110,
        cellRendererParams: {
          onClickEdit: this.editRow.bind(this),
          onClickDelete: this.RejectRow.bind(this),
        },
      },
      {
        headerName: 'Department',
        minWidth: 170,
        field: 'Department',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
      },
      {
        headerName: 'RCA Code',
        minWidth: 120,
        field: 'RCACode',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
      },
      {
        headerName: 'Problem Identified Date',
        minWidth: 200,
        field: 'CreatedAt',
        sortable: true,
        filter: true,
        valueFormatter: function (params: any) {
          return moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
      },
      {
        headerName: 'Initial Problem',
        minWidth: 200,
        field: 'InitProbStatement',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
      },
      {
        headerName: 'Image',
        minWidth: 90,
        field: 'UploadFile',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = `<img style="width: 50px; height: 40px;" src="assets/images/no-image.png"/>`;
          if (params && params.value) {
            html = '';
            html = `<img style="width: 50px; height: 40px;" src="${params.value}"/>`;
          }
          return html;
        },
      },
      {
        headerName: 'Team Leader',
        minWidth: 200,
        field: 'TeamLeader',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
      },
      {
        headerName: 'Status',
        minWidth: 110,
        field: 'StatusCycle',
        sortable: true,
        filter: true,
        cellStyle: function (params: any): any {
          if (params.value == 'Open')
            return { color: 'white', backgroundColor: 'gray' };
          else if (params.value == 'InProgress')
            return { color: 'white', backgroundColor: 'orange' };
          else if (params.value == 'Complete')
            return { color: 'white', backgroundColor: '#1cd51c' };
          else if (params.value == 'Closed')
            return { color: 'white', backgroundColor: 'green' };
          else if (params.value == 'Delayed')
            return { color: 'white', backgroundColor: 'brown' };
          else if (params.value == 'Rejected')
            return { color: 'white', backgroundColor: 'red' };
        },
      },
      {
        headerName: 'Participants',
        minWidth: 130,
        field: 'Participant',
        sortable: true,
        filter: true,
        cellStyle: function (params: any) {
          // let html = 'view';
          return {
            color: 'blue',
            'text-decoration': 'underline',
            cursor: 'pointer',
          };
        },
      },
      {
        headerName: 'RCA Tools',
        minWidth: 120,
        field: 'RCATools',
        sortable: true,
        filter: true,
        cellStyle: function (params: any) {
          // let html = 'view';
          return {
            color: 'blue',
            'text-decoration': 'underline',
            cursor: 'pointer',
          };
        },
      },
      {
        headerName: 'Report',
        minWidth: 100,
        field: 'Report',
        sortable: true,
        filter: true,
        cellStyle: function (params: any) {
          // let html: any = ''
          // if (params.data.StatusCycle && (params.data.StatusCycle == 'Closed' || params.data.StatusCycle == 'Completed'))
          //   html = `print`;
          // else
          //   html = `-`;
          // return html;
          if (params.value == 'print')
            return {
              color: 'blue',
              'text-decoration': 'underline',
              cursor: 'pointer',
            };
          else return { color: 'blue' };
        },
      },
      {
        headerName: 'Start Date',
        minWidth: 200,
        field: 'CreatedAt',
        sortable: true,
        filter: true,
        valueFormatter: function (params: any) {
          return params && params.value
            ? moment(params.value).format('DD/MM/YYYY hh:mm a')
            : '';
        },
      },
      {
        headerName: 'End Date',
        minWidth: 200,
        field: 'ClosedAt',
        sortable: true,
        filter: true,
        valueFormatter: function (params: any) {
          return params && params.value
            ? moment(params.value).format('DD/MM/YYYY hh:mm a')
            : '';
        },
      },
      {
        headerName: 'Duartion (Days)',
        minWidth: 110,
        field: 'Duration',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
      },
    ];

    this.columnDefsSWOTPEST = [
      {
        headerName: 'Action',
        cellRenderer: 'editRenderer',
        minWidth: 100,
        cellRendererParams: {
          onClick: this.editRow.bind(this),
        },
      },
      // {
      //   headerName: 'Module', minWidth: 140, field: 'Module', sortable: true,
      // filter: true,  tooltipComponent: 'customTooltip', cellRenderer: function (params: any) {
      //     let html = params && params.value ? params.value : '';
      //     return html;
      //   }
      // },
      {
        headerName: 'Department',
        minWidth: 200,
        field: 'Department',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
      },
      {
        headerName: 'RCA Code',
        minWidth: 160,
        field: 'RCACode',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html =
            params && params.value ? 'AT-SWOTPEST-' + params.value : '';
          return html;
        },
      },
      {
        headerName: 'Team Leader',
        minWidth: 200,
        field: 'TeamLeader',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
      },
      {
        headerName: 'Participants',
        minWidth: 130,
        field: 'Participants',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = 'view';
          return html;
        },
      },
      {
        headerName: 'SWOT Subject',
        minWidth: 200,
        field: 'SWOTSubject',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
      },
      {
        headerName: 'PEST Subject',
        minWidth: 200,
        field: 'PESTSubject',
        sortable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
      },
    ];
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.ImageUrl = environment.O3CoreApiImageUrl;
    this.GetMenuAccess();
    this.GetBreadcrumList();
    this.getMenulist();
    this.getGroups();
    this.getAllUserList();
    this.makeDataLastWeek();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.spinnerText = 'Loading Menu Data';
    this.spinner.show();
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.breadcrumList = data.BreadcrumList;
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  getMenulist(): any {
    const obj = {};
    this.userManagementService.GetMenulist(obj).subscribe(
      (data: any) => {
        this.AllMenuList = data.mennulist;
        this.ATMenuList = this.AllMenuList.find(
          (menu: any) =>
            menu?.List[0]?.Url == '/at/historical-table'
        );
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.spinnerText = 'Fetching Groups Data';
    this.spinner.show();
    this.sfdService.getGroupData(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.groupList = res.masterList;
        this.groupFiltList = this.groupList.slice();
        if (this.groupFiltList && this.groupFiltList.length)
          this.historyHeaderData.Group = this.groupFiltList[0].TypeId;
        this.getPlants();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getPlants(): void {
    const key = {
      GroupId: this.historyHeaderData.Group,
    };
    this.spinner.show();
    this.dtService.getPlantData(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.plantList = res.DepartList;
        this.plantFiltList = this.plantList.slice();
        if (this.plantFiltList && this.plantFiltList.length)
          this.historyHeaderData.Plant = parseInt(this.plantFiltList[0].DeptId);
        this.getDepartments();
        this.getTeamList();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getDepartments() {
    const key = {
      ptId: this.historyHeaderData.Plant,
    };
    this.spinner.show();
    this.dtService.getDeptData(key).subscribe(
      (res: any) => {
        this.deparmentList = res;
        this.deparmentFiltList = this.deparmentList.slice();
        if (this.deparmentFiltList && this.deparmentFiltList.length) {
          // this.historyHeaderData.Department = parseInt(this.deparmentFiltList[0].TypeId);
          this.historyHeaderData.Department = 'ALL';
        }
        this.spinner.hide();
        this.getUniqueCode();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getUniqueCode() {
    const key = {
      Id: this.historyHeaderData.Department,
      ptID:
        this.historyHeaderData.Department == 'ALL'
          ? this.historyHeaderData.Plant
          : '',
    };
    this.spinnerText = 'Fetching Groups Data';
    this.spinner.show();
    this.analyticalToolsService.getUniqueCode(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.uniqueCodeList = res.data;
        this.uniqueCodeFiltList =
          this.uniqueCodeList && this.uniqueCodeList.length
            ? this.uniqueCodeList.slice()
            : [];
        this.onSearch();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getTeamList() {
    this.spinnerText = 'Fetching Team Data';
    this.spinner.show();
    const data = {
      EGId: this.historyHeaderData.Group,
      PlantId: this.historyHeaderData.Plant,
    };
    this.commonService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        this.teamList = res.employeeInfoList;
        this.teamList.map(
          (user: any) =>
            (user.FullName = user.FirstName.trim() + ' ' + user.lastName.trim())
        );
        this.teamFiltTeams = this.teamList.slice();
        this.spinner.hide();
        this.historyHeaderForm.controls.teamLeader.patchValue(['ALL']);
        this.toggleAllSelection();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  getAllUserList() {
    this.spinnerText = 'Fetching User Data';
    this.spinner.show();
    this.managerExecutionService.getUserList().subscribe(
      (res: any) => {
        this.userAllList = res.UserList;
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  tosslePerOne() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return;
    }
    if (
      this.historyHeaderForm.controls.teamLeader.value.length ==
      this.teamList.length
    ) {
      this.historyHeaderForm.controls.teamLeader.patchValue(['ALL']);
    }
    // this.allSelected.select();
  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.historyHeaderForm.controls.teamLeader.patchValue(['ALL']);
      // this.allSelected.select();
    } else {
      this.historyHeaderForm.controls.teamLeader.patchValue([]);
    }
  }

  setTeamLeaderChange(val) {
    let teamLeaderList = [];
    this.teamLeaderArr = [];
    if (this.teamList && this.teamList.length) {
      for (var i = 0; i < val.length; i++) {
        let obj = this.teamList.find((ind: any) => ind.EmpInfo_Id == val[i]);
        if (obj != undefined) teamLeaderList.push(obj);
      }
    }
    this.teamLeaderArr = this.analyticalToolsService.getTeamLeaderList()
      ? this.analyticalToolsService.getTeamLeaderList()
      : [];

    if (teamLeaderList && teamLeaderList.length) {
      for (const teamid of teamLeaderList) {
        if (this.teamLeaderArr && this.teamLeaderArr.length) {
          let obj = this.teamLeaderArr.find(
            (ind: any) => ind == teamid.EmpInfo_Id
          );
          if (!obj) this.teamLeaderArr.push(teamid.EmpInfo_Id);
        } else this.teamLeaderArr.push(teamid.EmpInfo_Id);
      }
    }
  }

  setTeamAndValidate(val: any) {
    let obj = this.historyHeaderData.TeamLeader.find(
      (teamId: any) => teamId == val
    );
    if (!obj) {
      let index = this.teamLeaderArr.findIndex((ind: any) => ind == val);
      if (index > -1) {
        this.teamLeaderArr.splice(index, 1);
        this.analyticalToolsService.setHistoricalTeamLeaderList(
          this.teamLeaderArr
        );
        this.historyHeaderData.TeamLeader =
          this.analyticalToolsService.getHistoricalTeamLeaderList();
      }
    } else {
      this.analyticalToolsService.setHistoricalTeamLeaderList(
        this.teamLeaderArr
      );
      this.historyHeaderData.TeamLeader =
        this.analyticalToolsService.getHistoricalTeamLeaderList();
    }
  }

  keyUp() {
    this.historyHeaderData.TeamLeader =
      this.analyticalToolsService.getHistoricalTeamLeaderList()
        ? this.analyticalToolsService.getHistoricalTeamLeaderList()
        : [];
    if (
      this.historyHeaderData.TeamLeader &&
      this.historyHeaderData.TeamLeader.length
    )
      this.historyHeaderForm.controls.teamLeader.patchValue([
        ...this.historyHeaderData.TeamLeader.map((item: any) => item),
      ]);
  }

  setTeam() {
    this.teamFiltTeams = this.teamList;
    if (this.historyHeaderData.TeamLeader[0] != 'ALL')
      this.historyHeaderData.TeamLeader =
        this.analyticalToolsService.getHistoricalTeamLeaderList()
          ? this.analyticalToolsService.getHistoricalTeamLeaderList()
          : [];
    else this.historyHeaderData.TeamLeader = [];

    if (
      this.historyHeaderData.TeamLeader &&
      this.historyHeaderData.TeamLeader.length
    )
      this.historyHeaderForm.controls.teamLeader.patchValue([
        ...this.historyHeaderData.TeamLeader.map((item: any) => item),
      ]);
    else this.historyHeaderForm.controls.teamLeader.patchValue(['ALL']);
  }

  resetSelectFilter(type: any) {
    if (type == 'TeamLeader') {
      if (this.selectTeamLeaderFilter && this.selectTeamLeaderFilter.searchForm)
        this.selectTeamLeaderFilter.searchForm.reset();
    }
  }

  makeDataLastWeek() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 6);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    this.historyHeaderData.FromDate = stDT;
    this.historyHeaderData.ToDate = edDT;
  }

  createNewRCAScreen() {
    this.analyticalToolsService.setHistoricalHeaderData(this.historyHeaderData);
    this.DisplayRightmenu(83, '/Home/Index');
  }

  createNewSWOTPESTScreen() {
    this.router.navigate(['/at/swot']);
  }
  createNewRCAstagesScreen() {
    this.analyticalToolsService.setHistoricalHeaderData(this.historyHeaderData);
    this.DisplayRightmenu(83, '/at/at-stages');
  }

  dashboardRCAScreen() {
    this.analyticalToolsService.setHistoricalHeaderData(this.historyHeaderData);
    this.router.navigate(['/at/rca-dashboard']);
  }

  dashboardSWOTPESTScreen() { }

  onSearch() {
    this.spinnerText = 'Fetching Historical Data';
    this.spinner.show();
    this.historyHeaderData.headerTabIndex = this.selectedHeaderIndex;
    this.analyticalToolsService
      .getHistoricalData(this.historyHeaderData)
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.historicalMType = res.mType;

          if (this.selectedHeaderIndex == 0) {
            this.rowData = res.data;
            let OpenRcaFilter = this.rowData.filter(
              (row: any) => row.StatusCycle == 'Open'
            );
            let InprogressRcaFilter = this.rowData.filter(
              (row: any) => row.StatusCycle == 'InProgress'
            );
            let ClosedRcaFilter = this.rowData.filter(
              (row: any) => row.StatusCycle == 'Completed'
            );
            let DelayedRcaFilter = this.rowData.filter(
              (row: any) => row.StatusCycle == 'Delayed'
            );
            let CompletedRcaFilter = this.rowData.filter(
              (row: any) => row.StatusCycle == 'NotClosed'
            );
            let RejectedRcaFilter = this.rowData.filter(
              (row: any) => row.StatusCycle == 'Rejected'
            );

            let rowData: any = [];

            this.rowData = rowData.concat(
              OpenRcaFilter,
              InprogressRcaFilter,
              CompletedRcaFilter,
              DelayedRcaFilter,
              RejectedRcaFilter,
              ClosedRcaFilter
            );

            let FiveWhyActionFilter: any = [];

            // Total RCA's Card

            this.OpenRca.Count =
              OpenRcaFilter && OpenRcaFilter.length ? OpenRcaFilter.length : 0;
            this.OpenRca.Percentage =
              this.rowData && this.rowData.length
                ? Math.round((100 * OpenRcaFilter.length) / this.rowData.length)
                : 0;

            this.InprogressRca.Count =
              InprogressRcaFilter && InprogressRcaFilter.length
                ? InprogressRcaFilter.length
                : 0;
            this.InprogressRca.Percentage =
              this.rowData && this.rowData.length
                ? Math.round(
                  (100 * InprogressRcaFilter.length) / this.rowData.length
                )
                : 0;

            this.ClosedRca.Count =
              ClosedRcaFilter && ClosedRcaFilter.length
                ? ClosedRcaFilter.length
                : 0;
            this.ClosedRca.Percentage =
              this.rowData && this.rowData.length
                ? Math.round(
                  (100 * ClosedRcaFilter.length) / this.rowData.length
                )
                : 0;

            this.DelayedRca.Count =
              DelayedRcaFilter && DelayedRcaFilter.length
                ? DelayedRcaFilter.length
                : 0;
            this.DelayedRca.Percentage =
              this.rowData && this.rowData.length
                ? Math.round(
                  (100 * DelayedRcaFilter.length) / this.rowData.length
                )
                : 0;

            this.CompletedRca.Count =
              CompletedRcaFilter && CompletedRcaFilter.length
                ? CompletedRcaFilter.length
                : 0;
            this.CompletedRca.Percentage =
              this.rowData && this.rowData.length
                ? Math.round(
                  (100 * CompletedRcaFilter.length) / this.rowData.length
                )
                : 0;

            this.RejectedRca.Count =
              RejectedRcaFilter && RejectedRcaFilter.length
                ? RejectedRcaFilter.length
                : 0;
            this.RejectedRca.Percentage =
              this.rowData && this.rowData.length
                ? Math.round(
                  (100 * RejectedRcaFilter.length) / this.rowData.length
                )
                : 0;

            for (const row of this.rowData) {
              let grpObj = this.groupList.find(
                (grp: any) => grp.TypeId == row.Group
              );
              let pltObj = this.plantList.find(
                (plt: any) => plt.DeptId == row.Plant
              );
              let dptObj = this.deparmentList.find(
                (dpt: any) => dpt.TypeId == row.Dept
              );
              let tlObj = this.teamList.find(
                (team: any) => team.EmpInfo_Id == row.TeamLeader
              );

              row.IsWrite = row.StatusCycle == 'Rejected' ? false : true;
              row.Module = 'Analytical Tools';
              row.Group = grpObj ? grpObj.TypeName : '';
              row.Plant = pltObj ? pltObj.DeptDesc : '';
              row.Department = dptObj ? dptObj.TypeName : '';
              row.RCACode = 'AT-RCA-' + row.ID;
              row.TeamLeader = tlObj ? tlObj.fullName : '';
              row.Duration = '';
              row.Participant = 'view';
              row.RCATools = 'view';
              row.Report =
                row.StatusCycle &&
                  (row.StatusCycle == 'Closed' || row.StatusCycle == 'Completed')
                  ? 'print'
                  : '-';
              row.UploadFile =
                row && row.UploadFile ? this.ImageUrl + row.UploadFile : '';

              if (row.StatusCycle == 'NotClosed') row.StatusCycle = 'Complete';
              else if (row.StatusCycle == 'Completed')
                row.StatusCycle = 'Closed';

              // row.IsWrite = row.StatusCycle == 'Closed' ? false : this.IsWrite ;

              if (row.CreatedAt && row.ClosedAt) {
                let startDate: any, closedDate: any;
                startDate = new Date(row.CreatedAt);
                closedDate = new Date(row.ClosedAt);
                const diffTime = Math.abs(closedDate - startDate);
                const diffMin = Math.floor(diffTime / 60000);
                const diffHour = Math.floor(diffMin / 60);
                const diffDays = Math.floor(diffMin / 1440);
                row.Duration = diffDays ? diffDays : '';
              }

              let teamArr = row.Team.split(',');
              row.Participants = [];
              if (teamArr && teamArr.length) {
                for (const team of teamArr) {
                  let partObj = this.teamList.find(
                    (prt: any) => prt.EmpInfo_Id == team
                  );
                  if (partObj) row.Participants.push(partObj);
                }
              }

              if (row.fiveWhyActionPlan && row.fiveWhyActionPlan.length) {
                for (const action of row.fiveWhyActionPlan)
                  FiveWhyActionFilter.push(action);
              }
            }

            // Total Action Card

            this.TotalAction = FiveWhyActionFilter;

            let OpenActionFilter = this.TotalAction.filter(
              (row: any) => row.Status == 'Open'
            );
            let InprogressActionFilter = this.TotalAction.filter(
              (row: any) => row.Status == 'InProgress'
            );
            let ClosedActionFilter = this.TotalAction.filter(
              (row: any) => row.Status == 'Closed'
            );
            let CompleteActionFilter = this.TotalAction.filter(
              (row: any) => row.Status == 'Complete'
            );
            let DelayedActionFilter = this.TotalAction.filter(
              (row: any) => row.Status == 'Delay' || row.Status == 'Delayed'
            );
            let ReOpenedActionFilter = this.TotalAction.filter(
              (row: any) => row.Status == 'ReOpened'
            );

            this.OpenAction.Count =
              OpenActionFilter && OpenActionFilter.length
                ? OpenActionFilter.length
                : 0;
            this.OpenAction.Percentage =
              this.TotalAction && this.TotalAction.length
                ? Math.round(
                  (100 * OpenActionFilter.length) / this.TotalAction.length
                )
                : 0;

            this.InprogressAction.Count =
              InprogressActionFilter && InprogressActionFilter.length
                ? InprogressActionFilter.length
                : 0;
            this.InprogressAction.Percentage =
              this.TotalAction && this.TotalAction.length
                ? Math.round(
                  (100 * InprogressActionFilter.length) /
                  this.TotalAction.length
                )
                : 0;

            this.ClosedAction.Count =
              ClosedActionFilter && ClosedActionFilter.length
                ? ClosedActionFilter.length
                : 0;
            this.ClosedAction.Percentage =
              this.TotalAction && this.TotalAction.length
                ? Math.round(
                  (100 * ClosedActionFilter.length) / this.TotalAction.length
                )
                : 0;

            this.CompleteAction.Count =
              CompleteActionFilter && CompleteActionFilter.length
                ? CompleteActionFilter.length
                : 0;
            this.CompleteAction.Percentage =
              this.TotalAction && this.TotalAction.length
                ? Math.round(
                  (100 * CompleteActionFilter.length) /
                  this.TotalAction.length
                )
                : 0;

            this.DelayedAction.Count =
              DelayedActionFilter && DelayedActionFilter.length
                ? DelayedActionFilter.length
                : 0;
            this.DelayedAction.Percentage =
              this.TotalAction && this.TotalAction.length
                ? Math.round(
                  (100 * DelayedActionFilter.length) / this.TotalAction.length
                )
                : 0;

            this.ReOpenedAction.Count =
              ReOpenedActionFilter && ReOpenedActionFilter.length
                ? ReOpenedActionFilter.length
                : 0;
            this.ReOpenedAction.Percentage =
              this.TotalAction && this.TotalAction.length
                ? Math.round(
                  (100 * ReOpenedActionFilter.length) /
                  this.TotalAction.length
                )
                : 0;

            // RCA By Stages Card
            let Open6W2hFilter = this.rowData.filter(
              (row: any) => row.Status6W2H == 'Open'
            );
            let Inprogress6W2hFilter = this.rowData.filter(
              (row: any) => row.Status6W2H == 'InProgress'
            );
            let Complete6W2hFilter = this.rowData.filter(
              (row: any) => row.Status6W2H == 'Completed'
            );

            this.Dash6W2H.Open =
              Open6W2hFilter && Open6W2hFilter.length
                ? Open6W2hFilter.length
                : 0;
            this.Dash6W2H.InProgress =
              Inprogress6W2hFilter && Inprogress6W2hFilter.length
                ? Inprogress6W2hFilter.length
                : 0;
            this.Dash6W2H.Completed =
              Complete6W2hFilter && Complete6W2hFilter.length
                ? Complete6W2hFilter.length
                : 0;

            let OpenFishBoneFilter = this.rowData.filter(
              (row: any) => row.StatusFishbone == 'Open'
            );
            let InprogressFishBoneFilter = this.rowData.filter(
              (row: any) => row.StatusFishbone == 'InProgress'
            );
            let CompleteFishBoneFilter = this.rowData.filter(
              (row: any) => row.StatusFishbone == 'Completed'
            );

            this.DashFishBone.Open =
              OpenFishBoneFilter && OpenFishBoneFilter.length
                ? OpenFishBoneFilter.length
                : 0;
            this.DashFishBone.InProgress =
              InprogressFishBoneFilter && InprogressFishBoneFilter.length
                ? InprogressFishBoneFilter.length
                : 0;
            this.DashFishBone.Completed =
              CompleteFishBoneFilter && CompleteFishBoneFilter.length
                ? CompleteFishBoneFilter.length
                : 0;

            let OpenCauseEffectFilter = this.rowData.filter(
              (row: any) => row.StatusCauseEffect == 'Open'
            );
            let InprogressCauseEffectFilter = this.rowData.filter(
              (row: any) => row.StatusCauseEffect == 'InProgress'
            );
            let CompleteCauseEffectFilter = this.rowData.filter(
              (row: any) => row.StatusCauseEffect == 'Completed'
            );

            this.DashCauseEffect.Open =
              OpenCauseEffectFilter && OpenCauseEffectFilter.length
                ? OpenCauseEffectFilter.length
                : 0;
            this.DashCauseEffect.InProgress =
              InprogressCauseEffectFilter && InprogressCauseEffectFilter.length
                ? InprogressCauseEffectFilter.length
                : 0;
            this.DashCauseEffect.Completed =
              CompleteCauseEffectFilter && CompleteCauseEffectFilter.length
                ? CompleteCauseEffectFilter.length
                : 0;

            let Open5WhyFilter = this.rowData.filter(
              (row: any) => row.StatusFiveWhy == 'Open'
            );
            let Inprogress5WhyFilter = this.rowData.filter(
              (row: any) => row.StatusFiveWhy == 'InProgress'
            );
            let Complete5WhyFilter = this.rowData.filter(
              (row: any) => row.StatusFiveWhy == 'Completed'
            );

            this.Dash5Why.Open =
              Open5WhyFilter && Open5WhyFilter.length
                ? Open5WhyFilter.length
                : 0;
            this.Dash5Why.InProgress =
              Inprogress5WhyFilter && Inprogress5WhyFilter.length
                ? Inprogress5WhyFilter.length
                : 0;
            this.Dash5Why.Completed =
              Complete5WhyFilter && Complete5WhyFilter.length
                ? Complete5WhyFilter.length
                : 0;

            let OpenActionPlanFilter = this.rowData.filter(
              (row: any) => row.StatusFiveWhyAction == 'Open'
            );
            let InprogressActionPlanFilter = this.rowData.filter(
              (row: any) => row.StatusFiveWhyAction == 'InProgress'
            );
            let CompleteActionPlanFilter = this.rowData.filter(
              (row: any) => row.StatusFiveWhyAction == 'Completed'
            );

            this.DashActionPlan.Open =
              OpenActionPlanFilter && OpenActionPlanFilter.length
                ? OpenActionPlanFilter.length
                : 0;
            this.DashActionPlan.InProgress =
              InprogressActionPlanFilter && InprogressActionPlanFilter.length
                ? InprogressActionPlanFilter.length
                : 0;
            this.DashActionPlan.Completed =
              CompleteActionPlanFilter && CompleteActionPlanFilter.length
                ? CompleteActionPlanFilter.length
                : 0;

            this.historicalDataList = [];
          } else {
            this.historicalDataList = res.data;
            for (const row of this.historicalDataList) {
              let grpObj = this.groupList.find(
                (grp: any) => grp.TypeId == row.Group
              );
              let pltObj = this.plantList.find(
                (plt: any) => plt.DeptId == row.Plant
              );
              let dptObj = this.deparmentList.find(
                (dpt: any) => dpt.TypeId == row.Dept
              );
              let tlObj = this.teamList.find(
                (team: any) => team.EmpInfo_Id == row.TeamLeader
              );

              row.IsWrite = this.IsWrite;
              row.Module = 'Analytical Tools';
              row.Group = grpObj ? grpObj.TypeName : '';
              row.Plant = pltObj ? pltObj.DeptDesc : '';
              row.Department = dptObj ? dptObj.TypeName : '';
              row.RCACode = row.ID;
              row.TeamLeader = tlObj ? tlObj.fullName : '';

              let teamArr = row.Team.split(',');
              row.Participants = [];
              if (teamArr && teamArr.length) {
                for (const team of teamArr) {
                  let partObj = this.teamList.find(
                    (prt: any) => prt.EmpInfo_Id == team
                  );
                  if (partObj) row.Participants.push(partObj);
                }
              }

              let SwotArr = row.swotAndPest.filter(
                (sw: any) => sw.SwotOrPest == 1
              );
              let PestArr = row.swotAndPest.filter(
                (pe: any) => pe.SwotOrPest == 2
              );
              row.SWOTSubject =
                SwotArr && SwotArr[0] && SwotArr[0].Subject
                  ? SwotArr[0].Subject
                  : '';
              row.PESTSubject =
                PestArr && PestArr[0] && PestArr[0].Subject
                  ? PestArr[0].Subject
                  : '';
            }

            this.rowData = [];
          }
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );
  }
  setHeaderID(id: any) {
    this.analyticalToolsService.setHeaderId(id);
  }
  hasPermission(event): boolean {
    const participants = event.rowData.Participants.map((x) => x.UserId);
    const teamLead = Array.isArray(event.rowData.Team?.split(','))
      ? event.rowData.Team?.split(',')
      : [event.rowData.Team];
    const participantsAndLead = [...participants, ...teamLead];
    const loggedInUserId = JSON.parse(localStorage.getItem('user')).UserId;
    const isAllowed = participantsAndLead.find(
      (user) => user == loggedInUserId
    );
    return isAllowed;
  }
  editRow(event: any) {
    if (!this.hasPermission(event)) {
      this.toaster.error('Error', 'You are not allowed to edit this record');
      return;
    }
    event = event.rowData;
    this.analyticalToolsService.setHeaderId(event?.ID);
    const menuObj = this.ATMenuList.ID;
    localStorage.setItem('O3RightMenuId', menuObj);
    this.router.navigate(['at/at-stages/edit', event?.ID]);
  }

  RejectRow(event: any) {
    if (!this.hasPermission(event)) {
      this.toaster.error('Error', 'You are not allowed to delete this record');
      return;
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Reject This RCA!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        event = event.rowData;
        this.analyticalToolsService.setRejectRow(event).subscribe(
          (res: any) => {
            this.spinner.hide();
            if (res.status) this.onSearch();
          },
          (err: any) => {
            this.spinner.hide();
          }
        );
      }
    });
  }

  onFilterChanged(event: any) {
    this.gridOptions.api.setQuickFilter(event.value);
  }
  /// AG Grid
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  cellClicked(params: any) {
    const col = params.colDef.field;

    if (col == 'Participant') {
      this.dialogParticipantsList = params.data.Participants;
      this.dialogParticipantsList.map((team: any) => {
        team.isLeader = team.fullName == params.data.TeamLeader ? true : false;
      });
      let dialogRef = this.dialog.open(this.participantsListModal, {
        width: '25%',
      });
      dialogRef.afterClosed().subscribe((result) => { });
    } else if (col == 'RCATools') {
      this.spinner.show();
      this.viewStageData = params.data;
      setTimeout(() => {
        this.selectedIndex =
          this.viewStageData && this.viewStageData.stageId
            ? this.viewStageData.stageId - 1
            : 0;

        if (this.viewStageData.stageId == 1) {
          if (
            this.viewStageData.StatusCycle &&
            (this.viewStageData.StatusCycle == 'Completed' ||
              this.viewStageData.StatusCycle == 'Closed')
          ) {
            this.selectedIndex = 4;
          } else if (
            (this.viewStageData.Status6W2H &&
              this.viewStageData.Status6W2H == 'InProgress') ||
            !this.viewStageData.Status6W2H ||
            this.viewStageData.Status6W2H == 'Open'
          ) {
            this.selectedIndex = 0;
          } else if (
            (this.viewStageData.StatusFishbone &&
              this.viewStageData.StatusFishbone == 'InProgress') ||
            !this.viewStageData.StatusFishbone ||
            this.viewStageData.StatusFishbone == 'Open'
          ) {
            this.selectedIndex = 1;
          } else if (
            (this.viewStageData.StatusCauseEffect &&
              this.viewStageData.StatusCauseEffect == 'InProgress') ||
            !this.viewStageData.StatusCauseEffect ||
            this.viewStageData.StatusCauseEffect == 'Open'
          ) {
            this.selectedIndex = 2;
          } else if (
            (this.viewStageData.StatusFiveWhy &&
              this.viewStageData.StatusFiveWhy == 'InProgress') ||
            !this.viewStageData.StatusFiveWhy ||
            this.viewStageData.StatusFiveWhy == 'Open'
          ) {
            this.selectedIndex = 3;
          } else if (
            (this.viewStageData.StatusFiveWhyAction &&
              this.viewStageData.StatusFiveWhyAction == 'InProgress') ||
            !this.viewStageData.StatusFiveWhyAction ||
            this.viewStageData.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedIndex = 4;
          }

          this.setFishboneDataView();
          this.setCauseEffectDataView();
          this.setFiveWhyDataView();
          this.setFivewhyActionDataView();
        } else if (this.viewStageData.stageId == 2) {
          if (
            this.viewStageData.StatusCycle &&
            (this.viewStageData.StatusCycle == 'Completed' ||
              this.viewStageData.StatusCycle == 'Closed')
          ) {
            this.selectedIndex = 4;
          } else if (
            (this.viewStageData.StatusFishbone &&
              this.viewStageData.StatusFishbone == 'InProgress') ||
            !this.viewStageData.StatusFishbone ||
            this.viewStageData.StatusFishbone == 'Open'
          ) {
            this.selectedIndex = 1;
          } else if (
            (this.viewStageData.StatusCauseEffect &&
              this.viewStageData.StatusCauseEffect == 'InProgress') ||
            !this.viewStageData.StatusCauseEffect ||
            this.viewStageData.StatusCauseEffect == 'Open'
          ) {
            this.selectedIndex = 2;
          } else if (
            (this.viewStageData.StatusFiveWhy &&
              this.viewStageData.StatusFiveWhy == 'InProgress') ||
            !this.viewStageData.StatusFiveWhy ||
            this.viewStageData.StatusFiveWhy == 'Open'
          ) {
            this.selectedIndex = 3;
          } else if (
            (this.viewStageData.StatusFiveWhyAction &&
              this.viewStageData.StatusFiveWhyAction == 'InProgress') ||
            !this.viewStageData.StatusFiveWhyAction ||
            this.viewStageData.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedIndex = 4;
          }

          this.setFishboneDataView();
          this.setCauseEffectDataView();
          this.setFiveWhyDataView();
          this.setFivewhyActionDataView();
        } else if (this.viewStageData.stageId == 3) {
          if (
            this.viewStageData.StatusCycle &&
            (this.viewStageData.StatusCycle == 'Completed' ||
              this.viewStageData.StatusCycle == 'Closed')
          ) {
            this.selectedIndex = 4;
          } else if (
            (this.viewStageData.StatusCauseEffect &&
              this.viewStageData.StatusCauseEffect == 'InProgress') ||
            !this.viewStageData.StatusCauseEffect ||
            this.viewStageData.StatusCauseEffect == 'Open'
          ) {
            this.selectedIndex = 2;
          } else if (
            (this.viewStageData.StatusFiveWhy &&
              this.viewStageData.StatusFiveWhy == 'InProgress') ||
            !this.viewStageData.StatusFiveWhy ||
            this.viewStageData.StatusFiveWhy == 'Open'
          ) {
            this.selectedIndex = 3;
          } else if (
            (this.viewStageData.StatusFiveWhyAction &&
              this.viewStageData.StatusFiveWhyAction == 'InProgress') ||
            !this.viewStageData.StatusFiveWhyAction ||
            this.viewStageData.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedIndex = 4;
          }

          this.setCauseEffectDataView();
          this.setFiveWhyDataView();
          this.setFivewhyActionDataView();
        } else if (this.viewStageData.stageId == 4) {
          if (
            this.viewStageData.StatusCycle &&
            (this.viewStageData.StatusCycle == 'Completed' ||
              this.viewStageData.StatusCycle == 'Closed')
          ) {
            this.selectedIndex = 4;
          } else if (
            (this.viewStageData.StatusFiveWhy &&
              this.viewStageData.StatusFiveWhy == 'InProgress') ||
            !this.viewStageData.StatusFiveWhy ||
            this.viewStageData.StatusFiveWhy == 'Open'
          ) {
            this.selectedIndex = 3;
          } else if (
            (this.viewStageData.StatusFiveWhyAction &&
              this.viewStageData.StatusFiveWhyAction == 'InProgress') ||
            !this.viewStageData.StatusFiveWhyAction ||
            this.viewStageData.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedIndex = 4;
          }
          this.setFiveWhyDataView();
          this.setFivewhyActionDataView();
        } else if (this.viewStageData.stageId == 5) {
          if (
            this.viewStageData.StatusCycle &&
            (this.viewStageData.StatusCycle == 'Completed' ||
              this.viewStageData.StatusCycle == 'Closed')
          ) {
            this.selectedIndex = 4;
          } else if (
            (this.viewStageData.StatusFiveWhyAction &&
              this.viewStageData.StatusFiveWhyAction == 'InProgress') ||
            !this.viewStageData.StatusFiveWhyAction ||
            this.viewStageData.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedIndex = 4;
          }
          this.setFivewhyActionDataView();
        }

        let dialogRef = this.dialog.open(this.viewAtStages, {
          minWidth: '95%',
          maxWidth: '95%',
          minHeight: '95vh',
          maxHeight: '95vh',
        });
        dialogRef.afterOpened().subscribe((result) => {
          this.spinner.hide();
        });
      }, 1000);
    } else if (col == 'Report') {
      if (
        params.data.StatusCycle &&
        (params.data.StatusCycle == 'Closed' ||
          params.data.StatusCycle == 'Completed')
      ) {
        this.viewStageData = params.data;
        let InitImageURL =
          params.data && params.data.UploadFile
            ? `<img src="${params.data.UploadFile}" style="width:125px;height:auto;display: block; margin: 5px auto;">`
            : '';

        let refocusedImageURL =
          params.data && params.data.fishBone && params.data.fishBone.UploadFile
            ? `<img src="${this.ImageUrl + params.data.fishBone.UploadFile
            }" style="width:125px;height:auto;display: block; margin: 5px auto;">`
            : '';

        this.dialogParticipantsList = params.data.Participants;
        this.dialogParticipantsList.map((team: any) => {
          team.isLeader =
            team.fullName == params.data.TeamLeader ? true : false;
        });

        let ParticipantsTableRow: any = '';
        for (const participants of this.dialogParticipantsList) {
          ParticipantsTableRow += `
          <tr>
            <td colspan="6" class="row-text">
              ${participants.fullName}
              <span>${participants.isLeader ? '(Leader)' : ''}</span>
            </td>
          </tr>`;
        }

        let machineData: any = '';
        let materialData: any = '';
        let manData: any = '';
        let methodData: any = '';
        let motherNatureData: any = '';
        let managementData: any = '';

        let sixWtwoHTableRow: any = '';

        let fishBoneTableRow: any = '';

        let causeTableRow: any = '';

        let fivewhyTableRow: any = '';

        let fivewhyActionTableRow: any = '';

        if (this.viewStageData.swth) {
          sixWtwoHTableRow = `
            <tr class="six-bgc">
              <td colspan="17" class="heading-text">6W2H</td>           
            </tr>
            <tr class="six-sub-bgc">
              <td colspan="2" class="heading-text">What</td>
              <td colspan="2" class="heading-text">Where</td>
              <td colspan="2" class="heading-text">When</td>
              <td colspan="2" class="heading-text">Which</td>
              <td colspan="2" class="heading-text">Who</td>
              <td colspan="2" class="heading-text">How</td>
              <td colspan="2" class="heading-text">HowMuch</td>
              <td colspan="2" class="heading-text">ToWhom</td>
            </tr>
            <tr>
              <td colspan="2" class="row-text-center">${this.viewStageData.swth.What ? this.viewStageData.swth.What : ''
            }</td>
              <td colspan="2" class="row-text-center">${this.viewStageData.swth.Where
              ? this.viewStageData.swth.Where
              : ''
            }</td>
              <td colspan="2" class="row-text-center">${this.viewStageData.swth.When ? this.viewStageData.swth.When : ''
            }</td>
              <td colspan="2" class="row-text-center">${this.viewStageData.swth.Which
              ? this.viewStageData.swth.Which
              : ''
            }</td>
              <td colspan="2" class="row-text-center">${this.viewStageData.swth.Who ? this.viewStageData.swth.Who : ''
            }</td>
              <td colspan="2" class="row-text-center">${this.viewStageData.swth.How ? this.viewStageData.swth.How : ''
            }</td>
              <td colspan="2" class="row-text-center">${this.viewStageData.swth.HowMuch
              ? this.viewStageData.swth.HowMuch
              : ''
            }</td>
              <td colspan="2" class="row-text-center">${this.viewStageData.swth.ToWhom
              ? this.viewStageData.swth.ToWhom
              : ''
            }</td>
            </tr>`;
        }
        if (this.viewStageData.fishBone) {
          let MachineList = this.historicalMType.filter(
            (m: any) => m.MType == 1
          );
          let MaterialList = this.historicalMType.filter(
            (m: any) => m.MType == 2
          );
          let ManList = this.historicalMType.filter((m: any) => m.MType == 3);
          let MethodList = this.historicalMType.filter(
            (m: any) => m.MType == 4
          );
          let MotherNatureList = this.historicalMType.filter(
            (m: any) => m.MType == 5
          );
          let ManagementList = this.historicalMType.filter(
            (m: any) => m.MType == 6
          );

          let MachineValueArr =
            this.viewStageData &&
              this.viewStageData.fishBone &&
              this.viewStageData.fishBone.Machinery
              ? this.viewStageData.fishBone.Machinery.split(',').map(
                (num: any) => (num = parseInt(num))
              )
              : [];
          let MaterialValueArr =
            this.viewStageData &&
              this.viewStageData.fishBone &&
              this.viewStageData.fishBone.Material
              ? this.viewStageData.fishBone.Material.split(',').map(
                (num: any) => (num = parseInt(num))
              )
              : [];
          let ManValueArr =
            this.viewStageData &&
              this.viewStageData.fishBone &&
              this.viewStageData.fishBone.Man
              ? this.viewStageData.fishBone.Man.split(',').map(
                (num: any) => (num = parseInt(num))
              )
              : [];
          let MethodValueArr =
            this.viewStageData &&
              this.viewStageData.fishBone &&
              this.viewStageData.fishBone.Method
              ? this.viewStageData.fishBone.Method.split(',').map(
                (num: any) => (num = parseInt(num))
              )
              : [];
          let MotherNatureValueArr =
            this.viewStageData &&
              this.viewStageData.fishBone &&
              this.viewStageData.fishBone.MotherNature
              ? this.viewStageData.fishBone.MotherNature.split(',').map(
                (num: any) => (num = parseInt(num))
              )
              : [];
          let ManagementValueArr =
            this.viewStageData &&
              this.viewStageData.fishBone &&
              this.viewStageData.fishBone.Management
              ? this.viewStageData.fishBone.Management.split(',').map(
                (num: any) => (num = parseInt(num))
              )
              : [];

          if (MachineValueArr && MachineValueArr.length) {
            for (const machineValue of MachineValueArr) {
              let machineObj = MachineList.find(
                (m1: any) => m1.ID == machineValue
              );
              machineData += machineObj ? machineObj.Name + ' , ' : '';
            }
            machineData = machineData.substring(0, machineData.length - 2);
          }

          if (MaterialValueArr && MaterialValueArr.length) {
            for (const materialValue of MaterialValueArr) {
              let materialObj = MaterialList.find(
                (m2: any) => m2.ID == materialValue
              );
              materialData += materialObj ? materialObj.Name + ' , ' : '';
            }
            materialData = materialData.substring(0, materialData.length - 2);
          }

          if (ManValueArr && ManValueArr.length) {
            for (const manValue of ManValueArr) {
              let manObj = ManList.find((m3: any) => m3.ID == manValue);
              manData += manObj ? manObj.Name + ' , ' : '';
            }
            manData = manData.substring(0, manData.length - 2);
          }

          if (MethodValueArr && MethodValueArr.length) {
            for (const methodValue of MethodValueArr) {
              let methodObj = MethodList.find(
                (m4: any) => m4.ID == methodValue
              );
              methodData += methodObj ? methodObj.Name + ' , ' : '';
            }
            methodData = methodData.substring(0, methodData.length - 2);
          }

          if (MotherNatureValueArr && MotherNatureValueArr.length) {
            for (const motherNatureValue of MotherNatureValueArr) {
              let motherNatureObj = MotherNatureList.find(
                (m5: any) => m5.ID == motherNatureValue
              );
              motherNatureData += motherNatureObj
                ? motherNatureObj.Name + ' , '
                : '';
            }
            motherNatureData = motherNatureData.substring(
              0,
              motherNatureData.length - 2
            );
          }

          if (ManagementValueArr && ManagementValueArr.length) {
            for (const managementValue of ManagementValueArr) {
              let managementObj = ManagementList.find(
                (m6: any) => m6.ID == managementValue
              );
              managementData += managementObj ? managementObj.Name + ' , ' : '';
            }
            managementData = managementData.substring(
              0,
              managementData.length - 2
            );
          }

          fishBoneTableRow = `
            <tr class="fish-bgc">
              <td colspan="17" class="heading-text">Fishbone</td>           
            </tr>
            <tr>
              <td colspan="2.5" class="heading-sub-text fish-sub-bgc">Machine : </td>  
              <td colspan="6" class="row-text">${machineData}</td>   
              <td colspan="2.5" class="heading-sub-text fish-sub-bgc">Method : </td>  
              <td colspan="6" class="row-text">${methodData}</td>         
            </tr>
            <tr>
              <td colspan="2.5" class="heading-sub-text fish-sub-bgc">Material : </td>  
              <td colspan="6" class="row-text">${materialData}</td>   
              <td colspan="2.5" class="heading-sub-text fish-sub-bgc">Mother Nature : </td>  
              <td colspan="6" class="row-text">${motherNatureData}</td>              
            </tr>
            <tr>
              <td colspan="2.5" class="heading-sub-text fish-sub-bgc">Man : </td>  
              <td colspan="6" class="row-text">${manData}</td>     
              <td colspan="2.5" class="heading-sub-text fish-sub-bgc">Management : </td>  
              <td colspan="6" class="row-text">${managementData}</td>       
            </tr>`;
        }
        if (
          this.viewStageData.causeAndEffects &&
          this.viewStageData.causeAndEffects.length
        ) {
          this.viewStageData.causeAndEffects.sort(function (a: any, b: any) {
            return a.Priority - b.Priority;
          });
          this.viewStageData.causeAndEffects.sort(function (a: any, b: any) {
            return a.MType.MType - b.MType.MType;
          });

          causeTableRow = `
            <tr class="cause-bgc">
              <td colspan="17" class="heading-text">Cause & Effect</td>           
            </tr>
            <tr class="cause-sub-bgc">
              <td colspan="4" class="heading-text">Contributing Source</td>
              <td class="heading-text">Priority</td>
              <td colspan="3" class="heading-text">Cause</td>
              <td class="heading-text">Weight</td>
              <td colspan="3" class="heading-text">Validation Result</td>
              <td class="heading-text">Cause Identified</td>
              <td colspan="3" class="heading-text">Remark</td>
            </tr>`;

          for (const cause of this.viewStageData.causeAndEffects) {
            if (cause.MType.MType == 1) cause.displayMType = 'Machine';
            else if (cause.MType.MType == 2) cause.displayMType = 'Material';
            else if (cause.MType.MType == 3) cause.displayMType = 'Man';
            else if (cause.MType.MType == 4) cause.displayMType = 'Method';
            else if (cause.MType.MType == 5)
              cause.displayMType = 'Mother Nature';
            else if (cause.MType.MType == 6) cause.displayMType = 'Management';

            causeTableRow += `
          <tr>
            <td colspan="4" class="row-text-center">${cause.displayMType}</td>
            <td class="row-text-center">${cause.Priority}</td>
            <td colspan="3" class="row-text-center">${cause.ProbableCause}</td>
            <td class="row-text-center">${cause.Weight}</td>
            <td colspan="3" class="row-text-center">${cause.ValidationResult
              }</td>
            <td class="row-text-center">${cause.CauseIdentified ? 'true' : 'false'
              }</td>
            <td colspan="3" class="row-text-center">${cause.Remark}</td>
          </tr>`;
          }
        }
        if (this.viewStageData.fiveWhy && this.viewStageData.fiveWhy.length) {
          this.viewStageData.fiveWhy.sort(function (a: any, b: any) {
            return a.Priority - b.Priority;
          });
          this.viewStageData.fiveWhy.sort(function (a: any, b: any) {
            return a.MType - b.MType;
          });

          if (this.viewStageData.stageId >= 4) {
            fivewhyTableRow = `
            <tr class="five-bgc">
              <td colspan="16" class="heading-text">5 Why</td>           
            </tr>
            <tr class="five-sub-bgc">
              <td colspan="3" class="heading-text">Cause</td>
              <td colspan="2" class="heading-text">Why1</td>
              <td colspan="2" class="heading-text">Why2</td>
              <td colspan="2" class="heading-text">Why3</td>
              <td colspan="2" class="heading-text">Why4</td>
              <td colspan="2" class="heading-text">Why5</td>
              <td colspan="2" class="heading-text">RootCause</td>
              <td colspan="1" class="heading-text">MType</td>
            </tr>`;
          } else {
            fivewhyTableRow = `
            <tr class="five-bgc">
              <td colspan="16" class="heading-text">5 Why</td>           
            </tr>
            <tr class="five-sub-bgc">
              <td class="heading-text">Contributing Source</td>
              <td class="heading-text">Priority</td>
              <td class="heading-text">Cause</td>
              <td class="heading-text">Weight</td>
              <td colspan="2" class="heading-text">Why1</td>
              <td colspan="2" class="heading-text">Why2</td>
              <td colspan="2" class="heading-text">Why3</td>
              <td colspan="2" class="heading-text">Why4</td>
              <td colspan="2" class="heading-text">Why5</td>
              <td colspan="1" class="heading-text">RootCause</td>
              <td colspan="1" class="heading-text">MType</td>
            </tr>`;
          }

          for (const five of this.viewStageData.fiveWhy) {
            if (five.MType == 1) five.displayMType = 'Machine';
            else if (five.MType == 2) five.displayMType = 'Material';
            else if (five.MType == 3) five.displayMType = 'Man';
            else if (five.MType == 4) five.displayMType = 'Method';
            else if (five.MType == 5) five.displayMType = 'Mother Nature';
            else if (five.MType == 6) five.displayMType = 'Management';

            const mRecords = this.viewStageData.fiveWhy.filter(
              (fiveY: any) =>
                five.MType == fiveY.MType && five.Priority == fiveY.Priority
                  && five.ValidationFromCauseEffects == fiveY.ValidationFromCauseEffects
                );
            if (mRecords && mRecords.length) {
              let disabled = mRecords.map((rec: any, index: number) => {
                if (index == 0)
                  rec.rowSpan =
                    mRecords && mRecords.length ? mRecords.length : 1;
                else rec.spanRow = true;
              });
            }

            const display = five.spanRow ? 'd-none' : '';

            if (this.viewStageData.stageId >= 4) {
              fivewhyTableRow += `
              <tr>
                <td colspan="3" class="row-text-center ${display}" rowspan="${five.rowSpan ? five.rowSpan : ''
                }">${five.ValidationFromCauseEffects}</td>
                <td colspan="2"  class="row-text-center">${five.Why1}</td>
                <td colspan="2" class="row-text-center">${five.Why2}</td>
                <td colspan="2" class="row-text-center">${five.Why3}</td>
                <td colspan="2" class="row-text-center">${five.Why4}</td>
                <td colspan="2" class="row-text-center">${five.Why5}</td>
                <td colspan="2" class="row-text-center">${five.RootCause}</td>
                <td colspan="1" class="row-text-center">${five && five.TypeOfM ? five.TypeOfM : ''
                }</td>
              </tr>`;
            } else {
              fivewhyTableRow += `
              <tr>
                <td class="row-text-center ${display}" rowspan="${five.rowSpan ? five.rowSpan : ''
                }">${five.displayMType}</td>
                <td class="row-text-center ${display}" rowspan="${five.rowSpan ? five.rowSpan : ''
                }">${five.Priority}</td>
                <td class="row-text-center ${display}" rowspan="${five.rowSpan ? five.rowSpan : ''
                }">${five.ValidationFromCauseEffects}</td>
                <td class="row-text-center ${display}" rowspan="${five.rowSpan ? five.rowSpan : ''
                }">${five.Weight ? five.Weight : ''}</td>
                <td colspan="2"  class="row-text-center">${five.Why1}</td>
                <td colspan="2" class="row-text-center">${five.Why2}</td>
                <td colspan="2" class="row-text-center">${five.Why3}</td>
                <td colspan="2" class="row-text-center">${five.Why4}</td>
                <td colspan="2" class="row-text-center">${five.Why5}</td>
                <td colspan="1" class="row-text-center">${five.RootCause}</td>
                <td colspan="1" class="row-text-center">${five && five.TypeOfM ? five.TypeOfM : ''
                }</td>
              </tr>`;
            }
          }
        }
        if (
          this.viewStageData.fiveWhyActionPlan &&
          this.viewStageData.fiveWhyActionPlan.length
        ) {
          fivewhyActionTableRow = `
          <tr class="five-action-bgc">
            <td colspan="17" class="heading-text">5 Why Action Plan</td>           
          </tr>
          <tr class="five-sub-action-bgc">
            <td class="heading-text">
              <span class="d-block">Root</span>
              <span class="d-block">Cause</span>
            </td>
            <td class="heading-text">Action</td>
            <td class="heading-text">
              <span class="d-block">Action</span>
              <span class="d-block">Type</span>
            </td>
            <td class="heading-text">AssignTo</td>
            <td class="heading-text">
              <span class="d-block">Assigned</span>
              <span class="d-block">Date</span>
            </td>
            <td class="heading-text">
              <span class="d-block">Target</span>
              <span class="d-block">Date</span>
            </td>
            <td class="heading-text">
              <span class="d-block">Revised</span>
              <span class="d-block">Date</span>
            </td>
            <td class="heading-text">
              <span class="d-block">NoOf</span>
              <span class="d-block">Misses</span>
            </td>
            <td class="heading-text">
              <span class="d-block">Action</span>
              <span class="d-block">Completed</span>
            </td>
            <td class="heading-text">
              <span class="d-block">Action</span>
              <span class="d-block">Completed(By&On)</span>
            </td>
            <td class="heading-text">Verification</td>
            <td class="heading-text">
              <span class="d-block">Verification</span>
              <span class="d-block">(By&On)</span>
            </td>
            <td class="heading-text">
              <span class="d-block">New</span>
              <span class="d-block">Assignee</span>
            </td>
            <td class="heading-text">
              <span class="d-block">Closed</span>
              <span class="d-block">Date</span>
            </td>
            <td class="heading-text">Remarks</td>
            <td class="heading-text">Status</td>
          </tr>`;

          for (const fiveAction of this.viewStageData.fiveWhyActionPlan) {

            if (fiveAction.fiveWhy && fiveAction.fiveWhy.ID) {
              const mRecords = this.viewStageData.fiveWhyActionPlan.filter(
                (fiveY: any) =>
                  fiveY.fiveWhy && fiveY.fiveWhy.ID == fiveAction.fiveWhy.ID
              );
              if (mRecords && mRecords.length) {
                let disabled = mRecords.map((rec: any, index: number) => {
                  if (index == 0)
                    rec.rowSpan =
                      mRecords && mRecords.length ? mRecords.length : 1;
                  else {
                    rec.spanRow = true;
                  }
                });
              }
            }

            const display = fiveAction.spanRow ? 'd-none' : '';

            fivewhyActionTableRow += `
              <tr>
                <td class="row-text-center ${display}" rowspan="${fiveAction.rowSpan ? fiveAction.rowSpan : ''
              }">${fiveAction.RootCause ?? ''}</td>
                <td class="row-text-center">${fiveAction.Action ?? ''}</td>
                <td class="row-text-center">${fiveAction.ActionType ?? ''}</td>
                <td class="row-text-center">${this.getTeamName(
                fiveAction.AssignTo,
                'assignTo'
              )}</td>
                <td class="row-text-center">${fiveAction.AssignedDate
                ? moment(fiveAction.AssignedDate).format('DD/MM/YYYY')
                : ''
              }</td>
                <td class="row-text-center">${fiveAction.TargetDate
                ? moment(fiveAction.TargetDate).format('DD/MM/YYYY')
                : ''
              }</td>
                <td class="row-text-center">${fiveAction.RevisedDate
                ? moment(fiveAction.RevisedDate).format('DD/MM/YYYY')
                : ''
              }</td>
                <td class="row-text-center">${fiveAction.NoOfMisses ?? ''}</td>
                <td class="row-text-center">${fiveAction.ActionCompleted ?? ''
              }</td>
                <td class="row-text-center"><span class="d-block">${this.getUserName(
                fiveAction,
                'actionBy'
              )}</span><span class="d-block">${fiveAction.ActionCompletedOn
                ? moment(fiveAction.ActionCompletedOn).format('DD/MM/YYYY')
                : ''
              }</span></td>
                <td class="row-text-center">${fiveAction.Verification ?? ''
              }</td>
                <td class="row-text-center"><span class="d-block">${this.getUserName(
                fiveAction,
                'verificationBy'
              )}</span><span class="d-block">${fiveAction.VerificationOn
                ? moment(fiveAction.VerificationOn).format('DD/MM/YYYY')
                : ''
              }</span></td>
                <td class="row-text-center">${fiveAction.NewAssignee
                ? this.getTeamName(fiveAction.NewAssignee, 'newAssignTo')
                : ''
              }</td>
                <td class="row-text-center">${fiveAction.ClosedDate
                ? moment(fiveAction.ClosedDate).format('DD/MM/YYYY')
                : ''
              }</td>
                <td class="row-text-center">${fiveAction.Remarks ?? ''}</td>
                <td class="row-text-center">${fiveAction.Status ?? ''}</td>
              </tr>`;
          }
        }

        const WindowPrt: any = window.open('', '_blank');

        let style = `
        <style>
          table, td, th {border: 1px solid black;font-family: system-ui;}
          table {width: 100%;border-collapse: collapse;}
          .d-none{
            display: none;
          }
          .d-flex{
            display: flex;
          }
          .d-block{
            display: block;
          }
          .heading-text{
            font-weight:bold;font-size:12px;text-align:center;
          }
          .heading-sub-text{
            font-weight:bold;font-size:12px;
          }
          .row-text{
            font-size:11px;
          }
          .row-text-center{
            font-size:11px;text-align:center;
          }
          @media print {
            .six-bgc{
              background: #ffb194;
              -webkit-print-color-adjust: exact; 
            }
            .six-sub-bgc{
              background: #ffd1c0;
              -webkit-print-color-adjust: exact; 
            }
            .fish-bgc{
              background: #a4d5ff;
              -webkit-print-color-adjust: exact; 
            }
            .fish-sub-bgc{
              background: #cce8ff;
              -webkit-print-color-adjust: exact; 
            }
            .cause-bgc{
              background: #ffb95f;
              -webkit-print-color-adjust: exact; 
            }
            .cause-sub-bgc{
              background: #ffcd8c;
              -webkit-print-color-adjust: exact; 
            }
            .five-bgc{
              background: #cfa4cf;
              -webkit-print-color-adjust: exact; 
            }
            .five-sub-bgc{
              background: #ebc5eb;
              -webkit-print-color-adjust: exact; 
            }
            .five-action-bgc{
              background: lightpink;
              -webkit-print-color-adjust: exact; 
            }
            .five-sub-action-bgc{
              background: #ffcbd3;
              -webkit-print-color-adjust: exact; 
            }
          }
          .six-bgc{
            background: #ffb194;
          }
          .six-sub-bgc{
            background: #ffd1c0;
          }
          .fish-bgc{
            background: #a4d5ff;
          }
          .fish-sub-bgc{
            background: #cce8ff;
          }
          .cause-bgc{
            background: #ffb95f;
          }
          .cause-sub-bgc{
            background: #ffcd8c;
          }
          .five-bgc{
            background: #cfa4cf;
          }
          .five-sub-bgc{
            background: #ebc5eb;
          }
          .five-action-bgc{
            background: lightpink;
          }
          .five-sub-action-bgc{
            background: #ffcbd3;
          }
        </style>`;

        WindowPrt.document.write(style);

        let body = `
        <table data-show-print="true" id="table1" style="width:100%" class="table">
          <thead>
          </thead>
          <tbody>
            <tr>
              <td colspan="6" style="font-size:18px;font-weight:bold;text-align:center">
             ${this.historyHeaderData.Plant === 22
            ? '   <img style="width:50px;height:50px;" src="../../../assets/images/siglogo.png">'
            : 'RCA Report'
          }
              </td>
              <td colspan="6" style="font-size:18px;font-weight:bold;text-align:center">Report / Code Number : AT-RCA-${this.viewStageData.ID
          }</td>
              <td colspan="4" style="text-align:center;padding: 10px 0;"><img src="../../../assets/images/o3-smart-manufacturing.PNG"></td>
            </tr>
            <tr>
              <td colspan="3" style="font-size:15px;font-weight:bold;">Group : </td>
              <td colspan="3" class="row-text">${this.viewStageData.Group}</td>
              <td style="font-size:15px;font-weight:bold;vertical-align: top;" rowspan="4" colspan="7">Initial Problem : <span style="font-size:14px;font-weight: 400;">${this.viewStageData.InitProbStatement
          }</span></td>
              <td colspan="3" style="text-align:center;" rowspan="${this.dialogParticipantsList.length + 8
          }">${InitImageURL}${refocusedImageURL}</td>
            </tr>
            <tr>
              <td colspan="3" style="font-size:15px;font-weight:bold;">Plant : </td>
              <td colspan="3" class="row-text">${this.viewStageData.Plant}</td>
            </tr>
            <tr>
            <td colspan="3" style="font-size:15px;font-weight:bold;">Line : </td>
            <td colspan="3" class="row-text">${this.viewStageData.Line}</td>
          </tr>
            <tr>
              <td colspan="3" style="font-size:15px;font-weight:bold;">Department : </td>
              <td colspan="3" class="row-text">${this.viewStageData.Department
          }</td>
            </tr>
            <tr>
              <td colspan="6" style="font-size:15px;font-weight:bold;">Participants</td>
              <td colspan="7" style="font-size:15px;font-weight:bold;vertical-align: top;" rowspan="${this.dialogParticipantsList.length + 1
          }">Refocused Problem Statement : <span style="font-size:14px;font-weight: 400;">${this.viewStageData.swth
            ? this.viewStageData.swth.ProblemStatement
            : this.viewStageData.fishBone &&
              this.viewStageData.fishBone.ProblemStatement
              ? this.viewStageData.fishBone.ProblemStatement
              : ''
          }</span></td>
            </tr>
            <tr>
            <td colspan="3" style="font-size:15px;font-weight:bold;">Severity : </td>
            <td colspan="3" class="row-text">${this.viewStageData?.Severity || ''
          }</td>
          </tr>
          <tr>
          <td colspan="3" style="font-size:15px;font-weight:bold;">Customer : </td>
          <td colspan="3" class="row-text">${this.viewStageData?.Customer || ''
          }</td>
          <td style="font-size:15px;font-weight:bold;vertical-align: top;" rowspan="3" colspan="7">Detail Notes : <span style="font-size:14px;font-weight: 400;">${this.viewStageData.DetailNotes || ''
          }</span></td>
          <tr>
          <td colspan="3" style="font-size:15px;font-weight:bold;">NCR Category : </td>
          <td colspan="3" class="row-text">${this.viewStageData?.NCRCategory || ''
          }</td>
        </tr>
        </tr>
            ${ParticipantsTableRow}
            ${sixWtwoHTableRow}
            ${fishBoneTableRow}
            ${causeTableRow}
            ${fivewhyTableRow}
            ${fivewhyActionTableRow}
          </tbody>
        </table>`;

        WindowPrt.document.write(body);

        setTimeout(() => {
          WindowPrt.document.close();
          WindowPrt.focus();
          WindowPrt.print();
          WindowPrt.close();
        }, 3000);
      } else return;
    } else if (col == 'UploadFile') {
      if (params && params.value)
        this.imageZoomModal(params.value, 'initialImage');
      else this.imageZoomModal('assets/images/no-image.png', 'initialImage');
    }
  }

  tabChanged(event: any) {
    if (event.index == 0) {
    } else if (event.index == 1) {
    } else if (event.index == 2) {
    } else if (event.index == 3) {
    } else if (event.index == 4) {
    }
  }

  tabHeaderChanged(event: any) {
    this.selectedHeaderIndex = event.index;
    if (event.index == 0) {
      this.onSearch();
    } else if (event.index == 1) {
    }
  }

  isDisabled(stageName: any): any {
    if (stageName == '6W2H') {
      return this.viewStageData && this.viewStageData.stageId == 1
        ? false
        : true;
    } else if (stageName == 'FishBone') {
      return this.viewStageData &&
        (this.viewStageData.stageId == 1 || this.viewStageData.stageId == 2)
        ? false
        : true;
    } else if (stageName == 'Cause & Effect') {
      return this.viewStageData &&
        (this.viewStageData.stageId == 1 ||
          this.viewStageData.stageId == 2 ||
          this.viewStageData.stageId == 3)
        ? false
        : true;
    } else if (stageName == '5 Why') {
      return this.viewStageData &&
        (this.viewStageData.stageId == 1 ||
          this.viewStageData.stageId == 2 ||
          this.viewStageData.stageId == 3 ||
          this.viewStageData.stageId == 4)
        ? false
        : true;
    } else if (stageName == '5 Why Action Plan') {
      return this.viewStageData &&
        (this.viewStageData.stageId == 1 ||
          this.viewStageData.stageId == 2 ||
          this.viewStageData.stageId == 3 ||
          this.viewStageData.stageId == 4 ||
          this.viewStageData.stageId == 5)
        ? false
        : true;
    }
  }
  getCauseValue(val: any) {
    if (val.length > 40) {
      let text = val.substring(0, 40); //cuts to 1024
      let last = text.lastIndexOf(' '); //gets last space (to avoid cutting the middle of a word)
      text = text.substring(0, 40); //cuts from last space (to avoid cutting the middle of a word)
      text = text + ` ...`; //adds (...) at the end to show that it's cut
      return text;
    } else return val;
  }
  getShortData(data: any) {
    let strLength = this.viewStageData.stageId != 4 ? 25 : 40;
    if (data.length > strLength) {
      let text = data.substring(0, strLength); //cuts to 1024
      let last = text.lastIndexOf(' '); //gets last space (to avoid cutting the middle of a word)
      text = text.substring(0, strLength); //cuts from last space (to avoid cutting the middle of a word)
      text = text + ` ...`; //adds (...) at the end to show that it's cut
      return text;
    } else return data;
  }
  getUserName(row: any, type: any) {
    if (type == 'actionBy') {
      let objFindName = this.userAllList.find(
        (usr: any) => usr.UserId == row.ActionCompletedBy
      );
      if (objFindName) return objFindName.UserName;
      else if (row.ActionCompletedBy == 100001) return 'Admin';
      else return null;
    } else if (type == 'verificationBy') {
      let objFindName = this.userAllList.find(
        (usr: any) => usr.UserId == row.VerificationBy
      );
      if (objFindName) return objFindName.UserName;
      else if (row.VerificationBy == 100001) return 'Admin';
      else return null;
    } else if (type == 'verifiedBy') {
      let objFindName = this.userAllList.find(
        (usr: any) => usr.UserId == row.VerifiedBy
      );
      if (objFindName) return objFindName.UserName;
      else if (row.VerifiedBy == 100001) return 'Admin';
      else return null;
    }
  }
  getTeamName(val: any, type: any) {
    if (type == 'assignTo') {
      let objAssignName = this.teamList.find(
        (usr: any) => usr.EmpInfo_Id == val
      );
      if (objAssignName) return objAssignName.fullName;
      else return '';
    } else if (type == 'newAssignTo') {
      let objNewAssignName = this.teamList.find(
        (usr: any) => usr.EmpInfo_Id == val
      );
      if (objNewAssignName) return objNewAssignName.fullName;
      else return '';
    }
  }
  getMinDate(date: any) {
    let currentDate = new Date(date);
    return new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  imageZoomModal(i: any, type: any) {
    if (type == 'causeEffect') {
      let dialogRef = this.dialog.open(ImageDialogComponent, {
        height: '90%',
        width: '90%',
        data:
          this.viewStageData.causeAndEffects[i].FileSrc ||
          this.ImageUrl + this.viewStageData.causeAndEffects[i].UploadFile,
      });
    } else if (type == 'fiveAction') {
      let dialogRef = this.dialog.open(ImageDialogComponent, {
        height: '90%',
        width: '90%',
        data:
          this.viewStageData.fiveWhyActionPlan[i].FileSrc ||
          this.ImageUrl + this.viewStageData.fiveWhyActionPlan[i].UploadFile,
      });
    } else if (type == 'initialImage') {
      let dialogRef = this.dialog.open(ImageDialogComponent, {
        height: '90%',
        width: '90%',
        data: i,
      });
    } else if (type == 'refocusedImage') {
      let dialogRef = this.dialog.open(ImageDialogComponent, {
        height: '90%',
        width: '90%',
        data: this.ImageUrl + this.RefocusedUploadFile,
      });
    }
  }
  updateIndex(arr: any) {
    arr.sort(function (a: any, b: any) {
      return a.Priority - b.Priority;
    });
    arr.sort(function (a: any, b: any) {
      return a.M - b.M;
    });
    arr.forEach((element: any, index: any) => (element.index = index));

    return arr;
  }

  setFishboneDataView() {
    this.MachineryChecked =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.Machinery
        ? true
        : false;
    this.MaterialChecked =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.Material
        ? true
        : false;
    this.ManChecked =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.Man
        ? true
        : false;
    this.MethodChecked =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.Method
        ? true
        : false;
    this.MotherNatureChecked =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.MotherNature
        ? true
        : false;
    this.ManagementChecked =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.Management
        ? true
        : false;
    this.RefocusedUploadFile =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.UploadFile
        ? this.viewStageData.fishBone.UploadFile
        : '';

    this.mList = {
      machineryList: [],
      materialList: [],
      manList: [],
      methodList: [],
      motherNatureList: [],
      managementList: [],
    };
    this.mType = {
      machinery: [],
      material: [],
      man: [],
      method: [],
      motherNature: [],
      management: [],
    };

    const plantObj = this.plantList.find(
      (pla: any) => pla.DeptDesc == this.viewStageData.Plant
    );

    this.orgMachineryList,
      this.orgMaterialList,
      this.orgManList,
      this.motherNatureList,
      this.orgMethodList,
      (this.orgManagementList = []);

    this.mList.machineryList = this.historicalMType.filter(
      (m: any) => m.MType == 1 && !m.Plant
    );
    let machineListByPlant = this.historicalMType.filter(
      (m: any) => m.MType == 1 && m.Plant && m.Plant == plantObj.DeptId
    );
    this.mList.machineryList =
      this.mList.machineryList.concat(machineListByPlant);
    this.orgMachineryList = [...this.mList.machineryList];
    this.orgMachineryList.map((machinery: any) =>
      this.machineryList.push([...this.mList.machineryList])
    );

    this.mList.materialList = this.historicalMType.filter(
      (m: any) => m.MType == 2 && !m.Plant
    );
    let materialListByPlant = this.historicalMType.filter(
      (m: any) => m.MType == 2 && m.Plant && m.Plant == plantObj.DeptId
    );
    this.mList.materialList =
      this.mList.materialList.concat(materialListByPlant);
    this.orgMaterialList = [...this.mList.materialList];
    this.orgMaterialList.map((material: any) =>
      this.materialList.push([...this.mList.materialList])
    );

    this.mList.manList = this.historicalMType.filter(
      (m: any) => m.MType == 3 && !m.Plant
    );
    let manListByPlant = this.historicalMType.filter(
      (m: any) => m.MType == 3 && m.Plant && m.Plant == plantObj.DeptId
    );
    this.mList.manList = this.mList.manList.concat(manListByPlant);
    this.orgManList = [...this.mList.manList];
    this.orgManList.map((man: any) =>
      this.manList.push([...this.mList.manList])
    );

    this.mList.methodList = this.historicalMType.filter(
      (m: any) => m.MType == 4 && !m.Plant
    );
    let methodListByPlant = this.historicalMType.filter(
      (m: any) => m.MType == 4 && m.Plant && m.Plant == plantObj.DeptId
    );
    this.mList.methodList = this.mList.methodList.concat(methodListByPlant);
    this.orgMethodList = [...this.mList.methodList];
    this.orgMethodList.map((man: any) =>
      this.methodList.push([...this.mList.methodList])
    );

    this.mList.motherNatureList = this.historicalMType.filter(
      (m: any) => m.MType == 5 && !m.Plant
    );
    let motherListByPlant = this.historicalMType.filter(
      (m: any) => m.MType == 5 && m.Plant && m.Plant == plantObj.DeptId
    );
    this.mList.motherNatureList =
      this.mList.motherNatureList.concat(motherListByPlant);
    this.orgMotherNatureList = [...this.mList.motherNatureList];
    this.orgMotherNatureList.map((motherNature: any) =>
      this.motherNatureList.push([...this.mList.motherNatureList])
    );

    this.mList.managementList = this.historicalMType.filter(
      (m: any) => m.MType == 6 && !m.Plant
    );
    let managementListByPlant = this.historicalMType.filter(
      (m: any) => m.MType == 6 && m.Plant && m.Plant == plantObj.DeptId
    );
    this.mList.managementList = this.mList.managementList.concat(
      managementListByPlant
    );
    this.orgManagementList = [...this.mList.managementList];
    this.orgManagementList.map((management: any) =>
      this.managementList.push([...this.mList.managementList])
    );

    this.mType.machinery =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.Machinery
        ? this.viewStageData.fishBone.Machinery.split(',').map(
          (num: any) => (num = parseInt(num))
        )
        : [];
    this.mType.material =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.Material
        ? this.viewStageData.fishBone.Material.split(',').map(
          (num: any) => (num = parseInt(num))
        )
        : [];
    this.mType.man =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.Man
        ? this.viewStageData.fishBone.Man.split(',').map(
          (num: any) => (num = parseInt(num))
        )
        : [];
    this.mType.method =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.Method
        ? this.viewStageData.fishBone.Method.split(',').map(
          (num: any) => (num = parseInt(num))
        )
        : [];
    this.mType.motherNature =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.MotherNature
        ? this.viewStageData.fishBone.MotherNature.split(',').map(
          (num: any) => (num = parseInt(num))
        )
        : [];
    this.mType.management =
      this.viewStageData &&
        this.viewStageData.fishBone &&
        this.viewStageData.fishBone.Management
        ? this.viewStageData.fishBone.Management.split(',').map(
          (num: any) => (num = parseInt(num))
        )
        : [];

    let index = 0;
    let filteredList = [];
    for (let machinery of this.machineryList) {
      filteredList = machinery.filter(
        (mac: any) => !this.mType.machinery.includes(mac.ID)
      );
      const currentMachinery = machinery.find(
        (mac: any) => this.mType.machinery[index] == mac.ID
      );
      currentMachinery && filteredList.push(currentMachinery);
      this.machineryList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
    for (let material of this.materialList) {
      filteredList = material.filter(
        (mac: any) => !this.mType.material.includes(mac.ID)
      );
      const currentMaterial = material.find(
        (mac: any) => this.mType.material[index] == mac.ID
      );
      currentMaterial && filteredList.push(currentMaterial);
      this.materialList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
    for (let man of this.manList) {
      filteredList = man.filter((mac: any) => !this.mType.man.includes(mac.ID));
      const currentMan = man.find(
        (mac: any) => this.mType.man[index] == mac.ID
      );
      currentMan && filteredList.push(currentMan);
      this.manList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
    for (let method of this.methodList) {
      filteredList = method.filter(
        (mac: any) => !this.mType.method.includes(mac.ID)
      );
      const currentMethod = method.find(
        (mac: any) => this.mType.method[index] == mac.ID
      );
      currentMethod && filteredList.push(currentMethod);
      this.methodList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
    for (let motherNature of this.motherNatureList) {
      filteredList = motherNature.filter(
        (mac: any) => !this.mType.motherNature.includes(mac.ID)
      );
      const currentMotherNature = motherNature.find(
        (mac: any) => this.mType.motherNature[index] == mac.ID
      );
      currentMotherNature && filteredList.push(currentMotherNature);
      this.motherNatureList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
    for (let management of this.managementList) {
      filteredList = management.filter(
        (mac: any) => !this.mType.management.includes(mac.ID)
      );
      const currentManagement = management.find(
        (mac: any) => this.mType.management[index] == mac.ID
      );
      currentManagement && filteredList.push(currentManagement);
      this.managementList[index] = filteredList;
      index++;
    }
    index = 0;
    filteredList = [];
  }

  setCauseEffectDataView() {
    let index = 0;
    let fishBoneDataNotInCause: any = [];

    if (this.viewStageData.stageId != 3) {
      if (
        !this.viewStageData.StatusCauseEffect ||
        this.viewStageData.StatusCauseEffect == 'Open'
      ) {
        this.viewStageData.causeAndEffects = [];
      } else {
        if (
          this.viewStageData &&
          this.viewStageData.causeAndEffects &&
          !this.viewStageData.causeAndEffects.length &&
          this.viewStageData.fishBone
        ) {
          const MachineryArr = this.viewStageData.fishBone.Machinery.split(',');
          const MaterialArr = this.viewStageData.fishBone.Material.split(',');
          const ManArr = this.viewStageData.fishBone.Man.split(',');
          const MethodArr = this.viewStageData.fishBone.Method.split(',');
          const MotherNatureArr =
            this.viewStageData.fishBone.MotherNature.split(',');
          const ManagementArr =
            this.viewStageData.fishBone.Management.split(',');
          let machineIndex: any = 1,
            materialIndex: any = 1,
            methodIndex: any = 1,
            motherNatureIndex: any = 1,
            managementIndex: any = 1,
            manIndex: any = 1;
          for (const machinery of MachineryArr) {
            let val = this.historicalMType.find(
              (mtype: any) => mtype.ID == machinery
            );
            if (val) {
              val.M = val.MType;
              val.ProbableCause = val.Name;
              val.displayMType = 'Machine';
              val.Priority = machineIndex;
              val.Weight = 2;
              val.ValidationResult = '';
              val.CauseIdentified = false;
              val.Remark = '';
              val.MType = { MType: val.M };
              this.viewStageData.causeAndEffects.push(val);
              machineIndex++;
            }
          }
          for (const material of MaterialArr) {
            let val = this.historicalMType.find(
              (mtype: any) => mtype.ID == material
            );
            if (val) {
              val.M = val.MType;
              val.ProbableCause = val.Name;
              val.displayMType = 'Material';
              val.Priority = materialIndex;
              val.Weight = 2;
              val.ValidationResult = '';
              val.CauseIdentified = false;
              val.Remark = '';
              val.MType = { MType: val.M };
              this.viewStageData.causeAndEffects.push(val);
              materialIndex++;
            }
          }
          for (const man of ManArr) {
            let val = this.historicalMType.find(
              (mtype: any) => mtype.ID == man
            );
            if (val) {
              val.M = val.MType;
              val.ProbableCause = val.Name;
              val.displayMType = 'Man';
              val.Priority = manIndex;
              val.Weight = 2;
              val.ValidationResult = '';
              val.CauseIdentified = false;
              val.Remark = '';
              val.MType = { MType: val.M };
              this.viewStageData.causeAndEffects.push(val);
              manIndex++;
            }
          }
          for (const method of MethodArr) {
            let val = this.historicalMType.find(
              (mtype: any) => mtype.ID == method
            );
            if (val) {
              val.M = val.MType;
              val.ProbableCause = val.Name;
              val.displayMType = 'Method';
              val.Priority = methodIndex;
              val.Weight = 2;
              val.ValidationResult = '';
              val.CauseIdentified = false;
              val.Remark = '';
              val.MType = { MType: val.M };
              this.viewStageData.causeAndEffects.push(val);
              methodIndex++;
            }
          }
          for (const motherNature of MotherNatureArr) {
            let val = this.historicalMType.find(
              (mtype: any) => mtype.ID == motherNature
            );
            if (val) {
              val.M = val.MType;
              val.ProbableCause = val.Name;
              val.displayMType = 'Mother Nature';
              val.Priority = motherNatureIndex;
              val.Weight = 2;
              val.ValidationResult = '';
              val.CauseIdentified = false;
              val.Remark = '';
              val.MType = { MType: val.M };
              this.viewStageData.causeAndEffects.push(val);
              motherNatureIndex++;
            }
          }
          for (const management of ManagementArr) {
            let val = this.historicalMType.find(
              (mtype: any) => mtype.ID == management
            );
            if (val) {
              val.M = val.MType;
              val.ProbableCause = val.Name;
              val.displayMType = 'Management';
              val.Priority = managementIndex;
              val.Weight = 2;
              val.ValidationResult = '';
              val.CauseIdentified = false;
              val.Remark = '';
              val.MType = { MType: val.M };
              this.viewStageData.causeAndEffects.push(val);
              managementIndex++;
            }
          }
        } else if (
          this.viewStageData.causeAndEffects &&
          this.viewStageData.causeAndEffects.length
        ) {
          if (this.viewStageData.fishBone) {
            const MachineryArr =
              this.viewStageData.fishBone.Machinery &&
                this.viewStageData.fishBone.Machinery.length
                ? this.viewStageData.fishBone.Machinery.split(',')
                : [];
            const MaterialArr =
              this.viewStageData.fishBone.Material &&
                this.viewStageData.fishBone.Material.length
                ? this.viewStageData.fishBone.Material.split(',')
                : [];
            const ManArr =
              this.viewStageData.fishBone.Man &&
                this.viewStageData.fishBone.Man.length
                ? this.viewStageData.fishBone.Man.split(',')
                : [];
            const MethodArr =
              this.viewStageData.fishBone.Method &&
                this.viewStageData.fishBone.Method.length
                ? this.viewStageData.fishBone.Method.split(',')
                : [];
            const MotherNatureArr =
              this.viewStageData.fishBone.MotherNature &&
                this.viewStageData.fishBone.MotherNature.length
                ? this.viewStageData.fishBone.MotherNature.split(',')
                : [];
            const ManagementArr =
              this.viewStageData.fishBone.Management &&
                this.viewStageData.fishBone.Management.length
                ? this.viewStageData.fishBone.Management.split(',')
                : [];

            if (MachineryArr.length) {
              for (const machinery of MachineryArr) {
                const cause = this.viewStageData.causeAndEffects.find(
                  (ce: any) => ce.MType.ID == machinery
                );
                const probValue = this.historicalMType.find(
                  (mt: any) => mt.ID == machinery
                );
                if (!cause)
                  fishBoneDataNotInCause.push({
                    CauseIdentified: 0,
                    M: 1,
                    Weight: 2,
                    ProbableCause: probValue.Name,
                    Remark: '',
                    ValidationResult: '',
                    displayMType: 'Machine',
                    fishBoneDataNotInCause: true,
                    MType: { MType: 1 },
                  });
              }
            }
            if (MaterialArr.length) {
              for (const material of MaterialArr) {
                const cause = this.viewStageData.causeAndEffects.find(
                  (ce: any) => ce.MType.ID == material
                );
                const probValue = this.historicalMType.find(
                  (mt: any) => mt.ID == material
                );
                if (!cause)
                  fishBoneDataNotInCause.push({
                    CauseIdentified: 0,
                    M: 2,
                    Weight: 2,
                    ProbableCause: probValue.Name,
                    Remark: '',
                    ValidationResult: '',
                    displayMType: 'Material',
                    fishBoneDataNotInCause: true,
                    MType: { MType: 2 },
                  });
              }
            }
            if (ManArr.length) {
              for (const man of ManArr) {
                const cause = this.viewStageData.causeAndEffects.find(
                  (ce: any) => ce.MType.ID == man
                );
                const probValue = this.historicalMType.find(
                  (mt: any) => mt.ID == man
                );
                if (!cause)
                  fishBoneDataNotInCause.push({
                    CauseIdentified: 0,
                    M: 3,
                    Weight: 2,
                    ProbableCause: probValue.Name,
                    Remark: '',
                    ValidationResult: '',
                    displayMType: 'Man',
                    fishBoneDataNotInCause: true,
                    MType: { MType: 3 },
                  });
              }
            }
            if (MethodArr.length) {
              for (const method of MethodArr) {
                const cause = this.viewStageData.causeAndEffects.find(
                  (ce: any) => ce.MType.ID == method
                );
                const probValue = this.historicalMType.find(
                  (mt: any) => mt.ID == method
                );
                if (!cause)
                  fishBoneDataNotInCause.push({
                    CauseIdentified: 0,
                    M: 4,
                    Weight: 2,
                    ProbableCause: probValue.Name,
                    Remark: '',
                    ValidationResult: '',
                    displayMType: 'Method',
                    fishBoneDataNotInCause: true,
                    MType: { MType: 4 },
                  });
              }
            }
            if (MotherNatureArr.length) {
              for (const motherNature of MotherNatureArr) {
                const cause = this.viewStageData.causeAndEffects.find(
                  (ce: any) => ce.MType.ID == motherNature
                );
                const probValue = this.historicalMType.find(
                  (mt: any) => mt.ID == motherNature
                );
                if (!cause)
                  fishBoneDataNotInCause.push({
                    CauseIdentified: 0,
                    M: 5,
                    Weight: 2,
                    ProbableCause: probValue.Name,
                    Remark: '',
                    ValidationResult: '',
                    displayMType: 'Mother Nature',
                    fishBoneDataNotInCause: true,
                    MType: { MType: 5 },
                  });
              }
            }
            if (ManagementArr.length) {
              for (const management of ManagementArr) {
                const cause = this.viewStageData.causeAndEffects.find(
                  (ce: any) => ce.MType.ID == management
                );
                const probValue = this.historicalMType.find(
                  (mt: any) => mt.ID == management
                );
                if (!cause)
                  fishBoneDataNotInCause.push({
                    CauseIdentified: 0,
                    M: 6,
                    Weight: 2,
                    ProbableCause: probValue.Name,
                    Remark: '',
                    ValidationResult: '',
                    displayMType: 'Management',
                    fishBoneDataNotInCause: true,
                    MType: { MType: 6 },
                  });
              }
            }
          } else {
            let M1 = this.viewStageData.causeAndEffects.filter(
              (causeEff: any) => causeEff.MType.MType == 1
            );
            let M2 = this.viewStageData.causeAndEffects.filter(
              (causeEff: any) => causeEff.MType.MType == 2
            );
            let M3 = this.viewStageData.causeAndEffects.filter(
              (causeEff: any) => causeEff.MType.MType == 3
            );
            let M4 = this.viewStageData.causeAndEffects.filter(
              (causeEff: any) => causeEff.MType.MType == 4
            );
            let M5 = this.viewStageData.causeAndEffects.filter(
              (causeEff: any) => causeEff.MType.MType == 5
            );
            let M6 = this.viewStageData.causeAndEffects.filter(
              (causeEff: any) => causeEff.MType.MType == 6
            );

            if (M1 && !M1.length)
              this.viewStageData.causeAndEffects.push({
                CauseIdentified: false,
                M: 1,
                Weight: 2,
                Priority: 1,
                ProbableCause: '',
                Remark: '',
                ValidationResult: '',
                displayMType: 'Machine',
                indModule: true,
                MType: { MType: 1 },
              });
            if (M2 && !M2.length)
              this.viewStageData.causeAndEffects.push({
                CauseIdentified: false,
                M: 2,
                Weight: 2,
                Priority: 1,
                ProbableCause: '',
                Remark: '',
                ValidationResult: '',
                displayMType: 'Material',
                indModule: true,
                MType: { MType: 2 },
              });
            if (M3 && !M3.length)
              this.viewStageData.causeAndEffects.push({
                CauseIdentified: false,
                M: 3,
                Weight: 2,
                Priority: 1,
                ProbableCause: '',
                Remark: '',
                ValidationResult: '',
                displayMType: 'Man',
                indModule: true,
                MType: { MType: 3 },
              });
            if (M4 && !M4.length)
              this.viewStageData.causeAndEffects.push({
                CauseIdentified: false,
                M: 4,
                Weight: 2,
                Priority: 1,
                ProbableCause: '',
                Remark: '',
                ValidationResult: '',
                displayMType: 'Method',
                indModule: true,
                MType: { MType: 4 },
              });
            if (M5 && !M5.length)
              this.viewStageData.causeAndEffects.push({
                CauseIdentified: false,
                M: 5,
                Weight: 2,
                Priority: 1,
                ProbableCause: '',
                Remark: '',
                ValidationResult: '',
                displayMType: 'Mother Nature',
                indModule: true,
                MType: { MType: 5 },
              });
            if (M6 && !M6.length)
              this.viewStageData.causeAndEffects.push({
                CauseIdentified: false,
                M: 6,
                Weight: 2,
                Priority: 1,
                ProbableCause: '',
                Remark: '',
                ValidationResult: '',
                displayMType: 'Management',
                indModule: true,
                MType: { MType: 6 },
              });
          }
        }
        if (fishBoneDataNotInCause.length) {
          for (let fish of fishBoneDataNotInCause) {
            const data = this.viewStageData.causeAndEffects.filter(
              (cause: any) => cause.M == fish.M
            );

            if (data && data.length) {
              fish.Priority = Math.floor(data[data.length - 1].Priority) + 1;
              this.viewStageData.causeAndEffects.splice(
                data[data.length - 1].index,
                0,
                fish
              );
              this.viewStageData.causeAndEffects = this.updateIndex(
                this.viewStageData.causeAndEffects
              );
            } else {
              fish.Priority = 1;
              this.viewStageData.causeAndEffects.splice(0, 0, fish);
              this.viewStageData.causeAndEffects = this.updateIndex(
                this.viewStageData.causeAndEffects
              );
            }
          }
        }
      }
    } else {
      if (
        this.viewStageData &&
        this.viewStageData.causeAndEffects &&
        !this.viewStageData.causeAndEffects.length
      ) {
        this.viewStageData.causeAndEffects = [
          {
            CauseIdentified: false,
            M: 1,
            Weight: 2,
            Priority: 1,
            ProbableCause: '',
            Remark: '',
            ValidationResult: '',
            displayMType: 'Machine',
            indModule: true,
            MType: { MType: 1 },
          },
          {
            CauseIdentified: false,
            M: 2,
            Weight: 2,
            Priority: 1,
            ProbableCause: '',
            Remark: '',
            ValidationResult: '',
            displayMType: 'Material',
            indModule: true,
            MType: { MType: 2 },
          },
          {
            CauseIdentified: false,
            M: 3,
            Weight: 2,
            Priority: 1,
            ProbableCause: '',
            Remark: '',
            ValidationResult: '',
            displayMType: 'Man',
            indModule: true,
            MType: { MType: 3 },
          },
          {
            CauseIdentified: false,
            M: 4,
            Weight: 2,
            Priority: 1,
            ProbableCause: '',
            Remark: '',
            ValidationResult: '',
            displayMType: 'Method',
            indModule: true,
            MType: { MType: 4 },
          },
          {
            CauseIdentified: false,
            M: 5,
            Weight: 2,
            Priority: 1,
            ProbableCause: '',
            Remark: '',
            ValidationResult: '',
            displayMType: 'Mother Nature',
            indModule: true,
            MType: { MType: 5 },
          },
          {
            CauseIdentified: false,
            M: 6,
            Weight: 2,
            Priority: 1,
            ProbableCause: '',
            Remark: '',
            ValidationResult: '',
            displayMType: 'Management',
            indModule: true,
            MType: { MType: 6 },
          },
        ];
      }
    }

    for (let causeEffect of this.viewStageData.causeAndEffects) {
      if (causeEffect.Priority % 1 === 0) causeEffect.sub = false;
      else causeEffect.sub = true;

      causeEffect.index = index;
      causeEffect.M = causeEffect.MType.MType;
      if (causeEffect.M == 1) causeEffect.displayMType = 'Machine';
      else if (causeEffect.M == 2) causeEffect.displayMType = 'Material';
      else if (causeEffect.M == 3) causeEffect.displayMType = 'Man';
      else if (causeEffect.M == 4) causeEffect.displayMType = 'Method';
      else if (causeEffect.M == 5) causeEffect.displayMType = 'Mother Nature';
      else causeEffect.displayMType = 'Management';
      index++;
    }

    this.viewStageData.causeAndEffects.sort((a: any, b: any) =>
      a.M > b.M ? 1 : b.M > a.M ? -1 : 0
    );
    this.viewStageData.causeAndEffects.sort((a: any, b: any) =>
      a.Priority > b.Priority ? 1 : b.Priority > a.Priority ? -1 : 0
    );
    this.viewStageData.causeAndEffects.sort((a: any, b: any) =>
      a.M > b.M ? 1 : b.M > a.M ? -1 : 0
    );

    this.viewStageData.causeAndEffects.map((cause: any, index: number) => {
      cause.index = index;
    });
    this.viewStageData.causeAndEffects.sort(function (a: any, b: any) {
      return a.Priority - b.Priority;
    });
    this.viewStageData.causeAndEffects.sort(function (a: any, b: any) {
      return a.M - b.M;
    });

    this.causeEffectModelDataSource = new MatTableDataSource(
      this.viewStageData.causeAndEffects
    );
  }

  setFiveWhyDataView() {
    if (this.viewStageData.stageId != 4) {
      if (
        !this.viewStageData.StatusFiveWhy ||
        this.viewStageData.StatusFiveWhy == 'Open'
      ) {
        this.viewStageData.fiveWhy = [];
      } else {
        if (
          this.viewStageData.causeAndEffects &&
          this.viewStageData.causeAndEffects.length &&
          !this.viewStageData.fiveWhy.length
        ) {
          for (let cause of this.viewStageData.causeAndEffects) {
            const causeEffect = cause;
            // const cause = this.viewStageData.causeAndEffects.find((ce: any) => ce.MType.ID == material);

            if (cause.CauseIdentified) {
              let displayMType: any = '';
              displayMType =
                causeEffect.MType.MType == 1
                  ? 'Machine'
                  : causeEffect.MType.MType == 2
                    ? 'Material'
                    : causeEffect.MType.MType == 3
                      ? 'Man'
                      : causeEffect.MType.MType == 4
                        ? 'Method'
                        : causeEffect.MType.MType == 5
                          ? 'Mother Nature'
                          : 'Management';
              let obj: any = {
                MType: causeEffect.MType.MType,
                displayMType: displayMType,
                M: causeEffect.MType.MType,
                Priority: cause.Priority,
                priorityGroup: Math.floor(cause.Priority),
                Weight: cause.Weight,
                ValidationFromCauseEffects: cause.ProbableCause,
                CauseAndEffectsId: cause.ID,
                Why1: '',
                Why2: '',
                Why3: '',
                Why4: '',
                Why5: '',
                RootCause: '',
                check1: false,
                check2: false,
                check3: false,
                check4: false,
                check5: false,
                ValidationEditFalse: true,
                delete: false,
              };

              this.viewStageData.fiveWhy.push(obj);
            }
          }
        } else if (
          this.viewStageData.causeAndEffects &&
          this.viewStageData.causeAndEffects.length &&
          this.viewStageData.fiveWhy &&
          this.viewStageData.fiveWhy.length
        ) {
          for (const ce of this.viewStageData.causeAndEffects) {
            const find = this.viewStageData.fiveWhy.find(
              (fy: any) =>
                fy.MType == ce.MType.MType && fy.Priority == ce.Priority
            );
            if (!find) {
              let displayMType: any = '';
              displayMType =
                ce.MType.MType == 1
                  ? 'Machine'
                  : ce.MType.MType == 2
                    ? 'Material'
                    : ce.MType.MType == 3
                      ? 'Man'
                      : ce.MType.MType == 4
                        ? 'Method'
                        : ce.MType.MType == 5
                          ? 'Mother Nature'
                          : 'Management';

              this.viewStageData.fiveWhy.push({
                MType: ce.MType.MType,
                displayMType: displayMType,
                M: ce.MType.MType,
                Priority: ce.Priority,
                priorityGroup: Math.floor(ce.Priority),
                Weight: ce.Weight,
                ValidationFromCauseEffects: ce.ProbableCause,
                CauseAndEffectsId: ce.ID,
                Why1: '',
                Why2: '',
                Why3: '',
                Why4: '',
                Why5: '',
                RootCause: '',
                check1: false,
                check2: false,
                check3: false,
                check4: false,
                check5: false,
                ValidationEditFalse: true,
                delete: false,
              });
            }
          }
          for (const cae of this.viewStageData.causeAndEffects) {
            if (!cae.CauseIdentified) {
              const findIdentified = this.viewStageData.fiveWhy.findIndex(
                (fy: any) =>
                  fy.MType == cae.MType.MType && fy.Priority == cae.Priority
              );
              if (findIdentified != -1) {
                this.viewStageData.fiveWhy.splice(findIdentified, 1);
              }
            }
          }
        }

        this.viewStageData.fiveWhy &&
          this.viewStageData.fiveWhy.length &&
          this.viewStageData.fiveWhy.map(
            (fiveY: any) => (fiveY.M = fiveY.MType)
          );
      }
    } else {
      if (
        this.viewStageData &&
        this.viewStageData.fiveWhy &&
        !this.viewStageData.fiveWhy.length
      ) {
        this.viewStageData.fiveWhy = [
          {
            M: 1,
            MType: 1,
            displayMType: 'Machine',
            Priority: 1,
            ValidationFromCauseEffects: '',
            CauseAndEffectsId: null,
            Why1: '',
            Why2: '',
            Why3: '',
            Why4: '',
            Why5: '',
            RootCause: '',
            check1: false,
            check2: false,
            check3: false,
            check4: false,
            check5: false,
            ValidationEditFalse: false,
            delete: false,
          },
        ];
      }
    }

    if (this.viewStageData.stageId != 4)
      this.fiveWhyColumns = [
        'Contributing Source',
        'Priority',
        'Valid Cause',
        'Weight',
        'Why1',
        'Why2',
        'Why3',
        'Why4',
        'Why5',
        'RootCause',
        "M's Analysis",
      ];
    else
      this.fiveWhyColumns = [
        'Valid Cause',
        'Why1',
        'Why2',
        'Why3',
        'Why4',
        'Why5',
        'RootCause',
        "M's Analysis",
      ];

    if (this.viewStageData.fiveWhy && this.viewStageData.fiveWhy.length == 1) {
      this.viewStageData.fiveWhy[0].MainRowShow = true;
    }

    if (this.viewStageData.fiveWhy && this.viewStageData.fiveWhy.length) {
      let arr = [],
        i = 0;
      for (let fy of this.viewStageData.fiveWhy) {
        if (fy.ValidationFromCauseEffects) fy.causeNotEmpty = true;
        else fy.causeNotEmpty = false;
        fy.check1 = fy.Why1 ? true : false;
        fy.check2 = fy.Why2 ? true : false;
        fy.check3 = fy.Why3 ? true : false;
        fy.check4 = fy.Why4 ? true : false;
        fy.check5 = fy.Why5 ? true : false;

        if (fy.check1) fy.parentCheck = 1;
        else if (fy.check2) fy.parentCheck = 2;
        else if (fy.check3) fy.parentCheck = 3;
        else if (fy.check4) fy.parentCheck = 4;
        else if (fy.check5) fy.parentCheck = 5;

        fy.readyForAP = fy && fy.ID && fy.RootCause ? true : false;

        if (!fy.RootCause) {
          if (fy.Why5) fy.check5 = false;
          else if (fy.Why4) fy.check4 = false;
          else if (fy.Why3) fy.check3 = false;
          else if (fy.Why2) fy.check2 = false;
          else if (fy.Why1) fy.check1 = false;
        }

        const mRecords = this.viewStageData.fiveWhy.filter(
          (fiveY: any) =>
            fy.MType == fiveY.MType && fy.Priority == fiveY.Priority
              && fy.ValidationFromCauseEffects == fiveY.ValidationFromCauseEffects
        );
        if (mRecords && mRecords.length) {
          let disabled = mRecords.map((rec: any, index: number) => {
            if (index == 0)
              rec.rowSpan = mRecords && mRecords.length ? mRecords.length : 1;
            else {
              rec.spanRow = true;
              rec.sub = true;
            }
          });
        }
        i++;
      }
    }
    let a = this.viewStageData.fiveWhy.filter((obj: any) => !obj.spanRow);
    if (a && a.length) {
      for (let index = 0; index < a.length; index++) {
        if (index == a.length - 1) a[index].MainRowShow = true;
        else a[index].MainRowShow = false;
      }
    }

    this.viewStageData.fiveWhy.sort((a: any, b: any) =>
      a.Priority > b.Priority ? 1 : b.Priority > a.Priority ? -1 : 0
    );
    this.viewStageData.fiveWhy.sort((a: any, b: any) =>
      a.Weight < b.Weight ? 1 : b.Weight < a.Weight ? -1 : 0
    );
    this.viewStageData.fiveWhy.sort((a: any, b: any) =>
      a.MType > b.MType ? 1 : b.MType > a.MType ? -1 : 0
    );

    this.viewStageData.fiveWhy.map((cause: any, index: number) => {
      cause.index = index;
    });

    this.viewStageData.fiveWhy.sort(function (a: any, b: any) {
      return a.Priority - b.Priority;
    });
    this.viewStageData.fiveWhy.sort(function (a: any, b: any) {
      return a.MType - b.MType;
    });

    this.fiveWhyDataSource = new MatTableDataSource(this.viewStageData.fiveWhy);
  }

  setFivewhyActionDataView() {
    if (
      this.viewStageData.fiveWhyActionPlan &&
      this.viewStageData.fiveWhyActionPlan.length
    ) {
      for (const fiveaction of this.viewStageData.fiveWhyActionPlan) {
        if (!fiveaction.fiveWhyId)
          fiveaction.fiveWhyId =
            fiveaction && fiveaction.fiveWhy && fiveaction.fiveWhy.ID
              ? fiveaction.fiveWhy.ID
              : null;
        if (fiveaction && fiveaction.fiveWhy && !fiveaction.fiveWhy.RootCause)
          fiveaction.RootCause = '';
      }

      if (this.viewStageData.fiveWhy && this.viewStageData.fiveWhy.length) {
        if (
          this.viewStageData.causeAndEffects &&
          this.viewStageData.causeAndEffects.length
        ) {
          for (const cae of this.viewStageData.causeAndEffects) {
            if (!cae.CauseIdentified) {
              const findIdentified = this.viewStageData.fiveWhy.findIndex(
                (fy: any) =>
                  fy.MType == cae.MType.MType && fy.Priority == cae.Priority
              );
              if (findIdentified != -1) {
                this.viewStageData.fiveWhy.splice(findIdentified, 1);
              }
            }
          }
        }
        for (const fy of this.viewStageData.fiveWhy) {
          const find = this.viewStageData.fiveWhyActionPlan.find(
            (fya: any) => fya.fiveWhyId == fy.ID
          );
          if (!find) {
            this.viewStageData.fiveWhyActionPlan.push({
              fiveWhyId: fy.ID,
              RootCause: fy && fy.RootCause ? fy.RootCause : '',
              Action: '',
              ActionType: 'Preventive',
              AssignTo: '',
              TargetDate: null,
              RevisedDate: null,
              ClosedDate: null,
              Status: 'Open',
              VerifiedBy: '',
            });
          }
        }
        let arrInd = [];
        for (const fya of this.viewStageData.fiveWhyActionPlan) {
          const findfya = this.viewStageData.fiveWhy.find(
            (fy: any) => fy.ID == fya.fiveWhyId
          );
          if (findfya) arrInd.push(fya);
        }
        this.viewStageData.fiveWhyActionPlan = arrInd;
      }
    }

    if (
      this.viewStageData.fiveWhyActionPlan &&
      this.viewStageData.fiveWhyActionPlan.length
    ) {
      if (this.viewStageData.stageId != 5) {
        const actionplan = this.viewStageData.fiveWhyActionPlan.filter(
          (fiveY: any) => fiveY.RootCause != ''
        );
        this.viewStageData.fiveWhyActionPlan =
          actionplan && actionplan.length ? actionplan : [];
      }
    }

    if (this.viewStageData.stageId == 0 || this.viewStageData.stageId == 5) {
      if (
        this.viewStageData.fiveWhyActionPlan &&
        this.viewStageData.fiveWhyActionPlan.length &&
        this.viewStageData.fiveWhyActionPlan.length > 1
      ) {
        let i = 1;
        for (const fya of this.viewStageData.fiveWhyActionPlan) {
          let arr = this.viewStageData.fiveWhyActionPlan.filter(
            (fiveY: any) => fiveY.FyActionId == fya.FyActionId
          );
          if (arr && arr.length && !fya.fiveWhyId) {
            arr.map((cause: any, index: number) => {
              cause.fiveWhyId = i;
            });
            i++;
          }
        }
        this.viewStageData.fiveWhyActionPlan.map(
          (cause: any, index: number) => {
            delete cause.FyActionId;
          }
        );
      }
    }

    for (const fya of this.viewStageData.fiveWhyActionPlan) {
      const mRecords = this.viewStageData.fiveWhyActionPlan.filter(
        (fiveY: any) => fya.fiveWhyId == fiveY.fiveWhyId
      );
      if (mRecords && mRecords.length) {
        let disabled = mRecords.map((rec: any, index: number) => {
          if (index == 0)
            rec.rowSpan = mRecords && mRecords.length ? mRecords.length : 1;
          else {
            rec.spanRow = true;
          }
        });
      }
    }
  }
}
