import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Vision } from '../models/treeModels/vision';

@Injectable({
  providedIn: 'root',
})
export class KpitargetService {
  
  NetApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  addTarget(data: any): any {
    return this.http.post<any>(this.NetApiUrl + 'KPITargets/AddKpiTargets', data);
  }
  updateKpi(data: any): any {
    return this.http.put<any>(this.NetApiUrl + 'KPITargets/UpdateKpiTarget', data);
  }
  DeleteTarget(data:any):any {
    return this.http.post<any>(this.NetApiUrl + 'KPITargets/DeleteKpiTarget', data);
  }

  getTarget(data: any): any {
    return this.http.post<any>(this.NetApiUrl + 'KPITargets/GetKpiTarget', data);
  }

  getVisions(): Observable<Vision[]> {
    return this.http.get<Vision[]>(this.NetApiUrl + 'Vision/GetAllVisions');
  }

  getZeroValueTarget(data: any): any {
    return this.http.post<any>(this.NetApiUrl + 'KPITargets/GetZeroValue', data);
  }

  deleteKpiTarget(data: any): any {
    return this.http.post<any>(this.NetApiUrl + 'KPI/DeleteWholeKPis', data);
  }
  refreshKpiTarget(data: any): any {
    return this.http.post<any>(this.NetApiUrl + 'KPITargets/RefreshKpis', data);
  }

  getTreeData(data: any): Observable<any[]> {
    return this.http.get<TreeNode[]>(this.NetApiUrl + 'KPITree/GetKpiTrees' + data);
  }

  getTargets(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.NetApiUrl + 'KPI/GetTargetData', data);
  }
}
