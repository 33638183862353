
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-renderer',
  template: `
  <style>
  .row-action-btns {
    min-width: 70px;
    }
  .row-action-btns .mat-icon-button:not(:last-child) {
    margin-right: 10px;
    }
    .row-action-btns .mat-icon-button.edit-btn {
        background: #3aafe4;
    }
    .row-action-btns .mat-icon-button {
        width: 34px;
        height: 34px;
        line-height: 34px;
        border-radius: 10px;
        visibility: visible;
        transition: all .4s;
        top:5px
    }
    .mat-button-wrapper{
      padding: 0;
    }
  </style>
    <div class="row-action-btns">
    <button (click)="onClick($event)" mat-mini-fab="" color="primary" ptooltip="Action Edit" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: blue;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-pencil" style="color: #fff; margin-bottom=10px;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
    </div>
    `
})

export class ButtonEditRendererComponent implements ICellRendererAngularComp {

  params: any;
  label!: string;

  agInit(params: any): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event: any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(this.params);

    }
  }
}