import { Component, ViewContainerRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-select-list',
  template: `
    <div class="container" #group tabindex="0" (keydown)="onKeyDown($event)">
      <mat-select
        (selectionChange)="setSelectedValue($event)"
        [(ngModel)]="itemname"
      >
        <mat-option *ngFor="let item of data" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </div>
  `,
  styles: [
    `
      .container {
        background: transparent;
        width: 190px;
      }
    `,
  ],
})
export class MatSelectRendererComponent implements ICellEditorAngularComp {
  params: any;

  data!: any[];
  itemname!: number;
  selectedIndex!: number;

  @ViewChild('group', { read: ViewContainerRef }) public group!: {
    element: { nativeElement: { focus: () => void } };
  };

  agInit(params: any): void {
    this.params = params;
    this.itemname = this.params.value;
    this.data = this.params.colDef.cellEditorParams.data;
    this.selectedIndex = this.data.findIndex((item) => {
      return item.id === this.params.value;
    });
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    this.group.element.nativeElement.focus();
    this.selectItemBasedOnSelectedIndex();
  }

  selectItemBasedOnSelectedIndex(): void {
    this.itemname = this.data[this.selectedIndex].id;
  }

  setSelectedValue(event: any): void {
    const colId = this.params.column.colId;
    this.params.node.setDataValue(colId, event.value);
  }

  getValue(): any {
    return this.itemname;
  }

  isPopup(): boolean {
    return true;
  }
  onKeyDown(event: any): void {
    const key = event.which || event.keyCode;
    if (key === 38 || key === 40) {
      this.preventDefaultAndPropagation(event);

      if (key === 38) {
        // up
        this.selectedIndex =
          this.selectedIndex === 0
            ? this.data.length - 1
            : this.selectedIndex - 1;
      } else if (key === 40) {
        // down
        this.selectedIndex =
          this.selectedIndex === this.data.length - 1
            ? 0
            : this.selectedIndex + 1;
      }
      this.selectItemBasedOnSelectedIndex();
    }
  }

  private preventDefaultAndPropagation(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
