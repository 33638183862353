import { Component, OnDestroy } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'btn-cell-renderer',
    template:
        `<style>
        .button{
            color: white;
            background: #3aafe4;
        }
        i{
            color: white;
        }    
        .button:disabled{
            opacity:0.5
        }
    </style>
    <button [disabled]="!this.params.data.IsWrite" mat-icon-button class="button add_btn"  (click)="onClick($event)">
    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
    </button>`
})

export class EditBtnRenderer implements ICellRendererAngularComp, OnDestroy {
    params: any;
    refresh(params: any): boolean {
        throw new Error('Method not implemented.');
    }

    agInit(params: any): void {
        this.params = params;
    }

    onClick($event: any): any {

        if (this.params.onClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowData: this.params.node.data,
                index: this.params.node.rowIndex
                // ...something
            };
            this.params.onClick(params);
        }
    }
    ngOnDestroy(): any {
        // no need to remove the button click handler
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}