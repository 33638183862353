<div class="performance-analysis-content">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="title-row-flex-both-end">
      <h1 class="main-title">Performance Analysis</h1>
      <div class="basic-mat-input">
        <span class="date-label">Last 30 days</span>
        <mat-form-field appearence="fill">
          <!-- <mat-label>Choose date range</mat-label> -->
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="startDate">
            <input matEndDate formControlName="endDate">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- Dropdown -->
  <form [formGroup]="dropDownForm">
    <div class="drop-down-group">
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Year</mat-label>
          <mat-select name="Year">
            <mat-option *ngFor="let Year of yearList" [value]="Year">
              {{Year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Month</mat-label>
          <mat-select name="Month">
            <mat-option *ngFor="let Month of monthList" [value]="Month">
              {{Month}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Week</mat-label>
          <mat-select name="Week">
            <mat-option *ngFor="let Week of weekList" [value]="Week">
              {{Week}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Day</mat-label>
          <mat-select name="Day">
            <mat-option *ngFor="let Day of dayList" [value]="Day">
              {{Day}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Shift</mat-label>
          <mat-select name="Shift">
            <mat-option *ngFor="let Shift of shiftList" [value]="Shift">
              {{Shift}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Machine</mat-label>
          <mat-select name="Machine">
            <mat-option *ngFor="let Machine of machineList" [value]="Machine">
              {{Machine}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <!-- Charts -->
  <div class="chart-group">

    <!-- OEE Loses -->
    <div class="chart-items">
      <div class="chart-title"> OEE Losses</div>
      <app-o3-charts [chartData]="oeeLoss" [datasets]="oeeLoss.barChartData" [labels]="oeeLoss.barChartLabels"
        [colors]="oeeLoss.barChartColors" [options]="oeeLoss.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- Performance Loses -->
    <div class="chart-items">
      <div class="chart-title"> Performance Losses</div>
      <app-o3-charts [chartData]="performanceLoses" [datasets]="performanceLoses.barChartData"
        [labels]="performanceLoses.barChartLabels" [colors]="performanceLoses.barChartColors"
        [options]="performanceLoses.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- Rate Loss -->
    <div class="chart-items">
      <div class="chart-title"> Rate Loss</div>
      <app-o3-charts [chartData]="rateLoss" [datasets]="rateLoss.barChartData" [labels]="rateLoss.barChartLabels"
        [colors]="rateLoss.barChartColors" [options]="rateLoss.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- Actual Speed vs Target Speed -->
    <div class="chart-items">
      <div class="chart-title"> Actual Speed vs Target Speed</div>
      <app-o3-charts [chartData]="speedActualTarget" [datasets]="speedActualTarget.barChartData"
        [colors]="speedActualTarget.barChartColors" [labels]="speedActualTarget.barChartLabels"
        [options]="speedActualTarget.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- Production Orders - Actual vs Target -->
    <div class="chart-items">
      <div class="chart-title"> Production Orders - Actual vs Target</div>
      <app-o3-charts [chartData]="productionOrdersSpeedActualTarget"
        [datasets]="productionOrdersSpeedActualTarget.barChartData"
        [colors]="productionOrdersSpeedActualTarget.barChartColors"
        [labels]="productionOrdersSpeedActualTarget.barChartLabels"
        [options]="productionOrdersSpeedActualTarget.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- output - Actual vs Ideal -->
    <div class="chart-items">
      <div class="chart-title"> output - Actual vs Ideal</div>
      <app-o3-charts [chartData]="outputActualIdeal" [datasets]="outputActualIdeal.barChartData"
        [colors]="outputActualIdeal.barChartColors" [labels]="outputActualIdeal.barChartLabels"
        [options]="outputActualIdeal.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- Actual Speed Trend -->
    <div class="chart-items">
      <div class="chart-title"> Actual Speed Trend</div>
      <app-o3-charts [chartData]="actualSpeedTrend" [datasets]="actualSpeedTrend.barChartData"
        [colors]="actualSpeedTrend.barChartColors" [labels]="actualSpeedTrend.barChartLabels"
        [options]="actualSpeedTrend.barChartOptions">
      </app-o3-charts>
    </div>
  </div>

</div>
