import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Group } from '../models/group';
import { Plant } from '../models/plant';
import { env } from 'process';

@Injectable({
  providedIn: 'root'
})

export class CilConfigurationService {
  baseUrl: string = environment.O3CoreApiUrl;
  kpibaseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  // hardCodeToken: string = environment.token;

  constructor(private http: HttpClient) { }

  getGroupData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetGroup', data, { headers: header });
  }

  GetPlantByEgDDL(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(this.baseUrl + 'DMS/GetPlantByEgDDL', { headers: header, params: { Id: data.Id } });
  }

  async GetCILData(data: any): Promise<any> {
    return await this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/GetCILData', data).toPromise();
  }

  GetTreeModal(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.baseUrl + 'Common/GetTreeModal', data, { headers: header });
  }

  GetCompModelTreeForCIL(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.baseUrl + 'CIL/GetCompModelTreeForCIL', data, { headers: header });
  }

  GetMachineModelTreeForCIL(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.baseUrl + 'CIL/GetMachineModelTreeForCIL', data, { headers: header });
  }

  PerformCilTask(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/InsertCILTransaction', data, { headers: header });
  }

  performCilAction(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/InsertCILTransactionMultiple', data, { headers: header });
  }

  getTaskAssignedUsers(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/GetTaskAssignedUsers', data);
  }
}
