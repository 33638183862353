<div class="plant-model-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="top-box-content">
      <h1 class="main-title">{{langObj ? langObj?.KPIHierarchyMaster : ('KPIHierarchyMaster' | translate)}}</h1>
      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
      <form [formGroup]="groupForm">
        <div class="content-top-row content-top-row-order-processing">
          <!-- GROUP -->
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 240px;">
            <!-- <mat-label>Status</mat-label> -->
            <mat-select formControlName="group" (selectionChange)="getKPIHierarchyMaster()"
              placeholder="{{langObj ? langObj?.Group : ('Group' | translate)}}" panelClass="testClass" name="Status">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
                (filteredReturn)="filteredGroups = $event"></mat-select-filter>
              <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
                {{group.EG_Desc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
      <button *ngIf="IsWrite" (click)="openaddNewKPI()" class="add-btn" pTooltip="Add New KPIStructure"
        tooltipPosition="bottom">
        <img src="assets/images/add.png" alt="" width="20px" height="20px" />
      </button>

      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="width: 400px; margin-left: 400px">
          <input matInput placeholder="{{langObj ? langObj?.Search : ('Search' | translate)}}"
            (keyup)="applyFilter($event)" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="wrapper-box wrapper-box-shadow table-wrapper" *ngIf="KPIModelData !== undefined">
    <table mat-table [dataSource]="KPIModelData" class="basic-table plant-model-table" matSort>
      <!-- groupID Column -->
      <ng-container matColumnDef="SKPIId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
          <p class="header-text">{{langObj ? langObj?.SKPIId : ('SKPIId' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.SKPIId }}</td>
      </ng-container>

      <!-- groupDescription Column -->
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">{{langObj ? langObj?.name : ('name' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- remarks Column -->
      <ng-container matColumnDef="Remarks">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.remarks }}</td>
      </ng-container>

      <!-- groupOrder Column -->
      <ng-container matColumnDef="level">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="grp-order">
          <p class="header-text">{{langObj ? langObj?.level : ('level' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.level }}</td>
      </ng-container>

      <!-- active Column -->
      <ng-container matColumnDef="IsActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
          <p class="header-text">{{langObj ? langObj?.InActive : ('InActive' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.IsActive }}</td>
      </ng-container>
      <!-- NofLicense Column -->

      <ng-container matColumnDef="CreatedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-By">
          <p class="header-text">{{langObj ? langObj?.CreatedBy : ('CreatedBy' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.CreatedBy }}</td>
      </ng-container>
      <!-- createdOn Column -->
      <ng-container matColumnDef="CreatedOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
          <p class="header-text">{{langObj ? langObj?.CreatedOn : ('CreatedOn' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.CreatedOn | date: "y/MM/dd h:mm:ss" }}
        </td>
      </ng-container>

      <!-- KPIMasterActions Column -->
      <ng-container matColumnDef="KPIMasterActions">
        <th mat-header-cell *matHeaderCellDef>
          <p class="header-text">{{langObj ? langObj?.Actions : ('Actions' | translate)}}</p>
        </th>

        <td mat-cell *matCellDef="let element" class="row-action-btns-column">
          <div class="row-action-btns" style="min-width: 78px">
            <button *ngIf="IsWrite" mat-icon-button class="edit-btn" aria-label="Edit Group"
              (click)="openEditKPIModel(element)" pTooltip="Edit" tooltipPosition="top">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                  fill="white" />
              </svg>
            </button>
            <button *ngIf="IsAccess" mat-icon-button class="tree-view-btn" aria-label="View Row Tree"
              pTooltip="Open Tree" (click)="openKPIModel(element)">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14 8.75V5.25H4.375V6.125H2.625V3.5H10.5V0H0V3.5H1.75V12.25H4.375V14H14V10.5H4.375V11.375H2.625V7H4.375V8.75H14Z"
                  fill="white" />
              </svg>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="KPIMasterColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: KPIMasterColumns"></tr>
    </table>
    <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
  </div>

  <!-- add new plant model -->
  <div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{ active: isaddKPIModelVisible }">
    <div class="sidepanel-close" (click)="closeaddNewPlantItemModel()"></div>
    <div class="model-content">
      <div class="model-top">
        <h2 class="model-title">{{ formMode ? "Add" : "Update" }} {{langObj ? langObj?.KPI : ('KPI' | translate)}}</h2>
        <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeaddNewPlantItemModel()">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
              fill="black" />
          </svg>
        </button>
      </div>
      <div class="model-body">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

        <form [formGroup]="KPIHierarchyForm" (ngSubmit)="
            formMode
              ? addNewKPIFormSubmit()
              : editKPIFormSubmit()
          ">
          <div class="form-group">
            <mat-form-field [ngClass]="{
                'is-invalid':
                  KPIHierarchyForm.get('plant')?.errors &&
                  KPIHierarchyForm.get('plant')?.touched
              }" appearance="fill" class="field-fill">
              <mat-label>{{langObj ? langObj?.Plant : ('Plant' | translate)}}</mat-label>
              <mat-select formControlName="plant" placeholder="{{langObj ? langObj?.Plant : ('Plant' | translate)}}"
                panelClass="testClass" name="Status">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList"
                  (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
                  {{plant.PT_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <p class="invalid-feedback" *ngIf="
                KPIHierarchyForm.get('plant')?.touched &&
                KPIHierarchyForm
                  .get('plant')
                  ?.hasError('required')
              ">
              * Plant is required!
            </p>
          </div>
          <div class="form-group">
            <mat-form-field [ngClass]="{
                'is-invalid':
                  KPIHierarchyForm.get('strategicKPI')?.errors &&
                  KPIHierarchyForm.get('strategicKPI')?.touched
              }" appearance="fill" class="field-fill">
              <mat-label>{{langObj ? langObj?.strategicKPI : ('strategicKPI' | translate)}}</mat-label>
              <mat-select formControlName="strategicKPI"
                placeholder="{{langObj ? langObj?.strategicKPI : ('strategicKPI' | translate)}}" panelClass="testClass"
                name="Status">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'Name'" [array]="SKPIList"
                  (filteredReturn)="filteredStretegic = $event"></mat-select-filter>
                <mat-option *ngFor="let kpi of filteredStretegic" [value]="kpi.SKPIId">
                  {{kpi.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <p class="invalid-feedback" *ngIf="
                KPIHierarchyForm.get('strategicKPI')?.touched &&
                KPIHierarchyForm.get('strategicKPI')?.hasError('required')
              ">
              * Strategic KPI is required!
            </p>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>{{langObj ? langObj?.rename : ('rename' | translate)}}</mat-label>
              <input matInput placeholder="{{langObj ? langObj?.rename : ('rename' | translate)}}"
                formControlName="rename" />
            </mat-form-field>

          </div>

          <div class="form-group">
            <mat-form-field [ngClass]="{
                'is-invalid':
                  KPIHierarchyForm.get('remarks')?.errors &&
                  KPIHierarchyForm.get('remarks')?.touched
              }" appearance="fill" class="field-fill">
              <mat-label>{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}</mat-label>
              <textarea matInput formControlName="remarks"></textarea>
            </mat-form-field>
            <p class="invalid-feedback" *ngIf="
                KPIHierarchyForm.get('remarks')?.touched &&
                KPIHierarchyForm.get('remarks')?.hasError('required')
              ">
              * KPI remarks is required!
            </p>
          </div>
          <div class="model-form-actions">
            <button type="submit" class="add-btn" style="margin-left: 10px; margin-top: 0px" pTooltip="Update"
              tooltipPosition="top" *ngIf="!formMode && IsWrite">
              <img src="assets/images/edit.png" alt="" width="20px" height="20px" />
            </button>
            <button type="submit" class="add-btn" style="margin-left: 10px; margin-top: 0px" pTooltip="Add"
              tooltipPosition="top" *ngIf="formMode && IsWrite" [disabled]="!KPIHierarchyForm.valid">
              <img src="assets/images/save.png" alt="" width="20px" height="20px" />
            </button>
            <button type="button" class="add-btn" style="margin-left: 10px; margin-top: 0px" pTooltip="Cancel"
              tooltipPosition="top" (click)="closeaddNewPlantItemModel()">
              <img src="assets/images/cancel.png" alt="" width="20px" height="20px" />
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- plant group view model -->
  <div class="sidepanel-model-wrapper open-plant-group-model" [ngClass]="{ active: isPlantGroupModelVisible }">
    <div class="sidepanel-close" (click)="closePlantGroupModel()"></div>
    <div class="model-content">
      <div class="model-top">
        <h2 class="model-title">{{langObj ? langObj?.KPIStructure : ('KPIStructure' | translate)}}</h2>
        <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closePlantGroupModel()">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
              fill="black" />
          </svg>
        </button>
      </div>
      <div class="model-body">
        <div class="tree-controls">
          <button mat-flat-button class="btn-tree-control" (click)="collapseAllNode()">
            <div class="icon">
              <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.5 1.1547C13.7376 0.440169 15.2624 0.440169 16.5 1.1547L25.0574 6.0953C26.295 6.80983 27.0574 8.13034 27.0574 9.5594V19.4406C27.0574 20.8697 26.295 22.1902 25.0574 22.9047L16.5 27.8453C15.2624 28.5598 13.7376 28.5598 12.5 27.8453L3.94263 22.9047C2.70503 22.1902 1.94263 20.8697 1.94263 19.4406V9.5594C1.94263 8.13034 2.70503 6.80983 3.94263 6.0953L12.5 1.1547Z"
                  fill="#824AED" />
                <g clip-path="url(#clip0)">
                  <path
                    d="M14.0118 14.9844C13.933 14.897 13.8218 14.8399 13.6947 14.8395L10.1018 14.8345C9.86386 14.8341 9.67135 15.0265 9.67177 15.2645C9.67218 15.5024 9.94967 15.7699 10.0688 15.889L10.9776 16.7978L8.33301 19.417L9.58301 20.667L12.1888 18.0095L13.0976 18.9182C13.2942 19.1149 13.4938 19.3241 13.7317 19.3244C13.9697 19.3249 14.1622 19.1324 14.1617 18.8945L14.1568 15.3015C14.1563 15.1745 14.0993 15.0632 14.0118 14.9844ZM19.0763 11.1565L21.6663 8.58362L20.4163 7.33362L17.8442 9.91654L16.9201 8.98654C16.7201 8.78527 16.5172 8.57111 16.2755 8.5707C16.0334 8.57028 15.838 8.76737 15.838 9.0107L15.843 12.6882C15.843 12.8182 15.9013 12.9319 15.9905 13.0128C16.0709 13.1024 16.1838 13.1607 16.313 13.1611L19.9672 13.1661C20.2088 13.1665 20.4047 12.9694 20.4047 12.7261C20.4043 12.4828 20.1222 12.2086 20.0009 12.0869L19.0763 11.1565Z"
                    fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="13.3333" height="13.3333" fill="white" transform="translate(8.33301 7.33362)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p class="btn-text">{{langObj ? langObj?.CollapseAll : ('CollapseAll' | translate)}}</p>
          </button>
          <button mat-flat-button class="btn-tree-control" (click)="expandAllNode()">
            <div class="icon">
              <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.5 1.1547C13.7376 0.440169 15.2624 0.440169 16.5 1.1547L25.0574 6.0953C26.295 6.80983 27.0574 8.13034 27.0574 9.5594V19.4406C27.0574 20.8697 26.295 22.1902 25.0574 22.9047L16.5 27.8453C15.2624 28.5598 13.7376 28.5598 12.5 27.8453L3.94263 22.9047C2.70503 22.1902 1.94263 20.8697 1.94263 19.4406V9.5594C1.94263 8.13034 2.70503 6.80983 3.94263 6.0953L12.5 1.1547Z"
                  fill="#3AAFE4" />
                <g clip-path="url(#clip0)">
                  <path
                    d="M17.0932 8.50018L18.2178 9.62477L15.5086 12.3336C15.3561 12.4861 15.356 12.7333 15.5086 12.886L16.6138 13.9911C16.7666 14.1439 17.0143 14.1433 17.1665 13.9911L19.875 11.2819L20.9996 12.4065C21.2446 12.6515 21.6664 12.4783 21.6664 12.1303V8.22404C21.6664 8.00812 21.4916 7.83341 21.2757 7.83341H17.3695C17.0231 7.83341 16.8472 8.25336 17.0932 8.50018Z"
                    fill="white" />
                  <path
                    d="M12.8332 15.0093L10.1244 17.7185L8.9998 16.5939C8.7548 16.3489 8.33301 16.522 8.33301 16.8701V20.7763C8.33301 20.9922 8.50772 21.1669 8.72363 21.1669H12.6299C12.9754 21.1669 13.1531 20.748 12.9061 20.5001L11.7819 19.3752L14.4907 16.6667C14.6432 16.5142 14.6433 16.267 14.4907 16.1144L13.3856 15.0093C13.2337 14.8574 12.9863 14.8562 12.8332 15.0093Z"
                    fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="13.3333" height="13.3333" fill="white"
                      transform="translate(8.33301 21.1669) rotate(-90)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p class="btn-text">{{langObj ? langObj?.ExpandAll : ('ExpandAll' | translate)}}</p>
          </button>
        </div>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <!-- /////// delete the first <mat-tree-node> -->

          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <!-- ///////////// remove "; when: hasChild" from *matTreeNodeDef -->

            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>

            <!-- //////////// add this line to make space for removed button -->
            <div *ngIf="!node.hasChild" class="pad"></div>
            <div class="node-icon">
              <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.5 1.1547C13.7376 0.440169 15.2624 0.440169 16.5 1.1547L25.0574 6.0953C26.295 6.80983 27.0574 8.13034 27.0574 9.5594V19.4406C27.0574 20.8697 26.295 22.1902 25.0574 22.9047L16.5 27.8453C15.2624 28.5598 13.7376 28.5598 12.5 27.8453L3.94263 22.9047C2.70503 22.1902 1.94263 20.8697 1.94263 19.4406V9.5594C1.94263 8.13034 2.70503 6.80983 3.94263 6.0953L12.5 1.1547Z"
                  fill="#10CE9C" />
                <path
                  d="M10.0101 9.89258H9.24838V11.6056H8V20.1187H11.2585V11.6056H10.0101V9.89258ZM10.2166 18.2144H9.04188V17.4526H10.2166V18.2144H10.2166ZM10.2166 16.6909H9.04188V15.9292H10.2166V16.6909H10.2166ZM10.2166 15.1675H9.04188V14.4058H10.2166V15.1675H10.2166ZM10.2166 12.8823V13.644H9.04188V12.8823H10.2166Z"
                  fill="white" />
                <path
                  d="M19.7516 11.6056V9.89258H18.9899V11.6056H17.7415V20.1187H21V11.6056H19.7516ZM19.9581 18.2144H18.7834V17.4526H19.9581V18.2144ZM19.9581 16.6909H18.7834V15.9292H19.9581V16.6909ZM19.9581 15.1675H18.7834V14.4058H19.9581V15.1675ZM19.9581 13.644H18.7834V12.8823H19.9581V13.644Z"
                  fill="white" />
                <path d="M14.1191 18.9761H14.8809V20.1187H14.1191V18.9761Z" fill="white" />
                <path
                  d="M12.0202 20.1188H13.3574V18.2145H15.6426V20.1188H16.9798V8.88037L12.0202 10.0472V20.1188ZM14.8809 11.359H16.0556V12.1207H14.8809V11.359ZM14.8809 12.8824H16.0556V13.6442H14.8809V12.8824ZM14.8809 14.4059H16.0556V15.1676H14.8809V14.4059ZM14.8809 15.9293H16.0556V16.691H14.8809V15.9293ZM12.9444 11.359H14.1191V12.1207H12.9444V11.359ZM12.9444 12.8824H14.1191V13.6442H12.9444V12.8824ZM12.9444 14.4059H14.1191V15.1676H12.9444V14.4059ZM12.9444 15.9293H14.1191V16.691H12.9444V15.9293Z"
                  fill="white" />
              </svg>
            </div>
            <span class="tree-text">{{ node.name }}</span>

            <div (mouseover)="showButton = true" (mouseleave)="showButton = false">
              <button mat-icon-button (click)="openaddNewKPIItemModel(node)">
                <mat-icon class="mat-icon-create">add</mat-icon>
              </button>
              <button mat-icon-button>
                <mat-icon class="mat-icon-edit" (click)="openEditKPIItemModel(node)">edit</mat-icon>
              </button>
              <button mat-icon-button (click)="deleteItem(node)">
                <mat-icon class="mat-icon-delete">delete</mat-icon>
              </button>
              <button mat-icon-button (click)="openFormulaModel(node)">
                <mat-icon class="mat-icon-exposer">exposure</mat-icon>
              </button>
              <ng-container matTreeNodeOutlet></ng-container>
            </div>

          </mat-tree-node>
        </mat-tree>

      </div>
    </div>
  </div>
</div>

<!-- add new group model -->
<div class="sidepanel-model-wrapper add-group-item-model" [ngClass]="{ active: isaddNewGroupItemModelVisible }">
  <div class="sidepanel-close" (click)="closeaddNewGroupModel()"></div>
  <div class="model-content">
    <div class="model-top">
      <div class="d-flex align-items-center">
        <button mat-icon-button class="go-back" (click)="closeaddNewGroupModel()">
          <div class="btn-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="#1D252C" />
            </svg>
          </div>
        </button>
        <h2 class="model-title">
          {{ plantgroupFormMode ? "Add New" : "Update" }}
          {{ addnewGroupModelTitle }}
        </h2>
      </div>
      <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeaddNewGroupModel()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
            fill="black" />
        </svg>
      </button>
    </div>
    <div class="model-body">
      <mat-tab-group [selectedIndex]="tabIndex" (selectedTabChange)="onTabChanged($event);">
        <mat-tab label="From KPI Tree" [disabled]="tabIndex === 1 && !plantgroupFormMode ? true : false">
          <form [formGroup]="addKPIChildForm" (ngSubmit)="
          plantgroupFormMode
            ? addKPIItem()
            : editKPIItem()
        ">
            <div class="form-group">
              <mat-form-field [ngClass]="{
            'is-invalid':
            addKPIChildForm.get('plant')?.errors &&
            addKPIChildForm.get('plant')?.touched
          }" appearance="fill" class="field-fill">
                <mat-label>{{langObj ? langObj?.Plant : ('Plant' | translate)}}</mat-label>
                <mat-select formControlName="plant" (selectionChange)="getKpisByPlant($event.value)"
                  placeholder="{{langObj ? langObj?.Plant : ('Plant' | translate)}}" panelClass="testClass"
                  name="Status">
                  <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList"
                    (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                  <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
                    {{plant.PT_Desc}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <p class="invalid-feedback" *ngIf="
          addKPIChildForm.get('plant')?.touched &&
          addKPIChildForm
              .get('plant')
              ?.hasError('required')
          ">
                * Plant is required!
              </p>
            </div>
            <div class="form-group" *ngIf="plantgroupFormMode">
              <mat-form-field [ngClass]="{
            'is-invalid':
            addKPIChildForm.get('plant')?.errors &&
            addKPIChildForm.get('plant')?.touched
          }" appearance="fill" class="field-fill">
                <mat-label>{{langObj ? langObj?.KPI : ('KPI' | translate)}}</mat-label>
                <mat-select #select placeholder="{{langObj ? langObj?.kpi : ('KPI' | translate)}}"
                  panelClass="testClass" name="Status" [formControl]="kpis" multiple>
                  <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="KPIList"
                    (filteredReturn)="filteredKPIs = $event"></mat-select-filter>
                  <!-- <mat-checkbox [(ngModel)]="allSelected"
            [ngModelOptions]="{standalone: true}"
            (change)="toggleAllSelection()">Select All</mat-checkbox> -->
                  <mat-option *ngFor="let kpi of filteredKPIs" [value]="kpi" (click)="optionClick()">
                    {{kpi.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <p class="invalid-feedback" *ngIf="
              addKPIChildForm.get('kpi')?.touched &&
              addKPIChildForm.get('kpi')?.hasError('required')
            ">
                * KPI is required!
              </p>
            </div>

            <div class="form-group" *ngIf="!plantgroupFormMode">
              <mat-form-field [ngClass]="{
            'is-invalid':
            addKPIChildForm.get('plant')?.errors &&
            addKPIChildForm.get('plant')?.touched
          }" appearance="fill" class="field-fill">
                <mat-label>{{langObj ? langObj?.KPI : ('KPI' | translate)}}</mat-label>
                <mat-select formControlName="kpi" placeholder="{{langObj ? langObj?.kpi : ('KPI' | translate)}}"
                  panelClass="testClass" name="Status">
                  <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="KPIList"
                    (filteredReturn)="filteredKPIs = $event"></mat-select-filter>
                  <mat-option *ngFor="let kpi of filteredKPIs" [value]="kpi.kpiId">
                    {{kpi.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <p class="invalid-feedback" *ngIf="
              addKPIChildForm.get('kpi')?.touched &&
              addKPIChildForm.get('kpi')?.hasError('required')
            ">
                * KPI is required!
              </p>
            </div>

            <div class="form-group" *ngIf="!plantgroupFormMode">
              <mat-form-field appearance="fill" class="field-fill">
                <mat-label>{{langObj ? langObj?.rename : ('rename' | translate)}}</mat-label>
                <input matInput placeholder="{{langObj ? langObj?.rename : ('rename' | translate)}}"
                  formControlName="rename" />
              </mat-form-field>

            </div>

            <div class="form-group">
              <mat-form-field appearance="fill" class="field-fill">
                <mat-label>Remarks</mat-label>
                <textarea matInput formControlName="remarks"></textarea>
              </mat-form-field>
            </div>
            <div class="model-form-actions">
              <button type="button" class="add-btn" style="margin-left: 10px; margin-top: 0px" pTooltip="Cancel"
                tooltipPosition="top" (click)="closeaddNewGroupModel()">
                <img src="assets/images/cancel.png" alt="" width="20px" height="20px" />
              </button>
              <button type="submit" class="add-btn" style="margin-left: 10px; margin-top: 0px" pTooltip="Update"
                tooltipPosition="top" *ngIf="!plantgroupFormMode">
                <img src="assets/images/edit.png" alt="" width="20px" height="20px" />
              </button>
              <button type="submit" class="add-btn" style="margin-left: 10px; margin-top: 0px" pTooltip="Add"
                tooltipPosition="top" *ngIf="plantgroupFormMode" [disabled]="!addKPIChildForm.valid">
                <img src="assets/images/save.png" alt="" width="20px" height="20px" />
              </button>
              <!-- <button type="button" mat-flat-button class="btn-cancel" (click)="closeaddNewGroupModel()"> Cancel
        </button>
        <button mat-flat-button color="accent" [disabled]="!addKPIChildForm.valid" class="btn-accent">{{plantgroupFormMode ? 'Save': 'Update'}}</button> -->
            </div>
          </form>
        </mat-tab>
        <mat-tab label="New Level" [disabled]="tabIndex === 0 && !plantgroupFormMode ? true : false">
          <form [formGroup]="addKPIChildForm" (ngSubmit)="
          plantgroupFormMode
            ? addKPIItem()
            : editKPIItem()
        ">
            <div class="form-group">
              <mat-form-field [ngClass]="{
            'is-invalid':
            addKPIChildForm.get('plant')?.errors &&
            addKPIChildForm.get('plant')?.touched
          }" appearance="fill" class="field-fill">
                <mat-label>{{langObj ? langObj?.Plant : ('Plant' | translate)}}</mat-label>
                <mat-select formControlName="plant" (selectionChange)="getKpisByPlant($event.value)"
                  placeholder="{{langObj ? langObj?.Plant : ('Plant' | translate)}}" panelClass="testClass"
                  name="Status">
                  <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList"
                    (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                  <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
                    {{plant.PT_Desc}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <p class="invalid-feedback" *ngIf="
          addKPIChildForm.get('plant')?.touched &&
          addKPIChildForm
              .get('plant')
              ?.hasError('required')
          ">
                * Plant is required!
              </p>
            </div>
            <div class="form-group">
              <mat-form-field appearance="fill" class="field-fill">
                <mat-label>{{langObj ? langObj?.level : ('level' | translate)}}</mat-label>
                <input matInput placeholder="{{langObj ? langObj?.level : ('level' | translate)}}"
                  formControlName="level" />
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field appearance="fill" class="field-fill">
                <mat-label>Remarks</mat-label>
                <textarea matInput formControlName="remarks"></textarea>
              </mat-form-field>
            </div>
            <div class="model-form-actions">
              <button type="button" class="add-btn" style="margin-left: 10px; margin-top: 0px" pTooltip="Cancel"
                tooltipPosition="top" (click)="closeaddNewGroupModel()">
                <img src="assets/images/cancel.png" alt="" width="20px" height="20px" />
              </button>
              <button type="submit" class="add-btn" style="margin-left: 10px; margin-top: 0px" pTooltip="Update"
                tooltipPosition="top" *ngIf="!plantgroupFormMode">
                <img src="assets/images/edit.png" alt="" width="20px" height="20px" />
              </button>
              <button type="submit" class="add-btn" style="margin-left: 10px; margin-top: 0px" pTooltip="Add"
                tooltipPosition="top" *ngIf="plantgroupFormMode" [disabled]="!addKPIChildForm.valid">
                <img src="assets/images/save.png" alt="" width="20px" height="20px" />
              </button>
              <!-- <button type="button" mat-flat-button class="btn-cancel" (click)="closeaddNewGroupModel()"> Cancel
        </button>
        <button mat-flat-button color="accent" [disabled]="!addKPIChildForm.valid" class="btn-accent">{{plantgroupFormMode ? 'Save': 'Update'}}</button> -->
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <ng-template #displayrowRoleModal>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{kpiName}}-Integration </h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <mat-dialog-content class="popup" style="height:85%">
      <form [formGroup]="KpiIntegrationForm">
        <div class="content-top-row content-top-row-order-processing">
          <mat-form-field appearance="fill" class="field-fill inputs">
            <mat-label>{{langObj ? langObj?.Group : ('Group' | translate)}}</mat-label>
            <mat-select formControlName="group" placeholder="{{langObj ? langObj?.Group : ('Group' | translate)}}"
              panelClass="testClass" name="Status" [disabled]="true" (selectionChange)="getPlantsIntegration()">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
                (filteredReturn)="filteredGroups = $event"></mat-select-filter>
              <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
                {{group.EG_Desc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="field-fill inputs">
            <mat-label>{{langObj ? langObj?.Plant : ('Plant' | translate)}}</mat-label>
            <mat-select formControlName="plant" placeholder="{{langObj ? langObj?.Plant : ('Plant' | translate)}}"
              panelClass="testClass" name="Status" (selectionChange)="getStretegic();getTreeBYPlant();">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList"
                (filteredReturn)="filteredPlants = $event"></mat-select-filter>
              <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
                {{plant.PT_Desc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="chart-group">
          <div class="chart-items">
            <div class="chart-title">Strategic KPI</div>
            <div>
              <mat-chip-list aria-label="Color selection">
                <mat-chip *ngFor="let cat of SKPIList" color="accent" [class.active]="cat === activeChipsCat"
                  [class.inactive]="cat !== activeChipsCat" (click)="activeChipsCat = cat"
                  (click)="getTreeIntegration(cat)">
                  {{cat.Name}}
                </mat-chip>
              </mat-chip-list>
            </div>
            <!-- <div [chart]="chartDTClassificationParetoAnalysis"></div> -->
          </div>

          <div class="chart-items">
            <div class="chart-title">KPI Tree</div>
            <div class="model-body">
              <div class="tree-controls">
                <button mat-flat-button class="btn-tree-control" (click)="collapseAllNode()">
                  <div class="icon">
                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5 1.1547C13.7376 0.440169 15.2624 0.440169 16.5 1.1547L25.0574 6.0953C26.295 6.80983 27.0574 8.13034 27.0574 9.5594V19.4406C27.0574 20.8697 26.295 22.1902 25.0574 22.9047L16.5 27.8453C15.2624 28.5598 13.7376 28.5598 12.5 27.8453L3.94263 22.9047C2.70503 22.1902 1.94263 20.8697 1.94263 19.4406V9.5594C1.94263 8.13034 2.70503 6.80983 3.94263 6.0953L12.5 1.1547Z"
                        fill="#824AED" />
                      <g clip-path="url(#clip0)">
                        <path
                          d="M14.0118 14.9844C13.933 14.897 13.8218 14.8399 13.6947 14.8395L10.1018 14.8345C9.86386 14.8341 9.67135 15.0265 9.67177 15.2645C9.67218 15.5024 9.94967 15.7699 10.0688 15.889L10.9776 16.7978L8.33301 19.417L9.58301 20.667L12.1888 18.0095L13.0976 18.9182C13.2942 19.1149 13.4938 19.3241 13.7317 19.3244C13.9697 19.3249 14.1622 19.1324 14.1617 18.8945L14.1568 15.3015C14.1563 15.1745 14.0993 15.0632 14.0118 14.9844ZM19.0763 11.1565L21.6663 8.58362L20.4163 7.33362L17.8442 9.91654L16.9201 8.98654C16.7201 8.78527 16.5172 8.57111 16.2755 8.5707C16.0334 8.57028 15.838 8.76737 15.838 9.0107L15.843 12.6882C15.843 12.8182 15.9013 12.9319 15.9905 13.0128C16.0709 13.1024 16.1838 13.1607 16.313 13.1611L19.9672 13.1661C20.2088 13.1665 20.4047 12.9694 20.4047 12.7261C20.4043 12.4828 20.1222 12.2086 20.0009 12.0869L19.0763 11.1565Z"
                          fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="13.3333" height="13.3333" fill="white" transform="translate(8.33301 7.33362)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p class="btn-text">{{langObj ? langObj?.CollapseAll : ('CollapseAll' | translate)}}</p>
                </button>
                <button mat-flat-button class="btn-tree-control" (click)="expandAllNode()">
                  <div class="icon">
                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5 1.1547C13.7376 0.440169 15.2624 0.440169 16.5 1.1547L25.0574 6.0953C26.295 6.80983 27.0574 8.13034 27.0574 9.5594V19.4406C27.0574 20.8697 26.295 22.1902 25.0574 22.9047L16.5 27.8453C15.2624 28.5598 13.7376 28.5598 12.5 27.8453L3.94263 22.9047C2.70503 22.1902 1.94263 20.8697 1.94263 19.4406V9.5594C1.94263 8.13034 2.70503 6.80983 3.94263 6.0953L12.5 1.1547Z"
                        fill="#3AAFE4" />
                      <g clip-path="url(#clip0)">
                        <path
                          d="M17.0932 8.50018L18.2178 9.62477L15.5086 12.3336C15.3561 12.4861 15.356 12.7333 15.5086 12.886L16.6138 13.9911C16.7666 14.1439 17.0143 14.1433 17.1665 13.9911L19.875 11.2819L20.9996 12.4065C21.2446 12.6515 21.6664 12.4783 21.6664 12.1303V8.22404C21.6664 8.00812 21.4916 7.83341 21.2757 7.83341H17.3695C17.0231 7.83341 16.8472 8.25336 17.0932 8.50018Z"
                          fill="white" />
                        <path
                          d="M12.8332 15.0093L10.1244 17.7185L8.9998 16.5939C8.7548 16.3489 8.33301 16.522 8.33301 16.8701V20.7763C8.33301 20.9922 8.50772 21.1669 8.72363 21.1669H12.6299C12.9754 21.1669 13.1531 20.748 12.9061 20.5001L11.7819 19.3752L14.4907 16.6667C14.6432 16.5142 14.6433 16.267 14.4907 16.1144L13.3856 15.0093C13.2337 14.8574 12.9863 14.8562 12.8332 15.0093Z"
                          fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="13.3333" height="13.3333" fill="white"
                            transform="translate(8.33301 21.1669) rotate(-90)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p class="btn-text">{{langObj ? langObj?.ExpandAll : ('ExpandAll' | translate)}}</p>
                </button>
              </div>
              <mat-tree [dataSource]="dataSourceIntegration" [treeControl]="treeControl">
                <!-- /////// delete the first <mat-tree-node> -->

                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                  <!-- ///////////// remove "; when: hasChild" from *matTreeNodeDef -->

                  <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                  </button>

                  <!-- //////////// add this line to make space for removed button -->
                  <div *ngIf="!node.hasChild" class="pad"></div>
                  <div class="node-icon">
                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5 1.1547C13.7376 0.440169 15.2624 0.440169 16.5 1.1547L25.0574 6.0953C26.295 6.80983 27.0574 8.13034 27.0574 9.5594V19.4406C27.0574 20.8697 26.295 22.1902 25.0574 22.9047L16.5 27.8453C15.2624 28.5598 13.7376 28.5598 12.5 27.8453L3.94263 22.9047C2.70503 22.1902 1.94263 20.8697 1.94263 19.4406V9.5594C1.94263 8.13034 2.70503 6.80983 3.94263 6.0953L12.5 1.1547Z"
                        fill="#10CE9C" />
                      <path
                        d="M10.0101 9.89258H9.24838V11.6056H8V20.1187H11.2585V11.6056H10.0101V9.89258ZM10.2166 18.2144H9.04188V17.4526H10.2166V18.2144H10.2166ZM10.2166 16.6909H9.04188V15.9292H10.2166V16.6909H10.2166ZM10.2166 15.1675H9.04188V14.4058H10.2166V15.1675H10.2166ZM10.2166 12.8823V13.644H9.04188V12.8823H10.2166Z"
                        fill="white" />
                      <path
                        d="M19.7516 11.6056V9.89258H18.9899V11.6056H17.7415V20.1187H21V11.6056H19.7516ZM19.9581 18.2144H18.7834V17.4526H19.9581V18.2144ZM19.9581 16.6909H18.7834V15.9292H19.9581V16.6909ZM19.9581 15.1675H18.7834V14.4058H19.9581V15.1675ZM19.9581 13.644H18.7834V12.8823H19.9581V13.644Z"
                        fill="white" />
                      <path d="M14.1191 18.9761H14.8809V20.1187H14.1191V18.9761Z" fill="white" />
                      <path
                        d="M12.0202 20.1188H13.3574V18.2145H15.6426V20.1188H16.9798V8.88037L12.0202 10.0472V20.1188ZM14.8809 11.359H16.0556V12.1207H14.8809V11.359ZM14.8809 12.8824H16.0556V13.6442H14.8809V12.8824ZM14.8809 14.4059H16.0556V15.1676H14.8809V14.4059ZM14.8809 15.9293H16.0556V16.691H14.8809V15.9293ZM12.9444 11.359H14.1191V12.1207H12.9444V11.359ZM12.9444 12.8824H14.1191V13.6442H12.9444V12.8824ZM12.9444 14.4059H14.1191V15.1676H12.9444V14.4059ZM12.9444 15.9293H14.1191V16.691H12.9444V15.9293Z"
                        fill="white" />
                    </svg>
                  </div>
                  <span class="tree-text">{{ node.name }}</span>

                </mat-tree-node>
              </mat-tree>

            </div>

          </div>
        </div>
        <div class="mat-chip-bg">
          <center>
            <mat-chip-list class="mat-chip-list-wrapper-drag" cdkDropList #originList="cdkDropList"
              [cdkDropListData]="formulas" [cdkDropListConnectedTo]="[destinationList]">
              <mat-chip color="primary" style="height: 34px; width:34px; font-size: 20px; color: white;"
                (click)="drop($event)" selected *ngFor="let item of formulas" cdkDrag>{{item.formula}}</mat-chip>
            </mat-chip-list>
          </center>
        </div>

        <div>
          <!-- <h2>Drag here</h2> -->
          <div cdkDropList #destinationList="cdkDropList" [cdkDropListData]="destination"
            cdkDropListOrientation="horizontal" class="field-list" (cdkDropListDropped)="drop($event)">
            <div class="field-box" *ngFor="let item of destination;  let i=index" matTooltipClass="custom-tooltip"
              [matTooltip]="getKPIName(item)" matTooltipPosition="above" cdkDrag (click)="removeKeyword(list,i)">
              {{item.formula}}{{item.KPICode}}</div>

          </div>
          <div style="float: right">
            <button type="submit" mat-mini-fab mat-button color="accent" (click)="resetCalculation()"
              matTooltip="Reset Calculation" matTooltipClass="custom-tooltip" matTooltipPosition="above">
              <mat-icon>refresh</mat-icon>
            </button>
            <button type="submit" mat-mini-fab mat-button color="primary" (click)="setFormula()" matTooltip="Save"
              matTooltipClass="custom-tooltip" matTooltipPosition="above">
              <mat-icon>save</mat-icon>
            </button>
          </div>
        </div>

        <div class="mat-chip-bg-tree">
          <!-- <input type="text"  
        class="form-control vertical-spacing-5" 
        placeholder="filter"     
        formControlName="availableSearchInput" 
        #availableSearchInput /> -->
          <center>
            <mat-chip-list cdkDropList #originList="cdkDropList" [cdkDropListData]="allTreeList"
              [cdkDropListConnectedTo]="[destinationList]">
              <mat-chip color="accent" selected style="font-size: 12px; color: white;" *ngFor="let item of allTreeList"
                cdkDrag>{{item.Name}}</mat-chip>
            </mat-chip-list>
          </center>
        </div>
        <!-- <div class="d-flex  align-items-center">
  
          <mat-form-field class="example-chip-list " appearance="fill" style="width:93%;margin-left: 5px;">
            <mat-label>Calculation</mat-label>
            <mat-chip-list #chipList aria-label="Video keywords" multiple [formControl]="formControl" class="example-chip"
              cdkDropList class="example-chip field-fill no-wrap" cdkDropListOrientation="horizontal"
              (cdkDropListDropped)="drop($event)">
              <mat-chip cdkDrag class="example-box" *ngFor="let item of destination; let i=index" [selected]="item"
                [value]="item.formula" style="background-color: #e1e0e0;opacity: 1;" (dblclick)="allowEdit(item,i)"
                (removed)="removeKeyword(list,i)" (cdkDragStarted)="dragStart(i)" (mouseenter)="mouseEnter(i)">
                <h5>{{item.formula}}</h5>
              
  
              </mat-chip>
              <input [matChipInputFor]="chipList" [readonly]="true">
            </mat-chip-list>
          </mat-form-field>
          <input matInput formControlName="formula" style="display: none;" required />
          <div class=" d-flex  align-items-center">
            <button type="submit" mat-mini-fab mat-button color="accent" (click)="resetCalculation()"
              matTooltip="Reset Calculation" matTooltipClass="custom-tooltip" matTooltipPosition="above"
              style="margin-left: 10px;margin-bottom: 5px;">
              <mat-icon>refresh</mat-icon>
            </button>
            <button type="submit" mat-mini-fab mat-button color="primary" (click)="saveKpiIntegration()" matTooltip="Save"
              matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left:10px;margin-bottom: 5px;"
              [disabled]="disableSave">
              <mat-icon>save</mat-icon>
            </button>
          </div>
  
        </div>  -->
        <!-- <div style="margin-top: 10px;overflow-x: auto;">
          <div style="display: flex;flex-wrap: wrap; " *ngIf="CheckGeneralCatagory">
            <div *ngFor="let datapoint of datapoints1; let i1 =index" style="margin-left:10px">
              <h2 class="datapointsheading"> {{datapoint.machine == "" ? 'General' : datapoint.machine }} </h2>
              <div *ngFor="let check of datapoint.data; let i2= index">
                <mat-checkbox style="margin: 12px 20px;" #check [checked]="check.checked"
                  (change)="onCheckboxChange($event, check.KpiDpId,check.DataPoints,i1,i2)">{{ check.DataPoints}}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div> -->
      </form>

    </mat-dialog-content>

  </ng-template>
</div>