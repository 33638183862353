<div class="production-db-content">
    <div class="content-top-box">
        <ul class="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                    <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
                </li>
            </ul>
        </ul>
        <div class="title-row-flex-both-end">
            <h1 class="main-title">Production</h1>
            <div>
                <button mat-raised-button color="accent" class="btn-accent mr-15-px">Live</button>
                <button mat-raised-button color="accent" class="btn-accent">Yesterday</button>
            </div>
        </div>
    </div>

    <app-dashboard-header></app-dashboard-header>

    <div class="d-flex">
        <div class="w-100">
            <div class="chart-items w-50 float-left" [ngClass]="{'mr-1': i%2==0, 'w-100': last}"
                *ngFor="let oee of productionOEEChart; let i=index; let last = last;">
                <div class="chart-title">{{productionData[i].title}}</div>
                <div [chart]="oee"></div>
            </div>
        </div>
        <div class="d-flex justify-content-around w-100 my-5px ">
            <div class="chart-items w-98">
                <div class="chart-title">No Orders/No Plan</div>
                <div style="text-align: -webkit-center; background-color: aqua;margin-bottom: -8px;">
                    <p style="font-size: 200%;padding: 20px;margin-bottom: 7px;">0.00</p>
                    <p style="font-size: 100%;margin-top: -26px;"> Min</p>
                </div>
                <div class="chart-items w-100">
                    <div class="chart-title">Machine Output </div>
                    <div
                        style="text-align: -webkit-center; font-size: 200%;background-color: #0f0af2;padding: 20px;margin-bottom: 5px;">
                        <p style="margin-bottom: 7px;">128.25M</p>
                    </div>
                    <div class="chart-items w-100">
                        <div class="chart-title">No Orders/No Plan</div>
                        <div
                            style="text-align: -webkit-center; font-size: 200%;background-color:#f5c80f;padding: 20px;">
                            <p style="margin-bottom: 7px;">146.87M</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-around w-100 my-5px">
            <div class="chart-items w-100">
                <div class="chart-title">Live OEE</div>
                <div [chart]="equipmentEfficency"></div>
            </div>
        </div>
    </div>
    <div class="d-flex">
        <div class="d-flex justify-content-around w-100 my-5px">
            <div class="chart-items w-35">
                <div class="chart-title">Output (Pcs)</div>
                <div [chart]="outputPcs"></div>
            </div>
            <div class="chart-items w-35">
                <div class="chart-title">Total Good Pallets Vs Total</div>
                <div [chart]="pallets"></div>
            </div>
            <div class="chart-items w-30">
                <div class="chart-title">Machine Stops</div>
                <div [chart]="downtime"></div>
            </div>
        </div>
    </div>
</div>