<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a
          class="breadcrumb-link"
          [routerLink]="breadcrumItem.Url"
          [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
          >{{ breadcrumItem.text }}</a
        >
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">FTE-Macro-Level</h1>
    </div>
    <form [formGroup]="SearchFTEForm">
    <div class="content-top-row" style="display: flex; justify-content: flex-end;">
      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label >Select Group</mat-label>
        <mat-select panelClass="testClass" name="group" formControlName="group"
        (selectionChange)="getPlantByGroup()">
        <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
      </mat-select>
        <mat-error >Please select group</mat-error>
      </mat-form-field>
      <mat-form-field  appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label >Select Plant</mat-label>
        <mat-select panelClass="testClass" name="plant" formControlName="plant" (selectionChange)="getProjectByPlant()">
          <mat-option *ngFor="let p of plants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
        </mat-select>
        <mat-error >Please select plant</mat-error>
      </mat-form-field>
    </div>
  </form>
  </div>

    <div class="wrapper-box wrapper-box-shadow grid-container">
      <form [formGroup]="SearchFTEForm">
        <div class="content-top-row content-top-row-order-processing">

   
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label >Select Project</mat-label>
            <mat-select formControlName="project" (selectionChange)="getFTEList()" required>
              <mat-option *ngFor="let p of projectDDL" [value]="p.projectId">{{p.projectName}}</mat-option>                 
            </mat-select>
            <mat-error >Please select project</mat-error>
          </mat-form-field>
          <mat-form-field  appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label >Select Frequency</mat-label>
            <mat-select formControlName="frequencies" (selectionChange)="getFTEList()">                
              <mat-option *ngFor="let frequency of frequencyDDL"[value]="frequency.frequencyId">{{ frequency.frequencyName }}</mat-option>         
            </mat-select>
            <mat-error >Please select frequency</mat-error>
          </mat-form-field>
          <div class="" style="margin-left: 15px;">
            <button 
            mat-mini-fab
            mat-button
            mat-dialog-close
            color="accent"
            (click)="openFTEModel()"
            pTooltip="Add MasterData" tooltipPosition="top"  *ngIf="IsWrite"
            >
            <i class="fa fa-plus" style="color: #fff;"></i>
          </button>
          </div>
        </div>
      </form>
    <div style="display: flex; justify-content: flex-end;">
      <input type="text" formControlName="searchValue" class="form-control" placeholder="Search" (keyup)="applyFilter($event)" autocomplete="off" style="width: 20%;margin-bottom: 10px;"/>
           
  </div>
    <mat-card class="p-mt-0 p-pt-0">
    <mat-card-content>
      <ngx-spinner
          [fullScreen]="false"
          bdColor="#fff"
          size="medium"
          color="#050a4f"
          type="ball-clip-rotate-multiple"
          >
          <p style="font-size: 20px; color: #050a4f">Fetching FTE Data...</p>
      </ngx-spinner>
      <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1" style="overflow-x: scroll;">
        <form [formGroup]="FTEMacroForm" >
          <table mat-table  class="basic-table plant-model-table" [dataSource]="FTEData" matSort>
          
            <ng-container matColumnDef="project">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Project</p>
              </th>
              <td mat-cell *matCellDef="let element"> 
                {{element.ProjectName}}
               </td>
            </ng-container>
            <ng-container matColumnDef="topic">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Topic</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.TopicName}} </td>
            </ng-container>
            <ng-container matColumnDef="task">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Task</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.TaskName}} </td>
            </ng-container>
            <ng-container matColumnDef="frequency">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Frequency</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.Name}} </td>
            </ng-container>
            <ng-container matColumnDef="duration">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Duration</p><span><p class="header-text">(Hours)</p></span>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Duration}} </td>
            </ng-container>
            <ng-container matColumnDef="owner">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Owner</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.UserName}} </td>
            </ng-container>

            <ng-container matColumnDef="periority">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Periority</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.priority}} </td>
            </ng-container>
  
            <ng-container matColumnDef="from">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">From</p>
              </th>
              <td mat-cell *matCellDef="let element">
                  {{ element.From}}
              </td>
            </ng-container>
  
            <ng-container matColumnDef="to">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">To</p>
              </th>
              <td mat-cell *matCellDef="let element; let index = index">
                  {{ element.To }} 
              </td>
            </ng-container>
  
            <ng-container matColumnDef="jan">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Jan</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Jan}} </td>
            </ng-container>
  
            <ng-container matColumnDef="feb">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Feb</p>
              </th>
              <td mat-cell *matCellDef="let element" > {{element.Feb}} </td>
            </ng-container>
  
            <ng-container matColumnDef="mar">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Mar</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Mar}} </td>
            </ng-container>
  
            <ng-container matColumnDef="apr">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Apr</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Apr}} </td>
            </ng-container>
  
            <ng-container matColumnDef="may">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">May</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.May}} </td>
            </ng-container>
  
            <ng-container matColumnDef="jun">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Jun</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Jun}} </td>
            </ng-container>
  
            <ng-container matColumnDef="jul">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Jul</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Jul}} </td>
            </ng-container>
  
            <ng-container matColumnDef="aug">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Aug</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Aug}} </td>
            </ng-container>
  
            <ng-container matColumnDef="sep">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Sep</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Sep}} </td>
            </ng-container>
  
            <ng-container matColumnDef="oct">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Oct</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Oct}} </td>
            </ng-container>
  
            <ng-container matColumnDef="nov">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Nov</p>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Nov}} </td>
            </ng-container>
  
            <ng-container matColumnDef="dec">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Dec</p>
              </th>
              <td mat-cell *matCellDef="let element" > {{element.Dec}} </td>
            </ng-container>
    
            <ng-container matColumnDef="FTEActions">
              <th mat-header-cell *matHeaderCellDef>
                <p class="header-text">Action</p>
    
              </th>
              <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                <div class="row-action-btns">
                  <button mat-icon-button class="edit-btn"  aria-label="Edit Row"
                         pTooltip="Edit FTE" tooltipPosition="top" (click)="openEditFTEModal(element)"  *ngIf="IsWrite">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                        fill="white" />
                    </svg>
                  </button>
                  <button mat-icon-button  class="node-control-btn node-delete" aria-label="delete item"
                        pTooltip="Delete FTE" tooltipPosition="top" (click)="DeleteFTE(element)"  *ngIf="IsWrite">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                            fill="white" />
                        </svg>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="FTEColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: FTEColumns;"></tr>
            <tr class="mat-row" *matNoDataRow style="text-align: center;">
              <td class="mat-cell" colspan="22">No data matching</td>
            </tr>
          </table>
        </form>
      </div>
      <mat-paginator
      class="basic-paginataor"
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons>
    </mat-paginator>
    </mat-card-content>
  </mat-card>
    </div>
</div>

<div class="sidepanel-model-wrapper edit-plant-row-model"  [ngClass]="{'active': FTEModelVisible}">
  <div class="sidepanel-close" (click)="closeaFTEModel()" ></div>
  <div class="model-content">
    <div class="model-top">
      <h2 class="model-title">{{FTEformMode ? 'Add' : 'Update'}} FTE</h2>
      <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeaFTEModel()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
            fill="black" />
        </svg>
      </button>
    </div>
    <div class="model-body">
      <form [formGroup]="FTEForm">
        <div class="form-group">
          <mat-form-field  appearance="fill" class="field-fill">
            <mat-label class="required">Plant</mat-label>
            <mat-select  name="plant" formControlName="taskplant" [disabled]="true" required> 
              <mat-option *ngFor="let p of plants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
              </mat-select>
              <mat-error>Required</mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          
          <mat-form-field  [ngClass]="{
            'is-invalid':
              FTEForm.get('projectname')?.errors &&
              FTEForm.get('projectname')?.touched
          }" appearance="fill" class="field-fill">
          <mat-label class="required">Select Project</mat-label>
          <mat-select formControlName="projectname" placeholder="Select Project" (selectionChange)="getMastertopic()" [disabled]="true" required>
                         
            <mat-option *ngFor="let p of projectDDL" [value]="p.projectId" >{{p.projectName}}</mat-option>             
          </mat-select>
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
          FTEForm.get('projectname')?.touched &&
          FTEForm.get('projectname')?.hasError('required')">
            * project name is required!
            </p>
            <p class="invalid-feedback" *ngIf="
            FTEForm.get('projectname')?.touched &&
            FTEForm.get('projectname')?.hasError('pattern')">
            * please remove special charactor
            </p>

        </div>
        <div class="form-group">
          <mat-form-field  [ngClass]="{
            'is-invalid':
              FTEForm.get('topic')?.errors &&
              FTEForm.get('topic')?.touched
          }" appearance="fill" class="field-fill">
          <mat-label class="required">Select Topic</mat-label>
          <mat-select formControlName="topic" placeholder="Select Topic" (selectionChange)="getTaskByTopicid()" required>
                       
            <mat-option *ngFor="let p of topicDDL" [value]="p.topicId">{{p.topicName}}</mat-option>
          </mat-select>
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
              FTEForm.get('topic')?.touched &&
              FTEForm.get('topic')?.hasError('required')">
            * Topic is required!
            </p>
        </div>
        <div class="form-group">
          <mat-form-field  [ngClass]="{
            'is-invalid':
              FTEForm.get('task')?.errors &&
              FTEForm.get('task')?.touched
          }" appearance="fill" class="field-fill">
          <mat-label class="required">Select Task</mat-label>
          <mat-select formControlName="task" placeholder="Select Task" required>        
            <mat-option *ngFor="let p of taskDDL" [value]="p.taskId">{{p.taskName}}</mat-option>
          </mat-select>
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
              FTEForm.get('task')?.touched &&
              FTEForm.get('task')?.hasError('required')">
            * Task is required!
            </p>
        </div>
        <div class="form-group">
          <mat-form-field  [ngClass]="{
            'is-invalid':
              FTEForm.get('frequency')?.errors &&
              FTEForm.get('frequency')?.touched
          }" appearance="fill" class="field-fill">
          <mat-label class="required">Select Frequency</mat-label>
          <mat-select formControlName="frequency" placeholder="Select Frequency" [disabled]="true" required>                
            <mat-option *ngFor="let frequency of frequencyDDL"[value]="frequency.frequencyId">{{ frequency.frequencyName }}</mat-option>
          </mat-select>
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
              FTEForm.get('frequency')?.touched &&
              FTEForm.get('frequency')?.hasError('required')">
            * project name is required!
            </p>
        </div>
        <div class="form-group">
          <mat-form-field  [ngClass]="{
            'is-invalid':
              FTEForm.get('duration')?.errors &&
              FTEForm.get('duration')?.touched
          }" appearance="fill" class="field-fill">
            <mat-label>Enter Duration (In Hours)</mat-label>
            <input type="number" matInput placeholder="Enter Duration" formControlName="duration" required autocomplete="off" (change)="DailyChanges($event)">
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
            FTEForm.get('duration')?.touched &&
            FTEForm.get('duration')?.hasError('required')">
            * Duration is required!
            </p>
        </div>
        <div class="form-group">
          <mat-form-field  appearance="fill" class="field-fill">
            <mat-label>Select Multiple Owners</mat-label>
          <mat-select  formControlName="owner" placeholder="Select Owner" 
          *ngIf="FTEformMode" multiple required>
            <mat-select-filter 
            [placeholder]="'Search'" 
            [displayMember]="'userName'"
            [array]="OwnerDDL" 
            (filteredReturn)="filteredUsers = $event"
            ></mat-select-filter>
            <mat-option *ngFor="let Own of filteredUsers" [value]="Own.userId" >{{Own.userName}}</mat-option>
          </mat-select>  
          <mat-select  formControlName="owner" placeholder="Select Owner" 
          *ngIf="!FTEformMode" multiple required>
            <mat-select-filter
              [placeholder]="'Search'"
              [displayMember]="'userName'"
              [array]="OwnerDDLForEdit"
              (filteredReturn)="filteredUsers = $event"
            ></mat-select-filter>
            <mat-option *ngFor="let Own of filteredUsers" [value]="Own.userId" >
              {{Own.userName}}</mat-option
            >
          </mat-select>  
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field  [ngClass]="{
            'is-invalid':
              FTEForm.get('periority')?.errors &&
              FTEForm.get('periority')?.touched
          }" appearance="fill" class="field-fill">
            <mat-label>Enter Priority</mat-label>
            <mat-select formControlName="periority" placeholder="Select Priority" required>                
              <mat-option value="Low">Low</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="High">High</mat-option>
            </mat-select>
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
            FTEForm.get('periority')?.touched &&
            FTEForm.get('periority')?.hasError('required')">
            * priority is required!
            </p>
        </div>

        <nz-form-label >Start Date:</nz-form-label>
        <div class="content-top-row" style="margin-bottom: 20px;">
          <nz-date-picker class="ngdatepickercustom" nzPlaceHolder="Start Date" id="startPicker" formControlName="fromdate" nzFormat="dd/MM/yyyy HH:mm"
                    [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" [nzDisabled]="dateDisableProperty" ></nz-date-picker>
         
          <p class="invalid-feedback" *ngIf="
            FTEForm.get('fromdate')?.touched &&
            FTEForm.get('fromdate')?.hasError('required')">
            * from date is required!
            </p>
        </div>

        <nz-form-label>End Date:</nz-form-label>
        <div class="content-top-row">
          
          <nz-date-picker class="ngdatepickercustom" nzPlaceHolder="End Date" id="endPicker" formControlName="todate" nzFormat="dd/MM/yyyy HH:mm"
          [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" [nzDisabled]="dateDisableProperty" ></nz-date-picker>
         
          <p class="invalid-feedback" *ngIf="
            FTEForm.get('todate')?.touched &&
            FTEForm.get('todate')?.hasError('required')">
            * to date is required!
            </p>
        </div>

        <div class="model-form-actions">
        <button
        (click)="updateFTE()"
        *ngIf="!FTEformMode"
        [disabled]="!FTEForm.valid"
        type="submit"
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="primary"
        pTooltip="Update" tooltipPosition="top"
      >
        <i class="fa fa-pencil" style="color: #fff"></i>
      </button>
      <button
      (click)="saveFTE()"
      *ngIf="FTEformMode"
        [disabled]="!FTEForm.valid"
        type="submit"
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="primary"
        pTooltip="Save" tooltipPosition="top" 
      >
        <i class="fa fa-floppy-o" style="color: #fff"></i>
      </button>
      <button
      (click)="closeaFTEModel()"
      type="button"
      mat-mini-fab
      mat-button
      mat-dialog-close
      color="warn"
      pTooltip="Cancel" tooltipPosition="top" 
    >
      <i class="fa fa-close" style="color: #fff"></i>
    </button>
        </div>
      </form>
    </div>
  </div>
</div>
