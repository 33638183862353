<div class="machine-parameter-content"> 
    <div class="content-top-box">
      <ul class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="breadcrumb-link"></a>
        </li>
      </ul>
      <div class="tab-content-top-box">
        <h1 class="main-title">CIL Report</h1>
      </div>
    </div>
    <div class="plant-model-content">
  
      <form >
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <div class="content-top-row">
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing ">
                <mat-label class="placeholder-title">Group</mat-label>
                <mat-select formControlName="groupList" panelClass="testClass" name="Group">
                  <mat-option >
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing ">
                <mat-label class="placeholder-title">Unit data</mat-label>
                <div class="unit-input-wrapper">
                  <input matInput readonly="true" formControlName="UnitDataList">
                  <span matSuffix >
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15 9.375V5.625H4.6875V6.5625H2.8125V3.75H11.25V0H0V3.75H1.875V13.125H4.6875V15H15V11.25H4.6875V12.1875H2.8125V7.5H4.6875V9.375H15Z"
                        fill="#824AED" />
                    </svg>
                  </span>
                </div>
              </mat-form-field>
              <button class="icon-btn" style="margin-left: 27px;">
                <img src="assets/images/refresh1.png" alt="" width="20px" height="20px">
              </button>
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" >
                <mat-label class="placeholder-title">From Date</mat-label>
                <input matInput [ngxMatDatetimePicker]="picker" placeholder="Process To Date" formControlName="fromProcessDate" readonly/>
          <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker> </ngx-mat-datetime-picker>
            </mat-form-field>
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" >
                <mat-label class="placeholder-title">To Date</mat-label>
                <input matInput [ngxMatDatetimePicker]="timePicker" placeholder="Process To Date" formControlName="toProcessDate"  readonly/>
          <mat-datepicker-toggle matSuffix [for]="$any(timePicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #timePicker> </ngx-mat-datetime-picker>
            </mat-form-field>
            </div>
        </div>
      </form>
      <div style="display: flex; justify-content: flex-end;">
        <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'test', sheet: 'sheet_name', Props: {Author: 'Talha'}})">Excel</button>
        <button mat-raised-button (click)="exporter.exportTable('csv')">Csv</button>
        <button mat-raised-button (click)="exporter.exportTable('json')">Json</button>
        <button mat-raised-button (click)="exporter.exportTable('txt')">Txt</button>
      </div>
      <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" class="basic-table plant-model-table"
      matSort>
      <!-- Machine Column -->
      <ng-container matColumnDef="machine">
        <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header
          class="header-min-width">
          <p class="header-text">Machine</p>
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.machine}}
        </td>
      </ng-container>

      <!-- Component Column -->
      <ng-container matColumnDef="component">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 174px;">
          <p class="header-text">Component</p>
        </th>
        <td mat-cell *matCellDef="let element">
        </td>
      </ng-container>

      <!-- Task Name Column -->
      <ng-container matColumnDef="taskname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Task Name</p>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.puDesc }}</td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="grp-order">
          <p class="header-text">Type</p>
        </th>
        <td mat-cell *matCellDef="let element">

        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
          <p class="header-text">Status</p>
        </th>
        <td mat-cell *matCellDef="let element">

        </td>
      </ng-container>

      <!-- TimeStamp Column -->
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
          <p class="header-text">Task TimeStamp</p>
        </th>
        <td mat-cell *matCellDef="let element">
         
        </td>
      </ng-container>

       <!-- Start Time Column -->
       <ng-container matColumnDef="starttime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
          <p class="header-text">Start Time</p>
        </th>
        <td mat-cell *matCellDef="let element">
        
        </td>
      </ng-container>

        <!-- End Time Column -->
        <ng-container matColumnDef="endtime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
            <p class="header-text">End Time</p>
            </th>
            <td mat-cell *matCellDef="let element">
            
            </td>
        </ng-container> 
        
          <!-- Actual Time Column -->
          <ng-container matColumnDef="actualtime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
            <p class="header-text">Actual Time(Mins)</p>
            </th>
            <td mat-cell *matCellDef="let element">
            
            </td>
        </ng-container> 
        
          <!-- Estimated Time Column -->
          <ng-container matColumnDef="estimatedtime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
            <p class="header-text">Estimated Time(Mins)</p>
            </th>
            <td mat-cell *matCellDef="let element">
            
            </td>
        </ng-container>   
        
          <!-- Remarks Column -->
          <ng-container matColumnDef="remarks">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
            <p class="header-text">Remarks</p>
            </th>
            <td mat-cell *matCellDef="let element">
            
            </td>
        </ng-container>      
        
          <!-- Task Column -->
          <ng-container matColumnDef="task">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
            <p class="header-text">Task Frequency</p>
            </th>
            <td mat-cell *matCellDef="let element">
            
            </td>
        </ng-container> 
        
          <!-- Pre Task Image Column -->
          <ng-container matColumnDef="taskimg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
            <p class="header-text">Pre Task Image</p>
            </th>
            <td mat-cell *matCellDef="let element">
            
            </td>
        </ng-container> 
        
          <!-- SOP Column -->
          <ng-container matColumnDef="sop">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
            <p class="header-text">SOP</p>
            </th>
            <td mat-cell *matCellDef="let element">
            
            </td>
        </ng-container>     
        
          <!-- User Name Column -->
          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="created-on">
            <p class="header-text">User Name</p>
            </th>
            <td mat-cell *matCellDef="let element">
            
            </td>
        </ng-container>           

      <tr mat-header-row *matHeaderRowDef="cilreportColumns"></tr>
      <tr mat-row *matRowDef="
          let row;
          columns: cilreportColumns
        "></tr>
        <tr class="mat-row" *matNoDataRow style="text-align: center;">
          <td class="mat-cell" colspan="8">No data matching</td>
        </tr>
    </table>

    <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
    </div>