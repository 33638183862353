import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  baseUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getAllModule(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'Language/GetModuleDD');
  }

  GetAllPages(data:any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'Language/GetPagesDD',data);
  }

  getLanguages(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'Language/GetLanguageDD');
  }

  saveLanguage(data:any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'Language/SubmitLanguage',data);
  }

  getTranslatedLanguages(data:any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'Language/getAllLanguageByModuleIDPageIDLanguageID',data);
  }

  getLanguageTags(data:any):Observable<any>{
    return this.http.post<any>(this.baseUrl + 'Language/getLanguageTags',data);
  }
}