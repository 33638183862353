import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GridSizeChangedEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BtnDeleteCellRenderer } from 'src/app/btn-deletecell-renderer';
import { MatCheckboxRendererComponent } from 'src/app/mat-checkbox-renderer';
import { MatSelectRendererComponent } from 'src/app/mat-selectlist-renderer';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { MeetingEvaluationService } from 'src/app/services/meeting-evaluation.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-meeting-evaluation',
  templateUrl: './meeting-evaluation.component.html',
  styleUrls: ['./meeting-evaluation.component.scss']
})
export class MeetingEvaluationComponent implements OnInit {
  gridApi: any;
  gridColumnApi: any;
  modules: any;
  defaultColDef: any;
  components: any;
  columnDefs: any;
  columnDefs1: any;
  rowData: any[] = [];
  bottomData: any;
  rowData1: any[] = [];
  MeetingEvaluationForm!: FormGroup;
  bottomOptions = {
    alignedGrids: [],
    defaultColDef: {
      flex: 1,
      minWidth: 110,
    }
  };
  gridOptions = {
    frameworkComponents: {
      btnCellRenderer: BtnDeleteCellRenderer,
      checkboxRenderer: MatCheckboxRendererComponent,
      slectlistRenderer: MatSelectRendererComponent
    },
  };

  meetingMasterId = 0;
  ownerList: any[] = [];
  rowSpanCount = 0;
  totalWeight = 0;
  totalScore = 0;
  totalScorePercentage = '0';
  isSaving = false;
  disabled = true;
  successMessage = '';
  paramsValues: any = {
    plant: 0,
    meeting: 0,
    group: 0,
    meetingAttendId: 0,
  };
  constructor(private fb: FormBuilder, private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private meetingEvaluationService: MeetingEvaluationService,
    private route: ActivatedRoute, private kpiservice: KpitreeService) {
    this.MeetingEvaluationForm = this.fb.group({
      meeting: [''],
      location: [''],
      leader: [''],
      date: ['']
    });
    this.columnDefs = [
      {
        field: 'area',
        cellRenderer: (params: any) => {
          if (params.value && params.value.name) {
            return '<div class="area-name">' +
              params.value.name +
              '' +
              '</div>';
          } else { return ''; }
        },
        rowSpan: this.rowSpan,
        cellClassRules: { 'show-cell': 'value !== undefined' },
        cellStyle: (params: any) => {
          if (params.value && params.value.name) {
            return { background: '#49FFB9', border: '1px solid #333' };
          } else {
            return { background: 'white' };
          }
        },
        width: 120,
      },
      { field: 'detail', headerName: 'check detail', width: '390' },
      { field: 'weight', headerName: 'Weight', width: '150' },
      {
        field: 'score', headerName: 'Self Evaluation Score', width: '200', editable: true,
        onCellValueChanged: (params: any) => this.onChangeScore(params),
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['0', '0.5', '1'],
        },
        cellStyle: {
          color: 'black',
          'background-color': '#EDF1E5',
        }
      }
    ];
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      this.paramsValues.plant = param?.plant ? parseInt(param?.plant, 10) : 0;
      this.paramsValues.meeting = param?.meeting ? parseInt(param?.meeting, 10) : 0;
      this.paramsValues.group = param?.group ? parseInt(param?.group, 10) : 0;
      this.paramsValues.meetingAttendId = param?.meetingAttendId ? parseInt(param?.meetingAttendId, 10) : 0;
    });
    this.getUsers();
    this.getMeetingDefinations();
  }




  getUsers(): void {

    const key = {
      EGId: this.paramsValues.group,
      PlantId: this.paramsValues.plant
    };
    this.kpiservice.getEmployeeMasterData(key).then((data) => {
      this.ownerList = data.employeeInfoList?.map((user: any) => {
        return {
          id: user.EmpInfo_Id,
          name: user.FirstName + ' ' + user.lastName
        };
      });
      this.columnDefs1 = [
        { field: 'problem', headerName: 'Problem', width: 250, editable: true },
        { field: 'action', headerName: 'Action Required', width: 300, editable: true },
        {
          field: 'owner', headerName: 'Owner', width: 215, editable: true,
          cellRenderer: 'slectlistRenderer',
          cellEditorParams: {
            data: this.ownerList
          },
        },
        {
          field: 'date', headerName: 'Date', width: 150,
          valueFormatter: (prop: any) => moment(new Date().toISOString()).format('DD-MM-YYYY')
        },
        {
          field: 'successful', headerName: 'Successful ?', width: 150,
          cellRenderer: 'checkboxRenderer'
        },
        {
          field: 'rowId', headerName: 'Action', width: 150,
          cellRenderer: 'btnCellRenderer',
          cellRendererParams: {
            onClick: this.deleteRow.bind(this),
            label: 'Delete',
          }
        },
      ];
      this.rowData1 = [
        {
          problem: '', action: '', owner: this.ownerList[0].id,
          date: moment(new Date().toISOString()).format('DD-MM-YYYY'), successful: false, rowId: Math.floor(Math.random() * 1000)
        }
      ];
    }, ({ error }) => {
      this.toaster.error(error.message);
    });
  }

  getMeetingDefinations(): void {
    const key = {
      plantId: this.paramsValues.plant,
      meetingId: this.paramsValues.meeting
    };
    this.meetingEvaluationService.getAllMeetingEvaluationActions(key).subscribe(res => {
      this.meetingMasterId = res?.meetingMasterId;
      //  setting values in form group
      this.MeetingEvaluationForm.get('meeting')?.setValue(res?.meetingDefName);
      this.MeetingEvaluationForm.get('leader')?.setValue(res?.leaderName);
      this.MeetingEvaluationForm.get('date')?.setValue(new Date());
      // getting thte unique areas from response
      const uniqueAreas = [... new Set(res?.detail?.map((item: any) => {
        return item.name;
      }))];
      // setting the total weight
      this.totalWeight = res?.detail.map((item: any) => {
        return item?.weight;
      }).reduce((a: any, b: any) => a + b);
      // setting the total score
      this.totalScore = res?.detail.map((item: any) => {
        return parseFloat(item?.score);
      }).reduce((a: any, b: any) => a + b);
      // setting the total score percentage
      this.totalScorePercentage = ((this.totalScore / res?.detail.length) * 100).toFixed(2);

      // formate data according to the ag grid
      const formattedArray: any[] = [];
      uniqueAreas.forEach(area => {
        const filteredArray = res?.detail.filter((x: any) => x?.name === area);
        if (filteredArray.length > 0) {
          filteredArray.forEach((meeting: any) => {
            const areafound = formattedArray.findIndex((item: any) => {
              return item?.area?.name === area;
            });
            if (areafound === -1) {
              formattedArray.push({
                area: {
                  name: area
                },
                detail: meeting?.detail,
                weight: meeting?.weight,
                score: meeting?.score,
                totalcount: filteredArray?.length
              });
            } else {
              formattedArray.push({
                detail: meeting?.detail,
                weight: meeting?.weight,
                score: meeting?.score
              });
            }
          });
        }
      });
      // setting formatted data in ag grid
      this.rowData = formattedArray;
    }, ({ error }) => {
      this.toaster.error(error.message);
    });
  }

  onChangeScore(params: any): void {
    // setting the total score
    this.totalScore = this.rowData.map((item: any) => {
      return parseFloat(item?.score);
    }).reduce((a: any, b: any) => a + b);
    // setting the total score percentage
    this.totalScorePercentage = ((this.totalScore / this.rowData?.length) * 100).toFixed(2);
  }

  saveRecord(): void {
    this.isSaving = true;
    const data = {
      meetingMasterId: this.meetingMasterId,
      date: this.MeetingEvaluationForm.value.date,
      meetingAttendId: this.paramsValues.meetingAttendId,
      details: this.rowData?.map((item) => {
        return {
          selfEvaScore: parseFloat(item?.score),
          weight: item?.weight,
          percentage: parseFloat(this.totalScorePercentage),
        };
      }),
      actions: this.rowData1?.filter(x => x.problem !== '').map((item) => {
        return {
          problem: item?.problem,
          actionRequired: item?.action,
          ownerId: item?.owner,
          date: new Date(item?.date),
          isSuccess: item?.successful,
        };
      })
    };
    if (data.actions.length > 0) {
      this.meetingEvaluationService.addMeetingEvaluation(data).subscribe(res => {
        this.successMessage = 'Thanks for your feedback';
        this.getUsers();
        this.getMeetingDefinations();
        this.isSaving = false;
      }, ({ error }) => {
        this.toaster.error(error.message);
        this.isSaving = false;
      });
    } else {
      this.toaster.warning('Please add some actions first');
      this.isSaving = false;
    }
  }

  addNewRow(): any {
    if (this.rowData1.filter(x => x.problem === '' || x.action === '')?.length > 0) {
      this.toaster.warning('Please Fill Or Remove Empty fields first');
      return;
    }
    const slicedRowData = this.rowData1.slice();
    slicedRowData.push({
      problem: '',
      action: '',
      owner: this.ownerList[0].id,
      date: moment(new Date().toISOString()).format('DD-MM-YYYY'),
      successful: false,
      rowId: Math.floor(Math.random() * 1000)
    });
    this.rowData1 = slicedRowData;
  }

  deleteRow(e: any): any {
    this.rowData1 = this.rowData1.filter(x => x.rowId !== e.rowData?.rowId);
  }
  rowSpan(params: any): any {
    if (params.data.area) {
      return params.data.totalcount;
    } else {
      return 1;
    }
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridSizeChanged(params: GridSizeChangedEvent): any {
    params.api.sizeColumnsToFit();
  }
}
