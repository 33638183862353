<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
  <p style="color: white">Fetching Data...</p>
</ngx-spinner>
<div class="content-top-box ">
    <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="displayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
    </ul>
    <div class="tab-content-top-box">
        <h1 class="main-title">Glass Chemical Lab QA Report</h1>
    </div>
    <form [formGroup]="chemicalQaReportForm">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <div class="content-top-row">
                <div class="inputField" style="width: 245px;">
                    <nz-date-picker class="calender-input" style="width: 240px;"
                    formControlName="fromdate" nzFormat="yyyy-MM-dd"
                    [nzInputReadOnly]="true"  ></nz-date-picker>
                    <span class="floating-text">Start Date</span>   
                </div>    
                <div class="inputField" style="width: 245px;">
                    <nz-date-picker class="calender-input" style="width: 240px;"
                    formControlName="todate" nzFormat="yyyy-MM-dd"
                    [nzInputReadOnly]="true" ></nz-date-picker>
                    <span class="floating-text">End Date</span>   
                </div>  
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-label>Select Report Type</mat-label>
                    <mat-select formControlName="reportType" panelClass="testClass" name="reportType" (selectionChange)="getChemicalMaterialProperties(false)">
                        <mat-option *ngFor="let material of materialList" [value]="material.TypeId">{{material.TypeName }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button type="button" mat-button mat-mini-fab color="primary" (click)="getReports()"
                pTooltip="Get Report" tooltipPosition="top" style="margin-left: 10px;">
                <mat-icon>print</mat-icon>
                </button>
            </div>
        </div>
    </form>
    <mat-divider></mat-divider>
    <div style="margin-top: 20px;">
        <div class="d-flex justify-content-between items-center" style="margin: 20px 0 20px 0;">
          <button type="button" mat-button mat-flat-button color="accent" (click)="exportToExcel()"
          pTooltip="Export Excel" tooltipPosition="top" style="margin-left: 10px;">
           Export To Excel
          </button>
        </div>
        <mat-divider></mat-divider>
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
          <div class="d-flex justify-content-between items-center">
            <h2 style="font-weight: bold;">Chemical - {{reportType}} Report</h2>
            <!-- <h4 style="font-weight: bold;">{{currentDate | date: 'MM/dd/yy, H:mm:ss'}}</h4> -->
          </div>
          <mat-divider style="margin-bottom: 10px;"></mat-divider>
          <ag-grid-angular style="height: 35vw; width: 100%;" class="ag-theme-alpine" [rowData]="rowDataReport" [columnDefs]="columnDefsReport"
          (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef" [pagination]="true" [paginationPageSize]="10"></ag-grid-angular>            
        </div>
      </div>
</div>