import { Component,  OnInit , TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { CILDashboardService } from 'src/app/services/cil-dashboard-service';
// import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { ButtonViewRendererComponent } from 'src/app/components/button-view-render.components';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-cil-dashboard',
  templateUrl: './cil-dashboard.component.html',
  styleUrls: ['./cil-dashboard.component.scss']
})

export class CilDashboardComponent implements OnInit {
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('popupViewModal') private popupViewModal!: TemplateRef<any>;

  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  filteredGroups: any;
  filteredPlants : any;
  filteredLines : any;
  filteredUnits : any;
  filteredComponents :any
  breadcrumList: any;
  moduleId!: any;
  pageId!:any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  componentList!:any;
  CLLDashboardForm!: FormGroup;
  TotalDefects!: any;
  TotalDefecteSolved!:any;
  CILAdherence!:any;
  TotalDefectsPerc!:any;
  CILTask!: any;
  key: any;
  dashboardData!: any;
  chartCILTask!: Chart;
  chartCILType! : Chart;
  chartCILTool! : Chart;
  options!: Options;
  image!:any;
  currentImagePath!: any;
  defaultExcelExportParams: any;
  frameworkComponents: any;

  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;
  imageUrl: string = '';
 
  gridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
  };
   // iconUrl
   menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
   menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';

  constructor(
    private formBuilder: FormBuilder,
    private productivityservice: ProductivityService,
    private cilService : CILDashboardService,
    private toaster: ToastrService,
    public router: Router,
    private userService: UserManagementService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    ) { 
    }

  ngOnInit(): void {
    this.imageUrl = environment.O3CoreApiImageUrl;
    this.frameworkComponents = {
      buttonRenderer: ButtonViewRendererComponent,
    }
    this.columnDefs = [
      { headerName: 'Line', field: 'Pl_Desc', filter: 'agTextColumnFilter' },
      { headerName: 'Task Name', field: 'taskName', filter: 'agTextColumnFilter' },
      { headerName: 'Machine', field: 'Machine', filter: 'agTextColumnFilter' },
      { headerName: 'Component', field: 'Component', filter: 'agTextColumnFilter' },
      { headerName: 'Task Status', field: 'taskStatus', filter: 'agTextColumnFilter' },
      { headerName: 'Type', field: 'Type', filter: 'agTextColumnFilter' },
      { headerName: 'Start Time', field: 'StartTime', filter: 'agDateColumnFilter', valueFormatter: function (params: any) {return params.value !== null ?  moment(params.value).format('MM/DD/YYYY HH:mm:ss') :'' } },
      { headerName: 'End Time', field: 'EndTime', filter: 'agDateColumnFilter', valueFormatter: function (params: any) { return params.value !== null ? moment(params.value).format('MM/DD/YYYY HH:mm:ss') : '' } },
      { headerName: 'Estimated Time', field: 'EstimatedTime',filter: 'agTextColumnFilter' },
      { headerName: 'Remarks', field: 'Remarks', filter: 'agTextColumnFilter' },
      { headerName: 'Frequency', field: 'Frequency', filter: 'agTextColumnFilter' },
      { headerName: 'User Name', field: 'UserName', filter: 'agTextColumnFilter' },
      { headerName: 'PreImage', field: 'PreTaskImage',cellRenderer: (params: any) => {return `<img style="width:40px;height:40px ;cursor:pointer" alt="${params.value}" src="${params.value !== '' ? this.imageUrl + params.value : 'assets/images/no-image.png' }">`}},
      { headerName: 'PostImage', field: 'Postimage',cellRenderer: (params: any) => {return `<img style="width:40px;height:40px ;cursor:pointer" alt="${params.value}" src="${params.value !== '' ? this.imageUrl + params.value : 'assets/images/no-image.png' }">`}}
    ]
    this.defaultExcelExportParams = {
      addImageToCell: function (rowIndex: number, col: any, value: any,callback:any) {
        if (col.colId === 'PreImage' || col.colId === 'PostImage') {
         
        }else{
         return;
        }
      },
    }
    this.initform();
    this.CLLDashboardForm.controls.unit.patchValue("0");
    this.CLLDashboardForm.controls.components.patchValue("0");
    this.CLLDashboardForm.controls.line.patchValue("0");
    this.datasource();
    this.GetBreadcrumList();
  }

  initform(){
    const dateStart = new Date();
    //dateStart.setDate(dateStart.getDate() - 183);
    dateStart.setHours(6);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);


    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate());
    this.CLLDashboardForm = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      components:['',Validators.required],
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
      shift: ['', []],
    });
  };

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  onChangeLine(data: any): void {
    if (parseInt(data.value) === 0) {
      this.filteredUnits.length = 0;
      this.filteredComponents.length = 0;
      this.CLLDashboardForm.controls.unit.patchValue("0");
      this.CLLDashboardForm.controls.components.patchValue("0");
      this.getUnit(parseInt(data.value));
    } else {
      this.getUnit(data.value);
    }
  }
  onChangePlant(data: any): void {
    this.getLine(data.value);
  }
  onChangeUnit(data: any): void {
    this.getComponent(data.value);
  }
  datasource(): void {
    const key = {
      UserId: 206 
    };
    const id = {
      value: 0
    };
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      id.value = data.lstEG[0].EG_Id;
      this.CLLDashboardForm.get('UserGroup1')?.setValue(data.lstEG[0].EG_Id);
      this.CLLDashboardForm.get('UserPlant1')?.setValue(data.lstEG[0].EG_Id);
      this.filteredGroups = this.GroupList.slice();
      this.getPlants(id);
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: 206
    };

    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      const id = data.lstPlant[0].PT_Id;
      this.CLLDashboardForm.get('UserPlant1')?.patchValue(id);
      this.filteredPlants = this.PlantList1.slice();
      this.getLine(id);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getLine(id: any) {
    const key = {
      PT_ID: id,
      userId: 206
    };

    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      const lineDefault = this.lineList[0];
      this.CLLDashboardForm.controls.line.patchValue(lineDefault.PL_Id);
      this.getUnit(lineDefault.PL_Id);
      this.filteredLines = this.lineList.slice();
    }, (error: any) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  getUnit(id: number) {
    const key = {
      PL_ID: id,
      userId: 206,
      PT_ID: this.CLLDashboardForm.value.UserPlant1,
    };

    this.productivityservice.getUnitData(key).subscribe((data: any) => {
      this.unitList = data.lstUnit;
      const unitDefault = this.unitList[0];
      if(this.unitList.length !== 0){
        this.CLLDashboardForm.controls.unit.patchValue(unitDefault.PU_Id);
        this.getComponent(unitDefault.PU_Id);
      }else{
        this.getComponent(0);
      }
      this.filteredUnits = this.unitList.slice();
     
    }, (error: any) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  getComponent(id  : number){
    const key = {
      PU_ID: id,
      UserId: 206 ,
      PT_ID: this.CLLDashboardForm.value.UserPlant1,  
    };
    this.productivityservice.getComponentData(key).subscribe((data: any) => {
      this.componentList = data.lstComponent;
      const comDefault = this.componentList[0];
      this.componentList.length !== 0 ?this.CLLDashboardForm.controls.components.patchValue(comDefault.ComponentId):this.CLLDashboardForm.controls.components.patchValue("0");
      this.filteredComponents = this.componentList.slice();
      this.onSubmit();
    }, (error: any) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  onSubmit(): void {
    this.makeData();
    this.getAMCILDashboard();
  }

  today(): void {
    this.makeDatatoDay();
    this.getAMCILDashboard();
  }

  yesterday(): void {
    this.makeDataYesterDay();
    this.getAMCILDashboard();
  }

  lastWeek(): void {
    this.makeDataLastWeek();
    this.getAMCILDashboard();
  }

  lastMonth(): void {
    this.makeDataLastMonth();
    this.getAMCILDashboard();
  }
  
  getAMCILDashboard() {
    this.spinner.show();
    this.cilService.getCILDashboard(this.key).subscribe((data: any) => {
      this.dashboardData = data;
      this.spinner.hide();
      this.setData();
    }, (error: any) => {
      this.toaster.error('error', error.ErrorMessage);
      this.spinner.hide();
    });
  }

  setData() {
    if(this.dashboardData !== null){
      this.TotalDefects = this.dashboardData.totalCount;
      this.TotalDefecteSolved = this.dashboardData.doneCount;
      this.TotalDefectsPerc = this.dashboardData.NotExecutedCount;
      if((parseInt(this.dashboardData.doneCount)/parseInt(this.dashboardData.totalCount))){
        this.CILAdherence = ((parseInt(this.dashboardData.doneCount)/parseInt(this.dashboardData.totalCount))*100).toFixed(2) + ' %'
      }
      else{
        this.CILAdherence = 0 + '%'
      }

      this.CILTask = this.dashboardData.NotDoneCount;
      if(this.dashboardData.dtData){
        this.drawCILTaskChart();
        this.drawCilTypeChart();
        this.drawCilToolChart();
      }
      this.rowData = this.dashboardData.dtData;
    }
  }

  makeData() {
    const startDt = this.CLLDashboardForm.value.dateStart;
    const endDt = this.CLLDashboardForm.value.dateEnd;
    this.key = {
      groupId:this.CLLDashboardForm.value.UserGroup1,
      PT_ID: this.CLLDashboardForm.value.UserPlant1,
      UnitId: this.CLLDashboardForm.value.unit,
      ComponentId: this.CLLDashboardForm.value.components,
      dtStartDate: startDt,
      dtEndDate: endDt,
      Status: '*'
    };

  }

  makeDatatoDay(){
    const stDT = new Date();
    stDT.setDate(stDT.getDate());
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    const startDt = stDT;
    const endDt = edDT;
    this.CLLDashboardForm.get('dateStart')?.patchValue(stDT);
    this.CLLDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      groupId: this.CLLDashboardForm.value.UserGroup1,
      PT_ID: this.CLLDashboardForm.value.UserPlant1,
      UnitId: this.CLLDashboardForm.value.unit,
      ComponentId: this.CLLDashboardForm.value.components,
      dtStartDate: startDt,
      dtEndDate: endDt,
      Status: '*'
    };
   
  }
  makeDataYesterDay() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 1);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.CLLDashboardForm.get('dateStart')?.patchValue(stDT);
    this.CLLDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      groupId: this.CLLDashboardForm.value.UserGroup1,
      PT_ID: this.CLLDashboardForm.value.UserPlant1,
      UnitId: this.CLLDashboardForm.value.unit,
      ComponentId: this.CLLDashboardForm.value.components,
      dtStartDate: startDt,
      dtEndDate: endDt,
      Status: '*'
    };
  }

  makeDataLastWeek() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 6);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.CLLDashboardForm.get('dateStart')?.patchValue(stDT);
    this.CLLDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      groupId: this.CLLDashboardForm.value.UserGroup1,
      PT_ID: this.CLLDashboardForm.value.UserPlant1,
      UnitId: this.CLLDashboardForm.value.unit,
      ComponentId: this.CLLDashboardForm.value.components,
      dtStartDate: startDt,
      dtEndDate: endDt,
      Status: '*'
    };
  }

  makeDataLastMonth() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 30);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.CLLDashboardForm.get('dateStart')?.patchValue(stDT);
    this.CLLDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      groupId: this.CLLDashboardForm.value.UserGroup1,
      PT_ID: this.CLLDashboardForm.value.UserPlant1,
      UnitId: this.CLLDashboardForm.value.unit,
      ComponentId: this.CLLDashboardForm.value.components,
      dtStartDate: startDt,
      dtEndDate: endDt,
      Status: '*'
    };

  }

  drawCILTaskChart(){
    let cilTaskLabel : any[] = new Array();
    let cilTaskData: any[] = new Array();
    this.dashboardData?.lstCILTask.forEach((element:any )=> {
      cilTaskLabel.push(element.Name);
      cilTaskData.push([element.Name,parseFloat(element.Countt)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {  
        categories: cilTaskLabel, 
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'CIL Task',
        type : 'column',
        data: cilTaskData
      }]
    };
    let chart = new Chart(this.options);
    this.chartCILTask = chart;
  };

  drawCilTypeChart(){
    let cilTypeLabel : any[] = new Array();
    let cilTypeData: any[] = new Array();
    this.dashboardData.lstCILType.forEach((element:any )=> {
      cilTypeLabel.push(element.Name);
      cilTypeData.push([element.Name,parseFloat(element.Countt)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {  
        categories: cilTypeLabel, 
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'CIL Type',
        type : 'column',
        data: cilTypeData
      }]
    };
    let chart = new Chart(this.options);
    this.chartCILType = chart;
  }

  drawCilToolChart(){
    let cilToolLabel : any[] = new Array();
    let cilToolData: any[] = new Array();
    this.dashboardData.lstCILTool.forEach((element:any )=> {
      cilToolLabel.push(element.Name);
      cilToolData.push([element.Name,parseFloat(element.Countt)]);
    });;
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {  
        categories: cilToolLabel, 
    },
      credits: {
        enabled: false
      },
      series: [{
        name: 'CIL Tool',
        type : 'column',
        data: cilToolData
      }]
    };
    let chart = new Chart(this.options);
    this.chartCILTool = chart;
  }

  /// AG Grid 
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  excelExport() {
    this.gridApi.exportDataAsExcel();
  }
  csvExport(){
    this.gridApi.exportDataAsCsv();
  }

  cellClicked(params: any): void {
    debugger;
    const col = params.colDef.field;
    if (col === 'Postimage') {
      this.image = params.data.Postimage;
      this.openPictureBeforeModal();
    }if(col === 'PreTaskImage'){
      this.image = params.data.PreTaskImage;
      this.openPictureBeforeModal();
    }
   
  }

  openPictureBeforeModal(): void {
    this.currentImagePath = this.image === ''
      ?  'assets/images/no-image.png'
      : this.imageUrl+this.image;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  imageZoomModal(imagePath: String): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

}

