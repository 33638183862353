import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { arrayToTree } from 'performant-array-to-tree';
import { UserManu } from 'src/app/models/userManu';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-o3-administration',
  templateUrl: './o3-administration.component.html',
  styleUrls: ['./o3-administration.component.scss']
})
export class O3AdministrationComponent implements OnInit {
  administrationlist!: any[];
  userMenu!: UserManu[];
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  constructor(
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private usermanagementService: UserManagementService,
    private router: Router) { }

  ngOnInit(): void {
    const list = localStorage.getItem('flatMenuItem');
    const treeData = list !== null ? JSON.parse(list) : [];

    this.administrationlist = [
      { title: 'Manufacturing Admin', routerLink: this.getManufacturingAdmin,
        icon: 'assets/images/manufacturing-admin.svg', disabled: false },
      // tslint:disable-next-line:max-line-length
      // { title: 'KPI’s Admin', routerLink: this.getKpisAdmin, icon: 'assets/images/kpi-admin.svg', disabled: this.kpiMaster.isMenuEnabled(treeData, 'KPI\'s Admin') },
      // tslint:disable-next-line:max-line-length
      { title: 'Quality Admin', routerLink: this.getQualityAdmin, icon: 'assets/images/quality-admin.svg', disabled: false },
      // tslint:disable-next-line:max-line-length
      // { title: 'General Settings', routerLink: this.getGeneralSettings , icon: 'assets/images/general-settings.svg', disabled: false },
      // tslint:disable-next-line:max-line-length
      { title: 'Master Data Upload', routerLink: this.getMasterDataUpload , icon: 'assets/images/master-data-upload.svg', disabled: false },
      // tslint:disable-next-line:max-line-length
      { title: 'User Management', routerLink: this.getUserManagement, icon: 'assets/images/kpi-management.svg', disabled: false  },
      
      { title: 'CitiesAndCountries', routerLink: this.geCitiesAndCountries, icon: 'assets/images/kpi-management.svg', disabled: false  }

    ];

    this.GetMenuAccess();
    this.GetBreadcrumList();
  }

  getManufacturingAdmin = (): void => {
    this.router.navigate(['/manufacturingadmin']);
  }
  getKpisAdmin = (): void => {
    this.router.navigate(['/kpisadmin']);
  }
  getQualityAdmin = (): void => {
    this.router.navigate(['/qualityadmin']);
  }
  getGeneralSettings = (): void => {
    this.router.navigate(['/generalsettings']);
  }
  getMasterDataUpload = (): void => {
    this.router.navigate(['/masterdataupload']);
  }
  getUserManagement = (): void => {
    this.router.navigate(['/usermanagement']);
  }
  geCitiesAndCountries = (): void => {
    this.router.navigate(['/CitiesAndCountries']);
  }

  
  //breadCrumb

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;

    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any{
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([url]));
    // return false;
  }


}
