import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
} from 'ng-apexcharts';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';

import { MeetingMatrixService } from '../../services/meeting-matrix.service';
import { PrimeCellEditorComponent } from '../../editors/prime-cell-editor.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { BtnDeleteCellRenderer } from 'src/app/btn-deletecell-renderer';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { MeetingActionService } from 'src/app/services/meeting-action.service';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Frequency, MeetingMatrixFrequency } from 'src/app/models/frequency';
import { TranslaterService } from 'src/app/services/translater.service';
import * as _ from 'lodash';
import { differenceInCalendarDays, getDate } from 'date-fns';
import { StockChart } from 'angular-highcharts';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Options } from 'highcharts';
import { Chart } from 'angular-highcharts';
import { AtStagesNcrComponent } from 'src/app/analytical-tools/at-stages-ncr/at-stages-ncr.component';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OverallActionPlanService } from 'src/app/services/overall-actionPlan.service';
import { CommonService } from 'src/app/services/common.service';
import { ImageDialogComponent } from 'src/app/analytical-tools/image-dialog/image-dialog.component';
import { environment } from 'src/environments/environment';
import { DatepickerService } from 'src/app/services/Datepicker.service';



export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};


interface Meeting {
  meetingId: number;
  leaderId: number;
  meetingName: string;
  meetingMasterId: number;
  kpiName: string;
  frequencyId: number;
  frequencyName: string;
  type: string;
}
@Component({
  selector: 'app-meeting-matrix',
  templateUrl: './meeting-matrix.component.html',
  styleUrls: ['./meeting-matrix.component.scss'],
})
export class MeetingMatrixComponent implements OnInit {
  blobStorageUrl = environment.O3CoreApiImageUrl;
  highChartsColor = [
    '#00008B',
    '#34A56F',
    '#F80000',
    '#2D0A77',
    '#8C001A',
    '#FF69B4',
    '#FA4A08',
    '#5453A6',
    '#8B008B',
    '#3090C7',
    '#F1C40F',
    '#CB4335',
    '#36454F',
    '#A569BD',
    '#FFA500',
    '#179fcc',
    '#093323',
    '#08fa03',
    '#048b8e',
    '#9637A4',
    '#F39C12',
    '#0b774e',
    '#F5B7B1',
    '#0d98da',
    '#512E5F',
    '#C471A5',
    '#0034bb',
    '#06573d',
    '#0d6e54',
    '#0692aa',
    '#611745',
    '#F80000',
    '#FFDF00',
    '#6D81A8',
    '#08FAC7',
    '#D208FA',
    '#FAEB08',
  ];
  @ViewChild('displayUserModal') private displayUserModal!: TemplateRef<any>;
  @ViewChild('agendasViewSheet') private viewAgendasSheet!: TemplateRef<any>;
  @ViewChild('addActionModal') private addActionModal!: TemplateRef<any>;
  @ViewChild('displayStockChartModal')
  private stockChartModel!: TemplateRef<any>;
  @ViewChild('suggestedActionModal')
  private suggestedActionModal!: TemplateRef<any>;
  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;
  @ViewChild('analysisChart') private analysisChart!: TemplateRef<any>;
  @ViewChild('visibleParameterComparison')
  private visibleParameterComparison!: TemplateRef<any>;
  @ViewChild('suggestedPaginator', { static: true })
  suggestedPaginator!: MatPaginator;
  @ViewChildren('inputRef') inputAgendRefs!: QueryList<ElementRef>;
  @ViewChild('addActionPlanModal') private addActionPlanModal!: TemplateRef<any>;
  @ViewChild('fileInputAdd') fileInputAdd!: ElementRef;
  @ViewChild('imageInputAdd') imageInputAdd!: ElementRef;
  @ViewChild('updateActionModal') private updateActionModal!: TemplateRef<any>;
  @ViewChild('fileInputFile') fileInputFile!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;
  public filteredBanksMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public bankMultiFilterCtrl: FormControl = new FormControl();
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  isExpanded: boolean = false;
  public kpiId: any;
  chartType: boolean = false; // false column true line
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  mxDate = new Date();
  mnDate = new Date();
  ButtonType!: any;
  public stepHour = 1;
  public stepMinute = 1;
  search = '';
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  inputFormControl = new FormControl({ value: null, disabled: true });
  MeetingMatrixForm!: FormGroup;
  MatrixForm!: FormGroup;
  ManualParticepantForm!: FormGroup;
  meetingSuggestedActionForm!: FormGroup;
  groupListDDL!: any[];
  plants!: any;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  columnDefs: any;
  rowData: any;
  pausebutton = false;
  isSaur = false;
  loadingOverlayComponent: any;
  range = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    FGapfromDate: new FormControl(),
    FGaptoDate: new FormControl(),
  });

  rangeFinacialGap = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });
  rowSelection = 'multiple';
  columnDefsSuggested: any;
  frameworkComponents: any;
  defaultColDef: {
    sortable: true;
    floatingFilter: true;
    filter: 'agMultiColumnFilter';
    headerHeight: 50;
    floatingFilterComponentParams: {
      suppressFilterButton: false;
    };
    filterParams: { newRowsAction: 'keep' };
  };
  scoreCardGridOptions: any = {
    defaultColDef: {
      sortable: true,
      floatingFilter: true,
      filter: 'agMultiColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    context: {
      thisComponent: this,
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRenderer: BtnDeleteCellRenderer,
      customLoadingOverlay: CustomLoadingOverlay,
    },
  };
  gridOptionsActions: any = {
    defaultColDef: {
      sortable: true,
      floatingFilter: false,
      filter: 'agMultiColumnFilter',
      // headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
  };
  columnDefs1: any;
  frequencies!: MeetingMatrixFrequency[];
  rowData1: any;
  treeLevelList: any;
  level2 = false;
  hide = false;
  hide1 = false;
  isShow = false;
  isShowreverse = true;
  SideModelVisible = false;
  date!: Date;
  UserDDL: any;
  filteredUsers: any;
  gridOptions1: any = {
    defaultColDef: {
      floatingFilter: true,
      sortable: true,
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
    },
  };
  gridApi: any;
  gridColumnApi: any;
  startYear!: Date;
  endYear!: Date;
  gridApiEdit: any;
  selectmeeting!: any;
  gridColumnApiEdit: any;
  meetingOriginalArray: any[] = [];
  meetingsArray: any[] = [];
  selectedMeeting: any = {
    meetingId: 0,
    meetingName: '',
    meetingType: '',
  };

  cellData: any;
  timer = '00:00:00';
  interval: any;
  meetingStartTime: any;
  meetingEndTime: any;
  meetingStarted = false;
  meetingStoped = false;
  minutes = 0;
  hours = 0;
  seconds = 0;
  meetingParticipants: any[] = [];
  selectedAll = false;
  chartOptions!: ChartOptions;
  meetingEvalutionColumns: string[] = ['Date', 'Score', 'Target', 'Level'];
  meetingEvaluationData!: MatTableDataSource<any>;
  meetingAgendas!: any[];
  isKpiAddToAction = true;
  meetingSuggestedActions!: any;
  toastrService: any;
  meetingActionId: any;
  meetingDefId: any;
  meetingDefId2: any;
  AddAgendaForm!: FormGroup;
  MeetingMatrixFrequencyForm!: FormGroup;
  agendaFormArray!: FormArray;
  filteredParticipants: any;
  meetingAgendasArray!: any[];
  protected _onDestroy = new Subject<void>();
  Accountable: FormControl = new FormControl();
  shiftData: any[] = [];
  filtereMeetings!: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  sort = 'asc';
  stock: any;
  pageId!: any;
  rowDataCopy: any = [];
  enableGlidePath: boolean = false;
  scoreCardGridApi: any;
  scoreCardGridColumnApi: any;
  hasGlidePath: false;
  ButtonTypeedit: any;
  formMode: boolean;
  financialGapList: any;

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Analysis',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptions1: MatProgressButtonOptions = {
    active: false,
    text: 'Comparison',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  options: Options;
  chartArray: any[] = [];
  KPIAnalysisForm: FormGroup;
  chartData: any[] = [];
  chartArrayForAnalysis: any[] = [];
  options2: Options;
  KPIComparisonForm: FormGroup;
  KPIName: any;
  meetingTitle: any;
  nameKpi: any;
  currentKPIId: any;
  sameUOM: any[] = [];
  differentUOM: any[] = [];
  checkedUOM: boolean = false;
  uniqueUoms: any[] = [];
  individualUom: any;
  selectedUom: any;
  compData: any[] = [];
  addActionForm!: FormGroup;
  imageAddActionPlan: any;
  fileAddActionPlan: any;
  addImageSrc: any;
  addFileSrc: any;
  disableVerification: boolean;
  targetDate: any;
  RevisedDate: any;
  userList: any = [];
  participantsByPlants: any = [];
  overallMeetingActions: any[];
  columnDefsallAction: any;
  editActionPlanForm!: FormGroup;
  imageFile: any;
  data: any;
  ImageSave: any;
  fileSave: any;
  imageSrc: string;
  Status: any;
  ModuleName: any;
  UniqueCode: any;
  disableNewAssigne: boolean;
  closefile: any;
  showinput: boolean;
  firstDayOfWeek: any;
  currentDialog: any;
  currentNodeDate: any;
  currentAssigneeEmail: any;
  currentResponsibleEmail: any;
  doc: string;
  images: any[] = [];
  fileType: string;
  displayMaximizable: boolean;
  fileDesc: any;
  decimalPlaces: number = 2;
  ScoreCardData: any;
  gridApiActions: any;
  gridColumnApiActions: any;
  actionSelectedRow: any;

  constructor(
    private kpiservice: KpitreeService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private meetingService: MeetingMatrixService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private kpiMaster: KpimasterService,
    private meetingaction: MeetingActionService,
    private dialog: MatDialog,
    private analyticalToolsService: AnalyticalToolsService,
    public datePipe: DatePipe,
    public translater: TranslaterService,
    private spinner: NgxSpinnerService,
    private actionplanService: OverallActionPlanService,
    private commonService: CommonService,
    private datepickerService: DatepickerService,
  ) {
    // this.i18n.setLocale(MONDAY_LOCALE);
    this.loadingOverlayComponent = 'customLoadingOverlay';
    this.columnDefsallAction = [
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        resizable: true,
        filter: true,
        cellRenderer: (params: any): any => {
          if (params.data.Status !== 'Closed') {
            return `
            <div>
            <button mat-mini-fab="" 
            color="primary"
            ptooltip="Action Delete" 
            title="Edit Action" 
            tooltipposition="top" 
            class="viewRCA mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
             </div>
          `;
          }
        },
      },
      {
        field: 'RootCause',
        headerName: 'Root Cause',
        resizable: true,
        filter: true,
      },
      {
        field: 'Action',
        headerName: 'Meeting Action',
        resizable: true,
        width: 150,
        filter: true,
      },
      {
        field: 'KPIName',
        headerName: 'KPI Name',
        resizable: true,
        filter: true,
      },
      {
        field: 'Status',
        headerName: 'Status',
        resizable: true,
        filter: true,
        width: 100,
        cellStyle: function (params: any): any {
          if (params.value == 'Open')
            return { color: 'white', backgroundColor: 'gray' };
          else if (params.value == 'InProgress')
            return { color: 'white', backgroundColor: 'orange' };
          else if (params.value == 'Complete')
            return { color: 'white', backgroundColor: '#1cd51c' };
          else if (params.value == 'Closed')
            return { color: 'white', backgroundColor: 'green' };
          else if (params.value == 'Delayed')
            return { color: 'white', backgroundColor: 'brown' };
          else if (params.value == 'Rejected')
            return { color: 'white', backgroundColor: 'red' };
        },
      },
      {
        field: 'CreatedAt',
        headerName: 'Assigned Date',
        resizable: true,
        filter: true,
        valueFormatter: function (params) {
          return params.value
            ? moment(params.value).format('DD-MM-YYYY HH:mm:ss')
            : params.value;
        },
        width: 150,
      },
      {
        field: 'AssingedBy',
        headerName: 'Assigned To',
        resizable: true,
        filter: true,
        width: 150,
      },
      {
        field: 'TargetDate',
        headerName: 'Target Date',
        resizable: true,
        filter: true,
        valueFormatter: function (params) {
          return params.value
            ? moment(params.value).format('DD-MM-YYYY')
            : params.value;
        },
        width: 150,
      },
      {
        field: 'NoOfMisses',
        headerName: 'No of Misses',
        resizable: true,
        filter: true,
      },
      {
        field: 'RevisedDate',
        headerName: 'New Target Date',
        resizable: true,
        filter: true,
        valueFormatter: function (params) {
          return params.value
            ? moment(params.value).format('DD-MM-YYYY')
            : params.value;
        },
        width: 150,
      },
      {
        field: 'ActionCompleted',
        headerName: 'Completed',
        resizable: true,
        filter: true,
        width: 130,
      },
      {
        field: 'ClosedFile',
        headerName: 'Closed File',
        sortable: true,
        resizable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = '';
          if (params && params.value) {
            html = `<button mat-mini-fab=""
             color="primary" 
             ptooltip="View document" 
             title="View document" 
             tooltipposition="top" 
             class="viewDocument mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="viewDocument mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon></span><span matripple="" class="viewDocument mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            `;
          }
          return html;
        },
      },
      {
        field: 'Remarks',
        headerName: 'Remarks',
        resizable: true,
      },
    ];

  }
  cellClicked(params: any, showButton: boolean): void {
    const col = params.colDef.field;
    this.meetingActionId = params.data.meetingActionId;
    this.kpiId = params.data.KPIId;
    this.KPIName = params.data['KPI Name'];
    this.formMode = false;
    this.ButtonType = '';
    this.meetingActionId = params.data.meetingActionId;

    if (col === 'xyz') {
      this.ButtonType = params.event.target.classList[1];

      if (this.ButtonType === 'fa-edit') {
        this.ButtonType = '';
        this.formMode = true;

        this.MatrixForm.get('meetingDefName').setValue(
          params.data?.meetingDefName
        );
        this.MatrixForm.get('action').setValue(params.data?.action);
        this.MatrixForm.get('responsible').setValue(
          parseInt(params.data?.responsibleId)
        );
        // this.MatrixForm.get('targetdate').setValue("2022-09-23")
        const str = new Date(params.data?.targetdate);
        // const [day, month, year] = str.split('/');
        // const data = new Date(+year, +month - 1, +day);
        // let latest_date =this.datepipe.transform(date, 'yyyy-MM-dd');
        //let latest_date = new Date(date);
        this.MatrixForm.get('targetdate').setValue(str);
        this.MatrixForm.valid === true;
        this.MatrixForm.controls['targetdate'].disable();
      }
      if (this.ButtonType === 'fa-trash') {
        this.ButtonType = '';
        this.deleteMeetingAction();
      }
    }
  }
  closeModel(): void {
    this.MatrixForm.get('action')?.setValue('');
    this.MatrixForm.get('responsible')?.setValue('');
    this.MatrixForm.get('targetdate')?.setValue('');
    this.MatrixForm.get('meetingDefName')?.setValue('');
    this.MatrixForm.controls['targetdate'].enable();
    this.formMode = false;
  }
  deleteMeetingAction(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          meetingActionId: this.meetingActionId,
        };
        this.meetingaction.deleteMeetingAction(key).subscribe(
          (res: any) => {
            Swal.fire(
              'Deleted!',
              'Your selected Meeting action has been deleted.',
              'success'
            );
            const key = {
              meetingDefId: this.selectedMeeting.meetingId,
            };
            this.meetingService.getMeetingAction(key).subscribe((data) => {
              for (var d of data) {
                if (d.newtargetdate != null && d.newtargetDate != '') {
                  d.newtargetdate = moment(d.newtargetdate).format(
                    'DD/MM/YYYY HH:mm:ss'
                  );
                }
                if (d.completiondate != null && d.completiondate != '') {
                  d.completiondate = moment(d.completiondate).format(
                    'DD/MM/YYYY HH:mm:ss'
                  );
                }
              }

              this.rowData1 = data;
              this.MatrixForm.get('meetingDefName')?.setValue(
                this.selectedMeeting.meetingName
              );
              this.MatrixForm.get('meetingDefId')?.setValue(
                this.selectedMeeting.meetingId
              );
            });
          },
          (error: any) => {
            this.toastrService.success('Error', error.message);
          }
        );
      }
    });
  }
  ngOnInit(): void {
    this.getGroupData();
    this.GetBreadcrumList();
    this.initialdisplay();
    this.GetMenuAccess();
    this.createEditActionPlanForm();
    this.ManualParticepantForm = this.fb.group({
      participant: [[], Validators.required],
    });
    this.MeetingMatrixFrequencyForm = this.fb.group({
      frequency: ['', Validators.required],
    });
    this.MeetingMatrixForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      search: [''],
    });
    this.MatrixForm = this.fb.group({
      meetingDefId: ['', Validators.required],
      meetingDefName: ['', Validators.required],
      action: [''],
      kpi: [''],
      responsible: ['', Validators.required],
      // Accountable: ['', Validators.required],
      targetdate: ['', Validators.required],
    });

    // this.AddAgendaForm = this.fb.group({
    //   addagendas: this.fb.array([this.createAgendaForm()]),
    // });
    this.AddAgendaForm = this.fb.group({
      agenda: ['', Validators.required],
    });
    this.agendaFormArray = this.AddAgendaForm.get('addagendas') as FormArray;

    this.meetingSuggestedActionForm = this.fb.group({
      action: ['', Validators.required],
      completionDate: ['', Validators.required],
      responsibleId: ['', Validators.required],
    });

    const today = new Date().getFullYear();
    this.startYear = new Date(today, 0);
    this.endYear = new Date(today, 11, 31);
    var date = new Date();
    var firstDayfincialGap = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7
    );
    var lastDayfincialGap = new Date();
    this.rangeFinacialGap.get('fromDate').setValue(firstDayfincialGap);
    this.rangeFinacialGap.get('toDate').setValue(lastDayfincialGap);
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 2, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.range.get('fromDate').setValue(firstDayfincialGap);
    this.range.get('toDate').setValue(lastDayfincialGap);

    this.KPIAnalysisForm = this.fb.group({
      fromDate: [firstDay, Validators.required],
      toDate: [lastDay, Validators.required],
    });

    this.KPIComparisonForm = this.fb.group({
      fromDate: [firstDay, Validators.required],
      toDate: [lastDay, Validators.required],
    });

    // this.getKPIAnalysisFormChanges();
    //this.getKPIComparisonFormChanges();

    this.range.valueChanges.subscribe((res) => {
      if (res.fromDate && res.toDate) {
        // this.getFinancialGapList(res.fromDate, res.toDate);
        // this.getGridDataByFrequency();
      }
    });
    this.rangeFinacialGap.valueChanges.subscribe((res) => {
      if (res.fromDate && res.toDate) {
        // this.getFinancialGapList(res.fromDate, res.toDate);
        // this.getGridDataByFrequency();x
      }
    });
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      // colors: [this.getRandomColor(), this.getRandomColor()],
      colors: [
        '#00008B',
        '#34A56F',
        '#F80000',
        '#2D0A77',
        '#8C001A',
        '#FF69B4',
        '#FA4A08',
        '#5453A6',
        '#8B008B',
        '#3090C7',
        '#F1C40F',
        '#CB4335',
        '#36454F',
        '#A569BD',
        '#FFA500',
        '#179fcc',
        '#093323',
        '#08fa03',
        '#048b8e',
      ],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        text: 'Evaluation',
        align: 'left',
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: [],
        title: {
          text: 'Meeting Evalutions',
        },
      },
      yaxis: {
        title: {
          text: 'Total',
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    };
    this.filteredBanksMulti.next(this.filteredParticipants);
    this.filterBanksMulti();
    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
      });

    this.createAddActionPlanForm();
  }

  getKPIAnalysisFormChanges() {
    this.KPIAnalysisForm.valueChanges.subscribe((res) => {
      if (res.fromDate && res.toDate) {
        this.onDateChangeInKPIAnaModal();
      }
    });
  }
  getKPIComparisonFormChanges() {
    this.KPIComparisonForm.valueChanges.subscribe((res) => {
      if (res.fromDate && res.toDate) {
        this.onDateChangeInKPICompModal();
      }
    });
  }

  dateChangeFromModelComp(obj) {
    if (obj.target._model.selection.start && obj.target._model.selection.end) {
      this.KPIComparisonForm.get('fromDate').setValue(obj.target._model.selection.start);
      this.KPIComparisonForm.get('toDate').setValue(obj.target._model.selection.end);
      // this.onClickParamterComparsion(true);
      this.comparisonMainData();
    }
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1,
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  getRandomColor() {
    // var letters = '0123456789ABCDEF'.split('');
    // var color = '#';
    // for (var i = 0; i < 6; i++ ) {
    //     color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;
    // var letters = '0123456789ABCDEF';
    // var color = '#';
    // for (var i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random()*16)];
    // }
    // return color;

    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    //document.body.style.backgroundColor = "#" + randomColor;
    var color = '#' + randomColor;
    return color;
  }

  getRandomHexColor(): string {
    const max = 0xffffff;
    const rand = Math.floor(Math.random() * max).toString(16);
    const zeros = Array.from({ length: 6 - rand.length }, () => 0).join('');
    return '#' + zeros + rand;
  }

  get repeatAgendaForm(): any {
    return this.AddAgendaForm.get('addagendas') as FormArray;
  }

  getMasterShift(): void {
    const key = {
      plantId: this.MeetingMatrixForm.get('plant')?.value,
    };
    this.meetingService.getMasterShifts(key).subscribe(
      (data) => {
        this.shiftData = data;
        // this.shiftData.forEach((c=>))
        this.shiftData.forEach((element) => {
          element.startTime = new Date().setTime(element.startTime);
          element.endTime = new Date().setTime(element.endTime);
        });
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  createAgendaForm(): FormGroup {
    return this.fb.group({
      agenda: ['', Validators.required],
    });
  }

  // addNewAgendaRow(event: any, index: number): void {
  //   
  //   const arrayagendas = this.AddAgendaForm.get('addagendas')?.value.filter(
  //     (x: any) => x.agenda === ''
  //   );
  //   const agenda = this.AddAgendaForm.get('addagendas')?.value;
  //   for (const value of agenda) {
  //     const tempAgenda = this.meetingAgendasArray.filter((meet: any) => value.agenda === meet.agenda);
  //     if (tempAgenda.length === 0) {this.meetingAgendasArray.push({agenda: value.agenda}); }
  //   }
  //   if (arrayagendas?.length === 0) {
  //     this.agendaFormArray.push(this.createAgendaForm());
  //     setTimeout(() => {
  //       const lastinput: HTMLInputElement = this.inputAgendRefs.last
  //         .nativeElement.children[0].children[0].children[0].children[0]
  //         .children[0] as HTMLInputElement;
  //       lastinput.focus();
  //     }, 300);
  //   } else {
  //     this.toaster.warning('Please Fill Or Remove Empty Row First');
  //   }
  // }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.meetingService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.MeetingMatrixForm.get('group')?.setValue(
          this.groupListDDL[0].TypeId
        );
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.MeetingMatrixForm.get('group')?.value,
    };
    this.meetingService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.MeetingMatrixForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.onPlantChange();
        this.getMasterShift();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  setFirstWeek(): void {
    const dept = this.plants.find(item => item.DeptId === this.MeetingMatrixForm.value.plant);
    this.firstDayOfWeek = dept ? dept.firstDayOfWeek : 0;
    this.setWeekValue(this.firstDayOfWeek);
  }

  onPlantChange() {
    this.setFirstWeek();
    this.getParticipantsOnPlant();
    this.getFrequencyOnMeeting();
  }

  onDateChangeInKPIAnaModal() {
    this.chartType = false;
    const selectedRows = this.scoreCardGridApi.getSelectedRows();
    const KPIs = selectedRows.map((row) => row.KPICode);
    //const KPIs = selectedRows.map((row) => row.KPIId);
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name']
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'] },
      ];
    }, []);

    const key = {
      frequencyId: this.MeetingMatrixFrequencyForm.value.frequency.frequencyId,
      kpiId: KPIs.join(','),
      frequencyName:
        this.MeetingMatrixFrequencyForm.value.frequency.frequencyName,
      plantId: this.MeetingMatrixForm.get('plant')?.value,
      // date: latestdate,
      // fromDate: moment(this.KPIAnalysisForm.value.fromDate).format(
      //   'YYYY-MM-DD HH:mm:ss'
      // ),
      // toDate: moment(this.KPIAnalysisForm.value.toDate).format(
      //   'YYYY-MM-DD HH:mm:ss'
      // ),
      fromDate: this.KPIAnalysisForm.value.fromDate,
      toDate: this.KPIAnalysisForm.value.toDate,
      meetingDefId: this.selectedMeeting.meetingId,
    };

    this.meetingService.getScoreCardMeetingMatrix(key).subscribe(
      (data: any) => {
        this.chartArray = [];
        this.chartArrayForAnalysis = [];
        let columns = [
          ...data.reduce(
            (s, o) => (Object.keys(o).forEach((k) => s.add(k)), s),
            new Set()
          ),
        ];
        columns = columns.filter(
          (c) =>
            c !== 'Trend' &&
            c !== 'Type' &&
            c !== 'Indicator' &&
            c !== 'Responsible' &&
            c !== 'Last Month' &&
            c !== 'KPICode' &&
            c !== 'MTD' &&
            c !== 'YTD' &&
            c !== 'KPIId' &&
            c !== 'meetingDefId' &&
            c !== 'meetingDefName' &&
            c !== 'Line' &&
            c !== 'UoM' &&
            c !== 'KPI Name' &&
            c !== 'Owner' &&
            c !== 'FinancialGap' &&
            c !== 'Target Date' &&
            c !== 'Unit' &&
            c !== 'Previous' &&
            c !== 'Department Name'
        );
        distinctRows.forEach((element, ind) => {
          let obj = [];


          const analysisDataTarget = [];
          const analysisData = [];
          const analysisLabel = [];
          const title = element['KPI Name'];
          const chartObj = { id: '', chartOption: {} };
          const chartObj2 = { id: '', chartOption: {} };

          for (const col of columns) {
            data.forEach((rowNode, index) => {
              // console.log('col', col);
              if (
                rowNode.KPICode === element.KPICode &&
                rowNode.Type === 'Glidepath'
              ) {
                // const kpi = this.scoreCardGridApi.getRowNode(rowNode.id);
                const value = rowNode[col];
                analysisLabel.push(col);
                analysisDataTarget.push(parseFloat(value ? value : 0));
              }
              if (
                rowNode.KPICode === element.KPICode &&
                rowNode.Type === 'Actual'
              ) {
                if (analysisLabel.indexOf(col) !== -1) {
                } else {
                  // var objectPropertyName = col;
                  analysisLabel.push(col);
                }
                const value = rowNode[col];

                analysisData.push(parseFloat(value ? value : 0));
              }
            });
          }
          this.options = {
            title: {
              text: title,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              categories: analysisLabel,
            },
            yAxis: {},
            tooltip: {
              valueDecimals: 2,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                    return this.y;
                  },
                },
              },
            },
            series: [
              {
                type: 'column',
                name: this.langObj ? this.langObj?.Target : 'Target',
                data: analysisDataTarget,
                color: '#10ce9c',
              },
              {
                type: 'column',
                name: this.langObj ? this.langObj?.Actual : 'Actual',
                data: analysisData,
                color: 'blue',
              },
            ],
          };

          this.options2 = {
            title: {
              text: title,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              categories: analysisLabel,
            },
            yAxis: {},
            tooltip: {
              valueDecimals: 2,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                    return this.y;
                  },
                },
              },
            },
            series: [
              {
                type: 'line',
                name: this.langObj ? this.langObj?.Target : 'Target',
                data: analysisDataTarget,
                color: '#10ce9c',
              },
              {
                type: 'line',
                name: this.langObj ? this.langObj?.Actual : 'Actual',
                data: analysisData,
                color: 'blue',
              },
            ],
          };
          let chart = new Chart(this.options);
          let chart2 = new Chart(this.options2);
          chartObj.chartOption = chart;
          chartObj2.chartOption = chart2;
          this.chartArray.push(chartObj);
          this.chartArrayForAnalysis.push(chartObj2);
        });
      },
      (error: any) => {
        this.toastrService.error(error.message);
      }
    );
  }

  onDateChangeInKPICompModal() {
    this.onClickParamterComparsion(true);
  }

  getFrequency(): void {
    this.scoreCardGridApi.showLoadingOverlay();
    const key = {
      meetingDefId: this.meetingDefId2,
    };
    this.meetingService.getFrequency(key).subscribe((res) => {
      this.frequencies = res;
      const freqObj = this.frequencies[0];
      this.MeetingMatrixFrequencyForm.get('frequency')?.setValue(freqObj);
      this.date = new Date();
      // const latestdate = this.date;
      const latestdate = new Date(this.range.value.toDate).setHours(
        23,
        59,
        59,
        0
      );
      const key = {
        frequencyId:
          this.MeetingMatrixFrequencyForm.value.frequency.frequencyId,
        kpiId: this.MeetingMatrixFrequencyForm.value.frequency.kpiId,
        frequencyName:
          this.MeetingMatrixFrequencyForm.value.frequency.frequencyName,
        plantId: this.MeetingMatrixForm.get('plant')?.value,
        // date: latestdate,
        // fromDate: moment(this.range.value.fromDate).format(
        //   'YYYY-MM-DD HH:mm:ss'
        // ),
        // toDate: moment(latestdate).format('YYYY-MM-DD HH:mm:ss'),
        fromDate: this.range.value.fromDate,
        toDate: new Date(latestdate),
        meetingDefId: this.selectedMeeting.meetingId,
      };
      this.scoreCardGridApi.showLoadingOverlay();
      this.meetingService.getScoreCardMeetingMatrix(key).subscribe(
        (data: any) => {
          this.ScoreCardData = data;
          this.createScoreCardData(data);
        },
        (error: any) => {
          this.toastrService.error(error.message);
        }
      );
      //
    });
  }
  isDecimal(n) {
    var result = n - Math.floor(n) !== 0;

    if (result) return true;
    else return false;
  }
  createScoreCardData(res:any) {
    const temp = JSON.parse(JSON.stringify(res)); 

     
    if (!temp || temp.length === 0) {
      this.scoreCardGridApi.hideOverlay();
      this.scoreCardGridApi.setRowData([]);
      this.onScoreCardGridFirstDataRendered();
      this.hasGlidePath = false;

      return;
    }
    let glidePath = temp.filter((element) => {
      return element.Type === 'Glidepath';
    });
    let actual = temp.filter((element) => {
      return element.Type === 'Actual';
    });

    glidePath = _.sortBy(glidePath, ['KPIId']);
    actual = _.sortBy(actual, ['KPIId']);

    Object.entries(temp).forEach((score: any) => {
      Object.keys(score[1]).map((key) => {
        if (
          key !== 'Type' &&
          key !== 'Indicator' &&
          key !== 'Trend' &&
          key !== 'Department Name' &&
          key !== 'Owner' &&
          key !== 'Responsible' &&
          key !== 'Last Month' &&
          key !== 'KPICode' &&
          key !== 'Previous' &&
          key !== 'MTD' &&
          key !== 'YTD' &&
          key !== 'KPIId' &&
          key !== 'meetingDefId' &&
          key !== 'meetingDefName' &&
          key !== 'Line' &&
          key !== 'Unit' &&
          key !== 'UoM' &&
          key !== 'KPI Name' &&
          key !== 'KPI Code' &&
          key !== 'Line' &&
          key !== 'FinancialGap' &&
          key !== 'Target Date'
        ) {
          if (score[1][key] !== undefined && score[1][key] !== null) {
            if (!isNaN(score[1][key]) && this.isDecimal(score[1][key])) {
              score[1][key] = Number(score[1][key]).toFixed(this.decimalPlaces);
            }

            score[1][key] = `${Number(score[1][key]).toFixed(this.decimalPlaces)} (${score[1].UoM})`;
          }
        } else {
          return key;
        }
      });
    });

    this.rowData = temp;
    this.scoreCardGridApi.showLoadingOverlay();
    this.scoreCardGridApi.setRowData(temp);

    if (this.rowData) {
      this.rowData.forEach((element) => {
        delete element?.Owner;
        delete element?.Responsible;
        // Object.keys(element).forEach((key) => {
        //   if (
        //     key !== 'Type' &&
        //     key !== 'Indicator' &&
        //     key !== 'Responsible' &&
        //     key !== 'Last Month' &&
        //     key !== 'KPICode' &&
        //     key !== 'MTD' &&
        //     key !== 'YTD' &&
        //     key !== 'KPIId' &&
        //     key !== 'meetingDefId' &&
        //     key !== 'meetingDefName' &&
        //     key !== 'Line' &&
        //     key !== 'UoM' &&
        //     key !== 'KPI Name' &&
        //     key !== 'Owner' &&
        //     key !== 'FinancialGap' &&
        //     key !== 'Target Date' &&
        //     key !== 'Trend' &&
        //     key !== 'Department Name' &&
        //     key !== 'Previous' &&
        //     key !== 'Unit'
        //   ) {
        //     if (element[key] && !isNaN(element[key])) {
        //       if (this.isDecimal(element[key])) {
        //         element[key] = element[key].toFixed(2);
        //       }
        //     }
        //   }
        // });
      });

      this.rowDataCopy = this.rowData;

      this.columnDefs = this.generateColumns(this.rowData);
      const colorElements = document.getElementsByClassName(
        'ag-body-viewport'
      ) as HTMLCollectionOf<HTMLElement>;
      colorElements[0].style.height = '25vw';
      colorElements[0].style.overflowY = 'scroll';
      setTimeout(() => {
        //reset column state

        if (this.rowData.length) {
          this.headerBreaker();
          this.scoreCardGridColumnApi.resetColumnState();
          this.autoSizeAll();
        }
      }, 1000);

      this.hasGlidePath = this.rowData.some((row) => row.Type === 'Glidepath');

      this.scoreCardGridApi.hideOverlay();

    }
    this.onScoreCardGridFirstDataRendered();
    this.scoreCardGridApi.hideOverlay();
  }
  getFrequencyOnMeeting(): void {
    const key = {
      plantId: this.MeetingMatrixForm.get('plant')?.value,
    };
    this.meetingService.getMeetingByFrequency(key).subscribe((data: any) => {
      this.meetingOriginalArray = data;
      const frequencies = [
        ...new Set(data.map((c: { frequencyName: any }) => c.frequencyName)),
      ];
      const formattedMeetingsArray: any = [];
      frequencies.forEach((freq) => {
        const obj = {
          frequencyName: freq,
          meetings: data
            ?.filter((x: any) => x.frequencyName === freq)
            .map((item: any) => {
              return {
                meetingId: item?.meetingId,
                meetingName: item?.meetingName,
                kpiName: item?.kpiName,
                frequencyId: item?.frequencyId,
                frequencyName: item?.frequencyName,
                meetingMasterId: item?.meetingMasterId,
                meetingType: item?.type,
                leaderId: item?.leaderId,
              };
            }),
        };
        formattedMeetingsArray.push(obj);
      });
      this.meetingsArray = formattedMeetingsArray;
      this.filtereMeetings = this.meetingsArray;
    });
    this.search = '';
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;

        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        this.columnDefs1 = [
          {
            field: 'xyz',
            sortable: true,
            filter: true,
            headerName: 'Actions',
            hide: !this.IsWrite,
            //  cellRenderer: 'btnCellRendererNCR',
            cellRenderer: (params: any): any => {
              this.meetingActionId = params.data.meetingActionId;

              if (params.data.Status === 'open') {
                return `
            <div>
           
             <button mat-mini-fab="" color="primary" ptooltip="Edit" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary"  ng-reflect-color="info" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-edit" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
           
            <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
           
           </div>
           
           `;
              }
            },
            cellRendererParams: {
              clicked(field: any) { },
            },
            maxWidth: 100,
            minWidth: 80,
            cellStyle: { textAlign: 'center' },
          },
          {
            field: 'action',
            headerName: 'Action',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'responsible',
            headerName: 'Responsible',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          // {
          //   field: 'accountable',
          //   headerName: 'Accountable',
          //   sortable: true,
          //   resizable: true,
          //   wrapText: true,
          // },
          {
            field: 'targetdate',
            headerName: 'Target Date',
            editable: true,
            sortable: true,
            resizable: true,
            wrapText: true,
            cellRenderer: (data: any) => {
              return data.value
                ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
                : '';
            },
          },
          {
            field: 'newtargetdate',
            headerName: 'New Target Date',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: 'agDateColumnFilter',
            cellEditor: 'primeCellEditor',
            editable: this.IsWrite
              ? (params: any) => params.data.Status !== 'Closed'
              : false,
            filterParams: {
              comparator: myDateComparator,
              calendarOptions: {
                dateFormat: 'dd/mm/yy', // Set the date format
                showButtonBar: true, // Show the button bar
                yearRange: '2000:2030', // Set the year range
                monthNavigator: true, // Show the month navigator
                locale: {
                  // Set the locale
                  firstDayOfWeek: 1,
                  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                  dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                  monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                }
              }
            },
            cellStyle: {
              color: 'black',
              'background-color': '#EDF1E5',
            },
          },
          {
            field: 'misses',
            headerName: '# Misses',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'completiondate',
            headerName: 'Completion Date',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: 'agDateColumnFilter',
            cellEditor: 'primeCellEditor',
            editable: this.IsWrite
              ? (params: any) => params.data.Status !== 'Closed'
              : false,
            filterParams: {
              comparator: myDateComparator,
              calendarOptions: {
                dateFormat: 'dd/mm/yy', // Set the date format
                showButtonBar: true, // Show the button bar
                yearRange: '2000:2030', // Set the year range
                monthNavigator: true, // Show the month navigator
                locale: {
                  // Set the locale
                  firstDayOfWeek: 1,
                  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                  dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                  monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                }
              }
            },
            cellStyle: {
              color: 'black',
              'background-color': '#EDF1E5',
            },
          },
          {
            field: 'Status',
            sortable: true,
            filter: true,
            resizable: true,
            wrapText: true,
            editable: this.IsWrite
              ? (params: any) => params.data.Status !== 'Closed'
              : false,
            cellRenderer: 'agCellRenderer',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              cellRenderer: 'genderCellRenderer',
              values: ['Closed', 'In Progress'],
            },
            cellStyle: (params: { value: string }) => {
              if (params.value === 'Closed') {
                return {
                  color: 'black',
                  backgroundColor: '#38cf36',
                  'font-weight': 'bold',
                };
              } else if (params.value === 'In Progress') {
                return {
                  color: 'black',
                  backgroundColor: 'yellow',
                  'font-weight': 'bold',
                };
              } else if (params.value === 'Delayed') {
                return {
                  color: 'black',
                  backgroundColor: '#eb3d3d',
                  'font-weight': 'bold',
                };
              } else {
                return {
                  color: 'black',
                  backgroundColor: '#11ced1',
                  'font-weight': 'bold',
                };
              }
            },
          },
        ];
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  openSideModal(): void {
    this.SideModelVisible = true;
  }
  saveMeetingAction(): void {
    var meetingActionId = this.meetingActionId;
    // if (this.meetingStarted === true) {
    const MeetingMatrix = {
      meetingDefId: this.MatrixForm.value.meetingDefId,
      meetingActionId,
      action: this.MatrixForm.value.action,
      responsible: this.MatrixForm.value.responsible,
      targetdate: moment(this.MatrixForm.value.targetdate).format(
        'YYYY-MM-DD'
      ),
      status: 'open',
      kpi: this.isKpiAddToAction ? this.kpiId : null,

      // Accountable: this.MatrixForm.value.Accountable,
    };
    if (this.formMode === true) {
      this.meetingService.updateMeetingAction(MeetingMatrix).subscribe(
        (res) => {
          this.toaster.success(
            'success',
            'Meeting Action Updated Successfully'
          );
          this.resetForm();
          this.formMode = false;
        },
        (error) => {
          this.toaster.error('error', error.error.ErrorMessage);
        }
      );
    } else {
      this.meetingService.addMeetingAction(MeetingMatrix).subscribe(
        (res) => {
          this.toaster.success(
            'success',
            'Meeting Action Added Successfully'
          );
          this.resetForm();
        },
        (error) => {
          this.toaster.error('error', error.console.error.ErrorMessage);
        }
      );
    }
    // } else {
    //   this.toaster.warning('First Start Meeting');
    // }
  }

  enableGlidePathChange(event: any): void {
    const instance = this.scoreCardGridApi.getFilterInstance('Type');

    let Type = event.checked ? ['Glidepath', 'Actual'] : ['Actual'];

    let myArray = Type;
    instance?.setModel({
      values: myArray.length ? myArray : [],
    });
    this.scoreCardGridApi?.onFilterChanged();
  }
  updateMeetingData(): void { }
  resetForm(): void {
    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
    };
    this.getUserList();
    this.meetingService.getMeetingAction(key).subscribe((data) => {
      for (var d of data) {
        if (d.newtargetdate != null && d.newtargetDate != '') {
          d.newtargetdate = moment(d.newtargetdate).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        }
        if (d.completiondate != null && d.completiondate != '') {
          d.completiondate = moment(d.completiondate).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        }
      }
      this.rowData1 = data;
      this.MatrixForm.get('meetingDefName')?.setValue(
        this.selectedMeeting.meetingName
      );
      this.MatrixForm.get('meetingDefId')?.setValue(
        this.selectedMeeting.meetingId
      );
    });
    this.MatrixForm.get('action')?.setValue('');
    this.MatrixForm.get('responsible')?.setValue('');
    this.MatrixForm.get('targetdate')?.setValue('');
    this.MatrixForm.get('meetingDefName')?.setValue('');
  }
  closeSideModel(): void {
    this.SideModelVisible = false;
  }
  initialdisplay(): void {
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.add('content-show');
    wrapper1?.classList.remove('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
  }
  toggleDisplay(
    frequencyId: any,
    kpiName: any,
    frequencyName: any,
    meetingId: number,
    meetingName: string,
    meetingType: any,
    meetingMasterId: number,
    leaderId: number,
    meeting
  ): void {
    this.selectedMeeting = {
      meetingId,
      meetingName,
      meetingMasterId,
      meetingType,
      kpis: kpiName,
      leaderId
    };
    this.rowData = [];
    this.meetingDefId2 = meetingId;

    this.getFrequency();
    this.selectmeeting = this.selectedMeeting.meetingName;
    // get meeting participants
    this.getMeetingParticipants();
    this.getAllMeetingAgendas();
    this.resetMeeting();
    //  ui rendere with animations
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.add('content-show');
    wrapper2?.classList.remove('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    this.enableGlidePath = false;
    this.enableGlidePathChange({ checked: false });
  }
  headerBreaker(): void {

    setTimeout(() => {

      var headerCenter = document.getElementsByClassName('ag-header-cell-label') as HTMLCollectionOf<HTMLElement>;
      var headerEl = document.getElementsByClassName('ag-header-cell-text');
      for (var i = 0; i < headerEl.length; i++) {
        if (String((headerEl[i] as HTMLElement).innerHTML).includes("W -")) {
          headerCenter[i].style.display = 'flex';
          headerCenter[i].style.justifyContent = 'center';
          var txt = String(headerEl[i].innerHTML);
          if (!txt.includes("<br>")) {
            var txtArr = txt.split('(');
            if (!txtArr[1].includes("<span>")) {
              txtArr[1] = "<span style='font-size: 11px; color: #0f0fd1;'>" + "(" + txtArr[1] + "</span>";
            }
            var newTxt = txtArr[0] + "<br> " + txtArr[1];
            headerEl[i].innerHTML = newTxt;
          }
          var headerLabel = document.getElementsByClassName('ag-header-cell-label') as HTMLCollectionOf<HTMLElement>;
          headerLabel[i].style.textAlign = 'center';
        }
      }

    }, 1000);
  }
  getGridDataByFrequency(): any {
    this.scoreCardGridApi.showLoadingOverlay();
    this.date = new Date();
    const latestdate = new Date(this.range.value.toDate).setHours(
      23,
      59,
      59,
      0
    );
    const key = {
      frequencyId: this.MeetingMatrixFrequencyForm.value.frequency.frequencyId,
      kpiId: this.MeetingMatrixFrequencyForm.value.frequency.kpiId,
      frequencyName:
        this.MeetingMatrixFrequencyForm.value.frequency.frequencyName,
      plantId: this.MeetingMatrixForm.get('plant')?.value,
      fromDate: this.range.value.fromDate,
      toDate: new Date(latestdate),
      meetingDefId: this.selectedMeeting.meetingId,
    };
    this.meetingService
      .getScoreCardMeetingMatrix(key)
      .subscribe((data: any) => {
       
      
        
        this.ScoreCardData = data;
        this.createScoreCardData(this.ScoreCardData);
        
        this.scoreCardGridColumnApi.resetColumnState();
        this.autoSizeAll();
      });
  }
  generateColumns(data: any[]): any {

    let columnDefinitions: any[] = [];
    const hideColumns = [
      'Unit',
      'Previous',
      'Trend',
      'Department Name',
      'Responsible',
      'Type',
      'UoM',
      'YTD',
      'MTD',
    ];
    const cellClassColumns = [
      'Type',
      'Indicator',
      'Responsible',
      'Last Month',
      'KPICode',
      'MTD',
      'YTD',
      'KPIId',
      'meetingDefId',
      'meetingDefName',
      'Line',
      'UoM',
      'KPI Name',
      'Owner',
      'FinancialGap',
      'Target Date',
    ];
    const sortAble = ['FinancialGap'];
    const multiFilter = ['KPI Name', 'Line'];

    data.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key === 'FinancialGap' ? 'Financial Gap' : key, // .toUpperCase()
          field: key,
          headerCheckboxSelection: key === 'KPI Name' ? true : false,
          checkboxSelection: key === 'KPI Name' ? true : false,
          showDisabledCheckboxes: key === 'KPI Name' ? true : false,
          resizable: true,
          hide: hideColumns.includes(key),
          cellStyle: {},
          // sortable: sortAble.includes(key),
          sortable: true,
          autoHeight: true,
          headerClass: {},
          // width: 100,
          floatingFilter: multiFilter.includes(key) ? true : false,
          // suppressMenu: true,
          filter: multiFilter.includes(key) ? 'agMultiColumnFilter' : true,
          onCellClicked: {},
          cellClass: !cellClassColumns.includes(key)
            ? 'ag-grid-dynamic-cell'
            : '',
        };
        mappedColumn.cellStyle = (params: any): any => {
          const col = params.colDef.field;
          if (
            key !== 'Type' &&
            key !== 'Indicator' &&
            key !== 'Responsible' &&
            key !== 'Last Month' &&
            key !== 'KPICode' &&
            key !== 'MTD' &&
            key !== 'YTD' &&
            key !== 'KPIId' &&
            key !== 'meetingDefId' &&
            key !== 'meetingDefName' &&
            key !== 'Line' &&
            key !== 'UoM' &&
            key !== 'KPI Name' &&
            key !== 'Owner' &&
            key !== 'FinancialGap' &&
            key !== 'Target Date'
          ) {
            const styleObj = {
              'font-weight': 'bold',
              'background-color': '',
              color: 'black',
              textAlign: 'right'
            };

            if (col === 'KPI Name') {
              styleObj['font-weight'] = '700';
            }
            if (params.data.Type === 'Actual') {
              let currentActualValue = params.value;

              this.scoreCardGridOptions.api.forEachLeafNode(
                (rowNode, index) => {
                  // console.log('node ' + rowNode + ' is not a group!');
                  if (
                    rowNode.data.KPIId === params.data.KPIId &&
                    rowNode.data.Type === 'Glidepath'
                  ) {
                    // rowNode.data.Gaps = 10;
                    // console.log(rowNode.data);
                    if (params.api.getRowNode(index) !== undefined) {
                      // const previousGildepath = params.api.getRowNode(params.node.rowIndex - 1);
                      const rowGlidepath = params.api.getRowNode(index).data;
                      if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        // tslint:disable-next-line:max-line-length
                        parseFloat(currentActualValue) >
                        parseFloat(rowGlidepath[params.colDef.field]) &&
                        currentActualValue && // && parseInt(currentActualValue, 10) !== 0
                        rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                      ) {
                        // console.log(params.data.KPIName, 'rowGlidepath', rowGlidepath);
                        // console.log(params.data.KPIName, 'rowActual', params.data);
                        if (params.data?.Trend === 'Up') {
                          styleObj['background-color'] = 'green';
                          styleObj.color = 'white';
                        } else {
                          styleObj['background-color'] = 'red';
                          styleObj.color = 'white';
                        }
                        // console.log(styleObj);
                        // tslint:disable-next-line:max-line-length
                      } else if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        // tslint:disable-next-line:max-line-length
                        parseFloat(currentActualValue) <
                        parseFloat(rowGlidepath[params.colDef.field]) &&
                        currentActualValue && // && parseInt(currentActualValue, 10) !== 0
                        rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                      ) {
                        // console.log(params.data.KPIName, 'rowGlidepath2', rowGlidepath);
                        // console.log(params.data.KPIName, 'rowActual2', params.data);
                        if (params.data?.Trend === 'Down') {
                          styleObj['background-color'] = 'green';
                          styleObj.color = 'white';
                        } else {
                          styleObj['background-color'] = 'red';
                          styleObj.color = 'white';
                        }
                      } else if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        parseFloat(currentActualValue) === 0 &&
                        parseFloat(rowGlidepath[params.colDef.field]) === 0 &&
                        rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                      ) {
                        styleObj['background-color'] = 'green';
                        styleObj.color = 'white';
                      } else if (
                        rowGlidepath[params.colDef.field] !==
                        (undefined || null) &&
                        parseFloat(currentActualValue) ===
                        parseFloat(rowGlidepath[params.colDef.field]) &&
                        rowGlidepath.Type === 'Glidepath'
                      ) {
                        styleObj['background-color'] = 'green';
                        styleObj.color = 'white';
                      }
                    }
                  }
                }
              );
            } else {
              styleObj['background-color'] = 'yellow';
            }
            return styleObj;
          }
          if (col === 'KPI Name') {
            const styleObj = {
              'font-weight': 'bold',
            };
            return styleObj;
          }
          if (key === 'Type' && params.data.Type === 'Actual') {
            return { 'font-weight': 'bold', color: '#009688' };
          } else if (key === 'Type' && params.data.Type === 'Glidepath') {
            return { 'font-weight': 'bold', color: '#bf360c' };
          }
        };
        if (
          key !== 'Type' &&
          key !== 'Indicator' &&
          key !== 'Responsible' &&
          key !== 'Last Month' &&
          key !== 'KPICode' &&
          key !== 'MTD' &&
          key !== 'YTD' &&
          key !== 'KPIId' &&
          key !== 'meetingDefId' &&
          key !== 'meetingDefName' &&
          key !== 'Line' &&
          key !== 'UoM' &&
          key !== 'KPI Name'
        ) {
          // mappedColumn.width = 150;
        }
        if (
          key === 'KPIId' ||
          key === 'KPICode' ||
          key === 'meetingDefId' ||
          key === 'meetingDefName' ||
          key === 'Owner'
        ) {
          mappedColumn.hide = true;
        }
        // if (key === 'KPI Name') {
        //   mappedColumn.width = 400;
        // }
        columnDefinitions.push(mappedColumn);
      });
    });
    // Remove duplicate columns
    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    return columnDefinitions;
  }
  splitArrayIntoChunksOfLen(arr, len) {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }
  toggleDisplayreverse(): void {
    if (this.meetingStarted === false) {
      this.selectedMeeting = {
        meetingId: 0,
        meetingName: '',
        meetingType: '',
      };
      const wrapper2 = document.querySelector('#wrapper2');
      wrapper2?.classList.remove('content-show');
      wrapper2?.classList.add('content-hide');
      const wrapper3 = document.querySelector('#wrapper3');
      wrapper3?.classList.remove('content-show');
      wrapper3?.classList.add('content-hide');
      const wrapper1 = document.querySelector('#wrapper1');
      wrapper1?.classList.add('content-show');
      wrapper1?.classList.remove('content-hide');
    } else {
      this.toaster.warning('Meeting in Progress');
    }
    this.enableGlidePath = false;
    this.enableGlidePathChange({ checked: false });
  }
  hideTargetLevel = false;
  getMeetingEvaluations(): void {
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.add('content-show');
    wrapper3?.classList.remove('content-hide');
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');

    const TargetLevel = document.querySelector('#TargetLevel');
    const level = document.querySelector('#level');

    // chart data binding and getting from apis
    const key = {
      meetingDefId: this.selectedMeeting?.meetingId,
      meetingtype: this.selectedMeeting?.meetingType,
    };
    this.meetingService.getMeetingEvalationDashboard(key).subscribe(
      (response) => {
        // const targetArray = [];
        // const scoreArray = [];
        // const levelArray = [];
        // const datesArray = [];
        // const tableData = [];
        // let uniquesdate: any = [];
        // let uniqueLevels: any = [];
        var targetArray = [];
        var scoreArray = [];
        var levelArray = [];
        var datesArray = [];
        var tableData = [];
        var uniquesdate: any = [];
        var uniqueLevels: any = [];
        if (key.meetingtype === 'Evaluation Level') {
          this.hideTargetLevel = true;
          // target?.classList.add('hide');
          // target?.classList.remove('show');
          for (const iterator of response) {
            targetArray.push(iterator?.Target);
            scoreArray.push(iterator?.Score);
            levelArray.push(iterator?.EvalutionLevel);
            datesArray.push(
              moment(iterator?.Date).format('DD/MM/YYYY HH:mm:ss')
            );
            uniqueLevels = [...new Set(levelArray)];
            uniquesdate = [...new Set(datesArray)];
            tableData.push({
              Date: new Date(iterator?.Date),
              Score: iterator?.Score,
              Level: iterator?.EvalutionLevel,
            });
          }
          this.chartOptions.xaxis = {
            categories: uniquesdate,
            title: {
              text: 'Meeting Evalutions',
            },
          };
          let seriesScoreArr = [];
          for (const value of uniqueLevels) {
            seriesScoreArr = response
              .filter((res: any) => value === res.EvalutionLevel)
              .map((m: any) => m.Score);
            const seriesObj = {
              name: value,
              data: seriesScoreArr,
            };
            this.chartOptions.series.push(seriesObj);
          }
          this.meetingEvaluationData = new MatTableDataSource(tableData);
          this.meetingEvaluationData.paginator = this.paginator;
        } else if (key.meetingtype === 'Evaluation Check List') {
          this.hideTargetLevel = false;
          // level?.classList.add('hide');
          // level?.classList.remove('show');
          for (const iterator of response) {
            targetArray.push(iterator?.Target);
            scoreArray.push(iterator?.Score);
            datesArray.push(moment(iterator?.Date).format('DD/MM/YYYY'));
            tableData.push({
              Date: new Date(iterator?.Date),
              Score: iterator?.Score,
              Target: iterator?.Target,
            });
          }
          this.chartOptions.xaxis = {
            categories: datesArray,
            title: {
              text: 'Meeting Evalutions',
            },
          };
          this.chartOptions.series = [
            {
              name: 'Score',
              data: scoreArray,
            },
            {
              name: 'Traget',
              data: targetArray,
            },
          ];
          this.meetingEvaluationData = new MatTableDataSource(tableData);
          this.meetingEvaluationData.paginator = this.paginator;
        } else {
          for (const iterator of response) {
            targetArray.push(iterator?.Target);
            scoreArray.push(iterator?.Score);
            datesArray.push(moment(iterator?.Date).format('DD/MM/YYYY'));
            tableData.push({
              Date: new Date(iterator?.Date),
              Score: iterator?.Score,
              Target: iterator?.Target,
            });
          }
          this.chartOptions.xaxis = {
            categories: datesArray,
            title: {
              text: 'Meeting Evalutions',
            },
          };
          this.chartOptions.series = [
            {
              name: 'Score',
              data: scoreArray,
            },
            {
              name: 'Traget',
              data: targetArray,
            },
          ];
          this.meetingEvaluationData = new MatTableDataSource(tableData);
          this.meetingEvaluationData.paginator = this.paginator;
        }
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
    this.chartOptions.series = [];
  }

  toggleMeetingEvaluationReverse(): void {
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.add('content-show');
    wrapper2?.classList.remove('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
    this.enableGlidePath = false;
  }

  getContextMenuItems = (params: any) => {
    var result = [

      {
        // custom item
        name: 'RCA',
        action: () => {
          this.currentKPIId = params.node.data?.KPIId;
          this.openRcaDue({ data: params.node.data, layout: 'RCA' })

        },
        cssClasses: ['redFont', 'bold'],
      },
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'export',
    ];
    return result;
  }
  openRcaDue(params: any) {
    // if (!this.meetingStarted) {
    //   this.toaster.error('Meeting not started yet');
    //   // this.toastrService.warning('Meeting not started yet');
    //   return;
    // }
    this.analyticalToolsService.setHeaderId(0);

    const key = {
      EGId: this.MeetingMatrixForm.get('group')?.value,
      PlantId: this.MeetingMatrixForm.get('plant')?.value,
    };

    this.kpiservice.getEmployeeMasterData(key).then(
      (users: any) => {
        this.UserDDL = users.employeeInfoList.filter((x: any) => {
          return x.EmpInfo_Id;
        });
        this.UserDDL.forEach((element: any) => {
          element.fullName = element.FirstName + ' ' + element.lastName;
        });
        this.filteredUsers = this.UserDDL.slice();
        this.filteredParticipants = this.UserDDL.slice();
        this.filterBanksMulti();
        const dialogRef = this.dialog.open(AtStagesNcrComponent, {
          height: '100%',
          width: '100%',
          panelClass: 'full-screen-modal-RCA',

          data: {
            tableId: this.selectedMeeting.meetingId,
            KPIId: this.currentKPIId,
            KPIName: params?.data?.['KPI Name'],
            isKPI: true,

            MeetingAttendId: this.selectedMeeting?.meetingId,
            moduleId: 3,
            moduleName: 'MeetingMatrix',
            componentName: 'meeting-matrixV2',
            patchHeader: params?.data?.headerId === 0 ? false : true,
            headerId: null,
            InitProbStatement: params?.data?.['KPI Name'],
            meetingName: this.selectedMeeting.meetingName,
            Group: this.MeetingMatrixForm.get('group')?.value,
            Plant: this.MeetingMatrixForm.get('plant')?.value,
            hideTabs: true,
            leaderId: this.selectedMeeting?.leaderId,
            participants: this.meetingParticipants,
            rcaLayoutType: params?.layout

          },
        });

        dialogRef.afterClosed().subscribe((data) => {


        });
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getStockChartData(kpiId: number) {
    const latestdate = new Date(this.range.value.toDate).setHours(
      23,
      59,
      59,
      59
    );
    const obj = {
      frequencyName:
        this.MeetingMatrixFrequencyForm.value.frequency.frequencyName,
      plantId: this.MeetingMatrixForm.get('plant')?.value,
      fromDate: this.range.value.fromDate,
      toDate: latestdate,
      kpiId: kpiId,
    };
    this.meetingService.getStockChartData(obj).subscribe((data) => {
      let series = [];
      let yAxis = [];
      let glideData = [];
      let actualData = [];

      for (let i = 0; i < data.length; i++) {
        const type = data[i].Type;
        const date = data[i].Date;
        const target = data[i].Target;

        if (type === 'Actual') {
          actualData.push({
            x: +new Date(date),
            y: parseFloat(target),
            label: this.langObj ? this.langObj?.Actual : 'Actual',
          });
        }
        if (type === 'Target') {
          glideData.push({
            x: +new Date(date),
            y: parseFloat(target),
            label: this.langObj ? this.langObj?.Target : 'Target',
          });
        }
      }

      yAxis.push(
        {
          lineWidth: 1,
          opposite: false,
          title: {
            text: this.langObj ? this.langObj?.Actual : 'Actual',
            style: {
              color: '#00008B',
            },
          },
          labels: {
            style: {
              color: '#00008B',
            },
          },
        },
        {
          lineWidth: 1,
          opposite: false,
          title: {
            text: this.langObj ? this.langObj?.Target : 'Target',
            style: {
              color: '#34A56F',
            },
          },
          labels: {
            style: {
              color: '#34A56F',
            },
          },
        }
      );

      series.push(
        {
          tooltip: {
            valueDecimals: 2,
          },
          name: this.langObj ? this.langObj?.Actual : 'Actual',
          type: 'line',
          data: actualData,
          color: '#00008B',
          yAxis: 0,
        },
        {
          tooltip: {
            valueDecimals: 2,
          },
          name: this.langObj ? this.langObj?.Target : 'Target',
          type: 'line',
          data: glideData,
          color: '#34A56F',
          yAxis: 1,
        }
      );

      this.createStockChartForSingleInstance(yAxis, series);
    });
    this.openStockChartModal();
  }

  createStockChartForSingleInstance(yAxis: any, series: any): void {
    this.stock = new StockChart({
      chart: {
        height: 510,
      },
      plotOptions: {
        series: {
          turboThreshold: 50000000,
          events: {
            hide(): any {
              this.yAxis.update({
                visible: false,
              });
            },
            show(): any {
              this.yAxis.update({
                visible: true,
              });
            },
          },
        },
      },
      rangeSelector: {
        selected: 1,
      },
      title: {
        text: 'Stock Chart', // uniqueGroups.toString(),
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        valueDecimals: 2,
      },
      yAxis: yAxis,
      series: series,
    });
  }

  openStockChartModal(): void {
    this.dialog.open(this.stockChartModel, { height: '85vh', width: '100%' });
  }

  filterBanksMulti() {
    if (!this.filteredParticipants) {
      return;
    }

    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.filteredBanksMulti.next(this.filteredParticipants);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanksMulti.next(
      this.filteredParticipants.filter(
        (bank: any) =>
          (bank.FirstName + ' ' + bank.lastName).toLowerCase().indexOf(search) >
          -1
      )
    );
  }

  getUserList(): void {
    const key = {
      EGId: this.MeetingMatrixForm.get('group')?.value,
      PlantId: this.MeetingMatrixForm.get('plant')?.value,
    };

    this.kpiservice.getEmployeeMasterData(key).then(
      (users: any) => {
        this.UserDDL = users.employeeInfoList.filter((x: any) => {
          return x.EmpInfo_Id;
        });
        this.UserDDL.forEach((element: any) => {
          element.fullName = element.FirstName + ' ' + element.lastName;
        });

        this.filteredUsers = this.UserDDL.slice();
        this.filteredParticipants = this.UserDDL.slice();
        this.filterBanksMulti();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  openModal() {
    this.addActionForm.reset();
    this.addActionForm.get('actionType')?.setValue('Corrective');
    this.getOverallMeetingActions();
    this.addActionForm.get('kpi')?.setValue(this.KPIName);
    this.dialog.open(this.addActionModal, { height: 'auto', width: '90%' });
  }

  resetRoleModal(): void {
    this.addActionForm?.reset();
    this.addFileSrc = null;
    this.addImageSrc = null;
    this.imageAddActionPlan = null;
    this.fileAddActionPlan = null;
    this.addActionForm.get('actionType')?.setValue('Preventive');
  }

  cellClickedActionTable(params: any) {
    let col = params.colDef.field;
    this.actionSelectedRow = params.node;

    let classList = params.event.target.classList;
    if (col === 'actions') {
      if (
        classList.contains('mat-icon-no-color') ||
        classList.contains('viewRCA')
      ) {
        this.editRow(params);
      }
    }
    if (col === 'ClosedFile') {
      if (classList.contains('viewDocument')) {
        this.doc =
          this.blobStorageUrl +
          params.data.ClosedFile;
        const fileType = params.data.ClosedFile.split('.').pop();
        if (
          fileType === 'jpeg' ||
          fileType === 'jpg' ||
          fileType === 'png' ||
          fileType === 'gif'
        ) {
          this.images = [];
          this.fileType = 'img';
          this.images.push(this.doc);
        } else {
          this.fileType = 'doc';
        }
        this.fileDesc = params.data?.KPIName;
        this.displayMaximizable = true;
      }
    }
  }

  onScoreCardCellClicked(params: any): void {
    const col = params.colDef.field;
    let data = params.data;

    this.currentKPIId = data.KPIId;

    if (
      col === 'Trend' ||
      col === 'Type' ||
      col === 'Indicator' ||
      col === 'Responsible' ||
      col === 'Last Month' ||
      col === 'KPICode' ||
      col === 'MTD' ||
      col === 'YTD' ||
      col === 'KPIId' ||
      col === 'meetingDefId' ||
      col === 'meetingDefName' ||
      col === 'Line' ||
      col === 'UoM' ||
      col === 'KPI Name' ||
      col === 'Owner' ||
      col === 'FinancialGap' ||
      col === 'Target Date' ||
      col === 'Unit' ||
      col === 'Previous' ||
      col === 'Department Name'
    ) {
      return;
    }

    const key1 = {
      kpiId: params.data.KPIId,
    };
    // this.meetingaction.getResponsibleOnKpiId(key1).subscribe((res) => {
    //   this.filteredUsers = res.slice();
    // });
    this.getUserList();
    this.isKpiAddToAction = true;

    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
    };
    if (params.colDef.field !== 'na') {
      this.meetingService.getMeetingAction(key).subscribe((data) => {
        for (var d of data) {
          if (d.newtargetdate != null && d.newtargetDate != '') {
            d.newtargetdate = moment(d.newtargetdate).format(
              'DD/MM/YYYY HH:mm:ss'
            );
          }
          if (d.completiondate != null && d.completiondate != '') {
            d.completiondate = moment(d.completiondate).format(
              'DD/MM/YYYY HH:mm:ss'
            );
          }
        }
        this.rowData1 = data;
        this.MatrixForm.get('meetingDefName')?.setValue(
          this.selectedMeeting.meetingName
        );
        this.selectmeeting = this.MatrixForm.get('meetingDefName')?.setValue(
          this.selectedMeeting.meetingName
        );
        this.MatrixForm.get('meetingDefId')?.setValue(
          this.selectedMeeting.meetingId
        );
        this.kpiId = params.data.KPIId;
        this.KPIName = params.data['KPI Name'];
        this.MatrixForm.get('kpi')?.setValue(params.data['KPI Name']);
        this.currentKPIId = this.kpiId
        this.openModal();
      });
    }
  }

  removeAgendaRow(value: any): void {
    if (this.meetingStarted === true) {
      this.meetingAgendasArray.forEach((element, index) => {
        if (element.agenda == value) this.meetingAgendasArray.splice(index, 1);
      });
    } else {
      this.toaster.warning('First Start Meeting');
    }
  }

  actionAddClick(): void {
    this.isKpiAddToAction = false;
    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
    };
    this.getUserList();
    this.meetingService.getMeetingAction(key).subscribe((data) => {
      for (var d of data) {
        if (d.newtargetdate != null && d.newtargetDate != '') {
          d.newtargetdate = moment(d.newtargetdate).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        }
        if (d.completiondate != null && d.completiondate != '') {
          d.completiondate = moment(d.completiondate).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        }
      }
      this.rowData1 = data;
      this.MatrixForm.get('meetingDefName')?.setValue(
        this.selectedMeeting.meetingName
      );
      this.MatrixForm.get('meetingDefId')?.setValue(
        this.selectedMeeting.meetingId
      );
      this.currentKPIId = null;
      this.KPIName = null;
      this.openModal();
    });
  }

  meetingSearch(): void {
    const searchedArray: any = [];
    this.meetingOriginalArray.forEach((element) => {
      if (
        element.meetingName.toLowerCase().includes(this.search.toLowerCase())
      ) {
        searchedArray.push(element);
      }
    });
    const frequencies = [
      ...new Set(
        searchedArray.map((c: { frequencyName: any }) => c.frequencyName)
      ),
    ];
    const formattedMeetingsArray: any = [];
    frequencies.forEach((freq) => {
      const obj = {
        frequencyName: freq,
        meetings: searchedArray
          ?.filter((x: any) => x.frequencyName === freq)
          .map((item: any) => {
            return {
              meetingId: item?.meetingId,
              meetingName: item?.meetingName,
              kpiName: item?.kpiName,
              frequencyId: item?.frequencyId,
              frequencyName: item?.frequencyName,
              meetingMasterId: item?.meetingMasterId,
              meetingType: item?.type,
              leaderId: item?.leaderId
            };
          }),
      };
      formattedMeetingsArray.push(obj);
    });
    this.filtereMeetings = formattedMeetingsArray;
  }

  onCellValueChanged(params: any): void {
    const col = params.colDef.field;
    if (col === 'Status') {
      const compDate = new Date();
      this.cellData = {
        status: params.data.Status,
        meetingActionId: params.data.meetingActionId,
        createdBy: JSON.parse(localStorage.user).userId,
        completionDate: moment(compDate).format('MM/DD/YYYY'),
      };
      this.meetingService.setCompletionDate(this.cellData).subscribe(
        (response: any) => {
          this.toaster.success('Success', 'Data Added Successfully');
          const key = {
            meetingDefId: this.selectedMeeting.meetingId,
          };
          this.meetingService.getMeetingAction(key).subscribe((data) => {
            for (var d of data) {
              if (d.newtargetdate != null && d.newtargetDate != '') {
                d.newtargetdate = moment(d.newtargetdate).format(
                  'DD/MM/YYYY HH:mm:ss'
                );
              }
              if (d.completiondate != null && d.completiondate != '') {
                d.completiondate = moment(d.completiondate).format(
                  'DD/MM/YYYY HH:mm:ss'
                );
              }
            }
            this.rowData1 = data;
            this.MatrixForm.get('meetingDefName')?.setValue(
              this.selectedMeeting.meetingName
            );
            this.MatrixForm.get('meetingDefId')?.setValue(
              this.selectedMeeting.meetingId
            );
          });
        },
        (error: any) => {
          return this.toaster.error('Error', error.message);
        }
      );
    } else if (col === 'completiondate') {
      const dateString =
        params.data.completiondate === null ? null : params.data.completiondate;
      let dateObject = ''; // Oct 23
      if (dateString != null) {
        dateObject = this.convertDate(params.data.completiondate);
      }
      const targetDate = new Date(
        this.convertDate(params.data.targetdate).replace(/-/g, '/')
      );
      const completionDate = new Date(
        this.convertDate(params.data.completiondate).replace(/-/g, '/')
      );
      const newTargetDate = params.data.newtargetdate;
      // if (completionDate >= targetDate) {
      // params.data.Status = 'Closed';
      this.cellData = {
        status: 'Closed',
        completionDate: dateObject,
        meetingActionId: params.data.meetingActionId,
        createdBy: JSON.parse(localStorage.user).userId,
      };
      this.meetingService.setCompletionDate(this.cellData).subscribe(
        (response: any) => {
          this.toaster.success('Success', col + 'Status Added Successfully');
          // params.node.setDataValue('Status', params.data.Status);
          const key = {
            meetingDefId: this.selectedMeeting.meetingId,
          };
          this.meetingService.getMeetingAction(key).subscribe((data) => {
            for (var d of data) {
              if (d.newtargetdate != null && d.newtargetDate != '') {
                d.newtargetdate = moment(d.newtargetdate).format(
                  'DD/MM/YYYY HH:mm:ss'
                );
              }
              if (d.completiondate != null && d.completiondate != '') {
                d.completiondate = moment(d.completiondate).format(
                  'DD/MM/YYYY HH:mm:ss'
                );
              }
            }
            this.rowData1 = data;
          });
        },
        (error: any) => {
          return this.toaster.error('Error', error.error.message);
        }
      );
      this.gridOptions1.suppressClickEdit = false;
      return;
      // }
      // else {
      //   this.toaster.error('Completion Date should be grater than target date');
      //   // params.node.setDataValue('completiondate', '');
      //   const key = {
      //     meetingDefId: this.selectedMeeting.meetingId,
      //   };
      //   this.meetingService.getMeetingAction(key).subscribe((data) => {
      //     this.rowData1 = data;
      //   });
      //   // return params.oldValue;
      // }
    } else if (col === 'newtargetdate') {
      let dateObject;
      if (params.data.newtargetdate) {
        dateObject = this.convertDate(params.data.newtargetdate);
      }
      const oldTargetDate = this.convertDate(
        moment(params.data.targetdate).format('DD/MM/YYYY')
      );
      const newTargetDate = dateObject;
      params.data.Status = 'Delayed';
      params.data.misses = params.data.misses + 1;
      this.cellData = {
        newTargetDate: dateObject,
        status: params.data.Status,
        completionDate: null,
        meetingActionId: params.data.meetingActionId,
      };
      // var newDateArr = newTargetDate.split('-');
      // var oldDateArr = oldTargetDate.split('-');
      // if (parseInt(newDateArr[0]) >= parseInt(oldDateArr[0]) && parseInt(newDateArr[1]) >= parseInt(oldDateArr[1]) && parseInt(newDateArr[2]) >= parseInt(oldDateArr[2])) {
      if (newTargetDate >= oldTargetDate) {
        this.meetingService.setTargetDate(this.cellData).subscribe(
          (response: any) => {
            this.toaster.success('Success', 'Data Added Successfully');
            params.node.setDataValue('misses', params.data.misses);
            params.node.setDataValue('Status', 'Delayed');
          },
          (error: any) => {
            return this.toaster.error('Error', error.message);
          }
        );
      } else {

        this.toaster.error('New Target Date should be grater than Target Date');
        const key = {
          meetingDefId: this.selectedMeeting.meetingId,
        };
        this.meetingService.getMeetingAction(key).subscribe((data) => {
          for (var d of data) {
            if (d.newtargetdate != null && d.newtargetDate != '') {
              d.newtargetdate = moment(d.newtargetdate).format(
                'DD/MM/YYYY HH:mm:ss'
              );
            }
            if (d.completiondate != null && d.completiondate != '') {
              d.completiondate = moment(d.completiondate).format(
                'DD/MM/YYYY HH:mm:ss'
              );
            }
          }
          this.rowData1 = data;
        });
        return;
      }
    }
    return;
  }

  onScoreCardGridReady(params: any): void {
    this.scoreCardGridApi = params.api;
    this.scoreCardGridColumnApi = params.columnApi;
    this.scoreCardGridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#wrapper2grid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridReadyEdit(params: any): any {
    this.gridApiEdit = params.api;
    this.gridColumnApiEdit = params.columnApi;
  }
  onGridReadyAction(params: any): any {
    this.gridApiActions = params.api;
    this.gridColumnApiActions = params.columnApi;
  }
  

  viewMeetingsAgendas(): void {
    this.dialog.open(this.viewAgendasSheet, { height: 'auto', width: '70%' });
    for (const x of this.meetingAgendas) {
      const tempAgenda = this.meetingAgendasArray.filter(
        (meet: any) => x.MeetingAgendaName === meet.agenda
      );
      if (tempAgenda.length === 0) {
        this.meetingAgendasArray.push({ agenda: x.MeetingAgendaName });
      }
    }
  }

  getAllMeetingAgendas(): void {
    const key = {
      meetingDefId: this.selectedMeeting?.meetingId,
    };
    this.meetingService.getAllMeetingAgendasv1(key).subscribe(
      (response) => {
        this.meetingAgendas = response;
        this.meetingAgendas.forEach((x: any) => {
          this.meetingAgendasArray.push({ agenda: x.MeetingAgendaName });
        });
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }

  closeSheet(event: MouseEvent): void {
    this.dialog.closeAll();
    event.preventDefault();
  }
  // meeting suggested actions
  openMeetingSuggestedModal(): void {
    this.getUserList();
    this.dialog.open(this.suggestedActionModal, {
      height: '90%',
      width: '90%',
    });
    this.getSuggestedActions();
  }

  getSuggestedActions(): void {
    const key = {
      meetingDefId: this.selectedMeeting.meetingId,
    };
    this.meetingService.getMeetingSuggestedActions(key).subscribe(
      (response) => {
        this.columnDefsSuggested = [
          {
            field: 'meetingSuggestedActionId',
            headerName: 'Actions',
            sortable: true,
            resizable: true,
            wrapText: true,
            width: 75,
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
              onClick: this.deleteMeetingSuggested.bind(this),
              label: 'Delete',
            },
          },
          {
            field: 'action',
            headerName: 'Suggested Action',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'meetingName',
            headerName: 'Meeting Name',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'responsibleName',
            headerName: 'Responsible',
            sortable: true,
            resizable: true,
            wrapText: true,
          },
          {
            field: 'completionDate',
            headerName: 'Completion Date',
            sortable: true,
            resizable: true,
            wrapText: true,
            filter: 'agDateColumnFilter',
            cellEditor: 'primeCellEditor',
            editable: (params: any) => params.data.Status !== 'Closed',
            filterParams: {
              comparator: myDateComparator,
              calendarOptions: {
                dateFormat: 'dd/mm/yy', // Set the date format
                showButtonBar: true, // Show the button bar
                yearRange: '2000:2030', // Set the year range
                monthNavigator: true, // Show the month navigator
                locale: {
                  // Set the locale
                  firstDayOfWeek: 1,
                  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                  dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                  monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                }
              }
            },
            cellStyle: {
              color: 'black',
              'background-color': '#EDF1E5',
            },
          },
        ];
        this.meetingSuggestedActions = response;
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }

  addSuggestedAction(): void {
    if (this.meetingStarted === true) {
      const { action, completionDate, responsibleId } =
        this.meetingSuggestedActionForm.value;
      const data = {
        meetingDefId: this.selectedMeeting.meetingId,
        action,
        completionDate,
        responsibleId,
      };
      this.meetingService.addMeetingSuggestedAction(data).subscribe(
        (response) => {
          this.toaster.success('Meeting suggested action added successfully');
          this.getSuggestedActions();
        },
        ({ error }) => {
          this.toaster.error(error.message);
        }
      );
    } else {
      this.toaster.warning('First Start Meeting');
    }
  }

  deleteMeetingSuggested({ rowData }: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Suggested Action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          meetingSuggestedActionId: rowData.meetingSuggestedActionId,
        };
        this.meetingService.deleteMeetingSuggestedAction(key).subscribe(
          (response) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your selected Action has been deleted.',
              icon: 'success',
              timer: 800,
              showConfirmButton: false,
            });
            this.getSuggestedActions();
          },
          ({ error }) => {
            this.toaster.error('Error', error.message);
          }
        );
      }
    });
  }

  onCellSuggestedValueChanged({ data }: any): void {
    const { meetingSuggestedActionId, completionDate } = data;
    const key = {
      meetingSuggestedActionId,
      completionDate: this.convertDate(completionDate),
    };
    // this.meetingService.updateSuggestedCompletionDate(key).subscribe(
    //   (response) => {
    //     this.toaster.success('Completion Date updated successfully');
    //   },
    //   ({ error }) => {
    //     this.toaster.error(error.message);
    //   }
    // );
  }

  // meeting participants

  updatedgetmeeting() {
    const key = {
      meetingId: this.selectedMeeting?.meetingId,
    };
    this.meetingService.getMeetingParticipantsv1(key).subscribe(
      (res) => {
        this.meetingParticipants = res?.map(
          ({ participantId, participantName, reason, isAttended }) => {
            return {
              participantId,
              participantName,
              isAttended,
              reason,
            };
          }
        );
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }

  getMeetingParticipants(): void {
    const key = {
      meetingId: this.selectedMeeting?.meetingId,
    };
    this.meetingService.getMeetingParticipantsv1(key).subscribe(
      (res) => {
        this.meetingParticipants = res?.map(
          ({ participantId, participantName, reason, isAttended }) => {
            return {
              participantId,
              participantName,
              isAttended,
              reason,
            };
          }
        );
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
  }

  addManualPartcipent(): void {
    const filterdata = this.meetingParticipants;
    const parti = filterdata.filter(
      (p: any) =>
        p.participantId === this.ManualParticepantForm.value.participant
    );
    if (parti.length > 0) {
      this.toaster.error('Error', 'Participant  Already Exist');
      return;
    }

    if (this.meetingStarted === true) {
      const key = {
        meetingId: this.selectedMeeting?.meetingId,
        responsible: this.ManualParticepantForm.get('participant')?.value,
      };
      this.toaster.success('Meeting Particepents added successfully');
      const participantName = this.filteredParticipants.find(
        (x: any) => x.EmpInfo_Id === key.responsible
      )?.fullName;
      this.meetingParticipants.push({
        participantId: key.responsible,
        participantName,
        isAttended: false,
        reason: '',
      });
      if (this.selectedAll === true) {
        this.selectedAll = false;
      }
    } else {
      this.toaster.warning('First Start Meeting');
    }
  }

  openUserModal(): void {
    this.dialog.open(this.displayUserModal, {
      panelClass: 'participants-container',

      height: 'auto',
      width: '50%',
    });
    this.getUserList();
  }

  participantAttended(event: any, id: number): void {
    const participant = this.meetingParticipants.find(
      (x) => x.participantId === id
    );
    if (participant) {
      // participant.isAttended = !participant.isAttended;
      // if (event.value === 'ontime' || event.value === 'late') {
      participant.isAttended = true;
      // } else {
      //   participant.isAttended = false;
      // }
    }
  }

  selectAllParticipants(event: any): void {
    this.selectedAll = event?.checked;
    this.meetingParticipants = this.meetingParticipants?.map(
      ({ participantId, participantName, reason }) => {
        return {
          participantId,
          participantName,
          isAttended: event?.checked,
          reason: event?.checked ? 'ontime' : '',
        };
      }
    );
  }

  saveMeetingAgenda(): void {
    if (this.meetingStarted === true) {
      const key = {
        agenda: this.AddAgendaForm.value.agenda,
      };
      if (this.AddAgendaForm.value.agenda !== '') {
        this.meetingAgendasArray.push(key);
        this.AddAgendaForm.reset();
      }
    } else {
      this.toaster.warning('First Start Meeting');
    }
  }

  startMeeting(): any {
    const key = {
      meetingDefId: this.selectedMeeting?.meetingId,
    };
    if (this.meetingStarted === false) {
      this.meetingService.getMeetingFrequencyStatus(key).subscribe(
        (response) => {
          if (response?.length > 0) {
            const heldMeeting = new Date(response[0]?.MeetingStartTime);
            const currentDate = new Date();
            const date = new Date();
            const quarter = Math.floor(currentDate.getMonth() / 3);
            const quarterFirstDate = new Date(
              currentDate.getFullYear(),
              quarter * 3,
              1
            );
            const quarterEndDate = new Date(
              quarterFirstDate.getFullYear(),
              quarterFirstDate.getMonth() + 3,
              0
            );
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const firstDayOfBiMonth =
              currentDate.getDay() > 15
                ? new Date(currentYear, currentMonth, 16)
                : new Date(currentYear, currentMonth, 1);
            const lastDayBiOfMonth =
              currentDate.getDay() > 15
                ? new Date(currentYear, currentMonth + 1, 0)
                : new Date(currentYear, currentMonth, 15);
            const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
            const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
            const firstDayOfWeek = new Date(
              date.setDate(date.getDate() - date.getDay())
            );
            const lastDayOfWeek = new Date(
              date.setDate(date.getDate() - date.getDay() + 7)
            );
            const frequency = response[0]?.Name;

            // if (
            //   frequency === 'Yearly' &&
            //   heldMeeting.getFullYear() === currentYear
            // ) {
            //   this.toaster.warning('You cannot start meeting twice in a year');
            //   return;
            // }
            // else if (

            //   frequency === 'Quarterly' &&
            //   heldMeeting >= quarterFirstDate &&
            //   heldMeeting <= quarterEndDate
            // ) {
            //   this.toaster.warning(
            //     'You cannot start meeting twice in a quarter'
            //   );
            //   return;
            // } else if (
            //   frequency === 'Monthly' &&
            //   heldMeeting >= firstDayOfMonth &&
            //   heldMeeting <= lastDayOfMonth
            // ) {
            //   this.toaster.warning('You cannot start meeting twice in a month');
            //   return;
            // } else if (
            //   frequency === 'BiMonthly' &&
            //   heldMeeting >= firstDayOfBiMonth &&
            //   heldMeeting <= lastDayBiOfMonth
            // ) {
            //   this.toaster.warning(
            //     'You cannot start meeting twice in a bimonth'
            //   );
            //   return;
            // } else if (
            //   frequency === 'Weekly' &&
            //   heldMeeting >= firstDayOfWeek &&
            //   heldMeeting <= lastDayOfWeek
            // ) {
            //   this.toaster.warning('You cannot start meeting twice in a week');
            //   return;
            // } else if (
            //   frequency === 'Daily' &&
            //   heldMeeting.getDate() === currentDate.getDate()
            // ) {
            //   this.toaster.warning('You cannot start meeting twice in day');
            //   return;
            // } else if (frequency === 'Shiftly') {
            //   if (this.shiftData.length === 0) {
            //     this.toaster.warning('First save shift');
            //   } else if (this.shiftData.length) {
            //     const length = response.filter(
            //       (c) =>
            //         new Date(c.MeetingStartTime).getDate() ===
            //         currentDate.getDate()
            //     ).length;
            //     // const length = heldMeeting.getDate() === currentDate.getDate();
            //     if (length > this.shiftData.length) {
            //       this.toaster.warning(
            //         'You cannot start meeting more than your shifts'
            //       );
            //       return;
            //     }
            // }
            // }
          }
          this.meetingStarted = true;
          this.pausebutton = true;
          this.meetingStoped = false;
          this.meetingStartTime = new Date();
          this.interval = setInterval(() => {
            this.seconds = this.seconds + 1;
            if (this.seconds === 60) {
              this.minutes = this.minutes + 1;
              this.seconds = 0;
            }
            if (this.minutes === 60) {
              this.hours = this.hours + 1;
              this.minutes = 0;
              this.seconds = 0;
            }
            this.timer =
              ('0' + this.hours).slice(-2) +
              ':' +
              ('0' + this.minutes).slice(-2) +
              ':' +
              ('0' + this.seconds).slice(-2);
          }, 1000);
        },
        ({ error }) => {
          this.toaster.error(error.message);
        }
      );
    } else {
      this.toaster.warning('Meeting has been started already');
    }
  }

  stopMeeting(): any {
    if (
      this.meetingParticipants.filter((x) => x.isAttended === true).length > 0
    ) {
      if (this.meetingStarted === true) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to Stop the Meeting Right Now!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Stop it!',
          cancelButtonText: 'No, keep it',
        }).then((result) => {
          if (result.value) {
            clearInterval(this.interval);
            this.meetingEndTime = new Date();
            this.meetingStarted = false;
            this.meetingStoped = true;
            const domainUrl = document.location.origin;
            const meetingId = this.selectedMeeting?.meetingId;
            const meetingName = this.selectedMeeting?.meetingName;
            const groupId = parseInt(this.MeetingMatrixForm.value.group, 10);
            const plantId = parseInt(this.MeetingMatrixForm.value.plant, 10);
            const meetingType = this.meetingOriginalArray.find(
              (x) => x.meetingId === meetingId
            )?.type;
            const data = {
              meetingId,
              meetingName,
              evaluationUrl:
                meetingType === 'Evaluation Check List'
                  ? domainUrl +
                  `/meeting-evaluation?plant=${plantId}&meeting=${meetingId}&group=${groupId}`
                  : domainUrl +
                  `/meeting-evaluation-level?plant=${plantId}&meeting=${meetingId}`,
              meetingStartTime: this.meetingStartTime,
              meetingEndTime: this.meetingEndTime,
              participantsAttended: this.meetingParticipants
                ?.filter((x) => x.isAttended === true)
                ?.map((item: any) => {
                  return {
                    participantId: item.participantId,
                    reason: item?.reason,
                  };
                }),
              agendaDetail: this.meetingAgendasArray,
              createdBy: JSON.parse(localStorage.user).UserId,
            };
            this.meetingService.stopMeetingAndSendEmailv1(data).subscribe(
              (res) => {
                this.toaster.success(
                  'Meeting Has been Ended And also mail have been sent to meeting attended'
                );
                this.resetMeeting();
              },
              ({ error }) => {
                this.toaster.error(error.message);
              }
            );
          }
        });
        return;
      }
      this.toaster.warning('Please start the meeting first');
    } else {
      this.toaster.warning('Please make sure Attendence first');
    }
  }

  resetMeeting(): void {
    clearInterval(this.interval);
    this.timer = '00:00:00';
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    this.meetingStartTime = undefined;
    this.meetingEndTime = undefined;
    this.meetingStarted = false;
    this.meetingStoped = false;
    this.pausebutton = false;
    this.meetingAgendasArray = [];
  }

  convertDate(date: any): any {
    const dateParts = date.split('/');
    return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
  }

  onScoreCardGridFirstDataRendered(e?: any) {
    this.enableGlidePath = false;
    this.enableGlidePathChange({ checked: false });

    const columns = this.scoreCardGridColumnApi.getAllDisplayedColumns();


    // const allColumnIds = [];
    // this.scoreCardGridColumnApi?.getColumns()?.forEach((column) => {
    //   allColumnIds.push(column.getId());
    // });

    // this.scoreCardGridColumnApi?.autoSizeColumns(allColumnIds, false);
    // e?.api?.sizeColumnsToFit();

    // this.scoreCardGridColumnApi.moveColumns(['gold', 'silver', 'bronze', 'total'], 6);
  }

  toggleView() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      const colorElements = document.getElementsByClassName(
        'ag-body-viewport'
      ) as HTMLCollectionOf<HTMLElement>;
      colorElements[0].style.height = '70vh';
      colorElements[0].style.overflowY = 'scroll';
    }
    else {
      const colorElements = document.getElementsByClassName(
        'ag-body-viewport'
      ) as HTMLCollectionOf<HTMLElement>;
      colorElements[0].style.height = '25vw';
      colorElements[0].style.overflowY = 'scroll';
    }
    const colorElements = document.getElementsByClassName(
      'content-wrapper'
    ) as HTMLCollectionOf<HTMLElement>;
    if (this.isExpanded) {
      colorElements[0].style.setProperty('height', '100vh');
    } else {
      colorElements[0].style.setProperty('height', '100%');
    }
  }

  navigateLeakDetection(element: any) {
    const name = element?.chartOption?.options?.title?.text
    if (name.substring(0, 3) === 'DNT') {
      (window as any).open(
        'https://geoportal-collector.saur.fr/collector/apps/webappviewer/index.html?id=c2770d0f481b4b7d8063f2628d5749ea&query=PredFuitesO3_6530_0%2CHydraulicZoneCode%2C' +
        name.substring(6, 15),
        '_blank'
      );
    } else {
      this.toaster.warning(
        'warn',
        'Leak Detection Code not available of this KPI'
      );
    }
  }

  navigateLeakDetectionAll() {
    (window as any).open(
      'https://geoportal-collector.saur.fr/collector/apps/webappviewer/index.html?id=c2770d0f481b4b7d8063f2628d5749ea&query=PredFuitesO3_6530_0%2CHydraulicZoneCode%2C',
      '_blank'
    );
  }

  sortGapColumn(type: any) {
    if (type === 'asc') {
      this.sort = 'desc';
    } else if (type === 'desc') {
      this.sort = null;
    } else {
      this.sort = 'asc';
    }

    this.scoreCardGridColumnApi.applyColumnState({
      state: [{ colId: 'FinancialGap', sort: type }],
      defaultState: { sort: null },
    });
  }

  getFinancialGapList(fromDate: any, toDate: any) {
    const data = {
      fromDate: fromDate,
      toDate: toDate,
      meetingDefId: this.selectedMeeting.meetingId,
      frequencyId: this.MeetingMatrixFrequencyForm.value.frequency.frequencyId,
    };
    this.meetingService.GetKPIFinanicalGap(data).subscribe(
      (res: any) => {
        this.financialGapList = res;
        if (this.financialGapList.length) {
          this.financialGapList.forEach((e: any) => {
            const itemArr: any[] = [];
            this.scoreCardGridApi.forEachLeafNode((rowNode: any) => {
              if (
                rowNode.data.KPIId === e.KPIId &&
                rowNode.data.Type === 'Actual'
              ) {
                const record = this.scoreCardGridApi.getRowNode(rowNode.id);
                if (record) {
                  record.setDataValue('FinancialGap', e.FinanicalGap);
                }
              }
            });
          });
          // alert();

          // const filterIndex: any = itemArr.filter(
          //   (c) => c.rowIndex === this.RowIndex
          // );
          // const rowNode = this.gridApi.getRowNode(filterIndex);
          // const newDate = moment(new Date()).format('DD/MM/YYYY');
          // rowNode.setDataValue('FinancialGap', newDate);
        }
      },
      (err: any) => {
      }
    );
  }

  onClickParamterAnalysis(isTrue) {
    //checking saur plant for leak detection
    const plant = this.plants.find(
      (p) => p.DeptId === this.MeetingMatrixForm.get('plant')?.value
    );
    plant.DeptDesc === 'Secteur Hérault-Sud 1'
      ? (this.isSaur = true)
      : (this.isSaur = false);

    this.chartType = false;
    // this.KPIAnalysisForm.reset();
    const selectedRows = this.scoreCardGridApi.getSelectedRows();
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name']
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'] },
      ];
    }, []);
    const KPIName = distinctRows;
    this.KPIAnalysisForm.get('fromDate').setValue(this.range.value.fromDate);
    this.KPIAnalysisForm.get('toDate').setValue(this.range.value.toDate);
    let dialog = this.dialog.open(this.analysisChart, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '100vh',
      minWidth: '95vw',
      maxHeight: '100vh',
    });

    dialog.afterClosed().subscribe((result) => {
      this.chartArray = [];
      this.chartArrayForAnalysis = [];
    });

    distinctRows.forEach((element) => {
      const columns = this.columnDefs
        .map((c) => c.field)
        .filter(
          (c) =>
            c !== 'Trend' &&
            c !== 'Type' &&
            c !== 'Indicator' &&
            c !== 'Responsible' &&
            c !== 'Last Month' &&
            c !== 'KPICode' &&
            c !== 'MTD' &&
            c !== 'YTD' &&
            c !== 'KPIId' &&
            c !== 'meetingDefId' &&
            c !== 'meetingDefName' &&
            c !== 'Line' &&
            c !== 'UoM' &&
            c !== 'KPI Name' &&
            c !== 'Owner' &&
            c !== 'FinancialGap' &&
            c !== 'Target Date' &&
            c !== 'Unit' &&
            c !== 'Previous' &&
            c !== 'Department Name'
        );
      const analysisDataTarget = [];
      const analysisData = [];
      const analysisLabel = [];
      const title = element['KPI Name'];
      this.meetingTitle = title;
      const kpiid = element.KPIId;
      const chartObj = { id: '', chartOption: {} };
      const chartObj2 = { id: '', chartOption: {} };
      for (const col of columns) {
        this.scoreCardGridOptions.api.forEachLeafNode((rowNode, index) => {
          if (
            rowNode.data.KPICode === element.KPICode &&
            rowNode.data.Type === 'Glidepath'
          ) {
            const kpi = this.scoreCardGridApi.getRowNode(rowNode.id);
            const value = this.scoreCardGridApi.getValue(col, kpi);
            analysisLabel.push(col);
            analysisDataTarget.push(
              value ? parseFloat(value.replace(`(${element.UoM})`, '')) : 0
            );
          }
          if (
            rowNode.data.KPICode === element.KPICode &&
            rowNode.data.Type === 'Actual'
          ) {
            const kpi = this.scoreCardGridApi.getRowNode(rowNode.id);
            const value = this.scoreCardGridApi.getValue(col, kpi);
            if (analysisLabel.indexOf(col) !== -1) {
            } else {
              analysisLabel.push(col);
            }
            analysisData.push(
              value ? parseFloat(value.replace(`(${element.UoM})`, '')) : 0
            );
          }
        });
      }

      this.options = {
        title: {
          text: title,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: analysisLabel,
        },
        yAxis: {},
        tooltip: {
          valueDecimals: 2,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: (event) => {
                  const key = {
                    meetingDefId: this.selectedMeeting.meetingId,
                  };
                  this.meetingService
                    .getMeetingAction(key)
                    .subscribe((data) => {
                      for (var d of data) {
                        if (d.newtargetdate != null && d.newtargetDate != '') {
                          d.newtargetdate = moment(d.newtargetdate).format(
                            'DD/MM/YYYY HH:mm:ss'
                          );
                        }
                        if (
                          d.completiondate != null &&
                          d.completiondate != ''
                        ) {
                          d.completiondate = moment(d.completiondate).format(
                            'DD/MM/YYYY HH:mm:ss'
                          );
                        }
                      }
                      this.getUserList();
                      this.rowData1 = data;
                      this.MatrixForm.get('meetingDefName')?.setValue(
                        this.selectedMeeting.meetingName
                      );
                      this.selectmeeting = this.MatrixForm.get(
                        'meetingDefName'
                      )?.setValue(this.selectedMeeting.meetingName);
                      this.MatrixForm.get('meetingDefId')?.setValue(
                        this.selectedMeeting.meetingId
                      );
                      this.kpiId = kpiid;
                      this.nameKpi = title;
                      this.MatrixForm.get('kpi')?.setValue(title);
                      this.openModal();
                    });
                },
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y;
              },
            },
          },
        },
        series: [
          {
            type: 'column',
            name: this.langObj ? this.langObj?.Target : 'Target',
            data: analysisDataTarget,
            color: '#10ce9c',
          },
          {
            type: 'column',
            name: this.langObj ? this.langObj?.Actual : 'Actual',
            data: analysisData,
            color: 'blue',
          },
        ],
      };
      this.options2 = {
        title: {
          text: title,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: analysisLabel,
        },
        yAxis: {},
        tooltip: {
          valueDecimals: 2,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: (event) => {
                  const key = {
                    meetingDefId: this.selectedMeeting.meetingId,
                  };
                  this.meetingService
                    .getMeetingAction(key)
                    .subscribe((data) => {
                      for (var d of data) {
                        if (d.newtargetdate != null && d.newtargetDate != '') {
                          d.newtargetdate = moment(d.newtargetdate).format(
                            'DD/MM/YYYY HH:mm:ss'
                          );
                        }
                        if (
                          d.completiondate != null &&
                          d.completiondate != ''
                        ) {
                          d.completiondate = moment(d.completiondate).format(
                            'DD/MM/YYYY HH:mm:ss'
                          );
                        }
                      }
                      this.getUserList();
                      this.rowData1 = data;
                      this.MatrixForm.get('meetingDefName')?.setValue(
                        this.selectedMeeting.meetingName
                      );
                      this.selectmeeting = this.MatrixForm.get(
                        'meetingDefName'
                      )?.setValue(this.selectedMeeting.meetingName);
                      this.MatrixForm.get('meetingDefId')?.setValue(
                        this.selectedMeeting.meetingId
                      );
                      this.kpiId = kpiid;
                      this.nameKpi = title;
                      this.MatrixForm.get('kpi')?.setValue(title);
                      this.openModal();
                    });
                },
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y;
              },
            },
          },
        },
        series: [
          {
            type: 'line',
            name: this.langObj ? this.langObj?.Target : 'Target',
            data: analysisDataTarget,
            color: '#10ce9c',
          },
          {
            type: 'line',
            name: this.langObj ? this.langObj?.Actual : 'Actual',
            data: analysisData,
            color: 'blue',
          },
        ],
      };
      let chart = new Chart(this.options);
      let chart2 = new Chart(this.options2);
      chartObj.chartOption = chart;
      chartObj2.chartOption = chart2;
      this.chartArray.push(chartObj);
      this.chartArrayForAnalysis.push(chartObj2);
    });
  }

  //calledByModal set to true when a call is initiated through KPI Comparison modal date range
  onClickParamterComparsion(calledByModal) {
    setTimeout(() => {
      var chartDatetimeEl = document.getElementsByClassName(
        'highcharts-input-group'
      )[0] as HTMLElement;
      if (!(chartDatetimeEl as HTMLElement).classList.contains('d-none')) {
        (chartDatetimeEl as HTMLElement).classList.add('d-none');
      }
    }, 800);
    const selectedRows = this.scoreCardGridApi.getSelectedRows();
    this.spinnerButtonOptions1.active = true;
    // tslint:disable-next-line:max-line-length
    if (!calledByModal) {
      this.KPIComparisonForm.get('fromDate').setValue(this.range.value.fromDate);
      this.KPIComparisonForm.get('toDate').setValue(this.range.value.toDate);
      this.dialog.open(this.visibleParameterComparison, {
        panelClass: 'custom-dialog-container',
        width: '95vw !important',
        height: '100vw',
        minWidth: '95vw',
        maxHeight: '100vh',
      });
    }

    const seletedRowsUOm = selectedRows.map(
      (value: any) => value.UoM
    );
    this.uniqueUoms = ['ALL', ...new Set(seletedRowsUOm)];
    this.individualUom = [];
    this.individualUom = this.uniqueUoms[0];
    this.comparisonMainData();

    this.spinnerButtonOptions1.active = false;
  }

  onModelUpdated() {
    this.autoSizeAll();
  }

  autoSizeAll() {
    var allColIds = this.scoreCardGridOptions.columnApi
      .getAllColumns()
      .map((column) => column.colId);
    this.scoreCardGridOptions.columnApi.autoSizeColumns(allColIds);

    // If you want to resize all columns
    this.scoreCardGridOptions.columnApi.autoSizeAllColumns();

  }

  onFinancialGapRangeChange() {
    const { fromDate, toDate } = this.rangeFinacialGap.value;
    this.getFinancialGapList(fromDate, toDate);
    this.KPIAnalysisForm.patchValue({
      fromDate: moment(
        new Date(new Date(fromDate).setHours(0, 0, 0, 0))
      ).format('YYYY-MM-DD HH:mm:ss'),
      toDate: moment(new Date(new Date(toDate).setHours(0, 0, 0, 0))).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
    });
  }

  onFirstDataRendered(params: {
    columnApi: { autoSizeAllColumns: () => void };
  }) {
    params.columnApi.autoSizeAllColumns();
  }

  GetRawMaterialParameters(element: any): void {
    this.individualUom = [];
    this.individualUom = element;
    this.changeComparisonData();
  }

  changeComparisonData(): void {
    const array: any[] = [];
    const yAxisArr: any[] = [];
    const selectedRows = this.scoreCardGridApi.getSelectedRows();
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name'] && o.UoM == el.UoM
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'], UoM: el.UoM },
      ];
    }, []);
    const seletedGroups = selectedRows.map(
      (value: any) => value.ParameterGroup
    );
    const uniqueGroups = [...new Set(seletedGroups)];
    let values = '';
    let valueNames = [];
    const matchingKPINames = [];
    const latestdate = new Date(this.range.value.toDate).setHours(
      23,
      59,
      59,
      59
    );
    distinctRows.forEach(item => {
      if (item.UoM === this.individualUom) {
        matchingKPINames.push(item['KPI Name']);
      }
    });
    if (this.individualUom == 'ALL') {
      values = distinctRows.map((value: any) => value.KPICode).toString();
      valueNames = distinctRows.map((value: any) => value['KPI Name']);
      for (let i = 0; i < valueNames.length; i++) {
        const dataArr = [];
        for (let j = 0; j < this.compData.length; j++) {
          if (valueNames[i] === this.compData[j].KPIName) {
            dataArr.push({
              x: new Date(this.compData[j].Date).getTime(),
              y: this.compData[j].Target ? parseFloat(this.compData[j].Target) : 0,
              label: valueNames[i],
            });
          }
        }
        let negativeArr = [];
        negativeArr = dataArr.map((c) => c.y);
        const obj = {
          tooltip: {
            valueDecimals: 2,
          },
          name: valueNames[i],
          type: 'line',
          data: dataArr,
          color: this.getRandomHexColor(),
          yAxis: i,
        };
        array.push(obj);
        yAxisArr.push({
          lineWidth: 1,
          visibe: true,
          opposite: false,
          title: {
            text: valueNames[i],
            style: {
              color: this.highChartsColor[i],
            },
          },
          labels: {
            style: {
              color: this.highChartsColor[i],
            },
          },
        });
      }
      yAxisArr.forEach((yAxis) => {
        yAxis.visible = false;
      });
      array.forEach(element => {
        element.yAxis = 0
      });
      this.stock = new StockChart({
        chart: {
          height: 730,
        },
        plotOptions: {
          series: {
            turboThreshold: 50000000,
            events: {
              hide(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
              show(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: uniqueGroups.toString(),
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        yAxis: [
          {
            "lineWidth": 1,
            "opposite": false,
            "title": {
              "text": "",
            },
            "visible": true
          },
        ],
        series: array,
      });
    }
    else {
      const valuesNotInArray2 = distinctRows.filter(x => x.UoM == this.individualUom);
      values = valuesNotInArray2.map((value: any) => value.KPICode).toString();
      valueNames = matchingKPINames;

      const filteredArr = this.compData.filter(obj => obj.UOM === this.individualUom);
      for (let i = 0; i < valueNames.length; i++) {
        const dataArr = [];
        for (let j = 0; j < filteredArr.length; j++) {
          if (valueNames[i] === filteredArr[j].KPIName) {
            dataArr.push({
              x: new Date(filteredArr[j].Date).getTime(),
              y: filteredArr[j].Target ? parseFloat(filteredArr[j].Target) : 0,
              label: valueNames[i],
            });
          }
        }
        let negativeArr = [];
        negativeArr = dataArr.map((c) => c.y);
        const obj = {
          tooltip: {
            valueDecimals: 2,
          },
          name: valueNames[i],
          type: 'line',
          data: dataArr,
          color: this.getRandomHexColor(),
          yAxis: i,
        };
        array.push(obj);
        yAxisArr.push({
          lineWidth: 1,
          visibe: true,
          opposite: false,
          title: {
            text: valueNames[i],
            style: {
              color: this.highChartsColor[i],
            },
          },
          labels: {
            style: {
              color: this.highChartsColor[i],
            },
          },
        });
      }
      yAxisArr.forEach((yAxis) => {
        yAxis.visible = false;
      });
      array.forEach(element => {
        element.yAxis = 0
      });
      this.stock = new StockChart({
        chart: {
          height: 730,
        },
        plotOptions: {
          series: {
            turboThreshold: 50000000,
            events: {
              hide(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
              show(): any {
                this.yAxis.update({
                  visible: false,
                });
              },
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: uniqueGroups.toString(),
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        yAxis: [
          {
            "lineWidth": 1,
            "opposite": false,
            "title": {
              "text": "",
            },
            "visible": true
          },
        ],
        series: array,
      });
    }
  }

  comparisonMainData(): void {
    let values = '';
    const selectedRows = this.scoreCardGridApi.getSelectedRows();
    const distinctRows = selectedRows.reduce((prev, el) => {
      if (
        prev.some(
          (o) => o.KPICode == el.KPICode && o['KPI Name'] == el['KPI Name'] && o.UoM == el.UoM
        )
      )
        return prev;
      return [
        ...prev,
        { KPICode: el.KPICode, KPIId: el.KPIId, 'KPI Name': el['KPI Name'], UoM: el.UoM },
      ];
    }, []);
    const latestdate = new Date(this.range.value.toDate).setHours(
      23,
      59,
      59,
      59
    );
    values = distinctRows.map((value: any) => value.KPICode).toString();
    let fromDate = true ? this.KPIComparisonForm.value.fromDate : this.range.value.fromDate;
    let toDate = true ? moment(this.KPIComparisonForm.value.toDate).utc() : moment(latestdate).utc();
    const obj = {
      frequencyName:
        this.MeetingMatrixFrequencyForm.value.frequency.frequencyName,
      plantId: this.MeetingMatrixForm.get('plant')?.value,
      fromDate,
      toDate,
      kpiId: values,
    };
    this.spinner.show();
    this.meetingService.getStockChartData(obj).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.compData = data;
        this.changeComparisonData();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      });
  }

  navigateToParameterAnalysis(): void {
    this.router.navigate([]).then(result => {
      window.open(`/Dashboard/ParameterAnalysis`, '_blank');
    });
  }

  createAddActionPlanForm() {
    this.addActionForm = this.fb.group({
      id: [''],
      action: ['', Validators.required],
      actionType: ['', Validators.required],
      assignTo: ['', Validators.required],
      targetDate: ['', Validators.required],
      kpi: [''],
    });
  }

  onChangeCompleteAdd(): void {
    if (this.addActionForm.value.completed === 'Yes') {
      this.disableVerification = false;
      this.addActionForm.get('verification').setValue('');
    }
  }

  readFileAdd(event: any, type: any): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        let src = reader.result as string;
        if (type === 'pic') {
          this.imageAddActionPlan = event.target.files[0];
          this.addImageSrc = src;
        } else {
          this.fileAddActionPlan = event.target.files[0];
          this.addFileSrc = src;
        }
      };
    }
  }

  deleteFileAddActionModal(type: any): void {
    if (type === 'pic') {
      this.addImageSrc = null;
      this.imageAddActionPlan = new File([''], 'filename');;
    } else {
      this.fileAddActionPlan = new File([''], 'filename');
      this.addFileSrc = null;
    }
  }

  openFileAddActionPlan(type?: any): void {
    if (type === 'pic') {
      this.imageInputAdd.nativeElement.click();
    } else {
      this.fileInputAdd.nativeElement.click();
    }
  }

  addActionPlan(): void {
    this.targetDate = this.addActionForm.value.targetDate;
    this.targetDate = new Date(this.targetDate);
    this.targetDate.setHours(new Date().getHours());
    this.targetDate.setMinutes(new Date().getMinutes());
    this.targetDate.setSeconds(new Date().getSeconds());
    let status =
      this.addActionForm.value.targetDate &&
        !this.addActionForm.value.revisedDate
        ? 'InProgress'
        : this.addActionForm.value.targetDate &&
          this.addActionForm.value.revisedDate
          ? 'Delayed'
          : 'Open';
          if (this.addActionForm.value.verification === 'Approved') {
            status = 'Closed';
            // fiveWhy.ClosedDate = new Date();
          } else if (this.addActionForm.value.verification === 'Disapproved') {
            status = 'ReOpened';
            // fiveWhy.ActionCompleted = 'No';
            this.addActionForm.get('completed').setValue('No');
          }

    const key = {
      actionName: this.addActionForm.value.action,
      actionType: this.addActionForm.value.actionType,
      responsible: this.addActionForm.value.assignTo,
      targetDate: this.targetDate,
      status: status,
      userId: JSON.parse(localStorage.user).UserId,
      plantId: this.MeetingMatrixForm.value.plant,
      meetingStatus: this.meetingStarted === true ? 1 : 0,
      meetingId:  this.selectedMeeting.meetingId,
      kpiId: this.currentKPIId
    };
    this.spinner.show();
    this.actionplanService.addActionPlan(key).subscribe(({
      next: (res: any) => {
        this.spinner.hide();
        if (res?.data[0]?.message === 'Add Successfully') {
          this.toaster.success('Sucess', 'Added Successfully');
          this.getOverallMeetingActions();
          this.addActionForm.reset();
          this.addActionForm.get('actionType')?.setValue('Corrective');
          this.addActionForm.get('kpi')?.setValue(this.KPIName);
        }
      },
      error: ({ error }) => {
        this.spinner.hide();
        this.toaster.error('Error', '');
      }
    }));
  }

  getParticipantsOnPlant() {
    let data = {
      EGId: this.MeetingMatrixForm.value.group,
      PlantId: this.MeetingMatrixForm.value.plant,
    };

    this.commonService.getPeopleMasterData(data).subscribe(
      (response: any) => {
        if (!response.HasError) {
          // this.orgMachineryList.map((machinery: any) => this.machineryList.push([...this.mList.machineryList]));
          this.participantsByPlants = response.employeeInfoList;
          this.participantsByPlants.map(
            (user: any) =>
            (user.FullName =
              user.FirstName.trim() + ' ' + user.lastName.trim())
          );
          this.userList = this.participantsByPlants.slice();
        }
      },
      (err: any) => {

      }
    );
  }

  getOverallMeetingActions() {
    const data = {
      meetingId: this.selectedMeeting.meetingId,
    };
    this.meetingService.getAllMeetingActions(data).subscribe(
      (response) => {
        this.overallMeetingActions = response;
      },
      (err: any) => {

      }
    );
  }
  getOverallMeetingForUdpate() {
    const data = {
      meetingId: this.selectedMeeting.meetingId,
    };
    this.meetingService.getAllMeetingActions(data).subscribe(
      (response) => {
        // this.overallMeetingActions = response;
        const updatedRecord = response.find(c=>c.ID === this.data.ID);
        // const rowNode = this.gridApiActions.getSelectedNodes();
        if(this.actionSelectedRow){
          this.actionSelectedRow.setData(updatedRecord);
        }
        
      },
      (err: any) => {

      }
    );
  }

  editRow(params: any): void {
    // this.headerId = params?.data?.HeaderId;
    if (params) {
      this.data = [];
      this.ImageSave = null;
      this.fileSave = null;
      this.closefile = null;
      // this.currentNodeDate = params.node.data.ObservedDate;
      // this.currentAssigneeEmail = params.node.data.AssigneEmail;
      // this.currentResponsibleEmail = params.node.data.ParticpantEmails;
      this.data = params.node.data;
      this.ModuleName = params.node.data.Module;
      this.UniqueCode = params.node.data.UniqueCode;
      this.editActionPlanForm.get('actions').setValue(params.node.data.Action);
      this.editActionPlanForm
        .get('assignTo')
        .setValue(params.node.data.AssignTo);
      this.editActionPlanForm
        .get('completed')
        .setValue(params.node.data.ActionCompleted);
      this.editActionPlanForm.get('remarks').setValue(params.node.data.Remarks);
      this.editActionPlanForm.get('remarks').setValue(params.node.data.Remarks);
      this.editActionPlanForm
        .get('NewAssignee')
        .setValue(params.node.data.NewAssigneeId);
      if (params.node.data.ActionCompleted === 'No') {
        this.disableVerification = true;
      }
      if (params.node.data.Verification === 'Disapproved') {
        this.disableNewAssigne = false;
      }
      this.editActionPlanForm
        .get('verification')
        .setValue(params.node.data.Verification);
        this.editActionPlanForm
        .get('rootcause')
        .setValue(params.node.data.RootCause);
      this.editActionPlanForm
        .get('actionType')
        .setValue(params.node.data.ActionType);
      if (params.node.data.RevisedDate !== null) {
        this.editActionPlanForm
          .get('revisedDate')
          .setValue(
            this.datePipe.transform(
              new Date(params.node.data.RevisedDate),
              'yyyy-MM-dd'
            )
          );
      }
      if (params.node.data.TargetDate !== null) {
        this.editActionPlanForm
          .get('targetDate')
          .setValue(
            this.datePipe.transform(
              new Date(params.node.data.TargetDate),
              'yyyy-MM-dd'
            )
          );
      }
      this.targetDate = params.node.data.TargetDate;

      if (params.data.UploadFile) {
        this.data.UploadFile = params.data.UploadFile;
      }
      // this.editActionPlanForm.get('targetDate').setValue(params.data.TargetDate) ;
      this.currentDialog =  this.dialog.open(this.updateActionModal, { height: 'auto', width: '90%' });
    }
  }

  getMinDate(type: any): any {
    if (type === 'Revised') {
      const date = this.data.RevisedDate
        ? this.data.RevisedDate
        : this.data.TargetDate;
      const currentDate = new Date(date);

      const minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate() + 1)
      );

      return (current: Date): boolean =>
        differenceInCalendarDays(current, minDateStartFrom) < 0;
    } else if (type === 'Target') {
      const currentDate = new Date();
      const minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate())
      );

      return (current: Date): boolean =>
        differenceInCalendarDays(current, minDateStartFrom) < 0;
    }
  }

  targetChange(): void {
  }

  onChangeComplete(): void {
    if (this.editActionPlanForm.value.completed === 'Yes') {
      this.disableVerification = false;
      this.editActionPlanForm.get('verification').setValue('');
    }
  }

  onChangeVerification(): void {
    if (this.editActionPlanForm.value.verification === 'Disapproved') {
      this.disableNewAssigne = false;
    } else if (this.editActionPlanForm.value.verification !== 'Disapproved') {
      this.disableNewAssigne = true;
    }
    if (this.editActionPlanForm.value.verification === 'Approved') {
      this.showinput = true;
    } else {
      this.showinput = false;
    }
  }

  imageZoomModal(): void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      height: '90%',
      width: '90%',
      data:
        this.data.FileSrc || environment.O3CoreApiImageUrl + this.data.UploadFile,
    });
    // this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  deleteFile(type: any): void {
    if (type === 'pic') {
      this.data.FileSrc = '';
      this.data.UploadFile = '';
      this.ImageSave = new File([''], 'filename');
    } else {
      this.data.DocSrc = '';
      this.data.UploadDoc = '';
      this.fileSave = new File([''], 'filename');
    }
  }

  openFile(type?: any): void {
    if (type === 'pic') {
      this.fileInput.nativeElement.click();
    } else {
      this.fileInputFile.nativeElement.click();
    }
  }

  readFile(event: any, type: any): void {
    this.imageFile = event.target.files[0];
    if (type === 'pic') {
      this.data.UploadFile = this.imageFile;
      this.ImageSave = event.target.files[0];
    } else {
      this.data.UploadDoc = this.imageFile;
      this.fileSave = event.target.files[0];
    }
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        if (type === 'pic') {
          this.data.FileSrc = this.imageSrc;
        } else {
          this.data.DocSrc = this.imageSrc;
        }
      };
    }
  }

  uploadedFile(event: any): void {
    if (event.target.files.length > 0) {
      this.closefile = event.target.files[0];
    }
  }

  get af() {
    return this.editActionPlanForm.controls;
  }

  downloadPDF() {
    // [href]=" yData.DocSrc || blobStorageUrl + yData.UploadDoc"
    if (this.data.DocSrc) {
      const base64 = this.data.DocSrc;
      var a = document.createElement('a'); //Create <a>
      a.href = base64; //Image Base64 Goes here
      a.target = '_blank';
      a.download = 'file.pdf'; //File name Here
      a.click();
    } else {
      var a = document.createElement('a'); //Create <a>
      a.href = environment.O3CoreApiImageUrl + this.data.UploadDoc; //Image Base64 Goes here
      a.download = 'file.pdf';
      a.target = '_blank'; //File name Here
      a.click();
    }
  }

  UpdateActionPlan(): void {
    this.Status = '';
    this.Status =
      this.editActionPlanForm.value.targetDate &&
        !this.editActionPlanForm.value.revisedDate
        ? 'InProgress'
        : this.editActionPlanForm.value.targetDate &&
          this.editActionPlanForm.value.revisedDate
          ? 'Delayed'
          : 'Open';

    if (this.editActionPlanForm.value.targetDate) {
      this.targetDate = this.editActionPlanForm.value.targetDate;
      this.targetDate = new Date(this.targetDate);
      this.targetDate.setHours(new Date().getHours());
      this.targetDate.setMinutes(new Date().getMinutes());
      this.targetDate.setSeconds(new Date().getSeconds());
    }
    if (this.editActionPlanForm.value.revisedDate) {
      this.RevisedDate = this.editActionPlanForm.value.revisedDate;
      this.RevisedDate = new Date(this.RevisedDate);
      this.RevisedDate.setHours(new Date().getHours());
      this.RevisedDate.setMinutes(new Date().getMinutes());
      this.RevisedDate.setSeconds(new Date().getSeconds());
    }
    if (this.editActionPlanForm.value.completed === 'Yes') {
      this.Status = 'Complete';
      var templateBody = null;
      var plantName = null;

      var plant = this.plants.find(
        (x) => x.DeptId == this.MeetingMatrixForm.value.plant
      );
      plantName = plant.DeptDesc;
      var date = null;
      var user = this.userList.find(
        (x) => x.EmpInfo_Id == this.editActionPlanForm.value.assignTo
      );
      if (
        this.editActionPlanForm.controls.revisedDate.value >
        this.editActionPlanForm.controls.targetDate.value
      ) {
        date = this.editActionPlanForm.controls.revisedDate.value;
      } else {
        date = this.editActionPlanForm.controls.targetDate.value;
      }
      if (this.editActionPlanForm.controls.verification.value == 'Approved') {
        templateBody =
          `<div style="font-family: Helvetica; display: flex; flex-direction: column;">
        <div style="display: flex;background-color:#1d4ed8; color: white; padding: 2rem">
            <h2 style="margin-right: auto">O3 SMART MANUFACTURING</h2>
            <img src="https://res.cloudinary.com/durdmvneg/image/upload/v1629181792/logo-full_ku1lp4.png" style="display: block; border: 0px; object-fit:contain ;" />
        </div>
        <h2 style="margin-left: auto; margin-right: auto; margin-top: 1rem; font-weight: 800">Action Plan Detail</h2>
        <div style="background-color: #f3f4f6; padding: 1rem; display: flex;">
            <div style="width: 20%;font-weight: 800;">Plant: </div><div style="width: 80%;font-weight: 800;">` +
          plantName +
          `</div>
        </div>
        <table style="margin-top: 1.5rem;">
            <tr>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Action Plan</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Created Date</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Target Date</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Responsible</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Status</th>
            </tr>
            <tr>
                <td style='text-align: center'>` +
          this.editActionPlanForm.controls.actions.value +
          `</td>
                <td style='text-align: center'>` +
          moment(this.currentNodeDate).format('DD/MM/YYYY hh:mm:ss') +
          `</td>
                <td style='text-align: center'>` +
          moment(date).format('DD/MM/YYYY hh:mm:ss') +
          `</td>
                <td style='text-align: center'>` +
          user.FullName +
          `</td>
                <td style='text-align: center'>Closed</td>
            </tr>
        </table>
    </div>`;
      } else {
        templateBody =
          `<div style="font-family: Helvetica; display: flex; flex-direction: column;">
        <div style="display: flex;background-color:#1d4ed8; color: white; padding: 2rem">
            <h2 style="margin-right: auto">O3 SMART MANUFACTURING</h2>
            <img src="https://res.cloudinary.com/durdmvneg/image/upload/v1629181792/logo-full_ku1lp4.png" style="display: block; border: 0px; object-fit:contain ;" />
        </div>
        <h2 style="margin-left: auto; margin-right: auto; margin-top: 1rem; font-weight: 800">Action Plan Detail</h2>
        <div style="background-color: #f3f4f6; padding: 1rem; display: flex;">
            <div style="width: 20%;font-weight: 800;">Plant: </div><div style="width: 80%;font-weight: 800;">` +
          plantName +
          `</div>
        </div>
        <table style="margin-top: 1.5rem;">
            <tr>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Action Plan</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Created Date</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Target Date</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Responsible</th>
                <th style="background-color:#e7e5e4; padding: 0.8rem">Status</th>
            </tr>
            <tr>
                <td style='text-align: center'>` +
          this.editActionPlanForm.controls.actions.value +
          `</td>
                <td style='text-align: center'>` +
          moment(this.currentNodeDate).format('DD/MM/YYYY hh:mm:ss') +
          `</td>
                <td style='text-align: center'>` +
          moment(date).format('DD/MM/YYYY hh:mm:ss') +
          `</td>
                <td style='text-align: center'>` +
          user.FullName +
          `</td>
                <td style='text-align: center'>Completed</td>
            </tr>
        </table>
    </div>`;
      }
      var emailParam = {
        Body: templateBody,
        ToEmailAddress: this.currentAssigneeEmail,
        CCEmails: this.currentResponsibleEmail,
        Subject: 'Action Completion',
      };
      this.actionplanService.sendMail(emailParam).subscribe(
        (res) => {
          // this.toaster.success("Mail sent", "Success");
        },
        ({ error }) => {
          this.toaster.error('Cannot Send Mail', 'Error');
        }
      );
    }

    if (this.editActionPlanForm.value.verification === 'Approved') {
      this.Status = 'Closed';
      // fiveWhy.ClosedDate = new Date();
    } else if (this.editActionPlanForm.value.verification === 'Disapproved') {
      this.Status = 'ReOpened';
      // fiveWhy.ActionCompleted = 'No';
      this.editActionPlanForm.get('completed').setValue('No');
    }

    const formdata = new FormData();
    formdata.append('file', this.fileSave);
    formdata.append('image', this.ImageSave);
    formdata.append('closefile', this.closefile);
    const key = {
      actionId: this.data.ID,
      actionName: this.editActionPlanForm.value.actions,
      actionType: this.editActionPlanForm.value.actionType,
      responsible: this.editActionPlanForm.value.assignTo,
      targetDate: this.targetDate ? this.targetDate : null,
      revisedDate: this.RevisedDate ? this.RevisedDate : null,
      ActionCompleted: this.editActionPlanForm.value.completed,
      Verification: this.editActionPlanForm.value.verification,
      remarks: this.editActionPlanForm.value.remarks,
      NewAssignee: this.editActionPlanForm.value.NewAssignee
        ? this.editActionPlanForm.value.NewAssignee
        : null,
      status: this.Status,
      userId: JSON.parse(localStorage.user).UserId,
      rootCause: this.editActionPlanForm.value.rootcause
    };
    formdata.append('values', JSON.stringify(key));
    this.actionplanService.editActionPlan(formdata).subscribe((res: any) => {
      if (res[0].Column1 === 'Updated Successfully') {
        this.toaster.success('Success', `Updated Sucessfully `);
        this.getOverallMeetingForUdpate();
        this.currentDialog.close();
        this.showinput = false;
      }
    });
    // }
  }


  createEditActionPlanForm() {
    this.editActionPlanForm = this.fb.group({
      id: [''],
      remarks: [''],
      closefile: [''],
      revisedDate: [''],
      actions: [''],
      assignTo: [''],
      completed: ['No'],
      verification: [''],
      actionType: [''],
      targetDate: [''],
      NewAssignee: [''],
      rootcause: ['']
    });
  }
  setWeekValue(weekDay: number): void {
    if (weekDay == undefined || weekDay == null || Number.isNaN(weekDay))
      weekDay = 0
    sessionStorage.removeItem('weekDay');
    sessionStorage.setItem('weekDay', weekDay.toString());
    this.datepickerService.getFirstDayOfWeek()
  }


  decreaseDecimalPlaces():any{
    if(this.decimalPlaces>0){
    this.decimalPlaces = this.decimalPlaces-1;
  }
    this.createScoreCardData(this.ScoreCardData);
  }
  IncreaseDecimalPlaces():any{
    if(this.decimalPlaces<4)
    {
    this.decimalPlaces = this.decimalPlaces+1;
    }
    this.createScoreCardData(this.ScoreCardData);
  }
}

function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}

function GenderCellRenderer(): void { }

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};


