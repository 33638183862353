<div class="machine-parameter-content">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title"> Material Definition </h1>
    </div>
  </div>
<mat-card class="p-mt-0 p-pt-0" style="padding-top: 5px !important;" >
  <form [formGroup]="rawMaterialForm">
    <div class="content-top-row" style="margin-left: 5px;">
    <mat-form-field appearance="fill" class="field-fill-custom-master">
      <mat-label class="placeholder-title">Select Group</mat-label>
      <mat-select formControlName="group" panelClass="testClass" name="Group" (selectionChange)="getPlantData()">
        <mat-option *ngFor="let Group of groupList" [value]="Group.TypeId">
          {{Group.TypeName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="field-fill-custom-master styling-class">
      <mat-label class="required">Select Plant</mat-label>
        <mat-select panelClass="testClass" name="plant" formControlName="plant" (selectionChange)="GetAllRawMaterials()">
          <mat-option *ngFor="let plant of plantList" [value]="plant.DeptId">{{plant.DeptDesc}}</mat-option> 
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="field-fill-custom-master styling-class">
      <mat-label>Enter Material Code</mat-label>
      <input matInput formControlName="code">
    </mat-form-field>
    <mat-form-field appearance="fill" class="field-fill-custom-master styling-class">
      <mat-label>Enter Material Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <button type="button" (click)="resetRawMaterial()" style="margin-left: 20px;" class="icon-btn" pTooltip="Reset" tooltipPosition="top">
      <img src="assets/images/refresh1.png" alt="" width="20px" height="20px">
    </button>
    <button type="submit" class="icon-btn" style="margin-left: 10px" pTooltip="Save" tooltipPosition="top"
     (click)="AddRawMaterial()" *ngIf="mode && IsWrite">
    <img src="assets/images/save.png" alt="" width="20px" height="20px">
    </button>
    <button type="submit" class="icon-btn" style="margin-left: 10px;" pTooltip="Update" tooltipPosition="top"
    (click)="UpdateRawMaterial()" *ngIf="!mode && IsWrite">
    <img src="assets/images/edit.png" alt="" width="20px" height="20px">
    </button>
    <!-- <button mat-mini-fab mat-button mat-dialog-close color="accent" 
    pTooltip="Add Configuration Header" tooltipPosition="top" style="margin-left: 16px; margin-top: 10px;" (click)="openConfigurationModal()">
    <i class="fa fa-plus" style="color: #fff"></i>
    </button> -->
    </div>
  </form>
  <mat-card-content style="margin-top: 20px;">
    <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
      <p style="font-size: 20px; color: #050a4f;">Fetching Tools Data...</p>
    </ngx-spinner>
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
      <ag-grid-angular
      style="height: 25vw;"
      class="ag-theme-alpine"
      [rowData]="rowData"
      [columnDefs]="columnsDefs"
      [pagination]="true"
      [paginationPageSize]="5"
      (cellClicked)="onCellClicked($event)"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      >
      </ag-grid-angular>
    </div>
  </mat-card-content>
</mat-card>
</div>


<!--Raw Material Properties Popup-->
<ng-template #rawMaterialPropertiesTemplate>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>Material Parameters</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <div class="model-body">
      <form [formGroup]="rawMaterialPropertiesForm">
        <div class="form-group">

          <mat-form-field appearance="fill" class="field-fill styling-class">
            <mat-label>Enter Parameter Name</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>

          <mat-form-field appearance="fill" class="field-fill styling-class">
            <mat-label>Select Unit Type</mat-label>
            <mat-select formControlName="unit" (selectionChange)="changeUnitType($event)">
              <mat-option *ngFor="let unit of paramUnitList" [value]="unit.paramUnitId">
                {{ unit?.paramUnitName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="field-fill styling-class">
            <mat-label>Enter Normal Range</mat-label>
            <input matInput formControlName="range">
          </mat-form-field>
          <mat-form-field appearance="fill" class="field-fill styling-class">
            <mat-label>Select Property Type</mat-label>
            <mat-select formControlName="type" (selectionChange)="changeType($event)">
              <mat-option *ngFor="let type of TypeList" [value]="type.name">
                {{ type?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-mini-fab mat-button color="accent" (click)="openModal()"
            pTooltip="Add New Group" tooltipPosition="top" style="margin-left: 5px;">
            <i class="fa fa-plus" style="color: #fff"></i>
          </button>

          <button type="button" (click)="resetRawMaterialParameters()" style="margin-left: 10px;" class="icon-btn" pTooltip="Reset" tooltipPosition="top">
            <img src="assets/images/refresh1.png" alt="" width="20px" height="20px">
          </button>

          <button type="submit" class="icon-btn" style="margin-left: 10px;" pTooltip="Update" tooltipPosition="top"
            (click)="UpdateRawMaterialsParameter()" *ngIf="!materialMode && IsWrite">
            <img src="assets/images/edit.png" alt="" width="20px" height="20px">
          </button>

          <button type="submit" class="icon-btn" style="margin-left: 0px;" pTooltip="Save" tooltipPosition="top"
            (click)="AddRawMaterialsParameter()" *ngIf="materialMode && IsWrite">
            <img src="assets/images/save.png" alt="" width="20px" height="20px">
          </button>
        </div>
      </form>
      <mat-card-content>
        <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
          <p style="font-size: 20px; color: #050a4f;">Fetching Tools Data...</p>
        </ngx-spinner>
        <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
          <ag-grid-angular
          style="height: 23vw;"
          class="ag-theme-alpine"
          [rowData]="templateRowData"
          [columnDefs]="templateColumnsDefs"
          [pagination]="true"
          [paginationPageSize]="5"
          (cellClicked)="templateOnCellClicked($event)"
          [gridOptions]="templateGridOptions"
          (gridReady)="templateOnGridReady($event)"
          >
          </ag-grid-angular>
        </div>
      </mat-card-content>
    </div>
</ng-template>


<!--Configuration Header Popup-->
<ng-template #configurationHeaderTemplate>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Configuration Header</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <form [formGroup]="configurationHeaderForm">
    <mat-form-field appearance="fill" class="field-fill styling-class">
      <mat-label>Select Header Property</mat-label>
      <mat-select formControlName="type" (selectionChange)="changeRMInspectionMasterData($event)" >
        <mat-option *ngFor="let type of RMDataArray" [value]="type.rmMasterId">
          {{ type?.rmName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
    <mat-chip-list>
      <mat-chip  *ngFor="let type of updatedRMDataArray" [value]="type.name"
        [removable]="true" (removed)="removeRMInspectionMasterData($event)">
        {{ type?.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <div class="form-group">
      <button type="submit" class="icon-btn" style="margin-left: 750px; margin-top: 90px;" pTooltip="Save" tooltipPosition="top"
            (click)="AddRawMaterialInspectionHeader()" *ngIf="materialMode && IsWrite">
            <img src="assets/images/save.png" alt="" width="20px" height="20px">
          </button>
    </div>
</ng-template>


<!--Group Popup-->
<ng-template #displayGroupModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Add Group</h2>
      <button type="button" mat-mini-fab mat-button color="warn" title="close" (click)="closeGroupPopup()">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <form [formGroup]="groupForm">
    <div class="form-group">
      <mat-form-field appearance="fill" class="field-fill styling-class" style="width: 280px;">
        <mat-label>Enter Group Name</mat-label>
        <input matInput formControlName="group">
      </mat-form-field>
      <button type="button" (click)="resetGroupName()" style="margin-left: 20px; margin-top: 0px;" class="icon-btn" pTooltip="Reset" tooltipPosition="top">
        <img src="assets/images/refresh1.png" alt="" width="20px" height="20px">
      </button>
      <button type="button" class="icon-btn" style="margin-left: 20px;" pTooltip="Save" tooltipPosition="top"
        (click)="AddGroupName($event)" *ngIf="materialMode && IsWrite">
        <img src="assets/images/save.png" alt="" width="20px" height="20px">
      </button>
    </div>
  </form>
</ng-template>