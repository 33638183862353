import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { UserManu } from 'src/app/models/userManu';
import { LicenseManager } from 'ag-grid-enterprise';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { QualityParameterLoggingService } from 'src/app/services/Quality-Parameter-Logging.service';
import { ProcessParameterLoginService } from 'src/app/services/Process-Parameter-Login.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { Router } from '@angular/router';
import { CheckboxRenderer } from 'src/app/CheckboxRenderer';
import { NgxSpinnerService } from 'ngx-spinner';
import { QualityParameterService } from 'src/app/services/quality-parameter.service';

@Component({
  selector: 'app-quality-parameter-login',
  templateUrl: './quality-parameter-login.component.html',
  styleUrls: ['./quality-parameter-login.component.scss'],
})
export class QualityParameterLoginComponent implements OnInit {
  columnDefs: any;
  rowData: any;
  EmptyData: any;

  rowSelection: any;
  isPlantGroupModelVisible = false;
  varUnitName!: string;
  processDataForm!: FormGroup;
  components!: any;
  actionPermission!: UserManu;
  gridApi: any;
  gridColumnApi: any;
  GroupList!: any[];
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  LineNode: any;
  UnitNode: any;
  UnitName!: string;
  unitid!: any;
  params!: any;
  check!: any;
  paginationPageSize!: any;

  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
      checkboxRenderer: CheckboxRenderer,
    },
  };
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;
  loading = false;
  maxCavity: any;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private qualityParameterLoggingService: QualityParameterLoggingService,
    private userService: UserManagementService,
    private spinner: NgxSpinnerService,
    private enterpriseGroupService: EnterPriseGroupService,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    public router: Router,
    private qualityParameterService: QualityParameterService
  ) {
    // LicenseManager.setLicenseKey('kazaz');

    this.columnDefs = [
      {
        field: 'isSelected',
        minWidth: 50,
        headerName: '',
        cellRenderer: 'checkboxRenderer',
      },

      // {
      //   field: 'isSelected', hide: 'true', sortable: true, editable: false, headerName: '', filter: true, resizable: true, wrapText: true,
      // },
      {
        field: 'isDeleted',
        hide: 'true',
        sortable: true,
        editable: false,
        headerName: '',
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        field: 'qplId',
        hide: 'true',
        sortable: true,
        editable: false,
        headerName: '',
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        // tslint:disable-next-line: max-line-length
        field: 'varDesc',
        sortable: true,
        editable: false,
        headerName: 'Description',
        filter: true,
        resizable: true,
        wrapText: true,
        minWidth: 300,
      },

      {
        field: 'autoManual',
        sortable: true,
        headerName: 'Auto/Manual',
        cellStyle: {
          color: 'black',
          'background-color': '#bdccdd',
        },
        filter: true,
        resizable: true,
        wrapText: true,
        editable: (params: any) =>
          params.data.isSelected !== false && this.IsWrite,
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Auto', 'Manual'],
        },
      },
      {
        // tslint:disable-next-line: max-line-length
        field: 'interval',
        sortable: true,
        cellStyle: {
          color: 'black',
          'background-color': '#bdccdd',
        },
        editable: (params: any) =>
          params.data.isSelected !== false && this.IsWrite,
        headerName: 'Interval (Mins)',
        filter: true,
        resizable: true,
        wrapText: true,
        minWidth: 300,
      },
      {
        field: 'cavity',
        headerName: 'Sample',
        editable: (params: any) =>
          params.data.isSelected !== false && this.IsWrite,
        sortable: true,
        cellStyle: {
          color: 'black',
          'background-color': '#bdccdd',
        },
      },
      {
        field: 'processType',
        sortable: true,
      },
    ];

    this.rowSelection = 'multiple';
  }
  parameterGroupList!: any[];

  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;
  ngOnInit(): void {
    this.processDataForm = this.fb.group({
      group: ['', Validators.required],
    });
    this.processDataForm = this.fb.group({
      UnitNameList: [''],
      parametergroup: ['', Validators.required],
      Group: ['', Validators.required],
      cavity: [''],
      processType: [''],
    });

    this.getGroups();
    this.GetMenuAccess();
    this.GetBreadcrumList();
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    const id = {
      value: 0,
    };

    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.masterList;
        this.processDataForm.get('Group')?.setValue(data.masterList[0].TypeId);
      },
      (error: { message: any }) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }

  // Icon
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  // close model
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  ResetData(): void {
    this.processDataForm.get('parametergroup')?.reset();
    this.UnitName = ' ';
    this.rowData = this.EmptyData;
  }

  getParameterGroups(): void {
    const Key = {
      puId: this.unitid,
    };
    this.qualityParameterLoggingService.getPlants(Key).subscribe(
      (res: any[]) => {
        this.parameterGroupList = res;
        // this.processDataForm.get('parametergroup')?.setValue(this.parameterGroupList[0].ptId);
      },
      (error: { message: any }) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  SelectVarUnitName(unitname: any): any {
    if (unitname.title === 'UNT') {
      this.varUnitName = unitname.name;
      this.isPlantGroupModelVisible = false;
    }
  }

  QualityApplyFilterData(): void {
    const PostData = {
      groupId: this.processDataForm.value.parametergroup,
      puId: this.unitid,
    };
    this.qualityParameterLoggingService.getDataApplyFilter(PostData).subscribe(
      (data) => {
        this.spinner.hide();
        // tslint:disable-next-line: only-arrow-functions
        data.forEach((res: any) => {
          res.interval = res.interval === null ? 0 : res.interval;
          if (res.autoManual === false || res.autoManual === null) {
            res.autoManual = 'Manual';
          } else if (res.autoManual === true || res.autoManual === null) {
            res.autoManual = 'Auto';
          }
        });
        // const arr= data.filter(x => x.isDeleted === false);
        this.rowData = data;
      },
      (error) => {
        this.spinner.hide();
        this.toastrService.error('error', error.message);
      }
    );
  }

  onCellValueChanged(params: any): void {
    const check = params.colDef.field;
    if (this.IsWrite) {
      if (check === 'isSelected') {
        const getrow = params.data;
        if (getrow.isSelected === true) {
          const addKey = {
            autoManual: getrow.autoManual === 'Auto' ? true : false,
            interval: 0,
            isSelected: getrow.isSelected,
            puId: this.unitid,
            cavity: getrow.cavity !== null ? getrow.cavity : 1,
            varId: getrow.varId,
            desc: getrow.varDesc,
            createdBy: JSON.parse(localStorage.user).UserId,
            processType: this.processDataForm.value.processType,
            qualityGroupId: this.processDataForm.value.parametergroup,
          };
          this.qualityParameterLoggingService
            .addqualityparameterlogging(addKey)
            .subscribe(
              (data: any) => {
                this.toastrService.success(' Record Added Successfully... ');
                this.QualityApplyFilterData();
              },
              (error: any) => {
                this.toastrService.error('Error', error.error.message);
                this.QualityApplyFilterData();
              }
            );
        } else if (getrow.isSelected === false) {
          // const setAM = {
          //   reqType: 0,
          //   autoManual: 'Manual',
          //   qplId: getrow.plId,
          //   puId: this.unitid,
          //   varId: getrow.varId,
          //   processType: this.processDataForm.value.processType
          // };
          // this.qualityParameterLoggingService.ChangeParameter(setAM).subscribe((data: any) => {
          // }, (error: { message: any; }) => {
          //   this.toastrService.error('Error', error.message);
          // });

          // const setInt = {
          //   reqType: 1,
          //   interval: 0,
          //   cavity: null,
          //   qplId: getrow.plId,
          //   puId: this.unitid,
          //   varId: getrow.varId,
          //   processType: this.processDataForm.value.processType
          // };
          // this.qualityParameterLoggingService.ChangeParameter(setInt).subscribe((data: any) => {
          // }, (error: { message: any; }) => {
          //   this.toastrService.error('Error', error.message);
          // });
          const delKey = {
            qplId: getrow.plId,
            quaId: getrow.varId,
          };
          this.qualityParameterLoggingService.DeleteSelected(delKey).subscribe(
            (data: any) => {
              this.toastrService.success('Record Reset Successfully...');
              this.QualityApplyFilterData();
            },
            (error: any) => {
              this.toastrService.error('Error', error.error.message);
              this.QualityApplyFilterData();
            }
          );
        }
      } else if (check === 'autoManual') {
        const getrow = params.data;
        const key = {
          reqType: 0,
          autoManual: 'Manual',
          qplId: getrow.plId,
          puId: this.unitid,
          varId: getrow.varId,
          processType: this.processDataForm.value.processType,
          createdBy: JSON.parse(localStorage.user).UserId,
        };
        this.qualityParameterLoggingService.ChangeParameter(key).subscribe(
          (data: any) => {
            this.toastrService.success(' Entry Saved Successfully... ');
            this.QualityApplyFilterData();
          },
          (error: { message: any }) => {
            this.toastrService.error('Error', error.message);
            this.QualityApplyFilterData();
          }
        );
      } else if (check === 'interval') {
        const getrow = params.data;
        const key = {
          reqType: 1,
          interval: getrow.interval === '' ? 0 : getrow.interval,
          cavity: getrow.cavity !== null ? getrow.cavity : 1,
          qplId: getrow.plId,
          puId: this.unitid,
          varId: getrow.varId,
          processType: this.processDataForm.value.processType,
          createdBy: JSON.parse(localStorage.user).UserId,
        };
        this.qualityParameterLoggingService.ChangeParameter(key).subscribe(
          (data: any) => {
            this.toastrService.success(' Entry Saved Successfully... ');
            this.QualityApplyFilterData();
          },
          (error: any) => {
            this.toastrService.error('Error', error.error.message);
            this.QualityApplyFilterData();
          }
        );
      } else if (check === 'cavity') {
        const getrow = params.data;
        const key = {
          reqType: 1,
          interval: getrow.interval,
          cavity: getrow.cavity !== null ? getrow.cavity : 1,
          qplId: getrow.plId,
          puId: this.unitid,
          varId: getrow.varId,
          processType: this.processDataForm.value.processType,
          createdBy: JSON.parse(localStorage.user).UserId,
        };
        this.qualityParameterLoggingService.ChangeParameter(key).subscribe(
          (data: any) => {
            this.toastrService.success(' Entry Saved Successfully... ');
            this.QualityApplyFilterData();
          },
          (error: { message: any }) => {
            this.toastrService.error('Error', error.message);
            this.QualityApplyFilterData();
          }
        );
      }
    } else {
      this.toastrService.error('Opps..! Only Read Permission');
      this.QualityApplyFilterData();
    }
  }

  onClickUnit(node: any): void {
    if (node.Level === 'Unit') {
      this.isPlantGroupModelVisible = false;
      this.UnitName = node.text;
      this.unitid = node.PrimaryId;
      // this.processDataForm.get('parametergroup')?.reset();
      this.rowData = this.EmptyData;
      this.getParameterCavity();
    }
  }
  getParameterCavity(): void {
    const Key = {
      puId: this.unitid,
    };
    this.qualityParameterService.getMaxCavity(Key).subscribe(
      (response: any) => {
        this.maxCavity = response.cavity !== null ? response.cavity : 1;
        this.processDataForm.get('cavity')?.setValue(this.maxCavity);
        this.processDataForm.get('processType')?.setValue(response.processType);
      },
      (error: { message: any }) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  SelectUnitName(unitname: any) {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
      this.getParameterGroups();
    }
  }

  openUnit(): any {
    this.isPlantGroupModelVisible = true;
    const key = {
      EGId: this.processDataForm.value.Group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.UnitNode = filterData(this.plantGroupSource.data, 'UNT');
        this.expandAllNode();
      },
      (error: any) => {
        this.toastrService.error('Error', error.message);
      }
    );

    function filterData(data: PlantGroupElement[], title: string) {
      const r = data.filter(function (o: any) {
        Object.keys(o).forEach(function (e: any) {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
  }

  getUnits(): void {
    this.ResetData();
  }

  //breadCrumb

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}

function GenderCellRenderer(): void {}

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};
