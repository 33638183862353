<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <!-- <div class="tab-content-top-box">
      <h1 class="main-title">NCR Approval</h1>
    </div> -->
    
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-6 p-lg-8 p-xl-8">
      <mat-card-title>NCR Approval </mat-card-title>
    </div>
  </div>
    <div class="plant-model-content">
      <!-- <form [formGroup]="productForm"> -->
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

        <form [formGroup]="ncrForm" >
        <div class="wrapper-box wrapper-box-shadow grid-container">
          <div class="mat-form-field-wrapper">
            <div class="grid-box">
              <input [hidden]="true">
              <mat-form-field appearence="fill" class="grid-box-filed-item" style="width: 18%;">
                <input
                matInput
                formControlName="startDate"
                [ngxMatDatetimePicker]="startPicker"
                placeholder="Start Date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="$any(startPicker)"
              ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #startPicker> </ngx-mat-datetime-picker>
              </mat-form-field>
              <mat-form-field appearence="fill" class="grid-box-filed-item"  style="width: 18%;">
                <input
                matInput
                formControlName="endDate"
                [ngxMatDatetimePicker]="endPicker"
                placeholder="End Date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="$any(endPicker)"
              ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #endPicker> </ngx-mat-datetime-picker>
              </mat-form-field>
              <button (click)="getAllNCR()" class="add-btn" style="margin-left: 27px;" pTooltip="Apply Filter" tooltipPosition="top">
                <img src="assets/images/search.png" alt="" width="20px" height="20px">
              </button>
            </div>
          </div>
        </div>
      </form>
      <!-- </form> -->
      <ag-grid-angular
      style="width: 100%; height: 25vw"
      class="ag-theme-alpine"
      [rowData]="dataArray"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      (cellValueChanged)="onCellValueChanged($event)"
    >
    </ag-grid-angular>
    </div>

  
  </div>

</div>
