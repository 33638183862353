import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  GridReadyEvent,
} from '@ag-grid-community/core';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { BtnCellRendererQxMatrix } from 'src/app/btn-cell-rendererQxMatrix';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PrimeCellEditorComponent } from '../../../editors/prime-cell-editor.component';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ImmediateActionService } from 'src/app/services/immediateAction.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { Immediatesortaction } from 'src/app/models/immediate_actions/immediatesortaction';
import { Immediateprocessaction } from 'src/app/models/immediate_actions/immediateprocessaction';
import { NcrService } from 'src/app/services/ncr.service';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import * as moment from 'moment';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import Swal from 'sweetalert2';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ImageDialogComponent } from 'src/app/analytical-tools/image-dialog/image-dialog.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-immediate-action',
  templateUrl: './immediate-action.component.html',
  styleUrls: ['./immediate-action.component.scss'],
})
export class ImmediateActionComponent implements OnInit {
  // public modules: Module[] = [ClientSideRowModelModule, SparklinesModule];
  @ViewChild('displayEditSortModal')
  private displayEditSortModal!: TemplateRef<any>;
  @ViewChild('displayEditProcessModal')
  private displayEditProcessModal!: TemplateRef<any>;
  @ViewChild('displayEditConcessionModal')
  private displayEditConcessionModal!: TemplateRef<any>;
  @ViewChild('displayEditDowngradeModal')
  private displayEditDowngradeModal!: TemplateRef<any>;
  @ViewChild('displayEditDisposeModal')
  private displayEditDisposeModal!: TemplateRef<any>;
  @ViewChild('displayFOCModal')
  private displayFOCModal!: TemplateRef<any>;
  @ViewChild('displayMaterialDisposalModal')
  private displayMaterialDisposalModal!: TemplateRef<any>;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('fileInput') fileInput!: ElementRef;
  public rowHeight = 50;
  groups: any[] = [];
  currencies: any[] = [];
  filterForm!: FormGroup;
  sortingForm!: FormGroup;
  sortingSearchForm!: FormGroup;
  detailNotesForm!: FormGroup;
  processForm!: FormGroup;
  concessionForm!: FormGroup;
  downgradeForm!: FormGroup;
  disposeForm!: FormGroup;
  processSearchForm!: FormGroup;
  concessionSearchForm!: FormGroup;
  downgradeSearchForm!: FormGroup;
  disposeSearchForm!: FormGroup;
  immediatesortactioneditform!: FormGroup;
  immediateprocessactioneditform!: FormGroup;
  immediateconcessionactioneditform!: FormGroup;
  immediatedowngradeactioneditform!: FormGroup;
  immediatedisposeactioneditform!: FormGroup;
  focApprovingAuthorityform!: FormGroup;
  focApprovingAuthorityDisposeform!: FormGroup;
  plants: any[] = [];
  Lines: any[] = [];
  UsersList: any[] = [];
  departmentList: any[] = [];
  Machines: any[] = [];
  breadcrumList: any[] = [];
  IsAccess: any;
  IsWrite: any;
  NCRId: any;
  RCDTitle: any;
  NcrProductDescription: any;
  NcrQuantityWaste: any;
  NcrProcessOrder: any;
  NcrCustomerName: any;
  NcrProductCode: any;
  UOMId: any;
  GlobeFileName: any;
  GlobeFilePath: any;
  SortActionId: any;
  QuantityHold: any;
  SortTotalHoldQty: any;
  ProcessTotalHoldQty: any;
  ConcessionTotalHoldQty: any;
  DowngradeTotalHoldQty: any;
  DisposeTotalHoldQty: any;
  buttonType: any;
  FOCReprocessFormId: any = 0;
  FOCDisposeFormId: any = 0;
  ReprocessActionId: any = 0;
  ConcessionActionId: any = 0;
  allSelected = false;
  allSelected2 = false;
  allSelected3 = false;
  allSelected4 = false;
  allSelected5 = false;
  togallSelected = false;
  togallSelected2 = false;
  togallSelected3 = false;
  togallSelected4 = false;
  togallSelected5 = false;
  isInternal = true;
  showWrapper2 = false;
  immediaterowData: any[] = [];
  Uomms: any[] = [];
  ncrActionData!: any[];
  SortingrowData: any[] = [];
  sortEditArray!: FormArray;
  processEditArray!: FormArray;
  concessionEditArray!: FormArray;
  downgradeEditArray!: FormArray;
  disposeEditArray!: FormArray;
  gridApi: any;
  detailNotesgridApi: any;
  sortgridApi: any;
  processgridApi: any;
  sortingtab!: boolean;
  defaulttab: boolean = false;
  processtab!: boolean;
  concessiontab!: boolean;
  downgradetab!: boolean;
  disposetab!: boolean;
  detailNotestab!: boolean;
  immediatesortactions!: Immediatesortaction[];
  immediateprocessactions!: Immediateprocessaction[];
  immediateconcessionactions!: Immediateprocessaction[];
  immediatedowngradeactions!: Immediateprocessaction[];
  immediatedisposeactions!: Immediateprocessaction[];
  immediateDetailNotes!: any;
  immediatecolumnDefs: any;
  detailnotescolumnDefs: any;
  Customers!: any[];
  moduleId!: any;
  pageId!: any;
  focFirstColumns: any[] = ['desc', 'qty', 'unit', 'amount', 'remove'];
  focSecondColumns: any[] = ['workers', 'hours', 'rate', 'amount', 'remove'];
  focThirdColumns: any[] = ['desc', 'hours', 'rate', 'amount', 'remove'];
  materialData!: MatTableDataSource<any>;
  materialDataDetail: any[] = [];
  workersData!: MatTableDataSource<any>;
  workersDataDetail: any[] = [];
  machineData!: MatTableDataSource<any>;
  machineDataDetail: any[] = [];
  TotalMaterialCost: any = 0;
  TotalWorkersCost: any = 0;
  TotalMachineCost: any = 0;
  TotalCost: any = 0;
  FormDate: any;
  FormId: any;
  imageFile: any;
  imageSrc: any;
  ImageUrl: any;
  headerData: any = {};
  lineOtherSelected: boolean = false;

  detailNotesGridOptions: any = {};

  gridOptions: any = {
    columnDefs: [
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: true,
        resizable: true,
        minWidth: 150,
        cellRenderer: (params: any) => {
          return `
          <div>
            <button mat-mini-fab="" color="primary" ptooltip="Send for Approval" title="SendforApproval" tooltipposition="top" class="mat-focus-indicator sortactionApproval mat-mini-fab mat-button-base mat-primary" style="background-color: green;" ng-reflect-color="primary" ng-reflect-text="Action Approval" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-tasks" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" color="primary" ptooltip="Action Edit" title="Edit" tooltipposition="top" class="mat-focus-indicator sortactionEdit mat-mini-fab mat-button-base mat-primary" style="background-color: #0033cc;" ng-reflect-color="primary" ng-reflect-text="Action Edit" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-edit" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" color="primary" ptooltip="Action Delete" title="Delete" tooltipposition="top" class="mat-focus-indicator sortactionDelete mat-mini-fab mat-button-base mat-primary" style="background-color: red;" ng-reflect-color="primary" ng-reflect-text="Action Delete" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          </div>
          `;
        },
      },
      {
        field: 'sortactionid',
        hide: true,
      },
      {
        field: 'departmentid',
        hide: true,
      },
      {
        field: 'masterdepartment',
        headerName: 'Department',
        cellRenderer: 'agGroupCellRenderer',
        sortable: true,
        minWidth: 150,
        resizable: true,
        filter: true,
      },
      {
        field: 'teamleadid',
        hide: true,
      },
      {
        field: 'teamlead',
        headerName: 'Team Lead',
        minWidth: 150,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'team',
        hide: true,
      },
      {
        field: 'uomid',
        hide: true,
      },
      {
        field: 'uom',
        headerName: 'UOM',
        minWidth: 150,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'assigndate',
        headerName: 'Assign Date',
        minWidth: 180,
        sortable: true,
        resizable: true,
        filter: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },
      },
      {
        field: 'assigndata',
        hide: true,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        resizable: true,
      },
      {
        field: 'targetdate',
        headerName: 'Target Date',
        minWidth: 150,
        editable: true,
        sortable: true,
        resizable: true,
        filter: 'agDateColumnFilter',
        cellEditor: 'primeCellEditor',
        filterParams: {
          comparator: myDateComparator,
        },
      },
      {
        field: 'targetdata',
        hide: true,
      },
      {
        field: 'misses',
        headerName: 'No Of Misses',
        minWidth: 150,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'aging',
        headerName: 'Aging',
        minWidth: 100,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'totalholdqty',
        headerName: 'Total Hold Qty',
        minWidth: 150,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'goodqty',
        headerName: 'Good Qty',
        minWidth: 150,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'badqty',
        headerName: 'Reject Qty',
        minWidth: 150,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'balanceqty',
        headerName: 'Balance Qty',
        minWidth: 150,
        sortable: true,
        resizable: true,
        filter: true,
      },
    ],
    defaultColDef: { flex: 1 },
    masterDetail: true,
    isRowMaster: (dataItem: any) => {
      return dataItem.detail ? true : false;
    },
    detailCellRendererParams: {
      detailGridOptions: {
        columnDefs: [
          {
            field: 'performedbyid',
            hide: true,
          },
          {
            field: 'performedby',
            headerName: 'Performed By',
            minWidth: 150,
          },
          {
            field: 'performedon',
            headerName: 'Performed On',
            minWidth: 150,
            resizable: true,
            cellRenderer: (data: any) => {
              return data.value
                ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
                : '';
            },
          },
          {
            field: 'detailsortqty',
            headerName: 'Sorted Quantity',
            minWidth: 100,
          },
          {
            field: 'detailgoodqty',
            headerName: 'Good Quantity',
            minWidth: 100,
          },
          {
            field: 'detailbadqty',
            headerName: 'Reject Quantity',
            minWidth: 100,
          },
        ],
        defaultColDef: { flex: 1 },
        isRowMaster: (dataItem: any) => {
          return dataItem.detail ? true : false;
        },
      },
      getDetailRowData: (params: any) => {
        params.successCallback(params.data.detail);
      },
    },
    getDetailRowData: (params: any) => {
      params.successCallback(params.data.detail);
    },
    frameworkComponents: {
      btnCellRendererQxMatrix: BtnCellRendererQxMatrix,
      primeCellEditor: PrimeCellEditorComponent,
    },
  };

  gridOptions2: any = {
    columnDefs: [
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: true,
        resizable: true,
        minWidth: 200,
        cellRenderer: () => {
          return `
          <div>
            <button mat-mini-fab="" color="primary" ptooltip="FOC" title="FOC" tooltipposition="top" class="mat-focus-indicator focform mat-mini-fab mat-button-base mat-primary" style="background-color: #078fc3;" ng-reflect-color="primary" ng-reflect-text="Action Approval" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-table" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" color="primary" ptooltip="Send for Approval" title="SendforApproval" tooltipposition="top" class="mat-focus-indicator sortactionApproval mat-mini-fab mat-button-base mat-primary" style="background-color: green;" ng-reflect-color="primary" ng-reflect-text="Action Approval" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-tasks" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" color="primary" ptooltip="Action Edit" title="Edit" tooltipposition="top" class="mat-focus-indicator sortactionEdit mat-mini-fab mat-button-base mat-primary" style="background-color: #0033cc;" ng-reflect-color="primary" ng-reflect-text="Action Edit" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-edit" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" color="primary" ptooltip="Action Delete" title="Delete" tooltipposition="top" class="mat-focus-indicator sortactionDelete mat-mini-fab mat-button-base mat-primary" style="background-color: red;" ng-reflect-color="primary" ng-reflect-text="Action Delete" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          </div>
          `;
        },
      },
      {
        field: 'sortactionid',
        hide: true,
      },
      {
        field: 'departmentid',
        hide: true,
      },
      {
        field: 'masterdepartment',
        headerName: 'Department',
        cellRenderer: 'agGroupCellRenderer',
        sortable: true,
        minWidth: 140,
        resizable: true,
        filter: true,
      },
      {
        field: 'teamleadid',
        hide: true,
      },
      {
        field: 'teamlead',
        headerName: 'Team Lead',
        minWidth: 140,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'team',
        hide: true,
      },
      {
        field: 'uomid',
        hide: true,
      },
      {
        field: 'uom',
        headerName: 'UOM',
        minWidth: 140,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'assigndate',
        headerName: 'Assign Date',
        minWidth: 180,
        sortable: true,
        resizable: true,
        filter: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 140,
        resizable: true,
      },
      {
        field: 'assigndata',
        hide: true,
      },
      {
        field: 'targetdate',
        headerName: 'Target Date',
        minWidth: 150,
        editable: true,
        sortable: true,
        resizable: true,
        filter: 'agDateColumnFilter',
        cellEditor: 'primeCellEditor',
        filterParams: {
          comparator: myDateComparator,
        },
      },
      {
        field: 'targetdata',
        hide: true,
      },
      {
        field: 'misses',
        headerName: 'No Of Misses',
        minWidth: 150,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'aging',
        headerName: 'Aging',
        minWidth: 100,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'totalholdqty',
        headerName: 'Total Hold Qty',
        minWidth: 150,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'totalqty',
        headerName: 'Total Qty',
        minWidth: 140,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'balanceqty',
        headerName: 'Balance Qty',
        minWidth: 140,
        sortable: true,
        resizable: true,
        filter: true,
      },
    ],
    defaultColDef: { flex: 1 },
    masterDetail: true,
    isRowMaster: (dataItem: any) => {
      return dataItem.detail ? true : false;
    },
    detailCellRendererParams: {
      detailGridOptions: {
        columnDefs: [
          {
            field: 'performedbyid',
            hide: true,
          },
          {
            field: 'performedby',
            headerName: 'Performed By',
            minWidth: 150,
          },
          {
            field: 'performedon',
            headerName: 'Performed On',
            minWidth: 150,
            cellRenderer: (data: any) => {
              return data.value
                ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
                : '';
            },
          },
          { field: 'detailqty', headerName: 'Total Quantity', minWidth: 100,},
        ],
        defaultColDef: { flex: 1 },
        isRowMaster: (dataItem: any) => {
          return dataItem.detail ? true : false;
        },
      },
      getDetailRowData: (params: any) => {
        params.successCallback(params.data.detail);
      },
    },
    getDetailRowData: (params: any) => {
      params.successCallback(params.data.detail);
    },
    frameworkComponents: {
      btnCellRendererQxMatrix: BtnCellRendererQxMatrix,
      primeCellEditor: PrimeCellEditorComponent,
    },
  };

  gridOptions3: any = {};
  sortDialogRef: MatDialogRef<any, any>;

  constructor(
    private sfdService: ShopFloorDataService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private fb: FormBuilder,
    public router: Router,
    private toaster: ToastrService,
    private machineService: MachineMappingService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private ncrService: NcrService,
    private immediateActionService: ImmediateActionService,
    private platModelService: EnterPriseGroupService,
    private usermanagementService: UserManagementService,
  ) {
    this.immediatecolumnDefs = [
      {
        field: 'action',
        headerName: 'Action',
        minWidth: 90,
        sortable: true,
        autoHeight: true,
        resizable: true,
        filter: true,
        cellRenderer: (): any => {
          return `
            <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-list" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            `;
        },
        cellRendererParams: {
          clicked(field: any): any {},
        },
      },

      {
        field: 'id',
        headerName: 'NCR ID',
        minWidth: 100,
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'ncrdetail',
        headerName: 'NCR Detail',
        suppressSizeToFit: true,
        sortable: true,
        resizable: true,
        autoHeight: true,
        filter: true,
      },
      {
        field: 'ncrdate',
        headerName: 'NCR Date',
        suppressSizeToFit: true,
        sortable: true,
        autoHeight: true,
        resizable: true,
        filter: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },
      },
      {
        field: 'quantityhold',
        headerName: 'NCR Hold Qty',
        minWidth: 150,
        sortable: true,
        autoHeight: true,
        resizable: true,
        filter: true,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        sortable: true,
        autoHeight: true,
        resizable: true,
        filter: true,
        cellRenderer: (params: any) => {
          if (params.value === 'InProgress') {
            return `In Progress`;
          } else {
            return params.value;
          }
        },
      },
      {
        field: 'product',
        headerName: 'Prod Desc',
        minWidth: 150,
        sortable: true,
        resizable: true,
        autoHeight: true,
      },
      {
        field: 'totalholdqty',
        headerName: 'Total Hold Qty',
        minWidth: 150,
        sortable: true,
        resizeable: true,
        autoHeight: true,
      },
      {
        field: 'sorting',
        headerName: 'Sorting',
        autoHeight: true,
        resizable: true,
        suppressSizeToFit: true,
        cellRenderer: function (param) {
          return (
            '<b>Total Hold Qty:</b> ' +
            param.data.sortingtotalholdqty +
            '<br/>' +
            '<b>Total Pieces:</b> ' +
            param.data.sortingtotalpieces +
            '<br/>' +
            '<b>Good Pieces:</b> ' +
            param.data.sortinggoodpieces +
            '<br/>' +
            '<b>Rejected Pieces:</b> ' +
            param.data.sortingbadpieces
          );
        },
      },
      {
        field: 'reprocess',
        headerName: 'Re-process',
        suppressSizeToFit: true,
        autoHeight: true,
        resizable: true,
        cellRenderer: function (param) {
          return (
            '<b>Total Hold Qty:</b> ' +
            param.data.processtotalholdqty +
            '<br/>' +
            '<b>Re-process Qty:</b> ' +
            param.data.processtotalpieces
          );
        },
      },
      {
        field: 'concession',
        headerName: 'Concession',
        suppressSizeToFit: true,
        autoHeight: true,
        resizable: true,
        cellRenderer: function (param) {
          return (
            '<b>Total Hold Qty:</b> ' +
            param.data.concessiontotalholdqty +
            '<br/>' +
            '<b>Concessioned Qty:</b> ' +
            param.data.concessiontotalpieces
          );
        },
      },
      {
        field: 'downgrade',
        headerName: 'Downgrade',
        suppressSizeToFit: true,
        resizable: true,
        cellRenderer: function (param) {
          return (
            '<b>Total Hold Qty:</b> ' +
            param.data.downgradetotalholdqty +
            '<br/>' +
            '<b>Downgrade Qty:</b> ' +
            param.data.downgradetotalpieces
          );
        },
      },
      {
        field: 'dispose',
        headerName: 'Dispose',
        suppressSizeToFit: true,
        autoHeight: true,
        resizable: true,
        cellRenderer: function (param) {
          return (
            '<b>Total Hold Qty:</b> ' +
            param.data.disposetotalholdqty +
            '<br/>' +
            '<b>Disposed Qty:</b> ' +
            param.data.disposetotalpieces
          );
        },
      },
    ];

    this.detailnotescolumnDefs = [
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: true,
        resizable: true,
        maxWidth: 100,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => {
          return `
          <div>
           <button mat-mini-fab="" color="primary" ptooltip="Action Delete" title="Delete" tooltipposition="top" class="mat-focus-indicator sortactionDelete mat-mini-fab mat-button-base mat-primary" style="background-color: red;" ng-reflect-color="primary" ng-reflect-text="Action Delete" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          </div>
          `;
        },
      },
      {
        field: 'id',
        hide: true,
      },
      {
        field: 'createdbyid',
        hide: true,
      },

      {
        field: 'detail',
        headerName: 'Detail',
        minWidth: 230,
        resizable: true,
      },

      {
        field: 'createdby',
        headerName: 'Created By',
        maxWidth: 200,
        resizable: true,
      },

      {
        field: 'createdon',
        headerName: 'Created Date',
        maxWidth: 180,
        sortable: true,
        resizable: true,
        filter: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },
      },
    ];
    setTimeout(() => {}, 500);
    this.detailNotesGridOptions = {
      columnDefs: this.detailnotescolumnDefs,
      rowData: this.immediateDetailNotes,
      onGridReady: function (params) {
        params.api.sizeColumnsToFit();
      },
      onRowEditingStarted: function (event) {
        console.log('never called - not doing row editing');
      },
      onRowEditingStopped: function (event) {
        console.log('never called - not doing row editing');
      },
      onCellEditingStarted: function (event) {
        console.log('cellEditingStarted');
      },
      onCellEditingStopped: function (event) {
        console.log('cellEditingStopped');
      },
    };
    this.gridOptions3 = {
      columnDefs: this.immediatecolumnDefs,
      rowData: this.immediaterowData,
      onGridReady: function (params) {
        params.api.sizeColumnsToFit();
      },
      onRowEditingStarted: function (event) {
        console.log('never called - not doing row editing');
      },
      onRowEditingStopped: function (event) {
        console.log('never called - not doing row editing');
      },
      onCellEditingStarted: function (event) {
        console.log('cellEditingStarted');
      },
      onCellEditingStopped: function (event) {
        console.log('cellEditingStopped');
      },
    };
  }
  @ViewChild('SelectAll') SelectAll!: MatSelect;
  @ViewChild('SelectAllProcess') SelectAllProcess!: MatSelect;
  @ViewChild('SelectAllConcession') SelectAllConcession!: MatSelect;
  @ViewChild('SelectAllDowngrade') SelectAllDowngrade!: MatSelect;
  @ViewChild('SelectAllDispose') SelectAllDispose!: MatSelect;

  @ViewChild('displayAddSortModal')
  private displayAddSortModal!: TemplateRef<any>;
  @ViewChild('displayAddProcessModal')
  private displayAddProcessModal!: TemplateRef<any>;
  @ViewChild('displayAddConcessionModal')
  private displayAddConcessionModal!: TemplateRef<any>;
  @ViewChild('displayAddDowngradeModal')
  private displayAddDowngradeModal!: TemplateRef<any>;
  @ViewChild('displayAddDisposeModal')
  private displayAddDisposeModal!: TemplateRef<any>;

  ngOnInit() {
    if(!this.data.NCRFilters){
      this.GetBreadcrumList();
      this.GetMenuAccess();
      this.initialdisplay();
    }
    this.getCurrencies();
    this.getGroups();
    this.getUnitOfMeasurement();
    this.NcrProductDescription = '';
    this.NcrQuantityWaste = 0;
    this.NcrProcessOrder = '';
    this.NcrCustomerName = '';
    this.NcrProductCode = '';
    this.RCDTitle = '';
    this.filterForm = this.fb.group({
      group: [''],
      plant: [''],
      line: [''],
      machine: [''],
      datePicker: [''],
      start: [''],
      end: [''],
    });

    const d = new Date();
    d.setDate(d.getDate() - 7); // setMonth(d.getMonth() - 3);
    this.data.NCRFilters ? this.filterForm.get('start')?.setValue(this.data.NCRFilters.start) : this.filterForm.get('start')?.setValue(d);
    this.data.NCRFilters ? this.filterForm.get('end')?.setValue(this.data.NCRFilters.end) : this.filterForm.get('end')?.setValue(new Date());
    this.sortingSearchForm = this.fb.group({
      department: [''],
      teamlead: [''],
      team: [''],
      uom: [''],
    });

    this.detailNotesForm = this.fb.group({
      detail: [''],
    });

    this.processSearchForm = this.fb.group({
      department: [''],
      teamlead: [''],
      team: [''],
      uom: [''],
    });

    this.concessionSearchForm = this.fb.group({
      department: [''],
      teamlead: [''],
      team: [''],
      uom: [''],
    });

    this.downgradeSearchForm = this.fb.group({
      department: [''],
      teamlead: [''],
      team: [''],
      uom: [''],
    });

    this.disposeSearchForm = this.fb.group({
      department: [''],
      teamlead: [''],
      team: [''],
      uom: [''],
    });

    this.sortingForm = this.fb.group({
      department: ['', Validators.required],
      teamlead: ['', Validators.required],
      team: ['', Validators.required],
      currency: ['', Validators.required],
      uom: ['', Validators.required],
      totalholdquantity: ['', Validators.required],
      assigndate: ['', Validators.required],
    });

    this.processForm = this.fb.group({
      department: ['', Validators.required],
      teamlead: ['', Validators.required],
      team: ['', Validators.required],
      currency: ['', Validators.required],
      uom: ['', Validators.required],
      totalholdquantity: ['', Validators.required],
      assigndate: ['', Validators.required],
    });

    this.concessionForm = this.fb.group({
      department: ['', Validators.required],
      teamlead: ['', Validators.required],
      team: ['', Validators.required],
      currency: ['', Validators.required],
      uom: ['', Validators.required],
      totalholdquantity: ['', Validators.required],
      assigndate: ['', Validators.required],
    });

    this.downgradeForm = this.fb.group({
      department: ['', Validators.required],
      teamlead: ['', Validators.required],
      team: ['', Validators.required],
      currency: ['', Validators.required],
      uom: ['', Validators.required],
      totalholdquantity: ['', Validators.required],
      assigndate: ['', Validators.required],
    });

    this.disposeForm = this.fb.group({
      department: ['', Validators.required],
      teamlead: ['', Validators.required],
      team: ['', Validators.required],
      currency: ['', Validators.required],
      uom: ['', Validators.required],
      totalholdquantity: ['', Validators.required],
      assigndate: ['', Validators.required],
    });

    this.immediatesortactioneditform = this.fb.group({
      department: { value: '', disabled: true },
      teamlead: { value: '', disabled: true },
      team: { value: '', disabled: true },
      currency: { value: '', disabled: true },
      uom: { value: '', disabled: true },
      totalholdquantity: ['', Validators.required],
      assigndate: { value: '', disabled: true },
      targetdate: { value: '', disabled: true },
      sortdetailActions: this.fb.array([this.createSortEditElementArray()]),
    });

    this.focApprovingAuthorityform = this.fb.group({
      productionorderno: ['', Validators.required],
      productdescription: ['', Validators.required],
      customername: [''],
      quantityorder: [''],
      quantityfailed: [''],
      ncrno: [''],
      ccrno: [''],
      salesReferenceNo: ['', Validators.required],
      reprocessedQty: [''],
      reprocessingTargetDate: [''],
      descNonConformance: [''],
      instructionForReprocessing: [''],
      totalmaterialcost: [''],
      totalworkerscost: [''],
      totalmachinecost: [''],
      totalcost: [''],
    });

    this.focApprovingAuthorityDisposeform = this.fb.group({
      // referenceno: ['', Validators.required],
      productdescription: ['', Validators.required],
      customername: [''],
      productcode: ['', Validators.required],
      productionorderno: ['', Validators.required],
      disposedquantity: [''],
      // designno: ['',Validators.required],
      disposedreason: ['', Validators.required],
      quantityhold: [''],
      quantityfailed: [''],
      reworkcost: [''],
      sortingcost: [''],
      othercost: [''],
      othercostremarks: [''],
      approvedby: ['', Validators.required],
      preparedby: ['', Validators.required],
      salesReferenceNo: ['', Validators.required],
      concessionDetails: [''],
      nonConformance: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });

    this.FormDate = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
    this.FormId = '';

    this.sortEditArray = this.immediatesortactioneditform.get(
      'sortdetailActions'
    ) as FormArray;

    this.immediateprocessactioneditform = this.fb.group({
      department: { value: '', disabled: true },
      teamlead: { value: '', disabled: true },
      team: { value: '', disabled: true },
      currency: { value: '', disabled: true },
      uom: { value: '', disabled: true },
      totalholdquantity: ['', Validators.required],
      assigndate: { value: '', disabled: true },
      targetdate: { value: '', disabled: true },
      processdetailActions: this.fb.array([
        this.createProcessEditElementArray(),
      ]),
    });

    this.processEditArray = this.immediateprocessactioneditform.get(
      'processdetailActions'
    ) as FormArray;

    this.immediateconcessionactioneditform = this.fb.group({
      department: { value: '', disabled: true },
      teamlead: { value: '', disabled: true },
      team: { value: '', disabled: true },
      currency: { value: '', disabled: true },
      uom: { value: '', disabled: true },
      totalholdquantity: ['', Validators.required],
      assigndate: { value: '', disabled: true },
      targetdate: { value: '', disabled: true },
      processdetailActions: this.fb.array([
        this.createConcessionEditElementArray(),
      ]),
    });

    this.concessionEditArray = this.immediateconcessionactioneditform.get(
      'processdetailActions'
    ) as FormArray;

    this.immediatedowngradeactioneditform = this.fb.group({
      department: { value: '', disabled: true },
      teamlead: { value: '', disabled: true },
      team: { value: '', disabled: true },
      currency: { value: '', disabled: true },
      uom: { value: '', disabled: true },
      totalholdquantity: ['', Validators.required],
      assigndate: { value: '', disabled: true },
      targetdate: { value: '', disabled: true },
      processdetailActions: this.fb.array([
        this.createDowngradeEditElementArray(),
      ]),
    });

    this.downgradeEditArray = this.immediatedowngradeactioneditform.get(
      'processdetailActions'
    ) as FormArray;

    this.immediatedisposeactioneditform = this.fb.group({
      department: { value: '', disabled: true },
      teamlead: { value: '', disabled: true },
      team: { value: '', disabled: true },
      currency: { value: '', disabled: true },
      uom: { value: '', disabled: true },
      totalholdquantity: ['', Validators.required],
      assigndate: { value: '', disabled: true },
      targetdate: { value: '', disabled: true },
      processdetailActions: this.fb.array([
        this.createDisposeEditElementArray(),
      ]),
    });

    this.disposeEditArray = this.immediatedisposeactioneditform.get(
      'processdetailActions'
    ) as FormArray;
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
      data.sort = this.sort.toArray()[index];
    }, 1000);
  }

  getCurrencies(): void {
    this.immediateActionService.getAllCurrencies().subscribe((res: any) => {
      this.currencies = res;
    });
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.sfdService.getGroupData(key).subscribe((res: any) => {
      this.groups = res.masterList;
      this.data.NCRSelectedRow ? this.filterForm.get('group')?.setValue(this.data.NCRFilters.groupList) : 
      this.filterForm.get('group')?.setValue(this.groups[0]?.TypeId);
      this.getPlantByGroup();
    });
  }

  onChangeGroup(): void {
    this.getPlantByGroup();
    //this.searchImmediateActions();
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.filterForm.value.group,
    };
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.sortingtab = false;
      this.defaulttab = false;
      this.plants = data.DepartList;
      this.data.NCRSelectedRow ? this.filterForm.get('plant')?.setValue(this.data.NCRFilters.plant) :
      this.filterForm.get('plant')?.setValue(this.plants[0].DeptId);
      this.getLines(this.filterForm.value.plant);
      this.getAlldepartment();
      this.getUserslist();
      this.getActions();
    });
  }

  onChangePlant(data: any): void {
    this.sortingtab = false;
    this.defaulttab = false;
    this.getLines(data.value);
    this.getAlldepartment();
    this.getUserslist();
    this.getActions();
  }

  getAlldepartment(): void {
    const key = {
      ptId: this.filterForm.value.plant,
    };
    this.machineService.getDepartmenBytPlant(key).subscribe(
      (response: any) => {
        this.departmentList = response;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getUserslist(): void {
    const { group, plant } = this.filterForm.value;
    const key = {
      EGId: group,
      PlantId: plant,
    };
    this.commonService.getPeopleMasterData(key).subscribe(
      (data: any) => {
        this.UsersList = data.employeeInfoList;
      },
      (error: any) => {
        this.toaster.error('error', error.error.message);
      }
    );
  }

  getActions(): void {
    this.spinner.show();
    const key = {
      plantId: this.filterForm.value.plant,
    };
    this.immediateActionService
      .getImmediateActionPlantWiseMasterData(key)
      .subscribe(
        (ncrActions) => {
          this.spinner.hide();
          this.ncrActionData = ncrActions;
          if (this.data.showWrapper2) {
            this.NCRId = this.data.tableId;
            this.toggleDisplay();
          }
        },
        (error) => {
          this.spinner.hide();
          this.toaster.error(error.error.message);
        }
      );
  }

  getLines(id: any): void {
    const key = {
      plantId: this.filterForm.value.plant,
    };
    this.commonService.getmasterline(key).subscribe(
      (res) => {
        this.Lines = res;
        if (res.length > 0) {
          this.data.NCRSelectedRow && this.data.NCRSelectedRow.lineId ? this.filterForm.get('line')?.setValue(this.data.NCRSelectedRow.lineId) :
          this.filterForm.get('line')?.setValue(this.Lines[0].plId);
          this.getMachines(this.filterForm.value.line);
        } else {
          this.filterForm.get('line')?.setValue('');
          this.filterForm.get('machine')?.setValue('');
        }
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onChangeLine(data: any): void {
    this.lineOtherSelected = true;
    if(data.value != 0){
      this.getMachines(data.value);
      this.lineOtherSelected = false;
    }
    else{
      this.filterForm.get('machine')?.setValue('0');
      this.searchImmediateActions();
    }
    this.Machines = [];
    //this.searchImmediateActions();
  }

  getMachines(id: any): void {
    const key = {
      plId: this.filterForm.value.line,
    };
    this.commonService.getMachineByLine(key).then(
      (res) => {
        this.Machines = res;
        if (res.length > 0) {
          this.data.NCRSelectedRow ? this.filterForm.get('machine')?.setValue(this.data.NCRSelectedRow.puId) :
          this.filterForm.get('machine')?.setValue(this.Machines[0].puId);
        } else {
          this.filterForm.get('machine')?.setValue('');
        }
        this.searchImmediateActions();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onChangeMachine(data: any): void {
    this.searchImmediateActions();
  }

  getUnitOfMeasurement(): void {
    this.ncrService.getUnitOfMeasurement().subscribe(
      (res) => {
        this.Uomms = res;
      },

      (error) => {
        this.toaster.error(error.error.message);
      }
    );
  }

  createSortEditElementArray(): FormGroup {
    let Emp_Id = 0;
    this.UsersList.forEach((element: any) => {
      if (element.UserId === JSON.parse(localStorage.user).UserId) {
        Emp_Id = element.EmpInfo_Id;
      }
    });
    return this.fb.group({
      detailsortqty: ['', Validators.required],
      detailgoodqty: ['', Validators.required],
      detailbadqty: ['', Validators.required],
      performedby: [Emp_Id, Validators.required],
      performedon: [new Date(), Validators.required],
    });
  }

  addNewRow(): any {
    this.sortEditArray.push(this.createSortEditElementArray());
  }

  get repeateSortElementForm(): any {
    return this.immediatesortactioneditform.get(
      'sortdetailActions'
    ) as FormArray;
  }

  removeRepeatSortElement(index: any): void {
    this.sortEditArray.removeAt(index);
    const { costElementId } =
      this.immediatesortactioneditform.value.sortdetailActions[index];
  }

  createProcessEditElementArray(): FormGroup {
    let Emp_Id = 0;
    this.UsersList.forEach((element: any) => {
      if (element.UserId === JSON.parse(localStorage.user).UserId) {
        Emp_Id = element.EmpInfo_Id;
      }
    });
    return this.fb.group({
      detailqty: ['', Validators.required],
      performedby: [Emp_Id, Validators.required],
      performedon: [new Date(), Validators.required],
    });
  }

  addNewRow2(): any {
    this.processEditArray.push(this.createProcessEditElementArray());
  }

  get repeateProcessElementForm(): any {
    return this.immediateprocessactioneditform.get(
      'processdetailActions'
    ) as FormArray;
  }

  removeRepeatProcessElement(index: any): void {
    this.processEditArray.removeAt(index);
    const { costElementId } =
      this.immediateprocessactioneditform.value.processdetailActions[index];
  }

  createConcessionEditElementArray(): FormGroup {
    let Emp_Id = 0;
    this.UsersList.forEach((element: any) => {
      if (element.UserId === JSON.parse(localStorage.user).UserId) {
        Emp_Id = element.EmpInfo_Id;
      }
    });
    return this.fb.group({
      detailqty: ['', Validators.required],
      performedby: [Emp_Id, Validators.required],
      performedon: [new Date(), Validators.required],
    });
  }

  addNewRow3(): any {
    this.concessionEditArray.push(this.createConcessionEditElementArray());
  }

  get repeateConcessionElementForm(): any {
    return this.immediateconcessionactioneditform.get(
      'processdetailActions'
    ) as FormArray;
  }

  removeRepeatConcessionElement(index: any): void {
    this.concessionEditArray.removeAt(index);
    const { costElementId } =
      this.immediateconcessionactioneditform.value.concessiondetailActions[
        index
      ];
  }

  createDowngradeEditElementArray(): FormGroup {
    let Emp_Id = 0;
    this.UsersList.forEach((element: any) => {
      if (element.UserId === JSON.parse(localStorage.user).UserId) {
        Emp_Id = element.EmpInfo_Id;
      }
    });
    return this.fb.group({
      detailqty: ['', Validators.required],
      performedby: [Emp_Id, Validators.required],
      performedon: [new Date(), Validators.required],
    });
  }

  addNewRow4(): any {
    this.downgradeEditArray.push(this.createDowngradeEditElementArray());
  }

  get repeateDowngradeElementForm(): any {
    return this.immediatedowngradeactioneditform.get(
      'processdetailActions'
    ) as FormArray;
  }

  removeRepeatDowngradeElement(index: any): void {
    this.downgradeEditArray.removeAt(index);
    const { costElementId } =
      this.immediatedowngradeactioneditform.value.processdetailActions[index];
  }

  createDisposeEditElementArray(): FormGroup {
    let Emp_Id = 0;
    this.UsersList.forEach((element: any) => {
      if (element.UserId === JSON.parse(localStorage.user).UserId) {
        Emp_Id = element.EmpInfo_Id;
      }
    });
    return this.fb.group({
      detailqty: ['', Validators.required],
      performedby: [Emp_Id, Validators.required],
      performedon: [new Date(), Validators.required],
    });
  }

  addNewRow5(): any {
    this.disposeEditArray.push(this.createDisposeEditElementArray());
  }

  get repeateDisposeElementForm(): any {
    return this.immediatedisposeactioneditform.get(
      'processdetailActions'
    ) as FormArray;
  }

  removeRepeatDisposeElement(index: any): void {
    this.disposeEditArray.removeAt(index);
    const { costElementId } =
      this.immediatedisposeactioneditform.value.processdetailActions[index];
  }

  oncellclicked(params: any) {
    const col = params.colDef.field;
    this.buttonType = '';
    if (col === 'action') {
      this.buttonType = params.event.target.classList[1];
      if (this.buttonType === 'fa-list') {
        this.ncrModalDataBinding(params);
        this.toggleDisplay();
      }
    }
  }

  ncrModalDataBinding(params: any) {
    console.log(
      '🚀 ~ file: immediate-action.component.ts ~ line 1585 ~ ImmediateActionComponent ~ ncrModalDataBinding ~ params',
      params
    );
    this.NCRId = params.data.id;
    this.NcrProductDescription = params.data.product;
    this.NcrCustomerName = params.data.customername;
    (this.NcrProductCode = params.data.prodcode),
      (this.UOMId = params.data.uomid);
    this.QuantityHold = params.data.quantityhold;
    this.NcrQuantityWaste = params.data.quantitywaste;
    this.NcrProcessOrder = params.data.processorder;
  }
  ondetailNoteseditcellclicked(params: any) {
    const col = params.colDef.field;
    this.buttonType = '';
    this.buttonType = params.event.target.classList[1];
    if (col === 'actions') {
      if (this.buttonType === 'fa-trash') {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to delete these notes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
        }).then((result) => {
          if (result.value) {
            const immediateSort = {
              id: params.data.id,
            };
            this.spinner.show();
            this.immediateActionService
              .deleteImmediateDetailNotes(immediateSort)
              .subscribe(
                (data: any) => {
                  if (data[0].error == 'false') {
                    this.SearchImmediateDetailNotes();
                    this.toaster.success(data[0].message);
                  } else {
                    this.SearchImmediateDetailNotes();
                    this.toaster.error('Error', data[0].message);
                  }
                  this.spinner.hide();
                },
                (error: any) => {
                  this.spinner.hide();
                  this.SearchImmediateDetailNotes();
                  this.toaster.error('Error', error.message);
                }
              );
          }
        });
      }
    }
  }

  onsorteditcellclicked(params: any) {
    const col = params.colDef.field;
    this.buttonType = '';
    let flag = 0;
    let myteam = params.data.teamuserids.split(',');
    myteam.forEach((element) => {
      if (JSON.parse(localStorage.user).UserId == Number(element)) {
        flag = 1;
      }
    });
    if (col === 'actions') {
      if (
        JSON.parse(localStorage.user).UserId == params.data.teamleaduserid ||
        flag == 1
      ) {
        this.buttonType = params.event.target.classList[1];
        if (this.buttonType === 'fa-edit') {
          this.SortActionId = params.data.sortactionid;
          this.buttonType = '';
          let tempSelect = [];
          let tempSelect2 = [];
          tempSelect = params.data.team.split(',');
          tempSelect.forEach((element) => {
            tempSelect2.push(Number(element));
          });
          this.immediatesortactioneditform.get('team')?.setValue(tempSelect2);
          this.immediatesortactioneditform
            .get('department')
            ?.setValue(params.data.departmentid);
          this.immediatesortactioneditform
            .get('uom')
            ?.setValue(params.data.uomid);
          this.immediatesortactioneditform
            .get('currency')
            ?.setValue(params.data.currencyid);
          this.immediatesortactioneditform
            .get('teamlead')
            ?.setValue(params.data.teamleadid);
          this.immediatesortactioneditform
            .get('totalholdquantity')
            ?.setValue(params.data.totalholdqty);
          this.immediatesortactioneditform
            .get('assigndate')
            ?.setValue(params.data.assigndata);
          this.immediatesortactioneditform
            .get('targetdate')
            ?.setValue(params.data.targetdata);
          this.sortEditArray.clear();
          const detailsArray: {}[] = [];
          let Emp_Id = 0;
          this.UsersList.forEach((element: any) => {
            if (element.UserId === JSON.parse(localStorage.user).UserId) {
              Emp_Id = element.EmpInfo_Id;
            }
          });
          if (params.data.detail == undefined) {
            const detail = {
              performedby: Emp_Id,
              performedon: new Date(),
              detailsortqty: null,
              detailgoodqty: null,
              detailbadqty: null,
            };
            detailsArray.push(detail);
            this.addNewRow();
          } else {
            params.data.detail.forEach((element: any) => {
              const detail = {
                performedby: element.performedbyid,
                performedon: element.performedon,
                detailsortqty: element.detailsortqty,
                detailgoodqty: element.detailgoodqty,
                detailbadqty: element.detailbadqty,
              };
              detailsArray.push(detail);
              this.addNewRow();
            });
          }
          this.immediatesortactioneditform
            .get('sortdetailActions')
            ?.setValue(detailsArray);
          this.openSortEditModal();
        } else if (this.buttonType === 'fa-tasks') {
          if (params.data.wanttosend == 'true') {
            if (
              params.data.targetdate != null &&
              params.data.targetdate != undefined
            ) {
              const immediateSort = {
                id: params.data.sortactionid,
              };
              this.spinner.show();
              this.immediateActionService
                .sendActionForApproval(immediateSort)
                .subscribe(
                  (data: any) => {
                    if (data[0].error == 'false') {
                      this.SearchImmediateSortActions();
                      this.toaster.success(data[0].message);
                    } else {
                      this.SearchImmediateSortActions();
                      this.toaster.error('Error', data[0].message);
                    }
                    this.spinner.hide();
                  },
                  (error: any) => {
                    this.spinner.hide();
                    this.SearchImmediateSortActions();
                    this.toaster.error('Error', error.message);
                  }
                );
            } else {
              this.toaster.error(
                'Error',
                'First set targetdate for this action!'
              );
            }
          } else {
            this.toaster.error('Error', 'Already sent!');
          }
        } else if (this.buttonType === 'fa-trash') {
          Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete sort action',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
          }).then((result) => {
            if (result.value) {
              const immediateSort = {
                id: params.data.sortactionid,
              };
              this.spinner.show();
              this.immediateActionService
                .deleteAllImmediateActions(immediateSort)
                .subscribe(
                  (data: any) => {
                    if (data[0].error == 'false') {
                      this.SearchImmediateSortActions();
                      this.toaster.success(data[0].message);
                    } else {
                      this.SearchImmediateSortActions();
                      this.toaster.error('Error', data[0].message);
                    }
                    this.spinner.hide();
                  },
                  (error: any) => {
                    this.spinner.hide();
                    this.SearchImmediateSortActions();
                    this.toaster.error('Error', error.message);
                  }
                );
            }
          });
        }
      } else {
        this.toaster.warning(
          'Warning',
          'Only team lead or team member can do this action'
        );
      }
    }
  }

  onprocesseditcellclicked(params: any) {
    const col = params.colDef.field;
    this.buttonType = '';
    let flag = 0;
    let myteam = params.data.teamuserids.split(',');
    myteam.forEach((element) => {
      if (JSON.parse(localStorage.user).UserId == Number(element)) {
        flag = 1;
      }
    });
    if (col === 'actions') {
      if (
        JSON.parse(localStorage.user).UserId == params.data.teamleaduserid ||
        flag == 1
      ) {
        this.buttonType = params.event.target.classList[1];
        let Emp_Id = 0;
        this.UsersList.forEach((element: any) => {
          if (element.UserId === JSON.parse(localStorage.user).UserId) {
            Emp_Id = element.EmpInfo_Id;
          }
        });
        if (this.buttonType === 'fa-edit') {
          this.SortActionId = params.data.sortactionid;
          this.buttonType = '';
          let tempSelect = [];
          let tempSelect2 = [];
          tempSelect = params.data.team.split(',');
          tempSelect.forEach((element) => {
            tempSelect2.push(Number(element));
          });
          this.immediateprocessactioneditform
            .get('team')
            ?.setValue(tempSelect2);
          this.immediateprocessactioneditform
            .get('department')
            ?.setValue(params.data.departmentid);
          this.immediateprocessactioneditform
            .get('uom')
            ?.setValue(params.data.uomid);
          this.immediateprocessactioneditform
            .get('currency')
            ?.setValue(params.data.currencyid);
          this.immediateprocessactioneditform
            .get('teamlead')
            ?.setValue(params.data.teamleadid);
          this.immediateprocessactioneditform
            .get('totalholdquantity')
            ?.setValue(params.data.totalholdqty);
          this.immediateprocessactioneditform
            .get('assigndate')
            ?.setValue(params.data.assigndata);
          this.immediateprocessactioneditform
            .get('targetdate')
            ?.setValue(params.data.targetdata);
          this.processEditArray.clear();
          const detailsArray: {}[] = [];
          if (params.data.detail == undefined) {
            const detail = {
              performedby: Emp_Id,
              performedon: new Date(),
              detailqty: null,
            };
            detailsArray.push(detail);
            this.addNewRow2();
          } else {
            params.data.detail.forEach((element: any) => {
              const detail = {
                performedby: element.performedbyid,
                performedon: element.performedon,
                detailqty: element.detailqty,
              };
              detailsArray.push(detail);
              this.addNewRow2();
            });
          }
          this.immediateprocessactioneditform
            .get('processdetailActions')
            ?.setValue(detailsArray);
          this.openProcessEditModal();
        } else if (this.buttonType === 'fa-tasks') {
          if (params.data.wanttosend == 'true') {
            if (params.data.isformfilled == true) {
              const immediateSort = {
                id: params.data.sortactionid,
              };
              this.immediateActionService
                .sendActionForApproval(immediateSort)
                .subscribe((data: any) => {
                  if (data[0].error == 'false') {
                    this.SearchImmediateReProcessActions();
                    this.toaster.success(data[0].message);
                  } else {
                    this.SearchImmediateReProcessActions();
                    this.toaster.error('Error', data[0].message);
                  }
                });
            } else {
              this.toaster.error('Error', 'First fill FOC form!');
            }
          } else {
            this.toaster.error('Error', 'Already sent!');
          }
        } else if (this.buttonType === 'fa-trash') {
          Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete reprocess action',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
          }).then((result) => {
            if (result.value) {
              const immediateSort = {
                id: params.data.sortactionid,
              };
              this.spinner.show();
              this.immediateActionService
                .deleteAllImmediateActions(immediateSort)
                .subscribe(
                  (data: any) => {
                    if (data[0].error == 'false') {
                      this.SearchImmediateReProcessActions();
                      this.toaster.success(data[0].message);
                    } else {
                      this.SearchImmediateReProcessActions();
                      this.toaster.error('Error', data[0].message);
                    }
                    this.spinner.hide();
                  },
                  (error: any) => {
                    this.spinner.hide();
                    this.SearchImmediateReProcessActions();
                    this.toaster.error('Error', error.message);
                  }
                );
            }
          });
        } else if (this.buttonType === 'fa-table') {
          if (
            params.data.targetdate != null &&
            params.data.targetdate != undefined
          ) {
            this.ReprocessActionId = params.data.sortactionid;
            this.focApprovingAuthorityform.reset();
            this.focApprovingAuthorityform
              .get('reprocessingTargetDate')
              ?.setValue(params.data.targetdata);
            if (params.data.totalqty != undefined) {
              this.focApprovingAuthorityform
                .get('reprocessedQty')
                .setValue(Number(params.data.totalqty));
            } else {
              this.focApprovingAuthorityform
                .get('reprocessedQty')
                ?.setValue(Number(0));
            }
            this.getFocReprocessApprovingAuthority(Emp_Id);
            this.openFOC();
          } else {
            this.toaster.error(
              'Error',
              'First set targetdate for this action!'
            );
          }
        }
      } else {
        this.toaster.warning(
          'Warning',
          'Only team lead or team member can do this action'
        );
      }
    }
  }

  onconcessioneditcellclicked(params: any) {
    const col = params.colDef.field;
    this.buttonType = '';
    let flag = 0;
    let myteam = params.data.teamuserids.split(',');
    myteam.forEach((element) => {
      if (JSON.parse(localStorage.user).UserId == Number(element)) {
        flag = 1;
      }
    });
    if (col === 'actions') {
      if (
        JSON.parse(localStorage.user).UserId == params.data.teamleaduserid ||
        flag == 1
      ) {
        this.buttonType = params.event.target.classList[1];
        let Emp_Id = 0;
        this.UsersList.forEach((element: any) => {
          if (element.UserId === JSON.parse(localStorage.user).UserId) {
            Emp_Id = element.EmpInfo_Id;
          }
        });
        if (this.buttonType === 'fa-edit') {
          this.SortActionId = params.data.sortactionid;
          this.buttonType = '';
          let tempSelect = [];
          let tempSelect2 = [];
          tempSelect = params.data.team.split(',');
          tempSelect.forEach((element) => {
            tempSelect2.push(Number(element));
          });
          this.immediateconcessionactioneditform
            .get('team')
            ?.setValue(tempSelect2);
          this.immediateconcessionactioneditform
            .get('department')
            ?.setValue(params.data.departmentid);
          this.immediateconcessionactioneditform
            .get('uom')
            ?.setValue(params.data.uomid);
          this.immediateconcessionactioneditform
            .get('currency')
            ?.setValue(params.data.currencyid);
          this.immediateconcessionactioneditform
            .get('teamlead')
            ?.setValue(params.data.teamleadid);
          this.immediateconcessionactioneditform
            .get('totalholdquantity')
            ?.setValue(params.data.totalholdqty);
          this.immediateconcessionactioneditform
            .get('assigndate')
            ?.setValue(params.data.assigndata);
          this.immediateconcessionactioneditform
            .get('targetdate')
            ?.setValue(params.data.targetdata);
          this.concessionEditArray.clear();
          const detailsArray: {}[] = [];
          if (params.data.detail == undefined) {
            const detail = {
              performedby: Emp_Id,
              performedon: new Date(),
              detailqty: null,
            };
            detailsArray.push(detail);
            this.addNewRow3();
          } else {
            params.data.detail.forEach((element: any) => {
              const detail = {
                performedby: element.performedbyid,
                performedon: element.performedon,
                detailqty: element.detailqty,
              };
              detailsArray.push(detail);
              this.addNewRow3();
            });
          }
          this.immediateconcessionactioneditform
            .get('processdetailActions')
            ?.setValue(detailsArray);
          this.openConcessionEditModal();
        } else if (this.buttonType === 'fa-tasks') {
          if (params.data.wanttosend == 'true') {
            if (params.data.isformfilled == true) {
              const immediateSort = {
                id: params.data.sortactionid,
              };
              this.immediateActionService
                .sendActionForApproval(immediateSort)
                .subscribe((data: any) => {
                  if (data[0].error == 'false') {
                    this.SearchImmediateConcessionActions();
                    this.toaster.success(data[0].message);
                  } else {
                    this.SearchImmediateConcessionActions();
                    this.toaster.error('Error', data[0].message);
                  }
                });
            } else {
              this.toaster.error('Error', 'First fill FOC form!');
            }
          } else {
            this.toaster.error('Error', 'Already sent!');
          }
        } else if (this.buttonType === 'fa-trash') {
          Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete concession action',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
          }).then((result) => {
            if (result.value) {
              const immediateSort = {
                id: params.data.sortactionid,
              };
              this.spinner.show();
              this.immediateActionService
                .deleteAllImmediateActions(immediateSort)
                .subscribe(
                  (data: any) => {
                    if (data[0].error == 'false') {
                      this.SearchImmediateConcessionActions();
                      this.toaster.success(data[0].message);
                    } else {
                      this.SearchImmediateConcessionActions();
                      this.toaster.error('Error', data[0].message);
                    }

                    this.spinner.hide();
                  },
                  (error: any) => {
                    this.spinner.hide();
                    this.SearchImmediateConcessionActions();
                    this.toaster.error('Error', error.message);
                  }
                );
            }
          });
        } else if (this.buttonType === 'fa-table') {
          if (
            params.data.targetdate != null &&
            params.data.targetdate != undefined
          ) {
            this.ConcessionActionId = params.data.sortactionid;
            this.focApprovingAuthorityDisposeform.reset();
            this.RCDTitle = 'Concessioned';
            if (params.data.totalqty != undefined) {
              this.focApprovingAuthorityDisposeform
                .get('disposedquantity')
                ?.setValue(Number(params.data.totalqty));
            } else {
              this.focApprovingAuthorityDisposeform
                .get('disposedquantity')
                ?.setValue(Number(0));
            }
            this.getFocApprovingAuthorityDisposeForm(Emp_Id);
            this.openMaterialDisposal();
          } else {
            this.toaster.error(
              'Error',
              'First set targetdate for this action!'
            );
          }
        }
      } else {
        this.toaster.warning(
          'Warning',
          'Only team lead or team member can do this action'
        );
      }
    }
  }

  ondowngradeeditcellclicked(params: any) {
    const col = params.colDef.field;
    this.buttonType = '';
    let flag = 0;
    let myteam = params.data.teamuserids.split(',');
    myteam.forEach((element) => {
      if (JSON.parse(localStorage.user).UserId == Number(element)) {
        flag = 1;
      }
    });
    if (col === 'actions') {
      if (
        JSON.parse(localStorage.user).UserId == params.data.teamleaduserid ||
        flag == 1
      ) {
        this.buttonType = params.event.target.classList[1];
        let Emp_Id = 0;
        this.UsersList.forEach((element: any) => {
          if (element.UserId === JSON.parse(localStorage.user).UserId) {
            Emp_Id = element.EmpInfo_Id;
          }
        });
        if (this.buttonType === 'fa-edit') {
          this.SortActionId = params.data.sortactionid;
          this.buttonType = '';
          let tempSelect = [];
          let tempSelect2 = [];
          tempSelect = params.data.team.split(',');
          tempSelect.forEach((element) => {
            tempSelect2.push(Number(element));
          });
          this.immediatedowngradeactioneditform
            .get('team')
            ?.setValue(tempSelect2);
          this.immediatedowngradeactioneditform
            .get('department')
            ?.setValue(params.data.departmentid);
          this.immediatedowngradeactioneditform
            .get('uom')
            ?.setValue(params.data.uomid);
          this.immediatedowngradeactioneditform
            .get('currency')
            ?.setValue(params.data.currencyid);
          this.immediatedowngradeactioneditform
            .get('teamlead')
            ?.setValue(params.data.teamleadid);
          this.immediatedowngradeactioneditform
            .get('totalholdquantity')
            ?.setValue(params.data.totalholdqty);
          this.immediatedowngradeactioneditform
            .get('assigndate')
            ?.setValue(params.data.assigndata);
          this.immediatedowngradeactioneditform
            .get('targetdate')
            ?.setValue(params.data.targetdata);
          this.downgradeEditArray.clear();
          const detailsArray: {}[] = [];
          if (params.data.detail == undefined) {
            const detail = {
              performedby: Emp_Id,
              performedon: new Date(),
              detailqty: null,
            };
            detailsArray.push(detail);
            this.addNewRow4();
          } else {
            params.data.detail.forEach((element: any) => {
              const detail = {
                performedby: element.performedbyid,
                performedon: element.performedon,
                detailqty: element.detailqty,
              };
              detailsArray.push(detail);
              this.addNewRow4();
            });
          }
          this.immediatedowngradeactioneditform
            .get('processdetailActions')
            ?.setValue(detailsArray);
          this.openDowngradeEditModal();
        } else if (this.buttonType === 'fa-tasks') {
          if (params.data.wanttosend == 'true') {
            if (params.data.isformfilled == true) {
              const immediateSort = {
                id: params.data.sortactionid,
              };
              this.immediateActionService
                .sendActionForApproval(immediateSort)
                .subscribe((data: any) => {
                  if (data[0].error == 'false') {
                    this.SearchImmediateDowngradeActions();
                    this.toaster.success(data[0].message);
                  } else {
                    this.SearchImmediateDowngradeActions();
                    this.toaster.error('Error', data[0].message);
                  }
                });
            } else {
              this.toaster.error('Error', 'First fill FOC form!');
            }
          } else {
            this.toaster.error('Error', 'Already sent!');
          }
        } else if (this.buttonType === 'fa-trash') {
          Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete downgrade action',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
          }).then((result) => {
            if (result.value) {
              const immediateSort = {
                id: params.data.sortactionid,
              };
              this.spinner.show();
              this.immediateActionService
                .deleteAllImmediateActions(immediateSort)
                .subscribe(
                  (data: any) => {
                    if (data[0].error == 'false') {
                      this.SearchImmediateDowngradeActions();
                      this.toaster.success(data[0].message);
                    } else {
                      this.SearchImmediateDowngradeActions();
                      this.toaster.error('Error', data[0].message);
                    }

                    this.spinner.hide();
                  },
                  (error: any) => {
                    this.spinner.hide();
                    this.SearchImmediateDowngradeActions();
                    this.toaster.error('Error', error.message);
                  }
                );
            }
          });
        } else if (this.buttonType === 'fa-table') {
          if (
            params.data.targetdate != null &&
            params.data.targetdate != undefined
          ) {
            this.ConcessionActionId = params.data.sortactionid;
            this.focApprovingAuthorityDisposeform.reset();
            this.RCDTitle = 'Downgraded';
            if (params.data.totalqty != undefined) {
              this.focApprovingAuthorityDisposeform
                .get('disposedquantity')
                ?.setValue(Number(params.data.totalqty));
            } else {
              this.focApprovingAuthorityDisposeform
                .get('disposedquantity')
                ?.setValue(Number(0));
            }
            this.getFocApprovingAuthorityDisposeForm(Emp_Id);
            this.openMaterialDisposal();
          } else {
            this.toaster.error(
              'Error',
              'First set targetdate for this action!'
            );
          }
        }
      } else {
        this.toaster.warning(
          'Warning',
          'Only team lead or team member can do this action'
        );
      }
    }
  }

  ondisposeeditcellclicked(params: any) {
    const col = params.colDef.field;
    this.buttonType = '';
    let flag = 0;
    let myteam = params.data.teamuserids.split(',');
    myteam.forEach((element) => {
      if (JSON.parse(localStorage.user).UserId == Number(element)) {
        flag = 1;
      }
    });
    if (col === 'actions') {
      if (
        JSON.parse(localStorage.user).UserId == params.data.teamleaduserid ||
        flag == 1
      ) {
        this.buttonType = params.event.target.classList[1];
        let Emp_Id = 0;
        this.UsersList.forEach((element: any) => {
          if (element.UserId === JSON.parse(localStorage.user).UserId) {
            Emp_Id = element.EmpInfo_Id;
          }
        });
        if (this.buttonType === 'fa-edit') {
          this.SortActionId = params.data.sortactionid;
          this.buttonType = '';
          let tempSelect = [];
          let tempSelect2 = [];
          tempSelect = params.data.team.split(',');
          tempSelect.forEach((element) => {
            tempSelect2.push(Number(element));
          });
          this.immediatedisposeactioneditform
            .get('team')
            ?.setValue(tempSelect2);
          this.immediatedisposeactioneditform
            .get('department')
            ?.setValue(params.data.departmentid);
          this.immediatedisposeactioneditform
            .get('uom')
            ?.setValue(params.data.uomid);
          this.immediatedisposeactioneditform
            .get('currency')
            ?.setValue(params.data.currencyid);
          this.immediatedisposeactioneditform
            .get('teamlead')
            ?.setValue(params.data.teamleadid);
          this.immediatedisposeactioneditform
            .get('totalholdquantity')
            ?.setValue(params.data.totalholdqty);
          this.immediatedisposeactioneditform
            .get('assigndate')
            ?.setValue(params.data.assigndata);
          this.immediatedisposeactioneditform
            .get('targetdate')
            ?.setValue(params.data.targetdata);
          this.disposeEditArray.clear();
          const detailsArray: {}[] = [];
          if (params.data.detail == undefined) {
            const detail = {
              performedby: Emp_Id,
              performedon: new Date(),
              detailqty: null,
            };
            detailsArray.push(detail);
            this.addNewRow5();
          } else {
            params.data.detail.forEach((element: any) => {
              const detail = {
                performedby: element.performedbyid,
                performedon: element.performedon,
                detailqty: element.detailqty,
              };
              detailsArray.push(detail);
              this.addNewRow5();
            });
          }
          this.immediatedisposeactioneditform
            .get('processdetailActions')
            ?.setValue(detailsArray);
          this.openDisposeEditModal();
        } else if (this.buttonType === 'fa-tasks') {
          if (params.data.wanttosend == 'true') {
            if (params.data.isformfilled == true) {
              const immediateSort = {
                id: params.data.sortactionid,
              };
              this.spinner.show();
              this.immediateActionService
                .sendActionForApproval(immediateSort)
                .subscribe(
                  (data: any) => {
                    if (data[0].error == 'false') {
                      this.SearchImmediateDisposeActions();
                      this.toaster.success(data[0].message);
                    } else {
                      this.SearchImmediateDisposeActions();
                      this.toaster.error('Error', data[0].message);
                    }
                    this.spinner.hide();
                  },
                  (error: any) => {
                    this.spinner.hide();
                    this.SearchImmediateDisposeActions();
                    this.toaster.error('Error', error.message);
                  }
                );
            } else {
              this.toaster.error('Error', 'First fill FOC form!');
            }
          } else {
            this.toaster.error('Error', 'Already sent!');
          }
        } else if (this.buttonType === 'fa-trash') {
          Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete dispose action',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
          }).then((result) => {
            if (result.value) {
              const immediateSort = {
                id: params.data.sortactionid,
              };
              this.spinner.show();
              this.immediateActionService
                .deleteAllImmediateActions(immediateSort)
                .subscribe(
                  (data: any) => {
                    if (data[0].error == 'false') {
                      this.SearchImmediateDisposeActions();
                      this.toaster.success(data[0].message);
                    } else {
                      this.SearchImmediateDisposeActions();
                      this.toaster.error('Error', data[0].message);
                    }
                    this.spinner.hide();
                  },
                  (error: any) => {
                    this.spinner.hide();
                    this.SearchImmediateDisposeActions();
                    this.toaster.error('Error', error.message);
                  }
                );
            }
          });
        } else if (this.buttonType === 'fa-table') {
          if (
            params.data.targetdate != null &&
            params.data.targetdate != undefined
          ) {
            this.ConcessionActionId = params.data.sortactionid;
            this.focApprovingAuthorityDisposeform.reset();
            this.RCDTitle = 'Disposed';
            if (params.data.totalqty != undefined) {
              this.focApprovingAuthorityDisposeform
                .get('disposedquantity')
                ?.setValue(Number(params.data.totalqty));
            } else {
              this.focApprovingAuthorityDisposeform
                .get('disposedquantity')
                ?.setValue(Number(0));
            }
            this.getFocApprovingAuthorityDisposeForm(Emp_Id);
            this.openMaterialDisposal();
          } else {
            this.toaster.error(
              'Error',
              'First set targetdate for this action!'
            );
          }
        }
      } else {
        this.toaster.warning(
          'Warning',
          'Only team lead or team member can do this action'
        );
      }
    }
  }

  onCellValueChanged1(params: any): void {
    const col = params.colDef.field;
    if (col === 'targetdate') {
      if (params.data.teamleaduserid == JSON.parse(localStorage.user).UserId) {
        let tempele = params.data.targetdate.split('/');
        let year = Number(tempele[2]);
        let month = Number(tempele[1]);
        let day = Number(tempele[0]);
        let tempdate = new Date(year, month - 1, day);
        let tempdate2 = new Date(params.data.assigndata);
        tempdate2.setHours(0, 0, 0, 0);
        if (tempdate2 <= tempdate) {
          tempdate = new Date(year, month - 1, day + 1);
          let UpdatedOn = new Date();
          const immediateSort = {
            updatedBy: JSON.parse(localStorage.user).UserId,
            updatedOn: new Date(
              UpdatedOn.getUTCFullYear(),
              UpdatedOn.getUTCDate(),
              UpdatedOn.getUTCHours(),
              UpdatedOn.getUTCMinutes(),
              UpdatedOn.getUTCSeconds()
            ),
            id: params.data.sortactionid,
            targetDate: tempdate,
          };
          this.spinner.show();
          this.immediateActionService.setNewTargetDate(immediateSort).subscribe(
            (data: any) => {
              if (data[0].error == 'false') {
                this.SearchImmediateSortActions();
                this.toaster.success(data[0].message);
              } else {
                this.SearchImmediateSortActions();
                this.toaster.error('Error', data[0].message);
              }
              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.SearchImmediateSortActions();
              this.toaster.error('Error', error.message);
            }
          );
        } else {
          this.SearchImmediateSortActions();
          this.toaster.error(
            'Error',
            'Target date must be greater than or equal to assign date.'
          );
        }
      } else {
        this.SearchImmediateSortActions();
        this.toaster.error(
          'Error',
          'Team lead of this sort action can set target date'
        );
      }
    }
  }

  onCellValueChanged2(params: any): void {
    const col = params.colDef.field;

    if (col === 'targetdate') {
      if (params.data.teamleaduserid == JSON.parse(localStorage.user).UserId) {
        let tempele = params.data.targetdate.split('/');
        let year = Number(tempele[2]);
        let month = Number(tempele[1]);
        let day = Number(tempele[0]);
        let tempdate = new Date(year, month - 1, day);
        let tempdate2 = new Date(params.data.assigndata);
        tempdate2.setHours(0, 0, 0, 0);
        if (tempdate2 <= tempdate) {
          tempdate = new Date(year, month - 1, day + 1);
          let UpdatedOn = new Date();
          const immediateSort = {
            updatedBy: JSON.parse(localStorage.user).UserId,
            updatedOn: new Date(
              UpdatedOn.getUTCFullYear(),
              UpdatedOn.getUTCMonth(),
              UpdatedOn.getUTCDate(),
              UpdatedOn.getUTCHours(),
              UpdatedOn.getUTCMinutes(),
              UpdatedOn.getUTCSeconds()
            ),
            id: params.data.sortactionid,
            targetDate: tempdate,
          };
          this.spinner.show();
          this.immediateActionService.setNewTargetDate(immediateSort).subscribe(
            (data: any) => {
              if (data[0].error == 'false') {
                this.SearchImmediateReProcessActions();
                this.toaster.success(data[0].message);
              } else {
                this.SearchImmediateReProcessActions();
                this.toaster.error('Error', data[0].message);
              }

              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.SearchImmediateReProcessActions();
              this.toaster.error('Error', error.message);
            }
          );
        } else {
          this.SearchImmediateReProcessActions();
          this.toaster.error(
            'Error',
            'Target date must be greater than or equal to assign date.'
          );
        }
      } else {
        this.SearchImmediateReProcessActions();
        this.toaster.error(
          'Error',
          'Team lead of this re-process action can set target date'
        );
      }
    }
  }

  onCellValueChanged3(params: any): void {
    const col = params.colDef.field;

    if (col === 'targetdate') {
      if (params.data.teamleaduserid == JSON.parse(localStorage.user).UserId) {
        let tempele = params.data.targetdate.split('/');
        let year = Number(tempele[2]);
        let month = Number(tempele[1]);
        let day = Number(tempele[0]);
        let tempdate = new Date(year, month - 1, day);
        let tempdate2 = new Date(params.data.assigndata);
        tempdate2.setHours(0, 0, 0, 0);
        if (tempdate2 <= tempdate) {
          tempdate = new Date(year, month - 1, day + 1);
          let UpdatedOn = new Date();
          const immediateSort = {
            updatedBy: JSON.parse(localStorage.user).UserId,
            updatedOn: new Date(
              UpdatedOn.getUTCFullYear(),
              UpdatedOn.getUTCMonth(),
              UpdatedOn.getUTCDate(),
              UpdatedOn.getUTCHours(),
              UpdatedOn.getUTCMinutes(),
              UpdatedOn.getUTCSeconds()
            ),
            id: params.data.sortactionid,
            targetDate: tempdate,
          };
          this.spinner.show();
          this.immediateActionService.setNewTargetDate(immediateSort).subscribe(
            (data: any) => {
              if (data[0].error == 'false') {
                this.SearchImmediateConcessionActions();
                this.toaster.success(data[0].message);
              } else {
                this.SearchImmediateConcessionActions();
                this.toaster.error('Error', data[0].message);
              }

              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.SearchImmediateConcessionActions();
              this.toaster.error('Error', error.message);
            }
          );
        } else {
          this.SearchImmediateConcessionActions();
          this.toaster.error(
            'Error',
            'Target date must be greater than or equal to assign date.'
          );
        }
      } else {
        this.SearchImmediateConcessionActions();
        this.toaster.error(
          'Error',
          'Team lead of this concession action can set target date'
        );
      }
    }
  }

  onCellValueChanged4(params: any): void {
    const col = params.colDef.field;

    if (col === 'targetdate') {
      if (params.data.teamleaduserid == JSON.parse(localStorage.user).UserId) {
        let tempele = params.data.targetdate.split('/');
        let year = Number(tempele[2]);
        let month = Number(tempele[1]);
        let day = Number(tempele[0]);
        let tempdate = new Date(year, month - 1, day);
        let tempdate2 = new Date(params.data.assigndata);
        tempdate2.setHours(0, 0, 0, 0);
        if (tempdate2 <= tempdate) {
          tempdate = new Date(year, month - 1, day + 1);
          let UpdatedOn = new Date();
          const immediateSort = {
            updatedBy: JSON.parse(localStorage.user).UserId,
            updatedOn: new Date(
              UpdatedOn.getUTCFullYear(),
              UpdatedOn.getUTCMonth(),
              UpdatedOn.getUTCDate(),
              UpdatedOn.getUTCHours(),
              UpdatedOn.getUTCMinutes(),
              UpdatedOn.getUTCSeconds()
            ),
            id: params.data.sortactionid,
            targetDate: tempdate,
          };
          this.spinner.show();
          this.immediateActionService.setNewTargetDate(immediateSort).subscribe(
            (data: any) => {
              if (data[0].error == 'false') {
                this.SearchImmediateDowngradeActions();
                this.toaster.success(data[0].message);
              } else {
                this.SearchImmediateDowngradeActions();
                this.toaster.error('Error', data[0].message);
              }

              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.SearchImmediateDowngradeActions();
              this.toaster.error('Error', error.message);
            }
          );
        } else {
          this.SearchImmediateDowngradeActions();
          this.toaster.error(
            'Error',
            'Target date must be greater than or equal to assign date.'
          );
        }
      } else {
        this.SearchImmediateDowngradeActions();
        this.toaster.error(
          'Error',
          'Team lead of this downgrade action can set target date'
        );
      }
    }
  }

  onCellValueChanged5(params: any): void {
    const col = params.colDef.field;

    if (col === 'targetdate') {
      if (params.data.teamleaduserid == JSON.parse(localStorage.user).UserId) {
        let tempele = params.data.targetdate.split('/');
        let year = Number(tempele[2]);
        let month = Number(tempele[1]);
        let day = Number(tempele[0]);
        let tempdate = new Date(year, month - 1, day);
        let tempdate2 = new Date(params.data.assigndata);
        tempdate2.setHours(0, 0, 0, 0);
        if (tempdate2 <= tempdate) {
          tempdate = new Date(year, month - 1, day + 1);
          let UpdatedOn = new Date();
          const immediateSort = {
            updatedBy: JSON.parse(localStorage.user).UserId,
            updatedOn: new Date(
              UpdatedOn.getUTCFullYear(),
              UpdatedOn.getUTCMonth(),
              UpdatedOn.getUTCDate(),
              UpdatedOn.getUTCHours(),
              UpdatedOn.getUTCMinutes(),
              UpdatedOn.getUTCSeconds()
            ),
            id: params.data.sortactionid,
            targetDate: tempdate,
          };
          this.spinner.show();
          this.immediateActionService.setNewTargetDate(immediateSort).subscribe(
            (data: any) => {
              if (data[0].error == 'false') {
                this.SearchImmediateDisposeActions();
                this.toaster.success(data[0].message);
              } else {
                this.SearchImmediateDisposeActions();
                this.toaster.error('Error', data[0].message);
              }

              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.SearchImmediateDisposeActions();
              this.toaster.error('Error', error.message);
            }
          );
        } else {
          this.SearchImmediateDisposeActions();
          this.toaster.error(
            'Error',
            'Target date must be greater than or equal to assign date.'
          );
        }
      } else {
        this.SearchImmediateDisposeActions();
        this.toaster.error(
          'Error',
          'Team lead of this dispose action can set target date'
        );
      }
    }
  }

  openSortEditModal(): void {
    this.sortDialogRef =  this.dialog.open(this.displayEditSortModal, {
      height: 'auto',
      width: '100%',
      disableClose: true,
    });
  }

  openProcessEditModal(): void {
    this.sortDialogRef = this.dialog.open(this.displayEditProcessModal, {
      height: 'auto',
      width: '100%',
      disableClose: true,
    });
  }

  openConcessionEditModal(): void {
    this.sortDialogRef = this.dialog.open(this.displayEditConcessionModal, {
      height: 'auto',
      width: '100%',
      disableClose: true,
    });
  }

  openDowngradeEditModal(): void {
    this.sortDialogRef = this.dialog.open(this.displayEditDowngradeModal, {
      height: 'auto',
      width: '100%',
      disableClose: true,
    });
  }

  openDisposeEditModal(): void {
    this.sortDialogRef = this.dialog.open(this.displayEditDisposeModal, {
      height: 'auto',
      width: '100%',
      disableClose: true,
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.sizeColumnsToFit();
    const element: HTMLElement = document.querySelector(
      '#wrapper1grid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }

  onSortGridReady(params: GridReadyEvent) {
    this.sortgridApi = params.api;
    this.sortgridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#wrapper2grid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }

  onDetailNotesGridReady(params: GridReadyEvent) {
    this.detailNotesgridApi = params.api;
    this.detailNotesgridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#detailNotesGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
    params.api.sizeColumnsToFit();
  }

  onProcessGridReady(params: GridReadyEvent) {
    this.processgridApi = params.api;
    this.processgridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#wrapper3grid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }

  initialdisplay(): void {
    this.showWrapper2 = false;
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.add('content-show');
    wrapper1?.classList.remove('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
  }

  toggleDisplay(): void {
    //  ui rendere with animations
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
    this.showWrapper2 = true;
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.add('content-show');
    wrapper2?.classList.remove('content-hide');
    if (this.ncrActionData != null && this.ncrActionData != undefined) {
      if (this.ncrActionData.length != 0) {
        this.CheckClickedMenu(this.ncrActionData[0].name);
      } else {
        this.defaulttab = true;
      }
    } else {
      this.defaulttab = true;
    }
  }

  toggleDisplayreverse(): void {
    const reprocess = document.querySelector('#ReProcess');
    const concession = document.querySelector('#Concession');
    const downgrade = document.querySelector('#Downgrade');
    const dispose = document.querySelector('#Dispose');
    reprocess?.classList.remove('active');
    concession?.classList.remove('active');
    downgrade?.classList.remove('active');
    dispose?.classList.remove('active');
    this.showWrapper2 = false;
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.add('content-show');
    wrapper1?.classList.remove('content-hide');
    this.searchImmediateActions();
  }

  CheckClickedMenu(temp: any): void {
    const sorting = document.querySelector('#Sorting');

    const reprocess = document.querySelector('#ReProcess');
    const concession = document.querySelector('#Concession');
    const downgrade = document.querySelector('#Downgrade');
    const dispose = document.querySelector('#Dispose');
    const detailNotes = document.querySelector('#DetailNotes');
    if (temp == 'Sorting') {
      sorting?.classList.add('active');
      reprocess?.classList.remove('active');
      concession?.classList.remove('active');
      downgrade?.classList.remove('active');
      dispose?.classList.remove('active');
      detailNotes?.classList.remove('active');
      this.Sorting();
    } else if (temp == 'ReProcess') {
      sorting?.classList.remove('active');
      reprocess?.classList.add('active');
      concession?.classList.remove('active');
      downgrade?.classList.remove('active');
      dispose?.classList.remove('active');
      detailNotes?.classList.remove('active');
      this.ReProcess();
    } else if (temp == 'Concession') {
      sorting?.classList.remove('active');
      reprocess?.classList.remove('active');
      concession?.classList.add('active');
      downgrade?.classList.remove('active');
      dispose?.classList.remove('active');
      detailNotes?.classList.remove('active');
      this.Concession();
    } else if (temp == 'Downgrade') {
      sorting?.classList.remove('active');
      reprocess?.classList.remove('active');
      concession?.classList.remove('active');
      downgrade?.classList.add('active');
      dispose?.classList.remove('active');
      detailNotes?.classList.remove('active');
      this.Downgrade();
    } else if (temp == 'Dispose') {
      sorting?.classList.remove('active');
      reprocess?.classList.remove('active');
      concession?.classList.remove('active');
      downgrade?.classList.remove('active');
      dispose?.classList.add('active');
      detailNotes?.classList.remove('active');
      this.Dispose();
    } else if (temp == 'DetailNotes') {
      sorting?.classList.remove('active');
      reprocess?.classList.remove('active');
      concession?.classList.remove('active');
      downgrade?.classList.remove('active');
      dispose?.classList.remove('active');
      detailNotes?.classList.add('active');
      this.DetailNotes();
    }
  }

  Sorting(): void {
    this.allSelected = false;
    this.defaulttab = false;
    this.sortingtab = true;
    this.processtab = false;
    this.concessiontab = false;
    this.downgradetab = false;
    this.disposetab = false;
    this.detailNotestab = false;
    let temp = '0';
    this.sortingSearchForm.get('department')?.setValue(temp);
    this.sortingSearchForm.get('teamlead')?.setValue(temp);
    this.sortingSearchForm.get('uom')?.setValue(temp);
    this.SearchImmediateSortActions();
  }

  ReProcess(): void {
    this.allSelected2 = false;
    this.defaulttab = false;
    this.sortingtab = false;
    this.processtab = true;
    this.concessiontab = false;
    this.downgradetab = false;
    this.disposetab = false;
    this.detailNotestab = false;
    let temp = '0';
    this.processSearchForm.get('department')?.setValue(temp);
    this.processSearchForm.get('teamlead')?.setValue(temp);
    this.processSearchForm.get('uom')?.setValue(temp);
    this.SearchImmediateReProcessActions();
  }

  Concession(): void {
    this.allSelected3 = false;
    this.defaulttab = false;
    this.sortingtab = false;
    this.processtab = false;
    this.concessiontab = true;
    this.downgradetab = false;
    this.disposetab = false;
    this.detailNotestab = false;
    let temp = '0';
    this.concessionSearchForm.get('department')?.setValue(temp);
    this.concessionSearchForm.get('teamlead')?.setValue(temp);
    this.concessionSearchForm.get('uom')?.setValue(temp);
    this.SearchImmediateConcessionActions();
  }

  Downgrade(): void {
    this.allSelected4 = false;
    this.defaulttab = false;
    this.sortingtab = false;
    this.processtab = false;
    this.concessiontab = false;
    this.downgradetab = true;
    this.disposetab = false;
    this.detailNotestab = false;
    let temp = '0';
    this.downgradeSearchForm.get('department')?.setValue(temp);
    this.downgradeSearchForm.get('teamlead')?.setValue(temp);
    this.downgradeSearchForm.get('uom')?.setValue(temp);
    this.SearchImmediateDowngradeActions();
  }

  Dispose(): void {
    this.allSelected5 = false;
    this.defaulttab = false;
    this.sortingtab = false;
    this.processtab = false;
    this.concessiontab = false;
    this.downgradetab = false;
    this.disposetab = true;
    this.detailNotestab = false;
    let temp = '0';
    this.disposeSearchForm.get('department')?.setValue(temp);
    this.disposeSearchForm.get('teamlead')?.setValue(temp);
    this.disposeSearchForm.get('uom')?.setValue(temp);
    this.SearchImmediateDisposeActions();
  }

  DetailNotes(): void {
    this.sortingtab = false;
    this.defaulttab = false;
    this.processtab = false;
    this.concessiontab = false;
    this.downgradetab = false;
    this.disposetab = false;
    this.detailNotestab = true;
    this.SearchImmediateDetailNotes();
  }

  searchImmediateActions(): void {
    const instance = {
      egId: this.filterForm.value.group > 0 ? this.filterForm.value.group : 0,
      plantId: this.filterForm.value.plant,
      lineId: this.data.NCRSelectedRow && !this.data.NCRSelectedRow.lineId ? 0 : this.filterForm.value.line,
      machineId: this.data.NCRSelectedRow && !this.data.NCRSelectedRow.puId ? 0 : this.filterForm.value.machine,

      startDate: moment(this.filterForm.get('start')?.value).format(
        'YYYY-MM-DD'
      ),
      endDate: moment(this.filterForm.get('end')?.value).format('YYYY-MM-DD'),
    };
    this.immediateActionService.searchNCRImmediateActions(instance).subscribe(
      (data: any) => {
        this.immediaterowData = data;
        if (this.data.showWrapper2) {
          if (this.immediaterowData.length) {
            const ncr = this.immediaterowData.find(
              (immd) => immd.id == this.data.tableId
            );
            console.log(
              '🚀 ~ file: immediate-action.component.ts ~ line 3132 ~ ImmediateActionComponent ~ searchImmediateActions ~ ncr',
              ncr
            );
            this.ncrModalDataBinding({
              data: ncr,
            });
          }
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  openSortingModal(): void {
    this.sortingForm.reset();
    this.sortingForm.get('assigndate')?.setValue(new Date());
    this.sortingForm.get('uom')?.setValue(this.UOMId);
    this.sortDialogRef = this.dialog.open(this.displayAddSortModal, {
      height: 'auto',
      width: '85%',
    });
  }

  openProcessModal(): void {
    this.processForm.reset();
    this.processForm.get('assigndate')?.setValue(new Date());
    this.processForm.get('uom')?.setValue(this.UOMId);
    this.sortDialogRef = this.dialog.open(this.displayAddProcessModal, {
      height: 'auto',
      width: '85%',
    });
  }
  closeProcessModal() {
    this.dialog.closeAll();
  }
  openConcessionModal(): void {
    this.concessionForm.reset();
    this.concessionForm.get('assigndate')?.setValue(new Date());
    this.concessionForm.get('uom')?.setValue(this.UOMId);
    this.sortDialogRef = this.dialog.open(this.displayAddConcessionModal, {
      height: 'auto',
      width: '85%',
    });

    this.sortDialogRef.afterClosed().subscribe((result) => {});
  }

  openDowngradeModal(): void {
    this.downgradeForm.reset();
    this.downgradeForm.get('assigndate')?.setValue(new Date());
    this.downgradeForm.get('uom')?.setValue(this.UOMId);
    this.sortDialogRef = this.dialog.open(this.displayAddDowngradeModal, {
      height: 'auto',
      width: '85%',
    });
  }

  openDisposeModal(): void {
    this.disposeForm.reset();
    this.disposeForm.get('assigndate')?.setValue(new Date());
    this.disposeForm.get('uom')?.setValue(this.UOMId);
    this.sortDialogRef = this.dialog.open(this.displayAddDisposeModal, {
      height: 'auto',
      width: '85%',
    });
  }

  submitDetailNotesEntry(): void {
    if (this.detailNotesForm.valid) {
      let CreatedOn = new Date();
      const immediateSort = {
        createdBy: JSON.parse(localStorage.user).UserId,
        createdOn: new Date(
          CreatedOn.getUTCFullYear(),
          CreatedOn.getUTCMonth(),
          CreatedOn.getUTCDate(),
          CreatedOn.getUTCHours(),
          CreatedOn.getUTCMinutes(),
          CreatedOn.getUTCSeconds()
        ),
        ncrId: this.NCRId,
        plantId: this.filterForm.value.plant,
        detail: this.detailNotesForm.value.detail,
      };
      this.spinner.show();
      this.immediateActionService
        .saveDetailNotesActions(immediateSort)
        .subscribe(
          (data: any) => {
            if (data.error == 'false') {
              this.detailNotesForm.reset();
              // this.dialog.closeAll();
              this.SearchImmediateDetailNotes();
              this.toaster.success('Notes Added Successfuly');
            } else {
              this.toaster.error('Error', data.message);
            }

            this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          }
        );
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  // add/edit methods
  submitSortEntry(): void {
    if (this.sortingForm.valid) {
      if (
        Number(this.sortingForm.value.totalholdquantity) +
          Number(this.SortTotalHoldQty) <=
        Number(this.QuantityHold)
      ) {
        let CreatedOn = new Date();
        let teamMemberIds = '';
        if (this.sortingForm.value.team) {
          teamMemberIds = this.sortingForm.value.team.join(',');
        }
        const immediateSort = {
          departmentId: this.sortingForm.value.department,
          teamLeadId: this.sortingForm.value.teamlead,
          createdBy: JSON.parse(localStorage.user).UserId,
          createdOn: new Date(
            CreatedOn.getUTCFullYear(),
            CreatedOn.getUTCMonth(),
            CreatedOn.getUTCDate(),
            CreatedOn.getUTCHours(),
            CreatedOn.getUTCMinutes(),
            CreatedOn.getUTCSeconds()
          ),
          uomId: this.sortingForm.value.uom,
          currencyId: this.sortingForm.value.currency,
          totalHoldQty: this.sortingForm.value.totalholdquantity,
          assignDate: this.sortingForm.value.assigndate,
          typeName: 'Sorting',
          teamMemberIds: teamMemberIds,
          ncrId: this.NCRId,
          plantId: this.filterForm.value.plant,
          status: 'Pending',
        };
        this.spinner.show();
        this.immediateActionService
          .saveImmediateSortingActions(immediateSort)
          .subscribe(
            (data: any) => {
              if (data.error == 'false') {
                this.sortDialogRef.close();
                this.SearchImmediateSortActions();
                this.toaster.success('Master Sort Entry Added Successfuly');
              } else {
                this.toaster.error('Error', data.message);
              }

              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
      } else {
        this.toaster.error(
          'Error',
          'You must enter Total Hold Quantity less than or equal to NCR Hold Quantity'
        );
      }
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  updateSortEntry(): void {
    if (this.immediatesortactioneditform.valid) {
      let totalCount = 0;
      let errorCounter = 0;
      let mylen =
        this.immediatesortactioneditform.value.sortdetailActions.length;
      this.immediatesortactioneditform.value.sortdetailActions.forEach(
        (element) => {
          totalCount =
            Number(totalCount) +
            Number(element.detailsortqty) -
            Number(element.detailbadqty);
          if (mylen == 1) {
            totalCount = totalCount + Number(element.detailbadqty);
          }
          mylen--;
        }
      );
      if (
        totalCount <= this.immediatesortactioneditform.value.totalholdquantity
      ) {
        this.immediatesortactioneditform.value.sortdetailActions.forEach(
          (element) => {
            if (
              Number(element.detailsortqty) !=
              Number(element.detailgoodqty) + Number(element.detailbadqty)
            ) {
              errorCounter++;
            }
          }
        );
        if (errorCounter === 0) {
          const immediateSort = {
            actionId: this.SortActionId,
            detail: this.immediatesortactioneditform.value.sortdetailActions,
          };
          this.spinner.show();
          this.immediateActionService
            .updateImmediateSortingActions(immediateSort)
            .subscribe(
              (data: any) => {
                if (data.error == 'false') {
                  this.sortDialogRef.close();
                  this.SearchImmediateSortActions();
                  this.toaster.success('Master Sort Entry Updated Successfuly');
                } else {
                  this.toaster.error('Error', data.message);
                }

                this.spinner.hide();
              },
              (error: any) => {
                this.spinner.hide();
                this.toaster.error('Error', error.message);
              }
            );
        } else {
          this.toaster.error(
            'Error',
            'In detail section sum of total quantity must be equal to the sum of total reject and good quatity'
          );
        }
      } else {
        this.toaster.error(
          'Error',
          'detail total count must be less than or equal to total hold quantity'
        );
      }
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  submitReProcessEntry(): void {
    if (this.processForm.valid) {
      if (
        Number(this.processForm.value.totalholdquantity) +
          Number(this.ProcessTotalHoldQty) <=
        Number(this.QuantityHold)
      ) {
        let CreatedOn = new Date();
        let teamMemberIds = '';
        if (this.processForm.value.team) {
          teamMemberIds = this.processForm.value.team.join(',');
        }
        const immediateSort = {
          departmentId: this.processForm.value.department,
          teamLeadId: this.processForm.value.teamlead,
          createdBy: JSON.parse(localStorage.user).UserId,
          createdOn: new Date(
            CreatedOn.getUTCFullYear(),
            CreatedOn.getUTCMonth(),
            CreatedOn.getUTCDate(),
            CreatedOn.getUTCHours(),
            CreatedOn.getUTCMinutes(),
            CreatedOn.getUTCSeconds()
          ),
          uomId: this.processForm.value.uom,
          currencyId: this.processForm.value.currency,
          totalHoldQty: this.processForm.value.totalholdquantity,
          assignDate: this.processForm.value.assigndate,
          typeName: 'ReProcess',
          teamMemberIds: teamMemberIds,
          ncrId: this.NCRId,
          plantId: this.filterForm.value.plant,
          status: 'Pending',
        };
        this.spinner.show();
        this.immediateActionService
          .saveImmediateSortingActions(immediateSort)
          .subscribe(
            (data: any) => {
              if (data.error == 'false') {
                this.sortDialogRef.close();
                this.SearchImmediateReProcessActions();
                this.toaster.success(
                  'Master ReProcess Entry Added Successfuly'
                );
              } else {
                this.toaster.error('Error', data.message);
              }

              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
      } else {
        this.toaster.error(
          'Error',
          'You must enter Total Hold Quantity less than or equal to NCR Hold Quantity'
        );
      }
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  updateProcessEntry(): void {
    if (this.immediateprocessactioneditform.valid) {
      let totalCount = 0;
      this.immediateprocessactioneditform.value.processdetailActions.forEach(
        (element) => {
          totalCount = Number(totalCount) + Number(element.detailqty);
        }
      );
      if (
        totalCount <=
        this.immediateprocessactioneditform.value.totalholdquantity
      ) {
        const immediateSort = {
          actionId: this.SortActionId,
          detail:
            this.immediateprocessactioneditform.value.processdetailActions,
        };
        this.spinner.show();
        this.immediateActionService
          .updateImmediateProcessActions(immediateSort)
          .subscribe(
            (data: any) => {
              if (data.error == 'false') {
                this.sortDialogRef.close();
                this.SearchImmediateReProcessAction();
                this.toaster.success('Master Entry Updated Successfuly');
              } else {
                this.toaster.error('Error', data.message);
              }
              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
      } else {
        this.toaster.error(
          'Error',
          'detail total count must be less than or equal to total hold quantity'
        );
      }
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  submitConcessionEntry(): void {
    if (this.concessionForm.valid) {
      if (
        Number(this.concessionForm.value.totalholdquantity) +
          Number(this.ConcessionTotalHoldQty) <=
        Number(this.QuantityHold)
      ) {
        let CreatedOn = new Date();
        let teamMemberIds = '';
        if (this.concessionForm.value.team) {
          teamMemberIds = this.concessionForm.value.team.join(',');
        }
        const immediateSort = {
          departmentId: this.concessionForm.value.department,
          teamLeadId: this.concessionForm.value.teamlead,
          createdBy: JSON.parse(localStorage.user).UserId,
          createdOn: new Date(
            CreatedOn.getUTCFullYear(),
            CreatedOn.getUTCMonth(),
            CreatedOn.getUTCDate(),
            CreatedOn.getUTCHours(),
            CreatedOn.getUTCMinutes(),
            CreatedOn.getUTCSeconds()
          ),
          uomId: this.concessionForm.value.uom,
          currencyId: this.concessionForm.value.currency,
          totalHoldQty: this.concessionForm.value.totalholdquantity,
          assignDate: this.concessionForm.value.assigndate,
          typeName: 'Concession',
          teamMemberIds: teamMemberIds,
          ncrId: this.NCRId,
          plantId: this.filterForm.value.plant,
          status: 'Pending',
        };
        this.spinner.show();
        this.immediateActionService
          .saveImmediateSortingActions(immediateSort)
          .subscribe(
            (data: any) => {
              if (data.error == 'false') {
                this.sortDialogRef.close();
                this.SearchImmediateConcessionActions();
                this.toaster.success(
                  'Master Concession Entry Added Successfuly'
                );
              } else {
                this.toaster.error('Error', data.message);
              }
              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
      } else {
        this.toaster.error(
          'Error',
          'You must enter Total Hold Quantity less than or equal to NCR Hold Quantity'
        );
      }
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  updateConcessionEntry(): void {
    if (this.immediateconcessionactioneditform.valid) {
      let totalCount = 0;
      this.immediateconcessionactioneditform.value.processdetailActions.forEach(
        (element) => {
          totalCount = Number(totalCount) + Number(element.detailqty);
        }
      );
      if (
        totalCount <=
        this.immediateconcessionactioneditform.value.totalholdquantity
      ) {
        const immediateSort = {
          actionId: this.SortActionId,
          detail:
            this.immediateconcessionactioneditform.value.processdetailActions,
        };
        this.spinner.show();
        this.immediateActionService
          .updateImmediateProcessActions(immediateSort)
          .subscribe(
            (data: any) => {
              if (data.error == 'false') {
                this.sortDialogRef.close();
                this.SearchImmediateConcessionActions();
                this.toaster.success('Master Entry Updated Successfuly');
              } else {
                this.toaster.error('Error', data.message);
              }
              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
      } else {
        this.toaster.error(
          'Error',
          'detail total count must be less than or equal to total hold quantity'
        );
      }
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  submitDowngradeEntry(): void {
    if (this.downgradeForm.valid) {
      if (
        Number(this.downgradeForm.value.totalholdquantity) +
          Number(this.DowngradeTotalHoldQty) <=
        Number(this.QuantityHold)
      ) {
        let CreatedOn = new Date();
        let teamMemberIds = '';
        if (this.downgradeForm.value.team) {
          teamMemberIds = this.downgradeForm.value.team.join(',');
        }
        const immediateSort = {
          departmentId: this.downgradeForm.value.department,
          teamLeadId: this.downgradeForm.value.teamlead,
          createdBy: JSON.parse(localStorage.user).UserId,
          createdOn: new Date(
            CreatedOn.getUTCFullYear(),
            CreatedOn.getUTCMonth(),
            CreatedOn.getUTCDate(),
            CreatedOn.getUTCHours(),
            CreatedOn.getUTCMinutes(),
            CreatedOn.getUTCSeconds()
          ),
          uomId: this.downgradeForm.value.uom,
          currencyId: this.downgradeForm.value.currency,
          totalHoldQty: this.downgradeForm.value.totalholdquantity,
          assignDate: this.downgradeForm.value.assigndate,
          typeName: 'Downgrade',
          teamMemberIds: teamMemberIds,
          ncrId: this.NCRId,
          plantId: this.filterForm.value.plant,
          status: 'Pending',
        };
        this.spinner.show();
        this.immediateActionService
          .saveImmediateSortingActions(immediateSort)
          .subscribe(
            (data: any) => {
              if (data.error == 'false') {
                this.sortDialogRef.close();
                this.SearchImmediateDowngradeActions();
                this.toaster.success(
                  'Master Downgrade Entry Added Successfuly'
                );
              } else {
                this.toaster.error('Error', data.message);
              }
              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
      } else {
        this.toaster.error(
          'Error',
          'You must enter Total Hold Quantity less than or equal to NCR Hold Quantity'
        );
      }
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  updateDowngradeEntry(): void {
    if (this.immediatedowngradeactioneditform.valid) {
      let totalCount = 0;
      this.immediatedowngradeactioneditform.value.processdetailActions.forEach(
        (element) => {
          totalCount = Number(totalCount) + Number(element.detailqty);
        }
      );
      if (
        totalCount <=
        this.immediatedowngradeactioneditform.value.totalholdquantity
      ) {
        const immediateSort = {
          actionId: this.SortActionId,
          detail:
            this.immediatedowngradeactioneditform.value.processdetailActions,
        };
        this.spinner.show();
        this.immediateActionService
          .updateImmediateProcessActions(immediateSort)
          .subscribe(
            (data: any) => {
              if (data.error == 'false') {
                this.sortDialogRef.close();
                this.SearchImmediateDowngradeActions();
                this.toaster.success('Master Entry Updated Successfuly');
              } else {
                this.toaster.error('Error', data.message);
              }
              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
      } else {
        this.toaster.error(
          'Error',
          'detail total count must be less than or equal to total hold quantity'
        );
      }
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  submitDisposeEntry(): void {
    if (this.disposeForm.valid) {
      if (
        Number(this.disposeForm.value.totalholdquantity) +
          Number(this.DisposeTotalHoldQty) <=
        Number(this.QuantityHold)
      ) {
        let CreatedOn = new Date();
        let teamMemberIds = '';
        if (this.disposeForm.value.team) {
          teamMemberIds = this.disposeForm.value.team.join(',');
        }
        const immediateSort = {
          departmentId: this.disposeForm.value.department,
          teamLeadId: this.disposeForm.value.teamlead,
          createdBy: JSON.parse(localStorage.user).UserId,
          createdOn: new Date(
            CreatedOn.getUTCFullYear(),
            CreatedOn.getUTCMonth(),
            CreatedOn.getUTCDate(),
            CreatedOn.getUTCHours(),
            CreatedOn.getUTCMinutes(),
            CreatedOn.getUTCSeconds()
          ),
          uomId: this.disposeForm.value.uom,
          currencyId: this.disposeForm.value.currency,
          totalHoldQty: this.disposeForm.value.totalholdquantity,
          assignDate: this.disposeForm.value.assigndate,
          typeName: 'Dispose',
          teamMemberIds: teamMemberIds,
          ncrId: this.NCRId,
          plantId: this.filterForm.value.plant,
          status: 'Pending',
        };
        this.spinner.show();
        this.immediateActionService
          .saveImmediateSortingActions(immediateSort)
          .subscribe(
            (data: any) => {
              if (data.error == 'false') {
                this.sortDialogRef.close();
                this.SearchImmediateDisposeActions();
                this.toaster.success('Master Dispose Entry Added Successfuly');
              } else {
                this.toaster.error('Error', data.message);
              }
              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
      } else {
        this.toaster.error(
          'Error',
          'You must enter Total Hold Quantity less than or equal to NCR Hold Quantity'
        );
      }
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  updateDisposeEntry(): void {
    if (this.immediatedisposeactioneditform.valid) {
      let totalCount = 0;
      this.immediatedisposeactioneditform.value.processdetailActions.forEach(
        (element) => {
          totalCount = Number(totalCount) + Number(element.detailqty);
        }
      );
      if (
        totalCount <=
        this.immediatedisposeactioneditform.value.totalholdquantity
      ) {
        const immediateSort = {
          actionId: this.SortActionId,
          detail:
            this.immediatedisposeactioneditform.value.processdetailActions,
        };
        this.spinner.show();
        this.immediateActionService
          .updateImmediateProcessActions(immediateSort)
          .subscribe(
            (data: any) => {
              if (data.error == 'false') {
                this.sortDialogRef.close();
                this.SearchImmediateDisposeActions();
                this.toaster.success('Master Entry Updated Successfuly');
              } else {
                this.toaster.error('Error', data.message);
              }
              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
      } else {
        this.toaster.error(
          'Error',
          'detail total count must be less than or equal to total hold quantity'
        );
      }
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  //search functions

  SearchImmediateSortAction(): void {
    if (this.togallSelected == false) {
      this.SearchImmediateSortActions();
    }
  }

  SearchImmediateSortActions(): void {
    let teamMemberIds = '';
    if (this.allSelected == true) {
      teamMemberIds = '';
    } else {
      if (this.sortingSearchForm.value.team) {
        teamMemberIds = this.sortingSearchForm.value.team.join(',');
      }
    }
    const immediateSort = {
      departmentId: Number(this.sortingSearchForm.value.department),
      teamLeadId: Number(this.sortingSearchForm.value.teamlead),
      teamMemberIds: teamMemberIds,
      ncrId: this.NCRId,
      uomId: Number(this.sortingSearchForm.value.uom),
      plantId: this.filterForm.value.plant,
      typeName: 'Sorting',
    };
    this.immediateActionService
      .searchImmediateSortingActions(immediateSort)
      .subscribe((data: any) => {
        this.immediatesortactions = data;
        if (this.immediatesortactions != null) {
          this.immediatesortactions.forEach((element) => {
            if (
              element.sortingtotalholdqty != null &&
              element.sortingtotalholdqty != 0
            ) {
              this.SortTotalHoldQty = element.sortingtotalholdqty;
            }
            if (element.targetdate != null) {
              element.targetdate = moment(element.targetdate).format(
                'DD/MM/YYYY'
              );
            }
          });
        } else {
          this.SortTotalHoldQty = 0;
        }
      });
  }

  SearchImmediateReProcessAction(): void {
    if (this.togallSelected2 == false) {
      this.SearchImmediateReProcessActions();
    }
  }

  SearchImmediateReProcessActions(): void {
    let teamMemberIds = '';
    if (this.allSelected2 == true) {
      teamMemberIds = '';
    } else {
      if (this.processSearchForm.value.team) {
        teamMemberIds = this.processSearchForm.value.team.join(',');
      }
    }
    const immediateSort = {
      departmentId: Number(this.processSearchForm.value.department),
      teamLeadId: Number(this.processSearchForm.value.teamlead),
      teamMemberIds: teamMemberIds,
      ncrId: this.NCRId,
      uomId: Number(this.processSearchForm.value.uom),
      plantId: this.filterForm.value.plant,
      typeName: 'ReProcess',
    };
    this.immediateActionService
      .searchImmediateReProcessActions(immediateSort)
      .subscribe((data: any) => {
        this.immediateprocessactions = data;
        if (this.immediateprocessactions != null) {
          this.immediateprocessactions.forEach((element) => {
            if (
              element.processtotalholdqty != null &&
              element.processtotalholdqty != 0
            ) {
              this.ProcessTotalHoldQty = element.processtotalholdqty;
            }
            if (element.targetdate != null) {
              element.targetdate = moment(element.targetdate).format(
                'DD/MM/YYYY'
              );
            }
          });
        } else {
          this.ProcessTotalHoldQty = 0;
        }
      });
  }

  SearchImmediateConcessionAction(): void {
    if (this.togallSelected3 == false) {
      this.SearchImmediateConcessionActions();
    }
  }

  SearchImmediateConcessionActions(): void {
    let teamMemberIds = '';
    if (this.allSelected3 == true) {
      teamMemberIds = '';
    } else {
      if (this.concessionSearchForm.value.team) {
        teamMemberIds = this.concessionSearchForm.value.team.join(',');
      }
    }
    const immediateSort = {
      departmentId: Number(this.concessionSearchForm.value.department),
      teamLeadId: Number(this.concessionSearchForm.value.teamlead),
      teamMemberIds: teamMemberIds,
      ncrId: this.NCRId,
      uomId: Number(this.concessionSearchForm.value.uom),
      plantId: this.filterForm.value.plant,
      typeName: 'Concession',
    };
    this.immediateActionService
      .searchImmediateReProcessActions(immediateSort)
      .subscribe((data: any) => {
        this.immediateconcessionactions = data;
        if (this.immediateconcessionactions != null) {
          this.immediateconcessionactions.forEach((element) => {
            if (
              element.processtotalholdqty != null &&
              element.processtotalholdqty != 0
            ) {
              this.ConcessionTotalHoldQty = element.processtotalholdqty;
            }
            if (element.targetdate != null) {
              element.targetdate = moment(element.targetdate).format(
                'DD/MM/YYYY'
              );
            }
          });
        } else {
          this.ConcessionTotalHoldQty = 0;
        }
      });
  }

  SearchImmediateDowngradeAction(): void {
    if (this.togallSelected4 == false) {
      this.SearchImmediateDowngradeActions();
    }
  }

  SearchImmediateDowngradeActions(): void {
    let teamMemberIds = '';
    if (this.allSelected4 == true) {
      teamMemberIds = '';
    } else {
      if (this.downgradeSearchForm.value.team) {
        teamMemberIds = this.downgradeSearchForm.value.team.join(',');
      }
    }
    const immediateSort = {
      departmentId: Number(this.downgradeSearchForm.value.department),
      teamLeadId: Number(this.downgradeSearchForm.value.teamlead),
      teamMemberIds: teamMemberIds,
      ncrId: this.NCRId,
      uomId: Number(this.downgradeSearchForm.value.uom),
      plantId: this.filterForm.value.plant,
      typeName: 'Downgrade',
    };
    this.immediateActionService
      .searchImmediateReProcessActions(immediateSort)
      .subscribe((data: any) => {
        this.immediatedowngradeactions = data;
        if (this.immediatedowngradeactions != null) {
          this.immediatedowngradeactions.forEach((element) => {
            if (
              element.processtotalholdqty != null &&
              element.processtotalholdqty != 0
            ) {
              this.DowngradeTotalHoldQty = element.processtotalholdqty;
            }
            if (element.targetdate != null) {
              element.targetdate = moment(element.targetdate).format(
                'DD/MM/YYYY'
              );
            }
          });
        } else {
          this.DowngradeTotalHoldQty = 0;
        }
      });
  }

  SearchImmediateDisposeAction(): void {
    if (this.togallSelected5 == false) {
      this.SearchImmediateDisposeActions();
    }
  }

  SearchImmediateDisposeActions(): void {
    let teamMemberIds = '';
    if (this.allSelected5 == true) {
      teamMemberIds = '';
    } else {
      if (this.disposeSearchForm.value.team) {
        teamMemberIds = this.disposeSearchForm.value.team.join(',');
      }
    }
    const immediateSort = {
      departmentId: Number(this.disposeSearchForm.value.department),
      teamLeadId: Number(this.disposeSearchForm.value.teamlead),
      teamMemberIds: teamMemberIds,
      ncrId: this.NCRId,
      uomId: Number(this.disposeSearchForm.value.uom),
      plantId: this.filterForm.value.plant,
      typeName: 'Dispose',
    };
    this.immediateActionService
      .searchImmediateReProcessActions(immediateSort)
      .subscribe((data: any) => {
        this.immediatedisposeactions = data;
        if (this.immediatedisposeactions != null) {
          this.immediatedisposeactions.forEach((element) => {
            if (
              element.processtotalholdqty != null &&
              element.processtotalholdqty != 0
            ) {
              this.DisposeTotalHoldQty = element.processtotalholdqty;
            }
            if (element.targetdate != null) {
              element.targetdate = moment(element.targetdate).format(
                'DD/MM/YYYY'
              );
            }
          });
        } else {
          this.DisposeTotalHoldQty = 0;
        }
      });
  }

  SearchImmediateDetailNotes(): void {
    const immediateSort = {
      ncrId: this.NCRId,
      plantId: this.filterForm.value.plant,
    };
    this.immediateActionService
      .searchImmediateDetailNotes(immediateSort)
      .subscribe((data: any) => {
        this.immediateDetailNotes = data;
      });
  }
  //select all in multi select members

  toggleAllSelection(): void {
    this.togallSelected = true;
    if (this.allSelected) {
      this.SelectAll.options.forEach((item: MatOption) => item.select());
      const filteredItem = this.UsersList?.map((item: any) => item?.EmpInfo_Id);
      this.sortingSearchForm.get('team')?.setValue([...filteredItem]);
      this.togallSelected = false;
    } else {
      this.SelectAll.options.forEach((item: MatOption) => item.deselect());
      this.sortingSearchForm.get('team')?.setValue([]);
      this.togallSelected = false;
    }
  }

  toggleAllSelection2(): void {
    this.togallSelected2 = true;
    if (this.allSelected2) {
      this.SelectAllProcess.options.forEach((item: MatOption) => item.select());
      const filteredItem = this.UsersList?.map((item: any) => item?.EmpInfo_Id);
      this.processSearchForm.get('team')?.setValue([...filteredItem]);
      this.togallSelected2 = false;
    } else {
      this.SelectAllProcess.options.forEach((item: MatOption) =>
        item.deselect()
      );
      this.processSearchForm.get('team')?.setValue([]);
      this.togallSelected2 = false;
    }
  }

  toggleAllSelection3(): void {
    this.togallSelected3 = true;
    if (this.allSelected3) {
      this.SelectAllConcession.options.forEach((item: MatOption) =>
        item.select()
      );
      const filteredItem = this.UsersList?.map((item: any) => item?.EmpInfo_Id);
      this.concessionSearchForm.get('team')?.setValue([...filteredItem]);
      this.togallSelected3 = false;
    } else {
      this.SelectAllConcession.options.forEach((item: MatOption) =>
        item.deselect()
      );
      this.concessionSearchForm.get('team')?.setValue([]);
      this.togallSelected3 = false;
    }
  }

  toggleAllSelection4(): void {
    this.togallSelected4 = true;
    if (this.allSelected4) {
      this.SelectAllDowngrade.options.forEach((item: MatOption) =>
        item.select()
      );
      const filteredItem = this.UsersList?.map((item: any) => item?.EmpInfo_Id);
      this.downgradeSearchForm.get('team')?.setValue([...filteredItem]);
      this.togallSelected4 = false;
    } else {
      this.SelectAllDowngrade.options.forEach((item: MatOption) =>
        item.deselect()
      );
      this.downgradeSearchForm.get('team')?.setValue([]);
      this.togallSelected4 = false;
    }
  }

  toggleAllSelection5(): void {
    this.togallSelected5 = true;
    if (this.allSelected5) {
      this.SelectAllDispose.options.forEach((item: MatOption) => item.select());
      const filteredItem = this.UsersList?.map((item: any) => item?.EmpInfo_Id);
      this.disposeSearchForm.get('team')?.setValue([...filteredItem]);
      this.togallSelected5 = false;
    } else {
      this.SelectAllDispose.options.forEach((item: MatOption) =>
        item.deselect()
      );
      this.disposeSearchForm.get('team')?.setValue([]);
      this.togallSelected5 = false;
    }
  }

  openFOC(): void {
    this.deleteImageFile();
    this.sortDialogRef = this.dialog.open(this.displayFOCModal, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '100vh',
      minWidth: '95vw',
      maxHeight: '100vh',
    });
  }

  openMaterialDisposal(): void {
    this.deleteImageFile();
    this.sortDialogRef = this.dialog.open(this.displayMaterialDisposalModal, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '100vh',
      minWidth: '95vw',
      maxHeight: '100vh',
    });
  }

  toggleCheckbox(event) {
    event.source.id === 'checkbox1'
      ? (this.isInternal = true)
      : (this.isInternal = false);
  }

  saveFocMaterialDetail(): void {
    let matdesc = document.querySelector(
      '#FOCMaterialDescription'
    ) as HTMLInputElement;
    let matqty = document.querySelector(
      '#FOCMaterialQuantity'
    ) as HTMLInputElement;
    let matrate = document.querySelector(
      '#FOCMaterialRate'
    ) as HTMLInputElement;
    let material = {
      id: 1,
      desc: matdesc.value,
      qty: Number(matqty.value),
      unit: Number(matrate.value),
      amount: Number(matqty.value) * Number(matrate.value),
    };
    if (
      material.desc != '' &&
      material.qty != 0 &&
      material.unit != 0 &&
      material.amount != 0
    ) {
      this.TotalMaterialCost = 0;
      material.id = this.materialDataDetail.length + 1;
      this.materialDataDetail.push(material);
      this.materialData = new MatTableDataSource<any>(this.materialDataDetail);
      this.getPaginator(this.materialData, 0);
      matdesc.value = '';
      matqty.value = '0';
      matrate.value = '0';
      this.materialDataDetail.forEach((element: any) => {
        this.TotalMaterialCost = this.TotalMaterialCost + element.amount;
      });
      this.focApprovingAuthorityform
        .get('totalmaterialcost')
        ?.setValue(this.TotalMaterialCost);
      let tempCalculate =
        Number(this.TotalMaterialCost) +
        Number(this.TotalWorkersCost) +
        Number(this.TotalMachineCost);
      this.focApprovingAuthorityform.get('totalcost')?.setValue(tempCalculate);
    }
  }

  removeFocMaterialDetail(ele: any): void {
    let myarr = this.materialDataDetail;
    this.materialDataDetail = [];
    let counter = 1;
    myarr.forEach((element: any) => {
      if (element.id != ele.id) {
        element.id = counter;
        this.materialDataDetail.push(element);
        counter++;
      } else {
        this.TotalMaterialCost = this.TotalMaterialCost - ele.amount;
      }
    });
    this.focApprovingAuthorityform
      .get('totalmaterialcost')
      .setValue(this.TotalMaterialCost);
    this.materialData = new MatTableDataSource<any>(this.materialDataDetail);
    if (this.materialDataDetail.length != 0) {
      this.getPaginator(this.materialData, 0);
    }
    let tempCalculate =
      Number(this.TotalMaterialCost) +
      Number(this.TotalWorkersCost) +
      Number(this.TotalMachineCost);
    this.focApprovingAuthorityform.get('totalcost')?.setValue(tempCalculate);
  }

  saveFocWorkerDetail(): void {
    let workno = document.querySelector('#FOCWorkerNo') as HTMLInputElement;
    let hourno = document.querySelector('#FOCWorkerHourNo') as HTMLInputElement;
    let workrate = document.querySelector('#FOCWorkerRate') as HTMLInputElement;
    let worker = {
      id: 1,
      workers: Number(workno.value),
      hours: Number(hourno.value),
      rate: Number(workrate.value),
      amount:
        Number(workno.value) * Number(hourno.value) * Number(workrate.value),
    };
    if (
      worker.workers != 0 &&
      worker.hours != 0 &&
      worker.rate != 0 &&
      worker.amount != 0
    ) {
      this.TotalWorkersCost = 0;
      worker.id = this.workersDataDetail.length + 1;
      this.workersDataDetail.push(worker);
      this.workersData = new MatTableDataSource<any>(this.workersDataDetail);
      this.getPaginator(this.workersData, 1);
      workno.value = '0';
      hourno.value = '0';
      workrate.value = '0';
      this.workersDataDetail.forEach((element: any) => {
        this.TotalWorkersCost = this.TotalWorkersCost + element.amount;
      });
      this.focApprovingAuthorityform
        .get('totalworkerscost')
        ?.setValue(this.TotalWorkersCost);

      let tempCalculate =
        Number(this.TotalMaterialCost) +
        Number(this.TotalWorkersCost) +
        Number(this.TotalMachineCost);
      this.focApprovingAuthorityform.get('totalcost')?.setValue(tempCalculate);
    }
  }

  removeFocWorkerDetail(ele: any): void {
    let myarr = this.workersDataDetail;
    this.workersDataDetail = [];
    let counter = 1;
    myarr.forEach((element: any) => {
      if (element.id != ele.id) {
        element.id = counter;
        this.workersDataDetail.push(element);
        counter++;
      } else {
        this.TotalWorkersCost = this.TotalWorkersCost - ele.amount;
      }
    });
    this.focApprovingAuthorityform
      .get('totalworkerscost')
      .setValue(this.TotalWorkersCost);
    this.workersData = new MatTableDataSource<any>(this.workersDataDetail);
    if (this.workersDataDetail.length != 0) {
      this.getPaginator(this.workersData, 1);
    }

    let tempCalculate =
      Number(this.TotalMaterialCost) +
      Number(this.TotalWorkersCost) +
      Number(this.TotalMachineCost);
    this.focApprovingAuthorityform.get('totalcost')?.setValue(tempCalculate);
  }

  saveFocMachineDetail(): void {
    let macdesc = document.querySelector(
      '#FOCMachineDescription'
    ) as HTMLInputElement;
    let machour = document.querySelector(
      '#FOCMachineHourNo'
    ) as HTMLInputElement;
    let macrate = document.querySelector('#FOCMachineRate') as HTMLInputElement;
    let machine = {
      id: 1,
      desc: macdesc.value,
      hours: Number(machour.value),
      rate: Number(macrate.value),
      amount: Number(machour.value) * Number(macrate.value),
    };
    if (
      machine.desc != '' &&
      machine.hours != 0 &&
      machine.rate != 0 &&
      machine.amount != 0
    ) {
      this.TotalMachineCost = 0;
      machine.id = this.machineDataDetail.length + 1;
      this.machineDataDetail.push(machine);
      this.machineData = new MatTableDataSource<any>(this.machineDataDetail);
      this.getPaginator(this.machineData, 2);
      macdesc.value = '';
      machour.value = '0';
      macrate.value = '0';
      this.machineDataDetail.forEach((element: any) => {
        this.TotalMachineCost = this.TotalMachineCost + element.amount;
      });
      this.focApprovingAuthorityform
        .get('totalmachinecost')
        ?.setValue(this.TotalMachineCost);

      let tempCalculate =
        Number(this.TotalMaterialCost) +
        Number(this.TotalWorkersCost) +
        Number(this.TotalMachineCost);
      this.focApprovingAuthorityform.get('totalcost')?.setValue(tempCalculate);
    }
  }

  removeFocMachineDetail(ele: any): void {
    let myarr = this.machineDataDetail;
    this.machineDataDetail = [];
    let counter = 1;
    myarr.forEach((element: any) => {
      if (element.id != ele.id) {
        element.id = counter;
        this.machineDataDetail.push(element);
        counter++;
      } else {
        this.TotalMachineCost = this.TotalMachineCost - ele.amount;
      }
    });
    this.focApprovingAuthorityform
      .get('totalmachinecost')
      ?.setValue(this.TotalMachineCost);
    this.machineData = new MatTableDataSource<any>(this.machineDataDetail);

    if (this.machineDataDetail.length != 0) {
      this.getPaginator(this.machineData, 2);
    }

    let tempCalculate =
      Number(this.TotalMaterialCost) +
      Number(this.TotalWorkersCost) +
      Number(this.TotalMachineCost);
    this.focApprovingAuthorityform.get('totalcost')?.setValue(tempCalculate);
  }

  saveApprovingAuthority(): void {
    const formData = new FormData();
    formData.append('file', this.imageFile);
    if (this.focApprovingAuthorityform.valid) {
      if (
        this.isInternal == false &&
        (this.focApprovingAuthorityform.value.ccrno == null ||
          this.focApprovingAuthorityform.value.ccrno == undefined ||
          this.focApprovingAuthorityform.value.ccrno == '')
      ) {
        this.toaster.error('Error', 'CCRNO is required');
      } else {
        const approvingauthority = {
          id: this.FOCReprocessFormId,
          actionId: this.ReprocessActionId,
          createdBy: JSON.parse(localStorage.user).UserId,
          productDescription:
            this.focApprovingAuthorityform.value.productdescription,
          productionOrderNo:
            this.focApprovingAuthorityform.value.productionorderno,
          customerName: this.focApprovingAuthorityform.value.customername,
          quantityOrder: this.focApprovingAuthorityform.value.quantityorder,
          quantityFailed: this.focApprovingAuthorityform.value.quantityfailed,
          ncrNo: this.focApprovingAuthorityform.value.ncrno,
          ccrNo: this.focApprovingAuthorityform.value.ccrno,
          isInternal: this.isInternal,
          lineCheckedBy: this.focApprovingAuthorityform.value.linecheckedby,
          lineVerifiedBy: this.focApprovingAuthorityform.value.lineverifiedby,
          lineReason: this.focApprovingAuthorityform.value.linereason,
          totalMaterialCost:
            this.focApprovingAuthorityform.value.totalmaterialcost,
          totalWorkersCost:
            this.focApprovingAuthorityform.value.totalworkerscost,
          totalMachineCost:
            this.focApprovingAuthorityform.value.totalmachinecost,
          materialDetail: this.materialDataDetail,
          workersDetail: this.workersDataDetail,
          machineDetail: this.machineDataDetail,
          salesReferenceNo:
            this.focApprovingAuthorityform.value.salesReferenceNo,
          descNonConformance:
            this.focApprovingAuthorityform.value.descNonConformance,
          instructionForReprocessing:
            this.focApprovingAuthorityform.value.instructionForReprocessing,
          fileName: this.GlobeFileName,
          filePath: this.GlobeFilePath,
        };
        formData.append(
          'ApprovingAuthorityModal',
          JSON.stringify(approvingauthority)
        );
        this.spinner.show();
        this.immediateActionService.saveFOCApprovalForm(formData).subscribe(
          (data: any) => {
            if (data[0].error == 'false') {
              this.sortDialogRef.close();
              this.toaster.success('Saved Successfully!');
              this.SearchImmediateReProcessActions();
            } else {
              this.toaster.error('Error', data[0].message);
            }
            this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
      }
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  saveFOCDisposeApprovingAuthority(): void {
    const formData = new FormData();
    formData.append('file', this.imageFile);
    if (this.focApprovingAuthorityDisposeform.valid) {
      const approvingauthority = {
        id: this.FOCDisposeFormId,
        actionId: this.ConcessionActionId,
        createdBy: JSON.parse(localStorage.user).UserId,
        referenceNo: '',
        productDescription:
          this.focApprovingAuthorityDisposeform.value.productdescription,
        customerName: this.focApprovingAuthorityDisposeform.value.customername,
        productCode: this.focApprovingAuthorityDisposeform.value.productcode,
        productionOrderNo:
          this.focApprovingAuthorityDisposeform.value.productionorderno,
        disposedQuantity:
          this.focApprovingAuthorityDisposeform.value.disposedquantity,
        designNo: '',
        disposedReason:
          this.focApprovingAuthorityDisposeform.value.disposedreason,
        quantityHold: this.focApprovingAuthorityDisposeform.value.quantityhold,
        quantityFailed:
          this.focApprovingAuthorityDisposeform.value.quantityfailed,
        reworkCost: this.focApprovingAuthorityDisposeform.value.reworkcost ? this.focApprovingAuthorityDisposeform.value.reworkcost : 0,
        sortingCost: this.focApprovingAuthorityDisposeform.value.sortingcost ? this.focApprovingAuthorityDisposeform.value.sortingcost : 0,
        otherCost: this.focApprovingAuthorityDisposeform.value.othercost ? this.focApprovingAuthorityDisposeform.value.othercost : 0,
        otherCostRemarks:
          this.focApprovingAuthorityDisposeform.value.othercostremarks ? this.focApprovingAuthorityDisposeform.value.othercostremarks : 0,
        totalCost: this.TotalCost,
        approvedBy: this.focApprovingAuthorityDisposeform.value.approvedby,
        preparedBy: this.focApprovingAuthorityDisposeform.value.preparedby,
        salesReferenceNo:
          this.focApprovingAuthorityDisposeform.value.salesReferenceNo,
        concessionDetails:
          this.focApprovingAuthorityDisposeform.value.concessionDetails,
        nonConformance:
          this.focApprovingAuthorityDisposeform.value.nonConformance,
        startDate: this.focApprovingAuthorityDisposeform.value.startDate,
        endDate: this.focApprovingAuthorityDisposeform.value.endDate,
        fileName: this.GlobeFileName,
        filePath: this.GlobeFilePath,
      };
      this.spinner.show();
      formData.append(
        'ApprovingAuthorityModal',
        JSON.stringify(approvingauthority)
      );
      this.immediateActionService
        .saveFOCApprovalDisposeForm(formData)
        .subscribe(
          (data: any) => {
            if (data[0].error == 'false') {
              this.sortDialogRef.close();
              this.toaster.success('Saved Successfully!');
              if (this.concessiontab == true) {
                this.SearchImmediateConcessionActions();
              } else if (this.downgradetab == true) {
                this.SearchImmediateDowngradeActions();
              } else if (this.disposetab == true) {
                this.SearchImmediateDisposeActions();
              }
            } else {
              this.toaster.error('Error', data[0].message);
            }
            this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
    } else {
      this.toaster.error('Error', 'Please enter all required fields');
    }
  }

  getFocReprocessApprovingAuthority(ele: any): void {
    const instance = {
      actionId: this.ReprocessActionId,
    };
    this.GlobeFileName = '';
    this.GlobeFilePath = '';
    this.immediateActionService
      .getFocReprocessApprovingAuthority(instance)
      .subscribe(
        (data: any) => {
          if (data != null && data.length != 0) {
            this.GlobeFileName = data[0].imageName;
            this.GlobeFilePath = data[0].imagePath;
            if (this.GlobeFilePath != '') {
              this.imageSrc =
                environment.O3CoreApiImageUrl +
                data[0].imagePath;
            }
            this.focApprovingAuthorityform
              .get('productionorderno')
              ?.setValue(data[0].productionorderno);
            this.focApprovingAuthorityform
              .get('customername')
              ?.setValue(data[0].customername);
            this.focApprovingAuthorityform
              .get('quantityorder')
              ?.setValue(data[0].quantityorder);
            this.focApprovingAuthorityform
              .get('quantityfailed')
              ?.setValue(data[0].quantityfailed);
            this.focApprovingAuthorityform
              .get('productdescription')
              ?.setValue(data[0].productdescription);
            this.focApprovingAuthorityform
              .get('ncrno')
              ?.setValue(data[0].ncrno);
            this.focApprovingAuthorityform
              .get('ccrno')
              ?.setValue(data[0].ccrno);
            this.focApprovingAuthorityform
              .get('descNonConformance')
              ?.setValue(data[0].descNonConformance);
            this.focApprovingAuthorityform
              .get('instructionForReprocessing')
              ?.setValue(data[0].instructionForReprocessing);
            this.focApprovingAuthorityform
              .get('salesReferenceNo')
              ?.setValue(data[0].salesReferenceNo);
            this.TotalMaterialCost = data[0].totalmaterialcost;
            this.focApprovingAuthorityform
              .get('totalmaterialcost')
              ?.setValue(data[0].totalmaterialcost);
            this.TotalWorkersCost = data[0].totalworkerscost;
            this.focApprovingAuthorityform
              .get('totalworkerscost')
              ?.setValue(data[0].totalworkerscost);
            this.TotalMachineCost = data[0].totalmachinecost;
            this.focApprovingAuthorityform
              .get('totalmachinecost')
              ?.setValue(data[0].totalmachinecost);

            let tempCalculate =
              Number(this.TotalMaterialCost) +
              Number(this.TotalWorkersCost) +
              Number(this.TotalMachineCost);
            this.focApprovingAuthorityform
              .get('totalcost')
              ?.setValue(tempCalculate);
            this.FormDate = moment(new Date(data[0].createdon)).format(
              'DD/MM/YYYY HH:mm:ss'
            );
            this.FormId = 'FOC-PRO-' + data[0].id;
            this.FOCReprocessFormId = data[0].id;
            this.isInternal = data[0].isinternal;
            if (
              data[0].materialdetail != null &&
              data[0].materialdetail.length != 0
            ) {
              let myarr = data[0].materialdetail;
              this.materialDataDetail = [];
              let counter = 1;
              myarr.forEach((element: any) => {
                element.id = counter;
                this.materialDataDetail.push(element);
                counter++;
              });
              this.materialData = new MatTableDataSource<any>(
                this.materialDataDetail
              );
              this.getPaginator(this.materialData, 0);
            } else {
              this.materialDataDetail = [];
              this.materialData = undefined;
              //this.getPaginator(this.materialData, 0);
            }
            if (
              data[0].workersdetail != null &&
              data[0].workersdetail.length != 0
            ) {
              let myarr = data[0].workersdetail;
              this.workersDataDetail = [];
              let counter = 1;
              myarr.forEach((element: any) => {
                element.id = counter;
                this.workersDataDetail.push(element);
                counter++;
              });
              this.workersData = new MatTableDataSource<any>(
                this.workersDataDetail
              );
              this.getPaginator(this.workersData, 1);
            } else {
              this.workersDataDetail = [];
              this.workersData = undefined;
              //this.getPaginator(this.workersData, 1);
            }
            if (
              data[0].machinedetail != null &&
              data[0].machinedetail.length != 0
            ) {
              let myarr = data[0].machinedetail;
              this.machineDataDetail = [];
              let counter = 1;
              myarr.forEach((element: any) => {
                element.id = counter;
                this.machineDataDetail.push(element);
                counter++;
              });
              this.machineData = new MatTableDataSource<any>(
                this.machineDataDetail
              );
              this.getPaginator(this.machineData, 2);
            } else {
              this.machineDataDetail = [];
              this.machineData = undefined;
              //this.getPaginator(this.machineData, 2);
            }
          } else {
            this.FOCReprocessFormId = 0;
            this.TotalMachineCost = 0;
            this.TotalMaterialCost = 0;
            this.TotalWorkersCost = 0;

            let tempCalculate =
              Number(this.TotalMaterialCost) +
              Number(this.TotalWorkersCost) +
              Number(this.TotalMachineCost);
            this.focApprovingAuthorityform
              .get('totalcost')
              ?.setValue(tempCalculate);
            this.FormDate = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
            this.FormId = '';
            this.machineDataDetail = [];
            this.machineData = undefined;
            //this.getPaginator(this.machineData, 2);
            this.workersDataDetail = [];
            this.workersData = undefined;
            //this.getPaginator(this.workersData, 1);
            this.materialDataDetail = [];
            this.materialData = undefined;
            //this.getPaginator(this.materialData, 0);
            this.isInternal = true;
            this.focApprovingAuthorityform
              .get('productionorderno')
              ?.setValue(this.NcrProcessOrder);
            this.focApprovingAuthorityform
              .get('customername')
              ?.setValue(this.NcrCustomerName);
            this.focApprovingAuthorityform
              .get('quantityorder')
              ?.setValue(this.QuantityHold);
            this.focApprovingAuthorityform
              .get('quantityfailed')
              ?.setValue(this.NcrQuantityWaste);
            this.focApprovingAuthorityform
              .get('productdescription')
              ?.setValue(this.NcrProductDescription);
            this.focApprovingAuthorityform.get('ncrno')?.setValue(this.NCRId);
            this.focApprovingAuthorityform
              .get('totalmaterialcost')
              ?.setValue(this.TotalMaterialCost);
            this.focApprovingAuthorityform
              .get('totalworkerscost')
              ?.setValue(this.TotalWorkersCost);
            this.focApprovingAuthorityform
              .get('totalmachinecost')
              ?.setValue(this.TotalMachineCost);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
  }

  getFocApprovingAuthorityDisposeForm(ele: any): void {
    this.GlobeFileName = '';
    this.GlobeFilePath = '';
    const instance = {
      actionId: this.ConcessionActionId,
    };
    this.immediateActionService
      .getFocApprovingAuthorityDisposeForm(instance)
      .subscribe(
        (data: any) => {
          if (data != null && data.length != 0) {
            this.GlobeFileName = data[0].imageName;
            this.GlobeFilePath = data[0].imagePath;
            if (this.GlobeFilePath != '') {
              this.imageSrc =
                environment.O3CoreApiImageUrl +
                data[0].imagePath;
            }
            this.TotalCost = data[0].totalcost;
            this.FOCDisposeFormId = data[0].id;
            this.focApprovingAuthorityDisposeform
              .get('concessionDetails')
              ?.setValue(data[0].concessionDetails);
            this.focApprovingAuthorityDisposeform
              .get('nonConformance')
              ?.setValue(data[0].nonConformance);
            this.focApprovingAuthorityDisposeform
              .get('startDate')
              ?.setValue(data[0].startDate);
            this.focApprovingAuthorityDisposeform
              .get('endDate')
              ?.setValue(data[0].endDate);
            this.focApprovingAuthorityDisposeform
              .get('salesReferenceNo')
              ?.setValue(data[0].salesReferenceNo);
            this.focApprovingAuthorityDisposeform
              .get('productdescription')
              ?.setValue(data[0].productdescription);
            this.focApprovingAuthorityDisposeform
              .get('customername')
              ?.setValue(data[0].customername);
            this.focApprovingAuthorityDisposeform
              .get('productcode')
              ?.setValue(data[0].productcode);
            this.focApprovingAuthorityDisposeform
              .get('productionorderno')
              ?.setValue(data[0].productionorderno);
            this.focApprovingAuthorityDisposeform
              .get('quantityhold')
              ?.setValue(data[0].quantityhold);
            this.focApprovingAuthorityDisposeform
              .get('quantityfailed')
              ?.setValue(data[0].quantityfailed);
            this.focApprovingAuthorityDisposeform
              .get('approvedby')
              ?.setValue(data[0].approvedby);
            this.focApprovingAuthorityDisposeform
              .get('preparedby')
              ?.setValue(data[0].preparedby);
            this.FormDate = moment(new Date(data[0].createdon)).format(
              'DD/MM/YYYY HH:mm:ss'
            );
            if (this.concessiontab == true) {
              this.FormId = 'FOC-CON-' + data[0].id;
            }
            if (this.downgradetab == true) {
              this.FormId = 'FOC-DOWN-' + data[0].id;
            }
            if (this.disposetab == true) {
              this.FormId = 'FOC-DIS-' + data[0].id;
            }
            // this.focApprovingAuthorityDisposeform.get('designno')?.setValue(data[0].designno);
            // this.focApprovingAuthorityDisposeform.get('disposedquantity')?.setValue(data[0].disposedquantity);
            this.focApprovingAuthorityDisposeform
              .get('disposedreason')
              ?.setValue(data[0].disposedreason);
            // this.focApprovingAuthorityDisposeform.get('referenceno')?.setValue(data[0].referenceno);
            this.focApprovingAuthorityDisposeform
              .get('reworkcost')
              ?.setValue(data[0].reworkcost);
            this.focApprovingAuthorityDisposeform
              .get('sortingcost')
              ?.setValue(data[0].sortingcost);
            this.focApprovingAuthorityDisposeform
              .get('othercost')
              ?.setValue(data[0].othercost);
            this.focApprovingAuthorityDisposeform
              .get('othercostremarks')
              ?.setValue(data[0].othercostremarks);
          } else {
            this.TotalCost = 0;
            this.FOCDisposeFormId = 0;
            this.FormId = '';
            this.FormDate = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
            this.focApprovingAuthorityDisposeform
              .get('productdescription')
              ?.setValue(this.NcrProductDescription);
            this.focApprovingAuthorityDisposeform
              .get('customername')
              ?.setValue(this.NcrCustomerName);
            this.focApprovingAuthorityDisposeform
              .get('productcode')
              ?.setValue(this.NcrProductCode);
            this.focApprovingAuthorityDisposeform
              .get('productionorderno')
              ?.setValue(this.NcrProcessOrder);
            this.focApprovingAuthorityDisposeform
              .get('quantityhold')
              ?.setValue(this.QuantityHold);
            this.focApprovingAuthorityDisposeform
              .get('quantityfailed')
              ?.setValue(this.NcrQuantityWaste);
            this.focApprovingAuthorityDisposeform
              .get('approvedby')
              ?.setValue(ele);
            this.focApprovingAuthorityDisposeform
              .get('preparedby')
              ?.setValue(ele);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
  }

  calculateTotalCost(): void {
    this.TotalCost =
      Number(this.focApprovingAuthorityDisposeform.value.reworkcost) +
      Number(this.focApprovingAuthorityDisposeform.value.sortingcost) +
      Number(this.focApprovingAuthorityDisposeform.value.othercost);
  }

  deleteImageFile() {
    this.imageFile = [];
    this.headerData.UploadFile = '';
    this.imageSrc = '';
  }

  openImageFile() {
    this.fileInput.nativeElement.click();
  }

  imageZoomModal(): void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      width: '500px',
      height: 'auto',
      data: this.imageSrc
        ? this.imageSrc
        : this.ImageUrl + this.headerData.UploadFile,
    });
  }

  readFile(event: any) {
    if (event.target.files && event.target.files.length) {
      this.imageFile = event.target.files[0];
      this.GlobeFileName = this.imageFile.name;
      // this.imageFileName = this.imageFile.name;

      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = async () => {
          this.imageSrc = reader.result as string;
        };
      }
    }
  }
}

function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}
