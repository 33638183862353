<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
    <p style="color: white">Fetching Data...</p>
</ngx-spinner>
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="displayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">Glass Physical Lab QA Dashboard </h1>
    </div>
  </div>
  <form [formGroup]="dbData">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row">

        <div class="inputField">
          <nz-date-picker class="calender-input" id="picker" (ngModelChange)="loadDashBoard(); getHourlyDefect()"
            formControlName="startDate" [nzDisabledDate]="" nzFormat="yyyy-MM-dd"></nz-date-picker>
          <span class="floating-text" style="margin-left: 10px;">Start Date</span>
        </div>
        <div class="inputField">
          <nz-date-picker class="calender-input" id="picker" formControlName="endDate" (ngModelChange)="loadDashBoard(); getHourlyDefect()"
            [nzDisabledDate]="" nzFormat="yyyy-MM-dd"></nz-date-picker>
          <span class="floating-text" style="margin-left: 10px;">End Date</span>
        </div>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Shift</mat-label>
          <mat-select panelClass="testClass" formControlName="shift" name="Shift" (selectionChange)="loadDashBoard(); getHourlyDefect()">
            <mat-option [value]="0">ALL</mat-option>
            <mat-option *ngFor="let s of shiftList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Crew</mat-label>
          <mat-select panelClass="testClass" formControlName="crew" name="Crew" (selectionChange)="loadDashBoard()">
            <mat-option [value]="0">ALL</mat-option>
            <mat-option *ngFor="let s of crewList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Thickness</mat-label>
          <mat-select panelClass="testClass" formControlName="thickness" name="Thickness" (selectionChange)="loadDashBoard()">
            <mat-option [value]="0">ALL</mat-option>
            <mat-option *ngFor="let s of thicknessList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </div>
  </form>

<div class="p-grid p-fluid" style="margin-top: 10px">
    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
        <div id="ZebraValue"></div>
    </div>
  
    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
        <div id="Pull(Ton/day)"></div>
    </div>

    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
        <div id="SeedCount"></div>
    </div>

    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
        <div id="BloomGrade"></div>
    </div>

    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
        <div id="ReamComparatorGrade"></div>
    </div>

    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
        <div id="TSV(mm)"></div>
    </div>

    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
        <div id="DefectDensity(#/sqm)"></div>
    </div>

    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
      <div id="Thickness(mm)"></div>
    </div>

    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
      <div id="MuStrain(psi)"></div>
    </div>
    
    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
      <div id="LastMeasuredCrossBow(mm)"></div>
    </div>

    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
      <div id="Corrugation(#)"></div>
    </div>

    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
      <div id="LastMeasuredWarpage(mm)"></div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12 p-xl-12">
      <div id="HourlyDefect"></div>
    </div>
</div>
