import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import * as  Highcharts from 'highcharts';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { ButtonViewRendererComponent } from 'src/app/components/button-view-render.components';
import { DatePipe } from '@angular/common';
import { RMDashboardService } from '../../services/rm-dashboard.service';
import Drilldown from 'highcharts/modules/drilldown';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import { ManagerExecutionService } from 'src/app/services/manager.service';

More(Highcharts);
Drilldown(Highcharts);
Exporting(Highcharts);
@Component({
  selector: 'app-rm-dashboard',
  templateUrl: './rm-dashboard.component.html',
  styleUrls: ['./rm-dashboard.component.scss']
})
export class RmDashboardComponent implements OnInit {
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('breakDownDialog') private breakDownDialog!: TemplateRef<any>;

  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  breadcrumList: any;
  GroupList!: any[];
  PlantList!: any[];
  LineList!: any[];
  dashboardData!: any;
  highCharts: any;
  image!: any;
  currentImagePath!: any;
  defaultExcelExportParams: any;
  frameworkComponents: any;

  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  blobStorageUrl: string = '';

  gridOptions: any = {
    defaultColDef: {
      rowHeight: 45,
      rowStyle: {
        'border-bottom': 'white 10px solid',
        'border-top': 'white 10px solid',
        "max-height": "50px",
      },
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
  };
  unitPickerIsOpen: boolean = false;
  isPickFromUnit: boolean = false;
  HeaderData: any = {
    Group: '',
    StartDate: new Date(
      moment().subtract(1, 'd').set('hour', 6).set('minutes', 0).toString()
    ),
    EndDate: new Date(),
    Plant: '',
    Line: '',
  };
  HeaderForm: any;
  TotalWO: any = 0;
  ClosedWO: any = 0;
  CompletedWO: any = 0;
  OpenedWO: any = 0;
  UsedSpareParts: any = 0;
  TreeData: any = [];

  Minor: any = 0;
  Moderate: any = 0;
  Major: any = 0;
  ResponseTime: any = 0;
  TroubleShootTime: any = 0;
  WaitingSPTime: any = 0;
  UnitsInGroup: any = [];
  PlantsInGroup: any = [];
  UnitsByLine: any = [];
  ComponentByUnit: any = [];

  drilldownData: any;
  spinnerText: string = "";
  userList: any = [];
  openedPercentage: any;
  completedPercentage: any;
  closedPercentage: any;
  minorArr: any = [];
  moderateArr: any = [];
  majorArr: any = [];
  chartMinor!: Chart;
  chartModerate!: Chart;
  chartMajor!: Chart;
  isMinorChart: boolean = false;
  isModerateChart: boolean = false;
  isMajorChart: boolean = false;

  constructor(
    private productivityservice: ProductivityService,
    private toaster: ToastrService,
    public router: Router,
    private userService: UserManagementService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private pmDashboardService: RMDashboardService,
    private fb: FormBuilder,
    public managerExecutionService: ManagerExecutionService) {
    this.frameworkComponents = {
      buttonRenderer: ButtonViewRendererComponent,
    }
    this.columnDefs = [
      {
        headerName: 'Line/Machine', field: 'units.puDesc', cellRenderer: function (params: any) {
          let html = params && params.data ? params.data.line.plDesc + '/' + params.data.units.puDesc : '';
          return html;
        },
        
      },
      { headerName: 'WO Id', field: 'WOId', filter: 'agTextColumnFilter',  },
      { headerName: 'WO Type', field: 'WOType.Name', filter: 'agTextColumnFilter',  },
      { headerName: 'Failure Mode', field: 'FailureMode.Name', filter: 'agTextColumnFilter',  },
      { headerName: 'WO Status', field: 'WOStatus', filter: 'agTextColumnFilter',  },
      { headerName: 'Opearator Remark', field: 'Remark', filter: 'agTextColumnFilter',  },
      {
        headerName: 'WO Raise Time', field: 'CreatedAt', filter: 'agTextColumnFilter', valueFormatter: function (params: any) { 
          return params.value !== null ? moment(params.value).format('MM/DD/YYYY HH:mm:ss a') : '' 
        }, 
      },
      {
        headerName: 'Operator Picture', field: 'UploadPicture', cellRenderer: function (params: any) {
          let html = '';
          if (params.value) {
            html = `<img style="width:40px;height:40px ;cursor:pointer" alt="${params.value}" src="${ environment.O3CoreApiImageUrl  + params.value}">`
          }
          return html;
        },
        
      },
      {
        headerName: 'Manager Assign Time', field: 'managerExc.CreatedAt', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? moment(html).format('MM/DD/YYYY HH:mm:ss a') : '';
        },
        
      },
      {
        headerName: 'Technician', field: 'managerExc.assignUser.UserName', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Priority', field: 'managerExc.Priority.Name', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'SparePartCode', field: 'managerExc.SparePartCode', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Manager Remark', field: 'managerExc.ManagerRemarks', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Technician Start Time', field: 'techExc.StartTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? moment(html).format('MM/DD/YYYY HH:mm:ss a') : '';
        },
        
      },
      {
        headerName: 'SP Request Time', field: 'techExc.SPRequestTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? moment(html).format('MM/DD/YYYY HH:mm:ss a') : '';
        },
        
      },
      {
        headerName: 'SP Received Time', field: 'techExc.SPReceivedTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? moment(html).format('MM/DD/YYYY HH:mm:ss a') : '';
        },
        
      },
      {
        headerName: 'Technician Complete Time', field: 'techExc.CompleteTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? moment(html).format('MM/DD/YYYY HH:mm:ss a') : '';
        },
        
      },
      {
        headerName: 'Technician Remark', field: 'techExc.Remarks', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Technician Picture', field: 'techExc.AfterPicture', cellRenderer: function (params: any) {
          let html = '';
          if (params && params.value) {
            html = `<img style="width:40px;height:40px ;cursor:pointer" alt="${params.value}" src="${ environment.O3CoreApiImageUrl  + params.value}">`
          }
          return html;
        },
        
      },
      {
        headerName: 'Response Time', field: 'ResponseTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value + ' ' + 'min' : '';
          return html;
        },
        
      },
      {
        headerName: 'TroubleShoot Time', field: 'TroubleShootTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value + ' ' + 'min' : '';
          return html;
        },
        
      },
      {
        headerName: 'Waiting SP Time', field: 'WaitingSPTime', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value + ' ' + 'min' : '';
          return html;
        },
        
      },
    ];

    this.defaultExcelExportParams = {
      addImageToCell: function (rowIndex: number, col: any, value: any, callback: any) {
        if (col.colId === 'PreImage' || col.colId === 'PostImage') {

        } else {
          return;
        }
      },
    }

  }

  ngOnInit(): void {
    this.blobStorageUrl = environment.O3CoreApiImageUrl;
    this.HeaderForm = this.fb.group({
      Group: ['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      Plant: ['', Validators.required],
      Line: ['', Validators.required],
    });
    this.GetBreadcrumList();
    this.getGroup();
    this.getUserList();
  };

  getUserList() {
    this.spinnerText = "Fetching User Data";
    this.spinner.show();
    this.managerExecutionService.getUserList().subscribe((res: any) => {
      this.userList = res.UserList;
      this.spinner.hide();
    }, (err: any) => {
      this.spinner.hide();
    });
  };

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  };

  getGroup(): void {
    const key = {
      UserId: 206
    };
    const id = {
      value: 0
    };
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      if (this.GroupList && this.GroupList.length) {
        this.HeaderForm.get('Group')?.setValue(this.GroupList[0].EG_Id);
      }
      this.filteredGroups = this.GroupList.slice();
      this.getPlants();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  };

  getPlants(): void {
    const key = {
      EG_ID: this.HeaderForm.value.Group,
      userId: 206
    };
    this.spinner.show();
    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.spinner.hide();
      this.PlantList = data.lstPlant;
      this.filteredPlants = this.PlantList.slice();

      if (this.PlantList && this.PlantList.length)
        this.HeaderForm.get('Plant')?.setValue(this.PlantList[0].PT_Id);
      this.getLine();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getLine() {
    const key = {
      PT_ID: this.HeaderForm.value.Plant,
      // UserId: ''
      userId: 206
    };
    this.spinner.show();
    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.spinner.hide();
      this.LineList = data.lstLine;
      this.filteredLines = this.LineList.slice();
      if (this.LineList && this.LineList.length){
        this.HeaderForm.get('Line')?.setValue(this.LineList[0].PL_Id);
        this.getRMDashboard();
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getRMDashboard() {
    if (!this.HeaderData.Group || !this.HeaderData.Line || !this.HeaderData.StartDate || !this.HeaderData.EndDate) {
      return
    }
    else {
      this.spinner.show();
      this.pmDashboardService.getRMDashboardData(this.HeaderData).subscribe((res: any) => {
        this.spinner.hide();
        this.dashboardData = res;
        let dummydata = {
          point: {
            options: {
              drilldown: '-1'
            }
          }
        }
        this.getTableData(dummydata, this.HeaderData.Line);
        this.setData();
        this.spinner.hide();
      }, (error: any) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();
      });
    }
  };

  setData() {
    if (this.dashboardData !== null) {
      this.TotalWO = this.dashboardData.TotalWO.length;
      this.ClosedWO = this.dashboardData.ClosedWO.length;
      this.CompletedWO = this.dashboardData.CompletedWO.length;
      this.OpenedWO = this.dashboardData.OpenedWO.length;
      this.UsedSpareParts = this.dashboardData.UsedSpareParts.length;
      this.Minor = 0; this.Moderate = 0; this.Major = 0;
      this.minorArr = []; this.moderateArr = []; this.majorArr = [];
      if (this.TotalWO) {
        this.openedPercentage = Math.floor((100 * this.OpenedWO) / this.TotalWO);
        this.completedPercentage = Math.floor((100 * this.CompletedWO) / this.TotalWO);
        this.closedPercentage = Math.floor((100 * this.ClosedWO) / this.TotalWO);
      }
      if (this.CompletedWO) {
        let totalFixedMin: any = 0, startDate: any, completeDate: any;
        for (const row of this.dashboardData.CompletedWO) {
          startDate = row && row.CreatedAt ? row.CreatedAt : '';
          completeDate = row && row.techExc && row.techExc.CompleteTime ? row.techExc.CompleteTime : '';
          startDate = startDate ? new Date(startDate) : new Date();
          completeDate = completeDate ? new Date(completeDate) : new Date();
          const diffTime = Math.abs(completeDate - startDate);
          const diffMin = Math.floor(diffTime / 60000);
          totalFixedMin += diffMin;
          const obj = { timeMin: diffMin, woID: row.WOId, machine: row.units.puDesc }
          if (diffMin <= 30) {
            this.Minor++;
            this.minorArr.push(obj)
          }
          else if (diffMin > 30 && diffMin <= 600) {
            this.Moderate++;
            this.moderateArr.push(obj)
          }
          else if (diffMin > 600) {
            this.Major++;
            this.majorArr.push(obj)
          }
        }
      }
      if (this.TotalWO) {
        let totalResponseTime: any = 0, totalTroubleShootTime: any = 0, totalWaitingSPTime: any = 0, TroubleShootTimeLength: any = 0, WaitingSPTimeLength: any = 0;
        for (const row of this.dashboardData.TotalWO) {
          if (row.techExc && row.techExc.StartTime) {
            let woRaiseTime: any = row.CreatedAt;
            let startJobTime: any = row.techExc.StartTime;
            woRaiseTime = new Date(woRaiseTime);
            startJobTime = new Date(startJobTime);
            var diff =(startJobTime.getTime() - woRaiseTime.getTime()) / 1000;
            diff /= 60;
            const diffResponseMin =Math.abs(Math.round(diff));
            totalResponseTime += diffResponseMin;
          }
          if (row.techExc && row.techExc.SPRequestTime) {
            let jobStartTime: any = row.techExc.StartTime;
            let spRequestTime: any = row.techExc.SPRequestTime;
            jobStartTime = new Date(jobStartTime);
            spRequestTime = new Date(spRequestTime);
            var diff =(spRequestTime.getTime() - jobStartTime.getTime()) / 1000;
            diff /= 60;
            const diffTroubleShootMin =Math.abs(Math.round(diff));
            totalTroubleShootTime += diffTroubleShootMin;
            TroubleShootTimeLength++;
          }
          if (row.techExc && row.techExc.SPRequestTime && row.techExc.SPReceivedTime) {
            let RequestSPTime: any = row.techExc.SPRequestTime;
            let spReceivedTime: any = row.techExc.SPReceivedTime;
            RequestSPTime = new Date(RequestSPTime);
            spReceivedTime = new Date(spReceivedTime);
            var diff =(spReceivedTime.getTime() - RequestSPTime.getTime()) / 1000;
            diff /= 60;
            const diffWaitingSPMin =Math.abs(Math.round(diff));
            totalWaitingSPTime += diffWaitingSPMin;
            WaitingSPTimeLength++;
          }
        }
        this.ResponseTime = Math.floor(totalResponseTime / this.dashboardData.TotalWO.filter(x=>x.techExc?.StartTime !=null && x.techExc?.StartTime !=undefined).length);
        this.TroubleShootTime = Math.floor(totalTroubleShootTime / TroubleShootTimeLength);
        this.WaitingSPTime = Math.floor(totalWaitingSPTime / WaitingSPTimeLength);
      }
      else {
        this.ResponseTime = 0;
        this.TroubleShootTime = 0;
        this.WaitingSPTime = 0;
      }
    }
  };

  getMinerChart() {
    if (this.Minor == 0)
      return
    this.isMinorChart = true;
    this.isModerateChart = false;
    this.isMajorChart = false;
    let minorLabel: any[] = new Array();
    let dialogRef = this.dialog.open(this.breakDownDialog, { disableClose: true, height: 'unset', width: '90%' });
    let minorData: any[] = new Array();

    this.minorArr.forEach((element: any) => {
      minorLabel.push('WO ID : ' + element.woID);
      minorData.push({
        name: element.woID,
        y: parseFloat(element.timeMin),
        machine: element.machine,
        color: '#0f0fd1'
      });
    });
    let minorBreakdown: any = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: minorLabel,
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter(this: any) {
          return 'Minutes : ' + this.point.y + '<br/>Machine : ' + this.point.machine
        }
      },
      series: [{
        name: 'Minor Breakdown',
        type: 'column',
        data: minorData
      }]
    };
    let chart = new Chart(minorBreakdown);
    this.chartMinor = chart;
  };

  getModerateChart() {
    if (this.Moderate == 0)
      return
    this.isMinorChart = false;
    this.isModerateChart = true;
    this.isMajorChart = false;
    let moderateLabel: any[] = new Array();
    let dialogRef = this.dialog.open(this.breakDownDialog, { disableClose: true, height: 'unset', width: '90%' });
    let moderateData: any[] = new Array();
    this.moderateArr.forEach((element: any) => {
      moderateLabel.push('WO ID : ' + element.woID);
      moderateData.push({
        name: element.woID,
        y: parseFloat(element.timeMin),
        machine: element.machine,
        color: '#0f0fd1'
      });
    });
    let moderateBreakdown: any = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: moderateLabel,
      },
      tooltip: {
        formatter(this: any) {
          return 'Minutes : ' + this.point.y + '<br/>Machine : ' + this.point.machine
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Moderate Breakdown',
        type: 'column',
        data: moderateData
      }]
    };
    let chart = new Chart(moderateBreakdown);
    this.chartModerate = chart;
  };

  getMajorChart() {
    if (this.Major == 0)
      return
    this.isMinorChart = false;
    this.isModerateChart = false;
    this.isMajorChart = true;
    let majorLabel: any[] = new Array();
    let dialogRef = this.dialog.open(this.breakDownDialog, { disableClose: true, height: 'unset', width: '90%' });
    let majorData: any[] = new Array();
    this.majorArr.forEach((element: any) => {
      majorLabel.push('WO ID : ' + element.woID);
      majorData.push({
        name: element.woID,
        y: parseFloat(element.timeMin),
        machine: element.machine,
        color: '#0f0fd1'
      });
    });
    let majorBreakdown: any = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: majorLabel,
      },
      tooltip: {
        formatter(this: any) {
          return 'Minutes : ' + this.point.y + '<br/>Machine : ' + this.point.machine
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Major Breakdown',
        type: 'column',
        data: majorData
      }]
    };
    let chart = new Chart(majorBreakdown);
    this.chartMajor = chart;
  };

  getTableData(event: any, line: any) {
    if (event.point.options.drilldown) {
      let data = {
        StartDate: this.HeaderData.StartDate,
        EndDate: this.HeaderData.EndDate,
        ChartUnit: event.point.options.drilldown,
        ChartLine: event.point.options.drilldown != '-1' ? '' : line
      }
      this.spinner.show();
      this.pmDashboardService.getTableData(data).subscribe((res: any) => {
        this.spinner.hide();
        this.rowData = res.TableData && res.TableData.length ? res.TableData : [];
        if (this.rowData && this.rowData.length) {
          let i = 0;
          for (const row of this.rowData) {
            if (row.managerExc) {
              let userObj = this.userList.find((user: any) => {
                return user.UserId == row.managerExc.assignUser
              });
              if (userObj)
                row.managerExc.assignUser = userObj
            }
            if (row.techExc) {
              if (row.techExc.AfterPicture) {
                let techImages = row.techExc.AfterPicture.split(',');
                row.techExc.AfterPicture = techImages[techImages.length - 1];
              }

              let woRaiseTime: any = row.CreatedAt;
              let startJobTime: any = row.techExc.StartTime;
              woRaiseTime = new Date(woRaiseTime);
              startJobTime = new Date(startJobTime);
              const diffResponseTime = Math.abs(startJobTime - woRaiseTime);
              const diffResponseMin = Math.round(diffResponseTime / 60000);
              row.ResponseTime = diffResponseMin;
            }

            if (row.techExc && row.techExc.SPRequestTime) {
              let jobStartTime: any = row.techExc.StartTime;
              let spRequestTime: any = row.techExc.SPRequestTime;
              jobStartTime = new Date(jobStartTime);
              spRequestTime = new Date(spRequestTime);
              const diffTroubleShootTime = spRequestTime - jobStartTime;
              const diffTroubleShootMin = Math.abs(Math.round(diffTroubleShootTime / 60000));
              row.TroubleShootTime = diffTroubleShootMin;
            }

            if (row.techExc && row.techExc.SPRequestTime && row.techExc.SPReceivedTime) {
              let RequestSPTime: any = row.techExc.SPRequestTime;
              let spReceivedTime: any = row.techExc.SPReceivedTime;
              RequestSPTime = new Date(RequestSPTime);
              spReceivedTime = new Date(spReceivedTime);
              const diffWaitingSPTime = spReceivedTime - RequestSPTime;
              const diffWaitingSPMin = Math.abs(Math.round(diffWaitingSPTime / 60000));
              row.WaitingSPTime = diffWaitingSPMin;
            }

            // if (row.ClosedAt) {
            //   let JobStartTime: any = row.CreatedAt;
            //   let JobSolvedTime: any = row.ClosedAt;
            //   JobStartTime = new Date(JobStartTime);
            //   JobSolvedTime = new Date(JobSolvedTime);
            //   const diffMDTTime = Math.abs(JobSolvedTime - JobStartTime);
            //   const diffMDTMin = Math.floor(diffMDTTime / 60000);
            //   row.MDTTime = diffMDTMin;
            // }

            // if (row.WOStatus == '2' || row.WOStatus == '3') {
            //   let startDate: any = row.CreatedAt;
            //   let completeDate: any = row.techExc && row.techExc.CompleteTime && row.techExc.CompleteTime ? row.techExc.CompleteTime : '';
            //   startDate = new Date(startDate);
            //   completeDate = new Date(completeDate);
            //   const diffTime = Math.abs(completeDate - startDate);
            //   const diffMin = Math.floor(diffTime / 60000);
            //   row.MTTRTime = diffMin;
            // }

            // if ((this.rowData.length - 1) > i) {
            //   let fromDate: any = this.rowData[i].CreatedAt;
            //   let toDate: any = this.rowData[i + 1].CreatedAt;
            //   fromDate = new Date(fromDate);
            //   toDate = new Date(toDate);
            //   const diffTime = Math.abs(toDate - fromDate);
            //   const diffMin = Math.floor(diffTime / 60000);
            //   row.MTBFTime = diffMin;
            // }

            row.WOStatus = row.WOStatus == '1' ? 'Open' : row.WOStatus == '2' ? 'Close' : row.WOStatus == '3' ? 'Complete' : row.WOStatus == '4' ? 'Delay' : 'In Progress';
            if (row.UploadPicture) {
              let operatorImages = row.UploadPicture.split(',');
              row.UploadPicture = operatorImages[operatorImages.length - 1];
            }
            i++;
          }
        }
      }, (error: any) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();
      });
    }
  };

  /// AG Grid 
  onGridReady(params: any): any {
    this.gridApi = params.api;
  };

  excelExport() {
    this.gridApi.exportDataAsExcel();
  };

  csvExport() {
    this.gridApi.exportDataAsCsv();
  };

  cellClicked(params: any): void {
    const col = params.colDef.field;
    if (col == 'UploadPicture' || col == 'techExc.AfterPicture') {
      this.image = params && params.value ? params.value : '';
      if (this.image != '')
        this.openPictureBeforeModal();
    }
  };

  openPictureBeforeModal(): void {
    this.currentImagePath = this.image === ''
      ? 'assets/images/no-image.png'
      : environment.O3CoreApiImageUrl + this.image;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  };

  imageZoomModal(imagePath: String): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  };
}
