<ngx-spinner bdColor="rgba(0, 0, 0, 0)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
<div dir="{{languageDir}}">
  <div class="machine-parameter-content">
    <div class="content-top-box">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
      </ul>
      <div class="tab-content-top-box">
        <h1 class="main-title">
          {{
          langObj ? langObj?.MeetingScheduler : ("MeetingScheduler" | translate)
          }}
        </h1>
      </div>
      <form [formGroup]="MeetingDefForm">
        <div class="content-top-row" style="display: flex; justify-content: flex-end">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>{{
              langObj ? langObj?.SelectGroup : ("SelectGroup" | translate)
              }}</mat-label>
            <mat-select panelClass="testClass" name="group" formControlName="group"
              (selectionChange)="getPlantByGroup()">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groupListDDL"
              (filteredReturn)="filteredGroupListDDL = $event"></mat-select-filter>
                <mat-option *ngFor="let g of filteredGroupListDDL" [value]="g.TypeId">
                  {{ g.TypeName }}
                </mat-option>
            </mat-select>
            <mat-error>{{
              langObj ? langObj?.grroup : ("grroup" | translate)
              }}</mat-error>
          </mat-form-field>
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>{{
              langObj ? langObj?.SelectPlant : ("SelectPlant" | translate)
              }}</mat-label>
            <mat-select panelClass="testClass" name="plant" formControlName="plant" (selectionChange)="getKpiByPlant()">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plants"
              (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                <mat-option *ngFor="let p of filteredPlants" [value]="p.DeptId">
                  {{ p.DeptDesc }}
                </mat-option>
            </mat-select>
            <mat-error>{{
              langObj ? langObj?.plant : ("plant" | translate)
              }}</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="wrapper-box wrapper-box-shadow grid-container">
      <form [formGroup]="MeetingDefForm" (ngSubmit)="MeetingDefFormMode ? saveMeetingDef() : updateMeetingDef()">
        <input matInput formControlName="meetingDefId" name="meetingDefId" hidden />
        <div class="content-top-row content-top-row-order-processing">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="margin-left: 10px">
            <mat-label>{{
              langObj ? langObj?.MeetingName : ("MeetingName" | translate)
              }}</mat-label>
            <input matInput formControlName="meeting" name="meeting" required />
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>{{
              langObj
              ? langObj?.MeetingFrequency
              : ("MeetingFrequency" | translate)
              }}</mat-label>
            <mat-select formControlName="frequency" required>
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="frequencies"
              (filteredReturn)="filteredFrequencies = $event"></mat-select-filter>
              <mat-option
                *ngFor="let frequency of filteredFrequencies"
                [value]="frequency.masterFrequencyId"
              >
                {{ frequency.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{
              langObj ? langObj?.SelMeetFreq : ("SelMeetFreq" | translate)
              }}</mat-error>
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select formControlName="kpi" placeholder="{{
              langObj ? langObj?.SelectKPI : ('SelectKPI' | translate)
            }}" multiple>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="no match found"
                  [formControl]="kpiSearchCtrl"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let kpi of filteredKpisMulti | async" [value]="kpi.kpiCode">{{ kpi.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>{{
              langObj ? langObj?.SelectLevel : ("SelectLevel" | translate)
              }}</mat-label>
            <mat-select formControlName="level" (selectionChange)="onChangeLevel($event)" required>
              <mat-option value="Plant">{{
                langObj ? langObj?.plnt : ("plnt" | translate)
                }}</mat-option>
              <mat-option value="Department">{{ langObj ? langObj?.Department : ("Department" | translate) }}
              </mat-option>
              <mat-option value="Pillar">{{
                langObj ? langObj?.Pillar : ("Pillar" | translate)
                }}</mat-option>
            </mat-select>
            <mat-error>{{
              langObj ? langObj?.plsselectlvl : ("plsselectlvl" | translate)
              }}</mat-error>
          </mat-form-field>
          <mat-form-field appearence="fill" *ngIf="departmenthide"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>{{
              langObj
              ? langObj?.SelectDepartment
              : ("SelectDepartment" | translate)
              }}</mat-label>
            <mat-select formControlName="department">
              <mat-option *ngFor="let department of departments" [value]="department.TypeId">
                {{ department.TypeName }}
              </mat-option>
            </mat-select>

            <mat-error>{{
              langObj ? langObj?.plsselctdept : ("plsselctdept" | translate)
              }}</mat-error>
          </mat-form-field>
          <mat-form-field appearence="fill" *ngIf="pillarhide"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>{{
              langObj ? langObj?.SelectPillar : ("SelectPillar" | translate)
              }}</mat-label>
            <mat-select formControlName="pillar">
              <mat-option *ngFor="let pillar of pillars" [value]="pillar.pillarId">{{ pillar.name }}
              </mat-option>
            </mat-select>
            <mat-error>{{
              langObj ? langObj?.plsslctpillar : ("plsslctpillar" | translate)
              }}</mat-error>
          </mat-form-field>
        </div>
        <div class="content-top-row content-top-row-order-processing">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="margin-left: 10px">
            <mat-select formControlName="meetingEvaluation" placeholder="{{
              langObj
                ? langObj?.MeetingEvaluations
                : ('MeetingEvaluations' | translate)
            }}" required>
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="MeetingEvaluations"
            (filteredReturn)="filteredMeetingEvaluations = $event"></mat-select-filter>
              <mat-option
                *ngFor="let eval of filteredMeetingEvaluations"
                [value]="eval.meetingId"
              >
                {{ eval.name }} -
                <span
                  *ngIf="eval.type === 'Evaluation Check List'"
                  style="color: #10ce9c"
                  >{{ eval.type }}</span
                ><span
                  *ngIf="eval.type === 'Evaluation Level'"
                  style="color: #ff2121"
                  >{{ eval.type }}</span
                >
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="margin-left: 10px">
            <mat-select ngxMatSelect [hasSearchBox]="true" [useMobileView]="true" mobileViewType="BottomSheet"
              (selectionChange)="setAutoParticepent()" [formControlName]="'leader'" [multiple]="false"
              name="empFullName" [required]="true" [source]="UserDDL" [displayMember]="'empFullName'" placeholder="{{
              langObj
                ? langObj?.plslsctmeetingleader
                : ('plslsctmeetingleader' | translate)
            }}" [valueMember]="'EmpInfo_Id'" #sf="ngxMatSelect">
              <mat-option [value]="item.EmpInfo_Id" *ngFor="let item of sf.filteredSource">
                {{ item.empFullName }}
              </mat-option>
            </mat-select>
            <mat-error>{{
              langObj
              ? langObj?.plslsctmeetingleader
              : ("plslsctmeetingleader" | translate)
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select ngxMatSelect #ms4="ngxMatSelect" [displayMember]="'empFullName2'" [valueMember]="'EmpInfo_Id'"
              [source]="UserDDL" [useInfiniteScroll]="true" formControlName="participants" [multiple]="true"
              placeholder="{{
              langObj
                ? langObj?.MeetingParticipants
                : ('MeetingParticipants' | translate)
            }}">
              <mat-option [value]="item.EmpInfo_Id" *ngFor="let item of ms4.filteredSource" [disabled]="
                MeetingDefForm.controls['leader'].value === item.EmpInfo_Id
                  ? true
                  : false
              ">
                {{ item.empFullName }}
              </mat-option>
            </mat-select>

            <mat-error>{{
              langObj
              ? langObj?.plsslctmeetingparticipants
              : ("plsslctmeetingparticipants" | translate)
              }}
            </mat-error>
          </mat-form-field>
          <!-- <div class="inputField">
          <nz-date-picker
            class="calender-input"
            id="startPicker"
            [nzDisabledDate]="disabledDate"
            formControlName="startTime"
            nzFormat="yyyy-MM-dd HH:mm"
            [nzShowTime]="{ nzFormat: 'HH:mm' }"
            [nzInputReadOnly]="true"
          ></nz-date-picker>
          <span class="floating-text">{{
            langObj
              ? langObj?.MeetingStartTime
              : ("MeetingStartTime" | translate)
          }}</span>
        </div>
        <div class="inputField">
          <nz-date-picker
            class="calender-input"
            id="endPicker"
            [nzDisabledDate]="disabledDate"
            formControlName="endTime"
            nzFormat="yyyy-MM-dd HH:mm"
            [nzShowTime]="{ nzFormat: 'HH:mm' }"
            [nzInputReadOnly]="true"
          ></nz-date-picker>
          <span class="floating-text">{{
            langObj ? langObj?.MeetingEndTime : ("MeetingEndTime" | translate)
          }}</span>
        </div> -->
          <button type="submit" mat-mini-fab mat-button [color]="MeetingDefFormMode ? 'accent' : 'primary'"
            [pTooltip]="MeetingDefFormMode ? 'Save' : 'Update'" tooltipPosition="top" [disabled]="!MeetingDefForm.valid"
            style="margin-left: 20px; cursor: pointer" *ngIf="IsWrite">
            <i [class]="MeetingDefFormMode ? 'fa fa-floppy-o' : 'fa fa-pencil'" [ngStyle]="
              MeetingDefFormMode ? { color: '#fff' } : { color: '#f2f2f2' }
            "></i>
          </button>
          <button (click)="resetForm()" mat-mini-fab mat-button class="add-btn1" style="margin-left: 10px"
            pTooltip="Reset" tooltipPosition="top">
            <img src="assets/images/refresh1.png" alt="" width="20px" height="20px" />
          </button>
        </div>
        <div class="content-top-row content-top-row-order-processing" style="justify-content: flex-end">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <input matInput placeholder="{{langObj ? langObj?.Search : ('Search' | translate)}}"
              (keyup)="applyFilter($event)" autocomplete="off" />
          </mat-form-field>
        </div>
      </form>
      <table mat-table class="basic-table plant-model-table" [dataSource]="MeetingData" matSort>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-text">
              {{ langObj ? langObj?.Actions : ("Actions" | translate) }}
            </p>
          </th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <div style="margin-left: 3px">
                <mat-slide-toggle [pTooltip]="element.inActive ? 'inActive' : 'Active'" tooltipPosition="top"
                  [checked]="!element.inActive" (change)="onCheckboxChange($event, element)"></mat-slide-toggle>
              </div>
              <button mat-icon-button class="node-control-btn node-comments" *ngIf="IsWrite"
                pTooltip="Meeting Questions" tooltipPosition="top" (click)="openQuestionModel(element)">
                <mat-icon style="color: #ffffff; width: 25px; height: 25px">chat</mat-icon>
              </button>
              <button mat-icon-button class="edit-btn" aria-label="Edit Row" *ngIf="IsWrite && !element.inActive"
                pTooltip="Edit Meeting" tooltipPosition="top" (click)="editMeetingDef(element)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button class="node-control-btn node-delete" aria-label="delete item"
                *ngIf="IsWrite && !element.inActive" [disabled]="element.participantAttendExist > 0 "
                [ngStyle]="{'background-color':element.participantAttendExist > 0 ? '#a0a0a0' : 'f85439' }"
                pTooltip="Delete Meeting" tooltipPosition="top" (click)="deleteMeetingDef(element)">
                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button class="node-control-btn node-create" aria-label="create item"
                pTooltip="Add Agendas" tooltipPosition="top" *ngIf="IsWrite && !element.inActive"
                (click)="addAgendaModal(element)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.5783 7.10211H6.91168V11.7688H5.35612V7.10211H0.689453V5.54655H5.35612V0.879883H6.91168V5.54655H11.5783V7.10211Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button class="node-control-btn node-create" aria-label="create item" pTooltip="View"
                tooltipPosition="top" (click)="openViewModal(element)">
                <svg width="20" height="16" class="svg-icon" viewBox="2 2 16 16" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10,6.978c-1.666,0-3.022,1.356-3.022,3.022S8.334,13.022,10,13.022s3.022-1.356,3.022-3.022S11.666,6.978,10,6.978M10,12.267c-1.25,0-2.267-1.017-2.267-2.267c0-1.25,1.016-2.267,2.267-2.267c1.251,0,2.267,1.016,2.267,2.267C12.267,11.25,11.251,12.267,10,12.267 M18.391,9.733l-1.624-1.639C14.966,6.279,12.563,5.278,10,5.278S5.034,6.279,3.234,8.094L1.609,9.733c-0.146,0.147-0.146,0.386,0,0.533l1.625,1.639c1.8,1.815,4.203,2.816,6.766,2.816s4.966-1.001,6.767-2.816l1.624-1.639C18.536,10.119,18.536,9.881,18.391,9.733 M16.229,11.373c-1.656,1.672-3.868,2.594-6.229,2.594s-4.573-0.922-6.23-2.594L2.41,10l1.36-1.374C5.427,6.955,7.639,6.033,10,6.033s4.573,0.922,6.229,2.593L17.59,10L16.229,11.373z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button aria-label="reschedule meeting" class="node-control-btn node-reschedule"
                pTooltip="Reschedule Meeting" tooltipPosition="top" (click)="rescheduleMeeting(element)">
                <mat-icon style="color: #ffffff; width: 25px; height: 25px">event_repeat</mat-icon>
              </button>
              <button mat-icon-button aria-label="KPI Order List" class="node-control-btn node-reschedule"
                pTooltip="KPI Order List" tooltipPosition="top" (click)="openKPIOrderList(element)" style="
                background-color: hsl(270deg 96% 48% / 82%);
                color: #ffffff;
              ">
                <mat-icon style="color: #ffffff; width: 25px; height: 25px">timeline</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">
              {{ langObj ? langObj?.Id : ("Id" | translate) }}
            </p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.meetingDefId }}</td>
        </ng-container>
        <ng-container matColumnDef="meetingname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">
              {{ langObj ? langObj?.MeetingName : ("MeetingName" | translate) }}
            </p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.meetingName }}</td>
        </ng-container>
        <ng-container matColumnDef="startTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">
              {{ langObj ? langObj?.StartTime : ("StartTime" | translate) }}
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.StartTime | date: "medium" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="endTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">
              {{ langObj ? langObj?.EndTime : ("EndTime" | translate) }}
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.EndTime | date: "medium" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="leadername">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">
              {{ langObj ? langObj?.Leader : ("Leader" | translate) }}
            </p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
        </ng-container>
        <ng-container matColumnDef="frequency">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">
              {{ langObj ? langObj?.freq : ("freq" | translate) }}
            </p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.frequencyName }}</td>
        </ng-container>
        <ng-container matColumnDef="meetingMasterName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">
              {{
              langObj
              ? langObj?.MeetingEvaluation
              : ("MeetingEvaluation" | translate)
              }}
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.meetingMasterName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="level">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">
            <p class="header-text">
              {{ langObj ? langObj?.Level : ("Level" | translate) }}
            </p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.levelName }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="meetingColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: meetingColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            {{ langObj ? langObj?.nodatamatch : ("nodatamatch" | translate) }}
          </td>
        </tr>
      </table>
      <mat-paginator class="basic-paginataor" [pageSizeOptions]="[3, 5, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>

  <ng-template #displayAgendaModal>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>
          {{
          langObj ? langObj?.addmeetingagenda : ("addmeetingagenda" | translate)
          }}
        </h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>

    <form [formGroup]="AddAgendaForm">
      <mat-dialog-content>
        <div formArrayName="addagendas">
          <div *ngFor="let agendaArray of repeatAgendaForm.controls; let i = index" [formGroupName]="i">
            <div #inputRef class="d-flex justify-content-between align-items-center">
              <mat-form-field appearance="fill" class="field-fill inputs">
                <mat-label class="required">{{
                  langObj
                  ? langObj?.MeetingAgenda
                  : ("MeetingAgenda" | translate)
                  }}
                </mat-label>
                <input matInput placeholder="Enter Meeting Agenda" formControlName="agenda"
                  (keyup.enter)="addNewAgendaRow($event, i)" [(ngModel)]="value" />
                <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error>{{
                  langObj ? langObj?.Required : ("Required" | translate)
                  }}</mat-error>
              </mat-form-field>

              <!-- <button
              mat-mini-fab
              mat-button
              color="accent"
              style="background-color: #3aafe4"
              type="button"
              (click)="removeAgendaRow($event, i)"
            >
              <i class="fa fa-times" style="color: #fff"></i>
            </button> -->
              <button type="button" (click)="saveMeetingAgenda()" mat-mini-fab mat-button color="primary"
                matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above" [disabled]="!value"
                style="margin-left: 20px; margin-bottom: 16px;">
                <i class="fa fa-floppy-o" style="color: #fff"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex justify-content-end">
        <button
          type="button"
          (click)="saveMeetingAgenda()"
          mat-mini-fab
          mat-button
          color="primary"
          matTooltip="Save"
          matTooltipClass="custom-tooltip"
          matTooltipPosition="above"
          style="margin-left: 20px"
        >
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
      </div> -->
      </mat-dialog-content>
    </form>
    <table mat-table class="basic-table plant-model-table" [dataSource]="agendaData" matSort>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          <p class="header-text">
            {{ langObj ? langObj?.Actions : ("Actions" | translate) }}
          </p>
        </th>
        <td mat-cell *matCellDef="let element" class="row-action-btns-column">
          <div class="row-action-btns">
            <button mat-icon-button class="node-control-btn node-delete" aria-label="delete item"
              pTooltip="Delete Agenda" tooltipPosition="top" (click)="deleteMeetibgAgenda(element)">
              <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                  fill="white" />
              </svg>
            </button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="agendaName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="">
          <p class="header-text">
            {{ langObj ? langObj?.Agenda : ("Agenda" | translate) }}
          </p>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.MeetingName }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="agendaColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: agendaColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          {{ langObj ? langObj?.nodatamatch : ("nodatamatch" | translate) }}
        </td>
      </tr>
    </table>
    <mat-paginator class="basic-paginataor" [pageSizeOptions]="[3, 5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
  </ng-template>

  <ng-template #KpiAndParticepants>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{ langObj ? langObj?.Details : ("Details" | translate) }}</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close" (click)="closePopUpModal()">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
      <div class="wrapper-box wrapper-box-shadow grid-container">
        <div class="container">
          <div class="col-md-4">
            <h2>{{ langObj ? langObj?.kpis : ("kpis" | translate) }}</h2>
            <li *ngFor="let name of name" style="font-weight: 100">{{ name }}</li>
          </div>

          <div class="col-md-4">
            <h2>{{ langObj ? langObj?.Leader : ("Leader" | translate) }}</h2>

            <li *ngFor="let LeaderName of LeaderName" style="font-weight: 100">
              {{ LeaderName }}
            </li>
          </div>

          <div class="col-md-4">
            <h2>
              {{ langObj ? langObj?.Participants : ("Participants" | translate) }}
            </h2>

            <li *ngFor="let FullName of FullName" style="font-weight: 100">
              {{ FullName.MeetingParticpant }}
            </li>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{langObj ? langObj?.AddMeetingQuestions : ('AddMeetingQuestions' | translate)}}</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>

      <form [formGroup]="surveyForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="survey-options">
          <div formArrayName="surveyQuestions" class="poll-options">
            <ol class="list-style">
              <li *ngFor="
                let questionCtrl of questionFormGroups.controls;
                let i = index
              ">
                <div [formGroupName]="i" class="d-flex justify-content-between">
                  <div class="question_wrapper">
                    <div class="content-top-row">
                      <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                        style="width: 80%">
                        <input formControlName="questionTitle" maxlength="200" matInput placeholder="Question title"
                          [required]="true" />
                      </mat-form-field>
                      <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-select formControlName="questionType" placeholder="Question type" ng-model="myVar"
                          [(value)]="selectedOption[i]" (selectionChange)="
                          onSeletQuestionType(selectedOption[i], i)
                        " [required]="true">
                          <mat-option *ngFor="let type of questions" [value]="type.value">
                            {{ type.viewValue }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div formGroupName="questionGroup">
                      <!-- <div *ngSwitchCase="'Single Choice'"> -->
                      <div *ngIf="
                        questionCtrl.value.questionType === 'Single Choice'
                      ">
                        <div *ngIf="
                          questionCtrl.controls.questionGroup.controls.options
                        " style="display: flex">
                          <ul class="question-type" formArrayName="options" class="content-row-wrap" style="width: 95%">
                            <li *ngFor="
                              let optionCtrl of questionCtrl.controls
                                .questionGroup.controls.options.controls;
                              let j = index
                            ">
                              <div [formGroupName]="j">
                                <mat-form-field
                                  class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                                  style="width: 80%">
                                  <input formControlName="optionText" matInput placeholder="option text" maxlength="100"
                                    [required]="true" />
                                </mat-form-field>
                                <mat-icon *ngIf="j >= 0" (click)="removeOption(i, j)"
                                  style="color: red; cursor: pointer">
                                  delete
                                </mat-icon>
                              </div>
                            </li>
                          </ul>
                          <div style="margin-top: 20px">
                            <button mat-mini-fab color="warn" type="button" (click)="addOption(i)">
                              <mat-icon style="color: #fff"> add_box </mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- <div *ngSwitchCase="'Multi Choice'"> -->
                      <div *ngIf="questionCtrl.value.questionType === 'Multi Choice'">
                        <div *ngIf="
                          questionCtrl.controls.questionGroup.controls.options
                        " style="display: flex">
                          <ul class="question-type" formArrayName="options" class="content-row-wrap" style="width: 95%">
                            <li *ngFor="
                              let optionCtrl of questionCtrl.controls
                                .questionGroup.controls.options.controls;
                              let j = index
                            ">
                              <div [formGroupName]="j">
                                <mat-form-field
                                  class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                                  style="width: 80%">
                                  <input formControlName="optionText" matInput placeholder="option text" maxlength="100"
                                    [required]="true" />
                                </mat-form-field>

                                <mat-icon *ngIf="j >= 0" (click)="removeOption(i, j)"
                                  style="color: red; cursor: pointer">
                                  delete
                                </mat-icon>
                              </div>
                            </li>
                          </ul>
                          <div style="margin-top: 20px">
                            <button mat-mini-fab color="warn" type="button" (click)="addOption(i)">
                              <mat-icon style="color: #fff"> add_box </mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- <div *ngSwitchCase="'Descriptive'"> -->
                      <div *ngIf="questionCtrl.questionType === Descriptive"></div>
                    </div>
                  </div>
                  <div class="action-row">
                    <mat-checkbox (change)="onChange($event, i)" formControlName="IsChecked" color="accent"
                      style="margin-left: 10px">
                    </mat-checkbox>
                    <button *ngIf="i >= 0" color="warn" type="button" (click)="onRemoveQuestion(i)"
                      class="poll-remove-btn">
                      <img src="../../../../assets/images/delete-row.png" style="width: 35px" />
                    </button>
                  </div>
                </div>
              </li>
            </ol>
            <div class="d-flex justify-content-between">
              <button type="button" mat-raised-button (click)="onAddQuestion()">
                {{ langObj ? langObj?.AddQuestion : ("AddQuestion" | translate) }}
              </button>
              <button [disabled]="!surveyForm.valid" type="submit" class="back-button" mat-flat-button color="accent"
                matBadgePosition="after" matBadgeColor="accent" MatBadgeSize="large" style="margin-left: 14px">
                <p class="btn-text">
                  {{ surveyDetailFormMode ? "Add" : "Update" }} Survey
                </p>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #questionModal>
    <div class="d-flex justify-content-between align-items-center">
      <h2>{{ QuestionTitle }}</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close" (click)="CloseQuestion()">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
    <form [formGroup]="questionForm">
      <mat-dialog-content>
        <div class="p-col-7 d-flex justify-content-end align-items-center">
          <button mat-mini-fab mat-button color="accent" (click)="addNewRow()" matTooltip="Add New Row"
            matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px">
            <i class="fa fa-calendar-plus-o" style="color: #fff"></i>
          </button>

          <button mat-mini-fab mat-button color="primary"
            (click)="formMode ? updateMeetingQuestion() : saveMeetingQuestion()"
            matTooltip="{{ formMode ? 'Update' : 'Save' }}" matTooltipClass="custom-tooltip" matTooltipPosition="above"
            style="margin-left: 20px" [disabled]="!Question || !selectedGroup">
            <i class="fa fa-save" style="color: #fff"></i>
          </button>

          <button mat-mini-fab mat-button color="accent" (click)="addGroupModal()" matTooltip="Add Group"
            matTooltipClass="custom-tooltip" matTooltipPosition="above"
            style="margin-left: 20px; background-color: green">
            <i class="fa fa-users" style="color: #fff"></i>
          </button>
        </div>
        <table mat-table class="basic-table plant-model-table" [dataSource]="QuestionData" formArrayName="GRD">
          <tr mat-header-row *matHeaderRowDef="QuestionColumns"></tr>
          <tr mat-row *matRowDef="let row; let i = index; columns: QuestionColumns"></tr>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef [style.display]="'none'">
              S/No
            </th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)"
              [style.display]="'none'">
              {{ index + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Meeting">
            <th mat-header-cell *matHeaderCellDef [style.display]="'none'">
              {{langObj ? langObj?.MeetingID : ('MeetingID' | translate)}}
            </th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)"
              [style.display]="'none'">
              <mat-form-field appearance="outline">
                <mat-select formControlName="MeetingId">
                  <mat-option *ngFor="let metting of MeetingQusList" [value]="metting.meetingDefId">
                    {{ metting.meetingName }}</mat-option>
                </mat-select>
                <mat-error>{{langObj ? langObj?.PleaseEnterMeeting : ('PleaseEnterMeeting' | translate)}}</mat-error>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="Area">
            <th mat-header-cell *matHeaderCellDef style="width: 200px !important">
              {{langObj ? langObj?.Group : ('Group' | translate)}}
            </th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <mat-form-field appearance="outline" style="width: 140px !important">
                <mat-select [(value)]="selectedGroup" formControlName="Area"
                  placeholder="{{langObj ? langObj?.DropdownGroup : ('DropdownGroup' | translate)}}">
                  <mat-option *ngFor="let data of GroupAdded" [value]="data">
                    {{ data }}</mat-option>
                </mat-select>
                <mat-error>{{langObj ? langObj?.PleaseEnterArea : ('PleaseEnterArea' | translate)}}</mat-error>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="Line">
            <th mat-header-cell *matHeaderCellDef style="width: 200px !important">
              {{langObj ? langObj?.Line : ('Line' | translate)}}
            </th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <mat-form-field appearance="outline" style="width: 140px !important">
                <mat-select formControlName="LineId"
                  placeholder="{{langObj ? langObj?.DropdownLine : ('DropdownLine' | translate)}}">
                  <mat-option *ngFor="let line of lineList" [value]="line.PL_Id">
                    {{ line.PL_Desc }}</mat-option>
                </mat-select>
                <mat-error>{{langObj ? langObj?.PleaseEnterLine : ('PleaseEnterLine' | translate)}}</mat-error>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="Question">
            <th mat-header-cell *matHeaderCellDef style="width: 100% !important">
              {{langObj ? langObj?.Question : ('Question' | translate)}}
            </th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <mat-form-field appearance="outline" class="block" style="width: 100% !important">
                <input formControlName="Question" matInput [(ngModel)]="Question" />
                <mat-error>{{langObj ? langObj?.PleaseEnterQuestion : ('PleaseEnterQuestion' | translate)}}</mat-error>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="Unit">
            <th mat-header-cell *matHeaderCellDef style="width: 200px !important">
              {{langObj ? langObj?.Unit : ('Unit' | translate)}}
            </th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <mat-form-field appearance="outline" style="width: 140px !important">
                <mat-select formControlName="Unit"
                  placeholder="{{langObj ? langObj?.DropdownLine : ('DropdownLine' | translate)}}"
                  (selectionChange)="GetTargetValue($event, index)">
                  <mat-option *ngFor="let unit of UnitList" [value]="unit.TypeId">
                    {{ unit.Status }}</mat-option>
                </mat-select>
                <mat-error>{{langObj ? langObj?.PleaseEnterUnit : ('PleaseEnterUnit' | translate)}}</mat-error>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="Target">
            <th mat-header-cell *matHeaderCellDef style="width: 200px !important">
              {{langObj ? langObj?.Target : ('Target' | translate)}}
            </th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <mat-form-field appearance="outline" style="width: 140px !important">
                <mat-select formControlName="Target" placeholder="Dropdown Line">
                  <mat-option *ngFor="let target of TargetValue[index]" [value]="target">
                    {{ target }}</mat-option>
                </mat-select>
                <mat-error>{{langObj ? langObj?.PleaseEnterTarget : ('PleaseEnterTarget' | translate)}}</mat-error>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <button mat-mini-fab mat-button color="warn" pTooltip="Remove" tooltipPosition="right"
                style="margin: 0.5rem 0 0 3rem" (click)="removeRepeatEntry(index)">
                <i class="fa fa-times" style="color: #fff"></i>
              </button>
            </td>
          </ng-container>
        </table>
        <div class="card-footer pb-0 pt-3"></div>
        <!-- <mat-paginator class="basic-paginataor" [pageSizeOptions]="[3, 8, 10,20]" showFirstLastButtons (page)="onChangedPageSize($event)">
            </mat-paginator> -->
        <!-- <mat-paginator  [pageSizeOptions]="[3, 10, 25, 100]"></mat-paginator> -->
      </mat-dialog-content>
    </form>
  </ng-template>

  <ng-template #displayGroupModal>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{langObj ? langObj?.AddGroup : ('AddGroup' | translate)}}</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>

    <form [formGroup]="AddGroupForm">
      <mat-dialog-content>
        <mat-form-field appearance="fill" class="field-fill inputs1" style="width: 85%">
          <!-- <mat-label class="required">Group Name</mat-label> -->
          <mat-label>{{
            langObj ? langObj?.SelectGroup : ("SelectGroup" | translate)
            }}</mat-label>
          <input matInput placeholder="Enter Group Name" formControlName="dropname" />
          <mat-error>{{
            langObj ? langObj?.Required : ("Required" | translate)
            }}</mat-error>
        </mat-form-field>
        <button type="submit" mat-mini-fab mat-button color="primary" (click)="SaveGroup()"
          [disabled]="!AddGroupForm.valid" matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"
          style="margin-left: 20px">
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
      </mat-dialog-content>
    </form>
  </ng-template>

  <ng-template #displayRescheduleModal>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{langObj ? langObj?.RescheduleMeeting : ('RescheduleMeeting' | translate)}}</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <div>
      <form [formGroup]="rescheduleForm">
        <div class="content-row-wrap">
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 100%">
            <mat-label>{{langObj ? langObj?.MeetingName : ('MeetingName' | translate)}}*</mat-label>
            <input rows="4" matInput formControlName="meetingName" readonly />
            <mat-error>{{langObj ? langObj?.MeetingNameisRequired : ('MeetingNameisRequired' | translate)}}</mat-error>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 100%; height: auto">
            <mat-label>{{langObj ? langObj?.MeetingSubject : ('MeetingSubject' | translate)}}*</mat-label>
            <!-- <textarea
            rows="4"
            matInput
            formControlName="meetingSubject"
          ></textarea> -->
            <mat-chip-list #chipList formControlName="meetingSubject">
              <mat-chip *ngFor="let agenda of Agendas" [selectable]="selectable" [removable]="removable"
                (removed)="remove(participant)">
                {{ agenda.MeetingAgenda }}
              </mat-chip>
              <mat-chip *ngIf="Agendas.length == 0">No Subject</mat-chip>
            </mat-chip-list>

            <mat-error>{{langObj ? langObj?.MeetingSubjectisRequired : ('MeetingSubjectisRequired' |
              translate)}}</mat-error>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 100%; height: auto">
            <mat-label>{{langObj ? langObj?.MeetingParticipants : ('MeetingParticipants' | translate)}}</mat-label>
            <mat-chip-list #chipList formControlName="meetingParticipants">
              <mat-chip *ngFor="let participant of ParticipantSelectedList" [selectable]="selectable"
                [removable]="removable" (removed)="remove(participant)">
                {{ participant.MeetingParticpant }}
              </mat-chip>
              <mat-chip *ngIf="ParticipantSelectedList.length == 0">No Participant</mat-chip>
            </mat-chip-list>
            <!-- <mat-select formControlName="meetingParticipants" multiple>
            <mat-select-trigger>
              <mat-chip-list>
                <mat-chip
                  *ngFor="
                    let user of getParticipantsForm('meetingParticipants')
                  "
                  [removable]="true"
                  (removed)="onParticipantsRemoved(user)"
                >
                  {{ user?.empFullName }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-select-trigger>
            <mat-option *ngFor="let user of UserDDL" [value]="user">{{
              user?.empFullName
            }}</mat-option>
          </mat-select> -->
          </mat-form-field>
        </div>
        <h3 class="subheading">{{langObj ? langObj?.MeetingTime : ('MeetingTime' | translate)}}:</h3>
        <div class="content-row-wrap">
          <div class="inputField">
            <nz-time-picker style="width: 100%; margin-left: 0px !important; height: 50px" class="calender-input"
              nzFormat="HH:mm:ss " formControlName="rescheduleStartTime"></nz-time-picker>
            <span class="floating-text" style="padding-left: 0px">{{langObj ? langObj?.StartTime : ('StartTime' |
              translate)}}</span>
          </div>

          <div class="inputField">
            <nz-time-picker nzFormat="HH:mm:ss " style="width: 100%; margin-left: 0px !important; height: 50px"
              class="calender-input" formControlName="rescheduleEndTime"></nz-time-picker>
            <span class="floating-text" style="padding-left: 0px">{{langObj ? langObj?.EndTime : ('EndTime' |
              translate)}}</span>
          </div>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>{{langObj ? langObj?.Duration : ('Duration' | translate)}} (minutes):</mat-label>
            <input matInput formControlName="duration" readonly [value]="realTimeDuration" />
          </mat-form-field>
        </div>
        <h3 class="subheading">{{langObj ? langObj?.RecurrencePattern : ('RecurrencePattern' | translate)}}:</h3>
        <div class="d-flex justify-content-between">
          <div style="width: 15%">
            <mat-radio-group style="
              display: flex;
              justify-content: space-between;
              flex-direction: column;
            " required aria-label="Select an option" (change)="changePattern($event)" formControlName="RecurrneceType">
              <mat-radio-button value="Daily">Daily</mat-radio-button>
              <mat-radio-button value="Weekly">Weekly</mat-radio-button>
              <mat-radio-button value="Monthly">Monthly</mat-radio-button>
              <mat-radio-button value="Yearly">Yearly</mat-radio-button>
            </mat-radio-group>
          </div>
          <div style="width: 80%">
            <div *ngIf="dailypattern" style="display: flex">
              <div>
                <mat-radio-group style="
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                " aria-label="Select an option" formControlName="RecurEveryWeekDay"
                  (change)="changedaysNumber($event)">
                  <mat-radio-button value="false">No of Days</mat-radio-button>
                  <mat-radio-button value="true">Every Weekday</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="content-top-row" style="margin-top: 0px">
                <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  style="width: 80%">
                  <mat-label>{{langObj ? langObj?.Day : ('Day' | translate)}}(s)</mat-label>
                  <input type="number" formControlName="RecurIntervalDayDaily" matInput [disabled]="this.nofdays"
                    oninput="this.value = 
                  !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="weeklypattern">
              <div class="content-row-wrap">
                <mat-label class="field-side-label">{{langObj ? langObj?.RecurEvery : ('RecurEvery' |
                  translate)}}</mat-label>
                <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  style="margin-top: 0px; margin-left: 25px">
                  <mat-label>{{langObj ? langObj?.Week : ('Week' | translate)}}(s)</mat-label>
                  <input formControlName="RecurIntervalWeekly" (keyup)="ValidatingField()" type="number" matInput
                    oninput="this.value = 
                  !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
                </mat-form-field>
                <mat-label class="field-side-label">{{langObj ? langObj?.On : ('On' | translate)}}:</mat-label>
              </div>
              <div class="content-row-wrap">
                <!-- <label *ngFor="let days of weekdays; let i = index">
              <mat-checkbox (change)='onChange(days.name)'  formControlName="RecurDaysOfWeekWeekly" class="checkbox-list" 
              [checked]="days.Checked"
              >{{ days.name }}
              </mat-checkbox>
               <input (change)='onChange(days.name)' type="checkbox" formControlName="RecurDaysOfWeekWeekly" class="form-check-input" 
             [checked]='true'
              />{{ days.name }} 
              
            </label> -->
                <div formArrayName="RecurDaysOfWeekWeekly" *ngFor="let days of weekdays; let i = index">
                  <mat-checkbox (change)="onChange(days.name)" class="checkbox-list" [checked]="days.Checked">{{
                    days.name }}
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div *ngIf="monthlypattern">
              <div class="d-flex">
                <div>
                  <mat-radio-group style="
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                  " aria-label="Select an option" (change)="changeMonthlyNumbers($event)"
                    formControlName="RecurringInDaysOfMonthly">
                    <mat-radio-button class="select-radio" value="true">Day</mat-radio-button>
                    <mat-radio-button class="select-radio" value="false">The</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div>
                  <div class="content-top-row">
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 40%">
                      <mat-label>{{langObj ? langObj?.Day : ('Day' | translate)}}(s)</mat-label>
                      <input formControlName="RecurDayOfMonthMonthly" type="number" matInput [disabled]="this.monthsday"
                        oninput="this.value = 
                      !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
                    </mat-form-field>
                    <mat-label style="
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    ">
                      {{langObj ? langObj?.OfEvery : ('OfEvery' | translate)}}</mat-label>
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 40%">
                      <mat-label>{{langObj ? langObj?.Month : ('Month' | translate)}}(s)</mat-label>
                      <input formControlName="RecurIntervalOfMonthMontly" type="number" matInput
                        [disabled]="this.monthsday" oninput="this.value = 
                      !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
                    </mat-form-field>
                  </div>
                  <div class="content-top-row">
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 30%">
                      <mat-label>{{langObj ? langObj?.Week : ('Week' | translate)}}</mat-label>
                      <mat-select formControlName="RecurWeekOfMonthMontly" [disabled]="this.monthsselect">
                        <mat-option *ngFor="let week of weekscount" [value]="week.countId">
                          {{ week.count }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 30%">
                      <mat-label>{{langObj ? langObj?.WeekDay : ('WeekDay' | translate)}}</mat-label>
                      <mat-select formControlName="RecurDaysOfWeekMonthly" [disabled]="this.monthsselect">
                        <mat-option *ngFor="let week of weekdays" [value]="week.Id">
                          {{ week.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-label style="
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    ">
                      {{langObj ? langObj?.OfEvery : ('OfEvery' | translate)}}</mat-label>
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 20%">
                      <mat-label>{{langObj ? langObj?.Month : ('Month' | translate)}}(s)</mat-label>
                      <input type="number" matInput formControlName="RecurIntervalOfMonthMontlyRelative"
                        [disabled]="this.monthsselect" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="yearlypattern">
              <div class="content-top-row" style="margin-top: 0px">
                <mat-label style="
                  width: 10%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                  {{langObj ? langObj?.RecurEvery : ('RecurEvery' | translate)}}:</mat-label>
                <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  style="width: 20%">
                  <mat-label>{{langObj ? langObj?.Year : ('Year' | translate)}}(s)</mat-label>
                  <input formControlName="RecurIntervalofYearsYearly" type="number" matInput oninput="this.value = 
                  !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
                </mat-form-field>
              </div>
              <div class="d-flex">
                <div>
                  <mat-radio-group style="
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                  " formControlName="RecurAbsoluteYearly" aria-label="Select an option"
                    (change)="changeYearlyNumbers($event)">
                    <mat-radio-button class="select-radio" value="true">On</mat-radio-button>
                    <mat-radio-button class="select-radio" value="false">on The</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div>
                  <div class="content-top-row">
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 20%">
                      <mat-label>{{langObj ? langObj?.Month : ('Month' | translate)}}</mat-label>
                      <mat-select formControlName="RecurMonthYearly" [disabled]="this.inputAdd">
                        <mat-option *ngFor="let month of months" [value]="month.Id">
                          {{ month.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 20%">
                      <mat-label>{{langObj ? langObj?.Date : ('Date' | translate)}}</mat-label>
                      <input formControlName="RecurDayOfMonthYearly" type="number" matInput
                        [disabled]="this.inputAdd" />
                    </mat-form-field>
                  </div>
                  <div class="content-top-row">
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 20%">
                      <mat-label>{{langObj ? langObj?.Week : ('Week' | translate)}}</mat-label>
                      <mat-select formControlName="RecurWeekOfMonthYearly" [disabled]="this.select">
                        <mat-option *ngFor="let week of weekscount" [value]="week.countId">
                          {{ week.count }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 20%">
                      <mat-label>{{langObj ? langObj?.WeekDay : ('WeekDay' | translate)}}</mat-label>
                      <mat-select formControlName="RecurDaysOfWeekYearly" [disabled]="this.select">
                        <mat-option *ngFor="let week of weekdays" [value]="week.Id">
                          {{ week.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-label style="
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    ">
                      of</mat-label>
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 20%">
                      <mat-label>{{langObj ? langObj?.Month : ('Month' | translate)}}(s)</mat-label>
                      <mat-select formControlName="RecurringMonthYearly" [disabled]="this.select">
                        <mat-option *ngFor="let month of months" [value]="month.Id">
                          {{ month.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 class="subheading">{{langObj ? langObj?.RangeOfRecurrence : ('RangeOfRecurrence' | translate)}}:</h3>
        <div class="d-flex justify-content-between">
          <div class="w-15">
            <div class="inputField" style="width: 100%">
              <nz-date-picker style="width: 100%; margin-left: 0px !important; height: 50px" class="calender-input"
                id="startPicker" nzFormat="dd/MM/yyyy" [nzInputReadOnly]="true" formControlName="recurrenctStartDate">
              </nz-date-picker>
              <span class="floating-text" style="padding-left: 0px">{{langObj ? langObj?.StartDate : ('StartDate' |
                translate)}}</span>
            </div>
          </div>
          <div class="w-20">
            <mat-radio-group (change)="changerange($event)" style="
              display: flex;
              justify-content: flex-start;
              flex-direction: column;
            " aria-label="Select an option" formControlName="recurrencEndDateStatus">
              <mat-radio-button value="noenddate">No end date</mat-radio-button>
              <mat-radio-button value="endafter">End after:</mat-radio-button>
              <mat-radio-button value="endby">End by:</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="w-50">
            <div style="padding: 12px"></div>
            <div class="content-top-row">
              <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 50%">
                <mat-label>{{langObj ? langObj?.Occurences : ('Occurences' | translate)}}:</mat-label>
                <input type="number" formControlName="recurrencOccurrences" matInput [disabled]="this.endAfter" oninput="this.value = 
                !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
              </mat-form-field>
            </div>
            <div style="padding: 12px">
              <div class="inputField" style="width: 50%">
                <nz-date-picker style="width: 100%; margin-left: 0px !important; height: 50px" class="calender-input"
                  id="startPicker" nzFormat="dd/MM/yyyy" [nzInputReadOnly]="true" [nzDisabled]="this.endBy"
                  formControlName="recurrencEndDate">
                </nz-date-picker>
                <span class="floating-text" style="padding-left: 0px">{{langObj ? langObj?.Date : ('Date' |
                  translate)}}</span>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" mat-mini-fab (click)="SaveSchedule()" [disabled]="!rescheduleForm.valid" mat-button
          color="primary" [pTooltip]="Save" tooltipPosition="top"
          style="margin-left: 20px; cursor: pointer; float: right">
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
      </form>
    </div>
  </ng-template>

  <ng-template #KPIOrderListModal>
    <div class="modal-header">
      <h2 class="modal-title">{{langObj ? langObj?.KPIOrderList : ('KPIOrderList' | translate)}}</h2>

      <button mat-mini-fab mat-button mat-dialog-close class="close-modal" color="warn" title="close"
        (click)="activeModal.dismiss('Cross click')">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
    <ag-grid-angular style="width: 100%; height: 90%" class="ag-theme-alpine" [rowData]="KPIOrderList"
      [columnDefs]="KPIOrderColumns" (gridReady)="onKPIOrderGridReady($event)"
      (cellValueChanged)="onKPIOrderCellValueChanged($event)" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
  </ng-template>
</div>