import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Tool } from 'src/app/models/tool';
import { Action } from 'src/app/models/action';
import { Frequency } from 'src/app/models/frequency';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { FourM } from '../../models/fourM';
import { Kpireason } from 'src/app/models/kpireason';
import { ScorecardService } from 'src/app/services/scorecard.service';
import { UserPlant } from 'src/app/models/userPlant';
import * as _moment from 'moment';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { Router } from '@angular/router';
const moment = _moment;
@Component({
  selector: 'app-yearlyscorecard',
  templateUrl: './yearlyscorecard.component.html',
  styleUrls: ['./yearlyscorecard.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class YearlyscorecardComponent implements OnInit {
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;
  groupList: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  calendar_en = {
    closeText: 'Close',
    prevText: 'Previous',
    nextText: 'Next',
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    weekHeader: 'Week',
    weekNumberTitle: 'W',
    firstDayOfWeek: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: '',
    timeOnlyTitle: 'Only Time',
    timeText: 'Time',
    hourText: 'Hour',
    minuteText: 'Minute',
    secondText: 'Second',
    currentText: 'Current Date',
    ampm: false,
    year: 'Year',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    list: 'Agenda',
    allDayText: 'All Day',
    moreLinkText: 'More...',
    noEventsText: 'No Events',
    today: 'Today',
    clear: 'Clear',

  };
  constructor(
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private scorecardservice: ScorecardService,
    private fb: FormBuilder,
    private usermanagementService: UserManagementService,
    public router: Router,
    private translater: TranslaterService,
  ) {
    this.getGroupData();
    this.GetBreadcrumList();
    this.GetMenuAccess();
  }

  yearlyScoreForm!: FormGroup;
  searchPlants!: UserPlant[];
  frequencies!: Frequency[];
  columnDefs!: any;
  scoreCardFormMode!: string;
  rowData!: any[];
  toolslist!: Tool[];
  fourMList!: FourM[];
  toolPath!: string;
  toolName!: string;
  reasonslist!: Kpireason[];
  VisibleScoreCardTarget = false;
  actionlist: Action[] = [];
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
    floatingFilter: true,
  };

  ngOnInit(): void {
    //this.getLangWords();
    this.yearlyScoreForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      fromDate: [''],
      toDate: [''],
    });
  }
  getGroupData(): void {
    const key = {
      Id: 0
    };
    // tslint:disable-next-line: no-debugger
    this.usermanagementService.getGroupData(key).subscribe((data: any) => {
      this.groupList = data.masterList;
      const obj = this.groupList[0];
      this.yearlyScoreForm.get('group')?.setValue(obj);
      this.getPlants();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlants(): void {
    const key = {
      GroupId: this.yearlyScoreForm.value.group.TypeId
    };
    this.usermanagementService.getDepartments(key).subscribe((res: any) => {
      this.searchPlants = res.DepartList;
      const plantObject = this.searchPlants[0];
      this.yearlyScoreForm.get('plant')?.setValue(plantObject);
      this.onPlantChange();
    },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // getAllPlants(): void {
  //   const key = {
  //     userId: JSON.parse(localStorage.user).UserId,
  //   };
  //   this.kpiMaster.getUserPlants(key).subscribe(
  //     (plants) => {
  //       this.searchPlants = plants;
  //       if (this.searchPlants.length === 1) {
  //         const plantObject = this.searchPlants[0];
  //         this.yearlyScoreForm.get('plant')?.setValue(plantObject);
  //         this.onPlantChange();
  //       }
  //     },
  //     (error) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }

  generateColumns(data: any[]): any {
    this.columnDefs = [];
    let columnDefinitions: any[] = [];
    //  this.gridOptions.columnApi.setColumnsVisible('KPIId', false);
    data.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key, // .toUpperCase()
          // headerClass: 'header-style',
          headerClass: {},
          field: key,
          resizable: true,
          wrapText: true,
          rowGroup: false,
          width: 250,
          cellStyle: {},
          hide: false,
          filter: true,
          sortable: true,
        };
        mappedColumn.cellStyle = (params: any): any => {
          const styleObj = {
            'font-weight': 'bold',
            'background-color': '',
            color: '',
          };

          if (
            key !== 'KPICode' &&
            key !== 'Group' &&
            key !== 'Vision' &&
            key !== 'Driver' &&
            key !== 'KPI Name' &&
            key !== 'Trend' &&
            key !== 'Owner' &&
            key !== 'Unit Of Measure'

          ) {
            styleObj['background-color'] = '#ccffff';
          }
          if (key === 'Actual gap against expected') {
            if (params.value > 0) {
              styleObj['background-color'] = 'green';
              styleObj.color = 'white';
            }
            else {
              styleObj['background-color'] = 'red';
              styleObj.color = 'white';

            }
          }
          return styleObj;
        };

        columnDefinitions.push(mappedColumn);
      });
    });

    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    return columnDefinitions;
  }
  onPlantChange(): any {
    const date = new Date();
    const getdata = {
      plantId: this.yearlyScoreForm.value.plant.DeptId,
      fromDate: moment(new Date(date.getFullYear(), 0, 1)).format('YYYY-MM-DD'),
      toDate: moment(new Date(date.getFullYear(), 12, 31)).format('YYYY-MM-DD'),
    };
    if (getdata.plantId === undefined || !getdata.fromDate) {
      this.toaster.warning('Warning', 'Please Select Plant And Dates');
      return;
    }
    this.scorecardservice.getYearlyScoreCard(getdata).subscribe(
      (response: any) => {
        this.rowData = response;
        if (this.rowData) {
          this.columnDefs = this.generateColumns(this.rowData);
          setTimeout(() => {
            this.gridOptions.api.columnController.resetColumnState();
          }, 100);
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }
  onDateChange(value: any): any {
    const getdata = {
      plantId: this.yearlyScoreForm.value.plant.DeptId,
      fromDate: this.yearlyScoreForm.value.fromDate,
      toDate: this.yearlyScoreForm.value.toDate,
    };
    if (getdata.plantId === undefined || !getdata.fromDate) {
      this.toaster.warning('Warning', 'Please Select Plant And Dates');
      return;
    }
    this.scorecardservice.getYearlyScoreCard(getdata).subscribe(
      (response: any) => {
        this.rowData = response;
        if (this.rowData) {
          this.columnDefs = this.generateColumns(this.rowData);
          setTimeout(() => {
            this.gridOptions.api.columnController.resetColumnState();
          }, 100);
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  fielArrayValueChange(event: any, index: number): void {
    const field = this.actionlist[index];
    field.action = event.target.value;
  }

  fielDateValueChange(event: any, index: number): void {
    const field = this.actionlist[index];
    field.targetDate = new Date(event).toISOString();
  }

  deleteFieldValue(index: any): void {
    this.actionlist.splice(index, 1);
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;

    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }
}
function getSqlDate(value: any): any {
  return (
    value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate()
  );
}
