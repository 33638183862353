import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.scss']
})
export class QualityComponent implements OnInit {

  equipmentEfficency!: Chart;
  outputPcs!: Chart;
  pallets!: Chart;
  ytd!: Chart;
  downtime!: Chart;
  downtimeOccurences!: Chart;
  wasteData: any = [];
  wasteCategoryData:any = [];
  wasteCategoryOEEChart: Chart[] = [];
  wasteOEEChart: Chart[] = [];
  breadcrumList: any = [];
  constructor(private toaster: ToastrService, private usermanagementService: UserManagementService,
    public router: Router) { }
  ngOnInit(): void {
    this.drawWaste();
    this.drawWasteCategory();
  }
  drawWaste() {
    this.wasteData = [
      {
        title: 'Waste (Pcs)', categories: ['29 Aug 21', '30 Aug 21', '31 Aug 21', '01 Sep 21', '02 Sep 21', '03 Sep 21', '04 Sep 21', '05 Sep 21', '06 Sep 21', '07 Sep 21'],
        data: [
          { y: 39000, color: '#14b1ff' }, { y: 30000, color: '#14b1ff' }, { y: 18000, color: '#14b1ff' }, { y: 20000, color: '#14b1ff' }, { y: 18000, color: '#14b1ff' }, { y: 18000, color: '#14b1ff' },
          { y: 15000, name: '%', color: '#14b1ff' }, { y: 16000, color: '#14b1ff' }, { y: 19000, color: '#14b1ff' }, { y: 0, color: '#14b1ff' }]
      }];
    if (this.wasteData && this.wasteData.length) {
      for (const wasteOEE of this.wasteData) {
        const chart = new Chart({
          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 45
            }
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: wasteOEE.categories,
            crosshair: true
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              borderWidth: 0,
              dataLabels: {
                enabled: true,
              },
              point: {
                events: {
                  click: this.getYTDHeaderBasedValues.bind(this)
                }
              }
            }
          },
          series: [
            {
              name: wasteOEE.title,
              type: 'column',
              data: wasteOEE.data
            }
          ]
        });
        this.wasteOEEChart.push(chart);
      }
    }
  }

  drawWasteCategory() {
    this.wasteCategoryData = [
      {
        title: 'Waste by Category', categories: ['Scrap-Waste','Total'],
        data: [
          { y: 4951450, color: '#ff644d' }, { y: 4951450, color: '#0b37ff' }]
      }];
    if (this.wasteCategoryData && this.wasteCategoryData.length) {
      for (const wasteCategoryOEE of this.wasteCategoryData) {
        const chart = new Chart({
          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 45
            }
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: wasteCategoryOEE.categories,
            crosshair: true
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              borderWidth: 0,
              dataLabels: {
                enabled: true,
              },
              point: {
                events: {
                  click: this.getYTDHeaderBasedValues.bind(this)
                }
              }
            }
          },
          series: [
            {
              name: wasteCategoryOEE.title,
              type: 'column',
              data: wasteCategoryOEE.data
            }
          ]
        });
        this.wasteCategoryOEEChart.push(chart);
      }
    }
  }

  getYTDHeaderBasedValues() { }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

}
