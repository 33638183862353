import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  PanZoomConfig,
  PanZoomAPI,
  PanZoomModel,
  PanZoomConfigOptions,
} from 'ngx-panzoom';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MenuItem } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Driver } from 'src/app/models/driver';
import { Frequency } from 'src/app/models/frequency';
import { Pillar } from 'src/app/models/pillar';
import { Trend } from 'src/app/models/trend';

import { UserPlant } from 'src/app/models/userPlant';

import { KpiTrdeeModel } from 'src/app/models/treeModels/kpiTreeModel';
import { StrategicKpi } from 'src/app/models/treeModels/strategicKpi';
import { Vision } from 'src/app/models/treeModels/vision';
import { VisionKpi } from 'src/app/models/treeModels/visionKpi';
import { Uom } from 'src/app/models/uom';
import { Userlist } from 'src/app/models/userlist';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { KpitreeService } from 'src/app/services/kpitree.service';
import Swal from 'sweetalert2';
import { CalculationType } from 'src/app/models/calculationType';
import { UserManu } from 'src/app/models/userManu';
import { KpitargetService } from 'src/app/services/kpitraget.service';
import { CustomDateComponent } from '../../date-components/custom-date-component.component';
import { PrimeCellEditorComponent } from '../../editors/prime-cell-editor.component';
import { BtnCellRenderer } from '../../btn-cell-renderer.component';
import { MatDialog } from '@angular/material/dialog';
import * as _moment from 'moment';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ZerovalueService } from 'src/app/services/Zerovalue.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { TreeNode } from 'src/app/interface/TreeNode';
import { KpiGroupService } from 'src/app/services/kpigroups.service';
import * as XLSX from 'xlsx';
import { differenceInCalendarDays } from 'date-fns';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OrganizationChart } from 'primeng/organizationchart';
import { forkJoin } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';


interface Point {
  x: number;
  y: number;
}
const moment = _moment;
@Component({
  selector: 'app-kpitreedefination',
  templateUrl: './kpitreedefination.component.html',
  styleUrls: ['./kpitreedefination.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpitreedefinationComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('visibleYearlyTarget')
  private visibleYearlyTargetTbl!: TemplateRef<any>;
  @ViewChild('ImportDialog') ImportDialog!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('displayMonthlyTarget')
  private displayMonthlyTargetTbl!: TemplateRef<any>;
  @ViewChild('displayQuarterlyTarget')
  private displayQuarterlyTargetTbl!: TemplateRef<any>;
  @ViewChild('visibleWeeklyTarget')
  private visibleWeeklyTargetTbl!: TemplateRef<any>;
  @ViewChild('visibleDailyTarget')
  private visibleDailyTargetTbl!: TemplateRef<any>;
  @ViewChild('kpiHistoryModal')
  private kpiHistoryModal!: TemplateRef<any>;
  @ViewChild('visibleShiftlyTarget')
  private visibleShiftlyTargetTbl!: TemplateRef<any>;
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('gridElement', { static: true }) private gridElement!: ElementRef;
  @ViewChild('pickerforgrid') picker!: any;
  @ViewChild('starttimepicker') pickerE!: any;
  @ViewChild('chart') chart: OrganizationChart;
  @ViewChild('orgChart') orgTree: ElementRef;
  // @ViewChild('orgChartContainer') orgChartContainer: ElementRef;
  @ViewChild('panZoomContainer') panZoomContainer: ElementRef;
  @ViewChild('orgChartContainer', { static: false }) orgChartContainer: ElementRef;
  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;
  isFullscreen = false;
  originalContainerStyles: any;
  nodeExpandedStates: { [key: number]: boolean } = {};


  currentModel: PanZoomModel;
  groupList: any;
  unitList: any;
  editRecordList: any;
  kpiGroups: any[];
  kpiDataTypes: any[];
  VersionList: any;
  CurrentVersion: number;
  dialogRef: any;
  excelData: any;
  formMode: boolean;
  datatype: number = 3;
  gridColId: any;
  gridRowIndex: any;
  LastModified: any;
  rowParams: any;
  timeFormGroup: FormGroup;
  disableSave: boolean;
  historygridRowData: any;
  calcKpiDataPointIds: any;
  datapoints1: any;
  CurrentDate = new Date()
  ButtonType: string;
  IsTargetBtnDisabled = false;
  calendar_en: any = {
    closeText: 'Close',
    prevText: 'Previous',
    nextText: 'Next',
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    weekHeader: 'Week',
    weekNumberTitle: 'W',
    firstDayOfWeek: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: '',
    timeOnlyTitle: 'Only Time',
    timeText: 'Time',
    hourText: 'Hour',
    minuteText: 'Minute',
    secondText: 'Second',
    currentText: 'Current Date',
    ampm: false,
    year: 'Year',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    list: 'Agenda',
    allDayText: 'All Day',
    moreLinkText: 'More...',
    noEventsText: 'No Events',
    today: 'Today',
    clear: 'Clear',

  };
  KPIHistoryGrid: any;
  KpiHistoryColumnsDefs: ({ field: string; headerName: string; sortable: boolean; editable: boolean; wrapText: boolean; filter: boolean; resizable: boolean; width: number; cellStyle?: undefined; hide?: undefined; valueGetter?: undefined; } | { field: string; headerName: string; sortable: boolean; editable: boolean; wrapText: boolean; filter: boolean; resizable: boolean; cellStyle: (params: any) => { 'background-color': any; }; width: number; hide?: undefined; valueGetter?: undefined; } | { field: string; headerName: string; sortable: boolean; editable: boolean; wrapText: boolean; filter: boolean; resizable: boolean; hide: boolean; width: number; cellStyle?: undefined; valueGetter?: undefined; } | {
    field: string; headerName: string; sortable: boolean; editable: boolean; wrapText: boolean; filter: boolean; resizable: boolean; valueGetter: (params: any) => string;
    width: number; cellStyle?: undefined; hide?: undefined;
  })[];
  IsLocked: any;
  constructor(
    private changeDetector: ChangeDetectorRef,
    private primengConfig: PrimeNGConfig,
    private kpiMaster: KpimasterService,
    private commonService: CommonService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private fb: FormBuilder,
    private kpiservice: KpitreeService,
    private zerovalueservice: ZerovalueService,
    private spinner: NgxSpinnerService,
    private kpitarget: KpitargetService,
    private machineMappingService: MachineMappingService,
    private dialog: MatDialog,
    public router: Router,
    private kpiGroupService: KpiGroupService
  ) {
    this.Target = [
      { label: "Actual", value: 1 },
      { label: "Target", value: 2 }

    ];
  }

  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      filterParams: {
        cssClass: 'custom-ag-text-column-filter'
      },
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },

    },
    popupParent: document.querySelector('body'),
    floatingFilter: true,
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRenderer: BtnCellRenderer,

    },
  };

  // Related to panzoom

  private panZoomConfigOptions: PanZoomConfigOptions = {
    zoomLevels: 10,
    scalePerZoomLevel: 1.5,
    zoomStepDuration: 2,
    freeMouseWheelFactor: 0.001,
    zoomToFitZoomLevelFactor: 0.9,
    dragMouseButton: 'left',
  };
  panzoomConfig: PanZoomConfig = new PanZoomConfig(this.panZoomConfigOptions);
  private panZoomAPI!: PanZoomAPI;
  private apiSubscription!: Subscription;
  panzoomModel!: PanZoomModel;
  canvasWidth = 2400;
  initialZoomHeight = null;
  initialZoomWidth = this.canvasWidth;
  initialised = false;
  scale = this.getCssScale(this.panzoomConfig.initialZoomLevel);
  private isMobile = false;
  availabilityDataSource = [
    { area: 'Short Stops Count', machine: '', frequency: '	' },
    { area: 'Stop Count', machine: '', frequency: '	' },
    { area: 'Downtime', machine: '', frequency: '	' },
    { area: 'OEE%', machine: '', frequency: '	' },
    { area: 'Waste', machine: '', frequency: '' },
    { area: 'Production Output', machine: '', frequency: '' },
    { area: 'CCR', machine: '', frequency: '' },
    { area: 'NCR', machine: '', frequency: '' },
  ];
  sourceColumns: any[] = ['area', 'machine', 'frequency'];
  dates!: any;
  operators!: any;
  operands!: any;
  rulelist!: any;
  stringvalue!: any;
  formulastring!: any;
  myform!: FormGroup;
  zeroform!: FormGroup;
  kpireasonForm!: FormGroup;
  visionForm!: FormGroup;
  strategicForm!: FormGroup;
  searchPlantForm!: FormGroup;
  plants!: any[];
  visions!: Vision[];
  calculationType!: CalculationType[];
  searchPlants!: UserPlant[];
  searchList!: any[];
  strategicKpi!: StrategicKpi;
  strategicKpiId!: number;
  visionKpi!: VisionKpi;
  visionId!: number;
  data1: any = [];
  rangeDates!: Date[];
  minDate!: any;
  maxDate!: any;
  data2!: TreeNode[];
  machineData!: TreeNode[];
  kpiItems!: any[];
  stratgicKpiItems!: MenuItem[];
  selectedNode!: any;
  selectedEvent!: any;
  visibleSidebar2 = false;
  visionMenuItems!: MenuItem[];
  stratgicvisionMenuItems!: MenuItem[];
  title!: string;
  formula!: string;
  selectedCountry!: string;
  name: any;
  // selectedCountry: string;
  frequencies!: Frequency[];
  uoms!: Uom[];
  lines!: any[];
  machines!: any[];
  responsibles!: any[];
  accountables!: any[];
  consults!: any[];
  informs!: any[];
  responsibleroles!: any[];
  accountableroles!: any[];
  consultroles!: any[];
  informedroles!: any[];
  trends!: Trend[];
  uom: any;
  freqNode!: any;
  searchListFilter!: any[];
  freqType!: boolean;
  pillars!: Pillar[];
  departments!: any;
  stragicKpis!: StrategicKpi[];
  drivers!: Driver[];
  raci!: Userlist[];
  displayVision!: boolean;
  displayStartgicKpi!: boolean;
  displayFormulaBuilder!: boolean;
  displayMachineDialog!: boolean;
  displayFormulaRuleDialog!: boolean;
  remark!: string;
  rule!: string;
  quantify!: string;
  datevalue!: string;
  header = 'Add Vision';
  headerKpi = 'Add Startgic Kpi';
  showButton!: boolean;
  showFormulaDate!: boolean;
  showFormulaOperand!: boolean;
  showFormulaOperater!: boolean;
  strategicshowButton!: boolean;
  // Vision Proprites
  visionGroup!: string;
  visionGroupData!: any[];
  kpi!: KpiTrdeeModel;
  kpiObject!: KpiTrdeeModel;
  visionPlant!: string;
  visionPlantData!: any[];
  visionTitle!: string;
  visionDate!: string;
  visionQuantify!: string;
  visionRemark!: string;
  // Stargic Kpi Proprites
  stratgicKpiGroup!: string;
  stratgicKpiGroupData!: any[];
  stratgicKpiPlant!: string;
  stratgicKpiPlantData!: any[];
  stratgicKpiQuantify!: string;
  stratgicKpiRemark!: string;
  // Kpi Propties
  formulaBuilder!: string;
  kpiDriver!: Driver[];
  kpiUom: string | undefined;
  kpiPillar: string[] = [];
  kpidepartments: string[] = [];
  attachedStargicKpi: string[] = [];
  kpiStragicKpi: string[] = [];
  kpiFormula!: string;
  machineHirearchy!: string;
  kpiResponsible!: string;
  kpiAccountable!: string;
  kpiC!: string;
  kpiInformed!: string;
  kpiTarget!: string;
  kpiEntry!: string;
  isShown = false;
  strategicFormMode!: string;
  visionFormMode!: string;
  kpiFormMode!: string;
  resultantResArray: any = [];
  resultantConsArray: any = [];
  resultantInfArray: any = [];
  SKPIId!: number;
  visionMenu!: boolean;
  strategicMenu!: boolean;
  kpiMenu!: boolean;
  selectNodePlantId!: string | undefined;
  treeDataSpinner = 'treeDataSpinner';
  kpiCalculationType!: string | undefined;
  kpiCalculationTypeItselfId!: string;
  selectedMachine!: TreeNode;
  plantId!: number;
  userMenu!: UserManu[];
  treePermission!: any;
  IsAccess!: any;
  IsWrite!: any;
  targetPermission!: UserManu;
  showFormula = false;
  showDynamicFormula = false;
  FormulaCalculationTypes!: any[];
  Sources!: any[];
  Machines!: any[];
  Frequency!: any[];
  // Target Information
  showCheckBox!: boolean;
  targetDate!: Date;
  dataTypeDate!: Date;
  // targetAmount!: number;
  targetAmount: string = '';
  components!: any;
  displayTarget = true;
  gridApi: any;
  gridColumnApi: any;
  shift!: any;
  viewKpiInformation!: boolean;
  viewDetailsKpiTarget!: boolean;
  kpiTitle!: string | undefined;
  kpifrequency!: string | undefined;
  visibleVisionTarget = false;
  modalTitle!: string;
  dayOffList: number[] = [];
  kpiautotarget: boolean | undefined;
  dataArray!: [];
  addVisiblePopUp = false;
  shifts!: any[];
  breadcrumList!: any;
  languageDir = 'ltr';
  time: Date | null = null;
  Target!: any;
  targetArray: any[] = [];
  FetchArray: any[] = [];
  Dynamictargetstringvalue!: any;
  Dynamickpistringvalue!: any;
  showDynamicFormulaOperand: boolean = false;
  displayDynamicFormulaBuilder!: boolean;
  Dynamicformulastring: any = '';
  PrevIndex: any;
  currentIndex: any;

  columnDefs = [
    {
      field: 'TargetDate',
      sortable: true,
      filter: 'agDateColumnFilter',
      editable: false,
      cellEditor: 'primeCellEditor',
      filterParams: {
        comparator: myDateComparator,
      },
      
    },
    { field: 'TargetYear', sortable: true, filter: true,  },
    {
      field: 'TargetValue',
      sortable: true,
      filter: true,
      onCellClicked: this.makeCellClicked.bind(this),
      editable: () => {
        if (this.viewDetailsKpiTarget) {
          return false;
        } else {
          return true;
        }
      },
      
      // valueParser: (params: any) => this.inputValidation(params),
      // valueFormatter: currencyFormatter,
    },
  ];

  openModal(): void {
    this.dialog.open(this.displayRoleModal, { height: '50%', width: '40%' });
    this.enableOverlay();
    this.getzerovalue();
  }

  // tslint:disable-next-line: member-ordering
  columnDefsMonth = [
    {
      field: 'TargetDate',
      sortable: true,
      headerName: 'Target Date',
      filter: 'agDateColumnFilter',
      editable: true,
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellEditor: 'primeCellEditor',
      filterParams,
    },
    {
      field: 'TargetMonth',
      sortable: true,
      filter: true,
      suppressToolPanel: true,
      
    },
    {
      field: 'TargetValue',
      sortable: true,
      valueParser: (params: any) => this.inputValidation(params),
      filter: true,
      onCellClicked: this.makeCellClicked.bind(this),
      editable: () => {
        if (this.viewDetailsKpiTarget) {
          return false;
        } else {
          return true;
        }
      },
      
    },
    { field: 'TargetQuarter', sortable: true, filter: true,  },
    { field: 'TargetYear', sortable: true, filter: true,  },
    {
      field: 'KPITargetId',
      headerName: 'Action',
      // cellRenderer: 'btnCellRenderer',
      // cellRendererParams: {
      //   onClick: this.deletekpiTarget.bind(this),
      //   label: 'Delete',
      // },
      cellRenderer: (params: any) => {
        return `   <button mat-mini-fab="" color="primary" ptooltip="Update" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Update" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-align-justify" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
       <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Delete" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
       `
      },
      minWidth: 150,
      
    },


  ];
  // tslint:disable-next-line: member-ordering
  columnDefsQuarter = [
    {
      field: 'TargetDate',
      sortable: true,
      filter: 'agDateColumnFilter',
      editable: true,
      cellEditor: 'primeCellEditor',
      filterParams: {
        comparator: myDateComparator,
      },
      
    },
    { field: 'TargetQuarter', sortable: true, filter: true,  },
    {
      field: 'TargetValue',
      sortable: true,
      valueParser: (params: any) => this.inputValidation(params),
      filter: true,
      onCellClicked: this.makeCellClicked.bind(this),
      editable: () => {
        if (this.viewDetailsKpiTarget) {
          return false;
        } else {
          return true;
        }
      },
      
    },
    { field: 'TargetYear', sortable: true, filter: true,  },
    {
      field: 'KPITargetId',
      headerName: 'Action',
      // cellRenderer: 'btnCellRenderer',
      // cellRendererParams: {
      //   onClick: this.deletekpiTarget.bind(this),
      //   label: 'Delete',
      // },
      cellRenderer: (params: any) => {
        return `   <button mat-mini-fab="" color="primary" ptooltip="Update" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Update" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-align-justify" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
       <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Delete" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
       `
      },
      minWidth: 150,
      
    },
  ];
  // tslint:disable-next-line: member-ordering
  columnDefsWeek = [
    {
      field: 'TargetDate',
      sortable: true,
      filter: 'agDateColumnFilter',
      editable: true,
      cellEditor: 'primeCellEditor',
      filterParams: {
        comparator: myDateComparator,
      },
      
    },
    { field: 'TargetWeek', sortable: true, filter: true,  },
    {
      field: 'TargetValue',
      sortable: true,
      valueParser: (params: any) => this.inputValidation(params),
      filter: true,
      onCellClicked: this.makeCellClicked.bind(this),
      editable: () => {
        if (this.viewDetailsKpiTarget) {
          return false;
        } else {
          return true;
        }
      },
      
    },
    {
      field: 'TargetMonth',
      sortable: true,
      filter: true,
      suppressToolPanel: true,
      
    },
    { field: 'TargetQuarter', sortable: true, filter: true,  },
    { field: 'TargetYear', sortable: true, filter: true,  },
    {
      field: 'KPITargetId',
      headerName: 'Action',
      // 
      cellRenderer: (params: any) => {
        return `   <button mat-mini-fab="" color="primary" ptooltip="Update" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Update" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-align-justify" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
       <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Delete" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
       `
      },
      minWidth: 150,
      
    },
  ];
  // tslint:disable-next-line: member-ordering
  columnDefsDay = [
    {
      field: 'TargetDate',
      sortable: true,
      filter: 'agDateColumnFilter',
      editable: true,
      cellEditor: 'primeCellEditor',
      filterParams: {
        comparator: myDateComparator,
      },
      
    },
    { field: 'TargetDay', sortable: true, filter: true,  },
    {
      field: 'TargetValue',
      valueParser: (params: any) => this.inputValidation(params),
      sortable: true,
      filter: true,
      onCellClicked: this.makeCellClicked.bind(this),
      editable: () => {
        if (this.viewDetailsKpiTarget) {
          return false;
        } else {
          return true;
        }
      },
      
    },
    { field: 'TargetWeek', sortable: true, filter: true,  },
    {
      field: 'TargetMonth',
      sortable: true,
      filter: true,
      suppressToolPanel: true,
      
    },
    { field: 'TargetQuarter', sortable: true, filter: true,  },
    { field: 'TargetYear', sortable: true, filter: true,  },
    {
      field: 'KPITargetId',
      headerName: 'Action',
      // cellRenderer: 'btnCellRenderer',
      // cellRendererParams: {
      //   onClick: this.deletekpiTarget.bind(this),
      //   label: 'Delete',
      // },
      cellRenderer: (params: any) => {
        return `   <button mat-mini-fab="" color="primary" ptooltip="Update" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Update" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-align-justify" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
       <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Delete" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
       `
      },
      minWidth: 150,
      
    },
  ];
  // tslint:disable-next-line: member-ordering
  columnDefsShift = [
    {
      field: 'TargetDate',
      sortable: true,
      filter: 'agDateColumnFilter',
      editable: true,
      cellEditor: 'primeCellEditor',
      filterParams: {
        comparator: myDateComparator,
      },
      
    },
    { field: 'TargetShift', sortable: true, filter: true, hide: true,  },
    { field: 'ShiftName', sortable: true, filter: true,  },
    {
      field: 'TargetValue',
      valueParser: (params: any) => this.inputValidation(params),
      sortable: true,
      filter: true,
      onCellClicked: this.makeCellClicked.bind(this),
      editable: () => {
        if (this.viewDetailsKpiTarget) {
          return false;
        } else {
          return true;
        }
      },
      
    },
    { field: 'TargetDay', sortable: true, filter: true,  },
    { field: 'TargetWeek', sortable: true, filter: true,  },
    {
      field: 'TargetMonth',
      sortable: true,
      filter: true,
      suppressToolPanel: true,
      
    },
    { field: 'TargetQuarter', sortable: true, filter: true,  },
    { field: 'TargetYear', sortable: true, filter: true,  },
    {
      field: 'KPITargetId',
      headerName: 'Action',
      // cellRenderer: 'btnCellRenderer',
      // cellRendererParams: {
      //   onClick: this.deletekpiTarget.bind(this),
      //   label: 'Delete',
      // },
      cellRenderer: (params: any) => {
        return `   <button mat-mini-fab="" color="primary" ptooltip="Update" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Update" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-align-justify" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
       <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Delete" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
       `
      },
      minWidth: 150,
      
    },
  ];

  // tslint:disable-next-line: member-ordering

  // tslint:disable-next-line:member-ordering
  addStrategicKpi: TreeNode = {
    label: 'Stargi Kpi',
    type: 'strategickpi',
    styleClass: 'p-strategickpi',
    expanded: true,
    plant: 'plant',
    quantify: 'quantify',
    children: [],
  };
  // tslint:disable-next-line:member-ordering
  selectedFile!: TreeNode;

  // tslint:disable-next-line:member-ordering
  text!: string;
  // tslint:disable-next-line:member-ordering
  kpis!: any;

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    // this.renderer.setStyle(this.el.nativeElement.ownerDocument.body, 'overflow', 'hidden');
    this.apiSubscription = this.panzoomConfig.api.subscribe(
      (api: PanZoomAPI) => (this.panZoomAPI = api)
    );
    // this.modelChangedSubscription = this.panzoomConfig.modelChanged.subscribe((model: PanZoomModel) => this.onModelChanged(model));
    this.isMobile = this.isMobileDevice();
    if (this.isMobile) {
      // this.contentItems = this.contentItems.slice(0, 13);
    }
    this.searchList = [
      { id: 0, name: 'Vision' },
      { id: 1, name: 'StrategicKPI' },
      { id: 2, name: 'KPI' },
    ];
    this.FormulaCalculationTypes = [
      { name: 'Sum', code: 'Sum' },
      { name: 'Avg', code: 'Avg' },
      { name: 'Cummulative', code: 'Cummulative' },
    ];
    this.Sources = [
      { name: 'O3', code: 'O3' },
      { name: 'ERP', code: 'ERP' },
      { name: 'Manual', code: 'Manual' },
      { name: 'Others', code: 'Others' },
    ];
    this.Machines = [];
    this.Frequency = [];
    this.searchPlantForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      searchList: ['', Validators.required],
      searchListFilter: ['', Validators.required],
      //Date:[''],
      VersionList: ['']
    });
    this.myform = this.fb.group({
      kpiTitle: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      kpiDriver: [''],
      kpiCode: [''],
      kpifrequency: ['', Validators.required],
      kpiUom: ['', Validators.required],
      kpiPillar: [''],
      kpidepartments: [''],
      attachedStargicKpi: [''],
      kpiStragicKpi: [''],
      kpiline: [''],
      kpimachine: [''],
      kpiFormula: ['', [Validators.pattern('[^"]+$')]],
      kpiDynamicFormulaValue: [this.FetchArray.map((c) => c.name), [Validators.pattern('[^"]+$')]],
      kpiResponsible: [''],
      kpiAccountable: [''],
      kpiC: [''],
      kpiResponsibleRole: [''],
      kpiAccountableRole: [''],
      kpiConsultRole: [''],
      kpiInformedRole: [''],
      Source: [''],
      kpiInformed: [''],
      kpiEntry: [''],
      kpiCalculationType: [''],
      kpiCalculationTypeItselfId: [''],
      kpiAllowActualEntry: [false],
      kpiAllowTargetEntry: [false],
      kpiAllowDynamicTarget: [false],
      machineHirearchy: [''],
      trend: ['', Validators.required],
      kpiFormulaType: ['', Validators.required],
      kpigroup: [''],
      kpiDataType: [''],
    });
    this.visionForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      plant: ['', Validators.required],
      quantify: ['', [Validators.pattern('[^"]+$')]],
      remarks: ['', [Validators.pattern('[^"]+$')]],
      yearFrom: ['', Validators.required],
      yearTo: ['', Validators.required],
    });
    this.strategicForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      plant: ['', Validators.required],
      quantify: ['', [Validators.pattern('[^"]+$')]],
      remarks: ['', [Validators.pattern('[^"]+$')]],
      year: ['', Validators.required],
      vision: [''],
    });
    this.zeroform = this.fb.group({
      zeroVisionValue: ['', Validators.required],
      zeroVisionDate: ['', Validators.required],
    });
    this.formulastring = '';
    this.visionMenu = true;
    this.strategicMenu = true;
    this.kpiMenu = true;
    this.strategicFormMode = 'add';
    this.visionFormMode = 'add';
    this.kpiFormMode = 'add';
    this.myform.get('kpiline')?.valueChanges.subscribe((selectedValue) => {
      // this.myform.get('kpiline')?.setValue(selectedValue === null ? '' : this.lines.find(c => c.plId === selectedValue));
      if (selectedValue) {
        this.getMachinesonEdit(selectedValue.plId);
      }
    });

    const body = document.querySelector('body');
    body?.classList.add('sidebar-icon-only');
    this.primengConfig.ripple = true;
    this.isShown = false;
    this.GetNodeMenus();
    this.getVisions();
    this.getDrivers();
    this.getFrequesncies();
    this.getUoms();
    this.getPillars();
    this.getGroupData();
    this.getCalculationType();
    this.getTrends();
    this.getdatelist();
    // this.getUserList();
    this.GetKPIDataTypes();
    this.searchPlantForm.controls.searchList.setValue(this.searchList[0]);


    this.timeFormGroup = this.fb.group({
      timepicker: ['', Validators.required],
    });
    this.timeFormGroup.controls.timepicker.valueChanges.subscribe((value: any) => {
      this.onChangeHour(value);
    });

  }

  DuplicateNode(key: any): any {
    this.kpiservice.DuplicateNode(key).subscribe(
      (res) => {
        this.getTreeData();
        this.showPopupMsg(res[0].result);
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  showPopupMsg(msg: any): any {
    if (msg !== null || msg !== '') {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
      } else {
        this.toaster.error('Error', msg.substring(3, msg.length));
      }
    }
  }

  getFrequencies(Type: boolean, freqNode: any): any {
    const id = this.searchPlantForm.value.plant.DeptId;
    const key = {
      plantId: id,
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.kpiMaster.getMasterFrequencies(key).subscribe(
      (data) => {
        this.frequencies = data;
        if (this.kpiFormMode !== 'add') {
          const res = this.editRecordList;
          const valueObj = this.frequencies.find(
            (x) => x.frequencyId === parseInt(res.Frequency, 10)
          );
          this.myform.get('kpifrequency')?.setValue(valueObj);
        }
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }


  // ...

  getTreeData(): void {
    this.data1 = [];
    this.spinner.show();
    const id = this.searchPlantForm.value.plant.DeptId;
    const key = {
      plantId: id,
      userId: JSON.parse(localStorage.user).UserId,
      treeId: this.searchPlantForm.value.searchListFilter?.kpiTree,
      date: new Date(),
      version: this.searchPlantForm.value.VersionList ? this.searchPlantForm.value.VersionList.Id : 0
    };

    const treeData$ = this.kpiservice.getTreeData(key);
    const dayOffList$ = this.kpiMaster.getMasterDayOff(key);
    const pillars$ = this.kpiMaster.getPillarMasterData(key);
    const drivers$ = this.kpiMaster.getMasterDrivers(key);
    const uoms$ = this.kpiMaster.getMasterUoms(key);

    forkJoin([treeData$, dayOffList$, pillars$, drivers$, uoms$]).subscribe(
      ([treeData, dayOffList, pillars, drivers, uoms]) => {
        this.handleTreeData(treeData);
        this.dayOffList = this.kpiMaster.getDayOfflist(dayOffList);
        this.pillars = pillars;
        this.drivers = drivers;
        this.uoms = uoms;
        this.spinner.hide();
      },
      error => {
        this.handleError(error);
      }
    );

    this.plantId = key.plantId;
    this.getplantUnit();
    this.getLines();
    this.getDepartments();
  }

  private handleTreeData(treeData: any): void {
    const myArr = [];
    myArr.push(treeData);
    this.data1 = myArr;
    if (
      (this.data1[0] === null || this.data1.length === 0) &&
      this.searchPlantForm.value.searchList?.id === 0
    ) {
      this.showButton = true;
      this.strategicshowButton = true;
    } else {
      this.showButton = false;
      this.strategicshowButton = false;
    }

    if (this.selectedNode) {
      setTimeout(() => {
        
        this.focusNode(this.selectedNode);
      }, 1000);
    } else {
      setTimeout(() => {
        this.onResetViewClicked();
      }, 1000);
    }
  }

  private handleError(error: any): void {
    this.toaster.error('Error', error.message);
    this.spinner.hide();
  }

  changeSelectList(): void {
    this.searchPlantForm.controls.searchList.setValue(this.searchList[0]);
    this.getResponsibleRoles();
    this.GetKPIVersion();
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        const obj = this.groupList[0];
        this.searchPlantForm.get('group')?.setValue(obj);
        this.getPlants();
        // this.getUserList();
        this.getAccountableRoles();
        this.getConsultRoles();
        this.getInformedRoles();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlants(): void {
    this.searchListFilter = [];
    const key = {
      // userId: JSON.parse(localStorage.user).UserId,
      GroupId: this.searchPlantForm.value.group.TypeId,
    };
    this.usermanagementService.getDepartments(key).subscribe(
      (res: any) => {
        this.searchPlants = res.DepartList;
        this.plants = res.DepartList;
        // if (this.searchPlants.length === 1) {
        const plantObject = this.searchPlants[0];
        this.searchPlantForm.get('plant')?.setValue(plantObject);

        this.changeSelectList();
        this.GetKPIGroups();

        this.getDepartments();

        // this.getTreeData();
        // }
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getSearchList(): void {
    this.searchListFilter = [];
    if (this.searchPlantForm.value.VersionList) {
      if (this.searchPlantForm.value.VersionList.IsLocked) {
        this.IsLocked = true;
      }
      else {
        this.IsLocked = false;
      }
    }
    this.GetNodeMenus();
    const id = this.searchPlantForm.value.plant.DeptId;
    const searchListId = this.searchPlantForm.value.searchList.id;
    const key = {
      plantId: id,
      version: this.searchPlantForm.value.VersionList ? this.searchPlantForm.value.VersionList.Id : 0
    };
    if (searchListId === 0) {
      this.kpiservice.getVisionsByPlant(key).subscribe(
        (res: any) => {
          this.searchListFilter = res;
          this.searchPlantForm.controls.searchListFilter.setValue(
            this.searchListFilter[0]
          );
          this.selectedNode = undefined;
          this.getTreeData();
        },
        (error) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else if (searchListId === 1) {
      this.kpiservice.getStrategicKpiByPlant(key).subscribe(
        (res: any) => {
          this.searchListFilter = res;
          this.searchPlantForm.controls.searchListFilter.setValue(
            this.searchListFilter[0]
          );
          this.selectedNode = undefined;
          this.getTreeData();
        },
        (error) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      const keys = {
        ptId: id,
        versionId: this.searchPlantForm.value.VersionList ? this.searchPlantForm.value.VersionList.Id : 0
      };
      this.kpiservice.GetAllKpisByPlantWithVersion(keys).subscribe(
        (res: any) => {
          this.searchListFilter = res;
          this.searchPlantForm.controls.searchListFilter.setValue(
            this.searchListFilter[0]
          );
          this.selectedNode = undefined;
          this.getTreeData();

        },
        (error) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  getAllMachines(): void {
    const data = {
      kpiId: this.selectedNode.kpiId,
    };
    this.kpiservice.getAllMachines(data).subscribe(
      (machine) => {
        const myArr = [];
        myArr.push(machine);
        this.machineData = myArr;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getplantUnit(): void {
    const data = {
      PU_Id: this.searchPlantForm.value.plant.DeptId,
    };
    this.kpiservice.plantUnit(data).subscribe(
      (machine) => {
        this.unitList = machine;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getTrends(): void {
    this.kpiMaster.getTrends().subscribe(
      (trends) => {
        this.trends = trends;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  GetKPIGroups(): void {
    const key = {
      ptId: this.searchPlantForm.value.plant.DeptId,
    };
    this.kpiGroupService.GetKPIGroups(key).subscribe(
      (kpiGroups) => {
        this.kpiGroups = kpiGroups;


      },
      (error) => {
        this.toaster.error('Error!', error.message);
      }
    );
  }

  GetKPIDataTypes(): void {

    this.kpiGroupService.GetKPIDataTypes().subscribe(
      (data) => {
        this.kpiDataTypes = data;
      },
      (error) => {
        this.toaster.error('Error!', error.message);
      }
    );
  }
  getVisions(): void {
    this.kpiservice.getVisions().subscribe(
      (visions) => {
        this.visions = visions;
      },
      (error) => {
        this.toaster.error('Error!', error.message);
      }
    );
  }
  getCalculationType(): void {
    this.kpiservice.getCalculationType().subscribe(
      (calculationType) => {
        this.calculationType = calculationType;
      },
      (error) => {
        this.toaster.error('Error!', error.message);
      }
    );
  }
  getDrivers(): void {
    this.kpiMaster.getDrivers().subscribe(
      (drivers) => {
        this.drivers = drivers;
      },
      (error) => {
        this.toaster.error('Error!', error.message);
      }
    );
  }

  getFrequesncies(): void {
    this.kpiMaster.getFrequencies().subscribe(
      (data) => {
        this.frequencies = data;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  ImportToExcel() {
    if (this.searchPlantForm.value.VersionList == undefined) {
      this.toaster.error('Please Select Version First.');

    }
    else {
      this.dialogRef = this.dialog.open(this.ImportDialog, { width: '500px' });
      this.enableOverlay();
      this.dialogRef.afterClosed().subscribe((result: any) => {
      });
    }
  }
  openFile() {
    this.fileInput.nativeElement.click();
  }
  uploadedFile(event: any) {
    debugger;
    this.dialogRef.close();
    this.excelData = [];
    // this.spinner.show();
    let recordArr = [];
    this.excelData = event.target.files[0];
    // const fileName = this.excelData.name;
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.excelData);
    fileReader.onload = (e) => {
      const arrayBuffer: any = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();

      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });
      for (let j = 0; j < workbook.SheetNames.length; j++) {
        const sheetName = workbook.SheetNames[j];
        const worksheet = workbook.Sheets[sheetName];

        if (sheetName === 'Vision') {
          const record = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          // Fill Name and Lable
          record.forEach(function (e) {
            if (typeof e === "object") { e["Name"] = record[0]['VisionFormulation'] }
          });
          record.forEach(function (e) {
            if (typeof e === "object") { e["Label"] = record[0]['VisionFormulation'] }
          });

          if (!record[0]['ID']) {
            this.toaster.error(
              `ID can't be empty on row ${sheetName} on Vision Sheet`,
              'Error',
              {
                timeOut: 2000,
              }
            );
          }
          else if (!record[0]['Parent'] && record[0]['Parent'] !== 0) {
            this.toaster.error(
              `Parent can't be empty on row ${sheetName} on Vision Sheet`,
              'Error',
              {
                timeOut: 2000,
              }
            );
          }
          else if (!record[0]['Group']) {
            this.toaster.error(
              `Group can't be empty on row ${sheetName} on Vision Sheet`,
              'Error',
              {
                timeOut: 2000,
              }
            );
          }
          else if (!record[0]['Plant']) {
            this.toaster.error(
              `Plant can't be empty on row ${sheetName} on Vision Sheet`,
              'Error',
              {
                timeOut: 2000,
              }
            );
          }
          else if (!record[0]['YearFrom']) {
            this.toaster.error(
              `YearFrom can't be empty on row ${sheetName} on Vision Sheet`,
              'Error',
              {
                timeOut: 2000,
              }
            );
          }
          else if (!record[0]['YearTo']) {
            this.toaster.error(
              `YearTo can't be empty on row ${sheetName} on Vision Sheet`,
              'Error',
              {
                timeOut: 2000,
              }
            );
          }
          else if (!record[0]['VisionFormulation']) {
            this.toaster.error(
              `VisionFormulation can't be empty on row ${sheetName} on Vision Sheet`,
              'Error',
              {
                timeOut: 2000,
              }
            );
          }
          else if (!record[0]['Type']) {
            this.toaster.error(
              `Type can't be empty on row ${sheetName} on Vision Sheet`,
              'Error',
              {
                timeOut: 2000,
              }
            );
          }


          // else if (!record[0]['Quantify']) {
          //   this.toaster.error(
          //     `Quantify can't be empty on row ${sheetName} on Vision Sheet`,
          //     'Error',
          //     {
          //       timeOut: 2000,
          //     }
          //   );
          // } 
          // else if (!record[0]['Remarks']) {
          //   this.toaster.error(
          //     `Remarks can't be empty on row ${sheetName} on Vision Sheet`,
          //     'Error',
          //     {
          //       timeOut: 2000,
          //     }
          //   );
          // } 
          else {
            // const record = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            for (const iterator of record) {
              recordArr.push(iterator);
            }
            //recordArr.push(record);

            //recordArr.push(record)
            //  recordArr.push({Vision:record})
          }
        } else if (sheetName === 'StrategicKPI') {
          const record1 = XLSX.utils.sheet_to_json(worksheet, { raw: true });


          for (var i = 0; i < record1.length; i++) {

            // Fill Name and Lable
            record1.forEach(function (e) {
              if (typeof e === "object") { e["Name"] = record1[i]['StrategicKPIDescription'] }
            });
            record1.forEach(function (e) {
              if (typeof e === "object") { e["Label"] = record1[i]['StrategicKPIDescription'] }
            });
            if (!record1[i]['ID']) {
              this.toaster.error(
                `ID can't be empty on row ${sheetName} on Strategic KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            } else if (!record1[i]['Parent']) {
              this.toaster.error(
                `Parent can't be empty on row ${sheetName} on Strategic KPI`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            } else if (!record1[i]['Group']) {
              this.toaster.error(
                `Group can't be empty on row ${sheetName} on Strategic KPI`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            } else if (!record1[i]['Plant']) {
              this.toaster.error(
                `Plant can't be empty on row ${sheetName} on Strategic KPI`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            } else if (!record1[i]['StrategicKPIDescription']) {
              this.toaster.error(
                `Strategic KPI Description can't be empty on row ${sheetName} on Strategic KPI`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record1[i]['Type']) {
              this.toaster.error(
                `Type can't be empty on row ${sheetName} on Strategic KPI`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record1[i]['Quantify']) {
              this.toaster.error(
                `Quantify can't be empty on row ${sheetName} on Strategic KPI`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record1[i]['Remarks']) {
              this.toaster.error(
                `Remarks can't be empty on row ${sheetName} on Strategic KPI`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record1[i]['Year']) {
              this.toaster.error(
                `Year can't be empty on row ${sheetName} on Strategic KPI`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }

          }

          //const record1 = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          for (const iterator of record1) {
            recordArr.push(iterator);
          }

          // recordArr.push({StrategicKPI:record})
          // recordArr.push(record)
        } else if (sheetName === 'KPI') {
          const record2 = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          for (var i = 0; i < record2.length; i++) {
            if (!record2[i]['ID']) {
              this.toaster.error(
                `ID can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['Parent']) {
              this.toaster.error(
                `Parent can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 20000,
                }
              );
            }

            else if (!record2[i]['Group']) {
              this.toaster.error(
                `Group can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }

            else if (!record2[i]['Plant']) {
              this.toaster.error(
                `Plant can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            } else if (!record2[i]['Label']) {
              this.toaster.error(
                `Label can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['KPIName']) {
              this.toaster.error(
                `KPIName can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }

            else if (!record2[i]['Type']) {
              this.toaster.error(
                `Type can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }

            else if (!record2[i]['UOM']) {
              this.toaster.error(
                `UOM can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }

            else if (!record2[i]['Frequency']) {
              this.toaster.error(
                `Frequency can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            } else if (!record2[i]['Trend']) {
              this.toaster.error(
                `Trend can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['Department']) {
              // this.toaster.error(
              //   `Department can't be empty on row ${sheetName} on  KPI Sheet`,
              //   'Error',
              //   {
              //     timeOut: 2000,
              //   }
              // );
            }
            else if (!record2[i]['Line']) {
              this.toaster.error(
                `Line can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['Machine']) {
              this.toaster.error(
                `Machine can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['KpiFormulaType']) {
              this.toaster.error(
                `KpiFormulaType can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['FormulaCalculationType']) {
              this.toaster.error(
                `FormulaCalculationType can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['AllowActualEntry']) {
              // this.toaster.error(
              //   `AllowActualEntry can't be empty on row ${sheetName} on  KPI Sheet `,
              //   'Error',
              //   {
              //     timeOut: 2000,
              //   }
              // );
            }
            else if (!record2[i]['AllowAutoTarget']) {

              // this.toaster.error(
              //   `AllowAutoTarget can't be empty on row ${sheetName} on  KPI Sheet `,
              //   'Error',
              //   {
              //     timeOut: 2000,
              //   }
              // );
            }



            else if (!record2[i]['ResponsibleRole']) {
              this.toaster.error(
                `ResponsibleRole can't be empty on row ${sheetName} on  KPI Sheet `,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['AccountableRole']) {
              this.toaster.error(
                `AccountableRole can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['ConsultRole']) {
              this.toaster.error(
                `ConsultRole can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['InformRole']) {
              this.toaster.error(
                `InformRole can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['Responsible']) {
              this.toaster.error(
                `Responsible can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['Accountable']) {
              this.toaster.error(
                `Accountable can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['Consult']) {
              this.toaster.error(
                `Consult can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
            else if (!record2[i]['Informed']) {
              this.toaster.error(
                `Informed can't be empty on row ${sheetName} on  KPI Sheet`,
                'Error',
                {
                  timeOut: 2000,
                }
              );
            }
          }

          // const record2 = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          for (const iterator of record2) {
            recordArr.push(iterator);
          }
        } else {
          return;
        }
      }
      // console.log(recordArr);
      // this.spinner.hide();
      recordArr.forEach((el: any) => {
        el.CreatedBy = JSON.parse(localStorage.user).UserId;
      });
      //  let tempArr = []

      //   tempArr.push({Tree:recordArr})
      this.spinner.show();
      this.kpiservice.addtreeData(recordArr).subscribe(
        (res) => {
          this.formMode = false;
          this.toaster.success(res.message);
          this.getSearchList()
          this.getTreeData();
          this.spinner.hide();
        },
        ({ error }) => {
          this.spinner.hide();
          this.toaster.error('error');
        }
      );

      //  this.validateSheet();
    };
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  getPillars(): void {
    this.kpiMaster.getPillars().subscribe(
      (pillars) => {
        this.pillars = pillars;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getUoms(): void {
    this.kpiMaster.getUoms().subscribe(
      (uoms) => {
        this.uoms = uoms;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getLines(): void {
    const key = {
      plantId: this.searchPlantForm.value.plant.DeptId,
    };
    this.commonService.getmasterline(key).subscribe(
      (lines) => {
        this.lines = lines;
        // this.getMachines();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getMachinesonEdit(val: any): void {
    const key = {
      plId: val,
    };
    this.commonService.getMachineByLine(key).then(
      (machines) => {
        const { PuId } = this.editRecordList;
        this.machines = machines;
        this.myform
          .get('kpimachine')
          ?.setValue(
            PuId === null ? '' : this.machines.find((c) => c.puId === PuId)
          );
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getMachines(): void {
    const key = {
      plId: this.myform.value.kpiline.plId,
    };
    this.commonService.getMachineByLine(key).then(
      (machines) => {
        this.machines = machines;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getResponsibleRoles(): any {
    const key = {
      EGId: this.searchPlantForm.value.group.TypeId,
      PlantId: this.searchPlantForm.value.plant.DeptId,
    };
    this.kpiservice.getPositionMasterData(key).then(
      (responsibleroles: any) => {
        this.responsibleroles = responsibleroles.positionMasterList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getResponsibleRolesUsers(): any {
    this.responsibles = [];
    const key = {
      EGId: this.searchPlantForm.value.group.TypeId,
      PlantId: this.searchPlantForm.value.plant.DeptId,
      roleId: this.myform.value.kpiResponsibleRole
        ? // ? this.myform.value.kpiResponsibleRole?.POS_Id
        this.myform.value.kpiResponsibleRole
          .filter((c: any) => c)
          .map((c: any) => c.POS_Id)
          .toString()
        : 0,
    };
    this.resultantResArray = this.myform.value.kpiResponsible
      ? this.myform.value.kpiResponsible
      : [];
    this.kpiservice.getPositionMappedUsers(key).then(
      (responsibles: any) => {
        this.responsibles = responsibles;
        for (var res of this.responsibles) {
          var foundCount = 0;
          for (var i = 0; i < this.responsibles.length; i++) {
            if (this.responsibles[i].fullName == res.fullName && this.responsibles[i].EmpInfo_Id == res.EmpInfo_Id && this.responsibles[i].userId == res.userId) {
              foundCount++;
              if (foundCount > 1) {
                this.responsibles.splice(i, 1);
              }
            }
          }
        }
        if (this.resultantResArray !== null) {
          const matchedReccords = this.responsibles.filter((o1) =>
            this.resultantResArray.some(
              (o2: any) => o2 && o1.EmpInfo_Id === o2.EmpInfo_Id
            )
          );
          if (matchedReccords.length) {
            // this.responsibles.push(this.resultantArray)
            this.myform.get('kpiResponsible')?.setValue(matchedReccords);
          } else {
            this.myform.get('kpiResponsible')?.reset();
          }
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getTarget(): void {
    const key = {


      visionId: this.selectedNode.visionCode,
      kpiId: this.selectedNode.kpiCode,
      frequency: this.selectedNode.frequency,

    };
    this.kpitarget.getTarget(key).subscribe(
      (data: any) => {
        this.dataArray = data;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );

  }

  getResponsibleRolesUsersForEdit(): any {
    this.responsibles = [];
    const key = {
      EGId: this.searchPlantForm.value.group.TypeId,
      PlantId: this.searchPlantForm.value.plant.DeptId,
      roleId: this.myform.value.kpiResponsibleRole
        ? // ? this.myform.value.kpiResponsibleRole?.POS_Id
        this.myform.value.kpiResponsibleRole
          .filter((c: any) => c)
          .map((c: any) => c.POS_Id)
          .toString()
        : 0,
    };
    this.kpiservice.getPositionMappedUsers(key).then(
      (responsibles: any) => {
        this.responsibles = responsibles;
        if (this.kpiFormMode === 'edit' || this.kpiFormMode === 'details') {
          const { RId } = this.editRecordList;
          if (RId != null) {
            const accoun: string[] = RId.split(',');
            const respArray = [];
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < accoun.length; i++) {
              const obj = this.responsibles.find(
                (x) => x.EmpInfo_Id === parseInt(accoun[i], 10)
              );
              // console.log('RespAray B4', respArray);
              respArray.push(obj);
              // console.log('RespAray After', respArray);
            }
            this.myform.get('kpiResponsible')?.setValue(respArray);
          }
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getAccountableRoles(): any {
    const key = {
      egId: this.searchPlantForm.value.group.TypeId,
    };
    this.usermanagementService.masterroles(key).subscribe(
      (accountableroles: any) => {
        this.accountableroles = accountableroles;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getAccountableRolesUsers(): any {
    this.accountables = [];
    const key = {
      EGId: this.searchPlantForm.value.group.TypeId,
      PlantId: this.searchPlantForm.value.plant.DeptId,
      roleId: this.myform.value.kpiAccountableRole
        ? this.myform.value.kpiAccountableRole.POS_Id
        : // ?.map((c: any) => c.POS_Id)
        // .toString()
        0,
    };
    this.myform.get('kpiAccountable')?.reset();
    this.kpiservice.getPositionMappedUsers(key).then(
      (accountables: any) => {
        this.accountables = accountables;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getAccountableRolesUsersForEdit(): any {
    this.accountables = [];
    const key = {
      EGId: this.searchPlantForm.value.group.TypeId,
      PlantId: this.searchPlantForm.value.plant.DeptId,
      roleId: this.myform.value.kpiAccountableRole
        ? this.myform.value.kpiAccountableRole.POS_Id
        : // ?.map((c: any) => c.POS_Id)
        // .toString()
        0,
    };
    this.myform.get('kpiAccountable')?.reset();
    this.kpiservice.getPositionMappedUsers(key).then(
      (accountables: any) => {
        this.accountables = accountables;
        if (this.kpiFormMode === 'edit' || this.kpiFormMode === 'details') {
          const { AId } = this.editRecordList;
          if (AId != null) {
            const accoun: string[] = AId.split(',');
            const accouArray = [];
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < accoun.length; i++) {
              const obj = this.accountables.find(
                (x) => x.EmpInfo_Id === parseInt(accoun[i], 10)
              );
              accouArray.push(obj);
            }
            this.myform.get('kpiAccountable')?.setValue(accouArray);
          }
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getConsultRoles(): any {
    const key = {
      egId: this.searchPlantForm.value.group.TypeId,
    };
    this.usermanagementService.masterroles(key).subscribe(
      (consultroles: any) => {
        this.consultroles = consultroles;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getConsultRolesUsers(): any {
    this.consults = [];
    const key = {
      EGId: this.searchPlantForm.value.group.TypeId,
      PlantId: this.searchPlantForm.value.plant.DeptId,
      roleId: this.myform.value.kpiConsultRole
        ? this.myform.value.kpiConsultRole
          ?.filter((c: any) => c)
          .map((c: any) => c.POS_Id)
          .toString()
        : 0,
    };
    // this.resultantConsArray = this.myform.value.kpiC; // this.myform.get('kpiResponsible')?.setValue([]);
    this.resultantConsArray = this.myform.value.kpiC
      ? this.myform.value.kpiC
      : [];
    this.kpiservice.getPositionMappedUsers(key).then(
      (consults: any) => {
        this.consults = consults;
        for (var res of this.consults) {
          var foundCount = 0;
          for (var i = 0; i < this.consults.length; i++) {
            if (this.consults[i].fullName == res.fullName && this.consults[i].EmpInfo_Id == res.EmpInfo_Id && this.consults[i].userId == res.userId) {
              foundCount++;
              if (foundCount > 1) {
                this.consults.splice(i, 1);
              }
            }
          }
        }
        if (this.resultantConsArray !== null) {
          const matchedReccords = this.consults.filter((o1) =>
            this.resultantConsArray.some(
              (o2: any) => o2 && o1.EmpInfo_Id === o2.EmpInfo_Id
            )
          );
          if (matchedReccords.length) {
            this.myform.get('kpiC')?.setValue(matchedReccords);
          } else {
            this.myform.get('kpiC')?.reset();
          }
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getConsultRolesUsersForEdit(): any {
    this.consults = [];
    const key = {
      EGId: this.searchPlantForm.value.group.TypeId,
      PlantId: this.searchPlantForm.value.plant.DeptId,
      roleId: this.myform.value.kpiConsultRole
        ? this.myform.value.kpiConsultRole
          ?.filter((c: any) => c)
          .map((c: any) => c.POS_Id)
          .toString()
        : 0,
    };
    this.kpiservice.getPositionMappedUsers(key).then(
      (consults: any) => {
        this.consults = consults;
        if (this.kpiFormMode === 'edit' || this.kpiFormMode === 'details') {
          const { CId } = this.editRecordList;
          if (CId != null) {
            const consult: string[] = CId.split(',');
            const consultArray = [];
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < consult.length; i++) {
              const obj = this.consults.find(
                (x) => x.EmpInfo_Id === parseInt(consult[i], 10)
              );
              consultArray.push(obj);
            }
            this.myform.get('kpiC')?.setValue(consultArray);
          }
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getInformedRoles(): any {
    const key = {
      egId: this.searchPlantForm.value.group.TypeId,
    };
    this.usermanagementService.masterroles(key).subscribe(
      (informedroles: any) => {
        this.informedroles = informedroles;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getInformedRolesUsers(): any {
    this.informs = [];
    const key = {
      EGId: this.searchPlantForm.value.group.TypeId,
      PlantId: this.searchPlantForm.value.plant.DeptId,
      roleId: this.myform.value.kpiInformedRole
        ? this.myform.value.kpiInformedRole
          .filter((c: any) => c)
          ?.map((c: any) => c.POS_Id)
          .toString()
        : 0,
    };
    // this.resultantInfArray = this.myform.value.kpiInformed; // this.myform.get('kpiResponsible')?.setValue([]);
    this.resultantInfArray = this.myform.value.kpiInformed
      ? this.myform.value.kpiInformed
      : [];
    this.kpiservice.getPositionMappedUsers(key).then(
      (informs: any) => {
        this.informs = informs;
        for (var res of this.informs) {
          var foundCount = 0;
          for (var i = 0; i < this.informs.length; i++) {
            if (this.informs[i].fullName == res.fullName && this.informs[i].EmpInfo_Id == res.EmpInfo_Id && this.informs[i].userId == res.userId) {
              foundCount++;
              if (foundCount > 1) {
                this.informs.splice(i, 1);
              }
            }
          }
        }
        if (this.resultantInfArray !== null) {
          const matchedReccords = this.informs.filter((o1) =>
            this.resultantInfArray.some(
              (o2: any) => o2 && o1.EmpInfo_Id === o2.EmpInfo_Id
            )
          );
          if (matchedReccords.length) {
            this.myform.get('kpiInformed')?.setValue(matchedReccords);
          } else {
            this.myform.get('kpiInformed')?.reset();
          }
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getInformedRolesUsersForEdit(): any {
    this.informs = [];
    const key = {
      EGId: this.searchPlantForm.value.group.TypeId,
      PlantId: this.searchPlantForm.value.plant.DeptId,
      roleId: this.myform.value.kpiInformedRole
        ? this.myform.value.kpiInformedRole
          ?.filter(Boolean)
          ?.map((c: any) => c.POS_Id)
          .toString()
        : 0,
    };
    this.kpiservice.getPositionMappedUsers(key).then(
      (informs: any) => {
        this.informs = informs;
        if (this.kpiFormMode === 'edit' || this.kpiFormMode === 'details') {
          const { IId } = this.editRecordList;
          if (IId != null) {
            const inform: string[] = IId.split(',');
            const informArray = [];
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < inform.length; i++) {
              const obj = this.informs.find(
                (x) => x.EmpInfo_Id === parseInt(inform[i], 10)
              );
              informArray.push(obj);
            }
            this.myform.get('kpiInformed')?.setValue(informArray);
          }
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getDepartments(): void {
    const key = {
      ptId: this.searchPlantForm.value.plant.DeptId,
    };
    this.machineMappingService.getDepartmenBytPlant(key).subscribe(
      (response: any) => {
        this.departments = response;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getUserList(): void {
    const key = {
      // egId: JSON.parse(localStorage.egId),
      egId: this.searchPlantForm.value.group.TypeId,
    };
    this.usermanagementService.getRaciUsers(key).subscribe(
      (users) => {
        this.raci = users;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onNodeSelect(event: any): void {
    this.selectedNode = event.node;
    

    this.datatype = Number(event.node.kpiDataType);
    //  this.datatype = 1;
    // console.log(this.selectedNode);

    if (event.node.allowAutoTargetEntry === '1') {
      if (event.node.frequency === 'Yearly') {
        this.kpiItems[1].items[5].visible = true;
      } else {
        this.kpiItems[1].items[5].visible = false;
      }
      this.kpiItems[1].items[5].disabled = false;
    } else {
      this.kpiItems[1].items[5].disabled = true;
    }
    // if (event.node.allowTargetEntry === '1') {
    //   this.kpiItems[1].disabled = false;
    //   this.kpiItems[1].visible = true;
    // } else {
    //   this.kpiItems[1].disabled = true;
    //   this.kpiItems[1].visible = false;
    // }
    this.maxDate = new Date(event.node.visionMaxDate);
    this.minDate = new Date(event.node.visionMinDate);
    // if (event.node & event.node.expanded) {
    //   this.focusNode(event.node);
    // }

    // this.closeContextMenu();
    // this.onCenterContentClicked();

  }
  toggleFullscreen() {
    if (!this.isFullscreen) {
      const container = this.orgChartContainer.nativeElement;
      const sidePanel = document.getElementsByClassName('sidepanel-model-wrapper') as HTMLCollectionOf<HTMLElement>;


      if (sidePanel.length > 0) {
        const element = sidePanel[0]; // Assuming there's only one element with the class name

        // Set the z-index
        element.style.zIndex = '999999';
      }


      // Save the original styles of the container
      this.originalContainerStyles = {
        position: container.style.position,
        top: container.style.top,
        left: container.style.left,
        width: container.style.width,
        height: container.style.height,
        zIndex: container.style.zIndex
      };

      // Apply fullscreen styles to the container
      container.style.position = 'fixed';
      container.style.top = '0';
      container.style.left = '0';
      container.style.width = '100%';
      container.style.height = '100%';
      container.style.zIndex = '9999';
      container.style.background = '#fff';
      this.isFullscreen = true;
    } else {
      const container = this.orgChartContainer.nativeElement;

      // Restore the original styles of the container
      container.style.position = this.originalContainerStyles.position;
      container.style.top = this.originalContainerStyles.top;
      container.style.left = this.originalContainerStyles.left;
      container.style.width = this.originalContainerStyles.width;
      container.style.height = this.originalContainerStyles.height;
      container.style.zIndex = this.originalContainerStyles.zIndex;

      this.isFullscreen = false;
    }


  }
  toggleNode(node: TreeNode) {
    const nodeId = node.id;
    this.nodeExpandedStates[nodeId] = !this.nodeExpandedStates[nodeId];
    // if(node.expanded){
    this.focusNode(node);
    // }
  }

  isNodeExpanded(nodeId: number): boolean {
    return this.nodeExpandedStates[nodeId] !== false;
  }


  focusNode2(event: any) {
    // Get the clicked node from the p-organizationChart component
    const node = event;

    // If a node was found, calculate its position and move the viewport to that position
    if (node) {
      const nodeElement = document.querySelector(`[data-nodeid='${node.id}']`);
      if (nodeElement) {
        nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    }
  }
  focusNode(event: any) {
    // Get the clicked node from the p-organizationChart component
    const node = event;

    // If a node was found, calculate its position and move the viewport to that position
    if (node) {
      const nodeElement = document.querySelector(`[data-nodeid='${node?.id}']`);
      if (nodeElement) {
        // Scroll the node element into view
        nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

        // Get the bounding box of the node element
        // const rect = nodeElement.getBoundingClientRect();

        // // Calculate the center point of the node element
        // const centerX = rect.left + rect.width / 2;
        // const centerY = rect.top + rect.height / 2;

        // // Convert the center point to model coordinates
        // const pointInModelCoords: Point = this.panZoomAPI.getViewPosition({ x: centerX, y: centerY });

        // // Pan the PanZoom component to the node's center point
        // this.panZoomAPI.panToPoint(pointInModelCoords);
      }
    }
  }










  ngAfterViewInit(): void {
    // this.resetZoomToFit();
    this.initialised = true;
    this.changeDetector.detectChanges();
    // setTimeout(() => {
    //   this.getSearchList();
    // }, 2000)

  }

  updateChart() {
    this.changeDetector.detectChanges();
  }






  ngOnDestroy(): void {
    // this.modelChangedSubscription.unsubscribe();
    this.apiSubscription.unsubscribe();
  }
  resetZoomToFit(): void {
    let height = this.gridElement.nativeElement.clientHeight;
    const width = this.gridElement.nativeElement.clientWidth;
    height = (this.canvasWidth * height) / width;
    this.panzoomConfig.initialZoomToFit = {
      x: 0,
      y: 0,
      width: this.canvasWidth,
      height,
    };
    this.initialZoomHeight = height;
  }

  onZoomInClicked(): void {
    this.panZoomAPI.zoomIn('viewCenter');
  }

  onZoomOutClicked(): void {
    this.panZoomAPI.zoomOut('viewCenter');
  }

  // onContentClick(event: MouseEvent): void {
  //   const panZoom = this.ngxPanZoomComponent;
  //   const rect = event.target.getBoundingClientRect();
  //   const x = event.clientX - rect.left;
  //   const y = event.clientY - rect.top;

  //   panZoom.panToPoint(x, y);
  // }

  onResetViewClicked(): void {
    // this.panZoomAPI.resetView();
    // Get the clicked node from the p-organizationChart component
    const node = this.data1[0];

    // If a node was found, calculate its position and move the viewport to that position
    const nodeElement = document.querySelector(`[data-nodeid='${node?.id}']`);
    if (nodeElement) {
      nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      // nodeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }

  }

  onPanLeft100Clicked(): void {
    this.panZoomAPI.panDelta({ x: -100, y: 0 });
  }

  onPanRight100Clicked(): void {
    this.panZoomAPI.panDelta({ x: 100, y: 0 });
  }

  onPanUp100Clicked(): void {
    this.panZoomAPI.panDelta({ x: 0, y: -100 });
  }

  onPanDown100Clicked(): void {
    this.panZoomAPI.panDelta({ x: 0, y: 100 });
  }

  onPanLeftPercentClicked(): void {
    this.panZoomAPI.panDeltaPercent({ x: -20, y: 0 });
  }

  onPanRightPercentClicked(): void {
    this.panZoomAPI.panDeltaPercent({ x: 20, y: 0 });
  }

  onPanUpPercentClicked(): void {
    this.panZoomAPI.panDeltaPercent({ x: 0, y: -20 });
  }

  onPanDownPercentClicked(): void {
    this.panZoomAPI.panDeltaPercent({ x: 0, y: 20 });
  }

  onPanToPointClicked(): void {
    this.panZoomAPI.panToPoint({ x: 2400, y: 4270 });
  }

  onCenterContentClicked(): void {
    this.panZoomAPI.centerContent();
  }

  onCenterXClicked(): void {
    this.panZoomAPI.centerX();
  }

  onCenterYClicked(): void {
    this.panZoomAPI.centerY();
  }

  onCenterTopLeftClicked(): void {
    this.panZoomAPI.centerTopLeft();
  }

  onCenterBottomLeftClicked(): void {
    this.panZoomAPI.centerBottomLeft();
  }

  onCenterTopRightClicked(): void {
    this.panZoomAPI.centerTopRight();
  }

  onCenterBottomRightClicked(): void {
    this.panZoomAPI.centerBottomRight();
  }
  onInitialized(api: PanZoomAPI) {
    // Save the API instance for later use
    this.panZoomAPI = api;

    // Zoom to a specific point
    const x = 100; // x coordinate
    const y = 100; // y coordinate
    const scale = 2; // zoom scale

    // Convert the point to model coordinates
    const pointInModelCoords: Point = this.panZoomAPI.getViewPosition({ x, y });

    // Pan to the desired point
    this.panZoomAPI.panToPoint(pointInModelCoords);

    // Zoom to the desired scale
    this.panZoomAPI.changeZoomLevel(scale, pointInModelCoords);
  }
  onParentClick(event: MouseEvent) {
    // Get the clicked point
    const clickedPoint: Point = { x: event.clientX, y: event.clientY };

    // Convert the clicked point from view coordinates to model coordinates
    const modelCoords: Point = this.panZoomAPI.getViewPosition(clickedPoint);

    // Pan to the clicked point
    this.panZoomAPI.panToPoint(modelCoords);

    // Zoom to the clicked point with a specific scale
    this.panZoomAPI.changeZoomLevel(2, modelCoords);
  }

  private isMobileDevice(): boolean {
    return (
      // tslint:disable-next-line: deprecation
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    );
  }

  onModelChanged(model: PanZoomModel): void {
    // this.panzoomModel = utils.deepCopy(model);
    // this.scale = this.getCssScale(this.panzoomModel.zoomLevel);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private getCssScale(zoomLevel: any): number {
    return Math.pow(
      this.panzoomConfig.scalePerZoomLevel,
      zoomLevel - this.panzoomConfig.neutralZoomLevel
    );
  }

  nodeUnselect(event: any): void {
    // console.log(event, 'node unselect');
    this.selectedNode = event.node;
    if (event.node.allowAutoTargetEntry === '1') {
      if (event.node.frequency === 'Yearly') {
        this.kpiItems[1].items[5].visible = true;
      } else {
        this.kpiItems[1].items[5].visible = false;
      }
      this.kpiItems[1].items[5].disabled = false;
    } else {
      this.kpiItems[1].items[5].disabled = true;
    }
    // if (event.node.allowTargetEntry === '1') {
    //   this.kpiItems[1].disabled = false;
    //   this.kpiItems[1].visible = true;
    // } else {
    //   this.kpiItems[1].disabled = true;
    //   this.kpiItems[1].visible = false;
    // }
  }

  machineNodeSelect(event: any): void {
    this.myform.get('machineHirearchy')?.setValue(this.selectedMachine);
  }

  machineNodeUnselect(event: any): void { }
  showVisionDialog(): void {
    if (this.searchPlantForm.value.VersionList == undefined) {
      this.toaster.error('Please Select Version First.');

    }
    else {
      this.resetForm();
      this.displayVision = true;
      this.visionFormMode = 'add';
      const plantObject = this.plants.find((p) => p.DeptId === this.plantId);
      this.visionForm.get('plant')?.setValue(plantObject);
    }
  }
  showStartgicKpiDialog(): void {

    if (this.searchPlantForm.value.VersionList == undefined) {
      this.toaster.error('Please Select Version First.');

    }
    else {
      this.resetForm();
      this.displayStartgicKpi = true;
      this.strategicFormMode = 'add';
      const plantObject = this.plants.find((p) => p.DeptId === this.plantId);
      this.strategicForm.get('plant')?.setValue(plantObject);
    }
  }
  showFormulaDialog(): void {
    this.displayFormulaBuilder = true;
    this.formulastring = this.myform.value.kpiFormula;
  }
  showFormulaRuleDialog(): void {
    this.displayFormulaRuleDialog = true;
  }
  showMachineDialog(): void {
    this.displayMachineDialog = true;
  }
  showEntry(): void {
    this.isShown = this.isShown;
  }
  showAllowActualEntry(): void {
    this.showFormula = !this.showFormula;
  }
  showAllowTarget(): void {
    // this.showDynamicFormula = !this.showDynamicFormula;
    this.showDynamicFormulaOperand = true;
    if (this.myform.value.kpiAllowDynamicTarget) {
      this.showDynamicFormula = true;
      this.showDynamicFormulaOperand = true;
    } else {
      this.showDynamicFormula = false;
      this.showDynamicFormulaOperand = false;
    }

  }


  showDynamicFormulaDialog(): void {
    this.displayDynamicFormulaBuilder = true;
    this.formulastring = this.myform.value.kpiFormula;
  }

  dynamicformulamaking(): any {

    if (typeof this.Dynamickpistringvalue === 'object' && this.Dynamickpistringvalue !== undefined && this.Dynamictargetstringvalue !== undefined) {

      this.Dynamicformulastring += this.Dynamickpistringvalue.kpiCode + '-' + this.Dynamickpistringvalue.name + '-' + this.Dynamictargetstringvalue.label;

      const obj = {
        name: this.Dynamickpistringvalue.kpiCode + '-' + this.Dynamickpistringvalue.name + '-' + this.Dynamictargetstringvalue.label,
        kpiCode: this.Dynamickpistringvalue.kpiCode,
        kpiType: this.Dynamictargetstringvalue.label,
        type: 'Operands'
      }
      if (obj) {
        this.targetArray.push(obj);
      }
    }

    else if (this.Dynamickpistringvalue === undefined) {
      this.toaster.error('Please Select KPI Code')
      //this.Dynamicformulastring += this.Dynamickpistringvalue +'-'+this.Dynamictargetstringvalue.label;
    }
    else {
      this.toaster.error('Please Select KPI Target Value')
      //this.Dynamicformulastring += this.Dynamickpistringvalue +'-'+this.Dynamictargetstringvalue.label;
    }


  }
  AddDynamicFormulaValue(): void {

    // this.formula = this.formulastring;
    // this.myform.get('kpiDynamicFormulaTarget')?.setValue(this.Dynamicformulastring);

    //Fetched Data from TargetArry
    this.FetchArray = [];
    for (let i = 0; i < this.targetArray.length; i++) {
      if (this.targetArray[i].name !== '') {
        const obj = {
          name: this.targetArray[i].name
        }
        this.FetchArray.push(obj);
      }

    }

    let projectNames = this.targetArray;
    this.myform.get('kpiDynamicFormulaValue')?.setValue(projectNames);

    // this.targetArray=[{name:''}];
    // this.targetCounter=0; // Reset Counter Value
    this.displayDynamicFormulaBuilder = false;
  }
  ClearDyanamicChipList() {
    this.targetArray = [];
    // this.FetchArray=[];
  }
  addOperator(oper: any): void {


    const key = {
      name: oper,
      kpiCode: this.Dynamickpistringvalue.kpiCode,
      kpiType: this.Dynamictargetstringvalue.label,
      type: 'Operator'
    }

    this.targetArray.push(key);
    let formvalidate = '';
    this.targetArray.forEach((elem, index) => {
      formvalidate = formvalidate + elem.dpid;
    });

    try {

      if (eval(formvalidate)) {
        let counter = 0;
        this.disableSave = false;
        for (let i = 0; i < this.targetArray.length - 1; i++) {
          if (
            this.targetArray[i].type === 'Operands' &&
            this.targetArray[i + 1].type === 'Operands' &&
            this.targetArray.length > i
          ) {
            counter++;
          }
          if (counter > 0) {
            // console.log('double Operator');
            this.disableSave = true;
          }
        }
      }
    } catch (error) {
      this.disableSave = true;
    }
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.targetArray, this.PrevIndex, this.currentIndex);
    let formvalidate = '';
    this.targetArray.forEach((elem, index) => {
      formvalidate = formvalidate + elem.dpid;
    });
    try {
      // tslint:disable-next-line:no-eval
      if (eval(formvalidate)) {
        let counter = 0;
        this.disableSave = false;
        for (let i = 0; i < this.targetArray.length - 1; i++) {
          if (
            this.targetArray[i].type === 'Operands' &&
            this.targetArray[i + 1].type === 'Operands' &&
            this.targetArray.length > i
          ) {
            counter++;
          }
          if (counter > 0) {
            this.disableSave = true;
          }
        }
      }
    } catch (error) {
      this.disableSave = true;
    }
  }

  removeKeyword(key: any, index: any): any {
    // this.targetArray.forEach((element, index) => {
    //   if (element === key.name) {
    //     this.targetArray.splice(index, 1);
    //   }
    // });
    // this.datapoints1.forEach((element) => {
    //   element.data.forEach((element1: any) => {
    //     if (element1.KpiDpId === key.dpid) {
    //       element1.checked = false;
    //     }
    //   });
    // });
    this.targetArray.splice(index, 1);
    let formvalidate = '';
    this.targetArray.forEach((elem) => {
      formvalidate = formvalidate + elem.name;
    });
    try {
      // tslint:disable-next-line:no-eval
      if (eval(formvalidate)) {
        let counter = 0;
        this.disableSave = false;
        for (let i = 0; i < this.targetArray.length - 1; i++) {
          if (
            this.targetArray[i].type === 'Operands' &&
            this.targetArray[i + 1].type === 'Operands' &&
            this.targetArray.length > i
          ) {
            counter++;
          }
          if (counter > 0) {
            this.disableSave = true;
          }
        }
      }
    } catch (error) {
      this.disableSave = true;
    }
  }
  mouseEnter(index: any): void {
    this.currentIndex = index;
  }

  allowEdit(key: any, index: number): void {
    if (key.editable) {
      this.targetArray[index].disable = true;
    }
  }

  dragStart(index: any): void {
    this.PrevIndex = index;
  }



  onSubmit(): void {
    if (this.myform.valid) {
      const updateValues = this.myform.value;
      const rrole: any[] = updateValues.kpiResponsibleRole
        ? updateValues.kpiResponsibleRole
          ?.filter((c) => c)
          .map((c: any) => c.POS_Id)
          .toString()
        : null;
      const arole: any[] = updateValues.kpiAccountableRole
        ? updateValues.kpiAccountableRole.POS_Id // .map((c: any) => c.POS_Id).toString()
        : null;
      const crole: any[] = updateValues.kpiConsultRole
        ? updateValues.kpiConsultRole
          ?.filter((c) => c)
          .map((c: any) => c.POS_Id)
          .toString()
        : null;
      const irole: any[] = updateValues.kpiInformedRole
        ? updateValues.kpiInformedRole
          ?.filter((c) => c)
          .map((c: any) => c.POS_Id)
          .toString()
        : null;
      const kpiMastertreeData = {
        title: this.myform.value.kpiTitle,
        kpiCode: this.myform.value.kpiCode,
        type: 'kpi',
        styleClass: 'p-kpi',
        drivers: this.myform.value.kpiDriver,
        frequency: this.myform.value.kpifrequency.frequencyId,
        parentid: this.selectedNode.id,
        plantid: this.selectedNode.plantId,
        uom: this.myform.value.kpiUom.uomId,
        pillar: this.myform.value.kpiPillar,
        departments: this.myform.value.kpidepartments,
        attachedStargicKpi: this.myform.value.attachedStargicKpi,
        formula: this.myform.value.kpiFormula,
        kpiDyanmicFormula: this.myform.value.kpiDynamicFormulaValue,
        responsible: this.myform.value.kpiResponsible,
        kpiResponsibleRole: rrole,
        accountable: this.myform.value.kpiAccountable,
        kpiAccountableRole: arole,
        consult: this.myform.value.kpiC,
        kpiConsultRole: crole,
        informed: this.myform.value.kpiInformed,
        kpiInformedRole: irole,
        entry: this.myform.value.kpiEntry,
        strategicKpiId: this.selectedNode.strategicKpiId,
        trendId: this.myform.value.trend.trendId,
        plId: this.myform.value.kpiline?.plId,
        puId: this.myform.value.kpimachine?.puId,
        kpigroup: this.myform.value.kpigroup?.kpiGroupId,
        kpiDataType: this.myform.value.kpiDataType?.KPIDT_Id,
        allowActualEntry:
          this.myform.value.kpiAllowActualEntry === null ? false : true,
        allowAutoTargetEntry:
          this.myform.value.kpiAllowTargetEntry === null ? false : true,
        allowTarget: this.myform.value.kpiAllowDynamicTarget === null ? false : true,
        // tslint:disable-next-line:max-line-length
        calculationTypeId:
          this.myform.value.kpiCalculationType === (undefined || null)
            ? null
            : this.myform.value.kpiCalculationType.calculationTypeId,
        // tslint:disable-next-line:max-line-length
        calculationTypeItselfId:
          this.myform.value.kpiCalculationTypeItselfId === (undefined || null)
            ? null
            : this.myform.value.kpiCalculationTypeItselfId.calculationTypeId,
        visionId: this.selectedNode.visionId,
        kpiFormulaType:
          this.myform.value.kpiFormulaType === (undefined || null)
            ? null
            : this.myform.value.kpiFormulaType.name,
        createdBy: JSON.parse(localStorage.user).UserId,
        createdOn: new Date(),
        versionId: this.searchPlantForm.value.VersionList.Id
      };
      this.selectNodePlantId = this.selectedNode.plantId;
      // console.log()
      this.kpiservice.addKpi(kpiMastertreeData).subscribe(
        (response: any) => {
          const addKpiNode: TreeNode = {
            label: 'New KPI',
            type: 'kpi',
            styleClass: 'p-kpi',
            expanded: true,
            frequency: 'Frequency',
            uom: 'uom',
            frequencyColor: '#fed713',
            kpiCode: '1',
            children: [],
          };

          this.selectedNode.children?.push(addKpiNode);
          addKpiNode.label = this.myform.value.kpiTitle;
          addKpiNode.frequency = this.myform.value.kpifrequency.name
          addKpiNode.uom = this.myform.value.kpiUom.name;
          addKpiNode.id = response[0].NodeId;
          addKpiNode.plantId = this.selectNodePlantId;
          addKpiNode.strategicKpiId = this.selectedNode.strategicKpiId;
          addKpiNode.pid = this.selectedNode.id;
          addKpiNode.visionId = this.selectedNode.visionId;
          addKpiNode.frequencyColor = response[0].frequencyColor;
          addKpiNode.kpiId = response[0].kpiId;
          addKpiNode.kpiCode = response[0].kpiCode;
          this.buildTreeWithExpandedState(this.selectedNode, false, true);

          // this.getSearchList();
          this.CurrentDate = new Date();
          const id = this.searchPlantForm.value.plant.DeptId;
          const key = {
            ptId: id,
            versionId: this.searchPlantForm.value.VersionList ? this.searchPlantForm.value.VersionList.Id : 0
          };
          this.kpiservice.GetAllKpisByPlantWithVersion(key).subscribe(
            (res: any) => {
              this.searchListFilter = res;
              // this.searchPlantForm.controls.searchListFilter.setValue(
              //   this.searchListFilter[0]
              // );
              // let maxObject = this.searchListFilter.reduce((prev, current) => {
              //   return (prev.Id > current.Id) ? prev : current;
              // });
              // console.log("this is a searchFilter",this.searchListFilter);
              // this.getTreeData();

            },
            (error) => {
              this.toaster.error('Error', error.message);
            }
          );
          //this.getTreeData();
          this.myform.reset();


          this.visibleSidebar2 = false;

          setTimeout(() => {
            const element: HTMLElement = document.getElementsByClassName(
              'p-corner-top'
            )[0] as HTMLElement;
            element.click();
          }, 1000);
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  editKPI(): void {

    const id = this.selectedNode.id;
    const updateValues = this.myform.value;
    const rrole: any[] = updateValues.kpiResponsibleRole
      ? updateValues.kpiResponsibleRole
        ?.filter((c) => c)
        .map((c: any) => c.POS_Id)
        .toString()
      : null;
    const arole: any[] = updateValues.kpiAccountableRole
      ? updateValues.kpiAccountableRole.POS_Id // .map((c: any) => c.POS_Id).toString()
      : null;
    const crole: any[] = updateValues.kpiConsultRole
      ? updateValues.kpiConsultRole
        ?.filter((c) => c)
        .map((c: any) => c.POS_Id)
        .toString()
      : null;
    const irole: any[] = updateValues.kpiInformedRole
      ? updateValues.kpiInformedRole
        ?.filter((c) => c)
        .map((c: any) => c.POS_Id)
        .toString()
      : null;
    if (this.myform.valid) {
      const kpiMastertreeData = {
        Id: id,
        title: this.myform.value.kpiTitle,
        kpiCode: this.myform.value.kpiCode,
        type: 'kpi',
        styleClass: 'p-kpi',
        drivers: this.myform.value.kpiDriver,
        frequency: this.myform.value.kpifrequency?.frequencyId,
        parentid: this.selectedNode.pid,
        plantid: this.selectedNode.plantId,
        uom: this.myform.value.kpiUom?.uomId,
        pillar: this.myform.value.kpiPillar,
        departments: this.myform.value.kpidepartments,
        formula: this.myform.value.kpiFormula,
        kpiDyanmicFormula: this.myform.value.kpiDynamicFormulaValue,
        responsible: this.myform.value.kpiResponsible,
        accountable: this.myform.value.kpiAccountable,
        consult: this.myform.value.kpiC,
        informed: this.myform.value.kpiInformed,
        entry: this.myform.value.kpiEntry,
        strategicKpiId: this.SKPIId,
        trendId: this.myform.value.trend?.trendId,
        plId: this.myform.value.kpiline?.plId,
        puId: this.myform.value.kpimachine?.puId,
        allowActualEntry: this.myform.value.kpiAllowActualEntry,
        allowAutoTargetEntry: this.myform.value.kpiAllowTargetEntry,
        allowTarget: this.myform.value.kpiAllowDynamicTarget,
        kpigroup: this.myform.value.kpigroup?.kpiGroupId,
        kpiDataType: this.myform.value.kpiDataType?.KPIDT_Id,
        calculationTypeId:
          this.myform.value.kpiCalculationType === undefined
            ? null
            : this.myform.value.kpiCalculationType.calculationTypeId,
        calculationTypeItselfId:
          this.myform.value.kpiCalculationTypeItselfId === undefined
            ? null
            : this.myform.value.kpiCalculationTypeItselfId.calculationTypeId,
        attachedStargicKpi: this.myform.value.attachedStargicKpi,
        visionId: this.selectedNode.visionId,
        kpiId: this.selectedNode.kpiId,

        kpiFormulaType:
          this.myform.value.kpiFormulaType === undefined
            ? null
            : this.myform.value.kpiFormulaType.name,
        kpiResponsibleRole: rrole,
        kpiAccountableRole: arole,
        kpiConsultRole: crole,
        kpiInformedRole: irole,
        updatedBy: JSON.parse(localStorage.user).UserId,
        updatedOn: new Date(),
      };
      this.kpiservice.updateKpi(kpiMastertreeData).subscribe(
        (response: any) => {
          this.selectedNode.label = this.myform.value.kpiTitle;
          this.selectedNode.frequency = this.myform.value.kpifrequency.name;
          this.selectedNode.uom = this.myform.value.kpiUom.name;
          this.myform.reset();
          this.visibleSidebar2 = false;
          // this.getSearchList();
          //this.getTreeData();
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
    this.isShown = true;
  }


  CreateNewKPIVersion(): void {

    const id = this.selectedNode.id;
    const updateValues = this.myform.value;
    const rrole: any[] = updateValues.kpiResponsibleRole
      ? updateValues.kpiResponsibleRole
        ?.filter((c) => c)
        .map((c: any) => c.POS_Id)
        .toString()
      : null;
    const arole: any[] = updateValues.kpiAccountableRole
      ? updateValues.kpiAccountableRole.POS_Id // .map((c: any) => c.POS_Id).toString()
      : null;
    const crole: any[] = updateValues.kpiConsultRole
      ? updateValues.kpiConsultRole
        ?.filter((c) => c)
        .map((c: any) => c.POS_Id)
        .toString()
      : null;
    const irole: any[] = updateValues.kpiInformedRole
      ? updateValues.kpiInformedRole
        ?.filter((c) => c)
        .map((c: any) => c.POS_Id)
        .toString()
      : null;
    if (this.myform.valid) {
      const kpiMastertreeData = {
        Id: id,
        title: this.myform.value.kpiTitle,
        kpiCode: this.myform.value.kpiCode,
        type: 'kpi',
        styleClass: 'p-kpi',
        drivers: this.myform.value.kpiDriver,
        frequency: this.myform.value.kpifrequency?.frequencyId,
        parentid: this.selectedNode.pid,
        plantid: this.selectedNode.plantId,
        uom: this.myform.value.kpiUom?.uomId,
        pillar: this.myform.value.kpiPillar,
        departments: this.myform.value.kpidepartments,
        formula: this.myform.value.kpiFormula,
        kpiDyanmicFormula: this.myform.value.kpiDynamicFormulaValue,
        responsible: this.myform.value.kpiResponsible,
        accountable: this.myform.value.kpiAccountable,
        consult: this.myform.value.kpiC,
        informed: this.myform.value.kpiInformed,
        entry: this.myform.value.kpiEntry,
        strategicKpiId: this.SKPIId,
        trendId: this.myform.value.trend?.trendId,
        plId: this.myform.value.kpiline?.plId,
        puId: this.myform.value.kpimachine?.puId,
        allowActualEntry: this.myform.value.kpiAllowActualEntry,
        allowAutoTargetEntry: this.myform.value.kpiAllowTargetEntry,
        allowTarget: this.myform.value.kpiAllowDynamicTarget,
        kpigroup: this.myform.value.kpigroup?.kpiGroupId,
        kpiDataType: this.myform.value.kpiDataType?.KPIDT_Id,
        calculationTypeId:
          this.myform.value.kpiCalculationType === undefined
            ? null
            : this.myform.value.kpiCalculationType.calculationTypeId,
        calculationTypeItselfId:
          this.myform.value.kpiCalculationTypeItselfId === undefined
            ? null
            : this.myform.value.kpiCalculationTypeItselfId.calculationTypeId,
        attachedStargicKpi: this.myform.value.attachedStargicKpi,
        visionId: this.selectedNode.visionId,

        kpiFormulaType:
          this.myform.value.kpiFormulaType === undefined
            ? null
            : this.myform.value.kpiFormulaType.name,
        kpiResponsibleRole: rrole,
        kpiAccountableRole: arole,
        kpiConsultRole: crole,
        kpiInformedRole: irole,
        updatedBy: JSON.parse(localStorage.user).UserId,
        updatedOn: new Date(),
        versionId: this.searchPlantForm.value.VersionList.Id
      };
      this.kpiservice.CreateNewKPIVersion(kpiMastertreeData).subscribe(
        (response: any) => {
          this.selectedNode.label = this.myform.value.kpiTitle;
          this.selectedNode.frequency = this.myform.value.kpifrequency.name;
          this.selectedNode.uom = this.myform.value.kpiUom.name;
          this.myform.reset();
          this.visibleSidebar2 = false;
          this.getSearchList();
          this.getTreeData();
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
    this.isShown = true;
  }

  addVision(): void {
    this.spinner.show();
    const { name, plant, yearFrom, yearTo, quantify, remarks } =
      this.visionForm.value;
    const vision = {
      name,
      plantId: this.visionForm.value.plant.DeptId,
      quantify,
      remarks,
      yearFrom: yearFrom.getFullYear(),
      yearTo: yearTo.getFullYear(),
      type: 'vision',
      styleClass: 'p-vision',
      versionId: this.searchPlantForm.value.VersionList.Id
    };
    this.kpiservice.addVision(vision).subscribe(
      (response: any) => {
        this.CurrentDate = new Date();
        this.spinner.hide();
        this.toaster.success('Success', 'Vision added successfully');
        this.showButton = false;
        this.strategicshowButton = false;
        // this.getTreeData();
        this.getSearchList();
        this.getVisions();
        // this.getGroupData();
        this.displayVision = false;
        this.resetForm();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  updateVision(): void {
    const { name, plant, yearFrom, yearTo, quantify, remarks } =
      this.visionForm.value;
    const vision = {
      visionId: this.visionId,
      name,
      plantId: this.visionForm.value.plant.DeptId,
      quantify,
      remarks,
      yearFrom: yearFrom.getFullYear(),
      yearTo: yearTo.getFullYear(),
      type: 'vision',
    };
    this.kpiservice.updateVision(vision).subscribe(
      (response: any) => {
        this.selectedNode.label = name;
        this.selectedNode.plant = plant.DeptDesc;
        this.selectedNode.quantify = quantify;
        this.toaster.success('Success', 'Vision Updated Successfully');
        this.visionForm.reset();
        this.displayVision = false;
        // this.getGroupData();
        this.getVisions();
        //this.getTreeData();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  CreateNewVisionVersion(): void {
    const { name, plant, yearFrom, yearTo, quantify, remarks } =
      this.visionForm.value;
    const vision = {
      visionId: this.visionId,
      name,
      plantId: this.visionForm.value.plant.DeptId,
      quantify,
      remarks,
      yearFrom: yearFrom.getFullYear(),
      yearTo: yearTo.getFullYear(),
      type: 'vision',
      versionId: this.searchPlantForm.value.VersionList.Id
    };
    this.kpiservice.CreateNewVisionVersion(vision).subscribe(
      (response: any) => {
        this.selectedNode.label = name;
        this.selectedNode.plant = plant.DeptDesc;
        this.selectedNode.quantify = quantify;
        this.toaster.success('Success', 'Vision Updated Successfully');
        this.visionForm.reset();
        this.displayVision = false;
        // this.getGroupData();
        this.getVisions();
        this.getTreeData();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getStragicKpi(key: any): any {
    this.kpiservice.getStratgicKpi(key).subscribe(
      (skpi) => {
        this.stragicKpis = skpi;
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  addStrategicKpidata(): void {
    this.spinner.show();
    const { name, plant, quantify, remarks, vision, year } =
      this.strategicForm.value;
    const data = {
      name,
      quantify,
      remarks,
      year: year.getFullYear(),
      visionId: vision === null || undefined || '' ? null : vision.VisionId,
      parentId:
        vision === null || undefined || '' ? null : this.selectedNode.id,
      plantId: this.strategicForm.value.plant.DeptId,
      type: 'strategickpi',
      styleClass: 'p-strategickpi',
      versionId: this.searchPlantForm.value.VersionList.Id
    };
    debugger;
    this.kpiservice.addStrategicKpi(data).subscribe(
      (response: any) => {
        debugger;
        this.CurrentDate = new Date();
        this.spinner.hide();
        //this.getTreeData();
        this.toaster.success('Success', 'Strategic KPI added successfully');
        this.selectedNode.children?.push(this.addStrategicKpi);
        this.addStrategicKpi.label = name;
        this.addStrategicKpi.plant = plant.DeptDesc;
        this.addStrategicKpi.quantify = quantify;
        this.addStrategicKpi.id = response[0].KpiTreeId;
        this.addStrategicKpi.plantId = plant.DeptId;
        this.addStrategicKpi.strategicKpiId = response[0].SKPIId;
        this.displayStartgicKpi = false;
        this.strategicForm.reset();
        //this.getTreeData();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  updateStrategicKpiData(): void {
    const { name, plant, vision, quantify, remarks, year } =
      this.strategicForm.value;
    const updatedData = {
      Id: this.selectedNode.id,
      name,
      quantify,
      remarks,
      year: year.getFullYear(),
      visionId: vision === undefined ? null : vision.VisionId,
      plantId: this.plantId,
    };
    this.kpiservice.updateStrategicKpi(updatedData).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'strategic KPI updated successfully');
        //this.getTreeData();
        this.selectedNode.label = name;
        this.selectedNode.plant = plant.ptDesc;
        this.selectedNode.quantify = quantify;
        this.displayStartgicKpi = false;
        // this.getGroupData();
        this.getVisions();
        this.getCalculationType();
        this.strategicForm.reset();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  CreateStrategicKPIVersion(): void {
    const { name, plant, vision, quantify, remarks, year } =
      this.strategicForm.value;
    const updatedData = {
      Id: this.selectedNode.id,
      name,
      quantify,
      remarks,
      year: year.getFullYear(),
      visionId: vision === undefined ? null : vision.VisionId,
      plantId: this.plantId,
      versionId: this.searchPlantForm.value.VersionList.Id
    };
    this.kpiservice.CreateStrategicKpiVersion(updatedData).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'strategic KPI Version Created successfully');
        this.getTreeData();
        this.selectedNode.label = name;
        this.selectedNode.plant = plant.ptDesc;
        this.selectedNode.quantify = quantify;
        this.displayStartgicKpi = false;
        // this.getGroupData();
        this.getVisions();
        this.getCalculationType();
        this.strategicForm.reset();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }


  removeId(data: any, id: any): any {
    data.forEach((o: any, i: any): any => {
      if (o.id && o.id === id) {
        data.splice(i, 1);
        return true;
      } else if (o.children) {
        this.removeId(o.children, id);
      }
    });
    if (this.data1.length === 0) {
      this.showButton = true;
      this.strategicshowButton = true;
    }

    //this.getTreeData();
    // setTimeout(() => {
    //   const element: HTMLElement = document.getElementsByClassName(
    //     'p-corner-top'
    //   )[0] as HTMLElement;
    //   element.click();
    // }, 1000);

  }

  visionMenuFunction(): void {
    this.displayVision = false;
    this.visionMenu = true;
  }

  // getzerovalue
  getzerovalue(): void {
    const key = {
      kpiId: this.selectedNode.kpiId,
    };
    this.zerovalueservice.getzerovalue(key).subscribe(
      (response: any) => {
        this.zeroform
          .get('zeroVisionValue')
          ?.setValue(response.zeroVisionValue);
        this.zeroform.get('zeroVisionDate')?.setValue(response.zeroVisionDate);
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  // Add zeroValue
  addzerovalue(): void {
    const key = {
      kpiId: this.selectedNode.kpiId,
      zeroVisionValue: this.zeroform.value.zeroVisionValue,
      zeroVisionDate: moment(this.zeroform.value.zeroVisionDate).format(
        'YYYY-MM-DD'
      ),
    };
    this.zerovalueservice.addzerovalue(key).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'ZeroVision Add Successfully');
        this.dialog.closeAll();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  closeaddNewGroupModel(): void {
    this.visibleSidebar2 = false;
  }

  resetForm(): void {
    this.visionForm.reset();
    this.myform.reset();
    this.strategicForm.reset();
  }
  closeContextMenu(): void {
    const elements = document.getElementsByClassName('p-tieredmenu-overlay');
    while (elements.length > 0) {
      elements[0].classList.remove('p-tieredmenu-overlay');
    }
    const elem = document.querySelector('.ng-trigger-overlayAnimation');
    if (elem !== null) {
      elem.remove();
    }
  }
  displayCalculationType(): any {
    this.isShown = !this.isShown;
  }
  getdatelist(): any {
    this.dates = this.kpiservice.dateList();
    this.operators = this.kpiservice.operatorList();
    this.operands = this.kpiservice.operandlist();
  }
  loadrule(): any {
    if (this.rule === 'Date') {
      this.showFormulaDate = true;
      this.showFormulaOperand = false;
      this.showFormulaOperater = false;
      this.rulelist = this.kpiservice.dateList();
    } else if (this.rule === 'Operand') {
      this.rulelist = this.frequencies;
      this.showFormulaOperand = true;
      this.showFormulaDate = false;
      this.showFormulaOperater = false;
    } else if (this.rule === 'Operator') {
      this.showFormulaOperater = true;
      this.showFormulaOperand = false;
      this.showFormulaDate = false;
      this.rulelist = this.kpiservice.operatorList();
    }
  }
  formulamaking(): any {
    if (typeof this.stringvalue === 'object' && this.stringvalue !== null) {
      this.formulastring += this.stringvalue.kpiCode;
      // this.displayFormulaRuleDialog = false;
    } else {
      this.formulastring += this.stringvalue;
      // this.displayFormulaRuleDialog = false;
    }
  }
  AddFormulaValue(): void {
    // this.formula = this.formulastring;
    this.myform.get('kpiFormula')?.setValue(this.formulastring);
    this.displayFormulaBuilder = false;
  }
  FrequecnyChange(event: any): any {
    this.myform.get('kpiCode')?.setValue(event.value.name[0] + '');
  }
  onFrequencyChange(event: any): any {
    const key = {
      plantId: this.searchPlantForm.value.plant.DeptId,
      frequencyId: event.value.frequencyId,
    };
    this.kpiMaster.getKpiFrequency(key).subscribe(
      (kpi) => {
        this.kpis = kpi;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  deletekpiTargetComplete(): any {
    const key = {
      kpiId: this.selectedNode.kpiId,
    };
    this.kpitarget.deleteKpiTarget(key).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'Data Delete Successfuly');
        this.getTreeData();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  deletekpiTarget(e: any): any {
    e.rowData.TargetValue = '';
    const key = {
      data: e.rowData,
    };
    this.onCellValueChanged(key);
    const getTarget = {
      visionId: this.selectedNode.visionCode,
      kpiId: this.selectedNode.kpiCode,
      frequency: this.selectedNode.frequency,
    };
    this.kpitarget.getTarget(getTarget).subscribe(
      (data: any) => {
        this.dataArray = data;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  inputValidation(params: any): any {
    if (isNaN(params.newValue) && this.selectedNode.kpiDataType == 1) {
      this.toaster.warning('Invalid!', 'Please Enter Integar Value Only');
      return params.oldValue;
    } else {
      return params.newValue;
    }
  }
  GetMasterShiftData(): any {
    const id = this.searchPlantForm.value.plant.DeptId;
    const key = {
      plantId: id,
    };
    this.kpiMaster.getMasterShifts(key).subscribe(
      (data) => {
        this.shifts = data;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onCellValueChanged(params: any): void {

    let TargetValue;
    // if(params.data.TargetValue == 0)
    // TargetValue='';
    // else
    TargetValue = params.data.TargetValue;

    const dateString = params.data.TargetDate; // Oct 23
    const dateParts = dateString.split('/');
    // month is 0-based, that's why we need dataParts[1] - 1
    const dateObject = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
    if (
      this.selectedNode.frequency === 'Yearly' &&
      this.kpiautotarget === true
    ) {
      this.kpiautotarget = true;
    } else {
      this.kpiautotarget = false;
    }
    const monthly = {
      kpiId: this.selectedNode.kpiId,
      frequecnyId: this.selectedNode.frequencyId,
      uomId: this.selectedNode.uomId,
      plantId: this.selectedNode.plantId,
      visionId: this.selectedNode.visionId,
      kpiCode: this.selectedNode.kpiCode,
      date: dateObject,
      // targetValue: params.data.TargetValue > 0 ? params.data.TargetValue : 0,
      targetValue: TargetValue,
      kpiTargetId: params.data.KPITargetId,
      createdBy: JSON.parse(localStorage.user).UserId,
      createdOn: new Date(),
      updatedBy: JSON.parse(localStorage.user).UserId,
      updatedOn: new Date(),
      kpiautotarget: this.kpiautotarget,
    };
    const key = {
      KPICode: monthly.kpiCode,
      Date: monthly.date
    };
    this.kpiservice.CheckOlderVersionKPI(key).subscribe(
      (res: any) => {
        if (res == false) {
          this.kpitarget.addTarget(monthly).subscribe(
            (response: any) => {
              // this.myform.reset();
              this.toaster.success('Success', 'Data Updated Successfuly');
              this.getTreeData();
            },
            (error: any) => {
              this.toaster.error('Error', error.message);
            }
          );
        }
        else {
          Swal.fire({
            title: 'Are You Sure?',
            text: 'This KPI Contains Older Version.Do you want to update?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          }).then((result) => {
            if (result.value) {
              this.kpitarget.addTarget(monthly).subscribe(
                (response: any) => {
                  // this.myform.reset();
                  this.toaster.success('Success', 'Data Updated Successfuly');
                  this.getTreeData();
                },
                (error: any) => {
                  this.toaster.error('Error', error.message);
                }
              );
            }
            else {
              const getTarget = {
                visionId: this.selectedNode.visionCode,
                kpiId: this.selectedNode.kpiCode,
                frequency: this.selectedNode.frequency,
              };
              this.kpitarget.getTarget(getTarget).subscribe(
                (data: any) => {
                  this.dataArray = data;
                },
                (error: any) => {
                  this.toaster.error('Error', error.message);
                }
              );
            }
          });


        }
      }
    );
  }
  addTargetEntry(): void {
    let value = '';
    this.IsTargetBtnDisabled = true;
    if (
      this.targetDate === undefined ||
      this.targetDate === null ||
      this.targetAmount === undefined ||
      this.targetAmount === null ||
      this.targetAmount === ''
    ) {
      this.toaster.warning('Validate', 'Please add date or value first');
      this.IsTargetBtnDisabled = false;

    } else {
      const monthlydate = this.targetDate;
      const dateSave = this.targetAmount;

      if (this.selectedNode.kpiDataType === '2')
        value = moment(dateSave).format('DD/MM/YYYY');
      else
        value = this.targetAmount;

      const shift = this.shift;
      let shiftValue = 0;
      if (shift !== undefined) {
        shiftValue = parseInt(shift.shiftPattern, 10);
      }
      const today = new Date(monthlydate);
      const quarter = Math.floor((today.getMonth() + 3) / 3);
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1;
      const currentDay = today.getDate();
      const currentWeek = this.getWeekNumber(today);
      const monthly = {
        kpiId: this.selectedNode.kpiId,
        frequecnyId: this.selectedNode.frequencyId,
        uomId: this.selectedNode.uomId,
        plantId: this.selectedNode.plantId,
        visionId: this.selectedNode.visionId,
        kpiCode: this.selectedNode.kpiCode,
        date: getSqlDate(monthlydate),
        targetValue: value,
        createdBy: JSON.parse(localStorage.user).UserId,
        createdOn: new Date(),
        YearFrom: currentYear,
        currentquarter: quarter,
        currentmonth: currentMonth,
        currentweek: currentWeek,
        currentday: currentDay,
        currentshift: shiftValue,
        kpiautotarget: this.kpiautotarget,
      };
      if (this.selectedNode.frequency === 'Quarterly') {
        const actualEntries = this.dataArray.filter(
          (x: { Date: string | number | Date }) =>
            Math.floor((new Date(x.Date).getMonth() + 3) / 3) === quarter &&
            new Date(x.Date).getFullYear() === currentYear
        );
        if (actualEntries.length > 0) {
          this.toaster.error(
            'Alert!!',
            'Target Entry Of This Quarter Is Already Added'
          );
          this.IsTargetBtnDisabled = false;

          return;
        } else {
          monthly.currentmonth = 0;
          monthly.currentweek = 0;
          monthly.currentday = 0;
          monthly.currentshift = 0;
        }
      } else if (this.selectedNode.frequency === 'Monthly') {
        const actualEntries = this.dataArray.filter(
          (x: { Date: string | number | Date }) =>
            new Date(x.Date).getMonth() + 1 === currentMonth &&
            new Date(x.Date).getFullYear() === currentYear
        );
        if (actualEntries.length > 0) {
          this.toaster.error(
            'Alert!!',
            'Target Entry Of This Month Already Added'
          );
          this.IsTargetBtnDisabled = false;

          return;
        } else {
          monthly.currentday = 0;
          monthly.currentshift = 0;
        }
      } else if (this.selectedNode.frequency === 'Weekly') {
        const actualEntries = this.dataArray.filter(
          (x: {
            TargetDate: string | number | Date;
            TargetWeek: string;
            TargetYear: string;
          }) =>
            parseInt(x.TargetWeek.substring(1), 10) === currentWeek &&
            parseInt(x.TargetYear, 10) === currentYear
        );
        if (actualEntries.length > 0) {
          this.toaster.error(
            'Alert!!',
            'Target Entry Of This Week Is Already Added'
          );
          this.IsTargetBtnDisabled = false;

          return;
        } else {
          monthly.currentday = 0;
          monthly.currentshift = 0;
        }
      } else if (this.selectedNode.frequency === 'BiMonthly') {
        const actualEntries = this.dataArray.filter(
          (x: { Date: string | number | Date }) =>
            new Date(x.Date).getMonth() + 1 === currentMonth &&
            new Date(x.Date).getFullYear() === currentYear
        );
        if (actualEntries.length > 1) {
          this.toaster.error(
            'Alert!!',
            'Target Entry Of This BiMonth Is Already Added'
          );
          this.IsTargetBtnDisabled = false;

          return;
        } else {
          monthly.currentday = 0;
          monthly.currentshift = 0;
        }
      } else if (this.selectedNode.frequency === 'Daily') {
        const actualEntries = this.dataArray.filter(
          (x: {
            Date: string | number | Date;
            TargetWeek: string;
            TargetYear: string;
          }) =>
            new Date(x.Date).getDate() === currentDay &&
            parseInt(x.TargetWeek.substring(1), 10) === currentWeek &&
            new Date(x.Date).getMonth() + 1 === currentMonth &&
            parseInt(x.TargetYear, 10) === currentYear
        );
        if (actualEntries.length > 0) {
          this.toaster.error(
            'Alert!!',
            'Target Entry Of This Day Is Already Added'
          );
          this.IsTargetBtnDisabled = false;

          return;
        } else {
          monthly.currentshift = 0;
        }
      } else if (this.selectedNode.frequency === 'Shiftly') {
        const actualEntreis = this.dataArray.filter(
          (x: { Date: string | number | Date; TargetShift: number }) =>
            new Date(x.Date).getDate() === currentDay &&
            this.getWeekNumber(new Date(x.Date)) === currentWeek &&
            x.TargetShift === shiftValue &&
            new Date(x.Date).getMonth() + 1 === currentMonth &&
            new Date(x.Date).getFullYear() === currentYear
        );
        if (this.shift === undefined || this.shift === null) {
          this.toaster.warning('Warning!!', 'Please select shift value first');
          this.IsTargetBtnDisabled = false;

          return;
        }
        if (actualEntreis.length > 0) {
          this.toaster.error(
            'Alert!!',
            'Actual Entry Of This Shift Already Added'
          );
          this.IsTargetBtnDisabled = false;

          return;
        }
      }
      const key = {
        KPICode: monthly.kpiCode,
        Date: monthly.date
      };
      this.kpiservice.CheckOlderVersionKPI(key).subscribe(
        (res: any) => {
          if (res == false) {
            this.kpitarget.addTarget(monthly).subscribe(
              (response: any) => {
                // this.myform.reset();
                this.toaster.success('Success', 'Data Updated Successfuly');
                this.getTreeData();
                this.IsTargetBtnDisabled = false;
                this.targetAmount = null;

                const getTarget = {
                  visionId: this.selectedNode.visionCode,
                  kpiId: this.selectedNode.kpiCode,
                  frequency: this.selectedNode.frequency,
                };
                this.kpitarget.getTarget(getTarget).subscribe(
                  (data: any) => {
                    this.dataArray = data;
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                    this.IsTargetBtnDisabled = false;

                  }
                );
              },
              (error: any) => {
                this.toaster.error('Error', error.message);
                this.IsTargetBtnDisabled = false;

              }
            );



          }
          else {
            Swal.fire({
              title: 'Are You Sure?',
              text: 'This KPI Contains Older Version.Do you want to update?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
            }).then((result) => {
              if (result.value) {
                this.kpitarget.addTarget(monthly).subscribe(
                  (response: any) => {
                    // this.myform.reset();
                    this.toaster.success('Success', 'Data Updated Successfuly');
                    this.getTreeData();
                    this.IsTargetBtnDisabled = false;
                    this.targetAmount = null;
                    const getTarget = {
                      visionId: this.selectedNode.visionCode,
                      kpiId: this.selectedNode.kpiCode,
                      frequency: this.selectedNode.frequency,
                    };
                    this.kpitarget.getTarget(getTarget).subscribe(
                      (data: any) => {
                        this.dataArray = data;
                      },
                      (error: any) => {
                        this.toaster.error('Error', error.message);
                        this.IsTargetBtnDisabled = false;

                      }
                    );
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                    this.IsTargetBtnDisabled = false;

                  }
                );

              }
              else {
                const getTarget = {
                  visionId: this.selectedNode.visionCode,
                  kpiId: this.selectedNode.kpiCode,
                  frequency: this.selectedNode.frequency,
                };
                this.kpitarget.getTarget(getTarget).subscribe(
                  (data: any) => {
                    this.dataArray = data;

                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);

                  }
                );
              }
            });


          }
        }
      );
      ///////////
    }
  }
  getWeekNumber(d: any): any {
    const date = new Date(d.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    const weekNumber =
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
        7
      );
    return weekNumber;
  }
  addZeroTargets(): void {
    if (this.targetDate === undefined) {
      this.toaster.warning('Please Select Target Date First');
    } else {
      const data = {
        date: getSqlDate(this.targetDate),
        kpiId: this.selectedNode.kpiId,
        frequencyId: this.selectedNode.frequencyId,
        uomId: this.selectedNode.uomId,
        plantId: this.selectedNode.plantId,
        visionId: this.selectedNode.visionId,
        kpiCode: this.selectedNode.kpiCode,
        userId: JSON.parse(localStorage.user).UserId,
        frequencyName: this.selectedNode.frequency,
      };

      this.kpitarget.getZeroValueTarget(data).subscribe(
        () => {
          this.toaster.success('Data Saved Successfully');
          this.getTreeData();
          this.targetDate = new Date();
          // this.targetAmount = 0;
          this.targetAmount = '';
          this.kpiTitle = this.selectedNode.label;
          this.kpifrequency = this.selectedNode.frequency;
          this.kpiUom = this.selectedNode.uom;
          const getTarget = {
            visionId: this.selectedNode.visionCode,
            kpiId: this.selectedNode.kpiCode,
            frequency: this.selectedNode.frequency,
          };
          this.kpitarget.getTarget(getTarget).subscribe(
            (response: any) => {
              this.dataArray = response;
            },
            (error: any) => {
              this.toaster.error('Error', error.message);
            }
          );
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  onTargetValueChange(e: any) {
    // console.log(e);
    let value = (e.target as HTMLInputElement).value;
    // console.log(value);
    value = value.trim();
    if (value.match(/\./g)?.length > 1) {
      // console.log('match');
      this.toaster.error('Invalid Value');
      // (e.target as HTMLInputElement).value = value.match('/^d*.?d+$/gm') as any;
    }
  }

  gridDate(event: any): void {

    const itemArr: any[] = [];
    this.gridApi.forEachLeafNode((node: any) => {
      itemArr.push(node);
    });
    const filterIndex: any = itemArr.filter(
      (c) => c.rowIndex === this.gridRowIndex
    );
    const rowNode = this.gridApi.getRowNode(filterIndex[0].id);
    rowNode.setDataValue(this.gridColId, moment(event.value).format('DD/MM/YYYY'));
  }
  cellClicked(params: any) {
    const col = params.colDef.field;
    this.ButtonType = '';
    if (col === 'KPITargetId') {
      this.ButtonType = params.event.target.classList[1];
      if (this.ButtonType == 'fa-trash') {
        const key = {
          KPITargetId: params.data.KPITargetId
        }
        this.kpitarget.DeleteTarget(key).subscribe(
          (res) => {
            this.toaster.success("Target Deleted Successfully");
            this.getTarget();

          },
          (error: any) => {
            this.toaster.error('error', error.message);
          })
      }
      else
        if (this.ButtonType == 'fa-align-justify') {
          const param = {

            rowData: params.node.data
            // ...something
          };
          this.deletekpiTarget(param)
        }
    }
  }

  makeCellClicked(params: any) {


    // if (params.data.AllowActualEntry) {
    if (this.selectedNode?.kpiDataType === '2') {
      this.reOpenCalendar();
    }
    else if (this.selectedNode?.kpiDataType === '3') {
      this.reOpenTimePicker();
    }
    this.gridColId = params.column.colId;
    this.gridRowIndex = params.rowIndex;
    this.rowParams = params;
    // }

    // this.method();
  }

  reOpenCalendar(): any {
    const self = this;
    setTimeout(() => {
      self.picker.open();
    }, 50);
  }
  isSplitView() {
    const url = this.router.url;
    if (url.includes('KPISplitView')) {
      return true;
    }
    return false;
  }
  onChangeHour(event: any): void {
    const itemArr: any[] = [];
    this.gridApi.forEachLeafNode((node: any) => {
      itemArr.push(node);
    });
    const filterIndex: any = itemArr.filter(
      (c) => c.rowIndex === this.gridRowIndex
    );
    const rowNode = this.gridApi.getRowNode(filterIndex[0].id);
    rowNode.setDataValue(this.gridColId, (event));
  }

  disabledDate(current: Date): boolean {
    // Can not select days before today and today
    if (differenceInCalendarDays(current, new Date()) > 0) {
      return true;
    }

  }

  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  // disabledDateTime: DisabledTimeFn = () => ({
  //   nzDisabledHours: () => this.range(0, 24).splice(4, 20),
  //   nzDisabledMinutes: () => this.range(30, 60),
  //   nzDisabledSeconds: () => [55, 56]
  // });
  reOpenTimePicker(): any {
    const self = this;
    setTimeout(() => {
      self.pickerE.open();
      // self.pickerE.data('clockpicker').show();
    }, 50);
  }

  onCheckHistory(param: any) {

    this.GetKPIHistoryColumnsAccess();

    const key = {
      KPITreeId: param
    }
    this.kpiservice.CheckKPIHistory(key).subscribe(
      (res: any) => {

        this.historygridRowData = res;
        this.dialog.open(this.kpiHistoryModal, {
          // id: 'message-pop-up',
          // panelClass: 'custom-dialog-container',
          width: '100%',
          height: 'auto',

        });
        this.enableOverlay();
      }
    );


  }


  GetKPIHistoryColumnsAccess(): void {
    this.KpiHistoryColumnsDefs = [

      {
        field: 'label',
        headerName: 'Name',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      }, {
        field: 'kpicode',
        headerName: 'KPI Code',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'type',
        headerName: 'Type',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      }, {
        field: 'parent',
        headerName: 'Parent',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'frequencyName',
        headerName: 'Frequency',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,

        cellStyle: (params: any) => {
          return {
            'background-color': params.data.frequencyColor,
          }
        },
        width: 250
      },
      {
        field: 'driver',
        headerName: 'Driver',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },


      {
        field: 'uomName',
        headerName: 'UOM',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'Pillars',
        headerName: 'Pillar',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'Department',
        headerName: 'Department',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      }
      ,
      {
        field: 'StrategicKpi',
        headerName: 'Strategic KPI',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'Line',
        headerName: 'Line',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'Machine',
        headerName: 'Machine',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'SKPIId',
        headerName: 'Strategic KPI Id',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        hide: true,
        width: 250
      }, {
        field: 'Trend',
        headerName: 'Trend',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: "KpiFormulaType",
        headerName: "KPI Formula Type",
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: "formula",
        headerName: "Formula",
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'ParentId',
        headerName: 'Parent Id',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        hide: true,
        width: 250

      }, {
        field: 'plantId',
        headerName: 'Plant Id',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        hide: true,
        width: 250

      }, {
        field: 'kpiid',
        headerName: 'KPI Id',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        hide: true,
        width: 250

      }, {
        field: 'visionId',
        headerName: 'Vision Id',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        hide: true,
        width: 250

      },
      {
        field: 'ResponsibleRoles',
        headerName: 'Responsible Roles',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'Responsible',
        headerName: 'Responsible Users',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'AccountableRole',
        headerName: 'Accountable Roles',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'Accountable',
        headerName: 'Accountable Users',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        width: 250
      },
      {
        field: 'StartDate',
        headerName: 'Date',
        sortable: true,
        editable: false,
        wrapText: true,
        filter: true,
        resizable: true,
        valueGetter: function (params) {
          return moment(new Date(params.data.StartDate)).format('DD/MM/YYYY HH:mm:ss') + ' - ' + (params.data.EndDate == null ? "Continued" : moment(new Date(params.data.EndDate)).format('DD/MM/YYYY HH:mm:ss'));
        },
        // cellRenderer: (data: any) => {
        //   return data.value
        //     ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
        //     : '';
        // },
        width: 350
      },
      // {
      //   field: 'EndDate',
      //   headerName: 'End Date',
      //   sortable: true,
      //   editable: false,
      //   wrapText: true,
      //   filter: true,
      //   resizable: true,
      //   cellRenderer: (data: any) => {
      //     return data.value
      //       ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
      //       : '';
      //   },
      //   width: 250
      // }
    ];

  }

  KPIHistoryOnGridReady(params: any): void {
    this.KPIHistoryGrid = params.api;
    // this.KPIHistoryGrid.sizeColumnsToFit();
    this.KPIHistoryGrid.setDomLayout('autoHeight');
    const e: HTMLElement = document.querySelector(
      '#KPIHistoryGrid'
    ) as HTMLElement;
    if (e !== null) {
      e.style.height = '';
    }
  }

  GetKPIVersion(): any {
    const key = {

      Plant: this.searchPlantForm.value.plant.DeptId

    }

    this.kpiservice.GetKPIVersion(key).subscribe(
      (data: any) => {

        let arr = [];
        data.forEach(element => {
          if (element.IsLocked == true) {
            const obj = {
              Id: element.Id,
              name: "Version " + element.VersionNo + "(" + moment(new Date(element.ApplicableFromDate)).format("DD/MM/YYYY") + "-" + moment(new Date(element.ApplicableToDate)).format("DD/MM/YYYY") + ")",
              IsLocked: element.IsLocked
            }
            arr.push(obj);
          }
          else {
            const obj = {
              Id: element.Id,
              name: "Version " + element.VersionNo + "(" + moment(new Date(element.ApplicableFromDate)).format("DD/MM/YYYY") + ")" + "(Drafted)",
              IsLocked: element.IsLocked
            }
            arr.push(obj);
          }

        });


        let firstElement = arr.find(element => element.IsLocked);
        let index = arr.findIndex(element => element.IsLocked);
        if (arr.length > 0) {
          if (index != -1) {
            arr[index].name = "Version " + data[index].VersionNo + "(" + moment(new Date(data[index].ApplicableFromDate)).format("DD/MM/YYYY") + "-" + (data[index].ApplicableToDate ? moment(new Date(data[index].ApplicableToDate)).format("DD/MM/YYYY") : "Continued") + ") (LIVE)";
          }
        }
        this.VersionList = arr;

        this.searchPlantForm.get("VersionList").setValue(firstElement ? firstElement : arr[0]);
        this.getSearchList();
        // console.log(this.searchPlantForm.value.VersionList.Id,"this is the version");
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }

    )

  }


  GetNodeMenus(): any {
    this.visionMenuItems = [
      {
        label: 'Add Strategic KPI',
        icon: 'pi pi-fw pi-user-plus',
        disabled: this.IsLocked,
        command: (event) => {
          if (this.IsWrite) {
            this.strategicFormMode = 'add';
            this.closeContextMenu();
            this.strategicForm.reset();
            this.displayStartgicKpi = true;
            const plantObject = this.plants.find(
              (p) => p.DeptId === this.plantId
            );
            this.strategicForm.get('plant')?.setValue(plantObject);
            const visionObject = this.visions.find(
              (v) => v.VisionId === Number(this.selectedNode.visionId)
            );
            this.strategicForm.get('vision')?.setValue(visionObject);
            this.visionMenu = false;
          } else {
            this.closeContextMenu();
            this.toaster.error(
              'Alert!',
              'you do not have permission to access'
            );
          }
        },
      },
      {
        label: 'Edit Vision',
        icon: 'pi pi-fw pi-user-edit',
        disabled: false,
        command: (event) => {
          if (this.IsWrite) {
            this.closeContextMenu();
            this.visionMenu = false;
            this.visionForm.reset();
            const key = {
              visionId: this.selectedNode.visionId,

            };
            this.kpiservice.getOneVision(key).subscribe(
              (response) => {
                this.visionFormMode = 'edit';
                this.visionKpi = response;
                this.visionId = this.visionKpi.visionId;
                const selectedYearFrom = this.visionKpi.yearFrom;
                const yearpickFrom = new Date(
                  '01/' + '/01/' + selectedYearFrom
                );
                const selectedYearTo = this.visionKpi.yearTo;
                const yearpickTo = new Date('12/' + '/31/' + selectedYearTo);
                const plantObject = this.plants.find(
                  (p) => p.DeptId === this.visionKpi.plantId
                );
                this.visionForm.get('name')?.setValue(this.visionKpi.name);
                this.visionForm
                  .get('quantify')
                  ?.setValue(this.visionKpi.quantify);
                this.visionForm
                  .get('remarks')
                  ?.setValue(this.visionKpi.remarks);
                this.visionForm.get('yearFrom')?.setValue(yearpickFrom);
                this.visionForm.get('yearTo')?.setValue(yearpickTo);
                this.visionForm.get('plant')?.setValue(plantObject);
              },
              (error) => {
                this.toaster.error('Error', error.message);
              }
            );
            this.displayVision = true;
            this.header = 'Edit Vision';
            // this.visionForm.get('plant')?.setValue(plantObject);
          } else {
            this.closeContextMenu();
            this.toaster.error(
              'Alert!',
              'you do not have permission to access'
            );
          }
        },
      },
      {
        label: 'Update Vision Version',
        icon: 'pi pi-fw pi-user-edit',
        disabled: this.IsLocked,
        command: (event: any) => {
          if (this.IsWrite) {
            this.closeContextMenu();
            this.visionMenu = false;
            this.visionForm.reset();
            const key = {
              visionId: this.selectedNode.visionId,

            };
            this.kpiservice.getOneVision(key).subscribe(
              (response) => {
                this.visionFormMode = 'NewVersion';
                this.visionKpi = response;
                this.visionId = this.visionKpi.visionId;
                const selectedYearFrom = this.visionKpi.yearFrom;
                const yearpickFrom = new Date(
                  '01/' + '/01/' + selectedYearFrom
                );
                const selectedYearTo = this.visionKpi.yearTo;
                const yearpickTo = new Date('12/' + '/31/' + selectedYearTo);
                const plantObject = this.plants.find(
                  (p) => p.DeptId === this.visionKpi.plantId
                );
                this.visionForm.get('name')?.setValue(this.visionKpi.name);
                this.visionForm
                  .get('quantify')
                  ?.setValue(this.visionKpi.quantify);
                this.visionForm
                  .get('remarks')
                  ?.setValue(this.visionKpi.remarks);
                this.visionForm.get('yearFrom')?.setValue(yearpickFrom);
                this.visionForm.get('yearTo')?.setValue(yearpickTo);
                this.visionForm.get('plant')?.setValue(plantObject);
              },
              (error) => {
                this.toaster.error('Error', error.message);
              }
            );
            this.displayVision = true;
            this.header = 'Edit Vision';
            // this.visionForm.get('plant')?.setValue(plantObject);
          } else {
            this.closeContextMenu();
            this.toaster.error(
              'Alert!',
              'you do not have permission to access'
            );
          }
        },

      },
      {
        label: 'Check Vision History',
        icon: 'pi pi-fw pi-ticket',
        command: (event) => {
          if (this.IsAccess) {
            this.closeContextMenu();

            const key = {
              visionId: this.selectedNode.visionId,
            };
            this.onCheckHistory(this.selectedNode.id);

          }
        }
      },
      {
        label: 'Remove Permanently Vision',
        icon: 'pi pi-fw pi-trash',
        disabled: this.IsLocked,
        command: (event) => {
          if (this.IsWrite && this.selectedNode?.visionIsDrafted === '1') {
            this.closeContextMenu();
            Swal.fire({
              title: 'Are you sure?',
              text: 'You want to delete Vision!',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!',
              cancelButtonText: 'No, keep it',
            }).then((result) => {
              if (result.value) {
                const key = {
                  visionId: this.selectedNode.visionId,
                };
                this.kpiservice.deleteVision(key).subscribe(
                  (response: any) => {
                    Swal.fire(
                      'Deleted!',
                      'Your selected Vision has been deleted.',
                      'success'
                    );
                    this.removeId(this.data1, this.selectedNode.id);
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                  }
                );
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelled', 'Your Vision is safe :)', 'error');
              }
            });
          } else {
            this.closeContextMenu();
            this.toaster.warning(
              'Alert!',
              'you do not have permission to access or only draft kpi can be deleted'
            );
          }
        },
      },
      {
        label: 'Close Vision',
        icon: 'pi pi-fw pi-trash',
        disabled: this.IsLocked,
        command: (event) => {
          if (this.IsWrite) {
            this.closeContextMenu();
            Swal.fire({
              title: 'Are you sure?',
              text: 'You want to close Vision Version!',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!',
              cancelButtonText: 'No, keep it',
            }).then((result) => {
              if (result.value) {
                const key = {
                  visionId: this.selectedNode.visionId,
                  versionId: this.searchPlantForm.value.VersionList.Id
                };
                this.kpiservice.DeleteVisionVersion(key).subscribe(
                  (response: any) => {
                    Swal.fire(
                      'Deleted!',
                      'Your selected Vision has been deleted.',
                      'success'
                    );
                    this.removeId(this.data1, this.selectedNode.id);
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                  }
                );
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelled', 'Your Vision is safe :)', 'error');
              }
            });
          } else {
            this.closeContextMenu();
            this.toaster.error(
              'Alert!',
              'you do not have permission to access'
            );
          }
        },
      },
    ];
    this.stratgicKpiItems = [
      {
        label: 'Add KPI',
        icon: 'pi pi-fw pi-user-plus',
        disabled: this.IsLocked,
        command: (event) => {
          if (this.IsWrite) {

            this.getResponsibleRoles();
            this.resetForm();
            this.closeContextMenu();
            // this.getAllMachines();
            // this.getUserList();
            const panel = document.querySelector('.model-content');
            if (panel) {
              panel.scrollTop = 0;
            }
            this.visibleSidebar2 = true;
            this.kpiFormMode = 'add';
            const key = {
              plantId: this.selectedNode.plantId,
              visionId: this.selectedNode.visionId,
            };
            this.freqNode = undefined;
            this.isShown = false;
            this.showFormula = true;
            this.showDynamicFormula = true;
            this.getFrequencies(this.freqType, this.freqNode);
            this.getStragicKpi(key);
          } else {
            this.closeContextMenu();
            this.toaster.error(
              'Alert!',
              'you do not have permission to access'
            );
          }
        },
      },
      {
        label: 'Edit Strategic KPI',
        icon: 'pi pi-fw pi-user-edit',
        disabled: false,
        command: (event) => {
          if (this.IsWrite) {
            const key = {
              strategicKpiId: this.selectedNode.strategicKpiId,
            };
            this.strategicForm.reset();
            this.closeContextMenu();
            this.kpiservice.getOneStrategicKpi(key).subscribe(
              (response) => {
                this.strategicFormMode = 'edit';
                this.strategicKpi = response;
                const visionObject = this.visions.find(
                  (v) => v.VisionId === this.strategicKpi.visionId
                );
                const selectedYear = this.strategicKpi.year;
                const yearpick = new Date('01/' + '/01/' + selectedYear);
                this.strategicKpiId = this.strategicKpi.skpiId;
                this.strategicForm
                  .get('name')
                  ?.setValue(this.strategicKpi.name);
                this.strategicForm
                  .get('quantify')
                  ?.setValue(this.strategicKpi.quantify);
                this.strategicForm.get('year')?.setValue(yearpick);
                this.strategicForm
                  .get('remarks')
                  ?.setValue(this.strategicKpi.remarks);
                this.strategicForm.get('vision')?.setValue(visionObject);
                const plantObject = this.plants.find(
                  (p) => p.DeptId === this.strategicKpi.plantId
                );
                this.strategicForm.get('plant')?.setValue(plantObject);
                this.displayStartgicKpi = true;
              },
              (error) => {
                this.toaster.error('Error', error.message);
              }
            );
          } else {
            this.closeContextMenu();
            this.toaster.error(
              'Alert!',
              'you do not have permission to access'
            );
          }
        },
      },
      {
        label: 'Update Strategic KPI Version',
        icon: 'pi pi-fw pi-user-edit',
        disabled: this.IsLocked,
        command: (event: any) => {
          if (this.IsWrite) {
            const key = {
              strategicKpiId: this.selectedNode.strategicKpiId,
            };
            this.strategicForm.reset();
            this.closeContextMenu();
            this.kpiservice.getOneStrategicKpi(key).subscribe(
              (response) => {
                this.strategicFormMode = 'NewVersion';
                this.strategicKpi = response;
                const visionObject = this.visions.find(
                  (v) => v.VisionId === this.strategicKpi.visionId
                );
                const selectedYear = this.strategicKpi.year;
                const yearpick = new Date('01/' + '/01/' + selectedYear);
                this.strategicKpiId = this.strategicKpi.skpiId;
                this.strategicForm
                  .get('name')
                  ?.setValue(this.strategicKpi.name);
                this.strategicForm
                  .get('quantify')
                  ?.setValue(this.strategicKpi.quantify);
                this.strategicForm.get('year')?.setValue(yearpick);
                this.strategicForm
                  .get('remarks')
                  ?.setValue(this.strategicKpi.remarks);
                this.strategicForm.get('vision')?.setValue(visionObject);
                const plantObject = this.plants.find(
                  (p) => p.DeptId === this.strategicKpi.plantId
                );
                this.strategicForm.get('plant')?.setValue(plantObject);
                this.displayStartgicKpi = true;
              },
              (error) => {
                this.toaster.error('Error', error.message);
              }
            );
          } else {
            this.closeContextMenu();
            this.toaster.error(
              'Alert!',
              'you do not have permission to access'
            );
          }
        },
      },
      {
        label: 'Check Strategic Kpi History',
        icon: 'pi pi-fw pi-ticket',
        command: (event) => {
          if (this.IsAccess) {
            this.closeContextMenu();


            this.onCheckHistory(this.selectedNode.id);

          }
        }
      },
      {
        label: 'Remove Permanently Strategic KPI',
        icon: 'pi pi-fw pi-trash',
        disabled: this.IsLocked,
        command: (event) => {
          if (this.IsWrite && this.selectedNode?.strategicIsDrafted === '1') {
            this.closeContextMenu();
            Swal.fire({
              title: 'Are you sure?',
              text: 'You want to delete Strategic Kpi node!',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!',
              cancelButtonText: 'No, keep it',
            }).then((result: any) => {
              if (result.value) {
                const key = {
                  strategicKpiId: this.selectedNode.strategicKpiId,
                };
                this.kpiservice.deleteStrategicKpi(key).subscribe(
                  (response: any) => {
                    Swal.fire(
                      'Deleted!',
                      'Your selected Strategic Kpi has been deleted.',
                      'success'
                    );
                    this.removeId(this.data1, this.selectedNode.id);
                    setTimeout(() => {
                      // const element: HTMLElement = document.getElementsByClassName(
                      //   'p-corner-top'
                      // )[0] as HTMLElement;
                      // element.click();
                      const nodeElement: HTMLElement = document.querySelector(
                        `[data-nodeid='${this.selectedNode.pid}']`
                      );
                      if (nodeElement) {
                        nodeElement.click();
                        nodeElement.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                          inline: 'center',
                        });
                        // nodeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                      }
                    }, 1000);
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                  }
                );
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                  'Cancelled',
                  'Your Strategic Kpi node is safe :)',
                  'error'
                );
              }
            });
          } else {
            this.closeContextMenu();
            this.toaster.warning(
              'Alert!',
              'you do not have permission to access or only draft kpi can be deleted'
            );
          }
        },
      },
      {
        label: 'Close Strategic KPI',
        icon: 'pi pi-fw pi-trash',
        disabled: this.IsLocked,
        command: (event) => {
          if (this.IsWrite) {
            this.closeContextMenu();
            Swal.fire({
              title: 'Are you sure?',
              text: 'You want to close Strategic Kpi Version!',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!',
              cancelButtonText: 'No, keep it',
            }).then((result: any) => {
              if (result.value) {
                const key = {
                  strategicKpiId: this.selectedNode.strategicKpiId,
                  versionId: this.searchPlantForm.value.VersionList.Id
                };
                this.kpiservice.DeleteStrategicKpiVersion(key).subscribe(
                  (response: any) => {
                    Swal.fire(
                      'Deleted!',
                      'Your selected Strategic Kpi has been deleted.',
                      'success'
                    );
                    this.removeId(this.data1, this.selectedNode.id);
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                  }
                );
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                  'Cancelled',
                  'Your Strategic Kpi node is safe :)',
                  'error'
                );
              }
            });
          } else {
            this.closeContextMenu();
            this.toaster.error(
              'Alert!',
              'you do not have permission to access'
            );
          }
        },
      },
    ];
    this.kpiItems = [
      {
        label: 'KPI',
        icon: 'pi pi-sitemap',
        items: [
          {
            label: 'Add KPI',
            icon: 'pi pi-fw pi-user-plus',
            disabled: this.IsLocked,
            command: (event: any) => {
              if (this.IsWrite) {
                // this.addChildNode(this.selectedNode);
                this.getResponsibleRoles();
                this.closeContextMenu();
                // this.getUserList();
                this.resetForm();
                //this.getAllMachines();
                this.kpiFormMode = 'add';
                const panel = document.querySelector('.model-content');
                if (panel) {
                  panel.scrollTop = 0;
                }
                this.visibleSidebar2 = true;
                this.isShown = false;
                this.showFormula = true;
                this.showDynamicFormula = true;
                const key = {
                  plantId: this.selectedNode.plantId,
                  visionId: this.selectedNode.visionId,
                };
                this.freqNode = this.selectedNode.frequency;
                this.freqType = true;
                this.getFrequencies(this.freqType, this.freqNode);
                this.getStragicKpi(key);
              } else {
                this.closeContextMenu();
                this.toaster.error(
                  'Alert!',
                  'you do not have permission to access'
                );
              }
            },
          },
          {
            label: 'Edit KPI',
            icon: 'pi pi-fw pi-user-edit',
            disabled: false,
            command: (event: any) => {
              if (this.IsWrite) {
                this.getResponsibleRoles();
                this.closeContextMenu();
                const keyskpid = {
                  plantId: this.selectedNode.plantId,
                  visionId: this.selectedNode.visionId,
                };
                this.freqNode = this.selectedNode.frequency;
                this.freqType = false;
                this.getFrequencies(this.freqType, this.freqNode);
                this.getStragicKpi(keyskpid);
                this.resetForm();
                this.getCalculationType();
                // this.getAllMachines();
                // this.getUserList();
                const current = new Date();
                const key = {

                  // kpiId: this.selectedNode.id,
                  kpiId: Number(this.selectedNode.kpiId),
                  timestamp: current.getTime(),
                };
                this.kpiservice.getKpiByID(key).subscribe(
                  (res) => {
                    this.editRecordList = res;
                    this.myform
                      .get('kpiline')
                      ?.setValue('', { emitEvent: false });
                    // this.getUserList();
                    this.kpiFormMode = 'edit';
                    this.SKPIId = res.SKPIId;
                    const panel = document.querySelector('.model-content');
                    if (panel) {
                      panel.scrollTop = 0;
                    }
                    this.visibleSidebar2 = true;
                    if (res.kpiDynamicFormula) {
                      this.targetArray = [];
                      this.FetchArray = JSON.parse(res.kpiDynamicFormula);
                      this.targetArray = JSON.parse(res.kpiDynamicFormula);
                      this.AddDynamicFormulaValue();
                    }
                    // this.showDynamicFormula=res.allowTarget;
                    // this.showDynamicFormulaOperand= res.allowTarget;
                    this.myform.get('kpiAllowDynamicTarget')?.setValue(res.allowTarget);
                    this.showAllowTarget();
                    this.myform.get('kpiTitle')?.setValue(res.Label);
                    this.myform.get('kpiCode')?.setValue(res.KpiCode);
                    this.myform.get('kpiFormula')?.setValue(res.Formula);
                    const kpiGroupId = this.kpiGroups.find(
                      (x) => x.kpiGroupId === parseInt(res.KPIGroupId, 10)
                    );
                    this.myform.get('kpigroup')?.setValue(kpiGroupId);

                    const kpiDT_Id = this.kpiDataTypes.find((x) => x.KPIDT_Id === parseInt(res.KPIDT_Id, 10));
                    this.myform.get('kpiDataType')?.setValue(kpiDT_Id);


                    this.myform.get('kpiAllowActualEntry')?.setValue(res.AllowActualEntry);
                    this.myform.get('kpiAllowTargetEntry')?.setValue(res.AllowAutoTargetEntry);
                    if (res.AllowAutoTargetEntry === true) {
                      this.isShown = true;
                    } else {
                      this.isShown = false;
                    }

                    if (res.AllowActualEntry === false) {
                      this.showFormula = true;
                      // this.showDynamicFormula=true;
                    } else {
                      this.showFormula = false;
                      // this.showDynamicFormula=false;
                      this.formulastring = this.myform
                        .get('kpiFormula')
                        ?.setValue('');
                    }
                    const calculationObj = this.calculationType.find(
                      (x) =>
                        x.calculationTypeId ===
                        parseInt(res.CalculationTypeId, 10)
                    );
                    const calculationItselfObj = this.calculationType.find(
                      (x) => x.calculationTypeId === res.CalculationTypeItselfId
                    );
                    this.myform
                      .get('kpiCalculationType')
                      ?.setValue(calculationObj);
                    this.myform
                      .get('kpiCalculationTypeItselfId')
                      ?.setValue(calculationItselfObj);
                    const valueObj = this.frequencies.find(
                      (x) => x.frequencyId === parseInt(res.Frequency, 10)
                    );
                    this.myform.get('kpifrequency')?.setValue(valueObj);
                    const trendObj = this.trends.find(
                      (x) => x.trendId === parseInt(res.TrendId, 10)
                    );
                    this.myform.get('trend')?.setValue(trendObj);
                    const uomObj = this.uoms.find(
                      (x) => x.uomId === parseInt(res.Uom, 10)
                    );
                    this.myform.get('kpiUom')?.setValue(uomObj);
                    const valueFormulaObj = this.FormulaCalculationTypes.find(
                      (x) => x.name === res.KpiFormulaType
                    );
                    this.myform
                      .get('kpiFormulaType')
                      ?.setValue(valueFormulaObj);
                    // drivers multiselect dropdown
                    if (res.DriverId != null) {
                      const drvrs: string[] = res.DriverId.split(',');
                      const driverArray = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < drvrs.length; i++) {
                        const driverObj = this.drivers.find(
                          (x) => x.driverId === parseInt(drvrs[i], 10)
                        );
                        driverArray.push(driverObj);
                      }
                      this.myform.get('kpiDriver')?.setValue(driverArray);
                    }
                    if (res.PillarId != null) {
                      // pillar multiselect dropdown
                      const pilar: string[] = res.PillarId.split(',');
                      const pillarArray = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < pilar.length; i++) {
                        const obj = this.pillars.find(
                          (x) => x.pillarId === parseInt(pilar[i], 10)
                        );
                        pillarArray.push(obj);
                      }
                      this.myform.get('kpiPillar')?.setValue(pillarArray);
                    }
                    // department multiselect dropdown
                    if (res.DepartmentId != null) {
                      const dep: string[] = res.DepartmentId.split(',');
                      const depArray = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < dep.length; i++) {
                        const obj = this.departments.find(
                          (x: any) => x.TypeId === parseInt(dep[i], 10)
                        );
                        depArray.push(obj);
                      }
                      this.myform.get('kpidepartments')?.setValue(depArray);
                    }
                    if (res.AttachedStargicKpi != null) {
                      const dep: string[] = res.AttachedStargicKpi.split(',');
                      const depArray = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < dep.length; i++) {
                        const obj = this.stragicKpis?.find(
                          (x) => x.skpId === parseInt(dep[i], 10)
                        );
                        depArray.push(obj);
                      }
                      this.myform.get('attachedStargicKpi')?.setValue(depArray);
                    }
                    // tslint:disable-next-line:max-line-length
                    this.myform
                      .get('kpiline')
                      ?.setValue(
                        res.PlId === null
                          ? ''
                          : this.lines.find((c) => c.plId === res.PlId)
                      );

                    // ResponsibleRoleId
                    if (res.ResponsibleRoleId) {
                      // tslint:disable-next-line:max-line-length
                      const dep: string[] = res.ResponsibleRoleId.split(',');
                      const arr = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < dep.length; i++) {
                        const obj = this.responsibleroles.find(
                          (x) => x.POS_Id === parseInt(dep[i], 10)
                        );
                        arr.push(obj);
                      }
                      this.myform.get('kpiResponsibleRole')?.setValue(arr);
                      this.getResponsibleRolesUsersForEdit();
                    }
                    // tslint:disable-next-line:max-line-length
                    if (res.AccountableRoleId) {
                      // tslint:disable-next-line:max-line-length
                      // const dep: string[] = res.AccountableRoleId.split(',');
                      // const arr = [];
                      // tslint:disable-next-line:prefer-for-of
                      // for (let i = 0; i < dep.length; i++) {
                      const obj = this.responsibleroles.find(
                        (x) => x.POS_Id === parseInt(res.AccountableRoleId, 10)
                      );
                      // arr.push(obj);
                      // }
                      this.myform.get('kpiAccountableRole')?.setValue(obj);
                      this.getAccountableRolesUsersForEdit();
                    }
                    // this.myform.get('kpiAccountableRole')?.setValue(res.AccountableRoleId === null
                    //       ? ''
                    //       : this.responsibleroles.find(
                    //         (c) => c.POS_Id === res.AccountableRoleId
                    //       )
                    //   );
                    // this.getAccountableRolesUsers();
                    // tslint:disable-next-line:max-line-length
                    if (res.ConsultRoleId !== null) {
                      // tslint:disable-next-line:max-line-length
                      const dep: string[] = res.ConsultRoleId.split(',');
                      const arr = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < dep.length; i++) {
                        const obj = this.responsibleroles.find(
                          (x) => x.POS_Id === parseInt(dep[i], 10)
                        );
                        arr.push(obj);
                      }
                      this.myform.get('kpiConsultRole')?.setValue(arr);
                      // this.myform
                      //   .get('kpiConsultRole')
                      //   ?.setValue(
                      //     res.ConsultRoleId === null
                      //       ? ''
                      //       : this.responsibleroles.find(
                      //         (c) => c.POS_Id === res.ConsultRoleId
                      //       )
                      //   );
                      this.getConsultRolesUsersForEdit();
                    }
                    // consult multiselect dropdown

                    // tslint:disable-next-line:max-line-length
                    if (res.InformRoleId !== null) {
                      // tslint:disable-next-line:max-line-length
                      const dep: string[] = res.InformRoleId.split(',');
                      const arr = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < dep.length; i++) {
                        const obj = this.responsibleroles.find(
                          (x) => x.POS_Id === parseInt(dep[i], 10)
                        );
                        arr.push(obj);
                      }
                      this.myform.get('kpiInformedRole')?.setValue(arr);
                      // this.myform
                      //   .get('kpiInformedRole')
                      //   ?.setValue(
                      //     res.InformRoleId === null
                      //       ? ''
                      //       : this.responsibleroles.find(
                      //         (c) => c.POS_Id === res.InformRoleId
                      //       )
                      //   );
                      this.getInformedRolesUsersForEdit();

                    }
                    // informed multiselect dropdown
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                  }
                );
              } else {
                this.closeContextMenu();
                this.toaster.error(
                  'Alert!',
                  'you do not have permission to access'
                );
              }
            },
          },
          /////////////////////////////
          {
            label: 'Update KPI Version',
            icon: 'pi pi-fw pi-user-edit',
            disabled: this.IsLocked,
            command: (event: any) => {
              if (this.IsWrite) {

                this.getResponsibleRoles();
                this.closeContextMenu();
                const keyskpid = {
                  plantId: this.selectedNode.plantId,
                  visionId: this.selectedNode.visionId,
                };
                this.freqNode = this.selectedNode.frequency;
                this.freqType = false;
                this.getFrequencies(this.freqType, this.freqNode);
                this.getStragicKpi(keyskpid);
                this.resetForm();
                this.getCalculationType();
                // this.getAllMachines();
                // this.getUserList();
                const current = new Date();
                const key = {
                  // kpiId: this.selectedNode.id,
                  kpiId: Number(this.selectedNode.kpiId),
                  timestamp: current.getTime(),
                };
                this.kpiservice.getKpiByID(key).subscribe(
                  (res) => {
                    this.editRecordList = res;
                    this.myform
                      .get('kpiline')
                      ?.setValue('', { emitEvent: false });
                    // this.getUserList();
                    this.kpiFormMode = 'NewVersion';
                    this.SKPIId = res.SKPIId;
                    const panel = document.querySelector('.model-content');
                    if (panel) {
                      panel.scrollTop = 0;
                    }
                    this.visibleSidebar2 = true;
                    if (res.kpiDynamicFormula) {
                      this.targetArray = [];
                      this.FetchArray = JSON.parse(res.kpiDynamicFormula);
                      this.targetArray = JSON.parse(res.kpiDynamicFormula);
                      this.AddDynamicFormulaValue();
                    }
                    // this.showDynamicFormula=res.allowTarget;
                    // this.showDynamicFormulaOperand= res.allowTarget;
                    this.myform.get('kpiAllowDynamicTarget')?.setValue(res.allowTarget);
                    this.showAllowTarget();
                    this.myform.get('kpiTitle')?.setValue(res.Label);
                    this.myform.get('kpiCode')?.setValue(res.KpiCode);
                    this.myform.get('kpiFormula')?.setValue(res.Formula);
                    const kpiGroupId = this.kpiGroups.find(
                      (x) => x.kpiGroupId === parseInt(res.KPIGroupId, 10)
                    );
                    this.myform.get('kpigroup')?.setValue(kpiGroupId);

                    const kpiDT_Id = this.kpiDataTypes.find((x) => x.KPIDT_Id === parseInt(res.KPIDT_Id, 10));
                    this.myform.get('kpiDataType')?.setValue(kpiDT_Id);


                    this.myform.get('kpiAllowActualEntry')?.setValue(res.AllowActualEntry);
                    this.myform.get('kpiAllowTargetEntry')?.setValue(res.AllowAutoTargetEntry);
                    if (res.AllowAutoTargetEntry === true) {
                      this.isShown = true;
                    } else {
                      this.isShown = false;
                    }

                    if (res.AllowActualEntry === false) {
                      this.showFormula = true;
                      // this.showDynamicFormula=true;
                    } else {
                      this.showFormula = false;
                      // this.showDynamicFormula=false;
                      this.formulastring = this.myform
                        .get('kpiFormula')
                        ?.setValue('');
                    }
                    const calculationObj = this.calculationType.find(
                      (x) =>
                        x.calculationTypeId ===
                        parseInt(res.CalculationTypeId, 10)
                    );
                    const calculationItselfObj = this.calculationType.find(
                      (x) => x.calculationTypeId === res.CalculationTypeItselfId
                    );
                    this.myform
                      .get('kpiCalculationType')
                      ?.setValue(calculationObj);
                    this.myform
                      .get('kpiCalculationTypeItselfId')
                      ?.setValue(calculationItselfObj);
                    const valueObj = this.frequencies.find(
                      (x) => x.frequencyId === parseInt(res.Frequency, 10)
                    );
                    this.myform.get('kpifrequency')?.setValue(valueObj);
                    const trendObj = this.trends.find(
                      (x) => x.trendId === parseInt(res.TrendId, 10)
                    );
                    this.myform.get('trend')?.setValue(trendObj);
                    const uomObj = this.uoms.find(
                      (x) => x.uomId === parseInt(res.Uom, 10)
                    );
                    this.myform.get('kpiUom')?.setValue(uomObj);
                    const valueFormulaObj = this.FormulaCalculationTypes.find(
                      (x) => x.name === res.KpiFormulaType
                    );
                    this.myform
                      .get('kpiFormulaType')
                      ?.setValue(valueFormulaObj);
                    // drivers multiselect dropdown
                    if (res.DriverId != null) {
                      const drvrs: string[] = res.DriverId.split(',');
                      const driverArray = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < drvrs.length; i++) {
                        const driverObj = this.drivers.find(
                          (x) => x.driverId === parseInt(drvrs[i], 10)
                        );
                        driverArray.push(driverObj);
                      }
                      this.myform.get('kpiDriver')?.setValue(driverArray);
                    }
                    if (res.PillarId != null) {
                      // pillar multiselect dropdown
                      const pilar: string[] = res.PillarId.split(',');
                      const pillarArray = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < pilar.length; i++) {
                        const obj = this.pillars.find(
                          (x) => x.pillarId === parseInt(pilar[i], 10)
                        );
                        pillarArray.push(obj);
                      }
                      this.myform.get('kpiPillar')?.setValue(pillarArray);
                    }
                    // department multiselect dropdown
                    if (res.DepartmentId != null) {
                      const dep: string[] = res.DepartmentId.split(',');
                      const depArray = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < dep.length; i++) {
                        const obj = this.departments.find(
                          (x: any) => x.TypeId === parseInt(dep[i], 10)
                        );
                        depArray.push(obj);
                      }
                      this.myform.get('kpidepartments')?.setValue(depArray);
                    }
                    if (res.AttachedStargicKpi != null) {
                      const dep: string[] = res.AttachedStargicKpi.split(',');
                      const depArray = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < dep.length; i++) {
                        const obj = this.stragicKpis?.find(
                          (x) => x.skpId === parseInt(dep[i], 10)
                        );
                        depArray.push(obj);
                      }
                      this.myform.get('attachedStargicKpi')?.setValue(depArray);
                    }
                    // tslint:disable-next-line:max-line-length
                    this.myform
                      .get('kpiline')
                      ?.setValue(
                        res.PlId === null
                          ? ''
                          : this.lines.find((c) => c.plId === res.PlId)
                      );

                    // ResponsibleRoleId
                    if (res.ResponsibleRoleId) {
                      // tslint:disable-next-line:max-line-length
                      const dep: string[] = res.ResponsibleRoleId.split(',');
                      const arr = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < dep.length; i++) {
                        const obj = this.responsibleroles.find(
                          (x) => x.POS_Id === parseInt(dep[i], 10)
                        );
                        arr.push(obj);
                      }
                      this.myform.get('kpiResponsibleRole')?.setValue(arr);
                      this.getResponsibleRolesUsersForEdit();
                    }
                    // tslint:disable-next-line:max-line-length
                    if (res.AccountableRoleId) {
                      // tslint:disable-next-line:max-line-length
                      // const dep: string[] = res.AccountableRoleId.split(',');
                      // const arr = [];
                      // tslint:disable-next-line:prefer-for-of
                      // for (let i = 0; i < dep.length; i++) {
                      const obj = this.responsibleroles.find(
                        (x) => x.POS_Id === parseInt(res.AccountableRoleId, 10)
                      );
                      // arr.push(obj);
                      // }
                      this.myform.get('kpiAccountableRole')?.setValue(obj);
                      this.getAccountableRolesUsersForEdit();
                    }
                    // this.myform.get('kpiAccountableRole')?.setValue(res.AccountableRoleId === null
                    //       ? ''
                    //       : this.responsibleroles.find(
                    //         (c) => c.POS_Id === res.AccountableRoleId
                    //       )
                    //   );
                    // this.getAccountableRolesUsers();
                    // tslint:disable-next-line:max-line-length
                    if (res.ConsultRoleId !== null) {
                      // tslint:disable-next-line:max-line-length
                      const dep: string[] = res.ConsultRoleId.split(',');
                      const arr = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < dep.length; i++) {
                        const obj = this.responsibleroles.find(
                          (x) => x.POS_Id === parseInt(dep[i], 10)
                        );
                        arr.push(obj);
                      }
                      this.myform.get('kpiConsultRole')?.setValue(arr);
                      // this.myform
                      //   .get('kpiConsultRole')
                      //   ?.setValue(
                      //     res.ConsultRoleId === null
                      //       ? ''
                      //       : this.responsibleroles.find(
                      //         (c) => c.POS_Id === res.ConsultRoleId
                      //       )
                      //   );
                      this.getConsultRolesUsersForEdit();
                    }
                    // consult multiselect dropdown

                    // tslint:disable-next-line:max-line-length
                    if (res.InformRoleId !== null) {
                      // tslint:disable-next-line:max-line-length
                      const dep: string[] = res.InformRoleId.split(',');
                      const arr = [];
                      // tslint:disable-next-line:prefer-for-of
                      for (let i = 0; i < dep.length; i++) {
                        const obj = this.responsibleroles.find(
                          (x) => x.POS_Id === parseInt(dep[i], 10)
                        );
                        arr.push(obj);
                      }
                      this.myform.get('kpiInformedRole')?.setValue(arr);
                      // this.myform
                      //   .get('kpiInformedRole')
                      //   ?.setValue(
                      //     res.InformRoleId === null
                      //       ? ''
                      //       : this.responsibleroles.find(
                      //         (c) => c.POS_Id === res.InformRoleId
                      //       )
                      //   );
                      this.getInformedRolesUsersForEdit();

                    }
                    // informed multiselect dropdown
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                  }
                );
              } else {
                this.closeContextMenu();
                this.toaster.error(
                  'Alert!',
                  'you do not have permission to access'
                );
              }

            },
          },
          {
            label: 'Check Kpi History',
            icon: 'pi pi-fw pi-ticket',

            command: (event) => {
              if (this.IsAccess) {
                this.closeContextMenu();


                this.onCheckHistory(this.selectedNode.id);

              }
            }
          },
          {
            label: 'Duplicate Branch',
            icon: 'far fa-copy',
            disabled: this.IsLocked,
            command: (event: any) => {
              if (this.IsWrite) {
                this.closeContextMenu();
                const duplicate = {
                  cloneId: Number(this.selectedNode.id),
                  parentId: Number(this.selectedNode.pid),
                  createdBy: JSON.parse(localStorage.user).UserId,
                  versionId: this.searchPlantForm.value.VersionList.Id,
                  nodeType: 'multiple',
                };
                this.DuplicateNode(duplicate);
              } else {
                this.closeContextMenu();
                this.toaster.error(
                  'Alert!',
                  'you do not have permission to access'
                );
              }
            },
          },
          {
            label: 'Duplicate Child',
            icon: 'far fa-copy',
            disabled: this.IsLocked,
            command: (event: any) => {
              if (this.IsWrite) {
                this.closeContextMenu();
                const duplicate = {
                  cloneId: Number(this.selectedNode.id),
                  parentId: Number(this.selectedNode.pid),
                  createdBy: JSON.parse(localStorage.user).UserId,
                  nodeType: 'child',
                  versionId: this.searchPlantForm.value.VersionList.Id
                };
                this.DuplicateNode(duplicate);
              } else {
                this.closeContextMenu();
                this.toaster.error(
                  'Alert!',
                  'you do not have permission to access'
                );
              }
            },
          },
          {
            label: 'Duplicate Node',
            icon: 'far fa-copy',
            disabled: this.IsLocked,
            command: (event: any) => {
              if (this.IsWrite) {
                const key = {
                  cloneId: Number(this.selectedNode.id),
                  parentId: Number(this.selectedNode.pid),
                  createdBy: JSON.parse(localStorage.user).UserId,
                  nodeType: 'single',
                  versionId: this.searchPlantForm.value.VersionList.Id
                };
                this.DuplicateNode(key);
                this.getTreeData();
              } else {
                this.toaster.error(
                  'Alert!',
                  'you do not have permission to access'
                );
              }
            },
          },
          {
            label: 'Remove Permanently',
            icon: 'pi pi-fw pi-trash',
            disabled: this.IsLocked,
            command: (event: any) => {
              if (this.IsWrite && this.selectedNode?.isDrafted === '1') {
                this.closeContextMenu();
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'You want to delete Kpi!',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'No, keep it',
                }).then((result) => {
                  if (result.value) {
                    const key = {
                      kpiId: this.selectedNode.id,
                    };
                    this.kpiservice.deleteKpi(key).subscribe(
                      (response: any) => {
                        // Swal.fire(
                        //   'Deleted!',
                        //   'Your selected kpi node has been deleted.',
                        //   'success',
                        // );
                        Swal.fire({
                          // position: 'top-end',
                          icon: 'success',
                          title: 'Your selected kpi node has been deleted',
                          showConfirmButton: false,
                          timer: 1500
                        })
                        this.removeId(this.data1, this.selectedNode.id);
                        // this.getSearchList();
                        // this.getTreeData();
                        setTimeout(() => {
                          // const element: HTMLElement = document.getElementsByClassName(
                          //   'p-corner-top'
                          // )[0] as HTMLElement;
                          // element.click();
                          const nodeElement: HTMLElement = document.querySelector(
                            `[data-nodeid='${this.selectedNode.pid}']`
                          );
                          if (nodeElement) {
                            nodeElement.click();
                            nodeElement.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                              inline: 'center',
                            });
                            // nodeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                          }
                        }, 1000);
                        
                      },
                      (error: any) => {
                        this.toaster.error('Error', error.message);
                      }
                    );
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Cancelled', 'Your kpi node is safe :)', 'error');
                  }
                });
              } else {
                this.closeContextMenu();
                this.toaster.warning(
                  'Alert!',
                  'you do not have permission to access or only draft kpi can be deleted'
                );
              }
            },
          },
          {
            label: 'Close KPI',
            icon: 'pi pi-fw pi-trash',
            disabled: this.IsLocked,
            command: (event: any) => {
              if (this.IsWrite) {
                this.closeContextMenu();
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'You want to close Kpi Version!',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'No, keep it',
                }).then((result) => {
                  if (result.value) {
                    const key = {
                      kpiId: this.selectedNode.id,
                      versionId: this.searchPlantForm.value.VersionList.Id
                    };
                    this.kpiservice.deleteKpiVersion(key).subscribe(
                      (response: any) => {
                        // Swal.fire(
                        //   'Deleted!',
                        //   'Your selected kpi node has been deleted.',
                        //   'success',
                        // );
                        Swal.fire({
                          // position: 'top-end',
                          icon: 'success',
                          title: 'Your selected kpi node has been deleted',
                          showConfirmButton: false,
                          timer: 1500
                        })
                        // this.removeId(this.data1, this.selectedNode.id);
                        this.getSearchList();
                        // this.getTreeData();
                      },
                      (error: any) => {
                        this.toaster.error('Error', error.message);
                      }
                    );
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Cancelled', 'Your kpi node is safe :)', 'error');
                  }
                });
              } else {
                this.closeContextMenu();
                this.toaster.error(
                  'Alert!',
                  'you do not have permission to access'
                );
              }
            },
          },
        ],
      },
      {
        label: 'Target',
        icon: 'pi pi-fw pi-pencil',

        items: [
          {
            label: 'Add Target',
            icon: 'pi pi-fw pi-user-plus',
            disabled: !this.IsLocked,
            command: (event: any) => {
              if (this.IsWrite) {
                if (this.selectedNode.allowAutoTargetEntry === '1') {
                  this.showCheckBox = true;
                  this.kpiautotarget = false;
                } else {
                  this.showCheckBox = false;
                  this.kpiautotarget = false;
                }
                this.targetDate = new Date();
                this.dataTypeDate = new Date();
                this.LastModified = moment.utc(new Date(this.selectedNode.LastModified)).local().format('YYYY-MM-DD HH:mm:ss');
                // this.targetAmount = 0;
                this.targetAmount = '';
                this.viewKpiInformation = true;
                this.viewDetailsKpiTarget = false;
                this.kpiTitle = this.selectedNode.label;
                this.kpifrequency = this.selectedNode.frequency;
                this.kpiUom = this.selectedNode.uom;

                const getTarget = {
                  visionId: this.selectedNode.visionCode,
                  kpiId: this.selectedNode.kpiCode,
                  frequency: this.selectedNode.frequency,
                };

                this.kpitarget.getTarget(getTarget).subscribe(
                  (response: any) => {
                    this.dataArray = response;
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                  }
                );
                this.closeContextMenu();
                if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Yearly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.kpiautotarget = false;
                  this.dialog.open(this.visibleYearlyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Monthly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.opendisplayMonthlyTargetTbl();

                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Quarterly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.displayQuarterlyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                  this.gridOptions.columnApi.setColumnVisible(
                    'TargetMonth',
                    false
                  );
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Weekly'
                ) {
                  this.modalTitle = 'Weekly';
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleWeeklyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'BiMonthly'
                ) {
                  this.modalTitle = 'BiMonthly';
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.opendisplayMonthlyTargetTbl();

                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Daily'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleDailyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Shiftly'
                ) {
                  this.GetMasterShiftData();
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  const di = this.dialog.open(this.visibleShiftlyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                }
              } else {
                this.closeContextMenu();
                this.toaster.error(
                  'Alert!',
                  'you do not have permission to access'
                );
              }
            },
          },
          {
            label: 'Edit Target',
            icon: 'pi pi-fw pi-user-edit',
            disabled: !this.IsLocked,
            command: (event: any) => {
              if (this.IsWrite) {
                this.showCheckBox = false;
                this.kpiautotarget = false;
                this.targetDate = new Date();
                this.dataTypeDate = new Date();
                // this.targetAmount = 0;
                this.targetAmount = '';
                this.viewKpiInformation = false;
                this.viewDetailsKpiTarget = false;
                this.kpiTitle = this.selectedNode.label;
                this.kpifrequency = this.selectedNode.frequency;
                this.kpiUom = this.selectedNode.uom;
                const getTarget = {
                  visionId: this.selectedNode.visionCode,
                  kpiId: this.selectedNode.kpiCode,
                  frequency: this.selectedNode.frequency,
                };
                this.kpitarget.getTarget(getTarget).subscribe(
                  (response: any) => {
                    this.dataArray = response;
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                  }
                );
                this.closeContextMenu();
                if (this.selectedNode.type === 'vision') {
                  this.visibleVisionTarget = true;
                } else if (this.selectedNode.type === 'stratgickpi') {
                  this.visibleVisionTarget = true;
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Yearly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.kpiautotarget = false;
                  this.showCheckBox = false;
                  this.dialog.open(this.visibleYearlyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Monthly'
                ) {
                  this.modalTitle = 'Monthly';
                  this.opendisplayMonthlyTargetTbl();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'BiMonthly'
                ) {
                  this.modalTitle = 'BiMonthly';
                  this.opendisplayMonthlyTargetTbl();

                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Quarterly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.displayQuarterlyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Weekly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleWeeklyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Daily'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleDailyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Daily'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleDailyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Shiftly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleShiftlyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                }
              } else {
                this.closeContextMenu();
                this.toaster.error(
                  'Alert!',
                  'you do not have permission to access'
                );
              }
            },
          },
          {
            label: 'Details  Target',
            icon: 'pi pi-fw pi-ticket',
            command: (event: any) => {
              if (this.IsWrite) {
                this.targetDate = new Date();
                this.dataTypeDate = new Date();
                // this.targetAmount = 0;
                this.targetAmount = '';
                this.viewKpiInformation = false;
                this.viewDetailsKpiTarget = true;
                this.kpiTitle = this.selectedNode.label;
                this.kpifrequency = this.selectedNode.frequency;
                this.kpiUom = this.selectedNode.uom;
                const getTarget = {
                  visionId: this.selectedNode.visionCode,
                  kpiId: this.selectedNode.kpiCode,
                  frequency: this.selectedNode.frequency,
                };
                this.kpitarget.getTarget(getTarget).subscribe(
                  (response: any) => {
                    this.dataArray = response;
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                  }
                );
                this.closeContextMenu();
                if (this.selectedNode.type === 'vision') {
                  this.visibleVisionTarget = true;
                } else if (this.selectedNode.type === 'stratgickpi') {
                  this.visibleVisionTarget = true;
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Yearly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleYearlyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Monthly'
                ) {
                  this.opendisplayMonthlyTargetTbl();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Quarterly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.displayQuarterlyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Weekly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleWeeklyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'BiMonthly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleWeeklyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Daily'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleDailyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Daily'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleDailyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                } else if (
                  this.selectedNode.type === 'kpi' &&
                  this.selectedNode.frequency === 'Shiftly'
                ) {
                  this.kpiTitle = this.selectedNode.label;
                  this.kpifrequency = this.selectedNode.frequency;
                  this.kpiUom = this.selectedNode.uom;
                  this.dialog.open(this.visibleShiftlyTargetTbl, {
                    height: '90%',
                    width: '100%',
                  });
                  this.enableOverlay();
                }
              } else {
                this.closeContextMenu();
                this.toaster.error(
                  'Alert!',
                  'you do not have permission to access'
                );
              }
            },
          },
          {
            label: 'Remove Target',
            icon: 'pi pi-fw pi-trash',
            disabled: !this.IsLocked,
            command: (event: any) => {
              if (this.IsWrite) {
                if (this.selectedNode) {
                  this.deletekpiTargetComplete();
                }
              } else {
                this.closeContextMenu();
                this.toaster.error(
                  'Alert!',
                  'you do not have permission to access'
                );
              }
            },
          },
          {
            separator: true,
          },
          {
            label: 'Refresh Target',
            icon: 'pi pi-refresh',

            disabled: true,
            command: (event: any) => {
              if (this.selectedNode) {
                const key = {
                  kpiId: this.selectedNode.kpiId,
                };
                this.kpitarget.refreshKpiTarget(key).subscribe(
                  (response: any) => {
                    this.toaster.success('Success', 'Data Refresh Successfuly');
                    this.getTreeData();
                  },
                  (error: any) => {
                    this.toaster.error('Error', error.message);
                  }
                );
              }
            },
          },
        ],
      },
    ];
  }
  expandAll() {
    const expandedData = this.buildTreeWithExpandedState(this.data1[0], true);
    this.data1 = [expandedData];
    setTimeout(() => {
      this.onResetViewClicked();
    }, 1000);
  }

  collapseAll() {
    const collapsedData = this.buildTreeWithExpandedState(this.data1[0], false, true);
    this.data1 = [collapsedData];
    setTimeout(() => {
      this.onResetViewClicked();
    }, 1000);
  }

  buildTreeWithExpandedState(node: TreeNode, expanded: boolean, isRoot: boolean = false): TreeNode {
    const newNode: TreeNode = { ...node, expanded: isRoot ? true : expanded };

    if (node.children) {
      newNode.children = node.children.map(child => this.buildTreeWithExpandedState(child, expanded));
    }

    return newNode;
  }


  enableOverlay(): void {
    if (this.isFullscreen) {
      const overlayEl = document.getElementsByClassName('cdk-overlay-container') as HTMLCollectionOf<HTMLElement>;
      if (overlayEl.length > 0) {
        const element = overlayEl[0]; // Assuming there's only one element with the class name
        // Update the inline style attribute with !important
        element.setAttribute('style', 'z-index: 999999 !important');
      }
    }
  }

  opendisplayMonthlyTargetTbl(): void {
    this.dialog.open(this.displayMonthlyTargetTbl, {
      height: '90%',
      width: '100%',
    });
    this.enableOverlay();

  }

}


function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }

  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}
function currencyFormatter(params: any): any {
  return formatNumber(params.value);
}
function formatNumber(val: any): any {
  return Math.floor(val)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
function getSqlDate(value: any): any {
  return (
    value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate()
  );
}



const filterParams = {
  debounceMs: 500,
  suppressAndOrCondition: true,
  comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
    if (cellValue == null) {
      return 0;
    }
    const dateParts = cellValue.split('/');
    const year = Number(dateParts[2]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[0]);
    const cellDate = new Date(year, month, day);

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  },
};
