import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ToastrService } from 'ngx-toastr';
import { OrderProcessingService } from 'src/app/services/orderprocessing.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
    selector: 'app-unit-picker',
    templateUrl: './unit-picker.component.html',
    styleUrls: ['./unit-picker.component.scss']
})
export class UnitPickerComponent implements OnInit {

    @Input() Group: number = 0;
    @Input() unitPickerIsOpen: boolean = false;
    @Input() isPickFromUnit: boolean = false;
    updateOrderprocessingData: boolean = false;
    selectedUnit = { Name: '', Id: 0 };
    NodeData: any = {};
    isPopupMachine: boolean = false;
    treeControl = new NestedTreeControl<PlantGroupElement>(node => node.children);
    plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
    hasChild = (_: number, node: PlantGroupElement) => !!node.children && node.children.length > 0;
    @Output() emitSelectedUnit = new EventEmitter<any>();
    @Input() fromPage: string;

    constructor(
        private toaster: ToastrService,
        private enterpriseGroupService: EnterPriseGroupService,
        private spinner: NgxSpinnerService
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log('ngOnChanges from unit picker');

        if (this.unitPickerIsOpen) this.openModal();
    }

    openModal(): void {
        this.updateOrderprocessingData = true;
        this.isPopupMachine = false;
        const key = {
            EGId: this.Group,
            level: this.fromPage === 'pmDashboard' ? 'Line' : (this.isPickFromUnit ? 'Component' : 'Unit'),
            CreatedBy: 0,
            IsOptional: 0
        };
        this.spinner.show();
        this.enterpriseGroupService.getTreeModal(key).subscribe((data: any) => {
            this.spinner.hide();
            const plantGroupData: PlantGroupElement[] = data.TreeModalList;
            this.plantGroupSource.data = plantGroupData;
            this.udpateNodeParentDetails();
            this.treeControl.dataNodes = this.plantGroupSource.data;
        }, (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
        });
    }

    udpateNodeParentDetails() {
        if (this.plantGroupSource.data && this.plantGroupSource.data.length) {
            this.plantGroupSource.data.forEach((group) => {
                if (group.children && group.children.length) {
                    group.children.forEach((plant) => {
                        plant.groupName = group.text;
                        plant.groupId = group.PrimaryId;
                        if (plant.children && plant.children.length) {
                            plant.children.forEach((division: any) => {
                                division.plantId = plant.PrimaryId;
                                division.plantName = plant.text;
                                division.groupName = group.text;
                                division.groupId = group.PrimaryId;
                                if (division.children && division.children.length) {
                                    division.children.forEach((department: any) => {
                                        department.divisionId = division.PrimaryId;
                                        department.divisionName = division.text;
                                        department.plantId = plant.PrimaryId;
                                        department.plantName = plant.text;
                                        department.groupId = group.PrimaryId;
                                        department.groupName = group.text;
                                        if (department.children && department.children.length) {
                                            department.children.forEach((line: any) => {
                                                line.departmentId = department.PrimaryId;
                                                line.departmentName = department.text;
                                                line.divisionId = division.PrimaryId;
                                                line.divisionName = division.text;
                                                line.plantId = plant.PrimaryId;
                                                line.plantName = plant.text;
                                                line.groupId = group.PrimaryId;
                                                line.groupName = group.text;
                                                if (line.children && line.children.length) {
                                                    line.children.forEach((unit: any) => {
                                                        unit.lineId = line.PrimaryId;
                                                        unit.lineName = line.text;
                                                        unit.departmentId = department.PrimaryId;
                                                        unit.departmentName = department.text;
                                                        unit.divisionId = division.PrimaryId;
                                                        unit.divisionName = division.text;
                                                        unit.plantId = plant.PrimaryId;
                                                        unit.plantName = plant.text;
                                                        unit.groupId = group.PrimaryId;
                                                        unit.groupName = group.text;
                                                        if (unit.children && unit.children.length) {
                                                            unit.children.forEach((assembly: any) => {
                                                                assembly.unitName = unit.text;
                                                                assembly.unitId = unit.PrimaryId;
                                                                assembly.lineId = line.PrimaryId;
                                                                assembly.lineName = line.text;
                                                                assembly.departmentId = department.PrimaryId;
                                                                assembly.departmentName = department.text;
                                                                assembly.divisionId = division.PrimaryId;
                                                                assembly.divisionName = division.text;
                                                                assembly.plantId = plant.PrimaryId;
                                                                assembly.plantName = plant.text;
                                                                assembly.groupId = group.PrimaryId;
                                                                assembly.groupName = group.text;
                                                                if (assembly.children && assembly.children.length) {
                                                                    assembly.children.forEach((subAssembly: any) => {
                                                                        subAssembly.assemblyName = assembly.text;
                                                                        subAssembly.assemblyId = assembly.PrimaryId;
                                                                        subAssembly.unitName = unit.text;
                                                                        subAssembly.unitId = unit.PrimaryId;
                                                                        subAssembly.lineId = line.PrimaryId;
                                                                        subAssembly.lineName = line.text;
                                                                        subAssembly.departmentId = department.PrimaryId;
                                                                        subAssembly.departmentName = department.text;
                                                                        subAssembly.divisionId = division.PrimaryId;
                                                                        subAssembly.divisionName = division.text;
                                                                        subAssembly.plantId = plant.PrimaryId;
                                                                        subAssembly.plantName = plant.text;
                                                                        subAssembly.groupId = group.PrimaryId;
                                                                        subAssembly.groupName = group.text;
                                                                        if (subAssembly.children && subAssembly.children.length) {
                                                                            subAssembly.children.forEach((component: any) => {
                                                                                component.subAssemblyName = subAssembly.text;
                                                                                component.subAssemblyId = subAssembly.PrimaryId;
                                                                                component.assemblyName = assembly.text;
                                                                                component.assemblyId = assembly.PrimaryId;
                                                                                component.unitName = unit.text;
                                                                                component.unitId = unit.PrimaryId;
                                                                                component.lineId = line.PrimaryId;
                                                                                component.lineName = line.text;
                                                                                component.departmentId = department.PrimaryId;
                                                                                component.departmentName = department.text;
                                                                                component.divisionId = division.PrimaryId;
                                                                                component.divisionName = division.text;
                                                                                component.plantId = plant.PrimaryId;
                                                                                component.plantName = plant.text;
                                                                                component.groupId = group.PrimaryId;
                                                                                component.groupName = group.text;
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
    }

    onNodeSelect(node: any): void {
        if (this.isPickFromUnit) {
            this.updateOrderprocessingData = false;
            if (node.Level == 'Line' || node.Level == 'Unit' || node.Level == 'Assembly' || node.Level == 'SubAssembly' || node.Level == 'Component') {
                if (node.Level == 'Line') {
                    this.NodeData = {}
                    this.NodeData.line = node.PrimaryId;
                    this.NodeData.lineName = node.text;
                    this.NodeData.displayMachine = `${node.text}`;
                    this.NodeData.Plant = node.plantId;
                    this.NodeData.Level = node.Level;
                    this.NodeData.plantName = node.plantName;
                    this.NodeData.divisionName = node.divisionName;
                    this.NodeData.departmentName = node.departmentName;                    
                }
                else if (node.Level == 'Unit') {
                    this.NodeData = {}
                    this.NodeData.line = node.lineId;
                    this.NodeData.lineName = node.lineName;
                    this.NodeData.units = node.PrimaryId;
                    this.NodeData.unitName = node.text;
                    this.NodeData.displayMachine = `${node.lineName}/${node.text}`;
                    this.NodeData.Plant = node.plantId;
                    this.NodeData.Level = node.Level;
                }
                else if (node.Level == 'Assembly') {
                    this.NodeData = {}
                    this.NodeData.assembly = node.PrimaryId;
                    this.NodeData.assemblyName = node.text;
                    this.NodeData.units = node.unitId;
                    this.NodeData.unitName = node.unitName;
                    this.NodeData.line = node.lineId;
                    this.NodeData.lineName = node.lineName;
                    this.NodeData.displayMachine = `${node.lineName}/${node.unitName}/${node.text}`;
                    this.NodeData.Plant = node.plantId;
                    this.NodeData.Level = node.Level;
                }
                else if (node.Level == 'SubAssembly') {
                    this.NodeData = {}
                    this.NodeData.subAssembly = node.PrimaryId;
                    this.NodeData.subAssemblyName = node.text;
                    this.NodeData.assembly = node.assemblyId;
                    this.NodeData.assemblyName = node.assemblyName;
                    this.NodeData.units = node.unitId;
                    this.NodeData.unitName = node.unitName;
                    this.NodeData.line = node.lineId;
                    this.NodeData.lineName = node.lineName;
                    this.NodeData.displayMachine = `${node.lineName}/${node.unitName}/${node.assemblyName}/${node.text}`;
                    this.NodeData.Plant = node.plantId;
                    this.NodeData.Level = node.Level;
                }
                else if (node.Level == 'Component') {
                    this.NodeData = {}
                    this.NodeData.component = node.PrimaryId;
                    this.NodeData.componentName = node.text;
                    this.NodeData.subAssembly = node.subAssemblyId;
                    this.NodeData.subAssemblyName = node.subAssemblyName;
                    this.NodeData.assembly = node.assemblyId;
                    this.NodeData.assemblyName = node.assemblyName;
                    this.NodeData.units = node.unitId;
                    this.NodeData.unitName = node.unitName;
                    this.NodeData.line = node.lineId;
                    this.NodeData.lineName = node.lineName;
                    this.NodeData.displayMachine = `${node.lineName}/${node.unitName}/${node.assemblyName}/${node.subAssemblyName}/${node.text}`;
                    this.NodeData.Plant = node.plantId;
                    this.NodeData.Level = node.Level;
                }
            }
        }
        else {
            if (node.Level != 'Unit')
                return
            this.updateOrderprocessingData = false;
            this.NodeData.Id = node.PrimaryId;
            this.NodeData.Name = node.text;
        }
        this.emitSelectedUnit.emit(this.NodeData);
    }

    // expand all node
    expandAllNode(): void {
        this.treeControl.expandAll();
    }

    // collapse group view
    collapseAllNode(): void {
        this.treeControl.collapseAll();
    }

    // close model
    closePlantGroupModel(): void {
        this.updateOrderprocessingData = false;
        this.selectedUnit.Id = 0;
        this.selectedUnit.Name = '';
        this.emitSelectedUnit.emit(this.selectedUnit);
    }

    // replace icon
    replaceIcon(value: any): any {
        return value.replace(/(<([^>]+)>)/gi, '');
    }

    replaceString(value: any): any {
        const data = value.split('"')[1];
        if (data === 'IsOptionalplanticon') {
            return true;
        } else {
            return false;
        }
    }

}