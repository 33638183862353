import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CilConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/cil-configuration/cil-configuration.component';
import { AmCilDashboardComponent } from './am-cil-dashboard/am-cil-dashboard.component';
import { CilManagementComponent } from './cil-management/cil-management.component';
import { CilReportComponent } from './cil-report/cil-report.component';

const routes: Routes = [
    {path: 'CILManagement', component: CilManagementComponent },
    {path: 'CILMaster', component: CilConfigurationComponent },
    {path: 'CILReport', component: CilReportComponent },
    {path : 'AMCILDashboard',component : AmCilDashboardComponent},
];
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CILRoutingModule {}
