import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from 'src/app/material-module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProgressiveMaintenanceRoutingModule } from './progressiveMaintenance-routing.module';

@NgModule({
    imports: [
        CommonModule,
        ProgressiveMaintenanceRoutingModule,
        MaterialModule,
        MatMenuModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatTreeModule,
        MatCardModule,
        MatSelectModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatDialogModule,
        NgxMaterialTimepickerModule,
        MaterialModule,
        MatExpansionModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        FormsModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        MatSelectFilterModule
    ],
    declarations: [
    ],
        schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProgressiveMaintenanceModule { }
