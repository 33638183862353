<div class="machine-parameter-content">
  <div class="content-top-box">
    <form [formGroup]="addCostForm">
      <div class="content-top-row">
        <!-- <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Cost Center Parent</mat-label>
          <mat-select formControlName="costCenterparent" panelClass="testClass" [disabled]="disableCostParent">
            <mat-option *ngFor="let parent of parentDDL" [value]="parent.costCenterId">{{parent.costCenterName}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.CostCenterName : ('CostCenterName' | translate)}}</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterCostCenterName : ('EnterCostCenterName' | translate)}}" formControlName="name" required>
        </mat-form-field>

        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.GLAccount : ('GLAccount' | translate)}}</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterGLAccount : ('EnterGLAccount' | translate)}}" formControlName="glaccount" required>
        </mat-form-field>

        <button type="submit" *ngIf="!CostformMode" [disabled]="!addCostForm.valid" mat-mini-fab mat-button
          mat-dialog-close color="primary" pTooltip="Update" tooltipPosition="top" (click)="UpdateCostElement()"
          style="margin-left: 15px;">
          <mat-icon style="color: #fff" *ngIf="IsWrite">edit</mat-icon>
        </button>
        <button type="submit" *ngIf="CostformMode && IsWrite " [disabled]="!addCostForm.valid" mat-mini-fab mat-button
          mat-dialog-close color="primary" pTooltip="Save" tooltipPosition="top" (click)="AddCostCenter()"
          style="margin-left: 15px;">
          <mat-icon style="color: #fff">save</mat-icon>
        </button>

        <button [disabled]="!addCostForm.valid" mat-mini-fab mat-button pTooltip="Reset" tooltipPosition="top"
          (click)="RefreshCostCenter()" style="margin-left: 15px;">
          <mat-icon style="color: #fff">refresh</mat-icon>
        </button>
        <!-- <div class="import_export">
          <button class="import" (click)="ImportToExcel()">
            Import As Excel</button>
        </div> -->

      </div>
    </form>
  </div>
</div>
<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center">
    <mat-card-title class="p-ml-2">{{langObj ? langObj?.CostCenter : ('CostCenter' | translate)}}</mat-card-title>
    <div class="">
      <mat-form-field>
        <input matInput placeholder="{{langObj ? langObj?.Search : ('Search' | translate)}}...." autocomplete="off" (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>
  </div>

  <mat-card-content>
    <!-- <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
      <p style="font-size: 20px; color: #050a4f">Fetching Departments Data...</p>
    </ngx-spinner> -->
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
      <table mat-table [dataSource]="costCenterDataSource" class="basic-table plant-model-table" matSort>

        <ng-container matColumnDef="costActions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-text">{{langObj ? langObj?.Action : ('Action' | translate)}}</p>

          </th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <button mat-icon-button class="edit-btn" aria-label="Edit Row" *ngIf="IsWrite" pTooltip="Edit Cost"
                tooltipPosition="top" (click)="EditCost(element)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button class="node-control-btn node-delete" aria-label="delete item" *ngIf="IsWrite"
                pTooltip="Delete Cost" tooltipPosition="top" (click)="DeleteCost(element)" [disabled] = 'element.child > 0 ? "true" : "false"' [ngStyle]="{'background-color': (element.child > 0) ? '#D3D3D3' : '#FF0000'}">
                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                    fill="white" />
                </svg>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="costCenterparent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Cost Center Parent</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element?.parentName}}</td>
        </ng-container> -->

        <ng-container matColumnDef="costCenter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.CostCenterName : ('CostCenterName' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.costCenterName}} </td>
        </ng-container>

        <ng-container matColumnDef="glAccount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.GLAccount : ('GLAccount' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element?.glAccount}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="costCenterColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: costCenterColumns;"></tr>
        <tr class="mat-row" *matNoDataRow style="text-align: center;">
          <td class="mat-cell" colspan="4">{{langObj ? langObj?.Nodatamatching : ('Nodatamatching' | translate)}}</td>
        </tr>
      </table>
    </div>
    <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
  </mat-card-content>
</mat-card>
<ng-template #ImportDialog style="padding: 0px;">
  <div>
    <h1 mat-dialog-title>{{langObj ? langObj?.UploadExcel : ('UploadExcel' | translate)}}</h1>
    <div mat-dialog-content>
      <div class="d-flex">
        <div class="flex-1">
          <button mat-flat-button color="accent" type="button" (click)="openFile()">{{langObj ? langObj?.UploadExcel : ('UploadExcel' | translate)}}
            </button>
          <input type="file" (change)="uploadExcelFile($event)" class="d-none" #fileInput>
        </div>
        <div>
          <button mat-flat-button color="accent" type="button" class="w-22"><a class="sample"
              href="assets/xlsx/Loss_Analysis.xlsx" download>{{langObj ? langObj?.SampleExcel : ('SampleExcel' | translate)}}<i class="fa fa-download"
                aria-hidden="true"></i></a></button>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="mat-dialog-actions">
      <button mat-button class="dialog-cancel-btn" (click)="onCancelClick()">{{langObj ? langObj?.Cancel : ('Cancel' | translate)}}</button>
      <!-- <button mat-button class="dialog-save-btn" (click)="onSaveClick()" cdkFocusInitial>Save</button> -->
    </div>
  </div>
</ng-template>

<ng-template #duplicateDialog style="padding: 0px;">
  <mat-card class="p-mt-0 p-pt-0">
    <div class="p-d-flex p-jc-between p-ai-center pad">
      <mat-card-title class="p-ml-2">{{langObj ? langObj?.DuplicateData : ('DuplicateData' | translate)}}</mat-card-title>
      <div class="">
        <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  
    <mat-card-content>
      <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
        <p style="font-size: 20px; color: #050a4f">{{langObj ? langObj?.FetchingData : ('FetchingData' | translate)}}...</p>
      </ngx-spinner>
      
      <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
        <table mat-table [dataSource]="duplicateDataSource" class="basic-table plant-model-table" matSort>
  
          <ng-container matColumnDef="_costCenterName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.CostCenterName : ('CostCenterName' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.CostCenterName}}</td>
          </ng-container>
  
          <ng-container matColumnDef="_costElementName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.CostElementName : ('CostElementName' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.CostElementName}} </td>
          </ng-container>
  
          <ng-container matColumnDef="_sheetName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.SheetName : ('SheetName' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.SheetName}}</td>
          </ng-container>

          <ng-container matColumnDef="_message">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.Message : ('Message' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.Message}}</td>
          </ng-container>

          <ng-container matColumnDef="_sheetNo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.SheetNo : ('SheetNo' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.SheetNo}}</td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="duplicateDataColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: duplicateDataColumns;"></tr>
          <tr class="mat-row" *matNoDataRow style="text-align: center;">
            <td class="mat-cell" colspan="4">{{langObj ? langObj?.Nodatamatching : ('Nodatamatching' | translate)}}</td>
          </tr>
        </table>
      </div>
      <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</ng-template>