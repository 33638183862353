<div class="production-db-content" dir="{{languageDir}}">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a><!--{{lang.getTransalatedValue(breadcrumItem.text)}}</a>-->
        </li>
      </ul>
    </ul>
    <div class="title-row-flex-both-end">
      <h1 class="main-title">{{langObj ? langObj?.OEEDashBoard : ('OEEDashBoard' | translate)}}</h1>
    </div>
  </div>
  <div class="chart-group" dir="{{languageDir}}" style="margin-top: 12px;">
    <div class="chart-items-land">
      <div class="chart-title"> Customized Dashboard
        <span class="bottom-float" *ngIf="access"> <button mat-icon-button matTooltip="Add New Tab"
            (click)="openTabPopup()" aria-label="Example icon button with a vertical three dot icon">
            <mat-icon class="o3Color-icon"> tab</mat-icon>
          </button></span>
        <span class="bottom-float"> <button mat-icon-button matTooltip="Download Dashboard" (click)="downloadPDF()"
            aria-label="Example icon button with a vertical three dot icon">
            <mat-icon class="o3Color-icon"> picture_as_pdf</mat-icon>
          </button></span>
      </div>
      <div></div>
    </div>
  </div>

  <!-- <app-speed-dial-fab [options]="FabOptions"></app-speed-dial-fab> -->
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template="<img src='assets/images/spinner.gif' />"></ngx-spinner>
  <div>

    <mat-tab-group class="my-mat-tab-group" [selectedIndex]="selectedTabIndex"
      (selectedIndexChange)="tabSelected($event)">
      <mat-tab *ngFor="let tab of tabs; let i = index" class="tab">
        <ng-template mat-tab-label>
          {{ tab.TabName }}
          <button mat-icon-button (click)="shareTab(i)" *ngIf="IsAdmin">
            <mat-icon class="Color-icon-delete">share</mat-icon>
          </button>
          <button mat-icon-button (click)="shareTab(i)" *ngIf="!IsAdmin">
            <mat-icon class="Color-icon-delete">info</mat-icon>
          </button>
        </ng-template>
        <!-- Tab content -->
        <ng-template matTabContent>
          <div>
            <ul class="menu-card-list" dir="{{languageDir}}" *ngIf="!DataExist">
              <li class="list-items" *ngFor="let cardItem of CardListTop" cdkDrag>
                <a class="list-link cursor-scroll" [ngClass]="{'disabled': cardItem.disabled}">
                  <div class="link-top-box">
                    <div class="box-bg">
                      <svg width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
                          fill="#242bf0" />
                      </svg>
                    </div>
                    <div class="top-icon">
                      <img [src]="cardItem.imagePath!" alt="cardItem.text">
                    </div>
                  </div>
                  <p>
                    <span class="link-text-land">{{cardItem.labelName}} </span>

                  </p>
                  <div><span class="link-text-land-result">{{cardItem.result | number : '1.2-2'}}
                      {{cardItem.uom}}</span></div>

                  <div>
                    <span class="bottom-float-left">
                    </span>
                    <span class="bottom-float">
                      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
                        *ngIf="access" (click)="deleteDashboardCard(cardItem)">
                        <mat-icon class="Color-icon-delete">delete</mat-icon>
                      </button>
                      <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                        *ngIf="access" (click)="changeDatapoint(cardItem);"
                        matTooltip="{{langObj ? langObj?.changeDatapoint : ('changeDatapoint' | translate)}}">
                        <mat-icon class="o3Color-icon">edit</mat-icon>
                      </button>
                    </span>

                  </div>
                </a>
              </li>
            </ul>

          </div>
          <div class="container-landing-page" #container fxLayout="row wrap">
            <div class="card-landing-page" *ngFor="let card of chartArrayRight" [cdkDragData]="card"
              [style.zIndex]="card.zIndex" fxFlex="{{card.Item.width}}"
              [ngStyle]="{'width': card.Item.width + '%','height':card.Item.height}">
              <mat-card>
                <div class="resize-handle" (mousedown)="startResize($event, card)">
                  <!-- <mat-icon>drag_handle</mat-icon> -->
                </div>
                <mat-card-title>
                  <div style="float:right">
                    <mat-icon class="o3Color-icon"
                      matTooltip="{{langObj ? langObj?.changeDatapoint : ('changeDatapoint' | translate)}}"
                      (click)="changeDatapoint(card.Item)" *ngIf="access">edit</mat-icon>
                    <mat-icon class="o3Color-icon" (click)="toggleMaximize(card)"> fullscreen</mat-icon>
                    <mat-icon class="Color-icon-delete" (click)="deleteDashboardCardRight(card)"
                      *ngIf="access">delete</mat-icon>
                  </div>
                  <div style="float: left;">
                    <!-- <mat-icon class="o3Color-icon" matTooltip="Add filter" (click)="FilterPopup('all')"  >filter_list</mat-icon> -->
                    <mat-icon class="o3Color-icon" matTooltip="Save Layout" (click)="saveLayout(card)">save</mat-icon>
                  </div>
                </mat-card-title>
                <mat-card-content style="margin-top:36px">
                  <div [id]="card.Item.Id" [chart]="card.chartOption"></div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

  </div>

  <ng-template #AddTabPoup>
    <div mat-dialog-title class="mat-title">
      <div class="d-flex justify-content-between align-items-center">
        <h1 *ngIf="!updateCard" class="main-title">{{langObj ? langObj?.addTab : ('addTab' | translate)}}</h1>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <div class="five-s-divider">
      <form [formGroup]="AddTabform">
        <div class="content-top-row content-top-row-order-processing">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <input matInput formControlName="tabName" placeholder="Tab Name">
          </mat-form-field>
          <button mat-mini-fab color="primary" type="submit"
            pTooltip="{{langObj ? langObj?.save : ('save' | translate)}}" tooltipPosition="top"
            (click)="addNewTab('',1)">
            <mat-icon style="color: #fff">save</mat-icon>
          </button>
        </div>
      </form>
    </div>
    <ag-grid-angular style="width: 100%; height: 17vw;" class="ag-theme-alpine" [columnDefs]="columnDefs"
      [gridOptions]="gridOptions" [enterMovesDown]="true" [enterMovesDownAfterEdit]="true" [rowData]="rowData"
      (gridReady)="onGridReady($event)" [singleClickEdit]="true" [frameworkComponents]="frameworkComponents"
      [getRowStyle]="getRowStyle" (rowClicked)="rowClicked($event)" (cellValueChanged)="addNewTab($event,2)"
      [pagination]="true" [paginationPageSize]="5"></ag-grid-angular>
  </ng-template>

  <ng-template #ShareTabPoup>
    <div mat-dialog-title class="mat-title">
      <div class="d-flex justify-content-between align-items-center">
        <h1 class="main-title"> </h1>
        <!-- {{langObj ? langObj?.shareTab : ('shareTab' | translate)}} -->
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <div class="five-s-divider">
      <form [formGroup]="ShareTabform">
        <div class="content-top-row content-top-row-order-processing" *ngIf="IsAdmin">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-select formControlName="user" multiple placeholder="{{langObj ? langObj?.User : ('User' | translate)}}"
              panelClass="testClass" name="Status">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'UserName'" [array]="menuAccessUserList"
                (filteredReturn)="filteredUsers = $event"></mat-select-filter>
              <mat-option *ngFor="let c of filteredUsers" [value]="c.UserId">{{c.UserName}}</mat-option>

            </mat-select>
            <mat-error class="error-msg" *ngIf="checkError('chartType', 'required')">This field is
              required</mat-error>
          </mat-form-field>
          <mat-radio-group aria-label="Select an option" class="radio-group" formControlName="accessType">
            <mat-radio-button value="1" checked>Read</mat-radio-button>
            <mat-radio-button value="2">Write</mat-radio-button>
          </mat-radio-group>
          <button mat-mini-fab color="primary" type="submit"
            pTooltip="{{langObj ? langObj?.save : ('save' | translate)}}" tooltipPosition="top"
            (click)="saveShareTab()">
            <mat-icon style="color: #fff">save</mat-icon>
          </button>
        </div>
      </form>
    </div>
    <ag-grid-angular style="width: 100%; height: 17vw;" class="ag-theme-alpine" [columnDefs]="ShareTabcolumnDefs"
      [gridOptions]="gridOptions" [enterMovesDown]="true" [enterMovesDownAfterEdit]="true" [rowData]="rowDataSharedTabs"
      (gridReady)="onGridReady($event)" [singleClickEdit]="true" [frameworkComponents]="frameworkComponents"
      [getRowStyle]="getRowStyle" (rowClicked)="rowClicked($event)" (cellValueChanged)="addNewTab($event,2)"
      [pagination]="true" [paginationPageSize]="5"></ag-grid-angular>
  </ng-template>

  <ng-template #AddCardPoup>
    <div mat-dialog-title class="mat-title">
      <div class="d-flex justify-content-between align-items-center">
        <h1 *ngIf="!updateCard" class="main-title">{{popupLabel}}</h1>
        <h1 *ngIf="updateCard" class="main-title">{{langObj ? langObj?.updateCard : ('updateCard' | translate)}}</h1>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
      <div class="five-s-divider">
        <form [formGroup]="AddCardform">
          <div class="content-top-row content-top-row-order-processing">
            <!-- GROUP -->
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <!-- <mat-label>Status</mat-label> -->
              <mat-select formControlName="UserGroup1" (selectionChange)="onChangeGroup($event)"
                placeholder="{{langObj ? langObj?.Group : ('Group' | translate)}}" panelClass="testClass" name="Status">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
                  (filteredReturn)="filteredGroups = $event"></mat-select-filter>
                <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
                  {{group.EG_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-select formControlName="UserPlant1" (selectionChange)="onChangePlant($event)"
                placeholder="{{langObj ? langObj?.Plant : ('Plant' | translate)}}" panelClass="testClass" name="Status">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList1"
                  (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
                  {{plant.PT_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-select formControlName="line" (selectionChange)="onChangeLine($event)"
                placeholder="{{langObj ? langObj?.Line : ('Line' | translate)}}" panelClass="testClass" name="line">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
                  (filteredReturn)="filteredLines = $event"></mat-select-filter>
                <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
                  {{line.PL_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-select formControlName="unit" placeholder="{{langObj ? langObj?.Unit : ('Unit' | translate)}}"
                panelClass="testClass" name="unit">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="unitList"
                  (filteredReturn)="filteredUnits = $event"></mat-select-filter>
                <mat-option value="0">All</mat-option>
                <mat-option *ngFor="let unit of filteredUnits" [value]="unit.PU_Id">
                  {{unit.PU_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="mar-radio">
            <span>Time Period <mat-icon style="margin-top:3px" class="o3Color-icon" #tooltip="matTooltip"
                matTooltip={{infoContent}}>info</mat-icon> : </span>
            <mat-radio-group aria-label="Select an option" formControlName="timeperiod">
              <mat-radio-button style="margin:5px" value="noofdays">Number Of Days </mat-radio-button>
              <mat-radio-button style="margin:5px" value="daterange">Date Range</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="content-top-row content-top-row-order-processing">
            <div class="inputField">
              <nz-date-picker class="calender-input" id="startPicker" formControlName="dateStart"
                nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }"
                [nzInputReadOnly]="true"></nz-date-picker>
              <span class="floating-text" style="font-size: 15px;">{{langObj ? langObj?.StartDate : ('StartDate' |
                translate)}}</span>
            </div>
            <div class="inputField">
              <nz-date-picker class="calender-input" id="endPicker" formControlName="dateEnd"
                nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }"
                [nzInputReadOnly]="true"></nz-date-picker>
              <span class="floating-text" style="font-size: 15px;">{{langObj ? langObj?.EndDate : ('EndDate' |
                translate)}}</span>
            </div>
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-select formControlName="source" (selectionChange)="toggleOption($event.value)"
                [disabled]="requestType === 'top'"
                placeholder="{{langObj ? langObj?.DataPointSource : ('DataPointSource' | translate)}}"
                panelClass="testClass" name="Status">

                <mat-option *ngFor="let c of dataPointSources" [value]="c.Id">{{c.SourceName}}</mat-option>

              </mat-select>
            </mat-form-field>
          </div>
          <div class="content-top-row content-top-row-order-processing">
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-select formControlName="chartType" (selectionChange)="getDataPointList($event.value)"
                [disabled]="requestType === 'top'"
                placeholder="{{langObj ? langObj?.chartType : ('chartType' | translate)}}" panelClass="testClass"
                name="Status">
                <mat-option *ngIf="requestType === 'top'" value="value">Single Value</mat-option>
                <mat-option *ngFor="let c of chartList" [value]="c.Value">{{c.Name}}</mat-option>

              </mat-select>
              <mat-error class="error-msg" *ngIf="checkError('chartType', 'required')">Data point field is
                required</mat-error>
            </mat-form-field>
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-select formControlName="datapointName" (selectionChange)="setLabel($event)"
                placeholder="{{langObj ? langObj?.datapointName : ('datapointName' | translate)}}"
                panelClass="testClass" name="Status">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'Name'" [array]="GroupList"
                  (filteredReturn)="filteredDataPoints = $event"></mat-select-filter>
                <mat-option *ngFor="let d of filteredDataPoints" [value]="d.Id">
                  {{d.Name}}
                </mat-option>
              </mat-select>
              <mat-error class="error-msg" *ngIf="checkError('datapointName', 'required')">Data point field is
                required</mat-error>
            </mat-form-field>
          </div>
          <div class="content-top-row content-top-row-order-processing">
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <input matInput formControlName="analysisUrl"
                placeholder="{{langObj ? langObj?.analysisUrl : ('analysisUrl' | translate)}}">
            </mat-form-field>
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <input matInput formControlName="orderNo"
                placeholder="{{langObj ? langObj?.orderNo : ('orderNo' | translate)}}">
            </mat-form-field>
          </div>
          <div class="content-top-row content-top-row-order-processing">
            <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <input matInput formControlName="labelName"
                placeholder="{{langObj ? langObj?.labelName : ('labelName' | translate)}}">
              <mat-error class="error-msg" *ngIf="checkError('labelName', 'required')"> Name field is
                required</mat-error>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              *ngIf="requestType !== 'top' && sourceName === 'Parameters'">
              <mat-select formControlName="aggregates"
                placeholder="{{langObj ? langObj?.aggregates  : ('aggregates' | translate)}}" panelClass="testClass"
                name="Status">
                <mat-option *ngFor="let ag of AggeregateList" [value]="ag.id">
                  {{ag.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              *ngIf="requestType !== 'top' && ![1, 4, 5,6,7].includes(sourceId)">
              <mat-select formControlName="values" placeholder="{{
              langObj ? langObj?.values : ('values' | translate)
            }}" multiple>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="no match found"
                    [formControl]="valueSearchCtrl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let obj of filteredValueMulti | async" [value]="obj.Id">
                  {{obj.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="btn-position">
            <!-- <button mat-raised-button color="accent" class="btn-accent" >{{langObj ? langObj?.save : ('save' | translate)}}</button> -->
            <button mat-mini-fab *ngIf="!updateCard" color="primary" type="submit"
              pTooltip="{{langObj ? langObj?.save : ('save' | translate)}}" tooltipPosition="top"
              (click)="saveDashboardCard()">
              <mat-icon style="color: #fff">save</mat-icon>
            </button>
            <button mat-mini-fab *ngIf="updateCard" color="primary" type="submit"
              pTooltip="{{langObj ? langObj?.update : ('update' | translate)}}" tooltipPosition="top"
              (click)="updateDashboardCard()">
              <mat-icon style="color: #fff">edit</mat-icon>
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template #FilterPoup>
    <div mat-dialog-title class="mat-title">
      <div class="d-flex justify-content-between align-items-center">
        <h1 class="main-title">{{langObj ? langObj?.filter : ('filter' | translate)}}</h1>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
      <div class="five-s-divider">
        <form [formGroup]="Headerform">
          <div class="content-top-row content-top-row-order-processing">
            <!-- GROUP -->
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <!-- <mat-label>Status</mat-label> -->
              <mat-select formControlName="UserGroup1" (selectionChange)="onChangeGroup($event)"
                placeholder="{{langObj ? langObj?.Group : ('Group' | translate)}}" panelClass="testClass" name="Status">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
                  (filteredReturn)="filteredGroups = $event"></mat-select-filter>
                <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
                  {{group.EG_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-select formControlName="UserPlant1" (selectionChange)="onChangePlant($event)"
                placeholder="{{langObj ? langObj?.Plant : ('Plant' | translate)}}" panelClass="testClass" name="Status">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList1"
                  (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
                  {{plant.PT_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-select formControlName="line" (selectionChange)="onChangeLine($event)"
                placeholder="{{langObj ? langObj?.Line : ('Line' | translate)}}" panelClass="testClass" name="line">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
                  (filteredReturn)="filteredLines = $event"></mat-select-filter>
                <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
                  {{line.PL_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-select formControlName="unit" placeholder="{{langObj ? langObj?.Unit : ('Unit' | translate)}}"
                panelClass="testClass" name="unit" (selectionChange)="changeFilter();">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="unitList"
                  (filteredReturn)="filteredUnits = $event"></mat-select-filter>
                <mat-option value="0">All</mat-option>
                <mat-option *ngFor="let unit of filteredUnits" [value]="unit.PU_Id">
                  {{unit.PU_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="content-top-row content-top-row-order-processing">
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-select formControlName="frequency" (selectionChange)="changeFilter()"
                placeholder="{{langObj ? langObj?.frequency : ('frequency' | translate)}}" panelClass="testClass"
                name="Status">
                <mat-option value="Weekly">Weekly</mat-option>
                <mat-option value="Monthly">Monthly</mat-option>
              </mat-select>
              <!-- <mat-error class="error-msg" *ngIf="checkError('frequency', 'required')">Data point field is
              required</mat-error> -->
            </mat-form-field>
            <div class="inputField">
              <nz-date-picker class="calender-input" id="startPicker" formControlName="dateStart"
                nzFormat="dd/MM/yyyy HH:mm" (nzOnOk)="changeFilter();" [nzShowTime]="{ nzFormat: 'HH:mm' }"
                [nzInputReadOnly]="true"></nz-date-picker>
              <span class="floating-text" style="font-size: 15px;">{{langObj ? langObj?.StartDate : ('StartDate' |
                translate)}}</span>
            </div>
            <div class="inputField">
              <nz-date-picker class="calender-input" id="endPicker" formControlName="dateEnd"
                nzFormat="dd/MM/yyyy HH:mm" (nzOnOk)="changeFilter();" [nzShowTime]="{ nzFormat: 'HH:mm' }"
                [nzInputReadOnly]="true"></nz-date-picker>
              <span class="floating-text" style="font-size: 15px;">{{langObj ? langObj?.EndDate : ('EndDate' |
                translate)}}</span>
            </div>
            <span class="bottom-float"> <button mat-icon-button matTooltip="Save Filter" (click)="saveFilter()"
                aria-label="Example icon button with a vertical three dot icon">
                <mat-icon class="o3Color-icon">save</mat-icon>
              </button></span>
            <span class="bottom-float"> <button mat-icon-button matTooltip="Delete Saved Filter"
                (click)="deleteFilter()" aria-label="Example icon button with a vertical three dot icon">
                <mat-icon class="Color-icon-delete">delete</mat-icon>
              </button></span>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <!-- floating button code start... -->
  <div class="dialog_scrim" *ngIf="openMenu">
  </div>

  <button mat-fab color="primary" (click)="clickMenu()" class="menu-button" [class.mat-elevation-z2]="!isOver"
    *ngIf="IsTabExist && access" [class.mat-elevation-z8]="isOver" (mouseover)="isOver = true"
    (mouseleave)="isOver = false">
    <i class="material-icons white-icon" *ngIf="!openMenu">menu</i>
    <i class="material-icons white-icon" *ngIf="openMenu">close</i>
  </button>

  <!-- menu button -->
  <div dir="{{languageDir}}" *ngIf="IsTabExist">
    <button mat-mini-fab class="action-button three mat-elevation-z8" *ngIf="openMenu" color="basic"
      (click)="openPopup('right')">
      <i class="material-icons">dashboard</i>
    </button>
    <button mat-raised-button class="label labelthree mat-elevation-z8" style="color:white" *ngIf="openMenu"
      color="basic" (click)="openPopup('right')">
      Manage Chart Card
    </button>
    <button mat-mini-fab class="action-button two mat-elevation-z8" *ngIf="openMenu" color="basic"
      (click)="openPopup('top')">
      <i class="material-icons">dashboard</i>
    </button>
    <button mat-raised-button class="label labeltwo mat-elevation-z8" *ngIf="openMenu" color="basic"
      (click)="openPopup('top')">
      Manage Single Value Card
    </button>
    <!-- <button mat-mini-fab class="action-button one mat-elevation-z8" *ngIf="openMenu" color="basic"
    (click)="openPopup('top')">
    <i class="material-icons">dashboard</i>
  </button>

  <button mat-raised-button class="label labelone mat-elevation-z8" *ngIf="openMenu" color="basic"
    (click)="openPopup('top')">
    Manage Slot 1(TOP)
  </button> -->
  </div>
  <!-- end code here.. -->




  <!-- ============================================================== Dashboard tour========================================== -->

  <section class="hero" *ngIf="DataExist || tabs?.length === 0">
    <div class="container">
      <div class="hero-inner">
        <div class="hero-copy">
          <h1 class="hero-title h2-mobile mt-0 is-revealing">{{clock | date: 'hh:mm:ss'}}</h1>
          <h3 class="hero-title h2-mobile mt-0 is-revealing">{{clock | date}}</h3>
          <h1 class="hero-title h2-mobile mt-0 is-revealing">Create Your Dynamic Dashboard</h1>
          <p>Data is most powerful when it's comprehensible. With our platform, you can transform raw data into dynamic,
            insightful dashboards that drive better decision-making. It's time to break free from rigid templates and
            create a dashboard that evolves with your needs.</p>
          <p class="hero-cta is-revealing" *ngIf="access"><a class="button button-primary button-shadow"
              (click)="tabs.length > 0 ? clickMenu() : openTabPopup()">Get Started</a></p>

        </div>
        <div class="hero-illustration is-revealing">
          <img src='assets/images/o3-dynamic-db.png' />
        </div>
      </div>
    </div>
  </section>

  <ng-template #createAction>
    <div mat-dialog-content>
      <div class="d-flex" style="width:300px !important;justify-content: space-around">
        <div class="icon-card-container">
          <mat-card class="action-card" (click)="openAddActionModal()">
            <div class="card-content-model">
              <mat-icon class="large-icon white-icon">call_to_action</mat-icon>
              <div class="button-text white-text">{{ langObj ? langObj?.Action : ("Action" | translate) }}</div>
            </div>
          </mat-card>
        </div>
        <div class="icon-card-container">
          <mat-card class="rca-card" (click)="openRcaDue()">
            <div class="card-content">
              <mat-icon class="large-icon white-icon">manage_search</mat-icon>
              <div class="button-text white-text">{{ langObj ? langObj?.RCA : ("RCA" | translate) }}</div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #addActionModal>
    <div mat-dialog-title>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          {{ langObj ? langObj?.AddAction : ("AddAction" | translate) }}
          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>
    </div>
    <mat-dialog-content>
      <form [formGroup]="addActionForm">
        <div class="content-top-row" style="margin-bottom: 20px">
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>{{langObj ? langObj?.Action : ('Action' | translate)}} *</mat-label>
            <input matInput placeholder="Enter Action Name" formControlName="action" />
          </mat-form-field>
          <div class="radio-wrapper" style="width: 20%;">
            <mat-label>{{langObj ? langObj?.ActionType : ('ActionType' | translate)}} *:</mat-label>
            <mat-radio-group formControlName="actionType" class="radio-group" name="ActionType">
              <mat-radio-button value="Preventive">Preventive</mat-radio-button>
              <mat-radio-button value="Corrective">Corrective</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="background: gainsboro;">
            <mat-label>KPI</mat-label>
            <input matInput placeholder="Enter Remarks" formControlName="kpi" />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>{{langObj ? langObj?.SelectAssignee : ('SelectAssignee' | translate)}} *</mat-label>
            <mat-select formControlName="assignTo">
              <mat-select-filter [placeholder]="'Search User'" [displayMember]="'FullName'"
                [array]="participantsByPlants" (filteredReturn)="userList = $event">
              </mat-select-filter>
              <mat-option *ngFor="let user of userList" [value]="user?.EmpInfo_Id">
                {{ user?.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="inputField" style="width: 17%;margin-top: 0px;">
            <nz-date-picker class="calender-input" style="margin-left: 0px !important; width: 100%" [nzShowTime]="false"
              nzFormat="yyyy-MM-dd" formControlName="targetDate">
            </nz-date-picker>
            <span class="floating-text">{{langObj ? langObj?.TargetDate : ('TargetDate' | translate)}} *</span>
          </div>
          <button [disabled]="!addActionForm.valid" mat-mini-fab mat-button color="primary" matTooltip="Add"
            matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 10px;"
            (click)="addActionPlan()">
            <i class="fa fa-floppy-o" style="color: #fff"></i>
          </button>
        </div>
      </form>
      <!-- <ag-grid-angular style="width: 100%; height: 20vw" class="ag-theme-alpine" [pagination]="true" 
      [paginationAutoPageSize]="5" [columnDefs]="columnDefsallAction" [rowData]="overallMeetingActions"
      (cellClicked)="cellClickedActionTable($event)" (gridReady)="onGridReadyAction($event)">
    </ag-grid-angular> -->
    </mat-dialog-content>
  </ng-template>