import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { MatDialog } from '@angular/material/dialog';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { RMDashboardService } from '../../services/rm-dashboard.service';
import { Options } from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import { ManagerExecutionService } from 'src/app/services/manager.service';

More(Highcharts);
Drilldown(Highcharts);
Exporting(Highcharts);

@Component({
  selector: 'app-pm-dashboard',
  templateUrl: './pm-dashboard.component.html',
  styleUrls: ['./pm-dashboard.component.scss'],
})
export class PmDashboardComponent implements OnInit {
  @ViewChild('breakDownDialog') private breakDownDialog!: TemplateRef<any>;
  @ViewChild('TaskCompletedDialog') private TaskCompletedDialog!: TemplateRef<any>;
  @ViewChild('TaskNotCompletedDialog') private TaskNotCompletedDialog!: TemplateRef<any>;
  
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  breadcrumList: any;
  GroupList!: any[];
  PlantList!: any[];
  LineList!: any[];
  dashboardData!: any;
  defaultExcelExportParams: any;
  unitPickerIsOpen: boolean = false;
  isPickFromUnit: boolean = false;
  HeaderData: any = {
    Group: '',
    StartDate:new Date(
      moment().subtract(1, 'd').set('hour', 6).set('minutes', 0).toString()
    ),
    EndDate: new Date(),
    Plant: '',
    Line: '',
  };
  HeaderForm: any;
  TreeData: any = [];
  userList: any = [];
  chartModerate!: Chart;
  options: Options;
  totalBreakdowns: any;
  bdCategory: any;
  majorBDMachineLevel: any;
  majorBDSubAssemblyLevel: any;
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  dashboardCount: any = {
    completed: 0,
    notCompleted: 0,
    tbm: 0,
    adhernce: 0
  };
  taskType: any = [];


  chartTaskCompletedUnit: Chart;
  chartTaskCompletedFrequency: Chart;
  chartTaskCompletedTaskType: Chart;
  chartTaskCompletedMaintenanceType: Chart;

  chartTaskNotCompletedUnit: Chart;
  chartTaskNotCompletedFrequency: Chart;
  chartTaskNotCompletedTaskType: Chart;
  chartTaskNotCompletedMaintenanceType: Chart;

  TaskCompletedChartData: any = {
    AllData: [],
    UnitData: [],
    FrequencyData: [],
    TaskTypeData: [],
    MaintenanceTypeData: []
  };

  TaskNotCompletedChartData: any = {
    AllData: [],
    UnitData: [],
    FrequencyData: [],
    TaskTypeData: [],
    MaintenanceTypeData: []
  };

  frameworkComponents: any;
  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;
  gridOptions: any = {
    defaultColDef: {
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- HERE
      // minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
    },
    popupParent: document.querySelector('body'),
  };

  constructor(
    private productivityservice: ProductivityService,
    private toaster: ToastrService,
    public router: Router,
    private userService: UserManagementService,
    private spinner: NgxSpinnerService,
    private pmDashboardService: RMDashboardService,
    private fb: FormBuilder,
    public managerExecutionService: ManagerExecutionService,
    private dialog: MatDialog
  ) {
    this.defaultExcelExportParams = {
      addImageToCell: function (
        rowIndex: number,
        col: any,
        value: any,
        callback: any
      ) {
        if (col.colId === 'PreImage' || col.colId === 'PostImage') {
        } else {
          return;
        }
      },
    };


    this.columnDefs = [
      {
        headerName: 'Task Description', minWidth: 350, field: 'Description', filter: 'agTextColumnFilter', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Unit', minWidth: 130, field: 'PU_Desc', filter: 'agTextColumnFilter', wrapText: true, cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Frequency', minWidth: 150, field: 'FrequencyName', filter: 'agTextColumnFilter', cellRenderer: function (params: any) {
          let html = params.value;
          return html;
        },
        
      },
      {
        headerName: 'Task Type', minWidth: 150, field: 'TaskTypeName', filter: 'agTextColumnFilter', wrapText: true, cellRenderer: function (params: any) {
          let html = params.value;
          return html;
        },
        
      },
      {
        headerName: 'Maintenance Type', minWidth: 150, field: 'MaintenanceType', filter: 'agTextColumnFilter', valueFormatter: function (params: any) {
          let html = params.value;
          return html;
        },
        
      },
      {
        headerName: 'Start Time', minWidth: 150, field: 'StartTime', filter: 'agTextColumnFilter', wrapText: true, 
      },
      {
        headerName: 'Finish Time', minWidth: 150, field: 'FinishTime', filter: 'agTextColumnFilter', wrapText: true,
      },
      {
        headerName: 'Status', minWidth: 100, field: 'displayStatus', filter: 'agTextColumnFilter', cellStyle: function (params: any) {
          if (params.value == 'Done')
            return { color: 'white', backgroundColor: 'green' };
          else
            return { color: 'white', backgroundColor: 'red' };
        },
        
      },
    ];
  }

  ngOnInit(): void {
    this.HeaderForm = this.fb.group({
      Group: ['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      Plant: ['', Validators.required],
      Line: ['', Validators.required],
    });
    this.getGroup();
    this.getUserList();
    this.GetBreadcrumList();
  }

  getUserList() {
    this.spinner.show();
    this.managerExecutionService.getUserList().subscribe(
      (res: any) => {
        this.userList = res.UserList;
        this.spinner.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  getGroup(): void {
    const key = {
      UserId: 206,
    };
    const id = {
      value: 0,
    };
    this.productivityservice.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.lstEG;
        if (this.GroupList && this.GroupList.length) {
          this.HeaderForm.get('Group')?.setValue(this.GroupList[0].EG_Id);
        }
        this.filteredGroups = this.GroupList.slice();
        this.getPlants();

      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(): void {
    const key = {
      EG_ID: this.HeaderForm.value.Group,
      userId: 206
    };
    this.spinner.show();
    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.spinner.hide();
      this.PlantList = data.lstPlant;
      this.filteredPlants = this.PlantList.slice();

      if (this.PlantList && this.PlantList.length)
        this.HeaderForm.get('Plant')?.setValue(this.PlantList[0].PT_Id);
      this.getLine();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getLine() {
    const key = {
      PT_ID: this.HeaderForm.value.Plant,
      userId: 206
    };
    this.spinner.show();
    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.spinner.hide();
      this.LineList = data.lstLine;
      this.filteredLines = this.LineList.slice();

      if (this.LineList && this.LineList.length){
        this.HeaderForm.get('Line')?.setValue(this.LineList[0].PL_Id);
        this.getPMDashboard();
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getPMDashboard() {
    if (!this.HeaderData.Group || !this.HeaderData.Line || !this.HeaderData.Plant || !this.HeaderData.StartDate || !this.HeaderData.EndDate)
      return
    this.spinner.show();
    const data = {
      EgID: this.HeaderData.Group,
      PLID: this.HeaderData.Line,
      PTID: this.HeaderData.Plant,
      dateStart: this.HeaderData.StartDate,
      dateEnd: this.HeaderData.EndDate
    } 
    this.pmDashboardService.getPMTaskDetailsDash(data).subscribe(
      (res: any) => {
        this.dashboardData = res;
        this.dashboardCount.completed = 0;
        this.dashboardCount.notCompleted = 0;
        this.dashboardCount.tbm = 0;
        this.dashboardCount.adhernce = 0;
        this.taskType = [];

        this.dashboardData.map(val => {
          if (val.Status == 1) {
            this.dashboardCount.completed += 1;
            val.displayStatus = 'Done';
          }
          else if (val.Status == 0) {
            this.dashboardCount.notCompleted += 1;
            val.displayStatus = 'Not Done';
          }
          if (val.MaintenanceType === 'Time Based')
            this.dashboardCount.tbm += 1;
          if (!this.taskType.some(task => task.name == val.TaskTypeName)) {
            const data = {
              name: val.TaskTypeName,
              count: this.dashboardData.filter(rec => rec.TaskTypeName == val.TaskTypeName).length
            }
            this.taskType.push(data);
          }
          if (val.StartTime)
         
          val.StartTime= val.StartTime?moment(val.StartTime).format('DD/MM/YYYY hh:mm a'):'';
          if (val.FinishTime)
          val.FinishTime= val.FinishTime?moment(val.FinishTime).format('DD/MM/YYYY hh:mm a'):'';
        })
        this.dashboardCount.adhernce = ((this.dashboardCount.completed / this.dashboardData.length) * 100).toFixed(2);
        this.dashboardCount.adhernce = this.dashboardCount.adhernce =='NaN'  ? '0' : this.dashboardCount.adhernce;
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();
      }
    );
  }

  openPMadherance() {
    if (this.dashboardCount.adhernce == 0)
      return
    const adherenceData = [];
    for (let k = new Date(this.HeaderData.StartDate).getMonth(); k <= new Date(this.HeaderData.EndDate).getMonth(); k++)
      adherenceData.push({ month: k, monthName: this.months[k] })

    let dialogRef = this.dialog.open(this.breakDownDialog, { height: 'unset', width: '90%' });
    let moderateData = [
      { name: 'Value 1', y: 40, machine: 'Test', color: '#0f0fd1' }
    ];
    let moderateBreakdown: any = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.months,
      },
      yAxis: {
        min: 0,
        max: 100,
        labels: {
          formatter: function () {
            return this.axis.defaultLabelFormatter.call(this) + '%';
          }
        }
      },
      tooltip: {
        formatter(this: any) {
          return `${this.point.series.name} Tasks : ` + this.point.y
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Completed',
        data: [49.9, 71.5, 80.4, 90.2, 98.0, 99.0, 88.6, 90.5, 99.4, 99.1, 95.6, 54.4],
        color: '#2f7ed8'

      }, {
        name: 'Not Completed',
        data: [83.6, 78.8, 98.5, 93.4, 79.0, 84.5, 97.0, 82.3, 91.2, 83.5, 98.6, 92.3],
        color: '#1aadce'

      }]
    };
    let chart = new Chart(moderateBreakdown);
    this.chartModerate = chart;
  }

  openTaskCompleted() {
    if (this.dashboardCount.completed == 0)
      return
    if (this.dashboardData && this.dashboardData.length) {
      this.TaskCompletedChartData.AllData = this.dashboardData.filter(rec => rec.Status == 1);
      this.TaskCompletedChartData.UnitData = [...new Set(this.TaskCompletedChartData.AllData.map(val => val.PU_Desc))];
      this.TaskCompletedChartData.FrequencyData = [...new Set(this.TaskCompletedChartData.AllData.map(val => val.FrequencyName))];
      this.TaskCompletedChartData.TaskTypeData = [...new Set(this.TaskCompletedChartData.AllData.map(val => val.TaskTypeName))];
      this.TaskCompletedChartData.MaintenanceTypeData = [...new Set(this.TaskCompletedChartData.AllData.map(val => val.MaintenanceType))];
    }

    let TaskCompletedUnitData: any = [];
    let TaskCompletedFrequencyData: any = [];
    let TaskCompletedTaskTypeData: any = [];
    let TaskCompletedMaintenanceTypeData: any = [];

    this.TaskCompletedChartData.UnitData.forEach((element: any) => {
      const unit = this.TaskCompletedChartData.AllData.filter(rec => rec.PU_Desc == element);
      TaskCompletedUnitData.push({
        name: element,
        y: unit.length,
      });
    });
    this.TaskCompletedChartData.FrequencyData.forEach((element: any) => {
      const frequency = this.TaskCompletedChartData.AllData.filter(rec => rec.FrequencyName == element);
      TaskCompletedFrequencyData.push({
        name: element,
        y: frequency.length,
      });
    });
    this.TaskCompletedChartData.TaskTypeData.forEach((element: any) => {
      const tasktype = this.TaskCompletedChartData.AllData.filter(rec => rec.TaskTypeName == element);
      TaskCompletedTaskTypeData.push({
        name: element,
        y: tasktype.length,
      });
    });
    this.TaskCompletedChartData.MaintenanceTypeData.forEach((element: any) => {
      const maintenancetype = this.TaskCompletedChartData.AllData.filter(rec => rec.MaintenanceType == element);
      TaskCompletedMaintenanceTypeData.push({
        name: element,
        y: maintenancetype.length,
      });
    });

    let dialogRef = this.dialog.open(this.TaskCompletedDialog, { height: '90vh', width: '90%' });


    let TaskCompletedUnitChart: any = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.TaskCompletedChartData.UnitData,
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter(this: any) {
          return `<b>${this.point.name}</b><br/>Count : ${this.point.y}`
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            formatter: function () {
              let ToolTip: any = this;
              return `${ToolTip.point.options.y}`;
            }
          }
        },
        column: {
          pointWidth: 50,
        }
      },
      series: [{
        name: 'Units',
        type: 'column',
        colorByPoint: true,
        data: TaskCompletedUnitData
      }]
    };

    let TaskCompletedFrequencyChart: any = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.TaskCompletedChartData.FrequencyData,
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter(this: any) {
          return `<b>${this.point.name}</b><br/>Count : ${this.point.y}`
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            formatter: function () {
              let ToolTip: any = this;
              return `${ToolTip.point.options.y}`;
            }
          }
        },
        column: {
          pointWidth: 50,
        }
      },
      series: [{
        name: 'Frequency',
        type: 'column',
        colorByPoint: true,
        data: TaskCompletedFrequencyData
      }]
    };

    let TaskCompletedTaskTypeChart: any = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.TaskCompletedChartData.TaskTypeData,
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter(this: any) {
          return `<b>${this.point.name}</b><br/>Count : ${this.point.y}`
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            formatter: function () {
              let ToolTip: any = this;
              return `${ToolTip.point.options.y}`;
            }
          }
        },
        column: {
          pointWidth: 50,
        }
      },
      series: [{
        name: 'Task Type',
        type: 'column',
        colorByPoint: true,
        data: TaskCompletedTaskTypeData
      }]
    };

    let TaskCompletedMaintenanceTypeChart: any = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter(this: any) {
          return `<b>Maintenance Type</b><br/>${this.point.name} : ${this.point.y}`
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}'
          }
        }
      },
      series: [{
        name: 'Maintenance Type',
        type: 'pie',
        colorByPoint: true,
        data: TaskCompletedMaintenanceTypeData
      }]
    };

    this.chartTaskCompletedUnit = new Chart(TaskCompletedUnitChart);
    this.chartTaskCompletedFrequency = new Chart(TaskCompletedFrequencyChart);
    this.chartTaskCompletedTaskType = new Chart(TaskCompletedTaskTypeChart);
    this.chartTaskCompletedMaintenanceType = new Chart(TaskCompletedMaintenanceTypeChart);
  }
  openTaskNotCompleted() {
    if (this.dashboardCount.notCompleted == 0)
      return
    if (this.dashboardData && this.dashboardData.length) {
      this.TaskNotCompletedChartData.AllData = this.dashboardData.filter(rec => rec.Status == 0);
      this.TaskNotCompletedChartData.UnitData = [...new Set(this.TaskNotCompletedChartData.AllData.map(val => val.PU_Desc))];
      this.TaskNotCompletedChartData.FrequencyData = [...new Set(this.TaskNotCompletedChartData.AllData.map(val => val.FrequencyName))];
      this.TaskNotCompletedChartData.TaskTypeData = [...new Set(this.TaskNotCompletedChartData.AllData.map(val => val.TaskTypeName))];
      this.TaskNotCompletedChartData.MaintenanceTypeData = [...new Set(this.TaskNotCompletedChartData.AllData.map(val => val.MaintenanceType))];
    }

    let TaskNotCompletedUnitData: any = [];
    let TaskNotCompletedFrequencyData: any = [];
    let TaskNotCompletedTaskTypeData: any = [];
    let TaskNotCompletedMaintenanceTypeData: any = [];

    this.TaskNotCompletedChartData.UnitData.forEach((element: any) => {
      const unit = this.TaskNotCompletedChartData.AllData.filter(rec => rec.PU_Desc == element);
      TaskNotCompletedUnitData.push({
        name: element,
        y: unit.length,
      });
    });
    this.TaskNotCompletedChartData.FrequencyData.forEach((element: any) => {
      const frequency = this.TaskNotCompletedChartData.AllData.filter(rec => rec.FrequencyName == element);
      TaskNotCompletedFrequencyData.push({
        name: element,
        y: frequency.length,
      });
    });
    this.TaskNotCompletedChartData.TaskTypeData.forEach((element: any) => {
      const tasktype = this.TaskNotCompletedChartData.AllData.filter(rec => rec.TaskTypeName == element);
      TaskNotCompletedTaskTypeData.push({
        name: element,
        y: tasktype.length,
      });
    });
    this.TaskNotCompletedChartData.MaintenanceTypeData.forEach((element: any) => {
      const maintenancetype = this.TaskNotCompletedChartData.AllData.filter(rec => rec.MaintenanceType == element);
      TaskNotCompletedMaintenanceTypeData.push({
        name: element,
        y: maintenancetype.length,
      });
    });


    let dialogRef = this.dialog.open(this.TaskNotCompletedDialog, { height: '90vh', width: '90%' });

    let TaskNotCompletedUnitChart: any = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.TaskNotCompletedChartData.UnitData,
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter(this: any) {
          return `<b>${this.point.name}</b><br/>Count : ${this.point.y}`
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            formatter: function () {
              let ToolTip: any = this;
              return `${ToolTip.point.options.y}`;
            }
          }
        },
        column: {
        }
      },
      series: [{
        name: 'Units',
        type: 'column',
        colorByPoint: true,
        data: TaskNotCompletedUnitData
      }]
    };

    let TaskNotCompletedFrequencyChart: any = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.TaskNotCompletedChartData.FrequencyData,
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter(this: any) {
          return `<b>${this.point.name}</b><br/>Count : ${this.point.y}`
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            formatter: function () {
              let ToolTip: any = this;
              return `${ToolTip.point.options.y}`;
            }
          }
        },
        column: {
        }
      },
      series: [{
        name: 'Frequency',
        type: 'column',
        colorByPoint: true,
        data: TaskNotCompletedFrequencyData
      }]
    };

    let TaskNotCompletedTaskTypeChart: any = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.TaskNotCompletedChartData.TaskTypeData,
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter(this: any) {
          return `<b>${this.point.name}</b><br/>Count : ${this.point.y}`
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            formatter: function () {
              let ToolTip: any = this;
              return `${ToolTip.point.options.y}`;
            }
          },
        },
        column: {
        }
      },
      series: [{
        name: 'Task Type',
        type: 'column',
        colorByPoint: true,
        data: TaskNotCompletedTaskTypeData
      }]
    };

    let TaskNotCompletedMaintenanceTypeChart: any = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter(this: any) {
          return `<b>Maintenance Type</b><br/>${this.point.name} : ${this.point.y}`
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}'
          }
        }
      },
      series: [{
        name: 'Maintenance Type',
        type: 'pie',
        colorByPoint: true,
        data: TaskNotCompletedMaintenanceTypeData
      }]
    };

    this.chartTaskNotCompletedUnit = new Chart(TaskNotCompletedUnitChart);
    this.chartTaskNotCompletedFrequency = new Chart(TaskNotCompletedFrequencyChart);
    this.chartTaskNotCompletedTaskType = new Chart(TaskNotCompletedTaskTypeChart);
    this.chartTaskNotCompletedMaintenanceType = new Chart(TaskNotCompletedMaintenanceTypeChart);
  }

  /// AG Grid 
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  excelExport() {
    this.gridApi.exportDataAsExcel();
  };

  csvExport() {
    this.gridApi.exportDataAsCsv();
  };

}
