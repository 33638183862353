import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NcrService {

  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }
  getNcrApproval(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'ncrapproval', data);
  }

  changeNcrRecord(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'ncrrecord', data);
  }
  getQualityArea(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'masterqualityarea', data);
  }
  
  addNcr(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/addNcr', data);
  }
  updateNcr(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.o3CoreApiUrl + 'NCR/updateNcr', data);
  }
  getDefectbyPlant(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/DefectByPlant', data);
  }
  getNcr(): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + 'NCR/ncr');
  }
  getNcrType(): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + 'NCR/getNCRTypes');
  }
  getOperationNumber(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/Productionplan', data);
  }
  getProcessOrder(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Product/GetProcessOrder', data);
  }
  changeNcrRecord1(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/NCRrecord', data);
  }
  getUnitOfMeasurement(): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + 'NCR/GetUnitOfMeasurement');
  }
  getncrspecification(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/getNcrSpecification', data);
  }
  getRCAStatus(data: any): Observable<any> {
    return this.http.post(this.o3CoreApiUrl + 'NCR/GetRCAStatus', data);
  }
  getFailureModeDetails(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/getAllFailureMode', data);
  }
  getCustomer(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Customer/GetCustomerData', data);
  }
  updateRemarks(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/updateNCRRemarks', data);
  }
  ncractionexecutionUpdate(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/updateCapaNCRActions', data);
  }
  updateStatus(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/addNCRStatus', data);
  }
  updateRCAStatus(data: any): Observable<any> {
    return this.http.post(this.o3CoreApiUrl + 'NCR/updateRCAStatus', data);
  }
  reOpenNCR(data: any): Observable<any> {
    return this.http.post(this.o3CoreApiUrl + 'NCR/ReOpenNCR', data);
  }
  SaveNcrCost(data: any) {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/SaveNcrCost', data);
  }
  getUom(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'UnitOfMeasure/GetMasterUnitOfMeasures', data);
  }
  deletencraction(data: any): any {
    return this.http.post(this.o3CoreApiUrl + 'NCR/deleteNCRActions', data);
  }
  ncrAction(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/addNCRActions', data);
  }
  updateNcraction(data: any): any {
    return this.http.post(this.o3CoreApiUrl + 'NCR/updateNCRActions', data);
  }
  getImplementCapa(data): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/GetImplementCapa', data);
  }
  allncrAction(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/getAllNCRActions', data);
  }
  deleteNcr(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/deleteNcr', data);
  }
  getFilteredData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/getFilterNCRData', data);
  }
  getNCRById(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/GetNCRById', data);
  }
  getncrfiles(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/getNcrFiles', data);
  }
  addncrfiles(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/addNcrFiles', data);
  }
  deletencrfiles(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/deleteNcrFiles', data);
  }
  getNCRHeader(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCRReportConfiguration/GetNCRHeaderName', data);
  }
  ncrHeaderFooterConfiguration(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCRReportConfiguration/nCR_CRUD_HeaderFooterConfiguration', data);
  }
  submitCheckedNCRreportHeader(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCRReportConfiguration/addOrEdit_CheckedNCRReportHeader', data);
  }
  getSubAndResultHeader(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCRReportConfiguration/get_SubandResultHeader', data);
  }
  getNcrReportData(data: any): Observable<any> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCRReportConfiguration/ncrReportData', data);
  }

}
