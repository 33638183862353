<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
  <p style="color: white">Fetching Data...</p>
</ngx-spinner>
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="displayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">PPC Live Stacker Data </h1>
    </div>
  </div>
  <form [formGroup]="ppcStackerData">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row">

        <div class="inputField">
          <nz-date-picker class="calender-input" id="picker" (ngModelChange)="getJobByStacker()"
            formControlName="startDate" [nzDisabledDate]="" nzFormat="yyyy-MM-dd"></nz-date-picker>
          <span class="floating-text" style="margin-left: 10px;">Start Date</span>
        </div>
        <div class="inputField">
          <nz-date-picker class="calender-input" id="picker" formControlName="endDate" (ngModelChange)="getJobByStacker()"
            [nzDisabledDate]="" nzFormat="yyyy-MM-dd"></nz-date-picker>
          <span class="floating-text" style="margin-left: 10px;">End Date</span>
        </div>

        <div class="inputField">
            <p-multiSelect inputId="multiselect" formControlName="stacker" [options]="machineName" optionLabel="MachineName"
              [filter]="true" (onChange)="getJobByStacker()" selectedItemsLabel="ALL" maxSelectedLabels={{machineName.length-1}}  defaultLabel="Select Stacker">
            </p-multiSelect> 
        </div>

        <div class="inputField">
            <p-multiSelect inputId="multiselect" formControlName="jon_no" [options]="jobList" optionLabel="JobNo1"
              [filter]="true" selectedItemsLabel="ALL" maxSelectedLabels={{jobList.length-1}}  defaultLabel="Select JobNo">
            </p-multiSelect> 
        </div>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Shift</mat-label>
          <mat-select panelClass="testClass" formControlName="shift" name="Shift" >
            <mat-option value="0">ALL</mat-option>
            <mat-option *ngFor="let s of shiftList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div class="content-top-row">

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Crew</mat-label>
          <mat-select panelClass="testClass" formControlName="crew" name="Crew" >
            <mat-option value="0">ALL</mat-option>
            <mat-option *ngFor="let s of crewList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <button type="button" mat-button mat-mini-fab color="primary" (click)="getLiteData();getStakerData()" pTooltip="Search Data" 
          tooltipPosition="top" style="margin-left: 10px;">
            <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </form>
  <br><br>
  <mat-accordion  style="margin-top: 20px;">
    <!-- Start Lite Data -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc1 disable_ripple_acc1">
        <mat-panel-title>
          PPC Lite Data
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="width: 100%; margin-top: 20px;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowLiteData" [columnDefs]="columnDefsLiteData"
            [defaultColDef]="defaultColDef" (gridReady)="onGridReadyLiteData($event)" [pagination]="true" [paginationPageSize]="50">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>
    <!-- End Lite Data -->
    <!-- Start Stackern Log -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc2 disable_ripple_acc2">
        <mat-panel-title>
          PPC Stacker Data
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="width: 100%; margin-top: 20px;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowStackerData" [columnDefs]="columnDefsStackerData"
            [defaultColDef]="defaultColDef" (gridReady)="onGridReadyStakerData($event)" [pagination]="true" [paginationPageSize]="50"
            [frameworkComponents]="frameworkComponents" (cellClicked)='oncellclicked($event)' [getRowStyle]="getRowStyle">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>
    <!-- End Stacker Log -->
  </mat-accordion>

  <ng-template #stackerModel>
    <!-- <button (click)="getSelectedRowData()">Click</button> -->
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2 *ngIf="shiftCrewId==0">Add Pallet Qty</h2>
        <h2 *ngIf="shiftCrewId!=0">Update Pallet Qty</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <hr>
    <mat-dialog-content>
      <form [formGroup]="StacketDataShiftCrew" (ngSubmit)="updatePalletQty()">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <div class="content-top-row">
                <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                    <!-- <mat-label>Date</mat-label>
                    <input type="text" formControlName="date" name="date" [disabled]='disable' matInput readonly> -->
                    <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                      <mat-label>StartPackTime</mat-label>
                      <input type="text" readonly matInput formControlName = "StartPackTime" name="StartPackTime">
                    </mat-form-field>
                  
                <!-- </mat-form-field> -->
        
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-label>Shift</mat-label>
                    <mat-select panelClass="testClass" formControlName="shift" name="Shift">
                      <mat-option value="0">Select Shift</mat-option>
                      <mat-option *ngFor="let s of shiftList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-label>Crew</mat-label>
                    <mat-select panelClass="testClass" formControlName="crew" name="Crew" [disabled]="crewStatus">
                      <mat-option value="0">Select Crew</mat-option>
                      <mat-option *ngFor="let s of crewList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label>Machine Name</mat-label>
                  <input type="text" readonly matInput formControlName = "MachineName" name="MachineName">
                </mat-form-field>

                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label>Order Number</mat-label>
                  <input type="text" readonly matInput formControlName = "OrderNumber" name="OrderNumber">
                </mat-form-field>
            </div>
            <div class="content-top-row">
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Batch Number</mat-label>
                <input type="text" readonly matInput formControlName = "BatchNumber" name="BatchNumber">
              </mat-form-field>

              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Stacker Status</mat-label>
                <mat-select panelClass="testClass" formControlName="StackerStatus" name="StackerStatus">
                  <mat-option value="OK">OK</mat-option>
                  <mat-option value="FAULT">FAULT</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Lite Count Actual</mat-label>
                <input type="number" [readonly]='IsLiteCountValid'  matInput formControlName = "LiteCount" name="LiteCount" (change)="calculateActualResut()">
              </mat-form-field>

              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Lite Count ERP</mat-label>
                <input type="text" readonly matInput formControlName = "LiteCountERP" name="LiteCountERP">
              </mat-form-field>
  
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Result Actual</mat-label>
                <input type="number" readonly matInput formControlName = "Result" name="Result">
              </mat-form-field>
            </div>
            <div class="content-top-row">
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Result ERP</mat-label>
                <input type="text" readonly matInput formControlName = "ResultERP" name="ResultERP">
              </mat-form-field>
              <button *ngIf="shiftCrewId==0" type="submit"  mat-mini-fab mat-button color="primary" pTooltip="Save" tooltipPosition="top" style="margin-left: 10px;">
                  <mat-icon>save</mat-icon>
              </button>
              <button *ngIf="shiftCrewId!=0" type="submit"  mat-mini-fab mat-button color="primary" pTooltip="Update" tooltipPosition="top" style="margin-left: 10px;">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>
      </form>
    </mat-dialog-content>
  
  </ng-template>
