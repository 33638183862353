import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Group } from '../models/group';
import { Plant } from '../models/plant';

@Injectable({
  providedIn: 'root',
})
export class DefectConfigurationService {
  baseUrl: string = environment.O3CoreApiUrl;
  kpibaseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) {}

  addDefects(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.kpibaseUrl + 'defects', data);
  }

  getGroupData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetGroup', data, {
      headers: header,
    });
  }

  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetDepartments', data);
  }

  getUnitByLine(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(this.baseUrl + 'DMS/GetUnitByLine', {
      headers: header,
      params: { Id: data.Id },
    });
  }

  getDefectTypeDLL(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetDefectTypeDDL', data, {
      headers: header,
    });
  }

  getSubDefectTypeDLL(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'DMS/GetSubDefectTypeDDL',
      data,
      { headers: header }
    );
  }

  GetDefectPriorityDDL(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'DMS/GetDefectPriorityDDL',
      data,
      { headers: header }
    );
  }

  GetDefectStatusDDL(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'DMS/GetDefectStatusDDL',
      data,
      { headers: header }
    );
  }

  GetDefectClassificationDDL(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(
      this.baseUrl + 'DMS/GetDefectClassificationDDL',
      data,
      { headers: header }
    );
  }

  GetDefectData(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetDefectData', data, {
      headers: header,
    });
  }

  getDefectfiles(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetDefectfiles', data, {
      headers: header,
    });
  }

  deleteDefectFiles(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/DeleteDefectFiles', data, {
      headers: header,
    });
  }

  addDefectfiles(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/AddDefectFiles', data, {
      headers: header,
    });
  }

  SaveDefectData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/InsertDefectData', data, {
      headers: header,
    });
  }

  UpdateDefectData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/UpdateDefectData', data, {
      headers: header,
    });
  }

  getCompModalTree(data: any): any {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.baseUrl + 'DMS/GetCompModelTree', data, {
      headers: header,
    });
  }

  deleteDefect(data: any): Observable<any[]> {
    const param = new HttpParams().set('id', data);
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'DMS/DeleteDefectData?' + param,
      { params: param }
    );
  }

  EmailDefectData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'DMS/EmailDefectData', data);
  }

  getDefects(): Observable<Group[]> {
    return this.http.get<Group[]>(this.kpibaseUrl + 'defectsdata');
  }

  getDefectReports(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.kpibaseUrl + 'defectreports', data);
  }

  getProducts(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.kpibaseUrl + 'unitproduct', data);
  }

  getProductsData(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.kpibaseUrl + 'products', data);
  }

  getParameters(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'parametergroups', data);
  }

  machineParameters(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'machinedefects', data);
  }

  addMachineParameters(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'defectparameter', data);
  }
  setDefectClosed(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'DefectAction/SetDefectClosed', data);
  }
}
