<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">TDC Analysis</h1>
    </div>
  </div>

  <div class="wrapper-box wrapper-box-shadow grid-container ">
    <form [formGroup]="TdcAnalysisForm">
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Select Group</mat-label>
          <mat-select panelClass="testClass" name="group" formControlName="group" (selectionChange)="getPlantByGroup()">
            <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
          </mat-select>
          <mat-error>Please select group</mat-error>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Select Plant</mat-label>
          <mat-select panelClass="testClass" name="plant" formControlName="plant"
            (selectionChange)="filterTdcAnalysis()">
            <mat-option *ngFor="let p of plants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
          </mat-select>
          <mat-error>Please select plant</mat-error>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Year</mat-label>
          <mat-select name="Year" formControlName="year" (selectionChange)="filterTdcAnalysis()">
            <mat-option *ngFor="let Year of yearList" [value]="Year">
              {{Year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <ag-grid-angular style="width: 100%; height: 40vw" class="ag-theme-alpine" [rowData]="rowData"
      [columnDefs]="columnDefs" [suppressRowTransform]="true" (cellValueChanged)="onCellValueChanged($event)"
      [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" [icons]="icons" [pagination]="true"
      [paginationPageSize]="paginationPageSize">
    </ag-grid-angular>

  </div>

</div>