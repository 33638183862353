import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OleService } from 'src/app/services/ole.service';
import { ProjectComponent } from '../project/project.component';
import { TaskComponent } from '../Task/Task.component';
import { TopicComponent } from '../Topic/Topic.component';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
@Component({
  selector: 'app-Master-ole',
  templateUrl: './Master-ole.component.html',
  styleUrls: ['./Master-ole.component.scss']
})
export class MasterOleComponent implements OnInit {
  @ViewChild(ProjectComponent) ProjectComponent!: ProjectComponent;
  @ViewChild(TaskComponent) TaskComponent!: TaskComponent;
  @ViewChild(TopicComponent) TopicComponent!: TopicComponent;
  searchOleMasterForm!: FormGroup;
  plants!: any;
  groupListDDL!: any[];
  breadcrumList!: any;
  IsAccess!: any;
  IsWrite!: any;
  permisson!: any;
  constructor( private fb: FormBuilder,private toaster: ToastrService,private OleService: OleService,private userManagementService: UserManagementService,public router: Router,private masterOleService: EnterPriseGroupService,) { }

  ngOnInit() {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.searchOleMasterForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.getGroupData();
  }
  getGroupData(): void {
    const key = {
      Id: 0
    };
    // tslint:disable-next-line: no-debugger
    this.OleService.getGroupData(key).subscribe((data: any) => {
      this.groupListDDL = data.masterList;
      this.searchOleMasterForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
      this.getPlantByGroup();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.searchOleMasterForm.get('group')?.value
    };
    // this.MachineId = null;
    this.OleService.getPlantsData(key).subscribe((data: any) => {
      this.plants = data.DepartList;
      this.searchOleMasterForm.get('plant')?.setValue(this.plants[0].DeptId);
      this.getMasterData();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getMasterData(): void {
    const plant = this.searchOleMasterForm.get('plant')?.value;
    const group = this.searchOleMasterForm.get('group')?.value;
    const key = {
      plantId: plant,
      groupId: group
    };
    this.ProjectComponent.getProjectMaster(key);
    this.TopicComponent.getTopicMaster(key);
    this.TaskComponent.getTaskMaster(key);
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.masterOleService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  } 
}
