import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SuccessMessage } from '../models/successMessage';
@Injectable({
  providedIn: 'root'
})
export class ChecklistService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }
  getTypes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'qualitytypes');
  }

  updateCheckListdataById(checkList: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'qualitychecklist', checkList);
  }

  saveCheckListType(data: any) {
    return this.http.post(this.o3CoreApiUrl + 'QualityTypes/CreateQualityType', data);
  }

  editCheckListType(data: any) {
    return this.http.post(this.o3CoreApiUrl + 'QualityTypes/UpdateQualityTypes', data);
  }

  deleteCheckListType(data: any) {
    return this.http.post(this.o3CoreApiUrl + 'QualityTypes/DeleteQualityTypes', data);
  }

  getTypesByPlant(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'QualityTypes/GetAllQualityTypes', data);
  }

  getCheckListforQualityType(data: any) {
    return this.http.post(this.o3CoreApiUrl + 'QualityTypes/GetQualityTypesbyChecklist', data);
  }

  savedropdownitem(data:any){
    return this.http.post(this.o3CoreApiUrl + 'QualityChecklist/SaveDropDownItem', data);
  }

  getdropdowndatabyId(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'QualityCheckList/GetdropdowndatabyDetailId', data)
  }

  getItemTypes(): Observable<any> {
    return this.http.get<any>(this.o3CoreApiUrl + 'QualityTypes/GetItemTypes');
  }

  getCheckList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'QualityChecklist/GetAllQualityCheckLists', data);
  }

  updateCheckListdata(checkList: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/UpdateNewQualityCheckList', checkList);
  }

  addCheckListdata(checkList: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/AddNewQualityCheckList', checkList);
  }

  getCheckListById(checkList: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/GetQualityCheckListById', checkList);
  }

  deleteItemdropdown(data: any) {
    return this.http.post(this.o3CoreApiUrl + 'QualityChecklist/DeleteDropdownElement', data);
  }

  deletechecklist(data: any): Observable<SuccessMessage> {
    return this.http.post<SuccessMessage>(this.o3CoreApiUrl + 'QualityChecklist/DeleteQualityList', data);
  }

  CheckList_CRUD_HeaderFooterConfiguration(data: any) {
    return this.http.post(this.o3CoreApiUrl + 'CheckListHeaderFooter/CheckList_CRUD_HeaderFooterConfiguration', data);
  }

  DuplicateChecklist(data: any) {
    return this.http.post(this.o3CoreApiUrl + 'QualityChecklist/DuplicateCheckList', data);
  }

  getHearderAndSubHeader(data: any) {
    return this.http.post(this.o3CoreApiUrl + 'CheckListHeaderFooter/GetHearderAndSubHeader', data);
  }

  UpdateChecklistHeaderSubHeader(data: any) {
    return this.http.post(this.o3CoreApiUrl + 'CheckListHeaderFooter/UpdateChecklistHeaderSubHeader', data);
  }

  GetCheckListReport(data: any): Observable<any> {
    return this.http.post(this.o3CoreApiUrl + 'CheckListHeaderFooter/GetCheckListReport', data);
  }

  GetChecklistExecutionImages(data: any) {
    return this.http.post(this.o3CoreApiUrl + 'QualityCheckExecution/GetChecklistExecutionImages', data);
  }
}