<div class="sfdc-configuration-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">Defect Management Master Data</h1>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
    <p style="color: white">Fetching Data...</p>
  </ngx-spinner>
  <form [formGroup]="defectmainDataForm">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row content-top-row-order-processing">

        <!-- GROUP -->
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Group</mat-label>
          <mat-select formControlName="group" placeholder="Group" panelClass="testClass" name="group"
            (selectionChange)="getPlants($event.value)">
            <mat-option *ngFor="let group of GroupList" [value]="group.id">
              {{group.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Plant -->
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Plant</mat-label>
          <mat-select formControlName="plant" placeholder="Plant" panelClass="testClass" name="plant"
            (selectionChange)="onPlantChange()">
            <mat-option *ngFor="let plant of PlantList" [value]="plant.id">
              {{plant.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button type="button" (click)="onPlantChange()" mat-mini-fab mat-button color="primary" pTooltip="Refresh"
          tooltipPosition="top" style="margin-left: 20px;margin-top: 5px;">
          <i class="fa fa-refresh" style="color: #fff"></i>
        </button>
      </div>

    </div>

  </form>



  <!-- Accordian -->
  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc1" class="disable_ripple_acc1">
        <mat-panel-title>
          Defect Type
        </mat-panel-title>

      </mat-expansion-panel-header>

      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <form [formGroup]="defecttypeDataForm" (ngSubmit)="saveDefectType()">
          <div class="content-top-row">
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Defect Type</mat-label>
              <input matInput formControlName="type">
              <mat-error class="error-msg" *ngIf="checkError('type', 'required')">Type is required</mat-error>
            </mat-form-field>
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Defect Type Order</mat-label>
              <input matInput formControlName="typeorder" type="number">
              <mat-error class="error-msg" *ngIf="checkError('typeorder', 'required')">Type Order is required
              </mat-error>
            </mat-form-field>
            <button [disabled]="!defecttypeDataForm.valid" mat-mini-fab mat-button color="primary" type="submit"
              pTooltip="Save" tooltipPosition="top" style="margin-left: 20px;" *ngIf="DefectTypeformMode && IsWrite">
              <i class="fa fa-save" style="color: #fff"></i>
            </button>
            <button [disabled]="!defecttypeDataForm.valid" mat-mini-fab mat-button color="primary" type="submit"
              pTooltip="Update" tooltipPosition="top" style="margin-left: 20px;" *ngIf="!DefectTypeformMode && IsWrite">
              <i class="fa fa-pencil" style="color: #fff"></i>
            </button>
            <button [disabled]="!defecttypeDataForm.valid" (click)="resetDefectType()" mat-mini-fab mat-button
              color="primary" pTooltip="Reset" tooltipPosition="top" style="margin-left: 20px;">
              <i class="fa fa-refresh" style="color: #fff"></i>
            </button>
          </div>
        </form>
        <!--second row-->
        <mat-form-field appearance="fill" class="field-fill"
          style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <mat-label>Search</mat-label>
          <input [readonly]="false" matInput class="input-text" (keyup)="applyFilterForDefectType($event)"
            autocomplete="off" />
        </mat-form-field>
        <div class="content-row-flex-both-end">
          <!--table-->
          <table mat-table class="basic-table plant-model-table" [dataSource]="defectTypeList" matSort>
            <ng-container matColumnDef="plant">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Plant</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.plantName}}</td>
            </ng-container>

            <ng-container matColumnDef="defect">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Defect Type</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <ng-container matColumnDef="order">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Order</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.order}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                <p class="header-text">Actions</p>
              </th>
              <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                <div class="row-action-btns">
                  <button mat-icon-button *ngIf="IsWrite" class="edit-btn" (click)="editDefctType(element)"
                    aria-label="Edit Row">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                        fill="white" />
                    </svg>
                  </button>
                  <button *ngIf="IsWrite" mat-icon-button (click)="deleteRecord(element,'Defect Type')"
                    class="node-control-btn node-delete" aria-label="delete item">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                        fill="white" />
                    </svg>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="defecttypecolumns"></tr>
            <tr mat-row *matRowDef="let row; columns: defecttypecolumns"></tr>
          </table>
        </div>
        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5,10, 20]" showFirstLastButtons></mat-paginator>
      </div>

    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc2" class="disable_ripple_acc2">
        <mat-panel-title>
          Sub Defect Type
        </mat-panel-title>

      </mat-expansion-panel-header>
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <form [formGroup]="subdefectDataForm" (ngSubmit)="saveSubDefectType()">
          <div class="content-top-row">
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Sub Defect Type</mat-label>
              <input matInput formControlName="type">
              <mat-error class="error-msg" *ngIf="checkErrorSub('type', 'required')">Sub Defect Type is required
              </mat-error>
            </mat-form-field>
            <button *ngIf="IsWrite" mat-mini-fab mat-button mat-dialog-close color="accent" (click)="openModal()"
              pTooltip="Map Subdefects" tooltipPosition="top" style="margin-left: 20px;">
              <i class="fa fa-plus" style="color: #fff"></i>
            </button>
            <button [disabled]="!subdefectDataForm.valid" mat-mini-fab mat-button color="primary" type="submit"
              pTooltip="Save" tooltipPosition="top" style="margin-left: 20px;" *ngIf="SubDefectTypeformMode && IsWrite">
              <i class="fa fa-save" style="color: #fff"></i>
            </button>
            <button [disabled]="!subdefectDataForm.valid" mat-mini-fab mat-button color="primary" type="submit"
              pTooltip="Update" tooltipPosition="top" style="margin-left: 20px;"
              *ngIf="!SubDefectTypeformMode && IsWrite">
              <i class="fa fa-pencil" style="color: #fff"></i>
            </button>
            <button [disabled]="!subdefectDataForm.valid" (click)="resetsubDefect()" mat-mini-fab mat-button
              color="primary" pTooltip="Reset" tooltipPosition="top" style="margin-left: 20px;">
              <i class="fa fa-refresh" style="color: #fff"></i>
            </button>
          </div>
        </form>
        <!--second row-->
        <mat-form-field appearance="fill" class="field-fill"
          style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <mat-label>Search</mat-label>
          <input [readonly]="false" matInput class="input-text" (keyup)="applyFilterForSubDefectType($event)"
            autocomplete="off" />
        </mat-form-field>
        <div class="content-row-flex-both-end">
          <!--table-->
          <table mat-table class="basic-table plant-model-table" [dataSource]="subDefectTypeList" matSort>

            <!-- <ng-container matColumnDef="group">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Group</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.egName}}</td>
            </ng-container> -->


            <ng-container matColumnDef="plant">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Plant</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.plantName}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">ID</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.id}}</td>
            </ng-container> -->

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Sub Defect Type</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                <p class="header-text">Actions</p>
              </th>
              <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                <div class="row-action-btns">
                  <button mat-icon-button *ngIf="IsWrite" class="edit-btn" (click)="editDefctSubType(element)"
                    aria-label="Edit Row">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                        fill="white" />
                    </svg>
                  </button>
                  <button mat-icon-button *ngIf="IsWrite" (click)="deleteRecord(element,'Defect Sub Type')"
                    class="node-control-btn node-delete" aria-label="delete item">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                        fill="white" />
                    </svg>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="subdefectcolumns"></tr>
            <tr mat-row *matRowDef="let row; columns: subdefectcolumns"></tr>
          </table>
        </div>
        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5,10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </mat-expansion-panel>

    <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc3" class="disable_ripple_acc3">
        <mat-panel-title>
          Defect Status
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-row-flex-both-end">
        
          <table mat-table class="basic-table plant-model-table" [dataSource]="defectStatusList" matSort>

            <ng-container matColumnDef="defect">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Defect Status</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="defectstatuscolumns"></tr>
            <tr mat-row *matRowDef="let row; columns: defectstatuscolumns"></tr>
          </table>
        </div>
      </div>
    </mat-expansion-panel> -->

    <!--fourth accordian-->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc4" class="disable_ripple_acc4">
        <mat-panel-title>
          Defect Priority
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <form [formGroup]="defectpriorityDataForm" (ngSubmit)="saveDefectPriority()">
          <div class="content-top-row" style="margin-top: 30px;">
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Defect Priority</mat-label>
              <input matInput formControlName="dpriority">
              <mat-error class="error-msg" *ngIf="checkErrorDefectPriority('dpriority', 'required')">Defect priority is required</mat-error>
            </mat-form-field>
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Defect Priority Order</mat-label>
              <input matInput formControlName="dpriorityorder" type="number">
              <mat-error class="error-msg" *ngIf="checkErrorDefectPriority('dpriorityorder', 'required')">
                Defect priority order is required</mat-error>
            </mat-form-field>
            <button [disabled]="!defectpriorityDataForm.valid" mat-mini-fab mat-button color="primary" type="submit"
              pTooltip="Save" tooltipPosition="top" style="margin-left: 20px;"
              *ngIf="defectPriorityformMode && IsWrite">
              <i class="fa fa-save" style="color: #fff"></i>
            </button>
            <button [disabled]="!defectpriorityDataForm.valid" mat-mini-fab mat-button color="primary" type="submit"
              pTooltip="Update" tooltipPosition="top" style="margin-left: 20px;"
              *ngIf="!defectPriorityformMode && IsWrite">
              <i class="fa fa-pencil" style="color: #fff"></i>
            </button>
            <button [disabled]="!defectpriorityDataForm.valid" (click)="resetDefectpriority()" mat-mini-fab mat-button
              color="primary" pTooltip="Reset" tooltipPosition="top" style="margin-left: 20px;">
              <i class="fa fa-refresh" style="color: #fff"></i>
            </button>
          </div>

        </form>

        <mat-form-field appearance="fill" class="field-fill"
          style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <mat-label>Search</mat-label>
          <input [readonly]="false" matInput class="input-text" (keyup)="applyFilterForDefectPriorityType($event)"
            autocomplete="off" />
        </mat-form-field>
        <div class="content-row-flex-both-end">
          <!--table-->
          <table mat-table class="basic-table plant-model-table" [dataSource]="defectPriorityList" matSort>

            <ng-container matColumnDef="plant">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Plant</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.plantName}}</td>
            </ng-container>

            <ng-container matColumnDef="defect">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Defect Priority</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <ng-container matColumnDef="order">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Order</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.order}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                <p class="header-text">Actions</p>
              </th>
              <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                <div class="row-action-btns">
                  <button mat-icon-button *ngIf="IsWrite" class="edit-btn" (click)="editDefctPriority(element)"
                    aria-label="Edit Row">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                        fill="white" />
                    </svg>
                  </button>
                  <button mat-icon-button *ngIf="IsWrite" (click)="deleteRecord(element,'Defect Priority')"
                    class="node-control-btn node-delete" aria-label="delete item">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                        fill="white" />
                    </svg>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="defectprioritycolumns"></tr>
            <tr mat-row *matRowDef="let row; columns: defectprioritycolumns"></tr>
          </table>
        </div>
        <mat-paginator #paginator class="basic-paginataor" [pageSizeOptions]="[5,10, 20]" showFirstLastButtons>
        </mat-paginator>
      </div>
    </mat-expansion-panel>

    <!--fifth accordian-->
    <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc5" class="disable_ripple_acc5">
        <mat-panel-title>
          Defect Classification
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <form [formGroup]="defectclassificationDataForm" (ngSubmit)="saveDefectClassification()">
          <div class="content-top-row" style="margin-top: 30px;">
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <input matInput formControlName="classification" placeholder="Classification">
              <mat-error class="error-msg" *ngIf="checkErrorDefectClassification('classification', 'required')">
                Classification is required</mat-error>
            </mat-form-field>
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <input matInput formControlName="classificationorder" type="number" placeholder="Classification Order">
              <mat-error class="error-msg" *ngIf="checkErrorDefectClassification('classificationorder', 'required')">
                Classification order is required</mat-error>
            </mat-form-field>
            <button [disabled]="!defectclassificationDataForm.valid" mat-mini-fab mat-button color="primary"
              type="submit" pTooltip="Save" tooltipPosition="top" style="margin-left: 20px;"
              *ngIf="defectClassificationformMode && IsWrite">
              <i class="fa fa-save" style="color: #fff"></i>
            </button>
            <button [disabled]="!defectclassificationDataForm.valid" mat-mini-fab mat-button color="primary"
              type="submit" pTooltip="Update" tooltipPosition="top" style="margin-left: 20px;"
              *ngIf="!defectClassificationformMode && IsWrite">
              <i class="fa fa-pencil" style="color: #fff"></i>
            </button>
            <button [disabled]="!defectclassificationDataForm.valid" (click)="resetDefectclassification()" mat-mini-fab
              mat-button color="primary" pTooltip="Reset" tooltipPosition="top" style="margin-left: 20px;">
              <i class="fa fa-refresh" style="color: #fff"></i>
            </button>
          </div>

        </form>
        <mat-form-field appearance="fill" class="field-fill"
          style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <mat-label>Search</mat-label>
          <input [readonly]="false" matInput class="input-text" (keyup)="applyFilterForDefectClassification($event)"
            autocomplete="off" />
        </mat-form-field>
        <div class="content-row-flex-both-end">

          <table mat-table class="basic-table plant-model-table" [dataSource]="defectClassificationList" matSort>

            <ng-container matColumnDef="plant">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Plant</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.plantName}}</td>
            </ng-container>

            <ng-container matColumnDef="defect">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Defect Classification</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <ng-container matColumnDef="order">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Order</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.order}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                <p class="header-text">Actions</p>
              </th>
              <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                <div class="row-action-btns">
                  <button mat-icon-button *ngIf="IsWrite" class="edit-btn" (click)="editDefctClassification(element)"
                    aria-label="Edit Row">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                        fill="white" />
                    </svg>
                  </button>
                  <button mat-icon-button *ngIf="IsWrite" class="node-control-btn node-delete" aria-label="delete item"
                    (click)="deleteRecord(element,'Classifcation')">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                        fill="white" />
                    </svg>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="defectclassificationcolumns"></tr>
            <tr mat-row *matRowDef="let row; columns: defectclassificationcolumns"></tr>
          </table>
        </div>
        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5,10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </mat-expansion-panel> -->


    <!--sixth accordian-->
    <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc6" class="disable_ripple_acc6">
        <mat-panel-title>
          Defect Type Role Group
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <form [formGroup]="defecttyperoleDataForm" (ngSubmit)="saveDefectTypeRoleGroup()">

          <div class="content-top-row" style="margin-top: 30px;">
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-select formControlName="master" placeholder="Defect Master" panelClass="testClass" name="master">
                <mat-option [value]="0">Select master</mat-option>
                <mat-option [value]="1">Defect Type</mat-option>
                <mat-option [value]="2">Priority</mat-option>
                <mat-option [value]="3">Classification</mat-option>
              </mat-select>
              <mat-error class="error-msg" *ngIf="checkErrorDefectTypeRole('master', 'required')">Master is required</mat-error>
            </mat-form-field>
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <input matInput formControlName="role" placeholder="Role Group Name">
              <mat-error class="error-msg" *ngIf="checkErrorDefectTypeRole('role', 'required')">Role is required</mat-error>
            </mat-form-field>
            <button  [disabled]="!defecttyperoleDataForm.valid" mat-mini-fab mat-button color="primary" type="submit"  pTooltip="Save" tooltipPosition="top" style="margin-left: 20px;" *ngIf="defecttyperoleformMode && IsWrite" >
              <i class="fa fa-save" style="color: #fff"></i>
            </button>
            <button [disabled]="!defecttyperoleDataForm.valid" mat-mini-fab mat-button color="primary" type="submit" pTooltip="Update" tooltipPosition="top" style="margin-left: 20px;" *ngIf="!defecttyperoleformMode && IsWrite" >
              <i class="fa fa-pencil" style="color: #fff"></i>
            </button>
            <button [disabled]="!defecttyperoleDataForm.valid" (click)="resetDefecttyperole()" mat-mini-fab mat-button color="primary" pTooltip="Reset" tooltipPosition="top" style="margin-left: 20px;" >
              <i class="fa fa-refresh" style="color: #fff"></i>
            </button>
           
          </div>

        </form>
        <mat-form-field appearance="fill" class="field-fill" style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <mat-label>Search</mat-label>
          <input [readonly]="false" matInput class="input-text" (keyup)="applyFilterForDefectRoleGroup($event)" autocomplete="off" />
        </mat-form-field>
        <div class="content-row-flex-both-end">
        
          <table mat-table class="basic-table plant-model-table" [dataSource]="defectRoleGroupList" matSort>

            <ng-container matColumnDef="plant">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Plant</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.plantName}}</td>
            </ng-container>

            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Role Group</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.mdType}}</td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <p class="header-text">Type</p>
              </th>
              <td mat-cell *matCellDef="let element">{{element.roleName}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                <p class="header-text">Actions</p>
              </th>
              <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                <div class="row-action-btns">
                  <button mat-icon-button *ngIf="IsWrite" class="edit-btn" (click)="editDefctRole(element)" aria-label="Edit Row"  >
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                        fill="white" />
                    </svg>
                  </button>
                  <button mat-icon-button *ngIf="IsWrite" (click)="deleteRecord(element,'Defect Role')" class="node-control-btn node-delete" aria-label="delete item">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                        fill="white" />
                    </svg>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="defectrolecolumns"></tr>
            <tr mat-row *matRowDef="let row; columns: defectrolecolumns"></tr>
          </table>
        </div>
        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5,10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </mat-expansion-panel> -->

    <!--seventh accordian-->
    <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header class="acc7" class="disable_ripple_acc7">
            <mat-panel-title>
              Role Group Users
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <form [formGroup]="rolegroupDataForm" (ngSubmit)="saveRoleGroupUsers()">
    
              <div class="content-top-row" style="margin-top: 30px;">
                <mat-form-field appearence="fill"
                  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-select formControlName="role" placeholder="Role Group" panelClass="testClass" name="role">
                    <mat-option *ngFor="let group of defectRoleGroupsList" [value]="group.id">
                     {{group.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error-msg" *ngIf="checkErrorDefectRoleGroup('role', 'required')">Role is required</mat-error>
                </mat-form-field>
                <mat-form-field appearence="fill"
                  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-select formControlName="user" placeholder="Users" panelClass="testClass" name="user" multiple>
                    <mat-option *ngFor="let user of defectRoleGroupUsersList" [value]="user.id" >
                    {{user.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error-msg" *ngIf="checkErrorDefectRoleGroup('user', 'required')">User is required</mat-error>
                </mat-form-field>
                <button  [disabled]="!rolegroupDataForm.valid" mat-mini-fab mat-button color="primary" type="submit"  pTooltip="Save" tooltipPosition="top" style="margin-left: 20px;" *ngIf="IsWrite" >
                  <i class="fa fa-save" style="color: #fff"></i>
                </button>
                <button type="button" [disabled]="!rolegroupDataForm.valid" (click)="resetroleGroup()" mat-mini-fab mat-button color="primary" pTooltip="Reset" tooltipPosition="top" style="margin-left: 20px;" >
                  <i class="fa fa-refresh" style="color: #fff"></i>
                </button>
           
              </div>
    
            </form>
            <mat-form-field appearance="fill" class="field-fill" style="display: flex; justify-content: flex-end; margin-top: 10px;">
              <mat-label>Search</mat-label>
              <input [readonly]="false" matInput class="input-text" (keyup)="applyFilterForDefectRoleGroupUser($event)" autocomplete="off" />
            </mat-form-field>
            <div class="content-row-flex-both-end">
             
              <table mat-table class="basic-table plant-model-table" [dataSource]="defectRoleGroupsUsersList" matSort>
    
                <ng-container matColumnDef="plant">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <p class="header-text">Plant</p>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.plantName}}</td>
                </ng-container>
    
                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <p class="header-text">Role Group</p>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.roleGroupName}}</td>
                </ng-container>
    
                <ng-container matColumnDef="user">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <p class="header-text">Users</p>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.userNameList}}</td>
                </ng-container>
    
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>
                    <p class="header-text">Actions</p>
                  </th>
                  <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                    <div class="row-action-btns">
                      <button mat-icon-button *ngIf="IsWrite" class="node-control-btn node-delete" aria-label="delete item" (click)="deleteRecord(element,'Role Users')">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                            fill="white" />
                        </svg>
                      </button>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="rolegroupcolumns"></tr>
                <tr mat-row *matRowDef="let row; columns: rolegroupcolumns"></tr>
              </table>
            </div>
            <mat-paginator class="basic-paginataor" [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
          </div>
        </mat-expansion-panel> -->
  </mat-accordion>
</div>
<ng-template #displayDefectMapping>
  <form [formGroup]="mapDataForm" (ngSubmit)="saveSubDefectMappingType()">
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>Map SubDefects</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <mat-dialog-content>
      <mat-form-field appearance="fill" class="field-fill inputs">
        <mat-label>Defect Type</mat-label>
        <mat-select name="defect" formControlName="defect">
          <mat-option *ngFor="let defectTypeDDL of defectTypeDDLList" [value]="defectTypeDDL.id">{{defectTypeDDL.name}}
          </mat-option>
        </mat-select>
        <mat-error class="error-msg" *ngIf="checkErrorSubDefectMap('defect', 'required')">Defect Type is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="field-fill inputs">
        <mat-label>Sub Defect Type</mat-label>
        <mat-select name="subdefect" formControlName="subdefect" multiple>
          <mat-option *ngFor="let subDefectDDL of subDefectTypeDDLList" [value]="subDefectDDL.id"
            (onSelectionChange)="change($event)">{{subDefectDDL.name}}</mat-option>
        </mat-select>
        <mat-error class="error-msg" *ngIf="checkErrorSubDefectMap('subdefect', 'required')">Sub Defect Type is required
        </mat-error>
      </mat-form-field>
    </mat-dialog-content>

    <div mat-dialog-actions style="float: right; margin-top: 10px">
      <button [disabled]="!mapDataForm.valid" mat-mini-fab mat-button color="primary" type="submit" pTooltip="Save"
        tooltipPosition="top" style="margin-left: 20px;" *ngIf="IsWrite">
        <i class="fa fa-save" style="color: #fff"></i>
      </button>
    </div>
  </form>
</ng-template>