import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
} from '@angular/forms';
import { NcrPlantWiseActionModel } from 'src/app/models/NcrPlantWiseActionModel';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ImmediateActionService } from 'src/app/services/immediateAction.service';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { UserManu } from 'src/app/models/userManu';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpitreeService } from 'src/app/services/kpitree.service';

@Component({
  selector: 'app-PlantWiseImmediateNcrActions',
  templateUrl: './PlantWiseImmediateNcrActions.component.html',
  styleUrls: ['./PlantWiseImmediateNcrActions.component.scss']
})
export class PlantWiseImmediateNcrActionsComponent implements OnInit {
  @Input() pPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<NcrPlantWiseActionModel>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ncrActionId!: number;
  plants!: Plantbygroup[];
  roles!:any[];
  immediateActions!:any[];
  ncrActionForm!: FormGroup;
  levelRoleArray!: FormArray;
  formMode!: boolean;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  plantId!: number;
  egId!:number;
  updateNcrData:any;
  ncrActionByName!:any[];
  ncrActionPlantModelVisible = false;
  ncrActionColumns: any[] = ['ncrImmediateActions' , 'plant', 'name', 'levels'];
  ncrActionData!: MatTableDataSource<NcrPlantWiseActionModel>;
  IsAccess: any;
  IsWrite: any;
  moduleId: any;
  pageId: any;
  constructor(
    private fb: FormBuilder,
    private kpiService: KpimasterService,
    private kpiservice: KpitreeService,
    private immediateActionService: ImmediateActionService,
    private platModelService: EnterPriseGroupService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.GetMenuAccess();

    this.isRead = true;
    this.IsWrite = true;

    this.isDelete=true;
    this.formMode = true;

    this.ncrActionForm = this.fb.group({
      id:[0],
      plant: ['', [Validators.required]],
      action: ['', [Validators.required]],
      approvaltype: ['', [Validators.required]],
      levelsofapproval: ['', [Validators.required]],
      levelwiseroles: this.fb.array([])
    });

    this.levelRoleArray = this.ncrActionForm.get(
      'levelwiseroles'
    ) as FormArray;
  }
  createLevelWiseElementArray(ele: any): FormGroup {
    if(this.formMode == true)
    {
      let mynum=Number(ele)+1;
      let myname='Level'+mynum;
      if(this.roles.length>0){
        return this.fb.group({
          id:[0],
          level: [myname, Validators.required],
          role: [this.roles[0].roleId, Validators.required]
        });
      }
      else
      {
        return this.fb.group({
          id:[0],
          level: [myname, Validators.required],
          role: ['', Validators.required]
        });
      }
    }
    else
    {
      let mynum=Number(ele)+1;
      let myname='Level'+mynum;
      if(ele<this.updateNcrData.levelDetail.length)
      {
        return this.fb.group({
          id:[this.updateNcrData.levelDetail[ele].levelId],
          level: [this.updateNcrData.levelDetail[ele].levelName, Validators.required],
          role: [this.updateNcrData.levelDetail[ele].roleId, Validators.required]
        });
      }
      else
      {
        return this.fb.group({
          id:[0],
          level: [myname, Validators.required],
          role: ['', Validators.required]
        });
      }
    }
  }

  addNewRow(): any {
    let ele = this.ncrActionForm.value.levelsofapproval;
    this.levelRoleArray.clear();
    if(ele > 20)
    {
      this.ncrActionForm.get('levelsofapproval').setValue('');
    }
    else{
      for(let i=0; i< ele; i++)
      {
        this.levelRoleArray.push(this.createLevelWiseElementArray(i));
      }
    }
  }

  get repeatLevelWiseElementForm(): any {
    return this.ncrActionForm.get(
      'levelwiseroles'
    ) as FormArray;
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getPaginator(): void {
    setTimeout(() => {
      this.ncrActionData.paginator = this.paginator;
      this.ncrActionData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ncrActionData.filter = filterValue.trim().toLowerCase();

    if (this.ncrActionData.paginator) {
      this.ncrActionData.paginator.firstPage();
    }
  }

  getPlants(): void {
    this.kpiService.getAllPlants().subscribe(
      (plants) => {
        this.plants = plants;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getRoles(): any {
    debugger
    const key = {
      EGId: this.egId,
      PlantId: this.plantId
    };
    this.kpiservice.getPositionMasterData(key).then(
      (responsibleroles: any) => {
        this.roles = responsibleroles.positionMasterList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getImmediateActions(): void {
    this.immediateActionService.getAllImmediateActions().subscribe(
      (immediateActions) => {
        this.immediateActions = immediateActions;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getPlantWiseLocationMaster(key: any): void {
    debugger
    this.spinner.show();
    this.plantId = key.plantId;
    this.egId = key.egId;
    this.immediateActionService.getImmediateActionPlantWiseMasterData(key).subscribe(
      (ncrActions) => {
        this.ncrActionData = new MatTableDataSource<NcrPlantWiseActionModel>(ncrActions);
        this.getPlants();
        this.getRoles();
        this.getImmediateActions();
        this.getPaginator();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      }
    );
  }

  getActions(): void {
    debugger
    this.spinner.show();

    const key = {
      plantId: this.plantId,
    };
    this.immediateActionService.getImmediateActionPlantWiseMasterData(key).subscribe(
      (ncrActions) => {
        this.spinner.hide();

        this.ncrActionData = new MatTableDataSource<NcrPlantWiseActionModel>(ncrActions);
        this.getPaginator();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error(error.error.message);
      }
    );
  }

  openModal(): void {
    if (this.IsWrite) {
      this.ncrActionForm.reset();
      this.levelRoleArray.clear();
      this.ncrActionForm.get('plant')?.setValue(this.plantId);
      this.formMode = true;
      this.ncrActionPlantModelVisible = true;
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  submitForm(): void {
    if(this.ncrActionForm.valid)
    {
      this.spinner.show();
      let CreatedOn=new Date();
      const instance = {
        plantId: this.ncrActionForm.value.plant,
        immediateActionId: this.ncrActionForm.value.action,
        approvalType:this.ncrActionForm.value.approvaltype,
        levels: this.ncrActionForm.value.levelsofapproval,
        levelWiseRoleDetails:this.ncrActionForm.value.levelwiseroles,
        createdBy: JSON.parse(localStorage.user).UserId,
        createdOn: new Date(CreatedOn.getUTCFullYear(),
        CreatedOn.getUTCMonth(),
        CreatedOn.getUTCDate(),
        CreatedOn.getUTCHours(),
        CreatedOn.getUTCMinutes(),
        CreatedOn.getUTCSeconds()
        ),
      };
      this.immediateActionService.saveNcrImmediateAction(instance).subscribe(
        (response) => {
          this.spinner.hide();
          if(response.error == 'false')
          {
            this.toaster.success('Success', 'Ncr Action Added Successfully');
            this.getActions();
            this.closeActionMasterModel();
            this.resetForm();
          }
          else{
            this.toaster.error('Error', response.message);
          }
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    }
  }

  updateForm(): void {
    debugger
    if(this.ncrActionForm.valid)
    {
      this.spinner.show();
      let CreatedOn=new Date();
      const instance = {
        id:this.ncrActionForm.value.id,
        plantId: this.ncrActionForm.value.plant,
        immediateActionId: this.ncrActionForm.value.action,
        approvalType:this.ncrActionForm.value.approvaltype,
        levels: this.ncrActionForm.value.levelsofapproval,
        levelWiseRoleDetails:this.ncrActionForm.value.levelwiseroles,
        createdBy: JSON.parse(localStorage.user).UserId,
        createdOn: new Date(CreatedOn.getUTCFullYear(),
        CreatedOn.getUTCMonth(),
        CreatedOn.getUTCDate(),
        CreatedOn.getUTCHours(),
        CreatedOn.getUTCMinutes(),
        CreatedOn.getUTCSeconds()
        ),
      };
      this.immediateActionService.updateNcrImmediateAction(instance).subscribe(
        (response) => {
          this.spinner.hide();
          if(response.error == false)
          {
            this.toaster.success('Success', 'Ncr Action Updated Successfully');
            this.getActions();
            this.closeActionMasterModel();
            this.resetForm();
          }
          else{
            this.toaster.error('Error', response.message);
          }
        },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.error.message);
        }
      );
    }
  }

  deleteNcrAction(ncrActionId: number): void {
    debugger
    if (this.isDelete) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete action!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          const key = {
            immediateActionId:ncrActionId,
            plantId:this.plantId
          };
          this.immediateActionService.deletePlantWiseImmediateAction(key).subscribe(
            (success) => {
              if(success[0].Error == 'false')
              {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'Your selected action has been deleted.',
                  icon: 'success',
                  timer: 800,
                  showConfirmButton: false,
                });
              }
              else
              {
                this.toaster.error('Error', 'It cannot be deleted because an other table is using this action.');
              }
              this.spinner.hide();

              this.getActions();
            },
            (error) => {
          this.spinner.hide();

              this.toaster.error('Error', error.error.message);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your selected action is safe :)', 'error');
        }
      });
    } else {
      this.spinner.hide();

      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  getOneNcrAction(ncrActionId: number): void {
    debugger
    this.isUpdate = true;
    if (this.isUpdate) {
      const instance = {
        ImmediateActionId:ncrActionId,
        PlantId:this.plantId
      };
      this.immediateActionService.getOnePlantWiseImmediateAction(instance).subscribe(
        (ncrAction) => {
          this.updateNcrData=ncrAction;
          this.formMode = false;
          this.ncrActionForm.get('id')?.setValue(ncrAction.id);
          this.ncrActionForm.get('plant')?.setValue(this.plantId);
          this.ncrActionForm.get('action')?.setValue(ncrAction.actionId);
          this.ncrActionForm.get('approvaltype')?.setValue(ncrAction.approvalType);
          this.ncrActionForm.get('levelsofapproval')?.setValue(ncrAction.levelsOfApproval);
          this.addNewRow();
          this.ncrActionPlantModelVisible = true;
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  resetForm(): void {
    this.ncrActionForm.reset();
  }

  closeActionMasterModel(): void {
    this.ncrActionPlantModelVisible = false;
  }

}
