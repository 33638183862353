import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CardItem } from 'src/app/models/uimodels/cardItem';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  leftBannerImgurl = 'assets/images/login-banner.png';
  homeCardList!: CardItem[];
  breadcrumList!: CardItem[];
  userName!: any;
  selectedRowIndex = -1;
  CrewId!: any;
  shiftStartDataSource!: MatTableDataSource<any>;
  shiftcloseColumns: any[] = ['date', 'shiftstart', 'shiftend', 'shift', 'machine'];
  @ViewChild('displayShiftStartModal') private displayShiftStartModal!: TemplateRef<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  constructor(public router: Router, private userManagementService: UserManagementService,
    private toaster: ToastrService, private dialog: MatDialog, private analyticalToolsService: AnalyticalToolsService) { }

  ngOnInit(): void {
    this.GetMenuChildList();
    this.GetBreadcrumList();
    // this.GetShiftStart();
    this.userName = JSON.parse(localStorage.user).UserName;
  }
  DisplayRightmenu(menuId: any, url: any): any {
    if (menuId === 84)
      this.setRca()
    localStorage.setItem('O3RightMenuId', menuId);

    if (url.indexOf('/Home/ExternalLink?link=') > -1) {
      url = url.replace('/Home/ExternalLink?link=', '');
      window.open(url, '_blank')
    }
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }
  GetMenuChildList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.userManagementService.GetMenuChildList(obj).subscribe((data: any) => {
      this.homeCardList = data.BreadcrumList;
      this.homeCardList.forEach(va => {
        va.Imagepath = va.Imagepath === '' ? '' : 'assets/Content/Icon/' + va.Imagepath?.split('/').pop()?.replace('png', 'svg');
      });
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  GetShiftStart(): void {
    const key = {};
    this.userManagementService.GetShiftStart(key).subscribe((res: any) => {
      this.shiftStartDataSource = new MatTableDataSource<any>(res.CrewsList);
      this.getPaginator(this.shiftStartDataSource, 0);
      if (res.IsShiftStart === true || res.CrewsList.length === 0) {
        this.dialog.closeAll();
      } else {
        this.dialog.open(this.displayShiftStartModal, {
          width: '100vw !important',
          height: '90vh', minWidth: '100vw',
          disableClose: true
        });
      }
    });
  }

  highlightedRow(element: any): void {
    this.CrewId = element.CrewId;
    this.selectedRowIndex = element.CrewId;
  }

  SubmitShiftStart(): void {
    const key = {
      CrewId: this.CrewId
    };
    this.userManagementService.SubmitShiftStart(key).subscribe((response: any) => {
      if (this.selectedRowIndex === this.CrewId) {
        if (!response.HasError) {
          if (response.ErrorMessage.substring(2, 1) === '0') {
            this.showPopupMsg(response.ErrorMessage);
            this.dialog.closeAll();
          }
          else {
            this.showPopupMsg(response.ErrorMessage);
          }
        }
      } else {
        this.toaster.error('Error', 'Please select shift start');
      }
    });
  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
    }, 1000);
  }

  showPopupMsg(msg: any): any {
    if (msg !== null || msg !== '') {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
      } else {
        this.toaster.error('Error', msg.substring(3, msg.length));
      }
    }
  }

  setRca() {
    this.analyticalToolsService.setRca(true);
  }

}
