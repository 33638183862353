import { Component, OnDestroy } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'btn-cell-renderer',
    template:
        `<style>
        .edit-btn{
            color: white;
            background: #3aafe4;
            margin-right:5px
        }
        .del-btn{
            color: white;
            background: red;
            margin-right:5px
        }
        .view-btn{
            color: white;
            background: orange;
        }
        i{
            color: white;
        }    
        .btn:disabled{
            opacity:0.5
        }
        .btn{
            padding: 7px 10px;
            border: none;
            border-radius: 5px;
            cursor:pointer;
        }
        .d-flex{
            display:flex;
        }
    </style>
    <div class="d-flex">
        <button class="edit-btn btn custom-tooltip " title="edit" [disabled]="!this.params.data.IsWrite || this.params.data.WOStatus == 'Close'" mat-icon-button pTooltip="Action Detail" tooltipPosition="top"(click)="onClickEdit($event)">
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        </button>

        <button class="del-btn btn custom-tooltip" title="Delete" [disabled]="!this.params.data.IsWrite || this.params.data.WOStatus != 'Open'" mat-icon-button pTooltip="Action Detail" tooltipPosition="top"  (click)="onClickDelete($event)">
        <i class="fa fa-trash" aria-hidden="true"></i>
        </button>

        <button class="view-btn btn custom-tooltip" title="View"  mat-icon-button matTooltip="View"  matTooltipPosition="right" (click)="onClickView($event)">
        <i class="fa fa-eye" aria-hidden="true"></i>
        </button>
    </div>
    `
})

export class ActionEditDeleteViewBtnRenderer implements ICellRendererAngularComp, OnDestroy {
    params: any;
    refresh(params: any): boolean {
        throw new Error('Method not implemented.');
    }

    agInit(params: any): void { 
        this.params = params;
    }

    onClickEdit($event: any): any {

        if (this.params.onClickEdit instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowData: this.params.node.data,
                index: this.params.node.rowIndex
                // ...something
            };
            this.params.onClickEdit(params);
        }
    }
    onClickDelete($event: any): any {

        if (this.params.onClickDelete instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowData: this.params.node.data,
                index: this.params.node.rowIndex
                // ...something
            };
            this.params.onClickDelete(params);
        }
    }
    onClickView($event: any): any {

        if (this.params.onClickView instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowData: this.params.node.data,
                index: this.params.node.rowIndex
                // ...something
            };
            this.params.onClickView(params);
        }
    }
    ngOnDestroy(): any {
        // no need to remove the button click handler
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}