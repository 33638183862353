<div class="production-db-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{ breadcrumItem.text }}</a>
        </li>
      </ul>
    </ul>

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  </div>
  <form [formGroup]="CLLDashboardForm" (ngSubmit)="onSubmit()">
    <div class="title-row-flex-both-end">
      <h1 class="main-title">New Defect DashBoard</h1>
    </div>
    <div class="content-top-row">
      <mat-form-field 
      class="top-row-first-filed-item top-row-first-filed-item-order-processing " style="width: 13%; margin-left: 0px;">
      <mat-label>SelectGroup</mat-label>
      <mat-select formControlName="UserGroup1" (selectionChange)="getPlants($event)" placeholder="Group"
        panelClass="testClass" name="Status">
        <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
          (filteredReturn)="filteredGroups = $event"></mat-select-filter>
        <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
          {{ group.EG_Desc }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field 
      class="top-row-first-filed-item top-row-first-filed-item-order-processing " style="width: 13%;">
      <mat-label>SelectPlant </mat-label>
      <mat-select formControlName="UserPlant1" placeholder="Plant" panelClass="testClass" name="Status"
        (selectionChange)="onChangePlant($event)">
        <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList1"
          (filteredReturn)="filteredPlants = $event"></mat-select-filter>
        <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
          {{ plant.PT_Desc }}
        </mat-option>
      </mat-select>
    </mat-form-field>
      <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 13%;">
        <mat-label>Department</mat-label>
        <mat-select formControlName="Departments" (selectionChange)="onChangeDepartments($event)">
          <mat-select-filter [placeholder]="'Search Department'" [displayMember]="'TypeName'" [array]="deparmentList"
            (filteredReturn)="deparmentFiltList = $event">
          </mat-select-filter>
          <mat-option value="0">ALL</mat-option>
          <mat-option *ngFor="let dept of deparmentFiltList" [value]="dept.TypeId">
            {{ dept.TypeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- GROUP -->
      <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 13%;">
        <mat-label>Line </mat-label>
        <mat-select formControlName="line" panelClass="testClass" name="line" (selectionChange)="onChangeLine($event)">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
            (filteredReturn)="filteredLines = $event"></mat-select-filter>
          <mat-option value="0">ALL</mat-option>
          <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
            {{ line.PL_Desc }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 13%;">
        <mat-label>Unit</mat-label>
        <mat-select formControlName="unit" panelClass="testClass" name="unit" (selectionChange)="onChangeUnit($event)">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="unitList"
            (filteredReturn)="filteredUnits = $event"></mat-select-filter>
          <mat-option value="0">ALL</mat-option>
          <mat-option *ngFor="let unit of filteredUnits" [value]="unit.PU_Id">
            {{ unit.PU_Desc }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field appearance="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            formControlName="dateStart"
            placeholder="From Date"
          />
          <input matEndDate formControlName="dateEnd" placeholder="To Date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>     -->

      <div class="inputField">
        <nz-range-picker class="calender-input" [nzInputReadOnly]="true" [nzShowTime]="{ nzFormat: 'HH:mm' }"
          nzFormat="yyyy-MM-dd HH:mm" formControlName="datePicker" [nzDisabledDate]="disabledDate"></nz-range-picker>
        <span class="floating-text">Date Range</span>
      </div>
      <button mat-mini-fab mat-button color="primary" type="submit" pTooltip="Search" tooltipPosition="top"
        style="margin-left: 10px;">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </form>

  <!-- chart -->
  <div class="chart-group">
    <div class="chart-items-l">
      <div [chart]="chartCILType"></div>
    </div>

    <div class="chart-items-w30-l">
      <div [chart]="chartLinePercentage"></div>
    </div>
    <div class="chart-items-u">
      <div [chart]="chartCILTask"></div>
    </div>

    <div class="chart-items-w30-u">
      <div [chart]="chartUnitPercentage"></div>
    </div>
  </div>
</div>
<ng-template #imageZoomModel>

  <img [src]="currentImagePath" style="height: 98%; width: 100%; overflow: hidden;">

</ng-template>

<!--popup modal for production checklist history-->

<section>
  <div class="example-button-row">
    <button type="button" class="btn btn-success" (click)="csvExport()" style="margin-top: 30px;"><img
        src="assets/images/csv-file-format.png"></button>
    <button type="button" class="btn btn-success" (click)="excelExport()"
      style="margin-top: 30px; margin-left: 10px;"><i class="fa fa-file-excel-o icon-white"
        aria-hidden="true"></i></button>
  </div>
</section>
<mat-card-content>
  <ag-grid-angular style="width: 100%; height: 24vw;" class="ag-theme-alpine" [rowData]="rowData"
    [defaultExcelExportParams]="defaultExcelExportParams" [frameworkComponents]="frameworkComponents"
    [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" (cellClicked)="cellClicked($event)"
    [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="50">
  </ag-grid-angular>
</mat-card-content>

<ng-template #popupViewModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Action View</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div>
    <table [dataSource]="actionDataSource" mat-table class="basic-table plant-model-table theight" matSort>
      <!-- Checklist Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header>
          <p class="header-text">Target Date</p>

        </th>
        <td mat-cell *matCellDef="let element">{{element.TargetDate | date : 'medium'}}</td>
      </ng-container>
      <ng-container matColumnDef="newDate">
        <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header>
          <p class="header-text">New Target Date</p>
        </th>
        <td mat-cell *matCellDef="let element">{{element.NewTargetDate | date : 'medium'}}</td>
      </ng-container>

      <!-- Values Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Action Name</p>
        </th>
        <td mat-cell *matCellDef="let element">{{element.Action}}</td>
      </ng-container>

      <!-- Values Column -->
      <ng-container matColumnDef="assignto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Responsible</p>
        </th>
        <td mat-cell *matCellDef="let element">{{element.FullName}}</td>
      </ng-container>

      <!-- Values Column -->
      <ng-container matColumnDef="closedate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Completion Date</p>
        </th>
        <td mat-cell *matCellDef="let element">{{element.CloseDate | date : 'medium'}}</td>
      </ng-container>

      <!-- Values Column -->
      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Remarks</p>
        </th>
        <td mat-cell *matCellDef="let element">{{element.Remarks}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow style="text-align: center;">
        <td class="mat-cell" colspan="8">No Data Found</td>
      </tr>
    </table>
  </div>
</ng-template>