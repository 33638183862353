import { Component, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import Exporting from 'highcharts/modules/exporting';
import More from 'highcharts/highcharts-more';
import ExportData from 'highcharts/modules/export-data';
import Accessibility from 'highcharts/modules/accessibility';
import highchartsTreemap from 'highcharts/modules/treemap';
import * as Highcharts from 'highcharts';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexPlotOptions,
} from 'ng-apexcharts';
import { OpmsGlassService } from 'src/app/services/opms-glass.service';
import { DatePipe } from '@angular/common';
import * as Chart from 'chart.js';
import { GlassQaDashboardService } from 'src/app/services/glass-qa-dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { YieldDetailComponent } from './YieldDetail/yield-detail/yield-detail.component';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
};
const HighchartsMore = require('highcharts/highcharts-more.src');
HighchartsMore(Highcharts);
const HC_solid_gauge = require('highcharts/modules/solid-gauge.src');
HC_solid_gauge(Highcharts);
highchartsTreemap(Highcharts);
More(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);
@Component({
  selector: 'app-opms-db',
  templateUrl: './opms-db.component.html',
  styleUrls: ['./opms-db.component.scss'],
})
export class OpmsDbComponent implements OnInit {
  require: any;
  chart2: any;
  public breadcrumList: any;
  gauge!: any;
  chartValue = 48;
  //iconUrl
  menuRedIconUrl: string = '/assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl: string = '/assets/images/menu-bottom-green-icon.png';
  menuBlueIconUrl = '/assets/images/menu-bottom-blue-icon.png';

  private subscriptions = new Subscription();
  actionStatusChartOptions: any;
  highChartsColor = [
    '#DC143C',
    '#00adad',
    '#3b2fb5',
    '#6D81A8',
    '#C471A5',
    '#512E5F',
    '#0034bb',
    '#0b774e',
    '#570000',
    '#0d98da',
    '#00002e',
    '#00a800',
    '#00a8a8',
    '#06573d',
    '#F80000',
    '#0d6e54',
    '#FFDF00',
    '#0692aa',
    '#611745',
    '#ff5252',
    '#ff52ff',
    '#08fa03',
    '#08FAC7',
    '#D208FA',
    '#FAEB08',
  ];
  yieldChartOptions: any;
  guageChartOptions: any;
  qThicknessChartOptions: any;
  LossChartOptions: any;
  waterfallChartOptions: any;
  LossvsPackedChartOptions: any;
  trimLossChartOptions: any;
  avgYieldChartOptions: any;
  avgtrimlossChartOptions: any;
  QualityTonChartOptions: any;
  PackinTonChartOptions: any;
  chartOptions: any;
  capacityUtilization: any;
  tonboxtypeChartOptions: any;
  donughtChartOptions: any;
  versionsData: any[] = [];
  browserData: any[] = [];
  dbData!: FormGroup;
  shiftList: any[] = [];
  crewList: any[] = [];
  thicknessList: any[] = [];
  shiftAll: any[] = [];
  crewAll: any[] = [];
  thicknessAll: any[] = [];
  data: any;
  carddata: any;
  avgPacked: any;
  avgPull: any;
  yieldPercentage: any;
  packedTon: any;
  pullton: any;
  changeover: any;
  netchange: any;
  crewName: any[] = [];
  crewYieldPercentage: any[] = [];
  canvas1: any;
  ctx1: any;
  heatmapOptions: any;
  Zebra = {
    Z_LE: '',
    Z_LC: '',
    Z_RC: '',
    Z_RE: ''
  };
  Bloom = {
    B_L: '',
    B_C: '',
    B_R: ''
  };
  MuStrain = {
    M_LE: '',
    M_LC: '',
    M_C: '',
    M_RC: '',
    M_RE: ''
  };
  dashboard = 'none';
  reports = 'none';
  printCharts = 'none';
  isSelected = false;
  productionReportData: any = [];
  productionReportStatus = false;
  YieldLossesReportData: any = [];
  yieldLossesReportStatus = false;
  printHeaderStatus = false;
  YieldLossesCommentsReportData: any = [];
  yieldLossesCommentsReportStatus = false;
  bloomZebraReportData: any = [];
  bloomZebraReportStatus = false;
  muStrainReportData: any = [];
  muStrainReportStatus = false;
  corrugationGradeReportData: any = [];
  corrugationGradeReportStatus = false;
  otherReportData: any = [];
  otherReportStatus = false;
  OPMSEventReportData: any = [];
  OPMSEventReportStatus = false;
  defectReportData: any = [];
  defectReportStatus = false;
  defectCountReportData: any = [];
  defectCountReportStatus = false;
  manualDefectReportData: any = [];
  manualDefectReportStatus = false;
  manualDefectCountReportData: any = [];
  manualDefectCountReportStatus = false;
  printHeader = false;
  startDate: string = '';
  endDate: string = '';
  shift: string = '';
  crew: string = '';
  thickness: string = '';
  isShiftAvailable: boolean = true;
  NcrTypeWiseChartOptions: any;
  NcrStatusWiseChartOptions: any;
  hourlyDefect: any;
  zebraValueChartOptions: any;
  bloomGradeChartOptions: any;
  muStrainChartOptions: any;

  constructor(
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private opmsGlassservice: OpmsGlassService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private glassQaDashboard: GlassQaDashboardService,
    private dialog: MatDialog,

  ) {}

  ngOnInit() {
    Chart.defaults.global.legend.labels.usePointStyle = true;
    var firstDate = new Date((new Date()).setDate((new Date().getDate())-1));
    this.dbData = this.fb.group({
      startDate: [this.datePipe.transform(firstDate, 'yyyy-MM-dd')],
      endDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      shift: [''],
      crew: [''],
      thickness: [''],
      screenType: [['1']]
    });
    this.dbData.get('shift')?.setValue(0);
    this.getBreadcrumList();
    this.LoadBaseDetails();
  }
  LoadBaseDetails() {
    this.spinner.show();
    var data = {
      StartDate: this.dbData.get('startDate')?.value,
      EndDate: this.dbData.get('endDate')?.value,
      Shift: this.dbData.get('shift')?.value ? this.dbData.get('shift')?.value : 0 
    };
    this.opmsGlassservice.GetFilterParams(data).subscribe((response: any) => {
      if(response.ShiftList.length != 0 && response.ThicknessList.length != 0){
        this.shiftList = response.ShiftList;
        this.crewList = response.CrewList;
        this.thicknessList = response.ThicknessList;
        if(this.shiftList.length == 1){
          this.dbData.get('shift')?.setValue(this.shiftList[0].TypeId);
        }
        else{
          this.dbData.get('shift')?.setValue(this.dbData.get('shift')?.value == '' ? 0 : this.dbData.get('shift')?.value);
        }
        if(this.crewList.length == 1){
          this.dbData.get('crew')?.setValue(this.crewList[0].TypeId);
        }
        else{
          this.dbData.get('crew')?.setValue(0);
        }
        if(this.thicknessList.length == 1){
          this.dbData.get('thickness')?.setValue(this.thicknessList[0].TypeId);
        }
        else{
          this.dbData.get('thickness')?.setValue(0);
        }
        this.loadDashBoard();
        this.loadNcrDashboard();
        this.dbData.get('screenType')?.setValue(['1']);
        this.dashboard = 'block';
        this.reports = 'none';
        this.isShiftAvailable = false;
      }
      else{
        this.spinner.hide();
        this.toaster.warning('Warning','No Shift Data Available in Selected Date Range');
        this.dashboard = 'none';
        this.reports = 'none';
        this.isShiftAvailable = true;
        this.dbData.get('screenType')?.setValue(['']);
        this.dbData.get('shift')?.setValue('');
        this.dbData.get('crew')?.setValue('');
        this.dbData.get('thickness')?.setValue('');
      }
    });
  }
  loadDashBoard() {
    this.spinner.show();
    this.shiftAll = [];
    this.crewAll = [];
    this.thicknessAll = [];
    if (this.dbData.get('shift')?.value == 0) {
      for (var i = 0; i < this.shiftList.length; i++) {
        this.shiftAll.push(this.shiftList[i].TypeId);
      }
    } else {
      this.shiftAll.push(this.dbData.get('shift')?.value);
    }
    if (this.dbData.get('crew')?.value == 0) {
      for (var i = 0; i < this.crewList.length; i++) {
        this.crewAll.push(this.crewList[i].TypeId);
      }
    } else {
      this.crewAll.push(this.dbData.get('crew')?.value);
    }
    if (this.dbData.get('thickness')?.value == 0) {
      for (var i = 0; i < this.thicknessList.length; i++) {
        this.thicknessAll.push(this.thicknessList[i].TypeId);
      }
    } else {
      this.thicknessAll.push(this.dbData.get('thickness')?.value);
    }
    this.data = {};
    this.data = {
      StartDate: this.dbData.get('startDate')?.value,
      EndDate: this.dbData.get('endDate')?.value,
      Shift: this.shiftAll.toString(),
      Thickness: this.thicknessAll.toString(),
      Crew: this.crewAll.toString(),
    };
    this.getPullAndAvgPull();
    this.getPackAndAvgPack();
    this.getChangeOverAndNetChange();
    this.getYield();
    this.getHourlyDefect();
    this.getManualHourlyDefect();
    this.getZebraValuesChart();
    this.productionReportData= [];
    this.productionReportStatus = false;
    this.YieldLossesReportData = [];
    this.yieldLossesReportStatus = false;
    this.YieldLossesCommentsReportData = [];
    this.yieldLossesCommentsReportStatus = false;
    this.bloomZebraReportData = [];
    this.bloomZebraReportStatus = false;
    this.muStrainReportData = [];
    this.muStrainReportStatus = false;
    this.corrugationGradeReportData = [];
    this.corrugationGradeReportStatus = false;
    this.otherReportData = [];
    this.otherReportStatus = false;
    this.OPMSEventReportData = [];
    this.OPMSEventReportStatus = false;
    this.defectReportData = [];
    this.defectReportStatus = false;
    this.defectCountReportData = [];
    this.defectCountReportStatus = false;
    this.manualDefectReportData = [];
    this.manualDefectReportStatus = false;
    this.manualDefectCountReportData = [];
    this.manualDefectCountReportStatus = false;
    this.getPrintProductionReport();
    this.getPrintYieldLossesReport();
    this.getPrintYieldLossesCommentsReport();
    this.getPrintBloomZebraReport();
    this.getPrintMuStrainReport();
    this.getPrintCorrugationGradeReport();
    this.getPrintOtherQualityReport();
    this.getPrintEventsReport();
    this.getPrintDefectsReport();
    this.getPrintDefectsCountReport();
    this.getPrintManualDefectsReport();
    this.getPrintManualDefectsCountReport();
  }
  loadNcrDashboard(){
    this.getNcrTypeWise();
    this.getNcrStatusWise();
  }

  getPrintProductionReport(){
    this.opmsGlassservice.GetPrintProductionReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.productionReportData = response.result;
            this.productionReportStatus = response.result.length > 1 ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getPrintYieldLossesReport(){
    this.opmsGlassservice.GetPrintYieldLossesReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.YieldLossesReportData = response.result;
            this.yieldLossesReportStatus = response.result.length > 1 ? true : false;
            this.printHeaderStatus = response.result.length > 1 ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getPrintYieldLossesCommentsReport(){
    this.opmsGlassservice.GetPrintYieldLossesCommentsReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.YieldLossesCommentsReportData = response.result;
            this.yieldLossesCommentsReportStatus = response.result.length ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getPrintBloomZebraReport(){
    this.opmsGlassservice.GetPrintBloomZebraReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.bloomZebraReportData = response.result;
            this.bloomZebraReportStatus = response.result.length ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getPrintMuStrainReport(){
    this.opmsGlassservice.GetPrintMuStrainReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.muStrainReportData = response.result;
            this.muStrainReportStatus = response.result.length ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getPrintCorrugationGradeReport(){
    this.opmsGlassservice.GetPrintCorrugationGradeReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.corrugationGradeReportData = response.result;
            this.corrugationGradeReportStatus = response.result.length ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getPrintOtherQualityReport(){
    this.opmsGlassservice.GetPrintOtherQualityReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.otherReportData = response.result;
            this.otherReportStatus = response.result.length ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getPrintEventsReport(){
    this.opmsGlassservice.GetPrintEventsReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.OPMSEventReportData = response.result;
            this.OPMSEventReportStatus = response.result.length ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getPrintDefectsReport(){
    this.opmsGlassservice.GetPrintDefectsReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.defectReportData = response.result;
            this.defectReportStatus = response.result.length > 1 ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getPrintDefectsCountReport(){
    this.opmsGlassservice.GetPrintDefectsCountReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.defectCountReportData = response.result;
            this.defectCountReportStatus = response.result.length ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getPrintManualDefectsReport(){
    this.opmsGlassservice.GetPrintManualDefectsReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.manualDefectReportData = response.result;
            this.manualDefectReportStatus = response.result.length > 1 ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getPrintManualDefectsCountReport(){
    this.opmsGlassservice.GetPrintManualDefectsCountReport(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.manualDefectCountReportData = response.result;
            this.manualDefectCountReportStatus = response.result.length ? true : false;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }
        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }

  getPackAndAvgPack(){
    this.opmsGlassservice.getPackAndAvgPack(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          this.packedTon = response.result[0].packedTon;
          this.avgPacked = response.result[0].AvgPacked;
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) => {
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getPullAndAvgPull(){
    this.opmsGlassservice.GetPullAndAvgPull(this.data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.pullton = response.result[0].pullTon;
            this.avgPull = response.result[0].AvgPull;
          }else{
            this.toaster.error("Error",response.ErrorMessage);
          }

        },
        (error: any) => {
          this.toaster.error("Error", error.error.Message);
        }
    );
  }
  getChangeOverAndNetChange(){
    this.opmsGlassservice.getChangeOverAndNetChange(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          this.changeover =  response.result[0].changeOver;
          this.netchange =  response.result[0].netChange;
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) => {
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getYield(): void {
    this.opmsGlassservice.getMonthlyReviewData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          this.guageChartOptions = {
            chart: {
              type: 'solidgauge',
              events: {
                click: () => { this.getYieldDetailDayWise() }
              },
              height: 350,
            },
            title: {
              text: 'Yield %',
            },
            credits: {
              enabled: false,
            },
            pane: {
              center: ['50%', '85%'],
              size: '120%',
              startAngle: -90,
              endAngle: 90,
              background: {
                backgroundColor: '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                borderWidth: 0,
                shape: 'arc',
              },
            },
            yAxis: {
              min: 0,
              max: 100,
              stops: [
                [0.6, '#55BF3B'], // green
                [0.7, '#DDDF0D'], // yellow
                [0.8, '#DF5353'], // red
              ],
              lineWidth: 0,
              tickWidth: 0,
              minorTickInterval: null,
              tickAmount: 2,
              title: {
                y: -70,
              },
              labels: {
                y: 16,
              },
            },
            plotOptions: {
              solidgauge: {
                dataLabels: {
                  y: 5,
                  borderWidth: 0,
                  useHTML: true,
                },
              },
            },
            series: [
              {
                name: 'Yield',
                data: [response.result[0].YieldPercentage],
                dataLabels: {
                  format:
                    '<div style="text-align:center">' +
                    '<span style="font-size:25px">{y}</span><br/>' +
                    '</div>',
                },
                events: {
                  click: () => { this.getYieldDetailDayWise() }
                },
              },
            ],
          };
          Highcharts.chart('Yield', this.guageChartOptions);
          this.getAvgYieldThickness();
          this.getYieldCrewNameChart();
          this.getLossClassificationChart();
          this.getAvgtrimlossThickness();
          this.getWaterFallChart();
          this.getAvgTrimeLossCrewChart();
          this.getQualityByton();
          this.getQualitybyThicknessChart();
          this.gettonboxtypeChart();
          this.getLossvsPackedChart();
          this.getPackinTonsChart();
          this.getDefectIdentificationChart();
        }else{
          this.toaster.error("Error",response.ErrorMessage);
          this.getAvgYieldThickness();
          this.getYieldCrewNameChart();
          this.getLossClassificationChart();
          this.getAvgtrimlossThickness();
          this.getWaterFallChart();
          this.getAvgTrimeLossCrewChart();
          this.getQualityByton();
          this.getQualitybyThicknessChart();
          this.gettonboxtypeChart();
          this.getLossvsPackedChart();
          this.getPackinTonsChart();
          this.getDefectIdentificationChart();
        }
      },
      (error: any) => {
        this.toaster.error("Error", error.error.Message);
        this.getAvgYieldThickness();
        this.getYieldCrewNameChart();
        this.getLossClassificationChart();
        this.getAvgtrimlossThickness();
        this.getWaterFallChart();
        this.getAvgTrimeLossCrewChart();
        this.getQualityByton();
        this.getQualitybyThicknessChart();
        this.gettonboxtypeChart();
        this.getLossvsPackedChart();
        this.getPackinTonsChart();
        this.getDefectIdentificationChart();
      }
    );
  }
  getAvgYieldThickness(): void {
    const thickness: any[] = [];
    const yieldpercentage: any[] = [];
    this.opmsGlassservice.GetAvgYieldByThicknessData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          if (response.result.length) {
            response.result.forEach((data: any) => {
              thickness.push(data.Thickness);
              yieldpercentage.push(data.YieldPercentage);
            });
            this.avgYieldChartOptions = {
              chart: {
                type: 'bar',
                height: 350,
              },
              credits: {
                enabled: false,
              },
              title: {
                text: 'Avg Yield By Thickness',
              },
  
              xAxis: {
                min: 0,
                categories: thickness,
                title: {
                  text: 'Avg Yield',
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: '',
                  align: 'high',
                },
                labels: {
                  overflow: 'justify',
                },
              },
  
              plotOptions: {
                bar: {
                  dataLabels: {
                    enabled: true,
                  },
                },
              },
              series: [
                {
                  name: 'Avg Yield',
                  color: '#1bf98e',
                  data: yieldpercentage,
                },
              ],
            };
          }
          else{
            this.avgYieldChartOptions = {
              chart: {
                type: 'bar',
              },
              credits: {
                enabled: false,
              },
              title: {
                text: 'Avg Yield By Thickness',
              },
  
              xAxis: {
                min: 0,
                categories: thickness,
                title: {
                  text: 'Avg Yield',
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: '',
                  align: 'high',
                },
                labels: {
                  overflow: 'justify',
                },
              },
  
              plotOptions: {
                bar: {
                  dataLabels: {
                    enabled: true,
                  },
                },
              },
              series: [
                {
                  name: 'Avg Yield',
                  color: '#1bf98e',
                  data: yieldpercentage,
                },
              ],
            };
          }
          Highcharts.chart('AvgYieldByThickness', this.avgYieldChartOptions);
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getYieldCrewNameChart(): void {
    const crewName: any[] = [];
    const crewYieldPercentage: any[] = [];
    this.opmsGlassservice.GetYieldPercentageByCrewData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          response.result.forEach((data: any) => {
            crewName.push(data.CrewName);
            crewYieldPercentage.push(data.YieldPercentage);
          });
  
          this.yieldChartOptions = {
            chart: {
              type: 'column',
              height: 350,
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Yield % By Crew Name',
            },
            xAxis: {
              categories: crewName,
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Total Saturation %',
              },
            },
            tooltip: {
              headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true,
            },
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            series: [
              { name: 'Crew Name', data: crewYieldPercentage, color: '#62c7c7' },
            ],
          };
          Highcharts.chart('YieldByCreName', this.yieldChartOptions);
        }else{
          this.toaster.error("Error", response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getLossClassificationChart(): void {
    let yieldLossArray: any[] = [];
    let actualValue: any[] = [];
    let targetValue: any[] = [];
    this.opmsGlassservice.GetLossClassificationData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          response.result.forEach((data: any) => {
            yieldLossArray.push(data.YieldLoss);
            actualValue.push(data.ActualValue);
            targetValue.push(data.TargetValue);
          });
          this.LossChartOptions = {
            chart: {
              type: 'column',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Loss Classification',
            },
            xAxis: {
              categories: yieldLossArray,
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Actual And Target',
              },
            },
            tooltip: {
              headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true,
            },
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            series: [
              {
                name: 'Actual',
                data: actualValue,
                color: '#0f04dd',
              },
              {
                name: 'Target',
                data: targetValue,
                color: '#1bf98e',
              },
            ],
          };
          Highcharts.chart('LossClassification', this.LossChartOptions);
          Highcharts.chart('LossClassification2', this.LossChartOptions);
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getAvgtrimlossThickness(): void {
    const thickness: any[] = [];
    const trimlosspercentage: any[] = [];
    const targetvalue: any[] = [];
    this.opmsGlassservice.GetAvgTrimLossByThicknessData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          response.result.forEach((data: any) => {
            thickness.push(data.Thickness);
            trimlosspercentage.push(data.TrimLossPercentage);
            targetvalue.push(data.TargetValue);
          });
          this.avgtrimlossChartOptions = {
            chart: {
              type: 'bar',
            },
            title: {
              text: 'Avg Trim Loss By Thickness',
            },
  
            xAxis: {
              min: 0,
              categories: thickness,
              title: {
                text: null,
              },
            },
            yAxis: {
              visible: true,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
  
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                data: trimlosspercentage,
                name: 'Trim Loss%',
                color: '#0f04dd',
              },
              {
                name: 'Target',
                data: targetvalue,
                color: '#1bf98e',
              },
            ],
          };
          Highcharts.chart('AvgTrimlossByThickness', this.avgtrimlossChartOptions);
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getWaterFallChart(): void {
    const description: any[] = [];
    const value: any[] = [];
    this.opmsGlassservice.GetWaterFallChartForYTDData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          response.result.forEach((data: any) => {
            description.push(data.Description);
            value.push(data.Value);
          });
          this.waterfallChartOptions = {
            chart: {
              type: 'waterfall',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Waterfall Chart',
            },
            xAxis: {
              categories: description,
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Total Saturation %',
              },
            },
            tooltip: {
              headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true,
            },
            plotOptions: {
              waterfall: {
                dataLabels: {
                  enabled: false,
                },
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            series: [
              {
                name: 'Loss%',
                data: value,
                color: '#0f04dd',
              },
            ],
          };
          Highcharts.chart('WaterFallChart', this.waterfallChartOptions);
          Highcharts.chart('WaterFallChart2', this.waterfallChartOptions);
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getAvgTrimeLossCrewChart(): void {
    const crewname: any[] = [];
    const trimlosspercentage: any[] = [];
    this.opmsGlassservice.GetAvgTrimLossByCrewNameData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          response.result.forEach((data: any) => {
            crewname.push(data.CrewName);
            trimlosspercentage.push(data.TrimLossPercentage);
          });
          this.trimLossChartOptions = {
            chart: {
              type: 'column',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Avg Trim Loss Crew wise',
            },
            xAxis: {
              categories: crewname,
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Total Saturation %',
              },
            },
            tooltip: {
              headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true,
            },
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            series: [
              {
                name: 'Trim Loss %',
                data: trimlosspercentage,
                color: '#e62e8a',
              },
            ],
          };
          Highcharts.chart('AvgTrimLossCrewWise', this.trimLossChartOptions);
          this.spinner.hide();
        }else{
          this.toaster.error("Error",response.ErrorMessage);
          this.spinner.hide();
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
        this.spinner.hide();
      }
    );
  }
  getQualityByton(): void {
    const quality: any[] = [];
    const totalpacked: any[] = [];
    this.opmsGlassservice.GetQualityByTonData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          response.result.forEach((data: any) => {
            quality.push(data.Quality);
            totalpacked.push(data.TotalPacked);
          });
          this.QualityTonChartOptions = {
            chart: {
              type: 'bar',
              height: 350,
            },
            title: {
              text: 'Quality By Ton',
            },
  
            xAxis: {
              min: 0,
              categories: quality,
              title: {
                text: null,
              },
            },
            yAxis: {
              visible: true,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
  
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                color: '#62c7c7',
                name: 'Total Packed',
                data: totalpacked,
              },
            ],
          };
          Highcharts.chart('QuantityByTon', this.QualityTonChartOptions);
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getQualitybyThicknessChart(): void {
    const thickness: any[] = [];
    const totalPacked: any[] = [];
    this.opmsGlassservice.GetQuantityByThicknessData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          response.result.forEach((data: any) => {
            thickness.push(data.Thickness);
            totalPacked.push(data.TotalPacked);
          });
          this.qThicknessChartOptions = {
            chart: {
              type: 'column',
              height: 350,
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Quantity By Thickness (TON)',
            },
            xAxis: {
              categories: thickness,
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Total Saturation %',
              },
            },
            tooltip: {
              headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true,
            },
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            series: [
              {
                name: 'Thickness',
                data: totalPacked,
                color: '#0f04dd',
              },
            ],
          };
          Highcharts.chart('QunatityByThickness', this.qThicknessChartOptions);
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  gettonboxtypeChart(): void {
    const boxType: any[] = [];
    const totalPacked: any[] = [];
    this.opmsGlassservice.GetTon_BoxTypeData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          response.result.forEach((data: any) => {
            boxType.push(data.BoxType);
            totalPacked.push(data.TotalPacked);
          });
          this.tonboxtypeChartOptions = {
            chart: {
              type: 'column',
              height: 350,
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Ton/Box type',
            },
            xAxis: {
              categories: boxType,
              crosshair: true,
            },
            yAxis: {
              min: 0,
            },
            tooltip: {
              headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true,
            },
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            series: [
              {
                name: 'ton/box type',
                data: totalPacked,
                color: '#E85236',
              },
            ],
          };
          Highcharts.chart('Ton/BoxType', this.tonboxtypeChartOptions);
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    ); 
  }
  getLossvsPackedChart(): void {
    const boxType: any[] = [];
    const totalPacked: any[] = [];
    this.opmsGlassservice.GetLosseVsPacked_TonData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          response.result.forEach((data: any) => {
            boxType.push(data.BoxType);
            totalPacked.push(data.TotalPacked);
          });
          this.LossvsPackedChartOptions = {
            chart: {
              type: 'column',
              height: 350,
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Loose vs Packed (Ton)',
            },
            xAxis: {
              categories: boxType,
              crosshair: true,
            },
            yAxis: {
              min: 0,
            },
            tooltip: {
              headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true,
            },
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            series: [
              {
                name: '',
                data: totalPacked,
                color: '#0f04dd',
              },
            ],
          };
          Highcharts.chart('LossvsPacked', this.LossvsPackedChartOptions);
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getPackinTonsChart(): void {
    let boxtype: any[] = [];
    let thickness: any[] = [];
    let values: any[] = [];
    let data: any[] = [];
    let index = 0;
    this.opmsGlassservice.GetThicknessWisePackingInTonsData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          if (response.result) {
            response.result.forEach((data: any) => {
              boxtype.push(data.BoxType);
              thickness.push(data.Thickness);
              index = index + 1;
            });
            thickness = [...new Set(thickness)];
            boxtype = [...new Set(boxtype)];
            for(var i = 0; i<thickness.length; i++){
              for(var j = 0; j<boxtype.length; j++){
                let uniqueData = response.result.find((fre: any) => {
                  const a = thickness[i];
                  const b = fre.Thickness;
                  const c = boxtype[j];
                  const d = fre.BoxType;
                  return (a == b) && (c == d)
                });
                if(uniqueData){
                  values.push(uniqueData.TotalPacked);
                }
                else{
                  values.push(0);
                }
              }
              const obj = {
                name: thickness[i],
                data: values,
                color: this.highChartsColor[i]
              }
              data.push(obj);
              values = [];
            }
            this.PackinTonChartOptions = {
              chart: {
                type: 'column',
                height: 350,
              },
              credits: {
                enabled: false,
              },
              title: {
                text: 'Thickness-wise Packing in Tons',
              },
              xAxis: {
                categories: boxtype,
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: 'Actual And Target',
                },
              },
              tooltip: {
                headerFormat:
                  '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true,
              },
              plotOptions: {
                column: {
                  dataLabels: {
                    enabled: true,
                  },
                  pointPadding: 0.2,
                  borderWidth: 0,
                },
              },
              series: data
            };
          }
          Highcharts.chart('ThicknessWisePacking', this.PackinTonChartOptions);
          Highcharts.chart('ThicknessWisePacking2', this.PackinTonChartOptions);
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getDefectIdentificationChart(): void {
    let defectArr: any[] = [];
    this.opmsGlassservice.GetDeffectIdentificationData(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          response.result.forEach((data: any) => {
            const key = {
              name: data.DeffectName,
              value: data.DeffectCounts,
              colorValue: data.DeffectCounts,
            };
            defectArr.push(key);
          });
          this.heatmapOptions = {
            credits: {
              enabled: false,
            },
            colorAxis: {
              minColor: '#ffffff',
              maxColor: '#0034bb',
            },
            title: {
              text: 'Defect Identification',
            },
            series: [
              {
                type: 'treemap',
                layoutAlgorithm: 'squarified',
                data: defectArr,
              },
            ],
          };
          Highcharts.chart('DefectIdentification', this.heatmapOptions);
          Highcharts.chart('DefectIdentification2', this.heatmapOptions);
        }else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getNcrTypeWise(): void {
    const categories: any[] = [];
    const dataArray: any[] = [];
    const colorArray: any[] = [];
    let total = 0;
    const data = {
      StartDate: this.dbData.get('startDate')?.value,
      EndDate: this.dbData.get('endDate')?.value,
    };
    this.opmsGlassservice.GetNcrTypeWise(data).subscribe(
      (response: any) => {
        if(!response.HasError){
          if(response.result){
            response.result.forEach((a: any) => (total += a.data));
            for (const element of response.result) {
              categories.push(element?.label);
              dataArray.push(element?.data);
              colorArray.push('#F85439');
            }
            categories.push('Total');
            dataArray.push(total);
            colorArray.push('#040496');
            this.NcrTypeWiseChartOptions = {
              chart: {
                type: 'column',
                height: 350,
              },
              credits: {
                enabled: false,
              },
              title: {
                text: 'No. of NCR Type Wise',
              },
              xAxis: {
                categories,
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: '',
                },
              },
              tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0"></td>' +
                  '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true,
              },
              plotOptions: {
                column: {
                  dataLabels: {
                    enabled: true,
                  },
                  pointPadding: 0.2,
                  borderWidth: 0,
                  colorByPoint: true
                },
              },
              colors: colorArray,
              series: [
                {
                  showInLegend: false,
                  name: 'OverAll Completions',
                  data: dataArray,
                },
              ],
            };
          }
          Highcharts.chart('NcrTypeWise', this.NcrTypeWiseChartOptions);
        }
        else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getNcrStatusWise(): void {
    const ncrOpen: any[] = [];
    const ncrClosed: any[] = [];
    const ncrRejected: any[] = [];
    const ncrPending: any[] = [];
    const categories: any[] = [];
    const data = {
      StartDate: this.dbData.get('startDate')?.value,
      EndDate: this.dbData.get('endDate')?.value,
    };
    this.opmsGlassservice.GetNcrStatusWise(data).subscribe(
      (response: any) => {
        if(!response.HasError){
          if(response.result){
            for (const element of response.result) {
              categories.push(element?.MonthNo);
              ncrOpen.push(element?.OpenedNCR);
              ncrClosed.push(element?.ClosedNCR);
              ncrRejected.push(element?.RejectedNCR);
              ncrPending.push(element?.PendingNCR);
            }
            this.NcrStatusWiseChartOptions = {
              chart: {
                type: 'column',
                height: 350,
              },
              title: {
                text: 'No. of NCR Status Wise',
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                categories,
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: '',
                },
              },
              tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0">{series.name}:</td>' +
                  '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true,
              },
              plotOptions: {
                column: {
                  dataLabels: {
                    enabled: true,
                  },
                  pointPadding: 0.2,
                  borderWidth: 0,
                },
              },
              series: [
                {
                  name: 'Open',
                  data: ncrOpen,
                  color: '#040496'
                },
                {
                  name: 'closed',
                  data: ncrClosed,
                  color: '#10CE9C'
                },
                {
                  name: 'Rejected',
                  data: ncrRejected,
                  color: '#F85439'
                },
                {
                  name: 'Pending',
                  data: ncrPending,
                  color: '#ff1a8c'
                },
              ],
            };
          }
          Highcharts.chart('NcrStatusWise', this.NcrStatusWiseChartOptions);
        }
        else{
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.toaster.error("Error", error.error.Message);
      }
    );
  }
  getHourlyDefect(): void {
    const data = {
      StartDate: this.dbData.get('startDate')?.value,
      EndDate: this.dbData.get('endDate')?.value,
      Shift: this.data.Shift,
      TransactionId: this.data.Shift.length == 1 ? 0 : 1
    };
    this.glassQaDashboard.GetHourlyDefect(data).subscribe(
      (response: any) => {
        if(!response.HasError){
          if(response.result.length){
            var categories = [];
            var defectClass = [];
            var series = [];
            var data = [];
            response.result.forEach((data: any) => {
              categories.push(data.Hour);
              defectClass.push(data.DefectClass);
            });
            categories = [...new Set(categories)];
            defectClass = [...new Set(defectClass)];
            defectClass.sort((a, b) => a - b);
            for(var i = 0; i<defectClass.length; i++){
              for(var j = 0; j<categories.length; j++){
                let uniqueData = response.result.find((fre: any) => {
                  const a = defectClass[i];
                  const b = fre.DefectClass;
                  const c = categories[j];
                  const d = fre.Hour;
                  return (a == b) && (c == d)
                });
                if(uniqueData){
                  data.push(uniqueData.defect_no);
                }
                else{
                  data.push(0);
                }
              }
              const obj = {
                name: defectClass[i],
                data: data,
                color: defectClass[i] == '10' ? this.highChartsColor[0] : this.highChartsColor[i+1]
              }
              series.push(obj);
              data = [];
            }
          }else{
            categories = ['Hour 1','Hour 2','Hour 3'];
            series = [
              {
                name: 'Defect Class',
                data: [],
                color: this.highChartsColor[0]
              }
            ]
          }
          this.hourlyDefect = {
            chart: {
              type: 'column',
              height: 350,
            },
            credits: {
              enabled: false,
            },
            title: {
                text: 'Hourly Defects'
            },
            xAxis: {
                categories: categories
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Values'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: series
          }
          Highcharts.chart('HourlyDefect', this.hourlyDefect);
          Highcharts.chart('HourlyDefect2', this.hourlyDefect);
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.toaster.error(error.error)
      }
    )
  }
  getManualHourlyDefect(): void {
    this.glassQaDashboard.GetManualHourlyDefect(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          if(response.result.length){
            var categories = [];
            var defectClass = [];
            var series = [];
            var data = [];
            response.result.forEach((data: any) => {
              categories.push(data.Hour);
              defectClass.push(data.DefectClass);
            });
            categories = [...new Set(categories)];
            defectClass = [...new Set(defectClass)];
            defectClass.sort((a, b) => a - b);
            for(var i = 0; i<defectClass.length; i++){
              for(var j = 0; j<categories.length; j++){
                let uniqueData = response.result.find((fre: any) => {
                  const a = defectClass[i];
                  const b = fre.DefectClass;
                  const c = categories[j];
                  const d = fre.Hour;
                  return (a == b) && (c == d)
                });
                if(uniqueData){
                  data.push(uniqueData.defect_no);
                }
                else{
                  data.push(0);
                }
              }
              const obj = {
                name: defectClass[i],
                data: data,
                color: this.highChartsColor[i]
              }
              series.push(obj);
              data = [];
            }
          }else{
            categories = ['Hour 1','Hour 2','Hour 3'];
            series = [
              {
                name: 'Defect Class',
                data: [],
                color: this.highChartsColor[0]
              }
            ]
          }
          this.hourlyDefect = {
            chart: {
              type: 'column',
              height: 350,
            },
            credits: {
              enabled: false,
            },
            title: {
                text: 'Manual Hourly Defects'
            },
            xAxis: {
                categories: categories
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Values'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: series
          }
          Highcharts.chart('ManualHourlyDefect', this.hourlyDefect);
          Highcharts.chart('ManualHourlyDefect2', this.hourlyDefect);
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
      }
    )
  }
  getZebraValuesChart(): void {
    this.glassQaDashboard.GetZebraValues(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['LE','LC','RC','RE'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.zebraValueChartOptions = {
            chart: {
              type: 'column',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Zebra Values(Degrees)',
            },
            xAxis: {
              categories: categories,
            },
            yAxis: {
              min: 0,
            },
            tooltip: {
              headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true,
            },
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
                pointPadding: 0.2,
                groupPadding: 0.13,
                borderWidth: 0,
              },
            },
            series: series
          };
          Highcharts.chart('ZebraValue', this.zebraValueChartOptions);
          this.getBloomGrade();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getBloomGrade();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message);
        this.getBloomGrade();
      }
    )
  }
  getBloomGrade(): void {
    this.glassQaDashboard.GetBloomGrade(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['L','C','R'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.bloomGradeChartOptions = {
            chart: {
              type: 'column',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Bloom Grade(#)',
            },
      
            xAxis: {
              min: 0,
              categories: categories,
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
      
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            series: series
          };
          Highcharts.chart('BloomGrade', this.bloomGradeChartOptions);
          this.getMUStrain();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getMUStrain();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message);
        this.getMUStrain();
      }
    )
  }
  getMUStrain(): void {
    this.glassQaDashboard.GetMuStrainPsi(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['LE','LC','C','RC','RE','Average'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.muStrainChartOptions = {
            chart: {
              type: 'column',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Mu Strain(psi)',
            },
      
            xAxis: {
              min: 0,
              categories: categories,
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
      
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            series: series
          };
          Highcharts.chart('MuStrain(psi)', this.muStrainChartOptions);
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
      }
    )
  }

  getYieldDetailDayWise(){
    this.dialog.open(YieldDetailComponent, {
      width: '90vw !important',
      height: '90vh', minWidth: '90vw',
      data: {
        StartDate: this.dbData.get('startDate')?.value,
        EndDate: this.dbData.get('endDate')?.value
      },
    });
  }

  displayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  getBreadcrumList(): any {
    this.spinner.show();
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    const breadCrumSub = this.usermanagementService
      .GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(breadCrumSub);
  }
  getPrint(){
    this.startDate = this.datePipe.transform(this.dbData.value.startDate, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.dbData.value.endDate, 'yyyy-MM-dd');
    if (this.dbData.get('shift')?.value == 0) {
      this.shift = this.shiftList.map((shitDetail: any)=> {return shitDetail.TypeName}).toString();
    } else {
      this.shift = this.shiftList.find((s: any)=>{
        return s.TypeId == this.dbData.get('shift')?.value
      }).TypeName
    }
    if (this.dbData.get('crew')?.value == 0) {
      this.crew = this.crewList.map((shitDetail: any)=> {return shitDetail.TypeName}).toString();
    } else {
      this.crew = this.crewList.find((c: any)=>{
        return c.TypeId == this.dbData.get('crew')?.value
      }).TypeName
    }
    if (this.dbData.get('thickness')?.value == 0) {
      this.thickness = this.thicknessList.map((shitDetail: any)=> {return shitDetail.TypeName}).toString();
    } else {
      this.thickness = this.thicknessList.find((t: any)=>{
        return t.TypeId == this.dbData.get('thickness')?.value
      }).TypeName
    }
    this.printHeaderStatus = true;
    this.printHeader = true;
    this.printCharts = 'block';
    setTimeout(() => {
      const WindowPrt: any = window.open('', '_blank');
      let style = `
      <style media="all">
      table, th, tr, td {
        border: 0.3px solid #0a0a0a;
        border-collapse: collapse;
      }
      th{
        background-color: #0f0fd1;
        color: white;
        height: 20px;
        word-wrap: break-word;
      }
      .thRow{
        height: 45px;
      }
      .tdRow{
        height: 30px;
      }
      td{
        text-align: center;
        word-wrap: break-word;
        padding: 0px;
        vertical-align: middle;
      }
      .borderNone{
        border: none;
      }
      .reportsName{
        width: 15%; margin: 10px; background-color: #10CE9C; height: 40px;
      }
      .reportsNameComment{
        width: 20%; margin: 20px 10px 10px; background-color: #10CE9C; height: 40px;
      }
      .resultRow{
        color:#0f0fd1;
      }
      .header{
        width: 100%;
        height: 70px;
        background-color: #0909b7;
        text-align: center;
      }
      .filters{
        font-weight: bold;
        font-size: larger;
        color: #0f0fd1;
      }
      .headerTable{
        width: 100%;
        border: none;
      }
      .headerTable td{
        border: none;
      }
      .menu-items {
        width: 15%;
        padding: 0 31px;
        margin-bottom: 20px;
        height: 140px;
     }
      .menu-items .menu-card-item {
        margin: 0 0 30px 0;
        width: 100%;
        position: relative;
        display: block;
        background: white;
        box-shadow: 2px 2px 15px rgba(5, 5, 124, 0.14);
        border-radius: 10px;
        padding: 30px 20px;
        height: 90px;
     }
      .menu-items .menu-card-item > .chart-items {
        width: 100%;
        margin: 0;
        padding: 20px 0 0 20px;
     }
      .menu-items .menu-card-item:last-child {
        margin-bottom: 0;
     }
      .menu-items .title-text {
        font-weight: bold;
        font-size: 18px;
        line-height: 4px;
        color: #1d252c;
     }
      .menu-items .data-text {
        font-weight: bold;
        font-size: 25px;
        line-height: 20px;
        color: #0f0fd1;
     }
      .menu-items .data-text.small-data-text {
        font-size: 18px;
        line-height: 21px;
     }
      .menu-items .menu-bottom-icon {
        position: absolute;
        right: 0;
        bottom: -1px;
     }
      .menu-items .menu-bottom-icon img {
        height: 40px;
      }
      .menu-card-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 30%;
        margin-left: 40px
     }
      .menu-card-group .mat-card-height {
        height: 180px;
     }
      .menu-card-group .menu-card-item-four {
        width: 108%;
        position: relative;
        display: block;
        background: #fff;
        box-shadow: 2px 2px 15px rgba(5, 5, 124, 0.14);
        border-radius: 10px;
        padding: 10px 0 0 0;
     }
      .menu-card-group .menu-card-item-three {
        width: calc(32.4% - 30px);
        position: relative;
        display: block;
        background: #fff;
        box-shadow: 2px 2px 15px rgba(5, 5, 124, 0.14);
        border-radius: 10px;
        padding: 10px 0 0 0;
     }
      .menu-card-group .menu-card-item-two {
        width: 100%;
        position: relative;
        display: block;
        background: #fff;
        box-shadow: 2px 2px 15px rgba(5, 5, 124, 0.14);
        border-radius: 10px;
        padding: 10px 0 0 0;
     }
      .menu-card-group .title-text {
        font-weight: bold;
        font-size: 20px;
        color: #4c4949;
        margin: 0 !important;
        text-align: center;
     }
      .menu-card-group .data-text {
        font-weight: bold;
        font-size: 20px;
        color: #4c4949;
        text-align: center;
        margin: 7px 0;
     }
      .menu-card-group .data-text.small-data-text {
        font-size: 18px;
        line-height: 21px;
     }
      .menu-card-group .menu-bottom-icon1 {
        position: absolute;
        right: 0;
        bottom: 0;
     }     
     .text-center{
      text-align: center;
      border: none;
      }
      .dash-table {
        width: 85%;
     }
      .dash-table thead {
        background: unset;
        color: unset;
        position: unset;
        top: unset;
        z-index: unset;
     }
      .dash-table tr {
        text-align: center;
     }
      .dash-table tr:nth-child(even) {
        background-color: unset;
     }
      .dash-table table tr:hover {
        transform: unset;
        box-shadow: unset;
     }
      .dash-table td {
        width: 100px;
        font-size: 14px;
        font-weight: bold;
        color: #4c4949;
        padding: 5px !important;
     }
      .dash-table th {
        width: 100px;
        font-size: 14px;
        padding: 5px !important;
        color: #4c4949;
     }     
    //  .dashboard{
    //     margin-left: 20px !important;
    //   }
      @media print{
        body{
          -webkit-print-color-adjust: exact !important;
        }
        @page {
          size:  auto; 
          // size: Tabloid;
          margin: 15px;
        }
        table, th, td {
          border: 0.3px solid #0a0a0a;
          border-collapse: collapse;
        }
        th{
          background-color: #0f0fd1;
          color: white;
          height: 20px;
          word-wrap: break-word;
        }
        .thRow{
          height: 45px;
        }
        .tdRow{
          height: 30px;
        }
        td{
          text-align: center;
          word-wrap: break-word;
          padding: 0px;
          vertical-align: middle;
        }
        .borderNone{
          border: none;
        }
        .reportsName{
          width: 18%; margin: 20px 10px 10px; background-color: #10CE9C; height: 40px;
        }
        .resultRow{
          color:#0f0fd1;
        }
        .reportsNameComment{
          width: 28%; margin: 20px 10px 10px; background-color: #10CE9C; height: 40px;
        }
        .header{
          width: 100%;
          height: 50px;
          background-color: #0909b7;
          text-align: center;
        }
        .filters{
          font-weight: bold;
          font-size: larger;
          color: #0f0fd1;
        }
        .headerTable{
          width: 100%;
          border: none;
        }
        .headerTable td{
          border: none;
        }
        .menu-items {
          width: 13%;
          padding: 0 28px;
          margin-bottom: 20px;
          height: 140px;
       }
        .menu-items .menu-card-item {
          margin: 0 0 30px 0;
          width: 100%;
          position: relative;
          display: block;
          background: white;
          box-shadow: 2px 2px 15px rgba(5, 5, 124, 0.14);
          border-radius: 10px;
          padding: 30px 20px;
          height: 90px;
       }
        .menu-items .menu-card-item > .chart-items {
          width: 100%;
          margin: 0;
          padding: 20px 0 0 20px;
       }
        .menu-items .menu-card-item:last-child {
          margin-bottom: 0;
       }
        .menu-items .title-text {
          font-weight: bold;
          font-size: 18px;
          line-height: 4px;
          color: #1d252c;
       }
        .menu-items .data-text {
          font-weight: bold;
          font-size: 25px;
          line-height: 20px;
          color: #0f0fd1;
       }
        .menu-items .data-text.small-data-text {
          font-size: 18px;
          line-height: 21px;
       }
        .menu-items .menu-bottom-icon {
          position: absolute;
          right: 0;
          bottom: -1px;
       }
        .menu-items .menu-bottom-icon img {
          height: 40px;
       }       
        #yieldTable{
          width: 70%;
        }
        // #LossClassification{
        //   width: 100%;
        //   padding-left: 10%;
        // }
        // #WaterFallChart{
        //   width: 100%;
        //   padding-left: 10%;
        // }
        // .dashboard{
        //   margin-left: -10px !important;
        // }
        .menu-card-group {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 30%;
          margin-left: 30px
       }
        .menu-card-group .mat-card-height {
          height: 180px;
       }
        .menu-card-group .menu-card-item-four {
          width: 108%;
          position: relative;
          display: block;
          background: #fff;
          box-shadow: 2px 2px 15px rgba(5, 5, 124, 0.14);
          border-radius: 10px;
          padding: 10px 0 0 0;
       }
        .menu-card-group .menu-card-item-three {
          width: calc(32.4% - 30px);
          position: relative;
          display: block;
          background: #fff;
          box-shadow: 2px 2px 15px rgba(5, 5, 124, 0.14);
          border-radius: 10px;
          padding: 10px 0 0 0;
       }
        .menu-card-group .menu-card-item-two {
          width: 100%;
          position: relative;
          display: block;
          background: #fff;
          box-shadow: 2px 2px 15px rgba(5, 5, 124, 0.14);
          border-radius: 10px;
          padding: 10px 0 0 0;
       }
        .menu-card-group .title-text {
          font-weight: bold;
          font-size: 20px;
          color: #4c4949;
          margin: 0 !important;
          text-align: center;
       }
        .menu-card-group .data-text {
          font-weight: bold;
          font-size: 20px;
          color: #4c4949;
          text-align: center;
          margin: 7px 0;
       }
        .menu-card-group .data-text.small-data-text {
          font-size: 18px;
          line-height: 21px;
       }
        .menu-card-group .menu-bottom-icon1 {
          position: absolute;
          right: 0;
          bottom: 0;
       }  
       .text-center{
        text-align: center;
        border: none;
        }   
        .dash-table {
          width: 85%;
       }
        .dash-table thead {
          background: unset;
          color: unset;
          position: unset;
          top: unset;
          z-index: unset;
       }
        .dash-table tr {
          text-align: center;
       }
        .dash-table tr:nth-child(even) {
          background-color: unset;
       }
        .dash-table table tr:hover {
          transform: unset;
          box-shadow: unset;
       }
        .dash-table td {
          width: 100px;
          font-size: 14px;
          font-weight: bold;
          color: #4c4949;
          padding: 5px !important;
       }
        .dash-table th {
          width: 100px;
          font-size: 14px;
          padding: 5px !important;
          color: #4c4949;
       }         
      }
      </style>
      `
      WindowPrt.document.write(style); 
      let div = document.getElementsByClassName('header1')[0].innerHTML;
      if((this.dbData.get('screenType')?.value).includes('1')){
        div += `<br>`
        div += `<div style="width: 100%;display: flex; margin-left: -15px;">`
        div += document.getElementsByClassName('printCard')[0].innerHTML;
        div += document.getElementsByClassName('printCard')[1].innerHTML;
        div += document.getElementsByClassName('printCard')[2].innerHTML;
        div += document.getElementsByClassName('printCard')[3].innerHTML;
        div += document.getElementsByClassName('printCard')[4].innerHTML;
        div += document.getElementsByClassName('printCard')[5].innerHTML;
        div += `</div>`
        div += `<div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: space-evenly;">`
        div += document.getElementById('Yield1').innerHTML;
        div += document.getElementById('AvgYieldByThickness1').innerHTML;
        div += document.getElementById('YieldByCreName1').innerHTML;
        div += document.getElementById('AvgTrimlossByThickness1').innerHTML;
        div += document.getElementById('AvgTrimLossCrewWise1').innerHTML;
        div += document.getElementById('LossvsPacked1').innerHTML;
        div += document.getElementById('QunatityByThickness1').innerHTML;
        div += document.getElementById('QuantityByTon1').innerHTML;
        div += document.getElementById('Ton/BoxType1').innerHTML;
        div += `</div>`
        div += `<div class="dashboard" style="width: 100%;display: flex;flex-wrap: wrap;justify-content: space-evenly;">`
        div += document.getElementById('LossClassification1').innerHTML;
        div += document.getElementById('WaterFallChart1').innerHTML;
        div += document.getElementById('ThicknessWisePacking1').innerHTML;
        div += document.getElementById('DefectIdentification1').innerHTML;
        div += document.getElementById('HourlyDefect1').innerHTML;
        div += document.getElementById('ManualHourlyDefect1').innerHTML;
        div += `</div>`
        div += `<div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: space-evenly;">`
        div += document.getElementById('NcrTypeWise1').innerHTML;
        div += document.getElementById('NcrStatusWise1').innerHTML;
        div += `</div>`
        div += `<br>`
        div += `<div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: space-evenly;">`
        div += document.getElementById('ZebraValue1').innerHTML;
        div += document.getElementById('BloomGrade1').innerHTML;
        div += document.getElementById('MuStrain(psi)1').innerHTML;
        div += `</div><br>`
        div += `<div style='page-break-after: always;'></div>`
      }
      if((this.dbData.get('screenType')?.value).includes('2')){
        div += document.getElementById('print').innerHTML;
      }
      WindowPrt.document.write('<html><body>');
      WindowPrt.document.write(div);
      WindowPrt.document.write('</body></html>');
      this.spinner.hide();
      setTimeout(() => {
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
        WindowPrt.close();
        this.printHeaderStatus = this.YieldLossesReportData.length > 1 ? true : false;
        this.printHeader = false;
        this.printCharts = 'none';
      }, 1000);
    }, 1000);
  }

  selectAllItems() {
    this.isSelected = !this.isSelected;

    if (this.isSelected) {
      this.dbData.get('screenType')?.setValue(['0','1','2']);
      this.dashboard = 'block';
      this.reports = 'block';
    } else {
      this.dbData.get('screenType')?.setValue([]);
      this.dashboard = 'none';
      this.reports = 'none';
    }
  }

  checkForAll(){
    let screenType = [];
    screenType = this.dbData.get('screenType')?.value;
    screenType = screenType.filter((a: any) => a !== '0');
    if(screenType.length !== 2){
      this.dbData.get('screenType')?.setValue(screenType);
      if(screenType.length == 0){
        this.dashboard = 'none';
        this.reports = 'none';
      }
      else if(screenType[0] == '1'){
        this.dashboard = 'block';
        this.reports = 'none';
      }
      else{
        this.dashboard = 'none';
        this.reports = 'block';
      }
      this.isSelected = false;
    }
    else{
      this.isSelected = true;
      this.dbData.get('screenType')?.setValue(['0','1','2']);
      this.dashboard = 'block';
      this.reports = 'block';
    }
  }
}
