import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class QualityMachineTrendDashboardService {
  baseUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) {}
  getParameterDropdown(): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + 'MachineAndQualityTrendDashboard/GetParameterDropdown'
    );
  }
  getMachineChartData(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'MachineAndQualityTrendDashboard/GetMachineTrendChartData',
      data
    );
  }
  getCheckListDropdown(): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + 'MachineAndQualityTrendDashboard/GetCheckListDropdown'
    );
  }
  getQualityParametersDropdownByPlantId(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl +
        'MachineAndQualityTrendDashboard/GetQualityParametersDropdownByPlantId',
      data
    );
  }
  getMachineParametersDropdownByPlantId(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl +
        'MachineAndQualityTrendDashboard/GetMachineParametersDropdownByPlantId',
      data
    );
  }

  getProductsDropdownByMachineParameterId(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl +
        'MachineAndQualityTrendDashboard/GetProductsDropdownByMachineParameterId',
      data
    );
  }

  getProductsDropdownByQualityParameterId(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl +
        'MachineAndQualityTrendDashboard/GetProductsDropdownByQualityParameterId',
      data
    );
  }

  getQualityChartData(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'MachineAndQualityTrendDashboard/GetQualityTrendChartData',
      data
    );
  }
  getCheckListChartData(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'CheckListDashboard/GetCheckListDashboarData',
      data
    );
  }
  getParameterByPlant(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'MachineAndQualityTrendDashboard/GetParameterByPlant',
      data
    );
  }

  getQualityParametersDropdownByQualityParamsTypeId(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl +
        'MachineAndQualityTrendDashboard/GetQualityParametersDropdownByQualityParamsTypeId',
      data
    );
  }
  
  getChemicalLabSPCQualityChartData(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'MachineAndQualityTrendDashboard/GetChemicalLabSPCQualityChartData',
      data
    );
  }

  getReportTypeForPhysicalLab(): Observable<any> {
    return this.http.get<any>(
      this.baseUrl +
        'MachineAndQualityTrendDashboard/GetReportTypeForPhysicalLab'
    );
  }

  getPhysicalLabSPCQualityChartData(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'MachineAndQualityTrendDashboard/GetPhysicalLabSPCQualityChartData',
      data
    );
  }

  getMachineQualityParameterAnalysis(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'MachineAndQualityTrendDashboard/GetMachineQualityAnalysisChart', data)
  }

  getMachineQualityParameterComparison(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'MachineAndQualityTrendDashboard/GetMachineQualityComparisonChart', data)
  }

  getQualityParametersForQualityParameterAnalysis(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'MachineAndQualityTrendDashboard/GetQualityParametersForQualityParameterAnalysis');
  }

  getQualityPhysicalLabParameterAnalysis(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'MachineAndQualityTrendDashboard/GetQualityPhysicalLabParameterAnalysis', data)
  }
}
