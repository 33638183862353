import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import Swal from 'sweetalert2';
import { MasterShiftService } from "../../services/master-shift.service";
@Component({
  selector: 'app-general-shift',
  templateUrl: './general-shift.component.html',
  styleUrls: ['./general-shift.component.scss']
})
export class GeneralShiftComponent implements OnInit {
  groupList!: any[];
  shiftModelVisible = false;
  shiftForm!: FormGroup;
  groupId!: number;
  plantId!: number;
  GroupId: any;
  egId: any;
  shift:any;
  shiftID: any;
  shiftId!: number;
  plantList!: any[];
  shiftformMode!: boolean;
  isUpdateshift: boolean = false;
  shiftData!: MatTableDataSource<any>;
  shiftcolumns: string[] = ['plant', 'shiftName', 'shiftActions'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(private machineService: MachineMappingService ,private fb: FormBuilder,private shiftService: MasterShiftService,private toaster: ToastrService) { }

  ngOnInit() {
    this.shiftForm = this.fb.group({
      // shiftPattern: ['', Validators.required],
      // group: ['', Validators.required],
      plant: ['', Validators.required],
      shiftno: ['', Validators.required],
      shiftname: ['', Validators.required],
      // startTime: ['',Validators.required ],
      // endTime: ['',Validators.required],
    });
    // this.getGroupsData();
    
  }
  openShiftModal(): void {
    
    this.getGroupsData();
    this.shiftformMode = true;
    this.shiftForm.get('group')?.setValue(this.groupId);

    this.shiftModelVisible = true;
    this.getShift();
  }
  opensideModaledit(): void {
     this.getGroupsData();
    this.shiftformMode = false;

    this.shiftModelVisible = true;

  }
  getGroupsData(): void {
    const key = {
      GroupId: this.groupId,
    };

    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.plantList = data.DepartList;
      this.shiftForm.get('plant')?.setValue(this.plantId);  
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        //  this.spinner.hide();
      }
    );
  }
  getShift(): void {
    
    const key = {
      egId: this.shiftForm.value.group
    };
  
    this.shiftService.getShift(key).subscribe((data: any) => {
    
      this.shift = data;
      this.shiftForm.get('shiftName')?.setValue(this.shift[0].shiftId);
      // this.getArea(this.plantId);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

saveShift(): void {

  const area = {
    egId: this.shiftForm.value.group,
    plantId : this.shiftForm.value.plant,
    shiftPattern : this.shiftForm.value.shiftno,
      name: this.shiftForm.value.shiftname,
      userId: JSON.parse(localStorage.user).UserId,
  };
  
  this.shiftService.addGeneralShift(area).subscribe(
    (response) => {
      this.toaster.success('Success', 'Shift Added Successfully');
      this.closeShiftModel();
      this.getshiftList(this.egId);

    },
    (error) => {
      this.toaster.error('Error', error.error.message);
    }
  );
}
updateShift(): void {
  
  const key = {
    shiftId:this.shiftId,
    egId: this.shiftForm.value.group,
    plantId : this.shiftForm.value.plant,
    shiftPattern: this.shiftForm.value.shiftno,
    name: this.shiftForm.value.shiftname,
    userId: JSON.parse(localStorage.user).UserId,
  };
  debugger
  this.shiftService.updateShift(key).subscribe(
    (response) => {
      this.toaster.success('Success', 'Area Updated Successfully');
      this.closeShiftModel();
      this.getshiftList(this.egId);
      this.isUpdateshift = false;
    },
    (error: any) => {
      this.toaster.error('Error', error.error.message);

    }
  );
}
editshiftModel(element: any): void {
  debugger
  const key = {
    shiftId: element.shiftId
  }

  this.shiftService.getOneGeneralShift(key).subscribe((response) => {
    this.opensideModaledit()
    this.shiftForm.get('group')?.setValue(this.groupId);
    this.shiftForm.get('shiftno')?.setValue(response.shiftPattern);
    this.shiftForm.get('shiftname')?.setValue(response.name);
    // this.shiftForm.get('startTime')?.setValue(element.startTime);
    // this.shiftForm.get('endTime')?.setValue(element.endTime);
    this.shiftId = element.shiftId
  }, (error) => {
    this.toaster.error('Error', error.error.message);
  })
  //  this.departmentrowId= element.departmentId


}

Deleteshift(element: any): void {
  // if (this.isDelete) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to delete shift!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it',
  }).then((result) => {
    if (result.value) {
      debugger
      console.log(element);
      var id = {
        shiftId: element.shiftId
      }
      this.shiftService.deleteShift(id).subscribe((response) => {
          Swal.fire({
            title: 'Deleted!',
            text: 'Your selected shift has been deleted.',
            icon: 'success',
            timer: 800,
            showConfirmButton: false,
          });
          this.getshiftList(this.egId)
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire('Cancelled', 'Your selected shift is safe :)', 'error');
    }
  });

}

reset(): void {
  this.shiftForm.reset();
  this.isUpdateshift = false;
}
  closeShiftModel(): void {
    this.shiftModelVisible = false;
    this.reset();
  }
  getshiftList(key:any): void {
    this.groupId = key.egId;
    this.egId = key;
   this.plantId = key.plantId;
    this.shiftService.getGeneralShifts(key).subscribe((data) => {
    this.shiftData = new MatTableDataSource<any>(data);
      this.getPaginator();

    },
      (error) => {
        this.toaster.error(error.error.message);

      });

  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.shiftData.filter = filterValue.trim().toLowerCase();

    if (this.shiftData.paginator) {
      this.shiftData.paginator.firstPage();
    }
  }
  getPaginator(): void {
    setTimeout(() => {
      this.shiftData.paginator = this.paginator;
      this.shiftData.sort = this.sort;
    }, 1000);
  }
}
