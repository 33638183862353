import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

import getDocDefinition from "./docDefinition";

function printDoc(printParams: { PDF_HEADER_COLOR: string; PDF_INNER_BORDER_COLOR: string; PDF_OUTER_BORDER_COLOR: string; PDF_LOGO: string; PDF_PAGE_ORITENTATION: string; PDF_WITH_HEADER_IMAGE: boolean; PDF_WITH_FOOTER_PAGE_COUNT: boolean; PDF_HEADER_HEIGHT: number; PDF_ROW_HEIGHT: number; PDF_ODD_BKG_COLOR: string; PDF_EVEN_BKG_COLOR: string; PDF_WITH_CELL_FORMATTING: boolean; PDF_WITH_COLUMNS_AS_LINKS: boolean; PDF_SELECTED_ROWS_ONLY: boolean; }, gridApi: any, columnApi: any) {
  console.log("Exporting to PDF...");
  const docDefinition: any = getDocDefinition(printParams, gridApi, columnApi);
  pdfMake.createPdf(docDefinition).download();
}

export default printDoc;
