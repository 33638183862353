import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MasterareaService {
  baseUrl2: string = environment.O3CoreApiUrl;
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

constructor(private http: HttpClient) { }

getGroupData(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl2 + 'Common/GetGroup', data);
}
addArea(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.o3CoreApiUrl + 'MasterArea/AddMasterArea', data);
}
getAllAreaList(): Observable<any[]> {
  return this.http.get<any[]>(this.baseUrl + 'allmasterareas');
}
deleteArea(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'MasterArea/DeleteMasterArea', data);
}
GetOneMasterArea(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'MasterArea/GetOneMasterArea', data);
}
updateArea(data: any): Observable <any[]> {
  return this.http.put<any[]>(this.o3CoreApiUrl + 'MasterArea/UpdateMasterArea', data);
}
GetMasterArea(data: any): Observable<any> {
  return this.http.post<any>(this.o3CoreApiUrl + 'Area/masterareabygroup', data);
}
}
