import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Action } from '../models/action';
import { Kpireason } from '../models/kpireason';
import { LossAnalysis } from '../models/LossAnalysis';


@Injectable({
  providedIn: 'root'
})
export class LossanalysisService {

   
  O3CoreApiUrl: string = environment.O3CoreApiUrl;


  constructor(private http: HttpClient) { }

  addLossAnalysis(data: any): any {
    return this.http.post<any>(this.O3CoreApiUrl + 'LossAnalysis/AddLossAnalysis', data);
  }

  updateLossAnalysis(data: any): any {
    // return this.http.put<any>(this.baseUrl + 'lossanalysis', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'LossAnalysis/UpdateLossAnalysis', data);
    
  }

  deleteLossAnalysis(data: any): Observable<LossAnalysis> {
    // return this.http.post<LossAnalysis>(
    //   this.baseUrl + 'deletelossanalysis',
    //   data
    // );
    return this.http.post<LossAnalysis>(
      this.O3CoreApiUrl + 'LossAnalysis/DeleteLossAnalysis',
      data
    );
  }

  getMasterReason(data: any): Observable<Kpireason[]> {
    //return this.http.post<Kpireason[]>(this.baseUrl + 'kpireasonlossanalysis', data);
    return this.http.post<Kpireason[]>(this.O3CoreApiUrl + 'LossAnalysis/GetPlantKPiData', data);
    
  }
  // getLossAnalysisActions(data: any): Observable<Action> {
  //   return this.http.post<Action>(this.baseUrl + 'lossanalysisaction', data);
  // }

}
