<div class="machine-parameter-content">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title"> Material Execution </h1>
    </div>
  </div>
  <mat-card class="p-mt-3">
    <div class="p-d-flex p-jc-between p-ac-center p-ai-center"
      style="background-color: #0F0FD1;height: 80px;border-radius: 5px; margin: -10px; overflow: hidden; justify-content: flex-end;">
      <form [formGroup]="rawMaterialExecutionForm">
        <div class="content-top-row">
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 22%;">
            <mat-label class="placeholder-title">Select Group</mat-label>
            <mat-select formControlName="group" panelClass="testClass" name="Group" (selectionChange)="getPlantData()">
              <mat-option *ngFor="let Group of groupList" [value]="Group.TypeId">
                {{Group?.TypeName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 22%;">
            <mat-label class="required">Select Plant</mat-label>
            <mat-select panelClass="testClass" name="plant" formControlName="plant"
              (selectionChange)="GetAllRawMaterials()">
              <mat-option *ngFor="let plant of plantList" [value]="plant.DeptId">
                {{plant?.DeptDesc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 22%;">
            <mat-label class="required">Select Material</mat-label>
            <mat-select panelClass="testClass" name="plant" formControlName="material"
              (selectionChange)="GetAllRMInspectionMasterData($event)">
              <mat-option *ngFor="let material of materialList" [value]="material.rawMaterialId">
                {{material?.rawMaterialName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 22%;">
            <mat-label class="required">Select Parameter Type</mat-label>
            <mat-select panelClass="testClass" name="plant" formControlName="type"
              (selectionChange)="changeParamUnitType($event)">
              <mat-option *ngFor="let type of typeList" [value]="type.id">
                {{type?.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
    <form [formGroup]="rawMaterialInspectionHeaderForm" *ngIf="headerMode">
      <div class="content-row-wrap">
        <mat-form-field style="height: 45px;" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          *ngFor="let header of headersArray">
          <mat-label class="required">{{header?.rmName}}</mat-label>
          <input [type]="header.rmType" matInput [formControlName]="header.rmName" [readonly]="readOnlyProperty">
        </mat-form-field>
      </div>
    </form>
    <!--Physical Spec Accordian-->
    <mat-accordion style="margin-top: 20px;">
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="acc1" class="disable_ripple_acc1">
          <mat-panel-title>
            Physical Specifications
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="phySpecDataSource" *ngIf="headerMode">
            <ng-container matColumnDef="paramName">
              <th mat-header-cell *matHeaderCellDef> Parameter Name </th>
              <td mat-cell *matCellDef="let element">{{ element?.paramName }} </td>
            </ng-container>

            <ng-container matColumnDef="paramUnitName">
              <th mat-header-cell *matHeaderCellDef>
                Parameter Unit
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.paramUnitName }}</td>
            </ng-container>

            <ng-container matColumnDef="normalRange">
              <th mat-header-cell *matHeaderCellDef>
                Normal Range
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.normalRange }}</td>
            </ng-container>

            <ng-container matColumnDef="typicalValue">
              <th mat-header-cell *matHeaderCellDef>
                Typical value
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-radio-group required aria-label="Select an option" *ngIf="element.paramUnitType === 'Radio'"
                  (change)="changeTypicalvalues($event, element)" style="display: flex;">
                  <mat-radio-button *ngFor="let data of radioTypeList" [value]="data.name" style="margin-left: 10px;">
                    {{ data?.name }}
                  </mat-radio-button>
                </mat-radio-group>
                <mat-form-field appearance="fill" class="field-fill-table" *ngIf="element.paramUnitType === 'Input'"
                  style=" height: 60px !important;">
                  <input matInput autocomplete="off" (change)="changeTypicalvalues($event, element)"
                    [value]="element.typicalValue ? element.typicalValue : ''" [readonly]="readOnlyProperty">
                </mat-form-field>
                <mat-form-field appearance="fill" class="field-fill-table" *ngIf="element.paramUnitType === 'Dropdown'"
                  style=" height: 60px !important;">
                  <mat-label class="required">Select Value</mat-label>
                  <mat-select panelClass="testClass" name="plant"
                    (selectionChange)="changeTypicalvalues($event, element)">
                    <mat-option *ngFor="let value of valueList" [value]="value.id">
                      {{value?.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="parametersHeaderColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: parametersHeaderColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="3">No data matching</td>
            </tr>
          </table>
        </div>



        <!-- <table mat-table [dataSource]="phySpecDataSource" matSort *ngIf="headerMode">
          <ng-container matColumnDef="paramName">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header
              class="header-min-width">
              <p class="header-text">Parameter Name</p>
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.paramName }}</td>
          </ng-container>
          <ng-container matColumnDef="paramUnitName">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header
              class="header-min-width">
              <p class="header-text">Parameter Unit</p>
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.paramUnitName }}</td>
          </ng-container>
          <ng-container matColumnDef="normalRange">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header
              class="header-min-width">
              <p class="header-text">Normal Range</p>
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.normalRange }}</td>
          </ng-container>
          <ng-container matColumnDef="typicalValue">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header
              class="header-min-width">
              <p class="header-text">Typical value</p>
            </th>
            <td mat-cell *matCellDef="let element" class="content-top-row">
              <mat-radio-group required aria-label="Select an option" *ngIf="element.paramUnitType === 'Radio'"
                (change)="changeTypicalvalues($event, element)" style="display: flex;">
                <mat-radio-button *ngFor="let data of radioTypeList" [value]="data.name" style="margin-left: 10px;">
                  {{ data?.name }}
                </mat-radio-button>
              </mat-radio-group>
              <mat-form-field appearance="fill" class="field-fill-table" *ngIf="element.paramUnitType === 'Input'"
                style=" height: 50px !important;">
                <input matInput autocomplete="off" (change)="changeTypicalvalues($event, element)"
                  [value]="element.typicalValue ? element.typicalValue : ''" [readonly]="readOnlyProperty">
              </mat-form-field>
              <mat-form-field appearance="fill" class="field-fill-table" *ngIf="element.paramUnitType === 'Dropdown'"
                style=" height: 50px !important;">
                <mat-label class="required">Select Value</mat-label>
                <mat-select panelClass="testClass" name="plant"
                  (selectionChange)="changeTypicalvalues($event, element)">
                  <mat-option *ngFor="let value of valueList" [value]="value.id">
                    {{value?.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="parametersHeaderColumns"></tr>
          <tr mat-row *matRowDef=" let row; columns: parametersHeaderColumns"></tr>
          <tr class="mat-row" *matNoDataRow style="text-align: center;">
            <td class="mat-cell" colspan="4">No data matching</td>
          </tr>
        </table> -->
      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="headerMode" class="action-btn">
      <button *ngIf="btnShowHideProperty" mat-mini-fab mat-button color="primary"
        (click)="AddRawMaterialExecutionMasterHeader()" pTooltip="Save" tooltipPosition="top">
        <mat-icon>save</mat-icon>
      </button>
      <!-- <button type="submit" class="icon-btn" pTooltip="Save" tooltipPosition="top"
        (click)="AddRawMaterialExecutionMasterHeader()" *ngIf="btnShowHideProperty">
        <img src="assets/images/save.png" alt="" width="20px" height="20px">
      </button> -->
    </div>
    <!--Lab Spec Accordian-->
    <mat-accordion style="margin-top: 5px;" *ngIf="rawMaterialExecutionForm.controls['type'].value === 2">
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="acc2" class="disable_ripple_acc2">
          <mat-panel-title>
            Lab Specifications
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="parametersHeaderDataSource">
            <ng-container matColumnDef="paramName">
              <th mat-header-cell *matHeaderCellDef> Parameter Name </th>
              <td mat-cell *matCellDef="let element">{{ element?.paramName }} </td>
            </ng-container>

            <ng-container matColumnDef="paramUnitName">
              <th mat-header-cell *matHeaderCellDef>
                Parameter Unit
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.paramUnitName }}</td>
            </ng-container>

            <ng-container matColumnDef="normalRange">
              <th mat-header-cell *matHeaderCellDef>
                Normal Range
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.normalRange }}</td>
            </ng-container>

            <ng-container matColumnDef="paramType">
              <th mat-header-cell *matHeaderCellDef>
                Parameter Type
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.paramType }}</td>
            </ng-container>

            <ng-container matColumnDef="typicalValue">
              <th mat-header-cell *matHeaderCellDef>
                Typical value
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-radio-group required aria-label="Select an option" *ngIf="element.paramUnitType === 'Radio'"
                  (change)="changeTypicalvalues($event, element)" style="display: flex;">
                  <mat-radio-button *ngFor="let data of radioTypeList" [value]="data.name" style="margin-left: 10px;">
                    {{ data?.name }}
                  </mat-radio-button>
                </mat-radio-group>
                <mat-form-field appearance="fill" class="field-fill-table" *ngIf="element.paramUnitType === 'Input'"
                  style=" height: 60px !important;">
                  <input matInput autocomplete="off" (change)="changeTypicalvalues($event, element)"
                    [value]="element.typicalValue ? element.typicalValue : ''" [readonly]="readOnlyProperty">
                </mat-form-field>
                <mat-form-field appearance="fill" class="field-fill-table" *ngIf="element.paramUnitType === 'Dropdown'"
                  style=" height: 60px !important;">
                  <mat-label class="required">Select Value</mat-label>
                  <mat-select panelClass="testClass" name="plant"
                    (selectionChange)="changeTypicalvalues($event, element)">
                    <mat-option *ngFor="let value of valueList" [value]="value.id">
                      {{value?.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="labParametersColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: labParametersColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="3">No data matching</td>
            </tr>
          </table>
        </div>


        <!-- <table mat-table [dataSource]="parametersHeaderDataSource" class="basic-table plant-model-table margin-top"
          matSort *ngIf="headerMode">
          <ng-container matColumnDef="paramName">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header
              class="header-min-width">
              <p class="header-text">Parameter Name</p>
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.paramName }}</td>
          </ng-container>
          <ng-container matColumnDef="paramUnitName">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header
              class="header-min-width">
              <p class="header-text">Parameter Unit</p>
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.paramUnitName }}</td>
          </ng-container>
          <ng-container matColumnDef="normalRange">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header
              class="header-min-width">
              <p class="header-text">Normal Range</p>
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.normalRange }}</td>
          </ng-container>
          <ng-container matColumnDef="paramType">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header
              class="header-min-width">
              <p class="header-text">Parameter Type</p>
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.paramType }}</td>
          </ng-container>
          <ng-container matColumnDef="typicalValue">
            <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header
              class="header-min-width">
              <p class="header-text">Typical value</p>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-radio-group required aria-label="Select an option" *ngIf="element.paramUnitType === 'Radio'"
                (change)="changeLabTypicalValues($event, element)">
                <mat-radio-button *ngFor="let data of radioTypeList" [value]="data.name" style="margin-left: 10px;">
                  {{ data?.name }}
                </mat-radio-button>
              </mat-radio-group>
              <mat-form-field appearance="fill" class="field-fill-table" *ngIf="element.paramUnitType === 'Input'"
                style="width: 180px;">
                <input matInput autocomplete="off" (change)="changeLabTypicalValues($event, element)"
                  [value]="element.typicalValue ? element.typicalValue : ''">
              </mat-form-field>
              <mat-form-field appearance="fill" class="field-fill-table" *ngIf="element.paramUnitType === 'Dropdown'"
                style="width: 180px; height: 50px !important;">
                <mat-label class="required">Select Value</mat-label>
                <mat-select panelClass="testClass" name="plant">
                  <mat-option *ngFor="let value of valueList" [value]="value.id">
                    {{value?.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="labParametersColumns"></tr>
          <tr mat-row *matRowDef=" let row; columns: labParametersColumns"></tr>
          <tr class="mat-row" *matNoDataRow style="text-align: center;">
            <td class="mat-cell" colspan="4">No data matching</td>
          </tr>
        </table> -->
      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="headerMode && labBtnShowHideProperty" class="action-btn">
      <button mat-mini-fab mat-button color="primary" (click)="AddRawMaterialExecutionLabSpecifications()"
        pTooltip="Save" tooltipPosition="top">
        <mat-icon>save</mat-icon>
      </button>
    </div>
    <!-- <div class="content-row-wrap btn-class" *ngIf="headerMode && labBtnShowHideProperty">
      <button type="submit" class="icon-btn" pTooltip="Save" tooltipPosition="top"
        (click)="AddRawMaterialExecutionLabSpecifications()">
        <img src="assets/images/save.png" alt="" width="20px" height="20px">
      </button>
    </div> -->
  </mat-card>
</div>