import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _moment from 'moment';
import { AtHeader } from 'src/app/models/analytical-toolsModels/atHeader';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { formatDate } from '@angular/common';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
@Component({
  selector: 'app-swot',
  templateUrl: './swot.component.html',
  styleUrls: ['./swot.component.scss']
})
export class SwotComponent implements OnInit {
  stageId: number = 6;

  SWOT: any = {

    strength: [],
    weakness: [],
    opportunities: [],
    threats: [],
  };
  s_w_o_t: any = {
    strength: [],
    weakness: [],
    opportunities: [],
    threats: [],
  };

  newSWOT: any = {
    strength: [],
    weakness: [],
    opportunities: [],
    threats: [],
  }

  PEST: any = {
    political: [],
    economic: [],
    social: [],
    technology: []
  }

  p_e_s_t: any = {
    political: [],
    economic: [],
    social: [],
    technology: []
  }

  newPEST: any = {
    political: [],
    economic: [],
    social: [],
    technology: []
  }
  stageName: string = 'SWOT & PEST';
  breadcrumList!: any;
  headerData: AtHeader = {
    ID: 0,
    Group: '',
    Plant: '',
    Dept: '',
    Team: '',
    TeamLeader: '',
    InitProbStatement: '',
    NoOfM: 6,
    stageId: 6,
    uniqueCode: '',
    CreatedAt: new Date(),
    CreatedBy: ''
  };
  swot: any;
  List: any = [
    { id: 1, title: 'vlaue 1' }
  ];
  strengthList: any = [];
  swotData: any;
  weakNessList: any = [];
  opportunitiesList: any = [];
  threatsList: any = [];
  orgstrengthList: any = [];
  orgweakNessList: any = [];
  orgopportunitiesList: any = [];
  orgthreatsList: any = [];
  other: boolean = false;
  user: any;
  pestData: any;
  politicalList: any = [];
  orgPoliticalList: any = [];
  economicList: any = [];
  orgEconomicList: any = [];
  socialList: any = [];
  orgSocialList: any = [];
  technologyList: any = [];
  orgTechnologyList: any = [];
  headerDate: any;
  headerCreatedAt: any;
  orgPoliticalListnewCheck: any = [];
  orgSocialListnewCheck: any = [];
  orgEconomicListnewCheck: any = [];
  orgTechnologyListnewCheck: any = [];
  isReset: boolean = false;
  SwotSubject: any;
  PestSubject: any;
  rcaId: number = 0;
  updateStatus: boolean = false;
  spinnerText: string = '';

  permisson!: any;
  IsWrite: boolean = false;
  IsAccess!: any
  checkBool: boolean = false;
  constructor(
    private analyticalToolsService: AnalyticalToolsService, 
    private userManagementService: UserManagementService, 
    private spinner: NgxSpinnerService, 
    private router: Router, 
    private toaster: ToastrService, 
    private enterpriseGroupService: EnterPriseGroupService) {
  }


  ngOnInit(): void {
    this.getSwotData();
    this.getPestData();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.headerData.CreatedBy = this.user.UserId;
  }

  ngDoCheck() {
    if (this.swotData && this.swotData.length && this.pestData && this.pestData.length && !this.checkBool && this.headerData.Dept) {
      this.checkBool = true;
      let swotpestdata = this.analyticalToolsService.getSWOTPESTData();
      if (swotpestdata && swotpestdata.swotAndPest && swotpestdata.swotAndPest.length) {
        this.setSearchData(swotpestdata)
      }
    }
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.spinner.show();
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.spinner.hide();
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getSwotData() {
    this.analyticalToolsService.getSwotData().subscribe((res: any) => {
      this.swotData = res.data
      this.s_w_o_t.strength = res.data.filter((m: any) => m.SWOT_type == 1);
      this.orgstrengthList = [...this.s_w_o_t.strength];
      this.orgstrengthList.map((strength: any) => this.strengthList.push([...this.s_w_o_t.strength]));

      this.s_w_o_t.weakness = res.data.filter((m: any) => m.SWOT_type == 2);
      this.orgweakNessList = [...this.s_w_o_t.weakness];
      this.orgweakNessList.map((weakness: any) => this.weakNessList.push([...this.s_w_o_t.weakness]));

      this.s_w_o_t.opportunities = res.data.filter((m: any) => m.SWOT_type == 3);
      this.orgopportunitiesList = [...this.s_w_o_t.opportunities];
      this.orgopportunitiesList.map((opportunities: any) => this.opportunitiesList.push([...this.s_w_o_t.opportunities]));

      this.s_w_o_t.threats = res.data.filter((m: any) => m.SWOT_type == 4);
      this.orgthreatsList = [...this.s_w_o_t.threats];
      this.orgthreatsList.map((threats: any) => this.threatsList.push([...this.s_w_o_t.threats]));

    });
  }

  getPestData() {
    this.spinnerText = 'Fetching PEST Data';
    this.spinner.show();
    this.analyticalToolsService.getPestData().subscribe((res: any) => {
      this.spinner.hide();
      this.pestData = res.data;
      this.politicalList = this.pestData.filter((pest: any) => { return pest.PEST_type == 1 })
      this.p_e_s_t.political = [...this.politicalList];
      this.economicList = this.pestData.filter((pest: any) => { return pest.PEST_type == 2 })
      this.p_e_s_t.economic = [...this.economicList];
      this.socialList = this.pestData.filter((pest: any) => { return pest.PEST_type == 3 })
      this.p_e_s_t.social = [...this.socialList];
      this.technologyList = this.pestData.filter((pest: any) => { return pest.PEST_type == 4 })
      this.p_e_s_t.technology = [...this.technologyList];
      for (var i = 0; i < this.politicalList.length; i++)
        this.orgPoliticalList.push(false);
      for (var i = 0; i < this.economicList.length; i++)
        this.orgEconomicList.push(false);
      for (var i = 0; i < this.socialList.length; i++)
        this.orgSocialList.push(false);
      for (var i = 0; i < this.technologyList.length; i++)
        this.orgTechnologyList.push(false);
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }


  somethingClick(checkbox: any, item: any) {
    if (checkbox.checked == true) {
      if (item.PEST_type == 1)
        this.PEST.political.push(item.ID);
      else if (item.PEST_type == 2)
        this.PEST.economic.push(item.ID);
      else if (item.PEST_type == 3)
        this.PEST.social.push(item.ID);
      else if (item.PEST_type == 4)
        this.PEST.technology.push(item.ID);
    }
    else {
      if (item.PEST_type == 1) {
        let index = this.PEST.political.findIndex((pol: any) => { return pol == item.ID });
        this.PEST.political.splice(index, 1);
      }
      else if (item.PEST_type == 2) {
        let index = this.PEST.economic.findIndex((eco: any) => { return eco == item.ID });
        this.PEST.economic.splice(index, 1);
      }
      else if (item.PEST_type == 3) {
        let index = this.PEST.social.findIndex((soc: any) => { return soc == item.ID });
        this.PEST.social.splice(index, 1);
      }
      else if (item.PEST_type == 4) {
        let index = this.PEST.technology.findIndex((tech: any) => { return tech == item.ID });
        this.PEST.technology.splice(index, 1);
      }
    }
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  addSwotRow(type: number) {
    if (type == 1) {
      // this.s_w_o_t.strength.push({ SWOT_type: 1, Name: '' });
      this.s_w_o_t.strength.unshift({ SWOT_type: type, Name: '' });
      this.SWOT.strength.unshift('');
      this.newSWOT.strength.unshift('');
      this.strengthList.unshift([{ SWOT_type: type, Name: '' }]);
    }
    else if (type == 2) {
      // this.s_w_o_t.weakness.push({ SWOT_type: 2, Name: '' });
      this.s_w_o_t.weakness.unshift({ SWOT_type: type, Name: '' });
      this.SWOT.weakness.unshift('');
      this.newSWOT.weakness.unshift('');
      this.weakNessList.unshift([{ SWOT_type: type, Name: '' }]);
    }
    else if (type == 3) {
      // this.s_w_o_t.opportunities.push({ SWOT_type: 3, Name: '' });
      this.s_w_o_t.opportunities.unshift({ SWOT_type: type, Name: '' });
      this.SWOT.opportunities.unshift('');
      this.newSWOT.opportunities.unshift('');
      this.opportunitiesList.unshift([{ SWOT_type: type, Name: '' }]);
    }
    else if (type == 4) {
      // this.s_w_o_t.threats.push({ SWOT_type: 4, Name: '' });
      this.s_w_o_t.threats.unshift({ SWOT_type: type, Name: '' });
      this.SWOT.threats.unshift('');
      this.newSWOT.threats.unshift('');
      this.threatsList.unshift([{ SWOT_type: type, Name: '' }]);
    }
  }

  deleteSwotRow(type: number, i: number) {
    if (type == 1) {
      this.s_w_o_t.strength.splice(i, 1);
      this.SWOT.strength.splice(i, 1);
      this.newSWOT.strength.splice(i, 1);
      this.strengthList.splice(i, 1);
    } else if (type == 2) {
      this.s_w_o_t.weakness.splice(i, 1);
      this.SWOT.weakness.splice(i, 1);
      this.newSWOT.weakness.splice(i, 1);
      this.weakNessList.splice(i, 1);
    } else if (type == 3) {
      this.s_w_o_t.opportunities.splice(i, 1);
      this.SWOT.opportunities.splice(i, 1);
      this.newSWOT.opportunities.splice(i, 1);
      this.opportunitiesList.splice(i, 1);
    } else if (type == 4) {
      this.s_w_o_t.threats.splice(i, 1);
      this.SWOT.threats.splice(i, 1);
      this.newSWOT.threats.splice(i, 1);
      this.threatsList.splice(i, 1);
    }
  }

  deletePestRow(type: number, i: number) {
    if (type == 1) {
      this.p_e_s_t.political.splice(i, 1);
      this.PEST.political.splice(i, 1);
      this.newPEST.political.splice(i, 1);
      this.politicalList.splice(i, 1);
    } else if (type == 2) {
      this.p_e_s_t.economic.splice(i, 1);
      this.PEST.economic.splice(i, 1);
      this.newPEST.economic.splice(i, 1);
      this.economicList.splice(i, 1);
    } else if (type == 3) {
      this.p_e_s_t.social.splice(i, 1);
      this.PEST.social.splice(i, 1);
      this.newPEST.social.splice(i, 1);
      this.socialList.splice(i, 1);
    } else if (type == 4) {
      this.p_e_s_t.technology.splice(i, 1);
      this.PEST.technology.splice(i, 1);
      this.newPEST.technology.splice(i, 1);
      this.technologyList.splice(i, 1);
    }
  }

  addPestRow(type: number) {
    if (type == 1)
      this.p_e_s_t.political.unshift({ PEST_type: 1, Name: '' });
    else if (type == 2)
      this.p_e_s_t.economic.unshift({ PEST_type: 2, Name: '' });
    else if (type == 3)
      this.p_e_s_t.social.unshift({ PEST_type: 3, Name: '' });
    else if (type == 4)
      this.p_e_s_t.technology.unshift({ PEST_type: 4, Name: '' });
  }

  searchValues(filter: any, type: number, index: number) {
    filter = filter.target.value;
    if (type == 1)
      this.strengthList[index] = this.orgstrengthList.filter((strength: any) => {
        let lowerCase = strength.Name.toLocaleLowerCase();
        return strength.Name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    else if (type == 2)
      this.weakNessList[index] = this.orgweakNessList.filter((weakNess: any) => {
        let lowerCase = weakNess.Name.toLocaleLowerCase();
        return weakNess.Name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    else if (type == 3)
      this.opportunitiesList[index] = this.orgopportunitiesList.filter((opportunities: any) => {
        let lowerCase = opportunities.Name.toLocaleLowerCase();
        return opportunities.Name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    else if (type == 4)
      this.threatsList[index] = this.orgthreatsList.filter((threats: any) => {
        let lowerCase = threats.Name.toLocaleLowerCase();
        return threats.Name.startsWith(filter) || lowerCase.startsWith(filter);
      });
  }

  updateSWOTList(type: number, index: number) {

    let tempArr: any = [];
    let existingSelected: any = [];
    if (type == 1) {
      tempArr = this.strengthList[index].filter((strength: any) => strength.ID != this.SWOT.strength[index]);
      if (tempArr.length)
        this.strengthList.map((strength: any, ind: number) => {
          if (index != ind) {
            const existingSelectedstrength = this.SWOT.strength.filter((mat: any) => this.strengthList[ind].find((m: any) => m.ID == mat) && mat != this.SWOT.strength[index]);
            this.strengthList[ind] = [...tempArr];
            for (const mate of existingSelectedstrength) {
              if (!existingSelected.find((ins: any) => ins == mate)) {
                this.strengthList[ind].push(this.s_w_o_t.strength.find((mat: any) => mat.ID == mate));
                existingSelected.push(mate);
              }
            }
          }
        });
    }
    else if (type == 2) {
      tempArr = this.weakNessList[index].filter((weakness: any) => weakness.ID != this.SWOT.weakness[index]);
      if (tempArr.length)
        this.weakNessList.map((weakness: any, ind: number) => {
          if (index != ind) {
            const existingSelectedweakness = this.SWOT.weakness.filter((mat: any) => this.weakNessList[ind].find((m: any) => m.ID == mat) && mat != this.SWOT.weakness[index]);
            this.weakNessList[ind] = [...tempArr];
            for (const mate of existingSelectedweakness) {
              if (!existingSelected.find((ins: any) => ins == mate)) {
                this.weakNessList[ind].push(this.s_w_o_t.weakness.find((mat: any) => mat.ID == mate));
                existingSelected.push(mate);
              }
            }
          }
        });
    }
    else if (type == 3) {
      tempArr = this.opportunitiesList[index].filter((opportunities: any) => opportunities.ID != this.SWOT.opportunities[index]);
      if (tempArr.length)
        this.opportunitiesList.map((opportunities: any, ind: number) => {
          if (index != ind) {
            const existingSelectedopportunities = this.SWOT.opportunities.filter((mat: any) => this.opportunitiesList[ind].find((m: any) => m.ID == mat) && mat != this.SWOT.opportunities[index]);
            this.opportunitiesList[ind] = [...tempArr];
            for (const mate of existingSelectedopportunities) {
              if (!existingSelected.find((ins: any) => ins == mate)) {
                this.opportunitiesList[ind].push(this.s_w_o_t.opportunities.find((mat: any) => mat.ID == mate));
                existingSelected.push(mate);
              }
            }
          }
        });
    }
    else if (type == 4) {
      tempArr = this.threatsList[index].filter((threats: any) => threats.ID != this.SWOT.threats[index]);
      if (tempArr.length)
        this.threatsList.map((threats: any, ind: number) => {
          if (index != ind) {
            const existingSelectedthreats = this.SWOT.threats.filter((mat: any) => this.threatsList[ind].find((m: any) => m.ID == mat) && mat != this.SWOT.threats[index]);
            this.threatsList[ind] = [...tempArr];
            for (const mate of existingSelectedthreats) {
              if (!existingSelected.find((ins: any) => ins == mate)) {
                this.threatsList[ind].push(this.s_w_o_t.threats.find((mat: any) => mat.ID == mate));
                existingSelected.push(mate);
              }
            }
          }
        });
    }
  }

  saveSwotPest() {

    const date = new Date();
    const getYear = date.getFullYear();
    const getMonth = date.getMonth();
    const getDate = date.getDate();
    const getHour = date.getHours();
    const getMinute = date.getMinutes();
    const getSecond = date.getSeconds();
    const getMilliSec = date.getMilliseconds();
    const fullDate = getYear + '-' + getMonth + '-' + getDate + ' ' + getHour + ':' + getMinute + ':' + getSecond + '.' + getMilliSec;
    let data = [];
    let newSWOTdata: any = [];
    let newPESTdata: any = [];

    const headerDate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en')
    const headerCreatedAt: any = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss', 'en')
    this.headerData.CreatedAt = headerCreatedAt;
    this.headerData.CreatedBy = this.user.UserId;
    this.spinner
    let swotData, swotValStatus, pestData, pestValStatus = false;


    if (this.SWOT.strength.length > 0 || this.newSWOT.strength.length > 0 || this.SWOT.weakness.length > 0 || this.newSWOT.weakness.length > 0 || this.SWOT.opportunities.length > 0 || this.newSWOT.opportunities.length > 0 || this.SWOT.threats.length > 0 || this.newSWOT.threats.length > 0 || this.SwotSubject) {
      swotData = true;

      if ((this.SWOT.strength.length > 0 || this.newSWOT.strength.length > 0) && (this.SWOT.weakness.length > 0 || this.newSWOT.weakness.length > 0) && (this.SWOT.opportunities.length > 0 || this.newSWOT.opportunities.length > 0) && (this.SWOT.threats.length > 0 || this.newSWOT.threats.length > 0) && this.SwotSubject)
        swotValStatus = true;
      else
        swotValStatus = false;
    }
    if (this.PEST.political.length > 0 || this.newPEST.political.length > 0 || this.PEST.economic.length > 0 || this.newPEST.economic.length > 0 || this.PEST.social.length > 0 || this.newPEST.social.length > 0 || this.PEST.technology.length > 0 || this.newPEST.technology.length > 0 || this.PestSubject) {
      pestData = true;
      if ((this.PEST.political.length > 0 || this.newPEST.political.length > 0) && (this.PEST.economic.length > 0 || this.newPEST.economic.length > 0) && (this.PEST.social.length > 0 || this.newPEST.social.length > 0) && (this.PEST.technology.length > 0 || this.newPEST.technology.length > 0) && this.PestSubject)
        pestValStatus = true;
      else
        pestValStatus = false;
    }

    if (swotData && swotValStatus) {
      // swot
      for (const strength of this.SWOT.strength) {
        if (strength)
          data.push({ header: this.headerData.ID, SwotOrPest: 1, SwotOrPestId: strength, Subject: this.SwotSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }
      for (const newstrength of this.newSWOT.strength) {
        if (newstrength)
          newSWOTdata.push({ SWOT_type: 1, Name: newstrength, Subject: this.SwotSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }

      for (const weakness of this.SWOT.weakness) {
        if (weakness)
          data.push({ header: this.headerData.ID, SwotOrPest: 1, SwotOrPestId: weakness, Subject: this.SwotSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }
      for (const newweakness of this.newSWOT.weakness) {
        if (newweakness)
          newSWOTdata.push({ SWOT_type: 2, Name: newweakness, Subject: this.SwotSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }

      for (const opportunities of this.SWOT.opportunities) {
        if (opportunities)
          data.push({ header: this.headerData.ID, SwotOrPest: 1, SwotOrPestId: opportunities, Subject: this.SwotSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }
      for (const newopportunities of this.newSWOT.opportunities) {
        if (newopportunities)
          newSWOTdata.push({ SWOT_type: 3, Name: newopportunities, Subject: this.SwotSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }

      for (const threats of this.SWOT.threats) {
        if (threats)
          data.push({ header: this.headerData.ID, SwotOrPest: 1, SwotOrPestId: threats, Subject: this.SwotSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }
      for (const newthreats of this.newSWOT.threats) {
        if (newthreats)
          newSWOTdata.push({ SWOT_type: 4, Name: newthreats, Subject: this.SwotSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }
    }
    else if (swotData) {
      this.toaster.warning('Warning', 'Please complete SWOT Section')
      return
    }

    if (pestData && pestValStatus) {
      //pest
      for (const political of this.PEST.political) {
        if (political)
          data.push({ header: this.headerData.ID, SwotOrPest: 2, SwotOrPestId: political, Subject: this.PestSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }
      for (const newpolitical of this.newPEST.political) {
        if (newpolitical)
          newPESTdata.push({ PEST_type: 1, Name: newpolitical, Subject: this.PestSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }

      for (const economic of this.PEST.economic) {
        if (economic)
          data.push({ header: this.headerData.ID, SwotOrPest: 2, SwotOrPestId: economic, Subject: this.PestSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }
      for (const neweconomic of this.newPEST.economic) {
        if (neweconomic)
          newPESTdata.push({ PEST_type: 2, Name: neweconomic, Subject: this.PestSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }

      for (const social of this.PEST.social) {
        if (social)
          data.push({ header: this.headerData.ID, SwotOrPest: 2, SwotOrPestId: social, Subject: this.PestSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }
      for (const newsocial of this.newPEST.social) {
        if (newsocial)
          newPESTdata.push({ PEST_type: 3, Name: newsocial, Subject: this.PestSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }

      for (const technology of this.PEST.technology) {
        if (technology)
          data.push({ header: this.headerData.ID, SwotOrPest: 2, SwotOrPestId: technology, Subject: this.PestSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }
      for (const newtechnology of this.newPEST.technology) {
        if (newtechnology)
          newPESTdata.push({ PEST_type: 4, Name: newtechnology, Subject: this.PestSubject, CreatedAt: fullDate, CreatedBy: this.user.UserId })
      }
    }
    else if (pestData) {
      this.toaster.warning('Warning', 'Please complete PEST Section')
      return
    }

    if ((swotData && swotValStatus) || (pestData && pestValStatus)) {
      this.headerData.NoOfM = 4;
      let values: any = {
        header: this.headerData,
        data: data,
        newSwot: newSWOTdata,
        newPest: newPESTdata,
        sSubject: this.SwotSubject,
        pSubject: this.PestSubject
      }
      if (this.updateStatus) {
        this.spinnerText = 'Updating';
        if (swotData && swotValStatus)
          this.spinnerText += ' SWOT ';
        if (swotData && swotValStatus && pestData && pestValStatus)
          this.spinnerText += '&';
        if (pestData && pestValStatus)
          this.spinnerText += ' PEST ';
        this.spinnerText += 'Data';
        this.spinner.show();
        this.analyticalToolsService.updateSwotPestData(values).subscribe((res: any) => {
          this.clearData();
          this.getSwotData();
          this.getPestData();
          this.spinner.hide();
          this.toaster.success(`Updated Successfully`, 'Success', {
            timeOut: 4000,
          });
        }, (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.ErrorMessage);
        })
      } else {
        this.spinnerText = 'Creating';
        if (swotData && swotValStatus)
          this.spinnerText += ' SWOT ';
        if (swotData && swotValStatus && pestData && pestValStatus)
          this.spinnerText += '&';
        if (pestData && pestValStatus)
          this.spinnerText += ' PEST ';
        this.spinnerText += 'Data';
        this.spinner.show();
        this.analyticalToolsService.addSwotPestData(values).subscribe((res: any) => {
          this.clearData();
          this.getSwotData();
          this.getPestData();
          this.spinner.hide();
          this.toaster.success(`Saved Successfully`, 'Success', {
            timeOut: 4000,
          });
        }, (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.ErrorMessage);
        })
      }
    }
    else {
      this.toaster.warning('Warning', 'Please make atleast one section complete')
      return
    }

  }

  checkDuplicate(swotOrPestName: string, name: any, index: number) {
    if (swotOrPestName == 'strength') {
      let probExist = this.orgstrengthList.find((orgStr: any, ind: number) => index != ind && orgStr.Name == name);
      if (probExist) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newSWOT.strength[index] = '';
      }
      let probExistNew = this.newSWOT.strength.find((newStr: any, ind: number) => index != ind && newStr == name);
      if (probExistNew) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newSWOT.strength[index] = '';
      }
    }
    if (swotOrPestName == 'weakness') {
      let probExist = this.orgweakNessList.find((orgWeakn: any, ind: number) => index != ind && orgWeakn.Name == name);
      if (probExist) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newSWOT.weakness[index] = '';
      }
      let probExistNew = this.newSWOT.weakness.find((newWeekn: any, ind: number) => index != ind && newWeekn == name);
      if (probExistNew) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newSWOT.weakness[index] = '';
      }
    }
    if (swotOrPestName == 'opportunities') {
      let probExist = this.orgopportunitiesList.find((orgOpportun: any, ind: number) => index != ind && orgOpportun.Name == name);
      if (probExist) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newSWOT.opportunities[index] = '';
      }
      let probExistNew = this.newSWOT.opportunities.find((newOpportune: any, ind: number) => index != ind && newOpportune == name);
      if (probExistNew) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newSWOT.opportunities[index] = '';
      }
    }
    if (swotOrPestName == 'threats') {
      let probExist = this.orgthreatsList.find((orgThreats: any, ind: number) => index != ind && orgThreats.Name == name);
      if (probExist) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newSWOT.threats[index] = '';
      }
      let probExistNew = this.newSWOT.threats.find((newThreat: any, ind: number) => index != ind && newThreat == name);
      if (probExistNew) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newSWOT.threats[index] = '';
      }
    }
    if (swotOrPestName == 'political') {
      let probExist = this.p_e_s_t.political.find((orgPolit: any, ind: number) => index != ind && orgPolit.Name == name);
      if (probExist) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newPEST.political[index] = '';
      }
      let probExistNew = this.newPEST.political.find((newPolit: any, ind: number) => index != ind && newPolit == name);
      if (probExistNew) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newPEST.political[index] = '';
      }
    }
    if (swotOrPestName == 'economic') {
      let probExist = this.p_e_s_t.economic.find((orgeconomic: any, ind: number) => index != ind && orgeconomic.Name == name);
      if (probExist) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newPEST.economic[index] = '';
      }
      let probExistNew = this.newPEST.economic.find((neweconomic: any, ind: number) => index != ind && neweconomic == name);
      if (probExistNew) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newPEST.economic[index] = '';
      }
    }
    if (swotOrPestName == 'social') {
      let probExist = this.p_e_s_t.social.find((orgsocial: any, ind: number) => index != ind && orgsocial.Name == name);
      if (probExist) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newPEST.social[index] = '';
      }
      let probExistNew = this.newPEST.social.find((newsocial: any, ind: number) => index != ind && newsocial == name);
      if (probExistNew) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newPEST.social[index] = '';
      }
    }
    if (swotOrPestName == 'technology') {
      let probExist = this.p_e_s_t.technology.find((orgtechnology: any, ind: number) => index != ind && orgtechnology.Name == name);
      if (probExist) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newPEST.technology[index] = '';
      }
      let probExistNew = this.newPEST.technology.find((newtechnology: any, ind: number) => index != ind && newtechnology == name);
      if (probExistNew) {
        this.toaster.warning('Warning', name + ' Value already exist in' + swotOrPestName);
        this.newPEST.technology[index] = '';
      }
    }
  }

  clearData() {
    this.headerData = {
      ID: 0,
      Group: '',
      Plant: '',
      Dept: '',
      Team: '',
      TeamLeader: '',
      InitProbStatement: '',
      NoOfM: 6,
      stageId: 6,
      uniqueCode: '',
      CreatedAt: new Date(),
      CreatedBy: this.user.UserId
    };
    this.SWOT = {
      strength: [],
      weakness: [],
      opportunities: [],
      threats: [],
    };
    this.newSWOT = {
      strength: [],
      weakness: [],
      opportunities: [],
      threats: [],
    };
    this.PEST = {
      political: [],
      economic: [],
      social: [],
      technology: []
    };
    this.newPEST = {
      political: [],
      economic: [],
      social: [],
      technology: []
    }
    this.strengthList = [];
    this.weakNessList = [];
    this.opportunitiesList = [];
    this.threatsList = [];
    this.orgPoliticalList = []
    this.orgEconomicList = []
    this.orgSocialList = []
    this.orgTechnologyList = []
    this.SwotSubject = '';
    this.PestSubject = '';
    this.isReset = true;
    this.analyticalToolsService.setHeaderId(this.headerData.ID);
  }

  checkTrue(pestValue: any, index: number, pestName: string) {

    if (pestName == "political") {
      if (pestValue) {
        this.orgPoliticalListnewCheck[index] = 1;
      } else {
        this.orgPoliticalListnewCheck[index] = 0;
      }
    }

    if (pestName == "economic") {
      if (pestValue) {
        this.orgEconomicListnewCheck[index] = 1;
      } else {
        this.orgEconomicListnewCheck[index] = 0;
      }
    }

    if (pestName == "social") {
      if (pestValue) {
        this.orgSocialListnewCheck[index] = 1;
      } else {
        this.orgSocialListnewCheck[index] = 0;
      }
    }

    if (pestName == "technology") {
      if (pestValue) {
        this.orgTechnologyListnewCheck[index] = 1;
      } else {
        this.orgTechnologyListnewCheck[index] = 0;
      }
    }


  }

  setSearchData(event: any) {

    if (event && event.swotAndPest && event.swotAndPest.length) {
      this.updateStatus = true;
      if (!Array.isArray(event.Team))
      event.Team = event.Team && event.Team.split(',').map((num: any) => num = parseInt(num));
      this.analyticalToolsService.setTeamLeaderList(event.Team);
      this.headerData = event;
      const SwotAndPest = [...event.swotAndPest];
      this.rcaId = event.rcaId;
      delete event.causeAndEffects;
      delete event.fishBone;
      delete event.fiveWhy;
      delete event.rca;
      delete event.rcaId;
      delete event.swotAndPest;
      this.analyticalToolsService.setHeaderId(this.headerData.ID);
      this.analyticalToolsService.setRcaId(this.rcaId);
      this.analyticalToolsService.setRca(false);
      this.headerData.CreatedBy = this.user.UserId;

      for (let sp of SwotAndPest) {
        if (sp.SwotOrPest == 1) {
          this.SwotSubject = sp.Subject
          let str = this.orgstrengthList.find((newWeekn: any, ind: number) => newWeekn.ID == sp.SwotOrPestId);
          if (str) {
            this.SWOT.strength.push(str.ID);
            let ind = this.s_w_o_t.strength.findIndex((obj: any) => obj.ID == str.ID);
            this.updateSWOTList(1, ind);
          }

          let weak = this.orgweakNessList.find((newWeekn: any, ind: number) => newWeekn.ID == sp.SwotOrPestId);
          if (weak) {
            this.SWOT.weakness.push(weak.ID);
            let ind = this.s_w_o_t.weakness.findIndex((obj: any) => obj.ID == weak.ID);
            this.updateSWOTList(2, ind);
          }

          let opr = this.orgopportunitiesList.find((newWeekn: any, ind: number) => newWeekn.ID == sp.SwotOrPestId);
          if (opr) {
            this.SWOT.opportunities.push(opr.ID);
            let ind = this.s_w_o_t.opportunities.findIndex((obj: any) => obj.ID == opr.ID);
            this.updateSWOTList(3, ind);
          }

          let trts = this.orgthreatsList.find((newWeekn: any, ind: number) => newWeekn.ID == sp.SwotOrPestId);
          if (trts) {
            this.SWOT.threats.push(trts.ID);
            let ind = this.s_w_o_t.threats.findIndex((obj: any) => obj.ID == trts.ID);
            this.updateSWOTList(4, ind);
          }
        }

        if (sp.SwotOrPest == 2) {
          this.PestSubject = sp.Subject
          let political = this.p_e_s_t.political.find((newWeekn: any, ind: number) => newWeekn.ID == sp.SwotOrPestId);
          if (political) {
            let ind = this.p_e_s_t.political.findIndex((obj: any) => obj.ID == political.ID);
            if (ind || ind == 0)
              this.orgPoliticalList[ind] = true;
            let obj = { checked: true };
            this.somethingClick(obj, this.p_e_s_t.political[ind]);
          }

          let economic = this.p_e_s_t.economic.find((newWeekn: any, ind: number) => newWeekn.ID == sp.SwotOrPestId);
          if (economic) {
            let ind = this.p_e_s_t.economic.findIndex((obj: any) => obj.ID == economic.ID);
            if (ind || ind == 0)
              this.orgEconomicList[ind] = true;
            let obj = { checked: true };
            this.somethingClick(obj, this.p_e_s_t.economic[ind]);

          }

          let social = this.p_e_s_t.social.find((newWeekn: any, ind: number) => newWeekn.ID == sp.SwotOrPestId);
          if (social) {
            let ind = this.p_e_s_t.social.findIndex((obj: any) => obj.ID == social.ID);
            if (ind || ind == 0)
              this.orgSocialList[ind] = true;
            let obj = { checked: true };
            this.somethingClick(obj, this.p_e_s_t.social[ind]);
          }

          let technology = this.p_e_s_t.technology.find((newWeekn: any, ind: number) => newWeekn.ID == sp.SwotOrPestId);
          if (technology) {
            let ind = this.p_e_s_t.technology.findIndex((obj: any) => obj.ID == technology.ID);
            if (ind || ind == 0)
              this.orgTechnologyList[ind] = true;
            let obj = { checked: true };
            this.somethingClick(obj, this.p_e_s_t.technology[ind]);
          }
        }

      }
    }
  }
}
