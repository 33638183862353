import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { QcBoxService } from 'src/app/services/qcBox.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { WarehouseService } from 'src/app/services/warehouse.service';
import * as moment from 'moment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-QC-Box',
  templateUrl: './QC-Box.component.html',
  styleUrls: ['./QC-Box.component.scss']
})
export class QCBoxComponent implements OnInit {
  @ViewChild('displayQualityHistoryModal') displayQualityHistoryModal!: TemplateRef<any>;
  @ViewChild('displayQualityStatusModal') private displayQualityStatusModal!: TemplateRef<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('sorter1') sorter1: any;
  @ViewChild('sorter2') sorter2: any;
  @ViewChild('sorter3') sorter3: any;

  qcBoxDataSource!: MatTableDataSource<any>;
  qcBoxColumns: any[] = ['BatchInfoId', 'ProcessOrder', 'PPD_Num', 'BatchNo', 'Qty', 'ResultOn', 'Remarks', 'createdOn', 'createdBy'];

  batchInfoDataSource!: MatTableDataSource<any>;
  batchInfoColumns: any[] = ['action', 'BatchInfoId', 'ProcessOrder', 'PPD_Num', 'BatchNo', 'Qty', 'ResultOn', 'Remarks', 'createdOn', 'createdBy'];

  // qualityHistoryDataSource!: MatTableDataSource<any>;
  // qualityHistoryColumns: any[] = ['QCBatchInfoId', 'ProcessOrder', 'ItemCode', 'ItemDesc', 'QCStatus', 'Remarks', 'BatchNo', 'Result', 'CreatedOn', 'CreatedBy'];

  warehouseForm!: FormGroup;
  statusForm!: any;
  groupList!: any[];
  filteredGroupList!: any[];
  plantList!: any[];
  filteredPlantList!: any[];
  itemInfoList!: any[];
  filteredItemInfoList!: any[];
  processOrderList!: any[];
  filteredProcessOrderList!: any[];
  PalletId!: any;
  selectedRowIndex!: any;
  highLightedArray: any[] = [];
  breadcrumList: any;
  statusType!: any;
  remarksShowHideProperty = false;
  status!: any;
  statusList: any = [
    // { statusId: 0, statusType: 'Approve'},
    // { statusId: 1, statusType: 'Reject' },
    // { statusId: 2, statusType: 'Hold' },
  ];
  paginationPageSize!: any;
  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      // BtnRenderer: BtnDownload,
    },
  };
  gridApi!: any;
  gridColumnApi!: any;

  QualityHistoryColumns = [
    { field: 'QCBatchInfoId', headerName: 'QCBatchInfoId' },
    { field: 'ProcessOrder', headerName: 'ProcessOrder' },
    { field: 'ItemCode', headerName: 'ItemCode' },
    { field: 'ItemDesc', headerName: 'ItemDesc' },
    { field: 'QCStatus', headerName: 'QCStatus' },
    { field: 'Remarks', headerName: 'Remarks' },
    { field: 'BatchNo', headerName: 'BatchNo' },
    { field: 'Result', headerName: 'Result' },
    { field: 'CreatedOn', headerName: 'CreatedOn',
      valueFormatter: function (params: any) { return moment(params.value).format('DD/MM/YYYY HH:mm:ss') } 
    },
    { field: 'CreatedBy', headerName: 'CreatedBy' },
  ];
  qualityHistoryList: any = [];

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private qcBoxService: QcBoxService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.warehouseForm = this.fb.group({
      groupList: [''],
      plantList: [''],
      item: ['ALL'],
      itemDesc: ['ALL'],
      procOrder: ['ALL'],
      scantBatchNo: ['']
    });
    this.statusForm = this.fb.group({
      status: [''],
      remarks: ['']
    });
    this.GetBreadcrumList();
    this.GetGroups();
    this.GetQualityStatus();
    this.statusType = 'Approve';
    this.status = 0;
  }


  GetGroups(): void {
    this.highLightedArray = [];
    const key = {
      CreatedBy: 0
    };
    this.spinner.show();
    this.qcBoxService.GetGroups(key).subscribe((res: any) => {
      this.spinner.hide();
      this.groupList = res.masterList;
      this.filteredGroupList = this.groupList.slice();
      if (this.groupList && this.groupList.length)
        this.warehouseForm.get('groupList')?.setValue(res.masterList[0].TypeId);
      this.GetPlants();
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went rong');
    });
  }

  GetPlants(): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    this.highLightedArray = [];
    const key = {
      GroupId: this.warehouseForm.value.groupList,
      UserId: 0
    };
    this.spinner.show();
    this.qcBoxService.GetPlants(key).subscribe((res: any) => {
      this.spinner.hide();
      this.plantList = res.DepartList;
      this.filteredPlantList = this.plantList.slice();
      if (this.plantList && this.plantList.length)
        this.warehouseForm.get('plantList')?.setValue(res.DepartList[0].DeptId);
      this.GetItemInfo();
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went rong');
    });
  }

  GetItemInfo(): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    const key = {
      EGId: this.warehouseForm.value.groupList,
      PTId: this.warehouseForm.value.plantList
    };
    this.spinner.show();
    this.qcBoxService.GetItemInfo(key).subscribe((res: any) => {
      this.spinner.hide();
      this.itemInfoList = res.ItemInfoList;
      this.filteredItemInfoList = this.itemInfoList.slice();
      // this.warehouseForm.get('item')?.setValue(res.ItemInfoList[0].ItemCode);
      // this.warehouseForm.get('itemDesc')?.setValue(res.ItemInfoList[0].ItemDesc);
      // this.warehouseForm.get('item')?.setValue(this.itemInfoList[this.itemInfoList.length - 1].ItemCode);
      // this.warehouseForm.get('itemDesc')?.setValue(this.itemInfoList[this.itemInfoList.length - 1].ItemDesc);
      const index = this.itemInfoList.findIndex((el: any) => el.ItemDesc == 'ALL');
      if(index != -1){
        this.warehouseForm.get('item')?.setValue( this.itemInfoList[index].ItemCode);
        this.warehouseForm.get('itemDesc')?.setValue( this.itemInfoList[index].ItemDesc);  
      }
      this.GetProcessOrder();
      this.GetBatchInfoList();
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went rong');
    });
  }

  ItemChange(event: any, type: any) {
    let index:any;
    if (type == 'itemCode')
      index = this.itemInfoList.findIndex((el: any) => el.ItemCode == event.value);
    else
      index = this.itemInfoList.findIndex((el: any) => el.ItemDesc == event.value);
    this.warehouseForm.get('item')?.setValue(this.itemInfoList[index].ItemCode);
    this.warehouseForm.get('itemDesc')?.setValue(this.itemInfoList[index].ItemDesc);
    this.GetProcessOrder();
    this.GetBatchInfoList();
  }

  GetItemInfoDesc(event: any): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    const key = {
      EGId: this.warehouseForm.value.groupList,
      PTId: this.warehouseForm.value.plantList
    };
    this.spinner.show();
    this.qcBoxService.GetItemInfo(key).subscribe((res: any) => {
      this.spinner.hide();
      this.itemInfoList = res.ItemInfoList;
      this.filteredItemInfoList = this.itemInfoList.slice();
      // this.warehouseForm.get('item')?.setValue(res.ItemInfoList[0].ItemCode);
      // this.warehouseForm.get('itemDesc')?.setValue(res.ItemInfoList[0].ItemDesc);
      // this.warehouseForm.get('item')?.setValue(this.itemInfoList[this.itemInfoList.length - 1].ItemCode);
      // this.warehouseForm.get('itemDesc')?.setValue(this.itemInfoList[this.itemInfoList.length - 1].ItemDesc);
      const index = this.itemInfoList.findIndex((el: any) => el.ItemDesc == 'ALL');
      if(index != -1){
        this.warehouseForm.get('item')?.setValue( this.itemInfoList[index].ItemCode);
        this.warehouseForm.get('itemDesc')?.setValue( this.itemInfoList[index].ItemDesc);  
      }
      this.GetProcessOrder();
      this.GetBatchInfoList();
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went rong');
    });
  }

  GetDescInfoItem(event: any): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    const key = {
      EGId: this.warehouseForm.value.groupList,
      PTId: this.warehouseForm.value.plantList
    };
    this.spinner.show();
    this.qcBoxService.GetItemInfo(key).subscribe((res: any) => {
      this.spinner.hide();
      this.itemInfoList = res.ItemInfoList;
      const index = this.itemInfoList.findIndex((el: any) => el.ItemDesc == 'ALL');
      if(index != -1){
        this.warehouseForm.get('item')?.setValue( this.itemInfoList[index].ItemCode);
        this.warehouseForm.get('itemDesc')?.setValue( this.itemInfoList[index].ItemDesc);  
      }
      this.GetProcessOrder();
      this.GetBatchInfoList();
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went rong');
    });
  }

  GetProcessOrder(): void {
    const key = {
      ItemCode: this.warehouseForm.value.item
    };
    this.spinner.show();
    this.qcBoxService.GetProcessOrder(key).subscribe((res: any) => {
      this.spinner.hide();
      (res.masterList).unshift({"TypeId": 0, "TypeName": "ALL"});
      this.processOrderList = res.masterList;
      this.filteredProcessOrderList = this.processOrderList.slice();
      this.warehouseForm.get('procOrder')?.setValue(0);
      // if (res.masterList.length === 0) {
      //   this.warehouseForm.get('procOrder')?.setValue('ALL');
      // }
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went Wrong');
    });
  }

  GetBatchInfoList(): void {
    const key = {
      GroupId: this.warehouseForm.value.groupList,
      PlantId: this.warehouseForm.value.plantList,
      ItemCode: this.warehouseForm.value.item,
      PPId: 0
    };
    this.spinner.show();
    this.qcBoxService.GetBatchInfoList(key).subscribe((res: any) => {
      this.spinner.hide();
      this.qcBoxDataSource = new MatTableDataSource<any>(res.BatchInfoList);
      this.getPaginator(this.qcBoxDataSource, 0);
      this.qcBoxDataSource.sort = this.sorter1;
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went rong');
    });
  }

  selectedBatchInfoList(): void {
    const key = {
      GroupId: this.warehouseForm.value.groupList,
      PlantId: this.warehouseForm.value.plantList,
      ItemCode: this.warehouseForm.value.item,
      PPId: 0
    };
    this.spinner.show();
    this.qcBoxService.GetBatchInfoList(key).subscribe((res: any) => {
      this.spinner.hide();
      this.batchInfoDataSource = new MatTableDataSource<any>(res.BatchInfoList);
      this.getPaginator(this.batchInfoDataSource, 1);
      this.batchInfoDataSource.sort = this.sorter2;
      this.highLightedArray = this.batchInfoDataSource.data;
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went rong');
    });
  }

  GetQualityStatus(): void {
    this.spinner.show();
    this.qcBoxService.GetQualityStatus().subscribe((res: any) => {
      this.spinner.hide();
      this.statusList = res.masterList;
      this.statusForm.get('status')?.setValue(this.statusList[0].TypeId);
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went rong');
    });
  }

  MoveBatchInfoDataSource(): void {
    this.batchInfoDataSource = new MatTableDataSource<any>(this.qcBoxDataSource.data);
    this.getPaginator(this.batchInfoDataSource, 1);
    this.batchInfoDataSource.sort = this.sorter2;
  }

  clearBatchInfoDataData(): void {
    // this.warehouseForm = this.fb.group({
    //   groupList: [''],
    //   plantList: [''],
    //   item: ['ALL'],
    //   itemDesc: ['ALL'],
    //   procOrder: ['ALL'],
    //   scantBatchNo: ['']
    // });
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
  }

  updatehighlight(element: any): void {
    if (this.qcBoxDataSource.data.length !== this.batchInfoDataSource.data.length) {
      this.highLightedArray.push(element.BatchInfoId);
      console.log(this.highLightedArray);
      this.selectedRowIndex = element.BatchInfoId;
      if (this.batchInfoDataSource.data.length > 0) {
        const value = this.batchInfoDataSource.data.find((x: any) => x.BatchInfoId === element.BatchInfoId) ? true : false;
        if (!value) {
          this.batchInfoDataSource.data.push(element);
        }
        this.batchInfoDataSource = new MatTableDataSource<any>(this.batchInfoDataSource.data);
        this.getPaginator(this.batchInfoDataSource, 1);
        this.batchInfoDataSource.sort = this.sorter2;
      }
      else {
        this.batchInfoDataSource.data.push(element);
        this.batchInfoDataSource = new MatTableDataSource<any>(this.batchInfoDataSource.data);
        this.getPaginator(this.batchInfoDataSource, 1);
        this.batchInfoDataSource.sort = this.sorter2;
      }
    }
  }

  deleteBatchInfoDataData(element: any): void {
    const index = this.batchInfoDataSource.data.findIndex((x: any) => x.BatchInfoId === element.BatchInfoId);
    if (index > -1) {
      this.batchInfoDataSource.data.splice(index, 1);
      this.batchInfoDataSource = new MatTableDataSource<any>(this.batchInfoDataSource.data);
      this.getPaginator(this.batchInfoDataSource, 1);
      this.batchInfoDataSource.sort = this.sorter2;
    }
  }

  scanBatchNo() {
    if (this.qcBoxDataSource.data.length !== this.batchInfoDataSource.data.length) {
      const value = this.qcBoxDataSource.data.filter((x: any) => x.BatchNo == this.warehouseForm.value.scantBatchNo)
      if (value && value.length) {
        for (const row of value) {
          if (this.batchInfoDataSource.data.length > 0) {
            const obj = this.batchInfoDataSource.data.find((x: any) => x.BatchInfoId === row.BatchInfoId) ? true : false;
            if (!obj) {
              this.batchInfoDataSource.data.push(row);
            }
          }
          else {
            this.batchInfoDataSource.data.push(row);
          }
          this.getPaginator(this.batchInfoDataSource, 1);
          this.batchInfoDataSource.sort = this.sorter2;
        }
      }
      else {
        this.toaster.error('', 'Scanned Wrong Barcode!');
      }
    }
    else {
      this.toaster.error('', 'All BatchInfo Data Has Been Selected!');
    }
    this.warehouseForm.patchValue({ 'scantBatchNo': null });
  }

  warehouseHistoryModal(): void {
    this.dialog.open(this.displayQualityHistoryModal, { minWidth: '90%', height: '90vh' });
    this.GetQualityInfoHistoryList();
  }

  GetQualityInfoHistoryList(): void {
    const key = {
      PTID: this.warehouseForm.value.plantList,
      ItemCode: this.warehouseForm.value.item,
    };
    this.spinner.show();
    this.qcBoxService.GetQualityInfoList(key).subscribe((res: any) => {
      this.spinner.hide();
      this.qualityHistoryList = res.QualityInfoList;
      // this.qualityHistoryDataSource = new MatTableDataSource<any>(res.QualityInfoList);
      // this.getPaginator(this.qualityHistoryDataSource, 2);
      // this.qualityHistoryDataSource.sort = this.sorter3;
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went rong');
    });
  }

  changeStatus(event: any): void {
    const typeStatus = this.statusList.find((x: any) => x.TypeId === event.value);
    this.statusType = typeStatus.TypeName;
    this.remarksShowHideProperty = this.statusType == 'Hold' || this.statusType === 'Reject' ? true : false;;
  }

  SubmitQuality(): void {
    if (this.batchInfoDataSource.data && this.batchInfoDataSource.data.length) {
    let arr: any = [];
    for (const palletID of this.batchInfoDataSource.data)
      arr.push({ PallateBoxId: palletID.BatchInfoId })
    const key = {
      BoxList: arr,
      QCStatusId: this.statusForm.value.status,
      IsPallet: false,
      Remarks: this.statusForm.value.remarks
    };
    this.spinner.show();
    this.qcBoxService.SubmitQuality(key).subscribe((res: any) => {
      this.spinner.hide();
      if (res.HasError)
        this.toaster.error(res.ErrorMessage);
      else
        this.toaster.success(res.ErrorMessage);
      this.GetBatchInfoList();
      this.batchInfoDataSource.data = [];
      this.statusForm.get('remarks')?.setValue('');
      // this.clearBatchInfoDataData();
      this.dialog.closeAll();
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went rong');
    });
  }
    else{
      this.toaster.warning('Warning', 'Please Select The Batch Info');
    }
  }

  openPopUpViewForQualityStatus(): void {
    if(this.batchInfoDataSource.data && this.batchInfoDataSource.data.length){
      if(this.statusForm.get('status').value == ''){
        this.toaster.warning('Warning', 'Please Select The Status');
      }
      else{
        this.dialog.open(this.displayQualityStatusModal, {
          minWidth: '50%',
          height: 'unset',
          disableClose: true
        });
      }
    }
    else{
      this.toaster.warning('Warning', 'Please Select The Batch Info');
    }

  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
    }, 100);
  }

  applyFilterForSelectedBatchInfo(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.batchInfoDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForqcBoxDataSource(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.qcBoxDataSource.filter = filterValue.trim().toLowerCase();
  }

  // applyFilterForPalletReceivedHistory(event: any): void {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.qualityHistoryDataSource.filter = filterValue.trim().toLowerCase();
  // }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        console.log(data.BreadcrumList);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
