<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">QX Matrix Reports</h1>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

  <form [formGroup]="qxmatrixreportDataForm">

    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row">

        <mat-form-field appearence="fill" 
        class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-select formControlName="group" placeholder="group" panelClass="testClass" name="Status"  (selectionChange)="onChangeGroup($event)">
          <mat-option *ngFor="let plant of groupList" [value]="plant.TypeId">
            {{plant.TypeName}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field appearence="fill" 
      class="top-row-first-filed-item top-row-first-filed-item-order-processing">
      <mat-select formControlName="plant" placeholder="Plant"    (selectionChange)="getDefectReports()" panelClass="testClass" name="Plant">
        <mat-option *ngFor="let plant of Plants" [value]="plant.DeptId">
          {{ plant.DeptDesc }}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
      <mat-label>Select Parameters</mat-label>
      <mat-select formControlName="parameter" (selectionChange)="selectParameters($event)">
        <mat-option [value]="0">Process Parameters</mat-option>             
      <mat-option [value]="1">Quality Parameters</mat-option>             
      </mat-select>
    </mat-form-field>
         

     
        <!-- <div class="btn-position">
          <button mat-flat-button color="accent" (click)="onSubmit()" class="btn-accent-big">
            Add
          </button>
        </div> -->

      </div>
    </div>
    <div class="wrapper-box wrapper-box-shadow grid-container">
        <ag-grid-angular
   style="width: 100%; height: 25vw"
    class="ag-theme-alpine"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)" 
    [icons]= "icons" 
    [pagination]= "true" >
</ag-grid-angular>


    </div>
  </form>



</div>
