import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticalToolsService {

  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  headerId: number;
  fileteredData: any = [];
  teamLeaderList: any = [];
  historicaTeamLeaderList: any = [];
  currentIndex: number = 0;
  status: boolean = false;
  levelId: any;
  SwotPestData: any = [];
  historicalHeaderValues: any;

  constructor(private http: HttpClient) {
    this.headerId = 0;
  }

  getFilteredIndex() {
    return this.currentIndex;
  }

  setFilteredIndex(currentIndex: number) {
    this.currentIndex = currentIndex;
  }

  setFilteredData(fileteredData: any) {
    this.fileteredData = fileteredData;
  }

  getFilteredData() {
    return this.fileteredData;
  }

  setHeaderId(headerId: number) {
    const HeaderId: any = headerId ? headerId.toString() : headerId;
    localStorage.setItem('headerId', HeaderId);
  }

  setTeamLeaderList(teamLeaderList: any) {
    this.teamLeaderList = teamLeaderList;
  }

  getTeamLeaderList() {
    return this.teamLeaderList;
  }

  setHistoricalTeamLeaderList(list:any){
    this.historicaTeamLeaderList = list;
  }
  getHistoricalTeamLeaderList(){
    return this.historicaTeamLeaderList;
  }

  setSWOTPESTData(data:any){
    this.SwotPestData = data;
  }

  getSWOTPESTData(){
    return this.SwotPestData;
  }

  setHistoricalHeaderData(data:any){
    this.historicalHeaderValues = data;
  }

  getHistoricalHeaderData(){
    return this.historicalHeaderValues;
  }

  getHeaderId() {
    const headerId: any = localStorage.getItem('headerId');
    return parseInt(headerId);
  }

  getUserGroup() {
    const egId = localStorage.getItem('egId');
    return egId ? parseInt(egId) : null;
  }

  setRcaId(rcaId: number) {
    const RcaId: any = rcaId ? rcaId.toString() : rcaId;
    localStorage.setItem('rcaId', RcaId);
  }

  getRcaId() {
    const rcaId: any = localStorage.getItem('rcaId');
    return parseInt(rcaId);
  }

  setStatus(status: boolean) {
    this.status = status;
    // localStorage.setItem('status', Status);
  }

  getStatus() {
    // const status: any = localStorage.getItem('status');
    return this.status;
  }

  setLevelId(levelId: number) {
    this.levelId = levelId ? levelId.toString() : levelId;
    // localStorage.setItem('levelId', LevelId);
  }

  getLevelId() {
    // const levelId: any = localStorage.getItem('levelId');
    return parseInt(this.levelId);
  }

  setRca(rca: boolean) {
    localStorage.setItem('rca', rca ? 'true' : 'false');
  }

  getRca() {
    const rca = localStorage.getItem('rca');
    return rca == 'true' ? true : false;
  }

  // getUserGroup(data: any): Observable<any[]> {
  //   const token = localStorage.getItem('token');
  //   const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  //   return this.http.post<any[]>(this.o3ApiUrl + 'Dashboard/GetEnterpriseList', data, { headers: header });
  // }

  searchHeader(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(this.baseUrl + 'search-header', { headers: header, params: data });
  }

  // *** Core Api Method *** //
  getSwotData(): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    //return this.http.get<any[]>(this.baseUrl + 'swots', { headers: header });
    return this.http.get<any[]>(this.o3CoreApiUrl + 'atswot/getswot', { headers: header });
  }

  // *** Core Api Method *** //
  getPestData(): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    //return this.http.get<any[]>(this.baseUrl + 'pests', { headers: header });
    return this.http.get<any[]>(this.o3CoreApiUrl + 'ATPest/GetPest', { headers: header });
  }

  // *** Core Api Method *** //
  addSwotPestData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    //return this.http.post<any[]>(this.baseUrl + 'add-swot-and-pest', data, { headers: header });
    return this.http.post<any[]>(this.o3CoreApiUrl + 'AtSwotAndPest/AddSwotPest', data, { headers: header });
  }

  updateSwotPestData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.put<any[]>(this.baseUrl + 'update-swot-and-pest', data, { headers: header });
  }

  //stage dashboard 
  getStageTeamCountByUser(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.baseUrl + 'get-stage-team-count-by-user', data, { headers: header });
  }

  getStagesDetailTableView(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(this.baseUrl + 'get-stages-detai-table-view', { headers: header, params: data });
  }

  getStagesDetailGraphView(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.baseUrl + 'get-stages-detai-graph-view', data, { headers: header });
  }

  getGraphDeptViseActionPlan(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(this.baseUrl + 'get-graph-dept-vise-action-plan', { headers: header, params: data });
  }

  getStageDataById(type: any, id: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(this.baseUrl + 'get-stage-data-by-id', { headers: header, params: { type: type, id: id } });
  }

  //swot & pest 
  getSwotPestAllDept(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(this.baseUrl + 'get-swot-pest-all-dept', data, { headers: header });
  }

  getSwotPestDataByDept(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(this.baseUrl + 'get-swot-pest-data-by-dept', { headers: header, params: data });
  }

  // *** Core Api Method ***//
  getUniqueCode(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.post<any[]>(this.baseUrl + 'get-unique-code-by-dept', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ATDashboard/GetUniqueCodeBydept', data);
  }

  // *** Core Api Method *** //
  getHistoricalData(data:any){
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.post<any[]>(this.baseUrl + 'get-historical-data', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'AT/SearchHistoricalData', data);
  }

  setRejectRow(data: any) {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.post<any[]>(this.baseUrl + 'set-reject-row', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ATDashboard/setRejectData', data);
  }
}


