import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Driver } from 'src/app/models/driver';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { UserManu } from 'src/app/models/userManu';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss'],
})
export class DriversComponent implements OnInit {
  @Input() dPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<Driver>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  drivers!: Driver[];
  driverId!: number;
  plants!: Plantbygroup[];
  driverForm!: FormGroup;

  formMode!: boolean;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  plantId!: number;

  driverModelVisible = false;

  driverColumns: string[] = ['driverActions', 'plant', 'name', ];
  driverData!: MatTableDataSource<Driver>;
  IsAccess: any;
  IsWrite: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!:any;
  breadcrumList: any;
  constructor(
    private kpiService: KpimasterService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private platModelService: EnterPriseGroupService,
    private translater: TranslaterService,
    private spinner: NgxSpinnerService,
    private usermanagementService: UserManagementService,
  ) {
  }
  ngOnInit(): void {

    this.GetMenuAccess();
    //this.getLangWords();
    this.GetBreadcrumList();
    this.isRead = this.dPermissions.IsAccess;
    this.isWrite = this.dPermissions.IsWrite;
    this.isUpdate = this.dPermissions.IsWrite;
    this.isDelete = this.dPermissions.IsWrite;
    this.formMode = true;
    this.driverForm = this.fb.group({
      drivername: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      plant: ['', Validators.required],
    });
  }

  


//menu and breadcrumb
GetBreadcrumList(): any {
  const obj = {
    MenuId:
      localStorage.getItem('O3RightMenuId') === null
        ? 1
        : localStorage.getItem('O3RightMenuId'),
  };
  // tslint:disable-next-line: align
  this.usermanagementService.GetBreadcrumList(obj).subscribe(
    (data: any) => {
      this.breadcrumList = data.BreadcrumList;
      this.moduleId = this.breadcrumList[0].id;
      this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      this.getLangWords();
    },
    (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    }
  );
}

public getLangWords() {
  const key = {
    ModuleId: this.moduleId,
    PageId: this.pageId,
    LanguageId: 1
  };
  this.translater.getLangWords(key).subscribe((res: any) => {
    let obj: any = {};
    res.tagList.map((elem: any) => {
      obj[elem.tagName] = elem.translatedValue;
    });
    this.langObj = obj;
  });
}
  
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getPaginator(): void {
    setTimeout(() => {
      this.driverData.paginator = this.paginator;
      this.driverData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.driverData.filter = filterValue.trim().toLowerCase();

    if (this.driverData.paginator) {
      this.driverData.paginator.firstPage();
    }
  }

  getPlants(): void {
    this.kpiService.getAllPlants().subscribe(
      (plants) => {
        this.plants = plants;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  openModal(): void {
    if (this.isWrite) {
      this.resetForm();
      this.driverForm.get('plant')?.setValue(this.plantId);
      this.formMode = true;
      this.driverModelVisible = true;
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  getMasterDrivers(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.kpiService.getMasterDrivers(key).subscribe(
      (drivers) => {
        this.driverData = new MatTableDataSource<Driver>(drivers);
        this.getPaginator();
        this.getPlants();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      }
    );
  }

  getDrivers(): void {
    this.spinner.show();

    const key = {
      plantId: this.plantId,
    };
    this.kpiService.getMasterDrivers(key).subscribe(
      (drivers) => {
        this.spinner.hide();

        this.driverData = new MatTableDataSource<Driver>(drivers);
        this.getPaginator();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error(error.error.message);
      }
    );
  }

  getOneDriver(driverId: number): void {
    if (this.isUpdate) {
      const key = {
        driverId,
      };
      this.kpiService.getOneDriver(key).subscribe(
        (driver) => {
          this.driverId = driverId;
          this.driverForm.get('drivername')?.setValue(driver.name);
          this.plants = [];
          this.getPlants();
          this.driverForm.get('plant')?.setValue(this.plantId);
          this.formMode = false;
          this.driverModelVisible = true;
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  submitForm(): void {
    this.spinner.show();
    const driver = {
      plantId: this.driverForm.value.plant,
      name: this.driverForm.value.drivername,
    };
    this.kpiService.addDriver(driver).subscribe(
      (response) => {
    this.spinner.hide();

        this.toaster.success('Success', 'Driver Added Successfully');
        this.getDrivers();
        this.closeDriverModel();
        this.resetForm();
      },
      (error) => {
    this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }

  updateForm(): void {
    this.spinner.show();

    const driver = {
      driverId: this.driverId,
      plantId: this.driverForm.value.plant,
      name: this.driverForm.value.drivername,
    };
    this.kpiService.updateDriver(driver).subscribe(
      (response) => {
    this.spinner.hide();

        this.toaster.success('Success', 'Driver Updated Successfully');
        this.getDrivers();
        this.closeDriverModel();
        this.resetForm();
      },
      (error) => {
    this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }

  deleteDriver(driverId: number): void {
    if (this.isDelete) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete driver!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          const key = {
            driverId,
          };
          this.kpiService.deletDriver(key).subscribe(
            (success) => {
              Swal.fire({
                title: 'Deleted!',
                text: 'Your selected Driver has been deleted.',
                icon: 'success',
                timer: 800,
                showConfirmButton: false,
              });
              this.spinner.hide();
              this.getDrivers();
            },
            (error) => {
          this.spinner.show();

              this.toaster.error('Error', error.error.message);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your driver is safe :)', 'error');
        }
      });
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  resetForm(): void {
    this.driverForm.reset();
  }

  closeDriverModel(): void {
    this.driverModelVisible = false;
  }
}
