import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KpiDataPointService {

O3CoreApiUrl = environment.O3CoreApiUrl;
NetApiUrl:string= environment.O3CoreApiUrl;

constructor(private http: HttpClient) { }

GetUnitsByPlant(data: any): Observable<any[]> {
  // return this.http.post<any[]>(this.baseUrl + 'plantUnit', data);
  return this.http.post<any[]>(this.NetApiUrl + 'Machine/PlantUnit', data);
}

GetAllDataPoints(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.NetApiUrl + 'KPIDataPoints/GetAllDataPoints', data);
}

AddDataPoints(data: any): Observable<any[]> {
  // return this.http.post<any[]>(this.baseUrl + 'adddatapoints', data);
  return this.http.post<any[]>(this.O3CoreApiUrl + 'KPIDataPoints/AddDataPoints', data);
}

UpdateDataPoints(data: any): Observable<any[]> {
  // return this.http.post<any[]>(this.baseUrl + 'updatedatapoints', data);
  return this.http.post<any[]>(this.O3CoreApiUrl + 'KPIDataPoints/UpdateDataPoints', data);
}

DeleteDataPoints(data: any): Observable<any[]> {
  // return this.http.post<any[]>(this.baseUrl + 'deleteldatapoints', data);
  return this.http.post<any[]>(this.O3CoreApiUrl + 'KPIDataPoints/DeleteDataPoints', data);
}
getKPIDataSource(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.NetApiUrl + 'KPIIntegration/GetKPIDataSource', data);
}

getKPIO3Areas(data: any): Observable<any[]> {
  // return this.http.post<any[]>(this.baseUrl + 'getallkpio3areas', data);
  return this.http.post<any[]>(this.NetApiUrl + 'KPIIntegration/GetKPIO3Areas', data);
  
}

getOEEList(data: any): Observable<any[]> {
  // return this.http.post<any[]>(this.baseUrl + '/getoeelist', data);
  return this.http.post<any[]>(this.O3CoreApiUrl + 'KPIDataPoints/GetOEEList', data);
}

getDirectQueryList(data: any): Observable<any[]> {
  
  // return this.http.post<any[]>(this.baseUrl + '/getdirectquerylist', data);
  return this.http.post<any[]>(this.O3CoreApiUrl + 'KPIDataPoints/GetDirectQueryList', data);
}

}
