import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AreaSummaryComponent } from './area-summary/area-summary.component';
import { FaultAnalysisComponent } from './fault-analysis/fault-analysis.component';
import { MachineOeeAnalysisComponent } from './machine-oee-analysis/machine-oee-analysis.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { OeeAnalysisComponent } from './oee-analysis/oee-analysis.component';
import { ProductionComponent } from './production/production.component';
import { QualityComponent } from './quality/quality.component';

const routes: Routes = [
  { path: 'OEE-Analysis', component: OeeAnalysisComponent },
  { path: 'Machine-OEE-Analysis', component: MachineOeeAnalysisComponent },
  { path: 'Area-Summary', component: AreaSummaryComponent },
  { path: 'Production', component:ProductionComponent },
  { path: 'Quality', component: QualityComponent },
  { path: 'Maintenance', component: MaintenanceComponent },
  { path: 'Fault-Analysis', component: FaultAnalysisComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrpDashboardRoutingModule { }
