<div class="production-db-content">
    <div class="content-top-box">
        <ul class="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                    <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
                </li>
            </ul>
        </ul>
        <div class="title-row-flex-both-end">
            <h1 class="main-title">Quality</h1>
            <div>
                <button mat-raised-button color="accent" class="btn-accent mr-15-px">Live</button>
                <button mat-raised-button color="accent" class="btn-accent">Yesterday</button>
            </div>
        </div>
    </div>

    <app-dashboard-header></app-dashboard-header>

    <div class="d-flex">
        <div class="w-100">
            <div class="chart-items w-50 float-left" [ngClass]="{'mr-1': i%2==0, 'w-100': last}"
                *ngFor="let oee of wasteOEEChart; let i=index; let last = last;">
                <div class="chart-title">{{wasteData[i].title}}</div>
                <div [chart]="oee"></div>
            </div>
        </div>
        <div class="d-flex justify-content-around w-100 my-5px ">
            <div class="chart-items w-98">
                <div class="chart-title">Waste (Pcs)</div>
                <div style="text-align: -webkit-center; background-color: aqua;">
                    <p style="font-size: 200%;padding:50px;margin-bottom: 7px;">4.95M</p>
                </div>
                <div class="chart-items w-100">
                    <div class="chart-title">Waste</div>
                    <div
                        style="text-align: -webkit-center; font-size: 200%;background-color: aqua;padding:50px;margin-bottom: 7px;">
                        <p>2.89%</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-around w-100 my-5px">
            <div class="chart-items w-50 float-left" [ngClass]="{'mr-1': i%2==0, 'w-100': last}"
                *ngFor="let oee of wasteCategoryOEEChart; let i=index; let last = last;">
                <div class="chart-title">{{wasteCategoryData[i].title}}</div>
                <div [chart]="oee"></div>
            </div>
        </div>
    </div>
</div>