import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MachineMappingService {
  // tslint:disable-next-line:typedef
  baseUrl: string = environment.O3CoreApiUrl;
  apiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getMachineMappingData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Account/GetMachineMappingList', data);
  }

  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetDepartments', data);
  }

  getDepartmentData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetDepartmentByPlant', data);
  }

  getDepartmenBytPlant(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Machine/GetDepartmentByPlant', data);
  }

  getAllDepartmentData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetAllDepartmentByPlant', data);
  }
  getProductData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetProdLine', data);
  }

  getUnitData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetProdUnit', data);
  }

  AddMachineData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Account/SumbitMachineMapping', data);
  }
  getLineList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetLineList', data);
  }
  getUnitList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetUnitList', data);
  }
  getAssemblyList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetAssemblyList', data);
  }
  getComponentListByAssembly(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Dashboard/GetComponentListByAssembly', data);
  }
  getUnitbyUser(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.apiUrl + 'getunitbyuser', data);
  }
  async getComponentbyUnit(data: any): Promise<any> {
    return await this.http.post<any>(this.apiUrl + 'getcomponentbyunit', data).toPromise();
  }


}
