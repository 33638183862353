import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

const moment = _moment;

@Component({
  selector: 'app-quality-db',
  templateUrl: './quality-db.component.html',
  styleUrls: ['./quality-db.component.scss'],
})
export class QualityDbComponent implements OnInit {
  dropDownForm!: FormGroup;
  //Drop-down Variables
  yearList = ['2015', '2016', '2017', '2018', '2019', '2020', '2021'];
  monthList = [
    'ALL',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  weekList = [
    'ALL',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  dayList = [
    'ALL',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
  ];
  shiftList = ['ALL', 'Morning', 'Evening', 'Night'];
  machineList = ['ALL', 'RTM1', 'RTM2'];

  // Date Time Range
  range = new FormGroup({
    startDate: new FormControl(moment()),
    endDate: new FormControl(moment()),
  });

  // Charts

  // # of CCR
  ccr = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12'],
    barChartData: [
      {
        data: [1.8, 2.4, 3.4, 2.8, 1.4, 2.5, 1.8, 2.4, 2.8, 2.4, 1.7, 1.7],
        label: '  Target',
      },
    ],
    barChartColors: [{ backgroundColor: '#3AAFE4' }],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            barThickness: 20,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };

  // # of NCR
  ncr = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12'],
    barChartData: [
      { data: [8, 13, 18, 14, 7, 12, 8, 11, 14, 12, 8, 8], label: '  Target' },
    ],
    barChartColors: [{ backgroundColor: '#3AAFE4' }],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 5,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            barThickness: 20,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };

  // CCR by Project
  ccrByProject = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12'],
    barChartData: [
      {
        data: [1.8, 2.4, 3.4, 2.8, 1.4, 2.5, 1.8, 2.4, 2.8, 2.4, 1.7, 1.7],
        label: '  Target',
      },
    ],
    barChartColors: [{ backgroundColor: '#3AAFE4' }],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            barThickness: 20,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };

  // NCR by Project
  ncrByProject = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12'],
    barChartData: [
      {
        data: [1.8, 2.4, 3.4, 2.8, 1.4, 2.5, 1.8, 2.4, 2.8, 2.4, 1.7, 1.7],
        label: '  Target',
      },
    ],
    barChartColors: [{ backgroundColor: '#3AAFE4' }],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            barThickness: 20,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };

  // # of CCR Open vs Closed
  ccrOpenClosed = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12'],
    barChartData: [
      {
        data: [2, 3.2, 4.9, 5, 3, 1.5, 1.8, 1.6, 3, 3.2, 7.1],
        label: '  Open',
      },
      {
        data: [1.5, 1.8, 4.3, 4.3, 2.8, 1.4, 2.2, 1.5, 2.2, 4.4, 4.4, 4.4],
        label: '  Closed',
      },
    ],
    barChartColors: [
      { backgroundColor: '#040496' },
      { backgroundColor: '#10CE9C' },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            //barThickness: 10,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };

  // # of NCR Open vs Closed
  ncrOpenClosed = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12'],
    barChartData: [
      { data: [9, 14, 9, 12, 8, 5, 7, 6, 7, 14.2, 9], label: '  Open' },
      {
        data: [7, 7.2, 8, 6, 10.2, 8, 5, 7, 10.2, 11.2, 8, 7],
        label: '  Closed',
      },
    ],
    barChartColors: [
      { backgroundColor: '#040496' },
      { backgroundColor: '#10CE9C' },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 5,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            //barThickness: 10,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };

  // CCR Gap Analysis
  ccrGapAnalysis = {
    barChartLabels: ['Product', 'Service', 'Others', 'Total'],
    barChartData: [
      { data: [15, [15, 35], [35, 50.1], 0], label: '  Lorem Ipsem' },
      { data: [0, 0, 0, 0], label: '  Lorem Ipsem' },
      { data: [0, 0, 0, 50.1], label: '  Lorem' },
    ],
    barChartColors: [
      { backgroundColor: '#F85439' },
      { backgroundColor: '#10CE9C' },
      { backgroundColor: '#040496' },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
        },
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 10,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            barThickness: 60,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };

  // NCR Gap Analysis
  ncrGapAnalysis = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    barChartData: [
      { data: [15, 0, 0, 0, 0, 0, 0, 0], label: '  Lorem Ipsem' },
      {
        data: [0, [15, 35], [35, 49], [49, 55], [55, 58], [58, 60], 0, 0],
        label: '  Lorem Ipsem',
      },
      { data: [0, 0, 0, 0, 0, 0, 64, 0], label: '  Lorem' },
    ],
    barChartColors: [
      { backgroundColor: '#10CE9C' },
      { backgroundColor: '#F85439' },
      { backgroundColor: '#040496' },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
        },
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 10,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            barThickness: 60,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;

  constructor(
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    Chart.defaults.global.legend.labels.usePointStyle = true;
    this.dropDownForm = this.formBuilder.group({
      groupList: [''],
      UnitDataList: [''],
    });

    this.GetMenuAccess();
    this.GetBreadcrumList();
  }
  //breadCrumb

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        console.log(data.BreadcrumList);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
