import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { causeEffectModelElement } from 'src/app/models/analytical-toolsModels/cause-effectmodel';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { FivewhyService } from 'src/app/services/fivewhy.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ManagerExecutionService } from '../../services/manager.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from 'src/app/analytical-tools/image-dialog/image-dialog.component';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-fivewhy-actionplan',
  templateUrl: './fivewhy-actionplan.component.html',
  styleUrls: ['./fivewhy-actionplan.component.scss'],
})
export class FivewhyActionplanComponent implements OnInit {
  newGroupItemParent!: causeEffectModelElement;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Output() _emitHeaderData: EventEmitter<any> = new EventEmitter();
  @Input('data')
  set data(data: any) {
    //do whatever you want with your data here, this data will be passed from parent component
  }
  @Input() headerID: any;
  @Input() _permission: any;

  causeEffect: any = [];
  stageName: string = '5 Why Action Plan';
  stageId: number = 5;
  breadcrumList!: any;
  spinnerText: string = '';
  headerData: any = {
    ID: 0,
    Group: '',
    Plant: '',
    Dept: '',
    Team: '',
    TeamLeader: '',
    InitProbStatement: '',
    NoOfM: 6,
    stageId: 5,
    uniqueCode: '',
    CreatedAt: new Date(),
    CreatedBy: '',
  };
  user: any;
  rcaId: number = 0;
  updateStatus: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatCheckbox) checkbox!: MatCheckbox;

  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileInputFile') fileInputFile!: ElementRef;
  @Input() actionPlan_: EventEmitter<any> = new EventEmitter();
  @Input() headerData_: EventEmitter<any> = new EventEmitter();
  imageFile: any;
  imageSrc: any;
  // ImageUrl: any;
  blobStorageUrl: any;
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;

  // causeEffectModelColumns: string[] = ['RootCause', 'Action', 'ActionType', 'Picture', 'File', 'AssignTo', 'AssignedDate', 'TargetDate', 'RevisedDate', 'No Of Misses', 'ActionCompleted (Yes/No)', 'ActionCompleted (By & On)', 'Verification (Approved / Disapproved)', 'Verification (By & On)', 'New Assignee', 'ClosedDate', 'Remarks', 'Verified', 'VerifiedBy', 'Status', '', ''];

  causeEffectModelColumns: string[];

  fiveWhy: any = [];
  userList: any = [];
  filteredUserList: any = [];
  filteredVerifyUserList: any = [];

  fiveWhyActionPlanDataSource = new MatTableDataSource();
  formData: any = [];
  levelId: number = 5;
  isReset: boolean = false;
  FileList: any = [];
  FileData: any = [];
  ind: any = 0;
  UploadFile: any = [];
  IndividualFivewhyID: number = 0;
  permisson!: any;
  IsWrite: boolean = false;
  IsAccess!: any;
  orgfiveWhy: any = [];
  userAllList: any = [];

  newUserList: any = [];
  newUserFiltList: any = [];
  newOrgUserList: any = [];
  newOrgUserFiltList: any = [];
  sub: Subscription;
  cycleStatuses: any;
  constructor(
    public managerExecutionService: ManagerExecutionService,
    private FivewhyService: FivewhyService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private analyticalToolsService: AnalyticalToolsService,
    private userManagementService: UserManagementService,
    private dialog: MatDialog,
    private enterpriseGroupService: EnterPriseGroupService,
    private commonService: CommonService,
    private dataService: DataService
  ) {}

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  ngOnInit(): void {
    this.sub = this.actionPlan_.subscribe((event: any) => {
      this.saveFiveWhyPlan(event);
    });
    window.scrollTo(0, 0);

    this.dataService.getData().subscribe((value: any) => {
      this.headerData = value;
    });

    this.permisson = this._permission;
    this.IsWrite = this.permisson?.IsWrite;
    // this.ImageUrl = environment.ImageUrl;
    this.blobStorageUrl = environment.O3CoreApiImageUrl;
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.headerData.CreatedBy = this.user.UserId;
    const userGroup = this.analyticalToolsService.getUserGroup();
    if (userGroup) this.headerData.Group = userGroup;
    this.fiveWhyActionPlanDataSource = new MatTableDataSource(this.fiveWhy);

    this.headerData.ID = this.headerID;

    this.getAllUserList();
  }
  createColumns() {
    let columns = [
      'Root Cause',
      'Action',
      'Action Type',
      'Picture (jpg,png,jpeg)',
      'File (pdf,doc)',
      'Assign To',
      'Assigned Date',
      'Target Date',
      'Revised Date',
      'No Of Misses',
      'Action Completed (Yes/No)',
      'Action Completed (By & On)',
      'Verification (Approved / Disapproved)',
      'Verified (By & On)',
      'New Assignee',
      'Closed Date',
      'Remarks',
      'Status',
      'Actions',
    ];
    let route: any = this.router.url;
    route = route.split('/');
    route = route.pop();
    const limitedColumns = ['MeetingMatrixV2', 'Scorecard'];
    if (limitedColumns.includes(route)) {
      // if (this.cycleStatuses.StatusCycle === 'InProgress') {
      //   this.causeEffectModelColumns = columns;
      // } else
      if (this.cycleStatuses.StatusCycle === 'Completed') {
        this.causeEffectModelColumns = columns;
      } else {
        this.causeEffectModelColumns = [
          'Root Cause',
          'Action',
          'Action Type',
          'Picture (jpg,png,jpeg)',
          'File (pdf,doc)',
          'Assign To',
          'Assigned Date',
        ];
      }
    } else {
      this.causeEffectModelColumns = columns;
    }

  }
  showRemainingColumns() {
    let route: any = this.router.url;
    route = route.split('/');
    route = route.pop();
    const limitedColumns = ['MeetingMatrixV2', 'Scorecard'];
    if (limitedColumns.includes(route)) {
      // if (this.cycleStatuses.StatusCycle === 'InProgress') {
      //   return true;
      // }
      return false;
    } else {
      return true;
    }
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.spinnerText = 'Loading Menu Data';
    // this.spinner.show();
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        // this.spinner.hide();
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        // this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getFiveWhyActionPlan(reset: any): any {
    this.spinnerText = 'Fetching Five Why Plan Data';
    this.spinner.show();
    const data = { headerId: this.analyticalToolsService.getHeaderId() || 0 };
    this.FivewhyService.getFiveWhyPlanData(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.updateStatus = res.updateStatus;
        this.levelId = res && res.stageId ? res.stageId : 0;
        this.analyticalToolsService.setTeamLeaderList(res.Team);
        this.rcaId = res.rcaId ? res.rcaId : 0;

        delete res.rcaId;

        if (res.ID) {
          this.headerData = res;
        }
        // this.analyticalToolsService.setHeaderId(this.headerData.ID);
        this.analyticalToolsService.setRcaId(this.rcaId);
        this.analyticalToolsService.setLevelId(this.levelId);
        this.analyticalToolsService.setStatus(this.updateStatus);

        this.cycleStatuses = res;
        this.fiveWhy = res.fiveWhyActionPlan;
        this.createColumns();
        if (this.levelId != 0 && this.levelId != 5) {
          if (this.fiveWhy && this.fiveWhy.length) {
            const actionplan = this.fiveWhy.filter(
              (fiveY: any) => fiveY.RootCause != ''
            );
            this.fiveWhy = actionplan && actionplan.length ? actionplan : [];
            this.orgfiveWhy = this.fiveWhy;
          }
          if (this.IndividualFivewhyID) {
            const actionplan = this.fiveWhy.filter(
              (fiveY: any) => fiveY.fiveWhyId == this.IndividualFivewhyID
            );
            this.fiveWhy = actionplan && actionplan.length ? actionplan : [];
          }
        } else {
          if (this.fiveWhy && this.fiveWhy.length == 1) {
            this.fiveWhy[0].MainRowShow = true;
          }
          this.orgfiveWhy = this.fiveWhy;
        }

        if (this.levelId == 0 || this.levelId == 5) {
          if (this.fiveWhy && this.fiveWhy.length && this.fiveWhy.length > 1) {
            this.setFyActionSort();
          }
        }

        for (const fya of this.fiveWhy) {
          const mRecords = this.fiveWhy.filter(
            (fiveY: any) => fya.fiveWhyId == fiveY.fiveWhyId
          );
          if (mRecords && mRecords.length) {
            let disabled = mRecords.map((rec: any, index: number) => {
              if (index == 0)
                rec.rowSpan = mRecords && mRecords.length ? mRecords.length : 1;
              else {
                rec.spanRow = true;
              }
            });
          }
          fya.NoOfMisses = fya.NoOfMisses ? fya.NoOfMisses : 0;
          fya.MissesCondition = true;

          if (fya.Status == 'InProgress') {
            let currentDate: any = new Date();
            let targetDate: any = new Date(fya.TargetDate);
            currentDate = moment(currentDate).format('YYYY-MM-DD');
            targetDate = moment(targetDate).format('YYYY-MM-DD');
            if (currentDate > targetDate) {
              fya.Status = 'Delayed';
              fya.NoOfMisses = 1;
            }
          }
          fya.ActionCompleted = fya.ActionCompleted == 'Yes' ? 'Yes' : 'No';
        }

        let a = this.fiveWhy.filter((obj: any) => !obj.spanRow);
        if (a && a.length) {
          for (let index = 0; index < a.length; index++) {
            if (index == a.length - 1) a[index].MainRowShow = true;
            else a[index].MainRowShow = false;
          }
        }

        if (reset == 'reset' && !this.headerData.ID) this.resetData();
        setTimeout(() => {
          this.getUserList();
        }, 1500);

        this.fiveWhyActionPlanDataSource = new MatTableDataSource(this.fiveWhy);
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  getUserList() {
    this.spinnerText = 'Fetching User Data';
    this.spinner.show();
    // this.headerData = this._headerData;
    const data = {
      EGId: this.headerData?.group || this.headerData?.Group,
      PlantId: this.headerData?.plant || this.headerData?.Plant,
    };

    this.commonService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        if (!res.HasError) {
          // this.orgMachineryList.map((machinery: any) => this.machineryList.push([...this.mList.machineryList]));
          this.userList = res.employeeInfoList;
          this.userList.map(
            (user: any) =>
              (user.FullName =
                user.FirstName.trim() + ' ' + user.lastName.trim())
          );

          this.filteredUserList = this.userList.slice();
          this.filteredVerifyUserList = this.userList.slice();

          this.fiveWhy.map((orgUser: any) =>
            this.newUserList.push([...this.userList])
          );
          this.fiveWhy.map((orgUser: any) =>
            this.newOrgUserList.push([...this.userList])
          );
          this.newUserFiltList =
            this.newUserList && this.newUserList.length
              ? this.newUserList.slice()
              : [];
          this.newOrgUserFiltList =
            this.newOrgUserList && this.newOrgUserList.length
              ? this.newOrgUserList.slice()
              : [];
        }
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  searchValues(filter: any, index: number, type: any) {
    filter = filter.target.value;
    if (type == 'assignTo') {
      this.newUserList[index] = this.userList.filter((user: any) => {
        let lowerCase = user.fullName.toLocaleLowerCase();
        return user.fullName.startsWith(filter) || lowerCase.startsWith(filter);
      });
    } else if (type == 'newAssignee') {
      this.newOrgUserList[index] = this.userList.filter((user: any) => {
        let lowerCase = user.fullName.toLocaleLowerCase();
        return user.fullName.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
  }

  getAllUserList() {
    // this.spinnerText = "Fetching User Data";
    this.spinner.show();
    this.managerExecutionService.getUserList().subscribe(
      (res: any) => {
        this.userAllList = res.UserList;
        this.spinner.hide();
        this.getFiveWhyActionPlan('');
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  setSort() {
    this.fiveWhy.sort(function (a: any, b: any) {
      return a.fiveWhyId - b.fiveWhyId;
    });
  }

  setFyActionSort() {
    let i = 1;
    for (const fya of this.fiveWhy) {
      let arr = this.fiveWhy.filter(
        (fiveY: any) => fiveY.FyActionId == fya.FyActionId
      );
      if (arr && arr.length && !fya.fiveWhyId) {
        arr.map((cause: any, index: number) => {
          cause.fiveWhyId = i;
        });
        i++;
      }
    }
    this.fiveWhy.map((cause: any, index: number) => {
      delete cause.FyActionId;
    });
  }

  addRow(element: any, i: number, type: any) {
    let obj: any;
    if (type == 'sub') {
      obj = {
        fiveWhyId: element.fiveWhyId,
        RootCause: element.RootCause,
        Action: '',
        ActionType: element.ActionType,
        AssignTo: '',
        NewAssignee: '',
        AssignedDate: null,
        TargetDate: null,
        RevisedDate: null,
        NoOfMisses: 0,
        ActionCompleted: 'No',
        ActionCompletedBy: '',
        ActionCompletedOn: null,
        Verification: '',
        VerificationBy: '',
        VerificationOn: null,
        ClosedDate: null,
        Status: 'Open',
        VerifiedBy: '',
        FromActionPlan: true,
      };
      obj.MainRowShow = false;
    } else if (type == 'main') {
      obj = {
        fiveWhyId: element.fiveWhyId + 1,
        RootCause: '',
        Action: '',
        ActionType: element.ActionType,
        AssignTo: '',
        NewAssignee: '',
        AssignedDate: null,
        TargetDate: null,
        RevisedDate: null,
        NoOfMisses: 0,
        ActionCompleted: 'No',
        ActionCompletedBy: '',
        ActionCompletedOn: null,
        Verification: '',
        VerificationBy: '',
        VerificationOn: null,
        ClosedDate: null,
        Status: 'Open',
        VerifiedBy: '',
        FromActionPlan: true,
      };
    }
    // this.fiveWhy.push({ Action: element.Action, ActionType: element.ActionType, AssignTo: element.AssignTo, TargetDate: element.TargetDate, RevisedDate: element.RevisedDate, ClosedDate: element.ClosedDate, Status: element.Status, VerifiedBy: element.VerifiedBy, FromActionPlan:true});
    this.fiveWhy.splice(i + 1, 0, obj);
    // this.orgfiveWhy.splice(i + 1, 0, obj);

    this.setSort();

    for (const fya of this.fiveWhy) {
      const mRecords = this.fiveWhy.filter(
        (fiveY: any) => fya.fiveWhyId == fiveY.fiveWhyId
      );
      if (mRecords && mRecords.length) {
        let disabled = mRecords.map((rec: any, index: number) => {
          if (index == 0)
            rec.rowSpan = mRecords && mRecords.length ? mRecords.length : 1;
          else {
            rec.spanRow = true;
          }
        });
      }
    }
    let a = this.fiveWhy.filter((obj: any) => !obj.spanRow);
    if (a && a.length) {
      for (let index = 0; index < a.length; index++) {
        if (index == a.length - 1) a[index].MainRowShow = true;
        else a[index].MainRowShow = false;
      }
    }
    this.newUserList = [];
    this.newOrgUserList = [];
    this.fiveWhy.map((orgUser: any) =>
      this.newUserList.push([...this.userList])
    );
    this.fiveWhy.map((orgUser: any) =>
      this.newOrgUserList.push([...this.userList])
    );
    this.newUserFiltList =
      this.newUserList && this.newUserList.length
        ? this.newUserList.slice()
        : [];
    this.newOrgUserFiltList =
      this.newOrgUserList && this.newOrgUserList.length
        ? this.newOrgUserList.slice()
        : [];
    this.fiveWhyActionPlanDataSource = new MatTableDataSource(this.fiveWhy);
  }
  deleteRow(i: any) {
    if (this.levelId == 0 || this.levelId == 5) {
      const isHaveSub = this.fiveWhy.filter(
        (cause: any) => cause.fiveWhyId == this.fiveWhy[i].fiveWhyId
      );
      if (isHaveSub && isHaveSub.length > 1 && !this.fiveWhy[i].spanRow) {
        this.toaster.warning('Warning', `Remove sub row for this row`);
        return;
      }
    }
    this.fiveWhy.splice(i, 1);
    // this.orgfiveWhy.splice(i, 1);
    for (const fya of this.fiveWhy) {
      const mRecords = this.fiveWhy.filter(
        (fiveY: any) => fya.fiveWhyId == fiveY.fiveWhyId
      );
      if (mRecords && mRecords.length) {
        let disabled = mRecords.map((rec: any, index: number) => {
          if (index == 0)
            rec.rowSpan = mRecords && mRecords.length ? mRecords.length : 1;
          else {
            rec.spanRow = true;
          }
        });
      }
    }
    let a = this.fiveWhy.filter((obj: any) => !obj.spanRow);
    if (a && a.length) {
      for (let index = 0; index < a.length; index++) {
        if (index == a.length - 1) a[index].MainRowShow = true;
        else a[index].MainRowShow = false;
      }
    }
    this.newUserList = [];
    this.newOrgUserList = [];
    this.fiveWhy.map((orgUser: any) =>
      this.newUserList.push([...this.userList])
    );
    this.fiveWhy.map((orgUser: any) =>
      this.newOrgUserList.push([...this.userList])
    );
    this.newUserFiltList =
      this.newUserList && this.newUserList.length
        ? this.newUserList.slice()
        : [];
    this.newOrgUserFiltList =
      this.newOrgUserList && this.newOrgUserList.length
        ? this.newOrgUserList.slice()
        : [];
    this.fiveWhyActionPlanDataSource = new MatTableDataSource(this.fiveWhy);
  }

  isChecked(event: any, row: any) {
    if (event.checked) {
      row.VerifiedBy = this.user.UserId;
    } else {
      row.VerifiedBy = null;
    }
  }

  getUserName(row: any, type: any) {
    if (type == 'actionBy') {
      let objFindName = this.userAllList.find(
        (usr: any) => usr.UserId == row.ActionCompletedBy
      );
      if (objFindName) return objFindName.UserName;
      else if (row.ActionCompletedBy == 100001) return 'Admin';
      else return null;
    } else if (type == 'verificationBy') {
      let objFindName = this.userAllList.find(
        (usr: any) => usr.UserId == row.VerificationBy
      );
      if (objFindName) return objFindName.UserName;
      else if (row.VerificationBy == 100001) return 'Admin';
      else return null;
    } else if (type == 'verifiedBy') {
      let objFindName = this.userAllList.find(
        (usr: any) => usr.UserId == row.VerifiedBy
      );
      if (objFindName) return objFindName.UserName;
      else if (row.VerifiedBy == 100001) return 'Admin';
      else return null;
    }
  }

  getFileTypeName(val: any) {
    if (val && typeof val === 'string') {
      let arr = val.split('.');
      if (arr && arr.length && arr.length != 1)
        return `( ${arr[arr.length - 1]} )`;
      // else if (arr.length == 1) {
      //   arr = val.split(';');
      //   arr = arr[0].split('/');
      //   return `( ${arr[arr.length - 1]} )`;
      // }
      else return ``;
    }
  }

  resetData() {
    this.headerData = {
      ID: 0,
      Group: '',
      Plant: '',
      Dept: '',
      Team: '',
      TeamLeader: '',
      InitProbStatement: '',
      NoOfM: 6,
      stageId: 5,
      uniqueCode: '',
      CreatedAt: new Date(),
      CreatedBy: this.user.UserId,
    };
    this.isReset = true;
    this.analyticalToolsService.setRca(false);
    this.analyticalToolsService.setRcaId(0);
    this.analyticalToolsService.setHeaderId(0);
    this.analyticalToolsService.setFilteredIndex(0);
    this.analyticalToolsService.setFilteredData([]);
    this.analyticalToolsService.setTeamLeaderList([]);
    this.getFiveWhyActionPlan('');
  }

  saveFiveWhyPlan(e: any) {
    let event = e.headerData;
    let integrationData = e.integrationData;
    let statusCheck = false;
    delete event?.stageID;
    delete event?.CauseFileCount;

    const checkRemark = this.fiveWhy.find(
      (el: any) => el.RevisedDate && !el.Remarks
    );
    if (checkRemark) {
      this.toaster.warning(
        'Warning',
        `Please Fill The Remarks Field For Rootcause ${checkRemark.RootCause}`
      );
      return;
    }
    for (const fiveWhy of this.fiveWhy) {
      // if (fiveWhy.RevisedDate && !fiveWhy.Remarks) {
      //   this.toaster.warning('Warning', `Please Fill The Remarks Field For Rootcause ${fiveWhy.RootCause}`);
      //   return;
      // }
      delete fiveWhy.FileSrc;
      delete fiveWhy.DocSrc;
      delete fiveWhy.minRevisedDate;
      delete fiveWhy.MinTargetDate;
      delete fiveWhy.rowSpan;
      delete fiveWhy.spanRow;
      if (this.levelId == 0 || this.levelId == 5) {
        delete fiveWhy.MainRowShow;
        fiveWhy.FyActionId = fiveWhy.fiveWhyId;
        fiveWhy.fiveWhyId = null;
      }
      fiveWhy.AssignTo = fiveWhy.AssignTo == '' ? null : fiveWhy.AssignTo;
      fiveWhy.NewAssignee =
        fiveWhy.NewAssignee == '' ? null : fiveWhy.NewAssignee;
      fiveWhy.VerifiedBy = fiveWhy.VerifiedBy == '' ? null : fiveWhy.VerifiedBy;
      fiveWhy.ActionCompletedBy =
        fiveWhy.ActionCompletedBy == '' ? null : fiveWhy.ActionCompletedBy;
      fiveWhy.VerificationBy =
        fiveWhy.VerificationBy == '' ? null : fiveWhy.VerificationBy;

      if (fiveWhy.TargetDate) {
        fiveWhy.TargetDate = new Date(fiveWhy.TargetDate);
        fiveWhy.TargetDate.setHours(new Date().getHours());
        fiveWhy.TargetDate.setMinutes(new Date().getMinutes());
        fiveWhy.TargetDate.setSeconds(new Date().getSeconds());
      }
      if (fiveWhy.AssignedDate) {
        fiveWhy.AssignedDate = new Date(fiveWhy.AssignedDate);
        fiveWhy.AssignedDate.setHours(new Date().getHours());
        fiveWhy.AssignedDate.setMinutes(new Date().getMinutes());
        fiveWhy.AssignedDate.setSeconds(new Date().getSeconds());
      }
      if (fiveWhy.RevisedDate) {
        fiveWhy.RevisedDate = new Date(fiveWhy.RevisedDate);
        fiveWhy.RevisedDate.setHours(new Date().getHours());
        fiveWhy.RevisedDate.setMinutes(new Date().getMinutes());
        fiveWhy.RevisedDate.setSeconds(new Date().getSeconds());
      }
      if (fiveWhy.ClosedDate) {
        fiveWhy.ClosedDate = new Date(fiveWhy.ClosedDate);
        fiveWhy.ClosedDate.setHours(new Date().getHours());
        fiveWhy.ClosedDate.setMinutes(new Date().getMinutes());
        fiveWhy.ClosedDate.setSeconds(new Date().getSeconds());
      }

      fiveWhy.Status =
        fiveWhy.TargetDate && !fiveWhy.RevisedDate
          ? 'InProgress'
          : fiveWhy.TargetDate && fiveWhy.RevisedDate
          ? 'Delayed'
          : 'Open';
      if (fiveWhy.ActionCompleted == 'Yes') {
        fiveWhy.Status = 'Complete';
      }
      if (fiveWhy.Verification == 'Approved') {
        fiveWhy.Status = 'Closed';
        fiveWhy.ClosedDate = new Date();
      } else if (fiveWhy.Verification == 'Disapproved') {
        fiveWhy.Status = 'ReOpened';
        fiveWhy.ActionCompleted = 'No';
      }

      if (fiveWhy.Status != 'Closed') statusCheck = true;
    }

    const closecheck = this.orgfiveWhy.some((el: any) => el.Status != 'Closed');
    if (closecheck) statusCheck = true;

    if (event?.StatusFiveWhy && event?.StatusFiveWhy == 'InProgress') {
      let found: any;
      if (this.levelId == 0 || this.levelId == 5)
        found = this.orgfiveWhy.some(
          (el: any) =>
            el.RootCause ||
            el.Action ||
            el.AssignTo ||
            el.TargetDate ||
            el.Verification
        );
      else
        found = this.orgfiveWhy.some(
          (el: any) =>
            el.Action || el.AssignTo || el.TargetDate || el.Verification
        );

      if (!found) event.StatusFiveWhyAction = 'Open';
      else event.StatusFiveWhyAction = 'InProgress';
    } else {
      // const found = this.orgfiveWhy.some((el: any) => el.Action || el.AssignTo || el.TargetDate || el.Verification);

      let found: any;
      if (this.levelId == 0 || this.levelId == 5)
        found = this.orgfiveWhy.some(
          (el: any) =>
            el.RootCause ||
            el.Action ||
            el.AssignTo ||
            el.TargetDate ||
            el.Verification
        );
      else
        found = this.orgfiveWhy.some(
          (el: any) =>
            el.Action || el.AssignTo || el.TargetDate || el.Verification
        );

      if (!found) event.StatusFiveWhyAction = 'Open';
      else if (statusCheck) event.StatusFiveWhyAction = 'InProgress';
      else if (!statusCheck) event.StatusFiveWhyAction = 'Completed';
    }

    if (statusCheck) {
      let completeArr = this.orgfiveWhy.filter(
        (el: any) => el.Status != 'Closed'
      );
      const completecheck = completeArr.every(
        (el: any) => el.Status == 'Complete'
      );
      if (completecheck) {
        if (event.StatusFiveWhy == 'Completed') event.StatusCycle = 'NotClosed';
        else event.StatusCycle = 'InProgress';
      } else event.StatusCycle = 'InProgress';
    }

    const delaycheck = this.orgfiveWhy.some(
      (el: any) => el.Status == 'Delayed' || el.Status == 'ReOpened'
    );
    if (delaycheck) event.StatusCycle = 'Delayed';

    // let FiveWhy = {
    //   headerId: this.headerData.ID,
    //   createdBy: this.user.UserId,
    //   data: this.fiveWhy
    // };
    delete event.fiveWhy;
    delete event.causeAndEffects;
    delete event.fiveWhyActionPlan;
    delete event.updateStatus;
    delete event.hasRca;
    delete event.uniqueCode;
    // event.StatusFiveWhyAction = event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress' ? 'InProgress' : statusCheck ? 'InProgress' : 'Completed';
    let iCount = 0;
    event.stageId = this.levelId ? this.levelId : this.stageId;
    const formData = new FormData();
    for (const file of this.fiveWhy) {
      if (file.UploadFile && typeof file.UploadFile === 'object') {
        formData.append('UploadFile', file.UploadFile);
        file.UploadFile = true;
        iCount++;
      }
      if (file.UploadDoc && typeof file.UploadDoc === 'object') {
        formData.append('UploadFile', file.UploadDoc);
        file.UploadDoc = true;
        iCount++;
      }
    }
    event.FyActionFileCount = iCount;
    event.CreatedBy = this.user.UserId;
    formData.append('UploadFile', event.UploadFile);
    formData.append('header', JSON.stringify(event));
    formData.append('data', JSON.stringify(this.fiveWhy));
    formData.append('createdBy', JSON.stringify(this.user.UserId));

    let route = this.router.url;
    if (!route.includes('at/at-stages')) {
      formData.append('integrationData', JSON.stringify(integrationData));
    }

    if (this.IndividualFivewhyID)
      formData.append(
        'individualFivewhyID',
        JSON.stringify(this.IndividualFivewhyID)
      );
    this.spinnerText = 'Saving Five Why Plan Data';
    this.spinner.show();
    if (this.updateStatus) {
      this.FivewhyService.updateFiveWhyPlanData(formData).subscribe(
        (res: any) => {
          this.analyticalToolsService.setHeaderId(res.data.headerId);
          this.analyticalToolsService.setStatus(this.updateStatus);
          this.analyticalToolsService.setRcaId(res.data.rcaId);
          this.toaster.success('Success', res.message);
          this.headerData.ID = res.data.headerId;
          this._emitHeaderData.emit(res.data);
          this.onSuccess.emit('actionPlan');

          this.getFiveWhyActionPlan('');
          this.spinner.hide();
          // this.router.navigate(['/at/fivewhy']);
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    } else {
      this.FivewhyService.addFiveWhyActionData(formData).subscribe(
        (res: any) => {
          this.analyticalToolsService.setHeaderId(res.data.headerId);
          this._emitHeaderData.emit(res.data);
          this.updateStatus = true;
          this.onSuccess.emit('actionPlan');
          this.analyticalToolsService.setStatus(this.updateStatus);
          this.analyticalToolsService.setRcaId(res.data.rcaId);
          this.toaster.success('Success', res.message);
          this.headerData.ID = res.data.headerId;
          this.analyticalToolsService.searchHeader;
          this.getFiveWhyActionPlan('');
          // this.router.navigate(['/at/fivewhy']);
          this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    }
  }

  statusChange(data: any) {
    if (data.Status == 'Closed') {
      if (!data.Action) {
        this.toaster.warning(
          'Warning',
          `You Can't Close Status Until Action Field Value Is Empty`
        );
        setTimeout(() => {
          data.Status = 'Open';
        }, 250);
        return;
      } else if (!data.ActionType) {
        this.toaster.warning(
          'Warning',
          `You Can't Close Status Until ActionType Field Value Is Empty`
        );
        setTimeout(() => {
          data.Status = 'Open';
        }, 250);
        return;
      } else if (!data.AssignTo) {
        this.toaster.warning(
          'Warning',
          `You Can't Close Status Until AssignTo Field Value Is Empty`
        );
        setTimeout(() => {
          data.Status = 'Open';
        }, 250);
        return;
      } else if (!data.AssignedDate) {
        this.toaster.warning(
          'Warning',
          `You Can't Close Status Until AssignedDate Field Value Is Empty`
        );
        setTimeout(() => {
          data.Status = 'Open';
        }, 250);
        return;
      } else if (!data.TargetDate) {
        this.toaster.warning(
          'Warning',
          `You Can't Close Status Until TargetDate Field Value Is Empty`
        );
        setTimeout(() => {
          data.Status = 'Open';
        }, 250);
        return;
      } else if (!data.RevisedDate) {
        this.toaster.warning(
          'Warning',
          `You Can't Close Status Until RevisedDate Field Value Is Empty`
        );
        setTimeout(() => {
          data.Status = 'Open';
        }, 250);
        return;
      } else if (!data.ClosedDate) {
        this.toaster.warning(
          'Warning',
          `You Can't Close Status Until ClosedDate Field Value Is Empty`
        );
        setTimeout(() => {
          data.Status = 'Open';
        }, 250);
        return;
      } else if (!data.VerifiedBy) {
        this.toaster.warning(
          'Warning',
          `You Can't Close Status Until VerifiedBy Field Value Is Empty`
        );
        setTimeout(() => {
          data.Status = 'Open';
        }, 250);
        return;
      }
    }
  }

  openFile(i: any, type: any) {
    if (type == 'pic') this.fileInput.nativeElement.click();
    else this.fileInputFile.nativeElement.click();
    this.ind = i;
  }

  readFile(event: any, type: any) {
    this.imageFile = event.target.files[0];
    // this.fiveWhy[this.ind].UploadFile = this.imageFile;
    // this.fiveWhy[this.ind].Index = this.ind
    // this.UploadFile[this.ind].File = this.imageFile;
    // this.UploadFile[this.ind].Index = this.ind
    // this.imageFile.encoding = this.ind
    // this.UploadFile.push(this.imageFile);
    if (type == 'pic') this.fiveWhy[this.ind].UploadFile = this.imageFile;
    else this.fiveWhy[this.ind].UploadDoc = this.imageFile;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        if (type == 'pic') this.fiveWhy[this.ind].FileSrc = this.imageSrc;
        else this.fiveWhy[this.ind].DocSrc = this.imageSrc;
      };
    }
  }

  deleteFile(i: any, type: any) {
    if (type == 'pic') {
      this.fiveWhy[i].FileSrc = '';
      this.fiveWhy[i].UploadFile = '';
    } else {
      this.fiveWhy[i].DocSrc = '';
      this.fiveWhy[i].UploadDoc = '';
    }
    // this.fiveWhy[this.ind].UploadFile = '';
  }

  imageZoomModal(i: any): void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      height: '90%',
      width: '75%',
      data:
        this.fiveWhy[i].FileSrc || this.blobStorageUrl + this.fiveWhy[i].UploadFile,
    });
    // this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  getMinDate(row: any, type: any) {
    if (type == 'Revised') {
      const date = row.RevisedDate ? row.RevisedDate : row.TargetDate;
      let currentDate = new Date(date);
      // return new Date(currentDate.setDate(currentDate.getDate() + 1))

      let minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate() + 1)
      );

      row.MinRevisedDate = (current: Date): boolean =>
        // Can not select days before today and today
        differenceInCalendarDays(current, minDateStartFrom) < 0;
      return row.MinRevisedDate;
    } else if (type == 'Target') {
      let currentDate = new Date();
      // return new Date(currentDate.setDate(currentDate.getDate() + 1))

      let minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate())
      );

      row.MinTargetDate = (current: Date): boolean =>
        // Can not select days before today and today
        differenceInCalendarDays(current, minDateStartFrom) < 0;
      return row.MinTargetDate;
    }
  }

  setAssignDate(row: any) {
    row.AssignedDate = new Date();
  }

  onRevisedDateChange(row: any) {
    if (row.MissesCondition) {
      row.NoOfMisses++;
      row.MissesCondition = false;
    } else if (!row.MissesCondition && !row.RevisedDate) {
      row.NoOfMisses--;
      row.MissesCondition = true;
    }
    // row.MinRevisedDate = (current: Date): boolean =>
    // // Can not select days before today and today
    // differenceInCalendarDays(current, new Date(row.TargetDate)) < 0;
  }

  ActionCompletedChange(row: any) {
    if (row.ActionCompleted == 'Yes') {
      row.ActionCompletedBy = this.user.UserId;
      row.ActionCompletedOn = new Date();
      row.Verification = '';
    } else {
      row.ActionCompletedBy = '';
      row.ActionCompletedOn = null;
    }
  }

  VerificationChange(row: any) {
    // if (row.Verification == 'Approved') {
    row.VerificationBy = this.user.UserId;
    row.VerificationOn = new Date();
    // }
    // else if (row.Verification == 'Disapproved') {
    //   row.VerificationBy = '';
    //   row.VerificationOn = null;
    // }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
