<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" template=" <img src='assets/images/spinner.gif' />">
    <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>

<div>
<div class="content-top-box">
    <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
    </ul>
</div>

<div class="container">
    <header style="width: 100%;">
        <div class="year">
            <h2>Year {{currentYear}}</h2>
            <button mat-icon-button class="add-button" [disabled]="!IsWrite" (click)="addPMTask()">
                <mat-icon class="menu">add</mat-icon>
            </button>
        </div>
        <form [formGroup]="HeaderForm" style="width: 80%;">
            <div class="content-top-row content-top-row-order-processing w-100">
                <mat-form-field appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-select formControlName="group" [(ngModel)]="headerData.group" placeholder="Select Group"
                        name="group" (selectionChange)="getPlants(headerData.group)" [disabled]="!isAdmin">
                        <input (keyup)="searchValues($event, 'group')" class="form-control w-100"
                            placeholder="Search Group">
                        <mat-option *ngFor="let group of filteredGroupList" [value]="group.id">
                            {{group.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-select formControlName="plant" [(ngModel)]="headerData.plant" placeholder="Select Plant"
                        name="plant" (selectionChange)="getTaskList()" [disabled]="!isAdmin">
                        <input (keyup)="searchValues($event, 'plant')" class="form-control w-100"
                            placeholder="Search Plant">
                        <mat-option *ngFor="let plant of filteredPlantList" [value]="plant.id">
                            {{plant.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-select formControlName="technician"
                        [(ngModel)]="headerData.technician" placeholder="Select Technician" name="technician"
                        (selectionChange)="getTaskList()" [disabled]="!isAdmin">
                        <input (keyup)="searchValues($event, 'technician')" class="form-control w-100"
                            placeholder="Search Technician">
                        <mat-option value="ALL">ALL</mat-option>
                        <mat-option *ngFor="let tech of filteredTechnicianList" [value]="tech.UserId">
                            {{tech.fullName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="headerData.WeekMonth == 1 && weekScheduleTaskColumns && weekScheduleTaskColumns.length"
                    class="week-btns">
                    <!-- <h4>Week</h4> -->
                    <button type="button" (click)="changeWeek(i)" [ngClass]="{'bg-success':weekIndex == i}"
                        class="nav-button" tooltipPosition="top"
                        *ngFor="let week of weekScheduleTaskColumns; let i = index">{{ i+1 }}
                    </button>
                </div>
                <mat-form-field appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-select formControlName="curmonth" [(ngModel)]="currentMonth" placeholder="Month List"
                        name="month" (selectionChange)="getTaskList()">
                        <mat-option *ngFor="let month of monthNames" [value]="month">
                            {{month}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-select formControlName="wkmonth" [(ngModel)]="headerData.WeekMonth" placeholder="Week Month"
                        panelClass="testClass" name="WeekMonth" (selectionChange)="changeCalendar()">
                        <mat-option *ngFor="let calType of calTypeList" [value]="calType.id">
                            {{calType.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </header>
    <div class="table-container">
        <div class="schedule-table">
            <table *ngIf="scheduleTaskColumns && scheduleTaskColumns[0]">
                <thead>
                    <tr>
                        <th *ngFor="let column of scheduleTaskColumns[0];let i=index"
                            [ngClass]="{'bg-color-weekoff': column.weekOff }">{{ column.date }}</th>
                    </tr>
                    <tr>
                        <th *ngFor="let column of scheduleTaskColumns[0];let i=index"
                            [ngClass]="{'bg-color-weekoff': column.weekOff }">{{
                            column.day }}</th>
                    </tr>
                </thead>
                <tbody *ngIf="taskRow && taskRow[0]">
                    <tr *ngFor="let row of taskRow[0]; let i = index" [ngClass]="{ 'bg-success': i%2 != 0 }">
                        <td *ngFor="let column of scheduleTaskColumns[0];let j=index" #tooltip="matTooltip"
                            (click)="column.task && column.task[row] && reScheduleTask(row, column)"
                            [matTooltip]="column.task && column.task[row] ? (column.task[row].Status ? 'Completed' : 'Open') : ''"
                            [ngClass]="{'text-danger': column.task && column.task[row] && !column.task[row].Status, 'text-success': column.task && column.task[row] && column.task[row].Status ,'low-opacity': column.fullDate < currentDate}">
                            {{ column.task && column.task[row] ? ((column.task[row].Task.WOType ?
                            column.task[row].Task.WOType.Name : '') + ' - ' +
                            column.task[row].Task.Duration) + 'Hrs' : '' }} <br>
                            {{ column.task && column.task[row] ? column.task[row].Task.units.puDesc : '' }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr></tr>
                </tfoot>
            </table>
            <table *ngIf="scheduleTaskColumns && scheduleTaskColumns[1]">
                <thead>
                    <tr>
                        <th *ngFor="let column of scheduleTaskColumns[1];let i=index"
                            [ngClass]="{'bg-color-weekoff': column.weekOff }">{{ column.date }}</th>
                    </tr>
                    <tr>
                        <th *ngFor="let column of scheduleTaskColumns[1];let i=index"
                            [ngClass]="{'bg-color-weekoff': column.weekOff }">{{
                            column.day }}</th>
                    </tr>
                </thead>
                <tbody *ngIf="taskRow && taskRow[1]">
                    <tr *ngFor="let row of taskRow[1]; let i = index" [ngClass]="{ 'bg-success': i%2 != 0 }">
                        <td *ngFor="let column of scheduleTaskColumns[1];let j=index" #tooltip="matTooltip"
                            (click)="column.task && column.task[row] && reScheduleTask(row, column)"
                            [matTooltip]="column.task && column.task[row] ? (column.task[row].Status ? 'Completed' : 'Open') : ''"
                            [ngClass]="{'text-danger': column.task && column.task[row] && !column.task[row].Status, 'text-success': column.task && column.task[row] && column.task[row].Status ,'low-opacity': column.fullDate < currentDate}">
                            {{ column.task && column.task[row] ? ((column.task[row].Task.WOType ?
                            column.task[row].Task.WOType.Name : '') + ' - ' +
                            column.task[row].Task.Duration) + 'Hrs' : '' }} <br>
                            {{ column.task && column.task[row] && column.task[row].Task && column.task[row].Task.units ? column.task[row].Task.units.puDesc : '' }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr></tr>
                </tfoot>
            </table>
            <table *ngIf="scheduleTaskColumns && scheduleTaskColumns[2]">
                <thead>
                    <tr>
                        <th *ngFor="let column of scheduleTaskColumns[2];let i=index"
                            [ngClass]="{'bg-color-weekoff': column.weekOff }">{{ column.date }}</th>
                    </tr>
                    <tr>
                        <th *ngFor="let column of scheduleTaskColumns[2];let i=index"
                            [ngClass]="{'bg-color-weekoff': column.weekOff }">{{
                            column.day }}</th>
                    </tr>
                </thead>
                <tbody *ngIf="taskRow && taskRow[2]">
                    <tr *ngFor="let row of taskRow[2]; let i = index" [ngClass]="{ 'bg-success': i%2 != 0 }">
                        <td *ngFor="let column of scheduleTaskColumns[2];let j=index" #tooltip="matTooltip"
                            (click)="column.task && column.task[row] && reScheduleTask(row, column)"
                            [matTooltip]="column.task && column.task[row] ? (column.task[row].Status ? 'Completed' : 'Open') : ''"
                            [ngClass]="{'text-danger': column.task && column.task[row] && !column.task[row].Status, 'text-success': column.task && column.task[row] && column.task[row].Status ,'low-opacity': column.fullDate < currentDate}">
                            {{ column.task && column.task[row] ? ((column.task[row].Task.WOType ?
                            column.task[row].Task.WOType.Name : '') + ' - ' +
                            column.task[row].Task.Duration) + 'Hrs' : '' }} <br>
                            {{ column.task && column.task[row] ? column.task[row].Task.units.puDesc : '' }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr></tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>

<ng-template #pmTaskForm>
    <div mat-dialog-title class="mat-title">
        <div class="d-flex justify-content-between align-items-center">
            <h1 class="main-title">Add PM Task</h1>
            <button mat-mini-fab mat-button mat-dialog-close color="warn" [disabled]="!IsWrite" title="close">
                <i class="fa fa-times" style="color: #fff"></i>
            </button>
        </div>
    </div>
    <div class="col-md-12">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <form [formGroup]="PMTaskForm" (ngSubmit)="TaskOnSubmit()" >
                <div class="content-top-row prevForm">
                    <mat-form-field class="w-22">
                        <mat-select formControlName="group" (selectionChange)="getPlants(PMTask.Group)"
                            placeholder="Group" [(ngModel)]="PMTask.Group">
                            <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="groupList"
                            (filteredReturn)="groupFiltList = $event">
                          </mat-select-filter>            
                            <mat-option *ngFor="let group of groupFiltList" [value]="group.id">
                                {{group.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Group is Required</mat-error>
                    </mat-form-field>
                    <!-- Plant -->
                    <mat-form-field class="w-22">
                        <mat-select formControlName="plant" [(ngModel)]="PMTask.Plant" placeholder="Plant"
                            (selectionChange)="getPMTaskData('change')">
                            <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="plantList"
                            (filteredReturn)="plantFiltList = $event">
                          </mat-select-filter>            
                            <mat-option *ngFor="let plant of plantFiltList" [value]="plant.id">
                                {{plant.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Plant is Required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearence="fill" class="w-22">
                        <mat-label>Line</mat-label>
                        <mat-select formControlName="line" [(ngModel)]="PMTask.line" name="Line"
                          (selectionChange)="setUnitList('change')">
                          <mat-select-filter [placeholder]="'Search Line'" [displayMember]="'PL_Desc'" [array]="LineList"
                            (filteredReturn)="LineFilterList = $event">
                          </mat-select-filter>
                          <mat-option *ngFor="let line of LineFilterList" [value]="line.PL_Id">
                            {{ line.PL_Desc }}
                          </mat-option>
                        </mat-select>
                        <mat-error>Line is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearence="fill" class="w-22" style="margin: 0;">
                        <mat-label>Unit</mat-label>
                        <mat-select formControlName="unit" [(ngModel)]="PMTask.units" name="Unit"
                          (selectionChange)="setAssemblyList('change')">
                          <mat-select-filter [placeholder]="'Search Unit'" [displayMember]="'PU_Desc'" [array]="UnitList"
                            (filteredReturn)="UnitFilterList = $event">
                          </mat-select-filter>
                          <mat-option *ngFor="let unit of UnitFilterList" [value]="unit.PU_Id">
                            {{ unit.PU_Desc }}
                          </mat-option>
                        </mat-select>
                        <mat-error>Unit is Required</mat-error>
                      </mat-form-field>

                </div>
                <div class="content-top-row prevForm">
                    <mat-form-field appearence="fill" class="w-22">
                        <mat-label>Assembly</mat-label>
                        <mat-select formControlName="assembly" [(ngModel)]="PMTask.assembly" name="Assembly" 
                          (selectionChange)="setSubAssemblyList('change')">
                          <mat-select-filter [placeholder]="'Search Assembly'" [displayMember]="'Assbly_Desc'"
                            [array]="AssemblyList" (filteredReturn)="AssemblyFilterList = $event">
                          </mat-select-filter>
                          <mat-option *ngFor="let assembly of AssemblyFilterList" [value]="assembly.Assbly_Id">
                            {{ assembly.Assbly_Desc }}
                          </mat-option>
                        </mat-select>
                        <mat-error>Assembly is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearence="fill" class="w-22">
                        <mat-label>Sub Assembly</mat-label>
                        <mat-select formControlName="subAssembly" [(ngModel)]="PMTask.subAssembly" name="Sub Assembly"
                         (selectionChange)="setComponentList('change')">
                          <mat-select-filter [placeholder]="'Search Sub Assembly'" [displayMember]="'SAsbly_Desc'"
                            [array]="SubAssemblyList" (filteredReturn)="SubAssemblyFilterList = $event">
                          </mat-select-filter>
                          <mat-option *ngFor="let subAssembly of SubAssemblyFilterList" [value]="subAssembly.SAssbly_Id">
                            {{ subAssembly.SAsbly_Desc }}
                          </mat-option>
                        </mat-select>
                        <mat-error>Sub Assembly is Required}</mat-error>
                      </mat-form-field>
                      <mat-form-field appearence="fill" class="w-22">
                        <mat-label>Component</mat-label>
                        <mat-select formControlName="component" [(ngModel)]="PMTask.component" name="Component">
                          <mat-select-filter [placeholder]="'Search Component'" [displayMember]="'ComponentName'"
                            [array]="ComponentList" (filteredReturn)="ComponentFilterList = $event">
                          </mat-select-filter>
                          <mat-option *ngFor="let component of ComponentFilterList" [value]="component.ComponentId">
                            {{ component.ComponentName }}
                          </mat-option>
                        </mat-select>
                        <mat-error>Component is Required</mat-error>
                      </mat-form-field>
                      
                    <mat-form-field class="w-22" style="margin: 0;">
                        <mat-label>Description</mat-label>
                        <input matInput type="text" formControlName="description" [(ngModel)]="PMTask.Description" />
                        <mat-error>Description is Required</mat-error>
                    </mat-form-field>

                </div>
                <div class="content-top-row prevForm">

                    <mat-form-field class="w-22">
                        <mat-label>Select Technicians</mat-label>
                        <mat-select [(ngModel)]="PMTask.Technician" formControlName="technicians">
                            <mat-select-filter [placeholder]="'Search'" [displayMember]="'UserName'" [array]="filterTechnicianList"
                            (filteredReturn)="TechnicianFiltList = $event">
                          </mat-select-filter>            
                            <mat-option *ngFor="let technician of TechnicianFiltList" [value]="technician.UserId">
                                {{technician.UserName}} 
                            </mat-option>
                        </mat-select>
                        <mat-error>Technician is Required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-22">
                        <mat-label>Select Task Type</mat-label>
                        <mat-select [(ngModel)]="PMTask.TaskType" formControlName="task_type"  (selectionChange)="setMaintenanceType()">
                            <mat-select-filter [placeholder]="'Search'" [displayMember]="'Name'" [array]="TaskTypeList"
                            (filteredReturn)="TaskTypeFiltList = $event">
                          </mat-select-filter>            
                            <mat-option *ngFor="let taskType of TaskTypeFiltList" [value]="taskType.ID">
                                {{taskType.Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Task Type is Required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-22">
                        <mat-label>Select Frequency</mat-label>
                        <mat-select [(ngModel)]="PMTask.Frequency" formControlName="frequency"
                            (selectionChange)="setFrequencyDuration()">
                            <mat-select-filter [placeholder]="'Search'" [displayMember]="'Name'" [array]="frequencyList"
                            (filteredReturn)="FrequencyFiltList = $event">
                          </mat-select-filter>            
                            <mat-option *ngFor="let frequency of FrequencyFiltList" [value]="frequency.ID">
                                {{frequency.Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Frequency is Required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-22" style="margin: 0;">
                        <mat-label>Activity Type</mat-label>
                        <mat-select [(ngModel)]="PMTask.WOType" formControlName="WOType">
                            <mat-select-filter [placeholder]="'Search'" [displayMember]="'Name'" [array]="WOTypeList"
                            (filteredReturn)="WOTypeFiltList = $event">
                          </mat-select-filter>            
                            <mat-option *ngFor="let technology of WOTypeFiltList" [value]="technology.ID">
                                {{technology.Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Activity Type is Required</mat-error>
                    </mat-form-field>


                </div>

                <div class="content-top-row prevForm">    
                    <mat-form-field class="w-22">
                        <mat-label>Duration</mat-label>
                        <input matInput matInput type="number" formControlName="type_duration"
                            [(ngModel)]="PMTask.Duration" (change)="durationChange(PMTask.Duration)"/>
                        <span matSuffix class="min"> / hrs</span>
                        <mat-error>Duration is Required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-22">
                        <mat-label>Spare Parts Code}</mat-label>
                        <input matInput formControlName="spare_part" [(ngModel)]="PMTask.SpareParts" />
                        <mat-error>Spare Code is Required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-22">
                        <mat-label>Maintenance Type</mat-label>
                        <mat-select [(ngModel)]="PMTask.MaintenanceType" formControlName="maintenanceType" disabled>
                            <mat-option *ngFor="let mtype of MaintenanceType" [value]="mtype.Name">
                                {{mtype.Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Maintenance Type is Required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-22">
                        <mat-label>From Task Date</mat-label>
                        <input matInput [min]="minDate" [matDatepicker]="picker" formControlName="fromTaskDate"
                            [(ngModel)]="PMTask.FromTaskDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error>From Task Date is Required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-22">
                        <mat-label>To Date</mat-label>
                        <input matInput [min]="minDate" [matDatepicker]="picker2" formControlName="toTaskDate"
                          [(ngModel)]="PMTask.ToTaskDate">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-error>To Date is Required</mat-error>
                      </mat-form-field>
                    
                </div>

                <div class="content-top-row prevForm" style="justify-content: flex-end;">   
                    <div style="width: 23.5%;justify-content: space-between;display: flex;align-items: center;">
                        <button mat-flat-button color="accent" type="button" [disabled]="!IsWrite" class="upload"
                            (click)="openFile()">
                            Upload File</button>
                        <input type="file" (change)="readFile($event)" class="d-none" #fileInput multiple>

                        <button class="add-btn1" type="submit" [disabled]="!IsWrite" pTooltip="Save"
                            tooltipPosition="right">
                            <img src="assets/images/save.png" alt="" width="20px" height="20px">
                        </button>
                    </div>
                </div>

                <div *ngIf="FileData && FileData.length" class="imgflex">
                    <div *ngFor="let file of FileData; let i = index" class="relative">
                        <div>
                            <img *ngIf="file.FileType == 'image'" [src]="file.ImageSrc" alt="" width="250px"
                                height="200px">

                            <video *ngIf="file.FileType == 'video'" width="250" height="200" controls>
                                <source [src]="file.ImageSrc" type="video/mp4">
                                Your Browser does not Support the video tag
                            </video>

                            <a *ngIf="file.FileType == 'application'" [href]="file.ImageSrc" target="_blank" download>
                                <img src="./assets/images/show-pdf2.png" width="200" height="200" alt="pdf">
                            </a>
                        </div>

                        <img src="./assets/images/remove.png" (click)="removeFile(i)" class="absolute" alt=""
                            width="20px" height="20px">

                    </div>
                </div>

            </form>
        </div>
    </div>
</ng-template>
</div>