import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';

const moment = _moment;
@Component({
  selector: 'app-oee-dashboard',
  templateUrl: './oee-dashboard.component.html',
  styleUrls: ['./oee-dashboard.component.scss']
})
export class OeeDashboardComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart!: BaseChartDirective;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  treeControl = new NestedTreeControl<PlantGroupElement>(node => node.children);
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  hasChild = (_: number, node: PlantGroupElement) => !!node.children && node.children.length > 0;
  oeeDashboardForm!: FormGroup;

  oeeAnalysis = {
    barType: 'radial',
    color: '#10CE9C',
    secondColor: '#E5E5E5',
    progress: 0,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      }
    }
  };
  capacityUtilizationAnalysis = {
    barType: 'radial',
    color: '#3AAFE4',
    secondColor: '#E5E5E5',
    progress: 0,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      }
    }
  };
  availabilityAnalysis = {
    barType: 'radial',
    color: '#F85439',
    secondColor: '#E5E5E5',
    progress: 0,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#F85439',
      }
    }
  };
  performanceAnalysis = {
    barType: 'radial',
    color: '#3AAFE4',
    secondColor: '#E5E5E5',
    progress: 0,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      }
    }
  };

  qualityAnalysis = {
    barType: 'radial',
    color: '#F85439',
    secondColor: '#E5E5E5',
    progress: 0,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#F85439',
      }
    }
  };
  // iconUrl
  menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';
  menuBlueIconUrl = 'assets/images/menu-bottom-blue-icon.png';

  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  filteredUnits: any;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  OeeDashboardData!: any;
  isproductionDBModel = false;
  isPopupMachine = false;
  isPlantGroupModelVisible = false;
  UnitName: string | undefined;
  CUPerc!: any;
  GrossProduce!: any;
  AvailableHours!: any;
  PlannedHours!: any;
  RunningHourse!: any;
  Waste!: any;
  ShutdownHours!: any;
  idealProduction!: any;
  grossProduction!: any;
  netProduction!: any;
  wasteKG!: any;
  monthlyOeeLabel: any[] = new Array();
  monthlyOeeData: any[] = new Array();
  monthlyOutputLabel: any[] = new Array();
  monthlyOutputData: any[] = new Array();
  chartOutputLossData: any[] = new Array();
  chartOutputLossWaterfall!: any;
  chartMonthlyOutput!: Chart;
  chartMonthlyOeeTrend!: Chart;
  chartMonthlyOeeDrill!: Chart;
  options!: Options;
  activeButton!:any;
  OEEDatasource!: MatTableDataSource<any>;
  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridApiEdit: any;
  gridColumnApi: any;
  gridColumnApiEdit: any;
  key: any;
  OutputLoses!: any;
  window: any;
  gridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
  };

  constructor(
    private formBuilder: FormBuilder,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private productivityservice: ProductivityService,
    private spinner: NgxSpinnerService,
    public router: Router
  ) {
    this.columnDefs = [
      { headerName: 'Date', field: 'DATE', filter: 'agTextColumnFilter' , valueFormatter: function (params: any) {
        return moment(params.value).format('MM/DD/YYYY HH:mm');
      }},
      { headerName: 'Start time', field: 'StartTime', filter: 'agTextColumnFilter', valueFormatter: function (params: any) {
        return moment(params.value).format('MM/DD/YYYY HH:mm');
      }, },
      { headerName: 'End time', field: 'EndTime', filter: 'agTextColumnFilter' , valueFormatter: function (params: any) {
        return moment(params.value).format('MM/DD/YYYY HH:mm');
      },},
      { headerName: 'Plant', field: 'pl_desc', filter: 'agTextColumnFilter' },
      { headerName: 'Machine', field: 'pu_desc', filter: 'agTextColumnFilter' },
      { headerName: 'Production start time', field: 'PSStartTime', filter: 'agTextColumnFilter' , valueFormatter: function (params: any) {
        return moment(params.value).format('MM/DD/YYYY HH:mm');
      },},
      { headerName: 'Production end time', field: 'PSEndTime', filter: 'agTextColumnFilter' , valueFormatter: function (params: any) {
        return moment(params.value).format('MM/DD/YYYY HH:mm');
      },},
      { headerName: 'Gross Produce', field: 'GrossProduce', filter: 'agTextColumnFilter' },
      { headerName: 'Ideal Produce', field: 'IdealProduce', filter: 'agTextColumnFilter' },
      { headerName: 'Target speed', field: 'TargetSpeed', filter: 'agTextColumnFilter' },
      { headerName: 'Runtime', field: 'RunTime', filter: 'agTextColumnFilter' },
      { headerName: 'Downtime', field: 'Downtime', filter: 'agTextColumnFilter' },
      { headerName: 'Time Lost', field: 'Time Lost', filter: 'agTextColumnFilter' },
      { headerName: 'Process order', field: 'ProcessOrder', filter: 'agTextColumnFilter' },
      { headerName: 'PPD Description', field: 'PPD_Desc', filter: 'agTextColumnFilter' },
      { headerName: 'Planned start date', field: 'PlannedStartDate', filter: 'agTextColumnFilter' , valueFormatter: function (params: any) {
        return moment(params.value).format('MM/DD/YYYY HH:mm');
      },},
      { headerName: 'Planned end date', field: 'PlannedEndDate', filter: 'agTextColumnFilter' , valueFormatter: function (params: any) {
        return moment(params.value).format('MM/DD/YYYY HH:mm');
      },},
      { headerName: 'Target quantity', field: 'TargetQuantity', filter: 'agTextColumnFilter' },
      { headerName: 'Item description', field: 'ItemDesc', filter: 'agTextColumnFilter' }
    ]
   }

  ngOnInit(): void {
    this.initForm();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.datasource();

  }
  initForm() {
    const dateStart = new Date();
    //dateStart.setDate(dateStart.getDate() - 183);
    dateStart.setHours(6);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);


    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate());

    this.oeeDashboardForm = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
      shift: ['', []],
      searchval: [''],
    });
  }

  onChangeGroup(data: any): void {
    this.getPlants(data);
  }
  onChangeLine(data: any): void {
    this.getUnit(data.value);
  }
  onChangePlant(data: any): void {
    this.getLine(data.value);
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }

  datasource(): void {
    const key = {
      UserId: 206     //later it will be change this is only for testing
    };
    const id = {
      value: 0
    };
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      if (this.GroupList.length > 0) {
        id.value = data.lstEG[0].EG_Id;
        this.oeeDashboardForm.get('UserGroup1')?.setValue(data.lstEG[0].EG_Id);
        this.oeeDashboardForm.get('UserPlant1')?.setValue(data.lstEG[0].EG_Id);
        this.filteredGroups = this.GroupList.slice();
        this.getPlants(id);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: 206
    };
    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      if (this.PlantList1.length > 0) {
        const id = data.lstPlant[0].PT_Id;
        this.oeeDashboardForm.get('UserPlant1')?.patchValue(id);
        this.filteredPlants = this.PlantList1.slice();
        this.getLine(id);
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getLine(id: any) {
    const key = {
      PT_ID: id,
      userId: 206
    };

    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      if (this.lineList.length > 0) {
        const lineDefault = this.lineList[0];
        this.oeeDashboardForm.controls.line.patchValue(lineDefault.PL_Id);
        this.filteredLines = this.lineList.slice();
        this.getUnit(lineDefault.PL_Id);
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUnit(id: number) {
    const key = {
      PL_ID: id,
      userId: 206
    };

    this.productivityservice.getUnitData(key).subscribe((data: any) => {
      this.unitList = data.lstUnit;
      if (this.unitList.length > 0) {
        const unitDefault = this.unitList[0];
        this.oeeDashboardForm.controls.unit.patchValue(unitDefault.PU_Id);
        this.filteredUnits = this.unitList.slice();
      }
      this.onSubmit();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });

  }

  onSubmit(): void {
    this.makeData();
    this.getOeeDashboard();
  }

  today(): void {
    this.makeDatatoDay();
    this.getOeeDashboard();
  }

  yesterday(): void {
    this.makeDataYesterDay();
    this.getOeeDashboard();
  }

  lastWeek(): void {
    this.makeDataLastWeek();
    this.getOeeDashboard();
  }

  lastMonth(): void {
    this.makeDataLastMonth();
    this.getOeeDashboard();
  }

  getOeeDashboard() {
    this.resetOEE();
    this.spinner.show();
    this.productivityservice.getOEE(this.key).subscribe((data: any) => {
      this.OeeDashboardData = data;
      this.spinner.hide();
      this.setData();
    }, (error: any) => {
      this.toaster.error('error', error.message);
      this.spinner.hide();
    });
  }

  setData() {
    this.capacityUtilizationAnalysis.progress = this.OeeDashboardData.CUPerc.toFixed(0);
    this.oeeAnalysis.progress = (this.OeeDashboardData.AvgOee >= 100) ? this.OeeDashboardData.AvgOee.toFixed(0) : this.OeeDashboardData.AvgOee.toFixed(0);
    this.availabilityAnalysis.progress = (this.OeeDashboardData.AvgAvailability >= 100) ? this.OeeDashboardData.AvgAvailability.toFixed(0) : this.OeeDashboardData.AvgAvailability.toFixed(0);
    this.performanceAnalysis.progress = (this.OeeDashboardData.AvgPerformance >= 100) ? this.OeeDashboardData.AvgPerformance.toFixed(0) : this.OeeDashboardData.AvgPerformance.toFixed(0);
    this.qualityAnalysis.progress = (this.OeeDashboardData.AvgQualityRate >= 100) ? this.OeeDashboardData.AvgQualityRate.toFixed(0) : this.OeeDashboardData.AvgQualityRate.toFixed(0);
    this.setColorCoding();
    this.GrossProduce = this.nFormatter(this.OeeDashboardData.GrossProduce, 0);
    this.AvailableHours = this.nFormatter(this.OeeDashboardData.AvailableHours, 0);
    this.PlannedHours = this.nFormatter(this.OeeDashboardData.PlannedHours, 0);
    this.RunningHourse = this.nFormatter(this.OeeDashboardData.RunningHourse, 0);
    this.Waste = this.nFormatter(this.OeeDashboardData.Waste, 0);
    this.ShutdownHours = this.nFormatter(this.OeeDashboardData.ShutdownHours, 0);
    this.idealProduction = this.nFormatter(this.OeeDashboardData.IdealProduce, 0);
    this.grossProduction = this.nFormatter(this.OeeDashboardData.GrossProduce, 0);
    this.netProduction = this.nFormatter(this.OeeDashboardData.NetProduce, 0);
    this.wasteKG = this.nFormatter(this.OeeDashboardData.WasteKg, 0);
    this.rowData = this.OeeDashboardData.dtDetails;
    if (this.OeeDashboardData.dtMonthlyOee !== null) {
      this.drawMonthlyOee(this.OeeDashboardData.dtMonthlyOee);

    }
    else {
      this.monthlyOeeData.length = 0;
      this.monthlyOeeLabel.length = 0;
    }
    if (this.OeeDashboardData.dtMonthlyGrossProduce !== null) {
      this.drawMonthlyOutput(this.OeeDashboardData.dtMonthlyGrossProduce);
    } else {
      this.monthlyOutputData.length = 0;
      this.monthlyOutputLabel.length = 0;
    }
    this.drawMonthlyOutputLoss(this.OeeDashboardData);
    this.monthlyOEEDrillDown();
  }


  resetOEE(): void {
    this.capacityUtilizationAnalysis.progress = 0;
    this.oeeAnalysis.progress = 0;
    this.availabilityAnalysis.progress = 0;
    this.performanceAnalysis.progress = 0;
    this.qualityAnalysis.progress = 0;
    this.GrossProduce = 0;
    this.AvailableHours = 0;
    this.PlannedHours = 0;
    this.RunningHourse = 0;
    this.Waste = 0;
    this.ShutdownHours = 0;
    this.idealProduction = 0;
    this.grossProduction = 0;
    this.netProduction = 0;
    this.wasteKG = 0;
  }
  makeData() {
    this.key = {
      dtStartDate: this.oeeDashboardForm.value.dateStart,
      dtEndDate: this.oeeDashboardForm.value.dateEnd,
      GroupId: this.oeeDashboardForm.value.UserGroup1,
      PlantId: this.oeeDashboardForm.value.UserPlant1,
      UnitId: this.oeeDashboardForm.value.unit,
      LineId: this.oeeDashboardForm.value.line,
      shift: '*'
    };

  }

  makeDatatoDay() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate());
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    const startDt = stDT;
    const endDt = edDT;
    this.oeeDashboardForm.get('dateStart')?.patchValue(stDT);
    this.oeeDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.oeeDashboardForm.value.UserGroup1,
      PlantId: this.oeeDashboardForm.value.UserPlant1,
      UnitId: this.oeeDashboardForm.value.unit,
      LineId: this.oeeDashboardForm.value.line,
      shift: '*'
    };

  }
  makeDataYesterDay() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 1);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.oeeDashboardForm.get('dateStart')?.patchValue(stDT);
    this.oeeDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.oeeDashboardForm.value.UserGroup1,
      PlantId: this.oeeDashboardForm.value.UserPlant1,
      UnitId: this.oeeDashboardForm.value.unit,
      LineId: this.oeeDashboardForm.value.line,
      shift: '*'
    };
  }

  makeDataLastWeek() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 6);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.oeeDashboardForm.get('dateStart')?.patchValue(stDT);
    this.oeeDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.oeeDashboardForm.value.UserGroup1,
      PlantId: this.oeeDashboardForm.value.UserPlant1,
      UnitId: this.oeeDashboardForm.value.unit,
      LineId: this.oeeDashboardForm.value.line,
      shift: '*'
    };
  }

  makeDataLastMonth() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 30);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.oeeDashboardForm.get('dateStart')?.patchValue(stDT);
    this.oeeDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.oeeDashboardForm.value.UserGroup1,
      PlantId: this.oeeDashboardForm.value.UserPlant1,
      UnitId: this.oeeDashboardForm.value.unit,
      LineId: this.oeeDashboardForm.value.line,
      shift: '*'
    };

  }

//=================================================================== Monthly OEE chart =======================================================//

  drawMonthlyOee(data: any) {
    let dtLabel: any[] = new Array();
    let dtData: any[] = new Array();
    if (data !== null) {
      data.forEach((element: any) => {
        dtLabel.push(element.Month);
        dtData.push([element.Month, parseFloat(element.AvgMonthlyOEE)]);
      });
    }
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: dtLabel,
      },
      yAxis: {
        min : 0,
        max : 100,
        labels : {
          format: '{value}%'
        },
        title: {
          text: 'OEE Trend'
        },
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.2f}</b>'
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'OEE Trend',
        type: 'column',
        data: dtData
      }]
    };
    let chart = new Chart(this.options);
    this.chartMonthlyOeeTrend = chart;
  }

//==================================================== Monthly Output chart==========================================//

  drawMonthlyOutput(data: any) {
    let dtLabel: any[] = new Array();
    let dtData: any[] = new Array();
    if (data !== null) {
      data.forEach((element: any) => {
        dtLabel.push(element.Month);
        dtData.push([element.Month, parseFloat(element.SumGrossProduce)]);
      });
    }
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: dtLabel,
      },
      yAxis: {
        title: {
          text: 'Volume Output'
        },
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.2f}</b>'
      },
      series: [{
        name: 'Volume Output',
        type: 'column',
        data: dtData
      }]
    };
    let chart = new Chart(this.options);
    this.chartMonthlyOutput = chart;
  }

   //============================================= Monthly Output loss waterfall chart ===============================//

  drawMonthlyOutputLoss(response: any) {
    this.chartOutputLossData.length = 0;
    this.chartOutputLossData.push({ label: 'Good Output', value: response.GoodsOutput ? response.GoodsOutput : 0 });
    this.chartOutputLossData.push({ label: 'Waste', value: response.WasteOutput ? response.WasteOutput : 0 });
    this.chartOutputLossData.push({ label: 'Speed Loss', value: response.SpeedLossOutput ? response.SpeedLossOutput : 0 });
    this.chartOutputLossData.push({ label: 'Shutdown loss', value: response.ShutDownOutput ? response.ShutDownOutput : 0 });
    this.chartOutputLossData.push({ label: 'DownTime loss', value: response.DowntimeOutput ? response.DowntimeOutput : 0 });
    this.waterfallMonthlyOutputLoss();
  }

  waterfallMonthlyOutputLoss() {
    let wasteData: any[] = new Array();
    this.chartOutputLossData.forEach(element => {
      wasteData.push({ 'name': element.label, 'y': element.value });
    });
    wasteData.push({ 'name': 'Total', 'isIntermediateSum': true, 'color': '#0f0fd1' });

    this.options = {
      chart: {
        type: 'waterfall',
        inverted: false 
      },

      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category',
      },

      yAxis: {
        title: {
          text: 'Output Losses'
        },
      },

      legend: {
        enabled: false
      },

      tooltip: {
        pointFormat: '<b>{point.y:,.2f}</b>'
      },
      plotOptions: {
        series : {
          borderColor: ''
        }
      },
      series: [{
        type: 'waterfall',
        name: 'Output Losses',
        color: '#5adb90',
        data: wasteData,
      }]

    };
    let chart = new Chart(this.options);
    this.chartOutputLossWaterfall = chart;
  }

  nFormatter(num: any, digits: any) {

    if (digits === undefined) {
      digits = 1;
    }
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: " K" },
      { value: 1E6, symbol: " M" },
      { value: 1E9, symbol: " G" },
      { value: 1E12, symbol: " T" },
      { value: 1E15, symbol: " P" },
      { value: 1E18, symbol: " E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }

  //=============================================================== Color Coding ==========================================//

  setColorCoding() {
    //OEE
    if (this.oeeAnalysis.progress > 0 && this.oeeAnalysis.progress <= 20) {
      this.oeeAnalysis.color = "#fa1f0f";
    } else if (this.oeeAnalysis.progress > 20 && this.oeeAnalysis.progress <= 50) {
      this.oeeAnalysis.color = "#fae311";
    } else if (this.oeeAnalysis.progress > 50 && this.oeeAnalysis.progress <= 70) {
      this.oeeAnalysis.color = "#fc7005";
    } else if (this.oeeAnalysis.progress > 70) {
      this.oeeAnalysis.color = "#069420";
    }
    //CU
    if (this.capacityUtilizationAnalysis.progress > 0 && this.capacityUtilizationAnalysis.progress <= 20) {
      this.capacityUtilizationAnalysis.color = "#fa1f0f";
    } else if (this.capacityUtilizationAnalysis.progress > 20 && this.capacityUtilizationAnalysis.progress <= 50) {
      this.capacityUtilizationAnalysis.color = "#fae311";
    } else if (this.capacityUtilizationAnalysis.progress > 50 && this.capacityUtilizationAnalysis.progress <= 70) {
      this.capacityUtilizationAnalysis.color = "#fc7005";
    } else if (this.capacityUtilizationAnalysis.progress > 70) {
      this.capacityUtilizationAnalysis.color = "#069420";
    }
    // Availability
    if (this.availabilityAnalysis.progress > 0 && this.availabilityAnalysis.progress <= 20) {
      this.availabilityAnalysis.color = "#fa1f0f";
    } else if (this.availabilityAnalysis.progress > 20 && this.availabilityAnalysis.progress <= 50) {
      this.availabilityAnalysis.color = "#fae311";
    } else if (this.availabilityAnalysis.progress > 50 && this.availabilityAnalysis.progress <= 70) {
      this.availabilityAnalysis.color = "#fc7005";
    } else if (this.availabilityAnalysis.progress > 70) {
      this.availabilityAnalysis.color = "#069420";
    }
    //performanceAnalysis
    if (this.performanceAnalysis.progress > 0 && this.performanceAnalysis.progress <= 20) {
      this.performanceAnalysis.color = "#fa1f0f";
    } else if (this.performanceAnalysis.progress > 20 && this.performanceAnalysis.progress <= 50) {
      this.performanceAnalysis.color = "#fae311";
    } else if (this.performanceAnalysis.progress > 50 && this.performanceAnalysis.progress <= 70) {
      this.performanceAnalysis.color = "#fc7005";
    } else if (this.performanceAnalysis.progress > 70) {
      this.performanceAnalysis.color = "#069420";
    }
    //qualityAnalysis
    if (this.qualityAnalysis.progress > 0 && this.qualityAnalysis.progress <= 20) {
      this.qualityAnalysis.color = "#fa1f0f";
    } else if (this.qualityAnalysis.progress > 20 && this.qualityAnalysis.progress <= 50) {
      this.qualityAnalysis.color = "#fae311";
    } else if (this.qualityAnalysis.progress > 50 && this.qualityAnalysis.progress <= 70) {
      this.qualityAnalysis.color = "#fc7005";
    } else if (this.qualityAnalysis.progress > 70) {
      this.qualityAnalysis.color = "#069420";
    }
  }
 
  //========================================================= AG Grid ====================================================//
 
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  excelExport() {
    this.gridApi.exportDataAsExcel();
  }
  csvExport() {
    this.gridApi.exportDataAsCsv();
  }
  

  //============================================================  Drill Down ===========================================================//
  monthlyOEEDrillDown() {
    var ySum: number = 0;
    var yrSum: number = 0;
    var ycSum: number = 0;
    var yfSum: number = 0;
    let pData: any[] = new Array();
    let drillData: any[] = new Array();
    let rData: any[] = new Array();
    let cData: any[] = new Array();
    let fData: any[] = new Array();
    let main = new Set();
    let category = new Set();
    let reason = new Set();
    let fault = new Set();

    this.OeeDashboardData.dtDrillDownChartData.forEach((element: any) => {
      main.add(element.Main);
      category.add(element.Category);
      reason.add(element.Reson);
      fault.add(element.Fault);

    });
    
    main.forEach(m => {
      this.OeeDashboardData.dtDrillDownChartData.forEach((element: any) => {
        if (m === element.Main) {
          ySum += element.Perc
          const dataKey = {
            name: m,
            y: ySum,
            drilldown: element.Category
          }
          pData.push(dataKey);
        } else {
          ySum = 0;
        }
      });
    });

    category.forEach((c: any) => {
      this.OeeDashboardData.dtDrillDownChartData.forEach((e: any) => {
        if (c === e.Category) {
          const data = {
            name: e.Category,
            y: ycSum += e.Perc,
            drilldown: e.Reson
          };
          cData.push(data);
        } else {
          ycSum = 0;
        }
      });
      const keyDataCategory = {
        id: c,
        name: c,
        type: 'column',
        data: cData
      }
      drillData.push(keyDataCategory);
      cData = [];

    });

    reason.forEach((r: any) => {
      this.OeeDashboardData.dtDrillDownChartData.forEach((e: any) => {
        if (r === e.Reson) {
          const data = {
            name: e.Reson,
            y: yrSum += e.Perc,
            drilldown: e.Fault
          };
          rData.push(data);
        } else {
          yrSum = 0;
        }
      });
      const keyDataReason = {
        id: r,
        name: r,
        type: 'column',
        data: rData
      }
      drillData.push(keyDataReason);
      rData = [];

    });

    fault.forEach((f: any) => {
      this.OeeDashboardData.dtDrillDownChartData.forEach((e: any) => {
        if (f === e.Fault) {
          const data = {
            name: e.Fault,
            y: yfSum += e.Perc,
            drilldown: e.Fault
          };
          fData.push(data);
        } else {
          yfSum = 0;
        }
      });
      const keyDataFault = {
        id: f,
        name: f,
        type: 'column',
        data: fData
      }
      drillData.push(keyDataFault);
      fData = [];

    });

    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        min:0,
        max:100,
        labels : {
          format: '{value}%'
        },
        title:{
          text:'OEE Looses'
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: ' %'
      },
      series: [
        {
          name: 'Main',
          type: 'column',
          colorByPoint: true,
          data: pData
        }
      ],
      drilldown: {
        "allowPointDrilldown": false,
        activeAxisLabelStyle: {
          textDecoration: 'none',
          fontStyle: 'normal'
        },
        activeDataLabelStyle: {
          textDecoration: 'none',
          fontStyle: 'normal'
        },
        "series": drillData,
        drillUpButton: {
          relativeTo: 'spacingBox',
          position: {
            y: 0,
            x: 0
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'blue',
            r: 0,
            states: {
              hover: {
                fill: '#ffffff'
              },
              select: {
                stroke: '#039',
                fill: '#0f02fa'
              }
            }
          }

        },
      }
    };
    let chart = new Chart(this.options);
    this.chartMonthlyOeeDrill = chart;
  }
  showPhase(event:any){
    this.activeButton = event;
  }
}

