import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class KpiactualentryService {
  
  O3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  addActualEntry(data: any): any {
    // return this.http.post<any>(this.baseUrl + 'actuallentry', data);
    return this.http.post<any>(
      this.O3CoreApiUrl + 'ActualEntry/AddActualEntry',
      data
    );
  }

  

  GetKPIActualEntryForKpi(data: any): any {
    // return this.http.post<any>(this.baseUrl + 'actuallentry', data);
    return this.http.post<any>(
      this.O3CoreApiUrl + 'ActualEntry/GetKPIActualEntryForKpi',
      data
    );
  }
  deleteActualEntry(data: any): any {
    // return this.http.post<any>(this.baseUrl + 'deleteActualEntry', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'ActualEntry/DeleteActualEntry', data);
  }
  getActualEntry(data: any): any {
    return this.http.post<any>(
      this.O3CoreApiUrl + 'ActualEntry/GetKpiTargetActualEntry',
      data
    );
  }
  getKPIActualEntry(data: any): any {
    return this.http.post<any>(
      this.O3CoreApiUrl + 'ActualEntry/GetKPIActualEntry',
      data
    );
  }
  executeActualEntry(data: any): any {
    return this.http.post<any>(
      this.O3CoreApiUrl + 'ActualEntry/ExecuteActualEntry',
      data
    );
  }

  getRaciInformation(data: any): any {
    return this.http
      .post<any>(this.O3CoreApiUrl + 'ActualEntry/GetKpiRaciInformation', data)
      .toPromise();
  }

  getActualEntryExportData(data: any): any {
    return this.http.post<any>(this.O3CoreApiUrl + 'ActualEntry/ExportActuaEntry', data);
  }
  getTargetExportData(data: any): any {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITargets/ExportTarget', data);
  }
  importActualEntry(data: any): any {
    return this.http.post<any>(this.O3CoreApiUrl + 'ActualEntry/ImportAcutalEntry', data);
  }
  importTarget(data: any): any {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITargets/ImportTarget', data);
  }
}
