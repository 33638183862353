export const MONTHS = [
  {
    Id: 1,
    name: 'January',
  },
  {
    Id: 2,
    name: 'February',
  },
  {
    Id: 3,
    name: 'March',
  },
  {
    Id: 4,
    name: 'April',
  },
  {
    Id: 5,
    name: 'May',
  },
  {
    Id: 6,
    name: 'June',
  },
  {
    Id: 7,
    name: 'July',
  },
  {
    Id: 8,
    name: 'August',
  },
  {
    Id: 9,
    name: 'September',
  },
  {
    Id: 10,
    name: 'October',
  },
  {
    Id: 11,
    name: 'November',
  },
  {
    Id: 12,
    name: 'December',
  },
];

export const WEEKSCOUNT = [
  { countId: 1, count: 'first' },
  { countId: 2, count: 'second' },
  { countId: 3, count: 'third' },
  { countId: 4, count: 'fourth' },
  { countId: 5, count: 'last' },
];
export const WEEKDAYS = [
  { Id: 1, name: 'Sunday' },
  { Id: 2, name: 'Monday' },
  { Id: 3, name: 'Tuesday' },
  { Id: 4, name: 'Wednesday' },
  { Id: 5, name: 'Thursday' },
  { Id: 6, name: 'Friday' },
  { Id: 7, name: 'Saturday' },
];
export const HIGHCHARTCOLORS = [
  '#00008B',
  '#34A56F',
  '#F80000',
  '#2D0A77',
  '#8C001A',
  '#FF69B4',
  '#FA4A08',
  '#5453A6',
  '#8B008B',
  '#3090C7',
  '#F1C40F',
  '#CB4335',
  '#36454F',
  '#A569BD',
  '#FFA500',
  '#179fcc',
  '#093323',
  '#08fa03',
  '#048b8e',
  '#9637A4',
  '#F39C12',
  '#0b774e',
  '#F5B7B1',
  '#0d98da',
  '#512E5F',
  '#C471A5',
  '#0034bb',
  '#06573d',
  '#0d6e54',
  '#0692aa',
  '#611745',
  '#F80000',
  '#FFDF00',
  '#6D81A8',
  '#08FAC7',
  '#D208FA',
  '#FAEB08',
];
