import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpmsGlassService } from 'src/app/services/opms-glass.service';
import * as Highcharts from 'highcharts';
import * as _moment from 'moment';

@Component({
  selector: 'app-yield-detail',
  templateUrl: './yield-detail.component.html',
  styleUrls: ['./yield-detail.component.scss']
})
export class YieldDetailComponent implements OnInit {

  yieldDetailData: FormGroup;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public parmsData: any,
    private datePipe: DatePipe,
    private opmsGlassservice: OpmsGlassService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.yieldDetailData = this.fb.group({
      startDate: [this.datePipe.transform(new Date(this.parmsData.StartDate), 'yyyy-MM-dd')],
      endDate: [this.datePipe.transform(new Date(this.parmsData.EndDate), 'yyyy-MM-dd')]
    });
    this.getYieldDetailDayWise();
  }

  getYieldDetailDayWise(){
    this.spinner.show();
    const paramsData = {
      StartDate: _moment(this.yieldDetailData.get('startDate').value).utc(true),
      EndDate:  _moment(this.yieldDetailData.get('endDate').value).utc(true)
    }
    const dates: any[] = [];
    const yieldpercentage: any[] = [];
    let avgYieldChartOptions = {};
    this.opmsGlassservice.GetYieldDetailDayWise(paramsData).subscribe(
      (response: any) => {
        if(!response.HasError){
          if (response.result.length) {
            response.result.forEach((data: any) => {
              dates.push(data.Date);
              yieldpercentage.push([new Date(data.Date).getTime(),data.YieldPercentage]);
            });
            avgYieldChartOptions = {
              chart: {
                type: 'line',
                height: 400,
              },
              credits: {
                enabled: false,
              },
              title: {
                text: 'Yield% By Date',
              },
  
              xAxis: {
                tickPixelInterval: 100,
                labels: {
                  format: '{value:%Y-%m-%d}'
                },
                title: {
                  enabled: false,
                  text: 'Date',
                },
              },
              yAxis: {
                title: {
                  text: 'Yield%',
                  align: 'high',
                  x: 12,
                  y: 80
                },
              labels: {
                  enabled: true,
                  x: 15,
              }
              },
  
              plotOptions: {
                line: {
                  dataLabels: {
                    enabled: true
                  },
                },
              },
              rangeSelector: {
                buttons: [{
                  count: 5,
                  type: 'day',
                  text: '5D'
                }, {
                  count: 30,
                  type: 'day',
                  text: '30D'
                },{
                  count: 365,
                  type: 'day',
                  text: '1Y'
                }, {
                  type: 'all',
                  text: 'All'
                }],
                inputEnabled: false,
                selected: 0
              },
              legend: {
                enabled: false
              },
              tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.x: %Y-%m-%d}: {point.y}'
              },
              series: [
                {
                  name: 'Yield%',
                  color: '#DF5353',
                  data: yieldpercentage,
                },
              ],
            };
          }
          else{
            avgYieldChartOptions = {
              chart: {
                type: 'bar',
              },
              credits: {
                enabled: false,
              },
              title: {
                text: 'Yield% By Date',
              },
  
              xAxis: {
                min: 0,
                categories: dates,
                title: {
                  text: 'Date',
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: '',
                  align: 'high',
                },
                labels: {
                  overflow: 'justify',
                },
              },
  
              plotOptions: {
                bar: {
                  dataLabels: {
                    enabled: true,
                  },
                },
              },
              series: [
                {
                  name: 'Yield%',
                  color: '#0692aa',
                  data: yieldpercentage,
                },
              ],
            };
            this.toaster.warning('Warning','No Data Available')
          }
          Highcharts.stockChart('YieldDetail', avgYieldChartOptions);
          this.spinner.hide();
        }else{
          this.spinner.hide();
          this.toaster.error("Error",response.ErrorMessage);
        }
      },
      (error: any) =>{
        this.spinner.hide();
        this.toaster.error("Error", error.error);
      }
    );
  }

}
