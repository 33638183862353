import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../services/auth.guard';
import { DtConfigurationComponent } from './dt-configuration/dt-configuration.component';

const routes: Routes = [
      { path: 'ReasonAndCategories', component: DtConfigurationComponent , canActivate: [AuthGuard] },

];
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DTRoutingModule {}
