import * as Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import More from 'highcharts/highcharts-more';
import ExportData from 'highcharts/modules/export-data';
import Accessibility from 'highcharts/modules/accessibility';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { StockChart } from 'angular-highcharts';
import highcharts3D from 'highcharts/highcharts-3d';
import Histogram from 'highcharts/modules/histogram-bellcurve';
import HC_fullscreen from 'highcharts/modules/full-screen';
import HC_more from 'highcharts/highcharts-more';
import SeriesLabel from 'highcharts/modules/series-label';
import * as moment from 'moment';
import { DynamicDashboardComponent } from '../screens/dashboard/dynamic-dashboard/dynamic-dashboard.component';
import { LandingPageComponent } from '../screens/landing-page/landing-page/landing-page.component';

More(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);
highcharts3D(Highcharts);
Histogram(Highcharts);
HC_more(Highcharts);
SeriesLabel(Highcharts);
export class DynamicChart {
  options: Options;
  stock!: StockChart;
  format = 'YYYY-MM-DD HH:mm:ss';
  highChartsColor = [
    '#00008B',
    '#34A56F',
    '#F80000',
    '#2D0A77',
    '#8C001A',
    '#FF69B4',
    '#FA4A08',
    '#5453A6',
    '#8B008B',
    '#3090C7',
    '#F1C40F',
    '#CB4335',
    '#36454F',
    '#A569BD',
    '#FFA500',
    '#179fcc',
    '#093323',
    '#08fa03',
    '#048b8e',
    '#9637A4',
    '#F39C12',
    '#0b774e',
    '#F5B7B1',
    '#0d98da',
    '#512E5F',
    '#C471A5',
    '#0034bb',
    '#06573d',
    '#0d6e54',
    '#0692aa',
    '#611745',
    '#F80000',
    '#FFDF00',
    '#6D81A8',
    '#08FAC7',
    '#D208FA',
    '#FAEB08',
  ];

  constructor() {

  }

  drawDrillDownChart(data, element) {
    //this.chartArrayRight = [];
    const chartObj = { Item: {}, chartOption: {} };

    let parent = [];
    let drillData = [];

    // Process Parent Data from data.Table
    data.Table.forEach(val => {
      const parentData = {
        name: val.name,
        y: val.result,
        drilldown: val.name
      }
      parent.push(parentData);
    });

    // Process Child Data for Drilldown from data.Table1
    data.Table.forEach(element => {
      let child = [];

      data.Table1.forEach(val => {
        if (element.name === val.id) { // Match 'id' from data.Table1 with 'name' from data.Table
          const childData = {
            name: val.name,
            y: val.result,
            drilldown: val.name // Assuming 'name' is used for further drilldown, adjust if necessary
          }
          child.push(childData);
        }
      });

      const keyData = {
        id: element.name,
        data: child
      }
      drillData.push(keyData);
    });

    // Highcharts Options Configuration
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: element.labelName, // Replace with your title
        style: {
          fontWeight: 'bold'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: 'Values',
        },
      },
      plotOptions: {
        series: {
          turboThreshold: 1000000,
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        valueDecimals: 2,
        formatter: function () {
          return '<b>' + this.point.name + ': ' + this.point.y + '</b>';
        }
      },
      series: [
        {
          name: 'Main',
          type: 'column',
          colorByPoint: true,
          data: parent,
        },
      ],
      drilldown: {
        series: drillData
      }
    };
    let chart = new Chart(this.options);
    chartObj.chartOption = chart;
    chartObj.Item = element;
    return chartObj;
  }

  //=============================================Line Comparison Chart=========================================================//
  drawLineComparison(data1, element) {
    const chartObj = { Item: {}, chartOption: {} };
    const yAxisArr: any[] = [];
    const array: any[] = [];
    const names = [];
    const uniqueData: any[] = [];
    if (data1.Table2.length > 0) {
      data1.Table2.forEach(element => {
        const obj = {
          name: element.name,
          Id: element.id
        }
        uniqueData.push(obj);
      });

      const myArray = uniqueData.filter((v, i, a) => a.findIndex(t => t.Id === v.Id) === i);

      var data: any[] = new Array();
      data = data1?.Table2;
      for (let i = 0; i < myArray.length; i++) {
        const dataArr = [];
        for (let j = 0; j < data.length; j++) {
          if (myArray[i].Id === data[j].id) {
            dataArr.push({ x: new Date(data[j].Date).getTime(), y: parseFloat(data[j].result), label: data[j].name });
          }

        }
        let negativeArr = [];
        //console.log(dataArr);
        negativeArr = dataArr.map((c) => c.y);
        const obj = {
          tooltip: {
            valueDecimals: 2,
          },
          name: myArray[i].name,
          type: 'line',
          data: dataArr,
          color: this.highChartsColor[i],
          yAxis: i,
        };
        if (obj.data.length > 0) {
          array.push(obj);
        }

        yAxisArr.push({
          lineWidth: 1,
          visible: true,
          // opposite:false,
          opposite: negativeArr.some((v) => v < 0) ? true : false,
          title: {
            text: names[i],
            style: {
              color: this.highChartsColor[i],

              fontWeight: 'bold'  // Makes the title bold
            },
          },
          labels: {
            style: {
              color: this.highChartsColor[i],
            },
          },
        });
      }
      yAxisArr.forEach((yAxis) => { // hide y axis
        yAxis.visible = false;
      });
      // creating chart

      this.stock = new StockChart({
        chart: {
          type: 'line',
          zoomType: 'x'
        },
        rangeSelector: {
          selected: 4
        },
        yAxis: yAxisArr,
        xAxis: {
          type: 'datetime'
        },
        plotOptions: {
          series: {
            compare: 'percent',
            showInNavigator: true,
            turboThreshold: 1000000,
            events: {
              click: this.openActionsModel.bind(this, element)
            },
          },

        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: true
        },
        title: {
          text: element.labelName,
          style: {
            fontWeight: 'bold'  // Makes the title bold
          },
        },

        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
          valueDecimals: 2,
          split: true
        },

        series: array
      });
    } else if (data1.Table3.length > 0) {
      data1.Table3.forEach(element => {
        const obj = {
          name: element.name,
          Id: element.id
        }
        uniqueData.push(obj);
      });

      const myArray = uniqueData.filter((v, i, a) => a.findIndex(t => t.Id === v.Id) === i);

      var data: any[] = new Array();
      data = data1?.Table3;
      for (let i = 0; i < myArray.length; i++) {
        const dataArrActual = [];
        const dataArrTarget = [];
        for (let j = 0; j < data.length; j++) {
          if (myArray[i].Id === data[j].id) {
            dataArrActual.push({ x: new Date(data[j].Date).getTime(), y: parseFloat(data[j].resultActual) });
            dataArrTarget.push({ x: new Date(data[j].Date).getTime(), y: parseFloat(data[j].resultTarget) });
          }

        }

        const objActual = {
          name: myArray[i].name + ' -Actual',
          data: dataArrActual,
          type: 'line',
          color: '#f70707',
          yAxis: i,
        };
        if (objActual.data.length > 0) {
          array.push(objActual);
        }

        const objTarget = {
          name: myArray[i].name + ' -Target',
          data: dataArrTarget,
          type: 'line',
          color: '#0808c0',
          yAxis: i,
        };
        if (objTarget.data.length > 0) {
          array.push(objTarget);
        }
      }

      this.stock = new StockChart({
        chart: {
          type: 'line',
          zoomType: 'x'
        },
        rangeSelector: {
          selected: 4
        },
        title: {
          text: element.labelName,
          style: {
            fontWeight: 'bold'  // Makes the title bold
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: 'Value'
          }
        },
        plotOptions: {
          series: {
            turboThreshold: 1000000,
            events: {
              click: this.openActionsModel.bind(this, element)
            },
          },
        },
        legend: {
          enabled: true
        },
        series: array
      });
    }

    chartObj.chartOption = this.stock;
    chartObj.Item = element;
    return chartObj;
  }

  //==========================================Column Comparision =============================================//
  drawColumnComparison(data1: any, element: any) {
    const chartObj = { Item: {}, chartOption: {} };
    const array: any[] = [];
    const arrayTarget = [];
    const uniqueData: any[] = [];
    data1.Table3.forEach(element => {
      const obj = {
        name: element.name,
        Id: element.Id
      }
      uniqueData.push(obj);
    });

    const myArray = uniqueData.filter((v, i, a) => a.findIndex(t => t.Id === v.Id) === i);

    var data: any[] = new Array();
    data = data1?.Table3;
    for (let i = 0; i < myArray.length; i++) {
      const dataArrActual = [];
      const dataArrTarget = [];
      for (let j = 0; j < data.length; j++) {
        if (myArray[i].Id === data[j].Id) {
          dataArrActual.push({ x: new Date(data[j].Date).getTime(), y: parseFloat(data[j].resultActual) });
          dataArrTarget.push({ x: new Date(data[j].Date).getTime(), y: parseFloat(data[j].resultTarget) });
        }

      }

      const objActual = {
        name: myArray[i].name + ' -Actual',
        type: 'column',
        data: dataArrActual,
        color: '#f70707',
        // yAxis: i,
      };
      if (objActual.data.length > 0) {
        array.push(objActual);
      }

      const objTarget = {
        name: myArray[i].name + ' -Target',
        type: 'column',
        data: dataArrTarget,
        color: '#0808c0',
        // yAxis: i,
      };
      if (objTarget.data.length > 0) {
        array.push(objTarget);
      }
    }
    this.stock = new StockChart({
      chart: {
        type: 'column',
        zoomType: 'x'
      },
      rangeSelector: {
        selected: 4
      },
      title: {
        text: element.labelName,
        style: {
          fontWeight: 'bold'  // Makes the title bold
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: 'Value'
        }
      },
      plotOptions: {
        series: {
          turboThreshold: 1000000,
          events: {
            click: this.openActionsModel.bind(this, element)
          },
        },
      },
      legend: {
        enabled: true
      },
      series: array
    });
    // let chart =  new Chart(this.options);
    chartObj.chartOption = this.stock;
    chartObj.Item = element;
    return chartObj;
  }



  draw3dDonut(data, element) {
    const chartObj = { Item: {}, chartOption: {} };
    const title = element.labelName;
    let chartLeftData = [];
    data.Table.forEach(val => {
      chartLeftData.push({ name: val.name, y: val.result });
      //this.chartLeftCategory.push({name : val.name});
    });

    this.options = {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: title,
        style: {
          fontWeight: 'bold'  // Makes the title bold
        }
      },
      credits: {
        enabled: false
      },
      subtitle: {
        text: ''
      },
      plotOptions: {
        pie: {
          innerSize: 100,
          depth: 45
        },
        series: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '<span style="font-size: 10px; font-weight: normal">' + this.point.name === undefined ? (this.y).toFixed(2) : this.point.name + ' ' + (this.y).toFixed(2) + ' </span>';
            }
          },
          events: {
            click: this.openActionsModel.bind(this, element)
          },
        }
      },
      series: [{
        name: title,
        type: 'pie',
        data: chartLeftData
      }]
    };
    let chart = new Chart(this.options);
    chartObj.chartOption = chart;
    chartObj.Item = element;
    return chartObj;
  }

  draw3dColumn(data, element) {
    const chartObj = { Item: {}, chartOption: {} };
    const title = element.labelName;
    let chartLeftData = [];
    let chartLeftCategory = [];
    data.Table1.forEach(val => {
      chartLeftData.push({ name: '', y: val.result });
      chartLeftCategory.push(val.name);
    })

    this.options = {
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 15,
          beta: 15,
          depth: 50,
          viewDistance: 25
        }
      }, xAxis: {
        categories: chartLeftCategory
      },
      yAxis: {

      },
      credits: {
        enabled: false
      },
      title: {
        text: title,
        style: {
          fontWeight: 'bold'  // Makes the title bold
        }
      },
      subtitle: {
        text: ''
      },
      plotOptions: {
        column: {
          depth: 25
        },
        series: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '<span style="font-size: 10px; font-weight: normal">' + this.point.name === undefined ? (this.y).toFixed(2) : this.point.name + ' ' + (this.y).toFixed(2) + ' </span>';
            }
          },
          events: {
            click: this.openActionsModel.bind(this, element)
          },
        }
      },
      series: [{
        name: title,
        type: 'column',
        data: chartLeftData,
        colorByPoint: true
      }]
    };
    let chart = new Chart(this.options);
    chartObj.chartOption = chart;
    chartObj.Item = element;
    return chartObj;
  }

  draw3dPie(data, element) {
    const chartObj = { Item: {}, chartOption: {} };
    const title = element.labelName;
    let chartLeftData = [];
    data.Table.forEach(val => {
      chartLeftData.push({ name: val.name, y: val.result });
      //this.chartLeftCategory.push({name : val.name});
    })

    this.options = {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
        }
      },
      title: {
        text: title,
        style: {
          fontWeight: 'bold'  // Makes the title bold
        }
      },

      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          }
        },
        series: {
          events: {
            click: this.openActionsModel.bind(this, element)
          },
        }
      },
      series: [{
        type: 'pie',
        name: title,
        data: chartLeftData
      }]
    }
    let chart = new Chart(this.options);
    chartObj.chartOption = chart;
    chartObj.Item = element;
    return chartObj;
  }


  drawDonut(data, element) {
    const chartObj = { Item: {}, chartOption: {} };
    const title = element.labelName;
    let chartLeftData = [];
    data.Table.forEach(val => {
      chartLeftData.push({ name: val.name, y: val.result, color: this.getRandomColor() });
    });

    this.options = {
      chart: {
        type: 'pie'
      },
      title: {
        text: title,
        style: {
          fontWeight: 'bold'  // Makes the title bold
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          innerSize: '50%',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} '
          }
        },
        series: {
          events: {
            click: this.openActionsModel.bind(this, element)
          },
        }
      },
      series: [{
        name: '',
        type: 'pie',
        data: chartLeftData
      }]
    };
    let chart = new Chart(this.options);
    chartObj.chartOption = chart;
    chartObj.Item = element;
    return chartObj;
  }

  // stackedchart(data, element) {
  //   debugger;
  //   const chartObj = { Item: {}, chartOption: {} };
  //   const categories = [];
  //   const openCount = [];
  //   const closeCount = [];
  //   const totalCount = [];
  //   let dataCounts: any;
  //   if (data.Table4.length > 0) {
  //     data.Table4.forEach(obj => {
  //       categories.push(obj.name);
  //       openCount.push(obj.openCount);
  //       closeCount.push(obj.closeCount);
  //       totalCount.push(obj.totalCount);
  //     });

  //     dataCounts = [{
  //       name: 'Total',
  //       type: 'bar',
  //       color: '#2822e0',
  //       data: totalCount
  //     }, {
  //       name: 'Close',
  //       type: 'bar',
  //       color: '#09bc80',
  //       data: closeCount
  //     }, {
  //       name: 'Open',
  //       type: 'bar',
  //       color: '#fa120a',
  //       data: openCount
  //     }]
  //   } else if (data.Table6.length > 0) {
  //     data.Table6.forEach(obj => {
  //       categories.push(obj.name);
  //       openCount.push(obj.openCount);
  //       closeCount.push(obj.closeCount);
  //       totalCount.push(obj.delayCount);
  //     });

  //     dataCounts = [{
  //       name: 'Delay',
  //       type: 'bar',
  //       color: '#2822e0',
  //       data: totalCount
  //     }, {
  //       name: 'Close',
  //       type: 'bar',
  //       color: '#09bc80',

  //       data: closeCount
  //     }, {
  //       name: 'Open',
  //       type: 'bar',
  //       color: '#fa120a',
  //       data: openCount
  //     }]
  //   } else {
  //     data.Table7.forEach(obj => {
  //       categories.push(obj.name);
  //       openCount.push(obj.totalCount);
  //       closeCount.push(obj.withTarget);
  //       totalCount.push(obj.withoutTarget);
  //     });
  //     dataCounts = [{
  //       name: '# of KPIs Without Target',
  //       type: 'bar',
  //       color: '#fa120a',
  //       data: totalCount
  //     }, {
  //       name: '# of KPIs With Target',
  //       type: 'bar',
  //       color: '#09bc80',
  //       data: closeCount
  //     }, {
  //       name: '# of KPIs Created',
  //       type: 'bar',
  //       color: '#2822e0',

  //       data: openCount
  //     }]
  //   }

  //   this.options = {
  //     chart: {
  //       type: 'bar'
  //     },
  //     title: {
  //       text: element.labelName,
  //       style: {
  //         fontWeight: 'bold'  // Makes the title bold
  //       }
  //     },
  //     xAxis: {
  //       categories: categories
  //     },
  //     yAxis: {
  //       min: 0,
  //       title: {
  //         text: 'Count'
  //       }
  //     },
  //     legend: {
  //       reversed: true
  //     },
  //     plotOptions: {
  //       series: {
  //         stacking: 'normal',
  //         events: {
  //           click: this.openActionsModel.bind(this, element)
  //         }
  //       },
  //     },
  //     credits: {
  //       enabled: false
  //     },
  //     series: dataCounts
  //   };
  //   let chart = new Chart(this.options);
  //   chartObj.chartOption = chart;
  //   chartObj.Item = element;
  //   return chartObj;
  // }


  stackedchart(data, element) {
    const chartObj = { Item: {}, chartOption: {} };
    const categories: string[] = [];
    const totalPackedData: number[] = [];

    // Check if data is available in @result-like structure
    if (data.Table.length > 0) {
      // Loop through the data and push the 'name' and 'result' (TotalPacked) values
      data.Table.forEach(obj => {
        categories.push(obj.name);  // BoxType (name)
        totalPackedData.push(obj.result);  // TotalPacked
      });
    }

    // Define the data for the stacked bar chart (explicitly cast to SeriesBarOptions)
    const dataCounts: any[] = [{
      name: 'Total Packed (Tons)',
      type: 'bar',
      color: '#2822e0',
      data: totalPackedData  // Use the total packed values
    }];

    // Set up chart options
    this.options = {
      chart: {
        type: 'bar'
      },
      title: {
        text: element.labelName,
        style: {
          fontWeight: 'bold'  // Makes the title bold
        }
      },
      xAxis: {
        categories: categories  // BoxType categories
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total Packed (Tons)'  // Y-axis title
        }
      },
      legend: {
        reversed: true
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          events: {
            click: this.openActionsModel.bind(this, element)
          }
        }
      },
      credits: {
        enabled: false
      },
      series: dataCounts  // Cast dataCounts to SeriesOptionsType
    } as Highcharts.Options;

    let chart = new Highcharts.Chart(this.options);
    chartObj.chartOption = chart;
    chartObj.Item = element;
    return chartObj;
  }

  splineChartChemicalGlass(data, element) {
    const chartObj = { Item: {}, chartOption: {} };

    // Extract relevant data from the input
    const chartData = data.Table10[0];
    const categories = chartData.CreatedOn.split(',');
    const values = chartData.Value.split(',').map(str => parseFloat(str));
    const target = chartData.Target;
    let plotlines = [];
    let targetValue = null;

    // Process target and plot lines
    if (target && target.startsWith('M')) {
      targetValue = parseFloat(target.split(" ")[1]);
      let targetPosition = target.startsWith('Max.') ? -20 : 20;

      plotlines.push({
        color: '#ff5252', // Red line for target
        width: 2,
        value: targetValue,
        label: {
          text: target,
          style: {
            color: '#ff5252',
            fontWeight: 'bold'
          },
          textAlign: 'left',
          y: targetPosition
        }
      });
    } else if (target && target.includes('±')) {
      let lines = target.split('±');
      let baseTarget = parseFloat(lines[0]);
      let deviation = parseFloat(lines[1].split('%')[0]);

      plotlines.push({
        color: '#ff5252', // Max red line
        width: 2,
        value: baseTarget + deviation,
        label: {
          text: `Max. ${(baseTarget + deviation).toFixed(2)}%`,
          style: {
            color: '#ff5252',
            fontWeight: 'bold'
          },
          textAlign: 'left',
          y: -20
        }
      });
      plotlines.push({
        color: '#ff5252', // Min red line
        width: 2,
        value: baseTarget - deviation,
        label: {
          text: `Min. ${(baseTarget - deviation).toFixed(2)}%`,
          style: {
            color: '#ff5252',
            fontWeight: 'bold'
          },
          textAlign: 'left',
          y: 20
        }
      });
      targetValue = baseTarget; // Set target value for comparison
    }

    // Calculate y-axis limits
    const sortedValues = [...values].sort((a, b) => a - b);
    const yAxisMin = sortedValues[0] - 2;
    const yAxisMax = sortedValues[sortedValues.length - 1] + 2;

    // Determine color based on whether values are above or below the target
    let seriesColor = '#09bc80'; // Default color is green
    if (targetValue !== null) {
      if (values.some(val => val > targetValue)) {
        seriesColor = '#2822e0'; // Change to blue if any value is above the target
      }
    }

    // Configure chart options
    this.options = {
      chart: {
        type: 'spline'
      },
      title: {
        text: element.labelName,
        style: {
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: `Target - ${target}`
        },
        min: yAxisMin,
        max: yAxisMax,
        plotLines: plotlines
      },
      plotOptions: {
        spline: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: true
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'spline', // Specify the type as 'spline'
        data: values,
        color: seriesColor // Apply the determined color (green or blue)
      }]
    };

    // Create the chart
    let chart = new Chart(this.options);
    chartObj.chartOption = chart;
    chartObj.Item = element;
    return chartObj;
  }


  splineChart(data, element) {
    const chartObj = { Item: {}, chartOption: {} };
    const categories = [];
    const closePer = [];
    const totalCount = [];
    data.Table5.forEach(obj => {
      categories.push(obj.name);
      closePer.push(obj.closePercentage);
      totalCount.push(obj.totalCount);
    });

    this.options = {
      chart: {
        type: 'column'
      },
      title: {
        text: element.labelName,
        style: {
          fontWeight: 'bold'  // Makes the title bold
        }
      },
      xAxis: {
        categories: categories
      },
      yAxis: [{
        title: {
          text: 'Count'
        }
      }, {
        title: {
          text: 'Percentage'
        },
        opposite: true
      }],
      legend: {
        reversed: true
      },
      plotOptions: {
        series: {
          events: {
            click: this.openActionsModel.bind(this, element)
          }
        },

      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Total Count',
        type: 'column',
        color: '#2822e0',
        data: totalCount
      }, {
        name: 'Close Percentage',
        type: 'spline',
        color: '#09bc80',
        data: closePer
      }]
    };
    let chart = new Chart(this.options);
    chartObj.chartOption = chart;
    chartObj.Item = element;
    return chartObj;

  }

  drawGauge(data, element) {
    let max = 0;
    let value = 0;

    max = data?.Table8[0]?.maxval;
    value = data?.Table8[0]?.actualValue;

    const chartObj = { Item: {}, chartOption: {} };
    this.options = {
      chart: {
        type: 'solidgauge',
        events: {
          render: renderIcons
        }
      },

      title: {
        text: element.labelName,
        style: {
          fontWeight: 'bold'
        }
      },
      credits: {
        enabled: false
      },
      // tooltip: {
      //   borderWidth: 0,
      //   backgroundColor: 'none',
      //   shadow: false,
      //   style: {
      //     fontSize: '16px'
      //   },
      //   valueSuffix: '',
      //   pointFormat: '{series.name}<br>' +
      //     '<span style="font-size: 2em; color: {point.color}; ' +
      //     'font-weight: bold">{point.y}</span>',
      //   positioner: function (labelWidth) {
      //     return {
      //       x: (this.chart.chartWidth - labelWidth) / 2,
      //       y: (this.chart.plotHeight / 2) + 15
      //     };
      //   }
      // },

      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{ // Track for Conversion
          outerRadius: '112%',
          innerRadius: '88%',
          backgroundColor: '#adadf7',
          borderWidth: 0
        }, { // Track for Engagement
          outerRadius: '87%',
          innerRadius: '63%',
          backgroundColor: value >= max ? '#9cf7df' : value >= (max * 0.9) ? '#f9faac' : '#faa7b4',
          borderWidth: 0
        }
        ]
      },

      yAxis: {
        min: 0,
        max: max,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true
        }
      },

      series: [{
        name: 'Target',
        type: 'solidgauge',
        data: [{
          color: '#0f0fd1',
          radius: '112%',
          innerRadius: '88%',
          y: max
        }],
        custom: {
          icon: 'commenting-o',
          iconColor: '#ffffff'
        }
      }, {
        name: 'Actual',
        type: 'solidgauge',
        data: [{
          color: value >= max ? '#10ce9c' : value >= (max * 0.9) ? '#ede90c' : '#e32242',
          radius: '87%',
          innerRadius: '63%',
          y: value
        }],
        dataLabels: {
          enabled: true,
          format: '<div><span style="font-size:35px;text-align:center;color:' +
            (value >= max ? '#10ce9c' : value >= (max * 0.9) ? '#ede90c' : '#e32242') + '">{y}</span><br/></div>',
          borderWidth: 0
        },
        //   custom: {
        //     icon: 'commenting-o',
        //     iconColor: '#ffffff'
        // }
      }]


    };
    let chart = new Chart(this.options);
    chartObj.chartOption = chart;
    chartObj.Item = element;
    return chartObj;
  }

  //========================================= UTC to MILI===========================================//
  getMili(Start_Time: any) {
    var date = moment(Start_Time).format(this.format);
    return new Date(date).getTime();
  }

  //==================get random color==============
  getRandomColor(): any {
    var letters = '0123456789ABCDEF';
    var color = '#'; // <-----------
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  openActionsModel(obj, elem) {
    if (obj.addedFrom === 'personalized') {
      LandingPageComponent.openActionsModel(obj, elem);
    } else {
      DynamicDashboardComponent.openActionsModel(obj, elem);
    }
  }

}

const trackColors = Highcharts.getOptions().colors.map(color =>
  new Highcharts.Color(color).setOpacity(0.3).get()
);

function renderIcons() {
  this.series.forEach(series => {
    if (series) {
      series.icon = this.renderer
        .text(
          series.points[0].y,
          0,
          0
        )
        .attr({
          zIndex: 10
        })
        .css({
          color: series.options.custom.iconColor,
          fontSize: '1.6em',
          fontWeight: 'bold'
        })
        .add(this.series[1].group);
    }
    series.icon.attr({
      x: this.chartWidth / 2 - 15,
      y: this.plotHeight / 2 -
        series.points[0].shapeArgs.innerR -
        (
          series.points[0].shapeArgs.r -
          series.points[0].shapeArgs.innerR
        ) / 2 +
        8
    });
  });
}

