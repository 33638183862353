import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class KpiGroupService {
    o3CoreUrl = environment.O3CoreApiUrl;

    constructor(private http: HttpClient) { }

    GetKPIGroups(data: any): Observable<any[]> {
        return this.http.post<any[]>(this.o3CoreUrl + 'KPIGroups/GetKPIGroups', data);
    }

    GetKPIDataTypes(): Observable<any[]> {
        return this.http.get<any[]>(this.o3CoreUrl + 'KPIGroups/GetKPIDataType');
    }
}
