<div class="fade-in-image" style="display: flex;">
  <img _ngcontent-acf-c153="" src="assets/images/logo-secondary.svg" alt="O3 Smart Manufacturing">
  <h1 class="main-title">Meeting Evaluation</h1>
</div>
<div class="meeting-evaluation-content">
<form [formGroup]='MeetingEvaluationForm'>
  <div style="display: flex; justify-content: center;">
  <div style="width: 75%;">
    <div class="content-top-row" style="justify-content: flex-start; margin-left: 6rem;">
      <mat-form-field appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing " style="margin-left:25px;">
        <mat-label>Meeting</mat-label>
        <input matInput formControlName="meeting" [readonly]="true"/>
      </mat-form-field>
      <!-- <mat-form-field appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing " style="margin-left:10px;">
        <mat-label>Location</mat-label>
        <input matInput formControlName="location" />
      </mat-form-field> -->
      <mat-form-field appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing " style="margin-left:10px;">
        <mat-label>Leader</mat-label>
        <input matInput formControlName="leader" [readonly]="true"/>
      </mat-form-field>
      <mat-form-field    [readonly]="true" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="margin-left: 10px;">
          <input matInput [ngxMatDatetimePicker]="timePicker" placeholder="Date" formControlName="date"
         [readonly]="true" />
          <mat-datepicker-toggle matSuffix [for]="$any(timePicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #timePicker> </ngx-mat-datetime-picker>
        </mat-form-field>
    </div>
  </div> 
  <div style="width: 25%;">
    <div class="content-top-row" style="display:flex;">
      <div>
        <p class="top-rightbox-maintext">Score</p>
      </div>
      <div >
        <p class="top-rightbox-text" style="border-bottom: none;">0 = No</p>
        <p class="top-rightbox-text" style="border-bottom: none;">0.5 = Partial</p>
        <p class="top-rightbox-text">1 = Yes</p>
      </div>
    </div>
  </div>
</div>
</form>
<div class="wrapper-box wrapper-box-shadow grid-container">

<ag-grid-angular
    #agGrid
    style="width: 100%; height: 800px;"
    class="ag-theme-alpine"
    [modules]="modules"
    [columnDefs]="columnDefs"
    [rowData]="rowData"
    [defaultColDef]="defaultColDef"
    [components]="components"
    [suppressRowTransform]="true"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="onGridSizeChanged($event)"
  ></ag-grid-angular>
  <div class="grid-box" style="width: 100%;">
    <div style="display:flex;">
      <div style="width: 55%;">
        <h3 class="total-section">
          Total
        </h3>
      </div>
      <div style="width: 45%;">
        <div style="display: flex;">
          <p class="footer-right-section" style="border-bottom: none;">{{totalWeight}}</p>
          <p class="footer-right-section" style="border-bottom: none;">{{totalScore}}</p>
        </div>
        
        <p class="footer-bottom-section">{{totalScorePercentage}}%</p>
      </div>
    </div>
  </div>

  <button
          type="button"
          mat-mini-fab
          mat-button
          (click)="addNewRow()"
          color="accent"
          pTooltip="Add New Row" tooltipPosition="top" 
          style="margin-top: 16px;"
        >
          <i class="fa fa-plus" style="color: #fff"></i>
        </button>
  <ag-grid-angular
    #agGrid
    style="width: 100%; height: 300px; margin-top: 30px;"
    class="ag-theme-alpine"
    [modules]="modules"
    [columnDefs]="columnDefs1"
    [rowData]="rowData1"
    [gridOptions]="gridOptions"
  ></ag-grid-angular>

  <div *ngIf="successMessage !== ''" class="success_message">
    <h2>{{successMessage}}</h2>
  </div>
  <div *ngIf="successMessage === ''" style="margin-top: 20px;">
    <h3 *ngIf="isSaving" style="font-weight: bold; color:#49FFB9; margin-top: 20px;">
      Saving Data...</h3>
    <button *ngIf="!isSaving" mat-flat-button color="accent" (click)="saveRecord()" class="btn-accent-big">
      Save Record
    </button>
  </div>
</div>
</div>

