import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
  PlantGroupElement,
  PlantModelElement,
} from 'src/app/models/O3AdministrationModels/plant-model';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UserManu } from 'src/app/models/userManu';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-plant-model',
  templateUrl: './plant-model.component.html',
  styleUrls: ['./plant-model.component.scss'],
})
export class PlantModelComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<PlantModelElement>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  formMode!: boolean;
  edited = false;
  plantModelColumns: string[] = [
    'plantModelActions',
    'EGId',
    'EGDesc',
    'Remarks',
    'GrpOrder',
    'IsActive',
    'NoOfLicense',
    'CreatedBy',
    'CreatedOn',
    
  ];
  plantModelData!: MatTableDataSource<PlantModelElement>;
  addNewPlantModeItemlForm!: FormGroup;
  isaddNewPlantModeItemModelVisible = false;
  addNewPlantModeItemlFormSubmitted = false;
  plnatPermission!: UserManu;

  plantgroup!: any;
  plantgroupFormMode!: boolean;

  isPlantGroupModelVisible = false;

  addnewGroupModelTitle = '';
  addNewGroupItemForm!: FormGroup;
  isaddNewGroupItemModelVisible = false;
  addNewGroupItemFormSubmitted = false;
  newGroupItemParent!: PlantGroupElement;
  breadcrumList!: any;
  editGroupModelTitle = '';
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();

  plantModelRowId!: number;
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  loading= false;
  ExportPG: any = [];
  ExportPara: any = [];
  excelData: any = [];
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length >= 0 && node.NextLevel != null
  constructor(
    private formBuilder: FormBuilder,
    private platModelService: EnterPriseGroupService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private userManagementService: UserManagementService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.formMode = true;
    this.plantgroupFormMode = true;
    this.addNewPlantModeItemlForm = this.formBuilder.group({
      groupDescription: ['', Validators.required],
      groupOrder: ['', Validators.required],
      active: [false],
      remarks: ['',Validators.required],
      noOfLic: ['',Validators.required]
    });
    this.addNewGroupItemForm = this.formBuilder.group({
        description: ['', Validators.required],
        order: ['', Validators.required],
        optional: [''],
        active: [''],
        remarks: ['']
    });
    this.getPlantModel();
  }
  // start add new plant methods
  openaddNewPlantItemModel(): void {
    this.addNewPlantModeItemlForm.reset();
    this.isaddNewPlantModeItemModelVisible = true;
    this.formMode = true;
  }
  closeaddNewPlantItemModel(): void {
    this.isaddNewPlantModeItemModelVisible = false;
  }

  addNewPlantModeItemlFormSubmit(): void {
    const groupKey = {
      EGDesc: this.addNewPlantModeItemlForm.value.groupDescription,
      Remarks: this.addNewPlantModeItemlForm.value.remarks,
      IsActive: this.addNewPlantModeItemlForm.value.active,
      GrpOrder: this.addNewPlantModeItemlForm.value.groupOrder,
      EGId: 0,
      NoOfLic: this.addNewPlantModeItemlForm.value.noOfLic,
      TransactionTypeId: 1
    };
    this.platModelService.submitGroup(groupKey).subscribe(
      (response: any) => {
        this.toaster.success('Success', response.ErrorMessage);
        this.closeaddNewPlantItemModel();
        this.getPlantModel();
        this.addNewPlantModeItemlForm.reset();
      },
      (error) => {
        this.toaster.error('Error', error.error.err.message);
      }
    );
    this.table.renderRows();
    this.addNewPlantModeItemlForm.reset();
    this.isaddNewPlantModeItemModelVisible = false;
  }
  // end add new plant methods
  // start edit plant methods
  openEditPlantItemModel(element: any): void {
    this.spinner.show();
    const key = {
      EGId: element.EGId
    };
    // if (this.plnatPermission.IsUpdate) {
    this.platModelService.getGroupList(key).subscribe((data: any) => {
      this.spinner.hide();
      const groupdata = data.groupList[0];
      this.openaddNewPlantItemModel();
      this.plantModelRowId = element.EGId;
      this.addNewPlantModeItemlForm.get('groupDescription')?.setValue(groupdata.EGDesc);
      this.addNewPlantModeItemlForm.get('groupOrder')?.setValue(groupdata.GrpOrder);
      this.addNewPlantModeItemlForm.get('active')?.setValue(element.IsActive);
      this.addNewPlantModeItemlForm.get('remarks')?.setValue(element.Remarks);
      this.addNewPlantModeItemlForm.get('noOfLic')?.setValue(element.NoOfLic);
      this.formMode = false;
    },
      (error) => {
      this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
    // }else {
    //   this.toaster.error(
    //     'Alert!',
    //     'you do not have permission to access'
    //   );
    // }
  }
  editPlantModeItemlFormSubmit(): void {
    const groupKey = {
      EGDesc: this.addNewPlantModeItemlForm.value.groupDescription,
      Remarks: this.addNewPlantModeItemlForm.value.remarks,
      IsActive:  this.addNewPlantModeItemlForm.value.active,
      GrpOrder: this.addNewPlantModeItemlForm.value.groupOrder,
      EGId: this.plantModelRowId,
      NoOfLic: this.addNewPlantModeItemlForm.value.noOfLic,
      TransactionTypeId: 2
    };
    this.platModelService.submitGroup(groupKey).subscribe(
      (response: any) => {
        // this.toaster.success('Success', 'Group Update Successfully');
        this.showPopupMsgForGroup(response.ErrorMessage);
        this.getPlantModel();
        this.addNewPlantModeItemlForm.reset();
      },
      (error) => {
        this.toaster.error('Error', error.error.err.message);
      }
    );
    this.table.renderRows();
    this.addNewPlantModeItemlForm.reset();
    this.isaddNewPlantModeItemModelVisible = false;
    this.table.renderRows();
  }
  // end edit plant methods
  // Start Delete Group
  deleteGroup(EGId: number): void {
    if (this.plnatPermission.IsDelete) {
      this.plantModelRowId = EGId;
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete Group!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          const groupKey = {
            EGDesc: '',
            Remarks: '',
            IsActive: 0,
            GrpOrder: 1,
            EGId,
            TransactionTypeId: 3
          };
          this.platModelService.submitGroup(groupKey).subscribe(
            (success: any) => {
              this.showPopupMsgForGroup(success.ErrorMessage);
              Swal.fire({
                title: 'Deleted!',
                text: 'Your selected Group has been deleted.',
                icon: 'success',
                timer: 800,
                showConfirmButton: false,
              });
              this.getPlantModel();
            },
            (error) => {
              this.toaster.error('Error', error.error.message);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your Group is safe :)', 'error');
        }
      });
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  // End Delete Group
  // start plant group methods
  openPlantGroupModel(element: any): void {
    this.isPlantGroupModelVisible = true;
    this.getTreeModel(element);
    this.plantgroup = element;
    // this.plantGroupSource.data = plantGroupData;
    // this.treeControl.dataNodes = this.plantGroupSource.data;
  }

  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }

  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  // end plant group methods
  // start group model methods
  openaddNewGroupModel(element: PlantGroupElement): void {
    // if (this.plnatPermission.IsCreate) {
      this.plantgroupFormMode = true;
      this.newGroupItemParent = element;
      this.addnewGroupModelTitle =  element.NextLevel; // (element.NextLevel === 'Unit' ? 'Machine' : element.NextLevel);
      this.addNewGroupItemForm.reset();
      this.isPlantGroupModelVisible = false;
      this.isaddNewGroupItemModelVisible = true;
      // tslint:disable-next-line: max-line-length
      if (element.NextLevel === 'Plant' || element.NextLevel === 'Division' || element.NextLevel === 'Department' || element.NextLevel === 'Assembly' || element.NextLevel === 'SubAssembly'){
        this.edited = true;
      } else {
       this.edited = false;
      }
    // } else {
    //   this.toaster.error('Alert!', 'you do not have permission to access');
    // }
  }
  addNewGroupModeItemlFormSubmit(): void {
    const data = {
      Desc: this.addNewGroupItemForm.value.description,
      Remarks: this.addNewGroupItemForm.value.remarks,
      IsOptional: this.addNewGroupItemForm.value.optional,
      IsActive: this.addNewGroupItemForm.value.active,
      Order: this.addNewGroupItemForm.value.order,
      Level: this.newGroupItemParent.Level,
      PrimaryId : this.newGroupItemParent.PrimaryId,
      ParentId: this.newGroupItemParent.parentId,
      TransactionTypeId: 1
    };
    this.platModelService.addPlantModalTree(data).subscribe(response => {
      // this.toaster.success('Success', response.ErrorMessage);
      this.showPopupMsg(response.ErrorMessage);
      // this.isaddNewGroupItemModelVisible = false;
      // this.isPlantGroupModelVisible = true;
      this.getTreeModel(this.plantgroup);
    }, error => {
      this.toaster.error('Error', error.message);
    });
  }
  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
       this.toaster.success('Success', msg.substring(3, msg.length));
       this.isaddNewGroupItemModelVisible = false;
       this.isPlantGroupModelVisible = true;
    }
    else {
      this.toaster.error('Error', msg.substring(3, msg.length));
    }
}
showPopupMsgForGroup(msg: any): any {
  if (msg.substring(2, 1) === '0') {
     this.toaster.success('Success', msg.substring(3, msg.length));
  }
  else {
    this.toaster.error('Error', msg.substring(3, msg.length));
  }
}

  closeaddNewGroupModel(): void {
    this.isaddNewGroupItemModelVisible = false;
    this.isPlantGroupModelVisible = true;
  }
   openEditGroupModel(element: PlantGroupElement): void {
    //  if ( this.plnatPermission.IsUpdate ){
     this.plantgroupFormMode = false;
       // tslint:disable-next-line:max-line-length
     if (element.Level === 'Plant' || element.Level === 'Division' || element.Level === 'Department' || element.Level === 'Assembly' || element.Level === 'SubAssembly'){
         this.edited = true;
       } else {
        this.edited = false;
       }
     const data = {
      Level: element.Level,
      PrimaryId: element.PrimaryId
     };
     this.newGroupItemParent = element;
     this.addnewGroupModelTitle = element.Level; // === 'Line' ? 'Machine' : element.Level);
     this.platModelService.getPlantModalById(data).subscribe(response => {
      this.addNewGroupItemForm.get('description')?.setValue(response.ChildModelList[0].Desc);
      this.addNewGroupItemForm.get('order')?.setValue(response.ChildModelList[0].Order);
      this.addNewGroupItemForm.get('optional')?.setValue(response.ChildModelList[0].IsOptional);
      this.addNewGroupItemForm.get('active')?.setValue(response.ChildModelList[0].IsActive);
      this.addNewGroupItemForm.get('remarks')?.setValue(response.ChildModelList[0].Remarks);
      this.isaddNewGroupItemModelVisible = true;
      this.isPlantGroupModelVisible = false;
    }, error => {
      this.toaster.error('Error', error.message);
    });
  // } else {
  //   this.toaster.error('Alert!', 'you do not have permission to access');
  // }
  }

  editNewGroupModeItemlFormSubmit(): void {
    const data = {
      Desc: this.addNewGroupItemForm.value.description,
      Remarks: this.addNewGroupItemForm.value.remarks,
      IsOptional: this.addNewGroupItemForm.value.optional,
      IsActive: this.addNewGroupItemForm.value.active,
      Order: this.addNewGroupItemForm.value.order,
      Level: this.newGroupItemParent.Level,
      PrimaryId : this.newGroupItemParent.PrimaryId,
      ParentId: this.newGroupItemParent.ParentEditId,
      TransactionTypeId: 2,
      EGId: this.plantgroup.EGId
    };
    this.platModelService.addPlantModalTree(data).subscribe(response => {
      this.showPopupMsg(response.ErrorMessage);
      this.getTreeModel(this.plantgroup);
    }, error => {
      this.toaster.error('Error', error.message);
    });
  }
  deleteGroupNode(node: any): void {
    // if (this.plnatPermission.IsDelete) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete ' + this.replaceIcon(node.icon) +  node.text + ' !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          const data = {
            Desc: '',
            Remarks:  '',
            IsOptional:  true,
            IsActive:  true,
            Order:  1,
            Level: node.Level,
            PrimaryId : node.PrimaryId,
            ParentId: node.ParentEditId,
            TransactionTypeId: 3,
            EGId: this.plantgroup.EGId
          };
          this.platModelService.addPlantModalTree(data).subscribe(response => {
            if (response.HasError === false) {
              this.showPopupMsgForGroup(response.ErrorMessage);
              this.isaddNewGroupItemModelVisible = false;
              this.isPlantGroupModelVisible = true;
              this.getTreeModel(this.plantgroup);
            } else {
              this.toaster.error('Error', response.ErrorMessage);
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your ' + this.replaceIcon(node.icon) + node.text + ' is safe :)', 'error');
        }
      });
    // } else {
    //   this.toaster.error('Alert!', 'you do not have permission to access');
    // }
  }
  // end group model methods
  getPlantModel(): void {
    this.spinner.show();
    // const userRoles = JSON.parse(localStorage.user).userRoles;
    // const role = userRoles.includes('Super Admin');
    const key = {
      EGId:  0, // : JSON.parse(localStorage.user).egId,
      CreatedBy: JSON.parse(localStorage.user).UserId
    };

    this.platModelService.getGroupList(key).subscribe((data: any) => {
      this.plantModelData = new MatTableDataSource<PlantModelElement>(data.groupList);
      this.getPaginator();
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  getPaginator(): void {
    setTimeout(() => {
      this.plantModelData.paginator = this.paginator;
      this.plantModelData.sort = this.sort;
    }, 1000);
  }
  
  applyFilter(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.plantModelData.filter = filterValue.trim().toLowerCase();
  }
  replaceIcon(value: any): any{
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }
  getTreeModel(element: any): void {
    const key = {
      EGId: element.EGId,
      isOptional: 1
    };
    this.platModelService.getTreeModal(key).subscribe((data: any) => {
      const plantGroupData: PlantGroupElement[] = data.TreeModalList;
      this.plantGroupSource.data = plantGroupData;
      this.treeControl.dataNodes = this.plantGroupSource.data;
      // this.expandAllNode();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  ExportTOExcel(name: any) {
    this.spinner.show();
    const data = { groupId: name.EGId };
    this.platModelService.getExcel(data).subscribe((res: any) => {
      this.ExportPG = res;
      if (this.ExportPG && this.ExportPG.length) {
        const workBook = XLSX.utils.book_new(); // create a new blank book
  
        this.ExportPG = this.ExportPG;
        const PGSheet = XLSX.utils.json_to_sheet([
          ...this.ExportPG,
          ...this.ExportPara,
        ]);
        this.spinner.hide();
        XLSX.utils.book_append_sheet(workBook, PGSheet, 'Plantmodel'); // add the worksheet to the book
        XLSX.writeFile(workBook, `Plant-Model.xlsx`); // initiate a file
      }
    });
  }
  onReadExcel(event: any) {
    this.excelData = event.addedFiles[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.excelData);
    fileReader.onload = (e) => {
      let arrayBuffer: any = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.excelData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.validateSheet();
    };
  }
  validateSheet() {
    try {
      let user: any = localStorage.getItem('user');
      user = JSON.parse(user);
      let plantMasterData: any = [];
      let PropertyData: any = { createdBy: user.UserId };

      if (this.excelData && this.excelData.length) {
        let index = 0;
        for (let data of this.excelData) {
          const group = this.plantModelData.data.find(
            (grp: any) =>
              grp.EGDesc == data['Group'] ||
              grp.EGDesc?.toLowerCase() == data['Group'].toLowerCase()
          );
          if (!group) {
            this.toaster.warning(
              `Group ${data['Group']} doesn't exist in database on line ${index + 1
              }`,
              'Warning'
            );
            this.excelData = [];
            return;
          }
          if (!data['Group']) {
            this.toaster.warning(
              `Please Enter Group on line ${index + 1}`,
              'Warning'
            );
            this.excelData = [];
            return;
          }
          if (!data['Plant']) {
            this.toaster.warning(
              `Please Enter Plant on line ${index + 1}`,
              'Warning'
            );
            this.excelData = [];
            return;
          }
          if (!data['Division'] && data['Department']) {
            this.toaster.warning(
              `Please Enter Division on line ${index + 1}`,
              'Warning'
            );
            this.excelData = [];
            return;
          }
          if (!data['Department'] && data['Line']) {
            this.toaster.warning(
              `Please Enter Department on line ${index + 1}`,
              'Warning'
            );
            this.excelData = [];
            return;
          }
          if (!data['Line'] && data['Unit']) {
            this.toaster.warning(
              `Please Enter Line on line ${index + 1}`,
              'Warning'
            );
            this.excelData = [];
            return;
          }
          if (!data['Unit'] && data['Assembly']) {
            this.toaster.warning(
              `Please Enter Unit on line ${index + 1}`,
              'Warning'
            );
            this.excelData = [];
            return;
          }
          if (!data['Assembly'] && data['SubAssembly']) {
            this.toaster.warning(
              `Please Enter Assembly on line ${index + 1}`,
              'Warning'
            );
            this.excelData = [];
            return;
          }
          if (!data['SubAssembly'] && data['Component']) {
            this.toaster.warning(
              `Please Enter SubAssembly on line ${index + 1}`,
              'Warning'
            );
            this.excelData = [];
            return;
          } else {
            plantMasterData.push(
              Object.assign(this.excelData[index], PropertyData)
            );
          }
          index++;
        }
        const masterData = { plantMasterDatas: plantMasterData };
        this.uploadMasterDataPlantModel(masterData);
      } else this.toaster.warning('No Data Found', 'Warning');
    } catch (err: any) {
      this.toaster.error(err, 'Error');
    }
  }
  uploadMasterDataPlantModel(masterData: any) {
    this.spinner.show();
    this.platModelService.uploadMasterDataPlantModel(masterData).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status == 'Plant not available')
          this.toaster.warning(res.status, 'warning');
        else this.toaster.success(res.status, 'Sucess');
      },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error(err.message, 'Error');
      }
    );
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any{
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([url]));
    // return false;
  }
}
