import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
})
export class ImageDialogComponent implements OnInit {
  imagePath: any;
  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.imagePath = this.data;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  onClickDownload(e) {
    var name = e.files[0].name;
    var file = e.files[0];
    var blob = new Blob([file]);
    var url = URL.createObjectURL(blob);
    this.download(name, url);
  }
  download(filename, url) {
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    document.body.appendChild(element);
    element.click();
    //remove the hidden link 
    //document.body.removeChild(element);
  }
}
