import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActionDbService {
  baseUrl: string = environment.O3CoreApiUrl;
  NetApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }
  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Common/GetDepartments', data);
  }
  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Common/GetGroup', data);
  }
  getActionByStatus(key: any): Observable<any[]> {
    // return this.http.post<any[]>(this.apiUrl + 'getMeetingActionByStatus', key);
    return this.http.post<any[]>(this.NetApiUrl + 'ScoreCardMeetingMatrix/GetMeetingActionByStatus', key);
  }
  getActionDashboard(key: any): Observable<any[]> {
    // return this.http.post<any[]>(this.apiUrl + 'getMeetingActionForDashBoard', key);
    return this.http.post<any[]>(this.NetApiUrl + 'ScoreCardMeetingMatrix/GetMeetingActionForDashBoard', key);
  }
}
