import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  kpiBaseUrl: string = environment.O3CoreApiUrl;
  baseUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Common/GetDepartments', data);
  }
  addProject(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpiBaseUrl + 'addproject', data);
  }
  getMasterProject(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpiBaseUrl + 'masterproject', data);
  }
  deleteProject(data: any): Observable<any> {
    return this.http.post<any>(this.kpiBaseUrl + 'deleteproject', data);
  }
  GetOneMasterProject(data: any): Observable<any> {
    return this.http.post<any>(this.kpiBaseUrl + 'project', data);
  }
  updateProject(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.kpiBaseUrl + 'project', data);
  }
}
