import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { UserManu } from 'src/app/models/userManu';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
@Component({
  selector: 'app-pillars',
  templateUrl: './pillars.component.html',
  styleUrls: ['./pillars.component.scss'],
})
export class PillarsComponent implements OnInit {
  @Input() pPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<Pillar>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  pillars!: Pillar[];
  pillarId!: number;
  plants!: Plantbygroup[];
  pillarForm!: FormGroup;
  formMode!: boolean;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  plantId!: number;
  breadcrumList: any;
  pillarModelVisible = false;
  moduleId!: any;
  pageId!:any;
  pillarColumns: string[] = ['pillarActions' , 'plant', 'name', ];
  pillarData!: MatTableDataSource<Pillar>;
  IsAccess: any;
  IsWrite: any;
  languageDir = 'ltr';
  langObj: any = {};
  
  constructor(
    private fb: FormBuilder,
    private kpiService: KpimasterService,
    private platModelService: EnterPriseGroupService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private translater: TranslaterService,
    private usermanagementService: UserManagementService,
  ) {
  }
  ngOnInit(): void {
    this.GetMenuAccess();
    this.GetBreadcrumList();
    //this.getLangWords();
    this.isRead = this.pPermissions.IsAccess;
    this.isWrite = this.pPermissions.IsWrite;
    this.isUpdate = this.pPermissions.IsWrite;
    this.isDelete = this.pPermissions.IsWrite;
    this.formMode = true;
    this.pillarForm = this.fb.group({
      pillarname: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      plant: ['', Validators.required],
    });
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }
  
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }



  getPaginator(): void {
    setTimeout(() => {
      this.pillarData.paginator = this.paginator;
      this.pillarData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.pillarData.filter = filterValue.trim().toLowerCase();

    if (this.pillarData.paginator) {
      this.pillarData.paginator.firstPage();
    }
  }
  getPlants(): void {
    this.kpiService.getAllPlants().subscribe(
      (plants) => {
        this.plants = plants;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getPillarsMaster(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.kpiService.getPillarMasterData(key).subscribe(
      (pillars) => {
        this.pillarData = new MatTableDataSource<Pillar>(pillars);
        this.getPlants();
        this.getPaginator();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      }
    );
  }
  getPillars(): void {
    this.spinner.show();

    const key = {
      plantId: this.plantId,
    };
    this.kpiService.getPillarMasterData(key).subscribe(
      (pillars) => {
        this.spinner.hide();

        this.pillarData = new MatTableDataSource<Pillar>(pillars);
        this.getPaginator();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error(error.error.message);
      }
    );
  }

  openModal(): void {
    if (this.isWrite) {
      this.resetForm();
      this.pillarForm.get('plant')?.setValue(this.plantId);
      this.formMode = true;
      this.pillarModelVisible = true;
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  getOnePillar(pillarId: number): void {
    if (this.isUpdate) {
      const key = {
        pillarId,
      };
      this.kpiService.getOnePillar(key).subscribe(
        (pillar) => {
          this.pillarId = pillarId;
          this.pillarForm.get('pillarname')?.setValue(pillar.name);
          this.plants = [];
          this.getPlants();
          this.pillarForm.get('plant')?.setValue(this.plantId);
          this.formMode = false;
          this.pillarModelVisible = true;
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  submitForm(): void {
    this.spinner.show();

    const pillar = {
      plantId: this.pillarForm.value.plant,
      name: this.pillarForm.value.pillarname,
    };
    this.kpiService.addPillar(pillar).subscribe(
      (response) => {
        this.spinner.hide();
        this.toaster.success('Success', 'Pillar Added Successfully');
        this.getPillars();
        this.closePillarModel();
        this.resetForm();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  updateForm(): void {
    this.spinner.show();
    const pillar = {
      pillarId: this.pillarId,
      plantId: this.pillarForm.value.plant,
      name: this.pillarForm.value.pillarname,
    };
    this.kpiService.updatePillar(pillar).subscribe(
      (response) => {
    this.spinner.hide();

        this.toaster.success('Success', 'Pillar Updated Successfully');
        this.getPillars();
        this.closePillarModel();
        this.resetForm();
      },
      (error) => {
    this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }

  deletePillar(pillarId: number): void {

    if (this.isDelete) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete pillar!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          const key = {
            pillarId,
          };
          this.kpiService.deletPillar(key).subscribe(
            (success) => {

              Swal.fire({
                title: 'Deleted!',
                text: 'Your selected Pillar has been deleted.',
                icon: 'success',
                timer: 800,
                showConfirmButton: false,
              });
          this.spinner.hide();

              this.getPillars();
            },
            (error) => {
          this.spinner.hide();

              this.toaster.error('Error', error.error.message);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your selected pillar is safe :)', 'error');
        }
      });
    } else {
      this.spinner.hide();

      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  resetForm(): void {
    this.pillarForm.reset();
  }
  closePillarModel(): void {
    this.pillarModelVisible = false;
  }
}
