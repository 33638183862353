<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">Reporting</h1>
    </div>
    <form [formGroup]="ReportingForm">
    <div class="content-top-row" style="display: flex; justify-content: flex-end;">
      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label >Select Group</mat-label>
        <mat-select panelClass="testClass" name="group" formControlName="group"
        (selectionChange)="getPlantByGroup()">
        <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
      </mat-select>
        <mat-error >Please select group</mat-error>
      </mat-form-field>
      <mat-form-field  appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label >Select Plant</mat-label>
        <mat-select panelClass="testClass" name="plant" formControlName="plant">
          <mat-option *ngFor="let p of plants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
        </mat-select>
        <mat-error >Please select plant</mat-error>
      </mat-form-field>
    </div>
  </form>
  </div>

      <div class="wrapper-box wrapper-box-shadow grid-container ">
        <ag-grid-angular
        style="width: 100%; height: 25vw"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        >
      </ag-grid-angular>  
      </div>

</div>
