import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MachineParamtersService {
  baseUrl: string = environment.O3CoreApiUrl;
  baseUrl2: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) {}

  //body => { VG_Id:0
  //            Pu_id:5 }

  gettVariableGroupList(data: any): Observable<any[]> {
    //HistoryVarGroup,EditVarGroup,DeleteVarGroup
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'Variable/GetVariableGroupList',
      data
    );
  }

  sumbitGroup(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Variable/SumbitGroup', data);
  }

  //body => { VG_Id:0
  //          VA_Id:5 }
  getVariableList(data: any): Observable<any[]> {
    //LoadEditVariable,LoadVariable
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'Variable/GetVariableList',
      data
    );
  }
  sumbitVariables(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'Variable/SumbitVaraibles',
      data
    );
  }
  //body => { VG_Id:0
  //            VA_Id:5
  //            Pu_id:5 }
  getParameterSpecificationsList(data: any): Observable<any[]> {
    //LoadEditParameterSpecifications,LoadParameterSpecifications
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'Variable/GetParameterSpecificationsList',
      data
    );
  }

  sumbitParameterSpecifications(data: any): Observable<any[]> {
    //SaveParameterSpecifications
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'Variable/SumbitParameterSpecifications',
      data
    );
  }
  deletevariableparameterspec(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'QualityParameter/DeleteVariableParameterSpec', data);
  }
  //body => { Id:0 }
  getDataType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Variable/GetDataType', data);
  }

  //body => { Id:0 }
  getDatasource(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Variable/GetDatasource', data);
  }

  //body => { Id:0 }
  getCalculationMethod(data: any): Observable<any[]> {
    //loadCalculationMethod
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'Variable/GetCalculationMethod',
      data
    );
  }

  //body => { Id:0 }
  getInputTag(data: any): Observable<any[]> {
    //loadInputTag
    return this.http.post<any[]>(
      this.baseUrl + 'Variable/GetCalculationMethod',
      data
    );
  }

  //body => { Id:0 }
  parameterList(data: any): Observable<any[]> {
    //loadParameterSpecification_Desc
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'Variable/ParameterSpecification_Desc',
      data
    );
  }

  //body => { UserId:0 } and response is
  DesktopFunctionsList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Variable/GetInputTag', data);
  }

  //body => { Id:5 }
  parameterLinkProduct(data: any): Observable<any[]> {
    //loadParameterSpecification_LinkProduct
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'Variable/ParameterSpecification_LinkProduct',
      data
    );
  }
  getparameterLinkProduct(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'QualityParam/getparameterlinkproduct', data);
  }
  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetGroup', data);
  }

  // data type routes

  async GetAllDataTypesDetail(data: any): Promise<any[]> {
    return await this.http
      .post<any[]>(this.o3CoreApiUrl + 'VariableParameterDataTypeDetail/GetAllDataTypeDetails', data)
      .toPromise();
  }

  AddDataType(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'VariableParameterDataTypeDetail/AddDataType',
      data
    );
  }

  UpdateDataType(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'VariableParameterDataTypeDetail/UpdateDataType',
      data
    );
  }

  DeleteDataType(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreApiUrl + 'VariableParameterDataTypeDetail/DeleteDataType',
      data
    );
  }
  GetGroupType(): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + 'Variable/GetGroupType');
  }
}
