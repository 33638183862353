import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AreaComponent } from "src/app/AuditMasterComponents/area/area.component";
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { AuditCategoriesComponent } from 'src/app/AuditMasterComponents/audit-categories/audit-categories.component';
import { ProcessAuditCategoriesComponent } from 'src/app/AuditMasterComponents/process-audit-categories/process-audit-categories.component';
import { CheckpointComponent } from 'src/app/AuditMasterComponents/checkpoint/checkpoint.component';
import { ProcessCheckpointComponent } from 'src/app/AuditMasterComponents/process-checkpoint/process-checkpoint.component';
import { ScoreTableComponent } from 'src/app/AuditMasterComponents/score-table/score-table.component';
import { GloabalStandardComponent } from 'src/app/AuditMasterComponents/gloabal-standard/gloabal-standard.component';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { Router } from '@angular/router';
import { SetAuditCategoriesComponent } from 'src/app/AuditMasterComponents/set-audit-categories/set-audit-categories.component';

@Component({
  selector: 'app-audit-master-data',
  templateUrl: './audit-master-data.component.html',
  styleUrls: ['./audit-master-data.component.scss']
})

export class AuditMasterDataComponent implements OnInit {

  @ViewChild(AreaComponent) areaComponent!: AreaComponent;
  @ViewChild(AuditCategoriesComponent) auditCategoriesComponent!: AuditCategoriesComponent;
  @ViewChild(CheckpointComponent) checkpointComponent!: CheckpointComponent;
  @ViewChild(ScoreTableComponent) scoreTableComponent!: ScoreTableComponent;
  @ViewChild(GloabalStandardComponent) gloabalStandardComponent!: GloabalStandardComponent;
  @ViewChild(ProcessAuditCategoriesComponent) processAuditCategoriesComponent!: ProcessAuditCategoriesComponent;
  @ViewChild(ProcessCheckpointComponent) processCheckPointComponent!: ProcessCheckpointComponent;
  @ViewChild(SetAuditCategoriesComponent) SetAuditCategoriesComponent!: SetAuditCategoriesComponent;

  searchAuditMasterForm!: FormGroup;
  moduleId!:any;
  pageId!:any;
  plants!: any;
  groupListDDL!: any[];
  masterData: any = {};
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  categoryLength: number;
  constructor(
    private kpiMaster: KpimasterService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private userService: UserManagementService,
    private machineService: MachineMappingService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.searchAuditMasterForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.getGroupData();
  }

  getGroupData(): void {
    const key = {
      Id: 0
    };
    this.userService.getGroupData(key).subscribe((data: any) => {
      this.groupListDDL = data.masterList;
      this.searchAuditMasterForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
      this.getPlantByGroup();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }


  getMasterData(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const group = this.searchAuditMasterForm.get('group')?.value;
    const key = {
      plantId: plant,
      groupId: group
    };
    this.SetAuditCategoriesComponent.getAuditCategories(key);
    this.auditCategoriesComponent.getAuditMaster(key);
    this.checkpointComponent.getCheckpointMaster(key);
    this.scoreTableComponent.getScoreMaster(key);
    this.gloabalStandardComponent.getGlobalStandardMaster(key);
    this.areaComponent.getAreaMaster(key);
    this.processAuditCategoriesComponent.getAuditMaster(key);
    this.processCheckPointComponent.getCheckpointMaster(key);
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.searchAuditMasterForm.get('group')?.value
    };
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.plants = data.DepartList;
      this.searchAuditMasterForm.get('plant')?.setValue(this.plants[0].DeptId);
      this.getMasterData();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.userService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      this.moduleId = this.breadcrumList[0].id;
      this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  getCategoryLength(length: number){
    this.categoryLength = length;
  }
}
