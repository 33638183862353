import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.scss']
})
export class ProductionComponent implements OnInit {

  productionData: any = [];
  productionOEEChart: Chart[] = [];
  equipmentEfficency!: Chart;
  outputPcs!: Chart;
  pallets!: Chart;
  ytd!: Chart;
  downtime!: Chart;
  downtimeOccurences!: Chart;
  analysisHeaderForm = this.formBuilder.group({
    shift: ['', Validators.required],
    week: ['', Validators.required],
    month: ['', Validators.required],
    quarter: ['', Validators.required],
    line: ['', Validators.required],
    area: [new Date(), Validators.required]
  });
  IsAccess: boolean = false;
  IsWrite: boolean = false;
  breadcrumList: any = [];

  constructor(private formBuilder: FormBuilder, private toaster: ToastrService, private usermanagementService: UserManagementService,
    public router: Router) { }

  ngOnInit(): void {
    this.drawChart();
    this.drawOee();
    this.drawChartOutput();
    this.drawChartPallets();
  }
  drawOee() {
    this.productionData = [
      {
        title: 'OEE', categories: ['01 Sep 21', '02 Sep 21', '03 Sep 21', '04 Sep 21', '05 Sep 21', '06 Sep 21', '07 Sep 21'],
        data: [
          { y: 70, color: '#dc3545' }, { y: 65, color: '#dc3545' }, { y: 67, color: '#dc3545' }, { y: 65, color: '#dc3545' },
          { y: 69, name: '%', color: '#dc3545' }, { y: 60, color: '#dc3545' }, { y: 0, color: '#dc3545' }]
      }];
    if (this.productionData && this.productionData.length) {
      for (const productionOEE of this.productionData) {
        const chart = new Chart({
          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 45
            }
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: productionOEE.categories,
            crosshair: true
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              borderWidth: 0,
              dataLabels: {
                enabled: true,
              },
              point: {
                events: {
                  click: this.getYTDHeaderBasedValues.bind(this)
                }
              }
            }
          },
          series: [
            {
              name: productionOEE.title,
              type: 'column',
              data: productionOEE.data
            }
          ]
        });
        this.productionOEEChart.push(chart);
      }
    }
  }

  drawChart() {

    const deptColumns = ['']
    this.equipmentEfficency = new Chart({
      series: [
        {
          name: 'OEE',
          type: 'column',
          data: [53],
          color: "#258cf6"
        },
        {
          name: 'Availability',
          type: 'column',
          data: [78.70],
          "color": "#0f0fd1"
        },
        {
          name: 'Performance',
          type: 'column',
          data: [70],
          "color": "#f8636f"
        },
        {
          name: 'Quality',
          type: 'column',
          data: [96.10],
          "color": "#8c0697 "
        }
      ],
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: deptColumns,
        crosshair: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getYTDHeaderBasedValues.bind(this)
            }
          }
        }
      }
    });
  }

  drawChartOutput() {

    const deptColumns = ['01 Sep 21', '02 Sep 21', '03 Sep 21', '04 Sep 21', '05 Sep 21', '06 Sep 21', '07 Sep 21']
    this.outputPcs = new Chart({
      series: [
        {
          name: 'Machine Output',
          type: 'column',
          data: [595000, 549000, 574000, 53000, 475000, 433000],
          color: "#0f0af2"
        },
        {
          name: 'Actual Output',
          type: 'column',
          data: [595000, 549000, 574000, 53000, 475000, 433000],
          "color": "#f9ed10"
        }
      ],
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: deptColumns,
        crosshair: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getYTDHeaderBasedValues.bind(this)
            }
          }
        }
      }
    });
  }

  drawChartPallets() {

    const deptColumns = ['01 Sepetember', '02 Sepetember', '03 Sepetember', '04 Sepetember', '05 Sepetember', '06 Sepetember', '07 Sepetember']
    this.pallets = new Chart({
      series: [
        {
          name: 'Total Machine',
          type: 'column',
          data: [413, 371, 397, 332, 245, 331],
          color: "#0f0af2"
        },
        {
          name: 'Total Good Pallets/Boxes',
          type: 'column',
          data: [413, 371, 397, 332, 245, 331],
          "color": "#19ef0a"
        }
      ],
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: deptColumns,
        crosshair: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getYTDHeaderBasedValues.bind(this)
            }
          }
        }
      }
    });
    this.downtime = new Chart({

      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
      },
      xAxis: {
        categories: ['Operational', 'TSD', 'Mechanical', 'Electrical', 'Tool Team', 'Changeover ', 'Utility', 'No raw matter', 'Preventive Maintenance', 'Carriage manufacturing'],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Duration (Min)',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        valueSuffix: ' millions'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'bar',
        data: [{ color: '#dc3545', y: 8400 }, { color: '#19ef0a', y: 1600 }, { color: '#19ef0a', y: 1100 }, { color: '#19ef0a', y: 800 }, { color: '#19ef0a', y: 300 }, { color: '#19ef0a', y: 200 }, { color: '#19ef0a', y: 100 }, { color: '#19ef0a', y: 0 }]
      }]
    });
  }

  getYTDHeaderBasedValues() { }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }
}
