import { Component, ViewChild, Input, OnInit } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexChart,
  ApexPlotOptions,
  ApexLegend,
  ChartComponent
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  colors: string[];
  // to
};

@Component({
  selector: 'app-o3-area-chart',
  templateUrl: './o3-area-chart.component.html',
  styleUrls: ['./o3-area-chart.component.scss']
})
export class O3AreaChartComponent  {
  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions: any;
  constructor() {
    this.chartOptions = {
      series: [
        {
          data: [
            {
              x: "Area A",
              y: 120
            },
            {
              x: "Area B",
              y: 120
            },
            {
              x: "Area C",
              y: 70
            },
            {
              x: "Area D",
              y: 100
            },
            {
              x: "Area E",
              y: 80
            },
            {
              x: "Area F",
              y: 100
            },
            {
              x: "Area G",
              y: 70
            },
            {
              x: "Area H",
              y: 30
            },
            {
              x: "Area I",
              y: 44
            }
          ]
        }
      ],
      legend: {
        show: false
      },
      // dataLabels
      // : {
      //   show: false
      // },
      chart: {
        height: 450,
        type: "treemap",
        toolbar: {
          show: false,
        },
        fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
        foreColor: '#000000',
        animations: {
          enabled: false,
        }
      },
      title: {
        text: "Distibuted Treemap (different color for each cell)",
        align: "center"
      },
      colors: [
        "#F85439",
        "#10CE9C",
        "#3AAFE4",
        "#040496",
              ],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false
        }
      },
    };
  }

  public generateData(count: any, yrange: any) {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = "w" + (i + 1).toString();
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({
        x: x,
        y: y
      });
      i++;
    }
    return series;
  }

}