import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Mastershift } from 'src/app/models/mastershift';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import { UserManu } from 'src/app/models/userManu';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import Swal from 'sweetalert2';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-mastershift',
  templateUrl: './mastershift.component.html',
  styleUrls: ['./mastershift.component.scss'],
})
export class MastershiftComponent implements OnInit {
  @Input() shiftPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<Mastershift>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  plants!: Plantbygroup[];
  masterShiftForm!: FormGroup;
  formMode!: boolean;
  plantId!: number;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  shiftId!: number;
  date8!: Date;
  startTime: Date | null = null;
  endTime: Date | null = null;

  shiftModelVisible = false;

  shiftColumns: string[] = [
    'shiftActions',
    'plant',
    'startTime',
    'endTime',
    'shiftName',
    'shiftPattern',
  ];
  shiftData!: MatTableDataSource<Mastershift>;
  IsWrite: any;
  IsAccess: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  breadcrumList: any;
  constructor(
    private kpiService: KpimasterService,
    private toaster: ToastrService,
    private platModelService: EnterPriseGroupService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private translater: TranslaterService,
    private usermanagementService: UserManagementService
  ) {
  }

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();

    //this.getLangWords();
    const startTime = new Date();
    const endTime = new Date();
    this.isRead = this.shiftPermissions.IsAccess;
    this.isWrite = this.shiftPermissions.IsWrite;
    this.isUpdate = this.shiftPermissions.IsWrite;
    this.isDelete = this.shiftPermissions.IsWrite;
    this.masterShiftForm = this.fb.group({
      shiftPattern: ['', Validators.required],
      plant: ['', Validators.required],
      shiftName: ['', Validators.required],
      startTime: [''],
      endTime: [''],
    });
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1,
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getMasterShift(): void {
    this.spinner.show();

    const key = {
      plantId: this.plantId,
    };
    this.kpiService.getMasterShifts(key).subscribe(
      (data: any) => {
        if (data.length) {
          data.forEach((shift: any) => {
            shift.startTime = new Date(`1970-12-11T${shift.startTime}Z`);
            shift.endTime = new Date(`1970-12-11T${shift.endTime}Z`);
          });
        }
        this.shiftData = new MatTableDataSource<Mastershift>(data);
        this.getPaginator();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getPaginator(): void {
    setTimeout(() => {
      this.shiftData.paginator = this.paginator;
      this.shiftData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.shiftData.filter = filterValue.trim().toLowerCase();

    if (this.shiftData.paginator) {
      this.shiftData.paginator.firstPage();
    }
  }

  openModal(): void {
    if (this.isWrite) {
      this.resetForm();
      this.masterShiftForm.get('plant')?.setValue(this.plantId);
      this.shiftModelVisible = true;
      this.formMode = true;
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  GetMasterShiftData(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.kpiService.getMasterShifts(key).subscribe(
      (data) => {
        if (data.length) {
          data.forEach((shift: any) => {
            shift.startTime = new Date(`1970-12-11T${shift.startTime}Z`);
            shift.endTime = new Date(`1970-12-11T${shift.endTime}Z`);
          });
        }
        this.shiftData = new MatTableDataSource<Mastershift>(data);
        this.getPlants();
        this.getPaginator();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  getPlants(): void {
    this.kpiService.getAllPlants().subscribe(
      (plants) => {
        this.plants = plants;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  updateForm(): void {
    this.spinner.show();
    let _startTime = new Date(
      `01/01/1970 ${this.masterShiftForm.value.startTime}`
    );
    let _endTime = new Date(`01/01/1970 ${this.masterShiftForm.value.endTime}`);
    let startTime = [
      this.padTo2Digits(_startTime.getUTCHours()),
      this.padTo2Digits(_startTime.getUTCMinutes()),
      this.padTo2Digits(_startTime.getUTCSeconds()),
    ].join(':');
    let endTime = [
      this.padTo2Digits(_endTime.getUTCHours()),
      this.padTo2Digits(_endTime.getUTCMinutes()),
      this.padTo2Digits(_endTime.getUTCSeconds()),
    ].join(':');

    const masterShift = {
      shiftId: this.shiftId,
      plantId: this.masterShiftForm.value.plant,
      shiftPattern: this.masterShiftForm.value.shiftPattern,
      name: this.masterShiftForm.value.shiftName,
      startTime: startTime, // .toLocaleTimeString(),
      endTime: endTime, // .toLocaleTimeString(),
    };

    this.kpiService.updatemastershift(masterShift).subscribe(
      (response) => {
        this.spinner.hide();

        this.toaster.success('Success', 'Master Shift Updated Successfully');
        this.getMasterShift();
        this.closeShiftModel();
        this.resetForm();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }

  submitForm(): void {
    this.spinner.show();
    let _startTime = new Date(
      `01/01/1970 ${this.masterShiftForm.value.startTime}`
    );
    let _endTime = new Date(`01/01/1970 ${this.masterShiftForm.value.endTime}`);
    let startTime = [
      this.padTo2Digits(_startTime.getUTCHours()),
      this.padTo2Digits(_startTime.getUTCMinutes()),
      this.padTo2Digits(_startTime.getUTCSeconds()),
    ].join(':');
    let endTime = [
      this.padTo2Digits(_endTime.getUTCHours()),
      this.padTo2Digits(_endTime.getUTCMinutes()),
      this.padTo2Digits(_endTime.getUTCSeconds()),
    ].join(':');
    const data = {
      plantId: this.masterShiftForm.value.plant,
      shiftPattern: this.masterShiftForm.value.shiftPattern,
      name: this.masterShiftForm.value.shiftName,
      startTime: startTime, // .toLocaleTimeString(),
      endTime: endTime, // .toLocaleTimeString(),
    };
    this.kpiService.addMasterShift(data).subscribe(
      (response) => {
        this.spinner.hide();

        this.toaster.success('Success', 'Master Shift Added Successfully');
        this.getMasterShift();
        this.closeShiftModel();
        this.resetForm();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }

  deleteMasterShift(shiftId: number): void {
    if (this.isDelete) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete Master Shift!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          const key = {
            shiftId,
          };

          this.kpiService.deleteMasterShift(key).subscribe(
            (success) => {
              Swal.fire({
                title: 'Deleted!',
                text: 'Your selected Master Shift has been deleted.',
                icon: 'success',
                timer: 800,
                showConfirmButton: false,
              });
              this.spinner.hide();

              this.getMasterShift();
            },
            (error) => {
              this.spinner.hide();

              this.toaster.error('Error', error.error.message);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your Master Shift is safe :)', 'error');
        }
      });
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  resetForm(): void {
    this.masterShiftForm.reset();
  }

  getOneMasterShift(shiftId: number): void {
    if (this.isUpdate) {
      const key = {
        shiftId,
      };
      this.shiftId = shiftId;
      this.kpiService.getOneMasterShift(key).subscribe(
        (data) => {
          this.shiftId = shiftId;
          this.plants = [];
          this.getPlants();
          this.masterShiftForm.get('plant')?.setValue(this.plantId);
          this.masterShiftForm.get('shiftId')?.setValue(data.shiftId);
          this.masterShiftForm.get('shiftPattern')?.setValue(data.shiftPattern);
          this.masterShiftForm.get('shiftName')?.setValue(data.name);
          if (data.endTime !== undefined) {
            data.endTime = new Date(`1970-12-11T${data.endTime}Z`);
            data.endTime = `${data.endTime.getHours()}:${data.endTime.getMinutes()}`;

            this.masterShiftForm.get('endTime')?.setValue(data.endTime);
          }
          if (data.startTime !== undefined) {
            data.startTime = new Date(`1970-12-11T${data.startTime}Z`);
            data.startTime = `${data.startTime.getHours()}:${data.startTime.getMinutes()}`;

            this.masterShiftForm.get('startTime')?.setValue(data.startTime);
          }
          this.formMode = false;
          this.shiftModelVisible = true;
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  closeShiftModel(): void {
    this.shiftModelVisible = false;
  }
}
