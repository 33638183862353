import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PmRoutingModule } from './pm-route.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Material Imports
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTreeModule } from '@angular/material/tree';
import { MaterialModule } from 'src/app/material-module';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatTabsModule } from '@angular/material/tabs';
//Ngx Imports
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxSpinnerModule } from 'ngx-spinner';

//Components
import { ProductdetailComponent } from 'src/app/screens/productdetail/productdetail.component';
import { OperatorExecutionComponent } from './operator-execution/operator-execution.component';
import { TechnicianExecutionComponent } from './technician-execution/technician-execution.component';
import { ManagerExecutionComponent } from './manager-execution/manager-execution.component';
import { TechnicianExecutionListComponent } from './technician-execution-list/technician-execution-list.component';
import { ManagerExecutionFormComponent } from './manager-execution-form/manager-execution-form.component';
import { OperatorExecutionFormComponent } from './operator-execution-form/operator-execution-form.component';
import { ReactiveMaintenanceConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/reactive-maintenance-configuration/reactive-maintenance-configuration.component';
import { PreventiveMaintenanceModule } from './preventive-maintenance/preventive-maintenance.module';
import { SkillMatrixModule } from './skill-matrix/skill-matrix.module';
import { RmDashboardComponent } from './rm-dashboard/rm-dashboard.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMatSelectModule } from '../ngx-mat-select/ngx-mat-select.module';
// import { UnitPickerComponent } from '../components/unit-picker/unit-picker.component';

import { AgGridModule } from 'ag-grid-angular';
import { MatSelectFilterModule } from 'mat-select-filter';
import { HomeModule } from '../screens/home.module';
import { SafetyModule } from '../safety/safety.module';

import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';

import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as drilldown from 'highcharts/modules/drilldown.src';
// Import ng-circle-progress
// import { NgCircleProgressModule } from 'ng-circle-progress';
import { TechnisionHistoryComponent } from './skill-matrix/technision-history/technision-history.component';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  key => antDesignIcons[key]
);
import { EditBtnRenderer } from '../edit-action-btn-renderer.component';
import { DeleteBtnRenderer } from '../delete-action-btn-renderer.component';
import { RmDashboardCloneComponent } from './rm-dashboard-clone/rm-dashboard-clone.component';
import { TranslateModule } from '@ngx-translate/core';
import { PmDashboardComponent } from './pm-dashboard/pm-dashboard.component';
import { KpiMastersDashboardComponent } from './kpi-masters-dashboard/kpi-masters-dashboard.component';

import { MatCarouselModule } from 'ng-mat-carousel';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true,
    }),
    PmRoutingModule,
    FormsModule,
    ReactiveFormsModule,

    //Material Imports
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MaterialModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatTimepickerModule,
    MatTabsModule,
    //Ngx Imports
    NgxSpinnerModule,
    NgxMaterialTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    PreventiveMaintenanceModule,
    SkillMatrixModule,
    AgGridModule.withComponents([EditBtnRenderer, DeleteBtnRenderer]),
    MatSelectFilterModule,
    ChartModule,
    NgxDropzoneModule,
    HomeModule,
    NzDatePickerModule,
    SafetyModule,
    NgxMatSelectModule,
    MatCarouselModule.forRoot(),
    // NgCircleProgressModule.forRoot({
    // })
  ],
  declarations: [
    ProductdetailComponent,
    OperatorExecutionComponent,
    OperatorExecutionFormComponent,
    TechnicianExecutionComponent,
    ManagerExecutionComponent,
    TechnicianExecutionListComponent,
    ManagerExecutionFormComponent,
    ReactiveMaintenanceConfigurationComponent,
    RmDashboardComponent,
    EditBtnRenderer,
    DeleteBtnRenderer,
    TechnisionHistoryComponent,
    RmDashboardCloneComponent,
    PmDashboardComponent,
    KpiMastersDashboardComponent,
    // UnitPickerComponent,
    // PreventiveMaintenanceModule,
    // DropdownModule
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, drilldown] },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons }, // add as factory to your providers
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmModule { }
