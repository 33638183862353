import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { UserManu } from 'src/app/models/userManu';
import { ToastrService } from 'ngx-toastr';
import { ProcessParameterLoginService } from 'src/app/services/Process-Parameter-Login.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { CheckboxRenderer } from 'src/app/CheckboxRenderer';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-process-parameter-login',
  templateUrl: './process-parameter-login.component.html',
  styleUrls: ['./process-parameter-login.component.scss'],
})
export class ProcessParameterLoginComponent implements OnInit {
  loading = false;
  columnDefs: any;
  rowData: any;
  EmptyGrid: any;
  moduleId!: any;
  pageId!:any;

  rowSelection: any;
  isPlantGroupModelVisible: boolean = false;
  varUnitName!: string;
  processDataForm!: FormGroup;
  components!: any;
  actionPermission!: UserManu;
  gridApi: any;
  gridColumnApi: any;

  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  LineNode: any;
  UnitNode: any;
  UnitName!: string;
  unitid!: any;
  params!: any;

  check!: any;
  paginationPageSize!: any;

  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
      checkboxRenderer: CheckboxRenderer,
    },
  };
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;
  
  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private userService: UserManagementService,
    private processParameterLoginService: ProcessParameterLoginService,
    private enterpriseGroupService: EnterPriseGroupService,
    private spinner: NgxSpinnerService,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    public router: Router
  ) {
  }
  parameterGroupList!: any[];
  groupTypeList!: any[];
  VariableGroupList!: any[];
  GroupList!: any[];

  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;
  ngOnInit(): void {
    this.columnDefs = [
      {
        field: 'isSelected',
        minWidth: 200,
        headerName: '',
        cellRenderer: 'checkboxRenderer',
        
      },
      {
        field: 'isDeleted',
        hide: 'true',
        sortable: true,
        editable: false,
        headerName: '',
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        field: 'plId',
        hide: 'true',
        sortable: true,
        editable: false,
        headerName: '',
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        // tslint:disable-next-line: max-line-length
        field: 'varDesc',
        sortable: true,
        editable: false,
        headerName: 'Description',
        filter: true,
        resizable: true,
        wrapText: true,
        minWidth: 400,
      },

      {
        field: 'autoManual',
        sortable: true,
        headerName: 'Auto/Manual',
        cellStyle: {
          color: 'black',
          'background-color': '#bdccdd',
        },
        filter: true,
        resizable: true,
        wrapText: true,
        outerHeight: 600,
        editable: (params: any) =>
          params.data.isSelected !== false && this.IsWrite,
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Auto', 'Manual'],
        },
        minWidth: 400,
      },
      {
        // tslint:disable-next-line: max-line-length
        field: 'interval',
        sortable: true,
        cellStyle: {
          color: 'black',
          'background-color': '#bdccdd',
        
        },
        editable: (params: any) =>
          params.data.isSelected !== false && this.IsWrite,
        headerName: 'Interval (Mins)',
        filter: true,
        resizable: true,
        wrapText: true,
        minWidth: 200,
      },
      {
        field: 'processType',
        sortable: true,
        hide: 'true',
      },
    ];
    this.rowSelection = 'multiple';
    this.processDataForm = this.fb.group({
      group: ['', Validators.required],
    });
    this.processDataForm = this.fb.group({
      UnitNameList: [''],
      parametergroup: ['', Validators.required],
      Group: ['', Validators.required],
      processType: [''],
      variableGroupType: [''],
      parameterGroupType:['']
    });

    this.getGroups();
    this.GetMenuAccess();
    this.GetBreadcrumList();
  }
  
  getGroups(): void {
    const key = {
      Id: 0,
    };
    const id = {
      value: 0,
    };
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.masterList;
        this.processDataForm.get('Group')?.setValue(data.masterList[0].TypeId);
      },
      (error: { message: any }) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }

  getUnits(): void {
    this.ResetData();
    this.rowData = this.EmptyGrid;
  }

  getParameterGroups(): void {
    const Key = {
      puId: this.unitid,
    };
    this.rowData = this.EmptyGrid;
    this.parameterGroupList = this.EmptyGrid;
    
    this.processParameterLoginService.GetGroupType().subscribe(
      (res: any[]) => {
        this.groupTypeList = res;
      },
      (error: { message: any }) => {
        this.toastrService.error('Error', error.message);
      }

    );
  }


  getVariableGroups(): void {
    const Key = {
      groupTypeId: this.processDataForm.value.variableGroupType,
      UnitId : this.unitid,
    };
    this.rowData = this.EmptyGrid;
    this.parameterGroupList = this.EmptyGrid;
   
    this.processParameterLoginService.GetVariableGroups(Key).subscribe(
      (res: any[]) => {
        this.VariableGroupList = res;
      },
      (error: { message: any }) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }

  GetParameterGroups(): void {
    const Key = {
      variableGroupType: this.processDataForm.value.variableGroupType,
      parameterGroupType:this.processDataForm.value.parameterGroupType,
      UnitId : this.unitid,
    };
    this.rowData = this.EmptyGrid;
    this.parameterGroupList = this.EmptyGrid;
    this.processParameterLoginService.parametergroupsforSampling(Key).subscribe(
      (res: any[]) => {
        this.parameterGroupList = res;
      },
      (error: { message: any }) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  

  ApplyFilterData(): void {
    this.spinner.show();
    this.loading = true;
    const PostData = {
      groupId: this.processDataForm.value.parametergroup,
      puId: this.unitid,
    };
    this.processParameterLoginService.getDataApplyFilter(PostData).subscribe(
      (data) => {
        this.spinner.hide();
        this.loading = false;
        data.forEach(function (res: any) {
          res.interval = res.interval === null ? 0 : res.interval;
          if (res.autoManual === false || res.autoManual === null) {
            res.autoManual = 'Manual';
          } else if (res.autoManual === true || res.autoManual === null) {
            res.autoManual = 'Auto';
          }
        });
        this.rowData = data;
       if(this.processDataForm.value.parameterGroupType!=2){
        this.gridOptions.columnApi.setColumnVisible('interval', false)
      }
      else{
        this.gridOptions.columnApi.setColumnVisible('interval', true)
      }
      this.gridOptions.api.sizeColumnsToFit();
        const array = data.map((c) => c.processType);
        const filtered = array.filter(function (el) {
          return el != null;
        });
        if (filtered.length > 0) {
          this.processDataForm.get('processType')?.setValue(filtered[0]);
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastrService.error('error', error.message);
      }
    );
  }
  
  ResetData(): void {
    this.processDataForm.get('parametergroup')?.reset();
    this.UnitName = ' ';
    this.rowData = this.EmptyGrid;
    this.processDataForm.get('variableGroupType')?.reset();
    
  }
  // close model
  closePlantGroupModel() {
    this.isPlantGroupModelVisible = false;
  }
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  SelectVarUnitName(unitname: any): any {
    if (unitname.title === 'UNT') {
      this.varUnitName = unitname.name;
      this.isPlantGroupModelVisible = false;
    }
  }

  onCellValueChanged(params: any): void {
    const check = params.colDef.field;
    if (this.IsWrite) {
      if (check === 'isSelected') {
        const getrow = params.data;
        if (getrow.isSelected === true) {
          const AddKey = {
            autoManual: getrow.autoManual,
            interval: getrow.interval,
            isSelected: getrow.isSelected,
            puId: this.unitid,
            varId: getrow.varId,
            processType: this.processDataForm.value.processType,
            // userId: JSON.parse(localStorage.user).UserId
          };
          this.processParameterLoginService
            .AddParametersLogging(AddKey)
            .subscribe(
              (data: any) => {
                this.toastrService.success('Record Add Successfully...');
                this.ApplyFilterData();
                debugger;
                this.showPopupMsg(data[0].message);
              },
              (error: any) => {
                this.toastrService.error('Error', error.error.message);
                // params.node.setDataValue(params.colDef.field, params.oldValue);
                this.ApplyFilterData();
              }
            );
        } else if (getrow.isSelected === false) {
          const delKey = {
            plId: getrow.plId,
            puId: this.unitid,
          };
          this.processParameterLoginService.DeleteSelected(delKey).subscribe(
            (data: any) => {
              this.toastrService.success('Record Reset Successfully...');
              this.ApplyFilterData();
              this.showPopupMsg(data[0].message);
            },
            (error: any) => {
              this.toastrService.error('Error', error.error.message);
              this.ApplyFilterData();
            }
          );
        }
      } else if (check === 'autoManual') {
        const getrow = params.data;
        const Key = {
          reqType: 0,
          autoManual: getrow.autoManual,
          plId: getrow.plId,
          puId: this.unitid,
          processType: this.processDataForm.value.processType,
          // userId: JSON.parse(localStorage.user).UserId
        };
        this.processParameterLoginService.ChangeParameter(Key).subscribe(
          (data: any) => {
            this.toastrService.success('Auto/Manual Added.....');
            this.ApplyFilterData();
            this.showPopupMsg(data[0].message);
          },
          (error: any) => {
            this.toastrService.error('Error', error.error.message);
          }
        );
      } else if (check === 'interval') {
        const getrow = params.data;
        const Key = {
          reqType: 1,
          interval: getrow.interval === '' ? 0 : getrow.interval,
          plId: getrow.plId,
          puId: this.unitid,
          processType: this.processDataForm.value.processType,
        };
        this.processParameterLoginService.ChangeParameter(Key).subscribe(
          (data: any) => {
            this.toastrService.success('Interval Added.....');
            this.ApplyFilterData();
          },
          (error: any) => {
            this.toastrService.error('Error', error.error.message);
            this.ApplyFilterData();
          }
        );
      }
    } else {
      this.toastrService.error('Opps..! Only Read Permission');
      this.ApplyFilterData();
    }
  }

  // Icon
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  onClickUnit(node: any) {
    if (node.Level === 'Unit') {
      this.isPlantGroupModelVisible = false;
      this.UnitName = node.text;
      this.unitid = node.PrimaryId;
      this.rowData = this.EmptyGrid;
    }
  }

  SelectUnitName(unitname: any) {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
      this.getParameterGroups();
    }
  }
  openUnit(): any {
    this.isPlantGroupModelVisible = true;

    const key = {
      EGId: this.processDataForm.value.Group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.UnitNode = filterData(this.plantGroupSource.data, 'UNT');
        this.expandAllNode();
      },
      (error: any) => {
        this.toastrService.error('Error', error.message);
      }
    );

    function filterData(data: PlantGroupElement[], title: string) {
      const r = data.filter(function (o: any) {
        Object.keys(o).forEach(function (e: any) {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
  }

  //breadCrumb

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
//menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  showPopupMsg(msg: any): any {
    if (msg !== null || msg !== '') {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
      } else {
        this.toaster.error('Error', msg.substring(3, msg.length));
      }
    }
  }
}

function GenderCellRenderer(): void {}

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};
