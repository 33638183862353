import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QualityexecutionService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  addCheckListExecution(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'addqualityexecution', data);
  }

  getCheckListByIdForExecution(checkList: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'qualitychecklistforexecution', checkList);
  }

  getCheckList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'qualitychecklists', data);
  }

  getCheckListExec(data:any):Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'QualityChecklist/GetqualityCheckListExec', data);
  }

  getCheckList1(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'QualityChecklist/GetAllQualityCheckLists', data);
  }

  CheckListShiftsByDate(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/Qualityexecutionshift', data);
  }

  getCheckListByIdForNewExecution(checkList: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/QualityNewchecklistforexecution', checkList);
  }

  addNewCheckListExecution(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/AddQualityNewCheckExecution', data);
  }

  getdropdown(data:any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/GetDropdowndata',data)
  }

  getNewCheckListByIdForExecutionEdit(checkList: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/QualityNewchecklistforexecutionEdit', checkList);
  }

  editCheckListExecution(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/EditQualityCheckExecution', data);
  }
}
