import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { FteService } from 'src/app/services/Fte.service';
import { UserAccessService } from 'src/app/services/userAccess.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-FTE',
  templateUrl: './FTE.component.html',
  styleUrls: ['./FTE.component.scss']
})
export class FTEComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  columnDefs: any;
  rowData: any;
  dataArray!: [];
  taskDDLForEdit!: any[];
  FTEForm!: FormGroup;
  FTEMacroForm!: FormGroup;
  fteId: any;
  groups!: any;
  numberOfDays!: any;
  projectDDL!: any[];
  IsAccess:any;
  IsWrite:any;
  concatedFromArray!: any[];
  FTEColumns: string[] = ['FTEActions', 'project', 'topic', 'task', 'frequency', 'duration', 'owner', 'periority', 'from', 'to',
                          'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', ];
  FTEData!: MatTableDataSource<any>;
  plants!: any;
  projectId!: any;
  topicId!: any;
  taskId!: any;
  frequencyId!: any;
  groupId!: number;
  plantId!: number;
  groupListDDL!: any[];
  frequencyDDL!: any[];
  topicDDL!: any[];
  taskDDL!: any[];
  OwnerDDL!: any[];
  OwnerDDLForEdit!: any[];
  FTEModelVisible = false;
  FTEformMode = false;
  isUpdateFTE = false;
  SearchFTEForm!: FormGroup;
  taskPlant!: any;
  fteProject!: any;
  duration: any;
  currentDate!: any;
  ownerId: any;
  filteredUsers: any;
  FromMonthList: any[] = [
    { id : 1, name: 'January'}, { id : 2, name: 'February'}, { id : 3, name: 'March'}, { id : 4, name: 'April'}, { id : 5, name: 'May'},
    { id : 6, name: 'June'}, { id : 7, name: 'July'}, { id : 8, name: 'August'}, { id : 9, name: 'September'}, { id : 10, name: 'October'},
    { id : 11, name: 'November'}, { id : 12, name: 'December'}
  ];
  ToMonthList: any[] = [
    { id : 1, name: 'January'}, { id : 2, name: 'February'}, { id : 3, name: 'March'}, { id : 4, name: 'April'}, { id : 5, name: 'May'},
    { id : 6, name: 'June'}, { id : 7, name: 'July'}, { id : 8, name: 'August'}, { id : 9, name: 'September'}, { id : 10, name: 'October'},
    { id : 11, name: 'November'}, { id : 12, name: 'December'}
  ];
  selectedFromMonth = this.FromMonthList[0].name;
  selectedToMonth = this.ToMonthList[0].name;
  breadcrumList!: any;
  Jan!: number;
  dateDisableProperty = false;
  constructor(private fb: FormBuilder, private FteService: FteService, private toaster: ToastrService,
              private userAccessServices: UserAccessService, private platModelService: EnterPriseGroupService,
               private usermanagementService: UserManagementService, public router: Router) {
   }

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.FTEForm = this.fb.group({
      taskplant: [''],
      projectname: ['', Validators.required],
      topic: ['', Validators.required],
      task: ['', Validators.required, ],
      frequency: ['', Validators.required],
      duration: ['', Validators.required],
      owner: [[]],
      periority: ['', Validators.required],
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],
    });
    this.SearchFTEForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      project: ['', Validators.required],
      frequencies: ['', Validators.required],
    });

    this.FTEMacroForm = this.fb.group({ FTEMacroArray: this.fb.array([this.MacroForm()]) });

    this.getGroupData();
    this.GetMenuAccess();
  }
  
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
      // localStorage.setItem('GetMenuAccess', this.permisson);
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  MacroForm(): FormGroup {
    return this.fb.group({
      Jan: [''],
      Feb: [''],
      Mar: [''],
      Apr: [''],
      May: [''],
      Jun: [''],
      Jul: [''],
      Aug: [''],
      Sep: [''],
      Oct: [''],
      Nov: [''],
      Dec: [''],
    });
  }

  openFTEModel(): void {
    this.FTEformMode = true;
    this.dateDisableProperty = false;
    if (this.SearchFTEForm.value.frequencies === 1) {
      this.dateDisableProperty = true;
      const date = new Date();
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const lastDayOfYear = new Date(date.getFullYear(), 11 + 1, 0);
      this.FTEForm.get('fromdate')?.setValue(firstDayOfYear);
      this.FTEForm.get('todate')?.setValue(lastDayOfYear);
    }
    this.FTEForm.get('taskplant')?.setValue(this.SearchFTEForm.value.plant);
    this.FTEForm.get('projectname')?.setValue(this.SearchFTEForm.value.project);
    this.FTEForm.get('frequency')?.setValue(this.SearchFTEForm.value.frequencies);
    this.getMastertopic();
    this.getTaskByTopicid();

    this.getUserList();

    this.FTEModelVisible = true;
  }

  getGroupData(): void {
    const key = {
      Id: 0
    };
    this.usermanagementService.getGroupData(key).subscribe((data: any) => {
      this.groupListDDL = data.masterList;
      this.SearchFTEForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
      this.getPlantByGroup();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlantByGroup(): void {

    const key = {
      GroupId: this.SearchFTEForm.get('group')?.value
    };

    this.usermanagementService.getDepartments(key).subscribe((data: any) => {
      this.plants = data.DepartList;
      this.SearchFTEForm.get('plant')?.setValue(this.plants[0].DeptId);
      // this.taskPlant =this.SearchFTEForm.get('plant')?.value;
    // this.plantId =this.SearchFTEForm.get('plants')?.value
      this.getProjectByPlant();
      this.getUserList();
     }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getProjectByPlant(): void {
    const key = {
      plantId: this.SearchFTEForm.get('plant')?.value
    };
    this.FteService.getMasterProject(key).subscribe((data) => {
      this.projectDDL = data;
      this.getMasterfrequency();
      this.SearchFTEForm.get('project')?.setValue(this.projectDDL[0].projectId);
    // this.fteProject=this.FTEForm.get('projectName')?.value;
      // this.SearchFTEForm.get('frequencies')?.setValue(this.frequencyDDL[0].frequencyId);
      // this.getMasterfrequency();

    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getMasterfrequency(): void {
    const key = {
      plantId:  this.SearchFTEForm.get('plant')?.value
    };
    this.FteService.getMasterFrequencies(key).subscribe((data) => {
      const array: any[] = [];
      data.forEach(element => {
        if (element.frequencyName === 'Quarterly' || element.frequencyName === 'Shiftly' ||
        element.frequencyName === 'BiWeekly') {
        } else {
          array.push(element);
        }
        this.frequencyDDL = array;
      });
      this.SearchFTEForm.get('frequencies')?.setValue(this.frequencyDDL[0].frequencyId);

      this.FTEForm.get('frequency')?.setValue(this.frequencyDDL[0].masterFrequencyId);
      this.getFTEList();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      });
  }

  getMastertopic(): void {
    const key = {
      projectId:  this.FTEForm.get('projectname')?.value
    };
    this.FteService.getMasterTopic(key).subscribe((data) => {
      this.topicDDL = data;
      this.FTEForm.get('topic')?.setValue(this.topicDDL[0].topicId);
      this.getTaskByTopicid();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      });
  }

  getTaskByTopicid(): void {
    const key = {
      topicId:  this.FTEForm.get('topic')?.value
    };
    this.FteService.GetTaskByTopicId(key).subscribe((data) => {
      this.taskDDL = data;
      if (this.FTEformMode) {
      this.FTEForm.get('task')?.setValue(this.taskDDL[0].taskId);
      }
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      });
  }

  getUserList(): void {
    const key = {
      egId:  this.SearchFTEForm.get('group')?.value
    };
    this.usermanagementService.getRaciUsers(key).subscribe((data: any) => {
      this.OwnerDDL = data;
      this.FTEForm.get('owner')?.setValue([this.OwnerDDL[0].userId]);
      this.filteredUsers = this.OwnerDDL.slice();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      });
  }

  getUserListForEdit(userId: number): void {
    const key = {
      egId:  this.SearchFTEForm.get('group')?.value
    };
    this.usermanagementService.getRaciUsers(key).subscribe((data: any) => {
      this.OwnerDDLForEdit = data;
      this.FTEForm.get('owner')?.setValue([userId]);
      this.filteredUsers = this.OwnerDDLForEdit.slice();
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      });
  }


  openEditFTEModal(element: any): void {
      // const index = this.taskDDL.findIndex(x => x.taskId === element.TaskId);
      // this.taskDDLForEdit = this.taskDDL.splice(index, 1);
      this.FTEForm.get('taskplant')?.setValue(this.SearchFTEForm.value.plant);
      this.FTEForm.get('projectname')?.setValue(element.ProjectId);
      this.getMastertopic();
      this.FTEForm.get('topic')?.setValue(element.TopicId);
      this.FTEForm.get('task')?.setValue(element.TaskId);
      this.FTEForm.get('frequency')?.setValue(element.MasterFrequencyId);
      this.FTEForm.get('duration')?.setValue(element.Duration);
      this.FTEForm.get('periority')?.setValue(element.priority);
      this.FTEForm.get('fromdate')?.setValue(element.FromDate);
      this.FTEForm.get('todate')?.setValue(element.ToDate);
      this.getUserListForEdit(element.UserId);

      this.fteId = element.FTEId;

      this.FTEformMode = false;
      this.FTEModelVisible = true;

  }
  updateFTE(): void {

    const task = {
      fteId: this.fteId,
      plantId: this.FTEForm.value.taskplant,
      projectId : this.FTEForm.value.projectname,
      topicId : this.FTEForm.value.topic,
      taskId : this.FTEForm.value.task,
      frequrncyId : this.FTEForm.value.frequency,
      duration : this.FTEForm.value.duration,
      ownerId: this.FTEForm.value.owner,
      priority : this.FTEForm.value.periority,
      from : this.FTEForm.value.fromdate,
      to : this.FTEForm.value.todate
      // userId : this.FTEForm.value.owner
    };
    if (this.FTEData.data.findIndex(item => item.FTEId !== this.fteId &&
       item.UserId === task.ownerId && item.TaskId === task.taskId) > -1) {
      this.toaster.error('Error', `You have already assigned task to ${this.FTEData.data.find(item => item.UserId === task.ownerId).UserName}`);
    }else {
    this.FteService.UpdateFTE(task).subscribe(
      (response) => {
        this.toaster.success('Success', 'FTE Updated Successfully');

        this.closeaFTEModel();
        this.getFTEList();
        this.resetForm();

        this.isUpdateFTE = false;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
    }
  }

  DailyChanges(event: any): void {
    console.log(event);
    if (this.FTEForm.value.frequency === 7 && parseInt(event.target.value, 10) > 16) {
        this.toaster.error('error', 'You cannot add duration more than 16 hours');
        this.FTEForm.get('duration')?.reset();
    }
  }

  saveFTE(): void {
    const FTEMaster = {
      projectId : this.FTEForm.value.projectname,
      topicId : this.FTEForm.value.topic,
      taskId : this.FTEForm.value.task,
      frequencyId : this.FTEForm.value.frequency,
      duration : this.FTEForm.value.duration,
      ownerId : this.FTEForm.value.owner,
      plantId : this.FTEForm.value.taskplant,
      priority : this.FTEForm.value.periority,
      from : this.FTEForm.value.fromdate,
      to : this.FTEForm.value.todate,
    };
    if (this.FTEData.data.findIndex(item => 
      item.UserId === FTEMaster.ownerId.find((x: number) => x === item.UserId) && item.TaskId === FTEMaster.taskId) > -1) {
      this.toaster.error('Error', `You have already assigned task to ${this.FTEData.data.find(item => item.UserId === FTEMaster.ownerId.find((x: number) => x === item.UserId)).UserName}`);
    } else {
      this.FteService.AddFTE(FTEMaster).subscribe(
        (response) => {
          this.toaster.success('Success', 'FTE Added Successfully');
          this.getFTEList();
          this.closeaFTEModel();
          this.resetForm();
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    }
  }

  DeleteFTE(element: any): void {
    Swal.fire({
      title: 'Please make sure!',
      text: 'Are you want to delete ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const id = {
          fteId: element.FTEId
        };
        this.FteService.DeleteFTE(id).subscribe((response) => {
          this.toaster.success('Success', 'FTE Deleted Successfully');
          this.getFTEList();
        }, (error) => {
          this.toaster.error('Error', error.error.message);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your data is safe :)', 'error');
      }
    });

  }

  FromChange(event: any): void {
    // const month = event.value;
    const month = parseInt(event.source.selected.id.split('-')[2], 10);

  }

  ToChange(index: number, event: any): void {


  }

  resetForm(): void {
    this.FTEForm.reset();
    this.isUpdateFTE = false;
  }
  closeaFTEModel(): void {
    this.FTEModelVisible = false;
    this.resetForm();
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.FTEData.filter = filterValue.trim().toLowerCase();
    if (this.FTEData.paginator) {
      this.FTEData.paginator.firstPage();
    }
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  daysInMonth(date: any): any {
    const Month = new Date(date).getMonth() + 1;
    const year = new Date(date).getFullYear();
    const firstDay = new Date(year, Month - 1, 1);
    const lastDay = new Date(year, Month , 0);
    let days = 0;
    const curDate = firstDay;
    while (curDate <= lastDay) {
        const dayOfWeek = curDate.getDay();
        if (!((dayOfWeek === 5) || (dayOfWeek === 6))) {
          days++;
        }
        curDate.setDate(curDate.getDate() + 1);
    }
    return days;
  }

  getFTEList(): void {
    const key = {
      plantId: this.SearchFTEForm.get('plant')?.value,
      projectId : this.SearchFTEForm.get('project')?.value,
      frequencyId : this.SearchFTEForm.get('frequencies')?.value
    };
    this.FteService.GetAllFTE(key).subscribe((data) => {
      const updatedArray: any[] | undefined = [];
      data.forEach(element => {
        const frequency = element.Name;
        let duration = element.Duration;
        let durations = 0;
        const item =  {
          Duration: element.Duration,
          FTEId: element.FTEId,
          MasterFrequencyId: element.MasterFrequencyId,
          Name: element.Name,
          ProjectId: element.ProjectId,
          ProjectName: element.ProjectName,
          TaskId: element.TaskId,
          TaskName: element.TaskName,
          TopicId: element.TopicId,
          TopicName: element.TopicName,
          UserId: element.UserId,
          UserName: element.UserName,
          plantId: element.plantId,
          plantName: element.plantName,
          FromDate: element.From,
          ToDate: element.To,
          From: this.FromMonthList.find(x => x.id === new Date(element.From).getMonth() + 1).name,
          To: this.FromMonthList.find(x => x.id === new Date(element.To).getMonth() + 1).name,
          priority: element.priority,
          Jan: '',
          Feb: '',
          Mar: '',
          Apr: '',
          May: '',
          Jun: '',
          Jul: '',
          Aug: '',
          Sep: '',
          Oct: '',
          Nov: '',
          Dec: '',
        };
        // to calculate startingf and ending month range
        const monthArray = [];
        const fromMonth = new Date(element.From).getMonth() + 1;
        const toMonth = new Date(element.To).getMonth() + 1;
        const year = new Date(element.From).getFullYear();
        for (let i = fromMonth; i <= toMonth; i++) {
          monthArray.push(i);
        }
        if (frequency === 'Yearly') {
          durations = duration / 12;
        }else if (frequency === 'Quarterly') {
          durations = duration / 3;
        } else if (frequency === 'Monthly') {
          durations = duration;
        }else if (frequency === 'Weekly') {
          durations = duration * 4;
        }else if (frequency === 'BiWeekly') {
          durations = duration * 2;
        }
        for (let i = 0; i <= monthArray.length; i++) {
          duration = durations; // element.Duration;
          switch (monthArray[i]) {
            // january case
            case 1:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.Jan = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
            // february case
            case 2:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.Feb = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
            // march case
            case 3:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.Mar = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
            // april case
            case 4:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.Apr = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
            // may
            case 5:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.May = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
            // june case
            case 6:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.Jun = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
            // july case
            case 7:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.Jul = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
            // august case
            case 8:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.Aug = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
            // september case
            case 9:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.Sep = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
            // october case
            case 10:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.Oct = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
            // november case
            case 11:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.Nov = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
            // december case
            case 12:
              this.currentDate = new Date(year, monthArray[i] - 1);
              this.numberOfDays = this.daysInMonth(this.currentDate);
              if (frequency === 'Daily') {
                duration = element.Duration * this.numberOfDays;
              }
              item.Dec = (duration / ((this.numberOfDays) * 9)).toFixed(2);
              break;
          }
        }
        updatedArray.push(item);
      });
      this.FTEData = new MatTableDataSource<any>(updatedArray);
      this.getPaginator();
    },
      (error) => {
        this.toaster.error(error.error.message);
      });
  }

  getPaginator(): void {
    setTimeout(() => {
      this.FTEData.paginator = this.paginator;
      this.FTEData.sort = this.sort;
    }, 1000);
  }
}
