import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'btn-cell-rendererQxMatrix',
    template: `
 <button mat-mini-fab color="primary" (click)="onClick($event,'menu')" matTooltip="Menu" class="context-menu">
        <!-- <i class="fa fa-edit Update" style="color: #fff;"></i> -->
        <mat-icon>menu</mat-icon>
        </button>
      
  `
})
// tslint:disable-next-line:component-class-suffix
export class BtnCellRendererKPIGridView implements ICellRendererAngularComp, OnDestroy {
    private params: any;


    refresh(params: any): boolean {
        throw new Error('Method not implemented.');
    }
    agInit(params: any): void {
        console.log({ params })
        this.params = params;
    }
    onClick($event: any, type: any): any {
        if (this.params.onClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: { ...$event, customType: type },
                rowData: this.params.node.data,
                type
                // ...something
            };
            this.params.onClick(params);
        }
    }
    ngOnDestroy(): any {
        // no need to remove the button click handler
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}
