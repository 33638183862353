import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Pillar } from 'src/app/models/pillar';
import { GembaWalkService } from 'src/app/services/gemba-walk.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { GlobalStandardService } from 'src/app/services/global-standard.service';
import { AreaService } from 'src/app/services/area.service';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';

import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { BtnCellRendererGambaWalk } from 'src/app/btn-cell-rendererGambaWalk.component';
import { BehaviorSubject } from 'rxjs';
import * as _moment from 'moment';
import { CustomTooltip } from 'src/app/custom-tooltip.component';
import { PeopleMasterDataService } from 'src/app/services/people-master-data-service';
import { MatCheckboxRendererComponent } from 'src/app/mat-checkbox-renderer';
import { environment } from 'src/environments/environment';

const moment = _moment;

interface ActionInterface {
  action: any;
  target: any;
  newtarget: any;
  misses: any;
  remarks: any;
  responsible: any;
  completed: any;
  isRequiredPictureAfter: any;
}

@Component({
  selector: 'app-gemba-walk',
  templateUrl: './gemba-walk.component.html',
  styleUrls: ['./gemba-walk.component.scss'],
})
export class GembaWalkComponent implements OnInit {
  gembaWalkForm!: FormGroup;
  gembaDataForm!: FormGroup;
  pictureAfter!: FormGroup;
  owners!: any[];
  filteredOwners!: any[];
  responsibleArray!: any[];
  filteredAreas!: any[];
  raciUsers!: any[];
  UserId!: any;
  fileSave!: File;
  files: File[] = [];
  file2Save!: File;
  GembaId!: any;
  AreaName!: any;
  OwnerName!: any;
  FormMode!: boolean;
  columnDefs: any;
  ownersList!: any;
  icons!: any;
  startDate = new Date(new Date().getTime() - 3888000000);
  ownerslist!: any;
  cellData: any;
  startYear = new Date(new Date().getTime() - 3888000000);
  minDate!: Date;
  endYear!: any;
  UserIdsforNotification!: any[];
  ActionArray: ActionInterface[] = [];
  auditClassifications!: any[];
  dmsClassification!: any[];
  ButtonType!: any;
  observationDataForm!: FormGroup;
  imageSrc!: string;
  completionCheck!: boolean;
  plants!: any;
  area!: any[];
  responsibleForm!: FormGroup;
  groups!: any[];
  gembaData!: MatTableDataSource<any>;
  pillarData!: MatTableDataSource<Pillar>;
  closeGembaForm!: FormGroup

  // ActionDataSource!: MatTableDataSource<any>;
  @ViewChild('fileInput', { static: false })
  myFileInput!: ElementRef;
  globalColumns: string[] = ['plant', 'area', 'type', 'name', 'file'];
  actionColumns: string[] = [
    'action',
    'departments',
    'responsible',
    'remarks',
    'target',
    'isRequiredPictureAfter',
    'remove',
  ];
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderGambaWalk: BtnCellRendererGambaWalk,
      btnCellRenderer: BtnCellRenderer,
      customTooltip: CustomTooltip,
      checkboxRenderer: MatCheckboxRendererComponent,

    },
  };
  reciData: any = {
    53: [
      {
        userName: 'xyz',
        userId: 1,
      },
    ],
  };
  rowData!: any[];
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;
  areaId!: any;
  ownerId!: any;
  image: any;
  currentImagePath: any;
  departmentList: any;
  responsibleUser: any;
  generalCategoryList: any;
  raci!: any;
  gembaWalkId: any;
  rows!: FormArray;
  gembaActionform!: FormGroup;
  actionDataSource = new BehaviorSubject<AbstractControl[]>([]); // new MatTableDataSource();
  sectionManagerList: any;
  moduleId!: any;
  pageId!: any;
  rowDataViewActions!: any;
  previewerColumns: string[] = ['Desc', 'Action', 'Remove'];
  filesForm!: FormGroup;
  previewerData!: MatTableDataSource<any>;
  disableSave = false;
  displayMaximizable = false;
  images: any[] = [];
  fileType!: string;
  imageIndexOne = 0;
  fileDesc: any;
  doc!: any;
  FileData: any = [];
  IsAllOwner = false ;
  checkedClose = false;
  closeFile!: File ;
  uploadImage = true;
  CloseImage = '';
  disableImage = false;
  checkBoxDisable = false;
  columnDefsActionView: any;
  imageUrl: string;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private userService: UserManagementService,
    private toaster: ToastrService,
    private areaService: AreaService,
    private globalService: GlobalStandardService,
    private gembaService: GembaWalkService,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private machineService: MachineMappingService,
    private kpiMaster: KpimasterService,
    public router: Router,
    private peopleService: PeopleMasterDataService,
  ) {
    this.columnDefs = [
       {
        field: 'Actions',
        cellRenderer: 'btnCellRenderGambaWalk',
        cellRendererParams: {
          clicked: function (field: any) { },
        },
        sortable: true,
        filter: true,
        resizable: true,
        width: 300,
        wrapText: true,
        pinned: 'left',
      },
      {
        headerName: 'Area',
        field: 'AreaName',
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        pinned: 'left',
      },
      { 
        field: 'Owner',
        sortable: true,
        width: 100,
        filter: true,
        resizable: true,
        wrapText: true,
        pinned: 'left',
        cellStyle: (params: any) => {
          return { backgroundColor: params.data.FrequecncyColor };
        },
      },
      {
        field: 'Observation',
        sortable: true,
        width: 130,
        filter: true,
        resizable: true,
        wrapText: true,
        pinned: 'left',
        tooltipValueGetter: (params: any) => params.value,
      },
      // {
      //   field: 'pictureBefore',
      //   headerName: 'Picture Before',
      //   width: 120,
      //   sortable: true,
      //   resizable: true,
      //   hide: true ,
      //   pinned: 'left',
      //   cellRenderer: (params: any): any => {
      //     return params.value.substring(params.value.lastIndexOf('/') + 1, params.value.length) !== ''
      //       ? '<img style="height:40px;width:40px" src="' +
      //       params.value +
      //       '" />'
      //       : '<img style="height:40px;width:40px" src="assets/images/no-image.png" />';
      //   },
      // },
      {
        field: 'CreatedBy',
        width: 120,
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
         pinned: 'left',
      },
      {
        field: 'fivesClassification',
        headerName: '5sClassification',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
      },
      // {
      //   field: 'OwnerId',
      //   hide: true,
      //   sortable: true,
      //   filter: true,
      //   resizable: true,
      //   wrapText: true,
      // },
      {
        field: 'Category',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        field: 'ObservationDate',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        field: 'ObservationStatus',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        editable: false,
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Closed', 'Open', 'In Progress', 'Delayed', 'Reject'],
        },
      },
      {
        field: 'Remarks',
        sortable: true,
        editable: (params: any) =>
          params.data.ObservationStatus !== 'Closed' && this.IsWrite,
        filter: true,
        resizable: true,
        wrapText: true,
        cellStyle: {
          color: 'black',
          'background-color': '#EDF1E5',
        },
      },

      {
        field: 'CompletionDate',
        sortable: true,
        resizable: true,
        wrapText: true,
        filter: 'agDateColumnFilter',
        cellEditor: 'primeCellEditor',
        filterParams: {
          comparator: myDateComparator,
        },
      },
    ];
    this.columnDefsActionView = [
      {
        headerName: 'ActionName',
        field: 'ActionName',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        headerName: 'Responsible',
        field: 'responsibleName',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        headerName: 'No Of Misses',
        field: 'NoOfMisses',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        field:'Observation',
        headerName: 'Observation',
        sortable:true,
        filter:true,
        resizeable:true,
        wrapText:true
      },
      {
        field:'Remarks',
        headerName: 'Remarks',
        sortable:true,
        filter:true,
        resizeable:true,
        wrapText:true
      },
      {
        headerName: 'TargetDate',
        field: 'TargetDate',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        valueFormatter: function (params: any) {return params.value !== null ?  moment(params.value).format('MM/DD/YYYY HH:mm:ss') :'' }
      },
      {
        headerName: 'NewTargetDate',
        field: 'NewTargetDate',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        valueFormatter: function (params: any) {return params.value !== null ?  moment(params.value).format('MM/DD/YYYY HH:mm:ss') :'' }
      },
      {
        headerName: 'Picture After Required',
        field: 'IsRequiredPictureAfter',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        cellRenderer: 'checkboxRenderer',
        cellRendererParams: {disable: true},
        editable: false,
      },
      {
        field: 'PictureAfter',
        headerName: 'Picture After',
        sortable: true,
        resizable: true,
        filter: false,
        cellRenderer: function (params: any) {
          let html = `<img style="width: 50px; height: 40px;" src="assets/images/no-image.png"/>`;
          if (params && params.value) {
            html = '';
            html = `<img style="width: 50px; height: 40px;" src="${params.value}"/>`;
           
          }
          return html;
        },
      },
      {
        headerName: 'Rejected',
        field: 'IsRejected',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        cellRenderer: 'checkboxRenderer',
        cellRendererParams: {disable: true},
        editable: false,
      },
      {
        field: 'IsCompleted',
        headerName: 'Completed',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        cellRenderer: 'checkboxRenderer',
        cellRendererParams: {disable: true},
        editable:  false,
      },
      
      {
        field: 'IsVerified',
        headerName: 'Verified',
        sortable: true,
        filter: true,
        resizable: true,
        width: 200,
        wrapText: true,
        hide: !this.IsWrite,
        cellRenderer: 'checkboxRenderer',
        cellRendererParams: {disable: true},
        editable: false,
      },
    ];
  }

  @ViewChild(MatTable) table!: MatTable<Pillar>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('displayObservationModal')
  private displayObservationModal!: TemplateRef<any>;
  @ViewChild('displayRoleModal1') private displayRoleModal1!: TemplateRef<any>;
  @ViewChild('displayActionModal')
  private displayActionModal!: TemplateRef<any>;

  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('imageZoomModel2') private imageZoomModel2!: TemplateRef<any>;
  @ViewChild('imageZoomModel3') private imageZoomModel3!: TemplateRef<any>;
  @ViewChild('previewModal') private previewModal!: TemplateRef<any>;
  @ViewChild('ViewActionDetailModel') private ViewActionDetailModel!: TemplateRef<any>;
  @ViewChild('CloseObservation') private CloseObservation!: TemplateRef<any>;
  ngOnInit() {
    this.imageUrl = environment.O3CoreApiImageUrl;
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.minDate = new Date();
    this.gembaWalkForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.filesForm = this.fb.group({
      file: [],
    });
    this.closeGembaForm = this.fb.group({
      remarks:[''],
      image: [''] ,
    })
    this.gembaDataForm = this.fb.group({
      area: ['', Validators.required],
      owner: ['', Validators.required],
      startdate: [''],
      enddate: [''],
    });
    this.gembaActionform = this.fb.group({
      actionsArray: this.fb.array([this.actionForm()]),
    });
    this.rows = this.gembaActionform.get('actionsArray') as FormArray;
    this.completionCheck = true;
    const d = new Date();
    d.setDate(d.getDate() - 7); // setMonth(d.getMonth() - 3);
    this.gembaDataForm.get('startdate')?.setValue(d);
    this.gembaDataForm.get('enddate')?.setValue(new Date());
    this.closeGembaForm.get('image').setValidators(Validators.required)
    this.observationDataForm = this.fb.group({
      observe: ['', Validators.required],
      fivesclassification: ['', Validators.required],
      generalCategories: ['', Validators.required],
      image: [''],
      save: [''],
    });

    this.responsibleForm = this.fb.group({
      owner: [''],
    });

    this.pictureAfter = this.fb.group({
      image: [''],
    });

    this.responsibleArray = [];
    this.raciUsers = [];
    this.UserIdsforNotification = [];
    this.UserId = localStorage.getItem('user');
    this.UserId = JSON.parse(this.UserId).UserId;
    this.getGroups();
  }

  getFiveSClassification(): void {
    this.userService.GetAuditClassifications().subscribe(
      (data: any) => {
        this.auditClassifications = data;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getAlldepartment(): void {
    const key = {
      Id: this.gembaWalkForm.value.plant,
    };
    this.machineService.getAllDepartmentData(key).subscribe(
      (response: any) => {
        this.departmentList = response.masterList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getGeneralCategories(): void {
    this.gembaService.getGembaCatagories().subscribe(
      (res: any) => {
           this.generalCategoryList = res;
      }
    );
  }

  getUsersbyDepartment(event: any, i: any): void {
    const key = {
      egId: this.gembaWalkForm.value.group,
      plantId: this.gembaWalkForm.value.plant,
      departmentId: event,
    };
    this.peopleService.getEmployeePositionMappedMasterData(key).subscribe(
      (response: any) => {
        this.raciUsers[i] = response.employeePositionMappedList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  resetFilterList(i: any): void {
    this.raciUsers = [];
    this.raciUsers[i] = [];
    const key = {
      egId: this.gembaWalkForm.value.group,
      plantId: this.gembaWalkForm.value.plant,
      departmentId: this.gembaActionform.value.actionsArray[i].department,
    };
    if (key.departmentId) {
      this.peopleService.getEmployeePositionMappedMasterData(key).subscribe(
        (response: any) => {
          this.raciUsers[i] = response.employeePositionMappedList;
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  openModal(): void {
    const key = {
      area: this.gembaDataForm.value.area,
      owner: this.gembaDataForm.value.owner,
    };
    if (key.area === 0 || key.owner === 0) {
      this.toaster.warning('Please Select Area And Owner');
    } else {
      this.FormMode = true;
      this.observationDataForm.reset();
      this.fileSave = null;
      this.image = '';
      this.files = [];
      this.FileData = [];
      this.dialog.open(this.displayObservationModal, {
        height: '55%',
        width: '85%',
      });
      this.getGeneralCategories();
      this.getFiveSClassification();
    }
  }

  onAreaChange(event: any): void {
    this.getOwner();
    this.area.map((x) => {
      if (x.areaId === event.value) {
        this.AreaName = x.areaName;
      }
    });
    this.getsectionManager();
  }

  onOwnerChange(event: any): void {
    this.owners.map((x) => {
      if (x.userId === event.value) {
        this.OwnerName = x.userName;
      }
    });
  }

  editModal(): void {
    this.FormMode = false;
    this.dialog.open(this.displayObservationModal, {
      height: '55%',
      width: '85%',
    });
    this.getGeneralCategories();
    this.getFiveSClassification();
  }

  openGlobalModal(): void {
    this.dialog.open(this.displayRoleModal1, { height: '80%', width: '90%' });
    this.getGlobalStandards();
  }

  openActionModal(): void {
    this.dialog.open(this.displayActionModal, { height: '80%', width: '90%' });
    this.getgembaactions();
  }

  getPaginator(): void {
    setTimeout(() => {
      this.gembaData.paginator = this.paginator;
      this.gembaData.sort = this.sort;
    }, 1000);
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    const id = {
      value: 0,
    };
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.gembaWalkForm.get('group')?.setValue(data.masterList[0]?.TypeId);
        this.groups = data.masterList;
        id.value = data.masterList[0]?.TypeId;
        this.getPlant(id);
        this.rowData = [];
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  onPlantChange(event: any): void {
    this.getArea();
    this.getAlldepartment();
    this.rowData = [];
  }

  getGlobalStandards(): void {
    const key = {
      plantId: this.gembaWalkForm.value.plant,
    };
    this.spinner.show();

    this.globalService.getGlobalStandard(key).subscribe(
      (data) => {
        this.gembaData = new MatTableDataSource<any>(data);
        this.spinner.hide();

        setTimeout(() => {
          this.gembaData.paginator = this.paginator;
          this.gembaData.sort = this.sort;
        }, 1000);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }

  getGembaWalkData(): void {
    const key = {
      startDate: this.gembaDataForm.value.startdate,
      endDate: this.gembaDataForm.value.enddate,
      plantId: this.gembaWalkForm.value.plant,
      userId: JSON.parse(localStorage.user).UserId,
      areaId:
        this.gembaDataForm.value.area === 0
          ? this.area.map((c: any) => c.areaId).toString()
          : this.gembaDataForm.value.area,
      ownerId:
        this.gembaDataForm.value.area === 0
          ? this.owners.map((c: any) => c.userId).toString()
          : this.gembaDataForm.value.owner.toString(),
    };
    this.spinner.show();
    this.gembaService.getGembaWalk(key).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.rowData = data.data;
      },
      (error: any) => {
        this.toaster.error('error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  getPlant(event: any): void {
    const key = {
      GroupId: event.value,
    };

    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.gembaWalkForm.get('plant')?.setValue(data.DepartList[0].DeptId);
        this.getAlldepartment();
        this.getArea();
        this.rowData = [];
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getArea(): void {
    const key = {
      plantId: this.gembaWalkForm.value.plant,
    };
    const urole = JSON.parse(localStorage.roleName).map((v: any) =>
      v.toLowerCase()
    );
    const uid = JSON.parse(localStorage.user).UserId;
    this.areaService.getArea(key).subscribe(
      (data: any) => {
        this.area = data;
        this.filteredAreas = data.slice();
        this.gembaDataForm.get('area')?.setValue(this.area[0].areaId);
        const key2 = {
          value: this.gembaDataForm.value.area,
        };
        this.getOwner();
        this.onAreaChange(key2);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getsectionManager(): void {
    const key = {
      areaId: this.gembaDataForm.value.area,
    };
    this.areaService.getSectioMmanagerbyArea(key).subscribe(
      (data: any): any => {
        this.sectionManagerList = data;
      },
      (error) => {
        this.toaster.error('Error', error.error.Message);
      }
    );
  }

  getOwner(): void {
    const a = this.area.map((x) => {
      return x.areaId;
    });
    const key = {
      egId: this.gembaWalkForm.value.group,
      areaId: this.gembaDataForm.value.area,
      ptId: this.gembaWalkForm.value.plant,
      areaIds: a,
    };

    this.userService.GetUsersByArea(key).subscribe(
      (data: any): any => {
        this.owners = data;
        this.filteredOwners = this.owners.slice();
        this.ownersList = this.owners.map((x) => {
          return x.userName;
        });

        if (this.gembaDataForm.value.area === 0)
        {
          this.IsAllOwner = true ;
          this.gembaDataForm.get('owner')?.setValue(0);
        }
        else
        {
          this.IsAllOwner = false ;
          this.gembaDataForm.get('owner')?.setValue(data[0].userId);
        }
        const key2 = {
          value: this.gembaDataForm.value.owner,
        };
        this.onOwnerChange(key2);
        this.getGembaWalkData();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  submitData(): void {
    if (this.FormMode === true) {
      this.onSubmit();
    } else if (this.FormMode === false) {
      this.updateGemba();
    }
  }

  updateGemba(): void {
    const formData = new FormData();
    formData.append('file', this.fileSave);
    const gembaWalk = {
      gembaWalkId: this.GembaId,
      userId: JSON.parse(localStorage.user).UserId,
      plantId: this.gembaWalkForm.value.plant,
      areaId: this.areaId,
      ownerId: this.ownerId,
      observation: this.observationDataForm.value.observe,
      classificationId: this.observationDataForm.value.classification,
      fiveSClassificationId: this.observationDataForm.value.fivesclassification,
      generalCategoryId: this.observationDataForm.value.generalCategories,
    };
    this.spinner.show();

    formData.append('gembaWalk', JSON.stringify(gembaWalk));
    if (
      !gembaWalk.areaId ||
      !gembaWalk.ownerId ||
      !gembaWalk.fiveSClassificationId ||
      !gembaWalk.generalCategoryId
    ) {
      this.toaster.warning('Please Select All Fields');
      this.spinner.hide();
    } else {
      this.gembaService.updateGembaWalk(formData).subscribe(
        (data: any) => {
          this.toaster.success('Data Updated Successfuly');
          this.areaId = null;
          this.ownerId = null;
          this.getGembaWalkData();
          this.dialog.closeAll();
          this.spinner.hide();
        },
        (error: any) => {
          this.toaster.error('Error', error.error.Message);
          this.spinner.hide();
        }
      );
    }
  }

  sendNotifcationsSectionManagers(descr: any): void {
    const key2 = {
      userId: this.sectionManagerList.map((c: any) => c.sectionManagerId),
      desc: descr,
      title: 'Gemba-Walk',
      createdBy: JSON.parse(localStorage.user).UserId,
    };
    this.notificationService.addnotificationuserwise(key2).subscribe(
      (data: any): any => {
      },
      (error: any) => {
        this.toaster.error('Error', error.error.Message);
      }
    );
  }

  onSubmit(): void {
    const formData = new FormData();
    for (let i = 0 ; i<this.files.length ; i++)
    {
      formData.append('files', this.files[i]);
    }
    const gembaWalk = {
      userId: JSON.parse(localStorage.user).UserId,
      plantId: this.gembaWalkForm.value.plant,
      areaId: this.gembaDataForm.value.area,
      ownerId: this.gembaDataForm.value.owner,
      observation: this.observationDataForm.value.observe,
      classificationId: this.observationDataForm.value.classification,
      generalCategoryId: this.observationDataForm.value.generalCategories,
      fiveSClassificationId: this.observationDataForm.value.fivesclassification,
    };
    this.spinner.show();
    formData.append('gembaWalk', JSON.stringify(gembaWalk));
    this.gembaService.addGembaWalk(formData).subscribe(
      (data: any) => {
        this.toaster.success('Data Added Successfuly');
        this.dialog.closeAll();
        this.spinner.hide();
        this.getGembaWalkData();
        this.sendNotifcationsSectionManagers(
          'New Observation ' +
          this.observationDataForm.value.observe +
          ' is created in Gemba Walk '
        );
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }

  uploadedFile(event: any): void {
    const files = event.target.files;
    if (files) {
      for (const file of files) {
        if (file.type.match('image.*')) {
          this.fileType = 'image';
          this.files.push(file);
        }
        else {
          this.toaster.warning('Warning', 'File Format Not Valid ');
          return;
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.FileData.push({ FileType: this.fileType, ImageSrc: e.target.result });
        };
        reader.readAsDataURL(file);
      }

    }
  }

  imageZoomModal(params: any): void {
    const col = params.colDef.field;
    if (col === 'pictureBefore') {
      this.image = params.data.Before;
      this.openPictureBeforeModal();
    }
    if (col === 'pictureAfter') {
      this.image = params.data.pictureAfter;
      this.openPictureAfterModal();
    }
  }
   imageZoom(): void {
    this.currentImagePath = this.CloseImage
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
   }
  openPictureBeforeModal(): void {
    this.currentImagePath = this.image.substring(this.image.lastIndexOf('/') + 1, this.image.length) === ''
      ? 'assets/images/no-image.png'
      : this.image;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  openPictureAfterModal(): void {
    this.currentImagePath = this.image.includes('/null')
      ? 'assets/images/no-image.png'
      : this.image;
    this.dialog.open(this.imageZoomModel2, { height: '90%', width: '90%' });
  }

  previewImage(): void {
    this.dialog.open(this.imageZoomModel3, { height: '80%', width: '90%' });
  }

  cellClicked(params: any): void {
    this.ButtonType = '';
    const col = params.colDef.field;
    this.ButtonType = params.event.target.classList[2]; // .contains('Update');//?  "updatedata" :  "deletedata";

    if (col === 'pictureBefore') {
      this.image = params.data.pictureBefore;
      this.openPictureBeforeModal();
    }
    if (col === 'PictureAfter') {
      this.GembaId = params.data.gembaWalkId;
      this.currentImagePath = params.data.PictureAfter;
      this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
    }
    if (col === 'Actions') {
      this.ButtonType = params.event.target.textContent;
      if(this.ButtonType === 'remove_red_eye 2')
      {
        this.closeGembaForm.get('remarks').setValue('')
        
        const dialog = this.dialog.open(this.CloseObservation, {
          width: '30%',
          height: 'auto',
        });
        dialog.afterClosed().subscribe((result) => { 
          this.CloseImage = '';
        });
        this.uploadImage = false;
        this.CloseImage = params.data.CloseImage == "" ? "assets/images/no-image.png" : this.imageUrl + params.data.CloseImage;
        this.closeGembaForm.get('remarks').setValue(params.data.CloseRemarks)
      }
      if (
        this.ButtonType === ' insert_drive_file'
      ) {
        this.openEditFilesModal(params.data);
      }
      else if (
        this.ButtonType === 'remove_red_eye'
      ) {
        this.gembaWalkId = params.data.gembaWalkId ;
         if(params.data.NoOfActions == 0 || params.data.NoOfActions == null)
         {
          this.toaster.warning("Actions Not Available")
         }
         else
         {
          this.openViewActions(params.data);
         }
        
      }
      else if(params.data.ReadOnly)
      {
             this.toaster.warning('warning',
             'You are not authorize to this action')
      }
      else
      {
        if (this.ButtonType === 'edit') {
          this.observationDataForm.get('image').clearValidators();
  
          if (params.data.CompletionDate === null) {
            this.GembaId = params.data.gembaWalkId;
            this.areaId = params.data.areaId;
            this.ownerId = params.data.OwnerId;
            this.image = params.data.pictureBefore;
            this.observationDataForm
              .get('observe')
              ?.setValue(params.data.Observation);
            this.observationDataForm
              .get('classification')
              ?.setValue(params.data.CategoryId);
            this.observationDataForm
              .get('generalCategories')
              ?.setValue(params.data.auditCatId);
            this.observationDataForm
              .get('fivesclassification')
              ?.setValue(params.data.fiveId);
            this.AreaName = params.data.AreaName;
            this.OwnerName = params.data.Owner;
            this.editModal();
            this.ButtonType = '';
          } else {
            this.toaster.warning('Cannot Add Or Update Completed Data.');
          }
        }
     
        if (
          this.ButtonType === 'delete' &&
          params.data.ObservationStatus.toLowerCase() === 'pending'
        ) {
          if (params.data.CompletionDate === null) {
            this.GembaId = params.data.gembaWalkId;
            this.deleteGemba();
            this.ButtonType = '';
          } else {
            this.toaster.warning('Cannot delete in progress or completed Data.');
          }
        }
        if (this.ButtonType === 'add') {
          this.GembaId = params.data.gembaWalkId;
          if (params.data.UserRole === 'section manager') {
            this.openActionModal();
          } else {
            this.toaster.warning(
              'warning',
              'You are not authorize to add gemba action'
            );
          }
  
        }
        if (
          this.ButtonType === 'close' &&
          params.data.ObservationStatus.toLowerCase() === 'pending'
        ) {
          if (params.data.CompletionDate === null) {
            this.uploadImage = true
            this.GembaId = params.data.gembaWalkId;

            this.closeGembaForm.get('remarks').setValue('');
            this.closeGembaForm.get('image').setValue('');
            this.disableImage = false;
            this.checkBoxDisable = false;
            this.closeGembaForm.get('remarks').clearValidators();
            this.closeGembaForm.get('remarks').setValidators(null)
            this.closeGembaForm.get('remarks').updateValueAndValidity();
            this.closeFile = null;
            this.closeGembaForm.get('image').setValidators(Validators.required)
            this.dialog.open(this.CloseObservation, {
              width: '40%',
              height: 'auto',
            });
            this.ButtonType = '';
          } else {
            this.toaster.warning('Cannot delete in progress or completed Data.');
          }
        }
      }
       
    }
  }

  deleteGemba(): void {
    debugger;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Gemba Walk!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          gembaWalkId: this.GembaId,
        };
        this.gembaService.deleteGembaWalk(key).subscribe(
          (success) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your selected Gemba has been deleted.',
              icon: 'success',
              timer: 800,
              showConfirmButton: false,
            });
            this.getGembaWalkData();
          },
          (error) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      }
    });
  }

  closeObservation(): void {
    const formData: any = new FormData();
    if(this.closeFile)
    {
      formData.append('Imagefile', this.closeFile);

    }
    const key = {
      id: this.GembaId,
      ImageRequired: this.checkedClose ? 1 : 0 ,
      remarks : this.closeGembaForm.value.remarks ,
      userId: JSON.parse(localStorage.user).UserId,
    };
    formData.append('obj',  JSON.stringify(key));
    this.gembaService.setGembaClosed(formData).subscribe(
      (data): any => {
        this.toaster.success('Success' , 'Your selected Observation has been closed.')
        this.getGembaWalkData();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  onCellValueChanged(params: any): void {
    const col = params.colDef.field;
    if (col === 'Remarks') {
      if((params?.oldValue != params?.newValue)){
        this.cellData = {
          remarks: params.data.Remarks,
          gembaWalkId: params.data.gembaWalkId,
          updatedBy: JSON.parse(localStorage.user).UserId,
        };
        this.gembaService.setNewRemarks(this.cellData).subscribe(
          (data): any => {
            this.toaster.success('Remarks Successfuly added');
            this.getGembaWalkData();
          },
          (error) => {
            this.toaster.error('Error', error.error.Message);
          }
        );
      }
    }
  }

  convertDate(date: any): any {
    const dateParts = date.split('/');
    return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  addRow(d?: any, noUpdate?: boolean): void {
    const row = this.fb.group({
      actionName: [d && d.actionName ? d.actionName : null, []],
      targetDate: [d && d.targetDate ? d.targetDate : null, []],
      noOfMisses: [d && d.misses ? d.misses : null, []],
      responsibleId: [d && d.responsible ? d.responsible : null, []],
      newTargetDate: [d && d.newTargetDate ? d.newTargetDate : null, []],
      rejected: [d && d.rejected ? d.rejected : null, []],
      completed: [d && d.completed ? d.completed : null, []],
      isRequiredPictureAfter: [
        d && d.isRequiredPictureAfter ? d.isRequiredPictureAfter : null,
        [],
      ],
      gembaActionId: [d && d.gembaActionId ? d.gembaActionId : null, []],
      remarks: [d && d.remarks ? d.remarks : null, []],
      department: [d && d.department ? d.department : null, []],
      verified: [d && d.verified ? d.verified : null, []],
    });
    this.rows.push(row);
    if (!noUpdate) {
      this.updateView();
    }
  }

  actionForm(): FormGroup {
    return this.fb.group({
      actionName: [],
      targetDate: [],
      noOfMisses: [],
      responsibleId: [],
      newTargetDate: [],
      completed: [],
      rejected: [],
      isRequiredPictureAfter: [],
      verified: [],
      remarks: [],
      gembaActionId: [],
      department: [],
    });
  }

  updateView(): void {
    this.actionDataSource.next(this.rows.controls);
  }

  addGembaActions(): void {
    this.disableSave = true;
    this.gembaActionform.value.actionsArray.forEach((element: any) => {
      element.targetDate = moment(element.targetDate).format('YYYY-MM-DD');
    });
    const key = {
      actions: this.gembaActionform.value.actionsArray,
      gembaWalkId: this.GembaId,
      userId: JSON.parse(localStorage.user).UserId,
    };
    if (this.gembaActionform.value.actionsArray.length > 0) {
      this.gembaService.addGembaActions(key).subscribe(
        (data: any): any => {
          this.disableSave = false;
          this.toaster.success(data.message);
          this.UserIdsforNotification =
            this.gembaActionform.value.actionsArray.map((c: any) =>
              c.gembaActionId == null ? c.responsibleId : ''
            );
          const filterUserIds = [];
          for (const item of this.UserIdsforNotification) {
            if (item !== '') {
              filterUserIds.push(item);
            }
          }
          const key2 = {
            userId: filterUserIds,
            desc: 'Gemba Action is created',
            title: 'Gemba Walk',
            createdBy: JSON.parse(localStorage.user).UserId,
          };
          this.notificationService
            .addnotificationmappeduserwise(key2)
            .subscribe((data: any): any => {
              console.log('Notification sent!');
            });
          this.getgembaactions();
          this.getGembaWalkData();
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    }
  }

  removeAt(element: any, i: any): void {
    if (confirm('Do you really want to remove it?')) {
      if (
        element.value.gembaActionId === null ||
        element.value.gembaActionId === undefined
      ) {
        this.rows.removeAt(i);
        this.updateView();
      } else {
        const key = {
          gembaActionId: element.value.gembaActionId,
          gembaWalkId: this.GembaId,
        };
        this.gembaService.deletegembaaction(key).subscribe((data: any): any => {
          this.toaster.success('action deleted successfully!');
          this.getgembaactions();
          this.getGembaWalkData();
        });
      }
    }
  }

  getgembaactions(): void {
    const key = {
      gembaWalkId: this.GembaId,
    };
    this.gembaService.getgembaactions(key).subscribe(
      (data: any): any => {
        debugger;
        this.rows.clear();
        const list: any[] = [];
        var i = 0;
        data.forEach((element: any) => {
          const data = {
            actionName: element.actionName,
            targetDate: element.targetDate,
            noOfMisses: element.noOfMisses,
            responsibleId: element.responsibleId,
            newTargetDate: element.newTargetDate,
            remarks: element.remarks,
            completed: element.completed,
            rejected: element.rejected,
            isRequiredPictureAfter: element.isRequiredPictureAfter,
            gembaActionId: element.gembaActionId,
            verified: element.verified,
            department: element.department,
          };
          this.getUsersbyDepartment(element.department, i);
          i++;
          list.push(data);
          this.rows.push(this.actionForm());
        });
        this.gembaActionform.get('actionsArray')?.setValue(list);
        this.responsibleArray.length = list.length;
        this.actionDataSource.next(this.rows.controls);
      },
      (error) => {
        this.toaster.error('Error', error.error.Message);
      }
    );
  }


  openEditFilesModal(gembaRow: any): void {
    this.gembaWalkId = gembaRow.gembaWalkId ;
    this.getGembafiles();
    this.dialog.open(this.previewModal, { height: 'auto', width: '90vw' });
  }

  getGembafiles(): void
  {
    const key = {
      GembaId : this.gembaWalkId
    };
    this.previewerData = new MatTableDataSource<any>([]);
    this.gembaService.getGembafiles(key).subscribe(
      (listData: any) => {
        this.previewerData = new MatTableDataSource<any>(listData);
        setTimeout(() => {
          this.previewerData.paginator = this.paginator;
        }, 1000);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  openViewActions(gemba: any): void
  {
    this.getGembaActions(gemba.id);
    this.dialog.open(this.ViewActionDetailModel , { height: 'auto', width: '90%' });
  }

  getGembaActions(id: number): void
  {
    const key = {
      gembaWalkId : this.gembaWalkId
    };
    this.rowDataViewActions = [];
    this.gembaService.getGembaActions2(key).subscribe((res) => {
           this.rowDataViewActions = res;
    });
  }

  removeFile(ele: any): void {
    const key = {
      FileId: ele.Id,
    };
    this.gembaService.deleteDefectFiles(key).subscribe(
      (response: any) => {
        this.toaster.success('Gemba File Delete Successfuly');
        this.getGembafiles();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.Message);
      }
    );
  }

  saveGembaFiles(e: any): void {
    this.files = [];
    for (const iterator of e.target.files) {
      this.files.push(iterator);
    }
    const formdata = new FormData();
    for (const s of this.files) {
      formdata.append('file', s);
    }
    const defect = {
      GembaId: this.gembaWalkId,
    };
    formdata.append('gemba', JSON.stringify(defect));
    this.spinner.show();
    this.gembaService.addGembafiles(formdata).subscribe(
        () => {
          this.spinner.hide();
          this.toaster.success('Gemba Files Added Successfuly');
          this.files = [];
          this.filesForm.controls.file.setValue(null, { emitEvent: false });
          this.getGembafiles();
        },
        (error: any) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
  }

  openFile(elem: any): void
  {
    const array = elem.FileName.split('.');
    this.doc = this.imageUrl + elem.FilePath;
    const fileType =  array[array?.length - 1];
    if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif') {
      this.images = [];
      this.fileType = 'img';
      this.images.push(this.doc);
    } else {
      this.fileType = 'doc';
    }
    this.displayMaximizable = true;
  }

  deleteFile(i: number): void 
  {
    this.files.splice(i , 1);
    this.FileData.splice(i , 1);
  }

  checkChangeClose(event: any): void {
    this.checkedClose = event.checked ;
    if (event.checked === true)
    {
     
      this.closeGembaForm.get('image').clearValidators();
      this.closeGembaForm.get('image').setValidators(null)
      this.closeGembaForm.get('image').updateValueAndValidity();
      this.closeGembaForm.get('remarks').setValidators(Validators.required)
      this.closeGembaForm.get('remarks').updateValueAndValidity();
      this.disableImage = true;
    }
    else
    {
      this.closeGembaForm.get('image').setValidators(Validators.required)
      this.closeGembaForm.get('image').updateValueAndValidity();
      this.closeGembaForm.get('remarks').clearValidators();
      this.closeGembaForm.get('remarks').setValidators(null)
      this.closeGembaForm.get('remarks').updateValueAndValidity();
      this.disableImage = false;
    }
   }

   onFileSelectClose(event: any): void {
    const file = event.target.files[0];
     if (file) {
       this.closeFile = file
       this.checkBoxDisable = true
     } else
    {
      this.checkBoxDisable = false
    }
   }
}

function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}

function GenderCellRenderer(): void { }

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};

const countryCellRenderer = (params: any) => { };

const tooltipValueGetterfunc = (params: any) => {
  console.log(params);
};
