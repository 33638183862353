import { Injectable } from '@angular/core';
/**
 * File used to define const values used for roles check and 
 * Order number Types according to roles
 */
import { 
  rolesForMaterialConsumptionScreen,
  GlassPOType,
  LaminationPOType,
  OfflinePOType,
  GlassStandards
} from 'src/app/@constants/RulesForMaterialConsumptionScreen';

@Injectable({
  providedIn: 'root'
})
export class CommonMethodsForPOService {

  constructor() { }

  //================================ Common Methods For Process Orders ================================//

  /**
   * Funntion Used for Filtering Order Number according to roles
   * return filtered PO list without duplication 
   */
  getFilteredPOList(POList: any,userRolesForPO: any){
    let tempPOList = [];
    if(!userRolesForPO.length)
      tempPOList = POList;
    else{
      if(userRolesForPO.length && userRolesForPO.includes(rolesForMaterialConsumptionScreen.GlassManufacturingRole))
        tempPOList.push(...this.filterOrderListByRoleType(POList,GlassPOType.split(',')))
      if(userRolesForPO.length && userRolesForPO.includes(rolesForMaterialConsumptionScreen.GlassLaminationRole))
        tempPOList.push(...this.filterOrderListByRoleType(POList,LaminationPOType.split(',')))
      if(userRolesForPO.length && userRolesForPO.includes(rolesForMaterialConsumptionScreen.GlassOfflineRole))
        tempPOList.push(...this.filterOrderListByRoleType(POList,OfflinePOType.split(',')))
    }
    tempPOList = tempPOList.length ? tempPOList.sort((index1,index2) => index2.PPDId - index1.PPDId) : [];
    if(tempPOList.length){
      const uniwuePOlist = [];
      for(let listIndex = 0; listIndex < tempPOList.length; listIndex++){
        if(this.POTypeCheck(tempPOList[listIndex]?.PO,LaminationPOType.split(','))
          && tempPOList[listIndex]?.PU_Desc == 'Lamination')
          uniwuePOlist.push(tempPOList[listIndex]);
        if(this.POTypeCheck(tempPOList[listIndex]?.PO,OfflinePOType.split(','))
          && tempPOList[listIndex]?.PU_Desc == 'Offline')
          uniwuePOlist.push(tempPOList[listIndex]);
        if(!this.POTypeCheck(tempPOList[listIndex]?.PO,OfflinePOType.split(','))
          && !this.POTypeCheck(tempPOList[listIndex]?.PO,LaminationPOType.split(','))
          && tempPOList[listIndex]?.PU_Desc !== 'Lamination' && tempPOList[listIndex]?.PU_Desc !== 'Offline')
          uniwuePOlist.push(tempPOList[listIndex]);
      }
      tempPOList = [...new Set(uniwuePOlist)];
      return tempPOList;
    }
    else
      return tempPOList;
  }

  /**
   * Function used for Filtering Order Numbers according to
   * order type allows for specefic role and for validate duplicate orders
   * Return list of Order Number allowed by specific role and without duplication
   */
  filterOrderListByRoleType(POList: Array<any>,orderType: Array<string>){
    const processOrderList = [];
    for(let POindex = 0; POindex < POList.length; POindex++){
      for(let POType = 0; POType < orderType.length; POType++){
        if(POList[POindex].PO.slice(0,2).toLowerCase() == orderType[POType])
          processOrderList.push(POList[POindex]);
      }
    }
    return processOrderList;
  }

  /**
   * Funtion used for checking PO number allowed for specific user
   * retuen true if allow and false if not allowed
   */
  POTypeCheck(OrderNumber: string,OrderType: Array<string>){
    return OrderType.includes(OrderNumber.slice(0,2).toLowerCase())
  }

  /**
   * Funtion used for Calculation of ResultERP value in Manual Production
   * return calculated result according to different roles
   */
  resultERPCalculation(params: any,thicknessList: any): any {
    if(params.ItemCode && params.LiteCountErp && params.ResultValidity && params.OrderNumber){
      const parameters = params.ItemCode.substr(6);
      const length = parameters.substr(3,4);
      const width = parameters.substr(7,4);
      let thickness: any,  resultERP: string,  nominalThick: number = 0;
      thickness = this.thicknessAndSizeCalculation(params,'thickness');
      /**
       * Calculation Method for other than Lamination role
       */
      if(!this.POTypeCheck(params.OrderNumber,LaminationPOType.split(','))){
        if(GlassStandards.JIS == params?.EffUnit.split('-')[0])
          nominalThick = thicknessList.find(x => x.TypeName == thickness).NominalThick;
        else if(GlassStandards.ASTM == params?.EffUnit.split('-')[0])
          nominalThick = thicknessList.find(x => x.TypeName == thickness+' '+GlassStandards.ASTM).NominalThick;
        else
          nominalThick = thicknessList.find(x => x.TypeName == thickness+'('+GlassStandards.EN+')').NominalThick;
        resultERP = ((length*width*nominalThick*2.5)/1000000000).toString();
      }
      /**
       * Calculation Method for Lamination role only
       */
      if(this.POTypeCheck(params.OrderNumber,LaminationPOType.split(','))){
        let thickIndex = 0;
        for(let loopIndex = 0; loopIndex < 2; loopIndex++){
          const thick = thickness.split('.').length == 2 
                          ? (thickness.split('.')[0].length == 4 ? thickness.substr(thickIndex,2) : thickness[loopIndex]) 
                          : thickness.substr(thickIndex,2)
          nominalThick = nominalThick + parseFloat(thicknessList.find(x => x.TypeName == thick).NominalThick);
          thickIndex = 2;
        }
        const PVBSheetsWeight = (0.46 * parseInt(thickness.split('.')[thickness.split('.').length-1]))
        resultERP = (((length*width*nominalThick*2.5)/1000000000) + ((length*width*PVBSheetsWeight)/1000000000)).toString();
      }
      return (parseFloat(resultERP)*params.LiteCountErp);
    }
    else{
      /**
       * return Value updated by user for specific data as once
       * updated by user will not be calculated everytime
       */
      return params.PPD_ID ? params.InvalidResult : ''
    }
  }

  /**
   * Funtion used for Calculation of Thickness and Size from ItemCode
   * return calculated Thickness and Size according to different roles
   */
  thicknessAndSizeCalculation(params: any,returnType: string): any {
    if(params.ItemCode){
      const PO = params?.OrderNumber ? params?.OrderNumber : params?.PO ? params?.PO : ''
      const POType = this.POTypeCheck(PO,LaminationPOType.split(','));
      if(returnType == 'thickness'){
        /**
         * return Thickness Value according to role
         */
        let thickness
        if(POType){
          let combinaotionOfThickness = '';
          thickness = params.ItemCode.substr(17,4);
          for(let noOfThickness = 0; noOfThickness < Math.round(thickness.length/2); noOfThickness++){
            if(noOfThickness == 0){
              combinaotionOfThickness = Number(thickness.substr(noOfThickness,2)).toString();
            }
            else{
              combinaotionOfThickness = combinaotionOfThickness + Number(thickness.substr(noOfThickness + 1,2)).toString();
            }
          }
          const noOfPVBSheets = Math.round(parseFloat(params.ItemCode.substr(3,1) +'.'+ params.ItemCode.substr(4,2))/0.38);
          thickness = combinaotionOfThickness +'.'+ noOfPVBSheets;
        }
        else{
          thickness = Number(params.ItemCode.substr(6,3)).toString();
          if(thickness[thickness.length-1] == '0'){
            thickness = thickness.slice(0,-1);
          }
          else{
            thickness = thickness[0]+'.'+thickness[1];
          }
        }
        return thickness;
      }
      else{
        /**
         * Return Size Value same logic for all roles
         */
        return params.ItemCode.substr(9,4) + ' X ' +params.ItemCode.substr(13,4);
      }
    }
    else{
      return ''
    }
  }

  /**
   * Function Used for Calculation of SQM calculation for Barcode
   * generated for Lamination role only
   */
  resultSQMTCalculation(params: any){
    if(params.ItemCode && params.LiteCountErp){
      const length = params.ItemCode.substr(9,4);
      const width = params.ItemCode.substr(13,4);
      return ((parseInt(length)*parseInt(width)*parseInt(params.LiteCountErp))/1000000);
    }
  }
}