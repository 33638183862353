import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { AreaService } from 'src/app/services/area.service';
import { Chart } from 'angular-highcharts';
import * as  Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
More(Highcharts);
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
import { ThemePalette } from '@angular/material/core';
import { ProcessConfirmationService } from 'src/app/services/process-confirmation.service';
import * as _moment from 'moment';
import { Router } from '@angular/router';
import { MeetingMatrixService } from 'src/app/services/meeting-matrix.service';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
// Initialize exporting module.
Exporting(Highcharts);
const moment = _moment;
@Component({
  selector: 'app-process-confirmation-db',
  templateUrl: './process-confirmation-db.component.html',
  styleUrls: ['./process-confirmation-db.component.scss']
})
export class ProcessConfirmationDbComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ElementRef }) container: ElementRef | undefined;
  ProcessConfirmationDbForm!: FormGroup;
  groupListDDL!: any[];
  filterdplants!: any[];
  areaDDL!: any[];
  options: Highcharts.Options = {};
  chartArrayAreaOne: any;
  chartArrayAreaTwo: any;
  chartOneArr: any = [];
  FromTimePicker: any;
  ToTimePicker: any;
  chartTwoArr: any = [];
  chartThreeArr: any = [];
  dateChart: any;
  meetingsChart: any;
  breadcrumList: any;
  dataArray!: any[];
  columnDefs!: any;
  moduleId!: any;
  monthfirstDay = new Date(new Date().getFullYear(), new Date().getMonth());
pageId!:any;
  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body')
  };
  gridApi!: any;
  gridColumnApi!: any;
  meetingsData: any;
  subscription!: Subscription;
  meetingDrillChart!: Chart;
  part2: any;

  constructor(
    private processConfirmation: ProcessConfirmationService, 
    public router: Router,
    private spinner: NgxSpinnerService,
    private meetingSaturation: MeetingMatrixService,
    // tslint:disable-next-line:max-line-length
    private fb: FormBuilder, 
    private areaService: AreaService, 
    private machineService: MachineMappingService,
    private usermanagementService: UserManagementService, 
    private toaster: ToastrService
    ) {
  }
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  chart: any;
  userChart: any;

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.columnDefs = [{ field: 'EG_Desc', headerName: 'Group' }, { field: 'PT_Desc', headerName: 'Plant' },
    { field: 'AreaName', headerName: 'Area Name' }, { field: 'Category', headerName: 'Audit Category' },
    { field: 'name', headerName: 'Complaince/Non-Complaince' }, { field: 'NoOfExecution', headerName: 'No of Execution' }];
    this.GetBreadcrumList();
    this.getGroupData();
    //this.getLangWords();
    this.ProcessConfirmationDbForm = this.fb.group({
      area: [''],
      plant: [''],
      group: [''],
      // dateStart: [''],
      // dateEnd: ['']
      datePicker:['']
    });
    this.ProcessConfirmationDbForm.get('datePicker')?.setValue([
      this.monthfirstDay,
      new Date(),
    ]);
    //const start = new Date();
    //this.FromTimePicker = new Date(start.setHours(0, 0, 0, 0));
    //this.ToTimePicker = new Date();
  }

  getGroupData(): void {
    const key = {
      Id: 0
    };
    // tslint:disable-next-line: no-debugger
    this.usermanagementService.getGroupData(key).subscribe((data: any) => {
      this.groupListDDL = data.masterList;
      this.ProcessConfirmationDbForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
      this.getPlantByGroup();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.ProcessConfirmationDbForm.value.group
    };
    // this.MachineId = null;
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.filterdplants = data.DepartList;
      this.ProcessConfirmationDbForm.get('plant')?.setValue(this.filterdplants[0].DeptId);
      // this.audicategories = [];
      this.areaDDL = [];
      this.getAreaByPlant();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getAreaByPlant(): void {
    // tslint:disable-next-line:no-debugger
    debugger;
    const plant = this.ProcessConfirmationDbForm.get('plant')?.value;
    const key = {
      plantId: plant
    };
    this.areaService.getMasterArea(key).subscribe((data) => {
      this.areaDDL = data;
      this.chartOneArr = [];
      this.chartTwoArr = [];
      this.chartThreeArr = [];
      this.ProcessConfirmationDbForm.get('area')?.setValue('ALL');
      this.onToProcessDate();
      // this.chartOne();
      // this.getNoOfUsersAssignedToMeeting();
      // this.meetingsDrillDown();
    },
      (error) => {
        this.toaster.error(error.error.message);
      });
  }
  processConfirmationByArea1(key: any): void {

    this.processConfirmation.processConfirmationByArea1(key).subscribe((response: any) => {
      const res = JSON.parse(response[0].chart1data);
      this.chartArrayAreaOne = res.Part1 ? res.Part1 : [];
      this.chartArrayAreaTwo = res.Part2 ? res.part2 : [];
      const seriesArrChartOne = [...new Set(this.chartArrayAreaOne.map((c: any) => c.name))];
      seriesArrChartOne.forEach((element: any) => {
        this.chartOneArr.push({ name: 'Category', type: 'column', id: element, color: '#0feeee', data: [] });
      });
      if(res.part2){
        res.Part2.forEach((a1: any) => {
          this.chartOneArr.forEach((a2: any) => {
            if (a1.id === a2.id) {
              a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
            }
          });
        });
        const seriesArrChartTwo = [...new Set(this.chartArrayAreaTwo.map((c: any) => c.drilldown))];
        seriesArrChartTwo.forEach((element: any) => {
          this.chartOneArr.push({ name: 'Compliance/Non-Compliance', type: 'column', id: element, data: [] });
        });
      } 
      if(res.Part3){
        res.Part3.forEach((a1: any) => {
          this.chartOneArr.forEach((a2: any) => {
            if (a1.id === a2.id) {
              a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
            }
          });
        });
      }
      // this.processConfirmationByArea2();
      this.chart = new Chart({
        // Created pie chart using Highchart
        chart: {
          type: 'column',
          options3d: {
            enabled: true,
            alpha: 45
          }
        },
        title: {
          text: 'Areawise Drilldown'
        },
        xAxis: {
          type: 'category'
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b> of total<br/>'
        },
        series: [
          {
            name: 'Area',
            type: 'column',
            data: this.chartArrayAreaOne
          }
        ],
        drilldown: {
          series: this.chartOneArr
        }
      });
      this.processConfirmationByUsers(key);
    },
      (error) => {
        this.toaster.error(error.error.Message);
      });
  }
  processConfirmationByUsers(key: any): void {
    this.processConfirmation.processConfirmationByUsers(key).subscribe((response: any) => {
      const res = JSON.parse(response[0].chart1data);
      // this.chartArrayAreaOne = res.Part1;
      // this.chartArrayAreaTwo = res.Part2;
      if(res.Part1){
        const seriesArrChartOne = [...new Set(res.Part1.map((c: any) => c.name))];
        seriesArrChartOne.forEach((element: any) => {
          this.chartTwoArr.push({ name: 'Category', type: 'column', id: element, color: '#0feeee', data: [] });
        });
      }
      if(res.part2){
        res.Part2.forEach((a1: any) => {
          this.chartTwoArr.forEach((a2: any) => {
            if (a1.id === a2.id) {
              a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
            }
          });
        });
        const seriesArrChartTwo = [...new Set(res.Part2.map((c: any) => c.drilldown))];
        seriesArrChartTwo.forEach((element: any) => {
          this.chartTwoArr.push({ name: 'Compliance/Non-Compliance', type: 'column', id: element, data: [] });
        });
      }
      if(res.Part3){
        res.Part3.forEach((a1: any) => {
          this.chartTwoArr.forEach((a2: any) => {
            if (a1.id === a2.id) {
              a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
            }
          });
        });
      }
      this.userChart = new Chart({
        // Created pie chart using Highchart
        chart: {
          type: 'column',
          options3d: {
            enabled: true,
            alpha: 45
          }
        },
        title: {
          text: 'Userwise Drilldown'
        },
        xAxis: {
          type: 'category'
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b> of total<br/>'
        },
        series: [
          {
            name: 'Users',
            type: 'column',
            data: res.Part1
          }
        ],
        drilldown: {
          series: this.chartTwoArr
        }
      });
      this.processConfirmationByDate(key);

    },
      (error) => {
        this.toaster.error(error.error.message);
      });
  }
  processConfirmationByDate(key: any): void {
    this.processConfirmation.processConfirmationByDate(key).subscribe((response: any) => {
      const res = JSON.parse(response[0].chart1data);
      // this.chartArrayAreaOne = res.Part1;
      // this.chartArrayAreaTwo = res.Part2;
      if(res.Part1){
        const seriesArrChartOne = [...new Set(res.Part1.map((c: any) => c.name))];
        seriesArrChartOne.forEach((element: any) => {
          this.chartThreeArr.push({ name: 'Area', type: 'column', id: element, color: '#0feeee', data: [] });
        });
      }
      if(res.part2){
        res.Part2.forEach((a1: any) => {
          this.chartThreeArr.forEach((a2: any) => {
            if (a1.id === a2.id) {
              a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
            }
          });
        });
        const seriesArrChartTwo = [...new Set(res.Part2.map((c: any) => c.drilldown))];
        seriesArrChartTwo.forEach((element: any) => {
          this.chartThreeArr.push({ name: 'Category', type: 'column', id: element, data: [] });
        });
      }
      if(res.Part3){
        res.Part3.forEach((a1: any) => {
          this.chartThreeArr.forEach((a2: any) => {
            if (a1.id === a2.id) {
              a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
            }
          });
        });
      }
      this.dateChart = new Chart({
        // Created pie chart using Highchart
        chart: {
          type: 'column',
          options3d: {
            enabled: true,
            alpha: 45
          }
        },
        title: {
          text: 'Date Chart'
        },
        xAxis: {
          type: 'category'
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45
          },
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b> of total<br/>'
        },
        series: [
          {
            name: 'Dates',
            type: 'column',
            data: res.Part1
          }
        ],
        drilldown: {
          series: this.chartThreeArr
        }
      });
    },
      (error: any) => {
        this.toaster.error(error.error.message);
      });
  }

  clearCharts(): void {
    if (this.chart) {
      this.chart.destroy();
    }
    if (this.userChart) {
      this.userChart.destroy();
    }
    if (this.dateChart) {
      this.dateChart.destroy();
    }
  }
  chartOne(): void {
    this.clearCharts();
    const key = {
      // tslint:disable-next-line:max-line-length
      areaId: this.ProcessConfirmationDbForm.value.area === 'ALL' ? this.areaDDL.map(c => c.areaId).toString() : this.ProcessConfirmationDbForm.value.area,
      //startDate: this.ProcessConfirmationDbForm.value.dateStart,
      //endDate: this.ProcessConfirmationDbForm.value.dateEnd,
      startDate: this.ProcessConfirmationDbForm.get('datePicker')?.value[0],
      endDate: this.ProcessConfirmationDbForm.get('datePicker')?.value[1],
    };
    this.processConfirmationByArea1(key);
    this.GetProcessConfirmationExportData(key);
  }
  onProcessDate(): any {
    this.chartOne();
  }
  onToProcessDate(): any {
    this.chartOne();
  }
 //menu and breadcrumb
 GetBreadcrumList(): any {
  const obj = {
    MenuId:
      localStorage.getItem('O3RightMenuId') === null
        ? 1
        : localStorage.getItem('O3RightMenuId'),
  };
  // tslint:disable-next-line: align
  this.usermanagementService.GetBreadcrumList(obj).subscribe(
    (data: any) => {
      this.breadcrumList = data.BreadcrumList;
      this.moduleId = this.breadcrumList[0].id;
      this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
    },
    (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    }
  );
}
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onBtnExportCSV(): void {
    this.gridOptions.api.exportDataAsCsv();
  }

  onBtnExportExcel(): void {
    this.gridOptions.api.exportDataAsExcel();
  }

  GetProcessConfirmationExportData(key: any): void {
    this.processConfirmation.GetProcessConfirmationExportData(key).subscribe((res: any) => {
      this.dataArray = res;
    });
  }

}
