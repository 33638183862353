<div>
  <div class="content-top-box">
    <ul class="breadcrumb" *ngIf="!data?.showWrapper2">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a
          class="breadcrumb-link"
          [routerLink]="breadcrumItem.Url"
          [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
          >{{breadcrumItem.text}}</a
        >
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">
        Immediate Action
      </h1>
    </div>
  </div>

  <div class="content-wrapper">
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      template=" <img src='assets/images/spinner.gif' />"
    ></ngx-spinner>
    <div id="wrapper1">
      <div class="wrapper-box wrapper-box-shadow grid-container">
        <form [formGroup]="filterForm">
          <div class="content-top-row">
            <mat-form-field
              appearence="fill"
              style="width: 22%"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <mat-select
                ngxMatSelect
                [hasSearchBox]="true"
                [useMobileView]="true"
                mobileViewType="BottomSheet"
                (selectionChange)="onChangeGroup()"
                [formControlName]="'group'"
                [multiple]="false"
                name="TypeName"
                [source]="groups"
                [displayMember]="'TypeName'"
                placeholder="Select Group"
                [valueMember]="'TypeId'"
                #sf="ngxMatSelect"
              >
                <mat-option
                  [value]="group.TypeId"
                  *ngFor="let group of sf.filteredSource"
                >
                  {{ group.TypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              appearence="fill"
              style="width: 22%"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <mat-select
                ngxMatSelect
                [hasSearchBox]="true"
                [useMobileView]="true"
                mobileViewType="BottomSheet"
                (selectionChange)="onChangePlant($event)"
                [formControlName]="'plant'"
                [multiple]="false"
                name="DeptDesc"
                [source]="plants"
                [displayMember]="'DeptDesc'"
                placeholder="Select Plant"
                [valueMember]="'DeptId'"
                #sfplant="ngxMatSelect"
              >
                <mat-option
                  [value]="plant.DeptId"
                  *ngFor="let plant of sfplant.filteredSource"
                >
                  {{ plant.DeptDesc }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              appearence="fill"
              style="width: 22%"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <mat-select
                ngxMatSelect
                [hasSearchBox]="true"
                [useMobileView]="true"
                mobileViewType="BottomSheet"
                (selectionChange)="onChangeLine($event)"
                [formControlName]="'line'"
                [multiple]="false"
                name="plDesc"
                [source]="Lines"
                [displayMember]="'plDesc'"
                placeholder="Select Line"
                [valueMember]="'plId'"
                #sfline="ngxMatSelect"
              >
                <mat-option [value]="0">Other</mat-option>
                <mat-option
                  [value]="line.plId"
                  *ngFor="let line of sfline.filteredSource"
                >
                  {{ line.plDesc }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              appearence="fill"
              style="width: 22%"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            >
              <mat-select
                ngxMatSelect
                [hasSearchBox]="true"
                [useMobileView]="true"
                mobileViewType="BottomSheet"
                [formControlName]="'machine'"
                [multiple]="false"
                name="puDesc"
                [source]="Machines"
                [displayMember]="'puDesc'"
                placeholder="Select Machine"
                [valueMember]="'puId'"
                (selectionChange)="onChangeMachine($event)"
                #sfmachine="ngxMatSelect"
              >
                <mat-option *ngIf="lineOtherSelected" value="0">Other</mat-option>
                <div *ngIf="!lineOtherSelected">
                  <mat-option 
                    [value]="machine.puId"
                    *ngFor="let machine of sfmachine.filteredSource"
                  >
                    {{ machine.puDesc }}
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>

            <!-- <div class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <label class="floating-label">{{langObj ? langObj?.DateRange : ('DateRange' | translate)}}</label>
              <input type="text" matInput ngxDaterangepickerMd formControlName="datePicker" [timePicker]="true"
                [timePicker24Hour]="true" [showDropdowns]="true" [closeOnAutoApply]="true"
                [locale]="{format: 'DD/MM/YYYY HH:mm'}" [autoApply]="true" [readonly]="true" />
            </div> -->
          </div>
          <div class="content-row-wrap">
            <div class="inputField" style="width: 20%; margin-left: 25px">
              <nz-date-picker
                class="calender-input"
                style="margin-left: 0px !important; width: 100%"
                id="startDate"
                formControlName="start"
                nzFormat="dd/MM/yyyy"
                [nzInputReadOnly]="true"
              ></nz-date-picker>
              <span class="floating-text">Start Date</span>
            </div>

            <div class="inputField" style="width: 20%; margin-left: 25px">
              <nz-date-picker
                class="calender-input"
                style="margin-left: 0px !important; width: 100%"
                id="endDate"
                formControlName="end"
                nzFormat="dd/MM/yyyy"
                [nzInputReadOnly]="true"
              ></nz-date-picker>
              <span class="floating-text">End Date}</span>
            </div>
            <button
              mat-mini-fab
              mat-button
              class="search-icon-btn"
              (click)="searchImmediateActions()"
              pTooltip="Filter"
              tooltipPosition="top"
              style="margin-left: 20px; margin-top: 10px"
            >
              <i class="fa fa-search" style="color: #fff"></i>
            </button>
          </div>
        </form>
        <ag-grid-angular
          id="wrapper1grid"
          style="width: 100%; margin-top: 5px"
          class="ag-theme-alpine"
          [gridOptions]="gridOptions3"
          [columnDefs]="immediatecolumnDefs"
          [rowData]="immediaterowData"
          [rowHeight]="rowHeight"
          (gridReady)="onGridReady($event)"
          (cellClicked)="oncellclicked($event)"
          [pagination]="true"
          [paginationPageSize]="5"
        >
        </ag-grid-angular>
      </div>
    </div>
    <div [hidden]="!showWrapper2" id="wrapper2">
      <div class="d-flex justify-content-end">
        <div class="float:right;">
          <button
            mat-mini-fab
            mat-button
            color="warn"
            mat-dialog-close
            pTooltip="Close"
            tooltipPosition="top"
            *ngIf="data?.showWrapper2"
          >
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
        <button
          type="button"
          (click)="toggleDisplayreverse()"
          mat-flat-button
          color="accent"
          class="back-button"
          style="margin-left: 14px"
          *ngIf="!data?.showWrapper2"
        >
          <p class="btn-text">
            <i
              class="fa fa-arrow-left"
              style="color: #fff; margin-right: 9px"
            ></i
            >Previous
          </p>
        </button>
      </div>
      <div class="wrapper-box wrapper-box-shadow grid-container">
        <div class="tabs_wrapper">
          <div class="tabs">
            <button
              id="DetailNotes"
              mat-button
              (click)="CheckClickedMenu('DetailNotes')"
              color="primary"
            >
              <img src="../../../assets/images/DetailNotes.png" /><span
                style="margin-left: 5px"
                >Detail Notes</span
              >
            </button>
            <button
              *ngFor="let a of ncrActionData"
              id="{{ a.name }}"
              mat-button
              (click)="CheckClickedMenu(a.name)"
              color="primary"
              [class.active]="data?.showWrapper2 && a.name == 'Sorting'"
            >
              <img src="../../../assets/images/{{ a.name }}.png" /><span
                style="margin-left: 5px"
                >{{ a.name }}</span
              >
            </button>
          </div>
          <div class="tabs-views">
            <div *ngIf="defaulttab">
              <h2>This Plant Does not have Access</h2>
            </div>

            <div *ngIf="detailNotestab">
              <form [formGroup]="detailNotesForm">
                <div class="content-top-row">
                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                    style="width: 50%"
                  >
                    <mat-label class="required">Detail</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="detail"
                      required
                    />
                  </mat-form-field>
                  <button
                    style="margin-top: 4px; margin-left: 10px"
                    type="submit"
                    mat-mini-fab
                    mat-button
                    color="primary"
                    pTooltip="Save"
                    tooltipPosition="top"
                    (click)="submitDetailNotesEntry()"
                  >
                    <mat-icon style="color: #fff">save</mat-icon>
                  </button>
                </div>
              </form>
              <ag-grid-angular
                id="detailNotesGrid"
                style="width: 100%; height: 20vw; margin-top: 10px"
                class="ag-theme-alpine"
                (gridReady)="onDetailNotesGridReady($event)"
                [gridOptions]="detailNotesGridOptions"
                [suppressRowTransform]="true"
                [columnDefs]="columnDefs"
                [rowData]="immediateDetailNotes"
                (cellClicked)="ondetailNoteseditcellclicked($event)"
                [pagination]="true"
                [paginationPageSize]="5"
              >
              </ag-grid-angular>
            </div>

            <div *ngIf="sortingtab">
              <form [formGroup]="sortingSearchForm">
                <div class="content-top-row">
                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'department'"
                      [multiple]="false"
                      name="TypeName"
                      [source]="departmentList"
                      [displayMember]="'TypeName'"
                      placeholder="Select Department"
                      [valueMember]="'TypeId'"
                      #sfDept="ngxMatSelect"
                      (selectionChange)="SearchImmediateSortActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let dep of sfDept.filteredSource"
                        [value]="dep.TypeId"
                      >
                        {{ dep.TypeName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'teamlead'"
                      [multiple]="false"
                      name="fullName"
                      [source]="UsersList"
                      [displayMember]="'fullName'"
                      placeholder="Select Team Lead"
                      [valueMember]="'EmpInfo_Id'"
                      #sfUser="ngxMatSelect"
                      (selectionChange)="SearchImmediateSortActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let usel of sfUser.filteredSource"
                        [value]="usel.EmpInfo_Id"
                      >
                        {{ usel.fullName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'team'"
                      [multiple]="true"
                      name="fullName"
                      [source]="UsersList"
                      [displayMember]="'fullName'"
                      placeholder="Select Team"
                      [valueMember]="'EmpInfo_Id'"
                      #msUser="ngxMatSelect"
                      (selectionChange)="SearchImmediateSortAction()"
                      #SelectAll
                    >
                      <div class="Select-all selectAllItems">
                        <mat-checkbox
                          [(ngModel)]="allSelected"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="toggleAllSelection()"
                          >Select All</mat-checkbox
                        >
                      </div>
                      <mat-option
                        *ngFor="let useli of msUser.filteredSource"
                        [value]="useli.EmpInfo_Id"
                      >
                        {{ useli.fullName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'uom'"
                      [multiple]="false"
                      name="uomDesc"
                      [source]="Uomms"
                      [displayMember]="'uomDesc'"
                      placeholder="Select UOM"
                      [valueMember]="'uomId'"
                      #sfUom="ngxMatSelect"
                      (selectionChange)="SearchImmediateSortActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let uoms of sfUom.filteredSource"
                        [value]="uoms.uomId"
                        >{{ uoms.uomDesc }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <button
                    class="add-btn"
                    (click)="openSortingModal()"
                    pTooltip="Add"
                    tooltipPosition="top"
                    style="margin-top: 5px"
                  >
                    <img
                      src="assets/images/add.png"
                      alt=""
                      width="20px"
                      height="20px"
                    />
                  </button>
                </div>
              </form>
              <ag-grid-angular
                id="wrapper2grid"
                style="width: 100%; height: 20vw; margin-top: 10px"
                class="ag-theme-alpine"
                (gridReady)="onSortGridReady($event)"
                [gridOptions]="gridOptions"
                [suppressRowTransform]="true"
                [columnDefs]="columnDefs"
                [rowData]="immediatesortactions"
                [pagination]="true"
                [paginationPageSize]="5"
                (cellClicked)="onsorteditcellclicked($event)"
                (cellValueChanged)="onCellValueChanged1($event)"
              >
              </ag-grid-angular>
            </div>

            <div *ngIf="processtab">
              <form [formGroup]="processSearchForm">
                <div class="content-top-row">
                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'department'"
                      [multiple]="false"
                      name="TypeName"
                      [source]="departmentList"
                      [displayMember]="'TypeName'"
                      placeholder="Select Department"
                      [valueMember]="'TypeId'"
                      #sfProcessDept="ngxMatSelect"
                      (selectionChange)="SearchImmediateReProcessActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let dep of sfProcessDept.filteredSource"
                        [value]="dep.TypeId"
                      >
                        {{ dep.TypeName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'teamlead'"
                      [multiple]="false"
                      name="fullName"
                      [source]="UsersList"
                      [displayMember]="'fullName'"
                      placeholder="Select Team Lead"
                      [valueMember]="'EmpInfo_Id'"
                      #sfProcessUser="ngxMatSelect"
                      (selectionChange)="SearchImmediateReProcessActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let usel of sfProcessUser.filteredSource"
                        [value]="usel.EmpInfo_Id"
                      >
                        {{ usel.fullName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'team'"
                      [multiple]="true"
                      name="fullName"
                      [source]="UsersList"
                      [displayMember]="'fullName'"
                      placeholder="Select Team"
                      [valueMember]="'EmpInfo_Id'"
                      #msProcessUser="ngxMatSelect"
                      (selectionChange)="SearchImmediateReProcessAction()"
                      #SelectAllProcess
                    >
                      <div class="Select-all selectAllItems">
                        <mat-checkbox
                          [(ngModel)]="allSelected2"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="toggleAllSelection2()"
                          >Select All</mat-checkbox
                        >
                      </div>
                      <mat-option
                        *ngFor="let useli of msProcessUser.filteredSource"
                        [value]="useli.EmpInfo_Id"
                      >
                        {{ useli.fullName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'uom'"
                      [multiple]="false"
                      name="uomDesc"
                      [source]="Uomms"
                      [displayMember]="'uomDesc'"
                      placeholder="Select UOM"
                      [valueMember]="'uomId'"
                      #sfProcessUom="ngxMatSelect"
                      (selectionChange)="SearchImmediateReProcessActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let uoms of sfProcessUom.filteredSource"
                        [value]="uoms.uomId"
                        >{{ uoms.uomDesc }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    class="add-btn"
                    (click)="openProcessModal()"
                    pTooltip="Add"
                    tooltipPosition="top"
                    style="margin-top: 5px"
                  >
                    <img
                      src="assets/images/add.png"
                      alt=""
                      width="20px"
                      height="20px"
                    />
                  </button>
                </div>
              </form>
              <ag-grid-angular
                id="wrapper3grid"
                style="width: 100%; height: 20vw; margin-top: 10px"
                class="ag-theme-alpine"
                (gridReady)="onProcessGridReady($event)"
                [gridOptions]="gridOptions2"
                [suppressRowTransform]="true"
                [columnDefs]="columnDefs"
                [rowData]="immediateprocessactions"
                [pagination]="true"
                [paginationPageSize]="5"
                (cellClicked)="onprocesseditcellclicked($event)"
                (cellValueChanged)="onCellValueChanged2($event)"
              >
              </ag-grid-angular>
            </div>

            <div *ngIf="concessiontab">
              <form [formGroup]="concessionSearchForm">
                <div class="content-top-row">
                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'department'"
                      [multiple]="false"
                      name="TypeName"
                      [source]="departmentList"
                      [displayMember]="'TypeName'"
                      placeholder="Select Department"
                      [valueMember]="'TypeId'"
                      #sfConcessionDept="ngxMatSelect"
                      (selectionChange)="SearchImmediateConcessionActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let dep of sfConcessionDept.filteredSource"
                        [value]="dep.TypeId"
                      >
                        {{ dep.TypeName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'teamlead'"
                      [multiple]="false"
                      name="fullName"
                      [source]="UsersList"
                      [displayMember]="'fullName'"
                      placeholder="Select Team Lead"
                      [valueMember]="'EmpInfo_Id'"
                      #sfConcessionUser="ngxMatSelect"
                      (selectionChange)="SearchImmediateConcessionActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let usel of sfConcessionUser.filteredSource"
                        [value]="usel.EmpInfo_Id"
                      >
                        {{ usel.fullName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'team'"
                      [multiple]="true"
                      name="fullName"
                      [source]="UsersList"
                      [displayMember]="'fullName'"
                      placeholder="Select Team"
                      [valueMember]="'EmpInfo_Id'"
                      #msConcessionUser="ngxMatSelect"
                      (selectionChange)="SearchImmediateConcessionAction()"
                      #SelectAllConcession
                    >
                      <div class="Select-all selectAllItems">
                        <mat-checkbox
                          [(ngModel)]="allSelected3"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="toggleAllSelection3()"
                          >Select All</mat-checkbox
                        >
                      </div>
                      <mat-option
                        *ngFor="let useli of msConcessionUser.filteredSource"
                        [value]="useli.EmpInfo_Id"
                      >
                        {{ useli.fullName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'uom'"
                      [multiple]="false"
                      name="uomDesc"
                      [source]="Uomms"
                      [displayMember]="'uomDesc'"
                      placeholder="Select UOM"
                      [valueMember]="'uomId'"
                      #sfConcessionUom="ngxMatSelect"
                      (selectionChange)="SearchImmediateConcessionActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let uoms of sfConcessionUom.filteredSource"
                        [value]="uoms.uomId"
                      >
                        {{ uoms.uomDesc }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    class="add-btn"
                    (click)="openConcessionModal()"
                    pTooltip="Add"
                    tooltipPosition="top"
                    style="margin-top: 5px"
                  >
                    <img
                      src="assets/images/add.png"
                      alt=""
                      width="20px"
                      height="20px"
                    />
                  </button>
                </div>
              </form>
              <ag-grid-angular
                id="wrapper3grid"
                style="width: 100%; height: 20vw; margin-top: 10px"
                class="ag-theme-alpine"
                (gridReady)="onProcessGridReady($event)"
                [gridOptions]="gridOptions2"
                [suppressRowTransform]="true"
                [columnDefs]="columnDefs"
                [rowData]="immediateconcessionactions"
                [pagination]="true"
                [paginationPageSize]="5"
                (cellClicked)="onconcessioneditcellclicked($event)"
                (cellValueChanged)="onCellValueChanged3($event)"
              >
              </ag-grid-angular>
            </div>

            <div *ngIf="downgradetab">
              <form [formGroup]="downgradeSearchForm">
                <div class="content-top-row">
                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'department'"
                      [multiple]="false"
                      name="TypeName"
                      [source]="departmentList"
                      [displayMember]="'TypeName'"
                      placeholder="Select Department"
                      [valueMember]="'TypeId'"
                      #sfDowngradeDept="ngxMatSelect"
                      (selectionChange)="SearchImmediateDowngradeActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let dep of sfDowngradeDept.filteredSource"
                        [value]="dep.TypeId"
                      >
                        {{ dep.TypeName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'teamlead'"
                      [multiple]="false"
                      name="fullName"
                      [source]="UsersList"
                      [displayMember]="'fullName'"
                      placeholder="Select Team Lead"
                      [valueMember]="'EmpInfo_Id'"
                      #sfDowngradeUser="ngxMatSelect"
                      (selectionChange)="SearchImmediateDowngradeActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let usel of sfDowngradeUser.filteredSource"
                        [value]="usel.EmpInfo_Id"
                      >
                        {{ usel.fullName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'team'"
                      [multiple]="true"
                      name="fullName"
                      [source]="UsersList"
                      [displayMember]="'fullName'"
                      placeholder="Select Team"
                      [valueMember]="'EmpInfo_Id'"
                      #msDowngradeUser="ngxMatSelect"
                      (selectionChange)="SearchImmediateDowngradeAction()"
                      #SelectAllDowngrade
                    >
                      <div class="Select-all selectAllItems">
                        <mat-checkbox
                          [(ngModel)]="allSelected4"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="toggleAllSelection4()"
                          >Select All</mat-checkbox
                        >
                      </div>
                      <mat-option
                        *ngFor="let useli of msDowngradeUser.filteredSource"
                        [value]="useli.EmpInfo_Id"
                      >
                        {{ useli.fullName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'uom'"
                      [multiple]="false"
                      name="uomDesc"
                      [source]="Uomms"
                      [displayMember]="'uomDesc'"
                      placeholder="Select UOM"
                      [valueMember]="'uomId'"
                      #sfDowngradeUom="ngxMatSelect"
                      (selectionChange)="SearchImmediateDowngradeActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let uoms of sfDowngradeUom.filteredSource"
                        [value]="uoms.uomId"
                      >
                        {{ uoms.uomDesc }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    class="add-btn"
                    (click)="openDowngradeModal()"
                    pTooltip="Add"
                    tooltipPosition="top"
                    style="margin-top: 5px"
                  >
                    <img
                      src="assets/images/add.png"
                      alt=""
                      width="20px"
                      height="20px"
                    />
                  </button>
                </div>
              </form>
              <ag-grid-angular
                id="wrapper3grid"
                style="width: 100%; height: 20vw; margin-top: 10px"
                class="ag-theme-alpine"
                (gridReady)="onProcessGridReady($event)"
                [gridOptions]="gridOptions2"
                [suppressRowTransform]="true"
                [columnDefs]="columnDefs"
                [rowData]="immediatedowngradeactions"
                [pagination]="true"
                [paginationPageSize]="5"
                (cellClicked)="ondowngradeeditcellclicked($event)"
                (cellValueChanged)="onCellValueChanged4($event)"
              >
              </ag-grid-angular>
            </div>

            <div *ngIf="disposetab">
              <form [formGroup]="disposeSearchForm">
                <div class="content-top-row">
                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'department'"
                      [multiple]="false"
                      name="TypeName"
                      [source]="departmentList"
                      [displayMember]="'TypeName'"
                      placeholder="Select Department"
                      [valueMember]="'TypeId'"
                      #sfDisposeDept="ngxMatSelect"
                      (selectionChange)="SearchImmediateDisposeActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let dep of sfDisposeDept.filteredSource"
                        [value]="dep.TypeId"
                      >
                        {{ dep.TypeName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'teamlead'"
                      [multiple]="false"
                      name="fullName"
                      [source]="UsersList"
                      [displayMember]="'fullName'"
                      placeholder="Select Team Lead"
                      [valueMember]="'EmpInfo_Id'"
                      #sfDisposeUser="ngxMatSelect"
                      (selectionChange)="SearchImmediateDisposeActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let usel of sfDisposeUser.filteredSource"
                        [value]="usel.EmpInfo_Id"
                      >
                        {{ usel.fullName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'team'"
                      [multiple]="true"
                      name="fullName"
                      [source]="UsersList"
                      [displayMember]="'fullName'"
                      placeholder="Select Team"
                      [valueMember]="'EmpInfo_Id'"
                      #msDisposeUser="ngxMatSelect"
                      (selectionChange)="SearchImmediateDisposeAction()"
                      #SelectAllDispose
                    >
                      <div class="Select-all selectAllItems">
                        <mat-checkbox
                          [(ngModel)]="allSelected5"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="toggleAllSelection5()"
                          >Select All</mat-checkbox
                        >
                      </div>
                      <mat-option
                        *ngFor="let useli of msDisposeUser.filteredSource"
                        [value]="useli.EmpInfo_Id"
                      >
                        {{ useli.fullName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  >
                    <mat-select
                      ngxMatSelect
                      [hasSearchBox]="true"
                      [useMobileView]="true"
                      mobileViewType="BottomSheet"
                      [formControlName]="'uom'"
                      [multiple]="false"
                      name="uomDesc"
                      [source]="Uomms"
                      [displayMember]="'uomDesc'"
                      placeholder="Select UOM"
                      [valueMember]="'uomId'"
                      #sfDisposeUom="ngxMatSelect"
                      (selectionChange)="SearchImmediateDisposeActions()"
                    >
                      <mat-option [value]="'0'">Select All</mat-option>
                      <mat-option
                        *ngFor="let uoms of sfDisposeUom.filteredSource"
                        [value]="uoms.uomId"
                        >{{ uoms.uomDesc }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    class="add-btn"
                    (click)="openDisposeModal()"
                    pTooltip="Add"
                    tooltipPosition="top"
                    style="margin-top: 5px"
                  >
                    <img
                      src="assets/images/add.png"
                      alt=""
                      width="20px"
                      height="20px"
                    />
                  </button>
                </div>
              </form>
              <ag-grid-angular
                id="wrapper3grid"
                style="width: 100%; height: 20vw; margin-top: 10px"
                class="ag-theme-alpine"
                (gridReady)="onProcessGridReady($event)"
                [gridOptions]="gridOptions2"
                [suppressRowTransform]="true"
                [columnDefs]="columnDefs"
                [rowData]="immediatedisposeactions"
                [pagination]="true"
                [paginationPageSize]="5"
                (cellClicked)="ondisposeeditcellclicked($event)"
                (cellValueChanged)="onCellValueChanged5($event)"
              >
              </ag-grid-angular>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #displayAddSortModal>
    <form [formGroup]="sortingForm">
      <div mat-dialog-title style="margin: 0px">
        <div class="d-flex justify-content-between align-items-center">
          <div class="float:left;">
            <h2>Add Sort Entry</h2>
          </div>
          <div class="float:right;">
            <button
              mat-mini-fab
              mat-button
              color="warn"
              pTooltip="Close"
              tooltipPosition="top"
              (click)="sortDialogRef.close()"
            >
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
          </div>
        </div>
      </div>

      <mat-dialog-content>
        <div class="content-row-wrap">
          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'department'"
              [multiple]="false"
              name="TypeName"
              [required]="true"
              [source]="departmentList"
              [displayMember]="'TypeName'"
              placeholder="Select Department"
              [valueMember]="'TypeId'"
              #sfdepart="ngxMatSelect"
            >
              <mat-option
                *ngFor="let dep of sfdepart.filteredSource"
                [value]="dep.TypeId"
              >
                {{ dep.TypeName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'teamlead'"
              [multiple]="false"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team Lead"
              [valueMember]="'EmpInfo_Id'"
              #sfuserL="ngxMatSelect"
            >
              <mat-option
                *ngFor="let usel of sfuserL.filteredSource"
                [value]="usel.EmpInfo_Id"
              >
                {{ usel.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'team'"
              [multiple]="true"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team"
              [valueMember]="'EmpInfo_Id'"
              #sfuserLi="ngxMatSelect"
            >
              <mat-option
                *ngFor="let useli of sfuserLi.filteredSource"
                [value]="useli.EmpInfo_Id"
              >
                {{ useli.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'currency'"
              [multiple]="false"
              name="currencyname"
              [required]="true"
              [source]="currencies"
              [displayMember]="'currencyname'"
              placeholder="Select Currency"
              [valueMember]="'id'"
              #sfcurrency1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let cur of sfcurrency1.filteredSource"
                [value]="cur.id"
                >{{ cur.currencyname }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'uom'"
              [multiple]="false"
              name="uomDesc"
              [required]="true"
              [source]="Uomms"
              [displayMember]="'uomDesc'"
              placeholder="Select UOM"
              [valueMember]="'uomId'"
              #sfuom1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let uoms of sfuom1.filteredSource"
                [value]="uoms.uomId"
                >{{ uoms.uomDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-label class="required">Total Hold Quantity</mat-label>
            <input
              type="number"
              min="1"
              matInput
              formControlName="totalholdquantity"
              placeholder="On Hold {{ QuantityHold }}"
              required
            />
          </mat-form-field>
          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <input
              matInput
              formControlName="assigndate"
              [ngxMatDatetimePicker]="assigndatepic"
              placeholder="Assign Date"
              readonly
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(assigndatepic)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #assigndatepic> </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>

        <div class="d-flex justify-content-end mb-10">
          <button
            type="submit"
            mat-mini-fab
            mat-button
            color="primary"
            pTooltip="Save"
            tooltipPosition="top"
            (click)="submitSortEntry()"
          >
            <mat-icon style="color: #fff">save</mat-icon>
          </button>
        </div>
        <!-- <div class="p-field p-col-3" style="margin-left: 10px;">
          <img [src]="imageSrc">
        </div> -->
      </mat-dialog-content>
    </form>
  </ng-template>

  <ng-template #displayEditSortModal>
    <form [formGroup]="immediatesortactioneditform">
      <div class="d-flex justify-content-between align-items-center">
        <h1 mat-dialog-title style="margin: 0px">Update Sort Entry</h1>
        <button
          mat-mini-fab
          mat-button
          color="warn"
          pTooltip="Close"
          tooltipPosition="top"
          (click)="sortDialogRef.close()"
        >
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
      <div class="content-row-wrap">
        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'department'"
            [multiple]="false"
            name="TypeName"
            [required]="true"
            [source]="departmentList"
            [displayMember]="'TypeName'"
            placeholder="Select Department"
            [valueMember]="'TypeId'"
            #sfeditdepart="ngxMatSelect"
          >
            <mat-option
              *ngFor="let dep of sfeditdepart.filteredSource"
              [value]="dep.TypeId"
            >
              {{ dep.TypeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'teamlead'"
            [multiple]="false"
            name="fullName"
            [required]="true"
            [source]="UsersList"
            [displayMember]="'fullName'"
            placeholder="Select Team Lead"
            [valueMember]="'EmpInfo_Id'"
            #sfedituserL="ngxMatSelect"
          >
            <mat-option
              *ngFor="let usel of sfedituserL.filteredSource"
              [value]="usel.EmpInfo_Id"
            >
              {{ usel.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'team'"
            [multiple]="true"
            name="fullName"
            [required]="true"
            [source]="UsersList"
            [displayMember]="'fullName'"
            placeholder="Select Team"
            [valueMember]="'EmpInfo_Id'"
            #sfedituserLi="ngxMatSelect"
          >
            <mat-option
              *ngFor="let useli of sfedituserLi.filteredSource"
              [value]="useli.EmpInfo_Id"
            >
              {{ useli.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'currency'"
            [multiple]="false"
            name="currencyname"
            [required]="true"
            [source]="currencies"
            [displayMember]="'currencyname'"
            placeholder="Select Currency"
            [valueMember]="'id'"
            #sfeditcurrency1="ngxMatSelect"
          >
            <mat-option
              *ngFor="let cur of sfeditcurrency1.filteredSource"
              [value]="cur.id"
              >{{ cur.currencyname }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'uom'"
            [multiple]="false"
            name="uomDesc"
            [required]="true"
            [source]="Uomms"
            [displayMember]="'uomDesc'"
            placeholder="Select UOM"
            [valueMember]="'uomId'"
            #sfedituom1="ngxMatSelect"
          >
            <mat-option
              *ngFor="let uoms of sfedituom1.filteredSource"
              [value]="uoms.uomId"
              >{{ uoms.uomDesc }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-label class="required">Total Hold Quantity</mat-label>
          <input
            matInput
            formControlName="totalholdquantity"
            readonly
            required
          />
        </mat-form-field>
        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <input
            matInput
            formControlName="assigndate"
            [ngxMatDatetimePicker]="assigndatepic"
            placeholder="Assign Date"
            readonly
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(assigndatepic)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #assigndatepic> </ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <input
            matInput
            formControlName="targetdate"
            [ngxMatDatetimePicker]="targetdatepic"
            placeholder="Target Date"
            readonly
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(targetdatepic)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #targetdatepic> </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>

      <div class="p-grid p-fluid">
        <div class="p-col-3">
          <h2>Update Details</h2>
          <hr />
        </div>
        <div class="p-col-9 d-flex justify-content-end align-items-center">
          <button
            mat-mini-fab
            mat-button
            color="primary"
            (click)="addNewRow()"
            matTooltip="Add New Row"
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
            style="margin-left: 20px"
          >
            <i class="fa fa-plus-square-o" style="color: #fff"></i>
          </button>
          <button
            mat-mini-fab
            mat-button
            color="primary"
            matTooltip="'Update'"
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
            style="margin-left: 20px"
            (click)="updateSortEntry()"
          >
            <i class="fa fa-save" style="color: #fff"></i>
          </button>
        </div>
      </div>
      <div formArrayName="sortdetailActions">
        <div
          *ngFor="
            let elementArray of repeateSortElementForm.controls;
            let i = index
          "
        >
          <div [formGroupName]="i">
            <div class="content-row-wrap">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 15%"
              >
                <mat-label class="required">Total Quantity</mat-label>
                <input
                  type="number"
                  min="1"
                  matInput
                  formControlName="detailsortqty"
                  required
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 15%"
              >
                <mat-label class="required">Good Quantity</mat-label>
                <input
                  type="number"
                  min="0"
                  matInput
                  formControlName="detailgoodqty"
                  required
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 15%"
              >
                <mat-label class="required">Reject Quantity</mat-label>
                <input
                  type="number"
                  min="0"
                  matInput
                  formControlName="detailbadqty"
                  required
                />
              </mat-form-field>
              <mat-form-field
                appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 18%"
              >
                <mat-select
                  ngxMatSelect
                  [hasSearchBox]="true"
                  [useMobileView]="true"
                  mobileViewType="BottomSheet"
                  [formControlName]="'performedby'"
                  [multiple]="false"
                  name="fullName"
                  [required]="true"
                  [source]="UsersList"
                  [displayMember]="'fullName'"
                  placeholder="Performed By"
                  [valueMember]="'EmpInfo_Id'"
                  #sfeditperformedby="ngxMatSelect"
                >
                  <mat-option
                    *ngFor="let usel of sfeditperformedby.filteredSource"
                    [value]="usel.EmpInfo_Id"
                  >
                    {{ usel.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 18%"
              >
                <input
                  matInput
                  formControlName="performedon"
                  [ngxMatDatetimePicker]="performedonpic"
                  placeholder="Performed Date"
                  readonly
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="$any(performedonpic)"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #performedonpic>
                </ngx-mat-datetime-picker>
              </mat-form-field>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 10px;
                "
              >
                <i
                  class="fa fa-trash fa-2x"
                  (click)="removeRepeatSortElement(i)"
                  style="color: red"
                ></i>
              </div>
              <!-- <button mat-mini-fab mat-button color="warn" pTooltip="Remove" tooltipPosition="right"
                style="margin: 0.5rem 0 0 3rem;" (click)="removeRepeatSortElement(i)">
                <i class="fa fa-times" style="color: #fff"></i>
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #displayAddProcessModal>
    <form [formGroup]="processForm">
      <div mat-dialog-title style="margin: 0px">
        <div class="d-flex justify-content-between align-items-center">
          <div class="float:left;">
            <h2 mat-dialog-title>Add Re-Process Entry</h2>
          </div>
          <div class="float:right;">
            <button
              mat-mini-fab
              mat-button
              (click)="sortDialogRef.close()"
              color="warn"
              pTooltip="Close"
              tooltipPosition="top"
            >
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
          </div>
        </div>
      </div>

      <mat-dialog-content>
        <div class="content-row-wrap">
          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'department'"
              [multiple]="false"
              name="TypeName"
              [required]="true"
              [source]="departmentList"
              [displayMember]="'TypeName'"
              placeholder="Select Department"
              [valueMember]="'TypeId'"
              #sfadddepart="ngxMatSelect"
            >
              <mat-option
                *ngFor="let dep of sfadddepart.filteredSource"
                [value]="dep.TypeId"
              >
                {{ dep.TypeName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'teamlead'"
              [multiple]="false"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team Lead"
              [valueMember]="'EmpInfo_Id'"
              #sfadduserL="ngxMatSelect"
            >
              <mat-option
                *ngFor="let usel of sfadduserL.filteredSource"
                [value]="usel.EmpInfo_Id"
              >
                {{ usel.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'team'"
              [multiple]="true"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team"
              [valueMember]="'EmpInfo_Id'"
              #sfadduserLi="ngxMatSelect"
            >
              <mat-option
                *ngFor="let useli of sfadduserLi.filteredSource"
                [value]="useli.EmpInfo_Id"
              >
                {{ useli.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'currency'"
              [multiple]="false"
              name="currencyname"
              [required]="true"
              [source]="currencies"
              [displayMember]="'currencyname'"
              placeholder="Select Currency"
              [valueMember]="'id'"
              #sfaddcurrency1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let cur of sfaddcurrency1.filteredSource"
                [value]="cur.id"
                >{{ cur.currencyname }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'uom'"
              [multiple]="false"
              name="uomDesc"
              [required]="true"
              [source]="Uomms"
              [displayMember]="'uomDesc'"
              placeholder="Select UOM"
              [valueMember]="'uomId'"
              #sfadduom1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let uoms of sfadduom1.filteredSource"
                [value]="uoms.uomId"
                >{{ uoms.uomDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-label class="required">Total Hold Quantity</mat-label>
            <input
              type="number"
              min="1"
              matInput
              formControlName="totalholdquantity"
              required
            />
          </mat-form-field>
          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <input
              matInput
              formControlName="assigndate"
              [ngxMatDatetimePicker]="assignadddatepic"
              placeholder="Assign Date"
              readonly
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(assignadddatepic)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #assignadddatepic>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>

        <div class="d-flex justify-content-end mb-10">
          <button
            type="submit"
            mat-mini-fab
            mat-button
            color="primary"
            pTooltip="Save"
            tooltipPosition="top"
            style="margin-right: 5px"
            (click)="submitReProcessEntry()"
          >
            <mat-icon style="color: #fff">save</mat-icon>
          </button>
        </div>
        <!-- <div class="p-field p-col-3" style="margin-left: 10px;">
          <img [src]="imageSrc">
        </div> -->
      </mat-dialog-content>
    </form>
  </ng-template>

  <ng-template #displayEditProcessModal>
    <form [formGroup]="immediateprocessactioneditform">
      <div class="d-flex justify-content-between align-items-center">
        <h1 mat-dialog-title style="margin: 0px">Update Re-Process Entry</h1>
        <button
          mat-mini-fab
          mat-button
          (click)="sortDialogRef.close()"
          color="warn"
          pTooltip="Close"
          tooltipPosition="top"
        >
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>

      <div class="content-row-wrap">
        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'department'"
            [multiple]="false"
            name="TypeName"
            [required]="true"
            [source]="departmentList"
            [displayMember]="'TypeName'"
            placeholder="Select Department"
            [valueMember]="'TypeId'"
            #sfeditprocessdepart="ngxMatSelect"
          >
            <mat-option
              *ngFor="let dep of sfeditprocessdepart.filteredSource"
              [value]="dep.TypeId"
            >
              {{ dep.TypeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'teamlead'"
            [multiple]="false"
            name="fullName"
            [required]="true"
            [source]="UsersList"
            [displayMember]="'fullName'"
            placeholder="Select Team Lead"
            [valueMember]="'EmpInfo_Id'"
            #sfeditprocessuserL="ngxMatSelect"
          >
            <mat-option
              *ngFor="let usel of sfeditprocessuserL.filteredSource"
              [value]="usel.EmpInfo_Id"
            >
              {{ usel.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'team'"
            [multiple]="true"
            name="fullName"
            [required]="true"
            [source]="UsersList"
            [displayMember]="'fullName'"
            placeholder="Select Team"
            [valueMember]="'EmpInfo_Id'"
            #sfeditprocessuserLi="ngxMatSelect"
          >
            <mat-option
              *ngFor="let useli of sfeditprocessuserLi.filteredSource"
              [value]="useli.EmpInfo_Id"
            >
              {{ useli.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'currency'"
            [multiple]="false"
            name="currencyname"
            [required]="true"
            [source]="currencies"
            [displayMember]="'currencyname'"
            placeholder="Select Currency"
            [valueMember]="'id'"
            #sfeditprocesscurrency1="ngxMatSelect"
          >
            <mat-option
              *ngFor="let cur of sfeditprocesscurrency1.filteredSource"
              [value]="cur.id"
              >{{ cur.currencyname }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'uom'"
            [multiple]="false"
            name="uomDesc"
            [required]="true"
            [source]="Uomms"
            [displayMember]="'uomDesc'"
            placeholder="Select UOM"
            [valueMember]="'uomId'"
            #sfeditprocessuom1="ngxMatSelect"
          >
            <mat-option
              *ngFor="let uoms of sfeditprocessuom1.filteredSource"
              [value]="uoms.uomId"
              >{{ uoms.uomDesc }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-label class="required">Total Hold Quantity</mat-label>
          <input
            matInput
            formControlName="totalholdquantity"
            readonly
            required
          />
        </mat-form-field>

        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <input
            matInput
            formControlName="assigndate"
            [ngxMatDatetimePicker]="assigneditprocessdatepic"
            placeholder="Assign Date"
            readonly
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(assigneditprocessdatepic)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #assigneditprocessdatepic>
          </ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <input
            matInput
            formControlName="targetdate"
            [ngxMatDatetimePicker]="targeteditprocessdatepic"
            placeholder="Target Date"
            readonly
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(targeteditprocessdatepic)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #targeteditprocessdatepic>
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>

      <div class="p-grid p-fluid">
        <div class="p-col-3">
          <h2>Update Details</h2>
          <hr />
        </div>
        <div class="p-col-9 d-flex justify-content-end align-items-center">
          <button
            mat-mini-fab
            mat-button
            color="primary"
            (click)="addNewRow2()"
            matTooltip="Add New Row"
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
            style="margin-left: 20px"
          >
            <i class="fa fa-plus-square-o" style="color: #fff"></i>
          </button>
          <button
            mat-mini-fab
            mat-button
            color="primary"
            matTooltip="'Update'"
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
            style="margin-left: 20px"
            (click)="updateProcessEntry()"
          >
            <i class="fa fa-save" style="color: #fff"></i>
          </button>
        </div>
      </div>
      <div formArrayName="processdetailActions">
        <div
          *ngFor="
            let elementArray of repeateProcessElementForm.controls;
            let i = index
          "
        >
          <div [formGroupName]="i">
            <div class="content-row-wrap">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <mat-label class="required">Total Quantity</mat-label>
                <input
                  type="number"
                  min="1"
                  matInput
                  formControlName="detailqty"
                  required
                />
              </mat-form-field>
              <mat-form-field
                appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <mat-select
                  ngxMatSelect
                  [hasSearchBox]="true"
                  [useMobileView]="true"
                  mobileViewType="BottomSheet"
                  [formControlName]="'performedby'"
                  [multiple]="false"
                  name="fullName"
                  [required]="true"
                  [source]="UsersList"
                  [displayMember]="'fullName'"
                  placeholder="Performed By"
                  [valueMember]="'EmpInfo_Id'"
                  #sfeditprocessperformedby="ngxMatSelect"
                >
                  <mat-option
                    *ngFor="let usel of sfeditprocessperformedby.filteredSource"
                    [value]="usel.EmpInfo_Id"
                  >
                    {{ usel.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <input
                  matInput
                  formControlName="performedon"
                  [ngxMatDatetimePicker]="editprocessperformedonpic"
                  placeholder="Performed Date"
                  readonly
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="$any(editprocessperformedonpic)"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #editprocessperformedonpic>
                </ngx-mat-datetime-picker>
              </mat-form-field>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 10px;
                "
              >
                <i
                  class="fa fa-trash fa-2x"
                  (click)="removeRepeatProcessElement(i)"
                  style="color: red"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #displayAddConcessionModal>
    <form [formGroup]="concessionForm">
      <div mat-dialog-title style="margin: 0px">
        <div class="d-flex justify-content-between align-items-center">
          <div class="float:left;">
            <h2>Add Concession Entry</h2>
          </div>
          <div class="float:right;">
            <button
              mat-mini-fab
              mat-button
              (click)="sortDialogRef.close()"
              color="warn"
              pTooltip="Close"
              tooltipPosition="top"
            >
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
          </div>
        </div>
      </div>

      <mat-dialog-content>
        <div class="content-row-wrap">
          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'department'"
              [multiple]="false"
              name="TypeName"
              [required]="true"
              [source]="departmentList"
              [displayMember]="'TypeName'"
              placeholder="Select Department"
              [valueMember]="'TypeId'"
              #sfaddcondepart="ngxMatSelect"
            >
              <mat-option
                *ngFor="let dep of sfaddcondepart.filteredSource"
                [value]="dep.TypeId"
              >
                {{ dep.TypeName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'teamlead'"
              [multiple]="false"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team Lead"
              [valueMember]="'EmpInfo_Id'"
              #sfaddconuserL="ngxMatSelect"
            >
              <mat-option
                *ngFor="let usel of sfaddconuserL.filteredSource"
                [value]="usel.EmpInfo_Id"
              >
                {{ usel.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'team'"
              [multiple]="true"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team"
              [valueMember]="'EmpInfo_Id'"
              #sfaddconuserLi="ngxMatSelect"
            >
              <mat-option
                *ngFor="let useli of sfaddconuserLi.filteredSource"
                [value]="useli.EmpInfo_Id"
              >
                {{ useli.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'currency'"
              [multiple]="false"
              name="currencyname"
              [required]="true"
              [source]="currencies"
              [displayMember]="'currencyname'"
              placeholder="Select Currency"
              [valueMember]="'id'"
              #sfaddconcurrency1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let cur of sfaddconcurrency1.filteredSource"
                [value]="cur.id"
                >{{ cur.currencyname }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'uom'"
              [multiple]="false"
              name="uomDesc"
              [required]="true"
              [source]="Uomms"
              [displayMember]="'uomDesc'"
              placeholder="Select UOM"
              [valueMember]="'uomId'"
              #sfaddconuom1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let uoms of sfaddconuom1.filteredSource"
                [value]="uoms.uomId"
                >{{ uoms.uomDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-label class="required">Total Hold Quantity</mat-label>
            <input
              type="number"
              min="1"
              matInput
              formControlName="totalholdquantity"
              required
            />
          </mat-form-field>

          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <input
              matInput
              formControlName="assigndate"
              [ngxMatDatetimePicker]="assignaddcondatepic"
              placeholder="Assign Date"
              readonly
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(assignaddcondatepic)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #assignaddcondatepic>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>

        <div class="d-flex justify-content-end mb-10">
          <button
            type="submit"
            mat-mini-fab
            mat-button
            color="primary"
            pTooltip="Save"
            tooltipPosition="top"
            style="margin-right: 5px"
            (click)="submitConcessionEntry()"
          >
            <mat-icon style="color: #fff">save</mat-icon>
          </button>
        </div>
        <!-- <div class="p-field p-col-3" style="margin-left: 10px;">
          <img [src]="imageSrc">
        </div> -->
      </mat-dialog-content>
    </form>
  </ng-template>

  <ng-template #displayEditConcessionModal>
    <form [formGroup]="immediateconcessionactioneditform">
      <div class="d-flex justify-content-between align-items-center">
        <h1 mat-dialog-title style="margin: 0px">Update Concession Entry</h1>
        <button
          mat-mini-fab
          mat-button
          (click)="sortDialogRef.close()"
          color="warn"
          pTooltip="Close"
          tooltipPosition="top"
        >
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-row-wrap">
          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'department'"
              [multiple]="false"
              name="TypeName"
              [required]="true"
              [source]="departmentList"
              [displayMember]="'TypeName'"
              placeholder="Select Department"
              [valueMember]="'TypeId'"
              #sfeditcondepart="ngxMatSelect"
            >
              <mat-option
                *ngFor="let dep of sfeditcondepart.filteredSource"
                [value]="dep.TypeId"
              >
                {{ dep.TypeName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'teamlead'"
              [multiple]="false"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team Lead"
              [valueMember]="'EmpInfo_Id'"
              #sfeditconuserL="ngxMatSelect"
            >
              <mat-option
                *ngFor="let usel of sfeditconuserL.filteredSource"
                [value]="usel.EmpInfo_Id"
              >
                {{ usel.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'team'"
              [multiple]="true"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team"
              [valueMember]="'EmpInfo_Id'"
              #sfeditconuserLi="ngxMatSelect"
            >
              <mat-option
                *ngFor="let useli of sfeditconuserLi.filteredSource"
                [value]="useli.EmpInfo_Id"
              >
                {{ useli.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'currency'"
              [multiple]="false"
              name="currencyname"
              [required]="true"
              [source]="currencies"
              [displayMember]="'currencyname'"
              placeholder="Select Currency"
              [valueMember]="'id'"
              #sfeditconcurrency1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let cur of sfeditconcurrency1.filteredSource"
                [value]="cur.id"
                >{{ cur.currencyname }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'uom'"
              [multiple]="false"
              name="uomDesc"
              [required]="true"
              [source]="Uomms"
              [displayMember]="'uomDesc'"
              placeholder="Select UOM"
              [valueMember]="'uomId'"
              #sfeditconuom1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let uoms of sfeditconuom1.filteredSource"
                [value]="uoms.uomId"
                >{{ uoms.uomDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-label class="required">Total Hold Quantity</mat-label>
            <input
              matInput
              formControlName="totalholdquantity"
              readonly
              required
            />
          </mat-form-field>

          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <input
              matInput
              formControlName="assigndate"
              [ngxMatDatetimePicker]="assigneditcondatepic"
              placeholder="Assign Date"
              readonly
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(assigneditcondatepic)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #assigneditcondatepic>
            </ngx-mat-datetime-picker>
          </mat-form-field>
          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <input
              matInput
              formControlName="targetdate"
              [ngxMatDatetimePicker]="targeteditcondatepic"
              placeholder="Target Date"
              readonly
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(targeteditcondatepic)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #targeteditcondatepic>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-3">
          <h2>Update Details</h2>
          <hr />
        </div>
        <div class="p-col-9 d-flex justify-content-end align-items-center">
          <button
            mat-mini-fab
            mat-button
            color="primary"
            (click)="addNewRow3()"
            matTooltip="Add New Row"
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
            style="margin-left: 20px"
          >
            <i class="fa fa-plus-square-o" style="color: #fff"></i>
          </button>
          <button
            mat-mini-fab
            mat-button
            color="primary"
            matTooltip="'Update'"
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
            style="margin-left: 20px"
            (click)="updateConcessionEntry()"
          >
            <i class="fa fa-save" style="color: #fff"></i>
          </button>
        </div>
      </div>
      <div formArrayName="processdetailActions">
        <div
          *ngFor="
            let elementArray of repeateConcessionElementForm.controls;
            let i = index
          "
        >
          <div [formGroupName]="i">
            <div class="content-row-wrap">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <mat-label class="required">Total Quantity</mat-label>
                <input
                  type="number"
                  min="1"
                  matInput
                  formControlName="detailqty"
                  required
                />
              </mat-form-field>
              <mat-form-field
                appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <mat-select
                  ngxMatSelect
                  [hasSearchBox]="true"
                  [useMobileView]="true"
                  mobileViewType="BottomSheet"
                  [formControlName]="'performedby'"
                  [multiple]="false"
                  name="fullName"
                  [required]="true"
                  [source]="UsersList"
                  [displayMember]="'fullName'"
                  placeholder="Performed By"
                  [valueMember]="'EmpInfo_Id'"
                  #sfeditconperformedby="ngxMatSelect"
                >
                  <mat-option
                    *ngFor="let usel of sfeditconperformedby.filteredSource"
                    [value]="usel.EmpInfo_Id"
                  >
                    {{ usel.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <input
                  matInput
                  formControlName="performedon"
                  [ngxMatDatetimePicker]="editconperformedonpic"
                  placeholder="Performed Date"
                  readonly
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="$any(editconperformedonpic)"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #editconperformedonpic>
                </ngx-mat-datetime-picker>
              </mat-form-field>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 10px;
                "
              >
                <i
                  class="fa fa-trash fa-2x"
                  (click)="removeRepeatConcessionElement(i)"
                  style="color: red"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #displayAddDowngradeModal>
    <form [formGroup]="downgradeForm">
      <div mat-dialog-title style="margin: 0px">
        <div class="d-flex justify-content-between align-items-center">
          <div class="float:left;">
            <h2>Add Downgrade Entry</h2>
          </div>
          <div class="float:right;">
            <button
              mat-mini-fab
              mat-button
              (click)="sortDialogRef.close()"
              color="warn"
              pTooltip="Close"
              tooltipPosition="top"
            >
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
          </div>
        </div>
      </div>

      <mat-dialog-content>
        <div class="content-row-wrap">
          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'department'"
              [multiple]="false"
              name="TypeName"
              [required]="true"
              [source]="departmentList"
              [displayMember]="'TypeName'"
              placeholder="Select Department"
              [valueMember]="'TypeId'"
              #sfadddowndepart="ngxMatSelect"
            >
              <mat-option
                *ngFor="let dep of sfadddowndepart.filteredSource"
                [value]="dep.TypeId"
              >
                {{ dep.TypeName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'teamlead'"
              [multiple]="false"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team Lead"
              [valueMember]="'EmpInfo_Id'"
              #sfadddownuserL="ngxMatSelect"
            >
              <mat-option
                *ngFor="let usel of sfadddownuserL.filteredSource"
                [value]="usel.EmpInfo_Id"
              >
                {{ usel.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'team'"
              [multiple]="true"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team"
              [valueMember]="'EmpInfo_Id'"
              #sfadddownuserLi="ngxMatSelect"
            >
              <mat-option
                *ngFor="let useli of sfadddownuserLi.filteredSource"
                [value]="useli.EmpInfo_Id"
              >
                {{ useli.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'currency'"
              [multiple]="false"
              name="currencyname"
              [required]="true"
              [source]="currencies"
              [displayMember]="'currencyname'"
              placeholder="Select Currency"
              [valueMember]="'id'"
              #sfadddowncurrency1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let cur of sfadddowncurrency1.filteredSource"
                [value]="cur.id"
                >{{ cur.currencyname }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'uom'"
              [multiple]="false"
              name="uomDesc"
              [required]="true"
              [source]="Uomms"
              [displayMember]="'uomDesc'"
              placeholder="Select UOM"
              [valueMember]="'uomId'"
              #sfadddownuom1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let uoms of sfadddownuom1.filteredSource"
                [value]="uoms.uomId"
                >{{ uoms.uomDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-label class="required">Total Hold Quantity</mat-label>
            <input
              type="number"
              min="1"
              matInput
              formControlName="totalholdquantity"
              required
            />
          </mat-form-field>

          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <input
              matInput
              formControlName="assigndate"
              [ngxMatDatetimePicker]="assignadddowndatepic"
              placeholder="Assign Date"
              readonly
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(assignadddowndatepic)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #assignadddowndatepic>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>

        <div class="d-flex justify-content-end mb-10">
          <button
            type="submit"
            mat-mini-fab
            mat-button
            color="primary"
            pTooltip="Save"
            tooltipPosition="top"
            style="margin-right: 5px"
            (click)="submitDowngradeEntry()"
          >
            <mat-icon style="color: #fff">save</mat-icon>
          </button>
        </div>
        <!-- <div class="p-field p-col-3" style="margin-left: 10px;">
          <img [src]="imageSrc">
        </div> -->
      </mat-dialog-content>
    </form>
  </ng-template>

  <ng-template #displayEditDowngradeModal>
    <form [formGroup]="immediatedowngradeactioneditform">
      <div class="d-flex justify-content-between align-items-center">
        <h1 mat-dialog-title style="margin: 0px">Update Downgrade Entry</h1>
        <button
          mat-mini-fab
          mat-button
          (click)="sortDialogRef.close()"
          color="warn"
          pTooltip="Close"
          tooltipPosition="top"
        >
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>

      <div class="content-row-wrap">
        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'department'"
            [multiple]="false"
            name="TypeName"
            [required]="true"
            [source]="departmentList"
            [displayMember]="'TypeName'"
            placeholder="Select Department"
            [valueMember]="'TypeId'"
            #sfeditdowndepart="ngxMatSelect"
          >
            <mat-option
              *ngFor="let dep of sfeditdowndepart.filteredSource"
              [value]="dep.TypeId"
            >
              {{ dep.TypeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'teamlead'"
            [multiple]="false"
            name="fullName"
            [required]="true"
            [source]="UsersList"
            [displayMember]="'fullName'"
            placeholder="Select Team Lead"
            [valueMember]="'EmpInfo_Id'"
            #sfeditdownuserL="ngxMatSelect"
          >
            <mat-option
              *ngFor="let usel of sfeditdownuserL.filteredSource"
              [value]="usel.EmpInfo_Id"
            >
              {{ usel.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'team'"
            [multiple]="true"
            name="fullName"
            [required]="true"
            [source]="UsersList"
            [displayMember]="'fullName'"
            placeholder="Select Team"
            [valueMember]="'EmpInfo_Id'"
            #sfeditdownuserLi="ngxMatSelect"
          >
            <mat-option
              *ngFor="let useli of sfeditdownuserLi.filteredSource"
              [value]="useli.EmpInfo_Id"
            >
              {{ useli.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'currency'"
            [multiple]="false"
            name="currencyname"
            [required]="true"
            [source]="currencies"
            [displayMember]="'currencyname'"
            placeholder="Select Currency"
            [valueMember]="'id'"
            #sfeditdowncurrency1="ngxMatSelect"
          >
            <mat-option
              *ngFor="let cur of sfeditdowncurrency1.filteredSource"
              [value]="cur.id"
              >{{ cur.currencyname }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'uom'"
            [multiple]="false"
            name="uomDesc"
            [required]="true"
            [source]="Uomms"
            [displayMember]="'uomDesc'"
            placeholder="Select UOM"
            [valueMember]="'uomId'"
            #sfeditdownuom1="ngxMatSelect"
          >
            <mat-option
              *ngFor="let uoms of sfeditdownuom1.filteredSource"
              [value]="uoms.uomId"
              >{{ uoms.uomDesc }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-label class="required">Total Hold Quantity</mat-label>
          <input
            matInput
            formControlName="totalholdquantity"
            readonly
            required
          />
        </mat-form-field>

        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <input
            matInput
            formControlName="assigndate"
            [ngxMatDatetimePicker]="assigneditdowndatepic"
            placeholder="Assign Date"
            readonly
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(assigneditdowndatepic)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #assigneditdowndatepic>
          </ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <input
            matInput
            formControlName="targetdate"
            [ngxMatDatetimePicker]="targeteditdowndatepic"
            placeholder="Target Date"
            readonly
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(targeteditdowndatepic)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #targeteditdowndatepic>
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>

      <div class="p-grid p-fluid">
        <div class="p-col-3">
          <h2>Update Details</h2>
          <hr />
        </div>
        <div class="p-col-9 d-flex justify-content-end align-items-center">
          <button
            mat-mini-fab
            mat-button
            color="primary"
            (click)="addNewRow4()"
            matTooltip="Add New Row"
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
            style="margin-left: 20px"
          >
            <i class="fa fa-plus-square-o" style="color: #fff"></i>
          </button>
          <button
            mat-mini-fab
            mat-button
            color="primary"
            matTooltip="'Update'"
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
            style="margin-left: 20px"
            (click)="updateDowngradeEntry()"
          >
            <i class="fa fa-save" style="color: #fff"></i>
          </button>
        </div>
      </div>
      <div formArrayName="processdetailActions">
        <div
          *ngFor="
            let elementArray of repeateDowngradeElementForm.controls;
            let i = index
          "
        >
          <div [formGroupName]="i">
            <div class="content-row-wrap">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <mat-label class="required">Total Quantity</mat-label>
                <input
                  type="number"
                  min="1"
                  matInput
                  formControlName="detailqty"
                  required
                />
              </mat-form-field>
              <mat-form-field
                appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <mat-select
                  ngxMatSelect
                  [hasSearchBox]="true"
                  [useMobileView]="true"
                  mobileViewType="BottomSheet"
                  [formControlName]="'performedby'"
                  [multiple]="false"
                  name="fullName"
                  [required]="true"
                  [source]="UsersList"
                  [displayMember]="'fullName'"
                  placeholder="Performed By"
                  [valueMember]="'EmpInfo_Id'"
                  #sfeditdownperformedby="ngxMatSelect"
                >
                  <mat-option
                    *ngFor="let usel of sfeditdownperformedby.filteredSource"
                    [value]="usel.EmpInfo_Id"
                  >
                    {{ usel.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <input
                  matInput
                  formControlName="performedon"
                  [ngxMatDatetimePicker]="editdownperformedonpic"
                  placeholder="Performed Date"
                  readonly
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="$any(editdownperformedonpic)"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #editdownperformedonpic>
                </ngx-mat-datetime-picker>
              </mat-form-field>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 10px;
                "
              >
                <i
                  class="fa fa-trash fa-2x"
                  (click)="removeRepeatDowngradeElement(i)"
                  style="color: red"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #displayAddDisposeModal>
    <form [formGroup]="disposeForm">
      <div mat-dialog-title style="margin: 0px">
        <div class="d-flex justify-content-between align-items-center">
          <div class="float:left;">
            <h2>Add Dispose Entry</h2>
          </div>
          <div class="float:right;">
            <button
              mat-mini-fab
              mat-button
              (click)="sortDialogRef.close()"
              color="warn"
              pTooltip="Close"
              tooltipPosition="top"
            >
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
          </div>
        </div>
      </div>

      <mat-dialog-content>
        <div class="content-row-wrap">
          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'department'"
              [multiple]="false"
              name="TypeName"
              [required]="true"
              [source]="departmentList"
              [displayMember]="'TypeName'"
              placeholder="Select Department"
              [valueMember]="'TypeId'"
              #sfadddisdepart="ngxMatSelect"
            >
              <mat-option
                *ngFor="let dep of sfadddisdepart.filteredSource"
                [value]="dep.TypeId"
              >
                {{ dep.TypeName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'teamlead'"
              [multiple]="false"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team Lead"
              [valueMember]="'EmpInfo_Id'"
              #sfadddisuserL="ngxMatSelect"
            >
              <mat-option
                *ngFor="let usel of sfadddisuserL.filteredSource"
                [value]="usel.EmpInfo_Id"
              >
                {{ usel.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'team'"
              [multiple]="true"
              name="fullName"
              [required]="true"
              [source]="UsersList"
              [displayMember]="'fullName'"
              placeholder="Select Team"
              [valueMember]="'EmpInfo_Id'"
              #sfadddisuserLi="ngxMatSelect"
            >
              <mat-option
                *ngFor="let useli of sfadddisuserLi.filteredSource"
                [value]="useli.EmpInfo_Id"
              >
                {{ useli.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'currency'"
              [multiple]="false"
              name="currencyname"
              [required]="true"
              [source]="currencies"
              [displayMember]="'currencyname'"
              placeholder="Select Currency"
              [valueMember]="'id'"
              #sfadddiscurrency1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let cur of sfadddiscurrency1.filteredSource"
                [value]="cur.id"
                >{{ cur.currencyname }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-select
              ngxMatSelect
              [hasSearchBox]="true"
              [useMobileView]="true"
              mobileViewType="BottomSheet"
              [formControlName]="'uom'"
              [multiple]="false"
              name="uomDesc"
              [required]="true"
              [source]="Uomms"
              [displayMember]="'uomDesc'"
              placeholder="Select UOM"
              [valueMember]="'uomId'"
              #sfadddisuom1="ngxMatSelect"
            >
              <mat-option
                *ngFor="let uoms of sfadddisuom1.filteredSource"
                [value]="uoms.uomId"
                >{{ uoms.uomDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <mat-label class="required">Total Hold Quantity</mat-label>
            <input
              type="number"
              min="1"
              matInput
              formControlName="totalholdquantity"
              required
            />
          </mat-form-field>
          <mat-form-field
            class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          >
            <input
              matInput
              formControlName="assigndate"
              [ngxMatDatetimePicker]="assignadddisdatepic"
              placeholder="Assign Date"
              readonly
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(assignadddisdatepic)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #assignadddisdatepic>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>

        <div class="d-flex justify-content-end mb-10">
          <button
            type="submit"
            mat-mini-fab
            mat-button
            color="primary"
            pTooltip="Save"
            tooltipPosition="top"
            style="margin-right: 5px"
            (click)="submitDisposeEntry()"
          >
            <mat-icon style="color: #fff">save</mat-icon>
          </button>
        </div>
        <!-- <div class="p-field p-col-3" style="margin-left: 10px;">
          <img [src]="imageSrc">
        </div> -->
      </mat-dialog-content>
    </form>
  </ng-template>

  <ng-template #displayEditDisposeModal>
    <form [formGroup]="immediatedisposeactioneditform">
      <div class="d-flex justify-content-between align-items-center">
        <h1 mat-dialog-title style="margin: 0px">Update Dispose Entry</h1>
        <button
          mat-mini-fab
          mat-button
          (click)="sortDialogRef.close()"
          color="warn"
          pTooltip="Close"
          tooltipPosition="top"
        >
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>

      <div class="content-row-wrap">
        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'department'"
            [multiple]="false"
            name="TypeName"
            [required]="true"
            [source]="departmentList"
            [displayMember]="'TypeName'"
            placeholder="Select Department"
            [valueMember]="'TypeId'"
            #sfeditdisdepart="ngxMatSelect"
          >
            <mat-option
              *ngFor="let dep of sfeditdisdepart.filteredSource"
              [value]="dep.TypeId"
            >
              {{ dep.TypeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'teamlead'"
            [multiple]="false"
            name="fullName"
            [required]="true"
            [source]="UsersList"
            [displayMember]="'fullName'"
            placeholder="Select Team Lead"
            [valueMember]="'EmpInfo_Id'"
            #sfeditdisuserL="ngxMatSelect"
          >
            <mat-option
              *ngFor="let usel of sfeditdisuserL.filteredSource"
              [value]="usel.EmpInfo_Id"
            >
              {{ usel.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'team'"
            [multiple]="true"
            name="fullName"
            [required]="true"
            [source]="UsersList"
            [displayMember]="'fullName'"
            placeholder="Select Team"
            [valueMember]="'EmpInfo_Id'"
            #sfeditdisuserLi="ngxMatSelect"
          >
            <mat-option
              *ngFor="let useli of sfeditdisuserLi.filteredSource"
              [value]="useli.EmpInfo_Id"
            >
              {{ useli.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'currency'"
            [multiple]="false"
            name="currencyname"
            [required]="true"
            [source]="currencies"
            [displayMember]="'currencyname'"
            placeholder="Select Currency"
            [valueMember]="'id'"
            #sfeditdiscurrency1="ngxMatSelect"
          >
            <mat-option
              *ngFor="let cur of sfeditdiscurrency1.filteredSource"
              [value]="cur.id"
              >{{ cur.currencyname }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select
            ngxMatSelect
            [hasSearchBox]="true"
            [useMobileView]="true"
            mobileViewType="BottomSheet"
            [formControlName]="'uom'"
            [multiple]="false"
            name="uomDesc"
            [required]="true"
            [source]="Uomms"
            [displayMember]="'uomDesc'"
            placeholder="Select UOM"
            [valueMember]="'uomId'"
            #sfeditdisuom1="ngxMatSelect"
          >
            <mat-option
              *ngFor="let uoms of sfeditdisuom1.filteredSource"
              [value]="uoms.uomId"
              >{{ uoms.uomDesc }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-label class="required">Total Hold Quantity</mat-label>
          <input
            matInput
            formControlName="totalholdquantity"
            readonly
            required
          />
        </mat-form-field>
        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <input
            matInput
            formControlName="assigndate"
            [ngxMatDatetimePicker]="assigneditdisdatepic"
            placeholder="Assign Date"
            readonly
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(assigneditdisdatepic)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #assigneditdisdatepic>
          </ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <input
            matInput
            formControlName="targetdate"
            [ngxMatDatetimePicker]="targeteditdisdatepic"
            placeholder="Target Date"
            readonly
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(targeteditdisdatepic)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #targeteditdisdatepic>
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>

      <div class="p-grid p-fluid">
        <div class="p-col-3">
          <h2>Update Details</h2>
          <hr />
        </div>
        <div class="p-col-9 d-flex justify-content-end align-items-center">
          <button
            mat-mini-fab
            mat-button
            color="primary"
            (click)="addNewRow5()"
            matTooltip="Add New Row"
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
            style="margin-left: 20px"
          >
            <i class="fa fa-plus-square-o" style="color: #fff"></i>
          </button>
          <button
            mat-mini-fab
            mat-button
            color="primary"
            matTooltip="'Update'"
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
            style="margin-left: 20px"
            (click)="updateDisposeEntry()"
          >
            <i class="fa fa-save" style="color: #fff"></i>
          </button>
        </div>
      </div>
      <div formArrayName="processdetailActions">
        <div
          *ngFor="
            let elementArray of repeateDisposeElementForm.controls;
            let i = index
          "
        >
          <div [formGroupName]="i">
            <div class="content-row-wrap">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <mat-label class="required">Total Quantity</mat-label>
                <input
                  type="number"
                  min="1"
                  matInput
                  formControlName="detailqty"
                  required
                />
              </mat-form-field>
              <mat-form-field
                appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <mat-select
                  ngxMatSelect
                  [hasSearchBox]="true"
                  [useMobileView]="true"
                  mobileViewType="BottomSheet"
                  [formControlName]="'performedby'"
                  [multiple]="false"
                  name="fullName"
                  [required]="true"
                  [source]="UsersList"
                  [displayMember]="'fullName'"
                  placeholder="Performed By"
                  [valueMember]="'EmpInfo_Id'"
                  #sfeditdisperformedby="ngxMatSelect"
                >
                  <mat-option
                    *ngFor="let usel of sfeditdisperformedby.filteredSource"
                    [value]="usel.EmpInfo_Id"
                  >
                    {{ usel.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              >
                <input
                  matInput
                  formControlName="performedon"
                  [ngxMatDatetimePicker]="editdisperformedonpic"
                  placeholder="Performed Date"
                  readonly
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="$any(editdisperformedonpic)"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #editdisperformedonpic>
                </ngx-mat-datetime-picker>
              </mat-form-field>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 10px;
                "
              >
                <i
                  class="fa fa-trash fa-2x"
                  (click)="removeRepeatDisposeElement(i)"
                  style="color: red"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #displayFOCModal>
    <form [formGroup]="focApprovingAuthorityform">
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <div class="float:left;"></div>
          <div class="float:right;">
            <button
              type="submit"
              mat-mini-fab
              mat-button
              color="primary"
              pTooltip="Save"
              tooltipPosition="top"
              style="margin-right: 5px"
              (click)="saveApprovingAuthority()"
            >
              <mat-icon style="color: #fff">save</mat-icon>
            </button>
            <button
              mat-mini-fab
              mat-button
              (click)="sortDialogRef.close()"
              color="warn"
              pTooltip="Close"
              tooltipPosition="top"
            >
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
          </div>
        </div>
      </div>
      <mat-dialog-content
        class="popup"
        style="height: 85%; min-height: 80vh !important"
      >
        <div
          class="p-grid"
          style="background-color: blue; height: 100px; padding: 15px"
        >
          <div class="p-col-2">
            <img src="../../../assets/images/logo-full.png" />
          </div>
          <div class="p-col-8">
            <!-- <div class="header-subtitle">
              <p>Integrated Management System</p>
            </div> -->
            <div class="header-main-title">
              <p>REPROCESSING OF NON CONFORMING MATERIALS</p>
            </div>
          </div>
          <div class="p-col-2">
            <div class="header-left">
              <p>Code: {{ FormId }}</p>
              <div class="imgflex">
                <img
                  *ngIf="imageFile && imageSrc"
                  (click)="imageZoomModal()"
                  [src]="imageSrc"
                  alt=""
                  width="60px"
                  height="40px"
                />
                <!-- <img
                  class="imgrel"
                  (click)="imageZoomModal()"
                  [src]="ImageUrl + headerData.UploadFile"
                  alt=""
                  width="60px"
                  height="40px"
                /> -->
                <img
                  *ngIf="(imageFile && imageSrc) || headerData.UploadFile"
                  src="assets/images/delete.png"
                  (click)="deleteImageFile()"
                  class="imgabsol"
                  alt=""
                  width="15px"
                  height="15px"
                  style="margin-left: -15px; margin-top: -25px"
                />
                <button
                  mat-flat-button
                  color="accent"
                  type="button"
                  class="upload"
                  (click)="openImageFile()"
                  style="margin-left: 2px"
                >
                  <mat-icon>attach_file</mat-icon>
                </button>
                <input
                  type="file"
                  (change)="readFile($event)"
                  class="d-none"
                  #fileInput
                  accept="image/*"
                />
              </div>
              <!-- <p>Edition:3</p> -->
            </div>
          </div>
        </div>
        <div class="form-wrapper">
          <div class="left-form">
            <!-- <div class="d-flex justify-content-end" style="margin-top: 10px;">
              <p>Date: <span>22/03/2021</span></p>
            </div> -->
            <div class="content-row-wrap">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 45.6%"
              >
                <mat-label>Production Order No:</mat-label>
                <input
                  [formControlName]="'productionorderno'"
                  required
                  matInput
                />
              </mat-form-field>
              <!-- <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 45.6%"
              >
                <mat-label>Customer Name</mat-label>
                <input [formControlName]="'customername'" required matInput />
              </mat-form-field> -->
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Product Description</mat-label>
                <input
                  [formControlName]="'productdescription'"
                  required
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Sales Reference No</mat-label>
                <input
                  [formControlName]="'salesReferenceNo'"
                  required
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 45.6%"
              >
                <mat-label>NCR Quantity Hold</mat-label>
                <input
                  type="number"
                  [formControlName]="'quantityorder'"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 45.6%"
              >
                <mat-label>Sorting Rejected Quantity</mat-label>
                <input
                  type="number"
                  [formControlName]="'quantityfailed'"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 45.6%"
              >
                <mat-label>Re-Processed Quantity</mat-label>
                <input
                  [formControlName]="'reprocessedQty'"
                  readonly="true"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 45.6%"
              >
                <input
                  matInput
                  formControlName="reprocessingTargetDate"
                  [matDatepicker]="reprocessingTargetDatePic"
                  placeholder="Target Date"
                  readonly
                  (click)="reprocessingTargetDatePic.open()"
                  disabled="true"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="reprocessingTargetDatePic"
                ></mat-datepicker-toggle>
                <mat-datepicker #reprocessingTargetDatePic> </mat-datepicker>
              </mat-form-field>
              <div class="checkbox-wrapper">
                <mat-checkbox
                  type="checkbox"
                  class="mat-checkbox-new"
                  id="checkbox1"
                  name="checkbox1"
                  [checked]="isInternal"
                  (change)="toggleCheckbox($event)"
                >
                  Internal
                </mat-checkbox>
                <mat-checkbox
                  type="checkbox"
                  class="mat-checkbox-new"
                  id="checkbox2"
                  name="checkbox2"
                  [checked]="!isInternal"
                  (change)="toggleCheckbox($event)"
                >
                  From Customer
                </mat-checkbox>
              </div>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 45.6%"
              >
                <mat-label>NCR No:</mat-label>
                <input [formControlName]="'ncrno'" readonly matInput />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 45.6%"
              >
                <mat-label>CCR No</mat-label>
                <input
                  [formControlName]="'ccrno'"
                  [readonly]="isInternal"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="height: 100px; width: 100%"
              >
                <mat-label>Description of Non Conformance</mat-label>
                <textarea
                  rows="3"
                  [formControlName]="'descNonConformance'"
                  matInput
                ></textarea>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="height: 100px; width: 100%"
              >
                <mat-label
                  >Special Instructions (If any) for reprocessing</mat-label
                >
                <textarea
                  rows="3"
                  [formControlName]="'instructionForReprocessing'"
                  matInput
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="right-form">
            <h3>Additional Materials for rework</h3>
            <div class="content-top-row">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 25%; margin-left: 0px"
              >
                <mat-label>Material Description</mat-label>
                <input id="FOCMaterialDescription" matInput />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 25%"
              >
                <mat-label>Quantity</mat-label>
                <input
                  id="FOCMaterialQuantity"
                  type="Number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 25%"
              >
                <mat-label>Price Per Unit</mat-label>
                <input
                  id="FOCMaterialRate"
                  type="Number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  matInput
                />
              </mat-form-field>
              <button
                mat-mini-fab
                mat-button
                color="primary"
                pTooltip="Save"
                tooltipPosition="top"
                style="margin-left: 5px"
                (click)="saveFocMaterialDetail()"
              >
                <mat-icon style="color: #fff">save</mat-icon>
              </button>
            </div>
            <div class="mat-elevation-z8">
              <table mat-table [dataSource]="materialData">
                <ng-container matColumnDef="desc">
                  <th mat-header-cell *matHeaderCellDef>
                    Material Description
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.desc }}</td>
                </ng-container>

                <ng-container matColumnDef="qty">
                  <th mat-header-cell *matHeaderCellDef>Quantity/Unit</th>
                  <td mat-cell *matCellDef="let element">{{ element.qty }}</td>
                </ng-container>

                <ng-container matColumnDef="unit">
                  <th mat-header-cell *matHeaderCellDef>Unit Price</th>
                  <td mat-cell *matCellDef="let element">{{ element.unit }}</td>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef>Amount</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.amount }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="remove">
                  <th mat-header-cell *matHeaderCellDef>
                    <button mat-icon-button>
                      <mat-icon>clear_all</mat-icon>
                    </button>
                  </th>
                  <td mat-cell *matCellDef="let element; let rem = index">
                    <button
                      mat-icon-button
                      matTooltip="Remove"
                      (click)="removeFocMaterialDetail(element)"
                    >
                      <mat-icon>clear</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="focFirstColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: focFirstColumns"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="3">
                    No Data Found
                  </td>
                </tr>
              </table>
              <mat-paginator
                #FirstTablePaginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
            <div class="amount-wrapper">
              <p>
                Total Material Cost: <span>{{ TotalMaterialCost }}</span>
              </p>
            </div>
            <h3>Additional Man hours for reworks</h3>
            <div class="content-top-row">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 25%; margin-left: 0px"
              >
                <mat-label>No of Workers</mat-label>
                <input
                  id="FOCWorkerNo"
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 25%"
              >
                <mat-label>No of Hours</mat-label>
                <input
                  id="FOCWorkerHourNo"
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 25%"
              >
                <mat-label>Rate Per Hour</mat-label>
                <input
                  id="FOCWorkerRate"
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  matInput
                />
              </mat-form-field>
              <button
                type="submit"
                mat-mini-fab
                mat-button
                color="primary"
                pTooltip="Save"
                tooltipPosition="top"
                style="margin-left: 5px"
                (click)="saveFocWorkerDetail()"
              >
                <mat-icon style="color: #fff">save</mat-icon>
              </button>
            </div>
            <div class="mat-elevation-z8">
              <table mat-table [dataSource]="workersData">
                <ng-container matColumnDef="workers">
                  <th mat-header-cell *matHeaderCellDef>No of Workers</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.workers }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="hours">
                  <th mat-header-cell *matHeaderCellDef>No of Hours</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.hours }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="rate">
                  <th mat-header-cell *matHeaderCellDef>Rate</th>
                  <td mat-cell *matCellDef="let element">{{ element.rate }}</td>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef>Amount</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.amount }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="remove">
                  <th mat-header-cell *matHeaderCellDef>
                    <button mat-icon-button>
                      <mat-icon>clear_all</mat-icon>
                    </button>
                  </th>
                  <td mat-cell *matCellDef="let element; let rem = index">
                    <button
                      mat-icon-button
                      matTooltip="Remove"
                      (click)="removeFocWorkerDetail(element)"
                    >
                      <mat-icon>clear</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="focSecondColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: focSecondColumns"
                ></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="3">
                    No Data Found
                  </td>
                </tr>
              </table>
              <mat-paginator
                #SecondTablePaginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
            <div class="amount-wrapper">
              <p>
                Total Workers Cost: <span>{{ TotalWorkersCost }}</span>
              </p>
            </div>
            <h3>Additional Machine Hours for reworks</h3>
            <div class="content-top-row">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 25%; margin-left: 0px"
              >
                <mat-label>Machine Description</mat-label>
                <input id="FOCMachineDescription" matInput />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 25%"
              >
                <mat-label>Machine Hours</mat-label>
                <input
                  id="FOCMachineHourNo"
                  type="Number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 25%"
              >
                <mat-label>Per Hour Rate</mat-label>
                <input
                  id="FOCMachineRate"
                  type="Number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  matInput
                />
              </mat-form-field>
              <button
                type="submit"
                mat-mini-fab
                mat-button
                color="primary"
                pTooltip="Save"
                tooltipPosition="top"
                style="margin-left: 5px"
                (click)="saveFocMachineDetail()"
              >
                <mat-icon style="color: #fff">save</mat-icon>
              </button>
            </div>
            <div class="mat-elevation-z8">
              <table mat-table [dataSource]="machineData">
                <ng-container matColumnDef="desc">
                  <th mat-header-cell *matHeaderCellDef>Machine Description</th>
                  <td mat-cell *matCellDef="let element">{{ element.desc }}</td>
                </ng-container>

                <ng-container matColumnDef="hours">
                  <th mat-header-cell *matHeaderCellDef>Machine Hours</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.hours }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="rate">
                  <th mat-header-cell *matHeaderCellDef>Machine Rate</th>
                  <td mat-cell *matCellDef="let element">{{ element.rate }}</td>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef>Amount</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.amount }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="remove">
                  <th mat-header-cell *matHeaderCellDef>
                    <button mat-icon-button>
                      <mat-icon>clear_all</mat-icon>
                    </button>
                  </th>
                  <td mat-cell *matCellDef="let element; let rem = index">
                    <button
                      mat-icon-button
                      matTooltip="Remove"
                      (click)="removeFocMachineDetail(element)"
                    >
                      <mat-icon>clear</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="focThirdColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: focThirdColumns"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="3">
                    No Data Found
                  </td>
                </tr>
              </table>
              <mat-paginator
                #SecondTablePaginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
            <div class="amount-wrapper">
              <p>
                Total Machine Cost: <span>{{ TotalMachineCost }}</span>
              </p>
            </div>
          </div>
          <div class="summary-form">
            <div class="p-grid" style="background-color: #f5f5f5">
              <div class="p-col-12 d-flex">
                <div class="p-col-12">
                  <span class="header">Summary:</span>
                  <div class="content-row-wrap">
                    <mat-form-field
                      class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 100%; margin-left: 0px"
                    >
                      <mat-label>Total Materials Cost: (SAR)</mat-label>
                      <input
                        [formControlName]="'totalmaterialcost'"
                        readonly
                        matInput
                      />
                    </mat-form-field>
                    <mat-form-field
                      class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 100%; margin-left: 0px"
                    >
                      <mat-label>Total Labour Cost: (SAR)</mat-label>
                      <input
                        [formControlName]="'totalworkerscost'"
                        readonly
                        matInput
                      />
                    </mat-form-field>
                    <mat-form-field
                      class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 100%; margin-left: 0px"
                    >
                      <mat-label>Total Machine Cost: (SAR)</mat-label>
                      <input
                        [formControlName]="'totalmachinecost'"
                        readonly
                        matInput
                      />
                    </mat-form-field>
                    <mat-form-field
                      class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 100%; margin-left: 0px"
                    >
                      <mat-label>Total Cost: (SAR)</mat-label>
                      <input
                        [formControlName]="'totalcost'"
                        readonly
                        matInput
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>
    </form>
  </ng-template>

  <ng-template #displayMaterialDisposalModal>
    <form [formGroup]="focApprovingAuthorityDisposeform">
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <div class="float:left;">
            <h2>Material Disposal</h2>
          </div>
          <div class="float:right;">
            <button
              type="submit"
              mat-mini-fab
              mat-button
              color="primary"
              pTooltip="Save"
              (click)="saveFOCDisposeApprovingAuthority()"
              tooltipPosition="top"
              style="margin-right: 5px"
            >
              <mat-icon style="color: #fff">save</mat-icon>
            </button>
            <button
              mat-mini-fab
              mat-button
              (click)="sortDialogRef.close()"
              color="warn"
              pTooltip="Close"
              tooltipPosition="top"
            >
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
          </div>
        </div>
      </div>
      <mat-dialog-content
        class="popup"
        style="height: 85%; min-height: 80vh !important"
      >
        <div
          class="p-grid"
          style="background-color: blue; height: 100px; padding: 15px"
        >
          <div class="p-col-2">
            <img src="../../../assets/images/logo-full.png" />
          </div>
          <div class="p-col-7">
            <div class="header-subtitle">
              <p>Authorization for Material Disposal</p>
            </div>
            <!-- <div class="d-flex justify-content-end">
              <p>AMD No: <span>198631</span></p>
            </div> -->
          </div>
          <div class="p-col-3">
            <div class="header-left">
              <p style="color: #fff">Code: {{ FormId }}</p>
              <div class="imgflex">
                <img
                  *ngIf="imageFile && imageSrc"
                  (click)="imageZoomModal()"
                  [src]="imageSrc"
                  alt=""
                  width="60px"
                  height="40px"
                />
                <!-- <img
                  class="imgrel"
                  (click)="imageZoomModal()"
                  [src]="ImageUrl + headerData.UploadFile"
                  alt=""
                  width="60px"
                  height="40px"
                /> -->
                <img
                  *ngIf="(imageFile && imageSrc) || headerData.UploadFile"
                  src="assets/images/delete.png"
                  (click)="deleteImageFile()"
                  class="imgabsol"
                  alt=""
                  width="15px"
                  height="15px"
                  style="margin-left: -15px; margin-top: -25px"
                />
                <button
                  mat-flat-button
                  color="accent"
                  type="button"
                  class="upload"
                  (click)="openImageFile()"
                  style="margin-left: 2px"
                >
                  <mat-icon>attach_file</mat-icon>
                </button>
                <input
                  type="file"
                  (change)="readFile($event)"
                  class="d-none"
                  #fileInput
                  accept="image/*"
                />
              </div>
              <!-- <div class="d-flex justify-content-center">
                <p style="color: #fff;">Code: {{FormId}}</p>
              </div>
              <div class="d-flex justify-content-center" style="margin-left:-37px;">
                <p style="color: #fff;">Implementation Date: {{FormDate}}</p>
              </div> -->
            </div>
          </div>
        </div>
        <div class="form-wrapper">
          <div class="left-form" style="width: 59%">
            <!-- <div class="d-flex justify-content-end" style="margin-top: 10px;">
              <p>Date: <span>22/03/2021</span></p>
            </div> -->
            <div class="content-row-wrap">
              <!-- <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style=" width: 46%;">
                <mat-label>Reference J.B. No:</mat-label>
                <input [formControlName]="'referenceno'" required matInput>
              </mat-form-field> -->
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Product Description</mat-label>
                <input
                  [formControlName]="'productdescription'"
                  required
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Product Code</mat-label>
                <input [formControlName]="'productcode'" required matInput />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Product Order No</mat-label>
                <input
                  [formControlName]="'productionorderno'"
                  required
                  matInput
                />
              </mat-form-field>

              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Sales Reference No</mat-label>
                <input
                  [formControlName]="'salesReferenceNo'"
                  required
                  matInput
                />
              </mat-form-field>

              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Non Conformance Description</mat-label>
                <input [formControlName]="'nonConformance'" required matInput />
              </mat-form-field>

              <!-- <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Customer Name</mat-label>
                <input [formControlName]="'customername'" required matInput />
              </mat-form-field> -->
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="height: 100px; width: 100%"
              >
                <mat-label>{{ RCDTitle }} Details</mat-label>
                <textarea
                  rows="3"
                  [formControlName]="'concessionDetails'"
                  matInput
                ></textarea>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>{{ RCDTitle }} Quantity</mat-label>
                <input
                  type="number"
                  [formControlName]="'disposedquantity'"
                  readonly
                  matInput
                />
              </mat-form-field>
              <!-- <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style=" width: 47%;">
                <mat-label>Design No</mat-label>
                <input [formControlName]="'designno'" required matInput>
              </mat-form-field> -->

              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="height: 100px; width: 100%"
              >
                <mat-label>{{ RCDTitle }} Reason</mat-label>
                <input [formControlName]="'disposedreason'" required matInput />
              </mat-form-field>
            </div>
          </div>
          <div class="right-form" style="width: 39%">
            <!-- <div class="content-top-row">

            </div> -->
            <div class="content-row-wrap">
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>NCR Quantity Hold</mat-label>
                <input
                  type="number"
                  [formControlName]="'quantityhold'"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Sorting Rejected Quantity</mat-label>
                <input
                  type="number"
                  [formControlName]="'quantityfailed'"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Re-work Cost</mat-label>
                <input
                  type="number"
                  [formControlName]="'reworkcost'"
                  (change)="calculateTotalCost()"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Sorting Cost</mat-label>
                <input
                  type="number"
                  [formControlName]="'sortingcost'"
                  (change)="calculateTotalCost()"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Other Cost</mat-label>
                <input
                  type="number"
                  [formControlName]="'othercost'"
                  (change)="calculateTotalCost()"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-label>Other Cost Remarks</mat-label>
                <input
                  type="text"
                  [formControlName]="'othercostremarks'"
                  matInput
                />
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <input
                  matInput
                  formControlName="startDate"
                  [matDatepicker]="startDatePic"
                  placeholder="Start Date"
                  readonly
                  (click)="startDatePic.open()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startDatePic"
                ></mat-datepicker-toggle>
                <mat-datepicker #startDatePic> </mat-datepicker>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <input
                  matInput
                  formControlName="endDate"
                  [matDatepicker]="endDatePic"
                  placeholder="End Date"
                  readonly
                  (click)="endDatePic.open()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="endDatePic"
                ></mat-datepicker-toggle>
                <mat-datepicker #endDatePic> </mat-datepicker>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-select
                  ngxMatSelect
                  [hasSearchBox]="true"
                  [useMobileView]="true"
                  mobileViewType="BottomSheet"
                  [formControlName]="'preparedby'"
                  [multiple]="false"
                  name="fullName"
                  [source]="UsersList"
                  [displayMember]="'fullName'"
                  placeholder="Prepared By"
                  [valueMember]="'EmpInfo_Id'"
                  #focprocesspreparedby="ngxMatSelect"
                  required
                >
                  <mat-option
                    *ngFor="let usel of focprocesspreparedby.filteredSource"
                    [value]="usel.EmpInfo_Id"
                  >
                    {{ usel.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="width: 100%"
              >
                <mat-select
                  ngxMatSelect
                  [hasSearchBox]="true"
                  [useMobileView]="true"
                  mobileViewType="BottomSheet"
                  [formControlName]="'approvedby'"
                  [multiple]="false"
                  name="fullName"
                  [source]="UsersList"
                  [displayMember]="'fullName'"
                  placeholder="Approved By"
                  [valueMember]="'EmpInfo_Id'"
                  #focprocessapprovedby="ngxMatSelect"
                  required
                >
                  <mat-option
                    *ngFor="let usel of focprocessapprovedby.filteredSource"
                    [value]="usel.EmpInfo_Id"
                  >
                    {{ usel.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="total-box-wrapper">
                <div class="total-box">
                  <p>
                    Total Cost: <span>{{ TotalCost }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="summary-form" style="background-color: #f5f5f5;">
            <div class="content-row-wrap">
              <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="height: 100px; width: 97%;">
                <mat-label>Quality Department Remarks</mat-label>
                <input [formControlName]="'qualitydeptrem'" required matInput>
              </mat-form-field>
            </div>
            <div class="content-top-row" style="padding-right: 40px; justify-content: flex-end;">
              <mat-label class="sign-label">Signed: (Supervisor/Manager)</mat-label>
              <mat-form-field appearance="fill">
                <mat-select ngxMatSelect [hasSearchBox]="true" [useMobileView]="true" mobileViewType="BottomSheet"
                  [formControlName]="'qualitydeptverify'" [multiple]="false" name="fullName" [source]="UsersList"
                  [displayMember]="'fullName'" [valueMember]="'EmpInfo_Id'" #qualitydeptverify="ngxMatSelect" required>
                  <mat-option *ngFor="let usel of qualitydeptverify.filteredSource" [value]="usel.EmpInfo_Id">
                    {{usel.fullName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="content-row-wrap">
              <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="height: 100px; width: 97%;">
                <mat-label>Production Department Remarks</mat-label>
                <input [formControlName]="'proddeptrem'" required matInput>
              </mat-form-field>
            </div>
            <div class="content-top-row" style="padding-right: 40px; justify-content: flex-end;">
              <mat-label class="sign-label">Signed: (Supervisor/Manager)</mat-label>
              <mat-form-field appearance="fill">
                <mat-select ngxMatSelect [hasSearchBox]="true" [useMobileView]="true" mobileViewType="BottomSheet"
                  [formControlName]="'proddeptverify'" [multiple]="false" name="fullName" [source]="UsersList"
                  [displayMember]="'fullName'" [valueMember]="'EmpInfo_Id'" #proddeptverify="ngxMatSelect" required>
                  <mat-option *ngFor="let usel of proddeptverify.filteredSource" [value]="usel.EmpInfo_Id">
                    {{usel.fullName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="summary-form" style="background-color: #f5f5f5;">
            <div class="content-row-wrap">
              <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                style="height: 100px; width: 97%;">
                <mat-label>Remarks</mat-label>
                <input [formControlName]="'rem'" required matInput>
              </mat-form-field>
            </div>
            <div class="content-top-row" style="padding-right: 40px; justify-content: space-between;">
              <div class="d-flex">
                <mat-label class="sign-label">Plant Manager</mat-label>
                <mat-form-field appearance="fill">
                  <mat-select ngxMatSelect [hasSearchBox]="true" [useMobileView]="true" mobileViewType="BottomSheet"
                    [formControlName]="'plantmanager'" [multiple]="false" name="fullName" [source]="UsersList"
                    [displayMember]="'fullName'" [valueMember]="'EmpInfo_Id'" #plantmanager="ngxMatSelect" required>
                    <mat-option *ngFor="let usel of plantmanager.filteredSource" [value]="usel.EmpInfo_Id">
                      {{usel.fullName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="d-flex">
                <mat-label class="sign-label">General Manager</mat-label>
                <mat-form-field appearance="fill">
                  <mat-select ngxMatSelect [hasSearchBox]="true" [useMobileView]="true" mobileViewType="BottomSheet"
                    [formControlName]="'generalmanager'" [multiple]="false" name="fullName" [source]="UsersList"
                    [displayMember]="'fullName'" [valueMember]="'EmpInfo_Id'" #generalmanager="ngxMatSelect" required>
                    <mat-option *ngFor="let usel of generalmanager.filteredSource" [value]="usel.EmpInfo_Id">
                      {{usel.fullName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div> -->
        </div>
      </mat-dialog-content>
    </form>
  </ng-template>
</div>
