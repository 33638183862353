import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TreeModule } from 'primeng/tree';
import { DropdownModule } from 'primeng/dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from 'src/app/material-module';
import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { HomeModule } from '../home.module';
import { MaintenanceBoardComponent } from './maintenance-board/maintenance-board.component';
import { OeeDashboardComponent } from './oee-dashboard/oee-dashboard.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatSelectFilterModule } from 'mat-select-filter';
import { ChartModule } from 'angular-highcharts';
import { ProductionQualityDashboardComponent } from './production-quality-dashboard/production-quality-dashboard.component';
import { QuotidianDashboardComponent } from './quotidian-dashboard/quotidian-dashboard.component';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_more from "highcharts/highcharts-more";
import SeriesLabel from 'highcharts/modules/series-label';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomBottomSheetComponent } from './custom-bottom-sheet/custom-bottom-sheet.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from 'primeng/api';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true,
    }),
    ChartsModule,
    SharedModule,
    HomeModule,
    NgApexchartsModule,
    DashboardRoutingModule,
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatTooltipModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    TreeModule,
    DropdownModule,
    NgxSpinnerModule, 
    AgGridModule,
    MatSelectFilterModule,
    HighchartsChartModule,
    ChartModule,
    MultiSelectModule ,
    FlexLayoutModule,
    DragDropModule,
    NgxMatSelectSearchModule,
    HighchartsChartModule,
  ],
  declarations: [
    MaintenanceBoardComponent,
    OeeDashboardComponent,
    ProductionQualityDashboardComponent,
    QuotidianDashboardComponent,
    CustomBottomSheetComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule {}
