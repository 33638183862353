import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { MicrotaskService } from '../../services/microtask.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { PlantGroupElement } from 'src/app/interface/plant-model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MicroTask } from 'src/app/models/OLE/micro-task';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { CrewScheduleService } from 'src/app/services/crewSchedule.service';
const moment = _moment;
@Component({
  selector: 'app-microtask',
  templateUrl: './microtask.component.html',
  styleUrls: ['./microtask.component.scss'],
})
export class MicrotaskComponent implements OnInit {
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  TaskSearchForm!: FormGroup;
  MicroTaskForm!: FormGroup;
  groupListDDL!: any[];
  plants!: any;
  UnitNode: any;
  unitid!: any;
  UnitName!: string;
  breadcrumList!: any;
  isPlantGroupModelVisible = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  selectedColor = '';
  workingcolor = false;
  waitingcolor = false;
  loadunloadcolor = false;
  transparentcolor = false;
  microtasks: MicroTask[] = [];
  down = false;
  savingData = false;
  shift!: any[];
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private microService: MicrotaskService,
    public router: Router,
    private crewService: CrewScheduleService,
    private enterpriseGroupService: EnterPriseGroupService,
    private toastrService: ToastrService
  ) {}
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;
  ngOnInit(): void {
    this.GetBreadcrumList();
    this.TaskSearchForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.MicroTaskForm = this.fb.group({
      UnitNameList: [''],
      date: [''],
      PUID: [0],
      shift: [''],
    });
    this.getGroupData();
  }

  getShifts(): void {
    const key = {
      PUID: this.MicroTaskForm.value.PUID,
      Date: moment(this.MicroTaskForm.value.date).format('YYYY-MM-DD'),
    };
    this.crewService.GetCrewScheduleList(key).then((data: any) => {
      this.shift = data.CrewScheduleList;
      this.MicroTaskForm.get('shift')?.setValue(this.shift[0]?.CrewId);
      this.getFTEMicroTasks();
    });
  }
  onChangeShift(data: any): void {
    this.getFTEMicroTasks();
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.microService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.TaskSearchForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.TaskSearchForm.get('group')?.value,
    };
    // this.MachineId = null;
    this.microService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.TaskSearchForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.MicroTaskForm.get('UnitNameList')?.setValue('');
        this.MicroTaskForm.get('shift')?.setValue('');
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  openUnit(): any {
    this.isPlantGroupModelVisible = true;
    const key = {
      EGId: this.TaskSearchForm.value.group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.UnitNode = filterData(this.plantGroupSource.data, 'Unit');
        this.expandAllNode();
      },
      (error: any) => {
        this.toastrService.error('Error', error.message);
      }
    );

    // tslint:disable-next-line:typedef
    function filterData(data: PlantGroupElement[], title: string) {
      const r = data.filter((o: any) => {
        Object.keys(o).forEach((e: any) => {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
  }

  // Icon
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  onClickUnit(node: any): void {
    const date = this.MicroTaskForm.value.date;
    if (date !== '') {
      this.isPlantGroupModelVisible = false;
      this.UnitName = node.text;
      this.unitid = node.PrimaryId;
      this.MicroTaskForm.get('UnitNameList')?.setValue(node.text);
      this.MicroTaskForm.get('PUID')?.setValue(node.PrimaryId);
      this.unitid = node.PrimaryId;
      this.getShifts();
    } else {
      this.toaster.warning('Please Select Date First');
    }
  }
  counter(i: number) {
    return new Array(i);
  }

  getFTEMicroTasks(): void {
    const shiftId = this.MicroTaskForm.value.shift;
    const shiftfound = this.shift.find((x) => x.CrewId === shiftId);
    if (shiftfound) {
      const keys = {
        puId: this.unitid,
        workingDate: moment(this.MicroTaskForm.value.date).format('YYYY-MM-DD'),
        shiftId: shiftId,
        shiftStartTime: shiftfound.ShiftStart,
        shiftEndTime: shiftfound.ShiftEnd,
      };
      this.microService.getFTEMicroTasksData(keys).subscribe(
        (response) => {
          this.microtasks = response === null ? [] : response;
        },
        (error: any) => {
          this.toaster.error(error.message);
        }
      );
    } else {
      this.toaster.warning('Shift Not Found');
      this.microtasks = [];
    }
  }

  saveMicroTasks(): void {
    debugger;
    this.savingData = true;
    const keys = {
      ptId: parseInt(this.TaskSearchForm.value.plant, 10),
      puId: this.unitid,
      workingDate: moment(this.MicroTaskForm.value.date).format('YYYY-MM-DD'),
      microtask: this.microtasks,
      shiftId: this.MicroTaskForm.get('shift')?.value,
    };
    this.microService.addFTEMicroTasksData(keys).subscribe(
      (response) => {
        this.toaster.success(response.message);
        this.getFTEMicroTasks();
        this.savingData = false;
      },
      (error: any) => {
        this.toaster.error(error.message);
        this.savingData = false;
      }
    );
  }
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }
  // color change and update color in data on mouse drag start

  changeColorFirstHalf(id: number, hourId: number, date: any): void {
    this.down = true;
    if (this.selectedColor !== '') {
      const microtask = this.microtasks.find((x) => x.id === id);
      if (microtask) {
        const hourfound = microtask.microHours.find(
          (x) => x.id === hourId && x.dates === date
        );
        if (hourfound) {
          hourfound.firstHalf = this.selectedColor;
        }
      }
    } else {
      this.toaster.warning('Please Select Color First');
    }
  }
  changeColorSecondHalf(id: number, hourId: number, date: any): void {
    this.down = true;
    if (this.selectedColor !== '') {
      const microtask = this.microtasks.find((x) => x.id === id);
      if (microtask) {
        const hourfound = microtask.microHours.find(
          (x) => x.id === hourId && x.dates === date
        );
        if (hourfound) {
          hourfound.secondHalf = this.selectedColor;
        }
      }
    } else {
      this.toaster.warning('Please Select Color First');
    }
  }
  mouseoverFirstHalf(id: number, hourId: number, date: any): void {
    if (this.down) {
      if (this.selectedColor !== '') {
        const microtask = this.microtasks.find((x) => x.id === id);
        if (microtask) {
          const hourfound = microtask.microHours.find(
            (x) => x.id === hourId && x.dates === date
          );
          if (hourfound) {
            hourfound.firstHalf = this.selectedColor;
          }
        }
      } else {
        this.toaster.warning('Please Select Color First');
      }
    }
  }
  mouseoverSecondHalf(id: number, hourId: number, date: any): void {
    if (this.down) {
      if (this.selectedColor !== '') {
        const microtask = this.microtasks.find((x) => x.id === id);
        if (microtask) {
          const hourfound = microtask.microHours.find(
            (x) => x.id === hourId && x.dates === date
          );
          if (hourfound) {
            hourfound.secondHalf = this.selectedColor;
          }
        }
      } else {
        this.toaster.warning('Please Select Color First');
      }
    }
  }

  mouseup(): void {
    this.down = false;
  }

  selectColor(color: string, type: number): void {
    switch (type) {
      case 1:
        this.workingcolor = true;
        this.waitingcolor = false;
        this.loadunloadcolor = false;
        this.transparentcolor = false;
        break;
      case 2:
        this.workingcolor = false;
        this.waitingcolor = true;
        this.loadunloadcolor = false;
        this.transparentcolor = false;
        break;
      case 3:
        this.workingcolor = false;
        this.waitingcolor = false;
        this.loadunloadcolor = true;
        this.transparentcolor = false;
        break;
      case 4:
        this.workingcolor = false;
        this.waitingcolor = false;
        this.loadunloadcolor = false;
        this.transparentcolor = true;
        break;
    }
    this.selectedColor = color;
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
}
