import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ButtonViewRendererComponent } from 'src/app/components/button-view-render.components';
import { CILDashboardService } from 'src/app/services/cil-dashboard-service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { DtServiceService } from 'src/app/services/dtService.service';
import { environment } from 'src/environments/environment';
import { DefectDashBoardService } from 'src/app/services/defect-dashboard-service';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-new-defect-dashboard',
  templateUrl: './new-defect-dashboard.component.html',
  styleUrls: ['./new-defect-dashboard.component.scss'],
})
export class NewDefectDashboardComponent implements OnInit {
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('popupViewModal') private popupViewModal!: TemplateRef<any>;
  displayedColumns = ['name', 'assignto', 'date', 'newDate', 'closedate', 'comments'];
  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  filteredUnits: any;
  filteredComponents: any;
  breadcrumList: any;
  moduleId!: any;
  pageId!: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any;
  unitList!: any;
  componentList!: any;
  CLLDashboardForm!: FormGroup;
  TotalDefects!: any;
  TotalDefecteSolved!: any;
  CILAdherence!: any;
  TotalDefectsPerc!: any;
  CILTask!: any;
  
  dashboardData: any;
  rowData!: any[];
  chartDS!: Chart;
  chartDP!: Chart;
  chartDC!: Chart;
  chartDT!: Chart;
  
  chartCILTask!: Chart;
  chartCILType!: Chart;
  chartCILTool!: Chart;
  options!: Options;
  image!: any;
  currentImagePath!: any;
  defaultExcelExportParams: any;
  frameworkComponents: any;

  // iconUrl
  menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';
  dashboarUnitData: any[];
  dashboardLineData: any[];
  linepercentageGraphData: any = [];
  chartLinePercentage: Chart;
  unitpercentageGraphData: any = [];
  chartUnitPercentage: Chart;
  deparmentFiltList: any;
  deparmentList: any;
  gridApi: any;
  gridColumnApi: any;
  paginationPageSize: 5;
  flag = true;
  gridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
  };
  todayDate = new Date();
  disabledDate = (current: Date): boolean =>
  // Can not select days before today and today
  differenceInCalendarDays(current, this.todayDate) > 0;
  columnDefs: ({ headerName: string; field: string; filter: string; valueFormatter?: undefined; cellRenderer?: undefined; cellRendererParams?: undefined; maxWidth?: undefined; suppressMenu?: undefined; } | { headerName: string; field: string; filter: string; valueFormatter: (params: any) => string; cellRenderer?: undefined; cellRendererParams?: undefined; maxWidth?: undefined; suppressMenu?: undefined; } | { headerName: string; field: string; cellRenderer: (params: any) => string; filter?: undefined; valueFormatter?: undefined; cellRendererParams?: undefined; maxWidth?: undefined; suppressMenu?: undefined; } | { headerName: string; cellRenderer: string; cellRendererParams: { onClick: any; label: string; }; maxWidth: number; suppressMenu: boolean; field?: undefined; filter?: undefined; valueFormatter?: undefined; })[];
  actionDataSource: any;
  totalCount: any;
  openCount: any;
  inProgressCount: any;
  delayCount: any;
  closedCount: any;
  completedCount: any;
  key: { groupId: any; plantId: any; lineId: number; UnitId: number; ComponentId: number; UserId: number; dtStartDate: Date; dtEndDate: Date; Status: string; };
  imageUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private productivityservice: ProductivityService,
    private cilService: CILDashboardService,
    private toaster: ToastrService,
    public router: Router,
    private userService: UserManagementService,
    private enterpriseGroupService: EnterPriseGroupService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private dtService: DtServiceService,
    private defectService :DefectDashBoardService
  ) {
  }

  ngOnInit(): void {
    this.imageUrl = environment.O3CoreApiImageUrl;
    this.frameworkComponents = {
      buttonRenderer: ButtonViewRendererComponent,
    };
    this.columnDefs = [
      { headerName: 'Defect Entry', field: 'DefectEntry', filter: 'agTextColumnFilter' },
      { headerName: 'Priority', field: 'Priority', filter: 'agTextColumnFilter' },
      { headerName: 'Status', field: 'Status', filter: 'agTextColumnFilter' },
      { headerName: 'Line', field: 'PL_Desc', filter: 'agTextColumnFilter' },
      { headerName: 'Machine', field: 'PU_Desc', filter: 'agTextColumnFilter' },
      { headerName: 'Component', field: 'ComponentName', filter: 'agTextColumnFilter' },
      { headerName: 'Defect Type', field: 'DefectType', filter: 'agTextColumnFilter' },
      // { headerName: 'Assign To', field: 'AssignTo', filter: 'agTextColumnFilter' },
      // { headerName: 'Target Date', field: 'TargetDate', filter: 'agDateColumnFilter', valueFormatter: function (params: any) {return params.value !== null ? moment(params.value).format('MM/DD/YYYY HH:mm:ss') : '' }},
      { headerName: 'Close Date', field: 'CloseDate', filter: 'agDateColumnFilter', valueFormatter: function (params: any) { return params.value !== null ? moment(params.value).format('MM/DD/YYYY HH:mm:ss') : '' } },
      // { headerName: 'Comments', field: 'Comments', filter: 'agTextColumnFilter' },
      { headerName: 'PostImageName', field: 'PostImageName', cellRenderer: (params: any) => { return `<img style="width:40px;height:40px ;cursor:pointer" alt="${params.value}" src="${params.value !== '' ? this.imageUrl + params.value : 'assets/images/no-image.png'}">` } },
      { headerName: 'PreImageName', field: 'PreImageName', cellRenderer: (params: any) => { return `<img style="width:40px;height:40px ;cursor:pointer" alt="${params.value}" src="${params.value !== '' ? this.imageUrl + params.value : 'assets/images/no-image.png'}">` } },
      { headerName: 'Created By', field: 'CreatedBy', filter: 'agTextColumnFilter' },
      { headerName: 'Created On', field: 'CreatedOn', filter: 'agDateColumnFilter', valueFormatter: function (params: any) { return moment(params.value).format('MM/DD/YYYY HH:mm:ss') } },
      { headerName: 'Action', cellRenderer: 'buttonRenderer', cellRendererParams: { onClick: this.viewAction.bind(this), label: 'View' }, maxWidth: 85, suppressMenu: true },
    ]

    this.defaultExcelExportParams = {
      addImageToCell: function (
        rowIndex: number,
        col: any,
        value: any,
        callback: any
      ) {
        if (col.colId === 'PreImage' || col.colId === 'PostImage') {
        } else {
          return;
        }
      },
    };
    this.initform();
    this.CLLDashboardForm.controls.unit.patchValue('0');
    this.CLLDashboardForm.controls.components.patchValue('0');
    this.CLLDashboardForm.controls.line.patchValue('0');
    this.CLLDashboardForm.controls.Departments.patchValue('0');
    this.datasource();
    this.GetBreadcrumList();
  }

  initform() {
    
    this.CLLDashboardForm = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      Departments: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      components: ['', Validators.required],
      // dateStart: [new Date(), Validators.required],
      // dateEnd: [new Date (), Validators.required],
      datePicker : [[new Date(moment().subtract(1, 'd').toString()), new Date()]] , 
      shift: ['', []],
    });
  }
  
  drawDefectType() {
    let dtLabel: any[] = new Array();
    let dtData: any[] = new Array();
    if (this.dashboardData.lstDefectType !== null && this.dashboardData.lstDefectType.length > 0) {
      this.dashboardData.lstDefectType.forEach((element: any) => {
        dtLabel.push(element.Name);
        dtData.push([element.Name, parseFloat(element.Countt)]);
      });
    }
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: dtLabel,
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defect Type',
        type: 'column',
        data: dtData
      }]
    };
    let chart = new Chart(this.options);
    this.chartDT = chart;
  }

  drawDefectClassification() {
    let dcLabel: any[] = new Array();
    let dcData: any[] = new Array();
    if (this.dashboardData.lstClassification !== null && this.dashboardData.lstClassification.length > 0) {
      this.dashboardData.lstClassification.forEach((element: any) => {
        dcLabel.push(element.Name);
        dcData.push([element.Name, parseFloat(element.Countt)]);
      });
    }
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: dcLabel,
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'CIL Classification',
        type: 'column',
        data: dcData
      }]
    };
    let chart = new Chart(this.options);
    this.chartDC = chart;
  }
  drawDefectPriority() {
    let dpLabel: any[] = new Array();
    let dpData: any[] = new Array();
    if (this.dashboardData.lstPriority !== null && this.dashboardData.lstPriority.length > 0) {
      this.dashboardData.lstPriority.forEach((element: any) => {
        dpLabel.push(element.Name);
        dpData.push([element.Name, parseFloat(element.Countt)]);
      });
    }
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: dpLabel,
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defect Priority',
        type: 'column',
        data: dpData
      }]
    };
    let chart = new Chart(this.options);
    this.chartDP = chart;
  }
  drawDefectStatus() {
    let dsLabel: any[] = new Array();
    let dsData: any[] = new Array();
    if (this.dashboardData.lstStatus !== null && this.dashboardData.lstStatus.length > 0) {
      this.dashboardData.lstStatus.forEach((element: any) => {
        dsLabel.push(element.Name);
        dsData.push([element.Name, parseFloat(element.Countt)]);
      });
    }
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: dsLabel,
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'defect Status',
        type: 'column',
        data: dsData
      }]
    };
    let chart = new Chart(this.options);
    this.chartDS = chart;
  };
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }
  cellClicked(params: any): void {
    const col = params.colDef.field;
    if (col === 'PostImageName') {
      this.image = this.imageUrl + params.data.PostImageName;
      this.openPictureBeforeModal();
    } if (col === 'PreImageName') {
      this.image = this.imageUrl + params.data.PreImageName;
      this.openPictureBeforeModal();
    }
    // if (col === 'pictureAfter') {
    //   this.GembaId = params.data.gembaWalkId;

    //   this.image = params.data.pictureAfter;
    //   this.openFileModal();
    // }

  }

  getDefectDashboard() {
    this.spinner.show();
    this.defectService.getDefectDashboard(this.key).subscribe((data: any) => {
      this.dashboardData = data;
      this.spinner.hide();
      this.setData1();
    }, (error: any) => {
      this.toaster.error('error', error.ErrorMessage);
      this.spinner.hide();
    });
  }
  setData1() {
    if (this.dashboardData !== null) {
      this.totalCount = this.dashboardData.totalCount;
      this.openCount = this.dashboardData.openCount;
      this.inProgressCount = this.dashboardData.inProgressCount;
      this.delayCount = this.dashboardData.delayCount;
      this.closedCount = this.dashboardData.closedCount;
      this.completedCount = this.dashboardData.completedCount;
      // this.drawDefectStatus();
      // this.drawDefectPriority();
      // this.drawDefectClassification();
      // this.drawDefectType();
      this.rowData = this.dashboardData.defectlist;
    }
  }
 
  viewAction(element: any): void {
    this.dialog.open(this.popupViewModal, {
      width: '50vw !important',
      height: 'auto',
      minWidth: '50vw',

    })
    this.actionDataSource = element.data.ActionPlan;
  }
  openPictureBeforeModal(): void {
    this.currentImagePath = this.image === ''
      ? 'assets/images/no-image.png'
      : this.image;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }
  imageZoomModal(imagePath: String): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }


  excelExport() {
    this.gridApi.exportDataAsExcel();
  }
  csvExport() {
    this.gridApi.exportDataAsCsv();
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  onChangeLine(data: any): void {
    if (parseInt(data.value) === 0) {
      this.filteredUnits.length = 0;
      this.filteredComponents.length = 0;
      this.CLLDashboardForm.controls.unit.patchValue('0');
      this.CLLDashboardForm.controls.components.patchValue('0');
      this.getUnit(parseInt(data.value));
    } else {
      this.getUnit(data.value);
    }
  }
  onChangePlant(data: any) {
    this.getDepartments(data.value);
  }

  onChangeDepartments(data: any) {
    this.getLine(data.value);
  }
  onChangeUnit(data: any): void {
    this.getComponent(data.value);
  }
  datasource(): void {
    const key = {
      UserId: JSON.parse(localStorage.user).UserId,
    };
    const id = {
      value: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.productivityservice.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.lstEG;
        id.value = data.lstEG[0].EG_Id;
        this.CLLDashboardForm.get('UserGroup1')?.setValue(data.lstEG[0].EG_Id);
        this.CLLDashboardForm.get('UserPlant1')?.setValue(data.lstEG[0].EG_Id);
        this.filteredGroups = this.GroupList.slice();
        this.getPlants(id);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: JSON.parse(localStorage.user).UserId,
    };

    this.productivityservice.getPlantsData(key).subscribe(
      (data: any) => {
        this.PlantList1 = data.lstPlant;
        const id = data.lstPlant[0].PT_Id;
        this.CLLDashboardForm.get('UserPlant1')?.patchValue(id);
        this.filteredPlants = this.PlantList1.slice();
        this.getDepartments(id);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getDepartments(id: any) {
    const key = {
      ptId: this.CLLDashboardForm.value.UserPlant1,
    };
    this.spinner.show();
    this.dtService.getDeptData(key).subscribe(
      (res: any) => {
        this.deparmentList = res;
        this.deparmentFiltList = this.deparmentList.slice();
        if (this.deparmentFiltList && this.deparmentFiltList.length) {
          // this.CLLDashboardForm.controls.Departments.patchValue(
          //   this.deparmentList[0].TypeId
          // );
        this.getLine(id);
        }
        else
        {
          if(this.flag)
          {
            this.onSubmit();
          }
        }

        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getLine(id: any) {
    // var selectedDepart : any[] = [];
    // if(this.CLLDashboardForm.value?.line == '0')
    // {
    // for(var i = 0; i< this.deparmentList?.length; i++)
    //  {
    //   selectedDepart.push(this.deparmentList[i].TypeId);
    // }
    // }
    // else {
    //   selectedDepart.push(this.CLLDashboardForm.value.TypeId)
    // }
    const key = {
      PT_ID: this.CLLDashboardForm.value.UserPlant1,
      Dept_ID: this.CLLDashboardForm.value.Departments,
     // Dept_ID : selectedDepart.toString(),
      userId: JSON.parse(localStorage.user).UserId,
    };

    this.productivityservice.getLineByDeptData(key).subscribe(
      (data: any) => {
        this.lineList = data.lstLine;
        const lineDefault = this.lineList[0];
        if (this.lineList.length !== 0) {
          // this.CLLDashboardForm.controls.line.patchValue(lineDefault.PL_Id);
          // this.getUnit(lineDefault.PL_Id);
          this.getUnit(0);
        } else {
          if(this.flag)
          {
            this.onSubmit();
          }
         
        }
        this.filteredLines = this.lineList.slice();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getUnit(id: number) {
    var selectedLine: any[] = [];
    if(this.CLLDashboardForm.value.line ==0)
    {
      for (var i = 0; i < this.lineList?.length; i++) {
        selectedLine.push(this.lineList[i].PL_Id);
      }
    }
    else {
      selectedLine.push(this.CLLDashboardForm.value.line)
    }
    const key = {
      PL_ID: selectedLine.toString(),
      userId: JSON.parse(localStorage.user).UserId,
      PT_ID: this.CLLDashboardForm.value.UserPlant1,
    };

    this.productivityservice.getUnitData(key).subscribe(
      (data: any) => {
        this.unitList = data.lstUnit;
        const unitDefault = this.unitList[0];
        if (this.unitList.length !== 0) {
          // this.CLLDashboardForm.controls.unit.patchValue(unitDefault.PU_Id);
          // this.getComponent(unitDefault.PU_Id);
          this.getComponent(0);
        } else {
          if(this.flag)
          {

            this.onSubmit();
          }

        }
        
        this.filteredUnits = this.unitList.slice();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getComponent(id: number) {
    const key = {
      PU_ID: id,
      UserId: JSON.parse(localStorage.user).UserId,
      PT_ID: this.CLLDashboardForm.value.UserPlant1,
    };
    this.productivityservice.getComponentData(key).subscribe(
      (data: any) => {
        this.componentList = data.lstComponent;
        const comDefault = this.componentList[0];
        this.componentList.length !== 0
          ? this.CLLDashboardForm.controls.components.patchValue(
              comDefault.ComponentId
            )
          : this.CLLDashboardForm.controls.components.patchValue('0');
        this.filteredComponents = this.componentList.slice();
        if(this.flag)
        {
          this.onSubmit();
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onSubmit(): void {
    this.getAMCILDashboard();
    this.makeData();
    this.getDefectDashboard();
    this.flag = false
  }
  getAMCILDashboard() {
    var selectDepartment :any[] = [];
    var selectedLine: any[] = [];
    var selectedUnit :any[]  = [];
    
    const data = {
      dateStart: this.CLLDashboardForm?.get('datePicker')?.value[0],
      dateEnd: this.CLLDashboardForm?.get('datePicker')?.value[1],
      groupId: this.CLLDashboardForm.value.UserGroup1,
      PTID: this.CLLDashboardForm.value.UserPlant1,
      DeptId: this.CLLDashboardForm.value.Departments,
      PLID: this.CLLDashboardForm.value.line,
      PUID: this.CLLDashboardForm.value.unit,
    };
    this.spinner.show();
    this.dashboardData = [];
    this.linepercentageGraphData = [];
    this.unitpercentageGraphData = [];
    this.dashboardLineData = [];
    this.dashboarUnitData = [];
    this.cilService.getDefectCILDashboard(data).subscribe(
      (data: any) => {
        this.dashboardData = data;
        this.spinner.hide();
        this.setData();
      },
      (error: any) => {
        this.toaster.error('error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
  }
  makeData() {
    debugger;
    const startDt = this.CLLDashboardForm?.get('datePicker')?.value[0];
    const endDt = this.CLLDashboardForm?.get('datePicker')?.value[1];
    this.key = {
      groupId: this.CLLDashboardForm.value.UserGroup1,
      plantId: this.CLLDashboardForm.value.UserPlant1,
      lineId: parseInt(this.CLLDashboardForm.value.line),
      UnitId: parseInt(this.CLLDashboardForm.value.unit),
      ComponentId: parseInt(this.CLLDashboardForm.value.components),
      UserId: parseInt(this.CLLDashboardForm.value.user),
      dtStartDate: startDt,
      dtEndDate: endDt,
      Status: '*'
    };

  }
  setData() {
    if (this.dashboardData !== null) {
      const LineData = [];
      let data = [];
      let machineName = '';
      this.dashboardData.DefectDashDeptList.map(
        (plant: any, plantindex: number) => {
          data = [];
          const ID = this.dashboardData.DefectDashDeptList[plantindex].Id;
          const done = this.dashboardData.DefectDashDeptList[plantindex].Done;
          const totalCount =
            this.dashboardData.DefectDashDeptList[plantindex].TotalCount;

          machineName = this.dashboardData.DefectDashDeptList[plantindex].Name;
          const percentage = (
            (parseInt(done) / parseInt(totalCount)) *
            100
          ).toFixed(2);
          data.push(totalCount);
          data.push(done);
          this.linepercentageGraphData.push({
            name: machineName,
            type: 'column',

            data: [parseInt(percentage)],
          });
          LineData.push({
            name: machineName,
            type: 'column',
            data: data,
          });
        }
      );
      this.dashboardLineData = LineData;

      if (this.dashboardData.DefectDashUnitList) {
        const unitdata = [];
        let dataUnit = [];
        let machineNameUnit = '';
        this.dashboardData.DefectDashUnitList.map(
          (plant: any, plantindex: number) => {
            dataUnit = [];
            const ID = this.dashboardData.DefectDashUnitList[plantindex].Id;

            machineNameUnit =
              this.dashboardData.DefectDashUnitList[plantindex].Name;
            const done = this.dashboardData.DefectDashUnitList[plantindex].Done;
            const totalCount =
              this.dashboardData.DefectDashUnitList[plantindex].TotalCount;
            const percentage = (
              (parseInt(done) / parseInt(totalCount)) *
              100
            ).toFixed(2);
            dataUnit.push(totalCount);
            dataUnit.push(done);
            this.unitpercentageGraphData.push({
              name: machineNameUnit,
              type: 'column',
              data: [parseInt(percentage)],
            });

            unitdata.push({
              name: machineNameUnit,
              type: 'column',
              data: dataUnit,
            });
          }
        );

        this.dashboarUnitData = unitdata;
      }

      this.drawCILUnitChart();
      this.drawCilLineChart();
    }
  }
  drawCILUnitChart() {
    this.options = {
      chart: {
        type: 'column',
      },

      title: {
        text: 'Defect Plan - Unit Wise',
      },

      xAxis: {
        categories: ['Defect Found', 'Defect Fixed'],
        labels: {
          style: {
            
            color: 'black',
            fontSize: '15px',
            
          },
        },
      },
      credits: {
        enabled: false
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
      },
      plotOptions: {
        column: {
         // pointPadding: 5,
           groupPadding: 0,
          pointWidth: 40,
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
          },
        },
      },

      series: this.dashboarUnitData,
    };
    if (
      this.dashboarUnitData.length > 6 &&
      this.dashboarUnitData.length < 10 &&
      this.options &&
      this.options.plotOptions.column.pointWidth
    ) {
      this.options.plotOptions.column.pointWidth = 20;
    }

    let chart = new Chart(this.options);
    this.chartCILTask = chart;
    this.drawCILUnitPercentage();
  }

  drawCilLineChart() {
    this.options = {
      chart: {
        type: 'column',
      },

      title: {
        text: 'Defect Plan - Department Wise',
      },

      xAxis: {
        categories: ['Defect Found', 'Defect Fixed', 'Adherence %'],
        labels: {
          style: {
            color: 'black',
            fontSize: '15px',
          },
        },
      },
      credits: {
        enabled: false
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
      },
      plotOptions: {
        column: {
          groupPadding: 0,
          pointWidth: 40,
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
          },
        },
      },

      series: this.dashboardLineData,
    };
    if (
      this.dashboardLineData.length > 6 &&
      this.options &&
      this.options.plotOptions.column.pointWidth
    ) {
      this.options.plotOptions.column.pointWidth = 20;
    }
    if (
      this.dashboardLineData.length > 10 &&
      this.options &&
      this.options.plotOptions.column.pointWidth
    ) {
      this.options.plotOptions.column.pointWidth = 30;
      const width = document.querySelectorAll('div.chart-items-w30-l');
      if (width && width.length) {
        width.forEach((node: any) => (node.style.width = '100%'));
      }

      const widthTwo = document.querySelectorAll('div.chart-items-l');
      if (widthTwo && widthTwo.length) {
        widthTwo.forEach((node: any) => (node.style.width = '100%'));
      }
    } else {
      const width = document.querySelectorAll('div.chart-items-w30-l');
      if (width && width.length) {
        width.forEach((node: any) => (node.style.width = '47%'));
      }

      const widthTwo = document.querySelectorAll('div.chart-items-l');
      if (widthTwo && widthTwo.length) {
        widthTwo.forEach((node: any) => (node.style.width = '48%'));
      }
    }

    let chart = new Chart(this.options);
    this.chartCILType = chart;

    this.drawCILLinePercentage();
  }
  drawCILLinePercentage() {
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Adherence - Department Wise %',
      },

      xAxis: {
        categories: ['Adherence in %'],
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
        labels: {
          overflow: 'justify',
        },
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {
          pointPadding: 4,
          // groupPadding: 0.5,
          pointWidth: 50,
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            format: '{y} %',
          },
        },
      },
      series: this.linepercentageGraphData,
    };
    if (
      this.linepercentageGraphData.length > 8 &&
      this.options &&
      this.options.plotOptions.column.pointWidth
    ) {
      this.options.plotOptions.column.pointWidth = 20;
    }

    let chart = new Chart(this.options);
    this.chartLinePercentage = chart;
  }
  drawCILUnitPercentage() {
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Adherence - Unit Wise %',
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: ['Adherence in %'],
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
        labels: {
          overflow: 'justify',
        },
      },

      plotOptions: {
        column: {
          pointPadding: 4,
          // groupPadding: 0.5,
          pointWidth: 50,
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            format: '{y} %',
          },
        },
      },

      series: this.unitpercentageGraphData,
    };
    if (
      this.unitpercentageGraphData.length > 6 &&
      this.options &&
      this.options.plotOptions.column.pointWidth
    ) {
      this.options.plotOptions.column.pointWidth = 20;
    }

    if (
      this.unitpercentageGraphData.length > 10 &&
      this.options &&
      this.options.plotOptions.column.pointWidth
    ) {
      this.options.plotOptions.column.pointWidth = 30;
      const width = document.querySelectorAll('div.chart-items-w30-u');
      if (width && width.length) {
        width.forEach((node: any) => (node.style.width = '100%'));
      }

      const widthTwo = document.querySelectorAll('div.chart-items-u');
      if (widthTwo && widthTwo.length) {
        widthTwo.forEach((node: any) => (node.style.width = '100%'));
      }
    } else {
      const width = document.querySelectorAll('div.chart-items-w30-u');
      if (width && width.length) {
        width.forEach((node: any) => (node.style.width = '47%'));
      }

      const widthTwo = document.querySelectorAll('div.chart-items-u');
      if (widthTwo && widthTwo.length) {
        widthTwo.forEach((node: any) => (node.style.width = '48%'));
      }
    }
    let chart = new Chart(this.options);
    this.chartUnitPercentage = chart;
  }

  
}
