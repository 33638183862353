import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { ButtonEditRendererComponent } from 'src/app/components/button-edit-render.components';
import { DatePickerComponent } from 'src/app/components/date-picker.components';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';

@Component({
  selector: 'app-sfdc-new',
  templateUrl: './sfdc-new.component.html',
  styleUrls: ['./sfdc-new.component.scss']
})
export class SfdcNewComponent implements OnInit {

  sfdcnewform!: FormGroup;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  productList!: any[];
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  filteredUnits: any;
  gridApi!: any;
  gridColumnApi!: any;
  columnDefs!: any;
  defaultColDef!: any;
  public frameworkComponents: any;
  rowData: any[] = new Array();
  dummyDate!: any[];
  constructor(
    private formBuilder: FormBuilder,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private productivityservice: ProductivityService,
    private spinner: NgxSpinnerService,
    private sfdcService: ShopFloorDataService,
    public router: Router,
  ) {
    this.frameworkComponents = {
      buttonViewRenderer: ButtonEditRendererComponent,
      datePicker: DatePickerComponent
    }
    this.columnDefs = [
      {headerName : 'Sr.No',  valueGetter: "node.rowIndex + 1" },
      {
        headerName :'Start Id',
        field: 'StartId',
        minWidth: 160
      },
      {  headerName :'Process Order', field: 'ProcessOrder'},
      {
        headerName :'Operation Number',
        field: 'PPD_Num',
        minWidth: 140
      },
      { headerName :'Machine', field: 'PuDesc'},
      {
        headerName :'Start Time',
        field: 'Start_Time',
        minWidth: 140,
        editable: true,
        cellEditor: 'datePicker'
      },
      {
        headerName :'End Time',
        field: 'End_Time',
        minWidth: 160,
        editable: true,
        cellEditor: 'datePicker',
      },
      { headerName: 'Action', cellRenderer: 'buttonViewRenderer', cellRendererParams: { onClick: this.onClickEdit.bind(this), label: 'View' }, maxWidth: 85, suppressMenu: true }
    ];
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter :true
    };
  }

  ngOnInit(): void {
    this.initForm();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.datasource();
  }

  //Onchange methods 
  onChangeGroup(data: any): void {
    this.getPlants(data);
  }
  onChangeLine(data: any): void {
    this.getUnit(data.value);
  }
  onChangePlant(data: any): void {
    this.getLine(data.value);
  }

  initForm() {
    const dateStart = new Date();
    //dateStart.setDate(dateStart.getDate() - 183);
    dateStart.setHours(6);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);


    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate());

    this.sfdcnewform = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
      shift: ['', []],
      searchval: [''],
    });
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  datasource(): void {
    const key = {
      UserId: 206     //later it will be change this is only for testing
    };
    const id = {
      value: 0
    };
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      if (this.GroupList.length > 0) {
        id.value = data.lstEG[0].EG_Id;
        this.sfdcnewform.get('UserGroup1')?.setValue(data.lstEG[0].EG_Id);
        this.sfdcnewform.get('UserPlant1')?.setValue(data.lstEG[0].EG_Id);
        this.filteredGroups = this.GroupList.slice();
        this.getPlants(id);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: 206
    };
    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      if (this.PlantList1.length > 0) {
        const id = data.lstPlant[0].PT_Id;
        this.sfdcnewform.get('UserPlant1')?.patchValue(id);
        this.filteredPlants = this.PlantList1.slice();
        this.getLine(id);
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getLine(id: any) {
    const key = {
      PT_ID: id,
      userId: 206
    };

    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      if (this.lineList.length > 0) {
        const lineDefault = this.lineList[0];
        this.sfdcnewform.controls.line.patchValue(lineDefault.PL_Id);
        this.filteredLines = this.lineList.slice();
        this.getUnit(lineDefault.PL_Id);
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUnit(id: number) {
    const key = {
      PL_ID: id,
      userId: 206
    };

    this.productivityservice.getUnitData(key).subscribe((data: any) => {
      this.unitList = data.lstUnit;
      if (this.unitList.length > 0) {
        const unitDefault = this.unitList[0];
        this.sfdcnewform.controls.unit.patchValue(unitDefault.PU_Id);
        this.filteredUnits = this.unitList.slice();
      }
      this.onSubmit();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  onClickEdit(data: any): void {
    this.updateProductionOrder(data.data);
  }

  updateProductionOrder(data:any):void{
    const key ={
      Start_Id : data.StartId,
      PPD_Id: data.PPD_Num, 
      StartDateTime:data.Start_Time,
      EndDateTime:data.End_Time
    }
    this.sfdcService.updateProductionDetail(key).subscribe((data: any) => {
      this.toaster.success('success',data.ErrorMessage);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  onSubmit(): void {
    const key = {
      EGId: this.sfdcnewform.get('UserGroup1')?.value,
      PlantId: this.sfdcnewform.get('UserPlant1')?.value,
      UnitId: this.sfdcnewform.get('unit')?.value,
      StartDateTime: this.dateAsYYYYMMDDHHNNSS(this.sfdcnewform.get('dateStart')?.value),
      EndDateTime: this.dateAsYYYYMMDDHHNNSS(this.sfdcnewform.get('dateEnd')?.value)
    };
    this.sfdcService.getProductionDetailList(key).subscribe((data: any) => {
      this.productList = data.lst;
      this.rowData =  this.productList;
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  dateAsYYYYMMDDHHNNSS(date: any): string {
    return date.getFullYear()
      + '-' + this.leftpad(date.getMonth() + 1, 2)
      + '-' + this.leftpad(date.getDate(), 2)
      + ' ' + this.leftpad(date.getHours(), 2)
      + ':' + this.leftpad(date.getMinutes(), 2)
      + ':' + this.leftpad(date.getSeconds(), 2);
  }
  leftpad(val: any, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
      + String(val)).slice(String(val).length);
  }
  //==================================================================== Ag Grid code Here ==================================================================//
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  
  }
  rowClicked(params:any) {
    params.node.setData({
      ...params.data,
      dirty: true
    });
  }
  getRowStyle(params:any) {
    if (params.data.dirty) {
        return { 'background-color': '#75e6b3' }
    }else{
      return { 'background-color': '#ffffff' }
    }
  }
}