<h1 mat-dialog-title>
    {{title}}
  </h1>
  
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>
  
  <div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">No</button>
    <button mat-raised-button color="accent" (click)="onConfirm()">Yes</button>
  </div>
