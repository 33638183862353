import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CILDashboardService } from 'src/app/services/cil-dashboard-service';
import { DtServiceService } from 'src/app/services/dtService.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import { MatDialog } from '@angular/material/dialog';
// import { resourceUsage } from 'process';
import { AuditDashboard } from 'src/app/services/audit-dashboard.service';
import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-five-s-dashboard',
  templateUrl: './five-s-dashboard.component.html',
  styleUrls: ['./five-s-dashboard.component.scss'],
})
export class FiveSDashboardComponent implements OnInit {
  @ViewChild('5SPoup') private groupLicensePopup!: TemplateRef<any>;
  todayDate = new Date();
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.todayDate) > 0;
  breadcrumList: any;
  isHide: boolean = true;
  moduleId: any;
  pageId: any;
  permisson: any;
  IsAccess: any;
  IsWrite: any;
  FiveSDashboardForm: any;
  spinnerText: string;
  groupList: any;
  groupFiltList: any;
  plantList: any;
  plantFiltList: any;
  charts: any = [];
  values: any = [];
  data: any;
  options = {};
  ORPGraphData: any = [];
  percentage: any;
  stock: any;
  fiveSList = [];
  drillDownData: any = [];
  barGraphDataresponse: any = [];
  barGraphData: any = [];
  fivesPoupValue: any = [];
  fivesPoupLabel: any = [];
  ORPGraphValues: any = [];
  chart: Chart;
  isPopupOpen: boolean = false;

  columnDefs: { field: string; headerName: string; }[];
  dataArray: any;
  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
  };
  gridApi: any;
  gridColumnApi: any;
  startDate: any;
  endDate: any;
  Current: any;
  Previous: any;
  SearchType: number = 1;
  SearchType2: number = 1;
  ngAfterViewInit() {
    this.generateDynamicclass();
  }
  ngOnInit() {
    this.columnDefs = [
      { field: 'EG_Desc', headerName: 'Group' },
      { field: 'PT_Desc', headerName: 'Plant' },
      { field: 'AreaName', headerName: 'Area Name' },
      { field: 'AuditCategory', headerName: 'Audit Category' },
      { field: 'Finding', headerName: 'Finding' },
    ];
    this.FiveSDashboardForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      startDate: [
        new Date(moment().subtract(1, 'd').toString()),
        Validators.required,
      ],
      endDate: [new Date(), Validators.required],
      datepicker: [],
    });

    const dates = [];
    dates.push(new Date(moment().subtract(1, 'd').toString()));
    dates.push(new Date());
    this.getGroups();
    this.GetBreadcrumList();

    this.FiveSDashboardForm.get('datepicker')?.setValue(dates, {
      emitEvent: false,
    });
  }
  constructor(
    private toaster: ToastrService,
    public router: Router,
    private userService: UserManagementService,
    private enterpriseGroupService: EnterPriseGroupService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private dtService: DtServiceService,
    private sfdService: ShopFloorDataService,
    private CILDashboardService: CILDashboardService,
    private ChangeDetectorRef: ChangeDetectorRef,
    private auditDashboardService: AuditDashboard,
    private dialog: MatDialog,
  ) {
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId =
          this.breadcrumList && this.breadcrumList.length
            ? this.breadcrumList[0].id
            : '';
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onBtnExportCSV(): void {
    this.gridOptions.api.exportDataAsCsv();
  }

  onBtnExportExcel(): void {
    this.gridOptions.api.exportDataAsExcel();
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.spinnerText = 'Fetching Groups Data';
    this.spinner.show();
    this.sfdService.getGroupData(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.groupList = res.masterList;
        this.groupFiltList = this.groupList.slice();
        if (this.groupFiltList && this.groupFiltList.length)
          this.FiveSDashboardForm.controls.group.patchValue(
            this.groupFiltList[0].TypeId
          );
        this.getPlants();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getPlants(): void {
    const key = {
      GroupId: this.FiveSDashboardForm.value.group,
    };
    this.spinner.show();
    this.dtService.getPlantData(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.plantList = res.DepartList;
        this.plantFiltList = this.plantList.slice();
        if (this.plantFiltList && this.plantFiltList.length) {
          this.FiveSDashboardForm.controls.plant.patchValue(
            parseInt(this.plantFiltList[0].DeptId)
          );
          this.getFiveSDashboardDataPrev(1)
        }
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  generateDynamicclass() {
    this.values = [];
    this.values = this.data;

    setTimeout(() => {
      this.createOverallGauge();
    }, 100);
  }
  createOverallGauge() {
    const percentageArr: any = [];
    let sum;
    if (this.values && this.values.length) {
      this.values.map((val: any, index: number) => {
        percentageArr.push(val.TotalPercentage);
      });
      sum = percentageArr.reduce((accumulator, curr) => {
        return accumulator + curr;
      });
      if (sum != 0) {
        this.percentage = Math.floor(sum / percentageArr.length + 1);
      }

      const chart = Highcharts.chart(`overall-chart-gauge`, {
        chart: {
          type: 'solidgauge',
          margin: [0, 0, 0, 0],
        },
        title: {
          text: 'S - Overall Score',
          floating: true,
          y: 340,
          style: {
            color: '#000',
            fontSize: '20px',
            fontWeight: '700',
            margin: 30,
          },
        },
        credits: {
          enabled: false,
        },
        pane: {
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '80%'],
          background: {
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc',
          },
        },
        exporting: { enabled: false },
        yAxis: {
          min: 0,
          max: 100,
          stops: [
            [0.1, '#DF5353'], // red
            [0.5, '#DDDF0D'], // yellow
            [0.9, '#55BF3B'], // green
          ],
          minorTickInterval: null,
          tickAmount: 2,
          labels: {
            y: 16,
          },
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: -25,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        series: [
          {
            name: null,
            data: [0],
            dataLabels: {
              format:
                '<div style="text-align: center"><span style="font-size: 1.25rem">{y}</span></div>',
            },
          },
        ],
      } as any);
      setTimeout(() => {
        chart.series[0].points[0].update(this.percentage);
      }, 0);
      this.createChartGauge();
      this.ChangeDetectorRef.detectChanges();
    }
  }
  createChartGauge() {
    if (this.values && this.values.length) {
      let a = document.querySelectorAll('div .gauge-divider');
      if (this.values.length >= 10) {
        let a = document.querySelectorAll('div .gauge-divider');
        a.forEach((node: any) => (node.style.width = '20%'));
      } else {
        a.forEach((node: any) => (node.style.width = '25%'));
      }

      this.values.map((val: any, index: any) => {
        const chart = Highcharts.chart(`chart-gauge-${index}`, {
          chart: {
            type: 'solidgauge',
            margin: [0, 0, 0, 0],
          },
          title: {
            useHTML: true,
            text: val.AreaName,
            y: 150,

            style: {
              color: '#fff',
              fontSize: '13px',
              margin: '1px',
              padding: '8px',
              'background-color': '#325aa8',
              'border-radius': '5px',
              widthAdjust: 500,
            },
          },
          credits: {
            enabled: false,
          },
          pane: {
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '50%'],
            // size: '160%',
            background: {
              innerRadius: '60%',
              outerRadius: '100%',
              shape: 'arc',
            },
          },
          exporting: { enabled: false },
          yAxis: {
            min: 0,
            max: 100,
            stops: [
              [0.1, '#DF5353'], // red
              [0.5, '#DDDF0D'], // yellow
              [0.9, '#55BF3B'], // green
            ],
            minorTickInterval: null,
            tickAmount: 2,
            labels: {
              y: 16,
            },
          },
          plotOptions: {
            solidgauge: {
              dataLabels: {
                y: -25,
                borderWidth: 0,
                useHTML: true,
              },
            },
          },
          tooltip: {
            enabled: false,
          },
          series: [
            {
              name: null,
              data: [0],
              cursor: 'pointer',
              events: {
                click: (event) => {
                  this.onClick(
                    event.point.options.y,
                    event.point.series.chart.options.title.text
                  );
                },
              },
              dataLabels: {
                format:
                  '<div style="text-align: center"><span style="font-size: 1.25rem">{y}</span></div>',
              },
            },
          ],
        } as any);
        setTimeout(() => {
          if(chart.series){
            chart.series[0].points[0].update(
              Math.round(val.TotalPercentage * 10) / 10
            );
          }
        }, 1500);
      });
      this.createORPBarGraph();
    }
    this.ChangeDetectorRef.detectChanges();
  }

  createORPBarGraph() {
    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Gemba Walk Open Finding',
        style: {
          color: '#000',
          fontSize: '20px',
          fontWeight: '700',
          marginTop: '20px',
        },
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
        },
        column: {
          pointWidth: 50,
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>',
      },

      series: [
        {
          name: 'Gemba Walk',
          colorByPoint: true,
          data: this.ORPGraphValues,
          cursor: 'pointer',
        },
      ],

      drilldown: {
        breadcrumbs: {
          buttonTheme: {
            fill: '#f7f7f7',
            padding: 8,
            stroke: '#cccccc',
            'stroke-width': 1,
          },
          floating: true,
          position: {
            align: 'right',
          },
          showFullPath: true,
        },
        series: this.drillDownData,
      },
    };

    let chart = new Chart(this.options);
    this.ORPGraphData = chart;
  }

  createFiveSChart() {
    this.options = {
      chart: {
        type: 'column',
      },

      title: {
        text: 'Non-Compliance in S Categories.',
      },

      xAxis: {
        type: 'category',
        labels: {
          style: {
            color: 'black',
            fontSize: '12px',
          },
        },
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: null,
        },
      },
      plotOptions: {
        column: {
          pointWidth: 50,
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
          },
        },
      },

      series: [
        {
          colorByPoint: true,
          data: this.fivesPoupValue,
        },
      ],
    };

    let chart = new Chart(this.options);
    this.chart = chart;
  }

  onSubmit(): void {
    this.SearchType2 = 2
    const data = {
      areaId: this.FiveSDashboardForm.value.group,
      ptId: this.FiveSDashboardForm.value.plant,
      dateStart: moment(this.FiveSDashboardForm.value.datepicker[0]).utc(),
      dateEnd: moment(this.FiveSDashboardForm.value.datepicker[1]).utc(),
    };
    this.spinner.show();
    this.drillDownData = [];
    this.ORPGraphValues = [];
    this.CILDashboardService.getFiveSDashboardData(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.percentage = '';
        this.values = [];
        this.data = res;
        this.generateDynamicclass();
        this.GetNewGembaWalkDash();
        this.GetAllExportData();
      },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error(err, 'Error');
        this.GetNewGembaWalkDash();
        this.GetAllExportData();
      }
    );
  }
  onClick(val: any, name: any) {
    if (val == 0) {
      return;
    } else {
      if (this.isPopupOpen == false) {
        this.isPopupOpen = true;
        this.data.map((res: any) => {
          if (
            Math.round(res.TotalPercentage * 10) / 10 == val &&
            name == res.AreaName
          ) {
            this.getNoOf5sFindingOverall(res.AreaId);
          }
        });
      }
    }
  }
  getDashboardData() {
    const data = {
      areaId: this.FiveSDashboardForm.value.group,
      ptId: this.FiveSDashboardForm.value.plant,
      dateStart: moment(this.FiveSDashboardForm.value.datepicker[0]).utc(),
      dateEnd: moment(this.FiveSDashboardForm.value.datepicker[1]).utc(),
    };
    this.spinner.show();
    this.data = [];
    this.CILDashboardService.getFiveSDashboardData(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.data = res;
      },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error(err, 'Error');
      }
    );
  }
  openPopup() {
    let dialogRef = this.dialog.open(this.groupLicensePopup, {
      minWidth: '80%',
      minHeight: '50%',
    });

    dialogRef.afterOpened().subscribe((res: any) => {
      this.createFiveSChart();
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.isPopupOpen = false;
    });
  }

  GetNewGembaWalkDash() {
    const data = {
      userId: JSON.parse(localStorage.getItem('user')).UserId,
      PTID: this.FiveSDashboardForm.value.plant,
      dateStart: moment(this.FiveSDashboardForm.value.datepicker[0]).utc(),
      dateEnd: moment(this.FiveSDashboardForm.value.datepicker[1]).utc(),
    };
    this.barGraphDataresponse = [];
    // this.drillDownData = [];
    this.CILDashboardService.GetNewGembaWalkDash(data).subscribe(
      (res: any) => {
        const data = [];
        this.barGraphDataresponse = res;
        this.barGraphDataresponse.map((res: any) => {
          data.push({ areaName: res.AreaName, category: res.Category });
        });
        let a = {};
        for (const val of data) {
          if (a[val.areaName]) {
            a[val.areaName] += 1;
          } else {
            a[val.areaName] = 1;
          }
        }
        const datas = [];
        for (const key in a) {
          this.ORPGraphValues.push({ name: key, y: a[key], drilldown: key });
        }
        const drillDownRecord = [];
        for (const val of this.barGraphDataresponse) {
          const valIndex = drillDownRecord.findIndex(
            (rec: any) => rec.name == val.AreaName
          );
          if (drillDownRecord && drillDownRecord[valIndex]) {
            const cat = drillDownRecord[valIndex]['data'].findIndex(
              (catRec: any) => catRec[0] == val.Category
            );
            if (
              drillDownRecord[valIndex]['data'] &&
              drillDownRecord[valIndex]['data'][cat]
            )
              drillDownRecord[valIndex]['data'][cat][1] += 1;
            else drillDownRecord[valIndex]['data'].push([val.Category, 1]);
          } else {
            const createVal = {
              colorByPoint: true,
              name: val.AreaName,
              id: val.AreaName,
              data: [[val.Category, 1]],
            };
            drillDownRecord.push(createVal);
          }
        }
        this.drillDownData = drillDownRecord;
        this.generateDynamicclass();
      },
      (err: any) => {
        this.toaster.error(err.error.Message, 'Error');
      }
    );
  }
  getNoOf5sFindingOverall(val) {
    if(this.SearchType2 === 2)
    {
      const data = {
        areaId: val,
        egId: this.FiveSDashboardForm.value.group,
        ptId: this.FiveSDashboardForm.value.plant,
        fromDate:  moment(this.FiveSDashboardForm.value.datepicker[0]).utc(),
        toDate: moment(this.FiveSDashboardForm.value.datepicker[1]).utc(),
      };
  
      this.spinner.show();
      this.fivesPoupValue = [];
      this.CILDashboardService.getNoOf5sFindingOverall(data).subscribe(
        (res: any) => {
          this.spinner.hide();
          const data = res;
          data.map((res) => {
            this.fivesPoupValue.push({ name: res.Name, y: res.Finding });
          });
          this.openPopup();
          this.ChangeDetectorRef.detectChanges();
        },
        (err: any) => {
          this.spinner.hide();
          this.toaster.error(err, 'Error');
        }
      );
    }
    else
    {
      const data = {
        areaId: val,
        ptId: this.FiveSDashboardForm.value.plant ,
        search : this.SearchType
      };
  
      this.spinner.show();
      this.fivesPoupValue = [];
      this.CILDashboardService.getNoOf5sFindingOverallPrev(data).subscribe(
        (res: any) => {
          this.spinner.hide();
          const data = res;
          data.map((res) => {
            this.fivesPoupValue.push({ name: res.Name, y: res.Finding });
          });
          this.openPopup();
          this.ChangeDetectorRef.detectChanges();
        },
        (err: any) => {
          this.spinner.hide();
          this.toaster.error(err, 'Error');
        }
      );
    }
  
  }
  onChange(a) {
    this.startDate = a.toString();
    // this.endDate = this.date.split(',');
  }
  GetAllExportData(): void {
    const key = {
      egId: this.FiveSDashboardForm.value.group,
      ptId: this.FiveSDashboardForm.value.plant,
      fromDate: moment(this.FiveSDashboardForm.value.datepicker[0]).utc(),
      toDate: moment(this.FiveSDashboardForm.value.datepicker[1]).utc(),
      // fromDate:  moment(this.FiveSDashboardForm.value.datepicker[0]).utc(),
      // toDate: moment(this.FiveSDashboardForm.value.datepicker[1]).utc(),
  
    }
    this.auditDashboardService.GetAllExportData(key).subscribe((res: any) => {
      this.dataArray = res;
    });

  }
  getFiveSDashboardDataPrev(numb: number): void {
    this.SearchType = numb
    this.SearchType2 = 1
    const key = {
      PTID: this.FiveSDashboardForm.value.plant,
      Execid: numb
    }
    this.spinner.show()
    this.CILDashboardService.getFiveSDashboardDataPrev(key).subscribe((res: any) => {
      this.spinner.hide();
      this.percentage = '';
      this.values = [];
      this.data = res;
      this.generateDynamicclass();
      this.GetNewGembaWalkDash();
      this.GetAllExportData();
    }, (error) => {
      this.spinner.hide()
      this.toaster.error('Error', error.error.Message)
      this.GetNewGembaWalkDash();
      this.GetAllExportData();
    })
  }
  GetNewGembaWalkDash2(data: any) {

    this.barGraphDataresponse = [];
    // this.drillDownData = [];
    this.CILDashboardService.GetNewGembaWalkDash(data).subscribe(
      (res: any) => {
        const data = [];
        this.barGraphDataresponse = res;
        this.barGraphDataresponse.map((res: any) => {
          data.push({ areaName: res.AreaName, category: res.Category });
        });
        let a = {};
        for (const val of data) {
          if (a[val.areaName]) {
            a[val.areaName] += 1;
          } else {
            a[val.areaName] = 1;
          }
        }
        const datas = [];
        for (const key in a) {
          this.ORPGraphValues.push({ name: key, y: a[key], drilldown: key });
        }
        const drillDownRecord = [];
        for (const val of this.barGraphDataresponse) {
          const valIndex = drillDownRecord.findIndex(
            (rec: any) => rec.name == val.AreaName
          );
          if (drillDownRecord && drillDownRecord[valIndex]) {
            const cat = drillDownRecord[valIndex]['data'].findIndex(
              (catRec: any) => catRec[0] == val.Category
            );
            if (
              drillDownRecord[valIndex]['data'] &&
              drillDownRecord[valIndex]['data'][cat]
            )
              drillDownRecord[valIndex]['data'][cat][1] += 1;
            else drillDownRecord[valIndex]['data'].push([val.Category, 1]);
          } else {
            const createVal = {
              colorByPoint: true,
              name: val.AreaName,
              id: val.AreaName,
              data: [[val.Category, 1]],
            };
            drillDownRecord.push(createVal);
          }
        }
        this.drillDownData = drillDownRecord;
      },
      (err: any) => {
        this.toaster.error(err, 'Error');
      }
    );
  }
}
