import { Component, OnDestroy } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'btn-cell-renderer',
    template:
        `<style>
        .button{
            background-repeat: no-repeat;
            display: inline-block;
            fill: currentColor;
            height: 24px;
            width: 24px;
            color: #119439;
            border-radius: 50%;
            display: inline-block;
            margin-bottom: 0;
            font-weight: 400;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            cursor: pointer;
            background-image: none;
            border: 1px solid #fff;
            font-size: 20px;
            line-height: 1.42857143;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    </style>
    <button mat-icon-button class="button add_btn"  (click)="onClick($event)">
            <i class="fa fa-plus-circle" aria-hidden="true"></i>
    </button>`
})
// tslint:disable-next-line:component-class-suffix
export class BtnRenderer implements ICellRendererAngularComp, OnDestroy {
    private params: any;
    refresh(params: any): boolean {
        throw new Error('Method not implemented.');
    }

    agInit(params: any): void {
        this.params = params;
    }

    onClick($event: any): any {

        if (this.params.onClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowData: this.params.node.data,
                index: this.params.node.rowIndex
                // ...something
            };
            this.params.onClick(params);
        }
    }
    ngOnDestroy(): any {
        // no need to remove the button click handler
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}
