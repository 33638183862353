<!-- header -->

<div class="sfdc-configuration-content" mat-dialog-title>
  <div class="ncrInfo-container">
    <h3 *ngIf="data?.moduleId == 1">{{ data?.ncrIdDesc }}</h3>
    <h3 *ngIf="data?.moduleId == 2">CCR-{{ data?.ccrID }}</h3>
    <h3 *ngIf="data?.moduleId == 4 && data?.['KPI Name']">
      {{ data?.['KPI Name'] }}
    </h3>
    <h3 *ngIf="data?.moduleId == 3 && data?.meetingName">
      {{ data?.meetingName }}
    </h3>
    <h3 *ngIf="data?.moduleId == 3 && data?.KPIId">{{ data?.KPIName }}</h3>

    <button
      mat-mini-fab
      mat-button
      mat-dialog-close
      color="warn"
      title="close"
      class="close-btn"
      (click)="closePopUpModal()"
    >
      <i class="fa fa-times" style="color: #fff"></i>
    </button>
  </div>
</div>

<!-- tabes -->
<div mat-dialog-content>
  <ng-container *ngIf="layoutShowHide()">
    <form
      name="searchDefectForm"
      [formGroup]="searchDefectForm"
      #formDirective="ngForm"
      (ngSubmit)="onSubmit(searchDefectForm, formDirective)"
    >
      <div class="w-100 headerForm">
        <mat-form-field appearence="fill" class="w-22 d-none">
          <mat-label>Group</mat-label>
          <mat-select
            [(ngModel)]="headerData.Group"
            formControlName="group"
            (selectionChange)="getDeptData()"
            disabled
          >
            <mat-select-filter
              [placeholder]="'Search Group'"
              [displayMember]="'TypeName'"
              [array]="groupList"
              (filteredReturn)="groupTempList = $event"
              disabled
            >
            </mat-select-filter>

            <mat-option
              *ngFor="let group of groupTempList"
              [value]="group.TypeId"
            >
              {{ group.TypeName }}
            </mat-option>
          </mat-select>
          <mat-error>Group is required</mat-error>
        </mat-form-field>
        <mat-form-field appearence="fill" class="w-22 d-none">
          <mat-label>Plant</mat-label>
          <mat-select
            [(ngModel)]="headerData.Plant"
            formControlName="plant"
            (selectionChange)="
              getAllDepartment(); getTeamList(); emitPlantChange()
            "
            disabled
          >
            <mat-select-filter
              [placeholder]="'Search Plant'"
              [displayMember]="'DeptDesc'"
              [array]="plantList"
              (filteredReturn)="plantTempList = $event"
              disabled
            >
            </mat-select-filter>

            <mat-option
              *ngFor="let plant of plantTempList"
              [value]="plant.DeptId"
            >
              {{ plant.DeptDesc }}
            </mat-option>
          </mat-select>
          <mat-error>Plant is required</mat-error>
        </mat-form-field>
        <mat-form-field appearence="fill" class="w-22">
          <mat-label>Department</mat-label>
          <mat-select
            [(ngModel)]="headerData.Dept"
            formControlName="department"
            (selectionChange)="getUniqueCode()"
          >
            <mat-select-filter
              [placeholder]="'Search Department'"
              [displayMember]="'TypeName'"
              [array]="deptList"
              (filteredReturn)="deptTempList = $event"
            >
            </mat-select-filter>
            <mat-option *ngFor="let dept of deptTempList" [value]="dept.TypeId">
              {{ dept.TypeName }}
            </mat-option>
          </mat-select>
          <mat-error>Department is required</mat-error>
        </mat-form-field>

        <mat-form-field appearence="fill" class="w-22">
          <mat-label>Team Leader</mat-label>
          <mat-select
            #selectTeamLeader
            [(ngModel)]="headerData.TeamLeader"
            formControlName="teamleader"
            (selectionChange)="
              addTeamLeader(headerData.TeamLeader); onSelectionChange()
            "
          >
            <mat-select-filter
              [placeholder]="'Search User'"
              [displayMember]="'FullName'"
              [array]="teams"
              (filteredReturn)="tempTeams = $event"
            >
            </mat-select-filter>
            <mat-option
              *ngFor="let user of tempTeams"
              [value]="user.EmpInfo_Id"
            >
              {{ user.FirstName }} {{ user.lastName }}
            </mat-option>
          </mat-select>
          <mat-error>Team Leader is required</mat-error>
        </mat-form-field>

        <mat-form-field appearence="fill" class="w-22">
          <mat-label>Participants</mat-label>
          <mat-select
            #selectParticipants
            [(ngModel)]="headerData.Team"
            formControlName="team"
            multiple
            (selectionChange)="
              setParticipantsChange(headerData.Team); onSelectionChange()
            "
          >
            <mat-select-filter
              #selectParticipantsFilter
              [placeholder]="'Search User'"
              [displayMember]="'FullName'"
              [array]="teamsParticipant"
              (filteredReturn)="tempTeamsteamsParticipant = $event"
              (keyup)="keyUp()"
            >
            </mat-select-filter>
            <mat-option
              *ngFor="let user of tempTeamsteamsParticipant"
              [value]="user.EmpInfo_Id"
              [disabled]="headerData.TeamLeader == user.EmpInfo_Id"
              (click)="setTeamAndValidate(user.EmpInfo_Id)"
            >
              {{ user.FirstName }} {{ user.lastName }}
            </mat-option>
          </mat-select>
          <mat-error>Participants is required</mat-error>
        </mat-form-field>
        <mat-form-field
          appearence="fill"
          class="w-10"
          *ngIf="headerData?.uniqueCode != undefined"
        >
          <mat-label>RCA Code</mat-label>

          <input
            matInput
            type="text"
            readonly
            [(ngModel)]="headerData.uniqueCode"
            formControlName="uniqueCode"
            *ngIf="headerData?.uniqueCode != undefined"
          />
        </mat-form-field>
      </div>
      <div class="w-100 headerForm" style="display: flex">
        <div
          *ngIf="stageID != 6"
          style="width: 77%; position: relative"
          title="{{ headerData.InitProbStatement }}"
        >
          <mat-form-field appearence="fill" class="w-70">
            <mat-label>Initial Problem Statement</mat-label>
            <input
              matInput
              [(ngModel)]="headerData.InitProbStatement"
              formControlName="initProbStat"
              [readonly]="isShowAllowInitProb()"
            />
            <mat-error>Initial Problem Statement is required</mat-error>
          </mat-form-field>

          <div class="imgflex" *ngIf="isDataUpdateAllow">
            <img
              *ngIf="imageFile && imageSrc"
              (click)="imageZoomModal()"
              [src]="imageSrc"
              alt=""
              width="60px"
              height="40px"
            />
            <img
              *ngIf="
                !imageSrc && headerData && headerData.UploadFile && !isLoading
              "
              class="imgrel"
              (click)="imageZoomModal()"
              [src]="ImageUrl + headerData.UploadFile"
              alt=""
              width="60px"
              height="40px"
            />
            <img
              *ngIf="
                (imageFile && imageSrc) || (headerData.UploadFile && !isLoading)
              "
              src="assets/images/delete.png"
              (click)="deleteFile()"
              class="imgabsol"
              alt=""
              width="15px"
              height="15px"
            />
            <button
              mat-flat-button
              color="accent"
              type="button"
              class="upload"
              (click)="openFile()"
            >
              <mat-icon>attach_file</mat-icon>
            </button>
            <input
              type="file"
              (change)="readFile($event)"
              class="d-none"
              #fileInput
              accept="image/*"
            />
          </div>
        </div>

        <div class="btn-group" *ngIf="isDataUpdateAllow">
          <button
            type="submit"
            class="save"
            tooltipPosition="top"
            matTooltip="Save"
          >
            <img
              src="assets/images/save.png"
              alt=""
              width="20px"
              height="20px"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="header-tab">
      <ng-container *ngIf="!data?.hideTabs">
        <mat-tab-group
          #tabChange
          [selectedIndex]="selectedIndex"
          (selectedTabChange)="onTabChange($event)"
          *ngIf="selectedIndex != 10"
        >
          <mat-tab [disabled]="selectedRCA?.disableSixWTwo">
            <ng-template mat-tab-label>
              6W2H
              <span>{{ selectedRCA?.Status6W2H }}</span>
            </ng-template>
            <div class="tab-content" *ngIf="selectedIndex == 0">
              <app-at6w2h
                [sixWTwoh_]="sixWTwoh_"
                [_headerData]="_headerData"
                [headerID]="headerId"
                (onSuccess)="nullifySubject($event)"
                [_integrationData]="_integrationData"
              ></app-at6w2h>
            </div>
          </mat-tab>

          <mat-tab class="column-tab" [disabled]="selectedRCA?.disableFishbone">
            <ng-template mat-tab-label>
              FishBone
              <span>{{ selectedRCA?.StatusFishbone }}</span>
            </ng-template>

            <div class="tab-content" *ngIf="selectedIndex == 1">
              <app-fishbone
                [fishBone_]="fishBone_"
                [_headerData]="_headerData"
                [headerID]="headerId"
                (onSuccess)="nullifySubject($event)"
                [_permission]="permisson"
                [_integrationData]="_integrationData"
              >
              </app-fishbone>
            </div>
          </mat-tab>

          <mat-tab
            [disabled]="selectedRCA?.disableCauseAndEffect"
            label="Cause & Effect"
          >
            <ng-template mat-tab-label>
              Cause & Effect
              <span>{{ selectedRCA?.StatusCauseEffect }}</span>
            </ng-template>
            <div class="tab-content" *ngIf="selectedIndex == 2">
              <app-cause-effect
                [causeAndEffects_]="causeAndEffects_"
                (onSuccess)="nullifySubject($event)"
                [headerID]="headerId"
                [_headerData]="_headerData"
                [_permission]="permisson"
                [_integrationData]="_integrationData"
              ></app-cause-effect>
            </div>
          </mat-tab>

          <mat-tab label="5 Why" [disabled]="selectedRCA?.disableFiveWhy">
            <ng-template mat-tab-label>
              5 Why
              <span>{{ selectedRCA?.StatusFiveWhy }}</span>
            </ng-template>
            <div class="tab-content" *ngIf="selectedIndex == 3">
              <app-five-why
                [fiveWhy_]="fiveWhy_"
                (onSuccess)="nullifySubject($event)"
                [headerID]="headerId"
                [_permission]="permisson"
                [_headerData]="headerData"
                [_integrationData]="_integrationData"
              ></app-five-why>
            </div>
          </mat-tab>

          <mat-tab
            label="Action Plan"
            [disabled]="selectedRCA?.disableActionPlan"
          >
            <ng-template mat-tab-label>
              Action Plan
              <span>{{ selectedRCA?.StatusFiveWhyAction }}</span>
            </ng-template>
            <div class="tab-content" *ngIf="selectedIndex == 4">
              <app-fivewhy-actionplan
                [actionPlan_]="actionPlan_"
                (onSuccess)="nullifySubject($event)"
                [_headerData]="headerData"
                [headerID]="headerId"
                [_permission]="permisson"
                [_integrationData]="_integrationData"
              ></app-fivewhy-actionplan>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
      <ng-container
        *ngIf="
          data?.hideTabs &&
          data?.moduleId === 3 &&
          data?.componentName === 'kpiView'
        "
      >
        <mat-tab-group
          #tabChange
          [selectedIndex]="selectedIndex"
          (selectedTabChange)="onTabChange($event)"
          *ngIf="selectedIndex != 10"
        >
          <mat-tab
            label="Action Plan"
            [disabled]="selectedRCA?.disableActionPlan"
          >
            <ng-template mat-tab-label>
              Action Plan
              <span>{{ selectedRCA?.StatusFiveWhyAction }}</span>
            </ng-template>
            <div class="tab-content" *ngIf="selectedIndex == 0">
              <app-fivewhy-actionplan
                [actionPlan_]="actionPlan_"
                (onSuccess)="nullifySubject($event)"
                [_headerData]="headerData"
                [headerID]="headerId"
                [_permission]="permisson"
                [_integrationData]="_integrationData"
              ></app-fivewhy-actionplan>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
      <ng-container
        *ngIf="
          data?.hideTabs &&
          data?.moduleId === 3 &&
          data?.componentName === 'meeting-matrixV2'
        "
      >
        <mat-tab-group
          #tabChange
          [selectedIndex]="selectedIndex"
          (selectedTabChange)="onTabChange($event)"
          *ngIf="selectedIndex != 10"
        >
          <mat-tab
            label="Action Plan"
            [disabled]="selectedRCA?.disableActionPlan"
          >
            <ng-template mat-tab-label>
              Action Plan
              <span>{{ selectedRCA?.StatusFiveWhyAction }}</span>
            </ng-template>
            <div class="tab-content" *ngIf="selectedIndex == 0">
              <app-fivewhy-actionplan
                [actionPlan_]="actionPlan_"
                (onSuccess)="nullifySubject($event)"
                [_headerData]="headerData"
                [headerID]="headerId"
                [_permission]="permisson"
                [_integrationData]="_integrationData"
              ></app-fivewhy-actionplan>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
    </div>
  </ng-container>

  <!-- meeting matrix v2 -->
  <ng-container *ngIf="!layoutShowHide()">
    <div class="d-flex flex-column">
      <form
        name="searchDefectForm"
        [formGroup]="searchDefectForm"
        #formDirective="ngForm"
        (ngSubmit)="onSubmit(searchDefectForm, formDirective)"
      >
        <div class="w-100 headerForm d-flex">
          <mat-form-field appearence="fill" class="w-22 d-none">
            <mat-label>Group</mat-label>
            <mat-select
              [(ngModel)]="headerData.Group"
              formControlName="group"
              (selectionChange)="getDeptData()"
              disabled
            >
              <mat-select-filter
                [placeholder]="'Search Group'"
                [displayMember]="'TypeName'"
                [array]="groupList"
                (filteredReturn)="groupTempList = $event"
                disabled
              >
              </mat-select-filter>

              <mat-option
                *ngFor="let group of groupTempList"
                [value]="group.TypeId"
              >
                {{ group.TypeName }}
              </mat-option>
            </mat-select>
            <mat-error>Group is required</mat-error>
          </mat-form-field>
          <mat-form-field appearence="fill" class="w-22 d-none">
            <mat-label>Plant</mat-label>
            <mat-select
              [(ngModel)]="headerData.Plant"
              formControlName="plant"
              (selectionChange)="
                getAllDepartment(); getTeamList(); emitPlantChange()
              "
              disabled
            >
              <mat-select-filter
                [placeholder]="'Search Plant'"
                [displayMember]="'DeptDesc'"
                [array]="plantList"
                (filteredReturn)="plantTempList = $event"
                disabled
              >
              </mat-select-filter>

              <mat-option
                *ngFor="let plant of plantTempList"
                [value]="plant.DeptId"
              >
                {{ plant.DeptDesc }}
              </mat-option>
            </mat-select>
            <mat-error>Plant is required</mat-error>
          </mat-form-field>
          <mat-form-field appearence="fill" class="w-22">
            <mat-label>Department</mat-label>
            <mat-select
              [(ngModel)]="headerData.Dept"
              formControlName="department"
              (selectionChange)="getUniqueCode()"
            >
              <mat-select-filter
                [placeholder]="'Search Department'"
                [displayMember]="'TypeName'"
                [array]="deptList"
                (filteredReturn)="deptTempList = $event"
              >
              </mat-select-filter>
              <mat-option
                *ngFor="let dept of deptTempList"
                [value]="dept.TypeId"
              >
                {{ dept.TypeName }}
              </mat-option>
            </mat-select>
            <mat-error>Department is required</mat-error>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="w-22"
            *ngIf="hideAndShowFilters()"
          >
            <mat-label>Team Leader</mat-label>
            <mat-select
              #selectTeamLeader
              [(ngModel)]="headerData.TeamLeader"
              formControlName="teamleader"
              (selectionChange)="
                addTeamLeader(headerData.TeamLeader); onSelectionChange()
              "
            >
              <mat-select-filter
                [placeholder]="'Search User'"
                [displayMember]="'FullName'"
                [array]="teams"
                (filteredReturn)="tempTeams = $event"
              >
              </mat-select-filter>
              <mat-option
                *ngFor="let user of tempTeams"
                [value]="user.EmpInfo_Id"
              >
                {{ user.FirstName }} {{ user.lastName }}
              </mat-option>
            </mat-select>
            <mat-error>Team Leader is required</mat-error>
          </mat-form-field>

          <mat-form-field
            appearence="fill"
            class="w-22"
            *ngIf="hideAndShowFilters()"
          >
            <mat-label>Participants</mat-label>
            <mat-select
              #selectParticipants
              [(ngModel)]="headerData.Team"
              formControlName="team"
              multiple
              (selectionChange)="
                setParticipantsChange(headerData.Team); onSelectionChange()
              "
            >
              <mat-select-filter
                #selectParticipantsFilter
                [placeholder]="'Search User'"
                [displayMember]="'FullName'"
                [array]="teamsParticipant"
                (filteredReturn)="tempTeamsteamsParticipant = $event"
                (keyup)="keyUp()"
              >
              </mat-select-filter>
              <mat-option
                *ngFor="let user of tempTeamsteamsParticipant"
                [value]="user.EmpInfo_Id"
                [disabled]="headerData.TeamLeader == user.EmpInfo_Id"
                (click)="setTeamAndValidate(user.EmpInfo_Id)"
              >
                {{ user.FirstName }} {{ user.lastName }}
              </mat-option>
            </mat-select>
            <mat-error>Participants is required</mat-error>
          </mat-form-field>
          <mat-form-field
            appearence="fill"
            class="w-10"
            *ngIf="headerData?.uniqueCode != undefined && hideAndShowFilters()"
          >
            <mat-label>RCA Code</mat-label>

            <input
              matInput
              type="text"
              readonly
              [(ngModel)]="headerData.uniqueCode"
              formControlName="uniqueCode"
              *ngIf="headerData?.uniqueCode != undefined"
            />
          </mat-form-field>

          <button
            type="submit"
            class="save"
            tooltipPosition="top"
            matTooltip="Save"
          >
            <img
              src="assets/images/save.png"
              alt=""
              width="20px"
              height="20px"
            />
          </button>
        </div>
      </form>
      <div class="header-tab">
        <ng-container *ngIf="!data?.hideTabs">
          <mat-tab-group
            #tabChange
            [selectedIndex]="selectedIndex"
            (selectedTabChange)="onTabChange($event)"
            *ngIf="selectedIndex != 10"
          >
            <mat-tab [disabled]="selectedRCA?.disableSixWTwo">
              <ng-template mat-tab-label>
                6W2H
                <span>{{ selectedRCA?.Status6W2H }}</span>
              </ng-template>
              <div class="tab-content" *ngIf="selectedIndex == 0">
                <app-at6w2h
                  [sixWTwoh_]="sixWTwoh_"
                  [_headerData]="_headerData"
                  [headerID]="headerId"
                  (onSuccess)="nullifySubject($event)"
                  [_integrationData]="_integrationData"
                ></app-at6w2h>
              </div>
            </mat-tab>

            <mat-tab
              class="column-tab"
              [disabled]="selectedRCA?.disableFishbone"
            >
              <ng-template mat-tab-label>
                FishBone
                <span>{{ selectedRCA?.StatusFishbone }}</span>
              </ng-template>

              <div class="tab-content" *ngIf="selectedIndex == 1">
                <app-fishbone
                  [fishBone_]="fishBone_"
                  [_headerData]="_headerData"
                  [headerID]="headerId"
                  (onSuccess)="nullifySubject($event)"
                  [_permission]="permisson"
                  [_integrationData]="_integrationData"
                >
                </app-fishbone>
              </div>
            </mat-tab>

            <mat-tab
              [disabled]="selectedRCA?.disableCauseAndEffect"
              label="Cause & Effect"
            >
              <ng-template mat-tab-label>
                Cause & Effect
                <span>{{ selectedRCA?.StatusCauseEffect }}</span>
              </ng-template>
              <div class="tab-content" *ngIf="selectedIndex == 2">
                <app-cause-effect
                  [causeAndEffects_]="causeAndEffects_"
                  (onSuccess)="nullifySubject($event)"
                  [headerID]="headerId"
                  [_headerData]="_headerData"
                  [_permission]="permisson"
                  [_integrationData]="_integrationData"
                ></app-cause-effect>
              </div>
            </mat-tab>

            <mat-tab label="5 Why" [disabled]="selectedRCA?.disableFiveWhy">
              <ng-template mat-tab-label>
                5 Why
                <span>{{ selectedRCA?.StatusFiveWhy }}</span>
              </ng-template>
              <div class="tab-content" *ngIf="selectedIndex == 3">
                <app-five-why
                  [fiveWhy_]="fiveWhy_"
                  (onSuccess)="nullifySubject($event)"
                  [headerID]="headerId"
                  [_permission]="permisson"
                  [_headerData]="headerData"
                  [_integrationData]="_integrationData"
                ></app-five-why>
              </div>
            </mat-tab>

            <mat-tab
              label="Action Plan"
              [disabled]="selectedRCA?.disableActionPlan"
            >
              <ng-template mat-tab-label>
                Action Plan
                <span>{{ selectedRCA?.StatusFiveWhyAction }}</span>
              </ng-template>
              <div class="tab-content" *ngIf="selectedIndex == 4">
                <app-fivewhy-actionplan
                  [actionPlan_]="actionPlan_"
                  (onSuccess)="nullifySubject($event)"
                  [_headerData]="headerData"
                  [headerID]="headerId"
                  [_permission]="permisson"
                  [_integrationData]="_integrationData"
                ></app-fivewhy-actionplan>
              </div>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
        <ng-container
          *ngIf="
            data?.hideTabs &&
            data?.moduleId === 3 &&
            data?.componentName === 'kpiView'
          "
        >
          <mat-tab-group
            #tabChange
            [selectedIndex]="selectedIndex"
            (selectedTabChange)="onTabChange($event)"
            *ngIf="selectedIndex != 10"
          >
            <mat-tab
              label="Action Plan"
              [disabled]="selectedRCA?.disableActionPlan"
            >
              <ng-template mat-tab-label>
                Action Plan
                <span>{{ selectedRCA?.StatusFiveWhyAction }}</span>
              </ng-template>
              <div class="tab-content" *ngIf="selectedIndex == 0">
                <app-fivewhy-actionplan
                  [actionPlan_]="actionPlan_"
                  (onSuccess)="nullifySubject($event)"
                  [_headerData]="headerData"
                  [headerID]="headerId"
                  [_permission]="permisson"
                  [_integrationData]="_integrationData"
                ></app-fivewhy-actionplan>
              </div>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
        <ng-container
          *ngIf="
            data?.hideTabs &&
            data?.moduleId === 3 &&
            data?.componentName === 'meeting-matrixV2'
          "
        >
          <mat-tab-group
            #tabChange
            [selectedIndex]="selectedIndex"
            (selectedTabChange)="onTabChange($event)"
            *ngIf="selectedIndex != 10"
          >
            <mat-tab
              label="Action Plan"
              [disabled]="selectedRCA?.disableActionPlan"
            >
              <ng-template mat-tab-label>
                Action Plan
                <span>{{ selectedRCA?.StatusFiveWhyAction }}</span>
              </ng-template>
              <div class="tab-content" *ngIf="selectedIndex == 0">
                <app-fivewhy-actionplan
                  [actionPlan_]="actionPlan_"
                  (onSuccess)="nullifySubject($event)"
                  [_headerData]="headerData"
                  [headerID]="headerId"
                  [_permission]="permisson"
                  [_integrationData]="_integrationData"
                ></app-fivewhy-actionplan>
              </div>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
