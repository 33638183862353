import { User } from './../models/user';
import { Header } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TechnicianService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  technicianData: any;
  technicianTaskData: any;
  technicianId: number = 0;
  techImage: string = '';
  technicianID: any;
  groupPlantData: any;
  From: any;
  To: any;
  technicianTabIndex = 0;
  TechnicianHeaderData: any = { Group: '', Plant: '', FromDate: '', ToDate: '' };
  TechnicianSkillMatrixHeaderData: any = { Group: '', Plant: '' };
  constructor(private http: HttpClient) { }

  setTechnicianTabIndex(val: any) {
    this.technicianTabIndex = val;
  }

  getTechnicianTabIndex() {
    return this.technicianTabIndex;
  }

  setTechnicianHeaderData(data: any) {
    this.TechnicianHeaderData.Group = data.Group
    this.TechnicianHeaderData.Plant = data.Plant
    this.TechnicianHeaderData.FromDate = data.FromDate
    this.TechnicianHeaderData.ToDate = data.ToDate
  }

  getTechnicianHeaderData() {
    return this.TechnicianHeaderData;
  }

  setSkillMatrixHeaderData(data: any){
    this.TechnicianSkillMatrixHeaderData.Group = data.Group
    this.TechnicianSkillMatrixHeaderData.Plant = data.Plant
  }

  getSkillMatrixHeaderData() {
    return this.TechnicianSkillMatrixHeaderData;
  }

  setFromDate(date: any) {
    this.From = date;
  }

  getFromDate() {
    return this.From;
  }

  setToDate(date: any) {
    this.To = date;
  }

  getToDate() {
    return this.To;
  }

  setTechnicianValues(element: any) {
    this.technicianData = element
  }

  getTechnicianValues() {
    return this.technicianData
  }

  setTechnicianTaskValues(element: any) {
    this.technicianTaskData = element
  }

  getTechnicianTaskValues() {
    return this.technicianTaskData
  }

  setTechnicianId(technicianId: number) {
    this.technicianId = technicianId;
  }

  setSelTech(technicianID: number) {
    this.technicianID = technicianID;
  }

  getSelTech() {
    return this.technicianID;
  }

  getTechnicianId() {
    return this.technicianId;
  }

  setTechnicianImage(techImage: string) {
    this.techImage = techImage;
  }

  getTechnicianImage() {
    return this.techImage;
  }

  setGroupPlant(GroupPlant: any) {
    this.groupPlantData = GroupPlant;
  }

  getGroupPlant() {
    return this.groupPlantData
  }

  getTechnicianList(headerData: any) {
    return this.http.get<any>(this.o3CoreApiUrl + 'TechScore/getTechnicianList', { params: headerData });
  }

  getTechnisionHistory(UserId: any) {
    return this.http.get<any>(this.baseUrl + 'getTechnisionHistory', { params: { UserId: UserId } });
  }

  getTechnisionHistoryTask() {
    return this.http.get<any>(this.baseUrl + 'getTechnisionHistoryTask');
  }
   getTechnicianExecutionList(data: any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMTechnicianExecution/showTechExecWO', { params: data });
  }
  // *** Core API Method *** //
  addTechnician(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTechnicianExecution/addTechExecWO', data);
  }
  // *** Core API Method *** //
  updateTechnician(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTechnicianExecution/updateTechExecWo', data);
  }
  // *** Core API Method *** //
  getTechnician(data: any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMTechnicianExecution/showTechExecWO', { params: { WOId: data } });
  }

}
