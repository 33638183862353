<ngx-spinner
  bdColor="rgba(0, 0, 0, 0)"
  template=" <img src='assets/images/spinner.gif' />"
>
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>
<div class="cause-effect-model-content">
  <div style="display: flex">
    <p
      *ngIf="levelId == 0 || levelId == 3"
      style="
        margin: 0 20px 0 0;
        font-family: system-ui;
        color: #0f0fd1;
        font-weight: 500;
      "
    >
      Minimum 4 M's Are Mandatory <span class="text-danger">*</span>
    </p>
    <p
      style="
        margin: 0;
        font-family: system-ui;
        color: #0f0fd1;
        font-weight: 500;
      "
    >
      Low: 2 | Medium: 5 | High: 8
    </p>
  </div>
  <div
    class="wrapper-box wrapper-box-shadow table-wrapper"
    *ngIf="causeEffectModelDataSource !== undefined"
  >
    <table
      mat-table
      [dataSource]="causeEffectModelDataSource"
      class="basic-table cause-effect-model-table"
      matSort
    >
      <ng-container matColumnDef="MType">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-min-width"
        >
          <p class="header-text">Contributing Source</p>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="m-type"
          [ngClass]="{
            'bg-salmon': element.M == 1,
            'bg-cadetblue': element.M == 2,
            'bg-cornflowerblue': element.M == 3,
            'bg-slategray': element.M == 4,
            'bg-burlywood': element.M == 5,
            'bg-coral': element.M == 6
          }"
        >
          {{
            element && element.displayMType
              ? element.displayMType
              : element.MType
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Priority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Priority</p>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="priority"
          style="width: 75px"
        >
          {{ element.Priority }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ProbableCause">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">
            Probable Cause <span class="text-danger">*</span>
          </p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div title="{{ element.ProbableCause }}">
            {{
              element.ID || element.fishBoneDataNotInCause
                ? getCauseValue(element.ProbableCause)
                : ""
            }}
          </div>
          <mat-form-field
            *ngIf="!element.ID && !element.fishBoneDataNotInCause"
            class="w-90"
          >
            <input
              matInput
              type="text"
              (change)="checkProbCauseExist(element.ProbableCause, i)"
              [(ngModel)]="element.ProbableCause"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="Weight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Weight</p>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          style="margin: 0 10px 0 10px; width: 175px"
          nowrap
        >
          <mat-radio-group
            aria-label="Select an option"
            controlName="Weight"
            [(ngModel)]="element.Weight"
          >
            <mat-radio-button
              *ngFor="let chk of Weigths"
              [value]="chk.value"
              [checked]="chk.checked"
              >{{ chk.value }}
            </mat-radio-button>
          </mat-radio-group>
        </td>
      </ng-container>
      <ng-container matColumnDef="ValidationResult">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="grp-order">
          <p class="header-text">
            Validation Result <span class="text-danger">*</span>
          </p>
        </th>
        <td mat-cell *matCellDef="let element">
          <div title="{{ element.ValidationResult }}">
            <mat-form-field class="w-100">
              <input
                matInput
                type="text"
                [readonly]="element.isFiveY && element.ID ? true : false"
                [(ngModel)]="element.ValidationResult"
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="Image">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-min-width"
        >
          <p class="header-text">Image (jpg,png,jpeg)</p>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          class="cause-identified"
        >
          <div class="imgflex" style="position: relative; display: flex">
            <button
              mat-flat-button
              color="accent"
              type="button"
              class="upload"
              (click)="openFile(i, 'pic')"
            >
              <mat-icon>attach_file</mat-icon>
            </button>
            <input
              type="file"
              (change)="readFile($event, 'pic')"
              (click)="$event.target.value = null"
              class="d-none"
              #fileInput
              accept="image/*"
            />

            <div title="image" style="display: flex">
              <img
                *ngIf="element.FileSrc || element.UploadFile"
                (click)="imageZoomModal(i)"
                [src]="element.FileSrc || blobStorageUrl + element.UploadFile"
                alt="image"
                width="60px"
                height="40px"
              />
              <!-- <span *ngIf="element.UploadFile" style="align-items: end;transform: rotate(90deg);display: flex;width: 50px;justify-content: center;">{{ getFileTypeName(element.UploadFile) }}</span> -->
            </div>
            <img
              *ngIf="element.FileSrc || element.UploadFile"
              src="assets/images/delete.png"
              (click)="deleteFile(i, 'pic')"
              class="imgabsol"
              alt=""
              width="15px"
              height="15px"
              style="position: absolute; left: 64px; top: 0"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="CauseIdentified">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-min-width"
          style="min-width: 120px"
        >
          <p class="header-text">
            Cause Identified <span class="text-danger">*</span>
          </p>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          class="cause-identified"
        >
          <mat-slide-toggle
            [(ngModel)]="element.CauseIdentified"
            (change)="updateRemark(i)"
          >
          </mat-slide-toggle>
        </td>
      </ng-container>
      <ng-container matColumnDef="Remark">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-min-width"
        >
          <p class="header-text">Remarks</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <div title="{{ element.Remark }}">
            <mat-form-field class="w-90">
              <input
                matInput
                type="text"
                [(ngModel)]="element.Remark"
                [disabled]="element.CauseIdentified"
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>
          <p class="header-text">Actions</p>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          class="row-action-btns-column"
        >
          <div class="row-action-btns" style="min-width: 85px">
            <button
              mat-icon-button
              class="button"
              [disabled]="!IsWrite"
              (click)="addRow(i, 'sub')"
              tooltipPosition="top"
              matTooltip="Sub Row"
            >
              <mat-icon class="menu">add_circle</mat-icon>
              <mat-icon class="menu">add_circle</mat-icon>
            </button>
            <button
              mat-icon-button
              class="button"
              *ngIf="levelId == 0 || levelId == 3"
              [disabled]="!IsWrite"
              (click)="addRow(i, 'main')"
              tooltipPosition="top"
              matTooltip="New Row"
            >
              <mat-icon class="menu">add_circle </mat-icon>
            </button>
            <button
              mat-icon-button
              class="delete-button button"
              [disabled]="!IsWrite"
              (click)="deleteRow(i)"
              *ngIf="
                !element.ID &&
                !element.indModule &&
                !element.fishBoneDataNotInCause
              "
              tooltipPosition="top"
              matTooltip="Delete Row"
            >
              <mat-icon class="menu">delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="causeEffectModelColumns; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: causeEffectModelColumns"></tr>
    </table>
  </div>
</div>
