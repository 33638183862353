import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ButtonStackerRendererComponent } from 'src/app/components/button-Stacker-render.components';
import { GlassPpcLiveGeneraldataService } from 'src/app/services/glass-ppc-live-generaldata.service';
import { OpmsGlassService } from 'src/app/services/opms-glass.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
@Component({
  selector: 'app-glass-ppc-live-stackerdata',
  templateUrl: './glass-ppc-live-stackerdata.component.html',
  styleUrls: ['./glass-ppc-live-stackerdata.component.scss']
})
export class GlassPpcLiveStackerdataComponent implements OnInit {

  @ViewChild('stackerModel') private stackerModel!: TemplateRef<any>;
  private subscriptions = new Subscription();
  public breadcrumList: any;
  ppcStackerData!: FormGroup;
  StacketDataShiftCrew!: FormGroup;
  machineName: any = [];
  jobList: any = [];
  columnDefsLiteData = [
    {headerName: 'MachineName', field: 'MachineName'},
    {headerName: 'JobNo', field: 'JobNo'},
    {headerName: 'LiteNo', field: 'LiteNo'},
    {headerName: 'CapId', field: 'CapId'},
    {headerName: 'Length', field: 'Length'},
    {headerName: 'Width', field: 'Width'},
    {headerName: 'FrontEdgeTachWheelNo', field: 'FrontEdgeTachWheelNo'},
    {headerName: 'RearEdgeTachWheelNo', field: 'RearEdgeTachWheelNo'},
    {headerName: 'NominalThickness', field: 'NominalThickness'},
    {headerName: 'YCB', field: 'YCB'},
    {headerName: 'LiteInCap', field: 'LiteInCap'},
    {headerName: 'Quality', field: 'Quality'},
    {headerName: 'CutTimestamp', field: 'CutTimestamp ', valueFormatter: this.dateFormatter},
  ];
  defaultColDef: any =  {
    resizable: true,
    sortable: true,
    filter: true,
  };
  rowLiteData: any = [];
  columnDefsStackerData = [
      {
        headerName: 'Actions',
        field: 'Actions',
        cellRenderer: 'buttonRenderer',
        minWidth: 140,
        cellRendererParams: { clicked: (field: any) => {} }
      },
      {headerName: 'MachineName', field: 'MachineName'},
      {headerName: 'OrderNumber', field: 'OrderNumber'},
      {headerName: 'Shift', field: 'Shift'},
      {headerName: 'Crew', field: 'Crew'},
      {headerName: 'BatchNumber', field: 'BatchNo'},
      {headerName: 'Valid BatchNumber', field: 'ValidBatchNo'},
      {headerName: 'StackerStatus', field: 'StackerStatus'},
      {headerName: 'LiteCount Actual', field: 'PalletLiteCount'},
      {headerName: 'LiteCount ERP', field: 'LiteCountErp',
       cellStyle: (params: any) => params.value != params.data.PalletLiteCount ? { color: 'red', background: '#e5dede'} : { color: 'black' }
      },
      {headerName: 'Result', field: 'Result', valueFormatter: (params: any) => params.data.oldDataLiteCount ? (params.value*params.data.PalletLiteCount).toFixed(4) : params.value},
      {headerName: 'Result ERP', field: 'ItemDesc', valueFormatter: (params: any) => this.resultERPCalculation(params.data)},
      {headerName: 'EndPackTime', field: 'StartPackTime', valueFormatter: this.dateFormatter},
  ];
  getRowStyle = params => {
    if (params.data.printStatus == true) {
        return { background: '#a2c6c6e8 !important;' };
    }
  };
  rowStackerData: any = [];
  panelOpenState = false;
  frameworkComponents: any;
  shiftList: any;
  crewList: any;
  thicknessList: any = [];
  batchId: number;
  stackerId: number;
  shiftCrewId: number = 0;
  crewStatus: boolean = true;
  oldLiteCountStatus: boolean = false;
  oldLiteCount: number;
  IsLiteCountValid: boolean = true;
  palletResultWithOutLite: any;
  oldLiteCountForResult: number;

  constructor(
    private spinner: NgxSpinnerService,
    private usermanagementService: UserManagementService,
    private glassPpcLiveService: GlassPpcLiveGeneraldataService,
    private toaster: ToastrService,
    public router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private opmsGlassservice: OpmsGlassService,
  ) 
  { 
    this.frameworkComponents = {
      buttonRenderer: ButtonStackerRendererComponent,
    };
  }

  ngOnInit(): void {
    this.loadBaseDetails();
    var firstDate = new Date((new Date()).setDate((new Date().getDate())-1));
    this.ppcStackerData = this.fb.group({
      startDate: [this.datePipe.transform(firstDate, 'yyyy-MM-dd')],
      endDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      stacker: [''],
      jon_no: [''],
      shift: [''],
      crew: [''],
    });
    this.StacketDataShiftCrew = this.fb.group({
      shift: [''],
      crew: [''],
      MachineName: [''],
      OrderNumber: [''],
      BatchNumber: [''],
      LiteCount: [''],
      LiteCountERP: [''],
      Result: [''],
      ResultERP: [''],
      StartPackTime: [''],
      StackerStatus: [''],
    })
    this.getBreadcrumList();
    this.getStackerList();
  }

  getStackerList(){
    this.machineName = [];
    this.glassPpcLiveService.GetStackerList().subscribe(
      (response: any) => {
        if(!response.HasError){
          if(response.result.length){
            this.machineName = response.result;
            this.ppcStackerData.get('stacker')?.setValue(this.machineName);
            this.getJobByStacker();
          }
        }else{
          this.toaster.error(response.result[0].Msg);
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.spinner.hide();
      }
    )
  }

  getJobByStacker(){
    this.spinner.show();
    const selectedJob = this.ppcStackerData.get('jon_no')?.value.length == this.jobList.length
                        ? [] : this.ppcStackerData.get('jon_no')?.value;
    this.jobList = [];
    if((this.ppcStackerData.get('stacker')?.value).length){
      let data = {
        StartDate: this.ppcStackerData.get('startDate')?.value,
        EndDate: this.ppcStackerData.get('endDate')?.value,
        Machine: (this.ppcStackerData.get('stacker')?.value).map(s => s.MachineName).toString()
      }
      this.glassPpcLiveService.GetJobList(data).subscribe(
        (response: any) => {
          if(!response.HasError){
            if(response.result.length){
              this.jobList = response.result;
              this.ppcStackerData.get('jon_no')?.setValue(selectedJob.length == 0 ? this.jobList : selectedJob);
              this.spinner.hide();
            }
            else{
              this.spinner.hide();
              this.toaster.warning("Warning! JobNo not available for selected Stacker");
            }
          }else{
            this.toaster.error(response.result[0].Msg);
            this.spinner.hide();
          }
        },
        (error: any) => {
          this.toaster.error(error.error.Message)
          this.spinner.hide();
        }
      )
    }
    else{
      this.spinner.hide();
      this.toaster.warning("Warning! Please Select Stacker");
    }
  }

  getLiteData(){
    this.spinner.show();
    if((this.ppcStackerData.get('jon_no')?.value).length){
      this.rowLiteData = [];
      let result: any = []; 
      result = (this.ppcStackerData.get('jon_no')?.value).map(a => a.JobNo);
      let data = {
        StartDate: this.ppcStackerData.get('startDate')?.value,
        EndDate: this.ppcStackerData.get('endDate')?.value,
        Machine: (this.ppcStackerData.get('stacker')?.value).map(s => s.MachineName).toString(),
        JobNo: result.toString()
      }
      this.glassPpcLiveService.GetLiteData(data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.spinner.hide();
            if(response.result.length){
              this.rowLiteData = response.result;
            }
          }else{
            this.toaster.error(response.result[0].Msg);
            this.spinner.hide();
          }
        },
        (error: any) => {
          this.toaster.error(error.error)
          this.spinner.hide();
        }
      )
    }
    else{
      this.spinner.hide();
      this.toaster.warning("Warning! Please Select JobNo");
    }
  }

  onGridReadyLiteData(params: any){
    const allColumnIds: any[] = [];
    (params.columnApi).getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    (params.columnApi).autoSizeColumns(allColumnIds, false);
  }

  getStakerData(){
    this.spinner.show();
    if((this.ppcStackerData.get('jon_no')?.value).length){
      this.rowStackerData = [];
      let result: any = []; 
      let shift: any = [];
      let crew: any = [];
      result = (this.ppcStackerData.get('jon_no')?.value).map(a => a.JobNo);
      if (this.ppcStackerData.get('shift')?.value == 0) {
        for (var i = 0; i < this.shiftList.length; i++) {
          shift.push(this.shiftList[i].TypeId);
        }
      } else {
        shift.push(this.ppcStackerData.get('shift')?.value);
      }
      if (this.ppcStackerData.get('crew')?.value == 0) {
        for (var i = 0; i < this.crewList.length; i++) {
          crew.push(this.crewList[i].TypeId);
        }
      } else {
        crew.push(this.ppcStackerData.get('crew')?.value);
      }
      let data = {
        StartDate: this.ppcStackerData.get('startDate')?.value,
        EndDate: this.ppcStackerData.get('endDate')?.value,
        Machine: (this.ppcStackerData.get('stacker')?.value).map(s => s.MachineName).toString(),
        JobNo: result.toString(),
        shift: shift.toString(),
        crew: crew.toString()
      }
      this.glassPpcLiveService.GetStackerData(data).subscribe(
        (response: any) => {
          if(!response.HasError){
            this.spinner.hide();
            if(response.result.length){
              this.rowStackerData = response.result;
            }
          }else{
            this.toaster.error(response.result[0].Msg);
            this.spinner.hide();
          }
        },
        (error: any) => {
          this.toaster.error(error.error)
          this.spinner.hide();
        }
      )
    }
  }
  
  //Enabling Barcode for PPC 
  oncellclicked(params: any): void {``
    const col = params.colDef.field;
    const resultERP = this.resultERPCalculation(params.data);
    this.oldLiteCountStatus = params.data.oldDataLiteCount;
    this.oldLiteCount = params.data.PalletLiteCount;
    this.oldLiteCountForResult = params.data.PalletLiteCount;
    this.IsLiteCountValid = (params.data.PalletLiteCount == parseInt(params.data.LiteCountErp)) ? true : false;
    const resultActual = this.oldLiteCountStatus ? (params.data.Result*params.data.PalletLiteCount).toFixed(4) : params.data.Result
    if (col === 'Actions') {
      const classes = params.event.target.classList.toString();
      if(classes.includes('edit_stacker')){
        this.batchId = params.data.BI_Id;
        this.stackerId = params.data.Id;
        this.dialog.open(this.stackerModel, {
          width: '90vw !important',
          height: '70vh', minWidth: '90vw'
        });
        this.StacketDataShiftCrew.get('shift')?.setValue("0");
        this.StacketDataShiftCrew.get('crew')?.setValue("0");
        this.shiftCrewId = 0;
         if(params.data.ShiftId){
          this.shiftCrewId = params.data.shiftCrewId;
          this.StacketDataShiftCrew.get('shift')?.setValue(params.data.ShiftId);
         }
         if(params.data.crewId){
          this.StacketDataShiftCrew.get('crew')?.setValue(params.data.crewId);
          this.crewStatus = false;
         }
         else{
          this.crewStatus = true;
         }
         this.StacketDataShiftCrew.get('MachineName')?.setValue(params.data.MachineName);
         this.StacketDataShiftCrew.get('OrderNumber')?.setValue(params.data.OrderNumber);
         this.StacketDataShiftCrew.get('BatchNumber')?.setValue(params.data.BatchNo);
         this.StacketDataShiftCrew.get('LiteCount')?.setValue(params.data.PalletLiteCount);
         this.StacketDataShiftCrew.get('LiteCountERP')?.setValue(params.data.LiteCountErp);
         this.StacketDataShiftCrew.get('Result')?.setValue(resultActual);
         this.StacketDataShiftCrew.get('ResultERP')?.setValue(resultERP);
         this.StacketDataShiftCrew.get('StartPackTime')?.setValue(params.data.StartPackTime);
         this.StacketDataShiftCrew.get('StackerStatus')?.setValue(params.data.StackerStatus);
      }
      else{
        if(classes.includes('withOutTin')){
          if(params.data.StackerStatus == 'OK' && params.data.PalletLiteCount == params.data.LiteCountErp && resultActual == resultERP){
            this.onClickPrintWithOutTin(params);
          }
          else{
            this.toaster.warning('Warning','Data Row is not valid');
          }
        }
        else if(classes.includes('withTin')){
          if(params.data.StackerStatus == 'OK' && params.data.PalletLiteCount == params.data.LiteCountErp && resultActual == resultERP){
            this.onClickPrint(params); 
          }
          else{
            this.toaster.warning('Warning','Data Row is not valid');
          }
        }
      }
    }
  }
  onClickPrint(event: any){
    if(event.data.OrderNumber){
      const key = {
        UnitId: 1517,
        OrderNo: event.data.OrderNumber,
        StackerId: event.data.StackerId,
        ManualProductionId: 0,
        LiteCount: event.data.LiteCountErp,
        SerialNo: event.data.BatchNo.split('_')[2],
      };
      this.glassPpcLiveService.GetBarCodeDetails_PPC(key).subscribe((res: any) => {
        if (res.GetBarCodeDetails.length > 0) {
          let htmlcontent = res.GetBarCodeDetails[0].HTMLLayout;
          const detailItems = res.GetBarCodeDetails[0].detailItems;
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries1]',
            res.BarCodeSeries1
          );
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries2]',
            res.BarCodeSeries2
          );
  
          $.each(detailItems, (i, item) => {
            if (item.Name === 'TimeNow') {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                moment(new Date()).format('DD-MM-YYYY hh:mm A')
              );
            } else {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                item.Value
              );
            }
          });
  
          const printWindow = window.open('', 'Print-Window')!;
          // printWindow?.document.open();
  
          const styleTags =
            '' +
            '<style type="text/css">' +
            '@media print {' +
            '{-webkit-print-color-adjust: exact;}' +
            '-webkit-print-color-adjust: exact;' +
            '}' +
            'table, th, td {' +
            'border:1px solid black;' +
            'border-collapse: collapse;' +
            '}' +
            '.td-bold {font-weight:bold; }' +
            '</style>';
  
          const scriptTags =
            '' +
            '<script type="text/javascript">' +
            'window.addEventListener(' +
            "'load'" +
            ', function () {' +
            'JsBarcode(".barcode").init();' +
            '})' +
            '</script>';
  
          const JsBCScript =
            '' +
            '<script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.3/dist/JsBarcode.all.min.js"></script>';
  
          printWindow?.document.write(
            styleTags +
            JsBCScript +
            scriptTags +
            '<html><body onload="window.print();">' +
            htmlcontent +
            '</body></html>'
          );
          // window.print();
          setTimeout(() => {
            printWindow?.document.close();
            printWindow?.close();
            this.updatePrintStatus(event);
          }, 1000);
  
          // window.print();
        } else {
          this.toaster.error('error', 'Data is Empty !');
        }
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
      );
    }
    else{
      this.toaster.warning('No Order Number Found','Warning');
    }
  }

  onClickPrintWithOutTin(event: any){
    if(event.data.OrderNumber){
      const key = {
        UnitId: 1517,
        OrderNo: event.data.OrderNumber,
        StackerId: event.data.StackerId,
        ManualProductionId: 0,
        LiteCount: event.data.LiteCountErp,
        SerialNo: event.data.BatchNo.split('_')[2]
      };
      this.glassPpcLiveService.GetBarCodeDetails_PPC_CustomizedForGlass(key).subscribe((res: any) => {
        if (res.GetBarCodeDetails.length > 0) {
          let htmlcontent = res.GetBarCodeDetails[0].HTMLLayout;
          const detailItems = res.GetBarCodeDetails[0].detailItems;
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries1]',
            res.BarCodeSeries1
          );
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries2]',
            res.BarCodeSeries2
          );
  
          $.each(detailItems, (i, item) => {
            if (item.Name === 'TimeNow') {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                moment(new Date()).format('DD-MM-YYYY hh:mm A')
              );
            } else {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                item.Value
              );
            }
          });
  
          const printWindow = window.open('', 'Print-Window')!;
          // printWindow?.document.open();
  
          const styleTags =
            '' +
            '<style type="text/css">' +
            '@media print {' +
            '{-webkit-print-color-adjust: exact;}' +
            '-webkit-print-color-adjust: exact;' +
            '}' +
            'table, th, td {' +
            'border:1px solid black;' +
            'border-collapse: collapse;' +
            '}' +
            '.td-bold {font-weight:bold; }' +
            '</style>';
  
          const scriptTags =
            '' +
            '<script type="text/javascript">' +
            'window.addEventListener(' +
            "'load'" +
            ', function () {' +
            'JsBarcode(".barcode").init();' +
            '})' +
            '</script>';
  
          const JsBCScript =
            '' +
            '<script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.3/dist/JsBarcode.all.min.js"></script>';
  
          printWindow?.document.write(
            styleTags +
            JsBCScript +
            scriptTags +
            '<html><body onload="window.print();">' +
            htmlcontent +
            '</body></html>'
          );
          // window.print();
          setTimeout(() => {
            printWindow?.document.close();
            printWindow?.close();
            this.updatePrintStatus(event);
          }, 1000);
  
          // window.print();
        } else {
          this.toaster.error('error', 'Data is Empty !');
        }
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
      );
    }
    else{
      this.toaster.warning('No Order Number Found','Warning');
    }
  }

  updatePrintStatus(params: any){
    if(!params.data.printStatus){
      const data = {
        shiftCrewId: params.data.shiftCrewId,
        userId: JSON.parse(localStorage.user).UserId,
        totalBatchId: params.data.BI_Id
      }
      this.glassPpcLiveService.UpdatePrintStatus(data).subscribe(
        (response: any) => {
          this.getStakerData();
        },
        (error: any) => {
          this.toaster.error(error.error.Message)
          this.spinner.hide();
        }
      )
    }
  }

  updatePalletQty(){
    if(this.StacketDataShiftCrew.value.shift == 0){
      this.toaster.warning("Warning","Please Select Shift");
      return;
    }
    const data = {
      TransactionId: this.shiftCrewId,
      BatchId: this.batchId,
      StackerId: this.stackerId,
      ShiftId: this.StacketDataShiftCrew.value.shift,
      CrewId: this.StacketDataShiftCrew.value.crew == 0 ? null : this.StacketDataShiftCrew.value.crew,
      CreatedBy: JSON.parse(localStorage.user).UserId,
      StackerStatus: this.StacketDataShiftCrew.value.StackerStatus,
      IsPalletValid: (this.StacketDataShiftCrew.value.LiteCount == this.StacketDataShiftCrew.value.LiteCountERP &&
                      this.StacketDataShiftCrew.value.Result == this.StacketDataShiftCrew.value.ResultERP) ? true : false,
      IsLiteCountChange: (this.oldLiteCount == this.StacketDataShiftCrew.value.LiteCount) ? false : true,
      IsOldBatchInfo: this.oldLiteCountStatus ? false : true,
      OrderNumber: this.StacketDataShiftCrew.value.OrderNumber,
      LiteCountActual: this.StacketDataShiftCrew.value.LiteCount,
      Result: parseFloat((this.StacketDataShiftCrew.value.Result/this.StacketDataShiftCrew.value.LiteCount).toFixed(4)),
      MachineName: this.StacketDataShiftCrew.value.BatchNumber.split('_')[0]
    }
    this.glassPpcLiveService.UpdatePalletQty(data).subscribe(
      (response: any) => {
        if(!response.HasError){
          if(response.result.length){
            if(this.shiftCrewId==0){
              this.toaster.success("Success","Stacker Data Details Added Successfuly");
            }
            else{
              this.toaster.success("Updated","Stacker Data Details Updated Successfuly");
              this.shiftCrewId = 0;
            }
            this.dialog.closeAll();
            this.getStakerData();
          }
        }else{
          this.toaster.error(response.result[0].Msg);
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.spinner.hide();
      }
    )
  }

  calculateActualResut(){
    if(!this.IsLiteCountValid){
      const resultActual = (this.StacketDataShiftCrew.value.Result/this.oldLiteCountForResult).toFixed(4);
      this.StacketDataShiftCrew.get('Result')?.setValue((parseFloat(resultActual)*this.StacketDataShiftCrew.value.LiteCount).toFixed(4));
      this.oldLiteCountForResult = this.StacketDataShiftCrew.value.LiteCount;
    }
  }

  onGridReadyStakerData(params: any){
    (params.api).sizeColumnsToFit();
  }

  getBreadcrumList(): any {
    this.spinner.show();
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    const breadCrumSub = this.usermanagementService
      .GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
          this.spinner.hide();
        }
      );
    this.subscriptions.add(breadCrumSub);
  }

  displayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  dateFormatter(params: any): any {
    if(params.value){
      return moment(new Date(params.value)).format('MM/DD/YYYY hh:mm a');
    }
  }
  resultERPCalculation(params: any): any {
    if(params.ItemCode && params.LiteCountErp){
      const parameters = params.ItemCode.substr(6);
      let thickness = Number(parameters.substr(0,3)).toString();
      if(thickness[thickness.length-1] == '0'){
        thickness = thickness.slice(0,-1);
      }
      else{
        thickness = thickness[0]+'.'+thickness[1];
      }
      const length = parameters.substr(3,4);
      const width = parameters.substr(7,4);
      const nominalThick = this.thicknessList.find(x => x.TypeName == thickness).NominalThick;
      const resultERP = ((length*width*nominalThick*2.5)/1000000000).toFixed(4);
      return (parseFloat(resultERP)*params.LiteCountErp).toFixed(4);
    }
  }

  loadBaseDetails(){
    const data = {
      UserId: JSON.parse(localStorage.user).UserId
     }
     this.opmsGlassservice.loadBaseDetails(data).subscribe(
       (response: any)=>{
        this.thicknessList = response.ThicknessList;
        this.shiftList = response.ShiftList;
        this.crewList = response.CrewList;
        this.ppcStackerData.get('shift')?.setValue("0");
        this.ppcStackerData.get('crew')?.setValue("0");
       },
     )
  }
}
