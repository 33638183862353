import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProcessParameterLoginComponent } from '../ParameterLoginConfig/process-parameter-login/process-parameter-login.component';
import { QualityParameterLoginComponent } from '../ParameterLoginConfig/quality-parameter-login/quality-parameter-login.component';
import { QualityProcessControlComponent } from '../ParameterLoginConfig/quality-process-control/quality-process-control.component';
import { AuditExecutionComponent } from '../quality-management/audits/audit-execution/audit-execution.component';
import { AuditMasterDataComponent } from '../quality-management/audits/audit-master-data/audit-master-data.component';
import { GembaWalkComponent } from '../quality-management/audits/gemba-walk/gemba-walk.component';
import { NcrApprovalComponent } from '../quality-management/ncr-approval/ncr-approval.component';
import { CcrManagementComponent } from '../quality-management/ncr-ccr-management/ccr-management/ccr-management.component';
import { CustomerManagementComponent } from '../quality-management/ncr-ccr-management/customer-management/customer-management.component';
import { NcrCcrManagementComponent } from '../quality-management/ncr-ccr-management/ncr-ccr-management.component';
import { NcrMangementComponent } from '../quality-management/ncr-ccr-management/ncr-management/ncr-mangement.component';
import { ProcessControlComponent } from '../quality-management/process-control/process-control.component';
import { QualityCheckHistoryComponent } from '../quality-management/quality-check-history/quality-check-history.component';
import { QualityMasterDataComponent } from '../quality-management/quality-control/quality-master-data/quality-master-data.component';
import { QualityParameterComponent } from '../quality-management/quality-parameter/quality-parameter.component';
import { QxMatrixReportComponent } from '../quality-management/quality-reports/qx-matrix-report/qx-matrix-report.component';
import { QxMatrixComponent } from '../quality-management/quality-reports/qx-matrix/qx-matrix.component';
import { QxUploadComponent } from '../quality-management/quality-reports/qx-upload/qx-upload.component';
import { CcrDashboardComponent } from '../screens/ccr-dashboard/ccr-dashboard.component';
import { QualitycheckexecutionComponent } from '../screens/check-List/execution/qualitycheckexecution.component';
import { AuthGuard } from '../services/auth.guard';
import { ProcessExecutionComponent } from '../quality-management/audits/process-execution/process-execution.component';
import { UsersActionsComponent } from '../quality-management/UsersActions/UsersActions.component';
import { ProcessConfirmationDbComponent } from '../quality-management/process-confirmation-db/process-confirmation-db.component';
import { QcVerifyComponent } from '../quality-management/qc-verify-report/qc-verify/qc-verify.component';
import { WarehouseReceivedComponent } from '../ProductionOrderComponents/warehouse/warehouse-received/warehouse-received.component';
import { QCBoxComponent } from '../ProductionOrderComponents/QC-Box/QC-Box.component';
import { RawMaterialDefinitionComponent } from '../KPIs Management/KpiMasterComponents/rawMaterialDefinition/rawMaterialDefinition.component';
import { RawMaterialExecutionComponent } from '../KPIs Management/KpiMasterComponents/rawMaterialExecution/rawMaterialExecution.component';
import { ChemicalLabComponent } from '../opms-quality/chemical-lab/chemical-lab.component';
import { QaDataEntryComponent } from '../Quality-ODS/qa-data-entry/qa-data-entry.component';
import { GlassPhysicalQaReportComponent } from './glass-physical-qa-report/glass-physical-qa-report.component';
import { GlassQaDashboardComponent } from './glass-qa-dashboard/glass-qa-dashboard.component';
import { GlassPpcLiveGeneraldataComponent } from './glass-ppc-live-generaldata/glass-ppc-live-generaldata.component';
import { GlassChemicalQaReportComponent } from './glass-chemical-qa-report/glass-chemical-qa-report.component';
import { GlassChemicalQaDashboardComponent } from './glass-chemical-qa-dashboard/glass-chemical-qa-dashboard.component';
import { NcrActionMasterComponent } from '../quality-management/ncrActionMaster/ncrActionMaster.component';
import { MachineParameterTrendComponent } from './spcQualityDashboard/machine-parameter-trend/machine-parameter-trend.component';
import { QualityParameterTrendComponent } from './spcQualityDashboard/quality-parameter-trend/quality-parameter-trend.component';
import { ParameterAnalysisDashboardComponent } from './spcQualityDashboard/ParameterAnalysisDashboard/ParameterAnalysisDashboard.component';
import { DynamicDashboardComponent } from '../screens/dashboard/dynamic-dashboard/dynamic-dashboard.component';
import { LandingPageComponent } from '../screens/landing-page/landing-page/landing-page.component';
const routes: Routes = [
  // {path: 'UserAcessList', component: UseraccessComponent, canActivate: [AuthGuard] },
  {
    path: 'CheckList',
    component: QualitycheckexecutionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'NcrCcrManagement',
    component: NcrCcrManagementComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'NcrManagement',
  //   component: NcrMangementComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'CcrManagement',
    component: CcrManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'CustomersManagement',
    component: CustomerManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ProcessParameterLogging',
    component: ProcessParameterLoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'QualityParameterLogging',
    component: QualityParameterLoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'CCRDashboard',
    component: CcrDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ProcessConfirmationDB ',
    component: ProcessConfirmationDbComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'AuditMaster',
    component: AuditMasterDataComponent,
    canActivate: [AuthGuard],
  },
  { path: 'QxMatrix', component: QxMatrixComponent, canActivate: [AuthGuard] },
  { path: 'QxUpload', component: QxUploadComponent, canActivate: [AuthGuard] },
  {
    path: 'QxMatrixReport',
    component: QxMatrixReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'NcrActionMaster',
    component: NcrActionMasterComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'GembaWalk',
    component: GembaWalkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'AuditExecution',
    component: AuditExecutionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'NCRApproval',
    component: NcrApprovalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'QuailtyMasterData',
    component: QualityMasterDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'QualityParameter',
    component: QualityParameterComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ProcessControl',
    component: ProcessControlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'QualityCheckHistory',
    component: QualityCheckHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'QualityControl',
    component: QualityProcessControlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ProcessExecution',
    component: ProcessExecutionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'UserActions',
    component: UsersActionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ProcessConfirmationDB',
    component: ProcessConfirmationDbComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'QCVerifyReport',
    component: QcVerifyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'WarehousePalletReceived',
    component: WarehouseReceivedComponent,
    canActivate: [AuthGuard],
  },
  { path: 'QCBox', component: QCBoxComponent, canActivate: [AuthGuard] },
  {
    path: 'RawMaterialDefinition',
    component: RawMaterialDefinitionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'RawMaterialExecution',
    component: RawMaterialExecutionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'chemicalLab',
    component: ChemicalLabComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'qaDataEntry',
    component: QaDataEntryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'physicalLabQAreport',
    component: GlassPhysicalQaReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chemicalLabQAreport',
    component: GlassChemicalQaReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'physicalLabQAdashboard',
    component: GlassQaDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chemicalLabQAdashboard',
    component: GlassChemicalQaDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'live_generaldata',
    component: GlassPpcLiveGeneraldataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'MachineParameterSPC',
    component: MachineParameterTrendComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'QualityParameterSPC',
    component: QualityParameterTrendComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ParameterAnalysisDashboard',
    component: ParameterAnalysisDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'myDashboard',
    component: DynamicDashboardComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QualityManagementRoutingModule {}
