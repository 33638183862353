import { Component, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
const moment = _moment;
//drilldown js method declaration
declare function setDatasourceMaintenance(data: any): any;

@Component({
  selector: 'app-maintenance-board',
  templateUrl: './maintenance-board.component.html',
  styleUrls: ['./maintenance-board.component.scss']

})
export class MaintenanceBoardComponent implements OnInit {
  treeControl = new NestedTreeControl<PlantGroupElement>(node => node.children);
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  hasChild = (_: number, node: PlantGroupElement) => !!node.children && node.children.length > 0;
  maintenanceBoardForm!: FormGroup;
  oeeAnalysis = {
    barType: 'radial',
    color: '#10CE9C',
    secondColor: '#E5E5E5',
    progress: 60,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      }
    }
  };
  capacityUtilizationAnalysis = {
    barType: 'radial',
    color: '#3AAFE4',
    secondColor: '#E5E5E5',
    progress: 55,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      }
    }
  };
  availabilityAnalysis = {
    barType: 'radial',
    color: '#F85439',
    secondColor: '#E5E5E5',
    progress: 45,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#F85439',
      }
    }
  };
  performanceAnalysis = {
    barType: 'radial',
    color: '#3AAFE4',
    secondColor: '#E5E5E5',
    progress: 65,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      }
    }
  };

  qualityAnalysis = {
    barType: 'radial',
    color: '#F85439',
    secondColor: '#E5E5E5',
    progress: 45,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#F85439',
      }
    }
  };


  // iconUrl
  menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';

  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  filteredUnits: any;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  MaintenanceDashboardData!: any;
  isproductionDBModel = false;
  isPopupMachine = false;
  isPlantGroupModelVisible = false;
  UnitName: string | undefined;
  countTS: any[] = new Array();
  minutesTS: any = 0;
  countPD: any[] = new Array();
  minutesPD: any = 0;
  countUPD: any[] = new Array();
  minutesUPD: any = 0;
  chartMaintenance!: Chart;
  options!: Options;

  key: any;

  constructor(
    private formBuilder: FormBuilder,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private productivityService: ProductivityService,
    private machineService: MachineMappingService,
    private enterpriseGroupService: EnterPriseGroupService,
    private spinner: NgxSpinnerService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.spinner.show();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.datasource();
  }

  onChangeGroup(data: any): void {
    this.getPlants(data);
  }
  onChangeLine(data: any): void {
    this.getUnit(data.value);
  }
  onChangePlant(data: any): void {
    this.getLine(data.value);
  }

  initForm() {
    const dateStart = new Date();
    dateStart.setHours(6);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);
    const dateEnd = new Date();
    this.maintenanceBoardForm = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
      shift: ['', []],
      searchval: [''],
    });
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  datasource(): void {
    const key = {
      UserId: 206
    };
    const id = {
      value: 0
    };
    this.productivityService.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      if(this.GroupList.length > 0){
        id.value = data.lstEG[0].EG_Id;
        this.maintenanceBoardForm.get('UserGroup1')?.setValue(data.lstEG[0].EG_Id);
        this.filteredGroups = this.GroupList.slice();
        this.getPlants(id);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: 206
    };

    this.productivityService.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      if(this.PlantList1.length > 0){
        const id = data.lstPlant[0].PT_Id;
        this.maintenanceBoardForm.get('UserPlant1')?.patchValue(id);
        this.filteredPlants = this.PlantList1.slice();
        this.getLine(id);
      }
     
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getLine(id: any) {
    const key = {
      PT_ID: id,
      userId: 206
    };

    this.productivityService.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      const lineDefault = this.lineList[0];
      if(this.lineList.length > 0){
        this.maintenanceBoardForm.controls.line.patchValue(lineDefault.PL_Id); 
        this.filteredLines = this.lineList.slice();
        this.getUnit(lineDefault.PL_Id);
      }
    
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUnit(id: number) {
    const key = {
      PL_ID: id,
      userId: 206
    };
    this.productivityService.getUnitData(key).subscribe((data: any) => {
      this.unitList = data.lstUnit;
      if(this.unitList.length > 0){
        const unitDefault = this.unitList[0];
        this.unitList.length > 0 ? this.maintenanceBoardForm.controls.unit.patchValue(unitDefault.PU_Id) : this.maintenanceBoardForm.controls.unit.patchValue('');
        this.filteredUnits = this.unitList.slice();
        this.onSubmit();
      } 
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });

  }

  onSubmit(): void {
    this.makeData();
    this.getMaintenance();
  }

  today(): void {
    this.makeDatatoDay();
    this.getMaintenance();
  }

  yesterday(): void {
    this.makeDataYesterDay();
    this.getMaintenance();
  }

  lastWeek(): void {
    this.makeDataLastWeek();
    this.getMaintenance();
  }

  lastMonth(): void {
    this.makeDataLastMonth();
    this.getMaintenance();
  }

  getMaintenance() {
    this.spinner.show();
    this.productivityService.getMaintenance(this.key).subscribe((data: any) => {
      this.MaintenanceDashboardData = data;
      this.spinner.hide();
      this.setData();
    }, (error: any) => {
      this.toaster.error('error', error.message);
      this.spinner.hide();
    });
  }

  setData() {
    setDatasourceMaintenance(this.MaintenanceDashboardData);
    this.setCountAndMinutes(this.MaintenanceDashboardData.dtData);
    this.oeeAnalysis.progress = this.MaintenanceDashboardData.AvgOee.toFixed(1);
    this.availabilityAnalysis.progress = this.MaintenanceDashboardData.AvgAvailability.toFixed(1);
    this.performanceAnalysis.progress = this.MaintenanceDashboardData.AvgPerformance.toFixed(1);
    this.qualityAnalysis.progress = this.MaintenanceDashboardData.AvgQualityRate.toFixed(1);
    this.setColorCoding();
  }

  barChartTotalDowntime() {
    let countTS = 0, countPD = 0, countUPD = 0;
    countTS = this.countTS.length;
    countPD = this.countPD.length;
    countUPD = this.countUPD.length;
    this.options = {
      chart: {
        type: 'bar'
      },
      title: {
        text: '<span style="font-weight: bold;">Total Downtime (TSD,PDT,UPDT)</span>'
      },
      xAxis: {
        categories: ['Total Shutdown', 'Plan Downtime', 'UnPlanned Downtime'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total Downtime (Count And Minute)',
          align: 'high'
        },
      },
      credits: {
        enabled: false
      },
      tooltip: {
        valueSuffix: ''
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        type: 'bar',
        name: 'Count',
        color: '#4287f5',
        data: [countTS, countPD, countUPD]
      }, {
        type: 'bar',
        name: 'Minutes',
        color: '#fcba03',
        data: [this.minutesTS, this.minutesPD, this.minutesUPD]
      }]
    };
    let chart = new Chart(this.options);
    this.chartMaintenance = chart;
  }

  makeData() {
    this.key = {
      PP_Id: 0,
      dtStartDate: this.maintenanceBoardForm.value.dateStart,
      dtEndDate: this.maintenanceBoardForm.value.dateEnd,
      GroupId: this.maintenanceBoardForm.value.UserGroup1,
      PlantId: this.maintenanceBoardForm.value.UserPlant1,
      UnitId: this.maintenanceBoardForm.value.unit,
      LineId: this.maintenanceBoardForm.value.line,
      shift: '*'
    };

  }

  makeDatatoDay(): void {
    const stDT = new Date();
    stDT.setDate(stDT.getDate());
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);
    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    const startDt = stDT;
    const endDt = edDT;
    this.maintenanceBoardForm.get('dateStart')?.patchValue(stDT);
    this.maintenanceBoardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.maintenanceBoardForm.value.UserGroup1,
      PlantId: this.maintenanceBoardForm.value.UserPlant1,
      UnitId: this.maintenanceBoardForm.value.unit,
      LineId: this.maintenanceBoardForm.value.line,
      shift: '*'
    };
  }

  makeDataYesterDay(): void {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 1);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.maintenanceBoardForm.get('dateStart')?.patchValue(stDT);
    this.maintenanceBoardForm.get('dateEnd')?.patchValue(edDT);

    this.key = {
      PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.maintenanceBoardForm.value.UserGroup1,
      PlantId: this.maintenanceBoardForm.value.UserPlant1,
      UnitId: this.maintenanceBoardForm.value.unit,
      LineId: this.maintenanceBoardForm.value.line,
      shift: '*'
    };
  }

  makeDataLastWeek(): void {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 6);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.maintenanceBoardForm.get('dateStart')?.patchValue(stDT);
    this.maintenanceBoardForm.get('dateEnd')?.patchValue(edDT);

    this.key = {
      PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.maintenanceBoardForm.value.UserGroup1,
      PlantId: this.maintenanceBoardForm.value.UserPlant1,
      UnitId: this.maintenanceBoardForm.value.unit,
      LineId: this.maintenanceBoardForm.value.line,
      shift: '*'
    };
  }

  makeDataLastMonth(): void {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 29);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.maintenanceBoardForm.get('dateStart')?.patchValue(stDT);
    this.maintenanceBoardForm.get('dateEnd')?.patchValue(edDT);

    this.key = {
      PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.maintenanceBoardForm.value.UserGroup1,
      PlantId: this.maintenanceBoardForm.value.UserPlant1,
      UnitId: this.maintenanceBoardForm.value.unit,
      LineId: this.maintenanceBoardForm.value.line,
      shift: '*'
    };
  }

  nFormatter(num: any, digits: any) {

    if (digits === undefined) {
      digits = 1;
    }
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: " K" },
      { value: 1E6, symbol: " M" },
      { value: 1E9, symbol: " G" },
      { value: 1E12, symbol: " T" },
      { value: 1E15, symbol: " P" },
      { value: 1E18, symbol: " E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  };

  //color coding
  setColorCoding() {
    //OEE
    if (this.oeeAnalysis.progress > 0 && this.oeeAnalysis.progress <= 20) {
      this.oeeAnalysis.color = "#fa1f0f";
    } else if (this.oeeAnalysis.progress > 20 && this.oeeAnalysis.progress <= 50) {
      this.oeeAnalysis.color = "#fae311";
    } else if (this.oeeAnalysis.progress > 50 && this.oeeAnalysis.progress <= 70) {
      this.oeeAnalysis.color = "#fc7005";
    } else if (this.oeeAnalysis.progress > 70) {
      this.oeeAnalysis.color = "#069420";
    }
    //CU
    if (this.capacityUtilizationAnalysis.progress > 0 && this.capacityUtilizationAnalysis.progress <= 20) {
      this.capacityUtilizationAnalysis.color = "#fa1f0f";
    } else if (this.capacityUtilizationAnalysis.progress > 20 && this.capacityUtilizationAnalysis.progress <= 50) {
      this.capacityUtilizationAnalysis.color = "#fae311";
    } else if (this.capacityUtilizationAnalysis.progress > 50 && this.capacityUtilizationAnalysis.progress <= 70) {
      this.capacityUtilizationAnalysis.color = "#fc7005";
    } else if (this.capacityUtilizationAnalysis.progress > 70) {
      this.capacityUtilizationAnalysis.color = "#069420";
    }
    // Availability
    if (this.availabilityAnalysis.progress > 0 && this.availabilityAnalysis.progress <= 20) {
      this.availabilityAnalysis.color = "#fa1f0f";
    } else if (this.availabilityAnalysis.progress > 20 && this.availabilityAnalysis.progress <= 50) {
      this.availabilityAnalysis.color = "#fae311";
    } else if (this.availabilityAnalysis.progress > 50 && this.availabilityAnalysis.progress <= 70) {
      this.availabilityAnalysis.color = "#fc7005";
    } else if (this.availabilityAnalysis.progress > 70) {
      this.availabilityAnalysis.color = "#069420";
    }
    //performanceAnalysis
    if (this.performanceAnalysis.progress > 0 && this.performanceAnalysis.progress <= 20) {
      this.performanceAnalysis.color = "#fa1f0f";
    } else if (this.performanceAnalysis.progress > 20 && this.performanceAnalysis.progress <= 50) {
      this.performanceAnalysis.color = "#fae311";
    } else if (this.performanceAnalysis.progress > 50 && this.performanceAnalysis.progress <= 70) {
      this.performanceAnalysis.color = "#fc7005";
    } else if (this.performanceAnalysis.progress > 70) {
      this.performanceAnalysis.color = "#069420";
    }
    //qualityAnalysis
    if (this.qualityAnalysis.progress > 0 && this.qualityAnalysis.progress <= 20) {
      this.qualityAnalysis.color = "#fa1f0f";
    } else if (this.qualityAnalysis.progress > 20 && this.qualityAnalysis.progress <= 50) {
      this.qualityAnalysis.color = "#fae311";
    } else if (this.qualityAnalysis.progress > 50 && this.qualityAnalysis.progress <= 70) {
      this.qualityAnalysis.color = "#fc7005";
    } else if (this.qualityAnalysis.progress > 70) {
      this.qualityAnalysis.color = "#069420";
    }
  }

  setCountAndMinutes(data: any) {
    this.countTS.length = 0;
    this.minutesTS = 0;
    this.countPD.length = 0;
    this.minutesPD = 0;
    this.countUPD.length = 0;
    this.minutesUPD = 0;
    data.forEach((element: any) => {
      if (element.Category === 'Total Shutdown') {
        this.countTS.push(element);
        this.minutesTS += element.Duration;
      } else if (element.Category === 'Planned Downtime') {
        this.countPD.push(element);
        this.minutesPD += element.Duration;
      } else {
        this.countUPD.push(element);
        this.minutesUPD += element.Duration;
      }
    });
    this.barChartTotalDowntime();
  }
}
