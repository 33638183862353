<section class="home-content" dir="{{languageDir}}">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <mat-card>
    <form [formGroup]="yearlyScoreForm">
      <div style="padding:10px;background-color: #0F0FD1;">
        <div class="p-grid p-fluid">
          <div class="p-col-4">
            <mat-card-title style="color: white;">{{langObj ? langObj?.YearlyScorecard : ('YearlyScorecard' |
              translate)}}</mat-card-title>
          </div>
          <div class="p-col-4">
            <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
              'is-invalid':
              yearlyScoreForm.get('group')?.errors &&
              yearlyScoreForm.get('group')?.touched
            }" [options]="groupList" formControlName="group"
              placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}" optionLabel="TypeName"
              [showClear]="true"></p-dropdown>
            <p class="invalid-feedback" *ngIf="
            yearlyScoreForm.get('group')?.touched &&
            yearlyScoreForm.get('group')?.hasError('required')
            ">
              * {{langObj ? langObj?.pleaseselectgroup : ('pleaseselectgroup' | translate)}}
            </p>
          </div>
          <div class="p-col-4">
            <p-dropdown [ngClass]="{
                    'is-invalid':
                    yearlyScoreForm.get('plant')?.errors &&
                    yearlyScoreForm.get('plant')?.touched
                  }" [options]="searchPlants" formControlName="plant" [filter]="true"
              placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}" optionLabel="DeptDesc"
              [showClear]="true" (onChange)="onPlantChange()">
            </p-dropdown>
            <p class="invalid-feedback" *ngIf="
                yearlyScoreForm.get('plant')?.touched &&
                yearlyScoreForm.get('plant')?.hasError('required')
                ">
              * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
            </p>
          </div>
        </div>
      </div>
      <mat-card-content>
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-6 p-lg-4">
            <label>{{langObj ? langObj?.FromDate : ('FromDate' | translate)}}</label>
            <p-calendar [locale]="calendar_en" [ngClass]="{
            'is-invalid':
            yearlyScoreForm.get('fromDate')?.errors &&
            yearlyScoreForm.get('fromDate')?.touched
          }" dateFormat="mm/yy" formControlName="fromDate" appendTo="body" [firstDayOfWeek]=0 view="month"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2015:2050" inputId="navigators">
            </p-calendar>
          </div>
          <div class="p-col-12 p-md-6 p-lg-4">
            <label>{{langObj ? langObj?.ToDate : ('ToDate' | translate)}}</label>
            <p-calendar [locale]="calendar_en" [ngClass]="{
            'is-invalid':
            yearlyScoreForm.get('toDate')?.errors &&
            yearlyScoreForm.get('toDate')?.touched
          }" dateFormat="mm/yy" formControlName="toDate" appendTo="body" [firstDayOfWeek]=0 view="month"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2015:2050" inputId="navigators">
            </p-calendar>
          </div>
          <div class="p-col-12 p-md-6 p-lg-4">
            <button mat-flat-button color="accent" type="submit" class="btn-accent" (click)="onDateChange($event)"
              [disabled]="!yearlyScoreForm.valid">
              {{langObj ? langObj?.Search : ('Search' | translate)}} </button>
        </div>
      </div>
      <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="rowData"
        [columnDefs]="columnDefs" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
      </ag-grid-angular>

      </mat-card-content>
    </form>
  </mat-card>
</section>