import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GridConfiguration } from '../interface/grid-model';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  O3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }
  
  saveGridConfiguration(data: GridConfiguration) {
    return this.http.post(this.O3CoreApiUrl + 'Grid/SaveGridConfiguration', data);
  }

  getGridConfiguration(data: GridConfiguration) {
    return this.http.post(this.O3CoreApiUrl + 'Grid/GetGridConfiguration', data);
  }

  deleteGridConfiguration(Id: number) {
    return this.http.post(this.O3CoreApiUrl + 'Grid/DeleteGridConfiguration', Id);
  }
}
