import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserManu } from 'src/app/models/userManu';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserPlant } from 'src/app/models/userPlant';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { PlantWiseImmediateNcrActionsComponent } from '../PlantWiseImmediateNcrActions/PlantWiseImmediateNcrActions.component';
@Component({
  selector: 'app-ncrActionMaster',
  templateUrl: './ncrActionMaster.component.html',
  styleUrls: ['./ncrActionMaster.component.scss']
})
export class NcrActionMasterComponent implements OnInit {

  @ViewChild(PlantWiseImmediateNcrActionsComponent) plantWiseImmediateNcrActionsComponent!: PlantWiseImmediateNcrActionsComponent;

  userMenu!: UserManu[];
  pillarRead!: boolean | undefined;
  driverRead!: boolean | undefined;
  ipeMasterDataRead!: boolean | undefined;
  toolRead!: boolean | undefined;
  trendRead!: boolean | undefined;
  frequencyRead!: boolean | undefined;
  fourMRead!: boolean | undefined;
  dayOffRead!: boolean | undefined;
  masterShiftRead!: boolean | undefined;
  uomRead!: boolean | undefined;
  departmentRead!: boolean | undefined;
  kpireasonRead!: boolean | undefined;
  kpimasterweightRead!: boolean | undefined;
  pillarsPermissions!: any;
  driversPermissions!: any;
  toolsPermissions!: any;
  trendsPermissions!: any;
  frequenciesPermissions!: any;
  fourMPermissions!: any;
  dayOffPermissions!: any;
  ipeMDReadPermissions!: any;
  masterShiftPermissions!: any;
  kpiMasterWeightPermissions!: any;
  uomsPermissions!: any;
  departmentsPermissions!: any;
  kpireaonsPermissions!: any;
  plants!: UserPlant[];
  searchMasterForm!: FormGroup;
  masterData: any = {};
  breadcrumList: any;
  IsWrite: any = true;
  IsAccess: any = true;
  groupList: any;
  moduleId: any;
  pageId: any;
  constructor(
    private kpiMaster: KpimasterService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
  ) { 
  }

  ngOnInit(): void {
    this.searchMasterForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
    });
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroupData();
    const list = localStorage.getItem('result');
    this.userMenu = list !== null ? JSON.parse(list) : [];
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        const obj = this.groupList[0];
        this.searchMasterForm.get('group')?.setValue(obj);
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(): void {
    const key = {
      GroupId: this.searchMasterForm.value.group.TypeId,
    };
    this.usermanagementService.getDepartments(key).subscribe(
      (res: any) => {
        this.plants = res.DepartList;
        const plantObject = this.plants[0];
        this.searchMasterForm.get('plant')?.setValue(plantObject);
        this.getMasterData(plantObject);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getMasterData(event: any): void {
    debugger
    const plant = this.searchMasterForm.value.plant.DeptId;
    const key = {
      egId:this.searchMasterForm.value.group.TypeId,
      plantId: plant
    };
    if (this.IsAccess) {
      this.plantWiseImmediateNcrActionsComponent.getPlantWiseLocationMaster(key);
     }
  }

  GetMenuAccess(): void {
    debugger
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }


}
