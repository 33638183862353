<section class="home-content">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  
<mat-card>
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-6 p-lg-7 p-xl-7">
      <mat-card-title>AM Action Plan</mat-card-title>
    </div>
    <div class="p-col-12 p-md-6 p-lg-5 p-xl-5" style="display: flex; justify-content: flex-end;">
      <form [formGroup]="usersActionsForm"  style="float: right;">
        <div style="display: flex;justify-content: space-between;">
          <mat-form-field  appearance="fill" class="field-fill">
            <mat-label class="required">Group</mat-label>
            <mat-select panelClass="testClass" name="group" formControlName="group"  (selectionChange)="getPlant($event)" >
              <mat-option *ngFor="let p of groups" [value]="p.TypeId">  {{p.TypeName}} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field  appearance="fill" class="field-fill" style="float: right; margin-left: 10px;">
            <mat-label class="required">Plant</mat-label>
            <mat-select panelClass="testClass" name="plant" formControlName="plant" (selectionChange)="onPlantChange($event)">
              <mat-option *ngFor="let pt of plants" [value]="pt.DeptId">  {{pt.DeptDesc}} </mat-option>
            </mat-select>
          </mat-form-field> 
        </div>
      </form>
    </div>
  </div>
</mat-card>

<div class="wrapper-box wrapper-box-shadow grid-container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

  <form [formGroup]="gembaDataForm" >
      <div class="content-top-row content-top-row-order-processing d-flex">
        <!-- <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Select Owner</mat-label>
          <mat-select panelClass="testClass" name="owner" formControlName="owner" (selectionChange)="onOwnerChange($event)">
            <mat-option *ngFor="let pt of owners" [value]="pt.userId">  {{pt.userName}} </mat-option>
          </mat-select>
        </mat-form-field> -->
        <div class="inputField">
          <nz-date-picker class="calender-input"  id="startPicker" formControlName="startdate" nzFormat="dd/MM/yyyy HH:mm"
          [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
          <span class="floating-text">Start Date</span>   
        </div>
        <!-- <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <input
            matInput
            formControlName="startdate"
            [ngxMatDatetimePicker]="startPicker"
            placeholder="Start Date" readonly
          />
          <mat-datepicker-toggle 
            matSuffix
            [for]="$any(startPicker)" 
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #startPicker> </ngx-mat-datetime-picker>
        </mat-form-field> -->

        <div class="inputField">
          <nz-date-picker class="calender-input"  id="endPicker" formControlName="enddate" nzFormat="dd/MM/yyyy HH:mm"
          [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
          <span class="floating-text">End Date</span>   
        </div>

        <!-- <mat-form-field
        appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      >
        <input
          matInput
          formControlName="enddate"
          [ngxMatDatetimePicker]="endPicker"
          placeholder="End Date" readonly
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="$any(endPicker)"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #endPicker> </ngx-mat-datetime-picker>
      </mat-form-field> -->
      <button (click)="getAllUsersActions()" class="search-btn" pTooltip="Filter Data" tooltipPosition="top">
        <img src="assets/images/search.png" alt="" width="20px" height="20px">
      </button>
      </div>
      </form>
      <ag-grid-angular
      style="width: 100%; height: 25vw"
       class="ag-theme-alpine"
       [rowData]="rowData"
       [columnDefs]="columnDefs" [gridOptions]="gridOptions"  (cellClicked)="cellClicked($event)"
       (cellValueChanged)="onCellValueChanged($event)"  [icons]="icons" [pagination]="true">
      </ag-grid-angular>

</div>

<ng-template #displayRoleModal2>
  <form [formGroup]="pictureAfter" >
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>Add Picture After and Remarks</h2>
        <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
        (click)="dialogClose()"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
      </div>
    </div>

    <div class="p-grid p-fluid">
      <div class="p-field p-col-3" style="margin-left: 10px;">
        <label>Image</label>
        <input
          pInputText
          formControlName="image"
          accept="image/x-png,image/gif,image/jpeg"
          id="file"
          type="file"
          class="form-control"  (change)="uploadedFile2($event)"/>
      </div>
      
      <div class="p-field p-col-3" style="margin-left: 10px;">
        <label>Remarks</label>
        <input
          pInputText
          formControlName="CompleteRemarks"
          id="completeRem"
          type="text"
          class="form-control"
          (change)="addCompleteRemarks2($event)"/>
      </div>

      <div class="p-field p-col-3" style="margin-left: 10px;">
        <button type="submit" (click)="onFileChange($event)" class="icon-btn" matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-top: 29px;">
          <img src="assets/images/save.png" alt="" width="20px" height="20px">
        </button>
      </div>
    </div>

  </form>

</ng-template>

<ng-template #displayRoleModal3>
  <form [formGroup]="rejectedRemarksForm" >
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>Add Remarks</h2>
        <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
      </div>
    </div>

    <div class="p-grid p-fluid">     
      <div class="p-field p-col-3" style="margin-left: 10px;">
        <label>Remarks</label>
        <input
          pInputText
          formControlName="RejectRemarks"
          id="rejectRem"
          type="text"
          class="form-control"
          (change)="addRejectRemarks2($event)"/>
      </div>

      <div class="p-field p-col-3" style="margin-left: 10px;">
        <button type="submit" (click)="onFileChange2()" class="icon-btn" matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-top: 29px;">
          <img src="assets/images/save.png" alt="" width="20px" height="20px">
        </button>
      </div>
    </div>

  </form>

</ng-template>

<ng-template #imageZoomModel>
  <!-- <img [src]="currentImagePath" style="height: 98%;width: 100%; overflow: hidden;"> -->
  <img  [src]="currentImagePath !== '' ? currentImagePath : 'assets/images/no-image.png' " style="height: 98%;width: 100%; overflow: hidden;" />

</ng-template>

</section>


<ng-template #previewModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Defect Files</h2>
      <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content style="padding: 15px 24px !important;  ">
    <div class="mat-elevation-z8">
      <table mat-table class="preview_table" [dataSource]="previewerData">
        <ng-container matColumnDef="Desc">
          <th mat-header-cell *matHeaderCellDef>
            File Description
          </th>
          <td mat-cell *matCellDef="let element">
            <a (click)="openFile(element)">
              {{ element.FileName }}
           </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> Download
            <!-- {{ langObj ? langObj?.Download : ("Download" | translate) }} -->
          </th>
          <td mat-cell *matCellDef="let element">
            <a href="{{ imageUrl + element.FilePath }}" target="_blank"><i class="fa fa-download"></i></a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="previewerColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: previewerColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3">
            No Data Matching
          </td>
        </tr>
      </table>
      <mat-paginator
        #paginator="matPaginator"
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-dialog-content>
</ng-template>
<p-dialog [header]="fileDesc" [(visible)]="displayMaximizable" [modal]="true" [style]="{width: '90vw'}"
  [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ngx-doc-viewer *ngIf="fileType==='doc'" [url]="doc" viewer="google" style="width:100%;height:90vh;"></ngx-doc-viewer>
  <angular-image-viewer *ngIf="fileType==='img'" [src]="images" [(index)]="imageIndexOne"></angular-image-viewer>
</p-dialog>