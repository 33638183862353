<div class="machine-parameter-content" dir="{{languageDir}}">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a
          class="breadcrumb-link"
          [routerLink]="breadcrumItem.Url"
          [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
          >{{breadcrumItem.text}}</a
        >
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">{{langObj ? langObj?.lossdashboard : ('lossdashboard' | translate)}}</h1>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  <form [formGroup]="gapSearchForm">
    <div
      class="content-top-row"
    >
      <mat-form-field
        appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      >
        <mat-label>{{langObj ? langObj?.group : ('group' | translate)}}</mat-label>
        <mat-select
          panelClass="testClass"
          name="group"
          formControlName="group"
          (selectionChange)="getPlantByGroup()"
        >
        <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groupListDDL"
        (filteredReturn)="filteredGroupListDDL = $event"></mat-select-filter>
          <mat-option *ngFor="let g of filteredGroupListDDL" [value]="g.TypeId">
            {{ g.TypeName }}
          </mat-option>
        </mat-select>
        <mat-error>{{langObj ? langObj?.Pleaseselectgroup : ('Pleaseselectgroup' | translate)}}</mat-error>
      </mat-form-field>
      <mat-form-field
        appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      >
        <mat-label>{{langObj ? langObj?.plant : ('plant' | translate)}}</mat-label>
        <mat-select
          panelClass="testClass"
          name="plant"
          formControlName="plant" (selectionChange)="gettree()"
        >
        <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plants"
        (filteredReturn)="filteredPlants = $event"></mat-select-filter>
          <mat-option *ngFor="let p of filteredPlants" [value]="p.DeptId">
            {{ p.DeptDesc }}
          </mat-option>
        </mat-select>
        <mat-error>{{langObj ? langObj?.Pleaseselectplant : ('Pleaseselectplant' | translate)}}</mat-error>
      </mat-form-field>
      <!-- <div class="inputField">
        <nz-date-picker class="calender-input" id="startPicker" formControlName="dateStart" nzFormat="dd/MM/yyyy HH:mm"
          [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true"></nz-date-picker>
        <span class="floating-text">{{langObj ? langObj?.StartDate : ('StartDate' | translate)}}</span>
      </div>
      <div class="inputField">
        <nz-date-picker class="calender-input" id="endPicker" formControlName="dateEnd" nzFormat="dd/MM/yyyy HH:mm"
          [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true"></nz-date-picker>
        <span class="floating-text">{{langObj ? langObj?.EndDate : ('EndDate' | translate)}}</span>
      </div> -->
      <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label>{{langObj ? langObj?.StartDate : ('StartDate' | translate)}}-{{langObj ? langObj?.EndDate : ('EndDate' | translate)}}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="{{langObj ? langObj?.StartDate : ('StartDate' | translate)}}" formControlName="dateStart">
          <input matEndDate placeholder="End date" formControlName="dateEnd" (dateChange)="gettree()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <!-- <div class="btn-position">
        <button type="submit" class="icon-btn" matTooltipClass="custom-tooltip" matTooltip="Apply Filter"  *ngIf="IsWrite"
          (click)="gettree()" tooltipPosition="top">
          <img src="assets/images/search.png" alt="" width="20px" height="20px">
        </button>
      </div> -->
      <!-- <div class="btn-position">
        <button mat-raised-button color="accent" class="btn-accent oee-button-w" (click)="today()">Today  </button>
      </div>
      <div class="btn-position">
        <button mat-raised-button color="accent" class="btn-accent" (click)="yesterday()">Yesterday</button>
      </div>
      <div class="btn-position">
        <button mat-raised-button color="accent" class="btn-accent" (click)="lastWeek()">Last Week</button>
      </div>
      <div class="btn-position">
        <button mat-raised-button color="accent" class="btn-accent" (click)="lastMonth()">Last Month</button>
      </div> -->
      <!-- <div class="d-flex" style="margin: 10px;">
        <div class="mr-10" style="margin: inherit;">
          <p class="topBtn mx-2" (click)="today();showPhase(1)" [class.phase-active]="activeButton === 1"
            matTooltipClass="custom-tooltip" matTooltip="Today(Current Shift)">T</p>
        </div>
        <div class="mr-10" style="margin: inherit;">
          <p class="topBtn mx-2" (click)="yesterday();showPhase(2)" [class.phase-active]="activeButton === 2"
            matTooltipClass="custom-tooltip" matTooltip="Yesterday(Last Shift)">1D</p>
        </div>
        <div class="mr-10" style="margin: inherit;">
          <p class="topBtn mx-2" (click)="lastWeek();showPhase(3)" [class.phase-active]="activeButton === 3"
            matTooltipClass="custom-tooltip" matTooltip="Last Week">1W</p>
        </div>
        <div class="mr-10" style="margin: inherit;">
          <p class="topBtn mx-2" (click)="lastMonth();showPhase(4)" [class.phase-active]="activeButton === 4"
            matTooltipClass="custom-tooltip" matTooltip="Last Month">1M</p>
        </div>
      </div> -->
    </div>
   
  </form>
  <!-- <div class="chart-group">
      <div class="chart-items">
          <div class="chart-title">Strategic KPI</div>
          <div >
              <mat-chip-list  aria-label="Color selection" >
                  <mat-chip *ngFor="let cat of SKPIList"  color="accent"  [class.active]="cat === activeChipsCat"   [class.inactive]="cat !== activeChipsCat" (click)="activeChipsCat = cat"  (click)="gettree(cat)">
                   {{cat.name}}
                  </mat-chip>
                  </mat-chip-list>
          </div>
            
      </div>
  </div> -->

  <div class="chart-group">
    <div class="chart-items" style="width: 100%;">
        <div class="chart-title">{{langObj ? langObj?.lossanalysis : ('lossanalysis' | translate)}}</div>
          <div [chart]="chartLossAnalysis"></div>
    </div>
</div>
  </div>