import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TreeModule } from 'primeng/tree';
import { DropdownModule } from 'primeng/dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from 'src/app/material-module';
import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AgGridModule } from 'ag-grid-angular';
import { MatSelectFilterModule } from 'mat-select-filter';
import { ChartModule } from 'angular-highcharts';


import { OrpDashboardRoutingModule } from './orp-dashboard-routing.module';
import { MachineOeeAnalysisComponent } from './machine-oee-analysis/machine-oee-analysis.component';
import { OeeAnalysisComponent } from './oee-analysis/oee-analysis.component';
import { HomeModule } from '../screens/home.module';
import { AreaSummaryComponent } from './area-summary/area-summary.component';
import { ProductionComponent } from './production/production.component';
import { QualityComponent } from './quality/quality.component';
import { FaultAnalysisComponent } from './fault-analysis/fault-analysis.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';


@NgModule({
  declarations: [
    OeeAnalysisComponent,
    MachineOeeAnalysisComponent,
    AreaSummaryComponent,
    ProductionComponent,
    QualityComponent,
    FaultAnalysisComponent,
    MaintenanceComponent,
    DashboardHeaderComponent
  ],
  imports: [
    CommonModule,
    OrpDashboardRoutingModule,
    ChartsModule,
    HomeModule,
    NgApexchartsModule,
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    TreeModule,
    DropdownModule,
    NgxSpinnerModule,
    AgGridModule,
    MatSelectFilterModule,
    ChartModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrpDashboardModule { }
