import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoginUser } from 'src/app/models/loginUser';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig],
})
export class NavbarComponent implements OnInit {
  @Input() isMenuOpen!: boolean;
  @Output() mobMenuTogglerEvent = new EventEmitter();
  searchForm!: FormGroup;
  notifcationCount = 0;
  isSarchFormVisible = false;
  userImage!: any;
  user!: LoginUser;
  checkUserAdmin = JSON.parse(localStorage.user).IsAdmin;
  breadcrumList: any;
  urll: any;
  public notifications!: any[];
  public toastrnotifications: any[] = [];
  public totoTemplate =
    '<button style="float:right;" (click)="cleartAlltoaster()">Close All</button>';
  translateForm!: FormGroup;
  public pageSize: number = 5;
  public notificationsLength: any;
  public pagedNotificationList: any;
  public sum = 5;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private toaster: ToastrService,
    private notificationService: NotificationService,
    private userManagementService: UserManagementService,
    public translater: TranslaterService,
    public translate: TranslateService
  ) {
    this.translateForm = this.formBuilder.group({
      languagvalue: [''],
    });
  }
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;

  ngOnInit(): void {
    // this.GetBreadcrumList();
    // this.usernotifications();
    this.searchForm = this.formBuilder.group({
      searchValue: ['', Validators.required],
    });
    this.translateForm.get('languagvalue')?.setValue('en');
    const u = localStorage.getItem('user');
    this.user = u !== null ? JSON.parse(u) : {};
    this.userImage = JSON.parse(localStorage.user).Pic;
  }

  UpdateNotificationStatus(notification: any): void {
    const key = {
      notificationId: notification.notificationId,
    };
    this.notificationService
      .updatenotificationstatus(key)
      .subscribe((res: any) => {
        this.usernotifications();
      });
  }

  closeModal(): void {
    this.dialog.closeAll();
  }

  onSubmit(): void {
    const searchValue = this.searchForm.value;
  }

  mobMenuToggler(): void {
    this.mobMenuTogglerEvent.emit(this.isMenuOpen);
  }

  toggleSearchForm(): void {
    this.isSarchFormVisible = !this.isSarchFormVisible;
  }

  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.clear();
    this.authService.decodedToken = null;
    this.toaster.info('logged out');
    this.router.navigate(['/login']);
  }

  // menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        if (!data.HasError) {
          this.breadcrumList = data.BreadcrumList;
        }
      },
      (error) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        this.toaster.error('Error', error.Message);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  usernotifications(): void {
    const key = {
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.notificationService.usernotifications(key).subscribe((res: any) => {
      this.notifications = res;
      this.notifcationCount = res.length;
    });
  }

  // clear notification
  ClearNotification(): void {
    const key = {
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.notificationService.ClearNotification(key).subscribe((res: any) => {
      this.usernotifications();
    });
  }

  ReturnHome(IdMenu: any): any {
    this.urll = 'landingPage';
    localStorage.setItem('O3RightMenuId', IdMenu);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([this.urll]));
  }
  cleartAlltoaster(): any {
    this.toaster.clear();
    this.toastrnotifications = [];
    this.ClearNotification();
  }
}
