import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { TdcanalysisService } from 'src/app/services/tdcanalysis.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-tdcAnalysis',
  templateUrl: './tdcAnalysis.component.html',
  styleUrls: ['./tdcAnalysis.component.scss'],
})
export class TdcAnalysisComponent implements OnInit {
  TdcAnalysisForm!: FormGroup;
  columnDefs: any;
  rowData: any;
  columnDefs1: any;
  rowData1: any;
  breadcrumList: any;
  groupListDDL!: any[];
  plants!: any;
  dataArray!: [];
  paginationPageSize!: any;
  icons!: any;
  IsAccess:any;
  IsWrite:any;
  currentYear = JSON.stringify(new Date().getFullYear());
  yearList = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  gridOptions: any = {
    paginationPageSize: 10,
  };
  gridApi: any;
  gridColumnApi: any;
  constructor(
    private usermanagementService: UserManagementService,
    public router: Router,
    private toaster: ToastrService,
    private tdcservice: TdcanalysisService,
    private fb: FormBuilder,
    private platModelService: EnterPriseGroupService,
    private spinner: NgxSpinnerService
  ) {
   
  }

  ngOnInit(): any {
    this.GetBreadcrumList();
    this.getGroupData();
    this.TdcAnalysisForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      year: ['', Validators.required],
    });
    this.TdcAnalysisForm.get('year')?.setValue(new Date().getFullYear());
    this.GetMenuAccess()
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
      // localStorage.setItem('GetMenuAccess', this.permisson);
      this.columnDefs = [
        {
          headerName: 'TDC Analysis',
          resizable: true,
          children: [
            {
              headerName: '',
              field: 'tdc',
              cellRenderer: (params: any) => {
                if (params.data && params.data.tdc) {
                  return (
                    '<div class="area-name">' + params.data.tdc + '' + '</div>'
                  );
                } else {
                  return '';
                }
              },
              cellStyle: (params: any) => {
                if (params.data.tdc) {
                  return { background: '#49FFB9' };
                } else {
                  return { background: 'white' };
                }
              },
              colSpan: (params: any) => {
                const tdc = params.data.tdc;
                if (
                  tdc === 'Total Revenue' ||
                  tdc === 'Sold Volume' ||
                  tdc === 'Total Delivered Cost'
                ) {
                  return 2;
                } else {
                  return 1;
                }
              },
              rowSpan(params: any): any {
                if (params.data.tdc) {
                  return params.data.totalcount;
                } else {
                  return 1;
                }
              },
            },
  
            {
              headerName: '',
              field: 'tdc1',
              resizable: true,
            },
          ],
        },
        {
          headerName: 'Budget',
          resizable: true,
          children: [
            {
              headerName: '',
              field: 'budget',
              editable: (params: any) => ((params.data.tdc === 'Total Revenue' || params.data.tdc === 'Sold Volume' ) && this.IsWrite) ? true : false ,
              resizable: true,
            },
          ],
        },
        {
          headerName: 'Actual',
          resizable: true,
          children: [
            {
              headerName: '',
              field: 'actual',
              editable: (params: any) => ((params.data.tdc === 'Total Revenue' || params.data.tdc === 'Sold Volume') && this.IsWrite) ? true : false,
              resizable: true,
            },
          ],
        },
        {
          headerName: 'Budget Cost vs Budget Volume',
          resizable: true,
          children: [
            {
              headerName: 'Cost/Unit',
              field: 'costunit1',
              resizable: true,
            },
          ],
        },
        {
          headerName: 'Budgt Cost vs Actual Volume',
          resizable: true,
          children: [
            {
              headerName: 'Cost/Unit',
              field: 'costunit2',
              resizable: true,
            },
          ],
        },
        {
          headerName: 'Actual Cost vs Actual Volume',
          resizable: true,
          children: [
            {
              headerName: 'Cost/Unit',
              field: 'costunit3',
              resizable: true,
            },
          ],
        },
        {
          headerName: 'P & L',
          resizable: true,
          children: [
            {
              headerName: 'vs Actual Volume',
              field: 'actualvolume',
              resizable: true,
            },
          ],
        },
      ];
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getCalculations(costElementTempArray: any): any {
    this.spinner.show();
    const totalVolBudget = parseFloat(costElementTempArray.find((val: any) => val.tdc === 'Sold Volume').budget);
    const totalVolActual = parseFloat(costElementTempArray.find((val: any) => val.tdc === 'Sold Volume').actual);
    const totalRevBudget = parseFloat(costElementTempArray.find((val: any) => val.tdc === 'Total Revenue').budget);
    const totalRevActual = parseFloat(costElementTempArray.find((val: any) => val.tdc === 'Total Revenue').actual);
    let totalDeliveredBudget = 0;
    let totalActual = 0;
    let costunit1 = 0;
    let costunit2 = 0;
    let costunit3 = 0;
    let actualvolume = 0;
    let tempUnit1Cost = 0;
    let tempUnit2Cost = 0;
    let tempUnit3Cost = 0;
    for (const val of costElementTempArray) {
      if (val.tdc !== 'Total Revenue' && val.tdc !== 'Sold Volume' && val.tdc !== 'Total Delivered Cost') {
        const index = costElementTempArray.findIndex((value: any) => value.tdc === val.tdc);
        tempUnit1Cost = val.budget / totalVolBudget;
        tempUnit2Cost = val.budget / totalVolActual;
        tempUnit3Cost = val.actual / totalVolActual;

        // setting costunit values values for [val] obj
        costElementTempArray[index].costunit1 = isNaN(tempUnit1Cost) ||
          tempUnit1Cost === undefined ? 0 : tempUnit1Cost.toFixed(2);
        costElementTempArray[index].costunit2 = isNaN(tempUnit2Cost) ||
          tempUnit2Cost === undefined ? 0 : tempUnit2Cost.toFixed(2);
        costElementTempArray[index].costunit3 = isNaN(tempUnit3Cost) ||
          tempUnit3Cost === undefined ? 0 : tempUnit3Cost.toFixed(2);
        // this.rowData = costElementTempArray;
        this.gridApi.setRowData(costElementTempArray);

        // Calculaiting Sum for TDC
        totalDeliveredBudget = totalDeliveredBudget + val.budget;
        totalActual = totalActual + val.actual;
        costunit1 = costunit1 + tempUnit1Cost;
        costunit2 = costunit2 + tempUnit2Cost;
        costunit3 = costunit3 + tempUnit3Cost;
        actualvolume = actualvolume + val.actualvolume;
      } else if (val.tdc !== 'Total Delivered Cost') {
        const PL = val.tdc === 'Total Revenue' ? totalRevBudget - totalRevActual : totalVolBudget - totalVolActual;
        const index = costElementTempArray.findIndex((value: any) => value.tdc === val.tdc);
        costElementTempArray[index].actualvolume = isNaN(PL) ? 0 : PL ?? 0;
        this.gridApi.setRowData(costElementTempArray);
      }
    }

    // finding the TDC index and setting all the sum values
    const objIndex = costElementTempArray.findIndex((val: any) => val.tdc === 'Total Delivered Cost');
    costElementTempArray[objIndex].budget = isNaN(totalDeliveredBudget) ? 0 : totalDeliveredBudget;
    costElementTempArray[objIndex].actual = isNaN(totalActual) ? 0 : totalActual;
    costElementTempArray[objIndex].costunit1 = isNaN(costunit1) ? 0 : costunit1.toFixed(2);
    costElementTempArray[objIndex].costunit2 = isNaN(costunit2) ? 0 : costunit2.toFixed(2);
    costElementTempArray[objIndex].costunit3 = isNaN(costunit3) ? 0 : costunit3.toFixed(2);
    costElementTempArray[objIndex].actualvolume = isNaN(actualvolume) ? 0 : actualvolume;
    // this.rowData = costElementTempArray;
    this.gridApi.setRowData(costElementTempArray);
    this.spinner.hide();
  }
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.TdcAnalysisForm.get('group')?.setValue(
          this.groupListDDL[0].TypeId
        );
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  filterTdcAnalysis(): void {
    const key = {
      plantId: this.TdcAnalysisForm.get('plant')?.value,
      year: this.TdcAnalysisForm.get('year')?.value,
    };

    this.getCostCenterElementData();
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.TdcAnalysisForm.get('group')?.value,
    };

    // this.MachineId = null;
    this.usermanagementService.getDepartments(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.TdcAnalysisForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getCostCenterElementData();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getCostCenterElementData(): void {
    this.spinner.show();
    this.rowData = [];
    const key = {
      plantId: this.TdcAnalysisForm.get('plant')?.value,
      year: this.TdcAnalysisForm.get('year')?.value,
    };
    this.tdcservice.getCostCenterElementData(key).subscribe(
      (dataArray: any) => {
        debugger;
        const costElementTempArray: any[] = [];
        dataArray.forEach((element: any) => {
          const costElement =
            element?.costElement === null ? [{}] : element?.costElement;
          let tempindex = 0;
          if (costElement) {
            for (const item of costElement) {
              debugger;
              const tempelement = {
                tdc: element?.parentName,
                tdc1: element?.CostElementName,
                budget: item?.Budget,
                actual: item?.Actual,
                costunit1: item?.BCostVSBVolume,
                costunit2: item?.BCostVSAVolume,
                costunit3: item?.ACostVSAVolume,
                actualvolume: item?.PL,
                totalcount: costElement?.length,
              };
              if (
                costElement.length === 1 &&
                (tempelement.tdc === 'Sold Volume' ||
                  tempelement.tdc === 'Total Revenue')
              ) {
                delete tempelement.tdc1;
                costElementTempArray.push(tempelement);
              } else if (tempelement.tdc === 'Total Delivered Cost') {
                costElementTempArray.push(tempelement);
              } else {
                tempelement.tdc1 = item?.CostElementName;
                tempelement.costunit1 = item?.BudgetCostVSBudgetVolume;
                tempelement.costunit2 = item?.BudgetCostVSActualVolume;
                tempelement.costunit3 = item?.ActualCostVSActualVolume;
                switch (tempindex) {
                  case 0:
                    costElementTempArray.push(tempelement);
                    tempindex = 1;
                    break;
                  case 1:
                    delete tempelement.tdc;
                    costElementTempArray.push(tempelement);
                    break;
                }
              }
            }
          }
        });
        this.rowData = costElementTempArray;
        this.getCalculations(this.rowData);
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        console.log(data.BreadcrumList);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  onCellValueChanged(params: any): void {
    const objIndex = this.rowData.findIndex((val: any) => val.tdc === params.data.tdc);
    this.rowData[objIndex].budget = isNaN(Number(params.data.budget)) ? 0 : Number(params.data.budget);
    this.rowData[objIndex].actual = isNaN(Number(params.data.actual)) ? 0 : Number(params.data.actual);
    this.getCalculations(this.rowData);
  }
}
