<div class="production-db-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a
            class="breadcrumb-link"
            [routerLink]="breadcrumItem.Url"
            [id]="breadcrumItem.id"
            (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
            >{{breadcrumItem.text}}</a
          >
        </li>
      </ul>
    </ul>
    <div class="title-row-flex-both-end">
      <h1 class="main-title">Main KPI</h1>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    template=" <img src='assets/images/spinner.gif' />"
  ></ngx-spinner>
  <form [formGroup]="HeaderForm" (ngSubmit)="getKPIMastersData()">
    <div class="content-top-row content-top-row-order-processing">
      <!-- GROUP -->
      <mat-form-field
        appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      >
        <mat-select
          formControlName="Group"
          [(ngModel)]="HeaderData.Group"
          placeholder="Group"
          panelClass="testClass"
          (selectionChange)="getDeptData()"
          name="Status"
        >
          <mat-select-filter
            [placeholder]="'Search'"
            [displayMember]="'EG_Desc'"
            [array]="GroupList"
            (filteredReturn)="filteredGroups = $event"
          ></mat-select-filter>
          <mat-option
            *ngFor="let group of filteredGroups"
            [value]="group.EG_Id"
          >
            {{ group.EG_Desc }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Plant -->
      <mat-form-field
        appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      >
        <mat-label>Plant</mat-label>
        <mat-select
          [(ngModel)]="HeaderData.Plant"
          formControlName="plant"
          (selectionChange)="getTreeMasterData()"
        >
          <mat-select-filter
            [placeholder]="'Search Plant'"
            [displayMember]="'DeptDesc'"
            [array]="plantList"
            (filteredReturn)="plantTempList = $event"
          >
          </mat-select-filter>

          <mat-option
            *ngFor="let plant of plantTempList"
            [value]="plant.DeptId"
          >
            {{ plant.DeptDesc }}
          </mat-option>
        </mat-select>
        <mat-error>Plant is Required</mat-error>
      </mat-form-field>
      <mat-form-field
        appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      >
        <mat-label>Line</mat-label>
        <mat-select
          formControlName="line"
          [(ngModel)]="HeaderData.Line"
          name="Line"
        >
          <mat-select-filter
            [placeholder]="'Search Line'"
            [displayMember]="'PL_Desc'"
            [array]="LineList"
            (filteredReturn)="LineFilterList = $event"
          >
          </mat-select-filter>
          <mat-option value="-1">
            ALL
          </mat-option>
          <mat-option *ngFor="let line of LineFilterList" [value]="line.PL_Id">
            {{ line.PL_Desc }}
          </mat-option>
        </mat-select>
        <mat-error>Line is Required</mat-error>
      </mat-form-field>

      <div class="inputField">
        <nz-date-picker
          style="margin: 0 20px 0 0 !important"
          readonly
          nzFormat="dd-MMM-yyyy HH:mm"
          [nzShowTime]="{ nzFormat: 'HH:mm' }"
          [nzMin]="minDate"
          [nzMax]="maxDate"
          [(ngModel)]="HeaderData.StartDate"
          formControlName="StartDate"
        ></nz-date-picker>
        <span class="floating-text">Start Date</span>
      </div>

      <div class="inputField">
        <nz-date-picker
          readonly
          [nzShowTime]="true"
          nzFormat="dd-MMM-yyyy HH:mm"
          [nzShowTime]="{ nzFormat: 'HH:mm' }"
          [nzMin]="minDate"
          [nzMax]="maxDate"
          [(ngModel)]="HeaderData.EndDate"
          formControlName="EndDate"
        ></nz-date-picker>
        <span class="floating-text">End Date</span>
      </div>
      <button type="submit" class="search" tooltipPosition="top">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3609 18.2168L14.6008 13.2662C15.8247 11.8113 16.4953 9.98069 16.4953 8.07499C16.4953 3.62251 12.8728 0 8.4203 0C3.96782 0 0.345306 3.62251 0.345306 8.07499C0.345306 12.5275 3.96782 16.15 8.4203 16.15C10.0918 16.15 11.6847 15.6458 13.0466 14.6888L17.8428 19.677C18.0432 19.8852 18.3129 20 18.6018 20C18.8753 20 19.1348 19.8957 19.3317 19.7061C19.7502 19.3034 19.7636 18.6357 19.3609 18.2168ZM8.4203 2.10652C11.7114 2.10652 14.3888 4.78391 14.3888 8.07499C14.3888 11.3661 11.7114 14.0435 8.4203 14.0435C5.12921 14.0435 2.45183 11.3661 2.45183 8.07499C2.45183 4.78391 5.12921 2.10652 8.4203 2.10652Z"
            fill="#10123D"
          />
        </svg>
      </button>
    </div>
  </form>
  <!-- Card -->
  <div class="menu-card-group">
    <div
      class="menu-card-item-four mat-card-height minor"
      (click)="openMajorBD()"
    >
      <div>
        <svg
          class="svg"
          width="62"
          height="68"
          viewBox="0 0 62 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
            fill="#10ce9c"
          />
        </svg>
      </div>
      <div class="svg-icon">
        <svg
          _ngcontent-cii-c74=""
          width="25"
          height="30"
          viewBox="0 1 21 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            _ngcontent-cii-c74=""
            d="M14.9684 11.344H15.9346C15.6507 8.65845 13.6403 6.52064 11.1149 6.21875V7.24618H9.88502V6.21875C7.35956 6.52064 5.3492 8.65845 5.06531 11.344H6.03148V12.6519H5.06531C5.3492 15.3374 7.35956 17.4752 9.88502 17.7771V16.7497H11.1149V17.7771C13.6403 17.4752 15.6507 15.3374 15.9346 12.6519H14.9684V11.344ZM11.807 14.3126L9.88502 12.2688V8.55409H11.1149V11.7271L12.6766 13.3879L11.807 14.3126Z"
            fill="white"
          ></path>
          <path
            _ngcontent-cii-c74=""
            d="M21 14.5853V9.41014H19.4573C19.2508 8.59864 18.9483 7.82249 18.554 7.0925L19.6453 5.93211L16.204 2.27269L15.1128 3.43312C14.4263 3.01379 13.6964 2.69215 12.9333 2.47257V0.832031H8.06665V2.47252C7.30357 2.69211 6.57369 3.01379 5.88719 3.43308L4.79598 2.27264L1.35473 5.93211L2.44597 7.0925C2.05168 7.82249 1.74922 8.59864 1.54273 9.41014H0V14.5853H1.54273C1.74922 15.3969 2.05164 16.1729 2.44597 16.903L1.35473 18.0634L4.79598 21.7228L5.88719 20.5624C6.57369 20.9817 7.30357 21.3034 8.06665 21.523V23.1634H12.9333V21.523C13.6964 21.3033 14.4263 20.9817 15.1128 20.5624L16.204 21.7228L19.6452 18.0634L18.554 16.903C18.9483 16.173 19.2507 15.397 19.4572 14.5853H21ZM10.5 19.1217C6.80601 19.1217 3.80074 15.9259 3.80074 11.9977C3.80074 8.06954 6.80601 4.87373 10.5 4.87373C14.194 4.87373 17.1993 8.06954 17.1993 11.9977C17.1993 15.9259 14.194 19.1217 10.5 19.1217Z"
            fill="white"
          ></path>
        </svg>
      </div>
      <p class="title-text">Total BD</p>
      <p class="data-text primary-color" style="color: #10ce9c">
        {{ MajorBDValues }}
        <span class="data-text small-data-text primary-color"></span>
      </p>
    </div>
    <div
      class="menu-card-item-four mat-card-height moderate"
      (click)="openMTTR()"
    >
      <div>
        <svg
          class="svg"
          width="62"
          height="68"
          viewBox="0 0 62 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
            fill="#ffb326"
          />
        </svg>
      </div>
      <div class="svg-icon">
        <svg
          _ngcontent-cii-c74=""
          width="25"
          height="30"
          viewBox="0 1 21 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            _ngcontent-cii-c74=""
            d="M14.9684 11.344H15.9346C15.6507 8.65845 13.6403 6.52064 11.1149 6.21875V7.24618H9.88502V6.21875C7.35956 6.52064 5.3492 8.65845 5.06531 11.344H6.03148V12.6519H5.06531C5.3492 15.3374 7.35956 17.4752 9.88502 17.7771V16.7497H11.1149V17.7771C13.6403 17.4752 15.6507 15.3374 15.9346 12.6519H14.9684V11.344ZM11.807 14.3126L9.88502 12.2688V8.55409H11.1149V11.7271L12.6766 13.3879L11.807 14.3126Z"
            fill="white"
          ></path>
          <path
            _ngcontent-cii-c74=""
            d="M21 14.5853V9.41014H19.4573C19.2508 8.59864 18.9483 7.82249 18.554 7.0925L19.6453 5.93211L16.204 2.27269L15.1128 3.43312C14.4263 3.01379 13.6964 2.69215 12.9333 2.47257V0.832031H8.06665V2.47252C7.30357 2.69211 6.57369 3.01379 5.88719 3.43308L4.79598 2.27264L1.35473 5.93211L2.44597 7.0925C2.05168 7.82249 1.74922 8.59864 1.54273 9.41014H0V14.5853H1.54273C1.74922 15.3969 2.05164 16.1729 2.44597 16.903L1.35473 18.0634L4.79598 21.7228L5.88719 20.5624C6.57369 20.9817 7.30357 21.3034 8.06665 21.523V23.1634H12.9333V21.523C13.6964 21.3033 14.4263 20.9817 15.1128 20.5624L16.204 21.7228L19.6452 18.0634L18.554 16.903C18.9483 16.173 19.2507 15.397 19.4572 14.5853H21ZM10.5 19.1217C6.80601 19.1217 3.80074 15.9259 3.80074 11.9977C3.80074 8.06954 6.80601 4.87373 10.5 4.87373C14.194 4.87373 17.1993 8.06954 17.1993 11.9977C17.1993 15.9259 14.194 19.1217 10.5 19.1217Z"
            fill="white"
          ></path>
        </svg>
      </div>
      <p class="title-text">MTTR</p>
      <p class="data-text primary-color" style="color: #ffb326">
        {{ MTTRValues }} m
        <span class="data-text small-data-text primary-color"></span>
      </p>
    </div>
    <div class="menu-card-item-four mat-card-height major" (click)="openMTBF()">
      <div>
        <svg
          class="svg"
          width="62"
          height="68"
          viewBox="0 0 62 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
            fill="#f85439"
          />
        </svg>
      </div>
      <div class="svg-icon">
        <svg
          _ngcontent-gmr-c74=""
          width="23"
          height="23"
          viewBox="0 1 20 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            _ngcontent-gmr-c74=""
            d="M9.62076 9.73145H5.63977V22.7084H9.62076V9.73145Z"
            fill="white"
          ></path>
          <path
            _ngcontent-gmr-c74=""
            d="M15.2603 13.248H11.2793V22.709H15.2603V13.248Z"
            fill="white"
          ></path>
          <path
            _ngcontent-gmr-c74=""
            d="M20.8999 7.31445H16.9189V22.7085H20.8999V7.31445Z"
            fill="white"
          ></path>
          <path
            _ngcontent-gmr-c74=""
            d="M3.98099 14.6279H0V22.7087H3.98099V14.6279Z"
            fill="white"
          ></path>
          <path
            _ngcontent-gmr-c74=""
            d="M1.99067 11.4525C2.90656 11.4525 3.6492 10.6627 3.6492 9.68927C3.6492 9.45838 3.60462 9.23909 3.52868 9.03684L6.72058 6.26563C6.98206 6.44812 7.29393 6.55628 7.63019 6.55628C8.05702 6.55628 8.44227 6.37996 8.73627 6.09845L11.6565 7.9251C11.6286 8.05252 11.6112 8.18365 11.6112 8.32021C11.6112 9.29441 12.3536 10.0841 13.2699 10.0841C14.1863 10.0841 14.9289 9.29466 14.9289 8.32021C14.9289 8.02931 14.8563 7.75915 14.739 7.51715L18.142 3.93002C18.3726 4.05917 18.631 4.13918 18.9099 4.13918C19.826 4.13918 20.5687 3.34945 20.5687 2.376C20.5687 1.4018 19.8263 0.611328 18.9099 0.611328C17.9936 0.611328 17.2512 1.4018 17.2512 2.376C17.2512 2.66665 17.3238 2.93706 17.4409 3.17906L14.0377 6.76545C13.8071 6.63629 13.5486 6.55628 13.2697 6.55628C12.8427 6.55628 12.4579 6.7326 12.1636 7.01412L9.24344 5.18771C9.27107 5.06004 9.28895 4.92891 9.28895 4.79235C9.28895 3.81816 8.54631 3.02843 7.63042 3.02843C6.71431 3.02843 5.97143 3.81816 5.97143 4.79235C5.97143 5.02349 6.01601 5.24254 6.09172 5.44478L2.90052 8.21575C2.63904 8.03277 2.32693 7.9246 1.99091 7.9246C1.07479 7.9246 0.331909 8.71483 0.331909 9.68927C0.331909 10.6627 1.07456 11.4525 1.99067 11.4525Z"
            fill="white"
          ></path>
        </svg>
      </div>
      <p class="title-text">MTBF</p>
      <p class="data-text primary-color" style="color: #f85439">
        {{ MTBFValues }} m
        <span class="data-text small-data-text primary-color"></span>
      </p>
    </div>
    <div
      class="menu-card-item-four mat-card-height major"
      (click)="openMinorStops()"
    >
      <div>
        <svg
          class="svg"
          width="62"
          height="68"
          viewBox="0 0 62 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
            fill="#659525"
          />
        </svg>
      </div>
      <div class="svg-icon">
        <svg
          _ngcontent-cii-c74=""
          width="25"
          height="30"
          viewBox="0 1 21 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            _ngcontent-cii-c74=""
            d="M14.9684 11.344H15.9346C15.6507 8.65845 13.6403 6.52064 11.1149 6.21875V7.24618H9.88502V6.21875C7.35956 6.52064 5.3492 8.65845 5.06531 11.344H6.03148V12.6519H5.06531C5.3492 15.3374 7.35956 17.4752 9.88502 17.7771V16.7497H11.1149V17.7771C13.6403 17.4752 15.6507 15.3374 15.9346 12.6519H14.9684V11.344ZM11.807 14.3126L9.88502 12.2688V8.55409H11.1149V11.7271L12.6766 13.3879L11.807 14.3126Z"
            fill="white"
          ></path>
          <path
            _ngcontent-cii-c74=""
            d="M21 14.5853V9.41014H19.4573C19.2508 8.59864 18.9483 7.82249 18.554 7.0925L19.6453 5.93211L16.204 2.27269L15.1128 3.43312C14.4263 3.01379 13.6964 2.69215 12.9333 2.47257V0.832031H8.06665V2.47252C7.30357 2.69211 6.57369 3.01379 5.88719 3.43308L4.79598 2.27264L1.35473 5.93211L2.44597 7.0925C2.05168 7.82249 1.74922 8.59864 1.54273 9.41014H0V14.5853H1.54273C1.74922 15.3969 2.05164 16.1729 2.44597 16.903L1.35473 18.0634L4.79598 21.7228L5.88719 20.5624C6.57369 20.9817 7.30357 21.3034 8.06665 21.523V23.1634H12.9333V21.523C13.6964 21.3033 14.4263 20.9817 15.1128 20.5624L16.204 21.7228L19.6452 18.0634L18.554 16.903C18.9483 16.173 19.2507 15.397 19.4572 14.5853H21ZM10.5 19.1217C6.80601 19.1217 3.80074 15.9259 3.80074 11.9977C3.80074 8.06954 6.80601 4.87373 10.5 4.87373C14.194 4.87373 17.1993 8.06954 17.1993 11.9977C17.1993 15.9259 14.194 19.1217 10.5 19.1217Z"
            fill="white"
          ></path>
        </svg>
      </div>
      <p class="title-text">Minor Stops</p>
      <p class="data-text primary-color" style="color: #f85439">
        {{ minorStopsValues }}
        <span class="data-text small-data-text primary-color"></span>
      </p>
    </div>
    <div
      class="menu-card-item-four mat-card-height major"
      (click)="openRepeatedBD()"
    >
      <div>
        <svg
          class="svg"
          width="62"
          height="68"
          viewBox="0 0 62 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
            fill="#c5541f"
          />
        </svg>
      </div>
      <div class="svg-icon">
        <svg
          _ngcontent-cii-c74=""
          width="25"
          height="30"
          viewBox="0 1 21 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            _ngcontent-cii-c74=""
            d="M14.9684 11.344H15.9346C15.6507 8.65845 13.6403 6.52064 11.1149 6.21875V7.24618H9.88502V6.21875C7.35956 6.52064 5.3492 8.65845 5.06531 11.344H6.03148V12.6519H5.06531C5.3492 15.3374 7.35956 17.4752 9.88502 17.7771V16.7497H11.1149V17.7771C13.6403 17.4752 15.6507 15.3374 15.9346 12.6519H14.9684V11.344ZM11.807 14.3126L9.88502 12.2688V8.55409H11.1149V11.7271L12.6766 13.3879L11.807 14.3126Z"
            fill="white"
          ></path>
          <path
            _ngcontent-cii-c74=""
            d="M21 14.5853V9.41014H19.4573C19.2508 8.59864 18.9483 7.82249 18.554 7.0925L19.6453 5.93211L16.204 2.27269L15.1128 3.43312C14.4263 3.01379 13.6964 2.69215 12.9333 2.47257V0.832031H8.06665V2.47252C7.30357 2.69211 6.57369 3.01379 5.88719 3.43308L4.79598 2.27264L1.35473 5.93211L2.44597 7.0925C2.05168 7.82249 1.74922 8.59864 1.54273 9.41014H0V14.5853H1.54273C1.74922 15.3969 2.05164 16.1729 2.44597 16.903L1.35473 18.0634L4.79598 21.7228L5.88719 20.5624C6.57369 20.9817 7.30357 21.3034 8.06665 21.523V23.1634H12.9333V21.523C13.6964 21.3033 14.4263 20.9817 15.1128 20.5624L16.204 21.7228L19.6452 18.0634L18.554 16.903C18.9483 16.173 19.2507 15.397 19.4572 14.5853H21ZM10.5 19.1217C6.80601 19.1217 3.80074 15.9259 3.80074 11.9977C3.80074 8.06954 6.80601 4.87373 10.5 4.87373C14.194 4.87373 17.1993 8.06954 17.1993 11.9977C17.1993 15.9259 14.194 19.1217 10.5 19.1217Z"
            fill="white"
          ></path>
        </svg>
      </div>
      <p class="title-text">Repeated BD</p>
      <p class="data-text primary-color" style="color: #f85439">
        {{ repeatedBDValues }}
        <span class="data-text small-data-text primary-color"></span>
      </p>
    </div>
  </div>

  <section>
    <div class="example-button-row">
      <button
        type="button"
        class="btn btn-success"
        (click)="csvExport()"
        style="margin-top: 30px"
      >
        <img src="assets/images/csv-file-format.png" />
      </button>
      <button
        type="button"
        class="btn btn-success"
        (click)="excelExport()"
        style="margin-top: 30px; margin-left: 10px"
      >
        <i class="fa fa-file-excel-o icon-white" aria-hidden="true"></i>
      </button>
    </div>
  </section>
  <mat-card-content>
    <ag-grid-angular
      style="width: 100%; height: 24vw"
      class="ag-theme-alpine"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      (gridReady)="onGridReady($event)"
      [gridOptions]="gridOptions"
      [pagination]="true"
      [paginationPageSize]="10"
    >
    </ag-grid-angular>
  </mat-card-content>
</div>

<!-- MTTR -Start -->
<ng-template #MTTR>
  <mat-dialog-content>
    <div style="display: flex; flex-wrap: wrap">
      <div [chart]="mttr" style="width: 30%"></div>
      <div [chart]="mttrMonthly" style="width: 70%"></div>
      <div [chart]="mttrCategory" style="width: 30%"></div>
      <div [chart]="mttrTechinicianLevel" style="width: 70%"></div>
    </div>
  </mat-dialog-content>
</ng-template>
<!-- MInor Stops Starts -->
<ng-template #MinorStops>
  <mat-dialog-content>
    <div style="display: flex; flex-wrap: wrap">
      <div [chart]="minorStopsMonthlyChart" style="width: 50%"></div>
      <div [chart]="minorStopsCategoryChart" style="width: 50%"></div>
    </div>
  </mat-dialog-content>
</ng-template>
<!-- MTBF Starts -->
<ng-template #MTBF>
  <mat-dialog-content>
    <div style="display: flex; flex-wrap: wrap">
      <div [chart]="mtbf" style="width: 30%"></div>
      <div [chart]="mtbfMonthly" style="width: 70%"></div>
      <div [chart]="mtbfCategory" style="width: 30%"></div>
      <div [chart]="mtbfMachineLevel" style="width: 70%"></div>
    </div>
  </mat-dialog-content>
</ng-template>
<!-- Major BD Starts  -->
<ng-template #MajorBD>
  <mat-dialog-content>
    <div style="display: flex; flex-wrap: wrap">
      <div [chart]="totalBreakdowns" style="width: 35%"></div>
      <div [chart]="bdCategory" style="width: 30%"></div>
      <div [chart]="majorBDMachineLevel" style="width: 35%"></div>
      <div [chart]="majorBDSubAssemblyLevel" style="width: 50%"></div>
      <div [chart]="totalBDComponentLevel" style="width: 50%"></div>
    </div>
  </mat-dialog-content>
</ng-template>
<ng-template #RepeatedBD>
  <mat-dialog-content>
    <div style="display: flex; flex-wrap: wrap">
      <div [chart]="repeatedBDChart" style="width: 100%"></div>
    </div>
  </mat-dialog-content>
</ng-template>
<ng-template>
  <mat-dialog-content>
    <div style="display: flex; flex-wrap: wrap"></div>
  </mat-dialog-content>
</ng-template>
