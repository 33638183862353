import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Department } from 'src/app/models/department';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import { UserManu } from 'src/app/models/userManu';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],
})
export class DepartmentsComponent implements OnInit {
  @Input() dpPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<Department>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  plantId!: number;
  departments!: Department[];
  plants!: Plantbygroup[];
  formMode!: boolean;
  departmentForm!: FormGroup;
  departmentId!: number;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;

  departmentModelVisible = false;

  departmentColumns: string[] = ['plant', 'name', 'departmentActions'];
  departmentData!: MatTableDataSource<Department>;
  IsAccess: any;
  IsWrite: any;
  constructor(
    private masterService: KpimasterService,
    private spinner: NgxSpinnerService,
    private platModelService: EnterPriseGroupService,
    private toaster: ToastrService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.GetMenuAccess();

    this.isRead = this.dpPermissions.IsAccess;
    this.isWrite = this.dpPermissions.IsWrite;
    this.isUpdate = this.dpPermissions.IsWrite;
    this.isDelete = this.dpPermissions.IsWrite;
    this.formMode = true;
    this.departmentForm = this.fb.group({
      departmentname: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      remarks: [''],
      plant: ['', Validators.required],
    });
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getPaginator(): void {
    setTimeout(() => {
      this.departmentData.paginator = this.paginator;
      this.departmentData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.departmentData.filter = filterValue.trim().toLowerCase();

    if (this.departmentData.paginator) {
      this.departmentData.paginator.firstPage();
    }
  }
  getPlants(): void {
    this.masterService.getAllPlants().subscribe(
      (plants) => {
        this.plants = plants;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  openModal(): void {
    if (this.isWrite) {
      this.resetForm();
      this.formMode = true;
      this.departmentForm.get('plant')?.setValue(this.plantId);
      this.departmentModelVisible = true;
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  getMasterDepartments(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.masterService.getMasterDepartments(key).subscribe(
      (response) => {
        this.departmentData = new MatTableDataSource<Department>(response);
        this.getPaginator();
        this.getPlants();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  getDepartments(): void {
    this.spinner.show();

    const key = {
      plantId: this.plantId,
    };
    this.masterService.getMasterDepartments(key).subscribe(
      (response) => {
        this.spinner.hide();

        this.departmentData = new MatTableDataSource<Department>(response);
        this.getPaginator();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }

  resetForm(): void {
    this.departmentForm.reset();
  }
  closeDepartmentModel(): void {
    this.departmentModelVisible = false;
  }
}
