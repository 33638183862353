<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center">
    <mat-card-title class="p-ml-2">{{langObj ? langObj?.KPIMasterWeight : (' KPIMasterWeight' | translate)}}</mat-card-title>
    <div class="" *ngIf="MasterWeightData !== undefined">
      <mat-form-field>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="{{langObj ? langObj?.Search : (' Search' | translate)}}"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
    <div class="" *ngIf="MasterWeightData !== undefined">
      <button
                mat-icon-button   
                aria-label="Edit Row"
                (click)="openModal1()"
                pTooltip="View Weight Detail"
                tooltipPosition="top"
              >
                <svg
                class="svg-icon"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                <path d="M10,6.978c-1.666,0-3.022,1.356-3.022,3.022S8.334,13.022,10,13.022s3.022-1.356,3.022-3.022S11.666,6.978,10,6.978M10,12.267c-1.25,0-2.267-1.017-2.267-2.267c0-1.25,1.016-2.267,2.267-2.267c1.251,0,2.267,1.016,2.267,2.267C12.267,11.25,11.251,12.267,10,12.267 M18.391,9.733l-1.624-1.639C14.966,6.279,12.563,5.278,10,5.278S5.034,6.279,3.234,8.094L1.609,9.733c-0.146,0.147-0.146,0.386,0,0.533l1.625,1.639c1.8,1.815,4.203,2.816,6.766,2.816s4.966-1.001,6.767-2.816l1.624-1.639C18.536,10.119,18.536,9.881,18.391,9.733 M16.229,11.373c-1.656,1.672-3.868,2.594-6.229,2.594s-4.573-0.922-6.23-2.594L2.41,10l1.36-1.374C5.427,6.955,7.639,6.033,10,6.033s4.573,0.922,6.229,2.593L17.59,10L16.229,11.373z"  fill="blue" />
                </svg>
              </button>
    </div>
    <div class="" *ngIf="MasterWeightData !== undefined">
      <button *ngIf="IsWrite" (click)="openModal()" class="icon-btn" pTooltip="Add Kpi Master Weight" tooltipPosition="top">
        <img src="assets/images/add.png" alt="" width="20px" height="20px">
      </button>
      <!-- <button
        mat-flat-button
        *ngIf="IsWrite"
        class="btn-add-item"
        (click)="openModal()"
        pTooltip="Add Kpi Master Weight"
        tooltipPosition="top"
      >
        <span class="btn-icon">
          <svg
            width="40"
            height="36"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.18143 5.4235H5.6525V8.9235H4.47619V5.4235H0.947266V4.25684H4.47619V0.756836H5.6525V4.25684H9.18143V5.4235Z"
              fill="white"
            />
          </svg>
        </span>
      </button> -->
    </div>
  </div>
  <mat-card-content>
    <ngx-spinner
      [fullScreen]="false"
      bdColor="#fff"
      size="medium"
      color="#050a4f"
      type="ball-clip-rotate-multiple"
    >
      <p style="font-size: 20px; color: #050a4f">{{langObj ? langObj?.FetchingKpiMasterData : (' FetchingKpiMasterData' | translate)}}</p>
    </ngx-spinner>
    <div
      class="wrapper-box wrapper-box-shadow table-wrapper p-p-1"
      *ngIf="MasterWeightData !== undefined"
    >
      <table
        mat-table
        [dataSource]="MasterWeightData"
        class="basic-table plant-model-table"
        matSort
      >
        <ng-container matColumnDef="Plant">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.Plant : (' Plant' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.plant }}</td>
        </ng-container>
        <ng-container matColumnDef="Frequency">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.Frequency : (' Frequency' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.frequency }}</td>
        </ng-container>
        <ng-container matColumnDef="KPI">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.KPI : (' KPI' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.kpi }}</td>
        </ng-container>
        <ng-container matColumnDef="Weight">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.Weight : ('Weight' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
        </ng-container>
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-text">{{langObj ? langObj?.Actions : (' Actions' | translate)}}</p>
          
          </th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <button
                mat-icon-button
                class="edit-btn"
                *ngIf="IsWrite"
                aria-label="Edit Row"
                (click)="getOneKpiMaster(element.Id)"
                pTooltip="Edit KPI Master Weight"
                tooltipPosition="top"
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white"
                  />
                </svg>
              </button>
              <button
                mat-icon-button
                class="node-control-btn node-delete"
                aria-label="delete item"
                *ngIf="IsWrite"
                (click)="deleteKpiMaster(element.Id)"
                pTooltip="Delete KPI Master Weight"
                tooltipPosition="top"
              >
                <svg
                  width="8"
                  height="10"
                  viewBox="0 0 8 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="MasterWeightColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: MasterWeightColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{langObj ? langObj?.Nodatamatching : (' Nodatamatching' | translate)}}</td>
        </tr>
      </table>
      <mat-paginator
        class="basic-paginataor"
        [pageSizeOptions]="[2, 5, 10, 20]"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
<ng-template #displayRoleModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>{{langObj ? langObj?.DetailedWeight : (' DetailedWeight' | translate)}}</h2>

      <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>

  <div mat-dialog-actions style="float: right; margin-top: 10px">
    <!-- <button color="accent" mat-flat-button cdkFocusInitial >
          {{formMode ? 'Add' : 'Update'}}
        </button> -->
  </div>
  <div>
    <table
      mat-table
      [dataSource]="FrequencyweightDataSource"
      class="basic-table plant-model-table"
      matSort
    >
      <!-- Frequency Column -->
      <ng-container matColumnDef="Frequency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">{{langObj ? langObj?.Frequency : (' Frequency' | translate)}}</p>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <!-- Weight Column -->
      <ng-container matColumnDef="TotalWeight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">{{langObj ? langObj?.TotalWeight : (' TotalWeight' | translate)}} %</p>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="weightColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: weightColumns"></tr>
    </table>

    <mat-paginator
      class="basic-paginataor"
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</ng-template>
<div
  class="sidepanel-model-wrapper edit-plant-row-model"
  [ngClass]="{ active: kpiMasterModalVisible }"
>
  <div class="sidepanel-close" (click)="closeKpiMasterModel()"></div>
  <div class="model-content">
    <div class="model-top">
      <h2 class="model-title">
        {{ formMode ? "Add" : "Update" }} {{langObj ? langObj?.KPIMasterWeight : (' KPIMasterWeight' | translate)}}
      </h2>
      <button
        mat-icon-button
        class="model-close-btn"
        aria-label="Close model"
        (click)="closeKpiMasterModel()"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
            fill="black"
          />
        </svg>
      </button>
    </div>
    <div class="model-body">
      <form
        [formGroup]="masterWeightForm"
        (ngSubmit)="formMode ? submitForm() : updateForm()"
      >
        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>{{langObj ? langObj?.Plant : (' Plant' | translate)}}</mat-label>
            <mat-select formControlName="plant" [disabled]="true" required>
              <mat-option *ngFor="let plant of plants" [value]="plant.ptId">{{
                plant.ptDesc
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>{{langObj ? langObj?.Frequency : (' Frequency' | translate)}}</mat-label>
            <mat-select
              formControlName="frequency"
              (selectionChange)="onFrequencyChange()"
              required
            >
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="frequencies"
            (filteredReturn)="filteredFrequencies = $event"></mat-select-filter>
              <mat-option
                *ngFor="let frequency of filteredFrequencies"
                [value]="frequency.frequencyId"
              >
                {{ frequency.name }}</mat-option
              >
            </mat-select>
            <mat-error>{{langObj ? langObj?.pleaseselectfrequency : (' pleaseselectfrequency' | translate)}}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>{{langObj ? langObj?.KPI : (' KPI' | translate)}}</mat-label>
            <mat-select formControlName="kpi" required>
              <mat-select-filter
                [placeholder]="'Search'"
                [displayMember]="'name'"
                [array]="Kpis"
                (filteredReturn)="filteredKpis = $event"
              ></mat-select-filter>
              <mat-option *ngFor="let kpi of filteredKpis" [value]="kpi.kpiId">
                {{ kpi.name }}</mat-option
              >
            </mat-select>
            <mat-error>{{langObj ? langObj?.pleaseselectkpi : (' pleaseselectkpi' | translate)}}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>{{langObj ? langObj?.Weight : (' Weight' | translate)}} %</mat-label>
            <input
              matInput
              placeholder="enter weight %"
              formControlName="weight"
              type="number"
              required
            />
            <mat-error>{{langObj ? langObj?.pleaseenterweight : (' pleaseenterweight' | translate)}}</mat-error>
          </mat-form-field>

         
        </div>

        <div class="model-form-actions">
          <button   type="button" class="icon-btn" style="margin-left: 10px;" pTooltip="Cancel" tooltipPosition="top" (click)="closeKpiMasterModel()" >
            <img src="assets/images/cancel.png" alt="" width="20px" height="20px">
          </button>
          <button   type="submit" class="icon-btn" style="margin-left: 10px;" pTooltip="Update" tooltipPosition="top" *ngIf="!formMode">
            <img src="assets/images/edit.png" alt="" width="20px" height="20px">
          </button>
          <button   type="submit" class="icon-btn" style="margin-left: 10px;" pTooltip="Save" tooltipPosition="top" *ngIf="formMode"  [disabled]="!masterWeightForm.valid">
            <img src="assets/images/save.png" alt="" width="20px" height="20px">
          </button>

          <!-- <button
            type="button"
            mat-flat-button
            class="btn-cancel"
            (click)="closeKpiMasterModel()"
          >
            Cancel
          </button>
          <button
            mat-flat-button
            color="accent"
            [disabled]="!masterWeightForm.valid"
            class="btn-accent"
          >
            {{ formMode ? "Add" : "Update" }}
          </button> -->
        </div>
      </form>
    </div>
  </div>
</div>
