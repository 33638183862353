import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ProjectService } from 'src/app/services/project.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  startValue: Date | null = null;
  endValue: Date | null = null;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  projectForm!: FormGroup;
  projectColumns: string[] = ['projectActions', 'name', 'desc', 'startdate', 'endate', ];
  projectData!: MatTableDataSource<any>;
  projectModelVisible = false;
  ProjectformMode!: boolean;
  plantId!: number;
  groupId!: number;
  plantsDDL!: any[];
  isDelete!: boolean;
  IsAccess:any;
  IsWrite:any;
  isUpdateProject: boolean = false;
  projectId: any;
  constructor(
     private fb: FormBuilder,
     private toaster: ToastrService,
     private platModelService: EnterPriseGroupService,
     private projectService: ProjectService,
     ) { }
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  ngOnInit() {
    this.projectForm = this.fb.group({
      plantId: ['', Validators.required],
      projectname: ['', Validators.required],
      projectdesc: ['', Validators.required],
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
    });
    this.GetMenuAccess();
  }
  
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
      // localStorage.setItem('GetMenuAccess', this.permisson);
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  openProjectModal(): void {
    this.resetForm();
    this.ProjectformMode = true;
    this.projectForm.get('plantId')?.setValue(this.plantId);
    this.projectModelVisible = true;
  }
  openEditProjectModal(): void {
    this.ProjectformMode = false;

    this.projectModelVisible = true;

  }
  onChange(result: Date): void {
    console.log('Selected Time: ', result);
  }

  onOk(result: Date | Date[] | null): void {
    console.log('onOk', result);
  }

  onCalendarChange(result: Array<Date | null>): void {
    console.log('onCalendarChange', result);
  }
  resetForm(): void {
    this.projectForm.reset();
    this.isUpdateProject = false;
  }
  closeProjectModel(): void {
    this.projectModelVisible = false;
    this.resetForm();
  }
  saveProject(): void {
    if ( moment(this.projectForm.value.dateStart).format('YYYY-MM-DD')<= moment(this.projectForm.value.dateEnd).format('YYYY-MM-DD')) {
      this.projectForm.get('dateEnd')?.setValue(moment(this.projectForm.value.dateEnd).format('YYYY-MM-DD'));
      const project = {
        plantId: this.projectForm.value.plantId,
        projectName: this.projectForm.value.projectname,
        projectDesc: this.projectForm.value.projectdesc,
        startDate: this.projectForm.value.dateStart,
        endDate: this.projectForm.value.dateEnd,
        userId: JSON.parse(localStorage.user).UserId,
      };
      this.projectService.addProject(project).subscribe(
        (response) => {
          this.toaster.success('Success', 'Project Added Successfully');
          this.getprojectList();
          this.closeProjectModel();
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    }else{
      this.toaster.error(
        'End date should not be lower than start date'
      );
      this.projectForm.get('dateEnd')?.setValue('');
      }
   
  }
  Deleteproject(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete project!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        var id = {
          projectId: element.projectId
        }
        this.projectService.deleteProject(id).subscribe((response) => {
          Swal.fire({
            title: 'Deleted!',
            text: 'Your selected project has been deleted.',
            icon: 'success',
            timer: 800,
            showConfirmButton: false,
          });
          this.getprojectList();
        },
          (error) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected project is safe :)', 'error');
      }
    });

  }
  updateProject(): void {
    if ( moment(this.projectForm.value.dateStart).format('YYYY-MM-DD')<= moment(this.projectForm.value.dateEnd).format('YYYY-MM-DD')) {
      this.projectForm.get('dateEnd')?.setValue(moment(this.projectForm.value.dateEnd).format('YYYY-MM-DD'));
    const key = {
      projectId: this.projectId,
      plantId: this.projectForm.value.plantId,
      projectName: this.projectForm.value.projectname,
      projectDesc: this.projectForm.value.projectdesc,
      startDate: this.projectForm.value.dateStart,
      endDate: this.projectForm.value.dateEnd,
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.projectService.updateProject(key).subscribe(
      (response) => {
        this.toaster.success('Success', 'Project Updated Successfully');
        this.getprojectList();
        this.closeProjectModel();
        this.isUpdateProject = false;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);

      });}
   
    else{
      this.toaster.error(
        'End date should not be lower than start date'
      );
      this.projectForm.get('dateEnd')?.setValue('');
      
    }
  }
  editProjectModel(element: any): void {
    const key = {
      projectId: element.projectId
    };

    this.projectService.GetOneMasterProject(key).subscribe((Response) => {
      this.openEditProjectModal()
      this.projectForm.get('plantId')?.setValue(this.plantId);
      this.projectForm.get('projectname')?.setValue(element.projectName);
      this.projectForm.get('projectdesc')?.setValue(element.projectDesc);
      this.projectForm.get('dateStart')?.setValue(element.startDate);
      this.projectForm.get('dateEnd')?.setValue(element.endDate);
      this.projectId = element.projectId;
    }, (error) => {
      this.toaster.error('Error', error.error.message);
    });


  }
  getPlantsByGroup(): void {

    const key = {
      GroupId: this.groupId
    };


    this.projectService.getPlantsData(key).subscribe(
      (plants: any) => {

        this.plantsDDL = plants.DepartList;
        this.projectForm.get('plantId')?.setValue(this.plantId);


      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getProjectMaster(key: any): void {
    this.plantId = key.plantId;
    this.groupId = key.groupId;
    this.projectForm.get('group')?.setValue(key.groupId);
    this.projectService.getPlantsData(key).subscribe(
      (plants: any) => {

        this.plantsDDL = plants.DepartList;
        this.projectForm.get('plantId')?.setValue(this.plantId);
        this.getprojectList();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getprojectList(): void {
    const key = {
      plantId: this.plantId
    }
    this.projectService.getMasterProject(key).subscribe((data) => {
      debugger
      this.projectData = new MatTableDataSource<any>(data);
      this.getPaginator();
    },
      (error) => {
        this.toaster.error(error.error.message);

      });

  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.projectData.filter = filterValue.trim().toLowerCase();

    if (this.projectData.paginator) {
      this.projectData.paginator.firstPage();
    }
  }
  getPaginator(): void {
    setTimeout(() => {
      this.projectData.paginator = this.paginator;
      this.projectData.sort = this.sort;
    }, 1000);
  }
}
