import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  NgxMatDateAdapter,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TreeModule } from 'primeng/tree';
import { DropdownModule } from 'primeng/dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from 'src/app/material-module';

import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HighchartsChartModule } from 'highcharts-angular';

import { AgGridModule } from 'ag-grid-angular';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TooltipModule } from 'primeng/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HomeRoutingModule } from '../screens/home-routing.module';
import { LossAnalysisRoutingModule } from './lossanalysis-routing.module';
import { MatChipsModule } from '@angular/material/chips';
import { CostMasterDataComponent } from './Cost-MasterData/Cost-MasterData.component';
import { CostdictionaryComponent } from './costdictionary/costdictionary.component';
import { CostelementComponent } from './costelement/costelement.component';
import { GapAnalysisComponent } from './GapAnalysis/GapAnalysis.component';
import { ReportingComponent } from './reporting/reporting.component';
import { CostCenterComponent } from './cost-center/cost-center.component';
import { TdcSimulationComponent } from './tdcSimulation/tdcSimulation.component';
import { TdcAnalysisComponent } from './tdcAnalysis/tdcAnalysis.component';
import { TdcelementComponent } from './tdcelement/tdcelement.component';
import { TdcMasterDataComponent } from './tdc-MasterData/tdc-MasterData.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMatSelectModule } from '../ngx-mat-select/ngx-mat-select.module';
import { TranslateModule } from '@ngx-translate/core';
import { ChartModule } from 'angular-highcharts';
import { MatSelectFilterModule } from 'mat-select-filter';
import { DashboardLossComponent } from './dashboard-loss/dashboard-loss.component';
import { MultiSelectModule } from 'primeng/multiselect';
@NgModule({
  imports: [
    TranslateModule.forChild({
      extend: true,
    }),
    CommonModule,
    ChartsModule,
    NgApexchartsModule,
    HomeRoutingModule,
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    AgGridModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    TreeModule,
    DropdownModule,
    NgxSpinnerModule,
    TooltipModule,
    MatTooltipModule,
    HighchartsChartModule,
    LossAnalysisRoutingModule,
    NzDatePickerModule,
    ChartModule,
    MatChipsModule,
    DragDropModule,
    MatSelectFilterModule,
    MultiSelectModule ,
    NgxDaterangepickerMd.forRoot(),
    NgxMatSelectModule.forRoot({
      maxWidthForMobileView: 600,
      inFirstLoadCallSearcher: true,
      inFirstLoadSearcherValue: '',
      emptyLabel: 'no entry found',
      noMoreResultLabel: 'no more found',
      useInfiniteScroll: false,
      searchBoxPlaceholder: 'Search',
      maximumResultForShow: 4000,
      useMobileView: false,
      valueMember: 'key',
      displayMember: 'value',
      mobileViewType: 'FullScreen',
    }),
  ],
  declarations: [
    CostMasterDataComponent,
    TdcMasterDataComponent,
    CostdictionaryComponent,
    CostelementComponent,
    GapAnalysisComponent,
    ReportingComponent,
    CostCenterComponent,
    TdcSimulationComponent,
    TdcAnalysisComponent,
    TdcelementComponent,
    DashboardLossComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LossAnalysisModule { }
