import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MasterOleComponent } from './Master-ole/Master-ole.component';
import { FTEComponent } from './FTE/FTE.component';
import { StaffAttendenceComponent } from './staff-attendence/staff-attendence.component';
import { FTEDashboardComponent } from './FTE-Dashboard/FTE-Dashboard.component';
import { MicroLevelSaturationComponent } from './micro-level-saturation/micro-level-saturation.component';
import { MicrotaskComponent } from './microtask/microtask.component';

const routes: Routes = [
      {path: 'OLEMaster', component: MasterOleComponent },
      {path: 'FTE', component: FTEComponent },
      {path: 'StaffAttendence', component: StaffAttendenceComponent },
      {path: 'FTEdashboard', component: FTEDashboardComponent },
      {path: 'microlevelsaturation', component:MicroLevelSaturationComponent},
      {path: 'MicroTask', component:MicrotaskComponent}
    ];
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class OLERoutingModule {}
