import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ManagerExecutionService } from '../../services/manager.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { OperatorExecutionService } from 'src/app/services/operator-execution.service';
import { PrevConfigurationService } from 'src/app/services/prev-configuration.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-manager-execution-form',
  templateUrl: './manager-execution-form.component.html',
  styleUrls: ['./manager-execution-form.component.scss']
})
export class ManagerExecutionFormComponent implements OnInit {
  isEdit: boolean = false;
  @ViewChild('manageroperatorimageViewModal') private manageroperatorimageViewModal!: TemplateRef<any>;
  @ViewChild('managertechnicianimageViewModal') private managertechnicianimageViewModal!: TemplateRef<any>;
  form = new FormGroup({
    SparePartCode: new FormControl(null),
    Priority: new FormControl(null, [Validators.required]),
    assignUser: new FormControl(null, [Validators.required]),
    ManagerRemarks: new FormControl(null, [Validators.required]),
    Manageruser: new FormControl(null),
    remark: new FormControl(''),
  });
  woTypeName: string = '';
  techResponded: boolean = false;

  breadcrumList!: any;

  operator: any = { LineId: '', TimeStamp: '', WOId: 0, WOStatus: '', WOType: '', FailureMode: '', SpareParts: '', Remark: '', UploadPicture: '', RequestedBy: '' };

  manager: any = { SparePartCode: '', Priority: '', assignUser: '', AssignTechnicianTimeStamp: '', ManagerRemarks: '', Manageruser: '', WOId: 0, CreatedAt: new Date(), CreatedBy: 1, operExecWo: 0 };
  userList: any;
  spinnerText: string = "";
  user: any = "";
  blobStorageUrl: string = '';
  PriorityList: any = [];
  OperatorFiles: any = [];
  IsWrite: boolean = false;
  woType: any = [];
  failMode: any = [];
  userFiltList: any = []
  TechnicianFiles: any = [];
  isviewMode: any;

  constructor(
    public router: Router, 
    private enterpriseGroupService: EnterPriseGroupService, 
    public managerExecutionService: ManagerExecutionService, 
    private toaster: ToastrService, 
    private spinner: NgxSpinnerService, 
    private userManagementService: UserManagementService, 
    public operatorExecutionService: OperatorExecutionService, 
    public prevConfigurationService: PrevConfigurationService,
    private dialog:MatDialog
    ) { }

  ngOnInit() {
    this.blobStorageUrl = environment.O3CoreApiImageUrl;
    this.operator = this.managerExecutionService.getManagerData();
    this.userList = this.managerExecutionService.getUserData();
    
    this.isviewMode = this.operator?.isviewButton
    this.userFiltList = this.userList && this.userList.length ? this.userList.slice() : [];
    if (this.userList && this.userList.length) {
      this.userList.sort(function (a, b) {
        return parseFloat(b.AverageScore) - parseFloat(a.AverageScore);
      });
      this.userFiltList = this.userList.slice()
      this.userFiltList.sort(function (a, b) {
        return a.fullName.localeCompare(b.fullName) || b.AverageScore - a.AverageScore;
      });
    }
    else {
      this.userFiltList = []
    }
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    if (!this.operator) {
      this.router.navigate(['/PM/manager']);
    } else {
      this.operator.SpareParts == 'Required' ? this.operator.SparePartsToggle = 1 : this.operator.SparePartsToggle = 0
      let OperatorUploadFiles: any = [];
      let TechnicianUploadFiles: any = [];

      if (this.operator.UploadPicture) {
        OperatorUploadFiles = this.operator.UploadPicture.split(',');
        for (let i = 0; i < OperatorUploadFiles.length; i++) {
          this.OperatorFiles.push({ ImageSrc: OperatorUploadFiles[i] })
        }
      }
      if (this.operator.techExc && this.operator.techExc.AfterPicture) {
        TechnicianUploadFiles = this.operator.techExc.AfterPicture.split(',');
        for (let i = 0; i < TechnicianUploadFiles.length; i++) {
          this.TechnicianFiles.push({ ImageSrc: TechnicianUploadFiles[i] })
        }
      }

      this.operator.TimeStamp
      this.manager.operExecWo = this.operator.WOId;
      this.operator.WOType = this.operator.WOType.ID,
        this.operator.TimeStamp = moment(this.operator.TimeStamp).format('DD/MM/YYYY hh:mm a')
      this.operator.FailureMode = this.operator.FailureMode.ID,
        this.getWOTypeFailureData();
      this.findManagerData();
    }
    this.GetBreadcrumList();
    this.GetMenuAccess();
  }

  // img popup
  imgview(e:any){
    let dialogRef = this.dialog.open(this.manageroperatorimageViewModal, {
      height: '90%',
      width: '90%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  oncloseimg(){
    this.dialog.closeAll();
  }
  imgview1(e:any){
    let dialogRef = this.dialog.open(this.managertechnicianimageViewModal, {
      height: '90%',
      width: '90%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  oncloseimg1(){
    this.dialog.closeAll();
  }
  // img popup

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.spinner.show();
    this.spinnerText = 'Loading Menu Data';
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.spinner.hide();
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  getWOTypeFailureData() {
    this.spinner.show();
    this.spinnerText = 'Fetching Master Data';
    const data = {
      WOType: this.operator.WOType,
      group: this.operator.Group.egId,
      plant: this.operator.Plant.ptId
    }
    this.operatorExecutionService.getOperatorMasterData(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.woType = res.WOTypes;
        this.failMode = res.failureModes;
        this.operator.WOType = this.operator.WOType
        this.operator.FailureMode = this.operator.FailureMode
      }
      else
        this.toaster.error('Error', res.message)
    },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error('Error', err.message)
      })
  }

  onsparechange(event){
    
  }

  findManagerData() {
    this.spinner.show();
    this.spinnerText = "Fetching Manager Data";
    const obj = {
      UserId: '',
      WOId: this.operator ? this.operator.WOId : ''
    }
    this.managerExecutionService.getOperatorData(obj).subscribe((res: any) => {
      if (res.MEWo) {
        this.techResponded = res.MEWo.techExc ? true : false;
        this.woTypeName = res.MEWo.WOType.Name;
        this.operator.WOIdDisplay = res.MEWo.WOId;
        const operatorName = this.userList.find((usr: any) => usr.UserId == this.operator.RequestedBy);
        let techMaxScore: any;
        let techMaxAvg: any;
        let userByScoreAvg: number = 0;
        this.PriorityList = res.priority;
        for (let tech of res.technician) {
          let score = tech.TechScore.find((scr: any) => scr.WOTypeId && scr.WOTypeId.ID == res.MEWo.WOType.ID);
          if (score) {
            tech.Score = score.Score;
            score.UserId = tech.UserId;
          }
          if ((!techMaxScore && score) || (score && score.Score && techMaxScore && techMaxScore.Score && score.Score > techMaxScore.Score))
            techMaxScore = score;
        }
        let techList: any = [];
        if (res.technician && res.technician.length) {
          for (let tech of res.technician) {
            tech.Score = tech.Score ? tech.Score : 0;
            const chk = techList.find((t: any) => t == tech.UserId)
            if (!chk)
              techList.push(tech.UserId);
          }
          if (this.userList && this.userList.length) {
            this.userList = this.userList.filter((item: any) => techList.includes(item.UserId))
            if (this.userList && this.userList.length) {
              for (let technician of res.technician) {
                const userIndex = this.userList.findIndex((user: any) => technician.UserId == user.UserId);
                if (this.userList[userIndex]) {
                  this.userList[userIndex].AverageScore = technician.AverageScore ? technician.AverageScore : 0;
                  this.userList[userIndex].Score = technician.Score;
                  if (!techMaxAvg || (techMaxAvg && techMaxAvg.AverageScore && technician.AverageScore > techMaxAvg.AverageScore))
                    techMaxAvg = technician;
                }
              }
            }
            if (techMaxScore)
              userByScoreAvg = techMaxScore.UserId;
            else if (techMaxAvg)
              userByScoreAvg = techMaxAvg.UserId;
          }
        }

        this.userList.sort((a, b) => {
          const nameA = a.fullName.toUpperCase(); // ignore upper and lowercase
          const nameB = b.fullName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        this.userList.sort((a: any, b: any) => parseFloat(b.AverageScore) - parseFloat(a.AverageScore));

        this.userList.sort((a: any, b: any) => parseFloat(b.Score) - parseFloat(a.Score));

        if (res.MEWo.managerExc) {
          this.manager = res.MEWo.managerExc;
          this.manager.Priority = res.MEWo.managerExc.Priority && res.MEWo.managerExc.Priority.ID ? res.MEWo.managerExc.Priority.ID : '';
          this.manager.assignUser = res.MEWo.managerExc.assignUser ? res.MEWo.managerExc.assignUser : this.userList[0].UserId;
          this.isEdit = true;
        } else {
          this.isEdit = false;
          this.manager.assignUser = this.userList[0].UserId;
        }

        this.manager.Manageruser = this.user.UserName;

        if (this.PriorityList && this.PriorityList.length) {
          const findhigh = this.PriorityList.find((pri: any) => pri.Name.trim().toLowerCase() == 'high')
          if (findhigh && !this.manager.Priority)
            this.manager.Priority = findhigh.ID
        }

        if(this.operator && this.operator.SpareParts != 'Required'){
          this.form.controls["SparePartCode"].setValidators(null);
          this.form.controls['SparePartCode'].updateValueAndValidity();
          this.form.controls['SparePartCode'].disable();    
        }
      }
      this.spinner.hide();
    }, (err: any) => {
      this.spinner.hide();
    });
  }

  onSubmit() {
    if (this.operator.SparePartsToggle == 1) {
      this.form.controls["SparePartCode"].setValidators([Validators.required]);
      this.form.controls['SparePartCode'].updateValueAndValidity()
    }
    else {
      this.form.controls["SparePartCode"].setValidators(null);
      this.form.controls['SparePartCode'].updateValueAndValidity()
    }
    if (!this.form.valid) {
      this.toaster.warning('Please Fill All Required Field', 'Error', {
        timeOut: 3000,
      });
      return false;
    } else {
      this.spinnerText = "Save Manager Data";
      this.spinner.show();
      this.manager.Manageruser = this.user.UserId;
      this.manager.CreatedBy = this.user.UserId;
      this.manager.WOId = this.operator.WOId;
      this.manager.WOType = this.operator.WOType;
      this.manager.FailureMode = this.operator.FailureMode;
      this.manager.SpareParts = this.operator.SpareParts == 'Required' ? 1 : 0;
      this.manager.AssignTechnicianTimeStamp = new Date();
      if (this.isEdit) {
        this.managerExecutionService.updateManagerData(this.manager).subscribe((res: any) => {
          if (res.status)
            this.toaster.success('Success', 'Data Updated Successfully');
          this.spinner.hide();
          this.router.navigate(['/PM/manager']);
        })
      } else {
        this.managerExecutionService.addManagerData(this.manager).subscribe((res: any) => {
          if (res.status) {
            if (res.result) {
              this.addUserWiseNotification(res.result);
            }
            this.toaster.success('Success', 'Data Inserted Successfully');
          }
          this.spinner.hide();
          this.router.navigate(['/PM/manager']);
        })
      }
      return true;
    }
  }

  addUserWiseNotification(val: any) {
    const data = {
      userId: val.assignUser, // multiple user ids send [104,105,7,9]
      desc: `Work Order ID ${val.WOId} Is Opened`,
      title: 'Work Order',
      createdBy: JSON.parse(localStorage.user).UserId,
    };
    this.spinnerText = 'Adding User Wise Notification';
    this.spinner.show();
    this.prevConfigurationService.addUserWiseNotification(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toaster.success('Success', res.message);
      }
    }, (error: any) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  SpareToggle(event:any){
    if(event?.checked){
      this.form.controls["SparePartCode"].setValidators([Validators.required]);
      this.form.controls['SparePartCode'].updateValueAndValidity();
      this.form.controls['SparePartCode'].enable();
    }
    else{
      this.form.controls["SparePartCode"].setValidators(null);
      this.form.controls['SparePartCode'].updateValueAndValidity();
      this.form.controls['SparePartCode'].disable();
    }
  }

}
