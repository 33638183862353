import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'btn-cell-rendererCheckListConfiguration',
    template: `
        <button mat-mini-fab color="primary" pTooltip="Edit CheckList" tooltipPosition="top" (click)="onClick($event)">
        <mat-icon>edit</mat-icon>
        </button>
        <button mat-mini-fab color="warn"  *ngIf="!params.data.lastExecutionId" pTooltip="Delete CheckList" tooltipPosition="top" (click)="onClick($event)">
        <mat-icon>delete</mat-icon>
        </button>
        <button mat-mini-fab="" color="primary" pTooltip="Duplicate CheckList" title="Duplicate Material" tooltipposition="top"   style="background: #1B085C;" >
        <mat-icon role="img"  aria-hidden="true" data-mat-icon-type="font">file_copy</mat-icon>
         </button>
        <button mat-mini-fab color="accent" (click)="onClick($event)" pTooltip="Configure Header/Footer" tooltipPosition="top" style="background: #824aed;" >
        <mat-icon>add</mat-icon>
        </button>
  `
})
// tslint:disable-next-line:component-class-suffix
export class BtnCellRendererCheckListConfiguration implements ICellRendererAngularComp, OnDestroy {
    params: any;
    refresh(params: any): boolean {
        throw new Error('Method not implemented.');
    }
    agInit(params: any): void {
        this.params = params;

    }
    onClick($event: any): any {
        if (this.params.onClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowData: this.params.node.data
                // ...something
            };
            this.params.onClick(params);
        }
    }
    ngOnDestroy(): any {
        // no need to remove the button click handler
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}
