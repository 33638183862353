import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { event } from 'jquery';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'btn-cell-rendererGambaWalk',
  template: `
    <button *ngIf="(params.node.data.ObservationStatus!=='Closed' && params.node.data.ObservationStatus!=='Completed')"
     mat-mini-fab color="primary"
     tooltipPosition="top"
     pTooltip="Edit GembaWalk"
     (click)="onClick($event)">
      <mat-icon>edit</mat-icon>
    </button>
    <button *ngIf="(params.node.data.ObservationStatus!=='Closed' && params.node.data.ObservationStatus!=='Completed'  && params.node.data.ObservationStatus.toLowerCase() !== 'in progress' )"
      mat-mini-fab
      tooltipPosition="top"
      pTooltip="Delete GembaWalk"
      color="warn"
      (click)="onClick($event)"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <button *ngIf="(params.node.data.ObservationStatus!=='Closed' && params.node.data.ObservationStatus!=='Completed')"
      mat-mini-fab
      color="accent"
      tooltipPosition="top"
      pTooltip="Add Action"
      (click)="onClick($event)"
      class="add-icon"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button *ngIf= "params.node.data.ObservationStatus !== 'Closed' && params.node.data.ObservationStatus.toLowerCase() !== 'in progress' && params.node.data.NoOfActions === 0 "
      mat-mini-fab
      color="accent"
      tooltipPosition="top"
      pTooltip="Close GembaWalk"
      (click)="onClick($event)"
    >
      <mat-icon>close</mat-icon>
    </button>
    <button *ngIf= "params.node.data.ObservationStatus === 'Closed' && params.node.data.NoOfActions === 0"
      mat-mini-fab
      color="accent"
      tooltipPosition="top"
      pTooltip="View Actions"
      (click)="onClick($event)"
    >
      <mat-icon>remove_red_eye 2</mat-icon>
    </button>
    <button style="background-color: #7a1dcf;"
    *ngIf= "params.node.data.ObservationStatus === 'Closed' ? false: true "
    tooltipPosition="top"
    pTooltip="View Image"
      mat-mini-fab
      (click)="onClick($event)"
    >
    <mat-icon> insert_drive_file</mat-icon>
    </button>
    
    <button style="background-color: #6FBABA;"
    *ngIf= "params.node.data.NoOfActions > 0"
    tooltipPosition="top"
     pTooltip="View Actions"
      mat-mini-fab
      (click)="onClick($event)"
    >
    <mat-icon>remove_red_eye</mat-icon>
    </button>
  `,
  styles: [
    ` button{
      height: 35px;
      width: 35px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 3px;

    }
    .mat-icon {
      transform: scale(0.7);
    }
      .add-icon {
        background: cornflowerblue;
      }
    `,
  ],
})
export class BtnCellRendererGambaWalk
  implements ICellRendererAngularComp, OnDestroy {
  clicked = false;
   params: any;
  refresh(params: any): boolean {
    throw new Error('Method not implemented.');
  }
  agInit(params: any): void {
    this.params = params;
  }
  onClick($event: any): any {
    debugger;
    const spec = $event.target.className;
    if (
      spec !== 'Delete' &&
      spec !== 'close' &&
      this.params.node.data.ObservationStatus.toLowerCase() !== 'in progress'
    ) {
      if (this.params.onClick instanceof Function) {
        // put anything into params u want pass into parents component
        const params = {
          event: $event,
          rowData: this.params.node.data,
          // ...something
        };
        this.params.onClick(params);
      }
    } else {
      this.clicked = true;
    }
  }
  ngOnDestroy(): any {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
