import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-quotidian-dashboard',
  templateUrl: './quotidian-dashboard.component.html',
  styleUrls: ['./quotidian-dashboard.component.scss']
})
export class QuotidianDashboardComponent implements OnInit {
  oeeDashboardForm!: FormGroup;
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  filteredUnits: any;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  OeeDashboardData!: any;
  //chart define
  chartOEEShiftDay!: Chart;
  chartOEEShiftNight!: Chart;
  chartOEEDay!: Chart;
  options: Options = {};
  criteriaType: boolean = false;
  //AG Grid
  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;
  gridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
  };
  constructor(private formBuilder: FormBuilder, private kpiMaster: KpimasterService,
    private toaster: ToastrService, private productivityservice: ProductivityService,
    private usermanagementService: UserManagementService, public router: Router, private spinner: NgxSpinnerService,) {
    this.columnDefs = [
      { headerName: 'Machine', field: 'machine', filter: 'agTextColumnFilter' },
      { headerName: 'Shift', field: 'shift', filter: 'agTextColumnFilter' },
      { headerName: 'Date', field: 'dateOEEAPQ', filter: 'agTextColumnFilter' },
      { headerName: 'OEE', field: 'AvgOee', filter: 'agTextColumnFilter' },
      { headerName: 'Availability', field: 'AvgAvailability', filter: 'agTextColumnFilter' },
      { headerName: 'Performance', field: 'AvgPerformance', filter: 'agTextColumnFilter' },
      { headerName: 'Quality', field: 'AvgQualityRate', filter: 'agTextColumnFilter' }
    ]
  }

  ngOnInit(): void {
    this.initForm();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.datasource();
  }
  initForm() {
    const dateStart = new Date();
    //dateStart.setDate(dateStart.getDate() - 183);
    dateStart.setHours(6);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);


    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate());

    this.oeeDashboardForm = this.formBuilder.group({
      UserGroup1: [''],
      UserPlant1: [''],
      unit: [''],
      line: [''],
      dateStart: [dateStart],
      dateEnd: [dateEnd],
      criteriaquery: ['day', []],
      searchval: [''],
    });
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }
  datasource(): void {
    const key = {
      UserId: 206     //later it will be change this is only for testing
    };
    const id = {
      value: 0
    };
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      if (this.GroupList.length > 0) {
        id.value = data.lstEG[0].EG_Id;
        this.oeeDashboardForm.get('UserGroup1')?.setValue(data.lstEG[0].EG_Id);
        this.oeeDashboardForm.get('UserPlant1')?.setValue(data.lstEG[0].EG_Id);
        this.filteredGroups = this.GroupList.slice();
        this.getPlants(id);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: 206
    };
    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      if (this.PlantList1.length > 0) {
        const id = data.lstPlant[0].PT_Id;
        this.oeeDashboardForm.get('UserPlant1')?.patchValue(id);
        this.filteredPlants = this.PlantList1.slice();
        this.getLine(id);
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getLine(id: any) {
    const key = {
      PT_ID: id,
      userId: 206
    };

    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      if (this.lineList.length > 0) {
        const lineDefault = this.lineList[0];
        this.oeeDashboardForm.controls.line.patchValue(lineDefault.PL_Id);
        this.filteredLines = this.lineList.slice();
        this.getUnit(lineDefault.PL_Id);
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUnit(id: number) {
    const key = {
      PL_ID: id,
      userId: 206
    };

    this.productivityservice.getUnitData(key).subscribe((data: any) => {
      this.unitList = data.lstUnit;
      if (this.unitList.length > 0) {
        const unitDefault = this.unitList[0];
        this.oeeDashboardForm.controls.unit.patchValue(unitDefault.PU_Id);
        this.filteredUnits = this.unitList.slice();
      }
      this.getOeeDashboard();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  onChangeGroup(data: any): void {
    this.getPlants(data);
  }
  onChangeLine(data: any): void {
    this.getUnit(data.value);
  }
  onChangePlant(data: any): void {
    this.getLine(data.value);
  }
  onChangeCriteria(data: any): void {
    if (data.value === 'shift') {
      this.criteriaType = true;
    } else {
      this.criteriaType = false;
    }
    this.getOeeDashboard();
  }
  getOeeDashboard() {
    const key = {
      PUID: this.oeeDashboardForm.get('unit')?.value,
      dateStart: this.oeeDashboardForm.get('dateStart')?.value,
      dateEnd: this.oeeDashboardForm.get('dateEnd')?.value,
      criteria: this.oeeDashboardForm.get('criteriaquery')?.value
    }
    this.spinner.show();
    this.productivityservice.getOEEANDAPQ(key).subscribe((data: any) => {
      this.OeeDashboardData = data.OEEAPQList;
      this.rowData = data.OEEAPQList;
      this.spinner.hide();
      this.drawOEEDay();
    }, (error: any) => {
      this.toaster.error('error', error.message);
      this.spinner.hide();
    });
  }

  //=================================================================================Quotidian OEE =================================================//


  drawOEEDay() {
    let label: any[] = new Array();
    let avgOEE: any[] = new Array();
    let avgAvailability: any[] = new Array();
    let avgPerformance: any[] = new Array();
    let avgQuality: any[] = new Array();
    var sumAvail: number = 0;
    var sumQual: number = 0;
    var sumPer: number = 0;
    if (this.oeeDashboardForm.get('criteriaquery')?.value === 'shift') {
      this.drawOEEShiftDay();
      this.drawOEEShiftNight();
    } else {
      this.OeeDashboardData.forEach((element: any) => {
        label.push(element.dateOEEAPQ);
        avgAvailability.push(parseFloat(element.AvgAvailability.toFixed(2)));
        avgPerformance.push([element.dateOEEAPQ, parseFloat(element.AvgPerformance.toFixed(2))]);
        avgQuality.push([element.dateOEEAPQ, parseFloat(element.AvgQualityRate.toFixed(2))]);
        avgOEE.push([element.dateOEEAPQ, parseFloat(element.AvgOee.toFixed(2))]);
        sumAvail += parseFloat(element.AvgAvailability.toFixed(2));
        sumQual += parseFloat(element.AvgQualityRate.toFixed(2));
        sumPer += parseFloat(element.AvgPerformance.toFixed(2));
      });
    }
    this.options = {
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: label
      },
      yAxis: {
        // min: 0,
        // max: 100,
        // labels: {
        //   format: '{value}%'
        // }
      },
      series: [
        {
          type: 'column',
          name: 'Avaiability',
          data: avgAvailability,
          color:'#10ce9c'
        },{
        type: 'column',
        name: 'Performance',
        data: avgPerformance,
        color : '#0f1ddb'
      }, {
        type: 'column',
        name: 'Quality',
        data: avgQuality,
        color:'#fcba03'
      },
       {
        type: 'spline',
        name: 'OEE',
        data: avgOEE,
        marker: {
          lineWidth: 2,
          lineColor: '',
          fillColor: 'white'
        }
      }, {
        type: 'pie',
        name: 'Total % ',
        data: [{
          name: 'Performance',
          y: sumPer / avgPerformance.length,
          color: '#0f1ddb',
        }, {
          name: 'Quality',
          y: sumQual / avgQuality.length,
          color: '#fcba03'
        }, {
          name: 'Avaiability',
          y: sumAvail / avgAvailability.length,
          color: '#10ce9c'
        }],
        center: [100, 20],
        size: 100,
        showInLegend: false,
        dataLabels: {
          enabled: false
        },
        tooltip:{
          valueDecimals:2
        }
      }]
    };
    let chart = new Chart(this.options);
    this.chartOEEDay = chart;
  }
  drawOEEShiftNight() {
    let label: any[] = new Array();
    let avgOEE: any[] = new Array();
    let avgAvailability: any[] = new Array();
    let avgPerformance: any[] = new Array();
    let avgQuality: any[] = new Array();
    var sumAvail: number = 0;
    var sumQual: number = 0;
    var sumPer: number = 0;
    this.OeeDashboardData.forEach((element:any )=> {
      if(element.shift.toLowerCase() === 'night'){
        label.push(element.dateOEEAPQ);
        avgAvailability.push(parseFloat(element.AvgAvailability.toFixed(2)));
        avgPerformance.push([element.dateOEEAPQ, parseFloat(element.AvgPerformance.toFixed(2))]);
        avgQuality.push([element.dateOEEAPQ, parseFloat(element.AvgQualityRate.toFixed(2))]);
        avgOEE.push([element.dateOEEAPQ, parseFloat(element.AvgOee.toFixed(2))]);
        sumAvail += parseFloat(element.AvgAvailability.toFixed(2));
        sumQual += parseFloat(element.AvgQualityRate.toFixed(2));
        sumPer += parseFloat(element.AvgPerformance.toFixed(2));
      }
    });
    this.options = {
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: label
      },
      yAxis: {
        // min: 0,
        // max: 100,
        // labels: {
        //   format: '{value}%'
        // }
      },
      series: [
        {
          type: 'column',
          name: 'Avaiability',
          data: avgAvailability,
          color:'#10ce9c'
        },{
        type: 'column',
        name: 'Performance',
        data: avgPerformance,
        color : '#0f1ddb'
      }, {
        type: 'column',
        name: 'Quality',
        data: avgQuality,
        color:'#fcba03'
      },
      {
        type: 'spline',
        name: 'OEE',
        data: avgOEE,
        marker: {
          lineWidth: 2,
          lineColor: '',
          fillColor: 'white'
        }
      }, {
        type: 'pie',
        name: 'Total % ',
        data: [{
          name: 'Performance',
          y: sumPer / avgPerformance.length,
          color: '#0f1ddb',
        }, {
          name: 'Quality',
          y: sumQual / avgQuality.length,
          color: '#fcba03'
        }, {
          name: 'Avaiability',
          y: sumAvail / avgAvailability.length,
          color: '#10ce9c'
        }],
        center: [100, 20],
        size: 100,
        showInLegend: false,
        dataLabels: {
          enabled: false
        },
        tooltip:{
          valueDecimals:2
        }
      }]
    };
    let chart = new Chart(this.options);
    this.chartOEEShiftNight = chart;
  }

  drawOEEShiftDay() {
    let label: any[] = new Array();
    let avgOEE: any[] = new Array();
    let avgAvailability: any[] = new Array();
    let avgPerformance: any[] = new Array();
    let avgQuality: any[] = new Array();
    var sumAvail: number = 0;
    var sumQual: number = 0;
    var sumPer: number = 0;
    this.OeeDashboardData.forEach((element:any )=> {
      if(element.shift.toLowerCase() === 'day'){
        label.push(element.dateOEEAPQ);
        avgAvailability.push(parseFloat(element.AvgAvailability.toFixed(2)));
        avgPerformance.push([element.dateOEEAPQ, parseFloat(element.AvgPerformance.toFixed(2))]);
        avgQuality.push([element.dateOEEAPQ, parseFloat(element.AvgQualityRate.toFixed(2))]);
        avgOEE.push([element.dateOEEAPQ, parseFloat(element.AvgOee.toFixed(2))]);
        sumAvail += parseFloat(element.AvgAvailability.toFixed(2));
        sumQual += parseFloat(element.AvgQualityRate.toFixed(2));
        sumPer += parseFloat(element.AvgPerformance.toFixed(2));
      }
    });
    this.options = {
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: label
      },
      yAxis: {
        // min: 0,
        // max: 100,
        // labels: {
        //   format: '{value}%'
        // }
      },
      series: [
        {
          type: 'column',
          name: 'Avaiability',
          data: avgAvailability,
          color:'#10ce9c'
        },{
        type: 'column',
        name: 'Performance',
        data: avgPerformance,
        color : '#0f1ddb'
      }, {
        type: 'column',
        name: 'Quality',
        data: avgQuality,
        color:'#fcba03'
      },
       {
        type: 'spline',
        name: 'OEE',
        data: avgOEE,
        marker: {
          lineWidth: 2,
          lineColor: '',
          fillColor: 'white'
        }
      }, {
        type: 'pie',
        name: 'Total % ',
        data: [{
          name: 'Performance',
          y: sumPer / avgPerformance.length,
          color: '#0f1ddb',
        }, {
          name: 'Quality',
          y: sumQual / avgQuality.length,
          color: '#fcba03'
        }, {
          name: 'Avaiability',
          y: sumAvail / avgAvailability.length,
          color: '#10ce9c'
        }],
        center: [100, 20],
        size: 100,
        showInLegend: false,
        dataLabels: {
          enabled: false
        },
        tooltip:{
          valueDecimals:2
        }
      }]
    };
    let chart = new Chart(this.options);
    this.chartOEEShiftDay = chart;
  }


  //====================================================================== AG GRID=======================================================================//
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  excelExport() {
    this.gridApi.exportDataAsExcel();
  }
  csvExport() {
    this.gridApi.exportDataAsCsv();
  }
}
