import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Validators } from '@angular/forms';
import { ButtonEditRendererComponent } from 'src/app/components/button-edit-render.components';
import { TranslaterService } from 'src/app/services/translater.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ButtonStackerRendererComponent } from 'src/app/components/button-Stacker-render.components';
import { DatePipe } from '@angular/common';
import { OpmsGlassService } from 'src/app/services/opms-glass.service';
import { GlassPpcLiveGeneraldataService } from 'src/app/services/glass-ppc-live-generaldata.service';
import Swal from 'sweetalert2';
import 'ag-grid-enterprise';
/**
 * File used to define const values used for roles check and 
 * Order number Types according to roles
 */
import { 
  rolesForMaterialConsumptionScreen,
  PVBColorTypes,
  GlassPOType,
  LaminationPOType,
  OfflinePOType 
} from 'src/app/@constants/RulesForMaterialConsumptionScreen';
import { GridConfiguration } from 'src/app/interface/grid-model';
import { GridService } from 'src/app/services/grid.service';
import { CommonMethodsForPOService } from 'src/app/services/shared-services/commonMethodsForPO.service';

@Component({
  selector: 'app-shop-floor-data',
  templateUrl: './shop-floor-data.component.html',
  styleUrls: ['./shop-floor-data.component.scss'],
})
export class ShopFloorDataComponent
  implements OnInit, OnDestroy
{
  @ViewChild('historyBatch1Modal') private historyBatch1Modal!: TemplateRef<any>;

  panelOpenState = false;
  total: any = 'Total: ';
  itemCode: any = 'ItemCode: ';
  quantityUnit!: string;
  groupDataForm!: FormGroup;
  materialConsumptionForm!: FormGroup;
  ItemTypeList!: any[];
  breadcrumList!: any;
  PPID!: number;
  JobBatchId: number = 0;
  PDtooltip: any;
  IDtooltip: any;
  processOrderList: any[] = [];
  fiteredProcessOrderList: any[];
  rowDataMaterialBatchHistory!: any[];
  columnMaterialBatchHistory!: any[];
  frameworkComponents: any;
  groups!: any[];
  Plants!: any[];
  languageDir = 'ltr';
  langObj: any = {};
  isLoteCodeNecessary: boolean = false;
  columnMaterialConsumption = [
    { headerName: 'Item Type', field: 'Name' },
    { headerName: 'Batch Number', field: 'BatchNo' },
    { headerName: 'Actual Quantity TON', field: 'Result' },
    { headerName: 'Remarks', field: 'Remarks' },
    {
      headerName: 'Time', field: 'RollStarttime',
      valueFormatter: function (params: any) {
        return moment(params.value).format('MM/DD/YYYY HH:mm');
      }
    },
    {
      headerName: 'Created On', field: 'CreatedOn',
      valueFormatter: function (params: any) {
        return moment(params.value).format('MM/DD/YYYY HH:mm');
      }
    },
    { headerName: 'Created By', field: 'CreatedBy'},
  ];
  rowDataMaterialConsumption: any[] = [];

  //================================================ Production Output code here ============================//

  @ViewChild('manualStackerModel') private manualStackerModel!: TemplateRef<any>;
  @ViewChild('OrderNumberLinkingModel') private OrderNumberLinkingModel!: TemplateRef<any>;
  manualStackerFromProductionOutput!: FormGroup;
  addManualStackerForProductionOutput!: FormGroup;
  machineNameForSearchFilter: any = [];
  machineNameForManualProductionEntry: any = [];
  shiftList: any;
  crewList: any;
  shiftListForManualEntry: any = [];
  crewListForManualEntry: any = [];
  thicknessList: any = [];
  qualityList: any = [];
  PVBColorList: any = [];
  CoatedGlassColorList: any = [];
  jobList: any = [];
  defaultColDef: any =  {
    resizable: true,
    filter: false,
  };
  columnDefsProductionOutput = [
      {
        headerName: 'Actions',
        field: 'Actions',
        cellRenderer: 'buttonRenderer',
        minWidth: 200,
        checkboxSelection: (params: any) => ((params.data.PPD_ID == 0 || (params?.data?.printStatus && !params?.data?.ERPStatus)) && params.data.GeneralBatchNo != '') ? true : false,
        cellStyle: (params: any) => params.data.GeneralBatchNo == '' ? { color: 'white', background: '#4040f2'} : ''
      },
      {headerName: 'Date', field: 'Date'},
      {headerName: 'MachineName', field: 'StackerName'},
      {headerName: 'OrderNumber', field: 'OrderNumber'},
      {headerName: 'Shift', field: 'Shift'},
      {headerName: 'Crew', field: 'Crew'},
      {headerName: 'Size', field: 'size'},
      {headerName: 'Thickness', field: 'thickness'},
      {headerName: 'LiteCount ERP', field: 'LiteCountErp'},
      {headerName: 'Result ERP', field: 'ResultErp'},
      {headerName: 'General BatchNumber', field: 'GeneralBatchNo'},
      {headerName: 'Actual BatchNumber', field: 'ActualBatchNo', sortable: false},
      {headerName: 'Created On', field: 'CreatedOn'},
  ];
  getRowStyle = params => {
    if(params.data.printStatus && !params.data.ERPStatus) {
      return { background: '#a2c6c6e8 !important;' };
    }
    else if(params.data.printStatus && params.data.ERPStatus){
      return { background: '#aaaad7 !important;' };
    }
  };
  frameworkComponentsForProduction: any;
  tempDataForProductionOutput: any[] = [];
  rowProductionOutput: any = [];
  gridApiForManualProductionOutput: any;
  gridColumnApiForManualProductionOutput: any;
  isManualProductionId: number = 0;
  isPOAvailable: boolean = true;
  POListForProductionOutputSearchFilter: any[] = [];
  POListForProductionOutput: any[] = [];
  filterPOListForProductionOutput: any[] = [];
  oldActualBatchNo: string = '';
  oldOrderNo: string = '';
  rowSelection: string = 'multiple';
  selectedRowsForLinkingPO: any[] = [];
  linkingOrderNumberForProductionOutput: FormGroup;
  rowHasOrderNumber: boolean = false;
  isDataAsc: boolean = false;
  toggleStatus: boolean = false;
  oldResultERP: string;
  isRowPrinted: number = 0
  manualStackerFromProductionOutputReport!: FormGroup;
  columnDefsProductionOutputReport = [
    {headerName: 'Date', field: 'Date',
      cellStyle: (params: any) => params.data.GeneralBatchNo == '' ? { color: 'white', background: '#4040f2',"font-weight":"bold"} : { color: 'black' },
    },
    {headerName: 'MachineName', field: 'StackerName'},
    {headerName: 'OrderNumber', field: 'OrderNumber'},
    {headerName: 'Shift', field: 'Shift'},
    {headerName: 'Crew', field: 'Crew'},
    {headerName: 'Size', field: 'size'},
    {headerName: 'Thickness', field: 'thickness'},
    {headerName: 'LiteCount ERP', field: 'LiteCountErp'},
    {headerName: 'Result ERP', field: 'ResultErp',},
    {headerName: 'General BatchNumber', field: 'GeneralBatchNo'},
    {headerName: 'Actual BatchNumber', field: 'ActualBatchNo', sortable: false},
    {headerName: 'Created On', field: 'CreatedOn'},
  ];
  rowProductionOutputReport: any = [];
  POListForProductionOutputReportSearchFilter: any[] = [];
  totalRows:number=0;
  pendingRows:number = 0;
  zeroValue:number = 0;
  printedRows:number = 0;
  actualRows:number = 0;
  actualweight: string = '0';
  tempDataForManualProductionOutputReport: any[] = [];
  menuRedIconUrl: string = '/assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl: string = '/assets/images/menu-bottom-green-icon.png';
  menuBlueIconUrl = '/assets/images/menu-bottom-blue-icon.png';
  gridApiForManualProductionOutputReport: any;
  gridColumnApiForManualProductionOutputReport: any;
  isDataAscReport: boolean = false;
  startDate: string = '';
  endDate: string = '';
  shift: string = '';
  crew: string = '';
  totalProductionCard: boolean = true;
  pendingProductionCard: boolean = false;
  zeroValueProductionCard: boolean = false;
  printedProductionCard: boolean = false;
  actualProductionCard: boolean = false;
  spinnerShow: any = 'block';
  ERPDataForSannad: Array<{ManualProductionId: number,Result: number,ActualBatchNo: string,
                          CreatedOn: string,PPD_ID: number,GridIndex: number,SQM: string}> = [];
  laminationBranding: boolean = false;
  PVBColorFieldDisable: boolean = false;
  gridReadyProductionOutput: boolean = false;
  gridReadyProductionOutputReport: boolean = false;
  isCoatedGlass: boolean = false;
  selectedRowsMachineName: Array<boolean> = [];
  linkingPOListForProductionOutput: any[] = [];
  filterLinkingPOListForProductionOutput: any[] = [];
  isUserHasRoleOfSanedDataPush: boolean = false;
  isUserHasRoleOfDeleteManualProductionData: boolean = false;
  isClicked: boolean = false;
  resultERP: any;
  //================================================ End ================================================//
  userRoles: Array<string> = [];
  canMaterialConsume: boolean = true;
  pageId: any;
  selectedPOForManualProductionOutput: any;

  constructor(
    private dialog: MatDialog,
    private sfdService: ShopFloorDataService,
    private toaster: ToastrService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private usermanagementService: UserManagementService,
    private translate: TranslaterService,
    private translater: TranslateService,
    private datePipe: DatePipe,
    private opmsGlassservice: OpmsGlassService,
    private glassPpcLiveService: GlassPpcLiveGeneraldataService,
    private gridService: GridService,
    private commonMethodsForPOService: CommonMethodsForPOService
  ) {
    this.translater.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === 'ar') {
        this.languageDir = 'rtl';
        this.getLangWords();
      } else {
        this.languageDir = 'ltr';
        this.getLangWords();
      }
    });
    this.frameworkComponents = {
      buttonEditRenderer: ButtonEditRendererComponent,
    };
    this.columnMaterialBatchHistory = [
      {
        headerName: 'Action',
        cellRenderer: 'buttonEditRenderer',
        cellRendererParams: {
          onClick: this.editMaterialConsumptionHistoryBatch.bind(this),
          label: 'Edit',
        },
        maxWidth: 80,
        suppressMenu: true,
      },
      { headerName: 'Item Type', field: 'Name' },
      { headerName: 'Batch Number', field: 'BatchNo' },
      { headerName: 'Actual Quantity TON', field: 'Result' },
      { headerName: 'Remarks', field: 'Remarks' },
      {
        headerName: 'Time',
        field: 'RollStarttime',
        valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
        maxWidth: 180,
      },
      {
        headerName: 'Created On',
        field: 'CreatedOn',
        valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
        maxWidth: 180,
      },
      { headerName: 'Created By', field: 'CreatedBy', maxWidth: 120 },
    ];
    this.frameworkComponentsForProduction = {
      buttonRenderer: ButtonStackerRendererComponent,
    };
    this.spinnerShow = 'block';
  }

  ngOnDestroy(): any {
  }
  ngOnInit(): void {
    this.spinnerShow = 'block';
    this.groupDataForm = this.fb.group({
      group: [''],
      status: [''],
      plant: [''],
      Unit: [''],
      processOrder: [''],
      operationName: [''],
      productDetail: [''],
      itemCode: [''],
      itemDescription: [''],
      remarks: [''],
      fromProcessDate: ['', Validators.required],
      toProcessDate: ['', Validators.required],
      processType: [''],
      parameterInterval: [],
      intervalType: [],
    });
    this.materialConsumptionForm = this.fb.group({
      materialDetail: ['',Validators.required],
      qty: ['',Validators.required],
      lot: ['',Validators.required],
      actQty: ['',Validators.required],
      remarks: [],
      date: [],
    });
    let firstDate = new Date(new Date().getTime());
    firstDate = new Date(firstDate.setDate(firstDate.getDate()-1));
    this.manualStackerFromProductionOutput = this.fb.group({
      startDate: [this.datePipe.transform(firstDate, 'yyyy-MM-dd')],
      endDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      stacker: [''],
      job_no: [''],
      shift: [''],
      crew: [''],
    });
    this.addManualStackerForProductionOutput = this.fb.group({
      manualStackerCreationDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      stacker: [''],
      shift: ['',Validators.required],
      crew: ['',Validators.required],
      OrderNumber: [''],
      GeneralBatchNumber: [''],
      ActualBatchNumber: [''],
      LiteCountERP: [''],
      ResultERP: [''],
      NoOfPallets: [''],
      Size: [''],
      Thickness: [''],
      PackingType: [''],
      Standard: [''],
      Branding: [''],
      Quality: [''],
      CoatedGlassColor: ['']
    });
    this.linkingOrderNumberForProductionOutput = this.fb.group({
      OrderNumber: ['',Validators.required],
      Branding: [''],
      PVBColorCode: [''],
      CoatedGlassColor: ['']
    });
    this.manualStackerFromProductionOutputReport = this.fb.group({
      startDate: [this.datePipe.transform(firstDate, 'yyyy-MM-dd')],
      endDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      job_no: [''],
      shift: [''],
      crew: [''],
    });
    this.GetBreadcrumList();
    this.getLangWords();
    /**
     * Checking User Roles For Filtering Order Numbers 
     * As User can see Order numbers According to Role
     * Admin can see all Orders
     */
    let userRoles = JSON.parse(localStorage.getItem('roleName'));
    userRoles = userRoles.map((role: string) => {
      return role.trim().toLowerCase();
    });
    if(!userRoles.length){
      this.userRoles = [];
    }
    else{
      this.userRoles = [];
      if(userRoles.includes(rolesForMaterialConsumptionScreen.GlassLaminationRole)){
        this.userRoles.push(rolesForMaterialConsumptionScreen.GlassLaminationRole);
      }
      if(userRoles.includes(rolesForMaterialConsumptionScreen.GlassOfflineRole)){
        this.userRoles.push(rolesForMaterialConsumptionScreen.GlassOfflineRole);
      }
      if(userRoles.includes(rolesForMaterialConsumptionScreen.GlassManufacturingRole)){
        this.userRoles.push(rolesForMaterialConsumptionScreen.GlassManufacturingRole);
      }
    }
    this.isUserHasRoleOfSanedDataPush = !userRoles.length ? true : userRoles.includes(rolesForMaterialConsumptionScreen.RoleCanPushDataToSaned);
    this.isUserHasRoleOfDeleteManualProductionData = !userRoles.length ? true :  userRoles.includes(rolesForMaterialConsumptionScreen.RoleCanDeleteData);
    this.getGroups();
    this.getStackerList();
    this.loadBaseDetails();
  }

  public getLangWords() {
    const key = {
      ModuleId: 1,
      PageId: 1,
      LanguageId:
        this.translater.currentLang === 'en' ||
        this.translater.currentLang === undefined
          ? 1
          : 2,
    };
    this.translate.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.sfdService.getGroupData(key).subscribe((res: any) => {
      this.groups = res.masterList;
      this.groupDataForm.get('group')?.setValue(this.groups[0]?.TypeId);
      this.getPlants();
    });
  }

  getPlants(): void {
    this.spinnerShow = 'block';
    const key = {
      GroupId: this.groupDataForm.value.group,
    };
    this.spinner.show();
    this.usermanagementService.getDepartments(key).subscribe((res: any) => {
      this.Plants = res.DepartList;
      this.groupDataForm.get('plant')?.setValue(this.Plants[0].DeptId);
      this.GetProcessOrderList();
    });
  }

  GetProcessOrderList(): void {
    this.spinnerShow = 'block';
    const key = {
      PUId: this.groupDataForm.get('plant')?.value,
      PPDId: 0,
      UserId: 0,
    };
    this.spinner.show();
    this.sfdService.GetProcessOrderList(key).subscribe((res: any) => {
      this.processOrderList = this.commonMethodsForPOService.getFilteredPOList(res.ProcessOrderList,this.userRoles);
      this.fiteredProcessOrderList = this.processOrderList.slice();
      this.spinner.hide();
      this.resetGroupDataFormForAllPO();
    });
  }

  resetGroupDataFormForAllPO(){
    const {group,plant} = this.groupDataForm.value;
    this.groupDataForm.reset();
    this.groupDataForm.get('group').setValue(this.groups.length ? group : '');
    this.groupDataForm.get('plant').setValue(this.Plants.length ? plant : '');
    this.materialConsumptionForm.reset();
    this.materialConsumptionForm.get('date').setValue(new Date());
    this.ItemTypeList = [];
    this.rowDataMaterialConsumption = [];
    this.total = 'Total: ';
    this.itemCode = 'Itemode: ';
    this.isLoteCodeNecessary = false;
  }

  getMaterialConsumption(): void {
    this.spinnerShow = 'block';
    let selectedOrder = this.processOrderList.find((x) => x.PPDId == this.groupDataForm.value.processOrder);
    this.groupDataForm.get('operationName')?.setValue(selectedOrder.Oper);
    this.groupDataForm.get('productDetail')?.setValue(selectedOrder.ProductDetail);
    this.PDtooltip = selectedOrder.ProductDetail;
    this.groupDataForm.get('itemCode')?.setValue(selectedOrder.ItemCode);
    this.groupDataForm.get('itemDescription')?.setValue(selectedOrder.ItemDesc);
    this.IDtooltip = selectedOrder.ItemDesc;
    this.groupDataForm.get('remarks')?.setValue(selectedOrder.Remarks);
    this.groupDataForm.get('status')?.setValue(selectedOrder.OperStatus);
    this.canMaterialConsume = (selectedOrder.OperStatus == 'Complete' || selectedOrder.OperStatus == 'Close') ? true : false;
    this.PPID = selectedOrder.PPDId;
    this.GetItemTypeByUnit();
    if(selectedOrder.PO.search('OL') == 0){
      this.isLoteCodeNecessary = true;
      this.materialConsumptionForm.get('lot').setValidators([Validators.required]);
      this.materialConsumptionForm.get('lot').updateValueAndValidity();
    }
    else{
      this.isLoteCodeNecessary = false;
      this.materialConsumptionForm.get('lot').clearValidators();
      this.materialConsumptionForm.get('lot').updateValueAndValidity();
    }
    this.RefreshMaterialConsumption()
  }

  GetItemTypeByUnit(): void {
    const key = {
      PUId: 0,
      BatchTypeId: 1,
      PPId: this.PPID,
      UserId: 0,
    };
    this.sfdService.GetItemTypeByUnit(key).subscribe((res: any) => {
      this.ItemTypeList = res.ItemTypeList;
      this.materialConsumptionForm.get('materialDetail').setValue(this.ItemTypeList[0]?.BatchItemTypeId);
      this.itemCode = this.ItemTypeList.find((x) => x.BatchItemTypeId === this.ItemTypeList[0]?.BatchItemTypeId)?.ItemCode;
      this.GetLableBatch(this.ItemTypeList[0]?.BatchItemTypeId);
    });
  }

  GetLableBatch(element: any): void {
    const key = {
      BatchTypeId: this.materialConsumptionForm.get('materialDetail')?.value,
      UserId: 0,
      PUID: 0,
      TypeId: 1,
    };
    this.sfdService.GetLableBatch(key).subscribe((res: any) => {
      this.materialConsumptionForm.get('qty')?.setValue(res.Qty);
      this.materialConsumptionForm.get('date')?.setValue(new Date());
      this.quantityUnit = res.Result;
      this.columnMaterialBatchHistory.forEach(function (colDef, index) {
        if (colDef.headerName === 'act') {
          colDef.headerName = 'Act Qty' + '(' + res.Result + ')';
        }
      });
      this.itemCode = 'Item Code: ' + this.ItemTypeList.find((x) => x.BatchItemTypeId === element)?.ItemCode;
      this.GetTotalBatch();
      this.GetJbBatchHistory(false);
    });
  }

  GetTotalBatch(): void {
    const key = {
      PPDID: this.PPID,
      BatchTypeId: 1,
      BatchItemTypeId: this.materialConsumptionForm.get('materialDetail')?.value,
      UserId: 0,
    };
    this.sfdService.GetTotalBatch(key).subscribe((res: any) => {
      this.total = res.ErrorMessage;
    });
  }

  GetJbBatchHistory(isHistory: boolean): void {
    const key = {
      PPDID: this.PPID,
      UserId: 0,
      Ishistory: isHistory,
      BatchTypeId: 1,
      JobBatchItemTypeId: this.materialConsumptionForm.get('materialDetail')?.value,
    };
    this.spinner.show();
    this.sfdService.GetJbBatchHistory(key).subscribe((res: any) => {
      isHistory ? this.rowDataMaterialBatchHistory = res.JbBatchHistoryList 
        : this.rowDataMaterialConsumption = res.JbBatchHistoryList;
      this.spinner.hide();
    });
  }

  openHistorybatchModal(element: any): void {
    if (this.groupDataForm.value.processOrder) {
      this.dialog.open(this.historyBatch1Modal, {
        width: '90vw !important',
        minWidth: '90vw',
        height: 'auto',
      });
      this.GetJbBatchHistory(true);
    } else {
      this.toaster.warning('Warning', 'Please Select Process Order');
      this.rowDataMaterialBatchHistory = [];
    }
  }

  RefreshMaterialConsumption(): void {
    this.materialConsumptionForm.get('actQty')?.reset();
    this.materialConsumptionForm.get('remarks')?.reset();
    this.JobBatchId = 0;
  }

  editMaterialConsumptionHistoryBatch(element: any): void {
    this.isLoteCodeNecessary ? this.materialConsumptionForm.get('lot')?.setValue(element.data.BatchNo) : '';
    this.materialConsumptionForm.get('actQty')?.setValue(element.data.Result);
    this.materialConsumptionForm.get('remarks')?.setValue(element.data.Remarks);
    this.materialConsumptionForm.get('date')?.setValue(element.data.RollStarttime);
    this.JobBatchId = element.data.JobBatchId;
    this.dialog.closeAll();
  }

  SaveJBBatchInfoForMaterialConsumption(): void {
    const key = {
      TransactionTypeId: 1,
      PPDID: this.PPID,
      JobBatchId: this.JobBatchId,
      BatchTypeId: 1,
      RollNo: 0,
      Result: this.materialConsumptionForm.value.actQty,
      BatchNo: this.isLoteCodeNecessary ? this.materialConsumptionForm.value.lot : '',
      BatchItemTypeId: this.materialConsumptionForm.value.materialDetail,
      Remarks: this.materialConsumptionForm.value.remarks,
      RollStarttime: this.materialConsumptionForm.value.date,
      UserId: 0,
    };
    this.sfdService.SaveJBBatchInfo(key).subscribe(
      (res: any) => {
        this.showPopupMsg(res.ErrorMessage);
        this.GetTotalBatch();
        this.GetJbBatchHistory(false);
        this.materialConsumptionForm.get('date')?.setValue(new Date());
        this.materialConsumptionForm.get('materialDetail')?.setValue(this.ItemTypeList[0].BatchItemTypeId);
        this.materialConsumptionForm.get('lot')?.reset();
        this.materialConsumptionForm.get('actQty')?.reset();
        this.materialConsumptionForm.get('remarks')?.reset();
      },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  UpdateJBBatchInfoForMaterialConsumption(): void {
    const key = {
      TransactionTypeId: 2,
      PPDID: this.PPID,
      JobBatchId: this.JobBatchId,
      BatchTypeId: 1,
      RollNo: 0,
      Result: this.materialConsumptionForm.value.actQty,
      BatchNo: this.isLoteCodeNecessary ? this.materialConsumptionForm.value.lot : '',
      BatchItemTypeId: this.materialConsumptionForm.value.materialDetail,
      Remarks: this.materialConsumptionForm.value.remarks,
      RollStarttime: this.materialConsumptionForm.value.date,
      UserId: 0,
    };
    this.sfdService.SaveJBBatchInfo(key).subscribe(
      (res: any) => {
        this.showPopupMsg(res.ErrorMessage);
        this.GetTotalBatch();
        this.GetJbBatchHistory(false);
        this.materialConsumptionForm.get('date')?.setValue(new Date());
        this.materialConsumptionForm.get('materialDetail')?.setValue(this.ItemTypeList[0].BatchItemTypeId);
        this.materialConsumptionForm.get('lot')?.reset();
        this.materialConsumptionForm.get('actQty')?.reset();
        this.materialConsumptionForm.get('remarks')?.reset();
        this.JobBatchId = 0;
      },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  showPopupMsg(msg: any): any {
    if (msg !== null || msg !== '') {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
      } else {
        this.toaster.error('Error', msg.substring(3, msg.length));
      }
    }
  }

  onGridReady(params: any): any {
    params.api.sizeColumnsToFit();
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  //================================================ Production Output code here ============================//
  
  loadBaseDetails(){
    const data = {
      UserId: JSON.parse(localStorage.user).UserId
     }
     this.opmsGlassservice.loadBaseDetails(data).subscribe(
       (response: any)=>{
        this.thicknessList = response.ThicknessList;
        this.shiftList = response.ShiftList;
        this.crewList = response.CrewList;
        this.qualityList = response.QualityList;
        this.PVBColorList = response.PVBColorList.filter(color => color.ColorType == PVBColorTypes.PVBColor);
        this.CoatedGlassColorList = response.PVBColorList.filter(color => color.ColorType == PVBColorTypes.CoatedGlassColor);
        this.manualStackerFromProductionOutput.get('shift')?.setValue("0");
        this.manualStackerFromProductionOutput.get('crew')?.setValue("0");
        this.manualStackerFromProductionOutputReport.get('shift')?.setValue("0");
        this.manualStackerFromProductionOutputReport.get('crew')?.setValue("0");
       },
     )
  }

  getStackerList(){
    this.sfdService.GetERPMachineList().subscribe(
      (response: any) => {
        if(!response.HasError){
          if(response.result.length){
            this.machineNameForSearchFilter = [];
            if(!this.userRoles.length)       
              this.machineNameForSearchFilter.push(...response.result);
            else{
              if(this.userRoles.includes(rolesForMaterialConsumptionScreen.GlassLaminationRole))
                this.machineNameForSearchFilter.push(...response.result.filter(row => row.MachineName == 'Lamination'));
              if(this.userRoles.includes(rolesForMaterialConsumptionScreen.GlassOfflineRole))
                this.machineNameForSearchFilter.push(...response.result.filter(row => row.MachineName == 'Offline'));
              if(this.userRoles.includes(rolesForMaterialConsumptionScreen.GlassManufacturingRole))
                this.machineNameForSearchFilter.push(...response.result.filter(row => row.MachineName != 'Lamination' && row.MachineName != 'Offline'));
            }
            this.manualStackerFromProductionOutput.get('stacker')?.setValue(this.machineNameForSearchFilter);
          }
        }else{
          this.toaster.error(response.result[0].Msg);
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.spinner.hide();
      }
    )
  }

  getPOListByDateRangeForProductionOutput(isGridDataRequest: boolean = false){
    this.POListForProductionOutput = [];
    this.POListForProductionOutputSearchFilter = [];
    this.filterPOListForProductionOutput = [];
    const params = {
      PlantId: this.groupDataForm.get('plant')?.value,
      StartDate: this.manualStackerFromProductionOutput.get('startDate')?.value,
      EndDate: this.manualStackerFromProductionOutput.get('endDate')?.value,
    };
    this.spinnerShow = 'none';
    this.spinner.show();
    this.sfdService.GetPOListByDateRangeForProductionOutput(params).subscribe(
      (response: any) => {
        this.spinner.hide();
        if(!response.HasError){
          if(response.result.length){
            const obj = 
              {
                PPDId: 0,
                PO: 'Not Available',
                OperDesc: null, 
                ItemDesc: null,
                ItemCode: null,
              }
            this.POListForProductionOutputSearchFilter = this.commonMethodsForPOService.getFilteredPOList(response.result,this.userRoles);
            this.POListForProductionOutput = this.commonMethodsForPOService.getFilteredPOList(response.resultWithStatus,this.userRoles);
            this.POListForProductionOutputSearchFilter.splice(0,0,obj);
            this.POListForProductionOutput.splice(0,0,obj);
            this.filterPOListForProductionOutput = this.POListForProductionOutput.slice();
            this.manualStackerFromProductionOutput.get('job_no')?.setValue(this.POListForProductionOutputSearchFilter); 
            if(isGridDataRequest)
              this.getManualProductionOutput();    
          }
          else{
            this.manualStackerFromProductionOutput.get('job_no').setValue([]);
            this.toaster.warning('No PO Available for Selected Date Range','Warning!');
          }
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.error);
      }
    )
  }

  onGridReadyProductionOutput(params: any){
    this.gridReadyProductionOutput = true;
    this.gridApiForManualProductionOutput = params.api;
    this.gridColumnApiForManualProductionOutput = params.columnApi;
    this.gridApiForManualProductionOutput.sizeColumnsToFit();
  }

  dateFormatter(params: any): any {
    if(params.value && params.data.GeneralBatchNo != ''){
      return moment(new Date(params.value)).format('MM/DD/YYYY hh:mm a');
    }
    else{
      return '';
    }
  }

  openManualStackerModel(){
    if(this.POListForProductionOutputSearchFilter.length == 0){
      this.toaster.warning('No PO Available for Selected Date Range','Warning!');
      return;
    }
    this.machineNameForManualProductionEntry = this.machineNameForSearchFilter.slice();
    this.isManualProductionId = 0;
    this.oldActualBatchNo = '';
    this.oldOrderNo = '';
    this.isRowPrinted = 0;
    this.isClicked = false;
    this.dialog.open(this.manualStackerModel, {
      width: '80vw !important',
      height: '65vh', minWidth: '80vw'
    });
    this.resetManualStackerForProductionOutputForm();
  }

  getBaseDetailsForProductionOutput(params: boolean, needApiCall: string = 'yes'){
    if(this.userRoles.includes(rolesForMaterialConsumptionScreen.GlassLaminationRole) && this.userRoles.length == 1 && needApiCall == 'no')
      return;
    if(this.addManualStackerForProductionOutput.get('OrderNumber')?.value != 0 && needApiCall == 'no'){
      const PO = this.POListForProductionOutputSearchFilter.find(
        (order: any) => (this.addManualStackerForProductionOutput.get('OrderNumber')?.value == order.PPDId 
                        || this.addManualStackerForProductionOutput.get('OrderNumber')?.value == order.PO)
      )
      if(this.commonMethodsForPOService.POTypeCheck(PO?.PO,LaminationPOType.split(',')))
        return;
    }
    const data = {
      Date: this.addManualStackerForProductionOutput.get('manualStackerCreationDate')?.value,
      ShiftId: params ? this.addManualStackerForProductionOutput.get('shift')?.value : 0
     }
     this.sfdService.GetBaseDetailsForProductionOutput(data).subscribe(
       (response: any)=>{
        if(this.isManualProductionId == 0){
          this.addManualStackerForProductionOutput.get('GeneralBatchNumber')?.setValue('BatchNo_'+response.TotalBatchNo);
          if(this.userRoles.includes(rolesForMaterialConsumptionScreen.GlassLaminationRole) && this.userRoles.length == 1){
            this.shiftListForManualEntry = this.shiftList.slice();
            this.crewListForManualEntry = this.crewList.slice();
            return;
          }
        }
        if(!params){
          this.shiftListForManualEntry = [];
          this.crewListForManualEntry = [];
          this.shiftListForManualEntry = response.ShiftList;
          this.crewListForManualEntry = response.CrewList;
          if(this.shiftListForManualEntry.length && this.crewListForManualEntry.length){
            this.addManualStackerForProductionOutput.get('shift')?.setValue('');
            this.addManualStackerForProductionOutput.get('crew')?.setValue('');
          }
          else{
            this.toaster.warning('Warning','No Shift or Crew available for selected Date')
          }
        }
        else{
          this.crewListForManualEntry = [];
          this.crewListForManualEntry = response.CrewList;
          this.addManualStackerForProductionOutput.get('crew')?.setValue(this.crewListForManualEntry[0].TypeId);
        }
       },
     )
  }

  /**
   * Funtion used for getting deatils of Order number selected
   */
  isPOSelected(){
    this.spinnerShow = 'block';
    this.spinner.show();
    if(this.addManualStackerForProductionOutput.get('OrderNumber')?.value == 0){
      this.isPOAvailable = false;
      this.spinner.hide();
      this.shiftListForManualEntry = [];
      this.crewListForManualEntry = [];
      this.getBaseDetailsForProductionOutput(false);
    }
    else{
      const PO = this.POListForProductionOutput.find(
        (order: any) => this.addManualStackerForProductionOutput.get('OrderNumber')?.value == order.PPDId 
      )
      const data = {
        PPDID: this.addManualStackerForProductionOutput.get('OrderNumber')?.value,
       }
      this.sfdService.GetPODetail(data).subscribe((res: any) => {
        /**
         * Logic for Values Calculation according to role specified order number
         * like Thickness, size and Branding etc.
         */
        this.selectedPOForManualProductionOutput = res.data[0];
        this.addManualStackerForProductionOutput.get('Size')?.setValue(this.commonMethodsForPOService.thicknessAndSizeCalculation(PO,'size'));
        this.addManualStackerForProductionOutput.get('Thickness')?.setValue(this.commonMethodsForPOService.thicknessAndSizeCalculation(PO,'thickness'));
        if(!this.commonMethodsForPOService.POTypeCheck(PO.PO,LaminationPOType.split(','))){
          this.laminationBranding = false;
          this.isCoatedGlass = false;
          this.addManualStackerForProductionOutput.get('PackingType')?.setValue(res.data[0].PackingType);
          this.addManualStackerForProductionOutput.get('Standard')?.setValue(res.data[0]?.EffUnit.split('-')[0]);
          this.addManualStackerForProductionOutput.get('Branding')?.setValue(res.data[0]?.EffUnit.split('-')[4] == 'BN' ? 'B' : 'N');
          this.addManualStackerForProductionOutput.get('Quality')?.setValue(
            this.qualityList.find((qty: any) => res.data[0]?.EffUnit.split('-')[1] == qty.QualityName)?.QualityValue
          );
          this.shiftListForManualEntry = [];
          this.crewListForManualEntry = [];
          this.getBaseDetailsForProductionOutput(false);
        }
        else if(this.commonMethodsForPOService.POTypeCheck(PO.PO,LaminationPOType.split(','))){
          this.laminationBranding = true;
          this.addManualStackerForProductionOutput.get('PackingType')?.setValue(res.data[0].PackingType);
          this.addManualStackerForProductionOutput.get('Standard')?.setValue((this.getPVBColorAndValidateColorFieldForProductionOutput(res.data[0],PVBColorTypes.PVBColor))?.TypeId);
          if(res.data[0].ItemCode.length > 21){
            this.isCoatedGlass = true;
            this.addManualStackerForProductionOutput.get('CoatedGlassColor')?.setValue((this.getPVBColorAndValidateColorFieldForProductionOutput(res.data[0],PVBColorTypes.CoatedGlassColor))?.TypeId);
          }
          else
            this.isCoatedGlass = false;
          this.addManualStackerForProductionOutput.get('Branding')?.setValue('N');
          this.addManualStackerForProductionOutput.get('Quality')?.setValue(res.data[0]?.EffUnit.split('-')[1]);
          this.shiftListForManualEntry = this.shiftList.slice();
          this.crewListForManualEntry = this.crewList.slice();
          this.addManualStackerForProductionOutput.get('shift')?.setValue('');
          this.addManualStackerForProductionOutput.get('crew')?.setValue('');
        }
        this.isPOAvailable = true;
        res.data[0].OrderNumber = PO.PO;
        this.resultERP = this.commonMethodsForPOService.resultERPCalculation(res.data[0],this.thicknessList);
        this.addManualStackerForProductionOutput.get('ResultERP')?.setValue(this.resultERP?.toFixed(4));
        this.addManualStackerForProductionOutput.get('LiteCountERP')?.setValue(res.data[0].LiteCountErp);
        let actualBatchNumber = '';
        if(this.oldActualBatchNo != '' && this.oldOrderNo != '' && PO.PO == this.oldOrderNo){
          actualBatchNumber = PO.PO+'_'+this.oldActualBatchNo;
        }
        else{
          actualBatchNumber = PO.PO+'_'+res.data[0].LastActualBatchNumber;
        }
        this.addManualStackerForProductionOutput.get('ActualBatchNumber')?.setValue(actualBatchNumber);
        this.spinner.hide();
      });
    }
    this.updateMachineNameForManualProductionEntry();
  }

  getPVBColorAndValidateColorFieldForProductionOutput(params: any,type: string){
    let colorCode;
    if(type == PVBColorTypes.PVBColor){
      colorCode = this.PVBColorList.find(
        (color) => (color.ColorCode == params.ItemCode.substr(1,2) && color.ColorType == PVBColorTypes.PVBColor)
      )
      if(colorCode?.ColorCode < 2 || colorCode?.ColorCode > 8)
        this.PVBColorFieldDisable = false;
      else
        this.PVBColorFieldDisable = true;
      if(params?.PVBColorCode){
        colorCode = this.PVBColorList.find(
          (color) => (color.TypeId == params?.PVBColorCode)
        )
      }
      return colorCode;
    }
    if(type == PVBColorTypes.CoatedGlassColor){
      colorCode = this.CoatedGlassColorList.find(
        (color) => (color.ColorCode == params.ItemCode.substr(22,3) && color.ColorType == PVBColorTypes.CoatedGlassColor)
      )
      return colorCode;
    }
  }

  /**
   * Funtion used for Update Machine List according to PO selected while adding data
   * return filtered Machine name list
   */
  updateMachineNameForManualProductionEntry(){
    this.machineNameForManualProductionEntry = [];
    const PO = this.POListForProductionOutput.find(
      (order: any) => this.addManualStackerForProductionOutput.get('OrderNumber')?.value == order.PPDId 
                      || this.addManualStackerForProductionOutput.get('OrderNumber')?.value == order.PO)
    if(!this.userRoles.length && this.addManualStackerForProductionOutput.get('OrderNumber')?.value == 0){
      this.machineNameForManualProductionEntry = this.machineNameForSearchFilter.slice();
    }
    else{
      if(this.addManualStackerForProductionOutput.get('OrderNumber')?.value == 0){
        this.machineNameForManualProductionEntry = this.machineNameForSearchFilter.slice();
      }
      if(this.commonMethodsForPOService.POTypeCheck(PO.PO,LaminationPOType.split(','))){
        this.machineNameForManualProductionEntry = this.machineNameForSearchFilter.filter(row => row.MachineName == 'Lamination');
      }
      if(this.commonMethodsForPOService.POTypeCheck(PO.PO,OfflinePOType.split(','))){
        this.machineNameForManualProductionEntry = this.machineNameForSearchFilter.filter(row => row.MachineName == 'Offline');
      }
      if(this.commonMethodsForPOService.POTypeCheck(PO.PO,GlassPOType.split(','))){
        this.machineNameForManualProductionEntry = this.machineNameForSearchFilter.filter(row => row.MachineName !== 'Lamination' && row.MachineName !== 'Offline');
      }
    }
    this.addManualStackerForProductionOutput.get('stacker')?.setValue(this.machineNameForManualProductionEntry[0]?.MachineName)
  }

  submitManualProductionOutput(){
    this.isClicked = true;
    if(this.addManualStackerForProductionOutput.get('shift')?.value && 
      this.addManualStackerForProductionOutput.get('crew')?.value){
      if((this.addManualStackerForProductionOutput.get('NoOfPallets').value == 0 || 
      this.addManualStackerForProductionOutput.get('NoOfPallets').value == null) && this.isManualProductionId == 0){
        this.toaster.warning('No of Pallets Should be greater than 0','Warning!');
        this.isClicked = false;
        return;
      }
      if(!this.isPOAvailable){
        var data = {
          PPD_ID: this.addManualStackerForProductionOutput.get('OrderNumber')?.value,
          Date: this.addManualStackerForProductionOutput.get('manualStackerCreationDate')?.value,
          StackerName: this.addManualStackerForProductionOutput.get('stacker')?.value,
          OrderNumber: null,
          ShiftId: this.addManualStackerForProductionOutput.get('shift')?.value,
          CrewId: this.addManualStackerForProductionOutput.get('crew')?.value,
          GeneralBatchNo: this.addManualStackerForProductionOutput.get('GeneralBatchNumber')?.value,
          ActualBatchNo: null,
          UserId: JSON.parse(localStorage.user).UserId,
          IsManualProductionId: this.isManualProductionId,
          NoOfPallets: this.addManualStackerForProductionOutput.get('NoOfPallets').value,
          Result: this.addManualStackerForProductionOutput.get('ResultERP').value,
          IsResultChange: false,
          Branding: null,
          PVBColorCode: null,
          SQM: null
        }
      }
      else{
        if(this.addManualStackerForProductionOutput.get('ResultERP').value === '' 
          || this.addManualStackerForProductionOutput.get('LiteCountERP').value == ''){
          this.toaster.warning('Data Cannot be saved for Empty Litecount or Result Value','Warning!');
          this.isClicked = false;
          return;
        }
        if(this.oldResultERP != this.addManualStackerForProductionOutput.get('ResultERP').value && this.rowHasOrderNumber == true
            && this.addManualStackerForProductionOutput.get('ResultERP').value > 0 && this.isManualProductionId != 0){
            this.toaster.warning('ResultERP value Should be 0 or -ve Value','Warning!');
            this.isClicked = false;
            return;
        }
        const PO = this.POListForProductionOutputSearchFilter.find(
          (order: any) => this.isManualProductionId == 0 || this.rowHasOrderNumber == false ? 
                          this.addManualStackerForProductionOutput.get('OrderNumber')?.value == order.PPDId :
                          this.addManualStackerForProductionOutput.get('OrderNumber')?.value == order.PO
        )
        data = {
          PPD_ID: this.isManualProductionId == 0 || this.rowHasOrderNumber == false ? 
                  this.addManualStackerForProductionOutput.get('OrderNumber')?.value :
                  PO.PPDId,
          Date: this.addManualStackerForProductionOutput.get('manualStackerCreationDate')?.value,
          StackerName: this.addManualStackerForProductionOutput.get('stacker')?.value,
          OrderNumber: PO.PO,
          ShiftId: this.addManualStackerForProductionOutput.get('shift')?.value,
          CrewId: this.addManualStackerForProductionOutput.get('crew')?.value,
          GeneralBatchNo: this.addManualStackerForProductionOutput.get('GeneralBatchNumber')?.value,
          ActualBatchNo: this.addManualStackerForProductionOutput.get('ActualBatchNumber')?.value,
          UserId: JSON.parse(localStorage.user).UserId,
          IsManualProductionId: this.isManualProductionId,
          NoOfPallets: this.addManualStackerForProductionOutput.get('NoOfPallets').value,
          Result: this.addManualStackerForProductionOutput.get('ResultERP').value <= 0 ? 
                  this.addManualStackerForProductionOutput.get('ResultERP').value : this.resultERP,
          IsResultChange: this.addManualStackerForProductionOutput.get('ResultERP').value == this.oldResultERP ? false : true,
          Branding: this.laminationBranding ? this.addManualStackerForProductionOutput.get('Branding').value : null,
          PVBColorCode: this.laminationBranding ? this.addManualStackerForProductionOutput.get('Standard').value : null,
          SQM: this.commonMethodsForPOService.resultSQMTCalculation(this.selectedPOForManualProductionOutput)
        }
      }
      this.sfdService.SubmitManualProductionOutput(data).subscribe(
        (res: any) => {
          if(!res.HasError){
            if(res.data.length){
              this.toaster.success('Success',res.data[0].Msg);
              this.getManualProductionOutput();
              this.dialog.closeAll();
              this.isClicked = false;
            }
          }
          else{
            this.toaster.error('Error',res.data[0].Msg);
            this.isClicked = false;
          }
        },
        (error: any) => {
          this.toaster.error(error.error);
          this.isClicked = false;
        }
      );
    }
    else{
      this.toaster.warning('Please Select Shift and Crew','Warning!')
    }
  }

  getManualProductionOutput(){
    this.spinnerShow = 'none';
    this.spinner.show();
    this.rowProductionOutput = [];
    this.selectedRowsForLinkingPO = [];
    this.ERPDataForSannad = [];
    this.selectedRowsMachineName = [];
    if((this.manualStackerFromProductionOutput.get('job_no')?.value).length && 
       (this.manualStackerFromProductionOutput.get('stacker')?.value).length){
      let shift: any = [];
      let crew: any = [];
      if (this.manualStackerFromProductionOutput.get('shift')?.value == 0) {
        for (var i = 0; i < this.shiftList.length; i++) {
          shift.push(this.shiftList[i].TypeId);
        }
      } else {
        shift.push(this.manualStackerFromProductionOutput.get('shift')?.value);
      }
      if (this.manualStackerFromProductionOutput.get('crew')?.value == 0) {
        for (var i = 0; i < this.crewList.length; i++) {
          crew.push(this.crewList[i].TypeId);
        }
      } else {
        crew.push(this.manualStackerFromProductionOutput.get('crew')?.value);
      }
      let data = {
        StartDate: this.manualStackerFromProductionOutput.get('startDate')?.value,
        EndDate: this.manualStackerFromProductionOutput.get('endDate')?.value,
        StackerName: (this.manualStackerFromProductionOutput.get('stacker')?.value).map(s => s.MachineName).toString(),
        OrderNumber: (this.manualStackerFromProductionOutput.get('job_no')?.value).map(j => j.PPDId).toString(),
        ShiftId: shift.toString(),
        CrewId: crew.toString()
      }
      this.sfdService.GetManualProductionOutput(data).subscribe(
        (res: any) => {
          if(!res.HasError){
            if(res.data.length){
              this.tempDataForProductionOutput = res.data;
              this.isDataAsc = false;
              this.togglePrintedAndAllDataForProductionOutput()
            }
            else{
              this.toaster.warning('No Data Found for Production Output','Warning!');
            }
          }
          this.spinner.hide();
        },
        (error: any) => {
          this.toaster.error(error.error)
          this.spinner.hide();
        }
      );
    }
    else{
      this.spinner.hide();
      this.toaster.warning('Please Select Stacker and Job No','Warning!')
    }
  }

  resetManualStackerForProductionOutputForm(){
    this.addManualStackerForProductionOutput.reset();
    this.isManualProductionId = 0;
    this.addManualStackerForProductionOutput.get('manualStackerCreationDate')?.setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    this.addManualStackerForProductionOutput.get('stacker')?.setValue(this.machineNameForSearchFilter[0].MachineName);
    this.addManualStackerForProductionOutput.get('OrderNumber')?.setValue(0);
    this.addManualStackerForProductionOutput.get('NoOfPallets')?.setValue(1);
    this.rowHasOrderNumber = false;
    this.isPOAvailable = false;
    this.isRowPrinted = 0;
    this.getBaseDetailsForProductionOutput(false);
  }

  oncellclicked(params: any): void {
    this.spinnerShow = 'block';
    const col = params.colDef.field;
    if (col === 'Actions') {
      const classes = params.event.target.classList.toString();
      if(classes.includes('delete_stacker')){
        if(this.isUserHasRoleOfDeleteManualProductionData)
          this.deleteManualProductionOutput(params.data);
        else{
          this.toaster.warning('Warning!','You are not Authorize to perform this task');
        }
        return;
      }
      this.spinner.show();
      if(classes.includes('edit_stacker')){
        if(this.POListForProductionOutputSearchFilter.length == 0){
          this.toaster.warning('No PO Available for Selected Date Range','Warning!');
          return;
        }
        this.isClicked = false;
        this.dialog.open(this.manualStackerModel, {
          width: '80vw !important',
          height: '65vh', minWidth: '80vw'
        });
        this.isRowPrinted = params.data.printStatus ? 1 : 0
        this.addManualStackerForProductionOutput.reset();
        this.addManualStackerForProductionOutput.get('NoOfPallets').setValue(0);
        this.isManualProductionId = params.data.ID;
        this.addManualStackerForProductionOutput.get('manualStackerCreationDate')?.setValue(params.data.Date);
        this.addManualStackerForProductionOutput.get('GeneralBatchNumber')?.setValue(params.data.GeneralBatchNo);
        if(params.data.PPD_ID == 0){
          this.isPOAvailable = false;
          this.rowHasOrderNumber = false;
          this.oldActualBatchNo = '';
          this.oldOrderNo = '';
          this.addManualStackerForProductionOutput.get('OrderNumber')?.setValue(params.data.PPD_ID);
          if(this.userRoles.includes(rolesForMaterialConsumptionScreen.GlassLaminationRole) && this.userRoles.length == 1){
            this.shiftListForManualEntry = this.shiftList.slice();
            this.crewListForManualEntry = this.crewList.slice();
            this.addManualStackerForProductionOutput.get('shift')?.setValue(params.data.ShiftId);
            this.addManualStackerForProductionOutput.get('crew')?.setValue(params.data.CrewId);
          }
          else{
            this.getBaseDetailsForProductionOutput(false);
            setTimeout(() => {
              this.addManualStackerForProductionOutput.get('shift')?.setValue(params.data.ShiftId);
              this.getBaseDetailsForProductionOutput(true);
            }, 2000);
            setTimeout(() => {
              this.addManualStackerForProductionOutput.get('crew')?.setValue(params.data.CrewId);
            }, 2000);
          }
          this.spinner.hide();
        }
        else{
          this.spinner.show();
          this.addManualStackerForProductionOutput.get('Size')?.setValue(this.commonMethodsForPOService.thicknessAndSizeCalculation(params.data,'size'));
          this.addManualStackerForProductionOutput.get('Thickness')?.setValue(this.commonMethodsForPOService.thicknessAndSizeCalculation(params.data,'thickness'));
          if(!this.commonMethodsForPOService.POTypeCheck(params.data.OrderNumber,LaminationPOType.split(','))){
            this.laminationBranding = false;
            this.isCoatedGlass = false;
            this.addManualStackerForProductionOutput.get('PackingType')?.setValue(params.data.PackingType);
            this.addManualStackerForProductionOutput.get('Standard')?.setValue(params.data?.EffUnit.split('-')[0]);
            this.addManualStackerForProductionOutput.get('Branding')?.setValue(params.data?.EffUnit.split('-')[4] == 'BN' ? 'B' : 'N');
            this.addManualStackerForProductionOutput.get('Quality')?.setValue(
              this.qualityList.find((qty: any) => params.data?.EffUnit.split('-')[1] == qty.QualityName)?.QualityValue
            );
          }
          else if(this.commonMethodsForPOService.POTypeCheck(params.data.OrderNumber,LaminationPOType.split(','))){
            this.laminationBranding = true;
            this.addManualStackerForProductionOutput.get('PackingType')?.setValue(params.data.PackingType);
            this.addManualStackerForProductionOutput.get('Standard')?.setValue((this.getPVBColorAndValidateColorFieldForProductionOutput(params.data,PVBColorTypes.PVBColor))?.TypeId);
            if(params.data.ItemCode.length > 21){
              this.isCoatedGlass = true;
              this.addManualStackerForProductionOutput.get('CoatedGlassColor')?.setValue((this.getPVBColorAndValidateColorFieldForProductionOutput(params.data,PVBColorTypes.CoatedGlassColor))?.TypeId);
            }
            else
              this.isCoatedGlass = false;
            this.addManualStackerForProductionOutput.get('Branding')?.setValue(params.data.Branding);
            this.addManualStackerForProductionOutput.get('Quality')?.setValue(params.data?.EffUnit.split('-')[1]);
          }
          this.isPOAvailable = true;
          this.rowHasOrderNumber = true;
          this.oldActualBatchNo = params.data.ActualBatchNo.split('_')[2];
          this.oldOrderNo = params.data.ActualBatchNo.split('_')[1];
          this.addManualStackerForProductionOutput.get('ActualBatchNumber')?.setValue(params.data.ActualBatchNo);
          this.addManualStackerForProductionOutput.get('LiteCountERP')?.setValue(params.data.LiteCountErp);
          this.addManualStackerForProductionOutput.get('OrderNumber')?.setValue(params.data.OrderNumber);
          if((this.userRoles.includes(rolesForMaterialConsumptionScreen.GlassLaminationRole) && this.userRoles.length == 1)
            || this.commonMethodsForPOService.POTypeCheck(params.data.OrderNumber,LaminationPOType.split(','))){
            this.shiftListForManualEntry = this.shiftList.slice();
            this.crewListForManualEntry = this.crewList.slice();
            this.addManualStackerForProductionOutput.get('shift')?.setValue(params.data.ShiftId);
            this.addManualStackerForProductionOutput.get('crew')?.setValue(params.data.CrewId);
          }
          else{
            this.getBaseDetailsForProductionOutput(false);
            setTimeout(() => {
              this.addManualStackerForProductionOutput.get('shift')?.setValue(params.data.ShiftId);
              this.getBaseDetailsForProductionOutput(true);
            }, 2000);
            setTimeout(() => {
              this.addManualStackerForProductionOutput.get('crew')?.setValue(params.data.CrewId);
            }, 2000);
          }
          this.oldResultERP = this.commonMethodsForPOService.resultERPCalculation(params.data,this.thicknessList)?.toFixed(4);
          this.addManualStackerForProductionOutput.get('ResultERP')?.setValue(this.oldResultERP);
          this.spinner.hide();
        }
        this.updateMachineNameForManualProductionEntry();
        this.addManualStackerForProductionOutput.get('stacker')?.setValue(params.data.StackerName);
      }
      else{
        if(classes.includes('withOutTin')){
          if(params.data.PPD_ID != 0){
            if(!this.commonMethodsForPOService.POTypeCheck(params.data.OrderNumber,LaminationPOType.split(','))){
              this.PrintBarCodeWithOutTinForGlassManufacturingOrders(params);
              return;
            }
            if(this.commonMethodsForPOService.POTypeCheck(params.data.OrderNumber,LaminationPOType.split(','))){
              this.PrintBarCodeWithOutTinForLaminationOrders(params);
              return;
            }
            this.toaster.warning('Warning','No Barcode Is Available For Selected Row');
          }
          else{
            this.toaster.warning('Warning','Data Row is not valid');
          }
        }
        else if(classes.includes('withTin')){
          if(params.data.PPD_ID != 0){
            if(!this.commonMethodsForPOService.POTypeCheck(params.data.OrderNumber,LaminationPOType.split(','))){
              this.PrintBarcodeWithTinForGlassManufacturingOrders(params); 
              return;
            }
            if(this.commonMethodsForPOService.POTypeCheck(params.data.OrderNumber,LaminationPOType.split(','))){
              this.PrintBarcodeWithTinForLaminationOrders(params);
              return;
            }
            this.toaster.warning('Warning','No Barcode Is Available For Selected Row');
          }
          else{
            this.toaster.warning('Warning','Data Row is not valid');
          }
        }
        this.spinner.hide();
      }
    }
  }

  deleteManualProductionOutput(params: any){
    if(params.ERPStatus){
      this.toaster.warning('Warning!','ERP Pushed data cannot be deleted');
      return;
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete this Row'+ (params.PPD_ID == 0 ? '.' : ' With PO ' + params.OrderNumber +' And Actual BatchNo ' + params.ActualBatchNo +'.'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.sfdService.DeleteManualProductionOutput({ManualProductionId: params.ID}).subscribe(
          (response: any) => {
            if(response.HasError){
              this.spinner.hide();
              this.toaster.error('Error',response.Msg);
            }
            else{
              this.toaster.success('Success!',response.Msg);
              this.getManualProductionOutput();
            }
          },
          (error: any) => {
            this.spinner.hide()
            this.toaster.error('Error!',error);
          }
        )
      }
    });
  }

  rowSelectionForProductionOutput(params: any) {     
    const selectedRows = params?.api?.rowModel?.rowsToDisplay.filter((node: any) => node.isSelected());
    const currentFocusedRow =  params?.api.getFocusedCell();
    const row = selectedRows.find(c=>c.rowIndex === currentFocusedRow.rowIndex);
    if(!row){
      if(this.selectedRowsForLinkingPO.length){
        this.selectedRowsForLinkingPO =  this.selectedRowsForLinkingPO.filter((linkPORow) => {
          return linkPORow.GridIndex != currentFocusedRow.rowIndex;
        });
      }
      if(this.ERPDataForSannad.length){
        this.ERPDataForSannad = this.ERPDataForSannad.filter((erpRow) => {
          return erpRow.GridIndex != currentFocusedRow.rowIndex;
        });
      }
      return;
    }
    if(selectedRows.length == 1){
      row.data.PPD_ID == 0 ? 
        this.selectedRowsForLinkingPO.push({ManualProductionId: row.data.ID,
                                            GridIndex: currentFocusedRow.rowIndex}) : 
        this.ERPDataForSannad.push({ManualProductionId: row.data.ID, 
                                    Result: this.commonMethodsForPOService.resultERPCalculation(row.data,this.thicknessList),
                                    ActualBatchNo: row.data.ActualBatchNo,
                                    CreatedOn: row.data.CreatedOn,
                                    PPD_ID: row.data.PPD_ID,
                                    GridIndex: currentFocusedRow.rowIndex,
                                    SQM: this.commonMethodsForPOService.resultSQMTCalculation(row.data).toString()});
      row.data.PPD_ID == 0 ?
        this.selectedRowsMachineName.push(row.data.StackerName == 'Lamination' ? true : false) : 
        this.selectedRowsMachineName = [];
    }
    else if(selectedRows.length > 1){
      if(this.selectedRowsForLinkingPO.length){
        if(row.data.PPD_ID != 0){
          row.setSelected(false);
          this.toaster.warning('Warning','Please Select only Invalid rows');
          return;
        }
        if((!this.selectedRowsMachineName[0] && row.data.StackerName == 'Lamination')
          || (this.selectedRowsMachineName[0] && row.data.StackerName != 'Lamination')){
          row.setSelected(false);
          this.toaster.warning('Warning','Please Select Unique Machine Name like Either Lamination or Stacker')
          return;
        }
        this.selectedRowsForLinkingPO.push({ManualProductionId: row.data.ID,
                                            GridIndex: currentFocusedRow.rowIndex});
      }
      else{
        if(row.data.PPD_ID == 0){
          row.setSelected(false);
          this.toaster.warning('Warning','Please Select only Printed rows');
        }
        else{
          this.ERPDataForSannad.push({ManualProductionId: row.data.ID, 
            Result: this.commonMethodsForPOService.resultERPCalculation(row.data,this.thicknessList),
            ActualBatchNo: row.data.ActualBatchNo,
            CreatedOn: row.data.CreatedOn,
            PPD_ID: row.data.PPD_ID,
            GridIndex: currentFocusedRow.rowIndex,
            SQM: this.commonMethodsForPOService.resultSQMTCalculation(row.data).toString()});
        }
      }
    }
    else{
      this.selectedRowsForLinkingPO = [];
      this.ERPDataForSannad = [];
      this.selectedRowsMachineName = [];
    }
  }

  /**
   * Funtion Used for Checking either Branding Field need to 
   * show or not according to Order number selected 
   * currently show for Lamination role only
   * return true if editable else false
   */
  isBrandingNeedToEnter(){
    const PO = this.POListForProductionOutput.find(
      (order: any) => this.linkingOrderNumberForProductionOutput.get('OrderNumber')?.value == order.PPDId 
    )
    if(this.commonMethodsForPOService.POTypeCheck(PO.PO,LaminationPOType.split(','))){
      this.laminationBranding = false;
      this.linkingOrderNumberForProductionOutput.get('Branding')?.setValue('N');
      this.linkingOrderNumberForProductionOutput.get('PVBColorCode')?.setValue((this.getPVBColorAndValidateColorFieldForProductionOutput(PO,PVBColorTypes.PVBColor))?.TypeId);
      if(PO.ItemCode.length > 21){
        this.isCoatedGlass = true;
        this.linkingOrderNumberForProductionOutput.get('CoatedGlassColor')?.setValue((this.getPVBColorAndValidateColorFieldForProductionOutput(PO,PVBColorTypes.CoatedGlassColor))?.TypeId);
      }
      else
        this.isCoatedGlass = false;
    } 
    else{
      this.laminationBranding = true;
      this.isCoatedGlass = false;
    }
  }

  linkOrderNumberForManualProductionOutput(){
    this.isClicked = false;
    this.dialog.open(this.OrderNumberLinkingModel, {
      width: '60vw !important',
      height: '50vh', minWidth: '50vw'
    }).afterClosed().subscribe(() => {
      this.laminationBranding = false;
      this.PVBColorFieldDisable = false;
    });
    const linkingPOListForProductionOutput = this.POListForProductionOutput.slice(1,this.POListForProductionOutput.length);
    this.linkingPOListForProductionOutput = [...this.commonMethodsForPOService.filterOrderListByRoleType(linkingPOListForProductionOutput,LaminationPOType.split(','))];
    if(!this.selectedRowsMachineName[0]){
      this.linkingPOListForProductionOutput = linkingPOListForProductionOutput.filter(PO => this.linkingPOListForProductionOutput.indexOf(PO) === -1);
    }
    this.linkingPOListForProductionOutput.length ? this.linkingPOListForProductionOutput.sort((index1,index2) => index2.PPDId - index1.PPDId) : [];
    this.filterLinkingPOListForProductionOutput = this.linkingPOListForProductionOutput.slice();
    this.linkingOrderNumberForProductionOutput.reset();
    this.laminationBranding = true;
  }

  submitOrderNumberForProductionOutput(){
    this.isClicked = true;
    const PO = this.POListForProductionOutput.find(
      (order: any) => this.linkingOrderNumberForProductionOutput.get('OrderNumber')?.value == order.PPDId 
    )
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Link '+this.selectedRowsForLinkingPO.length+' Rows to Order Number '+PO.PO+'.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinnerShow = 'none';
        this.spinner.show();
        const data = {
          ManualProductionID: this.selectedRowsForLinkingPO.map(obj => obj.ManualProductionId).toString(),
          PPD_ID: this.linkingOrderNumberForProductionOutput.get('OrderNumber')?.value,
          OrderNumber: PO.PO,
          UserId: JSON.parse(localStorage.user).UserId,
          Branding: !this.laminationBranding ? this.linkingOrderNumberForProductionOutput.get('Branding')?.value : null,
          PVBColorCode: !this.laminationBranding ? this.linkingOrderNumberForProductionOutput.get('PVBColorCode')?.value : null
        }
        this.sfdService.SubmitOrderNumberForProductionOutput(data).subscribe(
          (res: any) => {
            if(!res.HasError){
              if(res.data.length){
                this.toaster.success('Success',res.data[0].Msg);
                this.getManualProductionOutput();
                this.dialog.closeAll();
                this.isClicked = false;
              }
            }
            else{
              this.toaster.error('Error',res.data[0].Msg);
            }
            this.spinner.hide();
            this.selectedRowsForLinkingPO = [];
            this.ERPDataForSannad = [];
            this.selectedRowsMachineName = [];
          },
          (error: any) => {
            this.toaster.error(error.error.Message)
            this.spinner.hide();
            this.selectedRowsForLinkingPO = [];
            this.ERPDataForSannad = [];
          }
        );
      }
    });
  }

  updatePrintStatusForProductionOutput(params: any,type: boolean){
    const data = {
      ManualProductionID: type ? params.data.ID : -1,
      UserId: JSON.parse(localStorage.user).UserId,
      SannedData: JSON.stringify(this.ERPDataForSannad)
    }
    this.sfdService.UpdatePrintStatusForProductionOutput(data).subscribe(
      (response: any) => {
        this.toaster.success(response.result[0].Msg);
        this.getManualProductionOutput();
        this.ERPDataForSannad = [];
        this.selectedRowsForLinkingPO = [];
      },
      (error: any) => {
        this.toaster.error('Server Side Error')
        this.spinner.hide();
        this.ERPDataForSannad = [];
        this.selectedRowsForLinkingPO = [];
      }
    )
  }

  togglePrintedAndAllDataForProductionOutput(){
    this.selectedRowsForLinkingPO = [];
    this.ERPDataForSannad = [];
    this.selectedRowsMachineName = [];
    this.selectedRowsForLinkingPO = [];
    let filtereddata = [];
    if(this.toggleStatus){
      filtereddata = this.tempDataForProductionOutput.filter((row) => row.printStatus);
    }
    else{
      filtereddata = this.tempDataForProductionOutput.slice();
    }
    const data = filtereddata;
    if(filtereddata.length){
      let totalVerifiedWeight = 0;
      let totalVerifiedTags = 0;
      filtereddata.forEach(element => {
        const result = element?.PPD_ID != 0 ? this.commonMethodsForPOService.resultERPCalculation(element,this.thicknessList)?.toFixed(4) : '';
        element.ResultErp = result;
        element.CreatedOn = element.CreatedOn ? moment(new Date(element.CreatedOn)).utc(false).format('MM/DD/YYYY hh:mm a') : '';
        element.size = element?.PPD_ID != 0 ? this.commonMethodsForPOService.thicknessAndSizeCalculation(element,'size') : '';
        element.thickness = element?.PPD_ID != 0 ? this.commonMethodsForPOService.thicknessAndSizeCalculation(element,'thickness') : '';
        /**
         * return Total Value For Grid View
         */
        if(element.printStatus == 1 && (result > 0) && element.ERPStatus == 1){
          totalVerifiedWeight = totalVerifiedWeight + parseFloat(result);
          totalVerifiedTags = totalVerifiedTags + 1;
        }
      });
      const obj = {
        "Actions" : 'Total Verified Tags',
        "ID": 0,
        "PPD_ID": 0,
        "Date": "",
        "StackerName": "",
        "OrderNumber": "",
        "ShiftId": 0,
        "Shift": "",
        "CrewId": 0,
        "Crew": "",
        "GeneralBatchNo": "",
        "ActualBatchNo": "",
        "LiteCountErp": '',
        "ItemDesc": "",
        "ItemCode": "",
        "CreatedOn": "",
        "printStatus": false,
        "error": false,
        "ResultValidity": 0,
        "ResultErp": totalVerifiedTags,
      }
      const obj1 = {
        "Actions" : 'Total Verified Weight',
        "ID": 0,
        "PPD_ID": 0,
        "Date": "",
        "StackerName": "",
        "OrderNumber": "",
        "ShiftId": 0,
        "Shift": "",
        "CrewId": 0,
        "Crew": "",
        "GeneralBatchNo": "",
        "ActualBatchNo": "",
        "LiteCountErp": '',
        "ItemDesc": "",
        "ItemCode": "",
        "CreatedOn": "",
        "printStatus": false,
        "error": false,
        "ResultValidity": 0,
        "TotalVerifiedTags": '',
        "ResultErp": totalVerifiedWeight.toFixed(4)
      }
      data.push(obj);
      data.push(obj1);
    }
    this.sortGridDataForActalBatch(this.isDataAsc ? 'Asc' : 'Desc',data,'ProductionOutput');
  }

  sortGridDataForActalBatch(sortType: string, data: any, sectionName: string){
    sectionName == 'ProductionOutput' ? this.gridApiForManualProductionOutput.setRowData([]) : this.gridApiForManualProductionOutputReport.setRowData([]);
    let tempOrderNumber = [];
    let tempActualBatch = [];
    let actualSortedData = [];
    data.forEach(element => {
      const num = element.OrderNumber ? element.OrderNumber.match(/\d/g) : 0;
      if(num != 0){
        tempOrderNumber.push(num.join(''));
      }
    });
    tempOrderNumber = [...new Set(tempOrderNumber)];
    if(sortType =='Desc'){
      tempOrderNumber.sort((a,b) => b-a);
      for (let index = 0; index < tempOrderNumber.length; index++) {
        tempOrderNumber[index] = data.find(element => (element.OrderNumber && 
            element.OrderNumber.includes(element.OrderNumber.includes('-') ? tempOrderNumber[index].slice(0,-1) : tempOrderNumber[index])))?.OrderNumber;
      }
      for (let index = 0; index < tempOrderNumber.length; index++) {
        const element = data.filter(element => (element.ActualBatchNo &&
            element.ActualBatchNo.includes(element.OrderNumber.includes('-') ? tempOrderNumber[index].slice(0,-1) : tempOrderNumber[index])));
        element.forEach(element => {
          const num = element.ActualBatchNo.split('_').length == 3 ? element.ActualBatchNo.split('_')[2] : element.ActualBatchNo.split('_')[1];
          tempActualBatch.push(num);
        });
        tempActualBatch.sort((a,b) => b-a);
        tempActualBatch = [...new Set(tempActualBatch)];
        for (let index1 = 0; index1 < tempActualBatch.length; index1++) {
          const row = element.filter(element => (element.ActualBatchNo.split('_').length == 3 ? (element.ActualBatchNo.split('_')[2]) : element.ActualBatchNo.split('_')[1]) == tempActualBatch[index1]);
          actualSortedData = [...actualSortedData,...row];
        }
        tempActualBatch = [];
      }
    }
    else{
      tempOrderNumber.sort((a,b) => a-b);
      for (let index = 0; index < tempOrderNumber.length; index++) {
        tempOrderNumber[index] = data.find(element => (element.OrderNumber && 
            element.OrderNumber.includes(element.OrderNumber.includes('-') ? tempOrderNumber[index].slice(0,-1) : tempOrderNumber[index])))?.OrderNumber;
      }
      for (let index = 0; index < tempOrderNumber.length; index++) {
        const element = data.filter(element => (element.ActualBatchNo && 
          element.ActualBatchNo.includes(element.OrderNumber.includes('-') ? tempOrderNumber[index].slice(0,-1) : tempOrderNumber[index])));
        element.forEach(element => {
          const num = element.ActualBatchNo.split('_').length == 3 ? element.ActualBatchNo.split('_')[2] : element.ActualBatchNo.split('_')[1];
          tempActualBatch.push(num);
        });
        tempActualBatch.sort((a,b) => a-b);
        tempActualBatch = [...new Set(tempActualBatch)];
        for (let index1 = 0; index1 < tempActualBatch.length; index1++) {
          const row = element.filter(element => (element.ActualBatchNo.split('_').length == 3 ? element.ActualBatchNo.split('_')[2] : element.ActualBatchNo.split('_')[1]) == tempActualBatch[index1]);
          actualSortedData = [...actualSortedData,...row];
        }
        tempActualBatch = [];
      }
    }
    data.forEach(element => {
      if(element.PPD_ID == 0){
        actualSortedData.push(element);
      }
    });
    if(sectionName == 'ProductionOutput'){
      this.isDataAsc = sortType == 'Desc' ? false : true;
      this.rowProductionOutput = actualSortedData;
      this.gridApiForManualProductionOutput.setRowData(this.rowProductionOutput); 
      this.selectedRowsForLinkingPO = [];
      this.ERPDataForSannad = [];
      this.selectedRowsMachineName = [];
      this.selectedRowsForLinkingPO = [];
    }
    else{
      let totalWeight = 0;
      actualSortedData.forEach(element => {
        const result = element.PPD_ID != 0 ? this.commonMethodsForPOService.resultERPCalculation(element,this.thicknessList)?.toFixed(4) : '';
        sectionName == 'cards' && result ? totalWeight = totalWeight + parseFloat(result) : '';
        element.ResultErp = result;
        element.CreatedOn = element.CreatedOn ? moment(new Date(element.CreatedOn)).utc(false).format('MM/DD/YYYY hh:mm a') : '';
        element.size = element?.PPD_ID != 0 ? this.commonMethodsForPOService.thicknessAndSizeCalculation(element,'size') : '';
        element.thickness = element?.PPD_ID != 0 ? this.commonMethodsForPOService.thicknessAndSizeCalculation(element,'thickness') : '';
      })
      const obj = {
        "ID": 0,
        "PPD_ID": 0,
        "Date": "Total Weight",
        "StackerName": "",
        "OrderNumber": "",
        "ShiftId": 0,
        "Shift": "",
        "CrewId": 0,
        "Crew": "",
        "GeneralBatchNo": "",
        "ActualBatchNo": "",
        "LiteCountErp": '',
        "ItemDesc": "",
        "ItemCode": "",
        "CreatedOn": "",
        "printStatus": false,
        "error": false,
        "ResultValidity": 0,
        "ResultErp": totalWeight.toFixed(4)
      }
      if(sectionName != 'cards'){
        this.isDataAscReport = sortType == 'Desc' ? false : true;
      }
      else{
        actualSortedData.length ? actualSortedData.push(obj) : '';
      }
      this.rowProductionOutputReport = actualSortedData;
      this.gridApiForManualProductionOutputReport.setRowData(this.rowProductionOutputReport);
    }
  }

  onGridReadyProductionOutputReport(params: any){
    this.gridReadyProductionOutputReport = true;
    this.gridApiForManualProductionOutputReport = params.api;
    this.gridColumnApiForManualProductionOutputReport = params.columnApi;
    this.gridApiForManualProductionOutputReport.sizeColumnsToFit();
  }

  getPOListByDateRangeForProductionOutputReport(isGridDataRequest: boolean = false){
    this.POListForProductionOutputReportSearchFilter = [];   
    const params = {
      PlantId: this.groupDataForm.get('plant')?.value,
      StartDate: this.manualStackerFromProductionOutputReport.get('startDate')?.value,
      EndDate: this.manualStackerFromProductionOutputReport.get('endDate')?.value,
    };
    this.spinnerShow = 'none';
    this.spinner.show();
    this.sfdService.GetPOListByDateRangeForProductionOutput(params).subscribe(
      (response: any) => {
        this.spinner.hide();
        if(!response.HasError){
          if(response.result.length){
            const obj = 
            {
              PPDId: 0,
              PO: 'Not Available',
              OperDesc: null,
              ItemDesc: null,
              ItemCode: null,
            }
            this.POListForProductionOutputReportSearchFilter = this.commonMethodsForPOService.getFilteredPOList(response.result,this.userRoles);
            this.POListForProductionOutputReportSearchFilter.splice(0,0,obj);
            this.manualStackerFromProductionOutputReport.get('job_no')?.setValue(this.POListForProductionOutputReportSearchFilter);  
            if(isGridDataRequest)
              this.getManualProductionOutputReport();
          }
          else{
            this.manualStackerFromProductionOutputReport.get('job_no').setValue([]);
            this.toaster.warning('No PO Available for Selected Date Range','Warning!');
          }
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.error);
      }
    )
  }

  getManualProductionOutputReport(){
    this.spinnerShow = 'none';
    this.spinner.show();
    this.tempDataForManualProductionOutputReport = [];
    this.rowProductionOutputReport = [];
    this.totalRows = 0;
    this.pendingRows = 0;
    this.printedRows = 0;
    this.zeroValue = 0;
    this.actualRows = 0;
    this.actualweight = '0'; 
    if((this.manualStackerFromProductionOutputReport.get('job_no')?.value).length ){
      let shift: any = [];
      let crew: any = [];
      if (this.manualStackerFromProductionOutputReport.get('shift')?.value == 0) {
        for (var i = 0; i < this.shiftList.length; i++) {
          shift.push(this.shiftList[i].TypeId);
        }
      } else {
        shift.push(this.manualStackerFromProductionOutputReport.get('shift')?.value);
      }
      if (this.manualStackerFromProductionOutputReport.get('crew')?.value == 0) {
        for (var i = 0; i < this.crewList.length; i++) {
          crew.push(this.crewList[i].TypeId);
        }
      } else {
        crew.push(this.manualStackerFromProductionOutputReport.get('crew')?.value);
      }
      let data = {
        StartDate: this.manualStackerFromProductionOutputReport.get('startDate')?.value,
        EndDate: this.manualStackerFromProductionOutputReport.get('endDate')?.value,
        StackerName: '',
        OrderNumber: (this.manualStackerFromProductionOutputReport.get('job_no')?.value).map(j => j.PPDId).toString(),
        ShiftId: shift.toString(),
        CrewId: crew.toString()
      }
      this.sfdService.GetManualProductionOutput(data).subscribe(
        (res: any) => {
          if(!res.HasError){
            if(res.data.length){
              this.tempDataForManualProductionOutputReport = res.data;
              this.totalRows = res.data.length;
              this.pendingRows = res.data.filter(row => !row.printStatus).length;
              this.printedRows = res.data.filter(row => row.printStatus).length;
              this.zeroValue = res.data.filter(row => row.printStatus && row.ResultValidity === 0).length;
              this.actualRows = res.data.filter(row => row.printStatus && row.ERPStatus && row.ResultValidity !== 0).length;
              let actualweight = 0;
              res.data.forEach(element => {
                if(element.printStatus && element.ERPStatus && element.ResultValidity != 0){
                  const result = this.commonMethodsForPOService.resultERPCalculation(element,this.thicknessList)?.toFixed(4)
                  result ? actualweight = actualweight + parseFloat(result) : '';
                }
              })
              this.actualweight = actualweight.toFixed(4);
              this.isDataAscReport = false;
              this.totalProductionCard = true;
              this.pendingProductionCard = false;
              this.zeroValueProductionCard = false;
              this.printedProductionCard = false;
              this.actualProductionCard = false;
              this.productionOutputReportCards('Total');
            }
            else{
              this.tempDataForManualProductionOutputReport = [];
              this.totalRows = 0;
              this.pendingRows = 0;
              this.printedRows = 0;
              this.zeroValue = 0;
              this.actualRows = 0;          
              this.rowProductionOutputReport = [];
              this.toaster.warning('No Data Found for Production Output','Warning!');
            }
          }
          this.spinner.hide();
        },
        (error: any) => {
          this.toaster.error(error.error)
          this.spinner.hide();
        }
      );
    }
    else{
      this.spinner.hide();
      this.toaster.warning('Please Select Job No','Warning!')
    }
  }

  productionOutputReportCards(name: string) {
    let filteredData = [];
    if (name === 'Pending') {
      filteredData = this.tempDataForManualProductionOutputReport.filter(row => !row.printStatus)
      this.totalProductionCard = false;
      this.pendingProductionCard = true;
      this.zeroValueProductionCard = false;
      this.printedProductionCard = false;
      this.actualProductionCard = false;
    } 
    if(name==='Total'){
      filteredData = this.tempDataForManualProductionOutputReport.slice();
      this.totalProductionCard = true;
      this.pendingProductionCard = false;
      this.zeroValueProductionCard = false;
      this.printedProductionCard = false;
      this.actualProductionCard = false;
    }
    if(name==='0_Value'){
      filteredData =  this.tempDataForManualProductionOutputReport.filter(row => row.printStatus && row.ResultValidity === 0);
      this.totalProductionCard = false;
      this.pendingProductionCard = false;
      this.zeroValueProductionCard = true;
      this.printedProductionCard = false;
      this.actualProductionCard = false;
    }
    if(name==='Printed'){
      filteredData = this.tempDataForManualProductionOutputReport.filter(row => row.printStatus);
      this.totalProductionCard = false;
      this.pendingProductionCard = false;
      this.zeroValueProductionCard = false;
      this.printedProductionCard = true;
      this.actualProductionCard = false;
    }
    if(name==='Actual_Output'){
      filteredData = this.tempDataForManualProductionOutputReport.filter(row => row.printStatus && row.ResultValidity != 0 && row.ERPStatus);
      this.totalProductionCard = false;
      this.pendingProductionCard = false;
      this.zeroValueProductionCard = false;
      this.printedProductionCard = false;
      this.actualProductionCard = true;
    }
    this.sortGridDataForActalBatch(this.isDataAscReport ? 'Asc' : 'Desc',filteredData,'cards');
  }

  /**
   * Grid Configuration Methods for Manual Production Output
   */
  onSaveGridState(type: string = '') {
    let newState
    if(type == 'MaterialConsumptionPOR-vTLU31IVZb' && this.gridReadyProductionOutputReport)
      newState = this.gridColumnApiForManualProductionOutputReport?.getColumnState()
    else if(type == 'MaterialConsumptionPO-vTLU31IVZb' && this.gridReadyProductionOutput)
      newState = this.gridColumnApiForManualProductionOutput?.getColumnState()
    else
      return;
    const data: GridConfiguration = {
      UserId: JSON.parse(localStorage.getItem('user')).UserId,
      PageId: this.pageId,
      Configuration: JSON.stringify(newState),
      GridId: type
    }
    if (!data.Configuration) {
      return;
    }
    this.gridService.saveGridConfiguration(data).subscribe(
      (res: any) => {},
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    )
  }

  applyState(gridId: string) {
    const data: GridConfiguration = {
      UserId: JSON.parse(localStorage.getItem('user')).UserId,
      PageId: this.pageId,
      GridId: gridId
    }
    this.gridService.getGridConfiguration(data).subscribe((res: any) => {
      if (res.Table.length) {
        if(gridId == 'MaterialConsumptionPOR-vTLU31IVZb'){
          this.gridColumnApiForManualProductionOutputReport.applyColumnState({ state: JSON.parse(res.Table[0].Configuration), applyOrder: true, });
          this.gridApiForManualProductionOutputReport.sizeColumnsToFit();
        }
        else if(gridId == 'MaterialConsumptionPO-vTLU31IVZb'){
          this.gridColumnApiForManualProductionOutput.applyColumnState({ state: JSON.parse(res.Table[0].Configuration), applyOrder: true, });
          this.gridApiForManualProductionOutput.sizeColumnsToFit();
        }
      }
      else {
        if(gridId == 'MaterialConsumptionPOR-vTLU31IVZb'){
          this.gridColumnApiForManualProductionOutputReport.resetColumnState();
          this.gridApiForManualProductionOutputReport.sizeColumnsToFit();
        }
        else if(gridId == 'MaterialConsumptionPO-vTLU31IVZb'){
          this.gridColumnApiForManualProductionOutput.resetColumnState();
          this.gridApiForManualProductionOutput.sizeColumnsToFit();
        }
      }
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      })

  }

  /**
   * Print Funtions Used for Printing Barcodes and Report data
   */
  PrintBarcodeWithTinForGlassManufacturingOrders(event: any){
    if(event.data.OrderNumber){
      const key = {
        UnitId: 1517,
        OrderNo: event.data.OrderNumber,
        StackerId: 0,
        ManualProductionId: event.data.ID,
        LiteCount: event.data.LiteCountErp,
        SerialNo: event.data.ActualBatchNo.split('_')[2] ? event.data.ActualBatchNo.split('_')[2] : event.data.ActualBatchNo.split('_')[1],
        NetWeight: event.data.ResultErp,
        GrossWeight: (parseFloat(event.data.ResultErp) + 0.04).toFixed(4),
        SQM: this.commonMethodsForPOService.resultSQMTCalculation(event.data)?.toFixed(4)
      };
      this.glassPpcLiveService.GetBarCodeDetails_PPC(key).subscribe((res: any) => {
        if (res.GetBarCodeDetails && res.GetBarCodeDetails.length > 0 && !res.HasError) {
          let htmlcontent = res.GetBarCodeDetails[0].HTMLLayout;
          const detailItems = res.GetBarCodeDetails[0].detailItems;
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries1]',
            res.BarCodeSeries1
          );
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries2]',
            res.BarCodeSeries2
          );
  
          $.each(detailItems, (i, item) => {
            if (item.Name === 'GS1_Barcode') {
              if(item.Value){
                htmlcontent = htmlcontent.replace(
                  '[IsBarcodeAvailable]',
                  detailItems.find((item: any) => {
                    return item.Name == 'IsBarcodeAvailable'
                  }).Value 
                );
                htmlcontent = htmlcontent.replace(
                  '[' + item.Name + ']',
                  item.Value
                );
              } else{
                htmlcontent = htmlcontent.replace('[IsBarcodeAvailable]','<td><img src="[SaudiMadeLogo]"> </td>');
              }
            }
          });
          $.each(detailItems, (i, item) => {
            if (item.Name === 'TimeNow') {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                moment(new Date()).utc(false).format('DD-MM-YYYY hh:mm A')
              );
            } else if(item.Name !== 'GS1_Barcode') {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                item.Value
              );
            }
          });
  
          const printWindow = window.open('', 'Print-Window')!;
  
          const styleTags =
            '' +
            '<style type="text/css">' +
            '@media print {' +
            '{-webkit-print-color-adjust: exact;}' +
            '-webkit-print-color-adjust: exact;' +
            '}' +
            'table, th, td {' +
            'border:1px solid black;' +
            'border-collapse: collapse;' +
            '}' +
            '.td-bold {font-weight:bold; }' +
            '</style>';
  
          const scriptTags =
            '' +
            '<script type="text/javascript">' +
            'window.addEventListener(' +
            "'load'" +
            ', function () {' +
            'JsBarcode(".barcode").init();' +
            '})' +
            '</script>';
  
          const JsBCScript =
            '' +
            '<script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.3/dist/JsBarcode.all.min.js"></script>';
  
          printWindow?.document.write(
            styleTags +
            JsBCScript +
            scriptTags +
            '<html><body onload="window.print();">' +
            htmlcontent +
            '</body></html>'
          );
          setTimeout(() => {
            printWindow?.document.close();
            printWindow?.close();
            !event.data.printStatus ? this.updatePrintStatusForProductionOutput(event,true) : '';
          }, 1000);
        } else {
          this.toaster.error('error', 'Data is Empty !');
        }
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
      );
    }
    else{
      this.toaster.warning('No Order Number Found','Warning');
    }
  }

  PrintBarCodeWithOutTinForGlassManufacturingOrders(event: any){
    if(event.data.OrderNumber){
      const key = {
        UnitId: 1517,
        OrderNo: event.data.OrderNumber,
        StackerId: 0,
        ManualProductionId: event.data.ID,
        LiteCount: event.data.LiteCountErp,
        SerialNo: event.data.ActualBatchNo.split('_')[2] ? event.data.ActualBatchNo.split('_')[2] : event.data.ActualBatchNo.split('_')[1],
        NetWeight: event.data.ResultErp,
        GrossWeight: (parseFloat(event.data.ResultErp) + 0.04).toFixed(4),
        SQM: this.commonMethodsForPOService.resultSQMTCalculation(event.data)?.toFixed(4)
      };
      this.glassPpcLiveService.GetBarCodeDetails_PPC_CustomizedForGlass(key).subscribe((res: any) => {
        if (res.GetBarCodeDetails && res.GetBarCodeDetails.length > 0 && !res.HasError) {
          let htmlcontent = res.GetBarCodeDetails[0].HTMLLayout;
          const detailItems = res.GetBarCodeDetails[0].detailItems;
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries1]',
            res.BarCodeSeries1
          );
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries2]',
            res.BarCodeSeries2
          );
          $.each(detailItems, (i, item) => {
            if (item.Name === 'GS1_Barcode') {
              if(item.Value){
                htmlcontent = htmlcontent.replace(
                  '[IsBarcodeAvailable]',
                  detailItems.find((item: any) => {
                    return item.Name == 'IsBarcodeAvailable'
                  }).Value 
                );
                htmlcontent = htmlcontent.replace(
                  '[' + item.Name + ']',
                  item.Value
                );
              } else{
                htmlcontent = htmlcontent.replace('[IsBarcodeAvailable]','<td><img src="[SaudiMadeLogo]"> </td>');
              }
            }
          });
          $.each(detailItems, (i, item) => {
            if (item.Name === 'TimeNow') {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                moment(new Date()).utc(false).format('DD-MM-YYYY hh:mm A')
              );
            } else if(item.Name !== 'GS1_Barcode') {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                item.Value
              );
            }
          });
  
          const printWindow = window.open('', 'Print-Window')!;
  
          const styleTags =
            '' +
            '<style type="text/css">' +
            '@media print {' +
            '{-webkit-print-color-adjust: exact;}' +
            '-webkit-print-color-adjust: exact;' +
            '}' +
            'table, th, td {' +
            'border:1px solid black;' +
            'border-collapse: collapse;' +
            '}' +
            '.td-bold {font-weight:bold; }' +
            '</style>';
  
          const scriptTags =
            '' +
            '<script type="text/javascript">' +
            'window.addEventListener(' +
            "'load'" +
            ', function () {' +
            'JsBarcode(".barcode").init();' +
            '})' +
            '</script>';
  
          const JsBCScript =
            '' +
            '<script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.3/dist/JsBarcode.all.min.js"></script>';
  
          printWindow?.document.write(
            styleTags +
            JsBCScript +
            scriptTags +
            '<html><body onload="window.print();">' +
            htmlcontent +
            '</body></html>'
          );
          setTimeout(() => {
            printWindow?.document.close();
            printWindow?.close();
            !event.data.printStatus ? this.updatePrintStatusForProductionOutput(event,true) : '';
          }, 1000);  
        } else {
          this.toaster.error('error', 'Data is Empty !');
        }
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
      );
    }
    else{
      this.toaster.warning('No Order Number Found','Warning');
    }
  }

  PrintBarcodeWithTinForLaminationOrders(event: any){
    if(event.data.OrderNumber){
      const key = {
        UnitId: 1610,
        OrderNo: event.data.OrderNumber,
        LiteCount: event.data.LiteCountErp,
        SerialNo: event.data.ActualBatchNo.split('_')[2] ? event.data.ActualBatchNo.split('_')[2] : event.data.ActualBatchNo.split('_')[1],
        CombinationOfThickness: this.commonMethodsForPOService.thicknessAndSizeCalculation(event.data,'thickness'),
        Size: this.commonMethodsForPOService.thicknessAndSizeCalculation(event.data,'size'),
        QtyCode: event.data?.EffUnit.split('-')[1],
        PVBColor: (this.getPVBColorAndValidateColorFieldForProductionOutput(event.data,PVBColorTypes.PVBColor))?.ColorName,
        CoatedGlassColor: event.data.ItemCode.length > 21 ? (this.getPVBColorAndValidateColorFieldForProductionOutput(event.data,PVBColorTypes.CoatedGlassColor))?.ColorName : '',
        NetWeight: event.data.ResultErp,
        GrossWeight: (parseFloat(event.data.ResultErp) + 0.04).toFixed(4),
        SQM: this.commonMethodsForPOService.resultSQMTCalculation(event.data)?.toFixed(4),
        Crew: event.data.Crew,
        Shift: event.data.Shift,
        Branding: event.data.Branding == 'B' ? 'B' : 'NB',
        Tin: 1
      };
      this.glassPpcLiveService.GetBarCodeDetails_PPC_Lamination(key).subscribe((res: any) => {
        if (res.GetBarCodeDetails && res.GetBarCodeDetails.length > 0 && !res.HasError) {
          let htmlcontent = res.GetBarCodeDetails[0].HTMLLayout;
          const detailItems = res.GetBarCodeDetails[0].detailItems;
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries1]',
            res.BarCodeSeries1
          );
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries2]',
            res.BarCodeSeries2
          );
          $.each(detailItems, (i, item) => {
            if (item.Name === 'GS1_Barcode') {
              if(item.Value){
                htmlcontent = htmlcontent.replace(
                  '[IsBarcodeAvailable]',
                  detailItems.find((item: any) => {
                    return item.Name == 'IsBarcodeAvailable'
                  }).Value 
                );
                htmlcontent = htmlcontent.replace(
                  '[' + item.Name + ']',
                  item.Value
                );
              } else{
                htmlcontent = htmlcontent.replace('[IsBarcodeAvailable]','<td><img src="[SaudiMadeLogo]"> </td>');
              }
            }
          });
          $.each(detailItems, (i, item) => {
            if (item.Name === 'TimeNow') {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                moment(new Date()).utc(false).format('DD-MM-YYYY hh:mm A')
              );
            } else if(item.Name !== 'GS1_Barcode') {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                item.Value
              );
            }
          });
  
          const printWindow = window.open('', 'Print-Window')!;
  
          const styleTags =
            '' +
            '<style type="text/css">' +
            '@media print {' +
            '{-webkit-print-color-adjust: exact;}' +
            '-webkit-print-color-adjust: exact;' +
            '}' +
            'table, th, td {' +
            'border:1px solid black;' +
            'border-collapse: collapse;' +
            '}' +
            '.td-bold {font-weight:bold; }' +
            '</style>';
  
          const scriptTags =
            '' +
            '<script type="text/javascript">' +
            'window.addEventListener(' +
            "'load'" +
            ', function () {' +
            'JsBarcode(".barcode").init();' +
            '})' +
            '</script>';
  
          const JsBCScript =
            '' +
            '<script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.3/dist/JsBarcode.all.min.js"></script>';
  
          printWindow?.document.write(
            styleTags +
            JsBCScript +
            scriptTags +
            '<html><body onload="window.print();">' +
            htmlcontent +
            '</body></html>'
          );
          setTimeout(() => {
            printWindow?.document.close();
            printWindow?.close();
            !event.data.printStatus ? this.updatePrintStatusForProductionOutput(event,true) : '';
          }, 1000);
        } else {
          this.toaster.error('error', 'Data is Empty !');
        }
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
      );
    }
    else{
      this.toaster.warning('No Order Number Found','Warning');
    }
  }

  PrintBarCodeWithOutTinForLaminationOrders(event: any){
    if(event.data.OrderNumber){
      const key = {
        UnitId: 1610,
        OrderNo: event.data.OrderNumber,
        LiteCount: event.data.LiteCountErp,
        SerialNo: event.data.ActualBatchNo.split('_')[2] ? event.data.ActualBatchNo.split('_')[2] : event.data.ActualBatchNo.split('_')[1],
        CombinationOfThickness: this.commonMethodsForPOService.thicknessAndSizeCalculation(event.data,'thickness'),
        Size: this.commonMethodsForPOService.thicknessAndSizeCalculation(event.data,'sixe'),
        QtyCode: event.data?.EffUnit.split('-')[1],
        PVBColor: (this.getPVBColorAndValidateColorFieldForProductionOutput(event.data,PVBColorTypes.PVBColor))?.ColorName,
        CoatedGlassColor: event.data.ItemCode.length > 21 ? (this.getPVBColorAndValidateColorFieldForProductionOutput(event.data,PVBColorTypes.CoatedGlassColor))?.ColorName : '',
        NetWeight: event.data.ResultErp,
        GrossWeight: (parseFloat(event.data.ResultErp) + 0.04).toFixed(4),
        SQM: this.commonMethodsForPOService.resultSQMTCalculation(event.data)?.toFixed(4),
        Crew: event.data.Crew,
        Shift: event.data.Shift,
        Branding: event.data.Branding == 'B' ? 'B' : 'NB',
        Tin: 0
      };
      this.glassPpcLiveService.GetBarCodeDetails_PPC_Lamination(key).subscribe((res: any) => {
        if (res.GetBarCodeDetails && res.GetBarCodeDetails.length > 0 && !res.HasError) {
          let htmlcontent = res.GetBarCodeDetails[0].HTMLLayout;
          const detailItems = res.GetBarCodeDetails[0].detailItems;
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries1]',
            res.BarCodeSeries1
          );
          htmlcontent = htmlcontent.replace(
            '[BarCodeSeries2]',
            res.BarCodeSeries2
          );
          $.each(detailItems, (i, item) => {
            if (item.Name === 'GS1_Barcode') {
              if(item.Value){
                htmlcontent = htmlcontent.replace(
                  '[IsBarcodeAvailable]',
                  detailItems.find((item: any) => {
                    return item.Name == 'IsBarcodeAvailable'
                  }).Value 
                );
                htmlcontent = htmlcontent.replace(
                  '[' + item.Name + ']',
                  item.Value
                );
              } else{
                htmlcontent = htmlcontent.replace('[IsBarcodeAvailable]','<td><img src="[SaudiMadeLogo]"> </td>');
              }
            }
          });
          $.each(detailItems, (i, item) => {
            if (item.Name === 'TimeNow') {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                moment(new Date()).utc(false).format('DD-MM-YYYY hh:mm A')
              );
            } else if(item.Name !== 'GS1_Barcode') {
              htmlcontent = htmlcontent.replace(
                '[' + item.Name + ']',
                item.Value
              );
            }
          });
  
          const printWindow = window.open('', 'Print-Window')!;
  
          const styleTags =
            '' +
            '<style type="text/css">' +
            '@media print {' +
            '{-webkit-print-color-adjust: exact;}' +
            '-webkit-print-color-adjust: exact;' +
            '}' +
            'table, th, td {' +
            'border:1px solid black;' +
            'border-collapse: collapse;' +
            '}' +
            '.td-bold {font-weight:bold; }' +
            '</style>';
  
          const scriptTags =
            '' +
            '<script type="text/javascript">' +
            'window.addEventListener(' +
            "'load'" +
            ', function () {' +
            'JsBarcode(".barcode").init();' +
            '})' +
            '</script>';
  
          const JsBCScript =
            '' +
            '<script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.3/dist/JsBarcode.all.min.js"></script>';
  
          printWindow?.document.write(
            styleTags +
            JsBCScript +
            scriptTags +
            '<html><body onload="window.print();">' +
            htmlcontent +
            '</body></html>'
          );
          setTimeout(() => {
            printWindow?.document.close();
            printWindow?.close();
            !event.data.printStatus ? this.updatePrintStatusForProductionOutput(event,true) : '';
          }, 1000);  
        } else {
          this.toaster.error('error', 'Data is Empty !');
        }
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
      );
    }
    else{
      this.toaster.warning('No Order Number Found','Warning');
    }
  }

  getPrint(){
    this.startDate = this.datePipe.transform(this.manualStackerFromProductionOutputReport.get('startDate')?.value, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.manualStackerFromProductionOutputReport.get('endDate')?.value, 'yyyy-MM-dd');
    if (this.manualStackerFromProductionOutputReport.get('shift')?.value == 0) {
      this.shift = this.shiftList.map((shitDetail: any)=> {return shitDetail.TypeName}).toString();
    } else {
      this.shift = this.shiftList.find((s: any)=>{
        return s.TypeId == this.manualStackerFromProductionOutputReport.get('shift')?.value
      }).TypeName
    }
    if (this.manualStackerFromProductionOutputReport.get('crew')?.value == 0) {
      this.crew = this.crewList.map((shitDetail: any)=> {return shitDetail.TypeName}).toString();
    } else {
      this.crew = this.crewList.find((c: any)=>{
        return c.TypeId == this.manualStackerFromProductionOutputReport.get('crew')?.value
      }).TypeName
    }
    setTimeout(() => {
      const WindowPrt: any = window.open('', '_blank');
      let style = `
      <style media="all">
      table, th, td {
        border: 0.3px solid #0a0a0a;
        border-collapse: collapse;
      }
      th{
        background-color:  #BFBFF5;
        color: #480FD1;
        height: 20px;
        word-wrap: break-word;
      }
      .thRow{
        height: 45px;
      }
      .tdRow{
        height: 30px;
      }
      td{
        text-align: center;
        word-wrap: break-word;
        padding-top: 5px;
      }
      .resultRow{
        height: 30px;
        background-color: #a2c6c6e8;      
      }
      .header{
        width: 100%;
        height: 70px;
        background-color:  #2424d1;
        text-align: center;
      }
      .filters{
        font-weight: bold;
        font-size: larger;
      }
      .headerTable{
        width: 100%;
        border: none;
      }
      .headerTable td{
        border: none;
      }
      .menu-items {
        width: 15%;
        padding: 0 31px;
        margin-bottom: 20px;
        height: 140px;
      }
      .menu-items .menu-card-item {
        margin: 0 0 30px 0;
        width: 100%;
        position: relative;
        display: block;
        background: white;
        box-shadow: 2px 2px 15px rgba(5, 5, 124, 0.14);
        border-radius: 10px;
        padding: 30px 20px;
        height: 90px;
     }
     .menu-items .menu-card-item > .chart-items {
      width: 100%;
      margin: 0;
      padding: 20px 0 0 20px;
      }
      .menu-items .menu-card-item:last-child {
        margin-bottom: 0;
     }
      .menu-items .title-text {
        font-weight: bold;
        font-size: 18px;
        line-height: 4px;
        color: #1d252c;
     }
      .menu-items .data-text {
        font-weight: bold;
        font-size: 25px;
        line-height: 20px;
        color: #0f0fd1;
     }
      .menu-items .data-text.small-data-text {
        font-size: 18px;
        line-height: 21px;
      }
      .menu-items .menu-bottom-icon {
        position: absolute;
        right: 0;
        bottom: -1px;
      }
      .menu-items .menu-bottom-icon img {
        height: 40px;
      }  
      .menu-items .active-card{
        background-color: #a2c6c6e8;
      }
      @media print{
        body{
          -webkit-print-color-adjust: exact !important;
        }
        @page {
          size:  auto; 
          margin: 15px;
        }
        table, th, td {
          border: 0.3px solid #0a0a0a;
          border-collapse: collapse;
        }
        th{
          background-color:  #BFBFF5;
          color: #480FD1;
          height: 20px;
          word-wrap: break-word;
        }
        .thRow{
          height: 45px;
        }
        .tdRow{
          height: 30px;
        }
        td{
          text-align: center;
          word-wrap: break-word;
          padding-top: 5px;
        }
        .resultRow{
          height: 30px;
          background-color: #a2c6c6e8;  
        }
        .header{
          width: 100%;
          height: 50px;
          background-color:  #2424d1;
          text-align: center;
        }
        .filters{
          font-weight: bold;
          font-size: larger;
        }
        .headerTable{
          width: 100%;
          border: none;
        }
        .headerTable td{
          border: none;
        }
        .menu-items {
          width: 13%;
          padding: 0 28px;
          margin-bottom: 20px;
          height: 140px;
       }
        .menu-items .menu-card-item {
          margin: 0 0 30px 0;
          width: 100%;
          position: relative;
          display: block;
          background: white;
          box-shadow: 2px 2px 15px rgba(5, 5, 124, 0.14);
          border-radius: 10px;
          padding: 30px 20px;
          height: 90px;
       }
        .menu-items .menu-card-item > .chart-items {
          width: 100%;
          margin: 0;
          padding: 20px 0 0 20px;
       }
        .menu-items .menu-card-item:last-child {
          margin-bottom: 0;
       }
        .menu-items .title-text {
          font-weight: bold;
          font-size: 18px;
          line-height: 4px;
          color: #1d252c;
       }
        .menu-items .data-text {
          font-weight: bold;
          font-size: 25px;
          line-height: 20px;
          color: #0f0fd1;
       }
        .menu-items .menu-bottom-icon {
          position: absolute;
          right: 0;
          bottom: -1px;
       }
        .menu-items .menu-bottom-icon img {
          height: 40px;
       } 
       .menu-items .active-card{
        background-color: #a2c6c6e8;
        }            
      }
      </style>
      `
      WindowPrt.document.write(style); 
      let div = document.getElementsByClassName('header1')[0].innerHTML;
      div += `<br>`
      div += `<div style="width: 100%;display: flex;">`
      div += document.getElementsByClassName('printCard')[0].innerHTML;
      div += document.getElementsByClassName('printCard')[1].innerHTML;
      div += document.getElementsByClassName('printCard')[2].innerHTML;
      div += document.getElementsByClassName('printCard')[3].innerHTML;
      div += document.getElementsByClassName('printCard')[4].innerHTML;
      div += `</div>`
      div += `<div style="margin-top: 20px;">`
      div += document.getElementById('print').innerHTML;
      div += `</div>`
      WindowPrt.document.write('<html><body>');
      WindowPrt.document.write(div);
      WindowPrt.document.write('</body></html>');
      setTimeout(() => {
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
        WindowPrt.close();
      }, 1000);
    },1000);
  }
  //================================================ End ================================================//
}