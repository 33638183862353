import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RMconfigurationService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }


  getTreeStructure(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + 'get-configuration-tree');
  }

  addLine(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.baseUrl + 'add-line', data, { headers: header });
  }

  updateLine(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.put<any>(this.baseUrl + 'update-line', data, { headers: header });
  }

  deleteLine(data: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'delete-line', data);
  }

  getWOType(headerData:any): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + 'PMWOType/getWOType' , {params:headerData});
  }

  // *** Core API Method *** //
  addWOType(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'PMWOType/addWOType', data, { headers: header });
  }

  // *** Core API Method *** //
  updateWOType(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'PMWOType/updateWOType', data, { headers: header });
  }

  // *** Core API Method *** //
  deleteWOType(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMWOType/DeleteWOType', data);
  }

  getTechnician(data: any): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + 'PMTechnician/getTechnician', { params: data });
  }

  // *** Core API Method *** //
  addTechnician(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTechnician/addTechnician', data);
  }

  deleteTechnician(data: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'delete-technician', data);
  }
  // *** Core API Method *** //
  getPriority(headerData:any): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + 'PMPriority/getPriority' , {params:headerData});
  }
  // *** Core API Method *** //
  addPriority(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'PMPriority/AddPriority', data, { headers: header });
  }
  // *** Core API Method *** //
  updatePriority(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'PMPriority/UpdatePriority', data, { headers: header });
  }
  // *** Core API Method *** //
  deletePriority(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMPriority/DeletePriority', data);
  }
  // *** Core API Method *** //
  getFailureMode(headerData:any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMFailureMode/GetFailureMode' ,{params:headerData});
  }
  // *** Core API Method *** //
  addFailureMode(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'PMFailureMode/AddFailureMode', data, { headers: header });
  }
  // *** Core API Method *** //
  updateFailureMode(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'PMFailureMode/UpdateFailureMode', data, { headers: header });
  }
  // *** Core API Method *** //
  deleteFailureMode(data: any): Observable<any> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.delete<any>(this.baseUrl + `delete-failureMode/${data.ID}`, { headers: header });
    return this.http.delete<any>(this.o3CoreApiUrl + `PMFailureMode/DeleteFailureMode/${data.ID}`, { headers: header });
  }
  // *** Core API Method *** //
  getTime(headerData:any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMTime/getTime' , {params:headerData});
  }
  // *** Core API Method *** //
  addTime(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTime/addTime', data, { headers: header });
  }
  // *** Core API Method *** //
  updateTime(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTime/updateTime', data, { headers: header });
  }

  deleteTime(data: any): Observable<any> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.delete<any>(this.baseUrl + `delete-time/${data.ID}`, { headers: header });
  }

  // WOTypeMasterUpload(data: any): Observable<any[]> {
  //   return this.http.post<any>(this.baseUrl + 'add-master-wotype', data);
  // }

  // FailureModeMasterUpload(data: any): Observable<any[]> {
  //   return this.http.post<any>(this.baseUrl + 'add-master-failuremode', data);
  // }

  // PriorityMasterUpload(data: any): Observable<any[]> {
  //   return this.http.post<any>(this.baseUrl + 'add-master-priority', data);
  // }

  // DelayTimeMasterUpload(data: any): Observable<any[]> {
  //   return this.http.post<any>(this.baseUrl + 'add-master-delaytime', data);
  // }
  // *** Core API Method *** //
  RMMasterUpload(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMConfiguration/addMasterRM', data);
  }

}
