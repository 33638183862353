import { NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { StockChart } from 'angular-highcharts';
import { differenceInCalendarDays } from 'date-fns';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { PlantGroupElement } from 'src/app/interface/plant-model';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ProcessControlService } from 'src/app/services/processControlService.service';
import { QualityParameterService } from 'src/app/services/quality-parameter.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/services/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { Chart } from 'angular-highcharts';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { CustomTooltip } from 'src/app/custom-tooltip.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { interval, Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { CrewScheduleService } from 'src/app/services/crewSchedule.service';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';

const moment = _moment;

@Component({
  selector: 'app-quality-process-control',
  templateUrl: './quality-process-control.component.html',
  styleUrls: ['./quality-process-control.component.scss'],
})
export class QualityProcessControlComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Analysis',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptions1: MatProgressButtonOptions = {
    active: false,
    text: 'Comparison',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptionsOrders: MatProgressButtonOptions = {
    active: false,
    text: 'PO List',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptionsAnalysis: MatProgressButtonOptions = {
    active: false,
    text: 'Orders',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };

  @ViewChild('visibleYearlyTarget')
  private visibleYearlyTargetTbl!: TemplateRef<any>;
  @ViewChild('visibleParameterComparison')
  private visibleParameterComparison!: TemplateRef<any>;
  // @ViewChild('displayProcessModal')
  // private displayProcessModal!: TemplateRef<any>;
  // @ViewChild('displayDefectModal')
  // private displayDefectModal!: TemplateRef<any>;
  @ViewChild('displayQCModal') private displayQCModal!: TemplateRef<any>;
  @ViewChild('qualityBoolListModal')
  private qualityBoolListModal!: TemplateRef<any>;
  @ViewChild('displayQualityOrdersModal')
  private displayQualityOrdersModal!: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('pickerforgrid') picker!: any;
  processcontrolcolumns: string[] = [
    'LRL',
    'LSL',
    'LWL',
    'TGT',
    'UWL',
    'USL',
    'URL',
  ];
  defectColumns: string[] = [
    'DefectMode',
    'Department',
    'Line',
    'Unit',
    'Assembly',
    'Subassembly',
    'Component',
  ];
  ordersColumns: string[] = [
    'ProcessOrder',
    'ProdCode',
    'ProdDesc',
    'StartTime',
    'EndTime',
  ];
  qcVerifyColumns: string[] = [
    'ParameterId',
    'Parameter',
    'Sample',
    'Value',
    'verify',
    'remarks',
  ];
  qcdataSource = new MatTableDataSource<any>([]);
  processControlHeaderForm!: FormGroup;
  searchForm!: FormGroup;
  unitDetailForm!: FormGroup;
  ProcessControlDataForm!: FormGroup;
  isParamterValuesModelVisible = false;
  Exdpandhide = true;
  Collapsehide = false;
  isCardExpanded = false;
  Isexpandcontentshow = true;
  Isexpandcontenthide = true;
  Isexpandcontentbuttonshow = false;
  displayProcessModal!: boolean;
  displayDefectModal!: boolean;
  scrollPosition!: number;
  class = 'hidden';
  backgroundColor = '';
  qualityBoolVal!: string;
  // Date-Time Picker Variable
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  // public minDate = new Date();
  public maxDate = new Date();
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  subscription!: Subscription;
  private subscriptions: Subscription[] = [];
  intervalId!: any;
  // tslint:disable-next-line:max-line-length
  highChartsColor = [
    '#00008B',
    '#34A56F',
    '#F80000',
    '#2D0A77',
    '#8C001A',
    '#FF69B4',
    '#FA4A08',
    '#5453A6',
    '#8B008B',
    '#3090C7',
    '#F1C40F',
    '#CB4335',
    '#36454F',
    '#A569BD',
    '#FFA500',
    '#179fcc',
    '#093323',
    '#08fa03',
    '#048b8e',
    '#9637A4',
    '#F39C12',
    '#0b774e',
    '#F5B7B1',
    '#0d98da',
    '#512E5F',
    '#C471A5',
    '#0034bb',
    '#06573d',
    '#0d6e54',
    '#0692aa',
    '#611745',
    '#F80000',
    '#FFDF00',
    '#6D81A8',
    '#08FAC7',
    '#D208FA',
    '#FAEB08',
  ];

  canvas1: any;
  option: any;
  ctx1: any;

  highlightedRows = [];
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  group!: any[];
  IntervalTypeDDL!: any[];
  chartArray: any[] = [];
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();

  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );

  isPlantGroupModelVisible = false;
  varUnitName!: string;

  unitid: any;
  UnitName: any;
  UnitNode: any;
  gridApi: any;
  gridColumnApi: any;
  defaultColDef!: any;
  rowSelection: any;
  rowData!: any[];
  emptygrid!: any[];
  ONE_HOUR = 60 * 60 * 1000;
  columnDefs!: any;
  parameterId: any;
  chartData: any;
  parameterName: any;
  stock!: StockChart;
  chart!: StockChart;
  FromTimePicker: any;
  ToTimePicker: any;
  defaultColDefi: any = {
    flex: 1,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    minWidth: 200,
    // headerClass: 'ag-header-cell-text',
    floatingFilterComponentParams: {
      suppressFilterButton: false,
    },
    filterParams: { newRowsAction: 'keep' },
  };
  gridOptions: any = {
    headerHeight: 50,
    defaultColDef: this.defaultColDefi,
    autoGroupColumnDef: {
      headerName: 'Paramter Group',
      pinned: 'left',
      resizable: true,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
    },
    flex: 1,
    groupDefaultExpanded: 1,
    paginationPageSize: 10,
    // onCellEditingStarted: (params: any) => {
    //   debugger
    //   params.node.setDataValue(params.colDef.field, '32');
    // },
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    frameworkComponents: {
      customTooltip: CustomTooltip,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
      customLoadingOverlay: CustomLoadingOverlay,
    },
  };
  cavityList!: any[];
  maxCavity: any;
  parameterspecifications!: MatTableDataSource<any>;
  parametrdefect!: MatTableDataSource<any>;
  showCavity = false;
  chartSpecifications: any;
  frameworkComponents: any;
  qualityProcessOrder = new MatTableDataSource<any>([]);
  paramterSpcecificationName: any;
  intervalList: any;
  qcParamsSlot: any;
  qcheadertooltip: any;
  qcStartTimeSlot: any;
  qcEndTimeSlot: any;
  qcProcessorderNo: any;
  verifcationStatus: any;
  verificationStatus = 'Approve';
  qcProdCode: any;
  qcProcdDesc: any;
  today = new Date();
  groupTypeList!: any;
  isEventMode = false;
  isTimeMode = false;
  qualityGroupType: any;
  detailArray: any[] = [];
  verifyChecked = false;
  verifyCheckedArray: any[] = [];
  boolList: any;
  eventList: any;
  crewScheduleList: any;
  qcRole = false;
  plantId: any;
  currentprocessOrder: any;
  PPID: any;
  boolColId: any;
  getRowNodeId: (data: any) => any;
  boolRowIndex: any;
  selectionName: any;
  loadingOverlayComponent: any;
  constructor(
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    // private commonService: CommonService,
    private crewService: CrewScheduleService,
    private formBuilder: FormBuilder,
    private qualityParameterService: QualityParameterService,
    private enterpriseGroupService: EnterPriseGroupService,
    private processService: ProcessControlService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private sfdService: ShopFloorDataService,
    private elementRef: ElementRef
  ) {
    this.rowSelection = 'multiple';
    this.getRowNodeId = (data) => data.id;
    this.loadingOverlayComponent = 'customLoadingOverlay';
  }
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngAfterViewInit(): void {
    this.gridOptions.api.hideOverlay();
  }

  ngOnInit(): any {
    this.GetMenuAccess();
    this.GetBreadcrumList();

    this.ProcessControlDataForm = this.formBuilder.group({
      test: [''],
      test1: [''],
      test2: [''],
      test3: [''],
      test4: [''],
      test5: [''],
      test6: [''],
      test7: [''],
      test8: [''],
    });

    this.unitDetailForm = this.fb.group({
      groupList: ['', Validators.required],
      UnitNameList: ['', Validators.required],
      // fromProcessDate: ['', Validators.required],
      // toProcessDate: ['', Validators.required],
      datePicker: [''],
      groupType: [],
      intervalType: [''],
      processType: [''],
      parameterInterval: [''],
    });
    const start = new Date();
    const dates: any[] = [];
    dates.push(new Date(start.setHours(0, 0, 0, 0)).toISOString());
    dates.push(new Date().toISOString());
    this.unitDetailForm
      .get('datePicker')
      ?.setValue(dates, { emitEvent: false });
    this.getGroup();
    const source = interval(5 * 60 * 1000);
    this.subscription = source.subscribe((val) => this.onRefresh());
    this.unitDetailForm
      .get('datePicker')
      ?.valueChanges.subscribe((v) => this.onToProcessDate());
  }

  getGroup(): void {
    const key = {
      Id: 0,
    };
    this.qualityParameterService.getGroupData(key).subscribe(
      (data: any) => {
        this.group = data.masterList;
        this.unitDetailForm.get('groupList')?.setValue(this.group[0].TypeId);
      },
      (error: any) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  // scrollEnhancement(){
  //   debugger;
  //   // document.body.scrollTop = 0
  //   if(window.scrollY>0){
  //     // window.scrollTo(0, 0);
  //     window.scrollTo({top:0,behavior:'smooth'})
  //     console.log(window.scrollY)
  //   }
  //   console.log("Scroll Enhancements", window.scrollY);
  // }
  GetCrewScheduleList(): void {
    const key = {
      PUID: this.unitid,
      Date: new Date(), // .format('YYYY-MM-DD'),
    };
    this.crewService.GetCrewScheduleList(key).then((res: any) => {
      this.crewScheduleList = res.CrewScheduleList;
      if (this.crewScheduleList.length) {
        const date = new Date();
        // tslint:disable-next-line:max-line-length
        const record = this.crewScheduleList.filter(
          (c: any) =>
            date.getTime() >= new Date(c.ShiftStart).getTime() &&
            date.getTime() <= new Date(c.ShiftEnd).getTime()
        );
        if (record.length) {
          const start = new Date(record[0].ShiftStart);
          const end = new Date();
          this.unitDetailForm
            .get('datePicker')
            ?.setValue([start, end], { emitEvent: false });
        } else {
          const start = new Date();
          this.unitDetailForm
            .get('datePicker')
            ?.setValue(
              [new Date(start.setHours(0, 0, 0, 0)).toISOString(), new Date()],
              { emitEvent: false }
            );
        }
        this.GetGroupTypeList();
      } else {
        const start = new Date();
        // tslint:disable-next-line:max-line-length
        this.unitDetailForm
          .get('datePicker')
          ?.setValue(
            [new Date(start.setHours(0, 0, 0, 0)).toISOString(), new Date()],
            { emitEvent: false }
          );
        this.GetGroupTypeList();
      }
    });
  }

  getContextMenuItems = (params: any) => {
    // const that = this;
    let result: any;
    if (this.qualityGroupType === 'Time') {
      const key = params.column.colId;
      const date = new Date(new Date().setSeconds(0));
      debugger;
      const fromDate = new Date(key.substring(0, 20));
      const toDate = new Date(key.substring(23, 43));

      const array = JSON.parse(localStorage.roleName).map((v: any) =>
        v.toLowerCase()
      );
      this.qcRole = array.includes('qc leader') === true ? true : false;
      if (
        this.IsWrite &&
        this.qcRole &&
        date.getTime() >= fromDate.getTime() &&
        date.getTime() <= toDate.getTime() + 1 * 60000 &&
        (key.split('~poStatus')[1] === '' ||
          key.split('~poStatus')[1] === 'InProcess')
      ) {
        result = [
          {
            name: 'Specifiction Limits',
            action: () => {
              this.parameterId = params.node.data.parameterId;
              this.openModal(params);
            },
          },
          {
            name: 'Defect - Failure Mode Details',
            action: () => {
              this.parameterId = params.node.data.parameterId;
              this.openDefecct(params);
            },
          },
          {
            name: 'QC Verify',
            action: () => {
              this.openQc(params);
            },
          },
        ];
      } else {
        result = [
          {
            name: 'Specifiction Limits',
            action: () => {
              this.parameterId = params.node.data.parameterId;
              this.openModal(params);
            },
          },
          {
            name: 'Defect - Failure Mode Details',
            action: () => {
              this.parameterId = params.node.data.parameterId;
              this.openDefecct(params);
            },
          },
        ];
      }
    } else {
      result = [
        {
          name: 'Specifiction Limits',
          action: () => {
            this.parameterId = params.node.data.parameterId;
            this.openModal(params);
          },
        },
        {
          name: 'Defect - Failure Mode Details',
          action: () => {
            this.parameterId = params.node.data.parameterId;
            this.openDefecct(params);
          },
        },
        {
          name: 'QC Verify',
          action: () => {
            this.openQc(params);
          },
        },
      ];
    }
    return result;
  };
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  onChangeGroupGetMachine(): void {
    this.UnitName = ' ';
    this.rowData = this.emptygrid;
  }
  onCavityChange(event: any): any {
    const instance = this.gridApi.getFilterInstance('Cavity');
    const myArray: any = [event.value.toString()];
    instance.setModel({
      values: myArray.length > 0 ? myArray : null,
    });
    this.gridApi.onFilterChanged();
  }
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onCellValueChanged(params: any): void {
    debugger;
    console.log(params);
    if (this.IsWrite) {
      const dataType = params.data.DataType;
      if (dataType === 'Bool' || dataType === 'Date') {
        let newVal = params.value;
        if (dataType === 'Int') {
          const charcter = this.isCharNumeric(newVal);
          if (!charcter) {
            this.toaster.warning('DigitsOnly', 'Please Select Digits Only');
            // newVal = params.oldValue;
            params.node.setDataValue(params.colDef.field, params.oldValue);
            return;
          }
        }
        if (dataType === 'Float') {
          const float = parseFloat(newVal);
          // tslint:disable-next-line:use-isnan
          if (isNaN(float)) {
            this.toaster.warning(
              'Decimal Only',
              'Please Select decimal values Only'
            );
            // newVal = params.oldValue;
            params.node.setDataValue(params.colDef.field, params.oldValue);
            return;
          } else {
            params.value = parseFloat(params.value).toFixed(2);
          }
        }
        if (dataType === 'Date') {
          const date = params.value;
          if (date.match(new RegExp('/', 'g')).length === 2) {
          } else {
            this.toaster.warning(
              'SlashError',
              'Please Select Correct Date Format'
            );
            return;
          }
        }
        const startIntervalDate = params.colDef.field.substring(0, 20);
        const endIntervalDate = params.colDef.field.substring(23, 43);
        if (new Date() > new Date(endIntervalDate)) {
          this.toaster.warning('Time Passed', 'Slot time has been passed');
          return;
        }
        // const fromDate = new Date(params.colDef.field.substring(0, 19).replace(/-/g, '/'));
        // const toDate = new Date(params.colDef.field.substring(22, 41).replace(/-/g, '/'));
        let cellData: any;
        if (this.qualityGroupType === 'Time') {
          if (!params.oldValue || params.oldValue === '') {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: endIntervalDate,
              endInterval: endIntervalDate,
              startInterval: startIntervalDate,
              varId: params.data.parameterId,
              isOldValue: false,
              cavity: params.data.Cavity,
              po: params.column.colId.split('~')[1],
              createdBy: JSON.parse(localStorage.user).UserId,
              prodId:
                params.column.colId.split('~prodId')[1] !== undefined
                  ? params.column.colId.split('~prodId')[1].split('~')[0]
                  : '',
              ppId:
                params.column.colId.split('~ppId')[1] !== undefined
                  ? params.column.colId.split('~ppId')[1].split('~')[0]
                  : '',
              uomId:
                params.column.colId.split('~uomId')[1] !== undefined
                  ? params.column.colId.split('~uomId')[1].split('~')[0]
                  : '',
              ppdId:
                params.column.colId.split('~ppdId')[1] !== undefined
                  ? params.column.colId.split('~ppdId')[1].split('~')[0]
                  : '',
              intervalType: this.qualityGroupType,
            };
          } else {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: endIntervalDate,
              varId: params.data.parameterId,
              endInterval: endIntervalDate,
              startInterval: startIntervalDate,
              isOldValue: true,
              cavity: params.data.Cavity,
              po: params.column.colId.split('~')[1],
              createdBy: JSON.parse(localStorage.user).UserId,
              prodId:
                params.column.colId.split('~prodId')[1] !== undefined
                  ? params.column.colId.split('~prodId')[1].split('~')[0]
                  : '',
              ppId:
                params.column.colId.split('~ppId')[1] !== undefined
                  ? params.column.colId.split('~ppId')[1].split('~')[0]
                  : '',
              uomId:
                params.column.colId.split('~uomId')[1] !== undefined
                  ? params.column.colId.split('~uomId')[1].split('~')[0]
                  : '',
              ppdId:
                params.column.colId.split('~ppdId')[1] !== undefined
                  ? params.column.colId.split('~ppdId')[1].split('~')[0]
                  : '',
              intervalType: this.qualityGroupType,
            };
          }
        } else if (
          this.qualityGroupType === 'Event' ||
          this.qualityGroupType === 'Manual'
        ) {
          if (!params.oldValue || params.oldValue === '') {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: startIntervalDate,
              endInterval: startIntervalDate,
              startInterval: startIntervalDate,
              varId: params.data.parameterId,
              isOldValue: false,
              cavity: params.data.Cavity,
              po: params.column.colId.split('~')[1],
              eventId: params.column.colId.split('~EventId')[1].split('~')[0],
              createdBy: JSON.parse(localStorage.user).UserId,
              intervalType: this.qualityGroupType,
            };
          } else {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: startIntervalDate,
              varId: params.data.parameterId,
              endInterval: startIntervalDate,
              startInterval: startIntervalDate,
              isOldValue: true,
              cavity: params.data.Cavity,
              po: params.column.colId.split('~')[1],
              eventId: params.column.colId.split('~EventId')[1].split('~')[0],
              createdBy: JSON.parse(localStorage.user).UserId,
              intervalType: this.qualityGroupType,
            };
          }
        }
        this.processService.saveQualityProcessControl(cellData).subscribe(
          (response: any) => {
            // this.toaster.success('Success', 'Data Added Successfully');
            // this.showPopupMsg(response[0].result);
            const msg = response[0].result;
            if (msg !== null || msg !== '') {
              if (msg.substring(2, 1) === '0') {
                this.toaster.success('Success', msg.substring(3, msg.length));
                this.gridApi.refreshCells(params);
                // params.node.setDataValue(params.colDef.field, response[0].value);
                // params.node.setDataValue(params.colDef.field, newVal);
              } else {
                this.toaster.error('Error', msg.substring(3, msg.length));
                // params.node.setDataValue(params.colDef.field, params.oldValue);
              }
            }
          },
          (error: any) => {
            return this.toaster.error('Error', error.message);
          }
        );
      }
    }
  }
  onSelectionChanged(params: any): any {}
  onClickParamterAnalysis(): any {
    debugger;
    // const checkrow = this.gridApi.getSelectedRows().map((value: any) => value.DataType).includes('String');
    // if (checkrow) {
    //   this.toaster.warning('warning', 'Please select Int & Bool rows for Paramater Analysis');
    //   return;
    // }
    const selectedRows = this.gridApi
      .getSelectedRows()
      .filter(
        (c: any) =>
          c.DataType === 'Int' ||
          c.DataType === 'Bool' ||
          c.DataType === 'Float'
      );
    this.spinnerButtonOptions.active = true;
    this.chartArray = [];
    // tslint:disable-next-line:max-line-length
    this.dialog.open(this.visibleYearlyTargetTbl, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '100vh',
      minWidth: '95vw',
      maxHeight: '100vh',
    });
    selectedRows.forEach(async (element: any) => {
      const parameterName = element.Parameter + ' (' + element.Cavity + ')';
      const key = {
        parameterId: element.parameterId,
        cavity: element.Cavity,
        groupType: this.qualityGroupType,
      };
      const chartObj = { id: '', chartOption: {} };
      chartObj.id = element.parameterId + '_' + element.Cavity;
      await this.processService.getQualityProcessParameterAnalysis(key).then(
        (data: any) => {
          this.spinnerButtonOptions.active = false;
          const LRLarray: any[] = [];
          const LSLarray: any[] = [];
          const LWLarray: any[] = [];
          const TGTarray: any[] = [];
          const UWLarray: any[] = [];
          const USLarray: any[] = [];
          const URLarray: any[] = [];
          const array: any[] = [];
          data.forEach((c: any) => {
            // tslint:disable-next-line:one-variable-per-declaration
            const arr = [],
              arrLRL = [];
            const arrLSL = [];
            const arrLWL = [];
            const arrTGT = [];
            const arrUWL = [];
            const arrUSL = [];
            const arrURL = [];
            arr.push(new Date(c.processTime).getTime());
            arr.push(parseFloat(c.result));
            // array.push(arr);
            array.push({
              x: new Date(c.processTime).getTime(),
              y: isNaN(c.result) ? '' : parseFloat(c.result),
              label: c.Product,
            });
            arrLRL.push(new Date(c.processTime).getTime());
            arrLRL.push(parseFloat(c.LRL));
            LRLarray.push(arrLRL);
            arrLSL.push(new Date(c.processTime).getTime());
            arrLSL.push(parseFloat(c.LSL));
            LSLarray.push(arrLSL);
            arrLWL.push(new Date(c.processTime).getTime());
            arrLWL.push(parseFloat(c.LWL));
            LWLarray.push(arrLWL);
            arrTGT.push(new Date(c.processTime).getTime());
            arrTGT.push(parseFloat(c.TGT));
            TGTarray.push(arrTGT);
            arrUWL.push(new Date(c.processTime).getTime());
            arrUWL.push(parseFloat(c.UWL));
            UWLarray.push(arrUWL);
            arrUSL.push(new Date(c.processTime).getTime());
            arrUSL.push(parseFloat(c.USL));
            USLarray.push(arrUSL);
            arrURL.push(new Date(c.processTime).getTime());
            arrURL.push(parseFloat(c.URL));
            URLarray.push(arrURL);
          });
          this.stock = new StockChart({
            plotOptions: {
              series: {
                turboThreshold: 50000000,
              },
            },
            chart: {
              height: 730,
            },
            rangeSelector: {
              selected: 1,
            },
            title: {
              text: parameterName.toUpperCase(),
            },
            credits: {
              enabled: false,
            },
            legend: {
              enabled: true,
            },
            tooltip: {
              formatter(): any {
                // let s = '<b>' + StockChart.dateFormat('%A, %b %e, %Y', this.x) + '</b>';
                let s = '';
                this.points?.forEach((point: any) => {
                  console.log(point);
                  if (point.series.name === 'Result') {
                    s +=
                      '<br/> <span style="color: ' +
                      point.series.color +
                      '">' +
                      point.series.name +
                      ' : ' +
                      point.y +
                      '<span><br/>' +
                      '<br/><span style="color: #252031">Product : ' +
                      point.point.label +
                      '<span><br/>';
                  } else {
                    s +=
                      '<br/> <span style="color: ' +
                      point.series.color +
                      '">' +
                      point.series.name +
                      ' : ' +
                      point.y +
                      '<span><br/>';
                  }
                });
                return s;
              },
            },
            yAxis: {
              title: {
                text: 'Result',
              },
              opposite: false,
              // tickInterval: 50
            },
            series: [
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'Result',
                type: 'line',
                data: array,
                color: '#040496',
                marker: {
                  enabled: true,
                },
                label: {
                  onArea: false,
                },
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LRL',
                type: 'line',
                data: LRLarray,
                color: '#00ff00',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LSL',
                type: 'line',
                data: LSLarray,
                color: '#FF5733',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LWL',
                type: 'line',
                data: LWLarray,
                color: '#9C412D',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'TGT',
                type: 'line',
                data: TGTarray,
                color: '#9C902D',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'UWL',
                type: 'line',
                data: UWLarray,
                color: '#25B79B',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'USL',
                type: 'line',
                data: USLarray,
                color: '#257AB7',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'URL',
                type: 'line',
                data: URLarray,
                color: '#FF0909',
                dashStyle: 'ShortDash',
              },
            ],
          });

          chartObj.chartOption = this.stock;
          this.chartArray.push(chartObj);
          console.log(this.chartArray);
        },
        (error: any) => {
          this.toaster.error('Error', error.ErrorMessage);
          this.spinnerButtonOptions.active = false;
        }
      );
    });
    this.spinnerButtonOptions.active = false;
  }
  // onClickParamterComparsion(): void {
  //   const selectedRows = this.gridApi.getSelectedRows(); // .filter((c: any) => c.DataType === 'Int' || c.DataType === 'Bool');
  //   this.spinnerButtonOptions1.active = true;
  //   // tslint:disable-next-line:max-line-length
  //   this.dialog.open(this.visibleParameterComparison, { panelClass: 'custom-dialog-container', width: '95vw !important', height: '95vw', minWidth: '95vw', maxHeight: '90vh' });

  //   const array: any[] = [];
  //   let values = selectedRows.map((value: any) => value.parameterId);
  //   values = [...new Set(values)];
  //   values.forEach((element: any) => {
  //     const key = {
  //       parameterId: element,
  //     };
  //     this.processService.getQualityProcessParameterComparison(key).then((data: any) => {
  //         this.spinnerButtonOptions1.active = false;
  //         const obj = {
  //           name: data[0].name,
  //           type: 'line',
  //           data: data.map((value: any) => parseFloat(value.result))
  //         };
  //         array.push(obj);
  //         this.chart = new Chart({
  //           chart: {
  //             type: 'line',
  //             height: 700,
  //           },
  //           title: {
  //             text: 'Parameter Comparison'
  //           },
  //           credits: {
  //             enabled: false
  //           },
  //           series: array
  //         });
  //       });
  //   });
  //   this.spinnerButtonOptions1.active = false;
  // }
  // tslint: disable-next - line: typedef
  onClickParamterComparsion(): void {
    debugger;
    const selectedRows = this.gridApi
      .getSelectedRows()
      .filter((c: any) => c.DataType === 'Int' || c.DataType === 'Float');
    this.spinnerButtonOptions1.active = true;
    // tslint:disable-next-line:max-line-length
    this.dialog.open(this.visibleParameterComparison, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '100vw',
      minWidth: '95vw',
      maxHeight: '100vh',
    });

    const array: any = [];
    const yAxisArr: any[] = [];
    const values = selectedRows
      .map((value: any) => value.parameterId)
      .toString();
    const valueNames = selectedRows.map((value: any) => value.Parameter);
    const seletedGroups = selectedRows.map(
      (value: any) => value.ParamaterGroup
    );
    const uniqueGroups = [...new Set(seletedGroups)];
    const dataArrName: any[] = [];
    const key = {
      parameterId: values,
      groupType: this.qualityGroupType,
    };
    this.spinner.show();
    this.processService.getQualityProcessParameterComparison(key).then(
      (data: any) => {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < valueNames.length; i++) {
          const dataArr = [];
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < data.length; j++) {
            // const arr = [];
            if (valueNames[i] === data[j].name) {
              // arr.push(new Date(data[j].processTime).getTime());
              // arr.push(parseFloat(data[j].result));
              // dataArr.push(arr);
              dataArr.push({
                x: new Date(data[j].processTime).getTime(),
                y: isNaN(data[j].result) ? '' : parseFloat(data[j].result),
                label: data[j].product,
              });
            }
          }
          let negativeArr = [];
          console.log(dataArr);
          negativeArr = dataArr.map((c) => c.y);
          const obj = {
            tooltip: {
              valueDecimals: 2,
            },
            name: valueNames[i],
            type: 'line',
            data: dataArr,
            color: this.highChartsColor[i],
            yAxis: i,
          };
          array.push(obj);
          yAxisArr.push({
            lineWidth: 1,
            opposite: negativeArr.some((v) => v < 0) ? true : false,
            title: {
              text: valueNames[i],
              style: {
                color: this.highChartsColor[i],
              },
            },
            labels: {
              style: {
                color: this.highChartsColor[i],
              },
            },
          });
        }
        this.chart = new StockChart({
          chart: {
            height: 730,
          },
          plotOptions: {
            series: {
              turboThreshold: 50000000,
              events: {
                hide(): any {
                  debugger;
                  // this.yAxis.axisTitle.hide();
                  this.yAxis.update({
                    visible: false,
                  });
                },
                show(): any {
                  this.yAxis.update({
                    visible: true,
                  });
                },
              },
            },
          },
          rangeSelector: {
            selected: 1,
          },
          title: {
            text: uniqueGroups.toString(),
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: true,
          },
          tooltip: {
            formatter(): any {
              // let s = '<b>' + StockChart.dateFormat('%A, %b %e, %Y', this.x) + '</b>';
              let s = '';
              this.points?.forEach((point: any) => {
                console.log(point);
                s +=
                  '<br/> <span style="color: ' +
                  point.series.color +
                  '">' +
                  point.series.name +
                  ' : ' +
                  point.y +
                  '<span><br/>' +
                  '<br/><span style="color: ' +
                  point.series.color +
                  '">Product : ' +
                  point.point.label +
                  '<span><br/>';
              });
              return s;
            },
          },
          yAxis: yAxisArr,
          series: array,
        });
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
    this.spinnerButtonOptions1.active = false;
  }

  // this.chart = new StockChart({
  //   rangeSelector: {
  //     selected: 1
  //   },
  //   title: {
  //     text: 'series'
  //   },
  //   credits: {
  //     enabled: false
  //   },
  //   yAxis: {
  //     title: {
  //       text: 'Result'
  //     },
  //     opposite: false,
  //   },
  //   series: array
  // });

  // close model
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  SelectVarUnitName(unitname: any): any {
    if (unitname.title === 'UNT') {
      this.varUnitName = unitname.name;
      this.isPlantGroupModelVisible = false;
    }
  }
  onClickControlProcessOrder(): any {
    const key = {
      fromDate: this.unitDetailForm.value.datePicker[0],
      toDate: this.unitDetailForm.value.datePicker[1],
      puId: this.unitid,
      cavity: this.maxCavity,
      processType: this.unitDetailForm.value.processType,
    };
    this.spinnerButtonOptionsOrders.active = true;
    this.processService
      .getQualityControlProcessOrder(key)
      .subscribe((response: any) => {
        this.dialog.open(this.displayQualityOrdersModal, {
          height: '90%',
          width: '90%',
        });
        this.qualityProcessOrder.data = response;
        this.getPaginator();
      });
    this.spinnerButtonOptionsOrders.active = false;
  }
  getPaginator(): void {
    setTimeout(() => {
      this.qualityProcessOrder.paginator = this.paginator;
      this.qualityProcessOrder.sort = this.sort;
    }, 1000);
  }
  getPaginatorqc(): void {
    setTimeout(() => {
      this.qcdataSource.paginator = this.paginator;
      this.qcdataSource.sort = this.sort;
    }, 1000);
  }
  onProcessDate(): any {
    const getdata = {
      // date: moment(this.unitDetailForm.value.fromProcessDate).format('YYYY-MM-DD'),
      fromDate: this.unitDetailForm.value.datePicker[0],
      toDate: this.unitDetailForm.value.datePicker[1],
      puId: this.unitid,
      cavity: this.maxCavity,
      processType: this.unitDetailForm.value.processType,
    };
    // if (getdata.puId === undefined || !getdata.fromDate) {
    //   this.toaster.warning('Warning', 'Please Select Plant And Dates');
    //   return;
    // }
  }
  onRefresh(): void {
    if (this.unitDetailForm.valid) {
      this.unitDetailForm
        .get('datePicker')
        ?.setValue([this.unitDetailForm.value.datePicker[0], new Date()], {
          emitEvent: false,
        });
      this.rowData = [];
      this.onToProcessDate();
    } else {
      // this.toaster.warning('Select all mandatory fields');
      this.spinner.hide();
    }
    // this.gridOptions.api.redrawRows();
  }
  onToProcessDate(): any {
    const getdata = {
      // date: moment(this.unitDetailForm.value.fromProcessDate).format('YYYY-MM-DD'),
      fromDate: this.unitDetailForm.value.datePicker[0],
      toDate: this.unitDetailForm.value.datePicker[1],
      puId: this.unitid,
      cavity: this.maxCavity,
      processType: this.unitDetailForm.value.processType,
      interval: this.unitDetailForm.value.parameterInterval,
      currentDateTime: new Date(),
      groupType: this.qualityGroupType,
      // tslint:disable-next-line:max-line-length
      intervalType:
        this.qualityGroupType === 'Time' || this.qualityGroupType === 'Manual'
          ? this.qualityGroupType
          : this.unitDetailForm.value.intervalType,
    };
    if (getdata.puId === undefined || !getdata.fromDate || !getdata.toDate) {
      this.toaster.warning('Warning', 'Please Select Plant And Dates');
      return;
    }
    const from = this.unitDetailForm.value.datePicker[0];
    const To = this.unitDetailForm.value.datePicker[1];
    const timeDiff = moment(To).diff(moment(from), 'hours');

    // if (timeDiff > 24) {
    //   this.toaster.error('Error', 'Select Date Range between 24 hours');
    //   return;
    // }
    this.gridApi.showLoadingOverlay();
    // this.gridOptions.api.hideOverlay();
    this.processService.getQualityProcessControl(getdata).then(
      (response: any) => {
        this.gridOptions.api.hideOverlay();
        this.rowData = response;
        if (this.rowData) {
          this.columnDefs = this.generateColumns(this.rowData);
          setTimeout(() => {
            this.gridOptions.api.columnController.resetColumnState();
            if (this.qualityGroupType === 'Time') {
              const scroll = document.querySelector(
                '.ag-body-horizontal-scroll-viewport'
              );
              if (scroll !== null) {
                scroll.scrollLeft = scroll.scrollWidth;
              }
            }
            // this.gridApi.refresh();
          }, 100);
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  private getCategoryArray() {
    return {
      values: this.boolList,
      // const entries = Object.values(this.boolList);
    };
  }
  generateColumns(data: any[]): any {
    this.columnDefs = [];
    let columnDefinitions: any[] = [];
    //  this.gridOptions.columnApi.setColumnsVisible('KPIId', false);
    data.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key === 'Cavity' ? 'Sample' : key, // .toUpperCase()
          // headerClass: 'header-style',
          headerClass: 'ag-header-cell-text',
          headerTooltip: key,
          tooltipComponent: 'customTooltip',
          tooltipValueGetter,
          field: key,
          toolTip: key,
          resizable: true,
          wrapText: true,
          autoHeight: true,
          sortable: true,
          rowGroup: key === 'ParamaterGroup' ? true : false,
          width:
            key === 'Cavity' || key === 'DataType' || key === 'ParamaterGroup'
              ? 150
              : key === 'Parameter'
              ? 300
              : 200,
          cellStyle: {},
          hide:
            key === 'ParamaterGroup' || key === 'Cavity' || key === 'DataType'
              ? true
              : false,
          filter: true,
          editable: false,
          valueParser: {},
          // valueGetter: {},
          // tslint:disable-next-line:max-line-length
          valueGetter:
            key !== 'Parameter' &&
            key !== 'ParamaterGroup' &&
            key !== 'Cavity' &&
            key !== 'DataType' &&
            key !== 'parameterId'
              ? this.abValueGetter
              : null,
          // tslint:disable-next-line:max-line-length
          // valueFormatter: (key !== 'Parameter' && key !== 'ParamaterGroup' && key !== 'Cavity' && key !== 'DataType' && key !== 'parameterId') ? (params: any) => this.abValueGetter(params.value) : '', // (params: any) => this.abValueGetter(params.value),
          pinned:
            key === 'Parameter' ||
            key === 'Cavity' ||
            key === 'DataType' ||
            key === 'ParamaterGroup'
              ? 'left'
              : '',
          lockPinned:
            key === 'Parameter' ||
            key === 'Cavity' ||
            key === 'DataType' ||
            key === 'ParamaterGroup'
              ? true
              : false,
          rowSelection: 'multiple',
          onCellClicked: (params: any) => {
            if (params.data.DataType === 'Bool') {
              params.colDef.cellEditor = 'agRichSelectCellEditor';
              this.GetAllDataTypesDetail(params.data.parameterId);
              this.selectionName = params.data.Parameter;
              if (mappedColumn.editable) {
                this.dialog.open(this.qualityBoolListModal, {
                  height: 'auto',
                  width: '45%',
                });
              }
              this.boolColId = params.column.colId;
              this.boolRowIndex = params.rowIndex;
            } else if (params.data.DataType === 'Date') {
              // params.colDef.cellEditor = 'primeCellEditor';
              this.selectionName = params.data.Parameter;
              if (mappedColumn.editable) {
                this.reOpenCalendar();
              }
              this.boolColId = params.column.colId;
              this.boolRowIndex = params.rowIndex;
            } else {
              params.colDef.cellEditor = 'agTextCellEditor';
            }
          },
          cellEditor: null,
          // tslint:disable-next-line:max-line-length
          cellEditorParams:
            key !== 'Parameter' &&
            key !== 'ParamaterGroup' &&
            key !== 'Cavity' &&
            key !== 'DataType'
              ? (params: any): Promise<any> => {
                  return params.value;
                }
              : null,
        };
        if (this.qualityGroupType === 'Time') {
          mappedColumn.cellStyle = (params: any): any => {
            if (
              key !== 'Parameter' &&
              key !== 'ParamaterGroup' &&
              key !== 'Cavity' &&
              key !== 'DataType' &&
              key !== 'parameterId'
            ) {
              const fromDate = new Date(key.substring(0, 20));
              const toDate = new Date(key.substring(23, 43));
              // tslint:disable-next-line:max-line-length
              if (
                date.getTime() >= fromDate.getTime() &&
                date.getTime() <= toDate.getTime() + 1 * 60000
                // key.split('~poStatus')[1] === 'InProcess'
              ) {
                if (
                  key.split('~poStatus')[1] === '' ||
                  key.split('~poStatus')[1] === 'InProcess'
                ) {
                  return {
                    'font-weight': 'bold',
                    color: params.value
                      ? params.data[params.colDef.field].split('|')[1]
                      : 'black',
                    'background-color': '#bdccdd',
                  };
                } else {
                  if (params.value) {
                    return {
                      color: params.data[params.colDef.field].split('|')[1],
                    };
                  }
                }
              } else {
                if (params.value) {
                  return {
                    color: params.data[params.colDef.field].split('|')[1],
                  };
                }
              }
            }
          };
          const date = new Date(new Date().setSeconds(0));
          if (
            key !== 'Parameter' &&
            key !== 'ParamaterGroup' &&
            key !== 'Cavity' &&
            key !== 'DataType' &&
            key !== 'parameterId'
          ) {
            const fromDate = new Date(key.substring(0, 20));
            const toDate = new Date(key.substring(23, 43));
            // tslint:disable-next-line:max-line-length
            if (
              date.getTime() >= fromDate.getTime() &&
              date.getTime() <= toDate.getTime() + 1 * 60000
              // key.split('~poStatus')[1] === 'InProcess'
            ) {
              if (
                key.split('~poStatus')[1] === '' ||
                key.split('~poStatus')[1] === 'InProcess'
              ) {
                mappedColumn.editable = true;
                mappedColumn.lockPinned = true;
                mappedColumn.pinned = 'left';
              } else {
                mappedColumn.editable = false;
              }
              mappedColumn.valueParser = (params: any) => {
                debugger;
                console.log(params);
                if (this.IsWrite) {
                  const dataType = params.data.DataType;
                  if (
                    dataType === 'Int' ||
                    dataType === 'Float' ||
                    dataType === 'String'
                  ) {
                    const dataType = params.data.DataType;
                    let newVal = params.newValue;
                    if (dataType === 'Int') {
                      const charcter = Number.isInteger(Number(newVal));
                      if (!charcter) {
                        this.toaster.warning(
                          'DigitsOnly',
                          'Please Select Int Value Only'
                        );
                        // newVal = params.oldValue;
                        params.node.setDataValue(
                          params.colDef.field,
                          params.oldValue
                        );
                        return;
                      } else {
                        params.newValue = parseInt(params.newValue, 10);
                      }
                    }
                    if (dataType === 'Float') {
                      const float = parseFloat(newVal);
                      // tslint:disable-next-line:use-isnan
                      if (isNaN(float)) {
                        this.toaster.warning(
                          'FloatOnly',
                          'Please Select Float Only'
                        );
                        // newVal = params.oldValue;
                        params.node.setDataValue(
                          params.colDef.field,
                          params.oldValue
                        );
                        return;
                      } else {
                        params.newValue = parseFloat(params.newValue).toFixed(
                          2
                        );
                      }
                    }
                    if (dataType === 'Date') {
                      const date = params.newValue;
                      if (date.match(new RegExp('/', 'g')).length === 2) {
                      } else {
                        this.toaster.warning(
                          'SlashError',
                          'Please Select Correct Date Format'
                        );
                        return;
                      }
                    }
                    const startIntervalDate = params.colDef.field.substring(
                      0,
                      20
                    );
                    const endIntervalDate = params.colDef.field.substring(
                      23,
                      43
                    );
                    if (new Date() > new Date(endIntervalDate)) {
                      this.toaster.warning(
                        'Time Passed',
                        'Slot time has been passed'
                      );
                      return;
                    }
                    // const fromDate = new Date(params.colDef.field.substring(0, 19).replace(/-/g, '/'));
                    // const toDate = new Date(params.colDef.field.substring(22, 41).replace(/-/g, '/'));
                    let cellData: any;
                    if (!params.oldValue || params.oldValue === '') {
                      cellData = {
                        result: params.newValue,
                        entryOn: new Date(),
                        resultOn: endIntervalDate,
                        endInterval: endIntervalDate,
                        startInterval: startIntervalDate,
                        varId: params.data.parameterId,
                        isOldValue: false,
                        cavity: params.data.Cavity,
                        po: params.column.colId.split('~')[1],
                        createdBy: JSON.parse(localStorage.user).UserId,
                        prodId:
                          params.column.colId.split('~prodId')[1] !== undefined
                            ? params.column.colId
                                .split('~prodId')[1]
                                .split('~')[0]
                            : '',
                        ppId:
                          params.column.colId.split('~ppId')[1] !== undefined
                            ? params.column.colId
                                .split('~ppId')[1]
                                .split('~')[0]
                            : '',
                        uomId:
                          params.column.colId.split('~uomId')[1] !== undefined
                            ? params.column.colId
                                .split('~uomId')[1]
                                .split('~')[0]
                            : '',
                        ppdId:
                          params.column.colId.split('~ppdId')[1] !== undefined
                            ? params.column.colId
                                .split('~ppdId')[1]
                                .split('~')[0]
                            : '',
                        intervalType: this.qualityGroupType,
                      };
                    } else {
                      cellData = {
                        result: params.newValue,
                        entryOn: new Date(),
                        resultOn: endIntervalDate,
                        varId: params.data.parameterId,
                        endInterval: endIntervalDate,
                        startInterval: startIntervalDate,
                        isOldValue: true,
                        cavity: params.data.Cavity,
                        po: params.column.colId.split('~')[1],
                        createdBy: JSON.parse(localStorage.user).UserId,
                        prodId:
                          params.column.colId.split('~prodId')[1] !== undefined
                            ? params.column.colId
                                .split('~prodId')[1]
                                .split('~')[0]
                            : '',
                        ppId:
                          params.column.colId.split('~ppId')[1] !== undefined
                            ? params.column.colId
                                .split('~ppId')[1]
                                .split('~')[0]
                            : '',
                        uomId:
                          params.column.colId.split('~uomId')[1] !== undefined
                            ? params.column.colId
                                .split('~uomId')[1]
                                .split('~')[0]
                            : '',
                        ppdId:
                          params.column.colId.split('~ppdId')[1] !== undefined
                            ? params.column.colId
                                .split('~ppdId')[1]
                                .split('~')[0]
                            : '',
                        intervalType: this.qualityGroupType,
                      };
                    }
                    this.processService
                      .saveQualityProcessControl(cellData)
                      .subscribe(
                        (response: any) => {
                          // this.toaster.success('Success', 'Data Added Successfully');
                          // this.showPopupMsg(response[0].result);
                          const msg = response[0].result;
                          if (msg !== null || msg !== '') {
                            if (msg.substring(2, 1) === '0') {
                              this.toaster.success(
                                'Success',
                                msg.substring(3, msg.length)
                              );
                              params.node.setDataValue(
                                params.colDef.field,
                                response[0].value
                              );
                              if (response[0].value) {
                                const color = response[0].value.split('|')[1];
                                if (color === '#ff2121') {
                                  this.sendNotificationRole(
                                    `NCR ID - ${response[0].ncrId}
                                     Out of Specification Limits! The Defect had happened against PO: ${
                                       params.column.colId.split('~')[1]
                                     }`
                                  );
                                }
                                if (color === '#f49203') {
                                  this.sendNotificationRole(
                                    `NCR ID - ${response[0].ncrId}
                                    There is a risk of defect happening against PO ${
                                      params.column.colId.split('~')[1]
                                    }`
                                  );
                                }
                              }
                              // params.node.setDataValue(params.colDef.field, newVal);
                            } else {
                              this.toaster.error(
                                'Error',
                                msg.substring(3, msg.length)
                              );
                              params.node.setDataValue(
                                params.colDef.field,
                                response[0].value
                              );
                            }
                          }
                        },
                        (error: any) => {
                          return this.toaster.error('Error', error.message);
                        }
                      );
                    // }
                  }
                }
              };
            }
          }
          if (
            key !== 'Parameter' &&
            key !== 'ParamaterGroup' &&
            key !== 'parameterId' &&
            key !== 'Cavity' &&
            key !== 'DataType'
          ) {
            const fromDate = new Date(key.substring(0, 20));
            const toDate = new Date(key.substring(23, 43));
            mappedColumn.headerName =
              moment(fromDate).format('HH:mm:ss') +
              ' - ' +
              moment(toDate).format('HH:mm:ss') +
              ' - ' +
              key.split('~')[1] +
              ' - ' +
              key.split('~poStatus')[1];
            mappedColumn.headerTooltip = 'PO: ' + key.split('~')[1];
          }
        }
        if (
          this.qualityGroupType === 'Event' ||
          this.qualityGroupType === 'Manual'
        ) {
          if (
            key !== 'Parameter' &&
            key !== 'ParamaterGroup' &&
            key !== 'parameterId' &&
            key !== 'Cavity' &&
            key !== 'DataType'
          ) {
            // tslint:disable-next-line:max-line-length
            if (this.qualityGroupType === 'Event') {
              const fromDate = new Date(key.substring(0, 20));
              mappedColumn.headerName =
                moment(fromDate).format('HH:mm:ss') +
                ' - ' +
                `PO  :  ${key.split('~')[1]} LotCode:  ${
                  key.split('~lotCode')[1].split('~')[0]
                }`;
            } else {
              const fromDate = new Date(key.substring(0, 20));
              mappedColumn.headerName =
                moment(fromDate).format('HH:mm:ss') + ' - ' + key.split('~')[1];
              mappedColumn.headerTooltip = `PO  :  ${key.split('~')[1]}`;
            }
            mappedColumn.editable = true;
          }
          mappedColumn.cellStyle = (params: any): any => {
            if (
              key !== 'Parameter' &&
              key !== 'ParamaterGroup' &&
              key !== 'Cavity' &&
              key !== 'DataType' &&
              key !== 'parameterId'
            ) {
              // if (params.value) {
              return {
                'font-weight': 'bold',
                color: params.value
                  ? params.data[params.colDef.field].split('|')[1]
                  : 'black',
                'background-color': '#bdccdd',
              };
              // }
            }
          };
          mappedColumn.valueParser = (params: any) => {
            debugger;
            console.log(params);
            if (this.IsWrite) {
              const dataType = params.data.DataType;
              if (
                dataType === 'Int' ||
                dataType === 'Float' ||
                dataType === 'String'
              ) {
                const dataType = params.data.DataType;
                let newVal = params.newValue;
                if (dataType === 'Int') {
                  const charcter = Number.isInteger(Number(newVal));
                  if (!charcter) {
                    this.toaster.warning(
                      'DigitsOnly',
                      'Please Select Int Value Only'
                    );
                    // newVal = params.oldValue;
                    params.node.setDataValue(
                      params.colDef.field,
                      params.oldValue
                    );
                    return;
                  } else {
                    params.newValue = parseInt(params.newValue, 10);
                  }
                }
                if (dataType === 'Float') {
                  const float = parseFloat(newVal);
                  // tslint:disable-next-line:use-isnan
                  if (isNaN(float)) {
                    this.toaster.warning(
                      'FloatOnly',
                      'Please Select Float Only'
                    );
                    // newVal = params.oldValue;
                    params.node.setDataValue(
                      params.colDef.field,
                      params.oldValue
                    );
                    return;
                  } else {
                    params.newValue = parseFloat(params.newValue).toFixed(2);
                  }
                }
                if (dataType === 'Date') {
                  const date = params.newValue;
                  if (date.match(new RegExp('/', 'g')).length === 2) {
                  } else {
                    this.toaster.warning(
                      'SlashError',
                      'Please Select Correct Date Format'
                    );
                    return;
                  }
                }
                const startIntervalDate = params.colDef.field.substring(0, 20);
                const endIntervalDate = params.colDef.field.substring(23, 43);
                if (new Date() > new Date(endIntervalDate)) {
                  this.toaster.warning(
                    'Time Passed',
                    'Slot time has been passed'
                  );
                  return;
                }
                // const fromDate = new Date(params.colDef.field.substring(0, 19).replace(/-/g, '/'));
                // const toDate = new Date(params.colDef.field.substring(22, 41).replace(/-/g, '/'));
                let cellData: any;
                if (!params.oldValue || params.oldValue === '') {
                  cellData = {
                    result: params.newValue,
                    entryOn: new Date(),
                    resultOn: startIntervalDate,
                    endInterval: startIntervalDate,
                    startInterval: startIntervalDate,
                    varId: params.data.parameterId,
                    isOldValue: false,
                    cavity: params.data.Cavity,
                    po: params.column.colId.split('~')[1],
                    eventId: params.column.colId
                      .split('~EventId')[1]
                      .split('~')[0],
                    createdBy: JSON.parse(localStorage.user).UserId,
                    intervalType: this.qualityGroupType,
                    prodId:
                      params.column.colId.split('~prodId')[1] !== undefined
                        ? params.column.colId.split('~prodId')[1].split('~')[0]
                        : '',
                    ppId:
                      params.column.colId.split('~ppId')[1] !== undefined
                        ? params.column.colId.split('~ppId')[1].split('~')[0]
                        : '',
                    uomId:
                      params.column.colId.split('~uomId')[1] !== undefined
                        ? params.column.colId.split('~uomId')[1].split('~')[0]
                        : '',
                    ppdId:
                      params.column.colId.split('~ppdId')[1] !== undefined
                        ? params.column.colId.split('~ppdId')[1].split('~')[0]
                        : '',
                  };
                } else {
                  cellData = {
                    result: params.newValue,
                    entryOn: new Date(),
                    resultOn: startIntervalDate,
                    varId: params.data.parameterId,
                    endInterval: startIntervalDate,
                    startInterval: startIntervalDate,
                    isOldValue: true,
                    cavity: params.data.Cavity,
                    po: params.column.colId.split('~')[1],
                    eventId: params.column.colId
                      .split('~EventId')[1]
                      .split('~')[0],
                    createdBy: JSON.parse(localStorage.user).UserId,
                    intervalType: this.qualityGroupType,
                    prodId:
                      params.column.colId.split('~prodId')[1] !== undefined
                        ? params.column.colId.split('~prodId')[1].split('~')[0]
                        : '',
                    ppId:
                      params.column.colId.split('~ppId')[1] !== undefined
                        ? params.column.colId.split('~ppId')[1].split('~')[0]
                        : '',
                    uomId:
                      params.column.colId.split('~uomId')[1] !== undefined
                        ? params.column.colId.split('~uomId')[1].split('~')[0]
                        : '',
                    ppdId:
                      params.column.colId.split('~ppdId')[1] !== undefined
                        ? params.column.colId.split('~ppdId')[1].split('~')[0]
                        : '',
                  };
                }
                this.processService
                  .saveQualityProcessControl(cellData)
                  .subscribe(
                    (response: any) => {
                      // this.toaster.success('Success', 'Data Added Successfully');
                      // this.showPopupMsg(response[0].result);
                      const msg = response[0].result;
                      if (msg !== null || msg !== '') {
                        if (msg.substring(2, 1) === '0') {
                          this.toaster.success(
                            'Success',
                            msg.substring(3, msg.length)
                          );
                          params.node.setDataValue(
                            params.colDef.field,
                            response[0].value
                          );
                          if (response[0].value) {
                            const color = response[0].value.split('|')[1];
                            if (color === '#ff2121') {
                              this.sendNotificationRole(
                                `NCR ID - ${
                                  response[0].ncrId
                                }  Out of Specification Limits! The Defect had happened against PO: ${
                                  params.column.colId.split('~')[1]
                                }`
                              );
                            }
                            if (color === '#f49203') {
                              this.sendNotificationRole(
                                `NCR ID - ${
                                  response[0].ncrId
                                }  Watch Out! There is a risk of defect happening against: PO ${
                                  params.column.colId.split('~')[1]
                                }`
                              );
                            }
                          }
                          // params.node.setDataValue(params.colDef.field, newVal);
                        } else {
                          this.toaster.error(
                            'Error',
                            msg.substring(3, msg.length)
                          );
                          const colorElements = document.getElementsByClassName(
                            'ag-cell-focus'
                          ) as HTMLCollectionOf<HTMLElement>;
                          const color = colorElements[0].style.color;
                          params.node.setDataValue(
                            params.colDef.field,
                            response[0].value
                          );
                        }
                      }
                    },
                    (error: any) => {
                      return this.toaster.error('Error', error.message);
                    }
                  );
                // }
              }
            }
          };
        }
        if (key === 'parameterId') {
          mappedColumn.hide = true;
        }
        columnDefinitions.push(mappedColumn);
      });
    });

    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    this.rowSelection = 'multiple';
    this.getRowNodeId = (data) => data.id;
    return columnDefinitions;
  }

  // Icon
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  abValueGetter(params: any): any {
    if (params.data) {
      // debugger
      return params.data[params.colDef.field]
        ? params.data[params.colDef.field].split('|')[0]
        : '';
    }
  }
  getIntervalType(): void {
    debugger;
    const key = {
      puId: this.unitid,
    };
    this.processService.getQualityGroupEvents(key).subscribe(
      (data: any) => {
        this.IntervalTypeDDL = data;
        if (this.IntervalTypeDDL.length) {
          this.unitDetailForm
            .get('intervalType')
            ?.setValue(this.IntervalTypeDDL[0].val);
          this.onToProcessDate();
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onClickUnit(node: any): void {
    if (node.Level === 'Unit') {
      this.isPlantGroupModelVisible = false;
      this.UnitName = node.text;
      this.unitid = node.PrimaryId;
      this.unitDetailForm.get('UnitNameList')?.setValue(this.UnitName);
      this.groupTypeList = [];
      this.IntervalTypeDDL = [];
      this.intervalList = [];
      this.gridApi.setRowData([]);
      this.getParameterCavity();
      // this.getCurrentProcessOrder();
      const tree = this.treeControl.dataNodes;
      const data = this.getPath(tree[0], node.id);
      this.plantId = data.find((c: any) => c.Level === 'Plant').PrimaryId;

      // this.getParameterInterval();
    }
  }
  getPath(model: any, id: any): any {
    let path;
    const item = model;

    if (!model || typeof model !== 'object') {
      return;
    }

    if (model.id === id) {
      return [item];
    }

    (model.children || []).some(
      (child: any) => (path = this.getPath(child, id))
    );
    return path && [item, ...path];
  }
  onIntervalChange(): void {
    this.onToProcessDate();
  }
  getParameterInterval(): void {
    const Key = {
      puId: this.unitid,
    };
    this.qualityParameterService.getQualityControlInterval(Key).subscribe(
      (response: any) => {
        this.intervalList = response;
        this.unitDetailForm
          .get('parameterInterval')
          ?.setValue(this.intervalList[0].Interval);
        this.onToProcessDate();
      },
      (error: { message: any }) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getParameterCavity(): void {
    const Key = {
      puId: this.unitid,
    };
    this.qualityParameterService.getMaxCavity(Key).subscribe(
      (response: any) => {
        this.maxCavity = response.cavity;
        this.unitDetailForm.get('processType')?.setValue(response.processType);
        // // this.onToProcessDate();
        const val = response.cavity;
        const arr: any[] = [];
        for (let index = 0; index < val; index++) {
          // const element = index + 1;
          arr.push({ value: index + 1 });
        }
        this.cavityList = arr;
        this.showCavity = this.cavityList.length > 1 ? true : false;
      },
      (error: { message: any }) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  SelectUnitName(unitname: any): void {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
      this.groupTypeList = [];
      this.IntervalTypeDDL = [];
      this.intervalList = [];
      this.gridApi.setRowData([]);
      this.GetCrewScheduleList();
    }
  }
  openModal(params: any): void {
    this.displayProcessModal = true;
    // this.dialog.open(this.displayProcessModal, { height: '90%', width: '90%' });
    const podata = params.column.colId.split('~')[1];
    this.paramterSpcecificationName = params.node.data.Parameter;
    const key = {
      processOrder: podata,
      paramterId: params.node.data.parameterId,
      prodId:
        params.column.colId.split('~prodId')[1] !== undefined
          ? params.column.colId.split('~prodId')[1].split('~')[0]
          : '',
    };
    this.getParameterSpecifications(key);
  }
  openDefecct(params: any): void {
    if (window.scrollY > 0) {
      // window.scrollTo(0, 0);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      console.log(window.scrollY);
    }
    console.log('Window.scrollY', window.scrollY);
    document.body.scrollTop = 0;
    this.displayDefectModal = true;
    // this.dialog.open(this.displayDefectModal, { height: '90%', width: '90%' });
    this.qualityparamaterdefects(params);
  }

  openQc(params: any): void {
    debugger;
    this.qcParamsSlot = params.column.userProvidedColDef.headerName;
    this.qcStartTimeSlot = params.column.userProvidedColDef.field.substring(
      0,
      20
    );
    if (this.qualityGroupType === 'Time') {
      this.qcEndTimeSlot = params.column.userProvidedColDef.field.substring(
        23,
        43
      );
    } else {
      this.qcEndTimeSlot = params.column.userProvidedColDef.field.substring(
        0,
        20
      );
    }
    this.qcheadertooltip = params.column.userProvidedColDef.headerTooltip;
    this.qcProcessorderNo = params.column.colId.split('~')[1];
    this.qcProdCode = params.column.colId.split('~')[3];
    this.qcProcdDesc = params.column.colId.split('~')[2];
    const qcTableArr: any[] = [];
    const dateCol = params.column.colId;

    params.api.forEachLeafNode((node: any) => {
      qcTableArr.push({
        parameter: node.data.Parameter,
        value: node.data[dateCol]
          ? node.data[dateCol].split('|')[0]
          : node.data[dateCol],
        parameterId: node.data.parameterId,
        sample: node.data.Cavity,
        verificationStatus: 'Approve',
        remarks: node.data.remarks,
      });
    });
    this.qcdataSource.data = qcTableArr;
    this.getPaginatorqc();

    // global array empty
    this.detailArray = [];
    // for verified
    this.qcdataSource.data.forEach(
      (ele: {
        parameter: any;
        parameterId: any;
        sample: any;
        value: any;
        verificationStatus: any[];
        remarks: any;
      }) => {
        const obj = {
          parameter: ele.parameter,
          parameterId: ele.parameterId,
          sample: ele.sample,
          value: ele.value,
          verificationStatus: ele.verificationStatus
            ? ele.verificationStatus
            : 'Approve',
          remarks: ele.remarks ? ele.remarks : '',
        };
        this.detailArray.push(obj);
      }
    );
    // get qc verified data
    const key = {
      startTime: this.qcStartTimeSlot,
      endTime: this.qcEndTimeSlot,
    };
    this.qualityParameterService
      .GetQCVerifyDetail(key)
      .subscribe((res: any) => {
        this.verifyCheckedArray = res;
        const mergeArray = this.detailArray.map((element: any) => {
          const tableArray = this.verifyCheckedArray.find(
            (x: any) => x.paramterId === element.parameterId
          );
          return { ...element, ...tableArray };
        });
        this.qcdataSource.data = mergeArray;
      });
    // modal open
    console.log(this.qcdataSource.data);
    this.dialog.open(this.displayQCModal, { height: '90%', width: '90%' });
  }

  verifyChnage(element: any, event: any): void {
    const obj = this.qcdataSource.data.find(
      (x: any) => x.parameterId === element.parameterId
    );
    obj.verificationStatus = event.target.value;
  }

  QcVerifyremarksUpdate(element: any, event: any): void {
    const obj = this.qcdataSource.data.find(
      (x: any) => x.parameterId === element.parameterId
    );
    obj.remarks = event.target.value;
  }

  saveQcVerify(): void {
    debugger;
    const key = {
      startTimeSlot: this.qcStartTimeSlot,
      timeSlot: this.qcParamsSlot,
      endTimeSlot: this.qcEndTimeSlot,
      processOrderNo: this.qcProcessorderNo,
      verificationStatus: this.verifcationStatus,
      unitId: this.unitid,
      createdBy: JSON.parse(localStorage.user).UserId,
      createdOn: new Date(),
      prodCode: this.qcProdCode,
      prodDesc: this.qcProcdDesc,
      detail: this.qcdataSource.data, // data
      groupType: this.qualityGroupType,
    };

    this.qualityParameterService.saveQcVerify(key).subscribe(
      (response: any) => {
        // this.toaster.show('Success', 'Verifcation Saved Successfully');
        this.showPopupMsg(response.data);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  saveBool(qualityBoolVal: any): void {
    debugger;
    const itemArr: any[] = [];
    this.gridApi.forEachLeafNode((node: any) => {
      itemArr.push(node);
    });
    const filterIndex: any = itemArr.filter(
      (c) => c.rowIndex === this.boolRowIndex
    );
    const rowNode = this.gridApi.getRowNode(filterIndex[0].id);
    rowNode.setDataValue(this.boolColId, qualityBoolVal);
    this.dialog.closeAll();
  }
  gridDate(event: any): void {
    const itemArr: any[] = [];
    this.gridApi.forEachLeafNode((node: any) => {
      itemArr.push(node);
    });
    const filterIndex: any = itemArr.filter(
      (c) => c.rowIndex === this.boolRowIndex
    );
    const rowNode = this.gridApi.getRowNode(filterIndex[0].id);
    rowNode.setDataValue(
      this.boolColId,
      moment(event.value).format('DD/MM/YYYY')
    );
  }

  showPopupMsg(msg: any): any {
    // //debugger;
    if (msg !== null || msg !== '') {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
      } else {
        this.toaster.error('Error', msg.substring(3, msg.length));
      }
    }
  }
  getParameterSpecifications(key: any): void {
    this.qualityParameterService.getParameterSpecifications(key).subscribe(
      (response: any) => {
        this.parameterspecifications = new MatTableDataSource<any>(response);
        this.chartSpecifications = response;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getchartSpecifications(paramId: any): any {
    const key = {
      varId: paramId,
    };
    this.qualityParameterService.getParameterSpecifications(key).subscribe(
      (response: any) => {
        return response[0];
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  qualityparamaterdefects(params: any): void {
    const prodId =
      params.column.colId.split('~prodId')[1] !== undefined
        ? params.column.colId.split('~prodId')[1].split('~')[0]
        : '';
    const key = {
      paramId: this.parameterId,
      prodId,
    };
    this.qualityParameterService.qualityparamaterdefects(key).subscribe(
      (response: any) => {
        this.parametrdefect = new MatTableDataSource<any>(response);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  openUnit(): any {
    this.isPlantGroupModelVisible = true;
    const key = {
      EGId: this.unitDetailForm.value.groupList,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.UnitNode = filterData(this.plantGroupSource.data, 'UNT');
        this.expandAllNode();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );

    function filterData(data: PlantGroupElement[], title: string): any {
      const r = data.filter((o: any) => {
        Object.keys(o).forEach((e: any) => {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
  }
  // breadCrumb

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        console.log(data.BreadcrumList);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  closeParamterValuesModelVisible(): void {
    this.isParamterValuesModelVisible = false;
  }

  isCharNumeric(charStr: any): boolean {
    return !!/\d/.test(charStr);
  }
  // const source = interval(10000);
  // const text = 'Your Text Here';
  // this.subscription = source.subscribe(val => this.opensnack(text));
  // this.on
  GetGroupTypeList(): any {
    const key = {
      puId: this.unitid,
    };
    this.qualityParameterService.getQualityGroupbyUnit(key).subscribe(
      (data: any) => {
        this.groupTypeList = data.filter(
          (c: any) => c.groupTypeName !== 'None'
        );
        this.unitDetailForm
          .get('groupType')
          ?.setValue(this.groupTypeList[0].parameterGroupTypeId);
        // tslint:disable-next-line:max-line-length
        // this.onToProcessDate();
        this.qualityGroupType = this.groupTypeList.find(
          (c: any) =>
            c.parameterGroupTypeId === this.unitDetailForm.value.groupType
        )?.groupTypeName;
        this.EventMode();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  EventMode(): void {
    debugger;
    this.rowData = [];
    // tslint:disable-next-line:max-line-length
    const val = this.groupTypeList.find(
      (c: any) => c.parameterGroupTypeId === this.unitDetailForm.value.groupType
    )?.groupTypeName;
    this.qualityGroupType = val;
    if (val === 'Event') {
      this.isEventMode = true;
      this.isTimeMode = false;
      this.getIntervalType();
      // this.getQualityGroupEvents();
    } else if (val === 'Time') {
      this.isEventMode = false;
      this.isTimeMode = true;
      this.getParameterInterval();
    } else if (val === 'Manual') {
      this.isEventMode = false;
      this.isTimeMode = false;
      this.onToProcessDate();
    }
    // this.getIntervalType();
  }
  getQualityGroupEvents(): any {
    const key = {
      puId: this.unitid,
    };
    this.qualityParameterService.getQualityGroupEvents(key).then(
      (data: any) => {
        this.eventList = data;
        this.unitDetailForm
          .get('intervalType')
          ?.setValue(this.eventList[0].name);
        this.onToProcessDate();

        console.log(data.BreadcrumList);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  sendNotificationRole(descr: any): void {
    debugger;
    const key = {
      roleName: 'QC leader',
      egId: this.unitDetailForm.value.groupList,
      createdBy: JSON.parse(localStorage.user).UserId,
      desc: descr,
      title: 'Quality Control',
    };
    this.notificationService.addnotification(key).subscribe(
      (response: any) => {
        this.toaster.success('Notification Sent');
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  GetAllDataTypesDetail(val: any): any {
    const key = {
      variableId: val,
    };
    setTimeout(() => {
      console.log('Async Task Calling Callback');
      this.qualityParameterService
        .GetAllDataTypesDetail(key)
        .then((res: any) => {
          this.boolList = res.map((c: any) => c.text);
        });
    }, 1000);
    // await this.qualityParameterService.GetAllDataTypesDetail(key).then((res: any) => {
    //     this.boolList = res.map((c: any) => c.text);
    //   });
  }
  getCurrentProcessOrder(): void {
    debugger;
    const data = {
      puId: this.unitid,
    };
    this.qualityParameterService.getCurrentProcessOrder(data).subscribe(
      (response) => {
        if (response.length) {
          this.currentprocessOrder = response[0].ProcessOrder;
          this.PPID = response[0].PPD_Id;
          this.manualInput('process');
        } else {
          this.currentprocessOrder = null;
          this.PPID = null;
          this.manualInput('process');
        }
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  manualInput(val: any): void {
    const data = {
      plantId: this.plantId,
      lotcode: null,
      eventDateTime: new Date(),
      eventTypeId: 3,
      puId: this.unitid,
      processOrder: this.currentprocessOrder,
      processOrderId: this.PPID,
    };
    this.sfdService.addSfdcEvent(data).then(
      (response) => {
        this.toaster.success('Success', 'Manual Event Added Successfully');
        if (this.qualityGroupType === 'Manual') {
          this.unitDetailForm
            .get('datePicker')
            ?.setValue([this.unitDetailForm.value.datePicker[0], new Date()], {
              emitEvent: false,
            });
          this.onToProcessDate();
        }
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  expand() {
    this.isCardExpanded = !this.isCardExpanded;
    this.Exdpandhide = false;
    this.Collapsehide = true;
    this.Isexpandcontentshow = true;
    this.Isexpandcontenthide = false;
    this.Isexpandcontentbuttonshow = true;
  }
  close() {
    this.isCardExpanded = !this.isCardExpanded;
    this.Exdpandhide = true;
    this.Collapsehide = false;
    this.Isexpandcontentshow = true;
    this.Isexpandcontenthide = true;
    this.Isexpandcontentbuttonshow = false;
  }
  // onChange(result: Date[]): void {
  //   this.unitDetailForm.get('fromProcessDate')?.setValue(result[0]);
  //   this.unitDetailForm.get('toProcessDate')?.setValue(result[1]);
  // }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscriptions.forEach((sb) => sb.unsubscribe());
      this.elementRef.nativeElement.remove();
    }
    // this.processControlHeaderForm = undefined;
    // this.searchForm = undefined;
    // this.unitDetailForm = undefined;
    // this.ProcessControlDataForm = undefined;
    //this.pkg = undefined;
    //this.formGroup = undefined;
    // this.subscription.unsubscribe();
  }
  reOpenCalendar(): any {
    const self = this;
    setTimeout(() => {
      self.picker.open();
    }, 50);
  }
}
const tooltipValueGetter = (params: any) => ({ value: params.value });
