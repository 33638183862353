
<br>
<mat-accordion style="margin-top: 10px;">
<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header class="acc1 disable_ripple_acc1">
        <mat-panel-title>Monthly Stock</mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="monthlyStockGroup" action="">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <div class="content-top-row">
              <div class="inputField">
                <nz-date-picker class="calender-input" id="picker" formControlName="startDate"
                [nzDisabledDate]="" nzFormat="yyyy-MM-dd hh:mm"
                nzShowTime [nzInputReadOnly]="true" readonly></nz-date-picker>
                <span class="floating-text" style="margin-left: 10px;">Start Date</span>
              </div>
              <div class="inputField">
                <nz-date-picker class="calender-input" id="picker" formControlName="endDate"
                [nzDisabledDate]="" nzFormat="yyyy-MM-dd hh:mm"
                nzShowTime [nzInputReadOnly]="true" readonly></nz-date-picker>
                <span class="floating-text" style="margin-left: 10px;">End Date</span>
              </div>
                <!-- <div class="inputField">
                    <nz-date-picker class="calender-input" id="timePicker" formControlName="startDate"
                    nzFormat="dd/MM/yyyy" [nzInputReadOnly]="true">
                     </nz-date-picker>
                     <span class="floating-text" style="margin-left: 10px;">Start Date</span>
                  </div>
                  <div class="inputField">
                    <nz-date-picker class="calender-input" id="timePicker" formControlName="endDate"
                    nzFormat="dd/MM/yyyy" [nzInputReadOnly]="true">
                     </nz-date-picker>
                     <span class="floating-text" style="margin-left: 10px;">End Date</span>
                  </div> -->
                <button type="button" mat-raised-button 
                style="background-color: #0f0fd1;font-size: 14px!important; margin-left: 20px;height:40px;margin-top: 5px;"  (click)="getMonthlyStock(1)">
                <span style="font-size: 12px;color: white;">RETRIEVE</span></button>
                <!-- <div style="display: flex; justify-content: flex-end; margin-left: 30px; margin-top:0px;">
                  
                        <input type="file" (change)="saveFile($event)" formControlName="uploadFile" class="form-control" accept=".xlsx, .xls">
                      
                </div>
                <button type="button" mat-mini-fab mat-button mat-dialog-close (click)="uploadedFile()" class="upload-btn" pTooltip="Upload All" tooltipPosition="top" style="margin-left: 10px;">
                  <mat-icon>upload</mat-icon>
                </button>
                <button type="button" mat-raised-button color="warn"
                style="background-color: #0f0fd1;font-size: 14px!important; margin-left: 20px;height:40px;margin-top: 5px;"  (click)="exportTemplateAsXLSX()">
                <span style="font-size: 12px;color: white;">Download Template</span></button> -->
                <!-- <button type="button" mat-raised-button color="warn"
                style="background-color: #0f0fd1;font-size: 14px!important; margin-left: 20px;height:40px;margin-top: 5px;"  (click)="exportAsXLSX()">
                <span style="font-size: 12px;color: white;">Export To Excel</span></button> -->
                <button  mat-mini-fab mat-button color="primary" pTooltip="Export" tooltipPosition="top" (click)="exportAsXLSX()" style="margin-left: 10px; margin-top: 5px;">
                  <mat-icon>print</mat-icon>
                </button>
             
            </div>
            <div style="width: 100%;margin-top: 20px;">
                <!-- <mat-card-content>
                  <ag-grid-angular style="width: 100%;height:400px; " class="ag-theme-alpine" [rowData]="rowMonthlyStock" (gridReady)="onGridReady($event)"
                  [columnDefs]="columnDefsMonthlyStock" [defaultColDef]="defaultColDefMonthlyStock"  [pagination]="true" [paginationPageSize]="10">
                  </ag-grid-angular>
                </mat-card-content> -->
                <mat-card-content>
                    <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowMonthlyStock"
                    [columnDefs]="columnDefsMonthlyStock" [defaultColDef]="defaultColDefMonthlyStock" (firstDataRendered)="onFirstData($event)" (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="50">
                    </ag-grid-angular>
                  </mat-card-content>
            </div>
        </div>
    </form>
</mat-expansion-panel>


</mat-accordion>
