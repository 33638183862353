import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlantGroupElement } from '../models/O3AdministrationModels/plant-model';
import { Plantbygroup } from '../models/plantbygroup';
import { ProductLine } from '../models/ProductLine';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  baseUrl: string = environment.O3CoreApiUrl;
  kpiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Common/GetGroup', data);
  }
  getTreeModal(data: any): Observable<PlantGroupElement[]> {
    return this.http.post<PlantGroupElement[]>(this.o3CoreApiUrl + 'Common/GetTreeModal', data);
  }
  getGroups(): Observable<any[]> {
    return this.http.get<any[]>(this.kpiUrl + 'groups');
  }

  getPlants(groupId: any): Observable<Plantbygroup[]> {
    return this.http.post<Plantbygroup[]>(this.kpiUrl + 'plantsbygroup', groupId);
  }

  getmasterline(data: any): Observable<ProductLine[]> {
    return this.http.post<ProductLine[]>(this.o3CoreApiUrl + 'Machine/GetMasterLines', data);
  }

  async getMachineByLine(data: any): Promise<any> {
    return await this.http.post<any[]>(this.o3CoreApiUrl + 'Machine/GetUnitsByLine', data).toPromise();
  }

  // *** Core Api Method *** //
  getProductsByUnit(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'NCR/Productsbyunit', data);
  }

  getPeopleMasterData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PeopleMaster/GetEmployeeMasterInfoData', data);
  }

  getPeopleMasterEmpData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PeopleMaster/GetEmployeeMasterInfo', data);
  }

  getLineList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetLineList', data);
  }

  getUnitList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetUnitList', data);
  }
 
}
