import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Mastershift } from '../models/mastershift';

@Injectable({
  providedIn: 'root',
})
export class MeetingMatrixService {
  baseUrl = environment.O3CoreApiUrl;
  
  NetApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }
  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'Common/GetDepartments',
      data
    );
  }
  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.NetApiUrl + 'Common/GetGroup', data);
  }

  getFrequency(data: any): Observable<any> {
    // return this.http.post<any>(
    //   this.kpibaseUrl + 'getFrequencyAndKpiForEachMeeting',
    //   data
    // );
    // return this.http.post<any>(this.kpibaseUrl + 'getFrequencyAndKpiForEachMeeting', data);
    return this.http.post<any[]>(
      this.NetApiUrl +
      'ScoreCardMeetingMatrix/GetFrequencyAndKpiForEachMeeting',
      data
    );
  }

  getMeetingByFrequency(data: any): Observable<any[]> {
    //  return this.http.post<any[]>(this.kpibaseUrl + 'GetFrequencyMeetingMatrix',data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetFrequencyMeetingMatrix',
      data
    );
  }
  getMeetingStatus(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeetingStatus',
      data
    );
  }
  getMeetingFrequencyByMeetingId(data: any): Observable<any[]> {
    //  return this.http.post<any[]>(this.kpibaseUrl + 'GetFrequencyMeetingMatrix',data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetFrequencyMeetingMatrixByMeetingId',
      data
    );
  }
  CheckedMeetingStatus(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/CheckedMeetingStatus',
      data
    );
  }
  getScoreCardMeetingMatrix(data: any): Observable<any[]> {
    // return this.http.post<any[]>( this.kpibaseUrl + 'getScoreCardMeetingMatrix',data );
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetScoreCardMeetingMatrix',
      data
    );
  }

  GetKpiWiseAnalysisReportData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.NetApiUrl + 'ScoreCardMeetingMatrix/GetKpiWiseAnalysisReportData',data);
  }

  addMeetingAction(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.NetApiUrl + 'ScoreCardMeetingMatrix/AddMeetingAction', data);
  }
  updateMeetingAction(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.NetApiUrl + 'ScoreCardMeetingMatrix/updateMeetingAction', data)
  }
  getMeetingAction(data: any): Observable<any[]> {
    //return this.http.post<any[]>(this.kpibaseUrl + 'getMeetingAction', data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetMeetingAction',
      data
    );
  }
  setTargetDate(data: any): Observable<any> {
    return this.http.post<any>(this.NetApiUrl + 'ScoreCardMeetingMatrix/NewActionTargetDate', data);
  }
  AddMeetingException(data: any): Observable<any> {
    return this.http.post<any>(this.NetApiUrl + 'ScoreCardMeetingMatrix/AddMeetingException', data);
  }
  setCompletionDate(data: any): Observable<any> {
    return this.http.post<any>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/NewActionCompletionDate',
      data
    );
  }
  getMeetingParticipants(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.kpibaseUrl + 'getMeetingParticipantByMDefId',data );
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeetingParticipantByDefID',
      data
    );
  }
  getMeetingParticipantsv1(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.kpibaseUrl + 'getMeetingParticipantByMDefId', data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeetingParticipantByDefIDV1',
      data
    );
  }
  getMeetingAgendas(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeetingAgendaByDefID',
      data
    )
  }
  stopMeetingAndSendEmail(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.kpibaseUrl + 'addParticipantAttendMeeting', data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/StopMeetingAndSendEmail',
      data
    );
  }

  addParticipantAttendMeeting(data: any): Observable<any[]> {
   return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/addParticipantAttendMeeting',
      data
    );
  }
  stopMeetingAndSendEmailv1(data: any): Observable<any[]> {
   // return this.http.post<any[]>(this.kpibaseUrl + 'addParticipantAttendMeeting', data);
   return this.http.post<any[]>(
    this.NetApiUrl + 'ScoreCardMeetingMatrix/addParticipantAttendMeetingV1',
    data
  );
  }
  GetEmployeeInfo(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetEmployeeInfo',
      data
    );
  }
  GetTierMeetingChecklist_Data(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingEffectivenessChecklist/GetTierMeetingChecklist_Data', data
    )
  }
  AddTierMeetingChecklist_Data(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingEffectivenessChecklist/SaveTierMeetingAffectivenessChecklist', data
    )
  }

  getAllMeetingAgendas(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.kpibaseUrl + 'getMeeetingAgendaByDefId', data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeeetingAgendaByDefIdV1',
      data
    );
  }
  getAllMeetingAgendasv1(data: any): Observable<any[]> {
   // return this.http.post<any[]>(this.kpibaseUrl + 'getMeeetingAgendaByDefId', data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeeetingAgendaByDefIdV1',
      data
    );
  }
  getMeetingFrequencyStatus(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.kpibaseUrl + 'getMeetingDateTime', data);
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetMeetingDateTime',
      data
    );
  }
  getMeetingEvalationDashboard(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingEvaluationActionMaster/GetMeetingMasterScore',
      data
    );
  }

  getStockChartData(data: any): Observable<any> {
    return this.http.post<any>(this.NetApiUrl + 'ScoreCardMeetingMatrix/GetStockChartData', data);
  }

  getMasterShifts(data: any): Observable<Mastershift[]> {
    // return this.http.post<Mastershift[]>(this.kpibaseUrl + 'mastershift', data);

    //  return this.http.post<Mastershift[]>(this.kpibaseUrl + 'mastershift', data);
    return this.http.post<Mastershift[]>(
      this.NetApiUrl + 'MasterShift/GetMasterShifts',
      data
    );
  }

  getMeetingSuggestedActions(data: any): Observable<any[]> {
    //return this.http.post<any[]>(this.kpibaseUrl + 'getAllMeetingSuggestedAction',data);

    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetAllMeetingSuggestedAction',
      data
    );
  }
  addMeetingSuggestedAction(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/AddMeetingSuggestedAction',
      data
    );
  }
  deleteMeetingSuggestedAction(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/DeleteMeetingSuggestedAction',
      data
    );
  }
  // updateSuggestedCompletionDate(data: any): Observable<any> {
  //   return this.http.post<any>(
  //     this.kpibaseUrl + 'updateSuggestedCompletionDate',
  //     data
  //   );
  // }
  getMeetingSaturations(key: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl +
      'ScoreCardMeetingMatrix/GetMeetingSaturationActionsByMeeting',
      key
    );
  }
  // addManualParticepants(key: any): Observable<any[]> {
  //   return this.http.post<any[]>(this.kpibaseUrl + 'addManualPartcipent', key);
  // }
  // getTotalMeetings(key: any): Observable<any[]> {
  //   return this.http.post<any[]>(this.kpibaseUrl + 'getTotalMeetings', key);
  // }
  getNoOfUsersAssignedToMeeting(key: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetNoOfUsersAssignedToMeeting',
      key
    );
  }
  getNoOfParticpantAgainstMeeting(key: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetNoOfParticpantAgainstMeeting',
      key
    );
  }

  getNoOfParticpantAgainstMeetingSaturation(key: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetNoOfParticpantAgainstMeetingSaturation',
      key
    );
  }

  GetMeetingQuestionLine(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeetingQuestionLine',
      data
    );
  }
  GetMeetingQuestionByLine(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeetingQuestionByLine',
      data
    );
  }
  getKPITargetActual(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/KPITargetActual',
      data
    );
  }

  SaveMeeting(data: any) {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/AddAttainMeetingQuestion',
      data
    );
  }
  GetMeetingRecurrence(data: any) {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/getMeetingRecurrence',
      data
    );
  }

  GetMeetingException(data: any) {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeetingExceptions',
      data
    );
  }

  ResetMeeting(data: any) {
    return this.http.post<any[]>(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/ResetMeeting',
      data
    );
  }

  getCurrentMeetingActions(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetCurrentMeetingActions',
      data
    );
  }
  getAllMeetingActions(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.NetApiUrl + 'MeetingDefination/GetMeetingActions',
      data
    );
  }
  //updated endpoint for scorecard
  getScorecardMeetingMatrix(data: any): Observable<any> {
    return this.http.post(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetMeetingMatrixScoreCard',
      data
    );
  }

  getKPITrend(data: any): Observable<any> {
    return this.http.post(
      this.NetApiUrl.concat('ScoreCardMeetingMatrix/GetKPITrends'),
      data
    );
  }

  addMeetingNotes(data: any): Observable<any> {
    return this.http.post(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/AddMeetingNotes',
      data
    );
  }
  getCurrentMeetingNotes(data: any): Observable<any> {
    return this.http.post(
      this.NetApiUrl + 'ScoreCardMeetingMatrix/GetMeetingNotes',
      data
    );
  }
  GetKPIFinanicalGap(data: any): Observable<any> {
    return this.http.post(this.NetApiUrl + 'ScoreCardMeetingMatrix/GetKPIFinanicalGap', data);
  }

  GetPlantAllowedMenu(data: any): Observable<any> {
    return this.http.post<any>(
      this.NetApiUrl + 'MeetingMaster/GetPlantAllowedMenu',
      data
    );
  }

}
