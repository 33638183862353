import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import * as Chart from 'chart.js';
import { DataSourcesService } from 'src/app/services/data-sources.service';

interface Group {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  // dashboard chart
  dashboardChart = {
    lineChartLabels: ['1', '4', '7', '10', '13', '16', '19', '22', '25'],
    lineChartData: [
      {
        data: [2.8, 3.1, 3.4, 4.4, 3.2, 3.5, 3, 4, 4.5, 3.8],
        label: '  Current Month', fill: false, borderWidth: 1,
        pointRadius: 3,
        tension: 0.5,
      },
      {
        data: [2.2, 2.8, 3.5, 3.8, 3.2, 2.5, 2.2, 2, 2.8, 3],
        label: '  Last Month', fill: false, borderWidth: 1,
        pointBorderWidth: 1, tension: 0.5, pointRadius: 3
      },
    ],
    lineChartColors: [
      { borderColor: "#824AED" },
      { borderColor: "#3AAFE4" },
    ],
    lineChartOptions: {
      tooltips: {
        callbacks: {
          label: function (tooltipItem: any, data: any) {
            return data['datasets'][0]['data'][tooltipItem['index']] + 'K';
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      bezierCurve: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {

            gridLines: {
              borderDash: [8, 4],
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              callback: function (value:any, index:any) {
                if (value === 0) return 0;
                else return value + 'K';
              }
            }
          }
        ],
        xAxes: [{
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  viewAllEvent: boolean = false;
  viewAllAnnouncement: boolean = false;

  //company events input
  companyEvent = [{
    iconDate: '12',
    iconMonth: 'DEC 2020',
    eventTitle: 'IT Team Meeting',
    eventContent: 'Lorem at nec sed eros velit pharetra, mi velit.',
    eventFooter: 'Conference room'
  },
  {
    iconDate: '05',
    iconMonth: 'DEC 2020',
    eventTitle: 'Sales Quarterly Team Meeting',
    eventContent: 'Lorem at nec sed eros velit pharetra, mi velit.',
    eventFooter: 'Conference room'
  },
  {
    iconDate: '30',
    iconMonth: 'NOV 2020',
    eventTitle: 'Get Together Party',
    eventContent: 'Lorem at nec sed eros velit pharetra, mi velit.',
    eventFooter: 'Food court'
  },
  {
    iconDate: '27',
    iconMonth: 'NOV 2020',
    eventTitle: 'Zoom Meeting with the US Client',
    eventContent: 'Lorem at nec sed eros velit pharetra, mi velit.',
    eventFooter: 'Meeting room'
  },
  ]

  //HR Announcement input
  hrAnnouncements = [{
    iconDate: '05',
    iconMonth: 'NOV 2020',
    eventTitle: 'Salary Slip Released for Oct 2020',
    eventContent: 'Lorem at nec sed eros velit pharetra,mi velit.',
    eventFooter: 'By HR Admin '
  },
  {
    iconDate: '28',
    iconMonth: 'OCT 2020',
    eventTitle: 'New Openings for IT Consultant Role',
    eventContent: 'Lorem at nec sed eros velit pharetra...',
    eventFooter: 'By HR Manager'
  },
  {
    iconDate: '23',
    iconMonth: 'OCT 2020',
    eventTitle: 'Apply for Work from Home for Nov 2020',
    eventContent: 'Lorem at nec sed eros velit pharetra...',
    eventFooter: 'By HR Manager'
  },
  {
    iconDate: '15',
    iconMonth: 'OCT 2020',
    eventTitle: 'Update Time Sheets',
    eventContent: 'Lorem at nec sed eros velit pharetra, mi velit.',
    eventFooter: 'By HR Manager'
  },
  ]

  //Icon Url
  flag: string = 'assets/images/flag.svg';


  dashboardElementData: any[] = [];
  dashboardColumns: string[] = ['flag', 'dueDate', 'title', 'status', 'assignee'];
  dashboardData = new MatTableDataSource<any>();

  openViewAllEvent() {
    this.viewAllEvent = !this.viewAllEvent;
  }

  openViewAllAnnouncement() {
    this.viewAllAnnouncement = !this.viewAllAnnouncement;
  }

  constructor(
    private formBuilder: FormBuilder,
    private DataSourceService: DataSourcesService
  ) { }

  ngOnInit(): void {
    Chart.defaults.global.legend.labels.usePointStyle = true;
    this.DataSourceService.getDashboardData().subscribe((data: any) => {
      this.datasource(data);
      console.log('res is ', data);
    },
      error => { throw error },
      () => console.log("finished"));
  }


  datasource(data: any) {
    this.dashboardData.data = data;
    console.log('dashboardData', data)
  }
}

