import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss'],
})
export class UsermanagementComponent implements OnInit {
  usermanagements!: any[];
  constructor(public router: Router, private toaster: ToastrService) { }

  ngOnInit(): void {
    const list = localStorage.getItem('flatMenuItem');
    const treeData = list !== null ? JSON.parse(list) : [];
    this.usermanagements = [
      // tslint:disable-next-line:max-line-length
      {
        title: 'User Manager',
        routerLink: this.getUserManager,
        icon: 'assets/images/manufacturing-admin.svg',
        disabled: false
      },
      {
        title: 'Role Manager',
        routerLink: this.getUserRole,
        icon: 'assets/images/manufacturing-admin.svg',
        disabled: false
      },
      {
        title: 'User RoleMap',
        routerLink: this.getUserRoleMapping,
        icon: 'assets/images/manufacturing-admin.svg',
        disabled: false
      },
      {
        title: 'User MachineMap',
        routerLink: this.getMachineMapping,
        icon: 'assets/images/manufacturing-admin.svg',
        disabled: false
      },
    ];
  }

  getUserManager = (): void => {
    this.router.navigate(['/usermanager']);
  }
  getUserRole = (): void => {
    this.router.navigate(['/userrole']);
  }
  getUserRoleMapping = (): void => {
    this.router.navigate(['/userrolemapping']);
  }
  getMachineMapping = (): void => {
    this.router.navigate(['/machinemapping']);
  }
}
