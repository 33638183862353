import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrderProcessingService {
  // tslint:disable-next-line:typedef
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }



  getProductionPlan(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Planner/GetProductionPlan', data);
  }

  getUOMData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Planner/GetUOM', data);
  }

  submitProductionPlan(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Planner/SubmitProductionPlan', data);
  }

  getProductionPlanDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Planner/GetProductionPlanDetail', data);
  }

  getProducts(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Planner/GetProducts', data);

  }



  submitProductionPlanDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Planner/SubmitProductionPlanDetail', data);
  }

}