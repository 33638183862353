import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
// import { CILDashboardService } from 'src/app/services/cil-dashboard-service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { DefectDashBoardService } from 'src/app/services/defect-dashboard-service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
// import { Observable } from 'rxjs';
// import { switchMap } from 'rxjs/operators';
import { ButtonViewRendererComponent } from 'src/app/components/button-view-render.components';

@Component({
  selector: 'app-defect-dashboard',
  templateUrl: './defect-dashboard.component.html',
  styleUrls: ['./defect-dashboard.component.scss']
})
export class DefectDashboardComponent implements OnInit {
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('popupViewModal') private popupViewModal!: TemplateRef<any>;
  displayedColumns = ['name', 'assignto', 'date', 'newDate', 'closedate', 'comments'];
  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  filteredUnits: any;
  filteredComponents: any;
  filteredUsers: any;
  breadcrumList: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  actionDataSource!: any[];
  componentList!: any;
  DefectDashboardForm!: FormGroup;
  totalCount!: any;
  openCount!: any;
  inProgressCount!: any;
  delayCount!: any;
  closedCount!: any;
  image!: any;
  base64ImageUrl!: any;
  completedCount!: any;
  key: any;
  blob!: Blob;
  dashboardData!: any;
  agGridData: any[] = new Array();
  chartDS!: Chart;
  chartDP!: Chart;
  chartDC!: Chart;
  chartDT!: Chart;
  options!: Options;
  currentImagePath!: any;
  frameworkComponents: any;
  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;
  defaultExcelExportParams: any;
  moduleId!: any;
  pageId!:any;

  gridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
  };
  // iconUrl
  menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';
  imageUrl: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private productivityservice: ProductivityService,
    private defectService: DefectDashBoardService,
    private toaster: ToastrService,
    public router: Router,
    private userService: UserManagementService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
  }
  convertImageURLToBase64(url: any, callback: any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  ngOnInit(): void {
    this.imageUrl = environment.O3CoreApiImageUrl
    this.frameworkComponents = {
      buttonRenderer: ButtonViewRendererComponent,
    }
    this.columnDefs = [
      { headerName: 'Defect Entry', field: 'DefectEntry', filter: 'agTextColumnFilter' },
      { headerName: 'Priority', field: 'Priority', filter: 'agTextColumnFilter' },
      { headerName: 'Status', field: 'Status', filter: 'agTextColumnFilter' },
      { headerName: 'Line', field: 'PL_Desc', filter: 'agTextColumnFilter' },
      { headerName: 'Machine', field: 'PU_Desc', filter: 'agTextColumnFilter' },
      { headerName: 'Component', field: 'ComponentName', filter: 'agTextColumnFilter' },
      { headerName: 'Defect Type', field: 'DefectType', filter: 'agTextColumnFilter' },
      // { headerName: 'Assign To', field: 'AssignTo', filter: 'agTextColumnFilter' },
      // { headerName: 'Target Date', field: 'TargetDate', filter: 'agDateColumnFilter', valueFormatter: function (params: any) {return params.value !== null ? moment(params.value).format('MM/DD/YYYY HH:mm:ss') : '' }},
      { headerName: 'Close Date', field: 'CloseDate', filter: 'agDateColumnFilter', valueFormatter: function (params: any) { return params.value !== null ? moment(params.value).format('MM/DD/YYYY HH:mm:ss') : '' } },
      // { headerName: 'Comments', field: 'Comments', filter: 'agTextColumnFilter' },
      { headerName: 'PostImageName', field: 'PostImageName', cellRenderer: (params: any) => { return `<img style="width:40px;height:40px ;cursor:pointer" alt="${params.value}" src="${params.value !== '' ? this.imageUrl + params.value : 'assets/images/no-image.png'}">` } },
      { headerName: 'PreImageName', field: 'PreImageName', cellRenderer: (params: any) => { return `<img style="width:40px;height:40px ;cursor:pointer" alt="${params.value}" src="${params.value !== '' ? this.imageUrl +  params.value : 'assets/images/no-image.png'}">` } },
      { headerName: 'Created By', field: 'CreatedBy', filter: 'agTextColumnFilter' },
      { headerName: 'Created On', field: 'CreatedOn', filter: 'agDateColumnFilter', valueFormatter: function (params: any) { return moment(params.value).format('MM/DD/YYYY HH:mm:ss') } },
      { headerName: 'Action', cellRenderer: 'buttonRenderer', cellRendererParams: { onClick: this.viewAction.bind(this), label: 'View' }, maxWidth: 85, suppressMenu: true },
    ]
    this.defaultExcelExportParams = {
      addImageToCell: function (rowIndex: number, col: any, value: any, callback: any) {
        if (col.colId === 'PreImageName' || col.colId === 'PostImageName') {

          // var image = value;
          //  return {
          //     image: {
          //       id: image,
          //       base64: base64String,
          //       imageType: 'png',
          //       width: 50,
          //       height: 50,
          //       position: {
          //         offsetX: 30,
          //         offsetY: 5.5,
          //       },
          //     },
          //   };
        } else {
          return;
        }
      },
    }
    this.initform();
    this.DefectDashboardForm.controls.unit.patchValue("0");
    this.DefectDashboardForm.controls.components.patchValue("0");
    this.DefectDashboardForm.controls.user.patchValue("0");
    this.datasource();
    this.GetBreadcrumList();
  }

  initform() {
    const dateStart = new Date();
    //dateStart.setDate(dateStart.getDate() - 183);
    dateStart.setHours(6);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);


    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate());
    this.DefectDashboardForm = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      components: ['', Validators.required],
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
      shift: ['', []],
      user: ['', []]
    });
  };

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }

  viewAction(element: any): void {
    this.dialog.open(this.popupViewModal, {
      width: '50vw !important',
      height: 'auto',
      minWidth: '50vw',

    })
    this.actionDataSource = element.data.ActionPlan;
  }

  onChangeLine(data: any): void {
    if (parseInt(data.value) === 0) {
      this.filteredUnits.length = 0;
      this.filteredComponents.length = 0;
      this.DefectDashboardForm.controls.unit.patchValue("0");
      this.DefectDashboardForm.controls.components.patchValue("0");
      this.DefectDashboardForm.controls.user.patchValue("0");
      this.getUnit(parseInt(data.value));
    } else {
      this.getUnit(data.value);
    }
  }
  onChangePlant(data: any): void {
    this.getLine(data.value);
  }
  onChangeUnit(data: any): void {
    this.getComponent(data.value);
  }
  datasource(): void {
    const key = {
      UserId: 206
    };
    const id = {
      value: 0
    };
    // tslint:disable-next-line: no-debugger
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      id.value = data.lstEG[0].EG_Id;
      this.DefectDashboardForm.get('UserGroup1')?.setValue(data.lstEG[0].EG_Id);
      this.filteredGroups = this.GroupList.slice();
      this.getPlants(id);
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: 206
    };

    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      const id = data.lstPlant[0].PT_Id;
      this.DefectDashboardForm.get('UserPlant1')?.patchValue(id);
      this.filteredPlants = this.PlantList1.slice();
      this.getLine(id);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getLine(id: any) {
    const key = {
      PT_ID: id,
      userId: 206
    };

    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      const lineDefault = this.lineList[0];
      this.DefectDashboardForm.controls.line.patchValue("0");
      if (parseInt(this.DefectDashboardForm.controls.line.value) === 0) {
        this.DefectDashboardForm.controls.unit.patchValue("0");
        this.DefectDashboardForm.controls.components.patchValue("0");
        this.DefectDashboardForm.controls.user.patchValue("0");
        this.getUnit(parseInt(this.DefectDashboardForm.controls.line.value));
      } else {
        this.getUnit(lineDefault.PL_Id);
      }
      this.filteredLines = this.lineList.slice();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUnit(id: any) {
    const key = {
      PL_ID: id,
      userId: 206,
      PT_ID: this.DefectDashboardForm.value.UserPlant1,
    };

    this.productivityservice.getUnitData(key).subscribe((data: any) => {
      this.unitList = data.lstUnit;
      const unitDefault = this.unitList[0];
      if (this.unitList.length > 0) {
        this.DefectDashboardForm.controls.unit.patchValue(unitDefault.PU_Id);
        this.filteredUnits = this.unitList.slice();
        this.getComponent(unitDefault.PU_Id);
      }
      else{
        this.onSubmit();
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getComponent(id: any) {
    const key = {
      PU_ID: parseInt(id),
      UserId: 206,
      PT_ID: this.DefectDashboardForm.value.UserPlant1,
    };
    this.productivityservice.getComponentData(key).subscribe((data: any) => {
      this.componentList = data.lstComponent;
      const comDefault = this.componentList[0];
      if (this.componentList.length > 0) {
        this.DefectDashboardForm.controls.components.patchValue(comDefault.ComponentId);
        this.filteredComponents = this.componentList.slice();
      }
      this.onSubmit();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  onSubmit(): void {
    this.makeData();
    this.getDefectDashboard();
  }

  today(): void {
    this.makeDatatoDay();
    this.getDefectDashboard();
  }

  yesterday(): void {
    this.makeDataYesterDay();
    this.getDefectDashboard();
  }

  lastWeek(): void {
    this.makeDataLastWeek();
    this.getDefectDashboard();
  }

  lastMonth(): void {
    this.makeDataLastMonth();
    this.getDefectDashboard();
  }

  getDefectDashboard() {
    this.spinner.show();
    this.defectService.getDefectDashboard(this.key).subscribe((data: any) => {
      this.dashboardData = data;
      this.spinner.hide();
      this.setData();
    }, (error: any) => {
      this.toaster.error('error', error.ErrorMessage);
      this.spinner.hide();
    });
  }

  setData() {
    if (this.dashboardData !== null) {
      this.totalCount = this.dashboardData.totalCount;
      this.openCount = this.dashboardData.openCount;
      this.inProgressCount = this.dashboardData.inProgressCount;
      this.delayCount = this.dashboardData.delayCount;
      this.closedCount = this.dashboardData.closedCount;
      this.completedCount = this.dashboardData.completedCount;
      this.drawDefectStatus();
      this.drawDefectPriority();
      this.drawDefectClassification();
      this.drawDefectType();
      this.rowData = this.dashboardData.defectlist;
    }
  }

  makeData() {
    const startDt = this.DefectDashboardForm.value.dateStart;
    const endDt = this.DefectDashboardForm.value.dateEnd;
    this.key = {
      groupId: this.DefectDashboardForm.value.UserGroup1,
      plantId: this.DefectDashboardForm.value.UserPlant1,
      lineId: parseInt(this.DefectDashboardForm.value.line),
      UnitId: parseInt(this.DefectDashboardForm.value.unit),
      ComponentId: parseInt(this.DefectDashboardForm.value.components),
      UserId: parseInt(this.DefectDashboardForm.value.user),
      dtStartDate: startDt,
      dtEndDate: endDt,
      Status: '*'
    };

  }

  makeDatatoDay() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate());
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    const startDt = stDT;
    const endDt = edDT;
    this.DefectDashboardForm.get('dateStart')?.patchValue(stDT);
    this.DefectDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      groupId: this.DefectDashboardForm.value.UserGroup1,
      plantId: this.DefectDashboardForm.value.UserPlant1,
      lineId: parseInt(this.DefectDashboardForm.value.line),
      UnitId: parseInt(this.DefectDashboardForm.value.unit),
      ComponentId: parseInt(this.DefectDashboardForm.value.components),
      UserId: parseInt(this.DefectDashboardForm.value.user),
      dtStartDate: startDt,
      dtEndDate: endDt,
      Status: '*'
    };

  }
  makeDataYesterDay() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 1);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.DefectDashboardForm.get('dateStart')?.patchValue(stDT);
    this.DefectDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      groupId: this.DefectDashboardForm.value.UserGroup1,
      plantId: this.DefectDashboardForm.value.UserPlant1,
      lineId: parseInt(this.DefectDashboardForm.value.line),
      UnitId: parseInt(this.DefectDashboardForm.value.unit),
      ComponentId: parseInt(this.DefectDashboardForm.value.components),
      UserId: parseInt(this.DefectDashboardForm.value.user),
      dtStartDate: startDt,
      dtEndDate: endDt,
      Status: '*'
    };
  }

  makeDataLastWeek() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 6);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.DefectDashboardForm.get('dateStart')?.patchValue(stDT);
    this.DefectDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      groupId: this.DefectDashboardForm.value.UserGroup1,
      plantId: this.DefectDashboardForm.value.UserPlant1,
      lineId: parseInt(this.DefectDashboardForm.value.line),
      UnitId: parseInt(this.DefectDashboardForm.value.unit),
      ComponentId: parseInt(this.DefectDashboardForm.value.components),
      UserId: parseInt(this.DefectDashboardForm.value.user),
      dtStartDate: startDt,
      dtEndDate: endDt,
      Status: '*'
    };
  }

  makeDataLastMonth() {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 30);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = stDT;
    const endDt = edDT;
    this.DefectDashboardForm.get('dateStart')?.patchValue(stDT);
    this.DefectDashboardForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      groupId: this.DefectDashboardForm.value.UserGroup1,
      plantId: this.DefectDashboardForm.value.UserPlant1,
      lineId: parseInt(this.DefectDashboardForm.value.line),
      UnitId: parseInt(this.DefectDashboardForm.value.unit),
      ComponentId: parseInt(this.DefectDashboardForm.value.components),
      UserId: parseInt(this.DefectDashboardForm.value.user),
      dtStartDate: startDt,
      dtEndDate: endDt,
      Status: '*'
    };

  }

  drawDefectStatus() {
    let dsLabel: any[] = new Array();
    let dsData: any[] = new Array();
    if (this.dashboardData.lstStatus !== null && this.dashboardData.lstStatus.length > 0) {
      this.dashboardData.lstStatus.forEach((element: any) => {
        dsLabel.push(element.Name);
        dsData.push([element.Name, parseFloat(element.Countt)]);
      });
    }
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: dsLabel,
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'defect Status',
        type: 'column',
        data: dsData
      }]
    };
    let chart = new Chart(this.options);
    this.chartDS = chart;
  };

  drawDefectPriority() {
    let dpLabel: any[] = new Array();
    let dpData: any[] = new Array();
    if (this.dashboardData.lstPriority !== null && this.dashboardData.lstPriority.length > 0) {
      this.dashboardData.lstPriority.forEach((element: any) => {
        dpLabel.push(element.Name);
        dpData.push([element.Name, parseFloat(element.Countt)]);
      });
    }
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: dpLabel,
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defect Priority',
        type: 'column',
        data: dpData
      }]
    };
    let chart = new Chart(this.options);
    this.chartDP = chart;
  }

  drawDefectClassification() {
    let dcLabel: any[] = new Array();
    let dcData: any[] = new Array();
    if (this.dashboardData.lstClassification !== null && this.dashboardData.lstClassification.length > 0) {
      this.dashboardData.lstClassification.forEach((element: any) => {
        dcLabel.push(element.Name);
        dcData.push([element.Name, parseFloat(element.Countt)]);
      });
    }
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: dcLabel,
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'CIL Classification',
        type: 'column',
        data: dcData
      }]
    };
    let chart = new Chart(this.options);
    this.chartDC = chart;
  }

  drawDefectType() {
    let dtLabel: any[] = new Array();
    let dtData: any[] = new Array();
    if (this.dashboardData.lstDefectType !== null && this.dashboardData.lstDefectType.length > 0) {
      this.dashboardData.lstDefectType.forEach((element: any) => {
        dtLabel.push(element.Name);
        dtData.push([element.Name, parseFloat(element.Countt)]);
      });
    }
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1'],
      title: {
        text: ''
      },
      xAxis: {
        categories: dtLabel,
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Defect Type',
        type: 'column',
        data: dtData
      }]
    };
    let chart = new Chart(this.options);
    this.chartDT = chart;
  }

  /// AG Grid 

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }
  excelExport() {
    this.gridApi.exportDataAsExcel();
  }
  csvExport() {
    this.gridApi.exportDataAsCsv();
  }

  cellClicked(params: any): void {
    const col = params.colDef.field;
    if (col === 'PostImageName') {
      this.image = params.data.PostImageName;
      this.openPictureBeforeModal();
    } if (col === 'PreImageName') {
      this.image = params.data.PreImageName;
      this.openPictureBeforeModal();
    }
    // if (col === 'pictureAfter') {
    //   this.GembaId = params.data.gembaWalkId;

    //   this.image = params.data.pictureAfter;
    //   this.openFileModal();
    // }

  }

  openPictureBeforeModal(): void {
    this.currentImagePath = this.image === ''
      ? 'assets/images/no-image.png'
      : this.imageUrl + this.image;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }
}

