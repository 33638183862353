<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
    <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>

<div>
<div class="content-top-box">
    <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
    </ul>
</div>

<div class="content">
    <div class="header-title">
        <h1 class="main-title">Work Order</h1> &nbsp;
        <button mat-icon-button [disabled]="!IsWrite" class="add-button" (click)="addOperator()">
            <mat-icon class="menu">add</mat-icon>
        </button>
    </div>
    <form name="HeaderForm" [formGroup]="HeaderForm" class="w-70">
        <div class="w-100 headerForm d-flex">
            <mat-form-field appearence="fill"
                class="w-20">
                <mat-label>Group</mat-label>
                <mat-select (selectionChange)="getPlants(HeaderData.Group)" formControlName="group"
                    [(ngModel)]="HeaderData.Group">
                    <input (keyup)="onGroupKey($event.target)" (change)="onGroupKey('')" class="form-control w-100"
                        placeholder="Search Group">
                    <mat-option *ngFor="let group of GroupList" [value]="group.id">
                        {{group.name}}
                    </mat-option>
                </mat-select>
                <mat-error>Group is required</mat-error>
            </mat-form-field>

            <mat-form-field appearence="fill"
                class="w-20">
                <mat-label>Plant</mat-label>
                <mat-select formControlName="plant" [(ngModel)]="HeaderData.Plant"
                    (selectionChange)="getUserList()">
                    <input (keyup)="onPlantKey($event.target)" (change)="onPlantKey('')" class="form-control w-100"
                        placeholder="Search Plant">
                    <mat-option *ngFor="let plant of PlantList" [value]="plant.id">
                        {{plant.name}}
                    </mat-option>
                </mat-select>
                <mat-error>Plant is required</mat-error>
            </mat-form-field>

            <div class="inputField w-20">
                <nz-date-picker class="date-picker" readonly [nzShowTime]="false" nzFormat="dd-MMM-yyyy"
                    [(ngModel)]="dateFilter.From" formControlName="fromDate" (ngModelChange)="getFilterData()">
                </nz-date-picker>
                <span class="floating-text">From</span>
            </div>

            <div class="inputField w-20">
                <nz-date-picker class="date-picker" readonly [nzShowTime]="false" nzFormat="dd-MMM-yyyy"
                    [(ngModel)]="dateFilter.To" formControlName="toDate" (ngModelChange)="getFilterData()">
                </nz-date-picker>
                <span class="floating-text">To</span>
            </div>

            <mat-form-field appearance="fill"
                class="w-20">
                <mat-label>Search</mat-label>
                <input matInput type="text" (keyup)="onFilterChanged($event.target)">
            </mat-form-field>
        </div>
    </form>

    <div class="import_export">
        <button [disabled]="!IsWrite" class="export" (click)="ExportTOExcel()">Export</button>
    </div>
</div>
<div style="margin-top: 20px;">
    <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowData"
        [columnDefs]="columnDefs" (gridReady)="onGridReady($event)"
        [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10"
        [frameworkComponents]="frameworkComponents">
    </ag-grid-angular>
</div>
</div>