import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}
  private _data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _submit: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _ncrData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public setData(data: any) {
    this._data.next(data);
  }

  public getData(): Observable<any> {
    return this._data.asObservable();
  }
  public setSubmit(data: any) {
    this._submit.next(data);
  }
  public getSubmit() {
    return this._submit.asObservable();
  }
}
