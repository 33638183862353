import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Chart } from 'angular-highcharts';
import { Options, Point } from 'highcharts';
import * as moment from 'moment';


@Component({
  selector: 'app-production-quality-dashboard',
  templateUrl: './production-quality-dashboard.component.html',
  styleUrls: ['./production-quality-dashboard.component.scss']
})
export class ProductionQualityDashboardComponent implements OnInit {
  productionQualityForm!: FormGroup;
  oeeAnalysis = {
    barType: 'radial',
    color: '#10CE9C',
    secondColor: '#E5E5E5',
    progress: 60,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      }
    }
  };
  capacityUtilizationAnalysis = {
    barType: 'radial',
    color: '#3AAFE4',
    secondColor: '#E5E5E5',
    progress: 0,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      }
    }
  };
  availabilityAnalysis = {
    barType: 'radial',
    color: '#F85439',
    secondColor: '#E5E5E5',
    progress: 0,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#F85439',
      }
    }
  };
  performanceAnalysis = {
    barType: 'radial',
    color: '#3AAFE4',
    secondColor: '#E5E5E5',
    progress: 0,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      }
    }
  };

  qualityAnalysis = {
    barType: 'radial',
    color: '#F85439',
    secondColor: '#E5E5E5',
    progress: 0,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#F85439',
      }
    }
  };

  productivityAnalysis={
    barType: 'radial',
    color: '#F85439',
    secondColor: '#E5E5E5',
    progress: 0,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#F85439',
      }
    }
  };

  // iconUrl
  menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';

  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  filteredUnits: any;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  productionQualityDashboardData!: any;
  productionShiftData!: any;
  isproductionDBModel = false;
  isPopupMachine = false;
  isPlantGroupModelVisible = false;
  UnitName: string | undefined;
  GrossProduce!: any;
  idealProduction!: any;
  grossProduction!: any;
  netProduction!: any;
  Waste!: any;
  wasteKG!: any;
  options!: Options;
  chartMachine!: Chart;
  chartWasteWaterfall!: any;
  chartShiftDay!: Chart;
  chartShiftNight!: Chart;
  activeButton!:any;
  key: any;
  machineStopLabel: any[] = new Array();
  machineStopData: any[] = new Array();
  wasteWaterfallData: any[] = new Array();
  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridApiEdit: any;
  gridColumnApi: any;
  gridColumnApiEdit: any;
  OutputLoses!: any;
  window: any;
  gridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
  };
  constructor(
    private formBuilder: FormBuilder,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private productivityService: ProductivityService,
    private spinner: NgxSpinnerService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.spinner.show();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.datasource();
  }

  onChangeGroup(data: any): void {
    this.getPlants(data);
  }
  onChangeLine(data: any): void {
    this.getUnit(data.value);
  }
  onChangePlant(data: any): void {
    this.getLine(data.value);
  }

  initForm() {
    const dateStart = new Date();
    dateStart.setHours(6);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);
    const dateEnd = new Date();
    this.productionQualityForm = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
      shift: ['', []],
      searchval: [''],
    });
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  datasource(): void {
    const key = {
      UserId: 206
    };
    const id = {
      value: 0
    };
    this.productivityService.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      if (this.GroupList.length > 0) {
        id.value = data.lstEG[0].EG_Id;
        this.productionQualityForm.get('UserGroup1')?.setValue(data.lstEG[0].EG_Id);
        this.filteredGroups = this.GroupList.slice();
        this.getPlants(id);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: 206
    };

    this.productivityService.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      if (this.PlantList1.length > 0) {
        const id = data.lstPlant[0].PT_Id;
        this.productionQualityForm.get('UserPlant1')?.patchValue(id);
        this.filteredPlants = this.PlantList1.slice();
        this.getLine(id);
      }

    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getLine(id: any) {
    const key = {
      PT_ID: id,
      userId: 206
    };

    this.productivityService.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      const lineDefault = this.lineList[0];
      if (this.lineList.length > 0) {
        this.productionQualityForm.controls.line.patchValue(lineDefault.PL_Id);
        this.filteredLines = this.lineList.slice();
        this.getUnit(lineDefault.PL_Id);
      }

    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUnit(id: number) {
    const key = {
      PL_ID: id,
      userId: 206
    };
    this.productivityService.getUnitData(key).subscribe((data: any) => {
      this.unitList = data.lstUnit;
      if (this.unitList.length > 0) {
        const unitDefault = this.unitList[0];
        this.unitList.length > 0 ? this.productionQualityForm.controls.unit.patchValue(unitDefault.PU_Id) : this.productionQualityForm.controls.unit.patchValue('');
        this.filteredUnits = this.unitList.slice();
        this.onSubmit();
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });

  }

  onSubmit(): void {
    this.makeData();
    this.getProductionQualityData();
    }

  today(): void {
    this.makeDatatoDay();
    this.getProductionQualityData();
    }

  yesterday(): void {
    this.makeDataYesterDay();
    this.getProductionQualityData();
    }

  lastWeek(): void {
    this.makeDataLastWeek();
    this.getProductionQualityData();
    }

  lastMonth(): void {
    this.makeDataLastMonth();
    this.getProductionQualityData();
    }

  getProductionQualityData() {
    this.spinner.show();
    this.productivityService.getProductionQualityData(this.key).subscribe((data: any) => {
      this.productionQualityDashboardData = data;
      this.spinner.hide();
      this.setData();
    }, (error: any) => {
      this.toaster.error('error', error.message);
      this.spinner.hide();
    });
  }

  getDailyShiftData(){
    this.spinner.show();
    const key = {
      UnitId:this.key.UnitId,
      shiftStartDate: this.key.dtStartDate,
      shiftEndDate: this.key.dtEndDate

    }
    this.productivityService.getProductionShiftData(key).subscribe((data: any) => {
      this.productionShiftData = data.ShiftInfoList;
      var productivity :any = 0;
      this.productionShiftData.forEach((element:any) => {
        productivity+=element.Productivity;
      });
      this.spinner.hide();
      this.productivityAnalysis.progress = parseInt(this.nFormatter(productivity !== 0 ? productivity /this.productionShiftData.length : 0 ,0));
      
      //this.productivityAnalysis.progress.toFixed(0);
      this.drawShiftDay();
      this.drawShiftNight();
    }, (error: any) => {
      this.toaster.error('error', error.message);
      this.spinner.hide();
    });
  }

  setData() {
    //this.setCountAndMinutes(this.MaintenanceDashboardData.dtData);
    this.spinner.show();
    this.oeeAnalysis.progress = this.productionQualityDashboardData.AvgOee.toFixed(1);
    this.availabilityAnalysis.progress = this.productionQualityDashboardData.AvgAvailability.toFixed(1);
    this.performanceAnalysis.progress = this.productionQualityDashboardData.AvgPerformance.toFixed(1);
    this.qualityAnalysis.progress = this.productionQualityDashboardData.AvgQualityRate.toFixed(1);
    this.idealProduction = this.nFormatter(this.productionQualityDashboardData.IdealProduce ,0);
    this.GrossProduce = this.nFormatter(this.productionQualityDashboardData.GrossProduce, 0);
    this.netProduction = this.nFormatter(this.productionQualityDashboardData.NetProduce, 0);
    this.wasteKG = this.nFormatter(this.productionQualityDashboardData.WasteKg, 0);
    this.Waste = this.nFormatter((this.productionQualityDashboardData.Waste ?  this.productionQualityDashboardData.Waste / this.productionQualityDashboardData.GrossProduce * 100 : 0),0);
    this.makeDataMachineChart();
    this.makeDataWasteWaterfall();
    this.rowData = this.productionQualityDashboardData.dtDetails;
    if (this.rowData) {
      this.columnDefs = this.generateColumns(this.rowData);
      setTimeout(() => {
        this.gridOptions.api.columnController.resetColumnState();
      }, 100);
    }
    this.getDailyShiftData();
    this.setColorCoding();
    this.spinner.hide();
  }

  makeData() {
    this.key = {
      PP_Id: 0,
      dtStartDate: this.dateAsYYYYMMDDHHNNSS(this.productionQualityForm.value.dateStart),
      dtEndDate: this.dateAsYYYYMMDDHHNNSS(this.productionQualityForm.value.dateEnd),
      GroupId: this.productionQualityForm.value.UserGroup1,
      PlantId: this.productionQualityForm.value.UserPlant1,
      UnitId: this.productionQualityForm.value.unit,
      LineId: this.productionQualityForm.value.line,
      shift: '*'
    };
  }

  makeDatatoDay(): void {
    const stDT = new Date();
    stDT.setDate(stDT.getDate());
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);
    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    const startDt = this.dateAsYYYYMMDDHHNNSS(stDT);
    const endDt = this.dateAsYYYYMMDDHHNNSS(edDT);
    this.productionQualityForm.get('dateStart')?.patchValue(stDT);
    this.productionQualityForm.get('dateEnd')?.patchValue(edDT);
    this.key = {
      PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.productionQualityForm.value.UserGroup1,
      PlantId: this.productionQualityForm.value.UserPlant1,
      UnitId: this.productionQualityForm.value.unit,
      LineId: this.productionQualityForm.value.line,
      shift: '*'
    };
  }

  makeDataYesterDay(): void {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 1);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = this.dateAsYYYYMMDDHHNNSS(stDT);
    const endDt = this.dateAsYYYYMMDDHHNNSS(edDT);
    this.productionQualityForm.get('dateStart')?.patchValue(stDT);
    this.productionQualityForm.get('dateEnd')?.patchValue(edDT);

    this.key = {
      PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.productionQualityForm.value.UserGroup1,
      PlantId: this.productionQualityForm.value.UserPlant1,
      UnitId: this.productionQualityForm.value.unit,
      LineId: this.productionQualityForm.value.line,
      shift: '*'
    };
  }

  makeDataLastWeek(): void {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 6);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = this.dateAsYYYYMMDDHHNNSS(stDT);
    const endDt = this.dateAsYYYYMMDDHHNNSS(edDT);
    this.productionQualityForm.get('dateStart')?.patchValue(stDT);
    this.productionQualityForm.get('dateEnd')?.patchValue(edDT);

    this.key = {
      PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.productionQualityForm.value.UserGroup1,
      PlantId: this.productionQualityForm.value.UserPlant1,
      UnitId: this.productionQualityForm.value.unit,
      LineId: this.productionQualityForm.value.line,
      shift: '*'
    };
  }

  makeDataLastMonth(): void {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 29);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = this.dateAsYYYYMMDDHHNNSS(stDT);
    const endDt = this.dateAsYYYYMMDDHHNNSS(edDT);
    this.productionQualityForm.get('dateStart')?.patchValue(stDT);
    this.productionQualityForm.get('dateEnd')?.patchValue(edDT);

    this.key = {
      PP_Id: 0,
      dtStartDate: startDt,
      dtEndDate: endDt,
      GroupId: this.productionQualityForm.value.UserGroup1,
      PlantId: this.productionQualityForm.value.UserPlant1,
      UnitId: this.productionQualityForm.value.unit,
      LineId: this.productionQualityForm.value.line,
      shift: '*'
    };
  }

  nFormatter(num: any, digits: any) {

    if (digits === undefined) {
      digits = 1;
    }
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: " K" },
      { value: 1E6, symbol: " M" },
      { value: 1E9, symbol: " G" },
      { value: 1E12, symbol: " T" },
      { value: 1E15, symbol: " P" },
      { value: 1E18, symbol: " E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  };

  //color coding
  setColorCoding() {
    //OEE
    if (this.oeeAnalysis.progress > 0 && this.oeeAnalysis.progress <= 20) {
      this.oeeAnalysis.color = "#fa1f0f";
    } else if (this.oeeAnalysis.progress > 20 && this.oeeAnalysis.progress <= 50) {
      this.oeeAnalysis.color = "#fae311";
    } else if (this.oeeAnalysis.progress > 50 && this.oeeAnalysis.progress <= 70) {
      this.oeeAnalysis.color = "#fc7005";
    } else if (this.oeeAnalysis.progress > 70) {
      this.oeeAnalysis.color = "#069420";
    }
    // Availability
    if (this.availabilityAnalysis.progress > 0 && this.availabilityAnalysis.progress <= 20) {
      this.availabilityAnalysis.color = "#fa1f0f";
    } else if (this.availabilityAnalysis.progress > 20 && this.availabilityAnalysis.progress <= 50) {
      this.availabilityAnalysis.color = "#fae311";
    } else if (this.availabilityAnalysis.progress > 50 && this.availabilityAnalysis.progress <= 70) {
      this.availabilityAnalysis.color = "#fc7005";
    } else if (this.availabilityAnalysis.progress > 70) {
      this.availabilityAnalysis.color = "#069420";
    }
    //performanceAnalysis
    if (this.performanceAnalysis.progress > 0 && this.performanceAnalysis.progress <= 20) {
      this.performanceAnalysis.color = "#fa1f0f";
    } else if (this.performanceAnalysis.progress > 20 && this.performanceAnalysis.progress <= 50) {
      this.performanceAnalysis.color = "#fae311";
    } else if (this.performanceAnalysis.progress > 50 && this.performanceAnalysis.progress <= 70) {
      this.performanceAnalysis.color = "#fc7005";
    } else if (this.performanceAnalysis.progress > 70) {
      this.performanceAnalysis.color = "#069420";
    }
    //qualityAnalysis
    if (this.qualityAnalysis.progress > 0 && this.qualityAnalysis.progress <= 20) {
      this.qualityAnalysis.color = "#fa1f0f";
    } else if (this.qualityAnalysis.progress > 20 && this.qualityAnalysis.progress <= 50) {
      this.qualityAnalysis.color = "#fae311";
    } else if (this.qualityAnalysis.progress > 50 && this.qualityAnalysis.progress <= 70) {
      this.qualityAnalysis.color = "#fc7005";
    } else if (this.qualityAnalysis.progress > 70) {
      this.qualityAnalysis.color = "#069420";
    }
     //ProductivityAnalysis
     if (this.productivityAnalysis.progress > 0 && this.productivityAnalysis.progress <= 20) {
      this.productivityAnalysis.color = "#fa1f0f";
    } else if (this.productivityAnalysis.progress > 20 && this.productivityAnalysis.progress <= 50) {
      this.productivityAnalysis.color = "#fae311";
    } else if (this.productivityAnalysis.progress > 50 && this.productivityAnalysis.progress <= 70) {
      this.productivityAnalysis.color = "#fc7005";
    } else if (this.productivityAnalysis.progress > 70) {
      this.productivityAnalysis.color = "#069420";
    }
  }

  drawMachineStopChart() {
    this.options = {
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.machineStopLabel,
      },
      yAxis: {
        min: 0,
      },
      credits: {
        enabled: false
      },
      tooltip: {
        valueSuffix: ''
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        type: 'bar',
        name: 'Machine Stop',
        color: '#0f0fd1',
        data: this.machineStopData
      }]
    };
    let chart = new Chart(this.options);
    this.chartMachine = chart;
  }

  drawWasteWaterfallchart() {
    let wasteData: any[] = new Array();
    this.wasteWaterfallData.forEach(element => {
      wasteData.push({ 'name': element.label, 'y': element.value });
    });
    wasteData.push({ 'name': 'Total', 'isIntermediateSum': true, 'color': '#0f0fd1' });

    this.options = {
      chart: {
        type: 'waterfall'
      },

      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category'
      },

      yAxis: {
        title: {
          text: 'Waste'
        }
      },

      legend: {
        enabled: false
      },

      tooltip: {
        pointFormat: '<b>{point.y:,.2f}</b> Waste'
      },
      plotOptions: {
        waterfall: {
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        type: 'waterfall',
        name: 'Waste Waterfall',
        color: '#fcba03',

        data: wasteData,
      }]

    };
    let chart = new Chart(this.options);
    this.chartWasteWaterfall = chart;
  }

  makeDataMachineChart(): void {
    this.machineStopLabel = [];
    this.machineStopData = [];
    var map = this.productionQualityDashboardData.dtDrillDownChartData.reduce(function (map: any, ent: any) {
      if (ent.Main === "Availability Loss") {
        var name = ent.Reson
        var count = +ent.Value
        map[name] = (map[name] || 0) + 1
      }
      return map
    }, {});

    for (var m in map) {
      this.machineStopLabel.push(m);
      this.machineStopData.push(map[m]);
    }
    this.drawMachineStopChart();
  }

  makeDataWasteWaterfall(): void {
    this.wasteWaterfallData = [];
    let map = this.productionQualityDashboardData.dtDrillDownChartData.reduce(function (map: any, ent: any) {
      if (ent.Main === "Quality Loss") {
        var name = ent.Fault
        var Value = +ent.Value
        map[name] = (map[name] || 0) + Value
      }
      return map
    }, {});

    for (var m in map) {
      this.wasteWaterfallData.push({ label: m, value: map[m] });
    }
    this.drawWasteWaterfallchart();
  }

  drawShiftDay() {
    let label: any[] = new Array();
    let avgProductivity: any[] = new Array();
    let avgProductionQty: any[] = new Array();
    let manpower: any[] = new Array();
    var sumproductivity: number = 0;
    var sumProduction: number = 0;
    var sumMan: number = 0;
    this.productionShiftData.forEach((element:any )=> {
      if(element.ShiftDesc.toLowerCase() === 'day shift'){
        label.push(moment(element.Date).format('YYYY-MM-DD'));
        avgProductivity.push(parseFloat(element.Productivity.toFixed(2)));
        avgProductionQty.push([moment(element.Date).format('YYYY-MM-DD'), parseFloat(this.nFormatter(element.ProductionQty,0))]);
        manpower.push([moment(element.Date).format('YYYY-MM-DD'), parseFloat(element.ManPower)]);
        sumproductivity += parseFloat(element.Productivity.toFixed(2));
        sumProduction += parseFloat(element.ProductionQty.toFixed(2));
        sumMan += element.ManPower;
      }
    });
    this.options = {
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: label
      },
      yAxis: {
        min: 0,
        labels: {
          format: '{value}'
        }
      },
      series: [
        {
        type: 'column',
        name: 'Productivity',
        data: avgProductivity,
        color : '#0f1ddb'
      }, {
        type: 'column',
        name: 'ManPower',
        data: manpower,
        color:'#fcba03'
      },
       {
        type: 'spline',
        name: 'Production',
        data:  avgProductionQty,
        marker: {
          lineWidth: 2,
          lineColor: '',
          fillColor: 'white'
        },
        tooltip:{
          pointFormat: '<b>{point.y} k</b>'
        },
      }, 
    ]
    };
    let chart = new Chart(this.options);
    this.chartShiftDay = chart;
  }
  drawShiftNight() {
    let label: any[] = new Array();
    let avgProductivity: any[] = new Array();
    let avgProductionQty: any[] = new Array();
    let manpower: any[] = new Array();
    var sumproductivity: number = 0;
    var sumProduction: number = 0;
    var sumMan: number = 0;
    this.productionShiftData.forEach((element:any )=> {
      if(element.ShiftDesc.toLowerCase() === 'night shift'){
        label.push(moment(element.Date).format('YYYY-MM-DD'));
        avgProductivity.push(parseFloat(element.Productivity.toFixed(2)));
        avgProductionQty.push([moment(element.Date).format('YYYY-MM-DD'), parseFloat(this.nFormatter(element.ProductionQty,0))]);
        manpower.push([moment(element.Date).format('YYYY-MM-DD'), parseFloat(element.ManPower)]);
        sumproductivity += parseFloat(element.Productivity.toFixed(2));
        sumProduction += parseFloat(element.ProductionQty.toFixed(2));
        sumMan += element.ManPower;
      }
    });
    this.options = {
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: label
      },
      yAxis: {
        min: 0,
        labels: {
          format: '{value}'
        }
      },
      series: [
        {
        type: 'column',
        name: 'Productivity',
        data: avgProductivity,
        color : '#0f1ddb'
      }, {
        type: 'column',
        name: 'ManPower',
        data: manpower,
        color:'#fcba03'
      },
       {
        type: 'spline',
        name: 'Production',
        data:  avgProductionQty,
        marker: {
          lineWidth: 2,
          lineColor: '',
          fillColor: 'white'
        },
        tooltip:{
          pointFormat: '<b>{point.y} k</b>'
        },
      }, 
    ]
    };
    let chart = new Chart(this.options);
    this.chartShiftNight = chart;
  }


  //date formatter
  dateAsYYYYMMDDHHNNSS(date: any): string {
    return date.getFullYear()
      + '-' + this.leftpad(date.getMonth() + 1, 2)
      + '-' + this.leftpad(date.getDate(), 2)
      + ' ' + this.leftpad(date.getHours(), 2)
      + ':' + this.leftpad(date.getMinutes(), 2)
      + ':' + this.leftpad(date.getSeconds(), 2);
  }
  leftpad(val: any, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
      + String(val)).slice(String(val).length);
  }
  headerHeightSetter(): void {
    const padding = 20;
    const height = headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }
  generateColumns(data: any[]): any {
    let columnDefinitions: any[] = [];

    data.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key.toUpperCase(),
          headerClass: {},
          field: key,
          width: 170,
          // height: 200,
          // suppressSizeToFit: true,
          cellStyle: {},
        };
        if (mappedColumn.headerName === "PT_ID" || mappedColumn.headerName === "PL_ID" || mappedColumn.headerName === "PU_ID") {
        } else {
          columnDefinitions.push(mappedColumn);
        }
      });
    });

    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );

    return columnDefinitions;
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReadyEdit(params: any): any {
    this.gridApiEdit = params.api;
    this.gridColumnApiEdit = params.columnApi;
  }
  excelExport() {
    this.gridApi.exportDataAsExcel();
  }

  csvExport() {
    this.gridApi.exportDataAsCsv();
  }

  pdfExport() {
    this.gridApi.exportDataAsPdf();
  }
  showPhase(event:any){
    this.activeButton = event;
  }



}
function headerHeightGetter(): any {
  const columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');

  const columnHeaderTextsArray: Element[] = [];

  columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));

  const clientHeights = columnHeaderTextsArray.map(
    headerText => headerText.clientHeight
  );
  const tallestHeaderTextHeight = Math.max(...clientHeights);
  return tallestHeaderTextHeight;
}