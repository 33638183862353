<section class="home-content">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>

  <mat-card>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-6 p-lg-7 p-xl-7">
        <mat-card-title>Gemba Walk</mat-card-title>
      </div>
      <div class="p-col-12 p-md-6 p-lg-5 p-xl-5" style="display: flex; justify-content: flex-end;">
        <form [formGroup]="gembaWalkForm" style="float: right;">
          <div style="display: flex;justify-content: space-between;">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label class="required">Group</mat-label>
              <mat-select panelClass="testClass" name="group" formControlName="group"
                (selectionChange)="getPlant($event)">
                <mat-option *ngFor="let p of groups" [value]="p.TypeId"> {{p.TypeName}} </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="field-fill" style="float: right; margin-left: 10px;">
              <mat-label class="required">Plant</mat-label>
              <mat-select panelClass="testClass" name="plant" formControlName="plant"
                (selectionChange)="onPlantChange($event)">
                <mat-option *ngFor="let pt of plants" [value]="pt.DeptId"> {{pt.DeptDesc}} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </mat-card>

  <div class="wrapper-box wrapper-box-shadow grid-container">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

    <form [formGroup]="gembaDataForm">
      <div class="content-top-row content-top-row-order-processing d-flex">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label class="placeholder-title">Select Area
          </mat-label>
          <mat-select panelClass="testClass" name="area" formControlName="area"
            (selectionChange)="onAreaChange($event)">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'areaName'" [array]="area"
              (filteredReturn)="filteredAreas = $event"></mat-select-filter>
            <mat-option [value]="0"> All </mat-option>
            <mat-option *ngFor="let pt of filteredAreas" [value]="pt.areaId"> {{pt.areaName}} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Select Owner</mat-label>
          <mat-select panelClass="testClass" name="owner" formControlName="owner"
            (selectionChange)="onOwnerChange($event)">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'userName'" [array]="owners"
              (filteredReturn)="filteredOwners = $event"></mat-select-filter>
              <mat-option  [value] = "0" *ngIf = "IsAllOwner"> All </mat-option>
            <mat-option *ngFor="let pt of filteredOwners" [value]="pt.userId"> {{pt.userName}} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Start Date</mat-label>
          <input matInput formControlName="startdate" [ngxMatDatetimePicker]="startPicker" readonly />
          <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #startPicker> </ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>End Date</mat-label>
          <input matInput formControlName="enddate" [ngxMatDatetimePicker]="endPicker" readonly />
          <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #endPicker> </ngx-mat-datetime-picker>
        </mat-form-field>
        <button *ngIf="IsWrite" (click)="openModal()" class="add-btn" pTooltip="Add Observation" tooltipPosition="top">
          <img src="assets/images/add.png" alt="" width="20px" height="20px">
        </button>
        <button (click)="getGembaWalkData()" class="search-btn" pTooltip="Filter Data" tooltipPosition="top">
          <img src="assets/images/search.png" alt="" width="20px" height="20px">
        </button>
        <button (click)="openGlobalModal()" class="search-btn" pTooltip="Global Standards" tooltipPosition="top">
          <img src="assets/images/popup.png" alt="" width="20px" height="20px">
        </button>
      </div>
    </form>
    <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="rowData"
      [columnDefs]="columnDefs" [gridOptions]="gridOptions" (cellClicked)="cellClicked($event)"
      (cellValueChanged)="onCellValueChanged($event)" [icons]="icons" [pagination]="true">
    </ag-grid-angular>
  </div>

  <ng-template #displayObservationModal>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <form [formGroup]="observationDataForm">
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{FormMode ? 'Add' : 'Update'}} New Observation</h2>

          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center" style="margin-left: 20px;">
        <div class="p-xl-4 p-lg-4 p-md-6 p-col-12">
          <button class="user_badge" mat-flat-button color="accent" disableRipple>
            Area: {{AreaName}}
          </button>
          <!-- <h2>Area: <label for="">{{AreaName}}</label></h2>  -->
        </div>
        <div class="p-xl-8 p-lg-8 p-md-6 p-col-12">
          <button class="user_badge" mat-flat-button color="accent" disableRipple>
            Owner: {{OwnerName}}
          </button>
          <!-- <h2>Owner: <label for="">{{OwnerName}}</label></h2> -->
        </div>


      </div>
      <mat-dialog-content>

       
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-top-row">
          <mat-form-field class="top-row-first-filed-item ">
            <mat-label>Observation</mat-label>
            <input matInput formControlName="observe" placeholder="Enter Observation" />
          </mat-form-field>

          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Categories</mat-label>
            <mat-select name="generalCategories" formControlName="generalCategories">
              <mat-option *ngFor="let pt of generalCategoryList" [value]="pt.ID">
                {{pt.categoryName}} </mat-option>
            </mat-select>
            <mat-error>Please Select Category</mat-error>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Classification</mat-label>
            <mat-select name="5sclassification" formControlName="fivesclassification">
              <mat-option *ngFor="let pt of auditClassifications" [value]="pt.classficationId"> {{pt.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 27.5%" *ngIf="FormMode">
            <ngx-mat-file-input #myInput formControlName="image" placeholder="Add Gemba Images" [accept]="
               'image/x-png,image/gif,image/jpeg'
              " (change)="uploadedFile($event)" multiple required>
              <mat-icon ngxMatFileInputIcon>folder</mat-icon>
            </ngx-mat-file-input>
          </mat-form-field>
        
        </div>
        <div *ngIf="FileData && FileData.length && FormMode" class="imgflex">
          <div *ngFor="let file of FileData; let i = index" class="relative">
              <div>
                  <img *ngIf="file.FileType == 'image'" [src]="file.ImageSrc" alt="" width="100px" height="80px">
              </div>

              <img src="assets/images/remove.png" (click)="deleteFile(i)" class="absolute" alt="" width="20px"
                  height="20px">
          </div>
      </div>
        <div style="display: flex; justify-content : end">
          <button  [disabled]="!observationDataForm.valid" mat-mini-fab mat-button color="primary"
          pTooltip="Save" tooltipPosition="top" (click)="submitData()" style="margin-top:20px;margin-left: 40px;">
          <mat-icon style="color: #fff">save</mat-icon>
        </button>
        </div>
     
      </div>
      </mat-dialog-content>
    </form>
  </ng-template>

  <ng-template #displayRoleModal1>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>Global Standards</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>

    <table mat-table [dataSource]="gembaData" class="basic-table plant-model-table" matSort>
      <ng-container matColumnDef="plant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Plant</p>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.ptDesc}} </td>
      </ng-container>

      <!-- Sort Column -->
      <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Area</p>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.masterArea}} </td>
      </ng-container>


      <!-- Sort Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Type</p>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.typeName}} </td>
      </ng-container>

      <!-- Sort Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Name</p>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="file">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Action</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <a target="_blank" href="{{imageUrl + element.fullPath}}" *ngIf="element.filename !== null" [download]>
            <svg width="25" height="25" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.867 477.867"
              style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
              <g>
                <g>
                  <path d="M409.6,153.6h-85.333c-9.426,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067H409.6
                        c9.426,0,17.067,7.641,17.067,17.067v221.867c0,9.426-7.641,17.067-17.067,17.067H68.267c-9.426,0-17.067-7.641-17.067-17.067
                        V204.8c0-9.426,7.641-17.067,17.067-17.067H153.6c9.426,0,17.067-7.641,17.067-17.067S163.026,153.6,153.6,153.6H68.267
                        c-28.277,0-51.2,22.923-51.2,51.2v221.867c0,28.277,22.923,51.2,51.2,51.2H409.6c28.277,0,51.2-22.923,51.2-51.2V204.8
                        C460.8,176.523,437.877,153.6,409.6,153.6z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M335.947,243.934c-6.614-6.387-17.099-6.387-23.712,0L256,300.134V17.067C256,7.641,248.359,0,238.933,0
                        s-17.067,7.641-17.067,17.067v283.068l-56.201-56.201c-6.78-6.548-17.584-6.361-24.132,0.419c-6.388,6.614-6.388,17.1,0,23.713
                        l85.333,85.333c6.657,6.673,17.463,6.687,24.136,0.03c0.01-0.01,0.02-0.02,0.031-0.03l85.333-85.333
                        C342.915,261.286,342.727,250.482,335.947,243.934z" />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g>
              </g>
              <g></g>
              <g></g>
            </svg>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="globalColumns"></tr>
      <tr *matRowDef="let row; columns: globalColumns;" mat-row></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No Data Matching</td>
      </tr>
    </table>
    <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>

    <div mat-dialog-actions style="float: right; margin-top: 10px">
      <!-- <button color="accent" mat-flat-button cdkFocusInitial>
          Save & Close
        </button> -->
    </div>

  </ng-template>

  <ng-template #imageZoomModel>

    <img [src]="currentImagePath ? currentImagePath : assets/images/no-image.png" style="height: 98%; width: 100%; overflow: hidden;">

  </ng-template>

  <ng-template #imageZoomModel2>

    <img [src]="currentImagePath" style="height: 98%;width: 100%; overflow: hidden;">

  </ng-template>

</section>

<ng-template #previewModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Gemba Files</h2>
      <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content style="padding: 15px 24px !important;  ">
    <form [formGroup]="filesForm" novalidate>
      <mat-form-field
        appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      >
        <ngx-mat-file-input
          #myInput
          formControlName="file"
          placeholder="Add Gemba Files"
          [accept]="
           'image/x-png,image/gif,image/jpeg,application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel , application/vnd.ms-powerpoint'
          "
          (change)="saveGembaFiles($event)"
          multiple
        >
          <mat-icon ngxMatFileInputIcon>folder</mat-icon>
        </ngx-mat-file-input>
      </mat-form-field>
    </form>

    <div class="mat-elevation-z8">
      <table mat-table class="preview_table" [dataSource]="previewerData">
        <ng-container matColumnDef="Desc">
          <th mat-header-cell *matHeaderCellDef>
            File escription
          </th>
          <td mat-cell *matCellDef="let element">
            <a (click)="openFile(element)">
              {{ element.FileName }}
           </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> Download
            <!-- {{ langObj ? langObj?.Download : ("Download" | translate) }} -->
          </th>
          <td mat-cell *matCellDef="let element">
            <a href="{{ imageUrl + element.FilePath }}" target="_blank"><i class="fa fa-download"></i></a>
          </td>
        </ng-container>
        <ng-container matColumnDef="Remove">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-icon-button>
              <!-- (click)="removeAll()" matTooltip="Remove all" -->
              <mat-icon>clear_all</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element; let rem = index">
            <button
              mat-icon-button
              matTooltip="Remove this File"
              (click)="removeFile(element)"
            >
              <!-- (click)="removeAt(i)" -->
              <mat-icon>clear</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="previewerColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: previewerColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3">
            No Data Matching
          </td>
        </tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #displayActionModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Action</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close"><i class="fa fa-times"
          style="color: #fff"></i></button>
    </div>
  </div>
  <div style="margin-bottom: 20px;">
    <button mat-mini-fab mat-button color="accent" (click)="addRow()" matTooltip="Add Row"
      matTooltipClass="custom-tooltip" matTooltipPosition="above" ng-disabled="addGembaActionRowButton">
      <i class="fa fa-plus" style="color: #fff"></i>
    </button>
    <button mat-mini-fab mat-button mat-dialog-close color="primary" matTooltip="Save" [disabled] ="disableSave"
      matTooltipClass="custom-tooltip" matTooltipPosition="above" (click)="addGembaActions()"
      style="margin-left: 10px;" ng-disabled="addGembaActionRowButton">
      <i class="fa fa-save" style="color: #fff"></i>
    </button>
  </div>

  <form [formGroup]="gembaActionform">
    <table mat-table [dataSource]="actionDataSource" formArrayName="actionsArray" class="basic-table" matSort>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Action Name</p>
        </th>
        <td mat-cell *matCellDef="let row ; let index = index" [formGroupName]="index">
          <mat-form-field appearance="fill" class="field-fill" style="width: 130px; margin-left:-10px;">
            <input type="text"
              [readonly]="row.value.rejected || row.value.completed || row.value.verified ? true : false" matInput
              formControlName="actionName" placeholder="Action Name">
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="departments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Departments</p>
        </th>
        <td mat-cell *matCellDef="let row; let dep = index" [formGroupName]="dep">
          <mat-form-field appearance="fill" class="field-fill" style="width: 130px; margin-left:-10px;">
            <mat-select name="department"
              [disabled]="row.value.rejected || row.value.completed || row.value.verified ? true : false"
              placeholder="Departments" (selectionChange)="getUsersbyDepartment($event.value,dep)"
              formControlName="department">
              <mat-option *ngFor="let pt of departmentList" [value]="pt.TypeId"> {{pt.TypeName}} </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="responsible">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Responsible</p>
        </th>
        <td mat-cell *matCellDef="let row ; let res = index" [formGroupName]="res">
          <!-- <div *ngFor="let group of raciUsers[res]"> -->
          <mat-form-field appearance="fill" class="field-fill" style="width: 130px; margin-left:-10px;">
            <!-- <mat-select placeholder="Responsible" formControlName="responsibleId"
              [disabled]="row.value.rejected || row.value.completed || row.value.verified ? true : false" required>
              <mat-option *ngFor="let item of raciUsers[res]" [value]="item.EmpInfo_Id">{{item.FirstName}}
              </mat-option>
            </mat-select> -->
            <mat-select ngxMatSelect [hasSearchBox]="true" [useMobileView]="true" mobileViewType="BottomSheet"
              [disabled]="row.value.rejected || row.value.completed || row.value.verified ? true : false"
              [formControlName]="'responsibleId'" [multiple]="false" name="fullName" [required]="true"
              [source]="raciUsers[res]" [displayMember]="'fullName'"
              placeholder="Responsible"
              [valueMember]="'EmpInfo_Id'" #sf="ngxMatSelect" (openedChange)="resetFilterList(res)">
              <mat-option [value]="item.EmpInfo_Id" *ngFor="let item of sf.filteredSource">
                {{item.fullName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- </div>    -->
        </td>
      </ng-container>

      <ng-container matColumnDef="remarks">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Remarks</p>
        </th>
        <td mat-cell *matCellDef="let row; let rem = index" [formGroupName]="rem">
          <mat-form-field appearance="fill" class="field-fill" style="width: 130px; margin-left:-10px;">
            <input type="text" matInput formControlName="remarks"
              [readonly]="row.value.rejected || row.value.completed || row.value.verified ? true : false"
              placeholder="Remarks">
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Sort Column -->
      <ng-container matColumnDef="target">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Target Date</p>
        </th>
        <td mat-cell *matCellDef="let row; let tar = index" [formGroupName]="tar">
          <mat-form-field appearance="fill" class="field-fill" style="width: 130px; margin-left:-10px;">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="startDate" [min]="minDate" placeholder="Target Date" autocomplete="off"
              formControlName="targetDate"
              [disabled]="row.value.rejected || row.value.completed || row.value.verified ? true : false">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
        </td>
      </ng-container>


      <ng-container matColumnDef="isRequiredPictureAfter">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Picture After Required</p>
        </th>
        <td mat-cell *matCellDef="let row; let picAft= index" [formGroupName]="picAft">
          <mat-checkbox color="primary" formControlName="isRequiredPictureAfter"
            [disabled]="row.value.rejected || row.value.completed || row.value.verified ? true : false">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-icon-button>
            <!-- (click)="removeAll()" matTooltip="Remove all" -->
            <mat-icon>clear_all</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let rem= index;" [formGroupName]="rem">
          <button mat-icon-button matTooltip="Remove this action"
            [disabled]="element.value.rejected || element.value.completed || element.value.verified ? true : false"
            (click)="removeAt(element, rem)" ng-disabled="removeGembaActionRowButton">
            <!-- (click)="removeAt(i)" -->
            <mat-icon>clear</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="actionColumns"></tr>
      <tr *matRowDef="let row; columns: actionColumns;" mat-row></tr>
      <tr class="mat-row" *matNoDataRow style="text-align: center;">
        <td class="mat-cell" colspan="8">No Data Matching</td>
      </tr>
    </table>
    <!-- <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator> -->

  </form>

</ng-template>

<ng-template #ViewActionDetailModel>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Action Detail</h2>
      <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <!-- <div class="mat-elevation-z8"> -->
      <ag-grid-angular style="width: 100%; height: 30vw" class="ag-theme-alpine" [rowData]="rowDataViewActions"
      [columnDefs]="columnDefsActionView" [gridOptions]="gridOptions" (cellClicked)="cellClicked($event)"
      [pagination]="true">
    </ag-grid-angular>
    <!-- </div> -->
  </mat-dialog-content>
</ng-template>

<p-dialog [header]="fileDesc" [(visible)]="displayMaximizable" [modal]="true" [style]="{width: '90vw'}"
  [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ngx-doc-viewer *ngIf="fileType==='doc'" [url]="doc" viewer="google" style="width:100%;height:90vh;"></ngx-doc-viewer>
  <angular-image-viewer *ngIf="fileType==='img'" [src]="images" [(index)]="imageIndexOne"></angular-image-viewer>
</p-dialog>

<ng-template #CloseObservation>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Action Detail</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content  style="overflow:unset">
    <form [formGroup]="closeGembaForm">
      <div *ngIf = "uploadImage" >
        <div class="d-flex justify-content-between align-items-start">
          <mat-checkbox (change)="checkChangeClose($event)" [disabled]="checkBoxDisable" > Image Not Required</mat-checkbox>
        </div>
        <div class="content-top-row" style="margin-top: 0px;">
  
          <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px; width: 30%;">
            <input type="text" matInput placeholder="Remarks"  formControlName="remarks" >
          </mat-form-field>
          <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 30%;" [ngClass]="{'disable-bg': disableImage === true}" >
            <ngx-mat-file-input #myInput formControlName="image" placeholder="Add Action Close Image" [accept]="
              'image/x-png,image/gif,image/jpeg' " tooltipPosition="top"
              matTooltip="Action Close Image" (change)="onFileSelectClose($event)" [disabled] ="disableImage"  >
              <mat-icon ngxMatFileInputIcon>folder</mat-icon>
            </ngx-mat-file-input>
          </mat-form-field>
        </div>
        <div class="d-flex justify-content-end">
          <button mat-mini-fab mat-button  mat-dialog-close color="primary" title="Save" pTooltip="Close Defect"
            style="margin-left: 20px;" [disabled]="!closeGembaForm.valid" (click) = "closeObservation()" >
            <i class="fa fa-save" style="color: #fff"></i>
          </button>
        </div>
      </div>
        <div class="content-row-wrap" *ngIf = "!uploadImage" style="justify-content: space-between" >
          <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px; width: 70%;">
            <input type="text" matInput placeholder="Remarks"  formControlName="remarks">
          </mat-form-field>
          <img style="height:70px;width:70px" [src] ="CloseImage" (click) = "imageZoom()"/>
        </div>  
    </form>
  </mat-dialog-content>
</ng-template>
