import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TechnicianService } from '../../services/technician.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ManagerExecutionService } from '../../services/manager.service';
import { EditBtnRenderer } from 'src/app/edit-action-btn-renderer.component';
import { DeleteBtnRenderer } from 'src/app/delete-action-btn-renderer.component';
import * as moment from 'moment';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CILMasterService } from 'src/app/services/cil-master-service';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { ActionEditDeleteViewBtnRenderer } from 'src/app/action-edit-delete-view-btn-renderer.component';
import { differenceInCalendarDays } from 'date-fns';
@Component({
  selector: 'app-technician-execution-list',
  templateUrl: './technician-execution-list.component.html',
  styleUrls: ['./technician-execution-list.component.scss'],
})
export class TechnicianExecutionListComponent implements OnInit {
  woList: any = [];
  pmTaskList: any = [];
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('imageViewModal') private imageViewModal!: TemplateRef<any>;
  preventive: string = '';
  ImageUrl: string = '';
  blobStorageUrl: string = '';
  user: any;
  spinnerText: string = '';
  selectedFilter: any = 1;
  selected: any;
  dateFilter: any = {};
  technicianColumns: string[] = [
    'Type',
    'WOId',
    'LineId',
    'WOStatus',
    'ScheduledAt',
    'WOType',
    'FailureMode',
    'Priority',
    'SpareParts',
    'SparePartCode',
    'Remark',
    'UploadPicture',
    'StartTime',
    'CompleteTime',
    'SPRequestTime',
    'SPReceivedTime',
    'Remarks',
    'AfterPicture',
    'Actions',
  ];
  woDataSource = new MatTableDataSource();
  pmTaskDataSource = new MatTableDataSource();
  breadcrumList!: any;
  minDate: any;
  maxDate: any;
  userList: any;
  userListPMD: any;
  permisson!: any;
  IsWrite: boolean = false;
  IsAccess!: any;

  frameworkComponents: any;
  woColumnDefs!: any[];
  pmColumnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;
  HeaderForm!: FormGroup;
  GroupList: any = [];
  GroupTempList: any = [];
  PlantTempList: any = [];
  techList: any = [];
  PlantList: any;
  HeaderData: any = { Group: '', Plant: '' };
  frmDate: any;
  tDate: any;
  gridOptionsWO: any = {
    defaultColDef: {
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true, // <-- HERE
      autoHeight: true, // <-- HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
    },
    popupParent: document.querySelector('body'),
  };
  gridOptionsPM: any = {
    defaultColDef: {
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true, // <-- HERE
      autoHeight: true, // <-- HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
    },
    popupParent: document.querySelector('body'),
  };
  ExportTechnicianWOList: any = [];
  ExportTechnicianPMList: any = [];

  slidesImages: any = [];
  todayDate = new Date();
  disabledDate = (current: Date): boolean =>
  // Can not select days before today and today
   differenceInCalendarDays(current, this.todayDate) > 0;
  isInit: boolean=false;
  constructor(
    private cilMasterService: CILMasterService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private enterpriseGroupService: EnterPriseGroupService,
    private spinner: NgxSpinnerService,
    private technicianService: TechnicianService,
    private router: Router,
    private userManagementService: UserManagementService,
    public managerExecutionService: ManagerExecutionService,
    public commonservice: CommonService,
    private dialog: MatDialog
  ) {
    this.frameworkComponents = {
      editRenderer: EditBtnRenderer,
      deleteRenderer: DeleteBtnRenderer,
      actionEditDeleteViewBtnRenderer: ActionEditDeleteViewBtnRenderer
    }
    this.woColumnDefs = [
      {
        headerName: 'Action',
        field: 'Action',
        cellRenderer: 'actionEditDeleteViewBtnRenderer',
        minWidth: 140,
        cellRendererParams: {
          onClickEdit: this.editRow.bind(this),
          onClickView: this.viewButtonClicked.bind(this),
        },
        
      },
      {
        headerName: 'WO Status',
        maxWidth: 120,
        field: 'Status',
        filter: 'agTextColumnFilter',
        cellStyle: function (params: any) {
          if (params.value == 'Close' || params.value == 'Complete') {
            return { color: 'white', backgroundColor: 'green' };
          } else if (params.value == 'In Progress') {
            return { color: 'black', backgroundColor: 'orange' };
          } else {
            params.value = params.value == 'ReOpen' ? 'ReOpened' : params.value;
            return { color: 'white', backgroundColor: 'red' };
          }
        },
        
      },
      {
        headerName: 'WO Id',
        maxWidth: 100,
        field: 'WOId',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'WO Detail',
        minWidth: 200,
        field: 'woDetails',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'WO Type',
        minWidth: 200,
        field: 'woType',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Line/Machine',
        minWidth: 230,
        field: 'LineUnit',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Priority',
        maxWidth: 150,
        field: 'Priority',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Before Picture',
        maxWidth: 100,
        field: 'beforePicture',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = '';
          if (
            params &&
            params.data &&
            params.data.operExecWo &&
            params.data.operExecWo.UploadPicture
          ) {
            html = `<img style="width:60px;height:45px ;cursor:pointer" alt="" src="${
              environment.O3CoreApiImageUrl +
              params.data.operExecWo.UploadPicture.split(',')[0]
            }">`;
          } else {
            html = `<img style="width:60px;height:45px ;cursor:pointer" src="assets/images/no-image.png">`;
          }
          return html;
        },
        
      },

      {
        headerName: 'Raised By',
        minWidth: 200,
        field: 'raisedBy',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'WO Creation Stamp',
        field: 'WOCreatedStamp',
        minWidth: 220,
        filter: 'agTextColumnFilter',
        valueFormatter: function (params: any) {
          if(params.value){
          return moment(params.value).format('DD/MM/YYYY hh:mm a');
        }else{
          return 
        }
        },
        
      },
      {
        headerName: ' Technician StartDate',
        field: 'StartTime',
        minWidth: 220,
        filter: 'agTextColumnFilter',
        valueFormatter: function (params: any) {
          if(params.value){
          return moment(params.value).format('DD/MM/YYYY hh:mm a');
        }else{
          return 
        }
        },
        
      },
      {
        headerName: 'Technician CompleteDate',
        field: 'CompleteTime',
        minWidth: 220,
        filter: 'agTextColumnFilter',
        valueFormatter: function (params: any) {
          if (params.value) {
            return moment(params.value).format('DD/MM/YYYY hh:mm a');
          } 
          else {
          }
        },
        
      },
    ];

    this.pmColumnDefs = [
      {
        headerName: 'Action',
        field: 'Action',
        cellRenderer: 'actionEditDeleteViewBtnRenderer',
        minWidth: 140,
        cellRendererParams: {
          onClickEdit: this.editRow.bind(this),
          onClickView: this.viewButtonClicked.bind(this),
        },
        
      },
      {
        headerName: 'Status',
        minWidth: 130,
        field: 'Status',
        filter: 'agTextColumnFilter',
        cellStyle: function (params: any) {
          if (params.value == 'Done') {
            return { color: 'white', backgroundColor: 'green' };
          } else if(params.value == 'In Progress'){
            return { color: 'black', backgroundColor: 'orange' };
          }
          else{
            return { color: 'white', backgroundColor: 'red' };
          }

        },
        
      },
      {
        headerName: 'Line/Machine',
        minWidth: 230,
        field: 'LineUnit',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Spare Parts Code',
        minWidth: 200,
        field: 'spareParts',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Description',
        minWidth: 200,
        field: 'Description',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Started Time',
        minWidth: 180,
        field: 'StartTime',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html =
            params && params.value
              ? moment(params.value).format('DD/MM/YYYY hh:mm a')
              : '';
          return html;
        },
        
      },
      {
        headerName: 'Finished Time',
        minWidth: 180,
        field: 'FinishTime',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html =
            params && params.value
              ? moment(params.value).format('DD/MM/YYYY hh:mm a')
              : '';
          return html;
        },
        
      },
      {
        headerName: 'Frequency',
        minWidth: 100,
        field: 'Frequency',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Activity Type',
        minWidth: 150,
        field: 'Technology',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Scheduled Date',
        minWidth: 180,
        field: 'TaskExpireTime',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html =
            params && params.value
              ? moment(params.value).format('DD/MM/YYYY') // hh:mm a
              : '';
          return html;
        },
        
      },
      {
        headerName: 'Upload Picture',
        minWidth: 100,
        field: 'UploadPicture',
        filter: 'agTextColumnFilter',
        cellRenderer: function (params: any) {
          let html = '';
          if (params && params.data && params.data.UploadPicture) {
            html = `<img style="width:60px;height:45px ;cursor:pointer" alt="" src="${
              params.data.UploadPicture
                ? environment.O3CoreApiImageUrl + params.data.UploadPicture.split(',')[0]
                : ''
            }">`;
          } else {
            html = `<img style="width:60px;height:45px ;cursor:pointer" src="assets/images/no-image.png">`;
          }
          return html;
        },
        
      },
    ];
  }

  ngOnInit(): void {
    this.isInit=true;
    this.ImageUrl = environment.O3CoreApiImageUrl;
    this.blobStorageUrl = environment.O3CoreApiImageUrl;
    this.selectedFilter = this.technicianService.getTechnicianTabIndex();
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    const stDT = new Date();
    stDT.setDate(stDT.getDate()-1);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    this.HeaderForm = this.fb.group({
      Group: new FormControl(null, [Validators.required]),
      Plant: new FormControl(null, [Validators.required]),
      Filter: new FormControl(null, [Validators.required]),
      datePicker: new FormControl([stDT,edDT]),
    });
    let techHeaderData = this.technicianService.getTechnicianHeaderData();
    if (techHeaderData) {
      if (techHeaderData.Group) this.HeaderData.Group = techHeaderData.Group;
      if (techHeaderData.Plant) this.HeaderData.Plant = techHeaderData.Plant;
    }
    let dte = new Date();
    dte.setDate(dte.getDate() - 1);
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroups();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  getFilterData() {
    if (!this.HeaderData.Group || !this.HeaderData.Plant) return;
    else {
      this.minDate =this.HeaderForm?.get('datePicker')?.value[0] // this.dateFilter.From;
      this.maxDate =this.HeaderForm?.get('datePicker')?.value[1] //this.dateFilter.To;
      this.getUserList();
    }
  }

  getUserList() {
    this.spinnerText = 'Fetching User Data';
    this.spinner.show();
    this.managerExecutionService.getUserList().subscribe(
      (res: any) => {
        this.spinner.hide();
        this.userList = res.UserList;

        this.getTechnicianExecutionList();
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  getUserListPMD() {
    this.spinnerText = 'Fetching User Data';
    this.spinner.show();
    const data = {
      EGId: this.HeaderData.Group,
      PlantId: this.HeaderData.Plant,
    };
    this.commonservice.getPeopleMasterData(data).subscribe(
      (res: any) => {
        this.userListPMD = res.employeeInfoList;
        this.spinner.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  getUserName(userId: number) {
    let objFindName = this.userList.find((usr: any) => usr.UserId == userId);
    if (objFindName) return objFindName?.UserName;
    else if (userId == 100001) return 'Admin';
    else return null;
  }

  getTechnicianExecutionList(): void {
    const data = {
      UserId: this.user.UserId,
      TaskType:
        this.selectedFilter == 0
          ? 'Work Order'
          : this.selectedFilter == 1
          ? 'PM Task'
          : 'Work Order',
      From:this.HeaderForm?.get('datePicker')?.value[0],// this.dateFilter.From,
      To: this.HeaderForm?.get('datePicker')?.value[1], //this.dateFilter.To,
      groupId: this.HeaderData.Group,
      plantId: this.HeaderData.Plant,
    };
    this.spinner.show();
    this.spinnerText = 'Fetching Technician Data';
    this.technicianService.getTechnicianExecutionList(data).subscribe(
      (res: any) => {
        if (data.TaskType === 'Work Order') {
          this.woList = res.WO;
          this.woList.map((row: any) => {
            row.IsWrite = this.IsWrite;
            row.Status =
              row.operExecWo.WOStatus == 1
                ? 'Open'
                : row.operExecWo.WOStatus == 2
                ? 'Close'
                : row.operExecWo.WOStatus == 3
                ? 'Complete'
                : row.operExecWo.WOStatus == 4
                ? 'Delay'
                : 'In Progress';
            row.raisedBy = this.getUserName(row.operExecWo?.createdBy);
            row.WOCreatedStamp = row.operExecWo?.CreatedAt;
            row.woDetails = row.operExecWo?.Remark;
            row.woType = row.operExecWo?.WOType?.Name;
            row.WOId =
              row.operExecWo && row.operExecWo.WOId ? row.operExecWo.WOId : '';
            row.LineUnit =
              row && row.operExecWo && row.operExecWo.line
                ? (row.operExecWo.line ? row.operExecWo.line.plDesc : '') +
                  '/' +
                  (row.operExecWo.units ? row.operExecWo.units.puDesc : '')
                : '';
            row.Priority = row && row.Priority ? row.Priority.Name : '';
            row.woCreationStamp = row.operExecWo.TimeStamp;
            row.CreatedAt=row.operExecWo.CreatedAt;

            row.ClosedAt=row.operExecWo.ClosedAt;
            if(row.operExecWo.techExc){
              row.StartTime=row.operExecWo.techExc.StartTime;
              row.CompleteTime=row.operExecWo.techExc.CompleteTime;
            }            
            (row.woClosedStamp =
              row && row.operExecWo && row.operExecWo.ClosedAt
                ? row.operExecWo.ClosedAt
                : ''),
              (row.IsWrite = row.Status == 'Complete' || row.Status == 'Close' ? false : true);
          });
        } else {
          this.pmTaskList = res.WO;
          this.pmTaskList.map((pmTask: any) => {
            pmTask.Status = pmTask.StartTime && pmTask.FinishTime ? 'Done': pmTask.StartTime ? 'In Progress' : 'Open';
            pmTask.IsWrite = this.IsWrite;
            (pmTask.IsWrite = pmTask.Status == 'Done' ? false : true);
            pmTask.LineUnit =
              (pmTask && pmTask.Task && pmTask.Task.line
                ? pmTask.Task.line.plDesc
                : '') +
              '/' +
              (pmTask.Task.units ? pmTask.Task.units.puDesc : '');
            pmTask.spareParts =
              pmTask && pmTask.Task && pmTask.Task.SpareParts
                ? pmTask.Task.SpareParts
                : '';
            pmTask.StartTime =
              pmTask && pmTask.StartTime ? pmTask.StartTime : '';
            pmTask.FinishTime =
              pmTask && pmTask.FinishTime ? pmTask.FinishTime : '';
            pmTask.Frequency =
              pmTask && pmTask.Task && pmTask.Task.Frequency
                ? pmTask.Task.Frequency.Name
                : '';
            pmTask.Technology =
              pmTask && pmTask.Task && pmTask.Task.WOType
                ? pmTask.Task.WOType.Name
                : '';
            pmTask.TaskExpireTime =
            
              pmTask && pmTask.TaskExpireTime ? pmTask.TaskExpireTime : '';
              pmTask.Description= pmTask && pmTask.Task.Description ? pmTask.Task.Description : '';
          });
          this.woList = [];
        }
        this.getPaginator();
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      }
    );
  }

  getGroups() {
    const key = {
      UserId: this.user.UserId,
    };
    const id = {
      value: 0,
    };
    this.cilMasterService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.dropDownList;
        this.GroupTempList = data.dropDownList;
        if (this.GroupTempList && this.GroupTempList.length) {
          if (this.HeaderData.Group) {
            const find = this.GroupTempList.find(
              (grp: any) => grp.id == this.HeaderData.Group
            );
            this.HeaderData.Group = find ? find.id : this.GroupList[0].id;
          } else this.HeaderForm.get('Group')?.setValue(this.GroupList[0].id);
          this.getPlants(this.HeaderData.Group);
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  onGroupKey(value: any) {
    this.GroupList = this.searchGroupKey(value.value);
  }

  searchGroupKey(value: string) {
    let filter = value;
    return this.GroupTempList.filter((grp: any) => {
      let lowerCase = grp.name.toLocaleLowerCase();
      return grp.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  getPlants(id: any): void {
    this.cilMasterService.getPlantsData(id).subscribe(
      (data: any) => {
        this.PlantList = data.dropDownList;
        this.PlantTempList = data.dropDownList;
        if (this.PlantTempList && this.PlantTempList.length) {
          if (this.HeaderData.Plant) {
            const find = this.PlantTempList.find(
              (pnt: any) => pnt.id == this.HeaderData.Plant
            );
            this.HeaderData.Plant = find ? find.id : this.PlantList[0].id;
          } else this.HeaderForm.get('Plant')?.setValue(this.PlantList[0].id);
         this.setPMFilter();
          this.getFilterData();
        } else {
          this.setPMFilter();
          this.getFilterData();
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
setPMFilter(){
  if(this.isInit){
    const pmFilter =JSON.parse(localStorage.getItem("_pmFilter"));
    if(pmFilter!=null && pmFilter!=undefined)
     {
       this.HeaderData.Group=pmFilter.Group;
       this.HeaderData.Plant=pmFilter.Plant;
       this.HeaderForm?.get('datePicker').patchValue([new Date(pmFilter.FromDate),new Date(pmFilter.ToDate)]);
       localStorage.removeItem('_pmFilte');
       this.isInit=false;
     }  
  }
}
  onPlantKey(value: any) {
    this.PlantList = this.searchPlantKey(value.value);
  }

  searchPlantKey(value: string) {
    let filter = value;
    return this.PlantTempList.filter((grp: any) => {
      let lowerCase = grp.name.toLocaleLowerCase();
      return grp.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.woDataSource.filter = filterValue.trim().toLowerCase();

    if (this.woDataSource.paginator) {
      this.woDataSource.paginator.firstPage();
    }
  }

  getPaginator(): void {
    setTimeout(() => {
      this.woDataSource.paginator = this.paginator;
      this.woDataSource.sort = this.sort;
    }, 100);
  }

  ExportTOExcel() {
    this.ExportTechnicianWOList = [];
    this.ExportTechnicianPMList = [];
    if (this.selectedFilter == 0 || this.selectedFilter == 'Work Order') {
      if (this.woList && this.woList.length) {
        for (const row of this.woList) {
          const ExportData = {
            ['WO Status']:
              row && row.operExecWo && row.operExecWo.WOStatus
                ? row.operExecWo.WOStatus == 1
                  ? 'Open'
                  : row.operExecWo.WOStatus == 2
                  ? 'Close'
                  : row.operExecWo.WOStatus == 3
                  ? 'Complete'
                  : row.operExecWo.WOStatus == 4
                  ? 'Delay'
                  : row.operExecWo.WOStatus == 5
                  ? 'In Progress'
                  : row.operExecWo.WOStatus == 6
                  ? 'ReOpen'
                  : ''
                : '',
            ['WO ID']:
              row && row.operExecWo && row.operExecWo.WOId
                ? row.operExecWo.WOId
                : '',
            ['WO Creation Date']:
              row && row.operExecWo && row.operExecWo.TimeStamp
                ? moment(row.operExecWo.TimeStamp).format('DD/MM/YYYY hh:mm a')
                : '',
            ['WO Closed Date']:
              row && row.operExecWo && row.operExecWo.ClosedAt
                ? moment(row.operExecWo.ClosedAt).format('DD/MM/YYYY hh:mm a')
                : '',
            ['WO Type']:
              row && row.operExecWo && row.operExecWo.WOType
                ? row.operExecWo.WOType.Name
                : '',
            ['Failure Mode']:
              row && row.operExecWo && row.operExecWo.FailureMode
                ? row.operExecWo.FailureMode.Name
                : '',
            ['Group']:
              row && row.operExecWo && row.operExecWo.Group
                ? row.operExecWo.Group.egDesc
                : '',
            ['Line/Area']: row && row.LineUnit ? row.LineUnit : '',
            ['Spare Parts']:
              row && row.operExecWo && row.operExecWo.SpareParts
                ? 'Required'
                : 'Not Required',
            ['Spare Parts Code']:
              row && row.SparePartCode ? row.SparePartCode : '',
            ['Priority']: row && row.Priority ? row.Priority : '',
            ['Operator Remark']:
              row && row.operExecWo && row.operExecWo.Remark
                ? row.operExecWo.Remark
                : '',
            ['Supervisor Remarks']:
              row && row.ManagerRemarks ? row.ManagerRemarks : '',
            ['Requested By']: row && row.raisedBy ? row.raisedBy : '',
            ['Technician Status']: row && row.Status ? row.Status : '',
            ['Technician Start Time']:
              row.operExecWo &&
              row.operExecWo.techExc &&
              row.operExecWo.techExc.StartTime
                ? moment(row.operExecWo.techExc.StartTime).format(
                    'DD/MM/YYYY hh:mm a'
                  )
                : '',
            ['Technician SP Request Time']:
              row.operExecWo &&
              row.operExecWo.techExc &&
              row.operExecWo.techExc.SPRequestTime
                ? moment(row.operExecWo.techExc.SPRequestTime).format(
                    'DD/MM/YYYY hh:mm a'
                  )
                : '',
            ['Technician SP Received Time']:
              row.operExecWo &&
              row.operExecWo.techExc &&
              row.operExecWo.techExc.SPReceivedTime
                ? moment(row.operExecWo.techExc.SPReceivedTime).format(
                    'DD/MM/YYYY hh:mm a'
                  )
                : '',
            ['Technician Complete Time']:
              row.operExecWo &&
              row.operExecWo.techExc &&
              row.operExecWo.techExc.CompleteTime
                ? moment(row.operExecWo.techExc.CompleteTime).format(
                    'DD/MM/YYYY hh:mm a'
                  )
                : '',
            ['Technician Remarks']:
              row.operExecWo &&
              row.operExecWo.techExc &&
              row.operExecWo.techExc.Remarks
                ? row.operExecWo.techExc.Remarks
                : '',
          };
          this.ExportTechnicianWOList.push(ExportData);
        }
      } else {
        this.ExportTechnicianWOList = [
          {
            ['WO Status']: '',
            ['WO ID']: '',
            ['WO Creation Date']: '',
            ['WO Closed Date']: '',
            ['WO Type']: '',
            ['Failure Mode']: '',
            ['Group']: '',
            ['Line/Area']: '',
            ['Spare Parts']: '',
            ['Spare Parts Code']: '',
            ['Priority']: '',
            ['Operator Remark']: '',
            ['Supervisor Remarks']: '',
            ['Requested By']: '',
            ['Technician Status']: '',
            ['Technician Start Time']: '',
            ['Technician SP Request Time']: '',
            ['Technician SP Received Time']: '',
            ['Technician Complete Time']: '',
            ['Technician Remarks']: '',
          },
        ];
      }
      const workBook = XLSX.utils.book_new();
      const WOSheet = XLSX.utils.json_to_sheet(this.ExportTechnicianWOList);
      XLSX.utils.book_append_sheet(workBook, WOSheet, 'Technician Work Order');
      XLSX.writeFile(workBook, `Technician Work Order List.xlsx`);
    } else if (this.selectedFilter == 1 || this.selectedFilter == 'PM Task') {
      if (this.pmTaskList && this.pmTaskList.length) {
        for (const row of this.pmTaskList) {
          const ExportData = {
            ['Group']:
              row && row.Task && row.Task.Group ? row.Task.Group.egDesc : '',
            ['Line/Machine']: row && row.LineUnit ? row.LineUnit : '',
            ['Task Description']:
              row && row.Task && row.Task.Description
                ? row.Task.Description
                : '',
            ['Spare Parts Code']: row && row.spareParts ? row.spareParts : '',
            ['Duration']:
              row && row.Task && row.Task.Duration ? row.Task.Duration : '',
            ['Activity Type']: row && row.Technology ? row.Technology : '',
            ['Frequency']: row && row.Frequency ? row.Frequency : '',
            ['Scheduled Date']:
              row && row.TaskExpireTime
                ? moment(row.TaskExpireTime).format('DD/MM/YYYY')
                : '',
            ['Technician Status']: row && row.Status ? row.Status : '',
            ['Start Time']: row && row.StartTime ? moment(row.StartTime).format('DD/MM/YYYY hh:mm a') : '',
            ['Finish Time']: row && row.FinishTime ? moment(row.FinishTime).format('DD/MM/YYYY hh:mm a') : '',
            ['Technician Remarks']: row && row.Remarks ? row.Remarks : '',
          };
          this.ExportTechnicianPMList.push(ExportData);
        }
      } else {
        this.ExportTechnicianPMList = [
          {
            ['Group']: '',
            ['Line/Machine']: '',
            ['Task Description']: '',
            ['Spare Parts Code']: '',
            ['Duration']: '',
            ['Activity Type']: '',
            ['Frequency']: '',
            ['Scheduled Date']: '',
            ['Technician Status']: '',
            ['Start Time']: '',
            ['Finish Time']: '',
            ['Technician Remarks']: '',
          },
        ];
      }
      const workBook = XLSX.utils.book_new();
      const WOSheet = XLSX.utils.json_to_sheet(this.ExportTechnicianPMList);
      XLSX.utils.book_append_sheet(workBook, WOSheet, 'Technician PM Task');
      XLSX.writeFile(workBook, `Technician PM Task List.xlsx`);
    }
  }

  editRow(element: any) {
    element.rowData.isviewButton = false;
    if (this.selectedFilter == 0 || this.selectedFilter == 'Work Order') {
      this.selectedFilter = 0;
      this.technicianService.setTechnicianTabIndex(this.selectedFilter);
    } else if (this.selectedFilter == 1 || this.selectedFilter == 'PM Task') {
      this.selectedFilter = 1;
      this.technicianService.setTechnicianTabIndex(this.selectedFilter);
    }

    if (this.selectedFilter == 0 || this.selectedFilter == 'Work Order') {
      const data = {
        Group: this.HeaderData.Group,
        Plant: this.HeaderData.Plant,
        FromDate: this.HeaderForm?.get('datePicker')?.value[0],//this.dateFilter.From,
        ToDate: this.HeaderForm?.get('datePicker')?.value[1]//this.dateFilter.To,
      };
      this.technicianService.setTechnicianHeaderData(data);
      this.onClickUpdate(element.rowData);
    } else {
      const data = {
        Group: this.HeaderData.Group,
        Plant: this.HeaderData.Plant,
        FromDate: this.HeaderForm?.get('datePicker')?.value[0],//this.dateFilter.From,
        ToDate: this.HeaderForm?.get('datePicker')?.value[1]//this.dateFilter.To,
      };
      this.technicianService.setTechnicianHeaderData(data);

      this.editPMTask(element.rowData);
    }
    this.storeFilterData();
  }

  onClickUpdate(element: any): void {
    this.technicianService.setTechnicianValues(element);
    this.managerExecutionService.setUserData(this.userList);
    this.router.navigateByUrl('/PM/technician-form');
  }
  viewButtonClicked(element: any) {
    element.rowData.isviewButton = true;
    if (this.selectedFilter == 0 || this.selectedFilter == 'Work Order') {
      this.selectedFilter = 0;
      this.technicianService.setTechnicianTabIndex(this.selectedFilter);
    } else if (this.selectedFilter == 1 || this.selectedFilter == 'PM Task') {
      this.selectedFilter = 1;
      this.technicianService.setTechnicianTabIndex(this.selectedFilter);
    }

    if (this.selectedFilter == 0 || this.selectedFilter == 'Work Order') {
      const data = {
        Group: this.HeaderData.Group,
        Plant: this.HeaderData.Plant,
        FromDate: this.HeaderForm?.get('datePicker')?.value[0],//this.dateFilter.From,
        ToDate: this.HeaderForm?.get('datePicker')?.value[1]//this.dateFilter.To,
      };
      this.technicianService.setTechnicianHeaderData(data);
      this.onClickUpdate(element.rowData);
    } else {
      const data = {
        Group: this.HeaderData.Group,
        Plant: this.HeaderData.Plant,
        FromDate: this.HeaderForm?.get('datePicker')?.value[0],//this.dateFilter.From,
        ToDate: this.HeaderForm?.get('datePicker')?.value[1]//this.dateFilter.To,
      };
      this.technicianService.setTechnicianHeaderData(data);

      this.editPMTask(element.rowData);
      this.storeFilterData();
    }
  }
storeFilterData(){
  const data = {
    Group: this.HeaderData.Group,
    Plant: this.HeaderData.Plant,
    FromDate: this.HeaderForm?.get('datePicker')?.value[0],//this.dateFilter.From,
    ToDate: this.HeaderForm?.get('datePicker')?.value[1]//this.dateFilter.To,
  };
  localStorage.setItem("_pmFilter",JSON.stringify(data))
}
  
  editPMTask(element: any) {
    this.technicianService.setTechnicianTaskValues(element);
    this.managerExecutionService.setUserData(this.userList);
    this.router.navigateByUrl('/PM/prevMaintenance/edit-tech-task');
  }

  onFilterChanged(event: any) {
    if (this.selectedFilter == 0 || this.selectedFilter == 'Work Order')
      this.gridOptionsWO.api.setQuickFilter(event.value);
    else if (this.selectedFilter == 1 || this.selectedFilter == 'PM Task')
      this.gridOptionsPM.api.setQuickFilter(event.value);
  }

  /// AG Grid
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  cellClicked(params: any): void {
    const col = params.colDef.field;
    if (col == 'beforePicture' || col == 'UploadPicture') {
      if (col == 'beforePicture')
        this.slidesImages =
          params.data.operExecWo && params.data.operExecWo.UploadPicture
            ? params.data.operExecWo.UploadPicture.split(',')
            : [];
      else if (col == 'UploadPicture')
        this.slidesImages = params.data.UploadPicture
          ? params.data.UploadPicture.split(',')
          : [];

      if (this.slidesImages && this.slidesImages.length) {
        let dialogRef = this.dialog.open(this.imageViewModal, {
          height: '90%',
          width: '90%',
        });
        dialogRef.afterClosed().subscribe((result) => {});
      }
    }
  }
  oncloseimg(){
    this.dialog.closeAll();
  }
}
