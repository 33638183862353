<section class="welcome-page">
    <app-welcome-banner [bgImage]="leftBannerImgurl"></app-welcome-banner>
    <div class="welcome-content">
      <div class="welcome-form-area">
        <h2 class="welcome-title">Forgot Password ?</h2>
        <p class="notify-text">Looks like you’ve forgotten your password.
          Enter the email address associated to you O3 Smart Manufacturing account and we’ll send over a link.</p>
        <form [formGroup]="ForgotPassword" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label class="label" for="username">Email address</label>
            <input [ngClass]="{
                'is-invalid':
                  ForgotPassword.get('email')?.errors &&
                  ForgotPassword.get('email')?.touched
              }" type="email" formControlName="email" class="form-control"
              placeholder="Enter your email address "/>
              <p class="invalid-feedback" *ngIf="
              ForgotPassword.get('email')?.touched &&
              ForgotPassword.get('email')?.hasError('required')
            ">
                * invalid email
              </p>
              <p
              class="invalid-feedback"
              *ngIf="
                ForgotPassword.get('email')?.hasError('email') &&
                ForgotPassword.get('email')?.touched
              "
            >
              * invalid email
            </p>
          </div>
          <div class="d-flex justify-content-front align-items-center">
            <button mat-flat-button color="accent" [disabled]="!ForgotPassword.valid" class="btn-accent btn-lg">Submit</button>
            <button mat-button class="login-cancel-btn" routerLink="/login">Back</button>
          </div>
        </form>
      </div>
  
    </div>
  
  </section>