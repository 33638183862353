<section class="home-content">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>

  <mat-card>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-6 p-lg-7 p-xl-7">
        <mat-card-title>Check List Master Data</mat-card-title>
      </div>
      <div class="p-col-12 p-md-6 p-lg-5 p-xl-5" style="display: flex; justify-content: flex-end;">
        <form [formGroup]="searchQualityMasterForm"  style="float: right;">
          <div style="display: flex;justify-content: flex-end;">
            <mat-form-field  appearance="fill" class="field-fill inputs">
              <mat-label >Select Group</mat-label>
              <mat-select formControlName="group" placeholder="Select Group" panelClass="testClass" name="Status" (selectionChange)="onChangeGroup($event)">
                <mat-option *ngFor="let group of GroupList" [value]="group.TypeId">
                  {{group.TypeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
              <mat-form-field  appearance="fill" class="field-fill inputs" style="float: right; margin-left: 10px;">
                <mat-label >Select Plant</mat-label>
                <mat-select formControlName="plant" required (selectionChange)="onChangePlant($event)">                
                  <mat-option *ngFor="let plant of plants" [value]="plant.DeptId">{{plant.DeptDesc}}</mat-option>             
                </mat-select>
                <mat-error >Plant is Required</mat-error>
              </mat-form-field> 
          </div>
        </form>
      </div>
    </div>
  </mat-card>
  
  <mat-card class="p-mt-2">
    <div class="p-grid p-fluid">
      <div class="p-xl-12 p-lg-12 p-md-12  p-col-12">
        <app-check-list-type [plant]="plant" (qualityTypeChanged)="renderCheckList($event)"></app-check-list-type>
      </div>
    </div>
  </mat-card>

  <mat-card class="p-mt-2 p-p-0">
    <div class="p-grid p-fluid">
      <div class="p-xl-12 p-lg-12 p-md-12  p-col-12">
        <app-check-List></app-check-List>
      </div>
    </div>
  </mat-card>
</section>