import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Plant } from 'src/app/models/plant';
import { KpimasterService } from 'src/app/services/kpimaster.service';
@Component({
  selector: 'app-quality-area',
  templateUrl: './quality-area.component.html',
  styleUrls: ['./quality-area.component.scss']
})
export class QualityAreaComponent implements OnInit {
  QualityareaForm!: FormGroup;
  
  displayUserModal = false;
  owners = new FormControl();
  ownerList!: any[]
  formMode!: boolean;
  plantsDDL!:Plant[];
  QualityareaColumns: string[] = ['plant','name', 'ownername', 'areaActions'];
  constructor(private fb: FormBuilder,private userService:UserManagementService,private toaster: ToastrService,
    private spinner: NgxSpinnerService,private kpiService: KpimasterService) { }

  ngOnInit(): void {
    this.getAllPlants();
    this.getUsers();
    this.formMode = true;
    this.QualityareaForm = this.fb.group({
      plant: ['', Validators.required],
      area: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      owners: ['', Validators.required]
    });
  }
  getAllPlants():void{
  
    this.kpiService.getAllPlants().subscribe(
          arg => {
                  this.plantsDDL = arg
          },error=>{
            this.toaster.error('Error', error.error.message);
          });
  }
  getUsers(): void {
   
    const key = {
      egId: JSON.parse(localStorage.user).egId,
    };
   
    this.userService.getUsers(key).subscribe(
      (users: any) => {
        this.ownerList = (users);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }

  opensideModal(): void {
    this.formMode = true;

    this.displayUserModal = true;
  }
  closeaddNewPlantItemModel(): void {
    this.displayUserModal = false;
  }
  savequalityArea():void{
  

  }
}
