import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productspecs',
  templateUrl: './productspecs.component.html',
  styleUrls: ['./productspecs.component.scss']
})
export class ProductspecsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
