<div class="content-top-box">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a
        class="breadcrumb-link"
        [routerLink]="breadcrumItem.Url"
        [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
        >{{ breadcrumItem.text }}</a
      >
    </li>
  </ul>
  <div class="tab-content-top-box">
    <h1 class="main-title">Micro Level Saturation Dashboard</h1>
  </div>
  <form [formGroup]="SaturationDbForm" >
    <div class="content-top-row">
          <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label appearance="fill" class="field-fill">Group</mat-label>
            <mat-select  name="group" formControlName="group" (selectionChange)="getPlantByGroup()">
              <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId">  {{g.TypeName}} </mat-option>
            </mat-select>
          </mat-form-field>

        <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label >Plant</mat-label>
          <mat-select name="plant" formControlName="plant" (selectionChange)="onChangePlant()">
            <mat-option *ngFor="let p of filterdplants" [value]="p.DeptId">  {{p.DeptDesc}} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select #selectAll formControlName="employee" placeholder="Employee" multiple
          (selectionChange)="onChangeUser($event)">
            <mat-select-filter
              [placeholder]="'Search'"
              [displayMember]="'fullName'"
              [array]="ownerList"
              (filteredReturn)="Filteredowners = $event"
            ></mat-select-filter>
            <mat-option (click)="selectAllItems()" value="0">Select All</mat-option>
            <mat-option *ngFor="let o of Filteredowners" [value]="o.userId">{{o.fullName}}</mat-option>
          </mat-select> 
        </mat-form-field>
        <div class="inputField">
          <nz-date-picker (ngModelChange)="getMicrolLevelSaturations()" class="calender-input"  id="startPicker" formControlName="dateStart" nzFormat="dd/MM/yyyy" [nzInputReadOnly]="true" ></nz-date-picker>
          <span class="floating-text">Current From Date</span>   
        </div>
        <div class="inputField">
          <nz-date-picker (ngModelChange)="getMicrolLevelSaturations()" class="calender-input"  id="endPicker" formControlName="dateEnd" nzFormat="dd/MM/yyyy" [nzInputReadOnly]="true" ></nz-date-picker>
          <span class="floating-text">Current To Date</span>   
        </div>
    </div>
    <div class="content-top-row">
      <div class="inputField">
        <nz-date-picker (ngModelChange)="getBeforeMicrolSaturations()" class="calender-input"  id="beforestartPicker" formControlName="beforeStartDate" nzFormat="dd/MM/yyyy" [nzInputReadOnly]="true" ></nz-date-picker>
        <span class="floating-text">Before From Date</span>   
      </div>

        <div class="inputField">
          <nz-date-picker (ngModelChange)="getBeforeMicrolSaturations()" class="calender-input"  id="beforeendPicker" formControlName="beforeEndDate" nzFormat="dd/MM/yyyy" [nzInputReadOnly]="true" ></nz-date-picker>
          <span class="floating-text">Before To Date</span>   
        </div>
    </div>
  </form>
</div>
<div class="chart-group">
  <!-- OEE Loses -->
  <div class="chart-items">
    <div class="chart-title"> Micro Level Saturation</div>
    <div id="chart">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [dataLabels]="chartOptions.dataLabels"
        [plotOptions]="chartOptions.plotOptions"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [stroke]="chartOptions.stroke"
        [fill]="chartOptions.fill"
        [title]="chartOptions.title"
        [tooltip]="chartOptions.tooltip"
        [legend]="chartOptions.legend"
        [noData]="{
          text:'No-Data',
          align: 'center',
          verticalAlign: 'middle',
          offsetX : 0,
          offsetY:0,
          style: {
            fontSize:'14px'
          }}"
      ></apx-chart>
    </div>      
  </div>

  <div class="chart-items">
    <div class="chart-title">Operator Activities</div>
    <div id="chart1">
      <apx-chart
        [series]="chartOptions1.series"
        [chart]="chartOptions1.chart"
        [dataLabels]="chartOptions1.dataLabels"
        [plotOptions]="chartOptions1.plotOptions"
        [xaxis]="chartOptions1.xaxis"
        [yaxis]="chartOptions1.yaxis"
        [stroke]="chartOptions1.stroke"
        [fill]="chartOptions1.fill"
        [title]="chartOptions1.title"
        [tooltip]="chartOptions1.tooltip"
        [legend]="chartOptions1.legend"
      ></apx-chart>
    </div>      
  </div>
</div>
<div class="chart-group">
  <div class="chart-items">
    <div class="chart-title">Current</div>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource1">
        <ng-container matColumnDef="Operators">
          <th mat-header-cell *matHeaderCellDef> Operators </th>
          <td mat-cell *matCellDef="let element"> {{element.Operator}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="Working">
          <th mat-header-cell *matHeaderCellDef> Working </th>
          <td mat-cell *matCellDef="let element"> {{element.Working}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="Waiting">
          <th mat-header-cell *matHeaderCellDef> Waiting </th>
          <td mat-cell *matCellDef="let element"> {{element.Waiting}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3">No data matching</td>
        </tr>
      </table>
      <mat-paginator #paginator1="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div class="chart-items">
    <div class="chart-title">Current</div>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource2">
        <ng-container matColumnDef="Operators">
          <th mat-header-cell *matHeaderCellDef> Operator </th>
          <td mat-cell *matCellDef="let element"> {{element.Operator}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="Working">
          <th mat-header-cell *matHeaderCellDef> Value Added </th>
          <td mat-cell *matCellDef="let element"> {{element.Working}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="Waiting">
          <th mat-header-cell *matHeaderCellDef> Semi Value Added </th>
          <td mat-cell *matCellDef="let element"> {{element.Waiting}} </td>
        </ng-container>

        <ng-container matColumnDef="Loading">
          <th mat-header-cell *matHeaderCellDef> Non Value Added </th>
          <td mat-cell *matCellDef="let element"> {{element.Loading}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching</td>
        </tr>
      </table>
      <mat-paginator #paginator2="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
<div class="chart-group">
  <div class="chart-items">
    <div class="chart-title">BEFORE</div>
    <div id="chart3">
      <apx-chart
        [series]="chartOptions2.series"
        [chart]="chartOptions2.chart"
        [dataLabels]="chartOptions2.dataLabels"
        [plotOptions]="chartOptions2.plotOptions"
        [xaxis]="chartOptions2.xaxis"
        [yaxis]="chartOptions2.yaxis"
        [stroke]="chartOptions2.stroke"
        [fill]="chartOptions2.fill"
        [title]="chartOptions2.title"
        [tooltip]="chartOptions2.tooltip"
        [legend]="chartOptions2.legend"
      ></apx-chart>
    </div>      
  </div>
  <div class="chart-items">
    <div class="chart-title">BEFORE</div>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource3">
        <ng-container matColumnDef="Operators">
          <th mat-header-cell *matHeaderCellDef> Operators </th>
          <td mat-cell *matCellDef="let element"> {{element.Operator}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="Working">
          <th mat-header-cell *matHeaderCellDef> Working </th>
          <td mat-cell *matCellDef="let element"> {{element.Working}} </td>
        </ng-container>

        <ng-container matColumnDef="Waiting">
          <th mat-header-cell *matHeaderCellDef> Waiting </th>
          <td mat-cell *matCellDef="let element"> {{element.Waiting}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="Loading">
          <th mat-header-cell *matHeaderCellDef> Loading </th>
          <td mat-cell *matCellDef="let element"> {{element.Loading}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching</td>
        </tr>
      </table>
      <mat-paginator #paginator3="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
<div class="chart-group">
  <div class="chart-items" style="width: 100%">
    <div class="chart-title">Total Hours Worked</div>
    <div>
      <apx-chart
        [series]="chartOleTotalHoursWorkedOptions.series"
        [chart]="chartOleTotalHoursWorkedOptions.chart"
        [dataLabels]="chartOleTotalHoursWorkedOptions.dataLabels"
        [plotOptions]="chartOleTotalHoursWorkedOptions.plotOptions"
        [xaxis]="chartOleTotalHoursWorkedOptions.xaxis"
        [yaxis]="chartOleTotalHoursWorkedOptions.yaxis"
        [stroke]="chartOleTotalHoursWorkedOptions.stroke"
        [fill]="chartOleTotalHoursWorkedOptions.fill"
        [title]="chartOleTotalHoursWorkedOptions.title"
        [tooltip]="chartOleTotalHoursWorkedOptions.tooltip"
        [legend]="chartOleTotalHoursWorkedOptions.legend"
      ></apx-chart>
    </div>      
  </div>
</div>

