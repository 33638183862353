<div class="production-db-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="title-row-flex-both-end">
      <h1 class="main-title">Process Confirmation Dashboard</h1>
    </div>
  </div>

  <!-- Dropdown -->
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  <form [formGroup]="ProcessConfirmationDbForm">
    <div class="drop-down-group">
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Group</mat-label>
          <mat-select name="group" formControlName="group" (selectionChange)="getPlantByGroup()">
            <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label>Plant</mat-label>
          <mat-select name="plant" formControlName="plant" (selectionChange)="getAreaByPlant()">
            <mat-option *ngFor="let p of filterdplants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Area</mat-label>
          <mat-select name="area" formControlName="area" (selectionChange)="onToProcessDate()">
            <mat-option value="ALL">All</mat-option>
            <mat-option *ngFor="let a of areaDDL" [value]="a.areaId"> {{a.areaName}} </mat-option>

          </mat-select>
        </mat-form-field>
      </div>
      <div class="inputField top-row-first-filed-item top-row-first-filed-item-order-processing">
        <nz-range-picker class="calender-input" [nzInputReadOnly]="true" formControlName="datePicker"
          nzFormat="dd/MM/YYYY" (ngModelChange)="onToProcessDate()"></nz-range-picker>
        <span class="floating-text">Choose Date Range</span>
      </div>
   
      <!-- <div class="drop-down-element" style="width: 18%;">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"> 
          <mat-label appearance="fill">{{langObj ? langObj?.FromDate : ('FromDate' | translate)}}</mat-label>
          <input matInput [ngxMatDatetimePicker]="startPicker" class="form-control"
            formControlName="dateStart" [min]="minDate" [max]="maxDate" (dateInput)="onProcessDate()"
            [(ngModel)]="FromTimePicker" readonly>
          <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #startPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
            [color]="color" [enableMeridian]="enableMeridian">
          </ngx-mat-datetime-picker>
        </mat-form-field> 
      </div> -->
      <!-- <div class="drop-down-element" style="width: 18%;">
         <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label appearance="fill">{{langObj ? langObj?.ToDate : ('ToDate' | translate)}}</mat-label>
          <input matInput [ngxMatDatetimePicker]="endPicker" class="form-control"
            formControlName="dateEnd" [min]="minDate" [max]="maxDate" (dateInput)="onToProcessDate()"
            [(ngModel)]="ToTimePicker" readonly>
          <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #endPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
            [color]="color" [enableMeridian]="enableMeridian">
          </ngx-mat-datetime-picker>
        </mat-form-field> 
      </div> -->
    </div>
  </form>


</div>
<!-- Charts -->
<div class="chart-group">
  <div class="chart-items">

    <div [chart]="chart"></div>
  </div>
  <div class="chart-items">
    <div [chart]="userChart"></div>
  </div>
</div>
<div class="chart-group">
  <div class="chart-items" style="width: 100%;">
    <div [chart]="dateChart"></div>
  </div>
</div>

<section>
  <div class="example-button-row">
    <button type="button" class="btn btn-success"  (click)="onBtnExportCSV()" style="margin-top: 30px;"><img src="assets/images/csv-file-format.png" ></button>
    <button type="button" class="btn btn-success" (click)="onBtnExportExcel()" style="margin-top: 30px; margin-left: 10px;"><i class="fa fa-file-excel-o icon-white"  aria-hidden="true"></i></button>
  </div>
</section>
<!--ag-grid-->
<div style="margin-top: 10px;height: 300px;">
  <ag-grid-angular style="width: 100%; height:100%;" class="ag-theme-alpine" [columnDefs]="columnDefs"
    [rowData]="dataArray" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"
    [pagination]="true" [paginationPageSize]="10"></ag-grid-angular>
</div>