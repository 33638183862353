<section class="home-content" dir="{{languageDir}}">
    <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
    </ul>
    <mat-card class="master-card">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-6 p-lg-7 p-xl-7">
                <mat-card-title class="master-title">{{langObj ? langObj?.PerformMasterData : ('PerformMasterData' |
                    translate)}}</mat-card-title>
            </div>

            <div class="p-col-12 p-md-6 p-lg-5 p-xl-5" style="display: flex; justify-content: flex-end;">
                <form [formGroup]="searchAuditMasterForm" style="float: right;">
                    <mat-form-field appearance="fill" class="field-fill-custom-master" style="margin-right: 10px;">
                        <mat-label class="required">{{langObj ? langObj?.Group : ('Group' | translate)}}</mat-label>
                        <mat-select panelClass="testClass" name="group" formControlName="group"
                            (selectionChange)="getPlantByGroup()">
                            <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groupListDDL"
                            (filteredReturn)="filteredGroupListDDL = $event"></mat-select-filter>
                            <mat-option *ngFor="let g of filteredGroupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
                        </mat-select>
                    </mat-form-field>

                </form>
                <form [formGroup]="searchAuditMasterForm" style="float: right; margin-left: 10px;">
                    <mat-form-field appearance="fill" class="field-fill-custom-master">
                        <mat-label class="required">{{langObj ? langObj?.Plant : ('Plant' | translate)}}</mat-label>
                        <mat-select panelClass="testClass" name="plant" formControlName="plant"
                            (selectionChange)="getMasterData()">
                            <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plants"
                            (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                            <mat-option *ngFor="let p of filteredPlants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>
    </mat-card>
    <mat-card class="p-mt-2 p-p-0">
        <!-- Table -->
        <div class="p-grid p-fluid">
            <div class="p-xl-6 p-lg-6 p-md-6 p-col-6">
                <app-meetingmastersettings></app-meetingmastersettings>
            </div>
            <div class="p-xl-6 p-lg-6 p-md-6 p-col-6">
                <app-meeting-effectiveness-checklist></app-meeting-effectiveness-checklist>
            </div>
            <div class="p-xl-6 p-lg-6 p-md-6 p-col-6">
                <app-meeting-menus [plantId]="searchAuditMasterForm.controls.plant.value" [groupId]="searchAuditMasterForm.controls.group.value"></app-meeting-menus>
            </div>
        </div>
    </mat-card>
</section>