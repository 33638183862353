import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerManagementService {
  baseUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }
  addCustomer(data: any): any {
    return this.http.post<any>(this.baseUrl + 'addcustomer', data);
  }

  getCustomerList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'customers', data);
  }

  deleteCustomer(data: any): any {
    return this.http.post<any>(this.baseUrl + 'deletecustomer', data);
  }
  updateCustomer(data: any): any {
    return this.http.put<any>(this.baseUrl + 'customer', data);
  }

  getCountries(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'countries');
  }
  getCities(data: any): any {
    return this.http.post<any>(this.baseUrl + 'citiesbycountries', data);
  }
  getCustomerById(data: any): any {
    return this.http.post<any>(this.baseUrl + 'customer', data);
  }

  addUserByCustomer(data: any): any {
    return this.http.post<any>(this.baseUrl + 'customeruser', data);
  }
  getProducts(data: any): any {
    return this.http.post<any>(this.baseUrl + 'productsByGroup', data);
  }
  getUserList(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'allcustomeruser', data);
  }
  deleteUser(data: any): any {
    return this.http.post<any>(this.baseUrl + 'deletecustomeruser', data);
  }
  getUserById(data: any): any {
    return this.http.post<any>(this.baseUrl + 'onecustomeruser', data);
  }
  updateUser(data: any): any {
    return this.http.put<any>(this.baseUrl + 'customeruser', data);
  }
  customerLogin(data: any): any {
    return this.http.post<any>(this.baseUrl + 'customerlogin', data);
  }
}
