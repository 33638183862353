<div class="availability-analysis-content">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="title-row-flex-both-end">
      <h1 class="main-title">NCR & Customer Complaint DashBoard</h1>
      <!-- <div class="basic-mat-input">
        <span class="date-label">Last 30 days</span>
        <mat-form-field appearence="fill">
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="startDate">
            <input matEndDate formControlName="endDate">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div> -->
    </div>
  </div>

  <!-- Dropdown -->
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  <form [formGroup]="dropDownForm">
    <div class="drop-down-group">
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Group</mat-label>
          <mat-select formControlName="group" panelClass="testClass" name="Group"  (selectionChange)="onChangeGroup($event)" >
            <mat-option *ngFor="let Group of GroupList" [value]="Group.TypeId">
              {{Group.TypeName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Plant</mat-label>
          <mat-select panelClass="testClass" name="plant" formControlName="plant" (selectionChange)="onChangePlant($event)" required>
            <mat-option *ngFor="let plant of Plants" [value]="plant.DeptId">
              {{ plant.DeptDesc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Year</mat-label>
          <mat-select name="Year" formControlName="year" (selectionChange)="getCCRdashboardData()">
            <mat-option *ngFor="let Year of yearList" [value]="Year">
              {{Year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Month</mat-label>
          <mat-select name="Month" formControlName="month" (selectionChange)="getCCRdashboardData()">
            <mat-option *ngFor="let Month of monthList" [value]="Month">
              {{Month}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Week Days</mat-label>
          <mat-select name="Week" formControlName="week" (selectionChange)="getCCRdashboardData()">
            <mat-option *ngFor="let Week of weekList" [value]="Week">
              {{Week}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Days</mat-label>
          <mat-select name="Day" formControlName="day" (selectionChange)="getCCRdashboardData()">
            <mat-option value="ALL">ALL</mat-option>
            <mat-option *ngFor="let Day of dayList" [value]="Day">
              {{Day}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Shift</mat-label>
          <mat-select name="Shift" formControlName="shift" (selectionChange)="getCCRdashboardData()">
            <mat-option *ngFor="let Shift of shiftList" [value]="Shift">
              {{Shift}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Machine</mat-label>
          <mat-select formControlName="unit" (selectionChange)="getCCRdashboardData()">
            <mat-option  [value]=""> Please Select</mat-option>
            <mat-option *ngFor="let u of Units" [value]="u.puId">
              {{ u.puDesc }}
            </mat-option>
          </mat-select>
          <!-- </mat-select> -->
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<!-- 
  <div class="chart-group">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <div class="chart-items">
      <div class="chart-title"> CCR GAP Analysis</div>
      <canvas id="myChart"></canvas>
    </div>
    <div class="chart-items">
    <div class="chart-title">NCR GAP Analysis</div>
    <canvas id="ncrGapAnalysis"></canvas>
    </div>
  </div> -->



  <!-- Charts -->
  <div class="chart-group">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <!-- <div class="chart-items">
      <div class="chart-title"> CCR GAP Analysis</div>
      <canvas id="myChart"></canvas>
    </div> -->
    <div class="chart-items">
      <div class="chart-title">NCR GAP Analysis</div>
      <canvas id="ncrGapAnalysis"></canvas>
      </div>
    <!-- <div class="chart-items">
      <div class="chart-title ">No of CCR Open Vs Closed Vs Rejected</div>
      <canvas id="myChart1"></canvas>
    </div> -->

    <div class="chart-items">
      <div class="chart-title">No of NCR Open Vs Closed Vs Rejected</div>
      <canvas id="myChart2"></canvas>
    </div>

    <!-- CCR by Product-->
    <!-- <div class="chart-items">
      <div class="chart-title"> CCR by Product</div>
      <canvas id="ccrByProduct"></canvas>
    </div> -->

    <!-- NCR by Product -->
    <div class="chart-items">
      <div class="chart-title"> NCR by Product</div>
      <canvas id="ncrByProduct"></canvas>
    </div>

    <!-- # of Changeovers -->
    <!-- <div class="chart-items">
      <div class="chart-title"> # of CCR</div>
      <canvas id="myChart6"></canvas>
    
    </div> -->

    <!-- Short Stops -->
    <div class="chart-items">
      <div class="chart-title">  # of NCR</div>
      <canvas id="myChart7"></canvas>
    </div>
<!-- 
    <div class="chart-items">
      <div class="chart-title ">No of Complaints vs Concerns in CCR</div>
      <canvas id="ccrConcern"></canvas>
    </div> -->

  </div>
