import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QualityCheckHistoryService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) {}

  getCheckListHistory(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/GetAllQulaityCheckExecutions', data);
  }

  getCheckListHistoryById(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/GetAllQulaityCheckExecutionById', data);
  }

  plantUnit(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Machine/PlantUnit', data);
  }

  verifyqualitychecklist(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/VerifyChecklist', data);
  }

  rejectqualitychecklist(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'QualityChecklist/RejectChecklist', data);
  }

  completequalitychecklist(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/ConfirmChecklist', data);
  }

  rejectquality(data:any) : Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Qualitycheckexecution/RejectChecklist', data);
  }  
}
