import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
  ApexFill,
  ApexTooltip
} from 'ng-apexcharts';

import { Subscription } from 'rxjs';


export type ChartOptions1 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  labels: string[];
  stroke: any; // ApexStroke;
  dataLabels: any; // ApexDataLabels;
  fill: ApexFill;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-meeting-dashboard',
  templateUrl: './meeting-dashboard.component.html',
  styleUrls: ['./meeting-dashboard.component.scss']
})
export class MeetingDashboardComponent implements OnInit {
  breadcrumList!: any;
  MeetingDBForm!: FormGroup;
  groupListDDL!: any[];
  filterdplants!: any[];
  public chartOptions1!: any;
  public chartOptions2!: any;
  displayedColumns: string[] = ['empty', 'time1', 'time2', 'time3', 'time4', 'time5', 'time6',
   'time7', 'time8', 'time9', 'time10', 'time12', 'time13', 'time14'];
   dataSource = [{empty: 'Source', time1: '84' },
                  {empty: 'Target', time1: '90', time2: '90', time3: '90', time4: '90', time5: '90', time6: '90', time7: '90', time8: '90',
                   time9: '90', time10: '90', time11: '90', time12: '90', time13: '90', time14: '90'  }
  ];
  seconddisplayedColumns: string[] = ['empty', 'time1', 'time2', 'time3', 'time4', 'time5', 'time6',
  'time7', 'time8', 'time9', 'time10', 'time12', 'time13', 'time14'];
  dataSourcesecond = [{empty: 'Source', time1: '84', time2: '0', time3: '0', time4: '0', time5: '0', time6: '0', time7: '0', time8: '0',
  time9: '0', time10: '0', time11: '0', time12: '0', time13: '0', time14: '0' },
  {empty: 'Engagement Gap', time1: '5'},
  {empty: 'Action Gap', time1: '9'},
  {empty: 'Closure Gap', time1: '2'},
  {empty: 'Target', time1: '90', time2: '90', time3: '90', time4: '90', time5: '90', time6: '90', time7: '90', time8: '90',
   time9: '90', time10: '90', time11: '90', time12: '90', time13: '90', time14: '90'  }
];
  constructor(private fb: FormBuilder , private usermanagementService: UserManagementService, private toaster: ToastrService,
              public router: Router, private machineService: MachineMappingService) { }

  // tslint:disable-next-line: typedef
  ngOnInit() {
    this.GetBreadcrumList();
    this.getGroupData();
    this.MeetingDBForm = this.fb.group({
      plant: [''],
      group: [''],
    });
    this.chartOptions1 = {
      series: [
        {
          name: 'Target',
          data: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90]
        },
        {
          name: 'Score',
          data: [84]
        }
      ],
      chart: {
        height: 280,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#77B6EA', '#545454'],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Chart',
        align: 'left'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: ['18/7/2019', '20/8/2019', 'dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy',
                      'dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy'],
        // title: {
        //   text: 'Month'
        // }
      },
      yaxis: {
        min: 81,
        max: 91
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    };

    this.chartOptions2 = {
      series: [
        {
          name: 'Score',
          type: 'column',
          data: [84]
        },
        {
          name: 'Target',
          type: 'line',
          data: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90]
        },
        {
          name: 'Engagement Gap',
          type: 'column',
          data: [5]
        },
        {
          name: 'Actions Gap',
          type: 'column',
          data: [9]
        },
        {
          name: 'Closure Gap',
          type: 'column',
          data: [2]
        },
      ],
      chart: {
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 400,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        type: "category",
        categories: [
          '18/7/2019',
        '20/8/2019',
        'dd/mm/yy',
        'dd/mm/yy',
        'dd/mm/yy',
        'dd/mm/yy',
        'dd/mm/yy',
        'dd/mm/yy',
        'dd/mm/yy',
        'dd/mm/yy',
        'dd/mm/yy',
        'dd/mm/yy',
        'dd/mm/yy',
        'dd/mm/yy'
        ]
      },
      legend: {
        position: "right",
        offsetY: 10
      },
      fill: {
        opacity: 1
      }
    };

  }
  getGroupData(): void {
    const key = {
      Id: 0
    };

    this.usermanagementService.getGroupData(key).subscribe((data: any) => {
      this.groupListDDL = data.masterList;
      this.MeetingDBForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
      this.getPlantByGroup();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.MeetingDBForm.value.group
    };
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.filterdplants = data.DepartList;
      this.MeetingDBForm.get('plant')?.setValue(this.filterdplants[0].DeptId);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

}
