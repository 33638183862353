import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FishboneComponent } from './fishbone/fishbone.component';
import { AuthGuard } from '../services/auth.guard';
import { FiveWhyComponent } from './five-why/five-why.component';
import { At6w2hComponent } from './at6w2h/at6w2h.component';
import { FivewhyActionplanComponent } from './fivewhy-actionplan/fivewhy-actionplan.component';
import { CauseEffectComponent } from './cause-effect/cause-effect.component';
import { SwotComponent } from './swot/swot.component';
import { StageDashboardComponent } from './stage-dashboard/stage-dashboard/stage-dashboard.component';
import { SwotpestDashboardComponent } from './swotpest-dashboard/swotpest-dashboard/swotpest-dashboard.component';
import { NcrMangementComponent } from '../quality-management/ncr-ccr-management/ncr-management/ncr-mangement.component';

import { HistoricalTableComponent } from './historical-table/historical-table.component';
import { AtRcaDashboardComponent } from './at-rca-dashboard/at-rca-dashboard.component';
import { AtStages2Component } from './at-stages2/at-stages2.component';
// import { HistoricalTableComponent } from './historical-table/historical-table.component';

const routes: Routes = [
  { path: '', component: At6w2hComponent, canActivate: [AuthGuard] }, //done
  { path: 'sixwtwoh', component: At6w2hComponent, canActivate: [AuthGuard] }, //done
  { path: 'fishbone', component: FishboneComponent, canActivate: [AuthGuard] }, //done
  { path: 'causeEffect', component: CauseEffectComponent, canActivate: [AuthGuard] }, //done
  { path: 'fivewhy', component: FiveWhyComponent, canActivate: [AuthGuard] }, //done
  { path: 'fivewhy-actpln', component: FivewhyActionplanComponent, canActivate: [AuthGuard] }, //done
  { path: 'swot', component: SwotComponent, canActivate: [AuthGuard] }, //done
  { path: 'stages-db', component: StageDashboardComponent, canActivate: [AuthGuard] },
  { path: 'swot-pest-db', component: SwotpestDashboardComponent, canActivate: [AuthGuard] },
  {
    path: 'at-stages',
    component: AtStages2Component,
    canActivate: [AuthGuard],
  }, //done
  {
    path: 'at-stages/edit/:headerID',
    component: AtStages2Component,
    canActivate: [AuthGuard],
  }, //done
  {
    path: 'NcrManagement',
    component: NcrMangementComponent,
    canActivate: [AuthGuard],
  },
  { path: 'historical-table', component: HistoricalTableComponent, canActivate: [AuthGuard] }, //done
  { path: 'rca-dashboard', component: AtRcaDashboardComponent, canActivate: [AuthGuard] }, //done
  { path: 'swot/edit/:headerID', component: SwotComponent, canActivate: [AuthGuard] },
  {
    path: 'NcrManagement/:ncrID',
    component: NcrMangementComponent,
    canActivate: [AuthGuard],
    data: {},
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AtRoutingModule { }
