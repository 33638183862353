import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BatchReportService {
  // tslint:disable-next-line:typedef
  baseUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getItemCode(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'BatchReport/GetItemInfo', data);
  }
  getProcessOrder(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'BatchReport/GetProcessOrder', data);
  }
  getBatchReport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'BatchReport/GetBatchItemBOMList', data);
  }
  getBatchItemBOMReport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'BatchReport/GetBatchItemBOMInfo', data);
  }
  getProductDD(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + 'Variable/GetAllMappedProductCode');
  }
  getProductSpecification(data:any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Variable/GetProductSpecificationByMachine',data);
  }
  getBatchOutReport(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'BatchReport/GetBatchOutputList', data);
  }
  getJobBatchInfoDetails(data:any):Observable<any[]>{
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetJbBatchchecklistDetails',data);
  }

}