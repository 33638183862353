import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { prevTaskService } from '../../../../services/prev-task.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';

@Component({
  selector: 'app-schedule-task-dialog',
  templateUrl: './schedule-task-dialog.component.html',
  styleUrls: ['./schedule-task-dialog.component.scss']
})
export class ScheduleTaskDialogComponent implements OnInit {

  resheduledDate: any;
  taskDetail: any;
  availableDateList: any = [];
  availMachineList: any = [];
  availTaskList: any = [];
  otherTasks: any = [];
  spinnerText: string = '';
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  selectedMachine: number = 0;
  selectedTaskId: number = 0;
  technicianID: any;
  technicianList:any = []
  otherTaskList: any = [];
  permisson!: any;
  IsWrite:boolean = false;
  IsAccess!:any
  @ViewChild('allSelected') private allSelected: any;
  taskList: any = [];
  filteredTaskList: any = [];

  constructor(
    public dialogRef: MatDialogRef<ScheduleTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private enterpriseGroupService: EnterPriseGroupService, 
    private toaster: ToastrService, 
    private prevTaskService: prevTaskService, 
    private spinner: NgxSpinnerService) 
  {
    this.taskDetail = data.task[data.rowIndex];
    this.technicianList = data.technicianList;
    this.technicianID = parseInt(this.taskDetail.TaskBy);
  }

  ngOnInit(): void {
    if (this.taskDetail && !this.taskDetail.Status)
      this.getTechAvailbleTime()
      this.GetMenuAccess();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  updateRescheduleDate() {
    this.resheduledDate = '';
  }

  getMachinesForDate(){
    let availMachines = [];
    let machineList = [];
    let machineBasedTask = [];

    this.availMachineList = [];
    this.selectedMachine = 0;
    this.availTaskList = [];
    this.selectedTaskId = 0;

    let availDate = moment(new Date(this.resheduledDate)).format();        
    if (this.filteredTaskList && this.filteredTaskList.length) {
        availMachines = this.taskList.filter((tsk: any) => moment(tsk.TaskExpireTime).isSame(availDate, 'day'));

        if (availMachines && availMachines.length) {
            for (let machineData of availMachines) {
                const machine = machineList.find((machine: any) => machine.puId == machineData.Task.units.puId)
                if (!machine)
                    machineList.push(machineData.Task.units)
            }
            if (machineList && machineList.length) {
                for (let machine of machineList) {
                    let machineBasedTaskList = availMachines.filter((task: any) => task.Task.units.puId == machine.puId && task.ID != this.taskDetail.ID && !task.Status);
                    let data = { machineId: machine.puId, machineName: machine.puDesc, taskList: machineBasedTaskList }
                    machineBasedTask.push(data)
                }
            }
            this.availMachineList = machineBasedTask
        }
    }
  }

  getTechAvailbleTime() {
    let month = this.monthNames.findIndex((mnth: any) => mnth == this.taskDetail.month);
    let date = new Date().getMonth() == month ? new Date().getDate() : 1;
    const data = {
      id: this.taskDetail.Task.ID,
      taskId: this.taskDetail.ID,
      techId: this.taskDetail.TaskBy,
      taskDuration: this.taskDetail.Task.Duration,
      date: new Date(`${month + 1}-${date}-${new Date().getFullYear()}`),
    };
    this.spinnerText = 'Fetching Available Time';
    this.spinner.show();
    this.prevTaskService.getTechAvailableTime(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.availableDateList = res.data
        this.otherTaskList = res.otherTaskList,
        this.taskList = res.taskList,
        this.filteredTaskList = res.filteredTaskList

      }
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', err.ErrorMessage);
    })
  }

  getAvailbleMachine() {
    const availTaskList = this.availMachineList.find((machine: any) => machine.machineId == this.selectedMachine)
    this.availTaskList = availTaskList ? availTaskList.taskList : []
  }

  checkFrequency() {
    const selectTaskObj = this.availTaskList.find((task: any) => task.ID == this.selectedTaskId);
    if (selectTaskObj) {
      if (this.taskDetail.Task.Frequency.Name != selectTaskObj.Task.Frequency.Name)
         this.otherTaskList = [];
    }
  }

  tosslePerOne(all: any) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return;
    }
    if (this.otherTasks.length == this.otherTaskList.length)
      this.allSelected.select();

  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.otherTasks = [...this.otherTaskList.map((item: any) => item.ID), 0];
      // this.allSelected.select();
    } else {
      this.otherTasks = [];
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick() {
    if (!this.resheduledDate) {
      this.toaster.warning('Please Select Date');
      return;
    }
    else {
      const date1: any = new Date(this.taskDetail.TaskExpireTime)
      const date2: any = new Date(this.resheduledDate)
      let diffdays: any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));

      const data: any = {
        id: this.taskDetail.ID,
        taskId: this.taskDetail.Task.ID,
        reSheduleId: this.selectedTaskId,
        reScheduledDate: this.resheduledDate,
        otherTasks: this.otherTasks,
        diffDays: diffdays,
        technicianID: this.technicianID
      };
      if (data.reSheduleId) {
        const reschtaskid = this.availTaskList.find((availtask: any) => availtask.ID == data.reSheduleId);
        if (reschtaskid)
          data.reSheduleTaskId = reschtaskid.Task.ID
      }
      this.dialogRef.close(data);
    }
  }

}
