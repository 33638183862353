import { CcrDashboardService } from './../../services/ccr-dashboard.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { NgxSpinnerService } from 'ngx-spinner';

const moment = _moment;
@Component({
  selector: 'app-ccr-dashboard',
  templateUrl: './ccr-dashboard.component.html',
  styleUrls: ['./ccr-dashboard.component.scss'],
})
export class CcrDashboardComponent implements OnInit {
  currentYear = JSON.stringify(new Date().getFullYear());
  dropDownForm!: FormGroup;
  // Drop-down Variables
  yearList = [2015, 2016, 2017, 2018, 2019, 2020, 2021];
  monthList = [
    'ALL',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  weekList = [
    'ALL',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  dayList = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
  ];
  shiftList = ['ALL', 'Morning', 'Evening', 'Night'];
  machineList = ['ALL', 'RTM1', 'RTM2'];
  breadcrumList: any;
  plantList = ['P1', 'P2'];
  canvas: any;
  ctx: any;
  canvas1: any;
  ctx1: any;
  canvas2: any;
  ctx2: any;
  canvas3: any;
  ctx3: any;
  canvasCCRByProduct: any;
  ctx4: any;
  canvasNCRByProduct: any;
  ctx5: any;
  canvas6: any;
  ctx6: any;
  canvas7: any;
  ctx7: any;
  canvas8: any;
  ctx8: any;
  canvas9: any;
  ctx9: any;
  canvas10: any;
  ctx10: any;
  canvas11: any;
  ctx11: any;
  canvas12: any;
  ctx12: any;

  // Date Time Range
  range = new FormGroup({
    startDate: new FormControl(moment()),
    endDate: new FormControl(moment()),
  });

  GroupList: any;
  Plants: any;
  Units: any;
  myChart1: any;
  myChart2: any;
  myChart6: any;
  myChart7: any;
  ccrGapAnalysis: any;
  ncrGapAnalysis: any;
  ccrByProduct: any;
  ncrByProduct: any;
  ccrConcern: any;

  constructor(
    private formBuilder: FormBuilder,
    private userManagementService: UserManagementService,
    private toaster: ToastrService,
    public router: Router,
    private treeService: KpitreeService,
    private ccrDashService: CcrDashboardService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.GetBreadcrumList();

    Chart.defaults.global.legend.labels.usePointStyle = true;
    this.dropDownForm = this.formBuilder.group({
      group: [''],
      plant: [''],
      unit: [''],
      year: [''],
      month: [''],
      week: [''],
      day: [''],
      shift: [''],
    });
    this.dropDownForm.get('year')?.setValue(new Date().getFullYear());
    this.dropDownForm.get('month')?.setValue(this.monthList[0]);
    this.dropDownForm.get('week')?.setValue(this.weekList[0]);
    this.dropDownForm.get('day')?.setValue('ALL');
    this.getGroup();
  }
  // getCcrGapAnalysis(key: any): any {
  //   if (this.ccrGapAnalysis) {
  //     this.ccrGapAnalysis.destroy();
  //   }
  //   this.ccrDashService.getCcrGapAnalysis(key).subscribe((res: any) => {
  //     let total = 0;
  //     res.forEach((a: any) => (total += a.data));
  //     const ccrGapArray: any[] = [];
  //     const labelArr = res.map((value: any) => value.label);
  //     labelArr.push('Total');
  //     const colorArr = res.map((value: any) => value.backgroundColor);
  //     colorArr.push('#040496');
  //     const dataArr = res.map((value: any) => value.data);
  //     dataArr.push(total);
  //     res.forEach((rs: any) => {
  //       let obj = {
  //         label: rs.label,
  //         data: [rs.data],
  //         backgroundColor: '#F85439',
  //       };
  //       ccrGapArray.push(obj);
  //     });
  //     let obj = { label: 'Total', data: [total], backgroundColor: '#040496' };
  //     ccrGapArray.push(obj);

  //     // CCR Gap Analysis Chart
  //     this.canvas = document.getElementById('myChart');
  //     this.canvas.height = 400;
  //     this.canvas.width = 480;
  //     this.ctx = this.canvas.getContext('2d');
  //     this.ccrGapAnalysis = new Chart(this.ctx, {
  //       type: 'bar',
  //       data: {
  //         labels: labelArr,
  //         datasets: [
  //           {
  //             label: 'CCR Gap',
  //             data: dataArr,
  //             backgroundColor: colorArr,
  //             borderWidth: 1,
  //           },
  //         ],
  //       },
  //       options: {
  //         responsive: false,
  //         maintainAspectRatio: false,
  //         display: true,
  //         labelString: 'Percentage',
  //         tooltips: {
  //           callbacks: {
  //             label: (tooltipItem: any, data: any) => {
  //               return data.datasets[0].data[tooltipItem.index] + '';
  //             },
  //           },
  //         },
  //         legend: {
  //           display: false,
  //           position: 'bottom',
  //           labels: {
  //             padding: 20,
  //           },
  //         },
  //         scales: {
  //           xAxes: [
  //             {
  //               stacked: false,
  //               barThickness: 44,
  //               gridLines: {
  //                 display: false,
  //               },
  //             },
  //           ],
  //           yAxes: [
  //             {
  //               stacked: false,
  //               gridLines: {
  //                 borderDash: [8, 4],
  //               },
  //               ticks: {
  //                 gridDashType: 'dash',
  //                 beginAtZero: true,
  //                 stepSize: 10,
  //                 // min: 0,
  //                 // max: 60,
  //               },
  //             },
  //           ],
  //         },
  //       },
  //     });

  //     this.getNCrGapAnalysis(key);
  //   });
  // }
  getNCrGapAnalysis(key: any): void {
    if (this.ncrGapAnalysis) {
      this.ncrGapAnalysis.destroy();
    }
    this.ccrDashService.getNCrGapAnalysis(key).subscribe((res: any) => {
      let total = 0;
      res.forEach((a: any) => (total += a.data));
      const ccrGapArray: any[] = [];
      const labelArr = res.map((value: any) => value.label);
      labelArr.push('Total');
      const colorArr = res.map((value: any) => value.backgroundColor);
      colorArr.push('#040496');
      const dataArr = res.map((value: any) => value.data);
      dataArr.push(total);

      // CCR Gap Analysis Chart
      this.canvas3 = document.getElementById('ncrGapAnalysis');
      this.canvas3.height = 400;
      this.canvas3.width = 480;
      this.ctx3 = this.canvas3.getContext('2d');
      // this.ctx.height = 400;

      this.ncrGapAnalysis = new Chart(this.ctx3, {
        type: 'bar',
        data: {
          labels: labelArr,
          datasets: [
            {
              label: 'NCR Gap',
              data: dataArr,
              backgroundColor: colorArr,
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          display: true,
          labelString: 'Percentage',
          tooltips: {
            callbacks: {
              label: (tooltipItem: any, data: any) => {
                return data.datasets[0].data[tooltipItem.index] + '';
              },
            },
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              padding: 20,
            },
          },
          scales: {
            xAxes: [
              {
                stacked: false,
                barThickness: 44,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: false,
                gridLines: {
                  borderDash: [8, 4],
                },
                ticks: {
                  gridDashType: 'dash',
                  beginAtZero: true,
                  stepSize: 10,
                  // min: 0,
                  // max: 60,
                },
              },
            ],
          },
        },
      });

      // this.getCCrOpenVsClosed(key);
      this.getNcrOpenVsClosed(key);
    });
  }

  // getCCrOpenVsClosed(key: any): void {
  //   if (this.myChart1) {
  //     this.myChart1.destroy();
  //   }
  //   const ccrOpen: any[] = [];
  //   const ccrClosed: any[] = [];
  //   const ccrRejected: any[] = [];
  //   const ccrmonthNo: any[] = [];

  //   this.canvas1 = null;
  //   this.ccrDashService.getCCrOpenVsClosed(key).subscribe((res: any) => {
  //     res.forEach((element: any) => {
  //       ccrOpen.push(element.OpenedCCR);
  //       ccrClosed.push(element.ClosedCCR);
  //       ccrRejected.push(element.RejectedCCR);
  //       ccrmonthNo.push(element.MonthNo);
  //     });
  //     // No of CCR open vs Closed Chart
  //     this.canvas1 = document.getElementById('myChart1');
  //     this.canvas1.height = 400;
  //     this.canvas1.width = 480;
  //     this.ctx1 = this.canvas1.getContext('2d');
  //     // this.ctx.height = 400;
  //     this.myChart1 = new Chart(this.ctx1, {
  //       type: 'bar',
  //       data: {
  //         labels: ccrmonthNo,
  //         datasets: [
  //           {
  //             label: ' Open',
  //             data: ccrOpen,
  //             backgroundColor: 'rgba(4,4,150,1)',
  //             borderWidth: 1,
  //           },
  //           {
  //             label: '  Closed',
  //             data: ccrClosed,
  //             backgroundColor: 'rgba(16,206,156,1)',
  //             borderWidth: 1,
  //           },
  //           {
  //             label: ' Rejected',
  //             data: ccrRejected,
  //             backgroundColor: '#F85439',
  //             borderWidth: 1,
  //           },
  //         ],
  //       },
  //       options: {
  //         responsive: false,
  //         maintainAspectRatio: false,
  //         display: true,
  //         tooltips: {
  //           // custom:function(tooltipModel: { x: number; y: number; }){

  //           //   //Top-Left
  //           //    tooltipModel.x=45;
  //           //    tooltipModel.y=15;

  //           //  }.bind(this),

  //           callbacks: {
  //             label(tooltipItem: any, data: any): any {
  //               return Number(tooltipItem.yLabel) + '';
  //             },
  //           },
  //         },
  //         legend: {
  //           display: true,
  //           position: 'bottom',
  //           labels: {
  //             padding: 20,
  //           },
  //         },
  //         scales: {
  //           xAxes: [
  //             {
  //               gridLines: {
  //                 display: false,
  //               },
  //             },
  //           ],
  //           yAxes: [
  //             {
  //               gridLines: {
  //                 borderDash: [8, 4],
  //               },
  //               ticks: {
  //                 stepSize: 5,
  //                 // min: 0,
  //                 // max: 7,
  //                 beginAtZero: true,
  //               },
  //             },
  //           ],
  //         },
  //       },
  //     });
  //     this.getNcrOpenVsClosed(key);
  //   });
  // }
  getNcrOpenVsClosed(key: any): void {
    const ncrOpen: any[] = [];
    const ncrClosed: any[] = [];
    const ncrRejected: any[] = [];
    const ncrmonthNo: any[] = [];
    this.ccrDashService.getNcrOpenVsClosed(key).subscribe((res: any) => {
      res.forEach((element: any) => {
        ncrOpen.push(element.OpenedNCR);
        ncrClosed.push(element.ClosedNCR);
        ncrRejected.push(element.RejectedNCR);
        ncrmonthNo.push(element.MonthNo);
      });
      // No of NCR open vs Closed Chart
      this.canvas2 = document.getElementById('myChart2');
      this.canvas2.height = 400;
      this.canvas2.width = 480;
      this.ctx2 = this.canvas2.getContext('2d');
      if (this.myChart2) {
        this.myChart2.destroy();
      }
      // this.ctx.height = 400;
      this.myChart2 = new Chart(this.ctx2, {
        type: 'bar',
        data: {
          labels: ncrmonthNo,
          datasets: [
            {
              label: ' Open',
              data: ncrOpen,
              backgroundColor: 'rgba(4,4,150,1)',
              borderWidth: 1,
            },
            {
              label: '  Closed',
              data: ncrClosed,
              backgroundColor: 'rgba(16,206,156,1)',
              borderWidth: 1,
            },
            {
              label: ' Rejected',
              data: ncrRejected,
              backgroundColor: '#F85439',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          display: true,
          tooltips: {
            callbacks: {
              label(tooltipItem: any, data: any): any {
                return Number(tooltipItem.yLabel) + '';
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              padding: 20,
            },
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  borderDash: [8, 4],
                },
                ticks: {
                  stepSize: 5,
                  // min: 0,
                  // max: 25,
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
      // this.getCCrByProduct(key);
      this.getNcrByProduct(key);
    });
  }
  // getCCrByProduct(key: any): void {
  //   if (this.ccrByProduct) {
  //     this.ccrByProduct.destroy();
  //   }
  //   this.ccrDashService.getCCrByProduct(key).subscribe((res: any) => {
  //     const ccrProduct: any[] = [];
  //     const ccrProductData: any[] = [];
  //     const ccrCountByProduct: any[] = [];
  //     res.forEach((element: any) => {
  //       ccrProduct.push(element.label);
  //       ccrProductData.push(element.data);
  //     });
  //     // CCR by product Chart
  //     this.canvasCCRByProduct = document.getElementById('ccrByProduct');
  //     this.ctx4 = this.canvasCCRByProduct.getContext('2d');
  //     this.canvasCCRByProduct.height = 400;
  //     this.canvasCCRByProduct.width = 480;
  //     this.ccrByProduct = new Chart(this.ctx4, {
  //       type: 'bar',
  //       data: {
  //         labels: ccrProduct,
  //         datasets: [
  //           {
  //             label: 'CCR Gap',
  //             data: ccrProductData,
  //             backgroundColor: '#040496',
  //             borderWidth: 1,
  //           },
  //         ],
  //       },
  //       options: {
  //         responsive: false,
  //         maintainAspectRatio: false,
  //         display: true,
  //         labelString: 'Percentage',
  //         tooltips: {
  //           callbacks: {
  //             label: (tooltipItem: any, data: any) => {
  //               return data.datasets[0].data[tooltipItem.index] + '';
  //             },
  //           },
  //         },
  //         legend: {
  //           display: false,
  //           position: 'bottom',
  //           labels: {
  //             padding: 20,
  //           },
  //         },
  //         scales: {
  //           xAxes: [
  //             {
  //               stacked: false,
  //               barThickness: 44,
  //               gridLines: {
  //                 display: false,
  //               },
  //             },
  //           ],
  //           yAxes: [
  //             {
  //               stacked: false,
  //               gridLines: {
  //                 borderDash: [8, 4],
  //               },
  //               ticks: {
  //                 gridDashType: 'dash',
  //                 beginAtZero: true,
  //                 stepSize: 10,
  //                 // min: 0,
  //                 // max: 60,
  //               },
  //             },
  //           ],
  //         },
  //       },
  //     });
  //     this.getNcrByProduct(key);
  //   });
  // }
  getNcrByProduct(key: any): void {
    if (this.ncrByProduct) {
      this.ncrByProduct.destroy();
    }
    this.ccrDashService.getNcrByProduct(key).subscribe((res: any) => {
      const ccrProduct: any[] = [];
      const ccrProductData: any[] = [];
      res.forEach((element: any) => {
        ccrProduct.push(element.label);
        ccrProductData.push(element.data);
      });
      // NCR by product Chart
      this.canvasNCRByProduct = document.getElementById('ncrByProduct');
      this.ctx5 = this.canvasNCRByProduct.getContext('2d');
      this.canvasNCRByProduct.height = 400;
      this.canvasNCRByProduct.width = 480;
      this.ncrByProduct = new Chart(this.ctx5, {
        type: 'bar',
        data: {
          labels: ccrProduct,
          datasets: [
            {
              label: 'CCR Gap',
              data: ccrProductData,
              backgroundColor: '#040496',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          display: true,
          labelString: 'Percentage',
          tooltips: {
            callbacks: {
              label: (tooltipItem: any, data: any) => {
                return data.datasets[0].data[tooltipItem.index] + '';
              },
            },
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              padding: 20,
            },
          },
          scales: {
            xAxes: [
              {
                stacked: false,
                barThickness: 44,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: false,
                gridLines: {
                  borderDash: [8, 4],
                },
                ticks: {
                  gridDashType: 'dash',
                  beginAtZero: true,
                  stepSize: 10,
                  // min: 0,
                  // max: 60,
                },
              },
            ],
          },
        },
      });
      // this.getCCrNumber(key);
      this.getNcrNumber(key);
    });
  }
  // getCCrNumber(key: any): void {
  //   if (this.myChart6) {
  //     this.myChart6.destroy();
  //   }
  //   this.ccrDashService.getCCrNumber(key).subscribe((res: any) => {
  //     const ccrNumberMonth: any[] = [];
  //     const ccrNumberCount: any[] = [];
  //     res.forEach((element: any) => {
  //       ccrNumberMonth.push(element.Month);
  //       ccrNumberCount.push(element.Count);
  //     });
  //     // CCR number Chart
  //     this.canvas6 = document.getElementById('myChart6');
  //     this.ctx6 = this.canvas6.getContext('2d');
  //     this.canvas6.height = 400;
  //     this.canvas6.width = 480;
  //     this.myChart6 = new Chart(this.ctx6, {
  //       type: 'bar',
  //       data: {
  //         labels: ccrNumberMonth,
  //         datasets: [
  //           {
  //             label: ' Open',
  //             data: ccrNumberCount,
  //             backgroundColor: 'rgba(4,4,150,1)',
  //             borderWidth: 1,
  //           },
  //         ],
  //       },
  //       options: {
  //         responsive: false,
  //         maintainAspectRatio: false,
  //         display: true,
  //         tooltips: {
  //           callbacks: {
  //             label(tooltipItem: any, data: any): any {
  //               return Number(tooltipItem.yLabel) + '';
  //             },
  //           },
  //         },
  //         legend: {
  //           display: false,
  //           position: 'bottom',
  //           labels: {
  //             padding: 20,
  //           },
  //         },
  //         scales: {
  //           xAxes: [
  //             {
  //               gridLines: {
  //                 display: false,
  //               },
  //             },
  //           ],
  //           yAxes: [
  //             {
  //               gridLines: {
  //                 borderDash: [8, 4],
  //               },
  //               ticks: {
  //                 stepSize: 5,
  //                 // min: 0,
  //                 // max: 25,
  //                 beginAtZero: true,
  //               },
  //             },
  //           ],
  //         },
  //       },
  //     });
  //     this.getNcrNumber(key);
  //   });
  // }
  getNcrNumber(key: any): void {
    if (this.myChart7) {
      this.myChart7.destroy();
    }
    this.ccrDashService.getNcrNumber(key).subscribe((res: any) => {
      const ncrNumberMonth: any[] = [];
      const ncrNumberCount: any[] = [];
      res.forEach((element: any) => {
        ncrNumberMonth.push(element.Month);
        ncrNumberCount.push(element.Count);
      });
      // NCR number Chart
      this.canvas7 = document.getElementById('myChart7');
      this.ctx7 = this.canvas7.getContext('2d');
      this.canvas7.height = 400;
      this.canvas7.width = 480;
      this.myChart7 = new Chart(this.ctx7, {
        type: 'bar',
        data: {
          labels: ncrNumberMonth,
          datasets: [
            {
              label: ' Open',
              data: ncrNumberCount,
              backgroundColor: 'rgba(4,4,150,1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          display: true,
          tooltips: {
            callbacks: {
              label(tooltipItem: any, data: any): any {
                return Number(tooltipItem.yLabel) + '';
              },
            },
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              padding: 20,
            },
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  borderDash: [8, 4],
                },
                ticks: {
                  stepSize: 5,
                  // min: 0,
                  // max: 25,
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
      // this.getCCrConcern(key);
    });
  }

  // getCCrConcern(key: any): void {
  //   if (this.ccrConcern) {
  //     this.ccrConcern.destroy();
  //   }
  //   const ccrComplaint: any[] = [];
  //   const ccrConcern: any[] = [];
  //   const ccrmonthNo: any[] = [];
  //   this.canvas12 = null;
  //   this.ccrDashService.getCCrConcern(key).subscribe((res: any) => {
  //     res.forEach((element: any) => {
  //       ccrComplaint.push(element.Complaint);
  //       ccrConcern.push(element.Concern);
  //       ccrmonthNo.push(element.MonthNo);
  //     });
  //     // No of CCR open vs Closed Chart
  //     this.canvas12 = document.getElementById('ccrConcern');
  //     this.canvas12.height = 400;
  //     this.canvas12.width = 480;
  //     this.ctx12 = this.canvas12.getContext('2d');
  //     // this.ctx.height = 400;
  //     this.ccrConcern = new Chart(this.ctx12, {
  //       type: 'bar',
  //       data: {
  //         labels: ccrmonthNo,
  //         datasets: [
  //           {
  //             label: ' Complaint',
  //             data: ccrComplaint,
  //             backgroundColor: 'rgba(4,4,150,1)',
  //             borderWidth: 1,
  //           },
  //           {
  //             label: '  Concern',
  //             data: ccrConcern,
  //             backgroundColor: 'rgba(16,206,156,1)',
  //             borderWidth: 1,
  //           },
  //         ],
  //       },
  //       options: {
  //         responsive: false,
  //         maintainAspectRatio: false,
  //         display: true,
  //         tooltips: {
  //           callbacks: {
  //             label(tooltipItem: any, data: any): any {
  //               return Number(tooltipItem.yLabel) + '';
  //             },
  //           },
  //         },
  //         legend: {
  //           display: true,
  //           position: 'bottom',
  //           labels: {
  //             padding: 20,
  //           },
  //         },
  //         scales: {
  //           xAxes: [
  //             {
  //               gridLines: {
  //                 display: false,
  //               },
  //             },
  //           ],
  //           yAxes: [
  //             {
  //               gridLines: {
  //                 borderDash: [8, 4],
  //               },
  //               ticks: {
  //                 stepSize: 5,
  //                 // min: 0,
  //                 // max: 7,
  //                 beginAtZero: true,
  //               },
  //             },
  //           ],
  //         },
  //       },
  //     });
  //     // this.getNcrOpenVsClosed(key);
  //   });
  // }
  getCCRdashboardData(): void {
    if (this.dropDownForm.value.month !== 'ALL') {
      this.dayList = this.getDaysInMonth(
        this.monthList.indexOf(this.dropDownForm.value.month) - 1,
        this.dropDownForm.value.year
      );
    }
    const key = {
      year: this.dropDownForm.value.year,
      month:
        this.dropDownForm.value.month === 'ALL'
          ? ''
          : this.dropDownForm.value.month,
      week:
        this.dropDownForm.value.week === 'ALL'
          ? ''
          : this.dropDownForm.value.week,
      day:
        this.dropDownForm.value.day === 'ALL'
          ? ''
          : this.dropDownForm.value.day,
      shift: this.dropDownForm.value.shift,
      puId: this.dropDownForm.value.unit,
      ptId: this.dropDownForm.value.plant,
    };
    this.spinner.show();
    // this.getCcrGapAnalysis(key);
    this.getNCrGapAnalysis(key);
    this.spinner.hide();
  }
  getGroup(): void {
    const key = {
      Id: 0,
    };
    this.userManagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.masterList;
        this.dropDownForm.get('group')?.setValue(this.GroupList[0].TypeId);
        this.getPlants();
        // this.getLotsCodes();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getPlants(): void {
    const key = {
      GroupId: this.dropDownForm.value.group,
    };
    this.userManagementService.getDepartments(key).subscribe(
      (data: any) => {
        this.Plants = data.DepartList;
        this.dropDownForm.get('plant')?.setValue(this.Plants[0].DeptId);
        this.getCCRdashboardData();
        this.getMachine();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getMachine(): void {
    const key = {
      ptId: this.dropDownForm.value.plant,
    };
    this.treeService.plantUnit(key).subscribe(
      (data: any) => {
        this.Units = data;

        // this.dropDownForm.get('unit')?.setValue(this.Units[0].puId);
        // this.getQualityArea(this.Plants[0].DeptId);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onChangeGroup(data: any): void {
    this.dropDownForm.get('group')?.setValue(data.value);
    this.getPlants();
  }
  onChangePlant(data: any): void {
    this.dropDownForm.get('plant')?.setValue(data.value);
    setTimeout(() => {
      this.getMachine();
    }, 1000);

    this.getCCRdashboardData();
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        console.log(data.BreadcrumList);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  getDaysInMonth(month: any, year: any): any {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date).getDate());
      date.setDate(date.getDate() + 1);
    }
    return days;
  }
}
