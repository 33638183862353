import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { MeetingDefinationService } from 'src/app/services/meeting-defination.service';
import { MeetingmasterService } from 'src/app/services/meetingmaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import Swal from 'sweetalert2';
import { TranslaterService } from 'src/app/services/translater.service';


export interface areadata {
  Id: number;
  areaname: string;
  areaorder:number;
  areapercent:string;
  
}
@Component({
  selector: 'app-meeting-effectiveness-checklist',
  templateUrl: './meeting-effectiveness-checklist.component.html',
  styleUrls: ['./meeting-effectiveness-checklist.component.scss'],
})
export class MeetingEffectivenessChecklistComponent implements OnInit {

  areaColumns:string[]  = ['Action','areaId','areaname','areaorder','areapercent'];
  
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('displayGroupModal') private displayGroupModal!: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  MeetingForm!: FormGroup;
  MeetingAreaForm!: FormGroup;
  MeetingDetailList!: FormArray;
  plantId!: number;
  groupId!: number;
  IsAccess: any;
  IsWrite: any;
  groupListDDL!: any[];
  plantList!: any[];
  AreaList: any[] = [];
  NewArea: any[] = [];
  arealistCore: string[];
  formMode!: boolean;
  Areas !: MatTableDataSource<any>;
  meetingtarget = true;
  itemColumns: string[] = [
    'itemActions',
    'question',
    'areaname',
    'areapercent',
    'areaorder',
  ];
  counter = 1;
  meetingId!: number;
  MeetingData = new MatTableDataSource<any>([]);
  MeetingListDetails!: MatTableDataSource<any>;
  meetingmasterColumns: string[] = [
    'areaActions',
    'id',
    'plant',
    'effectiveness',
  ];
  meetingByIdObject!: any;
  meetingCheckType = '';
  isLoading = false;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  breadcrumList: any;
  formAreaMode: boolean;
  areaId: any;
  displaybtn: boolean;
  
 
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private userService: UserManagementService,
    private toaster: ToastrService,
    private meetingMasterService: MeetingmasterService,
    private platModelService: EnterPriseGroupService,
    private machineService: MachineMappingService,
    private meetingService: MeetingDefinationService,
    public translater: TranslaterService,
  ) {}

  ngOnInit() {
    this.GetBreadcrumList();
    this.formMode = true;
    this.MeetingForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      effectivenessname: ['', Validators.required],
      meetingdetails: this.fb.array([this.createMeetingDetailsFormGroup()]),
    });
    this.MeetingAreaForm = this.fb.group({
      areaname: ['', Validators.required],
      areaorder: ['', Validators.required],
      areapercent: ['', Validators.required],
    });
    this.getGroupData();
    this.GetMenuAccess();
    this.MeetingDetailList = this.MeetingForm.get(
      'meetingdetails'
    ) as FormArray;
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  SaveArea(): void {
    const key = {
      areaname: this.MeetingAreaForm.value.areaname,
      areaorder: this.MeetingAreaForm.value.areaorder,
      areapercent: this.MeetingAreaForm.value.areapercent,
      plantId: this.plantId
    };
     this.AreaList.push(key);
      // this.AreaList.forEach(element => {
      //  element.areaPercent = parseInt(element.areaPercent)
      // })
     let sum = this.AreaList.reduce(function (acc,obj){return acc + obj.areapercent},0)
    // if(sum <= 100){
    //   this.Areas =new MatTableDataSource<any>(this.AreaList);
    // console.log(this.AreaList);
    // this.MeetingAreaForm.reset();
    // this.toaster.success('Success', 'Area added successfully');
      
    // }
    // else {
    //   this.AreaList.pop();
    //   this.toaster.warning('Area percentage can not exceed 100%');
    //   return;
    // }
    if(key.areapercent > 100 || sum > 100)
    {
    this.toaster.warning('area percentage can not exceed 100');
    this.AreaList.pop();
    this.isLoading = false;
    return;
    }
    else
    {

    this.meetingMasterService
    .SaveArea(key).subscribe(
      (response) => {
        if( response[0].message == "Area With Same Name Exist")
        {
          this.toaster.warning('Area Already Exist')
        }
        else{
          this.toaster.success(
            response[0].message
          );

        }
     
      //this.MeetingForm.reset();
      this.MeetingAreaForm.reset();
      this.isLoading = false;
      this.GetArea();
      },
      (error) => {
        this.toaster.error(error.error.message);
      }
    );

    }
    
   
    
  } 
  GetArea(): void {
    const key = {
     PlantId:this.plantId
    }
    this.meetingMasterService.GetArea(key).subscribe(
      (response)=>{

        this.AreaList = response;
          this.AreaList.forEach(element => {
        element.areapercent = parseInt(element.areapercent)
       })
        this.Areas =new MatTableDataSource<any>(this.AreaList);
      }
    )

  }
  UpdateArea():void {

    const key = {
      areaname: this.MeetingAreaForm.value.areaname,
      areaorder: this.MeetingAreaForm.value.areaorder,
      areapercent: parseInt(this.MeetingAreaForm.value.areapercent),
      areaId:  this.areaId 
    };
    const target = this.AreaList.findIndex((obj)=>obj.areaId == key.areaId);
    this.AreaList.splice(target,1); 
     this.AreaList.push(key);
     // this.AreaList.forEach(element => {
     //  element.areaPercent = parseInt(element.areaPercent)
     // })
    let sum = this.AreaList.reduce(function (acc,obj){return acc + obj.areapercent},0)
    if(sum > 100){
      this.toaster.warning('Area Percentage Can Not Exceed 100%');
      this.AreaList.pop();
      this.isLoading = false;
      return;
    }
    this.meetingMasterService.UpdateArea(key).subscribe((response)=>{
      if(response[0].message == 'Area Percentage Can Not Exceed 100%!')
      {
        this.toaster.warning(response[0].message)
      }
      else{
        this.toaster.success(response[0].message);
        this.GetArea();
        this.MeetingAreaForm.reset();
        this.formAreaMode = true

      }
       
    },
    (error) => {
      this.toaster.error(error.error.message);
    }
    );
    //  const target = this.AreaList.findIndex((obj)=>obj.areaname == key.areaname)
    //  if(target !=null || target != undefined)
    //  {
    //   this.AreaList.splice(target,1,key);
    //  }
    
   // this.AreaList.push(key)
    // this.AreaList.forEach(element => {
    //   element.areaPercent = parseInt(element.areaPercent)
    //  })
    // let sum = this.AreaList.reduce(function (acc,obj){return acc + obj.areapercent},0)
    // if(sum <= 100){

    //   this.Areas =new MatTableDataSource<any>(this.AreaList);
    //   this.MeetingListDetails = new MatTableDataSource(
    //     this.MeetingForm.value.meetingdetails
    //   );
      // this.AreaList .forEach(element => {
      //   this.MeetingForm.value.meetingdetails.forEach(elem => {
      //     // if(elem.areaname == element.areaname)
      //     // {
      //     //   elem.areaname = element.areaname,
      //     //   elem.areaorder = element.areaorder,
      //     //   elem.areapercent = element.areapercent
      //     // }
      //   })
      // });
      // for(var i = 0; )
    // console.log(this.AreaList);

    // this.MeetingAreaForm.reset();
    // this.toaster.success('Success', 'Area Update successfully');
    // this.formAreaMode = true
    
      
    // }
    // else {
    //  // this.AreaList.splice(target,1)
    //  // this.AreaList.pop();
    //   this.toaster.warning('Area percentage can not exceed 100%');
    //   return;
    // }
    

  }
  getPaginator(): void {
    setTimeout(() => {
      this.MeetingData.paginator = this.paginator;
      this.MeetingData.sort = this.sort;
    }, 1000);
  }

  // create dynamic table row

  createMeetingDetailsFormGroup(): FormGroup {
    return this.fb.group({
      question: [''],
      areaname: [''],
      areaorder: [''],
      areapercent: [''],
    });
  }

  addChecklistRow(): void {
    const checkdetailArray = this.MeetingForm.value.meetingdetails;
    const question = checkdetailArray?.find(
      (x: { question: string }) => x.question === ''
    );
    const areaorder = checkdetailArray?.find(
      (x: { areaorder: string }) => x.areaorder === ''
    );
    const areapercent = checkdetailArray?.find(
      (x: { areapercent: string }) => x.areapercent === ''
    );
    const areaname = checkdetailArray?.find(
      (x: { areaname: string }) => x.areaname === ''
    );
    if (question || areaname || areaorder || areapercent) {
      this.toaster.warning('Please Fill Empty fields');
    } else {
      this.MeetingDetailList.push(this.createMeetingDetailsFormGroup());
      this.MeetingListDetails = new MatTableDataSource(
        this.MeetingForm.value.meetingdetails
      );
    }
  }
  deleteRow(element: any, index: number): void {
    this.MeetingDetailList.removeAt(index);
    this.MeetingListDetails = new MatTableDataSource(
      this.MeetingDetailList.controls
    );
  }
  deleteArea(element: any, index: number): void {
 
    const key = {
                 AreaId: element.areaId
                };
      this.meetingMasterService.DeleteArea(key).subscribe((response)=>{
        this.toaster.success(response[0].message)
        this.GetArea();
      })

      // this.AreaList.splice(index, 1);
      // this.Areas =new MatTableDataSource<any>(this.AreaList);
    
  }
  EditArea(element:any,index:number):void{
    this.formAreaMode = false
    this.MeetingAreaForm.get('areaname').setValue(element.areaname)
    this.MeetingAreaForm.get('areaorder').setValue(element.areaorder)
    this.MeetingAreaForm.get('areapercent').setValue(element.areapercent)
    this.areaId = element.areaId
  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.MeetingData.filter = filterValue.trim().toLowerCase();
    if (this.MeetingData.paginator) {
      this.MeetingData.paginator.firstPage();
    }
  }
  getMeetingMaster(key: any): void {
    this.groupId = key.groupId;
    this.plantId = key.plantId;
    const keyplant = {
      plantId: this.plantId,
    };
    this.meetingMasterService
      .GetMeetingEffectivenessChecklist_Master(keyplant)
      .subscribe(
        (data) => {
          if(data.length > 0){
            this.displaybtn = false
          }
          else{
            this.displaybtn = true
          }
          this.MeetingData.data = data;
          this.getPaginator();
        },
        (error) => {
          this.toaster.error(error.error.message);
        }
      );
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.meetingService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.MeetingForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  EditMeetingMaster(meetingId: number, displayRoleModal: any): void {
    this.resetForm();
    this.meetingId = meetingId;
    this.getGroupData();
    this.getMeetingMasterById(meetingId);
    this.dialog.open(displayRoleModal, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: 'auto',
      minWidth: '95vw',
      maxHeight: '95vh',
      disableClose: true,
    });
    this.formMode = false;
  }
  CheckAreaType(event: any, index: any): void {
    const TableareaList = this.AreaList.find(
      (data: any) => data.areaname == event.value
    );

    const areaOrder = TableareaList.areaorder;
    const areaPercent = TableareaList.areapercent;
    this.MeetingForm.controls.meetingdetails['controls'][
      index
    ].controls.areaorder.setValue(areaOrder);
    this.MeetingForm.controls.meetingdetails['controls'][
      index
    ].controls.areapercent.setValue(areaPercent);
  }
  getMeetingMasterById(meetingId: number): void {
    this.getGroupData();
    this.getPlantByGroup();
    const meeting = {
      MEC_Id: meetingId,
    };
    this.meetingMasterService
      .GetMeetingEffectivenessChecklist_MasterById(meeting)
      .subscribe(
        (response: any) => {
          this.meetingByIdObject = response;

          this.MeetingForm.get('plant')?.setValue(response.plantId);
          this.MeetingForm.get('effectivenessname')?.setValue(response.name);
         this.GetArea();
          const arealist: any[] = response.meetingEffectivenessDetails.map(
            (item: any) => ({
              question: item.question,
              areaname: item.areaname,
              areaorder: item.areaOrder,
              areapercent: item.areaPercentage,
            })
          );

          const meetingdetailArray: any[] = [];
          this.MeetingDetailList.clear();
          response.meetingEffectivenessDetails?.forEach((element: any) => {
            meetingdetailArray.push({
              question: element?.question,
              areaname: element?.areaname,
              areaorder: element?.areaOrder,
              areapercent: element?.areaPercentage,
            });
            this.MeetingDetailList.push(this.createMeetingDetailsFormGroup());
          });
          this.MeetingForm.get('meetingdetails')?.setValue(meetingdetailArray);
          //this.AreaList = [...new Set(arealist)];
          let arr = [...new Set(arealist)];
        
          // let temarr = arr.map(item => item.areaname)
          // .filter((value, index, self) => self.indexOf(value) === index)

          // let filteredData = arr.filter(
          //   (value, index, self) =>
          //     self.findIndex(
          //       (v) =>
          //         v.areaname === value.areaname && v.areapercent != 0
          //     ) === index
          // );
          
          // filteredData.forEach(element => {
          //   let obj = {
          //     areaname:element?.areaname,
          //     areaorder: element?.areaorder,
          //     areapercent:element?.areapercent
          //   }
            
              // this.AreaList.push()
              // this.Areas =new MatTableDataSource<any>(this.AreaList);
            
            
           
           
         //  });
          
         
          
           

          this.MeetingListDetails = new MatTableDataSource<any>(
            this.MeetingForm.value.meetingdetails
          );
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
        }
      );
  }

  onClickDeleteMeetingMaster(meetingId: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Meeting Effectiveness Checklist!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const id = {
          MEC_Id: meetingId,
         
        };
        this.meetingMasterService
          .DeleteMeetingEffectivenessChecklist(id)
          .subscribe(
            (data: any) => {
              const key = {
                plantId: this.plantId,
                groupId: this.groupId,
              };
              this.getMeetingMaster(key);
              this.toaster.success(
                'Meeting Effectiveness Checklist Deleted Successfully'
              );
            },
            (error: any) => {
              this.toaster.error('Error', error.error.message);
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Meeting Effectiveness Checklist is safe :)',
          'error'
        );
      }
    });
  }
  resetForm(): void {
    this.AreaList = [];
    this.MeetingListDetails = new MatTableDataSource<any>(
      this.MeetingDetailList.controls
    );
    this.MeetingForm.reset();
  }

  addCheckList(): any {
   
    this.isLoading = true;
    const checklistarray = this.MeetingForm.value.meetingdetails;
    if (checklistarray.length > 0) {
      const name = checklistarray.find(
        (x: { question: string }) => x.question === ''
      );
      const areaName = checklistarray.find(
        (x: { areaName: string }) => x.areaName === ''
      );
      const areaOrder = checklistarray.find(
        (x: { areaOrder: string }) => x.areaOrder === ''
      );
      const areapercent = checklistarray.find(
        (x: { areapercent: string }) => x.areapercent === ''
      );
      if (name || areaName || areaOrder || areapercent) {
        this.toaster.warning('Please Fill Or Remove Empty Fields');
        this.isLoading = false;
        return;
      }
    } else {
      this.toaster.warning('Fill Data Correctly');
      this.isLoading = false;
      return;
    }
    const meeting = {
      userId: JSON.parse(localStorage.user).UserId,
      name: this.MeetingForm.value.effectivenessname,
      plantId: this.MeetingForm.value.plant,
      meetingEffectivenessDetails: checklistarray?.map(
        (item: {
          question: any;
          areaname: any;
          areaorder: any;
          areapercent: any;
          userId: any;
        }) => {
          return {
            question: item?.question,
            areaname: item.areaname,
            areaOrder: item.areaorder,
            areaPercentage: item.areapercent,
            userId: JSON.parse(localStorage.user).UserId,
          };
        }
      ),
    };
    this.meetingMasterService
      .SaveMeetingEffectivenessChecklist(meeting)
      .subscribe(
        (response: any) => {
          if(response[0].Message =='Only One Checklist Can Be Created Against This Plant')
          {
            this.toaster.warning(response[0].Message);
          }
          else if (response.message == 'Weight must be equal to target') {
            this.toaster.warning(response.message);
          } else {
            this.toaster.success(response[0].Message);
          }

          const key = {
            plantId: this.plantId,
            groupId: this.groupId,
          };
          this.getMeetingMaster(key);
          this.MeetingDetailList.clear();
          this.dialog.closeAll();
          this.resetForm();
          this.NewArea = []
          this.Areas =new MatTableDataSource<any>(this.NewArea);

          this.isLoading = false;
        },
        ({ error }: any) => {
          this.toaster.error(error.message);
          this.isLoading = false;
        }
      );
  }
  updateCheckList(): any {
    this.isLoading = true;
    const checklistArray = this.MeetingForm.value.meetingdetails;
    if (checklistArray.length > 0) {
      const question = checklistArray.find(
        (x: { question: string }) => x.question === ''
      );
      const areaname = checklistArray.find(
        (x: { areaname: string }) => x.areaname === ''
      );
      const areaorder = checklistArray.find(
        (x: { areaorder: string }) => x.areaorder === ''
      );
      const areapercent = checklistArray.find(
        (x: { areapercent: string }) => x.areapercent === ''
      );
      if (question || areaname) {
        this.toaster.warning('Please Fill Or Remove Empty Fields');
        return;
      }
    } else {
      this.toaster.warning('Fill Data Correctly');
      return;
    }
    checklistArray.forEach(element =>{

      element.areaPercent = parseInt(element.areapercent)
      
    })
  
     const meeting = {
      MEC_Id: this.meetingId,
      userId: JSON.parse(localStorage.user).UserId,
      name: this.MeetingForm.value.effectivenessname,
      plantId: this.MeetingForm.value.plant,
      groupId: this.MeetingForm.value.group,
      meetingEffectivenessDetails: checklistArray.map(
        (item: {
          question: any;
          areaname: any;
          areaorder: any;
          areapercent: any;
        }) => {
          return {
            question: item?.question,
            areaname: item?.areaname,
            areaOrder: item?.areaorder,
            areaPercentage: item?.areapercent,
          };
        }
      ),
      }
    this.meetingMasterService
      .UpdateMeetingEffectivenessChecklist(meeting)
      .subscribe(
        (response: any) => {
          this.toaster.success('Meeting Updated Successfuly');
          const key = {
            plantId: this.plantId,
            groupId: this.groupId,
          };
          this.getMeetingMaster(key);
          this.MeetingDetailList.clear();
          this.dialog.closeAll();
          this.resetForm();
          this.isLoading = false;
        },
        ({ error }: any) => {
          this.toaster.error('Error', error.message);
          this.isLoading = false;
        }
      );
    
    
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.groupId,
    };

    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plantList = data.DepartList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  addGroupModal(): void {
    this.formAreaMode = true;
    
    this.dialog.open(this.displayGroupModal, { height: 'Auto', width: '60%' });
    this.MeetingAreaForm.reset();
  }

  setDataInitially(): void {
    const arealist: string[] = this.meetingByIdObject?.meetingDetails?.map(
      (item: any) => item.areaname
    );
    const meetingdetailArray: any[] = [];
    this.MeetingDetailList.clear();
    this.meetingByIdObject?.meetingDetails?.forEach((element: any) => {
      meetingdetailArray.push({
        question: element.question,
        areaname: element.areaname,
        areaorder: element.areaorder,
        areapercentage: element.areapercent,
      });
      this.MeetingDetailList.push(this.createMeetingDetailsFormGroup());
    });
    this.MeetingForm.get('meetingdetails')?.setValue(meetingdetailArray);
    this.AreaList = [...new Set(arealist)];
    this.MeetingListDetails = new MatTableDataSource<any>(
      this.MeetingForm.value.meetingdetails
    );
  }

  openModal(displayRoleModal: any): void {
    this.NewArea = []
    this.Areas =new MatTableDataSource<any>(this.NewArea);
    this.getGroupData();
    this.resetForm();
    this.MeetingDetailList.clear();
    this.addChecklistRow();
    this.MeetingForm.get('group')?.setValue(this.groupId);
    this.getPlantByGroup();
    this.MeetingForm.get('plant')?.setValue(this.plantId);
    this.formMode = true;
    this.GetArea()
    this.dialog.open(this.displayRoleModal, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: 'auto',
      minWidth: '95vw',
      maxHeight: '95vh',
      disableClose: true,
    });
  }
}
