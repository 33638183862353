import { Component, OnDestroy } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'btn-download',
    template:
        `<style>
        // .down_icon{
        //     color: white;
        //     background-color: #0f0fd1;
        //     padding: 10px 12px;
        //     border-radius: 20px;
        //     cursor: pointer;
        // }
    </style>
    <a [href]="params.onClick+params.data.FileDoc" class="down_icon" target="_self" download>
    <img [src]="params.onClick+params.data.FileDoc" width="60" height="45" alt="pdf">
    </a>`
})
// tslint:disable-next-line:component-class-suffix
export class BtnDownload implements ICellRendererAngularComp, OnDestroy {
    public params: any;
    // public fileExist:boolean = this.params.data.FileDoc ? true : false;
    refresh(params: any): boolean {
        throw new Error('Method not implemented.');
    }

    downloadFile(){
        const link = document.createElement('a');
        link.href = this.params.onClick+this.params.data.FileDoc;
        link.download = 'file';
        link.target = '_blank';
        link.click();
    }   


    agInit(params: any): void {
        this.params = params;
    }

    onClick($event: any): any {

        if (this.params.onClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowData: this.params.node.data,
                index: this.params.node.rowIndex
                // ...something
            };
            this.params.onClick(params);
        }
    }
    ngOnDestroy(): any {
        // no need to remove the button click handler
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}
