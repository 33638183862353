import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductivityService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getOEE(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Dashboard/GetOEEDashboard', data);
  }

  getMaintenance(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Dashboard/GetMaintenanceDashboard', data);
  }

  getProductionQualityData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Dashboard/GetOEEDashboard', data);
  }

  getProductionShiftData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'SDFC/GetShiftDetailsByUnit', data);
  }
  
  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetEnterpriseList', data);
  }

  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetPlantList', data);
  }

  getLineData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetLineList', data);
  }

  getLineByDeptData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetLineListByDept', data);
  }

  getUnitData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetUnitList', data);
  }

  getComponentData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetComponentList', data);
  }

  getUOMData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Planner/GetUOM', data);
  }

  submitProductionPlan(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Planner/SubmitProductionPlan', data);
  }

  getProductionPlanDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Planner/GetProductionPlanDetail', data);
  }

  getProducts(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Planner/GetProducts', data);
  }

  submitProductionPlanDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Planner/SubmitProductionPlanDetail', data);
  }

  getOEEANDAPQ(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Dashboard/GetDailyOEEANDAPQDashboard', data);
  }

  //======================== Downtime Pareto Dashboard===============================================//
  getDTParetoDashboard(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'ParetoAnalysis/GetParetoDashboard', data);
  }
  getFaultList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'ParetoAnalysis/GetFaultDurationDetails', data);
  }
}