<div class="machine-parameter-content">
    <div class="content-top-box ">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="displayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
      </ul>
      <div class="tab-content-top-box">
        <h1 class="main-title">Chemical Data Entry </h1>
      </div>
    </div>

    <!--Selection Form For Load Entries-->
    <form class="mb-2" [formGroup]="chemicalLabForm">
      <div class="wrapper-box wrapper-box-shadow grid-container">
        <h4 class="main-title">Materials for Analysis </h4>
        <div class="content-top-row" style="margin-left: -24px;">
          
          <!--DateTime Selection-->
          <div class="inputField">
            <nz-date-picker class="calender-input" id="picker" formControlName="dateTime"
              [nzDisabledDate]="" nzFormat="yyyy/MM/dd HH:mm"
              [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true"></nz-date-picker>
            <span class="floating-text">Select DateTime</span>
          </div>

          <!--Material Selection-->
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 15%;">
            <mat-label>Select Material</mat-label>
            <mat-select formControlName="materials" (selectionChange)="setMaterialOnChange($event.value)" panelClass="testClass" name="Group">
              <mat-option *ngFor="let material of materialList" [value]="material.TypeId">
                {{material.TypeName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!--Load / Reports buttons-->
          <!-- <button type="button" mat-flat-button color="accent" [disabled]="chemicalLabForm.invalid" matBadgeColor="accent" disableRipple pTooltip="Load"
            tooltipPosition="top" style="margin-left: 20px;" (click)="getChemicalMaterialProperties()">
            Load
          </button>
          <button type="button" mat-flat-button color="accent" 
          [disabled]="chemicalLabForm.invalid" matBadgeColor="accent" 
          disableRipple pTooltip="Get Report"
            tooltipPosition="top" style="margin-left: 20px;" (click)="getReport()">
            <i class="fa fa-file" aria-hidden="true" style="color: #fff"></i>
            Get Report
          </button> -->

          <button type="button" [disabled]="chemicalLabForm.invalid" (click)="getChemicalMaterialProperties()" 
          mat-button mat-mini-fab color="primary" 
          pTooltip="Load" tooltipPosition="top" style="margin-left: 10px;">
          <mat-icon>sync</mat-icon>
          </button>
          <button type="button" mat-button mat-mini-fab color="accent" 
          (click)="getReport()"
          pTooltip="Get Report" tooltipPosition="top" style="margin-left: 10px;">
          <mat-icon>print</mat-icon>
          </button>
        </div>  
      </div>
  
    </form>
    <hr>
    <div *ngIf="!isReportSwitch">
      <form *ngIf="isLoaded" class="mt-2" [formGroup]="chemicalDataForm">
          <div class="wrapper-box wrapper-box-shadow grid-container">
              <!--Supplier Details-->
              <div *ngIf="supplierDetails?.length">
                  <h4 class="main-title mrgn-top">Supplier Details</h4>
                  <div class="content-row-wrap mrgn-btm">
                      <!--Field Details-->
                      <mat-form-field *ngFor="let supplier of supplierDetails" class="top-row-first-filed-item top-row-first-filed-item-order-processing p-col-3 p-lg-3 p-md-4 p-sm-6">
                          <mat-label>{{supplier.Parameter}}</mat-label>
                          <input matInput type="number" *ngIf="supplier.Parameter === 'Truck No.' || supplier.Parameter === 'Delivery No.' || supplier.Parameter === 'Container No.' ? true : false" placeholder="Enter Value" formControlName="{{supplier.Parameter}}">
                          <input matInput type="text" *ngIf="supplier.Parameter !== 'Truck No.' && supplier.Parameter !== 'Delivery No.' &&  supplier.Parameter !== 'Container No.' ? true : false" placeholder="Enter Value" formControlName="{{supplier.Parameter}}">
                      </mat-form-field>
                  </div>
              </div>
  
              <!--Oxide Content-->
              <div *ngIf="oxideDetails?.length">
                  <h4 class="main-title">Oxide Content</h4>
                  <div class="content-row-wrap mrgn-btm">
                      <mat-form-field *ngFor="let oxide of oxideDetails" class="top-row-first-filed-item top-row-first-filed-item-order-processing p-col-3 p-lg-3 p-md-4 p-sm-6">
                          <mat-label *ngIf="oxide.Target">{{oxide.Parameter + ' | ' + oxide?.Target}} </mat-label>
                          <mat-label *ngIf="!oxide.Target">{{oxide.Parameter}} </mat-label>
                          <input matInput type="text" formControlName="{{oxide.Parameter}}">
                      </mat-form-field>
                  </div>
              </div>
  
              <!--Sample Details-->
              <div *ngIf="sampleDetails?.length">
                  <h4 class="main-title mrgn-top">Sample Details</h4>
                  <div class="content-row-wrap mrgn-btm">
                      <mat-form-field *ngFor="let sample of sampleDetails" class="top-row-first-filed-item top-row-first-filed-item-order-processing p-col-3 p-lg-3 p-md-4 p-sm-6">
                          <mat-label *ngIf="sample.Target">{{sample.Parameter + ' | ' + sample?.Target}} </mat-label>
                          <mat-label *ngIf="!sample.Target">{{sample.Parameter}} </mat-label>
                          <input matInput type="text" placeholder="Enter Value" formControlName="{{sample.Parameter}}">
                      </mat-form-field>
                  </div>
              </div>
  
              <!--Glass Redox Calculations-->
              <div *ngIf="gRedoxDetails?.length">
                  <h4 class="main-title mrgn-top">Glass Redox Calculation</h4>
                  <div class="content-row-wrap mrgn-btm">
                      <mat-form-field *ngFor="let gRedox of gRedoxDetails" class="top-row-first-filed-item top-row-first-filed-item-order-processing p-col-3 p-lg-3 p-md-4 p-sm-6">
                          <mat-label *ngIf="gRedox.Target">{{gRedox.Parameter + ' | ' + gRedox?.Target}} </mat-label>
                          <mat-label *ngIf="!gRedox.Target">{{gRedox.Parameter}} </mat-label>
                          <input matInput type="text" placeholder="Enter Value" formControlName="{{gRedox.Parameter}}">
                      </mat-form-field>
                  </div>
              </div>
  
              <!--Batch Redox-->
              <div *ngIf="bRedoxDetails?.length">
                  <h4 class="main-title mrgn-top">Batch Redox</h4>
                  <div class="content-row-wrap mrgn-btm">
                      <mat-form-field *ngFor="let bRedox of bRedoxDetails" class="p-col-3 p-lg-3 p-md-4 p-sm-6 top-row-first-filed-item top-row-first-filed-item-order-processing">
                          <mat-label *ngIf="bRedox.Target">{{bRedox.Parameter + ' | ' + bRedox?.Target}} </mat-label>
                          <mat-label *ngIf="!bRedox.Target">{{bRedox.Parameter}} </mat-label>
                          <input matInput type="text" placeholder="Enter Value" formControlName="{{bRedox.Parameter}}">
                      </mat-form-field>
                  </div>
              </div>
  
              <!--Tim Count Measurement "CPS"-->
              <div *ngIf="tcmDetails?.length">
                  <h4 class="main-title mrgn-top">Tim Count Measurement "CPS" </h4>
                  <div class="content-row-wrap mrgn-btm">
                      <mat-form-field *ngFor="let tcm of tcmDetails; index as i" class="p-col-3 p-lg-3 p-md-4 p-sm-6 top-row-first-filed-item top-row-first-filed-item-order-processing">
                          <mat-label *ngIf="tcm.Target">{{tcm.Parameter + ' | ' + tcm?.Target}} </mat-label>
                          <mat-label *ngIf="!tcm.Target">{{tcm.Parameter}} </mat-label>
                          <input matInput type="text" placeholder="Enter Value" formControlName="{{tcm.Parameter + '-' + i}}">
                      </mat-form-field>
                  </div>
              </div>
  
              <!--Grain Size Analysis-->
              <div *ngIf="grainDetails?.length">
                  <h4 class="main-title">Grain Size Analysis </h4>
                  <div class="content-row-wrap mrgn-btm">
                      <mat-form-field *ngFor="let grain of grainDetails" class="p-col-3 p-lg-3 p-md-4 p-sm-6 top-row-first-filed-item top-row-first-filed-item-order-processing">
                          <mat-label *ngIf="grain.Target">{{grain.Parameter + ' | ' + grain?.Target}} </mat-label>
                          <mat-label *ngIf="!grain.Target">{{grain.Parameter}} </mat-label>
                          <input matInput type="text" placeholder="Enter Value" formControlName="{{grain.Parameter}}">
                      </mat-form-field>
                  </div>
              </div>
  
              <!--Physical Inspection-->
              <div *ngIf="physicalInspectionDetails?.length">
                  <h4 class="main-title">Physical Inspection </h4>
                  <div class="content-row-wrap mrgn-btm">
                      <mat-form-field *ngFor="let phyInspection of physicalInspectionDetails" class="p-col-3 p-lg-3 p-md-4 p-sm-6 top-row-first-filed-item top-row-first-filed-item-order-processing">
                          <mat-label *ngIf="phyInspection.Target">{{phyInspection.Parameter + ' | ' + phyInspection?.Target}} </mat-label>
                          <mat-label *ngIf="!phyInspection.Target">{{phyInspection.Parameter}} </mat-label>
                          <input matInput type="text" placeholder="Enter Value" formControlName="{{phyInspection.Parameter}}">
                      </mat-form-field>
                  </div>
              </div>
  
              <!--Physical Analysis-->
              <div *ngIf="physicalDetails?.length">
                <h4 class="main-title">Physical Analysis </h4>
                <div class="content-row-wrap mrgn-btm">
                    <mat-form-field *ngFor="let physical of physicalDetails" class="p-col-3 p-lg-3 p-md-4 p-sm-6 top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label *ngIf="physical.Target">{{physical.Parameter + ' | ' + physical?.Target}} </mat-label>
                        <mat-label *ngIf="!physical.Target">{{physical.Parameter}} </mat-label>
                        <input matInput type="text" placeholder="Enter Value" formControlName="{{physical.Parameter}}">
                    </mat-form-field>
                </div>
              </div>
  
               <!--Chemical Analysis-->
               <div *ngIf="chemicalDetails?.length">
                <h4 class="main-title">Chemical Analysis </h4>
                <div class="content-row-wrap mrgn-btm">
                    <mat-form-field *ngFor="let chemical of chemicalDetails" class="p-col-3 p-lg-3 p-md-4 p-sm-6 top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label *ngIf="chemical.Target">{{chemical.Parameter + ' | ' + chemical?.Target}} </mat-label>
                        <mat-label *ngIf="!chemical.Target">{{chemical.Parameter}} </mat-label>
                        <input matInput type="text" placeholder="Enter Value" formControlName="{{chemical.Parameter}}">
                    </mat-form-field>
                </div>
              </div>
              
              <!--Grain Size Analysis-->
              <div *ngIf="grainDistDetails?.length">
                <h4 class="main-title">Grain Size Distribution </h4>
                <div class="content-row-wrap mrgn-btm">
                    <mat-form-field *ngFor="let grainDist of grainDistDetails" class="p-col-3 p-lg-3 p-md-4 p-sm-6 top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label *ngIf="grainDist.Target">{{grainDist.Parameter + ' | ' + grainDist?.Target}} </mat-label>
                        <mat-label *ngIf="!grainDist.Target">{{grainDist.Parameter}} </mat-label>
                        <input matInput type="text" placeholder="Enter Value" formControlName="{{grainDist.Parameter}}">
                    </mat-form-field>
                </div>
              </div>
  
              <!--Chemical Composition-->
              <div *ngIf="chemicalCompDetails?.length">
                  <h4 class="main-title">Chemical Composition </h4>
                  <div class="content-row-wrap mrgn-btm">
                      <mat-form-field *ngFor="let chemComp of chemicalCompDetails" class="p-col-3 p-lg-3 p-md-4 p-sm-6 top-row-first-filed-item top-row-first-filed-item-order-processing">
                          <mat-label *ngIf="chemComp.Target">{{chemComp.Parameter + ' | ' + chemComp?.Target}} </mat-label>
                          <mat-label *ngIf="!chemComp.Target">{{chemComp.Parameter}} </mat-label>
                          <input matInput type="text" placeholder="Enter Value" formControlName="{{chemComp.Parameter}}">
                      </mat-form-field>
                  </div>
              </div>
              
              <!--Material Entry-->
              <h4 class="main-title mrgn-top">Material Entry </h4>
              <div class="content-row-wrap mrgn-btm">
                  <mat-form-field *ngFor="let material of matEntryDetails" [style.display]="material.Parameter === 'Date'? 'none' : 'block'" class="p-col-3 p-lg-3 p-md-4 p-sm-6 p-sm-6 top-row-first-filed-item top-row-first-filed-item-order-processing">
                      <mat-label class="placeholder-title">{{material.Parameter}}</mat-label>
                      <input matInput type="text" placeholder="Enter Value" formControlName="{{material.Parameter}}">
                  </mat-form-field>
              </div>
  
              <!--Save / Update / Add / Delete buttons-->
              <button mat-mini-fab mat-button color="primary" type="submit" (click)="saveForm()" pTooltip="Save" tooltipPosition="top" style="margin-left: 20px;" *ngIf="!isUpdated">
                  <mat-icon>save</mat-icon>
              </button>
              <button class="mrgn-left" mat-mini-fab mat-button color="primary" type="submit" (click)="saveForm()" pTooltip="Update" tooltipPosition="top" style="margin-left: 20px;" *ngIf="isUpdated">
                  <mat-icon>edit</mat-icon>
              </button>
              <button class="mrgn-left" type="button" mat-mini-fab mat-button color="accent" matTooltip="Reset Form" (click)="resetForm()" matTooltipClass="custom-tooltip" matTooltipPosition="above">
                  <mat-icon>refresh</mat-icon>
              </button>
              <button color="primary" class="mrgn-left" type="button" mat-mini-fab mat-button matTooltip="History" (click)="openHistoryModal()" matTooltipClass="custom-tooltip" matTooltipPosition="above">
                  <mat-icon>history</mat-icon>
              </button>
  
          </div>
      </form>  
      <!--Recent History Table-->
      <div *ngIf="isLoaded" class="wrapper-box wrapper-box-shadow grid-container">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
        <div style="overflow-x: scroll;">
          <table mat-table [dataSource]="historyDataSource" class="basic-table plant-model-table">              
            <!-- All Columns -->
            <ng-container [matColumnDef]="headerName" *ngFor="let headerName of historyDataHeaders">
              
              <!--All Cells except Actions Column-->
              <div *ngIf="headerName !== 'Actions'">
                <th mat-header-cell *matHeaderCellDef><p class="header-text"> {{headerName}} </p></th>
                <td mat-cell *matCellDef="let element"> {{element[headerName]}} </td>
              </div>
  
              <!--Actions Column-->
              <div *ngIf="headerName === 'Actions'">
                <th mat-header-cell *matHeaderCellDef>
                  <p class="header-text">{{headerName}}</p>
                </th>
                <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                  <div class="row-action-btns" style="min-width: 80px;">
                    <button mat-icon-button class="edit-btn" aria-label="Edit Row"   (click)="getSelectedMaterialDataForEdit(element)" pTooltip="Edit" tooltipPosition="top">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                          fill="white" />
                      </svg>
                    </button>
                    <button mat-icon-button class="tree-view-btn" aria-label="View Row Tree" (click)="deleteMaterialData(element)" pTooltip="Delete" tooltipPosition="top" pTooltip="Delete" tooltipPosition="top">
                      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                          fill="#F85439" />
                        <circle cx="17" cy="17" r="14" fill="#F85439" />
                        <path
                          d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                          fill="white" />
                      </svg>
                    </button>
                  </div>
                </td>
              </div>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="historyDataHeaders"></tr>
            <tr mat-row *matRowDef="let row; columns: historyDataHeaders;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="isReportSwitch">
      <div class="d-flex justify-content-between items-center" style="margin: 20px 0 20px 0;">
          <button mat-button type="button" mat-raised-button 
          color="accent" (click)="exportToExcel()" style=" float: right;"><span style="color: white; font-size: 16px;">Excel Export</span></button>
      </div>
      <mat-divider></mat-divider>
      <div class="wrapper-box wrapper-box-shadow grid-container" >
        <div class="d-flex justify-content-between items-center">
          <h2 style="font-weight: bold;">Chemical Quality Lab - {{reportType}} Report</h2>
          <h4 style="font-weight: bold;">{{today | date:'MM/dd/yyyy, H:mm:ss'}}</h4>
        </div>
        <mat-divider></mat-divider>
        <!-- <div class="d-flex align-items-center" style="background: #0f0fd1;">
          <div class="p-col-10">
            <h1 style=" color:white">Chemical Quality Lab - {{reportType}} Report</h1>
          </div>
          <div class="p-col-2">
           <h3 style=" color:white">{{today | date:'MM/dd/yy, H:mm:ss'}}</h3>
          </div>
        </div> -->
        <div *ngFor="let repData of reportData;">
            <button mat-button type="button" mat-raised-button 
            style="margin: 20px 0;"
            color="accent">{{repData?.type}}</button>
            <table mat-table [dataSource]="repData?.data" class="basic-table plant-model-table">              
              <!-- All Columns -->
              <ng-container [matColumnDef]="headerName" *ngFor="let headerName of repData?.headers">
                
                <!--All Cells except Actions Column-->
                <div>
                  <th mat-header-cell *matHeaderCellDef><p class="header-text"> {{headerName}} </p></th>
                  <td mat-cell *matCellDef="let element"> {{element[headerName]}} </td>
                </div>
              </ng-container>
    
              <tr mat-header-row *matHeaderRowDef="repData?.headers"></tr>
              <tr mat-row *matRowDef="let row; columns: repData?.headers;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching</td>
              </tr>
            </table>
        </div>
      </div>
    </div>
</div>

<!--History PopUp Modal-->
<ng-template #chemicalHistoryModal>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{'Chemical Lab History'}}</h2>

          <button mat-mini-fab mat-button (click)="closeModal()" color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
    </div>
    <mat-dialog-content>
        <div class="wrapper-box wrapper-box-shadow grid-container">
            <div *ngIf="isLoaded" class="wrapper-box wrapper-box-shadow grid-container">
                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
                <div style="overflow-x: scroll;">
                  <table mat-table [dataSource]="historyDataSource" class="basic-table plant-model-table">              
                    <!-- All Columns -->
                    <ng-container [matColumnDef]="headerName" *ngFor="let headerName of historyDataHeaders">
                      
                      <!--All Cells except Actions Column-->
                      <div *ngIf="headerName !== 'Actions'">
                        <th mat-header-cell *matHeaderCellDef> <p class="header-text">{{headerName}}</p> </th>
                        <td mat-cell *matCellDef="let element"> {{element[headerName]}} </td>
                      </div>
          
                      <!--Actions Column-->
                      <div *ngIf="headerName === 'Actions'">
                        <th mat-header-cell *matHeaderCellDef>
                          <p class="header-text">{{headerName}}</p>
                        </th>
                        <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                          <div class="row-action-btns" style="min-width: 80px;">
                            <button mat-icon-button class="edit-btn" aria-label="Edit Row"   (click)="getSelectedMaterialDataForEdit(element)" pTooltip="Edit" tooltipPosition="top">
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                                  fill="white" />
                              </svg>
                            </button>
                            <button mat-icon-button class="tree-view-btn" aria-label="View Row Tree" (click)="deleteMaterialData(element)" pTooltip="Delete" tooltipPosition="top" pTooltip="Delete" tooltipPosition="top">
                              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                                  fill="#F85439" />
                                <circle cx="17" cy="17" r="14" fill="#F85439" />
                                <path
                                  d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                                  fill="white" />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </div>
                    </ng-container>
          
                    <tr mat-header-row *matHeaderRowDef="historyDataHeaders"></tr>
                    <tr mat-row *matRowDef="let row; columns: historyDataHeaders;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="4">No data matching</td>
                    </tr>
                  </table>
                </div>
                <mat-paginator class="basic-paginataor" [pageSize]="5" [pageSizeOptions]="[2, 5, 10, 20]" showFirstLastButtons></mat-paginator>
              </div>
        </div>
    </mat-dialog-content>
</ng-template>


<!--Removed AG-GRID For History Data-->
<!-- <ag-grid-angular style="width: 100%; height:30vw;" class="ag-theme-alpine"
    [columnDefs]="columnDefs" [rowData]="rowData" [gridOptions]="gridOptions" (cellClicked)="cellClicked($event)"
    (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="paginationPageSize">
</ag-grid-angular> -->