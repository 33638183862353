import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { TranslaterService } from "./translater.service";

export class Multilingual  {
    languageDir = 'ltr';
    langObject: any = {};
 
    constructor(  public translater: TranslaterService,public translate: TranslateService,) {
          this.translate.onLangChange.subscribe((event: LangChangeEvent) => { event.lang === 'ar' ?  this.languageDir = 'rtl' :  this.languageDir = 'ltr';this.getLangWords();});
         }
         public getLangWords() {
            const key = {
              ModuleId: 0,
              PageId: 0,
              LanguageId: this.translate.currentLang === 'en' ? 1 : this.translate.currentLang === 'ar' ? 2 : this.translate.currentLang === 'fr' ? 3 : this.translate.currentLang === 'sp' ? 4 : this.translate.currentLang === 'gr' ? 5 : this.translate.currentLang === 'du' ? 6 : 1
            };
            this.translater.getLangWords(key).subscribe((res: any) => {
              this.langObject = res.tagList;
            });
          }
          getTransalatedValue(value:string){
            let val;
            if(this.langObject.length > 0){
              val= this.langObject?.find(f => f.tagName === value)
            }
            return val?.translatedValue;
         }
}