<div class="production-db-content">
    <div class="content-top-box">
        <ul class="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                    <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
                </li>
            </ul>
        </ul>
        <div class="title-row-flex-both-end">
            <h1 class="main-title">Machine Wise OEE Analysis Summary</h1>
            <div>
                <button mat-raised-button color="accent" class="btn-accent mr-15-px">Live</button>
                <button mat-raised-button color="accent" class="btn-accent">Yesterday</button>
            </div>
        </div>
    </div>

    <app-dashboard-header></app-dashboard-header>

    <div class="w-100">
        <div class="chart-items w-50 float-left" [ngClass]="{'mr-1': i%2==0, 'w-100': last}"
            *ngFor="let oee of machineOEEChart; let i=index; let last = last;">
            <div class="chart-title">{{machineWiseoeeData[i].title}}</div>
            <div [chart]="oee"></div>
        </div>
        <div class="d-flex justify-content-around w-100 my-5px">
            <div class="chart-items w-30">
                <div class="chart-title">Machine Stops (Min)</div>
                <div [chart]="machineStops"></div>
            </div>
            <div class="chart-items w-20">
                <div class="chart-title">YTD</div>
                <div [chart]="ytd"></div>
            </div>
            <div class="chart-items w-25">
                <div class="chart-title">Top 10 Downtime (>60 Min)</div>
                <div [chart]="downtime"></div>
            </div>
            <div class="chart-items w-25">
                <div class="chart-title">Downtime Occurrences (>60 Min)</div>
                <div [chart]="downtimeOccurences"></div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-around w-100 my-5px">
    </div>

</div>