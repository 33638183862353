import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeNestedDataSource,
} from '@angular/material/tree';
import { differenceInCalendarDays } from 'date-fns';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import {
  ExampleFlatNode,
  PlantGroupElement,
} from 'src/app/interface/plant-model';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ProcessControlService } from 'src/app/services/processControlService.service';
import { QualityParameterService } from 'src/app/services/quality-parameter.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { StockChart } from 'angular-highcharts';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltip } from 'src/app/custom-tooltip.component';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { interval, Subscription } from 'rxjs';
import { MatSelectRendererComponent } from 'src/app/mat-selectlist-renderer';
import { MachineParamtersService } from 'src/app/services/machine-paramters.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CrewScheduleService } from 'src/app/services/crewSchedule.service';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { HIGHCHARTCOLORS } from 'src/app/@constants/constants';
// import { DatePipe } from '@angular/common';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

const moment = _moment;

@Component({
  selector: 'app-process-control',
  templateUrl: './process-control.component.html',
  styleUrls: ['./process-control.component.scss'],
})
export class ProcessControlComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
    // node => node.children
  );
  // treeControl = new NestedTreeControl<PlantGroupElement>(
  //   (node) => node.children
  // );
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  @ViewChild('pdfModel') private pdfModel!: TemplateRef<any>;

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Analysis',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptions1: MatProgressButtonOptions = {
    active: false,
    text: 'Comparison',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  @ViewChild('pickerforgrid') picker!: any;
  @ViewChild('visibleYearlyTarget')
  private visibleYearlyTargetTbl!: TemplateRef<any>;
  @ViewChild('visibleParameterComparison')
  private visibleParameterComparison!: TemplateRef<any>;
  @ViewChild('qualityBoolListModal')
  private qualityBoolListModal!: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  processcontrolcolumns: string[] = [
    'LRL',
    'LSL',
    'LWL',
    'TGT',
    'UWL',
    'USL',
    'URL',
  ];
  defectColumns: string[] = [
    'DefectMode',
    'Department',
    'Line',
    'Unit',
    'Assembly',
    'Subassembly',
    'Component',
  ];
  ordersColumns: string[] = [
    'ProcessOrder',
    'ProdCode',
    'ProdDesc',
    'StartTime',
    'EndTime',
  ];
  today = new Date();
  processControlHeaderForm!: FormGroup;
  searchForm!: FormGroup;
  unitDetailForm!: FormGroup;
  analysisChart!: FormGroup;
  columnDefs!: any;
  parameterspecifications = new MatTableDataSource<any>([]);
  parametrdefect!: MatTableDataSource<any>;
  isParamterValuesModelVisible = false;
  Exdpandhide = true;
  Collapsehide = false;
  isCardExpanded = false;
  Isexpandcontentshow = true;
  Isexpandcontenthide = true;
  Isexpandcontentbuttonshow = false;
  backgroundColor = '';
  displayProcessModal!: boolean;
  displayDefectModal!: boolean;
  crewScheduleList: any;
  loadingOverlayComponent: any;

  qualityProcessOrder = new MatTableDataSource<any>([]);
  // Date-Time Picker Variable
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  // public maxDate: moment.Moment | undefined;
  public maxDate = new Date();
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  gridApi: any;
  gridColumnApi: any;
  defaultColDef!: any;
  rowSelection: any;
  qualityBoolVal!: string;
  // tslint:disable-next-line:max-line-length
  highChartsColor = HIGHCHARTCOLORS;

  canvas1: any;
  option: any;
  ctx1: any;
  rowData!: any[];
  columns: any;
  emptygrid!: any[];
  chart!: any;
  autoGroupColumnDef = {
    headerName: 'Parameter Group',
    pinned: 'left',
    resizable: true,
    flex: 1,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    filter: true,
    filterValueGetter: (params: any) => params.data.ParameterGroup,
  };
  gridOptions: any = {
    suppressFieldDotNotation: true,
    defaultColDef: {
      // filter: 'agTextColumnFilter',
      flex: 1,
      resizable: true,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    groupDefaultExpanded: 1,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    frameworkComponents: {
      customTooltip: CustomTooltip,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
      slectlistRenderer: MatSelectRendererComponent,
      customLoadingOverlay: CustomLoadingOverlay,
    },
  };

  frameworkComponents: any;
  ProcessControlDataForm!: FormGroup;
  highlightedRows = [];
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  IntervalTypeDDL!: any[];
  group!: any[];
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();

  // treeControl = new NestedTreeControl<PlantGroupElement>(
  //   (node) => node.children
  // );

  isPlantGroupModelVisible = false;
  varUnitName!: string;

  unitid: any;
  UnitName: any;
  UnitNode: any;
  unitId: any;
  stock!: StockChart;
  parameterId: any;
  chartData: any;
  parameterName: any;
  // FromTimePicker!: Date;
  // ToTimePicker!: Date;
  chartArray: any[] = [];
  chartSpecifications: any;
  paramterSpcecificationName: any;
  intervalList: any;
  subscription!: Subscription;
  groupTypeList: any;
  qualityGroupType: any;
  isEventMode = false;
  isTimeMode = false;
  boolList = [];
  plantId: any;
  currentprocessOrder: any;
  PPID: any;
  boolRowIndex: any;
  boolColId: any;
  getRowNodeId: (data: any) => any;
  selectionName: any;
  TREE_DATA!: PlantGroupElement[];
  searchTree!: string;
  ResumeInterval = true;
  parameterGroupList: any;
  moduleId!: any;
  pageId!: any;
  selectedColumns: any = [];

  constructor(
    private fb: FormBuilder,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private userService: UserManagementService,
    public router: Router,
    private qualityParameterService: QualityParameterService,
    private enterpriseGroupService: EnterPriseGroupService,
    private processService: ProcessControlService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private machineParamtersService: MachineParamtersService,
    private notificationService: NotificationService,
    private crewService: CrewScheduleService,
    private sfdService: ShopFloorDataService,
  ) {
    this.rowSelection = 'single';
    this.getRowNodeId = (data) => data.id;
    this.loadingOverlayComponent = 'customLoadingOverlay';
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngAfterViewInit(): void {
    this.gridOptions.api.hideOverlay();
  }

  lowerCellEdit(event: any): void {
    // console.log(event, 'event');
  }
  ngOnInit(): void {
    this.ProcessControlDataForm = this.fb.group({
      test: [''],
      test1: [''],
      test2: [''],
      test3: [''],
      test4: [''],
      test5: [''],
      test6: [''],
      test7: [''],
      test8: [''],
    });
    this.GetMenuAccess();
    this.GetBreadcrumList();

    this.unitDetailForm = this.fb.group({
      groupList: ['', Validators.required],
      UnitNameList: ['', Validators.required],
      parameterGroup: [''],
      // toProcessDate: ['', Validators.required],
      processType: [''],
      datePicker: [''],
      parameterInterval: [],
      intervalType: [],
      groupType: [''],
      pdfcolumn: [''],
    });
    const start = new Date();
    this.getGroup();
    const selected = {
      startDate: new Date(start.setHours(0, 0, 0, 0)),
      endDate: new Date(),
    };
    const source = interval(5 * 60 * 1000);
    this.subscription = source.subscribe((val) => this.onRefresh());
    this.unitDetailForm.get('datePicker')?.setValue(selected);
    this.analysisChart = this.fb.group({
      analysisDatePicker: ['']
    });
  }

  transformer(node: any, level: number): any {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level,
      title: node.name,
      text: node.text,
      Level: node.Level,
      PrimaryId: node.PrimaryId,
      icon: node.icon,
      id: node.id,
      children: node.children,
      NextLevel: node.NextLevel,
      ParentEditId: node.ParentEditId,
    };
  }
  toggleTimer(): void {
    if (this.ResumeInterval) {
      this.ResumeInterval = false;
    } else {
      this.ResumeInterval = true;
    }
  }

  getGroup(): void {
    const key = {
      Id: 0,
    };
    this.qualityParameterService.getGroupData(key).subscribe(
      (data: any) => {
        this.group = data.masterList;
        this.unitDetailForm.get('groupList')?.setValue(this.group[0].TypeId);
      },
      (error: any) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  GetCrewScheduleList(): void {
    const key = {
      PUID: this.unitId,
      Date: new Date(),
    };
    this.crewService.GetCrewScheduleList(key).then((res: any) => {
      this.crewScheduleList = res.CrewScheduleList;
      if (this.crewScheduleList.length) {
        const date = new Date();
        const record = this.crewScheduleList.filter(
          (c: any) =>
            date.getTime() >= new Date(c.ShiftStart).getTime() &&
            date.getTime() <= new Date(c.ShiftEnd).getTime()
        );
        if (record.length) {
          const start = new Date(record[0].ShiftStart);
          const end = new Date();
          const selected = {
            startDate: start,
            endDate: new Date(),
          };
          this.unitDetailForm.get('datePicker')?.setValue(selected);
          this.GetGroupTypeList();
        } else {
          const start = new Date();
          const selected = {
            startDate: new Date(start.setHours(0, 0, 0, 0)),
            endDate: new Date(),
          };
          this.unitDetailForm.get('datePicker')?.setValue(selected);
          this.GetGroupTypeList();
        }
      } else {
        // const start = new Date();
        // tslint:disable-next-line:max-line-length
        const start = new Date();
        const selected = {
          startDate: new Date(start.setHours(0, 0, 0, 0)),
          endDate: new Date(),
        };
        this.unitDetailForm.get('datePicker')?.setValue(selected);
        // this.unitDetailForm
        //   .get('datePicker')
        //   ?.setValue(
        //     [new Date(start.setHours(0, 0, 0, 0)).toISOString(), new Date()],
        //     { emitEvent: false }
        //   );
        this.GetGroupTypeList();
      }
    });
  }
  onChangeGroupGetMachine(): void {
    this.UnitName = ' ';
    // this.unitDetailForm.get('processDate')?.setValue('');
    this.gridApi.setRowData([]);
  }
  onProcessDate(): any {}
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;
  onToProcessDate(): any {
    const getdata = {
      fromDate: moment(this.unitDetailForm.value.datePicker.startDate).utc(),
      toDate: moment(this.unitDetailForm.value.datePicker.endDate).utc(),
      puId: this.unitId,
      processType: 'PO',
      interval: this.unitDetailForm.value.parameterInterval,
      currentDateTime: new Date(),
      // intervalType: this.unitDetailForm.value.intervalType,
      groupType: this.qualityGroupType,
      // tslint:disable-next-line:max-line-length
      intervalType:
        this.qualityGroupType === 'Time' || this.qualityGroupType === 'Manual'
          ? this.qualityGroupType
          : this.unitDetailForm.value.intervalType,
      vgTypeId: 1,
    };
    const from = this.unitDetailForm.value.datePicker.startDate;
    const To = this.unitDetailForm.value.datePicker.endDate;
    const timeDiff = moment(To).diff(moment(from), 'hours');

    // if (timeDiff > 24) {
    //   this.toaster.error('Error', 'Select Date Range between 24 hours');
    //   return;
    // }
    if (!this.qualityGroupType) {
      return;
    }
    this.gridApi.showLoadingOverlay();
    this.processService.getParameterProcessControl(getdata).then(
      (response: any) => {
        this.gridApi.hideOverlay();
        const results: any = [];
        // var results = [];
        // tslint:disable-next-line:space-before-function-paren
        response.forEach(function (this: any, e: any): any {
          const a =
            e.parameterGroup +
            '|' +
            e.parameterName +
            '|' +
            e.parameterId +
            '|' +
            e.dataType;
          if (!this[a]) {
            // tslint:disable-next-line: max-line-length
            this[a] = {
              ParameterGroup: e.parameterGroup,
              Parameter: e.parameterName,
              parameterId: e.parameterId,
              DataType: e.dataType,
              dataTypeId: e.dataTypeId,
            };
            results.push(this[a]);
          }
          this[a][e.timInterval1] = e.value;
        }, {});
        this.rowData = results;
        if (this.rowData) {
          this.columnDefs = this.generateColumns(this.rowData);
          setTimeout(() => {
            this.gridOptions.api.columnController.resetColumnState();
            if (this.qualityGroupType === 'Time') {
              const scroll = document.querySelector(
                '.ag-body-horizontal-scroll-viewport'
              );
              if (scroll !== null) {
                scroll.scrollLeft = scroll.scrollWidth;
              }
            }
          }, 100);
          if (this.qualityGroupType === 'Manual') {
            this.onParameterGroupChange();
          }
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
    this.gridApi.showLoadingOverlay();
  }

  getPaginatorOrder(): void {
    setTimeout(() => {
      this.qualityProcessOrder.paginator = this.paginator;
      this.qualityProcessOrder.sort = this.sort;
    }, 1000);
  }
  async GetAllDataTypesDetail(val: any): Promise<any> {
    const key = {
      variableId: val,
    };
    await this.machineParamtersService
      .GetAllDataTypesDetail(key)
      .then((res: any) => {
        this.boolList = res.map((c: any) => c.text);
      });
  }
  generateColumns(data: any[]): any {
    this.columns = [];
    this.columnDefs = [];
    let columnDefinitions: any[] = [];
    //  this.gridOptions.columnApi.setColumnsVisible('KPIId', false);
    data.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key, // .toUpperCase()
          // headerClass: 'header-style',
          headerClass: {},
          headerTooltip: key,
          tooltipComponent: 'customTooltip',
          tooltipValueGetter,
          field: key,
          resizable: true,
          wrapText: true,
          rowGroup: key === 'ParameterGroup' ? true : false,
          width:
            key === 'DataType' ||
            key === 'ParameterGroup' ||
            key === 'dataTypeId'
              ? 150
              : key === 'Parameter'
              ? 300
              : 200,
          pinned:
            key === 'Parameter' ||
            key === 'DataType' ||
            key === 'dataTypeId' ||
            key === 'ParameterGroup'
              ? 'left'
              : '',
          lockPinned:
            key === 'Parameter' ||
            key === 'DataType' ||
            key === 'dataTypeId' ||
            key === 'ParameterGroup'
              ? true
              : false,
          cellStyle: {},
          hide:
            key === 'ParameterGroup' ||
            key === 'DataType' ||
            key === 'dataTypeId'
              ? true
              : false,
          filter: true,
          sortable: true,
          editable: false,
          valueParser: {},
          // tslint:disable-next-line:max-line-length
          valueGetter:
            key !== 'Parameter' &&
            key !== 'ParameterGroup' &&
            key !== 'DataType' &&
            key !== 'dataTypeId' &&
            key !== 'parameterId'
              ? this.abValueGetter
              : null,
          rowSelection: 'multiple',
          onCellClicked: (params: any) => {
            if (params.data.dataTypeId === 5) {
              params.colDef.cellEditor = 'agRichSelectCellEditor';
              this.GetAllDataTypesDetail(params.data.parameterId);
              this.selectionName = params.data.Parameter;
              if (mappedColumn.editable) {
                this.dialog.open(this.qualityBoolListModal, {
                  height: 'auto',
                  width: '45%',
                });
              }
              this.boolColId = params.column.colId;
              this.boolRowIndex = params.rowIndex;
            } else if (params.data.dataTypeId === 4) {
              // params.colDef.cellEditor = 'primeCellEditor';
              this.selectionName = params.data.Parameter;
              if (mappedColumn.editable) {
                this.reOpenCalendar();
              }
              this.boolColId = params.column.colId;
              this.boolRowIndex = params.rowIndex;
            } else {
              params.colDef.cellEditor = 'agTextCellEditor';
            }
          },
          cellEditor: null,
          cellEditorParams:
            key !== 'Parameter' && key !== 'DataType' && key !== 'dataTypeId'
              ? (params: any): Promise<any> => {
                  return params.value;
                }
              : null,
        };
        if (this.qualityGroupType === 'Time') {
          mappedColumn.cellStyle = (params: any): any => {
            if (
              key !== 'Parameter' &&
              key !== 'ParameterGroup' &&
              key !== 'Cavity' &&
              key !== 'DataType' &&
              key !== 'dataTypeId' &&
              key !== 'parameterId'
            ) {
              const fromDate = new Date(key.substring(0, 20));
              const toDate = new Date(key.substring(23, 43));
              // tslint:disable-next-line:max-line-length
              if (
                date.getTime() >= fromDate.getTime() &&
                date.getTime() <= toDate.getTime() + 1 * 60000
                // key.split('~poStatus')[1].split('~')[0] === 'InProcess'
              ) {
                if (
                  key.split('~poStatus')[1].split('~')[0] === '' ||
                  key.split('~poStatus')[1].split('~')[0] === 'InProcess'
                ) {
                  return {
                    'font-weight': 'bold',
                    color: params.value
                      ? params.data[params.colDef.field].split('|')[1]
                      : 'black',
                    'background-color': '#bdccdd',
                  };
                } else {
                  if (params.value) {
                    return {
                      color: params.data[params.colDef.field].split('|')[1],
                    };
                  }
                }
              } else {
                if (params.value) {
                  return {
                    color: params.data[params.colDef.field].split('|')[1],
                  };
                }
              }
            }
          };
          const date = new Date(new Date().setSeconds(0));
          if (
            key !== 'Parameter' &&
            key !== 'DataType' &&
            key !== 'dataTypeId' &&
            key !== 'parameterId' &&
            key !== 'ParameterGroup'
          ) {
            const fromDate = new Date(key.substring(0, 20));
            const toDate = new Date(key.substring(23, 43));
            // tslint:disable-next-line:max-line-length
            if (
              date.getTime() >= fromDate.getTime() &&
              date.getTime() <= toDate.getTime() + 1 * 60000
              // key.split('~poStatus')[1].split('~')[0] === 'InProcess'
            ) {
              if (
                key.split('~poStatus')[1].split('~')[0] === '' ||
                key.split('~poStatus')[1].split('~')[0] === 'InProcess'
              ) {
                mappedColumn.editable = true;
                mappedColumn.lockPinned = true;
                mappedColumn.pinned = 'left';
              } else {
                mappedColumn.editable = false;
              }
              mappedColumn.valueParser = (params: any) => {
                if (this.IsWrite) {
                  // const col = params.colDef.field;
                  // if (col === 'Status') {
                  const dataType = params.data.dataTypeId;
                  if (dataType === 1 || dataType === 2 || dataType === 3) {
                    const newVal = params.newValue;
                    if (dataType === 2) {
                      const charcter = Number.isInteger(Number(newVal));
                      if (!charcter) {
                        this.toaster.warning(
                          'DigitsOnly',
                          'Please Select Digits Only'
                        );
                        params.node.setDataValue(
                          params.colDef.field,
                          params.oldValue
                        );
                        // params.node.setDataValue(params.colDef.field, params.oldValue);
                        return;
                      } else {
                        params.newValue = parseInt(params.newValue, 10);
                      }
                    }
                    if (dataType === 3) {
                      const float = parseFloat(newVal);
                      // tslint:disable-next-line:use-isnan
                      if (isNaN(float)) {
                        this.toaster.warning(
                          'FloatOnly',
                          'Please Select Float Only'
                        );
                        params.node.setDataValue(
                          params.colDef.field,
                          params.oldValue
                        );
                        return;
                      } else {
                        params.newValue = parseFloat(params.newValue).toFixed(
                          2
                        );
                      }
                    }
                    const startIntervalDate = params.colDef.field.substring(
                      0,
                      20
                    );
                    const endIntervalDate = params.colDef.field.substring(
                      23,
                      43
                    );
                    if (new Date() > new Date(endIntervalDate)) {
                      this.toaster.warning(
                        'Time Passed',
                        'Slot time has been passed'
                      );
                      return;
                    }
                    let cellData: any;
                    if (!params.oldValue || params.oldValue === '') {
                      cellData = {
                        result: params.newValue,
                        entryOn: new Date(),
                        resultOn: endIntervalDate,
                        varId: params.data.parameterId,
                        isOldValue:
                          this.qualityGroupType !== 'Time' ? true : false,
                        po: params.column.colId.split('~')[1],
                        createdBy: JSON.parse(localStorage.user).UserId,
                        // tslint:disable-next-line:max-line-length
                        prodId:
                          params.column.colId.split('~prodId')[1] !== undefined
                            ? params.column.colId
                                .split('~prodId')[1]
                                .split('~')[0]
                            : '',
                        ppId:
                          params.column.colId.split('~ppId')[1] !== undefined
                            ? params.column.colId
                                .split('~ppId')[1]
                                .split('~')[0]
                            : '',
                        uomId:
                          params.column.colId.split('~uomId')[1] !== undefined
                            ? params.column.colId
                                .split('~uomId')[1]
                                .split('~')[0]
                            : '',
                        ppdId:
                          params.column.colId.split('~ppdId')[1] !== undefined
                            ? params.column.colId
                                .split('~ppdId')[1]
                                .split('~')[0]
                            : '',
                        intervalType: this.qualityGroupType,
                      };
                    } else {
                      cellData = {
                        result: params.newValue,
                        entryOn: new Date(),
                        resultOn: endIntervalDate,
                        varId: params.data.parameterId,
                        endInterval: endIntervalDate,
                        startInterval: startIntervalDate,
                        isOldValue: true,
                        po: params.column.colId.split('~')[1],
                        createdBy: JSON.parse(localStorage.user).UserId,
                        // tslint:disable-next-line:max-line-length
                        prodId:
                          params.column.colId.split('~prodId')[1] !== undefined
                            ? params.column.colId
                                .split('~prodId')[1]
                                .split('~')[0]
                            : '',
                        ppId:
                          params.column.colId.split('~ppId')[1] !== undefined
                            ? params.column.colId
                                .split('~ppId')[1]
                                .split('~')[0]
                            : '',
                        uomId:
                          params.column.colId.split('~uomId')[1] !== undefined
                            ? params.column.colId
                                .split('~uomId')[1]
                                .split('~')[0]
                            : '',
                        ppdId:
                          params.column.colId.split('~ppdId')[1] !== undefined
                            ? params.column.colId
                                .split('~ppdId')[1]
                                .split('~')[0]
                            : '',
                        intervalType: this.qualityGroupType,
                      };
                    }
                    this.processService.saveProessControl(cellData).subscribe(
                      (response: any) => {
                        const msg = response[0].result;
                        if (msg !== null || msg !== '') {
                          if (msg.substring(2, 1) === '0') {
                            this.toaster.success(
                              'Success',
                              msg.substring(3, msg.length)
                            );
                            if (response[0].value) {
                              const color = response[0].value.split('|')[1];
                              if (color === '#ff2121') {
                                this.sendNotificationRole(
                                  `NCR ID - ${
                                    response[0].ncrId
                                  } Out of Specification Limits! The Defect had happened against ${
                                    params.column.colId.split('~')[1]
                                  }`
                                );
                              }
                              if (color === '#ffff00') {
                                this.sendNotificationRole(
                                  `NCR ID - ${
                                    response[0].ncrId
                                  } Watch Out! There is a risk of defect happening against ${
                                    params.column.colId.split('~')[1]
                                  }`
                                );
                              }
                            }
                            params.node.setDataValue(
                              params.colDef.field,
                              response[0].value
                            );
                          } else {
                            this.toaster.error(
                              'Error',
                              msg.substring(3, msg.length)
                            );
                            params.node.setDataValue(
                              params.colDef.field,
                              params.oldValue
                            );
                          }
                        }
                      },
                      (error: any) => {
                        return this.toaster.error('Error', error.message);
                      }
                    );
                  }
                }
              };
            }
          }
          if (
            key !== 'Parameter' &&
            key !== 'parameterId' &&
            key !== 'DataType' &&
            key !== 'dataTypeId' &&
            key !== 'ParameterGroup'
          ) {
            const fromDate = moment(new Date(key.substring(0, 20))).format('DD-MM-YYYY HH:mm:ss');
            const toDate =  moment(new Date(key.substring(23, 43))).format('DD-MM-YYYY HH:mm:ss');
            mappedColumn.headerName =
            
              fromDate +
              ' - ' +
              toDate +
              ' - ' +
              key.split('~')[1];
            mappedColumn.headerTooltip =
              'PO: ' +
              key.split('~')[1] +
              ' - ' +
              key.split('~poStatus')[1].split('~')[0];
            //  mappedColumn.field = 
            //   fromDate +
            //   ' - ' +
            //   toDate +
            //   key.substring(43,(key.length))
          }
        }
        if (
          this.qualityGroupType === 'Event' ||
          this.qualityGroupType === 'Manual'
        ) {
          debugger;
          if (
            key !== 'Parameter' &&
            key !== 'ParameterGroup' &&
            key !== 'parameterId' &&
            key !== 'Cavity' &&
            key !== 'dataTypeId' &&
            key !== 'DataType'
          ) {
            // tslint:disable-next-line:max-line-length
            if (this.qualityGroupType === 'Event') {
              const fromDate = new Date(key.substring(0, 20));
              mappedColumn.headerName =
                moment(fromDate).format('DD-MM-YYYY HH:mm:ss') +
                ' - ' +
                `PO  :  ${key.split('~')[1]}
                 -  ${key.split('~poStatus')[1].split('~')[0]}`; // LotCode: ${key.split('~lotCode')[1].split('~')[0]
              mappedColumn.headerTooltip = `PO  :  ${key.split('~')[1]} `; // LotCode: ${key.split('~lotCode')[1].split('~')[0]}
            } else {
              const fromDate = new Date(key.substring(0, 20));
              mappedColumn.headerName =
                moment(fromDate).format('DD-MM-YYYY HH:mm:ss') +
                ' - ' +
                key.split('~')[1];
              mappedColumn.headerTooltip = `PO  :  ${key.split('~')[1]}`;
            }
            mappedColumn.editable = true;
          }
          mappedColumn.cellStyle = (params: any): any => {
            if (
              key !== 'Parameter' &&
              key !== 'ParameterGroup' &&
              key !== 'Cavity' &&
              key !== 'DataType' &&
              key !== 'dataTypeId' &&
              key !== 'parameterId'
            ) {
              // if (params.value) {
              return {
                'font-weight': 'bold',
                color: params.value
                  ? params.data[params.colDef.field].split('|')[1]
                  : 'black',
                'background-color': '#bdccdd',
              };
              // }
            }
          };
          mappedColumn.valueParser = (params: any) => {
            if (this.IsWrite) {
              const dataType = params.data.dataTypeId;
              if (dataType === 1 || dataType === 2 || dataType === 3) {
                const dataType = params.data.dataTypeId;
                let newVal = params.newValue;
                if (dataType === 2) {
                  const charcter = Number.isInteger(Number(newVal));
                  if (!charcter) {
                    this.toaster.warning(
                      'DigitsOnly',
                      'Please Select Int Value Only'
                    );
                    // newVal = params.oldValue;
                    params.node.setDataValue(
                      params.colDef.field,
                      params.oldValue
                    );
                    return;
                  } else {
                    params.newValue = parseInt(params.newValue, 10);
                  }
                }
                if (dataType === 3) {
                  const float = parseFloat(newVal);
                  // tslint:disable-next-line:use-isnan
                  if (isNaN(float)) {
                    this.toaster.warning(
                      'Decimal Only',
                      'Please Select decimal values Only'
                    );
                    // newVal = params.oldValue;
                    params.node.setDataValue(
                      params.colDef.field,
                      params.oldValue
                    );
                    return;
                  } else {
                    params.newValue = parseFloat(params.newValue).toFixed(2);
                  }
                }
                if (dataType === 4) {
                  const date = params.newValue;
                  if (date.match(new RegExp('/', 'g')).length === 2) {
                  } else {
                    this.toaster.warning(
                      'SlashError',
                      'Please Select Correct Date Format'
                    );
                    return;
                  }
                }
                const startIntervalDate = params.colDef.field.substring(0, 20);
                const endIntervalDate = params.colDef.field.substring(23, 43);
                if (new Date() > new Date(endIntervalDate)) {
                  this.toaster.warning(
                    'Time Passed',
                    'Slot time has been passed'
                  );
                  return;
                }
                // const fromDate = new Date(params.colDef.field.substring(0, 19).replace(/-/g, '/'));
                // const toDate = new Date(params.colDef.field.substring(22, 41).replace(/-/g, '/'));
                let cellData: any;
                if (!params.oldValue || params.oldValue === '') {
                  cellData = {
                    result: params.newValue,
                    entryOn: new Date(),
                    resultOn: startIntervalDate,
                    endInterval: startIntervalDate,
                    startInterval: startIntervalDate,
                    varId: params.data.parameterId,
                    isOldValue: this.qualityGroupType !== 'Time' ? true : false,
                    po: params.column.colId.split('~')[1],
                    // eventId: params.column.colId
                    //   .split('~EventId')[1]
                    //   .split('~')[0],
                    createdBy: JSON.parse(localStorage.user).UserId,
                    intervalType: this.qualityGroupType,
                    prodId:
                      params.column.colId.split('~prodId')[1] !== undefined
                        ? params.column.colId.split('~prodId')[1].split('~')[0]
                        : '',
                    ppId:
                      params.column.colId.split('~ppId')[1] !== undefined
                        ? params.column.colId.split('~ppId')[1].split('~')[0]
                        : '',
                    uomId:
                      params.column.colId.split('~uomId')[1] !== undefined
                        ? params.column.colId.split('~uomId')[1].split('~')[0]
                        : '',
                    ppdId:
                      params.column.colId.split('~ppdId')[1] !== undefined
                        ? params.column.colId.split('~ppdId')[1].split('~')[0]
                        : '',
                  };
                } else {
                  cellData = {
                    result: params.newValue,
                    entryOn: new Date(),
                    resultOn: startIntervalDate,
                    varId: params.data.parameterId,
                    endInterval: startIntervalDate,
                    startInterval: startIntervalDate,
                    isOldValue: true,
                    po: params.column.colId.split('~')[1],
                    // eventId: params.column.colId
                    //   .split('~EventId')[1]
                    //   .split('~')[0],
                    createdBy: JSON.parse(localStorage.user).UserId,
                    intervalType: this.qualityGroupType,
                    prodId:
                      params.column.colId.split('~prodId')[1] !== undefined
                        ? params.column.colId.split('~prodId')[1].split('~')[0]
                        : '',
                    ppId:
                      params.column.colId.split('~ppId')[1] !== undefined
                        ? params.column.colId.split('~ppId')[1].split('~')[0]
                        : '',
                    uomId:
                      params.column.colId.split('~uomId')[1] !== undefined
                        ? params.column.colId.split('~uomId')[1].split('~')[0]
                        : '',
                    ppdId:
                      params.column.colId.split('~ppdId')[1] !== undefined
                        ? params.column.colId.split('~ppdId')[1].split('~')[0]
                        : '',
                  };
                }
                this.processService.saveProessControl(cellData).subscribe(
                  (response: any) => {
                    // this.toaster.success('Success', 'Data Added Successfully');
                    // this.showPopupMsg(response[0].result);
                    const msg = response[0].result;
                    if (msg !== null || msg !== '') {
                      if (msg.substring(2, 1) === '0') {
                        this.toaster.success(
                          'Success',
                          msg.substring(3, msg.length)
                        );
                        if (response[0].value) {
                          const color = response[0].value.split('|')[1];
                          if (color === '#ff2121') {
                            this.sendNotificationRole(
                              `NCR ID - ${
                                response[0].ncrId
                              } Out of Specification Limits! The Defect had happened against ${
                                params.column.colId.split('~')[1]
                              }`
                            );
                          }
                          if (color === '#ffff00') {
                            this.sendNotificationRole(
                              `NCR ID - ${
                                response[0].ncrId
                              } Watch Out! There is a risk of defect happening against ${
                                params.column.colId.split('~')[1]
                              }`
                            );
                          }
                        }
                        params.node.setDataValue(
                          params.colDef.field,
                          response[0].value
                        );

                        // params.node.setDataValue(params.colDef.field, newVal);
                      } else {
                        this.toaster.error(
                          'Error',
                          msg.substring(3, msg.length)
                        );
                        params.node.setDataValue(
                          params.colDef.field,
                          params.oldValue
                        );
                      }
                    }
                  },
                  (error: any) => {
                    return this.toaster.error('Error', error.message);
                  }
                );
                // }
              }
            }
          };
        }
        if (key === 'parameterId') {
          mappedColumn.hide = true;
        }
        columnDefinitions.push(mappedColumn);
      });
    });

    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    this.rowSelection = 'multiple';
    this.getRowNodeId = (data) => data.id;
    const column = columnDefinitions;
    this.columns = column.filter((c) => c.hide !== true);
    return columnDefinitions;
  }
  sendNotificationRole(descr: any): void {
    const key = {
      roleName: 'QC leader',
      egId: this.unitDetailForm.value.groupList,
      createdBy: JSON.parse(localStorage.user).UserId,
      desc: descr,
      title: 'Process Control',
    };
    this.notificationService.addnotification(key).subscribe(
      (response: any) => {
        this.toaster.success('Notification Sent');
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  onSelectionChanged(params: any): any {
    // const selectedRows = this.gridApi.getSelectedRows();
    // // tslint:disable-next-line: no-non-null-assertion
    // document.querySelector('#selectedRows')!.innerHTML =
    //   selectedRows.length === 1 ? 'Parameter Analysis: ' + selectedRows[0].Parameter : '';
    // this.parameterName = selectedRows[0].Parameter;
    // this.parameterId = selectedRows[0].parameterId;
  }
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onCellValueChanged(params: any): void {
    if (this.IsWrite) {
      const dataType = params.data.dataTypeId;
      if (dataType === 4 || dataType === 5) {
        if (dataType === 4) {
          const date = params.value;
          if (date.match(new RegExp('/', 'g')).length === 2) {
          } else {
            this.toaster.warning(
              'SlashError',
              'Please Select Correct Date Format'
            );
            return;
          }
        }
        const startIntervalDate = params.colDef.field.substring(0, 20);
        const endIntervalDate = params.colDef.field.substring(23, 43);
        if (new Date() > new Date(endIntervalDate)) {
          this.toaster.warning('Time Passed', 'Slot time has been passed');
          return;
        }
        let cellData: any;
        if (this.qualityGroupType === 'Time') {
          if (!params.oldValue || params.oldValue === '') {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: endIntervalDate,
              varId: params.data.parameterId,
              isOldValue: false,
              po: params.column.colId.split('~')[0],
              createdBy: JSON.parse(localStorage.user).UserId,
              prodId:
                params.column.colId.split('~prodId')[1] !== undefined
                  ? params.column.colId.split('~prodId')[1].split('~')[0]
                  : '',
              ppId:
                params.column.colId.split('~ppId')[1] !== undefined
                  ? params.column.colId.split('~ppId')[1].split('~')[0]
                  : '',
              uomId:
                params.column.colId.split('~uomId')[1] !== undefined
                  ? params.column.colId.split('~uomId')[1].split('~')[0]
                  : '',
              ppdId:
                params.column.colId.split('~ppdId')[1] !== undefined
                  ? params.column.colId.split('~ppdId')[1].split('~')[0]
                  : '',
              intervalType: this.qualityGroupType,
            };
          } else {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: endIntervalDate,
              varId: params.data.parameterId,
              endInterval: endIntervalDate,
              startInterval: startIntervalDate,
              isOldValue: true,
              po: params.column.colId.split('~')[0],
              createdBy: JSON.parse(localStorage.user).UserId,
              prodId:
                params.column.colId.split('~prodId')[1] !== undefined
                  ? params.column.colId.split('~prodId')[1].split('~')[0]
                  : '',
              ppId:
                params.column.colId.split('~ppId')[1] !== undefined
                  ? params.column.colId.split('~ppId')[1].split('~')[0]
                  : '',
              uomId:
                params.column.colId.split('~uomId')[1] !== undefined
                  ? params.column.colId.split('~uomId')[1].split('~')[0]
                  : '',
              ppdId:
                params.column.colId.split('~ppdId')[1] !== undefined
                  ? params.column.colId.split('~ppdId')[1].split('~')[0]
                  : '',
              intervalType: this.qualityGroupType,
            };
          }
        } else if (
          this.qualityGroupType === 'Event' ||
          this.qualityGroupType === 'Manual'
        ) {
          cellData = {
            result: params.value,
            entryOn: new Date(),
            resultOn: startIntervalDate,
            varId: params.data.parameterId,
            endInterval: startIntervalDate,
            startInterval: startIntervalDate,
            isOldValue: true,
            po: params.column.colId.split('~')[1],
            // eventId: params.column.colId.split('~EventId')[1].split('~')[0],
            createdBy: JSON.parse(localStorage.user).UserId,
            intervalType: this.qualityGroupType,
            // };
          };
        }
        this.processService.saveProessControl(cellData).subscribe(
          (response: any) => {
            const msg = response[0].result;
            if (msg !== null || msg !== '') {
              if (msg.substring(2, 1) === '0') {
                this.toaster.success('Success', msg.substring(3, msg.length));
                // params.node.setDataValue(params.colDef.field, newVal);
              } else {
                this.toaster.error('Error', msg.substring(3, msg.length));
                // params.node.setDataValue(params.colDef.field, params.oldValue);
              }
            }
          },
          (error: any) => {
            return this.toaster.error('Error', error.message);
          }
        );
      }
    }
  }
  abValueGetter(params: any): any {
    if (params.data) {
      // debugger
      return params.data[params.colDef.field]
        ? params.data[params.colDef.field].split('|')[0]
        : '';
    }
  }
  onClickParamterComparsion(): void {
    const selectedRows = this.gridApi
      .getSelectedRows()
      .filter((c: any) => c.dataTypeId === 2 || c.dataTypeId === 3);
    this.spinnerButtonOptions1.active = true;
    if(selectedRows.length){
      this.dialog.open(this.visibleParameterComparison, {
        panelClass: 'custom-dialog-container',
        width: '95vw !important',
        height: '100vw',
        minWidth: '95vw',
        maxHeight: '100vh',
      });
      this.spinner.show();
      const start = new Date((new Date()).getTime());
      const selected = {
        startDate: new Date(start.setDate((new Date()).getDate()-1)),
        endDate: new Date(),
      };
      this.analysisChart.get('analysisDatePicker')?.setValue(selected);
      this.getParameterConparison();
    }
    else{
      this.toaster.warning('Warning','No Row Selected');
    }
  }

  getParameterConparison(){
    this.spinner.show();
    const selectedRows = this.gridApi
      .getSelectedRows()
      .filter((c: any) => c.dataTypeId === 2 || c.dataTypeId === 3);
    this.spinnerButtonOptions1.active = true;
    const array: any[] = [];
    const yAxisArr: any[] = [];
    const valueNames = selectedRows.map((value: any) => value.Parameter);
    const seletedGroups = selectedRows.map(
      (value: any) => value.ParameterGroup
    );
    const uniqueGroups = [...new Set(seletedGroups)];
    const key = {
      parameterId: selectedRows.map((value: any) => value.parameterId).toString(),
      groupType: this.qualityGroupType,
      fromDate: moment(this.analysisChart.value.analysisDatePicker.startDate).utc(),
      toDate: moment(this.analysisChart.value.analysisDatePicker.endDate).utc(),
    };
    this.processService.getParameterControlComparison(key).then(
      (data: any) => {
        for (let i = 0; i < valueNames.length; i++) {
          const dataArr = [];
          for (let j = 0; j < data.length; j++) {
            if (valueNames[i] === data[j].name) {
              dataArr.push({
                x: new Date(data[j].processTime).getTime(),
                y: isNaN(data[j].result) ? '' : parseFloat(data[j].result),
                label: data[j].product,
              });
            }
          }
          let negativeArr = [];
          negativeArr = dataArr.map((c) => c.y);
          const obj = {
            tooltip: {
              valueDecimals: 2,
            },
            name: valueNames[i],
            type: 'line',
            data: dataArr,
            color: this.highChartsColor[i],
            yAxis: i,
          };
          array.push(obj);
          yAxisArr.push({
            lineWidth: 1,
            opposite: negativeArr.some((v) => v < 0) ? true : false,
            title: {
              text: valueNames[i],
              style: {
                color: this.highChartsColor[i],
              },
            },
            labels: {
              style: {
                color: this.highChartsColor[i],
              },
            },
          });
        }
        this.chart = new StockChart({
          chart: {
            height: 700,
          },
          time: {
            useUTC: false
          },
          plotOptions: {
            series: {
              turboThreshold: 50000000,
              events: {
                hide(): any {
                  this.yAxis.update({
                    visible: false,
                  });
                },
                show(): any {
                  this.yAxis.update({
                    visible: true,
                  });
                },
              },
            },
          },
          rangeSelector: {
            selected: 1,
          },
          title: {
            text: uniqueGroups.toString(),
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: true,
          },
          tooltip: {
            formatter(): any {
              let s = '';
              this.points?.forEach((point: any) => {
                s +=
                  '<br/> <span style="color: ' +
                  point.series.color +
                  '">' +
                  point.series.name +
                  ' : ' +
                  point.y +
                  '<span><br/>' +
                  '<br/><span style="color: ' +
                  point.series.color +
                  '">Product : ' +
                  point.point.label +
                  '<span><br/>';
              });
              return s;
            },
          },
          yAxis: yAxisArr,
          series: array,
        });
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
    this.spinnerButtonOptions1.active = false;
  }
  
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  SelectVarUnitName(unitname: any): any {
    if (unitname.title === 'UNT') {
      this.varUnitName = unitname.name;
      this.unitId = unitname.PrimaryId;
      this.isPlantGroupModelVisible = false;
      // this.getPorcessType();
    }
  }
  getPorcessType(): void {
    const key = {
      puId: this.unitId,
    };
    this.processService.getMachineParameterProcessType(key).subscribe(
      (data: any) => {
        this.unitDetailForm.get('processType')?.setValue(data.processType);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onRefresh(): void {
    if (this.unitDetailForm.valid && this.ResumeInterval) {
      const start = this.unitDetailForm.value.datePicker.startDate;
      const selected = {
        startDate: start,
        endDate: new Date(),
      };
      this.unitDetailForm.get('datePicker')?.setValue(selected);
      this.gridApi.setRowData([]);
      this.onToProcessDate();
    } else {
      // this.toaster.warning('Select all mandatory fields');
      this.spinner.hide();
    }
    // this.gridOptions.api.redrawRows();
  }

  // Icon
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  onClickUnit(node: any): void {
    if (node.Level === 'Unit') {
      this.isPlantGroupModelVisible = false;
      this.UnitName = node.text;
      this.unitId = node.PrimaryId;
      this.unitDetailForm.get('UnitNameList')?.setValue(this.UnitName);
      this.groupTypeList = [];
      this.IntervalTypeDDL = [];
      this.intervalList = [];
      this.getPorcessType();
      this.rowData = this.emptygrid;
      // this.getParameterInterval();
      // this.getCurrentProcessOrder();
      // Getting Plant Id from seleted Node
      const tree = this.treeControl.dataNodes;
      const data = this.getPath(tree[0], node.id);
      this.plantId = data.find((c: any) => c.Level === 'Plant').PrimaryId;
    }
  }
  getPath(model: any, id: any): any {
    let path;
    const item = model;

    if (!model || typeof model !== 'object') {
      return;
    }

    if (model.id === id) {
      return [item];
    }

    (model.children || []).some(
      (child: any) => (path = this.getPath(child, id))
    );
    return path && [item, ...path];
  }

  SelectUnitName(unitname: any): void {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
      this.GetCrewScheduleList();
    }
    // this.getParameterGroups();
  }
  gridDate(event: any): void {
    const itemArr: any[] = [];
    this.gridApi.forEachLeafNode((node: any) => {
      itemArr.push(node);
    });
    const filterIndex: any = itemArr.filter(
      (c) => c.rowIndex === this.boolRowIndex
    );
    const rowNode = this.gridApi.getRowNode(filterIndex[0].id);
    rowNode.setDataValue(
      this.boolColId,
      moment(event.value).format('DD/MM/YYYY')
    );
  }
  openUnit(): any {
    this.searchTree = '';
    this.isPlantGroupModelVisible = true;

    const key = {
      EGId: this.unitDetailForm.value.groupList,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        // this.plantGroupSource.data = plantGroupData;
        this.dataSource.data = plantGroupData;
        this.TREE_DATA = plantGroupData;
        // this.treeControl.dataNodes = this.plantGroupSource.data;
        this.UnitNode = filterData(this.plantGroupSource.data, 'UNT');
        this.expandAllNode();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );

    function filterData(data: PlantGroupElement[], title: string): any {
      const r = data.filter((o: any) => {
        Object.keys(o).forEach((e: any) => {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
  }
  // breadCrumb
  onIntervalChange(): void {
    this.onToProcessDate();
  }
  getParameterInterval(): void {
    const Key = {
      puId: this.unitId,
      vgTypeId: 1,
    };
    this.processService.getProcessControlInterval(Key).subscribe(
      (response: any) => {
        this.intervalList = response;
        this.unitDetailForm
          .get('parameterInterval')
          ?.setValue(this.intervalList[0].Interval);
        this.onToProcessDate();
      },
      (error: { message: any }) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getParamterGroupList(): void {
    const key = {
      // paramterType: 'Process Control',
      vgTypeId: 1,
      puId: this.unitId,
      groupType: this.qualityGroupType,
    };
    this.processService.getParamterGroupList(key).subscribe(
      (data: any) => {
        this.parameterGroupList = data;
        this.unitDetailForm
          .get('parameterGroup')
          ?.setValue(this.parameterGroupList);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  onParameterGroupChange(): void {
    const filterValue = this.unitDetailForm.value.parameterGroup;
    const instance = this.gridApi.getFilterInstance('ParameterGroup');
    const myArray: any = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < filterValue.length; i++) {
      myArray.push(filterValue[i].vgDesc);
    }
    instance.setModel({
      values: myArray.length > 0 ? myArray : [],
    });
    this.gridApi.onFilterChanged();
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  closeParamterValuesModelVisible(): void {
    this.isParamterValuesModelVisible = false;
  }

  openModal(params: any): void {
    this.spinner.show();
    this.displayProcessModal = true;
    // this.dialog.open(this.displayProcessModal, { height: '70%', width: '90%' });?
    // const podata = params.column.colId.split('~')[1];
    this.paramterSpcecificationName = params.node.data.Parameter;
    const key = {
      // processOrder: podata,
      parameterId: params.node.data.parameterId,
      prodId:
        params.column.colId.split('~prodId')[1] !== undefined
          ? parseInt(params.column.colId.split('~prodId')[1].split('~')[0], 10)
          : null,
      ppId:
        params.column.colId.split('~ppId')[1] !== undefined
          ? parseInt(params.column.colId.split('~ppId')[1].split('~')[0], 10)
          : null,
      po: params.column.colId.split('~')[1],
      startTime: params.column.colId.substring(0, 20),
      // tslint:disable-next-line:max-line-length
      endTime:
        this.qualityGroupType === 'Time'
          ? params.column.colId.substring(23, 43)
          : params.column.colId.substring(0, 20),
    };
    this.getParameterSpecifications(key);
  }
  openDefecct(params: any): void {
    this.displayDefectModal = true;
    // this.dialog.open(this.displayDefectModal, { height: '70%', width: '90%' });
    this.processparamaterdefects(params);
  }
  getParameterSpecifications(key: any): void {
    this.parameterspecifications.data = [];
    this.processService.processparameterspecs(key).subscribe(
      (response: any) => {
        this.parameterspecifications.data = response;
        this.spinner.hide();
        // this.chartSpecifications = response;
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }
  processparamaterdefects(params: any): void {
    const prodId =
      params.column.colId.split('~prodId')[1] !== undefined
        ? parseInt(params.column.colId.split('~prodId')[1].split('~')[0])
        : 0;
    const key = {
      paramId: this.parameterId,
      prodId,
    };
    this.processService.processparamaterdefects(key).subscribe(
      (response: any) => {
        this.parametrdefect = new MatTableDataSource<any>(response);
        this.getPaginator();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getPaginator(): void {
    setTimeout(() => {
      this.parametrdefect.paginator = this.paginator;
      this.parametrdefect.sort = this.sort;
    }, 1000);
  }
  getIntervalType(): void {
    const key = {
      puId: this.unitId,
      vgTypeId: 1,
    };
    this.processService.getVariableGroupEvents(key).subscribe(
      (data: any) => {
        this.IntervalTypeDDL = data;
        this.unitDetailForm
          .get('intervalType')
          ?.setValue(this.IntervalTypeDDL[0].val);
        this.onToProcessDate();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onClickParamterAnalysis(status: boolean): void {
    const selectedRows = this.gridApi
      .getSelectedRows()
      .filter((c: any) => c.dataTypeId === 2 || c.dataTypeId === 3);
      if(!selectedRows.length){
        this.toaster.warning('Warning','No Row Selected');
        return;
      }
      if(status){
        this.dialog.open(this.visibleYearlyTargetTbl, {
          panelClass: 'custom-dialog-container',
          width: '95vw !important',
          height: '100vw',
          minWidth: '95vw',
          maxHeight: '100vh',
        });
        this.spinner.show();
        const start = new Date((new Date()).getTime());
        const selected = {
          startDate: new Date(start.setDate((new Date()).getDate()-2)),
          endDate: new Date(),
        };
        this.analysisChart.get('analysisDatePicker')?.setValue(selected);
      }
      this.chartArray = [];
      for(let rowIndex = 0; rowIndex<selectedRows.length; rowIndex++){
        this.getAnalysisChart(selectedRows[rowIndex]);
      }
  }
  getAnalysisChart(element: any){
    this.spinner.show();
    // const selectedRows = this.gridApi
    //   .getSelectedRows()
    //   .filter((c: any) => c.dataTypeId === 2 || c.dataTypeId === 3);
    this.spinnerButtonOptions.active = true;
    // this.chartArray = [];
    // selectedRows.forEach(async (element: any) => {
      
    // });
    const parameterName = element.Parameter;
      const key = {
        parameterId: element.parameterId,
        groupType: this.qualityGroupType,
        fromDate: moment(this.analysisChart.value.analysisDatePicker.startDate).utc(),
        toDate: moment(this.analysisChart.value.analysisDatePicker.endDate).utc(),
      };
      const chartObj = { id: '', chartOption: {} };
      chartObj.id = element.parameterId;
      this.processService.getProcessParameterAnalysis(key).subscribe(
        (data: any) => {
          let plotlines = [];
          const LRLarray: any[] = [];
          const LSLarray: any[] = [];
          const LWLarray: any[] = [];
          const TGTarray: any[] = [];
          const UWLarray: any[] = [];
          const USLarray: any[] = [];
          const URLarray: any[] = [];
          // const Productarray: any[] = [];
          const array: any[] = [];
          data.forEach((c: any) => {
            // tslint:disable-next-line:one-variable-per-declaration
            const arr = [];
            const arrLRL = [];
            const arrLSL = [];
            const arrLWL = [];
            const arrTGT = [];
            const arrUWL = [];
            const arrUSL = [];
            const arrURL = [];
            // const arrProduct = [];
            arr.push(new Date(c.processTime).getTime());
            arr.push(parseFloat(c.result));
            array.push({
              x: new Date(c.processTime).getTime(),
              y: parseFloat(c.result),
              label: c.Product,
            });
            arrLRL.push(new Date(c.processTime).getTime());
            arrLRL.push(parseFloat(c.LRL)); // LSL Rename To LRL
            LRLarray.push(arrLRL);
            arrLSL.push(new Date(c.processTime).getTime());
            arrLSL.push(parseFloat(c.LSL)); // LCL Rename To LSL
            LSLarray.push(arrLSL);
            arrLWL.push(new Date(c.processTime).getTime());
            arrLWL.push(parseFloat(c.LWL));
            LWLarray.push(arrLWL);
            arrTGT.push(new Date(c.processTime).getTime());
            arrTGT.push(parseFloat(c.TGT));
            TGTarray.push(arrTGT);
            arrUWL.push(new Date(c.processTime).getTime());
            arrUWL.push(parseFloat(c.UWL));
            UWLarray.push(arrUWL);
            arrUSL.push(new Date(c.processTime).getTime());
            arrUSL.push(parseFloat(c.USL)); // UCL Rename To USL
            USLarray.push(arrUSL);
            arrURL.push(new Date(c.processTime).getTime());
            arrURL.push(parseFloat(c.URL)); // USL Rename To URL
            URLarray.push(arrURL);
          });
          this.stock = new StockChart({
            plotOptions: {
              series: {
                turboThreshold: 50000000,
              },
            },
            time: {
              useUTC: false
            },
            chart: {
              height: 600,
            },
            rangeSelector: {
              selected: 1,
            },
            title: {
              text: parameterName.toUpperCase(),
            },
            credits: {
              enabled: false,
            },
            legend: {
              enabled: true,
            },
            tooltip: {
              formatter(): any {
                // let s = '<b>' + StockChart.dateFormat('%A, %b %e, %Y', this.x) + '</b>';
                let s = '';
                this.points?.forEach((point: any) => {
                  if (point.series.name === 'Result') {
                    s +=
                      '<br/> <span style="color: ' +
                      point.series.color +
                      '">' +
                      point.series.name +
                      ' : ' +
                      point.y +
                      '<span><br/>' +
                      '<br/><span style="color: #252031">Product : ' +
                      point.point.label +
                      '<span><br/>';
                  } else {
                    s +=
                      '<br/> <span style="color: ' +
                      point.series.color +
                      '">' +
                      point.series.name +
                      ' : ' +
                      point.y +
                      '<span><br/>';
                  }
                });
                return s;
              },
            },
            yAxis: [
              {
                title: {
                  text: 'Result',
                },
                opposite: false,
              },
              {
                title: {
                  text: 'Product: '+ (data.length ? data[0].Product : ''),
                  style: {
                    color: '#252031',
                  },
                },
                opposite: true,
              },
            ],
            series: [
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'Result',
                type: 'line',
                data: array,
                color: '#040496',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LRL',
                type: 'line',
                data: LRLarray,
                color: '#00ff00',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LSL',
                type: 'line',
                data: LSLarray,
                color: '#FF5733',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LWL',
                type: 'line',
                data: LWLarray,
                color: '#9C412D',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'TGT',
                type: 'line',
                data: TGTarray,
                color: '#9C902D',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'UWL',
                type: 'line',
                data: UWLarray,
                color: '#25B79B',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'USL',
                type: 'line',
                data: USLarray,
                color: '#257AB7',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'URL',
                type: 'line',
                data: URLarray,
                color: '#FF0909',
                dashStyle: 'ShortDash',
              },
            ],
          });

          chartObj.chartOption = this.stock;
          this.chartArray.push(chartObj);
          this.spinner.hide();
          this.spinnerButtonOptions.active = false;
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error('Error', error.ErrorMessage);
          this.spinnerButtonOptions.active = false;
        }
      );
  }
  getContextMenuItems = (params: any) => {
    if (params.column.colDef.headerName !== 'Parameter' &&  params.column.colDef.headerName !== 'Parameter Group'){
      let result: any;
      if (this.IsWrite) {
        result = [
          {
            name: 'Specifiction Limits',
            action: () => {
              this.parameterId = params.node.data.parameterId;
              this.openModal(params);
            },
          },
          {
            name: 'Defect - Failure Mode Details',
            action: () => {
              this.parameterId = params.node.data.parameterId;
              this.openDefecct(params);
            },
          },
        ];
      }
      return result;
    }
    else{
      return [];
    }
  };

  isCharNumeric(charStr: any): boolean {
    return !!/\d/.test(charStr);
  }
  GetGroupTypeList(): any {
    const key = {
      puId: this.unitId,
      vgTypeId: 1,
    };
    this.qualityParameterService.getVariableGroupbyUnit(key).subscribe(
      (data: any) => {
        this.groupTypeList = data.filter(
          (c: any) => c.groupTypeName !== 'None'
        );
        // this.unitDetailForm.get('groupType')?.s
        this.unitDetailForm
          .get('groupType')
          ?.setValue(this.groupTypeList[0].parameterGroupTypeId);
        // tslint:disable-next-line:max-line-length
        this.qualityGroupType = this.groupTypeList.find(
          (c: any) =>
            c.parameterGroupTypeId === this.unitDetailForm.value.groupType
        )?.groupTypeName;
        this.EventMode();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  EventMode(): void {
    debugger;
    // tslint:disable-next-line:max-line-length
    const val = this.groupTypeList.find(
      (c: any) => c.parameterGroupTypeId === this.unitDetailForm.value.groupType
    )?.groupTypeName;
    this.qualityGroupType = val;
    if (val === 'Event') {
      this.isEventMode = true;
      this.isTimeMode = false;
      this.getIntervalType();
    } else if (val === 'Time') {
      this.isEventMode = false;
      this.isTimeMode = true;
      this.getParameterInterval();
    } else if (val === 'Manual') {
      this.isEventMode = false;
      this.isTimeMode = false;
      this.getParamterGroupList();
      this.onToProcessDate();
    }
  }

  manualInput(): void {
    const data = {
      plantId: this.plantId,
      // lotcode: null,
      eventDateTime: new Date(),
      eventTypeId: 3,
      puId: this.unitId,
      // processOrder: this.currentprocessOrder,
      // processOrderId: this.PPID,
      userId: JSON.parse(localStorage.user).UserId,
      vgTypeId: 1,
    };
    this.sfdService.insertSfdcEvent(data).then(
      (response) => {
        this.toaster.success('Success', 'Manual Event Added Successfully');
        if (this.qualityGroupType === 'Manual') {
          const selected = {
            startDate: this.unitDetailForm.value.datePicker.startDate,
            endDate: new Date(),
          };
          this.unitDetailForm.get('datePicker')?.setValue(selected);
          this.onToProcessDate();
        }
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  expand() {
    this.isCardExpanded = !this.isCardExpanded;
    this.Exdpandhide = false;
    this.Collapsehide = true;
    this.Isexpandcontentshow = true;
    this.Isexpandcontenthide = false;
    this.Isexpandcontentbuttonshow = true;
  }
  close() {
    this.isCardExpanded = !this.isCardExpanded;
    this.Exdpandhide = true;
    this.Collapsehide = false;
    this.Isexpandcontentshow = true;
    this.Isexpandcontenthide = true;
    this.Isexpandcontentbuttonshow = false;
  }
  choosedDate(event: any) {
    // console.log({ event });
  }
  saveBool(qualityBoolVal: any): void {
    debugger;
    const itemArr: any[] = [];
    this.gridApi.forEachLeafNode((node: any) => {
      itemArr.push(node);
    });
    const filterIndex: any = itemArr.filter(
      (c) => c.rowIndex === this.boolRowIndex
    );
    const rowNode = this.gridApi.getRowNode(filterIndex[0].id);
    rowNode.setDataValue(this.boolColId, qualityBoolVal);
    this.dialog.closeAll();
  }
  filterRecursive(filterText: string, array: any[], property: string): any {
    let filteredData;

    // make a copy of the data so we don't mutate the original
    function copy(o: any): any {
      return Object.assign({}, o);
    }

    // has string
    if (filterText) {
      // need the string to match the property value
      filterText = filterText.toLowerCase();
      // copy obj so we don't mutate it and filter
      filteredData = array.map(copy).filter(function x(y) {
        if (y[property].toLowerCase().includes(filterText)) {
          return true;
        }
        // if children match
        if (y.children) {
          return (y.children = y.children.map(copy).filter(x)).length;
        }
      });
      // no string, return whole array
    } else {
      filteredData = array;
    }

    return filteredData;
  }
  filterTree(filterText: any): any {
    // use filter input text, return filtered TREE_DATA, use the 'name' object value
    this.dataSource.data = this.filterRecursive(
      filterText,
      this.TREE_DATA,
      'text'
    );
  }
  applyFilter(event: any): void {
    const filterText = event.target.value;
    this.filterTree(filterText);
    // show / hide based on state of filter string
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }
  reOpenCalendar(): any {
    const self = this;
    setTimeout(() => {
      self.picker.open();
    }, 50);
  }
  excelExport(): void {
    const params = {
      fileName: 'PC Excel' + new Date(),
    };
    this.gridOptions.api.exportDataAsExcel(params);
    // this.gridApi.exportDataAsExcel();
  }

  pdfExport() {
    this.selectedColumns = [];
    this.unitDetailForm.get('pdfcolumn')?.setValue('');
    this.dialog.open(this.pdfModel, {
      width: '50vw !important',
      height: '65vh',
      minWidth: '50vw',
    });
  }

  downloadPDF() {
    const columnsToExport = this.getColumnsToExport();

    const widths = this.getExportedColumnsWidths(columnsToExport);

    const rowsToExport = this.getRowsToExport(columnsToExport);

    const body = [columnsToExport, ...rowsToExport];

    const fillColor = (rowIndex, node, columnIndex) => {
      if (rowIndex < node.table.headerRows) {
        return '#f8f8f8';
      }
      return rowIndex % 2 === 0 ? '#fcfcfc' : '#ffffff';
    };

    const hLineWidth = (i, node) =>
      i === 0 || i === node.table.body.length ? 1 : 1;

    const vLineWidth = (i, node) =>
      i === 0 || i === node.table.widths.length ? 1 : 0;

    const hLineColor = (i, node) =>
      i === 0 || i === node.table.body.length ? '#dde2eb' : '#babfc7';

    const vLineColor = (i, node) =>
      i === 0 || i === node.table.widths.length ? '#dde2eb' : '#babfc7';

    const docDefintiion = {
      pageOrientation: 'landscape',
      content: [
        {
          style: 'myTable',
          table: {
            widths,
            body,
          },
          layout: {
            fillColor,
          },
        },
      ],
      images: {
        'ag-grid-logo': 'Good',
      },
      styles: {
        myTable: {
          margin: [0, 0, 0, 0],
        },
        tableHeader: {
          bold: true,
          margin: [0, 25 / 3, 0, 0],
        },
        tableCell: {},
      },
    };
    pdfMake.createPdf(docDefintiion).download('ProcessControl.pdf');
    this.dialog.closeAll();
    this.unitDetailForm.get('pdfcolumn')?.setValue('');
    this.selectedColumns = [];
  }

  columnSelect() {
    let column = [];
    this.selectedColumns = [];
    column = this.unitDetailForm.get('pdfcolumn')?.value;
    for (let i = 0; i < column.length; i++) {
      this.selectedColumns.push(column[i].headerName);
    }
    this.selectedColumns = this.selectedColumns.toString();
  }

  getRowsToExport(columnsToExport) {
    let rowsToExport = [];

    this.gridOptions.api.forEachNodeAfterFilterAndSort((node) => {
      let rowToExport = columnsToExport.map(({ colId }) => {
        let cellValue = this.gridOptions.api.getValue(colId, node);
        let tableCell = this.createTableCell(cellValue, colId);
        return tableCell;
      });
      rowsToExport.push(rowToExport);
    });

    return rowsToExport;
  }

  createTableCell(cellValue, colId) {
    const tableCell = {
      text: cellValue !== undefined ? cellValue : '',
      // noWrap: PDF_PAGE_ORITENTATION === "landscape",
      style: 'tableCell',
    };

    const pdfExportOptions = this.getPdfExportOptions(colId);

    if (pdfExportOptions) {
      const { styles, createURL } = pdfExportOptions;

      // if (PDF_WITH_CELL_FORMATTING && styles) {
      //   Object.entries(styles).forEach(
      //     ([key, value]) => (tableCell[key] = value)
      //   );
      // }

      // if (PDF_WITH_COLUMNS_AS_LINKS && createURL) {
      //   tableCell['link'] = createURL(cellValue);
      //   tableCell['color'] = 'blue';
      //   tableCell['decoration'] = 'underline';
      // }
    }

    return tableCell;
  }

  getExportedColumnsWidths(columnsToExport) {
    return columnsToExport.map(() => 100 / columnsToExport.length + '%');
  }

  getColumnsToExport() {
    let columnsToExport = [];
    let column;
    this.gridOptions.columnApi.getAllDisplayedColumns().forEach((col) => {
      const select = this.selectedColumns.split(',');
      if (col.userProvidedColDef) {
        column = select.find(
          (x: any) => x == col.userProvidedColDef.headerName
        );
        if (column) {
          let pdfExportOptions = this.getPdfExportOptions(col.getColId());
          if (pdfExportOptions && pdfExportOptions.skipColumn) {
            return;
          }
          let headerCell = this.createHeaderCell(col);
          columnsToExport.push(headerCell);
        }
      } else {
        let pdfExportOptions = this.getPdfExportOptions(col.getColId());
        if (pdfExportOptions && pdfExportOptions.skipColumn) {
          return;
        }
        let headerCell = this.createHeaderCell(col);
        columnsToExport.push(headerCell);
      }
    });

    return columnsToExport;
  }

  createHeaderCell(col) {
    let headerCell = {
      text: '',
      colSpan: '',
      colId: '',
    };

    let isColGroup = col.hasOwnProperty('children');

    if (isColGroup) {
      headerCell.text = col.originalColumnGroup.colGroupDef.headerName;
      headerCell.colSpan = col.children.length;
      headerCell.colId = col.groupId;
    } else {
      let headerName = col.colDef.headerName;

      if (col.sort) {
        headerName += ` (${col.sort})`;
      }
      if (col.filterActive) {
        headerName += ` [FILTERING]`;
      }

      headerCell.text = headerName;
      headerCell.colId = col.getColId();
    }

    headerCell['style'] = 'tableHeader';

    return headerCell;
  }

  getPdfExportOptions(colId) {
    let col = this.gridOptions.columnApi.getColumn(colId);
    return col.colDef.pdfExportOptions;
  }

  csvExport(): void {
    const params = {
      fileName: 'PC CSV ' + new Date(),
    };
    this.gridOptions.api.exportDataAsCsv(params);
  }
  // onChange(result: Date[]): void {
  //   this.unitDetailForm.get('fromProcessDate')?.setValue(result[0]);
  //   this.unitDetailForm.get('toProcessDate')?.setValue(result[1]);
  // }
}
const tooltipValueGetter = (params: any) => ({ value: params.value });
