<p-toast [style]="{marginTop: '80px'}"></p-toast>
<section class="home-content" dir="{{languageDir}}">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <mat-card>
    <div style="padding:10px;background-color: #0F0FD1;">
      <div class="p-grid p-fluid">
        <div class="p-col-8">
          <mat-card-title style="color: white;margin-top: 8px;">{{langObj ? langObj?.KPIGapAnalysis : ('KPIGapAnalysis'
            | translate)}}</mat-card-title>
        </div>
        <div class="p-col-4">
          <form [formGroup]="searchPlantForm">
            <div class="p-grid p-fluid">
              <div class="p-col-4 p-md-6">
                <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                              'is-invalid':
                                searchPlantForm.get('group')?.errors &&
                                searchPlantForm.get('group')?.touched
                            }" [options]="groupList" formControlName="group"
                  placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}" optionLabel="TypeName"
                  [showClear]="true"></p-dropdown>
                <p class="invalid-feedback" *ngIf="
                              searchPlantForm.get('group')?.touched &&
                              searchPlantForm.get('group')?.hasError('required')
                            ">
                  * {{langObj ? langObj?.pleaseselectgroup : ('pleaseselectgroup' | translate)}}
                </p>
              </div>
              <div class="p-col-8 p-md-6">
                <p-dropdown [filter]="true" (onChange)="getTreeData($event)" [ngClass]="{
                                'is-invalid':
                                searchPlantForm.get('plant')?.errors &&
                                searchPlantForm.get('plant')?.touched
                              }" [options]="searchPlants" formControlName="plant"
                  placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}" optionLabel="DeptDesc"
                  [showClear]="true"></p-dropdown>
                <p class="invalid-feedback" *ngIf="
                             searchPlantForm.get('plant')?.touched &&
                             searchPlantForm.get('plant')?.hasError('required')
                            ">
                  * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <mat-card-content>
      <div class="p-grid p-fluid">

        <div class="p-col-12 p-md-6 p-lg-4">
          <label>{{langObj ? langObj?.Frequency : ('Frequency' | translate)}}</label>
          <p-dropdown [options]="frequencies" (onChange)="onFrequencyChange($event)" [(ngModel)]="kpifrequencies"
            name="kpifrequency" placeholder="{{langObj ? langObj?.SelectFrequency : ('SelectFrequency' | translate)}}"
            optionLabel="name" [showClear]="false" scrollHeight="300px">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-md-6 p-lg-4">
          <label>{{langObj ? langObj?.KPIs : ('KPIs' | translate)}}</label>
          <p-dropdown [options]="kpis" [filter]="true" [(ngModel)]="kpiId" name="kpiId"
            placeholder="{{langObj ? langObj?.SelectKPI : ('SelectKPI' | translate)}}" optionLabel="fullCodeName"
            (onChange)="onKPiChange($event)"></p-dropdown>
        </div>
        <div class="p-col-12 p-md-6 p-lg-4">
          <label>{{langObj ? langObj?.Date : ('Date' | translate)}}</label>
          <p-calendar [locale]="calendar_en" dateFormat="dd/mm/yy" [(ngModel)]="visionDate" [monthNavigator]="true"
            [yearNavigator]="true" yearRange="2000:2030" inputId="navigators"
            (onSelect)="onDateChange(visionDate)"></p-calendar>
        </div>
      </div>
      <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
        <p style="font-size: 20px; color: #050a4f">{{langObj ? langObj?.FetchingTreeData : ('FetchingTreeData' |
          translate)}}</p>
      </ngx-spinner>
      <p-organizationChart [value]="data1" selectionMode="single" (onNodeSelect)="onNodeSelect($event)"
        styleClass="company" [style]="{'overflow': 'scroll','height': '500px'}">
        <ng-template let-node pTemplate="vision">
          <p-tieredMenu #menu [model]="visionMenuItems" [popup]="true" appendTo="body"></p-tieredMenu>
          <div class="node-header-vision p-corner-top">{{node.label}} | {{node.plant}}</div>
          <div class="node-content-vision">
            <div>{{node.quantify}}
            </div>
          </div>
        </ng-template>
        <ng-template let-node pTemplate="strategickpi">
          <p-tieredMenu #menu [model]="stratgicKpiItems" [popup]="true" appendTo="body"></p-tieredMenu>
          <div class="node-header-strategickpi p-corner-top">{{node.label}} | {{node.plant}}</div>
          <div class="node-content-strategickpi">
            <div>{{node.quantify}}
            </div>
          </div>

        </ng-template>
        <ng-template let-node pTemplate="kpi">
          <p-tieredMenu #menu [model]="kpiItems" [popup]="true" appendTo="body"></p-tieredMenu>
          <div class="p-corner-top" [style.background-color]="node.frequencyColor"
            style="color: #000000; height: 40px; padding-top: 10px;">{{node.label}}</div>
          <div class="node-content-kpi">
            <div style="color: #ffffff;"><span style="font-size: 10px;float: left;color: #ffffff;">{{langObj ?
                langObj?.TGTValue : ('TGTValue' | translate)}}<br />{{node.targetValue}}</span>
              {{node.frequency}} | {{node.uom}}<span style="font-size: 10px;float: right;color: #ffffff;">{{langObj ?
                langObj?.ACHValue : ('ACHValue' | translate)}}<br />{{node.achivementValue}}</span>
              <i style="position: absolute;right: 5px;bottom: 2px;color: #ffffff;" class="pi pi-ellipsis-h"
                (click)="menu.toggle($event)"></i>
            </div>

          </div>
        </ng-template>
      </p-organizationChart>
    </mat-card-content>


    <div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{'active': visibleGapAnalysis}">
      <div class="sidepanel-close" (click)="closeFrequencyModel()"></div>
      <div class="model-content">
        <div class="model-top">
          <h2 class="model-title">{{langObj ? langObj?.AddActionAnalysisInformation : ('AddActionAnalysisInformation' |
            translate)}}</h2>
          <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeFrequencyModel()">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
                fill="black" />
            </svg>
          </button>
        </div>
        <div class="model-body">
          <form [formGroup]="LossAnalysisForm" (ngSubmit)="onSubmit()">
            <div class="p-grid p-fluid">
              <div class="p-col">
                <mat-form-field appearance="fill" class="example-form-field">
                  <mat-label>{{langObj ? langObj?.AnalysisDate : ('AnalysisDate' | translate)}}</mat-label>
                  <input matInput [matDatepicker]="picker" placeholder="Analysis Date"
                    formControlName="lossAnalysisDate">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="p-col">
                <label class="required">{{langObj ? langObj?.KPIGapReason : ('KPIGapReason' | translate)}}</label>
                <p-dropdown [ngClass]="{
                          'is-invalid':
                          LossAnalysisForm.get('reasonname')?.errors &&
                          LossAnalysisForm.get('reasonname')?.touched
                      }" [options]="reasonslist" formControlName="reasonname" optionLabel="name"
                  placeholder="Please Select">
                </p-dropdown>
              </div>
              <div class="p-col">
                <label>{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}</label>
                <input type="text" pInputText formControlName="remarks" placeholder="Remarks">
              </div>
              <div class="p-col">
                <label class="required">{{langObj ? langObj?.AnalyticalTool : ('AnalyticalTool' | translate)}}</label>
                <p-dropdown [ngClass]="{
                          'is-invalid':
                          LossAnalysisForm.get('toolname')?.errors &&
                          LossAnalysisForm.get('toolname')?.touched
                      }" [options]="toolslist" (onChange)="onToolChange($event)" formControlName="toolname"
                  optionLabel="name"
                  placeholder="{{langObj ? langObj?.PleaseSelect : ('PleaseSelect' | translate)}}"></p-dropdown>
              </div>

              <div class="p-col">
                <div mt-3><label></label></div>
                <a href="{{toolPath}}{{toolName}}" mat-flat-button color="accent" type="button" class="btn-accent"
                  [download]
                  *ngIf="toolPath !== undefined && toolName !== undefined && toolPath !== null && toolName !== null && toolName !== '' && !gapAnalysisDetail">
                  {{langObj ? langObj?.ExecuteTool : ('ExecuteTool' | translate)}}</a>
              </div>

              <div class="p-col-12">
                <label class="required">{{langObj ? langObj?.CauseClassification4M5M6M : ('Cause Classification
                  (4M/5M/6M)' | translate)}}</label>
                <p-dropdown [ngClass]="{
                          'is-invalid':
                          LossAnalysisForm.get('fourm')?.errors &&
                          LossAnalysisForm.get('fourm')?.touched
                      }" [options]="fourMList" formControlName="fourm" optionLabel="name"
                  placeholder=" {{langObj ? langObj?.PleaseSelect : ('PleaseSelect' | translate)}}">
                </p-dropdown>
              </div>

              <div class="p-col">
                <label> {{langObj ? langObj?.RootCause : ('RootCause' | translate)}}</label>
                <input type="text" formControlName="rootcause" pInputText placeholder="Root Cause">
              </div>
            </div>
            <table id="tab1" class="basic-table plant-model-table">

              <thead>
                <tr>
                  <th class="text-center align-middle"> {{langObj ? langObj?.ActionPerform : ('ActionPerform' |
                    translate)}}</th>
                  <th class="text-center align-middle"> {{langObj ? langObj?.TargetDate : ('TargetDate' | translate)}}
                  </th>
                  <th class="text-center align-middle"> {{langObj ? langObj?.KPIResponsible : ('KPIResponsible' |
                    translate)}}</th>
                  <th class="text-center align-middle"> {{langObj ? langObj?.ActionAccountable : ('ActionAccountable' |
                    translate)}}</th>
                  <th class="text-center align-middle">
                    <button pButton label="" type="button" icon="pi pi-plus" class="p-button-raised p-button-rounded "
                      (click)="addFieldValue()" [disabled]="gapAnalysisDetail"></button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let field of actionlist; let i = index">

                  <td>
                    <mat-form-field appearance="fill" class="field-fill">
                      <input matInput value="{{field.action}}" placeholder="Action" class="form-control"
                        (change)="fielArrayValueChange($event, i)" type="text" name="{{field.action}}" />
                    </mat-form-field>
                  </td>
                  <td>


                    <mat-form-field appearance="fill" class="example-form-field">
                      <input matInput [matDatepicker]="picker" placeholder="Input & change events"
                        (dateInput)="addEvent('input', $event, i)" value="{{field.targetDate}}">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                  </td>
                  <td>
                    <mat-form-field appearance="fill" class="field-fill">
                      <input matInput [disabled]="true" class="form-control" value="{{resposibleUsers}}" type="text" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="fill" class="field-fill">
                      <mat-select multiple (selectionChange)="accountbleChange($event, i)">
                        <mat-select-filter [placeholder]="'Search'" [color]="'#F9FAFF'" [array]="raci"
                          (filteredReturn)="filterdraci=$event" [displayMember]="'fullName'">
                        </mat-select-filter>
                        <mat-option *ngFor="let field of raci" value="{{field.accountableUser}}"
                          [value]="field.EmpInfo_Id" [class.hide]="!isFiltered(field)">{{field.fullName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td>
                    <button pButton label="" icon="pi pi-times" class="p-button-raised p-button-rounded p-button-danger"
                      (click)="deleteFieldValue(i)" [disabled]="gapAnalysisDetail"></button>
                  </td>


                </tr>

              </tbody>
            </table>
            <div class="p-grid p-fluid">
              <div class="p-col-4">
                <label>{{langObj ? langObj?.RecommendedAnalysisToolsFromKPIArtificialIntelligence :
                  ('RecommendedAnalysisToolsFromKPIArtificialIntelligence' | translate)}}</label>


                <p-dropdown [options]="toolslist" optionLabel="name" placeholder="Please Select"></p-dropdown>
              </div>
            </div>
            <div class="model-form-actions">
              <button type="button" mat-flat-button class="btn-cancel" (click)="closeFrequencyModel()"> {{langObj ?
                langObj?.Cancel : ('Cancel' | translate)}}
              </button>
              <button mat-flat-button color="accent" type="submit"
                [disabled]="!LossAnalysisForm.valid || gapAnalysisDetail" class="btn-accent"> {{langObj ? langObj?.Add :
                ('Add' | translate)}} </button>
            </div>
          </form>
        </div>
      </div>
    </div>



    <!-- <div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{'active': visibleGapAnalysis}">     
        <div class="sidepanel-close" (click)="closeFrequencyModel()"></div>
        <div class="model-content">
          <div class="model-top">
            <h2 class="model-title">Analysis Information</h2>
            <button mat-icon-button class="model-close-btn" (click)="closeFrequencyModel()" aria-label="Close model">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
                  fill="black" />
              </svg>
            </button>
          </div>


          <div class="model-body">
            <form [formGroup]="LossAnalysisForm" >
              <div class="p-grid p-fluid">   
                  <div class="p-col">     
                    <mat-form-field appearance="fill" class="example-form-field">
                      <input matInput [matDatepicker]="picker" placeholder="Analysis Date"
                      formControlName="lossAnalysisDate">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>     
                  </div> 
                  
                   <div class="p-col">
                      <label >Reason</label>
                      <p-dropdown [ngClass] = "{
                          'is-invalid':
                          LossAnalysisForm.get('reasonname')?.errors &&
                          LossAnalysisForm.get('reasonname')?.touched
                      }" [options]="reasonslist" formControlName="reasonname" optionLabel="name" placeholder="Please Select">
                      </p-dropdown>
                  </div> 

                  
                  <div class="p-col">
                      <label>Remarks</label>
                      <input type="text" pInputText formControlName="remarks" placeholder="Remarks">         
                  </div>
                  <div class="p-col">
                      <label class="required">Tool</label>
                      <p-dropdown [ngClass] = "{
                          'is-invalid':
                          LossAnalysisForm.get('toolname')?.errors &&
                          LossAnalysisForm.get('toolname')?.touched
                      }"
                      [options]="toolslist" (onChange)="onToolChange($event)" formControlName="toolname" optionLabel="name" placeholder="Please Select"></p-dropdown>
                  </div>
        
                  <div class="p-col">
                   <div mt-3><label></label></div>
                      <a href="{{toolPath}}{{toolName}}" mat-flat-button color="accent" type="button" class="btn-accent" [download] *ngIf="toolPath !== undefined && toolName !== undefined && toolPath !== null && toolName !== null && toolName !== ''"> Execute Tool</a>
                  </div>
        
                  <div class="p-col-12">
                      <label class="required">Cause Classification (4M/5M/6M)</label>
                      <p-dropdown [ngClass] = "{
                          'is-invalid':
                          LossAnalysisForm.get('fourm')?.errors &&
                          LossAnalysisForm.get('fourm')?.touched
                      }" [options]="fourMList" formControlName="fourm" optionLabel="name" placeholder="Please Select">
                      </p-dropdown>
                  </div>
        
                  <div class="p-col">
                      <label>Root Cause</label>
                      <input type="text" formControlName="rootcause" pInputText placeholder="Root Cause">         
                  </div>
              </div>
              <table id="tab1"  class="basic-table plant-model-table">
      
                <thead>
                    <tr>
                        <th class="text-center align-middle">Action Perform</th>
                        <th class="text-center align-middle">Target Date</th>
                    
                        <th class="text-center align-middle">
                            <button pButton label="" type="button" icon="pi pi-plus" class="p-button-raised p-button-rounded " 
                            (click)="addFieldValue()"></button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let field of actionlist; let i = index">                   
      
                        <td>
                          <mat-form-field appearance="fill" class="field-fill">
                            <input matInput value="{{field.action}}" placeholder="Action" class="form-control" (change)="fielArrayValueChange($event, i)"  type="text" name="{{field.action}}" />         
                          </mat-form-field>
                          </td>
                        <td>
                         
      
                          <mat-form-field appearance="fill" class="example-form-field">
                            <input matInput [matDatepicker]="picker" placeholder="Input & change events"
                                   (dateInput)="addEvent('input', $event, i)" value="{{field.targetDate}}" >
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                          
                          <div class="example-events">
                            <div *ngFor="let e of events">{{e}}</div>
                          </div>
                          
                        </td>
                        <td>
                            <button pButton label="" icon="pi pi-times"
                                class="p-button-raised p-button-rounded p-button-danger"
                                (click)="deleteFieldValue(i)"></button>
                        </td>
                        
                       
                    </tr>
      
                </tbody>
            </table>
            <div class="p-grid p-fluid">
              <div class="p-col-4">
              <label>Recommended Analysis Tools From KPI Artificial Intelligence</label>
             
                  
                  <p-dropdown [options]="toolslist" optionLabel="name" placeholder="Please Select"></p-dropdown>
              </div>
          </div>
              <div class="model-form-actions">
                  
                <button type="button" mat-flat-button (click)="visibleGapAnalysis = false" class="btn-cancel" ></button>
               
                <button mat-flat-button color="accent" type="submit" [disabled]="!LossAnalysisForm.valid" class="btn-accent">Add </button>
              </div>
            </form>
          </div>
        </div>
      </div> -->






  </mat-card>
</section>