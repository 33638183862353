import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-KPISplitView',
  templateUrl: './KPISplitView.component.html',
  styleUrls: ['./KPISplitView.component.scss']
})
export class KPISplitViewComponent implements OnInit {
  breadcrumList!: any;
  IsAccess!: any;
  IsWrite!: any;
  moduleId!: any;
  pageId!: any;
  langObj: any = {};
  isExpanded: boolean = false;
  isRotate: boolean = false;
  grids: any[] = new Array(2);
  constructor(private el: ElementRef,

    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,

    public router: Router,
    private translater: TranslaterService,
  ) { }

  ngOnInit() {
    this.GetBreadcrumList();
    this.GetMenuAccess();

  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        // console.log(data.BreadcrumList);
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;

    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  toggleView() {
    this.isExpanded = !this.isExpanded;
  }
  rotateView() {
    this.isRotate = !this.isRotate;
  }
  onAddGrid() {
    this.grids.push({});
  }
  onDeleteGrid(index: any) {
    this.grids.splice(index, 1);
  }
}
