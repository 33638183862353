import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpmsGlassService } from 'src/app/services/opms-glass.service';

@Component({
  selector: 'app-opms-reports',
  templateUrl: './opms-reports.component.html',
  styleUrls: ['./opms-reports.component.scss'],
})
export class OpmsReportsComponent implements OnInit {
  opmsreportForm!: FormGroup;
  sizewisereport = false;
  thicknessreport = false;
  daywisereport = false;
  crewwisereport = false;
  shiftwisereport = false;
  sizecolumnDefs: any;
  sizerowData: any[] = [];
  thicknesscolumnDefs: any;
  thicknessrowData: any[] = [];
  daycolumnDefs: any;
  dayrowData: any[] = [];
  crewcolumnDefs: any;
  crewrowData: any[] = [];
  shiftwisecolumnDefs: any;
  selectedreport: any;
  shiftwiserowData: any[] = [];
  sizeWiseReportList: any[] = [];
  reports = [
    { value: 1, viewValue: 'Size Wise Report' },
    { value: 2, viewValue: 'Thickness Wise Report' },
    { value: 3, viewValue: 'Day Wise Report' },
    { value: 4, viewValue: 'Crew Wise Report' },
    { value: 5, viewValue: 'Shift Wise Report' },
    { value: 6, viewValue: 'Shift Detail Report' },
  ];
  gridApiSize: any;
  gridColApiSize: any;
  gridApiThickness: any;
  gridColApiThickness: any;
  gridApiDay: any;
  gridColApiDay: any;
  gridApiCrew: any;
  gridColApiCrew: any;
  gridApiShift: any;
  sizeexcelreport: any[] = [];
  thicknessexcelreport: any[] = [];
  dayexcelreport: any[] = [];
  crewexcelreport: any[] = [];
  shiftexcelreport: any[] = [];
  shiftDetailcolumnDefs: any[] = [];
  shiftDetailreport = false;
  shiftdetailexcelreport: any[] = [];
  shiftDetailrowData: any[] = [];
  gridApiShiftDetail: any;
  constructor(
    private opmsGlassservice: OpmsGlassService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.sizecolumnDefs = [
      {
        field: 'Thickness',
        headerName: 'Thickness',
        resizable: true,
        filter: true,
      },
      {
        field: 'Quality',
        headerName: 'Quality',
        resizable: true,
        filter: true,
      },
      {
        field: 'Stacker',
        headerName: 'QStacker',
        resizable: true,
        filter: true,
      },
      {
        field: 'Packing',
        headerName: 'Packing',
        resizable: true,
        filter: true,
      },
      {
        field: 'Length',
        headerName: 'Length',
        resizable: true,
        filter: true,
      },
      {
        field: 'Width',
        headerName: 'Width',
        resizable: true,
        filter: true,
      },
      {
        field: 'Lites',
        headerName: 'Lites',
        resizable: true,
        filter: true,
      },
      {
        field: 'Boxes',
        headerName: 'Boxes',
        resizable: true,
        filter: true,
      },
      {
        field: 'TotalPacked',
        headerName: 'Tonnage',
        resizable: true,
        filter: true,
      },
      {
        field: 'BoxWeight',
        headerName: 'Box Weight',
        resizable: true,
        filter: true,
      },
    ];

    this.thicknesscolumnDefs = [
      {
        field: 'Time',
        headerName: 'Production Time',
        resizable: true,
        filter: true,
      },
      {
        field: 'Thickness',
        headerName: 'Thickness',
        resizable: true,
        filter: true,
      },
      {
        field: 'Pull',
        headerName: 'Pull',
        resizable: true,
        filter: true,
      },
      {
        field: 'Pack',
        headerName: 'Pack',
        resizable: true,
        filter: true,
      },
      {
        field: 'AT',
        headerName: 'AT',
        resizable: true,
        filter: true,
      },
      {
        field: 'GL',
        headerName: 'GL',
        resizable: true,
        filter: true,
      },
      {
        field: 'AC',
        headerName: 'AC',
        resizable: true,
        filter: true,
      },
      {
        field: 'GA',
        headerName: 'GA',
        resizable: true,
        filter: true,
      },
      {
        field: 'GE',
        headerName: 'GE',
        resizable: true,
        filter: true,
      },
      {
        field: 'MR',
        headerName: 'MR',
        resizable: true,
        filter: true,
      },
      {
        field: 'GF',
        headerName: 'GF',
        resizable: true,
        filter: true,
      },
      {
        field: 'JV',
        headerName: 'JV',
        resizable: true,
        filter: true,
      },
      {
        field: 'Lami',
        headerName: 'Lami',
        resizable: true,
        filter: true,
      },
      {
        field: 'MR_3',
        headerName: 'MR_3',
        resizable: true,
        filter: true,
      },
      {
        field: 'Yield',
        headerName: 'Yield',
        resizable: true,
        filter: true,
      },
      {
        field: 'WIP',
        headerName: 'WIP',
        resizable: true,
        filter: true,
      },
      {
        field: 'WIP_MT',
        headerName: 'WIP_MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'Loss',
        headerName: 'Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TotalLoss',
        headerName: 'Total Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TotalLossMT',
        headerName: 'Total Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'Trimming',
        headerName: 'Trimming %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TrimmingMT',
        headerName: 'Trimming MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'SamplingLoss',
        headerName: 'Sampling Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'SamplingLossMT',
        headerName: 'Sampling Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'CuttingLoss',
        headerName: 'Cutting Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'cuttingLossMT',
        headerName: 'Cutting Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'BreakageLoss',
        headerName: 'Breakage Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'BreakageLossMT',
        headerName: 'Breakage Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ChangeOverLoss',
        headerName: 'Change Over Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ChangeOverLossMT',
        headerName: 'Chnage Over Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'PrefLoss',
        headerName: 'Pref Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'PrefLossMT',
        headerName: 'Pref Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'FloatLoss',
        headerName: 'Float Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'FloatLossMT',
        headerName: 'Float Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'EquipmentLoss',
        headerName: 'Equipment Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'EquipmentLossMT',
        headerName: 'Equipment Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'SaturationLoss',
        headerName: 'Saturation Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'SaturationLossMT',
        headerName: 'Saturation Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProfileLoss',
        headerName: 'Profile Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProfileLossMT',
        headerName: 'Profile Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'DefectLoss',
        headerName: 'Defect Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'DefectLossMT',
        headerName: 'Defect Loss MT',
        resizable: true,
        filter: true,
      },
      // {
      //   field: 'holdpercent',
      //   headerName: 'Hold %',
      //   resizable: true,
      //   filter: true,
      // },
      // {
      //   field: 'holdmt',
      //   headerName: 'Hold MT',
      //   resizable: true,
      //   filter: true,
      // },
      {
        field: 'HandlingLoss',
        headerName: 'Handling Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'HandlingLossMT',
        headerName: 'Handling Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ReworkLoss',
        headerName: 'Rework Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ReworkLossMT',
        headerName: 'Rework Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'LineLoss',
        headerName: 'Line Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'LineLossMT',
        headerName: 'Line Loss MT',
        resizable: true,
        filter: true,
      },
    ];

    this.daycolumnDefs = [
      {
        field: 'Date',
        headerName: 'Date',
        resizable: true,
        filter: true, 
        cellStyle: (params: any) => params.value == 'Cummulative' ? { color: 'white', background: '#4040f2'} : { color: 'black' },
        valueFormatter: this.dateFormatter,
      },
      {
        field: 'ProductionTime',
        headerName: 'Production Time',
        resizable: true,
        filter: true,
      },
      {
        field: 'Pull',
        headerName: 'Pull',
        resizable: true,
        filter: true,
      }, 
      {
        field: 'Pack',
        headerName: 'Pack',
        resizable: true,
        filter: true,
      },
      {
        field: 'ChangeOverTime',
        headerName: 'Change Over Time',
        resizable: true,
        filter: true,
      },
      {
        field: 'AT',
        headerName: 'AT',
        resizable: true,
        filter: true,
      },
      {
        field: 'GL',
        headerName: 'GL',
        resizable: true,
        filter: true,
      },
      {
        field: 'AC',
        headerName: 'AC',
        resizable: true,
        filter: true,
      },
      {
        field: 'GA',
        headerName: 'GA',
        resizable: true,
        filter: true,
      },
      {
        field: 'GE',
        headerName: 'GE',
        resizable: true,
        filter: true,
      },
      {
        field: 'MR',
        headerName: 'MR',
        resizable: true,
        filter: true,
      },
      {
        field: 'GF',
        headerName: 'GF',
        resizable: true,
        filter: true,
      },
      {
        field: 'JV',
        headerName: 'JV',
        resizable: true,
        filter: true,
      },
      {
        field: 'Lami',
        headerName: 'Lami',
        resizable: true,
        filter: true,
      },
      {
        field: 'MR_3',
        headerName: 'MR_3',
        resizable: true,
        filter: true,
      },
      {
        field: 'Yield',
        headerName: 'Yield',
        resizable: true,
        filter: true, 
      },
      {
        field: 'WIP',
        headerName: 'WIP',
        resizable: true,
        filter: true,
      },
      {
        field: 'WIP_MT',
        headerName: 'WIP_MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'Loss',
        headerName: 'Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TotalLoss',
        headerName: 'Total Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TotalLossMT',
        headerName: 'Total Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'Trimming',
        headerName: 'Trimming %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TrimmingMT',
        headerName: 'Trimming MT',
        resizable: true,
        filter: true,
      }, 
      {
        field: 'SamplingLoss',
        headerName: 'Sampling Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'SamplingLossMT',
        headerName: 'Sampling Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'CuttingLoss',
        headerName: 'Cutting Loss %',
        resizable: true,
        filter: true, 
      },
      {
        field: 'cuttingLossMT',
        headerName: 'Cutting Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'BreakageLoss',
        headerName: 'Breakage Loss %',
        resizable: true,
        filter: true, 
      },
      {
        field: 'BreakageLossMT',
        headerName: 'Breakage Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ChangeOverLoss',
        headerName: 'Change Over Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ChangeOverLossMT',
        headerName: 'Chnage Over Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'PrefLoss',
        headerName: 'Pref Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'PrefLossMT',
        headerName: 'Pref Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'FloatLoss',
        headerName: 'Float Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'FloatLossMT',
        headerName: 'Float Loss MT',
        resizable: true,
        filter: true,
      }, 
      {
        field: 'EquipmentLoss',
        headerName: 'Equipment Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'EquipmentLossMT',
        headerName: 'Equipment Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'SaturationLoss',
        headerName: 'Saturation Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'SaturationLossMT',
        headerName: 'Saturation Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProfileLoss',
        headerName: 'Profile Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProfileLossMT',
        headerName: 'Profile Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'DefectLoss',
        headerName: 'Defect Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'DefectLossMT',
        headerName: 'Defect Loss MT',
        resizable: true,
        filter: true,
      },
      // {
      //   field: 'holdpercent',
      //   headerName: 'Hold %',
      //   resizable: true,
      //   filter: true,
      // },
      // {
      //   field: 'holdmt',
      //   headerName: 'Hold MT',
      //   resizable: true,
      //   filter: true,
      // },
      {
        field: 'HandlingLoss',
        headerName: 'Handling Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'HandlingLossMT',
        headerName: 'Handling Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ReworkLoss',
        headerName: 'Rework Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ReworkLossMT',
        headerName: 'Rework Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'LineLoss',
        headerName: 'Line Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'LineLossMT',
        headerName: 'Line Loss MT',
        resizable: true,
        filter: true,
      },
    ];

    this.crewcolumnDefs = [
      {
        field: 'CrewName',
        headerName: 'Crew',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProductionTime',
        headerName: 'Production Time',
        resizable: true,
        filter: true,
      },
      {
        field: 'Pull',
        headerName: 'Pull',
        resizable: true,
        filter: true,
      },
      {
        field: 'Pack',
        headerName: 'Pack',
        resizable: true,
        filter: true,
      },
      {
        field: 'AT',
        headerName: 'AT',
        resizable: true,
        filter: true,
      },
      {
        field: 'GL',
        headerName: 'GL',
        resizable: true,
        filter: true,
      },
      {
        field: 'AC',
        headerName: 'AC',
        resizable: true,
        filter: true,
      },
      {
        field: 'GA',
        headerName: 'GA',
        resizable: true,
        filter: true,
      },
      {
        field: 'GE',
        headerName: 'GE',
        resizable: true,
        filter: true,
      },
      {
        field: 'MR',
        headerName: 'MR',
        resizable: true,
        filter: true,
      },
      {
        field: 'GF',
        headerName: 'GF',
        resizable: true,
        filter: true,
      },
      {
        field: 'JV',
        headerName: 'JV',
        resizable: true,
        filter: true,
      },
      {
        field: 'Lami',
        headerName: 'Lami',
        resizable: true,
        filter: true,
      },
      {
        field: 'MR_3',
        headerName: 'MR_3',
        resizable: true,
        filter: true,
      },
      {
        field: 'Yield',
        headerName: 'Yield',
        resizable: true,
        filter: true,
      },
      {
        field: 'WIP',
        headerName: 'WIP',
        resizable: true,
        filter: true,
      },
      {
        field: 'WIP_MT',
        headerName: 'WIP_MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'Loss',
        headerName: 'Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TotalLoss',
        headerName: 'Total Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TotalLossMT',
        headerName: 'Total Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'Trimming',
        headerName: 'Trimming %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TrimmingMT',
        headerName: 'Trimming MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'SamplingLoss',
        headerName: 'Sampling Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'SamplingLossMT',
        headerName: 'Sampling Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'CuttingLoss',
        headerName: 'Cutting Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'cuttingLossMT',
        headerName: 'Cutting Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'BreakageLoss',
        headerName: 'Breakage Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'BreakageLossMT',
        headerName: 'Breakage Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ChangeOverLoss',
        headerName: 'Change Over Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ChangeOverLossMT',
        headerName: 'Chnage Over Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'PrefLoss',
        headerName: 'Pref Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'PrefLossMT',
        headerName: 'Pref Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'FloatLoss',
        headerName: 'Float Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'FloatLossMT',
        headerName: 'Float Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'EquipmentLoss',
        headerName: 'Equipment Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'EquipmentLossMT',
        headerName: 'Equipment Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'SaturationLoss',
        headerName: 'Saturation Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'SaturationLossMT',
        headerName: 'Saturation Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProfileLoss',
        headerName: 'Profile Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProfileLossMT',
        headerName: 'Profile Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'DefectLoss',
        headerName: 'Defect Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'DefectLossMT',
        headerName: 'Defect Loss MT',
        resizable: true,
        filter: true,
      },
      // {
      //   field: 'holdpercent',
      //   headerName: 'Hold %',
      //   resizable: true,
      //   filter: true,
      // },
      // {
      //   field: 'holdmt',
      //   headerName: 'Hold MT',
      //   resizable: true,
      //   filter: true,
      // },
      {
        field: 'HandlingLoss',
        headerName: 'Handling Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'HandlingLossMT',
        headerName: 'Handling Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ReworkLoss',
        headerName: 'Rework Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ReworkLossMT',
        headerName: 'Rework Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'LineLoss',
        headerName: 'Line Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'LineLossMT',
        headerName: 'Line Loss MT',
        resizable: true,
        filter: true,
      },
    ];

    this.shiftwisecolumnDefs = [
      {
        field: 'Date',
        headerName: 'Date',
        resizable: true,
        filter: true,
        cellStyle: (params: any) => params.value == 'Cummulative' ? { color: 'white', background: '#4040f2'} : { color: 'black' },
        valueFormatter: this.dateFormatter,
      },
      {
        field: 'Shift',
        headerName: 'Shift',
        resizable: true,
        filter: true,
      },
      {
        field: 'Thickness',
        headerName: 'Thickness',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProductionTime',
        headerName: 'Production Time',
        resizable: true,
        filter: true,
      },
      {
        field: 'Pull',
        headerName: 'Pull',
        resizable: true,
        filter: true,
      },
      {
        field: 'Pack',
        headerName: 'Pack',
        resizable: true,
        filter: true,
      },
      {
        field: 'AT',
        headerName: 'AT',
        resizable: true,
        filter: true,
      },
      {
        field: 'GL',
        headerName: 'GL',
        resizable: true,
        filter: true,
      },
      {
        field: 'AC',
        headerName: 'AC',
        resizable: true,
        filter: true,
      },
      {
        field: 'GA',
        headerName: 'GA',
        resizable: true,
        filter: true,
      },
      {
        field: 'GE',
        headerName: 'GE',
        resizable: true,
        filter: true,
      },
      {
        field: 'MR',
        headerName: 'MR',
        resizable: true,
        filter: true,
      },
      {
        field: 'GF',
        headerName: 'GF',
        resizable: true,
        filter: true,
      },
      {
        field: 'JV',
        headerName: 'JV',
        resizable: true,
        filter: true,
      },
      {
        field: 'Lami',
        headerName: 'Lami',
        resizable: true,
        filter: true,
      },
      {
        field: 'MR_3',
        headerName: 'MR_3',
        resizable: true,
        filter: true,
      },
      {
        field: 'Yield',
        headerName: 'Yield',
        resizable: true,
        filter: true,
      },
      {
        field: 'WIP',
        headerName: 'WIP',
        resizable: true,
        filter: true,
      },
      {
        field: 'WIP_MT',
        headerName: 'WIP_MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'Loss',
        headerName: 'Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TotalLoss',
        headerName: 'Total Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TotalLossMT',
        headerName: 'Total Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'Trimming',
        headerName: 'Trimming %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TrimmingMT',
        headerName: 'Trimming MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'SamplingLoss',
        headerName: 'Sampling Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'SamplingLossMT',
        headerName: 'Sampling Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'CuttingLoss',
        headerName: 'Cutting Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'cuttingLossMT',
        headerName: 'Cutting Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'BreakageLoss',
        headerName: 'Breakage Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'BreakageLossMT',
        headerName: 'Breakage Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ChangeOverLoss',
        headerName: 'Change Over Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ChangeOverLossMT',
        headerName: 'Chnage Over Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'PrefLoss',
        headerName: 'Pref Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'PrefLossMT',
        headerName: 'Pref Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'FloatLoss',
        headerName: 'Float Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'FloatLossMT',
        headerName: 'Float Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'EquipmentLoss',
        headerName: 'Equipment Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'EquipmentLossMT',
        headerName: 'Equipment Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'SaturationLoss',
        headerName: 'Saturation Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'SaturationLossMT',
        headerName: 'Saturation Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProfileLoss',
        headerName: 'Profile Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProfileLossMT',
        headerName: 'Profile Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'DefectLoss',
        headerName: 'Defect Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'DefectLossMT',
        headerName: 'Defect Loss MT',
        resizable: true,
        filter: true,
      },
      // {
      //   field: 'holdpercent',
      //   headerName: 'Hold %',
      //   resizable: true,
      //   filter: true,
      // },
      // {
      //   field: 'holdmt',
      //   headerName: 'Hold MT',
      //   resizable: true,
      //   filter: true,
      // },
      {
        field: 'HandlingLoss',
        headerName: 'Handling Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'HandlingLossMT',
        headerName: 'Handling Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ReworkLoss',
        headerName: 'Rework Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ReworkLossMT',
        headerName: 'Rework Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'LineLoss',
        headerName: 'Line Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'LineLossMT',
        headerName: 'Line Loss MT',
        resizable: true,
        filter: true,
      },
    ];
   
    this.shiftDetailcolumnDefs = [
      {
        field: 'Date',
        headerName: 'Date',
        resizable: true,
        filter: true,
        cellStyle: (params: any) => params.value == 'Cummulative' ? { color: 'white', background: '#4040f2'} : { color: 'black' },
        valueFormatter: this.dateFormatter,
      },
      {
        field: 'Shift',
        headerName: 'Shift',
        resizable: true,
        filter: true,
      },
      {
        field: 'ShiftManager',
        headerName: 'Shift Manager',
        resizable: true,
        filter: true,
      },
      {
        field: 'Crew',
        headerName: 'Crew',
        resizable: true,
        filter: true,
      },
      {
        field: 'Thickness',
        headerName: 'Thickness',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProductionTime',
        headerName: 'Production Time',
        resizable: true,
        filter: true,
      },
      {
        field: 'NetWidth',
        headerName: 'Net Width',
        resizable: true,
        filter: true,
      },
      {
        field: 'Pull',
        headerName: 'Pull',
        resizable: true,
        filter: true,
      },
      {
        field: 'Pack',
        headerName: 'Pack',
        resizable: true,
        filter: true,
      },
      {
        field: 'AT',
        headerName: 'AT',
        resizable: true,
        filter: true,
      },
      {
        field: 'GL',
        headerName: 'GL',
        resizable: true,
        filter: true,
      },
      {
        field: 'AC',
        headerName: 'AC',
        resizable: true,
        filter: true,
      },
      {
        field: 'GA',
        headerName: 'GA',
        resizable: true,
        filter: true,
      },
      {
        field: 'GE',
        headerName: 'GE',
        resizable: true,
        filter: true,
      },
      {
        field: 'MR',
        headerName: 'MR',
        resizable: true,
        filter: true,
      },
      {
        field: 'GF',
        headerName: 'GF',
        resizable: true,
        filter: true,
      },
      {
        field: 'JV',
        headerName: 'JV',
        resizable: true,
        filter: true,
      },
      {
        field: 'Lami',
        headerName: 'Lami',
        resizable: true,
        filter: true,
      },
      {
        field: 'MR_3',
        headerName: 'MR_3',
        resizable: true,
        filter: true,
      },
      {
        field: 'Yield',
        headerName: 'Yield',
        resizable: true,
        filter: true,
      },
      {
        field: 'WIP',
        headerName: 'WIP',
        resizable: true,
        filter: true,
      },
      {
        field: 'WIP_MT',
        headerName: 'WIP_MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'Loss',
        headerName: 'Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TotalLoss',
        headerName: 'Total Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TotalLossMT',
        headerName: 'Total Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'Trimming',
        headerName: 'Trimming %',
        resizable: true,
        filter: true,
      },
      {
        field: 'TrimmingMT',
        headerName: 'Trimming MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'SamplingLoss',
        headerName: 'Sampling Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'SamplingLossMT',
        headerName: 'Sampling Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'CuttingLoss',
        headerName: 'Cutting Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'cuttingLossMT',
        headerName: 'Cutting Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'BreakageLoss',
        headerName: 'Breakage Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'BreakageLossMT',
        headerName: 'Breakage Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ChangeOverLoss',
        headerName: 'Change Over Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ChangeOverLossMT',
        headerName: 'Chnage Over Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'PrefLoss',
        headerName: 'Pref Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'PrefLossMT',
        headerName: 'Pref Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'FloatLoss',
        headerName: 'Float Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'FloatLossMT',
        headerName: 'Float Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'EquipmentLoss',
        headerName: 'Equipment Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'EquipmentLossMT',
        headerName: 'Equipment Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'SaturationLoss',
        headerName: 'Saturation Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'SaturationLossMT',
        headerName: 'Saturation Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProfileLoss',
        headerName: 'Profile Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ProfileLossMT',
        headerName: 'Profile Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'DefectLoss',
        headerName: 'Defect Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'DefectLossMT',
        headerName: 'Defect Loss MT',
        resizable: true,
        filter: true,
      },
      // {
      //   field: 'holdpercent',
      //   headerName: 'Hold %',
      //   resizable: true,
      //   filter: true,
      // },
      // {
      //   field: 'holdmt',
      //   headerName: 'Hold MT',
      //   resizable: true,
      //   filter: true,
      // },
      {
        field: 'HandlingLoss',
        headerName: 'Handling Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'HandlingLossMT',
        headerName: 'Handling Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'ReworkLoss',
        headerName: 'Rework Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'ReworkLossMT',
        headerName: 'Rework Loss MT',
        resizable: true,
        filter: true,
      },
      {
        field: 'LineLoss',
        headerName: 'Line Loss %',
        resizable: true,
        filter: true,
      },
      {
        field: 'LineLossMT',
        headerName: 'Line Loss MT',
        resizable: true,
        filter: true,
      },
    ];
  }

  ngOnInit() {
    var firstDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    this.opmsreportForm = this.fb.group({
      startDate: [this.datePipe.transform(firstDate, 'yyyy-MM-dd')],
      endDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      report: [''],
    });
    this.opmsreportForm.get("report")?.setValue(this.reports[0].value);
    this.OnSelectReport();
  }
  OnSelectReport(): void {
    this.spinner.show()
    this.selectedreport = this.opmsreportForm.get("report")?.value;
    if (this.selectedreport === 1) {
      this.sizewisereport = true;
      this.thicknessreport = false;
      this.daywisereport = false;
      this.crewwisereport = false;
      this.shiftwisereport = false;
      this.shiftDetailreport = false;
      this.getSizeWiseReport();
    } else if (this.selectedreport === 2) {
      this.sizewisereport = false;
      this.thicknessreport = true;
      this.daywisereport = false;
      this.crewwisereport = false;
      this.shiftwisereport = false;
      this.shiftDetailreport = false;
      this.getThicknessWisereport();
    } else if (this.selectedreport === 3) {
      this.sizewisereport = false;
      this.thicknessreport = false;
      this.daywisereport = true;
      this.crewwisereport = false;
      this.shiftwisereport = false;
      this.shiftDetailreport = false;
      this.getDayWiseReport();
    } else if (this.selectedreport === 4) {
      this.sizewisereport = false;
      this.thicknessreport = false;
      this.daywisereport = false;
      this.crewwisereport = true;
      this.shiftwisereport = false;
      this.shiftDetailreport = false;
      this.getCrewWiseReport();
    } else if (this.selectedreport === 5) {
      this.sizewisereport = false;
      this.thicknessreport = false;
      this.daywisereport = false;
      this.crewwisereport = false;
      this.shiftwisereport = true;
      this.shiftDetailreport = false;
      this.getShiftWiseReport();
    } else if(this.selectedreport === 6){
      this.sizewisereport = false;
      this.thicknessreport = false;
      this.daywisereport = false;
      this.crewwisereport = false;
      this.shiftwisereport = false;
      this.shiftDetailreport = true;
      this.getShiftDetailReport();
    }
    else{
      return;
    }
  }

  getSizeWiseReport(): void {
    this.sizerowData = [];
    const key = {
      StartDate: this.opmsreportForm.get('startDate')?.value,
      EndDate: this.opmsreportForm.get('endDate')?.value,
    };
    this.opmsGlassservice.GetSizeWiseReportData(key).subscribe(
      (data: any) => {
        this.sizerowData = data.SizeWiseReportList;
        this.sizeexcelreport = data.SizeWiseReportList.Length ? Object.keys(data.SizeWiseReportList[0]) : [];
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('error', error.message);
      }
    );
  }

  onGridReadySize(params :any){
    this.gridApiSize = params.api;
    this.gridColApiSize = params.columnApi;
    // const allColumnIds: any[] = [];
    // (params.columnApi).getAllColumns().forEach((column: any) => {
    //   allColumnIds.push(column.colId);
    // });
    // (params.columnApi).autoSizeColumns(allColumnIds, false);
    (params.api).sizeColumnsToFit();
    // this.onFirstDataRendered(params.columnApi);
  }

  sizeFirstData(params: any){
    (params.api).sizeColumnsToFit();
  }

  getThicknessWisereport(): void {
    this.thicknessrowData = [];
    const key = {
      StartDate: this.opmsreportForm.get('startDate')?.value,
      EndDate: this.opmsreportForm.get('endDate')?.value,
    };
    this.opmsGlassservice.GetThicknessWiseReportData(key).subscribe(
      (data: any) => {
        this.thicknessrowData = data.ThicknessWiseReportList;
        this.thicknessexcelreport = data.ThicknessWiseReportList.length ? Object.keys(data.ThicknessWiseReportList[0]) : [];
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('error', error.message);
      }
    );
  }

  onGridReadyThickness(params :any){
    this.gridApiThickness = params.api;
    this.gridColApiThickness = params.columnApi;
    const allColumnIds: any[] = [];
    (params.columnApi).getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    (params.columnApi).autoSizeColumns(allColumnIds, false);
    // (params.api).sizeColumnsToFit();
  }

  getDayWiseReport(): void {
    this.dayrowData = [];
    const key = {
      StartDate: this.opmsreportForm.get('startDate')?.value,
      EndDate: this.opmsreportForm.get('endDate')?.value,
    };
    this.opmsGlassservice.GetDayWiseReport(key).subscribe(
      (data: any) => {
        this.dayrowData = data.DayWiseReportList;
        this.dayexcelreport = data.DayWiseReportList.length ? Object.keys(data.DayWiseReportList[0]) : [];
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('error', error.message);
      }
    );
  }

  onGridReadyDay(params :any){
    this.gridApiDay = params.api;
    this.gridColApiDay = params.columnApi;
    const allColumnIds: any[] = [];
    (params.columnApi).getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    (params.columnApi).autoSizeColumns(allColumnIds, false);
    // (params.api).sizeColumnsToFit();
  }

  getCrewWiseReport(): void {
    this.crewrowData = [];
    const key = {
      StartDate: this.opmsreportForm.get('startDate')?.value,
      EndDate: this.opmsreportForm.get('endDate')?.value,
    };
    this.opmsGlassservice.GetCrewWiseReport(key).subscribe(
      (data: any) => {
        this.crewrowData = data.CrewWiseReportList;
        this.crewexcelreport = data.CrewWiseReportList.length ? Object.keys(data.CrewWiseReportList[0]) : [];
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('error', error.message);
      }
    );
  }

  onGridReadyCrew(params :any){
    this.gridApiCrew = params.api;
    this.gridColApiCrew = params.columnApi;
    const allColumnIds: any[] = [];
    (params.columnApi).getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    (params.columnApi).autoSizeColumns(allColumnIds, false);
    // (params.api).sizeColumnsToFit();
  }

  getShiftWiseReport(): void {
    this.shiftwiserowData = [];
    const key = {
      StartDate: this.opmsreportForm.get('startDate')?.value,
      EndDate: this.opmsreportForm.get('endDate')?.value,
    };
    this.opmsGlassservice.GetShiftWiseReport(key).subscribe(
      (data: any) => {
        this.shiftwiserowData = data.ShiftWiseReportList;
        this.shiftexcelreport = data.ShiftWiseReportList.length ? Object.keys(data.ShiftWiseReportList[0]) : [];
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('error', error.message);
      }
    );
  }

  onGridReadyShift(params :any){
    this.gridApiShift = params.api;
    this.gridColApiSize = params.columnApi;
    const allColumnIds: any[] = [];
    (params.columnApi).getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    (params.columnApi).autoSizeColumns(allColumnIds, false);
    // (params.api).sizeColumnsToFit();
  }

  getShiftDetailReport(): void {
    this.shiftDetailrowData = [];
    const key = {
      StartDate: this.opmsreportForm.get('startDate')?.value,
      EndDate: this.opmsreportForm.get('endDate')?.value,
    };
    this.opmsGlassservice.GetShiftdetailReport(key).subscribe(
      (data: any) => {
        this.shiftDetailrowData = data.ShiftWiseReportList;
        this.shiftdetailexcelreport = data.ShiftWiseReportList.length ? Object.keys(data.ShiftWiseReportList[0]) : [];
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('error', error.message);
      }
    );
  }

  onGridReadyShiftDeatil(params :any){
    this.gridApiShiftDetail = params.api;
    this.gridColApiSize = params.columnApi;
    const allColumnIds: any[] = [];
    (params.columnApi).getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    (params.columnApi).autoSizeColumns(allColumnIds, false);
    // (params.api).sizeColumnsToFit();
  }

  exportAsXLSX(): void {
    if (this.selectedreport === 1) {
      const excelParams = {
        headerRowHeight: 50,
        sheetName: 'Size Wise Report',
        fileName: 'Size Wise Report',
        allColumns: false,
        columnKeys: this.sizeexcelreport
      };
      this.gridApiSize.exportDataAsExcel(excelParams);
    } else if (this.selectedreport === 2) {
      const excelParams = {
        headerRowHeight: 50,
        sheetName: 'Thickness Wise Report',
        fileName: 'Thickness Wise Report',
        allColumns: false,
        columnKeys: this.thicknessexcelreport
      };
      this.gridApiThickness.exportDataAsExcel(excelParams);
    } else if (this.selectedreport === 3) {
      const excelParams = {
        headerRowHeight: 50,
        sheetName: 'Day Wise Report',
        fileName: 'Day Wise Report',
        allColumns: false,
        columnKeys: this.dayexcelreport
      };
      this.gridApiDay.exportDataAsExcel(excelParams);
    } else if (this.selectedreport === 4) {
      const excelParams = {
        headerRowHeight: 50,
        sheetName: 'Crew Wise Report',
        fileName: 'Crew Wise Report',
        allColumns: false,
        columnKeys: this.crewexcelreport
      };
      this.gridApiCrew.exportDataAsExcel(excelParams);
    } else if (this.selectedreport === 5) {
      const excelParams = {
        headerRowHeight: 50,
        sheetName: 'Shift Wise Report',
        fileName: 'Shift Wise Report',
        allColumns: false,
        columnKeys: this.shiftexcelreport
      };
      this.gridApiShift.exportDataAsExcel(excelParams);
    } else if(this.selectedreport === 6){
      const excelParams = {
        headerRowHeight: 50,
        sheetName: 'Shift Detail Report',
        fileName: 'Shift Detail Report',
        allColumns: false,
        columnKeys: this.shiftdetailexcelreport
      };
      this.gridApiShiftDetail.exportDataAsExcel(excelParams);
    } 
    else {
      return;
    }

    // const excelParams = {
    //   headerRowHeight: 50,
    //   sheetName: 'Physical - '+this.reportType,
    //   fileName: 'Physical - '+this.reportType,
    //   allColumns: false,
    //   columnKeys: this.exportExcelColumns
    // };
    // this.gridApi.exportDataAsExcel(excelParams);
  }

  dateFormatter(params: any): any {
    if(params.value != 'Cummulative'){
      return moment(params.value).format('MM/DD/YYYY');
    }
    else{
      return 'Cummulative';
    }
  }

}
