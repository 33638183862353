import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { TopicService } from 'src/app/services/topic.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-Topic',
  templateUrl: './Topic.component.html',
  styleUrls: ['./Topic.component.scss']
})
export class TopicComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  topicForm!: FormGroup;
  topicColumns: string[] = ['topicActions', 'project', 'topic',];
  topicData!: MatTableDataSource<any>;
  topicModelVisible = false;
  topicformMode!: boolean;
  plantId!: number;
  projectId!: any;
  topicId!: any;
  plantsDDL!: any[];
  groupId!: number;
  projectDDL!: any[];
  isUpdateTopic: boolean = false;
  IsAccess:any;
  IsWrite:any;

  constructor(
    private fb: FormBuilder,
    private topicService: TopicService,
    private platModelService: EnterPriseGroupService,
    private toaster: ToastrService) { }

  ngOnInit() {
  //  this.getMasterproject();
    this.topicForm = this.fb.group({
      plantId: ['', Validators.required],
      projectname: [ Validators.required, ],
      topic: ['', Validators.required],
    });
    this.GetMenuAccess();
  }
 
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
      // localStorage.setItem('GetMenuAccess', this.permisson);
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  openTopicModal(): void {
    this.getMasterproject();
    this.topicformMode = true;
    this.topicForm.get('plant')?.setValue(this.plantId);
 
    // this.topicForm.get('projectname')?.setValue(this.projectId);
    this.topicModelVisible = true;
  }
  openEditTopicModal(): void {
    // this.getMasterproject();
    this.topicformMode = false;

    this.topicModelVisible = true;

  }
  gettopicList(): void {
    const key= {
   plantId: this.plantId
    }
    this.topicService.getMasterTopic(key).subscribe((data) => {
      
      this.topicData = new MatTableDataSource<any>(data);
      this.getPaginator();
    },
      (error) => {
        this.toaster.error(error.error.message);
       
      });
  
  }
  getPlantsByGroup(): void {

    const key = {
      GroupId: this.groupId
    };


    this.topicService.getPlantsData(key).subscribe(
      (plants: any) => {
        
        this.plantsDDL = plants.DepartList;
        this.topicForm.get('plantId')?.setValue(this.plantId);
       

      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getMasterproject(): void {
    
    const key= {
  plantId:  this.plantId
    };
    this.topicService.getMasterProject(key).subscribe((data) => {
      this.projectDDL = data;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      });
  }
  getTopicMaster(key: any): void {
    this.plantId = key.plantId;
    this.groupId = key.groupId;
    this.topicForm.get('group')?.setValue(key.groupId);
    this.topicService.getPlantsData(key).subscribe(
      (plants: any) => {
        
      
        this.plantsDDL = plants.DepartList;
        this.topicForm.get('plantId')?.setValue(this.plantId);
        this.gettopicList();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
}
saveTopic(): void {
  const topicData = this.topicData .filteredData;
  const Topic = topicData.filter((c: any) => c.topicName.trim().toLowerCase()  === this.topicForm.value.topic.trim().toLowerCase());
  if (Topic.length > 0) {
    this.toaster.error('Error', 'Topic  Already Exist');
    return;
  }
  const topic = {
    plantId : this.topicForm.value.plantId,
    projectId : this.topicForm.value.projectname,
    topicName : this.topicForm.value.topic,
    userId: JSON.parse(localStorage.user).UserId,
  };
  this.topicService.addTopic(topic).subscribe(
    (response) => {
      this.toaster.success('Success', 'Topic Added Successfully');
      this.gettopicList();
      this.closetopicModel();
      this.resetForm();
    },
    (error) => {
      this.toaster.error('Error', error.error.message);
    }
  );
}
DeleteTopic(element: any): void {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to delete topic!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it',
  }).then((result) => {
    if (result.value) {
      debugger
      console.log(element);
      var id = {
        topicId: element.topicId
      }
      this.topicService.deleteTopic(id).subscribe((response) => {
          Swal.fire({
            title: 'Deleted!',
            text: 'Your selected topic has been deleted.',
            icon: 'success',
            timer: 800,
            showConfirmButton: false,
          });
          this.gettopicList();
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire('Cancelled', 'Your selected topic is safe :)', 'error');
    }
  });

}

editTopicModel(element: any): void {
  
  this.getMasterproject();
  this.topicId= element.topicId
  this.projectId = element.projectId;
  const key = {
    topicId: element.topicId
  }

  this.topicService.GetOneMasterTopic(key).subscribe((Response) => {
    debugger;
    this.openEditTopicModal()
    this.topicForm.get('plantId')?.setValue(this.plantId);
    this.topicForm.get('projectname')?.setValue(Response.projectId);

    this.topicForm.get('topic')?.setValue(Response.topicName);
  }, (error) => {
    this.toaster.error('Error', error.error.message);
  })


}
updateTopic(): void {
  const topicData = this.topicData .filteredData;
  const Topic = topicData.filter((c: any) => c.topicName.trim().toLowerCase()  === this.topicForm.value.topic.trim().toLowerCase());
  if (Topic.length > 0) {
    this.toaster.error('Error', 'Topic  Already Exist');
    return;
  }
  const key = {
    topicId: this.topicId,
    projectId: this.topicForm.value.projectname,
    plantId: this.topicForm.value.plantId,
    // projectName : this.topicForm.value.projectname,
    topicName : this.topicForm.value.topic,
    userId: JSON.parse(localStorage.user).UserId,
  };
  
  this.topicService.updateTopic(key).subscribe(
    (response) => {
      this.toaster.success('Success', 'Topic Updated Successfully');
      this.gettopicList();
      // this.getMasterproject();
      this.closetopicModel();
      this.resetForm();

      // this.gettopicList(this.topicId);
      this.isUpdateTopic = false;
    },
    (error: any) => {
      this.toaster.error('Error', error.error.message);

    }
  );
}
applyFilter(event: Event): void {
  const filterValue = (event.target as HTMLInputElement).value;
  this.topicData.filter = filterValue.trim().toLowerCase();

  if (this.topicData.paginator) {
    this.topicData.paginator.firstPage();
  }
}
getPaginator(): void {
  setTimeout(() => {
    this.topicData.paginator = this.paginator;
    this.topicData.sort = this.sort;
  }, 1000);
}
  resetForm(): void {
    this.topicForm.get('projectname')?.setValue('');
    this.topicForm.get('topic')?.setValue('');
    this.isUpdateTopic = false;
  }
  closetopicModel(): void {
    this.topicModelVisible = false;
    this.resetForm();
  }
}
