import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {
  filteredShiftList: any = [];
  shiftList: any = [];
  filteredWeekList: any = [];
  weekList: any = [];
  filteredMonthList: any = [];
  monthList: any = [];
  filteredQuarterList: any = [];
  quarterList: any = [];
  filteredLines: any = [];
  lineList: any = [];
  filteredAreaList: any = [];
  areaList: any = [];
  machineWiseoeeData: any = [];
    analysisHeaderForm = this.formBuilder.group({
    shift: ['', Validators.required],
    week: ['', Validators.required],
    month: ['', Validators.required],
    quarter: ['', Validators.required],
    line: ['', Validators.required],
    area: [new Date(), Validators.required]
  });
  IsAccess: boolean = false;
  IsWrite: boolean = false;
  breadcrumList: any = [];
  constructor(private formBuilder: FormBuilder,public router: Router) { }



  ngOnInit(): void {
  }

}
