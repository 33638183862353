import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QualityParameterService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  gettVariableGroupList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'parametergroup', data);
  }

  sumbitGroup(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'addparametergroup', data);
  }
  updateGroup(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.baseUrl + 'parametergroup', data);
  }

  deleteGroupList(data: any): any {
    return this.http.post<any>(this.baseUrl + 'deleteparametergroup', data);
  }

  getVariableList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3ApiUrl + 'QualityParam/qualityparameter', data);
  }
  sumbitVariables(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'addqualityparameter', data);
  }
  deleteParameter(data: any): any {
    return this.http.post<any>(this.baseUrl + 'deletequalityparameter', data);
  }
  updateParameter(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.o3ApiUrl + 'QualityParam/qualityparameter', data);
  }

  getParameterSpecificationsList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'qualityparameterspec', data);
  }

  sumbitParameterSpecifications(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3ApiUrl + 'QualityParam/AddQualityParameterSpec',
      data
    );
  }
  deleteParameterSpecifications(data: any): any {
    return this.http.post<any>(
      this.o3ApiUrl + 'QualityParam/DeleteParameterSpec',
      data
    );
  }
  updateParameterSpecifications(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.baseUrl + 'qualityparameterspec', data);
  }
  getDataType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3ApiUrl + 'Variable/GetDataType', data);
  }

  getDatasource(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3ApiUrl + 'Variable/GetDatasource',
      data
    );
  }

  getCalculationMethod(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3ApiUrl + 'Variable/GetCalculationMethod',
      data
    );
  }

  getInputTag(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3ApiUrl + 'Variable/GetCalculationMethod',
      data
    );
  }

  parameterList(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'Variable/ParameterSpecification_Desc',
      data
    );
  }

  parameterLinkProduct(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3ApiUrl + 'Variable/ParameterSpecification_LinkProduct',
      data
    );
  }
  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetGroup', data);
  }
  getMaxCavity(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'maxcavity', data);
  }
  getQualityControlInterval(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'getQualityControlInterval',
      data
    );
  }
  getProcessControlInterval(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'getProcessControlInterval',
      data
    );
  }
  getParameterSpecifications(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'parameterspecifications',
      data
    );
  }
  qualityparamaterdefects(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'qualityparamaterdefects',
      data
    );
  }
  saveQcVerify(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'saveQcVerify', data);
  }
  GetQCVerifyDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'getQCVerifyDetail', data);
  }
  getQcVerify(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'getQcVerify', data);
  }
  getQcVerify2(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3ApiUrl + 'Quality/QCVerifyReport', data);
  }
  GetAllMasterEvents(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'QualityParam/GetAllMasterEvents', data);
  }

  // quality parameter routes

  GetAllQualityParameterIntervals(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'getallqualityparameterintervals',
      data
    );
  }

  AddQualityParameterIntervals(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'addqualityparameterinterval',
      data
    );
  }

  UpdateQualityParameterIntervals(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'updatequalityparameterinterval',
      data
    );
  }

  // data type routes

  async GetAllDataTypesDetail(data: any): Promise<any[]> {
    return await this.http
      .post<any[]>(this.baseUrl + 'getalldatatypesdetail', data)
      .toPromise();
  }

  AddDataType(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'addqualityparameterdatatypedetail',
      data
    );
  }

  UpdateDataType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'updatedatatypedetail', data);
  }

  DeleteDataType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'deletedatatypedetail', data);
  }
  getParamaterGroupType(): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + 'Variable/GetParameterGroupType');
  }
  getQualityGroupbyUnit(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'getqualitygroupbyunit', data);
  }
  getVariableGroupbyUnit(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ProcessControl/GetParameterGroupTypeByUnit', data);
  }
  // add datatype in quality
  AddVariableType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'VariableParameterGroup/AddVariableType', data);
  }

  GetAllDataTypeIds(data: any): Promise<any[]> {
    return this.http
      .post<any[]>(this.baseUrl + 'getallparameterids', data)
      .toPromise();
  }
  async getQualityGroupEvents(data: any): Promise<any[]> {
    return await this.http.post<any[]>(this.baseUrl + 'getQualityGroupEvents', data).toPromise();
  }
  getCurrentProcessOrder(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'getCurrentProcessOrder', data);
  }
}
