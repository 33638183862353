import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ToastrService } from 'ngx-toastr';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MachineBoardService } from 'src/app/services/machineBoard.service';
import { MachineBoard } from '../../../models/machineBoard';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _moment from 'moment';
const moment = _moment;
interface SelectInterFace {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-machineboard',
  templateUrl: './machineboard.component.html',
  styleUrls: ['./machineboard.component.scss'],
})
export class MachineboardComponent implements OnInit {
  loading = false;
  machineBoardData = false;
  showMode = false;
  isMachineBoardModelVisible = false;
  displayMachineModal = false;
  UnitName!: any;
  MachineId!: any;
  MachineDashboardList!: any[];
  EmpltyArray!: any[];

  date1!: any;
  date2!: any;
  titles!: any[];
  ONE_HOUR = 60 * 60 * 1000;
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  currentUser!: MachineBoard;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  FromTimePicker!: Date;
  ToTimePicker!: Date;
  constructor(
    private formBuilder: FormBuilder,
    private enterpriseGroupService: EnterPriseGroupService,
    private toaster: ToastrService,
    private mbService: MachineBoardService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private kpiMaster: KpimasterService,
    private spinner: NgxSpinnerService
  ) {}
  get unitDetailFormControls(): any {
    return this.searchForm.controls;
  }
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  // colorAvailability: ThemePalette = 'primary';
  // colorPerformance: ThemePalette = 'accent';
  // colorQuality: ThemePalette = 'warn';
  barOptionsList: any[] = [
    'PuDesc',
    'AvailableRate',
    'PerformanceRate',
    'QualityRate',
    'PercentOEE',
    'OeeStatus',
    'MachineStatus',
  ];

  searchForm!: FormGroup;

  // fot data
  groupList!: any[];
  lineList: SelectInterFace[] = [
    { value: 'die-cutting', viewValue: 'Die Cutting' },
    { value: 'pie-cutting', viewValue: 'Pie Cutting' },
    { value: 'lie-cutting', viewValue: 'Lie Cutting' },
  ];
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;
  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroupList();
    // this.GetMachineDashboard();
    // search form
    this.date1 = new Date();
    this.date2 = new Date(new Date().getTime() + this.ONE_HOUR);
    this.searchForm = this.formBuilder.group({
      group: ['', Validators.required],
      unit: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });

    const d = new Date();
    d.setDate(d.getDate() - 1);
    const start = new Date();
    this.FromTimePicker = d;
    this.ToTimePicker = new Date();
    this.searchForm.get('startDate')?.setValue(d);
    this.searchForm.get('endDate')?.setValue(this.ToTimePicker);
  }

  getGroupList(): void {
    debugger;
    const key = {
      Id: 0,
    };
    this.mbService.getGroupList(key).subscribe((res: any) => {
      this.groupList = res.masterList;
      this.searchForm.get('group')?.setValue(this.groupList[0].TypeId);
    });
  }
  groupChange(): void {
    this.searchForm.get('unit')?.reset();
  }

  GetMachineDashboard(): void {
    this.loading = true;
    let endDate = new Date(this.date2).toLocaleString();
    const date = endDate.toLocaleString().split(',')[0].trim().split('/');
    const time = endDate
      .toLocaleString()
      .split(',')[1]
      .trim()
      .split(' ')[0]
      .trim()
      .split(':');
    endDate =
      date[2] + '/' + date[0] + '/' + date[1] + ' ' + time[0] + ':' + time[1];
    const key = {
      EGID: this.searchForm.value.group,
      PLID: this.MachineId, // this.searchForm.value.unit,
      StartTime: this.dateAsYYYYMMDDHHNNSS(this.searchForm.value.startDate), // '2021/03/29 11:41', // new Date(endDate),
      EndTime: this.dateAsYYYYMMDDHHNNSS(this.searchForm.value.endDate), // '2021/03/29 11:46' // new Date(endDate),
      Level: 'Line',
    };
    this.spinner.show();

    this.mbService.GetMachineDashboard(key).subscribe(
      (res: any) => {
        if (res.HasError === true) {
          this.spinner.hide();
          this.EmpltyArray = this.MachineDashboardList;
          this.toaster.error('Machine Not Found..!', res.message);
        } else {
          this.spinner.hide();
          this.loading = false;
          const array = res.OeeList;
          array.forEach((element: any) => {
            let colorCode = '';
            if (
              Math.round(element.PercentOEE) > 0 &&
              Math.round(element.PercentOEE) <= 20
            ) {
              colorCode = '#FF0000';
            } else if (
              Math.round(element.PercentOEE) > 20 &&
              Math.round(element.PercentOEE) <= 50
            ) {
              colorCode = '#ffff00';
            } else if (
              Math.round(element.PercentOEE) > 50 &&
              Math.round(element.PercentOEE) <= 70
            ) {
              colorCode = '#ffa500';
            } else if (Math.round(element.PercentOEE) > 70) {
              colorCode = '#008000';
            }
            element.colorAvailability = this.colorCode(
              element.AvailableRate.toFixed(2)
            );
            element.colorPerformance = this.colorCode(
              element.PerformanceRate.toFixed(2)
            );
            element.colorQuality = this.colorCode(
              element.QualityRate.toFixed(2)
            );
            element.AvailableRate = element.AvailableRate.toFixed(2);
            element.PerformanceRate = element.PerformanceRate.toFixed(2);
            element.QualityRate = element.QualityRate.toFixed(2);
            element.barOptionsBobst = {
              barType: 'radial',
              color: colorCode,
              secondColor: '#E5E5E5',
              progress:
                Math.round(element.PercentOEE) > 0
                  ? Math.round(element.PercentOEE)
                  : ' 0 ',
              radial: {
                depth: 9,
                size: 96,
                label: {
                  enable: true,
                  color: '#09608c',
                },
              },
            };
          });
          this.MachineDashboardList = array;
          this.showMode = true;
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }
  colorCode(value: any): any {
    let colorCode = '';
    if (Math.round(value) > 0 && Math.round(value) <= 20) {
      return (colorCode = 'warn');
    } else if (Math.round(value) > 20 && Math.round(value) <= 50) {
      return (colorCode = 'primary');
    } else if (Math.round(value) > 50 && Math.round(value) <= 70) {
      return (colorCode = 'secondary');
    } else if (Math.round(value) > 70) {
      return (colorCode = 'accent');
    } else if (Math.round(value) === 0) {
      return (colorCode = '');
    }
  }
  openTreeModal(): void {
    this.machineBoardData = true;
    const key = {
      EGId: this.searchForm.value.group,
      level: 'Line',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        // tslint:disable-next-line:no-shadowed-variable
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.isMachineBoardModelVisible = true;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onNodeSelect(node: any): void {
    // debugger;
    this.machineBoardData = false;
    // tslint:disable-next-line: align
    this.MachineId = node.PrimaryId;
    this.searchForm.get('unit')?.setValue(node.text);
  }

  SelectUnitName(unitname: any): void {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isMachineBoardModelVisible = false;
    }
  }

  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  closeaddNewPlantItemModel(): void {
    this.displayMachineModal = false;
  }

  closePlantGroupModel(): void {
    this.machineBoardData = false;
  }

  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  //date formatter
  dateAsYYYYMMDDHHNNSS(date: any): string {
    return (
      date.getFullYear() +
      '-' +
      this.leftpad(date.getMonth() + 1, 2) +
      '-' +
      this.leftpad(date.getDate(), 2) +
      ' ' +
      this.leftpad(date.getHours(), 2) +
      ':' +
      this.leftpad(date.getMinutes(), 2)
    );
  }
  leftpad(val: any, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength) + String(val)).slice(
      String(val).length
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
