<div class="production-db-content">
    <div class="content-top-box">
      <ul class="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
              (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
          </li>
        </ul>
      </ul>
      <div class="title-row-flex-both-end">
        <h1 class="main-title">Language Master</h1>
      </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <form [formGroup]="languageform">
      <div class="content-top-row content-top-row-order-processing">
        <!-- GROUP -->
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <!-- <mat-label>Status</mat-label> -->
          <mat-select formControlName="module"  placeholder="Module">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'moduleName'" [array]="moduleList"
            (filteredReturn)="filteredModules = $event"></mat-select-filter>
            <mat-option *ngFor="let module of filteredModules" [value]="module.moduleId">
              {{module.moduleName}}
            </mat-option>
          </mat-select>
          <mat-error class="error-msg" *ngIf="checkError('module', 'required')">Module field is required</mat-error>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="page"  placeholder="Page" 
            panelClass="testClass" name="Status">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'pageName'" [array]="pageList"
            (filteredReturn)="filteredPages = $event"></mat-select-filter>
            <mat-option *ngFor="let page of filteredPages" [value]="page.pageId">
              {{page.pageName}}
            </mat-option>
          </mat-select>
          <mat-error class="error-msg" *ngIf="checkError('page', 'required')">Page field is required</mat-error>
        </mat-form-field>
      </div>

      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <input matInput formControlName="tagName" placeholder="Tag Name">
        <mat-error class="error-msg" *ngIf="checkError('tagName', 'required')">Tag Name field is required</mat-error>
      </mat-form-field>
      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
      <input matInput formControlName="tagValue" placeholder="Tag value">
      <mat-error class="error-msg" *ngIf="checkError('tagValue', 'required')">Tag Value field is required</mat-error>
    </mat-form-field>
        <div class="btn-position">
          <button mat-raised-button color="accent" class="btn-accent" (click)="onSubmit()">Save</button>
        </div>
      </div>
  
      <div class="p-col-12 p-md-6 p-lg-5 p-xl-5" style="display: flex; justify-content: flex-end;float: right;">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Language</mat-label>
            <mat-select formControlName="language" (selectionChange)="onChangeLanguage($event)"
              panelClass="testClass" name="Status">
              <mat-option *ngFor="let lang of languageList" [value]="lang.languageId">
                {{lang.languageName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>

    </form>
 
        <ag-grid-angular
        #agGrid
        style="width: 100%; height: 25vw;margin-top: 122px;" class="ag-theme-alpine"
        id="myGrid"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [enterMovesDown]="true"
        [enterMovesDownAfterEdit]="true"
        [rowData]="rowData"
        (gridReady)="onGridReady($event)"
        [singleClickEdit]="true"
        [frameworkComponents]="frameworkComponents"
        [getRowStyle]="getRowStyle"
        (rowClicked)="rowClicked($event)"
        [pagination]="true" [paginationPageSize]="10"
      ></ag-grid-angular>

  </div>

