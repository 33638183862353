import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { HttpClient } from '@angular/common/http';
import { DummyTdcGridService } from 'src/app/services/dummy-tdc-grid.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TdcanalysisService } from 'src/app/services/tdcanalysis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { reduce } from 'lodash-es';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
// import * as data from '../../dummy-data.json';
@Component({
  selector: 'app-tdcSimulation',
  templateUrl: './tdcSimulation.component.html',
  styleUrls: ['./tdcSimulation.component.scss'],
})
export class TdcSimulationComponent implements OnInit {
  TdcSimulationForm!: FormGroup;
  groupListDDL!: any[];
  plants!: any;
  breadcrumList: any;
  columnDefs: any;
  rowData: any;
  defaultColDef: any;
  paginationPageSize!: any;
  IsAccess:any;
  IsWrite:any;
  currentYear = JSON.stringify(new Date().getFullYear());
  yearList = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  gridOptions: any = {
    paginationPageSize: 10,
  };
  gridApi: any;
  gridColumnApi: any;
  getRowNodeId: (data: any) => any;
  autoGroupColumnDef: any;
  businessTrend: any;
  constructor(
    private usermanagementService: UserManagementService,
    public router: Router,
    private toaster: ToastrService,
    private tdcservice: TdcanalysisService,
    private fb: FormBuilder,
    private platModelService: EnterPriseGroupService,
    private spinner: NgxSpinnerService
  ) {
    // this.currentYear = JSON.stringify(new Date().getFullYear());
    // const val = this.currentYear;
    // alert(val);
    this.defaultColDef = {
      flex: 1,
      // editable: true,
      sortable: true,
      filter: true,
      type: 'rightAligned'
    };
    this.autoGroupColumnDef = {
      minWidth: 300,
      cellRendererParams: {
        innerRenderer: (params: any) => {
          if (params.node.footer) {
            const isRootLevel = params.node.level === -1;
            if (isRootLevel) {
              return `<span style="color:navy; font-weight:bold">Grand Total</span>`;
            }
            return `<span style="color:navy">Sub Total ${params.value}</span>`;
          }
          return params.value;
        },
      },
    };
    this.getRowNodeId = data => data.id;
  }

  ngOnInit(): any {
    this.GetBreadcrumList();
    this.getGroupData();
    this.GetMenuAccess();
    this.TdcSimulationForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      year: ['', Validators.required],
      businessTrend: ['', Validators.required],
    });
    this.TdcSimulationForm.get('year')?.setValue(new Date().getFullYear());
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
      // localStorage.setItem('GetMenuAccess', this.permisson);
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.TdcSimulationForm.get('group')?.setValue(
          this.groupListDDL[0].TypeId
        );
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.TdcSimulationForm.get('group')?.value,
    };

    this.usermanagementService.getDepartments(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.TdcSimulationForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getTdcSimulationdata();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  filterTdcSimulation(): void {
    const key = {
      plantId: this.TdcSimulationForm.get('plant')?.value,
      year: this.TdcSimulationForm.get('year')?.value,
    };

    this.getTdcSimulationdata();
  }
  onCellValueChanged(params: any): void {
    if (params.oldValue === params.newValue) {
      return;
    }
    const itemArr: any[] = [];
    this.gridApi.forEachLeafNode((node: any) => {
      itemArr.push(node.data);
    });
    const rowSoldVolume = itemArr.find(c => c.tdc === 'Sold Volume');
    if (params.colDef.field === 'volume') {
      const withvalue20 = params.data.volume - params.data.value19;
      const businessTrend = this.TdcSimulationForm.get('businessTrend')?.value ? this.TdcSimulationForm.get('businessTrend')?.value : 0;
      const identifiedSavingVolumeDrop = (params.value * (1 - (businessTrend / 100)));
      const volumedropWithSavingValue = identifiedSavingVolumeDrop - params.data.withoutvalue201;
      const volumedropWithSavingCost = volumedropWithSavingValue - rowSoldVolume?.withoutvalue201;
      params.node.setDataValue('withvalue20', withvalue20);
      params.node.setDataValue('withcost20', (withvalue20 / rowSoldVolume?.withvalue20).toFixed(2));
      params.node.setDataValue('volume2', identifiedSavingVolumeDrop.toFixed(2));
      params.node.setDataValue('withvalue201', volumedropWithSavingValue.toFixed(2));
      params.node.setDataValue('withcost201', volumedropWithSavingCost.toFixed(2));
    }
    this.getCalculations(this.rowData);
  }

  getCalculations(costElementTempArray: any): any {
    this.spinner.show();
    this.businessTrend = this.TdcSimulationForm.value.businessTrend ? this.TdcSimulationForm.value.businessTrend ?? 0 : 0;
    const initialVolValue = parseFloat(costElementTempArray.find((val: any) => val.tdc === 'Sold Volume').value19);
    // const initialVolCost = parseFloat(costElementTempArray.find((val: any) => val.tdc === 'Sold Volume').cost19);
    // const initialRevValue = parseFloat(costElementTempArray.find((val: any) => val.tdc === 'Total Revenue').value19);
    // const initialRevCost = parseFloat(costElementTempArray.find((val: any) => val.tdc === 'Total Revenue').cost19);
    const initialwithoutValue201 = initialVolValue * (100 % - this.businessTrend);
    let totalDeliveredValue19 = 0;
    let totalCost19 = 0;
    let totalvolume = 0;
    let totalwithoutvalue20 = 0;
    let totalwithoutcost20 = 0;
    let totalwithvalue20 = 0;
    let totalwithcost20 = 0;
    let totalwithoutvalue201 = 0;
    let totalwithoutcost201 = 0;
    let totalvolume2 = 0;
    let totalwithvalue201 = 0;
    let totalwithcost201 = 0;
    let tempValue19 = 0;
    let tempCost19 = 0;
    let tempVolume1 = 0;
    let tempwithoutvalue20 = 0;
    let tempwithoutcost20 = 0;
    let tempwithvalue20 = 0;
    let tempwithcost20 = 0;
    let tempwithoutvalue201 = 0;
    let tempwithoutcost201 = 0;
    let tempVolume2 = 0;
    let tempwithvalue201 = 0;
    let tempwithcost201 = 0;
    for (const val of costElementTempArray) {
      if (val.tdc !== 'Total Revenue' && val.tdc !== 'Sold Volume' && val.tdc !== 'Total Delivered Cost') {
        const index = costElementTempArray.findIndex((value: any) => value.tdc === val.tdc);
        tempValue19 = parseFloat(val.value19);
        tempCost19 = parseFloat(val.value19) / initialVolValue;
        tempVolume1 = parseFloat(val.volume);
        tempwithoutvalue20 = parseFloat(val.value19);
        tempwithoutcost20 = tempwithoutvalue20 / initialVolValue;
        tempwithvalue20 = parseFloat(val.value19) - val.volume;
        tempwithcost20 = tempwithvalue20 / initialVolValue;
        tempVolume2 = parseFloat(val.volume) * (100 % - this.businessTrend);
        tempwithoutvalue201 = parseFloat(val.value19) * (100 % - this.businessTrend);
        tempwithoutcost201 = tempwithoutvalue201 / initialwithoutValue201;
        tempwithvalue201 = tempwithoutvalue201 - tempVolume2;
        tempwithcost201 = tempwithvalue201 / initialwithoutValue201;

        // setting values values for [val] obj
        costElementTempArray[index].value19 = isNaN(tempValue19) || tempValue19 === Infinity ? 0 : tempValue19 ?? 0;
        costElementTempArray[index].cost19 = isNaN(tempCost19) || tempCost19 === Infinity ? 0 : tempCost19 ?? 0;
        costElementTempArray[index].volume = isNaN(tempVolume1) || tempVolume1 === Infinity ? 0 : tempVolume1 ?? 0;
        costElementTempArray[index].withoutvalue20 = isNaN(tempwithoutvalue20) ||
          tempwithoutvalue20 === Infinity ? 0 : tempwithoutvalue20 ?? 0;
        costElementTempArray[index].withoutcost20 = isNaN(tempwithoutcost20) || tempwithoutcost20 === Infinity ? 0 : tempwithoutcost20 ?? 0;
        costElementTempArray[index].withvalue20 = isNaN(tempwithvalue20) || tempwithvalue20 === Infinity ? 0 : tempwithvalue20 ?? 0;
        costElementTempArray[index].withcost20 = isNaN(tempwithcost20) || tempwithcost20 === Infinity ? 0 : tempwithcost20 ?? 0;
        costElementTempArray[index].volume2 = isNaN(tempVolume2) || tempVolume2 === Infinity ? 0 : tempVolume2 ?? 0;
        costElementTempArray[index].withoutvalue201 = isNaN(tempwithoutvalue201) || tempwithoutvalue201 === Infinity ?
          0 : tempwithoutvalue201 ?? 0;
        costElementTempArray[index].withoutcost201 = isNaN(tempwithoutcost201) || tempwithoutcost201 === Infinity ?
          0 : tempwithoutcost201 ?? 0;
        costElementTempArray[index].withvalue201 = isNaN(tempwithvalue201) || tempwithvalue201 === Infinity ? 0 : tempwithvalue201 ?? 0;
        costElementTempArray[index].withcost201 = isNaN(tempwithcost201) || tempwithcost201 === Infinity ? 0 : tempwithcost201 ?? 0;
        this.gridApi.setRowData(costElementTempArray);

        // Calculaiting Sum for TDC
        totalDeliveredValue19 = totalDeliveredValue19 + val.value19;
        totalCost19 = totalCost19 + val.cost19;
        totalvolume = totalvolume + parseFloat(val.volume);
        totalwithoutvalue20 = totalwithoutvalue20 + val.withoutvalue20;
        totalwithoutcost20 = totalwithoutcost20 + val.withoutcost20;
        totalwithvalue20 = totalwithvalue20 + val.withvalue20;
        totalwithcost20 = totalwithcost20 + val.withcost20;
        totalvolume2 = totalvolume2 + parseFloat(val.volume2);
        totalwithoutvalue201 = totalwithoutvalue201 + val.withoutvalue201;
        totalwithoutcost201 = totalwithoutcost201 + val.withoutcost201;
        totalwithvalue201 = totalwithvalue201 + val.withvalue201;
        totalwithcost201 = totalwithcost201 + val.withcost201;
      }
      else if (val.tdc === 'Sold Volume') {
        const index = costElementTempArray.findIndex((value: any) => value.tdc === val.tdc);
        costElementTempArray[index].withoutvalue20 = isNaN(val.value19) ? 0 : val.value19 ?? 0;
        costElementTempArray[index].withvalue20 = isNaN(val.value19) ? 0 : val.value19 ?? 0;
        costElementTempArray[index].withoutvalue201 = isNaN(initialwithoutValue201) ? 0 : initialwithoutValue201 ?? 0;
        costElementTempArray[index].withvalue201 = isNaN(initialwithoutValue201) ? 0 : initialwithoutValue201 ?? 0;
        this.gridApi.setRowData(costElementTempArray);
      }
    }

    // finding the TDC index and setting all the sum values
    const objIndex = costElementTempArray.findIndex((val: any) => val.tdc === 'Total Delivered Cost');
    costElementTempArray[objIndex].value19 = isNaN(totalDeliveredValue19) || totalDeliveredValue19 === Infinity ?
      0 : totalDeliveredValue19 ?? 0;
    costElementTempArray[objIndex].cost19 = isNaN(totalCost19) || totalCost19 === Infinity ? 0 : totalCost19 ?? 0;
    costElementTempArray[objIndex].volume = isNaN(totalvolume) || totalvolume === Infinity ? 0 : totalvolume ?? 0;
    costElementTempArray[objIndex].withoutvalue20 = isNaN(totalwithoutvalue20) || totalwithoutvalue20 === Infinity ?
      0 : totalwithoutvalue20 ?? 0;
    costElementTempArray[objIndex].withoutcost20 = isNaN(totalwithoutcost20) || totalwithoutcost20 === Infinity ?
      0 : totalwithoutcost20 ?? 0;
    costElementTempArray[objIndex].withvalue20 = isNaN(totalwithvalue20) || totalwithvalue20 === Infinity ? 0 : totalwithvalue20 ?? 0;
    costElementTempArray[objIndex].withcost20 = isNaN(totalwithcost20) || totalwithcost20 === Infinity ? 0 : totalwithcost20 ?? 0;
    costElementTempArray[objIndex].volume2 = isNaN(totalvolume2) || totalvolume2 === Infinity ? 0 : totalvolume2 ?? 0;
    costElementTempArray[objIndex].withoutvalue201 = isNaN(totalwithoutvalue201) || totalwithoutcost201 === Infinity ?
      0 : totalwithoutvalue201 ?? 0;
    costElementTempArray[objIndex].withoutcost201 = isNaN(totalwithoutcost201) || totalwithoutcost201 === Infinity ?
      0 : totalwithoutcost201 ?? 0;
    costElementTempArray[objIndex].withvalue201 = isNaN(totalwithvalue201) || totalwithvalue201 === Infinity ? 0 : totalwithvalue201 ?? 0;
    costElementTempArray[objIndex].withcost201 = isNaN(totalwithcost201) || totalwithcost201 === Infinity ? 0 : totalwithcost201 ?? 0;
    this.gridApi.setRowData(costElementTempArray);
    this.spinner.hide();
  }

  formatNumber(params: any): any {
    const num = params.value ? params.value : 0;
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    return Math.floor(num).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getTdcSimulationdata(): void {
    const val = this.TdcSimulationForm.get('year')?.value;
    this.columnDefs = [
      {
        headerName: 'TDC',
        resizable: true,
        headerClass: 'table-header',
        children: [
          {
            headerName: '',
            field: 'tdc',
            type: 'leftAligned',
            resizable: true,
            cellRenderer: (params: any) => {
              if (params.data && params.data.tdc) {
                return (
                  '<div class="area-name">' + params.data.tdc + '' + '</div>'
                );
              } else {
                return '';
              }
            },
            cellStyle: (params: any) => {
              if (params.data && params.data.tdc) {
                return { background: '#49FFB9' };
              } else {
                return { background: 'white' };
              }
            },
          },
        ],
      },
      {
        headerName: val,
        resizable: true,
        children: [
          {
            headerName: 'Value',
            field: 'value19',
            resizable: true,
            valueFormatter: (params: any) => {
              if (!params.value) { return 0; } else {
                return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
              }
            },
            editable: (params: any) => ((params.data.tdc === 'Total Revenue' || params.data.tdc === 'Sold Volume') && this.IsWrite) ? true : false,
            cellStyle: (params: any) => {
              // tslint:disable-next-line:max-line-length
              if (params.data.tdc === 'Total Revenue' || params.data.tdc === 'Sold Volume') {
                return { background: '#C6E0B4' };
              } else {
                return { background: '' };
              }
            },
          },
          {
            headerName: 'Unit/Cost',
            field: 'cost19',
            resizable: true,
            valueFormatter: (params: any) => {
              if (!params.value) { return 0; } else {
                return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
              }
            },
            editable: (params: any) => ((params.data.tdc === 'Total Revenue' || params.data.tdc === 'Sold Volume') && this.IsWrite) ? true : false,
            cellStyle: (params: any) => {
              // tslint:disable-next-line:max-line-length
              if (params.data.tdc === 'Total Revenue' || params.data.tdc === 'Sold Volume') {
                return { background: '#C6E0B4' };
              } else {
                return { background: '' };
              }
            },
          },
        ],
      },
      {
        headerName: 'Identified Saving',
        resizable: true,
        children: [
          {
            headerName: '100% Volume',
            field: 'volume',
            editable:this.IsWrite ? ((params: any) => (params.data.tdc === 'Total Revenue' || params.data.tdc === 'Sold Volume' || params.data.tdc === 'Total Delivered Cost') ? false : true) : false,
            resizable: true,
            cellStyle: (params: any) => {
              if (params.data) {
                // tslint:disable-next-line:max-line-length
                if (params.data.tdc === 'Total Revenue' || params.data.tdc === 'Sold Volume' || params.data.tdc === 'Total Delivered Cost') {
                  return { background: 'white' };
                } else {
                  return { background: '#C6E0B4' };
                }
              }
              else {
                return { background: 'white' };
              }
            },
          },
        ],
      },
      {
        headerName: val + 1,
        resizable: true,
        children: [
          {
            headerName: 'with Same Volume & w/o Saving',
            resizable: true,
            children: [
              {
                headerName: 'Value',
                field: 'withoutvalue20',
                resizable: true,
              },
              {
                headerName: 'Cost/Unit',
                field: 'withoutcost20',
                resizable: true,
              },
            ],
          },
        ],
      },
      {
        headerName: val + 1,
        resizable: true,
        children: [
          {
            headerName: 'with Same Volume & with Saving',
            resizable: true,
            children: [
              {
                headerName: 'Value',
                field: 'withvalue20',
                resizable: true,
              },
              {
                headerName: 'Cost/Unit',
                field: 'withcost20',
                resizable: true,
              },
            ],
          },
        ],
      },
      {
        headerName: 'Identified Saving',
        resizable: true,
        children: [
          {
            headerName: '100% Volume',
            field: 'volume2',
            // editable: (params: any) => (params.data.tdc === 'Total Revenue' || params.data.tdc === 'Sold Volume' ||
            // params.data.tdc === 'Total Delivered Cost') ? false : true,
            resizable: true,
            // cellStyle: (params: any) => {
            //   if (params.data.tdc === 'Total Revenue' || params.data.tdc === 'Sold Volume' ||
            // params.data.tdc === 'Total Delivered Cost') {
            //     return { background: 'white' };
            //   } else {
            //     return { background: '#C6E0B4' };
            //   }
            // },
          },
        ],
      },
      {
        headerName: val + 1,
        resizable: true,
        children: [
          {
            headerName: 'with Volume Drop & w/o Saving',
            resizable: true,
            children: [
              {
                headerName: 'Value',
                field: 'withoutvalue201',
                resizable: true,
              },
              {
                headerName: 'Cost/Unit',
                field: 'withoutcost201',
                resizable: true,
              },
            ],
          },
        ],
      },
      {
        headerName: val + 1,
        resizable: true,
        children: [
          {
            headerName: 'with Volume Drop & with Saving',
            resizable: true,
            children: [
              {
                headerName: 'Value',
                field: 'withvalue201',
                resizable: true,
              },
              {
                headerName: 'Cost/Unit',
                field: 'withcost201',
                resizable: true,
              },
            ],
          },
        ],
      },
    ];
    this.spinner.show();
    const key = {
      plantId: this.TdcSimulationForm.get('plant')?.value,
      year: this.TdcSimulationForm.get('year')?.value,
      businessTrend: this.TdcSimulationForm.get('businessTrend')?.value,
    };
    this.tdcservice.getCostElementData(key).subscribe(
      (dataArray: any) => {
        const costElementTempArray: any[] = [];
        dataArray.forEach((element: any) => {
          const costElement =
            element?.costElement === null ? [{}] : element?.costElement;
          const tempindex = 0;
          if (costElement) {
            for (const item of costElement) {
              const tempelement = {
                id: element?.id,
                tdc: element?.parentName,
                value19: item?.TdcValue,
                cost19: item?.Cost,
                volume: item?.IdentifiedSavingVolumn,
                withoutvalue20: item?.SameVolumnWOSavingValue,
                withoutcost20: item?.SameVolumnWOSavingCost,
                withvalue20: item?.SameVolumnWithSavingValue,
                withcost20: item?.SameVolumnWithSavingCost,
                volume2: item?.IdentifiedSavingDropVolumn,
                withoutvalue201: item?.VolumnDropWOSavingValue,
                withoutcost201: item?.VolumnDropWOSavingCost,
                withvalue201: item?.VolumnDropWithSavingValue,
                withcost201: item?.VolumnDropWithSavingCost,
              };
              costElementTempArray.push(tempelement);
            }
          }
        });
        this.rowData = costElementTempArray;
        this.getCalculations(this.rowData);
        this.getRowNodeId = data => data.id;
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
}
