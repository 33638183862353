import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-prev-task-dialog',
  templateUrl: './prev-task-dialog.component.html',
  styleUrls: ['./prev-task-dialog.component.scss']
})
export class PrevTaskDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PrevTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private toaster: ToastrService,) { }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  onSaveClick(){
    if(this.data.ItemName == '' || this.data.ItemCode == ''){
      this.toaster.warning('Please Fill Item Fields');
      return;
    }
    else{
      this.dialogRef.close(this.data);
    }
  }

  ngOnInit(): void {
  }

}
