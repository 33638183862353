import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { endOfMonth, startOfMonth, startOfYesterday } from 'date-fns';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
import { DtServiceService } from 'src/app/services/dtService.service';
import { KPIMilestoneService } from 'src/app/services/KPI-milestone.service';
import { KpiGroupService } from 'src/app/services/kpigroups.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ToggleBtnCellRenderer } from 'src/app/toggle-btn-cell-renderer';
declare var window: any;
@Component({
  selector: 'app-KPI-milestone',
  templateUrl: './KPI-milestone.component.html',
  styleUrls: ['./KPI-milestone.component.scss'],
})
export class KPIMilestoneComponent implements OnInit {
  @ViewChild('milestoneModal')
  milestoneModal: any;
  @ViewChild('KPIMilestoneModal') KPIMilestoneModal: any;
  @ViewChild('KPIModal') KPIModal: any;
  milestoneForm: FormGroup;
  KPIForm: FormGroup;
  filtersForm: FormGroup;
  groupDisplayType = 'custom';
  today = new Date().setHours(0, 0, 0, 0);
  rowData: any = [];
  ranges = {
    Today: [new Date(), new Date()],
    Yesterday: [startOfYesterday(), new Date()],

    'This Month': [startOfMonth(new Date()), endOfMonth(new Date())],
  };
  milestoneGroupSource = new MatTreeNestedDataSource();

  gridApi: any;
  gridColumnApi: any;

  data: any = [];
  selectedMilestone: any;
  isSubmit: boolean;
  isUpadingMilestone = false;
  filteredPlant!: any[];
  isUpdatingKPI = false;
  groupList: any[] = [];
  groupTempList!: any[];
  plantList: any = [];
  plantTempList: any = [];
  KPIGroups: any = [];
  KPIGroupsTempList: any = [];
  KPIs: any = [];
  KPIsTempList: any = [];
  frequencies!: any[];
  IsWrite: any;
  IsAccess: any;

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    resizable: true,
  };
  public autoGroupColumnDef: ColDef = {
    minWidth: 200,
  };
  gridOptions: any = {
    enableGroupEdit: true,
    groupDisplayType: this.groupDisplayType,
    groupDefaultExpanded: 0,
  };
  breadcrumList: any;
  moduleId: any;
  pageId: any;
  domLayout: string = 'autoHeight';
  loadingOverlayComponent: string;
  frameworkComponents: any = {};
  frequencyId: any;
  frequenciesTempList: any[];
  selectedKPIs: any = [];
  langObj: any = {};
  columnDefs: any = [];
  languageDir = 'ltr';
  // KPITarget: FormArray;

  constructor(
    private fb: FormBuilder,
    private dtService: DtServiceService,
    private dialog: MatDialog,
    private kpiMilestoneService: KPIMilestoneService,
    private kpiGroupService: KpiGroupService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private kpimaster: KpimasterService,
    public translater: TranslaterService,
    public router: Router,
  ) {
    LicenseManager.setLicenseKey('kazaz');
    this.loadingOverlayComponent = 'customLoadingOverlay';

    this.frameworkComponents = {
      buttonRenderer: ToggleBtnCellRenderer,
      customLoadingOverlay: CustomLoadingOverlay,
    };
    this.columnDefs = [
      {
        field: 'milestoneName',
        headerName: 'Milestone Name',
        rowGroup: true,
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },

      {
        field: 'KPIName',
        headerName: 'Milestone/KPI Name',
        minWidth: 250,
        cellRenderer: 'agGroupCellRenderer',
        showRowGroup: true,
        headerValueGetter: this.localizeHeader.bind(this)

        // singleClickEdit: true,
        // editable: (params: any) => params.node.uiLevel === 1,
      },
      {
        field: 'actions',
        hide: this.IsWrite ? false : true,
        headerName: 'Actions',
        cellRenderer: (data) => {
          if (data.node.uiLevel === 0) {
            return `
            <div>
            <button mat-mini-fab="" color="accent" class="add-kpi mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="add-kpi mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">add</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" color="warn" class="delete-milestone mat-focus-indicator mat-mini-fab mat-button-base mat-warn" ng-reflect-color="warn"><span class="mat-button-wrapper"><mat-icon role="img" class="delete-milestone mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">delete</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" color="primary" class="edit-milestone mat-focus-indicator mat-mini-fab mat-button-base mat-accent" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="edit-milestone mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            </div>
          `;
          }
          if (data.node.uiLevel === 1 && data.data.Mtd_Id) {
            // console.log({ data });
            return `
            <div>
            <button mat-mini-fab="" color="warn" class="delete-kpi mat-focus-indicator mat-mini-fab mat-button-base mat-warn" ng-reflect-color="warn"><span class="mat-button-wrapper"><mat-icon role="img" class="delete-kpi mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">delete</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            </div>
          `;
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'KPITarget',
        headerName: 'Milestone/KPI Target',
        editable: (params: any): boolean =>
          params.node.uiLevel === 0 ? false : true,
        singleClickEdit: true,

        cellRenderer: (params: any) => {
          if (params.node.uiLevel == 0) {
            return;
          }
          if (params.node.uiLevel == 1) {
            if (
              params.node?.allLeafChildren &&
              params.node?.allLeafChildren.length
            ) {
              let sum = 0;
              params.node?.allLeafChildren?.forEach((element) => {
                sum += +element.data.KPITarget;
              });
              return sum.toFixed().toString();
            } else {
              return params.data.KPITarget;
            }

            //sum of childs kpi target

            // return '1250';
          } else {
            return params.data.KPITarget;
          }
        },
        width: 177,
        headerValueGetter: this.localizeHeader.bind(this)
      },

      {
        field: 'fromDate',
        headerName: 'From Date',
        cellRenderer: (params: any) => {
          if (params.node.uiLevel === 0) {
            let fromDate;
            if (
              params.node.allLeafChildren &&
              params.node.allLeafChildren.length > 0
            ) {
              fromDate = params.node.allLeafChildren[0].data.fromDate;
            } else {
              fromDate = params.data.fromDate;
            }
            return fromDate ? moment(fromDate).format('DD MMMM YYYY') : '';
          }
        },
        width: 130,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'toDate',
        headerName: 'To Date',
        cellRenderer: (params: any) => {
          if (params.node.uiLevel === 0) {
            let toDate;
            if (
              params.node.allLeafChildren &&
              params.node.allLeafChildren.length > 0
            ) {
              toDate = params.node.allLeafChildren[0].data.toDate;
            } else {
              toDate = params.data.toDate;
            }

            return toDate ? moment(toDate).format('DD MMMM YYYY') : '';
          }
        },
        width: 130,
        headerValueGetter: this.localizeHeader.bind(this)
      },

      // {
      //   field: 'isMilestoneLocked',
      //   headerName: 'Lock Milestone',
      //   cellRenderer: 'buttonRenderer',
      //   width: 130,
      // },
    ];
  }

  ngOnInit() {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.createFiltersForm();
    this.getFilters();
    this.createForm();
    this.createKPIForm();
    this.getKPIsByPlantAndFreq();
    // this.gridOptions = this.buildGridOptions(this);
  }
  // menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  createFiltersForm() {
    this.filtersForm = this.fb.group({
      plantId: [],
      group_Id: [],
    });
  }
  createForm() {
    this.milestoneForm = this.fb.group({
      milestoneName: ['', Validators.required],
      fromDate: [''],
      toDate: ['', Validators.required],

      // KPIGroupId: [{ value: '', disabled: false }, Validators.required],
      // isMilestoneLocked: [false],
    });
  }
  createKPIForm() {
    this.KPIForm = this.fb.group({
      kpiId: ['', Validators.required],
      KPITarget: [''],
      frequencyId: [''],
    });
  }

  _KPITarget(): FormArray {
    return this.KPIForm.get('KPITarget') as FormArray;
  }
  getFilters() {
    this.getGroups();
    this.getKPIGroups();
  }
  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.spinner.show();

    this.dtService.getGroupData(key).subscribe(
      (response: any) => {
        this.filtersForm.patchValue({
          group_Id: response?.masterList[0]?.TypeId,
        })
        this.groupList = response.masterList;
        this.groupTempList = this.groupList.slice();
        this.spinner.hide();

        this.getPlants();
      },
      (error: any) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      }
    );
  }
  getKPIGroups(): void {
    const key = {
      ptId: this.filtersForm.value.plantId,
    };
    this.spinner.show();
    this.kpiGroupService.GetKPIGroups(key).subscribe(
      (res) => {
        this.KPIGroups = res;
        this.KPIGroupsTempList = this.KPIGroups.slice();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error!', error.message);
        this.spinner.hide();
      }
    );
  }
  getPlants() {
    const key = {
      GroupId: this.filtersForm.value.group_Id,
    };

    this.spinner.show();
    this.dtService.getPlantData(key).subscribe(
      (res: any) => {
        this.spinner.hide();

        if (!res.HasError) {
          this.plantList = res.DepartList;
          this.plantTempList = this.plantList.slice();
          this.filtersForm.patchValue({
            plantId: this?.plantList[0]?.DeptId,
          });
          this.onPlantChange();
          this.getFrequencies();
        } else {
          this.toaster.error('Error', res.ErrorMessage);
        }
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }
  getFrequencies(): void {
    const key = {
      plantId: this.filtersForm.value.plantId,
    };
    this.kpimaster.getMasterFrequencies(key).subscribe(
      (frequencies) => {
        this.frequencies = frequencies;
        this.frequenciesTempList = this.frequencies.slice();
        if (frequencies.length) {
          this.KPIForm.patchValue({
            frequencyId: this.frequencies[0].Id,
          });
        }
        this.onFrequencyChange();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  onFrequencyChange() {
    this.getKPIsByPlantAndFreq();
  }
  resetKPITargetArray() {
    const formArray = this.KPIForm.get('KPITarget') as FormArray;
    formArray.controls = [];
    const control = <FormArray>this.KPIForm.controls['KPITarget'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i);
    }
  }
  onType(e) {
  }
  getKPIsByPlantAndFreq() {
    this.spinner.show();
    const key = {
      plantId: this.filtersForm.value.plantId,
      frequencyId: this.KPIForm.value.frequencyId,
    };
    this.kpimaster.getKpiFrequency(key).subscribe(
      (response) => {
        this.KPIs = response;
        this.KPIsTempList = this.KPIs.slice();
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }
  onPlantChange() {
    this.getKPIsByPlantAndFreq();
    this.getKPIGroups();
    this.getMilestoneAndKPIs();
  }

  // ----------------Grid Configuration------------------
  onCellClicked(params: any) {
    const col = params.colDef.field;
    const className = params.event.target.className;
    this.selectedMilestone = params.node.allLeafChildren[0].data;
    if (col === 'lockMilestone') {
    }

    if (col === 'actions') {
      if (className.includes('add-kpi')) {
        this.addKPI(params.node.allLeafChildren[0].data);
      }
      if (className.includes('edit-milestone')) {
        if (params.node.allLeafChildren && params.node.allLeafChildren.length) {
          this.editMilestone(params.node.allLeafChildren[0].data); //we can pass any index since all have the same data except KPI name and target
        } else {
          this.editMilestone(params.data);
        }
      }
      if (className.includes('delete-milestone')) {
        if (params.node.allLeafChildren && params.node.allLeafChildren.length) {
          this.deleteMilestone(params.node.allLeafChildren[0].data);
        } else {
          this.deleteMilestone(params.data);
        }
      }
      if (className.includes('delete-kpi')) {
        this.deleteKPI(params.data);
      }
    }
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout('autoHeight');
    // this.getMilestoneAndKPIs();
  }
  onCellValueChanged(params: any) {
    const col = params.colDef.field;
    const newValue = params.newValue;
    const uiLevel = params.node.uiLevel;
    const arr: any = params.node.allLeafChildren;
    let itemsToUpdate: any = [];

    if (col === 'KPIName') {
      if (uiLevel === 1) {
        const node = params.node.allLeafChildren[0].data;

        const data = {
          Mt_Id: node.Mt_Id,
          milestoneName: params.data.KPIName,
          // isMilestoneLocked: node.isMilestoneLocked,
          userId: JSON.parse(localStorage.user).UserId,
        };

        this.updateMilestone(data, params, false);
      }
    }
    // if (col === 'isMilestoneLocked') {
    //   if (uiLevel === 1) {
    //     if (params.node.allLeafChildren && params.node.allLeafChildren.length) {
    //       const node = params.node.allLeafChildren[0].data;
    //       const data = {
    //         Mt_Id: node.Mt_Id,
    //         isMilestoneLocked: params.data.isMilestoneLocked,
    //         milestoneName: node.milestoneName,
    //         userId: JSON.parse(localStorage.user).UserId,
    //       };
    //       this.updateMilestone(data, params, false);
    //     } else {
    //       const data = {
    //         Mt_Id: params.data.Mt_Id,
    //         isMilestoneLocked: params.data.isMilestoneLocked,
    //         milestoneName: params.data.milestoneName,
    //         userId: JSON.parse(localStorage.user).UserId,
    //       };
    //       this.updateMilestone(data, params);
    //     }
    //   }
    // }
    if (col === 'KPITarget') {
      if (uiLevel === 1) {
        this.updateKPITarget(newValue, params.data.Mtd_Id);
      }
    }
  }
  updateKPITarget(KPITarget, Mtd_Id) {
    this.spinner.show();
    if (KPITarget === '') {
      this.toaster.error('Error', 'Please enter KPI Target');
    }
    if (Mtd_Id == '') {
      this.toaster.error('Error', 'Please select KPI');
      this.spinner.hide();
      return;
    }
    this.kpiMilestoneService
      .UpdateMilestoneKPITarget({ KPITarget, Mtd_Id })
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.toaster.success('Success', 'KPI Target Updated Successfully');
        },
        ({ message }) => {
          this.spinner.hide();
          this.toaster.error('Error', message);
        }
      );
  }

  onKPITargetEnter(KPITargetFormLength, i, e) {
    let KPITarget = new Array(KPITargetFormLength);
    KPITarget[i] = e.target.value;
  }

  createKPITarget(KPIId): FormGroup {
    return this.fb.group({
      KPIId: KPIId,
      KPI_Target: [''],
    });
  }
  onKPISelect(e: any) {
    // console.log(e);
    // this.selectedKPIs = e.value;
    // if (e.value.length == 0) {
    //   this.resetKPITargetArray();
    // }
    // let formArrayValues = this.KPITargetGetter.value;
    // formArrayValues = formArrayValues.map((x) => x.KPIId);
    // console.log(formArrayValues, e.value);
    // const control = <FormArray>this.KPIForm.controls['KPITarget'];
    // if (formArrayValues.length >= 1) {
    //   let toBeDeleted = [];
    //   let toBeInserted = [];
    //   e.value.forEach((x) => {
    //     if (!formArrayValues.includes(x)) {
    //       toBeInserted.push(x);
    //     }
    //   });
    //   formArrayValues.forEach((x) => {
    //     if (!e.value.includes(x)) {
    //       toBeDeleted.push(x);
    //     }
    //   });
    //   console.log({ toBeInserted, toBeDeleted });
    //   toBeInserted.forEach((element) => {
    //     control.push(this.createKPITarget(element));
    //   });
    //   toBeDeleted.forEach((element) => {
    //     control.removeAt(element);
    //   });
    // } else {
    //   control.push(this.createKPITarget(e.value[0]));
    //   console.log(this.KPITargetGetter.value);
    // }
  }

  get KPITargetGetter() {
    return this.KPIForm.get('KPITarget') as FormArray;
  }
  // ---------------------------------------------//

  getMilestoneAndKPIs() {
    const data = {
      ...this.filtersForm.value,
    };
    this.spinner.show();
    this.gridApi.showLoadingOverlay();
    this.kpiMilestoneService.GetMilestoneTargetMaster(data).subscribe(
      (data) => {
        this.spinner.hide();

        this.setRowDataWithState(data);
        this.gridApi.hideOverlay();
      },
      (error) => {
        this.spinner.hide();
        this.gridApi.hideOverlay();

        this.toaster.error(error.error.message);
      }
    );
  }
  setRowDataWithState(data: any) {
    window.groupState = this.gridColumnApi.getColumnGroupState();
    this.gridApi.setRowData(data);
    this.gridColumnApi.setColumnGroupState(window.groupState);
  }
  addMilestone() {
    this.milestoneForm.reset();
    const dialogRef = this.dialog.open(this.milestoneModal, {
      height: 'auto',
      width: 'auto',
    });
  }
  editMilestone(data: any) {
    this.milestoneForm.patchValue(data);
    this.selectedMilestone = data;

    this.isUpadingMilestone = true;
    const dialogRef = this.dialog.open(this.milestoneModal, {
      height: 'auto',
      width: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.isUpadingMilestone = false;
    });
  }
  addKPI(data: any) {
    this.KPIForm.reset();
    this.resetKPITargetArray();
    this.selectedMilestone = data;
    this.dialog.open(this.KPIModal, {
      height: '340px',
      width: '540px',
    });
  }

  deleteMilestone(params: any) {
    const data = {
      Mt_Id: params.Mt_Id,
    };
    this.spinner.show();
    this.kpiMilestoneService.DeleteMilestoneTargetMaster(data).subscribe(
      (data) => {
        this.spinner.hide();
        this.toaster.success('Milestone deleted successfully');
        this.getMilestoneAndKPIs();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error(error.error.message);
      }
    );
  }
  deleteKPI(params: any) {
    const data = {
      Mtd_Id: params.Mtd_Id,
    };
    this.spinner.show();

    this.kpiMilestoneService.DeleteMilestoneKPI(data).subscribe(
      (data) => {
        this.spinner.hide();
        this.toaster.success('KPI Deleted Successfully');
        this.getMilestoneAndKPIs();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.error.message);
      }
    );
  }

  saveMilestone() {
    this.isSubmit = true;
    if (this.milestoneForm.invalid) {
      this.milestoneForm.markAllAsTouched();
      return;
    }
    if (this.milestoneForm.value.fromDate > this.milestoneForm.value.toDate) {
      this.toaster.error('From date should be less than to date');
      return;
    }
    // if (
    //   this.milestoneForm.value.fromDate < this.today ||
    //   this.milestoneForm.value.toDate < this.today
    // ) {
    //   this.toaster.error('Can not add milestone for past date');
    //   return;
    // }
    const data = {
      userId: JSON.parse(localStorage.user).UserId,
      fromDate: this.milestoneForm.value.fromDate.setHours(0, 0, 0, 0),
      toDate: this.milestoneForm.value.toDate.setHours(23, 59, 59, 0),
      ...this.milestoneForm.value,
      plantId: this.filtersForm.value.plantId,
    };
    this.spinner.show();

    this.kpiMilestoneService.SaveAddMilestoneTargetMaster(data).subscribe(
      (data) => {
        this.toaster.success('KPI Milestone Added Successfully');
        this.spinner.hide();
        this.getMilestoneAndKPIs();

        this.dialog.closeAll();
        this.isSubmit = false;
      },
      (error: any) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
        this.isSubmit = false;
      }
    );
  }
  saveKPI() {
    if (this.KPIForm.invalid) {
      this.KPIForm.markAllAsTouched();
      return;
    }

    this.isSubmit = true;
    this.KPIForm.value.KPITarget < 1
      ? this.KPIForm.get('KPITarget').setValue(0)
      : this.KPIForm.value.KPITarget;
    const data = {
      Mt_Id: this.selectedMilestone.Mt_Id,
      ...this.KPIForm.value,
    };

    this.spinner.show();
    this.kpiMilestoneService.ManuallyAddKPIToMilestone(data).subscribe(
      (response) => {
        this.toaster.success('KPI Added to milestone Successfully');

        // this.rowData.push({ ...this.selectedMilestone, ...data });
        // this.gridApi.setRowData(data);
        this.getMilestoneAndKPIs();

        // this.getMilestoneAndKPIs();
        this.spinner.hide();
        // this.getMilestoneAndKPIs();

        this.dialog.closeAll();
        this.isSubmit = false;
      },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error(err?.error?.message);

        this.isSubmit = false;
      }
    );
  }

  updateMilestone(milestone: any, params: any, isRefresh?) {
    // this.milestoneForm.controls.KPIGroupId.disable();
    const fromDate = new Date(this.milestoneForm.value.fromDate).setHours(
      0,
      0,
      0,
      0
    );
    const toDate = new Date(this.milestoneForm.value.toDate).setHours(
      23,
      59,
      59,
      0
    );
    this.isSubmit = true;
    if (this.milestoneForm.invalid) {
      this.milestoneForm.markAllAsTouched();
      return;
    }
    if (fromDate > toDate) {
      this.toaster.error('From date should be less than to date');
      return;
    }
    // if (
    //   this.milestoneForm.value.fromDate < this.today ||
    //   this.milestoneForm.value.toDate < this.today
    // ) {
    //   this.toaster.error('Can not add milestone for past date');
    //   return;
    // }
    const data = {
      fromDate,
      toDate,
      ...this.milestoneForm.value,
      plantId: this.filtersForm.value.plantId,
      Mt_Id: this.selectedMilestone.Mt_Id,
    };
    this.spinner.show();
    // this.milestoneForm.controls.KPIGroupId.disable();
    this.kpiMilestoneService.UpdateMilestoneTargetMaster(data).subscribe(
      (response: any) => {
        this.getMilestoneAndKPIs();
        this.dialog.closeAll();
        this.toaster.success('Milestone Updated Successfully');
        this.spinner.hide();
        if (isRefresh) {
          this.getMilestoneAndKPIs();
        }
      },
      (err: any) => {
        this.toaster.error(err.error.message);
        this.spinner.hide();
      }
    );
  }

  get m() {
    // getter for milestoneForm
    return this.milestoneForm.controls;
  }
  get k() {
    // getter for KPIForm
    return this.KPIForm.controls;
  }

  returnKPIName(id: any) {
    const kpi = this.KPIs.find((x) => x.kpiId == id);
    return kpi ? kpi.name : '';
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value:string){
    let val;
    if(this.langObj && this.langObj[value]){
      val = this.langObj[value];
    }
    return val?val:value;
  }
  getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1,
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if(this.langObj){
        this.gridApi.refreshHeader();
      }
    });
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpimaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        this.columnDefs = [
          {
            field: 'milestoneName',
            headerName: 'Milestone Name',
            rowGroup: true,
            hide: true,
            headerValueGetter: this.localizeHeader.bind(this)
          },

          {
            field: 'KPIName',
            headerName: 'Milestone/KPI Name',
            minWidth: 250,
            cellRenderer: 'agGroupCellRenderer',
            showRowGroup: true,
            headerValueGetter: this.localizeHeader.bind(this)

            // singleClickEdit: true,
            // editable: (params: any) => params.node.uiLevel === 1,
          },
          {
            field: 'actions',
            hide: this.IsWrite ? false : true,
            headerName: 'Actions',
            cellRenderer: (data) => {
              if (data.node.uiLevel === 0) {
                return `
            <div>
            <button mat-mini-fab="" color="accent" class="add-kpi mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="add-kpi mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">add</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" color="warn" class="delete-milestone mat-focus-indicator mat-mini-fab mat-button-base mat-warn" ng-reflect-color="warn"><span class="mat-button-wrapper"><mat-icon role="img" class="delete-milestone mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">delete</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" color="primary" class="edit-milestone mat-focus-indicator mat-mini-fab mat-button-base mat-accent" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="edit-milestone mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            </div>
          `;
              }
              if (data.node.uiLevel === 1 && data.data.Mtd_Id) {
                return `
            <div>
            <button mat-mini-fab="" color="warn" class="delete-kpi mat-focus-indicator mat-mini-fab mat-button-base mat-warn" ng-reflect-color="warn"><span class="mat-button-wrapper"><mat-icon role="img" class="delete-kpi mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">delete</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            </div>
          `;
              }
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'KPITarget',
            headerName: 'Milestone/KPI Target',
            editable: (params: any): boolean =>
              params.node.uiLevel === 0 ? false : true,
            singleClickEdit: true,

            cellRenderer: (params: any) => {
              if (params.node.uiLevel == 0) {
                return;
              }
              if (params.node.uiLevel == 1) {
                if (
                  params.node?.allLeafChildren &&
                  params.node?.allLeafChildren.length
                ) {
                  let sum = 0;
                  params.node?.allLeafChildren?.forEach((element) => {
                    sum += +element.data.KPITarget;
                  });
                  return sum.toFixed().toString();
                } else {
                  return params.data.KPITarget;
                }

                //sum of childs kpi target

                // return '1250';
              } else {
                return params.data.KPITarget;
              }
            },
            width: 177,
            headerValueGetter: this.localizeHeader.bind(this)
          },

          {
            field: 'fromDate',
            headerName: 'From Date',
            cellRenderer: (params: any) => {
              if (params.node.uiLevel === 0) {
                let fromDate;
                if (
                  params.node.allLeafChildren &&
                  params.node.allLeafChildren.length > 0
                ) {
                  fromDate = params.node.allLeafChildren[0].data.fromDate;
                } else {
                  fromDate = params.data.fromDate;
                }
                return fromDate ? moment(fromDate).format('DD MMMM YYYY') : '';
              }
            },
            width: 130,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'toDate',
            headerName: 'To Date',
            cellRenderer: (params: any) => {
              if (params.node.uiLevel === 0) {
                let toDate;
                if (
                  params.node.allLeafChildren &&
                  params.node.allLeafChildren.length > 0
                ) {
                  toDate = params.node.allLeafChildren[0].data.toDate;
                } else {
                  toDate = params.data.toDate;
                }

                return toDate ? moment(toDate).format('DD MMMM YYYY') : '';
              }
            },
            width: 130,
            headerValueGetter: this.localizeHeader.bind(this)
          },

          // {
          //   field: 'isMilestoneLocked',
          //   headerName: 'Lock Milestone',
          //   cellRenderer: 'buttonRenderer',
          //   width: 130,
          // },
        ];
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
}
