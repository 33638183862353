import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MasterDepartmentService } from 'src/app/services/master-department.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { PositionMasterComponent } from '../position-master/position-master.component';
import { EmployeeMasterComponent } from '../employee-master/employee-master.component';
import { EmployeePositionMappingComponent } from '../employee-position-mapping/employee-position-mapping.component';


@Component({
  selector: 'app-people-master-data',
  templateUrl: './people-master-data.component.html',
  styleUrls: ['./people-master-data.component.scss']
})
export class PeopleMasterDataComponent implements OnInit {
groupList!: any[];
plantList!: any[];
searchMasterForm!: FormGroup;
groupListDDL!:any[];
GroupId!: number;
@ViewChild(PositionMasterComponent) PositionMasterComponent!: PositionMasterComponent;
@ViewChild(EmployeeMasterComponent) EmployeeMasterComponent!: EmployeeMasterComponent;
@ViewChild(EmployeePositionMappingComponent) EmployeePositionMappingComponent!: EmployeePositionMappingComponent;
constructor(private machineService : MachineMappingService,private toaster: ToastrService,private roleService: MasterDepartmentService,private fb: FormBuilder,private breadcum: UserManagementService,public router: Router,private enterpriseGroupService: EnterPriseGroupService,) { }

ngOnInit() {
  this.getGroupsData();
  this.searchMasterForm = this.fb.group({
    group: ['', Validators.required],
    plant: ['', Validators.required]
  });
}

getGroupsData(): void {
  const key = {
    Id: 0,
  };
  this.roleService.getGroupData(key).subscribe((data: any) => {
    this.groupList = data.masterList;
    this.searchMasterForm.get('group')?.setValue(this.groupList[0].TypeId);
    this.getPlantData();
  },
    (error:any) => {
      this.toaster.error('Error', error.error.message);
    }
  );
}
getMasterData(): void {
  const key = {
    EGId: this.searchMasterForm.value.group,
    PlantId: this.searchMasterForm.value.plant
  };
  this.EmployeeMasterComponent.getEmployeeMasterData(key);
  this.PositionMasterComponent.getPositionMasterData(key);
  this.EmployeePositionMappingComponent.getEmployeePositionMappedData(key);
}

getPlantData(): any {
  const key = {
    GroupId : this.searchMasterForm.value.group
  };
  this.machineService.getPlantsData(key).subscribe((data: any) => {
    this.plantList = data.DepartList;
    this.searchMasterForm.get('plant')?.setValue(data.DepartList[0].DeptId);
    this.getMasterData();
  }, (error: any) => {
    this.toaster.error('Error', error.message);
  });
}
}
