<ngx-spinner bdColor="rgba(0, 0, 0, 0.2)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
<div class="defect-management-content">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    </div>
    <mat-card>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6 p-lg-7 p-xl-7">
          <h1 class="main-title">Defect Management</h1>
        </div>
        <div class="p-col-12 p-md-6 p-lg-5 p-xl-5" style="display: flex; justify-content: flex-end;">
          <form [formGroup]="DefectDataForm" style="float: right;">
            <div style="display: flex;justify-content: space-between;">
              <mat-form-field appearance="fill" class="field-fill" style="margin-right:20px ;">
                <mat-label>Group</mat-label>
                <mat-select (selectionChange)="OnChangeGroup($event)" formControlName="group">
                 <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groups"
                 (filteredReturn)="filteredGroups = $event"></mat-select-filter>
                  <mat-option *ngFor="let group of filteredGroups" [value]="group.TypeId">
                    {{ group.TypeName }}
                  </mat-option>
                </mat-select>
               </mat-form-field>
               <mat-form-field appearance="fill" class="field-fill">
                <mat-label>Select Plant</mat-label>
              <mat-select panelClass="testClass" name="plant" formControlName="plant" (selectionChange)="onPlantChange()">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plants"
              (filteredReturn)="filteredPlant = $event"></mat-select-filter>
                <mat-option *ngFor="let p of filteredPlant" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
              </mat-select>
              <mat-error>Please Select Plant</mat-error>
            </mat-form-field>
            </div>
          </form>
        </div>
      </div>
    </mat-card>


    <form [formGroup]="DefectDataForm">
    <div class="wrapper-box wrapper-box-shadow grid-container">

      <!-- <div  class="p-mt-0 p-pt-0"> -->
        <div class="d-flex justify-content-between" style="margin: 24px 0 -9px 0;">

            <button *ngIf="IsWrite" mat-mini-fab mat-button color="accent" (click)="opencreateDefectGroupModel()"
              pTooltip="Create Defect" tooltipPosition="top" >
              <i class="fa fa-plus" style="color: #fff"></i>
            </button>

            <div class="d-flex justify-content-between" style="margin-top: 20px;">
              <div class="score-cards-wrapper">
                <mat-card class="opendefect" style="margin-left: 4px">
                  <b color="warn">Open Defect : {{OpenedDefect}}</b>
                  
                </mat-card>
                <mat-card class="scores" style="margin-left: 12px">
                  <b>Close Defect : {{ClosedDefect}}</b>
                </mat-card>
                <mat-card class="fixing" style="margin-left: 12px">
                  <b>Defect Fix % : {{Fixing | number : '1.2-2'}}</b>
                </mat-card>
              </div>
            </div>
            <mat-form-field appearance="fill" class="field-fill-custom-master" style="margin-right: 10px;bottom: 10px">
              <mat-label>Search</mat-label>
              <input matInput class="input-text"  [value]="searchValue" (keyup)="onFilterChanged($event.target)" />
              <mat-error>Please Select Plant</mat-error>
            </mat-form-field>

        </div>
        <ag-grid-angular style="width: 100%; height:30vw" class="ag-theme-alpine" [rowData]="rowData"
        [columnDefs]="columnDefs" [gridOptions]="gridOptions" (cellClicked)="cellClicked($event)"
        (gridReady)="onGridReady($event)" [pagination]="true">
      </ag-grid-angular>
</div>
</form>

  <!-- add new table row model -->
  <div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{'active': iscreateDeffectGroupModelVisible}">
    <div class="sidepanel-close" (click)="closeaddNewVariableModel()"></div>
    <div class="model-content">
      <div class="model-top">
        <h2 class="model-title">Create Defect</h2>
        <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeaddNewVariableModel()">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
              fill="black" />
          </svg>
        </button>
      </div>
      <div class="model-body">
        <form [formGroup]="addNewVariableItemlForm" (ngSubmit)="addNewVariableItemlFormSubmit()">
          <div class="form-group">
            <mat-form-field appearence="fill" class="top-row-first-filed-item width" style="margin-left: 14px">
              <mat-label>Unit</mat-label>
              <mat-select formControlName="machine" (selectionChange)="getComponents('AddMode')" >
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'puDesc'" [array]="unitsModel"
                  (filteredReturn)="filteredUnitsModel = $event"></mat-select-filter>
                <mat-option *ngFor="let k of filteredUnitsModel" [value]="k.puId">
                  {{ k.puDesc }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-form-field appearence="fill" class="top-row-first-filed-item width" style="margin-left: 14px">
              <mat-label>Component</mat-label>
              <mat-select formControlName="component" >
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'ComponentName'" [array]="componentsModel"
                  (filteredReturn)="filteredcomponentsModel = $event"></mat-select-filter>
                <mat-option *ngFor="let k of filteredcomponentsModel" [value]="k.ComponentID">
                  {{ k.ComponentName }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>Defect</mat-label>
              <input matInput placeholder="Defect" formControlName="defect" required>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>Defect Type</mat-label>
              <mat-select [(value)]="selectedDefectType" formControlName="defectType" required>
                <mat-option *ngFor="let defect of defectType" [value]="defect.id">
                  {{defect.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>Sub Defect Type</mat-label>
              <mat-select [(value)]="selectedSubDefect" formControlName="subDefectType">
                <mat-option *ngFor="let subDefect of subDefectType" [value]="subDefect.id">
                  {{subDefect.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>Defect Priority</mat-label>
              <mat-select [(value)]="selectedDefectPriority" formControlName="defectPriority" required>
                <mat-option *ngFor="let priority of defectPriority" [value]="priority.id">
                  {{priority.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group"  style="display: none;">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>Defect Status</mat-label>
              <mat-select [(value)]="selectedDefectStatus" formControlName="defectStatus" required>
                <mat-option *ngFor="let status of defectStatus" [value]="status.id">
                  {{status.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group"  style="display: none;">
            <mat-form-field appearance="fill" class="field-fill-with-icon">
              <mat-label>Defect On</mat-label>
              <input [readonly]="true" matInput [ngxMatDatetimePicker]="startPicker" formControlName="date">
              <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #startPicker>
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>

          <div class="form-group">
            <button class="file-upload-button">
              <div class="upload-image-group ">
                <div class="upload-icon">
                  <img [src]="uploadIconurl" alt="{{uploadIconurl}}" />
                </div>
                <input type="file" #fileInput (change)="onFileSelect($event)"
                  accept="image/x-png,image/gif,image/jpeg" />
                <span class=" upload-image-content">Upload Image File</span>
              </div>
            </button>
            <span class="file-info">{{ uploadFileInfo }}</span>
          </div>

          <div class="model-form-actions">
            <button type="button" mat-flat-button class="btn-cancel" (click)="closeaddNewVariableModel()">
              Cancel
            </button>
            <button type="submit" mat-flat-button color="accent" class="btn-accent"
              (click)="addNewVariableItemlFormSubmit()">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Edit table row -->
  <div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{'active': iseditDeffectGroupModelVisible}">
    <div class="sidepanel-close" (click)="closeEditDefectGroupModel()"></div>
    <div class="model-content">
      <div class="model-title">
        <h2 class="model-title">Edit {{editGroupModelTitle}}</h2>
        <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeEditDefectGroupModel()">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
              fill="black" />
          </svg>
        </button>
      </div>
      <div class="model-body">
        <form [formGroup]="editTableVariableItemlForm" (ngSubmit)="editTableVariableItemlFormSubmit()">
          <div class="form-group">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>Defect Description</mat-label>
              <input matInput placeholder="Defect" formControlName="defect">
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>Defect Type</mat-label>
              <mat-select [(value)]="selectedDefectType" formControlName="defectType">
                <mat-option *ngFor="let defect of defectType" [value]="defect.id">
                  {{defect.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>SubDefect Type</mat-label>
              <mat-select [(value)]="selectedSubDefect" formControlName="subDefectType">
                <mat-option *ngFor="let subDefect of subDefectType" [value]="subDefect.id">
                  {{subDefect.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>Defect Priority</mat-label>
              <mat-select [(value)]="selectedDefectPriority" formControlName="defectPriority">
                <mat-option *ngFor="let priority of defectPriority" [value]="priority.id">
                  {{priority.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>Defect Status</mat-label>
              <mat-select [(value)]="selectedDefectStatus" formControlName="defectStatus">
                <mat-option *ngFor="let status of defectStatus" [value]="status.id">
                  {{status.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill" class="field-fill">
              <mat-label>Defect On</mat-label>
              <input [readonly]="true" matInput formControlName="defectOn">
            </mat-form-field>
          </div>
          <div class="form-group">
            <button class="file-upload-button">
              <div class="upload-image-group ">
                <div class="upload-icon">
                  <img [src]="uploadIconurl" alt="{{uploadIconurl}}" />
                </div>
                <input type="file" #fileInput (change)="onFileSelect(fileInput)" />
                <span class=" upload-image-content">Upload Image File</span>
              </div>
            </button>
            <span class="file-info">{{ uploadFileInfo }}</span>
          </div>
          <div class="model-form-actions">
            <button type="button" mat-flat-button class="btn-cancel" (click)="closeEditDefectGroupModel()">
              Cancel
            </button>
            <button type="submit" mat-flat-button color="accent" class="btn-accent">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ng-template #displayActionModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Action</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close"><i class="fa fa-times"
          style="color: #fff"></i></button>
    </div>
  </div>
  <div style="margin-bottom: 20px;">
    <button mat-mini-fab mat-button color="accent" (click)="addRow()" matTooltip="Add Row"
      matTooltipClass="custom-tooltip" matTooltipPosition="above" ng-disabled="addGembaActionRowButton">
      <i class="fa fa-plus" style="color: #fff"></i>
    </button>
    <button mat-mini-fab mat-button mat-dialog-close color="primary" matTooltip="Save" matTooltipClass="custom-tooltip"
      matTooltipPosition="above" style="margin-left: 20px;" (click)="addDefectActions()"
      ng-disabled="addGembaActionRowButton">
      <i class="fa fa-save" style="color: #fff"></i>
    </button>
  </div>
  <form [formGroup]="gembaActionform" >
    <table mat-table [dataSource]="actionDataSource" formArrayName="actionsArray" class="basic-table" matSort>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Action Name</p>
        </th>
        <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
          <mat-form-field appearance="fill" class="field-fill" style="width: 138px;">
            <input type="text" [disabled]="row.value.completed" matInput formControlName="actionName"
              placeholder="Action Name">
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="departments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Departments</p>
        </th>
        <td mat-cell *matCellDef="let row; let dep = index" [formGroupName]="dep">
          <mat-form-field appearance="fill" class="field-fill" style="width: 138px;">
            <mat-select name="department" placeholder="Departments" (selectionChange)="getUsersbyDepartment($event,dep)"
              formControlName="department">
              <mat-option *ngFor="let pt of allDepartmentList" [value]="pt.TypeId">{{pt.TypeName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="responsible">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Responsible</p>
        </th>
        <td mat-cell *matCellDef="let row ; let res = index" [formGroupName]="res">
          <mat-form-field appearance="fill" class="field-fill" style="width: 138px;">

            <mat-select ngxMatSelect [hasSearchBox]="true" [useMobileView]="true" mobileViewType="BottomSheet"
              [formControlName]="'responsibleId'" [multiple]="false"
              name="fullName" [required]="true" [source]="raciUsers[res]" [displayMember]="'fullName'"
              placeholder="Responsible"
              [valueMember]="'EmpInfo_Id'" #sf="ngxMatSelect">
              <mat-option [value]="item.EmpInfo_Id" *ngFor="let item of sf.filteredSource">
                {{item.fullName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- </div>    -->
        </td>
      </ng-container>

      <ng-container matColumnDef="remarks">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Remarks</p>
        </th>
        <td mat-cell *matCellDef="let row; let rem = index" [formGroupName]="rem">
          <mat-form-field appearance="fill" class="field-fill" style="width: 138px;">
            <input type="text" [disabled]="row.value.completed" matInput formControlName="remarks"
              placeholder="Remarks">
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Sort Column -->
      <ng-container matColumnDef="target">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Target Date</p>
        </th>
        <td mat-cell *matCellDef="let row; let tar = index" [formGroupName]="tar">
          <mat-form-field appearance="fill" class="field-fill" style="width: 138px;">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="startDate" [min]="minDate" placeholder="Target Date" autocomplete="off"
              formControlName="targetDate">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="isRequiredPictureAfter">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Picture After</p>
        </th>
        <td mat-cell *matCellDef="let row; let conf = index" [formGroupName]="conf">
          <mat-checkbox color="primary" formControlName="isRequiredPictureAfter"></mat-checkbox>
        </td>
      </ng-container>


      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-icon-button>
            <!-- (click)="removeAll()" matTooltip="Remove all" -->
            <mat-icon>clear_all</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let rem= index;" [formGroupName]="rem">
          <button mat-icon-button matTooltip="Remove this action" [disabled]="element.value.completed"
            (click)="deleteRecord(element, rem)" ng-disabled="removeGembaActionRowButton">
            <!-- (click)="removeAt(i)" -->
            <mat-icon>clear</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="actionColumns"></tr>
      <tr *matRowDef="let row; columns: actionColumns;" mat-row></tr>
      <tr class="mat-row" *matNoDataRow style="text-align: center;">
        <td class="mat-cell" colspan="8">No Data Match</td>
      </tr>
    </table>


  </form>
  <!-- <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
  </mat-paginator> -->
</ng-template>

<ng-template #displayCreateDefectModal>
  <form [formGroup]="addNewVariableItemlForm"
    (ngSubmit)="DefectformMode ? addNewVariableItemlFormSubmit() : editTableVariableItemlFormSubmit()">
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{DefectformMode ? 'Create' : 'Update' }} Defect</h2>

        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>

    <mat-dialog-content>

      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-top-row" style="height: 10px;" *ngIf = "DefectformMode">
          <mat-checkbox (change)="checkChange($event)" > Without Machine</mat-checkbox>

        </div>
        <div class="content-row-wrap">
        
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" *ngIf="checked">
            <mat-label>Line</mat-label>
            <mat-select formControlName="lines" (selectionChange)="getUnits('AddMode')" required>
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="LineModel"
                (filteredReturn)="filteredLineModel = $event"></mat-select-filter>
              <!-- <mat-option value="0">ALL</mat-option> -->
              <mat-option *ngFor="let L of filteredLineModel" [value]="L.PL_Id">
                {{ L.PL_Desc }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" *ngIf="checked">
            <mat-label>Unit</mat-label>
            <mat-select formControlName="machine" (selectionChange)="getAssembly('AddMode')" required>
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="unitsModel"
                (filteredReturn)="filteredUnitsModel = $event"></mat-select-filter>
              <mat-option *ngFor="let k of filteredUnitsModel" [value]="k.PU_Id">
                {{ k.PU_Desc }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" *ngIf="checked">
            <mat-label>Assembly</mat-label>
            <mat-select formControlName="assemblyy" (selectionChange)="getComponents('AddMode')" required>
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'Asmb_Desc'" [array]="assemblyModel"
                (filteredReturn)="filteredAssemblyModel = $event"></mat-select-filter>
              <mat-option *ngFor="let a of filteredAssemblyModel" [value]="a.Asmb_Id">
                {{ a.Asmb_Desc }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" *ngIf="checked" >
            <mat-label>Component</mat-label>
            <mat-select formControlName="component" required>
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'ComponentName'" [array]="componentsModel"
                (filteredReturn)="filteredcomponentsModel = $event"></mat-select-filter>
              <mat-option *ngFor="let c of filteredcomponentsModel" [value]="c.ComponentId">
                {{ c.ComponentName }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Defect</mat-label>
            <input matInput placeholder="Defect" formControlName="defect" required>
          </mat-form-field>


          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Defect Type</mat-label>
            <mat-select [(value)]="selectedDefectType" formControlName="defectType" required>
              <mat-option *ngFor="let defect of defectType" [value]="defect.id">
                {{defect.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Sub Defect Type</mat-label>
            <mat-select [(value)]="selectedSubDefect" formControlName="subDefectType">
              <mat-option *ngFor="let subDefect of subDefectType" [value]="subDefect.id">
                {{subDefect.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"  style="display: none;">
            <mat-label>Defect Status</mat-label>
            <mat-select [(value)]="selectedDefectStatus" formControlName="defectStatus" required [disabled]="true">
              <mat-option *ngFor="let status of defectStatus" [value]="status.id">
                {{status.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" *ngIf = "!checked">
            <mat-label>Select Department</mat-label>
            <mat-select [(value)]="selectedDepartment" formControlName="department" required>
              <mat-option *ngFor="let departments of departmentList" [value]="departments.TypeId">
                {{departments.TypeName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" *ngIf = "checked">
            <mat-label>Select Department</mat-label>
            <mat-select  formControlName="departmentMachine" required>
              <mat-option *ngFor="let departments of departmentListMachine" [value]="departments.Dept_Id">
                {{departments.Dept_Desc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
         
          <mat-form-field
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
           *ngIf="DefectformMode">
          <ngx-mat-file-input
            #myInput
            formControlName="image"
            placeholder="Add Defect Images"
            [accept]="
              'image/x-png,image/gif,image/jpeg'  "
            (change)="onFileSelect($event)"
            multiple required
          >
            <mat-icon ngxMatFileInputIcon>folder</mat-icon>
          </ngx-mat-file-input>

          
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" *ngIf="!checked">
          <mat-label>Remarks</mat-label>
          <input matInput placeholder="Remarks" formControlName="remarks" >
        </mat-form-field>
        </div>
        <div *ngIf="FileData && FileData.length && DefectformMode"     class="imgflex">
          <div *ngFor="let file of FileData; let i = index" class="relative">
              <div>
                  <img *ngIf="file.FileType == 'image'" [src]="file.ImageSrc" alt="" width="100px" height="80px">
              </div>

              <img src="assets/images/remove.png" (click)="deleteFile(i)" class="absolute" alt="" width="20px"
                  height="20px">

          </div>
      </div>
        <div class="content-top-row" style="display: none;">
          <div class="inputField"  >
            <nz-date-picker class="calender-input" id="startPicker" formControlName="date" nzFormat="dd/MM/yyyy HH:mm"
              [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true" [nzRequired]
              [nzDisabledDate]="disabledDate"></nz-date-picker>
            <span class="floating-text">Defect On</span>
          </div>
          <!-- <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Select Image</mat-label>
            <input type="file" formControlName="image" (change)="onFileSelect($event)" class="form-control"
            accept="image/x-png,image/gif,image/jpeg" required>
          </mat-form-field> -->
          <div class="top-row-first-filed-item top-row-first-filed-item-order-processing">

            <input type="file" formControlName="image" (change)="onFileSelect($event)" class="form-control"
              accept="image/x-png,image/gif,image/jpeg">
          </div>
          <img [src]="image ? image : 'assets/images/no-image.png'" alt="" width="60" height="60"
            style="margin-left:10px">
          <span class="file-info">{{ uploadFileInfo }}</span>
        </div>
        <h3 class="subheading">Select an Defect Priority</h3>
        <div class="content-top-row" >
          <mat-radio-group aria-label="Select an Defect Priority" formControlName ="defectPriority">
            <mat-radio-button *ngFor="let priority of defectPriority" [value]="priority.id" >{{priority.name}}</mat-radio-button>
          </mat-radio-group>
        </div>
     
        <div style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <!-- <button
            mat-mini-fab
            mat-button
            mat-dialog-close
            color="warn"
            title="close"
            (click)="closeaddNewVariableModel()"
          >
            <i class="fa fa-times" style="color: #fff"></i>
          </button> -->
          <!-- <button type="button" mat-flat-button class="btn-cancel" (click)="closeaddNewVariableModel()">
            Cancel
          </button> -->
          <button [disabled]="!addNewVariableItemlForm.valid" mat-mini-fab mat-button mat-dialog-close color="primary"
            type="submit" title="Save" pTooltip="Save Actions" style="margin-left: 20px;" *ngIf="DefectformMode">
            <i class="fa fa-save" style="color: #fff"></i>
          </button>
          <button zz mat-mini-fab mat-button mat-dialog-close color="primary"
            type="submit" title="Save" pTooltip="Save Actions" style="margin-left: 20px;" *ngIf="!DefectformMode">
            <i class="fa fa-pencil" style="color: #fff"></i>
          </button>
          <!-- <button type="submit" mat-flat-button color="accent" class="btn-accent" (click)="addNewVariableItemlFormSubmit()"> Save </button> -->

        </div>

      </div>

    </mat-dialog-content>
  </form>
</ng-template>

<ng-template #previewModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Defect Files</h2>
      <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content style="padding: 15px 24px !important;  ">
    <form [formGroup]="filesForm" novalidate>
      <mat-form-field
        appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing"
      >
        <ngx-mat-file-input
          #myInput
          formControlName="file"
          placeholder="Add Defect Files"
          [accept]="
          'image/x-png,image/gif,image/jpeg,application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel , application/vnd.ms-powerpoint'"
          (change)="saveDefectFiles($event)"
          multiple
        >
          <mat-icon ngxMatFileInputIcon>folder</mat-icon>
        </ngx-mat-file-input>
      </mat-form-field>
    </form>

    <div class="mat-elevation-z8">
      <table mat-table class="preview_table" [dataSource]="previewerData">
        <ng-container matColumnDef="Desc">
          <th mat-header-cell *matHeaderCellDef>
            File Description
          </th>
          <td mat-cell *matCellDef="let element">
            <a (click)="openFile(element)">
               {{ element.FileName }}
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> Download
            <!-- {{ langObj ? langObj?.Download : ("Download" | translate) }} -->
          </th>
          <td mat-cell *matCellDef="let element">
            <a href="{{ImageUrl + element.FilePath }}" target="_blank"><i class="fa fa-download"></i></a>
          </td>
        </ng-container>
        <ng-container matColumnDef="Remove">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-icon-button>
              <!-- (click)="removeAll()" matTooltip="Remove all" -->
              <mat-icon>clear_all</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element; let rem = index">
            <button
              mat-icon-button
              matTooltip="Remove this File"
              (click)="removeFile(element)"
            >
              <!-- (click)="removeAt(i)" -->
              <mat-icon>clear</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="previewerColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: previewerColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3">
            No Data Match
          </td>
        </tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #ViewActionDetailModel>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Action Detail</h2>
      <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <!-- <div class="mat-elevation-z8"> -->
      <ag-grid-angular style="width: 100%; height: 30vw" class="ag-theme-alpine" [rowData]="rowDataViewActions"
      [columnDefs]="columnDefsActionView" [gridOptions]="gridOptions" [enableSorting]="true"
      [pagination]="true"
      [enableFilter]="true"  (cellClicked)="cellClicked($event)"
      (gridReady)="onGridReady($event)" >
    </ag-grid-angular>
    <!-- </div> -->
  </mat-dialog-content>
</ng-template>
<ng-template #CloseDefect>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Action Detail</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content  style="overflow:unset">
    <form [formGroup]="CloseDefectForm">
      <div *ngIf = "uploadImage" >
        <div class="content-top-row" style="margin-top: 0px;">
          <mat-checkbox (change)="checkChangeClose($event)" [disabled] = "checkBoxDisable"> Image Not Required</mat-checkbox>
        </div>
        <div class="content-top-row" style="margin-top: 0px;">
          <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px; width: 30%;">
            <input type="text" matInput placeholder="Remarks"  formControlName="remarks">
          </mat-form-field>
          <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 30%;" [ngClass]="{'disable-bg': disableImage === true}">
            <ngx-mat-file-input #myInput formControlName="image" placeholder="Add Action Close Image" [accept]="
              'image/x-png,image/gif,image/jpeg'  " (change)="onFileSelectClose($event)" [disabled] = "disableImage"   tooltipPosition="top"
              matTooltip="Action Close Image" >
              <mat-icon ngxMatFileInputIcon>folder</mat-icon>
            </ngx-mat-file-input>
          </mat-form-field>
        </div>
        <div class="d-flex justify-content-end">
          <button mat-mini-fab mat-button  mat-dialog-close color="primary" title="Save" pTooltip="Close Defect"
            style="margin-left: 20px;" [disabled]="!CloseDefectForm.valid" (click)="closedefect()">
            <i class="fa fa-save" style="color: #fff"></i>
          </button>
          <!-- mat-dialog-close -->
        </div>
      </div>
      <div class="content-row-wrap" *ngIf = "!uploadImage" style="justify-content: space-between" >
        <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px; width: 70%;">
          <input type="text" matInput placeholder="Remarks"  formControlName="remarks">
        </mat-form-field>
        <img style="height:70px;width:70px" [src] ="this.CloseImage ? this.CloseImage : assets/images/no-image.png" (click) = "imageZoom()"/>
      </div>  
    </form>
  </mat-dialog-content>
</ng-template>
<p-dialog [header]="fileDesc" [(visible)]="displayMaximizable" [modal]="true" [style]="{width: '90vw'}"
  [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ngx-doc-viewer *ngIf="fileType==='doc'" [url]="doc" viewer="google" style="width:100%;height:90vh;"></ngx-doc-viewer>
  <angular-image-viewer *ngIf="fileType==='img'" [src]="images" [(index)]="imageIndexOne"></angular-image-viewer>
</p-dialog>


<ng-template #imageZoomModel>

  <img [src]="currentImagePath" style="height: 98%; width: 100%; overflow: hidden;">

</ng-template>