import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { QualityOdsService } from 'src/app/services/quality-ods.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import * as _moment from 'moment';
import { PDFExportRenderer } from 'src/app/pdf-export-renderer.component';

@Component({
  selector: 'app-glass-physical-qa-report',
  templateUrl: './glass-physical-qa-report.component.html',
  styleUrls: ['./glass-physical-qa-report.component.scss']
})
export class GlassPhysicalQaReportComponent implements OnInit {

  public breadcrumList: any;
  private subscriptions = new Subscription();
  qaMasterForm!: FormGroup;
  reportTypes!: any[];
  rowDataReport: any[] = [];
  columnDefsReport: any[] = [];
  gridApi: any;
  gridColumnApi: any;
  reportType: any;
  exportExcelColumns: any[] = [];
  gridOptionsReports: any = {
    suppressPropertyNamesCheck: true,
    defaultColDef: {
      cellRenderer: 'pdfExportRenderer',
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      filterParams: { newRowsAction: 'keep' },
    },
  };
  headerHeight = 70;
  limitValues: any[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private usermanagementService: UserManagementService,
    private toaster: ToastrService,
    public router: Router,
    private fb: FormBuilder,
    private qualityService: QualityOdsService,
    private datePipe: DatePipe,
  ) { }

  getReportTypes(): void {
    this.qualityService.getReportTypes({}).subscribe(response => {
      if (response?.HasError) {
        this.toaster.error('Error', response?.ErrorMessage);
      }else {
        this.reportTypes = response?.MaterialList;
        this.qaMasterForm.get('reportType')?.setValue(this.reportTypes[0]?.TypeId);
        this.getLimitValues();
      }
    }, (error) => {
      this.toaster.error('Error', error?.message);
    });
  }

  getReports(): void {
    this.columnDefsReport = [];
    this.rowDataReport = [];
    this.reportType =this.search(this.qaMasterForm.value.reportType, this.reportTypes)
    const {reportType, fromdate, todate} = this.qaMasterForm.value;
    const type = this.reportTypes.find(x => x.TypeId === reportType);
    if (reportType) {
        const key = {
          ReportName: type.TypeName,
          ReportId: type.TypeId,
          TransactionType: 6,
          DateValue: _moment(fromdate).format('yyyy/MM/DD'),
          EndDateValue: _moment(todate).format('yyyy/MM/DD'),
        };
        this.qualityService.getQAReport(key).subscribe(response => {
          if (response?.HasError) {
            this.toaster.error('Error', response?.ErrorMessage);
          }else {
            const tempColsArray: any[] = [];
            const headersArray: string[] = [];
            let qualityIndex: number;
            let thickIndex: number;
            const dataArray = response?.DataList;
            response?.Headers.forEach((value: string, index: number) => {
                const key = 'key' + index;
                if (value === 'NAME') {
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    width: 110,
                    resizable: true
                  });
                }else if(value == 'ShiftId'){
                  tempColsArray.push({
                    field: key,
                    headerName: 'Shift',
                    sortable: true,
                    maxWidth: 110
                  });
                }else if(value == 'CrewId'){
                  tempColsArray.push({
                    field: key,
                    headerName: 'Crew',
                    sortable: true,
                    maxWidth: 110
                  });
                }else if((value == 'QUALITY' || value == 'THK' || value == 'NOMINAL THICKNESS') && 
                         (type.TypeName == 'QA REPORT' || type.TypeName == 'THICKNESS TSV')){
                  tempColsArray.push({
                    field: value == 'QUALITY' ? 'QUALITY' : 'THK',
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 110
                  });
                  headersArray.push( value == 'QUALITY' ? 'QUALITY' : 'THK');
                  qualityIndex = value == 'QUALITY' ? index : qualityIndex;
                  thickIndex = (value == 'THK' || value == 'NOMINAL THICKNESS') ? index : thickIndex;
                  return;
                }else if(type.TypeName == 'QA REPORT' && value == 'edge light/kg (seed count per kg)'){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125,
                    cellStyle: (params: any) => ((params.data['MaxValSeedCount'] ? params.value > params.data['MaxValSeedCount'] : false) || 
                                (params.data['MinValSeedCount'] ? params.value < params.data['MinValSeedCount'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                  headersArray.push(key);
                  tempColsArray.push({
                    field: 'MaxValSeedCount',
                    headerName: 'Max Limit for Seed Count per Kg',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MaxValSeedCount');
                  tempColsArray.push({
                    field: 'MinValSeedCount',
                    headerName: 'Min Limit for Seed Count per Kg',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MinValSeedCount');
                  return;
                }else if(type.TypeName == 'QA REPORT' && (value == 'Zebra LE' || value == 'Zebra LC' || value == 'Zebra RC')){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 115,
                    cellStyle: (params: any) => ((params.data['MaxValZebra'] ? params.value > params.data['MaxValZebra'] : false) || 
                                (params.data['MinValZebra'] ? params.value < params.data['MinValZebra'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                }else if(type.TypeName == 'QA REPORT' && value == 'Zebra RE'){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 115,
                    cellStyle: (params: any) => ((params.data['MaxValZebra'] ? params.value > params.data['MaxValZebra'] : false) || 
                                (params.data['MinValZebra'] ? params.value < params.data['MinValZebra'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                  headersArray.push(key);
                  tempColsArray.push({
                    field: 'MaxValZebra',
                    headerName: 'Max Limit for Zebra Value with GL Quality',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MaxValZebra');
                  tempColsArray.push({
                    field: 'MinValZebra',
                    headerName: 'Min Limit for Zebra Value with GL Quality',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MinValZebra');
                  return;
                }else if(type.TypeName == 'QA REPORT' && (value == 'Bloom_L' || value == 'Bloom_C')){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 115,
                    cellStyle: (params: any) => ((params.data['MaxValBloom'] ? params.value > params.data['MaxValBloom'] : false) || 
                                (params.data['MinValBloom'] ? params.value < params.data['MinValBloom'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                }else if(type.TypeName == 'QA REPORT' && value == 'Bloom_R'){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 115,
                    cellStyle: (params: any) => ((params.data['MaxValBloom'] ? params.value > params.data['MaxValBloom'] : false) || 
                                (params.data['MinValBloom'] ? params.value < params.data['MinValBloom'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                  headersArray.push(key);
                  tempColsArray.push({
                    field: 'MaxValBloom',
                    headerName: 'Max Limit for Bloom Grade with AT Quality',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MaxValBloom');
                  tempColsArray.push({
                    field: 'MinValBloom',
                    headerName: 'Min Limit for Bloom Grade with AT Quality',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MinValBloom');
                  return;
                }else if(type.TypeName == 'THICKNESS TSV' && value == 'AVG'){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 110,
                    cellStyle: (params: any) => ((params.data['MaxValAvg'] ? params.value > params.data['MaxValAvg'] : false) || 
                                (params.data['MinValAvg'] ? params.value < params.data['MinValAvg'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                  headersArray.push(key);
                  tempColsArray.push({
                    field: 'MaxValAvg',
                    headerName: 'Max Limit for AVG with Thickness',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MaxValAvg');
                  tempColsArray.push({
                    field: 'MinValAvg',
                    headerName: 'Min Limit for AVG with Thickness',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MinValAvg');
                  return;
                }else {
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 120
                  });
                }
                // if(value !== 'edge light/kg (seed count per kg)' && value !== 'Zebra RE' && value !== 'Bloom_R' &&
                //    value !== 'QUALITY' && value !== 'THK' && value !== 'NOMINAL THICKNESS' && value !== 'AVG'){
                headersArray.push(key);
                // }
            });
            let indexUpdate = 0;
            // const tableData = dataArray.map((row: any[]) => {
            //   return row.reduce((result: { [x: string]: any; }, field: any, index: number) => {
            //     result[headersArray[index]] = field;
            //     return result;
            //   }, {});
            // });
            const tableData = [];
            for(let row = 0; row < dataArray.length; row++){
              let objRowForGrid = {};
              for(let rowData = 0; rowData < dataArray[row].length; rowData++){
                if(rowData == 0){
                  indexUpdate = 0;
                }
                if(headersArray[indexUpdate].includes('key') || headersArray[indexUpdate].includes('QUALITY') || headersArray[indexUpdate].includes('THK')){
                  objRowForGrid[headersArray[indexUpdate]] = dataArray[row][rowData];
                  indexUpdate = indexUpdate + 1;
                }
                else{
                  if(headersArray[indexUpdate] == 'MaxValSeedCount'){
                    objRowForGrid[headersArray[indexUpdate]] = this.limitValues.find(row => row.CriticalParameters == 'Seed Count')?.MaxValue;
                    objRowForGrid[headersArray[indexUpdate + 1]] = this.limitValues.find(row => row.CriticalParameters == 'Seed Count')?.MinValue; 
                  }
                  if(headersArray[indexUpdate] == 'MaxValZebra' && dataArray[row][qualityIndex].includes('GL')){
                    objRowForGrid[headersArray[indexUpdate]] = this.limitValues.find(row => row.CriticalParameters == 'Zebra value for Gl quality')?.MaxValue;
                    objRowForGrid[headersArray[indexUpdate + 1]] = this.limitValues.find(row => row.CriticalParameters == 'Zebra value for Gl quality')?.MinValue; 
                  }
                  if(headersArray[indexUpdate] == 'MaxValBloom' && dataArray[row][qualityIndex].includes('AT')){
                    objRowForGrid[headersArray[indexUpdate]] = this.limitValues.find(row => row.CriticalParameters == 'Bloom Grade (AT quality )')?.MaxValue;
                    objRowForGrid[headersArray[indexUpdate + 1]] = this.limitValues.find(row => row.CriticalParameters == 'Bloom Grade (AT quality )')?.MinValue; 
                  }
                  if(headersArray[indexUpdate] == 'MaxValAvg'){
                    let thicknessParams = this.limitValues.find(row1 => row1.Thickness ==  dataArray[row][thickIndex])
                    objRowForGrid[headersArray[indexUpdate]] = thicknessParams ? thicknessParams?.MaxValue : '';
                    objRowForGrid[headersArray[indexUpdate + 1]] = thicknessParams ? thicknessParams?.MinValue : ''; 
                  }
                  // result[headersArray[indexUpdate + 2]] = field;
                  objRowForGrid[headersArray[indexUpdate + 2]] = dataArray[row][rowData];
                  indexUpdate = indexUpdate + 3;
                }
              }
              tableData.push(objRowForGrid);
            }
            this.columnDefsReport = tempColsArray;
            this.rowDataReport = tableData;
            this.exportExcelColumns = headersArray.slice();
          }
        }, (error) => {
          this.toaster.error('Error', error?.message);
        });
     }
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getLimitValues(): any {
    this.qualityService.GetLimitValues().subscribe(
      (response: any) => {
        if(!response.HasError){
          this.limitValues = response.result;
          this.getReports();
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
      }
    )
  }

  // onColumnEverythingChanged(params: { columnApi: { getColumn:
  //   (arg0: string) => any; moveColumn: (arg0: any, arg1: number) => void; }; }): void {
  //   const selectionCol = params.columnApi.getColumn('selection-col');
  //   if (selectionCol) {
  //     params.columnApi.moveColumn(selectionCol, 0);
  //   }
  // }

  onFirstDataRendered(params: { columnApi: { autoSizeAllColumns: () => void; }; }): void {
    params.columnApi.autoSizeAllColumns();
  }

  exportToExcel(): void {
    const excelParams = {
      headerRowHeight: 50,
      sheetName: 'Physical - '+this.reportType,
      fileName: 'Physical - '+this.reportType,
      allColumns: false,
      columnKeys: this.exportExcelColumns
    };
    this.gridApi.exportDataAsExcel(excelParams);
  }

  search(nameKey: any, myArray: string | any[]){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].TypeId === nameKey) {
            return myArray[i].TypeName;
        }
    }
  }

  getBreadcrumList(): any {
    this.spinner.show();
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    const breadCrumSub = this.usermanagementService.GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      ).subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(breadCrumSub);
  }

  displayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  ngOnInit(): void {
    var firstDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    this.qaMasterForm = this.fb.group({
      fromdate: [this.datePipe.transform(firstDate, 'yyyy-MM-dd')],
      reportType: [''],
      todate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')]
    });
    this.getBreadcrumList();
    this.getReportTypes();
  }

}
