import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { event } from 'jquery';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'btn-cell-rendererDefect',
  template: `
    <button mat-mini-fab color="primary" (click)="onClick($event)" *ngIf="(params.node.data.statusName!=='Closed' && params.node.data.statusName!=='Completed')"  tooltipPosition="top"  pTooltip="Edit Defect">
      <mat-icon>edit</mat-icon>
    </button>
    <button *ngIf="(params.node.data.statusName==='Closed' || params.node.data.totalDefectActions>0) ? false: true" tooltipPosition="top"  pTooltip="Delete Defect"
      mat-mini-fab
      color="warn"
      [disabled]="clicked"
      (click)="onClick($event)"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <button *ngIf="(params.node.data.statusName!=='Closed' && params.node.data.statusName!=='Completed')" tooltipPosition="top"  pTooltip="Add Action"
      mat-mini-fab
      color="accent"
      (click)="onClick($event)"
      class="add-icon"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button *ngIf="(params.node.data.statusName ==='Closed' || params.node.data.totalDefectActions>0) ? false: true" tooltipPosition="top"  pTooltip="Close Defect"
      mat-mini-fab
      color="accent"
      (click)="onClick($event)"
    >
      <mat-icon>close</mat-icon>
    </button>
    <button *ngIf="params.node.data.statusName ==='Closed' && params.node.data.totalDefectActions === 0" tooltipPosition="top"  pTooltip="View Actions"
      mat-mini-fab
      color="accent"
      (click)="onClick($event)"
    >
      <mat-icon>remove_red_eye 2 </mat-icon>
    </button>
    <button style="background-color: #7a1dcf;"  pTooltip="View Image"
      mat-mini-fab
      (click)="onClick($event)"
      *ngIf="params.node.data.statusName ==='Closed' ? false: true"
    >
    <mat-icon> insert_drive_file</mat-icon>
    </button>
    <button style="background-color: #6FBABA;"  pTooltip="View Actions"
      mat-mini-fab tooltipPosition="top"
      *ngIf="params.node.data.totalDefectActions > 0"
      (click)="onClick($event)"
    >
    <mat-icon>remove_red_eye</mat-icon>
    </button>
  `,
  styles: [
    `
      .add-icon {
        background: cornflowerblue;
      }
      button{
        height: 35px;
        width: 35px;
        display: inline-flex;
    align-items: center;
    justify-content: center;
        margin: 0 3px;

      }
      .mat-icon {
        transform: scale(0.7);
      }
    `,
  ],
})
// tslint:disable-next-line:component-class-suffix
export class BtnCellRendererDefect
  implements ICellRendererAngularComp, OnDestroy {
  clicked = false;
  params: any;
  refresh(params: any): boolean {
    throw new Error('Method not implemented.');
  }
  agInit(params: any): void {
    this.params = params;
  }
  onClick($event: any): any {
    const spec = $event.target.className;
    if (
      spec !== 'Delete' &&
      spec !== 'close'
    ) {
      if (this.params.onClick instanceof Function) {
        // put anything into params u want pass into parents component
        const params = {
          event: $event,
          rowData: this.params.node.data,
          // ...something
        };
        this.params.onClick(params);
      }
    } else {
      this.clicked = true;
    }
  }
  ngOnDestroy(): any {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
