<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
<div>
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">Quality Parameter SPC</h1>
    </div>
  </div>
  <div>
    <form [formGroup]="qualityParameterTrendDashboardForm">
      <div class="content-top-row">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width:20%;">
          <mat-label>Select Quality Type</mat-label>
          <mat-select formControlName="qualityType" panelClass="testClass" (selectionChange)="getQualityParamsType()">
            <mat-option *ngFor="let item of qualityType" [value]="item.qualityTypeId">
              {{ item.qualityTypeDesc }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width:20%;">
          <mat-label>Select Parameter Type</mat-label>
          <mat-select formControlName="paramsType" panelClass="testClass" (selectionChange)="getParameterDropdown()">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="filteredParamsTypeArray"
              (filteredReturn)="paramsType = $event"></mat-select-filter>
            <mat-option *ngFor="let item of paramsType" [value]="item.TypeId">
              {{ item.TypeName }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width:20%;">
          <mat-label>Parameter</mat-label>
          <mat-select *ngIf="qualityParameterTrendDashboardForm.value.qualityType == 1" formControlName="parameter" panelClass="testClass">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="filteredParameterArray"
              (filteredReturn)="parameterArray = $event"></mat-select-filter>
            <mat-option *ngFor="let item of parameterArray" [value]="item.id">
              {{ item.name }}</mat-option>
          </mat-select>

          <mat-select *ngIf="qualityParameterTrendDashboardForm.value.qualityType == 2" formControlName="parameter" panelClass="testClass">
            <!-- <mat-select-filter [placeholder]="'Search'" [displayMember]="'UserColumnName'" [array]="filteredParameterArray"
              (filteredReturn)="parameterArray = $event"></mat-select-filter> -->
            <mat-optgroup *ngFor="let items of parameterArray" [label]="items.SectionName">
              <mat-option *ngFor="let item of items.Options" [value]="item.ActualColumnName">
                {{ item.UserColumnName }}</mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="qualityParameterTrendDashboardForm.value.qualityType == 2" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width:20%;">
          <mat-label>Product</mat-label>
          <mat-select formControlName="product" panelClass="testClass">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="filteredProductArray"
              (filteredReturn)="productArray = $event"></mat-select-filter>
            <mat-option value="0">Select All</mat-option>
            <mat-option *ngFor="let item of productArray" [value]="item.TypeId">
              {{ item.TypeName }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Choose Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="startDate" placeholder="From Date" />
            <input matEndDate formControlName="endDate" placeholder="To Date" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <button type="button" mat-button mat-mini-fab color="primary" (click)="getChemicalLabSPCQualityChartData()" pTooltip="Search Data" 
            tooltipPosition="top" style="float: right; margin-left: 10px;">
            <mat-icon>search</mat-icon>
        </button>
      </div>
    </form>
  </div>
  <div style="margin: 40px 0 0 0;display: flex;">
    <mat-grid-tile id="historianChart" style="margin-right: 10px;"></mat-grid-tile>
    <mat-grid-tile id="table" style="width:49.6%">
      <ag-grid-angular style="width: 100%;height: 435px" class="ag-theme-alpine" [rowData]="tableData"
        [columnDefs]="columnDefsTask" (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="8">
      </ag-grid-angular>
    </mat-grid-tile>

  </div>
  <div style="margin: 10px 0 0 0">
    <div id="areaRangeChart"></div>
  </div>
  <div style="margin: 10px 0 0 0">
    <div id="conrolChart"></div>
  </div>
</div>
