import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ElementRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { environment } from 'src/environments/environment';
import { CILMasterService } from 'src/app/services/cil-master-service';
import { RMconfigurationService } from 'src/app/services/rmconfiguration.service';
import { PrevConfigurationService } from 'src/app/services/prev-configuration.service';
import Swal from 'sweetalert2';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MatDialog } from '@angular/material/dialog';
import { PreventiveDialogComponent } from './preventive-dialog/preventive-dialog.component';
import * as XLSX from 'xlsx';
import { CommonService } from 'src/app/services/common.service';
import { EditBtnRenderer } from 'src/app/edit-action-btn-renderer.component';
import { DeleteBtnRenderer } from 'src/app/delete-action-btn-renderer.component';
import { CustomTooltip } from 'src/app/custom-tooltip.component';
import * as moment from 'moment';
import { ActionEditDeleteBtnRenderer } from 'src/app/action-edit-delete-btn-renderer.component';

@Component({
  selector: 'app-preventive-maintanance-configuration',
  templateUrl: './preventive-maintanance-configuration.component.html',
  styleUrls: ['./preventive-maintanance-configuration.component.scss'],
})
export class PreventiveMaintananceConfigurationComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('WOTypeTable') ExportWOTypeTable!: ElementRef;
  @ViewChild('TaskTypeTable') ExportTaskTypeTable!: ElementRef;
  @ViewChild('ToolTable') ExportToolTable!: ElementRef;
  @ViewChild('FrequencyTable') ExportFrequencyTable!: ElementRef;
  @ViewChild('TaskTable') ExportTaskTable!: ElementRef;

  @ViewChild('allSelected') private allSelected: any;

  panelOpenState = false;
  page = { length: 0, pageIndex: 0, pageSize: 10 };

  WOTypeColumns: string[] = ['ID', 'Name', 'CreatedAt', 'CreatedBy', 'action'];
  toolcolumns: string[] = ['ID', 'Name', 'CreatedAt', 'CreatedBy', 'action'];
  frequencycolumns: string[] = [
    'ID',
    'Name',
    'Duration',
    'CreatedAt',
    'CreatedBy',
    'action',
  ];
  tasktypecolumns: string[] = [
    'ID',
    'Name',
    'MaintenanceType',
    'CreatedAt',
    'CreatedBy',
    'action',
  ];
  taskColumns: string[] = [
    'ID',
    'Unit',
    'Description',
    'Technician',
    'TaskType',
    'Duration',
    'Frequency',
    'WOType',
    'SparePart',
    'CreatedAt',
    'CreatedBy',
    'action',
  ];

  ToolDataSource = new MatTableDataSource();
  FrequencyDataSource = new MatTableDataSource();
  TaskTypeDataSource = new MatTableDataSource();
  TaskDataSource = new MatTableDataSource();
  WOTypeDataSource = new MatTableDataSource();

  updateOrderprocessingData = false;
  formDirective: any;
  formMode!: boolean;
  displayComponentModal = false;
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  breadcrumList!: any;
  IsWrite: boolean = false;
  varUnitName!: string;
  edited = false;
  isEditAT = false;
  isEditFreq = false;
  isEditTT = false;
  isEditTask = false;
  user: any;
  groupId: any;
  raci: any;

  WOTypePage = { length: 0, pageIndex: 0, pageSize: 5 };
  ToolPage = { length: 0, pageIndex: 0, pageSize: 5 };
  FrequencyPage = { length: 0, pageIndex: 0, pageSize: 5 };
  TaskTypePage = { length: 0, pageIndex: 0, pageSize: 5 };
  TaskPage = { length: 0, pageIndex: 0, pageSize: 5 };

  HeaderData: any = { Group: '', Plant: '' };

  prevTool: any = { Name: '', CreatedAt: '', CreatedBy: 0, ID: 0 };

  prevTaskType: any = {
    Name: '',
    MaintenanceType: '',
    CreatedAt: '',
    CreatedBy: 0,
    ID: 0,
  };

  prevTask: any = {
    line: null,
    units: null,
    assembly: null,
    subAssembly: null,
    component: null,
    lineName: '',
    unitName: '',
    assemblyName: '',
    subAssemblyName: '',
    componentName: '',
    Description: '',
    Technician: '',
    Frequency: '',
    displayMachine: '',
    Duration: '',
    SpareParts: '',
    UploadFile: '',
    TaskType: '',
    MaintenanceType: '',
    FromTaskDate: new Date(),
    FileType: [],
    CreatedAt: '',
    CreatedBy: 0,
    ID: 0,
    IsFrequencyUpdate: false
  };

  prevFrequency: any = {
    Name: '',
    Duration: '',
    CreatedAt: '',
    CreatedBy: 0,
    ID: 0,
  };

  Technician: any = {
    ID: 0,
    RMPM: 'PM',
    Name: [],
    CreatedAt: new Date(),
    CreatedBy: 0,
  };

  TaskTypeList: any = [];
  ToolList: any = [];
  TaskList: any = [];
  frequencyList: any = [];

  TechnicianList: any = [];
  filterTechnicianList: any = [];
  TechnicianFiltList: any = [];
  filterFrequencyList: any = [];
  filterTaskTypeList: any = [];
  FreqDuration: any;

  isPlantGroupModelVisible: boolean = false;
  spinnerText: string = '';
  dialogBox: boolean = false;
  blobStorageUrl: string = '';
  @ViewChild('fileInput') fileInput!: ElementRef;

  HeaderForm = this.fb.group({
    Group: new FormControl(null, [Validators.required]),
    Plant: new FormControl(null, [Validators.required]),
  });
  prevToolForm = this.fb.group({
    name: new FormControl('', [Validators.required]),
  });
  prevFrequencyForm = this.fb.group({
    name: new FormControl('', [Validators.required]),
    duration: new FormControl('', [Validators.required]),
  });
  prevTaskTypeForm = this.fb.group({
    name: new FormControl('', [Validators.required]),
    maintenanceType: new FormControl('', [Validators.required]),
  });
  prevTaskForm = this.fb.group({
    line: new FormControl('', [Validators.required]),
    unit: new FormControl('', [Validators.required]),
    assembly: new FormControl(''),
    subAssembly: new FormControl(''),
    component: new FormControl(''),
    description: ['', Validators.required],
    technicians: ['', Validators.required],
    frequency: ['', Validators.required],
    WOType: ['', Validators.required],
    type_duration: ['', Validators.required],
    spare_part: ['', Validators.required],
    task_type: ['', Validators.required],
    maintenanceType: ['', Validators.required],
    fromTaskDate: ['', Validators.required],
    toTaskDate: ['', Validators.required],
  });
  WOTypeForm = this.fb.group({
    Name: new FormControl(null, [Validators.required]),
  });
  TechnicianForm!: FormGroup;
  WOTypeList: any = [];
  WOTypeFiltList: any = [];
  AllWOType: any = [];
  GroupList: any = [];
  PlantList: any = [];

  userList: any = [];
  orgUserList: any = [];

  excelFile: any;
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;
  ExcelTechnicianList: any = [];
  ExportWOType: any = [];
  ExportTool: any = [];
  ExportFrequency: any = [];
  ExportTaskType: any = [];
  ExportTask: any = [];
  ExportTechnician: any = [];
  imageSrc = new Array<string>();
  fileType: any;
  FileData: any = [];
  FileList: any = [];
  EditFileData: any = [];

  TechnologyMasterData: any = [];
  TaskTypeMasterData: any = [];
  FrequencyMasterData: any = [];
  ToolMasterData: any = [];
  TaskMasterData: any = [];
  TechnicianMasterData: any = [];

  AllTaskType: any = [];
  AllFrequency: any = [];
  AllTool: any = [];
  WOType: any = { ID: 0, Name: '', CreatedAt: new Date(), CreatedBy: 0 };
  GroupTempList: any = [];
  PlantTempList: any = [];

  MaintenanceType: any = [
    { ID: 1, Name: 'Time Based' },
    { ID: 2, Name: 'Condition Based' },
  ];

  pageSizeOptions = [5, 10, 20];

  frameworkComponents: any;
  rowData: any = [];
  columnDefsTask!: any[];
  domLayout = 'autoHeight';

  gridApi: any;
  gridColumnApi: any;

  gridOptions: any = {
    defaultColDef: {
      paginationPageSize: 50,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true, // <-- HERE
      autoHeight: true, // <-- HERE
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
    },
    popupParent: document.querySelector('body'),
  };

  LineList: any = [];
  LineFilterList: any = [];
  LineAllList: any = [];

  UnitList: any = [];
  UnitFilterList: any = [];
  UnitAllList: any = [];

  AssemblyList: any = [];
  AssemblyFilterList: any = [];
  AssemblyAllList: any = [];

  SubAssemblyList: any = [];
  SubAssemblyFilterList: any = [];
  SubAssemblyAllList: any = [];

  ComponentList: any = [];
  ComponentFilterList: any = [];
  ComponentAllList: any = [];
  isInit: boolean;
  minDate: Date = new Date();
  minToDate: Date = new Date();
  isTaskFreqUpdate: number = -1;
  startDateForTask: Date;
  endDateForTask: Date;
  InavlidExportWOType: any = [];
  InavlidExportFrequency: any = [];
  InavlidExportTaskType: any = [];
  InavlidExportTask: any = [];
  InavlidExportTechnician: any = [];

  constructor(
    private toaster: ToastrService,
    private fb: FormBuilder,
    public router: Router,
    private spinner: NgxSpinnerService,
    private cilMasterService: CILMasterService,
    private RMConfigurationService: RMconfigurationService,
    private enterpriseGroupService: EnterPriseGroupService,
    private userManagementService: UserManagementService,
    private PrevConfigurationService: PrevConfigurationService,
    public dialog: MatDialog,
    private commonService: CommonService,
  ) {
    this.frameworkComponents = {
      // editRenderer: EditBtnRenderer,
      // deleteRenderer: DeleteBtnRenderer,
      // customTooltip: CustomTooltip,
      actionEditDeleteBtnRenderer: ActionEditDeleteBtnRenderer,
    };

    this.columnDefsTask = [
      {
        headerName: 'Action',
        field: 'Action',
        cellRenderer: 'actionEditDeleteBtnRenderer',
        minWidth: 110,
        cellRendererParams: {
          onClickEdit: this.editTaskRow.bind(this),
          onClickDelete: this.deleteTaskRow.bind(this),
        },
        
      },
      {
        headerName: 'TaskId',
        minWidth: 150,
        field: 'ID',
        filter: true,        
      },
      {
        headerName: 'Line',
        minWidth: 150,
        field: 'Line',
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Unit',
        minWidth: 150,
        field: 'Unit',
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Assembly',
        minWidth: 150,
        field: 'Assembly',
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'SubAssembly',
        minWidth: 150,
        field: 'SubAssembly',
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Component',
        minWidth: 150,
        field: 'Component',
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Description',
        minWidth: 350,
        field: 'Description',
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Technician',
        minWidth: 200,
        field: 'Technician',
        filter: true,
      },
      {
        headerName: 'TaskType',
        minWidth: 150,
        field: 'TaskType',
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Duration',
        minWidth: 105,
        field: 'Duration',
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Frequency',
        minWidth: 129,
        field: 'Frequency',
        filter: true,
      },
      {
        headerName: 'Activity Type',
        minWidth: 150,
        field: 'WOType',
        filter: true,
      },
      {
        headerName: 'Spare Parts',
        minWidth: 150,
        field: 'SpareParts',
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Start Date',
        minWidth: 180,
        field: 'StartDate',
        filter: true,
        valueFormatter: function (params: any) {
          return params && params.value
            ? moment(params.value).format('DD/MM/YYYY hh:mm a')
            : '';
        },
        
      },
      {
        headerName: 'To Task Date',
        minWidth: 180,
        field: 'ToTaskDate',
        filter: true,
        valueFormatter: function (params: any) {
          return params && params.value
            ? moment(params.value).format('DD/MM/YYYY')
            : '';
        },
        
      },
      {
        headerName: 'Creation Date',
        minWidth: 180,
        field: 'CreatedAt',
        filter: true,
        valueFormatter: function (params: any) {
          return params && params.value
            ? moment(params.value).format('DD/MM/YYYY hh:mm a')
            : '';
        },
        
      },
      {
        headerName: 'Created By',
        minWidth: 150,
        field: 'CreatedUser',
        filter: true,
        cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
      },
    ];
  }

  ngOnInit(): void {
    this.blobStorageUrl = environment.O3CoreApiImageUrl;
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.getGroups();
    this.GetMenuAccess();
    this.GetBreadcrumList();
    this.TechnicianForm = this.fb.group({
      Name: new FormControl(null, [Validators.required]),
    });

    this.prevTaskType.MaintenanceType = this.MaintenanceType[0].Name;
    this.isInit = true;
  }

  //common
  getPaginator(dataSource: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataSource.paginator = this.paginator.toArray()[index];
      dataSource.sort = this.sort.toArray()[index];
    }, 500);
  }

  openFile() {
    this.fileInput.nativeElement.click();
  }

  readFile(event: any) {
    const files = event.target.files;
    this.FileList.push(event.target.files[0]);
    if (files) {
      for (let file of files) {
        if (file.type.match('image.*')) {
          this.fileType = 'image';
        } else if (file.type.match('video.*')) {
          this.fileType = 'video';
        } else if (file.type.match('application.*')) {
          this.fileType = 'application';
        }

        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageSrc = e.target.result;
          this.FileData.push({
            FileType: this.fileType,
            ImageSrc: this.imageSrc,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }

  removeFile(i: any) {
    this.FileData.splice(i, 1);
    this.FileList.splice(i, 1);
  }

  deleteFile(i: any) {
    this.EditFileData.splice(i, 1);
    this.prevTask.FileType.push(i);
  }

  ExportTOExcel(name: any) {
    if (this.HeaderData.Group && this.HeaderData.Plant) {
      const workBook = XLSX.utils.book_new(); // create a new blank book
      if(name == 'PM'){
        this.ExportWOType =
        this.ExportWOType && this.ExportWOType.length
          ? this.ExportWOType
          : [{ ['Group']: '', ['Plant']: '', ['Activity Type Name']: '' }];
        // this.ExportTool =
        //   this.ExportTool && this.ExportTool.length
        //     ? this.ExportTool
        //     : [{ ['Group']: '', ['Plant']: '', ['Tool Name']: '' }];
        this.ExportFrequency =
          this.ExportFrequency && this.ExportFrequency.length
            ? this.ExportFrequency
            : [
                {
                  ['Group']: '',
                  ['Plant']: '',
                  ['Frequency Name']: '',
                  ['Duration / days']: '',
                },
              ];

        this.ExportTask =
          this.ExportTask && this.ExportTask.length
            ? this.ExportTask
            : [
                {
                  ['Task_ID']: '',
                  ['Group']: '',
                  ['Plant']: '',
                  ['Description']: '',
                  ['Technician']: '',
                  ['Task Type']: '',
                  ['Frequency']: '',
                  ['Activity Type']: '',
                  ['Duration / hrs']: '',
                  ['Spare Parts']: '',
                  ['Line / Unit / Assembly / SubAssembly / Component']: '',
                },
              ];

        this.ExportTaskType =
          this.ExportTaskType && this.ExportTaskType.length
            ? this.ExportTaskType
            : [
                {
                  ['Group']: '',
                  ['Plant']: '',
                  ['Task Type Name']: '',
                  ['Maintenance Type']: '',
                },
              ];

        this.ExportTechnician =
          this.ExportTechnician && this.ExportTechnician.length
            ? this.ExportTechnician
            : [{ ['Group']: '', ['Plant']: '', ['Technician']: '' }];
      }
      else{
        this.InavlidExportWOType =
        this.InavlidExportWOType && this.InavlidExportWOType.length
          ? this.InavlidExportWOType
          : [{ ['Group']: '', ['Plant']: '', ['Activity Type Name']: '' }];

        this.InavlidExportFrequency =
          this.InavlidExportFrequency && this.InavlidExportFrequency.length
            ? this.InavlidExportFrequency
            : [
                {
                  ['Group']: '',
                  ['Plant']: '',
                  ['Frequency Name']: '',
                  ['Duration / days']: '',
                },
              ];

        this.InavlidExportTask =
          this.InavlidExportTask && this.InavlidExportTask.length
            ? this.InavlidExportTask
            : [
                {
                  ['Task_ID']: '',
                  ['Group']: '',
                  ['Plant']: '',
                  ['Description']: '',
                  ['Technician']: '',
                  ['Task Type']: '',
                  ['Frequency']: '',
                  ['Activity Type']: '',
                  ['Duration / hrs']: '',
                  ['Spare Parts']: '',
                  ['Line / Unit / Assembly / SubAssembly / Component']: '',
                },
              ];

        this.InavlidExportTaskType =
          this.InavlidExportTaskType && this.InavlidExportTaskType.length
            ? this.InavlidExportTaskType
            : [
                {
                  ['Group']: '',
                  ['Plant']: '',
                  ['Task Type Name']: '',
                  ['Maintenance Type']: '',
                },
              ];

        this.InavlidExportTechnician =
          this.InavlidExportTechnician && this.InavlidExportTechnician.length
            ? this.InavlidExportTechnician
            : [{ ['Group']: '', ['Plant']: '', ['Technician']: '' }];
      }
      
      const ActivityTypeSheet = XLSX.utils.json_to_sheet(name == 'PM' ? this.ExportWOType : this.InavlidExportWOType);
      // const ToolSheet = XLSX.utils.json_to_sheet(this.ExportTool);
      const FrequencySheet = XLSX.utils.json_to_sheet(name == 'PM' ? this.ExportFrequency : this.InavlidExportFrequency);
      const TechnicianSheet = XLSX.utils.json_to_sheet(name == 'PM' ? this.ExportTechnician : this.InavlidExportTechnician);
      const TaskTypeSheet = XLSX.utils.json_to_sheet(name == 'PM' ? this.ExportTaskType : this.InavlidExportTaskType);
      const TaskSheet = XLSX.utils.json_to_sheet(name == 'PM' ? this.ExportTask : this.InavlidExportTask);

      XLSX.utils.book_append_sheet(
        workBook,
        ActivityTypeSheet,
        'Activity Type'
      ); // add the worksheet to the book
      // XLSX.utils.book_append_sheet(workBook, ToolSheet, 'Tool');
      XLSX.utils.book_append_sheet(workBook, FrequencySheet, 'Frequency');
      XLSX.utils.book_append_sheet(workBook, TechnicianSheet, 'Technician');
      XLSX.utils.book_append_sheet(workBook, TaskTypeSheet, 'Task Type');
      XLSX.utils.book_append_sheet(workBook, TaskSheet, 'Task');
      XLSX.writeFile(workBook, `${name}.xlsx`); // initiate a file
    } else {
      this.toaster.warning('Please Fill Header Fields', 'Warning');
      return;
    }
  }

  tosslePerOne() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return;
    }
    if (
      this.TechnicianForm.controls.Name.value.length == this.userList.length
    ) {
      this.TechnicianForm.controls.Name.patchValue([
        ...this.userList.map((item) => item.UserId),
        'ALL',
      ]);
    }
  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.TechnicianForm.controls.Name.patchValue([
        ...this.userList.map((item) => item.UserId),
        'ALL',
      ]);
    } else {
      this.TechnicianForm.controls.Name.patchValue([]);
    }
  }

  getGroups() {
    const key = {
      UserId: '',
    };
    const id = {
      value: 0,
    };
    this.cilMasterService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.dropDownList;
        this.GroupTempList = data.dropDownList;
        if (this.GroupTempList && this.GroupTempList.length) {
          this.HeaderForm.get('Group')?.setValue(this.GroupList[0].id);
          this.getPlants(this.HeaderData.Group);
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(id: any) {
    this.cilMasterService.getPlantsData(id).subscribe(
      (data: any) => {
        this.PlantList = data.dropDownList;
        this.PlantTempList = data.dropDownList;
        if (this.PlantTempList && this.PlantTempList.length) {
          this.HeaderForm.get('Plant')?.setValue(this.PlantList[0].id);
          this.getDropdownData(2);
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getTreeMasterData() {
    const data = {
      GroupID: this.HeaderData.Group,
      PlantID: this.HeaderData.Plant,
    };
    this.spinner.show();
    this.PrevConfigurationService.GetTreeMasterData(data).subscribe(
      (res: any) => {
        if (!this.isInit) this.spinner.hide();
        this.LineAllList = [];
        this.LineList = [];
        this.LineFilterList = [];
        this.UnitAllList = [];
        this.UnitList = [];
        this.UnitFilterList = [];
        this.AssemblyAllList = [];
        this.AssemblyList = [];
        this.AssemblyFilterList = [];
        this.SubAssemblyAllList = [];
        this.SubAssemblyList = [];
        this.SubAssemblyFilterList = [];
        this.ComponentAllList = [];
        this.ComponentList = [];
        this.ComponentFilterList = [];

        this.LineAllList = res.Line;
        this.LineList = res.Line;
        this.UnitAllList = res.Unit;
        this.AssemblyAllList = res.Assembly;
        this.SubAssemblyAllList = res.SubAssembly;
        this.ComponentAllList = res.Component;

        this.LineFilterList = this.LineList.slice();

        if (this.LineAllList && this.LineFilterList.length) {
          this.prevTask.line = this.LineList[0].PL_Id
            ? this.LineList[0].PL_Id
            : '';
        }

        this.setUnitList('notChange');
        this.setAssemblyList('notChange');
        this.setSubAssemblyList('notChange');
        this.setComponentList('notChange');
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  setUnitList(type: any) {
    this.UnitList = [];
    this.UnitFilterList = [];
    this.AssemblyList = [];
    this.AssemblyFilterList = [];
    this.SubAssemblyList = [];
    this.SubAssemblyFilterList = [];
    this.ComponentList = [];
    this.ComponentFilterList = [];

    let unitList = this.UnitAllList.filter(
      (unit: any) => unit.PL_Id == this.prevTask.line
    );
    this.UnitList = unitList;
    this.UnitFilterList = this.UnitList.slice();
    if (unitList && unitList.length) {
      this.prevTask.units = this.UnitFilterList[0].PU_Id
        ? this.UnitFilterList[0].PU_Id
        : '';
      if (type == 'change') {
        this.prevTask.units = '';
        this.prevTask.assembly = '';
        this.prevTask.subAssembly = '';
        this.prevTask.component = '';
        this.setAssemblyList('change');
      }
    }
  }

  setAssemblyList(type: any) {
    this.AssemblyList = [];
    this.AssemblyFilterList = [];
    this.SubAssemblyList = [];
    this.SubAssemblyFilterList = [];
    this.ComponentList = [];
    this.ComponentFilterList = [];
    let assemblyList = this.AssemblyAllList.filter(
      (assembly: any) => assembly.PU_Id == this.prevTask.units
    );
    this.AssemblyList = assemblyList;
    this.AssemblyFilterList = this.AssemblyList.slice();

    if (assemblyList && assemblyList.length) {
      this.prevTask.assembly = this.AssemblyList[0].Assbly_Id
          ? this.AssemblyFilterList[0].Assbly_Id
          : '';
      if(type == 'change'){
        this.prevTask.assembly = '';
        this.prevTask.subAssembly = '';
        this.prevTask.component = '';
        this.setSubAssemblyList('change');
      }
    }
  }

  setSubAssemblyList(type: any) {
    this.SubAssemblyList = [];
    this.SubAssemblyFilterList = [];
    this.ComponentList = [];
    this.ComponentFilterList = [];
    let subassemblyList = this.SubAssemblyAllList.filter(
      (subassembly: any) => subassembly.Assbly_Id == this.prevTask.assembly
    );
    this.SubAssemblyList = subassemblyList;
    this.SubAssemblyFilterList = this.SubAssemblyList.slice();

    if (subassemblyList && subassemblyList.length) {
      this.prevTask.subAssembly = this.SubAssemblyList[0].SAssbly_Id
        ? this.SubAssemblyList[0].SAssbly_Id
        : '';
      if(type == 'change'){
        this.prevTask.subAssembly = '';
        this.prevTask.component = '';
        this.prevTask.subAssembly = this.SubAssemblyList[0].SAssbly_Id
          ? this.SubAssemblyList[0].SAssbly_Id
          : '';
        this.setComponentList('change');
      }
    }
  }

  setComponentList(type: any) {
    this.ComponentList = [];
    this.ComponentFilterList = [];
    let componentList = this.ComponentAllList.filter(
      (component: any) => component.SAssbly_Id == this.prevTask.subAssembly
    );
    this.ComponentList = componentList;
    this.ComponentFilterList = this.ComponentList.slice();
    this.prevTask.component = '';
    if (componentList && componentList.length){
      this.prevTask.component = componentList[0].ComponentId
        ? componentList[0].ComponentId
        : '';
    }
  }

  onGroupKey(value: any) {
    this.GroupList = this.searchGroupKey(value.value);
  }

  searchGroupKey(value: string) {
    let filter = value;
    return this.GroupTempList.filter((grp: any) => {
      let lowerCase = grp.name.toLocaleLowerCase();
      return grp.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  onPlantKey(value: any) {
    this.PlantList = this.searchPlantKey(value.value);
  }

  searchPlantKey(value: string) {
    let filter = value;
    return this.PlantTempList.filter((grp: any) => {
      let lowerCase = grp.name.toLocaleLowerCase();
      return grp.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  pmChange(opened: boolean): void {
    this.raci = this.userList.slice();
  }

  getUserList() {
    this.spinnerText = 'Fetching User Data';
    const data = {
      EGId: this.HeaderData.Group,
      PlantId: this.HeaderData.Plant,
    };
    this.commonService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        if (res.employeeInfoList && res.employeeInfoList.length) {
          this.userList = res.employeeInfoList.filter(
            (emp: any) => emp.Dept_Desc == 'Maintenance' && emp.UserId
          );
          this.raci = res.employeeInfoList.filter(
            (emp: any) => emp.Dept_Desc == 'Maintenance' && emp.UserId
          );

        } else this.userList = [];
        this.orgUserList =
          this.userList && this.userList.length ? this.userList.slice() : [];
        if (!this.isInit) this.spinner.hide();
        this.getAllTechnician();
        this.getTask();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  getTool() {
    this.spinnerText = 'Fetching Tool Data';
    this.spinner.show();
    this.PrevConfigurationService.getTool(this.HeaderData).subscribe(
      (res: any) => {
        this.AllTool = res.allToolList;
        this.ToolList = res.toolList;
        this.ExportTool = [];
        if (this.ToolList && this.ToolList.length) {
          for (const tool of this.ToolList) {
            let grpObj = this.GroupList.find(
              (grp: any) => grp.id == tool.Group.egId
            );
            tool.Group = grpObj;
            let pntObj = this.PlantList.find(
              (pnt: any) => pnt.id == tool.Plant.ptId
            );
            tool.Plant = pntObj;
            const ExportData = {
              ['Group']: tool.Group.name,
              ['Plant']: tool.Plant.name,
              ['Tool Name']: tool.Name,
            };
            this.ExportTool.push(ExportData);
          }
        }
        this.ToolDataSource = new MatTableDataSource(this.ToolList);
        this.ToolPage.length = this.ToolList.length;
        this.getPaginator(this.ToolDataSource, 1);
        if (!this.isInit) this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  getFrequency() {
    this.spinnerText = 'Fetching Frequency Data';
    this.spinner.show();
    this.PrevConfigurationService.getPrevFrequency(this.HeaderData).subscribe(
      (res: any) => {
        this.AllFrequency = res.allFrequencyList;
        this.frequencyList = res.frequencyList;
        this.filterFrequencyList =
          this.frequencyList && this.frequencyList.length
            ? this.frequencyList.slice()
            : [];
        this.ExportFrequency = [];
        if (this.frequencyList && this.frequencyList.length) {
          this.prevTask.Frequency = this.filterFrequencyList[0].ID
            ? this.filterFrequencyList[0].ID
            : '';
          this.FreqDuration = this.filterFrequencyList[0].Duration
            ? this.filterFrequencyList[0].Duration
            : 0;
          for (const fre of this.frequencyList) {
            let grpObj = this.GroupList.find(
              (grp: any) => grp.id == fre.Group.egId
            );
            fre.Group = grpObj;
            let pntObj = this.PlantList.find(
              (pnt: any) => pnt.id == fre.Plant.ptId
            );
            fre.Plant = pntObj;
            const ExportData = {
              ['Group']: fre.Group.name,
              ['Plant']: fre.Plant.name,
              ['Frequency Name']: fre.Name,
              ['Duration / days']: fre.Duration,
            };
            this.ExportFrequency.push(ExportData);
          }
        }
        this.FrequencyDataSource = new MatTableDataSource(this.frequencyList);
        this.FrequencyPage.length = this.frequencyList.length;
        this.getPaginator(this.FrequencyDataSource, 2);
        if (!this.isInit) this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  getTaskType() {
    this.spinnerText = 'Fetching TaskType Data';
    this.spinner.show();
    this.PrevConfigurationService.getTaskType(this.HeaderData).subscribe(
      (res: any) => {
        this.AllTaskType = res.allTaskTypeList;
        this.TaskTypeList = res.taskTypeList;
        this.filterTaskTypeList =
          this.TaskTypeList && this.TaskTypeList.length
            ? this.TaskTypeList.slice()
            : [];
        this.ExportTaskType = [];
        if (this.TaskTypeList && this.TaskTypeList.length) {
          this.prevTask.TaskType = this.filterTaskTypeList[0].ID
            ? this.filterTaskTypeList[0].ID
            : '';
          this.setMaintenanceType();
          for (const tasktype of this.TaskTypeList) {
            let grpObj = this.GroupList.find(
              (grp: any) => grp.id == tasktype.Group.egId
            );
            tasktype.Group = grpObj;
            let pntObj = this.PlantList.find(
              (pnt: any) => pnt.id == tasktype.Plant.ptId
            );
            tasktype.Plant = pntObj;
            const ExportData = {
              ['Group']: tasktype.Group.name,
              ['Plant']: tasktype.Plant.name,
              ['Task Type Name']: tasktype.Name,
              ['Maintenance Type']: tasktype.MaintenanceType,
            };
            this.ExportTaskType.push(ExportData);
          }
        }
        this.TaskTypeDataSource = new MatTableDataSource(this.TaskTypeList);
        this.TaskTypePage.length = this.TaskTypeList.length;
        this.getPaginator(this.TaskTypeDataSource, 3);
        if (!this.isInit) this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  getTask() {
    this.spinnerText = 'Fetching Frequency Data';
    this.spinner.show();
    const data = this.HeaderData;
    this.PrevConfigurationService.getPrevTask(this.HeaderData).subscribe(
      (res: any) => {
        this.TaskList = res.taskList;
        this.ExportTask = [];
        if (this.TaskList && this.TaskList.length) {
          for (const task of this.TaskList) {
            if (task && task.Group) {
              let grpObj = this.GroupList.find(
                (grp: any) => task.Group && task.Group.egId == grp.id
              );
              task.Group = grpObj ? grpObj : '';
              let pntObj = this.PlantList.find(
                (pnt: any) => task.Plant && task.Plant.ptId == pnt.id
              );
              task.Plant = pntObj ? pntObj : '';
              let techObj = this.userList.find(
                (user: any) => task.Technician && task.Technician == user.UserId
              );
              task.Line = task && task.line ? task.line.plDesc : '';
              task.Unit = task && task.units ? task.units.puDesc : '';
              task.Assembly = task && task.assembly ? task.assembly.assblyDesc : '';
              task.SubAssembly = task && task.subAssembly ? task.subAssembly.sAsblyDesc : '';
              task.Component = task && task.component ? task.component.componentName : '';
              task.Technician = techObj ? techObj.fullName : '';
              task.TaskType = task && task.TaskType ? task.TaskType.Name : '';
              task.WOType = task && task.WOType ? task.WOType.Name : '';
              task.Frequency =
                task && task.Frequency ? task.Frequency.Name : '';
              task.FromTaskDate = task && task.StartDate ? task.StartDate : '';
              task.ToTaskDate = task && task.ToTaskDate ? task.ToTaskDate : '';
              task.CreatedBy = this.setUserName(task.CreatedBy);
              task.IsWrite = this.IsWrite;
              const ExportData = {
                ['Task_ID']: task.ID,
                ['Group']: task.Group.name,
                ['Plant']: task.Plant.name,
                ['Line*']: task.Line,
                ['Unit*']: task.Unit,
                ['Assembly']: task.Assembly,
                ['SubAssembly']: task.SubAssembly,
                ['Component']: task.Component,
                ['Description']: task.Description,
                ['Technician']:
                  task.Technician && task?.Technician && task.Technician
                    ? task.Technician
                    : '',

                ['Task Type']: task.TaskType ? task?.TaskType : '',
                ['Frequency']: task.Frequency ? task?.Frequency : '',
                ['Activity Type']: task.WOType ? task?.WOType : '',
                ['Duration / hrs']: task.Duration,
                ['Spare Parts']: task.SpareParts,
                ['From Task Date (*YYYY-MM-DD)']: task.FromTaskDate ? '*' + moment(task.FromTaskDate).format('YYYY-MM-DD') : '',
                ['To Task Date (*YYYY-MM-DD)']: task.ToTaskDate ? '*' + moment(task.ToTaskDate).format('YYYY-MM-DD') : '',
              };
              this.ExportTask.push(ExportData);
            }
          }
        }
        this.TaskPage.length = res.allTaskList;
        this.spinner.hide();
        this.isInit = false;
      },
      (err: any) => {
        this.spinner.hide();
        this.isInit = false;
      }
    );
  }

  applyWOTypeFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.WOTypeDataSource.filter = filterValue.trim().toLowerCase();

    if (this.WOTypeDataSource.paginator) {
      this.WOTypeDataSource.paginator.firstPage();
    }
  }

  applyToolFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ToolDataSource.filter = filterValue.trim().toLowerCase();

    if (this.ToolDataSource.paginator) {
      this.ToolDataSource.paginator.firstPage();
    }
  }

  applyFrequencyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.FrequencyDataSource.filter = filterValue.trim().toLowerCase();

    if (this.FrequencyDataSource.paginator) {
      this.FrequencyDataSource.paginator.firstPage();
    }
  }

  applyTaskTypeFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.TaskTypeDataSource.filter = filterValue.trim().toLowerCase();

    if (this.TaskTypeDataSource.paginator) {
      this.TaskTypeDataSource.paginator.firstPage();
    }
  }

  applyTaskFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.TaskDataSource.filter = filterValue.trim().toLowerCase();

    if (this.TaskDataSource.paginator) {
      this.TaskDataSource.paginator.firstPage();
    }
  }

  editWOType(element: any) {
    this.WOType.ID = element.ID;
    this.WOType.Name = element.Name;
    this.isEditAT = true;
  }

  editTool(element: any) {
    this.edited = true;
    this.prevTool.ID = element.ID;
    this.prevTool.Name = element.Name;
  }

  editFrequency(element: any) {
    this.isEditFreq = true;
    this.prevFrequency.ID = element.ID;
    this.prevFrequency.Name = element.Name;
    this.prevFrequency.Duration = element.Duration;
  }

  editTaskType(element: any) {
    this.isEditTT = true;
    this.prevTaskType.ID = element.ID;
    this.prevTaskType.Name = element.Name;
    this.prevTaskType.MaintenanceType = element.MaintenanceType;
  }

  editTaskRow(element: any) {
    element = element.rowData;
    this.isEditTask = true;
    let UploadFiles: any = [];
    let UploadFilesType: any = [];
    this.FileData = [];
    this.EditFileData = [];

    this.prevTask.ID = element.ID;
    this.prevTask.Description = element.Description;
    this.prevTask.Duration = element.Duration;
    this.prevTask.SpareParts = element.SpareParts;
    this.prevTask.MaintenanceType = element.MaintenanceType;

    const techneeObj = this.TechnicianFiltList.find(
      (tecne: any) =>
        tecne.UserName.trim().toLowerCase() ==
        element.Technician.trim().toLowerCase()
    );
    const freqObj = this.filterFrequencyList.find(
      (fre: any) =>
        fre.Name.trim().toLowerCase() == element.Frequency.trim().toLowerCase()
    );
    this.isTaskFreqUpdate = element.IsDelete == 0 ? 0 : freqObj ? freqObj.ID : 0;
    const wotObj = this.WOTypeFiltList.find(
      (wo: any) =>
        wo.Name.trim().toLowerCase() == element.WOType.trim().toLowerCase()
    );
    const ttObj = this.filterTaskTypeList.find(
      (tt: any) =>
        tt.Name.trim().toLowerCase() == element.TaskType.trim().toLowerCase()
    );

    this.prevTask.Technician = techneeObj ? techneeObj.UserId : '';

    this.prevTask.Frequency = freqObj ? freqObj.ID : '';
    this.prevTask.WOType = wotObj ? wotObj.ID : '';
    this.prevTask.TaskType = ttObj ? ttObj.ID : '';

    if (element.UploadFile && element.FileType) {
      UploadFiles = element.UploadFile.split(',');
      UploadFilesType = element.FileType.split(',');
      for (let i = 0; i < UploadFiles.length; i++) {
        this.EditFileData.push({
          FileType: UploadFilesType[i],
          ImageSrc: UploadFiles[i],
        });
      }
    }

    if (element.units) {
      this.prevTask.displayMachine =
        element.line.plDesc + '/' + element.units.puDesc;
      this.prevTask.line = element.line.plId;
      this.setUnitList('notChange');
      this.prevTask.units = element.units.puId;
      this.setAssemblyList('notChange');
      if (element.assembly) {
        this.prevTask.displayMachine =
          element.line.plDesc +
          '/' +
          element.units.puDesc +
          '/' +
          element.assembly.assblyDesc;
        this.prevTask.assembly = element.assembly.assblyId;
        this.setSubAssemblyList('notChange');
        if (element.subAssembly) {
          this.prevTask.displayMachine =
            element.line.plDesc +
            '/' +
            element.units.puDesc +
            '/' +
            element.assembly.assblyDesc +
            '/' +
            element.subAssembly.sAsblyDesc;
          this.prevTask.subAssembly = element.subAssembly.sAssblyId;
          this.setComponentList('notChange');
          if (element.component) {
            this.prevTask.displayMachine =
              element.line.plDesc +
              '/' +
              element.units.puDesc +
              '/' +
              element.assembly.assblyDesc +
              '/' +
              element.subAssembly.sAsblyDesc +
              '/' +
              element.component.componentName;
            this.prevTask.component = element.component.componentId;
          }
          else{
            this.prevTask.component
          }
        }
        else{
          this.prevTask.subAssembly = '';
          this.prevTask.component
        }
      }
      else{
        this.prevTask.assembly = '';
        this.prevTask.subAssembly = '';
        this.prevTask.component
      }
    }

    const obj = this.frequencyList.find(
      (freq: any) => freq.ID == this.prevTask.Frequency
    );
    this.FreqDuration = obj.Duration;
    this.imageSrc = [];
    this.prevTask.FromTaskDate = element.StartDate;
    this.prevTask.ToTaskDate = element.ToTaskDate;
    this.startDateForTask = element.StartDate;
    this.endDateForTask = element.ToTaskDate;
    this.minDate = element.StartDate;
    this.minToDate = element.ToTaskDate;
  }

  deleteTool(element: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Tool!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        const data = {
          ID: element.ID,
        };
        this.PrevConfigurationService.deleteTool(data).subscribe(
          (success) => {
            this.toaster.success(`Data Deleted Successfully`, 'Success', {
              timeOut: 3000,
            });

            if (success.status == true) {
              this.toaster.success(success.message, 'Success', {
                timeOut: 3000,
              });
            } else {
              this.toaster.warning(success.message, 'Warning', {
                timeOut: 3000,
              });
            }

            this.spinner.hide();
            this.getTool();
          },
          (error) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
      }
      this.edited = false;
    });
  }

  delFrequency(element: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Frequency!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        const data = {
          ID: element.ID,
        };
        this.PrevConfigurationService.deletePrevFrequency(data).subscribe(
          (success) => {
            if (success.status == true) {
              this.toaster.success(success.message, 'Success', {
                timeOut: 3000,
              });
            } else {
              this.toaster.warning(success.message, 'Warning', {
                timeOut: 3000,
              });
            }
            this.spinner.hide();
            this.getFrequency();
          },
          (error) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
      }
      this.isEditFreq = false;
    });
  }

  deleteWOType(element: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete WOType!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.RMConfigurationService.deleteWOType(element).subscribe(
          (success) => {
            if (success.status == true) {
              this.toaster.success(success.message, 'Success', {
                timeOut: 3000,
              });
            } else {
              this.toaster.warning(success.message, 'Warning', {
                timeOut: 3000,
              });
            }
            this.spinner.hide();
            this.getWOType();
          },
          (error) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
      }
      this.isEditAT = false;
    });
  }

  deleteTaskType(element: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Task Type!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        const data = {
          ID: element.ID,
        };
        this.PrevConfigurationService.deleteTaskType(data).subscribe(
          (success) => {
            this.spinner.hide();
            if (success.status == true) {
              this.toaster.success(success.message, 'Success', {
                timeOut: 3000,
              });
            } else {
              this.toaster.warning(success.message, 'Warning', {
                timeOut: 3000,
              });
            }

            this.getTaskType();
          },
          (error) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
      }
      this.isEditTT = false;
    });
  }

  deleteTaskRow(element: any) {
    element = element.rowData;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Task!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        const data = {
          ID: element.ID,
        };
        this.PrevConfigurationService.deletePrevTask(data).subscribe(
          (success) => {
            if (success.status == true) {
              this.toaster.success(success.message, 'Success', {
                timeOut: 3000,
              });
            } else {
              this.toaster.warning(success.message, 'Warning', {
                timeOut: 3000,
              });
            }
            this.spinner.hide();
            this.getTask();
          },
          (error) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
      }
      this.resetTask();
    });
  }

  WOTypeSubmit(formDirective: FormGroupDirective) {
    if (this.HeaderForm.status == 'VALID') {
      if (this.WOTypeForm.status == 'INVALID') {
        this.toaster.warning('Warning', 'Please Fill All Required Fields');
        return;
      } else {
        this.formDirective = formDirective;
        this.WOType.CreatedAt = new Date();
        this.WOType.CreatedBy = this.user.UserId;
        this.WOType.RMPM = 'PM';
        const data = {
          Data: this.WOType,
          HeaderData: this.HeaderData,
        };
        if (!this.isEditAT) {
          const chkWOType = this.WOTypeList.find((wo: any) => {
            const a = wo.Name.toLowerCase();
            const b = this.WOType.Name.toLowerCase();
            return a == b;
          });
          if (chkWOType) {
            this.toaster.warning('Warning', 'This WOType Already Exist');
            return;
          }
          this.RMConfigurationService.addWOType(data).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.WOTypeForm.reset();
              this.isEditAT = false;
              if (res.status) {
                this.toaster.success('Date Added Successfully', res.message);
                this.formDirective.resetForm();
                this.WOTypeForm.reset();
                this.getWOType();
              }
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
        } else {
          const chkWOType = this.WOTypeList.find((wo: any) => {
            const a = wo.Name.toLowerCase();
            const b = this.WOType.Name.toLowerCase();
            return a == b && wo.ID != this.WOType.ID;
          });
          if (chkWOType) {
            this.toaster.warning('Warning', 'This WOType Already Exist');
            return;
          }
          this.RMConfigurationService.updateWOType(data).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.WOTypeForm.reset();
              this.isEditAT = false;
              if (res.status) {
                this.toaster.success('Date Updated Successfully', res.message);
                this.formDirective.resetForm();
                this.WOTypeForm.reset();
                this.getWOType();
              }
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
        }
      }
    } else {
      this.toaster.warning('Warning', 'Please Fill Header Fields');
      return;
    }
  }

  getWOType() {
    this.spinnerText = 'Fetching WOType Data';
    this.spinner.show();
    const data = {
      Group: this.HeaderData.Group,
      Plant: this.HeaderData.Plant,
      RMPM: 'PM',
    };
    this.RMConfigurationService.getWOType(data).subscribe(
      (res: any) => {
        if (!this.isInit) this.spinner.hide();
        this.AllWOType = res.allWoTypeList;
        this.WOTypeList = res.woTypeList;
        this.WOTypeFiltList =
          this.WOTypeList && this.WOTypeList.length
            ? this.WOTypeList.slice()
            : [];
        this.ExportWOType = [];
        if (this.WOTypeList && this.WOTypeList.length) {
          this.prevTask.WOType = this.WOTypeFiltList[0].ID
            ? this.WOTypeFiltList[0].ID
            : '';
          for (const wotype of this.WOTypeList) {
            let grpObj = this.GroupList.find(
              (grp: any) => grp.id == wotype.Group.egId
            );
            wotype.Group = grpObj;
            let pntObj = this.PlantList.find(
              (pnt: any) => pnt.id == wotype.Plant.ptId
            );
            wotype.Plant = pntObj;
            const ExportData = {
              ['Group']: wotype.Group.name,
              ['Plant']: wotype.Plant.name,
              ['Activity Type Name']: wotype.Name,
            };
            this.ExportWOType.push(ExportData);
          }
        }
        this.WOTypeDataSource = new MatTableDataSource(this.WOTypeList);
        this.WOTypePage.length = this.WOTypeList.length;
        this.getPaginator(this.WOTypeDataSource, 0);
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  ToolOnSubmit(formDirective: FormGroupDirective) {
    if (this.HeaderForm.invalid) {
      this.toaster.warning('Warning', 'Please Fill Header Fields');
      return;
    } else if (this.prevToolForm.invalid) {
      this.toaster.warning('Warning', 'Please Fill All Required Fields');
      return;
    } else {
      this.formDirective = formDirective;
      this.prevTool.CreatedAt = new Date();
      this.prevTool.CreatedBy = this.user.UserId;
      const data = {
        Data: this.prevTool,
        HeaderData: this.HeaderData,
      };
      if (!this.edited) {
        const chkTool = this.ToolList.find((tool: any) => {
          const a = tool.Name.toLowerCase();
          const b = this.prevTool.Name.toLowerCase();
          return a == b;
        });
        if (chkTool) {
          this.toaster.warning('Warning', 'This Tool Already Exist');
          return;
        }
        this.PrevConfigurationService.addTool(data).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.edited = false;
            this.formDirective.resetForm();
            this.prevToolForm.reset();
            if (res.status) {
              this.toaster.success('Date Added Successfully', res.message);
              this.getTool();
            }
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          }
        );
      } else {
        this.PrevConfigurationService.updateTool(data).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.edited = false;
            this.formDirective.resetForm();
            this.prevToolForm.reset();
            if (res.status) {
              this.toaster.success('Date Updated Successfully', res.message);
              this.getTool();
            }
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          }
        );
      }
    }
  }

  FrequencyOnSubmit(formDirective: FormGroupDirective) {
    if (this.HeaderForm.invalid) {
      this.toaster.warning('Warning', 'Please Fill Header Fields');
      return;
    } else if (this.prevFrequencyForm.invalid) {
      this.toaster.warning('Warning', 'Please Fill All Required Fields');
      return;
    } else {
      this.formDirective = formDirective;
      this.prevFrequency.CreatedAt = new Date();
      this.prevFrequency.CreatedBy = this.user.UserId;

      const data = {
        Data: this.prevFrequency,
        HeaderData: this.HeaderData,
      };
      if (!this.isEditFreq) {
        const chkFrequency = this.frequencyList.find((fre: any) => {
          const a = fre.Name.toLowerCase();
          const b = this.prevFrequency.Name.toLowerCase();
          return a == b;
        });
        if (chkFrequency) {
          this.toaster.warning('Warning', 'This Frequency Already Exist');
          return;
        }
        this.PrevConfigurationService.addPrevFrequency(data).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.isEditFreq = false;
            if (res.status) {
              this.toaster.success('Date Added Successfully', res.message);
              this.formDirective.resetForm();
              this.prevFrequencyForm.reset();
              this.getFrequency();
            }
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          }
        );
      } else {
        const chkFrequency = this.frequencyList.find((fre: any) => {
          const a = fre.Name.toLowerCase();
          const b = this.prevFrequency.Name.toLowerCase();
          return a == b && fre.ID != this.prevFrequency.ID;
        });
        if (chkFrequency) {
          this.toaster.warning('Warning', 'This Frequency Already Exist');
          return;
        }
        this.PrevConfigurationService.updatePrevFrequency(data).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.isEditFreq = false;
            if (res.status) {
              this.toaster.success('Date Updated Successfully', res.message);
              this.formDirective.resetForm();
              this.prevFrequencyForm.reset();
              this.getFrequency();
            }
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          }
        );
      }
    }
  }

  TaskTypeOnSubmit(formDirective: FormGroupDirective) {
    if (this.HeaderForm.invalid) {
      this.toaster.warning('Warning', 'Please Fill Header Fields');
      return;
    } else if (this.prevTaskTypeForm.invalid) {
      this.toaster.warning('Warning', 'Please Fill All Required Fields');
      return;
    } else {
      this.formDirective = formDirective;
      this.prevTaskType.CreatedAt = new Date();
      this.prevTaskType.CreatedBy = this.user.UserId;
      const data = {
        Data: this.prevTaskType,
        HeaderData: this.HeaderData,
      };
      if (!this.isEditTT) {
        const chkTaskType = this.TaskTypeList.find((tasktype: any) => {
          const a = tasktype.Name.toLowerCase();
          const b = this.prevTaskType.Name.toLowerCase();
          const c = tasktype?.MaintenanceType?.toLowerCase();
          const d = this.prevTaskType?.MaintenanceType?.toLowerCase();
          return a == b && c == d;
        });
        if (chkTaskType) {
          this.toaster.warning('Warning', 'This TaskType Already Exist');
          return;
        }
        this.PrevConfigurationService.addTaskType(data).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.isEditTT = false;
            this.formDirective.resetForm();
            this.prevTaskTypeForm.reset();
            if (res.status) {
              this.toaster.success('Date Added Successfully', res.message);
              this.getTaskType();
            }
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          }
        );
      } else {
        const chkTaskType = this.TaskTypeList.find((tasktype: any) => {
          const a = tasktype.Name.toLowerCase();
          const b = this.prevTaskType.Name.toLowerCase();
          const c = tasktype?.MaintenanceType?.toLowerCase();
          const d = this.prevTaskType?.MaintenanceType?.toLowerCase();
          return a == b && c == d && tasktype.ID != this.prevTaskType.ID;
        });
        if (chkTaskType) {
          this.toaster.warning('Warning', 'This TaskType Already Exist');
          return;
        }
        this.PrevConfigurationService.updateTaskType(data).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.isEditTT = false;
            this.formDirective.resetForm();
            this.prevTaskTypeForm.reset();
            if (res.status) {
              this.toaster.success('Date Updated Successfully', res.message);
              this.getTaskType();
            }
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error', error.message);
          }
        );
      }
    }
  }

  TaskOnSubmit(formDirective: FormGroupDirective) {
    if (this.HeaderForm.invalid) {
      this.toaster.warning('Warning', 'Please Fill Header Fields');
      return;
    } else if (this.prevTaskForm.invalid) {
      this.toaster.warning('Warning', 'Please Fill All Required Fields');
      return;
    } else {
      let technician = this.TechnicianFiltList.find((user: any) => {
        return this.prevTask.Technician == user.UserId
      });
      let frequency = this.AllFrequency.find((freq: any) => {
        return this.prevTask.Frequency == freq.ID
      });
      let wotpe = this.AllWOType.find((tsty: any) => {
        return this.prevTask.WOType == tsty.ID
      });
      let tasktype = this.AllTaskType.find((tt: any) => {
        return this.prevTask.TaskType == tt.ID
      });
      if(!this.isEditTask){
        const chkTask = this.TaskList.find((task: any) => {
          return  task.line.plId == this.prevTask.line && task.units.puId == this.prevTask.units
                  && task?.assembly?.assblyId == this.prevTask.assembly && task?.subAssembly?.sAssblyId == this.prevTask.subAssembly
                  && task?.component?.componentId == this.prevTask.component 
                  && task.Technician == technician.UserName
                  && task.TaskType == tasktype.Name
                  && task.Frequency == frequency.Name
                  && task.WOType == wotpe.Name
                  && task.Description.replace(/\s/g, '').toLowerCase() == this.prevTask.Description.replace(/\s/g, '').toLowerCase();
        });
        if (chkTask) {
          this.toaster.warning('Warning', 'This Task Already Exist');
          return;
        }
      }
      else{
        const chkTask = this.TaskList.find((task: any) => {
          return  task.line.plId == this.prevTask.line && task.units.puId == this.prevTask.units
                  && task?.assembly?.assblyId == this.prevTask.assembly && task?.subAssembly?.sAssblyId == this.prevTask.subAssembly
                  && task?.component?.componentId == this.prevTask.component && task.ID !== this.prevTask.ID
                  && task.Technician == technician.UserName
                  && task.TaskType == tasktype.Name
                  && task.Frequency == frequency.Name
                  && task.WOType == wotpe.Name
                  && task.Description.replace(/\s/g, '').toLowerCase() == this.prevTask.Description.replace(/\s/g, '').toLowerCase();
        });
        if (chkTask) {
          this.toaster.warning('Warning', 'This Task Already Exist');
          return;
        }
      }
      this.formDirective = formDirective;
      this.prevTask.Group = this.HeaderData.Group;
      this.prevTask.Plant = this.HeaderData.Plant;
      this.prevTask.CreatedAt = new Date();
      this.prevTask.CreatedBy = this.user.UserId;
      let NextDate = new Date();
      NextDate.setDate(NextDate.getDate() + this.FreqDuration);
      this.prevTask.NextScheduleAt = NextDate;
      this.prevTask.NextScheduleAt = this.prevTask.NextScheduleAt;
      this.prevTask.FromTaskDate = moment(
        new Date(this.prevTask.FromTaskDate)
      ).format('MM/DD/YYYY hh:mm:ss');
      this.prevTask.ToTaskDate = moment(
        new Date(this.prevTask.ToTaskDate)
      ).format('MM/DD/YYYY hh:mm:ss');
      // if (!this.prevTask.assembly) this.prevTask.assembly = null;
      // if (!this.prevTask.subAssembly) this.prevTask.subAssembly = null;
      // if (!this.prevTask.component) this.prevTask.component = null;

      this.spinner.show();
      if (!this.isEditTask) {
        this.spinnerText = 'Creating Prev Task Data';
        const Formvalues = new FormData();
        for (const file of this.FileList)
          Formvalues.append('File', file, file.name);
        Formvalues.append('Data', JSON.stringify(this.prevTask));

        this.PrevConfigurationService.addPrevTask(Formvalues).subscribe(
          (res: any) => {
            if (res.status) {
              this.resetTask();
              this.FileData = [];
              this.spinner.hide();
              this.toaster.success(
                res.message || `Data Inserted Successfully`,
                'Success',
                {
                  timeOut: 3000,
                }
              );
              this.getTask();
            }
          },
          (err: any) => {
            this.spinner.hide();
            this.toaster.error(err.message || `Server Error`, 'Error', {
              timeOut: 3000,
            });
          }
        );
      } else {
        this.spinnerText = 'Updating Prev Task Data';
        delete this.prevTask.lineName;
        delete this.prevTask.unitName;
        delete this.prevTask.assemblyName;
        delete this.prevTask.subAssemblyName;
        delete this.prevTask.componentName;
        delete this.prevTask.displayMachine;

        const Formvalues = new FormData();
        for (const file of this.FileList)
          Formvalues.append('File', file, file.name);
        Formvalues.append('Data', JSON.stringify(this.prevTask));
        this.PrevConfigurationService.updatePrevTask(Formvalues).subscribe(
          (res: any) => {
            if (res.status) {
              this.spinner.hide();
              this.toaster.success(
                res.message || `Data Updated Successfully`,
                'Success',
                {
                  timeOut: 3000,
                }
              );
              this.resetTask();
              this.minDate = new Date();
              this.minToDate = new Date();
              this.getTask();
            }
          },
          (err: any) => {
            this.spinner.hide();
            this.toaster.error(err.message || `Server Error`, 'Error', {
              timeOut: 3000,
            });
          }
        );
      }
    }
  }

  resetTask(){
    this.EditFileData = [];
    this.FileData = [];
    this.isEditTask = false;
    this.isTaskFreqUpdate = -1;
    this.prevTask = {
      line: this.LineList[0].PL_Id ? this.LineList[0].PL_Id: '',
      units: null,
      assembly: null,
      subAssembly: null,
      component: null,
      lineName: '',
      unitName: '',
      assemblyName: '',
      subAssemblyName: '',
      componentName: '',
      Description: '',
      Technician: '',
      Frequency: '',
      displayMachine: '',
      Duration: '',
      SpareParts: '',
      UploadFile: '',
      TaskType: '',
      MaintenanceType: '',
      FromTaskDate: new Date(),
      FileType: [],
      CreatedAt: '',
      CreatedBy: 0,
      ID: 0,
      IsFrequencyUpdate: false
    };
    this.minDate = new Date();
    this.minToDate = new Date();
    this.setUnitList('notChange');
    this.setAssemblyList('notChange');
    this.setSubAssemblyList('notChange');
    this.setComponentList('notChange');
  }

  searchValues(filter: any) {
    filter = filter.target.value;
    this.userList = this.orgUserList.filter((user: any) => {
      let lowerCase = user.fullName.toLocaleLowerCase();
      return (
        user.fullName.startsWith(filter) || lowerCase.startsWith(filter) !== 0
      );
    });
  }

  TechnicianSubmit() {
    if (this.HeaderForm.status == 'VALID') {
      const index: number = this.Technician.Name.indexOf('ALL');
      if (index !== -1) {
        this.Technician.Name.splice(index, 1);
      }
      this.Technician.CreatedAt = new Date();
      this.Technician.CreatedBy = this.user.UserId;
      const data = {
        Data: this.Technician,
        HeaderData: this.HeaderData,
      };
      this.RMConfigurationService.addTechnician(data).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status) {
            this.toaster.success(res.message, 'Success');
            this.getAllTechnician();
          }
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error(error.message, 'Error');
        }
      );
    } else {
      this.toaster.warning('Warning', 'Please Fill Header Fields');
      return;
    }
  }

  setUserName(val: any) {
    if (this.userList && this.userList.length) {
      const CreatedBy = this.userList.find((usr: any) => usr.UserId == val);
      return CreatedBy ? CreatedBy.fullName : '';
    }
  }

  customePaginator(event: any, name: any) {
    if (name == 'activitytype') {
      this.WOTypePage.pageIndex = event.pageIndex;
      this.WOTypePage.pageSize = event.pageSize;
    } else if (name == 'tool') {
      this.ToolPage.pageIndex = event.pageIndex;
      this.ToolPage.pageSize = event.pageSize;
    } else if (name == 'frequency') {
      this.FrequencyPage.pageIndex = event.pageIndex;
      this.FrequencyPage.pageSize = event.pageSize;
    } else if (name == 'tasktype') {
      this.TaskTypePage.pageIndex = event.pageIndex;
      this.TaskTypePage.pageSize = event.pageSize;
    } else if (name == 'task') {
      this.TaskPage.pageIndex = event.pageIndex;
      this.TaskPage.pageSize = event.pageSize;
      this.getTask();
    }
  }

  onNodeSelect(node: any): void {
    this.updateOrderprocessingData = false;
    if (
      node.Level == 'Unit' ||
      node.Level == 'Assembly' ||
      node.Level == 'SubAssembly' ||
      node.Level == 'Component'
    ) {
      this.prevTask.line = null;
      this.prevTask.units = null;
      this.prevTask.assembly = null;
      this.prevTask.subAssembly = null;
      this.prevTask.component = null;
      if (node.Level == 'Unit') {
        this.prevTask.line = node.lineId;
        this.prevTask.lineName = node.lineName;
        this.prevTask.units = node.PrimaryId;
        this.prevTask.unitName = node.text;
        this.prevTask.displayMachine = `${node.lineName}/${node.text}`;
        this.prevTask.Plant = node.plant;
      } else if (node.Level == 'Assembly') {
        this.prevTask.assembly = node.PrimaryId;
        this.prevTask.assemblyName = node.text;
        this.prevTask.units = node.unitId;
        this.prevTask.unitName = node.unitName;
        this.prevTask.line = node.lineId;
        this.prevTask.lineName = node.lineName;
        this.prevTask.displayMachine = `${node.lineName}/${node.unitName}/${node.text}`;
        this.prevTask.Plant = node.plant;
      } else if (node.Level == 'SubAssembly') {
        this.prevTask.subAssembly = node.PrimaryId;
        this.prevTask.subAssemblyName = node.text;
        this.prevTask.assembly = node.assemblyId;
        this.prevTask.assemblyName = node.assemblyName;
        this.prevTask.units = node.unitId;
        this.prevTask.unitName = node.unitName;
        this.prevTask.line = node.lineId;
        this.prevTask.lineName = node.lineName;
        this.prevTask.displayMachine = `${node.lineName}/${node.unitName}/${node.assemblyName}/${node.text}`;
        this.prevTask.Plant = node.plant;
      } else if (node.Level == 'Component') {
        this.prevTask.component = node.PrimaryId;
        this.prevTask.componentName = node.text;
        this.prevTask.subAssembly = node.subAssemblyId;
        this.prevTask.subAssemblyName = node.subAssemblyName;
        this.prevTask.assembly = node.assemblyId;
        this.prevTask.assemblyName = node.assemblyName;
        this.prevTask.units = node.unitId;
        this.prevTask.unitName = node.unitName;
        this.prevTask.line = node.lineId;
        this.prevTask.lineName = node.lineName;
        this.prevTask.displayMachine = `${node.lineName}/${node.unitName}/${node.assemblyName}/${node.subAssemblyName}/${node.text}`;
        this.prevTask.Plant = node.plant;
      }
    } else return;
  }

  openTreeModal(): void {
    const key = {
      EGId: this.HeaderData.Group,
      level: 'Plant',
      CreatedBy: 0,
      IsOptional: 0,
    };
    this.spinner.show();
    this.spinnerText = 'Fetching Tree Data';
    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (!this.dialogBox) {
          this.updateOrderprocessingData = true;
          const plantGroupData: PlantGroupElement[] = data.TreeModalList;
          this.plantGroupSource.data = plantGroupData;
          this.udpateNodeParentDetails();
          this.treeControl.dataNodes = this.plantGroupSource.data;
          this.isPlantGroupModelVisible = true;
        } else {
          this.plantGroupSource.data = data.TreeModalList;
          this.udpateNodeParentDetails();
          this.dialogBox = false;
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  udpateNodeParentDetails() {
    try {
      if (this.plantGroupSource.data && this.plantGroupSource.data.length) {
        this.plantGroupSource.data.forEach((group, groupIndex) => {
          if (group.children && group.children.length) {
            group.children.forEach((plant, plantIndex) => {
              plant.groupName = group.text;
              plant.groupId = group.PrimaryId;
              if (plant.children && plant.children.length) {
                plant.children.forEach(
                  (division: any, divisionIndex: number) => {
                    division.plantId = plant.PrimaryId;
                    division.plantName = plant.text;
                    division.groupName = group.text;
                    division.groupId = group.PrimaryId;
                    if (division.children && division.children.length) {
                      division.children.forEach(
                        (department: any, departmentIndex: number) => {
                          department.divisionId = division.PrimaryId;
                          department.divisionName = division.text;
                          department.plantId = plant.PrimaryId;
                          department.plantName = plant.text;
                          department.groupId = group.PrimaryId;
                          department.groupName = group.text;
                          if (
                            department.children &&
                            department.children.length
                          ) {
                            department.children.forEach((line: any) => {
                              line.departmentId = department.PrimaryId;
                              line.departmentName = department.text;
                              line.divisionId = division.PrimaryId;
                              line.divisionName = division.text;
                              line.plantId = plant.PrimaryId;
                              line.plantName = plant.text;
                              line.groupId = group.PrimaryId;
                              line.groupName = group.text;
                              if (line.children && line.children.length) {
                                line.children.forEach((unit: any) => {
                                  unit.lineId = line.PrimaryId;
                                  unit.lineName = line.text;
                                  unit.departmentId = department.PrimaryId;
                                  unit.departmentName = department.text;
                                  unit.divisionId = division.PrimaryId;
                                  unit.divisionName = division.text;
                                  unit.plantId = plant.PrimaryId;
                                  unit.plantName = plant.text;
                                  unit.groupId = group.PrimaryId;
                                  unit.groupName = group.text;
                                  if (unit.children && unit.children.length) {
                                    unit.children.forEach((assembly: any) => {
                                      assembly.unitName = unit.text;
                                      assembly.unitId = unit.PrimaryId;
                                      assembly.lineId = line.PrimaryId;
                                      assembly.lineName = line.text;
                                      assembly.departmentId =
                                        department.PrimaryId;
                                      assembly.departmentName = department.text;
                                      assembly.divisionId = division.PrimaryId;
                                      assembly.divisionName = division.text;
                                      assembly.plantId = plant.PrimaryId;
                                      assembly.plantName = plant.text;
                                      assembly.groupId = group.PrimaryId;
                                      assembly.groupName = group.text;
                                      if (
                                        assembly.children &&
                                        assembly.children.length
                                      ) {
                                        assembly.children.forEach(
                                          (subAssembly: any) => {
                                            subAssembly.assemblyName =
                                              assembly.text;
                                            subAssembly.assemblyId =
                                              assembly.PrimaryId;
                                            subAssembly.unitName = unit.text;
                                            subAssembly.unitId = unit.PrimaryId;
                                            subAssembly.lineId = line.PrimaryId;
                                            subAssembly.lineName = line.text;
                                            subAssembly.departmentId =
                                              department.PrimaryId;
                                            subAssembly.departmentName =
                                              department.text;
                                            subAssembly.divisionId =
                                              division.PrimaryId;
                                            subAssembly.divisionName =
                                              division.text;
                                            subAssembly.plantId =
                                              plant.PrimaryId;
                                            subAssembly.plantName = plant.text;
                                            subAssembly.groupId =
                                              group.PrimaryId;
                                            subAssembly.groupName = group.text;
                                            if (
                                              subAssembly.children &&
                                              subAssembly.children.length
                                            ) {
                                              subAssembly.children.forEach(
                                                (component: any) => {
                                                  component.subAssemblyName =
                                                    subAssembly.text;
                                                  component.subAssemblyId =
                                                    subAssembly.PrimaryId;
                                                  component.assemblyName =
                                                    assembly.text;
                                                  component.assemblyId =
                                                    assembly.PrimaryId;
                                                  component.unitName =
                                                    unit.text;
                                                  component.unitId =
                                                    unit.PrimaryId;
                                                  component.lineId =
                                                    line.PrimaryId;
                                                  component.lineName =
                                                    line.text;
                                                  component.departmentId =
                                                    department.PrimaryId;
                                                  component.departmentName =
                                                    department.text;
                                                  component.divisionId =
                                                    division.PrimaryId;
                                                  component.divisionName =
                                                    division.text;
                                                  component.plantId =
                                                    plant.PrimaryId;
                                                  component.plantName =
                                                    plant.text;
                                                  component.groupId =
                                                    group.PrimaryId;
                                                  component.groupName =
                                                    group.text;
                                                }
                                              );
                                            }
                                          }
                                        );
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          }
                        }
                      );
                    }
                  }
                );
              }
            });
          }
        });
      }
    } catch (err: any) {
      this.toaster.error(err, 'Error');
    }
  }

  closePlantGroupModel(): void {
    this.updateOrderprocessingData = false;
  }

  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  opensideComponentModal(): void {
    this.formMode = true;
    this.displayComponentModal = true;
  }
  closeaddNewPlantItemModel(): void {
    this.displayComponentModal = false;
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }

  setFrequencyDuration() {
    const obj = this.frequencyList.find(
      (freq: any) => freq.ID == this.prevTask.Frequency
    );
    this.FreqDuration = obj.Duration;
    this.prevTask.Duration = null;
    if(this.isEditTask && this.isTaskFreqUpdate != 0 && this.isTaskFreqUpdate != -1 && this.prevTask.Frequency != this.isTaskFreqUpdate)
    {
      this.prevTask.IsFrequencyUpdate = true;
      this.minDate = new Date();
      this.minToDate = new Date();
      this.prevTask.FromTaskDate = new Date();
      this.prevTask.ToTaskDate = new Date();
    }
    else if(this.prevTask.Frequency == this.isTaskFreqUpdate){
      this.minDate = this.startDateForTask;
      this.minToDate = this.endDateForTask;
      this.prevTask.FromTaskDate = new Date(this.minDate);
      this.prevTask.ToTaskDate = new Date(this.minToDate);
      this.prevTask.IsFrequencyUpdate = false;
    }
  }
  durationChange(val) {
    if (val <= 24) return;
    else {
      this.prevTask.Duration = null;
      this.toaster.warning('Duration must be lessthan 1 Day');
    }
  }
  setMaintenanceType() {
    const obj = this.TaskTypeList.find(
      (tt: any) => tt.ID == this.prevTask.TaskType
    );
    this.prevTask.MaintenanceType =
      obj && obj.MaintenanceType ? obj.MaintenanceType : '';
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  SelectVarUnitName(unitname: any): any {
    if (unitname.title === 'UNT') {
      this.varUnitName = unitname.name;
      this.isPlantGroupModelVisible = false;
    }
  }
  resetForm(form: FormGroup, value: any) {
    form.get(value)?.setValue('');
  }

  getDropdownData(i: number) {
    if (i == 1) {
      const ind = this.GroupList.findIndex(
        (obj: any) => obj.egId == this.HeaderData.Group
      );
      this.PlantList = this.GroupList[ind].plants;
      for (var i = 0; i < this.PlantList.length; i++) {
        if (this.PlantList[i].isDeleted == true) {
          this.PlantList.splice(i, 1);
          i--;
        }
      }
      this.PlantList.sort(function (a: any, b: any) {
        return a.ptId - b.ptId;
      });
    } else if (i == 2) {
      // this.filterFrequencyList = this.frequencyList.filter(
      //   (obj: any) =>
      //     obj.Group.egId == this.HeaderData.Group &&
      //     obj.Plant.ptId == this.HeaderData.Plant
      // );
      this.isInit=true;
      this.getTreeMasterData();
      this.getUserList();
      // this.getTool();
      this.getFrequency();
      this.getTaskType();
      this.getWOType();
      // this.getTask();
    }
  }

  getAllTechnician() {
    this.spinnerText = 'Fetching Technician Data';
    this.spinner.show();
    const data = {
      Plant: this.HeaderData.Plant,
      Group: this.HeaderData.Group,
      RMPM: 'PM',
    };
    this.RMConfigurationService.getTechnician(data).subscribe(
      (res: any) => {
        if (!this.isInit) this.spinner.hide();
        if (this.HeaderData.Group && this.HeaderData.Plant) {
          this.TechnicianList = res.data;
          this.Technician.Name = [];
          this.userList = [...this.orgUserList];
          this.filterTechnicianList = [];
          for (let tech of this.TechnicianList) {
            const user = this.userList.find(
              (user: any) => user.UserId == tech.UserId
            );
            if (user) {
              tech.UserName = user.fullName;
              let check;
              if (this.Technician?.Name?.length) {
                check = this.Technician.Name.find(
                  (user: any) => user == tech.UserId
                );
                if (!check) {
                  this.Technician.Name.push(tech.UserId);
                  this.filterTechnicianList.push({
                    UserId: tech.UserId,
                    UserName: tech.UserName,
                  });
                }
              } else {
                this.Technician.Name.push(tech.UserId);
                this.filterTechnicianList.push({
                  UserId: tech.UserId,
                  UserName: tech.UserName,
                });
              }
            }
          }
        } else {
          for (let exceltech of res.occupiedTechnicians) {
            var usr = this.userList.find(
              (user: any) => user.UserId == exceltech
            );
            if (usr) this.ExcelTechnicianList.push(usr);
          }
        }
        this.TechnicianFiltList =
          this.filterTechnicianList && this.filterTechnicianList.length
            ? this.filterTechnicianList.slice()
            : [];

        this.prevTask.Technician = this.TechnicianFiltList[0]?.UserId
          ? this.TechnicianFiltList[0]?.UserId
          : '';
        this.ExportTechnician = [];
        if (this.TechnicianList && this.TechnicianList.length) {
          for (const tech of this.TechnicianList) {
            const ExportData = {
              ['Group']: tech.Group.egDesc,
              ['Plant']: tech.Plant.ptDesc,
              ['Technician']: tech.UserName,
            };
            this.ExportTechnician.push(ExportData);
          }
        }
        if (this.Technician.Name.length == this.userList.length)
          this.TechnicianForm.controls.Name.patchValue([
            ...this.userList.map((item) => item.UserId),
            'ALL',
          ]);
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  onFilterChanged(event: any) {
    this.gridOptions.api.setQuickFilter(event.value);
  }
  /// AG Grid
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  openDialog(name: any): void {
    if (this.HeaderData.Group && this.HeaderData.Plant) {
      this.dialogBox = true;
      this.openTreeModal();
      let dialogRef = this.dialog.open(PreventiveDialogComponent, {
        disableClose: true,
        width: '500px',
        data: { moduleName: name },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.InavlidExportWOType = [];
        this.InavlidExportFrequency = [];
        this.InavlidExportTaskType = [];
        this.InavlidExportTechnician = [];
        this.InavlidExportTask = [];
        this.excelFile = [];
        if (result == undefined) {
        } else {
          this.excelFile = result;
          this.validateExcelData();
        }
      });
    } else {
      this.toaster.warning('Please Fill Header Fields', 'Warning');
      return;
    }
  }

  validateExcelData() {
    try {
      this.TechnologyMasterData = [];
      this.TaskTypeMasterData = [];
      this.ToolMasterData = [];
      this.FrequencyMasterData = [];
      this.TaskMasterData = [];
      this.TechnicianMasterData = [];
      let TechnoRow = 1;
      let TTRow = 1;
      let FreRow = 1;
      let TaskRow = 1;
      let TechRow = 1;
      let invalidActivityRow = false;
      let invalidFreqRow = false;
      let invalidTaskTypeRow = false;
      let invalidTchnicianRow = false;
      let invalidTaskRow = false;

      if (
        this.excelFile &&
        this.excelFile.Technology &&
        this.excelFile.Technology.length
      ) {
        for (let obj of this.excelFile.Technology) {
          const TechnologyData: any = {
            Group: '',
            Plant: '',
            Name: '',
            CreatedAt: '',
            CreatedBy: '',
          };
          if (!obj['Group']) {
            invalidActivityRow = true;
          } else if (!obj['Plant']) {
            invalidActivityRow = true;
          } else if (!obj['Activity Type Name']) {
            invalidActivityRow = true;
          } else {
            let groupobj: any;
            let plantobj: any;
            let uniqueData: any;
            let existXLData: any;

            groupobj = this.plantGroupSource.data.find((grp: any) => {
              const a = obj['Group'].trim().toLowerCase();
              const b = grp.text.trim().toLowerCase();
              return a == b;
            });

            if (groupobj) {
              TechnologyData.Group = groupobj.PrimaryId;
              plantobj = groupobj.children.find((pnt: any) => {
                const a = obj['Plant'].trim().toLowerCase();
                const b = pnt.text?.trim().toLowerCase();
                return a == b;
              });
              if (plantobj) {
                TechnologyData.Plant = plantobj.PrimaryId;

                uniqueData = this.AllWOType.find((tt: any) => {
                  const a = obj['Activity Type Name'].replace(/\s/g, '').toLowerCase();
                  const b = tt.Name.replace(/\s/g, '').toLowerCase();
                  return (
                    tt.Group &&
                    tt.Group.egId == TechnologyData.Group &&
                    tt.Plant &&
                    tt.Plant.ptId == TechnologyData.Plant &&
                    a == b
                  );
                });

                if (!uniqueData) {
                  TechnologyData.Name = obj['Activity Type Name'];
                }
                else{
                  invalidActivityRow = true;
                }
              } else {
                invalidActivityRow = true;
              }
            } else {
              invalidActivityRow = true;
            }

            if (TechnoRow != 1) {
              existXLData = this.TechnologyMasterData.find((tt: any) => {
                return (
                  tt.Name == TechnologyData.Name &&
                  tt.Group == TechnologyData.Group &&
                  tt.Plant == TechnologyData.Plant
                );
              });
            }
            if (uniqueData || existXLData)
              invalidActivityRow = true;
          }
          if(invalidActivityRow){
            const ExportData = {
              ['RowNo']: TechnoRow,
              ['Group']: obj['Group'],
              ['Plant']: obj['Plant'],
              ['Activity Type Name']: obj['Activity Type Name'],
            };
            this.InavlidExportWOType.push(ExportData);
          }
          else{
            TechnologyData.CreatedAt = new Date();
            TechnologyData.CreatedBy = this.user.UserId;
            this.TechnologyMasterData.push(TechnologyData);
          }
          TechnoRow++;
          invalidActivityRow = false
        }
      }

      if (
        this.excelFile &&
        this.excelFile.Frequency &&
        this.excelFile.Frequency.length
      ) {
        for (let obj of this.excelFile.Frequency) {
          const FrequencyData: any = {
            Group: '',
            Plant: '',
            Name: '',
            Duration: '',
            CreatedAt: '',
            CreatedBy: '',
          };
          if (!obj['Group']) {
            invalidFreqRow = true;
          } else if (!obj['Plant']) {
            invalidFreqRow = true;
          } else if (!obj['Frequency Name']) {
            invalidFreqRow = true;
          } else if (
            !obj['Duration / days'] ||
            typeof obj['Duration / days'] != 'number'
          ) {
            invalidFreqRow = true;
          } else {
            let groupobj: any;
            let plantobj: any;
            let uniqueData: any;
            let existXLData: any;

            groupobj = this.plantGroupSource.data.find((grp: any) => {
              const a = obj['Group'].trim().toLowerCase();
              const b = grp.text.trim().toLowerCase();
              return a == b;
            });

            if (groupobj) {
              FrequencyData.Group = groupobj.PrimaryId;
              plantobj = groupobj.children.find((pnt: any) => {
                const a = obj['Plant'].trim().toLowerCase();
                const b = pnt.text?.trim().toLowerCase();
                return a == b;
              });
              if (plantobj) {
                FrequencyData.Plant = plantobj.PrimaryId;
                uniqueData = this.AllFrequency.find((fre: any) => {
                  const a = obj['Frequency Name'].replace(/\s/g, '').toLowerCase();
                  const b = fre.Name.replace(/\s/g, '').toLowerCase();
                  return (
                    fre.Group &&
                    fre.Group.egId == FrequencyData.Group &&
                    fre.Plant &&
                    fre.Plant.ptId == FrequencyData.Plant &&
                    a == b
                  );
                });
                if (!uniqueData) {
                  FrequencyData.Name = obj['Frequency Name'];
                  FrequencyData.Duration = obj['Duration / days'];
                }
                else{
                  invalidFreqRow = true;
                }
              } else {
                invalidFreqRow = true;
              }
            } else {
              invalidFreqRow = true;
            }

            if (FreRow != 1) {
              existXLData = this.FrequencyMasterData.find((fre: any) => {
                return (
                  fre.Name == FrequencyData.Name &&
                  fre.Group == FrequencyData.Group &&
                  fre.Plant == FrequencyData.Plant
                );
              });
            }
            if (uniqueData || existXLData)
              invalidFreqRow = true;
          }
          if(invalidFreqRow){
            const ExportData = {
              ['RowNo']: FreRow,
              ['Group']: obj['Group'],
              ['Plant']: obj['Plant'],
              ['Frequency Name']: obj['Frequency Name'],
              ['Duration / days']: obj['Duration / days'],
            };
            this.InavlidExportFrequency.push(ExportData);
          }
          else{
            FrequencyData.CreatedAt = new Date();
            FrequencyData.CreatedBy = this.user.UserId;
            this.FrequencyMasterData.push(FrequencyData);
          }
          FreRow++;
          invalidFreqRow = false;
        }
      }

      if (
        this.excelFile &&
        this.excelFile.TaskType &&
        this.excelFile.TaskType.length
      ) {
        for (let obj of this.excelFile.TaskType) {
          const TaskTypeData: any = {
            Group: '',
            Plant: '',
            Name: '',
            MaintenanceType: '',
            CreatedAt: '',
            CreatedBy: '',
          };
          if (!obj['Group'] || !obj['Plant'] || !obj['Task Type Name'] || !obj['Maintenance Type']) {
            invalidTaskTypeRow = true;
          } 
          else {
            let groupobj: any;
            let plantobj: any;
            let uniqueData: any;
            let existXLData: any;

            groupobj = this.plantGroupSource.data.find((grp: any) => {
              const a = obj['Group'].trim().toLowerCase();
              const b = grp.text.trim().toLowerCase();
              return a == b;
            });

            if (groupobj) {
              TaskTypeData.Group = groupobj.PrimaryId;
              plantobj = groupobj.children.find((pnt: any) => {
                const a = obj['Plant'].trim().toLowerCase();
                const b = pnt.text?.trim().toLowerCase();
                return a == b;
              });
              if (plantobj) {
                TaskTypeData.Plant = plantobj.PrimaryId;
                uniqueData = this.AllTaskType.find((tt: any) => {
                  const a = obj['Task Type Name'].replace(/\s/g, '').toLowerCase();
                  const b = tt?.Name?.replace(/\s/g, '').toLowerCase();
                  const c = obj['Maintenance Type'].replace(/\s/g, '').toLowerCase();
                  const d = tt?.MaintenanceType?.replace(/\s/g, '').toLowerCase();
                  return (
                    tt.Group &&
                    tt.Group.egId == TaskTypeData.Group &&
                    tt.Plant &&
                    tt.Plant.ptId == TaskTypeData.Plant &&
                    a == b &&
                    c == d
                  );
                });
                if (!uniqueData) {
                  TaskTypeData.Name = obj['Task Type Name'];
                  TaskTypeData.MaintenanceType = obj['Maintenance Type'];
                }
                else{
                  invalidTaskTypeRow = true;
                }
              } else {
                invalidTaskTypeRow = true;
              }
            } else {
              invalidTaskTypeRow = true;
            }

            if (TTRow != 1) {
              existXLData = this.TaskTypeMasterData.find((tt: any) => {
                return (
                  tt.Name == TaskTypeData.Name &&
                  tt.Group == TaskTypeData.Group &&
                  tt.Plant == TaskTypeData.Plant &&
                  tt.MaintenanceType == TaskTypeData.MaintenanceType
                );
              });
            }
            if (uniqueData || existXLData)
              invalidTaskTypeRow = true;
          }
          if(invalidTaskTypeRow){
            const ExportData = {
              ['RowNo']: TTRow,
              ['Group']: obj['Group'],
              ['Plant']: obj['Plant'],
              ['Task Type Name']: obj['Task Type Name'],
              ['Maintenance Type']: obj['Maintenance Type'],
            };
            this.InavlidExportTaskType.push(ExportData);
          }
          else{
            TaskTypeData.CreatedAt = new Date();
            TaskTypeData.CreatedBy = this.user.UserId;
            this.TaskTypeMasterData.push(TaskTypeData);
          }
          TTRow++;
          invalidTaskTypeRow = false;
        }
      }

      if (this.excelFile && this.excelFile.Task && this.excelFile.Task.length) {
        for (let obj of this.excelFile.Task) {
          const Taskdata: any = {
            Task_ID: 0,
            Group: '',
            Plant: '',
            Description: '',
            Technician: '',
            TaskType: '',
            Frequency: '',
            WOType: '',
            Duration: '',
            SpareParts: '',
            line: null,
            units: null,
            assembly: null,
            subAssembly: null,
            component: null,
            CreatedAt: '',
            CreatedBy: '',
            NextScheduleAt: '',
            MaintenanceType: '',
            FromTaskDate: '',
          };
          if (!obj['Group'] || !obj['Plant'] || !obj['Description'] || !obj['Technician'] || !obj['Task Type']
              || !obj['Frequency'] || !obj['Activity Type'] || !obj['Duration / hrs'] || (typeof obj['Duration / hrs'] != 'number')
              || !obj['Spare Parts'] || !obj['Line*'] || !obj['Unit*'] || !obj['From Task Date (*YYYY-MM-DD)']
              || !obj['To Task Date (*YYYY-MM-DD)']) {
            invalidTaskRow = true;
          } 
          else {
            let groupobj: any;
            let plantobj: any;
            let technician: any;
            let frequency: any;
            let tasktype: any;
            let activitytype: any;

            let line: any;
            let unit: any;
            let assembly: any;
            let subassembly: any;
            let component: any;
            groupobj = this.plantGroupSource.data.find((grp: any) => {
              const a = obj['Group'].trim().toLowerCase();
              const b = grp.text.trim().toLowerCase();
              return a == b;
            });
            if (groupobj) {
              Taskdata.Group = groupobj.PrimaryId;
              plantobj = groupobj.children.find((plt: any) => {
                const a = obj['Plant'].trim().toLowerCase();
                const b = plt.text?.trim().toLowerCase();
                return a == b;
              });
              if (plantobj) {
                Taskdata.Plant = plantobj.PrimaryId;
                technician = this.userList.find((user: any) => {
                  const isInt = Number.isInteger(obj['Technician']);
                  const a = isInt
                    ? obj['Technician']
                    : obj['Technician'].replace(/\s/g, '').toLowerCase();
                  const b =
                    user.FirstName.replace(/\s/g, '').toLowerCase() +
                    user.lastName.replace(/\s/g, '').toLowerCase();
                  return a == b;
                });
                if (technician) {
                  Taskdata.Technician = technician.UserId;
                  tasktype = this.AllTaskType.find((tt: any) => {
                    const a = obj['Task Type'].replace(/\s/g, '').toLowerCase();
                    const b = tt.Name.replace(/\s/g, '').toLowerCase();
                    return a == b && tt.Plant.ptId == Taskdata.Plant;
                  });
                  if (tasktype) {
                    Taskdata.TaskType = tasktype.ID;
                    Taskdata.MaintenanceType = tasktype.MaintenanceType;
                    frequency = this.AllFrequency.find((freq: any) => {
                      const a = obj['Frequency'].trim().toLowerCase();
                      const b = freq.Name.trim().toLowerCase();
                      return a == b && freq.Plant.ptId == Taskdata.Plant;
                    });
                    if (frequency) {
                      this.FreqDuration = frequency.Duration;
                      Taskdata.Frequency = frequency.ID;
                      activitytype = this.AllWOType.find((tsty: any) => {
                        const a = obj['Activity Type'].trim().toLowerCase();
                        const b = tsty.Name.trim().toLowerCase();
                        return a == b && tsty.Plant.ptId == Taskdata.Plant;
                      });
                      if (activitytype) {
                        Taskdata.WOType = activitytype.ID;
                        Taskdata.Description = obj['Description'];
                        Taskdata.Duration = obj['Duration / hrs'];
                        Taskdata.SpareParts = obj['Spare Parts'];
                        line = this.LineAllList.find((line: any) => {
                          const a = obj['Line*'].trim().toLowerCase();
                          const b = line.PL_Desc.trim().toLowerCase();
                          return a == b && line.PT_Id == Taskdata.Plant;
                        });
                        if (line) {
                          Taskdata.line = line.PL_Id;
                          unit = this.UnitAllList.find((unit: any) => {
                            const a = obj['Unit*'].trim().toLowerCase();
                            const b = unit.PU_Desc.trim().toLowerCase();
                            return (
                              a == b &&
                              unit.PT_Id == Taskdata.Plant &&
                              unit.PL_Id == Taskdata.line
                            );
                          });
                          if (unit) {
                            Taskdata.units = unit.PU_Id;
                            if (obj['Assembly']) {
                              assembly = this.AssemblyAllList.find(
                                (ass: any) => {
                                  const a = obj['Assembly']
                                    .trim()
                                    .toLowerCase();

                                  const b =
                                    ass.Assbly_Desc.trim().toLowerCase();
                                  return (
                                    a == b &&
                                    ass.PT_Id == Taskdata.Plant &&
                                    ass.PU_Id == Taskdata.units
                                  );
                                }
                              );
                              if (assembly) {
                                Taskdata.assembly = assembly.Assbly_Id;
                                if (obj['SubAssembly']) {
                                  subassembly = this.SubAssemblyAllList.find(
                                    (subass: any) => {
                                      const a = obj['SubAssembly']
                                        .trim()
                                        .toLowerCase();

                                      const b =
                                        subass.SAsbly_Desc.trim().toLowerCase();

                                      return (
                                        a == b &&
                                        subass.PT_Id == Taskdata.Plant &&
                                        subass.Assbly_Id == Taskdata.assembly
                                      );
                                    }
                                  );
                                  if (subassembly) {
                                    Taskdata.subAssembly =
                                      subassembly.SAssbly_Id;
                                    if (obj['Component']) {
                                      component = this.ComponentAllList.find(
                                        (comp: any) => {
                                          const a = obj['Component']
                                            .trim()
                                            .toLowerCase();
                                          const b =
                                            comp.ComponentName.toLowerCase().replace(/\n/g, " ").trim();
                                          return (
                                            a == b &&
                                            comp.PT_Id == Taskdata.Plant &&
                                            comp.SAssbly_Id ==
                                              Taskdata.subAssembly
                                          );
                                        }
                                      );
                                      if (component) {
                                        Taskdata.component =
                                          component.ComponentId;
                                      } 
                                      else {
                                        Taskdata.component = null;
                                      }
                                    }
                                  } 
                                  else {
                                    Taskdata.subAssembly = null;
                                  }
                                }
                              } 
                              else {
                                Taskdata.assembly = null;
                              }
                            }
                          } else {
                            invalidTaskRow = true;
                          }
                        } else {
                          invalidTaskRow = true;
                        }
                      } else {
                        invalidTaskRow = true;
                      }
                    } else {
                      invalidTaskRow = true;
                    }
                  } else {
                    invalidTaskRow = true;
                  }
                } else {
                  invalidTaskRow = true;
                }
              } else {
                invalidTaskRow = true;
              }
            } else {
              invalidTaskRow = true;
            }
            if(obj.hasOwnProperty('Task_ID') && obj['Task_ID']){
              let chkTask = this.TaskList.find((task: any) => {
                return  task.line.plId == Taskdata.line && task.units.puId == Taskdata.units
                        && task?.assembly?.assblyId == Taskdata.assembly && task?.subAssembly?.sAssblyId == Taskdata.subAssembly
                        && task?.component?.componentId == Taskdata.component && task.ID !== obj['Task_ID']
                        && task.Technician == obj['Technician'] && task.TaskType == obj['Task Type'] && task.Frequency == obj['Frequency']
                        && task.WOType == obj['Activity Type']
                        && task.Description.replace(/\s/g, '').toLowerCase() == Taskdata.Description.replace(/\s/g, '').toLowerCase();
              });
              if (!chkTask && !isNaN(obj['Task_ID'])){
                Taskdata.Task_ID = obj['Task_ID'];
                chkTask = this.TaskList.find((task: any) => {
                  return task.ID == obj['Task_ID'];
                });
                const frequency = this.AllFrequency.find((freq: any) => {
                  const a = chkTask.Frequency.trim().toLowerCase();
                  const b = freq.Name.trim().toLowerCase();
                  return a == b && freq.Plant.ptId == chkTask.Plant.id;
                });
                if(chkTask){
                  if(frequency.ID != Taskdata.Frequency && chkTask.IsDelete == 0){
                    invalidTaskRow = true;
                  }
                  else if(frequency.ID != Taskdata.Frequency && chkTask.IsDelete == 1){
                    Taskdata.IsFreqUpdate = true;
                    if (obj['From Task Date (*YYYY-MM-DD)']) {
                      const dateValue = new Date(obj['From Task Date (*YYYY-MM-DD)']);
                      if (typeof dateValue === 'object' && dateValue instanceof Date && 
                        !isNaN(dateValue.getTime()) && dateValue.toString() !== 'Invalid Date') {
                          const currentDate = new Date(new Date().setHours(0,0,0,0));
                          const selectedDate = new Date(dateValue.setHours(0,0,0,0));
                          if (currentDate.getTime() > selectedDate.getTime()){
                            Taskdata.FromTaskDate = new Date();
                            Taskdata.NextScheduleAt = new Date().setDate((new Date().getDate() + this.FreqDuration));
                        } else {
                          Taskdata.NextScheduleAt = selectedDate.setDate(selectedDate.getDate() + this.FreqDuration);
                          Taskdata.FromTaskDate = new Date(obj['From Task Date (*YYYY-MM-DD)']);
                          if (obj['To Task Date (*YYYY-MM-DD)']) {
                            const dateValue = new Date(obj['To Task Date (*YYYY-MM-DD)']);
                            if (typeof dateValue === 'object' && dateValue instanceof Date && 
                              !isNaN(dateValue.getTime()) && dateValue.toString() !== 'Invalid Date') {
                                const currentDate = new Date(new Date().setHours(0,0,0,0));
                                const selectedDate = new Date(dateValue.setHours(0,0,0,0));
                                if (currentDate.getTime() > selectedDate.getTime() 
                                    || selectedDate.getTime() < new Date(new Date(Taskdata.FromTaskDate).setHours(0,0,0,0)).getTime()){
                                invalidTaskRow = true;
                              } else {
                                Taskdata.ToTaskDate = new Date(obj['To Task Date (*YYYY-MM-DD)']);
                              }                   
                            } else {
                              invalidTaskRow = true;
                            }
                          }  
                        }                   
                      } else {
                        invalidTaskRow = true;
                      }
                    } 
                  }
                  else if(frequency.ID == Taskdata.Frequency){
                    Taskdata.FromTaskDate = new Date(chkTask.FromTaskDate);
                    Taskdata.NextScheduleAt = new Date(chkTask.NextScheduleAt);
                    Taskdata.IsFreqUpdate = false;
                    const dateValue = new Date(obj['To Task Date (*YYYY-MM-DD)']);
                    if (typeof dateValue === 'object' && dateValue instanceof Date && 
                      !isNaN(dateValue.getTime()) && dateValue.toString() !== 'Invalid Date') {
                        let currentDate = new Date(chkTask.ToTaskDate);
                        currentDate = new Date(currentDate.setHours(0,0,0,0));
                        const selectedDate = new Date(dateValue.setHours(0,0,0,0));
                        if(currentDate.getTime() <= selectedDate.getTime()){
                          Taskdata.ToTaskDate = new Date(obj['To Task Date (*YYYY-MM-DD)']);
                        }
                        else{
                          invalidTaskRow = true;
                        }
                    } else {
                      invalidTaskRow = true;
                    }
                  }
                }
                else{
                  invalidTaskRow = true;
                }
              }
              else{
                invalidTaskRow = true;
              }
            }
            else{
              const chkTask1 = this.TaskList.find((task: any) => {
                return  task.line.plId == Taskdata.line && task.units.puId == Taskdata.units
                        && task.assembly.assblyId == Taskdata.assembly && task.subAssembly.sAssblyId == Taskdata.subAssembly
                        && task.component.componentId == Taskdata.component
                        && task.Description.replace(/\s/g, '').toLowerCase() == Taskdata.Description.replace(/\s/g, '').toLowerCase();
              });
              if (!chkTask1) {
                Taskdata.IsFreqUpdate = false;
                if (obj['From Task Date (*YYYY-MM-DD)']) {
                  const dateValue = new Date(obj['From Task Date (*YYYY-MM-DD)']);
                  if (typeof dateValue === 'object' && dateValue instanceof Date && 
                    !isNaN(dateValue.getTime()) && dateValue.toString() !== 'Invalid Date') {
                      const currentDate = new Date(new Date().setHours(0,0,0,0));
                      const selectedDate = new Date(dateValue.setHours(0,0,0,0));
                      if (currentDate.getTime() > selectedDate.getTime()){
                      invalidTaskRow = true;
                    } else {
                      let NextDate = new Date(obj['From Task Date (*YYYY-MM-DD)']);
                      Taskdata.FromTaskDate = new Date(NextDate);
                      NextDate.setDate(NextDate.getDate() + this.FreqDuration);
                      Taskdata.NextScheduleAt = NextDate;
                      if (obj['To Task Date (*YYYY-MM-DD)']) {
                        const dateValue = new Date(obj['To Task Date (*YYYY-MM-DD)']);
                        if (typeof dateValue === 'object' && dateValue instanceof Date && 
                          !isNaN(dateValue.getTime()) && dateValue.toString() !== 'Invalid Date') {
                            const currentDate = new Date(new Date().setHours(0,0,0,0));
                            const selectedDate = new Date(dateValue.setHours(0,0,0,0));
                            if (currentDate.getTime() > selectedDate.getTime() 
                                || selectedDate.getTime() < new Date(new Date(Taskdata.FromTaskDate).setHours(0,0,0,0)).getTime()){
                            invalidTaskRow = true;
                          } else {
                            Taskdata.ToTaskDate = new Date(obj['To Task Date (*YYYY-MM-DD)']);
                          }                   
                        } else {
                          invalidTaskRow = true;
                        }
                      }
                    }                   
                  } else {
                    invalidTaskRow = true;
                  }
                } 
              }
              else{
                invalidTaskRow = true;
              }
            }
            if(TaskRow != 1){
              const existWxcelData = this.TaskMasterData.find((task: any) => {
                return task.line == Taskdata.line && task.units == Taskdata.units && task.assembly == Taskdata.assembly && 
                  task.subAssembly == Taskdata.subAssembly && task.component == Taskdata.component && 
                  task.Description == Taskdata.Description && task.Technician == Taskdata.Technician &&
                  task.WOType == Taskdata.WOType && task.Frequency == Taskdata.Frequency && task.TaskType == Taskdata.TaskType
              })
              if(existWxcelData)
                invalidTaskRow = true;
            }
          }
          if(invalidTaskRow){
            let ExportData;
            if(obj.hasOwnProperty('Task_ID')){
              ExportData = {
                ['RowNo']: TaskRow,
                ['Task_ID']: obj['Task_ID'],
                ['Group']: obj['Group'],
                ['Plant']: obj['Plant'],
                ['Line*']: obj['Line*'],
                ['Unit*']: obj['Unit*'],
                ['Assembly']: obj['Assembly'],
                ['SubAssembly']: obj['SubAssembly'],
                ['Component']: obj['Component'],
                ['Description']: obj['Description'],
                ['Technician']: obj['Technician'],
                ['Task Type']: obj['Task Type'],
                ['Frequency']: obj['Frequency'],
                ['Activity Type']: obj['Activity Type'],
                ['Duration / hrs']: obj['Duration / hrs'],
                ['Spare Parts']: obj['Spare Parts'],
                ['From Task Date (*YYYY-MM-DD)']: obj['From Task Date (*YYYY-MM-DD)'],
                ['To Task Date (*YYYY-MM-DD)']: obj['To Task Date (*YYYY-MM-DD)'],
              };
            }
            else{
              ExportData = {
                ['RowNo']: TaskRow,
                ['Group']: obj['Group'],
                ['Plant']: obj['Plant'],
                ['Line*']: obj['Line*'],
                ['Unit*']: obj['Unit*'],
                ['Assembly']: obj['Assembly'],
                ['SubAssembly']: obj['SubAssembly'],
                ['Component']: obj['Component'],
                ['Description']: obj['Description'],
                ['Technician']: obj['Technician'],
                ['Task Type']: obj['Task Type'],
                ['Frequency']: obj['Frequency'],
                ['Activity Type']: obj['Activity Type'],
                ['Duration / hrs']: obj['Duration / hrs'],
                ['Spare Parts']: obj['Spare Parts'],
                ['From Task Date (*YYYY-MM-DD)']: obj['From Task Date (*YYYY-MM-DD)'],
                ['To Task Date (*YYYY-MM-DD)']: obj['To Task Date (*YYYY-MM-DD)'],
              };
            }
            this.InavlidExportTask.push(ExportData);
          }
          else{
            Taskdata.CreatedAt = new Date();
            Taskdata.CreatedBy = this.user.UserId;
            Taskdata.FromTaskDate = new Date(Taskdata.FromTaskDate.setHours(new Date().getHours()));
            Taskdata.ToTaskDate = new Date(Taskdata.ToTaskDate.setHours(new Date().getHours()));
            Taskdata.NextScheduleAt = new Date(Taskdata.NextScheduleAt);
            this.TaskMasterData.push(Taskdata);
          }
          TaskRow++;
          invalidTaskRow = false;
        }
      }

      if (
        this.excelFile &&
        this.excelFile.Technician &&
        this.excelFile.Technician.length
      ) {
        for (let obj of this.excelFile.Technician) {
          let existXLTech: any;
          const TechnicianData: any = {
            Data: {
              ID: 0,
              RMPM: 'PM',
              Name: [],
              CreatedAt: '',
              CreatedBy: '',
            },
            HeaderData: { Group: '', Plant: '' },
          };
          if (!obj['Group'] || !obj['Plant'] || !obj['Technician']) {
            invalidTchnicianRow = true;
          }
          else {
            let groupobj: any;
            let plantobj: any;

            groupobj = this.plantGroupSource.data.find((grp: any) => {
              const a = obj['Group'].trim().toLowerCase();
              const b = grp.text.trim().toLowerCase();
              return a == b;
            });

            if (groupobj) {
              TechnicianData.HeaderData.Group = groupobj.PrimaryId;
              plantobj = groupobj.children.find((pnt: any) => {
                const a = obj['Plant'].trim().toLowerCase();
                const b = pnt.text?.trim().toLowerCase();
                return a == b;
              });
              if (plantobj) {
                TechnicianData.HeaderData.Plant = plantobj.PrimaryId;
                let userobj = this.orgUserList.find((user: any) => {
                  const isInt = Number.isInteger(obj['Technician']);
                  const a = isInt
                    ? obj['Technician']
                    : obj['Technician'].replace(/\s/g, '').toLowerCase();
                  const b =
                    user.FirstName.replace(/\s/g, '').toLowerCase() +
                    user.lastName.replace(/\s/g, '').toLowerCase();
                  return a == b;
                });
                if (userobj) {
                  let techobj = this.TechnicianList.find((tech: any) => {
                    const a = tech.UserId;
                    const b = userobj.UserId;
                    return a == b;
                  });
                  if (!techobj) {
                    if (TechRow != 1) {
                      let existXLData = this.TechnicianMasterData.find(
                        (tech: any) => {
                          return (
                            tech.HeaderData.Plant ==
                            TechnicianData.HeaderData.Plant
                          );
                        }
                      );
                      if (existXLData) {
                        existXLTech = existXLData.Data.Name.find(
                          (techID: any) => {
                            return techID == userobj.UserId;
                          }
                        );
                        if(!existXLData)
                          TechnicianData.Data.Name.push(userobj.UserId);
                        else
                          invalidTchnicianRow = true;
                      }
                      else{
                        invalidTchnicianRow = true;
                      }
                    } else {
                      TechnicianData.Data.Name.push(userobj.UserId);
                    }
                  } else {
                    invalidTchnicianRow = true;
                  }
                } else {
                  invalidTchnicianRow = true;
                }
              } else {
                invalidTchnicianRow = true;
              }
            } else {
              invalidTchnicianRow = true;
            }
          }
          if (!invalidTchnicianRow)
          {
            TechnicianData.Data.CreatedAt = new Date();
            TechnicianData.Data.CreatedBy = this.user.UserId;
            this.TechnicianMasterData.push(TechnicianData);
          }
          else{
            const ExportData = {
              ['RowNo']: TechRow,
              ['Group']: obj['Group'],
              ['Plant']: obj['Plant'],
              ['Technician']: obj['Technician'],
            };
            this.InavlidExportTechnician.push(ExportData);
          }
          TechRow++;
          invalidTchnicianRow = false;
        }
      }
      if(this.TechnologyMasterData && this.TechnologyMasterData.length ||
        this.TaskTypeMasterData && this.TaskTypeMasterData.length ||
        this.FrequencyMasterData && this.FrequencyMasterData.length ||
        this.TaskMasterData && this.TaskMasterData.length ||
        this.TechnicianMasterData && this.TechnicianMasterData.length){
          this.MasterPMDataOnSubmit();
      }
      if(this.InavlidExportWOType && this.InavlidExportWOType.length ||
        this.InavlidExportFrequency && this.InavlidExportFrequency.length ||
        this.InavlidExportTaskType && this.InavlidExportTaskType.length ||
        this.InavlidExportTask && this.InavlidExportTask.length ||
        this.InavlidExportTechnician && this.InavlidExportTechnician.length){
          this.ExportTOExcel('PM_InvalidData_Sheet');   
          this.toaster.error(
            `Excel Sheet has been Downloaded with Invalid Data`,
            'Error',
            {
              timeOut: 3000,
            }
          );
      }
    } catch (err: any) {
      this.toaster.error(err, 'Error', {
        timeOut: 3000,
      });
      this.excelFile = [];
      this.TechnologyMasterData = [];
      this.TaskTypeMasterData = [];
      this.ToolMasterData = [];
      this.FrequencyMasterData = [];
      this.TaskMasterData = [];
      this.TechnicianMasterData = [];
    }
  }

  MasterPMDataOnSubmit() {
    this.spinnerText = 'Creating PM Master Data';
    this.spinner.show();
    if (this.TechnologyMasterData && this.TechnologyMasterData.length)
      this.TechnologyMasterData.map((row: any) => (row.RMPM = 'PM'));
    let data = {
      TechnologyMasterData: this.TechnologyMasterData,
      TaskTypeMasterData: this.TaskTypeMasterData,
      ToolMasterData: this.ToolMasterData,
      FrequencyMasterData: this.FrequencyMasterData,
      TaskMasterData: this.TaskMasterData,
      TechnicianMasterData: this.TechnicianMasterData,
    };
    this.PrevConfigurationService.PMMasterUpload(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.toaster.success(res.message, 'Success');
          if (res.taskRows && res.taskRows > 0)
            this.toaster.success(res.message, 'Success');

          this.TechnologyMasterData = [];
          this.TaskTypeMasterData = [];
          this.ToolMasterData = [];
          this.FrequencyMasterData = [];
          this.TaskMasterData = [];
          this.TechnicianMasterData = [];
          this.isInit=true;
          this.getFrequency();
          this.getTaskType();
          this.getTask();
          this.getAllTechnician();
          this.getWOType();
        }
        else{
          this.TechnologyMasterData = [];
          this.TaskTypeMasterData = [];
          this.ToolMasterData = [];
          this.FrequencyMasterData = [];
          this.TaskMasterData = [];
          this.TechnicianMasterData = [];
          this.isInit=true;
          this.toaster.error('Error',res.message);
        }
      },
      (error: any) => {
        this.excelFile = [];
        this.TechnologyMasterData = [];
        this.TaskTypeMasterData = [];
        this.ToolMasterData = [];
        this.FrequencyMasterData = [];
        this.TaskMasterData = [];
        this.TechnicianMasterData = [];
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }
}
