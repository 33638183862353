import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PeopleMasterDataService } from 'src/app/services/people-master-data-service';
import { CountryCode, CountryCodes } from 'src/app/services/country-code';

@Component({
  selector: 'app-employee-master',
  templateUrl: './employee-master.component.html',
  styleUrls: ['./employee-master.component.scss']
})
export class EmployeeMasterComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  countryCodes: Array<CountryCode> = CountryCodes;
  minDate = new Date();
  maxDate = new Date();
  filteredCountryList!: any;
  employeeForm!: FormGroup;
  formMode!: boolean;
  groupId!: number;
  plantId!: number;
  displayEmployeeModal = false;
  isUpdateEmpMaster: boolean = false;
  isnonAppUser: boolean = false;
  isappUser: boolean = false;
  isNonToapp : boolean = true;
  employeeMasterColumns: string[] = ['EmployeeNo', 'Name', 'Email', 'Grade', 'DOB', 'DOJ', 'Actions'];

  employeeData = new MatTableDataSource<any>([]);
  userList: any[] = new Array<any>();
  employeeList !: any[];
  filteredUserList!: any;
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private peopleService: PeopleMasterDataService,
  ) { }

  ngOnInit(): void {
    this.formMode = true;
    this.employeeForm = this.fb.group({
      user: new FormControl(),
      EmployeeNo: new FormControl('', [Validators.required]),
      EmpInfo_Id: new FormControl(),
      FirstName: new FormControl('', [Validators.required]),
      plant: new FormControl(),
      group: new FormControl(),
      lastName: new FormControl('', [Validators.required]),
      Email: new FormControl('',[Validators.email]),
      Grade: new FormControl(),
      DOB: new FormControl(),
      DOJ: new FormControl(),
      Nationality: new FormControl('', [Validators.required]),
      isAppUser: new FormControl(''),
    });

    this.filteredCountryList = this.countryCodes.slice();
    const dt = new Date();
    this.maxDate.setDate(dt.getDate() - 5110);
    this.minDate.setDate(dt.getDate() - 36500);
  }
  getPaginator(): void {
    setTimeout(() => {
      this.employeeData.paginator = this.paginator;
      this.employeeData.sort = this.sort;
    }, 1000);
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.employeeForm.controls[controlName].hasError(errorName);
  }

  getEmployeeMasterData(key: any): void {
    debugger
    this.spinner.show();
    this.groupId = key.EGId;
    this.plantId = key.PlantId;
    this.employeeForm.get('group')?.setValue(key.groupId);
    this.employeeForm.get('plant')?.setValue(key.plantId);
    this.peopleService.getEmployeeMasterData(key).subscribe((data) => {
      this.employeeData = data.employeeInfoList;
      this.employeeList = data.employeeInfoList;
      this.employeeData = new MatTableDataSource<any>(data.employeeInfoList);
      this.getPaginator();
      this.getUserList(this.groupId);
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });

  }

  onCheckedClick(event: any, value: string): void {
    if (value === 'appUser' && event.checked === true) {
      this.employeeForm.get('user')?.reset();
      this.isnonAppUser = false;
      this.isappUser = true;
      this.getUserList(this.groupId);
    } else {
      this.isappUser = false;
      this.isnonAppUser = true;
      this.employeeForm.get('FirstName')?.reset();
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.employeeData.filter = filterValue.trim().toLowerCase();

    if (this.employeeData.paginator) {
      this.employeeData.paginator.firstPage();
    }
  }

  getUserList(id: number): void {
    const key = {
      GroupId: id
    }
    if (!this.isUpdateEmpMaster) {
      this.peopleService.getUserList(key).subscribe((data) => {
        this.userList = data.UserList;
        this.filteredUserList = this.userList.slice();
      },
        (error) => {
          this.toaster.error(error.error.message);
          this.spinner.hide();
        });
    } else if(this.isUpdateEmpMaster && ! this.isNonToapp) {
      this.peopleService.getUserList(key).subscribe((data) => {
        this.userList = data.UserList;
        this.filteredUserList = this.userList.slice();
      },
        (error) => {
          this.toaster.error(error.error.message);
          this.spinner.hide();
        });
    }else{
      this.peopleService.getUserListForUpdate(key).subscribe((data) => {
        this.userList = data.UserList;
        this.filteredUserList = this.userList.slice();
      },
        (error) => {
          this.toaster.error(error.error.message);
          this.spinner.hide();
        });
    }

  }

  opensideModal(): void {
    if (!this.isUpdateEmpMaster) {
      this.formMode = true;
      this.displayEmployeeModal = true;
      this.isappUser = false;
      this.isnonAppUser = true;
      this.reset();
      this.isUpdateEmpMaster = false;
    }
    else {
      this.formMode = false;
      this.displayEmployeeModal = true;
    }
  }

  closeEmployeeModel(): void {
    this.displayEmployeeModal = false;
    this.reset();
  }


  setUserInfo(userInfo:any):void{
    this.userList.forEach(user =>{
      if(user.UserId === userInfo.value){
        this.employeeForm.get('FirstName')?.patchValue(user.Name);
        this.employeeForm.get('Email')?.patchValue(user.Email);
        return user;
      }
    });
  
  }

  saveEmployee(): void {
    const dob = this.employeeForm.get('DOB')?.value;
    const doj = this.employeeForm.get('DOJ')?.value;
    if (!this.isUpdateEmpMaster && this.employeeForm.valid) {
      const data = {
        TransactionType: 1,
        EmployeeNo: this.employeeForm.get('EmployeeNo')?.value,
        FirstName: this.employeeForm.get('FirstName')?.value,
        lastName: this.employeeForm.get('lastName')?.value,
        Email: this.employeeForm.get('Email')?.value,
        Grade: this.employeeForm.get('Grade')?.value,
        DOB: dob,
        DOJ: doj,
        Nationality: this.employeeForm.get('Nationality')?.value,
        PlantId: this.plantId,
        EGId: this.groupId,
        UserId: this.employeeForm.get('user')?.value
      };
      this.peopleService.addEmployee(data).subscribe((response:any) => {
        this.showPopupMsg(response.ErrorMessage);
        this.reset();
        this.closeEmployeeModel();
        const key = {
          PlantId: data.PlantId,
          EGId: data.EGId
        };
        this.getEmployeeMasterData(key);
      }, (error) => {
        this.toaster.error('Error', error.error.message);
      });
    } else if (this.employeeForm.valid) {
      let userId = 0;
      this.isUpdateEmpMaster === true && this.isnonAppUser ? userId = 0 : userId = this.employeeForm.get('user')?.value
      const data = {
        TransactionType: 2,
        EmpInfo_Id: this.employeeForm.get('EmpInfo_Id')?.value,
        EmployeeNo: this.employeeForm.get('EmployeeNo')?.value,
        FirstName: this.employeeForm.get('FirstName')?.value,
        lastName: this.employeeForm.get('lastName')?.value,
        Email: this.employeeForm.get('Email')?.value,
        Grade: this.employeeForm.get('Grade')?.value,
        DOB: dob,
        DOJ: doj,
        Nationality: this.employeeForm.get('Nationality')?.value,
        PlantId: this.plantId,
        EGId: this.groupId,
        UserId: userId
      };
      this.peopleService.addEmployee(data).subscribe((response :any) => {
        this.showPopupMsg(response.ErrorMessage);
        this.closeEmployeeModel();
        this.isUpdateEmpMaster = false;
        this.reset();
        const key = {
          PlantId: data.PlantId,
          EGId: data.EGId
        };
        this.getEmployeeMasterData(key);
      }, (error) => {
        this.toaster.error('Error', error.error.message);
      })
    }

  }

  onClickUpdateEmployee(element: any): void {
    this.isUpdateEmpMaster = true;
    this.isappUser = false;
    this.isnonAppUser = false;
    this.employeeForm.get('EmpInfo_Id')?.setValue(element.EmpInfo_Id),
      this.employeeForm.get('EmployeeNo')?.setValue(element.EmployeeNo),
      this.employeeForm.get('FirstName')?.setValue(element.FirstName),
      this.employeeForm.get('lastName')?.setValue(element.lastName),
      this.employeeForm.get('user')?.setValue(element.UserId),
      this.employeeForm.get('Email')?.setValue(element.Email),
      this.employeeForm.get('Grade')?.setValue(element.Grade),
      this.employeeForm.get('DOB')?.setValue(element.DOB),
      this.employeeForm.get('DOJ')?.setValue(element.DOJ),
      this.employeeForm.get('Nationality')?.setValue(element.Nationality),
      this.employeeForm.get('plant')?.setValue(this.plantId),
      this.employeeForm.get('group')?.setValue(this.groupId),
      element.UserId === 0 ? this.isnonAppUser = true : this.isappUser = true,
      element.UserId === 0 ? this.isNonToapp = false : this.isNonToapp = true
      this.getUserList(this.groupId);
    this.opensideModal();
  }

  onClickDeleteEmployee(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Master Employee!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        var id = {
          TransactionType: 3,
          EmpInfo_Id: element.EmpInfo_Id,
          DOB: new Date(),
          DOJ: new Date(),
        }
        this.peopleService.addEmployee(id).subscribe(
          (data: any) => {
            const key = {
              PlantId: this.plantId,
              EGId: this.groupId
            };
            this.getEmployeeMasterData(key);
            this.showPopupMsg(data.ErrorMessage);
          },
          (error: any) => {
            this.toaster.error('Error', error.error.message);
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Master Employee is safe :)', 'error');
      }
    });
  }

  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
      this.toaster.success('Success', msg.substring(3, msg.length));
    }
    else {
      this.toaster.error('Error', msg.substring(3, msg.length));
    }
  }

  reset(): void {
    this.employeeForm.reset();
    this.isUpdateEmpMaster = false;
  }
 
}
