import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { OrderProcessingOperation } from 'src/app/models/O3AdministrationModels/orderProcessingOperations';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ToastrService } from 'ngx-toastr';
import { OrderProcessingService } from 'src/app/services/orderprocessing.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { OrderProcessingElement } from 'src/app/models/O3AdministrationModels/orderProcessingElement';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonEditRendererComponent } from 'src/app/components/button-edit-render.components';
import { ButtonDeleteRendererComponent } from 'src/app/components/button-delete-render.components';
import { CellClickedEvent } from 'ag-grid-community';

@Component({
  selector: 'app-order-processing',
  templateUrl: './order-processing.component.html',
  styleUrls: ['./order-processing.component.scss'],
})
export class OrderProcessingComponent implements OnInit {
  //AG Grid Prop
  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;
  frameworkComponents: any;
  isDisable = true;
  gridOptions: any = {
    CodefaultlDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true, // <-- HERE
      autoHeight: true, // <-- & HERE
      minWidth: 225,
    },
    medalColumn: {
      width: 100,
      columnGroupShow: 'open',
      filter: false,
    },
    popupParent: document.querySelector('body'),
    floatingFilter: true,
  };
  constructor(
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private userService: UserManagementService,
    private orderService: OrderProcessingService,
    private dialog: MatDialog,
    private machineService: MachineMappingService,
    private enterpriseGroupService: EnterPriseGroupService,
    public router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonEditRendererComponent,
      buttonRendererDelete: ButtonDeleteRendererComponent,
    };
   
  }

  // form unitDetailForm fields controls
  get unitDetailFormControls(): any {
    return this.unitDetailForm.controls;
  }
  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  GroupList!: any[];
  PlantList!: any[];
  PlantList1!: any[];
  statusList!: any[];
  ProductList!: any[];
  ProductionId!: number;
  ProcessOrder!: number;
  TargetQuantity!: number;
  ItemDesc!: string;
  ItemCode!: string;
  MachineId!: any;
  UomId!: any;
  statusOperation!: any;

  isOrderProcessModelVisible = false;
  updateOrderprocessingData = false;
  Priority!: any;
  startDate!: Date;
  ProductId!: number;
  endDate!: Date;
  ProductionPlanId!: number;
  orderProcessingData!: MatTableDataSource<OrderProcessingElement>;
  orderProcessingUpdateData!: MatTableDataSource<OrderProcessingOperation>;
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  breadcrumList!: any;
  isPopupMachine = false;
  prodDesc!: any;
  // Date-Time Picker Variable
  IDtooltip: any;
  ICtooltip: any;
  ButtonType!: any;

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
  });
  // tslint:disable-next-line: member-ordering
  public dateControlStart = new FormControl(new Date(2021, 9, 4, 5, 6, 0));
  public dateControlEnd = new FormControl(new Date(2021, 10, 4, 5, 6, 0));

  OrderProcessingElementData: OrderProcessingElement[] = [];
  orderProcessingUpdate: OrderProcessingOperation[] = [];
  orderProcessingVariableGroupColumns: string[] = [
    'PO',
    'itemCode',
    'itemDesc',
    'poTarget',
    'plannedStart',
    'plannedEnd',
    'createdBy',
    'createdOn',
    'orderProcessingActions',
  ];
  orderProcessingUpdateGroupColumns: string[] = [
    'OperationNo',
    'OperationDescription',
    'ProductDescription',
    'ProductCode',
    'Machine',
    'ActualMachine',
    'Target',
    'UOM',
    'PlannedStart',
    'PlannedEnd',
    'Status',
    'ActualQuantity',
    'ActualStart',
    'ActualEnd',
    'CreatedBy',
    'CreatedOn',
  ];
  orderProcessingVariableGroupData =
    new MatTableDataSource<OrderProcessingElement>();
  BOMDataSource!: MatTableDataSource<any>;
  orderProcessingBomColumns: string[] = [
    'PPBID',
    'ItemCode',
    'ItemDesc',
    'Estimatedqty',
    'UOM',
    'CreatedBy',
    'CreatedOn',
  ];
  orderForm!: FormGroup;
  plantDataForm!: FormGroup;
  operationForm!: FormGroup;
  isSarchFormVisible = false;

  unitDetailForm!: FormGroup;
  UnitNode: any;
  Group: string | undefined;
  UnitName: string | undefined;
  isUnitName = false;
  selectedRowIndex = -1;
  unitDetailFormFormSubmitted = false;
  // _isDisabled = true;
  operationValidator!: boolean;
  submitProductionForm!: boolean;
  UpdateForm = false;
  FormMode!: boolean;
  isPlantGroupModelVisible = false;
  StatusList!: any[];
  StatusListEdit!: any[];
  ProductionPlanList!: any[];
  UOMList!: any[];
  ProductionPlanDetailId!: number;
  StatusNum!: number;
  UOMNum!: number;
  @ViewChild(MatTable) table: MatTable<OrderProcessingElement> | undefined;
  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  // @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('displayOrderModal') private displayOrderModal!: TemplateRef<any>;

  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    // // this.spinner.show();
    this.datasource();
    this.StatusNum = 1;
    this.UOMNum = 2;
    // tslint:disable-next-line: align
    this.orderForm = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      UnitName: ['', Validators.required],
      Machine: ['', Validators.required],
      StatusName: ['', Validators.required],
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      searchval: [''],
    });

    this.plantDataForm = this.formBuilder.group({
      UserGroup: ['', Validators.required],
      UserPlantPopup: ['', Validators.required],
      PO: ['', Validators.required],
      ItemCode: ['', Validators.required],
      ItemDesc: [''],
      PoQuantity: ['', Validators.required],
      StatusName: [''],
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      Priority: [''],
    });

    this.operationForm = this.formBuilder.group({
      OperationNo: ['', Validators.required],
      OperationDesc: ['', Validators.required],
      Machine: ['', Validators.required],
      Products: ['', Validators.required],
      UOM: ['', Validators.required],
      StatusName: ['', Validators.required],
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      Target: ['', Validators.required],
      Remarks: [''],
      PuId: [''],
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.plantDataForm.controls[controlName].hasError(errorName);
  };

  public checkErrorOperation = (controlName: string, errorName: string) => {
    return this.operationForm.controls[controlName].hasError(errorName);
  };

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): any {
    // this.orderProcessingVariableGroupData.paginator = this.paginator;
    // this.orderProcessingVariableGroupData.sort = this.sort;
  }

  datasource(): void {
    const key = {
      Id: 0,
    };
    const id = {
      value: 0,
    };
    // tslint:disable-next-line:
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.masterList;
        id.value = data.masterList[0].TypeId;
        this.orderForm.get('UserGroup1')?.setValue(data.masterList[0].TypeId);
        this.orderForm.get('UserPlant1')?.setValue(data.masterList[0].TypeId);
        this.getPlants(id);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  // cellClicked(params: any): void {
  //   debugger
  //   const col = params.colDef.field;
  //   this.ButtonType = '';
  //   if (col === 'Actions') {
  //     this.ButtonType = params.event.target.classList[1];
  //     if (this.ButtonType === 'fa-pencil') {
  //       this.onClickEdit(params);
  //     }
  //    else if (this.ButtonType === 'fa-trash') {
  //       this.ButtonType = '';
  //       this.deleteOrder(params.data);
  //     }
  //   }
  // }
  onSubmit(): void {
    this.spinner.show();
    const key = {
      PP_Id: 0,
      PO: '',
      ItemCode: '',
      StartTime: this.orderForm.value.dateStart ? this.orderForm.value.dateStart : null,
      EndTime: this.orderForm.value.dateEnd ? this.orderForm.value.dateEnd : null,
      PU_id: this.MachineId,
      UserId: JSON.parse(localStorage.user).UserId,
      GrpId: this.orderForm.value.UserGroup1,
      PlId: this.orderForm.value.UserPlant1,
      PSId: this.orderForm.value.StatusName,
    };

    this.orderService.getProductionPlan(key).subscribe(
      (data: any) => {
        this.rowData = data.ProductionPlanList;
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();
      }
    );
  }

  resetUnit(): void {
    this.orderForm.get('UnitName')?.reset();
    this.MachineId = 0;
  }

  resetMachine(): void {
    this.operationForm.get('Machine')?.reset();
    this.operationForm.get('Products')?.reset();
    this.MachineId = 0;
    this.ProductList = [];
  }
  getPlants(event: any): void {
    const key = {
      GroupId: event.value,
    };
    this.orderForm.get('UnitName')?.reset();
    this.operationForm.get('Machine')?.reset();
    this.MachineId = 0;
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.PlantList1 = data.DepartList;
        this.rowData = data.ProductionPlanList;
        this.orderForm.get('UserPlant1')?.setValue(this.PlantList1[0].DeptId);
        this.getStatus(true);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getPlantpopup(event: any): void {
    const key = {
      GroupId: this.plantDataForm.value.UserGroup,
    };
    this.operationForm.get('Machine')?.reset();
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.PlantList = data.DepartList;
        this.plantDataForm
          .get('UserPlantPopup')
          ?.setValue(this.orderForm.value.UserPlant1);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getStatus(status: boolean): void {
    const key = {};
    this.userService.getStatus(key).subscribe(
      (data: any) => {
        this.StatusList = data.masterList;
        const obj = { TypeId: 0, TypeName: 'All' };
        this.StatusList.push(obj);
        this.orderForm.get('StatusName')?.setValue(data.masterList[0].TypeId);
        this.operationForm.get('StatusName')?.setValue(this.statusOperation);
        if(status)
        this.onSubmit();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  // search form subit
  onSearch(): any {
    const searchValue = this.orderForm.value;
  }

  highlight(row: any): void {
    this.ProductionId = row.PP_Id;
    this.TargetQuantity = row.Target;
    this.ItemCode = row.ItemCode;
    this.Priority = row.Priority;
    this.ItemDesc = row.ItemDesc;
    this.ProcessOrder = row.PO;
    this.startDate = row.StartDate;
    this.endDate = row.EndDate;
  }

  deleteOrder(data: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Production Order!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((Result: any) => {
      if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Production Order is safe :)', 'error');
      } else if (Result.isConfirmed) {
        const key = {
          TransactionTypeid: 3,
          PP_Id: data.data.PP_Id,
          ProcessOrder: data.data.PO,
          TargetQuantity: data.data.Target,
          PlannedStartDate: data.data.StartDate,
          PlannedEndDate: data.data.EndDate,
          Group: data.data.EG_Id,
          Plant: data.data.PL_Id,
          Priority: data.data.Priority,
          ItemDesc: data.data.ItemDesc,
        };

        this.orderService.submitProductionPlan(key).subscribe(
          (data: any) => {
            // this.spinner.hide();
            this.showPopupMsg(data.ErrorMessage);
            this.onSubmit();
          },
          (error: any) => {
            // this.spinner.hide();
            this.showPopupMsg(error.ErrorMessage);
          }
        );
      }
    });
  }

  // open tree structure for unit
  openUnit(): any {
    this.isPlantGroupModelVisible = true;
  }

  SelectUnitName(unitname: any): void {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
    }
  }

  openModal(): void {
    this.updateOrderprocessingData = true;
    this.isPopupMachine = false;

    const key = {
      EGId: this.orderForm.value.UserGroup1,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  openMachineModal(): void {
    this.updateOrderprocessingData = true;
    this.isPopupMachine = true;
    const key = {
      EGId: this.plantDataForm.value.UserGroup,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.getStatus(false); // new
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // close model
  closePlantGroupModel(): void {
    this.updateOrderprocessingData = false;
  }
  getStatusData(): void {
    const key = {};
    this.userService.getStatus(key).subscribe(
      (data: any) => {
        this.statusList = [];
        this.StatusListEdit = data.masterList;

        // const obj = {TypeId: 0, TypeName: "All"}
        // this.StatusList.push(obj)
        this.operationForm
          .get('StatusName')
          ?.setValue(data.masterList[0].TypeId);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  addOrderPopup(): void {
    this.operationForm.reset();
    this.plantDataForm.reset();
    this.UpdateForm = false;
    this.FormMode = false;
    this.ProductionPlanId = 0;
    this.ICtooltip = '';
    this.IDtooltip = '';
    this.isOrderProcessModelVisible = false;
    this.orderProcessingUpdateData =
      new MatTableDataSource<OrderProcessingOperation>();
    this.BOMDataSource = new MatTableDataSource<any>();
    // this.datasource();
    this.dialog.open(this.displayOrderModal, {
      width: '100vw !important',
      height: '90vh',
      minWidth: '100vw',
    });
    // this.plantDataForm.get('UserGroup')?.setValue(this.GroupList[0].TypeId);
    // usama change uncommit uper line
    this.plantDataForm
      .get('UserGroup')
      ?.setValue(this.orderForm.value.UserGroup1);

    this.getPlantpopup(this.GroupList[0].TypeId);
  }

  closeForm(): void {
    this.UpdateForm = false;
    this.submitProductionForm = true;
    // this.datasource();
  }

  onClickEdit(item: any): void {
    this.selectedRowIndex = 0;
    this.updateOrder(item.data);
  }

  updateOrder(data: any): void {
    this.spinner.show();
    this.FormMode = true;
    this.UpdateForm = true;
    this.isOrderProcessModelVisible = false;

    const key = {
      PP_Id: data.PP_Id,
      PPD_Id: 0,
      JobNo: data.PO
    };
    this.orderService.getProductionPlanDetail(key).subscribe(
      (response: any) => {
        this.orderProcessingUpdateData =
          new MatTableDataSource<OrderProcessingOperation>(
            response.ProductionPlanDetailList
          );
        this.BOMDataSource = new MatTableDataSource<OrderProcessingOperation>(
          response.ProductionPlanBOMList
        );

        // this.plantDataForm.get('UserGroup')?.setValue(data.PO);
        // this.plantDataForm.get('UserPlantPopup')?.setValue(data.PO);
        this.plantDataForm.get('PO')?.setValue(data.PO);
        this.plantDataForm.get('ItemCode')?.setValue(data.ItemCode);
        this.ICtooltip = data.ItemCode;
        this.plantDataForm.get('PoQuantity')?.setValue(data.Target);
        this.plantDataForm.get('Priority')?.setValue(data.Priority);
        this.plantDataForm.get('ItemDesc')?.setValue(data.ItemDesc);
        this.IDtooltip = data.ItemDesc;
        // Usama change usergroup from user group1
        this.plantDataForm
          .get('UserGroup')
          ?.setValue(this.orderForm.value.UserGroup1);
        this.plantDataForm
          .get('UserPlantPopup')
          ?.setValue(this.orderForm.value.UserPlant1);
        this.plantDataForm.get('dateEnd')?.setValue(data.EndDate);
        this.plantDataForm.get('dateStart')?.setValue(data.StartDate);
        this.orderForm.get('searchval')?.reset(); // for search reset
        this.ProductionPlanId = data.PP_Id;
        this.getPaginator(this.orderProcessingUpdateData, 0);
        this.getStatusData();
        this.getPlantpopup(data.EG_Id);
        this.dialog.open(this.displayOrderModal, {
          width: '100vw !important',
          height: '90vh',
          minWidth: '100vw',
        });
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  applyFilter(event: any): void {
    // this.spinner.show();
    const filterValue = (event.target as HTMLInputElement).value;
    this.orderProcessingData.filter = filterValue.trim().toLowerCase();
    this.BOMDataSource.filter = filterValue.trim().toLowerCase();

    // if (this.orderProcessingData.paginator) {
    //   this.orderProcessingData.paginator.firstPage();
    // }
  }
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  // Button Change
  GetUOMData(): void {
    const key = {};

    this.orderService.getUOMData(key).subscribe(
      (data: any) => {
        this.UOMList = data.masterList;

        this.operationForm.get('UOM')?.setValue(data.masterList[0].TypeId);
        // usama change to undo uncomment upper line

        this.operationForm.get('UOM')?.setValue(this.UomId);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  UpdateOrderData(): void {
    if (
      this.operationForm.value.OperationNo === '' ||
      this.operationForm.value.OperationNo === null
    ) {
      this.toaster.warning('Please select row first');
    } else {
      this.GetUOMData();
      this.submitProductionForm = false;
      this.isOrderProcessModelVisible = true;
    }
    this.getStatus(false);
  }

  getMaster(): void {}

  GetProductData(): void {
    const key = {
      Id: this.operationForm.value.PuId, // this.MachineId
    };
    // tslint:disable-next-line: align
    this.orderService.getProducts(key).subscribe(
      (data: any) => {
        this.ProductList = data.masterList;
        // this.operationForm.get('Products')?.setValue(data.masterList[0].TypeId);
        if (this.prodDesc !== 'null-null') {
          this.operationForm
            .get('Products')
            ?.setValue(
              this.ProductList.find((x) => x.TypeName === this.prodDesc)?.TypeId
            );
        }
      },
      (error: any) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  onNodeSelect(node: any): void {
    debugger;
    this.updateOrderprocessingData = false;
    // tslint:disable-next-line: align
    // this.MachineId = node.PrimaryId;

    if (this.isPopupMachine === true) {
      this.operationForm.get('Machine')?.setValue(node.text);
      this.operationForm.get('PuId')?.setValue(node.PrimaryId);
      this.GetProductData();
    } else {
      this.MachineId = node.PrimaryId;
      this.orderForm.get('UnitName')?.setValue(node.text);
    }
  }

  AddOrderData(): void {
    this.GetUOMData();
    this.getStatusData();
    this.operationForm.reset();
    this.submitProductionForm = true;
    this.isOrderProcessModelVisible = true;
  }

  CloseOrderData(): void {
    this.isOrderProcessModelVisible = false;
    this.selectedRowIndex = 0;
  }
  updatehighlight(data: any): void {
    this.selectedRowIndex = data.PPD_Id;
    this.isOrderProcessModelVisible = false;
    this.ProductionPlanDetailId = data.PPD_Id;
    this.operationForm.get('OperationNo')?.setValue(data.OperNum);
    this.operationForm.get('OperationDesc')?.setValue(data.Operation);
    this.operationForm.get('Machine')?.setValue(data.Machine);
    this.operationForm.get('UOM')?.setValue(data.UOM_Id);
    this.operationForm.get('StatusName')?.setValue(data.PPS_Id);
    this.operationForm.get('dateEnd')?.setValue(data.EndDate);
    this.operationForm.get('dateStart')?.setValue(data.StartDate);
    this.operationForm.get('Target')?.setValue(data.Target);
    this.operationForm.get('Remarks')?.setValue(data.Remarks);
    this.operationForm.get('PuId')?.setValue(data.PU_Id);
    // this.MachineId = data.PU_Id;
    this.prodDesc = data.Prod_Desc + '-' + data.Prod_Code;

    // usama xhange
    this.UomId = data.UOM_Id;
    this.statusOperation = data.PPS_Id;

    this.GetProductData();
  }
  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }
  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
      data.sort = this.sort.toArray()[index];
      // this.orderProcessingData.paginator = this.paginator;
      // this.orderProcessingData.sort = this.sort;
    }, 1000);
  }

  submitproductionPlan(): any {
    let transactionId = 1;
    if (this.UpdateForm === true) {
      transactionId = 2;
    } else {
      this.ProductionPlanId = 0;
    }

    const key = {
      CreatedBy: 0,
      TransactionTypeid: transactionId,
      ProcessOrder: this.plantDataForm.value.PO,
      ItemCode: this.plantDataForm.value.ItemCode,
      PP_Id: this.ProductionPlanId,
      TargetQuantity: this.plantDataForm.value.PoQuantity,
      PlannedStartDate: new Date(this.plantDataForm.value.dateStart),
      PlannedEndDate: new Date(this.plantDataForm.value.dateEnd),
      Group: this.plantDataForm.value.UserGroup,
      Plant: this.plantDataForm.value.UserPlantPopup,
      Priority: this.plantDataForm.value.Priority,
      ItemDesc: this.plantDataForm.value.ItemDesc,
    };
    // this.spinner.show();
    if (this.plantDataForm.valid) {
      this.orderService.submitProductionPlan(key).subscribe(
        (data: any) => {
          this.showPopupMsg(data.ErrorMessage);
          if (data.Id !== 0 && data.ErrorMessage !== 'C1-PO Already Exists!') {
            const po = {
              PP_Id: data.Id,
            };
            this.getProductionPlanDetails(po);
            this.onSubmit();
            this.ProductionPlanId = data.Id;
            this.UpdateForm = true;
          } else {
            this.plantDataForm.get('PO')?.setValue('');
            this.plantDataForm.get('ItemCode')?.setValue('');
            this.plantDataForm.get('PoQuantity')?.setValue('');
            this.plantDataForm.get('Priority')?.setValue('');
            this.plantDataForm.get('ItemDesc')?.setValue('');
            this.plantDataForm.get('dateEnd')?.setValue('');
            this.plantDataForm.get('dateStart')?.setValue('');
          }

          // if (data.ErrorMessage === "C1-PO Already Exists!" && this.FormMode === false) {
          //   this.UpdateForm = false;
          // }
        },
        (error: any) => {
          this.showPopupMsg(error.ErrorMessage);
          // this.spinner.hide();
        }
      );
    }
  }

  productChange(data: any): void {
    this.ProductId = data;
  }

  applyFilterOne(event: any): void {
    // this.spinner.show();
    const filterValue = (event.target as HTMLInputElement).value;
    this.orderProcessingUpdateData.filter = filterValue.trim().toLowerCase();

    if (this.orderProcessingUpdateData.paginator) {
      this.orderProcessingUpdateData.paginator.firstPage();
    }
  }

  submitOperationPlan(): void {
    if (this.operationForm.valid) {
      const key = {
        Transactiontypeid: 1,
        PPD_Id: 0,
        PP_Id: this.ProductionPlanId,
        Prod_Id: this.operationForm.value.Products,
        PU_Id: this.operationForm.value.PuId, // this.MachineId,
        PPD_Desc: this.operationForm.value.OperationDesc,
        PPD_Num: this.operationForm.value.OperationNo,
        UOM_Id: this.operationForm.value.UOM,
        PPS_Id: this.operationForm.value.StatusName,
        PlannedStartDate:
          this.operationForm.value.dateStart !== ''
            ? new Date(this.operationForm.value.dateStart)
            : this.operationForm.value.dateStart,
        PlannedEndDate:
          this.operationForm.value.dateEnd! == ''
            ? new Date(this.operationForm.value.dateEnd)
            : this.operationForm.value.dateEnd,
        TargetQuantity: this.operationForm.value.Target,
        Remarks: this.operationForm.value.Remarks,
        CreatedBy: 0,
      };
      // this.spinner.show();
      if (this.ProductionPlanId) {
        const id = {
          PP_Id: this.ProductionPlanId,
          PPD_Id: 0,
        };
        // tslint:disable-next-line: align
        if (key.Prod_Id) {
          this.orderService.submitProductionPlanDetail(key).subscribe(
            (data: any) => {
              this.operationOrder(id);
              this.isOrderProcessModelVisible = false;
              this.getPaginator(this.orderProcessingUpdateData, 0);
              this.showPopupMsg(data.ErrorMessage);
              // this.spinner.hide();
            },
            (error: any) => {
              this.showPopupMsg(error.ErrorMessage);
              // this.spinner.hide();
            }
          );
        } else {
          this.toaster.warning('Please add data in all the fields');
          // this.spinner.hide();
        }
      } else {
        this.toaster.warning('Please add Order first');
        // this.spinner.hide();
      }
    }
  }

  operationOrder(data: any): void {
    // this.spinner.show();
    this.orderService.getProductionPlanDetail(data).subscribe(
      (response: any) => {
        this.orderProcessingUpdateData =
          new MatTableDataSource<OrderProcessingOperation>(
            response.ProductionPlanDetailList
          );
      },
      (error: any) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  getProductionPlanDetails(data: any): void {
    const key = {
      PP_Id: data.PP_Id,
      PPD_Id: 0,
    };
    this.orderService.getProductionPlanDetail(key).subscribe(
      (response: any) => {
        this.orderProcessingUpdateData =
          new MatTableDataSource<OrderProcessingOperation>(
            response.ProductionPlanDetailList
          );
        this.BOMDataSource = new MatTableDataSource<OrderProcessingOperation>(
          response.ProductionPlanBOMList
        );
        this.getPaginator(this.orderProcessingUpdateData, 0);
        this.getStatusData();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  updateOperationPlan(): void {
    if (this.operationForm.valid) {
      const key = {
        Transactiontypeid: 2,
        PPD_Id: this.ProductionPlanDetailId,
        PP_Id: this.ProductionPlanId,
        Prod_Id: this.operationForm.value.Products,
        PU_Id: this.operationForm.value.PuId, // this.MachineId,
        PPD_Desc: this.operationForm.value.OperationDesc,
        PPD_Num: this.operationForm.value.OperationNo,
        UOM_Id: this.operationForm.value.UOM,

        PPS_Id: this.operationForm.value.StatusName,
        PlannedStartDate: new Date(this.operationForm.value.dateStart),
        PlannedEndDate: new Date(this.operationForm.value.dateEnd),
        TargetQuantity: this.operationForm.value.Target,
        Remarks: this.operationForm.value.Remarks,
        CreatedBy: 0,
      };
      const id = {
        PP_Id: this.ProductionPlanId,
        PPD_Id: 0,
      };
      // this.spinner.show();
      if (key.Prod_Id) {
        this.orderService.submitProductionPlanDetail(key).subscribe(
          (data: any) => {
            this.operationOrder(id);
            this.isOrderProcessModelVisible = false;
            this.showPopupMsg(data.ErrorMessage);
            // this.spinner.hide();
          },
          (error: any) => {
            this.showPopupMsg(error.ErrorMessage);
            // this.spinner.hide();
          }
        );
      } else {
        this.toaster.warning('Please add all the fields');
        // this.spinner.hide();
      }
    }
  }

  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
      this.toaster.success('Success', msg.substring(3, msg.length));
    } else {
      this.toaster.error('Error', msg.substring(3, msg.length));
    }
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        // this.IsWrite = false; 
        this.columnDefs = [
          {
            headerName: 'Actions',
            field: 'Actions',
            //resizable: true,
            //filter: 'agTextColumnFilter',
            cellRenderer: 'buttonRenderer',
            cellRendererParams: {
                //clicked(field: any) {},
                onClick: this.onClickEdit.bind(this),
                label: 'Edit',
            },
             maxWidth: 75,
             minWidth: 50,
             cellStyle: { textAlign: 'left' },
             hide:!this.IsWrite,
            suppressMenu: true,
          },
          {
            headerName: ' ',
            field: 'Actions',
           // resizable: true,
           // filter: 'agTextColumnFilter',
            cellRenderer: 'buttonRendererDelete',
            cellRendererParams: {
                //clicked(field: any) {},
                onClick: this.deleteOrder.bind(this),
                label: 'delete',
            },
             maxWidth: 50,
             minWidth: 50,
             cellStyle: { textAlign: 'center', padding: 0 },
             hide:!this.IsWrite,
            suppressMenu: true,
          },
          { headerName: 'PO', field: 'PO', filter: 'agTextColumnFilter' },
          {
            headerName: 'Item Code',
            field: 'ItemCode',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'Item Desc',
            field: 'ItemDesc',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'PO Target',
            field: 'Target',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'Start Date',
            field: 'StartDate',
            filter: 'agDateColumnFilter',
            valueFormatter: function (params: any) {
              return moment(params.value).format('MM/DD/YYYY HH:mm');
            },
          },
          {
            headerName: 'End Date',
            field: 'EndDate',
            filter: 'agDateColumnFilter',
            valueFormatter: function (params: any) {
              return moment(params.value).format('MM/DD/YYYY HH:mm');
            },
          },
          {
            headerName: 'Created By',
            field: 'CreatedBy',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'Created On',
            field: 'CreatedOn',
            filter: 'agDateColumnFilter',
            valueFormatter: function (params: any) {
              return moment(params.value).format('MM/DD/YYYY HH:mm');
            },
          },
         
        ];
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  //===================================================================== AG Grid (TODO : AbdurRazique) =================================================================//

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
