import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-o3-charts',
  templateUrl: './o3-charts.component.html',
  styleUrls: ['./o3-charts.component.scss']
})
export class O3ChartsComponent {
  @Input() chartData: any;
  @Input('datasets') barChartData: any;
  @Input('labels') barChartLabels: any;
  @Input('colors') barChartColors: any;
  @Input('options') barChartOptions: any;


  // rateLoss = {
  //   barChartLabels: ['Apple', 'Banana', 'Kiwifruit', 'Blueberry', 'Orange', 'Grapes'],
  //   barChartLegend: true,
  //   barChartPlugins: [],
  //   options: {
  //     legend: { position: 'bottom' }
  //   },
  //   barChartData: [
  //     { data: [[0, 45], [25, 37], 60, 70, 46, 33], label: 'Best Fruits' }
  //   ],

  // }
  constructor() { }

  // barChartOptions: ChartOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   legend: { position: 'bottom' },
  //   scales: {
  //     yAxes: [
  //       {
  //         gridLines: {
  //           borderDash: [8, 4],
  //         },
  //         ticks: {
  //           beginAtZero: true
  //         }
  //       }
  //     ],
  //     xAxes: [{
  //       barThickness: 50,
  //       gridLines: {
  //         display: false,
  //       }
  //     }]
  //   }
  // };

  // barChartLabels: Label[] = ['Apple', 'Banana', 'Kiwifruit', 'Blueberry', 'Orange', 'Grapes'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  // options: any = {
  //   legend: { position: 'bottom' }
  // };
  // barChartColors: Color[] = [
  //   { backgroundColor: 'rgba(248, 84, 57, 1)' },
  // ]
  // barChartData: ChartDataSets[] = [
  //   { data: [[0, 45], [25, 37], 60, 70, 46, 33], label: 'Best Fruits' }
  // ];

}
