import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Success } from '../models/success';

@Injectable({
  providedIn: 'root'
})
export class GlassQaDashboardService {
  baseUrl = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  GetZebraValues(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetZebraValues',
      data
    );
  }

  GetPullTonByDay(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetPullTonByDay',
      data
    );
  }

  GetSeedCount(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetSeedCount',
      data
    );
  }

  GetBloomGrade(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetBloomGrade',
      data
    );
  }

  GetReamComparartorGrade(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetReamComparartorGrade',
      data
    );
  }

  GetTSV(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetTSV',
      data
    );
  }

  GetDefectDensitySqm(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetDefectDensitySqm',
      data
    );
  }

  GetThicknessMM(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetThicknessMM',
      data
    );
  }

  GetMuStrainPsi(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetMuStrainPsi',
      data
    );
  }

  GetLastMeasuredCrossBowMM(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetLastMeasuredCrossBowMM',
      data
    );
  }

  GetCorrugation(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetCorrugation',
      data
    );
  }

  GetLastMeasuredWarpage(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetLastMeasuredWarpage',
      data
    );
  }

  GetHourlyDefect(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetHourlyDefect',
      data
    );
  }

  GetManualHourlyDefect(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetManualHourlyDefect',
      data
    );
  }

  GetChemicalLabDashboard(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPhysicalLabQADashboardP/GetChemicalLabDashboard',
      data
    );
  }
}
