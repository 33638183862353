import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationCancel, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AnalyticalToolsService } from './services/analytical-tools.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Obeikan Client';
  isLoading = false;
  showContent = false;
  rcaUrl = [
    '/at',
    '/at/sixwtwoh',
    '/at/fishbone',
    '/at/causeEffect',
    '/at/fivewhy',
    '/at/fivewhy-actpln',
  ];
  public languageDir = 'ltr';
  constructor(
    private router: Router,
    private translater: TranslateService,
    private analyticalToolsService: AnalyticalToolsService
  ) {
    this.translater.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === 'ar') {
        this.languageDir = 'ltr';
      }
      else {
        this.languageDir = 'ltr';
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      const url = document.location.href;
      const checkurl = document.location.origin + '/NewSfdc';
      if (url === checkurl) {
        document.body.className = 'body_hide';
        document.body.style.paddingBottom = '0px';
      } else {
        document.body.className = 'body_change';
        document.body.style.paddingBottom = '90px';
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.showContent = true;
        if (event.url !== '/' && !this.rcaUrl.includes(event.url)) {
          this.analyticalToolsService.setRca(false);
          this.analyticalToolsService.setRcaId(0);
          this.analyticalToolsService.setHeaderId(0);
        }
      }
    });
  }
}
