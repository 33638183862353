import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GridSizeChangedEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BtnDeleteCellRenderer } from 'src/app/btn-deletecell-renderer';
import { MatCheckboxRendererComponent } from 'src/app/mat-checkbox-renderer';
import { MatSelectRendererComponent } from 'src/app/mat-selectlist-renderer';
import { MeetingEvaluationService } from 'src/app/services/meeting-evaluation.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-meeting-evaluation-level',
  templateUrl: './meeting-evaluation-level.component.html',
  styleUrls: ['./meeting-evaluation-level.component.scss']
})
export class MeetingEvaluationLevelComponent implements OnInit {

  gridApi: any;
  gridColumnApi: any;
  modules: any;
  components: any;
  columnDefs: any;
  columnDefs1: any;
  rowData: any[] = [];
  bottomData: any;
  MeetingEvaluationForm!: FormGroup;
  meetingMasterId = 0;
  leaderId = 0;
  rowSpanCount = 0;
  average = 0;
  isSaving = false;
  successMessage = '';
  paramValues: any = {
    plant: 0,
    meeting: 0,
    meetingAttendId: 0
  };
  constructor(private fb: FormBuilder, private toaster: ToastrService,
    private meetingEvaluationService: MeetingEvaluationService,
    private route: ActivatedRoute) {
    this.MeetingEvaluationForm = this.fb.group({
      meeting: [''],
      date: [''],
      leader: ['']
    });
    this.columnDefs = [
      {
        field: 'area',
        cellRenderer: (params: any) => {
          if (params.value && params.value.name) {
            return '<div class="area-name">' +
              params.value.name +
              '' +
              '</div>';
          } else { return ''; }
        },
        headerName: 'Level',
        rowSpan: this.rowSpan,
        cellClassRules: { 'show-cell': 'value !== undefined' },
        cellStyle: (params: any) => {
          if (params.value && params.value.name) {
            return { background: '#49FFB9', border: '1px solid #333' };
          } else {
            return { background: 'white' };
          }
        },
        width: 150,
      },
      { field: 'detail', headerName: 'Check Detail', width: '390' },
      {
        field: 'score', headerName: 'Evaluation Result', width: '200', editable: true,
        onCellValueChanged: (params: any) => this.getAverage(params),
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Ok', 'Not Ok'],
        },
        cellStyle: {
          color: '#000000',
          'background-color': '#EDF1E5',
        }
      },
      {
        field: 'xyz',
        headerName: 'Average',
        rowSpan: (params: { data: { xyz: any; }; }) => params.data.xyz !== undefined,

        // cellClassRules: { 'show-cell': 'value !== undefined' },
        // cellStyle: (params: any) => {
        //   if (params.value && params.value.name) {
        //     return { background: '#49FFB9', border: '1px solid #333' };
        //   } else {
        //     return { background: 'white' };
        //   }
        // },
        width: 150,
      },
    ];
  }
  ngOnInit(): void {
    this.route.queryParams
      .subscribe((param: any) => {
        this.paramValues.plant = param?.plant ? parseInt(param?.plant, 10) : 0;
        this.paramValues.meeting = param?.meeting ? parseInt(param?.meeting, 10) : 0;
        this.paramValues.meetingAttendId = param?.meetingAttendId ? parseInt(param?.meetingAttendId, 10) : 0;
      });
    this.getMeetingDefinations();
  }

  getMeetingDefinations(): void {
    const key = {
      plantId: this.paramValues.plant,
      meetingId: this.paramValues.meeting
    };
    this.meetingEvaluationService.getAllMeetingEvaluationActions(key).subscribe(res => {
      this.meetingMasterId = res?.meetingMasterId;
      this.leaderId = res?.leaderId;
      //  setting values in form group
      this.MeetingEvaluationForm.get('meeting')?.setValue(res?.meetingDefName);
      this.MeetingEvaluationForm.get('leader')?.setValue(res?.leaderName);
      this.MeetingEvaluationForm.get('date')?.setValue(new Date());
      // getting thte unique areas from response
      const uniqueAreas = [... new Set(res?.detail?.map((item: any) => {
        return item.name;
      }))];
      // formate data according to the ag grid
      const formattedArray: any[] = [];
      uniqueAreas.forEach(area => {
        const filteredArray = res?.detail.filter((x: any) => x?.name === area);
        if (filteredArray.length > 0) {
          filteredArray.forEach((meeting: any) => {
            const areafound = formattedArray.findIndex((item: any) => {
              return item?.area?.name === area;
            });
            if (areafound === -1) {
              formattedArray.push({
                area: {
                  name: area
                },
                detail: meeting?.detail,
                score: 'Not Ok',
                totalcount: filteredArray?.length,
                level: area
              });
            } else {
              formattedArray.push({
                detail: meeting?.detail,
                score: 'Not Ok',
                totalcount: filteredArray?.length,
                level: area
              });
            }
          });
        }
      });
      // setting formatted data in ag grid
      this.rowData = formattedArray;

    }, ({ error }) => {
      this.toaster.error(error.message);
    });
  }

  getAverage(params: any) {

    let counter = 0;
    const filteredlevel = this.rowData.filter((obj: any) => obj.level === params.data.level);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < filteredlevel.length; i++) {
      if (filteredlevel[i].score === 'Ok')
        counter++;
      this.average = (counter / params.data.totalcount) * 100;

      params.node.setDataValue('xyz', this.average);
    }

  }
  saveRecord(): void {
    debugger
    this.isSaving = true;
    const data = {
      meetingMasterId: this.meetingMasterId,
      date: this.MeetingEvaluationForm.value.date,
      leaderId: this.leaderId,
      meetingAttendId: this.paramValues.meetingAttendId,
      details: this.rowData?.map((item) => {
        return {
          evaluationResult: item?.score === 'Ok' ? true : false,
          average: item?.xyz,
          evalutionLevel: item?.level
        };
      })
    };
    this.meetingEvaluationService.addEvaluationResult(data).subscribe(res => {
      this.successMessage = 'Thanks for your feedback';
      this.getMeetingDefinations();
      this.isSaving = false;
    }, ({ error }) => {
      this.toaster.error(error.message);
      this.isSaving = false;
    });
  }
  rowSpan(params: any): any {
    if (params.data.area) {
      return params.data.totalcount;
    } else {
      return 1;
    }
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridSizeChanged(params: GridSizeChangedEvent): any {
    params.api.sizeColumnsToFit();
  }
}
