import { NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PlantGroupElement } from 'src/app/interface/plant-model';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MeetingActionService } from 'src/app/services/meeting-action.service';
import { QualityParameterLoggingService } from 'src/app/services/Quality-Parameter-Logging.service';
import { QualityParameterService } from 'src/app/services/quality-parameter.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { differenceInCalendarDays } from 'date-fns';
import { ProcessControlService } from 'src/app/services/processControlService.service';
import { CrewScheduleService } from 'src/app/services/crewSchedule.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';

const moment = _moment;
@Component({
  selector: 'app-qc-verify',
  templateUrl: './qc-verify.component.html',
  styleUrls: ['./qc-verify.component.scss'],
})
export class QcVerifyComponent implements OnInit, AfterViewInit {
  breadcrumList: any;
  Collapsehide = false;
  public color: ThemePalette = 'primary';
  columnDefs: any;
  crewScheduleList: any;
  public enableMeridian = false;
  Exdpandhide = true;
  FromTimePicker: any;
  gridApi: any;
  gridColumnApi: any;
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    autoGroupColumnDef: {
      headerName: 'Interval',
      pinned: 'left',
      resizable: true,
      width: 120,
    },
    flex: 1,
    groupDefaultExpanded: 1,
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
      customLoadingOverlay: CustomLoadingOverlay,
    },
  };
  groupTypeList: any;
  groupListDDL!: any[];
  isPlantGroupModelVisible = false;
  isCardExpanded = false;
  isEventMode = false;
  isTimeMode = false;
  IntervalTypeDDL: any;
  rowData: any;
  QcVerifyForm!: FormGroup;
  private subscriptions = new Subscription();
  plants!: any;
  today = new Date();
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;

  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  UnitName: any;
  unitId: any;
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();

  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  UnitNode: any;
  parameterGroupList: any;
  ToTimePicker: any;
  intervalList: any;
  qualityGroupType: any;
  loadingOverlayComponent: any;
  frameworkComponents: any;
  constructor(
    private fb: FormBuilder,
    private usermanagementService: UserManagementService,
    private meetingService: MeetingActionService,
    private toaster: ToastrService,
    public router: Router,
    private enterpriseGroupService: EnterPriseGroupService,
    private qualityParameterService: QualityParameterService,
    private spinner: NgxSpinnerService,
    private processService: ProcessControlService,
    private qualityParameterLoggingService: QualityParameterLoggingService,
    private crewService: CrewScheduleService
  ) {
    this.columnDefs = [
      {
        field: 'SrNo',
        headerName: 'Sr.No',
        filter: true,
        resizable: true,
        wrapText: true,
        hide: true,
      },
      {
        field: 'TimeSlot',
        headerName: 'Time Interval',
        filter: true,
        resizable: true,
        wrapText: true,
        rowGroup: true,
        hide: true,
        valueGetter: this.valuegetter,
      },
      // tslint:disable-next-line:max-line-length
      {
        field: 'VerificationStatus',
        headerName: 'Varification Status',
        filter: true,
        width: 150,
        resizable: true,
        wrapText: true,
        pinned: 'left',
        cellStyle: (params: { value: string }) => {
          if (params.value === 'Approve') {
            return {
              color: 'white',
              backgroundColor: '#38cf36',
              'font-weight': 'bold',
            };
          } else if (params.value === 'Hold') {
            return {
              color: 'black',
              backgroundColor: '#FFFF00',
              'font-weight': 'bold',
            };
          } else if (params.value === 'Reject') {
            return {
              color: 'white',
              backgroundColor: '#eb3d3d',
              'font-weight': 'bold',
            };
          } else {
            return {
              color: 'white',
              backgroundColor: '#11ced1',
              'font-weight': 'bold',
            };
          }
        },
      },
      // { field: 'ProdDesc', headerName: 'Product', filter: true, resizable: true, wrapText: true },
      // { field: 'ProcessOrderNo', headerName: 'PO', filter: true, resizable: true, wrapText: true },
      {
        field: 'ProcessOrderAndProduct',
        headerName: 'PO & Product',
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        field: 'ParamterGroup',
        headerName: 'Parameter Group',
        filter: true,
        resizable: true,
        wrapText: true,
        pinned: 'left',
      },
      {
        field: 'ParamterName',
        headerName: 'Parameter Name',
        filter: true,
        resizable: true,
        wrapText: true,
        pinned: 'left',
      },
      {
        field: 'Result',
        headerName: 'Result',
        filter: true,
        width: 120,
        resizable: true,
        wrapText: true,
        pinned: 'left',
        // cellStyle: { color: 'black', 'background-color': '#EDF1E5' }
        cellStyle: (params: any) => {
          if (params.data) {
            if (params.data.Value) {
              const color = params.data.Value.split('|');
              return {
                color: color[1],
                backgroundColor: '#bdccdd',
                'font-weight': 'bold',
              };
              // }
              // const backColor = params.data.Value.split('|');

              // if (
              //   params.value < params.data.LRL ||
              //   params.value > params.data.URL
              // ) {
              //   return {
              //     color: '#ff2121',
              //     backgroundColor: '#EDF1E5',
              //     'font-weight': 'bold',
              //   };
              // } else if (
              //   params.value < params.data.LWL ||
              //   params.value > params.data.UWL
              // ) {
              //   return {
              //     color: '#efbc03',
              //     backgroundColor: '#EDF1E5',
              //     'font-weight': 'bold',
              //   };
            } else {
              return {
                color: 'black',
                backgroundColor: '#bdccdd',
                'font-weight': 'bold',
              };
            }
          } else {
            return {
              color: 'black',
              backgroundColor: '#bdccdd',
              'font-weight': 'bold',
            };
          }
        },
      },
      {
        field: 'EnteredBy',
        headerName: 'Data Entered By',
        filter: true,
        resizable: true,
        wrapText: true,
        cellStyle: { color: 'black', 'background-color': '#bdccdd' },
      },
      {
        field: 'DataRecordTime',
        headerName: 'Data Record Time',
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        field: 'VerifiedBy',
        headerName: 'QC Verified By',
        filter: true,
        resizable: true,
        wrapText: true,
        cellStyle: { color: 'black', 'background-color': '#bdccdd' },
      },
      {
        field: 'QCTime',
        headerName: 'QC Time',
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        field: 'Remarks',
        headerName: 'Remarks',
        filter: true,
        resizable: true,
        wrapText: true,
      },
      {
        field: 'LRL',
        headerName: 'LRL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 80,
      },
      {
        field: 'LSL',
        headerName: 'LSL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 80,
      },
      {
        field: 'LWL',
        headerName: 'LWL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 80,
      },
      {
        field: 'TGT',
        headerName: 'TGT',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 80,
      },
      {
        field: 'UWL',
        headerName: 'UWL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 80,
      },
      {
        field: 'USL',
        headerName: 'USL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 80,
      },
      {
        field: 'URL',
        headerName: 'URL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 80,
      },
    ];
    this.loadingOverlayComponent = 'customLoadingOverlay';
  }
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngAfterViewInit(): void {
    this.gridOptions.api.hideOverlay();
  }
  ngOnInit(): any {
    this.getGroupData();
    this.GetBreadcrumList();
    this.QcVerifyForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      Unit: ['', Validators.required],
      interval: [''],
      datePicker: ['', Validators.required],
      // fromDate: ['', Validators.required],
      // toDate: ['', Validators.required],
      groupType: ['', Validators.required],
      intervalType: [''],
    });
    const start = new Date();
    // this.FromTimePicker = new Date(start.setHours(0, 0, 0, 0));
    // this.QcVerifyForm.get('fromDate')?.setValue(
    //   new Date(start.setHours(0, 0, 0, 0)),
    //   { emitEvent: false }
    // );
    const dates: any[] = [];
    dates.push(new Date(start.setHours(0, 0, 0, 0)).toISOString());
    dates.push(new Date().toISOString());
    this.QcVerifyForm.get('datePicker')?.setValue(dates);
  }
  valuegetter(params: any): any {
    const startDate = new Date(params.data.startDate);
    const endDate = new Date(params.data.endDate);
    return (
      moment(startDate).format('HH:mm:ss') +
      ' - ' +
      moment(endDate).format('HH:mm:ss')
    );
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // this.spinner.show();
    // tslint:disable-next-line: no-debugger
    const groupSub = this.meetingService
      .getGroupData(key)
      .pipe(
        finalize(() => {
          // this.spinner.hide();
        })
      )
      .subscribe(
        (data: any) => {
          this.groupListDDL = data.masterList;
          this.rowData = [];
          this.QcVerifyForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
          this.getPlantByGroup();
        },
        (error: any) => {
          this.toaster.error('error', error.message);
        }
      );
    this.subscriptions.add(groupSub);
  }
  GetCrewScheduleList(): void {
    const key = {
      PUID: this.unitId,
      Date: new Date(),
    };
    this.crewService.GetCrewScheduleList(key).then((res: any) => {
      this.crewScheduleList = res.CrewScheduleList;
      if (this.crewScheduleList.length) {
        const date = new Date();
        // tslint:disable-next-line:max-line-length
        const record = this.crewScheduleList.filter(
          (c: any) =>
            date.getTime() >= new Date(c.ShiftStart).getTime() &&
            date.getTime() <= new Date(c.ShiftEnd).getTime()
        );
        const start = new Date(record[0].ShiftStart);
        const end = new Date();
        this.QcVerifyForm.get('datepicker')?.setValue([start, end]);
        if (record.length) {
          const newdatestart = new Date(record[0].ShiftStart);
          const newdateend = new Date();
          this.QcVerifyForm.get('datePicker')?.setValue(
            [newdatestart, newdateend],
            { emitEvent: false }
          );
        } else {
          const newstart = new Date();
          this.QcVerifyForm.get('datePicker')?.setValue(
            [new Date(newstart.setHours(0, 0, 0, 0)).toISOString(), new Date()],
            { emitEvent: false }
          );
        }
      } else {
        const start = new Date();
        this.QcVerifyForm.get('datePicker')?.setValue(
          [new Date(start.setHours(0, 0, 0, 0)).toISOString(), new Date()],
          { emitEvent: false }
        );

        // this.QcVerifyForm.get('datepicker')?.setValue([start, new Date()]);
      }
    });
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.QcVerifyForm.get('group')?.value,
    };
    // this.spinner.show();
    // this.MachineId = null;
    this.resetValue();
    const plantByGroupSub = this.meetingService
      .getPlantsData(key)
      .pipe(
        finalize(() => {
          // this.spinner.hide();
        })
      )
      .subscribe(
        (data: any) => {
          this.plants = data.DepartList;
          this.QcVerifyForm.get('plant')?.setValue(this.plants[0].DeptId);
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    this.subscriptions.add(plantByGroupSub);
  }
  onPlantChange(): void {
    this.QcVerifyForm.get('interval')?.setValue('');
  }
  resetValue(): void {
    this.rowData = [];
    this.UnitName = '';
    this.QcVerifyForm.get('interval')?.setValue('');
  }
  onClickUnit(node: any): void {
    if (node.Level === 'Unit') {
      this.isPlantGroupModelVisible = false;
      this.UnitName = node.text;
      this.unitId = node.PrimaryId;
      this.GetGroupTypeList();
    }
    // this.getParameterInterval();
  }
  getParameterInterval(): void {
    const Key = {
      puId: this.unitId,
    };
    // this.spinner.show();
    const qualityParameterSub = this.qualityParameterService
      .getQualityControlInterval(Key)
      .pipe(
        finalize(() => {
          // this.spinner.hide();
        })
      )
      .subscribe(
        (response: any) => {
          this.intervalList = response;
          this.QcVerifyForm.get('interval')?.setValue(
            this.intervalList[0].Interval
          );
          this.onToProcessDate();
        },
        (error: { message: any }) => {
          this.toaster.error('Error', error.message);
        }
      );
    this.subscriptions.add(qualityParameterSub);
  }
  openUnit(): any {
    // this.spinner.show();
    this.isPlantGroupModelVisible = true;
    const key = {
      EGId: this.QcVerifyForm.value.group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };
    const unitSub = this.enterpriseGroupService
      .getTreeModal(key)
      .pipe(
        finalize(() => {
          // this.spinner.hide();
        })
      )
      .subscribe(
        (data: any) => {
          const plantGroupData: PlantGroupElement[] = data.TreeModalList;
          this.plantGroupSource.data = plantGroupData;
          this.treeControl.dataNodes = this.plantGroupSource.data;
          this.UnitNode = filterData(this.plantGroupSource.data, 'UNT');
          this.expandAllNode();
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    this.subscriptions.add(unitSub);

    function filterData(data: PlantGroupElement[], title: string): any {
      const r = data.filter(function (o: any) {
        Object.keys(o).forEach(function (e: any) {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
  }
  GetBreadcrumList(): any {
    // this.spinner.show();
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    const breadCrumSub = this.usermanagementService
      .GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
          // this.spinner.hide();
        })
      )
      .subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(breadCrumSub);
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  SelectUnitName(unitname: any): void {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
      this.gridApi.setRowData([]);
      this.getParameterGroups();
    }
  }

  getParameterGroups(): void {
    // this.spinner.show();
    const Key = {
      puId: this.unitId,
    };
    const paramGroupSub = this.qualityParameterLoggingService
      .getPlants(Key)
      .pipe(
        finalize(() => {
          // this.spinner.hide();
        })
      )
      .subscribe(
        (res: any[]) => {
          this.parameterGroupList = res;
          // this.processDataForm.get('parametergroup')?.setValue(this.parameterGroupList[0].ptId);
        },
        (error: { message: any }) => {
          this.toaster.error('Error', error.message);
        }
      );
    this.subscriptions.add(paramGroupSub);
  }
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }
  onIntervalChange(): void {
    this.onToProcessDate();
  }
  onToProcessDate(): any {
    debugger;
    const getdata = {
      // date: moment(this.unitDetailForm.value.fromProcessDate).format('YYYY-MM-DD'),
      fromDate: this.QcVerifyForm.value.datePicker[0],
      toDate: this.QcVerifyForm.value.datePicker[1],
      puId: this.unitId,
      interval: this.QcVerifyForm.value.interval,
      processType: 'PO',
      groupType: this.qualityGroupType,
      // eventType: this.QcVerifyForm.value.intervalType,
      eventType:
        this.qualityGroupType === 'Time' || this.qualityGroupType === 'Manual'
          ? this.qualityGroupType
          : this.QcVerifyForm.value.intervalType,
    };
    if (
      getdata.puId === undefined ||
      !getdata.fromDate ||
      !getdata.toDate ||
      (getdata.interval === '' && this.qualityGroupType === 'Time')
    ) {
      // this.toaster.warning('Warning', 'Please Select Unit And Dates');
      this.rowData = [];
      return;
    }
    const from = this.QcVerifyForm.value.datePicker[0];
    const To = this.QcVerifyForm.value.datePicker[1];
    const timeDiff = moment(To).diff(moment(from), 'hours');

    // if (timeDiff > 24) {
    //   this.toaster.error('Error', 'Select Date Range between 24 hours');
    // this.rowData = [];
    const start = new Date();
    this.QcVerifyForm.get('datepicker')?.setValue([start, new Date()]);
    // }
    this.gridApi.showLoadingOverlay();
    // this.spinner.show();
    const qcVerifySub = this.qualityParameterService
      .getQcVerify2(getdata)
      .pipe(
        finalize(() => {
          // this.spinner.hide();
        })
      )
      .subscribe(
        (response: any) => {
          this.gridApi.setRowData(response);
          this.gridApi.hideOverlay();
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    this.subscriptions.add(qcVerifySub);
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  GetGroupTypeList(): any {
    // this.spinner.show();
    const key = {
      puId: this.unitId,
    };
    const groupTypeSub = this.qualityParameterService
      .getQualityGroupbyUnit(key)
      .pipe(
        finalize(() => {
          // this.spinner.hide();
        })
      )
      .subscribe(
        (data: any) => {
          this.groupTypeList = data.filter(
            (c: any) => c.groupTypeName !== 'None'
          );
          // this.unitDetailForm.get('groupType')?.s
          this.QcVerifyForm.get('groupType')?.setValue(
            this.groupTypeList[0].parameterGroupTypeId
          );
          // tslint:disable-next-line:max-line-length
          this.qualityGroupType = this.groupTypeList.find(
            (c: any) =>
              c.parameterGroupTypeId === this.QcVerifyForm.value.groupType
          )?.groupTypeName;
          this.EventMode();
          console.log(data.BreadcrumList);
          this.getIntervalType();
          this.GetCrewScheduleList();
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(groupTypeSub);
  }
  EventMode(): void {
    debugger;
    this.rowData = [];
    // tslint:disable-next-line:max-line-length
    const val = this.groupTypeList.find(
      (c: any) => c.parameterGroupTypeId === this.QcVerifyForm.value.groupType
    )?.groupTypeName;
    this.qualityGroupType = val;
    if (val === 'Event') {
      this.isEventMode = true;
      this.isTimeMode = false;
      // this.getQualityGroupEvents();
    } else if (val === 'Time') {
      this.isEventMode = false;
      this.isTimeMode = true;
      this.getParameterInterval();
    } else {
      this.isEventMode = false;
      this.isTimeMode = false;
      this.onToProcessDate();
    }
    this.getIntervalType();
  }
  getIntervalType(): void {
    // this.spinner.show();
    const key = {
      puId: this.unitId,
    };
    const qualityGroupSub = this.processService
      .getQualityGroupEvents(key)
      .pipe(
        finalize(() => {
          // this.spinner.hide();
        })
      )
      .subscribe(
        (data: any) => {
          this.IntervalTypeDDL = data;
          this.QcVerifyForm.get('intervalType')?.setValue(
            this.IntervalTypeDDL[0].val
          );
          this.onToProcessDate();
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    this.subscriptions.add(qualityGroupSub);
  }
  onChange(result: Date[]): void {
    this.QcVerifyForm.get('fromDate')?.setValue(result[0]);
    this.QcVerifyForm.get('toDate')?.setValue(result[1]);
  }
  expand() {
    this.isCardExpanded = !this.isCardExpanded;
    this.Exdpandhide = false;
    this.Collapsehide = true;
  }
  close() {
    this.isCardExpanded = !this.isCardExpanded;
    this.Exdpandhide = true;
    this.Collapsehide = false;
  }
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;
}
function GenderCellRenderer(): void {}

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};
