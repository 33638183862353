<ngx-spinner bdColor="rgba(0, 0, 0, 0)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
<div class="machine-parameter-content">
  <ng-container *ngIf="!isExpanded">
    <div class="content-top-box">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{ breadcrumItem.text }}</a>
        </li>
      </ul>
      <!-- <div class="tab-content-top-box">
            <h1 class="main-title"> {{MeetingMatrix ? MeetingMatrix : "Meeting Matrix" }}</h1>
            </div> -->
    </div>
  </ng-container>
  <div [ngClass]="{ fullscreen: isExpanded }">
    <div class="content-wrapper">
      <div id="wrapper1" class="content-show">
        <form [formGroup]="MeetingMatrixForm">
          <div class="content-top-row" style="display: flex; justify-content: flex-end">
            <!-- <button
                     mat-button
                     appearence="fill"
                     class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                     (click)="openFullCalendar()"
                     >
                     Full Calender
                     </button> -->
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="height: 57px !important">
              <mat-label>{{
                langObj ? langObj?.SelectGroup : ("SelectGroup" | translate)
                }}
              </mat-label>
              <mat-select panelClass="testClass" name="group" formControlName="group"
                (selectionChange)="getPlantByGroup()">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groupListDDL"
                (filteredReturn)="filteredGroupListDDL = $event"></mat-select-filter>
                <mat-option *ngFor="let g of filteredGroupListDDL" [value]="g.TypeId">
                  {{ g.TypeName }}
                </mat-option>
              </mat-select>
              <mat-error>{{
                langObj ? langObj?.group : ("group" | translate)
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="height: 57px !important">
              <mat-label>{{
                langObj ? langObj?.SelectPlant : ("SelectPlant" | translate)
                }}
              </mat-label>
              <mat-select panelClass="testClass" name="plant" formControlName="plant"
                (selectionChange)="getFrequencyOnMeeting()">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plants"
                (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                <mat-option *ngFor="let p of filteredPlants" [value]="p.DeptId">
                  {{ p.DeptDesc }}
                </mat-option>
              </mat-select>
              <mat-error>{{
                langObj ? langObj?.Plant : ("Plant" | translate)
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="field-fill" style="
                     display: flex;
                     justify-content: flex-end;
                     height: 38px !important;
                     ">
              <mat-label>{{
                langObj ? langObj?.SearchMeeting : ("SearchMeeting" | translate)
                }}
              </mat-label>
              <input [readonly]="false" matInput [(ngModel)]="search" class="input-text" autocomplete="off"
                (keyup)="meetingSearch()" formControlName="search" />
            </mat-form-field>
          </div>
        </form>
        <div class="p-grid p-fluid">
          <div class="p-xl-12 p-lg-12 p-md-12 p-col-12" style="margin-top: 35px">
            <app-full-calendar (toggleDisplay)="toggleDisplayFromChild($event)"
              [plantId]="MeetingMatrixForm.controls['plant']" [MeetingId]="0"></app-full-calendar>
          </div>
        </div>
      </div>
      <div id="wrapper2" class="content-hide">
        <!-- <h2 style="color:#0000cd" >Meeting Name</h2> -->
        <ng-container *ngIf="!isExpanded">
          <h3>{{ selectmeeting }}</h3>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <button type="button" [disabled]="pausebutton" (click)="startMeeting();" mat-raised-button color="accent"
                *ngIf="IsWrite">
                {{ meetingStarted ? "Meeting Started" : "Start Meeting" }}
              </button>
              <button type="button" (click)="stopMeeting();" mat-raised-button color="warn" [disabled]="!meetingStarted"
                style="margin-left: 14px" *ngIf="IsWrite">
                {{ meetingStoped ? "Meeting Stopped" : "Stop Meeting" }}
              </button>
              <button type="button" (click)="resetMeeting()" mat-raised-button style="margin-left: 14px">
                {{ langObj ? langObj?.Reset : ("Reset" | translate) }}
              </button>
              <div class="d-flex align-items-center">
                <h3 class="timer_number">{{ timer }}</h3>
              </div>
              <!-- <button type="button" (click)="OpenFullCalendarByMeetingId()" mat-raised-button color="accent"
                        style="margin-left: 14px">
                        Full Calendar
                        </button> -->
            </div>
            <!-- [disabled]="!enabledopenTierMeeting" -->
            <!-- <div class="d-flex justify-content-between align-items-center w-50" style="width: 50%">
                     <button mat-mini-fab color="accent" matTooltip="Open Tier Meeting" matTooltipPosition="above"
                       (click)="openTierMeeting()">
                       <mat-icon style="color: white">library_books</mat-icon>
                     </button> -->
            <div class="d-flex justify-content-between align-items-center w-50" style="width: 50%">

              <button mat-mini-fab color="accent" matTooltip="Open Tier Meeting" matTooltipPosition="above"
                (click)="openMeetingCPStatus()">
                <mat-icon style="color: white">library_books</mat-icon>
              </button>
              <button mat-mini-fab color="warn" matTooltip="View Meeting Agenda & Notes" matTooltipPosition="above"
                (click)="openNotesAgendaModal()" *ngIf="IsWrite" [disabled]="!meetingStarted">
                <mat-icon style="color: white">view_agenda</mat-icon>
              </button>
              <button mat-mini-fab color="primary" matTooltip="Create Meeting Actions" matTooltipPosition="above"
                (click)="openRcaForActions()" *ngIf="IsWrite" [disabled]="!meetingStarted">
                <mat-icon style="color: white">edit_note </mat-icon>
                <!-- <mat-icon style="color: white">meeting_room</mat-icon> -->
              </button>
              <button mat-mini-fab matTooltip="Last Meeting Action" matTooltipPosition="above"
                (click)="openLastMeetingrModal()" type="button" mat-raised-button color="accent" *ngIf="IsWrite"
                style="background-color: #343a40">
                <mat-icon style="color: white">notes</mat-icon>
              </button>
              <button mat-mini-fab matTooltip="
                        Meeting Rescheduler
                        " matTooltipPosition="above" (click)="openMeetingRescheduler()" type="button" mat-raised-button
                style="background-color: #f17a30">
                <mat-icon class="material-icons-outlined">event_repeat</mat-icon>
              </button>
              <button type="button" mat-mini-fab (click)="decreaseDecimalPlaces()" matTooltip="Decrease Decimal Places" matTooltipPosition="above"   color="accent"
              style="margin-left: 14px">
             
              <mat-icon style="color: #fff;"> format_indent_decrease</mat-icon>
              </button>
              <button type="button" mat-mini-fab (click)="IncreaseDecimalPlaces()" matTooltip="Increase Decimal Places" matTooltipPosition="above"   color="accent"
              style="margin-left: 14px">
             
              <mat-icon style="color: #fff;"> format_indent_increase</mat-icon>
              </button>
              <!-- <button
                        (click)="openLastMeetingrModal()"
                        type="button"
                        mat-raised-button
                        color="accent"
                        *ngIf="IsWrite"
                        >
                        Last Meeting Action
                        </button> -->
              <button type="button" (click)="openUserModal()" mat-flat-button color="warn">
                {{
                langObj ? langObj?.Participants : ("Participants" | translate)
                }}
              </button>
              <button type="button" (click)="getMeetingEvaluations()" mat-flat-button color="accent"
                class="back-button">
                <p class="btn-text">
                  {{
                  langObj ? langObj?.Evaluations : ("Evaluations" | translate)
                  }}
                </p>
              </button>
              <button type="button" (click)="toggleDisplayreverse()" class="back-button" mat-flat-button color="accent"
                matBadgePosition="after" matBadgeColor="accent" MatBadgeSize="large">
                <p class="btn-text">
                  <i class="fa fa-arrow-left" style="color: #fff; margin-right: 9px"></i>
                  {{ langObj ? langObj?.Previous : ("Previous" | translate) }}
                </p>
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isExpanded">

          <div>
            <div class="d-flex align-items-center justify-content-between" style="padding:10px 15px">
              <div class="filters">
                <div class="frequency-wrapper">
                  <div style="margin-right: 7px">
                    <h3>
                      {{
                      langObj
                      ? langObj?.KPIFrequency
                      : ("KPIFrequency" | translate)
                      }}
                    </h3>
                  </div>
                  <form [formGroup]="MeetingMatrixFrequencyForm">
                    <p-dropdown formControlName="frequency" defaultLabel="Select a Frequency" [ngClass]="{
                             'is-invalid':
                             MeetingMatrixFrequencyForm.get('frequency')
                             ?.errors &&
                             MeetingMatrixFrequencyForm.get('frequency')
                             ?.touched
                             }" [options]="frequencies" (onChange)="getGridDataByFrequency()"
                      placeholder="Select a Frequency" scrollHeight="300px" name="frequency"
                      optionLabel="frequencyName">
                    </p-dropdown>
                  </form>
                </div>
                <div class="glide-wrapper">
                  <mat-slide-toggle name="enableGlidePath" (change)="enableGlidePathChange($event)"
                    [(ngModel)]="enableGlidePath">
                    <h3>Glide path</h3>
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="content-top-row mt-0" style="width:64% !important">
                <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  style="width: 100% !important">
                  <mat-label>Date Range (Financial Gap)</mat-label>
                  <mat-date-range-input [formGroup]="rangeFinacialGap" [rangePicker]="picker">
                    <input matStartDate formControlName="fromDate" placeholder="Gap From Date" [readOnly]="true" />
                    <input matEndDate formControlName="toDate" placeholder="Gap To Date" [readOnly]="true" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                  <mat-date-range-picker #picker (closed)="onFinancialGapRangeChange()"></mat-date-range-picker>
                </mat-form-field>
                <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  style="width: 100% !important">
                  <mat-label>Date Range (Scorecard)</mat-label>
                  <mat-date-range-input [formGroup]="range" [rangePicker]="pickerForScorecard">
                    <input matStartDate formControlName="fromDate" placeholder="From Date" [readOnly]="true" />
                    <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="pickerForScorecard">
                  </mat-datepicker-toggle>
                  <mat-date-range-picker #pickerForScorecard (closed)="getGridDataByFrequency($event)">
                  </mat-date-range-picker>
                </mat-form-field>
                <button mat-mini-fab color="accent" matTooltip="Expand" matTooltipPosition="above" (click)="expandAll()"
                  style="margin-left: 14px">
                  <mat-icon style="color: white;transform: rotate(40deg);">unfold_more</mat-icon>
                </button>
                <button mat-mini-fab color="accent" matTooltip="Collapse" matTooltipPosition="above"
                  (click)="collapseAll()" style="margin-left: 14px">
                  <mat-icon style="color: white;transform: rotate(40deg);">unfold_less</mat-icon>
                </button>
                <mat-spinner-button [options]="spinnerButtonOptions" (btnClick)="onClickParamterAnalysis()"
                  class="loader" id="selectedRows" style="margin: 10px 10px">
                </mat-spinner-button>
                <mat-spinner-button [options]="spinnerButtonOptions1" (btnClick)="onClickParamterComparsion(false)"
                  class="loader" id="selectedRows" style="margin: 10px 10px">
                </mat-spinner-button>
              </div>
              <div class="toggle-actions">
                <!-- <button mat-mini-fab mat-button mat-dialog-close pTooltip="Sort" tooltipPosition="top" color="primary"
                  (click)="sortGapColumn(sort)" style="margin-bottom: 4px">
                  <mat-icon>sort</mat-icon>
                </button> -->
                <button *ngIf="!isExpanded" mat-mini-fab mat-button mat-dialog-close color="accent"
                  pTooltip="Expand Grid" tooltipPosition="top" (click)="toggleView()" style="margin-bottom: 4px">
                  <mat-icon>fullscreen</mat-icon>
                </button>
                <button mat-mini-fab mat-button mat-dialog-close color="accent" pTooltip="Collapse Grid"
                  tooltipPosition="top" (click)="toggleView()" *ngIf="isExpanded" style="margin-bottom: 4px">
                  <mat-icon>fullscreen_exit</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <ag-grid-angular style="width: 100%" class="ag-theme-alpine" [rowData]="rowData" [columnDefs]="columnDefs"
              [gridOptions]="scoreCardGridOptions" (cellClicked)="onScoreCardCellClicked($event)"
              (gridReady)="onScoreCardGridReady($event)" [loadingOverlayComponent]="loadingOverlayComponent"
              [frameworkComponents]="frameworkComponents" (firstDataRendered)="onScoreCardGridFirstDataRendered($event)"
              [allowContextMenuWithControlKey]="true" [getContextMenuItems]="getContextMenuItems"
              [sideBar]="{ toolPanels: ['columns'] }">
            </ag-grid-angular>
          </div>
        </ng-container>
        <ng-container *ngIf="!isExpanded">
          <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedTabIndex"
            (selectedTabChange)="tabGroup($event)">
            <mat-tab label="Scorecard" [disabled]="!menuPermission.Scorecard">
              <div>
                <div class="d-flex align-items-center justify-content-between" style="padding:10px 0">
                  <div class="filters">
                    <div class="frequency-wrapper">
                      <div style="margin-right: 7px;width: max-content;">
                        <h3>
                          {{
                          langObj
                          ? langObj?.KPIFrequency
                          : ("KPIFrequency" | translate)
                          }}
                        </h3>
                      </div>
                      <form [formGroup]="MeetingMatrixFrequencyForm">
                        <p-dropdown formControlName="frequency" defaultLabel="Select a Frequency" [ngClass]="{
                                 'is-invalid':
                                 MeetingMatrixFrequencyForm.get('frequency')
                                 ?.errors &&
                                 MeetingMatrixFrequencyForm.get('frequency')
                                 ?.touched
                                 }" [options]="frequencies" (onChange)="getGridDataByFrequency()"
                          placeholder="Select a Frequency" scrollHeight="300px" name="frequency"
                          optionLabel="frequencyName">
                        </p-dropdown>
                      </form>
                    </div>
                    <div class="glide-wrapper">
                      <mat-slide-toggle name="enableGlidePath" (change)="enableGlidePathChange($event)"
                        [(ngModel)]="enableGlidePath">
                        <h3>Glide path</h3>
                      </mat-slide-toggle>
                    </div>
                  </div>
                  <div class="content-top-row mt-0">
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 100% !important">
                      <mat-label>Date Range (Financial Gap)</mat-label>
                      <mat-date-range-input [formGroup]="rangeFinacialGap" [rangePicker]="picker">
                        <input matStartDate formControlName="fromDate" placeholder="Gap From Date" [readOnly]="true" />
                        <input matEndDate formControlName="toDate" placeholder="Gap To Date" [readOnly]="true" />
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker (closed)="onFinancialGapRangeChange()">
                      </mat-date-range-picker>
                    </mat-form-field>
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 100% !important">
                      <mat-label>Date Range (Scorecard)</mat-label>
                      <mat-date-range-input [formGroup]="range" [rangePicker]="picker1">
                        <input matStartDate formControlName="fromDate" placeholder="From Date" [readOnly]="true" />
                        <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true" />
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker1">
                      </mat-datepicker-toggle>
                      <mat-date-range-picker #picker1 (closed)="getGridDataByFrequency($event)"></mat-date-range-picker>
                    </mat-form-field>

                    <mat-spinner-button [options]="spinnerButtonOptions" (btnClick)="onClickParamterAnalysis()"
                      class="loader" id="selectedRows" style="margin: 10px 10px">
                    </mat-spinner-button>
                    <mat-spinner-button [options]="spinnerButtonOptions1" (btnClick)="onClickParamterComparsion(false)"
                      class="loader" id="selectedRows" style="margin: 10px 10px">
                    </mat-spinner-button>
                  </div>
                  <div class="toggle-actions">
                    <!-- <button mat-mini-fab mat-button mat-dialog-close pTooltip="Sort" tooltipPosition="top"
                      color="primary" (click)="sortGapColumn(sort)" style="margin-bottom: 4px">
                      <mat-icon>sort</mat-icon>
                    </button> -->
                    <button *ngIf="!isExpanded" mat-mini-fab mat-button mat-dialog-close color="accent"
                      pTooltip="Expand Grid" tooltipPosition="top" (click)="toggleView()" style="margin-bottom: 4px">
                      <mat-icon>fullscreen</mat-icon>
                    </button>
                    <button mat-mini-fab mat-button mat-dialog-close color="accent" pTooltip="Collapse Grid"
                      tooltipPosition="top" (click)="toggleView()" *ngIf="isExpanded" style="margin-bottom: 4px">
                      <mat-icon>fullscreen_exit</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <ag-grid-angular #scorcardgrid style="width: 100%;" class="ag-theme-alpine" [rowData]="rowData"
                  [columnDefs]="columnDefs" [gridOptions]="scoreCardGridOptions" (modelUpdated)="onModelUpdated()"
                  (cellClicked)="onScoreCardCellClicked($event)" (gridReady)="onScoreCardGridReady($event)"
                  [loadingOverlayComponent]="loadingOverlayComponent" [frameworkComponents]="frameworkComponents"
                  (firstDataRendered)="onScoreCardGridFirstDataRendered($event)" [allowContextMenuWithControlKey]="true"
                  [getContextMenuItems]="getContextMenuItems2"
                  [sideBar]="{ toolPanels: ['columns', 'filters'] ,sideBar}"
                  (columnRowGroupChanged)="columnRowGroupChanged($event)" [defaultColDef]="defaultColDef"
                  [rowSelection]="rowSelection" [rowMultiSelectWithClick]="true">
                  <!-- [sideBar]="{ toolPanels: ['columns', 'filters'] ,sideBar}" -->
                </ag-grid-angular>
              </div>
            </mat-tab>
            <mat-tab label="Talk Sheet" [disabled]="!menuPermission.Talksheet">
              <div class="Wrapper-row">
                <div id="rowWrap1" class="w-100">
                  <div class="action-btn">
                    <form class="sub-container" [formGroup]="MeetingMatrixTalkSheetForm">
                      <div classs="frequency-btn-talk-sheet">
                        <!-- <h3>{{
                                    langObj
                                    ? langObj?.KPIFrequency
                                    : ("KPIFrequency" | translate)
                                    }}</h3> -->
                        <p-dropdown formControlName="tsfrequency" defaultLabel="Select a Frequency" [ngClass]="{
                                 'is-invalid':
                                 MeetingMatrixTalkSheetForm.get('tsfrequency')
                                 ?.errors &&
                                 MeetingMatrixTalkSheetForm.get('tsfrequency')
                                 ?.touched
                                 }" [options]="frequencies" (onChange)="onSearchScorecard()"
                          placeholder="Select a Frequency" scrollHeight="300px" name="tsfrequency"
                          optionValue="frequencyId" optionLabel="frequencyName" [(ngModel)]="defaultfrequencies">
                        </p-dropdown>
                      </div>
                      <div class="content-top-row mt-0">
                        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                          style="width: 270px !important">
                          <mat-label>Date Range</mat-label>
                          <mat-date-range-input [rangePicker]="picker3">
                            <input matStartDate formControlName="fromDate" placeholder="From Date" [readOnly]="true" />
                            <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true"
                              (dateChange)="getData()" />
                          </mat-date-range-input>
                          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                          <mat-date-range-picker #picker3></mat-date-range-picker>
                        </mat-form-field>
                      </div>
                      <button mat-mini-fab color="accent" matTooltip="Expand" matTooltipPosition="above"
                        (click)="expandAll()" style="margin-left: 14px">
                        <mat-icon style="color: white;transform: rotate(40deg);">unfold_more</mat-icon>
                      </button>
                      <button mat-mini-fab color="accent" matTooltip="Collapse" matTooltipPosition="above"
                        (click)="collapseAll()" style="margin-left: 14px">
                        <mat-icon style="color: white;transform: rotate(40deg);">unfold_less</mat-icon>
                      </button>
                      <button mat-mini-fab color="primary" matTooltip="Search" matTooltipPosition="above"
                        (click)="onSearchScorecard()" style="margin-left: 10px">
                        <mat-icon style="color: white">search</mat-icon>
                      </button>
                      <mat-spinner-button [options]="spinnerButtonOptions"
                        (btnClick)="onDateChangeInKPIAnaModalForTalkSheet()" class="loader" id="selectedRows"
                        style="margin: 10px 10px">
                      </mat-spinner-button>
                      <mat-spinner-button [options]="spinnerButtonOptions1"
                        (btnClick)="onClickParamterComparsionTalkSheet(false)" class="loader" id="selectedRows"
                        style="margin: 10px 10px">
                      </mat-spinner-button>
                      <div class="glide-wrapper">
                        <mat-slide-toggle name="enableGlidePathTalkSheet" (change)="enableGlidePathChangeV2($event)"
                          [checked]="enableGlidePathTalkSheet">
                          <h3>Glide path</h3>
                        </mat-slide-toggle>
                      </div>
                    </form>
                  </div>
                  <ag-grid-angular style="width: 100%; height: 20vw" class="ag-theme-alpine" [pagination]="true"
                    [girdOptions]="gridOptionsKPI" [paginationAutoPageSize]="5" [columnDefs]="columnDefskpi"
                    [rowData]="rowDatakpi" (gridReady)="onkpiGridReady($event)" [defaultColDef]="defaultColDefkpi"
                    (cellClicked)="cellClickedKPITable($event)" rowSelection="multiple"
                    [getContextMenuItems]="getContextMenuItems" [groupHideOpenParents]="true"
                    (selectionChanged)="onRowsSelection($event)" (columnResized)="onColumnResized($event)"
                    [maintainColumnOrder]="true" [autoGroupColumnDef]="autoGroupColumnDef">
                  </ag-grid-angular>
                </div>
                <!-- <div id="rowWrap2" class="w-100 mt-4"  >
                        <div class="w-100" *ngIf="rowDatakpi.length">
                          <div [chart]="stock"></div>
                        </div>
                        </div> -->
                <div id="rowWrap3" class="w-100">
                  <div class="action-btn">
                    <button mat-flat-button class="btn-tree-control" matTooltip="Collapse"
                      matTooltipClass="custom-tooltip" matTooltipPosition="above" (click)="onclickCollapse(3)"
                      *ngIf="showMore[3]">
                      <div class="icon">
                        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.5 1.1547C13.7376 0.440169 15.2624 0.440169 16.5 1.1547L25.0574 6.0953C26.295 6.80983 27.0574 8.13034 27.0574 9.5594V19.4406C27.0574 20.8697 26.295 22.1902 25.0574 22.9047L16.5 27.8453C15.2624 28.5598 13.7376 28.5598 12.5 27.8453L3.94263 22.9047C2.70503 22.1902 1.94263 20.8697 1.94263 19.4406V9.5594C1.94263 8.13034 2.70503 6.80983 3.94263 6.0953L12.5 1.1547Z"
                            fill="#824AED" />
                          <g clip-path="url(#clip0)">
                            <path
                              d="M14.0118 14.9844C13.933 14.897 13.8218 14.8399 13.6947 14.8395L10.1018 14.8345C9.86386 14.8341 9.67135 15.0265 9.67177 15.2645C9.67218 15.5024 9.94967 15.7699 10.0688 15.889L10.9776 16.7978L8.33301 19.417L9.58301 20.667L12.1888 18.0095L13.0976 18.9182C13.2942 19.1149 13.4938 19.3241 13.7317 19.3244C13.9697 19.3249 14.1622 19.1324 14.1617 18.8945L14.1568 15.3015C14.1563 15.1745 14.0993 15.0632 14.0118 14.9844ZM19.0763 11.1565L21.6663 8.58362L20.4163 7.33362L17.8442 9.91654L16.9201 8.98654C16.7201 8.78527 16.5172 8.57111 16.2755 8.5707C16.0334 8.57028 15.838 8.76737 15.838 9.0107L15.843 12.6882C15.843 12.8182 15.9013 12.9319 15.9905 13.0128C16.0709 13.1024 16.1838 13.1607 16.313 13.1611L19.9672 13.1661C20.2088 13.1665 20.4047 12.9694 20.4047 12.7261C20.4043 12.4828 20.1222 12.2086 20.0009 12.0869L19.0763 11.1565Z"
                              fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="13.3333" height="13.3333" fill="white"
                                transform="translate(8.33301 7.33362)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </button>
                  </div>
                  <ag-grid-angular style="width: 100%; height: 20vw" class="ag-theme-alpine" [pagination]="true"
                    [paginationAutoPageSize]="5" [columnDefs]="columnDefsallAction" [rowData]="overallMeetingActions"
                    (cellClicked)="cellClickedActionTable($event)">
                  </ag-grid-angular>
                </div>
                <div id="rowWrap4" class="w-100">
                  <div class="action-btn">
                    <button mat-flat-button class="btn-tree-control" matTooltip="Collapse"
                      matTooltipClass="custom-tooltip" matTooltipPosition="above" (click)="onclickCollapse(4)"
                      *ngIf="showMore[4]">
                      <div class="icon">
                        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.5 1.1547C13.7376 0.440169 15.2624 0.440169 16.5 1.1547L25.0574 6.0953C26.295 6.80983 27.0574 8.13034 27.0574 9.5594V19.4406C27.0574 20.8697 26.295 22.1902 25.0574 22.9047L16.5 27.8453C15.2624 28.5598 13.7376 28.5598 12.5 27.8453L3.94263 22.9047C2.70503 22.1902 1.94263 20.8697 1.94263 19.4406V9.5594C1.94263 8.13034 2.70503 6.80983 3.94263 6.0953L12.5 1.1547Z"
                            fill="#824AED" />
                          <g clip-path="url(#clip0)">
                            <path
                              d="M14.0118 14.9844C13.933 14.897 13.8218 14.8399 13.6947 14.8395L10.1018 14.8345C9.86386 14.8341 9.67135 15.0265 9.67177 15.2645C9.67218 15.5024 9.94967 15.7699 10.0688 15.889L10.9776 16.7978L8.33301 19.417L9.58301 20.667L12.1888 18.0095L13.0976 18.9182C13.2942 19.1149 13.4938 19.3241 13.7317 19.3244C13.9697 19.3249 14.1622 19.1324 14.1617 18.8945L14.1568 15.3015C14.1563 15.1745 14.0993 15.0632 14.0118 14.9844ZM19.0763 11.1565L21.6663 8.58362L20.4163 7.33362L17.8442 9.91654L16.9201 8.98654C16.7201 8.78527 16.5172 8.57111 16.2755 8.5707C16.0334 8.57028 15.838 8.76737 15.838 9.0107L15.843 12.6882C15.843 12.8182 15.9013 12.9319 15.9905 13.0128C16.0709 13.1024 16.1838 13.1607 16.313 13.1611L19.9672 13.1661C20.2088 13.1665 20.4047 12.9694 20.4047 12.7261C20.4043 12.4828 20.1222 12.2086 20.0009 12.0869L19.0763 11.1565Z"
                              fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="13.3333" height="13.3333" fill="white"
                                transform="translate(8.33301 7.33362)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </button>
                  </div>
                  <div [chart]="chart"></div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Dashboard" [disabled]="!menuPermission.Dashboard">
              <mat-card style="width: 100%">
                <form [formGroup]="chartrange">
                  <div class="content-top-row">
                    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                      style="width: 200px; margin-left: 80%">
                      <mat-label>Date Range </mat-label>
                      <mat-date-range-input [rangePicker]="picker2">
                        <input matStartDate formControlName="fromDate" placeholder="GFrom Date" [readOnly]="true" />
                        <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true" />
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker2></mat-date-range-picker>
                    </mat-form-field>
                  </div>
                </form>
                <div class="chart-group">
                  <!-- <div class="chart-items" style="width: 98%">
                    <div [chart]="progresscontainer"></div>
                  </div> -->
                  <div class="chart-items" style="width: 98%">
                    <div [chart]="schedulecontainer"></div>
                  </div>
                  <div class="chart-items">
                    <div [chart]="attendancecontainer"></div>
                  </div>
                  <div class="chart-items">
                    <div [chart]="effectivenesscontainer"></div>
                  </div>
                  <!-- <div class="chart-items" style="width: 98%">
                    <div [chart]="schedulecontainer"></div>
                  </div> -->
                </div>
              </mat-card>
              <div class="chart-group">
                <div class="chart-items" style="width: 100%">
                  <div #stackChart id="test"></div>
                </div>
                <div class="chart-items" style="width: 100%">
                  <div #stackChartPercentage id="test"></div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Safety & ENV" [disabled]="!menuPermission['Safety & ENV']">
              <div class="content-row-wrap">
                <button type="button" mat-flat-button color="accent" *ngFor="let line of lines"
                  (click)="onClickLines(line.PL_Id)" class="back-button" style="
                     margin-left: 14px;
                     margin-bottom: 10px;
                     width: fit-content;
                     ">
                  <p class="btn-text">{{ line.PL_Desc }}</p>
                </button>
                <button type="button" mat-flat-button color="accent" (click)="SaveMeeting()" class="back-button" style="
                        margin-left: 14px;
                        margin-bottom: 10px;
                        width: fit-content;
                        ">
                  <p class="btn-text">Save Meeting</p>
                </button>
              </div>
              <ag-grid-angular #ScoreGrid id="ScoreGrid" style="width: 100%" class="ag-theme-alpine"
                [columnDefs]="columnDefscorecard" [readOnly]="false" (gridReady)="onScoreGridReady($event)"
                (cellClicked)="onCellClickedMeeting($event)" [rowData]="rowDatasscoreCard"
                [suppressRowTransform]="true">
              </ag-grid-angular>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
      </div>
      <div id="wrapper3" class="content-hide">
        <div class="d-flex justify-content-end align-items-center">
          <div>
            <button type="button" (click)="openMeetingSuggestedModal()" mat-flat-button color="warn"
              style="margin-left: 14px">
              {{
              langObj
              ? langObj?.SuggestedActions
              : ("SuggestedActions" | translate)
              }}
            </button>
            <button type="button" (click)="toggleMeetingEvaluationReverse()" class="back-button" mat-flat-button
              color="accent" matBadgePosition="after" matBadgeColor="accent" MatBadgeSize="large"
              style="margin-left: 14px">
              <p class="btn-text">
                <i class="fa fa-arrow-left" style="color: #fff; margin-right: 9px"></i>{{ langObj ? langObj?.Previous :
                ("Previous" | translate) }}
              </p>
            </button>
          </div>
        </div>
        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
          [stroke]="chartOptions.stroke" [colors]="chartOptions.colors" [dataLabels]="chartOptions.dataLabels"
          [legend]="chartOptions.legend" [markers]="chartOptions.markers" [grid]="chartOptions.grid"
          [yaxis]="chartOptions.yaxis" [title]="chartOptions.title"></apx-chart>
        <div class="maxWidth">
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="meetingEvaluationData">
              <ng-container matColumnDef="Date">
                <th mat-header-cell *matHeaderCellDef>
                  {{ langObj ? langObj?.Date : ("Date" | translate) }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.Date | date: "dd-MM-yyyy HH:mm:ss" }}

                </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="Score">
                <th mat-header-cell *matHeaderCellDef [hidden]="hideScore">
                  {{ langObj ? langObj?.Score : ("Score" | translate) }}
                </th>
                <td mat-cell *matCellDef="let element" [hidden]="hideScore">{{ element.Score }}</td>
              </ng-container>
              <!-- Symbol Column -->
              <ng-container matColumnDef="Target">
                <th mat-header-cell *matHeaderCellDef [hidden]="hideTargetLevel" id="TargetLevel">
                  {{ langObj ? langObj?.Target : ("Target" | translate) }}
                </th>
                <td mat-cell [hidden]="hideTargetLevel" *matCellDef="let element">
                  {{ element.Target || " " }}
                </td>
              </ng-container>
              <ng-container matColumnDef="Level">
                <th mat-header-cell *matHeaderCellDef [hidden]="!hideTargetLevel">
                  {{ langObj ? langObj?.Level : ("Level" | translate) }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.Level || " " }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="meetingEvalutionColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: meetingEvalutionColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="3">
                  {{
                  langObj ? langObj?.nodatamatch : ("nodatamatch" | translate)
                  }}
                </td>
              </tr>
            </table>
            <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #displayRoleModal></ng-template>

<ng-template #displayUserModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>
        {{
        langObj ? langObj?.AddParticipants : ("AddParticipants" | translate)
        }}
      </h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <form [formGroup]="ManualParticepantForm">
      <div class="content-top-row">
        <mat-form-field class="top-row-first-filed-item" style="width: 40% !important">
          <mat-select formControlName="participant" placeholder="Meeting Participants">
            <mat-select-filter [placeholder]="'Search'" [array]="UserDDL"
              (filteredReturn)="filteredParticipants = $event" [displayMember]="'fullName'">
            </mat-select-filter>
            <mat-option *ngFor="let o of filteredParticipants" [value]="o.EmpInfo_Id">{{ o.fullName }}
            </mat-option>
          </mat-select>
          <mat-error style="padding-bottom: 50px">{{
            langObj ? langObj?.meetingpartic : ("meetingpartic" | translate)
            }}
          </mat-error>
        </mat-form-field>

        <button [disabled]="!ManualParticepantForm.valid" type="submit" (click)="addManualPartcipent()" mat-mini-fab
          mat-button color="primary" matTooltip="Add Special Attendees" matTooltipClass="custom-tooltip"
          matTooltipPosition="above" style="margin-left: 15px">
          <i class="fa fa-plus" style="color: #fff"></i>
        </button>

      </div>
    </form>
    <div class="p-grid p-fluid d-flex" style="margin-top: 20px">
      <div class="p-xl-4 p-lg-4 p-md-4 p-col-6">
        <h2>
          {{ langObj ? langObj?.Participant : ("Participant" | translate) }}
        </h2>
      </div>
      <div class="p-xl-8 p-lg-8 p-md-8 p-col-6 d-flex justify-content-center">
        <h2 style="margin-right: 10px">Select All</h2>
        <mat-checkbox color="accent" [checked]="selectedAll" (change)="selectAllParticipants($event)"
          style="margin-top: 5px">
        </mat-checkbox>
      </div>
    </div>
    <div class="participents-container">
      <div *ngFor="let part of meetingParticipants; let i = index">
        <div class="p-grid p-fluid">
          <div class="p-lg-4 p-xl-4 p-md-4 p-col-4">
            <div>
              <p style="font-size: 18px; overflow-wrap: break-word">
                <strong>{{ i + 1 }}</strong> -
                {{ part.participantName }}
              </p>
            </div>
          </div>
          <div class="p-lg-8 p-xl-8 p-md-8 p-col-8 d-flex">
            <mat-radio-group (change)="participantAttended($event, part.participantId)" [checked]="part.reason"
              [(ngModel)]="part.reason" style="width: 60%;">
              <mat-radio-button value="ontime">{{ langObj ? langObj?.OnTime : ("OnTime" | translate) }}
              </mat-radio-button>
              <mat-radio-button value="late">{{ langObj ? langObj?.Late : ("Late" | translate) }}
              </mat-radio-button>
              <mat-radio-button value="excluded">{{ langObj ? langObj?.Excluded : ("Excluded" | translate) }}
              </mat-radio-button>
              <mat-radio-button value="onLeave">{{ langObj ? langObj?.OnLeave : ("OnLeave" | translate) }}
              </mat-radio-button>
            </mat-radio-group>

            <!-- <i class="material-icons" style="font-size: 30px;color:#ff0019f3">delete</i> -->
            <div *ngIf="part.reason === 'late'" style="width: 17%;">
              <div class="showDiv">
                <mat-form-field appearance="fill" class="minute-field" style="margin-top: -18px;width: 88% !important;">
                  <mat-label>Late Min</mat-label>
                  <input matInput [(ngModel)]="part.lateValue">
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="part.Special == true" style="width: 30%;">
              <div class="showDiv">
                <button type="submit" (click)="RemoveSpecialPartcipent(part.participantId)" mat-mini-fab mat-button
                  color="warn" matTooltip="Remove Special Meeting Attendees" matTooltipClass="custom-tooltip"
                  matTooltipPosition="above" style="margin-left: 15px;margin-top: -10px">
                  <i class="material-icons" style="font-size: 24px;color:#fafafa">delete</i>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </mat-dialog-content>
  <div class="d-flex justify-content-between align-items-center">
    <button type="submit" (click)="AddParticipantAttendMeeting()" mat-mini-fab mat-button color="primary"
      matTooltip="Save Participants" matTooltipClass="custom-tooltip" matTooltipPosition="above"
      style="margin-left:  90%;" [disabled]="!meetingStarted">
      <i class="fa fa-floppy-o" style="color: #fff"></i>
    </button>
  </div>
</ng-template>

<ng-template #agendasViewSheet>
  <div class="d-flex justify-content-between align-items-center">
    <h2>
      {{ langObj ? langObj?.MeetingAgendas : ("MeetingAgendas" | translate) }}
    </h2>
    <button mat-mini-fab mat-button color="warn" title="close" (click)="closeSheet($event)">
      <i class="fa fa-times" style="color: #fff"></i>
    </button>
  </div>
  <mat-dialog-content>
    <form [formGroup]="AddAgendaForm">
      <div>
        <div>
          <div #inputRef class="d-flex justify-content-between align-items-center" style="margin-top: 20px"
            style="width: 60%">
            <mat-form-field appearance="fill" class="field-fill" style="width: 90% !important">
              <mat-label class="required">{{
                langObj ? langObj?.MeetingAgenda : ("MeetingAgenda" | translate)
                }}
              </mat-label>
              <input matInput placeholder="Enter Meeting Agenda" formControlName="agenda" />
            </mat-form-field>
            <button type="button" (click)="saveMeetingAgenda()" mat-mini-fab mat-button color="primary"
              matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"
              style="margin-left: 20px; margin-bottom: 20px" [disabled]="AddAgendaForm.invalid">
              <i class="fa fa-floppy-o" style="color: #fff"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="p-grid d-flex justify-content-start" *ngFor="let meeting of meetingAgendasArray; let i = index"
      style="width: 100%; margin-bottom: 15px; background-color: #cffaee">
      <div class="p-col-11">{{ i + 1 }} - {{ meeting.agenda }}</div>
      <div class="p-col-1" style="text-align: end; padding: 0px">
        <mat-icon (click)="removeAgendaRow(meeting.agenda)" style="color: red; cursor: pointer; margin: 5px">
          delete
        </mat-icon>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>
<ng-template #notesAndAgenda>
  <div class="d-flex justify-content-end align-items-center">
    <button mat-mini-fab mat-button color="warn" title="close" (click)="closeSheet($event)">
      <i class="fa fa-times" style="color: #fff"></i>
    </button>
  </div>
  <mat-dialog-content>
    <mat-tab-group mat-align-tabs="start">
      //meeting agenda tab
      <mat-tab [label]="
           langObj ? langObj?.MeetingAgendas : ('MeetingAgendas' | translate)
           ">
        <form [formGroup]="AddAgendaForm" style="margin-top: 20px">
          <div>
            <div>
              <div #inputRef class="d-flex justify-content-between align-items-center" style="margin-top: 20px"
                style="width: 60%">
                <mat-form-field appearance="fill" class="field-fill" style="width: 90% !important">
                  <mat-label class="required">{{
                    langObj
                    ? langObj?.MeetingAgenda
                    : ("MeetingAgenda" | translate)
                    }}
                  </mat-label>
                  <input matInput placeholder="Enter Meeting Agenda" formControlName="agenda" />
                </mat-form-field>
                <button type="button" (click)="saveMeetingAgenda()" mat-mini-fab mat-button color="primary"
                  matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"
                  style="margin-left: 20px; margin-bottom: 20px" [disabled]="AddAgendaForm.invalid">
                  <i class="fa fa-floppy-o" style="color: #fff"></i>
                </button>
              </div>
            </div>
          </div>
        </form>

        <div class="p-grid d-flex justify-content-start" *ngFor="let meeting of meetingAgendasArray; let i = index"
          style="width: 100%; margin-bottom: 15px; background-color: #cffaee">
          <div class="p-col-11">{{ i + 1 }} - {{ meeting?.agenda }}</div>
          <div class="p-col-1" style="text-align: end; padding: 0px">
            <mat-icon (click)="removeAgendaRow(meeting.agenda)" style="color: red; cursor: pointer; margin: 5px">
              delete
            </mat-icon>
          </div>
        </div>
      </mat-tab>
      <!-- //meeting notes tab -->
      <mat-tab label="Meeting Notes">
        <form [formGroup]="AddNotesForm" style="margin-top: 20px">
          <div>
            <div>
              <div #inputRef class="d-flex justify-content-between align-items-center" style="margin-top: 20px"
                style="width: 60%">
                <mat-form-field appearance="fill" class="field-fill" style="width: 90% !important">
                  <mat-label class="required">Meeting Note(s)</mat-label>
                  <input matInput placeholder="Enter Meeting Notes" formControlName="notes" />
                </mat-form-field>
                <button type="button" (click)="saveMeetingNotes()" mat-mini-fab mat-button color="primary"
                  matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"
                  style="margin-left: 20px; margin-bottom: 20px" [disabled]="AddNotesForm.invalid">
                  <i class="fa fa-floppy-o" style="color: #fff"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
        <div class="p-grid d-flex justify-content-start" *ngFor="let note of meetingNotes; let i = index"
          style="width: 100%; margin-bottom: 15px; background-color: #cffaee">
          <div class="p-col-11" style="overflow-x: auto">
            {{ i + 1 }} - {{ note?.Notes }}
          </div>
          <div class="p-col-1" style="text-align: end; padding: 0px">
            <mat-icon (click)="removeAgendaRow(note?.ID)" style="color: red; cursor: pointer; margin: 5px">
              delete
            </mat-icon>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
</ng-template>
<ng-template #displayLastMeetingModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Last Meeting Actions</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <!-- <ag-grid-angular
         style="width: 100%; height: 30vw"
         class="ag-theme-alpine"
         [columnDefs]="actionscolumnDefs"
         [rowData]="actionsrowData"
         [pagination]="true"
         [paginationPageSize]="10"
         >
         </ag-grid-angular> -->
    <ag-grid-angular style="width: 100%; height: 20vw" class="ag-theme-alpine" [pagination]="true"
      [paginationAutoPageSize]="5" [columnDefs]="columnDefsallAction" [rowData]="overallMeetingActions"
      (cellClicked)="cellClickedActionTable($event)">
    </ag-grid-angular>
  </mat-dialog-content>
</ng-template>
<ng-template #suggestedActionModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>
        {{
        langObj ? langObj?.SuggestedActions : ("SuggestedActions" | translate)
        }}
      </h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <form [formGroup]="meetingSuggestedActionForm">
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-top-row">
          <mat-form-field class="top-row-first-filed-item">
            <mat-label class="required">{{
              langObj ? langObj?.Action : ("Action" | translate)
              }}
            </mat-label>
            <input matInput formControlName="action" placeholder="add suggested action" />
            <mat-error>{{
              langObj ? langObj?.Required : ("Required" | translate)
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>{{
              langObj
              ? langObj?.SelectResponsible
              : ("SelectResponsible" | translate)
              }}
            </mat-label>
            <mat-select formControlName="responsibleId">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'fullName'" [array]="UserDDL"
                (filteredReturn)="filteredUsers = $event"></mat-select-filter>
              <mat-option *ngFor="let Own of filteredUsers" [value]="Own.EmpInfo_Id">{{ Own.fullName }}
              </mat-option>
            </mat-select>
            <!-- <mat-error>{{langObj ? langObj?.res : ('res' | translate)}</mat-error> -->
          </mat-form-field>
          <div class="inputField">
            <nz-date-picker class="calender-input" id="startPicker" formControlName="completionDate"
              nzFormat="dd/MM/yyyy" [nzInputReadOnly]="true"></nz-date-picker>
            <span class="floating-text">{{
              langObj ? langObj?.CompletionDate : ("CompletionDate" | translate)
              }}</span>
          </div>
          <button mat-mini-fab color="accent" [disabled]="!meetingSuggestedActionForm.valid"
            matTooltip="Add Meeting Actions" matTooltipPosition="above" (click)="addSuggestedAction()"
            style="margin-left: 14px">
            <mat-icon style="color: white">add_circle_outline</mat-icon>
          </button>
        </div>
      </div>
    </form>
    <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="meetingSuggestedActions"
      (gridReady)="onGridReady($event)" [columnDefs]="columnDefsSuggested" [gridOptions]="gridOptions"
      (cellValueChanged)="onCellSuggestedValueChanged($event)" [pagination]="true">
    </ag-grid-angular>
  </mat-dialog-content>
</ng-template>
<ng-template #qualityBoolListModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>{{ selectionName }} Selection</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row" style="height: auto !important">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="width: 100% !important">
          <mat-label>Select {{ selectionName }}</mat-label>
          <mat-select [(ngModel)]="qualityBoolVal">
            <mat-option *ngFor="let value of boolList" [value]="value">
              {{ value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-mini-fab mat-button color="primary" title="Save Color" style="margin-left: 10px"
          (click)="saveStatus(qualityBoolVal)">
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>
<ng-template #displaytierMeetingModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>TIER Meeting Effectiveness Checklist</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="chartpopup2">
    <ag-grid-angular class="ag-theme-alpine" [suppressRowTransform]="true" [columnDefs]="tierMeetingColumnDefs1"
      [rowData]="tierMeetingRowData" (gridReady)="onTierGridReady($event)" [alwaysShowVerticalScroll]="true"
      (cellDoubleClicked)="onTierGridCellDoubleClicked($event)" (cellClicked)="onTierGridCellClicked($event)"
      [getRowHeight]="getTierGridRowHeight" (cellValueChanged)="onTierGridCellValueChanged($event)"
      style="width: 100%; height: 100%">
    </ag-grid-angular>
  </div>
  <div mat-dialog-actions>
    <div class="totalscore_box_wrapper">
      <button mat-flat-button color="accent" (click)="saveAction()" class="btn-accent-big" pTooltip="Save meeting"
        [disabled]="!enabledopenTierMeeting">
        Save Record
      </button>
      <div class="totalscore_box">
        <p class="totalscore-title">Total Score:</p>
        <p class="totalscore-value">{{ this.totalscore | number: "1.2-2" }}%</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #displayMeetingPendingModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h4>Meeting Status</h4>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff; margin-top: -7px"></i>
      </button>
    </div>
  </div>
  <ag-grid-angular style="width: 100%; height: 80%; margin-top: 30px" class="ag-theme-alpine" [rowData]="rowData3"
    [columnDefs]="columnDefs3" (cellClicked)="oncellclicked($event)" rowSelection="multiple"
    (gridReady)="onGridReady($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="30">
  </ag-grid-angular>
</ng-template>

<ng-template #actionPlanModal>
  <form [formGroup]="editActionPlanForm">
    <div mat-dialog-title>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2> {{ langObj ? langObj?.UpdateAction : ("UpdateAction" | translate) }}</h2>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>
    </div>
    <mat-dialog-content>
      <div class="content-row-wrap" style="margin-bottom: 20px">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.RootCause : ('SelectNewAssignee' | translate)}}</mat-label>
          <input matInput placeholder="Enter Remarks" formControlName="rootcause" />
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.Action : ('Action' | translate)}} *</mat-label>
          <input matInput placeholder="Enter Action Name" formControlName="actions" [(ngModel)]="data.Action" />
        </mat-form-field>
        <div class="radio-wrapper">
          <mat-label>{{langObj ? langObj?.ActionType : ('ActionType' | translate)}} *:</mat-label>
          <mat-radio-group formControlName="actionType" class="radio-group" name="ActionType">
            <mat-radio-button value="Preventive">Preventive</mat-radio-button>
            <mat-radio-button value="Corrective">Corrective</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="imgFlex-Wrapper">
          <mat-label>{{langObj ? langObj?.ChooseImage : ('ChooseImage' | translate)}}</mat-label>
          <div class="imgflex" style="position: relative">
            <div title="image" style="display: flex">
              <img *ngIf="data.FileSrc || data.UploadFile" (click)="imageZoomModal()"
                [src]="data.FileSrc || blobStorageUrl + data.UploadFile" alt="image" width="60px" height="40px" />
            </div>
            <img *ngIf="data.FileSrc || data.UploadFile" src="assets/images/delete.png" (click)="deleteFile('pic')"
              class="imgabsol" alt="" width="15px" height="15px" style="position: absolute; left: 0; top: 0" />
            <button mat-flat-button color="accent" type="button" class="upload" (click)="openFile('pic')">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" (change)="readFile($event, 'pic')" class="d-none" #fileInput accept="image/*" />
          </div>
        </div>
        <div class="imgFlex-Wrapper">
          <mat-label>{{langObj ? langObj?.ChooseFile : ('ChooseFile' | translate)}}</mat-label>
          <div class="imgflex" style="position: relative">
            <div title="pdf" style="display: flex">
              <a *ngIf="data.DocSrc || data.UploadDoc" (click)="downloadPDF()"><img src="assets/images/file_img.png"
                  class="pdfimg" alt="pdf" width="60px" /></a>
            </div>
            <img *ngIf="data.DocSrc || data.UploadDoc" src="assets/images/delete.png" (click)="deleteFile('file')"
              class="imgabsol" alt="" width="15px" height="15px" style="position: absolute; left: 0; top: 0" />
            <button mat-flat-button color="accent" type="button" class="upload" (click)="openFile(i, 'file')"
              [disabled]="data.Status == 'Closed'">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" (change)="readFile($event, 'file')" class="d-none" #fileInputFile
              accept="application/pdf,application/vnd.ms-excel" />
          </div>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.SelectAssignee : ('SelectAssignee' | translate)}} *</mat-label>
          <mat-select formControlName="assignTo" [disabled]="data.Status == 'Closed' || !data.Action">
            <mat-select-filter [placeholder]="'Search User'" [displayMember]="'FullName'" [array]="participantsByPlants"
              (filteredReturn)="userList = $event">
            </mat-select-filter>
            <mat-option *ngFor="let user of userList" [value]="user?.EmpInfo_Id">
              {{ user?.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="inputField" style="margin-top: 15px; width: 17%">
          <!-- <nz-date-picker class="calender-input"  nzFormat="dd/MM/yyyy"
                  [nzInputReadOnly]="true" style="width: 100%; margin-left: 0px !important;"
                  [nzDisabledDate]="getMinDate('Target')" [disabled]="'true'"  [(ngModal)] = "data.TargetDate"></nz-date-picker> -->
          <nz-date-picker class="calender-input" style="margin-left: 0px !important; width: 100%"
            [nzInputReadOnly]="true" [nzShowTime]="false" nzFormat="yyyy-MM-dd" [nzDisabledDate]="getMinDate('Target')"
            formControlName="targetDate" (nzOnCalendarChange)="targetChange()"
            [nzDisabled]="data.Status != 'Open' || !data.AssignTo" [(ngModel)]="data.TargetDate">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.TargetDate : ('TargetDate' | translate)}} *</span>
        </div>
        <div class="inputField" style="margin-top: 15px; width: 17%">
          <nz-date-picker class="calender-input" formControlName="revisedDate" nzFormat="yyyy-MM-dd"
            [nzInputReadOnly]="true" style="width: 100%; margin-left: 0px !important"
            [nzDisabledDate]="getMinDate('Revised')" [nzDisabled]="
               (data.Status != 'InProgress' && data.Status == 'Open') ||
               data.Status == 'Complete' ||
               data.Status == 'Closed'
               ">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.RevisedDate : ('RevisedDate' | translate)}}</span>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" [ngClass]="{
               'disabled-input':
               data.Status == 'Closed' ||
               !data.TargetDate ||
               data.Status == 'Complete'
               }">
          <mat-label>{{langObj ? langObj?.SelectCompleted : ('SelectCompleted' | translate)}}</mat-label>
          <mat-select formControlName="completed" (selectionChange)="onChangeComplete()" [disabled]="
               data.Status == 'Closed' ||
               !data.TargetDate ||
               data.Status == 'Complete'
               ">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          [ngClass]="{ 'disabled-input': af?.completed?.value == 'No' }">
          <mat-label>{{langObj ? langObj?.SelectVerfication : ('SelectVerfication' | translate)}}</mat-label>
          <mat-select formControlName="verification" [disabled]="af?.completed?.value == 'No'"
            (selectionChange)="onChangeVerification()">
            <mat-option value="">None</mat-option>
            <mat-option value="Approved">Approved</mat-option>
            <mat-option value="Disapproved">Disapproved</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          [ngClass]="{ 'disabled-input': af?.completed?.value == 'No' }">
          <mat-label>{{langObj ? langObj?.SelectNewAssignee : ('SelectNewAssignee' | translate)}}</mat-label>
          <mat-select [disabled]="af?.completed?.value == 'No'" formControlName="NewAssignee">
            <mat-select-filter [placeholder]="'Search New Assignee'" [displayMember]="'FullName'"
              [array]="participantsByPlants" (filteredReturn)="userList = $event">
            </mat-select-filter>
            <mat-option *ngFor="let user of userList" [value]="user.EmpInfo_Id">
              {{ user?.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="p-xl-4 uploadinside" *ngIf="showinput" style="width: fit-content">
          <input placeholder="Choose Close File" type="file" (change)="uploadedFile($event)" formControlName="closefile"
            class="form-control" style="height: 48.31px; width: 219px; margin-top: 8px" />
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}</mat-label>
          <input matInput placeholder="Enter Remarks" formControlName="remarks" />
        </mat-form-field>
        <button mat-mini-fab mat-button color="primary" matTooltip="Update" (click)="UpdateActionPlan()"
          matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px; margin-top: 20px">
          <i class="fa fa-pencil" style="color: #fff"></i>
        </button>
      </div>
    </mat-dialog-content>
  </form>
</ng-template>

<ng-template #analysisChart style="padding-bottom: 0px !important" id="mat-analysis-dialog">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>KPI Analysis</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content class="chartpopup" id="mat-analysis-dialog">
    <form [formGroup]="KPIAnalysisForm">
      <div class="d-flex" style="grid-gap: 10px">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 200px">
          <mat-label>Date Range (Analysis)</mat-label>
          <mat-date-range-input [rangePicker]="pickerForAnalysis">
            <input matStartDate formControlName="fromDate" placeholder="From Date" [readOnly]="true" />
            <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerForAnalysis"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerForAnalysis (closed)="onKPIAnalysisRangeChange()"></mat-date-range-picker>
        </mat-form-field>
        <mat-slide-toggle (change)="chartType = !chartType">Histogram</mat-slide-toggle>
      </div>
    </form>
    <!-- center align the div -->
    <div class="d-flex  justify-content-center" *ngIf="isLoadingKPIAnalysis">

      <mat-spinner></mat-spinner>
    </div>

    <ng-container *ngIf="!isLoadingKPIAnalysis">
      <ng-container *ngIf="chartType">
        <div *ngFor="let item of chartArray">
          <div [id]="item.id" [chart]="item.chartOption">
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!chartType">
        <div *ngFor="let item of chartArrayForAnalysis">
          <div [id]="item.id" [chart]="item.chartOption">
          </div>
        </div>
      </ng-container>
    </ng-container>
  </mat-dialog-content>
</ng-template>

<ng-template #visibleParameterComparison style="padding-bottom: 0px !important">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>KPI Comparison</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content class="chartpopup">
    <!-- <form > -->
    <div class="d-flex" style="grid-gap: 10px">
      <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 200px">
        <mat-label>Date Range (Comparison)</mat-label>
        <mat-date-range-input [formGroup]="KPIComparisonForm" [rangePicker]="pickerComparison">
          <input matStartDate formControlName="fromDate" placeholder="From Date" [readOnly]="true" />
          <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="pickerComparison"></mat-datepicker-toggle>
        <mat-date-range-picker #pickerComparison (closed)="onClickParamterComparsion(true)"></mat-date-range-picker>
      </mat-form-field>
      <div style="margin-left: 20px;">
        <button *ngFor="let u of uniqueUoms" [disabled]="u.disabled" (click)="GetRawMaterialParameters(u)"
          [style]="u == individualUom ? 'color: #fff; padding: 5px 25px;background-color: rgb(56 210 173);background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);border-radius: 14px;font-weight: bold;font-size: 16px;border: 0;user-select: none;-webkit-user-select: none;touch-action: manipulation;cursor: pointer;' : ''"
          mat-raised-button
          style="margin-left: 10px; margin-right: 10px;color: #000; padding: 5px 25px;border-radius: 14px;font-weight: bold;font-size: 16px;border: 0;user-select: none;-webkit-user-select: none;touch-action: manipulation;cursor: pointer;"><span
            [style]="u == individualUom ? 'color:white; font-weight: 500;':'color:black; font-weight: 500;'">{{u}}</span>
        </button>
      </div>
    </div>
    <!-- </form> -->
    <div class="d-flex  justify-content-center" *ngIf="isLoadingKPIComparision">

      <mat-spinner></mat-spinner>
    </div>
    <div [chart]="stock" *ngIf="!isLoadingKPIComparision"></div>
  </mat-dialog-content>
</ng-template>

<ng-template #extendEndTimeModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Extend End Time</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <h3>Meeting is about to end. {{timeLeft>0 ? timeLeft: ''}}</h3>
    <form [formGroup]="extendTimeForm">
      <mat-form-field>
        <mat-label>Extend Time</mat-label>
        <input matInput type="number" formControlName="EndTime" />
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close color="primary">Cancel</button>
    <button mat-button color="primary">Extend</button>
  </mat-dialog-actions>
</ng-template>

<p-dialog [header]="fileDesc" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
  [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ngx-doc-viewer *ngIf="fileType === 'doc'" [url]="doc" viewer="google" style="width: 100%; height: 90vh">
  </ngx-doc-viewer>
  <angular-image-viewer *ngIf="fileType === 'img'" [src]="[images]" [(index)]="imageIndexOne"></angular-image-viewer>
</p-dialog>

<ng-template #ExtendMeeting style="padding-bottom: 0px !important">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Extend Meeting Time</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <h2>Do you want to extend meeting time?</h2>
    <div class="d-flex">
      <form [formGroup]="ExtendMeetingForm" style="width:100%">
        <mat-form-field style="width:25% !important"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Duration</mat-label>
          <input matInput type="number" placeholder="Enter Duration" formControlName="duration" />
        </mat-form-field>
        <mat-form-field style="width:66% !important;margin-left:10px"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Reason</mat-label>
          <input matInput placeholder="Enter Reason" formControlName="reason" />
        </mat-form-field>
        <div style="width:100%">
          <button type="button" mat-mini-fab mat-button color="primary" (click)="AddMeetingException()"
            matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above" style="float:right">
            <i class="fa fa-floppy-o" style="color: #fff"></i>
          </button>
        </div>
      </form>
    </div>


  </mat-dialog-content>
</ng-template>


<ng-template #addActionModal>
  <div mat-dialog-title>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2> {{ langObj ? langObj?.AddAction : ("AddAction" | translate) }}</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
  </div>
  <mat-dialog-content>
    <form [formGroup]="addActionForm">
      <div class="content-top-row" style="margin-bottom: 20px">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.Action : ('Action' | translate)}} *</mat-label>
          <input matInput placeholder="Enter Action Name" formControlName="action" />
        </mat-form-field>
        <div class="radio-wrapper" style="width: 20%;">
          <mat-label>{{langObj ? langObj?.ActionType : ('ActionType' | translate)}} *:</mat-label>
          <mat-radio-group formControlName="actionType" class="radio-group" name="ActionType">
            <mat-radio-button value="Preventive">Preventive</mat-radio-button>
            <mat-radio-button value="Corrective">Corrective</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="background: gainsboro;">
          <mat-label>KPI</mat-label>
          <input matInput placeholder="Enter Remarks" formControlName="kpi" readonly />
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.SelectAssignee : ('SelectAssignee' | translate)}} *</mat-label>
          <mat-select formControlName="assignTo">
            <mat-select-filter [placeholder]="'Search User'" [displayMember]="'FullName'" [array]="participantsByPlants"
              (filteredReturn)="userList = $event">
            </mat-select-filter>
            <mat-option *ngFor="let user of userList" [value]="user?.EmpInfo_Id">
              {{ user?.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="inputField" style="width: 17%;margin-top: 0px;">
          <nz-date-picker class="calender-input" style="margin-left: 0px !important; width: 100%" [nzShowTime]="false"
            nzFormat="yyyy-MM-dd" formControlName="targetDate">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.TargetDate : ('TargetDate' | translate)}} *</span>
        </div>
        <button [disabled]="!addActionForm.valid" mat-mini-fab mat-button color="primary" matTooltip="Add"
          matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 10px;"
          (click)="addActionPlan()">
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
      </div>
    </form>
    <ag-grid-angular style="width: 100%; height: 20vw" class="ag-theme-alpine" [pagination]="true"
      [paginationAutoPageSize]="5" [columnDefs]="columnDefsallAction" [rowData]="overallMeetingActions"
      (cellClicked)="cellClickedActionTable($event)">
    </ag-grid-angular>
  </mat-dialog-content>
</ng-template>