import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material-module';
import { DatePipe } from '@angular/common';

import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';
import { MissionComponent } from './screens/mission/mission.component';
import { VisionComponent } from './screens/vision/vision.component';
import { ToastrModule } from 'ngx-toastr';
import { TableModule } from 'primeng/table';
import { LoginComponent } from './screens/authentications/login/login.component';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { TreeTableModule } from 'primeng/treetable';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { ContextMenuModule } from 'primeng/contextmenu';
// Prime NG Imports
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from './services/authentication.service';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmationService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { AgGridModule } from 'ag-grid-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PrimeCellEditorComponent } from './editors/prime-cell-editor.component';
import { CustomDateComponent } from './date-components/custom-date-component.component';
import { BtnCellRenderer } from './btn-cell-renderer.component';
import { BtnCellRendererActualEntry } from './btn-cell-rendererActualEntry.component';
import { TreeModule } from 'primeng/tree';
import { ColorPickerModule } from 'primeng/colorpicker';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InputMaskModule } from 'primeng/inputmask';
import { WelcombannerService } from './services/welcombanner.service';
import { TooltipModule } from 'primeng/tooltip';
import { PrivateLayoutComponent } from './shared/private-layout/private-layout.component';
import { PublicLayoutComponent } from './shared/public-layout/public-layout.component';
import { SignUpComponent } from './screens/authentications/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './screens/authentications/forgot-password/forgot-password.component';
import { WelcomeBannerComponent } from './shared/welcome-banner/welcome-banner.component';
// Start Material Modules
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatGridListModule } from '@angular/material/grid-list';

// End Material Modules
import { ProductionOrderManagementComponent } from './screens/production-order-management/production-order-management.component';
import { O3AdministrationComponent } from './screens/o3-administration/o3-administration.component';
import { ManufacturingAdminComponent } from './O3AdministrationComponents/manufacturing-admin/manufacturing-admin.component';
import { QualityAdminComponent } from './O3AdministrationComponents/quality-admin/quality-admin.component';
import { GeneralSettingsComponent } from './O3AdministrationComponents/general-settings/general-settings.component';
import { MasterDataUploadComponent } from './O3AdministrationComponents/master-data-upload/master-data-upload.component';
import { UsermanagementComponent } from './screens/usermanagement/usermanagement.component';
import { AnalyticsComponent } from './screens/analytics/analytics.component';
// tslint:disable-next-line:max-line-length
import { ProductmasterComponent } from './screens/productmaster/productmaster.component';
import { ProductpropertyComponent } from './screens/productproperty/productproperty.component';
import { ProductspecsComponent } from './screens/productspecs/productspecs.component';
import { TreeChecklistExampleComponent } from './screens/tree-checklist-example/tree-checklist-example.component';
import { HomeModule } from './screens/home.module';
import { OLEModule } from './OLE/ole.module';
import { LossAnalysisModule } from './LossAnalysis/lossanalysis.module';
import { AccessModule } from './Access/access.module';
import { PmModule } from './PM/pm.module';
import { ProgressiveMaintenanceModule } from './Progressive Maintenance/progressiveMaintenance.module';
import { CILModule } from './CIL/cil.module';
import { DMSModule } from './DMS/dms.module';
import { DTModule } from './DT/dt.module';
import { KPIModule } from './KPIs Management/kpi.module';
import { DashboardComponent } from './screens/dashboard/dashboard.component';

import { NgApexchartsModule } from 'ng-apexcharts';
import { MatSelectFilterModule } from 'mat-select-filter';
import { CustomerComplaintComponent } from './customer-complaint/customer-complaint.component';
import { CcrHistoryComponent } from './ccr-history/ccr-history.component';
import { QualitymanagementModule } from './qualitymanagement/qualitymanagement.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PerformanceManagementModule } from './Performance Management/prmanagement.module';
import { BtnCellRendererGambaWalk } from './btn-cell-rendererGambaWalk.component';
import { BtnCellRendererQxMatrix } from './btn-cell-rendererQxMatrix';
import { BtnDeleteCellRenderer } from './btn-deletecell-renderer';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { DashboardModule } from './screens/dashboard/dashboard.module';
import { ChartsModule } from 'ng2-charts';
import { AtModule } from './analytical-tools/at.module';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as drilldown from 'highcharts/modules/drilldown.src';
import { CustomTooltip } from './custom-tooltip.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MeetingEvaluationComponent } from './meeting-evaluation/meeting-evaluation/meeting-evaluation.component';

import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { DatetestComponent } from './datetest/datetest.component';
import { MeetingEvaluationLevelComponent } from './meeting-evaluation/meeting-evaluation-level/meeting-evaluation-level.component';
import { OrpDashboardModule } from './orp-dashboard/orp-dashboard.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslaterService } from './services/translater.service';
import { SafetyModule } from './safety/safety.module';
import { OPMSMainComponent } from './OPMS-Glass/opms-main/opms-main.component';
import { OpmsDataComponent } from './OPMS-Glass/opms-data/opms-data.component';
import { OpmsDailyConsuptionComponent } from './OPMS-Glass/opms-daily-consuption/opms-daily-consuption.component';
import { OpmsMonthlyStockComponent } from './OPMS-Glass/opms-monthly-stock/opms-monthly-stock.component';
import { OpmsBoxReceivingComponent } from './OPMS-Glass/opms-box-receiving/opms-box-receiving.component';
import { QaDataEntryComponent } from './Quality-ODS/qa-data-entry/qa-data-entry.component';
import { PDFExportRenderer } from './pdf-export-renderer.component';
import { PdfExportPanelComponent } from './Quality-ODS/pdf-export-panel/pdf-export-panel.component';
import { ColorPickerComponent } from './Quality-ODS/color-picker.component';
import { OpmsQualityModule } from './opms-quality/opms-quality.module';
import { CustomerComplaintModule } from './CustomerCompalint/customercomplaint.module';
import { PalletActionRenderer } from './pallet-action-renderer';
import stock from 'highcharts/modules/stock';
import { BtnCellRendererRawMaterial } from './btn-cellRenderer-RawMaterial';
import { OpmsReportsComponent } from './OPMS-Glass/opms-reports/opms-reports.component';
// import { DocumentsModule } from './Document Management Components/documents.module';	
import { NgxDropzoneModule } from 'ngx-dropzone';	
import { VersionCheckService } from './services/VersionCheckService';	
import { NgxMatSelectConfigs, NgxMatSelectModule, NGX_MAT_SELECT_CONFIGS } from 'ngx-mat-select';
import { BtnCellRendererCilMaster } from './btn-cell-rendererCILMaster.component';
import { BtnRendererCheckListType } from './btn-RenderercheckListType';
import { BtnCellRendererForReports } from './btn-cell-rendererForReports.component';
import { ToggleBtnCellRenderer } from './toggle-btn-cell-renderer';
import { KPIMilestoneService } from './services/KPI-milestone.service';
import { HighchartsService } from './services/highcharts.service';
import { BtnCellRendererGapAnalysis } from './btn-cell-rendererGapAnalysis';
export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more];
}

registerLocaleData(en);
export const ngxMatSelectConfigs: NgxMatSelectConfigs = {	
  maxWidthForMobileView: 600,	
  inFirstLoadCallSearcher: true,	
  inFirstLoadSearcherValue: '',	
  emptyLabel: 'no entry found',	
  noMoreResultLabel: 'no more found',	
  useInfiniteScroll: false,	
  searchBoxPlaceholder: 'please search',	
  maximumResultForShow: 40,	
  useMobileView: false,	
  valueMember: 'key',	
  displayMember: 'value',	
  mobileViewType: 'FullScreen'	
};
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    ContentAnimateDirective,
    MissionComponent,
    VisionComponent,
    LoginComponent,
    PrimeCellEditorComponent,
    // DefectManagementMasterComponent,
    CustomDateComponent,
    BtnCellRenderer,
    BtnCellRendererActualEntry,
    BtnCellRendererGambaWalk,
    BtnCellRendererQxMatrix,
    BtnDeleteCellRenderer,
    BtnCellRendererRawMaterial,
    PDFExportRenderer,
    PrivateLayoutComponent,
    PublicLayoutComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    WelcomeBannerComponent,
    ProductionOrderManagementComponent,
    O3AdministrationComponent,
    ManufacturingAdminComponent,
    QualityAdminComponent,
    GeneralSettingsComponent,
    MasterDataUploadComponent,
    UsermanagementComponent,
    AnalyticsComponent,
    ProductmasterComponent,
    ProductpropertyComponent,
    ProductspecsComponent,
    AnalyticsComponent,
    ProductmasterComponent,
    ProductpropertyComponent,
    ProductspecsComponent,
    TreeChecklistExampleComponent,
    DashboardComponent,
    CustomerComplaintComponent,
    // CcrHistoryComponent,
    MeetingEvaluationComponent,
    CustomTooltip,
    DatetestComponent,
    MeetingEvaluationLevelComponent,
    // OPMS-Glass
    // OPMSMainComponent,
    // OpmsDataComponent,
    // OpmsDailyConsuptionComponent,
    // OpmsReportsComponent,
    // OpmsMonthlyStockComponent,
    // OpmsBoxReceivingComponent,
    QaDataEntryComponent,
    PdfExportPanelComponent,
    ColorPickerComponent,
    BtnCellRendererCilMaster,
    BtnRendererCheckListType,
    BtnCellRendererForReports,
    ToggleBtnCellRenderer,
    BtnCellRendererGapAnalysis,
  ],
  imports: [
    // NgxMatSelectSearchModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    OrganizationChartModule,
    NgxDropzoneModule,
    TreeTableModule,
    ToastModule,
    MatSelectFilterModule,
    PanelModule,
    ContextMenuModule,
    ButtonModule,
    MatRadioModule,
    SidebarModule,
    NgxSpinnerModule,
    InputTextModule,
    DropdownModule,
    MultiSelectModule,
    TieredMenuModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    TieredMenuModule,
    DialogModule,
    CalendarModule,
    InputSwitchModule,
    ScrollingModule,
    DragDropModule,
    CommonModule,
    InputNumberModule,
    CardModule,
    ColorPickerModule,
    TreeModule,
    RadioButtonModule,
    InputMaskModule,
    TooltipModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatGridListModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    ChartsModule,
    ChartModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    AgGridModule.withComponents([
      BtnCellRenderer,
      BtnCellRendererActualEntry,
      BtnCellRendererGambaWalk,
      BtnCellRendererQxMatrix,
      BtnCellRendererCilMaster,
      BtnDeleteCellRenderer,
      CustomTooltip,
      PDFExportRenderer,
      PalletActionRenderer,
      BtnRendererCheckListType,
    ]),
    MatMenuModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MaterialModule,
    MatExpansionModule,
    HomeModule,
    OLEModule,
    LossAnalysisModule,
    AccessModule,
    PmModule,
    ProgressiveMaintenanceModule,
    CILModule,
    DMSModule,
    NgApexchartsModule,
    DTModule,
    KPIModule,
    PerformanceManagementModule,
    QualitymanagementModule,
    // DocumentsModule,
    MatTooltipModule,
    DashboardModule,
    MatInputModule,
    AtModule,
    SafetyModule,
    // NgZorroAntdModule
    NzDatePickerModule,
    // NzTimePickerModule
    OrpDashboardModule,
    NzTimePickerModule,
    OpmsQualityModule,
    CustomerComplaintModule,
    NgxMatSelectModule.forRoot({	
      maxWidthForMobileView: 600,	
      inFirstLoadCallSearcher: true,	
      inFirstLoadSearcherValue: '',	
      emptyLabel: 'no entry found',	
      noMoreResultLabel: 'no more found',	
      useInfiniteScroll: false,	
      searchBoxPlaceholder: 'Search',	
      maximumResultForShow: 4000,	
      useMobileView: false,	
      valueMember: 'key',	
      displayMember: 'value',	
      mobileViewType: 'FullScreen'	
    })	
  ],
  
  providers: [
    DatePipe,
    MessageService,
    TranslaterService,
    AuthenticationService,
    ConfirmationService,
    VersionCheckService,
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    { provide: NGX_MAT_SELECT_CONFIGS, useValue: ngxMatSelectConfigs },
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [more, exporting, drilldown, highchartsModules],
    }, // add as factory to your providers
    // { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules },
    WelcombannerService,
    KPIMilestoneService,
    HighchartsService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support
// tslint:disable-next-line:typedef
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
