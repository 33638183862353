import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SideBarDef } from 'ag-grid-community';
import { differenceInCalendarDays } from 'date-fns';
import * as Highcharts from 'highcharts';
import * as _moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ImageDialogComponent } from '../analytical-tools/image-dialog/image-dialog.component';
import { CustomLoadingOverlay } from '../custom-loading-overlay.component';
import { PrimeCellEditorComponent } from '../editors/prime-cell-editor.component';
import { CommonService } from '../services/common.service';
import { DtServiceService } from '../services/dtService.service';
import { EnterPriseGroupService } from '../services/enterpriseGroup.service';
import { MachineMappingService } from '../services/machinemapping.service';
import { OverallActionPlanService } from '../services/overall-actionPlan.service';
import { ShopFloorDataService } from '../services/shopFloorData.service';
import { UserManagementService } from '../services/usermanagement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslaterService } from 'src/app/services/translater.service';
import { DatepickerService } from '../services/Datepicker.service';

const moment = _moment;
declare var window: any;
interface status {
  statusId: number;
  name: string;
}

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
})
export class UserActionsComponent implements OnInit {
  @ViewChild('previewModal') private previewModal!: TemplateRef<any>;
  @ViewChild('addActionModal') private addActionModal!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileInputFile') fileInputFile!: ElementRef;
  @ViewChild('addImageInput') addImageInput!: ElementRef;
  @ViewChild('addFileInput') addFileInput!: ElementRef;
  blobStorageUrl = environment.O3CoreApiImageUrl;
  status: status[];
  selectedStatus: status[];
  filteredModule!: any[];
  filteredDepartments!: any[];
  filteredEmployee!: any[];
  dropdownSettings: any = {};
  editActionPlanForm!: FormGroup;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  filterForm!: FormGroup;
  groups: any[] = [];
  filteredGroups: any[] = [];
  plants: any[] = [];
  filteredPlants: any[] = [];
  closefile: any;
  actionscolumnDefs: any;
  actionsrowData: any[] = [];
  Modules: any[] = [];
  ChartOptions: any;
  Chart2Options: any;
  Chart3Options: any;
  loadingOverlayComponent: any;
  gridOptions: any = {
    frameworkComponents: {
      customLoadingOverlay: CustomLoadingOverlay,
      primeCellEditor: PrimeCellEditorComponent,
    },
  };
  gridApi: any;

  // selectedOption = ['Open' ,'InProgress' ]
  totalActions: any;
  completedActions: any;
  pendingActions: any[] = [];
  UserDDL: any;
  Responsibles: any[] = [];
  Departments: any[] = [];
  newModules: any[] = [];
  totalActionsCount: any;
  completedActionsCount: any;
  pendingActionsCount: any;
  totalActionObservations: any[] = [];
  totalMonthresult: any[];
  completedActionObservations: any;
  completedMonthresult: any[];
  pendingActionObservations: any;
  pendingMonthresult: any[];
  gridColumnApi: any;
  defaultColDef: any;
  //sideBar: SideBarDef | string | string[] | boolean | null = 'columns';
  userList: any = [];
  targetDate: any;
  data: any;
  imageFile: any;
  imageSrc: any;  
  disableVerification = false;
  disableNewAssigne = true;
  fileSave: any;
  ImageSave: any;
  Status: any;
  filteredstatus: any;
  RevisedDate: Date;
  ModuleName: any;
  currentAssigneeEmail: string = null;
  currentResponsibleEmail: any = null;
  currentNodeDate: any = null;
  UniqueCode: any;
  onlyWhenGrouping: any = 'onlyWhenGrouping';

  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: false,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
    defaultToolPanel: 'columns',
  };
  showinput: boolean;
  HeaderId: any;
  doc: string;
  images: any[];
  fileType: string;
  fileDesc: any;
  displayMaximizable: boolean;
  imageIndexOne = 0;
  langObj: any;
  languageDir = 'ltr';
  pageId: any;
  moduleId: any;
  addActionForm: FormGroup;
  addFileSrc = '';
  addImageSrc = '';
  addFile = null;
  addImage = null;
  addStatus = 'Open';
  action = '';
  addDisableVerification = false;
  addDisableNewAssigne = true;
  addShowinput: boolean;
  addActionCloseFile: any;
  firstDayOfWeek: any;
  constructor(
    private toaster: ToastrService,
    private router: Router,
    private userManagementService: UserManagementService,
    private platModelService: EnterPriseGroupService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private dtservice: DtServiceService,
    private sfdService: ShopFloorDataService,
    private machineService: MachineMappingService,
    private actionplanService: OverallActionPlanService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private translater: TranslaterService,
    private datepickerService: DatepickerService,
  ) {
    this.selectedStatus = [
      { statusId: 1, name: 'Open' },
      { statusId: 3, name: 'InProgress' },
    ];

    this.status = [
      { statusId: 1, name: 'Open' },
      { statusId: 3, name: 'InProgress' },
      { statusId: 4, name: 'Closed' },
      { statusId: 5, name: 'Rejected' },
      { statusId: 6, name: 'Complete' },
      { statusId: 7, name: 'Delayed' },
      { statusId: 8, name: 'ReOpened' },
    ];

    this.defaultColDef = {
      editable: true,
      enableRowGroup: true,
      enableValue: true,
    };
    this.actionscolumnDefs = [
      {
        field: 'Actions',
        headerName: 'Actions',
        resizable: true,

        cellRenderer: (params: any): any => {
          if (params?.data.Status !== 'Closed') {
            return `
          <button mat-mini-fab="" matTooltip="Edit Physical Test" tooltipPosition="top" color="primary" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          `;
          }
        },
        width: 100,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      // {
      //   field: 'Actions',
      //   headerName: 'Actions',
      //   sortable: true,
      //   resizable: true,
      //   filter: true,
      //   editable: false,
      //   width: 100,
      // },
      {
        field: 'Module',
        headerName: 'Module',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        width: 100,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'HeaderId',
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'UniqueCode',
        headerName: 'Unique Code',
        sortable: true,
        resizable: true,
        filter: true,
        wrapText: true,
        editable: false,
        width: 130,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Action',
        headerName: 'Actions (RCA & others)',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Responsible',
        headerName: 'AssignTo / Responsible',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Status',
        headerName: 'Status',
        sortable: true,
        width: 100,
        resizable: true,
        filter: true,
        editable: false,
        cellStyle: function (params: any): any {
          if (params.value == 'Open')
            return { color: 'white', backgroundColor: 'gray' };
          else if (params.value == 'InProgress')
            return { color: 'white', backgroundColor: 'orange' };
          else if (params.value == 'Complete')
            return { color: 'white', backgroundColor: '#1cd51c' };
          else if (params.value == 'Closed')
            return { color: 'white', backgroundColor: 'green' };
          else if (params.value == 'Delayed')
            return { color: 'white', backgroundColor: 'brown' };
          else if (params.value == 'Rejected')
            return { color: 'white', backgroundColor: 'red' };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ObservedDate',
        headerName: 'Observed Date',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Department',
        headerName: 'Department',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        width: 120,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Observation',
        headerName: 'Initial Problem /Observation / Root Cause Problem',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'picturebefore',
        headerName: 'Picture Before',
        sortable: true,
        resizable: true,
        filter: false,
        hide: true,
        cellRenderer: function (params: any) {
          let html = `<img style="width: 50px; height: 40px;" src="assets/images/no-image.png"/>`;
          if (params && params.value) {
            html = '';
            html = `<img style="width: 50px; height: 40px;" src="${params.value}"/>`;
          }
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },

      {
        field: 'UploadDoc',
        headerName: 'Attachments',
        editable: false,
        hide: true,
        cellRenderer: function (params: any) {
          let html = '';
          if (params && params.value) {
            html = `<a href = "${params.value}" ><i class="fa fa-download"></i></a>`;
          }
          return html;
        },
        cellStyle: (params: any) => {
          // let html = 'view';
          return {
            color: 'blue',
            'text-decoration': 'underline',
            cursor: 'pointer',
          };
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'RootCause',
        headerName: 'Root Cause Problem',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },

      {
        field: 'ActionType',
        headerName: 'Action Type (Corrective/Preventive/Other)',
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },

      {
        field: 'ClosedFile',
        headerName: 'Closed File',
        sortable: true,
        resizable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = '';
          if (params && params?.data?.closedFile) {
            html = `<button mat-mini-fab="" matTooltip="View document" tooltipPosition="top" color="primary" class="viewDocument mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="viewDocument mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon></span><span matripple="" class="viewDocument mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            `;
          }
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'AssignedDate',
        headerName: 'Assigned Date',
        sortable: true,
        resizable: true,
        filter: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        editable: false,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'TargetDate',
        headerName: 'Target Date',
        sortable: true,
        resizable: true,
        filter: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'RevisedDate',
        headerName: 'New/Revised Target Date',
        sortable: true,
        resizable: true,
        filter: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'NoOfMisses',
        headerName: 'No of Misses',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ActionCompleted',
        headerName: 'Action Completed ',
        sortable: true,
        resizable: true,
        filter: true,
        wrapText: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Yes', 'No'],
        },
        editable: (params: any) => params.value === 'No',
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ActionCompletedByName',
        headerName: 'Action Completed By',
        sortable: true,
        resizable: true,
        filter: true,
        wrapText: true,
        editable: (params: any) => params.data.ActionCompleted === 'No',
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ActionCompletedOn',
        headerName: 'Action Completed On',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Verification',
        headerName: 'Verification',
        sortable: true,
        resizable: true,
        filter: true,
        editable: (params: any) =>
          params.value === 'Disapproved' || params.value === '',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Approved', 'Disapproved'],
        },
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'VerificationByName',
        headerName: 'Verified By',
        sortable: true,
        resizable: true,
        filter: true,
        wrapText: true,
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'VerificationOn',
        headerName: 'Verified On',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'NewAssignee',
        headerName: 'New Assignee',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'ClosedDate',
        headerName: 'Closed Date',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        valueFormatter: function (params: any) {
          return params.value === null || params.value === ''
            ? ''
            : moment(params.value).format('DD/MM/YYYY hh:mm a');
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'closedFile',
        headerName: 'Closed File',
        sortable: true,
        resizable: true,
        filter: true,
        cellRenderer: function (params: any) {
          let html = '';
          if (params && params.value) {
            html = `<a href = "${params.value}" ><i class="fa fa-download"></i></a>`;
          }
          return html;
        },
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'Remarks',
        headerName: 'Remarks',
        sortable: true,
        resizable: true,
        filter: true,
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
    ];
    this.loadingOverlayComponent = 'customLoadingOverlay';
  }

  ngOnInit() {
    this.GetBreadcrumList();
    this.GetMenuAccess();

    this.filterForm = this.fb.group({
      group: [''],
      plant: [''],
      module: [''],
      department: [''],
      area: [''],
      employee: [''],
      status: [''],
      datePicker: [''],
      startDate: [''],
      endDate: [''],
    });
    this.filterForm.get('status').setValue(this.selectedStatus);
    this.editActionPlanForm = this.fb.group({
      id: [''],
      remarks: [''],
      revisedDate: [''],
      actions: [''],
      assignTo: [''],
      completed: [''],
      verification: [''],
      actionType: [''],
      targetDate: [''],
      newAssignee: [''],
      rootcause:['']
    })
    this.addActionForm = this.fb.group({
      remarks: [''],
      revisedDate: [''],
      action: ['', Validators.required],
      actionType: ['', Validators.required],
      assignTo: ['', Validators.required],
      targetDate: ['', Validators.required],
      completed: [''],
      verification: [''],
      newAssignee: [''],
      rootcause:['']
    });
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const d = date.getDate();

    const firstDay = new Date(y, m - 1, d);
    const lastDay = new Date(y, m, d);
    const selected = {
      startDate: firstDay,
      endDate: lastDay,
    };
    this.filterForm.get('startDate').setValue(firstDay);
    this.filterForm.get('endDate').setValue(lastDay);
    this.getGroups();
    this.filteredstatus = this.status.slice();
  }

  saveState() {
    var ColumnState = JSON.stringify(this.gridColumnApi.getColumnState());

    localStorage.setItem('userActions-OverAllActionsColumnState', ColumnState);
  }

  getchart1(data: any): void {
    let monthsArr: any[] = [];
    let countArr: any[] = [];
    monthsArr = data.map((item) => item.monthName);
    countArr = data.map((item) => item.count);
    this.ChartOptions = {
      chart: {
        type: 'column',
        height: 180,
        width: 300,
        spacingRight: 50,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: monthsArr,
        crosshair: true,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        visible: false,
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: false,
          },
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [{ name: 'Months', data: countArr, color: '#62c7c7' }],
    };
    Highcharts.chart('Chart1', this.ChartOptions);
  }
  getchart2(data: any): void {
    let monthsArr: any[] = [];
    let countArr: any[] = [];
    let monthToNum = (month) => {
      let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return months.indexOf(month);
    };
    
    data.sort((a, b) => {
      return monthToNum(a.monthName) - monthToNum(b.monthName);
    });
    monthsArr = data.map((item) => item.monthName);
    countArr = data.map((item) => item.count);
    this.Chart2Options = {
      chart: {
        type: 'column',
        height: 180,
        width: 300,
        spacingRight: 50,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: monthsArr,
        crosshair: true,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        visible: false,
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: false,
          },
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [{ name: 'Months', data: countArr, color: '#e62e8a' }],
    };
    Highcharts.chart('Chart2', this.Chart2Options);
  }
  getchart3(data: any): void {
    let monthsArr: any[] = [];
    let countArr: any[] = [];
    monthsArr = data.map((item) => item.monthName);
    countArr = data.map((item) => item.count);
    this.Chart3Options = {
      chart: {
        type: 'column',
        height: 180,
        width: 300,
        spacingRight: 50,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: monthsArr,
        crosshair: true,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        visible: false,
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: false,
          },
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [{ name: 'Months', data: countArr, color: '#091182' }],
    };
    Highcharts.chart('Chart3', this.Chart3Options);
  }
  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.sfdService.getGroupData(key).subscribe((res: any) => {
      this.groups = res.masterList;
      this.filteredGroups = this.groups.slice();
      this.filterForm.get('group')?.setValue(this.groups[0]?.TypeId);
      this.getPlantByGroup();
    });
  }
  getNewModule() {
    this.actionplanService.getNewModule().subscribe((res: any) => {
      const a = { ID: 0, Name: 'RCA' };
      res.unshift(a);
      this.newModules = res;
      this.filterForm.get('module').setValue(this.newModules);
      setTimeout(() => {
        this.GetActionPlan();
      }, 5000);
    });
  }
  getEmployees() {
    const key = {
      EGId: this.filterForm.get('group').value,
      PlantId: this.filterForm.get('plant').value,
    };
    this.commonService.getPeopleMasterData(key).subscribe((res: any) => {
      var emp = {
        TransactionType: 0,
        EmpInfo_Id: 0,
        EmployeeNo: 'Emp-test-none app user',
        FirstName: 'Un',
        lastName: 'assigned',
        Email: '',
        Grade: '',
        DOB: null,
        DOJ: null,
        EGId: 0,
        UserId: 0,
        PlantId: 0,
        Nationality: '',
        CreatedBy: 0,
        fullName: 'Unassigned',
        Dept_Desc: '',
        Dept_Id: 0,
        PositionId: 0,
        AppTypeID: 1,
        AppVersion: '1.0',
        LanguageID: 1,
        HasError: false,
        ErrorMessage: '',
        IsSync: false,
      };
      res.employeeInfoList.unshift(emp);
      this.Responsibles = res.employeeInfoList;
      this.filterForm.get('employee').setValue(this.Responsibles);
      this.Responsibles.map(
        (user: any) =>
          (user.FullName = user.FirstName.trim() + ' ' + user.lastName.trim())
      );
    });
  }
  // makeDataLastWeek() {
  //   const stDT = new Date();
  //   stDT.setDate(stDT.getDate() - 6);
  //   stDT.setHours(6);
  //   stDT.setMinutes(0);
  //   stDT.setSeconds(0);

  //   const edDT = new Date();
  //   edDT.setDate(edDT.getDate());
  //   this.filterForm.get('startDate').setValue(stDT);
  //   this.filterForm.get('endDate').setValue(edDT);
  // }
  GetActionPlan(): void {
    // this.gridApi.showLoadingOverlay();
    const module = this.filterForm.value.module;
    const department = this.filterForm.value.department;
    const employee = this.filterForm.value.employee;
    const status = this.filterForm.value.status;
    if (module != '') {
      var Modulearr = [];
      module.forEach((elem) => {
        Modulearr.push(elem.ID);
      });

      var ModuleValue = Modulearr.toString();
    }

    if (department != '') {
      var departArr = [];
      department.forEach((elem) => {
        departArr.push(elem.TypeId);
      });
      var deptValue = departArr.toString();
    }

    if (employee != '') {
      var empArr = [];
      employee.forEach((elem) => {
        empArr.push(elem.EmpInfo_Id);
      });
      var empValue = empArr.toString();
    }

    if (status != '') {
      var statusArr = [];
      status.forEach((elem) => {
        statusArr.push(elem.name);
      });
      var statusValue = statusArr.toString();
    }

    // this.gridApi.setRowData([]);
    // const startDate = new Date(this.filterForm.value.startDate);
    const startDate = moment(this.filterForm.value.startDate).utc();

    const endDate = new Date(
      new Date(this.filterForm.value.endDate).setHours(23, 59, 59, 59)
    );
    // endDate.setHours(23, 59, 59, 999);
    //var dateEnd = new Date(this.filterForm.value.endDate).setHours(23, 59, 59, 59))
    const key = {
      ptId: this.filterForm.value.plant,
      fromDate: startDate,
      toDate: endDate,
      userId: JSON.parse(localStorage.user).UserId,
      module: ModuleValue,
      department: deptValue,
      employee: empValue,
      status: statusValue,
    };
    if (key.ptId) {
      this.actionsrowData = [];
      this.totalActions = [];
      this.actionplanService.GetActionPlan(key).subscribe((res: any) => {
      this.addImageSrc = '';
      this.addFileSrc = '';
      this.addImage = null;
      this.addFile = null;
        this.spinner.hide();
        res?.data.map((row) => {
          row.picturebefore = environment.O3CoreApiImageUrl + row.picturebefore;
          row.UploadDoc = environment.O3CoreApiImageUrl + row.UploadDoc
          row.closedFile = environment.O3CoreApiImageUrl + row.closedFile
        })
        this.actionsrowData = res?.data;
        this.totalActions = res?.chartData;
        this.totalActionObservations = this.totalActions?.map(
          (item) => item.ObservedDate
        );
        var monthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var group = this.totalActionObservations?.reduce(function (r, o) {
          var date = new Date(o);
          var month = date.getMonth();
          var monthName = monthNames[month];
          r[monthName]
            ? r[monthName].count++
            : (r[monthName] = { monthName: monthName, count: 1 });
          return r;
        }, {});
        this.totalMonthresult = Object.keys(group)?.map((key) => group[key]).reverse();
        this.getchart1(this.totalMonthresult);
        this.totalActionsCount = res?.chartData.length;
        this.completedActions = res.chartData?.filter((obj) => obj.Status === 'Closed');
        this.completedActionsCount = this.completedActions.length;
        this.completedActionObservations = this.completedActions?.map(
          (item) => item.ObservedDate
        );
        var compgroup = this.completedActionObservations?.reduce(function (
          r,
          o
        ) {
          var date = new Date(o);
          var month = date.getMonth();
          var monthName = monthNames[month];
          r[monthName]
            ? r[monthName].count++
            : (r[monthName] = { monthName: monthName, count: 1 });
          return r;
        },
          {});
        this.completedMonthresult = Object?.keys(compgroup)?.map(
          (key) => compgroup[key]
        );
        this.getchart2(this.completedMonthresult);
        this.pendingActions = [];
        this.totalActions?.forEach((element) => {
          if (
            element.Status === 'InProgress' ||
            element.Status === 'Open' ||
            element.Status === 'Delayed'
          ) {
            this.pendingActions?.push(element);
          }
        });
        // function status(Status){
        //   return Status === 'InProgress' && Status === 'Open'

        // }
        this.pendingActionsCount = this.pendingActions.length;
        this.pendingActionObservations = this.pendingActions?.map(
          (item) => item.ObservedDate
        );
        var pendinggroup = this.pendingActionObservations?.reduce(function (
          r,
          o
        ) {
          var date = new Date(o);
          var month = date.getMonth();
          var monthName = monthNames[month];
          r[monthName]
            ? r[monthName].count++
            : (r[monthName] = { monthName: monthName, count: 1 });
          return r;
        },
          {});
        this.pendingMonthresult = Object?.keys(pendinggroup)?.map(
          (key) => pendinggroup[key]
        )?.reverse();
        this.getchart3(this.pendingMonthresult);
        this.spinner.hide();
      });
    }
  }
  onColumnPivotChanged(e: any) {
    var ColumnState = JSON.stringify(this.gridColumnApi.getColumnState());

    localStorage.setItem('userActions-OverAllActionsColumnState', ColumnState);
  }
  // getModule(data: any) {
  //   const unique = [...new Set(data.map((item) => item.Module))];
  //   const unique1 = unique.map(function (elem) {
  //     return { name: elem };
  //   });
  //   this.Modules = unique1;
  // }

  filterData(): any {
    let modulesval = this.filterForm.value.module;
    let deptval = this.filterForm.value.department;
    let empval = this.filterForm.value.employee;
    let statusval = this.filterForm.value.status;
    let arrayData = this.actionsrowData;
    var monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Augu',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    if (modulesval.length) {
      modulesval = modulesval.map((x) => x.Name);
      arrayData = arrayData.filter((x) => modulesval.includes(x.Module));
    }
    if (deptval.length) {
      deptval = deptval.map((x) => x.TypeName);
      arrayData = arrayData.filter((x) => deptval.includes(x.Department));
    }
    if (empval.length) {
      empval = empval.map((x) => x.fullName);
      arrayData = arrayData.filter((x) => empval.includes(x.Responsible));
    }
    if (statusval.length) {
      statusval = statusval.map((x) => x.name);
      arrayData = arrayData.filter((x) => statusval.includes(x.Status));
    }
    this.totalActions = arrayData;
    this.totalActionsCount = arrayData?.length;
    this.totalActionObservations = this.totalActions.map(
      (item) => item.ObservedDate
    );

    var group = this.totalActionObservations.reduce(function (r, o) {
      var date = new Date(o);
      var month = date.getMonth();
      var monthName = monthNames[month];
      r[monthName]
        ? r[monthName].count++
        : (r[monthName] = { monthName: monthName, count: 1 });
      return r;
    }, {});
    this.totalMonthresult = Object.keys(group).map((key) => group[key]);
    this.getchart1(this.totalMonthresult);

    this.completedActions = arrayData.filter((obj) => obj.Status === 'Closed');
    this.completedActionsCount = this.completedActions.length;
    this.completedActionObservations = this.completedActions.map(
      (item) => item.ObservedDate
    );
    var compgroup = this.completedActionObservations.reduce(function (r, o) {
      var date = new Date(o);
      var month = date.getMonth();
      var monthName = monthNames[month];
      r[monthName]
        ? r[monthName].count++
        : (r[monthName] = { monthName: monthName, count: 1 });
      return r;
    }, {});
    this.completedMonthresult = Object.keys(compgroup).map(
      (key) => compgroup[key]
    ).reverse();
    this.getchart2(this.completedMonthresult);

    // this.pendingActions = arrayData.filter(
    //   (obj) => obj.Status === 'InProgress'
    // );
    this.pendingActions = arrayData.filter(status);

    function status(res) {
      return res.Status === 'InProgress' && res.Status === 'Open';
    }
    this.pendingActionsCount = this.pendingActions.length;
    this.pendingActionObservations = this.pendingActions.map(
      (item) => item.ObservedDate
    );
    var pendinggroup = this.pendingActionObservations.reduce(function (r, o) {
      var date = new Date(o);
      var month = date.getMonth();
      var monthName = monthNames[month];
      r[monthName]
        ? r[monthName].count++
        : (r[monthName] = { monthName: monthName, count: 1 });
      return r;
    }, {});
    this.pendingMonthresult = Object.keys(pendinggroup).map(
      (key) => pendinggroup[key]
    );
    this.getchart3(this.pendingMonthresult);
  }

  onModuleChange(event: any): any {
    this.GetActionPlan();
    // const instance = this.gridApi.getFilterInstance('Module');
    // let modulesval = this.filterForm.value.module;
    // console.log(modulesval);
    // // let item = this.Modules.find((element) => element === event.itemValue);

    // const myArray: any = [];
    // modulesval.forEach((element) => {
    //   myArray.push(element.Name);
    // });

    // instance.setModel({
    //   values: myArray,
    // });
    // this.gridApi.onFilterChanged();
    // this.filterData();
  }

  onDeptChange(event: any): any {
    this.GetActionPlan();
    // const instance = this.gridApi.getFilterInstance('Department');
    // let deptval = this.filterForm.value.department;
    // // let item = this.Departments.find((element) => element === event.itemValue);
    // const myArray: any = [];
    // deptval.forEach((element) => {
    //   myArray.push(element.TypeName);
    // });
    // instance.setModel({
    //   values: myArray,
    // });
    // this.gridApi.onFilterChanged();
    // this.filterData();
  }

  onEmpChange(event: any): any {
    this.GetActionPlan();
    // const instance = this.gridApi.getFilterInstance('Responsible');
    // let empval = this.filterForm.value.employee;
    // // let item = this.Responsibles.find((element) => element === event.itemValue);
    // const myArray: any = [];
    // empval.forEach((element) => {
    //   myArray.push(element.fullName);
    // });
    // instance.setModel({
    //   values: myArray,
    // });
    // this.gridApi.onFilterChanged();
    // this.filterData();
  }
  onstatusChange(event: any): any {
    this.GetActionPlan();
    // const instance = this.gridApi.getFilterInstance('Status');
    // let statusval = this.filterForm.value.status;
    // // let item = this.Responsibles.find((element) => element === event.itemValue);
    // const myArray: any = [];
    // statusval.forEach((element) => {
    //   myArray.push(element.name);
    // });
    // instance.setModel({
    //   values: myArray,
    // });
    // this.gridApi.onFilterChanged();
    // this.filterData();
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#mainGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    var columnState = JSON.parse(
      localStorage.getItem('userActions-OverAllActionsColumnState')
    );
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  onFilterChanged(params): any {
    localStorage.setItem(
      'ag-grid-persistane-filtering',
      JSON.stringify(params.api.getFilterModel())
    );
  }
  onFirstDataRendered(params): void {
    const filterModel = localStorage.getItem('ag-grid-persistane-filtering');
    params.api.setFilterModel(JSON.parse(filterModel));
  }
  // getResponsible(data: any) {
  //   const unique = [...new Set(data.map((item) => item.Responsible))];
  //   const unique1 = unique.map(function (elem) {
  //     return { name: elem };
  //   });
  //   this.Responsibles = unique1;
  // }
  // getDepartments(data: any) {
  //   const unique = [...new Set(data.map((item) => item.Department))];
  //   const unique1 = unique.map(function (elem) {
  //     return { name: elem };
  //   });
  //   this.Departments = unique1;
  // }
  getDepartment() {
    const key = {
      ptId: this.filterForm.get('plant').value,
    };
    this.dtservice.getDeptData(key).subscribe((res: any) => {
      this.Departments = res;

      this.filterForm.get('department').setValue(this.Departments);
      this.getEmployees();
    });
  }
  onTDateChange(): void {
    this.GetActionPlan();
  }
  onChangeGroup(): void {
    this.getPlantByGroup();
    this.filterForm.get('module').setValue('');
    this.filterForm.get('department').setValue('');
    this.filterForm.get('employee').setValue('');
    //this.filterForm.get('status').setValue('')
  }
  onChangePlant(): void {
    this.setFirstWeek();
    this.spinner.show();
    this.getUserList();
    this.getDepartment();
    setTimeout(() => {
      this.GetActionPlan();
    }, 5000);
  }
  getPlantByGroup(): void {
    this.spinner.show();
    const key = {
      GroupId: this.filterForm.value.group,
    };
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.plants = data.DepartList.filter((p: any) => p.DeptDesc);
      this.filteredPlants = this.plants.slice();
      this.filterForm.get('plant')?.setValue(this.plants[0].DeptId);
      this.setFirstWeek();
      this.getUserList();
      this.getNewModule();

      // setTimeout(() => {this.GetActionPlan()}, 5000);

      //this.GetActionPlan();
    });
  }

  setFirstWeek(): void {
    const dept = this.plants.find(item => item.DeptId === this.filterForm.value.plant);
    this.firstDayOfWeek = dept ? dept.firstDayOfWeek : 0;
    this.setWeekValue(this.firstDayOfWeek);
  }

  setWeekValue(weekDay: number): void {
    if (weekDay == undefined || weekDay == null || Number.isNaN(weekDay))
      weekDay = 0
    sessionStorage.removeItem('weekDay');
    sessionStorage.setItem('weekDay', weekDay.toString());
    this.datepickerService.getFirstDayOfWeek()
  }

  getContextMenuItems = (params: any) => {
    if (params.node.data.Status !== 'Closed') {
      const result = [
        {
          name: 'Edit Row ',
          action: () => {
            this.EditRow(params);
          },
        },
      ];
      return result;
    } else {
      return [];
    }
  };

  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value: string) {
    let val;
    if (this.langObj && this.langObj[value]) {
      val = this.langObj[value];
    }
    return val ? val : value;
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if (this.langObj) {
        this.gridApi.refreshHeader();
      }
    });
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId =
          this.breadcrumList && this.breadcrumList.length
            ? this.breadcrumList[0].id
            : '';
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  onCellValueChanged(params: any): void {
  }

  EditRow(params: any): void {
    if (params) {
      this.data = [];
      this.ImageSave = null;
      this.fileSave = null;
      this.closefile = null;
      this.data = params.node.data;
      this.ModuleName = params.node.data.Module;
      this.UniqueCode = params.node.data.UniqueCode;
      this.HeaderId = params.node.data.HeaderId;
      this.currentAssigneeEmail = params.node.data.AssigneEmail;
      this.currentResponsibleEmail = params.node.data.ParticpantEmails;
      this.currentNodeDate = params.node.data.ObservedDate;
      this.editActionPlanForm.get('actions').setValue(params.node.data.Action);
      this.editActionPlanForm
        .get('assignTo')
        .setValue(params.node.data.AssignTo);
      this.editActionPlanForm
        .get('completed')
        .setValue(params.node.data.ActionCompleted);
      this.editActionPlanForm.get('remarks').setValue(params.node.data.Remarks);
      this.editActionPlanForm.get('remarks').setValue(params.node.data.Remarks);
      this.editActionPlanForm
        .get('newAssignee')
        .setValue(params.node.data.NewAssigneeId);
      if (params.node.data.ActionCompleted === 'No') {
        this.disableVerification = true;
      }
      if (params.node.data.Verification === 'Disapproved') {
        this.disableNewAssigne = false;
      }
      this.editActionPlanForm
        .get('verification')
        .setValue(params.node.data.Verification);
      this.editActionPlanForm
        .get('actionType')
        .setValue(params.node.data.ActionType);
      if (params.node.data.RevisedDate !== null) {
        this.editActionPlanForm
          .get('revisedDate')
          .setValue(
            this.datePipe.transform(
              new Date(params.node.data.RevisedDate),
              'yyyy-MM-dd'
            )
          );
      }
      if (params.node.data.TargetDate !== null) {
        this.editActionPlanForm
          .get('targetDate')
          .setValue(
            this.datePipe.transform(
              new Date(params.node.data.TargetDate),
              'yyyy-MM-dd'
            )
          );
      }
      this.targetDate = params.node.data.TargetDate;
      this.editActionPlanForm.get('rootcause')?.setValue(params.node.data.RootCause);
      // this.editActionPlanForm.get('targetDate').setValue(params.data.TargetDate) ;
      this.dialog.open(this.previewModal, { height: 'auto', width: '100%' });
    }
  }

  update(): void {
    this.data.AssignTo = this.editActionPlanForm.value.assignTo;
  }

  cellClicked(params: any): void {
    const ButtonType = params.event.target.textContent;
    const col = params.colDef.field;
    let classList = params.event.target.classList;

    if (ButtonType === 'edit') {
      this.EditRow(params);
      //this.dialog.open(this.previewModal, { height: '100%', width: '90%' });
    }

    if (col === 'picturebefore') {
      this.imageZoomModal1(params.value);
    }

    if (col === 'ClosedFile') {
      if (classList.contains('viewDocument')) {
        this.doc = params.data.closedFile;
        const fileType = params.data.closedFile.split('.').pop();
        if (
          fileType === 'jpeg' ||
          fileType === 'jpg' ||
          fileType === 'png' ||
          fileType === 'gif'
        ) {
          this.images = [];
          this.fileType = 'img';
          this.images.push(this.doc);
        } else {
          this.fileType = 'doc';
        }
        this.fileDesc = params.data?.KPIName;
        this.displayMaximizable = true;
      }
    }

  }

  getUserList(): void {
    // this.spinnerText = 'Fetching User Data';
    // this.spinner.show();
    const data = {
      EGId: this.filterForm.value.group,
      PlantId: this.filterForm.value.plant,
    };
    this.commonService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        if (!res.HasError) {
          this.userList = res.employeeInfoList;
          this.userList.map(
            (user: any) =>
            (user.FullName =
              user.FirstName.trim() + ' ' + user.lastName.trim())
          );
          this.getDepartment();
        }
        // this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  UpdateActionPlan(): void {
    // if (
    //   this.editActionPlanForm.value.remarks === null ||
    //   this.editActionPlanForm.value.remarks === ''
    // ) {
    //   this.toaster.warning(
    //     'Warning',
    //     `Please Fill The Remarks Field For Rootcause`
    //   );
    // } else {
    // console.log ('update' , this.editActionPlanForm.value.remarks) ;
    // console.log(this.data);
    this.Status = '';
    // console.log(this.data , 'daadajsdjadjasjd');
    this.Status =
      this.editActionPlanForm.value.targetDate &&
        !this.editActionPlanForm.value.revisedDate
        ? 'InProgress'
        : this.editActionPlanForm.value.targetDate &&
          this.editActionPlanForm.value.revisedDate
          ? 'Delayed'
          : 'Open';

    if (this.editActionPlanForm.value.targetDate) {
      this.targetDate = this.editActionPlanForm.value.targetDate;
      this.targetDate = new Date(this.targetDate);
      this.targetDate.setHours(new Date().getHours());
      this.targetDate.setMinutes(new Date().getMinutes());
      this.targetDate.setSeconds(new Date().getSeconds());
    }
    if (this.editActionPlanForm.value.revisedDate) {
      this.RevisedDate = this.editActionPlanForm.value.revisedDate;
      this.RevisedDate = new Date(this.RevisedDate);
      this.RevisedDate.setHours(new Date().getHours());
      this.RevisedDate.setMinutes(new Date().getMinutes());
      this.RevisedDate.setSeconds(new Date().getSeconds());
    }
    if (this.editActionPlanForm.value.completed === 'Yes') {
      this.Status = 'Complete';
      var templateBody = null;
      var plantName = null;
      var plant = this.plants.find(x => x.DeptId == this.filterForm.controls.plant.value);
      plantName = plant.DeptDesc;
      var date = null;
      var user = this.userList.find(x => x.EmpInfo_Id == this.editActionPlanForm.value.assignTo);
      if (this.editActionPlanForm.controls.revisedDate.value > this.editActionPlanForm.controls.targetDate.value) {
        date = this.editActionPlanForm.controls.revisedDate.value;
      }
      else {
        date = this.editActionPlanForm.controls.targetDate.value;
      }
      if (this.editActionPlanForm.controls.verification.value == 'Approved') {
        templateBody =
          `<div style="font-family: Helvetica; display: flex; flex-direction: column;">
          <div style="display: flex;background-color:#1d4ed8; color: white; padding: 2rem">
              <h2 style="margin-right: auto">O3 SMART MANUFACTURING</h2>
              <img src="https://res.cloudinary.com/durdmvneg/image/upload/v1629181792/logo-full_ku1lp4.png" style="display: block; border: 0px; object-fit:contain ;" />
          </div>
          <h2 style="margin-left: auto; margin-right: auto; margin-top: 1rem; font-weight: 800">Action Plan Detail</h2>
          <div style="background-color: #f3f4f6; padding: 1rem; display: flex;">
              <div style="width: 20%;font-weight: 800;">Plant: </div><div style="width: 80%;font-weight: 800;">`+ plantName + `</div>
          </div>
          <table style="margin-top: 1.5rem;">
              <tr>
                  <th style="background-color:#e7e5e4; padding: 0.8rem">Action Plan</th>
                  <th style="background-color:#e7e5e4; padding: 0.8rem">Created Date</th>
                  <th style="background-color:#e7e5e4; padding: 0.8rem">Target Date</th>
                  <th style="background-color:#e7e5e4; padding: 0.8rem">Responsible</th>
                  <th style="background-color:#e7e5e4; padding: 0.8rem">Status</th>
              </tr>
              <tr>
                  <td style='text-align: center'>`+ this.editActionPlanForm.controls.actions.value + `</td>
                  <td style='text-align: center'>`+ moment(this.currentNodeDate).format("DD/MM/YYYY hh:mm:ss") + `</td>
                  <td style='text-align: center'>`+ moment(date).format("DD/MM/YYYY hh:mm:ss") + `</td>
                  <td style='text-align: center'>`+ user.FullName + `</td>
                  <td style='text-align: center'>Closed</td>
              </tr>
          </table>
      </div>`;
      }
      else {
        templateBody =
          `<div style="font-family: Helvetica; display: flex; flex-direction: column;">
          <div style="display: flex;background-color:#1d4ed8; color: white; padding: 2rem">
              <h2 style="margin-right: auto">O3 SMART MANUFACTURING</h2>
              <img src="https://res.cloudinary.com/durdmvneg/image/upload/v1629181792/logo-full_ku1lp4.png" style="display: block; border: 0px; object-fit:contain ;" />
          </div>
          <h2 style="margin-left: auto; margin-right: auto; margin-top: 1rem; font-weight: 800">Action Plan Detail</h2>
          <div style="background-color: #f3f4f6; padding: 1rem; display: flex;">
              <div style="width: 20%;font-weight: 800;">Plant: </div><div style="width: 80%;font-weight: 800;">`+ plantName + `</div>
          </div>
          <table style="margin-top: 1.5rem;">
              <tr>
                  <th style="background-color:#e7e5e4; padding: 0.8rem">Action Plan</th>
                  <th style="background-color:#e7e5e4; padding: 0.8rem">Created Date</th>
                  <th style="background-color:#e7e5e4; padding: 0.8rem">Target Date</th>
                  <th style="background-color:#e7e5e4; padding: 0.8rem">Responsible</th>
                  <th style="background-color:#e7e5e4; padding: 0.8rem">Status</th>
              </tr>
              <tr>
                  <td style='text-align: center'>`+ this.editActionPlanForm.controls.actions.value + `</td>
                  <td style='text-align: center'>`+ moment(this.currentNodeDate).format("DD/MM/YYYY hh:mm:ss") + `</td>
                  <td style='text-align: center'>`+ moment(date).format("DD/MM/YYYY hh:mm:ss") + `</td>
                  <td style='text-align: center'>`+ user.FullName + `</td>
                  <td style='text-align: center'>Completed</td>
              </tr>
          </table>
      </div>`;
      }

      var emailParam = {
        Body: templateBody,
        ToEmailAddress: this.currentAssigneeEmail,
        CCEmails: this.currentResponsibleEmail,
        Subject: 'Action Completion'
      }
      // this.actionplanService.sendMail(emailParam).subscribe((res) => {
      //   this.toaster.success("Mail sent", "Success");
      // },
      //   ({ error }) => {
      //     this.toaster.error("Cannot Send Mail", "Error");
      //   });
    }
    if (this.editActionPlanForm.value.verification === 'Approved') {
      this.Status = 'Closed';
      // fiveWhy.ClosedDate = new Date();
    } else if (this.editActionPlanForm.value.verification === 'Disapproved') {
      this.Status = 'ReOpened';
      // fiveWhy.ActionCompleted = 'No';
      this.editActionPlanForm.get('completed').setValue('No');
    }

    const formdata = new FormData();
    formdata.append('file', this.fileSave);
    formdata.append('image', this.ImageSave);
    formdata.append('closefile', this.closefile);

    const key = {
      actionId: this.data.ActionId,
      actionName: this.editActionPlanForm.value.actions,
      actionType: this.editActionPlanForm.value.actionType,
      responsible: this.editActionPlanForm.value.assignTo,
      targetDate: this.targetDate ? this.targetDate : null,
      revisedDate: this.RevisedDate ? this.RevisedDate : null,
      actionCompleted: this.editActionPlanForm.value.completed,
      Verification: this.editActionPlanForm.value.verification,
      remarks: this.editActionPlanForm.value.remarks,
      newAssignee: this.editActionPlanForm.value.newAssignee
        ? this.editActionPlanForm.value.newAssignee
        : null,
      status: this.Status,
      userId: JSON.parse(localStorage.user).UserId,
      headerId: this.HeaderId,
      rootCause : this.editActionPlanForm.value.rootcause
    };
    formdata.append('values', JSON.stringify(key));
    this.spinner.show();
    this.actionplanService.editActionPlan(formdata).subscribe((res: any) => {
      this.spinner.hide();
      this.GetActionPlan();
      this.dialog.closeAll();
      this.showinput = false;
      if (res[0].Column1 === 'Updated Successfully') {
        this.toaster.success('Success', `Updated Sucessfully `);
      }
    } , 
    (error: any) =>{
      this.spinner.hide();
    });
    // }
  }

  uploadedFile(event: any): void {
    if (event.target.files.length > 0) {
      this.closefile = event.target.files[0];
    }
  }

  getMinDate(type: any): any {
    if (type === 'Revised') {
      const date = this.data.RevisedDate
        ? this.data.RevisedDate
        : this.data.TargetDate;
      const currentDate = new Date(date);

      const minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate() + 1)
      );

      return (current: Date): boolean =>
        differenceInCalendarDays(current, minDateStartFrom) < 0;
    } else if (type === 'Target') {
      const currentDate = new Date();
      const minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate())
      );

      return (current: Date): boolean =>
        differenceInCalendarDays(current, minDateStartFrom) < 0;
    }
  }

  openFile(type: any): void {
    if (type === 'pic') {
      this.fileInput.nativeElement.click();
    } else {
      this.fileInputFile.nativeElement.click();
    }
  }

  readFile(event: any, type: any): void {
    this.imageFile = event.target.files[0];
    if (type === 'pic') {
      this.data.UploadFile = this.imageFile;
      this.ImageSave = event.target.files[0];
    } else {
      this.data.UploadDoc = this.imageFile;
      this.fileSave = event.target.files[0];
    }
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        if (type === 'pic') {
          this.data.FileSrc = this.imageSrc;
        } else {
          this.data.DocSrc = this.imageSrc;
        }
      };
    }
  }

  deleteFile(type: any): void {
    if (type === 'pic') {
      this.data.FileSrc = '';
      this.data.UploadFile = '';
      this.ImageSave = new File([''], 'filename');
    } else {
      this.data.DocSrc = '';
      this.data.UploadDoc = '';
      this.fileSave = new File([''], 'filename');
    }
  }

  onChangeComplete(): void {
    // console.log( 'compelete' , this.editActionPlanForm.value.completed);
    if (this.editActionPlanForm.value.completed === 'Yes') {
      this.disableVerification = false;
      this.editActionPlanForm.get('verification').setValue('');
    }
    else {
      this.disableVerification = true;
    }
  }

  onChangeVerification(): void {
    if (this.editActionPlanForm.value.verification === 'Disapproved') {
      this.disableNewAssigne = false;
    } else if (this.editActionPlanForm.value.verification !== 'Disapproved') {
      this.disableNewAssigne = true;
    }
    if (this.editActionPlanForm.value.verification === 'Approved') {
      this.showinput = true;
    } else {
      this.showinput = false;
    }
  }

  targetChange(): void {
  }
  onChangeRemarks(event: any): void {
  }

  imageZoomModal(): void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      height: '90%',
      width: '90%',
      data: (this.data.FileSrc ? this.data.FileSrc : environment.O3CoreApiImageUrl) + this.data.UploadFile,
    });
    // this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  downloadPDF() {
    // [href]=" yData.DocSrc || blobStorageUrl + yData.UploadDoc"
    if (this.data.DocSrc) {
      const base64 = this.data.DocSrc;
      var a = document.createElement("a"); //Create <a>
      a.href = base64; //Image Base64 Goes here
      a.target = '_blank'
      a.download = "file.pdf"; //File name Here
      a.click();
    }
    else {
      var a = document.createElement("a"); //Create <a>
      a.href = this.data.UploadDoc; //Image Base64 Goes here
      a.download = "file.pdf";
      a.target = '_blank'//File name Here
      a.click();
    }
  }

  imageZoomModal1(data): void {
    if (data == '') {
      let html = 'assets/images/no-image.png';
      let dialogRef = this.dialog.open(ImageDialogComponent, {
        height: '90%',
        width: '90%',
        data: html,
      });
    } else {
      let dialogRef = this.dialog.open(ImageDialogComponent, {
        height: '90%',
        width: '90%',
        data: data,
      });
    }

    // this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  openAddActionModal() {
    this.addActionForm?.reset();
    this.addActionForm.get('actionType')?.setValue('Corrective');
    this.addStatus = 'Open';
    this.dialog.open(this.addActionModal, { height: 'auto', width: '100%' });
  }

  addImageZoomModal(): void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      height: '90%',
      width: '90%',
      data: this.addImageSrc || environment.O3CoreApiImageUrl + this.addImage,
    });
    // this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  addDeleteFile(type: any): void {
    if (type === 'pic') {
      this.addImageSrc = '';
      this.addImage = null;
    } else {
      this.addFileSrc = '';
      this.addFile = null;
    }
  }

  addOpenFile(type: any): void {
    if (type === 'pic') {
      this.addImageInput.nativeElement.click();
    } else {
      this.addFileInput.nativeElement.click();
    }
  }

  addReadFile(event: any, type: any): void {
    if (type === 'pic') {
      this.addImage = event.target.files[0];
    } else {
      this.addFile = event.target.files[0];
    }
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (type === 'pic') {
          this.addImageSrc = reader.result as string;
        } else {
          this.addFileSrc = reader.result as string;
        }
      };
    }
  }

  addDownloadPDF() {
    // [href]=" yData.DocSrc || blobStorageUrl + yData.UploadDoc"
    if (this.addFileSrc) {
      const base64 = this.addFileSrc;
      var a = document.createElement("a"); //Create <a>
      a.href = base64; //Image Base64 Goes here
      a.target = '_blank'
      a.download = "file.pdf"; //File name Here
      a.click();
    }
    else {
      var a = document.createElement("a"); //Create <a>
      a.href = this.addFileSrc; //Image Base64 Goes here
      a.download = "file.pdf";
      a.target = '_blank'//File name Here
      a.click();
    }
  }

  addGetMinDate(type: any): any {
    if (type === 'Revised') {
      const date = this.addActionForm.value.targetDate
        ? this.addActionForm.value.RevisedDate
        : this.addActionForm.value.TargetDate;
      const currentDate = new Date(date);

      const minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate() + 1)
      );

      return (current: Date): boolean =>
        differenceInCalendarDays(current, minDateStartFrom) < 0;
    } else if (type === 'Target') {
      const currentDate = new Date();
      const minDateStartFrom = new Date(
        currentDate.setDate(currentDate.getDate())
      );

      return (current: Date): boolean =>
        differenceInCalendarDays(current, minDateStartFrom) < 0;
    }
  }

  addOnChangeComplete(): void {
    // console.log( 'compelete' , this.editActionPlanForm.value.completed);
    if (this.addActionForm.value.completed === 'Yes') {
      this.addDisableVerification = false;
      this.addActionForm.get('verification').setValue('');
    }
    else {
      this.addDisableVerification = true;
    }
  }

  addOnChangeVerification(): void {
    if (this.addActionForm.value.verification === 'Disapproved') {
      this.addDisableNewAssigne = false;
    } else if (this.addActionForm.value.verification !== 'Disapproved') {
      this.addDisableNewAssigne = true;
    }
    if (this.addActionForm.value.verification === 'Approved') {
      this.addShowinput = true;
    } else {
      this.addShowinput = false;
    }
  }

  addCloseFile(event: any): void {
    if (event.target.files.length > 0) {
      this.addActionCloseFile = event.target.files[0];
    }
  }

  addAction(): void {
    this.addStatus =
      this.addActionForm.value.targetDate &&
        !this.addActionForm.value.revisedDate
        ? 'InProgress'
        : this.addActionForm.value.targetDate &&
          this.addActionForm.value.revisedDate
          ? 'Delayed'
          : 'Open';
    if (this.editActionPlanForm.value.completed === 'Yes') {
      this.addStatus = 'Complete';
    }
    if (this.addActionForm.value.verification === 'Approved') {
      this.addStatus = 'Closed';
      // fiveWhy.ClosedDate = new Date();
    } else if (this.addActionForm.value.verification === 'Disapproved') {
      this.addStatus = 'ReOpened';
      // fiveWhy.ActionCompleted = 'No';
      this.addActionForm.get('completed').setValue('No');
    }
    const formdata = new FormData();
    formdata.append('file', this.addFile);
    formdata.append('image', this.addImage);
    // formdata.append('closefile', this.addActionCloseFile);

    const key = {
      actionName: this.addActionForm.value.action,
      actionType: this.addActionForm.value.actionType,
      responsible: this.addActionForm.value.assignTo,
      targetDate: this.targetDate ? this.targetDate : null,
      revisedDate: this.RevisedDate ? this.RevisedDate : null,
      actionCompleted: this.addActionForm.value.completed,
      Verification: this.addActionForm.value.verification,
      remarks: this.addActionForm.value.remarks,
      rootcause: this.addActionForm.value.rootcause,
      newAssignee: this.addActionForm.value.newAssignee
        ? this.addActionForm.value.newAssignee
        : null,
      status: this.addStatus,
      userId: JSON.parse(localStorage.user).UserId,
      plantId: this.filterForm.value.plant
    };
    formdata.append('values', JSON.stringify(key));
    this.actionplanService.AddActionUserAction(formdata).subscribe(({
      next: (res: any) => {
        this.toaster.success('Success', 'Saved Successfully');
        this.dialog.closeAll();
        this.GetActionPlan();
      },
      error: (res: any) => {
        this.toaster.error('Error')
      }
    }))
  }

}

function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}
