import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
  ChartComponent
} from "ng-apexcharts";
import * as Chart from 'chart.js';
// import * as Chart from 'chart.js';
import * as _moment from 'moment';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';

const moment = _moment;

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-cu-analysis',
  templateUrl: './cu-analysis.component.html',
  styleUrls: ['./cu-analysis.component.scss']
})
export class CuAnalysisComponent implements OnInit {
 
  public chartOptions!: Partial<ChartOptions>;
  dropDownForm!: FormGroup;
  //Drop-down Variables
  yearList = ['2015', '2016', '2017', '2018', '2019', '2020', '2021'];
  monthList = ['ALL', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  weekList = ['ALL', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  dayList = ['ALL', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  shiftList = ['ALL', 'Morning', 'Evening', 'Night'];
  machineList = ['ALL', 'RTM1', 'RTM2'];


  canvas: any;
  ctx: any;
  canvas1: any;
  ctx1: any;
  canvas2: any;
  ctx2: any;
  canvas3: any;
  ctx3: any;
  canvas4:any;
  ctx4:any;
  // Date Time Range
  range = new FormGroup({
    startDate: new FormControl(moment()),
    endDate: new FormControl(moment())
  });

  // Progress chart



  capacityUtilization = {
    barType: 'radial',
    color: '#10CE9C',
    secondColor: '#E5E5E5',
    progress: 65,
    radial: {
      depth: 8,
      size: 96,
      label: {
        enable: true,
        color: '#09608c',
      }
    }
  };

  // Charts

  // CU Loses
  cuLoses = {
    barChartLabels: ['CU', 'No Plan', 'Shutdowns', 'Org. Losses4', 'Target'],
    barChartData: [
      { data: [85, 0, 0, 0, 0], label: '  Achieved' },
      { data: [0, [85, 92], [92, 97], [97, 100.1], 0], label: '  Losses' },
      { data: [0, 0, 0, 0, 100.1], label: '  Target Loss' },
    ],
    barChartColors: [
      { backgroundColor: "#10CE9C" },
      { backgroundColor: "#F85439" },
      { backgroundColor: "#040496" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 20,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            },
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Organizational Losses
  organizationalLosses = {
    barChartLabels: ['Breaktime', 'Training', 'No-man Power', 'Meeting'],
    barChartData: [
      { data: [10.1, 6.2, 5, 1], label: '  Achieved' },
      { data: [0, 0, 0, 0], label: '  Losses' }
    ],
    barChartColors: [
      { backgroundColor: "#F85439" },
      { backgroundColor: "#10CE9C" },
    ],
    barChartOptions: {
      labelString: "  Percentage",
      tooltips: {
        callbacks: {
          label: function (tooltipItem:any, data:any) {
            return data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 2,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Shutdown Reasons
  shutdownReasons = {
    barChartLabels: ['Lorem', 'Lorem 1', 'Lorem 2', 'Lorem 4'],
    barChartData: [
      { data: [12.1, 8.5, 6.2, 4], label: '  Lorem Ipsem' },
      { data: [0, 0, 0, 0], label: '  Lorem Ipsem' }
    ],
    barChartColors: [
      { backgroundColor: "#F85439" },
      { backgroundColor: "#10CE9C" },
    ],
    barChartOptions: {
      labelString: "  Percentage",
      tooltips: {
        callbacks: {
          label: function (tooltipItem:any, data:any) {
            return data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 2,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;

  constructor(

    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private usermanagementService: UserManagementService,
    public router: Router
  ) {

    // this.chartOptions = {
    //   series: [78],
    //   chart: {
    //     height: 350,
    //     type: "radialBar"
    //   },
    //   plotOptions: {
    //     radialBar: {
    //       hollow: {
    //         size: "60%"
    //       }
    //     }
    //   },
    //   labels: ["Cricket"]
    // };
   }

  ngOnInit(): void {
    Chart.defaults.global.legend.labels.usePointStyle = true;
    this.dropDownForm = this.formBuilder.group({
      groupList: [''],
      UnitDataList: [''],
    });


    // this.capacityUtilization = {
     
    //   barType: 'radial',
    //   color: '#10CE9C',
    //   secondColor: '#E5E5E5',
    //   progress: 65,
    //   radial: {
    //     depth: 8,
    //     size: 96,
    //     label: {
    //       enable: true,
    //       color: '#09608c',
    //     }
    //   }
    // };
  
    

    this.canvas = document.getElementById('myChart');
    this.canvas.height = 300;
    this.canvas.width = 680;
    this.ctx = this.canvas.getContext('2d');
    // this.ctx.height = 400;
    const defectClosing = new Chart(this.ctx, {
      type: 'bar',
      data: {
        labels: ['CU','No Plan','ShutDowns','Org Losses4','Target'],
        datasets: [  
         { data: [85, 0, 0, 0, 0], label: '  Achieved' ,backgroundColor: "#10CE9C"},
         {data: [0, [85, 92], [92, 97], [97, 100.1], 0], label: '  Losses', backgroundColor: "#F85439"  },
         {  data: [0, 0, 0, 0, 100.1], label: '  Target' , backgroundColor: "#040496"},
         
       ],
      
      
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        display: true,
        labelString: 'Percentage',
        tooltips: {
          callbacks: {
            label: (tooltipItem: any, data: any) => {
              return data.datasets[0].data[tooltipItem.index] + '%';
            }
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            padding: 20
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
           barThickness: 44,
          
            gridLines: {
              display: false,

            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              borderDash: [8,4],
            },
            ticks: {
              gridDashType: 'dash',
              
               beginAtZero: true,
               stepSize: 20,
              min: 0,
              max: 120,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }]
        }
      }
    });



    this.canvas1 = document.getElementById('myChart1');
    this.canvas1.height = 300;
    this.canvas1.width = 480;
    this.ctx1 = this.canvas1.getContext('2d');
    // this.ctx.height = 400;
    const org = new Chart(this.ctx1, {
      type: 'bar',
      data: {
        labels: ['Breaktime', 'Training', 'No-man Power', 'Meeting'],
        datasets: [  
         { data: [10.1, 6.2, 5, 1], label: '  Achieved' ,backgroundColor: "#F85439"},
         { data: [0, 0, 0, 0], label: '  Losses', backgroundColor: "#10CE9C"},
       ],
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        display: true,
        labelString: 'Percentage',
        tooltips: {
          callbacks: {
            label: (tooltipItem: any, data: any) => {
              return data.datasets[0].data[tooltipItem.index] + '%';
            }
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            padding: 20
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
           barThickness: 44,
          
            gridLines: {
              display: false,

            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              gridDashType: 'dash',
              
               beginAtZero: true,
               stepSize: 2,
              min: 0,
              max: 12,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }]
        }
      }
    });

    this.canvas2 = document.getElementById('myChart2');
    this.canvas2.height = 300;
    this.canvas2.width = 480;
    this.ctx2 = this.canvas2.getContext('2d');
    // this.ctx.height = 400;
    const shut = new Chart(this.ctx2, {
      type: 'bar',
      data: {
        labels: ['No Orders','Holidays','No raw Materials','New Product Trial'],
        datasets: [  
         { data: [12.1, 8.5, 6.2, 4], label: '  Achieved' ,backgroundColor: "#F85439"},
         {  data: [0, 0, 0, 0], label: '  Losses', backgroundColor: "#10CE9C"},
        
         
       ],
      
      
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        display: true,
        labelString: 'Percentage',
        tooltips: {
          callbacks: {
            label: (tooltipItem: any, data: any) => {
              return data.datasets[0].data[tooltipItem.index] + '%';
            }
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            padding: 20
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
           barThickness: 44,
          
            gridLines: {
              display: false,

            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              gridDashType: 'dash',
              
               beginAtZero: true,
               stepSize: 2,
              min: 0,
              max: 14,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }]
        }
      }
    });


    this.GetMenuAccess();
    this.GetBreadcrumList();
  }


  //breadCrumb

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;

    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any{
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([url]));
    // return false;
  }


       
}


