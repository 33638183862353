import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { SixWTwoH } from 'src/app/models/analytical-toolsModels/sixwtwoh';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { SixwtwohService } from 'src/app/services/sixwtwoh.service';

@Component({
  selector: 'app-at6w2h',
  templateUrl: './at6w2h.component.html',
  styleUrls: ['./at6w2h.component.scss'],
})
export class At6w2hComponent implements OnInit {
  @Input() isAtNcr = false;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Output() _emitHeaderData: EventEmitter<any> = new EventEmitter();
  @Input() _headerData: EventEmitter<any> = new EventEmitter();

  spinnerText: string = '';
  stageName: string = '6W2H';
  stageId: number = 1;
  breadcrumList!: any;
  isReset: boolean = false;
  headerData: any = {
    ID: 0,
    Group: '',
    Plant: '',
    Dept: '',
    Team: '',
    TeamLeader: '',
    InitProbStatement: '',
    NoOfM: 6,
    stageId: 1,
    uniqueCode: '',
    CreatedAt: new Date(),
    CreatedBy: '',
  };
  rcaId: number = 0;
  form = new FormGroup({
    what: new FormControl('', [Validators.required]),
    who: new FormControl('', [Validators.required]),
    where: new FormControl('', [Validators.required]),
    whom: new FormControl(''),
    which: new FormControl('', [Validators.required]),
    how: new FormControl('', [Validators.required]),
    when: new FormControl('', [Validators.required]),
    howMuch: new FormControl(''),
    problemStatement: new FormControl('', [Validators.required]),
  });
  sixwtwoh: SixWTwoH;
  user: any;
  rca: boolean = false;
  levelId: number = 1;
  updateStatus: boolean = false;
  @Input() onSubmit: Observable<any>;
  @Input() sixWTwoh_ = new EventEmitter();
  @Input() headerID: number;
  sub: Subscription;
  constructor(
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private analyticalToolsService: AnalyticalToolsService,
    private router: Router,
    private sixwtwohService: SixwtwohService
  ) {
    this.sixwtwoh = {
      ID: 0,
      What: '',
      Where: '',
      Which: '',
      When: '',
      Who: '',
      ToWhom: '',
      How: '',
      HowMuch: '',
      ProblemStatement: '',
    };
  }

  ngOnInit(): void {
    this.sub = this.sixWTwoh_.subscribe((event: any) => {
      this.save6w2h(event);
    });
    window.scrollTo(0, 0);

    this.headerData.ID = this.headerID;
    this.rca = this.analyticalToolsService.getRca();
    this.rcaId = this.analyticalToolsService.getRcaId();
    this.getSixWTwoH(this.headerData.ID, '');

    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.headerData.CreatedBy = this.user.UserId;
    const userGroup = this.analyticalToolsService.getUserGroup();
    if (userGroup) this.headerData.Group = userGroup;
  }

  get f() {
    return this.form.controls;
  }

  clone6w2h() {}
  getSixWTwoH(headerId: number, reset: any) {
    this.spinnerText = 'Fetching 6-W-2-H Data';
    this.spinner.show();
    this.sixwtwohService.getSixWTwoH(headerId).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res && res.data && res.data.swth) {
          this.analyticalToolsService.setTeamLeaderList(res.data.Team);
          this.rcaId = res.data.rcaId;
          this.levelId =
            res && res.data && res.data.stageId
              ? res.data.stageId
              : this.stageId;
          delete res.data.rcaId;
          this.updateStatus = res && res.data && res.data.swth ? true : false;
          this.sixwtwoh = res.data.swth;
          this.headerData = res.data.ID ? res.data : this.headerData;
          this.analyticalToolsService.setHeaderId(this.headerData.ID);
          this.analyticalToolsService.setRcaId(this.rcaId);
          this.analyticalToolsService.setLevelId(this.levelId);
          this.analyticalToolsService.setStatus(this.updateStatus);
        }
        if (reset == 'reset' && !headerId) this.resetData();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  concatProblemStatement() {
    this.sixwtwoh.ProblemStatement =
      this.sixwtwoh.What +
      ' ' +
      this.sixwtwoh.Where +
      ' ' +
      this.sixwtwoh.When +
      ' ' +
      this.sixwtwoh.Which +
      ' ' +
      this.sixwtwoh.Who +
      ' ' +
      this.sixwtwoh.How +
      ' ' +
      this.sixwtwoh.HowMuch +
      ' ' +
      this.sixwtwoh.ToWhom;
  }

  // save6w2h(event: any) {}

  save6w2h(e: any) {
    let event = e.headerData;
    let integrationData = e.integrationData;
    delete event?.FyActionFileCount;
    delete event?.CauseFileCount;
    delete event?.stageID;
    if (this.headerData.rca) {
      event.rca = this.headerData.rca;
    }
    if (
      !this.sixwtwoh.What &&
      !this.sixwtwoh.Where &&
      !this.sixwtwoh.When &&
      !this.sixwtwoh.Which &&
      !this.sixwtwoh.Who &&
      !this.sixwtwoh.How &&
      !this.sixwtwoh.HowMuch &&
      !this.sixwtwoh.ToWhom
    )
      event.Status6W2H = 'Open';
    else if (this.form.invalid) event.Status6W2H = 'InProgress';
    else event.Status6W2H = 'Completed';

    delete event.uniqueCode;
    event.stageId = this.levelId ? this.levelId : this.stageId;
    const formData = new FormData();

    formData.append('UploadFile', event.UploadFile || undefined);
    formData.append('header', JSON.stringify(event));
    formData.append('data', JSON.stringify(this.sixwtwoh));
    let router = this.router.url;
    if (!router.includes('at/at-stages')) {
      formData.append('integrationData', JSON.stringify(integrationData));
    }

    this.spinnerText = 'Saving 6W2H Data';
    this.spinner.show();
    if (this.headerData.ID && this.sixwtwoh.ID) {
      this.sixwtwohService.updateSixWTwoh(formData).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toaster.success('Success', res.message);
          this._emitHeaderData.emit(res.data);

          this.analyticalToolsService.setRcaId(res.data.rcaId);
          if (res.data.rcaId) {
            this.analyticalToolsService.setHeaderId(res.data.headerId);
            this.analyticalToolsService.setStatus(this.updateStatus);
          } else {
            this.analyticalToolsService.setHeaderId(res.data.headerId);
            this.analyticalToolsService.setStatus(this.updateStatus);
          }
          this.headerData.ID = this.analyticalToolsService.getHeaderId() || 0;
          this.onSuccess.emit('sixw2h');

          this.getSixWTwoH(this.headerData.ID, '');

          //reseting the state
          this.sixwtwoh = {
            ID: 0,
            What: '',
            Where: '',
            Which: '',
            When: '',
            Who: '',
            ToWhom: '',
            How: '',
            HowMuch: '',
            ProblemStatement: '',
          };
        },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      this.sixwtwohService.addSixWTwoh(formData).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toaster.success('Success', res.message);
          this._emitHeaderData.emit(res.data);

          this.analyticalToolsService.setRcaId(res.data.rcaId);
          if (res.data.rcaId) {
            this.analyticalToolsService.setHeaderId(res.data.headerId);
          } else {
            this.analyticalToolsService.setHeaderId(res.data.headerId);
            this.updateStatus = true;
            this.analyticalToolsService.setStatus(this.updateStatus);
          }
          this.headerData.ID = this.analyticalToolsService.getHeaderId() || 0;
          this.onSuccess.emit('sixw2h');
          this.getSixWTwoH(this.headerData.ID, '');
        },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );
    }
    // }
  }

  resetData() {
    this.sixwtwoh = {
      ID: 0,
      What: '',
      Where: '',
      Which: '',
      When: '',
      Who: '',
      ToWhom: '',
      How: '',
      HowMuch: '',
      ProblemStatement: '',
    };
    // this.form.reset();
    this.isReset = true;
    this.headerData = {
      ID: 0,
      Group: '',
      Plant: '',
      Dept: '',
      Team: '',
      TeamLeader: '',
      InitProbStatement: '',
      NoOfM: 6,
      stageId: 1,
      uniqueCode: '',
      CreatedAt: new Date(),
      CreatedBy: this.user.UserId,
    };
    this.analyticalToolsService.setRca(false);
    this.analyticalToolsService.setRcaId(0);
    this.analyticalToolsService.setHeaderId(0);
    this.analyticalToolsService.setFilteredIndex(0);
    this.analyticalToolsService.setFilteredData([]);
    this.analyticalToolsService.setTeamLeaderList([]);
    // this.isReset = true;
    // this.analyticalToolsService.setHeaderId(this.headerData.ID);
  }

  setSearchData(event: any) {
    if (event && event.swth && event.swth.ID) {
      this.headerData = event;
      // if (event.StatusCycle && event.StatusCycle == 'Completed') {
      //   if (event.stageId == 1)
      //     this.toaster.info('Info', 'This Cycle Has Been Completed From 6W2H');
      //   else if (event.stageId == 2)
      //     this.toaster.info('Info', 'This Cycle Has Been Completed From Fishbone');
      //   else if (event.stageId == 3)
      //     this.toaster.info('Info', 'This Cycle Has Been Completed From Cause & Effect');
      //   else if (event.stageId == 4)
      //     this.toaster.info('Info', 'This Cycle Has Been Completed From 5Why');
      // }
      // else {
      if (event.stageId == 1) {
        if (
          (event.Status6W2H && event.Status6W2H == 'InProgress') ||
          !event.Status6W2H ||
          event.Status6W2H == 'Open'
        ) {
          this.router.navigate(['/at/sixwtwoh']);
          this.getSixWTwoH(event.ID, '');
        } else if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/sixwtwoh']);
          this.getSixWTwoH(event.ID, '');
          this.toaster.info('Info', 'This Cycle Has Been Completed From 6W2H');
        }
      } else if (event.stageId == 2) {
        if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fishbone']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Fishbone'
          );
        }
      } else if (event.stageId == 3) {
        if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/causeEffect']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Cause & Effect'
          );
        }
      } else if (event.stageId == 4) {
        if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fivewhy']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 5Why');
        }
      }
      // this.toaster.info('Info', '6W2H Data Not Found For This Header ID');
      // }
      // this.getSixWTwoH(this.headerData.ID, '');

      // this.analyticalToolsService.setTeamLeaderList(event.Team);
      // this.headerData = event;
      // this.sixwtwoh = event.swth;
      // this.rcaId = event.rcaId;
      // this.updateStatus = event.ID ? true : false;
      // this.analyticalToolsService.setHeaderId(this.headerData.ID);
      // this.analyticalToolsService.setStatus(this.updateStatus);
      // this.analyticalToolsService.setLevelId(this.levelId);
      // this.analyticalToolsService.setRcaId(this.rcaId);
      // this.analyticalToolsService.setRca(false);
      // this.headerData.CreatedBy = this.user.UserId;
      // delete event.rcaId;
    } else {
      if (event.stageId == 1) {
        if (
          (event.Status6W2H && event.Status6W2H == 'InProgress') ||
          !event.Status6W2H ||
          event.Status6W2H == 'Open'
        ) {
          this.router.navigate(['/at/sixwtwoh']);
          this.getSixWTwoH(event.ID, '');
        } else if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/sixwtwoh']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 6W2H');
        }
      } else if (event.stageId == 2) {
        if (
          (event.StatusFishbone && event.StatusFishbone == 'InProgress') ||
          !event.StatusFishbone ||
          event.StatusFishbone == 'Open'
        ) {
          this.router.navigate(['/at/fishbone']);
        } else if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fishbone']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Fishbone'
          );
        }
      } else if (event.stageId == 3) {
        if (
          (event.StatusCauseEffect &&
            event.StatusCauseEffect == 'InProgress') ||
          !event.StatusCauseEffect ||
          event.StatusCauseEffect == 'Open'
        ) {
          this.router.navigate(['/at/causeEffect']);
        } else if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/causeEffect']);
          this.toaster.info(
            'Info',
            'This Cycle Has Been Completed From Cause & Effect'
          );
        }
      } else if (event.stageId == 4) {
        if (
          (event.StatusFiveWhy && event.StatusFiveWhy == 'InProgress') ||
          !event.StatusFiveWhy ||
          event.StatusFiveWhy == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
        } else if (
          (event.StatusFiveWhyAction &&
            event.StatusFiveWhyAction == 'InProgress') ||
          !event.StatusFiveWhyAction ||
          event.StatusFiveWhyAction == 'Open'
        ) {
          this.router.navigate(['/at/fivewhy']);
          // this.router.navigate(['/at/fivewhy-actpln'], { queryParams: { headerId: event.ID } });
        } else if (event.StatusCycle && event.StatusCycle == 'Completed') {
          this.router.navigate(['/at/fivewhy']);
          this.toaster.info('Info', 'This Cycle Has Been Completed From 5Why');
        }
      }
      // this.toaster.info('Info', '6W2H Data Not Found For This Header ID');
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
