import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SixwtwohService {

  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) {
  }

  // *** Core Api Method *** //
  getSixWTwoH(headerId: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.get<any[]>(this.baseUrl + 'find-six-w-two-h', { headers: header, params: { headerId: headerId } });
    return this.http.get<any[]>(this.o3CoreApiUrl + 'AT6W2H/ShowSixWTwoH', { headers: header, params: { headerId: headerId } });
  }

  // *** Core Api Method *** //
  addSixWTwoh(data: any): Observable<any[]> {
    console.log('datadata', data);
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.post<any[]>(this.baseUrl + 'add-six-w-two-h', data, { headers: header });
    return this.http.post<any[]>(this.o3CoreApiUrl + 'AT6W2H/addSixWTwoH', data, { headers: header });
  }

  // *** Core Api Method *** //
  updateSixWTwoh(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.put<any[]>(this.baseUrl + 'update-six-w-two-h/', data, { headers: header });
   return this.http.post<any[]>(this.o3CoreApiUrl + 'AT6W2H/updateSixTwoH', data, { headers: header });
  }

}
