<section>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
    <p style="color: white">Fetching Data...</p>
  </ngx-spinner>
  <form [formGroup]="opmsreportForm">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row">
        <div class="inputField">
          <nz-date-picker class="calender-input" id="picker" (ngModelChange)="OnSelectReport()"
            formControlName="startDate" [nzDisabledDate]="" nzFormat="yyyy-MM-dd"></nz-date-picker>
          <span class="floating-text" style="margin-left: 10px;">Start Date</span>
        </div>
        <div class="inputField">
          <nz-date-picker class="calender-input" id="picker" (ngModelChange)="OnSelectReport()"
            formControlName="endDate" [nzDisabledDate]="" nzFormat="yyyy-MM-dd"></nz-date-picker>
          <span class="floating-text" style="margin-left: 10px;">End Date</span>
        </div>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Reports</mat-label>
          <mat-select panelClass="testClass" formControlName="report" name="report" (selectionChange)="OnSelectReport()">
            <mat-option *ngFor="let report of reports" [value]="report.value">
              {{ report.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button  mat-mini-fab mat-button color="primary" pTooltip="Export" tooltipPosition="top" (click)="exportAsXLSX()" style="margin-left: 10px; margin-top: 5px;">
          <mat-icon>print</mat-icon>
        </button>

        <!-- <button type="button" mat-raised-button color="warn"
          style="background-color: #0f0fd1;font-size: 14px!important; margin-left: 20px;height:40px;margin-top: 5px;"  (click)="exportAsXLSX()">
        <span style="font-size: 12px;color: white;">Export To Excel</span></button> -->


      <!-- <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label>Reports</mat-label>
        <mat-select panelClass="testClass" formControlName="report" (selectionChange)="OnSelectReport()">
          <mat-option *ngFor="let report of reports" [value]="report.value">
            {{ report.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      </div>
    </div>
  </form>
  <div *ngIf="sizewisereport">
    <ag-grid-angular style="width: 100%; height: 30vw; margin-top: 10px;" class="ag-theme-alpine"
      [rowData]="sizerowData" [columnDefs]="sizecolumnDefs" (gridReady)="onGridReadySize($event)" (firstDataRendered)="sizeFirstData($event)" [pagination]="true" [paginationPageSize]="50">
    </ag-grid-angular>
  </div>

  <div *ngIf="thicknessreport">
    <ag-grid-angular style="width: 100%; height: 30vw; margin-top: 10px;" class="ag-theme-alpine"
      [rowData]="thicknessrowData" [columnDefs]="thicknesscolumnDefs" (gridReady)="onGridReadyThickness($event)" [pagination]="true" [paginationPageSize]="50">
    </ag-grid-angular>
  </div>

  <div *ngIf="daywisereport">
    <ag-grid-angular style="width: 100%; height: 30vw; margin-top: 10px;" class="ag-theme-alpine" [rowData]="dayrowData"
      [columnDefs]="daycolumnDefs" (gridReady)="onGridReadyDay($event)" [pagination]="true" [paginationPageSize]="50">
    </ag-grid-angular>
  </div>

  <div *ngIf="crewwisereport">
    <ag-grid-angular style="width: 100%; height: 30vw; margin-top: 10px;" class="ag-theme-alpine"
      [rowData]="crewrowData" [columnDefs]="crewcolumnDefs" (gridReady)="onGridReadyCrew($event)" [pagination]="true" [paginationPageSize]="50">
    </ag-grid-angular>
  </div>

  <div *ngIf="shiftwisereport">
    <ag-grid-angular style="width: 100%; height: 30vw; margin-top: 10px;" class="ag-theme-alpine"
      [rowData]="shiftwiserowData" [columnDefs]="shiftwisecolumnDefs" (gridReady)="onGridReadyShift($event)" [pagination]="true" [paginationPageSize]="50">
    </ag-grid-angular>
  </div>

  <div *ngIf="shiftDetailreport">
    <ag-grid-angular style="width: 100%; height: 30vw; margin-top: 10px;" class="ag-theme-alpine"
      [rowData]="shiftDetailrowData" [columnDefs]="shiftDetailcolumnDefs" (gridReady)="onGridReadyShiftDeatil($event)" [pagination]="true" [paginationPageSize]="50">
    </ag-grid-angular>
  </div>



</section>