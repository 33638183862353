<section class="home-content" dir="{{languageDir}}">
    <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
    </ul>
    <mat-card>
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

        <form [formGroup]=" actionAnalysisForm">
            <div style="padding:10px;background-color: #0F0FD1;">
                <div class="p-grid p-fluid">
                    <div class="p-col-4">
                        <mat-card-title style="color: white;">{{langObj ? langObj?.ActionPlan : ('ActionPlan' |
                            translate)}}</mat-card-title>
                    </div>
                    <div class="p-col-4">
                        <p-dropdown *ngIf="groupList?.length > 0" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                        'is-invalid':
                        actionAnalysisForm.get('group')?.errors &&
                        actionAnalysisForm.get('group')?.touched
                      }" [options]="groupList" formControlName="group"
                            placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}"
                            optionLabel="TypeName" [showClear]="true"></p-dropdown>
                        <p class="invalid-feedback" *ngIf="
                      actionAnalysisForm.get('group')?.touched &&
                      actionAnalysisForm.get('group')?.hasError('required')
                      ">
                            {{langObj ? langObj?.grp : ('grp' | translate)}}
                        </p>
                    </div>
                    <div class="p-col-4">
                        <p-dropdown [ngClass]="{
                                  'is-invalid':
                                  actionAnalysisForm.get('plant')?.errors &&
                                  actionAnalysisForm.get('plant')?.touched
                                }" [options]="searchPlants" formControlName="plant"
                            placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}"
                            optionLabel="DeptDesc" [showClear]="true" [filter]="true"
                            (onChange)="getMasterData($event)"></p-dropdown>
                        <p class="invalid-feedback" *ngIf="
                               actionAnalysisForm.get('plant')?.touched &&
                               actionAnalysisForm.get('plant')?.hasError('required')
                              ">
                            {{langObj ? langObj?.plant : ('plant' | translate)}}
                        </p>
                    </div>
                </div>
            </div>
            <mat-card-content>
                <div class="p-grid p-fluid">
                    <div class="p-col-10 p-md-5 p-lg-3">
                        <label>{{langObj ? langObj?.From : ('From' | translate)}}</label>
                        <p-calendar [locale]="calendar_en" dateFormat="dd/mm/yy" [(ngModel)]="startYear" appendTo="body"
                            formControlName="fromDate" [showWeek]="true" [monthNavigator]="true" [yearNavigator]="true"
                            yearRange="2000:2030" inputId="navigators"></p-calendar>
                    </div>
                    <div class="p-col-10 p-md-5 p-lg-3">
                        <label>{{langObj ? langObj?.ToDate : ('ToDate' | translate)}}</label>
                        <p-calendar [locale]="calendar_en" dateFormat="dd/mm/yy" [(ngModel)]="endYear" appendTo="body"
                            [firstDayOfWeek]=1 [showWeek]="true" [monthNavigator]="true" [yearNavigator]="true"
                            yearRange="2000:2030" inputId="navigators" formControlName="toDate"></p-calendar>
                    </div>

                    <div class="p-col-10 p-md-5 p-lg-3">
                        <!-- <button type="submit" mat-raised-button color="accent"  (click)="ActionAnalysisData()"
                        [disabled]="!actionAnalysisForm.valid">Search</button> -->
                        <button mat-flat-button color="accent" type="submit" class="btn-accent"
                            (click)="ActionAnalysisData()" [disabled]="!actionAnalysisForm.valid"> {{langObj ?
                            langObj?.Search : ('Search' | translate)}} </button>
                    </div>

                </div>

                <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="dataArray" [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [gridOptions]="gridOptions" (cellValueChanged)="onCellValueChanged($event)" [icons]="icons" [pagination]="true" [paginationPageSize]="10">
                </ag-grid-angular>



            </mat-card-content>
        </form>
    </mat-card>
</section>