import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CostMasterDataComponent } from './Cost-MasterData/Cost-MasterData.component';
import { CostdictionaryComponent } from './costdictionary/costdictionary.component';
import { GapAnalysisComponent } from './GapAnalysis/GapAnalysis.component';
import { ReportingComponent } from './reporting/reporting.component';
import { CostCenterComponent } from './cost-center/cost-center.component';
import { TdcSimulationComponent } from './tdcSimulation/tdcSimulation.component';
import { TdcAnalysisComponent } from './tdcAnalysis/tdcAnalysis.component';
import { TdcelementComponent } from './tdcelement/tdcelement.component';
import { TdcMasterDataComponent } from './tdc-MasterData/tdc-MasterData.component';
import { DashboardLossComponent } from './dashboard-loss/dashboard-loss.component';
const routes: Routes = [
    {path: 'MasterData', component: CostMasterDataComponent },
    {path: 'TdcMasterData', component: TdcMasterDataComponent },
    {path: 'CostDictionary', component: CostdictionaryComponent },
    {path: 'GapAnalysis', component: GapAnalysisComponent },
    {path: 'Reporting', component: ReportingComponent },
    {path: 'CostCenter', component: CostCenterComponent },
    {path: 'TdcSimulation', component: TdcSimulationComponent },
    {path: 'TdcAnalysis', component: TdcAnalysisComponent },
    {path: 'tdcelement', component: TdcelementComponent },
    { path: 'Dashboard', component: DashboardLossComponent },


  ];
@NgModule({
imports: [
  RouterModule.forChild(routes)
],
exports: [
  RouterModule
]
})
export class LossAnalysisRoutingModule {}
