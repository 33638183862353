
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserManu } from 'src/app/models/userManu';
@Component({
  selector: 'app-general-settings',
    templateUrl: './general-settings.component.html',
     styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {
  manufacturinglist!: any[];
  userMenu!: UserManu[];
  constructor(private router: Router) {}

  ngOnInit(): void {
    const list = localStorage.getItem('result');
    this.userMenu = list !== null ? JSON.parse(list) : [];
    const crewSchedule = this.userMenu.find(
      (x) => x.ParentName === 'General Setting' && x.MenuName === 'Crew schedule'
    );
   
   
    this.manufacturinglist = [
      {
        title: 'Crew Schedule',
        routerLink: this.getCrewSchedule,
        icon: 'assets/images/plant-model.svg',
        disabled: false,
      },
      // { title: 'User & Access', 
      // routerLink: this.getUserAccess,
      //  icon: 'assets/images/UserAccess.svg', disabled: false },
      
    
    ];
  }

  getCrewSchedule = (): void => {
    this.router.navigate(['/crewshedule']);
  }
  // getUserAccess = (): void => {
  //   this.router.navigate(['/UserAccessList']);
  // }
  
  
  
  
  
}
