import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class GapanalysisService {
  
  O3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  //NOT IN USE
  // getPlantsData(data: any): Observable<any[]> {
  //   return this.http.post<any[]>(this.baseUrl + 'Common/GetDepartments', data);
  // }
  // getGroupData(data: any): Observable<any[]> {
  //   return this.http.post<any[]>(this.baseUrl + 'Common/GetGroup', data);
  // }

  AddGapAnalysis(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.baseUrl + 'addgapanalysis', data);
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'GapAnalysis/AddGapAnalysis',
      data
    );
  }

  GetAllGapAnalysis(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'GapAnalysis/GetAllGapAnalysis',
      data
    );
  }

  onCellValueChanged(data: any): Observable<any[]> {
  
    return this.http.post<any[]>(this.O3CoreApiUrl + 'GapAnalysis/AddActual', data);
  }

  DeleteGapAnalysis(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.baseUrl + 'deletegapanalysis', data);
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'GapAnalysis/Deletegapanalysis',
      data
    );
  }

  addPotential(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'GapAnalysis/AddPotentialSaving',
      data
    );
  }

  updatePotential(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'GapAnalysis/UpdatePotentialSaving',
      data
    );
  }

  deletePotential(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'GapAnalysis/DeletePotentialSaving',
      data
    );
  }

  GetAllPotentialSaving(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'GapAnalysis/GetAllPotentialSaving',
      data
    );
  }

  GetAllPotentialSavingData(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'GapAnalysis/GetAllPotentialSavingData',
      data
    );
  }
  GetAnalysisData(data: any): Promise<any[]> {
    return this.http.post<any[]>(
      this.O3CoreApiUrl + 'GapAnalysis/GetAnalysisData',
      data
    ).toPromise();
  }
}
