<!-- Start shift opms -->
<br>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
    <p style="color: white">Fetching Data...</p>
</ngx-spinner>
<form [formGroup]="ShiftOPMS" (ngSubmit)="SubmitshiftOPMSinfo()">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-top-row">
          <div class="inputField">
            <nz-date-picker class="calender-input" id="picker" formControlName="date" [nzDisabledDate]="disableFutureDates"
              [nzDisabled]="(rowHourlyData.length > 0 || rowEvent.length > 0 || rowRepacking.length > 0) && ShiftReportId != 0" 
              nzFormat="yyyy-MM-dd hh:mm a" nzShowTime [nzInputReadOnly]="true" readonly></nz-date-picker>
            <span class="floating-text" style="margin-left: 18px;">Date</span>
          </div>
    
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Shift</mat-label>
                <mat-select panelClass="testClass" formControlName="shift" name="Shift" 
                  [disabled]="(rowHourlyData.length > 0 || rowEvent.length > 0 || rowRepacking.length > 0) && ShiftReportId != 0">
                  <mat-option *ngFor="let s of shiftList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Crew</mat-label>
                <mat-select panelClass="testClass" formControlName="crew" name="Crew">
                  <mat-option *ngFor="let s of crewList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Shift Manager Name</mat-label>
                <mat-select panelClass="testClass" formControlName="manager" name="Manager">
                  <mat-option *ngFor="let s of shiftManagerList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button *ngIf="isOPMSUser && ShiftReportId == 0" type="submit"  mat-mini-fab mat-button color="primary" pTooltip="Save" tooltipPosition="top" style="margin-left: 10px;">
                <mat-icon>save</mat-icon>
            </button>
            <button *ngIf="isOPMSUser && ShiftReportId != 0" type="submit"  mat-mini-fab mat-button color="primary" pTooltip="Edit" tooltipPosition="top" style="margin-left: 10px;">
              <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="isOPMSUser" mat-mini-fab mat-button  mat-dialog-close color="accent" (click)="RefreshshiftinfoOPMS()"  pTooltip="Reset" tooltipPosition="top" style="margin-left: 10px;">
              <mat-icon>refresh</mat-icon>
            </button>
            <button mat-mini-fab mat-button mat-dialog-close  color="primary" (click)="LoadshiftOPMSHistory()" pTooltip="History" tooltipPosition="top" style="margin-left: 10px;">
               <mat-icon>list</mat-icon>
            </button>
        </div>
    </div>
</form>
<!-- Start Shift Model -->
<ng-template #shiftModel>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Hourly Shift Report</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <hr>
  <mat-dialog-content>
    <div style="width: 100%; margin-top: 10px;">
      <mat-card-content>
        <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowShiftModel"[columnDefs]="columnDefsShiftModel"
        [defaultColDef]="defaultColDefShiftModel" (cellClicked)='oncellclicked($event)' (gridReady)="onGridReadyShift($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
        </ag-grid-angular>
      </mat-card-content>
    </div>
  </mat-dialog-content>

</ng-template>

<!-- End Shift Model -->
<!-- End shift opms -->
<br><br>
<!-- Start accodian -->
<mat-accordion  style="margin-top: 20px;">
<!-- Start Hourly Data -->
    <mat-expansion-panel *ngIf="isOPMSUser" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc1 disable_ripple_acc1">
        <mat-panel-title>
          Hourly Data
        </mat-panel-title>
      </mat-expansion-panel-header>
        <form [formGroup]="HourlyData" (ngSubmit)="SubmitHourlyData()">
          <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <div class="content-top-row">
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px;">
                <mat-label>Time</mat-label>
                <mat-select panelClass="testClass" formControlName="hdTime" name="hourly_time">
                  <mat-option *ngFor="let h of hours" [value]="h.htime">{{h.hvalue}}</mat-option>
                </mat-select>
              </mat-form-field>

                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label>Gross Width</mat-label>
                  <input type="number" matInput formControlName = "hdGrossWidth" name="hourly_cross_width">
                </mat-form-field>
    
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label>Cut Width</mat-label>
                  <input type="number" matInput formControlName = "hdCutWidth" name="hourly_cut_width">
                </mat-form-field>
    
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label>Lehr Speed</mat-label>
                  <input type="number" matInput formControlName = "hdLehrSpeed" name="hourly_lehr_speed">
                </mat-form-field>

                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label>Pull</mat-label>
                  <input type="number" matInput formControlName = "hdPull" name="hourly_pull" (change)="pullYieldLosses(0)">
                </mat-form-field>
            </div>
            <div class="content-top-row">
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px;">
                <mat-label>Thickness</mat-label>
                <mat-select panelClass="testClass" formControlName="hdThickness" name="hourly_thickness">
                  <mat-option *ngFor="let t of thicknessList" [value]="t.TypeId">{{t.TypeName}}</mat-option>
                </mat-select>
              </mat-form-field>
      
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Actual Thickness</mat-label>
                <input type="number" matInput formControlName = "hdActualThickness" name="hourly_actual_thickness">
              </mat-form-field>
  
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Production Time</mat-label>
                <input type="number" matInput formControlName = "hdProduction" name="hourly_production_time" (change)="pullYieldLosses(1)">
              </mat-form-field>

              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Change Over Time</mat-label>
                <input type="number" matInput formControlName = "hdChangeOverTime" name="hourly_change_over_time">
              </mat-form-field>

              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Hourly Pull</mat-label>
                <input type="number" matInput formControlName = "hdPullYieldLosses" name="hourly_pull_yield_losses" readonly>
              </mat-form-field>
            </div>
            <div class="content-top-row">
              <button *ngIf="HdTransTypeId != 2" type="submit" mat-mini-fab mat-button color="primary" pTooltip="Save" tooltipPosition="top" style="margin-left: 10px;">
                <mat-icon>save</mat-icon>
              </button>
              <button *ngIf="HdTransTypeId == 2" type="submit" mat-mini-fab mat-button color="primary" pTooltip="Edit" tooltipPosition="top" style="margin-left: 10px;">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-mini-fab mat-dialog-close color="accent" (click)="RefreshHourlyData()" pTooltip="Reset" tooltipPosition="top" style="margin-left: 10px;">
                <mat-icon>refresh</mat-icon>
              </button>
              <button type="button" mat-mini-fab mat-button mat-dialog-close color="primary" (click)="LoadHourlyDataHistory()" pTooltip="History" tooltipPosition="top" style="margin-left: 10px;">
                <mat-icon>list</mat-icon>
              </button>
            </div>
          </div>
      </form>
      <div style="width: 100%;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowHourlyData"
          [columnDefs]="columnDefsHourlyData" [defaultColDef]="defaultColDefHourlyData"  [groupIncludeTotalFooter]="true" (gridReady)="onGridReadyHD($event)" [pagination]="true" [paginationPageSize]="10">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>
<!-- Start Hourly Data Model -->
    <ng-template #hourlyDataModel>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>Hourly Shift Report</h2>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <hr>
      <mat-dialog-content>
        <div style="width: 100%; margin-top: 10px;">
          <mat-card-content>
            <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowHourlyDataModel" [columnDefs]="columnDefsHourlyDataModel" 
            [defaultColDef]="defaultColDefHourlyData" (cellClicked)='oncellclickedHD($event)' [groupIncludeTotalFooter]="false" (gridReady)="onGridReadyHD($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
            </ag-grid-angular>
          </mat-card-content>
        </div>
      </mat-dialog-content>
    </ng-template>
    
<!-- End Hourly Data Model -->

<!-- End Hourly Data -->
<!-- Start Production Log -->
    <mat-expansion-panel *ngIf="isOPMSUser" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc2 disable_ripple_acc2">
        <mat-panel-title>
          Production Log
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="ProductionLog" (ngSubmit)="submitProductionLog()">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
          <div class="content-top-row">
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px;">
              <mat-label>Order No</mat-label>
              <input type="number" matInput formControlName = "OrderNo" (keyup)="productionByOrderNo($event.target)" name="production_order_no">
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Time</mat-label>
              <mat-select panelClass="testClass" formControlName="PLTime" name="production_time" (selectionChange)="nominalThick()">
                <mat-option *ngFor="let h of productionHours" [value]="h.htime">{{h.hvalue}}</mat-option>
              </mat-select>
            </mat-form-field>
  
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Quality</mat-label>
                <mat-select panelClass="testClass" formControlName = "Quality"  name="production_quality">
                  <mat-option *ngFor="let q of qualityMenu" [value]="q.Name">{{q.Name}}</mat-option>
                </mat-select>
              </mat-form-field>
  
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Stacker</mat-label>
                <mat-select panelClass="testClass" formControlName = "Stacker"  name="production_stacker">
                  <mat-option *ngFor="let s of stackerMenu" [value]="s.Name">{{s.Name}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-label>Length</mat-label>
                <input type="number" matInput formControlName = "Length" (change)="boxWeight()" name="production_length">
              </mat-form-field>
          </div>
          <div class="content-top-row">
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px;">
              <mat-label>Width</mat-label>
              <input type="number" matInput formControlName = "Width" (change)="boxWeight()" name="production_width">
            </mat-form-field>
    
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Lites</mat-label>
              <input type="number" matInput formControlName = "Lites" (change)="boxWeight()" name="production_lites">
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Boxes</mat-label>
              <input type="number" matInput formControlName = "Boxes" name="production_boxes" (change)="totalPacked()">
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Box Type</mat-label>
              <mat-select panelClass="testClass"  formControlName = "BoxType"  name="production_box_type" >
                <mat-option *ngFor="let b of boxType" [value]="b.Name">{{b.Name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Hold</mat-label>
              <input type="text" matInput formControlName = "Hold" name="production_hold">
            </mat-form-field>

          </div>
          <div class="content-top-row">
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px;">
              <mat-label>Destination</mat-label>
              <mat-select panelClass="testClass" formControlName = "Destination" name="production_destination">
                <mat-option *ngFor="let d of destinationMenu" [value]="d.Name">{{d.Name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>No Thick</mat-label>
              <input type="number" matInput formControlName = "nominalThick"  name="nominalThick" readonly>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Box Weight</mat-label>
              <input type="number" matInput formControlName = "BoxWeight" name="production_box_weight" (change)="totalPacked()" readonly>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Total Packed</mat-label>
              <input type="number" matInput formControlName = "TotalPacked" name="production_box_weight" readonly>
            </mat-form-field>

            <button *ngIf="productionTransId != 2" type="submit" mat-mini-fab mat-button color="primary" pTooltip="Save" tooltipPosition="top" style="margin-left: 10px;">
              <mat-icon>save</mat-icon>
            </button>
            <button *ngIf="productionTransId == 2" type="submit" mat-mini-fab mat-button color="primary" pTooltip="Edit" tooltipPosition="top" style="margin-left: 10px;">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-mini-fab mat-dialog-close color="accent" (click)="refreshProductionLog()"  pTooltip="Reset" tooltipPosition="top" style="margin-left: 10px;">
              <mat-icon>refresh</mat-icon>
            </button>
            <button type="button" mat-mini-fab mat-button mat-dialog-close color="primary" (click)="ProductionLogModel()" pTooltip="History" tooltipPosition="top" style="margin-left: 10px;">
              <mat-icon>list</mat-icon>
            </button>
          </div>
        </div>
    </form>
    <div style="width: 100%;">
      <mat-card-content>
        <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowProductionLog"
          [columnDefs]="columnDefsProductionLog" [defaultColDef]="defaultColDefProductionLog" [groupIncludeTotalFooter]="true" (gridReady)="onGridReadyPL($event)" [pagination]="true" [paginationPageSize]="10">
        </ag-grid-angular>
      </mat-card-content>
    </div>
    </mat-expansion-panel>
<!-- Start Production Log Model -->
    <ng-template #productionLogModel>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>Production Log</h2>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <hr>
      <mat-dialog-content>
        <div style="width: 100%;">
          <mat-card-content>
            <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowProductionLogModel" [columnDefs]="columnDefsProductionLogModel" 
            [defaultColDef]="defaultColDefProductionLog" (cellClicked)='oncellclickedPL($event)'
            (gridReady)="onGridReadyPL($event)" [gridOptions]="gridOptions" [groupIncludeTotalFooter]="false" [pagination]="true" [paginationPageSize]="10">
            </ag-grid-angular>
          </mat-card-content>
        </div>
      </mat-dialog-content>
    </ng-template>

<!-- End Production Log Model -->

<!-- End Production Log -->
<!-- Start Yield Losses -->
    <mat-expansion-panel *ngIf="isOPMSUser" (opened)="panelOpenState = true" (closed)="panelOpenState = false" (opened)="makeYieldForm()">
      <mat-expansion-panel-header class="acc3 disable_ripple_acc3">
        <mat-panel-title>
          Yield Losses
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-top-row" style="width: 100%; margin-bottom: 10px;">
          <div style="width: 100%;">
            <button mat-mini-fab mat-button mat-dialog-close color="accent" (click)="yieldLossesInfoModel()"  pTooltip="Add" tooltipPosition="top" style="float: right;">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div style="width: 100%;">
          <mat-card-content>
            <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowYieldLosses" [columnDefs]="columnDefsYieldLosses" 
            [defaultColDef]="defaultColDefYieldLosses" (cellClicked)='oncellclickedYL($event)' (gridReady)="onGridReadyYL($event)"  [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
            </ag-grid-angular>
          </mat-card-content>
        </div>
      </div>
    </mat-expansion-panel>

  <!-- Start Yield Model -->
    <ng-template #yieldLossesModel>
      <mat-dialog-content style="max-height: 100%; width: 100%;">
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h3>Yield Loss</h3>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
            <mat-icon (click)="refreshYield()">close</mat-icon>
          </button>
        </div>
      </div>
      <mat-divider></mat-divider>
        <div class="d-flex justify-content-between align-items-center" style="margin-top: 10px;">
          <h3>Yield Loss: <span>{{yield_Loss}}</span></h3><h3><span style="width: 110px;"></span></h3>
          <h3>Total Loss: <span></span>{{total_Loss}}</h3><h3><span style="width: 100px;"></span></h3>
        </div>
        <mat-divider></mat-divider>
        <mat-grid-list cols="6" rowHeight="50px" class="header">
          <mat-grid-tile><h3>Yield Loss</h3></mat-grid-tile>
          <mat-grid-tile><h3>Actual</h3></mat-grid-tile>
          <mat-grid-tile><h3>Comments</h3></mat-grid-tile>
          <mat-grid-tile><h3>Yield Loss</h3></mat-grid-tile>
          <mat-grid-tile><h3>Actual</h3></mat-grid-tile>
          <mat-grid-tile><h3>Comments</h3></mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <form *ngIf="yieldParameters.length > 0" [formGroup]="yieldForm">
          <mat-grid-list cols="6" rowHeight="60px">
            <div  *ngFor="let yield of yieldParameters" class="yield_model">
              <mat-grid-tile *ngIf="yield.YieldLoss=='LineLoss%'"><h4>WarehouseLoss%</h4></mat-grid-tile>
              <mat-grid-tile *ngIf="yield.YieldLoss=='LineLossMT'"><h4>WarehouseLossMT</h4></mat-grid-tile>
              <mat-grid-tile *ngIf="yield.YieldLoss!=='LineLoss%' && yield.YieldLoss!=='LineLossMT'"><h4>{{yield.YieldLoss}}</h4></mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field *ngIf="yield.YieldLoss == 'Time';" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label>{{yield.YieldLoss}}</mat-label>
                  <mat-select panelClass="testClass" (selectionChange)="yieldTime($event)" formControlName="{{yield.YieldLoss+'|Value1'}}">
                    <mat-option *ngFor="let h of yieldHours" [value]="h.htime">{{h.hvalue}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="yield.YieldLoss !== 'Time';" appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label class="placeholder-title" *ngIf="yield.YieldLoss=='LineLoss%'">WarehouseLoss%</mat-label>
                  <mat-label class="placeholder-title" *ngIf="yield.YieldLoss=='LineLossMT'">WarehouseLossMT</mat-label>
                  <mat-label class="placeholder-title" *ngIf="yield.YieldLoss!=='LineLoss%' && yield.YieldLoss!=='LineLossMT'">{{yield.YieldLoss}}</mat-label>
                  <input *ngIf="yield.YieldLoss=='Thickness'" readonly matInput  formControlName="{{yield.YieldLoss+'|Value1'}}" >
                  <input *ngIf="yield.YieldLoss=='Pull' || yield.YieldLoss=='TotalPacked' || yield.YieldLoss=='DefectLoss%' || yield.YieldLoss=='DefectLossMT'" type="number" readonly matInput  formControlName="{{yield.YieldLoss+'|Value1'}}" >
                  <input *ngIf="yield.YieldLossId<9 && yield.YieldLossId>4" type="number" (change)="yieldNumber()" matInput (keyup)="CheckValidation(yield.YieldLoss, $event)" formControlName="{{yield.YieldLoss+'|Value1'}}" >
                  <input *ngIf="yield.YieldLossId>8 && yield.YieldLossId<35" type="number" (change)='weightMT(yield.YieldLoss)' matInput (keyup)="CheckValidation(yield.YieldLoss, $event)" formControlName="{{yield.YieldLoss+'|Value1'}}" >
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <textarea matInput placeholder=""  formControlName="{{yield.YieldLoss+'|Value2'}}"></textarea>
                </mat-form-field>
              </mat-grid-tile>
            </div>
          </mat-grid-list>
          <mat-divider></mat-divider>
        <button type="submit" mat-raised-button color="warn" (click)="submitYieldLoss()" style="background-color: #4040f2; margin: 10px;">
          <span *ngIf="yieldTransId != 2" style="font-size: 12px;color: white;">Save & Close</span>
          <span *ngIf="yieldTransId == 2" style="font-size: 12px;color: white;">Edit & Close</span>
        </button>
      </form>
      </mat-dialog-content>

    </ng-template>

  <!-- End Yield Model -->

<!-- End Yield Looses -->
<!-- Start Repacking -->
<mat-expansion-panel *ngIf="isOPMSUser" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header class="acc4 disable_ripple_acc4">
    <mat-panel-title>
      Repacking
    </mat-panel-title>
  </mat-expansion-panel-header>
  <form action="" [formGroup]="RepackingGroup" >
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px;">
          <mat-label>Thickness</mat-label>
          <input type="number" matInput name="Thickness"  formControlName = "Thickness">
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Length</mat-label>
          <input type="number" matInput name="Length"  formControlName = "Length">
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Width</mat-label>
          <input type="number" matInput name="Width"  formControlName = "Width">
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Lites</mat-label>
          <input type="number" matInput name="Lites"  formControlName = "Lites">
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Boxes</mat-label>
          <input type="number" matInput name="Boxes"  formControlName = "Boxes">
        </mat-form-field>
      </div>
      <div class="content-top-row">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px;">
          <mat-label>Box Types</mat-label>
          <mat-select panelClass="testClass"  name="BoxType"  formControlName = "BoxType">
            <mat-option *ngFor="let b of boxType" [value]="b.Name">{{b.Name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Reason</mat-label>
          <input type="text" matInput name="Reason"  formControlName = "Reason">
        </mat-form-field>

        <button *ngIf="TransactionIdRepack != 2" type="button" mat-mini-fab mat-button mat-dialog-close color="primary" pTooltip="Save" tooltipPosition="top" style="margin-left: 10px;" (click)="saveRepacking()">
          <mat-icon>save</mat-icon>
        </button>
        <button *ngIf="TransactionIdRepack == 2" type="button" mat-mini-fab mat-button mat-dialog-close color="primary" pTooltip="Edit" tooltipPosition="top" style="margin-left: 10px;" (click)="saveRepacking()">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-mini-fab color="accent"  pTooltip="Reset" tooltipPosition="top" style="margin-left: 10px;" (click)="resetRepackingGroup()">
          <mat-icon>refresh</mat-icon>
        </button>
        <button type="button" mat-mini-fab mat-button mat-dialog-close color="primary" (click)="RepackingModel()" pTooltip="History" tooltipPosition="top" style="margin-left: 10px;">
          <mat-icon>list</mat-icon>
        </button>
      </div>
    </div>
  </form>
  <div style="width: 100%;">
    <mat-card-content>
      <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowRepacking"
        [columnDefs]="columnDefsRepacking" (gridReady)="onGridReadyRP($event)" [defaultColDef]="defaultColDefRepacking" [pagination]="true" [paginationPageSize]="10">
      </ag-grid-angular>
    </mat-card-content>
  </div>
</mat-expansion-panel>
<!-- Start Repacking Model -->
<ng-template #repackingModel>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Repacking</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <hr>
  <mat-dialog-content>
    <div style="width: 100%; margin-top: 10px;">
      <mat-card-content>
        <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowRepackingModel"  (cellClicked)='oncellclickedRepack($event)'
        [columnDefs]="columnDefsRepackingModel" [defaultColDef]="defaultColDefRepacking" (gridReady)="onGridReadyRP($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
        </ag-grid-angular>
      </mat-card-content>
    </div>
  </mat-dialog-content>
</ng-template>

<!-- End Repacking Model -->

<!-- End Repacking -->
<!-- Start Event -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="acc5 disable_ripple_acc5">
        <mat-panel-title>
          {{eventPannelTitle}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="Event" (ngSubmit)="SubmitEvent()">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
          <div class="content-top-row">
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px;">
              <mat-label>Time</mat-label>
              <mat-select panelClass="testClass" formControlName="etime" name="event_time">
                <mat-option *ngFor="let h of hours" [value]="h.htime">{{h.hvalue}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Area</mat-label>
              <mat-select panelClass="testClass" formControlName="hotEndArea" name="Hot End Area">
                <mat-option *ngFor="let a of hotEndAreaList" [value]="a.TypeId">{{a.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" style="width: 40vw; margin-left: 10px;">
              <mat-label>Event</mat-label>
              <textarea matInput formControlName = "event" name="event_event" cdkTextareaAutosize
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3">
              </textarea>
            </mat-form-field>

            <button *ngIf="EventTrandTypeId != 2" type="submit" mat-mini-fab mat-button color="primary" pTooltip="Save" tooltipPosition="top" style="margin-left: 10px;">
              <mat-icon>save</mat-icon>
            </button>
            <button *ngIf="EventTrandTypeId == 2" type="submit" mat-mini-fab mat-button color="primary" pTooltip="Edit" tooltipPosition="top" style="margin-left: 10px;">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-mini-fab color="accent" (click)="RefreshEvent()" pTooltip="Reset" tooltipPosition="top" style="margin-left: 10px;">
              <mat-icon>refresh</mat-icon>
            </button>
            <button type="button" mat-mini-fab mat-button mat-dialog-close color="primary" (click)="LoadEventHistory()" pTooltip="History" tooltipPosition="top" style="margin-left: 10px;">
              <mat-icon>list</mat-icon>
            </button>
          </div>
        </div>
      </form>
      <div style="width: 100%; margin-top: 20px;">
        <mat-card-content>
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowEvent"
            [columnDefs]="columnDefsEvent" (gridReady)="onGridReadyE($event)" [defaultColDef]="defaultColDefEvent" [pagination]="true" [paginationPageSize]="10">
          </ag-grid-angular>
        </mat-card-content>
      </div>
    </mat-expansion-panel>

<!-- Start Event Model -->
<ng-template #eventModel>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Event</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <hr>
  <mat-dialog-content>
    <div style="width: 100%; margin-top: 10px;">
      <mat-card-content>
        <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowEventModel"
        [columnDefs]="columnDefsEventModel" (gridReady)="onGridReadyE($event)" [defaultColDef]="defaultColDefEvent" (cellClicked)='oncellclickedEvent($event)' [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
        </ag-grid-angular>
      </mat-card-content>
    </div>
  </mat-dialog-content>
</ng-template>

<!-- End Event Model -->


<!-- End Event -->
<!-- Start Production Summary Report -->
<mat-expansion-panel *ngIf="isOPMSUser" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header class="acc4 disable_ripple_acc4">
    <mat-panel-title>
      Production Summary Report
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="wrapper-box wrapper-box-shadow table-wrapper">
    <div class="content-top-row" style="width: 100%; margin-bottom: 10px;">
      <div style="width: 100%;">
        <button mat-mini-fab mat-button mat-dialog-close color="accent" (click)="refreshProductionReport()"  pTooltip="Reset" tooltipPosition="top" style="float: right;">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
    <div style="width: 100%;">
      <mat-card-content>
        <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowProductionReporting"
        [columnDefs]="columnDefsProductionReporting" [defaultColDef]="defaultColDefProductionReporting" [groupIncludeTotalFooter]="true" (gridReady)="onGridReadyPR($event)" [pagination]="true" [paginationPageSize]="10">
        </ag-grid-angular>
      </mat-card-content>
    </div>
  </div>
</mat-expansion-panel>

<!-- End Production Summary Report -->
  </mat-accordion>
<!-- End accordian -->