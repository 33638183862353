<!-- Start accordian -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
<br>
<mat-accordion  style="margin-top: 20px;">
<!-- start Box Receiving -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="acc1 disable_ripple_acc1">
            <mat-panel-title>
                Box Receiving
            </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <div class="content-top-row">
                <button type="button" mat-raised-button color="warn" (click)="openMasterModal()" style="background-color: #4040f2; margin-left: 10px;">
                    <span style="font-size: 12px;color: white;">Add Master Data</span>
                </button>
            </div>
        </div> -->
        <form [formGroup]="BoxRecievingGroup"  action="">
            <div class="wrapper-box wrapper-box-shadow table-wrapper">
              <div class="content-top-row">
                <div class="inputField" style="margin-left: 0px;">
                  <nz-date-picker class="calender-input" id="picker" formControlName="Date"
                  [nzDisabledDate]="" nzFormat="yyyy-MM-dd hh:mm"
                  nzShowTime [nzInputReadOnly]="true" readonly></nz-date-picker>
                  <span class="floating-text" style="margin-left: 18px;">Date</span>
                </div>

                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label>Item Code</mat-label>
                  <input type="text" matInput name="box_receiving_item_code" formControlName="ItemCode" (change)="onBlurMethod()" >
                </mat-form-field>
          
                  <!-- <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-label>Box Type</mat-label>
                    <input type="text" matInput name="BoxType" formControlName="BoxType">
                  </mat-form-field> -->

                  <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-label>Box Type</mat-label>
                    <mat-select panelClass="testClass"  formControlName = "BoxType"  name="box_type" >
                      <mat-option *ngFor="let b of boxType" [value]="b.Name">{{b.Name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
      
                  <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-label>Length</mat-label>
                    <input type="number" matInput name="Length" formControlName="Length">
                  </mat-form-field>
      
                  <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-label>Width</mat-label>
                    <input type="number" matInput name="Width" formControlName="Width">
                  </mat-form-field>
                
              </div>
              <div class="content-top-row">
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 16px;">
                  <mat-label>Depth</mat-label>
                  <input type="number" matInput name="Depth" formControlName="Depth">
                </mat-form-field>

                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label>supplier</mat-label>
                  <input type="text" matInput name="Supplier" formControlName="Supplier">
                </mat-form-field>
        
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label>Receive</mat-label>
                  <input type="number" matInput name="Receive" formControlName="Receive">
                </mat-form-field>
    
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                  <mat-label>Price</mat-label>
                  <input type="number" matInput name="Price" formControlName="Price">
                </mat-form-field>
  
                <button type="button" mat-mini-fab mat-button mat-dialog-close color="primary" pTooltip="Save" tooltipPosition="top" style="margin-left: 10px;" (click)="saveBR()">
                  <mat-icon>save</mat-icon>
                </button>
                <button mat-mini-fab color="accent"  pTooltip="Reset" tooltipPosition="top" style="margin-left: 10px;" (click)="resetForm(1)">
                  <mat-icon>refresh</mat-icon>
                </button>
                <button type="button" mat-mini-fab mat-button mat-dialog-close color="primary" (click)="openRecievingModel()" pTooltip="History" tooltipPosition="top" style="margin-left: 10px;">
                  <mat-icon>list</mat-icon>
                </button>
                <button  mat-mini-fab mat-button color="accent" pTooltip="Add Master Data" tooltipPosition="top" (click)="openMasterModal()" style="margin-left: 10px;">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
        </form>
        <!-- <div class="content-top-row" style="width: 100%; margin-bottom: 10px;">
          <div style="width: 100%;">
            <mat-form-field appearence="fill" class="field-fill" style="float: right;">
              <input matInput placeholder="Search" name="box_receiving_search">
            </mat-form-field>
          </div>
        </div> -->
        <div style="width: 100%; margin-top:10px">
          <mat-card-content>
            <ag-grid-angular style="width: 100%;height:30vw" class="ag-theme-alpine" [rowData]="rowBoxReceiving"
            [columnDefs]="columnDefsBoxReceiving" [defaultColDef]="defaultColDefBoxReceiving" (firstDataRendered)="onFirstDataRendered($event)" [pagination]="true" [paginationPageSize]="50">
            </ag-grid-angular>
          </mat-card-content>
        </div>
    </mat-expansion-panel>
    <!-- Start Box Receiving Action Model -->
    <ng-template #boxReceivingModel>
        <div mat-dialog-title>
          <div class="d-flex justify-content-between align-items-center">
            <h2>Box Receiving</h2>
            <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <hr>
        <mat-dialog-content>
          <!-- <div class="content-top-row" style="width: 100%; margin-bottom: 10px;">
            <div style="width: 100%;">
              <mat-form-field appearence="fill" class="field-fill" style="float: right;">
                <input matInput placeholder="Search" name="box_receiving_model_search">
              </mat-form-field>
            </div>
          </div> -->
          <div style="width: 100%;margin-top: 10px;">
            <mat-card-content>
              <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowBoxReceiving"  [gridOptions]="gridOptions"  (cellClicked)="cellClickedBR($event)"
              [columnDefs]="columnDefsBoxReceivingModel" [defaultColDef]="defaultColDefBoxReceiving" (firstDataRendered)="onGridReadyBRM($event)" [frameworkComponents]="frameworkComponents" [pagination]="true" [paginationPageSize]="50">
              </ag-grid-angular>
            </mat-card-content>
          </div>
        </mat-dialog-content>
    </ng-template>

      <!-- End Box Receiving Action Model -->
    <!-- Start Box Receiving MasterData Model -->
    <ng-template #boxReceivingMasterDataModel>
        <div mat-dialog-title>
          <div class="d-flex justify-content-between align-items-center">
            <h2>Master Data</h2>
            <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top" (click)="resetForm(2)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <hr>
        <mat-dialog-content>
            <form action="" [formGroup]="MasterGroup" >
                <div class="wrapper-box wrapper-box-shadow table-wrapper">
                  <div class="content-top-row">
                    <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="margin-left: 0px;">
                      <mat-label>Item Code</mat-label>
                      <input type="text" matInput name="ItemCode" formControlName="ItemCode">
                    </mat-form-field>
          
                      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label>Length</mat-label>
                        <input type="number" matInput name="Length" formControlName="Length">
                      </mat-form-field>
          
                      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label>Width</mat-label>
                        <input type="number" matInput name="Width" formControlName="Width">
                      </mat-form-field>
      
                      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label>Depth</mat-label>
                        <input type="number" matInput name="Depth" formControlName="Depth">
                      </mat-form-field>

                      <!-- <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label>Box Type</mat-label>
                        <input type="text" matInput name="BoxType" formControlName="BoxType">
                      </mat-form-field> -->

                      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-label>Box Type</mat-label>
                        <mat-select panelClass="testClass"  formControlName = "BoxType"  name="production_box_type" >
                          <mat-option *ngFor="let b of boxType" [value]="b.Name">{{b.Name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                  </div>
                  <div class="content-top-row">
      
                    <button type="submit" mat-mini-fab mat-button  color="primary" pTooltip="Save" tooltipPosition="top" style="margin-left: 10px;" (click)="saveMaster()">
                      <mat-icon>save</mat-icon>
                    </button>
                    <button mat-mini-fab color="accent"  pTooltip="Reset" tooltipPosition="top" style="margin-left: 10px;" (click)="resetForm(2)">
                      <mat-icon>refresh</mat-icon>
                    </button>
                  </div>
                </div>
            </form>
            <!-- <div class="content-top-row" style="width: 100%; margin-bottom: 10px;">
              <div style="width: 100%;">
                <mat-form-field appearence="fill" class="field-fill" style="float: right;">
                  <input matInput placeholder="Search" name="box_receiving_master_model_search">
                </mat-form-field>
              </div>
            </div> -->
          <div style="width: 100%;margin-top: 10px;">
            <mat-card-content>
              <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="rowBoxReceivingMasterDataModel"   (cellClicked)="cellClickedMaster($event)"  [gridOptions]="gridOptions"
              [columnDefs]="columnDefsBoxReceivingMasterDataModel" [defaultColDef]="defaultColDefBoxReceiving" (firstDataRendered)="onGridReadyMasterData($event)" [pagination]="true" [paginationPageSize]="50">
              </ag-grid-angular>
            </mat-card-content>
          </div>
        </mat-dialog-content>
    </ng-template>

    <!-- End Box Receiving MasterData Model -->
</mat-accordion>

<!-- End accordian -->