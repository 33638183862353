import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  Injectable,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeNestedDataSource,
} from '@angular/material/tree';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { PlantGroupElement } from 'src/app/interface/plant-model';
import { ProductivityService } from 'src/app/services/productivity-service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { PrimeNGConfig } from 'primeng/api';
import * as moment from 'moment';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { MatAccordion } from '@angular/material/expansion';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { Paginator } from 'primeng/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { StockChart } from 'angular-highcharts';
import { MatTabGroup } from '@angular/material/tabs';
import { ChecklistService } from 'src/app/services/checklist.service';
import { flatten } from 'lodash-es';
import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ButtonEditRendererComponent } from 'src/app/components/button-edit-render.components';
import { ButtonPrintRendererComponent } from 'src/app/components/button-print-render.components';
import { ButtonSelectRendererComponent } from 'src/app/components/button-select-render.components';
import { ButtonViewRendererComponent } from 'src/app/components/button-view-render.components';
import { QualityParameterService } from 'src/app/services/quality-parameter.service';
import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { CustomTooltip } from 'src/app/custom-tooltip.component';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { ProcessControlService } from 'src/app/services/processControlService.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { QualityexecutionService } from 'src/app/services/qualityexecution.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { AreaService } from 'src/app/services/area.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import * as Chart from 'chart.js';
import { CrewScheduleService } from 'src/app/services/crewSchedule.service';
import { MachineParamtersService } from 'src/app/services/machine-paramters.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { TranslaterService } from 'src/app/services/translater.service';
import { MeetingMatrixService } from 'src/app/services/meeting-matrix.service';
import { indexOf } from 'lodash';
import { LangChangeEvent } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { BehaviorSubject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { CustomLoadingCellRenderer } from 'src/app/aggrid custom/custom-loading-cell-renderer.component';
import { differenceInCalendarDays } from 'date-fns';
import { interval, Subscription } from 'rxjs';
import { CustomLoadingOverlay } from 'src/app/custom-loading-overlay.component';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { CilConfigurationService } from 'src/app/services/cil-configuration.service';
import { PlanGroupComponent } from 'src/app/models/O3AdministrationModels/plant-model';
import { ThemePalette } from '@angular/material/core';
import { CILManagementElement } from 'src/app/models/CILManagementElement';
import { environment } from 'src/environments/environment';
import * as Highcharts from 'highcharts';
import { CommonService } from 'src/app/services/common.service';

require('highcharts/modules/networkgraph')(Highcharts);
//import { Group } from 'src/app/models/group';
//import { Group, Group, Group } from 'src/app/quality-management/quality-check-history/quality-check-history.component';
const left = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
    optional: true,
  }),
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(-100%)' }),
        animate('3s ease-out', style({ transform: 'translateX(0%)' })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)' }),
        animate('3s ease-out', style({ transform: 'translateX(100%)' })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
    optional: true,
  }),
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(100%)' }),
        animate('3s ease-out', style({ transform: 'translateX(0%)' })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)' }),
        animate('3s ease-out', style({ transform: 'translateX(-100%)' })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];
interface ShiftCloseInterface {
  sno: number;
  item: string;
}
interface batchCheckListInterface {
  BatchCheckListTypeId: number;
  Name: string;
  DataType: any;
  DataValue: any;
}
interface LineInterface {
  KpiparaId: number;
  KpiName: string;
  KpiValues: boolean;
}

// dummy interfaces
/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface FoodNode {
  name: string;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Fruit',
    children: [
      { name: 'Apple' },
      { name: 'Banana' },
      {
        name: 'Fruit loops',
        children: [
          {
            name: 'Lucky Charms',
            children: [{ name: 'Yellow Moons' }, { name: 'Green Clovers' }],
          },
          { name: 'Cheerios' },
        ],
      },
    ],
  },
  {
    name: 'Vegetables',
    children: [
      {
        name: 'Green',
        children: [{ name: 'Broccoli' }, { name: 'Brussels sprouts' }],
      },
      {
        name: 'Orange',
        children: [{ name: 'Pumpkins' }, { name: 'Carrots' }],
      },
    ],
  },
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

/**
 * @title Tree with flat nodes
 */

// dummy code end
@Component({
  selector: 'app-new-sfdc',
  templateUrl: './new-sfdc.component.html',
  styleUrls: ['./new-sfdc.component.scss'],
  animations: [
    trigger('animSlider', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class NewSfdcComponent implements OnInit {
  spinnerButtonOptions1: MatProgressButtonOptions = {
    active: false,
    text: 'Comparison',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptionsOrders: MatProgressButtonOptions = {
    active: false,
    text: 'PO List',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptionsAnalysis: MatProgressButtonOptions = {
    active: false,
    text: 'Orders',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Analysis',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  displayPosition!: boolean;
  position!: string;
  materialConsumptionForm!: FormGroup;
  style = {
    width: '100%',
    height: '100%',
  };
  // dummy logic
  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };
  operatorName: any;
  treeControlnew = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattenernew = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(
    this.treeControlnew,
    this.treeFlattenernew
  );

  // end dummy logic

  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('displayWorkOrderModal')
  private displayWorkOrderModal!: TemplateRef<any>;
  @ViewChild('historyBatchModal') private historyBatchModal!: TemplateRef<any>;
  @ViewChild('faultmodal') private faultmodal!: TemplateRef<any>;
  @ViewChild('timermodal') private timermodal!: TemplateRef<any>;
  @ViewChild('popupViewModal') private popupViewModal!: TemplateRef<any>;
  @ViewChild('historyMaterialModal')
  private historyMaterialModal!: TemplateRef<any>;
  @ViewChild('displayPoModal') private displayPoModal!: TemplateRef<any>;
  @ViewChild('displayQCModal') private displayQCModal!: TemplateRef<any>;

  isPlantGroupModelVisible = false;
  updateOrderprocessingData = false;
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  UnitNode: any;
  sfdcForm!: FormGroup;
  urll: any;
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;
  MachineId: any;
  qty: any;
  result: any;
  columnBatchHistory: any;
  columnMaterialBatchHistory: any;
  columnMaterialConsumptionHistory: any;
  itemCode!: string;
  ItemTypeList: any;
  materialConsumptionMode = true;
  chart: any;
  PPID: any;
  plantId: any;
  JobBatchId: any;
  machineSelectProperty!: boolean;
  materialConsumptionDataSource = new MatTableDataSource<any>([]);
  productionOutputDataSource!: MatTableDataSource<any>;
  groups!: any[];
  successMessage!: any;
  showMaterialButtons = false;
  stock!: StockChart;
  Machinetype = false;
  batchChecklistOnlyForShowColumns: string[] = ['checklist', 'values'];
  batchChecklist1Columns: string[] = ['checklist', 'values'];
  //toaster: any;
  materialColumns: any[] = [
    'itemtype',
    'batch',
    'length',
    'remarks',
    'time',
    'createdon',
    'createdby',
  ];
  productionColumns: any[] = ['checklist', 'values'];
  production1Columns: string[] = [
    'batchCon',
    'lotcode',
    'act',
    'remarks',
    'time',
    'createdon',
    'createdby',
    'action',
  ];
  wasteColumns: any[] = [
    'cattype',
    'time',
    'failure',
    'uom',
    'createdon',
    'createdby',
  ];
  highChartsColor = [
    '#00008B',
    '#34A56F',
    '#F80000',
    '#2D0A77',
    '#8C001A',
    '#FF69B4',
    '#FA4A08',
    '#5453A6',
    '#8B008B',
    '#3090C7',
    '#F1C40F',
    '#CB4335',
    '#36454F',
    '#A569BD',
    '#FFA500',
    '#179fcc',
    '#093323',
    '#08fa03',
    '#048b8e',
    '#9637A4',
    '#F39C12',
    '#0b774e',
    '#F5B7B1',
    '#0d98da',
    '#512E5F',
    '#C471A5',
    '#0034bb',
    '#06573d',
    '#0d6e54',
    '#0692aa',
    '#611745',
    '#F80000',
    '#FFDF00',
    '#6D81A8',
    '#08FAC7',
    '#D208FA',
    '#FAEB08',
  ];

  ProcessControlDataForm!: FormGroup;
  processparameter1Columns: string[] = ['processparameter', 'values'];
  detailPrentId!: any;
  processparameter1DataSource!: MatTableDataSource<any>;
  processparameter2Columns: string[] = ['materialparameter', 'values'];
  processparameter22DataSource!: MatTableDataSource<any>;
  processparameter3Columns: string[] = ['lineclearance', 'values'];
  processparameter33DataSource!: MatTableDataSource<any>;
  isFoodSector: boolean = false;
  total!: any;
  total1!: any;
  // chart!: StockChart;
  processValues!: any[];
  processValues1!: any[];
  productionOutputForm!: FormGroup;
  productionMode = true;
  batchCheckList: any;
  isAuto!: boolean;
  batchConsumptionList!: any[];
  filteredbatchConsumptionList!: any[];
  Result!: any;
  targetQty!: any;
  remainingQty = 0;
  plantDDL!: any[];
  areaDDL!: any[];
  ViewProductionOutputDataSource!: MatTableDataSource<any>;
  HistoryBatchDataSource1!: MatTableDataSource<any>;
  BatchCheckListTypeId!: any;
  dropdownArray: any[] = [];
  Groups!: any[];
  currentImagePath!: any;
  newDropdownList: any = [];
  centerLineDataSource!: MatTableDataSource<any>;

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('ProfileImageZoomModal')
  private ProfileImageZoomModal!: TemplateRef<any>;
  @ViewChild('imageZoomModelShow')
  private imageZoomModelShow!: TemplateRef<any>;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('centerlineModal') private centerlineModal!: TemplateRef<any>;
  @ViewChild('wastehistoryModal') private wastehistoryModal!: TemplateRef<any>;
  @ViewChild('matTabId', { static: false }) matTabId!: MatTabGroup;
  @ViewChild('lastshiftModal') private lastshiftModal!: TemplateRef<any>;
  groupTypeList: any;
  @ViewChild('historyBatch1Modal')
  private historyBatch1Modal!: TemplateRef<any>;
  @ViewChild('materialViewModal') private materialViewModal!: TemplateRef<any>;
  ManPowerRequired!: any;
  ManPower!: any;
  // @ViewChild('displayQualitySpecificationModal')
  // private displayQualitySpecificationModal!: TemplateRef<any>;
  displayQualitySpecificationModal!: boolean;
  // @ViewChild('displayQualityDefectModal')
  // private displayQualityDefectModal!: TemplateRef<any>;
  displayQualityDefectModal!: boolean;
  // @ViewChild('processControlSpecificationModal')
  // private processControlSpecificationModal!: TemplateRef<any>;
  // @ViewChild('displayProcssDefectModal')
  // private displayProcssDefectModal!: TemplateRef<any>;
  displayProcssDefectModal!: boolean;
  centerLineMode = true;
  obKpiParamInfoId1!: any;
  booleanConvertedArray: LineInterface[] = [];
  booleanConvertedArray1: LineInterface[] = [];
  booleanConvertedArray2: LineInterface[] = [];
  MaterialParameterList!: any[];
  LineParameterList!: any[];
  wasteForm!: FormGroup;
  wasteMode = true;
  WasteEntryId!: any;
  newJbWasteHistoryList!: any[];
  rowDataWasteHistory!: any[];
  wasteDataSource1!: MatTableDataSource<any>;
  totalWaste!: any;
  FaultList!: any[];
  GTList!: any[];
  filteredGTList!: any[];
  shortStopsForm!: FormGroup;
  resultWaste: any;
  wasteButtonShow = false;
  name!: any;
  downTimeForm!: FormGroup;
  downTimeMode = true;
  JobDownTimeId!: any;
  DTFaultList!: any[];
  qualityBoolVal!: string;
  filteredDTFaultList!: any[];
  JbDowntimeHistoryList!: any[];
  materialConsumptionDataSource1!: MatTableDataSource<any>;
  downtimeDataSource!: MatTableDataSource<any>;
  downtimeHistoryDataSource!: MatTableDataSource<any>;
  disableSFDCbuttons = true;
  downtimecolumns2: any[] = [
    'starttime',
    'endtime',
    'duration',
    'fault',
    'remarks',
    'createdon',
    'createdby',
  ];
  materialFoodSectorColumns: any[] = [
    'isrework',
    'batch',
    'length',
    'totalActQty',
    'remarks',
    'time',
    'createdon',
    'createdby',
    'sfdcactions',
  ];
  downtimeColumns: any[] = [
    'starttime',
    'endtime',
    'duration',
    'fault',
    'remarks',
    'createdon',
    'createdby',
    'downtimeactions',
  ];
  executeRole!: any;
  customerManagementColumns: string[] = [
    'listName',
    'Area',
    'Machine',
    'createdOn',
    'TotalExec',
    'LastexecDate',
    'action',
  ];
  selectionName: any;
  groupDataForm!: FormGroup;
  onlymachineSelectProperty = false;
  rowDataDowntimeHistory!: any[];
  ONE_HOUR = 60 * 60 * 1000;
  TWO_HOURS = 2 * (60 * 60 * 1000);
  div1: boolean = true;
  div2: boolean = false;
  div3: boolean = false;
  div4: boolean = false;
  div5: boolean = false;
  div6: boolean = false;
  panelOpenState = false;
  PDtooltip: any;
  columnWasteHistry!: any[];
  frameworkComponents: any;
  rowSelection: any;
  columnDefs!: any[];
  columnDefsDowntimeHistory!: any[];
  materialFoodSectorConsumptionForm!: FormGroup;
  JobBatchMaterialId!: any;
  IsWrite: any;
  gridApi: any;
  gridColumnApi: any;
  columnDefsProcessControl!: any[];
  rowDataProcessControl!: any[];
  rowDataQualityControl!: any[];
  columnDefsQualityControl!: any[];
  poprocessOrder: any;
  itemCodePo: any;
  itemDescPo: any;
  statusPo: any;
  chartArray: any[] = [];
  qualityProcessOrder = new MatTableDataSource<any>([]);
  startEndDuration: any;
  starttimediff: any;
  endtimediff: any;
  timedifference: any;
  points: any;
  isCharNumeric(charStr: any): boolean {
    return !!/\d/.test(charStr);
  }
  customerManagementDataSource = new MatTableDataSource<any>([]);
  checklistDataForm!: FormGroup;
  goodImgArray: File[] = [];
  formMode = true;
  checkListId!: any;
  checklistId!: number;
  shiftName!: any;
  itemColumns: string[] = ['items', 'good', 'bad', 'score', 'remarks'];
  addtabledata: Array<{
    id: number;
    itemtype: number;
    drop: string;
    item: string;
    checkListDetailId: number;
    score: number;
    remarks: string;
    catType: string;
    goodImage: string;
    badImage: string;
  }> = [];
  BadExampleImage = false;
  listTypeDDL!: any[];
  valueCheck!: any;
  valueCheck2!: any;
  itemTypeDDL!: any[];
  Plants!: any[];
  unitId: any;
  preAuto = false;
  preAuto2 = false;
  meetingParticipants: any[] = [];
  preCheck!: any;
  checkListAddModel = new MatTableDataSource<any | Group>([]);
  preCheck2!: any;
  _alldata!: any[];
  GoodExampleImage = false;
  isAuto2 = false;
  groupByColumns: string[] = [];
  counter = 1;
  hidetrue = false;
  fileSave!: any;
  categoryTypeDDL: any[] = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
  ];
  yes = true;
  no = true;
  rowDataBatchHistory!: any[];
  materialSubColumns: any[] = [
    'itemtype',
    'itemDesc',
    'itemuom',
    'estQty',
    'actQty',
    'supplier',
    'prodDate',
    'expireDate',
  ];
  paramterSpcecificationName: any;
  parametrdefect!: MatTableDataSource<any>;
  processcontrolcolumns: string[] = [
    'LRL',
    'LSL',
    'LWL',
    'TGT',
    'UWL',
    'USL',
    'URL',
  ];
  qcVerifyColumns: string[] = [
    'ParameterId',
    'Parameter',
    'Sample',
    'Value',
    'verify',
    'remarks',
  ];
  parameterspecifications = new MatTableDataSource<any>([]);
  defectColumns: string[] = [
    'DefectMode',
    'Department',
    'Line',
    'Unit',
    'Assembly',
    'Subassembly',
    'Component',
  ];
  gridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 225,
      filter: true,
      resizable: true,
      wrapText: true,
      autoHeight: true,
    },
  };
  selectedMeeting: any = {
    meetingId: 0,
    meetingName: '',
  };
  meetingOriginalArray: any[] = [];
  rowDataMaterialBatchHistory!: any[];
  processIntervalTypeDDL!: any[];
  ItemBOMViewList!: any[];
  rowDataMaterialConsumptionHistory!: any[];
  materialHistoryItemBOMList!: MatTableDataSource<any>;
  isFoodMaterialHisory: boolean = false;
  masterList!: any[];
  showButtons = false;
  showShiftClose = false;
  ProductionOutputDataSource!: MatTableDataSource<any>;
  @ViewChild('displayshiftcloseRoleModal')
  private displayshiftcloseRoleModal!: TemplateRef<any>;
  availabilityDataSource!: MatTableDataSource<any>;
  PerformanceDataSource!: MatTableDataSource<any>;
  crewId!: any;
  qualityDataSource!: MatTableDataSource<any>;
  shiftDataSource!: MatTableDataSource<any>;
  shiftactionDataSource!: MatTableDataSource<any>;
  poListDataSource!: MatTableDataSource<any>;
  rowData!: any[];
  canvas!: any;
  ctx!: any;
  lastShiftDataSource!: MatTableDataSource<any>;
  showMode = false;
  Supervisor!: any;
  OEEInfoList!: any[];
  ShiftStart!: any;
  ShiftEnd!: any;
  UserName!: any;
  DowntimeInfoList!: any[];
  Shift!: any;
  @ViewChild('visibleParameterComparison')
  private visibleParameterComparison!: TemplateRef<any>;
  showLastShiftProperty = false;
  UnitName: any;
  IsReworkRequired: boolean = false;
  processGroupType: any;
  intervalList: any;
  crewScheduleList: any;
  shiftList: any;
  qualityControlGroupTypeList: any;
  qualityGroupType: any;
  qualityIntervalList: any;
  processControlForm!: FormGroup;
  qualityControlForm!: FormGroup;
  boolList: any;
  maxCavity: any;
  qualityboolList: any;
  poListForm!: FormGroup;
  lastshiftColumns: string[] = ['sno', 'validations'];
  availabilityColumns: string[] = ['ttime', 'dtime', 'atime', 'rtime', 'mtime'];
  PerformanceColumns: string[] = ['order', 'qty', 'speed', 'total', 'diff'];
  qualityColumns: string[] = [
    'order',
    'production',
    'waste',
    'pieces',
    'calculation',
  ];
  ordersColumns: string[] = [
    'ProcessOrder',
    'ProdCode',
    'ProdDesc',
    'StartTime',
    'EndTime',
  ];

  timer = '00:00:00';
  interval: any;
  meetingStartTime: any;
  meetingEndTime: any;
  meetingStarted = false;
  meetingStoped = false;
  minutes = 0;
  hours = 0;
  seconds = 0;
  QualityIntervalTypeDDL!: any[];
  shiftColumns: string[] = ['sno', 'validation', 'values'];
  shiftCloseArray: ShiftCloseInterface[] = [];
  shiftactionColumns: any[] = ['sno', 'item'];
  JobBatchItemTypeId!: any;
  PPS_ID!: any;
  ItemBOMList!: any[];
  statusList!: any[];
  IDtooltip: any;
  batchItemBOMList!: MatTableDataSource<any>;
  isRework: boolean = false;
  HistoryBatchDataSource = new MatTableDataSource<any>();
  translateForm!: FormGroup;
  MeetingMatrixForm!: FormGroup;
  displayUserModal = false;
  faultclose: boolean = true;
  languageDir = 'ltr';
  shift1 = ' ';
  currentprocessOrder: any;
  qcdataSource = new MatTableDataSource<any>([]);
  qcRole = false;
  qcParamsSlot: any;
  qcStartTimeSlot: any;
  qcEndTimeSlot: any;
  qcheadertooltip: any;
  qcProcessorderNo: any;
  qcProdCode: any;
  qcProcdDesc: any;
  detailArray: any;
  verifyCheckedArray: any;
  verifcationStatus: any;
  remarks: any;
  chartSpecifications: any;
  langObj: any = {};
  isCardExpanded = false;
  isCardExpandedQuality = false;
  CollapsehideQuality = false;
  ExdpandhideQuality = true;
  Exdpandhide = true;
  Collapsehide = false;
  @ViewChild('displayQualityOrdersModal')
  private displayQualityOrdersModal!: TemplateRef<any>;
  @ViewChild('visibleYearlyTarget')
  private visibleYearlyTargetTbl!: TemplateRef<any>;
  loadingOverlayComponent: any;
  loadingOverlayComponentParams: any;
  qualityGridApi: any;
  qualityGridColumnApi: any;
  processGridApi: any;
  processGridColumnApi: any;
  isProcessGrid = false;
  boolColId: any;
  boolRowIndex: any;
  SFDC_OEE: any;
  SFDC_Availability: any;
  SFDC_Performance: any;
  SFDC_Quality: any;
  SFDC_TotalProducedQTy = 0;
  SFDC_TotalGoodPieces = 0;
  SFDC_Totalwaste: any;
  SFDC_TotalDowntime = 0;
  SFDC_TotalAverageSpeed: any;
  SFDC_RemaningTime = 0;
  calculatedremainingtime: any;
  poStartDate: any;
  curentTimeDiff: any;
  today = new Date();
  subscription!: Subscription;
  cavityList!: any[];
  showCavity = false;
  processControlSpecificationModal!: boolean;
  @ViewChild('qualityBoolListModal')
  private qualityBoolListModal!: TemplateRef<any>;
  dateArrCell = [];
  @ViewChild('qualityDateListModal')
  private qualityDateListModal!: TemplateRef<any>;
  OeeDashboardData!: any;

  //Variables from CIL Management
  hideBtn = true;
  hidesidePanel = false;
  displayMAchineModal = false;
  displayComponentModal = false;
  displayActionModal = false;
  IsAccess: any;
  CilManagDataForm!: FormGroup;
  CilActionDataForm!: FormGroup;
  performCILItemForm!: FormGroup;
  flattenArr: any;
  hideImage = false;
  disableRow = false;
  plant: any;
  ImageUrl!: any;
  units: any;
  filteredUnits: any;
  components: any;
  filteredcomponents: any;
  isButtonEnable = true;

  @ViewChild(MatTable) table!: MatTable<any>;
  compTreeSource = new MatTreeNestedDataSource<any>();
  machineTreeSource = new MatTreeNestedDataSource<any>();
  plantGroupComponentSource = new MatTreeNestedDataSource<PlanGroupComponent>();
  treeControlComponent = new NestedTreeControl<PlanGroupComponent>(
    (node) => node.children
  );

  // Add new variable Group Form
  isEditCILModelVisible = false;

  // Drop-down Variables
  selected: string | undefined;
  selectedMachine: string | undefined;
  selectedComponent: string | undefined;
  selectedGroup!: string;

  // upload file name
  uploadFileInfo!: File;
  file: File | undefined = undefined;

  // Dropdown Values
  plants!: any[];
  Lines!: any[];
  Machines!: any[];
  machineList!: any[];
  componentList!: any[];
  componentId!: any;
  machineId!: any;
  selectedTaskId!: any;
  selectedTaskComp!: any;
  show = false;
  // Date-Time Picker Variable
  startTime!: any;
  // public date!: moment.Moment;
  public color: ThemePalette = 'primary';
  public showSpinners = true;
  // Icon Url
  grpImgurl = 'assets/images/grp-icon.png';
  pltImgurl = 'assets/images/plt-icon.png';
  divImgurl = 'assets/images/div-icon.png';
  dptImgurl = 'assets/images/dpt-icon.png';
  linImgurl = 'assets/images/lin-icon.png';
  untImgurl = 'assets/images/unt-icon.png';
  asmImgurl = 'assets/images/asm-icon.png';
  samImgurl = 'assets/images/sam-icon.png';
  copImgurl = 'assets/images/cop-icon.png';
  collapseAllImgurl = 'assets/images/collapse-all-icon.png';
  expandAllImgurl = 'assets/images/expand-all-icon.png';
  downloadIconurl = 'assets/images/download-image-icon.png';
  uploadIconurl = 'assets/images/upload-icon.png';
  editIconurl = 'assets/images/edit-icon.png';
  deleteIconurl = 'assets/images/delete-icon.png';
  messageIconurl = 'assets/images/message-icon.png';
  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
  });

  CILManagementElementData: CILManagementElement[] = [];
  cilmanagementColumns: string[] = [
    'isChecked',
    'task',
    'type',
    'component',
    'frequency',
    'nexttime',
    'image',
    'remarks',
    'postImage',
    'postRemarks',
    'sop',
    'action',
  ];
  cilManagementVariableGroupData!: MatTableDataSource<CILManagementElement>;
  selection = new SelectionModel<CILManagementElement>(true, []);

  expandedElement: any;
  // CIL Management End Here

  startbutton = true;
  pausebutton = true;
  closebutton = true;
  constructor(
    // private translate: TranslaterService,
    private productivityservice: ProductivityService,
    private commonService: CommonService,
    private meetingService: MeetingMatrixService,
    private crewService: CrewScheduleService,
    private router: Router,
    private enterpriseGroupService: EnterPriseGroupService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private qualityParameterService: QualityParameterService,
    private processService: ProcessControlService,
    private userManagementService: UserManagementService,
    private usermanagementService: UserManagementService,
    private ChecklistService: ChecklistService,
    private qualityexecutionService: QualityexecutionService,
    private notificationService: NotificationService,
    private machineService: MachineMappingService,
    private AreaServices: AreaService,
    private dialog: MatDialog,
    private sfdService: ShopFloorDataService,
    private machineParamtersService: MachineParamtersService,
    public translater: TranslateService,
    public translater1: TranslaterService,
    private cilService: CilConfigurationService,

    // public  translater: TranslaterService,
    // public translate: TranslateService,
    public datepipe: DatePipe
  ) {
    // this.time();
    this.dataSource.data = TREE_DATA;
    this.translater.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === 'ar') {
        this.languageDir = 'ltr';
        this.getLangWords();
      } else {
        this.languageDir = 'ltr';
        this.getLangWords();

      }
    }

    );
    this.frameworkComponents = {
      buttonSelectRenderer: ButtonSelectRendererComponent,
      buttonEditRenderer: ButtonEditRendererComponent,
      buttonViewRenderer: ButtonViewRendererComponent,
      buttonPrintRenderer: ButtonPrintRendererComponent,
      customLoadingOverlay: CustomLoadingOverlay,
    };
    this.rowSelection = 'single';
    this.columnDefs = [
      {
        headerName: 'Actions',
        cellRenderer: 'buttonSelectRenderer',
        cellRendererParams: {
          onClick: this.selectHistory.bind(this),
          label: 'select',
        },
        maxWidth: 100,
      },
      { headerName: 'Id', field: 'PPDId', maxWidth: 85 },
      { headerName: 'PO No', field: 'PO', maxWidth: 150 },
      { headerName: 'Operation No', field: 'Oper' },
      { headerName: 'Status', field: 'OperStatus' },
      { headerName: 'Product Details', field: 'ProductDetail' },
      { headerName: 'Item Code', field: 'ItemCode' },
      { headerName: 'Item Description', field: 'ItemDesc' },
      { headerName: 'Priority', field: 'Priority' },
      { headerName: 'Remarks', field: 'Remarks' },
      {
        headerName: 'Created On',
        field: 'CreatedOn',
        valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
      },
      { headerName: 'Created By', field: 'CreatedBy' },
    ];

    this.columnDefsDowntimeHistory = [
      {
        headerName: 'Start time',
        field: 'StartTime',
        valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
      },
      {
        headerName: 'End time',
        field: 'EndTime',
        valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
      },
      { headerName: 'Duration', field: 'Duration' },
      { headerName: 'Fault', field: 'FaultName' },
      { headerName: 'Remarks', field: 'Remarks' },
      {
        headerName: 'Created On',
        field: 'CreatedOn',
        valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
      },
      { headerName: 'Created By', field: 'CreatedBy' },
      {
        headerName: 'Actions',
        cellRenderer: 'buttonEditRenderer',
        cellRendererParams: {
          onClick: this.onClickDowntimeHistory.bind(this),
          label: 'Edit',
        },
        maxWidth: 100,
      },
    ];

    this.columnWasteHistry = [
      { headerName: 'Category type', field: 'FaultName' },
      { headerName: 'Time', field: 'Time' },
      { headerName: 'qty', field: 'Amount' },
      { headerName: 'mode', field: 'Remarks' },
      {
        headerName: 'Created On',
        field: 'CreatedOn',
        valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
      },
      { headerName: 'Created By', field: 'CreatedBy' },
      {
        headerName: 'Actions',
        cellRenderer: 'buttonEditRenderer',
        cellRendererParams: {
          onClick: this.onClickWasteHistory.bind(this),
          label: 'Edit',
        },
        maxWidth: 100,
      },
    ];

    this.columnBatchHistory = [
      { headerName: 'Batch Consumption', field: 'IPBatchNo' },
      { headerName: 'Lotcode/Pallet', field: 'BatchNo' },
      { headerName: 'act', field: 'Result' },
      { headerName: 'remarks', field: 'Remarks' },
      { headerName: 'Time', field: 'RollStarttime' },
      { headerName: 'Created On', field: 'CreatedOn' },
      { headerName: 'Created By', field: 'CreatedBy' },
      {
        headerName: '',
        cellRenderer: 'buttonEditRenderer',
        cellRendererParams: {
          onClick: this.onClickProductHistoryBatch.bind(this),
          label: 'Edit',
        },
        maxWidth: 70,
      },
      {
        headerName: 'Actions',
        cellRenderer: 'buttonViewRenderer',
        cellRendererParams: {
          onClick: this.onClickPopupViewForChecklistHistory.bind(this),
          label: 'View',
        },
        maxWidth: 85,
        suppressMenu: true,
      },
      {
        headerName: '',
        cellRenderer: 'buttonPrintRenderer',
        cellRendererParams: {
          onClick: this.onClickGetBarCodeDetails.bind(this),
          label: 'Print',
        },
        maxWidth: 70,
      },
    ];

    this.columnMaterialBatchHistory = [
      { headerName: 'Item Type', field: 'Name' },
      { headerName: 'Lotcode/Details', field: 'BatchNo' },
      { headerName: 'act', field: 'Result' },
      { headerName: 'Remarks', field: 'Remarks' },
      {
        headerName: 'Time',
        field: 'RollStarttime',
        valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
        maxWidth: 180,
      },
      {
        headerName: 'Created On',
        field: 'CreatedOn',
        valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
        maxWidth: 180,
      },
      { headerName: 'Created By', field: 'CreatedBy', maxWidth: 120 },
      {
        headerName: 'Action',
        cellRenderer: 'buttonEditRenderer',
        cellRendererParams: {
          onClick: this.onClickEditMaterialConsumptionBatch.bind(this),
          label: 'Edit',
        },
        maxWidth: 80,
        // suppressMenu: true,
      },
    ];

    this.columnMaterialConsumptionHistory = [
      {
        headerName: 'Is Rework',
        field: 'IsRework',
        cellRenderer: (params: any) => (params.value === true ? 'Yes' : 'No'),
        maxWidth: 120,
      },
      { headerName: 'Lotcode/Pallet', field: 'BatchNo' },
      { headerName: 'act', field: 'Result', maxWidth: 180 },
      { headerName: 'Total Act Qty', field: 'Actual' },
      { headerName: 'Remarks', field: 'remarks' },
      { headerName: 'Time', field: 'RollStarttime' },
      { headerName: 'Created On', field: 'CreatedOn' },
      { headerName: 'Created By', field: 'CreatedBy' },
      {
        headerName: 'Actions',
        cellRenderer: 'buttonEditRenderer',
        cellRendererParams: {
          onClick: this.onClickEditFoodSectorMaterial.bind(this),
          label: 'Edit',
        },
        maxWidth: 90,
        suppressMenu: true,
      },
      {
        headerName: '',
        cellRenderer: 'buttonViewRenderer',
        cellRendererParams: {
          onClick: this.onClickPopupViewForMaterialHistory.bind(this),
          label: 'View',
        },
        maxWidth: 85,
        suppressMenu: true,
      },
    ];
    this.translateForm = this.fb.group({
      languagvalue: [''],
    });

    this.loadingOverlayComponent = 'customLoadingOverlay';
    this.loadingOverlayComponentParams = {
      loadingMessage: 'One moment please...',
    };
    this.selection.changed.subscribe((item) => {
      this.isButtonEnable =
        this.selection.selected.filter((c: any) => c.submitted === false)
          .length < 2;
    });
  }
  // starttime = 3600000 ;
  // endTime = 0;
  // plotlines: any[]= [];
  // time(){
  //     // this.starttime = new Date(new Date().getTime());
  // // this.endTime = new Date(new Date().getTime() + (24*60*60*1000));
  // for(let i = this.starttime; i<=10800000; i+900000){
  //   this.plotlines.push({
  //     width: 2,
  //     value: i+90000,
  //   })
  // }
  // console.log("time",this.plotlines);
  //   }
  options: any;
  ngOnInit(): void {

    // this.initialTheme();
    // CIL MANAGEMENT NgOninit Logic
    this.onPrevious8();
    this.operatorName = JSON.parse(localStorage.user).UserName;
    this.ImageUrl = environment.O3CoreApiImageUrl;
    this.CilManagDataForm = this.fb.group({
      id: [''],
      unit: new FormControl('', []),
      group: new FormControl('', []),
      plant: new FormControl('', []),
      component: new FormControl('', []),
      machine: new FormControl('', []),
      machineId: new FormControl('', []),
    });

    this.performCILItemForm = this.fb.group({
      // id :[''],
      option: new FormControl('', [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      Remarks: new FormControl('', [Validators.required]),
      postImage: new FormControl(''),
    });

    // this.GetMenuChildList();
    // this.GetBreadcrumList();
    this.getGroups();
    this.performCILItemForm.get('option')?.valueChanges.subscribe((val) => {
      if (val === 'NotDone') {
        this.performCILItemForm.controls.Remarks.setValidators([
          Validators.required,
        ]);
      } else {
        this.performCILItemForm.controls.Remarks.clearValidators();
      }
      this.performCILItemForm.controls.Remarks.updateValueAndValidity();
    });
    // this.getDefectMgmtTableData();
    // this.CilManagDataForm.get('machine')?.setValue(this.machineList[0].machineId);

    // CIL MANAGEMENT NgOninit Logic

    //this.date = new Date();
    //this.GetLastShiftInfoWithoutModel();
    // this.GetShift();
    if (this.disableSFDCbuttons == true) {
      //this.toaster.warning('Please select the unit first');
    }
    this.getLangWords();
    this.checklistDataForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      checktype: ['', Validators.required],
      checkname: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      area: [''],
      executionDate: ['', Validators.required],
      shift: ['', Validators.required],
      shiftName: [''],
      unitName: ['', Validators.required],
      currentProcessOrder: ['', Validators.required],
      currentProcessOrderId: [''],
      currentProductName: ['', Validators.required],
      previousProcessOrder: [''],
      previousProcessOrderId: [''],
      previousProductName: [''],
      date: new Date(),
    });
    this.GetTotalBatchForStaticTotal();
    this.total = 'Total: ';
    this.itemCode = 'ProdCode: ';
    this.MeetingMatrixForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.translateForm.get('languagvalue')?.setValue('en');
    this.GetStatus();
    this.poListForm = this.fb.group({
      Id: [],
      poNo: [''],
      operationNo: [''],
      status: [''],
      productDetails: [''],
      itemCode: [''],
      itemDescription: [''],
      priority: [''],
      action: [''],
    });
    this.processControlForm = this.fb.group({
      groupType: [''],
      eventType: [''],
      timeInterval: [''],
      datePicker: [''],
      // fromProcessDate: [''],
      // toProcessDate: [''],
    });
    this.qualityControlForm = this.fb.group({
      groupType: [''],
      eventType: [''],
      datePicker: [''],
      timeInterval: [''],
      fromProcessDate: [''],
      toProcessDate: [''],
    });

    const start = new Date();
    const processdates: any[] = [];
    processdates.push(new Date(start.setHours(0, 0, 0, 0)).toISOString());
    processdates.push(new Date().toISOString());
    this.processControlForm
      .get('datePicker')
      ?.setValue(processdates, { emitEvent: false });

    const qualitydates: any[] = [];
    qualitydates.push(new Date(start.setHours(0, 0, 0, 0)).toISOString());
    qualitydates.push(new Date().toISOString());
    this.qualityControlForm
      .get('datePicker')
      ?.setValue(qualitydates, { emitEvent: false });

    this.processControlForm
      .get('datePicker')
      ?.valueChanges.subscribe((v) => this.onToProcessDate());
    this.qualityControlForm
      .get('datePicker')
      ?.valueChanges.subscribe((v) => this.onToProcessDateQuanlityControl());
    const source = interval(5 * 60 * 1000);
    this.subscription = source.subscribe((val) => this.onRefresh());
    this.sfdcForm = this.fb.group({
      group: ['', Validators.required],
    });

    this.materialConsumptionForm = this.fb.group({
      Id: [],
      materialDate: new Date(),
      qty: [],
      lot: [],
      actQty: [],
      remarks: [],
      date: [],
    });
    this.groupDataForm = this.fb.group({
      Id: [],
      group: [''],
      plant: [''],
      line: [''],
      machine: [''],
      status: [''],
      Unit: [''],
      processOrder: [''],
      operationName: [''],
      productDetail: [''],
      itemCode: [''],
      itemDescription: [''],
      remarks: [''],
      fromProcessDate: ['', Validators.required],
      toProcessDate: ['', Validators.required],
      processType: [''],
      parameterInterval: [],
      intervalType: [],
    });

    this.productionOutputForm = this.fb.group({
      batchcon: [],
      pallet: [''],
      actQty: [''],
      remarks: [''],
      time: new Date(),
    });
    this.wasteForm = this.fb.group({
      categoryType: [],
      dateTime: new Date(),
      uom: [],
      failureMode: [],
    });
    this.downTimeForm = this.fb.group({
      Id: [],
      startDate: new Date(),
      endDate: new Date(new Date().getTime() + this.ONE_HOUR),
      duration: [''],
      fault: [''],
      faults: [''],
      remarks: [''],
    });
    this.materialFoodSectorConsumptionForm = this.fb.group({
      isRework: [],
      mlotCode: [],
      mActQtys: [],
      tActQtys: [],
      mTime: new FormControl(new Date(), [Validators.required]),
      remarks: [],
    });
    this.shortStopsForm = this.fb.group({
      startDate: new Date(),
      endDate: new Date(),
      duration: [''],
      fault: [''],
      remarks: [''],
    });
    this.primengConfig.ripple = true; //
    this.initialdisplay();
    this.initialtimer();
    this.GetCurrentDate();

    this.getGroups();
    this.initialEffect();
  }
  ngAfterViewInit(): void {
    this.initialTheme();
    this.gridOptionsProcessControl.api.hideOverlay();
    this.gridOptionsQualityControl.api.hideOverlay();
  }
  ngOnDestroy(): any {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // DUMMY CODE
  hasChild1 = (_: number, node: ExampleFlatNode) => node.expandable;
  onRefresh(): void {
    if (this.processControlForm.valid || this.qualityControlForm.valid) {
      // this.processControlForm.get('toProcessDate')?.setValue(new Date());
      this.processControlForm
        .get('datePicker')
        ?.setValue([this.processControlForm.value.datePicker[0], new Date()], {
          emitEvent: false,
        });
      this.qualityControlForm
        .get('datePicker')
        ?.setValue([this.qualityControlForm.value.datePicker[0], new Date()], {
          emitEvent: false,
        });
      this.rowDataProcessControl = [];
      this.rowDataQualityControl = [];
      this.onToProcessDate();
      this.onToProcessDateQuanlityControl();
    } else {
      this.spinner.hide();
    }
  }
  // filter recursively on a text string using property object value
  filterRecursive(filterText: string, array: any[], property: string) {
    let filteredData;
    //make a copy of the data so we don't mutate the original
    function copy(o: any) {
      return Object.assign({}, o);
    }

    // has string
    if (filterText) {
      // need the string to match the property value
      filterText = filterText.toLowerCase();
      // copy obj so we don't mutate it and filter
      filteredData = array.map(copy).filter(function x(y) {
        if (y[property].toLowerCase().includes(filterText)) {
          return true;
        }
        // if children match
        if (y.children) {
          return (y.children = y.children.map(copy).filter(x)).length;
        }
      });
      // no string, return whole array
    } else {
      filteredData = array;
    }

    return filteredData;
  }

  // pass mat input string to recursive function and return data
  filterTree(filterText: string) {
    // use filter input text, return filtered TREE_DATA, use the 'name' object value
    this.dataSource.data = this.filterRecursive(filterText, TREE_DATA, 'name');
  }

  // filter string from mat input filter
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.cilManagementVariableGroupData.filter = filterValue
      .trim()
      .toLowerCase();

    if (this.cilManagementVariableGroupData.paginator) {
      this.cilManagementVariableGroupData.paginator.firstPage();
    }
    this.filterTree(filterValue);
    // show / hide based on state of filter string
    if (filterValue) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

  //DUMMY CODE
  public getLangWords() {
    const key = {
      ModuleId: 1,
      PageId: 1,
      LanguageId:
        this.translater.currentLang === 'en' ||
          this.translater.currentLang === undefined
          ? 1
          : 2,
    };
    this.translater1.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }
  initialEffect() {
    const input = document.querySelector('#input');
    input?.classList.remove('effect');
    input?.classList.add('disableEffect');
  }
  initialtimer(): void {
    const wrapper1 = document.querySelector('#timer');
    wrapper1?.classList.add('hide-timer');
    wrapper1?.classList.remove('show-timer');
  }
  initialdisplay(): void {
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.add('content-show');
    wrapper1?.classList.remove('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    const wrapper4 = document.querySelector('#wrapper4');
    wrapper4?.classList.remove('content-show');
    wrapper4?.classList.add('content-hide');
    const wrapper5 = document.querySelector('#wrapper5');
    wrapper5?.classList.remove('content-show');
    wrapper5?.classList.add('content-hide');
    const wrapper6 = document.querySelector('#wrapper6');
    wrapper6?.classList.remove('content-show');
    wrapper6?.classList.add('content-hide');
    const wrapper7 = document.querySelector('#wrapper7');
    wrapper7?.classList.remove('content-show');
    wrapper7?.classList.add('content-hide');
    const wrapper8 = document.querySelector('#wrapper8');
    wrapper8?.classList.remove('content-show');
    wrapper8?.classList.add('content-hide');
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.groupDataForm.value.group,
    };
    // this.machineId = null;
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.groupDataForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getLines(this.plants[0].DeptId);
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  getLines(id: any): void {
    const key = {
      plantId: this.groupDataForm.value.plant,
    };
    this.commonService.getmasterline(key).subscribe(
      (res) => {
        this.Lines = res;

        if (res.length > 0) {
          this.groupDataForm.get('line')?.setValue(this.Lines[0].plId);
          this.getMachines(this.Lines[0].plId);
        } else {
          this.groupDataForm.get('line')?.setValue('');
          this.groupDataForm.get('machine')?.setValue('');
        }
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onChangeLine(data: any): void {
    this.getMachines(data.value);
    // this.getDateShifts();
  }

  getMachines(id: any): void {
    const key = {
      plId: this.groupDataForm.value.line,
    };
    this.commonService.getMachineByLine(key).then(
      (res) => {
        this.Machines = res;
        if (res.length > 0) {
          this.groupDataForm.get('machine')?.setValue(this.Machines[0].puId);
          this.SelectUnitName();
          this.GetProcessOrderList();
        } else {
          this.groupDataForm.get('machine')?.setValue('');
        }
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onChangePlant(data: any): void {
    this.getLines(data.value);
    // this.getUom(data.value);
  }
  shiftCloseChange(event: any, element: any): void {
    const found = this.shiftCloseArray.find((x) => x.sno === element.sno);
    if (found) {
      found.item = event.target.value.trim();
    }
  }
  getArea(): any {
    const key = {
      plantId: this.checklistDataForm.value.plant,
    };
    this.AreaServices.getQualityArea(key).subscribe(
      (areadata: any) => {
        this.areaDDL = areadata;
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
        this.spinner.hide();
      }
    );
  }
  scoreUpdate(event: any, data: any): void {
    this.detailPrentId = data.checkListDetailId;
    const checkListDetailId = parseInt(data.checkListDetailId, 10);
    const d = this._alldata.find(
      (x) => x.checkListDetailId === checkListDetailId
    );
    if (data.itemType === 1) {
      const v = event.value;
      d.catType = v;
    } else {
      const score = event.target.value === '' ? '' : event.target.value;
      d.score = score;
      d.catType = score;
    }

    const result = this.checkListAddModel;
  }
  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
  }
  openWorkOrderModal(): void {
    this.dialog.open(this.displayWorkOrderModal, {
      height: '80%',
      width: '90%',
    });
  }
  ViewBatchCheckListByUnitForProductionOutput(): void {
    this.batchCheckList = [];
    const key = {
      PUId: this.machineId,
      UserId: 0,
      JobBatchId: this.JobBatchId, // 17911
    };
    this.sfdService.GetJbBatchchecklistDetails(key).subscribe((res: any) => {
      const array: any = [];
      res.batchCheckList.forEach(
        (element: {
          BatchCheckListTypeId: any;
          Name: any;
          DataType: any;
          BatchCheckListValue: any;
        }) => {
          const data2 = {
            BatchCheckListTypeId: element.BatchCheckListTypeId,
            Name: element.Name,
            DataType: element.DataType,
            BatchCheckListValue: element.BatchCheckListValue,
          };
          array.push(data2);
        }
      );
      this.ViewProductionOutputDataSource = new MatTableDataSource<any>(array);
      this.GetBatchCheckListByUnit();
    });
  }
  viewProductionHistoryBatch(element: any): void {
    this.JobBatchId = element.JobBatchId;
    this.ViewProductionOutputDataSource = new MatTableDataSource<any>([]);
    this.ViewBatchCheckListByUnitForProductionOutput();
  }
  RefreshMaterialConsumption(): void {
    this.materialConsumptionForm.get('lot')?.reset();
    this.materialConsumptionForm.get('actQty')?.reset();
    this.materialConsumptionForm.get('remarks')?.reset();
    this.materialConsumptionMode = true;
  }
  RefreshProductionOutput(): void {
    this.productionOutputForm.get('actQty')?.reset();
    this.productionOutputForm.get('remarks')?.reset();
    this.productionOutputForm.get('batchcon')?.reset();
    this.productionMode = true;
    this.GetBatchCheckListByUnit();
    this.LastInstOPjobBatch();
    this.GetLatestLotCode();
  }
  RefreshWaste(): void {
    this.wasteForm.get('dateTime')?.setValue(new Date());
    this.wasteForm.get('failureMode')?.reset();
    this.wasteForm.get('uom')?.reset();
    this.wasteMode = true;
  }
  RefreshDownTime(): void {
    this.downTimeForm.get('remarks')?.reset();
    this.dialog.closeAll();
    this.downTimeMode = true;
  }
  editDownTime(element: any): void {
    const tabGroup = this.matTabId;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }
    const tabCount = tabGroup._tabs.length;
    tabGroup.selectedIndex = 0;
    this.downTimeMode = false;
    this.downTimeForm.get('startDate')?.setValue(element.StartTime);
    this.downTimeForm.get('endDate')?.setValue(element.EndTime);
    this.downTimeForm.get('duration')?.setValue(element.Duration);
    this.downTimeForm
      .get('fault')
      ?.setValue(
        this.DTFaultList.find((x) => x.FaultName === element.FaultName).FaultId
      );
    this.downTimeForm.get('remarks')?.setValue(element.Remarks);
    this.JobDownTimeId = element.JobDownTimeId;
    this.dialog.closeAll();
    this.getMinutes(element);
    this.getEndMinutes(element);
  }
  GetJbDowntimeHistory(): void {
    const key = {
      PUID: this.machineId,
      UserId: 0,
      Ishistory: false,
    };
    this.spinner.show();

    this.sfdService.GetJbDowntimeHistory(key).subscribe((res: any) => {
      this.spinner.hide();

      this.JbDowntimeHistoryList = res.JbDowntimeHistoryList;
      this.downtimeDataSource = new MatTableDataSource<any>(
        res.JbDowntimeHistoryList
      );
      if (this.JbDowntimeHistoryList.length) {
        if (this.JbDowntimeHistoryList[0].EndTime === null) {
          console.log('this.JbDowntimeHistoryList[0]', this.JbDowntimeHistoryList[0]);
          const stratTime = new Date(this.JbDowntimeHistoryList[0].StartTime);
          const dateFuture = new Date();
          const dateNow = stratTime;

          let seconds = Math.floor((dateFuture.getTime() - dateNow.getTime()) / 1000);
          let minutes = Math.floor(seconds / 60);
          let hours = Math.floor(minutes / 60);
          const days = Math.floor(hours / 24);
          hours = hours - (days * 24);
          minutes = minutes - (days * 24 * 60) - (hours * 60);
          seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
          this.hours = hours;
          this.minutes = minutes;
          this.seconds = seconds;
          this.timer =
            ('0' + this.hours).slice(-2) +
            ':' +
            ('0' + this.minutes).slice(-2) +
            ':' +
            ('0' + this.seconds).slice(-2);
          this.meetingStarted = true;
          this.timerflag = !this.timerflag;
          const wrapper1 = document.querySelector('#timer');
          wrapper1?.classList.remove('hide-timer');
          wrapper1?.classList.add('show-timer');
          this.applyTheme();
          this.interval = setInterval(() => {
            this.seconds = this.seconds + 1;
            if (this.seconds === 60) {
              this.minutes = this.minutes + 1;
              this.seconds = 0;
            }
            if (this.minutes === 60) {
              this.hours = this.hours + 1;
              this.minutes = 0;
              this.seconds = 0;
            }
            this.timer =
              ('0' + this.hours).slice(-2) +
              ':' +
              ('0' + this.minutes).slice(-2) +
              ':' +
              ('0' + this.seconds).slice(-2);
          }, 1000);
        }
        // this.startDownTime();
      }
      this.downTimeForm.get('duration')?.setValue('60');
    });
  }
  imageZoomModal(imagePath: string): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  ProfileImageZoom(imagePath: string): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.ProfileImageZoomModal, {
      height: '50%',
      width: '30%',
    });
    // var imgZoom = (<HTMLInputElement>document.getElementById('imgZoom'));
    //  imgZoom.width  =100;
    //  imgZoom.height  =100;
  }

  imageZoomModalShow(imagePath: string): void {
    this.currentImagePath = imagePath;
    this.dialog.open(this.imageZoomModelShow, { height: '90%', width: '90%' });
  }
  onChangeradio(value: any): void {
    if (value.value === 'Yes') {
      this.yes;
    } else {
      this.no;
    }
  }
  uploadedFile(event: any, checkListDetailId: number): void {
    const ind = this.addtabledata.findIndex(
      (c: any) => c.checkListDetailId === checkListDetailId
    );
    const found = this.goodImgArray[ind];
    if (found) {
      this.goodImgArray[ind] = event.target.files[0];
      this.addtabledata[ind].catType = event.target.files[0]?.name;
    } else {
      if (event.target.files.length > 0) {
        this.fileSave = event.target.files[0];
        this.addtabledata[ind].catType = event.target.files[0].name;
        this.goodImgArray.push(this.fileSave);
      }
    }
  }
  onDateChange(event: any, element: any): void {
    this.getDateShifts();
    const checkListDetailId = parseInt(element.checkListDetailId, 10);
    const d = this._alldata.find(
      (x) => x.checkListDetailId === checkListDetailId
    );
    d.catType = event.target.value;
  }
  remarksUpdate(event: any, data: any): void {
    const remarks = event.target.value;
    const checkListDetailId = parseInt(data.checkListDetailId, 10);
    const m = this._alldata.find(
      (x) => x.checkListDetailId === checkListDetailId
    );
    m.remarks = remarks;

    const result = this.checkListAddModel;
  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;
  groupHeaderClick(row: any): void {
    row.expanded = !row.expanded;
    this.checkListAddModel.filter = performance.now().toString(); // bug here need to fix
  }

  isGroup(index: any, item: any): boolean {
    return item.level;
  }
  GetBatchCheckListByUnit() {
    this.batchCheckList = [];
    const key = {
      PUId: this.machineId,
    };
    this.sfdService.GetBatchCheckListByUnit(key).subscribe((res: any) => {
      res.batchCheckList.forEach(
        (
          element: { BatchCheckListTypeId: any; Name: any; DataType: any },
          index: any
        ) => {
          this.BatchCheckListTypeId = element.BatchCheckListTypeId;
          const data = {
            BatchCheckListTypeId: element.BatchCheckListTypeId,
            Name: element.Name,
            DataType: element.DataType,
            DataValue: element.DataType === 'bool' ? false : '',
          };
          this.batchCheckList.push(data);
        }
      );
      const array = [];
      for (let i = 0; i < res.batchCheckList.length; i++) {
        if (res.batchCheckList[i].DataType === 'dropdown') {
          array.push(res.batchCheckList[i]);
          this.dropdownArray = array;
        }
        for (let j = 0; j < this.dropdownArray.length; j++) {
          this.newDropdownList[i] = this.dropdownArray[j].BCListType.masterList;
          this.checkListFunctionForDropdown(
            this.batchCheckList[i].BatchCheckListTypeId,
            this.newDropdownList[i][0]?.TypeName
          );
        }
      }
      this.productionOutputDataSource = new MatTableDataSource<any>(
        this.batchCheckList
      );
    });
  }
  checkListFunctionForDropdown(id: number, value: any): void {
    const check = this.batchCheckList.find(
      (x: { BatchCheckListTypeId: number }) => x.BatchCheckListTypeId === id
    );
    if (check.DataType === 'dropdown') {
      check.DataValue = value;
    }
  }

  LastInstOPjobBatch(): void {
    const key = {
      PPDID: this.PPID,
      UserId: 0,
      Ishistory: false,
      BatchTypeId: 3,
      JobBatchItemTypeId: 0,
    };
    this.spinner.show();

    this.sfdService.GetJbBatchHistory(key).subscribe((res: any) => {
      this.HistoryBatchDataSource1 = new MatTableDataSource<any>(
        res.JbBatchHistoryList
      );
      this.spinner.hide();
    });
  }

  GetLatestLotCode(): void {
    const key = {
      PPDID: this.PPID,
      PUID: this.machineId,
    };
    this.sfdService.GetLatestLotCode(key).subscribe((res: any) => {
      this.isAuto = res.isAuto;
      if (res.isAuto === true) {
        this.productionOutputForm.get('pallet')?.setValue(res.LotCode);
      } else {
        this.productionOutputForm.get('pallet')?.setValue(res.LotCode);
      }
    });
  }

  GetLableBatch(element: any): void {
    const key = {
      BatchTypeId: this.materialConsumptionForm.get('materialDate')?.value,
      UserId: 0,
      PUID: this.machineId,
      TypeId: 1,
    };
    this.sfdService.GetLableBatch(key).subscribe((res: any) => {
      this.materialConsumptionForm.get('qty')?.setValue(res.Qty);
      this.materialConsumptionForm.get('date')?.setValue(new Date());
      this.qty = res.Qty;
      this.result = res.Result;
      this.columnBatchHistory.forEach(function (
        colDef: { headerName: string },
        index: any
      ) {
        if (colDef.headerName === 'act') {
          colDef.headerName = 'Act Qty' + '(' + res.Result + ')';
        }
      });
      this.columnMaterialBatchHistory.forEach(function (
        colDef: { headerName: string },
        index: any
      ) {
        if (colDef.headerName === 'act') {
          colDef.headerName = 'Act Qty' + '(' + res.Result + ')';
        }
      });
      this.columnMaterialConsumptionHistory.forEach(function (
        colDef: { headerName: string },
        index: any
      ) {
        if (colDef.headerName === 'act') {
          colDef.headerName = 'Estimated Qty' + '(' + res.Result + ')';
        }
      });
      this.itemCode =
        'Item Code: ' +
        this.ItemTypeList.find(
          (x: { BatchItemTypeId: any }) => x.BatchItemTypeId === element.value
        )?.ItemCode;
      this.GetJbBatchHistory();
      this.GetJbBatchHistory1();
      this.GetTotalBatch();
    });
  }
  GetJbBatchHistory(): void {
    const key = {
      PPDID: this.PPID,
      UserId: 0,
      Ishistory: false,
      BatchTypeId: 1,
      JobBatchItemTypeId:
        this.materialConsumptionForm.get('materialDate')?.value,
    };
    this.spinner.show();
    this.sfdService.GetJbBatchHistory(key).subscribe((res: any) => {
      this.materialConsumptionDataSource = new MatTableDataSource<any>(
        res.JbBatchHistoryList
      );
      this.spinner.hide();
    });
  }
  GetJbBatchHistory1(): void {
    const key = {
      PPDID: this.PPID,
      UserId: 0,
      Ishistory: true,
      BatchTypeId: 1,
      JobBatchItemTypeId:
        this.materialConsumptionForm.get('materialDate')?.value,
    };
    this.spinner.show();
    this.sfdService.GetJbBatchHistory(key).subscribe((res: any) => {
      this.materialConsumptionDataSource1 = new MatTableDataSource<any>(
        res.JbBatchHistoryList
      );
      this.rowDataMaterialBatchHistory = res.JbBatchHistoryList;
      this.spinner.hide();

      this.getPaginator(this.materialConsumptionDataSource1, 2);
    });
  }
  GetTotalBatch(): void {
    const key = {
      PPDID: this.PPID,
      BatchTypeId: 1,
      BatchItemTypeId: this.materialConsumptionForm.get('materialDate')?.value,
      UserId: 0,
    };
    this.sfdService.GetTotalBatch(key).subscribe((res: any) => {
      this.total = res.ErrorMessage;
    });
  }
  SaveJBBatchInfoForMaterialConsumption(): void {
    const key = {
      TransactionTypeId: 1,
      PPDID: this.PPID,
      JobBatchId: 0,
      BatchTypeId: 1,
      RollNo: 0,
      Result: this.materialConsumptionForm.value.actQty,
      BatchNo: this.materialConsumptionForm.value.lot,
      BatchItemTypeId: this.materialConsumptionForm.value.materialDate,
      Remarks: this.materialConsumptionForm.value.remarks,
      RollStarttime: this.materialConsumptionForm.value.date,
      UserId: 0,
    };
    const data = {
      plantId: this.plantId,
      lotcode: this.materialConsumptionForm.value.lot,
      eventDateTime: this.materialConsumptionForm.value.date,
      eventTypeId: 1,
      puId: this.machineId,
      processOrder: this.groupDataForm.get('processOrder')?.value,
      processOrderId: this.PPID,
    };
    this.sfdService.SaveJBBatchInfo(key).subscribe(
      (res: any) => {
        if (res.ErrorMessage.substring(2, 1) === '0') {
          this.sfdService.addSfdcEvent(data).then(
            (response) => { },
            (error) => {
              this.showPopupMsg(error.ErrorMessage);
            }
          );
        }
        this.GetJbBatchHistory();

        this.GetTotalBatch();
        this.showPopupMsg(res.ErrorMessage);
        this.materialConsumptionForm.get('date')?.setValue(new Date());
        this.materialConsumptionForm
          .get('materialDate')
          ?.setValue(this.ItemTypeList[0].BatchItemTypeId);
        this.materialConsumptionForm.get('lot')?.reset();
        this.materialConsumptionForm.get('actQty')?.reset();
        this.materialConsumptionForm.get('remarks')?.reset();
        this.processControlForm
          .get('datePicker')
          ?.setValue([this.processControlForm.value.datePicker[0], new Date()]);
        this.qualityControlForm
          .get('datePicker')
          ?.setValue([this.qualityControlForm.value.datePicker[0], new Date()]);
      },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  showPopupMsg(msg: any): any {
    if (msg !== null || msg !== '') {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
      } else {
        this.toaster.error('Error', msg.substring(3, msg.length));
      }
    }
  }
  SumbitShiftClose(manPower: any): void {
    if (manPower > 0) {
      Swal.fire({
        title: this.translater.instant('MakeSure'),
        text: this.translater.instant('AllDetails'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translater.instant('Ok'),
        cancelButtonText: this.translater.instant('Cancel'),
      }).then((result) => {
        if (result.value) {
          const key = {
            CrewId: this.crewId,
            ShiftActionList: this.shiftCloseArray,
            ManPowerRequired: manPower,
          };
          this.sfdService.SumbitShiftClose(key).subscribe((res: any) => {
            this.successMessage = res.ErrorMessage;
            this.showPopupMsg(res.ErrorMessage);
            this.GetIsShiftStart();
            this.dialog.closeAll();
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            this.translater.instant('Cancelled'),
            this.translater.instant('AlertShiftMessage'),
            'error'
          );
        }
      });
    } else {
      this.toaster.error(
        this.translater.instant('ManPower should be greater than Zero')
      );
    }
  }

  UpdateJBBatchInfoForMaterialConsumption(): void {
    const key = {
      TransactionTypeId: 2,
      PPDID: this.PPID,
      JobBatchId: this.JobBatchId,
      BatchTypeId: 1,
      RollNo: 0,
      Result: this.materialConsumptionForm.value.actQty,
      BatchNo: this.materialConsumptionForm.value.lot,
      BatchItemTypeId: this.materialConsumptionForm.value.materialDate,
      Remarks: this.materialConsumptionForm.value.remarks,
      RollStarttime: this.materialConsumptionForm.value.date,
      UserId: 0,
    };
    this.sfdService.SaveJBBatchInfo(key).subscribe(
      (res: any) => {
        this.GetTotalBatch();
        this.GetJbBatchHistory();
        this.showPopupMsg(res.ErrorMessage);
        this.materialConsumptionForm.get('lot')?.reset();
        this.materialConsumptionForm.get('actQty')?.reset();
        this.materialConsumptionForm.get('remarks')?.reset();
        this.materialConsumptionMode = true;
      },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }
  UpdateJBDowntimeInfo(): void {
    const timeDiff = moment(this.downTimeForm.get('endDate')?.value).diff(
      moment(new Date(this.downTimeForm.get('startDate')?.value)),
      'minutes'
    );
    const key = {
      TransactionTypeId: 2,
      PUID: this.machineId,
      JobdowtimeId: this.JobDownTimeId,
      FaultId: this.downTimeForm.get('fault')?.value,
      StartTime: this.downTimeForm.get('startDate')?.value,
      EndTime: this.downTimeForm.get('endDate')?.value,
      Remarks: this.downTimeForm.get('remarks')?.value,
      Duration: timeDiff, // this.downTimeForm.get('duration')?.value,
      UserId: 0,
    };
    this.sfdService.SaveJBDowntimeInfo(key).subscribe(
      (res: any) => {
        this.GetJbDowntimeHistory();
        this.showPopupMsg(res.ErrorMessage);
        this.downTimeForm.get('remarks')?.reset();
        this.downTimeMode = true;
      },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  getFoodSectorMaterialConsumptionHistoryList() {
    const key = {
      Ishistory: true,
      PPDId: this.PPID,
    };
    this.sfdService.getBatchItemBOMList(key).subscribe(
      (res: any) => {
        this.materialHistoryItemBOMList = res.BatchItemBOMList;
        this.rowDataMaterialConsumptionHistory = res.BatchItemBOMList;
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  openHistoryFoodMaterialModal(): void {
    this.isFoodMaterialHisory = true;
    this.dialog.open(this.historyMaterialModal, {
      width: '100vw !important',
      height: 'auto',
      minWidth: '100vw',
    });
    this.getFoodSectorMaterialConsumptionHistoryList();
  }
  GetJbDowntimeHistory1(): void {
    const key = {
      PUID: this.machineId,
      UserId: 0,
      Ishistory: true,
    };
    this.spinner.show();
    this.sfdService.GetJbDowntimeHistory(key).subscribe((res: any) => {
      this.JbDowntimeHistoryList = res.JbDowntimeHistoryList;
      this.downtimeHistoryDataSource = new MatTableDataSource<any>(
        res.JbDowntimeHistoryList
      );

      // tslint:disable-next-line:max-line-length
      const array = this.JbDowntimeHistoryList.filter(
        (x) =>
          (new Date(x.StartTime) >= this.shiftstart &&
            new Date(x.EndTime) <= this.shiftend) ||
          x.EndTime === null
      );
      if (array.length) {
        const totalDownTime = array.reduce(
          (total, obj) => obj.Duration + total,
          0
        );
        // (total, obj) => obj.Duration ? obj.Duration : moment(obj.EndTime === null ? new Date(): new Date(obj.EndTime)).diff(moment(new Date(obj.StartTime), 'hours')  + total, 0
        this.SFDC_TotalDowntime = totalDownTime.toFixed(2) ?? 0;
      }

      // tslint:disable-next-line:max-line-length
      const timeslotsDiff =
        this.SFDC_TotalProducedQTy < 1
          ? 0
          : this.timedifference -
          this.SFDC_TotalDowntime /
          (this.SFDC_TotalProducedQTy > 0 ? this.SFDC_TotalProducedQTy : 1);
      this.SFDC_TotalAverageSpeed = timeslotsDiff.toFixed(2) ?? 0;
      // const timeDiff = moment(x.StartTime).subtract(moment(x.EndTime), 'hours');

      const currenttimeDiff = moment(new Date()).diff(
        moment(new Date(this.poStartDate)),
        'minutes'
      );
      this.curentTimeDiff = currenttimeDiff;
      const grossporduceinMin =
        this.curentTimeDiff / this.SFDC_TotalProducedQTy;
      const remaningQty = this.targetQty - this.SFDC_TotalProducedQTy;
      this.SFDC_RemaningTime =
        remaningQty > 0
          ? (this.targetQty - this.SFDC_TotalProducedQTy) * grossporduceinMin
          : 0;
      // if (this.SFDC_RemaningTime > 60) {
      //   const num = this.SFDC_RemaningTime;
      //   const days = num / 1440;
      //   const rdays = Math.round(days);
      //   this.calculatedremainingtime = rdays > 0 ? rdays + 'day(s)' : 0;
      //   const hours = num / 60;
      //   const rhours = Math.floor(hours);
      //   const minutes = (hours - rhours) * 60;
      //   const rminutes = Math.round(minutes);
      //   this.calculatedremainingtime =
      //     rhours + ' hr(s) and ' + rminutes + ' min(s).';
      // } else {
      //   this.calculatedremainingtime = this.SFDC_RemaningTime + ' min(s).';
      // }
      this.RemainingTime();
      this.rowDataDowntimeHistory = res.JbDowntimeHistoryList;
      this.spinner.hide();

      this.getPaginator(this.downtimeHistoryDataSource, 2);
      this.GetJbDowntimeHistory();
    });
  }
  RemainingTime(): void {
    const num = this.SFDC_RemaningTime;
    const days = num / 1440;
    const rdays = Math.round(days);
    const hours = num / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    if (rhours >= 24) {
      this.calculatedremainingtime = rdays + 'day(s)';
    } else if (rminutes >= 60) {
      this.calculatedremainingtime =
        rhours + ' hr(s) and ' + rminutes + ' min(s).';
    } else if (rminutes < 60) {
      this.calculatedremainingtime = rminutes + ' min(s).';
    }
  }
  openModal(): void {
    if (this.onlymachineSelectProperty === true) {
      this.dialog.open(this.displayRoleModal, {
        width: '100vw !important',
        height: 'auto',
        minWidth: '100vw',
      });
      this.GetJbDowntimeHistory1();
    } else {
      this.toaster.error('error', this.translater.instant('UnitSelection'));
    }
  }

  openHistorybatchModal(element: any): void {
    if (this.machineSelectProperty === true) {
      this.dialog.open(this.historyBatch1Modal, {
        width: '100vw !important',
        height: 'auto',
        minWidth: '100vw',
      });
    } else {
      this.toaster.error('error', this.translater.instant('PoListSelection'));
    }
    this.GetJbBatchHistory1();
  }

  createNewRow(id: number): ShiftCloseInterface {
    return {
      sno: id,
      item: '',
    };
  }
  shAdd(): void {
    // //
    const data: ShiftCloseInterface[] = [];
    for (let i = 1; i <= 50; i++) {
      data.push(this.createNewRow(i));
    }
    this.shiftactionDataSource.data.push(
      this.createNewRow(this.shiftactionDataSource.data.length + 1)
    );
    this.shiftactionDataSource.filter = '';
    this.shiftCloseArray.push(
      this.createNewRow(this.shiftactionDataSource.data.length)
    );
  }
  editProductionHistoryBatch(element: any): void {
    this.productionMode = false;
    this.productionOutputForm.get('batchcon')?.setValue(element.IPBatchId);
    this.productionOutputForm.get('pallet')?.setValue(element.BatchNo);
    this.productionOutputForm.get('actQty')?.setValue(element.Result);
    this.productionOutputForm.get('remarks')?.setValue(element.Remarks);
    this.productionOutputForm.get('time')?.setValue(element.RollStarttime);
    this.JobBatchId = element.JobBatchId;
    this.ProductionOutputDataSource = new MatTableDataSource<any>([]);
    this.GetBatchCheckListByUnitForProductionOutput();
    this.dialog.closeAll();
  }
  GetBatchCheckListByUnitForProductionOutput(): void {
    this.batchCheckList = [];
    const key = {
      PUId: this.machineId,
      UserId: 0,
      JobBatchId: this.JobBatchId,
    };
    this.sfdService.GetJbBatchchecklistDetails(key).subscribe((res: any) => {
      const array: any = [];
      res.batchCheckList.forEach(
        (element: {
          BatchCheckListTypeId: any;
          Name: any;
          DataType: any;
          BatchCheckListValue: any;
        }) => {
          const data2 = {
            BatchCheckListTypeId: element.BatchCheckListTypeId,
            Name: element.Name,
            DataType: element.DataType,
            BatchCheckListValue: element.BatchCheckListValue,
          };
          array.push(data2);
          this.batchCheckList = res.batchCheckList;
        }
      );
      this.productionOutputDataSource = new MatTableDataSource<any>(array);
    });
  }
  SaveJBBatchInfoForProductionOutput(): void {
    const array: {
      BatchCheckListTypeId: number;
      BatchCheckListValue: any;
    }[] = [];
    this.batchCheckList.forEach(
      (element: { BatchCheckListTypeId: any; DataValue: any }) => {
        const data = {
          BatchCheckListTypeId: element.BatchCheckListTypeId,
          BatchCheckListValue: element.DataValue,
        };
        array.push(data);
      }
    );
    const key = {
      TransactionTypeId: 1,
      PPDID: this.PPID,
      JobBatchId: 0,
      BatchTypeId: 3,
      RollNo: 0,
      Result: this.productionOutputForm.value.actQty,
      BatchNo: this.productionOutputForm.value.pallet,
      BatchItemTypeId: 0,
      Remarks: this.productionOutputForm.value.remarks,
      RollStarttime: this.productionOutputForm.value.time,
      UserId: 0,
      IPBatch: this.productionOutputForm.value.batchcon,
      JobBatchCheckList: array,
    };
    const data = {
      plantId: this.plantId,
      lotcode: this.productionOutputForm.value.pallet,
      eventDateTime: this.productionOutputForm.value.time,
      eventTypeId: 2,
      puId: this.machineId,
      processOrder: this.groupDataForm.get('processOrder')?.value,
      processOrderId: this.PPID,
    };
    this.sfdService.SaveJBBatchInfo(key).subscribe((res: any) => {
      if (this.machineSelectProperty === true) {
        // this.GetJbBatchHistory();
        this.LastInstOPjobBatch();
        this.GetJbBatchHistoryNew();
        this.GetTotalBatch1();
        this.GetLatestLotCode();
        this.showPopupMsg(res.ErrorMessage);
        this.GetBatchCheckListByUnit();
        this.productionOutputForm.get('time')?.setValue(new Date());
        this.productionOutputForm.get('actQty')?.reset();
        this.productionOutputForm.get('remarks')?.reset();
        if (this.isAuto === false) {
          this.productionOutputForm.get('pallet')?.reset();
        }
        this.productionMode = true;
        if (res.ErrorMessage.substring(2, 1) === '0') {
          this.sfdService.addSfdcEvent(data).then(
            (response) => { },
            (error) => {
              this.showPopupMsg(error.ErrorMessage);
            }
          );
        }
      } else {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('PoListSelection')
        );
      }
      this.processControlForm
        .get('datePicker')
        ?.setValue([this.processControlForm.value.datePicker[0], new Date()]);
      this.qualityControlForm
        .get('datePicker')
        ?.setValue([this.qualityControlForm.value.datePicker[0], new Date()]);
    });
  }

  GetJbBatchHistoryNew(): void {
    const key = {
      PPDID: this.PPID,
      UserId: 0,
      Ishistory: true,
      BatchTypeId: 3,
      JobBatchItemTypeId: 0,
    };
    this.spinner.show();
    this.sfdService.GetJbBatchHistory(key).subscribe((res: any) => {
      this.spinner.hide();

      this.HistoryBatchDataSource1 = new MatTableDataSource<any>(
        res.JbBatchHistoryList[0]
      );
      this.HistoryBatchDataSource = new MatTableDataSource<any>(
        res.JbBatchHistoryList
      );
      this.rowDataBatchHistory = res.JbBatchHistoryList;
      this.getPaginator(this.HistoryBatchDataSource, 2);

      this.LastInstOPjobBatch();
    });
  }

  GetTotalBatch1(): void {
    const key = {
      PPDID: this.PPID,
      BatchTypeId: 3,
      BatchItemTypeId: 0,
      UserId: 0,
    };
    this.sfdService.GetTotalBatch(key).subscribe((res: any) => {
      this.total1 = res.ErrorMessage;
      this.SFDC_TotalProducedQTy = this.total1.replace(/\D/g, '');
      this.GetTotalWaste();
      this.SFDC_TotalGoodPieces =
        this.SFDC_TotalProducedQTy - this.SFDC_Totalwaste || 0;
      this.GetLableBatch1(this.SFDC_TotalProducedQTy);
      this.GetJbDowntimeHistory1();
    });
  }

  getOeeDashboard(): void {
    const key = {
      GroupId: this.groupDataForm.value.group,
      PlantId: this.plantId,
      UnitId: this.unitId,
      dtEndDate: this.shiftend,
      dtStartDate: this.shiftstart,
      // dtEndDate:"2021-12-14T05:10:22.437Z",
      // dtStartDate: "2021-12-14T01:00:00.904Z",
      shift: '*',
    };
    this.spinner.show();
    this.productivityservice.getOEE(key).subscribe(
      (data: any) => {
        this.OeeDashboardData = data;
        this.SFDC_OEE = Math.round(data.AvgOee);
        this.SFDC_Availability = Math.round(data.AvgAvailability);
        this.SFDC_Performance = Math.round(data.AvgPerformance);
        this.SFDC_Quality = Math.round(data.AvgQualityRate);
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();
      }
    );
  }

  UpdateJBBatchInfoForProductionOutput(): void {
    const dataArray = this.batchCheckList;
    const array: { BatchCheckListTypeId: any; BatchCheckListValue: any }[] = [];
    dataArray.forEach(
      (element: {
        BatchCheckListTypeId: any;
        hasOwnProperty: (arg0: string) => boolean;
        DataValue: any;
        BatchCheckListValue: any;
      }) => {
        const data = {
          BatchCheckListTypeId: element.BatchCheckListTypeId,
          BatchCheckListValue:
            element.hasOwnProperty('DataValue') === true
              ? element.DataValue
              : element.BatchCheckListValue,
        };
        array.push(data);
      }
    );
    const key = {
      TransactionTypeId: 2,
      PPDID: this.PPID,
      JobBatchId: this.JobBatchId,
      BatchTypeId: 3,
      RollNo: 0,
      Result: this.productionOutputForm.value.actQty,
      BatchNo: this.productionOutputForm.value.pallet,
      BatchItemTypeId: 0,
      Remarks: this.productionOutputForm.value.remarks,
      RollStarttime: this.productionOutputForm.value.time,
      UserId: 0,
      IPBatch: this.productionOutputForm.value.batchcon,
      JobBatchCheckList: array,
    };

    this.sfdService.SaveJBBatchInfo(key).subscribe(
      (res: any) => {
        this.GetLatestLotCode();
        this.LastInstOPjobBatch();
        this.GetJbBatchHistoryNew();
        this.GetBatchCheckListByUnit();
        this.GetTotalBatch1();
        this.showPopupMsg(res.ErrorMessage);
        this.productionOutputForm.get('time')?.setValue(new Date());
        this.productionOutputForm.get('actQty')?.reset();
        this.productionOutputForm.get('remarks')?.reset();
        this.productionMode = true;
      },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }
  highlight(row: any): void { }

  getBatchConsumption() {
    const key = {
      id: this.PPID,
    };
    this.sfdService.getBatchConsumptionDD(key).subscribe(
      (res: any) => {
        this.batchConsumptionList = res.masterList;
        this.filteredbatchConsumptionList = this.batchConsumptionList.slice();
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  checkListFunction(id: number, event: any): void {
    const check = this.batchCheckList.find(
      (x: { BatchCheckListTypeId: number }) => x.BatchCheckListTypeId === id
    );
    switch (check?.DataType) {
      case 'bool':
        check.DataValue =
          check.DataValue === 'true' || check.BatchCheckListValue === 'true'
            ? false
            : true;
        break;
      case 'number':
        check.DataValue = parseInt(event.target.value, 10);
        break;
      case 'string':
        check.DataValue = event.target.value;
        break;
      case 'date':
        check.DataValue = event.target.value._d;
        break;
      case 'dropdown':
        check.DataValue = event.target.value;
    }
    console.table(this.batchCheckList);
  }
  // openHistoryModal(): void {
  //   //if (this.machineSelectProperty === true) {
  //   this.dialog.open(this.historyBatchModal, {
  //     width: '100vw !important',
  //     height: 'auto',
  //     minWidth: '100vw',
  //   });
  //   this.GetJbBatchHistoryNew();
  //   //} else {
  //   this.toaster.error('error', this.translater.instant('PoListSelection'));
  //   //}
  // }
  openHistoryModal(): void {
    if (this.machineSelectProperty === true) {
      this.dialog.open(this.historyBatchModal, {
        width: '100vw !important',
        height: 'auto',
        minWidth: '100vw',
      });
      this.GetJbBatchHistoryNew();
    } else {
      this.toaster.error('error', this.translater.instant('PoListSelection'));
    }
  }
  GetBarCodeDetails(element: any): void {
    const key = {
      UnitId: this.machineId,
      PPD_ID: this.PPID,
      BCType: 1,
      Id: element.JobBatchId,
    };
    this.sfdService.GetBarCodeDetails(key).subscribe((res: any) => {
      if (res.GetBarCodeDetails.length > 0) {
        let htmlcontent = res.GetBarCodeDetails[0].HTMLLayout;
        const detailItems = res.GetBarCodeDetails[0].detailItems;
        htmlcontent = htmlcontent.replace('[BarCodeSeries]', res.BarCodeSeries);

        $.each(detailItems, (i, item) => {
          htmlcontent = htmlcontent.replace('[' + item.Name + ']', item.Value);
        });

        const printWindow = window.open('', 'Print-Window');
        // printWindow?.document.open();

        const styleTags =
          '' +
          '<style type="text/css">' +
          '#table1 th, table td {' +
          'border:1px solid #000;' +
          'padding:0.5em;' +
          '}' +
          '.td-bold {font-weight:bold; color:blue; }' +
          '.circle { display:block;height:50px;width:100px;border-radius:50%;border:2px solid #000;margin: auto;color:#fff;line-height:50px;text-align:center}' +
          '</style>';

        const scriptTags =
          '' +
          '<script type="text/javascript">' +
          'window.addEventListener(' +
          "'load'" +
          ', function () {' +
          'JsBarcode(".barcode").init();' +
          '})' +
          '</script>';

        const JsBCScript =
          '' +
          '<script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.3/dist/JsBarcode.all.min.js"></script>';

        printWindow?.document.write(
          styleTags +
          JsBCScript +
          scriptTags +
          '<html><body onload="window.print()">' +
          htmlcontent +
          '</body></html>'
        );
        printWindow?.document.close();
        setTimeout(() => {
          printWindow?.close();
        }, 10);
        printWindow?.print();
      } else {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('DataEmpty')
        );
      }
    });
    this.GetBatchCheckListByUnit();
  }
  onClickPopupViewForChecklistHistory(data: any): void {
    this.openPopupViewForChecklistHistory(data.data);
  }

  openPopupViewForChecklistHistory(element: any): void {
    this.dialog.open(this.popupViewModal, {
      width: '100vw !important',
      height: 'auto',
      minWidth: '100vw',
    });
    this.viewProductionHistoryBatch(element);
  }

  GetKpiParameters(): void {
    const key = {
      PUId: this.machineId,
      UserId: 0,
    };
    this.spinner.show();

    this.sfdService.GetKpiParameters(key).subscribe((res: any) => {
      this.processparameter1DataSource = new MatTableDataSource<any>(
        res.ProcessParameterList
      );
      this.processparameter22DataSource = new MatTableDataSource<any>(
        res.MeterialParameterList
      );
      this.processparameter33DataSource = new MatTableDataSource<any>(
        res.LineParameterList
      );
      this.spinner.hide();
    });
  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
      data.sort = this.sort.toArray()[index];
    }, 1000);
  }
  GetKpiParamInfoList(element: any): void {
    const key = {
      PPD_Id: this.PPID,
      UserId: 0,
      JobKpiParamInfoId: 0,
    };
    this.sfdService.GetKpiParamInfoList(key).subscribe((res: any) => {
      this.centerLineDataSource = new MatTableDataSource<any>(
        res.KpiParameterinfoList
      );
      this.getPaginator(this.centerLineDataSource, 0);
    });
  }

  SumbitJobKpiParameters(element1: any): void {
    this.processValues1 = [];
    const table1 = this.processparameter1DataSource.filteredData;
    const table2 = this.processparameter22DataSource.filteredData;
    const table3 = this.processparameter33DataSource.filteredData;
    table1.forEach((element) => {
      const Id = element.id;
      const data = {
        PPD_Id: this.PPID,
        KpiParameterId: element.KpiparaId,
        KpiParameterValue: element.KpiValues,
      };
      this.processValues1.push(data);
    });
    table2.forEach((element) => {
      const data = {
        PPD_Id: this.PPID,
        KpiParameterId: element.KpiparaId,
        KpiParameterValue: element.KpiValues,
      };
      this.processValues1.push(data);
    });
    table3.forEach((element) => {
      const data = {
        PPD_Id: this.PPID,
        KpiParameterId: element.KpiparaId,
        KpiParameterValue: element.KpiValues,
      };
      this.processValues1.push(data);
    });
    const key = {
      UserId: 0,
      TransactionTypeId: 1,
      JobKpiParamInfoId: 0,
      JobkpiparaInfoList: this.processValues1,
    };
    this.sfdService.SumbitJobKpiParameters(key).subscribe(
      (res: any) => {
        this.GetKpiParamInfoList(element1);
        this.showPopupMsg(res.ErrorMessage);
        this.dialog.closeAll();
      },
      (error: { ErrorMessage: any }) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  SumbitJobKpiParameters1(element1: any): void {
    // //
    this.processValues = [];
    const table1 = this.processparameter1DataSource.filteredData;
    const table2 = this.processparameter22DataSource.filteredData;
    const table3 = this.processparameter33DataSource.filteredData;
    table1.forEach((element) => {
      const data = {
        PPD_Id: this.PPID,
        KpiParameterId: element.KpiparaId,
        KpiParameterValue: element.KpiValues,
      };
      this.processValues.push(data);
    });
    table2.forEach((element) => {
      const data = {
        PPD_Id: this.PPID,
        KpiParameterId: element.KpiparaId,
        KpiParameterValue: element.KpiValues,
      };
      this.processValues.push(data);
    });
    table3.forEach((element) => {
      const data = {
        PPD_Id: this.PPID,
        KpiParameterId: element.KpiparaId,
        KpiParameterValue: element.KpiValues,
      };
      this.processValues.push(data);
    });
    const key = {
      UserId: 0,
      TransactionTypeId: 2,
      JobKpiParamInfoId: this.obKpiParamInfoId1,
      JobkpiparaInfoList: this.processValues,
    };
    this.sfdService.SumbitJobKpiParameters(key).subscribe(
      (res: any) => {
        this.GetKpiParamInfoList(element1);
        this.showPopupMsg(res.ErrorMessage);
        this.dialog.closeAll();
      },
      (error: { ErrorMessage: any }) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  editProcessParameter(element: any): void {
    this.centerLineMode = false;
    this.dialog.open(this.centerlineModal, {
      width: '100vw !important',
      height: 'auto',
      minWidth: '100vw',
    });
    this.obKpiParamInfoId1 = element.JobKpiParamInfoId;
    this.GetKpiParamInfoDetails();
  }
  GetKpiParamInfoDetails(): void {
    this.booleanConvertedArray = [];
    this.booleanConvertedArray1 = [];
    this.booleanConvertedArray2 = [];
    const key = {
      PPD_Id: this.PPID,
      UserId: 0,
      JobKpiParamInfoId: this.obKpiParamInfoId1,
    };
    this.spinner.show();
    this.sfdService.GetKpiParamInfoDetails(key).subscribe((res: any) => {
      this.spinner.hide();

      this.MaterialParameterList = res.MeterialParameterList;
      this.LineParameterList = res.LineParameterList;
      res.ProcessParameterList.forEach((element: any) => {
        const data = {
          KpiparaId: element.KpiparaId,
          KpiName: element.KpiName,
          KpiValues: element.KpiValues,
        };
        this.booleanConvertedArray2.push(data);
      });
      res.MeterialParameterList.forEach((element: any) => {
        const data = {
          KpiparaId: element.KpiparaId,
          KpiName: element.KpiName,
          KpiValues: element.KpiValues,
        };
        this.booleanConvertedArray.push(data);
      });
      res.LineParameterList.forEach((element: any) => {
        const data = {
          KpiparaId: element.KpiparaId,
          KpiName: element.KpiName,
          KpiValues: Boolean(JSON.parse(element.KpiValues)),
        };
        this.booleanConvertedArray1.push(data);
      });
      this.processparameter1DataSource = new MatTableDataSource<any>(
        this.booleanConvertedArray2
      );
      this.processparameter22DataSource = new MatTableDataSource<LineInterface>(
        this.booleanConvertedArray
      );
      this.processparameter33DataSource = new MatTableDataSource<LineInterface>(
        this.booleanConvertedArray1
      );
    });
  }
  checkListFunctionForProcess(element: any, event: any): void {
    if (!this.centerLineMode) {
      const check = this.booleanConvertedArray2.find(
        (x) => x.KpiparaId === element.KpiparaId
      );
      check!.KpiValues = event.target.value;
    } else {
      const index = this.processparameter1DataSource.filteredData.findIndex(
        (e: any) => e.KpiparaId === element.KpiparaId
      );
      this.processparameter1DataSource.filteredData[index].KpiValues =
        event.target.value;
    }
  }
  checkListFunctionMaterial(element: any, event: any): void {
    if (!this.centerLineMode) {
      const check = this.booleanConvertedArray.find(
        (x) => x.KpiparaId === element.KpiparaId
      );
      check!.KpiValues = event.target.value;
    } else {
      const index = this.processparameter22DataSource.filteredData.findIndex(
        (e: any) => e.KpiparaId === element.KpiparaId
      );
      this.processparameter22DataSource.filteredData[index].KpiValues =
        event.target.value;
    }
  }

  checkListFunctionLine(element: any, event: any): void {
    if (!this.centerLineMode) {
      const check = this.booleanConvertedArray1.find(
        (x) => x.KpiparaId === element.KpiparaId
      );
      check!.KpiValues = check?.KpiValues ? false : true;
    } else {
      const index = this.processparameter33DataSource.filteredData.findIndex(
        (e: any) => e.KpiparaId === element.KpiparaId
      );
      this.processparameter33DataSource.filteredData[index].KpiValues = event
        .target.value
        ? true
        : false;
    }
  }
  async GetQualityAllDataTypesDetail(val: any): Promise<any> {
    const key = {
      variableId: val,
    };
    await this.qualityParameterService
      .GetAllDataTypesDetail(key)
      .then((res: any) => {
        this.qualityboolList = res.map((c: any) => c.text);
      });
  }
  GetJbWasteHistoryForHistory(): void {
    const key = {
      PPDID: this.PPID,
      UserId: 0,
      Ishistory: true,
    };
    this.spinner.show();
    this.sfdService.GetJbWasteHistory(key).subscribe((res: any) => {
      this.newJbWasteHistoryList = res.JbWasteHistoryList;
      this.rowDataWasteHistory = res.JbWasteHistoryList;
      this.spinner.hide();
      this.wasteDataSource1 = new MatTableDataSource<any>([
        this.newJbWasteHistoryList[0],
      ]);
    });
  }
  GetTotalWaste(): void {
    const key = {
      PPDID: this.PPID,
      UserId: 0,
    };
    this.sfdService.GetTotalWaste(key).subscribe((res: any) => {
      this.totalWaste = res.ErrorMessage;
      this.SFDC_Totalwaste = this.totalWaste.replace(/\D/g, '');
    });
  }
  date: any;

  GetCurrentDate() {
    this.date = new Date();
    let latest_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  }
  GetJbWasteHistory(): void {
    const key = {
      PPDID: this.PPID,
      UserId: 0,
      Ishistory: false,
    };
    this.spinner.show();

    this.sfdService.GetJbWasteHistory(key).subscribe((res: any) => {
      this.newJbWasteHistoryList = res.JbWasteHistoryList;
      this.wasteDataSource1 = new MatTableDataSource<any>(
        res.JbWasteHistoryList
      );
      this.spinner.hide();
    });
  }
  SaveJBWasteInfo(): void {
    const key = {
      TransactionTypeId: 1,
      PPDID: this.PPID,
      WasteEntryId: 0,
      FaultId: this.wasteForm.get('categoryType')?.value,
      Amount: this.wasteForm.get('uom')?.value,
      Time: this.wasteForm.value.dateTime,
      Remarks: this.wasteForm.get('failureMode')?.value,
      UserId: 0,
    };
    this.sfdService.SaveJBWasteInfo(key).subscribe(
      (res: any) => {
        this.GetJbWasteHistoryForHistory();
        this.GetTotalWaste();
        this.showPopupMsg(res.ErrorMessage);
        this.wasteForm.get('failureMode')?.reset();
        this.wasteForm.get('uom')?.reset();
      },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  UpdateJBWasteInfo(): void {
    const key = {
      TransactionTypeId: 2,
      PPDID: this.PPID,
      WasteEntryId: this.WasteEntryId,
      FaultId: this.wasteForm.get('categoryType')?.value,
      Amount: this.wasteForm.get('uom')?.value,
      Time: this.wasteForm.value.dateTime,
      Remarks: this.wasteForm.get('failureMode')?.value,
      UserId: 0,
    };

    this.sfdService.SaveJBWasteInfo(key).subscribe(
      (res: any) => {
        this.GetJbWasteHistory();
        this.GetTotalWaste();
        this.showPopupMsg(res.ErrorMessage);
        this.wasteForm.get('failureMode')?.reset();
        this.wasteForm.get('uom')?.reset();
        this.wasteMode = true;
      },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  editMaterialConsumptionHistoryBatch(element: any): void {
    this.materialConsumptionMode = false;
    this.materialConsumptionForm.get('lot')?.setValue(element.BatchNo);
    this.materialConsumptionForm.get('actQty')?.setValue(element.Result);
    this.materialConsumptionForm.get('remarks')?.setValue(element.Remarks);
    this.materialConsumptionForm.get('date')?.setValue(element.RollStarttime);
    this.JobBatchId = element.JobBatchId;
    this.dialog.closeAll();
  }
  editWasteHistory(element: any): void {
    this.wasteMode = false;
    this.wasteForm.get('dateTime')?.setValue(element.Time);
    this.wasteForm.get('failureMode')?.setValue(element.Remarks);
    this.wasteForm.get('uom')?.setValue(element.Amount);
    this.WasteEntryId = element.WasteEntryId;
    this.dialog.closeAll();
    this.wasteForm.get('categoryType')?.setValue(element.FaultId);
  }
  GetSTFault(): void {
    const key = {
      PUId: this.machineId,
    };
    this.sfdService.GetSTFault(key).subscribe((res: any) => {
      this.GTList = res.FaultList;
      this.filteredGTList = this.GTList.slice();
      this.shortStopsForm.get('fault')?.setValue(this.GTList[0]?.FaultId);
    });
  }
  changeExpireDate(event: any, id: number) {
    this.ItemBOMList.find((item) => item.ItemBOMDetailId === id).ExpireDate =
      moment(event.target.value).format('YYYY-MM-DD HH:mm');
    this.dateRangeValidation(id, event);
  }
  invalidDate: boolean = false;
  dateRangeValidation(id: number, event: any) {
    let from = '';
    let to = '';
    this.ItemBOMList.forEach((item: any, index: any) => {
      if (item.ItemBOMDetailId === id) {
        from = item.ExpireDate;
        to = item.ProductionDate;
        if (from && to) {
          this.invalidDate = new Date(from).valueOf() > new Date(to).valueOf();
          this.invalidDate ? { invalidRange: { from, to } } : null;
          if (this.invalidDate === false) {
            this.toaster.error(this.translater.instant('CheckExpiryDate'));
            event.target.value = '';
            this.ItemBOMList.find(
              (item) => item.ItemBOMDetailId === id
            ).ExpireDate = '';
          }
        } else if (to === '' || to === null) {
          this.toaster.error(
            this.translater.instant('ProductionDateSelection')
          );
          event.target.value = '';
          this.ItemBOMList.find(
            (item) => item.ItemBOMDetailId === id
          ).ExpireDate = '';
        }
      }
    });
  }
  changeActQty(event: any, id: number) {
    this.ItemBOMList.find((item) => item.ItemBOMDetailId === id).ItemBOMValue =
      parseFloat(event.target.value);
  }

  changeSupplier(event: any, id: number) {
    this.ItemBOMList.find((item) => item.ItemBOMDetailId === id).Supplier =
      event.target.value;
  }

  changeProductionDate(event: any, id: number) {
    this.ItemBOMList.find(
      (item) => item.ItemBOMDetailId === id
    ).ProductionDate = moment(event.target.value).format('YYYY-MM-DD HH:mm');
    this.dateRangeValidation(id, event);
  }
  SaveFoodSectorMaterialConsumption(): void {
    this.spinner.show();
    if (this.JobBatchMaterialId > 0) {
      const array: {
        ItemBOMDetailId: number;
        ItemBOMValue: any;
        Supplier: any;
        ExpireDate: any;
        ProductionDate: any;
      }[] = [];
      this.ItemBOMList.forEach((element) => {
        const data = {
          ItemBOMDetailId: element.ItemBOMDetailId,
          Supplier: element.Supplier,
          ExpireDate: element.ExpireDate,
          ProductionDate: element.ProductionDate,
          ItemBOMValue: element.ItemBOMValue,
        };
        array.push(data);
      });
      const key = {
        TransactionTypeId: 2,
        PPDID: this.PPID,
        Result: this.materialFoodSectorConsumptionForm.value.mActQtys,
        BatchNo: this.materialFoodSectorConsumptionForm.value.mlotCode,
        Remarks: this.materialFoodSectorConsumptionForm.value.remarks,
        RollStarttime: this.materialFoodSectorConsumptionForm.value.mTime,
        UserId: 0,
        JobBatchItemBomList: array,
        JobBatchId: this.JobBatchMaterialId,
      };
      this.sfdService.SaveFoodSectorMaterialConsumption(key).subscribe(
        (res: any) => {
          if (res.HasError === false) {
            this.toaster.success(
              this.translater.instant('Success'),
              this.translater.instant('MaterialConsumptionSaved')
            );
            this.getFoodSectorMaterialConsumptionList();
            this.getBatchConsumption();
            this.getItemBomListInfo();
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        (error: any) => {
          this.toaster.error(
            this.translater.instant('Error'),
            this.translater.instant('WentWrong')
          );
          this.spinner.hide();
        }
      );
    } else if (this.materialFoodSectorConsumptionForm.valid) {
      const array: {
        ItemBOMDetailId: number;
        ItemBOMValue: any;
        Supplier: any;
        ExpireDate: any;
        ProductionDate: any;
      }[] = [];
      this.ItemBOMList.forEach((element) => {
        const data = {
          ItemBOMDetailId: element.ItemBOMDetailId,
          Supplier: element.Supplier,
          ExpireDate: element.ExpireDate,
          ProductionDate: element.ProductionDate,
          ItemBOMValue: element.ItemBOMValue,
        };
        array.push(data);
      });
      const key = {
        TransactionTypeId: 1,
        PPDID: this.PPID,
        Result: this.materialFoodSectorConsumptionForm.value.mActQtys,
        BatchNo: this.materialFoodSectorConsumptionForm.value.mlotCode,
        Remarks: this.materialFoodSectorConsumptionForm.value.remarks,
        RollStarttime: this.materialFoodSectorConsumptionForm.value.mTime,
        UserId: 0,
        JobBatchItemBomList: array,
      };
      this.sfdService.SaveFoodSectorMaterialConsumption(key).subscribe(
        (res: any) => {
          if (res.HasError === false) {
            this.showPopupMsg(res.ErrorMessage);
            this.getFoodSectorMaterialConsumptionList();
            this.getBatchConsumption();
            this.getItemBomListInfo();
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        (error: any) => {
          this.toaster.error('error', error.message);
          this.spinner.hide();
        }
      );
    } else {
      this.toaster.error('Expiry date should be greater than Production date');
    }
    this.spinner.hide();
  }
  RefreshMaterialSubConsumption(): void {
    this.materialFoodSectorConsumptionForm.get('remarks')?.reset();
    this.materialFoodSectorConsumptionForm.get('isRework')?.reset();
    this.materialFoodSectorConsumptionForm.get('mTime')?.setValue(new Date());
    this.JobBatchMaterialId = '';
    this.isRework = false;
    this.getItemBomListInfo();
  }
  openWasteHistoryModal(): void {
    this.dialog.open(this.wastehistoryModal, {
      width: '100vw !important',
      height: 'auto',
      minWidth: '100vw',
    });
    this.GetJbWasteHistoryForHistory();
    //this.toaster.error('error', this.translater.instant('PoListSelection'));
  }
  getMinutes(event: any): void {
    const sDate = new Date(this.downTimeForm.get('startDate')?.value);
    const eDate = new Date(this.downTimeForm.get('endDate')?.value);
    const dateTimeDifference = eDate.getTime() - sDate.getTime();
    const days = dateTimeDifference / (1000 * 3600 * 24);
    const minutes = parseInt((days * 1440).toFixed(0), 10);
    this.downTimeForm.get('duration')?.setValue(minutes);
  }

  getEndMinutes(event: any): void {
    const sDate = this.downTimeForm.get('startDate')?.value;
    const eDate = this.downTimeForm.get('endDate')?.value;
    const dateTimeDifference = eDate.getTime() - sDate.getTime();
    const days = dateTimeDifference / (1000 * 3600 * 24);
    const minutes = parseInt((days * 1440).toFixed(0), 10);
    this.downTimeForm.get('duration')?.setValue(minutes);
  }

  getMinutes1(event: any): void {
    const sDate = this.downTimeForm.get('startDate')?.value;
    const eDate = this.downTimeForm.get('endDate')?.value;
    const dateTimeDifference = eDate.getTime() - sDate.getTime();
    const days = dateTimeDifference / (1000 * 3600 * 24);
    const minutes = (days * 1440).toFixed(0);
    this.downTimeForm.get('duration')?.setValue(minutes);
    this.shortStopsForm.get('duration')?.setValue(minutes);
  }

  getChangeStartTime(element: any): void {
    const min = this.shortStopsForm.get('duration')?.value;
    const today = new Date();
    const sttime = new Date(today.getTime() - min * 60000);
    this.shortStopsForm.get('startDate')?.setValue(sttime);
    this.shortStopsForm.get('endDate')?.setValue(today);
  }
  SaveJBDowntimeInfo(): void {
    const key = {
      TransactionTypeId: 1,
      PUID: this.machineId,
      JobdowtimeId: 0,
      FaultId: this.downTimeForm.get('fault')?.value,
      StartTime: this.downTimeForm.get('startDate')?.value,
      EndTime: this.downTimeForm.get('endDate')?.value,
      Remarks: this.downTimeForm.get('remarks')?.value,
      Duration: this.downTimeForm.get('duration')?.value,
      UserId: 0,
    };
    this.sfdService.SaveJBDowntimeInfo(key).subscribe(
      (res: any) => {
        this.GetJbDowntimeHistory();
        this.showPopupMsg(res.ErrorMessage);
        this.downTimeForm.get('remarks')?.reset();
      },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
    this.faultclose = false;
  }
  saveQcVerify(): void {
    const key = {
      startTimeSlot: this.qcStartTimeSlot,
      timeSlot: this.qcParamsSlot,
      endTimeSlot: this.qcEndTimeSlot,
      processOrderNo: this.qcProcessorderNo,
      verifcationStatus: this.verifcationStatus,
      unitId: this.unitId,
      createdBy: JSON.parse(localStorage.user).UserId,
      createdOn: new Date(),
      prodCode: this.qcProdCode,
      prodDesc: this.qcProcdDesc,
      detail: this.qcdataSource.data, // data
      groupType: this.qualityGroupType,
    };
    this.qualityParameterService.saveQcVerify(key).subscribe(
      (response: any) => {
        // this.toaster.show('Success', 'Verifcation Saved Successfully');
        this.showPopupMsg(response.data);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  verifyChnage(element: any, event: any): void {
    const obj = this.qcdataSource.data.find(
      (x: any) => x.parameterId === element.parameterId
    );
    obj.verificationStatus = event.target.value;
  }

  QcVerifyremarksUpdate(element: any, event: any): void {
    const obj = this.qcdataSource.data.find(
      (x: any) => x.parameterId === element.parameterId
    );
    obj.remarks = event.target.value;
  }
  onPrevious1(): void {
    if (this.disableSFDCbuttons == true) {
      this.toaster.error('Please Select Unit First');
    } else {
      // this.div1=!this.div1;
      //   this.div2=false;
      //   this.div3=false
      //   this.div4=false;
      //   this.div5=false;
      //   this.div6=false;
      const wrapper1 = document.querySelector('#wrapper1');
      wrapper1?.classList.remove('content-show');
      wrapper1?.classList.add('content-hide');
      const wrapper2 = document.querySelector('#wrapper2');
      wrapper2?.classList.add('content-show');
      wrapper2?.classList.remove('content-hide');
      const wrapper3 = document.querySelector('#wrapper3');
      wrapper3?.classList.remove('content-show');
      wrapper3?.classList.add('content-hide');
      const wrapper4 = document.querySelector('#wrapper4');
      wrapper4?.classList.remove('content-show');
      wrapper4?.classList.add('content-hide');
      const wrapper5 = document.querySelector('#wrapper5');
      wrapper5?.classList.remove('content-show');
      wrapper5?.classList.add('content-hide');
      const wrapper6 = document.querySelector('#wrapper6');
      wrapper6?.classList.remove('content-show');
      wrapper6?.classList.add('content-hide');
      const wrapper7 = document.querySelector('#wrapper7');
      wrapper7?.classList.remove('content-show');
      wrapper7?.classList.add('content-hide');
      const wrapper8 = document.querySelector('#wrapper8');
      wrapper8?.classList.remove('content-show');
      wrapper8?.classList.add('content-hide');
      const wrapper9 = document.querySelector('#wrapper9');
      wrapper9?.classList.remove('content-show');
      wrapper9?.classList.add('content-hide');
    }
  }
  onPrevious2(): void {
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.add('content-show');
    wrapper3?.classList.remove('content-hide');
    const wrapper4 = document.querySelector('#wrapper4');
    wrapper4?.classList.remove('content-show');
    wrapper4?.classList.add('content-hide');
    const wrapper5 = document.querySelector('#wrapper5');
    wrapper5?.classList.remove('content-show');
    wrapper5?.classList.add('content-hide');
    const wrapper6 = document.querySelector('#wrapper6');
    wrapper6?.classList.remove('content-show');
    wrapper6?.classList.add('content-hide');
    const wrapper7 = document.querySelector('#wrapper7');
    wrapper7?.classList.remove('content-show');
    wrapper7?.classList.add('content-hide');
    const wrapper8 = document.querySelector('#wrapper8');
    wrapper8?.classList.remove('content-show');
    wrapper8?.classList.add('content-hide');
    const wrapper9 = document.querySelector('#wrapper9');
    wrapper9?.classList.remove('content-show');
    wrapper9?.classList.add('content-hide');
  }
  onPrevious3(): void {
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    const wrapper4 = document.querySelector('#wrapper4');
    wrapper4?.classList.add('content-show');
    wrapper4?.classList.remove('content-hide');
    const wrapper5 = document.querySelector('#wrapper5');
    wrapper5?.classList.remove('content-show');
    wrapper5?.classList.add('content-hide');
    const wrapper6 = document.querySelector('#wrapper6');
    wrapper6?.classList.remove('content-show');
    wrapper6?.classList.add('content-hide');
    const wrapper7 = document.querySelector('#wrapper7');
    wrapper7?.classList.remove('content-show');
    wrapper7?.classList.add('content-hide');
    const wrapper8 = document.querySelector('#wrapper8');
    wrapper8?.classList.remove('content-show');
    wrapper8?.classList.add('content-hide');
    const wrapper9 = document.querySelector('#wrapper9');
    wrapper9?.classList.remove('content-show');
    wrapper9?.classList.add('content-hide');
  }
  onPrevious4(): void {
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    const wrapper4 = document.querySelector('#wrapper4');
    wrapper4?.classList.remove('content-show');
    wrapper4?.classList.add('content-hide');
    const wrapper5 = document.querySelector('#wrapper5');
    wrapper5?.classList.add('content-show');
    wrapper5?.classList.remove('content-hide');
    const wrapper6 = document.querySelector('#wrapper6');
    wrapper6?.classList.remove('content-show');
    wrapper6?.classList.add('content-hide');
    const wrapper7 = document.querySelector('#wrapper7');
    wrapper7?.classList.remove('content-show');
    wrapper7?.classList.add('content-hide');
    const wrapper8 = document.querySelector('#wrapper8');
    wrapper8?.classList.remove('content-show');
    wrapper8?.classList.add('content-hide');
    const wrapper9 = document.querySelector('#wrapper9');
    wrapper9?.classList.remove('content-show');
    wrapper9?.classList.add('content-hide');
  }
  onPrevious5(): void {
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    const wrapper4 = document.querySelector('#wrapper4');
    wrapper4?.classList.remove('content-show');
    wrapper4?.classList.add('content-hide');
    const wrapper5 = document.querySelector('#wrapper5');
    wrapper5?.classList.remove('content-show');
    wrapper5?.classList.add('content-hide');
    const wrapper6 = document.querySelector('#wrapper6');
    wrapper6?.classList.add('content-show');
    wrapper6?.classList.remove('content-hide');
    const wrapper7 = document.querySelector('#wrapper7');
    wrapper7?.classList.remove('content-show');
    wrapper7?.classList.add('content-hide');
    const wrapper8 = document.querySelector('#wrapper8');
    wrapper8?.classList.remove('content-show');
    wrapper8?.classList.add('content-hide');
    const wrapper9 = document.querySelector('#wrapper9');
    wrapper9?.classList.remove('content-show');
    wrapper9?.classList.add('content-hide');
  }
  onPrevious6(): void {
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    const wrapper4 = document.querySelector('#wrapper4');
    wrapper4?.classList.remove('content-show');
    wrapper4?.classList.add('content-hide');
    const wrapper5 = document.querySelector('#wrapper5');
    wrapper5?.classList.remove('content-show');
    wrapper5?.classList.add('content-hide');
    const wrapper6 = document.querySelector('#wrapper6');
    wrapper6?.classList.remove('content-show');
    wrapper6?.classList.add('content-hide');
    const wrapper7 = document.querySelector('#wrapper7');
    wrapper7?.classList.add('content-show');
    wrapper7?.classList.remove('content-hide');
    const wrapper8 = document.querySelector('#wrapper8');
    wrapper8?.classList.remove('content-show');
    wrapper8?.classList.add('content-hide');
    const wrapper9 = document.querySelector('#wrapper9');
    wrapper9?.classList.remove('content-show');
    wrapper9?.classList.add('content-hide');
  }
  onPrevious7(): void {
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    const wrapper4 = document.querySelector('#wrapper4');
    wrapper4?.classList.remove('content-show');
    wrapper4?.classList.add('content-hide');
    const wrapper5 = document.querySelector('#wrapper5');
    wrapper5?.classList.remove('content-show');
    wrapper5?.classList.add('content-hide');
    const wrapper6 = document.querySelector('#wrapper6');
    wrapper6?.classList.remove('content-show');
    wrapper6?.classList.add('content-hide');
    const wrapper7 = document.querySelector('#wrapper7');
    wrapper7?.classList.remove('content-show');
    wrapper7?.classList.add('content-hide');
    const wrapper8 = document.querySelector('#wrapper8');
    wrapper8?.classList.add('content-show');
    wrapper8?.classList.remove('content-hide');
    const wrapper9 = document.querySelector('#wrapper9');
    wrapper9?.classList.remove('content-show');
    wrapper9?.classList.add('content-hide');
  }
  onPrevious8(): void {
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.add('content-show');
    wrapper1?.classList.remove('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    const wrapper4 = document.querySelector('#wrapper4');
    wrapper4?.classList.remove('content-show');
    wrapper4?.classList.add('content-hide');
    const wrapper5 = document.querySelector('#wrapper5');
    wrapper5?.classList.remove('content-show');
    wrapper5?.classList.add('content-hide');
    const wrapper6 = document.querySelector('#wrapper6');
    wrapper6?.classList.remove('content-show');
    wrapper6?.classList.add('content-hide');
    const wrapper7 = document.querySelector('#wrapper7');
    wrapper7?.classList.remove('content-show');
    wrapper7?.classList.add('content-hide');
    const wrapper8 = document.querySelector('#wrapper8');
    wrapper8?.classList.remove('content-show');
    wrapper8?.classList.add('content-hide');
    const wrapper9 = document.querySelector('#wrapper9');
    wrapper9?.classList.remove('content-show');
    wrapper9?.classList.add('content-hide');
  }
  onPrevious9(): void {
    const wrapper1 = document.querySelector('#wrapper1');
    wrapper1?.classList.remove('content-show');
    wrapper1?.classList.add('content-hide');
    const wrapper2 = document.querySelector('#wrapper2');
    wrapper2?.classList.remove('content-show');
    wrapper2?.classList.add('content-hide');
    const wrapper3 = document.querySelector('#wrapper3');
    wrapper3?.classList.remove('content-show');
    wrapper3?.classList.add('content-hide');
    const wrapper4 = document.querySelector('#wrapper4');
    wrapper4?.classList.remove('content-show');
    wrapper4?.classList.add('content-hide');
    const wrapper5 = document.querySelector('#wrapper5');
    wrapper5?.classList.remove('content-show');
    wrapper5?.classList.add('content-hide');
    const wrapper6 = document.querySelector('#wrapper6');
    wrapper6?.classList.remove('content-show');
    wrapper6?.classList.add('content-hide');
    const wrapper7 = document.querySelector('#wrapper7');
    wrapper7?.classList.remove('content-show');
    wrapper7?.classList.add('content-hide');
    const wrapper8 = document.querySelector('#wrapper8');
    wrapper8?.classList.remove('content-show');
    wrapper8?.classList.add('content-hide');
    const wrapper9 = document.querySelector('#wrapper9');
    wrapper9?.classList.add('content-show');
    wrapper9?.classList.remove('content-hide');
  }

  isReworkWork(event: any) {
    this.isRework = event.checked;
    this.RefreshMaterialSubConsumptionOnIsreworkChecked();
  }
  RefreshMaterialSubConsumptionOnIsreworkChecked(): void {
    const key = {
      IsRework: this.isRework,
      PPDId: this.PPID,
    };
    this.sfdService.getItemBomListInfo(key).subscribe(
      (res: any) => {
        this.ItemBOMList = res.ItemBOMList;
        this.materialFoodSectorConsumptionForm
          .get('mActQtys')
          ?.setValue(res.Qty);
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  public checkErrorMaterialFoodConsumption = (
    controlName: string,
    errorName: string
  ) => {
    return this.materialFoodSectorConsumptionForm.controls[
      controlName
    ].hasError(errorName);
  };

  generateColumnsQualityControl(data: any[]): any {
    this.columnDefsQualityControl = [];
    let columnDefinitions: any[] = [];
    data.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key === 'Cavity' ? 'Sample' : key, // .toUpperCase()
          headerClass: {},
          headerTooltip: key,
          tooltipComponent: 'customTooltip',
          tooltipValueGetter,
          field: key,
          toolTip: key,
          resizable: true,
          wrapText: true,
          rowGroup: key === 'ParamaterGroup' ? true : false,
          width:
            key === 'Cavity' || key === 'DataType' || key === 'ParamaterGroup'
              ? 150
              : key === 'Parameter'
                ? 300
                : 200,
          cellStyle: {},
          hide:
            key === 'ParamaterGroup' || key === 'Cavity' || key === 'DataType'
              ? true
              : false,
          filter: true,
          sortable: true,
          editable: false,
          valueParser: {},
          valueGetter:
            key !== 'Parameter' &&
              key !== 'ParamaterGroup' &&
              key !== 'Cavity' &&
              key !== 'DataType' &&
              key !== 'parameterId'
              ? this.abValueGetter
              : null,
          pinned:
            key === 'Parameter' ||
              key === 'Cavity' ||
              key === 'DataType' ||
              key === 'ParamaterGroup'
              ? 'left'
              : '',
          lockPinned:
            key === 'Parameter' ||
              key === 'Cavity' ||
              key === 'DataType' ||
              key === 'ParamaterGroup'
              ? true
              : false,
          rowSelection: 'multiple',
          onCellClicked: (params: any) => {
            if (params.data.DataType === 'Bool') {
              params.colDef.cellEditor = 'agRichSelectCellEditor';
              this.GetAllDataTypesDetail(params.data.parameterId);
              this.selectionName = params.data.Parameter;
              this.isProcessGrid = false;
              if (mappedColumn.editable) {
                this.dialog.open(this.qualityBoolListModal, {
                  height: 'auto',
                  width: '45%',
                });
              }
              this.boolColId = params.column.colId;
              this.boolRowIndex = params.rowIndex;
            } else if (params.data.DataType === 'Date') {
              params.colDef.cellEditor = 'primeCellEditor';
              this.dateArrCell = params.data;
              //this.GetAllDataTypesDetail(params.data.parameterId);
              //this.isProcessGrid = true;
              //this.selectionName = params.data.Parameter;
              if (mappedColumn.editable) {
                this.dialog.open(this.qualityDateListModal, {
                  height: 'auto',
                  width: '45%',
                });
              }
            } else {
              params.colDef.cellEditor = 'agTextCellEditor';
            }
          },
          cellEditor: null,
          // tslint:disable-next-line:max-line-length
          cellEditorParams:
            key !== 'Parameter' &&
              key !== 'ParamaterGroup' &&
              key !== 'Cavity' &&
              key !== 'DataType'
              ? (params: any): Promise<any> => {
                return params.value;
              }
              : null,
        };
        if (this.qualityGroupType === 'Time') {
          mappedColumn.cellStyle = (params: any): any => {
            if (
              key !== 'Parameter' &&
              key !== 'ParamaterGroup' &&
              key !== 'Cavity' &&
              key !== 'DataType' &&
              key !== 'parameterId'
            ) {
              const fromDate = new Date(key.substring(0, 20));
              const toDate = new Date(key.substring(23, 43));
              // tslint:disable-next-line:max-line-length
              if (
                date.getTime() >= fromDate.getTime() &&
                date.getTime() <= toDate.getTime() + 1 * 60000 &&
                key.split('~poStatus')[1] === 'InProcess'
              ) {
                return {
                  'font-weight': 'bold',
                  color: params.value
                    ? params.data[params.colDef.field].split('|')[1]
                    : 'black',
                  'background-color': '#EDF1E5',
                };
              } else {
                if (params.value) {
                  return {
                    color: params.data[params.colDef.field].split('|')[1],
                  };
                }
              }
            }
          };
          const date = new Date(new Date().setSeconds(0));
          if (
            key !== 'Parameter' &&
            key !== 'ParamaterGroup' &&
            key !== 'Cavity' &&
            key !== 'DataType' &&
            key !== 'parameterId'
          ) {
            const fromDate = new Date(key.substring(0, 20));
            const toDate = new Date(key.substring(23, 43));
            if (
              date.getTime() >= fromDate.getTime() &&
              date.getTime() <= toDate.getTime() + 1 * 60000 &&
              key.split('~poStatus')[1] === 'InProcess'
            ) {
              mappedColumn.editable = true;
              mappedColumn.lockPinned = true;
              mappedColumn.pinned = 'left';
              mappedColumn.valueParser = (params: any) => {
                if (this.IsWrite) {
                  const dataType = params.data.DataType;
                  if (
                    dataType === 'Int' ||
                    dataType === 'Float' ||
                    dataType === 'String'
                  ) {
                    const dataType = params.data.DataType;
                    let newVal = params.newValue;
                    if (dataType === 'Int') {
                      const charcter = Number.isInteger(Number(newVal));
                      if (!charcter) {
                        this.toaster.warning(
                          'DigitsOnly',
                          'Please Select Int Value Only'
                        );
                        params.node.setDataValue(
                          params.colDef.field,
                          params.oldValue
                        );
                        return;
                      } else {
                        params.newValue = parseInt(params.newValue, 10);
                      }
                    }
                    if (dataType === 'Float') {
                      const float = parseFloat(newVal);
                      if (isNaN(float)) {
                        this.toaster.warning(
                          'Decimal Only',
                          'Please Select Decimal values Only'
                        );
                        params.node.setDataValue(
                          params.colDef.field,
                          params.oldValue
                        );
                        return;
                      } else {
                        params.newValue = parseFloat(params.newValue).toFixed(
                          2
                        );
                      }
                    }
                    if (dataType === 'Date') {
                      const date = params.newValue;
                      if (date.match(new RegExp('/', 'g')).length === 2) {
                      } else {
                        this.toaster.warning(
                          'SlashError',
                          'Please Select Correct Date Format'
                        );
                        return;
                      }
                    }
                    const startIntervalDate = params.colDef.field.substring(
                      0,
                      20
                    );
                    const endIntervalDate = params.colDef.field.substring(
                      22,
                      43
                    );
                    if (new Date() > new Date(endIntervalDate)) {
                      this.toaster.warning(
                        'Time Passed',
                        'Slot time has been passed'
                      );
                      return;
                    }
                    let cellData: any;
                    if (!params.oldValue || params.oldValue === '') {
                      cellData = {
                        result: params.newValue,
                        entryOn: new Date(),
                        resultOn: endIntervalDate,
                        endInterval: endIntervalDate,
                        startInterval: startIntervalDate,
                        varId: params.data.parameterId,
                        isOldValue: false,
                        cavity: params.data.Cavity,
                        po: params.column.colId.split('~')[1],
                        createdBy: JSON.parse(localStorage.user).UserId,
                        prodId:
                          params.column.colId.split('~prodId')[1] !== undefined
                            ? params.column.colId
                              .split('~prodId')[1]
                              .split('~')[0]
                            : '',
                        ppId:
                          params.column.colId.split('~ppId')[1] !== undefined
                            ? params.column.colId
                              .split('~ppId')[1]
                              .split('~')[0]
                            : '',
                        uomId:
                          params.column.colId.split('~uomId')[1] !== undefined
                            ? params.column.colId
                              .split('~uomId')[1]
                              .split('~')[0]
                            : '',
                        ppdId:
                          params.column.colId.split('~ppdId')[1] !== undefined
                            ? params.column.colId
                              .split('~ppdId')[1]
                              .split('~')[0]
                            : '',
                        intervalType: this.qualityGroupType,
                      };
                    } else {
                      cellData = {
                        result: params.newValue,
                        entryOn: new Date(),
                        resultOn: endIntervalDate,
                        varId: params.data.parameterId,
                        endInterval: endIntervalDate,
                        startInterval: startIntervalDate,
                        isOldValue: true,
                        cavity: params.data.Cavity,
                        po: params.column.colId.split('~')[1],
                        createdBy: JSON.parse(localStorage.user).UserId,
                        prodId:
                          params.column.colId.split('~prodId')[1] !== undefined
                            ? params.column.colId
                              .split('~prodId')[1]
                              .split('~')[0]
                            : '',
                        ppId:
                          params.column.colId.split('~ppId')[1] !== undefined
                            ? params.column.colId
                              .split('~ppId')[1]
                              .split('~')[0]
                            : '',
                        uomId:
                          params.column.colId.split('~uomId')[1] !== undefined
                            ? params.column.colId
                              .split('~uomId')[1]
                              .split('~')[0]
                            : '',
                        ppdId:
                          params.column.colId.split('~ppdId')[1] !== undefined
                            ? params.column.colId
                              .split('~ppdId')[1]
                              .split('~')[0]
                            : '',
                        intervalType: this.qualityGroupType,
                      };
                    }
                    this.processService
                      .saveQualityProcessControl(cellData)
                      .subscribe(
                        (response: any) => {
                          const msg = response[0].result;
                          if (msg !== null || msg !== '') {
                            if (msg.substring(2, 1) === '0') {
                              this.toaster.success(
                                'Success',
                                msg.substring(3, msg.length)
                              );
                              params.node.setDataValue(
                                params.colDef.field,
                                response[0].value
                              );
                              if (response[0].value) {
                                const color = response[0].value.split('|')[1];
                                if (color === '#ff2121') {
                                  this.sendNotificationRole(
                                    `NCR ID - ${response[0].ncrId}
                                     Out of Specification Limits! The Defect had happened against ${params.column.colId.split('~')[1]
                                    }`,
                                    'Quality Control'
                                  );
                                }
                                if (color === '#f49203') {
                                  this.sendNotificationRole(
                                    `NCR ID - ${response[0].ncrId}
                                     Watch Out! There is a risk of defect happening against ${params.column.colId.split('~')[1]
                                    }`,
                                    'Quality Control'
                                  );
                                }
                              }
                            } else {
                              this.toaster.error(
                                'Error',
                                msg.substring(3, msg.length)
                              );
                              params.node.setDataValue(
                                params.colDef.field,
                                response[0].value
                              );
                            }
                          }
                        },
                        (error: any) => {
                          return this.toaster.error('Error', error.message);
                        }
                      );
                  }
                }
              };
            }
          }
          if (
            key !== 'Parameter' &&
            key !== 'ParamaterGroup' &&
            key !== 'parameterId' &&
            key !== 'Cavity' &&
            key !== 'DataType'
          ) {
            const fromDate = new Date(key.substring(0, 20));
            const toDate = new Date(key.substring(23, 43));
            mappedColumn.headerName =
              moment(fromDate).format('HH:mm:ss') +
              ' - ' +
              moment(toDate).format('HH:mm:ss') +
              ' - ' +
              key.split('~')[1] +
              ' - ' +
              key.split('~poStatus')[1];
            mappedColumn.headerTooltip = 'PO: ' + key.split('~')[1];
          }
        }
        if (
          this.qualityGroupType === 'Event' ||
          this.qualityGroupType === 'Manual'
        ) {
          if (
            key !== 'Parameter' &&
            key !== 'ParamaterGroup' &&
            key !== 'parameterId' &&
            key !== 'Cavity' &&
            key !== 'DataType'
          ) {
            if (this.qualityGroupType === 'Event') {
              const fromDate = new Date(key.substring(0, 20));
              mappedColumn.headerName =
                moment(fromDate).format('YYYY-MM-DD HH:mm:ss') +
                ' - ' +
                `PO  :  ${key.split('~')[1]} LotCode:  ${key.split('~lotCode')[1].split('~')[0]
                }`;
              mappedColumn.headerTooltip = `PO  :  ${key.split('~')[1]
                } LotCode:  ${key.split('~lotCode')[1].split('~')[0]}`;
            } else {
              const fromDate = new Date(key.substring(0, 20));
              mappedColumn.headerName =
                moment(fromDate).format('YYYY-MM-DD HH:mm:ss') +
                ' - ' +
                key.split('~')[1];
              mappedColumn.headerTooltip = `PO  :  ${key.split('~')[1]}`;
            }
            mappedColumn.editable = true;
          }
          mappedColumn.cellStyle = (params: any): any => {
            if (
              key !== 'Parameter' &&
              key !== 'ParamaterGroup' &&
              key !== 'Cavity' &&
              key !== 'DataType' &&
              key !== 'parameterId'
            ) {
              return {
                'font-weight': 'bold',
                color: params.value
                  ? params.data[params.colDef.field].split('|')[1]
                  : 'black',
                'background-color': '#EDF1E5',
              };
            }
          };
          mappedColumn.editable = true;
          mappedColumn.lockPinned = true;
          mappedColumn.pinned = 'left';
          mappedColumn.valueParser = (params: any) => {
            if (this.IsWrite) {
              const dataType = params.data.DataType;
              if (
                dataType === 'Int' ||
                dataType === 'Float' ||
                dataType === 'String'
              ) {
                const dataType = params.data.DataType;
                let newVal = params.newValue;
                if (dataType === 'Int') {
                  const charcter = Number.isInteger(Number(newVal));
                  if (!charcter) {
                    this.toaster.warning(
                      'DigitsOnly',
                      'Please Select Int Value Only'
                    );
                    params.node.setDataValue(
                      params.colDef.field,
                      params.oldValue
                    );
                    return;
                  } else {
                    params.newValue = parseInt(params.newValue, 10);
                  }
                }
                if (dataType === 'Float') {
                  const float = parseFloat(newVal);
                  if (isNaN(float)) {
                    this.toaster.warning(
                      'FloatOnly',
                      'Please Select Float Only'
                    );
                    params.node.setDataValue(
                      params.colDef.field,
                      params.oldValue
                    );
                    return;
                  } else {
                    params.newValue = parseFloat(params.newValue).toFixed(2);
                  }
                }
                if (dataType === 'Date') {
                  const date = params.newValue;
                  if (date.match(new RegExp('/', 'g')).length === 2) {
                  } else {
                    this.toaster.warning(
                      'SlashError',
                      'Please Select Correct Date Format'
                    );
                    return;
                  }
                }
                const startIntervalDate = params.colDef.field.substring(0, 20);
                const endIntervalDate = params.colDef.field.substring(23, 43);
                if (new Date() > new Date(endIntervalDate)) {
                  this.toaster.warning(
                    'Time Passed',
                    'Slot time has been passed'
                  );
                  return;
                }
                let cellData: any;
                if (!params.oldValue || params.oldValue === '') {
                  cellData = {
                    result: params.newValue,
                    entryOn: new Date(),
                    resultOn: startIntervalDate,
                    endInterval: startIntervalDate,
                    startInterval: startIntervalDate,
                    varId: params.data.parameterId,
                    isOldValue: false,
                    cavity: params.data.Cavity,
                    po: params.column.colId.split('~')[1],
                    eventId: params.column.colId
                      .split('~EventId')[1]
                      .split('~')[0],
                    createdBy: JSON.parse(localStorage.user).UserId,
                    intervalType: this.qualityGroupType,
                    prodId:
                      params.column.colId.split('~prodId')[1] !== undefined
                        ? params.column.colId.split('~prodId')[1].split('~')[0]
                        : '',
                    ppId:
                      params.column.colId.split('~ppId')[1] !== undefined
                        ? params.column.colId.split('~ppId')[1].split('~')[0]
                        : '',
                    uomId:
                      params.column.colId.split('~uomId')[1] !== undefined
                        ? params.column.colId.split('~uomId')[1].split('~')[0]
                        : '',
                    ppdId:
                      params.column.colId.split('~ppdId')[1] !== undefined
                        ? params.column.colId.split('~ppdId')[1].split('~')[0]
                        : '',
                  };
                } else {
                  cellData = {
                    result: params.newValue,
                    entryOn: new Date(),
                    resultOn: startIntervalDate,
                    varId: params.data.parameterId,
                    endInterval: startIntervalDate,
                    startInterval: startIntervalDate,
                    isOldValue: true,
                    cavity: params.data.Cavity,
                    po: params.column.colId.split('~')[1],
                    eventId: params.column.colId
                      .split('~EventId')[1]
                      .split('~')[0],
                    createdBy: JSON.parse(localStorage.user).UserId,
                    intervalType: this.qualityGroupType,
                    prodId:
                      params.column.colId.split('~prodId')[1] !== undefined
                        ? params.column.colId.split('~prodId')[1].split('~')[0]
                        : '',
                    ppId:
                      params.column.colId.split('~ppId')[1] !== undefined
                        ? params.column.colId.split('~ppId')[1].split('~')[0]
                        : '',
                    uomId:
                      params.column.colId.split('~uomId')[1] !== undefined
                        ? params.column.colId.split('~uomId')[1].split('~')[0]
                        : '',
                    ppdId:
                      params.column.colId.split('~ppdId')[1] !== undefined
                        ? params.column.colId.split('~ppdId')[1].split('~')[0]
                        : '',
                  };
                }
                this.processService
                  .saveQualityProcessControl(cellData)
                  .subscribe(
                    (response: any) => {
                      const msg = response[0].result;
                      if (msg !== null || msg !== '') {
                        if (msg.substring(2, 1) === '0') {
                          this.toaster.success(
                            'Success',
                            msg.substring(3, msg.length)
                          );
                          params.node.setDataValue(
                            params.colDef.field,
                            response[0].value
                          );
                          if (response[0].value) {
                            const color = response[0].value.split('|')[1];
                            if (color === '#ff2121') {
                              this.sendNotificationRole(
                                `NCR ID - ${response[0].ncrId}
                                 Out of Specification Limits! The Defect had happened against ${params.column.colId.split('~')[1]
                                }`,
                                'Quality Control'
                              );
                            }
                            if (color === '#f49203') {
                              this.sendNotificationRole(
                                `NCR ID - ${response[0].ncrId}
                                 Watch Out! There is a risk of defect happening against ${params.column.colId.split('~')[1]
                                }`,
                                'Quality Control'
                              );
                            }
                          }
                        } else {
                          this.toaster.error(
                            'Error',
                            msg.substring(3, msg.length)
                          );
                          params.node.setDataValue(
                            params.colDef.field,
                            response[0].value
                          );
                        }
                      }
                    },
                    (error: any) => {
                      return this.toaster.error('Error', error.message);
                    }
                  );
              }
            }
          };
        }
        if (key === 'parameterId') {
          mappedColumn.hide = true;
        }
        columnDefinitions.push(mappedColumn);
      });
    });

    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    this.rowSelection = 'multiple';
    return columnDefinitions;
  }

  showDropDown = false;
  onClick() {
    this.showDropDown = true;
  }

  storingMachine = [];

  openTreeModal(): void {
    this.updateOrderprocessingData = true;
    const key = {
      EGId: this.groupDataForm.value.group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.isPlantGroupModelVisible = true;
        this.expandAllNode();
        this.storingMachine = data.TreeModalList;
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  GetWasteFaultByUnit(): void {
    const key = {
      PUId: this.machineId,
      UserId: 0,
    };
    this.sfdService.GetWasteFaultByUnit(key).subscribe((res: any) => {
      this.FaultList = res.FaultList;
      this.wasteForm.get('categoryType')?.setValue(this.FaultList[0]?.FaultId);
    });
  }
  GetIsShiftStart(): void {
    const key = {
      PUID: this.machineId,
    };
    this.sfdService.GetIsShiftStart(key).subscribe((res: any) => {
      this.crewId = res.CrewId;
      if (this.crewId === 0) {
        this.showShiftClose = false;
      } else {
        this.showShiftClose = true;
      }
      this.GetShiftCloseValidation();
    });
  }
  shiftstart: any;
  shiftend: any;
  GetCrewScheduleList(): void {
    const key = {
      PUID: this.machineId,
      Date: moment(new Date()), //.format('YYYY-MM-DD'),
    };
    this.crewService.GetCrewScheduleList(key).then((res: any) => {
      this.crewScheduleList = res.CrewScheduleList;
      if (this.crewScheduleList.length) {
        const date = new Date();
        // tslint:disable-next-line:max-line-length
        const record = this.crewScheduleList.filter(
          (c: any) =>
            date.getTime() >= new Date(c.ShiftStart).getTime() &&
            date.getTime() <= new Date(c.ShiftEnd).getTime()
        );
        this.shiftstart = new Date(record[0].ShiftStart);
        this.shiftend = new Date();
        this.s = this.shiftstart.getTime();
        this.Chart();
        // const minutes = parseInt(Math.abs(record[0].ShiftEnd.getTime() - record[0]. ShiftStart.getTime() / (1000 * 60) % 60);
        const from = record[0].ShiftStart;
        const To = record[0].ShiftEnd;
        const timeDiff = moment(new Date(To)).diff(
          moment(new Date(from)),
          'minutes'
        );

        this.timedifference = timeDiff;
        this.GetJbDowntimeHistory1();
        this.processControlForm
          .get('datePicker')
          ?.setValue([this.shiftstart, this.shiftend], { emitEvent: false });
        this.qualityControlForm
          .get('datePicker')
          ?.setValue([this.shiftstart, this.shiftend], { emitEvent: false });
        this.GetGroupTypeList();
        this.getOeeDashboard();
        this.GetQualityControlGroupTypeList();
      } else {
        const start = new Date();
        this.processControlForm
          .get('datePicker')
          ?.setValue(
            [new Date(start.setHours(0, 0, 0, 0)).toISOString(), new Date()],
            { emitEvent: false }
          );
        this.qualityControlForm
          .get('datePicker')
          ?.setValue(
            [new Date(start.setHours(0, 0, 0, 0)).toISOString(), new Date()],
            { emitEvent: false }
          );
        this.s = (new Date(start.setHours(0, 0, 0, 0))).getTime();
        this.Chart();
        this.GetJbDowntimeHistory1();
        this.GetGroupTypeList();
        this.GetQualityControlGroupTypeList();
      }
    });
  }
  abValueGetter(params: any): any {
    if (params.data) {
      return params.data[params.colDef.field]
        ? params.data[params.colDef.field].split('|')[0]
        : '';
    }
  }
  onIntervalChange(): void { }
  onToProcessDateQuanlityControl(): any {
    const getdata = {
      fromDate: this.qualityControlForm.value.datePicker[0],
      toDate: this.qualityControlForm.value.datePicker[1],
      puId: this.unitId,
      cavity: this.maxCavity,
      processType: 'PO',
      interval: this.qualityControlForm.value.timeInterval,
      currentDateTime: new Date(),
      groupType: this.qualityGroupType,
      intervalType:
        this.qualityGroupType === 'Time' || this.qualityGroupType === 'Manual'
          ? this.qualityGroupType
          : this.qualityControlForm.value.eventType,
    };
    if (
      getdata.puId === undefined ||
      !getdata.fromDate ||
      !getdata.toDate ||
      this.UnitName === ''
    ) {
      this.toaster.warning(this.translater.instant('SelectDatesPlants'));
      return;
    }
    const from = this.qualityControlForm.value.datePicker[0];
    const To = this.qualityControlForm.value.datePicker[1];
    const timeDiff = moment(To).diff(moment(from), 'hours');

    // if (timeDiff > 24) {
    //   this.toaster.error(
    //     this.translater.instant('Error'),
    //     this.translater.instant('DateRangeSelect')
    //   );
    //   return;
    // }

    this.qualityGridApi.showLoadingOverlay();
    this.processService.getQualityProcessControl(getdata).then(
      (response: any) => {
        this.gridOptionsQualityControl.api.hideOverlay();
        this.rowDataQualityControl = response;
        if (this.rowDataQualityControl) {
          this.columnDefsQualityControl = this.generateColumnsQualityControl(
            this.rowDataQualityControl
          );
          setTimeout(() => {
            this.gridOptionsQualityControl.api.columnController.resetColumnState();
            const scroll = document.querySelector(
              '#gridContainerQualityControl  .ag-body-horizontal-scroll-viewport'
            );
            if (scroll !== null) {
              scroll.scrollLeft = scroll.scrollWidth;
            }
          }, 100);
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }
  async GetAllDataTypesDetail(val: any): Promise<any> {
    const key = {
      variableId: val,
    };
    await this.machineParamtersService
      .GetAllDataTypesDetail(key)
      .then((res: any) => {
        this.boolList = res.map((c: any) => c.text);
      });
  }
  // ------- Context Menu Process Control
  processControlSpecification(params: any): void {
    this.processControlSpecificationModal = true;
    // this.dialog.open(this.processControlSpecificationModal, {
    //   height: '70%',
    //   width: '90%',
    // });
    const podata = params.column.colId.split('~')[1];
    this.paramterSpcecificationName = params.node.data.Parameter;
    const key = {
      processOrder: podata,
      paramterId: params.node.data.parameterId,
      prodId:
        params.column.colId.split('~prodId')[1] !== undefined
          ? params.column.colId.split('~prodId')[1].split('~')[0]
          : '',
    };
    this.getParameterSpecificationsProcessControl(key);
  }
  getParameterSpecificationsProcessControl(key: any): void {
    this.processService.processparameterspecs(key).subscribe(
      (response: any) => {
        this.parameterspecifications.data = response;
        // this.chartSpecifications = response;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onClickControlProcessOrderQualityControl(): any {
    const key = {
      fromDate: this.qualityControlForm.value.datePicker[0],
      toDate: this.qualityControlForm.value.datePicker[1],
      puId: this.unitId,
      cavity: this.maxCavity,
      processType: this.qualityControlForm.value.processType,
    };
    this.spinnerButtonOptionsOrders.active = true;
    this.processService
      .getQualityControlProcessOrder(key)
      .subscribe((response: any) => {
        this.dialog.open(this.displayQualityOrdersModal, {
          height: '90%',
          width: '90%',
        });
        this.qualityProcessOrder.data = response;
        // this.getPaginator();
      });
    this.spinnerButtonOptionsOrders.active = false;
  }
  onClickParamterComparsionQualityControl(): void {
    const selectedRows = this.qualityGridApi
      .getSelectedRows()
      .filter((c: any) => c.DataType === 'Int' || c.DataType === 'Float');
    this.spinnerButtonOptions1.active = true;
    // tslint:disable-next-line:max-line-length
    this.dialog.open(this.visibleParameterComparison, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '95vw',
      minWidth: '95vw',
      maxHeight: '90vh',
    });

    const array: any = [];
    const yAxisArr: any[] = [];
    const values = selectedRows
      .map((value: any) => value.parameterId)
      .toString();
    const valueNames = selectedRows.map((value: any) => value.Parameter);
    const seletedGroups = selectedRows.map(
      (value: any) => value.ParamaterGroup
    );
    const uniqueGroups = [...new Set(seletedGroups)];
    const dataArrName: any[] = [];
    const key = {
      parameterId: values,
      groupType: this.qualityGroupType,
    };
    this.spinner.show();
    this.processService.getQualityProcessParameterComparison(key).then(
      (data: any) => {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < valueNames.length; i++) {
          const dataArr = [];
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < data.length; j++) {
            // const arr = [];
            if (valueNames[i] === data[j].name) {
              // arr.push(new Date(data[j].processTime).getTime());
              // arr.push(parseFloat(data[j].result));
              // dataArr.push(arr);
              dataArr.push({
                x: new Date(data[j].processTime).getTime(),
                y: isNaN(data[j].result) ? '' : parseFloat(data[j].result),
                label: data[j].product,
              });
            }
          }
          let negativeArr = [];
          negativeArr = dataArr.map((c) => c.y);
          const obj = {
            tooltip: {
              valueDecimals: 2,
            },
            name: valueNames[i],
            type: 'line',
            data: dataArr,
            color: this.highChartsColor[i],
            yAxis: i,
          };
          array.push(obj);
          yAxisArr.push({
            lineWidth: 1,
            opposite: negativeArr.some((v) => v < 0) ? true : false,
            title: {
              text: valueNames[i],
              style: {
                color: this.highChartsColor[i],
              },
            },
            labels: {
              style: {
                color: this.highChartsColor[i],
              },
            },
          });
        }
        this.chart = new StockChart({
          chart: {
            height: 730,
          },
          plotOptions: {
            series: {
              turboThreshold: 50000000,
              events: {
                hide(): any {
                  // this.yAxis.axisTitle.hide();
                  this.yAxis.update({
                    visible: false,
                  });
                },
                show(): any {
                  this.yAxis.update({
                    visible: true,
                  });
                },
              },
            },
          },
          rangeSelector: {
            selected: 1,
          },
          title: {
            text: uniqueGroups.toString(),
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: true,
          },
          tooltip: {
            formatter(): any {
              // let s = '<b>' + StockChart.dateFormat('%A, %b %e, %Y', this.x) + '</b>';
              let s = '';
              this.points?.forEach((point: any) => {
                s +=
                  '<br/> <span style="color: ' +
                  point.series.color +
                  '">' +
                  point.series.name +
                  ' : ' +
                  point.y +
                  '<span><br/>' +
                  '<br/><span style="color: ' +
                  point.series.color +
                  '">Product : ' +
                  point.point.label +
                  '<span><br/>';
              });
              return s;
            },
          },
          yAxis: yAxisArr,
          series: array,
        });
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
    this.spinnerButtonOptions1.active = false;
  }
  onClickParamterAnalysisQualityControl(): any {
    // const checkrow = this.gridApi.getSelectedRows().map((value: any) => value.DataType).includes('String');
    // if (checkrow) {
    //   this.toaster.warning('warning', 'Please select Int & Bool rows for Paramater Analysis');
    //   return;
    // }
    const selectedRows = this.qualityGridApi
      .getSelectedRows()
      .filter(
        (c: any) =>
          c.DataType === 'Int' ||
          c.DataType === 'Bool' ||
          c.DataType === 'Float'
      );
    this.spinnerButtonOptions.active = true;
    this.chartArray = [];
    // tslint:disable-next-line:max-line-length
    this.dialog.open(this.visibleYearlyTargetTbl, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '95vh',
      minWidth: '95vw',
      maxHeight: '80vh !important',
    });
    selectedRows.forEach(async (element: any) => {
      const parameterName = element.Parameter + ' (' + element.Cavity + ')';
      const key = {
        parameterId: element.parameterId,
        cavity: element.Cavity,
        groupType: this.qualityGroupType,
      };
      const chartObj = { id: '', chartOption: {} };
      chartObj.id = element.parameterId + '_' + element.Cavity;
      await this.processService.getQualityProcessParameterAnalysis(key).then(
        (data: any) => {
          this.spinnerButtonOptions.active = false;
          const LRLarray: any[] = [];
          const LSLarray: any[] = [];
          const LWLarray: any[] = [];
          const TGTarray: any[] = [];
          const UWLarray: any[] = [];
          const USLarray: any[] = [];
          const URLarray: any[] = [];
          const array: any[] = [];
          data.forEach((c: any) => {
            // tslint:disable-next-line:one-variable-per-declaration
            const arr = [],
              arrLRL = [];
            const arrLSL = [];
            const arrLWL = [];
            const arrTGT = [];
            const arrUWL = [];
            const arrUSL = [];
            const arrURL = [];
            arr.push(new Date(c.processTime).getTime());
            arr.push(parseFloat(c.result));
            // array.push(arr);
            array.push({
              x: new Date(c.processTime).getTime(),
              y: isNaN(c.result) ? '' : parseFloat(c.result),
              label: c.Product,
            });
            arrLRL.push(new Date(c.processTime).getTime());
            arrLRL.push(parseFloat(c.LRL));
            LRLarray.push(arrLRL);
            arrLSL.push(new Date(c.processTime).getTime());
            arrLSL.push(parseFloat(c.LSL));
            LSLarray.push(arrLSL);
            arrLWL.push(new Date(c.processTime).getTime());
            arrLWL.push(parseFloat(c.LWL));
            LWLarray.push(arrLWL);
            arrTGT.push(new Date(c.processTime).getTime());
            arrTGT.push(parseFloat(c.TGT));
            TGTarray.push(arrTGT);
            arrUWL.push(new Date(c.processTime).getTime());
            arrUWL.push(parseFloat(c.UWL));
            UWLarray.push(arrUWL);
            arrUSL.push(new Date(c.processTime).getTime());
            arrUSL.push(parseFloat(c.USL));
            USLarray.push(arrUSL);
            arrURL.push(new Date(c.processTime).getTime());
            arrURL.push(parseFloat(c.URL));
            URLarray.push(arrURL);
          });
          this.stock = new StockChart({
            plotOptions: {
              series: {
                turboThreshold: 50000000,
              },
            },
            chart: {
              height: 730,
            },
            rangeSelector: {
              selected: 1,
            },
            title: {
              text: parameterName.toUpperCase(),
            },
            credits: {
              enabled: false,
            },
            legend: {
              enabled: true,
            },
            tooltip: {
              formatter(): any {
                // let s = '<b>' + StockChart.dateFormat('%A, %b %e, %Y', this.x) + '</b>';
                let s = '';
                this.points?.forEach((point: any) => {
                  if (point.series.name === 'Result') {
                    s +=
                      '<br/> <span style="color: ' +
                      point.series.color +
                      '">' +
                      point.series.name +
                      ' : ' +
                      point.y +
                      '<span><br/>' +
                      '<br/><span style="color: #252031">Product : ' +
                      point.point.label +
                      '<span><br/>';
                  } else {
                    s +=
                      '<br/> <span style="color: ' +
                      point.series.color +
                      '">' +
                      point.series.name +
                      ' : ' +
                      point.y +
                      '<span><br/>';
                  }
                });
                return s;
              },
            },
            yAxis: {
              title: {
                text: 'Result',
              },
              opposite: false,
              // tickInterval: 50
            },
            series: [
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'Result',
                type: 'line',
                data: array,
                color: '#040496',
                marker: {
                  enabled: true,
                },
                label: {
                  onArea: false,
                },
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LRL',
                type: 'line',
                data: LRLarray,
                color: '#00ff00',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LSL',
                type: 'line',
                data: LSLarray,
                color: '#FF5733',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LWL',
                type: 'line',
                data: LWLarray,
                color: '#9C412D',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'TGT',
                type: 'line',
                data: TGTarray,
                color: '#9C902D',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'UWL',
                type: 'line',
                data: UWLarray,
                color: '#25B79B',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'USL',
                type: 'line',
                data: USLarray,
                color: '#257AB7',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'URL',
                type: 'line',
                data: URLarray,
                color: '#FF0909',
                dashStyle: 'ShortDash',
              },
            ],
          });

          chartObj.chartOption = this.stock;
          this.chartArray.push(chartObj);
        },
        (error: any) => {
          this.toaster.error('Error', error.ErrorMessage);
          this.spinnerButtonOptions.active = false;
        }
      );
    });
    this.spinnerButtonOptions.active = false;
  }
  onClickParamterAnalysisProcessControl(): void {
    const selectedRows = this.processGridApi
      .getSelectedRows()
      .filter(
        (c: any) =>
          c.DataType === 'Int' ||
          c.DataType === 'Bool' ||
          c.DataType === 'Float'
      );
    this.spinnerButtonOptions.active = true;
    // tslint:disable-next-line:max-line-length
    this.dialog.open(this.visibleYearlyTargetTbl, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '95vh',
      minWidth: '95vw',
      maxHeight: '80vh !important',
    });
    this.chartArray = [];
    selectedRows.forEach(async (element: any) => {
      const parameterName = element.Parameter;
      const key = {
        parameterId: element.parameterId,
        groupType: this.qualityGroupType,
      };
      const chartObj = { id: '', chartOption: {} };
      chartObj.id = element.parameterId;
      await this.processService.getProcessParameterAnalysis(key).then(
        (data: any) => {
          this.spinnerButtonOptions.active = false;
          const LRLarray: any[] = [];
          const LSLarray: any[] = [];
          const LWLarray: any[] = [];
          const TGTarray: any[] = [];
          const UWLarray: any[] = [];
          const USLarray: any[] = [];
          const URLarray: any[] = [];
          const Productarray: any[] = [];
          const array: any[] = [];
          data.forEach((c: any) => {
            // tslint:disable-next-line:one-variable-per-declaration
            const arr = [],
              arrLRL = [];
            const arrLSL = [];
            const arrLWL = [];
            const arrTGT = [];
            const arrUWL = [];
            const arrUSL = [];
            const arrURL = [];
            const arrProduct = [];
            arr.push(new Date(c.processTime).getTime());
            arr.push(parseFloat(c.result));
            array.push({
              x: new Date(c.processTime).getTime(),
              y: parseFloat(c.result),
              label: c.Product,
            });
            arrLRL.push(new Date(c.processTime).getTime());
            arrLRL.push(parseFloat(c.LRL)); // LSL Rename To LRL
            LRLarray.push(arrLRL);
            arrLSL.push(new Date(c.processTime).getTime());
            arrLSL.push(parseFloat(c.LSL)); // LCL Rename To LSL
            LSLarray.push(arrLSL);
            arrLWL.push(new Date(c.processTime).getTime());
            arrLWL.push(parseFloat(c.LWL));
            LWLarray.push(arrLWL);
            arrTGT.push(new Date(c.processTime).getTime());
            arrTGT.push(parseFloat(c.TGT));
            TGTarray.push(arrTGT);
            arrUWL.push(new Date(c.processTime).getTime());
            arrUWL.push(parseFloat(c.UWL));
            UWLarray.push(arrUWL);
            arrUSL.push(new Date(c.processTime).getTime());
            arrUSL.push(parseFloat(c.USL)); // UCL Rename To USL
            USLarray.push(arrUSL);
            arrURL.push(new Date(c.processTime).getTime());
            arrURL.push(parseFloat(c.URL)); // USL Rename To URL
            URLarray.push(arrURL);
            arrProduct.push(new Date(c.processTime).getTime());
            arrProduct.push(c.Product); // USL Rename To URL
            Productarray.push({
              x: new Date(c.processTime).getTime(),
              y: isNaN(c.result) ? '' : parseFloat(c.result),
              label: c.Product,
            });
          });
          this.stock = new StockChart({
            plotOptions: {
              series: {
                turboThreshold: 50000000,
              },
            },
            chart: {
              height: 730,
            },
            rangeSelector: {
              selected: 1,
            },
            title: {
              text: parameterName.toUpperCase(),
            },
            credits: {
              enabled: false,
            },
            legend: {
              enabled: true,
            },
            tooltip: {
              formatter(): any {
                // let s = '<b>' + StockChart.dateFormat('%A, %b %e, %Y', this.x) + '</b>';
                let s = '';
                this.points?.forEach((point: any) => {
                  if (point.series.name === 'Result') {
                    s +=
                      '<br/> <span style="color: ' +
                      point.series.color +
                      '">' +
                      point.series.name +
                      ' : ' +
                      point.y +
                      '<span><br/>' +
                      '<br/><span style="color: #252031">Product : ' +
                      point.point.label +
                      '<span><br/>';
                  } else {
                    s +=
                      '<br/> <span style="color: ' +
                      point.series.color +
                      '">' +
                      point.series.name +
                      ' : ' +
                      point.y +
                      '<span><br/>';
                  }
                });
                return s;
              },
            },
            yAxis: [
              {
                title: {
                  text: 'Result',
                },
                opposite: false,
              },
              {
                title: {
                  text: 'Product',
                  style: {
                    color: '#252031',
                  },
                },
                opposite: true,
              },
            ],
            series: [
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'Result',
                type: 'line',
                data: array,
                color: '#040496',
                // dataLabels: {
                //   enabled: true,
                //   formatter(): any {
                //
                //     return `<span>${this.point.label}</span>`;
                //   }
                // }
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LRL',
                type: 'line',
                data: LRLarray,
                color: '#00ff00',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LSL',
                type: 'line',
                data: LSLarray,
                color: '#FF5733',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'LWL',
                type: 'line',
                data: LWLarray,
                color: '#9C412D',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'TGT',
                type: 'line',
                data: TGTarray,
                color: '#9C902D',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'UWL',
                type: 'line',
                data: UWLarray,
                color: '#25B79B',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'USL',
                type: 'line',
                data: USLarray,
                color: '#257AB7',
                dashStyle: 'ShortDash',
              },
              {
                tooltip: {
                  valueDecimals: 2,
                },
                name: 'URL',
                type: 'line',
                data: URLarray,
                color: '#FF0909',
                dashStyle: 'ShortDash',
              },
              // {
              //   // tooltip: {
              //   //   valueDecimals: 2
              //   // },
              //   name: 'Product',
              //   type: 'line',
              //   data: Productarray,
              //   color: '#252031',
              //   yAxis: 1
              //   // dashStyle: 'ShortDash'
              // }
            ],
          });

          chartObj.chartOption = this.stock;
          this.chartArray.push(chartObj);
        },
        (error: any) => {
          this.toaster.error('Error', error.ErrorMessage);
          this.spinnerButtonOptions.active = false;
        }
      );
    });
    this.spinnerButtonOptions.active = false;
  }
  onClickParamterComparsionProcessControl(): void {
    const selectedRows = this.processGridApi
      .getSelectedRows()
      .filter((c: any) => c.DataType === 'Int' || c.DataType === 'Float');
    this.spinnerButtonOptions1.active = true;
    // tslint:disable-next-line:max-line-length
    this.dialog.open(this.visibleParameterComparison, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '95vw',
      minWidth: '95vw',
      maxHeight: '90vh',
    });

    const array: any[] = [];
    const yAxisArr: any[] = [];
    const values = selectedRows
      .map((value: any) => value.parameterId)
      .toString();
    const valueNames = selectedRows.map((value: any) => value.Parameter);
    const seletedGroups = selectedRows.map(
      (value: any) => value.ParamaterGroup
    );
    const uniqueGroups = [...new Set(seletedGroups)];
    const dataArrName: any[] = [];
    const key = {
      parameterId: values,
      groupType: this.qualityGroupType,
    };
    this.spinner.show();
    this.processService.getParameterControlComparison(key).then(
      (data: any) => {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < valueNames.length; i++) {
          const dataArr = [];
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < data.length; j++) {
            // const arr = [];
            if (valueNames[i] === data[j].name) {
              // arr.push(new Date(data[j].processTime).getTime());
              // arr.push(parseFloat(data[j].result));
              // dataArr.push(arr);
              dataArr.push({
                x: new Date(data[j].processTime).getTime(),
                y: isNaN(data[j].result) ? '' : parseFloat(data[j].result),
                label: data[j].product,
              });
            }
          }
          let negativeArr = [];
          negativeArr = dataArr.map((c) => c.y);
          const obj = {
            tooltip: {
              valueDecimals: 2,
            },
            name: valueNames[i],
            type: 'line',
            data: dataArr,
            color: this.highChartsColor[i],
            yAxis: i,
          };
          array.push(obj);
          yAxisArr.push({
            lineWidth: 1,
            opposite: negativeArr.some((v) => v < 0) ? true : false,
            title: {
              text: valueNames[i],
              style: {
                color: this.highChartsColor[i],
              },
            },
            labels: {
              style: {
                color: this.highChartsColor[i],
              },
            },
          });
        }
        this.chart = new StockChart({
          chart: {
            height: 730,
          },
          plotOptions: {
            series: {
              turboThreshold: 50000000,
              events: {
                hide(): any {
                  // this.yAxis.axisTitle.hide();
                  this.yAxis.update({
                    visible: false,
                  });
                },
                show(): any {
                  this.yAxis.update({
                    visible: true,
                  });
                },
              },
            },
          },
          rangeSelector: {
            selected: 1,
          },
          title: {
            text: uniqueGroups.toString(),
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: true,
          },
          tooltip: {
            formatter(): any {
              // let s = '<b>' + StockChart.dateFormat('%A, %b %e, %Y', this.x) + '</b>';
              let s = '';
              this.points?.forEach((point: any) => {
                s +=
                  '<br/> <span style="color: ' +
                  point.series.color +
                  '">' +
                  point.series.name +
                  ' : ' +
                  point.y +
                  '<span><br/>' +
                  '<br/><span style="color: ' +
                  point.series.color +
                  '">Product : ' +
                  point.point.label +
                  '<span><br/>';
              });
              return s;
            },
          },
          yAxis: yAxisArr,
          series: array,
        });
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
    this.spinnerButtonOptions1.active = false;
  }
  saveBool(qualityBoolVal: any): void {
    const itemArr: any[] = [];
    let rowNode: any;
    if (this.isProcessGrid) {
      this.processGridApi.forEachLeafNode((node: any) => {
        itemArr.push(node);
      });
      const filterIndex: any = itemArr.filter(
        (c) => c.rowIndex === this.boolRowIndex
      );
      rowNode = this.processGridApi.getRowNode(filterIndex[0].id);
    } else {
      this.qualityGridApi.forEachLeafNode((node: any) => {
        itemArr.push(node);
      });
      const filterIndex: any = itemArr.filter(
        (c) => c.rowIndex === this.boolRowIndex
      );
      rowNode = this.qualityGridApi.getRowNode(filterIndex[0].id);
    }
    rowNode.setDataValue(this.boolColId, qualityBoolVal);
    this.dialog.closeAll();
  }
  onQualityControlGridReady(params: any): any {
    this.qualityGridApi = params.api;
    this.qualityGridColumnApi = params.columnApi;
  }
  onProcessGridReady(params: any): any {
    this.processGridApi = params.api;
    this.processGridColumnApi = params.columnApi;
    // Following line to make the currently visible columns fit the screen
    params.api.sizeColumnsToFit();

    // Following line dymanic set height to row on content
    params.api.resetRowHeights();
  }
  getParameterIntervalQualityControl(): void {
    const Key = {
      puId: this.unitId,
    };
    this.qualityParameterService.getQualityControlInterval(Key).subscribe(
      (response: any) => {
        this.qualityIntervalList = response;
        this.qualityControlForm
          .get('timeInterval')
          ?.setValue(this.qualityIntervalList[0]?.Interval);
        this.onToProcessDateQuanlityControl();
      },
      (error: { message: any }) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }

  getQualityIntervalType(): void {
    const key = {
      puId: this.machineId,
    };
    console.log('getQualityIntervalType', key);
    this.processService.getQualityGroupEvents(key).subscribe(
      (data: any) => {
        this.QualityIntervalTypeDDL = data;
        this.qualityControlForm
          .get('eventType')
          ?.setValue(this.QualityIntervalTypeDDL[0].val);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  qualityControlEventMode(): void {
    this.rowDataQualityControl = [];
    // tslint:disable-next-line:max-line-length
    this.qualityGroupType = this.qualityControlGroupTypeList.find(
      (c: any) =>
        c.parameterGroupTypeId === this.qualityControlForm.value.groupType
    )?.groupTypeName;
    if (this.qualityGroupType === 'Time') {
      // this.processControlForm
      //   .get('timeInterval')
      //   ?.setValue(this.intervalList[0].Interval);
      this.getParameterIntervalQualityControl();
    } else if (this.qualityGroupType === 'Event') {
      this.qualityControlForm.get('eventType')?.setValue('Input');
      this.onToProcessDateQuanlityControl();
    } else if (this.qualityGroupType === 'Manual') {
      this.onToProcessDateQuanlityControl();
    }
    this.getQualityIntervalType();
  }

  GetQualityControlGroupTypeList(): any {
    const key = {
      puId: this.unitId,
    };
    this.qualityParameterService.getQualityGroupbyUnit(key).subscribe(
      (data: any) => {
        this.qualityControlGroupTypeList = data.filter(
          (c: any) => c.groupTypeName !== 'None'
        );
        this.qualityControlForm
          .get('groupType')
          ?.setValue(this.qualityControlGroupTypeList[0].parameterGroupTypeId);
        this.qualityControlEventMode();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  sendNotificationRole(descr: any, title: any): void {
    const key = {
      roleName: 'QC leader',
      egId: this.groupDataForm.value.group,
      createdBy: JSON.parse(localStorage.user).UserId,
      desc: descr,
      title,
    };
    this.notificationService.addnotification(key).subscribe(
      (response: any) => {
        this.toaster.success('Notification Sent');
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  generateColumns(data: any[]): any {
    this.columnDefsProcessControl = [];
    let columnDefinitions: any[] = [];
    data.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key, // .toUpperCase()
          // headerClass: 'header-style',
          headerClass: {},
          headerTooltip: key,
          tooltipComponent: 'customTooltip',
          tooltipValueGetter,
          field: key,
          resizable: true,
          wrapText: true,
          rowGroup: key === 'ParamaterGroup' ? true : false,
          width:
            key === 'DataType' || key === 'ParamaterGroup'
              ? 150
              : key === 'Parameter'
                ? 300
                : 200,
          pinned:
            key === 'Parameter' ||
              key === 'DataType' ||
              key === 'ParamaterGroup'
              ? 'left'
              : '',
          lockPinned:
            key === 'Parameter' ||
              key === 'DataType' ||
              key === 'ParamaterGroup'
              ? true
              : false,
          cellStyle: {},
          hide: key === 'ParamaterGroup' || key === 'DataType' ? true : false,
          filter: true,
          sortable: true,
          editable: false,
          valueParser: {},
          // tslint:disable-next-line:max-line-length
          valueGetter:
            key !== 'Parameter' &&
              key !== 'ParamaterGroup' &&
              key !== 'DataType' &&
              key !== 'parameterId'
              ? this.abValueGetter
              : null,
          rowSelection: 'multiple',
          onCellClicked: (params: any) => {
            if (params.data.DataType === 'Bool') {
              params.colDef.cellEditor = 'agRichSelectCellEditor';
              this.GetAllDataTypesDetail(params.data.parameterId);
              this.isProcessGrid = true;
              this.selectionName = params.data.Parameter;
              if (mappedColumn.editable) {
                this.dialog.open(this.qualityBoolListModal, {
                  height: 'auto',
                  width: '45%',
                });
              }
              this.boolColId = params.column.colId;
              this.boolRowIndex = params.rowIndex;
            } else if (params.data.DataType === 'Date') {
              params.colDef.cellEditor = 'primeCellEditor';
              // this.GetAllDataTypesDetail(params.data.parameterId);
              // this.isProcessGrid = true;
              // this.selectionName = params.data.Parameter;
              if (mappedColumn.editable) {
                this.dialog.open(this.qualityDateListModal, {
                  height: 'auto',
                  width: '45%',
                });
              }
            } else {
              params.colDef.cellEditor = 'agTextCellEditor';
            }
          },
          cellEditor: null,
          cellEditorParams:
            key !== 'Parameter' && key !== 'DataType'
              ? (params: any): Promise<any> => {
                return params.value;
              }
              : null,
        };
        if (this.processGroupType === 'Time') {
          mappedColumn.cellStyle = (params: any): any => {
            if (
              key !== 'Parameter' &&
              key !== 'ParamaterGroup' &&
              key !== 'Cavity' &&
              key !== 'DataType' &&
              key !== 'parameterId'
            ) {
              const fromDate = new Date(key.substring(0, 20));
              const toDate = new Date(key.substring(23, 43));
              // tslint:disable-next-line:max-line-length
              if (
                date.getTime() >= fromDate.getTime() &&
                date.getTime() <= toDate.getTime() + 1 * 60000 &&
                key.split('~poStatus')[1] === 'InProcess'
              ) {
                return {
                  'font-weight': 'bold',
                  color: params.value
                    ? params.data[params.colDef.field].split('|')[1]
                    : 'black',
                  'background-color': '#EDF1E5',
                };
              } else {
                if (params.value) {
                  return {
                    color: params.data[params.colDef.field].split('|')[1],
                  };
                }
              }
            }
          };
          const date = new Date(new Date().setSeconds(0));
          if (
            key !== 'Parameter' &&
            key !== 'DataType' &&
            key !== 'parameterId' &&
            key !== 'ParamaterGroup'
          ) {
            const fromDate = new Date(key.substring(0, 20));
            const toDate = new Date(key.substring(23, 43));
            // tslint:disable-next-line:max-line-length
            if (
              date.getTime() >= fromDate.getTime() &&
              date.getTime() <= toDate.getTime() + 1 * 60000
              // key.split('~poStatus')[1] === 'InProcess'
            ) {
              if (
                key.split('~poStatus')[1] === '' ||
                key.split('~poStatus')[1] === 'InProcess'
              ) {
                mappedColumn.editable = true;
                mappedColumn.lockPinned = true;
                mappedColumn.pinned = 'left';
              } else {
                mappedColumn.editable = false;
              }
              mappedColumn.valueParser = (params: any) => {
                if (this.IsWrite) {
                  // const col = params.colDef.field;
                  // if (col === 'Status') {
                  const dataType = params.data.DataType;
                  if (
                    dataType === 'Int' ||
                    dataType === 'Float' ||
                    dataType === 'String'
                  ) {
                    const newVal = params.newValue;
                    if (dataType === 'Int') {
                      const charcter = Number.isInteger(Number(newVal));
                      if (!charcter) {
                        this.toaster.warning(
                          'DigitsOnly',
                          'Please Select Digits Only'
                        );
                        params.node.setDataValue(
                          params.colDef.field,
                          params.oldValue
                        );
                        // params.node.setDataValue(params.colDef.field, params.oldValue);
                        return;
                      } else {
                        params.newValue = parseInt(params.newValue, 10);
                      }
                    }
                    if (dataType === 'Float') {
                      const float = parseFloat(newVal);
                      // tslint:disable-next-line:use-isnan
                      if (isNaN(float)) {
                        this.toaster.warning(
                          'FloatOnly',
                          'Please Select Float Only'
                        );
                        params.node.setDataValue(
                          params.colDef.field,
                          params.oldValue
                        );
                        return;
                      } else {
                        params.newValue = parseFloat(params.newValue).toFixed(
                          2
                        );
                      }
                    }
                    const startIntervalDate = params.colDef.field.substring(
                      0,
                      20
                    );
                    const endIntervalDate = params.colDef.field.substring(
                      22,
                      43
                    );
                    if (new Date() > new Date(endIntervalDate)) {
                      this.toaster.warning(
                        'Time Passed',
                        'Slot time has been passed'
                      );
                      return;
                    }
                    let cellData: any;
                    if (!params.oldValue || params.oldValue === '') {
                      cellData = {
                        result: params.newValue,
                        entryOn: new Date(),
                        resultOn: endIntervalDate,
                        varId: params.data.parameterId,
                        isOldValue: false,
                        po: params.column.colId.split('~')[1],
                        createdBy: JSON.parse(localStorage.user).UserId,
                        // tslint:disable-next-line:max-line-length
                        prodId:
                          params.column.colId.split('~prodId')[1] !== undefined
                            ? params.column.colId
                              .split('~prodId')[1]
                              .split('~')[0]
                            : '',
                        ppId:
                          params.column.colId.split('~ppId')[1] !== undefined
                            ? params.column.colId
                              .split('~ppId')[1]
                              .split('~')[0]
                            : '',
                        uomId:
                          params.column.colId.split('~uomId')[1] !== undefined
                            ? params.column.colId
                              .split('~uomId')[1]
                              .split('~')[0]
                            : '',
                        ppdId:
                          params.column.colId.split('~ppdId')[1] !== undefined
                            ? params.column.colId
                              .split('~ppdId')[1]
                              .split('~')[0]
                            : '',
                        intervalType: this.processGroupType,
                      };
                    } else {
                      cellData = {
                        result: params.newValue,
                        entryOn: new Date(),
                        resultOn: endIntervalDate,
                        varId: params.data.parameterId,
                        endInterval: endIntervalDate,
                        startInterval: startIntervalDate,
                        isOldValue: true,
                        po: params.column.colId.split('~')[1],
                        createdBy: JSON.parse(localStorage.user).UserId,
                        // tslint:disable-next-line:max-line-length
                        prodId:
                          params.column.colId.split('~prodId')[1] !== undefined
                            ? params.column.colId
                              .split('~prodId')[1]
                              .split('~')[0]
                            : '',
                        ppId:
                          params.column.colId.split('~ppId')[1] !== undefined
                            ? params.column.colId
                              .split('~ppId')[1]
                              .split('~')[0]
                            : '',
                        uomId:
                          params.column.colId.split('~uomId')[1] !== undefined
                            ? params.column.colId
                              .split('~uomId')[1]
                              .split('~')[0]
                            : '',
                        ppdId:
                          params.column.colId.split('~ppdId')[1] !== undefined
                            ? params.column.colId
                              .split('~ppdId')[1]
                              .split('~')[0]
                            : '',
                        intervalType: this.processGroupType,
                      };
                    }
                    this.processService.saveProessControl(cellData).subscribe(
                      (response: any) => {
                        const msg = response[0].result;
                        if (msg !== null || msg !== '') {
                          if (msg.substring(2, 1) === '0') {
                            this.toaster.success(
                              'Success',
                              msg.substring(3, msg.length)
                            );
                            params.node.setDataValue(
                              params.colDef.field,
                              response[0].value
                            );
                            if (response[0].value) {
                              const color = response[0].value.split('|')[1];
                              if (color === '#ff2121') {
                                this.sendNotificationRole(
                                  `NCR ID - ${response[0].ncrId}
                                   Out of Specification Limits! The Defect had happened against ${params.column.colId.split('~')[1]
                                  }`,
                                  'Process Control'
                                );
                              }
                              if (color === '#f49203') {
                                this.sendNotificationRole(
                                  `NCR ID - ${response[0].ncrId}
                                   Watch Out! There is a risk of defect happening against ${params.column.colId.split('~')[1]
                                  }`,
                                  'Process Control'
                                );
                              }
                            }
                          } else {
                            this.toaster.error(
                              'Error',
                              msg.substring(3, msg.length)
                            );
                            params.node.setDataValue(
                              params.colDef.field,
                              response[0].value
                            );
                          }
                        }
                      },
                      (error: any) => {
                        return this.toaster.error('Error', error.message);
                      }
                    );
                  }
                }
              };
            }
          }
          if (
            key !== 'Parameter' &&
            key !== 'parameterId' &&
            key !== 'DataType' &&
            key !== 'ParamaterGroup'
          ) {
            const fromDate = new Date(key.substring(0, 20));
            const toDate = new Date(key.substring(23, 43));
            mappedColumn.headerName =
              moment(fromDate).format('HH:mm:ss') +
              ' - ' +
              moment(toDate).format('HH:mm:ss') +
              ' - ' +
              key.split('~')[1] +
              ' - ' +
              key.split('~poStatus')[1];
          }
        }
        if (
          this.processGroupType === 'Event' ||
          this.processGroupType === 'Manual'
        ) {
          if (
            key !== 'Parameter' &&
            key !== 'ParamaterGroup' &&
            key !== 'parameterId' &&
            key !== 'Cavity' &&
            key !== 'DataType'
          ) {
            if (this.processGroupType === 'Event') {
              const fromDate = new Date(key.substring(0, 20));
              mappedColumn.headerName =
                moment(fromDate).format('YYYY-MM-DD HH:mm:ss') +
                ' - ' +
                `PO  :  ${key.split('~')[1]} LotCode:  ${key.split('~lotCode')[1].split('~')[0]
                }`;
              mappedColumn.headerTooltip = `PO  :  ${key.split('~')[1]
                } LotCode:  ${key.split('~lotCode')[1].split('~')[0]}`;
            } else {
              const fromDate = new Date(key.substring(0, 20));
              mappedColumn.headerName =
                moment(fromDate).format('YYYY-MM-DD HH:mm:ss') +
                ' - ' +
                key.split('~')[1];
              mappedColumn.headerTooltip = `PO  :  ${key.split('~')[1]}`;
            }
            mappedColumn.editable = true;
          }
          mappedColumn.cellStyle = (params: any): any => {
            if (
              key !== 'Parameter' &&
              key !== 'ParamaterGroup' &&
              key !== 'Cavity' &&
              key !== 'DataType' &&
              key !== 'parameterId'
            ) {
              // if (params.value) {
              return {
                'font-weight': 'bold',
                color: params.value
                  ? params.data[params.colDef.field].split('|')[1]
                  : 'black',
                'background-color': '#EDF1E5',
              };
              // }
            }
          };
          mappedColumn.valueParser = (params: any) => {
            if (this.IsWrite) {
              const dataType = params.data.DataType;
              if (
                dataType === 'Int' ||
                dataType === 'Float' ||
                dataType === 'String'
              ) {
                const dataType = params.data.DataType;
                let newVal = params.newValue;
                if (dataType === 'Int') {
                  const charcter = Number.isInteger(Number(newVal));
                  if (!charcter) {
                    this.toaster.warning(
                      'DigitsOnly',
                      'Please Select Int Value Only'
                    );
                    // newVal = params.oldValue;
                    params.node.setDataValue(
                      params.colDef.field,
                      params.oldValue
                    );
                    return;
                  } else {
                    params.newValue = parseInt(params.newValue, 10);
                  }
                }
                if (dataType === 'Float') {
                  const float = parseFloat(newVal);
                  // tslint:disable-next-line:use-isnan
                  if (isNaN(float)) {
                    this.toaster.warning(
                      'FloatOnly',
                      'Please Select Float Only'
                    );
                    // newVal = params.oldValue;
                    params.node.setDataValue(
                      params.colDef.field,
                      params.oldValue
                    );
                    return;
                  } else {
                    params.newValue = parseFloat(params.newValue).toFixed(2);
                  }
                }
                if (dataType === 'Date') {
                  const date = params.newValue;
                  if (date.match(new RegExp('/', 'g')).length === 2) {
                  } else {
                    this.toaster.warning(
                      'SlashError',
                      'Please Select Correct Date Format'
                    );
                    return;
                  }
                }
                const startIntervalDate = params.colDef.field.substring(0, 20);
                const endIntervalDate = params.colDef.field.substring(23, 43);
                if (new Date() > new Date(endIntervalDate)) {
                  this.toaster.warning(
                    'Time Passed',
                    'Slot time has been passed'
                  );
                  return;
                }
                // const fromDate = new Date(params.colDef.field.substring(0, 20).replace(/-/g, '/'));
                // const toDate = new Date(params.colDef.field.substring(22, 41).replace(/-/g, '/'));
                let cellData: any;
                if (!params.oldValue || params.oldValue === '') {
                  cellData = {
                    result: params.newValue,
                    entryOn: new Date(),
                    resultOn: startIntervalDate,
                    endInterval: startIntervalDate,
                    startInterval: startIntervalDate,
                    varId: params.data.parameterId,
                    isOldValue: false,
                    po: params.column.colId.split('~')[1],
                    eventId: params.column.colId
                      .split('~EventId')[1]
                      .split('~')[0],
                    createdBy: JSON.parse(localStorage.user).UserId,
                    intervalType: this.processGroupType,
                    prodId:
                      params.column.colId.split('~prodId')[1] !== undefined
                        ? params.column.colId.split('~prodId')[1].split('~')[0]
                        : '',
                    ppId:
                      params.column.colId.split('~ppId')[1] !== undefined
                        ? params.column.colId.split('~ppId')[1].split('~')[0]
                        : '',
                    uomId:
                      params.column.colId.split('~uomId')[1] !== undefined
                        ? params.column.colId.split('~uomId')[1].split('~')[0]
                        : '',
                    ppdId:
                      params.column.colId.split('~ppdId')[1] !== undefined
                        ? params.column.colId.split('~ppdId')[1].split('~')[0]
                        : '',
                  };
                } else {
                  cellData = {
                    result: params.newValue,
                    entryOn: new Date(),
                    resultOn: startIntervalDate,
                    varId: params.data.parameterId,
                    endInterval: startIntervalDate,
                    startInterval: startIntervalDate,
                    isOldValue: true,
                    po: params.column.colId.split('~')[1],
                    eventId: params.column.colId
                      .split('~EventId')[1]
                      .split('~')[0],
                    createdBy: JSON.parse(localStorage.user).UserId,
                    intervalType: this.processGroupType,
                    prodId:
                      params.column.colId.split('~prodId')[1] !== undefined
                        ? params.column.colId.split('~prodId')[1].split('~')[0]
                        : '',
                    ppId:
                      params.column.colId.split('~ppId')[1] !== undefined
                        ? params.column.colId.split('~ppId')[1].split('~')[0]
                        : '',
                    uomId:
                      params.column.colId.split('~uomId')[1] !== undefined
                        ? params.column.colId.split('~uomId')[1].split('~')[0]
                        : '',
                    ppdId:
                      params.column.colId.split('~ppdId')[1] !== undefined
                        ? params.column.colId.split('~ppdId')[1].split('~')[0]
                        : '',
                  };
                }
                this.processService.saveProessControl(cellData).subscribe(
                  (response: any) => {
                    // this.toaster.success('Success', 'Data Added Successfully');
                    // this.showPopupMsg(response[0].result);
                    const msg = response[0].result;
                    if (msg !== null || msg !== '') {
                      if (msg.substring(2, 1) === '0') {
                        this.toaster.success(
                          'Success',
                          msg.substring(3, msg.length)
                        );
                        params.node.setDataValue(
                          params.colDef.field,
                          response[0].value
                        );
                        // params.node.setDataValue(params.colDef.field, newVal);
                      } else {
                        this.toaster.error(
                          'Error',
                          msg.substring(3, msg.length)
                        );
                        params.node.setDataValue(
                          params.colDef.field,
                          params.oldValue
                        );
                      }
                    }
                  },
                  (error: any) => {
                    return this.toaster.error('Error', error.message);
                  }
                );
                // }
              }
            }
          };
        }
        if (key === 'parameterId') {
          mappedColumn.hide = true;
        }
        columnDefinitions.push(mappedColumn);
      });
    });

    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    this.rowSelection = 'multiple';
    return columnDefinitions;
  }
  onToProcessDate(): any {
    const getdata = {
      fromDate: this.processControlForm.value.datePicker[0],
      toDate: this.processControlForm.value.datePicker[1],
      puId: this.unitId,
      processType: 'PO',
      interval: this.processControlForm.value.timeInterval,
      currentDateTime: new Date(),
      // intervalType: this.processControlForm.value.eventType,
      // tslint:disable-next-line:max-line-length
      intervalType:
        this.processGroupType === 'Time' || this.processGroupType === 'Manual'
          ? this.processGroupType
          : this.processControlForm.value.eventType,
      groupType: this.processGroupType,
      // eventType: [''],
      // timeInterval: ['']
    };
    if (
      getdata.puId === undefined ||
      !getdata.fromDate ||
      this.UnitName === ''
    ) {
      this.toaster.warning('Warning', 'Please Select Unit And Dates');
      this.spinner.hide();
      return;
    }
    const from = this.processControlForm.value.datePicker[0];
    const To = this.processControlForm.value.datePicker[1];
    const timeDiff = moment(To).diff(moment(from), 'hours');

    // if (timeDiff > 24) {
    //   this.toaster.error(
    //     this.translater.instant('Error'),
    //     this.translater.instant('DateRangeSelect')
    //   );
    //   return;
    // }
    this.processGridApi.showLoadingOverlay();
    this.processService.getParameterProcessControl(getdata).then(
      (response: any) => {
        this.gridOptionsProcessControl.api.hideOverlay();
        this.rowDataProcessControl = response;
        if (this.rowDataProcessControl) {
          this.columnDefsProcessControl = this.generateColumns(
            this.rowDataProcessControl
          );
          setTimeout(() => {
            this.gridOptionsProcessControl.api.columnController.resetColumnState();
            if (this.qualityGroupType === 'Time') {
              const scroll = document.querySelector(
                '.ag-body-horizontal-scroll-viewport'
              );
              if (scroll !== null) {
                scroll.scrollLeft = scroll.scrollWidth;
              }
            }
          }, 100);
        }
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }

  getParameterInterval(): void {
    const Key = {
      puId: this.unitId,
    };
    this.processService.getProcessControlInterval(Key).subscribe(
      (response: any) => {
        this.intervalList = response;
        this.processControlForm
          .get('timeInterval')
          ?.setValue(this.intervalList[0].Interval);
        this.onToProcessDate();
      },
      (error: { message: any }) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  processparamaterdefects(params: any): void {
    const prodId =
      params.column.colId.split('~prodId')[1] !== undefined
        ? params.column.colId.split('~prodId')[1].split('~')[0]
        : '';
    const key = {
      paramId: params.node.data.parameterId,
      prodId,
    };
    this.processService.processparamaterdefects(key).subscribe(
      (response: any) => {
        this.parametrdefect = new MatTableDataSource<any>(response);
        // this.getPaginator();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  processControlDefect(params: any): void {
    this.displayProcssDefectModal = true;
    // this.dialog.open(this.displayProcssDefectModal, {
    //   height: '70%',
    //   width: '90%',
    // });
    this.processparamaterdefects(params);
  }
  getContextMenuItemsProcessControl = (params: any) => {
    // const that = this;
    let result: any;
    if (this.IsWrite) {
      result = [
        {
          name: 'Specifiction Limits',
          action: () => {
            // this.parameterId = params.node.data.parameterId;
            this.processControlSpecification(params);
          },
        },
        {
          name: 'Defect - Failure Mode Details',
          action: () => {
            // this.parameterId = params.node.data.parameterId;
            this.processControlDefect(params);
          },
        },
      ];
    }
    return result;
  };
  expandprocess() {
    this.isCardExpanded = !this.isCardExpanded;
    this.Exdpandhide = false;
    this.Collapsehide = true;
  }
  closeprocess() {
    this.isCardExpanded = !this.isCardExpanded;
    this.Exdpandhide = true;
    this.Collapsehide = false;
  }
  expandquality() {
    this.isCardExpandedQuality = !this.isCardExpandedQuality;
    this.ExdpandhideQuality = false;
    this.CollapsehideQuality = true;
  }
  closequality() {
    this.isCardExpandedQuality = !this.isCardExpandedQuality;
    this.ExdpandhideQuality = true;
    this.CollapsehideQuality = false;
  }
  GetGroupTypeList(): any {
    const key = {
      puId: this.machineId,
    };
    this.qualityParameterService.getVariableGroupbyUnit(key).subscribe(
      (data: any) => {
        this.groupTypeList = data.filter(
          (c: any) => c.groupTypeName !== 'None'
        );
        if (this.groupTypeList.length) {
          this.processControlForm
            .get('groupType')
            ?.setValue(this.groupTypeList[0].parameterGroupTypeId);
          this.variableEventMode();
        }
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetStDuration(): void {
    const key = {
      PUId: this.groupDataForm.value.machine,
    };
    this.sfdService.GetStDuration(key).subscribe((res: any) => {
      this.masterList = res.masterList;
    });
  }
  GetTotalBatchForStaticTotal(): void {
    const key = {
      PPDID: 0,
      BatchTypeId: 3,
      BatchItemTypeId: 0,
      UserId: 0,
    };
    this.sfdService.GetTotalBatch(key).subscribe((res: any) => {
      this.total1 = res.ErrorMessage;
    });
  }
  GetFaultByUnit(): void {
    const key = {
      PUId: this.machineId,
      UserId: 0,
    };
    this.sfdService.GetFaultByUnit(key).subscribe((res: any) => {
      this.DTFaultList = res.FaultList;
      this.filteredDTFaultList = this.DTFaultList.slice();
      this.downTimeForm.get('fault')?.setValue(this.DTFaultList[0]?.FaultId);
      this.shortStopsForm.get('fault')?.setValue(this.FaultList[0]?.FaultId);
    });
  }
  getFoodSector() {
    const key = {
      Id: this.machineId,
    };
    this.sfdService.getFoodSector(key).subscribe(
      (res: any) => {
        this.isFoodSector = res.IsFoodSector;
        this.IsReworkRequired = res.IsReworkRequired;
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }

  selectedunit = '';

  SelectUnitName(): void {
    this.machineId = this.groupDataForm.value.machine;
    this.unitId = this.groupDataForm.value.machine;
    const machineName = this.Machines.find(
      (c) => c.puId === this.machineId
    ).puDesc;
    if (machineName !== null) {
      this.hideBtn = false;
      // this.machineId = unitname.PrimaryId;
      this.displayMAchineModal = false;
      this.startTime = new Date();
      this.getDefectMgmtTableData();
    }
    // Logic From CIL Management Ends Here
    this.getDateShifts();
    this.statusPo = ' ';
    this.itemDescPo = null;
    this.itemCodePo = null;
    this.showButtons = true;
    this.onlymachineSelectProperty = true;
    this.itemCode = 'Item Code: undefined';
    // if (unitname.Level === 'Unit') {
    //   this.UnitName = machineName;
    //   this.isPlantGroupModelVisible = false;
    // }
    this.productionOutputForm.get('actQty')?.reset();
    this.productionOutputForm.get('remarks')?.reset();
    if (this.isAuto === false) {
      this.productionOutputForm.get('pallet')?.reset();
    }
    this.groupDataForm.get('processOrder')?.reset();
    this.groupDataForm.get('operationName')?.reset();
    this.groupDataForm.get('productDetail')?.reset();
    this.groupDataForm.get('remarks')?.reset();
    this.materialConsumptionForm.get('qty')?.reset();
    this.total = 'Total: ';
    this.total1 = 'Total: ';
    this.targetQty = '';
    // this.GetLableBatch1(unitname);
    this.centerLineDataSource = new MatTableDataSource<any>([]);
    this.getPaginator(this.centerLineDataSource, 0);
    this.materialConsumptionDataSource = new MatTableDataSource<any>([]);
    this.HistoryBatchDataSource1 = new MatTableDataSource<any>([]);
    this.machineSelectProperty = false;
    this.ItemTypeList = [];
    this.FaultList = [];
    this.GetCrewScheduleList();
    this.GetGroupTypeList();
    this.GetIsShiftStart();
    this.GetLableBatchForWaste();
    this.GetStDuration();
    this.GetFaultByUnit();
    this.GetSTFault();
    this.GetWasteFaultByUnit();
    this.GetBatchCheckListByUnit();
    this.getFoodSector();
    this.GetLastShiftInfoWithoutModel();
    this.buttonThemeAfterSelectUnit();
    this.disableSFDCbuttons = false;
    this.selectedunit = '(' + machineName + ')';
  }
  onQualityControlRefresh(): void {
    this.qualityControlForm.get('toProcessDate')?.setValue(new Date());
    this.rowData = [];
    this.onToProcessDateQuanlityControl();
  }
  getPath(model: any, id: any): any {
    let path;
    const item = model;

    if (!model || typeof model !== 'object') {
      return;
    }

    if (model.id === id) {
      return [item];
    }

    (model.children || []).some(
      (child: any) => (path = this.getPath(child, id))
    );
    return path && [item, ...path];
  }

  getParameterCavity(): void {
    const Key = {
      puId: this.unitId,
    };
    this.qualityParameterService.getMaxCavity(Key).subscribe(
      (response: any) => {
        this.maxCavity = response.cavity;
        this.groupDataForm.get('processType')?.setValue(response.processType);
        const val = response.cavity;
        const arr: any[] = [];
        for (let index = 0; index < val; index++) {
          // const element = index + 1;
          arr.push({ value: index + 1 });
        }
        this.cavityList = arr;
        this.showCavity = this.cavityList.length > 1 ? true : false;
      },
      (error: { message: any }) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  onNodeSelect(node: any): void {
    if (node.Level === 'Unit') {
      this.updateOrderprocessingData = false;
      this.machineId = node.PrimaryId;
      this.unitId = node.PrimaryId;

      this.groupDataForm.get('Unit')?.setValue(node.text);
      this.rowDataProcessControl = [];
      this.customerManagementDataSource.data = [];
      // this.getParameterCavity();

      const tree = this.treeControl.dataNodes;
      const data = this.getPath(tree[0], node.id);
      this.plantId = data.find((c: any) => c.Level === 'Plant').PrimaryId;
      this.groupDataForm.get('intervalType')?.setValue('Time');
      this.getAllQualityCheckList();
      this.SelectUnitName();
      this.buttonThemeAfterSelectUnit();
    } else return;
    this.getOeeDashboard();
  }
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  onChange(result: Date): void {
  }
  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }

  // closeParamterValuesModelVisible(): void {
  //   this.isPlantGroupModelVisible = false;
  //   this.updateOrderprocessingData = false;
  // }
  ReturnHome(IdMenu: any): any {
    this.urll = 'home';
    localStorage.setItem('O3RightMenuId', IdMenu);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([this.urll]));
  }

  closePlantGroupModel(): void {
    this.updateOrderprocessingData = false;
  }
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  GetLableBatchForWaste(): void {
    const key = {
      BatchTypeId: 0,
      UserId: 0,
      PUID: this.machineId,
      TypeId: 3,
    };
    this.sfdService.GetLableBatch(key).subscribe((res: any) => {
      this.resultWaste = res.Result;
      this.name = res.Name;
      this.columnWasteHistry.forEach(function (colDef, index) {
        if (colDef.headerName === 'qty') {
          colDef.headerName = res.Result;
        }
        if (colDef.headerName === 'mode') {
          colDef.headerName = res.Name;
        }
      });
    });
  }
  timerflag: boolean = false;
  applyTheme() {
    const downTimer = document.querySelector('#downTimer');
    downTimer?.classList.add('bgColor');
    downTimer?.classList.remove('RemovebgColor');
    const downTimerPrevious = document.querySelector('#downTimerPrevious');
    downTimerPrevious?.classList.add('bgColor');
    downTimerPrevious?.classList.remove('RemovebgColor');
    const timer_blinks = document.querySelector('#timer_blinks');
    timer_blinks?.classList.add('timer_number');
    timer_blinks?.classList.remove('stop_timer_number');
  }
  removeTheme() {
    const downTimer = document.querySelector('#downTimer');
    downTimer?.classList.remove('bgColor');
    downTimer?.classList.add('RemovebgColor');
    const downTimerPrevious = document.querySelector('#downTimerPrevious');
    downTimerPrevious?.classList.remove('bgColor');
    // downTimerPrevious?.classList.add('RemovebgColor');
    const timer_blinks = document.querySelector('#timer_blinks');
    timer_blinks?.classList.remove('timer_number');
    timer_blinks?.classList.add('stop_timer_number');
  }
  startDownTime(): any {
    this.faultclose = true;

    this.timerflag = !this.timerflag;
    const wrapper1 = document.querySelector('#timer');
    wrapper1?.classList.remove('hide-timer');
    wrapper1?.classList.add('show-timer');
    if (this.timerflag) {
      this.applyTheme();
      console.log(this.meetingStarted);
      if (this.meetingStarted === false) {
        this.meetingStarted = true;
        this.meetingStoped = false;
        this.meetingStartTime = new Date();
        this.interval = setInterval(() => {
          this.seconds = this.seconds + 1;
          if (this.seconds === 60) {
            this.minutes = this.minutes + 1;
            this.seconds = 0;
          }
          if (this.minutes === 60) {
            this.hours = this.hours + 1;
            this.minutes = 0;
            this.seconds = 0;
          }
          this.timer =
            ('0' + this.hours).slice(-2) +
            ':' +
            ('0' + this.minutes).slice(-2) +
            ':' +
            ('0' + this.seconds).slice(-2);
        }, 1000);
        const minutesToAdd = 4;
        const currentDate = new Date();
        const futureDate = new Date(currentDate.getTime() - minutesToAdd * 60000);
        this.downTimeForm.get('fault')?.setValue(this.FaultList[0].FaultId),
          this.downTimeForm.get('startDate')?.setValue(futureDate),
          this.downTimeForm.get('endDate')?.setValue(null),
          this.SaveJBDowntimeInfo();
      } else {
        this.toaster.warning('Timer has been started already');
      }
    }
  }
  stopDownTime(): void {
    if (this.meetingStarted === true) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Stop the timer Right Now!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Stop it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.removeTheme();
          clearInterval(this.interval);
          this.meetingEndTime = new Date();
          this.meetingStarted = false;
          this.meetingStoped = true;
          const domainUrl = document.location.origin;
          const meetingId = this.selectedMeeting?.meetingId;
          const meetingName = this.selectedMeeting?.meetingName;
          const groupId = parseInt(this.MeetingMatrixForm.value.group, 10);
          const plantId = parseInt(this.MeetingMatrixForm.value.plant, 10);
          // const meetingType = this.meetingOriginalArray.find(
          //   (x) => x.meetingId === meetingId
          // )?.type;
          // const downTime = this.JbDowntimeHistoryList[0];
          // const data = {
          //   Duration: 4253,
          //   EndTime: new Date(),
          //   FaultId: downTime.FaultId,
          //   JobdowtimeId: 25417,
          //   PUID: downTime.JobDownTimeId,
          //   Remarks: "",
          //   StartTime: "2022-01-06T13:17:01Z",
          //   TransactionTypeId: 2,
          //   UserId: 0
          // };
          // this.meetingService.stopMeetingAndSendEmail(data).subscribe(
          //   (res) => {
          //     this.toaster.success(
          //       'Timer Has been Ended And also mail have been sent to meeting attended'
          //     );
          //     this.resetMeeting();
          //   },
          //   ({ error }) => {
          //     this.toaster.error(error.message);
          //   }
          // );
          this.dialog.open(this.faultmodal, {
            disableClose: true,
            width: '100vw !important',
            height: 'auto',
            minWidth: '100vw',
          });
          const element = this.JbDowntimeHistoryList[0];
          this.downTimeForm.get('startDate')?.setValue(element.StartTime);
          this.downTimeForm.get('endDate')?.setValue(new Date());
          this.downTimeForm.get('duration')?.setValue(element.Duration);
          this.downTimeForm
            .get('fault')
            ?.setValue(
              this.DTFaultList.find((x) => x.FaultName === element.FaultName).FaultId
            );
          this.downTimeForm.get('remarks')?.setValue(element.Remarks);
          this.JobDownTimeId = element.JobDownTimeId;
        }
      });
      return;
    }
  }
  start(): void {
    const key = {
      PPDId: this.PPID,
      UserId: 0,
      PPSID: this.statusList.find((c) => c.TypeName === 'InProcess').TypeId,
    };
    this.sfdService.SumbitProcessOrderStatus(key).subscribe((res: any) => {
      if (this.machineSelectProperty === true) {
        this.statusPo = this.statusList.find(
          (c) => c.TypeName === 'InProcess'
        ).TypeName;
        // const input = document.querySelector('#input');
        // input?.classList.add('effect');
        // input?.classList.remove('disableEffect');
        //this.StatusChangeBTN(this.statusPo);

        this.startbutton = true;
        this.pausebutton = false;
        this.closebutton = false;
        var Startinput = <HTMLInputElement>document.querySelector('#startbtn');
        var Pauseinput = <HTMLInputElement>document.querySelector('#pausebtn');
        var Closeinput = <HTMLInputElement>document.querySelector('#closebtn');
        Pauseinput.style.backgroundColor = '#65CE9D';
        Startinput.style.backgroundColor = '#dddddd';
        Closeinput.style.backgroundColor = '#65CE9D';

        this.GetProcessOrderList();
        this.showPopupMsg(res.ErrorMessage);
      } else {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('PoListSelection')
        );
      }
    });
  }

  pause() {
    const key = {
      PPDId: this.PPID,
      UserId: 0,
      PPSID: this.statusList.find((c) => c.TypeName === 'Pause').TypeId,
    };
    this.sfdService.SumbitProcessOrderStatus(key).subscribe((res: any) => {
      if (this.machineSelectProperty === true) {
        this.statusPo = this.statusList.find(
          (c) => c.TypeName === 'Pause'
        ).TypeName;
        // const input = document.querySelector('#input');
        // input?.classList.add('effect');
        // input?.classList.remove('disableEffect');
        //this.StatusChangeBTN(this.statusPo);
        this.startbutton = false;
        this.pausebutton = true;
        this.closebutton = false;

        var Startinput = <HTMLInputElement>document.querySelector('#startbtn');
        var Pauseinput = <HTMLInputElement>document.querySelector('#pausebtn');
        var Closeinput = <HTMLInputElement>document.querySelector('#closebtn');
        Pauseinput.style.backgroundColor = '#dddddd';
        Startinput.style.backgroundColor = '#65CE9D';
        Closeinput.style.backgroundColor = '#65CE9D';
        this.GetProcessOrderList();
        this.showPopupMsg(res.ErrorMessage);
      } else {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('PoListSelection')
        );
      }
    });
  }
  groupDataMode = true;
  SumbitProcessOrderStatus(): void {
    this.groupDataMode = true;

    const key = {
      PPDId: this.PPID,
      UserId: 0,
      PPSID: this.groupDataForm.get('status')?.value,
    };
    this.sfdService.SumbitProcessOrderStatus(key).subscribe((res: any) => {
      if (this.machineSelectProperty === true) {
        this.GetProcessOrderList();
        this.showPopupMsg(res.ErrorMessage);
      } else {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('PoListSelection')
        );
      }
    });
  }
  close() {
    const key = {
      PPDId: this.PPID,
      UserId: 0,
      PPSID: this.statusList.find((c) => c.TypeName === 'Complete').TypeId,
    };
    this.sfdService.SumbitProcessOrderStatus(key).subscribe((res: any) => {
      if (this.machineSelectProperty === true) {
        this.statusPo = this.statusList.find(
          (c) => c.TypeName === 'Complete'
        ).TypeName;
        // const input = document.querySelector('#input');
        // input?.classList.add('effect');
        // input?.classList.remove('disableEffect');
        this.startbutton = true;
        this.pausebutton = true;
        this.closebutton = true;

        var Startinput = <HTMLInputElement>document.querySelector('#startbtn');
        var Pauseinput = <HTMLInputElement>document.querySelector('#pausebtn');
        var Closeinput = <HTMLInputElement>document.querySelector('#closebtn');
        Pauseinput.style.backgroundColor = '#dddddd';
        Startinput.style.backgroundColor = '#dddddd';
        Closeinput.style.backgroundColor = '#dddddd';

        this.GetProcessOrderList();
        this.showPopupMsg(res.ErrorMessage);
        // this.StatusChangeBTN(this.statusPo);
      } else {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('PoListSelection')
        );
      }
    });
  }
  resetMeeting(): void {
    const wrapper1 = document.querySelector('#timer');
    wrapper1?.classList.add('hide-timer');
    wrapper1?.classList.remove('show-timer');
    clearInterval(this.interval);
    this.timer = '00:00:00';
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    this.meetingStartTime = undefined;
    this.meetingEndTime = undefined;
    this.meetingStarted = false;
    this.meetingStoped = false;
  }
  onClickDowntimeHistory(data: any): void {
    this.editDownTime(data.data);
  }
  onClickWasteHistory(data: any): void {
    this.editWasteHistory(data.data);
  }
  onClickProductHistoryBatch(data: any): void {
    this.editProductionHistoryBatch(data.data);
  }
  onClickEditMaterialConsumptionBatch(data: any): void {
    this.editMaterialConsumptionHistoryBatch(data.data);
  }
  onClickEditFoodSectorMaterial(data: any): void {
    this.editFoodSectorMaterial(data.data);
  }
  onClickPopupViewForMaterialHistory(data: any): void {
    this.openPopupViewForMaterialHistory(data.data);
  }
  openPopupViewForMaterialHistory(element: any): void {
    this.dialog.open(this.materialViewModal, {
      width: '100vw !important',
      height: 'auto',
      minWidth: '100vw',
    });
    this.getBatchItemBOMInfo(element, '');
  }

  onClickGetBarCodeDetails(data: any): void {
    this.GetBarCodeDetails(data.data);
  }
  editFoodSectorMaterial(element: any) {
    this.JobBatchMaterialId = element.JobBatchId;
    this.spinner.show();
    const key = {
      Ishistory: false,
      PPDId: this.PPID,
      JobBatchId: element.JobBatchId,
    };
    this.sfdService.getBatchItemBOMList(key).subscribe(
      (res: any) => {
        this.materialFoodSectorConsumptionForm
          .get('mActQtys')
          ?.setValue(element.Result);
        this.materialFoodSectorConsumptionForm
          .get('mlotCode')
          ?.setValue(element.BatchNo);
        this.materialFoodSectorConsumptionForm
          .get('mTime')
          ?.setValue(element.RollStarttime);
        this.materialFoodSectorConsumptionForm
          .get('remarks')
          ?.setValue(element.Remarks);
        this.materialFoodSectorConsumptionForm
          .get('isRework')
          ?.setValue(element.IsRework);
        this.getBatchItemBOMInfo(element, 'edit');
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  getBatchItemBOMInfo(element: any, actionType: string) {
    if (actionType === 'edit') {
      const key = {
        PUId: this.machineId,
        JobBatchId: element.JobBatchId,
      };
      this.sfdService.getBatchItemBOMInfo(key).subscribe(
        (res: any) => {
          this.ItemBOMList = res.batchItemBOMList;
        },
        (error: any) => {
          this.toaster.error(
            this.translater.instant('Error'),
            this.translater.instant('WentWrong')
          );
        }
      );
      this.dialog.closeAll();
    } else {
      const key = {
        PUId: this.machineId,
        JobBatchId: element.JobBatchId,
      };
      this.sfdService.getBatchItemBOMInfo(key).subscribe(
        (res: any) => {
          this.ItemBOMViewList = res.batchItemBOMList;
        },
        (error: any) => {
          this.toaster.error(
            this.translater.instant('Error'),
            this.translater.instant('WentWrong')
          );
        }
      );
    }
  }
  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.sfdService.getGroupData(key).subscribe((res: any) => {
      this.groups = res.masterList;
      this.groupDataForm.get('group')?.setValue(this.groups[0]?.TypeId);
      this.downtime = '(' + this.groups[0].TypeName + ')';
      this.CilManagDataForm.controls.group.setValue(this.groups[0].TypeId);
      this.selectedGroup = this.groups[0].TypeId;
      this.GetPlantByEgDDL();
      this.getPlantByGroup();
    });
  }
  GetItemTypeByUnit(element: any): void {
    const key = {
      PUId: this.machineId,
      BatchTypeId: 1,
      PPId: this.PPID,
      UserId: 0,
    };
    this.sfdService.GetItemTypeByUnit(key).subscribe((res: any) => {
      this.ItemTypeList = res.ItemTypeList;
      this.materialConsumptionForm
        .get('materialDate')
        ?.setValue(this.ItemTypeList[0]?.BatchItemTypeId);
      this.itemCode = this.ItemTypeList.find(
        (x: any) => x.BatchItemTypeId === this.ItemTypeList[0]?.BatchItemTypeId
      ).ItemCode;
      this.JobBatchItemTypeId = this.materialConsumptionForm.value.materialDate;
      const code = { value: this.ItemTypeList[0].BatchItemTypeId };
      this.GetLableBatch(code);
      this.GetJbBatchHistory();
      this.GetJbBatchHistory1();
      this.GetTotalBatch();
    });
  }
  downtime: string = '';
  selectedOption: number = 0;
  index: number = 0;
  selectHistoryProcessOrdersForSelectionChange(element: any): void {
    const wrapper1 = document.querySelector('#timer');
    wrapper1?.classList.add('hide-timer');
    wrapper1?.classList.remove('show-timer');
    this.groupDataForm.get('Unit')?.reset();
    this.groupDataForm.get('status')?.reset();
    this.materialConsumptionForm.get('materialDate')?.reset();
    this.materialConsumptionForm.get('qty')?.reset();
    this.wasteForm.get('categoryType')?.reset();
    this.downTimeForm.get('fault')?.reset();
    this.shortStopsForm.get('fault')?.reset();
    this.shortStopsForm.get('duration')?.reset();
    this.productionOutputForm.get('pallet')?.reset();
    this.ItemTypeList = [];
    this.FaultList = [];
    this.DTFaultList = [];
    this.GTList = [];
    this.filteredDTFaultList = [];
    this.filteredGTList = [];
    this.masterList = [];
    // this.statusList = [];
    this.showButtons = false;
    this.showShiftClose = false;
    this.onlymachineSelectProperty = false;
    this.machineSelectProperty = false;
    this.productionOutputDataSource = new MatTableDataSource<any>([]);
    this.selectHistoryProcessOrders(element);
    this.resetMeeting();
    this.getPlantByGroup();
    //this.GetCrewScheduleList();

    const index = this.groups.findIndex((x) => x.TypeId === element.value);
    this.downtime = '(' + this.groups[index].TypeName + ')';
  }
  getItemBomListInfo() {
    this.materialFoodSectorConsumptionForm.get('remarks')?.reset();
    this.JobBatchMaterialId = '';
    const key = {
      IsRework: this.isRework,
      PPDId: this.PPID,
    };
    this.sfdService.getItemBomListInfo(key).subscribe(
      (res: any) => {
        this.ItemBOMList = res.ItemBOMList;
        this.materialFoodSectorConsumptionForm
          .get('mActQtys')
          ?.setValue(res.Qty);
        this.materialFoodSectorConsumptionForm
          .get('mlotCode')
          ?.setValue(res.Lotcode);
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }

  getFoodSectorMaterialConsumptionList() {
    const key = {
      Ishistory: false,
      PPDId: this.PPID,
    };
    this.sfdService.getBatchItemBOMList(key).subscribe(
      (res: any) => {
        this.batchItemBOMList = res.BatchItemBOMList;
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }

  GetLableBatch1(element: any): void {
    const key = {
      BatchTypeId: this.PPID,
      UserId: 0,
      PUID: this.machineId,
      TypeId: 2,
    };
    this.sfdService.GetLableBatch(key).subscribe((res: any) => {
      this.productionOutputForm.get('time')?.setValue(new Date());
      this.targetQty = res.Qty;
      this.remainingQty =
        (this.SFDC_TotalProducedQTy as number) - (this.targetQty as number) ||
        0;
      this.Result = '(' + res.Result + ')';
    });
  }
  GetStatus(): void {
    const key = {};
    this.sfdService.GetStatus(key).subscribe((res: any) => {
      this.statusList = res.masterList;
    });
  }
  buttonThemeAfterSelectUnit() {
    var materialbtn = <HTMLInputElement>(
      document.querySelector('#MaterialConsumption')
    );
    var PObtn = <HTMLInputElement>document.querySelector('#ProductionOutput');
    var wastebtn = <HTMLInputElement>document.querySelector('#Waste');
    var processbtn = <HTMLInputElement>(
      document.querySelector('#ProcessControl')
    );
    var qualitybtn = <HTMLInputElement>(
      document.querySelector('#QualityControl')
    );
    var downtinebtn = <HTMLInputElement>(
      document.querySelector('#downTimerPrevious')
    );
    var checklistbtn = <HTMLInputElement>(
      document.querySelector('#ChecklistExecution')
    );
    var CILbtn = <HTMLInputElement>document.querySelector('#CIL');
    materialbtn.style.backgroundColor = '#1F01FF';
    PObtn.style.backgroundColor = '#312AF8';
    wastebtn.style.backgroundColor = '#524CFD';
    processbtn.style.backgroundColor = 'rgb(55 51 157)';
    qualitybtn.style.backgroundColor = 'rgb(93 89 201)';
    downtinebtn.style.backgroundColor = 'rgb(84 79 211)';
    checklistbtn.style.backgroundColor = 'rgb(126 124 233)';
    CILbtn.style.backgroundColor = 'rgb(126 124 233)';
  }

  initialTheme() {
    var Startinput = <HTMLInputElement>document.querySelector('#startbtn');
    var Pauseinput = <HTMLInputElement>document.querySelector('#pausebtn');
    var Closeinput = <HTMLInputElement>document.querySelector('#closebtn');
    var materialbtn = <HTMLInputElement>(
      document.querySelector('#MaterialConsumption')
    );
    var PObtn = <HTMLInputElement>document.querySelector('#ProductionOutput');
    var wastebtn = <HTMLInputElement>document.querySelector('#Waste');
    var processbtn = <HTMLInputElement>(
      document.querySelector('#ProcessControl')
    );
    var qualitybtn = <HTMLInputElement>(
      document.querySelector('#QualityControl')
    );
    var downtinebtn = <HTMLInputElement>(
      document.querySelector('#downTimerPrevious')
    );
    var checklistbtn = <HTMLInputElement>(
      document.querySelector('#ChecklistExecution')
    );
    var CILbtn = <HTMLInputElement>document.querySelector('#CIL');
    Pauseinput.style.backgroundColor = '#dddddd';
    Startinput.style.backgroundColor = '#dddddd';
    Closeinput.style.backgroundColor = '#dddddd';
    materialbtn.style.backgroundColor = '#dddddd';
    PObtn.style.backgroundColor = '#dddddd';
    wastebtn.style.backgroundColor = '#dddddd';
    processbtn.style.backgroundColor = '#dddddd';
    qualitybtn.style.backgroundColor = '#dddddd';
    downtinebtn.style.backgroundColor = '#dddddd';
    checklistbtn.style.backgroundColor = '#dddddd';
    CILbtn.style.backgroundColor = '#dddddd';
  }
  StatusChangeBTN(element: any) {
    if (element.OperStatus === 'Ready To Start') {
      var Startinput = <HTMLInputElement>document.querySelector('#startbtn');
      var Pauseinput = <HTMLInputElement>document.querySelector('#pausebtn');
      var Closeinput = <HTMLInputElement>document.querySelector('#closebtn');
      this.startbutton = false;
      this.pausebutton = true;
      this.closebutton = true;
      Pauseinput.style.backgroundColor = '#dddddd';
      Startinput.style.backgroundColor = '#65CE9D';
      Closeinput.style.backgroundColor = '#dddddd';
    }
    if (element.OperStatus == 'InProcess') {
      var Startinput = <HTMLInputElement>document.querySelector('#startbtn');
      var Pauseinput = <HTMLInputElement>document.querySelector('#pausebtn');
      var Closeinput = <HTMLInputElement>document.querySelector('#closebtn');
      Pauseinput.style.backgroundColor = '#65CE9D';
      Startinput.style.backgroundColor = '#dddddd';
      Closeinput.style.backgroundColor = '#65CE9D';

      this.startbutton = true;
      this.pausebutton = false;
      this.closebutton = false;
    }
    if (element.OperStatus == 'Complete') {
      var Startinput = <HTMLInputElement>document.querySelector('#startbtn');
      var Pauseinput = <HTMLInputElement>document.querySelector('#pausebtn');
      var Closeinput = <HTMLInputElement>document.querySelector('#closebtn');
      Pauseinput.style.backgroundColor = '#dddddd';
      Startinput.style.backgroundColor = '#dddddd';
      Closeinput.style.backgroundColor = '#dddddd';

      this.startbutton = true;
      this.pausebutton = true;
      this.closebutton = true;
    }
    if (element.OperStatus == 'Close') {
      var Startinput = <HTMLInputElement>document.querySelector('#startbtn');
      var Pauseinput = <HTMLInputElement>document.querySelector('#pausebtn');
      var Closeinput = <HTMLInputElement>document.querySelector('#closebtn');
      this.startbutton = true;
      this.pausebutton = true;
      this.closebutton = true;
      Pauseinput.style.backgroundColor = '#dddddd';
      Startinput.style.backgroundColor = '#dddddd';
      Closeinput.style.backgroundColor = '#dddddd';
    }
    if (element.OperStatus == 'Pause') {
      var Startinput = <HTMLInputElement>document.querySelector('#startbtn');
      var Pauseinput = <HTMLInputElement>document.querySelector('#pausebtn');
      var Closeinput = <HTMLInputElement>document.querySelector('#closebtn');
      Pauseinput.style.backgroundColor = '#dddddd';
      Startinput.style.backgroundColor = '#65CE9D';
      Closeinput.style.backgroundColor = '#65CE9D';
      this.startbutton = false;
      this.pausebutton = true;
      this.closebutton = false;
    }
  }
  selectHistoryProcessOrders(element: any): void {
    this.poStartDate = element.CreatedOn;
    this.getOeeDashboard();
    this.dialog.closeAll();
    this.GetShiftCloseValidation();
    this.centerLineDataSource = new MatTableDataSource<any>([]);
    this.getPaginator(this.centerLineDataSource, 0);
    this.materialConsumptionDataSource = new MatTableDataSource<any>([]);
    this.HistoryBatchDataSource1 = new MatTableDataSource<any>([]);
    this.wasteDataSource1 = new MatTableDataSource<any>([]);
    this.downtimeDataSource = new MatTableDataSource<any>([]);
    this.total = 'Total: ';
    this.total1 = 'Total: ';
    this.totalWaste = 'Total: ';
    this.targetQty = '';
    this.itemCode = 'ProdCode: ';
    this.groupDataForm.get('Id')?.setValue(element.PPDId);
    this.poprocessOrder = element.PO;
    this.groupDataForm.get('operationName')?.setValue(element.Oper);
    this.groupDataForm.get('productDetail')?.setValue(element.ProductDetail);
    this.PDtooltip = element.ProductDetail;
    this.itemCodePo = element.ItemCode;
    this.itemDescPo = element.ItemDesc;
    this.IDtooltip = element.ItemDesc;
    this.groupDataForm.get('remarks')?.setValue(element.Remarks);
    this.groupDataForm
      .get('status')
      ?.setValue(
        this.statusList.find((c) => c.TypeName === element.OperStatus)?.TypeId
      );
    this.statusPo = this.statusList.find(
      (c) => c.TypeName === element.OperStatus
    )?.TypeName;
    this.StatusChangeBTN(element);
    const input = document.querySelector('#input');
    input?.classList.remove('effect');
    input?.classList.add('disableEffect');
    this.PPID = element.PPDId;
    this.PPS_ID = element.PPS_Id;
    this.GetProcessOrderList();
    this.GetKpiParamInfoList(element);
    this.GetItemTypeByUnit(element);
    this.getChangeStartTime(element);
    this.GetStatus();
    this.GetTotalWaste();
    this.GetLableBatch1(element);
    this.GetJbWasteHistory();

    this.GetJbDowntimeHistory();
    this.getMinutes(element);
    this.GetLatestLotCode();
    this.GetTotalBatch1();
    this.LastInstOPjobBatch();
    this.GetLableBatchForWaste();
    this.getItemBomListInfo();
    this.getFoodSectorMaterialConsumptionList();
    this.getBatchConsumption();
    this.wasteButtonShow = true;
    this.showMaterialButtons = true;
    this.machineSelectProperty = true;

    // this.SFDC_TotalGoodPieces = this.SFDC_TotalProducedQTy - this.SFDC_Totalwaste || 0;
  }

  selectHistory(data: any): void {
    this.selectHistoryProcessOrders(data.data);
    // this.GetAllQualityParameterIntervals();
  }
  getProcessIntervalType(): void {
    const key = {
      puId: this.machineId,
    };
    this.processService.getVariableGroupEvents(key).subscribe(
      (data: any) => {
        this.processIntervalTypeDDL = data;
        this.processControlForm
          .get('eventType')
          ?.setValue(this.processIntervalTypeDDL[0].val);
        this.onToProcessDate();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  variableEventMode(): void {
    this.rowDataProcessControl = [];
    this.processGroupType = this.groupTypeList.find(
      (c: any) =>
        c.parameterGroupTypeId === this.processControlForm.value.groupType
    )?.groupTypeName;
    if (this.processGroupType === 'Time') {
      this.getParameterInterval();
    } else if (this.processGroupType === 'Event') {
      this.getProcessIntervalType();
    } else if (this.processGroupType === 'Manual') {
      this.onToProcessDate();
    }
  }

  onProcessControlRefresh(): void {
    if (this.processControlForm.valid) {
      this.rowDataProcessControl = [];
      this.processControlForm
        .get('datePicker')
        ?.setValue([this.processControlForm.value.datePicker[0], new Date()], {
          emitEvent: false,
        });
      this.onToProcessDate();
    } else {
      // this.toaster.warning('Select all mandatory fields');
    }
  }
  onQualityIntervalChange(): void {
    this.onToProcessDateQuanlityControl();
  }

  getCurrentProcessOrder(val: any): void {
    const data = {
      puId: this.machineId,
    };
    this.qualityParameterService.getCurrentProcessOrder(data).subscribe(
      (response) => {
        if (response.length) {
          this.currentprocessOrder = response[0].ProcessOrder;
          this.PPID = response[0].PPD_Id;
          this.manualInput(val);
        } else {
          this.currentprocessOrder = null;
          this.PPID = null;
          this.manualInput(val);
        }
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  manualInput(val: any): void {
    const data = {
      plantId: this.plantId,
      lotcode: null,
      eventDateTime: new Date(),
      eventTypeId: 3,
      puId: this.machineId,
      processOrder: this.currentprocessOrder,
      // processOrder: this.groupDataForm.get('processOrder')?.value,
      processOrderId: this.PPID,
    };
    this.sfdService.addSfdcEvent(data).then(
      (response) => {
        this.toaster.success(
          this.translater.instant('Success'),
          this.translater.instant('ManualInput') +
          ' ' +
          this.translater.instant('Event') +
          ' ' +
          this.translater.instant('AddedSuccessfully')
        );
        if (val === 'process') {
          this.onProcessControlRefresh();
        } else {
          this.onQualityControlRefresh();
        }
      },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  qualityProcessOrderProcessControl = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginatorProcessControl!: MatPaginator;

  onClickControlProcessOrderProcessControl(): any {
    const key = {
      fromDate: this.processControlForm.value.datePicker[0],
      toDate: this.processControlForm.value.datePicker[1],
      puId: this.unitId,
      processType: this.processControlForm.value.processType,
    };
    this.spinnerButtonOptionsOrders.active = true;
    this.processService
      .getQualityControlProcessOrder(key)
      .subscribe((response: any) => {
        this.dialog.open(this.displayQualityOrdersModal, {
          height: '90%',
          width: '90%',
        });
        this.qualityProcessOrderProcessControl.data = response;
        // this.getPaginatorOrder();
      });
    this.spinnerButtonOptionsOrders.active = false;
  }

  // C1-Result Time Should Not Be More Than Current Time!"

  manualOutPut(): void {
    const data = {
      plantId: this.plantId,
      lotcode: null,
      eventDateTime: new Date(),
      eventTypeId: 4,
      puId: this.machineId,
      processOrder: this.groupDataForm.get('processOrder')?.value,
      processOrderId: this.PPID,
    };
    this.sfdService.addSfdcEvent(data).then(
      (response) => {
        this.toaster.success(
          this.translater.instant('Success'),
          this.translater.instant('ManualOutPut') +
          ' ' +
          this.translater.instant('Event') +
          ' ' +
          this.translater.instant('AddedSuccessfully')
        );
      },
      (error) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }
  openQualitySpecification(params: any): void {
    this.displayQualitySpecificationModal = true;
    // this.dialog.open(this.displayQualitySpecificationModal, {
    //   height: '90%',
    //   width: '90%',
    // });
    const podata = params.column.colId.split('~')[1];
    this.paramterSpcecificationName = params.node.data.Parameter;
    const key = {
      processOrder: podata,
      paramterId: params.node.data.parameterId,
      prodId:
        params.column.colId.split('~prodId')[1] !== undefined
          ? params.column.colId.split('~prodId')[1].split('~')[0]
          : '',
    };
    this.getParameterSpecifications(key);
  }
  getParameterSpecifications(key: any): void {
    this.qualityParameterService.getParameterSpecifications(key).subscribe(
      (response: any) => {
        this.parameterspecifications.data = response;
        this.chartSpecifications = response;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  openQualityDefecct(params: any): void {
    this.displayQualityDefectModal = true;
    // this.dialog.open(this.displayQualityDefectModal, {
    //   height: '90%',
    //   width: '90%',
    // });
    this.qualityparamaterdefects(params);
  }
  qualityparamaterdefects(params: any): void {
    const prodId =
      params.column.colId.split('~prodId')[1] !== undefined
        ? params.column.colId.split('~prodId')[1].split('~')[0]
        : '';
    const key = {
      paramId: params.node.data.parameterId,
      prodId,
    };
    this.qualityParameterService.qualityparamaterdefects(key).subscribe(
      (response: any) => {
        this.parametrdefect = new MatTableDataSource<any>(response);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  openQc(params: any): void {
    this.qcParamsSlot = params.column.userProvidedColDef.headerName;
    this.qcStartTimeSlot = params.column.userProvidedColDef.field.substring(
      0,
      19
    );
    if (this.qualityGroupType === 'Time') {
      this.qcEndTimeSlot = params.column.userProvidedColDef.field.substring(
        22,
        41
      );
    } else {
      this.qcEndTimeSlot = params.column.userProvidedColDef.field.substring(
        0,
        19
      );
    }
    this.qcheadertooltip = params.column.userProvidedColDef.headerTooltip;
    this.qcProcessorderNo = params.column.colId.split('~')[1];
    this.qcProdCode = params.column.colId.split('~')[3];
    this.qcProcdDesc = params.column.colId.split('~')[2];
    const qcTableArr: any[] = [];
    const dateCol = params.column.colId;

    params.api.forEachLeafNode((node: any) => {
      qcTableArr.push({
        parameter: node.data.Parameter,
        value: node.data[dateCol]
          ? node.data[dateCol].split('|')[0]
          : node.data[dateCol],
        parameterId: node.data.parameterId,
        sample: node.data.Cavity,
        verificationStatus: 'Approve',
        remarks: node.data.remarks,
        // isVerified: false,
      });
    });
    this.qcdataSource.data = qcTableArr;
    // this.getPaginatorqc();

    // global array empty
    this.detailArray = [];
    // for verified
    this.qcdataSource.data.forEach(
      (ele: {
        parameter: any;
        parameterId: any;
        sample: any;
        value: any;
        verificationStatus: any[];
        remarks: any;
        // isVerified: any;
      }) => {
        const obj = {
          parameter: ele.parameter,
          parameterId: ele.parameterId,
          sample: ele.sample,
          value: ele.value,
          verificationStatus: ele.verificationStatus
            ? ele.verificationStatus
            : 'Approve',
          remarks: ele.remarks ? ele.remarks : '',
        };
        this.detailArray.push(obj);
      }
    );
    // get qc verified data
    const key = {
      startTime: this.qcStartTimeSlot,
      endTime: this.qcEndTimeSlot,
    };
    this.qualityParameterService
      .GetQCVerifyDetail(key)
      .subscribe((res: any) => {
        this.verifyCheckedArray = res;
        const mergeArray = this.detailArray.map((element: any) => {
          const tableArray = this.verifyCheckedArray.find(
            (x: any) => x.paramterId === element.parameterId
          );
          return { ...element, ...tableArray };
        });
        this.qcdataSource.data = mergeArray;
      });
    // modal open
    this.dialog.open(this.displayQCModal, { height: '90%', width: '90%' });
  }
  getContextMenuItems = (params: any) => {
    // const that = this;
    let result: any;
    if (this.qualityGroupType === 'Time') {
      const key = params.column.colId;
      const date = new Date(new Date().setSeconds(0));

      const fromDate = new Date(key.substring(0, 19).replace(/-/g, '/'));
      const toDate = new Date(key.substring(22, 41).replace(/-/g, '/'));

      const array = JSON.parse(localStorage.roleName).map((v: any) =>
        v.toLowerCase()
      );
      this.qcRole = array.includes('qc leader') === true ? true : false;
      if (
        this.IsWrite &&
        this.qcRole &&
        date.getTime() >= fromDate.getTime() &&
        date.getTime() <= toDate.getTime() + 1 * 60000 &&
        key.split('~poStatus')[1] === 'InProcess'
      ) {
        result = [
          {
            name: 'Specifiction Limits',
            action: () => {
              this.openQualitySpecification(params);
            },
          },
          {
            name: 'Defect - Failure Mode Details',
            action: () => {
              this.openQualityDefecct(params);
            },
          },
          {
            name: 'QC Verify',
            action: () => {
              this.openQc(params);
            },
          },
        ];
      } else {
        result = [
          {
            name: 'Specifiction Limits',
            action: () => {
              this.openQualitySpecification(params);
            },
          },
          {
            name: 'Defect - Failure Mode Details',
            action: () => {
              this.openQualityDefecct(params);
            },
          },
        ];
      }
    } else {
      result = [
        {
          name: 'Specifiction Limits',
          action: () => {
            this.openQualitySpecification(params);
          },
        },
        {
          name: 'Defect - Failure Mode Details',
          action: () => {
            this.openQualityDefecct(params);
          },
        },
        {
          name: 'QC Verify',
          action: () => {
            this.openQc(params);
          },
        },
      ];
    }
    return result;
  };

  SFDC_PerformanceList: any = [];
  SFDC_PerformanceList1: any = [];

  GetShiftCloseValidation(): void {
    const key = {
      //Id: this.Shift
      CrewId: this.crewId,
    };
    this.sfdService.GetShiftCloseValidation(key).subscribe((res: any) => {
      this.availabilityDataSource = new MatTableDataSource<any>(
        res.AvailabilityList
      );
      const array = res.AvailabilityList;
      // array.forEach((element: any) => {
      //   this.SFDC_TotalDowntime = element.TotalTime;
      // });
      this.PerformanceDataSource = new MatTableDataSource<any>(
        res.PerformanceList
      );
      this.SFDC_PerformanceList1 = res.PerformanceList;
      this.qualityDataSource = new MatTableDataSource<any>(res.QualityList);
      this.SFDC_PerformanceList = res.QualityList;
      this.shiftDataSource = new MatTableDataSource<any>(res.ValidationList);
      this.shiftactionDataSource =
        new MatTableDataSource<ShiftCloseInterface>();
    });
  }
  openshiftcloseModal(): void {
    this.dialog.open(this.displayshiftcloseRoleModal, {
      width: '100vw !important',
      height: 'auto',
      minWidth: '100vw',
    });
    this.GetShiftCloseValidation();
  }
  getSorting(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.sort = this.sort.toArray()[index];
    }, 1000);
  }
  GetProcessOrderList(): void {
    const key = {
      PUId: this.groupDataForm.value.machine,
      PPDId: 0,
      UserId: 0,
    };
    this.spinner.show();
    this.sfdService.GetProcessOrderList(key).subscribe((res: any) => {
      this.rowData = res.ProcessOrderList;
      this.spinner.hide();
      this.getPaginator(this.poListDataSource, 2);
      this.getSorting(this.poListDataSource, 9);
    });
  }
  openPoModal(): void {
    this.dialog.open(this.displayPoModal, {
      width: '100vw !important',
      height: 'auto',
      minWidth: '100vw',
    });
    this.GetProcessOrderList();
  }
  colorCode(value: any): any {
    let colorCode = '';
    if (Math.round(value) > 0 && Math.round(value) <= 20) {
      return (colorCode = '#FF0000');
    } else if (Math.round(value) > 20 && Math.round(value) <= 50) {
      return (colorCode = '#ffff00');
    } else if (Math.round(value) > 50 && Math.round(value) <= 70) {
      return (colorCode = '#ffa500');
    } else if (Math.round(value) > 70) {
      return (colorCode = '#008000');
    } else if (Math.round(value) === 0) {
      return (colorCode = '');
    }
  }

  GetLastShiftInfoWithoutModel(): void {
    const key = {
      Id: this.machineId,
    };
    this.spinner.show();
    this.sfdService.GetLastShiftInfo(key).subscribe((res: any) => {
      this.masterList = res.ShiftInfoList;
      this.OEEInfoList = res.OEEInfoList;
      const array = res.OEEInfoList;
      this.DowntimeInfoList = res.DowntimeInfoList;
      this.lastShiftDataSource = new MatTableDataSource<any>(res.masterList);
      this.spinner.hide();

      this.getPaginator(this.lastShiftDataSource, 3);

      array.forEach((element1: any) => {
        // this.SFDC_OEE = element1.OEE;
        // this.SFDC_Performance = element1.Performance;
        // this.SFDC_Availability = element1.Availability;
        // this.SFDC_Quality = element1.Quality;

        let colorCode = '';
        if (
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) > 0 &&
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) <= 20
        ) {
          colorCode = '#FF0000';
        } else if (
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) > 20 &&
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) <= 50
        ) {
          colorCode = '#ffff00';
        } else if (
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) > 50 &&
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) <= 70
        ) {
          colorCode = '#ffa500';
        } else if (
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) > 70
        ) {
          colorCode = '#008000';
        }
        element1.OEE = element1.OEE.toFixed(2);
        element1.Availability = element1.Availability.toFixed(2);
        element1.Performance = element1.Performance.toFixed(2);
        element1.Quality = element1.Quality.toFixed(2);
        this.showLastShiftProperty = element1.OEE > 0 ? true : false;
        element1.capacityUtilization = {
          barType: 'radial',
          color: this.colorCode(element1.OEE),
          secondColor: '#E5E5E5',
          progress: element1.OEE > 0 ? Math.round(element1.OEE) : ' 0 ',
          radial: {
            depth: 8,
            size: 96,
            label: {
              enable: true,
              color: '#09608C',
            },
          },
        };
        element1.availabilityUtilization = {
          barType: 'radial',
          color: this.colorCode(element1.Availability),
          secondColor: '#E5E5E5',
          progress:
            element1.Availability > 0
              ? Math.round(element1.Availability)
              : ' 0 ',
          radial: {
            depth: 8,
            size: 96,
            label: {
              enable: true,
              color: '#09608C',
            },
          },
        };
        element1.performanceUtilization = {
          barType: 'radial',
          color: this.colorCode(element1.Performance),
          secondColor: '#E5E5E5',
          progress:
            element1.Performance > 0 ? Math.round(element1.Performance) : ' 0 ',
          radial: {
            depth: 8,
            size: 96,
            label: {
              enable: true,
              color: '#09608C',
            },
          },
        };
        element1.qualityUtilization = {
          barType: 'radial',
          color: this.colorCode(element1.Quality),
          secondColor: '#E5E5E5',
          progress: element1.Quality > 0 ? Math.round(element1.Quality) : ' 0 ',
          radial: {
            depth: 8,
            size: 96,
            label: {
              enable: true,
              color: '#09608C',
            },
          },
        };
      });

      if (res.ShiftInfoList.length > 0) {
        this.UserName = res.ShiftInfoList[0].UserName;
        this.Shift = res.ShiftInfoList[0].Shift;
        this.ShiftStart = moment(this.masterList[0].ShiftStart).format(
          'YYYY-MM-DD HH:mm'
        );
        this.ShiftEnd = moment(this.masterList[0].ShiftEnd).format(
          'YYYY-MM-DD HH:mm'
        );
        this.Supervisor = this.masterList[0].Supervisor;
        this.OEEInfoList = array;
        this.showMode = true;
      } else {
        this.UserName = '';
        this.Shift = '';
        this.ShiftStart = '';
        this.ShiftEnd = '';
        this.Supervisor = '';
        this.OEEInfoList = array;
        this.showMode = true;
      }

      // DownTime Chart
      this.canvas = document.getElementById('downTimeChart');
      this.canvas.height = 300;
      this.canvas.width = 530;
      this.ctx = this.canvas.getContext('2d');
      const faultArray: any[] = [];
      const durationArray: any[] = [];
      res.DowntimeInfoList.forEach((element: any) => {
        faultArray.push(element.Fault);
      });
      res.DowntimeInfoList.forEach((element: any) => {
        durationArray.push(element.Duration);
      });
      const downtime = new Chart(this.ctx, {
        type: 'horizontalBar',
        data: {
          labels: faultArray,
          datasets: [
            {
              data: durationArray,
              label: 'Down Time (Mins)',
              backgroundColor: '#040496',
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          display: true,
          labelString: 'Percentage',
          tooltips: {
            callbacks: {
              label: (tooltipItem: any, data: any) => {
                return data.datasets[0].data[tooltipItem.index] + '%';
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              padding: 20,
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                barThickness: 44,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  borderDash: [8, 4],
                },
                ticks: {
                  gridDashType: 'dash',
                  beginAtZero: true,
                  stepSize: 10,
                  min: 0,
                  max: 80,
                  callback(value: any, index: any): any {
                    if (value === 0) {
                      return 0 + '%';
                    } else {
                      return value + '%';
                    }
                  },
                },
              },
            ],
          },
        },
      });

      // Weight Chart
      this.canvas = document.getElementById('weightChart');
      this.canvas.height = 300;
      this.canvas.width = 530;
      this.ctx = this.canvas.getContext('2d');
      const pcsFaultArray: any[] = [];
      const amountArray: any[] = [];
      res.WasteInfoList.forEach((element: any) => {
        pcsFaultArray.push(element.Fault);
      });
      res.WasteInfoList.forEach((element: any) => {
        amountArray.push(element.Amount);
      });
      const weight = new Chart(this.ctx, {
        type: 'horizontalBar',
        data: {
          labels: pcsFaultArray,
          datasets: [
            { data: amountArray, label: 'Pcs', backgroundColor: '#040496' },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          display: true,
          labelString: 'Percentage',
          tooltips: {
            callbacks: {
              label: (tooltipItem: any, data: any) => {
                return data.datasets[0].data[tooltipItem.index] + '%';
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              padding: 20,
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                barThickness: 44,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  borderDash: [8, 4],
                },
                ticks: {
                  gridDashType: 'dash',
                  beginAtZero: true,
                  stepSize: 20,
                  min: 0,
                  max: 120,
                  callback(value: any, index: any): any {
                    if (value === 0) {
                      return 0 + '%';
                    } else {
                      return value + '%';
                    }
                  },
                },
              },
            ],
          },
        },
      });
    });
  }

  GetLastShiftInfo(element: any): void {
    this.dialog.open(this.lastshiftModal, {
      width: '100vw !important',
      height: 'auto',
      minWidth: '100vw',
    });
    const key = {
      Id: this.machineId, // 1
    };
    this.spinner.show();
    this.sfdService.GetLastShiftInfo(key).subscribe((res: any) => {
      this.masterList = res.ShiftInfoList;
      this.OEEInfoList = res.OEEInfoList;
      const array = res.OEEInfoList;
      this.DowntimeInfoList = res.DowntimeInfoList;
      this.lastShiftDataSource = new MatTableDataSource<any>(res.masterList);
      this.spinner.hide();

      this.getPaginator(this.lastShiftDataSource, 3);

      array.forEach((element1: any) => {
        let colorCode = '';
        if (
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) > 0 &&
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) <= 20
        ) {
          colorCode = '#FF0000';
        } else if (
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) > 20 &&
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) <= 50
        ) {
          colorCode = '#ffff00';
        } else if (
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) > 50 &&
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) <= 70
        ) {
          colorCode = '#ffa500';
        } else if (
          Math.round(
            element1.OEE ||
            element1.Availability ||
            element1.Performance ||
            element1.Quality
          ) > 70
        ) {
          colorCode = '#008000';
        }
        element1.OEE = element1.OEE.toFixed(2);
        element1.Availability = element1.Availability.toFixed(2);
        element1.Performance = element1.Performance.toFixed(2);
        element1.Quality = element1.Quality.toFixed(2);
        this.showLastShiftProperty = element1.OEE > 0 ? true : false;
        element1.capacityUtilization = {
          barType: 'radial',
          color: this.colorCode(element1.OEE),
          secondColor: '#E5E5E5',
          progress: element1.OEE > 0 ? Math.round(element1.OEE) : ' 0 ',
          radial: {
            depth: 8,
            size: 96,
            label: {
              enable: true,
              color: '#09608C',
            },
          },
        };
        element1.availabilityUtilization = {
          barType: 'radial',
          color: this.colorCode(element1.Availability),
          secondColor: '#E5E5E5',
          progress:
            element1.Availability > 0
              ? Math.round(element1.Availability)
              : ' 0 ',
          radial: {
            depth: 8,
            size: 96,
            label: {
              enable: true,
              color: '#09608C',
            },
          },
        };
        element1.performanceUtilization = {
          barType: 'radial',
          color: this.colorCode(element1.Performance),
          secondColor: '#E5E5E5',
          progress:
            element1.Performance > 0 ? Math.round(element1.Performance) : ' 0 ',
          radial: {
            depth: 8,
            size: 96,
            label: {
              enable: true,
              color: '#09608C',
            },
          },
        };
        element1.qualityUtilization = {
          barType: 'radial',
          color: this.colorCode(element1.Quality),
          secondColor: '#E5E5E5',
          progress: element1.Quality > 0 ? Math.round(element1.Quality) : ' 0 ',
          radial: {
            depth: 8,
            size: 96,
            label: {
              enable: true,
              color: '#09608C',
            },
          },
        };
      });

      if (res.ShiftInfoList.length > 0) {
        this.UserName = res.ShiftInfoList[0].UserName;
        this.Shift = res.ShiftInfoList[0].Shift;
        this.ShiftStart = moment(this.masterList[0].ShiftStart).format(
          'YYYY-MM-DD HH:mm'
        );
        this.ShiftEnd = moment(this.masterList[0].ShiftEnd).format(
          'YYYY-MM-DD HH:mm'
        );
        this.Supervisor = this.masterList[0].Supervisor;
        this.OEEInfoList = array;
        this.showMode = true;
      } else {
        this.UserName = '';
        this.Shift = '';
        this.ShiftStart = '';
        this.ShiftEnd = '';
        this.Supervisor = '';
        this.OEEInfoList = array;
        this.showMode = true;
      }

      // DownTime Chart
      this.canvas = document.getElementById('downTimeChart');
      this.canvas.height = 300;
      this.canvas.width = 530;
      this.ctx = this.canvas.getContext('2d');
      const faultArray: any[] = [];
      const durationArray: any[] = [];
      res.DowntimeInfoList.forEach((element: any) => {
        faultArray.push(element.Fault);
      });
      res.DowntimeInfoList.forEach((element: any) => {
        durationArray.push(element.Duration);
      });
      const downtime = new Chart(this.ctx, {
        type: 'horizontalBar',
        data: {
          labels: faultArray,
          datasets: [
            {
              data: durationArray,
              label: 'Down Time (Mins)',
              backgroundColor: '#040496',
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          display: true,
          labelString: 'Percentage',
          tooltips: {
            callbacks: {
              label: (tooltipItem: any, data: any) => {
                return data.datasets[0].data[tooltipItem.index] + '%';
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              padding: 20,
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                barThickness: 44,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  borderDash: [8, 4],
                },
                ticks: {
                  gridDashType: 'dash',
                  beginAtZero: true,
                  stepSize: 10,
                  min: 0,
                  max: 80,
                  callback(value: any, index: any): any {
                    if (value === 0) {
                      return 0 + '%';
                    } else {
                      return value + '%';
                    }
                  },
                },
              },
            ],
          },
        },
      });

      // Weight Chart
      this.canvas = document.getElementById('weightChart');
      this.canvas.height = 300;
      this.canvas.width = 530;
      this.ctx = this.canvas.getContext('2d');
      const pcsFaultArray: any[] = [];
      const amountArray: any[] = [];
      res.WasteInfoList.forEach((element: any) => {
        pcsFaultArray.push(element.Fault);
      });
      res.WasteInfoList.forEach((element: any) => {
        amountArray.push(element.Amount);
      });
      const weight = new Chart(this.ctx, {
        type: 'horizontalBar',
        data: {
          labels: pcsFaultArray,
          datasets: [
            { data: amountArray, label: 'Pcs', backgroundColor: '#040496' },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          display: true,
          labelString: 'Percentage',
          tooltips: {
            callbacks: {
              label: (tooltipItem: any, data: any) => {
                return data.datasets[0].data[tooltipItem.index] + '%';
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              padding: 20,
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                barThickness: 44,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  borderDash: [8, 4],
                },
                ticks: {
                  gridDashType: 'dash',
                  beginAtZero: true,
                  stepSize: 20,
                  min: 0,
                  max: 120,
                  callback(value: any, index: any): any {
                    if (value === 0) {
                      return 0 + '%';
                    } else {
                      return value + '%';
                    }
                  },
                },
              },
            ],
          },
        },
      });
    });
  }
  sizeToFit() {
    this.gridOptionsProcessControl.api.sizeColumnsToFit();
  }
  gridOptionsProcessControl: any = {
    defaultColDef: {
      // filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    autoGroupColumnDef: {
      headerName: 'Paramter Group',
      pinned: 'left',
      resizable: true,
    },
    groupDefaultExpanded: 1,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    frameworkComponents: {
      customTooltip: CustomTooltip,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
    },
  };
  onCellValueChanged(params: any): void {
    if (this.IsWrite) {
      const dataType = params.data.DataType;
      if (dataType === 'Date' || dataType === 'Bool') {
        if (dataType === 'Date') {
          const date = params.value;
          if (date.match(new RegExp('/', 'g')).length === 2) {
          } else {
            this.toaster.warning(
              'SlashError',
              'Please Select Correct Date Format'
            );
            return;
          }
        }
        const startIntervalDate = params.colDef.field.substring(0, 20);
        const endIntervalDate = params.colDef.field.substring(22, 41);
        if (new Date() > new Date(endIntervalDate)) {
          this.toaster.warning('Time Passed', 'Slot time has been passed');
          return;
        }
        let cellData: any;
        if (this.processGroupType === 'Time') {
          if (!params.oldValue || params.oldValue === '') {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: endIntervalDate,
              varId: params.data.parameterId,
              isOldValue: false,
              po: params.column.colId.split('~')[0],
              createdBy: JSON.parse(localStorage.user).UserId,
              prodId:
                params.column.colId.split('~prodId')[1] !== undefined
                  ? params.column.colId.split('~prodId')[1].split('~')[0]
                  : '',
              ppId:
                params.column.colId.split('~ppId')[1] !== undefined
                  ? params.column.colId.split('~ppId')[1].split('~')[0]
                  : '',
              uomId:
                params.column.colId.split('~uomId')[1] !== undefined
                  ? params.column.colId.split('~uomId')[1].split('~')[0]
                  : '',
              ppdId:
                params.column.colId.split('~ppdId')[1] !== undefined
                  ? params.column.colId.split('~ppdId')[1].split('~')[0]
                  : '',
              intervalType: this.processGroupType,
            };
          } else {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: endIntervalDate,
              varId: params.data.parameterId,
              endInterval: endIntervalDate,
              startInterval: startIntervalDate,
              isOldValue: true,
              po: params.column.colId.split('~')[0],
              createdBy: JSON.parse(localStorage.user).UserId,
              prodId:
                params.column.colId.split('~prodId')[1] !== undefined
                  ? params.column.colId.split('~prodId')[1].split('~')[0]
                  : '',
              ppId:
                params.column.colId.split('~ppId')[1] !== undefined
                  ? params.column.colId.split('~ppId')[1].split('~')[0]
                  : '',
              uomId:
                params.column.colId.split('~uomId')[1] !== undefined
                  ? params.column.colId.split('~uomId')[1].split('~')[0]
                  : '',
              ppdId:
                params.column.colId.split('~ppdId')[1] !== undefined
                  ? params.column.colId.split('~ppdId')[1].split('~')[0]
                  : '',
              intervalType: this.processGroupType,
            };
          }
        } else if (
          this.processGroupType === 'Event' ||
          this.processGroupType === 'Manual'
        ) {
          if (!params.oldValue || params.oldValue === '') {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: startIntervalDate,
              endInterval: startIntervalDate,
              startInterval: startIntervalDate,
              varId: params.data.parameterId,
              isOldValue: false,
              po: params.column.colId.split('~')[1],
              eventId: params.column.colId.split('~EventId')[1].split('~')[0],
              createdBy: JSON.parse(localStorage.user).UserId,
              intervalType: this.processGroupType,
            };
          } else {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: startIntervalDate,
              varId: params.data.parameterId,
              endInterval: startIntervalDate,
              startInterval: startIntervalDate,
              isOldValue: true,
              po: params.column.colId.split('~')[1],
              eventId: params.column.colId.split('~EventId')[1].split('~')[0],
              createdBy: JSON.parse(localStorage.user).UserId,
              intervalType: this.processGroupType,
            };
          }
        }
        this.processService.saveProessControl(cellData).subscribe(
          (response: any) => {
            const msg = response[0].result;
            if (msg !== null || msg !== '') {
              if (msg.substring(2, 1) === '0') {
                this.toaster.success('Success', msg.substring(3, msg.length));
                // params.node.setDataValue(params.colDef.field, newVal);
              } else {
                this.toaster.error('Error', msg.substring(3, msg.length));
                // params.node.setDataValue(params.colDef.field, params.oldValue);
              }
            }
          },
          (error: any) => {
            return this.toaster.error('Error', error.message);
          }
        );
      }
    }
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  gridOptionsQualityControl: any = {
    defaultColDef: {
      // filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    autoGroupColumnDef: {
      headerName: 'Paramter Group',
      pinned: 'left',
      resizable: true,
    },
    flex: 1,
    groupDefaultExpanded: 1,
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      customTooltip: CustomTooltip,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
      customLoadingCellRenderer: CustomLoadingCellRenderer,
    },
  };
  onQualityControlCellValueChanged(params: any): void {
    if (this.IsWrite) {
      const dataType = params.data.DataType;
      if (dataType === 'Bool' || dataType === 'Date') {
        let newVal = params.value;
        if (dataType === 'Int') {
          const charcter = this.isCharNumeric(newVal);
          if (!charcter) {
            this.toaster.warning('DigitsOnly', 'Please Select Digits Only');
            // newVal = params.oldValue;
            params.node.setDataValue(params.colDef.field, params.oldValue);
            return;
          }
        }
        if (dataType === 'Float') {
          const float = parseFloat(newVal);
          // tslint:disable-next-line:use-isnan
          if (isNaN(float)) {
            this.toaster.warning(
              'Decimal Only',
              'Please Select Decimal values Only'
            );
            // newVal = params.oldValue;
            params.node.setDataValue(params.colDef.field, params.oldValue);
            return;
          } else {
            params.value = parseFloat(params.value).toFixed(2);
          }
        }
        if (dataType === 'Date') {
          const date = params.value;
          if (date.match(new RegExp('/', 'g')).length === 2) {
          } else {
            this.toaster.warning(
              'SlashError',
              'Please Select Correct Date Format'
            );
            return;
          }
        }
        const startIntervalDate = params.colDef.field.substring(0, 20);
        const endIntervalDate = params.colDef.field.substring(23, 43);
        if (new Date() > new Date(endIntervalDate)) {
          this.toaster.warning('Time Passed', 'Slot time has been passed');
          return;
        }
        let cellData: any;
        if (this.qualityGroupType === 'Time') {
          if (!params.oldValue || params.oldValue === '') {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: endIntervalDate,
              endInterval: endIntervalDate,
              startInterval: startIntervalDate,
              varId: params.data.parameterId,
              isOldValue: false,
              cavity: params.data.Cavity,
              po: params.column.colId.split('~')[1],
              createdBy: JSON.parse(localStorage.user).UserId,
              prodId:
                params.column.colId.split('~prodId')[1] !== undefined
                  ? params.column.colId.split('~prodId')[1].split('~')[0]
                  : '',
              ppId:
                params.column.colId.split('~ppId')[1] !== undefined
                  ? params.column.colId.split('~ppId')[1].split('~')[0]
                  : '',
              uomId:
                params.column.colId.split('~uomId')[1] !== undefined
                  ? params.column.colId.split('~uomId')[1].split('~')[0]
                  : '',
              ppdId:
                params.column.colId.split('~ppdId')[1] !== undefined
                  ? params.column.colId.split('~ppdId')[1].split('~')[0]
                  : '',
              intervalType: this.qualityGroupType,
            };
          } else {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: endIntervalDate,
              varId: params.data.parameterId,
              endInterval: endIntervalDate,
              startInterval: startIntervalDate,
              isOldValue: true,
              cavity: params.data.Cavity,
              po: params.column.colId.split('~')[1],
              createdBy: JSON.parse(localStorage.user).UserId,
              prodId:
                params.column.colId.split('~prodId')[1] !== undefined
                  ? params.column.colId.split('~prodId')[1].split('~')[0]
                  : '',
              ppId:
                params.column.colId.split('~ppId')[1] !== undefined
                  ? params.column.colId.split('~ppId')[1].split('~')[0]
                  : '',
              uomId:
                params.column.colId.split('~uomId')[1] !== undefined
                  ? params.column.colId.split('~uomId')[1].split('~')[0]
                  : '',
              ppdId:
                params.column.colId.split('~ppdId')[1] !== undefined
                  ? params.column.colId.split('~ppdId')[1].split('~')[0]
                  : '',
              intervalType: this.qualityGroupType,
            };
          }
        } else if (
          this.qualityGroupType === 'Event' ||
          this.qualityGroupType === 'Manual'
        ) {
          if (!params.oldValue || params.oldValue === '') {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: startIntervalDate,
              endInterval: startIntervalDate,
              startInterval: startIntervalDate,
              varId: params.data.parameterId,
              isOldValue: false,
              cavity: params.data.Cavity,
              po: params.column.colId.split('~')[1],
              eventId: params.column.colId.split('~EventId')[1].split('~')[0],
              createdBy: JSON.parse(localStorage.user).UserId,
              intervalType: this.qualityGroupType,
            };
          } else {
            cellData = {
              result: params.value,
              entryOn: new Date(),
              resultOn: startIntervalDate,
              varId: params.data.parameterId,
              endInterval: startIntervalDate,
              startInterval: startIntervalDate,
              isOldValue: true,
              cavity: params.data.Cavity,
              po: params.column.colId.split('~')[1],
              eventId: params.column.colId.split('~EventId')[1].split('~')[0],
              createdBy: JSON.parse(localStorage.user).UserId,
              intervalType: this.qualityGroupType,
            };
          }
        }
        this.processService.saveQualityProcessControl(cellData).subscribe(
          (response: any) => {
            const msg = response[0].result;
            if (msg !== null || msg !== '') {
              if (msg.substring(2, 1) === '0') {
                this.toaster.success('Success', msg.substring(3, msg.length));
                this.gridApi.refreshCells(params);
              } else {
                this.toaster.error('Error', msg.substring(3, msg.length));
              }
            }
          },
          (error: any) => {
            return this.toaster.error('Error', error.message);
          }
        );
      }
    }
  }
  getPlants(): void {
    const key = {
      GroupId: this.groupDataForm.value.group,
    };
    this.usermanagementService.getDepartments(key).subscribe((res: any) => {
      this.Plants = res.DepartList;
      this.checklistDataForm.get('plant')?.setValue(this.Plants[0].DeptId);
    });
  }
  GetGroup(): void {
    const key = {
      Id: 0,
    };
    this.usermanagementService.getGroupData(key).subscribe((res: any) => {
      this.Groups = res.masterList;

      this.groupDataForm.get('group')?.setValue(res.masterList[0].TypeId);
      this.getPlants();
    });
  }
  getListType(): any {
    this.ChecklistService.getTypes().subscribe(
      (areadata: any) => {
        this.listTypeDDL = areadata;
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  getItemType(): any {
    this.ChecklistService.getItemTypes().subscribe(
      (areadata: any) => {
        this.itemTypeDDL = areadata;
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
        this.spinner.hide();
      }
    );
  }
  removeImagesColumns(name: string): void {
    const removeIndex = this.itemColumns.findIndex((i: any) => {
      return i === name;
    });
    if (removeIndex >= 0) {
      const good = this.itemColumns.splice(removeIndex, 1);
    }
  }
  getDataRowVisible(data: any): boolean {
    const groupRows = this.checkListAddModel.data.filter((row) => {
      if (!(row instanceof Group)) {
        return false;
      }
      let match = true;
      this.groupByColumns.forEach((column: any) => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }
  customFilterPredicate(data: any | Group, filter: string): boolean {
    return data instanceof Group ? data.visible : this.getDataRowVisible(data);
  }
  uniqueBy(a: any, key: any): any {
    const seen: any = {};
    return a.filter((item: any) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }
  getSublevel(
    data: any[],
    level: number,
    groupByColumns: string[],
    parent: Group
  ): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map((row) => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups: any[] = [];
    groups.forEach((group: any) => {
      const rowsInGroup = data.filter(
        (row) => group[currentColumn] === row[currentColumn]
      );
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(
        rowsInGroup,
        level + 1,
        groupByColumns,
        group
      );
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }
  timeDif(): any {
    const today = new Date();

    const startDate = moment(this.checklistDataForm.value.executionDate);
    const endDate = moment(today);
    const timeDiff = endDate.diff(startDate, 'hours');

    // if (timeDiff > 24 || timeDiff < -24) {
    //   this.toaster.warning(
    //     this.translater.instant('Error'),
    //     this.translater.instant('DateRangeSelect')
    //   );
    //   return;
    // }
  }
  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  shiftstarttime: any;
  shiftendtime: any;
  s: any;
  getDateShifts(): void {
    this.timeDif();
    const key = {
      Date: new Date(),
      PUID: this.machineId,
    };
    this.checklistDataForm.get('shift')?.reset();
    this.checklistDataForm.get('shiftName')?.reset();
    this.crewService.GetCrewScheduleList(key).then(
      (response: any) => {
        const date = new Date();
        this.shiftList = response.CrewScheduleList;

        // date.getTime() >= fromDate.getTime() &&
        // date.getTime() <= toDate.getTime()
        // tslint:disable-next-line:max-line-length
        const record = this.shiftList.filter(
          (c: any) =>
            date.getTime() >= new Date(c.ShiftStart).getTime() &&
            date.getTime() <= new Date(c.ShiftEnd).getTime()
        );
        if (record.length > 0) {
          this.checklistDataForm.get('shift')?.setValue(record[0].CrewId);
          this.checklistDataForm.get('shiftName')?.setValue(record[0].Shift);
          this.shiftstarttime = record[0].ShiftStart;
          this.shiftendtime = response.CrewScheduleList[0].ShiftEnd;
          this.Shift = record[0].CrewId;
        } else {
          this.toaster.warning(
            this.translater.instant('ShiftNotFound'),
            this.translater.instant('DefineShift'),
            {
              timeOut: 3000,
            }
          );
        }
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
        this.spinner.hide();
      }
    );
  }
  Chart(): any {
    //  var starttime = (new Date(this.shiftstarttime)).getTime();
    // var endTime = (new Date(this.shiftendtime)).getTime();
    this.options = {
      chart: {
        type: 'bar',
        backgroundColor: '#000',
        height: 150,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '21 days, 22:19:05',
        style: {
          color: '#fff',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
          color: '#F0F0F0',
        },
      },
      yAxis: {
        type: 'time',
        dateTimeLabelFormats: {
          day: '%H:%M',
        },

        labels: {
          formatter: () => {
            // let s = '';
            // this.points?.forEach((point: any) => {
            // this.starttime = this.starttime+900000;
            //   return (this.starttime/(1000*60*60))
            this.s = this.s + 900000;
            return Highcharts.dateFormat(
              '%H:%M',
              this.s
            );

            // });
            // return s;
          },
          style: {
            color: '#fff',
          },
        },
        opposite: true,
        // minRange: 900000,
        // plotLines: [
        //   {
        //     width: 2,
        //     value: this.s
        //   }
        // ]
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          // dataLabels: {
          //   enabled: true,
          //   formatter: () => {
          //     // if (series) {
          //     //   return '{series.name}';
          //     // }
          //   },
          // },
        },
      },
      series: [
        {
          name: 'Pause',
          data: [1900000],
          color: '#ffff00',
        },
        { name: 'Running', data: [3400000], color: '#14e85b' },
        { name: 'Downtime', data: [1600000], color: '#FF0000' },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
      },
    };
    // this.options = {
    //   chart: {
    //     type: 'bar',
    //     backgroundColor: '#000',
    //     height: 150,
    //   },
    //   credits: {
    //     enabled: false,
    //   },
    //   title: {
    //     text: '21 days, 22:19:05',
    //   },
    //   xAxis: {
    //     categories: ['Actual']
    //   },
    //   yAxis: {
    //     type: 'datetime',
    //     dateTimeLabelFormats: {
    //       day: '%H:%M'
    //     },
    //     labels: {
    //       formatter(): any {
    //         console.log('this.value:', this.shiftstart);
    //         return Highcharts.dateFormat('%H:%M', this.value + new Date().getTime());
    //         // return Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.value + 1428042300000);
    //       }
    //     },
    //     opposite: true,
    //     minRange: 5 * 60000,
    //     plotLines: [{
    //       color: '#FF0000',
    //       width: 2,
    //       // value: 8500000,
    //       label: { text: 'current time' }
    //     }]
    //   },
    //   tooltip: {
    //     formatter(): any {
    //       console.log('y;', this.y);
    //       return Highcharts.dateFormat('%H:%M:%S', this.y) + ': <b>' + this.series.name;
    //     }
    //   },
    //   legend: {
    //     enabled: false,
    //   },
    //   // legend: {
    //   //   reversed: true
    //   // },
    //   plotOptions: {
    //     series: {
    //       stacking: 'normal',
    //       dataLabels: { enabled: true, formatter(): any { if (this.y) { return this.series.name } } }
    //     }
    //   },
    //   series: [
    //     { "name": "Pause", "data": [1900000], color: '#ffff00' },
    //     { "name": "Running", "data": [3400000], color: '#14e85b' },
    //     { "name": "Downtime", "data": [1700000], color: '#FF0000' }
    //   ]
    // };
    this.chart = Highcharts.chart('container', this.options);
  }

  getCheckListById(element: any): void {
    const checkList = {
      checkListId: element.qualityCheckListId,
      unitId: this.unitId,
    };

    this.qualityexecutionService
      .getCheckListByIdForExecution(checkList)
      .subscribe(
        (response: any) => {
          this.checklistDataForm
            .get('group')
            ?.setValue(this.groupDataForm.get('group')?.value);
          this.checklistDataForm.get('plant')?.setValue(this.plantId);
          this.checklistDataForm
            .get('unitName')
            ?.setValue(this.groupDataForm.get('Unit')?.value);
          this.checklistDataForm
            .get('checktype')
            ?.setValue(response[0].listType);
          this.checklistDataForm
            .get('checkname')
            ?.setValue(response[0].listName);
          this.checklistDataForm
            .get('currentProcessOrder')
            ?.setValue(
              response[0].currentProcessOrder === null
                ? ''
                : response[0].currentProcessOrder
            );
          this.checklistDataForm
            .get('currentProcessOrderId')
            ?.setValue(response[0].currentProcessOrderId);
          this.isAuto = response.currentProcessOrder;
          this.valueCheck = this.checklistDataForm.value.currentProcessOrderId;
          if (this.valueCheck) {
            this.isAuto = true;
          } else {
            this.isAuto = false;
          }

          this.checklistDataForm
            .get('currentProductName')
            ?.setValue(
              response[0].currentProduct === null
                ? ''
                : response[0].currentProduct
            );
          this.preCheck = this.checklistDataForm.value.currentProductName;
          if (this.preCheck) {
            this.preAuto = true;
          } else {
            this.preAuto = false;
          }
          this.checklistDataForm
            .get('previousProcessOrder')
            ?.setValue(
              response[0].prevProcessOrder === null
                ? ''
                : response[0].prevProcessOrder
            );
          this.checklistDataForm
            .get('previousProcessOrderId')
            ?.setValue(response[0].prevProcessOrderId);
          this.valueCheck2 =
            this.checklistDataForm.value.previousProcessOrderId;
          if (this.valueCheck2) {
            this.isAuto2 = true;
          } else {
            this.isAuto2 = false;
          }

          this.checklistDataForm
            .get('previousProductName')
            ?.setValue(
              response[0].prevProduct === null ? '' : response[0].prevProduct
            );
          this.preCheck2 = this.checklistDataForm.value.previousProductName;
          if (this.preCheck2) {
            this.preAuto2 = true;
          } else {
            this.preAuto2 = false;
          }
          // this.getDateShifts();
          this.checklistDataForm.get('area')?.setValue(element.areaName);
          response.forEach((ele: any) => {
            const row = {
              id: this.counter,
              item: ele.itemName,
              itemtype: ele.itemType,
              drop: ele.itemGroup,
              checkListDetailId: ele.checkListDetailId,
              remarks: '',
              score: 0,
              catType: '',
              goodImage: ele.goodExampleImage,
              badImage: ele.badExampleImage,
            };
            this.addtabledata.push(row);
            const f = new File([''], 'filename');
            this.goodImgArray.push(f);
          });

          response.forEach((item: any, index: any) => {
            item.id = index + 1;
            item.remarks = '';
            item.score = '';
            item.catType = '';
          });

          this._alldata = response;
          this.checkListAddModel.data = this.addGroups(
            this._alldata,
            this.groupByColumns
          );
          this.checkListAddModel.filterPredicate =
            this.customFilterPredicate.bind(this);
          this.checkListAddModel.filter = performance.now().toString();

          const goodImage = response.filter((element: any) => {
            if (element.goodExampleImage.split('/')[5]?.length > 0) {
              return element;
            }
          });
          if (goodImage.length > 0) {
            this.GoodExampleImage = true;
          } else {
            this.removeImagesColumns('good');
          }
          const badImage = response.filter((element: any) => {
            if (element.badExampleImage.split('/')[5]?.length > 0) {
              return element;
            }
          });
          if (badImage.length > 0) {
            this.BadExampleImage = true;
          } else {
            this.removeImagesColumns('bad');
          }
        },
        (error: any) => {
          this.toaster.error(
            this.translater.instant('Error'),
            this.translater.instant('WentWrong')
          );
        }
      );
  }
  resetForm(): void {
    this.checklistDataForm.reset();
    this.dialog.closeAll();

    this.addtabledata = [];
    this.addtabledata.length = 0;
  }
  sendNotification(): void {
    const key = {
      roleName: 'Line Leader',
      createdBy: JSON.parse(localStorage.user).UserId,
      egId: this.checklistDataForm.value.group,
      title: 'CheckList Execution',
      desc:
        'Execution has been created in check list ' +
        this.checklistDataForm.value.checkname +
        ' by ' +
        JSON.parse(localStorage.user).UserName,
    };
    this.notificationService.addnotification(key).subscribe(
      (response: any) => { },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  getAllQualityCheckList(): void {
    const key = {
      plantId: this.plantId,
      puId: this.unitId,
    };
    if (this.executeRole === true) {
      this.hidetrue = true;
    } else {
      this.hidetrue = false;
    }
    this.qualityexecutionService.getCheckList(key).subscribe(
      (checklist: any) => {
        this.customerManagementDataSource.data = checklist;
        this.getPaginator(this.customerManagementDataSource, 3);
      },
      (error: any) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  addCheckListExecution(): void {
    const result = this._alldata;
    const executionDetails: any[] = [];
    result.forEach((element, i) => {
      const v = {
        checkListDetailId: element.checkListDetailId,
        remarks: element.remarks,
        acutalValue: element.score,
        goodImage: element.goodImage,
        badImage: element.badImage,
        itemTypeId: element.itemType,
        catType:
          element.itemType !== 4
            ? element.catType
            : this.goodImgArray[i].name === 'filename'
              ? ''
              : this.goodImgArray[i].name,
      };
      executionDetails.push(v);
    });
    const found = executionDetails.some((el) => el.catType !== '');
    if (!found) {
      this.toaster.warning(this.translater.instant('SelectAnyValue'));
      return;
    }
    const formdata = new FormData();
    for (let index = 0; index < this.goodImgArray.length; index++) {
      formdata.append('goodImages', this.goodImgArray[index]);
    }
    const key = {
      userId: JSON.parse(localStorage.user).UserId,
      checkListId: this.checklistId,
      execDate: this.checklistDataForm.get('executionDate')?.value,
      shift: this.checklistDataForm.get('shift')?.value,
      unitId: this.unitId,
      processOrder: this.checklistDataForm
        .get('currentProcessOrder')
        ?.value.toString(),
      previousProcessOrder:
        this.isAuto2 === false
          ? this.checklistDataForm.get('previousProcessOrder')?.value
          : this.checklistDataForm.get('previousProcessOrderId')?.value,
      productName: this.checklistDataForm.get('currentProductName')?.value,
      previousProductName: this.checklistDataForm.get('previousProductName')
        ?.value,
      executionDetails,
    };
    formdata.append('checkListexecution', JSON.stringify(key));
    this.qualityexecutionService.addCheckListExecution(formdata).subscribe(
      (response) => {
        this.dialog.closeAll();
        this.toaster.success(
          this.translater.instant('Success'),
          this.translater.instant('AuditExecution') +
          this.translater.instant('AddedSuccessfully')
        );
        this.sendNotification();
        this.resetForm();

        this.getAllQualityCheckList();
      },
      (error) => {
        this.toaster.error(
          this.translater.instant('Error'),
          this.translater.instant('WentWrong')
        );
      }
    );
  }
  fillLarge() {
    this.setWidthAndHeight('100%', '100%');
  }
  setWidthAndHeight(width: any, height: any) {
    this.style = {
      width: width,
      height: height,
    };
  }
  OpenEditCheckList(listId: any, checklistExecutionModal: any): void {
    this.shiftName = null;
    this.checklistDataForm.get('shift')?.reset();
    this.checklistId = listId;
    this.itemColumns = ['items', 'good', 'bad', 'score', 'remarks'];

    this.checklistId = listId;
    this.GetGroup();
    this.getListType();
    this.getItemType();
    this.getCheckListById(listId);

    this.resetForm();
    this.goodImgArray = [];
    this.dialog.open(checklistExecutionModal, {
      width: '90vw !important',
      height: '90vh',
      minWidth: '90vw',
    });
    const d = new Date();
    this.checklistDataForm.get('executionDate')?.setValue(d);
    this.formMode = false;
  }

  // CIL Management Functions Starts Here

  public checkErrorType = (controlName: string, errorName: string) => {
    return this.performCILItemForm.controls[controlName].hasError(errorName);
  };

  opensideMachineModal(): void {
    this.formMode = true;

    this.displayMAchineModal = true;
    // this.GetMachineModelTreeForCIL();
    this.getUnitTreeModal();
  }

  opensideComponentModal(): void {
    this.formMode = true;
    this.displayComponentModal = true;
    this.onRemove(this.file);
    this.GetCompModelTreeForCIL();
  }

  openActionModal(): void {
    this.formMode = true;
    this.onRemove(this.file);
    this.displayActionModal = true;
  }
  closeActionModal(): void {
    this.displayActionModal = false;
  }

  closeaddNewPlantItemModel(): void {
    this.displayMAchineModal = false;
    this.displayComponentModal = false;
    this.displayUserModal = false;
  }

  closeaddNewVariableModel(): void { }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  OnChangeGroup(element: any): void {
    this.selectedGroup = element.value;
    const key = {
      EGId: this.selectedGroup, // 10, //this.CilManagDataForm.value.group,
      Level: 'Unit',
      IsOptional: 0,
      CreatedBy: 0,
    };
    this.cilService.GetTreeModal(key).subscribe(
      (res: any) => {
        this.machineList = res.TreeModalList;
        if (
          res.TreeModalList.Level === 'Unit' &&
          res.TreeModalList.level.length()
        ) {
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );

    // this.GetPlantByEgDDL();
  }
  GetPlantByEgDDL(): void {
    const key = {
      GroupId: this.selectedGroup,
    };

    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.CilManagDataForm.get('plant')?.setValue(data.DepartList[0].DeptId);
        this.getUnits();
        // this.getDefectMgmtTableData();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getUnits(): void {
    const key = {
      ptId: this.CilManagDataForm.value.plant,
      userId: JSON.parse(localStorage.user).UserId,
    };

    this.machineService.getUnitbyUser(key).subscribe(
      (data: any) => {
        this.units = data;
        this.filteredUnits = this.units.slice();
        this.CilManagDataForm.get('machine')?.setValue(data[0].puId);
        this.startTime = new Date();
        this.getComponents();
        // this.getDefectMgmtTableData();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getComponents(): void {
    const key = {
      puId: this.CilManagDataForm.value.machine,
    };

    this.machineService.getComponentbyUnit(key).then(
      (data: any) => {
        this.components = data;
        this.filteredcomponents = this.components.slice();
        this.CilManagDataForm.get('component')?.setValue(data[0].ComponentID);

        this.getDefectMgmtTableData();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getUnitTreeModal(): void {
    const key = {
      EGId: this.selectedGroup, // 10, //this.CilManagDataForm.value.group,
      Level: 'Unit',
      IsOptional: 0,
      CreatedBy: 0,
    };
    this.cilService.GetTreeModal(key).subscribe(
      (res: any) => {
        this.machineList = res.TreeModalList;
        const TreeData: PlantGroupElement[] = res.TreeModalList;
        this.machineTreeSource.data = TreeData;

        this.treeControl.dataNodes = this.machineTreeSource.data;
        this.flattenArr = flatten(TreeData);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  performCILItemFormSubmit(): void {
    //
  }

  getDefectMgmtTableData(): void {
    this.spinner.show();
    this.selection.clear();
    const key = {
      egId: this.selectedGroup,
      plantId: this.CilManagDataForm.value.plant,
      compId: this.CilManagDataForm.value.component,
      machineId: this.CilManagDataForm.value.machine,
    };
    this.cilService.GetCILData(key).then(
      (res: any) => {
        this.cilManagementVariableGroupData =
          new MatTableDataSource<CILManagementElement>(res.GetCILList);
        this.getPaginator(this.cilManagementVariableGroupData, 5);
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      },
      (error: any) => {
        throw error;
      }
    );
    this.selection.selected.length = 0;
    this.isButtonEnable = true;
    // this.masterToggle();
  }

  GetCompModelTreeForCIL(): void {
    const key = {
      ParentId: this.selectedGroup,
      plantId: this.CilManagDataForm.get('plant')?.value,
      machineId: this.CilManagDataForm.value.machine,
    };

    this.cilService.GetCompModelTreeForCIL(key).subscribe(
      (res: any) => {
        this.componentList = res.Tree;
        const TreeData: PlantGroupElement[] = res.Tree;
        this.compTreeSource.data = TreeData;
        this.treeControlComponent.dataNodes = this.compTreeSource.data;
        // this.treeControl.dataNodes = this.compTreeSource.data
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  GetMachineModelTreeForCIL(): void {
    const key = {
      ParentId: this.selectedGroup,
      plantId: 1,
    };
    this.cilService.GetMachineModelTreeForCIL(key).subscribe(
      (res: any) => {
        this.machineList = res.Tree;
        const TreeData: PlantGroupElement[] = res.Tree;
        this.machineTreeSource.data = TreeData;
        this.treeControl.dataNodes = this.machineTreeSource.data;
        // this.treeControl.dataNodes = this.compTreeSource.data
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  SelectComponent(component: any): void {
    if (component.text !== null) {
      this.CilManagDataForm.get('component')?.setValue(component.text);
      this.componentId = component.id;
      this.displayComponentModal = false;
      this.getDefectMgmtTableData();
    }
  }

  SelectNodeName(line: any): any { }

  SelectNodeNameForComp(line: any): any {
    if (line.Level === 'Component') {
      this.selectedComponent = line.SelectNodeNameForComp;
    }
  }

  onNodeSelectForComp(node: any): void {
    //
    if (node.Level === 'Component') {
      //
    }
  }

  // collapse group view

  collapseAllNodeComp(): void {
    this.treeControl.collapseAll();
  }

  expandAllNodeComp(): void {
    this.treeControl.expandAll();
  }

  // open edit CIL variable group model
  openEditCILGroupModel(element: CILManagementElement): void {
    // this.editGroupItemParent = element;
  }
  openCILExecformModel(): void {
    this.formMode = true;
    this.performCILItemForm.reset();
    this.isEditCILModelVisible = true;
    const t = new Date();

    this.performCILItemForm.get('endDate')?.setValue(t);
    this.performCILItemForm.get('startDate')?.setValue(t);
    this.performCILItemForm.get('postImage')?.reset();
    //  if(this.CilManagDataForm.get('startDate')?.value !== null){
    //    this.hidesidePanel = true
    //  }else{
    //    this.hidesidePanel = false
    //  }
    //  this.selectedTaskId = element.id;
    //  const showInput = false;
    //   this.hideImage = showInput;
  }

  openCILPerformModel(element: CILManagementElement): void {
    this.performCILItemForm.reset();
    this.formMode = false;
    this.isEditCILModelVisible = true;
    this.selectedTaskId = element.id;
    this.selectedTaskComp = element.componentId;
    this.onRemove(this.file);
    const t = new Date();
    this.performCILItemForm.get('endDate')?.setValue(t);
    this.performCILItemForm.get('startDate')?.setValue(t);
    this.performCILItemForm.get('postImage')?.reset();
    if (this.CilManagDataForm.get('machine')?.value === '') {
      this.toaster.error('please select machine first');
      this.isEditCILModelVisible = false;
    }
  }
  saveCilAction(): void {
    const arr: any[] = [];
    const data = this.selection.selected.filter(
      (c: any) => c.submitted === false
    );
    data.forEach((element) => {
      const si = {
        taskId: element.id, // this.taskForm.value.plantId,
        rbDone: this.performCILItemForm.value.option,
        startTime: this.performCILItemForm.value.startDate,
        endTime: new Date(), // this.performCILItemForm.value.endDate,
        userId: JSON.parse(localStorage.user).UserId,
        remarks: this.performCILItemForm.value.Remarks,
        componentId: this.CilManagDataForm.value.component,
        taskImage: '',
        taskImageName: '',
      };
      arr.push(si);
    });
    this.cilService.performCilAction(arr).subscribe(
      (res: any) => {
        this.toaster.success('Action Saved Successfully');
        this.selection.clear();
        this.getDefectMgmtTableData();
        this.isEditCILModelVisible = false;
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  SavePerformTask(): void {
    const formData: any = new FormData();
    formData.append('Imagefile', this.file);
    formData.append(
      'obj',
      JSON.stringify({
        taskId: this.selectedTaskId, // this.taskForm.value.plantId,
        rbDone: this.performCILItemForm.value.option,
        startTime: this.performCILItemForm.value.startDate,
        endTime: new Date(), // this.performCILItemForm.value.endDate,
        userId: JSON.parse(localStorage.user).UserId,
        remarks: this.performCILItemForm.value.Remarks,
        componentId: this.CilManagDataForm.value.component,
        taskImage: '',
        taskImageName: '',
      })
    );
    this.spinner.show();
    this.cilService.PerformCilTask(formData).subscribe(
      (res: any) => {
        if (res.HasError === false) {
          this.toaster.success('Task Performed Successfully');
          this.selection.clear();
          this.closeCILPerformModel();
          this.performCILItemForm.reset();
          this.getDefectMgmtTableData();
          this.spinner.hide();
        } else {
          this.toaster.error('error', res.ErrorMessage);
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  onFileSelect(event: any): void {
    this.file = undefined;
    this.file = event.addedFiles[0];
  }
  onRemove(event: any): void {
    this.file = undefined;
  }
  getEndTime(): void {
    const d = new Date();
  }

  // close machine parameter variable  model
  closeCILPerformModel(): void {
    this.isEditCILModelVisible = false;
    this.performCILItemForm.reset();
    // this.addNewVariableItemlForm.reset();
  }

  reset(): void {
    this.CilManagDataForm.reset();
    this.getDefectMgmtTableData();
  }

  deleteCIL(element: any): void { }

  // onFileSelect(input: any): void {
  //   const file = input.files[0];
  //   this.uploadFileInfo = `${file.name}`;
  // }

  isAllSelected(): any {
    if (this.selection.selected.length > 1) {
      this.show = true;
    } else {
      this.show = false;
    }

    const numSelected = this.selection.selected.length;
    const numRows = this.cilManagementVariableGroupData.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected()
      ? (this.selection.clear(), this.checkBoxCount())
      : this.cilManagementVariableGroupData.data.forEach((row) =>
        this.selection.select(row)
      );
  }

  checkBoxCount(): void {
    this.show = false;
  }

  // CIL Management Ends Here
}
const tooltipValueGetter = (params: any) => ({ value: params.value });
class Group {
  [key: string]: any;
  level = 0;
  parent!: Group; // =new Group();
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}
