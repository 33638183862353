<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ac-center p-ai-center">
      <mat-card-title class="p-ml-2">{{langObj ? langObj?.AnalyticalTools : (' AnalyticalTools' | translate)}}</mat-card-title>
      <div class="" *ngIf="toolData !== undefined">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event)" placeholder="{{langObj ? langObj?.Search : (' Search' | translate)}}" autocomplete="off">
        </mat-form-field>
      </div>
      <div class="" *ngIf="toolData !== undefined">
        <button *ngIf="IsWrite" (click)="openModal()" class="icon-btn" pTooltip="Add Tool" tooltipPosition="top">
          <img src="assets/images/add.png" alt="" width="20px" height="20px">
        </button>
        <!-- <button mat-flat-button class="btn-add-item" *ngIf="IsWrite" (click)="openModal()" pTooltip="Add Tool" tooltipPosition="top">
          <span class="btn-icon">
            <svg width="40" height="36" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.18143 5.4235H5.6525V8.9235H4.47619V5.4235H0.947266V4.25684H4.47619V0.756836H5.6525V4.25684H9.18143V5.4235Z"
                fill="white" />
            </svg>
          </span>
        </button> -->
      </div>            
  </div>
  <mat-card-content>
    <ngx-spinner
        [fullScreen]="false"
        bdColor="#fff"
        size="medium"
        color="#050a4f"
        type="ball-clip-rotate-multiple"
        >
        <p style="font-size: 20px; color: #050a4f">{{langObj ? langObj?.FetchingToolsData : (' FetchingToolsData' | translate)}}</p>
    </ngx-spinner>
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1" *ngIf="toolData !== undefined">
      <table mat-table [dataSource]="toolData" class="basic-table plant-model-table" matSort>
        <!-- <ng-container matColumnDef="toolId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text">Tool ID</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.toolId}} </td>
        </ng-container> -->
        <ng-container matColumnDef="plant">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">
              {{langObj ? langObj?.Plant : (' Plant' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.plant}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">
              {{langObj ? langObj?.Tool : (' Tool' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>  
        <ng-container matColumnDef="filePath">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.Download : (' Download' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <a href="{{element.fullPath}}" *ngIf="element.filename !== null" [download]>           
              <svg width="25" height="25" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M409.6,153.6h-85.333c-9.426,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067H409.6
                        c9.426,0,17.067,7.641,17.067,17.067v221.867c0,9.426-7.641,17.067-17.067,17.067H68.267c-9.426,0-17.067-7.641-17.067-17.067
                        V204.8c0-9.426,7.641-17.067,17.067-17.067H153.6c9.426,0,17.067-7.641,17.067-17.067S163.026,153.6,153.6,153.6H68.267
                        c-28.277,0-51.2,22.923-51.2,51.2v221.867c0,28.277,22.923,51.2,51.2,51.2H409.6c28.277,0,51.2-22.923,51.2-51.2V204.8
                        C460.8,176.523,437.877,153.6,409.6,153.6z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M335.947,243.934c-6.614-6.387-17.099-6.387-23.712,0L256,300.134V17.067C256,7.641,248.359,0,238.933,0
                        s-17.067,7.641-17.067,17.067v283.068l-56.201-56.201c-6.78-6.548-17.584-6.361-24.132,0.419c-6.388,6.614-6.388,17.1,0,23.713
                        l85.333,85.333c6.657,6.673,17.463,6.687,24.136,0.03c0.01-0.01,0.02-0.02,0.031-0.03l85.333-85.333
                        C342.915,261.286,342.727,250.482,335.947,243.934z"/>
                    </g>
                  </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g>
                  </g><g></g><g></g>
                </svg>
            </a>
          </td>
        </ng-container>  
        <ng-container matColumnDef="toolActions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-text">{{langObj ? langObj?.Actions : (' Actions' | translate)}}</p>

          </th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <button mat-icon-button *ngIf="IsWrite" class="edit-btn" aria-label="Edit Row" 
                    (click)="getOneTool(element.toolId)" pTooltip="Edit Tool" tooltipPosition="top">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button *ngIf="IsWrite" class="node-control-btn node-delete" aria-label="delete item"
                    (click)="deleteTool(element.toolId)" pTooltip="Delete Tool" tooltipPosition="top">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                        fill="white" />
                    </svg>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="toolColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: toolColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{langObj ? langObj?.Nodatamatching : (' Nodatamatching' | translate)}}</td>
        </tr>
      </table>  
      <mat-paginator 
        class="basic-paginataor" 
        [pageSizeOptions]="[2, 5, 10, 20]"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </mat-card-content>    
</mat-card>

<div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{'active': toolModelVisible}">
  <div class="sidepanel-close" (click)="closeToolModel()"></div>
  <div class="model-content">
    <div class="model-top">
      <h2 class="model-title">{{formMode ? 'Add' : 'Update'}} {{langObj ? langObj?.AnalyticalTool : (' AnalyticalTool' | translate)}}</h2>
      <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeToolModel()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
            fill="black" />
        </svg>
      </button>
    </div>
    <div class="model-body">
      <form [formGroup]="toolForm" (ngSubmit)="formMode ? submitForm() : updateForm()">
        <div class="form-group">
          <mat-form-field [ngClass]="{
            'is-invalid':
              toolForm.get('plant')?.errors &&
              toolForm.get('plant')?.touched
            }" appearance="fill" class="field-fill">
            <mat-label>{{langObj ? langObj?.Plant : (' Plant' | translate)}}</mat-label>
            <mat-select formControlName="plant" [disabled]="true">                
              <mat-option *ngFor="let plant of plants" [value]="plant.ptId">{{plant.ptDesc}}</mat-option>             
            </mat-select>
          </mat-form-field>
          <p  class="invalid-feedback" *ngIf="
            toolForm.get('plant')?.touched &&
            toolForm.get('plant')?.hasError('required')
          ">
            * {{langObj ? langObj?.pleaseselectplant : (' pleaseselectplant' | translate)}}
          </p>
        </div>
        <div class="form-group">
          <mat-form-field  [ngClass]="{
            'is-invalid':
              toolForm.get('toolname')?.errors &&
              toolForm.get('toolname')?.touched
          }" appearance="fill" class="field-fill">
            <mat-label>{{langObj ? langObj?.ToolName : (' ToolName' | translate)}}</mat-label>
            <input matInput placeholder="enter tool name" formControlName="toolname">
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
              toolForm.get('toolname')?.touched &&
              toolForm.get('toolname')?.hasError('required')">
            * {{langObj ? langObj?.toolnameisrequired : (' toolnameisrequired' | translate)}}
            </p>
            <p class="invalid-feedback" *ngIf="
              toolForm.get('toolname')?.touched &&
              toolForm.get('toolname')?.hasError('pattern')">
            * {{langObj ? langObj?.pleaseremovespecialcharacters : (' pleaseremovespecialcharacters' | translate)}}
            </p>
        </div>
        <div class="form-group">
          <div class="field-fill">
            <mat-label>{{langObj ? langObj?.FileUpload : (' FileUpload' | translate)}}</mat-label>
            <input type="file" (change)="uploadedFile($event)" formControlName="uploadFile" class="form-control" accept=".xlsx, .xls">
          </div>
        </div>
        <div class="model-form-actions">
          <button   type="button" class="icon-btn" style="margin-left: 10px;" pTooltip="Cancel" tooltipPosition="top" (click)="closeToolModel()" >
            <img src="assets/images/cancel.png" alt="" width="20px" height="20px">
          </button>
          <button   type="submit" class="icon-btn" style="margin-left: 10px;" pTooltip="Update" tooltipPosition="top" *ngIf="!formMode">
            <img src="assets/images/edit.png" alt="" width="20px" height="20px">
          </button>
          <button   type="submit" class="icon-btn" style="margin-left: 10px;" pTooltip="Save" tooltipPosition="top" *ngIf="formMode"[disabled]="!toolForm.valid">
            <img src="assets/images/save.png" alt="" width="20px" height="20px">
          </button>

          <!-- <button type="button" mat-flat-button class="btn-cancel" (click)="closeToolModel()"> Cancel
          </button>
          <button mat-flat-button color="accent" [disabled]="!toolForm.valid" class="btn-accent"> {{formMode ? 'Add' : 'Update'}} </button> -->
        </div>
      </form>
    </div>
  </div>
</div>