import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KPIMilestoneService {
  o3CoreUrl = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) {}

  SaveAddMilestoneTargetMaster(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreUrl + 'MilestoneKPITarget/SaveAddMilestoneTargetMaster',
      data
    );
  }
  SaveAddMilestoneTargetDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreUrl + 'MilestoneKPITarget/SaveAddMilestoneTargetDetail',
      data
    );
  }
  GetMilestoneTargetMaster(data): Observable<any[]> {
    return this.http.post<any[]>(
      this.o3CoreUrl + 'MilestoneKPITarget/GetMilestoneTargetMaster',
      data
    );
  }

  UpdateMilestoneTargetMaster(data): Observable<any> {
    return this.http.post(
      this.o3CoreUrl + 'MilestoneKPITarget/UpdateMilestoneTargetMaster',
      data
    );
  }

  UpdateMilestoneTargetDetail(data): Observable<any> {
    return this.http.post(
      this.o3CoreUrl + 'MilestoneKPITarget/UpdateMilestoneTargetDetail',
      data
    );
  }
  DeleteMilestoneTargetMaster(data): Observable<any> {
    return this.http.post(
      this.o3CoreUrl + 'MilestoneKPITarget/DeleteMilestoneTargetMaster',
      data
    );
  }

  ManuallyAddKPIToMilestone(data): Observable<any> {
    return this.http.post(
      this.o3CoreUrl + 'MilestoneKPITarget/AddKPIManually',
      data
    );
  }

  DeleteMilestoneKPI(data): Observable<any> {
    return this.http.post(
      this.o3CoreUrl + 'MilestoneKPITarget/DeleteMilestoneKPI',
      data
    );
  }
  UpdateMilestoneKPITarget(data): Observable<any> {
    return this.http.put(
      this.o3CoreUrl + 'MilestoneKPITarget/UpdateMilestoneKPITarget',
      data
    );
  }
}
