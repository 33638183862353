import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  O3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }
 
  GetCurrencyData(): Observable<any[]> {
    // return this.http.get<any[]>(this.baseUrl + 'countries');
    return this.http.get<any[]>(this.O3CoreApiUrl + 'currency/GetCurrency');
  }
}
