import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TreeModule } from 'primeng/tree';
import { DropdownModule } from 'primeng/dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from 'src/app/material-module';

import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HighchartsChartModule } from 'highcharts-angular';

import { AgGridModule } from 'ag-grid-angular';
import { MatSelectFilterModule } from 'mat-select-filter';

import { TooltipModule } from 'primeng/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HomeRoutingModule } from '../screens/home-routing.module';
import { ProjectComponent } from './project/project.component';
import { TaskComponent } from './Task/Task.component';
import { TopicComponent } from './Topic/Topic.component';
import { MasterOleComponent } from './Master-ole/Master-ole.component';
import { OLERoutingModule } from './ole-routing.module';
import { FTEComponent } from './FTE/FTE.component';
import { StaffAttendenceComponent } from './staff-attendence/staff-attendence.component';
import { FTEDashboardComponent } from './FTE-Dashboard/FTE-Dashboard.component';
import { MicroLevelSaturationComponent } from './micro-level-saturation/micro-level-saturation.component';
import { MicrotaskComponent } from './microtask/microtask.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';




@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    NgApexchartsModule,
    HomeRoutingModule,
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    AgGridModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    TreeModule,
    DropdownModule,
    NgxSpinnerModule,
    TooltipModule,
    MatTooltipModule,
    HighchartsChartModule,
    OLERoutingModule,
    MatSelectFilterModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NgxMatTimepickerModule
  ],
  declarations: [

    ProjectComponent,
    TaskComponent,
    TopicComponent,
    MasterOleComponent,
    FTEComponent,
    StaffAttendenceComponent,
    FTEDashboardComponent,
    MicroLevelSaturationComponent,
    MicrotaskComponent

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OLEModule { }
