import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OpmsQualityService {

  baseUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  deleteChemicalData(data: any): Observable<any> {
    return this.http.post<any[]>(
      this.baseUrl + 'DRS/DeleteMaterialEntries',
      data
    );
  }

  getMaterialsForChemical(data: any): Observable<any> {
    return this.http.post<any[]>(
      this.baseUrl + 'DRS/GetMaterialsForAnalysis',
      data
    );
  }

  getChemicalMaterialProperties(data: any): Observable<any> {
    return this.http.post<any[]>(
      this.baseUrl + 'DRS/GetMaterialProperties',
      data
    );
  }

  GetMaterialEntries(data: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + 'DRS/GetMaterialEntries', data);
  }

  getReport(data: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + 'DRS/GetReport', data);
  }

  insertChemicalData(data: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + 'DRS/InsertMaterialData', data);
  }

  updateChemicalData(data: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + 'DRS/UpdateMaterialData', data);
  }
}
