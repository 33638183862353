import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CcrDashboardService {
  baseUrl: string = environment.O3CoreApiUrl;
  kpibaseUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) {}

  getCcrGapAnalysis(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'ccrgapanalysis', data);
  }
  getNCrGapAnalysis(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'ncrgapanalysis', data);
  }
  getNcrOpenVsClosed(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'ncropenvsclosed', data);
  }
  getCCrOpenVsClosed(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'ccropenvsclosed', data);
  }
  getCCrConcern(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'ccrcomplaintvsconcern', data);
  }
  getCCrByProduct(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'ccrbyproduct', data);
  }
  getNcrByProduct(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'ncrbyproduct', data);
  }
  getNcrNumber(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'ncrnumber', data);
  }
  getCCrNumber(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'ccrnumber', data);
  }
}
