import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  NgxMatDateAdapter,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { TreeModule } from 'primeng/tree';
import { BtnCellRenderer } from '../btn-cell-renderer.component';
import { BtnCellRendererActualEntry } from '../btn-cell-rendererActualEntry.component';
import { CustomDateComponent } from '../date-components/custom-date-component.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { TreeTableModule } from 'primeng/treetable';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmationService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { MaterialModule } from 'src/app/material-module';
import { KPIRoutingModule } from './kpi-route.module';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { ManualactualentryComponent } from './manualactualentry/manualactualentry.component';
import { KpitreedefinationComponent } from './kpitreedefination/kpitreedefination.component';
import { LossanalysisComponent } from './lossanalysis/lossanalysis.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { RacimatrixComponent } from './racimatrix/racimatrix.component';
import { YearlyscorecardComponent } from './yearlyscorecard/yearlyscorecard.component';
import { KPITargetReportComponent } from './KPITargetReport/KPITargetReport.component';
import { IndividualPerformanceEvaluationComponent } from './individual-performance-evaluation/individual-performance-evaluation.component';
import { KpimasterComponent } from './kpimaster/kpimaster.component';
import { PillarsComponent } from './KpiMasterComponents/pillars/pillars.component';
import { DriversComponent } from './KpiMasterComponents/drivers/drivers.component';
import { ToolsComponent } from './KpiMasterComponents/tools/tools.component';
import { FrequencyComponent } from './KpiMasterComponents/frequency/frequency.component';
import { TrendsComponent } from './KpiMasterComponents/trends/trends.component';
import { UomComponent } from './KpiMasterComponents/uom/uom.component';
import { DepartmentsComponent } from './KpiMasterComponents/departments/departments.component';
import { MastershiftComponent } from './KpiMasterComponents/mastershift/mastershift.component';
import { DayoffComponent } from './KpiMasterComponents/dayoff/dayoff.component';
import { KpireasonsComponent } from './KpiMasterComponents/kpireasons/kpireasons.component';
import { FourmComponent } from './KpiMasterComponents/fourm/fourm.component';
import { KpimasterweightComponent } from './KpiMasterComponents/kpimasterweight/kpimasterweight.component';
import { IpeMasterDataComponent } from './KpiMasterComponents/ipe-master-data/ipe-master-data.component';
import { IndividualPerformanceEvaluationUserComponent } from './individual-performance-evaluation-user/individual-performance-evaluation-user.component';
import { KPIIntegartionComponent } from './KPIIntegartion/KPIIntegartion.component';
import { KpiDataPointsComponent } from './KpiMasterComponents/Kpi-Data-Points/Kpi-Data-Points.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';
import { KpiTreeVersionComponent } from './KpiTreeVersion/KpiTreeVersion.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DatepickerService } from '../services/Datepicker.service';
import { KpiWiseAnalysisReportComponent } from './KpiWiseAnalysisReport/KpiWiseAnalysisReport.component';
import { KPISplitViewComponent } from './KPISplitView/KPISplitView.component';
import { KPIGridViewComponent } from './KPIGridView/KPIGridView.component';
import { BtnCellRendererKPIGridView } from '../btn-cell-renderer-BtnCellRendererKPIGridView';
import { KpiRefreshComponent } from './kpi-refresh/kpi-refresh.component';
import { KpihierarchymasterComponent } from './kpihierarchymaster/kpihierarchymaster.component';
import { TranslateModule } from '@ngx-translate/core';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import { highchartsModules } from '../app.module';
import { NZ_DATE_CONFIG, NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import { HomeModule } from '../screens/home.module';
import { NgxMatSelectModule } from 'ngx-mat-select';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);
@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    CdkTreeModule,
    TranslateModule.forChild({
      extend: true,
    }),
    DragDropModule,
    MatChipsModule,
    NgxSpinnerModule,
    KPIRoutingModule,
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    TreeModule,
    ColorPickerModule,
    RadioButtonModule,
    TooltipModule,
    SliderModule,
    TableModule,
    OrganizationChartModule,
    TreeTableModule,
    ToastModule,
    PanelModule,
    ContextMenuModule,
    ButtonModule,
    SidebarModule,
    InputTextModule,
    DropdownModule,
    MultiSelectModule,
    TieredMenuModule,
    DialogModule,
    CalendarModule,
    InputSwitchModule,
    InputNumberModule,
    CardModule,
    HomeModule,
    NgxPanZoomModule,
    MatSelectFilterModule,
    MatTooltipModule,
    NgxDaterangepickerMd.forRoot(),
    NgxMatSelectModule.forRoot({
      maxWidthForMobileView: 600,
      inFirstLoadCallSearcher: true,
      inFirstLoadSearcherValue: '',
      emptyLabel: 'no entry found',
      noMoreResultLabel: 'no more found',
      useInfiniteScroll: false,
      searchBoxPlaceholder: 'Search',
      maximumResultForShow: 4000,
      useMobileView: false,
      valueMember: 'key',
      displayMember: 'value',
      mobileViewType: 'FullScreen',
    }),
  ],
  declarations: [
    ManualactualentryComponent,
    KpitreedefinationComponent,
    LossanalysisComponent,
    RacimatrixComponent,
    YearlyscorecardComponent,
    KPITargetReportComponent,
    IndividualPerformanceEvaluationComponent,
    KpimasterComponent,
    PillarsComponent,
    DriversComponent,
    ToolsComponent,
    FrequencyComponent,
    TrendsComponent,
    UomComponent,
    DepartmentsComponent,
    MastershiftComponent,
    DayoffComponent,
    KpireasonsComponent,
    FourmComponent,
    KpimasterweightComponent,
    IpeMasterDataComponent,
    IndividualPerformanceEvaluationUserComponent,
    KPIIntegartionComponent,
    KpiDataPointsComponent,
    KpiTreeVersionComponent,
    KpiWiseAnalysisReportComponent,
    KPISplitViewComponent,
    KPIGridViewComponent,
    BtnCellRendererKPIGridView,
    KpiRefreshComponent,
    KpihierarchymasterComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons }, // add as factory to your providers
    { provide: DateAdapter, useClass: DatepickerService },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1, // week starts on Monday (Sunday is 0)
      },
    },
  ]
})
export class KPIModule {}
function antDesignIcons(antDesignIcons: any) {
  throw new Error('Function not implemented.');
}