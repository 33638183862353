<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" template=" <img src='assets/images/spinner.gif' />">
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>
<div dir="{{languageDir}}">
<section>
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">{{langObj ? langObj?.OverallActionPlan : ('OverallActionPlan' | translate)}}</h1>
    </div>
  </div>

  <form [formGroup]="filterForm">
    <div class="content-row-wrap formInput">
      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label>{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}</mat-label>
        <mat-select formControlName="group" panelClass="testClass" name="Group" (selectionChange)="onChangeGroup()">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groups"
          (filteredReturn)="filteredGroups = $event"></mat-select-filter>
          <mat-option *ngFor="let group of filteredGroups" [value]="group.TypeId">
            {{ group.TypeName }}
          </mat-option>
        </mat-select>
        <mat-error>{{langObj ? langObj?.Pleaseselectgroup : ('Pleaseselectgroup' | translate)}}</mat-error>
      </mat-form-field>
      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label>{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}</mat-label>
        <mat-select panelClass="testClass" name="Plant" formControlName="plant" (selectionChange)="onChangePlant()"
          required>
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plants"
          (filteredReturn)="filteredPlants = $event"></mat-select-filter>
          <mat-option *ngFor="let plant of filteredPlants" [value]="plant.DeptId">
            {{ plant.DeptDesc }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label>{{langObj ? langObj?.DateRange : ('DateRange' | translate)}}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="startDate" placeholder="{{langObj ? langObj?.FromDate : ('FromDate' | translate)}}" />
          <input matEndDate formControlName="endDate" placeholder="{{langObj ? langObj?.ToDate : ('ToDate' | translate)}}" (dateChange)="onTDateChange()" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <div class="inputField">
        <label class="select-floating-label" for="multiselect">{{langObj ? langObj?.Module : ('Module' | translate)}}</label>
        <p-multiSelect inputId="multiselect" formControlName="module" [options]="newModules" optionLabel="Name"
          [filter]="true" (onChange)="onModuleChange($event)" maxSelectedLabels="{{ newModules.length - 1 }}"
          selectedItemsLabel="ALL">
        </p-multiSelect>
      </div>

      <div class="inputField">
        <label class="select-floating-label" for="multiselect">{{langObj ? langObj?.Department : ('Department' | translate)}}</label>
        <p-multiSelect inputId="multiselect" formControlName="department" [options]="Departments" optionLabel="TypeName"
          [filter]="true" (onChange)="onDeptChange($event)" selectedItemsLabel="ALL"
          maxSelectedLabels="{{ Departments.length - 1 }}">
        </p-multiSelect>
      </div>

      <div class="inputField">
        <label class="select-floating-label" for="multiselect">{{langObj ? langObj?.Employee : ('Employee' | translate)}}</label>
        <p-multiSelect inputId="multiselect" formControlName="employee" [options]="Responsibles" optionLabel="fullName"
          [filter]="true" (onChange)="onEmpChange($event)" selectedItemsLabel="ALL"
          maxSelectedLabels="{{ Responsibles.length - 1 }}">
        </p-multiSelect>
      </div>

      <div class="inputField">
        <label class="select-floating-label" for="multiselect">{{langObj ? langObj?.Status : ('Status' | translate)}}</label>
        <p-multiSelect inputId="multiselect" formControlName="status" [options]="status" optionLabel="name"
          selectedItemsLabel="ALL" maxSelectedLabels="{{ status.length - 1 }}" [(ngModel)]="selectedStatus"
          (onChange)="onstatusChange($event)">
        </p-multiSelect>
      </div>
      <!-- <button
            mat-mini-fab
            mat-button
            class="search-icon-btn"
           (click) = GetActionPlan()
            pTooltip="Filter"
            tooltipPosition="top"
            style="margin-left: 20px; margin-top: 22px"
          >
            <i class="fa fa-search" style="color: #fff"></i>
          </button> -->
      <!-- <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label>Select Status</mat-label>
        <mat-select formControlName="status" required>
          <mat-option *ngFor="let stat of status" [value]="stat.statusId">
            {{stat.statusName}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
    </div>
  </form>

  <div class="p-grid p-fluid">
    <div class="p-lg-12 p-md-12 p-sm-12">
      <div class="card-row">
        <div class="card-wrapper">
          <div class="card-header">
            <span class="card-text">{{langObj ? langObj?.TotalActions : ('TotalActions' | translate)}}</span>
            <span class="card-value">{{ totalActionsCount }}</span>
          </div>
          <div class="card-footer">
            <div id="Chart1"></div>
          </div>
        </div>

        <div class="card-wrapper">
          <div class="card-header">
            <span class="card-text">{{langObj ? langObj?.CompletedActions : ('CompletedActions' | translate)}}</span>
            <span class="card-value">{{ completedActionsCount }}</span>
          </div>
          <div class="card-footer">
            <div id="Chart2"></div>
          </div>
        </div>

        <div class="card-wrapper">
          <div class="card-header">
            <span class="card-text">{{langObj ? langObj?.PendingActions : ('PendingActions' | translate)}}</span>
            <span class="card-value">{{ pendingActionsCount }}</span>
          </div>
          <div class="card-footer">
            <div id="Chart3"></div>
          </div>
        </div>
      </div>
      <div class="addActionbtn">
        <button type="button"  mat-flat-button color="accent" class="back-button"
        style="margin-left: 14px" (click) ="openAddActionModal()">
        <p class="btn-text">
         Add Action
        </p>
      </button>
      </div>
      <ag-grid-angular id="mainGrid" style="width: 100%; height: 30vw; margin-top: 15px" class="ag-theme-alpine"
        [columnDefs]="actionscolumnDefs" [rowData]="actionsrowData" [pagination]="true" [defaultColDef]="defaultColDef"
        (columnMoved)="saveState()" [paginationPageSize]="10" (cellValueChanged)="onCellValueChanged($event)"
        [loadingOverlayComponent]="loadingOverlayComponent" (gridReady)="onGridReady($event)"
        [gridOptions]="gridOptions" [sideBar]="sideBar" (cellClicked)="cellClicked($event)"
        [groupHideOpenParents]="true" (firstDataRendered)="onFirstDataRendered($event)"
        (filterChanged)="onFilterChanged($event)" (columnRowGroupChanged)="onColumnPivotChanged($event)">
      </ag-grid-angular>
    </div>
  </div>
</section>

<ng-template #previewModal>
  <form [formGroup]="editActionPlanForm">
    <div mat-dialog-title>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>
            {{ langObj ? langObj?.UpdateAction : ("Edit" | translate) }}
          </h2>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>
    </div>
    <mat-dialog-content>
      <div class="content-row-wrap" style="margin-bottom: 20px">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.RootCause : ('SelectNewAssignee' | translate)}}</mat-label>
          <input matInput placeholder="Enter RootCause" formControlName="rootcause" />
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.Action : ('Action' | translate)}} *</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterActionName : ('EnterActionName' | translate)}}" formControlName="actions" [(ngModel)]="data.Action" />
        </mat-form-field>
        <div class="radio-wrapper">
          <mat-label>{{langObj ? langObj?.ActionType : ('ActionType' | translate)}} *:</mat-label>
          <mat-radio-group formControlName="actionType" class="radio-group" name="ActionType">
            <mat-radio-button value="Preventive">Preventive</mat-radio-button>
            <mat-radio-button value="Corrective">Corrective</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="imgFlex-Wrapper">
          <mat-label>{{langObj ? langObj?.ChooseImage : ('ChooseImage' | translate)}}</mat-label>
          <div class="imgflex" style="position: relative">
            <div title="image" style="display: flex">
              <img *ngIf="data.FileSrc || data.UploadFile" (click)="imageZoomModal()"
                [src]="data.FileSrc || blobStorageUrl + data.UploadFile" alt="image" width="60px" height="40px" />
            </div>
            <img *ngIf="data.FileSrc || data.UploadFile" src="assets/images/delete.png" (click)="deleteFile('pic')"
              class="imgabsol" alt="" width="15px" height="15px" style="position: absolute; left: 0; top: 0" />
            <button mat-flat-button color="accent" type="button" class="upload" (click)="openFile('pic')">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" (change)="readFile($event, 'pic')" class="d-none" #fileInput accept="image/*" />
          </div>
        </div>
        <div class="imgFlex-Wrapper">
          <mat-label>{{langObj ? langObj?.ChooseFile : ('ChooseFile' | translate)}}</mat-label>
          <div class="imgflex" style="position: relative">
            <div title="pdf" style="display: flex">
              <a *ngIf="data.DocSrc || data.UploadDoc" (click)="downloadPDF()"><img src="assets/images/file_img.png"
                  class="pdfimg" alt="pdf" width="60px" /></a>
            </div>
            <img *ngIf="data.DocSrc || data.UploadDoc" src="assets/images/delete.png" (click)="deleteFile('file')"
              class="imgabsol" alt="" width="15px" height="15px" style="position: absolute; left: 0; top: 0" />
            <button mat-flat-button color="accent" type="button" class="upload" (click)="openFile(i, 'file')"
              [disabled]="data.Status == 'Closed'">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" (change)="readFile($event, 'file')" class="d-none" #fileInputFile
              accept="application/pdf,application/vnd.ms-excel" />
          </div>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.SelectAssignee : ('SelectAssignee' | translate)}} *</mat-label>
          <mat-select formControlName="assignTo" [disabled]="data.Status == 'Closed' || !data.Action"
            (selectionChange)="update()">
            <mat-select-filter [placeholder]="'Search User'" [displayMember]="'FullName'" [array]="userList"
              (filteredReturn)="userList = $event">
            </mat-select-filter>

            <mat-option *ngFor="let user of userList" [value]="user.EmpInfo_Id">
              {{ user.FullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="inputField" style="margin-top: 15px; width: 17%">
          <nz-date-picker class="calender-input" style="margin-left: 0px !important; width: 100%"
            [nzInputReadOnly]="true" [nzShowTime]="false" nzFormat="yyyy-MM-dd" [nzDisabledDate]="getMinDate('Target')"
            formControlName="targetDate" (nzOnCalendarChange)="targetChange()" [nzDisabled]="
              data.Status === 'Open' ? (data.AssignTo ? false : true) : true
            " [(ngModel)]="data.TargetDate">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.TargetDate : ('TargetDate' | translate)}} *</span>
        </div>
        <div class="inputField" style="margin-top: 15px; width: 17%">
          <nz-date-picker class="calender-input" formControlName="revisedDate" nzFormat="yyyy-MM-dd"
            [nzInputReadOnly]="true" style="width: 100%; margin-left: 0px !important"
            [nzDisabledDate]="getMinDate('Revised')" [nzDisabled]="
              (data.Status != 'InProgress' && data.Status == 'Open') ||
              data.Status == 'Complete' ||
              data.Status == 'Closed'
            ">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.RevisedDate : ('RevisedDate' | translate)}}</span>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" [ngClass]="{
            'disabled-input':
              data.Status == 'Closed' ||
              !data.TargetDate ||
              data.Status == 'Complete'
          }">
          <mat-label>{{langObj ? langObj?.SelectCompleted : ('SelectCompleted' | translate)}}</mat-label>
          <mat-select formControlName="completed" (selectionChange)="onChangeComplete()" [disabled]="
              data.Status == 'Closed' ||
              !data.TargetDate ||
              data.Status == 'Complete'
            ">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          [ngClass]="{ 'disabled-input': disableVerification }">
          <mat-label>{{langObj ? langObj?.SelectVerfication : ('SelectVerfication' | translate)}}</mat-label>
          <mat-select formControlName="verification" [disabled]="disableVerification"
            (selectionChange)="onChangeVerification()">
            <mat-option value="">None</mat-option>
            <mat-option value="Approved">Approved</mat-option>
            <mat-option value="Disapproved">Disapproved</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          [ngClass]="{ 'disabled-input': disableNewAssigne }">
          <mat-label>{{langObj ? langObj?.SelectNewAssignee : ('SelectNewAssignee' | translate)}}</mat-label>
          <mat-select [disabled]="disableNewAssigne" formControlName="newAssignee">
            <mat-select-filter [placeholder]="'Search User'" [displayMember]="'FullName'" [array]="userList"
              (filteredReturn)="userList = $event">
            </mat-select-filter>

            <mat-option *ngFor="let user of userList" [value]="user.EmpInfo_Id">
              {{ user.FullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="p-xl-4 uploadinside" *ngIf="showinput" style="width: fit-content">
          <!-- <mat-label>Choose Close File</mat-label> -->
          <!-- <ngx-mat-file-input [formControl]="closefile" [accept]="accept"></ngx-mat-file-input> -->
          <input placeholder="{{langObj ? langObj?.ChooseCloseFile : ('ChooseCloseFile' | translate)}}" type="file" (change)="uploadedFile($event)" formControlName="closefile"
            class="form-control" style="height: 48.31px; width: 219px; margin-top: 8px" />
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterRemarks : ('EnterRemarks' | translate)}}" formControlName="remarks" />
        </mat-form-field>
        <button mat-mini-fab mat-button color="primary" matTooltip="Update" (click)="UpdateActionPlan()"
          matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px; margin-top: 20px">
          <i class="fa fa-pencil" style="color: #fff"></i>
        </button>
      </div>
    </mat-dialog-content>
  </form>
</ng-template>

<p-dialog [header]="fileDesc" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
  [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ngx-doc-viewer *ngIf="fileType === 'doc'" [url]="doc" viewer="google" style="width: 100%; height: 90vh">
  </ngx-doc-viewer>
  <angular-image-viewer *ngIf="fileType === 'img'" [src]="images" [(index)]="imageIndexOne"></angular-image-viewer>
</p-dialog>
</div>

<ng-template #addActionModal>
  <form [formGroup]="addActionForm">
    <div mat-dialog-title>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>
            {{ langObj ? langObj?.AddAction : ("AddAction" | translate) }}
          </h2>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>
    </div>
    <mat-dialog-content>
      <div class="content-row-wrap" style="margin-bottom: 20px">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.RootCause : ('SelectNewAssignee' | translate)}}</mat-label>
          <input matInput placeholder="Enter RootCause" formControlName="rootcause" />
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label> {{langObj ? langObj?.Action : ('Action' | translate)}} *</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterActionName : ('EnterActionName' | translate)}}" formControlName="action" />
        </mat-form-field>
        <div class="radio-wrapper">
          <mat-label>{{langObj ? langObj?.ActionType : ('ActionType' | translate)}} *:</mat-label>
          <mat-radio-group formControlName="actionType" class="radio-group" name="ActionType">
            <mat-radio-button value="Preventive">Preventive</mat-radio-button>
            <mat-radio-button value="Corrective">Corrective</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="imgFlex-Wrapper">
          <mat-label>{{langObj ? langObj?.ChooseImage : ('ChooseImage' | translate)}}</mat-label>
          <div class="imgflex" style="position: relative">
            <div title="image" style="display: flex">
              <img *ngIf="addImageSrc || addImage" (click)="addImageZoomModal()"
                 alt="image.png" width="60px" height="40px" />
            </div>
            <img *ngIf="addImageSrc || addImage" src="assets/images/delete.png" (click)="addDeleteFile('pic')"
              class="imgabsol" alt="" width="15px" height="15px" style="position: absolute; left: 0; top: 0" />
            <button mat-flat-button color="accent" type="button" class="upload"  (click)="addOpenFile('pic')">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" (change)="addReadFile($event, 'pic')" class="d-none" #addImageInput accept="image/*" />
          </div>
        </div>
        <div class="imgFlex-Wrapper">
          <mat-label>
            {{langObj ? langObj?.ChooseFile : ('ChooseFile' | translate)}}
          </mat-label>
          <div class="imgflex" style="position: relative">
            <div title="pdf" style="display: flex">
              <a *ngIf=" addFileSrc || addFile " (click)="addDownloadPDF()"><img src="assets/images/file_img.png"
                  class="pdfimg" alt="pdf" width="60px" /></a>
            </div>
            <img *ngIf=" addFileSrc || addFile" src="assets/images/delete.png" (click)="addDeleteFile('file')"
              class="imgabsol" alt="" width="15px" height="15px" style="position: absolute; left: 0; top: 0" />
            <button mat-flat-button color="accent" type="button" class="upload" (click)="addOpenFile('file')"
              [disabled]="addStatus == 'Closed'">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" (change)="addReadFile($event, 'file')" class="d-none" #addFileInput
              accept="application/pdf,application/vnd.ms-excel" />
          </div>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.SelectAssignee : ('SelectAssignee' | translate)}} *</mat-label>
          <mat-select formControlName="assignTo" [disabled]="addStatus == 'Closed' || !this.addActionForm.value.action"
             >
            <mat-select-filter [placeholder]="'Search User'" [displayMember]="'FullName'" [array]="userList"
              (filteredReturn)="userList = $event">
            </mat-select-filter>
            <mat-option *ngFor="let user of userList" [value]="user.EmpInfo_Id">
              {{ user.FullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="inputField" style="margin-top: 15px; width: 17%">
          <nz-date-picker class="calender-input" style="margin-left: 0px !important; width: 100%"
            [nzInputReadOnly]="true" [nzShowTime]="false" nzFormat="yyyy-MM-dd" [nzDisabledDate]="addGetMinDate('Target')"
            formControlName="targetDate"  [nzDisabled]="
              addStatus === 'Open' ? (addActionForm.value.assignTo ? false : true) : true
            ">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.TargetDate : ('TargetDate' | translate)}} *</span>
        </div>
        <div class="inputField" style="margin-top: 15px; width: 17%">
          <nz-date-picker class="calender-input" formControlName="revisedDate" nzFormat="yyyy-MM-dd"
            [nzInputReadOnly]="true" style="width: 100%; margin-left: 0px !important"
            [nzDisabledDate]="addGetMinDate('Revised')" [nzDisabled]="
              (addStatus && addStatus == 'Open') ||
              addStatus == 'Complete' ||
              addStatus == 'Closed'
            ">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.RevisedDate : ('RevisedDate' | translate)}}</span>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" [ngClass]="{
              'disabled-input':
              addStatus == 'Closed' ||
              !addActionForm.value.targetDate ||
              addStatus == 'Complete'
          }">
          <mat-label>{{langObj ? langObj?.SelectCompleted : ('SelectCompleted' | translate)}}</mat-label>
          <mat-select formControlName="completed" (selectionChange)="addOnChangeComplete()" [disabled]="
              addStatus == 'Closed' ||
              !addActionForm.value.targetDate ||
              addStatus == 'Complete'
            ">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          [ngClass]="{ 'disabled-input': disableVerification }">
          <mat-label>{{langObj ? langObj?.SelectVerfication : ('SelectVerfication' | translate)}}</mat-label>
          <mat-select formControlName="verification" [disabled]="addDisableVerification"
            (selectionChange)="addOnChangeVerification()">
            <mat-option value="">None</mat-option>
            <mat-option value="Approved">Approved</mat-option>
            <mat-option value="Disapproved">Disapproved</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          [ngClass]="{ 'disabled-input': addDisableNewAssigne }">
          <mat-label>{{langObj ? langObj?.SelectNewAssignee : ('SelectNewAssignee' | translate)}}</mat-label>
          <mat-select [disabled]="addDisableNewAssigne" formControlName="newAssignee">
            <mat-select-filter [placeholder]="'Search User'" [displayMember]="'FullName'" [array]="userList"
              (filteredReturn)="userList = $event">
            </mat-select-filter>
            <mat-option *ngFor="let user of userList" [value]="user.EmpInfo_Id">
              {{ user.FullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="p-xl-4 uploadinside" *ngIf="showinput" style="width: fit-content">
          <input placeholder="{{langObj ? langObj?.ChooseCloseFile : ('ChooseCloseFile' | translate)}}" type="file" (change)="addCloseFile($event)" formControlName="closefile"
            class="form-control" style="height: 48.31px; width: 219px; margin-top: 8px" />
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterRemarks : ('EnterRemarks' | translate)}}" formControlName="remarks" />
        </mat-form-field>
        <button [disabled] ="!addActionForm.valid" mat-mini-fab mat-button color="primary" matTooltip="Add Action" (click)="addAction()"
          matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px; margin-top: 20px">
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
      </div>
    </mat-dialog-content>
  </form>
</ng-template>