<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
    <p style="color: white;">{{ spinnerText }}</p>
</ngx-spinner>
<div class="container">

    <div class="content-top-box">
        <ul class="breadcrumb">
            <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
            </li>
        </ul>
    </div>
    <div class="sfdc-configuration-content">
        <div class="header-title">
            <h1 class="main-title">{{stageName}}</h1>
        </div>
    </div>
    <form name="searchDefectForm" [formGroup]="searchDefectForm" (ngSubmit)="onSubmit()">
        <div class="w-100 headerForm">
            <div>
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing w-20">
                    <mat-label>Group</mat-label>
                    <mat-select (selectionChange)="getDeptData($event.value)" formControlName="group">
                        <input (keyup)="onKey($event.target,'group')" class="form-control w-100" placeholder="Search Group">
                        <mat-option *ngFor="let group of groupList" [value]="group.TypeId">
                            {{group.TypeName}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Group is required</mat-error>
                </mat-form-field>
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing  w-20">
                    <mat-label>Plant</mat-label>
                    <mat-select formControlName="plant" (selectionChange)="getAllDepartment()">
                        <input (keyup)="onKey($event.target,'plant')" class="form-control w-100" placeholder="Search Plant">
                        <mat-option *ngFor="let plant of plantList" [value]="plant.DeptId">
                            {{plant.DeptDesc}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Plant is required</mat-error>
                </mat-form-field>
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing  w-20">
                    <mat-label>Department</mat-label>
                    <mat-select formControlName="department" multiple>
                        <input (keyup)="onKey($event.target,'department')" class="form-control w-100" placeholder="Search Department">
                        <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>
                        <mat-option *ngFor="let dept of deptList" [value]="dept.TypeId" (click)="tosslePerOne(allSelected.viewValue)">
                            {{dept.TypeName}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Department is required</mat-error>
                </mat-form-field>
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing w-20">
                    <input matInput [ngxMatDatetimePicker]="startPicker" placeholder="From Date" formControlName="fromDate" readonly [(ngModel)]="lastwlastmdate.dtStartDate">
                    <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #startPicker></ngx-mat-datetime-picker>
                </mat-form-field>

                <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing w-20">
                    <input matInput [ngxMatDatetimePicker]="endPicker" placeholder="To Date" formControlName="toDate" readonly [(ngModel)]="lastwlastmdate.dtEndDate">
                    <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #endPicker>
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>

            <div class="content-top-row content-top-row-order-processing float-right m-0">
                <button type="submit" class="save" tooltipPosition="top" [disabled]="searchDefectForm.value.group == '' || searchDefectForm.value.plant == ''">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.3609 18.2168L14.6008 13.2662C15.8247 11.8113 16.4953 9.98069 16.4953 8.07499C16.4953 3.62251 12.8728 0 8.4203 0C3.96782 0 0.345306 3.62251 0.345306 8.07499C0.345306 12.5275 3.96782 16.15 8.4203 16.15C10.0918 16.15 11.6847 15.6458 13.0466 14.6888L17.8428 19.677C18.0432 19.8852 18.3129 20 18.6018 20C18.8753 20 19.1348 19.8957 19.3317 19.7061C19.7502 19.3034 19.7636 18.6357 19.3609 18.2168ZM8.4203 2.10652C11.7114 2.10652 14.3888 4.78391 14.3888 8.07499C14.3888 11.3661 11.7114 14.0435 8.4203 14.0435C5.12921 14.0435 2.45183 11.3661 2.45183 8.07499C2.45183 4.78391 5.12921 2.10652 8.4203 2.10652Z"
                            fill="#10123D" />
                    </svg>
                </button>
                <div class="btn-position">
                    <button mat-flat-button color="accent" class="btn-accent" (click)="lastWeek()">Last Week</button>
                </div>
                <div class="btn-position">
                    <button mat-flat-button color="accent" class="btn-accent" (click)="lastMonth()">Last Month</button>
                </div>
                <div class="btn-position">
                    <button mat-flat-button color="accent" class="btn-accent" (click)="reset()">Reset</button>
                </div>
            </div>
        </div>

    </form>

    <div class="col-md-12 tect-center" style="margin:3px">
        <h1 class="main-title">{{stage_name }}</h1>
    </div>
    <div class="row col-md-12">
        <!-- Charts -->
        <div class="chart-group">
            <div class="chart-items">

                <div [chart]="chart"></div>
            </div>
            <div class="chart-items" *ngIf="graphTableShow === true">

                <div [chart]="chartByDept"></div>
            </div>
        </div>
    </div>

    <div class="col-md-12" *ngIf="graphTableShow === true">
        <!--table-->
        <button type="button" class="btn btn-success" (click)="onBtnExportCSV()" style="margin-top: 30px;"><img src="assets/images/csv-file-format.png"></button>
        <button type="button" class="btn btn-success" (click)="onBtnExportExcel()" style="margin-top: 30px; margin-left: 10px;"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
        <div style="height: 200px;">
            <ag-grid-angular style="width: 100%; height:30vw;" class="ag-theme-alpine" [columnDefs]="swotColumnDefs" [rowData]="swotpestByDeptDataArray" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="paginationPageSize"></ag-grid-angular>
        </div>
    </div>
</div>