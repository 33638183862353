import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ImageDialogComponent } from 'src/app/analytical-tools/image-dialog/image-dialog.component';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';
import { DtServiceService } from 'src/app/services/dtService.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { NcrService } from 'src/app/services/ncr.service';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { environment } from 'src/environments/environment';
import { ManagerExecutionService } from '../../services/manager.service';
@Component({
  selector: 'app-at-stages-ncr',
  templateUrl: './at-stages-ncr.component.html',
  styleUrls: ['../at-stages2/at-stages2.component.scss'],
})
export class AtStagesNcrComponent implements OnInit {
  @Input() stageName = '';
  @Input() stageID = 0;
  @Input() levelId = 0;
  @Input() updateStatus = false;
  firstReload: boolean = false;
  prevBTNToolTip: string;
  nextBTNToolTip: string = 'Fishbone';
  showNext: boolean = true;
  showPrev: boolean = true;
  @Output() sixWTwoh_ = new EventEmitter();
  @Output() fishBone_ = new EventEmitter();
  @Output() causeAndEffects_ = new EventEmitter();
  @Output() fiveWhy_ = new EventEmitter();
  @Output() actionPlan_ = new EventEmitter();
  @Output() _integrationData = new EventEmitter();
  headerData: any = {
    ID: 0,
    Group: '',
    Plant: '',
    Dept: '',
    Team: '',
    TeamLeader: '',
    InitProbStatement: '',
    NoOfM: 6,
    stageID: 1,
    uniqueCode: '',
    CreatedAt: new Date(),
    CreatedBy: '',
  };
  @Output() save = new EventEmitter();
  @Output() emitSearchData = new EventEmitter();
  @Output() mType = new EventEmitter();
  @Output() reset = new EventEmitter();
  @Output() setParentResetFalse = new EventEmitter();
  @Output() plantChange = new EventEmitter();
  @Output() _headerData = new EventEmitter();
  @Input() rca = false;
  @Input() isReset: boolean = false;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  value = 50;
  searchDefectForm: FormGroup;
  breadcrumList!: any;
  strongNew: any = [];
  filteredData: any = [];
  teamLeaderData: any = [];
  currentIndex: number = 0;
  spinnerText: string = '';
  rcaId: number = 0;
  // Dropdown Values
  groupList: any = [];
  groupTempList: any = [];
  plantList: any = [];
  plantTempList: any = [];
  deptList: any = [];
  deptTempList: any = [];
  selectedIndex = 10;
  teamList = [
    { id: 1, title: 'Team 1' },
    { id: 2, title: 'Team 2' },
    { id: 3, title: 'Team 3' },
    { id: 4, title: 'Team 4' },
    { id: 5, title: 'Team 5' },
  ];
  teamLeaderList: any = [];
  mList = [
    { id: 4, title: '4M' },
    { id: 5, title: '5M' },
    { id: 6, title: '6M' },
  ];
  deptApiStarted: boolean = false;
  dataNotFoundForDept: boolean = false;

  formDirective: any;
  userList: any;
  teams: any = [];
  tempTeams: any = [];
  permisson!: any;
  IsWrite: boolean = false;
  IsAccess!: any;
  disableCauseEffect: boolean = false;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('selectTeamLeader') selectTeamLeader: any;
  @ViewChild('selectTeamLeaderFilter') selectTeamLeaderFilter: any;
  @ViewChild('selectParticipants') selectParticipants: any;
  @ViewChild('selectParticipantsFilter') selectParticipantsFilter: any;

  imageFile: any;
  imageSrc: any;
  ImageUrl: any;
  // @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  headerId: number = 0;
  uniqueCodeList: any = [];
  uniqueCodeTempList: any = [];
  itemRow: any = {};
  disableFiveWhyAction: boolean = false;
  disableFiveWhy: boolean = false;
  disableFishbone: boolean = false;
  disable6W2H: boolean = false
  isGet: boolean = true;

  teamArr: any = [];
  historicalHeaderValues: any;
  teamsParticipant: any = [];
  tempTeamsteamsParticipant: any = [];
  previousTeamLeader: any;
  AllMenuList: any = [];
  ATMenuList: any = [];
  user: any;
  selectedRCA: any = {};
  @Output() _cloneForm: EventEmitter<any> = new EventEmitter();
  moduleId: any;
  pageId: any;
  menuAccess: any;
  isLoading: boolean;
  enableNext: boolean;
  enablePrev: boolean;
  isDataUpdateAllow: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ncrService: NcrService,
    private dataService: DataService,

    private router: Router,
    public managerExecutionService: ManagerExecutionService,
    private spinner: NgxSpinnerService,
    private sfdService: ShopFloorDataService,
    private toaster: ToastrService,
    private dtService: DtServiceService,
    private analyticalToolsService: AnalyticalToolsService,
    private dialog: MatDialog,
    private enterpriseGroupService: EnterPriseGroupService,
    private commonService: CommonService,
    private userManagementService: UserManagementService,
    private dialogRef: MatDialogRef<AtStagesNcrComponent>
  ) {}

  ngOnInit(): void {
    this.createForm();

    // this.GetBreadcrumList();
    const route = this.router.url;
    let headerID;

    headerID = JSON.parse(localStorage.getItem('headerId')) || 0;
    if (
      route.includes('MeetingMatrixV2') ||
      route.includes('KPIGapAnalysis') ||
      route.includes('Scorecard')
    ) {
      if (route.includes('MeetingMatrixV2') && this.data?.hideTabs) {
        this.changeIndexValue(0);
      } else {
        this.changeIndexValue(4);
      }
    } else if (
      route.includes('kpiView') ||
      route.includes('NcrManagement') ||
      route.includes('CcrManagement')
    ) {
      this.changeIndexValue(0);

      this.tabsShowHideStrategy();
    }
    const test = route.includes('kpiView' || 'NcrManagement');
    this.headerId = headerID;
    this.headerData.InitProbStatement = this.data?.InitProbStatement;
    this.ImageUrl = environment.O3CoreApiImageUrl;
    this.rcaId = this.analyticalToolsService.getRcaId();

    this.levelId = this.analyticalToolsService.getLevelId();
    this.filteredData = this.analyticalToolsService.getFilteredData();
    this.teamLeaderData = this.analyticalToolsService.getTeamLeaderList();
    this.currentIndex = this.analyticalToolsService.getFilteredIndex();
    setTimeout(() => {
      this.getGroups();
    }, 1500);
    this.setValidator();
    this.getUserList();
    this.GetMenuAccess();
    this.getMenulist();
    this.historicalHeaderValues =
      this.analyticalToolsService.getHistoricalHeaderData()
        ? this.analyticalToolsService.getHistoricalHeaderData()
        : 0;
    if (this.historicalHeaderValues) {
      this.headerData.Group = this.historicalHeaderValues.Group;
      this.headerData.Plant = this.historicalHeaderValues.Plant;
      if (this.historicalHeaderValues.Department != 'ALL')
        this.headerData.Dept = this.historicalHeaderValues.Department;
    }

    //module 1: NCR
    //module 2: CCR
    //module 3: KPI
    if (this.data?.patchHeader) {
      if (this.data?.moduleId === 3) {
        this.patchHeaderByKPIModule(this.data);
      } else {
        this.patchHeaderByNCRModule(this.data);
      }
      this.createForm();
    }
    this.patchHeaderByProps(this.data);
    this.onFormValueChanged();
    if(!this.data)
      this.isDataUpdateAllow = true;
    else
      this.isDataUpdateAllow = ('isDataUpdateAllow' in this.data) ? this.data.isDataUpdateAllow : true;
  }
  createForm() {
    this.searchDefectForm = new FormGroup({
      group: new FormControl(null, [Validators.required]),
      plant: new FormControl(null, [Validators.required]),
      department: new FormControl(null, [Validators.required]),
      team: new FormControl(null, [Validators.required]),
      teamleader: new FormControl(null, [Validators.required]),
      initProbStat: new FormControl(null),
      mType: new FormControl(null),
      refocusedProb: new FormControl(null),
      uniqueCode: new FormControl(null),
    });
  }
  onFormValueChanged() {
    this.searchDefectForm.valueChanges.subscribe((values: any) => {
      this.dataService.setData(values);
    });
  }
  getHeaderDataById(headerId: any) {}

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  get getCurrentURL() {
    return this.router.url;
  }
  ngOnChanges() {
    if (this.isReset) {
      if (this.formDirective) this.formDirective.resetForm();
      this.searchDefectForm.reset();
      this.plantList = [];
      this.plantTempList = [];
      this.deptList = [];
      this.deptTempList = [];
      this.teamLeaderList = [];
      this.teams = [];
      this.tempTeams = [];
      this.headerData.UploadFile = '';
      this.imageSrc = '';
      this.setParentResetFalse.emit(true);
      // this.isReset = false;
      this.headerData.Date = new Date();
      this.headerData.CreatedAt = new Date();
      this.isGet = true;
      this.getGroups();
    }
    if (
      this.headerData.Group &&
      this.headerData.Plant &&
      this.headerData.Dept
    ) {
      this.isGet = false;
      this.getDeptData();
    }
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getMenulist(): any {
    const obj = {};
    this.userManagementService.GetMenulist(obj).subscribe(
      (data: any) => {
        this.AllMenuList = data.mennulist;
        this.ATMenuList = this.AllMenuList.find(
          (menu: any) =>
            menu.Url == '/at/historical-table' && menu.List && menu.List.length
        );
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  ngDoCheck() {
    if (this.headerData.Group) {
    }
    this.rcaId = this.analyticalToolsService.getRcaId();
    this.rca = this.analyticalToolsService.getRca();
    this.levelId = this.analyticalToolsService.getLevelId();
    this.updateStatus = this.analyticalToolsService.getStatus();
    this.headerData.stageID =
      this.headerData && this.headerData.stageID
        ? this.headerData.stageID
        : this.stageID;
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.headerData.CreatedBy = this.user.UserId;
    this.filteredData = this.analyticalToolsService.getFilteredData();
    this.teamLeaderData = this.analyticalToolsService.getTeamLeaderList();
    this.currentIndex = this.analyticalToolsService.getFilteredIndex();
    if (this.teamLeaderData && this.teamLeaderData.length) {
      this.teamLeaderList = this.teams.filter((usr: any) =>
        this.teamLeaderData.includes(usr.EmpInfo_Id)
      );
    }

    if (
      this.plantTempList &&
      !this.plantTempList.length &&
      this.headerData.Group &&
      !this.deptApiStarted &&
      !this.dataNotFoundForDept
    )
      this.getDeptData();
  }

  getUserList() {
    // this.spinnerText = "Fetching User Data";
    this.spinner.show();
    this.managerExecutionService.getUserList().subscribe(
      (res: any) => {
        this.userList = res.UserList;
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  getTeamList() {
    // this.spinnerText = "Fetching Team Data";
    this.spinner.show();
    const data = {
      EGId: this.headerData.Group,
      PlantId: this.headerData.Plant,
    };
    this.commonService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        this.teams = res.employeeInfoList;
        this.teams.map(
          (team: any) =>
            (team.FullName = team.FirstName.trim() + ' ' + team.lastName.trim())
        );
        this.tempTeams = this.teams.slice();
        this.teamsParticipant = res.employeeInfoList;
        this.teamsParticipant.map(
          (teampart: any) =>
            (teampart.FullName =
              teampart.FirstName.trim() + ' ' + teampart.lastName.trim())
        );
        this.tempTeamsteamsParticipant = this.teamsParticipant.slice();
        if (
          this.teams &&
          this.teams.length &&
          this.teamLeaderData &&
          this.teamLeaderData.length &&
          this.teamLeaderList &&
          !this.teamLeaderList.length
        ) {
          this.teamLeaderList = this.teams.filter((usr: any) =>
            this.teamLeaderData.includes(usr.EmpInfo_Id)
          );
        }
        this.spinner.hide();
        this.teamLeaderList = [];
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  setTeamAndValidate(val: any) {
    if (this.headerData.TeamLeader != val) {
      let obj = this.headerData.Team.find((teamId: any) => teamId == val);
      if (!obj) {
        let index = this.teamArr.findIndex((ind: any) => ind == val);
        if (index > -1) {
          this.teamArr.splice(index, 1);
          this.analyticalToolsService.setTeamLeaderList(this.teamArr);
          this.headerData.Team =
            this.analyticalToolsService.getTeamLeaderList();
        }
      } else {
        this.analyticalToolsService.setTeamLeaderList(this.teamArr);
        this.headerData.Team = this.analyticalToolsService.getTeamLeaderList();
      }
    }
  }

  keyUp() {
    this.headerData.Team = this.analyticalToolsService.getTeamLeaderList()
      ? this.analyticalToolsService.getTeamLeaderList()
      : [];
    if (this.headerData.Team && this.headerData.Team.length)
      this.searchDefectForm.controls.team.patchValue([
        ...this.headerData.Team.map((item: any) => item),
      ]);
  }

  setTeam() {
    this.tempTeamsteamsParticipant = this.teamsParticipant;
    this.headerData.Team = this.analyticalToolsService.getTeamLeaderList()
      ? this.analyticalToolsService.getTeamLeaderList()
      : [];
    if (this.headerData.Team && this.headerData.Team.length)
      this.searchDefectForm.controls.team.patchValue([
        ...this.headerData.Team.map((item: any) => item),
      ]);
  }

  resetSelectFilter(type: any) {
    if (type == 'TeamLeader') {
      if (this.selectTeamLeaderFilter && this.selectTeamLeaderFilter.searchForm)
        this.selectTeamLeaderFilter.searchForm.reset();
    } else if (type == 'Participants') {
      if (
        this.selectParticipantsFilter &&
        this.selectParticipantsFilter.searchForm
      )
        this.selectParticipantsFilter.searchForm.reset();
    }
  }

  addTeamLeader(val: any) {
    if (this.headerData.Team && this.headerData.Team.length) {
      let obj = this.headerData.Team.find((team: any) => team == val);
      if (!obj) {
        this.headerData.Team.push(val);
        this.searchDefectForm.controls.team.patchValue([
          ...this.headerData.Team.map((item: any) => item),
        ]);
        this.analyticalToolsService.setTeamLeaderList(this.headerData.Team);
      }

      if (this.previousTeamLeader != val) {
        let leadobj = this.headerData.Team.findIndex(
          (team: any) => team == this.previousTeamLeader
        );
        if (leadobj > -1) {
          this.headerData.Team.splice(leadobj, 1);
          this.analyticalToolsService.setTeamLeaderList(this.headerData.Team);
          this.headerData.Team =
            this.analyticalToolsService.getTeamLeaderList();
        }
      }
    } else {
      this.headerData.Team = [];
      this.headerData.Team.push(val);
      this.analyticalToolsService.setTeamLeaderList(this.headerData.Team);
    }
    this.previousTeamLeader = val;
  }

  clickTeamLeader(val: any) {
  }

  onSelectionChange() {
    this._headerData.emit(this.headerData);
  }
  setParticipantsChange(val) {
    let teamLeaderList = [];
    this.teamArr = [];
    // this.analyticalToolsService.setTeamLeaderList(val);

    if (this.teamsParticipant && this.teamsParticipant.length) {
      for (var i = 0; i < val.length; i++) {
        let obj = this.teamsParticipant.find(
          (ind: any) => ind.EmpInfo_Id == val[i]
        );
        if (obj != undefined) teamLeaderList.push(obj);
      }
    }
    this.teamArr = this.analyticalToolsService.getTeamLeaderList()
      ? this.analyticalToolsService.getTeamLeaderList()
      : [];

    if (teamLeaderList && teamLeaderList.length) {
      for (const teamid of teamLeaderList) {
        if (this.teamArr && this.teamArr.length) {
          let obj = this.teamArr.find((ind: any) => ind == teamid.EmpInfo_Id);
          if (!obj) this.teamArr.push(teamid.EmpInfo_Id);
        } else this.teamArr.push(teamid.EmpInfo_Id);
      }
    }
  }

  resetTask() {
    this.reset.emit();
  }

  deleteFile() {
    this.imageFile = [];
    this.headerData.UploadFile = '';
    this.imageSrc = '';
  }

  setValidator() {}

  getGroups(): void {
    const key = {
      Id: 0,
    };
    // this.spinnerText = 'Fetching Groups Data';
    this.spinner.show();
    this.sfdService.getGroupData(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.groupList = res.masterList;
        this.groupTempList = this.groupList.slice();
        // setTimeout(() => {
        // if(this.isGet){
        if (
          this.groupTempList &&
          this.groupTempList.length &&
          !this.headerData.Group
        )
          if (this.data?.hideTabs && this.data?.componentName === 'kpiView') {
            this.headerData.Group = this.data?.groupId;
          } else {
            this.headerData.Group = this.groupTempList[0].TypeId;
          }
        if (this.headerData.Plant == '') this.getDeptData();
        //   }
        // }, 2000);
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getDeptData(): void {
    const key = {
      GroupId: this.headerData.Group,
    };
    this.deptApiStarted = true;
    this.spinner.show();
    this.dtService.getPlantData(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.deptApiStarted = false;
        this.plantList = res.DepartList;
        this.plantTempList = this.plantList.slice();
        //Reset plant if not exists from the list
        if (
          this.plantTempList &&
          this.plantTempList.length &&
          this.headerData.Plant
        ) {
          const plant = this.plantTempList.find(
            (pt: any) => pt.DeptId == this.headerData.Plant
          );
          if (!plant) {
            this.plantTempList = [];
            this.headerData.Plant = '';
            this.getDeptData();
          }
        }
        if (res.DepartList && !res.DepartList.length)
          this.dataNotFoundForDept = true;
        else this.dataNotFoundForDept = false;
        //Update the first data of plant
        if (
          this.plantTempList &&
          this.plantTempList.length &&
          !this.headerData.Plant
        ) {
          this.headerData.Plant = parseInt(this.plantTempList[0].DeptId);
        }
        this.getAllDepartment();
        this.getTeamList();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getAllDepartment() {
    const key = {
      ptId: this.headerData.Plant,
    };
    this.spinner.show();
    this.dtService.getDeptData(key).subscribe((res: any) => {
      this.deptList = res;
      this.deptTempList = this.deptList.slice();
      //Reset plant if not exists from the list
      if (
        this.deptTempList &&
        this.deptTempList.length &&
        this.headerData.Dept
      ) {
        const department = this.deptTempList.find(
          (dep: any) => dep.TypeId == this.headerData.Dept
        );
        if (!department) {
          this.headerData.Dept = '';
          this.getAllDepartment();
        }
      }
      if (
        this.deptTempList &&
        this.deptTempList.length &&
        !this.headerData.Dept
      )
        this.headerData.Dept = parseInt(this.deptTempList[0].TypeId);
      this.spinner.hide();
      this.getUniqueCode();
    });
  }

  getUniqueCode() {
    // this.isLoading = true;
    this.spinner.show();
    const key = {
      Id: this.headerData.Dept,
    };
    // this.spinnerText = 'Fetching Groups Data';
    this.spinner.show();
    this.analyticalToolsService.getUniqueCode(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.uniqueCodeList = res.data;
        this.uniqueCodeTempList = this.uniqueCodeList.slice();
        const url = this.router.url;
        let headerID = JSON.parse(localStorage.getItem('headerId'));

        this.selectedRCA = res.data.find((item: any) => item.ID === headerID);
        this.headerId = headerID;
        if (this.selectedRCA && this.selectedRCA != undefined) {

          if (this.selectedRCA.Status6W2H === 'Open') {
            this.selectedRCA.disableFishbone = true;
            this.selectedRCA.disableCauseAndEffect = true;
            this.selectedRCA.disableFiveWhy = true;
            this.selectedRCA.disableActionPlan = true;
          }
          if (this.selectedRCA.StatusFishbone == 'Open') {
            this.selectedRCA.disableCauseAndEffect = true;
            this.selectedRCA.disableFiveWhy = true;
            this.selectedRCA.disableActionPlan = true;
          }
          if (this.selectedRCA.StatusCauseEffect == 'Open') {
            this.selectedRCA.disableFiveWhy = true;
            this.selectedRCA.disableActionPlan = true;
          }
          if (this.selectedRCA.StatusFiveWhy == 'Open') {
            this.selectedRCA.disableActionPlan = true;
          }

          if (this.selectedRCA.Status6W2H === 'Open') {
            if (
              this.selectedRCA.StatusFishbone == 'Completed' ||
              this.selectedRCA.StatusCauseEffect == 'Completed' ||
              this.selectedRCA.StatusFiveWhy == 'Completed' ||
              this.selectedRCA.StatusFiveWhyAction == 'Completed'
            ) {
              this.selectedRCA.Status6W2H = '-';
            }
          }

          if (this.selectedRCA.StatusFishbone === 'Open') {
            if (
              this.selectedRCA.StatusCauseEffect == 'Completed' ||
              this.selectedRCA.StatusFiveWhy == 'Completed' ||
              this.selectedRCA.StatusFiveWhyAction == 'Completed'
            ) {
              this.selectedRCA.StatusFishbone = '-';
            }
            if (this.selectedRCA.disableFishbone) {
              this.selectedRCA.StatusFishbone = '-';
            }
          }
          if (this.selectedRCA.StatusCauseEffect === 'Open') {
            if (
              this.selectedRCA.StatusFiveWhy == 'Completed' ||
              this.selectedRCA.StatusFiveWhyAction == 'Completed'
            ) {
              this.selectedRCA.StatusCauseEffect = '-';
            }
            if (this.selectedRCA.disableCauseAndEffect) {
              this.selectedRCA.StatusCauseEffect = '-';
            }
          }
          if (this.selectedRCA.StatusFiveWhy === 'Open') {
            if (this.selectedRCA.StatusFiveWhyAction == 'Completed') {
              this.selectedRCA.StatusFiveWhy = '-';
            }
            if (this.selectedRCA.disableFiveWhy) {
              this.selectedRCA.StatusFiveWhy = '-';
            }
          }

          if (this.selectedRCA.StatusFiveWhyAction === 'Open') {
            if (this.selectedRCA.StatusFiveWhy == 'Completed') {
              this.selectedRCA.StatusFiveWhyAction = '-';
            }
            if (this.selectedRCA.disableActionPlan) {
              this.selectedRCA.StatusFiveWhyAction = '-';
            }
          }
          if (this.selectedRCA.Status6W2H == null) {
            if (
              this.selectedRCA.StatusFishbone == null ||
              this.selectedRCA.StatusFiveWhy == null ||
              this.selectedRCA.StatusFiveWhyAction == null
            ) {
              this.selectedRCA.Status6W2H = '-';
              this.selectedRCA.disableSixWTwo = true;
            }
          }

          if (this.selectedRCA.StatusFishbone == null) {
            if (
              this.selectedRCA.Status6W2H == null ||
              this.selectedRCA.StatusFiveWhy == null ||
              this.selectedRCA.StatusFiveWhyAction == null
            ) {
              this.selectedRCA.StatusFishbone = '-';
              this.selectedRCA.disableFishbone = true;
            }
          }

          if (
            this.selectedRCA.StatusCauseEffect == null ||
            (this.selectedRCA.StatusFiveWhy == null &&
              this.selectedRCA.StatusFiveWhyAction == null)
          ) {
            this.selectedRCA.StatusCauseEffect = '-';
            this.selectedRCA.disableCauseAndEffect = true;
          }

          if (
            this.selectedRCA.StatusFiveWhy === null &&
            this.selectedRCA.StatusFiveWhyAction === null
          ) {
            this.selectedRCA.StatusFiveWhy = '-';
            this.selectedRCA.disableFiveWhy = true;
          }

          if (
            this.selectedRCA.StatusFishbone == null ||
            (this.selectedRCA.StatusFishbone == '-' &&
              this.selectedRCA.StatusCauseEffect == 'Completed')
          ) {
            this.selectedRCA.StatusFishbone = '-';
            this.selectedRCA.disableFishbone = true;
          }

          if (
            this.selectedRCA.StatusFiveWhy == 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.StatusFiveWhyAction = 'Open';
          }

          if (this.selectedRCA.StatusFiveWhy == null) {
            if (
              this.selectedRCA.StatusFiveWhyAction == 'InProgress' ||
              this.selectedRCA.StatusFiveWhyAction == 'Completed'
            ) {
              this.selectedRCA.StatusFiveWhy = '-';
              this.selectedRCA.disableFiveWhy = true;
            }
          }

          if (
            this.selectedRCA.Status6W2H === 'Open' &&
            this.selectedRCA.StatusCauseEffect === '-' &&
            this.selectedRCA.StatusFishbone === '-' &&
            this.selectedRCA.StatusFiveWhy === '-' &&
            this.selectedRCA.StatusFiveWhyAction === '-'
          ) {
            this.selectedRCA.StatusFishbone = '-';
            this.selectedRCA.disableFishbone = true;
          }

          if (
            this.selectedRCA.Status6W2H === '-' &&
            this.selectedRCA.StatusCauseEffect === '-' &&
            this.selectedRCA.StatusFishbone === '-' &&
            this.selectedRCA.StatusFiveWhy === 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction === 'Completed'
          ) {
            this.selectedRCA.disableCauseAndEffect = true;
            this.selectedRCA.StatusCauseEffect = '-';
          }
          //inprogress cases

          if (
            this.selectedRCA.Status6W2H == 'InProgress' &&
            this.selectedRCA.StatusFishbone == 'Open' &&
            this.selectedRCA.StatusCauseEffect == '-' &&
            this.selectedRCA.StatusFiveWhy == '-' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableFishbone = true;
            this.selectedRCA.StatusFishbone = '-';
          }
          if (
            this.selectedRCA.Status6W2H == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'InProgress' &&
            this.selectedRCA.StatusCauseEffect == 'Open' &&
            this.selectedRCA.StatusFiveWhy == '-' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableCauseAndEffect = true;
            this.selectedRCA.StatusCauseEffect = '-';
          }

          if (
            this.selectedRCA.Status6W2H == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusCauseEffect == 'InProgress' &&
            this.selectedRCA.StatusFiveWhy == 'Open' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableFiveWhy = true;
            this.selectedRCA.StatusFiveWhy = '-';
          }

          if (
            this.selectedRCA.Status6W2H == '-' &&
            this.selectedRCA.StatusCauseEffect == '-' &&
            this.selectedRCA.StatusFishbone == '-' &&
            this.selectedRCA.StatusFiveWhy == null &&
            this.selectedRCA.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedRCA.disableFiveWhy = true;
            this.selectedRCA.StatusFiveWhy = '-';
          }

          if (
            this.selectedRCA.Status6W2H == '-' &&
            this.selectedRCA.StatusCauseEffect == 'InProgress' &&
            this.selectedRCA.StatusFishbone == '-' &&
            this.selectedRCA.StatusFiveWhy == 'Open' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableFiveWhy = true;
            this.selectedRCA.StatusFiveWhy = '-';
          }

          if (
            this.selectedRCA.Status6W2H == 'InProgress' &&
            this.selectedRCA.StatusCauseEffect == 'Open' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == '-' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableCauseAndEffect = true;
            this.selectedRCA.StatusCauseEffect = '-';
          }

          if (
            this.selectedRCA.Status6W2H == '-' &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == '-' &&
            this.selectedRCA.StatusFiveWhy == 'InProgress' &&
            this.selectedRCA.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedRCA.disableActionPlan = true;
            this.selectedRCA.StatusFiveWhyAction = '-';
          }

          if (
            this.selectedRCA.Status6W2H == '-' &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'InProgress' &&
            this.selectedRCA.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedRCA.disableActionPlan = true;
            this.selectedRCA.StatusFiveWhyAction = '-';
          }

          if (
            this.selectedRCA.Status6W2H == 'Completed' &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'InProgress' &&
            this.selectedRCA.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedRCA.disableActionPlan = true;
            this.selectedRCA.StatusFiveWhyAction = '-';
          }

          if (
            this.selectedRCA.Status6W2H == 'InProgress' &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'Open' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableFiveWhy = true;
            this.selectedRCA.StatusFiveWhy = '-';
          }

          if (
            this.selectedRCA.Status6W2H == '-' &&
            this.selectedRCA.StatusCauseEffect == 'InProgress' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedRCA.disableActionPlan = true;
            this.selectedRCA.StatusFiveWhyAction = '-';
          }

          if (
            this.selectedRCA.Status6W2H == '-' &&
            this.selectedRCA.StatusCauseEffect == 'Open' &&
            this.selectedRCA.StatusFishbone == 'InProgress' &&
            this.selectedRCA.StatusFiveWhy == '-' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableCauseAndEffect = true;
            this.selectedRCA.StatusCauseEffect = '-';
          }

          if (
            this.selectedRCA.Status6W2H == 'InProgress' &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedRCA.disableActionPlan = true;
            this.selectedRCA.StatusFiveWhyAction = '-';
          }
          Status6W2H: 'Completed';
          StatusCauseEffect: 'InProgress';
          StatusFishbone: 'Completed';
          StatusFiveWhy: 'Completed';
          StatusFiveWhyAction: 'Open';
          if (
            this.selectedRCA.Status6W2H == 'Completed' &&
            this.selectedRCA.StatusCauseEffect == 'InProgress' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedRCA.disableActionPlan = true;
            this.selectedRCA.StatusFiveWhyAction = '-';
          }
          Status6W2H: null;
          StatusCauseEffect: 'Open';
          StatusCycle: 'InProgress';
          StatusFishbone: 'InProgress';
          StatusFiveWhy: '-';
          StatusFiveWhyAction: '-';
          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == 'Open' &&
            this.selectedRCA.StatusFishbone == 'InProgress' &&
            this.selectedRCA.StatusFiveWhy == '-' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableCauseAndEffect = true;
            this.selectedRCA.StatusCauseEffect = '-';
          }
          Status6W2H: null;
          StatusCauseEffect: '-';
          StatusFishbone: 'InProgress';
          StatusFiveWhy: '-';
          StatusFiveWhyAction: '-';
          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == '-' &&
            this.selectedRCA.StatusFishbone == 'InProgress' &&
            this.selectedRCA.StatusFiveWhy == '-' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.Status6W2H = '-';
          }
          Status6W2H: null;
          StatusCauseEffect: 'Open';
          StatusFishbone: 'Completed';
          StatusFiveWhy: '-';
          StatusFiveWhyAction: '-';
          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == 'Open' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == '-' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.Status6W2H = '-';
          }
          Status6W2H: null;
          StatusCauseEffect: 'Completed';

          StatusFishbone: 'Completed';
          StatusFiveWhy: 'Open';
          StatusFiveWhyAction: '-';
          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'Open' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.Status6W2H = '-';
          }
          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction == 'Open'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.Status6W2H = '-';
          }
          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction == 'Completed'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.Status6W2H = '-';
          }
          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == 'InProgress' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction == 'Completed'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.Status6W2H = '-';
          }
          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'InProgress' &&
            this.selectedRCA.StatusFiveWhy == 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction == 'Completed'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.Status6W2H = '-';
          }
          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'InProgress' &&
            this.selectedRCA.StatusFiveWhyAction == 'Completed'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.Status6W2H = '-';
          }

          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == '-' &&
            this.selectedRCA.StatusFishbone == 'Open' &&
            this.selectedRCA.StatusFiveWhy == '-' &&
            this.selectedRCA.StatusFiveWhyAction == '-'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.Status6W2H = '-';
          }
          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction == 'InProgress'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.Status6W2H = '-';
          }
          if (
            this.selectedRCA.Status6W2H == null &&
            this.selectedRCA.StatusCauseEffect == 'Completed' &&
            this.selectedRCA.StatusFishbone == 'Completed' &&
            this.selectedRCA.StatusFiveWhy == 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction == 'InProgress'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.Status6W2H = '-';
          }
          Status6W2H: '-';
          StatusCauseEffect: 'InProgress';
          StatusCycle: 'InProgress';
          StatusFishbone: '-';
          StatusFiveWhy: '-';
          StatusFiveWhyAction: '-';
          if (
            this.selectedRCA.Status6W2H == '-' &&
            this.selectedRCA.StatusFishbone == '-'
          ) {
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.disableFishbone = true;
            this.selectedRCA.Status6W2H = '-';
            this.selectedRCA.StatusFishbone = '-';
          }
          Status6W2H: '-';
          StatusCauseEffect: '-';
          StatusFishbone: '-';
          StatusFiveWhy: '-';
          StatusFiveWhyAction: 'Completed';

          if (
            this.selectedRCA.Status6W2H === '-' &&
            this.selectedRCA.StatusCauseEffect === '-' &&
            this.selectedRCA.StatusFishbone === '-' &&
            this.selectedRCA.StatusFiveWhy === '-' &&
            this.selectedRCA.StatusFiveWhyAction === 'Completed'
          ) {
            this.selectedRCA.disableFiveWhy = true;
            this.selectedRCA.disableSixWTwo = true;
            this.selectedRCA.disableFishbone = true;
            this.selectedRCA.disableCauseAndEffect = true;
            this.selectedRCA.Status6W2H = '-';
            this.selectedRCA.StatusCauseEffect = '-';
            this.selectedRCA.StatusFishbone = '-';
            this.selectedRCA.StatusFiveWhy = '-';
          }
          if (
            this.selectedRCA.Status6W2H === '-' &&
            this.selectedRCA.StatusCauseEffect === 'Completed' &&
            this.selectedRCA.StatusFishbone === 'Completed' &&
            this.selectedRCA.StatusFiveWhy === 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction === 'Completed'
          ) {
            this.selectedRCA.disableSixWTwo = true;
          }
          Status6W2H: '-';
          StatusCauseEffect: '-';
          StatusFishbone: '-';
          StatusFiveWhy: 'Completed';
          StatusFiveWhyAction: '-';
          if (
            this.selectedRCA.Status6W2H === '-' &&
            this.selectedRCA.StatusCauseEffect === '-' &&
            this.selectedRCA.StatusFishbone === '-' &&
            this.selectedRCA.StatusFiveWhy === 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction === '-'
          ) {
            this.selectedRCA.disableCauseAndEffect = true;
          }
          if (
            this.selectedRCA.Status6W2H === '-' &&
            this.selectedRCA.StatusCauseEffect === '-' &&
            this.selectedRCA.StatusFishbone === '-' &&
            this.selectedRCA.StatusFiveWhy === 'Completed' &&
            this.selectedRCA.StatusFiveWhyAction === 'Open'
          ) {
            this.selectedRCA.disableCauseAndEffect = true;
          }
          //fiveWhyAction Cases
          if (this.selectedRCA.StatusFiveWhy == 'Completed') {
            this.selectedRCA.disableActionPlan = false;
          }
          if (this.selectedRCA.StatusFiveWhy == 'InProgress') {
            this.selectedRCA.disableActionPlan = false;
          }
          if (this.selectedRCA.StatusFiveWhy == 'Open') {
            this.selectedRCA.disableActionPlan = true;
          }
          if (
            this.selectedRCA.Status6W2H == 'InProgress' ||
            this.selectedRCA.StatusCauseEffect == 'InProgress' ||
            this.selectedRCA.StatusFishbone == 'InProgress' ||
            this.selectedRCA.StatusFiveWhy == 'InProgress' ||
            this.selectedRCA.StatusFiveWhyAction == 'InProgress'
          ) {
            if (this.selectedRCA.Status6W2H == 'InProgress') {
              this.selectedRCA.disableSixWTwo = false;
              this.selectedRCA.disableFishbone = true;
              this.selectedRCA.disableCauseAndEffect = true;
              this.selectedRCA.disableFiveWhy = true;
              this.selectedRCA.disableActionPlan = true;
            } else if (this.selectedRCA.StatusFishbone == 'InProgress') {
              this.selectedRCA.disableSixWTwo = true;

              this.selectedRCA.disableFishbone = false;

              this.selectedRCA.disableCauseAndEffect = true;
              this.selectedRCA.disableFiveWhy = true;
              this.selectedRCA.disableActionPlan = true;
            } else if (this.selectedRCA.StatusCauseEffect == 'InProgress') {
              this.selectedRCA.disableSixWTwo = true;
              if (this.selectedRCA.StatusFishbone !== '-') {
                this.selectedRCA.disableFishbone = false;
              } else {
                this.selectedRCA.disableFishbone = true;
              }

              this.selectedRCA.disableCauseAndEffect = false;
              this.selectedRCA.disableFiveWhy = true;
              this.selectedRCA.disableActionPlan = true;
            } else if (this.selectedRCA.StatusFiveWhy == 'InProgress') {
              if (this.selectedRCA.StatusCauseEffect !== '-') {
                this.selectedRCA.disableCauseAndEffect = false;
              } else {
                this.selectedRCA.disableCauseAndEffect = true;
              }
              if (this.selectedRCA.StatusFishbone !== '-') {
                this.selectedRCA.disableFishbone = false;
              } else {
                this.selectedRCA.disableFishbone = true;
              }
              this.selectedRCA.disableSixWTwo = true;
              this.selectedRCA.disableFiveWhy = false;
              this.selectedRCA.disableActionPlan = false;
            } else if (this.selectedRCA.StatusFiveWhyAction == 'InProgress') {
              if (this.selectedRCA.StatusFishbone !== '-') {
                this.selectedRCA.disableFishbone = false;
              } else {
                this.selectedRCA.disableFishbone = true;
              }
              if (this.selectedRCA.StatusCauseEffect !== '-') {
                this.selectedRCA.disableCauseAndEffect = false;
              } else {
                this.selectedRCA.disableCauseAndEffect = true;
              }
              if (this.selectedRCA.StatusFiveWhy !== '-') {
                this.selectedRCA.disableFiveWhy = false;
              } else {
                this.selectedRCA.disableFiveWhy = true;
              }

              this.selectedRCA.disableSixWTwo = true;

              this.selectedRCA.disableActionPlan = false;
            }
          }

          if (
            this.selectedRCA.StatusFiveWhy == 'InProgress' ||
            this.selectedRCA.StatusFiveWhy == 'Completed'
          ) {
            if (this.selectedRCA.StatusFiveWhyAction == '-') {
              this.selectedRCA.StatusFiveWhyAction = 'Open';
            }
          }

        } else {
          this.selectedRCA = {};
          this.selectedRCA.Status6W2H = '-';
          this.selectedRCA.StatusFishbone = '-';
          this.selectedRCA.StatusCauseEffect = '-';
          this.selectedRCA.StatusFiveWhy = '-';
          this.selectedRCA.StatusFiveWhyAction = '-';

          this.changeIndexValue(0);
        }
        this.afterSaveMoveTabStrategy();
        this.headerData.UploadFile = this.selectedRCA.UploadFile;
        this.isLoading = false;
        if (this.selectedRCA.ID) {
          this.setRCACode();
        }

        if (!this.firstReload) {
          let route = this.router.url;
          if (
            route.includes('MeetingMatrixV2') ||
            route.includes('KPIGapAnalysis') ||
            route.includes('Scorecard')
          ) {
            if (route.includes('MeetingMatrixV2') && this.data?.hideTabs) {
              this.changeIndexValue(0);
            } else {
              this.changeIndexValue(4);
            }
            this.firstReload = true;
          } else if (route.includes('KpiView')) {
            this.changeIndexValue(0);
            this.tabsShowHideStrategy();
            this.firstReload = true;
          } else {
            this.moveToActiveTab();
          }
        }
        this.navigationButtons();

        if (this.selectedRCA.StatusFiveWhyAction == 'Completed') {
          this.dialogRef;
        }
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }
  afterSaveMoveTabStrategy() {
    let rca = this.selectedRCA;
    if (!this.data?.hideTabs) {
      if (this.selectedIndex === 0 && rca.disableSixWTwo) {
        this.changeIndexValue(1);
      }
      if (this.selectedIndex === 1 && rca.disableFishbone) {
        this.changeIndexValue(2);
      }
      if (this.selectedIndex === 2 && rca.disableCauseAndEffect) {
        this.changeIndexValue(3);
      }
      if (this.selectedIndex === 3 && rca.disableFiveWhy) {
        this.changeIndexValue(4);
      }
    }
    if (this.data?.hideTabs && this.data?.componentName === 'kpiView') {
      return;
    }
  }
  setRCACode() {
    this.headerData.uniqueCode =
      this.selectedRCA?.ID != 0 ? 'AT-RCA-' + this.selectedRCA?.ID : undefined;
  }
  moveToActiveTab() {
    let rca = this.selectedRCA;
    if (
      rca.Status6W2H == 'InProgress' ||
      rca.StatusFishbone == 'InProgress' ||
      rca.StatusCauseEffect == 'InProgress' ||
      rca.StatusFiveWhy == 'InProgress' ||
      rca.StatusFiveWhyAction == 'InProgress'
    ) {
      if (rca.Status6W2H == 'InProgress') {
        this.changeIndexValue(0);
      } else if (rca.StatusFishbone == 'InProgress') {
        this.changeIndexValue(1);
      } else if (rca.StatusCauseEffect == 'InProgress') {
        this.changeIndexValue(2);
      } else if (rca.StatusFiveWhy == 'InProgress') {
        this.changeIndexValue(3);
      } else if (rca.StatusFiveWhyAction == 'InProgress') {
        this.changeIndexValue(4);
      }
    } else if (
      rca.Status6W2H == 'Open' ||
      rca.StatusFishbone == 'Open' ||
      rca.StatusCauseEffect == 'Open' ||
      rca.StatusFiveWhy == 'Open' ||
      rca.StatusFiveWhyAction == 'Open'
    ) {
      if (rca.Status6W2H == 'Open') {
        this.changeIndexValue(0);
      } else if (rca.StatusFishbone == 'Open') {
        this.changeIndexValue(1);
      } else if (rca.StatusCauseEffect == 'Open') {
        this.changeIndexValue(2);
      } else if (rca.StatusFiveWhy == 'Open') {
        this.changeIndexValue(3);
      } else if (rca.StatusFiveWhyAction == 'Open') {
        this.changeIndexValue(4);
      }
    } else if (
      rca.Status6W2H == 'Completed' ||
      rca.StatusFishbone == 'Completed' ||
      rca.StatusCauseEffect == 'Completed' ||
      rca.StatusFiveWhy == 'Completed' ||
      rca.StatusFiveWhyAction == 'Completed'
    ) {
      this.changeIndexValue(4);
    } else if (
      !rca.disableSixWTwo ||
      !rca.disableFishbone ||
      !rca.disableCauseAndEffect ||
      !rca.disableFiveWhy ||
      !rca.disableActionPlan
    ) {
      if (!rca.disableSixWTwo) {
        this.changeIndexValue(0);
      } else if (!rca.disableFishbone) {
        this.changeIndexValue(1);
      } else if (!rca.disableCauseAndEffect) {
        this.changeIndexValue(2);
      } else if (!rca.disableFiveWhy) {
        this.changeIndexValue(3);
      } else if (!rca.disableActionPlan) {
        this.changeIndexValue(4);
      }
    }

    this.firstReload = true;
  }

  async onSubmit(formData: any, formDirective: FormGroupDirective) {
    let rca = this.selectedRCA;
    if (!this.selectedRCA.StatusCycle) {
      this.headerData.UploadFile = this.imageFile;
    } else {
      if (this.imageFile) {
        this.headerData.UploadFile = this.imageFile;
      } else if (this.headerData.UploadFile) {

        let file = this.headerData.UploadFile;
        if (typeof file === 'string') {
          const fileExtention = file.split('.').pop();
          let extentionRemoved = file.split('at/').pop();
          const fileName = extentionRemoved.split('.').slice(0, -1).join('.');
          let image = environment.O3CoreApiImageUrl + this.headerData.UploadFile;
          this.headerData.UploadFile = await this.imageURLToFile(
            image,
            fileName,
            fileExtention
          );
        } else {
        }
      }
    }
    this.headerData.ID = this.headerId;
    if (this.headerData.stageID != 6) delete this.headerData.swotAndPest;
    if (this.searchDefectForm.valid) this.save.emit(this.headerData);
    this.formDirective = formDirective;

    if (this.headerData.Team.length == 0 || this.headerData.TeamLeader == '') {
      return;
    }

    const integrationData = {
      tableId: this.data.tableId,
      moduleId: this.data.moduleId,
      meetingId: this.data.meetingId,
      kpiId: this.data.KPIId,
      meetingAttendId: this.data.MeetingAttendId,
    };
    this.conditionalSubmission(rca, integrationData);
  }
  async imageURLToFile(url, name, defaultType = 'image/jpeg') {
    // here
    // url is http://localhost:4000/uploads/1651733092191-ClockWork_2-min.jpg
    // name is ClockWork_2-min.jpg (with extension)
    // defaultType would be image/jpeg, image/png, image/jpg
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: defaultType,
    };
    let file = new File([data], name, metadata);
    return file;
    // ... do something with the file or return it
  }

  conditionalSubmission(rca, integrationData) {
    if (!this.data?.hideTabs) {
      if (this.selectedIndex === 0) {
        this.headerData.hasRca = true;
        this.analyticalToolsService.setRca(true);

        this.sixWTwoh_.emit({
          headerData: this.headerData,
          integrationData: integrationData,
        });
      }
      if (this.selectedIndex === 1) {
        this.fishBone_.emit({
          headerData: {
            ...this.headerData,
          },
          integrationData: integrationData,
        });
      }
      if (this.selectedIndex === 2) {
        this.causeAndEffects_.emit({
          headerData: {
            ...this.headerData,
          },
          integrationData: integrationData,
        });
      }
      if (this.selectedIndex === 3) {
        this.fiveWhy_.emit({
          headerData: {
            ...this.headerData,
            StatusCauseEffect: rca.StatusCauseEffect,
            StatusFiveWhy: rca.StatusFiveWhy,
            StatusFiveWhyAction: rca.StatusFiveWhyAction,
            StatusFishbone: rca.StatusFishbone,
            Status6W2H: rca.Status6W2H,
          },
          integrationData: integrationData,
        });
      }
      if (this.selectedIndex === 4) {
        this.actionPlan_.emit({
          headerData: {
            ...this.headerData,
            StatusCauseEffect: rca.StatusCauseEffect,
            StatusFiveWhy: rca.StatusFiveWhy,
            StatusFiveWhyAction: rca.StatusFiveWhyAction,
            StatusFishbone: rca.StatusFishbone,
            Status6W2H: rca.Status6W2H,
          },
          integrationData: integrationData,
        });
      }
    }
    const components = ['kpiView', 'meeting-matrixV2'];
    if (this.data?.hideTabs && components.includes(this.data?.componentName)) {
      if (this.selectedIndex === 0) {
        this.actionPlan_.emit({
          headerData: {
            ...this.headerData,
            StatusCauseEffect: rca.StatusCauseEffect,
            StatusFiveWhy: rca.StatusFiveWhy,
            StatusFiveWhyAction: rca.StatusFiveWhyAction,
            StatusFishbone: rca.StatusFishbone,
            Status6W2H: rca.Status6W2H,
          },
          integrationData: integrationData,
        });
      }
    }
  }
  tabsShowHideStrategy() {
    let route = this.router.url;
    const disableWhen = ['/KPI/KpiView'];
    if (disableWhen.includes(route)) {
      this.selectedRCA.disableSixWTwo = true;
      this.selectedRCA.disableFishbone = true;
      this.selectedRCA.disableCauseAndEffect = true;
      this.selectedRCA.disableFiveWhy = true;
    }
  }
  pageSettings(type: string, last: boolean) {
    if (type == 'previous') {
      if (last) this.currentIndex = 0;
      else this.currentIndex -= 1;
    } else {
      if (last) this.currentIndex = this.filteredData.length - 1;
      else this.currentIndex += 1;
    }
    this.analyticalToolsService.setFilteredIndex(this.currentIndex);
    this.analyticalToolsService.setHeaderId(
      this.filteredData[this.currentIndex].ID
    );
    this.emitSearchData.emit(this.filteredData[this.currentIndex]);
  }

  onKey(value: any, type: any) {
    if (type == 'group') {
      this.groupList = this.searchKey(value.value, type);
    } else if (type == 'plant') {
      this.plantList = this.searchKey(value.value, type);
    }
  }

  searchKey(value: string, type: string) {
    let filter = value;
    if (type == 'group') {
      return this.groupTempList.filter((dep: any) => {
        let lowerCase = dep.TypeName.toLocaleLowerCase();
        return dep.TypeName.startsWith(filter) || lowerCase.startsWith(filter);
      });
    } else if (type == 'plant') {
      return this.plantTempList.filter((pla: any) => {
        let lowerCase = pla.DeptDesc.toLocaleLowerCase();
        return pla.DeptDesc.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
  }

  search() {
    this.spinnerText = 'Searching Data';
    const queryData = Object.assign({}, this.headerData);
    if (this.headerData.TeamLeader) {
      let obj = this.teams.find(
        (team: any) => team.EmpInfo_Id == this.headerData.TeamLeader
      );
      if (!obj) {
        this.headerData.Team = '';
        this.headerData.TeamLeader = '';
        this.headerData.InitProbStatement = '';
      }
    }
    // if (this.stageID == 6)
    delete queryData.NoOfM;
    // else if (this.stageID == 2 || this.stageID == 3)
    //   delete queryData.InitProbStatement;
    delete queryData.CreatedBy;
    // delete queryData.uniqueCode;
    this.spinner.show();
    if (!this.headerData.Department) {
      delete queryData.Department;
    }
    if (!this.headerData.Team) {
      delete queryData.Team;
    }
    if (!this.headerData.InitProbStatement) {
      delete queryData.InitProbStatement;
    }
    if (!this.headerData.TeamLeader) {
      delete queryData.TeamLeader;
    }
    this.analyticalToolsService.searchHeader(queryData).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.data && res.data.length) {
          this.filteredData = res.data.filter(
            (data: any) => data.swth && data.swth.ID
          );
          if (this.filteredData && !this.filteredData.length) {
            this.filteredData = res.data.filter(
              (data: any) => data.fishBone && data.fishBone.ID
            );
            if (this.filteredData && !this.filteredData.length) {
              this.filteredData = res.data.filter(
                (data: any) =>
                  data.causeAndEffects && data.causeAndEffects.length
              );
              if (this.filteredData && !this.filteredData.length) {
                this.filteredData = res.data.filter(
                  (data: any) => data.fiveWhy && data.fiveWhy.length
                );
                if (this.filteredData && !this.filteredData.length) {
                  this.filteredData = res.data.filter(
                    (data: any) => data.swotAndPest && data.swotAndPest.length
                  );
                } else {
                  let arr = res.data.filter(
                    (data: any) => data.swth && data.swth.ID
                  );
                  arr = arr && arr.length ? arr : [];
                  if (arr && arr.length) {
                    for (const ar of arr) {
                      const isExist = this.filteredData.find(
                        (fildata: any) => fildata.ID == ar.ID
                      );
                      if (!isExist) this.filteredData.push(ar);
                    }
                  }

                  arr = res.data.filter(
                    (data: any) => data.fishBone && data.fishBone.ID
                  );
                  arr = arr && arr.length ? arr : [];
                  if (arr && arr.length) {
                    for (const ar of arr) {
                      const isExist = this.filteredData.find(
                        (fildata: any) => fildata.ID == ar.ID
                      );
                      if (!isExist) this.filteredData.push(ar);
                    }
                  }

                  arr = res.data.filter(
                    (data: any) =>
                      data.causeAndEffects && data.causeAndEffects.length
                  );
                  arr = arr && arr.length ? arr : [];
                  if (arr && arr.length) {
                    for (const ar of arr) {
                      const isExist = this.filteredData.find(
                        (fildata: any) => fildata.ID == ar.ID
                      );
                      if (!isExist) this.filteredData.push(ar);
                    }
                  }
                }
              } else {
                let arr = res.data.filter(
                  (data: any) => data.swth && data.swth.ID
                );
                arr = arr && arr.length ? arr : [];
                if (arr && arr.length) {
                  for (const ar of arr) {
                    const isExist = this.filteredData.find(
                      (fildata: any) => fildata.ID == ar.ID
                    );
                    if (!isExist) this.filteredData.push(ar);
                  }
                }

                arr = res.data.filter(
                  (data: any) => data.fishBone && data.fishBone.ID
                );
                arr = arr && arr.length ? arr : [];
                if (arr && arr.length) {
                  for (const ar of arr) {
                    const isExist = this.filteredData.find(
                      (fildata: any) => fildata.ID == ar.ID
                    );
                    if (!isExist) this.filteredData.push(ar);
                  }
                }

                arr = res.data.filter(
                  (data: any) => data.fiveWhy && data.fiveWhy.length
                );
                arr = arr && arr.length ? arr : [];
                if (arr && arr.length) {
                  for (const ar of arr) {
                    const isExist = this.filteredData.find(
                      (fildata: any) => fildata.ID == ar.ID
                    );
                    if (!isExist) this.filteredData.push(ar);
                  }
                }
              }
            } else {
              let arr = res.data.filter(
                (data: any) => data.swth && data.swth.ID
              );
              arr = arr && arr.length ? arr : [];
              if (arr && arr.length) {
                for (const ar of arr) {
                  const isExist = this.filteredData.find(
                    (fildata: any) => fildata.ID == ar.ID
                  );
                  if (!isExist) this.filteredData.push(ar);
                }
              }

              arr = res.data.filter(
                (data: any) =>
                  data.causeAndEffects && data.causeAndEffects.length
              );
              arr = arr && arr.length ? arr : [];
              if (arr && arr.length) {
                for (const ar of arr) {
                  const isExist = this.filteredData.find(
                    (fildata: any) => fildata.ID == ar.ID
                  );
                  if (!isExist) this.filteredData.push(ar);
                }
              }

              arr = res.data.filter(
                (data: any) => data.fiveWhy && data.fiveWhy.length
              );
              arr = arr && arr.length ? arr : [];
              if (arr && arr.length) {
                for (const ar of arr) {
                  const isExist = this.filteredData.find(
                    (fildata: any) => fildata.ID == ar.ID
                  );
                  if (!isExist) this.filteredData.push(ar);
                }
              }
            }
          } else {
            let arr = res.data.filter(
              (data: any) => data.fishBone && data.fishBone.ID
            );
            arr = arr && arr.length ? arr : [];
            if (arr && arr.length) {
              for (const ar of arr) {
                const isExist = this.filteredData.find(
                  (fildata: any) => fildata.ID == ar.ID
                );
                if (!isExist) this.filteredData.push(ar);
              }
            }

            arr = res.data.filter(
              (data: any) => data.causeAndEffects && data.causeAndEffects.length
            );
            arr = arr && arr.length ? arr : [];
            if (arr && arr.length) {
              for (const ar of arr) {
                const isExist = this.filteredData.find(
                  (fildata: any) => fildata.ID == ar.ID
                );
                if (!isExist) this.filteredData.push(ar);
              }
            }

            arr = res.data.filter(
              (data: any) => data.fiveWhy && data.fiveWhy.length
            );
            arr = arr && arr.length ? arr : [];
            if (arr && arr.length) {
              for (const ar of arr) {
                const isExist = this.filteredData.find(
                  (fildata: any) => fildata.ID == ar.ID
                );
                if (!isExist) this.filteredData.push(ar);
              }
            }
          }

          this.analyticalToolsService.setFilteredData(this.filteredData);
          if (this.filteredData && this.filteredData.length) {
            this.currentIndex = 0;
            this.analyticalToolsService.setFilteredIndex(this.currentIndex);
            this.analyticalToolsService.setHeaderId(
              this.filteredData[this.currentIndex].ID
            );
            this.emitSearchData.emit(this.filteredData[this.currentIndex]);
          }
        } else this.toaster.info('Info', res.message);
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  openFile() {
    this.fileInput.nativeElement.click();
  }

  readFile(event: any) {
    if (event.target.files && event.target.files.length) {
      this.imageFile = event.target.files[0];
      // this.imageFileName = this.imageFile.name;

      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = async () => {
          this.imageSrc = reader.result as string;
        };
      }
    }
  }

  getFileTypeName(val: any) {
    let arr = val.split('.');
    if (arr && arr.length && arr.length != 1)
      return `( ${arr[arr.length - 1]} )`;
    else return ``;
  }

  imageZoomModal(): void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      width: '500px',
      height: '500px',
      data: this.imageSrc
        ? this.imageSrc
        : this.ImageUrl + this.headerData.UploadFile,
    });
  }

  emitPlantChange() {
    this.plantChange.emit();
  }

  //tabs

  onTabChange(event: any) {
    let type: string;
    if (event.index < this.selectedIndex) {
      type = 'previous';
    } else if (event.index > this.selectedIndex) {
      type = 'next';
    }

    this.selectedIndex = event.index;

    this.navigationButtons();
    // this.navigate(type);
  }

  nullifySubject(event: any) {
    this.getUniqueCode();
    if (event == 'sixw2h') {
      this.stageID = 1;
    }
    if (event == 'fishBone') {
      this.stageID = 2;
    }
    if (event == 'causeAndEffects') {
      this.stageID = 3;
    }
    if (event == 'fiveWhy') {
      this.stageID = 4;
    }
  }

  isShowAllowInitProb() {
    let router = this.router.url;
    if (router.includes('MeetingMatrixV2')) {
      return false;
    }
    if (router.includes('NcrManagement')) {
      return true;
    }
    if (router.includes('CcrManagement')) {
      return true;
    }
  }
  hideAndShowFilters() {
    const notShowWhen = ['/PerformanceManagement/MeetingMatrixV2'];
    const route = this.router.url;
    if (notShowWhen.includes(route)) {
      return false;
    }
    return true;
  }
  layoutShowHide() {
    const notShowWhen = ['/PerformanceManagement/MeetingMatrixV2'];
    const route = this.router.url;
    if (notShowWhen.includes(route)) {
      return false;
    }
    return true;
  }
  closePopUpModal(): void {
    this.dialogRef.close({
      StatusFiveWhyAction: this.selectedRCA.StatusFiveWhyAction,
      StatusCycle: this.selectedRCA.StatusCycle,
      headerID: this.analyticalToolsService.getHeaderId(),
    });
  }
  cloneForm() {
    this._cloneForm.emit(true);
  }

  patchHeaderByProps(props) {
    this.headerData.Plant = props?.Plant;
    this.headerData.Group = props?.Group;
    const participants = props?.participants?.map((x) => x.EmpInfo_Id);
    this.headerData.TeamLeader = props?.leaderId;
    this.headerData.Team = participants;
  }
  patchHeaderByNCRModule(e: any) {
    this.headerData.Plant = e?.Plant;
    this.headerData.Group = e?.Group;

    this.ncrService.getRCAStatus({ headerId: +e?.headerId }).subscribe(
      (response: any) => {
        if (!response.length) return;
        let Team = response[0]?.Team.split(',');

        this.headerData.TeamLeader = response[0]?.TeamLeader;
        this.headerData.Team = Array.from(Team, Number);
        this.headerData.uniqueCode = `AT-RCA-${response[0]?.ID}`;
        this.headerData.UploadFile = response[0].UploadFile;

        // this.headerData.Dept = response[0]?.Dept ;
      },
      (error: any) => {}
    );
  }

  changeIndexValue(index) {
    console.trace();
    this.selectedIndex = index;
  }
  patchHeaderByKPIModule(e: any) {
    this.ncrService.getRCAStatus({ headerId: +e?.headerId }).subscribe(
      (response: any) => {
        if (!response.length) return;
        let Team = response[0]?.Team.split(',');

        this.headerData.TeamLeader = response[0]?.TeamLeader;
        this.headerData.Team = Array.from(Team, Number);
        this.headerData.uniqueCode = `AT-RCA-${response[0]?.ID}`;
        this.headerData.UploadFile = response[0].UploadFile;
        this.headerData.InitProbStatement = response[0].InitProbStatement;
        // this.headerData.Dept = response[0]?.Dept ;
      },
      (error: any) => {}
    );
  }

  navigate(type: string) {
    let rca = this.selectedRCA;

    if (type == 'next') {
      if (this.selectedIndex === 0) {
        if (!rca.disableFishbone) {
          this.changeIndexValue(1);
          return;
        }
      }
      if (this.selectedIndex === 1) {
        if (!rca.disableCauseAndEffect) {
          this.changeIndexValue(2);
          return;
        }
      }
      if (this.selectedIndex === 2) {
        if (!rca.disableFiveWhy) {
          this.changeIndexValue(3);
          return;
        }
      }
      if (this.selectedIndex === 3) {
        if (!rca.disableActionPlan) {
          this.changeIndexValue(4);
          return;
        }
      }
    }
    if (type == 'previous') {
      if (this.selectedIndex === 0) {
        return;
      }
      if (this.selectedIndex === 1) {
        if (!rca.disableSixWTwo) {
          this.changeIndexValue(0);
          return;
        }
      }
      if (this.selectedIndex === 2) {
        if (!rca.disableFishbone) {
          this.changeIndexValue(1);
          return;
        }
      }
      if (this.selectedIndex === 3) {
        if (!rca.disableCauseAndEffect) {
          this.changeIndexValue(2);
          return;
        }
      }
      if (this.selectedIndex === 4) {
        if (!rca.disableFiveWhy) {
          this.changeIndexValue(3);
          return;
        }
      }
    }

    this.navigationButtons();
  }

  navigationButtons() {
    let rca = this.selectedRCA;
    if (this.selectedIndex === 0) {
      if (!rca.disableFishbone) {
        this.enableNext = true;
        this.enablePrev = false;
      }
      this.enablePrev = false;
    }
    if (this.selectedIndex === 1) {
      if (!rca.disableSixWTwo) {
        this.enablePrev = true;
      } else {
        this.enablePrev = false;
      }
      if (!rca.disableCauseAndEffect) {
        this.enableNext = true;
      } else {
        this.enableNext = false;
      }
    }
    if (this.selectedIndex === 2) {
      if (!rca.disableFiveWhy) {
        this.enableNext = true;
      } else {
        this.enableNext = false;
      }
      if (!rca.disableFishbone) {
        this.enablePrev = true;
      } else {
        this.enablePrev = false;
      }
    }
    if (this.selectedIndex === 3) {
      if (!rca.disableActionPlan) {
        this.enableNext = true;
      } else {
        this.enableNext = false;
      }
      if (!rca.disableFishbone) {
        this.enablePrev = true;
      } else {
        this.enablePrev = false;
      }
    }
    if (this.selectedIndex === 4) {
      if (!rca.disableFiveWhy) {
        this.enablePrev = true;
      } else {
        this.enablePrev = false;
      }
      this.enableNext = false;
    }
  }
}
