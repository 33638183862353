import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProcessExecutionService {
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  addProcessExecutionServiceExecution(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ProcessExecution/addprocessexecution', data);
  }

  getCheckList(): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + '');
  }
}
