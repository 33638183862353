import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DMSRoutingModule } from './dms-routing.module';
import { MaterialModule } from 'src/app/material-module';
import { DefectConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/defect-configuration/defect-configuration.component';
import { DefectManagementMasterComponent } from './defect-management-master/defect-management-master.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TooltipModule } from 'primeng/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DefectDashboardComponent } from './defect-dashboard/defect-dashboard.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatSelectFilterModule } from 'mat-select-filter';
import { ChartModule } from 'angular-highcharts';
import { BtnCellRendererDefect } from '../btn-cell-rendererDefect.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AngularImageViewerModule } from 'angular-x-image-viewer';
import { NgxMatSelectModule } from '../ngx-mat-select/ngx-mat-select.module';
import { DialogModule } from 'primeng/dialog';

@NgModule({
    imports: [
        CommonModule,
        DMSRoutingModule,
        MaterialModule,
        MatMenuModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatTreeModule,
        MatCardModule,
        MatSelectModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatDialogModule,
        DialogModule,
        NgxMaterialTimepickerModule,
        MaterialModule,
        MatExpansionModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        TooltipModule,
        MatTooltipModule,
        AgGridModule,
        MatSelectFilterModule,
        ChartModule,
        NgxDocViewerModule ,
        AngularImageViewerModule ,
        NgxMatSelectModule.forRoot({
            maxWidthForMobileView: 600,
            inFirstLoadCallSearcher: true,
            inFirstLoadSearcherValue: '',
            emptyLabel: 'no entry found',
            noMoreResultLabel: 'no more found',
            useInfiniteScroll: false,
            searchBoxPlaceholder: 'Search',
            maximumResultForShow: 4000,
            useMobileView: false,
            valueMember: 'key',
            displayMember: 'value',
            mobileViewType: 'FullScreen'
        })
    ],
    declarations: [
        DefectConfigurationComponent,
        DefectManagementMasterComponent,
        DefectDashboardComponent,
        BtnCellRendererDefect
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DMSModule { }
