import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Success } from '../models/success';

@Injectable({
  providedIn: 'root'
})
export class GlassPpcLiveGeneraldataService {
  baseUrl = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  GetNetWidth(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPpcLiveGeneralData/GetNetWidth',data
    );
  }

  GetStackerList() {
    return this.http.get(
      this.baseUrl + 'GlassPpcLiveGeneralData/GetStackerList'
    );
  }

  GetJobList(data: any) {
    return this.http.post(
      this.baseUrl + 'GlassPpcLiveGeneralData/GetJobList', data
    );
  }
  GetLiteData(data: any){
    return this.http.post(
      this.baseUrl + 'GlassPpcLiveGeneralData/GetLiteData', data
    );
  }
  GetStackerData(data: any){
    return this.http.post(
      this.baseUrl + 'GlassPpcLiveGeneralData/GetStackerData', data
    );
  }
  GetBarCodeDetailsLocalOrExport(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'BarcodePrint/GetBarCodeDetails', data
    );
  }

  GetBarCodeDetails_PPC(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'BarcodePrint/GetBarCodeDetails_PPC', data
    );
  }


  GetBarCodeDetails_PPC_CustomizedForGlass(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'BarcodePrint/GetBarCodeDetails_PPC_CustomizedForGlass', data
    );
  }

  GetBarCodeDetails_PPC_Lamination(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'BarcodePrint/GetBarCodeDetails_CustomizedForGlass_Lamination', data
    );
  }

  UpdatePalletQty(data: any){
    return this.http.post(
      this.baseUrl + 'GlassPpcLiveGeneralData/UpdatePalletQty', data
    )
  }

  UpdatePrintStatus(data: any){
    return this.http.post(
      this.baseUrl + 'GlassPpcLiveGeneralData/UpdatePrintStatus', data
    )
  }
}
