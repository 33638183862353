<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
  <p style="color: white">Fetching Data...</p>
</ngx-spinner>
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="displayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">Glass PPC Live Dashboard </h1>
    </div>
  </div>

<div class="p-grid p-fluid" style="margin-top: 10px">
    <div class="p-col-10 p-md-10 p-lg-10 p-xl-10">
        <div id="NetWidth" style="height: 300px"></div>
    </div>
    <div class="p-col-12 p-md-2 p-lg-2 p-xl-2" *ngIf="showCards">
      <div class="menu-items">
        <div class="menu-card-item chart-items ">
          <p class="title-text">Net Width</p>
          <p class="data-text">{{lastNetWidth}}
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuBlueIconUrl" alt="{{menuBlueIconUrl}}">
          </div>
        </div>
      </div>
    </div>
    
    <div class="p-col-10 p-md-10 p-lg-10 p-xl-10">
      <div id="GrossWidth" style="height: 300px"></div>
    </div>
    <div class="p-col-12 p-md-2 p-lg-2 p-xl-2" *ngIf="showCards">
    <div class="menu-items">
      <div class="menu-card-item chart-items ">
        <p class="title-text">Gross Width</p>
        <p class="data-text">{{lastGrossWidth}}
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuGreenIconUrl" alt="{{menuGreenIconUrl}}">
        </div>
      </div>
    </div>
    </div>

    <div class="p-col-10 p-md-10 p-lg-10 p-xl-10">
      <div id="UseableWidth" style="height: 300px"></div>
    </div>
    <div class="p-col-12 p-md-2 p-lg-2 p-xl-2" *ngIf="showCards">
    <div class="menu-items">
      <div class="menu-card-item chart-items ">
        <p class="title-text">Useable Width</p>
        <p class="data-text">{{lastUseableWidth}}
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuRedIconUrl" alt="{{menuRedIconUrl}}">
        </div>
      </div>
    </div>
    </div>

    <div class="p-col-10 p-md-10 p-lg-10 p-xl-10">
      <div id="LehrSpeed" style="height: 300px"></div>
    </div>
    <div class="p-col-12 p-md-2 p-lg-2 p-xl-2" *ngIf="showCards">
    <div class="menu-items">
      <div class="menu-card-item chart-items ">
        <p class="title-text">Lehr Speed</p>
        <p class="data-text">{{lastLehrSpeed}}
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuBlueIconUrl" alt="{{menuBlueIconUrl}}">
        </div>
      </div>
    </div>
    </div>
  
    <div class="p-col-10 p-md-10 p-lg-10 p-xl-10">
    <div id="LineSpeed" style="height: 300px"></div>
    </div>
    <div class="p-col-12 p-md-2 p-lg-2 p-xl-2" *ngIf="showCards">
  <div class="menu-items">
    <div class="menu-card-item chart-items ">
      <p class="title-text">LineSpeed</p>
      <p class="data-text">{{lastLineSpeed}}
      </p>
      <div class="menu-bottom-icon">
        <img class="img" [src]="menuGreenIconUrl" alt="{{menuGreenIconUrl}}">
      </div>
    </div>
  </div>
    </div>

    <div class="p-col-10 p-md-10 p-lg-10 p-xl-10">
      <div id="Yield" style="height: 300px"></div>
    </div>
    <div class="p-col-12 p-md-2 p-lg-2 p-xl-2" *ngIf="showCards">
      <div class="menu-items">
        <div class="menu-card-item chart-items ">
          <p class="title-text">Yield</p>
          <p class="data-text">{{lastYield}}
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuRedIconUrl" alt="{{menuRedIconUrl}}">
          </div>
          </div>
        </div>
    </div>
    
    <div class="p-col-10 p-md-10 p-lg-10 p-xl-10">
      <div id="NominalThickness" style="height: 300px"></div>
    </div>
    <div class="p-col-12 p-md-2 p-lg-2 p-xl-2" *ngIf="showCards">
      <div class="menu-items">
        <div class="menu-card-item chart-items ">
          <p class="title-text">Nominal Thickness</p>
          <p class="data-text">{{lastNominalThickness}}
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuBlueIconUrl" alt="{{menuBlueIconUrl}}">
          </div>
        </div>
      </div>
    </div>
</div>
