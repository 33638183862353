<mat-card-content>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
  </ngx-spinner>
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li> 
    </ul>
  </div>
  <div class="content">
    <div class="header-title">
      <h1 class="main-title">Technician</h1>
    </div>
    <form [formGroup]="HeaderForm" class="w-70">
      <div class="w-100 headerForm d-flex">
        <!-- GROUP -->
        <mat-form-field appearence="fill" class="w-20">
          <mat-label class="placeholder-title">Group </mat-label>
          <mat-select formControlName="Group" (selectionChange)="getPlants(HeaderData.Group)"
            [(ngModel)]="HeaderData.Group">
            <input (keyup)="onGroupKey($event.target)" (change)="onGroupKey('')" class="form-control w-100"
              placeholder="Search Group">
            <mat-option *ngFor="let group of GroupList" [value]="group.id">
              {{group.name}}
            </mat-option>
          </mat-select>
          <mat-error>Group is Required</mat-error>
        </mat-form-field>
        <!-- Plant -->
        <mat-form-field appearence="fill" class="w-20">
          <mat-label class="placeholder-title">Plant </mat-label>
          <mat-select formControlName="Plant" [(ngModel)]="HeaderData.Plant" placeholder="Plant"
            (selectionChange)="getFilterData()">
            <input (keyup)="onPlantKey($event.target)" (change)="onPlantKey('')" class="form-control w-100"
              placeholder="Search Plant">
            <mat-option *ngFor="let plant of PlantList" [value]="plant.id">
              {{plant.name}}
            </mat-option>
          </mat-select>
          <mat-error>Plant is Required</mat-error>
        </mat-form-field>

        <div class="inputField" style="width: 400px;">
          <nz-range-picker class="calender-input" [nzInputReadOnly]="true" [nzShowTime]="false"
            nzFormat="yyyy-MM-dd" formControlName="datePicker" [nzDisabledDate]="disabledDate" (ngModelChange)="getFilterData()">
          </nz-range-picker>
          <span class="floating-text">Date Range</span>
        </div>
        <mat-form-field appearance="fill" class="w-20">
          <mat-label>Search</mat-label>
          <input matInput type="text" (keyup)="onFilterChanged($event.target)">
        </mat-form-field>
      </div>
    </form>
    <div class="import_export">
      <button [disabled]="!IsWrite" class="export" (click)="ExportTOExcel()">Export</button>
    </div>
  </div>

  <div class="tab">
    <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="selectedFilter" (selectedTabChange)="getFilterData()">
      <mat-tab label="WorkOrder">
        <div style="margin-top: 20px;">
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="woList"
            [columnDefs]="woColumnDefs" (gridReady)="onGridReady($event)" (cellClicked)="cellClicked($event)"
            [gridOptions]="gridOptionsWO" [pagination]="true" [paginationPageSize]="10"
            [frameworkComponents]="frameworkComponents">
          </ag-grid-angular>
        </div>
      </mat-tab>
      <mat-tab label="PMTask">
        <div style="margin-top: 20px;">
          <ag-grid-angular style="width: 100%; height: 30vw;" class="ag-theme-alpine" [rowData]="pmTaskList"
            [columnDefs]="pmColumnDefs" (gridReady)="onGridReady($event)" (cellClicked)="cellClicked($event)"
            [gridOptions]="gridOptionsPM" [pagination]="true" [paginationPageSize]="10"
            [frameworkComponents]="frameworkComponents">
          </ag-grid-angular>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-template #imageViewModal>
    <button mat-mini-fab color="warn" (click)="oncloseimg()"><mat-icon>close</mat-icon></button>
    <mat-carousel timings="250ms ease-in" [autoplay]="false" interval="5000" color="accent" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
      [useMouseWheel]="true" orientation="ltr">
      <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slidesImages; let i = index" [image]="blobStorageUrl+slide"
        overlayColor="#00000040" [hideOverlay]="false"></mat-carousel-slide>
    </mat-carousel>
  </ng-template>
</mat-card-content>