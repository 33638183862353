<section class="home-content">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <mat-card style=" background-color:#0F0FD1 !important;">

    <form [formGroup]="searchMasterForm">
      <div class="p-grid p-fluid">

        <div class="p-col-4">
          <mat-card-title>{{langObj ? langObj?.MasterDataSettings : ('MasterDataSettings' | translate)}}</mat-card-title>
        </div>
        <div class="p-col-4">
          <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                        'is-invalid':
                        searchMasterForm.get('group')?.errors &&
                        searchMasterForm.get('group')?.touched
                      }" [options]="groupList" formControlName="group" placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}"
            optionLabel="TypeName" [showClear]="true"></p-dropdown>
          <p class="invalid-feedback" *ngIf="
                      searchMasterForm.get('group')?.touched &&
                      searchMasterForm.get('group')?.hasError('required')
                      ">
            * {{langObj ? langObj?.pleaseselectgroup : ('pleaseselectgroup' | translate)}}
          </p>
        </div>
        <div class="p-col-4">

          <p-dropdown [filter]="true" (onChange)="getMasterData($event)" [ngClass]="{
            'is-invalid':
            searchMasterForm.get('plant')?.errors &&
            searchMasterForm.get('plant')?.touched
          }" [options]="plants" formControlName="plant" placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}" optionLabel="DeptDesc"
            [showClear]="true"></p-dropdown>
          <p class="invalid-feedback" *ngIf="
         searchMasterForm.get('plant')?.touched &&
         searchMasterForm.get('plant')?.hasError('required')
        ">
            * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
          </p>

        </div>

      </div>
    </form>
  </mat-card>
  <mat-card class="p-mt-2 p-p-0">
    <!-- Table -->
    <div class="p-grid p-fluid">
      <div class="p-xl-6 p-lg-6 p-md-12 p-col-12" *ngIf="pillarRead">
        <app-pillars [pPermissions]='pillarsPermissions'></app-pillars>
      </div>
      <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="driverRead">
        <app-drivers [dPermissions]='driversPermissions'></app-drivers>
      </div>
      <!-- <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="toolRead">
        <app-tools [tPermissions]='toolsPermissions'></app-tools>
      </div> -->
      <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="frequencyRead">
        <app-frequency [fPermissions]='frequenciesPermissions'></app-frequency>
      </div>
      <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="uomRead">
        <app-uom [uPermissions]='uomsPermissions'></app-uom>
      </div>
      <!-- <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="departmentRead">
        <app-departments [dpPermissions]='departmentsPermissions'></app-departments>
      </div> -->
      <!-- <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="kpireasonRead">
        <app-kpireasons [kprPermissions]='kpireaonsPermissions'></app-kpireasons>
      </div> -->
      <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="masterShiftRead">
        <app-mastershift [shiftPermissions]='masterShiftPermissions'></app-mastershift>
      </div>
      <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="dayOffRead">
        <app-dayoff [dayPermissions]='dayOffPermissions'></app-dayoff>
      </div>
      <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="trendRead">
        <app-trends [trPermissions]='trendsPermissions'></app-trends>
      </div>
      <!-- <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="fourMRead">
        <app-fourm [fmPermissions]='fourMPermissions'></app-fourm>
      </div> -->
      <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="kpimasterweightRead">
        <app-kpimasterweight [fmPermissions]='kpiMasterWeightPermissions'></app-kpimasterweight>
      </div>
      <div class="p-xl-6 p-lg-6 p-md-12  p-col-12" *ngIf="ipeMasterDataRead">
        <app-ipe-master-data [pPermissions]='ipeMDReadPermissions'></app-ipe-master-data>
      </div>
      <!-- <div class="p-xl-6 p-lg-6 p-md-12  p-col-12">
        <app-Kpi-Data-Points></app-Kpi-Data-Points>
      </div> -->
    </div>
  </mat-card>
</section>