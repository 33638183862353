import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DynamicDashboardService } from 'src/app/services/dynamicdashboard.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ProductivityService } from 'src/app/services/productivity-service';
import { Options } from 'highcharts';
import { Chart } from 'angular-highcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { OverallActionPlanService } from 'src/app/services/overall-actionPlan.service';
import { ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { map, share, takeUntil } from "rxjs/operators";
import { Router } from '@angular/router';
import { ButtonDeleteRendererComponent } from 'src/app/components/button-delete-render.components';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import * as Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import More from 'highcharts/highcharts-more';
import ExportData from 'highcharts/modules/export-data';
import Accessibility from 'highcharts/modules/accessibility';
import HC_fullscreen from 'highcharts/modules/full-screen';
import * as moment from 'moment';
import { DynamicChart } from 'src/app/services/dynamic-chart';
import { ConfirmDialogModel, ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { Multilingual } from 'src/app/services/multilingual';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import jsPDF from "jspdf";
import HC_more from "highcharts/highcharts-more";
import SeriesLabel from 'highcharts/modules/series-label';
import HC_exporting from "highcharts/modules/exporting";
import { AtStagesNcrComponent } from 'src/app/analytical-tools/at-stages-ncr/at-stages-ncr.component';
import { CustomBottomSheetComponent } from '../custom-bottom-sheet/custom-bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { OpmsGlassService } from 'src/app/services/opms-glass.service';

HC_more(Highcharts);
SeriesLabel(Highcharts);
HC_exporting(Highcharts);
More(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);
HC_fullscreen(Highcharts);
@Component({
  selector: 'app-dynamic-dashboard',
  templateUrl: './dynamic-dashboard.component.html',
  styleUrls: ['./dynamic-dashboard.component.scss']
})
export class DynamicDashboardComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  @ViewChild('AddCardPoup') private addCardPopup!: TemplateRef<any>;
  @ViewChild('FilterPoup') private FilterPoup!: TemplateRef<any>;
  @ViewChild('addActionModal') private addActionModal!: TemplateRef<any>;
  @ViewChild('addImageInput') addImageInput!: ElementRef;
  @ViewChild('addFileInput') addFileInput!: ElementRef;
  // @ViewChild('chatMessages', { static: true }) chatMessagesRef!: ElementRef;
  @ViewChild('AddTabPoup') private addTabPoup!: TemplateRef<any>;
  @ViewChild('allSelected') private allSelected: MatSelect;
  @ViewChild('ShareTabPoup') private shareTabPoup!: TemplateRef<any>;
  // @ViewChild('chart') chartFullScreen: any;
  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('createAction', { static: true }) private createAction: TemplateRef<any>;
  public filteredValueMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public valueSearchCtrl: FormControl = new FormControl();
  chart: Highcharts.Chart;
  format = 'YYYY-MM-DD HH:mm:ss';
  chartOptions: any;
  dynamicChart: any;
  highChartsColor: string[] = ['#F52D55', '#5448CE', '#19C6C6'];
  chartObj: any;
  chartsLoop: any[];
  public openMenu: boolean = false;
  allSelectedOptions = false;
  isOver = false;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  IsAdmin: any;
  userDetails !: any;
  CardListTop: any[] = new Array();
  CardListLeft: any[] = new Array();
  CardListRight: any[] = new Array();
  dataPointList: any[] = new Array();
  chartList: any[] = new Array();
  filteredDataPoints: any;
  isPopupOpen: boolean = false;
  AddCardform!: FormGroup;
  Headerform!: FormGroup;
  addActionForm!: FormGroup;
  AddTabform!: FormGroup;
  ShareTabform!: FormGroup;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  data: any;
  pageId!: any;
  requestType: string = 'All';
  filteredGroups: any;
  filteredPlants: any;
  filteredLines: any;
  filteredUnits: any;
  filteredDynamicValues: any;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  AggeregateList!: any[];
  chartArray: any[] = [];
  chartArrayRight: any[] = [];
  options: Options;
  chartLeftData: any[] = new Array();
  chartRightData: any[] = new Array();
  chartLeftCategory: any[] = new Array();
  chartRightCategory: any[] = new Array();
  updateCard: boolean = false;
  avgYieldChartOptions: any;
  updateValues: any;
  activeButton!: any;
  Frequency: string = 'Monthly';
  DataExist: boolean = false;
  IsTabExist: boolean = false;
  access: boolean = true;
  addImageSrc = '';
  addStatus = 'Open';
  addFile = null;
  addImage = null;
  targetDate: any;
  RevisedDate: Date;
  addFileSrc = '';
  userList: any = [];
  savedFilterData: any = [];
  menuAccessUserList: any = [];
  filteredUsers: any;
  clock = new Date();
  subscription: Subscription;
  intervalId;
  time = new Date();
  popupLabel: string = '';
  tourStep: number = 0;
  cards = [
    { title: 'Card 1', content: 'Content for Card 1' },
    { title: 'Card 2', content: 'Content for Card 2' },
    { title: 'Card 3', content: 'Content for Card 3' },
    { title: 'Card 4', content: 'Content for Card 4' }
  ];
  FabOptions = {
    buttons: [
      {
        icon: 'timeline'
      },
      {
        icon: 'view_headline'
      },
      {
        icon: 'room'
      },
      {
        icon: 'lightbulb_outline'
      },
      {
        icon: 'lock'
      }
    ]
  };
  chats = [
    { isBot: true, message: 'Hi, how can I help you?' }
  ];
  userInput = '';
  isOnInitLoad: boolean = false;
  lang: any;
  tabs!: any;
  dataPointSources: any[] = new Array();
  dynamicListByDatasource: any[] = new Array();
  sourceName: string = 'Common DataPoints';
  sourceId: number = 1;
  selectedTabIndex;
  rowData!: any[];
  columnDefs!: any[];
  ShareTabcolumnDefs!: any[];
  rowDataSharedTabs!: any[];
  gridApi!: any;
  gridColumnApi!: any;
  public frameworkComponents: any;
  gridOptions: any = {
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      filter: true
    }
  }
  lastUpdatedWidth: any = 0;
  selectedFilter: any;
  static dialog: any;
  static createActionref: TemplateRef<any>; // Declare the property
  static selectedChartValue: any;
  static chartProp: any;
  KPIId: any;
  numbersArray: any;
  printHeaderStatus: boolean = false;
  printHeader: boolean = false;
  printCharts: string;
  selectedDataSource: any;
  infoContent: string = 'Number of Days: This function calculates and stores the number of days between a specified start date and an end date. It always returns a date range, starting from the current date and extending backwards for the calculated number of days.-----   Date Range: This function saves a specific date range as selected by the user. It then returns this exact date range whenever requested, maintaining the same start and end dates as originally selected.'
  constructor(private dynamicService: DynamicDashboardService, private toaster: ToastrService, private dialog: MatDialog, private formBuilder: FormBuilder, public translater: TranslaterService, private productivityservice: ProductivityService,
    private spinner: NgxSpinnerService, public translate: TranslateService, private actionplanService: OverallActionPlanService, public router: Router, private usermanagementService: UserManagementService, private kpiMaster: KpimasterService, private bottomSheet: MatBottomSheet, private opmsGlassservice: OpmsGlassService) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { event.lang === 'ar' ? this.languageDir = 'rtl' : this.languageDir = 'ltr'; this.getLangWords(); });
    DynamicDashboardComponent.dialog = dialog;
  }

  ngOnInit(): void {
    // this.getSavedFilter();
    this.getLangWords();
    // getting current logged in user
    let userdataJson: any = localStorage.getItem('user');
    this.userDetails = JSON.parse(userdataJson);
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.initForm();

    this.intervalId = setInterval(() => {
      this.time = new Date();
    }, 1000);

    // Using RxJS Timer
    this.subscription = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        share()
      )
      .subscribe(time => {
        let hour = this.clock.getHours();
        let minuts = this.clock.getMinutes();
        let seconds = this.clock.getSeconds();
        //let a = time.toLocaleString('en-US', { hour: 'numeric', hour12: true });
        let NewTime = hour + ":" + minuts + ":" + seconds
        //console.log(NewTime);
        this.clock = time;
      });

    this.frameworkComponents = {
      buttonDeleteRenderer: ButtonDeleteRendererComponent
    }
    this.columnDefs = [
      { headerName: 'Id', field: 'Id', headerValueGetter: this.localizeHeader.bind(this) },
      { headerName: 'Tab Name', field: 'TabName', editable: true, headerValueGetter: this.localizeHeader.bind(this) },
      { headerName: '', cellRenderer: 'buttonDeleteRenderer', cellRendererParams: { onClick: this.confirmDialog.bind(this), label: 'Delete' }, maxWidth: 85, suppressMenu: true }
    ];

    this.ShareTabcolumnDefs = [
      { headerName: 'Id', field: 'Id', headerValueGetter: this.localizeHeader.bind(this) },
      { headerName: 'Tab Name', field: 'TabName', headerValueGetter: this.localizeHeader.bind(this) },
      { headerName: 'User', field: 'UserName', headerValueGetter: this.localizeHeader.bind(this) },
      { headerName: 'Access Type', field: 'Name', headerValueGetter: this.localizeHeader.bind(this) },
      { headerName: 'Created By', field: 'createdby', headerValueGetter: this.localizeHeader.bind(this) },
      {
        headerName: 'Created On', field: 'Createdon', valueFormatter: function (params: any) {
          return moment(params.value).format('MM/DD/YYYY HH:mm');
        },
        headerValueGetter: this.localizeHeader.bind(this),
      },
      { headerName: '', cellRenderer: 'buttonDeleteRenderer', cellRendererParams: { onClick: this.deleteAccess.bind(this), label: 'Delete' }, maxWidth: 85, suppressMenu: true }
    ];

    this.getTabs();
    this.valueSearchCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterValue();
      });
  }

  filterValue(): void {
    if (!this.filteredDynamicValues) {
      return;
    }
    let search = this.valueSearchCtrl.value;
    if (!search) {
      this.filteredValueMulti.next(this.filteredDynamicValues);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredValueMulti.next(
      this.filteredDynamicValues.filter(
        (value: any) => value.Name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  tour() {
    this.tourStep = 1;
  }

  nextStep() {
    this.tourStep++;
  }

  endTour() {
    this.tourStep = null;
  }

  clickMenu() {
    this.openMenu = !this.openMenu;
  }

  hello(mex: string) {
    alert('Hello ' + mex + '!');
  }

  public getLangWords() {
    const key = {
      ModuleId: 0,
      PageId: 0,
      LanguageId: this.translate.currentLang === 'en' ? 1 : this.translate.currentLang === 'ar' ? 2 : this.translate.currentLang === 'fr' ? 3 : this.translate.currentLang === 'sp' ? 4 : this.translate.currentLang === 'gr' ? 5 : this.translate.currentLang === 'du' ? 6 : 1,
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value: string) {
    let val;
    if (this.langObj && this.langObj[value]) {
      val = this.langObj[value];
    }
    return val ? val : value;
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        console.log(data.BreadcrumList);
        // // this.getLangWords();
        this.lang = new Multilingual(this.translater, this.translate);
        // // this.lang.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        this.IsAdmin = data.IsAdmin;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  initForm() {
    const dateStart = new Date();
    //dateStart.setDate(dateStart.getDate() - 183);
    dateStart.setHours(0);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);

    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate());

    this.AddCardform = this.formBuilder.group({
      labelName: ['', [Validators.required]],
      datapointName: ['', [Validators.required]],
      analysisUrl: [],
      orderNo: [],
      chartType: ['', [Validators.required]],
      Id: [],
      values: [],
      source: [],
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
      aggregates: [],
      timeperiod: []
    });

    this.Headerform = this.formBuilder.group({
      UserGroup1: ['', Validators.required],
      UserPlant1: ['', Validators.required],
      unit: ['', Validators.required],
      line: ['', Validators.required],
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
      shift: ['', []],
      frequency: ['', Validators.required],
      Id: ['']

    });

    this.addActionForm = this.formBuilder.group({
      id: [''],
      action: ['', Validators.required],
      actionType: ['', Validators.required],
      assignTo: ['', Validators.required],
      targetDate: ['', Validators.required],
      kpi: [''],
    });

    this.AddTabform = this.formBuilder.group({
      tabName: ['', Validators.required]
    });

    this.ShareTabform = this.formBuilder.group({
      user: ['', Validators.required],
      accessType: ['1', Validators.required]
    });

    this.Headerform.get('frequency').setValue('Monthly');
    this.datasource();
  }

  onChangeGroup(data: any): void {
    this.getPlants(data);
  }

  onChangeLine(data: any): void {
    this.getUnitOnlineChange(data.value);
  }

  onChangePlant(data: any): void {
    this.getLine(data.value);
    this.getUserList();
    this.getDataPointSourceList();
  }

  //===================================================header dd code ========================================================//
  async datasource(): Promise<void> {
    const key = {
      UserId: 206, //later it will be change this is only for testing
    };
    this.productivityservice.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.lstEG;
        if (this.GroupList.length > 0) {
          this.updateCard === true ? this.AddCardform.get('UserGroup1').patchValue(this.updateValues.GroupId) : this.AddCardform.get('UserGroup1').patchValue(this.GroupList[0].EG_Id);
          this.filteredGroups = this.GroupList.slice();
        }
        this.getPlants('');
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  async getPlants(event: any): Promise<void> {
    const key = {
      EG_ID: this.AddCardform.value.UserGroup1,
      userId: 206,
    };
    this.productivityservice.getPlantsData(key).subscribe(
      (data: any) => {
        this.PlantList1 = data.lstPlant;
        if (this.PlantList1.length > 0) {
          const id = data.lstPlant[0].PT_Id;
          this.updateCard !== true ? this.AddCardform.get('UserPlant1').patchValue(id) : this.AddCardform.get('UserPlant1').patchValue(this.updateValues.PlantId);
          this.getLine(id);
          this.filteredPlants = this.PlantList1.slice();
          this.getUserList();
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  async getLine(id: any): Promise<void> {
    const key = {
      PT_ID: this.AddCardform.value.UserPlant1,
      userId: 206,
    };
    this.productivityservice.getLineData(key).subscribe(
      (data: any) => {
        this.lineList = data.lstLine;
        if (this.lineList.length > 0) {
          const lineDefault = this.lineList[0].PL_Id;
          this.updateCard === true ? this.AddCardform.get('line').patchValue(this.updateValues.LineId) : this.AddCardform.get('line').patchValue(lineDefault);
          this.filteredLines = this.lineList.slice();
          this.getUnit(lineDefault.PL_Id);
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  async getUnit(id: number): Promise<void> {
    const key = {
      PL_ID: this.AddCardform.value.line,
      userId: 206,
    };
    this.productivityservice.getUnitData(key).subscribe(
      (data: any) => {
        this.unitList = data.lstUnit;
        if (this.unitList.length > 0) {
          this.updateCard === true ? this.AddCardform.get('unit').patchValue(this.updateValues.UnitId) : this.AddCardform.get('unit').patchValue("0");
          this.filteredUnits = this.unitList.slice();
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getUnitOnlineChange(id: number) {
    const key = {
      PL_ID: this.AddCardform.value.line,
      userId: 206,
    };

    this.productivityservice.getUnitData(key).subscribe(
      (data: any) => {
        this.unitList = data.lstUnit;
        if (this.unitList.length > 0) {
          this.AddCardform.get('unit').patchValue(0);
          this.filteredUnits = this.unitList.slice();
          this.resetList();
          this.getCardList();
          //  this.getCardListLeft();
          this.getCardListRight();
        }

      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  checkDataExist() {
    if (this.CardListTop.length === 0 && this.CardListLeft.length === 0 && this.CardListRight.length === 0) {
      this.DataExist = true;
    } else {
      this.DataExist = false;
    }
  }

  saveFilter(): void {
    const key = {
      TransactionType: this.Headerform.value.Id === '' ? 1 : 2,
      startDate: this.Headerform.value.dateStart,
      endDate: this.Headerform.value.dateEnd,
      GroupId: this.Headerform.value.UserGroup1,
      PlantId: this.Headerform.value.UserPlant1,
      UnitId: this.Headerform.value.unit,
      LineId: this.Headerform.value.line,
      frequency: this.Headerform.value.frequency,
      Id: this.Headerform.value.Id !== 0 ? this.Headerform.value.Id : 0
    }
    this.spinner.show();
    this.dynamicService.saveUserFilterData(key).subscribe(
      (data: any) => {
        this.toaster.success('Success', data.Table[0].Msg);
        this.spinner.hide();
        this.getSavedFilter();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
  }

  getSavedFilter(): void {
    this.resetList();
    this.spinner.show();
    this.dynamicService.getFilterData().subscribe(
      (data: any) => {
        this.savedFilterData = data;
        this.isOnInitLoad = true;
        if (data.Table.length > 0) {
          this.Headerform.get('UserGroup1').setValue(data.Table[0].group);
          this.Headerform.get('UserPlant1').setValue(data.Table[0].plant);
          this.Headerform.get('line').setValue(data.Table[0].line);
          this.Headerform.get('unit').setValue(data.Table[0].machine);
          this.Headerform.get('dateStart').setValue(data.Table[0].start_date);
          this.Headerform.get('dateEnd').setValue(data.Table[0].end_date);
          this.Headerform.get('frequency').setValue(data.Table[0].frequency);
          this.Headerform.get('Id').setValue(data.Table[0].id);
          this.spinner.hide();
        } else {
          this.isOnInitLoad = true;
          this.spinner.hide();
          // this.getCardList();
          // this.getCardListLeft();
          // this.getCardListRight();
        }

      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
  }

  deleteFilter(): void {
    this.spinner.hide();
    this.dynamicService.deleteSavedFilter().subscribe(
      (data: any) => {
        this.toaster.success('Success', data.Table[0].Msg);
        this.spinner.hide();
        this.getSavedFilter();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
  }

  //======================================================END ================================================================//

  //================================================== fetch Dashboard Data ======================================//

  async getCardList(): Promise<void> {
    const key = {
      cardLocation: 'top',
      tabIndexId: this.tabs[this.selectedTabIndex]?.Id
    }
    await this.dynamicService.getCardList(key).then(
      (data: any) => {
        this.CardListTop = [];
        data.cardList.forEach(element => {
          this.getTopCardData(element);
        })
        this.checkDataExist();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
  }

  getTopCardData(element): any {
    const key = {
      dtStartDate: element.startDate,
      dtEndDate: element.endDate,
      GroupId: element.GroupId !== null ? element.GroupId : this.AddCardform.value.UserGroup1,
      PlantId: element.PlantId !== null ? element.PlantId : this.AddCardform.value.UserPlant1,
      UnitId: element.UnitId !== null ? element.UnitId : this.AddCardform.value.unit,
      LineId: element.LineId !== null ? element.LineId : this.AddCardform.value.line,
      dataPointId: element.dataPointId,
      Frequency: '',
      Id: element.Id,
      Shift: '*',
    }
    this.dynamicService.getTopCardData(key).subscribe(
      (data: any) => {
        const dataObj = {
          labelName: data[0].labelName,
          uom: data[0].uom,
          imagePath: data[0].imagePath,
          result: data[0].result,
          dataPointId: data[0].dataPointId,
          chartType: data[0].chartType,
          cardLocation: data[0].cardLocation,
          Id: data[0].Id,
          orderNo: data[0].orderNo,
          source: data[0].source,
          sourceValues: data[0].sourceValues,
          GroupId: data[0].GroupId,
          PlantId: data[0].PlantId,
          LineId: data[0].LineId,
          UnitId: data[0].UnitId,
          startDate: data[0].startDate,
          endDate: data[0].endDate,
          timeperiod: data[0].timeperiod
        }
        this.insertCardTop(dataObj);
        this.checkDataExist();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
  }

  insertCardTop(newCard) {
    const index = this.CardListTop.findIndex(card => card.orderNo > newCard.orderNo);

    if (index === -1) {
      this.CardListTop.push(newCard);
    } else {
      this.CardListTop.splice(index, 0, newCard);
    }
  }

  async getCardListRight(): Promise<void> {
    this.CardListRight = [];
    const key = {
      cardLocation: 'right',
      tabIndexId: this.tabs[this.selectedTabIndex]?.Id
    }
    await this.dynamicService.getCardList(key).then(
      (data: any) => {
        this.CardListRight = [];
        this.CardListRight = data.cardList;
        this.CardListRight.forEach(obj => {
          //parseInt(obj.width) === 0 ? 33.3 : obj.width
          obj.isMaximized = false
        })
        this.plotRightChart();
        this.checkDataExist();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
  }

  resetList() {
    this.CardListTop = [];
    this.CardListLeft = [];
    this.CardListRight = [];
  }

  resetForm(): void {
    this.AddCardform.get('labelName').reset(),
      this.AddCardform.get('datapointName').reset(),
      this.AddCardform.get('analysisUrl').reset(),
      this.AddCardform.get('orderNo').reset(),
      this.AddCardform.get('chartType').reset(),
      this.AddCardform.get('source').reset(),
      this.AddCardform.get('values').reset()
  }

  changeFilter(): void {
    if (this.requestType === 'top') {
      this.getCardList();
    } else if (this.requestType === 'right') {
      this.getCardListRight();
    } else {
      this.getCardList();
      this.getCardListRight();
    }
  }

  openPopup(request: string) {
    this.resetForm();
    this.updateCard = false;
    this.sourceName = '';

    if (request === 'right') {
      this.popupLabel = 'Add Chart';
    } else if (request === 'top') {
      this.popupLabel = 'Add Card';
    }

    let dialogRef = this.dialog.open(this.addCardPopup, {
      minWidth: '60%',
      minHeight: '50%',
    });
    dialogRef.afterOpened().subscribe((res: any) => {
      this.requestType = request;
      if (this.requestType === 'top') {
        this.AddCardform.get('chartType')?.setValue('value');
      }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.isPopupOpen = false;
    });
    this.getDataPointSourceList();
  }

  openTabPopup() {
    let dialogRef = this.dialog.open(this.addTabPoup, {
      minWidth: '35%',
      minHeight: '50%',
    });
    dialogRef.afterOpened().subscribe((res: any) => {

    });
    dialogRef.afterClosed().subscribe((res: any) => {

    });
  }

  FilterPopup(request: string) {
    let dialogRef = this.dialog.open(this.FilterPoup, {
      minWidth: '35%',
      minHeight: '50%',
    });
    dialogRef.afterOpened().subscribe((res: any) => {
      this.requestType = request;
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.isPopupOpen = false;
    });
  }

  openFilterPopup(request: string) {
    let dialogRef = this.dialog.open(this.FilterPoup, {
      minWidth: '35%',
      minHeight: '50%',
    });
    dialogRef.afterOpened().subscribe((res: any) => {
      this.requestType = request;
      if (this.requestType === 'top') {
        this.getCardList();
      } else if (this.requestType === 'right') {
        this.getCardListRight();
      } else {
        this.getCardList();
        this.getCardListRight();
      }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.isPopupOpen = false;
    });
  }

  navigateToAnalysisScreen(item: any): void {
    this.toaster.info('Analysis URL not define');
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.AddCardform.controls[controlName].hasError(errorName);
  }

  getDataPointList(): void {
    const itemToCheck = this.chartList.find(val => val.Value === this.AddCardform.get('chartType').value)?.valueType;
    const key = {
      datatype: this.requestType === 'top' ? 'value' : itemToCheck
    }
    this.dynamicService.getDataPointList(key).subscribe(
      (data: any) => {
        this.dataPointList = data.dataList;
        this.filteredDataPoints = this.dataPointList.slice();
        this.AddCardform.get('datapointName').patchValue(this.dataPointList[0]?.Id);
        this.AddCardform.get('labelName')?.setValue(this.dataPointList[0]?.Name);
        if (this.updateCard === true) {
          this.AddCardform.get('datapointName').patchValue(this.updateValues.dataPointId);
        }
        this.getDDBySourceList();
        this.getAggregatesList();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  getChartList() {
    const params = {
      sourceId: this.AddCardform.get('source').value
    }
    this.dynamicService.getChartList(params).subscribe(
      (data: any) => {
        this.chartList = data.Table;
        // this.AddCardform.get('chartType').patchValue(this.chartList[0].Value);
        if (this.updateCard === true) {
          this.AddCardform.get('chartType').patchValue(this.updateValues.chartType);
        }
        this.getDataPointList();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  saveDashboardCard() {
    const data = {
      TransactionType: 1,
      Id: 0,
      labelName: this.AddCardform.get('labelName').value,
      datapointId: this.AddCardform.get('datapointName').value,
      analysisUrl: this.AddCardform.get('analysisUrl').value === null ? '' : this.AddCardform.get('analysisUrl').value === null,
      orderNo: this.AddCardform.get('orderNo').value === null ? 100 : this.AddCardform.get('orderNo').value,
      chartType: this.AddCardform.get('chartType').value,
      cardLocation: this.requestType,
      tabIndexId: this.tabs[this.selectedTabIndex]?.Id,
      source: this.AddCardform.get('source').value,
      sourceValues: JSON.stringify(this.AddCardform.get('values').value),
      startDate: this.AddCardform.value.dateStart,
      endDate: this.AddCardform.value.dateEnd,
      GroupId: this.AddCardform.value.UserGroup1,
      PlantId: this.AddCardform.value.UserPlant1,
      UnitId: this.AddCardform.value.unit,
      LineId: this.AddCardform.value.line,
      aggregates: this.AddCardform.value.aggregates,
      timeperiod: this.AddCardform.value.timeperiod
    }
    this.spinner.show();
    this.dynamicService.saveDashboardCard(data).subscribe(
      (data: any) => {
        this.toaster.success('Success', data.ErrorMessage);
        if (this.requestType === 'top') {
          this.getCardList();
        } else {
          this.getCardListRight();
        }
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
  }

  deleteDashboardCard(obj): void {
    const message = `Are you sure you want to delete this card ?`;

    const dialogData = new ConfirmDialogModel("Confirm Action", message);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "600px",
      width: "450px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.deleteTopCard(obj);
      } else {
        console.log(dialogResult);
      }
    });
  }

  deleteTopCard(Item) {
    const data = {
      TransactionType: 2,
      Id: Item.Id,
      labelName: this.AddCardform.get('labelName').value,
      datapointId: 0,
      analysisUrl: this.AddCardform.get('analysisUrl').value === null ? '' : this.AddCardform.get('analysisUrl').value === null,
      orderNo: Item.orderNo,
      startDate: this.AddCardform.value.dateStart,
      endDate: this.AddCardform.value.dateEnd,
      GroupId: this.AddCardform.value.UserGroup1,
      PlantId: this.AddCardform.value.UserPlant1,
      UnitId: this.AddCardform.value.unit,
      LineId: this.AddCardform.value.line
    }

    this.dynamicService.saveDashboardCard(data).subscribe(
      (data: any) => {
        this.getCardList();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  deleteDashboardCardRight(obj): void {
    const message = `Are you sure you want to delete this chart ?`;

    const dialogData = new ConfirmDialogModel("Confirm Action", message);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "600px",
      width: "450px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.deleteRightCard(obj);
      } else {
        console.log(dialogResult);
      }
    });
  }

  deleteRightCard(Item) {
    const data = {
      TransactionType: 2,
      Id: Item.Item.Id,
      labelName: this.AddCardform.get('labelName').value,
      datapointId: 0,
      analysisUrl: this.AddCardform.get('analysisUrl').value === null ? '' : this.AddCardform.get('analysisUrl').value === null,
      orderNo: Item.orderNo,
      startDate: this.AddCardform.value.dateStart,
      endDate: this.AddCardform.value.dateEnd,
      GroupId: this.AddCardform.value.UserGroup1,
      PlantId: this.AddCardform.value.UserPlant1,
      UnitId: this.AddCardform.value.unit,
      LineId: this.AddCardform.value.line
    }

    this.dynamicService.saveDashboardCard(data).subscribe(
      (data: any) => {
        this.getCardListRight();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  //===============================================Updates Calls ===========================================================//
  changeDatapoint(Obj: any): void {
    this.requestType = Obj.cardLocation;
    this.updateCard = true;
    this.getDataPointSourceList();
    this.datasource();
    this.updateValues = Obj;
    this.sourceId = Obj.source;
    this.AddCardform.get('UserGroup1').patchValue(Obj.GroupId);
    this.AddCardform.get('UserPlant1').patchValue(Obj.PlantId);
    this.AddCardform.get('line').patchValue(Obj.LineId);
    this.AddCardform.get('unit').patchValue(Obj.UnitId);
    this.AddCardform.get('dateStart').patchValue(Obj.startDate);
    this.AddCardform.get('dateEnd').patchValue(Obj.endDate);
    this.AddCardform.get('timeperiod').patchValue(Obj.timeperiod);
    let dialogRef = this.dialog.open(this.addCardPopup, {
      minWidth: '50%',
      minHeight: '50%',
    });
    dialogRef.afterOpened().subscribe((res: any) => {

      // this.getChartList();
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.isPopupOpen = false;
      this.updateCard = false;
    });
  }

  updateDashboardCard() {
    const data = {
      TransactionType: 3,
      Id: this.AddCardform.get('Id').value,
      labelName: this.AddCardform.get('labelName').value,
      datapointId: this.AddCardform.get('datapointName').value,
      analysisUrl: this.AddCardform.get('analysisUrl').value === null ? '' : this.AddCardform.get('analysisUrl').value === null,
      orderNo: this.AddCardform.get('orderNo').value,
      chartType: this.AddCardform.get('chartType').value,
      cardLocation: this.requestType,
      source: this.AddCardform.get('source').value,
      sourceValues: JSON.stringify(this.AddCardform.get('values').value),
      startDate: this.AddCardform.value.dateStart,
      endDate: this.AddCardform.value.dateEnd,
      GroupId: this.AddCardform.value.UserGroup1,
      PlantId: this.AddCardform.value.UserPlant1,
      UnitId: this.AddCardform.value.unit,
      LineId: this.AddCardform.value.line,
      aggregates: this.AddCardform.value.aggregates,
      timeperiod: this.AddCardform.value.timeperiod
    }
    this.spinner.show();
    this.dynamicService.saveDashboardCard(data).subscribe(
      (data: any) => {
        this.toaster.success('Success', data.ErrorMessage);
        if (this.requestType === 'top') {
          this.getCardList();
        } else {
          this.getCardListRight();
        }
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
  }

  setLabel(data) {
    const obj = this.dataPointList.find(val => val.Id === data.value);
    this.AddCardform.get('labelName')?.setValue(obj.Name);
  }
  //===============================================END====================================================================//

  //============================================== plot chart code here ==============================================//
  plotRightChart(): void {
    this.chartArrayRight = [];
    this.CardListRight.forEach(async (element: any) => {
      const chartObj = { Item: {}, chartOption: {} };
      const key = {
        cardLocation: 'right',
        dtStartDate: element.startDate,
        dtEndDate: element.endDate,
        GroupId: element.GroupId !== null ? element.GroupId : this.AddCardform.value.UserGroup1,
        PlantId: element.PlantId !== null ? element.PlantId : this.AddCardform.value.UserPlant1,
        UnitId: element.UnitId !== null ? element.UnitId : this.AddCardform.value.unit,
        LineId: element.LineId !== null ? element.LineId : this.AddCardform.value.line,
        dataPointId: element.dataPointId,
        Frequency: '',
        Id: element.Id,
        shift: '*',
      }

      await this.dynamicService.getRightCardList(key).then(

        (data: any) => {
          const title = element.labelName;
          this.chartRightData = [];
          this.chartRightCategory = [];

          if (element.chartType === 'pie') {
            data.Table.forEach(val => {
              this.chartRightData.push({ name: val.name, y: val.result });
            })
          } else if (element.chartType === 'drilldown') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.drawDrillDownChart(data, element));
            return;
          }
          else if (element.chartType === 'lineComparision') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.drawLineComparison(data, element));
            return;
          }
          else if (element.chartType === 'stacked') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.stackedchart(data, element));
            return;
          }
          else if (element.chartType === 'columnComparison') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.drawColumnComparison(data, element));
            return;
          }
          else if (element.chartType === 'StockLineComparison') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.drawLineComparison(data, element));
            return;
          }
          else if (element.chartType === '3dDonutChart') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.draw3dDonut(data, element));
            return;
          }
          else if (element.chartType === '3dColumnChart') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.draw3dColumn(data, element));
            return;
          }
          else if (element.chartType === '3dPieChart') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.draw3dPie(data, element));
            return;
          }
          else if (element.chartType === 'donut') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.drawDonut(data, element));
            return;
          }
          else if (element.chartType === 'bar') {
            data.Table.forEach(val => {
              this.chartRightData.push({ name: '', y: val.result });
              this.chartRightCategory.push(val.name);
            })
          }
          else if (data.Table10 && Array.isArray(data.Table10) && element.chartType === 'spline') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.splineChartChemicalGlass(data, element));
            return
          }
          else if (element.chartType === 'spline') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.splineChart(data, element));
            return
          }
          else if (element.chartType === 'solidgauge') {
            this.dynamicChart = new DynamicChart();
            this.insertCard(this.dynamicChart.drawGauge(data, element));
            return
          }
          else if (data.Table9 && Array.isArray(data.Table9) && data.Table9.length > 0) {
            const chartData = this.processTable9ChartData(data.Table9);
            this.createTable9Chart(chartData.categories, chartData.series, title, element, chartData.date, chartData.time, chartData.Thickness);
            return;
          }
          else {
            data.Table.forEach(val => {
              this.chartRightData.push({ name: '', y: val.result });
              this.chartRightCategory.push(val.name);
            })
          }

          // create the chart
          this.options = {
            title: {
              text: title,
              style: {
                fontWeight: 'bold'  // Makes the title bold
              }
            },
            credits: {
              enabled: false
            },
            xAxis: {
              categories: this.chartRightCategory
            },
            yAxis: {
              // min: 0,
              // max: 100,
              // labels: {
              //   format: '{value}%'
              // }
            },
            tooltip: {
              enabled: true,
              style: {
                zIndex: 100,
              },
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                    return '<span style="font-size: 10px; font-weight: normal">' + this.point.name === undefined ? (this.y).toFixed(2) : this.point.name + ' ' + (this.y).toFixed(2) + ' </span>';
                  }
                },
                events: {
                  click: DynamicDashboardComponent.openActionsModel.bind(this, element)
                }
              }
            },
            series: [
              {
                name: title,
                type: element.chartType,
                colorByPoint: true,
                data: this.chartRightData
              }
            ],
          };
          let chart = new Chart(this.options);
          chartObj.chartOption = chart;
          chartObj.Item = element;
          //this.chartArrayRight.push(chartObj);
          this.insertCard(chartObj);
        })
    })
  }

  processTable9ChartData(data: any[]) {
    let series = [];
    let categories = [];
    let date = '';
    let time = '';
    let Thickness = '';

    if (data.length) {
      date = data[0].date ? data[0].date.substring(0, 10) : '';
      time = data[0].time || '';
      Thickness = data[0].Thickness || '';

      categories = Object.keys(data[0]).filter((key) => {
        return key !== 'name' && key !== 'time' && key !== 'date' && key !== 'Thickness' && key !== 'error' && data[0][key] !== null;
      });

      for (let i = 0; i < data.length; i++) {
        let seriesData = [];
        for (let j = 0; j < categories.length; j++) {
          seriesData.push(data[i][categories[j]]);
        }

        series.push({
          name: data[i].name,
          data: seriesData,
          color: this.highChartsColor[i]
        });
      }
    } else {
      categories = ['LE', 'LC', 'RC', 'RE'];
      series = [
        { name: 'Max', data: [], color: this.highChartsColor[0] },
        { name: 'Avg', data: [], color: this.highChartsColor[1] },
        { name: 'Min', data: [], color: this.highChartsColor[2] }
      ];
    }
    return { categories, series, date, time, Thickness };
  }

  createTable9Chart(categories: any[], series: any[], title: string, element: any, date: string, time: string, Thickness: string, is3D: boolean = false) {
    const chartObj = { Item: {}, chartOption: {} };

    const subtitleText = (date && time && Thickness)
      ? `Date: ${date} | Time: ${time} | Thickness: ${Thickness}`
      : '';

    this.options = {
      chart: {
        type: 'column',
        options3d: {
          enabled: is3D,
          alpha: 10,
          beta: 30,
          depth: 250
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: title,
        style: {
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: subtitleText,
        style: {
          fontSize: '12px',
          color: '#707070'
        }
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        min: 0,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
          pointPadding: 0.2,
          groupPadding: 0.13,
          borderWidth: 0,
        },
      },
      series: series,
    };
    let chart = new Chart(this.options);
    chartObj.chartOption = chart;
    chartObj.Item = element;
    this.insertCard(chartObj);  // Insert the chart into the dashboard
  }

  insertCard(newCard) {
    const index = this.chartArrayRight.findIndex(card => card.Item.orderNo > newCard.Item.orderNo);

    if (index === -1) {
      this.chartArrayRight.push(newCard);
    } else {
      this.chartArrayRight.splice(index, 0, newCard);
    }
  }

  showPhase(event: any) {
    this.activeButton = event;
  }

  // as of now not in use

  weekly() {
    this.Frequency = 'Weekly';
    this.plotRightChart();
  }
  monthly() {
    this.Frequency = 'Monthly';
    this.plotRightChart();
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.chartArrayRight, event.previousIndex, event.currentIndex);
  //   this.saveLayout();

  // }

  // saveLayout() {
  //   const layout = this.chartArrayRight.map(chart => chart);
  //   localStorage.setItem('myDraggableLayout', JSON.stringify(layout));
  // }

  // trackById(index: number, item: any): string {
  //   return item;
  // }

  removeCharactersTillSymbol(str: string): string {
    const indexOfSymbol = str.indexOf('-');
    if (indexOfSymbol === -1) {
      return str;
    } else {
      return str.substring(indexOfSymbol + 1);
    }
  }

  checkKPIId(kpiId: number) {
    const KPI = this.dynamicListByDatasource?.filter(obj => this.removeCharactersTillSymbol(obj.Name) === DynamicDashboardComponent.chartProp.point.series.name);
    KPI.forEach(obj => {
      if (obj.Id === kpiId) {
        this.KPIId = obj.Id;
      }
    })
  }

  addActionPlan(): void {
    this.targetDate = this.addActionForm.value.targetDate;
    this.targetDate = new Date(this.targetDate);
    this.targetDate.setHours(new Date().getHours());
    this.targetDate.setMinutes(new Date().getMinutes());
    this.targetDate.setSeconds(new Date().getSeconds());
    if (this.numbersArray.length > 1) {

      this.numbersArray.forEach((obj: any) => {
        this.checkKPIId(parseInt(obj));
      })
    }
    let status =
      this.addActionForm.value.targetDate &&
        !this.addActionForm.value.revisedDate
        ? 'InProgress'
        : this.addActionForm.value.targetDate &&
          this.addActionForm.value.revisedDate
          ? 'Delayed'
          : 'Open';
    if (this.addActionForm.value.verification === 'Approved') {
      status = 'Closed';
      // fiveWhy.ClosedDate = new Date();
    } else if (this.addActionForm.value.verification === 'Disapproved') {
      status = 'ReOpened';
      // fiveWhy.ActionCompleted = 'No';
      this.addActionForm.get('completed').setValue('No');
    }

    const key = {
      actionName: this.addActionForm.value.action,
      actionType: this.addActionForm.value.actionType,
      responsible: this.addActionForm.value.assignTo,
      targetDate: this.targetDate,
      status: status,
      userId: JSON.parse(localStorage.user).UserId,
      plantId: DynamicDashboardComponent.selectedChartValue.PlantId,
      meetingStatus: 2,
      meetingId: DynamicDashboardComponent.selectedChartValue.Id,
      kpiId: this.KPIId
    };
    this.spinner.show();
    this.actionplanService.addActionPlan(key).subscribe(({
      next: (res: any) => {
        this.spinner.hide();
        if (res?.data[0]?.message === 'Add Successfully') {
          this.toaster.success('Sucess', 'Added Successfully');
          // this.getOverallMeetingActions();
          // this.addActionForm.reset();
          // this.addActionForm.get('actionType')?.setValue('Corrective');
          // this.addActionForm.get('kpi')?.setValue(this.KPIName);
        }
      },
      error: ({ error }) => {
        this.spinner.hide();
        this.toaster.error('Error', '');
      }
    }));
  }

  openAddActionModal() {
    this.AddCardform.get('UserGroup1').patchValue(DynamicDashboardComponent.selectedChartValue.GroupId);
    this.AddCardform.get('UserPlant1').patchValue(DynamicDashboardComponent.selectedChartValue.PlantId);
    this.AddCardform.get('line').patchValue(DynamicDashboardComponent.selectedChartValue.LineId);
    this.AddCardform.get('unit').patchValue(DynamicDashboardComponent.selectedChartValue.UnitId);
    this.sourceName = DynamicDashboardComponent.selectedChartValue.source === 1 ? 'Common Datapoints' : DynamicDashboardComponent.selectedChartValue.source === 2 ? 'KPIs' : 'Parameters';
    this.getUserList();
    //if(DynamicDashboardComponent.selectedChartValue.source === 2){
    this.KPIId = undefined;
    this.numbersArray = DynamicDashboardComponent.selectedChartValue.sourceValues.slice(1, -1).split(',');
    if (this.numbersArray.length === 1) {
      this.KPIId = parseInt(this.numbersArray[0]);
    } else {
      this.getDDBySourceList();
    }
    this.addActionForm?.reset();
    this.addActionForm.get('actionType')?.setValue('Corrective');
    this.addActionForm.get('kpi')?.setValue(DynamicDashboardComponent.chartProp.point.series.name);
    this.addStatus = 'Open';
    this.dialog.open(this.addActionModal, { height: 'auto', width: '100%' });
    // }else{
    //   this.toaster.info('info','You can not take action on this ');
    // }

  }

  getUserList(): void {
    // this.spinnerText = 'Fetching User Data';
    // this.spinner.show();
    const data = {
      EGId: this.AddCardform.value.UserGroup1,
      PlantId: this.AddCardform.value.UserPlant1,
    };
    this.dynamicService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        console.log(res);
        if (!res.HasError) {
          this.userList = res.employeeInfoList;
          this.userList.map(
            (user: any) =>
            (user.FullName =
              user.FirstName.trim() + ' ' + user.lastName.trim())
          );
          //this.getDepartment();
        }
        // this.spinner.hide();
      },
      (err: any) => {
        console.log(err);
        // this.spinner.hide();
      }
    );
  }
  //===========================================================SHare Tab======================================//
  getMenuAccesUserList(): void {
    this.spinner.show();
    const data = {
      plant: this.AddCardform.value.UserPlant1,
      menuId: this.pageId
    };
    this.dynamicService.getUserList(data).subscribe(
      (res: any) => {
        this.menuAccessUserList = res.Table;
        this.filteredUsers = this.menuAccessUserList.slice();
        this.spinner.hide();
      },
      (err: any) => {
        console.log(err);
        // this.spinner.hide();
      }
    );
  }

  shareTab(index: number) {
    //this.tabs.splice(index, 1);
    let dialogRef = this.dialog.open(this.shareTabPoup, {
      minWidth: '50%',
      minHeight: '50%',
    });
    dialogRef.afterOpened().subscribe((res: any) => {
      this.getMenuAccesUserList();
      this.getShareTabs();
    });
    dialogRef.afterClosed().subscribe((res: any) => {

    });

  }

  saveShareTab(): void {
    const users = this.ShareTabform.get('user').value;
    const tabs: any = [];
    users.forEach(val => {
      const obj = {
        TransactionType: 1,
        tabId: this.tabs[this.selectedTabIndex]?.Id,
        userId: val,
        accessType: this.ShareTabform.get('accessType').value
      }
      tabs.push(obj);
    })

    const data = {
      tabs: tabs
    }

    this.dynamicService.saveShareTab(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.getShareTabs();
      },
      (err: any) => {
        console.log(err);
        // this.spinner.hide();
        this.getShareTabs();
      }
    );

  }

  getShareTabs(): void {
    const key = {
      transactionType: 1,
      shareTabId: 0
    }
    this.dynamicService.getSharedTabs(key).subscribe(
      (res: any) => {
        this.rowDataSharedTabs = res.Table;
        this.spinner.hide();
      },
      (err: any) => {
        console.log(err);
        // this.spinner.hide();
      }
    );
  }

  deleteAccess(obj): void {
    if (this.IsAdmin) {
      const message = `Are you sure you want to delete this User?`;

      const dialogData = new ConfirmDialogModel("Confirm Action", message);

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        maxWidth: "600px",
        width: "450px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {

        if (dialogResult) {
          this.deleteUserAccess(obj);
        } else {
          console.log(dialogResult);
        }
      });
    } else {
      this.toaster.error('Error', 'You do not have sufficient Access for this Operation');
    }

  }

  deleteUserAccess(obj): void {
    const key = {
      transactionType: 2,
      shareTabId: obj.data.Id
    }
    this.dynamicService.getSharedTabs(key).subscribe(
      (res: any) => {
        this.rowDataSharedTabs = res.Table;
        this.spinner.hide();
        this.getShareTabs();
      },
      (err: any) => {
        console.log(err);
        // this.spinner.hide();
      }
    );
  }

  //====================================================Add new tab===================================================================//
  addNewTab(obj, TransactionType) {
    // Add a new tab and update the database using the TabsService
    const newTab = {
      tabName: obj.data?.TabName !== undefined ? obj.data?.TabName : this.AddTabform.get('tabName').value,
      addedFrom: 'customized',
      TransactionType: TransactionType,
      Id: TransactionType === 1 ? 0 : obj.data?.Id
    };

    this.dynamicService.saveTabsData(newTab).subscribe(
      (res: any) => {
        this.toaster.success(res.Table[0].Msg)
        this.getTabs();
      },
      (err: any) => {
        console.log(err);
        // this.spinner.hide();
      }
    );
  }

  confirmDialog(obj): void {
    const message = `Are you sure you want to delete this Dashboard?`;

    const dialogData = new ConfirmDialogModel("Confirm Action", message);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "600px",
      width: "450px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.addNewTab(obj, 3);
      } else {
        console.log(dialogResult);
      }
    });
  }

  getTabs() {
    this.dynamicService.getSharedAndIndividualTabs().subscribe(
      (data: any) => {
        this.tabs = data.Table,
          this.tabs.length > 0 ? this.IsTabExist = true : this.IsTabExist = false;
        this.rowData = this.tabs;
      },
      (err: any) => {
        console.log(err);
        // this.spinner.hide();
      }
    );
  }

  ngAfterViewInit(): void {
    DynamicDashboardComponent.createActionref = this.createAction;

  }

  tabSelected(index: number) {
    this.resetList();
    this.selectedTabIndex = index;
    this.access = this.tabs[this.selectedTabIndex]?.access === 1 ? false : true;
    this.getCardList();
    setTimeout(() => {
      // Call your method to render the chart based on the selected tab index
      this.getCardListRight();
    }, 3000); // You can adjust the delay as needed

  }

  //========================================================= Add Datapoint Source=============================================//
  getDataPointSourceList() {
    this.dynamicService.getDataPointSourceData().subscribe(
      (data: any) => {
        this.dataPointSources = data.Table;
        this.AddCardform.get('source').setValue(this.dataPointSources[0].Id);
        if (this.updateCard === true) {
          this.AddCardform.get('source').patchValue(this.updateValues.source);
          this.sourceName = this.dataPointSources.find(val => val.Id === this.updateValues.source)?.SourceName;
        }
        this.getChartList();
      },
      (err: any) => {
        console.log(err);
        // this.spinner.hide();
      }
    );
  }

  toggleOption(source: any) {
    const obj = this.dataPointSources.find(val => val.Id === source);
    this.sourceName = obj?.SourceName;
    this.sourceId = source;
    this.dynamicListByDatasource = [];
    this.filteredDynamicValues = [];
    this.filteredDataPoints = [];
    this.getChartList();
  }

  getDDBySourceList() {
    const params = {
      group: this.AddCardform.value.UserGroup1,
      plant: this.AddCardform.value.UserPlant1,
      unit: this.AddCardform.value.unit,
      line: this.AddCardform.value.line,
      sourceName: this.sourceName
    }
    this.dynamicService.getDDBySourceList(params).subscribe(
      (data: any) => {
        this.dynamicListByDatasource = data.Table;
        this.filteredDynamicValues = this.dynamicListByDatasource?.slice();
        this.filteredValueMulti.next(this.filteredDynamicValues);
        this.filterValue();
        if (this.updateCard === true && this.updateValues?.sourceValues !== null) {
          this.AddCardform.get('values').patchValue(JSON.parse(this.updateValues?.sourceValues));
          this.AddCardform.get('labelName').patchValue(this.updateValues?.labelName);
          this.AddCardform.get('analysisUrl').patchValue(this.updateValues?.analysisUrl);
          this.AddCardform.get('orderNo').patchValue(this.updateValues?.orderNo);
          this.AddCardform.get('Id').patchValue(this.updateValues?.Id);
        }
      },
      (err: any) => {
        console.log(err);
        // this.spinner.hide();
      }
    );
  }

  getAggregatesList() {
    this.dynamicService.getAggregatesList().subscribe(
      (data: any) => {
        this.AggeregateList = data.Table;
        this.AddCardform.get('aggregates').patchValue(this.AggeregateList[0].id);
        if (this.updateCard === true) {
          this.AddCardform.get('aggregates').patchValue(this.updateValues.aggregates);
        }
      },
      (err: any) => {
        console.log(err);
        // this.spinner.hide();
      }
    );
  }

  toggleAllSelection() {
    if (this.allSelectedOptions) {
      this.allSelected.options.forEach((item: MatOption) => item.select());
      // this.selectedMachines = this.AnalysisDashboardForm.controls.unit.value;
      // this.getVariableParameters();
    } else {
      this.allSelected.options.forEach((item: MatOption) => item.deselect());
      // this.selectedMachines = this.AnalysisDashboardForm.controls.unit.value;
      // this.getVariableParameters();
    }
  }

  onValueChange() {
    let newStatus = true;
    this.allSelected.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
        // this.selectedMachines = this.AnalysisDashboardForm.controls.unit.value;
        // this.getVariableParameters();

      } else {
        // this.selectedMachines = this.AnalysisDashboardForm.controls.unit.value;
        // this.getVariableParameters();
      }
    });
    this.allSelectedOptions = newStatus;
  }

  //========================================================== End =========================================================//

  //==========================================================AgGrid======================================================================//
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }
  rowClicked(params: any) {
    params.node.setData({
      ...params.data,
      dirty: true
    });
  }

  getMili(Start_Time: any) {
    var date = moment(Start_Time).format(this.format);
    return new Date(date).getTime();
  }
  // ----------------------------------------------------------resize card code ---------------------------------------------------//
  startResize(event: MouseEvent, card: any) {
    const cardElement = event.target as HTMLElement;
    const containerRect = this.container.nativeElement.getBoundingClientRect();
    const cardRect = cardElement.getBoundingClientRect();

    const initialX = event.clientX;
    const initialWidth = cardRect.width;
    const widthChart = 0

    const mouseMoveHandler = (e: MouseEvent) => {
      const dx = e.clientX - initialX;
      const newWidth = initialWidth + dx;

      if (newWidth > 0) {
        cardElement.style.width = newWidth + 'px';
        // Calculate the flex value to distribute width among other cards
        const totalWidth = containerRect.width;
        card.Item.width = (newWidth / totalWidth) * 100;
        card.chartOption?.ref.setSize(newWidth - 50, card.height);
        // Adjust zIndex for the resized card
        card.zIndex = 1;
      }
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);

    const originalWidth = containerRect.width; // Store the original card width
    const originalHeight = containerRect.height; // Store the original card height
  }

  toggleMaximize(card) {
    card.Item.isMaximized = true;
    if (card.Item.isMaximized) {
      //this.lastUpdatedWidth = card.Item.width;
      this.openBottomSheet(card);
      //card.Item.width = card.Item.width;
    } else {
      card.Item.width = this.lastUpdatedWidth;
    }
    //card.chartOption?.ref.setSize(card.Item.isMaximized ? card.Item.width * 16 : this.lastUpdatedWidth * 16, card.height);
  }

  saveLayout(card) {
    this.chartArrayRight.find((item) => item.Item.Id === card.Item.Id).width = card.Item.width;
    const params = {
      Id: card.Item.Id,
      width: card.Item.width
    }
    this.dynamicService.saveLayout(params).subscribe(
      (data: any) => {
        this.toaster.success(data.Table[0].Column1);
      },
      (err: any) => {
        console.log(err);
        // this.spinner.hide();
      }
    );
  }

  downloadPDF() {
    const chartsContainer = this.container.nativeElement;
    let totalPages = 1; // Initialize total pages
    let currentPage = 1; // Initialize current page
    const pdf = new jsPDF();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10;
    const topMargin = 30; // Top margin to avoid header overlap
    const maxImageHeight = pageHeight - topMargin - margin; // Adjust max image height
    let contentStartY = topMargin; // Start content below the header

    chartsContainer.querySelectorAll('.highcharts-container').forEach((chartElement, index) => {
      const chartSVG = chartElement.querySelector('svg');
      const chartWidth = parseInt(chartSVG.getAttribute('width'), 10);
      const chartHeight = parseInt(chartSVG.getAttribute('height'), 10);
      const aspectRatio = chartWidth / chartHeight;

      // Scale the chart to fit the page width while maintaining aspect ratio
      const scaledWidth = pageWidth - 2 * margin;
      const scaledHeight = scaledWidth / aspectRatio;

      // Convert SVG to canvas
      const canvas = document.createElement('canvas');
      canvas.width = chartWidth;
      canvas.height = chartHeight;
      const context = canvas.getContext('2d');
      const DOMURL = window.URL || window.webkitURL || window;
      const svgData = new XMLSerializer().serializeToString(chartSVG);
      const img = new Image();
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(svgBlob);

      img.onload = () => {
        context.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        const imageDataURL = canvas.toDataURL('image/png');
        this.addHeader(pdf); // Add header

        // Check if the new content will fit on the current page
        if (contentStartY + scaledHeight > maxImageHeight) {
          pdf.addPage();
          currentPage++;
          totalPages++;
          contentStartY = topMargin; // Reset Y position for new page
          this.addFooter(pdf, currentPage, totalPages); // Add footer
        }

        // Add the image to the PDF
        pdf.addImage(imageDataURL, 'PNG', margin, contentStartY, scaledWidth, scaledHeight);
        contentStartY += scaledHeight + margin; // Update Y position for next content

        // Save the PDF after the last chart
        if (index === chartsContainer.childElementCount - 1) {
          pdf.save('charts.pdf');
        }
      };

      img.src = url;
    });

  }

  addHeader(pdf) {
    pdf.setFillColor(15, 15, 209); // Grey color; change as needed
    pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), 20, 'F'); // Rectangle for header

    // Set text color and font size
    pdf.setTextColor(255, 255, 255); // White color; change if needed
    pdf.setFontSize(20);

    // Add centered text
    this.centerText('O3, Dynamic Dashboard', 15, pdf);
  }

  addFooter(pdf, pageNumber, totalPages) {
    const date = new Date();
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    pdf.setFontSize(10);
    pdf.text(`Page ${pageNumber} of ${totalPages}`, 10, pdf.internal.pageSize.getHeight() - 20);
    pdf.text(`Date: ${formattedDate}`, pdf.internal.pageSize.getWidth() - 40, pdf.internal.pageSize.getHeight() - 20);
  }

  centerText(text, y, pdf) {
    var textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
    var textOffset = (pdf.internal.pageSize.getWidth() - textWidth) / 2;
    pdf.text(text, textOffset, y);
  }


  static openActionsModel(obj, elem) {
    DynamicDashboardComponent.selectedChartValue = obj;
    DynamicDashboardComponent.chartProp = elem;
    this.dialog.open(DynamicDashboardComponent.createActionref);
  }

  openRcaDue() {
    // console.trace();
    // console.log(params);
    // this.analyticalToolsService.setHeaderId(params.data.headerId);

    // this.headerId = params.data.headerId;
    // let params = this.currentClickedRow;
    const dialogRef = this.dialog.open(AtStagesNcrComponent, {
      height: '100%',
      width: '90%',
      panelClass: 'full-screen-modal',
      data: {
        InitProbStatement: DynamicDashboardComponent.selectedChartValue.labelName,
        // ccrID: params.id,
        // tableId: params.id,
        moduleId: 5,
        patchForm: 0,
        patchHeader: true,
        Group: DynamicDashboardComponent.selectedChartValue.GroupId,
        Plant: DynamicDashboardComponent.selectedChartValue.PlantId,
        headerId: 0,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      // this.analyticalToolsService.setHeaderId(0);

      console.log('RCA Closed');
      console.log(data);

      // if (
      //   data.StatusFiveWhyAction === 'Completed' &&
      //   data.StatusCycle === 'Completed'
      // ) {
      //   // const key = {
      //   //   ccrId: params.data.ccrId,
      //   //   status: params.data.status,
      //   //   rcaDue: 'Yes',
      //   //   implementCapa: params.data.implementCapa,
      //   //   ccrStatus: params.data.ccrStatus,
      //   // };
      //   // this.ccrService.getStatus(key).subscribe((response: any) => {
      //   //   console.log(response);
      //   //   this.onSearchClick();
      //   // });
      // }
      // this.onSearchClick();
    });
  }

  openBottomSheet(card: any) {
    // const dataToStore = {
    //   // Only include the properties you need to rebuild the chart
    //   series: card.chartOption.options.series,
    //   xAxis: card.chartOption.options.xAxis,
    //   yAxis: card.chartOption.options.yAxis,
    //   plotOptions : card.chartOption.options.plotOptions,

    //   // ... other necessary properties
    // };

    localStorage.setItem('chartData', JSON.stringify(card.chartOption.options));
    this.bottomSheet.open(CustomBottomSheetComponent);
  }

  plotChart() {
    for (let i = 0; i < this.chartsLoop.length; i++) {
      let target, targetposition;
      let plotlines = [];
      if (this.chartsLoop[i].Target && this.chartsLoop[i].Target[0] == 'M') {
        if (this.chartsLoop[i].Target.split(" ")[1][(this.chartsLoop[i].Target.split(" ")[1]).length - 1] == '%') {
          target = parseFloat(this.chartsLoop[i].Target.split(" ")[1].split('%')[0]);
        }
        else {
          target = parseFloat(this.chartsLoop[i].Target.split(" ")[1])
        }
        if (this.chartsLoop[i].Target.split(" ")[0] == 'Max.') {
          targetposition = -20;
        }
        if (this.chartsLoop[i].Target.split(" ")[0] == 'Min.') {
          targetposition = 20;
        }
        plotlines.push(
          {
            color: '#ff5252',
            width: 2,
            value: target,
            label: {
              text: this.chartsLoop[i].Target,
              style: {
                color: '#ff5252',
                fontWeight: 'bold',
              },
              textAlign: 'left',
              y: targetposition
            },
          }
        )
      }
      if (this.chartsLoop[i].Target && this.chartsLoop[i].Target[0] !== 'M') {
        let lines = this.chartsLoop[i].Target.split("±");
        if (lines.length == 1) {
          lines = this.chartsLoop[i].Target.split("+");
          target = parseFloat(lines[0]);
          let text = parseFloat(target + parseFloat(lines[1].split('%')[0])).toFixed(2);
          plotlines.push(
            {
              color: '#ff5252',
              width: 2,
              value: target + parseFloat(lines[1].split('%')[0]),
              label: {
                text: 'Max. ' + text + '%',
                style: {
                  color: '#ff5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: -20
              },
            }
          );
          text = (target).toString();
          plotlines.push(
            {
              color: '#ff5252',
              width: 2,
              value: target - parseFloat(lines[1].split('%')[0]),
              label: {
                text: 'Min. ' + text + '%',
                style: {
                  color: '#ff5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: 20
              },
            }
          );
        }
        else {
          target = parseFloat(lines[0]);
          let text = parseFloat(target + parseFloat(lines[1].split('%')[0])).toFixed(2);
          plotlines.push(
            {
              color: '#ff5252',
              width: 2,
              value: target + parseFloat(lines[1].split('%')[0]),
              label: {
                text: 'Max. ' + text + '%',
                style: {
                  color: '#ff5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: -20
              },
            }
          );
          text = parseFloat((target - parseFloat(lines[1].split('%')[0])).toString()).toFixed(2);
          plotlines.push(
            {
              color: '#ff5252',
              width: 2,
              value: target - parseFloat(lines[1].split('%')[0]),
              label: {
                text: 'Min. ' + text + '%',
                style: {
                  color: '#ff5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: 20
              },
            }
          );
        }
      }
      let data = this.chartsLoop[i].Value.split(',').map(str => Number(str));
      let limit = data.slice();
      this.chartOptions = {
        chart: {
          type: 'spline'
        },
        credits: {
          enabled: false,
        },
        title: {
          text: this.chartsLoop[i].Parameter
        },
        xAxis: {
          categories: this.chartsLoop[i].CreatedOn.split(',')
        },
        yAxis: {
          title: {
            text: "Target - " + this.chartsLoop[i].Target
          },
          min: limit.sort((a, b) => a - b)[0] - 2,
          max: limit.sort((a, b) => a - b)[limit.length - 1] + 2,
          plotLines: plotlines
        },
        plotOptions: {
          spline: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: true
          }
        },
        legend: {
          enabled: false
        },
        series: [{
          data: data,
          color: this.highChartsColor[i]
        }]
      }
      Highcharts.chart(this.chartsLoop[i].Parameter.toString(), this.chartOptions);
    }
    this.spinner.hide();
  }

}