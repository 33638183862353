import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class KpiMasterWeightService {
  
  O3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getMasterWeights(data: any): Observable<any[]> {

    // return this.http.post<any[]>(this.baseUrl + 'getmasterweight', data);
    return this.http.post<any[]>(this.O3CoreApiUrl + 'KPIMasterWeight/MasterWeightData', data);
  }

  addMasterWeights(data: any): Observable<any> {

    // return this.http.post<any>(this.baseUrl + 'masterweight', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'KPIMasterWeight/AddMasterWeight', data);
  }

  updateMasterWeights(data: any): Observable<any> {
    //return this.http.post<any>(this.baseUrl + 'masterweightupdate', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'KPIMasterWeight/UpdateMasterWeight', data);
  }

  getMasterWeightData(data: any): Observable<any> {

    //return this.http.post<any>(this.baseUrl + 'masterweightdata', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'KPIMasterWeight/GetMasterWeightData', data);
  }

  deleteMasterWeightData(data: any): Observable<any> {

    // return this.http.post<any>(this.baseUrl + 'deletemasterweight', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'KPIMasterWeight/DeleteMasterWeight', data);
  }

  getWeightfrequency(data: any): Observable<any[]> {

    return this.http.post<any[]>(this.O3CoreApiUrl + 'KPIMasterWeight/GetWeightFrequency', data);
  }

}
