import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AreaService } from 'src/app/services/area.service';
import { ProcessAuditCategoryService } from 'src/app/services/process-audit-category.service';
import { ProcessExecutionService } from 'src/app/services/processexecution.service';
import { CheckpointsService } from 'src/app/services/checkpoints.service';
import { GlobalStandardService } from 'src/app/services/global-standard.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { ScoreTableService } from 'src/app/services/score-table.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-process-execution',
  templateUrl: './process-execution.component.html',
  styleUrls: ['./process-execution.component.scss'],
})
export class ProcessExecutionComponent implements OnInit {
  searchAuditMasterForm!: FormGroup;
  GlobalData = new MatTableDataSource<any>([]);

  auditForm!: FormGroup;
  plants!: any;
  plantsDDL!: any[];
  areaDDL!: any[];
  scoreData!: any[];
  toggle = true;
  status = 'Enable';
  categoryTypeDDL: any[] = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
  ];

  globalColumns: string[] = ['plant', 'area', 'type', 'name', 'file'];

  // AEDetails!: any[];
  audicategories!: any[];
  checkPointsData = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChildren(MatPaginator) paginator1 = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort1 = new QueryList<MatSort>();
  auditexeColumns: string[] = ['srno', 'name', 'score'];
  filterdplants!: any[];
  groupListDDL!: any[];
  breadcrumList: any;
  IsWrite: any;
  IsAccess: any;
  Id = 0;
  Categorytype: any;
  nonCompList: any[] = [];
  compList: any[] = [];
  moduleId!: any;
  pageId!:any;
  imageUrl: string;

  constructor(
    private fb: FormBuilder,
    private kpiService: KpimasterService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private areaService: AreaService,
    private sccoreService: ScoreTableService,
    private spinner: NgxSpinnerService,
    private processCategoryService: ProcessAuditCategoryService,
    private checkpointService: CheckpointsService,
    private processExecutionService: ProcessExecutionService,
    private globalService: GlobalStandardService,
    private userService: UserManagementService,
    private machineService: MachineMappingService,
    public router: Router,
  ) {}

  @ViewChild('displayRoleModal1') private displayRoleModal1!: TemplateRef<any>;

  ngOnInit(): void {
    this.imageUrl = environment.O3CoreApiImageUrl;
    this.getGroupData();
    this.searchAuditMasterForm = this.fb.group({
      plant: ['', Validators.required],
      area: ['', Validators.required],
      group: ['', Validators.required],
    });
    this.auditForm = this.fb.group({
      sort: [''],
      order: [''],
      shine: [''],
      standardize: [''],
      sustain: [''],
      group: [''],
      catType: [''],
    });
    this.GetMenuAccess();
    this.GetBreadcrumList();
  }

  getAuditCategoryMaster(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const key = {
      plantId: plant, // .ptId,
    };
    this.processCategoryService.GetAllCategories(key).subscribe(
      (data) => {
        this.audicategories = data;
        
      },
      (error) => {
        this.toaster.error(error.error.Message);
      }
    );
  }


  changeArea(): void {
    this.resetButtonColor();
    this.checkPointsData.data = [];
    this.getPaginator();
  }
  getAuditAreaByPlant(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const key = {
      plantId: plant, // .ptId,
    };
    this.getAuditCategoryMaster();
    this.getScoreByPlant();
    this.areaService.getProcessCheckpoint(key).subscribe(
      (data) => {
        this.areaDDL = data;
        if (this.areaDDL.length > 0) {
          this.searchAuditMasterForm.get('area')?.setValue(data[0].areaId);
        }
        this.checkPointsData.filteredData = [];
      },
      (error) => {
        this.toaster.error(error.error.message);
      }
    );
  }

  getPaginator(): void {
    setTimeout(() => {
      this.checkPointsData.paginator = this.paginator;
      this.checkPointsData.sort = this.sort;
    }, 1000);
  }
  resetButtonColor(): void {
    const ele = Array.from(
      document.getElementsByClassName(
        'btnGreen'
      ) as HTMLCollectionOf<HTMLElement>
    );
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < ele.length; i++) {
      ele[i].style.backgroundColor = '#10ce9c';
    }
  }
  getCheckpointsByCategory(index: any, data: any): void {
    this.Categorytype = data.catType;
    this.resetButtonColor();
    const ele = Array.from(
      document.getElementsByClassName(
        'btnGreen'
      ) as HTMLCollectionOf<HTMLElement>
    );
    ele[index].style.backgroundColor = '#33b5e5';
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const area = this.searchAuditMasterForm.get('area')?.value;

    if (area !== '') {
      const key = {
        plantId: plant,
        processCatId: data.processCatId,
        areaId: area,
      };
      this.spinner.show();
      this.checkpointService.getProcessCheckpointsbyCategory(key).subscribe(
        (res: any) => {
          res.forEach((element: any) => {
            element.score = null;
            element.CatTypeDetail = null;
          });
          this.checkPointsData.data = res;
          // this.getScoreByPlant();
          this.getPaginator();
          this.spinner.hide();
        },
        (error) => {
          this.toaster.error(error.error.message);
          this.spinner.hide();
        }
      );
    } else {
      this.toaster.error('Select Area First..!  ');
      this.spinner.hide();
    }
  }

  getScoreByPlant(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const key = {
      plantId: plant, // .ptId,
    };
    this.sccoreService.getScores(key).subscribe(
      (data) => {
        this.nonCompList = [];
        this.compList = [];
        this.scoreData = data;
        data.map((element: any) => {
          const score = element.score;
          if (score < 3) {
            this.nonCompList.push(element);
          } else {
            this.compList.push(element);
          }
        });
      },
      (error) => {
        this.toaster.error(error.error.Message);
      }
    );
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.searchAuditMasterForm
          .get('group')
          ?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.searchAuditMasterForm.value.group,
    };
    // this.MachineId = null;
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.filterdplants = data.DepartList;
        if (this.filterdplants.length > 0) {
          this.searchAuditMasterForm
            .get('plant')
            ?.setValue(this.filterdplants[0].DeptId);
        } else {
          this.searchAuditMasterForm.get('plant')?.setValue(null);
        }
        this.getAuditAreaByPlant();
        this.audicategories = [];
        this.areaDDL = [];
        this.checkPointsData.data = data;
        this.getPaginator();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  openGlobalModal(): void {
    this.dialog.open(this.displayRoleModal1, { height: '80%', width: '90%' });
    this.getGlobalStandards();
  }

  getGlobalStandards(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const key = {
      plantId: plant,
    };
    this.spinner.show();

    this.globalService.getGlobalStandard(key).subscribe(
      (data) => {
        this.GlobalData.data = data;
        this.spinner.hide();

        this.getGlobalPaginator(this.GlobalData, 1);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }

  getGlobalPaginator(dataa: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataa.paginator = this.paginator1.toArray()[index];
    }, 1000);
  }

  scoreUpdate(event: any, data: any): void {
    const checkPointId = parseInt(data.checkPointId);
    if (data.CatType === 'Option') {
      const v = event.value;
      const d = this.checkPointsData.filteredData.find(
        (x) => x.checkPointId === checkPointId
      );
      d.CatTypeDetail = v;
    } else {
      const score = event.value; // parseInt(event.target.value);

      const m = this.checkPointsData.filteredData.find(
        (x) => x.checkPointId === checkPointId
      );
      m.score = score;
    }

    const result = this.checkPointsData;
  }

  saveAuditExecution(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const area = this.searchAuditMasterForm.get('area')?.value;
    const result = this.checkPointsData.filteredData;
    const executionDetails: any[] = [];

    if (this.Categorytype === 'Option') {
      const filteredData = this.checkPointsData.filteredData.filter(
        (c) => c.CatTypeDetail !== null
      );
      if (filteredData.length > 0) {
        filteredData.forEach((element) => {
          const v = {
            checkPointId: element.checkPointId,
            score: null,
            catType:
              element.CatTypeDetail === ('Yes' || 'No')
                ? element.CatTypeDetail
                : 'No',
            userId: JSON.parse(localStorage.user).UserId,
          };
          executionDetails.push(v);
        });
      } else {
        return;
      }
    } else {
      const filteredData = this.checkPointsData.filteredData.filter(
        (c) => c.score !== null
      );
      if (filteredData.length > 0) {
        filteredData.forEach((element) => {
          const v = {
            checkPointId: element.checkPointId,
            score: element.score,
            catType: null,
            userId: JSON.parse(localStorage.user).UserId,
          };
          executionDetails.push(v);
        });
      } else {
        return;
      }
    }
    const pCategoryId = result[0].processCatId;
    // const arrData = result.map((x) => return [ x.checkPointId, x.score]);
    const key = {
      plantId: plant,
      areaId: area,
      processCategoryId: pCategoryId,
      AEDetails: executionDetails,
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.processExecutionService
      .addProcessExecutionServiceExecution(key)
      .subscribe(
        (response) => {
          this.clearScoreData();
          this.toaster.success(
            'Success',
            'Process Execution Added Successfully'
          );
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
  }
  // breadCrumb

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiService.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  clearScoreData(): void {
    this.checkPointsData.data.forEach((element: any) => {
      element.CatTypeDetail = null;
    });
    this.checkPointsData.data.forEach((element: any) => {
      element.score = null;
    });
  }
}
