<div class="maintenance-board-content">
    <div class="content-top-box">
        <ul class="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                    <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
                </li>
            </ul>
        </ul>
        <div class="title-row-flex-both-end">
            <h1 class="main-title">Production and Quality</h1>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <form [formGroup]="productionQualityForm">
        <div class="content-top-row content-top-row-order-processing">
            <!-- GROUP -->
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <!-- <mat-label>Status</mat-label> -->
                <mat-select formControlName="UserGroup1" (selectionChange)="onChangeGroup($event)" placeholder="Group"
                    panelClass="testClass" name="group">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
                        (filteredReturn)="filteredGroups = $event"></mat-select-filter>
                    <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
                        {{group.EG_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="UserPlant1" (selectionChange)="onChangePlant($event)" placeholder="Plant"
                    panelClass="testClass" name="plant">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList1"
                        (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                    <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
                        {{plant.PT_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="line" (selectionChange)="onChangeLine($event)" placeholder="Line"
                    panelClass="testClass" name="line">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
                        (filteredReturn)="filteredLines = $event"></mat-select-filter>
                    <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
                        {{line.PL_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="unit" placeholder="Unit" panelClass="testClass" name="unit"
                    (selectionChange)="onSubmit()">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="unitList"
                        (filteredReturn)="filteredUnits = $event"></mat-select-filter>
                    <mat-option *ngFor="let unit of filteredUnits" [value]="unit.PU_Id">
                        {{unit.PU_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- Dropdown -->
        <div class="content-top-row content-top-row-order-processing">
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <input matInput [ngxMatDatetimePicker]="startPicker" placeholder="Start Date"
                    formControlName="dateStart" (dateChange)="onSubmit()" [min]="minDate" [max]="maxDate"
                    [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #startPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                    [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                    [color]="color" [enableMeridian]="enableMeridian">
                </ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <input matInput [ngxMatDatetimePicker]="endPicker" placeholder="End Date" formControlName="dateEnd"
                    (dateChange)="onSubmit()" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #endPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                    [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                    [color]="color" [enableMeridian]="enableMeridian">
                </ngx-mat-datetime-picker>
            </mat-form-field>
            <div class="d-flex" style="margin: 10px;">
                <div class="mr-10" style="margin: inherit;">
                    <p class="topBtn mx-2" (click)="today();showPhase(1)" [class.phase-active]="activeButton === 1" matTooltipClass="custom-tooltip" matTooltip="Today(Current Shift)">T</p>
                </div>
                <div class="mr-10" style="margin: inherit;">
                    <p class="topBtn mx-2" (click)="yesterday();showPhase(2)" [class.phase-active]="activeButton === 2"  matTooltipClass="custom-tooltip" matTooltip="Yesterday(Last Shift)">1D</p>
                </div>
                <div class="mr-10" style="margin: inherit;">
                    <p class="topBtn mx-2" (click)="lastWeek();showPhase(3)" [class.phase-active]="activeButton === 3"  matTooltipClass="custom-tooltip" matTooltip="Last Week">1W</p>
                </div>
                <div class="mr-10" style="margin: inherit;">
                    <p class="topBtn mx-2" (click)="lastMonth();showPhase(4)" [class.phase-active]="activeButton === 4"  matTooltipClass="custom-tooltip" matTooltip="Last Month">1M</p>
                </div>
            </div>
        </div>

        <div class="card-wrapper-list maintenance-board">
            <div class="card-item card-item-five">
                <div class="card">
                    <div class="card-suffix-icon">
                    </div>
                    <div class="content" style="position: relative">
                        <o3-percentage-graph [barProperties]="oeeAnalysis"></o3-percentage-graph>
                        <p class="graph-title">OEE</p>
                    </div>
                </div>
            </div>

            <div class="card-item card-item-five">
                <div class="card">

                    <o3-percentage-graph [barProperties]="availabilityAnalysis"></o3-percentage-graph>
                    <p class="graph-title">Availability</p>
                </div>
            </div>
            <div class="card-item card-item-five">
                <div class="card">

                    <o3-percentage-graph [barProperties]="performanceAnalysis"></o3-percentage-graph>
                    <p class="graph-title">Performance</p>
                </div>
            </div>
            <div class="card-item card-item-five">
                <div class="card">

                    <o3-percentage-graph [barProperties]="qualityAnalysis"></o3-percentage-graph>
                    <p class="graph-title merge-style">Quality</p>
                </div>
            </div>
            <div class="card-item card-item-five">
                <div class="card">

                    <o3-percentage-graph [barProperties]="productivityAnalysis"></o3-percentage-graph>
                    <p class="graph-title merge-style">Productivity</p>
                </div>
            </div>
        </div>

        <!-- Card -->
        <div class="menu-card-group">
            <div class="menu-card-item mat-card-height">
                <p class="title-text">Ideal Production</p>
                <p class="data-text">
                    {{idealProduction}}
                    <span class="data-text small-data-text"></span>
                </p>
                <div class="menu-bottom-icon">
                    <img class="img" [src]="menuRedIconUrl" alt="{{ menuRedIconUrl }}" />
                </div>
            </div>
            <div class="menu-card-item mat-card-height">
                <p class="title-text">Gross Production</p>
                <p class="data-text">
                    {{GrossProduce}}
                    <span class="data-text small-data-text"></span>
                </p>
                <div class="menu-bottom-icon">
                    <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
                </div>
            </div>
            <div class="menu-card-item mat-card-height">
                <p class="title-text">Net Production</p>
                <p class="data-text">
                    {{netProduction}}
                    <span class="data-text small-data-text"></span>
                </p>
                <div class="menu-bottom-icon">
                    <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
                </div>
            </div>
        </div>
        <div class="menu-card-group">
            <div class="menu-card-item mat-card-height">
                <p class="title-text">Waste</p>
                <p class="data-text">
                    {{wasteKG}}
                    <span class="data-text small-data-text"></span>
                </p>
                <div class="menu-bottom-icon">
                    <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
                </div>
            </div>
            <div class="menu-card-item mat-card-height">
                <p class="title-text">Waste %</p>
                <p class="data-text">
                    {{Waste}}
                    <span class="data-text small-data-text"></span>
                </p>
                <div class="menu-bottom-icon">
                    <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
                </div>
            </div>
        </div>
        <div class="chart-group">
            <div class="chart-items">
                <div class="chart-title">Machine Stops</div>
                <div [chart]="chartMachine"></div>
            </div>
            <div class="chart-items">
                <div class="chart-title">Waste Waterfall</div>
                <div [chart]="chartWasteWaterfall"></div>
            </div>
        </div>
        <div class="chart-group">
            <div class="chart-items">
              <div class="chart-title">Day Shift</div>
              <div [chart]="chartShiftDay"></div>
            </div>
            <div class="chart-items">
              <div class="chart-title">Night Shift</div>
              <div [chart]="chartShiftNight"></div>
            </div>
          </div>
    </form>
    <section>
        <div class="example-button-row">
          <button type="button" class="btn btn-success"  (click)="csvExport()" style="margin-top: 30px;"><img src="assets/images/csv-file-format.png" ></button>
          <button type="button" class="btn btn-success" (click)="excelExport()" style="margin-top: 30px; margin-left: 10px;"><i class="fa fa-file-excel-o icon-white"  aria-hidden="true"></i></button>
        </div>
      </section>
      <mat-card-content>
        <ag-grid-angular style="width: 100%; height: 20vw" class="ag-theme-alpine" [rowData]="rowData"
          [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" (firstDataRendered)="headerHeightSetter()"
          (columnResized)="headerHeightSetter()" [gridOptions]="gridOptions">
        </ag-grid-angular>
      </mat-card-content>
</div>