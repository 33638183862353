import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

const moment = _moment;

@Component({
  selector: 'app-availability-analysis',
  templateUrl: './availability-analysis.component.html',
  styleUrls: ['./availability-analysis.component.scss']
})
export class AvailabilityAnalysisComponent implements OnInit {
  dropDownForm!: FormGroup;
  //Drop-down Variables
  yearList = ['2015', '2016', '2017', '2018', '2019', '2020', '2021'];
  monthList = ['ALL', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  weekList = ['ALL', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  dayList = ['ALL', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  shiftList = ['ALL', 'Morning', 'Evening', 'Night'];
  machineList = ['ALL', 'RTM1', 'RTM2'];

  plantList = ['P1', 'P2'];
  canvas: any;
  ctx: any;
  canvas1: any;
  ctx1: any;
  canvas2: any;
  ctx2: any;
  canvas3: any;
  ctx3: any;
  canvas4: any;
  ctx4: any;
  canvas5: any;
  ctx5: any;
  canvas6: any;
  ctx6: any;
  canvas7: any;
  ctx7: any;
  canvas8: any;
  ctx8: any;
  canvas9: any;
  ctx9: any;
  canvas10: any;
  ctx10: any;
  canvas11: any;
  ctx11: any;


  // Date Time Range
  range = new FormGroup({
    startDate: new FormControl(moment()),
    endDate: new FormControl(moment())
  });

  // Charts

  // OEE Loses
  oeeLoses = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    barChartData: [
      { data: [1.3, [1.2, 1.3], 0, 0, 0, 0, 0, 0], label: '  Lorem Ipsem' },
      { data: [0, 0, 1.2, [1, 1.2], [0.8, 1], [0.65, 0.8], [0.6, 0.65], 0], label: '  Lorem Ipsem' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0.6], label: '  Target' },
    ],
    barChartColors: [
      { backgroundColor: "#040496" },
      { backgroundColor: "#F85439" },
      { backgroundColor: "#10CE9C" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 0.4,
            },
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 30,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Availability Loses
  availabilityLoses = {
    barChartLabels: ['Availability', 'PLanned DT', 'Unplanned DT', 'Target'],
    barChartData: [
      { data: [62, [62, 80], [80, 100.1], 0], label: '  Lorem Ipsem' },
      { data: [0, 0, 0, 0], label: '  Lorem Ipsem' },
      { data: [0, 0, 0, 100.1], label: '  Target Loss' },
    ],
    barChartColors: [
      { backgroundColor: "#F85439" },
      { backgroundColor: "#10CE9C" },
      { backgroundColor: "#040496" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 20,
              callback: function (value: any, index: any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            },
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 60,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Planned Downtime
  plannedDowntime = {
    barChartLabels: ['Changeovers DT', 'Preventive Maint. DT', 'Autonomous Maint. DT'],
    barChartData: [
      { data: [10.1, 6.2, 5], label: '  Lorem Ipsem' },
    ],
    barChartColors: [
      { backgroundColor: "#F85439" },
    ],
    barChartOptions: {
      labelString: "  Percentage",
      tooltips: {
        callbacks: {
          label: function (tooltipItem: any, data: any) {
            return data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 2,
              callback: function (value: any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }
        ],
        xAxes: [{
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Unplanned Downtime
  unplannedDowntime = {
    barChartLabels: ['Mechanical Downtime', 'Short Stops', 'Electrical Downtime', 'Utilities Downtime'],
    barChartData: [
      { data: [12.2, 9, 6.2, 5], label: '  Lorem Ipsem' },
    ],
    barChartColors: [
      { backgroundColor: "#F85439" },
    ],
    barChartOptions: {
      labelString: "  Percentage",
      tooltips: {
        callbacks: {
          label: function (tooltipItem: any, data: any) {
            return data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 2,
              callback: function (value: any, index: any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }
        ],
        xAxes: [{
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Changeovers DT
  changeoversDT = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '10'],
    barChartData: [
      { data: [16, 12, 18.5, 12, 14, 15, 10, 12, 16, 14], label: '  Lorem' },
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 2,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Mechanical Downtime
  mechanicalDowntime = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
    barChartData: [
      { data: [9, 8.5, 8, 7.5, 7, 6.7, 6.5, 6.3, 6, 5.7, 5.5, 5.3, 5, 4.7, 4.5, 4, 3.5, 3, 2.5, 2], label: '  Lorem' },
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 2,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 10,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  //  # of Changeovers
  changeOvers = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    barChartData: [
      { data: [9.1, 8, 7, 6, 5, 4, 3, 2], label: '  Lorem' },
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  //  Short Stops
  shortStops = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    barChartData: [
      { data: [9.1, 8, 7.5, 7, 6, 5.5, 5, 4, 3.5, 3, 2.5, 2], label: '  Lorem' },
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  //  Preventive Maint. DT
  preventiveMaintDT = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    barChartData: [
      { data: [9.1, 8, 7, 6, 5, 4, 3, 2], label: '  Lorem' },
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Electrical Downtime
  electricalDowntime = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    barChartData: [
      { data: [4.1, 3.8, 3.5, 3.2, 2.9, 2.6, 2.3, 1.9, 1.6, 1.2], label: '  Lorem' },
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Autonomous Maint. DT
  autonomousMaintDT = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    barChartData: [
      { data: [3.3, 3, 2.7, 2.5, 2.2, 2, 1.8, 1.5], label: '  Lorem' },
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 0.5,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Utilities Downtime
  utilitiesDowntime = {
    barChartLabels: ['1', '2', '3', '4', '5'],
    barChartData: [
      { data: [1.3, 0.9, 0.6, 0.4, 0.4], label: '  Lorem' },
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 0.2,
              beginAtZero: true,
            }
          }
        ],
        xAxes: [{
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;


  constructor(
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {


    Chart.defaults.global.legend.labels.usePointStyle = true;
    this.dropDownForm = this.formBuilder.group({
      groupList: [''],
      UnitDataList: [''],
    });

     // % of Defect Closing
     this.canvas = document.getElementById('myChart');
     this.canvas.height = 400;
     this.canvas.width = 480;
     this.ctx = this.canvas.getContext('2d');
     // this.ctx.height = 400;
     const defectClosing = new Chart(this.ctx, {
       type: 'bar',
       data: {
         labels: ['Total capacity', 'Shutdown', 'Planned capacity', 'Unplanned downtime', 'Planned downtime', 'Speed loss', 'Waste loss', 'OEE achieved'],
         datasets: [  
          { data: [1.3, [1.2, 1.3], 0, 0, 0, 0, 0, 0], label: '  Achieved' ,backgroundColor: "#040496"},
          { data: [0, 0, 1.2, [1, 1.2], [0.8, 1], [0.65, 0.8], [0.6, 0.65], 0], label: '  Losses', backgroundColor: "#F85439"  },
          { data: [0, 0, 0, 0, 0, 0, 0, 0.6], label: '  Target' , backgroundColor: "#10CE9C"},
         
        ],
       
       
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any) => {
               return data.datasets[0].data[tooltipItem.index];
             }
           }
         },
         legend: {
           display: true,
           position: 'bottom',
           labels: {
             padding: 20
           }
         },
         scales: {
           xAxes: [{
            barThickness: 18,
           
             gridLines: {
               display: false,

             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 2],
             },
             ticks: {
               gridDashType: 'dash',
               
                beginAtZero: true,
                stepSize: 0.4,
               min: 0,
               max: 1.6,
               callback: (value: any) => {
                 return value ;
               }
             }
           }]
         }
       }
     });


     this.canvas3 = document.getElementById('myChart1');
     this.ctx3 = this.canvas3.getContext('2d');
     this.canvas3.height = 400;
     this.canvas3.width = 480;
     const cilTasks = new Chart(this.ctx3, {
       type: 'bar',
       data: {
         labels: ['Availbility', 'Planned DT', 'Unplanned DT', 'target' ],
         datasets: [ 
           { data: [62, [62, 80], [80, 100.1], 0], label: '  Achieved',backgroundColor: "#F85439" },
           { data: [0, 0, 0, 0], label: '  Losses',backgroundColor: "#F85439" },
           { data: [0, 0, 0, 100.1], label: '  Target',backgroundColor: "#040496" },
          
         ]
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any)  => {
               return data.datasets[0].data[tooltipItem.index] + '%';
             }
           }
         },
         legend: {
           display: true,
           position: 'bottom',
           labels: {
             padding:20,
            
           }
         },
         scales: {
           xAxes: [{
            barThickness: 24,
             gridLines: {
               display: false
             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 4],
             },
             ticks: {
               Linedash: [10, 5],
               stepSize: 20,
               min: 0,
               max: 120,
               callback: (value: any) => {
                 return value + '%';
               }
             }
           }]
         }
       }
     });


     this.canvas2 = document.getElementById('myChart2');
     this.ctx2 = this.canvas2.getContext('2d');
     this.canvas2.height = 400;
     this.canvas2.width = 480;
     const cilTask = new Chart(this.ctx2, {
       type: 'bar',
       data: {
         labels: ['ChangeOvers DT', 'Preventive Maint.DT', 'Autonomous Maint.DT' ],
         datasets: [ 
           { data: [10.1, 6.2, 5], label: '  Lorem Ipsem',backgroundColor: "#F85439" },
          
         ]
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any)  => {
               return data.datasets[0].data[tooltipItem.index] + '%';
             }
           }
         },
         legend: {
           display: false,
           position: 'bottom',
           labels: {
             padding: 20
           }
         },
         scales: {
           xAxes: [{
            barThickness: 48,
             gridLines: {
               display: false
             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 4],
             },
             ticks: {
               Linedash: [10, 5],
               stepSize: 2,
               min: 0,
               max: 12,
               callback: (value: any) => {
                 return value + '%';
               }
             }
           }]
         }
       }
     });

     this.canvas3 = document.getElementById('myChart3');
     this.ctx3 = this.canvas3.getContext('2d');
     this.canvas3.height = 400;
     this.canvas3.width = 480;
     const cilTask3 = new Chart(this.ctx3, {
       type: 'bar',
       data: {
         labels: ['Machanical Downtime', 'Short Stops', 'Electrical Downtime','Utilities DownTime' ],
         datasets: [ 
           {data: [12.2, 9, 6.2, 5], label: '  Lorem Ipsem',backgroundColor: "#F85439" },
          
         ]
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any)  => {
               return data.datasets[0].data[tooltipItem.index] + '%';
             }
           }
         },
         legend: {
           display: false,
           position: 'bottom',
           labels: {
             padding: 20
           }
         },
         scales: {
           xAxes: [{
            barThickness: 48,
             gridLines: {
               display: false
             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 4],
             },
             ticks: {
               Linedash: [10, 5],
               stepSize: 2,
               min: 0,
               max: 14,
               callback: (value: any) => {
                 return value + '%';
               }
             }
           }]
         }
       }
     });

     this.canvas4 = document.getElementById('myChart4');
     this.ctx4 = this.canvas4.getContext('2d');
     this.canvas4.height = 400;
     this.canvas4.width = 480;
     const cilTask4 = new Chart(this.ctx4, {
       type: 'bar',
       data: {
         labels: ['CDT1','CDT2','CDT3','CDT4','CDT5','CDT6','CDT7','CDT8','CDT9','CDT10' ],
         datasets: [ 
           {data: [16, 12, 18.5, 12, 14, 15, 10, 12, 16, 14], label: '  Lorem Ipsem',backgroundColor: "#3AAFE4" },
          
         ]
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any)  => {
               return data.datasets[0].data[tooltipItem.index];
             }
           }
         },
         legend: {
           display: false,
           position: 'bottom',
           labels: {
             padding: 20
           }
         },
         scales: {
           xAxes: [{
            barThickness: 15,
             gridLines: {
               display: false
             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 4],
             },
             ticks: {
               Linedash: [10, 5],
               stepSize: 2,
               min: 0,
               max: 20,
               callback: (value: any) => {
                 return value;
               }
             }
           }]
         }
       }
     });


     this.canvas5 = document.getElementById('myChart5');
     this.ctx5 = this.canvas5.getContext('2d');
     this.canvas5.height = 400;
     this.canvas5.width = 480;
     const cilTask5 = new Chart(this.ctx5, {
       type: 'bar',
       data: {
         labels: ['MDT1','MDT2','MDT3','MDT4','MDT5','MDT6','MDT7','MDT8','MDT9','MDT10'],
         datasets: [ 
           {data: [9, 8.5, 8, 7.5, 7, 6.7, 6.5, 6.3, 6, 5.7], label: '  Lorem Ipsem',backgroundColor: "#3AAFE4" },
          
         ]
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any)  => {
               return data.datasets[0].data[tooltipItem.index];
             }
           }
         },
         legend: {
           display: false,
           position: 'bottom',
           labels: {
             padding: 20
           }
         },
         scales: {
           xAxes: [{
            barThickness: 15,
             gridLines: {
               display: false
             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 4],
             },
             ticks: {
               Linedash: [10, 5],
               stepSize: 2,
               min: 0,
               max: 10,
               callback: (value: any) => {
                 return value;
               }
             }
           }]
         }
       }
     });

     
     this.canvas6 = document.getElementById('myChart6');
     this.ctx6 = this.canvas6.getContext('2d');
     this.canvas6.height = 400;
     this.canvas6.width = 480;
     const cilTask6 = new Chart(this.ctx6, {
       type: 'bar',
       data: {
         labels: ['CO1','CO2','CO3','CO4','CO5','CO6','CO7','CO8'],
         datasets: [ 
           {data: [9.1, 8, 7, 6, 5, 4, 3, 2], label: '  Lorem Ipsem',backgroundColor: "#3AAFE4" },
          
         ]
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any)  => {
               return data.datasets[0].data[tooltipItem.index];
             }
           }
         },
         legend: {
           display: false,
           position: 'bottom',
           labels: {
             padding: 20
           }
         },
         scales: {
           xAxes: [{
            barThickness: 20,
             gridLines: {
               display: false
             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 4],
             },
             ticks: {
               Linedash: [10, 5],
               stepSize: 1,
               min: 0,
               max: 10,
               callback: (value: any) => {
                 return value;
               }
             }
           }]
         }
       }
     });

     
     this.canvas7 = document.getElementById('myChart7');
     this.ctx7 = this.canvas7.getContext('2d');
     this.canvas7.height = 400;
     this.canvas7.width = 480;
     const cilTask7 = new Chart(this.ctx7, {
       type: 'bar',
       data: {
         labels: ['SS1','SS2','SS3','SS4','SS5','SS6','SS7','SS8','SS9','SS10','SS11','SS12'],
         datasets: [ 
           { data: [9.1, 8, 7.5, 7, 6, 5.5, 5, 4, 3.5, 3, 2.5, 2], label: '  Lorem Ipsem',backgroundColor: "#3AAFE4" },
          
         ]
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any)  => {
               return data.datasets[0].data[tooltipItem.index];
             }
           }
         },
         legend: {
           display: false,
           position: 'bottom',
           labels: {
             padding: 20
           }
         },
         scales: {
           xAxes: [{
            barThickness: 20,
             gridLines: {
               display: false
             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 4],
             },
             ticks: {
               Linedash: [10, 5],
               stepSize: 1,
               min: 0,
               max: 10,
               callback: (value: any) => {
                 return value;
               }
             }
           }]
         }
       }
     });

     this.canvas8 = document.getElementById('myChart8');
     this.ctx8 = this.canvas8.getContext('2d');
     this.canvas8.height = 400;
     this.canvas8.width = 480;
     const cilTask8 = new Chart(this.ctx8, {
       type: 'bar',
       data: {
         labels: ['PMDT1','PMDT2','PMDT3','PMDT4','PMDT5','PMDT6','PMDT7','PMDT8'],
         datasets: [ 
           {data: [9.1, 8, 7, 6, 5, 4, 3, 2], label: '  Lorem Ipsem',backgroundColor: "#3AAFE4" },
          
         ]
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any)  => {
               return data.datasets[0].data[tooltipItem.index];
             }
           }
         },
         legend: {
           display: false,
           position: 'bottom',
           labels: {
             padding: 20
           }
         },
         scales: {
           xAxes: [{
            barThickness: 20,
             gridLines: {
               display: false
             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 4],
             },
             ticks: {
               Linedash: [10, 5],
               stepSize: 1,
               min: 0,
               max: 10,
               callback: (value: any) => {
                 return value;
               }
             }
           }]
         }
       }
     });

     this.canvas9 = document.getElementById('myChart9');
     this.ctx9 = this.canvas9.getContext('2d');
     this.canvas9.height = 400;
     this.canvas9.width = 480;
     const cilTask9 = new Chart(this.ctx9, {
       type: 'bar',
       data: {
         labels: ['EDT1','EDT2','EDT3','EDT4','EDT5','EDT6','EDT7','EDT8','EDT9','EDT10'],
         datasets: [ 
           {data: [4.1, 3.8, 3.5, 3.2, 2.9, 2.6, 2.3, 1.9, 1.6, 1.2], label: '  Lorem Ipsem',backgroundColor: "#3AAFE4" },
          
         ]
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any)  => {
               return data.datasets[0].data[tooltipItem.index];
             }
           }
         },
         legend: {
           display: false,
           position: 'bottom',
           labels: {
             padding: 20
           }
         },
         scales: {
           xAxes: [{
            barThickness: 20,
             gridLines: {
               display: false
             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 4],
             },
             ticks: {
               Linedash: [10, 5],
               stepSize: 1,
               min: 0,
               max: 5,
               callback: (value: any) => {
                 return value;
               }
             }
           }]
         }
       }
     });

     this.canvas10 = document.getElementById('myChart10');
     this.ctx10 = this.canvas10.getContext('2d');
     this.canvas10.height = 400;
     this.canvas10.width = 480;
     const cilTask10 = new Chart(this.ctx10, {
       type: 'bar',
       data: {
         labels: ['AMDT1','AMDT2','AMDT3','AMDT4','AMDT5','AMDT6','AMDT7','AMDT8'],
         datasets: [ 
           {data: [3.3, 3, 2.7, 2.5, 2.2, 2, 1.8, 1.5], label: '  Lorem Ipsem',backgroundColor: "#3AAFE4" },
          
         ]
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any)  => {
               return data.datasets[0].data[tooltipItem.index];
             }
           }
         },
         legend: {
           display: false,
           position: 'bottom',
           labels: {
             padding: 20
           }
         },
         scales: {
           xAxes: [{
            barThickness: 20,
             gridLines: {
               display: false
             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 4],
             },
             ticks: {
               Linedash: [10, 5],
               stepSize: .5,
               min: 0,
               max: 3.5,
               callback: (value: any) => {
                 return value;
               }
             }
           }]
         }
       }
     });

     this.canvas11 = document.getElementById('myChart11');
     this.ctx11 = this.canvas11.getContext('2d');
     this.canvas11.height = 400;
     this.canvas11.width = 480;
     const cilTask11 = new Chart(this.ctx11, {
       type: 'bar',
       data: {
         labels: ['UDT1','UDT2','UDT3','UDT4','UDT5'],
         datasets: [ 
           {data: [1.3, 0.9, 0.6, 0.4, 0.4], label: '  Lorem Ipsem',backgroundColor: "#3AAFE4" },
          
         ]
       },
       options: {
         responsive: false,
         maintainAspectRatio: false,
         display: true,
         labelString: 'Percentage',
         tooltips: {
           callbacks: {
             label: (tooltipItem: any, data: any)  => {
               return data.datasets[0].data[tooltipItem.index];
             }
           }
         },
         legend: {
           display: false,
           position: 'bottom',
           labels: {
             padding: 20
           }
         },
         scales: {
           xAxes: [{
            barThickness: 44,
             gridLines: {
               display: false
             }
           }],
           yAxes: [{
             gridLines: {
               borderDash: [8, 4],
             },
             ticks: {
               Linedash: [10, 5],
               stepSize: .2,
               min: 0,
               max: 1.4,
               callback: (value: any) => {
                 return value;
               }
             }
           }]
         }
       }
     });


     this.GetBreadcrumList();
     this.GetMenuAccess();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;

    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any{
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([url]));
    // return false;
  }

}
