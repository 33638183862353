import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

const moment = _moment;

@Component({
  selector: 'app-quality-analysis',
  templateUrl: './quality-analysis.component.html',
  styleUrls: ['./quality-analysis.component.scss']
})
export class QualityAnalysisComponent implements OnInit {

  dropDownForm!: FormGroup;
  //Drop-down Variables
  yearList = ['2015', '2016', '2017', '2018', '2019', '2020', '2021'];
  monthList = ['ALL', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  weekList = ['ALL', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  dayList = ['ALL', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  shiftList = ['ALL', 'Morning', 'Evening', 'Night'];
  machineList = ['ALL', 'RTM1', 'RTM2'];

  canvas: any;
  ctx: any;
  canvas1: any;
  ctx1: any;
  canvas2: any;
  ctx2: any;
  canvas3: any;
  ctx3: any;
  canvas4: any;
  ctx4: any;
  canvas5: any;
  ctx5: any;
  canvas6: any;
  ctx6: any;
  canvas7: any;
  ctx7: any;
  canvas8: any;
  ctx8: any;
  canvas9: any;
  ctx9: any;
  canvas10: any;
  ctx10: any;
  canvas11: any;
  ctx11: any;

  // Date Time Range
  range = new FormGroup({
    startDate: new FormControl(moment()),
    endDate: new FormControl(moment())
  });

  // Charts

  // OEE Loses
  oeeLoses = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    barChartData: [
      { data: [1.3, [1.2, 1.3], 0, 0, 0, 0, 0, 0], label: '  Actual' },
      { data: [0, 0, 1.2, [1, 1.2], [0.8, 1], [0.65, 0.8], [0.6, 0.65], 0], label: '  Actual' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0.6], label: '  Target' },
    ],
    barChartColors: [
      { backgroundColor: "#040496" },
      { backgroundColor: "#F85439" },
      { backgroundColor: "#10CE9C" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 0.4,
            },
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 30,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Qualtiy Loses
  qualtiyLoses = {
    barChartLabels: ['Quality', 'Changeover Waste', 'In-Process Waste', 'Target'],
    barChartData: [
      { data: [62, [62, 80], [80, 100.1], 0], label: '  Actual' },
      { data: [0, 0, 0, 0], label: '  Actual' },
      { data: [0, 0, 0, 100.1], label: '  Target Loss' },
    ],
    barChartColors: [
      { backgroundColor: "#F85439" },
      { backgroundColor: "#10CE9C" },
      { backgroundColor: "#040496" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 20,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            },
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 60,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Changeover Waste Reasons
  changeoverWasteReasons = {
    barChartLabels: ['Alignment waste', 'Ink smudgen waste', 'Gluing waste', 'Color Shade waste'],
    barChartData: [
      { data: [10.1, 6.2, 5, 1], label: '  Lorem Ipsem' },
      { data: [0, 0, 0, 0], label: '  Lorem Ipsem' }
    ],
    barChartColors: [
      { backgroundColor: "#F85439" },
      { backgroundColor: "#10CE9C" },
    ],
    barChartOptions: {
      labelString: "  Percentage",
      tooltips: {
        callbacks: {
          label: function (tooltipItem:any, data:any) {
            return data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 2,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // In-Process Waste Reasons
  inProcessWasteReasons = {
    barChartLabels: ['Lorem', 'Lorem 1', 'Lorem 2', 'Lorem 4'],
    barChartData: [
      { data: [12.2, 9.5, 6.2, 4], label: '  Lorem Ipsem' },
      { data: [0, 0, 0, 0], label: '  Lorem Ipsem' }
    ],
    barChartColors: [
      { backgroundColor: "#F85439" },
      { backgroundColor: "#10CE9C" },
    ],
    barChartOptions: {
      labelString: "  Percentage",
      tooltips: {
        callbacks: {
          label: function (tooltipItem:any, data:any) {
            return data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 2,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Net Output vs Gross Output
  netOutputGrossOutput = {
    barChartLabels: ['Net Output', 'Gross Output'],
    barChartData: [
      { data: [305.4, 0], label: '  Net Output' },
      { data: [0, 308.1], label: '  Gross Output' }
    ],
    barChartColors: [
      { backgroundColor: "#10CE9C" },
      { backgroundColor: "#040496" },
    ],
    barChartOptions: {
      labelString: "  Percentage",
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              min: 302,
            }
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Output Waste Reasons - Net Output vs Gross Output
  outputWasteReasons = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    barChartData: [
      { data: [105, 0, 0, 0, 0, 0, 0, 0], label: '  Lorem Ipsem' },
      { data: [0, [105, 115], [115, 125], [125, 135], [135, 142], [142, 147], [147, 150.1], 0], label: '  Lorem Ipsem' },
      { data: [0, 0, 0, 0, 0, 0, 0, 150.1], label: '  Target' },
    ],
    barChartColors: [
      { backgroundColor: "#10CE9C" },
      { backgroundColor: "#F85439" },
      { backgroundColor: "#040496" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 50,
            },
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  // Waste %
  wastePercent = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12'],
    barChartData: [
      { data: [2, 1.4, 2.3, 2.2, 1.4, 1.9, 2.2, 1.8, 2.2, 2.4, 2.2, 2.2], label: '  Waste' },
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      labelString: "  Percentage",
      tooltips: {
        callbacks: {
          label: function (tooltipItem:any, data:any) {
            return data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 0.5,
              beginAtZero: true,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }

  //Waste in UoM
  wasteUoM = {
    barChartLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12'],
    barChartData: [
      { data: [1.8, 2.4, 3.4, 2.8, 1.4, 2.5, 1.8, 2.4, 2.8, 2.4, 1.7, 1.7], label: '  Waste' },
    ],
    barChartColors: [
      { backgroundColor: "#3AAFE4" },
    ],
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  }
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;

  constructor(
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    Chart.defaults.global.legend.labels.usePointStyle = true;
    this.dropDownForm = this.formBuilder.group({
      groupList: [''],
      UnitDataList: [''],
    });


    this.canvas = document.getElementById('myChart');
    this.canvas.height = 300;
    this.canvas.width = 480;
    this.ctx = this.canvas.getContext('2d');
    // this.ctx.height = 400;
    const defectClosing = new Chart(this.ctx, {
      type: 'bar',
      data:{
        labels:['Total capacity', 'Shutdown', 'Planned capacity', 'Unplanned downtime', 'Planned downtime', 'Speed loss', 'Waste loss', 'OEE achieved'],
        datasets: [
        { data: [1.3, [1.2, 1.3], 0, 0, 0, 0, 0, 0], label: '  Total', backgroundColor: "#040496" },
        { data: [0, 0, 1.2, [1, 1.2], [0.8, 1], [0.65, 0.8], [0.6, 0.65], 0], label: '  Actual' ,backgroundColor: "#F85439"},
        { data: [0, 0, 0, 0, 0, 0, 0, 0.6], label: '  Target' , backgroundColor: "#10CE9C"},
      ],
    },
     
   
    options: {
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 0.4,
            },
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 30,
          gridLines: {
            display: false,
          }
        }]
      }
    }
    });


    this.canvas1 = document.getElementById('myChart1');
    this.canvas1.height = 300;
    this.canvas1.width = 480;
    this.ctx1 = this.canvas1.getContext('2d');
    // this.ctx.height = 400;
    const def = new Chart(this.ctx1, {
      type: 'bar',
      data:{
        labels:['Quality', 'Changeover Waste', 'In-Process Waste', 'Target'],
        datasets: [
          { data: [62, [62, 80], [80, 100.1], 0], label: ' Actual',backgroundColor: "#F85439"  },
          { data: [0, 0, 0, 0], label: '  Actual', backgroundColor: "#10CE9C" },
          { data: [0, 0, 0, 100.1], label: '  Target Loss', backgroundColor: "#040496" },
      ],
    },
     
   
    options: {
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 20,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            },
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 60,
          gridLines: {
            display: false,
          }
        }]
      }
    }
    });

    this.canvas2 = document.getElementById('myChart2');
    this.canvas2.height = 300;
    this.canvas2.width = 480;
    this.ctx2 = this.canvas2.getContext('2d');
    // this.ctx.height = 400;
    const def1 = new Chart(this.ctx2, {
      type: 'bar',
      data:{
        labels:['Alignment waste', 'Ink smudgen waste', 'Gluing waste', 'Color Shade waste'],
        datasets: [
          { data: [10.1, 6.2, 5, 1], label: '  Lorem Ipsem',backgroundColor: "#F85439" },
      { data: [0, 0, 0, 0], label: '  Lorem Ipsem', backgroundColor: "#10CE9C" }
      ],
    },
     
   
    options: {
      labelString: "  Percentage",
      tooltips: {
        callbacks: {
          label: function (tooltipItem:any, data:any) {
            return data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display:false,
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 2,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
    });

    
    this.canvas3 = document.getElementById('myChart3');
    this.canvas3.height = 300;
    this.canvas3.width = 480;
    this.ctx3 = this.canvas3.getContext('2d');
    // this.ctx.height = 400;
    const def2 = new Chart(this.ctx3, {
      type: 'bar',
      data:{
        labels:['Make ready waste', 'Maintenance related waste', 'Material related waste', 'Operational waste'],
        datasets: [
          { data: [12.2, 9.5, 6.2, 4], label: '  Lorem Ipsem', backgroundColor: "#F85439"  },
          { data: [0, 0, 0, 0], label: '  Lorem Ipsem', backgroundColor: "#10CE9C" }
      ],
    },
     
   
    options: {
      labelString: "  Percentage",
      tooltips: {
        callbacks: {
          label: function (tooltipItem:any, data:any) {
            return data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      },
      responsive: false,
      maintainAspectRatio: false,
      
      legend: {
        display:false,
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 2,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
    });

    this.canvas4 = document.getElementById('myChart4');
    this.canvas4.height = 300;
    this.canvas4.width = 480;
    this.ctx4 = this.canvas4.getContext('2d');
    // this.ctx.height = 400;
    const def3 = new Chart(this.ctx4, {
      type: 'bar',
      data:{
        labels: ['Net Output', 'Gross Output'],
        datasets: [
          { data: [305.4, 0], label: '  Net Output', backgroundColor: "#10CE9C"},
      { data: [0, 308.1], label: '  Gross Output', backgroundColor: "#040496" }
      ],
    },
     
   
    options: {
      labelString: "  Percentage",
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              min: 302,
            }
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
    });

    this.canvas5 = document.getElementById('myChart5');
    this.canvas5.height = 300;
    this.canvas5.width = 480;
    this.ctx5 = this.canvas5.getContext('2d');
    // this.ctx.height = 400;
    const def4 = new Chart(this.ctx5, {
      type: 'bar',
      data:{
        labels: ['Net Output','Maintenance waste', 'Material waste','Alignment waste', 'Ink smudgen waste', 'Gluing waste', 'Color Shade waste','Target'],
        datasets: [
          { data: [105, 0, 0, 0, 0, 0, 0, 0], label: '  Actual' ,  backgroundColor: "#10CE9C" },
         
        
      { data: [0, [105, 115], [115, 125], [125, 135], [135, 142], [142, 147], [147, 150.1], 0], label: '  Loss', backgroundColor: "#F85439" },
      { data: [0, 0, 0, 0, 0, 0, 0, 150.1], label: '  Target', backgroundColor: "#040496" },
      ],
    },
     
   
     
    options: {
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 50,
            },
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 50,
          gridLines: {
            display: false,
          }
        }]
      }
    }
    });
    
    this.canvas6= document.getElementById('myChart6');
    this.canvas6.height = 300;
    this.canvas6.width = 480;
    this.ctx6 = this.canvas6.getContext('2d');
    // this.ctx.height = 400;
    const def5 = new Chart(this.ctx6, {
      type: 'bar',
      data:{
        labels: ['Make ready waste','Maintenance waste', 'Material waste','Alignment waste', 'Ink smudgen waste', 'Gluing waste', 'Color Shade waste'],
        datasets: [{ data: [1.8, 2.4, 3.4, 2.8, 1.4, 2.5, 1.8, 2.4, 2.8, 2.4, 1.7], label: '  Waste', backgroundColor: "#3AAFE4"}
         
      ],
    },
    options: {
      labelString: "  Percentage",
      tooltips: {
        callbacks: {
          label: function (tooltipItem:any, data:any) {
            return data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              beginAtZero: true,
              stepSize: 0.5,
              min:0,
              max:2.5,
              callback: function (value:any, index:any) {
                if (value === 0) return 0 + '%';
                else return value + '%';
              }
            }
          }
        ],
        xAxes: [{
          stacked: true,
          barThickness: 18,
          gridLines: {
            display: false,
          }
        }]
      }
    }
    });

    this.canvas7= document.getElementById('myChart7');
    this.canvas7.height = 300;
    this.canvas7.width = 480;
    this.ctx7 = this.canvas7.getContext('2d');
    // this.ctx.height = 400;
    const def6 = new Chart(this.ctx7, {
      type: 'bar',
      data:{
        labels: ['Make ready waste','Maintenance waste', 'Material waste','Alignment waste', 'Ink smudgen waste', 'Gluing waste', 'Color Shade waste'],
        datasets: [
          { data: [1.8, 2.4, 3.4, 2.8, 1.4, 2.5, 1.8, 2.4, 2.8, 2.4, 1.7, 1.7], label: '  Waste', backgroundColor: "#3AAFE4"}
      ],
    },
    options: {
      responsive: false,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true
            }
          }
        ],
        xAxes: [{
          barThickness: 20,
          gridLines: {
            display: false,
          }
        }]
      }
    }
  
    });


    this.GetBreadcrumList();
    this.GetMenuAccess();



  }

    //breadCrumb

    GetMenuAccess(): void {
      const key = {
        Id: localStorage.getItem('O3RightMenuId')
      };
      this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
  
      },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    }
    GetBreadcrumList(): any{
      const obj = {
        MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
      };
      // tslint:disable-next-line: align
      this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
        this.breadcrumList = data.BreadcrumList;
        console.log(data.BreadcrumList);
      }, (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      });
    }
    DisplayRightmenu(menuId: any, url: any): any {
      localStorage.setItem('O3RightMenuId', menuId);
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([url]));
      // return false;
    }
  

}
