import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { QcBoxService } from 'src/app/services/qcBox.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import * as JsBarcode from 'jsbarcode';
import { DomSanitizer } from "@angular/platform-browser";
import { MatSort, Sort } from '@angular/material/sort';
import { PalletActionRenderer } from 'src/app/pallet-action-renderer';
import * as moment from 'moment';

@Component({
  selector: 'app-pallet',
  templateUrl: './pallet.component.html',
  styleUrls: ['./pallet.component.scss']
})
export class PalletComponent implements OnInit {

  @ViewChild('displayQualityHistoryModal') displayQualityHistoryModal!: TemplateRef<any>;
  @ViewChild('previewPalletDialog') private previewPalletDialog!: TemplateRef<any>;
  @ViewChild('batchInfoPalletDialog') private batchInfoPalletDialog!: TemplateRef<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('sorter1') sorter1: any;
  @ViewChild('sorter2') sorter2: any;
  @ViewChild('sorter3') sorter3: any;
  @ViewChild('sorter4') sorter4: any;
  @ViewChild('sorter5') sorter5: any;
  @ViewChild('sorter6') sorter6: any;


  qcBoxDataSource!: MatTableDataSource<any>;
  qcBoxColumns: any[] = ['BatchInfoId', 'ProcessOrder', 'PPD_Num', 'BatchNo', 'ItemCode', 'ItemDesc', 'Qty', 'ResultOn', 'Remarks', 'createdOn', 'createdBy'];

  batchInfoDataSource!: MatTableDataSource<any>;
  batchInfoColumns: any[] = ['action', 'BatchInfoId', 'ProcessOrder', 'PPD_Num', 'BatchNo', 'ItemCode', 'ItemDesc', 'Qty', 'ResultOn', 'Remarks', 'createdOn', 'createdBy'];

  // qualityHistoryDataSource!: MatTableDataSource<any>;
  // qualityHistoryColumns: any[] = ['PalletId', 'PalletLotCode', 'NoOfBox', 'NoOfItem', 'CreatedOn', 'CreatedBy', 'Action'];

  palletInfoDataSource!: MatTableDataSource<any>;
  palletInfoColumns: any[] = [ 'Action' , 'PalletId', 'PalletLotCode', 'NoOfBox', 'NoOfItem', 'CreatedOn', 'CreatedBy'];

  // previewPalletDataSource!: MatTableDataSource<any>;
  // previewPalletColumns: any[] = ['ItemCode', 'ItemDesc', 'NoOfBox', 'TotalQty'];

  // batchInfoPalletDataSource!: MatTableDataSource<any>;
  // batchInfoPalletColumns: any[] = ['BatchInfoId', 'ProcessOrder', 'PPD_Num', 'BatchNo', 'ItemCode', 'ItemDesc', 'Qty', 'ResultOn', 'Remarks', 'createdOn', 'createdBy'];

  warehouseForm!: FormGroup;
  groupList!: any[];
  filteredGroupList!: any[];
  plantList!: any[];
  filteredPlantList!: any[];
  itemInfoList!: any[];
  filteredItemInfoList!: any[];
  processOrderList!: any[];
  filteredProcessOrderList!: any[];
  PalletId!: any;
  selectedRowIndex!: any;
  highLightedArray: any[] = [];
  breadcrumList: any;
  remarksShowHideProperty = false;
  isPalletHistoryDialog: boolean = false;
  isPrint: boolean = false;
  testhtml: any;

  LogoPath: any;
  ItemCode: any;
  ItemDesc: any;
  ItemCode1: any;
  ItemDesc1: any;
  Qty: any;
  ApproveImage: any;
  ProdDate: any;
  BarCodeSeries: any;

  paginationPageSize!: any;

  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      palletActionRenderer: PalletActionRenderer,
    },
  };

  gridApi!: any;
  gridColumnApi!: any;

  PreviewPalletColumns = [
    { field: 'ItemCode', headerName: 'ItemCode', minWidth: 375, },
    { field: 'ItemDesc', headerName: 'ItemDesc', minWidth: 375, },
    { field: 'NoOfBox', headerName: 'NoOfBox', minWidth: 300, },
    { field: 'TotalQty', headerName: 'TotalQty', minWidth: 300, },
  ];

  PalletHistoryColumns = [
    {
      field: 'Action', headerName: 'Action',
      cellRenderer: 'palletActionRenderer',
      cellRendererParams: {
        onClickPrint: this.getPalletDataWithDialog.bind(this, 'print'),
        onClickSearch: this.getPalletDataWithDialog.bind(this, 'search'),
        onClickDelete: this.getPalletDataWithDialog.bind(this, 'delete'),
      },
    },
    { field: 'PalletId', headerName: 'PalletId' },
    { field: 'PalletLotCode', headerName: 'PalletLotCode' },
    { field: 'NoOfBox', headerName: 'NoOfBox' },
    { field: 'NoOfItem', headerName: 'NoOfItem' },
    {
      field: 'CreatedOn', headerName: 'CreatedOn',
      valueFormatter: function (params: any) { return moment(params.value).format('DD/MM/YYYY HH:mm:ss') }
    },
    { field: 'CreatedBy', headerName: 'CreatedBy' },
   
  ];

  BatchInfoColumns = [

    { field: 'BatchInfoId', headerName: 'BatchInfoId' },
    { field: 'ProcessOrder', headerName: 'ProcessOrder' },
    { field: 'PPD_Num', headerName: 'PPD_Num' },
    { field: 'BatchNo', headerName: 'BatchNo' },
    { field: 'ItemCode', headerName: 'ItemCode' },
    { field: 'ItemDesc', headerName: 'ItemDesc' },
    { field: 'Qty', headerName: 'Qty' },
    {
      field: 'ResultOn', headerName: 'ResultOn',
      valueFormatter: function (params: any) { return moment(params.value).format('DD/MM/YYYY HH:mm:ss') }
    },
    { field: 'Remarks', headerName: 'Remarks' },
    {
      field: 'createdOn', headerName: 'createdOn',
      valueFormatter: function (params: any) { return moment(params.value).format('DD/MM/YYYY HH:mm:ss') }
    },
    { field: 'createdBy', headerName: 'createdBy' },
  ];

  PreviewPalletList: any = []
  PalletHistoryList: any = [];
  BatchInfoList: any = [];

  constructor(private dialog: MatDialog,
    private fb: FormBuilder,
    private qcBoxService: QcBoxService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.warehouseForm = this.fb.group({
      groupList: [''],
      plantList: [''],
      item: ['ALL'],
      itemDesc: ['ALL'],
      procOrder: ['ALL'],
      scantBatchNo: ['']
    });
    this.GetBreadcrumList();
    this.GetGroups();
  }

  GetGroups(): void {
    this.highLightedArray = [];
    const key = {
      CreatedBy: 0
    };
    this.spinner.show();
    this.qcBoxService.GetGroups(key).subscribe((res: any) => {
      this.spinner.hide();
      this.groupList = res.masterList;
      this.filteredGroupList = this.groupList.slice();
      if (this.groupList && this.groupList.length)
        this.warehouseForm.get('groupList')?.setValue(res.masterList[0].TypeId);
      this.GetPlants();
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went Wrong');
    });
  }

  GetPlants(): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    this.highLightedArray = [];
    const key = {
      GroupId: this.warehouseForm.value.groupList,
      UserId: 0
    };
    this.spinner.show();
    this.qcBoxService.GetPlants(key).subscribe((res: any) => {
      this.spinner.hide();
      this.plantList = res.DepartList;
      this.filteredPlantList = this.plantList.slice();
      if (this.plantList && this.plantList.length)
        this.warehouseForm.get('plantList')?.setValue(res.DepartList[0].DeptId);
      this.GetItemInfo();
      this.GetPalletInfoList();
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went Wrong');
    });
  }

  GetItemInfo(): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    const key = {
      EGId: this.warehouseForm.value.groupList,
      PTId: this.warehouseForm.value.plantList
    };
    this.spinner.show();
    this.qcBoxService.GetItemInfo(key).subscribe((res: any) => {
      this.spinner.hide();
      this.itemInfoList = res.ItemInfoList;
      this.filteredItemInfoList = this.itemInfoList.slice();
      // this.warehouseForm.get('item')?.setValue(res.ItemInfoList[0].ItemCode);
      // this.warehouseForm.get('itemDesc')?.setValue(res.ItemInfoList[0].ItemDesc);
      // this.warehouseForm.get('item')?.setValue(this.itemInfoList[this.itemInfoList.length - 1].ItemCode);
      // this.warehouseForm.get('itemDesc')?.setValue(this.itemInfoList[this.itemInfoList.length - 1].ItemDesc);
      const index = this.itemInfoList.findIndex((el: any) => el.ItemDesc == 'ALL');
      if (index != -1) {
        this.warehouseForm.get('item')?.setValue(this.itemInfoList[index].ItemCode);
        this.warehouseForm.get('itemDesc')?.setValue(this.itemInfoList[index].ItemDesc);
      }
      this.GetProcessOrder();
      this.GetPalletBatchInfoList();
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went Wrong');
    });
  }

  ItemChange(event: any, type: any) {
    let index:any;
    if (type == 'itemCode')
      index = this.itemInfoList.findIndex((el: any) => el.ItemCode == event.value);
    else
      index = this.itemInfoList.findIndex((el: any) => el.ItemDesc == event.value);
    this.warehouseForm.get('item')?.setValue(this.itemInfoList[index].ItemCode);
    this.warehouseForm.get('itemDesc')?.setValue(this.itemInfoList[index].ItemDesc);
    this.GetProcessOrder();
    this.GetPalletBatchInfoList();
  }

  GetItemInfoDesc(event: any): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    const key = {
      EGId: this.warehouseForm.value.groupList,
      PTId: this.warehouseForm.value.plantList
    };
    this.spinner.show();
    this.qcBoxService.GetItemInfo(key).subscribe((res: any) => {
      this.spinner.hide();
      this.itemInfoList = res.ItemInfoList;
      this.filteredItemInfoList = this.itemInfoList.slice();
      // this.warehouseForm.get('item')?.setValue(res.ItemInfoList[0].ItemCode);
      // this.warehouseForm.get('itemDesc')?.setValue(res.ItemInfoList[0].ItemDesc);
      // this.warehouseForm.get('item')?.setValue(this.itemInfoList[this.itemInfoList.length - 1].ItemCode);
      // this.warehouseForm.get('itemDesc')?.setValue(this.itemInfoList[this.itemInfoList.length - 1].ItemDesc);
      const index = this.itemInfoList.findIndex((el: any) => el.ItemDesc == 'ALL');
      if (index != -1) {
        this.warehouseForm.get('item')?.setValue(this.itemInfoList[index].ItemCode);
        this.warehouseForm.get('itemDesc')?.setValue(this.itemInfoList[index].ItemDesc);
      }
      this.GetProcessOrder();
      this.GetPalletBatchInfoList();
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went Wrong');
    });
  }

  GetDescInfoItem(event: any): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    const key = {
      EGId: this.warehouseForm.value.groupList,
      PTId: this.warehouseForm.value.plantList
    };
    this.spinner.show();
    this.qcBoxService.GetItemInfo(key).subscribe((res: any) => {
      this.spinner.hide();
      this.itemInfoList = res.ItemInfoList;
      this.filteredItemInfoList = this.itemInfoList.slice();
      // this.warehouseForm.get('item')?.setValue(res.ItemInfoList[0].ItemCode);
      // this.warehouseForm.get('itemDesc')?.setValue(res.ItemInfoList[0].ItemDesc);
      // this.warehouseForm.get('item')?.setValue(this.itemInfoList[this.itemInfoList.length - 1].ItemCode);
      // this.warehouseForm.get('itemDesc')?.setValue(this.itemInfoList[this.itemInfoList.length - 1].ItemDesc);
      const index = this.itemInfoList.findIndex((el: any) => el.ItemDesc == 'ALL');
      if (index != -1) {
        this.warehouseForm.get('item')?.setValue(this.itemInfoList[index].ItemCode);
        this.warehouseForm.get('itemDesc')?.setValue(this.itemInfoList[index].ItemDesc);
      }
      this.GetProcessOrder();
      this.GetPalletBatchInfoList();
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went Wrong');
    });
  }

  GetProcessOrder(): void {
    const key = {
      ItemCode: this.warehouseForm.value.item
    };
    this.spinner.show();
    this.qcBoxService.GetProcessOrder(key).subscribe((res: any) => {
      this.spinner.hide();
      (res.masterList).unshift({"TypeId": 0, "TypeName": "ALL"});
      this.processOrderList = res.masterList;
      this.filteredProcessOrderList = this.processOrderList.slice();
      this.warehouseForm.get('procOrder')?.setValue(0);
      // if (res.masterList.length === 0) {
      //   this.warehouseForm.get('procOrder')?.setValue('ALL');
      // }
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went Wrong');
    });
  }

  GetPalletBatchInfoList(): void {
    const key = {
      GroupId: this.warehouseForm.value.groupList,
      PlantId: this.warehouseForm.value.plantList,
      ItemCode: this.warehouseForm.value.item,
      PPId: 0
    };
    this.spinner.show();
    this.qcBoxService.GetPalletBatchInfoList(key).subscribe((res: any) => {
      this.spinner.hide();
      this.qcBoxDataSource = new MatTableDataSource<any>(res.BatchInfoList);
      this.getPaginator(this.qcBoxDataSource, 0);
      this.qcBoxDataSource.sort = this.sorter1;
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went Wrong');
    });
  }

  selectedBatchInfoList(): void {
    const key = {
      GroupId: this.warehouseForm.value.groupList,
      PlantId: this.warehouseForm.value.plantList,
      ItemCode: this.warehouseForm.value.item,
      PPId: 0
    };
    this.spinner.show();
    this.qcBoxService.GetPalletBatchInfoList(key).subscribe((res: any) => {
      this.spinner.hide();
      this.batchInfoDataSource = new MatTableDataSource<any>(res.BatchInfoList);
      this.getPaginator(this.batchInfoDataSource, 1);
      this.batchInfoDataSource.sort = this.sorter2;
      this.highLightedArray = this.batchInfoDataSource.data;
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went Wrong');
    });
  }

  GetPalletInfoList(): void {
    const key = {
      PTID: this.warehouseForm.value.plantList,
      IsHistory: this.isPalletHistoryDialog ? 1 : 0,
    };
    this.spinner.show();
    this.qcBoxService.GetPalletInfoList(key).subscribe((res: any) => {
      this.spinner.hide();
      if (this.isPalletHistoryDialog) {
        this.PalletHistoryList = res.PalletInfoList
        // this.qualityHistoryDataSource = new MatTableDataSource<any>(res.PalletInfoList);
        // this.getPaginator(this.qualityHistoryDataSource, 3);
        // this.qualityHistoryDataSource.sort = this.sorter3;
      }
      else {
        this.palletInfoDataSource = new MatTableDataSource<any>(res.PalletInfoList);
        this.getPaginator(this.palletInfoDataSource, 2);
      }
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went Wrong');
    });
  }

  MoveBatchInfoDataSource(): void {
    this.spinner.show();
    this.batchInfoDataSource = new MatTableDataSource<any>(this.qcBoxDataSource.data);
    this.spinner.hide();
    this.getPaginator(this.batchInfoDataSource, 1);
    this.batchInfoDataSource.sort = this.sorter2;
  }

  clearBatchInfoDataData(): void {
    // this.warehouseForm = this.fb.group({
    //   groupList: [''],
    //   plantList: [''],
    //   item: ['ALL'],
    //   itemDesc: ['ALL'],
    //   procOrder: ['ALL'],
    //   scantBatchNo: ['']
    // });
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
  }

  openPreviewPalletDialog(): void {
    this.dialog.open(this.previewPalletDialog, { minWidth: '90%', height: '90vh' });
    this.PreviewPalletList = [];
    let PreviewPalletTableData: any;
    if (this.batchInfoDataSource.data && this.batchInfoDataSource.data.length) {
      for (const qty of this.batchInfoDataSource.data) {
        if (this.batchInfoDataSource.data && this.batchInfoDataSource.data.length > 1) {
          const objIndex = this.PreviewPalletList.findIndex((el: any) => el.PPD_Num == qty.PPD_Num);
          if (objIndex != -1) {
            this.PreviewPalletList[objIndex].TotalQty += qty.Qty;
            this.PreviewPalletList[objIndex].NoOfBox++;
          }
          else {
            this.PreviewPalletList.push({
              ItemCode: qty.ItemCode,
              ItemDesc: qty.ItemDesc,
              NoOfBox: 1,
              TotalQty: qty.Qty,
              PPD_Num: qty.PPD_Num
            })
          }
        }
        else {
          PreviewPalletTableData = {
            ItemCode: qty.ItemCode,
            ItemDesc: qty.ItemDesc,
            NoOfBox: this.batchInfoDataSource.data.length,
            TotalQty: qty.Qty,
            PPD_Num: qty.PPD_Num
          };
          this.PreviewPalletList.push(PreviewPalletTableData);
        }
      }
    }
    else {
      // PreviewPalletTableData = {
      //   ItemCode: '',
      //   ItemDesc: '',
      //   NoOfBox: '',
      //   TotalQty: '',
      //   PPD_Num: '',
      // };
      // this.PreviewPalletList.push(PreviewPalletTableData);
    }
    // this.previewPalletDataSource = new MatTableDataSource<any>(this.previewPalletDataSource.data);
    // this.getPaginator(this.previewPalletDataSource, 3);
    // setTimeout(() => {
    //   this.previewPalletDataSource.sort = this.sorter5;
    // }, 500);
  }

  updatehighlight(element: any): void {
    if (this.qcBoxDataSource.data.length !== this.batchInfoDataSource.data.length) {
      this.highLightedArray.push(element.BatchInfoId);
      console.log(this.highLightedArray);
      this.selectedRowIndex = element.BatchInfoId;
      if (this.batchInfoDataSource.data.length > 0) {
        const value = this.batchInfoDataSource.data.find((x: any) => x.BatchInfoId === element.BatchInfoId) ? true : false;
        if (!value) {
          this.batchInfoDataSource.data.push(element);
        }
        this.batchInfoDataSource = new MatTableDataSource<any>(this.batchInfoDataSource.data);
        this.getPaginator(this.batchInfoDataSource, 1);
        this.batchInfoDataSource.sort = this.sorter2;
      }
      else {
        this.batchInfoDataSource.data.push(element);
        this.batchInfoDataSource = new MatTableDataSource<any>(this.batchInfoDataSource.data);
        this.getPaginator(this.batchInfoDataSource, 1);
        this.batchInfoDataSource.sort = this.sorter2;
      }
    }
  }

  deleteBatchInfoDataData(element: any): void {
    const index = this.batchInfoDataSource.data.findIndex((x: any) => x.BatchInfoId === element.BatchInfoId);
    if (index > -1) {
      this.batchInfoDataSource.data.splice(index, 1);
      this.batchInfoDataSource = new MatTableDataSource<any>(this.batchInfoDataSource.data);
      this.getPaginator(this.batchInfoDataSource, 1);
      this.batchInfoDataSource.sort = this.sorter2;
    }
  }

  scanBatchNo() {
    if (this.qcBoxDataSource.data.length !== this.batchInfoDataSource.data.length) {
      const value = this.qcBoxDataSource.data.filter((x: any) => x.BatchNo == this.warehouseForm.value.scantBatchNo)
      if (value && value.length) {
        for (const row of value) {
          if (this.batchInfoDataSource.data.length > 0) {
            const obj = this.batchInfoDataSource.data.find((x: any) => x.BatchInfoId === row.BatchInfoId) ? true : false;
            if (!obj) {
              this.batchInfoDataSource.data.push(row);
            }
          }
          else {
            this.batchInfoDataSource.data.push(row);
          }
          this.getPaginator(this.batchInfoDataSource, 1);
          this.batchInfoDataSource.sort = this.sorter2;
        }
      }
      else {
        this.toaster.error('', 'Scanned Wrong Barcode!');
      }
    }
    else {
      this.toaster.error('', 'All BatchInfo Data Has Been Selected!');
    }
    this.warehouseForm.patchValue({ 'scantBatchNo': null });
  }

  warehouseHistoryModal(): void {
    this.isPalletHistoryDialog = true;
    let dialogRef = this.dialog.open(this.displayQualityHistoryModal, { disableClose: true, minWidth: '90%', height: '90vh' });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('The dialog was closed');
      this.isPalletHistoryDialog = false;
      this.GetPalletInfoList();
    });
    this.GetPalletInfoList();
  }

  subGeneratePallet() {
    const key: any = {
      PTID: this.warehouseForm.value.plantList,
      ItemCode: this.warehouseForm.value.item,
      TransactionTypeId: 1,
      PalletId: 0
    };
    if (this.batchInfoDataSource.data && this.batchInfoDataSource.data.length) {
      let arr: any = [];
      for (const palletID of this.batchInfoDataSource.data)
        arr.push({ PallateBoxId: palletID.BatchInfoId })
      key.PallateBoxList = arr;

      this.spinner.show();
      this.qcBoxService.subGeneratePallet(key).subscribe((res: any) => {
        this.spinner.hide();
        const dummyObj = { PalletId: 0 }
        this.GetPalletInfoList();
        this.GetPalletBatchInfoList();
        this.GetPalletInfo(dummyObj);
        this.batchInfoDataSource.data = [];
        // this.clearBatchInfoDataData();
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error('Error', 'Something Went Wrong');
      });
    }
    else {
      this.toaster.warning('Warning', 'Please Select The Batch Info');
    }


  }

  getPalletDataWithDialog(type: any, event: any) {
    if (type == 'print') {
      const key = {
        PlantId: this.warehouseForm.value.plantList,
        BCType: 2,
        ProdId: 'ALL',
        PalletId: event.PalletId
      };
      this.spinner.show();
      this.qcBoxService.getPalletBarcodeDetails(key).subscribe((res: any) => {
        if (res.GetBarCodeDetails && res.GetBarCodeDetails.length) {

          // this.isPrint = true;

          const logoPathObj = res.GetBarCodeDetails[0].detailItems.find((logpath: any) => logpath.Name == 'LogoPath');
          this.LogoPath = logoPathObj && logoPathObj.Value ? logoPathObj.Value : '';

          const itemCodeObj = res.GetBarCodeDetails[0].detailItems.find((itcode: any) => itcode.Name == 'ItemCode');
          this.ItemCode = itemCodeObj && itemCodeObj.Value ? itemCodeObj.Value : '-';

          const itemDescObj = res.GetBarCodeDetails[0].detailItems.find((itdesc: any) => itdesc.Name == 'ItemDesc');
          this.ItemDesc = itemDescObj && itemDescObj.Value ? itemDescObj.Value : '-';

          const itemCode1Obj = res.GetBarCodeDetails[0].detailItems.find((itcode1: any) => itcode1.Name == 'ItemCode1');
          this.ItemCode1 = itemCode1Obj && itemCode1Obj.Value ? itemCode1Obj.Value : '-';

          const itemDesc1Obj = res.GetBarCodeDetails[0].detailItems.find((itdesc1: any) => itdesc1.Name == 'ItemDesc1');
          this.ItemDesc1 = itemDesc1Obj && itemDesc1Obj.Value ? itemDesc1Obj.Value : '-';

          const qtyObj = res.GetBarCodeDetails[0].detailItems.find((qty: any) => qty.Name == 'Qty');
          this.Qty = qtyObj && qtyObj.Value ? qtyObj.Value : '-';

          const approveImageObj = res.GetBarCodeDetails[0].detailItems.find((appimg: any) => appimg.Name == 'ApproveImage');
          this.ApproveImage = approveImageObj && approveImageObj.Value ? approveImageObj.Value : '-';

          const prodDateObj = res.GetBarCodeDetails[0].detailItems.find((prodate: any) => prodate.Name == 'ProdDate');
          this.ProdDate = prodDateObj && prodDateObj.Value ? prodDateObj.Value : '-';

          this.BarCodeSeries = res.BarCodeSeries ? res.BarCodeSeries : '-';

          // JsBarcode("#barcode", this.BarCodeSeries);
          const WindowPrt: any = window.open("", "_blank");
          let printContent: any;
          //   printContent= document.getElementById("componentID");
          WindowPrt.document.write(`<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>`);
          setTimeout(() => {
            WindowPrt.document.write(`<script src="https://cdnjs.cloudflare.com/ajax/libs/jsbarcode/3.8.0/JsBarcode.all.js"></script>`)
          }, 1000);

          setTimeout(() => {
            var style = `<style>table, td, th {border: 1px solid black;font-family: system-ui;}table {width: 100%;border-collapse: collapse;}</style>`;
            WindowPrt.document.write(style);

            var body = `<table data-show-print="true" id="table1" style="width:100%" class="table">
            <thead><tr style="text-align: left;"><th colspan="16" style="padding: 10px;"><img src="http://localhost:4200/assets${this.LogoPath}" style="width:14%"></th></tr></thead>
            <tbody colspan="16"><tr>
                <td colspan="16" class="td-bold" style="text-align: center;font-size:130px;font-weight:bold;color:gray;">
                ${this.ItemCode}</td>
              </tr>
              <tr>
                <td colspan="16" style="text-align: center;font-size:32px;font-weight:bolder;color:gray;padding:10px" class="td-parent">
                ${this.ItemDesc}</td>
              </tr>
              <tr>
                <td colspan="16" class="td-bold" style="text-align: center;font-size:130px;font-weight:bold;color:gray;padding:10px">
                ${this.ItemCode1}</td>
              </tr>
              <tr>
                <td colspan="16" style="text-align: center;font-size:32px;font-weight:bolder;color:gray;padding:10px;" class="td-parent">
                ${this.ItemDesc1}</td>
              </tr>
              <tr style="text-align: center;font-size:20px;">
                <td class="td-bold" style="width:30%;font-weight:bold;">QTY-Pcs/Box</td>
                <td class="td-bold" style="width:30%;text-align: center;font-weight:bold;">${this.Qty} Pcs</td>
                <td style="text-align: top;" class="td-bold" colspan="2" rowspan="2">QC Stamp<br>
                  <span><img style="text-align: center;width:55%" src="http://localhost:4200/assets${this.ApproveImage}"></span>
                </td>
              </tr>
              <tr style="text-align: center;font-size:20px;">
                <td class="td-bold" style="width:30%;font-weight:bold;">Prod Date &amp; Shift</td>
                <td class="td-bold" style="width:30%;font-weight:bold;text-align: center;">${this.ProdDate}</td>
              </tr>
              <tr>
                <td class="td-bold" colspan="16" rowspan="2">
                  <div style="text-align:center;font-size:32px;">
                    <svg id="barcode"></svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>`;
            WindowPrt.document.write(body);

            var script = `<script>$("#barcode").JsBarcode("${this.BarCodeSeries}");</script>`;
            WindowPrt.document.write(script);

          }, 2000);

          setTimeout(() => {
            WindowPrt.document.close();
            WindowPrt.focus();
            WindowPrt.print();
            WindowPrt.close();
          }, 3000);
          this.spinner.hide();
        }
        else
          this.spinner.hide();

      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error('Error', 'Something Went Wrong');
      });
    }
    else if (type == 'search') {
      let dialogRef = this.dialog.open(this.batchInfoPalletDialog, { disableClose: true, minWidth: '90%', height: '70vh' });
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log('The dialog was closed');
        this.isPalletHistoryDialog = false;
      });
      this.GetPalletInfo(event);
    }
    else {
      const key = {
        PTID: this.warehouseForm.value.plantList,
        ItemCode: this.warehouseForm.value.item,
        TransactionTypeId: 3,
        PalletId: event.PalletId
      };
      this.spinner.show();
      this.qcBoxService.subGeneratePallet(key).subscribe((res: any) => {
        this.spinner.hide();
        const dummyObj = { PalletId: 0 }
        this.GetPalletInfoList();
        this.GetPalletBatchInfoList();
        this.GetPalletInfo(dummyObj);
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error('Error', 'Something Went Wrong');
      });
    }
  }

  GetPalletInfo(element: any): void {
    const key = {
      Id: element.PalletId
    };
    this.spinner.show();
    this.qcBoxService.GetPalletInfo(key).subscribe((res: any) => {
      this.spinner.hide();
      this.BatchInfoList = res.BatchInfoList;
      // this.batchInfoPalletDataSource = new MatTableDataSource<any>(res.BatchInfoList);
      // this.getPaginator(this.batchInfoPalletDataSource, 4);
      // this.batchInfoPalletDataSource.sort = this.sorter4;
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error('Error', 'Something Went Wrong');
    });
  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
    }, 100);
  }

  applyFilterForSelectedBatchInfo(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.batchInfoDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForqcBoxDataSource(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.qcBoxDataSource.filter = filterValue.trim().toLowerCase();
  }

  // applyFilterForPalletReceivedHistory(event: any): void {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.qualityHistoryDataSource.filter = filterValue.trim().toLowerCase();
  // }

  // applyFilterForPalletPreview(event: any): void {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.previewPalletDataSource.filter = filterValue.trim().toLowerCase();
  // }

  applyFilterForPalletInfo(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.palletInfoDataSource.filter = filterValue.trim().toLowerCase();
  }

  // applyFilterForPalletBatchInfo(event: any): void {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.batchInfoPalletDataSource.filter = filterValue.trim().toLowerCase();
  // }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        console.log(data.BreadcrumList);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
