
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { QualityShiftService } from "../../services/qualityshift.service";
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { QualityMasterProcessService } from 'src/app/services/quality-master-process.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import * as moment from 'moment';

@Component({
  selector: 'app-qualityshifts',
  templateUrl: './qualityshifts.component.html',
  styleUrls: ['./qualityshifts.component.scss']
})
export class QualityshiftsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Input() plantId!: number;

  masterShiftForm!: FormGroup;
  formMode!: boolean;
  // plantId!: number;
  plants!: any[];
  shifts!:[];
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  shiftId!: number;
  date8!: Date;

  shiftModelVisible = false;

  shiftColumns: string[] = [
    'plant',
    'shiftName',
    'startTime',
    'endTime',
    //  'shiftPattern',
    'shiftActions',
  ];
  shiftData!: MatTableDataSource<any>;
  IsWrite: any;
  IsAccess: any;
  productList: any;
  GroupId: any;
  plantsDDL: any;
  shift:any;
  shiftID: any;
  abc!: Date;

  constructor(
    private qualityService: QualityShiftService,
    private toaster: ToastrService,
    private machineService: MachineMappingService,
    private qualitymaster: QualityMasterProcessService,

   // private platModelService: EnterPriseGroupService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.masterShiftForm = this.fb.group({
      // shiftPattern: ['', Validators.required],
      plant: ['', Validators.required],
      shiftName: ['', Validators.required],
      startTime: ['',Validators.required ],
      endTime: ['',Validators.required],
    });
    let d = new Date();
  //   this.masterShiftForm.get('startTime')?.setValue(d.getHours+':'+ d.getMinutes);
  //  this.masterShiftForm.get('endTime')?.setValue(d.getHours()-1 +':'+ d.getMinutes);

    //  let d = new Date().toString();
    // let asd = new Date((new Date()).valueOf() - 1000*60*60*24);
    // let st= d.split(" ")[4];
    // let ed= new Date((new Date()).valueOf() -  );
    // this.masterShiftForm.get('startTime')?.setValue(st.substring(0, 5));
    // // this.masterShiftForm.get('endTime')?.setValue(ed);
    
  
  }

  // GetMenuAccess(): void {
  //   const key = {
  //     Id: localStorage.getItem('O3RightMenuId')
  //   };
  //   this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
  //     this.IsAccess = data.IsAccess;
  //     this.IsWrite = data.IsWrite;
  //   },
  //     (error:any) => {
  //       this.toaster.error('Error', error.error.message);
  //     }
  //   );
  // }

  getPlants(): void {

    const key = {
      GroupId: this.GroupId
    };

    this.machineService.getPlantsData(key).subscribe((data: any) => {

      this.plantsDDL = data.DepartList.filter((c: any) => c.DeptId === this.plantId);
      this.masterShiftForm.get('plant')?.setValue(this.plantsDDL[0].DeptId);
      // this.getArea(this.plantId);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getShift(): void {

    const key = {
      egId: this.GroupId,
      plantId : this.plantId
    };

    this.qualityService.getShift(key).subscribe((data: any) => {

      this.shift = data;
      this.masterShiftForm.get('shiftName')?.setValue(this.shift[0].shiftId);
      // this.getArea(this.plantId);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getListGP(plant: any, egid: any): void {

  
    this.GroupId = egid;
    this.plantId = plant;
    // this.masterShiftForm.get('plant')?.setValue(this.plantId);
     const key = {
      plantId: this.plantId,
    };
    this.qualityService.getQualityMasterShifts(key).subscribe(
      (data:any) => {

        this.shiftData = new MatTableDataSource<any>(data);
         this.getPaginator();
      },
      (error:any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
       

  }

  getPaginator(): void {
    setTimeout(() => {
      this.shiftData.paginator = this.paginator;
      this.shiftData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.shiftData.filter = filterValue.trim().toLowerCase();

    if (this.shiftData.paginator) {
      this.shiftData.paginator.firstPage();
    }
  }

  openModal(): void {
  

      this.resetForm(); 
    this.getPlants();
    this.getShift();

      // this.masterShiftForm.get('plant')?.setValue(this.plantId);
      this.shiftModelVisible = true;
      this.formMode = true;
  
  }

  getShiftbyId(Id:number): void {

    this.getPlants();
    this.getShift();
    this.shiftID= Id;
    const key = {
      shiftId: this.shiftID,
    };
    this.qualityService.getOneQualityShift(key).subscribe((data: any) => {

 
      this.masterShiftForm.get('plant')?.setValue(this.plantId);
      this.masterShiftForm.get('shiftName')?.setValue(data.generalShiftId);
      if (data.endTime !== undefined) {
        this.masterShiftForm
          .get('endTime')
          ?.setValue(data.endTime.substring(0, 5));
      }
      if (data.startTime !== undefined) {
        this.masterShiftForm
          .get('startTime')
          ?.setValue(data.startTime.substring(0, 5));
      }
      this.formMode = false;
      this.shiftModelVisible = true;
      //  this.CustomerDataForm.get('elementId')?.setValue(element);
    },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      });
  }

  updateForm(): void {
    debugger
     const shiftData = this.shiftData.filteredData;
    // const shift = shiftData.filter((c: any) => c.masterShiftId  === this.masterShiftForm.value.startTime);
    // if (shift.length > 0) {
    //   this.toaster.error('Error', 'Shift  Already Exist');
    //   return;
    // }
     const startComp =moment(this.masterShiftForm.value.startTime, ["h:mm a"]).format("hh:mm");
    const startTime = shiftData.filter((c: any) => c.startTime === startComp);
    if (startTime.length > 0) {
      this.toaster.error('Error', 'Shift already exist at same time');
      return;
    }
    const strTimeupdate =moment(this.masterShiftForm.value.startTime, ["h:mm a"]).format("hh:mm");
    const endTimeupdate =moment(this.masterShiftForm.value.endTime, ["h:mm a"]).format("hh:mm");
    const masterShift = {
      shiftId: this.shiftID,
      plantId: this.masterShiftForm.value.plant,
      // name: this.masterShiftForm.value.shiftName,
      masterShiftId:this.masterShiftForm.value.shiftName,
      startTime: strTimeupdate, 
      endTime: endTimeupdate,
      updatedBy: JSON.parse(localStorage.user).UserId
    };
    this.qualityService.updateQualityShiftData(masterShift).subscribe(
      (data:any) => {
        this.getListGP(this.plantId,this.GroupId);
        this.closeShiftModel();
        this.toaster.success(data.message);
      },
      (error:any) => {
        this.toaster.error('Error', error.error.message);
      }
    );

    
  }

  submitForm(): void {

    const shiftData = this.shiftData.filteredData;
    const shift = shiftData.filter((c: any) => c.masterShiftId  === this.masterShiftForm.value.shiftName);
    if (shift.length > 0) {
      this.toaster.error('Error', 'Shift  Already Exist');
      return;
    }
   
    
    if(this.masterShiftForm.valid){
      const strTime =moment(this.masterShiftForm.value.startTime, ["h:mm A"]).format("HH:mm");
      const endTime =moment(this.masterShiftForm.value.endTime, ["h:mm A"]).format("HH:mm");
    const data = {
      plantId: this.masterShiftForm.value.plant,
      masterShiftId:this.masterShiftForm.value.shiftName, 
      startTime: strTime,
      endTime: endTime, 
    };
    this.qualityService.addQualityShift(data).subscribe((data:any) => {
        this.getListGP(this.plantId,this.GroupId);
        // this.showPopupMsg(data.ErrorMessage);
        this.closeShiftModel();
        this.toaster.success(data.message);
        this.resetForm(); 

        // if(data.message ==="Shift is al"){
        //   this.masterShiftForm.value.startTime.setValue('');
        //   this.masterShiftForm.value.endTime.setValue('');


        // }
      },
      (error:any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }else{
    this.toaster.warning("Insert required field first!");

  }
 
 
    
  }
  // showPopupMsg(msg: any): any {
  //   if (msg !== null || msg !== '') {
  //     if (msg.substring(2, 1) === '0') {
  //       this.toaster.success('Success', msg.substring(3, msg.length));
  //     } else {
  //       this.toaster.error('Error', msg.substring(3, msg.length));
  //     }
  //   }
  // }
   formatAMPM(date:any) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  deleteMasterShift(shiftId: number): void {
   
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete Master Shift!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          const key = {
            shiftId,
          };
          this.qualityService.deleteQualityShiftRepo(key).subscribe(
            (data:any) => {
              this.getListGP(this.plantId, this.GroupId)
              this.toaster.success(data.message);
            },
            (error:any) => {
              this.toaster.error('Error', error.error.message);
            }
          );

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your Master Shift is safe :)', 'error');
        }
      });
   
  }
  resetForm(): void {
    this.masterShiftForm.reset();
  }

  getOneMasterShift(shiftId: number): void {
  
      const key = {
        shiftId,
      };
      this.shiftId = shiftId;
      
   
  }

  closeShiftModel(): void {
    this.shiftModelVisible = false;
  }

  OnChangePlant(data: any): void{
    this.masterShiftForm.get('plant')?.setValue(data.value);

  }
}
