import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ProductLine } from '../models/ProductLine';
import { SubAssembly } from '../models/subAssembly';
import { Assembly } from '../models/assembly';
import { ProductUnit } from '../models/ProductUnit';
import { Components } from '../models/component';
import { UserPlant } from '../models/userPlant';
import { SuccessMessage } from '../models/successMessage';
import { UserPermission } from '../models/userPermission';
import { Register } from '../models/register';
import { Userlist } from '../models/userlist';
import { UserMachine } from '../models/userMachine';
import { RolePermission } from '../models/rolePermission';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  // tslint:disable-next-line:typedef
  baseUrl2: string = environment.O3CoreApiUrl;
  kpibaseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;


  constructor(private http: HttpClient) { }

  getRoleMapData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Account/GetRoleMapList', data);
  }
  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetGroup', data);
  }

  getUserById(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl2 + 'Account/GetUserById', data);
  }

  getDepartments(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetDepartments', data);
  }
  adduserdepartment(data: any): Observable<any[]> {

    return this.http.post<any[]>(this.kpibaseUrl + 'adduserdepartment', data);
  }
  Updateuserdepartment(data: any): Observable<any[]> {

    return this.http.put<any[]>(this.kpibaseUrl + 'updateuserdepartment', data);
  }
  getUsersData(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Account/GetUserManagerList', data);
  }

  getusergroup(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'mastergroupuserdepartments', data);
  }
  getuserbydepartment(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'masteruserbydepartments', data);
  }


  submitUsersData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Account/SumbitUserManager', data);
  }
  getRoleList(data: any): Observable<any> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'Account/GetRoles', data, { headers: header });
  }
  getRoleMenuList(data: any): Observable<any> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.o3CoreApiUrl + 'Account/GetRoleMenulist', data, { headers: header });
  }
  SumbitRoleDetails(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Account/SumbitRoleDetails', data);
  }
  SumbitRoleMap(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Account/SumbitRoleMap', data);
  }

  GetStatusData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Account/SumbitRoleMap', data);
  }

  GetUsersByEgId(data: any): Observable<any[]> {

    return this.http.post<any[]>(this.kpibaseUrl + 'userbygroup', data);
  }

  GetUsersByArea(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Users/GetUserByArea', data);
  }

  GetAuditClassifications(): Observable<any[]> {
    return this.http.get<any[]>(this.o3CoreApiUrl + 'Classification/GetAuditClassifications');
  }

  GetDMSClassifications(): Observable<any[]> {
    return this.http.get<any[]>(this.kpibaseUrl + 'dmsclassfications');
  }

  getStatus(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Planner/GetStatus', data);
  }

  GetUOMData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl2 + 'Account/SumbitRoleMap', data);
  }

  GetMenulist(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Account/GetMenulist', data);
  }

  GetMenuChildList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetMenuChildList', data);
  }

  GetBreadcrumList(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetBreadcrumList', data);
  }

  getRoles(data: any): Observable<Role[]> {
    return this.http.post<Role[]>(this.baseUrl2 + 'roles', data);
  }
  addRole(role: any): any {
    return this.http.post(this.baseUrl2 + 'addrole', role);
  }
  getOneRole(role: any): Observable<any> {
    return this.http.post<any>(this.baseUrl2 + 'role', role);
  }
  updateRole(role: any): any {
    return this.http.put(this.baseUrl2 + 'role', role);
  }
  masterroles(data: any): any {
    return this.http.post(this.o3CoreApiUrl + 'Roles/MasterRoles', data);
  }
  deleteRole(role: any): Observable<SuccessMessage> {
    return this.http.post<SuccessMessage>(this.baseUrl2 + 'deleterole', role);
  }
  rolePermissions(search: any): Observable<RolePermission[]> {
    return this.http.post<RolePermission[]>(
      this.baseUrl2 + 'rolepermissions',
      search
    );
  }
  updateRolePermissions(data: any): Observable<SuccessMessage> {
    return this.http.put<SuccessMessage>(
      this.baseUrl2 + 'rolepermissions',
      data
    );
  }
  registerUser(register: any): any {
    return this.http.post(this.baseUrl2 + 'register', register);
  }
  updateUser(register: any): any {
    return this.http.put(this.baseUrl2 + 'user', register);
  }
  deleteUser(register: any): any {
    return this.http.post(this.baseUrl2 + 'deleteuser', register);
  }
  getOneMachine(data: any): Observable<UserMachine> {
    return this.http.post<UserMachine>(this.baseUrl2 + 'onemachine', data);
  }
  saveMachineAccess(data: any): any {
    return this.http.post(this.baseUrl2 + 'addmachineaccess', data);
  }
  getUsers(data: any): Observable<Userlist[]> {
    return this.http.post<Userlist[]>(this.baseUrl2 + 'users', data);
  }
  getRaciUsers(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Users/users', data);
  }
  getOneUser(data: any): Observable<Register> {
    return this.http.post<Register>(this.baseUrl2 + 'user', data);
  }
  userPermissions(data: any): Observable<UserPermission[]> {
    return this.http.post<UserPermission[]>(
      this.baseUrl2 + 'userpermissions',
      data
    );
  }
  updateUserPermissions(data: any): Observable<SuccessMessage> {
    return this.http.put<SuccessMessage>(
      this.baseUrl2 + 'userpermissions',
      data
    );
  }
  getUserPants(data: any): Observable<UserPlant[]> {
    return this.http.post<UserPlant[]>(this.baseUrl2 + 'userplant', data);
  }
  forgotPassword(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl2 + 'forgotpassword', data);
  }
  verifyOtp(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl2 + 'verifyotp', data);
  }
  updatePassword(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl2 + 'updatepassword', data);
  }
  getAllComponents(): Observable<Components[]> {
    return this.http.get<Components[]>(this.baseUrl2 + 'components');
  }
  getAllUnits(): Observable<ProductUnit[]> {
    return this.http.get<ProductUnit[]>(this.baseUrl2 + 'units');
  }
  getAllAssemblies(): Observable<Assembly[]> {
    return this.http.get<Assembly[]>(this.baseUrl2 + 'assemblies');
  }
  getAllSubAssemblies(): Observable<SubAssembly[]> {
    return this.http.get<SubAssembly[]>(this.baseUrl2 + 'subassemblies');
  }
  getAllLines(): Observable<ProductLine[]> {
    return this.http.get<ProductLine[]>(this.baseUrl2 + 'lines');
  }
  getMachineAccess(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl2 + 'machineaccess', data);
  }
  getSelectedNodesFlatArray(treeNode: any): any {
    const flatArray: any = [];
    const treeToFlat = (node: any): any => {
      if (node.data.IsSelect) {
        flatArray.push(node);
      }

      if (node.children.length) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < node.children.length; i++) {
          treeToFlat(node.children[i]);
        }
      }
    };

    treeToFlat(treeNode);

    return flatArray;
  }

  addMenuData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl2 + 'menu', data);
  }

  getMenuData(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl2 + 'menu');
  }

  getOneMenuData(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl2 + 'onemenu', data);
  }

  updateMenuData(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.baseUrl2 + 'menu', data);
  }

  deleteMenuData(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl2 + 'deletemenu', data);
  }
  getSelectedNodesMachineFlatArray(treeNode: any): any {
    const flatArray: any = [];
    const treeToFlat = (node: any): any => {
      if (node.partialSelected) {
        flatArray.push(node);
      }

      if (node.children.length) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < node.children.length; i++) {
          treeToFlat(node.children[i]);
        }
      }
    };

    treeToFlat(treeNode);

    return flatArray;
  }

  GetShiftStart(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/GetShiftStart', data);
  }

  SubmitShiftStart(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'SDFC/SubmitShiftStart', data);
  }
}
