import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditCategoryService {

  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getMasterCategories(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Categories/GetMasterCategories',data);
  }
  getAuditCategory(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Categories/GetAllCategories', data);
  }
  addAuditCategory(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Categories/AddCategory', data);
  }
  updateAuditCategory(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.o3CoreApiUrl + 'Categories/UpdateCategory', data);
  }
  deleteAuditCategory(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Categories/DeleteCategory', data);
  }
  SaveMasterAuditCategory(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Categories/SaveMasterAuditCategory', data);
  }

 }
