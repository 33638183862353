<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>
<header>
  <h1 mat-dialog-title>ReSchedule {{ taskDetail.Task.units ? taskDetail.Task.units.puDesc : '' }}</h1>
  <h1 mat-dialog-title>{{
    taskDetail.TechnicianName }}</h1>
</header>
<div mat-dialog-content class="prevDialogForm">
  <table style="margin: 0 auto;width: 100%;">
    <tbody>
      <tr>
        <td nowrap class="font-weight-bold">Task Name</td>
        <td nowrap>: {{ taskDetail.Task.WOType ? taskDetail.Task.WOType.Name : '' }}</td>
        <td nowrap class="font-weight-bold">Task Duration</td>
        <td nowrap>: {{ taskDetail.Task ? taskDetail.Task.Duration + ' Hours' : '' }}</td>
      </tr>
      <tr>
        <td nowrap class="font-weight-bold">Frequency</td>
        <td nowrap>: {{ taskDetail.Task.Frequency.Name }}</td>
        <td nowrap class="font-weight-bold">Scheduled At</td>
        <td nowrap>: {{ taskDetail.TaskExpireTime | date: 'dd-MM-yyyy' }}</td>
      </tr>
      <tr>
        <td nowrap class="font-weight-bold">Task Description</td>
        <td colspan="3">: {{ taskDetail.Task ? taskDetail.Task.Description : '' }}</td>
      </tr>
    </tbody>
  </table>

  <table style="margin: 0 auto;width: 100%;">
    <tbody>
      <tr *ngIf="!taskDetail.Status">
        <td nowrap colspan="2">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Select Month</mat-label>
            <mat-select placeholder="Choose Date" [(ngModel)]="taskDetail.month"
              (selectionChange)="updateRescheduleDate();getTechAvailbleTime();" required>
              <mat-option *ngFor="let month of monthNames" [value]="month">
                {{month}}
              </mat-option>
            </mat-select>
            <mat-error>Month is Required</mat-error>
          </mat-form-field>
        </td>
        <td nowrap colspan="2">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Available Dates</mat-label>
            <mat-select placeholder="Choose Date" [(ngModel)]="resheduledDate" (selectionChange)="getMachinesForDate()" required>
              <mat-option *ngFor="let date of availableDateList" [value]="date.date">
                {{ (date.date | date: 'dd-MM-yyyy') + ' Avl Hrs - ' + date.availableHrs}}
              </mat-option>
            </mat-select>
            <mat-error>Date is Required</mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="!taskDetail.Status">
        <td nowrap colspan="2">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Select Machine</mat-label>
            <mat-select placeholder="Choose Machine" [(ngModel)]="selectedMachine"
              (selectionChange)="getAvailbleMachine()">
              <mat-option *ngFor="let machine of availMachineList" [value]="machine.machineId">
                {{machine.machineName}}
              </mat-option>
            </mat-select>
            <mat-error>Machine is Required</mat-error>
          </mat-form-field>
        </td>
        <td nowrap colspan="2">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Select Task</mat-label>
            <mat-select placeholder="Choose Task" [(ngModel)]="selectedTaskId" (selectionChange)="checkFrequency()">
              <mat-option *ngFor="let task of availTaskList" [value]="task.ID">
                {{ task.Task.WOType.Name + ' - ' + task.Task.Duration + ' Hrs' }}
              </mat-option>
            </mat-select>
            <mat-error>Task is Required</mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="!taskDetail.Status">
        <td nowrap colspan="2">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Select Upcoming Task</mat-label>
            <mat-select placeholder="Choose" [(ngModel)]="otherTasks" multiple
              [disabled]="otherTaskList && !otherTaskList.length">
              <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>
              <mat-option *ngFor="let otherTask of otherTaskList" [value]="otherTask.ID"
                (click)="tosslePerOne(allSelected.viewValue)">
                {{ (otherTask.TaskExpireTime | date: 'dd-MM-yyyy') }}
              </mat-option>
            </mat-select>
            <mat-error>Upcoming Task is Required</mat-error>
          </mat-form-field>
        </td>
        <td nowrap colspan="2">
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Select Technician</mat-label>
            <mat-select placeholder="Choose" [(ngModel)]="technicianID">
              <mat-option *ngFor="let technician of technicianList" [value]="technician.UserId">
                {{ technician.fullName }}
              </mat-option>
            </mat-select>
            <mat-error>Technician is Required</mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="taskDetail.Status">
        <td nowrap class="font-weight-bold">Start Time</td>
        <td nowrap>: {{ taskDetail.StartTime | date: 'dd-MM-yyyy hh-mm a' }}</td>
        <td nowrap class="font-weight-bold">Finish Time</td>
        <td nowrap>: {{ taskDetail.FinishTime | date: 'dd-MM-yyyy hh-mm a' }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div mat-dialog-actions class="mat-dialog-actions">
  <button mat-button class="dialog-cancel-btn" (click)="onCancelClick()">Cancel</button>
  <button mat-button [disabled]="!IsWrite" class="dialog-save-btn" (click)="onSaveClick()" cdkFocusInitial
    [disabled]="taskDetail.Status ? true : false">ReSchedule</button>
</div>