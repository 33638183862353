import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QualityMasterProcessService {
  baseUrl = environment.O3CoreApiUrl;
  KpiUrl = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }


  gettreedata(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'DT/GetPlantModelTree', data);
  }

  addqualityarea(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'addqualityarea', data);
  }

  getproduct(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.KpiUrl + 'productsbyplant', data);
  }

}
