import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { WarehouseService } from 'src/app/services/warehouse.service';

@Component({
  selector: 'app-warehouse-received',
  templateUrl: './warehouse-received.component.html',
  styleUrls: ['./warehouse-received.component.scss'],
})
export class WarehouseReceivedComponent implements OnInit {
  @ViewChild('displayBatchInfoModal') displayBatchInfoModal!: TemplateRef<any>;
  @ViewChild('displayWarehouseHistoryModal')
  displayWarehouseHistoryModal!: TemplateRef<any>;
  @ViewChild('displayShiftStartModal')
  private displayShiftStartModal!: TemplateRef<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  wareHouseDataSource!: MatTableDataSource<any>;
  wareHouseColumns: any[] = [
    'action',
    'palletId',
    'palletLotCode',
    'noOfBox',
    'noOfItem',
    'createdOn',
    'createdBy',
  ];
  batchInfoDataSource!: MatTableDataSource<any>;
  batchInfoColumns: any[] = [
    'name',
    'name1',
    'name2',
    'name3',
    'name4',
    'name5',
  ];
  batchInfoViewDataSource!: MatTableDataSource<any>;
  batchInfoViewColumns: any[] = [
    'BatchInfoId',
    'ProcessOrder',
    'PPD_Num',
    'BatchNo',
    'ItemCode',
    'ItemDesc',
    'Qty',
    'ResultOn',
    'Remarks',
    'CreatedOn',
    'CreatedBy',
  ];
  warehouseHistoryDataSource!: MatTableDataSource<any>;
  warehouseHistoryColumns: any[] = [
    'PalletId',
    'PalletLotCode',
    'QCStatus',
    'NoOfBox',
    'NoOfItem',
    'CreatedOn',
    'CreatedBy',
    'action',
  ];

  warehouseForm!: FormGroup;
  groupList!: any[];
  plantList!: [];
  public plantList1: any;
  gridApi!: any;
  itemInfoList!: any[];
  processOrderList!: any[];
  PalletId!: any;
  selectedRowIndex!: any;
  highLightedArray: any[] = [];
  breadcrumList: any;
  batchInfoDataList: any[] = [];
  columnDefs: any;
  paginationPageSize!: any;
  SelectScan = 0;
  filteredGroups!: any[];
  filteredPlants!: any[];
  filteredItems!: any[];
  filteredProcessOrders!: any[];
  dataArray!: [];
  icons!: any;
  IsAccess:any;
  IsWrite:any;
  ButtonType!: any;
  gridOptions: any = {
    paginationPageSize: 10,
  };
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private warehouseService: WarehouseService,
    private toaster: ToastrService,
    private platModelService: EnterPriseGroupService,
    private usermanagementService: UserManagementService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.GetMenuAccess();
    this.warehouseForm = this.fb.group({
      groupList: [''],
      plantList: [''],
      item: [''],
      itemDesc: [''],
      procOrder: [''],
      scantBatchNo: [''],
    });
    this.GetBreadcrumList();
    this.GetGroups();
    this.columnDefs = [
      {
        field: 'action',
        sortable: true,
        resizable: true,
        filter: true,
        headerName: 'Action',
        cellRenderer: (params: any) => {
          return `
            <button mat-mini-fab="" color="primary" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: lightseagreen;" ng-reflect-color="primary"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>`;
        },
        cellRendererParams: {
          clicked(field: any) {},
        },
        maxWidth: 100,
        minWidth: 80,
        cellStyle: { textAlign: 'center' }
      },
      {
        field: 'PalletId',
        sortable: true,
        filter: true,
        resizable: true,
        headerName: 'Pallet Id',
      },
      {
        field: 'PalletLotCode',
        sortable: true,
        filter: true,
        resizable: true,

        headerName: 'Pallet LotCode',
      },
      {
        field: 'QCStatus',
        sortable: true,
        filter: true,
        resizable: true,
        headerName: 'QC Status',
      },
      {
        field: 'NoOfBox',
        sortable: true,
        filter: true,
        resizable: true,
        headerName: 'No of Box'
      },
      {
        field: 'NoOfItem',
        sortable: true,
        filter: true,
         resizable: true,
        headerName: 'No of items',
      },
      {
        field: 'CreatedOn',
        sortable: true,
        resizable: true,
        filter: true,
        headerName: 'Created On',
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },
      },
      {
        field: 'CreatedBy',
        sortable: true,
        filter: true,
        resizable: true,
        headerName: 'Created By',
      },
    ];
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
      // localStorage.setItem('GetMenuAccess', this.permisson);
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }


  cellClicked(params: any, showButton: boolean): void {
    const col = params.colDef.field;
    this.PalletId = params.data.PalletId;
    this.ButtonType = '';

    if (col === 'action') {
      this.ButtonType = params.event.target.classList[1];
      if (this.ButtonType === 'fa-eye') {
        this.dialog.open(this.displayBatchInfoModal, {
          width: '90vw !important',
          height: 'auto',
          minWidth: '90vw',
        });
        this.GetPalletInfo();
      } else {
        return;
      }
    }
  }
  GetGroups(): void {
    this.highLightedArray = [];
    const key = {
      CreatedBy: 0,
    };
    this.warehouseService.GetGroups(key).subscribe((res: any) => {
      this.groupList = res.masterList;
      this.filteredGroups = this.groupList.slice();
      this.warehouseForm.get('groupList')?.setValue(res.masterList[0].TypeId);
      this.GetPlants();
    });
  }

  GetPlants(): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    this.highLightedArray = [];
    const key = {
      GroupId: this.warehouseForm.value.groupList,
      UserId: 0,
    };
    this.warehouseService.GetPlants(key).subscribe((res: any) => {
      this.plantList = res.DepartList;
      this.filteredPlants = this.plantList.slice();
      this.warehouseForm.get('plantList')?.setValue(res.DepartList[0].DeptId);
      this.GetItemInfo();
    });
  }

  GetItemInfo(): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    const key = {
      EGId: this.warehouseForm.value.groupList,
      PTId: this.warehouseForm.value.plantList,
    };
    this.warehouseService.GetItemInfo(key).subscribe((res: any) => {
      this.itemInfoList = res.ItemInfoList;
      this.filteredItems = this.itemInfoList.slice();
      console.log(this.itemInfoList);
      this.warehouseForm.get('item')?.setValue(res.ItemInfoList[0].ItemCode);
      this.warehouseForm
        .get('itemDesc')
        ?.setValue(res.ItemInfoList[0].ItemDesc);
      this.GetProcessOrder();
      this.GetWHPalletInfoList();
    });
  }

  GetItemInfoDesc(event: any): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    const key = {
      EGId: this.warehouseForm.value.groupList,
      PTId: this.warehouseForm.value.plantList,
    };
    this.warehouseService.GetItemInfo(key).subscribe((res: any) => {
      this.itemInfoList = res.ItemInfoList;
      const index = res.ItemInfoList.findIndex(
        (el: any) => el.ItemCode === event.value
      );
      this.warehouseForm
        .get('item')
        ?.setValue(res.ItemInfoList[index].ItemCode);
      this.warehouseForm
        .get('itemDesc')
        ?.setValue(res.ItemInfoList[index].ItemDesc);
      this.GetProcessOrder();
    });
  }

  GetDescInfoItem(event: any): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    const key = {
      EGId: this.warehouseForm.value.groupList,
      PTId: this.warehouseForm.value.plantList,
    };
    this.warehouseService.GetItemInfo(key).subscribe((res: any) => {
      this.itemInfoList = res.ItemInfoList;
      const index = res.ItemInfoList.findIndex(
        (el: any) => el.ItemDesc === event.value
      );
      this.warehouseForm
        .get('item')
        ?.setValue(res.ItemInfoList[index].ItemCode);
      this.warehouseForm
        .get('itemDesc')
        ?.setValue(res.ItemInfoList[index].ItemDesc);
      this.GetProcessOrder();
    });
  }

  GetProcessOrder(): void {
    const key = {
      ItemCode: this.warehouseForm.value.item,
    };
    this.warehouseService.GetProcessOrder(key).subscribe((res: any) => {
      res.masterList.unshift({ TypeId: 0, TypeName: 'ALL' });
      this.processOrderList = res.masterList;
      this.filteredProcessOrders = this.processOrderList.slice();
      this.warehouseForm.get('procOrder')?.setValue(0);
      // if (res.masterList.length === 0) {
      //   this.warehouseForm.get('procOrder')?.setValue(0);
      // }
    });
  }

  GetWHPalletInfoList(): void {
    const key = {
      GroupId: this.warehouseForm.value.groupList,
      PlantId: this.warehouseForm.value.plantList,
      ItemCode: this.warehouseForm.value.item,
      PPId: 0,
    };
    console.log(key);
    this.warehouseService.GetWHPalletInfoList(key).subscribe((res: any) => {
      this.wareHouseDataSource = new MatTableDataSource<any>(
        res.PalletInfoList
      );
      this.getPaginator(this.wareHouseDataSource, 0);
    });
  }

  selectedBatchInfoList(): void {
    const key = {
      GroupId: this.warehouseForm.value.groupList,
      PlantId: this.warehouseForm.value.plantList,
      ItemCode: this.warehouseForm.value.item,
      PPId: 0,
    };
    this.warehouseService.GetWHPalletInfoList(key).subscribe((res: any) => {
      this.batchInfoDataSource = new MatTableDataSource<any>(
        res.PalletInfoList
      );
      this.getPaginator(this.batchInfoDataSource, 1);
    });
  }

  MoveBatchInfoDataSource(): void {
    this.selectedBatchInfoList();
    this.GetWHPalletInfoList();
  }

  clearBatchInfoDataData(): void {
    this.batchInfoDataSource = new MatTableDataSource<any>([]);
    this.getPaginator(this.batchInfoDataSource, 1);
  }

  openPopupViewForGetPalletInfo(element: any): void {
    this.PalletId = element.PalletId;
    this.dialog.open(this.displayBatchInfoModal, {
      width: '90vw !important',
      height: 'auto',
      minWidth: '90vw',
    });
    this.GetPalletInfo();
  }

  updatehighlight(element: any): void {
   if(this.IsWrite)
   {
    if (
      this.wareHouseDataSource.data.length !==
      this.batchInfoDataSource.data.length
    ) {
      this.selectedRowIndex = element.PalletId;
      if (this.batchInfoDataSource.data.length > 0) {
        const value = this.batchInfoDataSource.data.find(
          (x: any) => x.PalletId === element.PalletId
        )
          ? true
          : false;
        if (!value) {
          this.batchInfoDataSource.data.push(element);
          this.batchInfoDataSource = new MatTableDataSource<any>(
            this.batchInfoDataSource.data
          );
          this.getPaginator(this.batchInfoDataSource, 1);
        } else {
          this.toaster.error('Batch Info Already Selected!');
        }
      } else {
        this.batchInfoDataSource.data.push(element);
        this.batchInfoDataSource = new MatTableDataSource<any>(
          this.batchInfoDataSource.data
        );
        this.getPaginator(this.batchInfoDataSource, 1);
        console.log(this.batchInfoDataSource.data);
      }
    }
   }
   
  }

  deleteBatchInfoDataData(element: any): void {
    const index = this.batchInfoDataSource.data.findIndex(
      (x: any) => x.PalletId === element.PalletId
    );
    if (index > -1) {
      this.batchInfoDataSource.data.splice(index, 1);
      this.batchInfoDataSource = new MatTableDataSource<any>(
        this.batchInfoDataSource.data
      );
      this.getPaginator(this.batchInfoDataSource, 1);
    }
  }

  GetPalletInfo(): void {
    const key = {
      Id: this.PalletId,
    };
    this.warehouseService.GetPalletInfo(key).subscribe((res: any) => {
      this.batchInfoViewDataSource = new MatTableDataSource<any>(
        res.BatchInfoList
      );
      this.getPaginator(this.batchInfoViewDataSource, 2);
    });
  }

  warehouseHistoryModal(): void {
    this.dialog.open(this.displayWarehouseHistoryModal, { width: '70%' });
    this.GetWHPalletInfoHistoryList();
  }

  GetWHPalletInfoHistoryList(): void {
    const key = {
      PTID: this.warehouseForm.value.plantList,
      ItemCode: this.warehouseForm.value.item,
    };
    this.warehouseService
      .GetWHPalletInfoHistoryList(key)
      .subscribe((res: any) => {
        this.dataArray = res.PalletInfoList;
      });
  }

  warehouseReceivedModal(): void {}

  SubmitWHPallet(): void {
    const List: any = [];
    this.batchInfoDataList = [];
    this.batchInfoDataList = this.batchInfoDataSource.data;
    console.log(this.batchInfoDataList);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.batchInfoDataList.length; i++) {
      const item = {
        PallateBoxId: this.batchInfoDataList[i].PalletId,
      };
      List.push(item);
    }
    const key = {
      BoxList: List,
    };

    this.warehouseService.SubmitWHPallet(key).subscribe((res: any) => {
      if (res.HasError === false) {
        this.dialog.closeAll();
        this.GetPalletInfo();
        this.GetWHPalletInfoList();
        this.batchInfoDataSource = new MatTableDataSource<any>([]);
        this.batchInfoDataList = [];
        if (res.ErrorMessage === 'C1-Please select pallet items !') {
          this.toaster.error('Please select pallet items !');
        } else if (
          res.ErrorMessage === 'C0-Warehouse received  successfully !'
        ) {
          this.toaster.success('Warehouse received successfully !');
        }
      } else if (res.HasError === true) {
        this.toaster.error(
          'Object reference not set to an instance of an object.'
        );
        this.dialog.closeAll();
      }
    });
  }

  openPopUpViewForWareHouseReceived(): void {
    this.dialog.open(this.displayShiftStartModal, {
      width: '50vw !important',
      height: '48vh',
      minWidth: '50vw',
      disableClose: true,
    });
  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
    }, 1000);
  }

  applyFilterForSelectedBatchInfo(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.batchInfoDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForwareHouseDataSource(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.wareHouseDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForPalletReceivedHistory(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.warehouseHistoryDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForBatchInfo(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.batchInfoViewDataSource.filter = filterValue.trim().toLowerCase();
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  onChangeBatch(): void {
    let index = -1;
    setTimeout(() => {
      if ((this.warehouseForm.get('scantBatchNo')?.value).length !== 0) {
        for (let i = 1; i <= this.wareHouseDataSource.data.length; i++) {
          if (
            this.warehouseForm.value.scantBatchNo ===
            this.wareHouseDataSource.data[i - 1].PalletLotCode
          ) {
            this.SelectScan = 1;
            if (this.SelectScan === 1) {
              index = i - 1;
            }
            break;
          }
        }
      }
      if (this.SelectScan === 0) {
        this.toaster.error('Barcode Scan Not Found !');
        this.warehouseForm.get('scantBatchNo')?.setValue('');
      }
      if (index !== -1) {
        this.updatehighlight(this.wareHouseDataSource.data[index]);
        this.warehouseForm.get('scantBatchNo')?.setValue('');
      }
    }, 500);
    this.SelectScan = 0;
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
}
