import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { AnyForUntypedForms, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { QualityOdsService } from 'src/app/services/quality-ods.service';
import * as _moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { OpmsGlassService } from '../../services/opms-glass.service';


@Component({
  selector: 'app-qa-data-entry',
  templateUrl: './qa-data-entry.component.html',
  styleUrls: ['./qa-data-entry.component.scss']
})
export class QaDataEntryComponent implements OnInit, OnDestroy{
  @ViewChild('displayCommonAttributeHistoryModal')
  private displayCommonAttributeHistoryModal!: TemplateRef<any>;
  @ViewChild('displayMaterialDataHistoryModal')
  private displayMaterialDataHistoryModal!: TemplateRef<any>;
  @ViewChild('displayPDFExportModal')
  private displayPDFExportModal!: TemplateRef<any>;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  private subscriptions = new Subscription();
  public breadcrumList: any;
  qaMasterForm!: FormGroup;
  commonAttributesDataForm!: FormGroup;
  reportTypes!: any[];
  crewList!: any[];
  defectList!: any[];
  shiftList!: any[];
  thicknessList!: any[];
  materialList!: any;
  lastCommonAttribute!: any;
  commonAttributeInsertedId = 0;
  commonAttributes!: any[];
  commonAttributesColumns: string[] = [
    'Id',
    'Shift',
    'Crew',
    'Quality',
    'Standard',
    'Thickness',
    'GrossWidth',
    'NetAvailable',
    'NetCut',
    'CreatedOn',
    'Actions'
  ];
  commonAttributesData!: MatTableDataSource<any>;
  dataSource = new MatTableDataSource<any>();
  historyDataSource = new MatTableDataSource<any>();
  formPropertiesArray: any[] = [];
  qaDynamicForm!: FormGroup;
  isLoadingData = false;
  gridOptionsReports: any = {
    suppressPropertyNamesCheck: true,
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      filterParams: { newRowsAction: 'keep' },
    },
  };
  gridApi: any;
  gridColumnApi: any;
  headerHeight = 70;
  columnDefs: any[] = [];
  columnDefsMaterialHistory: any[] = [];
  rowSelection = 'single';
  ButtonType!: any;
  ButtonTypeMaterialHistory!: any;
  isMaterialDataMode = true;
  qaDataId = 0;
  // reports properties
  isReportMode = false;
  currentDate = new Date();
  rowDataReport: any[] = [];
  columnDefsReport: any[] = [];
  // rowMultiple = 'multiple';
  exportExcelColumns: any[] = [];
  QAPropertyList: any;
  tableRowData: any;
  headerData: any;
  reportType: any;
  isUpdated = false;
  disable = false;
  defectName: any;
  defectType: any;
  qualityMenu: any [] = [];
  rowObj!: any;
  limitValues: any[] = [];
  constructor(private fb: FormBuilder, private toaster: ToastrService, private datePipe: DatePipe,
              private qualityService: QualityOdsService, public dialog: MatDialog,
              public router: Router, private spinner: NgxSpinnerService,
              private usermanagementService: UserManagementService,
              private opmsGlassservice: OpmsGlassService,
              ) { }
  ngOnInit(): void {
    this.qaMasterForm = this.fb.group({
      date: [''],
      reportType: [''],
      crew: [''],
      shift: [''],
      quality: [''],
      standard: [''],
      thickness: [''],
      grossWidth: [''],
      netAvailable: [''],
      netCut: ['']
    });
    this.commonAttributesDataForm = this.fb.group({
      startDate: [''],
      endDate: [''],
    });
    this.getReportTypes();
    this.getBaseDetails();
    this.getQualityData();
    this.getBreadcrumList();
    this.getLimitValues();
  }

  getReportTypes(): void {
    this.qualityService.getReportTypes({}).subscribe(response => {
      if (response?.HasError) {
        this.toaster.error('Error', response?.ErrorMessage);
      }else {
        this.reportTypes = response?.MaterialList;
        this.qaMasterForm.get('reportType')?.setValue(this.reportTypes[0]?.TypeId);
      }
    }, (error) => {
      this.toaster.error('Error', error?.message);
    });
  }
  getBaseDetails(): void {
    const key = {
      UserId: JSON.parse(localStorage.user).UserId
    };
    this.qualityService.getBaseDetails(key).subscribe(response => {
      if (response?.HasError) {
        this.toaster.error('Error', response?.ErrorMessage);
      }else {
        this.crewList = response?.CrewList;
        this.defectList = response?.DefectList;
        this.shiftList = response?.ShiftList;
        this.thicknessList = response?.ThicknessList;
        this.materialList = response?.MaterialList;
        this.qaMasterForm.get('crew')?.setValue(this.crewList[0]?.TypeId);
        this.qaMasterForm.get('shift')?.setValue(this.shiftList[0].TypeId);
        this.qaMasterForm.get('thickness')?.setValue(this.thicknessList[0]?.TypeName);
        this.qaMasterForm.get('date')?.setValue(new Date());
        this.getLastCommonAttribute();
      }
    }, (error) => {
      this.toaster.error('Error', error?.message);
    });
    this.qualityService.getDefectName({}).subscribe(
      (response: any)=>{
        this.defectName = response.ThicknessList;
      }
    )
    this.qualityService.getDefects({}).subscribe(
      (response: any)=>{
        this.defectType = response.ThicknessList;
      }
    )
  }

  getQualityData(){
    this.opmsGlassservice.getQualityDropdown().subscribe(
      (response: any)=>{
        this.qualityMenu = (response.DropDownList).slice();
      },
    )
  }

  getThicknesses(): void {
    this.qualityService.getThickness({}).subscribe(response => {
      if (response?.HasError) {
        this.toaster.error('Error', response?.ErrorMessage);
      }else {
        this.thicknessList = response?.ThicknessList;
      }
    }, (error) => {
      this.toaster.error('Error', error?.message);
    });
  }
  getLastCommonAttribute(): void {
    const key = {
      UserId: JSON.parse(localStorage.user).UserId
    };
    this.qualityService.getLastCommonAttribute(key).subscribe(response => {
      if (response?.HasError) {
        this.toaster.error('Error', response?.ErrorMessage);
      }else {
        this.lastCommonAttribute = response?.LastEnteredCommonAttributes[0];
        this.qaMasterForm.get('quality')?.setValue((this.lastCommonAttribute?.Quality).split(","));
        this.qaMasterForm.get('standard')?.setValue(this.lastCommonAttribute?.Standard);
        this.qaMasterForm.get('grossWidth')?.setValue(this.lastCommonAttribute?.GrossWidth);
        this.qaMasterForm.get('netAvailable')?.setValue(this.lastCommonAttribute?.NetAvailable);
        this.qaMasterForm.get('netCut')?.setValue(this.lastCommonAttribute?.NetCut);
        this.qaMasterForm.get('crew')?.setValue(this.lastCommonAttribute?.CrewId);
        this.qaMasterForm.get('shift')?.setValue(this.lastCommonAttribute?.ShiftId);
        this.qaMasterForm.get('thickness')?.setValue(this.lastCommonAttribute?.Thickness);
        this.commonAttributeInsertedId = this.lastCommonAttribute?.Id;
      }
    }, (error) => {
      this.toaster.error('Error', error?.message);
    });
  }

  saveMasterEntry(): void {
    this.spinner.show();
    const {shift, crew, quality, standard, thickness, grossWidth,
          netAvailable, netCut, date} = this.qaMasterForm.value;
    const data = {
      ShiftId: shift,
      CrewId: crew,
      Quality: quality.toString(),
      Standard: standard,
      Thickness: thickness,
      GrossWidth: grossWidth,
      NetAvailable: netAvailable,
      NetCut: netCut,
      CreatedOn: _moment(date).format('yyyy/MM/DD')
    };
    this.qualityService.insertCommonAttribute(data).subscribe(response => {
      if (response?.HasError) {
        this.toaster.error('Error', response?.ErrorMessage);
      }else {
          this.commonAttributeInsertedId = response.data.Id 
          // this.getLastCommonAttribute();
          this.toaster.success('Success', 'Common CommonAttributes Saved Successfully');
          this.disable = true;
          this.isReportMode = false;
          this.formPropertiesArray = [];
          this.columnDefsReport = [];
          this.rowDataReport = [];
      }
      this.spinner.hide();
    }, (error) => {
        this.toaster.error('Error', error?.message);
        this.spinner.hide();
    });
  }

  updateMasterEntry(): void {
    this.spinner.show();
    const {shift, crew, quality, standard, thickness, grossWidth,
          netAvailable, netCut, date} = this.qaMasterForm.value;
    const data = {
      ShiftId: shift,
      CrewId: crew,
      Quality: quality.toString(),
      Standard: standard,
      Thickness: thickness,
      GrossWidth: grossWidth,
      NetAvailable: netAvailable,
      NetCut: netCut,
      Id: this.commonAttributeInsertedId,
    };
    this.qualityService.updateCommonAttribute(data).subscribe(response => {
      if (response?.HasError) {
        this.toaster.error('Error', response?.ErrorMessage);
      }else {
          // this.commonAttributeInsertedId = response.data.Id 
          // this.getLastCommonAttribute();
          this.toaster.success('Success', 'Common CommonAttributes Updated Successfully');
          this.isUpdated = false;
          this.disable = true;
          // this.getQAEntries(1);
          this.isReportMode = false;
          this.formPropertiesArray = [];
      }
      this.spinner.hide();
    }, (error) => {
        this.toaster.error('Error', error?.message);
        this.spinner.hide();
    });
  }

  getCommonAttributes(): void {
    this.spinner.show();
    const key = {
      StartDate: this.commonAttributesDataForm.get('startDate').value,
      EndDate: this.commonAttributesDataForm.get('endDate').value
    }
    this.qualityService.getCommonAttributes(key).subscribe(response => {
      if (response?.HasError) {
        this.toaster.error('Error', response?.ErrorMessage);
        this.spinner.hide()
      }else {
        this.commonAttributes = [];
        this.commonAttributes = response?.LastEnteredCommonAttributes
        this.commonAttributesData = new MatTableDataSource(this.commonAttributes);
        this.getPaginator();
      }
    }, (error) => {
        this.toaster.error('Error', error?.message);
        this.spinner.hide();
    });
  }

  // common attributes history related section
  openCommonAttributeHistoryModel(): void {
    this.dialog.open(this.displayCommonAttributeHistoryModal,
      {width: '100%', height: '95%', disableClose: true });
      let firstDate = new Date(
        new Date().getTime()
      );
      firstDate = new Date(firstDate.setDate(firstDate.getDate()-1));
      this.commonAttributesDataForm.get('startDate').setValue(this.datePipe.transform(firstDate, 'yyyy-MM-dd'));
      this.commonAttributesDataForm.get('endDate').setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    this.getCommonAttributes();
  }

  editCommonAttribute(Id: number): void {
    const foundAttribute = this.commonAttributes.find(x => x.Id === Id);
    this.commonAttributeInsertedId = Id;
    this.isUpdated = true;
    this.disable = false;
    if (foundAttribute) {
      const {CreatedOn, Crew, GrossWidth, NetAvailable, NetCut,
            Quality, Shift, Standard, Thickness} = foundAttribute;
      this.qaMasterForm.get('date')?.setValue(new Date(CreatedOn));
      this.qaMasterForm.get('quality')?.setValue(Quality.split(","));
      this.qaMasterForm.get('standard')?.setValue(Standard);
      this.qaMasterForm.get('grossWidth')?.setValue(GrossWidth);
      this.qaMasterForm.get('netAvailable')?.setValue(NetAvailable);
      this.qaMasterForm.get('netCut')?.setValue(NetCut);
      this.qaMasterForm.get('crew')?.setValue(this.crewList.find(x => x.TypeName === Crew)?.TypeId);
      this.qaMasterForm.get('shift')?.setValue(this.shiftList.find(x => x.TypeName === Shift)?.TypeId);
      this.qaMasterForm.get('thickness')?.setValue(Thickness);
      this.isReportMode = false;
      this.formPropertiesArray = [];
      this.columnDefsReport = [];
      this.rowDataReport = [];
      this.dialog.closeAll();
    }
  }

  deleteCommonAttribute(Id: number): void {
    this.qualityService.deleteCommonAttribute({Id}).subscribe(response => {
      if (response?.HasError) {
        this.toaster.error('Error', response?.ErrorMessage);
      }else {
          this.toaster.success('Success', 'Common Attribute Deleted Successfully');
          this.dialog.closeAll();
          this.getLastCommonAttribute();
          this.isReportMode = false;
          this.disable = false;
          this.formPropertiesArray = [];
          this.columnDefsReport = [];
          this.rowDataReport = [];
      }
    }, (error) => {
        this.toaster.error('Error', error?.message);
    });
  }

  resetQaMasterForm(){
    // this.qaMasterForm.reset();
    this.disable = false;
    this.isUpdated = false;
    this.qaMasterForm.get('date')?.setValue(new Date())
  }

  getPaginator(): void {
    setTimeout(() => {
      this.commonAttributesData.paginator = this.paginator;
      this.commonAttributesData.sort = this.sort;
      this.spinner.hide();
    }, 1000);
  }

  applyFilter(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.commonAttributesData.filter = filterValue.trim().toLowerCase();
  }

  // reports form sections and load reports

  loadReportForm(): void {
    this.isReportMode = false;
    this.isMaterialDataMode = true;
    const typeId = this.qaMasterForm.value.reportType;
    const reportType = this.reportTypes.find(x => x.TypeId === typeId);
    if (this.commonAttributeInsertedId != 0) {
      this.isLoadingData = true;
      const key = {
        ReportId: reportType.TypeId,
        ReportName: reportType.TypeName
      };
      this.qualityService.getQAProperties(key).subscribe(response => {
        if (response?.HasError) {
          this.toaster.error('Error', response?.ErrorMessage);
        }else {
          this.QAPropertyList = response?.QAPropertyList;
          const properties: any[] = response?.QAPropertyList;
          this.formPropertiesArray = [];
          const uniqueTypes: any[] = [...new Set(properties.map((x: any) => {
            return x.Type;
          }))];
          uniqueTypes.forEach(type => {
            this.formPropertiesArray.push({
              TypeName: type,
              properties: properties.filter(x => x.Type === type)
                          ?.map(x => {
                            return {
                              Id: x.Id,
                              Name: x.Name,
                              DisplayName: x.DisplayName
                            };
                          })
            });
          });
          const formControls: any = {};
          // tslint:disable-next-line:no-string-literal
          formControls['Time'] = [new Date()];
          properties.forEach(element => {
              formControls[element?.Name] = [''];
          });
          this.qaDynamicForm = this.fb.group(formControls);
          this.getQAEntries(1);
          this.isLoadingData = false;
        }
      }, (error) => {
        this.isLoadingData = false;
        this.toaster.error('Error', error?.message);
      });
    }else {
      this.toaster.warning('Warning', 'Please Fill Common Attributes Data');
    }
  }

  getQAEntries(transType: number): void {
    const {reportType, date} = this.qaMasterForm.value;
    const type = this.reportTypes.find(x => x.TypeId === reportType);
    if (reportType) {
        const key = {
          ReportName: type.TypeName,
          ReportId: type.TypeId,
          TransactionType: transType,
          DateValue: _moment(date).format('yyyy/MM/DD'),
          CommonAttributeId: this.commonAttributeInsertedId
        };
        this.qualityService.getQAEntries(key).subscribe(response => {
          if (response?.HasError) {
            this.toaster.error('Error', response?.ErrorMessage);
          }else {
            const dataArray = response?.DataList;
            this.headerData = response?.Headers;
            var indx = this.headerData.indexOf("CrewId");
            this.headerData[indx] = "Crew";
            indx = this.headerData.indexOf("ShiftId");
            this.headerData[indx] = "Shift";
            if(type.TypeName == "QA REPORT" || type.TypeName == "DEFFECT DENSITY"){
              for(let i=0; i<this.headerData.length; i++){
                if(this.headerData[i].includes('_') || this.headerData[i]=="L" || this.headerData[i]=="C" || this.headerData[i]=="R"){
                  let obj =  this.QAPropertyList.find((val: any) => val.Name === this.headerData[i]);
                  if(obj.Type == "Bloom / FLAT"){
                    this.headerData[i] = "BLOOM"+" "+obj.Name;
                  }
                  else if(obj.Type == "REAM LINE (1-5)"){
                    this.headerData[i] = "REAM LINE"+" "+obj.Name;
                  }
                  else{
                    this.headerData[i] = obj.Type+" "+obj.Name;
                  }
                }
              }
            }
            const historyTableData = dataArray.map((row: any[]) => {
              return row.reduce((result: any, field: any, index: number) => {
                result[this.headerData[index]] = field;
                return result;
              }, {});
            });
            this.dataSource = new MatTableDataSource(historyTableData);
            setTimeout(() => {
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }, 1000);
          }
        }, (error) => {
          this.toaster.error('Error', error?.message);
        });
     }
  }

  key: any;
  tableEleent: any = {};

  editTableEntry(element: any): any {
    const {reportType} = this.qaMasterForm.value;
    const type = this.reportTypes.find(x => x.TypeId === reportType);
    if(type.TypeName == "QA REPORT" || type.TypeName == "DEFFECT DENSITY"){
      for(let i=0; i< Object.keys(element).length; i++){
        this.key = Object.keys(element)[i];
        if(this.key.includes('_')){
          var key = this.key.split(" ");
          // var new_key = ;
          //  var key = {
          //   (key.length-1) : 
          //  }
          this.tableEleent[key[(key.length-1)]] = element[this.key] ;
          
          // var new_key = this.key.split(" ");
          // let {(new_key.length-1): this.key, ...rest } = element;
          // element = { this.key, ...rest }
        }
        else{
          // var new_key = element[this.key];
          this.tableEleent[this.key] = element[this.key];
        }
      
      }
       this.rowObj = this.tableEleent;

    }
    else{
     this.rowObj = element;
    }
    this.isMaterialDataMode = false;
    this.qaDataId = element.Id;
    for (const key in this.rowObj) {
      if (Object.prototype.hasOwnProperty.call(this.rowObj, key)) {
        if(key == "BLOOM L" || key == "BLOOM C" || key == "BLOOM R"){
          this.qaDynamicForm.controls[key.charAt(key.length-1)]?.setValue(this.rowObj[key]);
        }else{
          this.qaDynamicForm.controls[key]?.setValue(this.rowObj[key]);
        }
      }
    }
    this.dialog.closeAll();
  }

  deleteTableEntry(element: any): any {
    const {reportType} = this.qaMasterForm.value;
    const id = element.Id;
    const type = this.reportTypes.find(x => x.TypeId === reportType);
    if (type) {
      const key = {
        Id: id,
        ReportName: type.TypeName,
        TransactionType: 5,
        UserId: JSON.parse(localStorage.user).UserId
      };
      this.qualityService.deleteQAData(key).subscribe(response => {
        if (response?.HasError) {
          this.toaster.error('Error', response?.ErrorMessage);
        }else {
            this.toaster.success('Success', 'QA Data Deleted Successfully');
            this.dialog.closeAll();
            this.getQAEntries(1);
        }
      }, (error) => {
          this.toaster.error('Error', error?.message);
      });
    }else {
      this.toaster.error('Error', 'Please Select Report Type First');
    }
  }

  saveQAData(): void {
    this.spinner.show();
    this.qaDynamicForm.get('CommonAttributeId')?.setValue(this.commonAttributeInsertedId);
    const dataList: any[] = [];
    const formValueObj = this.qaDynamicForm.value;
    if (this.qaMasterForm.value.reportType === 5) {
      dataList.push({
        Name: 'NAME',
        Value: formValueObj.NAME ? formValueObj.NAME : '',
      });
      dataList.push({
        Name: 'Quality',
        Value: formValueObj.Quality ? formValueObj.Quality : '',
      });
      dataList.push({
        Name: 'Standard',
        Value: formValueObj.Standard ? formValueObj.Standard : '',
      });
      for (const key in formValueObj) {
        if (Object.prototype.hasOwnProperty.call(formValueObj, key) && key !== 'Time' && key !== 'NAME' && key !== 'Quality' && key !== 'Standard') {
          dataList.push({
            Name: key,
            Value: formValueObj[key] ? formValueObj[key] : ''
          });
        }
      }
    }
    else {
      for (const key in formValueObj) {
        if (Object.prototype.hasOwnProperty.call(formValueObj, key) && key !== 'Time') {
          dataList.push({
            Name: key,
            Value: formValueObj[key] ? formValueObj[key] : '' 
          });
        }
      }
    }

    const {reportType, shift, crew, quality, standard, thickness, grossWidth,
      netAvailable, netCut, date} = this.qaMasterForm.value;
    const type = this.reportTypes.find(x => x.TypeId === reportType);
    if (type) {
      const data = {
        Quality: quality.toString(),
        Standard: standard,
        Thickness: thickness,
        GrossWidth: grossWidth,
        NetAvailable: netAvailable,
        NetCut: netCut,
        ReportId: type?.TypeId,
        ReportName: type?.TypeName,
        CrewId: crew,
        ShiftId: shift,
        UserId: JSON.parse(localStorage.user).UserId,
        QADataList: dataList,
        CreatedOn: _moment(new Date()).format('yyyy/MM/DD HH:mm')
      };
      this.qualityService.insertQAData(data).subscribe(response => {
        if (response?.HasError) {
          this.toaster.error('Error', response?.ErrorMessage);
        }else {
            this.toaster.success('Success', 'QA Data Saved Successfully');
            this.getQAEntries(1);
            this.resetForm();
        }
        this.spinner.hide();
      }, (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error?.message);
      });
    }else {
      this.spinner.hide();
      this.toaster.error('Error', 'Please Select Report Type First');
    }
  }

  updateQAData(): void {
    this.spinner.show();
    this.qaDynamicForm.get('CommonAttributeId')?.setValue(this.commonAttributeInsertedId);
    const dataList: any[] = [];
    const formValueObj = this.qaDynamicForm.value;
    if (this.qaMasterForm.value.reportType === 5) {
      dataList.push({
        Name: 'NAME',
        Value: formValueObj.NAME,
      });
      dataList.push({
        Name: 'Quality',
        Value: formValueObj.Quality,
      });
      dataList.push({
        Name: 'Standard',
        Value: formValueObj.Standard,
      });
      for (const key in formValueObj) {
        if (Object.prototype.hasOwnProperty.call(formValueObj, key) && key !== 'Time' && key !== 'NAME' && key !== 'Quality' && key !== 'Standard') {
          dataList.push({
            Name: key,
            Value: formValueObj[key]
          });
        }
      }
    }
    else {
      for (const key in formValueObj) {
        if (Object.prototype.hasOwnProperty.call(formValueObj, key) && key !== 'Time') {
          dataList.push({
            Name: key,
            Value: formValueObj[key]
          });
        }
      }
    }
    const {reportType, shift, crew, quality, standard, thickness, grossWidth,
      netAvailable, netCut, date} = this.qaMasterForm.value;
    const type = this.reportTypes.find(x => x.TypeId === reportType);
    if (type) {
      const data = {
        Quality: quality.toString(),
        Standard: standard,
        Thickness: thickness,
        GrossWidth: grossWidth,
        NetAvailable: netAvailable,
        NetCut: netCut,
        ReportId: type?.TypeId,
        ReportName: type?.TypeName,
        CrewId: crew,
        ShiftId: shift,
        UserId: JSON.parse(localStorage.user).UserId,
        QADataList: dataList,
        UpdatedOn: _moment(new Date()).format('yyyy/MM/DD HH:mm'),
        UpdateId: this.qaDataId
      };
      this.qualityService.updateQAData(data).subscribe(response => {
        if (response?.HasError) {
          this.toaster.error('Error', response?.ErrorMessage);
        }else {
            this.getQAEntries(1);
            this.toaster.success('Success', 'QA Data Updated Successfully');
            this.resetForm();
            this.isMaterialDataMode = true;
            this.qaDataId = 0;
        }
        this.spinner.hide();
      }, (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error?.message);
      });
    }else {
      this.spinner.hide();
      this.toaster.error('Error', 'Please Select Report Type First');
    }

  }

  openMaterialDataHistoryModel(): void {
    const historyDialog = this.dialog.open(this.displayMaterialDataHistoryModal,
      {width: '100%', height: '90%', disableClose: true });
    this.getQAEntries(2);
    historyDialog.afterClosed().subscribe(() => {
      this.getQAEntries(1);
    });
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  // reports section

  getReports(): void {
    this.spinner.show();
    this.columnDefsReport = [];
    this.rowDataReport = [];
    this.isReportMode = true;
    this.reportType =this.search(this.qaMasterForm.value.reportType, this.reportTypes)
    const {reportType, date} = this.qaMasterForm.value;
    const type = this.reportTypes.find(x => x.TypeId === reportType);
    if (reportType) {
        const key = {
          ReportName: type.TypeName,
          ReportId: type.TypeId,
          TransactionType: 2,
          DateValue: _moment(date).format('yyyy/MM/DD')
        };
        this.qualityService.getQAReport(key).subscribe(response => {
          this.spinner.hide();
          if (response?.HasError) {
            this.toaster.error('Error', response?.ErrorMessage);
          }else {
            const tempColsArray: any[] = [];
            const headersArray: string[] = [];
            let qualityIndex: number;
            let thickIndex: number;
            const dataArray = response?.DataList;
            this.exportExcelColumns = [];
            response?.Headers.forEach((value: string, index: number) => {
                const key = 'key' + index;
                if (value === 'NAME') {
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    maxWidth: 110
                  });
                }else if(value == 'ShiftId'){
                  tempColsArray.push({
                    field: key,
                    headerName: 'Shift',
                    sortable: true,
                    resizable: true,
                    maxWidth: 110
                  });
                }else if(value == 'CrewId'){
                  tempColsArray.push({
                    field: key,
                    headerName: 'Crew',
                    sortable: true,
                    resizable: true,
                    maxWidth: 110
                  });
                }else if((value == 'QUALITY' || value == 'THK' || value == 'NOMINAL THICKNESS') && 
                         (type.TypeName == 'QA REPORT' || type.TypeName == 'THICKNESS TSV')){
                  tempColsArray.push({
                    field: value == 'QUALITY' ? 'QUALITY' : 'THK',
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 110
                  });
                  headersArray.push( value == 'QUALITY' ? 'QUALITY' : 'THK');
                  qualityIndex = value == 'QUALITY' ? index : qualityIndex;
                  thickIndex = (value == 'THK' || value == 'NOMINAL THICKNESS') ? index : thickIndex;
                  return;
                }else if(type.TypeName == 'QA REPORT' && value == 'edge light/kg (seed count per kg)'){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125,
                    cellStyle: (params: any) => ((params.data['MaxValSeedCount'] ? params.value > params.data['MaxValSeedCount'] : false) || 
                                (params.data['MinValSeedCount'] ? params.value < params.data['MinValSeedCount'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                  headersArray.push(key);
                  tempColsArray.push({
                    field: 'MaxValSeedCount',
                    headerName: 'Max Limit for Seed Count per Kg',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MaxValSeedCount');
                  tempColsArray.push({
                    field: 'MinValSeedCount',
                    headerName: 'Min Limit for Seed Count per Kg',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MinValSeedCount');
                  return;
                }else if(type.TypeName == 'QA REPORT' && (value == 'Zebra LE' || value == 'Zebra LC' || value == 'Zebra RC')){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 115,
                    cellStyle: (params: any) => ((params.data['MaxValZebra'] ? params.value > params.data['MaxValZebra'] : false) || 
                                (params.data['MinValZebra'] ? params.value < params.data['MinValZebra'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                }else if(type.TypeName == 'QA REPORT' && value == 'Zebra RE'){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 115,
                    cellStyle: (params: any) => ((params.data['MaxValZebra'] ? params.value > params.data['MaxValZebra'] : false) || 
                                (params.data['MinValZebra'] ? params.value < params.data['MinValZebra'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                  headersArray.push(key);
                  tempColsArray.push({
                    field: 'MaxValZebra',
                    headerName: 'Max Limit for Zebra Value with GL Quality',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MaxValZebra');
                  tempColsArray.push({
                    field: 'MinValZebra',
                    headerName: 'Min Limit for Zebra Value with GL Quality',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MinValZebra');
                  return;
                }else if(type.TypeName == 'QA REPORT' && (value == 'Bloom_L' || value == 'Bloom_C')){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 115,
                    cellStyle: (params: any) => ((params.data['MaxValBloom'] ? params.value > params.data['MaxValBloom'] : false) || 
                                (params.data['MinValBloom'] ? params.value < params.data['MinValBloom'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                }else if(type.TypeName == 'QA REPORT' && value == 'Bloom_R'){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 115,
                    cellStyle: (params: any) => ((params.data['MaxValBloom'] ? params.value > params.data['MaxValBloom'] : false) || 
                                (params.data['MinValBloom'] ? params.value < params.data['MinValBloom'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                  headersArray.push(key);
                  tempColsArray.push({
                    field: 'MaxValBloom',
                    headerName: 'Max Limit for Bloom Grade with AT Quality',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MaxValBloom');
                  tempColsArray.push({
                    field: 'MinValBloom',
                    headerName: 'Min Limit for Bloom Grade with AT Quality',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MinValBloom');
                  return;
                }else if(type.TypeName == 'THICKNESS TSV' && value == 'AVG'){
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    maxWidth: 110,
                    cellStyle: (params: any) => ((params.data['MaxValAvg'] ? params.value > params.data['MaxValAvg'] : false) || 
                                (params.data['MinValAvg'] ? params.value < params.data['MinValAvg'] : false)) ? 
                                {background: '#e5dede',color: 'red'} : { color: 'black' }
                  });
                  headersArray.push(key);
                  tempColsArray.push({
                    field: 'MaxValAvg',
                    headerName: 'Max Limit for AVG with Thickness',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MaxValAvg');
                  tempColsArray.push({
                    field: 'MinValAvg',
                    headerName: 'Min Limit for AVG with Thickness',
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 125
                  });
                  headersArray.push('MinValAvg');
                  return;
                }else {
                  tempColsArray.push({
                    field: key,
                    headerName: value,
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    maxWidth: 120
                  });
                }
                // if(value !== 'edge light/kg (seed count per kg)' && value !== 'Zebra RE' && value !== 'Bloom_R' &&
                //    value !== 'QUALITY' && value !== 'THK' && value !== 'NOMINAL THICKNESS' && value !== 'AVG'){
                headersArray.push(key);
                // }
            });
            let indexUpdate = 0;
            // const tableData = dataArray.map((row: any[]) => {
            //   if(type.TypeName == 'QA REPORT'){
            //     console.log(row[qualityIndex])
            //   }
            //   if(type.TypeName == 'THICKNESS TSV'){
            //     console.log(row[thickIndex])
            //   }
            //   return row.reduce((result: { [x: string]: any; }, field: any, index: number) => {
            //     if(index == 0){
            //       indexUpdate = 0;
            //     }
            //     if(headersArray[indexUpdate].includes('key') || headersArray[indexUpdate].includes('QUALITY') || headersArray[indexUpdate].includes('THK')){
            //       result[headersArray[indexUpdate]] = field;
            //       indexUpdate = indexUpdate + 1;
            //       return result;
            //     }
            //     else{
            //       if(headersArray[indexUpdate] == 'Max Limit for Seed Count per Kg'){
            //         result[headersArray[indexUpdate]] = this.updateLimitValuesForGrid('Max Seed Count',type.TypeName);
            //         result[headersArray[indexUpdate + 1]] = this.updateLimitValuesForGrid('Min Seed Count',type.TypeName);  
            //       }
            //       if(headersArray[indexUpdate] == 'Max Limit for Zebra Value with GL Quality'){
            //         result[headersArray[indexUpdate]] = this.updateLimitValuesForGrid('Max Zebra Value',type.TypeName);
            //         result[headersArray[indexUpdate + 1]] = this.updateLimitValuesForGrid('Max Zebra Value',type.TypeName);  
            //       }
            //       if(headersArray[indexUpdate] == 'Max Limit for Bloom Grade with AT Quality'){
            //         result[headersArray[indexUpdate]] = this.updateLimitValuesForGrid('Max Bloom Grade',type.TypeName);
            //         result[headersArray[indexUpdate + 1]] = this.updateLimitValuesForGrid('Max Bloom Grade',type.TypeName);  
            //       }
            //       // result[headersArray[indexUpdate + 2]] = field;
            //       indexUpdate = indexUpdate + 3;
            //       return result;
            //     }
            //   }, {});
            // });
            const tableData = [];
            for(let row = 0; row < dataArray.length; row++){
              let objRowForGrid = {};
              for(let rowData = 0; rowData < dataArray[row].length; rowData++){
                if(rowData == 0){
                  indexUpdate = 0;
                }
                if(headersArray[indexUpdate].includes('key') || headersArray[indexUpdate].includes('QUALITY') || headersArray[indexUpdate].includes('THK')){
                  objRowForGrid[headersArray[indexUpdate]] = dataArray[row][rowData];
                  indexUpdate = indexUpdate + 1;
                }
                else{
                  if(headersArray[indexUpdate] == 'MaxValSeedCount'){
                    objRowForGrid[headersArray[indexUpdate]] = this.limitValues.find(row => row.CriticalParameters == 'Seed Count')?.MaxValue;
                    objRowForGrid[headersArray[indexUpdate + 1]] = this.limitValues.find(row => row.CriticalParameters == 'Seed Count')?.MinValue; 
                  }
                  if(headersArray[indexUpdate] == 'MaxValZebra' && dataArray[row][qualityIndex].includes('GL')){
                    objRowForGrid[headersArray[indexUpdate]] = this.limitValues.find(row => row.CriticalParameters == 'Zebra value for Gl quality')?.MaxValue;
                    objRowForGrid[headersArray[indexUpdate + 1]] = this.limitValues.find(row => row.CriticalParameters == 'Zebra value for Gl quality')?.MinValue; 
                  }
                  if(headersArray[indexUpdate] == 'MaxValBloom' && dataArray[row][qualityIndex].includes('AT')){
                    objRowForGrid[headersArray[indexUpdate]] = this.limitValues.find(row => row.CriticalParameters == 'Bloom Grade (AT quality )')?.MaxValue;
                    objRowForGrid[headersArray[indexUpdate + 1]] = this.limitValues.find(row => row.CriticalParameters == 'Bloom Grade (AT quality )')?.MinValue; 
                  }
                  if(headersArray[indexUpdate] == 'MaxValAvg'){
                    let thicknessParams = this.limitValues.find(row1 => row1.Thickness ==  dataArray[row][thickIndex])
                    objRowForGrid[headersArray[indexUpdate]] = thicknessParams ? thicknessParams?.MaxValue : '';
                    objRowForGrid[headersArray[indexUpdate + 1]] = thicknessParams ? thicknessParams?.MinValue : ''; 
                  }
                  // result[headersArray[indexUpdate + 2]] = field;
                  objRowForGrid[headersArray[indexUpdate + 2]] = dataArray[row][rowData];
                  indexUpdate = indexUpdate + 3;
                }
              }
              tableData.push(objRowForGrid);
            }
            this.columnDefsReport = tempColsArray;
            this.rowDataReport = tableData;
            this.exportExcelColumns = headersArray.slice();
          }
        }, (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error?.message);
        });
     }
  }

  onFirstDataRendered(params: { columnApi: { autoSizeAllColumns: () => void; }; }): void {
    params.columnApi.autoSizeAllColumns();
  }

  // onColumnEverythingChanged(params: { columnApi: { getColumn:
  //   (arg0: string) => any; moveColumn: (arg0: any, arg1: number) => void; }; }): void {
  //   const selectionCol = params.columnApi.getColumn('selection-col');
  //   if (selectionCol) {
  //     params.columnApi.moveColumn(selectionCol, 0);
  //   }
  // }

  exportToExcel(): void {
    const excelParams = {
      headerRowHeight: 50,
      sheetName: 'Physical - '+this.reportType,
      fileName: 'Physical - '+this.reportType,
      allColumns: false,
      columnKeys: this.exportExcelColumns
    };
    this.gridApi.exportDataAsExcel(excelParams);
  }

  openPDFExportModel(): void {
    this.dialog.open(this.displayPDFExportModal, {height: '60%', width: '50%'});
  }

  resetForm(): void {
    this.qaDynamicForm.reset();
    this.isMaterialDataMode = true;
    this.qaDynamicForm.get('Time')?.setValue(new Date());
  }

  getLimitValues(): any {
    this.qualityService.GetLimitValues().subscribe(
      (response: any) => {
        if(!response.HasError){
          this.limitValues = response.result;
        }else{
          this.toaster.error(response.result[0].Msg);
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
      }
    )
  }

  updateLimitValuesForGrid(params: any,reportName: string){
    if(reportName == 'QA REPORT'){
      if(params == 'Max Seed Count'){
        return this.limitValues.find(row => row.CriticalParameters == 'Seed Count')?.MaxValue;
      }
      if(params == 'Min Seed Count'){
        return this.limitValues.find(row => row.CriticalParameters == 'Seed Count')?.MinValue;
      }
      if(params == 'Max Zebra Value'){
        return this.limitValues.find(row => row.CriticalParameters == 'Zebra value for Gl quality')?.MaxValue;
      }
      if(params == 'Min Zebra Value'){
        return this.limitValues.find(row => row.CriticalParameters == 'Zebra value for Gl quality')?.MinValue;
      }
      if(params == 'Max Bloom Grade'){
        return this.limitValues.find(row => row.CriticalParameters == 'Bloom Grade (AT quality )')?.MaxValue;
      }
      if(params == 'Min Bloom Grade'){
        return this.limitValues.find(row => row.CriticalParameters == 'Bloom Grade (AT quality )')?.MinValue;
      }
    }
    if(reportName == 'THICKNESS TSV'){
      console.log(params);
    }
    if(reportName !== 'QA REPORT' && reportName !== 'THICKNESS TSV'){
      return '';
    }
  }

  displayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  getBreadcrumList(): any {
    this.spinner.show();
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    const breadCrumSub = this.usermanagementService.GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      ).subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    this.subscriptions.add(breadCrumSub);
  }
  search(nameKey: any, myArray: string | any[]){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].TypeId === nameKey) {
            return myArray[i].TypeName;
        }
    }
  }
  validateNumberFields(event: AnyForUntypedForms,control: string){
    if(isNaN(event) && control == 'Class'){
      this.qaDynamicForm.get(control)?.setValue('');
      this.toaster.warning('Please enter number only','Warning!');
    }
    else if(isNaN(event) && event != '.'){
      this.qaDynamicForm.get(control)?.setValue('');
      this.toaster.warning('Please enter number only','Warning!');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  consoleTime(){
    console.log(new Date(this.qaDynamicForm.get('Time')?.value).getHours() + ':' + new Date(this.qaDynamicForm.get('Time')?.value).getMinutes());
  }

}
