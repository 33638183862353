<section class="home-content">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a
        class="breadcrumb-link"
        [routerLink]="breadcrumItem.Url"
        [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
        >{{ breadcrumItem.text }}</a
      >
    </li>
  </ul>
  <mat-card class="master-card">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-6 p-lg-7 p-xl-7">
        <mat-card-title class="master-title">Master Data Settings</mat-card-title>
      </div>

      <div class="p-col-12 p-md-6 p-lg-5 p-xl-5" style="display: flex; justify-content: flex-end;">
        <form [formGroup]="searchOleMasterForm" style="float: right;">
          <mat-form-field appearance="fill"class="field-fill-custom-master" style="margin-right: 10px;">
            <mat-label class="required">Group</mat-label>
            <mat-select panelClass="testClass" name="group" formControlName="group"
              (selectionChange)="getPlantByGroup()">
              <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
            </mat-select>
          </mat-form-field>

        </form>
        <form [formGroup]="searchOleMasterForm" style="float: right; margin-left: 10px;">
          <mat-form-field appearance="fill" class="field-fill-custom-master">
            <mat-label class="required">Plant</mat-label>
            <mat-select panelClass="testClass" name="plant" formControlName="plant" (selectionChange)="getMasterData()">
              <mat-option *ngFor="let p of plants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
  </mat-card>
  <mat-card class="p-mt-2 p-p-0">
    <!-- Table -->
    <div class="p-grid p-fluid">
      <div class="p-xl-6 p-lg-6 p-md-12 p-col-12">
        <app-project></app-project>
      </div>
      <div class="p-xl-6 p-lg-6 p-md-12  p-col-12">
        <app-Topic></app-Topic>
      </div>

      <div class="p-xl-12 p-lg-12 p-md-12  p-col-12">
        <app-Task></app-Task>
      </div>
    </div>
  </mat-card>
</section>
