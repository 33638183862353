import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { UserManu } from 'src/app/models/userManu';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-execution',
  templateUrl: './execution.component.html',
  styleUrls: ['./execution.component.scss']
})
export class ExecutionComponent implements OnInit {

  @Input() aPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<Pillar>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
displayMAchineModal = false;
  pillars!: Pillar[];
  pillarId!: number;
  plants!: Plantbygroup[];
  areaForm!: FormGroup;
  formMode!: boolean;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  plantId!: number;
displayUserModal = false;



  auditcatColumns: string[] = ['name','areaActions'];
  pillarData!: MatTableDataSource<Pillar>;
    getareaMaster: any;
  constructor(
    private fb: FormBuilder,
    private kpiService: KpimasterService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {


    this.formMode = true;
    this.areaForm = this.fb.group({
      areaname: ['', [Validators.required, Validators.pattern('[^"]+$')]],
       ownername: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      plant: ['', Validators.required],
    });
  }
  getPaginator(): void {
    setTimeout(() => {
      this.pillarData.paginator = this.paginator;
      this.pillarData.sort = this.sort;
    }, 1000);
  }


  opensideModal(): void {
    this.formMode = true;

    this.displayUserModal = true;
  }
   closeaddNewPlantItemModel(): void {
    this.displayUserModal = false;
  }




}
