import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const Excel_Type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const Excel_Extension = '.xlsx';
let FileSaver = require('file-saver');
@Injectable({
  providedIn: 'root'
})

export class OleService {
  baseUrl: string = environment.O3CoreApiUrl;
  
  constructor(private http: HttpClient) { }
  getPlantsData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Common/GetDepartments', data);
  }

  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Common/GetGroup', data);
  }

  // Staff Attendence 
  getStaffAttendence(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'StaffAttendance/GetStaffAttendance', data);
  }

  addStaffAttendence(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'StaffAttendance/AddStaffAttendance', data);
  }

  deleteStaffAttendence(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'StaffAttendance/DelStaffAttendance', data);
  }

  addAttendenceFile(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'StaffAttendance/addstaffattendancewithexcel', data);
  }

  //Excel Download

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileWithMultipleSheet(json1: any[], json2: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json1);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    const workbook: XLSX.WorkBook = { Sheets: { 'CostCenter': worksheet, 'CostElement': worksheet2 }, SheetNames: ['CostCenter', 'CostElement'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: Excel_Type });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + Excel_Extension);
  }

}
