import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  PlantModelElement,
  PlantGroupElement,
} from '../models/O3AdministrationModels/plant-model';

@Injectable({
  providedIn: 'root'
})
export class DefectDashBoardService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getUsers(data : any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Dashboard/GetUserListForEG',data);
  }
  getDefectDashboard(data : any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Dashboard/GetDefectDashboard',data);
  }

}