import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FivewhyService {

  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) {
  }

  // *** Core Api Method *** //
  getFiveWhyData(headerId: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.get<any[]>(this.baseUrl + 'find-five-why', { headers: header, params: { headerId: headerId } });
    return this.http.get<any[]>(this.o3CoreApiUrl + 'ATFiveWhy/ShowFiveWhy', { headers: header, params: { headerId: headerId } });
  }

  // *** Core Api Method *** //
  updateFiveWhyData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.put<any[]>(this.baseUrl + 'update-five-why', data, { headers: header });
   return this.http.post<any[]>(this.o3CoreApiUrl + 'ATFiveWhy/UpdateFiveWhy', data, { headers: header });
  }

  // *** Core Api Method *** //
  addFiveWhyData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.post<any[]>(this.baseUrl + 'add-five-why', data, { headers: header });
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ATFiveWhy/AddFiveWhy', data, { headers: header });
  }

  // *** Core Api Method *** //
  addFiveWhyActionData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.post<any[]>(this.baseUrl + 'add-five-why-plan', data, { headers: header });
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ATFiveWhyActionPlan/AddFiveWhyPlan', data, { headers: header });
  }

  // *** Core Api Method *** //
  getFiveWhyPlanData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.get<any[]>(this.baseUrl + 'find-five-why-plan', { headers: header, params: data });
    return this.http.get<any[]>(this.o3CoreApiUrl + 'ATFiveWhyActionPlan/ShowFiveWhyActionPlan', { headers: header, params: data });
  }

  // *** Core Api Method *** //
  updateFiveWhyPlanData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.put<any[]>(this.baseUrl + 'update-five-why-plan', data, { headers: header });
   return this.http.post<any[]>(this.o3CoreApiUrl + 'ATFiveWhyActionPlan/UpdateFiveWhyPlan', data, { headers: header });
  }

}
