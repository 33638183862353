<div class="order-processing-content">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="top-box-content">
      <h1 class="main-title">User & Access</h1>
    </div>
  </div>

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>


  <form [formGroup]="groupDataForm">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-box-content">
        <h1 class="content-title">User Access List</h1>
      </div>
      <div  class="content-top-row content-top-row-order-processing">


      <mat-form-field appearence="fill"
      class="top-row-first-filed-item top-row-first-filed-item-order-processing">
      <mat-select   formControlName="group" placeholder="Group" panelClass="testClass" (selectionChange)="getPlants()">
        <mat-option *ngFor="let group of Groups" [value]="group.TypeId">
          {{group.TypeName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearence="fill"
      class="top-row-first-filed-item top-row-first-filed-item-order-processing">
      <mat-select formControlName="plant"  placeholder="Plant" panelClass="testClass" name="plant"  (selectionChange)="getDeparts()">
        <!-- <mat-option [value]="0">ALL</mat-option> -->
        <mat-option *ngFor="let plant of Plants" [value]="plant.DeptId">
          {{plant.DeptDesc}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearence="fill"
      class="top-row-first-filed-item top-row-first-filed-item-order-processing">
      <mat-select   formControlName="department" placeholder="Department" panelClass="testClass">
        <mat-option *ngFor="let department of Departs" [value]="department.TypeId">
          {{department.TypeName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button (click)="getUsers()" class="icon-btn" style="margin-left: 20px;" pTooltip="Apply Filter" tooltipPosition="top">
      <img src="assets/images/search.png" alt="" width="20px" height="20px">
    </button>
    <!-- <button mat-flat-button color="accent" class="btn-accent" style="margin-left: 10px;" (click)="getUsers()">Apply Filter</button> -->
   
      </div>
    
    <!-- <div>
      <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bb6363" type = "square-jelly-box" [fullScreen] = "true"><p style="color: rgb(194, 169, 169)" > Loading... </p></ngx-spinner>
    </div> -->
      <div class="content-top-row content-top-row-order-processing" style="justify-content: flex-end;">


        <mat-form-field appearence="fill"
        class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <input matInput placeholder="Search"  (keyup)="applyFilter($event)" autocomplete="off">
        </mat-form-field>


      </div>


      <table mat-table class="basic-table plant-model-table" matSort [dataSource]="userAccessListDataSource"> 

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text"  > Action</p>
          </th>
          <td mat-cell  *matCellDef="let element"><img [src]="element.Image !== null ?   element.imageURL+element.Image : 'assets/images/img_avatar.png'" style="width: 40px;display: block;border-radius: 50%;"/> </td>
        </ng-container>

        <!-- User ID Column -->
        <ng-container matColumnDef="userid">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text"> User ID</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.UserId}}</td>
        </ng-container>

          <!-- User Name Column -->
        <ng-container matColumnDef="username">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text"> User Name</p>
          </th>
          <td mat-cell  *matCellDef="let element">{{element.UserName}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text">Name</p>
          </th>
          <td mat-cell  *matCellDef="let element">{{element.Name}}</td>
        </ng-container>

<!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text">Email</p>
          </th>
          <td mat-cell  *matCellDef="let element" >{{element.Email}} </td>
        </ng-container>

        <!-- Mobile Column -->
        <ng-container matColumnDef="mobile">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text">Mobile</p>
          </th>
          <td mat-cell  *matCellDef="let element">{{element.Mobile}}</td>
        </ng-container>

        <!-- IsGroupAdmin Column -->
        <ng-container matColumnDef="isgroupadmin">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text">IsGroupAdmin</p>
          </th>
          <td mat-cell  *matCellDef="let element" >{{element.IsAdmin}} </td>
        </ng-container>
        <ng-container matColumnDef="createdby">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text">CreatedBy</p>
          </th>
          <td mat-cell  *matCellDef="let element">{{element.CreatedBy}} </td>
        </ng-container>
        <ng-container matColumnDef="createdon">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text">CreatedOn</p>
          </th>
          <td mat-cell  *matCellDef="let element">{{element.CreatedOn}} </td>
        </ng-container>
        <!-- <ng-container matColumnDef="userActions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <button mat-icon-button class="edit-machine" aria-label="Machine Level Access" 
              (click)="openMachineDailog(element.UserId)" pTooltip="Machine Level Access" tooltipPosition="top">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.634,13.591H2.146c-0.247,0-0.449,0.201-0.449,0.448v2.692c0,0.247,0.202,0.449,0.449,0.449h4.488c0.247,0,0.449-0.202,0.449-0.449v-2.692C7.083,13.792,6.881,13.591,6.634,13.591 M6.185,16.283h-3.59v-1.795h3.59V16.283zM6.634,8.205H2.146c-0.247,0-0.449,0.202-0.449,0.449v2.692c0,0.247,0.202,0.449,0.449,0.449h4.488c0.247,0,0.449-0.202,0.449-0.449V8.653C7.083,8.407,6.881,8.205,6.634,8.205 M6.185,10.897h-3.59V9.103h3.59V10.897z M6.634,2.819H2.146c-0.247,0-0.449,0.202-0.449,0.449V5.96c0,0.247,0.202,0.449,0.449,0.449h4.488c0.247,0,0.449-0.202,0.449-0.449V3.268C7.083,3.021,6.881,2.819,6.634,2.819 M6.185,5.512h-3.59V3.717h3.59V5.512z M15.933,5.683c-0.175-0.168-0.361-0.33-0.555-0.479l1.677-1.613c0.297-0.281,0.088-0.772-0.31-0.772H9.336c-0.249,0-0.448,0.202-0.448,0.449v7.107c0,0.395,0.471,0.598,0.758,0.326l1.797-1.728c0.054,0.045,0.107,0.094,0.161,0.146c0.802,0.767,1.243,1.786,1.243,2.867c0,1.071-0.435,2.078-1.227,2.837c-0.7,0.671-1.354,1.086-2.345,1.169c-0.482,0.041-0.577,0.733-0.092,0.875c0.687,0.209,1.12,0.314,1.839,0.314c0.932,0,1.838-0.173,2.673-0.505c0.835-0.33,1.603-0.819,2.262-1.449c1.322-1.266,2.346-2.953,2.346-4.751C18.303,8.665,17.272,6.964,15.933,5.683 M15.336,14.578c-1.124,1.077-2.619,1.681-4.217,1.705c0.408-0.221,0.788-0.491,1.122-0.812c0.97-0.929,1.504-2.168,1.504-3.485c0-1.328-0.539-2.578-1.521-3.516c-0.178-0.17-0.357-0.321-0.548-0.456c-0.125-0.089-0.379-0.146-0.569,0.041L9.769,9.327v-5.61h5.861l-1.264,1.216c-0.099,0.094-0.148,0.229-0.137,0.366c0.014,0.134,0.088,0.258,0.202,0.332c0.313,0.204,0.61,0.44,0.882,0.7c1.158,1.111,2.092,2.581,2.092,4.145C17.405,12.026,16.48,13.482,15.336,14.578"
                    fill="white" />
                </svg>
              </button>
            </div>
          </td>
        </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="userAccessListColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: userAccessListColumns;"></tr>
      </table>
      <mat-paginator
      class="basic-paginataor"
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons>
    </mat-paginator>
    </div>
  </form>
  <p-dialog header="Machine Level Access" [(visible)]="displayMachineDailog" [closable]="true" [style]="{width: '70vw'}" [baseZIndex]="10000" [maximizable]="true" [modal]="true" 
    [resizable]="true" [contentStyle]="{height: '700px'}" styleClass="p-fluid">
  <br />
  
  <form [formGroup]="machineForm" (ngSubmit)="userMachineAccess()" class="forms-sample">
    <div class="tree-controls">
      <button mat-flat-button class="btn-tree-control" (click)="collapseAll()" type="button">
        <div class="icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#824AED" />
            <path
              d="M11.0118 12.9848C10.9331 12.8973 10.8218 12.8402 10.6947 12.8398L7.10183 12.8348C6.86392 12.8344 6.67141 13.0269 6.67183 13.2648C6.67224 13.5027 6.94974 13.7702 7.06891 13.8894L7.97764 14.7982L5.33307 17.4173L6.58307 18.6673L9.18891 16.0098L10.0976 16.9186C10.2943 17.1152 10.4939 17.3244 10.7318 17.3248C10.9697 17.3252 11.1622 17.1327 11.1618 16.8948L11.1568 13.3019C11.1564 13.1748 11.0993 13.0636 11.0118 12.9848ZM16.0764 9.1569L18.6664 6.58398L17.4164 5.33398L14.8443 7.9169L13.9201 6.9869C13.7201 6.78564 13.5172 6.57148 13.2756 6.57107C13.0335 6.57065 12.8381 6.76773 12.8381 7.01106L12.843 10.6886C12.843 10.8186 12.9014 10.9323 12.9906 11.0131C13.071 11.1027 13.1839 11.161 13.3131 11.1615L16.9672 11.1665C17.2089 11.1669 17.4047 10.9698 17.4047 10.7265C17.4043 10.4831 17.1222 10.209 17.001 10.0873L16.0764 9.1569Z"
              fill="white" />
          </svg>
        </div>
        <p class="btn-text">Collapse All</p>
      </button>
      <button mat-flat-button class="btn-tree-control" (click)="expandAll()" type="button">
        <div class="icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#3AAFE4" />
            <path
              d="M11.0118 12.9848C10.9331 12.8973 10.8218 12.8402 10.6947 12.8398L7.10183 12.8348C6.86392 12.8344 6.67141 13.0269 6.67183 13.2648C6.67224 13.5027 6.94974 13.7702 7.06891 13.8894L7.97764 14.7982L5.33307 17.4173L6.58307 18.6673L9.18891 16.0098L10.0976 16.9186C10.2943 17.1152 10.4939 17.3244 10.7318 17.3248C10.9697 17.3252 11.1622 17.1327 11.1618 16.8948L11.1568 13.3019C11.1564 13.1748 11.0993 13.0636 11.0118 12.9848ZM16.0764 9.1569L18.6664 6.58398L17.4164 5.33398L14.8443 7.9169L13.9201 6.9869C13.7201 6.78564 13.5172 6.57148 13.2756 6.57107C13.0335 6.57065 12.8381 6.76773 12.8381 7.01106L12.843 10.6886C12.843 10.8186 12.9014 10.9323 12.9906 11.0131C13.071 11.1027 13.1839 11.161 13.3131 11.1615L16.9672 11.1665C17.2089 11.1669 17.4047 10.9698 17.4047 10.7265C17.4043 10.4831 17.1222 10.209 17.001 10.0873L16.0764 9.1569Z"
              fill="white" />
          </svg>
        </div>
        <p class="btn-text">Expand All</p>
      </button>
    </div>
    <p-tree [value]="machineTree" selectionMode="checkbox" layout="vertical" [(selection)]="selectedMachine" scrollHeight="flex" (onNodeUnselect)="nodeUnselectTree($event)"></p-tree>
  <button style="float: right;" mat-flat-button color="accent"  type="submit" [disabled]="!machineForm.valid"
  class="btn-accent"> Save </button>
  </form> 
 
</p-dialog>

</div>
