import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
// import Highcharts from 'highcharts';
import * as  Highcharts from 'highcharts';

@Component({
  selector: 'app-custom-bottom-sheet',
  templateUrl: './custom-bottom-sheet.component.html',
  styleUrls: ['./custom-bottom-sheet.component.scss']
})
export class CustomBottomSheetComponent implements OnInit, AfterViewInit {
  chartOptionBottom: any;
  Highcharts = Highcharts;
  storedData:any;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private bottomSheetRef: MatBottomSheetRef<CustomBottomSheetComponent>) { }
  ngAfterViewInit(): void {
    this.loadChart();
  }

  loadChart() {
    if (this.storedData) {
      const options = JSON.parse(this.storedData);
      if (options && options.plotOptions) {
        this.chartOptionBottom = options;
      } else {
        console.error('Invalid chart data in localStorage');
        // Initialize with default values or handle the error appropriately
      }
    } else {
      console.error('No chart data found in localStorage');
      // Handle the absence of data: load defaults, show an error, etc.
    }
  }


  ngOnInit(): void {
    this.storedData = localStorage.getItem('chartData');
  }

  close() {
    this.bottomSheetRef.dismiss();
  }
}
