import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ElementRef,
  Input
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import {
  PlanGroupComponent,
  PlantGroupElement,
} from 'src/app/models/O3AdministrationModels/plant-model';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { CILMasterService } from 'src/app/services/cil-master-service';
import { DomSanitizer } from '@angular/platform-browser';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import * as XLSX from 'xlsx';
import { ProductBulkUploadService } from 'src/app/services/product-bulk-upload.service';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { EditBtnRenderer } from 'src/app/edit-action-btn-renderer.component';
import { DeleteBtnRenderer } from 'src/app/delete-action-btn-renderer.component';
import { CustomTooltip } from 'src/app/custom-tooltip.component';
import { BtnCellRendererCilMaster } from 'src/app/btn-cell-rendererCILMaster.component';
import { environment } from 'src/environments/environment';
const moment = _moment;
@Component({
  selector: 'app-cil-configuration',
  templateUrl: './cil-configuration.component.html',
  styleUrls: ['./cil-configuration.component.scss'],
})
export class CilConfigurationComponent implements OnInit {
  @Input() checked: boolean;
  panelOpenState = false;
  typecolumns: string[] = ['plant', 'name', 'remarks', 'action'];
  toolcolumns: string[] = ['plant', 'name', 'order', 'action'];
  frequencycolumns: string[] = [
    'plant',
    'name',
    'order',
    'time',
    'duration',
    'action',
  ];
  taskcolumns: string[] = [
    'line',
    'Machine',
    'assembly',
    'Component',
    'Task',
    'Type',
    'Frequency',
    'time',
    'sop',
    'img',
    'action',
  ];
  CilmainDataForm!: FormGroup;
  CiltypeDataForm!: FormGroup;
  CiltoolDataForm!: FormGroup;
  CilfrequencyDataForm!: FormGroup;
  CiltaskDataForm!: FormGroup;
  updateOrderprocessingData = false;
  groupDataForm!: FormGroup;
  formMode!: boolean;
  displayComponentModal = false;
  MachineId!: any;
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  plantGroupComponentSource = new MatTreeNestedDataSource<PlanGroupComponent>();
  treeControlComponent = new NestedTreeControl<PlanGroupComponent>(
    (node) => node.children
  );
  LineNode: any;
  breadcrumList!: any;
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  parameterType!: any[];
  varUnitName!: string;
  isproductionDBModel = false;
  isPopupMachine = false;
  selectedTool!: string[];
  currentImagePath!: any;
  files: File[] = [];
  moduleId!: any;
  pageId!: any;
  //Node
  UnitNode: any;
  @ViewChild('ImportDialog') ImportDialog!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  raci: any;
  // ImageUrl: any;
  taskFormMode = true;
  components: any;
  filteredcomponents: any;
  filteredUsers: any;
  searchDefectForm: any;
  filteredUnits: any;
  filteredTools!: any[];
  filteredTypes!: any[];
  Line: any;
  units: any;

  TypeList: any = [];
  ToolList: any = [];
  FrequencyList: any = [];
  TaskList: any = [];
  UnitList: any = [];
  ComponentList: any = [];

  TypeMasterData: any = [];
  ToolsMasterData: any = [];
  FrequencyMasterData: any = [];
  TaskMasterData: any = [];

  ExportType: any = [];
  ExportTool: any = [];
  ExportFrequency: any = [];
  ExportTask: any = [];


  user: any;
  dialogRef: any;
  filteredLine: any;
  assembly: any;
  filteredAssembly: any;
  task: any;
  AssemblyList: any;
  invalidType!: any[];
  invalidTool!: any[];
  invalidTask!: any[];
  invalidFrequency!: any[];
  excelData1: any;

  frameworkComponents: any;
  rowData: any = [];
  columnDefsTask!: any[];
  domLayout = "autoHeight";

  gridApi: any;
  gridColumnApi: any;

  gridOptions: any = {
    defaultColDef: {
      paginationPageSize: 50,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- HERE
      // minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
      // tooltipComponent: CustomTooltip,
    },
    rowSelection: 'multiple', 
    // headerHeight: 150,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
  };
  ButtonType: any;
  filteredRows!: any;
  filteredRowsData: any = [];
  disableActive = true ;
  Active  = true ;
  taskSearch = '';
  frequencySearch = '';
  toolSearch = '';
  typeSearch = '';
  constructor(
    private userManagementService: UserManagementService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    public router: Router,
    private platModelService: EnterPriseGroupService,
    private cilMasterService: CILMasterService,
    private senitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private machineService: MachineMappingService,
    private dialog: MatDialog,
    private productBulkUploadService: ProductBulkUploadService,
    public datePipe: DatePipe,
    public commonService: CommonService
  ) {
    this.frameworkComponents = {
      editRenderer: EditBtnRenderer,
      deleteRenderer: DeleteBtnRenderer,
      customTooltip: CustomTooltip,
      btnCellRenderCilMaster: BtnCellRendererCilMaster,
    };
    this.columnDefsTask = [
      {
        headerName: 'Action',
        field: 'action',
        // cellRenderer: 'editRenderer',
        minWidth: 140,
        cellRenderer: 'btnCellRenderCilMaster' ,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        cellRendererParams: {
          clicked(field: any): any { },
        },
        // cellRendererParams: {
        //   onClick: this.editTask.bind(this),
        // }
      },
      {
        headerName: 'Status',
        field: 'Status',
        // cellRenderer: 'editRenderer',
        minWidth: 100,
        filter: true
      } ,
      // {
      //   headerName: 'Delete',
      //   cellRenderer: 'deleteRenderer',
      //   minWidth: 92,
      //   cellRendererParams: {
      //     onClick: this.deleteRecord.bind(this, 'Task'),
      //   }
      // },
      {
        headerName: 'Line', minWidth: 200, field: 'PL_Desc', filter: true , cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'Unit', minWidth: 200, field: 'unitName', filter: true, cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'Assembly', minWidth: 200, field: 'Assbly_Desc', filter: true, cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'Component', minWidth: 200, field: 'componentName', filter: true, cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'Task', minWidth: 350, field: 'taskName', filter: true, cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'Type', minWidth: 150, field: 'cilTypeName', filter:true, cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'Frequency', minWidth: 115, field: 'cilFrequencyName', filter: true, cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'Estimated Time', minWidth: 150, field: 'estimatedTime', filter: true, cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        }
      },
      {
        headerName: 'SOP / OPL', minWidth: 120, field: 'sop', filter: true, cellRenderer: function (params: any) {

          let html = '';
          if (params && params.value) {
            html = `<a target="_blank" class="btn" href="${params.value}" style="width:100%"><i class="fa fa-download"></i> </a>`
          }
          return html;
        }
      },
      {
        headerName: 'Pre Image', minWidth: 150, field: 'preTaskImage', filter: true, cellRenderer: function (params: any) {
          let html = `<img style="width: 50px; height: 50px;" src="assets/images/no-image.png"/>`
          if (params && params.value) {
            html = ''
            html = `<img style="width: 50px; height: 50px;" src="${params.value}"/>`
          }
          return html;
        }
      }
    ];
  }
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;

  GroupList!: any[];
  PlantList!: any[];
  typeList!: MatTableDataSource<any>;
  toolList!: MatTableDataSource<any>;
  frequencyList!: MatTableDataSource<any>;
  taskList!: MatTableDataSource<any>;
  typeDropList!: any[];
  toolDropList!: any[];
  frequencyDropList!: any[];
  TapeNameDDL!: any[];
  UnitName!: string;
  base64PdfString!: any;
  base64ImageString!: any;
  imageName!: File;
  EmptyFile!: File;
  pdfName!: File;
  toolIds: any[] = new Array();
  isPlantGroupModelVisible = false;
  freuencyMode = true;
  typeformMode = true;
  toolformMode = true;
  componentId!: any;
  excelData: any;
  cilMasterData: any = [];
  // parent node
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.CilmainDataForm = this.fb.group({
      group: [''],
      plant: [''],
    });
    this.CiltypeDataForm = this.fb.group({
      id: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      remarks: new FormControl('', []),
    });
    this.CiltoolDataForm = this.fb.group({
      id: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      order: new FormControl('', [Validators.required]),
    });
    this.CilfrequencyDataForm = this.fb.group({
      id: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      time: new FormControl('', [Validators.required]),
      duration: new FormControl('', [Validators.required, Validators.min(1)]),
      order: new FormControl('', [Validators.required]),
    });
    this.CiltaskDataForm = this.fb.group({
      id: [''],
      assembly: new FormControl('', [Validators.required]),
      line: new FormControl('', [Validators.required]),
      unit: new FormControl('', [Validators.required]),
      component: new FormControl('', [Validators.required]),
      task: new FormControl('', [Validators.required]),
      remarks: new FormControl('', []),
      time: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      tools: new FormControl('', [Validators.required]),
      frequency: new FormControl('', [Validators.required]),
      sop: new FormControl('', []),
      img: new FormControl('', []),
      machineId: new FormControl('', []),
      users: new FormControl('', [Validators.required]),
    });

    this.GetMenuAccess();
    this.GetBreadcrumList();
    this.getGroups();
  }
  //================== Form Validation code here =====================//
  public checkErrorType = (controlName: string, errorName: string) => {
    return this.CiltypeDataForm.controls[controlName].hasError(errorName);
  };

  getGroups(): void {
    const key = {
      UserId: 0,
    };
    this.cilMasterService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.dropDownList;
        this.CilmainDataForm.get('group')?.setValue(this.GroupList[0].id);
        this.getPlants(this.GroupList[0].id);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(id: any): void {
    this.taskSearch = '';
    this.frequencySearch = '';
    this.toolSearch = '';
    this.typeSearch = '';
    this.cilMasterService.getPlantsData(id).subscribe(
      (data: any) => {
        this.PlantList = data.dropDownList;
        this.CilmainDataForm.controls.plant.patchValue(data.dropDownList[0].id);
        this.resetType();
        this.resetTool();
        this.resetTask();
        this.resetFrequency();
        this.getTaskList();
        this.getTypeList();
        this.getToolList();
        this.getFrequencyList();
        this.getCilType();
        this.getCilTool();
        this.getCilFrequency();
        this.getUserList();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  plantChange(): void {
    this.taskSearch = '';
    this.resetType();
    this.resetTool();
    this.resetTask();
    this.resetFrequency();
    this.frequencySearch = '';
    this.toolSearch = '';
    this.typeSearch = '';
    this.excelData = [];
    if (this.TypeMasterData) {
      this.TypeMasterData = [];
      this.getTypeList();
      this.getCilType();
    }
    if (this.ToolsMasterData) {
      this.ToolsMasterData = [];
      this.getToolList();
      this.getCilTool();
    }
    if (this.FrequencyMasterData) {
      this.FrequencyMasterData = [];
      this.getFrequencyList();
      this.getCilFrequency();
    }
    if (this.TaskMasterData) {
      this.TaskMasterData = [];
      this.getTaskList();
      this.getUserList();
    }
  }
  getLine(): void {
    const key = {
      PT_ID: this.CilmainDataForm.value.plant,
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.Line = [];
    this.filteredLine = [];
    this.units = [];
    this.filteredUnits = [];
    this.UnitList = [];
    this.assembly = [];
    this.filteredAssembly = [];
    this.components = [];
    this.filteredcomponents = [];
    this.ComponentList = [];
    this.machineService.getLineList(key).subscribe(
      (data: any) => {
        this.Line = data.lstLine;
        this.filteredLine = this.Line.slice();
        this.CiltaskDataForm.get('line')?.setValue(this.Line[0].PL_Id);
        this.getUnits("NormalMode");
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getUnits(status: any): void {
    var selectedLine: any[] = [];
    if (this.CiltaskDataForm.value?.line == 0) {
      for (var i = 0; i < this.Line?.length; i++) {
        selectedLine.push(this.Line[i].PL_Id);
      }
    }
    else {
      selectedLine.push(this.CiltaskDataForm.value?.line)
    }
    const key = {
      PL_ID: selectedLine.toString(),
    };
    this.units = [];
    this.filteredUnits = [];
    this.UnitList = [];
    this.assembly = [];
    this.filteredAssembly = [];
    this.components = [];
    this.filteredcomponents = [];
    this.ComponentList = [];
    this.machineService.getUnitList(key).subscribe(
      (data: any) => {
        this.units = data.lstUnit;
        this.UnitList = data.lstUnit;
        this.filteredUnits = this.units.slice();
        this.CiltaskDataForm.get('unit')?.setValue(this.units[0].PU_Id);
        if (this.task.length !== 0 && status == 'EditMode') {
          this.CiltaskDataForm.get('unit')?.setValue(this.task.unitId);
          this.getAssembly(status);
        }
        else {
          this.getAssembly('NormalMOde');
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getAssembly(status: any): void {
    var selectedUnit: any[] = [];
    if (this.CiltaskDataForm.value?.unit == 0) {
      for (var i = 0; i < this.units?.length; i++) {
        selectedUnit.push(this.units[i].PU_Id);
      }
    }
    else {
      selectedUnit.push(this.CiltaskDataForm.value?.unit)
    }
    const key = {
      PU_ID: selectedUnit.toString(),
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.assembly = [];
    this.filteredAssembly = [];
    this.components = [];
    this.filteredcomponents = [];
    this.ComponentList = [];
    this.machineService.getAssemblyList(key).subscribe(
      (data: any) => {
        this.assembly = data.lstasmb;
        this.filteredAssembly = this.assembly.slice();
        this.CiltaskDataForm.get('assembly')?.setValue(this.assembly[0].Asmb_Id);
        if (this.task.length !== 0 && status == 'EditMode') {
          this.CiltaskDataForm.get('assembly')?.setValue(this.task.Assbly_Id);
          this.getComponents(status);
        }
        else {
          this.getComponents('NormalMode');
        }

      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getComponents(status: any): void {
    var selectedAssemby: any[] = [];
    if (this.CiltaskDataForm.value?.assembly == 0) {
      for (var i = 0; i < this.assembly?.length; i++) {
        selectedAssemby.push(this.assembly[i].Asmb_Id);
      }
    }
    else {
      selectedAssemby.push(this.CiltaskDataForm.value?.assembly)
    }
    const key = {
      A_ID: selectedAssemby.toString(),
    };
    this.components = [];
    this.filteredcomponents = [];
    this.ComponentList = [];
    this.machineService.getComponentListByAssembly(key).subscribe(
      (data: any) => {
        this.components = data.lstComponent;
        this.ComponentList = data.lstComponent;
        this.filteredcomponents = this.components.slice();
        if (this.taskFormMode) {
          this.CiltaskDataForm.get('component')?.setValue(this.components[0].ComponentId);
        }
        if (this.task.length !== 0 && status == 'EditMode') {
          this.CiltaskDataForm.get('component')?.setValue(this.task.componentId);
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  saveType(): any {
    const key = {
      id: this.CiltypeDataForm.value.id,
      egId: this.CilmainDataForm.value.group,
      plantId: this.CilmainDataForm.value.plant,
      typeName: this.CiltypeDataForm.value.name,
      remarks: this.CiltypeDataForm.value.remarks,
      userId: 0,
    };

    if (
      this.CiltypeDataForm.value.id === 0 ||
      this.CiltypeDataForm.value.id === '' ||
      this.CiltypeDataForm.value.id === null ||
      this.CiltypeDataForm.value.id === undefined
    ) {
      this.cilMasterService.insertType(key).subscribe(
        (response: any) => {
          if (response.HasError === false) {
            this.showPopupMsg(response.ErrorMessage);
            this.CiltypeDataForm.reset();
            this.getTypeList();
            this.getCilType();
          }
        },
        (error: any) => {
          this.toaster.error('error', error.message);
        }
      );
    } else {
      this.cilMasterService.updateType(key).subscribe(
        (response: any) => {
          if (response.HasError === false) {
            this.showPopupMsg(response.ErrorMessage);
            this.CiltypeDataForm.reset();
            this.getTypeList();
            this.getCilType();
          }
        },
        (error: any) => {
          this.toaster.error('error', error.message);
        }
      );
    }
    this.typeformMode = true;
  }
  resetType(): void {
    this.CiltypeDataForm.reset();
    this.typeformMode = true;
  }
  editType(element: any): void {
    this.typeformMode = false;
    this.CiltypeDataForm.controls.name.setValue(element.typeName);
    this.CiltypeDataForm.controls.remarks.setValue(element.remarks);
    this.CiltypeDataForm.controls.id.setValue(element.id);
    this.CilmainDataForm.controls.group.setValue(element.egId);
    this.CilmainDataForm.controls.plant.setValue(element.plantId);
  }

  deleteType(element: any): void {
    this.cilMasterService.deleteType(element.id).subscribe(
      (response: any) => {
        if (response.HasError === false) {
          this.showPopupMsg(response.ErrorMessage);
          this.getTypeList();
          this.getCilType();
          this.resetType();
        }
      },
      (error: any) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }
  deleteRecord(panelType: any, element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete ' + panelType + ' Record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        if (panelType === 'Type') {
          this.deleteType(element);
        } else if (panelType === 'Tool') {
          this.deleteTool(element);
        } else if (panelType === 'Frequency') {
          this.deleteFrequency(element);
        } else if (panelType === 'Task') {
          // element = element
          this.deleteTask(element);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Record is safe :)', 'error');
      }
    });
  }

  getTypeList() {
    const key = {
      egId: this.CilmainDataForm.value.group,
      plantId: this.CilmainDataForm.value.plant,
    };
    this.cilMasterService.getCILTypeData(key).subscribe(
      (response: any) => {
        if (response.HasError === false) {
          this.typeList = response.GetCILMasterTypeList;
          this.TypeList = response.GetCILMasterTypeList;
          this.typeList = new MatTableDataSource<any>(
            response.GetCILMasterTypeList
          );
          this.getPaginator(this.typeList, 0);
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }


  //=================================================== Master Tool =============================================//
  saveTool() {
    const key = {
      id: this.CiltoolDataForm.value.id,
      egId: this.CilmainDataForm.value.group,
      plantId: this.CilmainDataForm.value.plant,
      toolOrder: this.CiltoolDataForm.value.order,
      toolName: this.CiltoolDataForm.value.name,
      userId: 0,
    };

    if (
      this.CiltoolDataForm.value.id === 0 ||
      this.CiltoolDataForm.value.id === '' ||
      this.CiltoolDataForm.value.id === null ||
      this.CiltoolDataForm.value.id === undefined
    ) {
      this.cilMasterService.insertTool(key).subscribe(
        (response: any) => {
          if (response.HasError === false) {
            this.showPopupMsg(response.ErrorMessage);
            this.CiltoolDataForm.reset();
            this.getToolList();
            this.getCilTool();
          }
        },
        (error: any) => {
          this.showPopupMsg(error.ErrorMessage);
        }
      );
    } else {
      this.cilMasterService.updateTool(key).subscribe(
        (response: any) => {
          if (response.HasError === false) {
            this.showPopupMsg(response.ErrorMessage);
            this.CiltoolDataForm.reset();
            this.getToolList();
            this.getCilTool();
          }
        },
        (error: any) => {
          this.toaster.error('error', error.message);
        }
      );
    }
    this.toolformMode = true;
  }
  resetTool(): void {
    this.CiltoolDataForm.reset();
    this.toolformMode = true;
  }

  editTool(element: any) {
    this.toolformMode = false;
    this.CiltoolDataForm.controls.name.setValue(element.toolName);
    this.CiltoolDataForm.controls.order.setValue(element.toolOrder);
    this.CiltoolDataForm.controls.id.setValue(element.id);
    this.CilmainDataForm.controls.group.setValue(element.egId);
    this.CilmainDataForm.controls.plant.setValue(element.plantId);
  }

  deleteTool(element: any): void {
    this.cilMasterService.deleteTool(element.id).subscribe(
      (response: any) => {
        if (response.HasError === false) {
          this.showPopupMsg(response.ErrorMessage);
          this.getToolList();
          this.getCilTool();
          this.resetTool();
        }
      },
      (error: any) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }
  getUserList(): void {
    const key = {
      EGId: this.CilmainDataForm.value.group,
      PlantId: this.CilmainDataForm.value.plant,
    };
    this.commonService.getPeopleMasterEmpData(key).subscribe((res: any) => {
      this.raci = res.employeeInfoList.filter((x: any) => x.UserId!=0);
      this.filteredUsers = this.raci.slice();
    },
      (error) => {
        this.toaster.error('Error', error.message);
      });
  }
  getToolList(): void {
    const key = {
      egId: this.CilmainDataForm.value.group,
      plantId: this.CilmainDataForm.value.plant,
    };
    this.cilMasterService.getCILToolData(key).subscribe(
      (response: any) => {
        if (response.HasError === false) {
          this.toolList = response.GetCILMasterToolsList;
          this.ToolList = response.GetCILMasterToolsList;
          this.toolList = new MatTableDataSource<any>(
            response.GetCILMasterToolsList
          );
          this.getPaginator(this.toolList, 1);
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  //=====================================================End Tool================================================//

  // ===================================================== Master Frquency ==========================================//
  saveFrequency(): void {
    const key = {
      id: this.CilfrequencyDataForm.value.id,
      egId: this.CilmainDataForm.value.group,
      plantId: this.CilmainDataForm.value.plant,
      order: this.CilfrequencyDataForm.value.order,
      frequencyName: this.CilfrequencyDataForm.value.name,
      nextTime: this.CilfrequencyDataForm.value.time,
      referenceTime: this.CilfrequencyDataForm.value.duration,
      userId: 0,
    };
    if (
      this.CilfrequencyDataForm.value.id === 0 ||
      this.CilfrequencyDataForm.value.id === '' ||
      this.CilfrequencyDataForm.value.id === null
    ) {
      this.cilMasterService.insertFrequency(key).subscribe(
        (response: any) => {
          if (response.HasError === false) {
            this.showPopupMsg(response.ErrorMessage);
            this.CilfrequencyDataForm.reset();
            this.getFrequencyList();
            this.getCilFrequency();
          }
        },
        (error: any) => {
          this.toaster.error('error', error.message);
        }
      );
    } else {
      this.cilMasterService.updateFrequency(key).subscribe(
        (response: any) => {
          if (response.HasError === false) {
            this.showPopupMsg(response.ErrorMessage);
            this.CilfrequencyDataForm.reset();
            this.getFrequencyList();
            this.getCilFrequency();
          }
        },
        (error: any) => {
          this.toaster.error('error', error.message);
        }
      );
    }
    this.freuencyMode = true;
  }

  resetFrequency(): void {
    this.CilfrequencyDataForm.reset();
    this.freuencyMode = true;
  }
  editFrequency(element: any): void {
    this.freuencyMode = false;
    this.CilfrequencyDataForm.controls.name.setValue(element.frequencyName);
    this.CilfrequencyDataForm.controls.order.setValue(element.order);
    this.CilfrequencyDataForm.controls.time.setValue(element.nextTime);
    this.CilfrequencyDataForm.controls.duration.setValue(element.referenceTime);
    this.CilfrequencyDataForm.controls.id.setValue(element.id);
    this.CilmainDataForm.controls.group.setValue(element.egId);
    this.CilmainDataForm.controls.plant.setValue(element.plantId);
  }

  deleteFrequency(element: any): void {
    this.cilMasterService.deleteFrequency(element.id).subscribe(
      (response: any) => {
        if (response.HasError === false) {
          this.showPopupMsg(response.ErrorMessage);
          this.getFrequencyList();
          this.getCilFrequency();
          this.resetFrequency();
        }
      },
      (error: any) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }
  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
      this.toaster.success('Success', msg.substring(3, msg.length));
    } else {
      this.toaster.warning('Warning', msg.substring(3, msg.length));
    }
  }
  getFrequencyList() {
    const key = {
      egId: this.CilmainDataForm.value.group,
      plantId: this.CilmainDataForm.value.plant,
    };
    this.cilMasterService.getCILFrequencyData(key).subscribe(
      (response: any) => {
        if (response.HasError === false) {
          this.frequencyList = response.GetCILMasterFrequencyList;
          this.FrequencyList = response.GetCILMasterFrequencyList;
          this.frequencyList = new MatTableDataSource<any>(
            response.GetCILMasterFrequencyList
          );
          this.getPaginator(this.frequencyList, 2);
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  // ====================================================== End Frequency ============================================//

  // ======================================================== Task ==================================================//
  estimateTime(eve: any): void {
    if (this.CiltaskDataForm.value.frequency) {
      const data = this.frequencyList.filteredData.find(
        (c) => c.id === this.CiltaskDataForm.value.frequency
      );
      if (parseInt(eve.target.value, 10) >= data.referenceTime) {
        this.CiltaskDataForm.get('time')?.setValue('');
        this.toaster.warning(
          'Estimated time should be less then from frequnecy duration'
        );
      }
    } else {
      this.toaster.warning('Please Select frequnecy');
      this.CiltaskDataForm.get('time')?.setValue('');
    }
  }
  saveTask(): void {
    const formData: any = new FormData();
    formData.append('Imagefile', this.imageName);
    formData.append('pdffile', this.pdfName);
    formData.append(
      'obj',
      JSON.stringify({
        typeId: this.CiltaskDataForm.value.type.toString(),
        taskName: this.CiltaskDataForm.value.task,
        remarks: this.CiltaskDataForm.value.remarks,
        frequencyId: this.CiltaskDataForm.value.frequency.toString(),
        toolIds: this.CiltaskDataForm.value.tools,
        estimatedTime: this.CiltaskDataForm.value.time.toString(),
        egId: this.CilmainDataForm.value.group.toString(),
        plantId: this.CilmainDataForm.value.plant.toString(),
        userId: 0,
        machineId: this.CiltaskDataForm.value.unit.toString(),
        componentId: this.CiltaskDataForm.value.component.toString(),
        createdOn: new Date(),
        userids: this.CiltaskDataForm.value.users,
        id:
          this.CiltaskDataForm.value.id === '' ||
            this.CiltaskDataForm.value.id === null
            ? 0
            : this.CiltaskDataForm.value.id,
      })
    );
    if (
      this.CiltaskDataForm.value.id === 0 ||
      this.CiltaskDataForm.value.id === '' ||
      this.CiltaskDataForm.value.id === null
    ) {
      this.cilMasterService.insertTask(formData).subscribe(
        (response: any) => {
          if (response.HasError === false) {
            this.toaster.success('success', 'Task Added Successfully');
            this.CiltaskDataForm.reset();
            this.taskFormMode = true;
            this.imageName = this.EmptyFile;
            this.pdfName = this.EmptyFile;
            this.getTaskList();
          } else {
            if (response.ErrorMessage === "Column 'LastID' does not belong to table .") {
              this.toaster.warning('warning', 'Task Already Exist');
            } else if (response.ErrorMessage === "Maximum request length exceeded.") {
              this.toaster.error('error', "Maximum Upload Limit Exceeded");
            }
          }
        },
        (error: any) => {
          this.toaster.error('error', error.ErrorMessage);
        }
      );
    } else {
      this.cilMasterService.updateTask(formData).subscribe(
        (response: any) => {
          if (response.HasError === false) {
            this.toaster.success('Success ', 'Task Updated Successfully');
            this.CiltaskDataForm.reset();
            this.taskFormMode = true;
            this.imageName = this.EmptyFile;
            this.pdfName = this.EmptyFile;
            this.getTaskList();
          }
          else if (response.ErrorMessage === "Maximum request length exceeded.") {
            this.toaster.error('error', "Maximum Upload Limit Exceeded");
          }
        },
        (error: any) => {
          this.toaster.error('error', error.ErrorMessage);
        }
      );
    }
  }
  resetTask(): void {
    this.CiltaskDataForm.reset();
    this.taskFormMode = true;
    // this.Line = [];
    // this.filteredLine = [];
    // this.units = [];
    // this.filteredUnits = [];
    // this.UnitList = [];
    // this.assembly = [];
    // this.filteredAssembly = [];
    // this.components = [];
    // this.filteredcomponents = [];
    // this.ComponentList = [];
    this.task = [];
  }
  async getTaskList() {
    this.TaskList = [];
    const key = {
      egId: this.CilmainDataForm.value.group,
      plantId: this.CilmainDataForm.value.plant,
    };
    this.spinner.show();
    this.cilMasterService.getCILTaskData(key).subscribe(
      (response: any) => {
        if (response.HasError === false) {
          this.TaskList = response.GetCILList;
          this.taskList = response.GetCILList;
          for (const tk of this.TaskList) {
            tk.IsWrite = this.IsWrite;
            tk.sop = tk && tk.sop ? environment.O3CoreApiImageUrl + tk.SopFileUrl : '';
            tk.preTaskImage = tk && tk.preTaskImage ? environment.O3CoreApiImageUrl + tk.PreTaskImageUrl : '';
          }
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();
      }
    );
    await this.delay(2000);
    this.resetTask();
    this.getLine();
  }
  editTask(element: any): void {
    this.task = element;
    this.CiltaskDataForm.get('line')?.setValue(element.PL_Id);
    this.getUnits("EditMode");
    const namePdf = element.sop?.split('/');
    this.taskFormMode = false;
    this.CiltaskDataForm.get('id')?.setValue(element.id);
    this.CiltaskDataForm.get('task')?.setValue(element.taskName);
    this.CiltaskDataForm.get('time')?.setValue(element.estimatedTime);
    this.CiltaskDataForm.get('type')?.setValue(element.cilTypeId);
    this.CiltaskDataForm.get('frequency')?.setValue(element.cilFrequencyId);
    this.CilmainDataForm.get('group')?.setValue(element.egId);
    this.CilmainDataForm.get('plant')?.setValue(element.plantId);
    this.CiltaskDataForm.get('users')?.setValue(
      element.edtUserIds.split`,`.map((x: any) => +x)
    );
    this.CiltaskDataForm.get('remarks')?.setValue(element.remarks);
    this.CiltaskDataForm.get('sop')?.setValue(element.sop);
    this.CiltaskDataForm.get('img')?.setValue(namePdf !== undefined ? namePdf[1] : '');
    this.componentId = element.componentId;
    this.MachineId = element.unitId;
    this.selectedTool = element.tools.map((item: any) => item.cilToolId);
    this.CiltaskDataForm.get('unit')?.setValue(element.unitId);
    this.imageName = (element.preTaskImage !== '') ? element.preTaskImage : 'assets/images/no-image.png';
    this.pdfName = (element.sop !== '') ? element.sop : '';
  }
  deleteTask(element: any): void {
    this.cilMasterService.deleteCILTaskData(element.id).subscribe(
      (response: any) => {
        if (response.HasError === false) {
          this.showPopupMsg(response.ErrorMessage);
          this.getTaskList();
        }
      },
      (error: any) => {
        this.showPopupMsg(error.ErrorMessage);
      }
    );
  }

  //=========================================================End Task=================================================//

  getCilType() {
    const key = {
      egId: this.CilmainDataForm.value.group,
      plantId: this.CilmainDataForm.value.plant,
    };
    this.cilMasterService.getCILTypeList(key).subscribe(
      (response: any) => {
        if (response.HasError === false) {
          this.typeDropList = response.dropDownList;
          this.filteredTypes = this.typeDropList.slice();
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getCilTool() {
    const key = {
      egId: this.CilmainDataForm.value.group,
      plantId: this.CilmainDataForm.value.plant,
    };
    this.cilMasterService.getCILToolList(key).subscribe(
      (response: any) => {
        if (response.HasError === false) {
          this.toolDropList = response.dropDownList;
          this.filteredTools = this.toolDropList.slice();
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getCilFrequency() {
    const key = {
      egId: this.CilmainDataForm.value.group,
      plantId: this.CilmainDataForm.value.plant,
    };
    this.cilMasterService.getCILFrequencyList(key).subscribe(
      (response: any) => {
        if (response.HasError === false) {
          this.frequencyDropList = response.dropDownList;
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  transform(base64: any) {
    return this.senitizer.bypassSecurityTrustResourceUrl(base64);
  }
  imageZoomModal(imagePath: String): void {
    this.currentImagePath = imagePath.indexOf('/null') > -1 ? null : imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  cellClicked(params: any): void {
    const col = params.colDef.field;
    if(params.data.Status === 'Active' && this.filteredRowsData?.length === 0)
    {
      this.Active = true;
    }
    else if(params.data.Status === 'InActive'  && (this.filteredRowsData?.length === 0 || this.filteredRowsData === undefined
       ))
    {
      this.Active = false;
    }
    if (col === 'preTaskImage') {
      if (params && params.value) {
        this.imageZoomModal(params.value);
      }
      else {
        this.imageZoomModal('assets/images/no-image.png');
      }
    }
    if (col === 'action') {
      this.ButtonType = params.event.target.textContent;

      if (this.ButtonType === 'edit') {
        this.editTask(params.data);
      }
      if (this.ButtonType === 'delete') {
        if(!params.data.Executed)
        {
          this.deleteRecord('Task', params.data);
        }
      }
    }
  }

  // multiple select
  change(event: any) {
    if (event.source.selected) {
      this.toolIds.push(event.source.value.toString());
    } else {
      this.toolIds.forEach((obj: any, index: number) => {
        if (obj === event.source.value) {
          this.toolIds.splice(index, 1);
        }
      });
    }
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  getPaginator(dataSource: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataSource.paginator = this.paginator.toArray()[index];
      dataSource.sort = this.sort.toArray()[index];
    }, 1000);
  }

  applyFilterForType(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.typeList.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForTool(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.toolList.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForFrequency(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.frequencyList.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForTask(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.taskList.filter = filterValue.trim().toLowerCase();
  }

  resetForm(form: FormGroup, value: any) {
    form.get(value)?.setValue('');
  }

  SelectUnitName(unitname: any): void {
    if (unitname.text !== null) {
      this.CiltaskDataForm.get('unit')?.setValue(unitname.text);
      this.CiltaskDataForm.get('unit')?.setValue(unitname.PrimaryId);
      this.isPlantGroupModelVisible = false;
    }
  }
  SelectComponent(component: any): void {
    if (component.text !== null) {
      this.CiltaskDataForm.get('component')?.setValue(component.text);
      this.componentId = component.id;
      this.displayComponentModal = false;
    }
  }
  resetUnit(): void {
    this.CiltaskDataForm.get('unit')?.reset();
    this.CiltaskDataForm.controls.unit.setValue(null);
    this.CiltaskDataForm.controls.component.reset();
  }
  openUnit(): void {
    this.isproductionDBModel = true;
    this.isPopupMachine = false;
    const key = {
      EGId: this.CilmainDataForm.value.group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };
    this.platModelService.getTreeModal(key).subscribe(
      (data: any) => {
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // close model
  closePlantGroupModel(): void {
    this.isproductionDBModel = false;
  }
  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  onNodeSelect(node: any): void {
    this.isproductionDBModel = false;
    this.CiltaskDataForm.get('unit')?.setValue(node.text);
  }
  opensideComponentModal(): void {
    if (this.CiltaskDataForm.controls.unit.value) {
      this.formMode = true;
      this.displayComponentModal = true;
      const key = {
        ParentId: this.CilmainDataForm.controls.group.value,
        PlantId: this.CilmainDataForm.controls.plant.value,
        MachineId: this.CiltaskDataForm.controls.unit.value,
      };
      this.platModelService.getTreeModalComponent(key).subscribe(
        (data: any) => {
          const plantGroupData: PlanGroupComponent[] = data.Tree;
          this.plantGroupComponentSource.data = plantGroupData;
          this.treeControlComponent.dataNodes =
            this.plantGroupComponentSource.data;
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      this.toaster.error('Error', 'Please select Unit');
    }
  }

  closeaddNewPlantItemModel(): void {
    this.displayComponentModal = false;
  }

  handleFileSelect(event: any, type: any): void {
    if (type === 'image') {
      this.imageName = event.target.files[0];
    } else {
      this.pdfName = event.target.files[0];
    }
    event.target.value = null;
    this.EmptyFile = event.target.files;
  }
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }
  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  //date formatter
  dateAsYYYYMMDDHHNNSS(date: any): string {
    return date.getFullYear()
      + '-' + this.leftpad(date.getMonth() + 1, 2)
      + '-' + this.leftpad(date.getDate(), 2)
      + ' ' + this.leftpad(date.getHours(), 2)
      + ':' + this.leftpad(date.getMinutes(), 2) + ':00'
  }
  leftpad(val: any, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
      + String(val)).slice(String(val).length);
  }

  onFilterChanged(event: any) {
    this.gridOptions.api.setQuickFilter(event.value);

  }
  /// AG Grid 
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  ImportToExcel() {
    if (this.CilmainDataForm.valid) {
      this.dialogRef = this.dialog.open(this.ImportDialog, { width: '500px' });
      this.dialogRef.afterClosed().subscribe((result: any) => {
      });
    }
    else {
      this.toaster.error(`Group And Plant Fields Required`, 'Error', {
        timeOut: 3000,
      });
      return;
    }
  }

  ExportTOExcel() {
    this.ExportType = [];
    this.ExportTool = [];
    this.ExportFrequency = [];
    this.ExportTask = [];
    if (this.CilmainDataForm.valid) {
      for (const type of this.TypeList) {
        const ExportData = { ['TypeName *']: type.typeName, ['Remarks']: type.remarks };
        this.ExportType.push(ExportData);
      }
      for (const tool of this.ToolList) {
        const ExportData = { ['ToolName *']: tool.toolName, ['Order *']: tool.toolOrder };
        this.ExportTool.push(ExportData);
      }
      for (const frequency of this.FrequencyList) {
        let date: any = this.datePipe.transform(frequency.nextTime, 'yyyy/MM/dd HH:mm');
        date = '*'.concat(date);
        const ExportData = {
          ['FrequencyName *']: frequency.frequencyName,
          ['Reference Time (*YYYY/MM/DD HH:mm)']: date,
          ['Duration / Min *']: frequency.referenceTime,
          ['Order *']: frequency.order
        };
        this.ExportFrequency.push(ExportData);
      }
      for (const task of this.TaskList) {
        let toolName: any = ''; let toolobj: any; let userobj: any; let userName: any = '';
        for (const tool of task.tools) {
          toolobj = this.ToolList.find((tol: any) => tol.id == tool.cilToolId);
          toolName += toolobj ? toolobj.toolName + ',' : '';
        }
        let userData = task.edtUserIds.split(',')
        for (const user of userData) {
          userobj = this.raci.find((usr: any) => usr.UserId == user.trim());
          userName += userobj ? userobj.FirstName.trim() + ' ' + userobj.lastName.trim() + ',' : '';
        }

        toolName = toolName.substring(0, toolName.length - 1);
        userName = userName.substring(0, userName.length - 1);

        const ExportData = {
          ['Line *']: task.PL_Desc,
          ['Unit *']: task.unitName,
          ['Assembly *']: task.Assbly_Desc,
          ['Component *']: task.componentName,
          ['Task *']: task.taskName,
          ['Remarks']: task.remarks,
          ['Frequency *']: task.cilFrequencyName,
          ['Estimated Time *']: task.estimatedTime,
          ['Type *']: task.cilTypeName,
          ['Tools * ( Enter Values Separated By comma(,) )']: toolName,
          ['Users * ( Enter Values Separated By comma(,) )']: userName,
        };
        this.ExportTask.push(ExportData);
      }
      const workBook = XLSX.utils.book_new(); // create a new blank book

      this.ExportType = this.ExportType && this.ExportType.length ? this.ExportType : [{ ['TypeName *']: '', ['Remarks']: '' }];
      this.ExportTool = this.ExportTool && this.ExportTool.length ? this.ExportTool : [{ ['ToolName *']: '', ['Order *']: '' }];
      this.ExportFrequency = this.ExportFrequency && this.ExportFrequency.length ? this.ExportFrequency : [{
        ['FrequencyName *']: '',
        ['Reference Time (*YYYY/MM/DD HH:mm)']: '',
        ['Duration / Min *']: '',
        ['Order *']: ''
      }];
      this.ExportTask = this.ExportTask && this.ExportTask.length ? this.ExportTask : [{
        ['Line *']: '',
        ['Unit *']: '',
        ['Assembly *']: '',
        ['Component *']: '',
        ['Task *']: '',
        ['Remarks']: '',
        ['Frequency *']: '',
        ['Estimated Time *']: '',
        ['Type *']: '',
        ['Tools * ( Enter Values Separated By comma(,) )']: '',
        ['Users * ( Enter Values Separated By comma(,) )']: '',
      }];


      const TypeSheet = XLSX.utils.json_to_sheet(this.ExportType);
      const ToolSheet = XLSX.utils.json_to_sheet(this.ExportTool);
      const FrequencySheet = XLSX.utils.json_to_sheet(this.ExportFrequency);
      const TaskSheet = XLSX.utils.json_to_sheet(this.ExportTask);

      XLSX.utils.book_append_sheet(workBook, TypeSheet, 'Type');
      XLSX.utils.book_append_sheet(workBook, ToolSheet, 'Tools');
      XLSX.utils.book_append_sheet(workBook, FrequencySheet, 'Frequency');
      XLSX.utils.book_append_sheet(workBook, TaskSheet, 'Task');
      XLSX.writeFile(workBook, `Export_CIL_Configuration.xlsx`); // initiate a file   
    }
    else {
      this.toaster.warning('Group And Plant Fields Required', 'Warning');
      return;
    }
  }

  invalidDataSheet(){
    this.ExportType = [];
    this.ExportTool = [];
    this.ExportFrequency = [];
    this.ExportTask = [];
    if (this.CilmainDataForm.valid) {
      let ityp = 0, itol = 0, ifreq = 0, itsk = 0;
      for (const type of this.excelData1.Type) {
        const ExportData = { ['TypeName *']: type['TypeName *'], ['Remarks']: type['Remarks'] };
        if(this.invalidType[ityp] == type['__rowNum__']){
          if(this.invalidType.length != this.ExportType.length){
            this.ExportType.push(ExportData);
            ityp++;
          }
        } 
      }
      for (const tool of this.excelData1.Tools) {
        const ExportData = { ['ToolName *']: tool['ToolName *'], ['Order *']: tool['Order *'] };
        if(this.invalidTool[itol] == tool['__rowNum__']){
          if(this.invalidTool.length != this.ExportTool.length){
            this.ExportTool.push(ExportData);
            itol++;
          }
        }
      }
      for (const frequency of this.excelData1.Frequency) {
        const ExportData = { 
          ['FrequencyName *']: frequency['FrequencyName *'], 
          ['Reference Time (*YYYY/MM/DD HH:mm)']: frequency['Reference Time (*YYYY/MM/DD HH:mm)'],
          ['Duration / Min *']: frequency['Duration / Min *'], 
          ['Order *']: frequency['Order *'] 
        };
        if(this.invalidFrequency[ifreq] == frequency['__rowNum__']){
          if(this.invalidFrequency.length != this.ExportFrequency.length){
            this.ExportFrequency.push(ExportData);
            ifreq++;
          }        
        }
      }
      for (const task of this.excelData1.Tasks) {
        const ExportData = { 
          ['Line *']: task['Line *'],
          ['Unit *']: task['Unit *'], 
          ['Assembly *']: task['Assembly *'],
          ['Component *']: task['Component *'], 
          ['Task *']: task['Task *'], 
          ['Remarks']: task['Remarks'],
          ['Frequency *']: task['Frequency *'], 
          ['Estimated Time *']: task['Estimated Time *'],
          ['Type *']: task['Type *'], 
          ['Tools * ( Enter Values Separated By comma(,) )']: task['Tools * ( Enter Values Separated By comma(,) )'],
          ['Users * ( Enter Values Separated By comma(,) )']: task['Users * ( Enter Values Separated By comma(,) )'], 
        };
        if(this.invalidTask[itsk] == task['__rowNum__']){
          if(this.invalidTask.length != this.ExportTask.length){
            this.ExportTask.push(ExportData);
            itsk++;
          }    
        }
      }
      const workBook = XLSX.utils.book_new(); // create a new blank book

      this.ExportType = this.ExportType && this.ExportType.length ? this.ExportType : [{ ['TypeName *']: '', ['Remarks']: '' }];
      this.ExportTool = this.ExportTool && this.ExportTool.length ? this.ExportTool : [{ ['ToolName *']: '', ['Order *']: '' }];
      this.ExportFrequency = this.ExportFrequency && this.ExportFrequency.length ? this.ExportFrequency : [{ 
        ['FrequencyName *']: '', 
        ['Reference Time (*YYYY/MM/DD HH:mm)']: '',
        ['Duration / Min *']: '', 
        ['Order *']: '' 
      }];
      this.ExportTask = this.ExportTask && this.ExportTask.length ? this.ExportTask : [{ 
        ['Line *']: '',
        ['Unit *']: '',
        ['Assembly *']: '',
        ['Component *']: '', 
        ['Task *']: '', 
        ['Remarks']: '',
        ['Frequency *']: '', 
        ['Estimated Time *']: '',
        ['Type *']: '', 
        ['Tools * ( Enter Values Separated By comma(,) )']: '',
        ['Users * ( Enter Values Separated By comma(,) )']: '', 
      }];


      const TypeSheet = XLSX.utils.json_to_sheet(this.ExportType);
      const ToolSheet = XLSX.utils.json_to_sheet(this.ExportTool);
      const FrequencySheet = XLSX.utils.json_to_sheet(this.ExportFrequency);
      const TaskSheet = XLSX.utils.json_to_sheet(this.ExportTask);

      XLSX.utils.book_append_sheet(workBook, TypeSheet, 'Type');
      XLSX.utils.book_append_sheet(workBook, ToolSheet, 'Tools');
      XLSX.utils.book_append_sheet(workBook, FrequencySheet, 'Frequency');
      XLSX.utils.book_append_sheet(workBook, TaskSheet, 'Task');
      XLSX.writeFile(workBook, `Export_CIL_Configuration_Invalid.xlsx`); // initiate a file   
      this.excelData1 = [];

    }
    else {
      this.toaster.warning('Group And Plant Fields Required', 'Warning');
      return;
    }
  }

  openFile() {
    this.fileInput.nativeElement.click()
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onReadExcel(event: any) {
    if(this.CilmainDataForm.invalid){
      this.toaster.error(`Group And Plant Fields Required`, 'Error', {
        timeOut: 3000,
      });
    }
    else{
      this.excelData = event.target.files[0];
      this.excelData1 = event.target.files[0];
      const fileName = this.excelData.name;
      let fileReader = new FileReader();
      let fileReader1 = new FileReader();
      fileReader.readAsArrayBuffer(this.excelData);
      fileReader1.readAsArrayBuffer(this.excelData1);
      fileReader.onload = (e) => {
        let arrayBuffer: any = fileReader.result;
        var data = new Uint8Array(arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary", cellDates: true });
        for (var i = 0; i < workbook.SheetNames.length; i++) {
          var sheet_name = workbook.SheetNames[i];
          var worksheet = workbook.Sheets[sheet_name];
          if (sheet_name == "Type"){
            this.excelData.Type = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          }
          else if (sheet_name == "Tools"){
            this.excelData.Tools = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          }
          else if (sheet_name == "Frequency"){
            this.excelData.Frequency = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          }
          else if (sheet_name == "Task"){
            this.excelData.Tasks = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          }
        }
        this.validateType();
      }
      fileReader1.onload = (e) => {
        let arrayBuffer: any = fileReader.result;
        var data = new Uint8Array(arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary", cellDates: true });
        for (var i = 0; i < workbook.SheetNames.length; i++) {
          var sheet_name = workbook.SheetNames[i];
          var worksheet = workbook.Sheets[sheet_name];
          if (sheet_name == "Type"){
            this.excelData1.Type = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          }
          else if (sheet_name == "Tools"){
            this.excelData1.Tools = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          }
          else if (sheet_name == "Frequency"){
            this.excelData1.Frequency = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          }
          else if (sheet_name == "Task"){
            this.excelData1.Tasks = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          }
        }
        this.dialogRef.close();
      }
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  async validateType(){
    let upload = 0;
    let TypeRow = 1;
    let invalidType: any[] = [];
    if (this.excelData && this.excelData.Type && this.excelData.Type.length) {
      for (let obj of this.excelData.Type) {
  
        if (!obj["TypeName *"]) {
          invalidType.push(TypeRow);
        }
        else {  
          const TypeData: any = {
            id:"",
            egId: this.CilmainDataForm.value.group,
            plantId: this.CilmainDataForm.value.plant,
            remarks: "",
            typeName: "",
            userId: 0
          };
  
          let uniqueData = this.TypeList.find((typ: any) => {
            const a = obj["TypeName *"].toLowerCase();
            const b = typ.typeName.toLowerCase();
            return a == b
          });
          if (!uniqueData) {
            TypeData.typeName = obj["TypeName *"];
            if (TypeRow != 1) {
              let existTypeXLData = this.TypeMasterData.find((typmas: any) => {
                return typmas.typeName == TypeData.typeName
              });
              if (existTypeXLData) {
                invalidType.push(TypeRow);
              }
              else{
                TypeData.remarks = obj["Remarks"] ?? '';
                TypeData.createdOn = new Date()
                TypeData.createdOn = this.datePipe.transform(TypeData.createdOn, 'yyyy-MM-dd HH:mm:ss');
                this.TypeMasterData.push(TypeData);
                upload = 1;
              }
            }
            else{
              TypeData.remarks = obj["Remarks"] ?? '';
                TypeData.createdOn = new Date()
                TypeData.createdOn = this.datePipe.transform(TypeData.createdOn, 'yyyy-MM-dd HH:mm:ss');
                this.TypeMasterData.push(TypeData);
                upload = 1;
            }
          }
          else {
            invalidType.push(TypeRow);
          }
  
        }
        TypeRow++;
      }
      if(TypeRow == this.excelData.Type.length+1){
        this.invalidType = invalidType;
        this.validateTool(upload);
      }
    }
    else{
      this.validateTool(upload);
    }
  }
  
  async validateTool(status: any){
    let upload = status;
    let ToolsRow = 1;
    let invalidtool: any[] = [];
    if (this.excelData && this.excelData.Tools && this.excelData.Tools.length) {
      for (let obj of this.excelData.Tools) {
        if (!obj["ToolName *"]) {
          invalidtool.push(ToolsRow);
        }
        else if (!obj["Order *"] || typeof obj['Order *'] != "number") {
          invalidtool.push(ToolsRow);
        }
        else {
  
          const ToolsData: any = {
            id: "",
            egId: this.CilmainDataForm.value.group,
            plantId: this.CilmainDataForm.value.plant,
            toolName: "",
            toolOrder: "",
            userId: 0
          };
          let uniqueData = this.ToolList.find((tol: any) => {
            const a = obj["ToolName *"].toLowerCase();
            const b = tol.toolName.toLowerCase();
            return a == b
          });
          if (!uniqueData) {
            ToolsData.toolName = obj["ToolName *"];
            if (ToolsRow != 1) {
              let existTypeXLData = this.ToolsMasterData.find((tolmas: any) => {
                return tolmas.toolName == ToolsData.toolName
              });
              if (existTypeXLData) {
                invalidtool.push(ToolsRow);
              }
              else{
                ToolsData.toolOrder = obj["Order *"];
                ToolsData.createdOn = new Date()
                ToolsData.createdOn = this.datePipe.transform(ToolsData.createdOn, 'yyyy-MM-dd HH:mm:ss');
                this.ToolsMasterData.push(ToolsData); 
                upload = 1;
              }
            }
            else{
              ToolsData.toolOrder = obj["Order *"];
              ToolsData.createdOn = new Date()
              ToolsData.createdOn = this.datePipe.transform(ToolsData.createdOn, 'yyyy-MM-dd HH:mm:ss');
              this.ToolsMasterData.push(ToolsData); 
              upload = 1;
            }         
          }
          else {
            invalidtool.push(ToolsRow);
          }
  
        }
        ToolsRow++;
      }
      if(ToolsRow == this.excelData.Tools.length +1){
        this.invalidTool = invalidtool;
        this.validateFrequency(upload);
      }
    }
    else{
      this.validateFrequency(upload);
    }
  }
  
  async validateFrequency(status: any){
    let upload = status;
    let FrequencyRow = 1;
    let invalidFrequency: any[] = [];
    if (this.excelData && this.excelData.Frequency && this.excelData.Frequency.length) {
      for (let obj of this.excelData.Frequency) {
        if (!obj["FrequencyName *"]) {
          invalidFrequency.push(FrequencyRow);
        }
        else if (!obj["Reference Time (*YYYY/MM/DD HH:mm)"]) {
          invalidFrequency.push(FrequencyRow);
        }
        else if (!obj["Duration / Min *"] || typeof obj['Duration / Min *'] != "number") {
          invalidFrequency.push(FrequencyRow);
        }
        else if (!obj["Order *"] || typeof obj['Order *'] != "number") {
          invalidFrequency.push(FrequencyRow);
        }
        else {
  
              const FrequencyData: any = {
                id: "",
                egId: this.CilmainDataForm.value.group,
                plantId: this.CilmainDataForm.value.plant,
                frequencyName: "",
                nextTime: "",
                referenceTime: "",
                order:"",
                userId: 0,
                firstNextTime: ""
              };
  
              let uniqueData = this.FrequencyList.find((fre: any) => {
                const a = obj["FrequencyName *"].toLowerCase();
                const b = fre.frequencyName.toLowerCase();
                const c = obj['Duration / Min *'];
                const d = fre.referenceTime;
                return (a == b) && (c == d)
              });
  
              if (!uniqueData) {
                FrequencyData.frequencyName = obj["FrequencyName *"];
                FrequencyData.referenceTime = obj["Duration / Min *"];
                FrequencyData.order = obj["Order *"];
                  if (obj['Reference Time (*YYYY/MM/DD HH:mm)'].toString().indexOf('*') != -1 && obj['Reference Time (*YYYY/MM/DD HH:mm)'].toString().indexOf('*') == 0) {
                    const sliceText = obj['Reference Time (*YYYY/MM/DD HH:mm)'].slice(1);
                    FrequencyData.nextTime = sliceText;
                    FrequencyData.nextTime = new Date(FrequencyData.nextTime);
                    this.dateAsYYYYMMDDHHNNSS(FrequencyData.nextTime);
                    if(FrequencyData.nextTime == 'Invalid Date'){
                      invalidFrequency.push(FrequencyRow);
                    }
                    else{
                      FrequencyData.nextTime = this.datePipe.transform(FrequencyData.nextTime, 'yyyy-MM-dd HH:mm:ss');
                      FrequencyData.firstNextTime = this.datePipe.transform(FrequencyData.nextTime, 'yyyy-MM-dd HH:mm:ss');
                      if (FrequencyRow != 1) {
                        let existTypeXLData = this.FrequencyMasterData.find((fremas: any) => {
                          return (fremas.frequencyName == FrequencyData.frequencyName) && (fremas.referenceTime == FrequencyData.referenceTime)
                        });
                        if (existTypeXLData) {
                          invalidFrequency.push(FrequencyRow);
                        }
                        else{
                          FrequencyData.createdOn = new Date()
                          FrequencyData.createdOn = this.datePipe.transform(FrequencyData.createdOn, 'yyyy-MM-dd HH:mm:ss'); 
                          this.FrequencyMasterData.push(FrequencyData);
                          upload = 1;
                        }
                      }
                      else{
                        FrequencyData.createdOn = new Date()
                        FrequencyData.createdOn = this.datePipe.transform(FrequencyData.createdOn, 'yyyy-MM-dd HH:mm:ss'); 
                        this.FrequencyMasterData.push(FrequencyData);
                        upload = 1;
                      }
                    }
                  }
                  else {
                    invalidFrequency.push(FrequencyRow);
                  }
              }
              else {
                invalidFrequency.push(FrequencyRow);
              }
  
        }
        FrequencyRow++;
      }
      if(FrequencyRow == this.excelData.Frequency.length + 1){
        this.invalidFrequency = invalidFrequency;
        this.validateSheet(upload);
      }
    }
    else{
      this.validateSheet(upload);
    }
  }

  async validateSheet(status: any) {
    try {
      this.spinner.show();
      let invalidtask: any[] = [];  
      let TaskRow = 1;
      let upload  = status;
      if (this.excelData && this.excelData.Tasks && this.excelData.Tasks.length) {
        for (let obj of this.excelData.Tasks) {

          if (!obj["Line *"]) {
            invalidtask.push(TaskRow);
          }
          else if (!obj["Unit *"]) {
            invalidtask.push(TaskRow);
          }
          else if(!obj['Assembly *']){
            invalidtask.push(TaskRow); 
          }
          else if (!obj["Component *"]) {
            invalidtask.push(TaskRow);
          }
          else if (!obj["Task *"]) {
            invalidtask.push(TaskRow);
          }
          else if (!obj["Estimated Time *"]) {
            invalidtask.push(TaskRow);
          }
          else if (!obj["Type *"]) {
            invalidtask.push(TaskRow);
          }
          else if (!obj["Tools * ( Enter Values Separated By comma(,) )"]) {
            invalidtask.push(TaskRow);
          }
          else if (!obj["Frequency *"]) {
            invalidtask.push(TaskRow);
          }
          else if (!obj["Users * ( Enter Values Separated By comma(,) )"]) {
            invalidtask.push(TaskRow);
          }
          else{
            let lineobj: any;
            let unitobj : any;
            let assemblyobj: any;
            let componentobj :any;
            let typeobj : any;
            let taskobj: any;
            let frequencyobj : any;
            let toolarr : any;
            let toolobj : any;
            let userarr : any;
            let userobj : any;
  
            const TaskData: any = {
              id:"",
              egId: this.CilmainDataForm.value.group,
              plantId: this.CilmainDataForm.value.plant,
              lineid:"",
              machineId:"",
              assemblyid:"",
              componentId:"",
              typeId:"",
              taskName:"",
              remarks:"",
              frequencyId:"",
              estimatedTime:"",
              toolIds:[],
              userids:[],
              createdOn:"",
              userId:0
            };

                lineobj = this.Line.find((line: any) => {
                  const a = obj['Line *'].toLowerCase();
                  const b = line.PL_Desc.toLowerCase();
                  return a == b;
                });
                if(lineobj){
                  TaskData.lineid = lineobj.PL_Id;
                  const key = { PL_Id: TaskData.lineid };

                  this.machineService.getUnitList(key).subscribe(
                    (data: any) => {
                      this.UnitList = data.lstUnit;
                    },
                    (error: any) => {
                      this.toaster.error('Error', error.message);
                    }
                  );
                  await this.delay(1000);
                  unitobj = this.UnitList.find((unit: any) => {
                    const a = obj['Unit *'].toLowerCase();
                    const b = unit.PU_Desc.toLowerCase();
                    return a == b;
                  });  
                  if(unitobj){
                    TaskData.machineId = unitobj.PU_Id;
                    const key = { PU_Id: TaskData.machineId };
  
                    this.machineService.getAssemblyList(key).subscribe(
                      (data: any) => {
                        this.AssemblyList = data.lstasmb;
                      },
                      (error: any) => {
                        this.toaster.error('Error', error.message);
                      }
                    );
                    await this.delay(1000);
                    assemblyobj = this.AssemblyList.find((asmb: any) => {
                      const a = obj['Assembly *'].toLowerCase();
                      const b = asmb.Asmb_Desc.toLowerCase();
                      return a == b;
                    }); 
                    if(assemblyobj){
                      TaskData.assemblyid = assemblyobj.Asmb_Id;
                      const key = { A_ID: TaskData.assemblyid };
                        this.machineService.getComponentListByAssembly(key).subscribe((data: any) => {
                          this.ComponentList = data.lstComponent;
                        },
                        (error: any) => {
                          this.toaster.error('Error', error.message);
                        }
                      );
                      await this.delay(1000);
                      componentobj = this.ComponentList.find((com: any) => {
                        const a = obj['Component *'].toLowerCase();
                        const b = com.ComponentName.toLowerCase();
                        return a == b;
                      });
                      if(componentobj){
                        TaskData.componentId = componentobj.ComponentId;
                        taskobj = this.TaskList.find((tsk: any) => {
                          const a = obj['Task *'].toLowerCase();
                          const b = tsk.taskName.toLowerCase();
                          return a == b;
                        });
                        if(!taskobj){
                          typeobj = this.TypeList.find((typ: any) => {
                            const a = obj['Type *'].toLowerCase();
                            const b = typ.typeName.toLowerCase();
                            return a == b;
                          });
                          if(typeobj){
                              TaskData.typeId = typeobj.id;
                              frequencyobj = this.FrequencyList.find((fre: any) => {
                                const a = obj['Frequency *'].toLowerCase();
                                const b = fre.frequencyName.toLowerCase();
                                return a == b;
                              });
                              if(frequencyobj){
                                  TaskData.frequencyId = frequencyobj.id;
                                  if ((parseInt(obj['Estimated Time *'], 10) < frequencyobj.referenceTime)) {
                                    TaskData.estimatedTime = obj['Estimated Time *'];
                                    toolarr = obj['Tools * ( Enter Values Separated By comma(,) )'].split(',');
                                    let i = 0;
                                    for(const tool of toolarr){
                                      toolobj = '';
                                      toolobj = this.ToolList.find((tol: any) => {
                                        const a = tool.toLowerCase();
                                        const b = tol.toolName.toLowerCase();
                                        return a == b;
                                      });
                                      if(toolobj){
                                        TaskData.toolIds.push(toolobj.id);          
                                      }
                                      else{
                                        i = i+1;
                                      }
                                    }
                                    if(i != 0){
                                      invalidtask.push(TaskRow);
                                    }
                                    else{
                                      userarr = obj['Users * ( Enter Values Separated By comma(,) )'].split(',');
                                      let i = 0;
                                      for(const user of userarr){
                                        userobj = '';
                                        userobj = this.raci.find((usr: any) => {
                                          let a = user.toLowerCase();
                                          let b = usr.FirstName.trim()+' '+usr.lastName.trim();
                                          b = b.toLowerCase();
                                          return a == b;
                                        });
                                        if(userobj){
                                          TaskData.userids.push(userobj.UserId)
                                        }
                                        else{
                                          i = i+1;
                                        }
                                      }
                                      if(i != 0){
                                        invalidtask.push(TaskRow);
                                      }
                                      else{
                                          TaskData.taskName = obj['Task *'];
                                          TaskData.remarks = obj["Remarks"] ?? '';
                                          TaskData.createdOn = new Date();
                                          TaskData.createdOn = this.datePipe.transform(TaskData.createdOn, 'yyyy-MM-dd HH:mm:ss');
                                          this.TaskMasterData.push(TaskData);
                                          upload = 1;
                                        }
                                      }
                                    }
                                    else{
                                      invalidtask.push(TaskRow);
                                    }
                                  }
                                  else{
                                    invalidtask.push(TaskRow);
                                  }
                              }
                          else{
                            invalidtask.push(TaskRow);
                            }
                        }
                        else{
                          invalidtask.push(TaskRow);
                        }
                      }
                      else{
                        invalidtask.push(TaskRow);
                      }
                      }
                      else{
                        invalidtask.push(TaskRow);
                      }

                    }
                    else{
                      invalidtask.push(TaskRow);
                    }

                  }
                  
                  else{
                    invalidtask.push(TaskRow);
                  }
          }
          TaskRow++;
        }
        }
        if(TaskRow == this.excelData.Tasks.length +1){
          this.invalidTask = invalidtask;
          if(this.invalidType.length !== 0 || this.invalidTool.length !== 0 || this.invalidFrequency.length !== 0 || this.invalidTask.length !== 0){
            this.invalidDataSheet();
            this.spinner.hide();
          }
        }
        if(upload == 1){
          this.uploadCilMasterData();
        }
        else{
          if(this.invalidType.length !== 0 || this.invalidTool.length !== 0 || this.invalidFrequency.length !== 0 || this.invalidTask.length !== 0){
            this.toaster.warning('Invalid Data not saved and  Sheet is Downloaded');
          }
          this.spinner.hide();
        }


    }
    catch (err: any) {
      this.toaster.error(err, 'Error');
      this.spinner.hide();
    }
  }

  async uploadCilMasterData() {
    this.spinner.show();
    let masterData= {
      TypeMasterData:this.TypeMasterData,
      ToolsMasterData:this.ToolsMasterData,
      FrequencyMasterData:this.FrequencyMasterData,
      TaskMasterData:this.TaskMasterData
    }
    this.productBulkUploadService.uploadCilMasterData(masterData).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status)
        this.toaster.success(res.message, 'Success');
        if(this.invalidType.length !== 0 || this.invalidTool.length !== 0 || this.invalidFrequency.length !== 0 || this.invalidTask.length !== 0){
          this.toaster.warning('Invalid Data not saved and  Sheet is Downloaded');
        }
      },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error(err.message, 'Error')
      });
      this.excelData = [];
      this.TypeMasterData = [];
      this.ToolsMasterData = [];
      this.FrequencyMasterData = [];
      this.TaskMasterData = [];
      await this.delay(2000);
      this.plantChange();
  }
  
  activeTasks(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Active Task!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Task !',
      cancelButtonText: 'No',
    }).then(
      (result) => {
        if (result.value) {
          const find = this.filteredRowsData.filter((item: any) => item.Status === 'Active' )
          if(find?.length > 0)
          {
            this.toaster.warning('Warning', 'Select Only InActive Task');
          }
          else
          {
            const Array = this.filteredRowsData.map((item: any) =>
            ({
              TaskId: item.id ,
              InActive : true
            }));
            this.cilMasterService.updateTaskStatus(Array).subscribe(
              (response: any) => {
                this.getTaskList();
                if (response?.message === 'Updated')
                {
                 this.toaster.success('Active Successfully');
                }
              });
          }
         
        } else {
          Swal.fire('Cancelled', 'Your selected task is safe :)');
          this.getTaskList();
        }
      },
      (dismiss: any) => {
        if (dismiss === 'cancel') {
          this.getTaskList();
        }
      }
    );
  }

  inActiveTasks(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to InActive Task!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, InActive !',
      cancelButtonText: 'No',
    }).then(
      (result) => {
        if (result.value) {
          const find = this.filteredRowsData.filter((item: any) => item.Status === 'InActive' )
          if(find?.length > 0)
          {
            this.toaster.warning('Warning', 'Select Only Active Task');
          }
          else
          {
            const Array = this.filteredRowsData.map((item: any) =>
            ({
              TaskId: item.id ,
              InActive : false
            }));
            this.cilMasterService.updateTaskStatus(Array).subscribe(
              (response: any) => {
                this.getTaskList();
                if (response?.message === 'Updated')
                {
                 this.toaster.success('InActive Successfully');
                }
              });
          }
        
        } else {
          Swal.fire('Cancelled', 'Your selected task is safe :)');
          this.getTaskList();
        }
      },
      (dismiss: any) => {
        if (dismiss === 'cancel') {
          this.getTaskList();
        }
      }
    );
  }

  onSelectionChanged(params: any) { 
    this.filteredRowsData = [];
    this.filteredRows = [];
    this.filteredRows = params?.api?.rowModel?.rowsToDisplay.filter((node: any) => node.isSelected());
    const unSelectedRows = params?.api?.rowModel?.rowsToDisplay.filter((node: any) => !node.isSelected());
    this.filteredRows.forEach((element: any) => {
      this.filteredRowsData.push(element.data);
    });
    if(this.filteredRowsData?.length > 0 )
    {
      if(params?.api.getDisplayedRowCount() === this.filteredRowsData?.length  )
      {
        const uniqueArray = [...new Set(this.filteredRowsData?.map(val => val.Status))]
        if(uniqueArray?.length > 1)
        {
          this.toaster.warning('Warn' , 'Select Single status Tasks either Active Or InActive ' );
          params?.api?.deselectAll();
        }
        else if(uniqueArray?.length == 1 && uniqueArray[0] === 'InActive')
        {
          this.Active = false;
        }
        else if(uniqueArray?.length == 1 && uniqueArray[0] === 'Active')
        {
          this.Active = true;
        }
      }
      else
      {
        const selectdRow =  params?.api.getFocusedCell();
    
        if(selectdRow && this.filteredRows )
        {
          const r = this.filteredRows.find(c=>c.rowIndex === selectdRow.rowIndex)
             if (this.Active && r?.data?.Status === 'InActive' && this.filteredRowsData?.length > 1)
             {
                  this.toaster.warning('Warn' , 'Select Single status Tasks either Active Or InActive ' );
                  r.setSelected(false);    
             }
             else if( !this.Active && r?.data?.Status === 'Active' && this.filteredRowsData?.length > 1)
             {
                   this.toaster.warning('Warn' , 'Select Single status Tasks either Active Or InActive ');
                   r?.setSelected(false);
       
             }
        }
      }
      this.disableActive = false
    }
    else
    {
      this.disableActive = true
    }
    if (unSelectedRows) {
      if (unSelectedRows.length === this.TaskList.length) {
        this.TaskList.forEach((elem: any) => {
          elem.InActive = false;
        });
      }
    }
    if(this.filteredRowsData[0]?.Status === 'Active' && (this.filteredRowsData?.length === 1 || this.filteredRowsData === undefined
      ))
    {
      this.Active = true;
    }
    else if(this.filteredRowsData[0]?.Status === 'InActive'  && (this.filteredRowsData?.length === 1 || this.filteredRowsData === undefined
       ))
    {
      this.Active = false;
    }
  }
   onFilterChangedAgGid(prams: any): void
  {
    this.onSelectionChanged(prams);
  }
}
