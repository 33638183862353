import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { KpimasterService } from './services/kpimaster.service';
@Component({
    selector: 'checkboxIsWrite-renderer',
    template: '<input type="checkbox" (click)="checkedHandler($event)" [checked]="params.value" />',
})
// tslint:disable-next-line: component-class-suffix
export class CheckboxIsWrite implements ICellRendererAngularComp {
    params: any;
    IsAccess: any;
    IsWrite: any;
    refresh(params: any): boolean {
        return true;
    }
    constructor( private kpiMaster: KpimasterService)
    {
            // const key = {
            //   Id: localStorage.getItem('O3RightMenuId'),
            // };
            // this.kpiMaster.GetMenuAccess(key).subscribe(
            //   (data: any) => {
            //     // this.permisson = data;
            //     this.IsAccess = data.IsAccess;
            //     this.IsWrite = data.IsWrite;
            //   }
            // );
    }
    agInit(params: any): void {
        this.params = params;
    }
    checkedHandler(event: any): void {
        const checked = event.target.checked;
        const colId = this.params.column.colId;
        this.params.node.setDataValue(colId, checked);
    }
}
























