<div class="content-top-box ">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="displayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <div class="tab-content-top-box">
    <h1 class="main-title">OPMS Glass</h1>
  </div>
</div>
<mat-tab-group mat-align-tabs="start">
  <mat-tab label="Data"><app-opms-data></app-opms-data></mat-tab>
  <mat-tab label="Daily Consumption" *ngIf="!isHotEndUser"><app-opms-daily-consuption></app-opms-daily-consuption></mat-tab>
  <mat-tab label="Monthly Stock" *ngIf="!isHotEndUser"><app-opms-monthly-stock></app-opms-monthly-stock></mat-tab>
  <mat-tab label="Box Receiving" *ngIf="!isHotEndUser"><app-opms-box-receiving></app-opms-box-receiving></mat-tab>
  <mat-tab label="OPMS Reports" *ngIf="!isHotEndUser"><app-opms-reports></app-opms-reports></mat-tab>
</mat-tab-group>