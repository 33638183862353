<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
  <p style="color: white">Fetching Data...</p>
</ngx-spinner>
<div class="content-top-box ">
    <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="displayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
    </ul>
    <div class="tab-content-top-box">
        <h1 class="main-title">Glass Chemical Lab QA Dashboard</h1>
    </div>
    <form [formGroup]="chemicalQaDashboardForm">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <div class="content-top-row">
                <div class="inputField" style="width: 245px;">
                    <nz-date-picker class="calender-input" style="width: 240px;" (ngModelChange)="loadDashBoard()"
                    formControlName="fromdate" nzFormat="yyyy-MM-dd"
                    [nzInputReadOnly]="true"  ></nz-date-picker>
                    <span class="floating-text">Start Date</span>   
                </div>    
                <div class="inputField" style="width: 245px;">
                    <nz-date-picker class="calender-input" style="width: 240px;" (ngModelChange)="loadDashBoard()"
                    formControlName="todate" nzFormat="yyyy-MM-dd"
                    [nzInputReadOnly]="true" ></nz-date-picker>
                    <span class="floating-text">End Date</span>   
                </div>  
                <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-label>Select Report Type</mat-label>
                    <mat-select formControlName="materialtype" panelClass="testClass" name="reportType" (selectionChange)="loadDashBoard()">
                        <mat-option *ngFor="let material of materialList" [value]="material.TypeId">{{material.TypeName }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <mat-divider></mat-divider>
</div>

<div class="p-grid p-fluid" style="margin-top: 10px">
    <div class="p-col-12 p-md-6 p-lg-6 p-xl-6" *ngFor="let chart of chartsLoop">
        <div id="{{chart.Parameter}}"></div>
    </div>
</div>