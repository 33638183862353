<div dir="{{languageDir}}">
  <section class="home-content">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <mat-card>
      <form [formGroup]="KpiSearchForm">
        <div style="padding:10px;background-color: #0F0FD1;">
          <div class="p-grid p-fluid">
            <div class="p-col-3">
              <mat-card-title style="color: white;">{{langObj ? langObj?.KPIIntegration : ('KPIIntegration' |
                translate)}}
              </mat-card-title>
            </div>
            <div class="p-col-1">
              <button mat-mini-fab color="accent" (click)="refreshData()" pTooltip="Refresh Integration"
                [disabled]="isLoading" style="float: right;">
                <mat-icon *ngIf="!isLoading">refresh
                </mat-icon>
                <mat-spinner color="accent" *ngIf="isLoading" diameter="20">
                </mat-spinner>
              </button>
            </div>
            <div class="p-col-3">
              <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                          'is-invalid':
                          KpiSearchForm.get('group')?.errors &&
                          KpiSearchForm.get('group')?.touched
                        }" [options]="groupList" formControlName="group"
                placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}" optionLabel="TypeName"
                [showClear]="true"></p-dropdown>
              <p class="invalid-feedback" *ngIf="
                        KpiSearchForm.get('group')?.touched &&
                        KpiSearchForm.get('group')?.hasError('required')
                        ">
                * {{langObj ? langObj?.pleaseselectgroup : ('pleaseselectgroup' | translate)}}
              </p>
            </div>
            <div class="p-col-3">
              <p-dropdown [filter]="true" [ngClass]="{
                                  'is-invalid':
                                  KpiSearchForm.get('plant')?.errors &&
                                  KpiSearchForm.get('plant')?.touched
                                }" [options]="searchPlants" formControlName="plant"
                placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}" optionLabel="DeptDesc"
                [showClear]="true" (onChange)="onChangePlant($event)"></p-dropdown>
              <p class="invalid-feedback" *ngIf="
                    KpiSearchForm.get('plant')?.touched &&
                    KpiSearchForm.get('plant')?.hasError('required')
                              ">
                * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
              </p>
            </div>
            <div class="p-col-2">
              <p-multiSelect [filter]="true" [ngClass]="{
                                  'is-invalid':
                                  KpiSearchForm.get('frequency')?.errors &&
                                  KpiSearchForm.get('frequency')?.touched
                                }" [options]="frequencies" formControlName="frequency"
                placeholder="{{langObj ? langObj?.SelectFrequency : ('SelectFrequency' | translate)}}"
                optionLabel="name" [showClear]="true" (onChange)="onFrequencyChange()">
              </p-multiSelect>
              <p class="invalid-feedback" *ngIf="
                    KpiSearchForm.get('frequency')?.touched &&
                    KpiSearchForm.get('frequency')?.hasError('required')
                              ">
                * {{langObj ? langObj?.pleaseselectfrequency : ('pleaseselectfrequency' | translate)}}
              </p>
            </div>
          </div>
        </div>

        <mat-card-content>

          <ag-grid-angular #agGrid id="myGrid" style="width: 100%;" class="ag-theme-alpine" [columnDefs]="columnDefs"
            [rowData]="rowData" rowSelection="multiple" (cellClicked)='oncellclicked($event)'
            (gridReady)="onGridReady($event)" [gridOptions]="gridOptions" [pagination]="true"
            [paginationPageSize]="paginationPageSize"></ag-grid-angular>
        </mat-card-content>
      </form>
    </mat-card>
  </section>

  <ng-template #displayrowRoleModal>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{langObj ? langObj?.KPIIntergration : ('KPIIntergration' | translate)}}</h2>
        <mat-label>( {{KPIName}} )</mat-label>
       
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <mat-dialog-content class="popup" style="height:85%">

      <form [formGroup]="KpiIntegrationForm">
        <div class="content-top-row">

          <!-- <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label >Select Data Source</mat-label>
          <mat-select  name="dataSource" formControlName="dataSource" (selectionChange)="onChangeDataSource()" required>
            <mat-select-filter
            [placeholder]="'Search'"
            [displayMember]="'dsName'"
            [array]="datasourceList"
            (filteredReturn)="filteredDataSource = $event"
              ></mat-select-filter>
            <mat-option *ngFor="let datasource of filteredDataSource" [value]="datasource.dsId" >{{datasource.dsName}}</mat-option> 
          </mat-select>
        </mat-form-field> -->


          <!-- <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label >Select Area in O3</mat-label>
          <mat-select  name="area" formControlName="area" required>
            <mat-select-filter
            [placeholder]="'Search'"
            [displayMember]="'areaName'"
            [array]="areaList"
            (filteredReturn)="filteredareas = $event"
              ></mat-select-filter>
            <mat-option *ngFor="let area of filteredareas" [value]="area.areaId">{{area.areaName}}</mat-option> 
          </mat-select>
        </mat-form-field> -->

          <!-- <mat-form-field appearance="fill" class="field-fill inputs" style="width:50%">
          <mat-label>{{langObj ? langObj?.Machine : ('Machine' | translate)}}</mat-label>
          <mat-select formControlName="machine" placeholder="{{langObj ? langObj?.Machine : ('Machine(s)' | translate)}}" 
           (selectionChange)="onMachineChange($event)"
            #selectAll multiple>
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'puDesc'" [array]="unitList"
              (filteredReturn)="filteredMachines = $event"></mat-select-filter>
            <div class="select-all selectAllItems">
              <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                (change)="toggleAllSelection()">Select All</mat-checkbox>
            </div>
            <mat-option [value]="0"> Plant </mat-option>
        
            <mat-option *ngFor="let machine of filteredMachines" [value]="machine">{{machine.puDesc}}</mat-option>
          </mat-select>
        </mat-form-field> -->

          <div style="width:50%">
            <mat-label>{{langObj ? langObj?.Machine : ('Machine' | translate)}}</mat-label>
            <p-multiSelect [filter]="true" [ngClass]="{
          'is-invalid':
          KpiIntegrationForm.get('machine')?.errors &&
          KpiIntegrationForm.get('machine')?.touched
        }" [options]="filteredMachines" formControlName="machine"
              placeholder="{{langObj ? langObj?.Machine : ('Machine(s)' | translate)}}" optionLabel="puDesc"
              [showClear]="true" (onChange)="onMachineChange($event,selected=true)" [(ngModel)]="selectedIds">
            </p-multiSelect>
          </div>
         

          <!-- 
        <mat-form-field appearance="fill" class="field-fill inputs">
          <mat-label>Data Calculation</mat-label>
          <input matInput formControlName="calculation" (keypress)="omit_special_char($event)" readonly />
        </mat-form-field> -->
          <!-- <mat-form-field appearance="fill" class="field-fill inputs" style="width:40%">
          <mat-label>{{langObj ? langObj?.SelectServiceType : ('SelectServiceType' | translate)}}</mat-label>
          <mat-select name="servicetype" formControlName="servicetype" required>
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'stName'" [array]="servicetypeList"
              (filteredReturn)="filteredServiceType = $event"></mat-select-filter>
            <div *ngFor="let servicetype of filteredServiceType">
              <mat-option *ngIf="servicetype.stName !== 'Weekly once'" [value]="servicetype.stId">{{servicetype.stName
                }}</mat-option>
            </div>
          </mat-select>
        </mat-form-field> -->

        </div>
        <div class="d-flex  align-items-center" style="margin-top: 10px;">
          <div class="p-col-2">

          </div>
          <div class="p-col-8 justify-content-between">
            <button mat-mini-fab mat-button type="submit" color="accent" title="Add" (click)="addOperator('+')">
              <i class="fa fa-plus" style="color: #fff"></i>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Subtract" class="Operations"
              (click)="addOperator('-')">
              <i class="fa fa-minus" style="color: #fff"></i>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Multiply" class="Operations"
              (click)="addOperator('*')">
              <i class="fa fa-times" style="color: #fff"></i>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('/')">
              <div style="color:#fff;font-size: 20px;font-weight: 900;">÷</div>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('(')">
              <div style="color:#fff">(</div>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator(')')">
              <div style="color:#fff">)</div>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('0')">
              <div style="color:#fff">0</div>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('1')">
              <div style="color:#fff">1</div>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('2')">
              <div style="color:#fff">2</div>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('3')">
              <div style="color:#fff">3</div>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('4')">
              <div style="color:#fff">4</div>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('5')">
              <div style="color:#fff">5</div>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('6')">
              <div style="color:#fff">6</div>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('7')">
              <div style="color:#fff">7</div>
            </button>
            <!-- <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('7')">
            <div style="color:#fff">7</div>
          </button> -->
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('8')">
              <div style="color:#fff">8</div>
            </button>
            <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
              (click)="addOperator('9')">
              <div style="color:#fff">9</div>
            </button>
          </div>
          <div class="p-col-4">

          </div>
        </div>
        <div class="d-flex  align-items-center">

          <mat-form-field class="example-chip-list " appearance="fill" style="width:93%;margin-left: 5px;">
            <mat-label>{{langObj ? langObj?.Calculation : ('Calculation' | translate)}}</mat-label>
            <mat-chip-list #chipList aria-label="Video keywords" multiple [formControl]="formControl"
              class="example-chip" cdkDropList class="example-chip field-fill no-wrap"
              cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
              <mat-chip cdkDrag class="example-box" *ngFor="let list of calcList; let i=index" [selected]="list"
                [value]="list.dpName" style="background-color: #e1e0e0;opacity: 1;" (dblclick)="allowEdit(list,i)"
                (removed)="removeKeyword(list,i)" (cdkDragStarted)="dragStart(i)" (mouseenter)="mouseEnter(i)">
                <h5>{{list.dpName}}</h5>
                <!-- this is for editable [disabled]="list.disable" this propert will bw used in chips and upper h5 will be removed and lower will be uncommit  -->
                <!-- <h5 *ngIf="!list.disable"  >{{list.dpName}}</h5> -->
                <!-- <input type="text" #Update *ngIf="list.disable" [value]="list.dpName" formControlName="Operator"
                style="background-color: white;color:black;border:none;width: 10px;" maxlength="1" 
                (keyup.Enter)="updateOperator(Update.value,i)" (keypress)="omit_special_char($event)" /> -->

              </mat-chip>
              <input [matChipInputFor]="chipList" [readonly]="true">
            </mat-chip-list>
          </mat-form-field>
          <input matInput formControlName="formula" style="display: none;" required />
          <div class=" d-flex  align-items-center">
            <button type="submit" mat-mini-fab mat-button color="accent" (click)="resetCalculation()"
              matTooltip="Reset Calculation" matTooltipClass="custom-tooltip" matTooltipPosition="above"
              style="margin-left: 10px;margin-bottom: 40px;">
              <mat-icon>refresh</mat-icon>
            </button>
            <button type="submit" mat-mini-fab mat-button color="primary" (click)="saveKpiIntegration()"
              matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"
              style="margin-left:10px;margin-bottom: 40px;" [disabled]="disableSave">
              <mat-icon>save</mat-icon>
            </button>
          </div>

        </div>
        <div style="margin-top: 10px;overflow-x: auto;">
          <!-- <h2 class="datapointsheading">Data Points</h2> -->
          <div style="display: flex;flex-wrap: wrap; " *ngIf="CheckGeneralCatagory">
            <div *ngFor="let datapoint of datapoints1; let i1 =index" style="margin-left:10px">
              <h2 class="datapointsheading"> {{datapoint.machine == "" ? 'Plant' : datapoint.machine }} </h2>
              <!-- <h2 class="datapointsheading" #elseBlock > General Catagory </h2> -->
              <div *ngFor="let check of datapoint.data; let i2= index">
                <mat-checkbox style="margin: 12px 20px;" #check [checked]="check.checked"
                  (change)="onCheckboxChange($event, check.KpiDpId,check.DataPoints,i1,i2)">{{ check.DataPoints}}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </form>

    </mat-dialog-content>

  </ng-template>
</div>