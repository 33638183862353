import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QcBoxService {
baseUrl: any = environment.O3CoreApiUrl;

constructor(private http: HttpClient) { }

GetGroups(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Common/GetGroup', data);
 }

 GetPlants(data: any): Observable<any[]> {
   return this.http.post<any[]>(this.baseUrl + 'Common/GetDepartments', data);
 }

 GetItemInfo(data: any): Observable<any[]> {
   return this.http.post<any[]>(this.baseUrl + 'Quality/GetItemInfo', data);
 }

GetQualityStatus(): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Quality/GetQualityStatus','');
}

 GetProcessOrder(data: any): Observable<any[]> {
   return this.http.post<any[]>(this.baseUrl + 'Quality/GetProcessOrder', data);
 }

 GetBatchInfoList(data: any): Observable<any[]> {
   return this.http.post<any[]>(this.baseUrl + 'Quality/GetBatchInfoList', data);
 }

 GetPalletBatchInfoList(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Pallet/GetBatchInfoList', data);
}

GetPalletInfoList(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Pallet/GetPalletInfoList', data);
}

GetPalletInfo(data: any): Observable<any[]> {
   return this.http.post<any[]>(this.baseUrl + 'Pallet/GetPalletInfo', data);
 }

 subGeneratePallet(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'Pallet/SubGeneratePallate', data);
}

getPalletBarcodeDetails(data: any): Observable<any[]> {
  return this.http.post<any[]>(this.baseUrl + 'BarcodePrint/GetPalletBarCodeDetails', data);
}

 GetQualityInfoList(data: any): Observable<any[]> {
   return this.http.post<any[]>(this.baseUrl + 'Quality/GetQualityInfoList', data);
 }

 SubmitQuality(data: any): Observable<any[]> {
   return this.http.post<any[]>(this.baseUrl + 'Quality/SubmitQuality', data);
 }

}
