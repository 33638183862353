<p-toast [style]="{ marginTop: '80px' }"></p-toast>
<div dir="{{languageDir}}">
  <section class="home-content">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <mat-card>
      <form [formGroup]="searchPlantForm">
        <div style="padding: 10px; background-color: #0f0fd1">
          <div class="p-grid p-fluid">
            <div class="p-col-4">
              <h2 style="color: #fff; margin-top: 5px;">KPI Historical Report</h2>
            </div>
            <div class="p-col-8">
              <div class="p-grid p-fluid ">
                <div class="p-col-6 p-md-6">
                  
                    <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                                      'is-invalid':
                                        searchPlantForm.get('group')?.errors &&
                                        searchPlantForm.get('group')?.touched
                                    }" [options]="groupList" formControlName="group"
                      placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}" optionLabel="TypeName"></p-dropdown>
                    <p class="invalid-feedback" *ngIf="
                                      searchPlantForm.get('group')?.touched &&
                                      searchPlantForm.get('group')?.hasError('required')
                                    ">
                      * please select group
                    </p>
                </div>

                <div class="p-col-6 p-md-6">
                  <p-dropdown [filter]="true" (onChange)="changeSelectList()" [ngClass]="{
                    'is-invalid':
                      searchPlantForm.get('plant')?.errors &&
                      searchPlantForm.get('plant')?.touched
                  }" [options]="plantList" formControlName="plant"
                    placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}"
                    optionLabel="DeptDesc"></p-dropdown>
                  <p class="invalid-feedback" *ngIf="
                    searchPlantForm.get('plant')?.touched &&
                    searchPlantForm.get('plant')?.hasError('required')
                  ">
                    * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-grid p-fluid content-row-wrap" style="margin-top: 10px;">
          <div class="p-col-2 p-md-2" style="margin-left: 10px;">
            <span class="p-float-label">
            <p-dropdown [filter]="true" (onChange)="setDateRangeFromVersion()" [ngClass]="{
              'is-invalid':
                searchPlantForm.get('VersionList')?.errors &&
                searchPlantForm.get('VersionList')?.touched
            }" [options]="versionList" formControlName="VersionList"
              
              optionLabel="name"></p-dropdown>
            <p class="invalid-feedback" *ngIf="
              searchPlantForm.get('VersionList')?.touched &&
              searchPlantForm.get('VersionList')?.hasError('required')
            ">
              * {{langObj ? langObj?.pleaseselectlist : ('pleaseselectlist' | translate)}}
            </p>
            <label for="ms-products"> {{langObj ? langObj?.KPIVersion : ('KPI Version' | translate)}} </label>
            </span>
          </div>

          <div class="p-col-2 p-md-2">
            <span class="p-float-label">
              <p-dropdown [filter]="true" (onChange)="getKpiList()" [ngClass]="{
                          'is-invalid':
                            searchPlantForm.get('Frequency')?.errors &&
                            searchPlantForm.get('Frequency')?.touched
                        }" [options]="frequencyList" formControlName="Frequency" 
                optionLabel="name"></p-dropdown>
              <p class="invalid-feedback" *ngIf="
                          searchPlantForm.get('Frequency')?.touched &&
                          searchPlantForm.get('Frequency')?.hasError('required')
                        ">
                * {{langObj ? langObj?.pleaseselectlist : ('pleaseselectlist' | translate)}}
              </p>
              <label for="ms-products"> {{langObj ? langObj?.Frequency : ('Frequency' | translate)}} </label>
            </span>
          </div>

          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 200px;height: 45px;margin-top: 5px;margin-left: 5px;">
            <mat-label>Select Date Range</mat-label>
            <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate">
              <input matStartDate formControlName="FromDate" placeholder="From Date" />
              <input matEndDate formControlName="ToDate" placeholder="To Date" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker (closed)="getKpiList()"
              [disabled]="dateRangeDisable"></mat-date-range-picker>
          </mat-form-field>
          <div class="p-col-2 p-md-2">
            <span class="p-float-label">
            <p-multiSelect [options]="kpiList" formControlName="kpis" name="Kpis" (onChange)="onChangeKpiSelection()"
              optionLabel="name"></p-multiSelect>
              <label for="ms-products">{{langObj ? langObj?.KPIs : ('KPIs' | translate)}} </label>
            </span>
          </div>
          <div class="">
            <div style="margin-top: 5px;">
              <h3>Glide Path</h3>
              <mat-slide-toggle class="toggle-custom" name="enableGlidePath" (change)="enableGlidePathChange($event)"
                [(ngModel)]="enableGlidePath">
                
              </mat-slide-toggle>
            </div>
          </div>

          <!-- <div class="">
            <button (click)="getKpiWiseAnalysisReportData()" class="icon-btn" style="margin-left: 7px; margin-top: 0px;"
              pTooltip="Apply Filter" tooltipPosition="top">
              <img src="assets/images/search.png" alt="" width="25px" height="25px">
            </button>
          </div> -->
          <mat-spinner-button [options]="spinnerButtonOptions" (btnClick)="onClickParamterAnalysis(false)"
            class="loader" id="selectedRows" style="margin: 0px 10px">
          </mat-spinner-button>
          <mat-spinner-button [options]="spinnerButtonOptions1" (btnClick)="onClickParamterComparsion(false)"
            class="loader" id="selectedRows" style="margin: 0px 10px">
          </mat-spinner-button>
          <mat-spinner-button [options]="spinnerButtonOptions3" (btnClick)="getRCAInfo()"
            class="loader" id="selectedRows" style="margin: 0px 10px">
          </mat-spinner-button>
          <!-- <button (click)="getRCAInfo()" mat-mini-fab mat-button mat-dialog-close color="primary" title="close">
            <mat-icon>remove_red_eye</mat-icon>
          </button> -->
        </div>
      </form>
      <div style="margin-top: 10px;">
        <ag-grid-angular #scoreGrid id="scoregrid" style="width: 100%;" class="ag-theme-alpine score-grid"
          [rowData]="rowDataForGrid" [columnDefs]="gridcolumnDefs" [gridOptions]="kpiWiseAnalysisReportGridOptions"
          (gridReady)="kpiWiseAnalysisReportGridReady($event)" (modelUpdated)="autoSizeAll()" 
          [loadingOverlayComponent]="loadingOverlayForkpiWiseAnalysisReportGrid"
          [frameworkComponents]="frameworkComponents" [allowContextMenuWithControlKey]="true" [allowContextMenuWithControlKey]="true" [getContextMenuItems]="getContextMenuItems"
          [sideBar]="{ toolPanels: ['columns', 'filters'] }" [defaultColDef]="defaultColDef"
          [rowSelection]="rowSelection" [rowMultiSelectWithClick]="true" 
          (selectionChanged)="onSelectionChanged($event)"[suppressRowClickSelection]="true"
          (firstDataRendered)="onGridFirstDataRendered()">
        </ag-grid-angular>
      </div>
    </mat-card>
  </section>
</div>


<ng-template #analysisChart style="padding-bottom: 0px !important">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2> {{ langObj ? langObj?.KPIAnalysis : ("KPIAnalysis" | translate) }}</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content class="chartpopup">
    <form [formGroup]="KPIAnalysisForm">
      <div class="d-flex" style="grid-gap: 10px">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 200px">
          <mat-label> {{ langObj ? langObj?.DateRangeAnalysis : ("DateRangeAnalysis" | translate) }} </mat-label>
          <mat-date-range-input [rangePicker]="picker1" [min]="minDate" [max]="maxDate">
            <input matStartDate formControlName="fromDate" placeholder="From Date" [readOnly]="true" />
            <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true"
              (dateChange)="onDateChangeInKPIAnaModal($event)" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>

        <mat-slide-toggle (change)="chartType = !chartType">Histogram</mat-slide-toggle>
        <button mat-mini-fab color="primary" matTooltip="All Leak Detection" matTooltipClass="custom-tooltip"
          matTooltipPosition="above" aria-label="Example icon button with a menu icon"
          style="float: right; overflow: hidden;" *ngIf="isSaur" (click)="navigateLeakDetectionAll()">
          <mat-icon>map</mat-icon>
        </button>
      </div>
    </form>
    <ng-container *ngIf="chartType">
      <div *ngFor="let item of chartArray">
        <!-- <button mat-flat-button color="accent" class="btn-accent" style="float: right;" *ngIf="isSaur" (click)="navigateLeakDetection()">Leak Detection</button> -->
        <button mat-mini-fab color="primary" matTooltip="Leak Detection" matTooltipClass="custom-tooltip"
          matTooltipPosition="above" aria-label="Example icon button with a menu icon" style="float: right;"
          *ngIf="isSaur" (click)="navigateLeakDetection(item)">
          <mat-icon>map</mat-icon>
        </button>
        <div [id]="item.id" [chart]="item.chartOption"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="!chartType">
      <div *ngFor="let item of chartArrayForAnalysis">
        <!-- <button mat-flat-button color="accent" class="btn-accent" style="float: right;" *ngIf="isSaur" (click)="navigateLeakDetection()">Leak Detection</button> -->
        <button mat-mini-fab color="primary" matTooltip="Leak Detection" matTooltipClass="custom-tooltip"
          matTooltipPosition="above" aria-label="Example icon button with a menu icon"
          style="float: right; overflow: hidden;" *ngIf="isSaur" (click)="navigateLeakDetection(item)">
          <mat-icon>map</mat-icon>
        </button>
        <div [id]="item.id" [chart]="item.chartOption"></div>
      </div>
    </ng-container>
  </mat-dialog-content>
</ng-template>


<ng-template #visibleParameterComparison style="padding-bottom: 0px !important">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>{{ langObj ? langObj?.KPIComparison : ("KPIComparison" | translate) }}</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content class="chartpopup" style="overflow-y:auto !important">
    <form [formGroup]="KPIComparisonForm">
      <div class="d-flex" style="align-items: center;">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 200px">
          <mat-label> {{ langObj ? langObj?.DateRangeComparison : ("DateRangeComparison" | translate) }}</mat-label>
          <mat-date-range-input [rangePicker]="picker1" [min]="minDate" [max]="maxDate">
            <input matStartDate formControlName="fromDate" placeholder="From Date" [readOnly]="true" />
            <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true"
              (dateChange)="dateChangeFromModelComp($event)" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>
        <div style="margin-left: 20px;">
          <button *ngFor="let u of uniqueUoms" [disabled]="u.disabled" (click)="GetRawMaterialParameters(u)"
            [style]="u == individualUom ? 'color: #fff; padding: 5px 25px;background-color: rgb(56 210 173);background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);border-radius: 14px;font-weight: bold;font-size: 16px;border: 0;user-select: none;-webkit-user-select: none;touch-action: manipulation;cursor: pointer;' : ''"
            mat-raised-button
            style="margin-left: 10px; margin-right: 10px;color: #000; padding: 5px 25px;border-radius: 14px;font-weight: bold;font-size: 16px;border: 0;user-select: none;-webkit-user-select: none;touch-action: manipulation;cursor: pointer;"><span
              [style]="u == individualUom ? 'color:white; font-weight: 500;':'color:black; font-weight: 500;'">{{u}}</span>
          </button>
        </div>
      </div>

    </form>
    <div [chart]="stock"></div>
  </mat-dialog-content>
</ng-template>

<ng-template #RACIdataModel>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <!-- <h2>{{ langObj ? langObj?.KPIComparison : ("KPIComparison" | translate) }}</h2> -->
      <h2>{{langObj ? langObj?.RACIInformation : ('RACI Information' | translate)}} </h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content  >
    <ag-grid-angular #rciGrid id="rciGrid" style="width: 100%" class="ag-theme-alpine score-grid" [pagination]="true"
    paginationPageSize="5" [rowData]="RACIRowData"  [columnDefs]="RACIColumnDef" [gridOptions]="RACIGridOption" (gridReady)="RACIOnGridReady($event)">
    </ag-grid-angular>
  </mat-dialog-content>

</ng-template>