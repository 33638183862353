import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImmediateActionService {
  baseUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) {}

  getAllImmediateActions(): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + 'ImmediateAction/GetAllImmediateActions'
    );
  }

  getAllCurrencies(): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + 'ImmediateAction/GetAllCurrencies'
    );
  }

  saveNcrImmediateAction(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SaveNCRImmediateAction',
      data
    );
  }

  updateNcrImmediateAction(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/UpdateNCRImmediateAction',
      data
    );
  }

  getImmediateActionPlantWiseMasterData(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/GetAllPlantWiseImmediateActions',
      data
    );
  }

  getOneApprovingAuthority(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/GetOneApprovingAuthority',
      data
    );
  }

  updateApprovingAuthority(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/UpdateApprovingAuthority',
      data
    );
  }

  getImmediateActionAuthorityWiseMasterData(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/GetAllAuthorityWiseImmediateActions',
      data
    );
  }

  deletePlantWiseImmediateAction(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/DeletePlantWiseImmediateAction',
      data
    );
  }

  getOnePlantWiseImmediateAction(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/GetOnePlantWiseImmediateAction',
      data
    );
  }

  searchNCRImmediateActions(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SearchNCRImmediateactions',
      data
    );
  }

  saveDetailNotesActions(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SaveImmediateDetailNotesActions',
      data
    );
  }

  saveImmediateSortingActions(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SaveImmediateSortingActions',
      data
    );
  }

  updateImmediateSortingActions(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/UpdateImmediateSortingActions',
      data
    );
  }

  updateImmediateProcessActions(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/UpdateImmediateProcessActions',
      data
    );
  }

  searchImmediateSortingActions(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SearchImmediateSortingActions',
      data
    );
  }

  searchSortingReport(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SearchSortingReport',
      data
    );
  }

  searchWaitingForApprovalSortingActions(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SearchWaitingForApprovalSortingActions',
      data
    );
  }

  searchWaitingForApprovalProcessActions(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SearchWaitingForApprovalProcessActions',
      data
    );
  }

  searchImmediateReProcessActions(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SearchImmediateReProcessActions',
      data
    );
  }

  searchImmediateDetailNotes(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SearchImmediateDetailNotes',
      data
    );
  }

  setNewTargetDate(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SetNewTargetDate',
      data
    );
  }

  sendActionForApproval(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SendActionForApproval',
      data
    );
  }

  deleteAllImmediateActions(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/DeleteAllImmediateActions',
      data
    );
  }

  deleteImmediateDetailNotes(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/DeleteImmediateDetailNotes',
      data
    );
  }

  approveImmediateAction(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/ApproveAction',
      data
    );
  }

  saveFOCApprovalForm(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SaveFOCApprovalForm',
      data
    );
  }

  saveFOCApprovalDisposeForm(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/SaveFOCApprovalDisposeForm',
      data
    );
  }

  getFocReprocessApprovingAuthority(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/GetFOCReprocessApprovingAuthority',
      data
    );
  }

  getFocApprovingAuthorityDisposeForm(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'ImmediateAction/GetFOCApprovingAuthorityDisposeForm',
      data
    );
  }
}
