import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { event } from 'jquery';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'btn-cell-rendererCilMaster',
  template: `
    <button
     mat-mini-fab color="primary"
     tooltipPosition="top"
     pTooltip="Edit Task"
     (click)="onClick($event)">
      <mat-icon>edit</mat-icon>
    </button>
    <button 
      mat-mini-fab
      tooltipPosition="top"
      pTooltip="Delete Task"
      color="warn"
      (click)="onClick($event)"
    >
      <mat-icon>delete</mat-icon>
    </button>
  `,
  styles: [
    ` button{
      height: 35px;
      width: 35px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 3px;

    }
    .mat-icon {
      transform: scale(0.7);
    }
      .add-icon {
        background: cornflowerblue;
      }
    `,
  ],
})
// tslint:disable-next-line:component-class-suffix
export class BtnCellRendererCilMaster
  implements ICellRendererAngularComp, OnDestroy {
  clicked = false;
   params: any;
  refresh(params: any): boolean {
    throw new Error('Method not implemented.');
  }
  agInit(params: any): void {
    this.params = params;
  }
  onClick($event: any): any {
    // const spec = $event.target.className;
    // if (
    //   spec !== 'Delete' &&
    //   spec !== 'close' &&
    //   this.params.node.data.ObservationStatus.toLowerCase() !== 'in progress'
    // ) {
    //   if (this.params.onClick instanceof Function) {
    //     // put anything into params u want pass into parents component
    //     const params = {
    //       event: $event,
    //       rowData: this.params.node.data,
    //       // ...something
    //     };
    //     this.params.onClick(params);
    //   }
    // } else {
    //   this.clicked = true;
    // }
  }
  ngOnDestroy(): any {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
