<div class="maintenance-board-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
      </ul>
    </ul>
    <div class="title-row-flex-both-end">
      <h1 class="main-title">Maintenance Board </h1>
      <!-- <div class="basic-mat-input" style="float: right; margin-top: auto">
            <span class="date-label">Last 30 days</span>
            <mat-form-field appearence="fill" style="padding-left: 15px">
               <mat-label>Choose date range</mat-label> 
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [formControl]="startDate" />
                <input matEndDate [formControl]="startDate" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon
                  ><mat-select></mat-select
                ></mat-icon> 
              </mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div> -->
    </div>
  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  <form [formGroup]="maintenanceBoardForm">
    <div class="content-top-row content-top-row-order-processing">
      <!-- GROUP -->
      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <!-- <mat-label>Status</mat-label> -->
        <mat-select formControlName="UserGroup1" (selectionChange)="onChangeGroup($event)" placeholder="Group"
          panelClass="testClass" name="group">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
            (filteredReturn)="filteredGroups = $event"></mat-select-filter>
          <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
            {{group.EG_Desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-select formControlName="UserPlant1" (selectionChange)="onChangePlant($event)" placeholder="Plant" panelClass="testClass" name="plant">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList1"
            (filteredReturn)="filteredPlants = $event"></mat-select-filter>
          <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
            {{plant.PT_Desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-select formControlName="line"  (selectionChange)="onChangeLine($event)" placeholder="Line" panelClass="testClass" name="line">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
            (filteredReturn)="filteredLines = $event"></mat-select-filter>
          <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
            {{line.PL_Desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-select formControlName="unit" placeholder="Unit" panelClass="testClass" name="unit" (selectionChange)="onSubmit()">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="unitList"
            (filteredReturn)="filteredUnits = $event"></mat-select-filter>
          <mat-option *ngFor="let unit of filteredUnits" [value]="unit.PU_Id">
            {{unit.PU_Desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Dropdown -->
    <div class="content-top-row content-top-row-order-processing">
      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <input matInput [ngxMatDatetimePicker]="startPicker" placeholder="Start Date" formControlName="dateStart"
          (dateChange)="onSubmit()" [min]="minDate" [max]="maxDate" [disabled]="disabled">
        <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #startPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
          [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
          [enableMeridian]="enableMeridian">
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <input matInput [ngxMatDatetimePicker]="endPicker" placeholder="End Date" formControlName="dateEnd"
          (dateChange)="onSubmit()" [min]="minDate" [max]="maxDate" [disabled]="disabled">
        <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #endPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
          [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
          [enableMeridian]="enableMeridian">
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <div class="btn-position">
        <button mat-flat-button color="accent" class="btn-accent" (click)="today()">Today</button>
      </div>
      <div class="btn-position">
        <button mat-flat-button color="accent" class="btn-accent" (click)="yesterday()">Yesterday</button>
      </div>
      <div class="btn-position">
        <button mat-flat-button color="accent" class="btn-accent" (click)="lastWeek()">Last Week</button>
      </div>
      <div class="btn-position">
        <button mat-flat-button color="accent" class="btn-accent" (click)="lastMonth()">Last Month</button>
      </div>
    </div>

    <div class="card-wrapper-list maintenance-board">
      <div class="card-item card-item-five">
        <div class="card">
          <div class="card-suffix-icon">
          </div>
          <div class="content" style="position: relative">
            <o3-percentage-graph [barProperties]="oeeAnalysis"></o3-percentage-graph>
            <p class="graph-title">OEE</p>
          </div>
        </div>
      </div>

      <div class="card-item card-item-five">
        <div class="card">

          <o3-percentage-graph [barProperties]="availabilityAnalysis"></o3-percentage-graph>
          <p class="graph-title">Availability</p>
        </div>
      </div>
      <div class="card-item card-item-five">
        <div class="card">

          <o3-percentage-graph [barProperties]="performanceAnalysis"></o3-percentage-graph>
          <p class="graph-title">Performance</p>
        </div>
      </div>
      <div class="card-item card-item-five">
        <div class="card">

          <o3-percentage-graph [barProperties]="qualityAnalysis"></o3-percentage-graph>
          <p class="graph-title merge-style">Quality</p>
        </div>
      </div>
    </div>

    <!-- Charts -->
    <mat-card>
      <div [chart]="chartMaintenance"></div>
    </mat-card>
    <div class="chart-group d3-chart">
      <div class="chart-items">
        <div class="chart-title">Total Shutdown <span style="float: right; color: #2196F3; font-weight: bold;">Count :
            {{countTS.length}} <span style="color: #fcba03; font-weight: bold;"> , Minutes : {{minutesTS |
              number:'1.2-2'}}</span></span></div>
        <div>
          <div id="chartTS" style="height:300px; width: 450px;">
            <div class="innerCont"
              style="overflow: auto; height:91% ; Width:100% ;position: relative;overflow: hidden;"></div>

          </div>
          <div class="breadcrumb">
            <div class="item">
              <a id="TSlevel0" href="javascript:void();">Total Shutdown </a>
              <a id="TSmessageSpan1" href="javascript:void();"></a>
              <a id="TSmessageSpan2" href="javascript:void();"></a>
              <a id="TSmessageSpan3" href="javascript:void();"></a>
              <a id="TSmessageSpan4" href="javascript:void();"></a>
              <a id="TSmessageSpan5" href="javascript:void();"></a>
              <a id="TSmessageSpan6" href="javascript:void();"></a>
            </div>
          </div>

        </div>
      </div>
      <div class="chart-items">
        <div class="chart-title">Faulty Summary (Top 10)</div>
        <div id="divTS">
          <canvas id="tstTotalShutdown" style="height:600px;"></canvas>
        </div>
      </div>
    </div>

    <div class="chart-group d3-chart">
      <div class="chart-items">
        <div class="chart-title">Planned Downtime <span style="float: right; color: #2196F3; font-weight: bold;">Count :
            {{countPD.length}} <span style="color: #fcba03; font-weight: bold;"> , Minutes : {{minutesPD |
              number:'1.2-2' }}</span></span></div>
        <div>

          <div id="chartPD" style="height:300px; width: 450px;">
            <div class="innerCont"
              style="overflow: auto; height:91% ; Width:100% ;position: relative;overflow: hidden;"></div>

          </div>
          <div class="breadcrumb">
            <div class="item">
              <a id="PDlevel0" href="javascript:void();">Planned Downtime </a>
              <a id="PDmessageSpan1" href="javascript:void();"></a>
              <a id="PDmessageSpan2" href="javascript:void();"></a>
              <a id="PDmessageSpan3" href="javascript:void();"></a>
              <a id="PDmessageSpan4" href="javascript:void();"></a>
              <a id="PDmessageSpan5" href="javascript:void();"></a>
              <a id="PDmessageSpan6" href="javascript:void();"></a>
            </div>
          </div>

        </div>
      </div>
      <div class="chart-items">
        <div class="chart-title">Faulty Summary (Top 10)</div>
        <div id="divPD">
          <canvas id="tsdPlannedDowntime" style="height:600px;"></canvas>
        </div>
      </div>
    </div>
    <div class="chart-group d3-chart">
      <div class="chart-items">
        <div class="chart-title">Un Planned Downtime <span
            style="float: right; color: #2196F3; font-weight: bold;">Count : {{countUPD.length}}<span
              style="color: #fcba03; font-weight: bold;"> , Minutes : {{minutesUPD | number:'1.2-2'}}</span></span>
        </div>
        <div>
          <div id="chartUPD" style="height:300px;width: 450px;">
            <div class="innerCont"
              style="overflow: auto; height:91% ; Width:100% ;position: relative;overflow: hidden;"></div>

          </div>
          <div class="breadcrumb">
            <div class="item">
              <a id="UPDlevel0" href="javascript:void();">Un Planned Downtime </a>
              <a id="UPDmessageSpan1" href="javascript:void();"></a>
              <a id="UPDmessageSpan2" href="javascript:void();"></a>
              <a id="UPDmessageSpan3" href="javascript:void();"></a>
              <a id="UPDmessageSpan4" href="javascript:void();"></a>
              <a id="UPDmessageSpan5" href="javascript:void();"></a>
              <a id="UPDmessageSpan6" href="javascript:void();"></a>
            </div>
          </div>

        </div>
      </div>
      <div class="chart-items">
        <div class="chart-title">Faulty Summary (Top 10)</div>
        <div id="divUPD">
          <canvas id="tsdUPlannedDowntime" style="height:600px;"></canvas>
        </div>
      </div>
    </div>
  </form>
</div>