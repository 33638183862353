<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center">
      <mat-card-title class="p-ml-2">Task</mat-card-title>
      <div class="" >
        <mat-form-field>
          <input matInput placeholder="Search...." autocomplete="off" (keyup)="applyFilter($event)">
        </mat-form-field>
      </div>
      <div class="" >
        <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="accent"
        (click)="openTaskModal()"
        pTooltip="Add Project" tooltipPosition="top" *ngIf="IsWrite"
      >
        <i class="fa fa-plus" style="color: #fff"></i>
      </button>
      </div>
  </div>

  <mat-card-content>
    <ngx-spinner
        [fullScreen]="false"
        bdColor="#fff"
        size="medium"
        color="#050a4f"
        type="ball-clip-rotate-multiple"
        >
        <p style="font-size: 20px; color: #050a4f">Fetching Departments Data...</p>
    </ngx-spinner>
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1" >
      <table mat-table  class="basic-table plant-model-table" [dataSource]="taskData" matSort>
        <ng-container matColumnDef="project">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="projectName">
            <p class="header-text">Project</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.projectName}} </td>
        </ng-container>
        <ng-container matColumnDef="topic">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="topicName">
            <p class="header-text">Topic</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.topicName}} </td>
        </ng-container>
        <ng-container matColumnDef="task">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="taskName">
            <p class="header-text">Task</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.taskName}} </td>
        </ng-container>
        <!-- <ng-container matColumnDef="FromDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="startDate">
            <p class="header-text">Start Date</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.startDate| date: "dd/MM/yyyy HH:mm:ss"}} </td>
        </ng-container> -->
        <!-- <ng-container matColumnDef="ToDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="endDate">
            <p class="header-text">End Date</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.endDate| date: "dd/MM/yyyy HH:mm:ss"}} </td>
        </ng-container> -->
        <!-- <ng-container matColumnDef="frequency">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="frequencyName">
            <p class="header-text">Frequency</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.frequencyName}} </td>
        </ng-container> -->

        <ng-container matColumnDef="topicActions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-text">Action</p>

          </th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <button mat-icon-button class="edit-btn"  aria-label="Edit Row"
                     pTooltip="Edit Task" tooltipPosition="top" (click)="editTaskModel(element)" *ngIf="IsWrite">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button  class="node-control-btn node-delete" aria-label="delete item"
                    pTooltip="Delete Task" tooltipPosition="top" (click)="DeleteTask(element)" *ngIf="IsWrite" >
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                        fill="white" />
                    </svg>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="taskColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: taskColumns;"></tr>
        <tr class="mat-row" *matNoDataRow style="text-align: center;">
          <td class="mat-cell" colspan="5">No data matching</td>
        </tr>
      </table>
      <mat-paginator
        class="basic-paginataor"
        [pageSizeOptions]="[2, 5, 10, 20]"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

<div class="sidepanel-model-wrapper edit-plant-row-model"  [ngClass]="{'active': taskModelVisible}">
  <div class="sidepanel-close" (click)="closetaskModel()" ></div>
  <div class="model-content">
    <div class="model-top">
      <h2 class="model-title">{{taskformMode ? 'Add' : 'Update'}} Task</h2>
      <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closetaskModel()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
            fill="black" />
        </svg>
      </button>
    </div>
    <div class="model-body">
      <form [formGroup]="taskForm">
        <div class="form-group">
        <mat-form-field  appearance="fill" class="field-fill">
          <mat-label class="required">Plant</mat-label>
          <mat-select  name="plant" formControlName="plantId" [disabled]="true"> 
              <mat-option  *ngFor="let p of plantsDDL" [value]="p.DeptId">  {{p.DeptDesc}} </mat-option>
            </mat-select>
            <mat-error>Required</mat-error>
        </mat-form-field>
      </div>
        <div class="form-group">
          <mat-form-field  [ngClass]="{
            'is-invalid':
              taskForm.get('projectname')?.errors &&
              taskForm.get('projectname')?.touched
          }" appearance="fill" class="field-fill">
          <mat-label class="required">Select Project</mat-label>
          <mat-select formControlName="projectname" placeholder="Select Project" (selectionChange)="getTopicbyproject()"  required>                
            <mat-option *ngFor="let p of projectDDL" [value]="p.projectId">{{p.projectName}}</mat-option>             
          </mat-select>
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
              taskForm.get('projectname')?.touched &&
              taskForm.get('projectname')?.hasError('required')">
            * project name is required!
            </p>
            <p class="invalid-feedback" *ngIf="
              taskForm.get('projectname')?.touched &&
              taskForm.get('projectname')?.hasError('pattern')">
            * please remove special charactor
            </p>

        </div>
        <div class="form-group">
          <mat-form-field  [ngClass]="{
            'is-invalid':
              taskForm.get('topic')?.errors &&
              taskForm.get('topic')?.touched
          }" appearance="fill" class="field-fill">
          <mat-label class="required">Select Topic</mat-label>
          <mat-select formControlName="topic" placeholder="Select Topic" required>                
            <mat-option *ngFor="let p of topicDDL" [value]="p.topicId">{{p.topicName}}</mat-option>             
          </mat-select>
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
              taskForm.get('topic')?.touched &&
              taskForm.get('topic')?.hasError('required')">
            * Topic is required!
            </p>
        </div>
        <div class="form-group">
          <mat-form-field  [ngClass]="{
            'is-invalid':
              taskForm.get('task')?.errors &&
              taskForm.get('task')?.touched
          }" appearance="fill" class="field-fill">
            <mat-label>Enter Task</mat-label>
            <input matInput placeholder="Enter task" formControlName="task" required>
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
              taskForm.get('task')?.touched &&
              taskForm.get('task')?.hasError('required')">
            * Task is required!
            </p>
        </div>
        <!-- <div class="form-group">
          <mat-form-field  [ngClass]="{
            'is-invalid':
              taskForm.get('frequency')?.errors &&
              taskForm.get('frequency')?.touched
          }" appearance="fill" class="field-fill">
          <mat-label class="required">Select Frequency</mat-label>
          <mat-select formControlName="frequency" placeholder="Select Frequency" required>                
            <mat-option
            *ngFor="let frequency of frequencyDDL"
            [value]="frequency.frequencyId"
          >
            {{ frequency.frequencyName }}</mat-option
          >           
          </mat-select>
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
              taskForm.get('frequency')?.touched &&
              taskForm.get('frequency')?.hasError('required')">
            * frequency is required!
            </p>
        </div> -->
        <!-- <div class="p-grid p-fluid"> -->
          <!-- <div class="p-lg-6 p-md-6 p-12">
            <div class="content-top-row inputField" >
              <nz-date-picker style="width: 100%; margin-left: 0px !important;height: 50px;" class="calender-input"  id="startPicker" formControlName="fromdate" nzFormat="dd/MM/yyyy HH:mm"
              [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
              <span class="floating-text" style="padding-left: 0px;">Start Date</span>   
            </div> -->
<!-- 
              <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <input matInput [ngxMatDatetimePicker]="startPicker" class="form-control" placeholder="Start Date" formControlName="fromdate"
              [min]="minDate" [max]="maxDate" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #startPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
              [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
              [color]="color" [enableMeridian]="enableMeridian">
            </ngx-mat-datetime-picker>
            </mat-form-field> -->
          <!-- </div> -->
          <!-- <div class="p-lg-6 p-md-6 p-12">
            <div class="content-top-row inputField" >
              <nz-date-picker style="width: 100%; margin-left: 0px !important;height: 50px;" class="calender-input"  id="endPicker" formControlName="todate" nzFormat="dd/MM/yyyy HH:mm"
              [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
              <span class="floating-text" style="padding-left: 0px;">End Date</span>   
            </div> -->

              <!-- <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <input matInput [ngxMatDatetimePicker]="endPicker" class="form-control" placeholder="End Date" formControlName="todate"
              [min]="minDate" [max]="maxDate" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #endPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
              [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
              [color]="color" [enableMeridian]="enableMeridian">
            </ngx-mat-datetime-picker>
          </mat-form-field>  -->

          <!-- </div> -->
        <!-- </div>  -->

        <div class="model-form-actions">
        <button
        (click)="updateTask()"
        *ngIf="!taskformMode"
        [disabled]="!taskForm.valid"
        type="submit"
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="primary"
        pTooltip="Update" tooltipPosition="top"
      >
        <i class="fa fa-pencil" style="color: #fff"></i>
      </button>
      <button
      (click)="saveTask()"
      *ngIf="taskformMode"
        [disabled]="!taskForm.valid"
        type="submit"
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="primary"
        pTooltip="Save" tooltipPosition="top" 
      >
        <i class="fa fa-floppy-o" style="color: #fff"></i>
      </button>
      <button
      (click)="closetaskModel()"
      type="button"
      mat-mini-fab
      mat-button
      mat-dialog-close
      color="warn"
      pTooltip="Cancel" tooltipPosition="top" 
    >
      <i class="fa fa-close" style="color: #fff"></i>
    </button>
        </div>
      </form>
    </div>
  </div>
</div>

