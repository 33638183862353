<form [formGroup]="HeaderForm" (ngSubmit)="onSubmit()">
  <div class="content-top-row headForm" style="display: flex;">

    <mat-form-field appearence="fill">
      <mat-select [(ngModel)]="HeaderData.Group" formControlName="Group" placeholder="Group"
        (selectionChange)="resetUnit()">
        <input (keyup)="onGroupKey($event.target)" (change)="onGroupKey('')" class="form-control w-100"
          placeholder="Search Group">
        <mat-option *ngFor="let group of GroupList" [value]="group.id">
          {{group.name}}
        </mat-option>
      </mat-select>
      <mat-error>Group is Required</mat-error>
    </mat-form-field>

    <mat-form-field appearence="fill" class="unit-pick">
      <input [readonly]="true" matInput formControlName="Unit" [(ngModel)]="HeaderData.UnitName" placeholder="Unit"
        (click)="openModal()">
      <span matSuffix (click)="openModal()">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15 9.375V5.625H4.6875V6.5625H2.8125V3.75H11.25V0H0V3.75H1.875V13.125H4.6875V15H15V11.25H4.6875V12.1875H2.8125V7.5H4.6875V9.375H15Z"
            fill="#824AED" />
        </svg>
      </span>
      <mat-error>Unit is Required</mat-error>
    </mat-form-field>

    <app-unit-picker [Group]="HeaderData.Group" (emitSelectedUnit)="setUnit($event)"
      [unitPickerIsOpen]="unitPickerIsOpen"></app-unit-picker>

    <!-- <mat-form-field appearance="fill" class="datefield">
      <input matInput readonly [matDatepicker]="picker" [(ngModel)]="HeaderData.Date" formControlName="Date"
        (dateChange)="dateChange()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> -->

    <nz-date-picker readonly [nzShowTime]="false" nzFormat="dd-MMM-yyyy" [(ngModel)]="HeaderData.Date" formControlName="Date" (ngModelChange)="onChange($event)"></nz-date-picker>

    <button type="submit" class="add-btn1" pTooltip="add" tooltipPosition="right">
      <i class="fa fa-plus" aria-hidden="true"></i>
    </button>
  </div>
</form>