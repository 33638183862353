import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventConfigurationService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getModal(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Variable/GetModel', data);
  }

  loadAddedParameters(data: any): Observable<any> {
    return this.http.post<any>(
      this.o3CoreApiUrl + 'Variable/LoadAddedParameters',
      data
    );
  }

  loadScript(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Variable/LoadScript', data);
  }

  loadScriptFault(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Variable/LoadScriptFault', data);
  }

  getDesktopFunctionsHistory(data: any): Observable<any> {
    return this.http.post<any>(
      this.o3CoreApiUrl + 'Variable/GetDesktopFunctionsHistory',
      data
    );
  }

  GetTEDList(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Variable/GetTEDList', data);
  }

  EvaluateExpression(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'Variable/EvaluateGrammer', data);
  }

  EvaluateExpressionFault(data: any): Observable<any> {
    return this.http.post<any>(
      this.o3CoreApiUrl + 'Variable/EvaluateGrammerFault',
      data
    );
  }

}
