import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

const token = localStorage.getItem('o3token');
const httpOptions = {
  headers: new HttpHeaders({
    Authorization: `Bearer ${token}`,
  }),
};
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  jwtHelper: any = new JwtHelperService();
  decodedToken: any;
  constructor(private http: HttpClient, private router: Router, private toaster: ToastrService) { }

  login(model: any): any {
    return this.http.post(this.o3CoreApiUrl + 'Auth/GetUserMEData', model).pipe(
      map((respose: any) => {
        const user = respose;
        if (!user.HasError) {
          if (user.Isloginsuccess) {
            localStorage.clear();
            localStorage.setItem('token', user.DeviceToken);
            localStorage.setItem('user', JSON.stringify(user));
            this.toaster.success('Success', 'Login Successfully ');
          }
          else {
            this.showPopupMsg(user.ErrorMessage);
          }
        }
      })
    );
  }

  loggedIn(): any {
    const token = localStorage.getItem('token');
    if (token !== null) {
      return true;
    } else { return false; }
  }
  isCustomerLogin(): boolean {
    const isAuthenticated = localStorage.getItem('isAuth');
    if (isAuthenticated == 'true') {
      return true;
    } else {
      return false;
    }
  }
  signUp(data: any): any {
    return this.http.post<any>(this.o3CoreApiUrl + 'signup', data, httpOptions);
  }
  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
      this.toaster.success('Success', msg.substring(3, msg.length));
    }
    else {
      this.toaster.error('Error', msg.substring(3, msg.length));
    }
  }

  getUserGroup(id: any): Observable<any> {
    return this.http.post(this.o3CoreApiUrl + 'Users/Usergroup', id);
  }
  getUserRoles(id: any): Observable<any> {
    return this.http.post(this.o3CoreApiUrl + 'Users/Rolesbyuser', id);
  }
  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.clear();
    this.decodedToken = null;
    this.router.navigate(['/login']);
  }
}
