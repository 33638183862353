import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { TaskService } from 'src/app/services/task.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';

@Component({
  selector: 'app-Task',
  templateUrl: './Task.component.html',
  styleUrls: ['./Task.component.scss']
})
export class TaskComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  taskData!: MatTableDataSource<any>;
  taskForm!: FormGroup;
  taskColumns: string[] = ['topicActions', 'project', 'topic', 'task',  ];
  taskModelVisible = false;
  taskformMode!: boolean;
  plantsDDL!: any[];
  projectDDL!: any[];
  frequencyDDL!: any[];
  IsAccess:any;
  IsWrite:any;
  topicDDL!: any[];
  projectId!: any;
  topicId!: any;
  taskId!: any;
  frequencyId!: any;
  groupId!: number;
  plantId!: number;
  topics!: any[];
  task!: any[];
  isUpdateTask = false;
  topic!: any;
  constructor(
     private fb: FormBuilder,
     private taskService: TaskService,
     private kpiService: KpimasterService,
     private platModelService: EnterPriseGroupService,
     private toaster: ToastrService
     ){ }
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  ngOnInit(): void {
    this.taskForm = this.fb.group({
      plantId: ['', Validators.required],
      projectname: ['', Validators.required, ],
      topic: ['', Validators.required],
      task: ['', Validators.required, ],
      frequency: ['', Validators.required],
      fromdate: [''],
      todate: [''],
    });
    this.GetMenuAccess();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
      // localStorage.setItem('GetMenuAccess', this.permisson);
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  openTaskModal(): void {
    // this.resetForm();
    this.getMasterfrequency();
    this.getMasterproject();
    // this.getMastertopic();
    this.getPlantsByGroup();
    this.taskformMode = true;
    // this.taskForm.get('plant')?.setValue(this.plantId);
    this.taskModelVisible = true;
  }
  openEditTaskModal(): void {
    // this.getMasterproject();
    this.taskformMode = false;
    this.taskModelVisible = true;

  }
  getPlantsByGroup(): void {
    const key = {
      GroupId: this.groupId
    };
    this.taskService.getPlantsData(key).subscribe(
      (plants: any) => {
        this.plantsDDL = plants.DepartList;
        this.taskForm.get('plantId')?.setValue(this.plantId);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getTaskMaster(key: any): void {
    this.plantId = key.plantId;
    this.groupId = key.groupId;
    this.taskForm.get('group')?.setValue(key.groupId);
    this.taskService.getPlantsData(key).subscribe(
      (plants: any) => {
        this.plantsDDL = plants.DepartList;
        this.taskForm.get('plantId')?.setValue(this.plantId);
        this.gettaskList();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
}
getMasterproject(): void {
  const key = {
plantId:  this.plantId
  };

  this.taskService.getMasterProject(key).subscribe((data) => {
    this.projectDDL = data;
    this.taskForm.get('projectname')?.setValue(this.projectDDL[0].projectId);
    // tslint:disable-next-line: deprecation
    this.getTopicbyproject();
  },
    (error) => {
      this.toaster.error('Error', error.error.message);
    });
}

getMasterfrequency(): void {
  const key = {
plantId:  this.plantId
  };
  this.taskService.getMasterFrequencies(key).subscribe((data) => {
    this.frequencyDDL = data;
    this.taskForm.get('frequency')?.setValue(this.frequencyDDL[0].frequencyId);
  },
    (error) => {
      this.toaster.error('Error', error.error.message);
    });
}
gettaskList(): void {
  const key = {
 plantId: this.plantId
  };
  this.taskService.getMasterTask(key).subscribe((data) => {

    this.taskData = new MatTableDataSource<any>(data);
    this.getPaginator();
  },
    (error) => {
      this.toaster.error(error.error.message);

    });

}
saveTask(): void {
  const task = {
    plantId : this.taskForm.value.plantId,
    projectId : this.taskForm.value.projectname,
    topicId : this.taskForm.value.topic,
    taskName : this.taskForm.value.task,
    startDate : this.taskForm.value.fromdate,
    endDate : this.taskForm.value.todate,
    frequencyId: this.taskForm.value.frequency,
    userId: JSON.parse(localStorage.user).UserId,
  };
  this.taskService.addTask(task).subscribe(
    (response) => {
      this.toaster.success('Success', 'Topic Added Successfully');
      this.gettaskList();
      this.closetaskModel();
      // this.resetForm();
    },
    (error) => {
      this.toaster.error('Error', error.error.message);
    }
  );
}
DeleteTask(element: any): void {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to delete task!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it',
  }).then((result) => {
    if (result.value) {
      console.log(element);
      const id = {
        taskId: element.taskId
      };
      this.taskService.deleteTask(id).subscribe((response) => {
          Swal.fire({
            title: 'Deleted!',
            text: 'Your selected task has been deleted.',
            icon: 'success',
            timer: 800,
            showConfirmButton: false,
          });
          this.gettaskList();
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire('Cancelled', 'Your selected task is safe :)', 'error');
    }
  });

}

editTaskModel(element: any): void {
  this.getMasterproject();
  this.getMasterfrequency();
  this.taskId = element.taskId;
  const key = {
    taskId: element.taskId,
    topicId: element.topicId,
    frequencyId: element.frequencyId,
  };
  this.taskService.GetOneMasterTask(key).subscribe((Response) => {
    this.openEditTaskModal();
    this.taskForm.get('plantId')?.setValue(this.plantId);
    this.taskForm.get('projectname')?.setValue(Response.projectId);
    this.taskForm.get('frequency')?.setValue(Response.frequencyId);
    this.taskForm.get('task')?.setValue(Response.taskName);
    this.getTopicbyproject();
    this.topic = element.topicId;
  }, (error) => {
    this.toaster.error('Error', error.error.message);
  });


}
updateTask(): void {
  const task = {
    taskId: this.taskId,
    plantId : this.taskForm.value.plantId,
    projectId : this.taskForm.value.projectname,
    topicId : this.taskForm.value.topic,
    taskName : this.taskForm.value.task,
    frequencyId: this.taskForm.value.frequency,
    userId: JSON.parse(localStorage.user).UserId,
  };
  this.taskService.updateTask(task).subscribe(
    (response) => {
      this.toaster.success('Success', 'Task Updated Successfully');
      this.gettaskList();
      // this.getMasterproject();
      this.closetaskModel();
      this.resetForm();

      // this.gettopicList(this.topicId);
      this.isUpdateTask = false;
    },
    (error: any) => {
      this.toaster.error('Error', error.error.message);

    }
  );
}
getTopicbyproject(): void {
  const key = {
    projectId: this.taskForm.get('projectname')?.value
  };
  // this.MachineId = null;
  this.taskService.getTpoicProject(key).subscribe((data: any) => {
    this.topicDDL = data;
    if (this.taskformMode) {
      this.taskForm.get('topic')?.setValue(this.topicDDL[0].topicId);
    } else {
    this.taskForm.get('topic')?.setValue(this.topic);
    }
  }, (error: any) => {
    this.toaster.error('Error', error.message);
  });
}

onProjectChange(): any {
  const key = {
    projectId: this.taskForm.value.projectname,
  };
  this.taskService.getTpoicProject(key).subscribe(
    (task) => {
      this.task = task;
    },
    (error) => {
      this.toaster.error('Error', error.error.message);
    }
  );
}
applyFilter(event: Event): void {
  const filterValue = (event.target as HTMLInputElement).value;
  this.taskData.filter = filterValue.trim().toLowerCase();

  if (this.taskData.paginator) {
    this.taskData.paginator.firstPage();
  }
}
getPaginator(): void {
  setTimeout(() => {
    this.taskData.paginator = this.paginator;
    this.taskData.sort = this.sort;
  }, 1000);
}
  resetForm(): void {
    this.taskForm.reset();
    this.isUpdateTask = false;
  }
  closetaskModel(): void {
    this.taskModelVisible = false;
    this.resetForm();
  }
}
