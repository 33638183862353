import { Component, OnInit, ViewChild, ViewChildren, QueryList, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ThemePalette } from '@angular/material/core';
import { UserManager } from 'src/app/models/usermanagement/userManager';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import Swal from 'sweetalert2';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-usermanager',
  templateUrl: './usermanager.component.html',
  styleUrls: ['./usermanager.component.scss']
})
export class UsermanagerComponent implements OnInit {
  managerId: any;
  groupList!: any[];
  formMode!: boolean;
  isaddNewUserModeItemModelVisible = false;
  UserId!: number;
  formLoader!: boolean;
  userModelRowId!: number;
  displayUserModal = false;
  groupId!: any;
  isUserGroupModelVisible = false;
  usergroup!: any;
  isaddNewGroupItemModelVisible!: boolean;
  GroupId!: number;
  breadcrumList: any;

  constructor(
    private toaster: ToastrService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private roleService: UserManagementService
  ) { }
  public color: ThemePalette = 'primary'
  public formGroup = new FormGroup({
    UserName: new FormControl('', [Validators.required]),
    Password: new FormControl('', [Validators.required]),
    Mobile: new FormControl('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(15)]),
    Email: new FormControl('', [Validators.email]),
    Name: new FormControl('', [Validators.required]),
    IsAdmin: new FormControl(''),
  });
  public userForm = new FormGroup({
    group: new FormControl('', [Validators.required]),
  });
  usermanagerdata!: UserManager[];
  // tslint:disable-next-line:max-line-length
  userColumns: string[] = ['managerId', 'userName', 'name', 'email', 'mobile', 'isGroupAdmin', 'createdBy','createdon', 'userManagerActions'];
  userData!: MatTableDataSource<UserManager>;


  @ViewChild(MatTable) table!: MatTable<UserManager> ;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: any;

ngOnInit(): void {
  this.formMode = true;
  this.getGroupsData();
}

getGroupsData(): void {
  const key = {
    Id: 0,
  };
  this.roleService.getGroupData(key).subscribe((data: any) => {
    this.groupList = data.masterList;
    this.GroupId = this.groupList[0].TypeId;
    this.getMasterUsers();
  },
    (error) => {
      this.toaster.error('Error', error.error.message);
    }
  );
}
getMasterUsers(): void {
  const key = {
    GroupId: this.GroupId,
    UserId: JSON.parse(localStorage.user).UserId};
    this.spinner.show();
  this.roleService.getUsersData(key).subscribe(
    (users: any) => {
      this.userData = new MatTableDataSource<any>(users.UserList);
      this.spinner.hide();
      this.getPaginator();
    },
    (error) => {
    this.spinner.hide();

      this.toaster.error(error.error.message);
    }
  );
}
onGroupChange(event: any): void {
  this.getMasterUsers();
}

openModal(): void {
  this.formMode = true;
  this.formGroup.reset();
  this.displayUserModal = true;
}
openModaledit(): void {
  this.formMode = false;
  this.displayUserModal = true;
  this.getMasterUsers();
}
getPaginator(): void {
  setTimeout(() => {
    this.userData.paginator = this.paginator;
    this.userData.sort = this.sort;
  }, 1000);
}

closeaddNewPlantItemModel(): void {
  this.displayUserModal = false;
}

addNewPlantModeItemlFormSubmit(): void {
  const groupKey = {
    UserName: this.formGroup.value.UserName,
    Password: this.formGroup.value.Password,
    Name: this.formGroup.value.Name,
    Mobile: this.formGroup.value.Mobile,
    Email: this.formGroup.value.Email,
    IsAdmin: this.formGroup.value.IsAdmin,
    UserId: JSON.parse(localStorage.user).UserId,
    GroupId: this.userForm.value.group,
    TransactionTypeId: 1
  };
  this.roleService.submitUsersData(groupKey).subscribe(
    (response: any) => {
      this.showPopupMsg(response.ErrorMessage);
      this.getMasterUsers();
      this.displayUserModal = false;
    },
    (error) => {
      this.showPopupMsg(error.ErrorMessage);
    }
  );
  this.table.renderRows();
  this.formGroup.reset();
  this.isaddNewUserModeItemModelVisible = false;
}

openEditPlantItemModel(element: any): void {
  const key = {
    UserId: element.UserId,
    GroupId: this.userForm.value.group
  };
  this.roleService.getUsersData(key).subscribe((data: any) => {
    this.openModaledit();
    this.userModelRowId = element.UserId;
    this.formGroup.get('UserName')?.setValue(element.UserName);
    this.formGroup.get('Password')?.setValue(element.Password);
    this.formGroup.get('Name')?.setValue(element.Name);
    this.formGroup.get('IsAdmin')?.setValue(element.IsAdmin);
    this.formGroup.get('Mobile')?.setValue(element.Mobile);
    this.formGroup.get('Email')?.setValue(element.Email);
  },
    (error) => {
      this.toaster.error('Error', error.error.message);
    }
  );
}
openaddNewPlantItemModel(): void {
  this.displayUserModal = true;
  this.formMode = true;
}

openPlantGroupModel(element: any): void {
  this.isUserGroupModelVisible = true;
  this.getMasterUsers();
  this.usergroup = element;
}
applyFilter(event: Event): void {
  const filterValue = (event.target as HTMLInputElement).value;
  this.userData.filter = filterValue.trim().toLowerCase();

  if (this.userData.paginator) {
    this.userData.paginator.firstPage();
  }
}

editPlantModeItemlFormSubmit(): void {
  const groupKey = {
    UserName: this.formGroup.value.UserName,
    Password: this.formGroup.value.Password,
    Name: this.formGroup.value.Name,
    Mobile: this.formGroup.value.Mobile,
    Email: this.formGroup.value.Email,
    IsAdmin: this.formGroup.value.IsAdmin,
    UserId: this.userModelRowId,
    GroupId: this.userForm.value.group,
    TransactionTypeId: 2
  };
  console.log(groupKey);
  this.roleService.submitUsersData(groupKey).subscribe(
    (response: any) => {
      this.showPopupMsg(response.ErrorMessage);
      this.getMasterUsers();
      this.formGroup.reset();
      this.displayUserModal = false;
    },
    (error) => {
      this.showPopupMsg(error.ErrorMessage);
    }
  );
  this.table.renderRows();
  this.isaddNewUserModeItemModelVisible = false;
  this.table.renderRows();
}

deleteGroupNode(node: any): void {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'You want to delete it' ,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const data = {
          UserName: '',
          Password:  '',
          IsAdmin: '',
          IsOptional:  true,
          IsActive:  true,
          Mobile:  '',
          Email: '',
          TransactionTypeId: 3,
          UserId: node.UserId,
          GroupId: this.userForm.value.group
        };
        console.log(data);
        this.roleService.submitUsersData(data).subscribe((response: any)=> {
          if (response) {
            this.isaddNewGroupItemModelVisible = false;
            this.isUserGroupModelVisible = true;
            if(response?.ErrorMessage.includes('C1')){
              this.toaster.warning('Warning!',response?.ErrorMessage);
            }
            else{
              this.toaster.success('Success',response?.ErrorMessage)
            }
            this.getMasterUsers();
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your '  + node.text + ' is safe :)', 'error');
      }
    });
}

showPopupMsg(msg: any): any {
  if (msg.substring(2, 1) === '0') {
     this.toaster.success('Success', msg.substring(3, msg.length));
     this.isaddNewUserModeItemModelVisible = false;
   }
  else {
    this.toaster.error('Error', msg.substring(3, msg.length));
  }
}

}
