import { ElementRef, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';

import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { DefectManagementElement } from 'src/app/models/defectManagementElement';
import {
  PlanGroupComponent,
  PlantGroupElement,
} from 'src/app/models/O3AdministrationModels/plant-model';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { DefectConfigurationService } from 'src/app/services/defect-configuration.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { DefectActionService } from 'src/app/services/defect-action.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DisabledTimeFn } from 'ng-zorro-antd/date-picker';
import { PeopleMasterDataService } from 'src/app/services/people-master-data-service';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { CustomTooltip } from 'src/app/custom-tooltip.component';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { BtnCellRendererDefect } from 'src/app/btn-cell-rendererDefect.component';
import { MatCheckboxRendererComponent } from 'src/app/mat-checkbox-renderer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-defect-configuration',
  templateUrl: './defect-configuration.component.html',
  styleUrls: ['./defect-configuration.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({
          left: '10px',
          height: '0px',
          minHeight: '0',
          visibility: 'hidden',
        })
      ),
      state(
        'expanded',
        style({ left: '10px', height: '*', visibility: 'visible' })
      ),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})

export class DefectConfigurationComponent implements OnInit {
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  filteredGroups!: any[];
  filteredPlant!: any[];
  breadcrumList: any;
  searchValue:string = '';
  IsWrite: any;
  IsAccess: any;
  IsDelete: any;
  rows!: FormArray;
  departmentList: any;
  allDepartmentList: any;
  gembaActionform!: FormGroup;
  completionCheck!: boolean;
  defectId!: any;
  image: any;
  responsibleArray!: any[];
  raciUsers!: any[];
  isClosedButtonEnabled!: any[];
  DefectformMode = true;
  minDate!: Date;
  UserIdsforNotification!: any[];
  actionDataSource = new BehaviorSubject<AbstractControl[]>([]);
  actionColumns: string[] = [
    'action',
    'departments',
    'responsible',
    'remarks',
    'target',
    'isRequiredPictureAfter',
    'remove',
  ];
  formMode!: boolean;
  componentId: any;
  plantGroupComponentSource = new MatTreeNestedDataSource<PlanGroupComponent>();
  treeControlComponent = new NestedTreeControl<PlanGroupComponent>(
    (node) => node.children
  );
  imageNames: File[] = [];
  closeFile!: File ;
  ImageUrl: any;
  currentImagePath!: any;
  units: any;
  filteredUnits: any;
  components: any;
  filteredcomponents: any;
  Line: any;
  filteredLine: any;
  filteredAssembly: any;
  assembly: any;
  defect: any;
  moduleId!: any;
  pageId!: any;
  LineModel: any[];
  filteredLineModel: any[];
  unitsModel: any[];
  filteredUnitsModel: any[];
  assemblyModel: any[];
  filteredAssemblyModel: any[];
  componentsModel: any[];
  filteredcomponentsModel: any[];
  columnDefs: any;
  columnDefsActionView = [
    {
      headerName: 'Action Name',
      field: 'ActionName',
      sortable: true,
      filter: true,
      resizable: true,
      wrapText: true,
    },
    {
      headerName: 'Responsible',
      field: 'responsibleName',
      sortable: true,
      filter: true,
      resizable: true,
      wrapText: true,
    },
    {
      headerName: 'Observation',
      field: 'Observation',
      sortable: true,
      filter: true,
      resizable: true,
      wrapText: true,
    },
    {
      headerName: 'Remarks',
      field: 'Remarks',
      sortable: true,
      filter: true,
      resizable: true,
      wrapText: true,
    },
    {
      headerName: 'TargetDate',
      field: 'TargetDate',
      sortable: true,
      filter: true,
      resizable: true,
      wrapText: true,
      valueFormatter: function (params: any) {return params.value !== null ?  moment(params.value).format('MM/DD/YYYY HH:mm:ss') : '' }
    },
    {
      headerName: 'NewTargetDate',
      field: 'NewTargetDate',
      sortable: true,
      filter: true,
      resizable: true,
      wrapText: true,
      valueFormatter: function (params: any) {return params.value !== null ?  moment(params.value).format('MM/DD/YYYY HH:mm:ss') : '' }
    },
    {
      headerName: 'No Of Misses',
      field: 'NoOfMisses',
      sortable: true,
      filter: true,
      resizable: true,
      wrapText: true,
    },
    {
      headerName: 'PictureAfter Req',
      field: 'IsRequiredPictureAfter',
      sortable: true,
      filter: true,
      resizable: true,
      wrapText: true,
      cellRenderer: 'checkboxRenderer',
      cellRendererParams: {disable: true},
      editable: false,
    },
    {
      field: 'PictureAfter',
      headerName: 'Picture After',
      sortable: true,
      resizable: true,
      filter: false,
      cellRenderer: (params: any) => {
        let html = `<img style="width: 50px; height: 40px;" src="assets/images/no-image.png"/>`;
        if (params && params.value) {
          html = '';
          html = `<img style="width: 50px; height: 40px;" src="${this.ImageUrl + params.value}"/>`;
         
        }
        return html;
      },
    },
    {
      headerName: 'Rejected',
      field: 'IsRejected',
      sortable: true,
      filter: true,
      resizable: true,
      wrapText: true,
      cellRenderer: 'checkboxRenderer',
      cellRendererParams: {disable: true},
      editable: false,
    },
    {
      field: 'IsCompleted',
      headerName: 'Completed',
      sortable: true,
      filter: true,
      resizable: true,
      wrapText: true,
      cellRenderer: 'checkboxRenderer',
      cellRendererParams: {disable: true},
      editable: false,
    },
    {
      field: 'IsVerified',
      headerName: 'Verified',
      sortable: true,
      filter: true,
      resizable: true,
      width: 200,
      wrapText: true,
      cellRenderer: 'checkboxRenderer',
      cellRendererParams: {disable: true},
      editable: false,
    },
  ];
  rowData: any;
  rowDataViewActions!: any;
  gridApi: any;
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderDefect: BtnCellRendererDefect,
      btnCellRenderer: BtnCellRenderer,
      customTooltip: CustomTooltip,
      checkboxRenderer: MatCheckboxRendererComponent,
    },
  };
  gridColumnApi: any;
  ButtonType: string;
  previewerColumns: string[] = ['Desc', 'Action', 'Remove'];
  previewerData!: MatTableDataSource<any>;
  filesForm!: FormGroup;
  files: File[] = [];
  checked = true;
  departmentListMachine!: any;
  displayMaximizable = false;
  images: any[] = [];
  fileType!: string;
  imageIndexOne = 0;
  fileDesc: any;
  doc!: any;
  defectlist: any;
  ClosedDefect: number;
  OpenedDefect: number;
  Fixing: number;
  FileData: any = [];
  CloseFormMode = false;
  checkedClose = false;
  uploadImage = true;
  CloseImage = '';
  disableImage = false;
  checkBoxDisable = false;
  constructor(
    private defectActionService: DefectActionService,
    private notificationService: NotificationService,
    private userService: UserManagementService,
    private defectService: DefectConfigurationService,
    private dialog: MatDialog,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    public router: Router,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private machineService: MachineMappingService,
    private spinner: NgxSpinnerService,
    private peopleService: PeopleMasterDataService,
  ) {}

  get addNewVariableItemlFormControls() {
    return this.addNewVariableItemlForm.controls;
  }

  get searchDefectFormControls() {
    return this.searchDefectForm.controls;
  }

  selected: string | undefined;
  selectedMachine: string | undefined;
  selectedComponent: string | undefined;
  selectedDefectType!: string;
  selectedSubDefect!: string;
  selectedDefectPriority!: string;
  selectedDefectStatus!: string;
  selectedDepartment!: string;
  selectedDepartmentMachine!: string;
  selectedGroup!: string;
  SelectedLine!: string | undefined;
  SelectedLine1!: string | undefined;

  // newTree

  isproductionDBModel = false;
  isPopupMachine = false;
  displayComponentModal = false;
  groups!: any[];
  plants!: any[];
  lineList!: any[];
  machineList!: any[];
  componentList!: any[];
  defectType!: any[];
  subDefectType!: any[];
  defectPriority!: any[];
  defectStatus!: any[];
  department!: any[];
  plantList = ['P1', 'P2'];
  id!: any;
  isAllowed = false;

  // Date-Time Picker Variable
  public date!: moment.Moment;
  public targetDate!: moment.Moment;
  public closeDate!: moment.Moment;
  public color: ThemePalette = 'primary';

  // Icon Url
  grpImgurl = 'assets/images/grp-icon.png';
  pltImgurl = 'assets/images/plt-icon.png';
  divImgurl = 'assets/images/div-icon.png';
  dptImgurl = 'assets/images/dpt-icon.png';
  linImgurl = 'assets/images/lin-icon.png';
  untImgurl = 'assets/images/unt-icon.png';
  asmImgurl = 'assets/images/asm-icon.png';
  samImgurl = 'assets/images/sam-icon.png';
  copImgurl = 'assets/images/cop-icon.png';
  collapseAllImgurl = 'assets/images/collapse-all-icon.png';
  expandAllImgurl = 'assets/images/expand-all-icon.png';
  downloadIconurl = 'assets/images/download-image-icon.png';
  uploadIconurl = 'assets/images/upload-icon.png';
  editIconurl = 'assets/images/edit-icon.png';
  deleteIconurl = 'assets/images/delete-icon.png';
  messageIconurl = 'assets/images/message-icon.png';

  // Table
  DefectManagementElementData: DefectManagementElement[] = [];
  defectManagementVariableGroupColumns: string[] = [
    // 'defectID',
    'defect',
    'Line',
    'machineName',
    'Assembly',
    'component',
    'priority',
    'defectStatus',
    'department',
    'Action',
  ];
  defectManagementVariableGroupData =
    new MatTableDataSource<DefectManagementElement>([]);
  expandedElement: any;

  // Add new variable Group Form
  iscreateDeffectGroupModelVisible = false;
  addNewVariableItemlForm!: FormGroup;
  addNewVariableItemlFormSubmitted = false;

  // Edit table form
  iseditDeffectGroupModelVisible = false;
  editTableVariableItemlForm!: FormGroup;
  editTableVariableItemlFormSubmitted = false;
  editGroupItemParent!: DefectManagementElement;
  editTableRowID!: number;
  editGroupModelTitle!: string;
  today = new Date();
  timeDefaultValue = setHours(new Date(), 0);
  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    targetDate: new FormControl(null, [Validators.required]),
    closeDate: new FormControl(null, [Validators.required]),
  });

  // Search form
  searchForm!: FormGroup;
  isSarchFormVisible = false;

  // upload file name
  uploadFileInfo!: string;
  postImageFileInfo!: string;

  DefectDataForm!: FormGroup;
  CloseDefectForm!: FormGroup
  // Search defect filter form
  searchDefectForm!: FormGroup;
  LineNode: any;
  UnitName!: string;
  Group!: string;
  ComponentNode: any;
  ComponentName!: string;
  isUnitName = false;
  searchDefectFormSubmitted = false;
  // tslint:disable-next-line:variable-name
  _isDisabled = true;

  isPlantGroupModelVisible = false;
  isCompGroupModelVisible = false;

  isGetDefectFlag = false;
  isCreateDefectFlag = false;

  @ViewChild(MatTable) table!: MatTable<DefectManagementElement>;
  @ViewChild('displayActionModal')
  private displayActionModal!: TemplateRef<any>;
  @ViewChild('displayCreateDefectModal')
  private displayCreateDefectModal!: TemplateRef<any>;
  @ViewChildren(MatPaginator) paginator2 = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort2 = new QueryList<MatSort>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('ViewActionDetailModel') private ViewActionDetailModel!: TemplateRef<any>;
  @ViewChild('previewModal') private previewModal!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('CloseDefect') private CloseDefect!: TemplateRef<any>;
  compTreeSource = new MatTreeNestedDataSource<any>();

  plantGroupDataParent!: PlantGroupElement;

  // parent node
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.ImageUrl = environment.O3CoreApiImageUrl;
    this.filesForm = this.fb.group({
      file: [],
    });
    this.DefectDataForm = this.formBuilder.group({
      group: [''],
      plant: [''],
    });
    this.CloseDefectForm = this.formBuilder.group({

      remarks:[''],
      image: [''] ,
    })
    this.searchDefectForm = this.formBuilder.group({
      assembly: [''],
      line: [''],
      component: [''],
      UnitDataList: [''],
      department: [''],
      machine: [''],
      defectPriority: [''],
      defectStatus: [''],
      SelectedLine: [''],
      machineId: new FormControl('', []),
    });
    this.minDate = new Date();
    this.responsibleArray = [];
    this.raciUsers = [];
    this.isClosedButtonEnabled = [];
    this.gembaActionform = this.fb.group({
      actionsArray: this.fb.array([this.actionForm()]),
    });
    this.rows = this.gembaActionform.get('actionsArray') as FormArray;
    this.completionCheck = true;
    const d = new Date();
    d.setDate(d.getDate() - 7); // setMonth(d.getMonth() - 3);

    // add new create defect form
    this.addNewVariableItemlForm = this.formBuilder.group({
      id: new FormControl('', []),
      assemblyy: [''],
      lines: [''],
      component: [''],
      machine: [''],
      defect: ['', Validators.required],
      defectType: ['', Validators.required],
      subDefectType: [''],
      defectPriority: ['', Validators.required],
      defectStatus: [''],
      department: [''],
      image: [''],
      date: [''],
      remarks: [''] ,
      departmentMachine : ['']
    });
    this.CloseDefectForm.get('image').setValidators(Validators.required)
    
    this.addNewVariableItemlForm.get('assemblyy').setValidators(Validators.required);
    this.addNewVariableItemlForm.get('lines').setValidators(Validators.required);
    this.addNewVariableItemlForm.get('machine').setValidators(Validators.required);
    this.addNewVariableItemlForm.get('image').setValidators(Validators.required);
    this.addNewVariableItemlForm.get('departmentMachine').setValidators(Validators.required);
    // edit defect form
    this.editTableVariableItemlForm = this.formBuilder.group({
      defect: ['', Validators.required],
      defectType: ['', Validators.required],
      subDefectType: ['', Validators.required],
      defectPriority: ['', Validators.required],
      defectStatus: ['', Validators.required],
      defectOn: ['', Validators.required],
      comments: ['', Validators.required],
      assignTo: ['', Validators.required],
      targetDate: ['', Validators.required],
      closeDate: ['', Validators.required],
      fileName: [''],
    });
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroups();
  }

  // breadCrumb
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        this.IsDelete = data.IsDelete;
        this.columnDefs = [
          {
            field: 'Actions',
            headerName: 'Actions',
            cellRenderer: 'btnCellRenderDefect',
            cellRendererParams: {
              clicked: function (field: any) { },
            },
            sortable: true,
            filter: true,
            resizable: true,
            width: 300,
            wrapText: true,
            hide: !this.IsWrite,
          },
        {
          headerName: 'Defect',
          field: 'defect',
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
        },
        {
          field: 'lineName',
          headerName: 'Line',
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
        },
        {
          field: 'machineName',
          headerName: 'Machine',
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
          tooltipValueGetter: (params: any) => params.value,
        },
        {
          field: 'Assbly_Desc',
          headerName: 'Assembly',
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
          tooltipValueGetter: (params: any) => params.value,
        },
        {
          field: 'componentName',
          headerName: 'Componenet',
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
        },
        {
          field: 'priorityName',
          headerName: 'Priority',
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
          width: 130,
        },
        {
          field: 'statusName',
          headerName: 'DefectStatus',
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
          width: 150,
          cellStyle: (params: any) => {
            if (params.value === 'Closed') {
              return {
                color: '#ffffff',
                'font-weight': 'bold',
                background: 'linear-gradient( 148deg,rgb(107, 246, 86) 0%,rgb(16, 177, 64) 53%,rgb(36, 234, 5) 100%)'
              };
            } else if (params.value === 'InProgress') {
              return {
                color: '#000000',
                backgroundColor: '#ffff00',
                'font-weight': 'bold',
              };
            }
            else if (params.value === 'Open') {
              return {
                color: '#ffffff',
                background : 'linear-gradient(148deg, #ef5735 0%, #e91111 53%, #ff0040 100%)' ,
                'font-weight': 'bold',
              };
            }
            else if (params.value === 'Delayed') {
              return {
                color: '#ffffff',
                background : '#fd5e53' ,
                'font-weight': 'bold',
              };
            }
            else {
              return {
                color: '',
                backgroundColor: '',
                'font-weight': 'bold',
              };
            }
          },
        },
        {
          field: 'departmentName',
          headerName: 'Department',
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
        },
        {
          field: 'remarks',
          headerName: 'Remarks',
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
        },
        {
          field: 'createdOn',
          headerName: 'Createdon',

          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
          cellRenderer: (data: any) => {
            return data.value
              ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
              : '';
          },
        },
        {
          field: 'fileUpload',
          headerName: 'Image',
          sortable: true,
          resizable: true,
          width: 100,
          hide: true ,
          cellRenderer: (params: any): any => {
            return params.value
              ? '<img style="height:40px;width:40px" src="' +
              params.value +
              '" />'
              : '<img style="height:40px;width:40px" src="assets/images/no-image.png" />';
          },
        }
      ];
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  OnChangeGroup(element: any): void {
    this.selectedGroup = element.value;
    this.getPlantByGroup();
    this.getDefectStatusDDL();
  }
  actionForm(): FormGroup {
    return this.fb.group({
      actionName: [],
      targetDate: [],
      noOfMisses: [],
      responsibleId: [],
      newTargetDate: [],
      completed: [],
      verified: [],
      isRequiredPictureAfter: [],
      remarks: [],
      defectTransactionActionId: [],
      department: [],
    });
  }
  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.spinner.show();
    this.defectService.getGroupData(key).subscribe((res: any) => {
      this.groups = res.masterList;
      this.DefectDataForm.get('group')?.setValue(this.groups[0].TypeId);
      this.selectedGroup = this.groups[0].TypeId;
      this.getPlantByGroup();
      this.getDefectStatusDDL();
      if (this.groups.length > 0) {
        this.filteredGroups = this.groups.slice();
      }
    });
    this.spinner.hide();
  }

  sendNotificationRole(descr: any): void {
    const key = {
      roleName: 'Section Manager',
      egId: this.DefectDataForm.value.group,
      departmentId: this.addNewVariableItemlForm.value.department,
      createdBy: JSON.parse(localStorage.user).UserId,
      desc: descr,
      title: 'Defect Management',
    };
    this.notificationService.addnotificationdepartmentwise(key).subscribe(
      (response: any) => {
        this.toaster.success('Notification Sent');
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  deleteRecord(element: any, i: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this Record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        this.removeAt(element, i);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Record is safe :)', 'error');
      }
    });
  }

  removeAt(element: any, i: any): void {
    if (
      element.value.defectTransactionActionId === null ||
      element.value.defectTransactionActionId === undefined
    ) {
      this.rows.removeAt(i);
      this.updateView();
    } else {
      const key = {
        defectTransactionId: element.value.defectTransactionActionId,
      };
      this.defectActionService
        .deleteDefectActions(key)
        .subscribe((data: any): any => {
          this.getDefectActionById();
        });
    }
  }

  getdepartment(): void {
    const key = {
      ptId: this.DefectDataForm.value.plant,
    };
    this.machineService.getDepartmenBytPlant(key).subscribe(
      (response: any) => {
        this.departmentList = response;
        
        this.selectedDepartment = this.departmentList[0].TypeName;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getAlldepartment(): void {
    const key = {
      Id: this.DefectDataForm.value.plant,
    };
    this.machineService.getAllDepartmentData(key).subscribe(
      (response: any) => {
        this.allDepartmentList = response.masterList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.DefectDataForm.value.group,
    };
    this.defectService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.DefectDataForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getdepartment();
        this.getAlldepartment();
        this.getLine('NormalMode');
        this.getDefectTypeDLL();
        this.getSubDefectTypeDLL();
        this.getDepartmentsMachine();
        if (this.plants.length > 0) {
          this.filteredPlant = this.plants.slice();
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getLine(status: any): void {
    if (status == 'NormalMode') {
      this.defect = [];
      const key = {
        PT_ID: this.DefectDataForm.value.plant,
        userId: JSON.parse(localStorage.user).UserId,
      };
      this.Line = [];
      this.filteredLine = [];
      this.units = [];
      this.filteredUnits = [];
      this.assembly = [];
      this.filteredAssembly = [];
      this.components = [];
      this.filteredcomponents = [];
      this.machineService.getLineList(key).subscribe(
        (data: any) => {
          this.Line = data.lstLine;
          this.filteredLine = this.Line.slice();
          if ((status = 'NormalMode')) {
            this.searchDefectForm.get('line')?.setValue('0');
          }
          this.getUnits(status);
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      const key = {
        PT_ID: this.DefectDataForm.value.plant,
        userId: JSON.parse(localStorage.user).UserId,
      };
      this.LineModel = [];
      this.filteredLineModel = [];
      this.unitsModel = [];
      this.filteredUnitsModel = [];
      this.assemblyModel = [];
      this.filteredAssemblyModel = [];
      this.componentsModel = [];
      this.filteredcomponentsModel = [];
      this.machineService.getLineList(key).subscribe(
        (data: any) => {
          this.LineModel = data.lstLine;

          this.filteredLineModel = this.LineModel.slice();
          this.addNewVariableItemlForm
            .get('lines')
            ?.setValue(this.LineModel[0].PL_Id);
          this.getUnits(status);
          if (this.defect.length !== 0 && status == 'EditMode') {
            this.addNewVariableItemlForm
              .get('lines')
              ?.setValue(this.defect.PL_Id);
            this.getUnits(status);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  getUnits(status: any): void {
    let key: { PL_ID: any; userId: any };
    if (status == 'NormalMode') {
      var selectedLine: any[] = [];
      if (this.searchDefectForm.value?.line == 0) {
        for (var i = 0; i < this.Line?.length; i++) {
          selectedLine.push(this.Line[i].PL_Id);
        }
      } else {
        selectedLine.push(this.searchDefectForm.value?.line);
      }
      key = {
        PL_ID: selectedLine.toString(),
        userId: JSON.parse(localStorage.user).UserId,
      };
      this.units = [];
      this.filteredUnits = [];
      this.assembly = [];
      this.filteredAssembly = [];
      this.components = [];
      this.filteredcomponents = [];
      this.machineService.getUnitList(key).subscribe(
        (data: any) => {
          this.units = data.lstUnit;

          this.filteredUnits = this.units.slice();
          this.searchDefectForm.get('machine')?.setValue('0');
          this.getAssembly(status);
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      var selectedLine: any[] = [];
      if (this.addNewVariableItemlForm.value?.lines == 0) {
        for (var i = 0; i < this.LineModel?.length; i++) {
          selectedLine.push(this.LineModel[i].PL_Id);
        }
      } else {
        selectedLine.push(this.addNewVariableItemlForm.value?.lines);
      }
      key = {
        PL_ID: selectedLine.toString(),
        userId: JSON.parse(localStorage.user).UserId,
      };
      this.unitsModel = [];
      this.filteredUnitsModel = [];
      this.assemblyModel = [];
      this.filteredAssemblyModel = [];
      this.componentsModel = [];
      this.filteredcomponentsModel = [];
      this.machineService.getUnitList(key).subscribe(
        (data: any) => {
          this.unitsModel = data.lstUnit;
          this.filteredUnitsModel = this.unitsModel.slice();
          if (status == 'AddMode') {
            this.addNewVariableItemlForm
              .get('machine')
              ?.setValue(this.unitsModel[0].PU_Id);
            this.getAssembly(status);
          }
          if (this.defect.length !== 0 && status == 'EditMode') {
            this.addNewVariableItemlForm
              .get('machine')
              ?.setValue(this.defect.puId);
            this.getAssembly(status);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
    this.getDefectPriorityDDL(status);
  }

  getAssembly(status: any): void {
    let key: { PU_ID: any; userId: any };
    if (status == 'NormalMode') {
      var selectedUnit: any[] = [];
      if (this.searchDefectForm.value?.machine == 0) {
        for (var i = 0; i < this.units?.length; i++) {
          selectedUnit.push(this.units[i].PU_Id);
        }
      } else {
        selectedUnit.push(this.searchDefectForm.value?.machine);
      }
      key = {
        PU_ID: selectedUnit.toString(),
        userId: JSON.parse(localStorage.user).UserId,
      };
      this.assembly = [];
      this.filteredAssembly = [];
      this.components = [];
      this.filteredcomponents = [];
      this.machineService.getAssemblyList(key).subscribe(
        (data: any) => {
          this.assembly = data.lstasmb;
          this.filteredAssembly = this.assembly.slice();
          this.searchDefectForm
            .get('assembly')
            ?.setValue('0');
          this.getComponents(status);
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      var selectedUnit: any[] = [];
      if (this.addNewVariableItemlForm.value?.machine == 0) {
        for (var i = 0; i < this.unitsModel?.length; i++) {
          selectedUnit.push(this.unitsModel[i].PU_Id);
        }
      } else {
        selectedUnit.push(this.addNewVariableItemlForm.value?.machine);
      }
      key = {
        PU_ID: selectedUnit.toString(),
        userId: JSON.parse(localStorage.user).UserId,
      };
      this.assemblyModel = [];
      this.filteredAssemblyModel = [];
      this.componentsModel = [];
      this.filteredcomponentsModel = [];
      this.machineService.getAssemblyList(key).subscribe(
        (data: any) => {
          this.assemblyModel = data.lstasmb;
          this.filteredAssemblyModel = this.assemblyModel.slice();
          if (status == 'AddMode') {
            this.addNewVariableItemlForm
              .get('assemblyy')
              ?.setValue(this.assemblyModel[0].Asmb_Id);
            this.getComponents(status);
          }
          if (this.defect.length !== 0 && status == 'EditMode') {
            this.addNewVariableItemlForm
              .get('assemblyy')
              ?.setValue(this.defect.Assbly_Id);
            this.getComponents(status);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  getComponents(status: any): void {
    let key: { A_ID: any };
    if (status == 'NormalMode') {
      var selectedAssemby: any[] = [];
      if (this.searchDefectForm.value?.assembly == 0) {
        for (var i = 0; i < this.assembly?.length; i++) {
          selectedAssemby.push(this.assembly[i].Asmb_Id);
        }
      } else {
        selectedAssemby.push(this.searchDefectForm.value?.assembly);
      }
      key = {
        A_ID: selectedAssemby.toString(),
      };
      this.components = [];
      this.filteredcomponents = [];
      this.machineService.getComponentListByAssembly(key).subscribe(
        (data: any) => {
          this.components = data.lstComponent;
          this.filteredcomponents = this.components.slice();
          this.searchDefectForm
            .get('component')
            ?.setValue('0');
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
      this.getDefectMgmtTableData();
    } else {
      var selectedAssemby: any[] = [];
      if (this.addNewVariableItemlForm.value?.assemblyy == 0) {
        for (var i = 0; i < this.assemblyModel?.length; i++) {
          selectedAssemby.push(this.assemblyModel[i].Asmb_Id);
        }
      } else {
        selectedAssemby.push(this.addNewVariableItemlForm.value?.assemblyy);
      }
      key = {
        A_ID: selectedAssemby.toString(),
      };
      this.componentsModel = [];
      this.filteredcomponentsModel = [];
      this.machineService.getComponentListByAssembly(key).subscribe(
        (data: any) => {
          this.componentsModel = data.lstComponent;
          this.filteredcomponentsModel = this.componentsModel.slice();
          if (status == 'AddMode') {
            this.addNewVariableItemlForm
              .get('component')
              ?.setValue(this.componentsModel[0].ComponentId);
          }
          if (this.defect.length !== 0 && status == 'EditMode') {
            this.addNewVariableItemlForm
              .get('component')
              ?.setValue(this.defect.componentId);
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  onPlantChange(): void {
    this.searchValue = null;
    this.getDefectMgmtTableData();
    this.getLine('NormalMode');
    this.getDefectTypeDLL();
    this.getSubDefectTypeDLL();
    this.getdepartment();
    this.getAlldepartment();
    this.getDepartmentsMachine();
  }

  getDefectTypeDLL(): void {
    const key = {
      egId: this.DefectDataForm.value.group,
      plantId: this.DefectDataForm.value.plant,
    };
    this.defectService.getDefectTypeDLL(key).subscribe((res: any) => {
      this.defectType = res.dropDownList;
      this.selectedDefectType = this.defectType[0].name;
    });
  }

  getSubDefectTypeDLL(): void {
    const key = {
      egId: this.DefectDataForm.value.group,
      plantId: this.DefectDataForm.value.plant,
    };
    this.defectService.getSubDefectTypeDLL(key).subscribe((res: any) => {
      this.subDefectType = res.dropDownList;
      if (this.defectPriority)
      {
        this.selectedDefectPriority = this.defectPriority[0]?.name;
      }
    });
  }

  searchDefect(): void {
    this.getDefectMgmtTableData();
  }

  reSetDefect() {
    this.searchDefectForm.reset();
    this.getLine('NormalMode');
  }

  openActionModal(element: any): void {
    this.defectId = element.id;
    const userRole = JSON.parse(localStorage.roleName).map((v: any) =>
      v.toLowerCase()
    );
    const userId = JSON.parse(localStorage.user).UserId;
    if (element.statusName === 'Closed') {
      this.toaster.warning('warning', ' Defect has been Closed ');
      return;
    }
    if (
      userRole.includes('section manager') ||
      (userRole.includes('operator') &&
        element.classificationName === 'Production')
    ) {
      this.isAllowed = true;
      this.dialog.open(this.displayActionModal, {
        width: '90%',
        height: '80%',
      });
    } else {
      this.isAllowed = false;
      this.toaster.warning(
        'warning',
        'You are not authorize to see defect action'
      );
    }

    this.getDefectActionById();
    // this.getdepartment();
    // this.getAlldepartment();
  }
  addRow(d?: any, noUpdate?: boolean): void {
    const row = this.fb.group({
      actionName: [d && d.actionName ? d.actionName : null, []],
      targetDate: [d && d.targetDate ? d.targetDate : null, []],
      noOfMisses: [d && d.misses ? d.misses : null, []],
      responsibleId: [d && d.responsible ? d.responsible : null, []],
      newTargetDate: [d && d.newTargetDate ? d.newTargetDate : null, []],
      completed: [d && d.completed ? d.completed : null, []],
      defectTransactionActionId: [
        d && d.defectTransactionActionId ? d.defectTransactionActionId : null,
        [],
      ],
      remarks: [d && d.remarks ? d.remarks : null, []],
      department: [d && d.department ? d.department : null, []],
      verified: [d && d.verified ? d.verified : null, []],
      isRequiredPictureAfter: [
        d && d.isRequiredPictureAfter ? d.isRequiredPictureAfter : null,
        [],
      ],
    });
    this.rows.push(row);
    if (!noUpdate) {
      this.updateView();
    }
  }
  updateView(): void {
    this.actionDataSource.next(this.rows.controls);
  }
  addDefectActions(): void {
    this.gembaActionform.value.actionsArray.forEach((element: any) => {
      element.targetDate = moment(element.targetDate).format('YYYY-MM-DD');
    });
    const key = {
      actions: this.gembaActionform.value.actionsArray,
      defectTransactionId: this.defectId,
      userId: JSON.parse(localStorage.user).UserId,
    };
    if (this.gembaActionform.value.actionsArray.length > 0) {
      this.defectActionService.addDefectActions(key).subscribe(
        (data: any) => {
          this.toaster.success('Success', data.message);
          this.getDefectMgmtTableData();
          this.UserIdsforNotification =
            this.gembaActionform.value.actionsArray.map((c: any) =>
              c.defectTransactionActionId == null ? c.responsibleId : ''
            );
          const filterUserIds = [];
          for (const item of this.UserIdsforNotification) {
            if (item !== '') {
              filterUserIds.push(item);
            }
          }
          const key2 = {
            userId: filterUserIds.toString(),
            desc: 'Defect Action is created',
            title: 'Defect Management',
            createdBy: JSON.parse(localStorage.user).UserId,
          };
          this.notificationService
            .addnotificationmappeduserwise(key2)
            .subscribe((data: any): any => {
            });
        },
        (error) => {
          this.toaster.error('Error', error.error.Message);
        }
      );
    }
  }
  getUsersbyDepartment(event: any, i: any): void {
    const key = {
      egId: this.DefectDataForm.value.group,
      plantId: this.DefectDataForm.value.plant,
      departmentId: event.value,
    };
    this.peopleService.getEmployeePositionMappedMasterData(key).subscribe(
      (response: any) => {
        this.raciUsers[i] = response.employeePositionMappedList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onFilterChanged(event: any) {
    this.gridOptions.api.setQuickFilter(event.value);
  }
  // resetFilterList(i: any): void {
  //   this.raciUsers = [];
  //   this.raciUsers[i] = [];
  //   const key = {
  //     egId: this.DefectDataForm.value.group,
  //     plantId: this.DefectDataForm.value.plant,
  //     departmentId: this.gembaActionform.value.actionsArray[i].department,
  //   };
  //   if (key.departmentId) {
  //     this.peopleService.getEmployeePositionMappedMasterData(key).subscribe(
  //       (response: any) => {
  //         this.raciUsers[i] = response.employeePositionMappedList;
  //       },
  //       (error: any) => {
  //         this.toaster.error('Error', error.message);
  //       }
  //     );
  //   }
  // }

  getDefectActionById(): void {
    const key = {
      DefectTransactionId: this.defectId,
    };
    this.defectActionService.getDefectActions(key).subscribe(
      (data: any): any => {
        this.rows.clear();
        const list: any[] = [];
        var i = 0;
        data.forEach((element: any) => {
          const data = {
            actionName: element.actionName,
            targetDate: element.targetDate,
            noOfMisses: element.noOfMisses,
            department: element.department,
            responsibleId: element.responsibleId,
            newTargetDate: element.newTargetDate,
            remarks: element.remarks,
            completed: element.completed,
            defectTransactionActionId: element.defectTransactionAction,
            verified: element.verified,
            isRequiredPictureAfter: element.isRequiredPictureAfter,
          };
          this.getUsersbyDepartment(element.department, i);
          i++;
          list.push(data);
          this.rows.push(this.actionForm());
        });
        this.gembaActionform.get('actionsArray')?.setValue(list);
        this.responsibleArray.length = list.length;
        this.actionDataSource.next(this.rows.controls);
      },
      (error) => {
        this.toaster.error('Error', error.error.Message);
      }
    );
  }

  getDefectMgmtTableData(): void {
    const key = {
      egId: this.DefectDataForm.value.group,
      plantId: this.DefectDataForm.value.plant,
      priorityId: 0,
      statusId: 0,
      classificationId: 0,
      machineId: 0,
      componentId: 0,
      assemblyId: 0,
    };
    this.spinner.show();
    this.defectService.GetDefectData(key).subscribe((res: any) => {
      this.gridApi.setRowData(res?.GetDefectList);
      this.defectlist = res.GetDefectList
      if( this.defectlist)
      {
        var total   = res.GetDefectList?.length;
        this.ClosedDefect   = this.defectlist.filter(x => (x.statusName === "Closed" || x.statusName === "Completed")).length;
        this.OpenedDefect   = this.defectlist.filter(x => x.statusName == "Open" || x.statusName === "InProgress").length;
        this.Fixing  = this.ClosedDefect/total *100;
      }
      this.spinner.hide();
      this.isClosedButtonEnabled = [];
      let i = 0;
      for (const item of res?.GetDefectList) {
        if (item.totalDefectActions > 0) {
          this.isClosedButtonEnabled[i] = false;
         
        } else {
          this.isClosedButtonEnabled[i] = true;
        }
        i++;
      }
      this.getPaginator();
    });
  }

  getDefectPriorityDDL(status: any): void {
    let key: { egId: any; plantId: any; unitId: any };
    if (status === 'NormalMode') {
      key = {
        egId: this.DefectDataForm.value.group,
        plantId: this.DefectDataForm.value.plant,
        unitId: this.searchDefectForm.value.machine,
      };
    } else {
      key = {
        egId: this.DefectDataForm.value.group,
        plantId: this.DefectDataForm.value.plant,
        unitId: this.addNewVariableItemlForm.value.machine,
      };
    }
    if (key !== null)
    {
      this.defectService.GetDefectPriorityDDL(key).subscribe((res: any) => {
        this.defectPriority = res.dropDownList;
        this.selectedDefectPriority = this.defectPriority[0].id;
      });
    }
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  getDefectStatusDDL(): void {
    const key = {
      Id: this.DefectDataForm.value.group,
    };
    this.defectService.GetDefectStatusDDL(key).subscribe((res: any) => {
      this.defectStatus = res.dropDownList;
      this.selectedDefectStatus = this.defectStatus[0]?.name;
    });
  }

  deleteDefect(element: any): void {
    this.defectId = element.Id;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this defect!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.defectService.deleteDefect(element.id).subscribe(
          (success) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your selected defect has been deleted.',
              icon: 'success',
              timer: 800,
              showConfirmButton: false,
            });
            this.spinner.hide();

            this.getDefectMgmtTableData();
          },
          (error) => {
            this.spinner.hide();

            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your defect is safe :)', 'error');
      }
    });
  }

  showPopupMsg(msg: any): any {
    if (msg !== null || msg !== '') {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
      } else {
        this.toaster.error('Error', msg.substring(3, msg.length));
      }
    }
  }

  // open create defect variable group model
  opencreateDefectGroupModel(): void {
    this.defect = [];
    this.DefectformMode = true;
    this.addNewVariableItemlForm.reset();
    this.imageNames = [];
    this.FileData = [];
    this.image = '';
    this.checked = true;
    const dialogRef = this.dialog.open(this.displayCreateDefectModal, {
      width: '90',
      height: '80',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.checked = true;
    })
    this.getLine('AddMode');
    this.addNewVariableItemlForm.get('assemblyy').setValidators(Validators.required);
    this.addNewVariableItemlForm.get('lines').setValidators(Validators.required);
    this.addNewVariableItemlForm.get('machine').setValidators(Validators.required);
    this.addNewVariableItemlForm.get('departmentMachine').setValidators(Validators.required);
    this.addNewVariableItemlForm.get('department').clearValidators();
    this.addNewVariableItemlForm.get('remarks').clearValidators();
    this.addNewVariableItemlForm
      .get('defectStatus')
      ?.setValue(this.defectStatus[0].id);
    this.addNewVariableItemlForm
      .get('department')
      ?.setValue(this.departmentList[0].TypeId);
    this.addNewVariableItemlForm.get('date')?.setValue(new Date());
  }
  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;
    disabledDateTime: DisabledTimeFn = () => ({
    nzDisabledHours: () => this.range(0, 24).splice(4, 20),
    nzDisabledMinutes: () => this.range(30, 60),
    nzDisabledSeconds: () => [55, 56],
  });

  // close machine parameter variable  model
  closeaddNewVariableModel(): void {
    this.iscreateDeffectGroupModelVisible = false;
    this.isGetDefectFlag = false;
    this.isCreateDefectFlag = false;
    this.addNewVariableItemlForm.reset();
  }

  // open edit defect variable group model
  openEditDefectGroupModel(element: any): void {
    this.defect = [];
    this.defect = element;
    this.addNewVariableItemlForm.reset();
    this.editGroupItemParent = element;
    this.DefectformMode = false;
    const dialogRef = this.dialog.open(this.displayCreateDefectModal, {
      width: '90',
      height: '80',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.checked = true;
    })
    this.getLine('EditMode');
    this.checked = element?.isMachine;
    if (this.checked)
    {
      this.addNewVariableItemlForm.get('assemblyy').setValidators(Validators.required);
      this.addNewVariableItemlForm.get('lines').setValidators(Validators.required);
      this.addNewVariableItemlForm.get('machine').setValidators(Validators.required);
      this.addNewVariableItemlForm.get('departmentMachine').setValidators(Validators.required);
      this.addNewVariableItemlForm.get('department').clearValidators();
      this.addNewVariableItemlForm.get('remarks').clearValidators();
      this.addNewVariableItemlForm.get('assemblyy').updateValueAndValidity();
      this.addNewVariableItemlForm.get('lines').updateValueAndValidity();
      this.addNewVariableItemlForm.get('machine').updateValueAndValidity();
      this.addNewVariableItemlForm.get('departmentMachine').updateValueAndValidity();
      this.addNewVariableItemlForm.get('department').updateValueAndValidity();
      this.addNewVariableItemlForm.get('remarks').updateValueAndValidity();

    }
    else
    {
      this.addNewVariableItemlForm.get('department').setValidators(Validators.required);
      this.addNewVariableItemlForm.get('remarks').setValidators(Validators.required);
      this.addNewVariableItemlForm.get('assemblyy').clearValidators();
      this.addNewVariableItemlForm.get('lines').clearValidators();
      this.addNewVariableItemlForm.get('machine').clearValidators();
      this.addNewVariableItemlForm.get('component').clearValidators();


      this.addNewVariableItemlForm.get('machine').updateValueAndValidity();
      this.addNewVariableItemlForm.get('assemblyy').updateValueAndValidity();
      this.addNewVariableItemlForm.get('lines').updateValueAndValidity();
      this.addNewVariableItemlForm.get('component').updateValueAndValidity();
      this.addNewVariableItemlForm.get('department').clearValidators();
      this.addNewVariableItemlForm.get('remarks').updateValueAndValidity();
    
    }
    this.editGroupModelTitle = this.editGroupItemParent.defect;
    this.editTableRowID = element.id;
    this.addNewVariableItemlForm.controls.defect.setValue(element.defect);
    this.addNewVariableItemlForm.controls.defectType.setValue(
      element.defectTypeId
    );
    this.addNewVariableItemlForm.controls.subDefectType.setValue(
      element.subDefectTypeId
    );
    this.addNewVariableItemlForm.controls.defectPriority.setValue(
      element.defectPriorityId
    );
    this.addNewVariableItemlForm.controls.defectStatus.setValue(
      element.defectStatusId
    );
    this.addNewVariableItemlForm.controls.department.setValue(
      element.departmentId
    );
    this.addNewVariableItemlForm.controls.departmentMachine.setValue(
      element.departmentId
    );
    this.addNewVariableItemlForm
    .get('remarks')
    ?.setValue(element.remarks);
    this.addNewVariableItemlForm
      .get('date')
      ?.setValue(new Date(element.defectOn));
  }
  // close machine parameter variable  model
  closeEditDefectGroupModel(): void {
    this.iseditDeffectGroupModelVisible = false;
    this.editTableVariableItemlForm.reset();
  }

  // edit new plant model form submit
  editTableVariableItemlFormSubmit(): void {
    this.editTableVariableItemlFormSubmitted = true;
    this.editdefectTableRow(this.editTableVariableItemlForm.value);
    this.editTableVariableItemlFormSubmitted = false;
  }

  // update edited row data
  editdefectTableRow(data: DefectManagementElement): void {
    this.editGroupItemParent.defect = data.defect;
    this.editGroupItemParent.assignTo = data.assignTo;
    this.editGroupItemParent.closeDate = data.closeDate;
    this.editGroupItemParent.comments = data.comments;
    this.editGroupItemParent.defectOn = data.defectOn;
    this.editGroupItemParent.targetDate = data.targetDate;
    this.editGroupItemParent.defectPriority = data.defectPriority;
    this.editGroupItemParent.defectType = data.defectType;
    this.editGroupItemParent.subDefectType = data.subDefectType;
    this.editGroupItemParent.defectStatus = data.defectStatus;
    this.editGroupItemParent.targetDate = data.targetDate;
    this.editGroupItemParent.closeDate = data.closeDate;
    this.editGroupItemParent.assignPlan = data.assignPlan;
    const formData: any = new FormData();
    for(let i = 0; i < this.imageNames.length; i++ )
    {
      formData.append('Imagefile', this.imageNames[i]);
    }
    formData.append(
      'obj',
      JSON.stringify({
        TransactionTypeId: 2,
        id: this.editTableRowID,
        componentId: this.addNewVariableItemlForm.value.component,
        egId: this.DefectDataForm.value.group,
        defect: this.addNewVariableItemlForm.get('defect')?.value,
        defectTypeId: this.addNewVariableItemlForm.get('defectType')?.value,
        subDefectTypeId:
          this.addNewVariableItemlForm.get('subDefectType')?.value,
        defectPriorityId:
          this.addNewVariableItemlForm.get('defectPriority')?.value,
        departmentId: this.checked === false ? this.addNewVariableItemlForm.get('department')?.value :
        this.addNewVariableItemlForm.get('departmentMachine')?.value ,
        targetDate: this.addNewVariableItemlForm.get('targetDate')?.value,
        defectOn: this.addNewVariableItemlForm.get('date')?.value,
        comments: this.addNewVariableItemlForm.get('comments')?.value,
        userId: 0,
        puId: this.addNewVariableItemlForm.value.machine,
        defectStatusId: this.addNewVariableItemlForm.value.defectStatus,
        closeDate: data.closeDate,
        assignto: data.assignTo,
        statusName: data.defectStatus,
        remarks: this.checked ? '' : this.addNewVariableItemlForm.value.remarks,
        isMachine : this.checked
      })
    );
    if (
      this.editTableVariableItemlForm.value.id != 0 ||
      this.editTableVariableItemlForm.value.id != ''
    ) {
      this.defectService.UpdateDefectData(formData).subscribe(
        (res: any) => {
          if (res.HasError === false) {
            this.showPopupMsg(res.ErrorMessage);
            this.iscreateDeffectGroupModelVisible = false;
            this.getDefectMgmtTableData();
            this.closeEditDefectGroupModel();
            this.editTableVariableItemlForm.reset();
          } else {
            this.showPopupMsg(res.ErrorMessage);
          }
        },
        (error) => {
          this.showPopupMsg(error.ErrorMessage);
        }
      );
    }
    this.getDefectMgmtTableData();
    this.defect = [];
  }

  getPaginator(): void {
    setTimeout(() => {
      this.defectManagementVariableGroupData.paginator = this.paginator;
      this.defectManagementVariableGroupData.sort = this.sort;
    }, 1000);
  }

  // add new variable group form submit
  addNewVariableItemlFormSubmit(): void {
    this.spinner.show();
    var formData: any = new FormData();
    for(let i = 0; i< this.imageNames?.length ; i++)
    {
      formData.append('Imagefile', this.imageNames[i]);
    }
    this.addNewVariableItemlFormSubmitted = true;
    this.isUnitName = true;
    formData.append(
      'obj',
      JSON.stringify({
        TransactionTypeId: 1,
        componentId: this.checked ? this.addNewVariableItemlForm.value.component : null,
        egId: this.DefectDataForm.value.group,
        plantId: this.DefectDataForm.value.plant,
        defect: this.addNewVariableItemlForm.get('defect')?.value,
        defectTypeId: this.addNewVariableItemlForm.get('defectType')?.value,
        subDefectTypeId:
          this.addNewVariableItemlForm.get('subDefectType')?.value,
        defectPriorityId:
          this.addNewVariableItemlForm.get('defectPriority')?.value,
        departmentId: this.checked === false ? this.addNewVariableItemlForm.get('department')?.value :
         this.addNewVariableItemlForm.get('departmentMachine')?.value ,
        targetDate: this.addNewVariableItemlForm.get('targetDate')?.value,
        defectOn: this.addNewVariableItemlForm.get('date')?.value,
        comments: this.addNewVariableItemlForm.get('comments')?.value,
        userId: 0,
        puId: this.checked ? this.addNewVariableItemlForm.value.machine : null,
        defectStatusId: this.addNewVariableItemlForm.value.defectStatus,
        remarks: this.checked ? '' : this.addNewVariableItemlForm.value.remarks,
        isMachine : this.checked
      })
    );
    if (
      this.addNewVariableItemlForm.value.id === 0 ||
      this.addNewVariableItemlForm.value.id === '' ||
      this.addNewVariableItemlForm.value.id === null
    ) {
      this.defectService.SaveDefectData(formData).subscribe(
        (res: any) => {
          if (res.HasError === false) {
            this.toaster.success('Success', 'Added Successfully');
            this.sendNotificationRole('Defect has been added successfully');
            this.getDefectMgmtTableData();
            this.iscreateDeffectGroupModelVisible = false;
          } else {
            this.showPopupMsg(res.ErrorMessage);
          }
        },
        (error) => {
          this.spinner.hide();
          this.showPopupMsg(error.ErrorMessage);
        }
      );
    }
    this.DefectformMode = true;
    this.addNewVariableItemlFormSubmitted = false;
    this.searchDefectForm.get('line').setValue('0');
    this.searchDefectForm.get('machine').setValue('0');
    this.searchDefectForm.get('assembly').setValue('0');
    this.searchDefectForm.get('component').setValue('0');
  }

  onFileSelect(event: any): void {
    const files = event.target.files;
    if (files) {
      for (const file of files) {
        if (file.type.match('image.*')) {
          this.fileType = 'image';
          this.imageNames.push(file);
        }
        else {
          this.toaster.warning('Warning', 'File Format Not Valid ');
          return;
        }

        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.FileData.push({ FileType: this.fileType, ImageSrc: e.target.result });
        };
        reader.readAsDataURL(file);
      }

    }
  }
  imageZoomModal(imagePath: any): void {
    this.currentImagePath = this.ImageUrl + imagePath;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }
  cellClicked(params: any): void {
    this.ButtonType = '';
    const col = params.colDef.field;
    this.ButtonType = params.event.target.classList[2]; // .contains('Update');//?  "updatedata" :  "deletedata";
 
    if (col === 'fileUpload') {
      this.image = params.data.fileUpload;
      this.imageZoomModal(this.image);
    }
    if(col === 'PictureAfterFilePath')
    {
      this.image = params.data.PictureAfterFilePath;
      this.imageZoomModal(this.image);
    }
    if (col === 'Actions') {
      this.ButtonType = params.event.target.textContent;
      if (this.ButtonType === 'edit') {
        this.addNewVariableItemlForm.get('image').clearValidators();
        if (params?.data?.statusName !== 'Closed') {
          this.openEditDefectGroupModel(params.data);
        } else {
          this.toaster.warning('Cannot Add Or Update Completed Data.');
        }
      }
      if (
        this.ButtonType === 'delete' &&
        params.data?.statusName?.toLowerCase() !== 'closed'
      ) {
        this.deleteDefect(params.data);
      }
     
      if (
        this.ButtonType === 'close' &&
        params?.data?.statusName?.toLowerCase() !== 'closed'
      ) {
        this.defectId = params.data.id;
        this.CloseDefectForm.reset();
        this.uploadImage = true;
        const userRole = JSON.parse(localStorage.roleName).map((v: any) =>
        v.toLowerCase()
        );
        if(params.data.userId === JSON.parse(localStorage.user).UserId 
        ||  userRole.includes('section manager')
        )
        {
          this.disableImage = false;
          this.checkBoxDisable = false;
          this.CloseDefectForm.get('remarks').clearValidators();
          this.CloseDefectForm.get('remarks').setValidators(null)
          this.CloseDefectForm.get('remarks').updateValueAndValidity();
          this.closeFile = null;
          this.CloseDefectForm.get('image').setValidators(Validators.required)
          this.dialog.open(this.CloseDefect, {
            width: '40%',
            height: 'auto',
          });
        }
        else
        {
          this.toaster.warning("Warning" , "You are not authorize")
          
        }

      }
      if (
        this.ButtonType === 'remove_red_eye 2 '
      ) {
        
        this.defectId = params.data.id;
        this.CloseDefectForm.reset();
        
        this.dialog.open(this.CloseDefect, {
          width: '30%',
          height: 'auto',
        });
        this.uploadImage = false;
        this.CloseImage = this.ImageUrl + params.data.CloseImage;
        this.CloseDefectForm.get('remarks').setValue(params.data.CloseRemarks)
      }
      if (
        this.ButtonType === ' insert_drive_file'
      ) {
        this.openEditFilesModal(params.data);
      }
      if (
        this.ButtonType === 'remove_red_eye'
      ) {
        this.openViewActions(params.data);
      }
      if(
        this.ButtonType === 'add'
      )
      {
        this.openActionModal(params.data);
      }
    }
  }

  openEditFilesModal(defect: any): void {
    this.getDefectfiles(defect.id);
    this.defectId = defect.id ;
    this.dialog.open(this.previewModal, { height: 'auto', width: '90%' });
  }

  getDefectfiles(Id: number): void {
    const key = {
      defectId: Id
    };
    this.previewerData = new MatTableDataSource<any>([]);
    this.defectService.getDefectfiles(key).subscribe(
      (listData: any) => {
        this.previewerData = new MatTableDataSource<any>(listData);
        setTimeout(() => {
          this.previewerData.paginator = this.paginator;
        }, 1000);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  removeFile(ele: any): void {
    const key = {
      FileId: ele.Id,
    };
    this.defectService.deleteDefectFiles(key).subscribe(
      (response: any) => {
        this.toaster.success('Defect File Delete Successfuly');
        this.getDefectfiles(this.defectId);
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  saveDefectFiles(e: any): void {
    this.files = [];
    for (const iterator of e.target.files) {
      this.files.push(iterator);
    }
    const formdata = new FormData();
    for (const s of this.files) {
      formdata.append('file', s);
    }
    const defect = {
      DefectId: this.defectId,
    };
    formdata.append('defect', JSON.stringify(defect));
    this.spinner.show();
    this.defectService.addDefectfiles(formdata).subscribe(
        () => {
          this.spinner.hide();
          this.toaster.success('Defect Files Added Successfuly');
          this.files = [];
          this.filesForm.controls.file.setValue(null, { emitEvent: false });
          this.getDefectfiles(this.defectId);
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
        }
      );
  }

  openViewActions(defect: any): void
  {
    this.getDefectActions(defect.id);
  }

  getDefectActions(id: number): void
  {
    const key = {
      DefectId : id
    };
    this.defectActionService.getDefectActions2(key).subscribe((res) => {
           this.rowDataViewActions = res;
           if(this.rowDataViewActions?.length > 0)
           {
            this.dialog.open(this.ViewActionDetailModel , { height: 'auto', width: '90%' });
           }
           else
           {
            this.toaster.warning("Actions Not Available")
           }
    });
  }

  getDepartmentsMachine(): void
  {
    const key = {
      plantId : this.DefectDataForm.value.plant
    };
    this.defectActionService.getDepartmentsMachine(key).subscribe((res) => {
     this.departmentListMachine = res;
     this.addNewVariableItemlForm
            .get('departmentMachine')
            ?.setValue(this.departmentListMachine[0].Dept_Id);
    });
  }

  checkChange(event: any): void
  {
    if (event.checked)
    {
     this.checked = false;
     this.addNewVariableItemlForm.get('department').setValidators(Validators.required);
     this.addNewVariableItemlForm.get('assemblyy').clearValidators();
     this.addNewVariableItemlForm.get('lines').clearValidators();
     this.addNewVariableItemlForm.get('machine').clearValidators();
     this.addNewVariableItemlForm.get('component').clearValidators();
     this.addNewVariableItemlForm.get('departmentMachine').clearValidators();

     this.addNewVariableItemlForm.get('department').updateValueAndValidity();
     this.addNewVariableItemlForm.get('assemblyy').updateValueAndValidity();
     this.addNewVariableItemlForm.get('lines').updateValueAndValidity();
     this.addNewVariableItemlForm.get('machine').updateValueAndValidity();
     this.addNewVariableItemlForm.get('component').updateValueAndValidity();
     this.addNewVariableItemlForm.get('departmentMachine').updateValueAndValidity();

    }
    else
    {
     this.checked = true;
     this.addNewVariableItemlForm.get('assemblyy').setValidators(Validators.required);
     this.addNewVariableItemlForm.get('lines').setValidators(Validators.required);
     this.addNewVariableItemlForm.get('machine').setValidators(Validators.required);
     this.addNewVariableItemlForm.get('departmentMachine').setValidators(Validators.required);
     this.addNewVariableItemlForm.get('department').clearValidators();
     this.addNewVariableItemlForm.get('remarks').clearValidators();

    this.addNewVariableItemlForm.get('assemblyy').updateValueAndValidity();
    this.addNewVariableItemlForm.get('lines').updateValueAndValidity();
    this.addNewVariableItemlForm.get('machine').updateValueAndValidity();
    this.addNewVariableItemlForm.get('departmentMachine').updateValueAndValidity();
    this.addNewVariableItemlForm.get('department').updateValueAndValidity();
    this.addNewVariableItemlForm.get('remarks').updateValueAndValidity();
    }
    this.addNewVariableItemlForm
            .get('Remarks')
            ?.setValue('');
    this.addNewVariableItemlForm
            .get('defect')
            ?.setValue('');
    this.addNewVariableItemlForm
            .get('image')
            ?.setValue('');
    this.imageNames = [];
    this.selectedDefectType = this.defectType[0].name;
    this.selectedDefectPriority = this.defectPriority[0]?.name;

  }
  
  openFile(elem: any): void
  {
    const array = elem.FileName.split('.');
    this.doc = environment.O3CoreApiImageUrl + elem.FilePath;
    const fileType =  array[array?.length - 1];
    if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif') {
      this.images = [];
      this.fileType = 'img';
      this.images.push(this.doc);
    } else {
      this.fileType = 'doc';
    }
    this.displayMaximizable = true;
  }

  deleteFile(i: number): void 
   {
     this.imageNames.splice(i , 1);
     this.FileData.splice(i , 1);
   }
   onFileSelectClose(event: any): void {
    const file = event.target.files[0];
     if (file) {
       this.closeFile = file
       this.CloseFormMode = true
       this.checkBoxDisable = true
     }
     else {
      this.checkBoxDisable = false
     }
   }
   checkChangeClose(event: any): void {
    this.checkedClose = event.checked ;
    if (event.checked === true)
    {
      this.CloseDefectForm.get('image').clearValidators();
      this.CloseDefectForm.get('image').setValidators(null)
      this.CloseDefectForm.get('image').updateValueAndValidity();
      this.CloseDefectForm.get('remarks').setValidators(Validators.required)
      this.CloseDefectForm.get('remarks').updateValueAndValidity();
      this.disableImage = true;
    }
    else
    {
      this.CloseDefectForm.get('image').setValidators(Validators.required)
      this.CloseDefectForm.get('image').updateValueAndValidity();
      this.CloseDefectForm.get('remarks').clearValidators();
      this.CloseDefectForm.get('remarks').setValidators(null)
      this.CloseDefectForm.get('remarks').updateValueAndValidity();
      this.disableImage = false;
       
    }
   }
   closedefect()
  {
    const formData: any = new FormData();
    if(this.closeFile)
    {
      formData.append('Imagefile', this.closeFile);
    }
    const key = {
      defectId: this.defectId,
      ImageRequired: this.checkedClose ? 1 : 0 ,
      remarks : this.CloseDefectForm.value.remarks
    };
    formData.append('obj',  JSON.stringify(key));
    this.defectService.setDefectClosed(formData).subscribe(
      (data): any => {
        this.toaster.success('Success' , 'Defect Closed')
        this.getDefectMgmtTableData();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.Message);
      }
    );
  }
   
  imageZoom(): void {
    this.currentImagePath = this.CloseImage ? this.CloseImage : 'assets/images/no-image.png'
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
   }
}


function GenderCellRenderer(): void { }

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};

