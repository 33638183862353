import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { OpmsGlassService } from 'src/app/services/opms-glass.service';
import * as Highcharts from 'highcharts';
import { GlassQaDashboardService } from 'src/app/services/glass-qa-dashboard.service';


@Component({
  selector: 'app-glass-qa-dashboard',
  templateUrl: './glass-qa-dashboard.component.html',
  styleUrls: ['./glass-qa-dashboard.component.scss']
})
export class GlassQaDashboardComponent implements OnInit {
  private subscriptions = new Subscription();
  public breadcrumList: any;
  dbData!: FormGroup;
  shiftList: any[] = [];
  crewList: any[] = [];
  thicknessList: any[] = [];
  highChartsColor = [
    '#DC143C',
    '#00adad',
    '#3b2fb5',
    '#6D81A8',
    '#C471A5',
    '#512E5F',
    '#0034bb',
    '#0b774e',
    '#570000',
    '#0d98da',
    '#00002e',
    '#00a800',
    '#00a8a8',
    '#06573d',
    '#F80000',
    '#0d6e54',
    '#FFDF00',
    '#0692aa',
    '#611745',
    '#ff5252',
    '#ff52ff',
    '#08fa03',
    '#08FAC7',
    '#D208FA',
    '#FAEB08',
  ];
  zebraValueChartOptions: any;
  pullTonByDayChartOptions: any;
  seedCountChartOptions: any;
  bloomGradeChartOptions: any;
  reamComparatorGradeChartOptions: any;
  tsvChartOptions: any;
  defectDensityChartOptions: any;
  thicknessChartOptions: any;
  muStrainChartOptions: any;
  lastMeasuredCrossBowChartOptions: any;
  corrugationChartOptions: any;
  lastmeasuredWarpage: any;
  hourlyDefect: any;
  shiftAll: any[] = [];
  crewAll: any[] = [];
  thicknessAll: any[] = [];
  data: any;

  constructor(
    private spinner: NgxSpinnerService,
    private usermanagementService: UserManagementService,
    private opmsGlassservice: OpmsGlassService,
    private glassQaDashboard: GlassQaDashboardService,
    private toaster: ToastrService,
    public router: Router,
    private datePipe: DatePipe,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    var firstDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    this.dbData = this.fb.group({
      startDate: [this.datePipe.transform(firstDate, 'yyyy-MM-dd')],
      endDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      shift: [''],
      crew: [''],
      thickness: [''],
    });
    this.getBreadcrumList();
    this.LoadBaseDetails();
  }

  LoadBaseDetails() {
    this.spinner.show();
    var data = {
      UserId: JSON.parse(localStorage.user).UserId,
    };
    this.opmsGlassservice.loadBaseDetails(data).subscribe((response: any) => {
      this.shiftList = response.ShiftList;
      this.dbData.get('shift')?.setValue(0);
      this.dbData.get('crew')?.setValue(0);
      this.dbData.get('thickness')?.setValue(0);
      this.crewList = response.CrewList;
      this.thicknessList = response.ThicknessList;
      this.loadDashBoard();
      this.getHourlyDefect();
    });
  }

  loadDashBoard() {
    this.spinner.show();
    this.shiftAll = [];
    this.crewAll = [];
    this.thicknessAll = [];
    if (this.dbData.get('shift')?.value == 0) {
      for (var i = 0; i < this.shiftList.length; i++) {
        this.shiftAll.push(this.shiftList[i].TypeId);
      }
    } else {
      this.shiftAll.push(this.dbData.get('shift')?.value);
    }
    if (this.dbData.get('crew')?.value == 0) {
      for (var i = 0; i < this.crewList.length; i++) {
        this.crewAll.push(this.crewList[i].TypeId);
      }
    } else {
      this.crewAll.push(this.dbData.get('crew')?.value);
    }
    if (this.dbData.get('thickness')?.value == 0) {
      for (var i = 0; i < this.thicknessList.length; i++) {
        this.thicknessAll.push(this.thicknessList[i].TypeId);
      }
    } else {
      this.thicknessAll.push(this.dbData.get('thickness')?.value);
    }
    this.data = {};
    this.data = {
      StartDate: this.dbData.get('startDate')?.value,
      EndDate: this.dbData.get('endDate')?.value,
      Shift: this.shiftAll.toString(),
      Thickness: this.thicknessAll.toString(),
      Crew: this.crewAll.toString(),
    };
    this.getZebraValuesChart();
  }

  getZebraValuesChart(): void {
    this.glassQaDashboard.GetZebraValues(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['LE','LC','RC','RE'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.zebraValueChartOptions = {
            chart: {
              type: 'column',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Zebra Values(Degrees)',
            },
            xAxis: {
              categories: categories,
              // crosshair: true,
            },
            yAxis: {
              min: 0,
            //   plotLines: [{
            //     color: '#FF0000',
            //     width: 2,
            //     value: 30,
            //     zIndex: 5
            // }]
            },
            tooltip: {
              headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true,
            },
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
                pointPadding: 0.2,
                groupPadding: 0.13,
                borderWidth: 0,
              },
            },
            series: series
          };
          Highcharts.chart('ZebraValue', this.zebraValueChartOptions);
          this.getPullTonByDay();
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getPullTonByDay();
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.getPullTonByDay();
        this.spinner.hide();
      }
    )
  }

  getPullTonByDay(): void {
    this.glassQaDashboard.GetPullTonByDay(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['Pull'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.pullTonByDayChartOptions = {
            chart: {
              type: 'bar',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Pull(Ton/day)',
            },
      
            xAxis: {
              min: 0,
              categories: categories,
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            //   plotLines: [{
            //     color: '#ff5252',
            //     width: 2,
            //     value: 30000,
            //     zIndex: 5,
            //     label: {
            //       text: 'Min. '+'%',
            //       style: {
            //         color: '#ff5252',
            //         fontWeight: 'bold',
            //       },
            //       textAlign: 'left',
            //       y: 20
            //     },
            // }]
            },
      
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            // legend: {
            //   layout: 'vertical',
            //   align: 'right',
            //   verticalAlign: 'top',
            //   x: -330,
            //   // y: 80,
            //   floating: true,
            //   borderWidth: 1,
      
            //   shadow: true,
            // },
            series: series
          };
          Highcharts.chart('Pull(Ton/day)', this.pullTonByDayChartOptions);
          this.getSeedCount();
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getSeedCount();
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.getSeedCount();
        this.spinner.hide();
      }
    )
  }

  getSeedCount(): void {
    this.glassQaDashboard.GetSeedCount(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['Seed Count'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.seedCountChartOptions = {
            chart: {
              type: 'bar',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Seed Count(#/kg)',
            },
      
            xAxis: {
              min: 0,
              categories: categories,
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            // legend: {
            //   layout: 'vertical',
            //   align: 'right',
            //   verticalAlign: 'top',
            //   x: -330,
            //   // y: 80,
            //   floating: true,
            //   borderWidth: 1,
      
            //   shadow: true,
            // },
            series: series
          };
          Highcharts.chart('SeedCount', this.seedCountChartOptions);
          this.getBloomGrade();
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getBloomGrade();
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.getBloomGrade();
        this.spinner.hide();
      }
    )
  }

  getBloomGrade(): void {
    this.glassQaDashboard.GetBloomGrade(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['L','C','R'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.bloomGradeChartOptions = {
            chart: {
              type: 'column',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Bloom Grade(#)',
            },
      
            xAxis: {
              min: 0,
              categories: categories,
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
      
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            // legend: {
            //   layout: 'vertical',
            //   align: 'right',
            //   verticalAlign: 'top',
            //   x: -330,
            //   // y: 80,
            //   floating: true,
            //   borderWidth: 1,
      
            //   shadow: true,
            // },
            series: series
          };
          Highcharts.chart('BloomGrade', this.bloomGradeChartOptions);
          this.getReamComparatorGrade();
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getReamComparatorGrade();
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.getReamComparatorGrade();
        this.spinner.hide();
      }
    )
  }

  getReamComparatorGrade(): void {
    this.glassQaDashboard.GetReamComparartorGrade(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['LE','LC','RC','RE'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.reamComparatorGradeChartOptions = {
            chart: {
              type: 'column',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Ream Comparator Grade(#)',
            },
      
            xAxis: {
              min: 0,
              categories: categories,
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
      
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            // legend: {
            //   layout: 'vertical',
            //   align: 'right',
            //   verticalAlign: 'top',
            //   x: -330,
            //   // y: 80,
            //   floating: true,
            //   borderWidth: 1,
      
            //   shadow: true,
            // },
            series: series
          };
          Highcharts.chart('ReamComparatorGrade', this.reamComparatorGradeChartOptions);
          this.getTsv();
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getTsv();
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.getTsv();
        this.spinner.hide();
      }
    )
  }

  getTsv(): void {
    this.glassQaDashboard.GetTSV(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['TSV'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.tsvChartOptions = {
            chart: {
              type: 'bar',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'TSV(mm)',
            },
      
            xAxis: {
              min: 0,
              categories: categories,
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
      
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            // legend: {
            //   layout: 'vertical',
            //   align: 'right',
            //   verticalAlign: 'top',
            //   x: -330,
            //   // y: 80,
            //   floating: true,
            //   borderWidth: 1,
      
            //   shadow: true,
            // },
            series: series
          };
          Highcharts.chart('TSV(mm)', this.tsvChartOptions);
          this.getDefectDensity();
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getDefectDensity();
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.getDefectDensity();
        this.spinner.hide();
      }
    )
  }

  getDefectDensity(): void {
    this.glassQaDashboard.GetDefectDensitySqm(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['SQM'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.defectDensityChartOptions = {
            chart: {
              type: 'bar',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Defect Density(#/sqm)',
            },
      
            xAxis: {
              min: 0,
              categories: categories,
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
      
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            // legend: {
            //   layout: 'vertical',
            //   align: 'right',
            //   verticalAlign: 'top',
            //   x: -330,
            //   // y: 80,
            //   floating: true,
            //   borderWidth: 1,
      
            //   shadow: true,
            // },
            series: series
          };
          Highcharts.chart('DefectDensity(#/sqm)', this.defectDensityChartOptions);
          this.getThickness();
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getThickness();
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.getThickness();
        this.spinner.hide();
      }
    )
  }

  getThickness(): void {
    this.glassQaDashboard.GetThicknessMM(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['LE','LC','C','RC','RE','Thickness Avg'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.thicknessChartOptions = {
            chart: {
              type: 'column',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Thickness(mm)',
            },
      
            xAxis: {
              min: 0,
              categories: categories,
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
      
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
              },
              pointPadding: 0.2,
              groupPadding: 0.1,
              borderWidth: 0,
            },
            // legend: {
            //   layout: 'vertical',
            //   align: 'right',
            //   verticalAlign: 'top',
            //   x: -330,
            //   // y: 80,
            //   floating: true,
            //   borderWidth: 1,
      
            //   shadow: true,
            // },
            series: series
          };
          Highcharts.chart('Thickness(mm)', this.thicknessChartOptions);
          this.getMUStrain();
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getMUStrain();
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.getMUStrain();
        this.spinner.hide();
      }
    )
  }

  getMUStrain(): void {
    this.glassQaDashboard.GetMuStrainPsi(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            categories = ['LE','LC','C','RC','RE','Average'];
            series = [
              {
                name: 'Max',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Avg',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Min',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.muStrainChartOptions = {
            chart: {
              type: 'column',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Mu Strain(psi)',
            },
      
            xAxis: {
              min: 0,
              categories: categories,
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
      
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            // legend: {
            //   layout: 'vertical',
            //   align: 'right',
            //   verticalAlign: 'top',
            //   x: -330,
            //   // y: 80,
            //   floating: true,
            //   borderWidth: 1,
      
            //   shadow: true,
            // },
            series: series
          };
          Highcharts.chart('MuStrain(psi)', this.muStrainChartOptions);
          this.getLastMeasuredCrossBow();
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getLastMeasuredCrossBow();
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.getLastMeasuredCrossBow();
        this.spinner.hide();
      }
    )
  }

  getLastMeasuredCrossBow(): void {
    this.glassQaDashboard.GetLastMeasuredCrossBowMM(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var subtitle = '';
          var series = [];
          if(response.result.length){
            subtitle = 'Date: '+response.result[0].date+' , '+'Time: '+response.result[0].time +' , '+'Thickness: '+response.result[0].Thickness
            series = [
              {
                name: 'Actual',
                data: [response.result[0].CrossBow],
                color: this.highChartsColor[1]
              }
            ]
          }else{
            subtitle = 'Date : '+' , '+'Time : '+' , '+'Thickness : ';
            series = [
              {
                name: 'Actual',
                data: [],
                color: this.highChartsColor[1]
              }
            ]
          }
          this.lastMeasuredCrossBowChartOptions = {
            chart: {
              type: 'bar',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Last Measured Cross Bow(mm)',
            },
            subtitle: {
              text: subtitle
            },
            xAxis: {
              min: 0,
              categories: [''],
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
      
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            // legend: {
            //   layout: 'vertical',
            //   align: 'right',
            //   verticalAlign: 'top',
            //   x: -330,
            //   // y: 80,
            //   floating: true,
            //   borderWidth: 1,
      
            //   shadow: true,
            // },
            series: series
          };
          Highcharts.chart('LastMeasuredCrossBow(mm)', this.lastMeasuredCrossBowChartOptions);
          this.getCorrugation();
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getCorrugation();
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.getCorrugation();
        this.spinner.hide();
      }
    )
  }

  getCorrugation(): void {
    this.glassQaDashboard.GetCorrugation(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var series = [];
          var data = [];
          var categories = [];
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[i]
                }
              )
              data = [];
            }
          }else{
            series = [
              {
                name: 'Left',
                data: [],
                color: this.highChartsColor[0]
              },
              {
                name: 'Center',
                data: [],
                color: this.highChartsColor[1]
              },
              {
                name: 'Right',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.corrugationChartOptions = {
            chart: {
              type: 'bar',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Corrugation(#)',
            },
      
            xAxis: {
              min: 0,
              categories: [''],
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
      
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            // legend: {
            //   layout: 'vertical',
            //   align: 'right',
            //   verticalAlign: 'top',
            //   x: -330,
            //   // y: 80,
            //   floating: true,
            //   borderWidth: 1,
      
            //   shadow: true,
            // },
            series: series
          };
          Highcharts.chart('Corrugation(#)', this.corrugationChartOptions);
          this.getLastMeasuredWarpage();
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.getLastMeasuredWarpage();
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.getLastMeasuredWarpage();
        this.spinner.hide();
      }
    )
  }

  getLastMeasuredWarpage(): void {
    this.glassQaDashboard.GetLastMeasuredWarpage(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var categories = [];
          var series = [];
          var data = [];
          var subtitle = ''
          if(response.result.length){
            categories = Object.keys(response.result[0]);
            categories = categories.filter(function(value){ 
              return value != 'name' && value != 'error' && value != 'Thickness' && value != 'date' && value != 'time';
            });
            for(var i = 0; i < response.result.length; i++){
              for(var j = 0; j < categories.length; j++){
                data.push(response.result[i][categories[j]]);
              }
              series.push(
                {
                  name: response.result[i].name,
                  data: data,
                  color: this.highChartsColor[2]
                }
              )
              data = [];
            }
            subtitle = 'Date: '+response.result[0].date+' , '+'Time: '+response.result[0].time +' , '+'Thickness: '+response.result[0].Thickness
          }else{
            categories = ['LE','C','RC'];
            subtitle = 'Date : '+' , '+'Time : '+' , '+'Thickness : ';
            series = [
              {
                name: 'Actual',
                data: [],
                color: this.highChartsColor[2]
              }
            ]
          }
          this.lastmeasuredWarpage = {
            chart: {
              type: 'column',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Last Measured Warpage(mm)',
            },
            subtitle: {
              text: subtitle
            },
            xAxis: {
              min: 0,
              categories: categories,
              
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              labels: {
                overflow: 'justify',
              },
            },
      
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            // legend: {
            //   layout: 'vertical',
            //   align: 'right',
            //   verticalAlign: 'top',
            //   x: -330,
            //   // y: 80,
            //   floating: true,
            //   borderWidth: 1,
      
            //   shadow: true,
            // },
            series: series
          };
          Highcharts.chart('LastMeasuredWarpage(mm)', this.lastmeasuredWarpage);
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.spinner.hide();
      }
    )
  }

  getHourlyDefect(): void {
    if(this.data.Shift.length == 1){
      this.data.TransactionId = 0;
    }
    else{
      this.data.TransactionId = 1;
    }
    this.glassQaDashboard.GetHourlyDefect(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          if(response.result.length){
            var categories = [];
            var defectClass = [];
            var series = [];
            var data = [];
            response.result.forEach((data: any) => {
              categories.push(data.Hour);
              defectClass.push(data.DefectClass);
            });
            categories = [...new Set(categories)];
            defectClass = [...new Set(defectClass)];
            defectClass.sort((a, b) => a - b);
            for(var i = 0; i<defectClass.length; i++){
              for(var j = 0; j<categories.length; j++){
                let uniqueData = response.result.find((fre: any) => {
                  const a = defectClass[i];
                  const b = fre.DefectClass;
                  const c = categories[j];
                  const d = fre.Hour;
                  return (a == b) && (c == d)
                });
                if(uniqueData){
                  data.push(uniqueData.defect_no);
                }
                else{
                  data.push(0);
                }
              }
              const obj = {
                name: defectClass[i],
                data: data,
                color: this.highChartsColor[i]
              }
              series.push(obj);
              data = [];
            }
          }else{
            categories = ['Hour 1','Hour 2','Hour 3'];
            series = [
              {
                name: 'Defect Class',
                data: [],
                color: this.highChartsColor[0]
              }
            ]
          }
          this.hourlyDefect = {
            chart: {
                type: 'column'
            },
            credits: {
              enabled: false,
            },
            title: {
                text: 'Hourly Defects'
            },
            xAxis: {
                categories: categories
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Values'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: series
          }
          Highcharts.chart('HourlyDefect', this.hourlyDefect);
        }else{
          this.toaster.error(response.result[0].Msg);
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.spinner.hide();
      }
    )
  }

  getBreadcrumList(): any {
    this.spinner.show();
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    const breadCrumSub = this.usermanagementService
      .GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
          this.spinner.hide();
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
          this.spinner.hide();
        }
      );
    this.subscriptions.add(breadCrumSub);
  }

  displayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

}
