import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TechScoreService {

  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getTechnicianList(data:any): Observable<any[]> {
    return this.http.get<any>(this.baseUrl + 'get-technician-list' , {params:data});
  }
  // *** Core API Method *** //
  getTechScoreList(data: any): Observable<any[]> {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMTechScore/GetTechScoreList', { params: data });
  }
  // *** Core API Method *** //
  createTechScore(data: any): Observable<any[]> {
    return this.http.post<any>(this.o3CoreApiUrl + 'PMTechScore/createTechScore', data);
  }

}
