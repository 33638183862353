import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ImageDialogComponent } from 'src/app/analytical-tools/image-dialog/image-dialog.component';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { CommonService } from 'src/app/services/common.service';
import { DtServiceService } from 'src/app/services/dtService.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { environment } from 'src/environments/environment';
import { ManagerExecutionService } from '../../services/manager.service';

@Component({
  selector: 'app-at-header',
  templateUrl: './at-header.component.html',
  styleUrls: ['./at-header.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({
          left: '10px',
          height: '0px',
          minHeight: '0',
          visibility: 'hidden',
        })
      ),
      state(
        'expanded',
        style({ left: '10px', height: '*', visibility: 'visible' })
      ),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class AtHeaderComponent implements OnInit {
  @Input() stageName = '';
  @Input() stageId = 0;
  @Input() levelId = 0;
  @Input() updateStatus = false;
  @Input() headerData: any;
  @Output() save = new EventEmitter();
  @Output() emitSearchData = new EventEmitter();
  @Output() mType = new EventEmitter();
  @Output() reset = new EventEmitter();
  @Output() setParentResetFalse = new EventEmitter();
  @Output() plantChange = new EventEmitter();
  @Input() rca = false;
  @Input() isReset: boolean = false;
  breadcrumList!: any;
  strongNew: any = [];
  filteredData: any = [];
  teamLeaderData: any = [];
  currentIndex: number = 0;
  spinnerText: string = '';
  rcaId: number = 0;
  // Dropdown Values
  groupList: any = [];
  groupTempList: any = [];
  plantList: any = [];
  plantTempList: any = [];
  deptList: any = [];
  deptTempList: any = [];

  teamList = [
    { id: 1, title: 'Team 1' },
    { id: 2, title: 'Team 2' },
    { id: 3, title: 'Team 3' },
    { id: 4, title: 'Team 4' },
    { id: 5, title: 'Team 5' },
  ];
  teamLeaderList: any = [];
  mList = [
    { id: 4, title: '4M' },
    { id: 5, title: '5M' },
    { id: 6, title: '6M' },
  ];
  deptApiStarted: boolean = false;
  dataNotFoundForDept: boolean = false;
  searchDefectForm = new FormGroup({
    group: new FormControl(null, [Validators.required]),
    plant: new FormControl(null, [Validators.required]),
    department: new FormControl(null, [Validators.required]),
    team: new FormControl(null, [Validators.required]),
    teamleader: new FormControl(null, [Validators.required]),
    initProbStat: new FormControl(null),
    mType: new FormControl(null),
    refocusedProb: new FormControl(null),
    uniqueCode: new FormControl(null),
  });
  formDirective: any;
  userList: any;
  teams: any = [];
  tempTeams: any = [];
  permisson!: any;
  IsWrite: boolean = false;
  IsAccess!: any;

  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('selectTeamLeader') selectTeamLeader: any;
  @ViewChild('selectTeamLeaderFilter') selectTeamLeaderFilter: any;
  @ViewChild('selectParticipants') selectParticipants: any;
  @ViewChild('selectParticipantsFilter') selectParticipantsFilter: any;

  imageFile: any;
  imageSrc: any;
  ImageUrl: any;
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  headerId: number = 0;
  uniqueCodeList: any = [];
  uniqueCodeTempList: any = [];

  isGet: boolean = true;

  teamArr: any = [];
  historicalHeaderValues: any;
  teamsParticipant: any = [];
  tempTeamsteamsParticipant: any = [];
  previousTeamLeader: any;
  AllMenuList: any = [];
  ATMenuList: any = [];
  constructor(
    public managerExecutionService: ManagerExecutionService,
    private spinner: NgxSpinnerService,
    private sfdService: ShopFloorDataService,
    private toaster: ToastrService,
    private dtService: DtServiceService,
    private router: Router,
    private analyticalToolsService: AnalyticalToolsService,
    private dialog: MatDialog,
    private enterpriseGroupService: EnterPriseGroupService,
    private commonService: CommonService,
    private userManagementService: UserManagementService
  ) {}

  ngOnInit(): void {
    this.ImageUrl = environment.O3CoreApiImageUrl;
    this.rcaId = this.analyticalToolsService.getRcaId();
    this.headerId = this.analyticalToolsService.getHeaderId();
    this.levelId = this.analyticalToolsService.getLevelId();
    this.filteredData = this.analyticalToolsService.getFilteredData();
    this.teamLeaderData = this.analyticalToolsService.getTeamLeaderList();
    this.currentIndex = this.analyticalToolsService.getFilteredIndex();
    setTimeout(() => {
      this.getGroups();
    }, 1500);
    this.setValidator();
    this.getUserList();
    this.GetMenuAccess();
    this.getMenulist();
    this.historicalHeaderValues =
      this.analyticalToolsService.getHistoricalHeaderData()
        ? this.analyticalToolsService.getHistoricalHeaderData()
        : 0;
    if (this.historicalHeaderValues) {
      this.headerData.Group = this.historicalHeaderValues.Group;
      this.headerData.Plant = this.historicalHeaderValues.Plant;
      if (this.historicalHeaderValues.Department != 'ALL')
        this.headerData.Dept = this.historicalHeaderValues.Department;
    }
  }

  ngOnChanges() {
    if (this.isReset) {
      if (this.formDirective) this.formDirective.resetForm();
      this.searchDefectForm.reset();
      this.plantList = [];
      this.plantTempList = [];
      this.deptList = [];
      this.deptTempList = [];
      this.teamLeaderList = [];
      this.teams = [];
      this.tempTeams = [];
      this.headerData.UploadFile = '';
      this.imageSrc = '';
      this.setParentResetFalse.emit(true);
      // this.isReset = false;
      this.headerData.Date = new Date();
      this.headerData.CreatedAt = new Date();
      this.isGet = true;
      this.getGroups();
    }
    if (
      this.headerData.Group &&
      this.headerData.Plant &&
      this.headerData.Dept
    ) {
      this.isGet = false;
      this.getDeptData();
    }
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getMenulist(): any {
    const obj = {};
    this.userManagementService.GetMenulist(obj).subscribe(
      (data: any) => {
        this.AllMenuList = data.mennulist;
        this.ATMenuList = this.AllMenuList.find(
          (menu: any) =>
            menu.Url == '/at/historical-table' && menu.List && menu.List.length
        );
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  ngDoCheck() {
    if (this.headerData.Group) {
      // this.getDeptData();
      //   this.spinnerText = "Fetching Team Data";
      // this.spinner.show();
      // const data = {
      //   EGId:this.headerData.Group,
      //   PlantId:this.headerData.Plant
      // }
      // this.analyticalToolsService.getTeamList(data).subscribe((res: any) => {
      //   this.teams = res.employeeInfoList;
      //   this.spinner.hide();
      // }, (err: any) => {
      //   this.spinner.hide();
      // });
    }
    this.rcaId = this.analyticalToolsService.getRcaId();
    this.rca = this.analyticalToolsService.getRca();
    this.headerId = this.analyticalToolsService.getHeaderId();
    this.levelId = this.analyticalToolsService.getLevelId();
    this.updateStatus = this.analyticalToolsService.getStatus();
    this.headerData.stageId =
      this.headerData && this.headerData.stageId
        ? this.headerData.stageId
        : this.stageId;
    this.filteredData = this.analyticalToolsService.getFilteredData();
    this.teamLeaderData = this.analyticalToolsService.getTeamLeaderList();
    this.currentIndex = this.analyticalToolsService.getFilteredIndex();
    if (this.teamLeaderData && this.teamLeaderData.length) {
      this.teamLeaderList = this.teams.filter((usr: any) =>
        this.teamLeaderData.includes(usr.EmpInfo_Id)
      );
    }
    // if(this.headerData.Group == '' && this.headerData.Plant == '' && this.headerData.Dept == '' && this.headerData.Team == '' && this.headerData.TeamLeader == '' && this.headerData.InitProbStatement == ''){
    //   this.plantList = [];
    //   this.plantTempList = [];
    //   this.deptList = [];
    //   this.deptTempList = [];
    //   this.teamLeaderList = [];
    //   this.teams = [];
    //   this.tempTeams = [];
    // }
    if (
      this.plantTempList &&
      !this.plantTempList.length &&
      this.headerData.Group &&
      !this.deptApiStarted &&
      !this.dataNotFoundForDept
    )
      this.getDeptData();
    // if (this.headerData.Group && (!this.headerData.Plant || !this.plantTempList.length))
    //   this.getDeptData();
    // if (this.plantTempList && this.plantTempList.length && this.headerData.Plant) {
    //   const plant = this.plantTempList.find((pt: any) => pt.DeptId == this.headerData.Plant);
    //   if (!plant) {
    //     this.getDeptData();
    //   }
    // }
  }

  getUserList() {
    // this.spinnerText = "Fetching User Data";
    this.spinner.show();
    this.managerExecutionService.getUserList().subscribe(
      (res: any) => {
        this.userList = res.UserList;
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  getTeamList() {
    // this.spinnerText = "Fetching Team Data";
    this.spinner.show();
    const data = {
      EGId: this.headerData.Group,
      PlantId: this.headerData.Plant,
    };
    this.commonService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        this.teams = res.employeeInfoList;
        this.teams.map(
          (team: any) =>
            (team.FullName = team.FirstName.trim() + ' ' + team.lastName.trim())
        );
        this.tempTeams = this.teams.slice();
        this.teamsParticipant = res.employeeInfoList;
        this.teamsParticipant.map(
          (teampart: any) =>
            (teampart.FullName =
              teampart.FirstName.trim() + ' ' + teampart.lastName.trim())
        );
        this.tempTeamsteamsParticipant = this.teamsParticipant.slice();
        if (
          this.teams &&
          this.teams.length &&
          this.teamLeaderData &&
          this.teamLeaderData.length &&
          this.teamLeaderList &&
          !this.teamLeaderList.length
        ) {
          this.teamLeaderList = this.teams.filter((usr: any) =>
            this.teamLeaderData.includes(usr.EmpInfo_Id)
          );
        }
        this.spinner.hide();
        this.teamLeaderList = [];
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  // setTeamLeader(val: any) {
  //   this.teamLeaderList = [];
  //   this.teamArr = [];
  //   if (this.tempTeams && this.tempTeams.length) {
  //     for (var i = 0; i < val.length; i++) {
  //       let obj = this.tempTeams.find((ind: any) => ind.EmpInfo_Id == val[i]);
  //       if (obj != undefined)
  //         this.teamLeaderList.push(obj);
  //     }
  //   }
  //   this.teamArr = this.analyticalToolsService.getTeamLeaderList() ? this.analyticalToolsService.getTeamLeaderList() : [];
  //   if (this.teamLeaderList && this.teamLeaderList.length) {
  //     for (const teamid of this.teamLeaderList) {
  //       if (this.teamArr && this.teamArr.length) {
  //         let obj = this.teamArr.find((ind: any) => ind == teamid.EmpInfo_Id);
  //         if (!obj)
  //           this.teamArr.push(teamid.EmpInfo_Id)
  //       }
  //       else
  //         this.teamArr.push(teamid.EmpInfo_Id)
  //     }
  //   }
  // }

  setTeamAndValidate(val: any) {
    if (this.headerData.TeamLeader != val) {
      let obj = this.headerData.Team.find((teamId: any) => teamId == val);
      if (!obj) {
        let index = this.teamArr.findIndex((ind: any) => ind == val);
        if (index > -1) {
          this.teamArr.splice(index, 1);
          this.analyticalToolsService.setTeamLeaderList(this.teamArr);
          this.headerData.Team =
            this.analyticalToolsService.getTeamLeaderList();
        }
      } else {
        this.analyticalToolsService.setTeamLeaderList(this.teamArr);
        this.headerData.Team = this.analyticalToolsService.getTeamLeaderList();
      }
    }
  }

  keyUp() {
    return;
    this.headerData.Team = this.analyticalToolsService.getTeamLeaderList()
      ? this.analyticalToolsService.getTeamLeaderList()
      : [];
    if (this.headerData.Team && this.headerData.Team.length)
      this.searchDefectForm.controls.team.patchValue([
        ...this.headerData.Team.map((item: any) => item),
      ]);
  }

  setTeam() {
    this.tempTeamsteamsParticipant = this.teamsParticipant;
    this.headerData.Team = this.analyticalToolsService.getTeamLeaderList()
      ? this.analyticalToolsService.getTeamLeaderList()
      : [];
    if (this.headerData.Team && this.headerData.Team.length)
      this.searchDefectForm.controls.team.patchValue([
        ...this.headerData.Team.map((item: any) => item),
      ]);
  }

  resetSelectFilter(type: any) {
    if (type == 'TeamLeader') {
      if (this.selectTeamLeaderFilter && this.selectTeamLeaderFilter.searchForm)
        this.selectTeamLeaderFilter.searchForm.reset();
    } else if (type == 'Participants') {
      if (
        this.selectParticipantsFilter &&
        this.selectParticipantsFilter.searchForm
      )
        this.selectParticipantsFilter.searchForm.reset();
    }
  }

  addTeamLeader(val: any) {
    if (this.headerData.Team && this.headerData.Team.length) {
      let obj = this.headerData.Team.find((team: any) => team == val);
      if (!obj) {
        this.headerData.Team.push(val);
        this.searchDefectForm.controls.team.patchValue([
          ...this.headerData.Team.map((item: any) => item),
        ]);
        this.analyticalToolsService.setTeamLeaderList(this.headerData.Team);
      }

      if (this.previousTeamLeader != val) {
        let leadobj = this.headerData.Team.findIndex(
          (team: any) => team == this.previousTeamLeader
        );
        if (leadobj > -1) {
          this.headerData.Team.splice(leadobj, 1);
          this.analyticalToolsService.setTeamLeaderList(this.headerData.Team);
          this.headerData.Team =
            this.analyticalToolsService.getTeamLeaderList();
        }
      }
    } else {
      this.headerData.Team = [];
      this.headerData.Team.push(val);
      this.analyticalToolsService.setTeamLeaderList(this.headerData.Team);
    }
    this.previousTeamLeader = val;
  }

  clickTeamLeader(val: any) {
  }

  // removeFromTeam(val: any) {
  //   if (this.headerData.Team && this.headerData.Team.length) {
  //     let index = this.headerData.Team.findIndex((ind: any) => ind == val);
  //     if (index > -1) {
  //       this.headerData.Team.splice(index, 1);
  //       this.searchDefectForm.controls.team
  //         .patchValue([...this.headerData.Team.map((item: any) => item)]);
  //     }
  //   }
  // }

  setParticipantsChange(val) {
    let teamLeaderList = [];
    this.teamArr = [];
    // this.analyticalToolsService.setTeamLeaderList(val);

    if (this.teamsParticipant && this.teamsParticipant.length) {
      for (var i = 0; i < val.length; i++) {
        let obj = this.teamsParticipant.find(
          (ind: any) => ind.EmpInfo_Id == val[i]
        );
        if (obj != undefined) teamLeaderList.push(obj);
      }
    }
    this.teamArr = this.analyticalToolsService.getTeamLeaderList()
      ? this.analyticalToolsService.getTeamLeaderList()
      : [];

    if (teamLeaderList && teamLeaderList.length) {
      for (const teamid of teamLeaderList) {
        if (this.teamArr && this.teamArr.length) {
          let obj = this.teamArr.find((ind: any) => ind == teamid.EmpInfo_Id);
          if (!obj) this.teamArr.push(teamid.EmpInfo_Id);
        } else this.teamArr.push(teamid.EmpInfo_Id);
      }
    }
  }

  resetTask() {
    this.reset.emit();
  }

  deleteFile() {
    this.imageFile = [];
    this.headerData.UploadFile = '';
    this.imageSrc = '';
  }

  setValidator() {
    // if (this.stageId == 1 || this.stageId == 4) {
    //   this.searchDefectForm.controls['initProbStat'].setValidators([Validators.required]);
    //   this.searchDefectForm.controls['initProbStat'].updateValueAndValidity();
    // }
    // else if (this.stageId == 2 || this.stageId == 3) {
    //   this.searchDefectForm.controls['mType'].setValidators([Validators.required]);
    //   this.searchDefectForm.controls['mType'].updateValueAndValidity();
    // }
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    // this.spinnerText = 'Fetching Groups Data';
    this.spinner.show();
    this.sfdService.getGroupData(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.groupList = res.masterList;
        this.groupTempList = this.groupList.slice();
        // setTimeout(() => {
        // if(this.isGet){
        if (
          this.groupTempList &&
          this.groupTempList.length &&
          !this.headerData.Group
        )
          this.headerData.Group = this.groupTempList[0].TypeId;
        if (this.headerData.Plant == '') this.getDeptData();
        //   }
        // }, 2000);
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getDeptData(): void {
    const key = {
      GroupId: this.headerData.Group,
    };
    this.deptApiStarted = true;
    this.spinner.show();
    this.dtService.getPlantData(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.deptApiStarted = false;
        this.plantList = res.DepartList;
        this.plantTempList = this.plantList.slice();
        //Reset plant if not exists from the list
        if (
          this.plantTempList &&
          this.plantTempList.length &&
          this.headerData.Plant
        ) {
          const plant = this.plantTempList.find(
            (pt: any) => pt.DeptId == this.headerData.Plant
          );
          if (!plant) {
            this.plantTempList = [];
            this.headerData.Plant = '';
            this.getDeptData();
            return;
          }
        }
        if (res.DepartList && !res.DepartList.length)
          this.dataNotFoundForDept = true;
        else this.dataNotFoundForDept = false;
        //Update the first data of plant
        if (
          this.plantTempList &&
          this.plantTempList.length &&
          !this.headerData.Plant
        )
          this.headerData.Plant = parseInt(this.plantTempList[0].DeptId);
        this.getAllDepartment();
        this.getTeamList();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getAllDepartment() {
    // const key = {
    //   egId: this.headerData.Group,
    //   plantId: this.headerData.Plant
    // };
    const key = {
      ptId: this.headerData.Plant,
    };
    this.spinner.show();
    this.dtService.getDeptData(key).subscribe((res: any) => {
      this.deptList = res;
      this.deptTempList = this.deptList.slice();
      //Reset plant if not exists from the list
      if (
        this.deptTempList &&
        this.deptTempList.length &&
        this.headerData.Dept
      ) {
        const department = this.deptTempList.find(
          (dep: any) => dep.TypeId == this.headerData.Dept
        );
        if (!department) {
          this.headerData.Dept = '';
          this.getAllDepartment();
          return;
        }
      }
      if (
        this.deptTempList &&
        this.deptTempList.length &&
        !this.headerData.Dept
      )
        this.headerData.Dept = parseInt(this.deptTempList[0].TypeId);
      this.spinner.hide();
      this.getUniqueCode();
    });
  }

  getUniqueCode() {
    const key = {
      Id: this.headerData.Dept,
    };
    // this.spinnerText = 'Fetching Groups Data';
    this.spinner.show();
    this.analyticalToolsService.getUniqueCode(key).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.uniqueCodeList = res.data;
        this.uniqueCodeTempList = this.uniqueCodeList.slice();
        if (
          this.uniqueCodeTempList &&
          this.uniqueCodeTempList.length &&
          this.headerData.ID
        ) {
          const uniqId = this.uniqueCodeTempList.find(
            (uni: any) => uni.ID == this.headerData.ID
          );
          this.headerData.uniqueCode =
            uniqId && uniqId.ID
              ? 'AT-RCA-' + uniqId.ID
              : this.headerData && this.headerData.uniqueCode
              ? this.headerData.uniqueCode
              : '';
        }
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  navigate(type: string) {
    this.headerData.ID = this.analyticalToolsService.getHeaderId();
    if (type == 'previous') {
      if (this.stageId == 2) {
        const menuObj = this.ATMenuList.List.find(
          (menu: any) => menu.Url == '/at'
        );
        localStorage.setItem('O3RightMenuId', menuObj.ID);
        this.router.navigate(['/at']);
      } else if (this.stageId == 3) {
        const menuObj = this.ATMenuList.List.find(
          (menu: any) => menu.Url == '/at/fishbone'
        );
        localStorage.setItem('O3RightMenuId', menuObj.ID);
        this.router.navigate(['/at/fishbone']);
      } else if (this.stageId == 4) {
        const menuObj = this.ATMenuList.List.find(
          (menu: any) => menu.Url == '/at/causeEffect'
        );
        localStorage.setItem('O3RightMenuId', menuObj.ID);
        this.router.navigate(['/at/causeEffect']);
      } else if (this.stageId == 5) {
        const menuObj = this.ATMenuList.List.find(
          (menu: any) => menu.Url == '/at/fivewhy'
        );
        localStorage.setItem('O3RightMenuId', menuObj.ID);
        this.router.navigate(['/at/fivewhy']);
      }
    } else {
      if (this.stageId == 1) {
        if (
          this.headerData.Status6W2H &&
          this.headerData.Status6W2H == 'Completed'
        ) {
          const menuObj = this.ATMenuList.List.find(
            (menu: any) => menu.Url == '/at/fishbone'
          );
          localStorage.setItem('O3RightMenuId', menuObj.ID);
          this.router.navigate(['/at/fishbone']);
        } else this.toaster.warning('Warning', 'Please Complete This Stage');
      } else if (this.stageId == 2) {
        if (
          this.headerData.StatusFishbone &&
          this.headerData.StatusFishbone == 'Completed'
        ) {
          const menuObj = this.ATMenuList.List.find(
            (menu: any) => menu.Url == '/at/causeEffect'
          );
          localStorage.setItem('O3RightMenuId', menuObj.ID);
          this.router.navigate(['/at/causeEffect']);
        } else this.toaster.warning('Warning', 'Please Complete This Stage');
      } else if (this.stageId == 3) {
        if (
          this.headerData.StatusCauseEffect &&
          this.headerData.StatusCauseEffect == 'Completed'
        ) {
          const menuObj = this.ATMenuList.List.find(
            (menu: any) => menu.Url == '/at/fivewhy'
          );
          localStorage.setItem('O3RightMenuId', menuObj.ID);
          this.router.navigate(['/at/fivewhy']);
        } else this.toaster.warning('Warning', 'Please Complete This Stage');
      } else if (this.stageId == 4) {
        if (
          this.headerData.StatusFiveWhy &&
          this.headerData.StatusFiveWhy != 'Open'
        ) {
          const menuObj = this.ATMenuList.List.find(
            (menu: any) => menu.Url == '/at/fivewhy-actpln'
          );
          localStorage.setItem('O3RightMenuId', menuObj.ID);
          this.router.navigate(['/at/fivewhy-actpln'], {
            queryParams: { headerId: this.headerData.ID },
          });
        } else
          this.toaster.warning(
            'Warning',
            'Please InProgress Or Complete This Stage'
          );
      }
    }
  }

  onSubmit(formData: any, formDirective: FormGroupDirective) {
    this.headerData.hasRca = this.rca;
    this.headerData.UploadFile = this.imageFile;
    if (this.headerData.stageId != 6) delete this.headerData.swotAndPest;
    if (this.searchDefectForm.valid) this.save.emit(this.headerData);
    this.formDirective = formDirective;
    this.headerData.UploadFile = '';
    // this.imageFile = '';
    // this.imageSrc = '';
  }

  pageSettings(type: string, last: boolean) {
    if (type == 'previous') {
      if (last) this.currentIndex = 0;
      else this.currentIndex -= 1;
    } else {
      if (last) this.currentIndex = this.filteredData.length - 1;
      else this.currentIndex += 1;
    }
    this.analyticalToolsService.setFilteredIndex(this.currentIndex);
    this.analyticalToolsService.setHeaderId(
      this.filteredData[this.currentIndex].ID
    );
    this.emitSearchData.emit(this.filteredData[this.currentIndex]);
  }

  onKey(value: any, type: any) {
    if (type == 'group') {
      this.groupList = this.searchKey(value.value, type);
    } else if (type == 'plant') {
      this.plantList = this.searchKey(value.value, type);
    }
  }

  searchKey(value: string, type: string) {
    let filter = value;
    if (type == 'group') {
      return this.groupTempList.filter((dep: any) => {
        let lowerCase = dep.TypeName.toLocaleLowerCase();
        return dep.TypeName.startsWith(filter) || lowerCase.startsWith(filter);
      });
    } else if (type == 'plant') {
      return this.plantTempList.filter((pla: any) => {
        let lowerCase = pla.DeptDesc.toLocaleLowerCase();
        return pla.DeptDesc.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
  }

  search() {
    this.spinnerText = 'Searching Data';
    const queryData = Object.assign({}, this.headerData);
    if (this.headerData.TeamLeader) {
      let obj = this.teams.find(
        (team: any) => team.EmpInfo_Id == this.headerData.TeamLeader
      );
      if (!obj) {
        this.headerData.Team = '';
        this.headerData.TeamLeader = '';
        this.headerData.InitProbStatement = '';
      }
    }
    // if (this.stageId == 6)
    delete queryData.NoOfM;
    // else if (this.stageId == 2 || this.stageId == 3)
    //   delete queryData.InitProbStatement;
    delete queryData.CreatedBy;
    // delete queryData.uniqueCode;
    this.spinner.show();
    if (!this.headerData.Department) {
      delete queryData.Department;
    }
    if (!this.headerData.Team) {
      delete queryData.Team;
    }
    if (!this.headerData.InitProbStatement) {
      delete queryData.InitProbStatement;
    }
    if (!this.headerData.TeamLeader) {
      delete queryData.TeamLeader;
    }
    this.analyticalToolsService.searchHeader(queryData).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.data && res.data.length) {
          // if (this.stageId == 1)
          //   this.filteredData = res.data.filter((data: any) => data.swth && data.swth.ID);
          // else if (this.stageId == 2)
          //   this.filteredData = res.data.filter((data: any) => data.fishBone && data.fishBone.ID);
          // else if (this.stageId == 3)
          //   this.filteredData = res.data.filter((data: any) => data.causeAndEffects && data.causeAndEffects.length);
          // else if (this.stageId == 4)
          //   this.filteredData = res.data.filter((data: any) => data.fiveWhy && data.fiveWhy.length || data.causeAndEffects);
          // else if (this.stageId == 6)
          //   this.filteredData = res.data.filter((data: any) => data.swotAndPest && data.swotAndPest.length);

          this.filteredData = res.data.filter(
            (data: any) => data.swth && data.swth.ID
          );
          if (this.filteredData && !this.filteredData.length) {
            this.filteredData = res.data.filter(
              (data: any) => data.fishBone && data.fishBone.ID
            );
            if (this.filteredData && !this.filteredData.length) {
              this.filteredData = res.data.filter(
                (data: any) =>
                  data.causeAndEffects && data.causeAndEffects.length
              );
              if (this.filteredData && !this.filteredData.length) {
                this.filteredData = res.data.filter(
                  (data: any) => data.fiveWhy && data.fiveWhy.length
                );
                if (this.filteredData && !this.filteredData.length) {
                  this.filteredData = res.data.filter(
                    (data: any) => data.swotAndPest && data.swotAndPest.length
                  );
                } else {
                  let arr = res.data.filter(
                    (data: any) => data.swth && data.swth.ID
                  );
                  arr = arr && arr.length ? arr : [];
                  if (arr && arr.length) {
                    for (const ar of arr) {
                      const isExist = this.filteredData.find(
                        (fildata: any) => fildata.ID == ar.ID
                      );
                      if (!isExist) this.filteredData.push(ar);
                    }
                  }

                  arr = res.data.filter(
                    (data: any) => data.fishBone && data.fishBone.ID
                  );
                  arr = arr && arr.length ? arr : [];
                  if (arr && arr.length) {
                    for (const ar of arr) {
                      const isExist = this.filteredData.find(
                        (fildata: any) => fildata.ID == ar.ID
                      );
                      if (!isExist) this.filteredData.push(ar);
                    }
                  }

                  arr = res.data.filter(
                    (data: any) =>
                      data.causeAndEffects && data.causeAndEffects.length
                  );
                  arr = arr && arr.length ? arr : [];
                  if (arr && arr.length) {
                    for (const ar of arr) {
                      const isExist = this.filteredData.find(
                        (fildata: any) => fildata.ID == ar.ID
                      );
                      if (!isExist) this.filteredData.push(ar);
                    }
                  }

                  // arr = res.data.filter((data: any) => data.swotAndPest && data.swotAndPest.length);
                  // arr = arr && arr.length ? arr : []
                  // if (arr && arr.length) {
                  //   for (const ar of arr) {
                  //     const isExist = this.filteredData.find((fildata: any) => fildata.ID == ar.ID);
                  //     if (!isExist)
                  //       this.filteredData.push(ar);
                  //   }
                  // }
                }
              } else {
                let arr = res.data.filter(
                  (data: any) => data.swth && data.swth.ID
                );
                arr = arr && arr.length ? arr : [];
                if (arr && arr.length) {
                  for (const ar of arr) {
                    const isExist = this.filteredData.find(
                      (fildata: any) => fildata.ID == ar.ID
                    );
                    if (!isExist) this.filteredData.push(ar);
                  }
                }

                arr = res.data.filter(
                  (data: any) => data.fishBone && data.fishBone.ID
                );
                arr = arr && arr.length ? arr : [];
                if (arr && arr.length) {
                  for (const ar of arr) {
                    const isExist = this.filteredData.find(
                      (fildata: any) => fildata.ID == ar.ID
                    );
                    if (!isExist) this.filteredData.push(ar);
                  }
                }

                arr = res.data.filter(
                  (data: any) => data.fiveWhy && data.fiveWhy.length
                );
                arr = arr && arr.length ? arr : [];
                if (arr && arr.length) {
                  for (const ar of arr) {
                    const isExist = this.filteredData.find(
                      (fildata: any) => fildata.ID == ar.ID
                    );
                    if (!isExist) this.filteredData.push(ar);
                  }
                }

                // arr = res.data.filter((data: any) => data.swotAndPest && data.swotAndPest.length);
                // arr = arr && arr.length ? arr : []
                // if (arr && arr.length) {
                //   for (const ar of arr) {
                //     const isExist = this.filteredData.find((fildata: any) => fildata.ID == ar.ID);
                //     if (!isExist)
                //       this.filteredData.push(ar);
                //   }
                // }
              }
            } else {
              let arr = res.data.filter(
                (data: any) => data.swth && data.swth.ID
              );
              arr = arr && arr.length ? arr : [];
              if (arr && arr.length) {
                for (const ar of arr) {
                  const isExist = this.filteredData.find(
                    (fildata: any) => fildata.ID == ar.ID
                  );
                  if (!isExist) this.filteredData.push(ar);
                }
              }

              arr = res.data.filter(
                (data: any) =>
                  data.causeAndEffects && data.causeAndEffects.length
              );
              arr = arr && arr.length ? arr : [];
              if (arr && arr.length) {
                for (const ar of arr) {
                  const isExist = this.filteredData.find(
                    (fildata: any) => fildata.ID == ar.ID
                  );
                  if (!isExist) this.filteredData.push(ar);
                }
              }

              arr = res.data.filter(
                (data: any) => data.fiveWhy && data.fiveWhy.length
              );
              arr = arr && arr.length ? arr : [];
              if (arr && arr.length) {
                for (const ar of arr) {
                  const isExist = this.filteredData.find(
                    (fildata: any) => fildata.ID == ar.ID
                  );
                  if (!isExist) this.filteredData.push(ar);
                }
              }

              // arr = res.data.filter((data: any) => data.swotAndPest && data.swotAndPest.length);
              // arr = arr && arr.length ? arr : []
              // if (arr && arr.length) {
              //   for (const ar of arr) {
              //     const isExist = this.filteredData.find((fildata: any) => fildata.ID == ar.ID);
              //     if (!isExist)
              //       this.filteredData.push(ar);
              //   }
              // }
            }
          } else {
            let arr = res.data.filter(
              (data: any) => data.fishBone && data.fishBone.ID
            );
            arr = arr && arr.length ? arr : [];
            if (arr && arr.length) {
              for (const ar of arr) {
                const isExist = this.filteredData.find(
                  (fildata: any) => fildata.ID == ar.ID
                );
                if (!isExist) this.filteredData.push(ar);
              }
            }

            arr = res.data.filter(
              (data: any) => data.causeAndEffects && data.causeAndEffects.length
            );
            arr = arr && arr.length ? arr : [];
            if (arr && arr.length) {
              for (const ar of arr) {
                const isExist = this.filteredData.find(
                  (fildata: any) => fildata.ID == ar.ID
                );
                if (!isExist) this.filteredData.push(ar);
              }
            }

            arr = res.data.filter(
              (data: any) => data.fiveWhy && data.fiveWhy.length
            );
            arr = arr && arr.length ? arr : [];
            if (arr && arr.length) {
              for (const ar of arr) {
                const isExist = this.filteredData.find(
                  (fildata: any) => fildata.ID == ar.ID
                );
                if (!isExist) this.filteredData.push(ar);
              }
            }

            // arr = res.data.filter((data: any) => data.swotAndPest && data.swotAndPest.length);
            // arr = arr && arr.length ? arr : []
            // if (arr && arr.length) {
            //   for (const ar of arr) {
            //     const isExist = this.filteredData.find((fildata: any) => fildata.ID == ar.ID);
            //     if (!isExist)
            //       this.filteredData.push(ar);
            //   }
            // }
          }

          this.analyticalToolsService.setFilteredData(this.filteredData);
          if (this.filteredData && this.filteredData.length) {
            this.currentIndex = 0;
            this.analyticalToolsService.setFilteredIndex(this.currentIndex);
            this.analyticalToolsService.setHeaderId(
              this.filteredData[this.currentIndex].ID
            );
            this.emitSearchData.emit(this.filteredData[this.currentIndex]);
          }
        } else this.toaster.info('Info', res.message);
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  openFile() {
    this.fileInput.nativeElement.click();
  }

  readFile(event: any) {
    if (event.target.files && event.target.files.length) {
      this.imageFile = event.target.files[0];
      // this.imageFileName = this.imageFile.name;

      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };
      }
    }
  }

  getFileTypeName(val: any) {
    // if(val && typeof val === 'string'){
    let arr = val.split('.');
    if (arr && arr.length && arr.length != 1)
      return `( ${arr[arr.length - 1]} )`;
    // else if (arr.length == 1) {
    //   arr = val.split(';');
    //   arr = arr[0].split('/');
    //   return `( ${arr[arr.length - 1]} )`;
    // }
    else return ``;
    // }
  }

  imageZoomModal(): void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      height: '90%',
      width: '75%',
      data: this.imageSrc
        ? this.imageSrc
        : this.ImageUrl + this.headerData.UploadFile,
    });
    // this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  // emitMType() {
  //   this.mType.emit(this.headerData.NoOfM);
  // }

  emitPlantChange() {
    this.plantChange.emit();
  }
}
