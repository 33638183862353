import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Trend } from 'src/app/models/trend';
import { UserManu } from 'src/app/models/userManu';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.scss'],
})
export class TrendsComponent implements OnInit {
  @Input() trPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<Trend>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  plantId!: number;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!:any;
  breadcrumList: any;
  trendColumns: string[] = ['plant', 'name'];
  trendData!: MatTableDataSource<Trend>;
  constructor(
    private kpiService: KpimasterService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private translater: TranslaterService,
    private usermanagementService: UserManagementService,
  ) {
  }
  ngOnInit(): void {
    this.GetBreadcrumList();
    //this.getLangWords();
    this.isRead = this.trPermissions.IsAccess;
    this.isWrite = this.trPermissions.IsWrite;
    this.isUpdate = this.trPermissions.IsWrite;
    this.isDelete = this.trPermissions.IsWrite;
  }
  getPaginator(): void {
    setTimeout(() => {
      this.trendData.paginator = this.paginator;
      this.trendData.sort = this.sort;
    }, 1000);
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }


  

//menu and breadcrumb
GetBreadcrumList(): any {
  const obj = {
    MenuId:
      localStorage.getItem('O3RightMenuId') === null
        ? 1
        : localStorage.getItem('O3RightMenuId'),
  };
  // tslint:disable-next-line: align
  this.usermanagementService.GetBreadcrumList(obj).subscribe(
    (data: any) => {
      this.breadcrumList = data.BreadcrumList;
      this.moduleId = this.breadcrumList[0].id;
      this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      this.getLangWords();
    },
    (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    }
  );
}

  
  
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.trendData.filter = filterValue.trim().toLowerCase();

    if (this.trendData.paginator) {
      this.trendData.paginator.firstPage();
    }
  }
  getMasterTrends(): void {
    this.spinner.show();
    this.kpiService.getTrends().subscribe(
      (trends) => {
        this.trendData = new MatTableDataSource<Trend>(trends);
        this.getPaginator();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
}
