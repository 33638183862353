<div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{ breadcrumItem.text }}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">Parameter Analysis Dashboard</h1>
    </div>
    <div class="content-wrapper">
      <form [formGroup]="FilterDataForm">
        <div class="content-row-wrap">
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Select Group</mat-label>
            <mat-select panelClass="testClass" name="group" [formControlName]="'group'" (selectionChange)="getPlantByGroup()">
              <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId">
                {{ g.TypeName }}
              </mat-option>
            </mat-select>
            <mat-error>Please select group</mat-error>
          </mat-form-field>

          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Select Plant</mat-label>
            <mat-select panelClass="testClass" name="plant" [formControlName]="'plant'" (selectionChange)="getMachineParametersDropdown()">
              <mat-option *ngFor="let p of plants" [value]="p.DeptId">
                {{ p.DeptDesc }}
              </mat-option>
            </mat-select>
            <mat-error>Please select plant</mat-error>
          </mat-form-field>

          <div class="inputField" id="MachineParameterList">
            <label class="floating-label" for="multiselect">Machine Parameter</label>
            <p-multiSelect inputId="multiselect" formControlName="machineParam" [options]="MachineParameterArray" optionLabel="name"
              [filter]="true" selectedItemsLabel="ALL"
              maxSelectedLabels={{MachineParameterArray.length-1}} defaultLabel="Select Parameter...">
            </p-multiSelect>
          </div>

          <div class="inputField" id="QualityParameterList">
            <label class="floating-label" for="multiselect">Quality Parameter</label>
            <p-multiSelect inputId="multiselect" formControlName="qualityParam" [options]="QualityParameterArray" optionLabel="name"
              [filter]="true" selectedItemsLabel="ALL"
              maxSelectedLabels={{QualityParameterArray.length-1}} defaultLabel="Select Parameter...">
            </p-multiSelect>
          </div>
          <div class="inputField" style="width:27%; margin-top:15px;">
            <nz-range-picker class="calender-input" [nzInputReadOnly]="true" [nzShowTime]="{ nzFormat: 'HH:mm' }"
              nzFormat="yyyy-MM-dd HH:mm" [formControlName]="'datePicker'">
            </nz-range-picker>
            <span class="floating-text">Choose Date Range</span>
          </div>
          <div class=" d-flex justify-content-between" style="margin-left:10px;">
            <mat-spinner-button [options]="spinnerButtonOptions" (btnClick)="onClickParameterAnalysis()" class="loader"
              id="selectedRows" style="margin-top:10px; margin-left:10px; padding:5px;">
            </mat-spinner-button>
            <mat-spinner-button [options]="spinnerButtonOptions1" (btnClick)="onClickParameterComparsion()"
              class="loader" id="selectedRows" style="margin-top:10px; margin-left:10px; padding:5px;">
            </mat-spinner-button>
          </div>
        </div>
      </form>
      <div *ngIf="analysisType" style="margin-top:10px;">
        <div *ngFor="let item of chartArray">
          <div [id]="item.id" [chart]="item.chartOption"></div>
        </div>
      </div>
      <div *ngIf="!analysisType" style="margin-top:10px;">
        <div [chart]="chart"></div>
      </div>
    </div>
  </div>
</div>

