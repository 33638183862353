import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  PlantGroupElement,
  PlantModelElement,
} from 'src/app/models/O3AdministrationModels/plant-model';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UserManu } from 'src/app/models/userManu';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';

import { TranslaterService } from 'src/app/services/translater.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { ExampleFlatNode } from 'src/app/interface/plant-model';
import { ProductivityService } from 'src/app/services/productivity-service';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { MatList } from '@angular/material/list';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-kpihierarchymaster',
  templateUrl: './kpihierarchymaster.component.html',
  styleUrls: ['./kpihierarchymaster.component.scss']
})
export class KpihierarchymasterComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<PlantModelElement>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('select') select: MatSelect;
  @ViewChild('displayrowRoleModal')
  private displayrowRoleModal!: TemplateRef<any>;
  @ViewChild(MatList, { read: ElementRef }) child: ElementRef;
  formMode!: boolean;
  edited = false;
  KPIMasterColumns: string[] = [
    'KPIMasterActions',
    'SKPIId',
    'Name',
    'Remarks',
    'level',
    'IsActive',
    'CreatedBy',
    'CreatedOn',
  ];
  formulas = [
   {formula: '+',id:1},
   {formula: '-',id:2},
   {formula: '*',id:3},
   {formula: '÷',id:4},
   {formula: '(',id:5},
   {formula: ')',id:6},
   {formula: '0',id:7},
   {formula: '1',id:8},
   {formula: '2',id:9},
   {formula: '3',id:10},
   {formula: '4',id:11},
   {formula: '5',id:12},
   {formula: '6',id:13},
   {formula: '7',id:14},
   {formula: '8',id:15},
   {formula: '9',id:16}
  ]
  //drag prop
  _currentIndex;
  _currentField;
  destination = [];
  //drag prop end
  KPIModelData!: MatTableDataSource<PlantModelElement>;
  KPIHierarchyForm!: FormGroup;
  groupForm!: FormGroup;
  KpiIntegrationForm!:FormGroup;
  isaddKPIModelVisible = false;
  plnatPermission!: UserManu;
  kpis = new FormControl('');
  plantgroup!: any;
  plantgroupFormMode!: boolean;
  isPlantGroupModelVisible = false;
  excelData: any = [];
  makeList :any=[];
  addnewGroupModelTitle = '';
  addKPIChildForm!: FormGroup;
  isaddNewGroupItemModelVisible = false;
  addNewGroupItemFormSubmitted = false;
  newKPIItemParent!: any;
  breadcrumList!: any;
  ExportPG: any = [];
  ExportPara: any = [];
  ExportPS: any = [];
  editGroupModelTitle = '';
  plantModelRowId!: number;
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  loading = false;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  showButton: boolean = false;
  islevelfirst : boolean = true;
  GroupList!: any[];
  PlantList!:any[];
  SKPIList!:any[];
  KPIList!:any[];
  TreeList!:any[];
  allTreeList!:any[];
  filteredKPIs: any;
  filteredGroups: any;
  filteredPlants: any;
  filteredStretegic: any;
  allSelected = false;
  kpiName!:any;
  KPITreeId !:any;
  groupId!:any;
  dataItemEdit!:any;
  tabIndex: number = 0
  clickedIndex:number=0;
  // parent node
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.label,
      level: level,
      Level : node.level,
      parent:node.id
    
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  dataSourceIntegration = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  //
  TreeModelData: any = [];
  TreeModelDataIntegration: any = [];
  @ViewChild('treeSelector') tree: any;
  constructor(
    private formBuilder: FormBuilder,
    private platModelService: EnterPriseGroupService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private userManagementService: UserManagementService,
    public router: Router,
    public translater: TranslaterService,
    private productivityservice: ProductivityService,
    private kpiTreeService : KpitreeService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.formMode = true;
    this.plantgroupFormMode = true;
    this.KPIHierarchyForm = this.formBuilder.group({
      plant: ['', Validators.required],
      strategicKPI: ['', Validators.required],
      remarks: [''],
      rename: [''],
      kpi : ['']
    });
    this.addKPIChildForm = this.formBuilder.group({
      kpi : [''],
      plant:['',Validators.required],
      rename : [''],
      level : [''],
      remarks: [''],
    });
    this.groupForm = this.formBuilder.group({
      group: ['', Validators.required],
    });

    this.KpiIntegrationForm = this.formBuilder.group({
      formula : [''],
      plant : [''],
      group : [''],
      availableSearchInput : []
    })
    this.getGroup();
  }

  getGroup(): void {
    const key = {
      UserId: 206     //later it will be change this is only for testing
    };
    const id = {
      value: 0
    };
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      if (this.GroupList.length > 0) {
        id.value = data.lstEG[0].EG_Id;
        this.groupForm.get('group')?.setValue(data.lstEG[0].EG_Id);
        // this.oeeDashboardForm.get('UserPlant1')?.setValue(data.lstEG[0].EG_Id);
       this.filteredGroups = this.GroupList.slice();
       this.getKPIHierarchyMaster();
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(): void {
    const key = {
      EG_ID: this.groupForm.get('group').value,
      userId: 206
    };
    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList = data.lstPlant;
      if (this.PlantList.length > 0) {
        const id = data.lstPlant[0].PT_Id;
        this.KPIHierarchyForm.get('plant')?.patchValue(id);
        this.addKPIChildForm.get('plant')?.patchValue(id);
        this.filteredPlants = this.PlantList.slice();
        this.getKpisByPlant(id);
        this.getStategicKpis(id);
      }
    
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getKpisByPlant(id: number) {
    const key = {
      ptId: id
    };
    this.kpiTreeService.getKpisByPlant(key).subscribe((data: any) => {
      this.KPIList = data;
      if (this.KPIList.length > 0) {
        this.filteredKPIs = this.KPIList.slice();
      }
      if(this.plantgroupFormMode === false && this.dataItemEdit.KPIId !== null){
        this.tabIndex=0
        this.addKPIChildForm.get('plant')?.setValue(this.dataItemEdit.PlantId);
        this.addKPIChildForm.get('kpi') ?.setValue(this.dataItemEdit.KPIId);
        this.addKPIChildForm.get('rename') ?.setValue(this.dataItemEdit.name);
        this.addKPIChildForm.get('remarks') ?.setValue(this.dataItemEdit.remarks);
      }else if(this.plantgroupFormMode === false && this.dataItemEdit.KPIId === null){
        this.tabIndex=1
        this.addKPIChildForm.get('plant')?.setValue(this.dataItemEdit.PlantId);
        this.addKPIChildForm.get('level') ?.setValue(this.dataItemEdit.name);
        this.addKPIChildForm.get('remarks') ?.setValue(this.dataItemEdit.remarks);
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getStategicKpis(id: any) {
    const key = {
      plantId: id
    };
    this.kpiTreeService.getallStrategicKpi(key).subscribe((data: any) => {
      this.SKPIList = data;
      if (this.SKPIList.length > 0) {
        const id = data[0].skpiId;
       // this.KPIHierarchyForm.get('strategicKPI')?.patchValue(id);
        this.filteredStretegic = this.SKPIList.slice();
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  // start add new plant methods
  openaddNewKPI(): void {
    this.KPIHierarchyForm.reset();
    this.isaddKPIModelVisible = true;
    this.formMode = true;
    this.getPlants();
  }
  closeaddNewPlantItemModel(): void {
    this.isaddKPIModelVisible = false;
  }

  addNewKPIFormSubmit(): void {
    const kpiList =[];
    const key = {
      name : this.KPIHierarchyForm.get('rename')?.value === null ? this.getStategicKpiName() : this.KPIHierarchyForm.get('rename')?.value,
      KPIId : 0,
    }
    kpiList.push(key);
    const groupKey = {
      KPIList: kpiList,
      level:'1',
      KPICode : '',
      SKPIId : this.KPIHierarchyForm.get('strategicKPI')?.value,
      ParentId : '',
      PlantId : this.KPIHierarchyForm.get('plant')?.value,
      remarks : this.KPIHierarchyForm.get('remarks')?.value,
      groupId : this.groupForm.get('group')?.value,
      TransactionType : 1
    };
    this.kpiTreeService.createKPIHierarchy(groupKey).subscribe(
      (response: any) => {
        this.toaster.success('Success', response.ErrorMessage);
        // this.showPopupMsg(response.ErrorMessage);
        this.closeaddNewPlantItemModel();
        this.getKPIHierarchyMaster();
        this.KPIHierarchyForm.reset();
      },
      (error) => {
        this.toaster.error('Error', error.error.err.message);
      }
    );
    this.table.renderRows();
    this.KPIHierarchyForm.reset();
    this.isaddKPIModelVisible = false;
  }
  getStategicKpiName() :string{
    let obj = this.SKPIList.find(val=> val.skpiId === this.KPIHierarchyForm.get('strategicKPI')?.value);
   return obj?.name;
  }
  // end add new plant methods
  // start edit plant methods
  openEditKPIModel(element: any): void {
    this.addnewGroupModelTitle = element.name;
    this.isaddKPIModelVisible  = true;
    this.formMode = false;
    this.spinner.show();
    this.getPlants();
    const key = {
      Id: element.Id,
    };
    // if (this.plnatPermission.IsUpdate) {
      this.kpiTreeService.getKPIHierarchyMasterTreeById(key).subscribe(
        (response) => {
          this.KPIHierarchyForm.get('plant')?.setValue(response[0].PlantId);
          this.getStategicKpis(response[0].PlantId);
          this.KPIHierarchyForm.get('kpi') ?.setValue(response[0].KPIId);
          this.KPIHierarchyForm.get('rename')?.setValue(response[0].name);
          this.KPIHierarchyForm.get('strategicKPI')?.setValue(response[0].SKPIId);
          this.KPIHierarchyForm.get('remarks') ?.setValue(response[0].remarks);
          this.newKPIItemParent = response[0];
          this.spinner.hide();
        },
        (error) => {
          this.toaster.error('Error', error.message);
        }
      );
  }
  editKPIFormSubmit(): void {
    const kpiList =[];
    const key = {
      name : this.KPIHierarchyForm.get('rename')?.value === '' ? this.getStategicKpiName() : this.KPIHierarchyForm.get('rename')?.value,
      KPIId : 0,
    }
    kpiList.push(key);
    const groupKey = {
      Id : this.newKPIItemParent.Id,
      KPIList: kpiList,
      level:'1',
      KPICode : '',
      SKPIId : this.KPIHierarchyForm.get('strategicKPI')?.value,
      ParentId : '',
      PlantId : this.KPIHierarchyForm.get('plant')?.value,
      remarks : this.KPIHierarchyForm.get('remarks')?.value,
      groupId : this.groupForm.get('group')?.value,
      TransactionType: 2,
    };
    this.kpiTreeService.createKPIHierarchy(groupKey).subscribe(
      (response: any) => {
        this.toaster.success('Success', response.ErrorMessage);
        this.getKPIHierarchyMaster();
        this.KPIHierarchyForm.reset();
      },
      (error) => {
        this.toaster.error('Error', error.error.err.message);
      }
    );
    this.table.renderRows();
    this.KPIHierarchyForm.reset();
    this.isaddKPIModelVisible = false;
    this.table.renderRows();
  }
  // end edit plant methods
  // Start Delete Group
  deleteItem(obj : any): void {
  
     // this.plantModelRowId = EGId;
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete KPI!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          const kpiList =[];
              const key = {
                name : '',
                KPIId : 0,
              }
              kpiList.push(key);
              const groupKey = {
                Id : parseInt(obj.parent),
                KPIList: kpiList,
                level:'1',
                KPICode : '',
                SKPIId : this.KPIHierarchyForm.get('strategicKPI')?.value,
                ParentId : '',
                PlantId : this.KPIHierarchyForm.get('plant')?.value? this.KPIHierarchyForm.get('plant')?.value:0,
                remarks : this.KPIHierarchyForm.get('remarks')?.value,
                groupId : this.groupForm.get('group')?.value,
                TransactionType: 3,
              };
          this.kpiTreeService.createKPIHierarchy(groupKey).subscribe(
            (response: any) => {
              if(!response.HasError){ 
              this.toaster.success('success', response.ErrorMessage);
              this.getKPIHierarchyMaster();
              this.isPlantGroupModelVisible = true;
              this.getTreeModel(this.plantgroup);
              }
              else{
                this.toaster.error('Error', response.ErrorMessage);
              }
            },
            (error) => {
              this.toaster.error('Error', error.ErrorMessage);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your KPI is safe :)', 'error');
        }
      });
   
  }
  // End Delete Group


  //tab change event
  onTabChanged($event){
    this.clickedIndex = $event.index;
  }
  // start plant group methods
  openKPIModel(element: any): void {
    this.getTreeModel(element);
    this.plantgroup = element;
  }

  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }

  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  // end plant group methods
  // start group model methods
  openaddNewKPIItemModel(element: PlantGroupElement): void {
    this.plantgroupFormMode = true;
    this.newKPIItemParent = element;
    this.addnewGroupModelTitle = element.NextLevel; 
    this.addKPIChildForm.reset();
    this.isPlantGroupModelVisible = false;
    this.isaddNewGroupItemModelVisible = true;
    this.getPlants();
    this.edited = false;
  
  }
  addKPIItem(): void { 
    const kpiList =[];
    const obj = {
      name : this.addKPIChildForm.get('level')?.value,
      KPIId : this.addKPIChildForm.get('kpi')?.value
    } 
    kpiList.push(obj);
    const data = {
      KPIList: this.clickedIndex === 0 ? this.select.value : kpiList,
      level: parseInt(this.newKPIItemParent.Level)+1,
      KPIId : this.addKPIChildForm.get('kpi')?.value ,
      KPICode : '',
      SKPIId : this.KPIHierarchyForm.get('strategicKPI')?.value,
      ParentId : parseInt(this.newKPIItemParent.parent),
      PlantId : this.addKPIChildForm.get('plant')?.value,
      remarks : this.addKPIChildForm.get('remarks')?.value,
      groupId : this.groupForm.get('group')?.value,
      TransactionType : 1
    };
    this.kpiTreeService.createKPIHierarchy(data).subscribe(
      (response) => {
        this.toaster.success('Success', response.ErrorMessage);
        this.select.value =[];
        this.getTreeModel(this.plantgroup);
        this.isaddNewGroupItemModelVisible = false;
        this.isPlantGroupModelVisible = true;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getKpiName(id:number):string {
    const kpi=  this.KPIList.find(val=> val.kpiId === id);
    return kpi.name;
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  closeaddNewGroupModel(): void {
    this.isaddNewGroupItemModelVisible = false;
    this.isPlantGroupModelVisible = true;
  }
  openEditKPIItemModel(element: any): void {
    if(element.Level != 1){
      this.plantgroupFormMode = false;
      this.edited = false;
      this.getPlants();
      this.addnewGroupModelTitle = element.name;
      const data = {
         Id:element.parent
      };
      this.newKPIItemParent = element;
      this.kpiTreeService.getKPIHierarchyMasterTreeById(data).subscribe(
        (response) => {
          this.dataItemEdit = response[0];
          this.isaddNewGroupItemModelVisible = true;
          this.isPlantGroupModelVisible = false;
        },
        (error) => {
          this.toaster.error('Error', error.message);
        }
      );
    }else{
      this.toaster.error('Error', 'You can not update parent KPI from here');
    }
    
  }

  editKPIItem(): void {
    const kpiList =[];
    if(this.tabIndex === 0){
      const obj = {
        name : this.addKPIChildForm.get('rename')?.value === '' ? this.getKpiName(this.addKPIChildForm.get('kpi')?.value) : this.addKPIChildForm.get('rename')?.value,
        KPIId : this.addKPIChildForm.get('kpi')?.value
      } 
      kpiList.push(obj);
    }else{
      const obj = {
        name : this.addKPIChildForm.get('level')?.value,
        KPIId : this.addKPIChildForm.get('kpi')?.value
      } 
      kpiList.push(obj);
    }
  
    const data = {
      Id: this.newKPIItemParent.parent,
      KPIList: kpiList,
      KPIId : this.addKPIChildForm.get('kpi')?.value ,
      PlantId : this.addKPIChildForm.get('plant')?.value,
      remarks : this.addKPIChildForm.get('remarks')?.value,
      TransactionType: 2,
    };
    this.kpiTreeService.createKPIHierarchy(data).subscribe(
      (response) => {
        this.toaster.success('Success', response.ErrorMessage);
        this.getTreeModel(this.plantgroup);
        this.isaddNewGroupItemModelVisible = false;
        this.isPlantGroupModelVisible = true;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }


  // end group model methods
  getKPIHierarchyMaster(): void {
    this.spinner.show();
    const key = {
     groupId : this.groupForm.get('group')?.value
    };

    this.kpiTreeService.getKPIHierarchyMaster(key).subscribe(
      (data: any) => {
        debugger;
        this.KPIModelData = new MatTableDataSource<PlantModelElement>(
          data
        );
        this.getPaginator();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  getPaginator(): void {
    setTimeout(() => {
      this.KPIModelData.paginator = this.paginator;
      this.KPIModelData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.KPIModelData.filter = filterValue.trim().toLowerCase();
  }
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }
  getTreeModel(element: any): void {
    const key = {
      plantId: element.PlantId,
      userId:element.CreatedBy,
      Id : element.Id
    };
    this.spinner.show();
    this.kpiTreeService.getKPIHierarchyMasterTree(key).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.makeList=[];
        this.isPlantGroupModelVisible = true;
        this.TreeModelData = JSON.parse(JSON.stringify(data));
        this.makeList.push(data);
        this.dataSource.data = this.makeList;
       
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  // formula screen
  openFormulaModel(val:any):void{
    if(val.level === 0){
      this.toaster.info('warning','You can not set formula on parent node');
    }else{
    this.dialog.open(this.displayrowRoleModal, {
      height: '90%',
      width: '90%',
    });
    this.getGroupIntegration();
    this.kpiName = val.name;
    // using this KPI Id in Integration model
    this.KPITreeId = val.parent;
    this.destination = [];
    this.groupId = this.groupForm.get('group').value;
  }
  }

  getGroupIntegration(): void {
    const key = {
      UserId: 206     //later it will be change this is only for testing
    };
    const id = {
      value: 0
    };
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      if (this.GroupList.length > 0) {
        id.value = data.lstEG[0].EG_Id;
       this.filteredGroups = this.GroupList.slice();
       this.KpiIntegrationForm.get('group')?.patchValue(this.groupId);
       this.getPlantsIntegration();
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlantsIntegration(): void {
    const key = {
      EG_ID: this.KpiIntegrationForm.get('group').value,
      userId: 206
    };
    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList = data.lstPlant;
      if (this.PlantList.length > 0) {
        const id = data.lstPlant[0].PT_Id;
        this.KpiIntegrationForm.get('plant')?.patchValue(id);
        this.filteredPlants = this.PlantList.slice();
        this.getStretegic();
        this.getTreeBYTreeId();
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getStretegic() {
    const key = {
      groupId:   this.KpiIntegrationForm.get('group')?.value
    };
    // this.MachineId = null;
    this.kpiTreeService.getKPIHierarchyMaster(key).subscribe(
      (data: any) => {
        this.SKPIList = data;
        this.getKPIHierarchyMasterTreeById();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  gettree(obj : any):void{
    const key = {
      plantId:   this.KpiIntegrationForm.get('plant')?.value,
      treeId : obj.kpiTree,
      userId:0
    };
    this.kpiTreeService.getTreeData(key).subscribe(
      (data: any) => {
        this.TreeList = data.children;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getKPIHierarchyMasterTreeById(): void {
    this.spinner.show();
    const key = {
     Id : this.KPITreeId
    };

    this.kpiTreeService.getKPIHierarchyMasterTreeById(key).subscribe(
      (data: any) => {
        debugger;
        this.spinner.hide();
        this.destination = JSON.parse(data[0].calculationJson ? data[0].calculationJson : '');
       
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      let item: any = event.previousContainer.data[event.previousIndex];
      let copy: any = JSON.parse(JSON.stringify(item));
      let element: any = {};
      for (let attr in copy) {
        if (attr == "formula" || attr == "KPICode") {
          element[attr] = copy[attr];
        } 
      }
      this.destination.splice(event.currentIndex, 0, element);
    }
  }



  getTreeIntegration(element: any): void {
    const key = {
      plantId: element.PlantId,
      userId:element.CreatedBy,
      Id : element.Id
    };
    this.spinner.show();
    this.kpiTreeService.getKPIHierarchyMasterTree(key).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.makeList=[];
        this.TreeModelDataIntegration = JSON.parse(JSON.stringify(data));
        this.makeList.push(data);
        this.dataSourceIntegration.data = this.makeList;
       
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getTreeBYTreeId(): void {
    const key = {
      Id : this.KPITreeId,
    };
    this.spinner.show();
    this.kpiTreeService.getKPIHierarchyMasterTreeByPlant(key).subscribe(
      (data: any) => {
        this.allTreeList = data
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  removeKeyword(key: any, index: any): any {
    this.destination.splice(index, 1);
  }

  getKPIName(item:any):any{
    if(item.KPICode){
      const KPI = this.allTreeList.find(val=> val.KPICode === item.KPICode);
      return KPI?.Name;
    }
 
  }

  setFormula():void{
    const calculationString = [];let calculationString1;
    this.destination.forEach(value=>{
      calculationString.push(value.formula || value.KPICode);
    })
    calculationString1 = JSON.stringify(calculationString);
    let stringWithoutCommas = calculationString1.replace(/['"]+/g, '').replace(/,/g, "");
   
      const kpiList =[];
      const obj = {
        name :"",
        KPIId : this.addKPIChildForm.get('kpi')?.value
      } 
      kpiList.push(obj);
      const data = {
        Id: this.KPITreeId,
        KPIList: kpiList,
        KPIId : this.addKPIChildForm.get('kpi')?.value ,
        PlantId : 0,
        remarks : this.addKPIChildForm.get('remarks')?.value,
        calculationJson : JSON.stringify(this.destination),
        calculationString : stringWithoutCommas.slice(1,stringWithoutCommas.length-1),
        TransactionType: 4,
      };
      this.kpiTreeService.createKPIHierarchy(data).subscribe(
        (response) => {
          this.toaster.success('Success', response.ErrorMessage);
          this.getTreeModel(this.plantgroup);
        },
        (error) => {
          this.toaster.error('Error', error.message);
        }
      );
    


  }

  resetCalculation(): void {
   this.destination = [];
  }

  
}
