import { Overlay } from '@angular/cdk/overlay';
import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AtStagesNcrComponent } from 'src/app/analytical-tools/at-stages-ncr/at-stages-ncr.component';
import { generateGuid } from 'src/app/guidGenerator';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { AreaService } from 'src/app/services/area.service';
import { CcrHistoryService } from 'src/app/services/ccr-history.service';
import { CommonService } from 'src/app/services/common.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { NcrService } from 'src/app/services/ncr.service';
import { OeeConfigurationService } from 'src/app/services/oee-configuration.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { WorkFlowService } from 'src/app/services/work-flow.service';
import { ImmediateActionComponent } from '../immediate-action/immediate-action.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { BtnCellRendererForReports } from 'src/app/btn-cell-rendererForReports.component';
import { ManagerExecutionService } from 'src/app/services/manager.service';

const moment = _moment;
@Component({
  selector: 'app-ncr-mangement',
  templateUrl: './ncr-mangement.component.html',
  styleUrls: ['./ncr-mangement.component.scss'],
})
export class NcrMangementComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChildren(MatPaginator) paginator2 = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort2 = new QueryList<MatSort>();
  @ViewChild('previewModal') private previewModal!: TemplateRef<any>;

  @ViewChild('capaActionHistoryModel')
  private capaActionHistoryModel!: TemplateRef<any>;
  maxDate = new Date();
  minDate = new Date();
  loading = false;
  defect!: any[];
  noPermissionError: string =
    'Current user does not have enough permissions to perform this action';
  filteredDefect: any;
  filteredCustomer: any;
  moduleId!: any;
  pageId!: any;
  cellData: any;
  rejvalue!: any;
  rcavalue!: any;
  breadcrumList!: any;
  columnDefs: any;
  columnDefs1: any;
  rowData: any;
  dataArray: any = [];
  datasource: any;
  areahide = true;
  Machinetype = true;
  isShow = false;
  imageSrc!: string;
  filesForm!: FormGroup;
  gridApi!: any;
  MachineIsAll = false;
  image: any;
  gridColumnApi: any;
  path!: string;
  savedPath!: string;
  Plants!: any[];
  GroupList!: any[];
  transitions: any = [];
  Groups!: any[];
  Lines!: any[];
  Uomms!: any[];
  hideButton = false;
  remarks!: boolean;
  Uom!: any;
  disabled = true;
  qualityArea!: any;
  ProcessOrder!: any[];
  OperationNumber: any[];
  filteredPO: any;
  FailureModeDetail!: any[];
  filteredfailureMode: any;
  selectedAccountable!: any[];
  Machines!: any[];
  NcrTypes!: any[];
  isUpdateNcr = false;
  customerlist!: any[];
  fileSave!: any;
  isEventMode = false;
  hideRequiredMarker = false;
  ncrCategories = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Process' },
    { id: 2, name: 'Product' },
    { id: 3, name: 'Material' },
    { id: 3, name: 'Quality' },
  ];
  firstReload = false;
  NcrDataSource = new MatTableDataSource<any>([]);
  NcrDataSourceList!: any[];
  orderProcessingVariableGroupColumns: string[] = [
    'Action',
    'ID',
    'failureMode',
    'puDesc',
    'operationName',
    'ncrAutoOrManual',
    'Desc',
    'ProductDesc',
    'Uom',
    'quantityWaste',
    'quantityHold',
    'imagePath',
    'capaNum',
    'authenticitystatus',
    'status',
    'rcaDue',
    'implementCapa',
    'remarks',
    'createdOn',
    'updatedOn',
  ];
  ncrDetailColumns: string[] = [
    'action',
    'description',
    'date',
    'owner',
    'tools',
  ];
  capaActionColumns: string[] = ['description', 'date', 'owner', 'isChecked'];
  NCRDataForm!: FormGroup;
  AddNCRDataForm!: FormGroup;
  currentImagePath!: any;
  raci!: any;
  params!: any;
  statusSelected = true;
  filterdraci: any;
  icons!: any;
  toolslist: any[] = [];
  paginationPageSize!: any;
  ButtonType!: any;
  formMode = true;
  qcRole!: any;
  IsWrite: any;
  ncrActionHistoryDataSource!: MatTableDataSource<any>;
  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },

    paginationPageSize: 10,
  };
  gridOptionsAction: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },

    paginationPageSize: 10,
  };
  ActionForm!: FormGroup;
  toolPath!: string;
  toolName!: string;
  ActionDetail!: boolean;
  newTG = true;
  ncrId!: number;
  actionId!: number;
  categoryTypeDDL: any[] = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
  ];
  authenticityStatusDDL: any[] = [
    { name: 'Pending' },
    { name: 'Approved' },
    { name: 'Reject' },
  ];
  statusDDL: any[] = [
    { name: 'InProgress' },
    { name: 'Delay' },
    { name: 'Closed' },
  ];
  remarksForm!: FormGroup;
  previewerColumns: string[] = ['Desc', 'Action', 'Remove'];
  previewerData!: MatTableDataSource<any>;
  Colorabc: any;
  TGstartDate: any;
  disabledColumns = false;
  Status: any;
  imageUrl!: string;
  lineOtherSelected!: boolean;
  gridApiAction: any;
  gridColumnApiAction: any;
  rowData1: any;
  IsAccess: any;
  listofLine: any = [];
  files: File[] = [];
  ncrIdDesc!: string;
  ncrWorkFlow!: any;
  instanceParams!: any;
  is5Why = false;
  isCauseEffect = false;
  isFishbone = false;
  is6w2h = false;
  ncr: any;
  paramNCRId: any;
  filteredNCR: any = [];
  showBoth: boolean = false;
  severity: string[] = ['Critical', 'Major', 'Minor', 'No'];
  ncrAutoOrManual: string;
  shiftListForManualEntry: any;
  crewListForManualEntry: any;
  public openMenu: boolean = false;
  isOver = false;
  reportHeaderForm: FormGroup;
  reportHeaderName: any;
  chfformMode: boolean = true;
  ConfigurationForm: FormGroup;
  nhf_Id: any;
  headerArrReport: any[] = [];
  FooterArrReport: any[] = [];
  rightHeaders: any[];
  bottomHeaders: any[];
  public rowData2!: any[];
  public columnDefs2!: any[];
  gridOptionsHeaderFooter: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
    },
  };
  frameworkComponents: any;
  public gridApi1: any;
  public gridColumnApi1: any;
  configTypes = [
    { Id: '1', name: 'Header' },
    { Id: '2', name: 'Footer' }
  ];
  public subHeaderColumnDefs!: any[];
  public w2HColumnDefs!: any[];
  public fishBoneColumnDefs!: any[];
  public causeEffectColumnDefs!: any[];
  public why5ColumnDefs!: any[];
  public actionPlanColumnDefs!: any[];
  public subHeaderDataSource!: any[];
  public w2HDataSource!: any[];
  public fishBoneDataSource!: any[];
  public causeEffectDataSource!: any[];
  public why5DataSource!: any[];
  public actionPlanDataSource!: any[];
  subHeaderGridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
    },
  };
  W2hGridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
    },
  };
  fishBoneGridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
    },
  };
  causeEffectGridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
    },
  };
  why5GridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
    },
  };
  actionPlanGridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
    },
  };
  rowSelection = 'multiple';
  public defaultColDef;
  public subHeaderGridApi: any;
  public w2HGridApi: any;
  public fishBoneGridApi: any;
  public causeEffectGridApi: any;
  public why5GridApi: any;
  public actionPlanGridApi: any;
  public w2HColumnApi: any;
  public fishboneColumnApi: any;
  public causeEffectColumnApi: any;
  public why5ColumnApi: any;
  public actionPlanColumnApi: any;
  public subHeaderColumnApi: any;
  foundSubHeader: any;
  foundNcrDetail: any;
  found6W2HColumns: any;
  foundFishboneColumns: any;
  foundCauseEffectColumns: any;
  found5WhyColumns: any;
  foundActionPlanColumns: any;
  foundRCADetail: { name: any; value: any; fieldName: any; order: any; }[];
  plantImage: any;
  isPageBreak = false;
  historicalMType: any = [];
  viewStageData: any;
  panelOpenState = false;
  teamList: any = [];
  userAllList: any = [];
  emailData: any = [];
  emailStatus: boolean = false;
  bulkInstanceKeysForDiscard: Array<string> = [];
  bulkNcrIdsForDelete: Array<string> = [];
  constructor(
    private overlay: Overlay,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private toastService: ToastrService,
    private commonService: CommonService,
    private ncrService: NcrService,
    private toaster: ToastrService,
    private analyticalToolsService: AnalyticalToolsService,
    private userManagementService: UserManagementService,
    private oeeConfigurationService: OeeConfigurationService,
    public router: Router,
    private platModelService: EnterPriseGroupService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private machineService: MachineMappingService,
    private flowService: WorkFlowService,
    private ccrService: CcrHistoryService,
    private areaService: AreaService,
    private kpiservice: KpitreeService,
    private workFlowService: WorkFlowService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private sfdService: ShopFloorDataService,
    private managerExecutionService: ManagerExecutionService,
  ) {
    this.frameworkComponents = {
      btnRenderer: BtnCellRendererForReports
    };
  }
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('displayRoleModal2') private displayRoleModal2!: TemplateRef<any>;
  @ViewChild('ncrRemarksModal') private ncrRemarksModal!: TemplateRef<any>;
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('configurationModal') private configurationModal!: TemplateRef<any>;
  @ViewChild('ncrHeaderModal') private ncrHeaderModal!: TemplateRef<any>;
  @ViewChild('HeadersModal') private HeadersModal!: TemplateRef<any>;

  ngOnInit(): void {
    this.columnDefs = [
      {
        field: 'action',
        sortable: true,
        filter: true,
        hide: this.IsWrite,
        headerName: 'Actions',
        cellRenderer: (params: any): any => {
          let actionBtns = `
            <div>
            <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          `;
          if(!params.data.instanceKey){
            actionBtns = actionBtns + `
              <button mat-mini-fab="" color="primary" ptooltip="Generate Instance Key" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #4b4b7c;"  ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-key" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
             `
          }
          if(params.data.ncrStatus === 'Pending'){
            actionBtns = actionBtns + `
              <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
             `
          }
          if(params.data.ncrStatus === 'Pending' && params.data.instanceKey){
            actionBtns = actionBtns + `
              <button mat-mini-fab="" color="primary" ptooltip="Edit" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-pencil" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
             `
          }
          if(params.data.ncrStatus === 'Approved'){
            actionBtns = actionBtns + `
              <button mat-mini-fab data-toggle="tooltip" data-placement="top" title="NCR Report" tooltipposition="top" class="ncrReport mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #CC1100;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="ncrReport fa fa-print" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>    
              <button mat-mini-fab="" color="primary" ptooltip="Immediate Action" tooltipposition="top" class="immediate-action mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2B4865;" ng-reflect-color="primary" ng-reflect-text="Immediate Action" ng-reflect-tooltip-position="top"><span class="immediate-action mat-button-wrapper"><i class="fa fa-industry immediate-action"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            `
          }
          if(this.severity.includes(params.data.rcaDue) && params.data.ncrStatus === 'Approved' && params.data.implementcapa === 'Yes'){
            actionBtns = actionBtns + `
              <button mat-mini-fab="" color="primary" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #20b2aa;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            `
          }
          if(this.severity.includes(params.data.rcaDue) && params.data.ncrStatus === 'Approved'){
            actionBtns = actionBtns + `
              <button mat-mini-fab="" color="primary" ptooltip="Action Detail" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #824AED;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-credit-card" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            `
          }
          if(params.data.Status == 'Closed'){
            actionBtns = actionBtns + `
              <button mat-mini-fab="" color="primary" ptooltip="Reopen" tooltipposition="top" class="reopen-ncr mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2C3639;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-solid fa-rotate-right"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
              </div>
            `
          }
          // if (
          //   this.severity.includes(params.data.rcaDue) &&
          //   params.data.ncrStatus === 'Approved' &&
          //   params.data.implementcapa === 'Yes'
          // ) {
          //   let actionBtns;
          //   params.data.Status !== 'Closed' &&
          //     params.data.ncrStatus === 'Approved'
          //     ? (actionBtns = `
          //   <div>
          //   <button mat-mini-fab data-toggle="tooltip" data-placement="top" title="NCR Report" tooltipposition="top" class="ncrReport mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #CC1100;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="ncrReport fa fa-print" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Action Detail" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #824AED;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-credit-card" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #20b2aa;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Immediate Action" tooltipposition="top" class="immediate-action mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2B4865;" ng-reflect-color="primary" ng-reflect-text="Immediate Action" ng-reflect-tooltip-position="top"><span class="immediate-action mat-button-wrapper"><i class="fa fa-industry immediate-action"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   </div>`)
          //     : (actionBtns = `<div>
          //   <button mat-mini-fab data-toggle="tooltip" data-placement="top" title="NCR Report" tooltipposition="top" class="ncrReport mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #CC1100;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="ncrReport fa fa-print" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Action Detail" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #824AED;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-credit-card" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #20b2aa;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Reopen" tooltipposition="top" class="reopen-ncr mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2C3639;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-solid fa-rotate-right"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Immediate Action" tooltipposition="top" class="immediate-action mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2B4865;" ng-reflect-color="primary" ng-reflect-text="Immediate Action" ng-reflect-tooltip-position="top"><span class="immediate-action mat-button-wrapper"><i class="fa fa-industry immediate-action"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   </div>`);

          //   return actionBtns;
          // }
          // if (
          //   params.data.rcaDue === 'Yes' &&
          //   params.data.ncrStatus === 'Approved'
          // ) {
          //   let actionBtns;
          //   params.data.Status !== 'Closed' &&
          //     params.data.ncrStatus === 'Approved'
          //     ? (actionBtns = `
          //   <button mat-mini-fab data-toggle="tooltip" data-placement="top" title="NCR Report" tooltipposition="top" class="ncrReport mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #CC1100;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="ncrReport fa fa-print" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          
          //   <button mat-mini-fab="" color="primary" ptooltip="Action Detail" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #824AED;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-credit-card" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Immediate Action" tooltipposition="top" class="immediate-action mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2B4865;" ng-reflect-color="primary" ng-reflect-text="Immediate Action" ng-reflect-tooltip-position="top"><span class="immediate-action mat-button-wrapper"><i class="fa fa-industry immediate-action"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   `)
          //     : (actionBtns = `
          //   <button mat-mini-fab data-toggle="tooltip" data-placement="top" title="NCR Report" tooltipposition="top" class="ncrReport mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #CC1100;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="ncrReport fa fa-print" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          
          //   <button mat-mini-fab="" color="primary" ptooltip="Action Detail" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #824AED;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-credit-card" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>        
          //   <button mat-mini-fab="" color="primary" ptooltip="Immediate Action" tooltipposition="top" class="immediate-action mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2B4865;" ng-reflect-color="primary" ng-reflect-text="Immediate Action" ng-reflect-tooltip-position="top"><span class="immediate-action mat-button-wrapper"><i class="fa fa-industry immediate-action"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>

          //   `);
          //   return actionBtns;
          // }
          // if (
          //   (params.data.instanceKey === null ||
          //     params.data.instanceKey === '') &&
          //   params.data.ncrStatus === 'Pending'
          // ) {
          //   let actionBtns;
          //   params.data.Status !== 'Closed' &&
          //     params.data.ncrStatus === 'Approved'
          //     ? (actionBtns = `
          //   <div>
          //   <button mat-mini-fab data-toggle="tooltip" data-placement="top" title="NCR Report" tooltipposition="top" class="ncrReport mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #CC1100;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="ncrReport fa fa-print" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #20b2aa;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Edit" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-pencil" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Generate Instance Key" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #4b4b7c;"  ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-key" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Immediate Action" tooltipposition="top" class="immediate-action mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2B4865;" ng-reflect-color="primary" ng-reflect-text="Immediate Action" ng-reflect-tooltip-position="top"><span class="immediate-action mat-button-wrapper"><i class="fa fa-industry immediate-action"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   </div>`)
          //     : (actionBtns = ` <div>
          //   <button mat-mini-fab data-toggle="tooltip" data-placement="top" title="NCR Report" tooltipposition="top" class="ncrReport mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #CC1100;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="ncrReport fa fa-print" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #20b2aa;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Edit" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-pencil" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Generate Instance Key" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #4b4b7c;"  ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-key" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Immediate Action" tooltipposition="top" class="immediate-action mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2B4865;" ng-reflect-color="primary" ng-reflect-text="Immediate Action" ng-reflect-tooltip-position="top"><span class="immediate-action mat-button-wrapper"><i class="fa fa-industry immediate-action"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>

          //   </div>`);
          //   return actionBtns;
          // }
          // if (params.data.ncrStatus === 'Pending') {
          //   let actionBtns;
          //   params.data.Status !== 'Closed' &&
          //     params.data.ncrStatus === 'Approved'
          //     ? (actionBtns = ` <div>
          //   <button mat-mini-fab data-toggle="tooltip" data-placement="top" title="NCR Report" tooltipposition="top" class="ncrReport mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #CC1100;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="ncrReport fa fa-print" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #20b2aa;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Edit" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-pencil" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Immediate Action" tooltipposition="top" class="immediate-action mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2B4865;" ng-reflect-color="primary" ng-reflect-text="Immediate Action" ng-reflect-tooltip-position="top"><span class="immediate-action mat-button-wrapper"><i class="fa fa-industry immediate-action"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   </div>`)
          //     : (actionBtns = `<div>
          //   <button mat-mini-fab data-toggle="tooltip" data-placement="top" title="NCR Report" tooltipposition="top" class="ncrReport mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #CC1100;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="ncrReport fa fa-print" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #20b2aa;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Edit" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-pencil" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Immediate Action" tooltipposition="top" class="immediate-action mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2B4865;" ng-reflect-color="primary" ng-reflect-text="Immediate Action" ng-reflect-tooltip-position="top"><span class="immediate-action mat-button-wrapper"><i class="fa fa-industry immediate-action"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   </div>`);
          //   return actionBtns;
          // } else {
          //   let actionBtns;
          //   params.data.Status !== 'Closed' &&
          //     params.data.ncrStatus === 'Approved'
          //     ? (actionBtns = `
          //   <button mat-mini-fab data-toggle="tooltip" data-placement="top" title="NCR Report" tooltipposition="top" class="ncrReport mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #CC1100;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="ncrReport fa fa-print" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>  
          //   <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #20b2aa;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          //   <button mat-mini-fab="" color="primary" ptooltip="Immediate Action" tooltipposition="top" class="immediate-action mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2B4865;" ng-reflect-color="primary" ng-reflect-text="Immediate Action" ng-reflect-tooltip-position="top"><span class="immediate-action mat-button-wrapper"><i class="fa fa-industry immediate-action"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          // `)
          //     : (actionBtns = `
          // <button mat-mini-fab data-toggle="tooltip" data-placement="top" title="NCR Report" tooltipposition="top" class="ncrReport mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #CC1100;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="ncrReport fa fa-print" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>    
          // <button mat-mini-fab="" color="warn" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #7a1dcf;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-file" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          // <button mat-mini-fab="" color="primary" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #20b2aa;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          // <button mat-mini-fab="" color="primary" ptooltip="Reopen" tooltipposition="top" class="reopen-ncr mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2C3639;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-solid fa-rotate-right"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          
          // <button mat-mini-fab="" color="primary" ptooltip="Immediate Action" tooltipposition="top" class="immediate-action mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #2B4865;" ng-reflect-color="primary" ng-reflect-text="Immediate Action" ng-reflect-tooltip-position="top"><span class="immediate-action mat-button-wrapper"><i class="fa fa-industry immediate-action"  style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          // `);
          //   return actionBtns;
          // }
          return actionBtns;
        },
        checkboxSelection: (params: any) => params.data.ncrStatus === 'Pending' ? true : false,
        // minWidth: 200,
      },
      {
        field: 'ncrIdDesc',
        sortable: true,
        filter: true,
        headerName: 'NCR ID',
        
      },
      {
        field: 'quatlityDesc',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        width: 500,
        headerName: 'Problem Desc',
        cellRenderer: (params: any) => {
          if (
            params.data.ncrTypeName === 'ALERT' &&
            params.data.ncrAutoOrManual === 'Auto'
          ) {
            return `<div>
                <span style="color: #efbc03; font-weight:bold;">Watch Out!</span>
                ${params.value}
              </div>`;
          } else if (
            params.data.ncrTypeName === 'NCR' &&
            params.data.ncrAutoOrManual === 'Auto'
          ) {
            return `<div>
            <span style="color: #ff2121; font-weight:bold;">Out of Specification Limits!</span>
              ${params.value}
            </div>`;
          }
          return params.value;
        },
        
      },
      {
        field: 'IncidentDate',
        sortable: true,
        filter: true,
        headerName: 'Incident Date',
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY')
            : '';
        },
      },
      {
        field: 'lineDesc',
        sortable: true,
        filter: true,
        headerName: 'Line',
        
      },

      {
        field: 'puDesc',
        sortable: true,
        filter: true,
        headerName: 'Machine',
        
      },
      {
        field: 'shift',
        sortable: true,
        filter: true,
        headerName: 'Shift',
        
      },
      {
        field: 'crew',
        sortable: true,
        filter: true,
        headerName: 'Crew',
        
      },
      {
        field: 'IncidentCause',
        sortable: true,
        filter: true,
        headerName: 'Cause of Incident',
        
      },
      {
        field: 'processOrderNo',
        sortable: true,
        filter: true,
        headerName: 'Incident Desc',
        
      },
      {
        field: 'itemDesc',
        sortable: true,
        filter: true,
        headerName: 'Cause of Incident Desc',
        
      },
      {
        field: 'productDesc',
        sortable: true,
        filter: true,
        headerName: 'Corrective Action Taken',
        
      },
      {
        field: 'operationName',
        sortable: true,
        filter: true,
        headerName: 'Incurred Losses',
        
      },
      {
        field: 'prcocessType',
        sortable: true,
        filter: true,
        headerName: 'NCR Category',
        
      },
      {
        field: 'areaName',
        sortable: true,
        filter: true,
        headerName: 'Area Name',
        
      },
      {
        field: 'ncrTypeName',
        hide: true,
        sortable: true,
        filter: true,
        headerName: 'NCR Type',
        
      },
      {
        field: 'ncrAutoOrManual',
        hide: true,
        sortable: true,
        filter: true,
        headerName: 'Auto or Manual',
        
      },
      {
        field: 'ncrStatus',
        width: 156,
        sortable: true,
        filter: true,
        headerName: 'NCR Validation',

        editable: (params: any) => {return (params.data.ncrStatus == 'Pending' && params.data.instanceKey != null)},
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Pending', 'Approved', 'Reject'],
        },
        cellStyle: {
          color: 'black',
          'background-color': this.qcRole === true ? '#EDF1E5' : '',
        },
        
      },
      {
        field: 'Status',
        width: 100,
        sortable: true,
        filter: true,
        headerName: 'Status',
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['In Progress', 'Closed', 'Delay'],
        },
        cellStyle: (params: { value: string }) => {
          if (params.value === 'Closed') {
            return {
              color: 'black',
              backgroundColor: '#38cf36',
              'font-weight': 'bold',
            };
          } else if (params.value === 'InProgress') {
            return {
              color: 'black',
              backgroundColor: 'yellow',
              'font-weight': 'bold',
            };
          } else if (params.value === 'Delay') {
            return {
              color: 'black',
              backgroundColor: '#eb3d3d',
              'font-weight': 'bold',
            };
          } else {
            return {
              color: 'black',
              backgroundColor: '#11ced1',
              'font-weight': 'bold',
            };
          }
        },
        
      },
      {
        field: 'rcaDue',
        headerName: 'RCA Due Status',
        sortable: true,
        width: 104,
        filter: true,
        resizable: true,
        wrapText: true,
        cellStyle: function (params: any) {
          if (params.data.Status == 'InProgress') {
            return {
              color: 'blue',
              'text-decoration': 'underline',
              cursor: 'pointer',
            };
          }

          if (params.data.Status == 'Closed') {
            return {
              color: 'Black',
            };
          }
          return {
            color: 'Black',
          };
        },
        editable: (params: any) =>
          (params.data.rcaDue == '' || params.data.rcaDue == null)  && params.data.Status !== 'Closed'
            ? true
            : false,
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Critical', 'Major', 'Minor', 'No'],
        },
        
      },
      {
        field: 'rcaDue',
        headerName: 'Implement RCA',
        sortable: true,
        width: 104,
        filter: true,
        resizable: true,
        wrapText: true,
        cellStyle: function (params: any) {
          if (params.data.Status == 'InProgress') {
            return {
              color: 'blue',
              'text-decoration': 'underline',
              cursor: 'pointer',
            };
          }

          if (params.data.Status == 'Closed') {
            return {
              color: 'Black',
            };
          }
          return {
            color: 'Black',
          };
        }, 
      },
      {
        field: 'implementcapa',
        sortable: true,
        filter: true,
        headerName: 'Implement Capa',
        width: 148,
        cellStyle: {
          color: 'black',
          'background-color': this.qcRole === true ? '#EDF1E5' : '',
        },

        resizable: true,
        wrapText: true,
        editable: (params: any) => params.data.Status !== 'Closed',
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Yes', 'No'],
        },
        
      },
      { field: 'remarks', sortable: true, filter: true, headerName: 'Remarks',  },
      {
        field: 'StartTime',
        sortable: true,
        filter: true,
        headerName: 'Start Time',
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm')
            : '';
        },
      },
      {
        field: 'EndTime',
        sortable: true,
        filter: true,
        headerName: 'End Time',
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm')
            : '';
        },
      },
      {
        field: 'CreatedBy',
        sortable: true,
        filter: true,
        headerName: 'Created By',
        
      },
      {
        field: 'createdOn',
        sortable: true,
        filter: true,
        headerName: 'Created On',
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },
      },
      {
        field: 'updatedOn',
        sortable: true,
        filter: true,
        hide: true,
        headerName: 'Updated On',
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },
      },
      {
        field: 'ParameterType',
        sortable: true,
        filter: true,
        headerName: 'Parameter Type',
        
        hide: true,
      },
      {
        field: 'GroupType',
        sortable: true,
        filter: true,
        headerName: 'Group Type',
        hide: true,
        
      },
      {
        field: 'TestId',
        sortable: true,
        filter: true,
        hide: true,
        headerName: 'Test Id',
        
      },
      {
        field: 'MaterialId',
        sortable: true,
        filter: true,
        hide: true,
        headerName: 'Chemical Lab Material Id',
        
      },
      {
        field: 'QAReportId',
        sortable: true,
        filter: true,
        hide: true,
        headerName: 'Physical Lab QAReport Id',
        
      },
      {
        field: 'ThicknessTSVReportId',
        sortable: true,
        filter: true,
        hide: true,
        headerName: 'Physical Lab ThicknessTSVReport Id',
        
      },
      {
        field: 'CompletionDate',
        sortable: true,
        filter: true,
        headerName: 'Completion Date',
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },        
      },
      {
        field: 'ncrcost',
        headerName: 'NCR Cost',
        editable: (params) => params.data.Status != 'Closed',
        cellStyle: {
          'background-color': '#EDF1E5',
        },
        
      },
    ];
    this.columnDefs1 = [
      {
        field: 'ParameterName',
        headerName: 'Parameter Name',
        filter: true,
        resizable: true,
        wrapText: true,
        
      },
      {
        field: 'Result',
        headerName: 'Result',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 120,
        
      },
      {
        field: 'LRL',
        headerName: 'LRL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 120,
        
      },
      {
        field: 'LSL',
        headerName: 'LSL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 120,
        
      },
      {
        field: 'LWL',
        headerName: 'LWL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 120,
        
      },
      {
        field: 'TGT',
        headerName: 'TGT',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 120,
        
      },
      {
        field: 'UWL',
        headerName: 'UWL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 120,
        
      },
      {
        field: 'USL',
        headerName: 'USL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 120,
        
      },
      {
        field: 'URL',
        headerName: 'URL',
        filter: true,
        resizable: true,
        wrapText: true,
        width: 120,
        
      },
    ];
    this.columnDefs2 = [
      {
        headerName: 'Action',
        cellRenderer: 'btnRenderer',
        field: 'action',
        minWidth: 100,
        cellRendererParams: {
        },
      },
      {
        headerName: 'Order',
        minWidth: 200,
        field: 'order',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Name',
        minWidth: 200,
        field: 'name',
        sortable: true,
        filter: true
      },
      {
        headerName: 'Value',
        minWidth: 160,
        field: 'value',
        sortable: true,
        filter: true
      },
      {
        headerName: 'Type',
        minWidth: 200,
        field: 'type',
        sortable: true,
        filter: true,
        cellRenderer: (params: any) => {
          if (params.data.type === '1') {
            return `
            Header`;
          } else {
            return `
           Footer
          `;
          }
        },
      }
    ];
    this.subHeaderColumnDefs = [
      {
        headerName: 'Action',
        minWidth: 200,
        field: 'action',
        sortable: true,
        filter: true,
        cellRenderer: (params: any) => {
          params.api.forEachNode((node) =>
            node.setSelected(!!node.data && node.data.checked === true)
          );
        },
      },
      {
        headerName: 'Name',
        minWidth: 200,
        field: 'name',
        sortable: true,
        filter: true
      },
      {
        field: 'order',
        headerName: 'Order',
        sortable: true,
        minWidth: 200,
        singleClickEdit: true,
        editable: (params: any) => {
          if (params.data.checked === true) { return true }
          else { return false }
        },
        cellStyle: {
          'background-color': '#EDF1E5',
        },
      },
      {
        headerName: 'Header Id',
        minWidth: 200,
        field: 'h_Id',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Category',
        minWidth: 200,
        field: 'category',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Plant Id',
        minWidth: 200,
        field: 'pt_Id',
        sortable: true,
        filter: true,
        hide: true
      },
    ];
    this.w2HColumnDefs = [
      {
        headerName: 'Action',
        minWidth: 200,
        field: 'action',
        sortable: true,
        filter: true,
        cellRenderer: (params: any) => {
          params.api.forEachNode((node) =>
            node.setSelected(!!node.data && node.data.checked === true)
          );
        },
      },
      {
        headerName: 'Name',
        minWidth: 200,
        field: 'name',
        sortable: true,
        filter: true
      },
      {
        field: 'order',
        headerName: 'Order',
        minWidth: 200,
        singleClickEdit: true,
        editable: (params: any) => {
          if (params.data.checked === true) { return true }
          else { return false }
        },
        cellStyle: {
          'background-color': '#EDF1E5',
        },
      },
      {
        headerName: 'Header Id',
        minWidth: 200,
        field: 'h_Id',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Category',
        minWidth: 200,
        field: 'category',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Plant Id',
        minWidth: 200,
        field: 'pt_Id',
        sortable: true,
        filter: true,
        hide: true
      },
    ];
    this.fishBoneColumnDefs = [
      {
        headerName: 'Action',
        minWidth: 200,
        field: 'action',
        sortable: true,
        filter: true,
        cellRenderer: (params: any) => {
          params.api.forEachNode((node) =>
            node.setSelected(!!node.data && node.data.checked === true)
          );
        },
      },
      {
        headerName: 'Name',
        minWidth: 200,
        field: 'name',
        sortable: true,
        filter: true
      },
      {
        field: 'order',
        headerName: 'Order',
        minWidth: 200,
        singleClickEdit: true,
        editable: (params: any) => {
          if (params.data.checked === true) { return true }
          else { return false }
        },
        cellStyle: {
          'background-color': '#EDF1E5',
        },
      },
      {
        headerName: 'Header Id',
        minWidth: 200,
        field: 'h_Id',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Category',
        minWidth: 200,
        field: 'category',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Plant Id',
        minWidth: 200,
        field: 'pt_Id',
        sortable: true,
        filter: true,
        hide: true
      },
    ];
    this.causeEffectColumnDefs = [
      {
        headerName: 'Action',
        minWidth: 200,
        field: 'action',
        sortable: true,
        filter: true,
        cellRenderer: (params: any) => {
          params.api.forEachNode((node) =>
            node.setSelected(!!node.data && node.data.checked === true)
          );
        },
      },
      {
        headerName: 'Name',
        minWidth: 200,
        field: 'name',
        sortable: true,
        filter: true
      },
      {
        field: 'order',
        headerName: 'Order',
        minWidth: 200,
        singleClickEdit: true,
        editable: (params: any) => {
          if (params.data.checked === true) { return true }
          else { return false }
        },
        cellStyle: {
          'background-color': '#EDF1E5',
        },
      },
      {
        headerName: 'Header Id',
        minWidth: 200,
        field: 'h_Id',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Category',
        minWidth: 200,
        field: 'category',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Plant Id',
        minWidth: 200,
        field: 'pt_Id',
        sortable: true,
        filter: true,
        hide: true
      },
    ];
    this.why5ColumnDefs = [
      {
        headerName: 'Action',
        minWidth: 200,
        field: 'action',
        sortable: true,
        filter: true,
        cellRenderer: (params: any) => {
          params.api.forEachNode((node) =>
            node.setSelected(!!node.data && node.data.checked === true)
          );
        },
      },
      {
        headerName: 'Name',
        minWidth: 200,
        field: 'name',
        sortable: true,
        filter: true
      },
      {
        field: 'order',
        headerName: 'Order',
        minWidth: 200,
        singleClickEdit: true,
        editable: (params: any) => {
          if (params.data.checked === true) { return true }
          else { return false }
        },
        cellStyle: {
          'background-color': '#EDF1E5',
        },
      },
      {
        headerName: 'Header Id',
        minWidth: 200,
        field: 'h_Id',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Category',
        minWidth: 200,
        field: 'category',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Plant Id',
        minWidth: 200,
        field: 'pt_Id',
        sortable: true,
        filter: true,
        hide: true
      },
    ];
    this.actionPlanColumnDefs = [
      {
        headerName: 'Action',
        minWidth: 200,
        field: 'action',
        sortable: true,
        filter: true,
        cellRenderer: (params: any) => {
          params.api.forEachNode((node) =>
            node.setSelected(!!node.data && node.data.checked === true)
          );
        },
      },
      {
        headerName: 'Name',
        minWidth: 200,
        field: 'name',
        sortable: true,
        filter: true
      },
      {
        field: 'order',
        headerName: 'Order',
        minWidth: 200,
        singleClickEdit: true,
        editable: (params: any) => {
          if (params.data.checked === true) { return true }
          else { return false }
        },
        cellStyle: {
          'background-color': '#EDF1E5',
        },
      },
      {
        headerName: 'Header Id',
        minWidth: 200,
        field: 'h_Id',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Category',
        minWidth: 200,
        field: 'category',
        sortable: true,
        filter: true,
        hide: true
      },
      {
        headerName: 'Plant Id',
        minWidth: 200,
        field: 'pt_Id',
        sortable: true,
        filter: true,
        hide: true
      },
    ];
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
      headerCheckboxSelection: (params: any) => this.isFirstColumn(params),
      checkboxSelection: (params: any) => this.isFirstColumn(params),
    };
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getIDFromRoute();
    this.filesForm = this.fb.group({
      file: [],
    });
    this.createForms();
    this.imageUrl = environment.O3CoreApiImageUrl;
    const array = JSON.parse(localStorage.roleName).map((v: any) =>
      v.toLowerCase()
    );
    this.qcRole = array.includes('qc leader') === true ? true : false;
    const d = new Date();
    d.setDate(d.getDate() - 7); // setMonth(d.getMonth() - 3);
    this.NCRDataForm.get('start')?.setValue(d);
    this.NCRDataForm.get('end')?.setValue(new Date());
    this.getNcrtype();
    this.getGroup();
    this.getAllUserList();
  }

  createForms() {
    this.NCRDataForm = this.fb.group({
      plant: ['', Validators.required],
      groupList: ['', Validators.required],
      line: ['', Validators.required],
      machine: [],
      ncrcategory: [''],
      start: [''],
      end: [''],
    });
    this.AddNCRDataForm = this.fb.group({
      order: [''],
      itemDesc: ['',Validators.required],
      desc: ['',Validators.required],
      uom: [''],
      operation: [''],
      process: ['', Validators.required],
      qdesc: ['', Validators.required],
      qwaste: [0],
      hquality: [0],
      fmode: [''],
      type: ['', Validators.required],
      qpulse: [''],
      image: [''],
      ncrId: [''],
      area: [0],
      prodId: [''],

      ncrAutoOrManual: [''],
      processOrder: ['',Validators.required],
      customerName: [''],
      processRelated: [''],
      machineRelated: [''],
      completionDate: new Date(),
      manualUom: [''],
      manualOperationName: ['',Validators.required],
      failureModeDetail: [''],
      salesOrderNo: [''],
      supplierName: [''],
      supplierId: [''],
      incidentDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'),Validators.required],
      shift: ['',Validators.required],
      crew: ['',Validators.required],
      startDateTime: [this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm'),Validators.required],
      endDateTime: [this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm'),Validators.required],
      causeOfIncident: ['',Validators.required],
    });

    this.ActionForm = this.fb.group({
      desc: ['', Validators.required],
      date: ['', Validators.required],
      toolname: ['', Validators.required],
      owner: ['', Validators.required],
      actionId: [''],
    });

    this.remarksForm = this.fb.group({
      remarks: ['', Validators.required],
      status: ['', Validators.required],
      rcaDue: ['', Validators.required],
      implementCapa: ['', Validators.required],
    });

    this.reportHeaderForm = this.fb.group({
      name: ['', [Validators.required]],
    });

    this.ConfigurationForm = this.fb.group({
      name: ['', [Validators.required]],
      value: [''],
      type: ['', Validators.required],
      order: ['', Validators.required],
    });
  }
  getIDFromRoute() {
    this.route.params.subscribe((params) => {
      this.paramNCRId = params.ncrID;
    });
  }
  imageZoomModal(params: any): void {
    const col = params.colDef.field;
    if (col === 'imagePath') {
      this.image = params.data.imagePath;
      this.openFileModal();
    }
  }
  openFileModal(): void {
    this.currentImagePath =
      this.image === null
        ? 'assets/images/no-image.png'
        : this.imageUrl + this.image;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }
  getFailureMode(): void {
    this.FailureModeDetail = [];
    this.filteredfailureMode = [];
    if (this.NCRDataForm.value.machine == -1) {
      var machineArr = [];

      this.Machines.forEach((element) => {
        machineArr.push(element.PU_Id);
      });
      var a = machineArr.toString();
      const key = {
        unitId: a,
      };
      this.ncrService.getFailureModeDetails(key).subscribe(
        (data: any) => {
          this.FailureModeDetail = data;
          this.filteredfailureMode = this.FailureModeDetail.slice();
        },
        (error: any) => {
          this.toastrService.error('Error', error.message);
        }
      );
    } else {
      const key = {
        unitId: this.NCRDataForm.value.machine,
      };
      this.ncrService.getFailureModeDetails(key).subscribe(
        (data: any) => {
          this.FailureModeDetail = data;
          this.filteredfailureMode = this.FailureModeDetail.slice();
        },
        (error: any) => {
          this.toastrService.error('Error', error.message);
        }
      );
    }
  }
  dateFormatter(params: any): any {
    return moment(params.value).format('MM/DD/YYYY hh:mm');
  }
  getGroup(): void {
    const key = {
      Id: 0,
    };
    this.oeeConfigurationService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.masterList;
        this.NCRDataForm.get('groupList')?.setValue(this.GroupList[0].TypeId);
        this.getPlants();
      },
      (error: any) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReadyAction(params: any): any {
    this.gridApiAction = params.api;
    this.gridColumnApiAction = params.columnApi;
  }
  getContextMenuItems = (params: any) => {
    if (params.node.data.ncrAutoOrManual === 'Auto' && params.node.data.prcocessType !== 'Quality') {
      this.rowData1 = [];
      const result = [
        {
          name: 'Specification ' + params.node.data.ncrIdDesc,
          action: () => {
            this.getNCRSpecifcation(params);
          },
        },
      ];
      return result;
    } else {
      return [];
    }
  };
  getNCRSpecifcation(params: any): void {
    this.dialog.open(this.displayRoleModal2, {
      height: '80%',
      width: '90%',
      disableClose: true,
    });
    const key = {
      parameterType: params.node.data.ParameterType,
      groupType: params.node.data.GroupType,
      testId: params.node.data.TestId,
    };
    this.ncrService.getncrspecification(key).subscribe(
      (res: any) => {
        this.rowData1 = res;

        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getAllNCR2(): void {
    this.ncrService.getNcr().subscribe(
      (res: any) => {
        this.dataArray = res.Result;

        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlants(): void {
    const key = {
      GroupId: this.NCRDataForm.value.groupList,
    };

    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.Plants = data.DepartList;

        this.NCRDataForm.get('plant')?.setValue(this.Plants[0].DeptId);
        this.getLines(this.Plants[0].DeptId);
        this.getQualityArea(this.Plants[0].DeptId);
        this.getNCRWorkFlow();
        this.getDefectByPlant();
        this.getTeamList();
        this.getNcrReportName();
      },
      (error: any) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }
  getWorkFlowTransitions() {
    this.workFlowService
      .getWorkflowStatuses(this.ncrWorkFlow.workFlowKey)
      .subscribe(
        (response: any) => {
          this.transitions = response;
        },
        (err: any) => {
        }
      );
  }
  getDefectByPlant(): void {
    this.defect = [];
    this.filteredDefect = [];
    const key = {
      plantId: this.NCRDataForm.get('plant').value,
    };

    this.ncrService.getDefectbyPlant(key).subscribe((data: any) => {
      this.defect = data;
      this.filteredDefect = this.defect.slice();
    });
  }
  getCustomer(): void {
    this.customerlist = [];
    this.filteredCustomer = [];
    const key = {
      plantId: this.NCRDataForm.get('plant').value,
    };
    this.ncrService.getCustomer(key).subscribe((data: any) => {
      this.customerlist = data;
      this.filteredCustomer = this.customerlist.slice();
    });
  }
  getLines(id: any): void {
    const key = {
      PT_ID: id,
    };
    this.commonService.getLineList(key).subscribe(
      (res: any) => {
        this.Lines = res.lstLine;
        this.listofLine = res.lstLine;
        if (res.lstLine.length > 0) {
          this.NCRDataForm.get('line')?.setValue(-1);
          let lineAll = [];
          this.listofLine.forEach((element) => {
          lineAll.push(element.PL_Id);
          });
          let lnl = lineAll.toString();
          this.getMachines(lnl);
        } else {
          this.NCRDataForm.get('line')?.setValue('');
          this.NCRDataForm.get('machine')?.setValue('');
        }
      },
      (error) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }
  getMachines(id: any): void {
    if (id === 0) {
      this.lineOtherSelected = true;
      this.Machinetype = true;
      this.NCRDataForm.controls.machine.setValue(0);
      this.onSearchClick();
    } else {
      this.lineOtherSelected = false;
      const key = {
        PL_ID: id,
      };
      this.commonService.getUnitList(key).subscribe(
        (res: any) => {
          this.Machines = res.lstUnit;
          if (res.lstUnit.length > 0) {
            this.NCRDataForm.get('machine')?.setValue(-1);
            this.MachineIsAll = true;
            this.onSearchClick();
          } else {
            this.NCRDataForm.get('machine')?.setValue('');
          }
        },
        (error) => {
          this.toastrService.error('Error', error.message);
        }
      );
    }
  }

  getNcrtype(): void {
    this.ncrService.getNcrType().subscribe(
      (res: any) => {
        this.NcrTypes = res;
        this.AddNCRDataForm.get('type')?.setValue(this.NcrTypes[0].ncrTypeId);
      },
      (error: any) => {
        this.toastrService.error('Error', error.error.message);
      }
    );
  }

  getUnitOfMeasurement(): void {
    this.ncrService.getUnitOfMeasurement().subscribe(
      (res) => {
        this.Uomms = res;
        if (!this.isUpdateNcr) {
          this.AddNCRDataForm.get('uom')?.setValue(this.Uomms[0]?.uomId);
        }
      },

      (error) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }

  date(value: any): any {
    if (value) {
      return moment(value).format('DD/MM/YYYY HH:mm:ss');
    } else {
      return;
    }
  }

  ncractionexecutionUpdate(): void {
    this.spinner.show();
    const data = this.ncrActionHistoryDataSource.filteredData;
    if (data.length > 0) {
      const isAllChecked = data.filter((element) => !element.isChecked).length;
      if (isAllChecked > 0) {
        this.ncrService.ncractionexecutionUpdate(data).subscribe(
          (response: any) => {
            this.spinner.hide();

            this.toastrService.success('Success', 'Updated Successfully');

            this.dialog.closeAll();
            this.onSearchClick();
          },
          (error: any) => {
            this.spinner.hide();

            this.toastrService.error('Error', error.error.message);
          }
        );
      } else {
        this.instanceParams.tableId = this.ncrId;
        this.instanceParams.tenantId = this.NCRDataForm.value.plant;
        this.instanceParams.payload = {
          ...this.ncr,
          plantId: this.NCRDataForm.get('plant')?.value, groupDesc: this.GroupList.find((x) => x.TypeId == this.NCRDataForm.get('groupList')?.value)?.TypeName,
          machineDesc: this.Machines.find((x) => x.PU_Id == this.NCRDataForm.get('machine')?.value)?.PU_Desc, lineDesc: this.Lines.find((x) => x.PL_Id == this.NCRDataForm.get('line')?.value)?.PL_Desc,
          plantDesc: this.Plants.find((x) => x.DeptId == this.NCRDataForm.get('plant')?.value)?.DeptDesc,

          workFlowKey: this.ncrWorkFlow ? this.ncrWorkFlow.workFlowKey : ''
        }
        this.flowService.setSingleInstance(this.instanceParams).subscribe(
          (res) => {
            this.spinner.hide();
            data[0].emailData = JSON.stringify(this.emailData);
            data[0].EmailStatus = this.emailStatus;
            this.ncrService.ncractionexecutionUpdate(data).subscribe(
            (response: any) => {
              this.toastrService.success('Success','Updated Successfully');
              this.dialog.closeAll();
              this.onSearchClick();
            },
            (error: any) => {
              this.toastrService.error('Error', error.error.message);
            }
           );
          },
          ({ error }) => {
            this.spinner.hide();
            this.toaster.error('Error', error?.message);
            this.onSearchClick();
          }
        );
      }
    }
  }

  OnChangeDate(event: any, data: any): any {
    const actionId = parseInt(data.actionId, 10);
    const d = new Date(data.actionDate);
    const implementCapaDate = event.target.value; // .format('YYYY-MMM-DD')
    const m = this.ncrActionHistoryDataSource.filteredData.find(
      (x) => x.actionId === actionId
    );
    m.implementCapaDate = implementCapaDate;
  }

  OnChangeTargetDate(event: any, data: any): any {
    const t = new Date();
    const newD = moment(t).format('YYYY-MM-DD');
    const targetDate = event.target.value;
    const tDate = moment(targetDate).format('YYYY-MM-DD');
    if (tDate >= newD) {
      const Key = {
        targetDate: event.target.value,
        ncrId: data.ncrId,
        reqType: 2,
      };
      this.ncrService.changeNcrRecord1(Key).subscribe(
        (): any => {
          this.toastrService.success('Target Date Successfuly added');
          this.onSearchClick();
        },
        (error) => {
          this.toastrService.error('Error', error.message);
        }
      );
    } else {
      this.toastrService.warning('Target should not be passed date');
      data.TargetDate = '';
    }
  }

  OnChangeNewTargetDate(event: any, data: any): any {
    const TD = moment(data.TargetDate).format('YYYY-MM-DD');
    const NTD = moment(event.target.value).format('YYYY-MM-DD');

    if (TD < NTD) {
      const key = {
        newTargetDate: event.target.value,
        ncrId: data.ncrId,
        reqType: 1,
      };
      this.ncrService.changeNcrRecord1(key).subscribe(
        (): any => {
          this.toastrService.success('New Target Date Successfuly added');
          this.onSearchClick();
        },
        (error) => {
          this.toastrService.error('Error', error.message);
        }
      );
    } else {
      this.toastrService.warning(
        'New Target Date must be greater then Target date.'
      );
      data.NewTargetDate = '';
      this.onSearchClick();
    }
  }

  ncrActionCheckbox(event: any, data: any): void {
    const actionId = parseInt(data.actionId, 10);
    const isChecked = event.target.checked;

    const m = this.ncrActionHistoryDataSource.filteredData.find(
      (x) => x.actionId === actionId
    );
    m.isChecked = isChecked;

    const result = this.ncrActionHistoryDataSource;
  }

  openModal2(element: any, params: any): void {
    this.spinner.hide();
    const key = {
      ncrId: this.ncrId,
    };
    this.dialog.open(this.ncrRemarksModal, {
      height: '40%',
      width: '40%',
      disableClose: true,
    });
    this.ncrId = element;
    this.Status = params.data.ncrStatus;
  }

  saveNotes(params: any): void {
    let data: any = {};
    const col = params.colDef.field;
    if (this.Status === 'Reject') {
      this.remarks = true;
      data = {
        ncrId: this.ncrId,
        remarks: this.remarksForm.value.remarks,
        status: 'Closed',
        ncrStatus: 'Reject',
        rcaDue: '',
        implementCapa: '',
        emailData: JSON.stringify(this.emailData),
        EmailStatus: this.emailStatus
      };
    } else {
      this.remarks = false;
      data = {
        ncrId: this.ncrId,
        remarks: this.remarksForm.value.remarks,
        status: 'Closed',
        rcaDue: 'No',
        implementCapa: this.remarksForm.value.implementCapa,
        ncrStatus: 'Approved',
        emailData: JSON.stringify(this.emailData),
        EmailStatus: this.emailStatus
      };
    }

    this.flowService.setSingleInstance(this.instanceParams).subscribe(
      (res) => {
        this.flowService
          .getInstanceCurrentStateByNCR(this.instanceParams)
          .subscribe(
            (resp) => {
              if (resp?.currentStateType === 'final') {
                data.status = 'Closed';
              }
              this.ncrService.updateRemarks(data).subscribe(
                (response: any) => {
                  this.toastrService.success(
                    'Success',
                    'Notes Added Successfully'
                  );
                  this.onSearchClick();
                  this.dialog.closeAll();
                },
                (error: any) => {
                  this.toastrService.error('Error', error.error.message);
                }
              );
            },
            ({ error }) => {
              this.spinner.hide();
              this.toaster.error('Error', error?.message);
              this.onSearchClick();
            }
          );
      },
      ({ error }) => {
        this.spinner.hide();
        this.toaster.error('Error', error?.message);
        this.onSearchClick();
      }
    );
  }

  onChangeDefect(e) {
  }
  hasPermission(params): boolean {
    let participants = params.data.participants;
    if (
      participants == null ||
      participants == undefined ||
      participants == ''
    ) {
      this.toastService.error('Error', 'No Participants Found');
      return false;
    }
    participants = participants.split(',');
    if (participants.length > 0) {
      const loggedInUserId = JSON.parse(localStorage.getItem('user')).UserId;
      const isAllowed = participants.some((user) => user == loggedInUserId);
      return isAllowed;
    }
  }
  cellClicked(params: any): void {
    this.emailStatus = false;
    this.ncrIdDesc = '';
      this.ncrIdDesc = params.data.ncrIdDesc;
      const col = params.colDef.field;
      this.ncrId = params.data.ncrId;
      const classList = params.event.target.classList;
      const classType = classList[0];
      this.ButtonType = params.event.target.classList[1];
      this.ncr = params.data;
  
      if (this.ButtonType === 'fa-trash') {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to delete NCR!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
        }).then((result) => {
          if (result.value) {
            params.data.instanceKey ? this.deleteDefect(params.data.instanceKey) : this.deleteNCR(this.ncrId.toString());
          }
        });
        return;
      }
      if (this.ButtonType === 'fa-file') {
        this.openEditFilesModal(this.ncr);
        return;
      }
    if(params.data.instanceKey){
      this.emailStatus = params.data.ncrAutoOrManual == 'Auto' ? false : true;
      if (col === 'action') {
  
        if (this.ButtonType === 'fa-solid') {
          //Reopen NCR Logic
          this.reOpenNCR(params);
        }
        if (this.ButtonType === 'fa-industry') {
          this.openImmediateAction(params);
        }
        if (this.ButtonType === 'fa-credit-card') {
          this.implementRCA(params, false);
        }
        if (this.ButtonType === 'fa-eye') {
          this.capaActionHistory(this.ncrId, false);
        }
        if (this.ButtonType === 'fa-pencil') {
          this.ButtonType = '';
          this.AddNCRDataForm.reset();
          this.AddNCRDataForm.patchValue({
            uom: params.data.uomId,
            itemDesc: params.data.ItemDesc,
            manualOperationName: params.data.manualOperationName,
            ...params.data,
          });
          this.AddNCRDataForm.patchValue({
            salesOrderNo: params.data.SalesOrderNo,
          });
          this.getSelectedRowData(params);
        }
        if (classType === 'ncrReport') {
          this.getreportData(params.data);
        }
      }
      if (params.colDef.headerName === 'Implement RCA') {
        if (
          this.severity.includes(params.data.rcaDue) &&
          params.data.Status !== 'Closed'
        ) {
          const participants = params.data.participants?.split(',');
          if(participants.includes(JSON.parse(localStorage.getItem('user')).UserId.toString()))
            this.implementRCA(params);
          else
            this.toaster.warning('Warning!','You do not have acces to perform this Action');
        }
      }
      if (col === 'ncrIdDesc') {
        if (
          params.data.ncrStatus === 'Approved' &&
          params.data.Status !== 'Closed' &&
          params.data.puDesc !== 'Other'
        ) {
          const dialogRef = this.dialog.open(ImmediateActionComponent, {
            height: '100%',
            width: '90%',
            panelClass: 'full-screen-modal',
            data: { tableId: params.data.ncrId },
          });
          dialogRef.afterClosed().subscribe((data) => { });
        }
      }
    }
    else{
      if (col === 'action') {
        if (this.ButtonType === 'fa-key') {
          this.ButtonType = '';
          this.setInstanceManually(params);
        }
        else{
          this.toaster.warning('Warning','Please Create an Instance Key first');
        }
      }
      else{
        this.toaster.warning('Warning','Please Create an Instance Key first');
      }
    }
  }
  rowSelectionForProductionOutput(params: any) {     
    const selectedRows = params?.api?.rowModel?.rowsToDisplay.filter((node: any) => node.isSelected());
    const currentFocusedRow =  params?.api.getFocusedCell();
    let row = selectedRows.find(c=>c.rowIndex === currentFocusedRow.rowIndex);
    if(selectedRows.length >= 1){
      if(row){
        row.data.instanceKey ? this.bulkInstanceKeysForDiscard.push(row.data.instanceKey) : '';
        this.bulkNcrIdsForDelete.push(row.data.ncrId);
      }
      else{
        row = params?.api?.rowModel?.rowsToDisplay.find((node: any) => node.rowIndex == currentFocusedRow.rowIndex)
        let index = this.bulkInstanceKeysForDiscard.indexOf(row.data.instanceKey);
        this.bulkInstanceKeysForDiscard.splice(index,1);
        index = this.bulkNcrIdsForDelete.indexOf(row.data.ncrId);
        this.bulkNcrIdsForDelete.splice(index,1);
      }
    }
    else{
      this.bulkInstanceKeysForDiscard = [];
      this.bulkNcrIdsForDelete = [];
    }
  }
  openRcaDue(params: any,isClicked: boolean) {
    this.analyticalToolsService.setHeaderId(params.data.headerId);
    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    const dialogRef = this.dialog.open(AtStagesNcrComponent, {
      height: '100%%',
      width: '90%',
      panelClass: 'full-screen-modal-RCA',

      data: {
        InitProbStatement: params.data.quatlityDesc,
        ncrIdDesc: params.data.ncrIdDesc,
        tableId: params.data.ncrId,
        moduleId: 1,
        patchForm: params.data.headerId != 0 ? true : false,
        patchHeader: true,
        Group: this.NCRDataForm.value.groupList,
        Plant: this.NCRDataForm.value.plant,
        headerId: params.data.headerId,
        hideTabs: false,
        isDataUpdateAllow: true
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.analyticalToolsService.setHeaderId(0);
      if (
        this.severity.includes(params.data.rcaDue) && !isClicked &&
        (data.StatusCycle == 'Open' || data.StatusCycle == 'InProgress' || data.StatusCycle == 'Completed')
      ) 
      {
        this.cellData = {
          ncrId: params.data.ncrId,
          Status: 'Closed',
          rcaDue: 'No',
          implementCapa: params.data.implementCapa,
          ncrStatus: params.data.ncrStatus,
        };
        this.emailData[this.emailData.length-1].key = 'RCA Status Updated By';
        const instanceKey = params.data.instanceKey;
        const { UserId, UserName } = JSON.parse(localStorage.user);
        let instanceParams = {
          instanceKey,
          userId: UserId.toString(),
          userName: UserName,
          actionName: 'update',
          workFlowKey: this.ncrWorkFlow.workFlowKey,
          tenantId: this.NCRDataForm.value.plant,
          tableId: params.data.ncrId,
          payload: {
            ...this.ncr,

            plantId: this.NCRDataForm.get('plant')?.value, groupDesc: this.GroupList.find((x) => x.TypeId == this.NCRDataForm.get('groupList')?.value)?.TypeName,
            machineDesc: this.Machines.find((x) => x.PU_Id == this.NCRDataForm.get('machine')?.value)?.PU_Desc, lineDesc: this.Lines.find((x) => x.PL_Id == this.NCRDataForm.get('line')?.value)?.PL_Desc, plantDesc: this.Plants.find((x) => x.DeptId == this.NCRDataForm.get('plant')?.value)?.DeptDesc,
            workFlowKey: this.ncrWorkFlow ? this.ncrWorkFlow.workFlowKey : ''
          }
        };
        this.flowService.setSingleInstance(instanceParams).subscribe(
          (res) => {
            this.updateRCAStatus(params);
          },
          ({ error }) => {
            this.spinner.hide();
            this.toaster.error('Error', error?.message);
          }
        );
      }
      else{
        this.onSearchClick();
      }
    });
  }

  updateRCAStatus(params: any) {
    const data = { 
      Status: params?.data?.rcaDue, 
      NCRId: params?.data?.ncrId, 
      emailData: JSON.stringify(this.emailData),
      EmailStatus: this.emailStatus 
    };
    this.spinner.show();
    this.ncrService.updateRCAStatus(data).subscribe(
      (response) => {
        this.spinner.hide();
        this.toaster.success('RCA Status Updated');
        this.onSearchClick()
      },
      ({ error }) => {
        this.spinner.hide();
        this.toaster.error(error);
        this.onSearchClick();
      }
    );
  }
  reOpenNCR(params) {
    this.emailData = [];
    this.spinner.show();
    const key ={
      instanceKey: params?.data?.instanceKey,
      tableId: params?.data?.ncrId,
      tenantId: this.NCRDataForm.value.plant,
      workFlowKey: this.ncrWorkFlow.workFlowKey,
    };
    this.workFlowService
      .resetWorkflowInstanceHeirarchy(key)
      .subscribe(
        (response) => {
          this.toaster.success(
            'Success',
            'Current Activity Key Has Been Reset'
          );
          this.emailData = [
            {key: 'NCR #',value: params.data.ncrTypeName + (params.data.ncrAutoOrManual == 'Manual' ? '-MNL-' : '-Auto-')},
            {key: 'Group',value: this.GroupList.find(
              (index) => index.TypeId == this.NCRDataForm.get('groupList').value).TypeName},
            {key: 'Plant',value: this.Plants.find(
              (index) => index.DeptId == this.NCRDataForm.get('plant').value).DeptDesc},
            {key: 'Line',value: params.data.lineDesc},
            {key: 'Machine',value: params.data.puDesc},
            {key: 'Incident Date',value: this.datePipe.transform(params.data.IncidentDate, 'yyyy-MM-dd')},
            {key: 'Shift',value: params.data.shift},
            {key: 'Crew',value: params.data.crew},
            {key: 'Problem Description',value: params.data.quatlityDesc},
            {key: 'NCR Type',value: params.data.ncrTypeName},
            {key: 'NCR Category',value: params.data.prcocessType},
            {key: 'Cause of Incident',value: params.data.IncidentCause},
            {key: 'Area Name',value: params.data.areaName},
            {key: 'Created By',value: params.data.ncrAutoOrManual == 'Auto' ? 'System Generated' : params.data.CreatedBy},
            {key: 'NCR Status',value: 'Pending'},
            {key: 'Reopened By',value: JSON.parse(localStorage.user).UserName.toString()}
          ]
          this.ncrService.reOpenNCR(
            { 
              ncrId: params.data?.ncrId, 
              emailData: JSON.stringify(this.emailData),
              EmailStatus: this.emailStatus 
            }
          ).subscribe(
            (res) => {
              this.spinner.hide();

              this.toaster.success('Success', 'NCR Reopened Successfully');
              this.onSearchClick();
            },
            (err: any) => {
              this.spinner.hide();

              this.toaster.error('Error', err.error.message);
            }
          );
        },
        (err: any) => {
          this.spinner.hide();

          this.toaster.error('Error', err.error.message);
        }
      );
  }
  implementRCA(params: any,isDataUpdateAllow: boolean = true){
    this.analyticalToolsService.setHeaderId(params.data.headerId); 
      const dialogRef = this.dialog.open(AtStagesNcrComponent, {
        height: '100%',
        width: '90%',
        panelClass: 'full-screen-modal-RCA',
        data: {
          InitProbStatement: params.data.quatlityDesc,
          ncrIdDesc: params.data.ncrIdDesc,
          tableId: params.data.ncrId,
          moduleId: 1,
          patchForm: params.data.headerId != 0 ? true : false,
          patchHeader: true,
          Group: this.NCRDataForm.value.groupList,
          Plant: this.NCRDataForm.value.plant,
          headerId: params.data.headerId,
          isDataUpdateAllow: isDataUpdateAllow
        },
      });  
    dialogRef.afterClosed().subscribe((data) => {
      this.onSearchClick();
    });
  }

  openImmediateAction(params) {
    const dialogRef2 = this.dialog.open(ImmediateActionComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        showWrapper2: true,
        tableId: params.data.ncrId,
        quantityOnHold: params.data.quantityHold,
        NCRFilters: this.NCRDataForm.value,
        NCRSelectedRow: params.data
      },
    });
    dialogRef2.afterClosed().subscribe((data) => { });
  }
  async onCellValueChanged(params: any) {
    this.emailData = [];
    if(params.data.instanceKey){
      this.emailStatus = params.data.ncrAutoOrManual == 'Auto' ? false : true;
      this.emailData = [
        {key: 'NCR #',value: params.data.ncrTypeName + (params.data.ncrAutoOrManual == 'Manual' ? '-MNL-' : '-Auto-')},
        {key: 'Group',value: this.GroupList.find(
          (index) => index.TypeId == this.NCRDataForm.get('groupList').value).TypeName},
        {key: 'Plant',value: this.Plants.find(
          (index) => index.DeptId == this.NCRDataForm.get('plant').value).DeptDesc},
        {key: 'Line',value: params.data.lineDesc},
        {key: 'Machine',value: params.data.puDesc},
        {key: 'Incident Date',value: this.datePipe.transform(params.data.IncidentDate, 'yyyy-MM-dd')},
        {key: 'Shift',value: params.data.shift},
        {key: 'Crew',value: params.data.crew},
        {key: 'Problem Description',value: params.data.quatlityDesc},
        {key: 'NCR Type',value: params.data.ncrTypeName},
        {key: 'NCR Category',value: params.data.prcocessType},
        {key: 'Cause of Incident',value: params.data.IncidentCause},
        {key: 'Area Name',value: params.data.areaName},
        {key: 'Created By',value: params.data.ncrAutoOrManual == 'Auto' ? 'System Generated' : params.data.CreatedBy},
        {key: 'NCR Status',value: params.data.ncrStatus},
        {key: 'RCA Status',value: params.data.rcaDue},
        {key: 'Implement Capa',value: params.data.implementcapa},
        {key: '',value: JSON.parse(localStorage.user).UserName.toString()},
      ]
      this.params = params;
      this.ncrId = params.data.ncrId;
      const col = params.colDef.field;
      const instanceKey = params.data.instanceKey;
      const { UserId, UserName } = JSON.parse(localStorage.user);
      this.instanceParams = {
        instanceKey,
        userId: UserId.toString(),
        userName: UserName,
        rules: null,
      };
      if (col === 'ncrcost') {
        const key = {
          ncrId: this.ncrId,
          ncrcost: params.data.ncrcost,
        };
        if (key) {
          this.ncrService.SaveNcrCost(key).subscribe((data: any) => {
            this.toastrService.success('Success', 'Ncr Cost Added Successfully!');
            this.onSearchClick();
          });
        }
      }
      if (col === 'ncrStatus') {
        const response: any = await this.checkPermission('Approve');
        if (!response.isSuccess) {
          this.toastrService.error('Error', 'User doesnt have access to perform this action');
          this.spinner.hide();
          return
        }
        if (params.data.ncrStatus === 'Approved') {
          this.spinner.show();
          this.cellData = {
            ncrId: params.data.ncrId,
            ncrStatus: params.value,
            status: 'InProgress',
          };
          this.instanceParams.actionName = 'approve';
          this.instanceParams.tableId = this.ncrId;
          this.instanceParams.tenantId = this.NCRDataForm.value.plant;
          this.instanceParams.workFlowKey = this.ncrWorkFlow.workFlowKey;
          this.instanceParams.payload = {
            ...this.ncr,
  
            plantId: this.NCRDataForm.get('plant')?.value,
            groupDesc: this.GroupList.find((x) => x.TypeId == this.NCRDataForm.get('groupList')?.value)?.TypeName,
            machineDesc: this.Machines.find((x) => x.PU_Id == this.NCRDataForm.get('machine')?.value)?.PU_Desc,
            lineDesc: this.Lines.find((x) => x.PL_Id == this.NCRDataForm.get('line')?.value)?.PL_Desc,
            plantDesc: this.Plants.find((x) => x.DeptId == this.NCRDataForm.get('plant')?.value)?.DeptDesc,
            workFlowKey: this.ncrWorkFlow ? this.ncrWorkFlow.workFlowKey : ''
          }
          this.flowService
            .setSingleInstance(this.instanceParams)
            .subscribe(
              (res) => {
                this.emailData[this.emailData.length-1].key = 'Approved By';
                this.cellData.emailData = JSON.stringify(this.emailData);
                this.cellData.EmailStatus = this.emailStatus;
                this.ncrService.updateStatus(this.cellData).subscribe(
                  (respo) => {
                    this.spinner.hide();
                    this.toastrService.success('Success','Approved Successfully');
                    this.onSearchClick();
                  },
                  (error) => {
                    this.spinner.hide();
                    this.toastrService.error('Error',error.message);
                    this.onSearchClick();
                  }
                );
              },
              ({ error }) => {
                this.spinner.hide();

                this.toaster.error('Error', error?.message);
                this.onSearchClick();
              }
            );

        } else if (params.data.ncrStatus === 'Reject') {
          this.spinner.show();
          this.cellData = {
            ncrId: params.data.ncrId,
            ncrStatus: params.value,
            status: 'Closed',
            emailData: ''
          };
          this.instanceParams.actionName = 'approve';
          this.instanceParams.workFlowKey = this.ncrWorkFlow.workFlowKey;
          this.instanceParams.TableId = params.data.ncrId;
          this.instanceParams.tenantId = this.NCRDataForm.value.plant;
          this.emailData[this.emailData.length-1].key = 'Rejected By';
          this.openModal2(this.ncrId, this.params);
        } else if (params.data.ncrStatus === 'Pending') {
          this.spinner.show();
          this.onSearchClick();
        }
      }
      if (col === 'rcaDue') {
        const response: any = await this.checkPermission('Update');
        if (!response.isSuccess) {
          this.toastrService.error('Error', 'User doesnt have access to perform this action');
          this.gridApi1.undoCellEditing()
          const colId = params.column.colId;
          this.params.node.setDataValue(colId, '');
          this.spinner.hide();
          return
        }
        this.instanceParams.actionName = 'update';
        this.instanceParams.workFlowKey = this.ncrWorkFlow.workFlowKey;
        this.instanceParams.TableId = params.data.ncrId;
        if (params.data.rcaDue === 'No') {
          this.spinner.show();
          this.cellData = {
            ncrId: params.data.ncrId,
            Status: 'Closed',
            rcaDue: 'No',
            implementCapa: params.data.implementCapa,
            ncrStatus: params.data.ncrStatus,
          };
          this.emailData[this.emailData.length-1].key = 'RCA Status Updated By';
          // this.onSearchClick();
          this.openModal2(this.ncrId, this.params);
        } else if (params.data.Status === 'Closed') {
          return;
        } else {
          this.spinner.show();
          this.openRcaDue(params,false);
          this.getUserList();
          this.getTools();
          this.ncrId = params.data.ncrId;
          this.getAllNCRAction();
        }
      }
      if (col === 'implementcapa') {
        if(params.data.StatusFiveWhyAction == 'Completed'){
          this.instanceParams.actionName = 'complete';
          this.instanceParams.workFlowKey = this.ncrWorkFlow.workFlowKey;
          const response: any = await this.checkPermission('Complete');
          if (!response.isSuccess) {
            this.toastrService.error('Error', 'User doesnt have access to perform this action');
            this.gridApi1.undoCellEditing();
            this.spinner.hide();
            return
          }
          if (params.data.implementcapa === 'Yes') {
            this.spinner.show();
            this.emailData[this.emailData.length-1].key = 'Capa Implemented By';
            this.capaActionHistory(this.ncrId, true);
          } else {
            this.spinner.show();
            const key: any = {
              ncrId: params.data.ncrId,
              status: 'Delay',
              rcaDue: params.data.rcaDue,
              implementCapa: params.value,
              ncrStatus: 'Approved'
            };
            this.emailData[this.emailData.length-1].key = 'Capa Implemented By';
            key.emailData = JSON.stringify(this.emailData);
            key.EmailStatus = this.emailStatus;
            this.ncrService.updateStatus(key).subscribe(
              (respo) => {
                this.spinner.hide();
                this.toastrService.success(
                  'Success',
                  'Updated Successfully!'
                );
                this.onSearchClick();
              },
              (error) => {
                this.toastrService.error('Error', error.message);
                this.onSearchClick();
              }
            );
          }
        }
        else{
          this.toaster.warning('Warning!','First Complete RCA');
          const colId = params.column.colId;
          params.value != '' ? params.node.setDataValue(colId, '') : '';
        }
      }
    }
    else{
      this.toaster.warning('Warning','Please Create an Instance Key first');
      this.onSearchClick();
    }
  }
  applyFilter(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.NcrDataSource.filter = filterValue.trim().toLowerCase();
  }

  getUom(id: any): void {
    const key = {
      plantId: id,
    };
    this.ncrService.getUom(key).subscribe(
      (res) => {
        this.Uom = res;
      },
      (error) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }

  getQualityArea(id: any): void {
    const key = {
      plantId: this.NCRDataForm.get('plant')?.value,
      egId: this.NCRDataForm.get('groupList')?.value,
    };
    this.areaService.getMasterArea(key).subscribe(
      (res) => {
        this.qualityArea = res;
      },
      (error) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }

  async openModal() {
    this.spinner.show();
    const response: any = await this.checkPermission('Create');
    if (!response.isSuccess) {
      this.toastrService.error('Error', 'User doesnt have access to perform this action');
      if(this.isUpdateNcr) this.isUpdateNcr = false;
      this.spinner.hide();
      return
    }
    if(!this.isUpdateNcr){
      this.showBoth = false;
      this.resetForm();
      this.disabledColumns = false;
      this.path = '';
      if (
        this.NCRDataForm.get('machine')?.value !== '' &&
        this.NCRDataForm.get('plant')?.value !== '' &&
        this.NCRDataForm.get('line')?.value !== ''
      ) {
        this.AddNCRDataForm.get('startDateTime').setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm'));
        this.AddNCRDataForm.get('endDateTime').setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm'));
        this.AddNCRDataForm.get('incidentDate').setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
        this.getBaseDetailsForManualNCR(false);
        this.dialog.open(this.displayRoleModal, {
          height: 'auto',
          width: '90%',
          disableClose: true,
        });
      } else {
        this.toastrService.error('Error', 'Plant/Line/Machine not selected!');
      }
    }
    else{
      if (this.NCRDataForm.get('')?.value !== '') {
        this.path = this.savedPath;
        this.dialog.open(this.displayRoleModal, {
          height: 'auto',
          width: '90%',
          disableClose: true,
        });
      } else {
        this.toastrService.error('Error', 'Machine not selected!');
      }
    }
  }

  closeModal(): void {
    this.isUpdateNcr = false;
    this.NCRDataForm.get('line')?.setValue(-1);
    this.lineOtherSelected = false;
    this.NCRDataForm.get('machine')?.setValue(-1);
    this.dialog.closeAll();
  }

  closePopUpModal(): void {
    this.dialog.closeAll();
    this.onSearchClick();
  }
  onChangePlant(data: any): void {
    this.getLines(data.value);
    this.getQualityArea(data.value);
    this.getNCRWorkFlow();
  }
  onChangeGroup(data: any): void {
    this.NCRDataForm.get('groupList')?.setValue(data.value);
    this.getPlants();
  }
  onChangeOrder(data: any): void {
    if (data?.value === 0) {
      this.showBoth = true;
      this.AddNCRDataForm.get('desc')?.setValue('');
      this.AddNCRDataForm.get('itemDesc')?.setValue('');
      this.OperationNumber = [];
      this.AddNCRDataForm.get('operation')?.setValue(0);
    } else {
      this.showBoth = false;
      const PO = this.ProcessOrder.find((c: any) => c.processId === data.value);
      if (this.NCRDataForm.get('machine')?.value === 0) {
        this.AddNCRDataForm.get('order')?.setValue('');
        this.AddNCRDataForm.get('desc')?.setValue('');
        this.AddNCRDataForm.get('itemDesc')?.setValue('');
      } else {
        this.AddNCRDataForm.get('desc')?.setValue(PO.prodDesc);
        this.AddNCRDataForm.get('prodId')?.setValue(PO.prodId);
        this.AddNCRDataForm.get('itemDesc')?.setValue(PO.itemDesc);
        this.AddNCRDataForm.get('operation')?.setValue(PO.productionPlan);
        this.AddNCRDataForm.get('salesOrderNo')?.setValue(PO.itemCode);
        this.AddNCRDataForm.get('fmode')?.setValue('');
      }
    }
  }
  onChangeLine(data: any): void {
    if (data.value == -1) {
      let lineAll = [];
      for (var i = 0; i <= this.listofLine; i++) {
        lineAll.push(this.listofLine.PL_ID);
      }
      this.listofLine.forEach((element) => {
        lineAll.push(element.PL_Id);
      });
      let lnl = lineAll.toString();

      this.getMachines(lnl);
    } else {
      this.getMachines(data.value);
    }
  }
  onChangeMachine(data: any): void {
    if (data.value === -1) {
      this.MachineIsAll = true;
    } else if (data.value === 0) {
      this.Machinetype = true;
      this.MachineIsAll = true;
    } else {
      this.MachineIsAll = false;
    }
    this.onSearchClick();
  }
  onChangeOption(event: any) {
    if (event.value == 0) {
      this.gridApi.destroyFilter('prcocessType');
    } else {
      const instance = this.gridApi.getFilterInstance('prcocessType');
      let item = this.ncrCategories.find(
        (element) => element.id === event.value
      );
      const myArray: any = [];
      myArray.push(item.name);
      instance.setModel({
        values: myArray,
      });
      this.gridApi.onFilterChanged();
    }
  }

  onChangeProduct(data: any): void {
    if (data.value === 'Process') {
      this.NCRDataForm.get('process')?.setValue('Process');
      this.areahide = true;
    } else {
      this.areahide = false;
    }
  }

  public onChangeFailureMode(val: any): void {
    this.AddNCRDataForm.get('fmode')?.setValue(val.value);
  }

  // Ncr Crud
  getAllNcr(): void {
    this.spinner.show();
    this.ncrService.getNcr().subscribe(
      (res: any) => {
        this.spinner.hide();
        this.NcrDataSource.data = res.Result;
        this.spinner.hide();
        this.path = environment.O3CoreApiImageUrl;
        this.savedPath = environment.O3CoreApiImageUrl;
        setTimeout(() => {
          this.NcrDataSource.paginator = this.paginator;
          this.NcrDataSource.sort = this.sort;
        }, 1000);
      },
      (error: any) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }

  onClickSaveNcr(): void {
    this.emailData = [];
    if(new Date(this.AddNCRDataForm.get('startDateTime')?.value).getTime() >= 
      new Date(this.AddNCRDataForm.get('endDateTime')?.value).getTime()){
        this.toaster.warning('Warning','End Date Time Should be Greater than Start Date Time');
        return;
    }
    this.spinner.show();
    if (!this.isUpdateNcr) {
      if (this.AddNCRDataForm.invalid) {
        this.toastrService.error('Error', 'Please insert required fields');
        this.loading = false;
        return;
      }
      const formdata = new FormData();
      if (this.fileSave) {
        if (this.fileSave?.length !== 0) {
          for (const singleFile of this.fileSave) {
            formdata.append('file', singleFile);
          }
        }
      }
      this.loading = true;
      const { UserId, UserName } = JSON.parse(localStorage.user);
      const instanceKey = generateGuid();
      
      const data = {
        opName: this.AddNCRDataForm.get('manualOperationName')?.value,
        plantId: this.NCRDataForm.get('plant')?.value,
        orderId: !this.Machinetype
          ? this.AddNCRDataForm.get('order')?.value
          : undefined,
        prodId: this.AddNCRDataForm.get('prodId')?.value,
        quantityHold: this.AddNCRDataForm.get('hquality')?.value, // decimal
        quantityWaste: this.AddNCRDataForm.get('qwaste')?.value, // decimal
        quatlityDesc: this.AddNCRDataForm.get('qdesc')?.value,
        uom: this.AddNCRDataForm.get('uom')?.value, // id
        puId: this.NCRDataForm.get('machine')?.value,
        type: this.AddNCRDataForm.get('type')?.value,
        defectId: this.AddNCRDataForm.get('fmode')?.value,
        areaId: this.AddNCRDataForm.get('area')?.value,
        prodDesc: this.AddNCRDataForm.get('desc')?.value,
        capaNum: this.AddNCRDataForm.get('qpulse')?.value,
        lineId: this.NCRDataForm.get('line')?.value,
        processType: this.AddNCRDataForm.get('process')?.value,
        processOrderNo: this.Machinetype
          ? this.AddNCRDataForm.get('processOrder')?.value
          : undefined,
        itemDesc: this.AddNCRDataForm.get('itemDesc')?.value,
        ncrAutoOrManual: 'Manual',
        manualUom: this.AddNCRDataForm.get('manualUom')?.value,
        manualOperationName: this.AddNCRDataForm.get('manualOperationName')?.value,
        failureModeDetail: this.AddNCRDataForm.get('failureModeDetail')?.value,
        createdBy: JSON.parse(localStorage.user).UserId,
        salesOrderNo: this.AddNCRDataForm.get('salesOrderNo')?.value,
        customerName: this.AddNCRDataForm.get('customerName')?.value,
        supplierName: this.AddNCRDataForm.get('supplierName')?.value,
        supplierId: this.AddNCRDataForm.get('supplierId')?.value,
        shiftId: this.AddNCRDataForm.get('shift')?.value,
        crewId: this.AddNCRDataForm.get('crew')?.value,
        incidentcause: this.AddNCRDataForm.get('causeOfIncident')?.value,
        incidentDate: this.AddNCRDataForm.get('incidentDate')?.value,
        startTime: this.AddNCRDataForm.get('startDateTime')?.value,
        endTime: this.AddNCRDataForm.get('endDateTime')?.value,
        instanceKey,
      };
      this.emailData = [
        {key: 'NCR #',value: this.NcrTypes.find(
          (index) => index.ncrTypeId == this.AddNCRDataForm.get('type')?.value).name + '-MNL-'},
        {key: 'Group',value: this.GroupList.find(
          (index) => index.TypeId == this.NCRDataForm.get('groupList').value).TypeName},
        {key: 'Plant',value: this.Plants.find(
          (index) => index.DeptId == this.NCRDataForm.get('plant').value).DeptDesc},
        {key: 'Line',value: this.NCRDataForm.get('line')?.value == 0 ? 'Other' : this.Lines.find(
          (index) => index.PL_Id == this.NCRDataForm.get('line').value).PL_Desc},
        {key: 'Machine',value: this.NCRDataForm.get('line')?.value == 0 ? 'Other' : this.Machines.find(
          (index) => index.PU_Id == this.NCRDataForm.get('machine').value).PU_Desc},
        {key: 'Incident Date',value: this.datePipe.transform(this.AddNCRDataForm.get('incidentDate')?.value, 'yyyy-MM-dd')},
        {key: 'Shift',value: this.shiftListForManualEntry.find(
          (index) => index.TypeId == this.AddNCRDataForm.get('shift')?.value).TypeName},
        {key: 'Crew',value: this.crewListForManualEntry.find(
          (index) => index.TypeId == this.AddNCRDataForm.get('crew')?.value).TypeName},
        {key: 'Problem Description',value: this.AddNCRDataForm.get('qdesc').value},
        {key: 'NCR Type',value: this.NcrTypes.find(
          (index) => index.ncrTypeId == this.AddNCRDataForm.get('type')?.value).name},
        {key: 'NCR Category',value: this.AddNCRDataForm.get('process').value},
        {key: 'Cause of Incident',value: this.AddNCRDataForm.get('causeOfIncident').value},
        {key: 'Area Name',value: this.qualityArea.find(
          (index) => index.areaId == this.AddNCRDataForm.get('area')?.value).areaName},
        {key: 'NCR Status',value: 'Pending'},
        {key: 'Created By',value: JSON.parse(localStorage.user).UserName.toString()},
      ]
      if (this.showBoth) {
        delete data?.orderId;

        data.processOrderNo = this.AddNCRDataForm.get('processOrder')?.value;
      }
      formdata.append('ncr', JSON.stringify(data));
      formdata.append('emailData', JSON.stringify(this.emailData));
      const instance = {
        workFlowKey: this.ncrWorkFlow ? this.ncrWorkFlow.workFlowKey : '',
        userId: UserId.toString(),
        userName: UserName,
        tableId: this.ncrId,
        tenantId: this.NCRDataForm.get('plant')?.value,

        instanceKey,
        payload: {
          ...data,

          createdBy: JSON.parse(localStorage.user).UserName,
          creatorId: JSON.parse(localStorage.user).UserId,
          IsAdmin: JSON.parse(localStorage.user).IsAdmin ? "Yes" : "No",
          createdOn: moment().format('YYYY-MM-DD HH:mm:ss'),
          plantId: this.NCRDataForm.get('plant')?.value, groupDesc: this.GroupList.find((x) => x.TypeId == this.NCRDataForm.get('groupList')?.value)?.TypeName,
          machineDesc: this.Machines.find((x) => x.PU_Id == this.NCRDataForm.get('machine')?.value)?.PU_Desc, lineDesc: this.Lines.find((x) => x.PL_Id == this.NCRDataForm.get('line')?.value)?.PL_Desc, plantDesc: this.Plants.find((x) => x.DeptId == this.NCRDataForm.get('plant')?.value)?.DeptDesc,
          workFlowKey: this.ncrWorkFlow ? this.ncrWorkFlow.workFlowKey : ''
        }

      };
      this.flowService.createSingleInstances(instance).subscribe(
        (response) => {
          this.ncrService.addNcr(formdata).subscribe(
            (resp: any) => {
              let ncrID = resp.ncrId;
              const createInstance = {
                instanceKey: response?.instanceKey,
                userId: UserId.toString(),
                userName: UserName,
                actionName: 'Create',
                workFlowKey: this.ncrWorkFlow.workFlowKey,
                rules: null,
                tenantId: this.NCRDataForm.value.plant,
                tableId: resp.ncrId,
                payload: {
                  ...data,
                  ncrId: resp.ncrId,
                  createdBy: JSON.parse(localStorage.user).UserName,
                  creatorId: JSON.parse(localStorage.user).UserId,
                  IsAdmin: JSON.parse(localStorage.user).IsAdmin ? "Yes" : "No",
                  createdOn: moment().format('YYYY-MM-DD HH:mm:ss'),
                  ncrStatus: "Pending",
                  plantId: this.NCRDataForm.get('plant')?.value,
                  groupDesc: this.GroupList.find((x) => x.TypeId == this.NCRDataForm.get('groupList')?.value)?.TypeName,
                  machineDesc: this.Machines.find((x) => x.PU_Id == this.NCRDataForm.get('machine')?.value)?.PU_Desc,
                  lineDesc: this.Lines.find((x) => x.PL_Id == this.NCRDataForm.get('line')?.value)?.PL_Desc,
                  plantDesc: this.Plants.find((x) => x.DeptId == this.NCRDataForm.get('plant')?.value)?.DeptDesc,
                }
              };

              this.flowService.setSingleInstance(createInstance).subscribe(
                (res) => {
                },
                ({ error }) => {
                  this.spinner.hide();
                  this.toaster.error('Error', error?.message);
                  this.deleteInstances([response?.instanceKey]);
                }
              );
              this.toastrService.success('Success', 'Added Successfully'+ (resp.emailStatus ? 'and Email Sent For NCR' : ''));
              if(!resp.emailStatus){
                this.toastrService.error('Error','Email not Sent For NCR');
              }
              this.fileSave = null;
              this.loading = false;
              this.resetForm();
              this.closeModal();
              this.NCRDataForm.get('line')?.setValue(-1);
              this.lineOtherSelected = false;
              this.NCRDataForm.get('machine')?.setValue(-1);
              this.onSearchClick();
              this.spinner.hide();
            },
            (error: any) => {
              this.toastrService.error('Error', error.message);
              this.loading = false;
              this.spinner.hide()
            }
          );
        },
        ({ error }) => {
          this.spinner.hide();
          this.toaster.error('Error', error?.message);
        }
      );
    } else {
      if (this.AddNCRDataForm.invalid) {
        this.toastrService.error('Error', 'Please insert required fields');
        this.loading = false;
        return;
      }
      const formdata = new FormData();
      formdata.append('file', this.fileSave);
      this.loading = true;
      const data = {
        opName: this.AddNCRDataForm.get('manualOperationName')?.value,
        plantId: this.NCRDataForm.get('plant')?.value,
        orderId: this.AddNCRDataForm.get('order')?.value,
        prodId: this.AddNCRDataForm.get('prodId')?.value,
        quantityHold: this.AddNCRDataForm.get('hquality')?.value,
        quantityWaste: this.AddNCRDataForm.get('qwaste')?.value,
        quatlityDesc: this.AddNCRDataForm.get('qdesc')?.value,
        uom: this.AddNCRDataForm.get('uom')?.value,
        puId: this.NCRDataForm.get('machine')?.value,
        type: this.AddNCRDataForm.get('type')?.value,
        defectId: this.AddNCRDataForm.get('failureModeDetail')?.value
          ? this.AddNCRDataForm.get('failureModeDetail')?.value
          : null,
        areaId: this.AddNCRDataForm.get('area')?.value,
        prodDesc: this.AddNCRDataForm.get('desc')?.value,
        capaNum: this.AddNCRDataForm.get('qpulse')?.value,
        lineId: this.NCRDataForm.get('line')?.value
          ? this.NCRDataForm.get('line')?.value
          : 0,
        ncrId: this.AddNCRDataForm.get('ncrId')?.value,
        processType: this.AddNCRDataForm.get('process')?.value,
        processOrderNo: this.AddNCRDataForm.get('processOrder')?.value,
        manualUom: this.AddNCRDataForm.get('manualUom')?.value,
        manualOperationName: this.AddNCRDataForm.get('manualOperationName')?.value,
        failureModeDetail: this.AddNCRDataForm.get('failureModeDetail')?.value,
        salesOrderNo: this.AddNCRDataForm.get('salesOrderNo')?.value,
        customerName: this.AddNCRDataForm.get('customerName')?.value,
        itemDesc: this.AddNCRDataForm.get('itemDesc')?.value,
        supplierName: this.AddNCRDataForm.get('supplierName')?.value,
        supplierId: this.AddNCRDataForm.get('supplierId')?.value,
        ncrAutoOrManual: this.ncrAutoOrManual,
        shiftId: this.AddNCRDataForm.get('shift')?.value,
        crewId: this.AddNCRDataForm.get('crew')?.value,
        incidentcause: this.AddNCRDataForm.get('causeOfIncident')?.value,
        incidentDate: this.AddNCRDataForm.get('incidentDate')?.value,
        startTime: this.AddNCRDataForm.get('startDateTime')?.value,
        endTime: this.AddNCRDataForm.get('endDateTime')?.value,
      };
      formdata.append('ncr', JSON.stringify(data));
      this.ncrService.updateNcr(formdata).subscribe(
        (res: any) => {
          this.isUpdateNcr = false;
          this.toastrService.success('Success', 'Updated Successfully!');
          this.fileSave = null;
          this.closeModal();
          this.resetForm();
          this.NCRDataForm.get('line')?.setValue(-1);
          this.lineOtherSelected = false;
          this.NCRDataForm.get('machine')?.setValue(-1);
          this.onSearchClick();
          this.spinner.hide();
        },
        (error: any) => {
          this.toastrService.error('Error', error.message);
          this.loading = false;
          this.spinner.hide();
        }
      );
    }
  }
  async setInstanceManually(params?: any) {
    const response: any = await this.checkPermission('Create');
    if (!response.isSuccess) {
      this.toastrService.error('Error', 'User doesnt have access to perform this action');
      this.spinner.hide();
      return
    }
    this.emailData = [];
    this.emailStatus = false;
    const { UserId, UserName } = JSON.parse(localStorage.user);
    const instanceKey = generateGuid();
    const instance = {
      workFlowKey: this.ncrWorkFlow ? this.ncrWorkFlow.workFlowKey : '',
      userId: UserId.toString(),
      userName: UserName,
      tableId: this.ncrId,
      tenantId: this.NCRDataForm.get('plant')?.value,

      instanceKey,
      payload: {
        ...this.ncr,

        createdBy: JSON.parse(localStorage.user).UserName,
        creatorId: JSON.parse(localStorage.user).UserId,
        IsAdmin: JSON.parse(localStorage.user).IsAdmin ? "Yes" : "No",
        createdOn: moment().format('YYYY-MM-DD HH:mm:ss'),
        plantId: this.NCRDataForm.get('plant')?.value, groupDesc: this.GroupList.find((x) => x.TypeId == this.NCRDataForm.get('groupList')?.value)?.TypeName,
        machineDesc: this.Machines.find((x) => x.PU_Id == this.NCRDataForm.get('machine')?.value)?.PU_Desc, lineDesc: this.Lines.find((x) => x.PL_Id == this.NCRDataForm.get('line')?.value)?.PL_Desc, plantDesc: this.Plants.find((x) => x.DeptId == this.NCRDataForm.get('plant')?.value)?.DeptDesc,
        workFlowKey: this.ncrWorkFlow ? this.ncrWorkFlow.workFlowKey : ''
      }

    };
    this.spinner.show();
    this.flowService.createSingleInstances(instance).subscribe(
      (response) => {
        const createInstance = {
          instanceKey: response?.instanceKey,
          userId: UserId.toString(),
          userName: UserName,
          actionName: 'Create',
          workFlowKey: this.ncrWorkFlow.workFlowKey,
          rules: null,
          tenantId: this.NCRDataForm.value.plant,
          tableId: this.ncrId,
          payload: {
            ...this.ncr,
            ncrId: this.ncrId,
            createdBy: JSON.parse(localStorage.user).UserName,
            creatorId: JSON.parse(localStorage.user).UserId,
            IsAdmin: JSON.parse(localStorage.user).IsAdmin ? "Yes" : "No",
            createdOn: moment().format('YYYY-MM-DD HH:mm:ss'),
            ncrStatus: "Pending",
            plantId: this.NCRDataForm.get('plant')?.value,
            groupDesc: this.GroupList.find((x) => x.TypeId == this.NCRDataForm.get('groupList')?.value)?.TypeName,
            machineDesc: this.Machines.find((x) => x.PU_Id == this.NCRDataForm.get('machine')?.value)?.PU_Desc,
            lineDesc: this.Lines.find((x) => x.PL_Id == this.NCRDataForm.get('line')?.value)?.PL_Desc,
            plantDesc: this.Plants.find((x) => x.DeptId == this.NCRDataForm.get('plant')?.value)?.DeptDesc,
          }
        };
        this.flowService.setSingleInstance(createInstance).subscribe(
          (res) => {
            this.loading = false;
            this.spinner.hide();
            this.flowService.setNCRInstanceKey({
              ncrId: params.data.ncrId,
              instanceKey: response.instanceKey,
            }).subscribe(
              (response: any) => {
                this.onSearchClick();
                this.toaster.success('Success', 'New Instance Created Successfully');
              },
              (err: any) => {
                this.loading = false;
                this.spinner.hide();
                this.deleteInstances([response.instanceKey]);
                this.toaster.error('Error', err?.message);
              }
            );
          },
          (err: any) => {
            this.loading = false;
            this.spinner.hide();
            this.deleteInstances([response.instanceKey]);
            this.toaster.error('Error', err?.error?.message);
          }
        );
      },
      ({ error }) => {
        this.spinner.hide();
        this.toaster.error('Error', error?.message);
        this.onSearchClick();
      }
    );
  }
  getSelectedRowData(params: any): void {
    const selectedData = params.data;
    this.ncrId = selectedData.ncrId;
    this.isUpdateNcr = true;
    selectedData.ptId ? this.NCRDataForm.get('plant')?.setValue(selectedData.ptId) : '';
    this.NCRDataForm.get('line')?.setValue(selectedData.lineId ? selectedData.lineId : 0);
    this.lineOtherSelected = this.NCRDataForm.get('line').value == 0 ? true : false;
    this.NCRDataForm.get('machine')?.setValue(selectedData.puId ? selectedData.puId : 0);

    this.AddNCRDataForm.get('order')?.setValue(selectedData?.orderProcessId);
    this.AddNCRDataForm.get('processOrder')?.setValue(selectedData.processOrderNo);
    this.AddNCRDataForm.get('operation')?.setValue(selectedData.operationName);
    this.AddNCRDataForm.get('type')?.setValue(selectedData.ncrTypeId);
    this.AddNCRDataForm.get('process')?.setValue(selectedData.prcocessType);
    this.AddNCRDataForm.get('hquality')?.setValue(selectedData.quantityHold);
    this.AddNCRDataForm.get('qwaste')?.setValue(selectedData.quantityWaste);
    this.AddNCRDataForm.get('qdesc')?.setValue(selectedData.quatlityDesc);
    this.AddNCRDataForm.get('failureModeDetail')?.setValue(selectedData.defectId);
    this.AddNCRDataForm.get('qpulse')?.setValue(selectedData.capaNum);
    this.AddNCRDataForm.get('desc')?.setValue(selectedData.productDesc);
    this.AddNCRDataForm.get('area')?.setValue(selectedData.areaId);
    this.AddNCRDataForm.get('supplierId')?.setValue(selectedData.SupplierID);
    this.AddNCRDataForm.get('supplierName')?.setValue(selectedData.SupplierName);
    if(selectedData.IncidentDate){
      this.AddNCRDataForm.get('incidentDate')?.setValue(selectedData.IncidentDate);
      this.getBaseDetailsForManualNCR(false);
      setTimeout(() => {
        this.AddNCRDataForm.get('shift')?.setValue(selectedData.shiftId);
        this.getBaseDetailsForManualNCR(true);
      }, 2000);
      setTimeout(() => {
        this.AddNCRDataForm.get('crew')?.setValue(selectedData.crewId);
      }, 2000); 
    }
    else{
      this.AddNCRDataForm.get('incidentDate')?.setValue('');
      this.shiftListForManualEntry = [];
      this.crewListForManualEntry = [];
    }
    this.AddNCRDataForm.get('causeOfIncident')?.setValue(selectedData.IncidentCause);
    this.AddNCRDataForm.get('startDateTime')?.setValue(
      this.datePipe.transform(selectedData.StartTime ? (new Date(selectedData.StartTime)) : (new Date()), 'yyyy-MM-dd HH:mm')
    );
    this.AddNCRDataForm.get('endDateTime')?.setValue(
      this.datePipe.transform(selectedData.EndTime ? (new Date(selectedData.EndTime)) : (new Date()), 'yyyy-MM-dd HH:mm')
    );

    this.imageSrc = selectedData.imagePath;
    this.disabledColumns = selectedData.ncrAutoOrManual === 'Manual' ? false : true;
    this.ncrAutoOrManual = selectedData.ncrAutoOrManual;
    if (selectedData.prcocessType === 'Process') {
      this.areahide = true;
    } else {
      this.areahide = false;
    }
    this.openModal();
  }

  // approveNCR(ncrID?: any) {
  //   let cellData = {
  //     ncrId: ncrID,
  //     ncrStatus: 'Approved',
  //     status: 'InProgress',

  //     // rcaDue:'No'
  //   };
  //   this.ncrService.updateStatus(cellData).subscribe(
  //     (respo) => {
  //       this.toastrService.success('Success', 'Approved Successfully');
  //       this.onSearchClick();
  //     },
  //     (error) => {
  //       this.toastrService.error('Error', error.message);
  //     }
  //   );
  // }

  onFileChange(event: any): any {
    if (event.target.files.length > 0) {
      this.files = event.target.files;
    }
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      this.fileSave = event.target.files;
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.path = '';
        this.imageSrc = reader.result as string;
        this.AddNCRDataForm.patchValue({
          fileSource: reader.result,
        });
      };
    }
  }

  public isFiltered(multiselectOption: any): any {
    return this.filterdraci.find(
      (item: any) => item.userId === multiselectOption.userId
    );
  }
  getUserList(): void {
    const key = {
      EGId: this.NCRDataForm.get('groupList')?.value,
      PlantId: this.NCRDataForm.get('plant')?.value,
    };

    this.kpiservice.getEmployeeMasterData(key).then(
      (users: any) => {
        this.raci = users.employeeInfoList.filter((x: any) => {
          return x.EmpInfo_Id;
        });
        this.raci.forEach((element: any) => {
          element.fullName = element.FirstName + ' ' + element.lastName;
        });

        // this.filteredUsers = this.raci.slice();
        this.filterdraci = this.raci.slice();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getTools(): void {
    const key = {
      plantId: this.NCRDataForm.value.plant,
    };
    this.ccrService.mastertools(key).subscribe(
      (response: any) => {
        this.toolslist = response;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  deletencrAction(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          //  ccrId:element.ccrId,
          actionId: element,
        };
        this.ncrService.deletencraction(key).subscribe(
          (success: any) => {
            Swal.fire(
              'Deleted!',
              'Your selected ccr action has been deleted.',
              'success'
            );
            this.getAllNCRAction();
          },
          (error: any) => {
            this.toastrService.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected ccr is safe :)', 'error');
      }
    });
  }

  getncractionbyId(data: any): void {
    // var Id:any[]=[];
    const p = data.responsible.split(',').map(Number);
    this.actionId = data.actionId;
    this.formMode = false;
    this.ActionForm.get('desc')?.setValue(data.actionDesc);
    this.ActionForm.get('date')?.setValue(data.actionDate);
    this.ActionForm.get('toolname')?.setValue(data.toolId);
    // this.ActionForm.get('toolname')?.setValue(this.toolslist.find((x:any) => x.name === data.name));
    this.ActionForm.get('owner')?.setValue(p);
  }

  getPaginator(): void {
    setTimeout(() => {
      this.ncrActionHistoryDataSource.paginator = this.paginator;
      this.ncrActionHistoryDataSource.sort = this.sort;
    }, 1000);
  }
  getPaginator2(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator2.toArray()[index];
      data.sort = this.sort2.toArray()[index];
    }, 1000);
  }
  onToolChange(event: any): void {

    const tool = this.ActionForm.value.toolname;
    this.toolPath = this.toolslist.find(
      (c: any) => c.toolId === event.value
    ).filePath;
    this.toolName = this.toolslist.find(
      (c: any) => c.toolId === event.value
    ).filename;
  }

  capaActionHistory(element: any, showButton: boolean): void {
    this.hideButton = showButton;
    this.dialog.open(this.capaActionHistoryModel, {
      height: 'auto',
      width: '80%',
      disableClose: true,
    });

    this.ncrId = element;
    this.getImplementCapa();
  }
  getImplementCapa() {
    this.ncrService
      .getImplementCapa({ headerId: this.ncr.headerId, ncrId: this.ncrId })
      .subscribe(
        (response: any) => {
          this.ncrActionHistoryDataSource = new MatTableDataSource<any>(
            response
          );
          this.spinner.hide();
        },
        (error: any) => {
          this.toastrService.error('Error', error.error.message);
        }
      );
  }
  getAllNCRAction(): void {
    const data = {
      ncrId: this.ncrId,
      headerId: this.ncr.headerId,
    };
    this.spinner.show();

    this.ncrService.allncrAction(data).subscribe(
      (res) => {
        this.ncrActionHistoryDataSource = new MatTableDataSource<any>(res);
        this.spinner.hide();

        this.getPaginator();
      },
      (error: any) => {
        this.toastrService.error('error', error.message);
      }
    );
  }
  deleteMultipleNcrs(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Multiple NCRs!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        if(this.bulkInstanceKeysForDiscard.length)
          this.deleteDefect(this.bulkInstanceKeysForDiscard.toString(),this.bulkNcrIdsForDelete.toString());
        else
          this.deleteNCR(this.bulkNcrIdsForDelete.toString());
        this.bulkNcrIdsForDelete = [];
        this.bulkInstanceKeysForDiscard = [];
      }
    });
    
  }
  deleteDefect(instanceKey: string,ncrIds: string = this.ncrId.toString()): void {
    const { UserId, UserName } = JSON.parse(localStorage.user);
    const flowInstance = {
      instanceKey,
      userId: UserId.toString(),
      userName: UserName,
    };
    this.flowService.discardInstance(flowInstance).subscribe(
      (response) => {
        this.deleteNCR(ncrIds);
      },
      ({ error }) => {
        this.spinner.hide();
        this.toaster.error('Error', error?.message);
        this.onSearchClick();
      }
    );
  }
  deleteNCR(ncrIds: string){
    const key = {
      ncrId: ncrIds,
    };
    this.ncrService.deleteNcr(key).subscribe(
      (res: any) => {
        Swal.fire('Deleted!','Your selected ncr action has been deleted.','success');
        this.onSearchClick();
      },
      (error: any) => {
        this.toastrService.error('Error', error.message);
        this.onSearchClick();
      }
    );
  }

  onSearchClick(): void {
    const data = {
      plantId: this.NCRDataForm.get('plant')?.value,
      lineId: this.NCRDataForm.get('line')?.value,
      puId: this.NCRDataForm.get('machine')?.value,
      startDate: moment(this.NCRDataForm.get('start')?.value).format(
        'YYYY-MM-DD'
      ),
      endDate: moment(this.NCRDataForm.get('end')?.value).format('YYYY-MM-DD'),
    };
    this.spinner.show();
    this.ncrService.getFilteredData(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.loading = false;
        this.getAllCheckListConfigured();
        this.GetConfigurationData();
        res.Result.forEach((element: any) => {
          if (element.headerId === '0' && !element.rcaDue) {
            element.rcaDue = '';
          }
          //else {
          //   element.rcaDue = 'Yes';
          // }
          if (element.orderProcessId === null) {
            element.orderProcessId = 0;
          }
        });
        let ncrDataFromAssets = JSON.parse(
          localStorage.getItem('ncrDataFromAssets')
        );

        if (this.paramNCRId) {
          this.ncrService.getNCRById({ ncrId: this.paramNCRId }).subscribe(
            (response) => {
              this.dataArray = [...res.Result, ...response];
              this.filteredNCR = response;

              this.firstReload = true;
            },
            (error: any) => {
            }
          );
        } else {
          this.dataArray = res.Result;
        }
        this.NcrDataSource.data = res.Result;
        this.NcrDataSourceList = res.Result;
        this.datasource = res.Result;
        this.path = environment.O3CoreApiImageUrl;
        this.savedPath = environment.O3CoreApiImageUrl;
      },
      (error: any) => {
        this.spinner.hide();
        this.toastrService.error('Error', error.message);
      }
    );
  }

  onFirstDataRendered(params?: any) {
    this.setFilteredRow();
  }

  setFilteredRow() {
    if (this.filteredNCR.length) {
      const instance = this.gridApi.getFilterInstance('ncrIdDesc');
      let ncrIdDesc = this.filteredNCR[0]?.ncrIdDesc;
      let myArray = [ncrIdDesc];
      instance.setModel({
        values: myArray.length ? myArray : [],
      });
      this.gridApi.onFilterChanged();
    }
  }
  resetForm(): void {
    this.AddNCRDataForm.get('processOrder')?.setValue('');
    this.AddNCRDataForm.get('manualUom')?.setValue('');
    this.AddNCRDataForm.get('manualOperationName')?.setValue('');
    this.AddNCRDataForm.get('failureModeDetail')?.setValue('');
    this.AddNCRDataForm.get('desc')?.setValue('');
    this.AddNCRDataForm.get('itemDesc')?.setValue('');
    this.AddNCRDataForm.get('order')?.setValue('');
    this.AddNCRDataForm.get('itemDesc')?.setValue('');
    this.AddNCRDataForm.get('salesOrderNo')?.setValue('');
    this.AddNCRDataForm.get('uom')?.setValue('');
    this.AddNCRDataForm.get('operation')?.setValue('');
    this.AddNCRDataForm.get('fmode')?.setValue('');
    this.AddNCRDataForm.get('hquality')?.setValue('');
    this.AddNCRDataForm.get('qwaste')?.setValue('');
    this.AddNCRDataForm.get('qdesc')?.setValue('');
    this.AddNCRDataForm.get('qpulse')?.setValue('');
    this.AddNCRDataForm.get('process')?.setValue('');
    this.AddNCRDataForm.get('failureModeDetail')?.setValue('');
    this.AddNCRDataForm.get('ncrId')?.setValue('');
    this.AddNCRDataForm.get('image')?.setValue('');
    this.AddNCRDataForm.get('salesOrderNo')?.setValue('');
    this.AddNCRDataForm.get('supplierId')?.setValue(''),
    this.AddNCRDataForm.get('supplierName')?.setValue(''),
    this.AddNCRDataForm.get('customerName')?.setValue('');
    this.AddNCRDataForm.get('area')?.setValue(this.qualityArea[0]?.areaId);
    this.AddNCRDataForm.get('type')?.setValue(this.NcrTypes[0]?.ncrTypeId);
    this.imageSrc = '';
    this.ActionForm.reset();
    this.AddNCRDataForm.get('shift')?.value;
    this.AddNCRDataForm.get('crew')?.value;
    this.AddNCRDataForm.get('causeOfIncident')?.value;
  }

  // menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  excelExport(): void {
    const params = {
      fileName: 'NCR ' + new Date(),
    };
    this.gridOptions.api.exportDataAsExcel(params);
    // this.gridApi.exportDataAsExcel();
  }
  csvExport(): void {
    const params = {
      fileName: 'NCR CSV ' + new Date(),
    };
    this.gridOptions.api.exportDataAsCsv(params);
  }
  openEditFilesModal(ncr: any): void {
    if (ncr.Status === 'Closed')
      //  this.isShow = !this.isShow
      this.filesForm.controls['file'].disable();
    //this.isShow =true;
    else this.filesForm.controls['file'].enable();

    this.Status = ncr.ncrStatus;
    this.getncrfiles(ncr.ncrId);
    this.dialog.open(this.previewModal, { height: 'auto', width: '90%' });
  }

  getncrfiles(ncrIdP: number): void {
    const key = {
      ncrId: ncrIdP,
    };

    this.ncrService.getncrfiles(key).subscribe(
      (listData: any) => {
        this.previewerData = new MatTableDataSource<any>(listData);
        this.getPaginator2(this.previewerData, 1);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  saveNCRFiles(e: any): void {
    this.files = [];
    for (const iterator of e.target.files) {
      this.files.push(iterator);
    }
    const formdata = new FormData();
    for (const s of this.files) {
      formdata.append('file', s);
    }
    const ncr = {
      ncrId: this.ncrId,
    };
    formdata.append('ncr', JSON.stringify(ncr));
    if (this.Status !== closed)
      this.ncrService.addncrfiles(formdata).subscribe(
        (response: any) => {
          this.toaster.success('CCR Files Added Successfuly');
          this.files = [];
          this.filesForm.controls.file.setValue(null, { emitEvent: false });
          // const fileInput = document.querySelector('ngx-mat-file-input[formcontrolname="file"] input[type="file"]' as HTMLInputElement);
          // fileInput.value = null;
          // this.myInputVariable.nativeElement.value = '';
          this.getncrfiles(this.ncrId);
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
          this.loading = false;
        }
      );
  }

  removeFile(ele: any): void {
    const key = {
      ncrFileId: ele.id,
    };
    this.ncrService.deletencrfiles(key).subscribe(
      (response: any) => {
        this.toaster.success('CCR File Delete Successfuly');
        this.loading = false;
        this.fileSave = null;
        this.getncrfiles(this.ncrId);
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.loading = false;
      }
    );
  }

  getNCRWorkFlow(): void {
    const screenName = 'NCR';
    const ptId = this.NCRDataForm.get('plant')?.value;
    const key = {
      screenName,
      tenantId: ptId.toString(),
      companyKey: environment.companyKey,
    };
    this.flowService.getWorkFlow(key).subscribe(
      (response) => {
        this.ncrWorkFlow = response;
        this.getWorkFlowTransitions();
      },
      ({ error }) => {
        this.spinner.hide();
        this.toaster.error('Error', error?.message);
      }
    );
  }

  // deleting multiple instance if exist an error after creating them to set state
  deleteInstances(keys: string[]): void {
    if (keys.length > 0) {
      this.flowService.deleteBulkInstances(keys).subscribe(
        (response) => {
         },
        ({ error }) => {
          this.spinner.hide();
          this.toaster.error('Error', error?.message);
        }
      );
    }
  }

  getBaseDetailsForManualNCR(params: boolean){
    this.spinner.show();
    const data = {
      Date: this.AddNCRDataForm.get('incidentDate')?.value,
      ShiftId: params ? this.AddNCRDataForm.get('shift')?.value : 0
     }
     this.sfdService.GetBaseDetailsForProductionOutput(data).subscribe(
       (response: any)=>{
        if(!params){
          this.shiftListForManualEntry = [];
          this.crewListForManualEntry = [];
          this.shiftListForManualEntry = response.ShiftList;
          this.crewListForManualEntry = response.CrewList;
          if(this.shiftListForManualEntry.length && this.crewListForManualEntry.length){
            this.AddNCRDataForm.get('shift')?.setValue('');
            this.AddNCRDataForm.get('crew')?.setValue('')
          }
          else{
            this.toaster.warning('Warning','No Shift or Crew available for selected Date')
          }
        }
        else{
          this.crewListForManualEntry = [];
          this.crewListForManualEntry = response.CrewList;
          this.AddNCRDataForm.get('crew')?.setValue(this.crewListForManualEntry.length ? this.crewListForManualEntry[0]?.TypeId : '');
        }
        this.spinner.hide();
       },
     )
  }

  configModal(): void {
    this.GetConfigurationData();
    this.dialog.open(this.configurationModal, {
      width: '100%',
      height: 'auto',
      maxHeight: '80vh'
    })
  }
  submitConfiguration(category: number) {
    this.spinner.show();
    let selectedColumns = [];
    if (category === 1) {
      const checkedColumns = this.subHeaderGridApi.getSelectedRows();
      selectedColumns = checkedColumns;
    } if (category === 2) {
      const checkedColumns = this.w2HGridApi.getSelectedRows();
      selectedColumns = checkedColumns;
    } if (category === 3) {
      const checkedColumns = this.fishBoneGridApi.getSelectedRows();
      selectedColumns = checkedColumns;
    } if (category === 4) {
      const checkedColumns = this.causeEffectGridApi.getSelectedRows();
      selectedColumns = checkedColumns;
    } if (category === 5) {
      const checkedColumns = this.why5GridApi.getSelectedRows();
      selectedColumns = checkedColumns;
    } if (category === 6) {
      const checkedColumns = this.actionPlanGridApi.getSelectedRows();
      selectedColumns = checkedColumns;
    }
    const obj = {
      pt_Id: this.NCRDataForm.value.plant,
      category: category,
      columns: selectedColumns
    };
    this.ncrService.submitCheckedNCRreportHeader(obj).subscribe({
      next: (data: any) => {
        this.toaster.success('Success', 'Header/SubHeader Added Successfully');
        this.GetConfigurationData();
        this.spinner.hide()
      },
      error: ({ error }) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    });
  }
  GetConfigurationData() {
    const obj = {
      pt_Id: this.NCRDataForm.value.plant
    }
    this.ncrService.getSubAndResultHeader(obj).subscribe({
      next: (data: any) => {
        this.subHeaderDataSource = [];
        this.w2HDataSource = [];
        this.fishBoneDataSource = [];
        this.causeEffectDataSource = [];
        this.why5DataSource = [];
        this.actionPlanDataSource = [];
        this.foundSubHeader = [];
        this.found5WhyColumns = [];
        this.found6W2HColumns = [];
        this.foundActionPlanColumns = [];
        this.foundCauseEffectColumns = [];
        this.foundFishboneColumns = [];
        data.forEach((elem, i) => {
          if (data[i].category == 1) {
            const hObj = {
              name: elem.name,
              checked: elem.checked,
              h_Id: elem.h_Id,
              category: elem.category,
              pt_Id: this.NCRDataForm.value.plant,
              order: elem.orderNo,
              type: elem.Type,
              fieldName: elem.fieldName
            }
            if (elem.name === 'Group') {
              const grp = this.GroupList.find(x => x.TypeId === this.NCRDataForm.value.groupList);
              hObj['value'] = grp ? grp!.TypeName : ''
            }
            if (elem.name === 'Plant') {
              const pt = this.Plants.find(x => x.DeptId === this.NCRDataForm.value.plant);
              hObj['value'] = pt ? pt!.DeptDesc : ''
            }
            if (elem.name === 'Line') {
              const line = this.Lines.find(x => x.PL_Id === this.NCRDataForm.value.line);
              hObj['value'] = line ? line!.PL_Desc : ''
            }
            if (elem.name === 'From Date') {
              hObj['value'] = moment(this.NCRDataForm.value.start).format('DD/MM/YYYY hh:mm:ss');
            }
            if (elem.name === 'To Date') {
              hObj['value'] = moment(this.NCRDataForm.value.end).format('DD/MM/YYYY hh:mm:ss');
            }
            this.subHeaderDataSource.push(hObj);
          } if (data[i].category == 2) {
            const sObj = {
              name: elem.name,
              checked: elem.checked,
              h_Id: elem.h_Id,
              category: elem.category,
              pt_Id: this.NCRDataForm.value.plant,
              order: elem.orderNo,
              value: '',
              fieldName: elem.fieldName
            }
            this.w2HDataSource.push(sObj);
          } if (data[i].category == 3) {
            const sObj = {
              name: elem.name,
              checked: elem.checked,
              h_Id: elem.h_Id,
              category: elem.category,
              pt_Id: this.NCRDataForm.value.plant,
              order: elem.orderNo,
              value: '',
              fieldName: elem.fieldName
            }
            this.fishBoneDataSource.push(sObj);
          } if (data[i].category == 4) {
            const sObj = {
              name: elem.name,
              checked: elem.checked,
              h_Id: elem.h_Id,
              category: elem.category,
              pt_Id: this.NCRDataForm.value.plant,
              order: elem.orderNo,
              value: '',
              fieldName: elem.fieldName
            }
            this.causeEffectDataSource.push(sObj);
          } if (data[i].category == 5) {
            const sObj = {
              name: elem.name,
              checked: elem.checked,
              h_Id: elem.h_Id,
              category: elem.category,
              pt_Id: this.NCRDataForm.value.plant,
              order: elem.orderNo,
              value: '',
              fieldName: elem.fieldName
            }
            this.why5DataSource.push(sObj);
          } if (data[i].category == 6) {
            const sObj = {
              name: elem.name,
              checked: elem.checked,
              h_Id: elem.h_Id,
              category: elem.category,
              pt_Id: this.NCRDataForm.value.plant,
              order: elem.orderNo,
              value: '',
              fieldName: elem.fieldName
            }
            this.actionPlanDataSource.push(sObj);
          }
        });
        this.plantImage = data[0].plantImage;
        this.foundSubHeader = this.subHeaderDataSource.filter(obj => obj.checked === true && obj.type === 'Sub_Header').map(obj => ({ "name": obj.name, "value": obj.value, "fieldName": obj.fieldName, 'order': obj.order })).sort((a, b) => a.order - b.order);
        this.foundNcrDetail = this.subHeaderDataSource.filter(x => x.checked === true && x.type === 'Ncr_Detail').map(obj => ({ "name": obj.name, "value": obj.value, "fieldName": obj.fieldName, 'order': obj.order })).sort((a, b) => a.order - b.order);
        this.foundRCADetail = this.subHeaderDataSource.filter(x => x.checked === true && x.type === 'RCA_Detail').map(obj => ({ "name": obj.name, "value": obj.value, "fieldName": obj.fieldName, 'order': obj.order })).sort((a, b) => a.order - b.order);
        this.found5WhyColumns = this.why5DataSource.filter(obj => obj.checked === true).map(obj => ({ "name": obj.name, "value": obj.value, 'order': obj.order, "fieldName": obj.fieldName })).sort((a, b) => a.order - b.order);
        this.found6W2HColumns = this.w2HDataSource.filter(obj => obj.checked === true).map(obj => ({ "name": obj.name, "value": obj.value, 'order': obj.order, "fieldName": obj.fieldName })).sort((a, b) => a.order - b.order);
        this.foundActionPlanColumns = this.actionPlanDataSource.filter(obj => obj.checked === true).map(obj => ({ "name": obj.name, "value": obj.value, 'order': obj.order, "fieldName": obj.fieldName })).sort((a, b) => a.order - b.order);
        this.foundCauseEffectColumns = this.causeEffectDataSource.filter(obj => obj.checked === true).map(obj => ({ "name": obj.name, "value": obj.value, 'order': obj.order, "fieldName": obj.fieldName })).sort((a, b) => a.order - b.order);
        this.foundFishboneColumns = this.fishBoneDataSource.filter(obj => obj.checked === true).map(obj => ({ "name": obj.name, "value": obj.value, 'order': obj.order, "fieldName": obj.fieldName })).sort((a, b) => a.order - b.order);
      },
      error: ({ error }) => { }
    })
  }

  onFirstDataRenderedForSubHeader(params: any) {
    params.api.forEachNode((node) =>
      node.setSelected(!!node.data && node.data.checked === true)
    );
  }
  cellClickedForSubHeader(params: any) {
    const data = params.api.gridOptionsWrapper.gridOptions.rowData;
    const selected = params.api.getSelectedRows();
    data.forEach((item) => {
      const d = selected.find(x => x.h_Id === item.h_Id);
      if (!d) {
        item.checked = false;
      }
    });
    params.api.getSelectedRows().forEach((item) => {
      item.checked = true;
    });

  }
  onGridReadySubHeader(params: any): any {
    this.subHeaderGridApi = params.api;
    this.subHeaderColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.subHeaderGridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#agGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }

  onGridReady6W2h(params: any): any {
    this.w2HGridApi = params.api;
    this.w2HColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.w2HGridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#agGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }
  onGridReadyFishbone(params: any): any {
    this.fishBoneGridApi = params.api;
    this.fishboneColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.fishBoneGridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#agGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }
  onGridReadyCauseEffect(params: any): any {
    this.causeEffectGridApi = params.api;
    this.causeEffectColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.causeEffectGridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#agGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }
  onGridReady5Why(params: any): any {
    this.why5GridApi = params.api;
    this.why5ColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.why5GridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#agGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }
  onGridReadyActionPlan(params: any): any {
    this.actionPlanGridApi = params.api;
    this.actionPlanColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.actionPlanGridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#agGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }
  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  GetNcrReportHeaderName(): void {
    this.dialog.open(this.ncrHeaderModal, {
      width: '100%',
      height: 'auto',
      maxHeight: '100vh'
    })
    this.reportHeaderForm.get('name').setValue(this.reportHeaderName);
  }
  getNcrReportName(): void {
    const key = {
      plantId: this.NCRDataForm.value.plant,
      name: this.reportHeaderForm.value.name ? this.reportHeaderForm.value.name : '',
      transactionType: 2
    }
    this.ncrService.getNCRHeader(key).subscribe(
      (response: any) => {
        this.reportHeaderForm.get('name').setValue(response[0]?.Name);
        this.reportHeaderName = response[0]?.Name;
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  saveNCRReportName(): void {
    const key = {
      plantId: this.NCRDataForm.value.plant,
      name: this.reportHeaderForm.value.name ? this.reportHeaderForm.value.name : '',
      transactionType: 1
    }
    this.ncrService.getNCRHeader(key).subscribe(
      (response: any) => {
        this.dialog.closeAll();
        this.reportHeaderName = response[0]?.Name;
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }
  ResetHeaderName(): void {
    this.reportHeaderForm.reset();
  }

  OpenHeaderConfiguration(): void {
    this.ConfigurationForm.reset();
    this.chfformMode = true;
    this.dialog.open(this.HeadersModal, { height: 'auto', width: '90%', maxHeight: '100vh' });
  }
  ResetCheckListConfiguration(): void {
    this.ConfigurationForm.reset();
    this.chfformMode = true;
  }
  getAllCheckListConfigured(): any {
    this.spinner.show();
    const key = {
      nHF_Id: 0,
      pt_Id: this.NCRDataForm.value.plant,
      name: '',
      value: '',
      type: '',
      order: 0,
      transactionType: 3
    };
    this.ncrService.ncrHeaderFooterConfiguration(key).subscribe(
      (response: any) => {
        this.headerArrReport = [];
        this.rightHeaders = [];
        this.bottomHeaders = [];
        this.FooterArrReport = [];
        this.spinner.hide();
        this.rowData2 = response;
        const plantCongData = response;
        plantCongData?.forEach((elem, i) => {
          if (plantCongData[i].type == 1) {
            const objConf = {
              name: elem.name,
              value: elem.value,
              type: elem.type
            }
            this.headerArrReport.push(objConf);
            this.rightHeaders = this.headerArrReport.slice(0, 4)
            this.bottomHeaders = this.headerArrReport.slice(4, this.headerArrReport.length)
          }
          else {
            const objfooter = {
              name: elem.name,
              value: elem.value,
              type: elem.type
            }
            this.FooterArrReport.push(objfooter)
          }
        });
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  SaveCheckListConfiguration(): any {
    this.spinner.show();
    const data = this.rowData2;
    const headerArr = data.filter(obj => obj.type.includes("1")).map(obj => ({ "nhf_Id": obj.nhf_Id, "pt_Id": obj.pt_Id, "name": obj.name, "order": obj.order, "type": obj.type, "value": obj.value }));
    const FooterArr = data.filter(obj => obj.type.includes("2")).map(obj => ({ "nhf_Id": obj.nhf_Id, "pt_Id": obj.pt_Id, "name": obj.name, "order": obj.order, "type": obj.type, "value": obj.value }));
    var hname = headerArr.some(x => x.name == this.ConfigurationForm.value.name && x.nhf_Id != this.nhf_Id);
    var fname = FooterArr.some(x => x.name == this.ConfigurationForm.value.name && x.nhf_Id != this.nhf_Id);

    var Headerresult = data.filter(obj => {
      return obj.type === '1'
    });
    var Headerlength = Headerresult.length;
    var Footerresult = data.filter(obj => {
      return obj.type === '2'
    });
    var Footerlength = Footerresult.length;
    const type = this.ConfigurationForm.value.type;
    if (type === '1') {
      if (hname == true) {
        this.spinner.hide();
        this.toaster.warning('Name Already Exists');
        return
      }
      this.SaveCheckListAPI();
    }
    if (type === '2') {
      if (fname == true) {
        this.spinner.hide();
        this.toaster.warning('Name Already Exists');
        return
      }
      this.SaveCheckListAPI();
    }
  }
  SaveCheckListAPI(): void {
    const key = {
      nhf_Id: 0,
      pt_Id: this.NCRDataForm.value.plant,
      name: this.ConfigurationForm.value.name,
      value: this.ConfigurationForm.value.value,
      type: this.ConfigurationForm.value.type,
      order: this.ConfigurationForm.value.order,
      transactionType: 1
    };
    this.ncrService.ncrHeaderFooterConfiguration(key).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.toaster.success('Data Added Successfuly');
        this.ConfigurationForm.reset();
        this.getAllCheckListConfigured();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  EditChecklistConfiguration(params: any): any {
    this.nhf_Id = params.data.nhf_Id;
    this.ConfigurationForm.get('name').setValue(params.data.name);
    this.ConfigurationForm.get('value').setValue(params.data.value);
    this.ConfigurationForm.get('type').setValue(params.data.type);
    this.ConfigurationForm.get('order').setValue(params.data.order);
    this.chfformMode = false;
  }
  UpdateCheckListConfiguration(): any {
    this.spinner.show();
    const data = this.rowData2;
    const headerArr = data.filter(obj => obj.type.includes("1")).map(obj => ({ "nhf_Id": obj.nhf_Id, "pt_Id": obj.pt_Id, "name": obj.name, "order": obj.order, "type": obj.type, "value": obj.value }));
    const FooterArr = data.filter(obj => obj.type.includes("2")).map(obj => ({ "nhf_Id": obj.nhf_Id, "pt_Id": obj.pt_Id, "name": obj.name, "order": obj.order, "type": obj.type, "value": obj.value }));
    var hname = headerArr.some(x => x.name == this.ConfigurationForm.value.name && x.nhf_Id != this.nhf_Id);
    var fname = FooterArr.some(x => x.name == this.ConfigurationForm.value.name && x.nhf_Id != this.nhf_Id);

    var Headerresult = data.filter(obj => {
      return obj.type === '1'
    });
    var Headerlength = Headerresult.length;
    var Footerresult = data.filter(obj => {
      return obj.type === '2'
    });
    var Footerlength = Footerresult.length;
    const type = this.ConfigurationForm.value.type;
    if (type === '1') {
      if (hname == true) {
        this.spinner.hide();
        this.toaster.warning('Name Already Exists');
        return
      }
      else {
        this.UpdateCheckListAPI();
      }
    }
    if (type === '2') {
      if (fname == true) {
        this.spinner.hide();
        this.toaster.warning('Name Already Exists');
        return
      }
      else {
        this.UpdateCheckListAPI();
      }
    }

  }
  UpdateCheckListAPI(): void {
    const key = {
      nhf_Id: this.nhf_Id,
      pt_Id: this.NCRDataForm.value.plant,
      name: this.ConfigurationForm.value.name,
      value: this.ConfigurationForm.value.value,
      type: this.ConfigurationForm.value.type,
      order: this.ConfigurationForm.value.order,
      transactionType: 2
    };
    this.ncrService.ncrHeaderFooterConfiguration(key).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.toaster.success('Data Updated Successfuly');
        this.ConfigurationForm.reset();
        this.getAllCheckListConfigured();
        this.chfformMode = true;
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  onGridReady1(params: any): any {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    params.api.sizeColumnsToFit();
    this.gridApi1.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#agGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }
  cellClickedForConfiguration(params: any) {
    const col = params.colDef.field;
    if (col === 'action') {
      this.ButtonType = params.event.target.textContent;
      if (this.ButtonType === 'delete') {
        this.DeleteChecklistConfigured(params);
      }
      if (this.ButtonType === 'edit') {
        this.EditChecklistConfiguration(params);
      }
    }
  }
  DeleteChecklistConfigured(params: any): any {
    const id = params.data.nhf_Id;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          nhf_Id: id,
          pt_Id: this.NCRDataForm.value.plant,
          name: '',
          value: '',
          type: '',
          order: 0,
          transactionType: 4
        };
        this.ncrService.ncrHeaderFooterConfiguration(key).subscribe(
          (response: any) => {
            this.spinner.hide();
            this.getAllCheckListConfigured();
            this.toaster.success('Data Deleted Successfully')
          },
          (error: any) => {
            this.spinner.hide();
            this.toaster.error('Error');
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Data is safe :)', 'error');
      }
    });
  }

  async checkPermission(actionName) {
    const { UserId, UserName } = JSON.parse(localStorage.user);

    const permission = {
      UserId: UserId.toString(),
      transitionKey: this.transitions.find(
        (status: any) => status?.actionName === actionName
      )?.transitionKey
    }
    const response = await this.flowService.getUserPermissionByTransition(permission).toPromise();
    return response
  }

  //----------Report Method----------//
  getreportData(data: any): void {
    const key = { ncrId: data.ncrId };
    this.spinner.show();
    this.isPageBreak = false;
    this.ncrService.getNcrReportData(key).subscribe({
      next: (res) => {
        this.spinner.hide();
        if (!res.data) { res.data = {} }
        if (res) {
          if (res.data) {
            this.foundSubHeader.forEach((e: any) => {
              if (e.name === 'Group') {
                e.value = res.data.Group;
              }
              if (e.name === 'Plant') {
                e.value = res.data.Plant;
              }
              if (e.name === 'Line') {
                e.value = res.data.Line;
              }
              if (e.name === 'Unit') {
                e.value = res.data.Unit;
              }
            });
          }
          this.foundRCADetail.forEach((ele) => {
            if (res.data.hasOwnProperty(ele.fieldName)) {
              if (ele.fieldName === 'createdOn' || ele.fieldName === 'updatedOn' || ele.fieldName === 'CompletionDate') {
                ele.value = res.data[ele.fieldName] ? moment(res.data[ele.fieldName]).format('DD-MM-YYYY HH:mm:ss') : '';
              } else if (ele.fieldName === 'ID') {
                ele.value = res.data[ele.fieldName] ? 'AT-RCA-' + res.data[ele.fieldName] : '';
              } else {
                ele.value = res.data[ele.fieldName];
              }
            }
            if (ele.fieldName === 'RefocusedProblem') {
              ele.value = res.data?.swth ? res.data?.swth.ProblemStatement : res.data?.fishBone && res.data?.fishBone.ProblemStatement ? res.data?.fishBone.ProblemStatement : '';

            }
          });

          this.ncrReportDataMapping(data);
          this.historicalMType = res.mType;
          if (res.data.reportFiles) {
            res.data.reportFiles = res.data.reportFiles.filter(x => ['PNG', 'png', 'jpg', 'jpeg', 'tiff', 'bmp', 'gif', 'eps', 'webp', 'svg'].includes(x.Type));
          }
          this.createReport(res, data.rcaDue);
        }
      },
      error: ({ error }) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    });
  }
  ncrReportDataMapping(data: any) {
    this.foundNcrDetail.forEach((ele) => {
      if (data.hasOwnProperty(ele.fieldName)) {
        if (ele.fieldName === 'createdOn' || ele.fieldName === 'updatedOn' || ele.fieldName === 'CompletionDate'
            || ele.fieldName === 'IncidentDate') 
        {
          ele.value = data[ele.fieldName] ? moment(data[ele.fieldName]).format('DD-MM-YYYY') : '';
        } 
        else if(ele.fieldName === 'StartTime' || ele.fieldName === 'EndTime'){
          ele.value = data[ele.fieldName] ? moment(data[ele.fieldName]).format('DD-MM-YYYY HH:mm') : '';
        }else {
          ele.value = data[ele.fieldName];
        }
      }
    });
  }
  createReport(params: any, rca: any): void {
    let mainContainer = document.createElement('table');
    mainContainer.style.width = '95%';
    mainContainer.style.tableLayout = 'auto';
    mainContainer.style.marginLeft = '50%';
    mainContainer.id = 'mainChecklistHistoryTable';
    mainContainer.style.transform = 'translateX(-50%)';
    let itemHeaderContainer = document.createElement('table');
    itemHeaderContainer.id = 'dynamicTable';
    itemHeaderContainer.innerHTML = '';
    let HeaderColspanHeading = this.rowData2.length > 0 ? 2 : 3;
    // Create Header Dynamic
    let headerObj;
    if (this.rowData2?.length > 0) {
      if (this.headerArrReport.length > 0) {
        headerObj = this.createHeaders();
        itemHeaderContainer.appendChild(headerObj.td);
      }
    }
    (mainContainer as HTMLElement).innerHTML = `<tr style='height:70px;' >
    <td style='border: 1px solid black; width: 20%; height: 70px; background-color: #fff;'><img style='width: 99%; height: 100%;' src='${this.plantImage ? this.plantImage : '/assets/images/logo-secondary.svg'}'></td>
    <td class='noPadding' colspan="${HeaderColspanHeading}">
    <div style='border-bottom:0px solid black; display: flex; justify-content: center; align-items: center;font-weight: 600; font-size: 1.5rem; padding: 1rem;text-align: center;'>${this.reportHeaderName ? this.reportHeaderName : 'NCR RCA Report'}</div>
    </td>
    ${itemHeaderContainer.innerHTML}
    </tr>`

    let emptyDiv = document.createElement('div');
    emptyDiv.className = 'emptyDiv';
    document.getElementById('reportContainer').innerHTML = '';
    document.getElementById('reportContainer').style.display = 'none';
    document.getElementById('reportContainer').appendChild(emptyDiv);
    document.getElementById('reportContainer').appendChild(mainContainer);
    var reportData = document.getElementById('reportContainer');
    //-------bttom header---------//
    if (headerObj?.row?.innerHTML) {
      let divBottom = document.createElement('div');
      let bottomHeaderTable = document.createElement('table');
      bottomHeaderTable.style.width = '95%';
      bottomHeaderTable.style.tableLayout = 'auto';
      bottomHeaderTable.style.marginLeft = '50%';
      bottomHeaderTable.classList.add('mainChecklistHistoryTable');
      bottomHeaderTable.style.transform = 'translateX(-50%)';
      (bottomHeaderTable as HTMLElement).innerHTML = `${headerObj?.row?.innerHTML}`;
      divBottom.appendChild(bottomHeaderTable);
      reportData.innerHTML += divBottom.innerHTML;
    }
    //-------End bttom header---------//
    //---------Ncr detail------------//
    if (this.foundNcrDetail?.length > 0) {
      let divNcr = document.createElement('div');
      let ncrTable = document.createElement('table');
      ncrTable.style.width = '95%';
      ncrTable.style.tableLayout = 'auto';
      ncrTable.style.marginLeft = '50%';
      ncrTable.classList.add('mainChecklistHistoryTable');
      ncrTable.style.transform = 'translateX(-50%)';
      (ncrTable as HTMLElement).innerHTML = this.craeteNcrDetailResult();
      divNcr.appendChild(ncrTable);
      reportData.innerHTML += divNcr.innerHTML;
    }
    //---------End Ncr detail------------//
    //---------RCA detail------------//
    if (this.foundRCADetail?.length > 0 && (['Yes', 'Critical', 'Minor', 'Major'].includes(rca))) {
      let divRca = document.createElement('div');
      let rcaTable = document.createElement('table');
      rcaTable.style.width = '95%';
      rcaTable.style.tableLayout = 'auto';
      rcaTable.style.marginLeft = '50%';
      rcaTable.classList.add('mainChecklistHistoryTable');
      rcaTable.style.transform = 'translateX(-50%)';
      (rcaTable as HTMLElement).innerHTML = this.createRCADetail();
      divRca.appendChild(rcaTable);
      reportData.innerHTML += divRca.innerHTML;
    }
    //---------End RCA detail------------//
    //---------Rca Tables Detail----------//
    const WindowPrt: any = window.open('', '_blank');
    if (['Yes', 'Critical', 'Minor', 'Major'].includes(rca)) {
      reportData.innerHTML += this.Create6W2HResult(params, rca);
      reportData.innerHTML += this.CreateFishBoneResult(params, rca);
      reportData.innerHTML += this.CreateCauseAndEffectResult(params, rca);
      reportData.innerHTML += this.Create5WhyResult(params, rca);
      reportData.innerHTML += this.CreateActionPlanResult(params);
    }
    //---------End Rca Tables Detail----------//
    //------------Footer-------------//
    if (this.FooterArrReport.length > 0) {
      let div = document.createElement('div');
      let footerTable = document.createElement('table');
      footerTable.style.width = '95%';
      footerTable.style.tableLayout = 'auto';
      footerTable.style.marginLeft = '50%';
      footerTable.classList.add('mainChecklistHistoryTable');
      footerTable.style.transform = 'translateX(-50%)';
      (footerTable as HTMLElement).innerHTML = `${this.createFooter()}`;
      div.appendChild(footerTable);
      reportData.innerHTML += div.innerHTML;
    }
    //----------End Footer-----------//
    //----------Ncr Images-----------//
    if (params?.data?.reportFiles && params?.data?.reportFiles.length > 0) {
      reportData.innerHTML += this.createNcrReportFiles(params);
      this.isPageBreak = true;
    }
    //----------End Ncr Images-------//
    //----------RCA Images---------//
    if (['Yes', 'Critical', 'Minor', 'Major'].includes(rca)) {
      if (params.data?.fishBone == null) {
        const obj = {
          UploadFile: ""
        }
        params.data.fishBone = obj;
      }
      if ((params.data?.UploadFile && !params.data?.fishBone.UploadFile) || (!params.data?.UploadFile && params.data?.fishBone.UploadFile)) {
        if (params.data?.UploadFile) {

          reportData.innerHTML += this.createRCAFiles(params);
          this.isPageBreak = true;
        }
        if (params.data?.fishBone.UploadFile) {

          reportData.innerHTML += this.createFishboneFiles(params);
          this.isPageBreak = true;
        }
      }
      else if (params.data?.UploadFile && params.data?.fishBone.UploadFile && params.data?.UploadFile != "" && params.data?.fishBone.UploadFile != "") {
        reportData.innerHTML += this.createRCAandFishboneFiles(params);
        this.isPageBreak = true;
      }
      let CountImages = 0;
      params.data?.causeAndEffects.forEach(element => {

        if (!element.UploadFile) {
          CountImages++;
        }
      });
      if (params.data?.causeAndEffects.length > 0 && params.data?.causeAndEffects.length != CountImages) {

        reportData.innerHTML += this.createCauseEffectFiles(params.data?.causeAndEffects);
        this.isPageBreak = true;
      }

      let CountImagesFivewhy = 0;
      params.data?.fiveWhyActionPlan.forEach(element => {

        if (!element.UploadFile) {
          CountImagesFivewhy++;
        }
      });
      if (params.data?.fiveWhyActionPlan.length > 0 && params.data?.fiveWhyActionPlan.length != CountImagesFivewhy) {

        reportData.innerHTML += this.createFiveWhyActionFiles(params.data?.fiveWhyActionPlan);
        this.isPageBreak = true;
      }
    }
    //----------End RCA Images---------//
    WindowPrt.document.write(this.getStyle());
    WindowPrt.document.write(reportData.innerHTML);
    WindowPrt.document.title = 'NCR/RCA Report';
    this.spinner.hide();
    setTimeout(() => {
      WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();
      WindowPrt.close();
    }, 3000);
  }
  getStyle(): any {
    let style = `<style>
    table {
      border-collapse: collapse;
      
    }
    table,
    td,
    thead,
    tbody,
    tr {
      border: 1px solid black;
      font-family: system-ui;
      font-size:12px;
    }
    .header-side-info {
    }
    .header-side-info-heading {
      font-size: 12px;
    }
    .header-side-info-text {
      font-size: 10px;
    }
    .header-heading {
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      background-color: #BEEDAA;
      color: black;
      -webkit-print-color-adjust: exact;
    }
    .tr_ > td {
      font-size: 12px;
      padding: 2px 10px;
    }
    .header-cell-heading {
      font-weight: bold;
      font-size:12px;
    }
    .tb-heading {
      font-size: 11px;
      background-color: rgb(114, 1, 124);
      color: white;
      font-weight: bold;
      text-align: center;
      -webkit-print-color-adjust: exact;
      padding: 2px 5px;
    }
    .tb-value {
      font-size: 10px;
      text-align: center;
    }
    .bgColor{
      background-color: #BEEDAA;
    }
    .filesTable{}
    .filesTable {
        border: none !important;
      }
      .emptyDiv{
        display: none;
      }
      
    @media print {
      body{
        margin: 0 !important;
      }
      table {
        border-collapse: collapse;
        width: 100% !important;
        
      }
      .row-text{
        word-break: break-word;
      }
      .row-text-center{
        word-break: break-word;
      }
      .emptyDiv{
        display: block;
        height: 17px;
        page-break-before: always;
      }
      .filesTable td:nth-child(1){
        padding-right: 10px;
      }
      .filesTable {        
        border: none !important;
      }
      .bgColor{
        background-color: #BEEDAA;
        -webkit-print-color-adjust: exact;
      }
      .mainChecklistHistoryTable {zoom: 0.6;  }
      
            .itemGroupContainer {background: #F1F5F9}
            @page {
              margin-top: 0;
              margin-bottom: 0;
          }
      table,
      td,
      thead,
      tbody,
      tr {
        border: 1px solid black;
        font-family: system-ui;
      }
      .header-side-info {
      }
      .header-side-info-heading {
        font-size: 12px;
      }
      .header-side-info-text {
        font-size: 10px;
      }
      .header-heading {
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        background-color: #BEEDAA;
        color: black;
        -webkit-print-color-adjust: exact;
      }
      .tr_ > td {
        font-size: 12px;
        padding: 2px 10px;
      }
      .header-cell-heading {
        font-weight: bold;
      }
      .tb-heading {
        font-size: 11px;
        background-color: rgb(114, 1, 124);
        color: white;
        font-weight: bold;
        text-align: center;
        -webkit-print-color-adjust: exact;
        padding: 2px 5px;
      }
      .tb-value{
        font-size: 11px;
        padding: 2px 5px;
        text-align: center;
        :last-child{
          padding: 10px 5px;
        }
      }
      .six-bgc{
        background: #ffb194;
        -webkit-print-color-adjust: exact; 
      }
      .six-sub-bgc{
        background: #ffd1c0;
        -webkit-print-color-adjust: exact; 
      }
      .fish-bgc{
        background: #a4d5ff;
        -webkit-print-color-adjust: exact; 
      }
      .fish-sub-bgc{
        background: #cce8ff;
        -webkit-print-color-adjust: exact; 
      }
      .cause-bgc{
        background: #ffb95f;
        -webkit-print-color-adjust: exact; 
      }
      .cause-sub-bgc{
        background: #ffcd8c;
        -webkit-print-color-adjust: exact; 
      }
      .five-bgc{
        background: #cfa4cf;
        -webkit-print-color-adjust: exact; 
      }
      .five-sub-bgc{
        background: #ebc5eb;
        -webkit-print-color-adjust: exact; 
      }
      .five-action-bgc{
        background: lightpink;
        -webkit-print-color-adjust: exact; 
      }
      .five-sub-action-bgc{
        background: #ffcbd3;
        -webkit-print-color-adjust: exact; 
      }
    }
    
    .six-bgc{
      background: #ffb194;
    }
    .six-sub-bgc{
      background: #ffd1c0;
    }
    .fish-bgc{
      background: #a4d5ff;
    }
    .fish-sub-bgc{
      background: #cce8ff;
    }
    .cause-bgc{
      background: #ffb95f;
    }
    .cause-sub-bgc{
      background: #ffcd8c;
    }
    .five-bgc{
      background: #cfa4cf;
    }
    .five-sub-bgc{
      background: #ebc5eb;
    }
    .five-action-bgc{
      background: lightpink;
    }
    .five-sub-action-bgc{
      background: #ffcbd3;
    }
    .heading-text{
      font-weight:bold;font-size:13px;text-align:center;
    }
    .heading-sub-text{
      font-weight:bold;font-size:13px;
    }
    .row-text{
      font-size:13px;
      word-break: break-word;
    }
    .row-text-center{
      font-size:13px;text-align:center;
      word-break: break-word;
    }
    </style>
    `;
    return style
  }
  createHeaders(): any {
    let headerArray = this.headerArrReport.slice();
    let ObjHeader = {
      row: null,
      td: null
    }
    var productionOutContainer = document.createElement('div');
    productionOutContainer.innerHTML = '';
    var count = 0;
    if (this.headerArrReport.length > 0) {
      let itemtd = document.createElement('td');
      itemtd.style.width = '20%';
      itemtd.style.border = '1px solid black';
      itemtd.rowSpan = 1;
      itemtd.style.padding = '0rem';
      itemtd.classList.add('noPadding');
      ObjHeader.row = document.createElement('tr');
      if (headerArray.length > 4) {
        for (let i = 0; i < 4; i++) {
          let s = document.createElement('div');
          s.style.display = 'flex';
          s.style.flexDirection = 'column';
          s.style.padding = '0.5rem';
          if (i < headerArray.length - 1) {
            s.style.borderBottom = '1px solid black';
          }
          s.innerHTML = "<div><span style='font-weight: 600'>" + headerArray[0].name + ":</span> " + (headerArray[0].value ? headerArray[0].value : '') + "</div>";
          itemtd.appendChild(s);
          headerArray?.splice(headerArray[0], 1);
        }
        if (headerArray?.length > 0) {
          for (var data of headerArray) {
            if (count == 0) {
              var tr = document.createElement('tr');
              tr.classList.add('dynamicTD');
              var td = document.createElement('td');
              td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
              td.style.border = '1px solid black';
              td.style.padding = '0.2rem';
              tr.appendChild(td);
              productionOutContainer.appendChild(tr);
            }
            else {
              if (count <= 3) {
                var td = document.createElement('td');
                td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
                td.style.border = '1px solid black';
                td.style.padding = '0.2rem';
                for (var i = 0; i < productionOutContainer.children.length; i++) {
                  if (i == productionOutContainer.children.length - 1 && productionOutContainer.children[i].classList.contains('dynamicTD')) {
                    productionOutContainer.children[i].appendChild(td);
                  }
                }
              }
              else {
                count = 0;
                var tr = document.createElement('tr');
                tr.classList.add('dynamicTD');
                var td = document.createElement('td');
                td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
                td.style.border = '1px solid black';
                td.style.padding = '0.2rem';
                tr.appendChild(td);
                productionOutContainer.appendChild(tr);
              }
            }
            count++;
            for (var i = 0; i < productionOutContainer.children.length; i++) {
              if (productionOutContainer.children[i].classList.contains('dynamicTD')) {
                var childrenCount = productionOutContainer.children[i].children.length;
                var fixedColspan = 4 / childrenCount;
                for (var j = 0; j < productionOutContainer.children[i].children.length; j++) {
                  if (childrenCount % 2 != 0) {
                    (productionOutContainer.children[i].children[0] as HTMLTableCellElement).colSpan = fixedColspan;
                    break;
                  }
                  (productionOutContainer.children[i].children[j] as HTMLTableCellElement).colSpan = fixedColspan;
                }
              }
            }
          }
          ObjHeader.row.append(productionOutContainer);
        }
      }
      else {
        let legth = headerArray?.length;
        for (let i = 0; i < legth; i++) {
          let s = document.createElement('div');
          s.style.display = 'flex';
          s.style.flexDirection = 'column';
          if (i < headerArray.length - 1) {
            s.style.borderBottom = '1px solid black';
          }
          s.style.padding = '0.5rem';
          s.innerHTML = "<div><span style='font-weight: 600'>" + headerArray[i].name + ":</span> " + (headerArray[i].value ? headerArray[i].value : '') + "</div>";
          itemtd.appendChild(s);
        }
      }
      ObjHeader.td = itemtd
    }
    return ObjHeader;
  }
  createRCADetail(): any {
    var productionOutContainer = document.createElement('div');
    productionOutContainer.innerHTML = '';
    var count = 0;
    let trHead = document.createElement('tr');
    let tdHead = document.createElement('td')
    tdHead.colSpan = 4;
    tdHead.className = 'header-heading';
    tdHead.innerText = 'Root Cause Analysis';
    trHead.append(tdHead);
    productionOutContainer.appendChild(trHead);
    for (var data of this.foundRCADetail) {
      if (count == 0) {
        var tr = document.createElement('tr');
        tr.classList.add('dynamicTD');
        var td = document.createElement('td');
        if (data.fieldName === 'UploadFile') {
          td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> <img style="width:20px;height:20px" alt="Image" src="' + (data.value ? data.value : '/assets/images/no-image.png') + '" >';
        } else {
          td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
        }
        td.style.border = '1px solid black';
        td.style.padding = '0.2rem';
        tr.appendChild(td);
        productionOutContainer.appendChild(tr);
      }
      else {
        if (count <= 3) {
          var td = document.createElement('td');
          if (data.fieldName === 'UploadFile') {
            td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> <img style="width:20px;height:20px" alt="Image" src="' + (data.value ? data.value : '/assets/images/no-image.png') + '" >';
          } else {
            td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
          }
          td.style.border = '1px solid black';
          td.style.padding = '0.2rem';
          for (var i = 0; i < productionOutContainer.children.length; i++) {
            if (i == productionOutContainer.children.length - 1 && productionOutContainer.children[i].classList.contains('dynamicTD')) {
              productionOutContainer.children[i].appendChild(td);
            }
          }
        }
        else {
          count = 0;
          var tr = document.createElement('tr');
          tr.classList.add('dynamicTD');
          var td = document.createElement('td');
          if (data.fieldName === 'UploadFile') {
            td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> <img style="width:20px;height:20px" alt="Image" src="' + (data.value ? data.value : '/assets/images/no-image.png') + '" >';
          } else {
            td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
          }
          td.style.border = '1px solid black';
          td.style.padding = '0.2rem';
          tr.appendChild(td);
          productionOutContainer.appendChild(tr);
        }
      }
      count++;
      for (var i = 0; i < productionOutContainer.children.length; i++) {
        if (productionOutContainer.children[i].classList.contains('dynamicTD')) {
          var childrenCount = productionOutContainer.children[i].children.length;
          var fixedColspan = 4 / childrenCount;
          for (var j = 0; j < productionOutContainer.children[i].children.length; j++) {
            if (childrenCount % 2 != 0) {
              if (childrenCount === 3) {
                (productionOutContainer.children[i].children[0] as HTMLTableCellElement).colSpan = fixedColspan;
                (productionOutContainer.children[i].children[1] as HTMLTableCellElement).colSpan = fixedColspan;
                (productionOutContainer.children[i].children[2] as HTMLTableCellElement).colSpan = 2;
                break;
              } else {
                (productionOutContainer.children[i].children[0] as HTMLTableCellElement).colSpan = fixedColspan;
                break;
              }
            }
            (productionOutContainer.children[i].children[j] as HTMLTableCellElement).colSpan = fixedColspan;
          }
        }
      }
    }
    return productionOutContainer.innerHTML
  }
  craeteNcrDetailResult(): any {
    var productionOutContainer = document.createElement('div');
    productionOutContainer.innerHTML = '';
    var count = 0;
    let trHead = document.createElement('tr');
    let tdHead = document.createElement('td')
    tdHead.colSpan = 4;
    tdHead.className = 'header-heading';
    tdHead.innerText = 'NCR Detail';
    trHead.append(tdHead);
    productionOutContainer.appendChild(trHead);
    for (var data of this.foundNcrDetail) {
      if (count == 0) {
        var tr = document.createElement('tr');
        tr.classList.add('dynamicTD');
        var td = document.createElement('td');
        td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
        td.style.border = '1px solid black';
        td.style.padding = '0.2rem';
        tr.appendChild(td);
        productionOutContainer.appendChild(tr);
      }
      else {
        if (count <= 3) {
          var td = document.createElement('td');
          td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
          td.style.border = '1px solid black';
          td.style.padding = '0.2rem';
          for (var i = 0; i < productionOutContainer.children.length; i++) {
            if (i == productionOutContainer.children.length - 1 && productionOutContainer.children[i].classList.contains('dynamicTD')) {
              productionOutContainer.children[i].appendChild(td);
            }
          }
        }
        else {
          count = 0;
          var tr = document.createElement('tr');
          tr.classList.add('dynamicTD');
          var td = document.createElement('td');
          td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
          td.style.border = '1px solid black';
          td.style.padding = '0.2rem';
          tr.appendChild(td);
          productionOutContainer.appendChild(tr);
        }
      }
      count++;
      for (var i = 0; i < productionOutContainer.children.length; i++) {
        if (productionOutContainer.children[i].classList.contains('dynamicTD')) {
          var childrenCount = productionOutContainer.children[i].children.length;
          var fixedColspan = 4 / childrenCount;
          for (var j = 0; j < productionOutContainer.children[i].children.length; j++) {
            if (childrenCount % 2 != 0) {
              if (childrenCount === 3) {
                (productionOutContainer.children[i].children[0] as HTMLTableCellElement).colSpan = fixedColspan;
                (productionOutContainer.children[i].children[1] as HTMLTableCellElement).colSpan = fixedColspan;
                (productionOutContainer.children[i].children[2] as HTMLTableCellElement).colSpan = 2;
                break;
              } else {
                (productionOutContainer.children[i].children[0] as HTMLTableCellElement).colSpan = fixedColspan;
                break;
              }
            }
            (productionOutContainer.children[i].children[j] as HTMLTableCellElement).colSpan = fixedColspan;
          }
        }
      }
    }
    return productionOutContainer.innerHTML
  }
  Create6W2HResult(params: any, rca: any): any {
    this.viewStageData = params.data;
    let sixWtwoHTableRow: any = '';
    if (this.viewStageData.swth && this.found6W2HColumns.length > 0) {
      sixWtwoHTableRow = `
      <table class = "mainChecklistHistoryTable" style="width: 95%; table-layout: auto; margin-left: 50%; transform: translateX(-50%);">
      <tbody>
        <tr class="six-bgc">
          <td colspan="${this.found6W2HColumns.length}" class="heading-text">6W2H</td>           
        </tr>`;
      sixWtwoHTableRow += `<tr class="six-sub-bgc">`;
      for (let i = 0; i < this.found6W2HColumns.length; i++) {
        sixWtwoHTableRow += `<td class="heading-text">${this.found6W2HColumns[i].name}</td>`
      }
      sixWtwoHTableRow += `</tr>`;
      sixWtwoHTableRow += `<tr class="row-text-center">`;
      for (const item of this.found6W2HColumns) {
        sixWtwoHTableRow += `<td class="row-text">${this.viewStageData.swth[item.name] ? this.viewStageData.swth[item.name] : ''}</td>`
      }
      sixWtwoHTableRow += `</tr> </tbody> </table>`;
    }
    return sixWtwoHTableRow;
  }
  CreateFishBoneResult(params: any, rca: any): any {
    this.viewStageData = params.data;
    let fishBone: any = '';
    let Machine: any = '';
    let Material: any = '';
    let Man: any = '';
    let Method: any = '';
    let MotherNature: any = '';
    let Management: any = '';
    let count = 1;
    if (this.viewStageData.fishBone && this.foundFishboneColumns.length > 0) {
      let MachineList = this.historicalMType.filter(
        (m: any) => m.MType == 1
      );
      let MaterialList = this.historicalMType.filter(
        (m: any) => m.MType == 2
      );
      let ManList = this.historicalMType.filter((m: any) => m.MType == 3);
      let MethodList = this.historicalMType.filter(
        (m: any) => m.MType == 4
      );
      let MotherNatureList = this.historicalMType.filter(
        (m: any) => m.MType == 5
      );
      let ManagementList = this.historicalMType.filter(
        (m: any) => m.MType == 6
      );

      let MachineValueArr =
        this.viewStageData &&
          this.viewStageData.fishBone &&
          this.viewStageData.fishBone.Machine
          ? this.viewStageData.fishBone.Machine.split(',').map(
            (num: any) => (num = parseInt(num))
          )
          : [];
      let MaterialValueArr =
        this.viewStageData &&
          this.viewStageData.fishBone &&
          this.viewStageData.fishBone.Material
          ? this.viewStageData.fishBone.Material.split(',').map(
            (num: any) => (num = parseInt(num))
          )
          : [];
      let ManValueArr =
        this.viewStageData &&
          this.viewStageData.fishBone &&
          this.viewStageData.fishBone.Man
          ? this.viewStageData.fishBone.Man.split(',').map(
            (num: any) => (num = parseInt(num))
          )
          : [];
      let MethodValueArr =
        this.viewStageData &&
          this.viewStageData.fishBone &&
          this.viewStageData.fishBone.Method
          ? this.viewStageData.fishBone.Method.split(',').map(
            (num: any) => (num = parseInt(num))
          )
          : [];
      let MotherNatureValueArr =
        this.viewStageData &&
          this.viewStageData.fishBone &&
          this.viewStageData.fishBone.MotherNature
          ? this.viewStageData.fishBone.MotherNature.split(',').map(
            (num: any) => (num = parseInt(num))
          )
          : [];
      let ManagementValueArr =
        this.viewStageData &&
          this.viewStageData.fishBone &&
          this.viewStageData.fishBone.Management
          ? this.viewStageData.fishBone.Management.split(',').map(
            (num: any) => (num = parseInt(num))
          )
          : [];

      if (MachineValueArr && MachineValueArr.length) {
        for (const machineValue of MachineValueArr) {
          let machineObj = MachineList.find(
            (m1: any) => m1.ID == machineValue
          );
          Machine += machineObj ? machineObj.Name + ' , ' : '';
        }
        Machine = Machine.substring(0, Machine.length - 2);
      }

      if (MaterialValueArr && MaterialValueArr.length) {
        for (const materialValue of MaterialValueArr) {
          let materialObj = MaterialList.find(
            (m2: any) => m2.ID == materialValue
          );
          Material += materialObj ? materialObj.Name + ' , ' : '';
        }
        Material = Material.substring(0, Material.length - 2);
      }

      if (ManValueArr && ManValueArr.length) {
        for (const manValue of ManValueArr) {
          let manObj = ManList.find((m3: any) => m3.ID == manValue);
          Man += manObj ? manObj.Name + ' , ' : '';
        }
        Man = Man.substring(0, Man.length - 2);
      }

      if (MethodValueArr && MethodValueArr.length) {
        for (const methodValue of MethodValueArr) {
          let methodObj = MethodList.find(
            (m4: any) => m4.ID == methodValue
          );
          Method += methodObj ? methodObj.Name + ' , ' : '';
        }
        Method = Method.substring(0, Method.length - 2);
      }

      if (MotherNatureValueArr && MotherNatureValueArr.length) {
        for (const motherNatureValue of MotherNatureValueArr) {
          let motherNatureObj = MotherNatureList.find(
            (m5: any) => m5.ID == motherNatureValue
          );
          MotherNature += motherNatureObj
            ? motherNatureObj.Name + ' , '
            : '';
        }
        MotherNature = MotherNature.substring(
          0,
          MotherNature.length - 2
        );
      }

      if (ManagementValueArr && ManagementValueArr.length) {
        for (const managementValue of ManagementValueArr) {
          let managementObj = ManagementList.find(
            (m6: any) => m6.ID == managementValue
          );
          Management += managementObj ? managementObj.Name + ' , ' : '';
        }
        Management = Management.substring(
          0,
          Management.length - 2
        );
      }

      const fishBoneData = {
        'Machine': Machine,
        'Method': Method,
        'Material': Material,
        'MotherNature': MotherNature,
        'Man': Man,
        'Management': Management
      };
      fishBone = `<table class = "mainChecklistHistoryTable" style="width: 95%; table-layout: auto; margin-left: 50%; transform: translateX(-50%);">
          <tbody>
          <tr class="fish-bgc">
            <td colspan="17" class="heading-text">Fishbone</td>           
          </tr>`;
      if (this.foundFishboneColumns.length === 3) {
        fishBone += `<tr>
                <td colspan="4" class="heading-sub-text fish-sub-bgc">${this.foundFishboneColumns[0].name} : </td>  
                <td colspan="8" class="row-text">
                ${fishBoneData[this.foundFishboneColumns[0].name.replaceAll(' ', '')] ? fishBoneData[this.foundFishboneColumns[0].name.replaceAll(' ', '')] : ''}
                </td></tr> 
                `;
        fishBone += `<tr>
                <td colspan="4" class="heading-sub-text fish-sub-bgc">${this.foundFishboneColumns[1].name} : </td>  
                <td colspan="8" class="row-text">
                ${fishBoneData[this.foundFishboneColumns[1].name.replaceAll(' ', '')] ? fishBoneData[this.foundFishboneColumns[1].name.replaceAll(' ', '')] : ''}
                </td></tr> 
                `;
        fishBone += `<tr>
                <td colspan="4" class="heading-sub-text fish-sub-bgc">${this.foundFishboneColumns[2].name} : </td>  
                <td colspan="8" class="row-text">
                ${fishBoneData[this.foundFishboneColumns[2].name.replaceAll(' ', '')] ? fishBoneData[this.foundFishboneColumns[2].name.replaceAll(' ', '')] : ''}
                </td></tr> 
                `;
      }
      else {
        for (let i = 0; i < this.foundFishboneColumns.length; i++) {
          if (count === 1) {
            fishBone += `<tr>`;
          }
          if (count <= 2) {
            if (this.foundFishboneColumns.length === 5) {
              if (i === 4) {
                fishBone += `
                    <td colspan="2.5" class="heading-sub-text fish-sub-bgc">${this.foundFishboneColumns[i].name} : </td>  
                    <td colspan="10" class="row-text">
                    ${fishBoneData[this.foundFishboneColumns[i].name.replaceAll(' ', '')] ? fishBoneData[this.foundFishboneColumns[i].name.replaceAll(' ', '')] : ''}
                    </td> 
                    `;
              } else {
                fishBone += `
                    <td colspan="2.5" class="heading-sub-text fish-sub-bgc">${this.foundFishboneColumns[i].name} : </td>  
                    <td colspan="6" class="row-text">
                    ${fishBoneData[this.foundFishboneColumns[i].name.replaceAll(' ', '')] ? fishBoneData[this.foundFishboneColumns[i].name.replaceAll(' ', '')] : ''}
                    </td> 
                    `;
              }
            } else {
              fishBone += `
                  <td colspan="2.5" class="heading-sub-text fish-sub-bgc">${this.foundFishboneColumns[i].name} : </td>  
                  <td colspan="6" class="row-text">
                  ${fishBoneData[this.foundFishboneColumns[i].name.replaceAll(' ', '')] ? fishBoneData[this.foundFishboneColumns[i].name.replaceAll(' ', '')] : ''}
                  </td> 
                  `;
            }
            count++;
          }
          if (count === 3) {
            fishBone += `</tr>`;
            count = 1;
          }
          if (i === this.foundFishboneColumns.length - 1) {
            fishBone += `</tr>`;
          }
        }
      }
      fishBone += `</tbody> </table>`;
    }
    return fishBone;
  }
  CreateCauseAndEffectResult(params: any, rca: any): any {
    this.viewStageData = params.data;
    let causeEffectTable: any = '';
    const colSpanObj = {
      'ContributingSource': 4,
      'Cause': 3,
      'ValidationResult': 3,
      'Remark': 3
    };
    if (this.viewStageData.causeAndEffects.length > 0 && this.foundCauseEffectColumns.length > 0) {
      this.viewStageData.causeAndEffects.sort(function (a: any, b: any) {
        return a.Priority - b.Priority;
      });
      this.viewStageData.causeAndEffects.sort(function (a: any, b: any) {
        return a.MType.MType - b.MType.MType;
      });
      causeEffectTable += `<table class = "mainChecklistHistoryTable" style="width: 95%; table-layout: auto; margin-left: 50%; transform: translateX(-50%);">
                           <tbody>
                           <tr class="cause-bgc">
                           <td colspan="17" class="heading-text">Cause & Effect</td>           
                           </tr>
                           <tr class="cause-sub-bgc">
                           `;
      for (let i = 0; i < this.foundCauseEffectColumns.length; i++) {
        causeEffectTable += `<td colspan="${colSpanObj[this.foundCauseEffectColumns[i].name.replaceAll(' ', '')]}" class="heading-text">${this.foundCauseEffectColumns[i].name}</td>`;
      }
      causeEffectTable += `</tr>`;
      for (const cause of this.viewStageData.causeAndEffects) {
        if (cause.MType.MType == 1) cause.ContributingSource = 'Machine';
        else if (cause.MType.MType == 2) cause.ContributingSource = 'Material';
        else if (cause.MType.MType == 3) cause.ContributingSource = 'Man';
        else if (cause.MType.MType == 4) cause.ContributingSource = 'Method';
        else if (cause.MType.MType == 5)
          cause.ContributingSource = 'Mother Nature';
        else if (cause.MType.MType == 6) cause.ContributingSource = 'Management';

        causeEffectTable += `<tr>`;
        for (let i = 0; i < this.foundCauseEffectColumns.length; i++) {
          if (this.foundCauseEffectColumns[i].fieldName === 'Image') {
            causeEffectTable += `<td class="row-text-center">  
            <img src='${cause[this.foundCauseEffectColumns[i].name.replaceAll(' ', '')] ? cause[this.foundCauseEffectColumns[i].name.replaceAll(' ', '')] : '/assets/images/no-image.png'}' 
            style='width:20px;height:20px' alt='Image' *ngIf="${cause.UploadFile != null}"></td>`;
          } else if (this.foundCauseEffectColumns[i].name === 'Cause Identified') {
            causeEffectTable += `<td colspan="${colSpanObj[this.foundCauseEffectColumns[i].name.replaceAll(' ', '')]}" class="row-text-center">
            ${cause[this.foundCauseEffectColumns[i].name.replaceAll(' ', '')] ? cause[this.foundCauseEffectColumns[i].name.replaceAll(' ', '')] === 0 ? 'false' : 'true' : ''}
            </td>`;
          } else {
            causeEffectTable += `<td colspan="${colSpanObj[this.foundCauseEffectColumns[i].name.replaceAll(' ', '')]}" class="row-text-center">
            ${cause[this.foundCauseEffectColumns[i].name.replaceAll(' ', '')] ? cause[this.foundCauseEffectColumns[i].name.replaceAll(' ', '')] : ''}
            </td>`;
          }
        }
        causeEffectTable += `</tr>`;
      }
      causeEffectTable += `</tbody> </table>`;
    }
    return causeEffectTable;
  }
  Create5WhyResult(params: any, rca: any): any {
    this.viewStageData = params.data;
    let fiveWhyTable: any = '';
    const colSpanObj = {
      'Wh1': 2,
      'Why2': 2,
      'Why3': 2,
      'Why4': 2,
      'Why5': 2
    };
    if (this.viewStageData.fiveWhy.length > 0 && this.found5WhyColumns.length > 0) {
      this.viewStageData.fiveWhy.sort(function (a: any, b: any) {
        return a.Priority - b.Priority;
      });
      this.viewStageData.fiveWhy.sort(function (a: any, b: any) {
        return a.MType - b.MType;
      });
      fiveWhyTable += `<table class = "mainChecklistHistoryTable" style="width: 95%; table-layout: auto; margin-left: 50%; transform: translateX(-50%);">
                      <tbody>
                      <tr class="five-bgc">
                      <td colspan="17" class="heading-text">5 Why</td>           
                      </tr>
                      <tr class="five-sub-bgc">
                      `;
      for (let i = 0; i < this.found5WhyColumns.length; i++) {
        fiveWhyTable += `<td colspan="${colSpanObj[this.found5WhyColumns[i].name.replaceAll(' ', '')]}" class="heading-text">${this.found5WhyColumns[i].name}</td>`;
      }
      fiveWhyTable += `</tr>`;
      for (const five of this.viewStageData.fiveWhy) {
        if (five.MType == 1) five.ContributingSource = 'Machine';
        else if (five.MType == 2) five.ContributingSource = 'Material';
        else if (five.MType == 3) five.ContributingSource = 'Man';
        else if (five.MType == 4) five.ContributingSource = 'Method';
        else if (five.MType == 5) five.ContributingSource = 'Mother Nature';
        else if (five.MType == 6) five.ContributingSource = 'Management';

        const mRecords = this.viewStageData.fiveWhy.filter(
          (fiveY: any) =>
            five.MType == fiveY.MType && five.Priority == fiveY.Priority
        );
        if (mRecords && mRecords.length) {
          let disabled = mRecords.map((rec: any, index: number) => {
            if (index == 0)
              rec.rowSpan =
                mRecords && mRecords.length ? mRecords.length : 1;
            else rec.spanRow = true;
          });
        }
        const display = five.spanRow ? 'd-none' : '';
        fiveWhyTable += `<tr>`;
        for (let i = 0; i < this.found5WhyColumns.length; i++) {
          fiveWhyTable += `<td colspan="${colSpanObj[this.found5WhyColumns[i].name.replaceAll(' ', '')]}" class="row-text-center" rowspan="">
          ${five[this.found5WhyColumns[i].name.replaceAll(' ', '')] ? five[this.found5WhyColumns[i].name.replaceAll(' ', '') === 'MType' ? 'TypeOfM' : this.found5WhyColumns[i].name.replaceAll(' ', '')] : ''}</td>`
        }
        fiveWhyTable += `</tr>`;
      }
      fiveWhyTable += `</tbody> </table>`;
    }
    return fiveWhyTable;
  }
  CreateActionPlanResult(params: any): any {
    this.viewStageData = params.data;
    let actionPlanTable: any = '';
    if (this.viewStageData.fiveWhyActionPlan.length > 0 && this.foundActionPlanColumns.length > 0) {
      actionPlanTable += `<table class = "actionPlanTable mainChecklistHistoryTable" style="width: 95%; table-layout: fixed; margin-left: 50%; transform: translateX(-50%); word-break: break-all;">
                          <tbody>
                          <tr class="five-action-bgc">
                            <td colspan="17" class="heading-text">5 Why Action Plan</td>           
                          </tr>
                          <tr class="five-sub-action-bgc">
                          `;
      for (let i = 0; i < this.foundActionPlanColumns.length; i++) {
        actionPlanTable += `<td class="heading-text">${this.foundActionPlanColumns[i].name}</td>`;
      }
      for (const fiveAction of this.viewStageData.fiveWhyActionPlan) {
        fiveAction.AssignedDate = fiveAction.AssignedDate
          ? moment(fiveAction.AssignedDate).format('DD/MM/YYYY') : '';
        fiveAction.TargetDate = fiveAction.TargetDate
          ? moment(fiveAction.TargetDate).format('DD/MM/YYYY') : '';
        fiveAction.RevisedDate = fiveAction.RevisedDate
          ? moment(fiveAction.RevisedDate).format('DD/MM/YYYY') : '';
        fiveAction.ClosedDate = fiveAction.ClosedDate
          ? moment(fiveAction.ClosedDate).format('DD/MM/YYYY') : '';
        fiveAction.ActionCompletedOn = fiveAction.ActionCompletedOn
          ? moment(fiveAction.ActionCompletedOn).format('DD/MM/YYYY') : '';
        fiveAction.VerificationOn = fiveAction.VerificationOn
          ? moment(fiveAction.VerificationOn).format('DD/MM/YYYY') : '';
        if (fiveAction.fiveWhy && fiveAction.fiveWhy.ID) {
          const mRecords = this.viewStageData.fiveWhyActionPlan.filter(
            (fiveY: any) =>
              fiveY.fiveWhy && fiveY.fiveWhy.ID == fiveAction.fiveWhy.ID
          );
          if (mRecords && mRecords.length) {
            let disabled = mRecords.map((rec: any, index: number) => {
              if (index == 0)
                rec.rowSpan =
                  mRecords && mRecords.length ? mRecords.length : 1;
              else {
                rec.spanRow = true;
              }
            });
          }
        }

        const display = fiveAction.spanRow ? 'd-none' : '';
        actionPlanTable += `<tr>`;
        for (let i = 0; i < this.foundActionPlanColumns.length; i++) {
          if (this.foundActionPlanColumns[i].name.replaceAll(' ', '') === 'ActionCompleted(By&On)') {
            actionPlanTable += `
              <td class="row-text-center"><span class="d-block">${this.getTeamName(
              fiveAction.ActionCompletedBy,
              'ActionCompletedBy'
            )}</span></br><span class="d-block">${fiveAction.ActionCompletedOn
              ? fiveAction.ActionCompletedOn : ''
              }</span></td>
            `;
          } else if (this.foundActionPlanColumns[i].name.replaceAll(' ', '') === 'Verification(By&On)') {
            actionPlanTable += `
              <td class="row-text-center"><span class="d-block">${this.getTeamName(
              fiveAction.VerificationBy,
              'VerificationBy'
            )}</span></br><span class="d-block">${fiveAction.VerificationOn
              ? fiveAction.VerificationOn : ''
              }</span></td>
            `;
          } else if (this.foundActionPlanColumns[i].fieldName === 'Image') {
            actionPlanTable += `<td class="row-text-center">  
            <img src='${fiveAction[this.foundActionPlanColumns[i].name.replaceAll(' ', '')] ? fiveAction[this.foundActionPlanColumns[i].name.replaceAll(' ', '')] : '/assets/images/no-image.png'}'
            style='width:20px;height:20px' alt='Image' *ngIf="${fiveAction.UploadFile != null}"></td>`;
          }
          else {
            actionPlanTable += `<td class="row-text-center">${this.getTeamName(
              fiveAction[this.foundActionPlanColumns[i].name.replaceAll(' ', '')],
              this.foundActionPlanColumns[i].name.replaceAll(' ', '')
            )}</td>`;
          }
        }
        actionPlanTable += `</tr>`;
      }
      actionPlanTable += `</tbody> </table>`;
    }
    return actionPlanTable;
  }
  createFooter(): any {
    var productionOutContainer = document.createElement('div');
    productionOutContainer.innerHTML = '';
    var count = 0;
    for (var data of this.FooterArrReport) {
      if (count == 0) {
        var tr = document.createElement('tr');
        tr.classList.add('dynamicTD');
        var td = document.createElement('td');
        if (data.fieldName === 'UploadFile') {
          td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> <img style="width:20px;height:20px" alt="Image" src="' + (data.value ? data.value : '/assets/images/no-image.png') + '" >';
        } else {
          td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
        }
        td.style.border = '1px solid black';
        td.style.padding = '0.2rem';
        tr.appendChild(td);
        productionOutContainer.appendChild(tr);
      }
      else {
        if (count <= 3) {
          var td = document.createElement('td');
          if (data.fieldName === 'UploadFile') {
            td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> <img style="width:20px;height:20px" alt="Image" src="' + (data.value ? data.value : '/assets/images/no-image.png') + '" >';
          } else {
            td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
          }
          td.style.border = '1px solid black';
          td.style.padding = '0.2rem';
          for (var i = 0; i < productionOutContainer.children.length; i++) {
            if (i == productionOutContainer.children.length - 1 && productionOutContainer.children[i].classList.contains('dynamicTD')) {
              productionOutContainer.children[i].appendChild(td);
            }
          }
        }
        else {
          count = 0;
          var tr = document.createElement('tr');
          tr.classList.add('dynamicTD');
          var td = document.createElement('td');
          if (data.fieldName === 'UploadFile') {
            td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> <img style="width:20px;height:20px" alt="Image" src="' + (data.value ? data.value : '/assets/images/no-image.png') + '" >';
          } else {
            td.innerHTML = "<span style= 'font-weight: 600'>" + data.name + ':</span> ' + (data.value ? data.value : '');
          }
          td.style.border = '1px solid black';
          td.style.padding = '0.2rem';
          tr.appendChild(td);
          productionOutContainer.appendChild(tr);
        }
      }
      count++;
      for (var i = 0; i < productionOutContainer.children.length; i++) {
        if (productionOutContainer.children[i].classList.contains('dynamicTD')) {
          var childrenCount = productionOutContainer.children[i].children.length;
          var fixedColspan = 4 / childrenCount;
          for (var j = 0; j < productionOutContainer.children[i].children.length; j++) {
            if (childrenCount % 2 != 0) {
              if (childrenCount === 3) {
                (productionOutContainer.children[i].children[0] as HTMLTableCellElement).colSpan = fixedColspan;
                (productionOutContainer.children[i].children[1] as HTMLTableCellElement).colSpan = fixedColspan;
                (productionOutContainer.children[i].children[2] as HTMLTableCellElement).colSpan = 2;
                break;
              } else {
                (productionOutContainer.children[i].children[0] as HTMLTableCellElement).colSpan = fixedColspan;
                break;
              }
            }
            (productionOutContainer.children[i].children[j] as HTMLTableCellElement).colSpan = fixedColspan;
          }
        }
      }
    }
    return productionOutContainer.innerHTML;
  }
  createNcrReportFiles(params: any): any {
    let filesTable = '';
    if (this.isPageBreak == false) {
      filesTable = `<div class="emptyDiv"></div>`;
    }
    filesTable += ` 
                  <table class = "filesTable mainChecklistHistoryTable" style="width: 95%; table-layout: fixed; margin-left: 50%; transform: translateX(-50%); border: none !important;word-break: break-all;">
                    <tbody style="border: none !important;">
                    <div class="bgColor" style="border: 1px solid black !important;width: 95%; margin-left: 50%;transform: translateX(-50%);display: flex;height: 25px; align-items: center;">
                      <div colspan="17" style="width: 100%;" class="heading-text">NCR Images</div>           
                      </div>
                      <tr style="border: none !important;">
                      <td style="border: none !important;">&nbsp;</td>
                      <td style="border: none !important;">&nbsp;</td>
                      </tr>
                    `;
    const totalImage = params?.data.reportFiles.length;
    let total = params?.data.reportFiles.length;
    let no = total >= 2 ? 2 : total;
    let rowArray = [];
    for (let i = 0; i < params?.data.reportFiles.length; i++) {
      if (rowArray.length === 0) {
        filesTable += '<div style="border: none !important;justify-content: center;display: flex; width: 95%;transform: translateX(-50%);margin-left: 50%;flex-wrap: wrap;">'
      }
      if (['PNG', 'png', 'jpg', 'jpeg'].includes(params?.data.reportFiles[i].Type)) {
        filesTable += `<div style="text-align: center;border: none !important;width: 50%;" colspan="9">
                     <img style="width:100%;height:16rem;object-fit: fill;" alt="Image" src='${params?.data.reportFiles[i].FilePath ? this.imageUrl + params?.data.reportFiles[i].FilePath : '/assets/images/no-image.png'}'>
                    </div>`;
        rowArray.push({ text: '' });
      }

      if (no < 2) {
        if ((i + 1) == totalImage) {
          for (let j = 0; j < 2 - no; j++) {
            rowArray.push({ text: '' });
          }
        }
      }
      if (rowArray.length === 2) {
        filesTable += '</div>';
        rowArray = [];
        total = total - no;
        no = total >= 2 ? 2 : total;
      }
    }
    filesTable += '</tbody> </table>';
    return filesTable;
  }
  createRCAFiles(params: any): any {
    let filesTable = '';
    if (this.isPageBreak == false) {
      filesTable = `<div class="emptyDiv"></div>`;
    }
    filesTable += `
                  <table class = "filesTable mainChecklistHistoryTable" style="width: 95%; table-layout: fixed; margin-left: 50%; transform: translateX(-50%); border: none !important;word-break: break-all;">
                    <tbody style="border: none !important;">
                    <div class="bgColor" style="border: 1px solid black !important;width: 95%; margin-left: 50%;transform: translateX(-50%);display: flex;height: 25px; align-items: center;">
                      <div colspan="17" style="width: 100%;" class="heading-text">RCA Images</div>           
                      </div>
                      <tr style="border: none !important;">
                      <td style="border: none !important;">&nbsp;</td>
                      <td style="border: none !important;">&nbsp;</td>
                      </tr>
                    `;
    const totalImage = 1;
    let total = 1;
    let no = total >= 2 ? 2 : total;
    let rowArray = [];
    for (let i = 0; i < 1; i++) {
      if (rowArray.length === 0) {
        filesTable += '<div style="border: none !important;justify-content: center;display: flex; width: 95%;transform: translateX(-50%);margin-left: 50%;flex-wrap: wrap;">'
      }
      if (['PNG', 'png', 'jpg', 'jpeg'].includes(params?.data.UploadFile.split('.').pop())) {
        filesTable += `<div style="text-align: center;border: none !important;width: 50%;" colspan="9">
                     <img style="width:100%;height:15rem; object-fit: fill;" alt="Image" src='${params?.data.UploadFile ? this.imageUrl + params?.data.UploadFile : '/assets/images/no-image.png'}'>
                    </div>`;
        rowArray.push({ text: '' });
      }

      if (no < 2) {
        if ((i + 1) == totalImage) {
          for (let j = 0; j < 2 - no; j++) {
            //filesTable += '<td></td>';
            rowArray.push({ text: '' });
          }
        }
      }
      if (rowArray.length === 2) {
        filesTable += '</div>';
        rowArray = [];
        total = total - no;
        no = total >= 2 ? 2 : total;
      }
    }
    filesTable += '</tbody> </table>';
    return filesTable;
  }
  createFishboneFiles(params: any): any {
    let filesTable = '';
    if (this.isPageBreak == false) {
      filesTable = `<div class="emptyDiv"></div>`;
    }
    filesTable += `
                  <table class = "filesTable mainChecklistHistoryTable" style="width: 95%; table-layout: fixed; margin-left: 50%; transform: translateX(-50%); border: none !important;word-break: break-all;">
                    <tbody style="border: none !important;">
                    <div class="bgColor" style="border: 1px solid black !important;width: 95%; margin-left: 50%;transform: translateX(-50%);display: flex;height: 25px; align-items: center;">
                      <div colspan="17" style="width: 100%;" class="heading-text">Fishbone Images</div>           
                      </div>
                      <tr style="border: none !important;">
                      <td style="border: none !important;">&nbsp;</td>
                      <td style="border: none !important;">&nbsp;</td>
                      </tr>
                    `;
    const totalImage = 1;
    let total = 1;
    let no = total >= 2 ? 2 : total;
    let rowArray = [];
    for (let i = 0; i < 1; i++) {
      if (rowArray.length === 0) {
        filesTable += '<div style="border: none !important;justify-content: center;display: flex; width: 95%;transform: translateX(-50%);margin-left: 50%;flex-wrap: wrap;">'
      }
      if (['PNG', 'png', 'jpg', 'jpeg'].includes(params?.data.fishBone.UploadFile.split('.').pop())) {
        filesTable += `<div style="text-align: center;border: none !important;width: 50%;" colspan="9">
                     <img style="width:100%;height:16rem; object-fit: fill;" alt="Image" src='${params?.data.fishBone.UploadFile ? this.imageUrl + params?.data.fishBone.UploadFile : '/assets/images/no-image.png'}'>
                    </div>`;
        rowArray.push({ text: '' });
      }
      if (no < 2) {
        if ((i + 1) == totalImage) {
          for (let j = 0; j < 2 - no; j++) {
            rowArray.push({ text: '' });
          }
        }
      }
      if (rowArray.length === 2) {
        filesTable += '</div>';
        rowArray = [];
        total = total - no;
        no = total >= 2 ? 2 : total;
      }
    }
    filesTable += '</tbody> </table>';
    return filesTable;
  }
  createRCAandFishboneFiles(params: any): any {
    let filesTable = '';
    if (this.isPageBreak == false) {
      filesTable = `<div class="emptyDiv"></div>`;
    }
    filesTable += `
                  <table class = "filesTable mainChecklistHistoryTable" style="width: 95%; table-layout: fixed; margin-left: 50%; transform: translateX(-50%); border: none !important;word-break: break-all;">
                    <tbody style="border: none !important;">
                    <div class="bgColor" style="border: 1px solid black !important;width: 95%; margin-left: 50%;transform: translateX(-50%);display: flex; height: 25px; align-items: center;">
                      <div colspan="8"  class="heading-text" style="width: 50%;">RCA Images</div>
                      <div  colspan="9"  class="heading-text" style="width: 50%;">Fishbone Images</div>           
                      </div>
                      <tr style="border: none !important;">
                      <td style="border: none !important;">&nbsp;</td>
                      <td style="border: none !important;">&nbsp;</td>
                      
                      </tr>
                    `;
    const totalImage = 1;
    let total = 1;
    let no = total >= 2 ? 2 : total;
    let rowArray = [];
    for (let i = 0; i < 1; i++) {
      if (rowArray.length === 0) {
        filesTable += '<div style="border: none !important;justify-content: center;display: flex; width: 95%;transform: translateX(-50%);margin-left: 50%;flex-wrap: wrap;align-items: center;">'
      }
      if (['PNG', 'png', 'jpg', 'jpeg'].includes(params?.data.UploadFile.split('.').pop()) && ['PNG', 'png', 'jpg', 'jpeg'].includes(params?.data.fishBone.UploadFile.split('.').pop())) {
        filesTable += `<div style="text-align: center;border: none !important;width: 50%;"colspan="8">
                     <img style="width:100%;height:16rem; object-fit: fill;" alt="Image" src='${params?.data.UploadFile ? this.imageUrl + params?.data.UploadFile : '/assets/images/no-image.png'}'>
                    </div>`;

        filesTable += `<div style="text-align: center;border: none !important;width: 50%;" colspan="9">
          <img style="width:100%;height:16rem; object-fit: fill;" alt="Image" src='${params?.data.fishBone.UploadFile ? this.imageUrl + params?.data.fishBone.UploadFile : '/assets/images/no-image.png'}'>
           </div>`;
        rowArray.push({ text: '' });
      }
      if (no < 2) {
        if ((i + 1) == totalImage) {
          for (let j = 0; j < 2 - no; j++) {
            rowArray.push({ text: '' });
          }
        }
      }
      if (rowArray.length === 2) {
        filesTable += '</div>';
        rowArray = [];
        total = total - no;
        no = total >= 2 ? 2 : total;
      }
    }
    filesTable += '</tbody> </table>';
    return filesTable;
  }
  createCauseEffectFiles(params: any): any {
    let filesTable = '';
    if (this.isPageBreak == false) {
    }
    filesTable += `
                  <table class = "filesTable mainChecklistHistoryTable" style="width: 95%; table-layout: fixed; margin-left: 50%; transform: translateX(-50%); border: none !important;word-break: break-all;">
                    <tbody style="border: none !important;">
                    <div class="bgColor" style="border: none !important;justify-content: center;display: flex; width: 95%;transform: translateX(-50%);margin-left: 50%;flex-wrap: wrap;height: 25px; align-items: center;">
                      <div colspan="17" class="heading-text" style="width: 100%;">Cause & Effect Images</div>           
                      </div>
                      <tr style="border: none !important;">
                      <td style="border: none !important;">&nbsp;</td>
                      <td style="border: none !important;">&nbsp;</td>
                      </tr>
                    `;
    const totalImage = params?.length;
    let total = params?.length;
    let no = total >= 2 ? 2 : total;
    let rowArray = [];
    for (let i = 0; i < params?.length; i++) {
      if (rowArray.length === 0) {
        filesTable += '<div style="border: none !important;justify-content: center;display: flex; width: 95%;transform: translateX(-50%);margin-left: 50%;flex-wrap: wrap;align-items: center;">'
      }
      if (['PNG', 'png', 'jpg', 'jpeg'].includes(params[i]?.UploadFile.split('.').pop())) {
        filesTable += `<div style="text-align: center;border: none !important;width: 50%;" colspan="8">
                     <img style="width:100%;height:16rem;  object-fit: fill;" alt="Image" src='${params[i]?.UploadFile ? this.imageUrl +  params[i]?.UploadFile : '/assets/images/no-image.png'}'>
                    </div>`;
        rowArray.push({ text: '' });
      }
      if (no < 2) {
        if ((i + 1) == totalImage) {
          for (let j = 0; j < 2 - no; j++) {
            rowArray.push({ text: '' });
          }
        }
      }
      if (rowArray.length === 2) {
        filesTable += '</tr>';
        rowArray = [];
        total = total - no;
        no = total >= 2 ? 2 : total;
      }
    }
    filesTable += '</tbody> </table>';
    return filesTable;
  }
  createFiveWhyActionFiles(params: any): any {
    let filesTable = '';
    if (this.isPageBreak == false) {
      filesTable = `<div class="emptyDiv"></div>`;
    }
    filesTable += `
                  <table class = "filesTable mainChecklistHistoryTable" style="width: 95%; table-layout: fixed; margin-left: 50%; transform: translateX(-50%); border: none !important;word-break: break-all;">
                    <tbody style="border: none !important;">
                    <div class="bgColor" style="border: none !important;justify-content: center;display: flex; width: 95%;transform: translateX(-50%);margin-left: 50%;flex-wrap: wrap;height: 25px; align-items: center;">
                      <div colspan="17" class="heading-text" style="width: 100%;">Five Why Action Plan Images</div>           
                      </div>
                      <tr style="border: none !important;">
                      <td style="border: none !important;">&nbsp;</td>
                      <td style="border: none !important;">&nbsp;</td>
                      </tr>
                    `;
    const totalImage = params?.length;
    let total = params?.length;
    let no = total >= 2 ? 2 : total;
    let rowArray = [];
    for (let i = 0; i < params?.length; i++) {
      if (rowArray.length === 0) {
        filesTable += '<div style="border: none !important;justify-content: center;display: flex; width: 95%;transform: translateX(-50%);margin-left: 50%;flex-wrap: wrap; align-items: center;">'
      }
      if (['PNG', 'png', 'jpg', 'jpeg'].includes(params[i]?.UploadFile.split('.').pop())) {
        filesTable += `<div style="text-align: center;border: none !important;width: 50%;" colspan="8">
                     <img style="width:100%;height:16rem;" alt="Image" src='${params[i]?.UploadFile ? this.imageUrl + params[i]?.UploadFile : '/assets/images/no-image.png'}'>
                    </div>`;
        rowArray.push({ text: '' });
      }
      if (no < 2) {
        if ((i + 1) == totalImage) {
          for (let j = 0; j < 2 - no; j++) {
            rowArray.push({ text: '' });
          }
        }
      }
      if (rowArray.length === 2) {
        filesTable += '</div>';
        rowArray = [];
        total = total - no;
        no = total >= 2 ? 2 : total;
      }
    }
    filesTable += '</tbody> </table>';
    return filesTable;
  }
  getTeamName(val: any, type: any) {
    if (type == 'AssignTo') {
      let objAssignName = this.teamList.find(
        (usr: any) => usr.EmpInfo_Id == val
      );
      if (objAssignName) return objAssignName.fullName;
      else return '';
    } else if (type == 'NewAssignee') {
      let objNewAssignName = this.teamList.find(
        (usr: any) => usr.EmpInfo_Id == val
      );
      if (objNewAssignName) return objNewAssignName.fullName;
      else return '';
    } if (type == 'ActionCompletedBy') {
      let objFindName = this.userAllList.find(
        (usr: any) => usr.UserId == val
      );
      if (objFindName) return objFindName.UserName;
      else if (val == 100001) return 'Admin';
      else return '';
    } else if (type == 'VerificationBy') {
      let objFindName = this.userAllList.find(
        (usr: any) => usr.UserId == val
      );
      if (objFindName) return objFindName.UserName;
      else if (val == 100001) return 'Admin';
      else return '';
    } else if (type == 'VerifiedBy') {
      let objFindName = this.userAllList.find(
        (usr: any) => usr.UserId == val
      );
      if (objFindName) return objFindName.UserName;
      else if (val == 100001) return 'Admin';
      else return '';
    }
    else {
      return (val ? val : '');
    }
  }
  getTeamList() {
    const data = {
      EGId: this.NCRDataForm.value.groupList,
      PlantId: this.NCRDataForm.value.plant,
    };
    this.commonService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        this.teamList = res.employeeInfoList;
        this.teamList.map(
          (user: any) =>
            (user.FullName = user.FirstName.trim() + ' ' + user.lastName.trim())
        );
      },
      (err: any) => {
      }
    );
  }
  getAllUserList() {
    this.managerExecutionService.getUserList().subscribe(
      (res: any) => {
        this.userAllList = res.UserList;
        this.spinner.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }
  //----------End Report Method------//

  get f() {
    return this.NCRDataForm.controls;
  }
  get NCRF() {
    return this.AddNCRDataForm.controls;
  }
}
