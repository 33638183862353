<form (ngSubmit)="submitFormHandler($event)">
    <div class="mb-2">
      <input
        class="form-check-input"
        type="radio"
        name="orientation"
        id="landscape"
        value="landscape"
        [checked]="PDF_PAGE_ORITENTATION === 'landscape'"
      />
      <label class="form-check-label" for="landscape">
        Landscape
      </label>
      <input
        class="form-check-input"
        type="radio"
        name="orientation"
        id="portrait"
        value="portrait"
        [checked]="PDF_PAGE_ORITENTATION === 'portrait'"
      />
      <label class="form-check-label" for="portrait">
        Portrait
      </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="headerImage"
        [checked]="PDF_WITH_HEADER_IMAGE"
      />
      <label class="form-check-label" for="headerImage">
        Header image (ag-Grid logo)
      </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="footerPageCount"
        [checked]="PDF_WITH_FOOTER_PAGE_COUNT"
      />
      <label class="form-check-label" for="footerPageCount">
        Footer (page count)
      </label>
    </div>
    <div class="my-2">
      <input
        type="number"
        id="headerRowHeight"
        style="width: 50px; margin-right: 5px"
        [value]="PDF_HEADER_HEIGHT"
      />
      <label for="headerRowHeight">Header height</label>
    </div>
    <div class="my-2">
      <input
        type="number"
        id="cellRowHeight"
        style="width: 50px; margin-right: 5px"
        [value]="PDF_ROW_HEIGHT"
      />
      <label for="cellRowHeight">Cell height</label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="exportWithFormatting"
        [checked]="PDF_WITH_CELL_FORMATTING"
      />
      <label class="form-check-label" for="exportWithFormatting">
        Cell styles
      </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="exportColumnsAsLink"
        [checked]="PDF_WITH_COLUMNS_AS_LINKS"
      />
      <label class="form-check-label" for="exportColumnsAsLink">
        Hyperlinks
      </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="selectedRowsOnly"
        [checked]="PDF_SELECTED_ROWS_ONLY"
      />
      <label class="form-check-label" for="selectedRowsOnly">
        Selected rows only
      </label>
    </div>
    <button type="submit" color="accent" mat-button mat-flat-button>
      Export to PDF
    </button>
  </form>
