<!-- Start Accordian -->
<br>
<mat-accordion style="margin-top: 10px;">
<!-- Start Daily Consumption -->
<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header class="acc1 disable_ripple_acc1">
        <mat-panel-title>Daily Consumption</mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="dailyConsumptionGroup" action="">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <div class="content-top-row">
                <div class="inputField">
                    <nz-date-picker class="calender-input" id="picker" formControlName="Date"
                    [nzDisabledDate]="" nzFormat="yyyy-MM-dd hh:mm"
                    nzShowTime [nzInputReadOnly]="true" readonly></nz-date-picker>
                    <span class="floating-text" style="margin-left: 18px;">Date</span>
                  </div>
                
                <!-- <div class="inputField">
                    <nz-date-picker class="calender-input" id="timePicker" formControlName="Date"
                    nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true">
                     </nz-date-picker>
                    <span class="floating-text">{{'ProcessToDate' | translate}}</span>
                  </div> -->
             
             
                <button type="button" mat-raised-button color="warn"
                style="background-color: #0f0fd1;font-size: 14px!important; margin-left: 20px;height:40px;margin-top: 5px;"  (click)="GetDailyConsumption()">
                <span style="font-size: 12px;color: white;">RETRIEVE</span></button>
            </div>
            <div style="width: 100%;margin-top: 20px;">
                <mat-card-content>
                  <ag-grid-angular style="width: 100%;height: 400px;" class="ag-theme-alpine" [rowData]="rowDailyConsumption"
                  [columnDefs]="columnDefsDailyConsumption" [defaultColDef]="defaultColDefDailyConsumption" (firstDataRendered)="onFirstDataRendered($event)" [pagination]="true" [paginationPageSize]="10">
                  </ag-grid-angular>
                </mat-card-content>
            </div>
        </div>
    </form>
</mat-expansion-panel>


</mat-accordion>