<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a
          class="breadcrumb-link"
          [routerLink]="breadcrumItem.Url"
          [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
          >{{ breadcrumItem.text }}</a
        >
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">FTE Dashboard</h1>
    </div>
    <form [formGroup]="FTEDbForm" >
      <div class="content-top-row">
            <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label appearance="fill" class="field-fill">Group</mat-label>
              <mat-select  name="group" formControlName="group" (selectionChange)="getPlantByGroup()">
                <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId">  {{g.TypeName}} </mat-option>
              </mat-select>
            </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label >Plant</mat-label>
            <mat-select name="plant" formControlName="plant" (selectionChange)="onChangePlant()">
              <mat-option *ngFor="let p of filterdplants" [value]="p.DeptId">  {{p.DeptDesc}} </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="inputField">
            <nz-date-picker (ngModelChange)="getFTETrends()" class="calender-input"  id="startPicker" formControlName="dateStart" nzFormat="dd/MM/yyyy"
            [nzInputReadOnly]="true" ></nz-date-picker>
            <span class="floating-text">From Date</span>   
          </div>
          <div class="inputField">
            <nz-date-picker (ngModelChange)="getFTETrends()" class="calender-input"  id="endPicker" formControlName="dateEnd" nzFormat="dd/MM/yyyy"
           [nzInputReadOnly]="true" ></nz-date-picker>
            <span class="floating-text">To Date</span>   
          </div>
      </div>
    </form>
  </div>

<div class="wrapper-box wrapper-box-shadow grid-container">
  <div class="p-grid p-fluid">
    <div class="p-xl-12 p-lg-12 p-md-12 p-col-12" style="margin-bottom: 20px;">
    <p class="graph-title">Total Avg. FTE Trend</p>
    <div id="chart">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [stroke]="chartOptions.stroke"
        [colors]="chartOptions.colors"
        [dataLabels]="chartOptions.dataLabels"
        [legend]="chartOptions.legend"
        [markers]="chartOptions.markers"
        [grid]="chartOptions.grid"
        [yaxis]="chartOptions.yaxis"
        [title]="chartOptions.title"
      ></apx-chart>
    </div>
    
    </div>
    <div class="p-xl-12 p-lg-12 p-md-12 p-col-12" style="height:auto;">
      <p class="graph-title">Employees Avg. FTE Trend Resource Wise</p>
      <!-- <canvas id="myChart"></canvas> -->
      <div id="chart1">
        <apx-chart
          [series]="chartOptions1.series"
          [chart]="chartOptions1.chart"
          [xaxis]="chartOptions1.xaxis"
          [stroke]="chartOptions1.stroke"
          [colors]="chartOptions1.colors"
          [dataLabels]="chartOptions1.dataLabels"
          [legend]="chartOptions1.legend"
          [markers]="chartOptions1.markers"
          [grid]="chartOptions1.grid"
          [yaxis]="chartOptions1.yaxis"
          [title]="chartOptions1.title"
        ></apx-chart>
      </div>
      
    </div>
  </div>
</div>
<div class="wrapper-box wrapper-box-shadow grid-container" style="margin-top: 20px;">
  <div class="p-grid p-fluid">
    <div class="p-xl-12 p-lg-12 p-md-12 p-col-12" style="height: auto;">
      <p class="graph-title">Employees FTE Resources Utilizations By Project</p>
      <div id="chart3">
        <apx-chart
        [series]="chartOptions2.series"
        [chart]="chartOptions2.chart"
        [dataLabels]="chartOptions2.dataLabels"
        [plotOptions]="chartOptions2.plotOptions"
        [xaxis]="chartOptions2.xaxis"
        [yaxis]="chartOptions2.yaxis"
        [stroke]="chartOptions2.stroke"
        [fill]="chartOptions2.fill"
        [title]="chartOptions2.title"
        [tooltip]="chartOptions2.tooltip"
        [legend]="chartOptions2.legend"
      ></apx-chart>
      </div>
    </div>
  </div>
</div>
</div>


