import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { AuditCategoryService } from 'src/app/services/audit-category.service';
import { AuditCategoriesComponent } from '../audit-categories/audit-categories.component';
import { CheckpointComponent } from '../checkpoint/checkpoint.component';
@Component({
  selector: 'app-set-audit-categories',
  templateUrl: './set-audit-categories.component.html',
  styleUrls: ['./set-audit-categories.component.scss']
})
export class SetAuditCategoriesComponent implements OnInit {
  @Output() categoryLength = new EventEmitter<number>();
  @Input() IsWrite: boolean;
  @ViewChild(MatTable) table!: MatTable<Pillar>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(AuditCategoriesComponent) auditCategoriesComponent!: AuditCategoriesComponent;
  @ViewChild(CheckpointComponent) checkpointComponent!: CheckpointComponent;
  auditCategoryform!: FormGroup;
  formMode!: boolean;
  plantId!: number;
  groupId!: number;
  displayUserModal = false;
  isUpdate: boolean = false;
  plantsDDL!: any[];
  auditcatColumns: string[] = ['ptDesc', 'name', 'actions'];
  allAuditCategories: any = [];
  auditCategoriesData = new MatTableDataSource<any>([]);
  auditCategoryId: any;
  constructor(
    private fb: FormBuilder,
    private machineService: MachineMappingService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private auditCategoryService: AuditCategoryService,
  ) {}

  ngOnInit(): void {
    this.formMode = true;
    this.auditCategoryform = this.fb.group({
      plantId: ['', Validators.required],
      auditCategory: ['', [Validators.required, Validators.pattern('[^"]+$')]],
    });
  }

  getPaginator(): void {
    setTimeout(() => {
      this.auditCategoriesData.paginator = this.paginator;
      this.auditCategoriesData.sort = this.sort;
    }, 1000);
  }


  getAllPlants(): void {
    const key = {
      GroupId: this.groupId
    };
    this.machineService.getPlantsData(key).subscribe(
      (plants: any) => {
        this.plantsDDL = plants.DepartList;
        this.auditCategoryform.get('plantId')?.setValue(this.plantId);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.auditCategoriesData.filter = filterValue.trim().toLowerCase();

    if (this.auditCategoriesData.paginator) {
      this.auditCategoriesData.paginator.firstPage();
    }
  }

  getAuditCategories(key: any): void {
    this.spinner.show();
    this.groupId = key.groupId;
    this.plantId = key.plantId;
    this.auditCategoryService.getMasterCategories(key).subscribe((data) => {
      this.allAuditCategories = data;
      this.auditCategoriesData.data = data;
      this.categoryLength.emit(this.allAuditCategories.length);
      this.getPaginator();
      this.spinner.hide();
    },
    (error) => {
      this.toaster.error(error.error.message);
      this.spinner.hide();
    });
  }

  opensideModal(): void {
    this.formMode = true;
    this.auditCategoryform.reset();
    this.isUpdate = false;
    this.displayUserModal = true;
    this.getAllPlants();
    this.auditCategoryId = 0;
  }
  closeaddNewPlantItemModel(): void {
    this.displayUserModal = false;
  }

  saveMasterAuditCategory(): void {
    let name = this.allAuditCategories.find((row) => row.name.toLowerCase() == this.auditCategoryform.value.auditCategory.toLowerCase())
    if(name){
      this.toaster.warning('warning','CategoryName: '+name.name+'is Already Created');
      return;
    }
    if (!this.isUpdate) {
      let data = {
        plantId: this.auditCategoryform.get('plantId')?.value,
        auditCategory: this.auditCategoryform.value.auditCategory,
        auditCategoryId: this.auditCategoryId,
        isDelete: 0
      }
      this.auditCategoryService.SaveMasterAuditCategory(data).subscribe((response: any) => {
        this.toaster.success('Success', 'Audit Category Added Successfully');
        this.auditCategoryform.reset();
        this.closeaddNewPlantItemModel();
        const key = {
          groupId: this.groupId,
          plantId: data.plantId
        };
        this.getAuditCategories(key);
      }, (error) => {
        this.toaster.error('Error', error.error.Message);
      });
    } else {
      let data = {
        auditCategoryId: this.auditCategoryId,
        plantId: this.auditCategoryform.get('plantId')?.value,
        AuditCategory: this.auditCategoryform.get('auditCategory')?.value,
        isDelete: 0
      }
      this.auditCategoryService.SaveMasterAuditCategory(data).subscribe((response: any) => {
        this.toaster.success('Success', 'Audit Category Updated Successfully');
        this.closeaddNewPlantItemModel();
        this.isUpdate = false;
        this.auditCategoryform.reset();
        const key = {
          groupId: this.groupId,
          plantId: data.plantId
        };
        this.getAuditCategories(key);
      }, (error) => {
        this.toaster.error('Error', error.error.Message);
      })
    }
  }
  onClickUpdateAuditCategory(element: any): void {
    this.getAllPlants();
    setTimeout(() => {
      this.isUpdate = true;
      this.formMode = false;
      this.auditCategoryform.get('plantId')?.setValue(element.ptId);
      this.auditCategoryform.get('auditCategory')?.setValue(element.name);
      this.auditCategoryId = element.masterAuditCategoryId;
      this.displayUserModal = true;
    }, 1500);
  }
  onClickDeleteAuditCategory(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Audit Category!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        var data = {
          auditCategoryId: element.masterAuditCategoryId,
          isDelete: 1
        }
        this.auditCategoryService.SaveMasterAuditCategory(data).subscribe(
          (data: any) => {
            const key = {
              plantId: element.ptId,
              groupId: this.groupId
            };
            this.getAuditCategories(key);
            if(data.message.includes('Successfuly'))
              this.toaster.success(data.message);
            else
              this.toaster.warning(data.message);
          },
          (error: any) => {
            this.toaster.error('Error', error.error.Message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Master Score is safe :)', 'error');
      }
    });
  }
}
