import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MeetingmastersettingsComponent } from './MeetingMasterSettings/meetingmastersettings/meetingmastersettings.component';
import { TranslaterService } from 'src/app/services/translater.service';
import { MeetingEffectivenessChecklistComponent } from './meeting-effectiveness-checklist/meeting-effectiveness-checklist.component';
import { MeetingMenusComponent } from './meeting-menus/meeting-menus.component';
@Component({
  selector: 'app-performancemastersettings',
  templateUrl: './performancemastersettings.component.html',
  styleUrls: ['./performancemastersettings.component.scss'],
})
export class PerformancemastersettingsComponent implements OnInit {
  @ViewChild(MeetingmastersettingsComponent)
  meetingMaster!: MeetingmastersettingsComponent;
  @ViewChild(MeetingEffectivenessChecklistComponent)
  meetingEffectiveness!: MeetingEffectivenessChecklistComponent;
  @ViewChild(MeetingMenusComponent) meetingMenus!: MeetingMenusComponent;
  searchAuditMasterForm!: FormGroup;
  plants!: any;
  filteredPlants!: any;
  groupListDDL!: any[];
  filteredGroupListDDL!: any[];
  masterData: any = {};
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;

  constructor(
    private kpiMaster: KpimasterService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private userService: UserManagementService,
    private machineService: MachineMappingService,
    public router: Router,
    public translater: TranslaterService,
  ) {
  }

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();

    this.searchAuditMasterForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.getGroupData();
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.filteredGroupListDDL = this.groupListDDL.slice();
        this.searchAuditMasterForm
          .get('group')
          ?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getMasterData(): void {
    const plant = this.searchAuditMasterForm.get('plant')?.value;
    const group = this.searchAuditMasterForm.get('group')?.value;
    const key = {
      plantId: plant,
      groupId: group,
    };
    this.meetingMaster.getMeetingMaster(key);
    this.meetingEffectiveness.getMeetingMaster(key);
    this.meetingMenus.getMeetingMenuList();
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.searchAuditMasterForm.get('group')?.value,
    };
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList.filter((p: any) => p.DeptDesc);
        this.filteredPlants = this.plants.slice();
        this.searchAuditMasterForm
          .get('plant')
          ?.setValue(this.plants[0].DeptId);
          this.meetingMenus.getMeetingMenuList();
        this.getMasterData();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
