import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { KpiMasterWeight } from 'src/app/models/kpimasterWeight';
import { UserManu } from 'src/app/models/userManu';
import { KpiMasterWeightService } from 'src/app/services/kpimasterWeight';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import Swal from 'sweetalert2';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
@Component({
  selector: 'app-kpimasterweight',
  templateUrl: './kpimasterweight.component.html',
  styleUrls: ['./kpimasterweight.component.scss']
})
export class KpimasterweightComponent implements OnInit {
  @Input() kprPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<KpiMasterWeight>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  // @ViewChild(SliderComponent) slider!:SliderComponent;


  plants!: any[];
  formMode!: boolean;
  frequencies!: any[];
  filteredFrequencies!: any[];
  kpiMasterId!: number;
  Kpis!: any;
  plantId!: number;
  masterWeightForm!: FormGroup;
  kpiMasterModalVisible!: boolean;

  MasterWeightColumns: string[] = ['Actions',  'Plant', 'Frequency', 'KPI', 'Weight', ];
  MasterWeightData!: MatTableDataSource<KpiMasterWeight>;
  masterService: any;
  filteredKpis: any;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  IsAccess: any;
  IsWrite: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!:any;
  breadcrumList: any;
  constructor(
    private dialog: MatDialog,
    private kpimaster: KpimasterService,
    private platModelService: EnterPriseGroupService,
    private kpiMasterWeight: KpiMasterWeightService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private translater: TranslaterService,
    private usermanagementService: UserManagementService,
  ) { 
    }
   weightColumns: string[] = ['Frequency', 'TotalWeight'];
  // FrequencyWeightData!: MatTableDataSource<KpiMasterWeight>;
  FrequencyweightDataSource!: MatTableDataSource<any>;
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;

  ngOnInit(): void {
    this.GetBreadcrumList();
    //this.getLangWords();
    this.GetMenuAccess();

    // this.isRead = this.kprPermissions.IsAccess;
    // this.isWrite = this.kprPermissions.IsWrite;
    // this.isUpdate = this.kprPermissions.IsWrite;
    // this.isDelete = this.kprPermissions.IsWrite;
    this.masterWeightForm = this.fb.group({
      plant: ['', Validators.required],
      kpi: ['', Validators.required],
      weight: ['', [Validators.required, Validators.min(1), Validators.max(100)]],
      frequency: ['', Validators.required],
    });
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  openModal1(): void {
    this.getWeightfrequency();
    this.dialog.open(this.displayRoleModal, { height: '50%', width: '60%' });
    // this.getPaginator1();
  }
  // getPaginator1(): void {
  //   setTimeout(() => {
  //     this.FrequencyweightDataSource.paginator = this.paginator;
  //     this.FrequencyweightDataSource.sort = this.sort;
  //   }, 1000);
  // }
  getPaginator(): void {
    setTimeout(() => {
      this.MasterWeightData.paginator = this.paginator;
      this.MasterWeightData.sort = this.sort;
    }, 1000);
  }
  deleteKpiMaster(kpiMasterId: number): void {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete Kpi Master!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          const key = {
            kpiMasterId,
          };
          this.kpiMasterWeight.deleteMasterWeightData(key).subscribe(
            () => {
              Swal.fire({
                title: 'Deleted!',
                text: 'Your selected Kpi Master Weight has been deleted.',
                icon: 'success',
                timer: 1000,
                showConfirmButton: false,
              });
          this.spinner.hide();

              this.getKpiMaster();
            },
            (error) => {
              this.toaster.error('Error', error.error.message);
          this.spinner.hide();

            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your Kpi Master is safe ');
        }
      });
  }


  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.MasterWeightData.filter = filterValue.trim().toLowerCase();

    if (this.MasterWeightData.paginator) {
      this.MasterWeightData.paginator.firstPage();
    }
  }

  getFrequencies(): void {
    const key = {
      plantId: this.plantId,
    };
    this.kpimaster.getMasterFrequencies(key).subscribe(
      (frequencies) => {
        this.frequencies = frequencies;
        this.filteredFrequencies = this.frequencies.slice();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  onFrequencyChange(): void {
    const key = {
      plantId: this.plantId,
      frequencyId: this.masterWeightForm.value.frequency,
    };
    this.kpimaster.getKpiFrequency(key).subscribe(
      (kpis) => {
        // tslint:disable-next-line: max-line-length
        const array1 = this.MasterWeightData.filteredData.filter((c: any) => c.frequencyId === this.masterWeightForm.value.frequency).map((c: any) => c.kpiId);
        const missing = kpis.filter((i => (a: any) => a.kpiId !== array1[i] || !++i)(0));
        this.Kpis = missing;
        this.filteredKpis = this.Kpis.slice();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getMasterKpiWeights(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.kpiMasterWeight.getMasterWeights(key).subscribe(
      (response) => {
        this.MasterWeightData = new MatTableDataSource<KpiMasterWeight>(response);
        this.getPaginator();
        this.getPlants();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }


  getWeightfrequency(): void {
    this.spinner.show();
    const key = {
      plantId: this.plantId,
      // frequencyId: this.masterWeightForm.value.frequency,
    };
    this.plantId = key.plantId;
    this.kpiMasterWeight.getWeightfrequency(key).subscribe(
      (response) => {
       
        this.FrequencyweightDataSource = new MatTableDataSource<any>(response);
        this.getPaginator();
       
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  openModal(): void {
    this.resetForm();
    this.getFrequencies();
    this.Kpis = [];
    this.masterWeightForm.get('plant')?.setValue(this.plantId);
    this.formMode = true;
    this.kpiMasterModalVisible = true;
  }

  getOneKpiMaster(event: any): void {
      const key = {
        kpiMasterId : event,
      };
      this.kpiMasterWeight.getMasterWeightData(key).subscribe(
        (data) => {
          this.kpiMasterId = event;
          this.masterWeightForm.get('weight')?.setValue(data.weight);
          this.getPlants();
          this.getFrequencies();
          this.masterWeightForm.get('plant')?.setValue(this.plantId);
          // this.masterWeightForm.get('kpi')?.setValue(data.kpi);
          this.masterWeightForm.get('frequency')?.setValue(data.frequencyId);
          const key = {
            plantId: this.plantId,
            frequencyId: data.frequencyId,
          };

          this.kpimaster.getKpiFrequency(key).subscribe(
            (kpis) => {
              this.Kpis = kpis;
              const kpiObj = this.Kpis.find((x: any) => x.kpiId === parseInt(data.kpiId, 10)
              );
              this.filteredKpis = this.Kpis.slice();
              this.masterWeightForm.get('kpi')?.setValue(kpiObj?.kpiId);
            },
            (error) => {
              this.toaster.error('Error', error.error.message);
            }
          );
          this.formMode = false;
          this.kpiMasterModalVisible = true;
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
  }
  getPlants(): void {
    this.kpimaster.getAllPlants().subscribe(
      (plants: any) => {
        this.plants = plants;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getKpiMaster(): void {
    this.spinner.show();

    const key = {
      plantId: this.plantId,
    };
    this.kpiMasterWeight.getMasterWeights(key).subscribe(
      (data) => {
        this.MasterWeightData = new MatTableDataSource<KpiMasterWeight>(data);
        debugger;
        this.getPaginator();
        this.spinner.hide();

      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();

      }
    );
  }

  submitForm(): void {
    const array = this.MasterWeightData.filteredData.filter((c: any) => c.frequencyId === this.masterWeightForm.value.frequency);
    const totalWeight = array.reduce((a: any, b: any) => a + b.weight, 0);
    const frequencyWeight = this.masterWeightForm.value.weight + totalWeight;
    if (frequencyWeight <= 100) {
      this.spinner.show();

      const kpiReason = {
        plantId: this.masterWeightForm.value.plant,
        kpiId: this.masterWeightForm.value.kpi,
        frequencyId: this.masterWeightForm.value.frequency,
        weight: this.masterWeightForm.value.weight
      };
      this.kpiMasterWeight.addMasterWeights(kpiReason).subscribe(
        (response) => {
          this.toaster.success('Success', 'Kpi Master added Successfully');
          this.getKpiMaster();
          this.closeKpiMasterModel();
          this.resetForm();
          this.spinner.hide();

        },
        (error) => {
          this.toaster.error('Error', error.error.message);
          this.spinner.hide();

        }
      );
    }else{
      this.toaster.error('Error', 'Weight Value Exceed');
      this.spinner.hide();

    }
  }

  updateForm(): void {
    const array = this.MasterWeightData.filteredData.filter((c: any) => c.frequencyId === this.masterWeightForm.value.frequency
       &&  c.Id !== this.kpiMasterId);
    const totalWeight = array.reduce((a: any, b: any) => a + b.weight, 0);
    const frequencyWeight = this.masterWeightForm.value.weight + totalWeight;
    if (frequencyWeight <= 100) {
      this.spinner.show();

      const key = {
        kpiMasterId: this.kpiMasterId,
        plantId: this.masterWeightForm.value.plant,
        frequencyId: this.masterWeightForm.value.frequency,
        kpiId: this.masterWeightForm.value.kpi,
        weight: this.masterWeightForm.value.weight,
      };
      this.kpiMasterWeight.updateMasterWeights(key).subscribe(
        (response: any) => {
          this.toaster.success('Success', 'Kpi Master Weight Updated Successfully');
          this.getKpiMaster();
          this.closeKpiMasterModel();
          this.resetForm();
          this.spinner.hide();

        },
        (error) => {
          this.toaster.error('Error', error.error.message);
          this.spinner.hide();

        }
      );
    } else {
      this.toaster.error('Error', 'Weight Value Exceed');
      this.spinner.hide();

    }
  }

  closeKpiMasterModel(): void {
    this.kpiMasterModalVisible = false;
  }

  resetForm(): void {
    this.masterWeightForm.reset();
  }

}
function SliderModule(SliderModule: any) {
  throw new Error('Function not implemented.');
}

