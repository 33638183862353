<div class="user-manager-class" id="process-order">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="breadcrumb-link" routerLink="/Access">Access</a>
      </li>
    </ul>
    <div class="top-box-content">
      <h1 class="main-title">User Manager</h1>
    </div>
  </div>

  <form [formGroup]="userForm">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field
          style="margin-top: 0px"
          appearence="fill"
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
        >
          <mat-select placeholder="Group" (selectionChange)="onGroupChange($event)" panelClass="testClass" formControlName="group" [(ngModel)]="GroupId">
            <mat-option *ngFor="let group of groupList" [value]="group.TypeId">{{group.TypeName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">      
          <input matInput placeholder="Search Record"  (keyup)="applyFilter($event)" autocomplete="off">   
        </mat-form-field>
            <button  class="add-btn" (click)="openModal()">
            <img src="assets/images/add.png" alt="" width="20px" height="20px">
          </button>
      </div>
      <table
        mat-table
        [dataSource]="userData"
        class="basic-table plant-model-table"
        matSort
      >
        <!-- PO Column -->
        <ng-container matColumnDef="managerId">
          <th
            mat-header-cell
            mat-sort-header-pointer-middle
            *matHeaderCellDef
            mat-sort-header
            class="header-min-width"
          >
            <p class="header-text">User Id</p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.UserId }}</td>
        </ng-container>

        <!-- Item Code Column -->
        <ng-container matColumnDef="userName">
          <th
            mat-header-cell
            mat-sort-header-pointer-middle
            *matHeaderCellDef
            mat-sort-header
            class="header-min-width"
          >
            <p class="header-text">User Name</p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.UserName }}</td>
        </ng-container>

        <!-- PO Target Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Name</p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
        </ng-container>

        <!-- Planned Start Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Email</p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.Email }}</td>
        </ng-container>

        <!-- Planned End Column -->
        <ng-container matColumnDef="mobile">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="grp-order"
          >
            <p class="header-text">Mobile</p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.Mobile }}</td>
        </ng-container>

        <!-- active Column -->
        <ng-container matColumnDef="isGroupAdmin">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-min-width"
          >
            <p class="header-text">IsGroupAdmin</p>
          </th>
          <div>
            <td mat-cell *matCellDef="let element">
              <div class="table-element-created-by-content">
                <span class="img-box">
                  <img
                    class="img"
                    [src]="element.icon"
                    alt="{{ element.icon }}"
                  />
                </span>
                <p class="element-text-main">{{ element.IsAdmin }}</p>
              </div>
            </td>
          </div>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-min-width"
          >
            <p class="header-text">CreatedBy</p>
          </th>
          <div>
            <td mat-cell *matCellDef="let element">
              <div class="table-element-created-by-content">
                <span class="img-box">
                  <img
                    class="img"
                    [src]="element.icon"
                    alt="{{ element.icon }}"
                  />
                </span>
                <p class="element-text-main">{{ element.CreatedBy }}</p>
              </div>
            </td>
          </div>
          <ng-container matColumnDef="createdon">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">CreatedOn</p>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.CreatedOn }}</td>
          </ng-container>
        </ng-container>

        <!-- userManagerActions Column -->
        <ng-container matColumnDef="userManagerActions">
          <th mat-header-cell *matHeaderCellDef><p class="header-text">Actions</p></th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <button mat-icon-button class="edit-btn" aria-label="Edit Row" (click)="openEditPlantItemModel(element)">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white"
                  />
                </svg>
              </button>
              <button
                mat-icon-button
                class="tree-view-btn"
                aria-label="View Row Tree" (click)="deleteGroupNode(element)"
              >
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                    fill="#F85439"
                  />
                  <circle cx="17" cy="17" r="14" fill="#F85439" />
                  <path
                    d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="userColumns" ></tr>
        <tr mat-row *matRowDef="let row; columns: userColumns"></tr>
      </table>
      <mat-paginator
        class="basic-paginataor"
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons></mat-paginator>
    </div>
  </form>
  <!--new code-->
  <div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{'active': displayUserModal}">
    <div class="sidepanel-close" (click)="closeaddNewPlantItemModel()"></div>
    <div class="model-content">
      <div class="model-top">
        <h2 class="model-title">{{formMode ? 'Add' : 'Update'}} User Manager</h2>
        <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeaddNewPlantItemModel()">
          <svg width="14" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
              fill="black" />
          </svg>
        </button>
      </div>
      <div class="model-body">
        <form [formGroup]="formGroup"  (ngSubmit)="formMode ? addNewPlantModeItemlFormSubmit() : editPlantModeItemlFormSubmit()">
          <div class="form-group">
            <mat-form-field [ngClass]="{
                'is-invalid':
                formGroup.get('groupDescription')?.errors &&
                formGroup.get('groupDescription')?.touched
              }" appearance="fill" class="field-fill">
              <mat-label>User Name<span class="required"></span></mat-label>
              <input matInput placeholder="user name" formControlName="UserName">
            </mat-form-field>
            <p class="invalid-feedback" *ngIf="
            formGroup.get('UserName')?.touched &&
            formGroup.get('UserName')?.hasError('required')">
              * user name is required!
            </p>
          </div>
          <div class="form-group">
            <mat-form-field  [ngClass]="{
                'is-invalid':
                formGroup.get('Password')?.errors &&
                formGroup.get('Password')?.touched
              }" appearance="fill" class="field-fill">
              <mat-label>Password<span class="required"></span></mat-label>
              <input matInput placeholder="enter password" formControlName="Password">
            </mat-form-field>
            <p class="invalid-feedback" *ngIf="
            formGroup.get('Password')?.touched &&
            formGroup.get('Password')?.hasError('required')">
              * password is required!
            </p>
          </div>
          <div class="form-group">
            <mat-checkbox color="accent" formControlName="IsAdmin"> IsAdmin </mat-checkbox>
          </div>
          <div class="form-group">
            <mat-form-field [ngClass]="{
              'is-invalid':
              formGroup.get('Name')?.errors &&
              formGroup.get('Name')?.touched
            }"  appearance="fill" class="field-fill">
              <mat-label>Name<span class="required"></span></mat-label>
              <input matInput  formControlName="Name">
            </mat-form-field>
            <p class="invalid-feedback" *ngIf="
            formGroup.get('Name')?.touched &&
            formGroup.get('Name')?.hasError('required')">
              * Name is required!
            </p>
          </div>
          <div class="form-group">
            <mat-form-field  appearance="fill" class="field-fill">
              <mat-label>Mobile</mat-label>
              <input matInput type="number" formControlName="Mobile" maxlength="15">
            </mat-form-field>
            
          </div>
          <div class="form-group">
            <mat-form-field   appearance="fill" class="field-fill">
              <mat-label>Email</mat-label>
              <input matInput  formControlName="Email">
            </mat-form-field>
             
          </div>
          <div class="model-form-actions">
            <button type="button" mat-flat-button class="btn-cancel" (click)="closeaddNewPlantItemModel()"> Cancel
            </button>
            <button mat-flat-button color="accent" [disabled]="!formGroup.valid" class="btn-accent" type="submit">  {{formMode ? 'Add' : 'Update'}} </button>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>
