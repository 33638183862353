<section class="home-content">
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-2">
      <mat-card-title class="mat-card-title">Check List Type</mat-card-title>
    </div>
    <div class="p-col-12 p-md-10">
      <form [formGroup]="checkListType" (ngSubmit)="saveCheckListType()">
        <div>
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Check List Type</mat-label>
            <input type="text" matInput formControlName="listType" name="checklisttype">
          </mat-form-field>
          <button *ngIf="IsWrite" [disabled]="!checkListType.valid" type="submit" mat-mini-fab mat-button color="primary"
            pTooltip="Save" tooltipPosition="top" style="margin-left: 10px; margin-top: 5px;">
            <mat-icon>save</mat-icon>
          </button>
          <button [disabled]="!checkListType.valid" mat-mini-fab mat-dialog-close color="accent"
            (click)="RefreshCheckList()" pTooltip="Reset" tooltipPosition="top"
            style="margin-left: 10px; margin-top: 5px;">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </form>
    </div>
    <div style="width: 100%; margin-top: 10px;">
      <mat-card-content>
        <ag-grid-angular #agGrid style="width: 100%" class="ag-theme-alpine" [rowData]="rowCheckListType"
          [columnDefs]="columnDefsCheckListType" [defaultColDef]="defaultColDefCheckListType" (gridReady)="onGridReady($event)"
          (cellClicked)="oncellclicked($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10"
          (columnMoved)="onSaveHeaderGridState()" (columnVisible)="onSaveHeaderGridState()"
          (columnPinned)="onSaveHeaderGridState()" (displayedColumnsChanged)="onSaveHeaderGridState()"
          (firstDataRendered)="applyHeaderFooterState('CheckListType-vTLU37IVZb')"
          >
        </ag-grid-angular>
      </mat-card-content>
    </div>
  </div>
</section>