import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MicroLevelSaturationService {

  o3ApiUrl = environment.O3CoreApiUrl;
  baseUrl: string = environment.O3CoreApiUrl;
  constructor(private httpClient: HttpClient) { }

  getMicroLevelSaturationData(data: any): Observable<any[]>{
    return this.httpClient.post<any[]>(this.baseUrl + 'getOperatorSaturationData', data);
  }
  getOleTotalHoursWorked(data: any): Observable<any[]>{
    return this.httpClient.post<any[]>(this.baseUrl + 'getOleTotalHoursWorked', data);
  }
}
