<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template="<img src='assets/images/spinner.gif' />">
</ngx-spinner>
<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{ breadcrumItem.text }}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">
        {{ langObj ? langObj?.MeetingMatrix : ("MeetingMatrix" | translate) }}
      </h1>
    </div>
  </div>

  <div [ngClass]="{'fullscreen': isExpanded}">
    <div class="content-wrapper">
      <div id="wrapper1" class="wrapper-box wrapper-box-shadow grid-container content-show"
        style="margin-bottom: 10px;">
        <form [formGroup]="MeetingMatrixForm">
          <div class="content-top-row" style="display: flex; justify-content: flex-end">
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>{{
                langObj ? langObj?.SelectGroup : ("SelectGroup" | translate)
                }}</mat-label>
              <mat-select panelClass="testClass" name="group" formControlName="group"
                (selectionChange)="getPlantByGroup()">
                <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId">
                  {{ g.TypeName }}
                </mat-option>
              </mat-select>
              <mat-error>{{
                langObj ? langObj?.group : ("group" | translate)
                }}</mat-error>
            </mat-form-field>
            <mat-form-field appearence="fill"
              class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>{{
                langObj ? langObj?.SelectPlant : ("SelectPlant" | translate)
                }}</mat-label>
              <mat-select panelClass="testClass" name="plant" formControlName="plant"
                (selectionChange)="onPlantChange()">
                <mat-option *ngFor="let p of plants" [value]="p.DeptId">
                  {{ p.DeptDesc }}
                </mat-option>
              </mat-select>
              <mat-error>{{
                langObj ? langObj?.Plant : ("Plant" | translate)
                }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="field-fill" style="display: flex; justify-content: flex-end">
              <mat-label>{{
                langObj ? langObj?.SearchMeeting : ("SearchMeeting" | translate)
                }}</mat-label>
              <input [readonly]="false" matInput [(ngModel)]="search" class="input-text" autocomplete="off"
                (keyup)="meetingSearch()" formControlName="search" />
            </mat-form-field>
          </div>
        </form>
        <div class="p-grid p-fluid">
          <div class="p-xl-3 p-lg-3 p-md-4 p-col-12" *ngFor="let freq of filtereMeetings">
            <button class="header_badge" mat-flat-button color="accent" matBadgePosition="after" matBadgeColor="accent"
              MatBadgeSize="large" disableRipple style="margin-left: 14px">
              {{ freq?.frequencyName }}
            </button>
            <div *ngFor="let meeting of freq?.meetings">
              <button type="button" (click)="
                  toggleDisplay(
                    meeting.frequencyId,
                    meeting.kpiName,
                    meeting.frequencyName,
                    meeting.meetingId,
                    meeting.meetingName,
                    meeting.meetingType,
                    meeting.meetingMasterId,
                    meeting?.leaderId,
                    meetings
                  )
                " class="content_badge" mat-flat-button color="accent" matBadgePosition="after" matBadgeColor="accent"
                MatBadgeSize="large" style="margin-left: 14px">
                <p class="badge_text">{{ meeting.meetingName }}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="wrapper2" class="wrapper-box wrapper-box-shadow grid-container content-hide"
        style="margin-bottom: 10px;">
        <!-- <h2 style="color:#0000cd" >Meeting Name</h2> -->
        <ng-container *ngIf="!isExpanded">
          <h3>{{ selectmeeting }}</h3>

          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <button type="button" [disabled]="pausebutton" (click)="startMeeting()" mat-raised-button color="accent"
                *ngIf="IsWrite">
                {{ meetingStarted ? "Meeting Started" : "Start Meeting" }}
              </button>
              <button type="button" (click)="stopMeeting()" mat-raised-button color="warn" style="margin-left: 14px"
                *ngIf="IsWrite">
                {{ meetingStoped ? "Meeting Stopped" : "Stop Meeting" }}
              </button>
              <button type="button" (click)="resetMeeting()" mat-raised-button style="margin-left: 14px">
                {{ langObj ? langObj?.Reset : ("Reset" | translate) }}
              </button>
              <div class="d-flex align-items-center">
                <h3 class="timer_number">{{ timer }}</h3>
              </div>
            </div>
            <div>
              <button mat-mini-fab color="warn" matTooltip="View Meeting Agendas" matTooltipPosition="above"
                (click)="viewMeetingsAgendas()" *ngIf="IsWrite">
                <mat-icon style="color: white">library_books</mat-icon>
              </button>
              <button mat-mini-fab color="accent" matTooltip="Add Meeting Actions" matTooltipPosition="above"
                (click)="actionAddClick()" style="margin-left: 14px">
                <mat-icon style="color: white">add_circle_outline</mat-icon>
              </button>
              <button type="button" mat-mini-fab (click)="decreaseDecimalPlaces()" matTooltip="Decrease Decimal Places" matTooltipPosition="above"   color="accent"
              style="margin-left: 14px">
             
              <mat-icon style="color: #fff;"> format_indent_decrease</mat-icon>
              </button>
              <button type="button" mat-mini-fab (click)="IncreaseDecimalPlaces()" matTooltip="Increase Decimal Places" matTooltipPosition="above"   color="accent"
              style="margin-left: 14px">
             
              <mat-icon style="color: #fff;"> format_indent_increase</mat-icon>
              </button>
              <button type="button" (click)="openUserModal()" mat-flat-button color="warn" style="margin-left: 14px">
                {{
                langObj ? langObj?.Participants : ("Participants" | translate)
                }}
              </button>
              <button type="button" (click)="getMeetingEvaluations()" mat-flat-button color="accent" class="back-button"
                style="margin-left: 14px">
                <p class="btn-text">
                  {{
                  langObj ? langObj?.Evaluations : ("Evaluations" | translate)
                  }}
                </p>
              </button>
              <button type="button" (click)="toggleDisplayreverse()" class="back-button" mat-flat-button color="accent"
                matBadgePosition="after" matBadgeColor="accent" MatBadgeSize="large" style="margin-left: 14px">
                <p class="btn-text">
                  <i class="fa fa-arrow-left" style="color: #fff; margin-right: 9px"></i>{{ langObj ? langObj?.Previous
                  : ("Previous" | translate) }}
                </p>
              </button>
             
            </div>
          </div>
        </ng-container>

        <div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="filters">
              <div class="frequency-wrapper">
                <div style="margin-right: 7px">
                  <h3>
                    {{
                    langObj
                    ? langObj?.KPIFrequency
                    : ("KPIFrequency" | translate)
                    }}
                  </h3>
                </div>
                <form [formGroup]="MeetingMatrixFrequencyForm">
                  <p-dropdown formControlName="frequency" defaultLabel="Select a Frequency" [ngClass]="{
                      'is-invalid':
                        MeetingMatrixFrequencyForm.get('frequency')?.errors &&
                        MeetingMatrixFrequencyForm.get('frequency')?.touched
                    }" [options]="frequencies" (onChange)="getGridDataByFrequency()" placeholder="Select a Frequency"
                    scrollHeight="300px" name="frequency" optionLabel="frequencyName">
                  </p-dropdown>
                </form>
              </div>
              <div class="glide-wrapper">
                <mat-slide-toggle name="enableGlidePath" (change)="enableGlidePathChange($event)" [(ngModel)]="enableGlidePath">
                  <h3>{{
                    langObj
                    ? langObj?.Glidepath
                    : ("Glidepath" | translate)
                    }}</h3>
                </mat-slide-toggle>
              </div>
            </div>

            <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 200px">
              <mat-label> {{ langObj ? langObj?.DateRangeFinancialGap : ("DateRangeFinancialGap" | translate) }}
              </mat-label>
              <mat-date-range-input [formGroup]="rangeFinacialGap" [rangePicker]="picker">
                <input matStartDate formControlName="fromDate" placeholder="Gap From Date" [readOnly]="true" />
                <input matEndDate formControlName="toDate" placeholder="Gap To Date" [readOnly]="true" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker (closed)="onFinancialGapRangeChange()"></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 200px">
              <mat-label> {{ langObj ? langObj?.DateRangeScorecard : ("DateRangeScorecard" | translate) }}</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker1">
                <input matStartDate formControlName="fromDate" placeholder="From Date" [readOnly]="true" />
                <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-date-range-picker #picker1 (closed)="getGridDataByFrequency()"></mat-date-range-picker>
            </mat-form-field>
            <mat-spinner-button [options]="spinnerButtonOptions" (btnClick)="onClickParamterAnalysis(false)"
              class="loader" id="selectedRows" style="margin: 10px 10px">
            </mat-spinner-button>
            <mat-spinner-button [options]="spinnerButtonOptions1" (btnClick)="onClickParamterComparsion(false)"
              class="loader" id="selectedRows" style="margin: 10px 10px">
            </mat-spinner-button>
            <button type="button" (click)="navigateToParameterAnalysis()" class="back-button" mat-flat-button
              color="accent">
              <p class="btn-text">
                <i style="color: #fff; margin-right: 9px"></i>{{ langObj ? langObj?.ParameterAnalysis
                : ("ParameterAnalysis" | translate) }}
              </p>
            </button>

            <div class="toggle-actions">
              <!-- <button mat-mini-fab mat-button mat-dialog-close pTooltip="Sort" tooltipPosition="top" color="primary"
                (click)="sortGapColumn(sort)" style="margin-bottom: 4px">
                <mat-icon>sort</mat-icon>
              </button> -->

              <button *ngIf="!isExpanded" mat-mini-fab mat-button mat-dialog-close color="accent" pTooltip="Expand Grid"
                tooltipPosition="top" (click)="toggleView()" style="margin-bottom: 4px">
                <mat-icon>fullscreen</mat-icon>
              </button>
              <button mat-mini-fab mat-button mat-dialog-close color="accent" pTooltip="Collapse Grid"
                tooltipPosition="top" (click)="toggleView()" *ngIf="isExpanded" style="margin-bottom: 4px">
                <mat-icon>fullscreen_exit</mat-icon>
              </button>

            </div>
          </div>
        </div>

        <ag-grid-angular #scoreGrid id="scoregrid" style="width: 100%;" class="ag-theme-alpine score-grid"
          [rowData]="rowData" [columnDefs]="columnDefs" [gridOptions]="scoreCardGridOptions"
          (cellClicked)="onScoreCardCellClicked($event)" (gridReady)="onScoreCardGridReady($event)"
          (modelUpdated)="onModelUpdated()" [loadingOverlayComponent]="loadingOverlayComponent"
          [frameworkComponents]="frameworkComponents" (firstDataRendered)="onScoreCardGridFirstDataRendered($event)"
          [allowContextMenuWithControlKey]="true" [getContextMenuItems]="getContextMenuItems"
          [sideBar]="{ toolPanels: ['columns', 'filters'] }" [defaultColDef]="defaultColDef"
          [rowSelection]="rowSelection" [rowMultiSelectWithClick]="true"
          (firstDataRendered)="onFirstDataRendered($event)" [suppressRowClickSelection]="true">
        </ag-grid-angular>
      </div>

      <div id="wrapper3" class="wrapper-box wrapper-box-shadow grid-container content-hide"
        style="margin-bottom: 10px;">
        <div class="d-flex justify-content-end align-items-center">
          <div>
            <button type="button" (click)="openMeetingSuggestedModal()" mat-flat-button color="warn"
              style="margin-left: 14px">
              {{
              langObj
              ? langObj?.SuggestedActions
              : ("SuggestedActions" | translate)
              }}
            </button>
            <button type="button" (click)="toggleMeetingEvaluationReverse()" class="back-button" mat-flat-button
              color="accent" matBadgePosition="after" matBadgeColor="accent" MatBadgeSize="large"
              style="margin-left: 14px">
              <p class="btn-text">
                <i class="fa fa-arrow-left" style="color: #fff; margin-right: 9px"></i>{{ langObj ? langObj?.Previous :
                ("Previous" | translate) }}
              </p>
            </button>
          </div>
        </div>
        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
          [stroke]="chartOptions.stroke" [colors]="chartOptions.colors" [dataLabels]="chartOptions.dataLabels"
          [legend]="chartOptions.legend" [markers]="chartOptions.markers" [grid]="chartOptions.grid"
          [yaxis]="chartOptions.yaxis" [title]="chartOptions.title"></apx-chart>
        <div class="maxWidth">
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="meetingEvaluationData">
              <ng-container matColumnDef="Date">
                <th mat-header-cell *matHeaderCellDef>
                  {{ langObj ? langObj?.Date : ("Date" | translate) }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.Date | date: "dd-MM-yyyy" }}
                </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="Score">
                <th mat-header-cell *matHeaderCellDef>
                  {{ langObj ? langObj?.Score : ("Score" | translate) }}
                </th>
                <td mat-cell *matCellDef="let element">{{ element.Score }}</td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="Target">
                <th mat-header-cell *matHeaderCellDef [hidden]="hideTargetLevel" id="TargetLevel">
                  {{ langObj ? langObj?.Target : ("Target" | translate) }}
                </th>
                <td mat-cell [hidden]="hideTargetLevel" *matCellDef="let element">
                  {{ element.Target || " " }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Level">
                <th mat-header-cell *matHeaderCellDef [hidden]="!hideTargetLevel">
                  {{ langObj ? langObj?.Level : ("Level" | translate) }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.Level || " " }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="meetingEvalutionColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: meetingEvalutionColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="3">
                  {{
                  langObj ? langObj?.nodatamatch : ("nodatamatch" | translate)
                  }}
                </td>
              </tr>
            </table>
            <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addActionModal>
  <div mat-dialog-title>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <!-- <h2>
                  {{ langObj ? langObj?.Edit : ("Edit" | translate) }}
                  {{ ModuleName }} ({{ UniqueCode }})
                  </h2> -->
        <h2>Add Action</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
  </div>
  <mat-dialog-content>
    <form [formGroup]="addActionForm">
      <div class="content-top-row" style="margin-bottom: 20px">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.Action : ('Action' | translate)}} *</mat-label>
          <input matInput placeholder="Enter Action Name" formControlName="action" />
        </mat-form-field>
        <div class="radio-wrapper" style="width: 20%;">
          <mat-label>{{langObj ? langObj?.ActionType : ('ActionType' | translate)}} *:</mat-label>
          <mat-radio-group formControlName="actionType" class="radio-group" name="ActionType">
            <mat-radio-button value="Preventive">Preventive</mat-radio-button>
            <mat-radio-button value="Corrective">Corrective</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="background: gainsboro;">
          <mat-label>KPI</mat-label>
          <input matInput placeholder="Enter Remarks" formControlName="kpi" readonly />
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.SelectAssignee : ('SelectAssignee' | translate)}} *</mat-label>
          <mat-select formControlName="assignTo">
            <mat-select-filter [placeholder]="'Search User'" [displayMember]="'FullName'" [array]="participantsByPlants"
              (filteredReturn)="userList = $event">
            </mat-select-filter>
            <mat-option *ngFor="let user of userList" [value]="user?.EmpInfo_Id">
              {{ user?.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="inputField" style="width: 17%;margin-top: 0px;">
          <nz-date-picker class="calender-input" style="margin-left: 0px !important; width: 100%" [nzShowTime]="false"
            nzFormat="yyyy-MM-dd" formControlName="targetDate">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.TargetDate : ('TargetDate' | translate)}} *</span>
        </div>
        <button [disabled]="!addActionForm.valid" mat-mini-fab mat-button color="primary" matTooltip="Add"
          matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 10px;"
          (click)="addActionPlan()">
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
      </div>
    </form>
    <ag-grid-angular style="width: 100%; height: 20vw" class="ag-theme-alpine" [pagination]="true" 
      [paginationAutoPageSize]="5" [columnDefs]="columnDefsallAction" [rowData]="overallMeetingActions"
      (cellClicked)="cellClickedActionTable($event)" (gridReady)="onGridReadyAction($event)">
    </ag-grid-angular>
  </mat-dialog-content>
</ng-template>

<ng-template #displayUserModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>
        {{
        langObj ? langObj?.AddParticipants : ("AddParticipants" | translate)
        }}
      </h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <form [formGroup]="ManualParticepantForm">
      <div class="content-top-row">
        <mat-form-field class="top-row-first-filed-item" style="width: 40% !important">
          <mat-select formControlName="participant" placeholder="Meeting Participants">
            <mat-select-filter [placeholder]="'Search'" [array]="UserDDL"
              (filteredReturn)="filteredParticipants = $event" [displayMember]="'fullName'"></mat-select-filter>
            <mat-option *ngFor="let o of filteredParticipants" [value]="o.EmpInfo_Id">{{ o.fullName }}</mat-option>
          </mat-select>
          <mat-error style="padding-bottom: 50px">{{
            langObj ? langObj?.meetingpartic : ("meetingpartic" | translate)
            }}</mat-error>
        </mat-form-field>
        <button [disabled]="!ManualParticepantForm.valid" type="submit" (click)="addManualPartcipent()" mat-mini-fab
          mat-button color="primary" matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"
          style="margin-left: 15px">
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
      </div>
    </form>
    <div class="p-grid p-fluid d-flex" style="margin-top: 20px">
      <div class="p-xl-4 p-lg-4 p-md-4 p-col-6">
        <h2>
          {{ langObj ? langObj?.Participant : ("Participant" | translate) }}
        </h2>
      </div>
      <div class="p-xl-8 p-lg-8 p-md-8 p-col-6 d-flex justify-content-center">
        <h2 style="margin-right: 10px">Select All</h2>
        <mat-checkbox color="accent" [checked]="selectedAll" (change)="selectAllParticipants($event)"
          style="margin-top: 5px">
        </mat-checkbox>
      </div>
    </div>
    <div class="participents-container">

      <div *ngFor="let part of meetingParticipants; let i = index">
        <div class="p-grid p-fluid">
          <div class="p-lg-4 p-xl-4 p-md-4 p-col-4">
            <div>
              <p style="font-size: 18px; overflow-wrap: break-word">
                <strong>{{ i + 1 }}</strong> - {{ part.participantName }}
              </p>
            </div>
          </div>
          <div class="p-lg-8 p-xl-8 p-md-8 p-col-8">
            <mat-radio-group (change)="participantAttended($event, part.participantId)" [checked]="part.reason"
              [(ngModel)]="part.reason">
              <mat-radio-button value="ontime">{{
                langObj ? langObj?.OnTime : ("OnTime" | translate)
                }}</mat-radio-button>
              <mat-radio-button value="late">{{
                langObj ? langObj?.Late : ("Late" | translate)
                }}</mat-radio-button>
              <mat-radio-button value="excluded">{{
                langObj ? langObj?.Excluded : ("Excluded" | translate)
                }}</mat-radio-button>
              <mat-radio-button value="onleave">{{
                langObj ? langObj?.OnLeave : ("OnLeave" | translate)
                }}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #agendasViewSheet>
  <div class="d-flex justify-content-between align-items-center">
    <h2>
      {{ langObj ? langObj?.MeetingAgendas : ("MeetingAgendas" | translate) }}
    </h2>
    <button mat-mini-fab mat-button color="warn" title="close" (click)="closeSheet($event)">
      <i class="fa fa-times" style="color: #fff"></i>
    </button>
  </div>
  <mat-dialog-content>
    <form [formGroup]="AddAgendaForm">
      <div>
        <div>
          <div #inputRef class="d-flex justify-content-between align-items-center" style="margin-top: 20px"
            style="width: 60%">
            <mat-form-field appearance="fill" class="field-fill" style="width: 90% !important">
              <mat-label class="required">{{
                langObj ? langObj?.MeetingAgenda : ("MeetingAgenda" | translate)
                }}</mat-label>
              <input matInput placeholder="Enter Meeting Agenda" formControlName="agenda" />
            </mat-form-field>
            <button type="button" (click)="saveMeetingAgenda()" mat-mini-fab mat-button color="primary"
              matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"
              style="margin-left: 20px; margin-bottom: 20px" [disabled]="AddAgendaForm.invalid">
              <i class="fa fa-floppy-o" style="color: #fff"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="p-grid d-flex justify-content-start" *ngFor="let meeting of meetingAgendasArray; let i = index"
      style="width: 100%; margin-bottom: 15px; background-color: #cffaee">
      <div class="p-col-11" style="overflow-x: auto">
        {{ i + 1 }} - {{ meeting.agenda }}
      </div>

      <div class="p-col-1" style="text-align: end; padding: 0px">

        <mat-icon (click)="removeAgendaRow(meeting.agenda)" style="color: red; cursor: pointer; margin: 5px">delete
        </mat-icon>
      </div>
    </div>
  </mat-dialog-content>

</ng-template>

<ng-template #suggestedActionModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>
        {{
        langObj ? langObj?.SuggestedActions : ("SuggestedActions" | translate)
        }}
      </h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <form [formGroup]="meetingSuggestedActionForm">
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-top-row">
          <mat-form-field class="top-row-first-filed-item">
            <mat-label class="required">{{
              langObj ? langObj?.Action : ("Action" | translate)
              }}</mat-label>
            <input matInput formControlName="action" placeholder="add suggested action" />
            <mat-error>{{
              langObj ? langObj?.Required : ("Required" | translate)
              }}</mat-error>
          </mat-form-field>
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>{{
              langObj
              ? langObj?.SelectResponsible
              : ("SelectResponsible" | translate)
              }}</mat-label>
            <mat-select formControlName="responsibleId">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'fullName'" [array]="UserDDL"
                (filteredReturn)="filteredUsers = $event"></mat-select-filter>
              <mat-option *ngFor="let Own of filteredUsers" [value]="Own.EmpInfo_Id">{{ Own.fullName }}</mat-option>
            </mat-select>
            <mat-error>{{langObj ? langObj?.res : ('res' | translate)}</mat-error>
          </mat-form-field>
          <div class="inputField">
            <nz-date-picker class="calender-input" id="startPicker" formControlName="completionDate"
              nzFormat="dd/MM/yyyy" [nzInputReadOnly]="true"></nz-date-picker>
            <span class="floating-text">{{
              langObj ? langObj?.CompletionDate : ("CompletionDate" | translate)
              }}</span>
          </div>
          <button mat-mini-fab color="accent" [disabled]="!meetingSuggestedActionForm.valid"
            matTooltip="Add Meeting Actions" matTooltipPosition="above" (click)="addSuggestedAction()"
            style="margin-left: 14px">
            <mat-icon style="color: white">add_circle_outline</mat-icon>
          </button>
        </div>
      </div>
    </form>
    <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="meetingSuggestedActions"
      (gridReady)="onGridReady($event)" [columnDefs]="columnDefsSuggested" [gridOptions]="gridOptions"
      (cellValueChanged)="onCellSuggestedValueChanged($event)" [pagination]="true">
    </ag-grid-angular>
  </mat-dialog-content>
</ng-template>

<ng-template #displayStockChartModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <!-- <h2>
        Stock Chart
      </h2> -->
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close" (click)="closeModel()">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <div class="w-100">
      <div [chart]="stock"></div>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #analysisChart style="padding-bottom: 0px !important">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2> {{ langObj ? langObj?.KPIAnalysis : ("KPIAnalysis" | translate) }}</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content class="chartpopup">
    <form [formGroup]="KPIAnalysisForm">
      <div class="d-flex" style="grid-gap: 10px">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 200px">
          <mat-label> {{ langObj ? langObj?.DateRangeAnalysis : ("DateRangeAnalysis" | translate) }} </mat-label>
          <mat-date-range-input [rangePicker]="picker1">
            <input matStartDate formControlName="fromDate" placeholder="From Date" [readOnly]="true" />
            <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true"
              (dateChange)="onDateChangeInKPIAnaModal($event)" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>

        <mat-slide-toggle (change)="chartType = !chartType">Histogram</mat-slide-toggle>
        <button mat-mini-fab color="primary" matTooltip="All Leak Detection" matTooltipClass="custom-tooltip"
          matTooltipPosition="above" aria-label="Example icon button with a menu icon"
          style="float: right; overflow: hidden;" *ngIf="isSaur" (click)="navigateLeakDetectionAll()">
          <mat-icon>map</mat-icon>
        </button>
      </div>
    </form>
    <ng-container *ngIf="chartType">
      <div *ngFor="let item of chartArray">
        <!-- <button mat-flat-button color="accent" class="btn-accent" style="float: right;" *ngIf="isSaur" (click)="navigateLeakDetection()">Leak Detection</button> -->
        <button mat-mini-fab color="primary" matTooltip="Leak Detection" matTooltipClass="custom-tooltip"
          matTooltipPosition="above" aria-label="Example icon button with a menu icon" style="float: right;"
          *ngIf="isSaur" (click)="navigateLeakDetection(item)">
          <mat-icon>map</mat-icon>
        </button>
        <div [id]="item.id" [chart]="item.chartOption"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="!chartType">
      <div *ngFor="let item of chartArrayForAnalysis">
        <!-- <button mat-flat-button color="accent" class="btn-accent" style="float: right;" *ngIf="isSaur" (click)="navigateLeakDetection()">Leak Detection</button> -->
        <button mat-mini-fab color="primary" matTooltip="Leak Detection" matTooltipClass="custom-tooltip"
          matTooltipPosition="above" aria-label="Example icon button with a menu icon"
          style="float: right; overflow: hidden;" *ngIf="isSaur" (click)="navigateLeakDetection(item)">
          <mat-icon>map</mat-icon>
        </button>
        <div [id]="item.id" [chart]="item.chartOption"></div>
      </div>
    </ng-container>
  </mat-dialog-content>
</ng-template>

<ng-template #visibleParameterComparison style="padding-bottom: 0px !important">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>{{ langObj ? langObj?.KPIComparison : ("KPIComparison" | translate) }}</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content class="chartpopup" style="overflow-y:auto !important">
    <form [formGroup]="KPIComparisonForm">
      <div class="d-flex" style="align-items: center;">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 200px">
          <mat-label> {{ langObj ? langObj?.DateRangeComparison : ("DateRangeComparison" | translate) }}</mat-label>
          <mat-date-range-input [rangePicker]="picker1">
            <input matStartDate formControlName="fromDate" placeholder="From Date" [readOnly]="true" />
            <input matEndDate formControlName="toDate" placeholder="To Date" [readOnly]="true"
              (dateChange)="dateChangeFromModelComp($event)" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>
        <div style="margin-left: 20px;">
          <button *ngFor="let u of uniqueUoms" [disabled]="u.disabled" (click)="GetRawMaterialParameters(u)"
            [style]="u == individualUom ? 'color: #fff; padding: 5px 25px;background-color: rgb(56 210 173);background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);border-radius: 14px;font-weight: bold;font-size: 16px;border: 0;user-select: none;-webkit-user-select: none;touch-action: manipulation;cursor: pointer;' : ''"
            mat-raised-button
            style="margin-left: 10px; margin-right: 10px;color: #000; padding: 5px 25px;border-radius: 14px;font-weight: bold;font-size: 16px;border: 0;user-select: none;-webkit-user-select: none;touch-action: manipulation;cursor: pointer;"><span
              [style]="u == individualUom ? 'color:white; font-weight: 500;':'color:black; font-weight: 500;'">{{u}}</span>
          </button>
        </div>
      </div>

    </form>
    <div [chart]="stock"></div>
  </mat-dialog-content>
</ng-template>

<ng-template #updateActionModal>
  <form [formGroup]="editActionPlanForm">
    <div mat-dialog-title>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>Update Action</h2>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>
    </div>
    <mat-dialog-content>
      <div class="content-row-wrap" style="margin-bottom: 20px">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Root Cause</mat-label>
          <input matInput placeholder="Enter Remarks" formControlName="rootcause" />
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label> {{langObj ? langObj?.Action : ('Action' | translate)}} *</mat-label>
          <input matInput placeholder="Enter Action Name" formControlName="actions" [(ngModel)]="data.Action" />
        </mat-form-field>
        <div class="radio-wrapper">
          <mat-label>{{langObj ? langObj?.ActionType : ('ActionType' | translate)}} *:</mat-label>
          <mat-radio-group formControlName="actionType" class="radio-group" name="ActionType">
            <mat-radio-button value="Preventive">Preventive</mat-radio-button>
            <mat-radio-button value="Corrective">Corrective</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="imgFlex-Wrapper">
          <mat-label>{{langObj ? langObj?.ChooseImage : ('ChooseImage' | translate)}}</mat-label>
          <div class="imgflex" style="position: relative">
            <div title="image" style="display: flex">
              <img *ngIf="data.FileSrc || data.UploadFile" (click)="imageZoomModal()"
                [src]="data.FileSrc || blobStorageUrl + data.UploadFile" alt="image" width="60px" height="40px" />
            </div>
            <img *ngIf="data.FileSrc || data.UploadFile" src="assets/images/delete.png" (click)="deleteFile('pic')"
              class="imgabsol" alt="" width="15px" height="15px" style="position: absolute; left: 0; top: 0" />
            <button mat-flat-button color="accent" type="button" class="upload" (click)="openFile('pic')">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" (change)="readFile($event, 'pic')" class="d-none" #fileInput accept="image/*" />
          </div>
        </div>
        <div class="imgFlex-Wrapper">
          <mat-label>{{langObj ? langObj?.ChooseFile : ('ChooseFile' | translate)}}</mat-label>
          <div class="imgflex" style="position: relative">
            <div title="pdf" style="display: flex">
              <a *ngIf="data.DocSrc || data.UploadDoc" (click)="downloadPDF()"><img src="assets/images/file_img.png"
                  class="pdfimg" alt="pdf" width="60px" /></a>
            </div>
            <img *ngIf="data.DocSrc || data.UploadDoc" src="assets/images/delete.png" (click)="deleteFile('file')"
              class="imgabsol" alt="" width="15px" height="15px" style="position: absolute; left: 0; top: 0" />
            <button mat-flat-button color="accent" type="button" class="upload" (click)="openFile(i, 'file')"
              [disabled]="data.Status == 'Closed'">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" (change)="readFile($event, 'file')" class="d-none" #fileInputFile
              accept="application/pdf,application/vnd.ms-excel" />
          </div>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.SelectAssignee : ('SelectAssignee' | translate)}} *</mat-label>
          <mat-select formControlName="assignTo" [disabled]="data.Status == 'Closed' || !data.Action">
            <mat-select-filter [placeholder]="'Search User'" [displayMember]="'FullName'" [array]="participantsByPlants"
              (filteredReturn)="userList = $event">
            </mat-select-filter>
            <mat-option *ngFor="let user of userList" [value]="user?.EmpInfo_Id">
              {{ user?.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="inputField" style="margin-top: 15px; width: 17%">
          <!-- <nz-date-picker class="calender-input"  nzFormat="dd/MM/yyyy"
                  [nzInputReadOnly]="true" style="width: 100%; margin-left: 0px !important;"
                  [nzDisabledDate]="getMinDate('Target')" [disabled]="'true'"  [(ngModal)] = "data.TargetDate"></nz-date-picker> -->
          <nz-date-picker class="calender-input" style="margin-left: 0px !important; width: 100%"
            [nzInputReadOnly]="true" [nzShowTime]="false" nzFormat="yyyy-MM-dd" [nzDisabledDate]="getMinDate('Target')"
            formControlName="targetDate" (nzOnCalendarChange)="targetChange()"
            [nzDisabled]="data.Status != 'Open' || !data.AssignTo" [(ngModel)]="data.TargetDate">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.TargetDate : ('TargetDate' | translate)}} *</span>
        </div>
        <div class="inputField" style="margin-top: 15px; width: 17%">
          <nz-date-picker class="calender-input" formControlName="revisedDate" nzFormat="yyyy-MM-dd"
            [nzInputReadOnly]="true" style="width: 100%; margin-left: 0px !important"
            [nzDisabledDate]="getMinDate('Revised')" [nzDisabled]="
               (data.Status != 'InProgress' && data.Status == 'Open') ||
               data.Status == 'Complete' ||
               data.Status == 'Closed'
               ">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.RevisedDate : ('RevisedDate' | translate)}}</span>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" [ngClass]="{
               'disabled-input':
               data.Status == 'Closed' ||
               !data.TargetDate ||
               data.Status == 'Complete'
               }">
          <mat-label>{{langObj ? langObj?.SelectCompleted : ('SelectCompleted' | translate)}}</mat-label>
          <mat-select formControlName="completed" (selectionChange)="onChangeComplete()" [disabled]="
               data.Status == 'Closed' ||
               !data.TargetDate ||
               data.Status == 'Complete'
               ">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          [ngClass]="{ 'disabled-input': af?.completed?.value == 'No' }">
          <mat-label>{{langObj ? langObj?.SelectVerfication : ('SelectVerfication' | translate)}}</mat-label>
          <mat-select formControlName="verification" [disabled]="af?.completed?.value == 'No'"
            (selectionChange)="onChangeVerification()">
            <mat-option value="">None</mat-option>
            <mat-option value="Approved">Approved</mat-option>
            <mat-option value="Disapproved">Disapproved</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          [ngClass]="{ 'disabled-input': af?.completed?.value == 'No' }">
          <mat-label>{{langObj ? langObj?.SelectNewAssignee : ('SelectNewAssignee' | translate)}}</mat-label>
          <mat-select [disabled]="af?.completed?.value == 'No'" formControlName="NewAssignee">
            <mat-select-filter [placeholder]="'Search New Assignee'" [displayMember]="'FullName'"
              [array]="participantsByPlants" (filteredReturn)="userList = $event">
            </mat-select-filter>
            <mat-option *ngFor="let user of userList" [value]="user.EmpInfo_Id">
              {{ user?.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="p-xl-4 uploadinside" *ngIf="showinput" style="width: fit-content">
          <input placeholder="Choose Close File" type="file" (change)="uploadedFile($event)" formControlName="closefile"
            class="form-control" style="height: 48.31px; width: 219px; margin-top: 8px" />
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}</mat-label>
          <input matInput placeholder="Enter Remarks" formControlName="remarks" />
        </mat-form-field>
        <button mat-mini-fab mat-button color="primary" matTooltip="Update" (click)="UpdateActionPlan()"
          matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px; margin-top: 20px">
          <i class="fa fa-pencil" style="color: #fff"></i>
        </button>
      </div>
    </mat-dialog-content>
  </form>
</ng-template>

<p-dialog [header]="fileDesc" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
  [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ngx-doc-viewer *ngIf="fileType === 'doc'" [url]="doc" viewer="google" style="width: 100%; height: 90vh">
  </ngx-doc-viewer>
  <angular-image-viewer *ngIf="fileType === 'img'" [src]="[images]" [(index)]="imageIndexOne"></angular-image-viewer>
</p-dialog>