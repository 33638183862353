import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThemePalette } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BatchReportService } from 'src/app/services/batch-report-service';
import { ProductivityService } from 'src/app/services/productivity-service';
import * as moment from 'moment';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ButtonViewRendererComponent } from 'src/app/components/button-view-render.components';
import { element } from 'protractor';
@Component({
  selector: 'app-batch-report',
  templateUrl: './batch-report.component.html',
  styleUrls: ['./batch-report.component.scss']
})
export class BatchReportComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort  = new QueryList<MatSort>();
  @ViewChild('batchViewModal') private batchViewModal!: TemplateRef<any>;

  shiftList=[{
    value:'all',
    name:'ALL',
   
  },{
    value:'day',
    name:'Day',
  },{
    value:'night',
    name:'Night',
  }]
  
  public rowData!: any[];
  public columnDefs!: any[];
  public gridApi: any;
  public gridColumnApi: any;
  public frameworkComponents: any;
  public defaultExportParams!:any;
  public defaultColDef!: any;
  public detailCellRendererParams!: any;
  public excelStyles!:any;
  gridOptions: any = {
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      filter : true,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- & HERE
      minWidth: 225,
    },
  };
  constructor(
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private userService: UserManagementService,
    private dialog: MatDialog,
    private productivityservice: ProductivityService,
    private enterpriseGroupService: EnterPriseGroupService,
    private batchReportService :BatchReportService,
    public router: Router,
    private spinner: NgxSpinnerService
  ) { 
    // this.frameworkComponents = {
    //   buttonViewRenderer : ButtonViewRendererComponent,
    // }
    this.defaultExportParams = {
      getCustomContentBelowRow: function (params:any) {
        return [
          [
            cell('',''),
            cell('BOM Item Code', 'header'),
            cell('BOM Item Desc', 'header'),
            cell('Item UOM', 'header'),
            cell('Estimated Qty', 'header'),
            cell('Actual Qty', 'header'),
            cell('Supplier', 'header'),
            cell('Production date', 'header'),
            cell('Expire date', 'header')
          ],
        ].concat(
          params.node.data.batchItemBomList.map(function (record:any) {
            return [
              cell('',''),
              cell(record.ItemCode, 'body'),
              cell(record.ItemDesc, 'body'),
              cell(record.UOM, 'body'),
              cell(record.EsimatedQty, 'body'),
              cell(record.ItemBOMValue, 'body'),
              cell(record.Supplier, 'body'),
              cell(record.ProductionDate, 'body'),
              cell(record.ExpireDate, 'body')
            ];
          }),
          [[]]
        );
      },
      columnWidth: 120,
    };
    this.columnDefs = [
{headerName : 'Sr.No',  valueGetter: "node.rowIndex + 1" ,cellRenderer: 'agGroupCellRenderer'},
{headerName : 'PO', field:'PO'},
{headerName : 'Batch No', field:'BatchNo'},
{headerName : 'Operation', field:'Operation'},
{headerName : 'Item Code', field:'ItemCode'},
{headerName : 'Item Desc', field:'ItemDesc',resizable: true, wrapText: true },
// {headerName : 'Machine', field:'Machine'},
{headerName : 'Is Rework', field:'IsRework', cellRenderer: (params:any) => params.value === true ? "Yes" : "No",},
{headerName : 'Estimated Qt', field:'Estimated',valueFormatter: this.valueFormatter,precision: 3},
{headerName : 'Actual Qty', field:'Result', valueFormatter: this.valueFormatter, precision: 3},
{headerName : 'Difference', field:'Difference', valueFormatter: this.valueFormatter, precision: 3},
{headerName : 'Remarks', field:'Remarks'},
{headerName : 'Time', field:'RollStarttime',valueFormatter: function (params: any) { return moment(params.value).format('MM/DD/YYYY HH:mm') }},
{headerName : 'Created On', field:'CreatedOn',valueFormatter: function (params: any) { return moment(params.value).format('MM/DD/YYYY HH:mm') }},
{headerName : 'Created By', field:'CreatedBy'},
// {headerName : 'Action',  cellRenderer: 'buttonViewRenderer', cellRendererParams: { onClick: this.onClickView.bind(this), label: 'View' }, maxWidth: 85, suppressMenu: true}
    ];
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { headerName: 'BOM Item Code', field:'ItemCode', filter: true, resizable: true, wrapText: true  },
          { headerName: 'BOM Item Desc', field:'ItemDesc',  filter: true, resizable: true, wrapText: true  },
          { headerName: 'Item UOM', field: 'UOM', filter: true, resizable: true, wrapText: true  },
          { headerName: 'Estimated Qty', field: 'EsimatedQty', filter: true, resizable: true, wrapText: true  },
          { headerName: 'Actual Qty', field: 'ItemBOMValue', filter: true, resizable: true, wrapText: true  },
          { headerName: 'Supplier', field: 'Supplier', filter: true, resizable: true, wrapText: true  },
          { headerName: 'Production date', field: 'ProductionDate', filter: true, resizable: true, wrapText: true },
          { headerName: 'Expire date', field: 'ExpireDate', filter: true, resizable: true, wrapText: true  },
        ],
       defaultColDef: { flex: 1 },
      },
      getDetailRowData: function (params: any) {
      params.successCallback(params.data.batchItemBomList);
      },
    };
    this.excelStyles = [
      {
        id: 'header',
        interior: {
          color: '#a47ed9',
          pattern: 'Solid',
        },
      },
      {
        id: 'body',
        interior: {
          color: '#d1c1e8',
          pattern: 'Solid',
        },
      },
    ];
}

  // form unitDetailForm fields controls
  public disabled = true;
  public showSpinners = true;
  GroupList!: any[];
  PlantList1!: any[];
  lineList!: any[];
  unitList!: any[];
  itemList!: any[];
  filteredItemCodes!:any[];
  processOrderList!: any[];
  filteredPOs!:any[];

  batchReportViewDatasource!:  MatTableDataSource<any>;
  MachineId!: any;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  breadcrumList!: any;
  isPopupMachine = false;
  prodDesc!: any;
  NB!:number;
  TEQ! : any;
  TAQ!:number;
  menuRedIconUrl = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl = 'assets/images/menu-bottom-green-icon.png';

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
  });

  batchViewColumns: any[] = [
    'itemtype',
    'itemDesc',
    'itemuom',
    'estQty',
    'actQty',
    'supplier',
    'prodDate',
    'expireDate'
  ];
  batchForm!: FormGroup;
  UnitNode: any;
  Group: string | undefined;
  length : boolean = false;
  inputReadonly = true;
  UnitName: string | undefined;
  isUnitName = false;
  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    // // this.spinner.show(); 
    this.batchForm = this.formBuilder.group({
      UserGroup1: [],
      UserPlant1: [],
      startDate : [],
      endDate: [],
      line : [],
      unit:[],
      ItemCode: [],
      po: [],
      batchType :[],
      shift :[],
      queryCriteria :[],
    });

    this.datasource();
    this.batchForm.controls.line.valueChanges.subscribe((selectedValue: any) => {
      this.getUnit(selectedValue);
    });

    this.batchForm.controls.UserPlant1.valueChanges.subscribe((value: any) => {
      this.getLine(value);
    });

    this.batchForm.get('queryCriteria')?.patchValue('today');
    this.batchForm.get('shift')?.patchValue('all');
    this.batchForm.get('batchType')?.patchValue('0');
    const event = {
      value : 'today'
    }
    this.criteriaChange(event);  
  };

  ngAfterViewInit(): any {
  
  }

  onItemCodeChange(event : any):void { 
    this.getProcessOrder(event.value);
  }

  onPOChange(event : any):void{
    this.batchForm.get('po')?.setValue(event.value);
  }


  datasource(): void {
    const key = {
      Id: 0
    };
    const id = {
      value: 0
    };
    this.productivityservice.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.lstEG;
      id.value = data.lstEG[0].EG_Id;
      this.length = this.GroupList.length >1 ? true : false;
      this.batchForm.get('UserGroup1')?.patchValue(data.lstEG[0].EG_Id);
      this.batchForm.get('UserPlant1')?.patchValue(data.lstEG[0].EG_Id);
      this.getPlants(id);
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  onSubmit(): void {
   this.spinner.show();
   this.getBatchReport();
  }


  criteriaChange(event:any):void {
    let startDate = new Date();
    let endDate = new Date();
    if(event.value === 'lastshift'){
      startDate.setDate(startDate.getDate() -1);
      startDate.setHours(19);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      endDate.setHours(7);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      this.batchForm.get('startDate')?.patchValue(startDate);
      this.batchForm.get('endDate')?.patchValue(endDate);
      this.disabled = true;
    }else if(event.value === 'yesterday'){
      startDate.setDate(startDate.getDate() -1);
      startDate.setHours(7);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      endDate.setHours(7);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      this.batchForm.get('startDate')?.patchValue(startDate);
      this.batchForm.get('endDate')?.patchValue(endDate);
      this.disabled = true;
    }else if(event.value === 'today'){
      startDate.setHours(7);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      endDate.setHours(19);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      this.batchForm.get('startDate')?.patchValue(startDate);
      this.batchForm.get('endDate')?.patchValue(endDate);
      this.disabled = true;
    }else{
      this.disabled = false;
      this.batchForm.get('startDate')?.patchValue(startDate);
      this.batchForm.get('endDate')?.patchValue(endDate);
    }
  }

  getPlants(event: any): void {
    const key = {
      EG_ID: event.value,
      userId: 206
    };

    this.productivityservice.getPlantsData(key).subscribe((data: any) => {
      this.PlantList1 = data.lstPlant;
      const id = data.lstPlant[0].PT_Id;
      this.batchForm.get('UserPlant1')?.patchValue(id);
      this.getLine(id);
      this.getItemCode();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getLine(id: any) {
    const key = {
      PT_ID: id,
      userId: 206
    };

    this.productivityservice.getLineData(key).subscribe((data: any) => {
      this.lineList = data.lstLine;
      const lineDefault = this.lineList[0];
      this.batchForm.controls.line.patchValue(lineDefault.PL_Id);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getUnit(id: number) {
    const key = {
      PL_ID: id,
      userId: 206
    };

    this.productivityservice.getUnitData(key).subscribe((data: any) => {
      this.unitList = data.lstUnit;
      const unitDefault = this.unitList[0];
      if(unitDefault){
        this.batchForm.controls.unit.patchValue(unitDefault.PU_Id);
      }else{
        this.batchForm.controls.unit.patchValue("0");
      }
     
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });

  }

  getItemCode(): void {
    const key = {
      EGId: this.batchForm.value.UserGroup1,
      PTId: this.batchForm.value.UserPlant1
    };
    this.batchReportService.getItemCode(key).subscribe((data: any) => {
      this.itemList = data.ItemInfoList;
      this.filteredItemCodes = this.itemList.slice();
      this.batchForm.get('ItemCode')?.patchValue(this.itemList[0].ItemCode);
      const id = this.itemList[0].ItemCode;
      this.getProcessOrder(id);
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getProcessOrder(itemCode:any): void {
    const key = {
      ItemCode: itemCode,
      PTId :this.batchForm.value.UserPlant1
    };
    this.batchReportService.getProcessOrder(key).subscribe((data: any) => {
      this.processOrderList = data.masterList;
      this.filteredPOs = this.processOrderList.slice();
      this.processOrderList.length > 0 ? this.batchForm.get('po')?.setValue(this.processOrderList[0].TypeName):this.batchForm.get('po')?.setValue("0");
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  getBatchReport():void {
    const key = {
      PTID: this.batchForm.value.UserPlant1,
      PLId: this.batchForm.value.line,
      PUId: this.batchForm.value.unit,
      ItemCode: this.batchForm.value.ItemCode,
      PO: this.batchForm.value.po,
      BatchType: parseInt(this.batchForm.value.batchType),
      FromDate: this.batchForm.value.startDate,
      ToDate: this.batchForm.value.endDate,
      shift : this.batchForm.value.shift
    };
    this.batchReportService.getBatchReport(key).subscribe((data: any) => {
      this.rowData = data.BatchItemBOMList;
      const selectedLine = this.lineList.find(element => element.PL_Id === this.batchForm.value.line);
      let sumEQ = 0;
      if(selectedLine.PL_Desc === 'Vegetables' && data.BatchItemBOMList.length > 0){
        data.BatchItemBOMList[0].batchItemBomList.forEach((element:any) => {
            sumEQ = sumEQ + element.EsimatedQty;
        });
        this.NB = data.NB.toFixed(2);
        this.TEQ = sumEQ.toFixed(2);
        this.TAQ = data.TAQ.toFixed(2);
      }else{
        this.NB = data.NB.toFixed(2);
        this.TEQ = data.TEQ.toFixed(2);
        this.TAQ = data.TAQ.toFixed(2);
      }
      this.spinner.hide();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
      this.spinner.hide();
    });
  }

  onClickView(data:any):void{
    this.openPopupViewBatchHistory(data.data);
  }

  openPopupViewBatchHistory(element: any): void {
    this.dialog.open(this.batchViewModal, {
      width: '100vw !important',
        height: 'auto',
        minWidth: '100vw',
    })
     this.getBatchItemBOMInfo(element);
  }

  getBatchItemBOMInfo(element : any):void{
    const key = {
      Id : element.JobBatchId
    };
    this.batchReportService.getBatchItemBOMReport(key).subscribe((data: any) => {
      this.batchReportViewDatasource = data.batchItemBOMList;
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

 
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }
  //===================================================================== AG Grid (TODO : AbdurRazique) =================================================================//
  onFirstDataRendered(params: any) {
    setTimeout(function () {
      params.api.getDisplayedRowAtIndex(1)?.setExpanded(true);
    }, 0);
  }
  excelExport() {
    this.gridApi.exportDataAsExcel();
  }
  csvExport(){
    this.gridApi.exportDataAsCsv();
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit(); 
  }
  valueFormatter(params: any) {
    return params.value.toFixed(params.colDef.precision);
  }

}
function cell(text:any, styleId:any) {
  return {
    styleId: styleId,
    data: {
      type: /^\d+$/.test(text) ? 'Number' : 'String',
      value: String(text),
    },
  };
}