import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MachineMapping } from 'src/app/models/usermanagement/MachineMapping';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
interface Group {
 value: string;
 viewValue: string;
}
@Component({
      selector: 'app-machinemapping',
        templateUrl: './machinemapping.component.html',
        styleUrls: ['./machinemapping.component.scss']
      })
export class MachinemappingComponent implements OnInit {

 @ViewChild(MatTable) table: MatTable<MachineMapping> | undefined;
 @ViewChild(MatPaginator) paginator!: MatPaginator;
 @ViewChild(MatSort) sort!: MatSort;
 @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;


 GroupList!: any[];
 UsersList!: any[];
 PlantList!: any[];
 ProductList!: any[];
 UnitsList!: any[];
 machineId!: number;
 UserId!: number;
 machineData!: MatTableDataSource<MachineMapping>;
 userRoleForm!: FormGroup;
 plantDataForm!: FormGroup;
 machineForm = true;
 status: Group[] = [
   { value: 'None', viewValue: 'None' },
 ];
 MachineMapData: MachineMapping[] = [];
 MachineMappingColumns: string[] = ['MachineRoleId', 'ProductUnit', 'CreatedOn', 'CreatedBy', 'Actions'];
 machineMappingData = new MatTableDataSource<MachineMapping>();
  GroupId!: number;
  filteredGroups: any;
  filteredUsers: any;
 // tslint:disable-next-line: no-shadowed-variable
 constructor(private formBuilder: FormBuilder,
             private dialog: MatDialog,
             private userService: UserManagementService,
             private machineService: MachineMappingService,
             private spinner: NgxSpinnerService,
              public router: Router,
             private toaster: ToastrService) { }
 ngOnInit(): void {
  this.datasource();
  this.userRoleForm =  this.formBuilder.group({
     userGroup: [''],
     UserName: [''],
     plant : [''],
     product : ['']
   });
  this.plantDataForm =  this.formBuilder.group({
    plant : [''],
    productLine : [''],
    productUnit : ['']
  });
 }

 datasource(): void {
  const key = {
    Id : 0
  };
  this.userService.getGroupData(key).subscribe( (data: any) => {
  this.GroupList = data.masterList;
  this.userRoleForm.get('userGroup')?.setValue(data.masterList[0].TypeId);
  this.GroupId = this.GroupList[0].TypeId;
  this.filteredGroups = this.GroupList.slice();
  this.getUserslist();
}, (error: any) => {
  this.toaster.error('error', error.message);
});
}

getUserslist(): void {
  const key = {
    GroupId : this.userRoleForm.value.userGroup 
  };
  this.spinner.show();
  this.machineData = new MatTableDataSource<MachineMapping>();
  this.userService.getUsersData(key).subscribe((data: any) => {
    this.UsersList = data.UserList;
  this.spinner.hide();

    this.userRoleForm.get('UserName')?.setValue(data.UserList[0].UserId);
    this.UserId = this.UsersList[0].UserId;
    this.filteredUsers = this.UsersList.slice();
    this.getMachineData();   
  }, (error: any) => {
  this.spinner.hide();

    this.toaster.error('error', error.error.message);
  });
}

getMachineData(): void {
  const key = {
  UserId :  this.userRoleForm.value.UserName
};
  // tslint:disable-next-line: align
  this.spinner.show();

  this.machineService.getMachineMappingData(key).subscribe((data: any) => {
    if(data.MachineMaplist){
    this.machineData = new MatTableDataSource<MachineMapping>(data.MachineMaplist);
  this.spinner.hide();

    this.getPaginator();
    }
  }, (error: any) => {
  this.spinner.hide();

    this.toaster.error('Error', error.message);
  });
  }
applyFilter(event: Event): void {
  const filterValue = (event.target as HTMLInputElement).value;
  this.machineData.filter = filterValue.trim().toLowerCase();

  if (this.machineData.paginator) {
    this.machineData.paginator.firstPage();
  }
}
 openUnit(): void {
 }

 getproducts(event: any): void {
   const data = {
     Dept_Id : event.value
   };
   this.machineService.getProductData(data).subscribe(( response: any) => {
    this.ProductList = response.LineList;
   }, (error: any) => {
     this.toaster.error('Error', error.message);
   });
 }
 getUnits(event: any): void {
   const key = {
    PL_Id : event.value,
    UserId : this.userRoleForm.value.UserName
   };
    this.machineService.getUnitData(key).subscribe(( response: any) => {
    this.UnitsList = response.UnitList;
   }, (error: any) => {
     this.toaster.error('Error', error.message);
   });
 }
 onSubmit(event: any): void {
   const key = {
     plid :  this.plantDataForm.value.productLine,
     Puid : this.plantDataForm.value.productUnit,
     Deptid : this.plantDataForm.value.plant,
     TransactionTypeId : 1,
     UserMachineMappId : 0,
     UserId : this.userRoleForm.value.UserName,
     CreatedBy : 0
   };
   this.machineService.AddMachineData(key).subscribe((data: any) => {
    this.showPopupMsg(data.ErrorMessage);
    this.dialog.closeAll();
     this.getMachineData();
}, (error: any) => {
  this.showPopupMsg(error.ErrorMessage);
});
 }
 highlight(row: any): void {
 this.machineId = row.MachineMapId;
 }
 Update(event: any): void {
  const key = {
    plid :  this.plantDataForm.value.productLine,
    Puid : this.plantDataForm.value.productUnit,
    Deptid : this.plantDataForm.value.plant,
    TransactionTypeId1 : 2,
    UserMachineMappId : 1,
    UserId : this.userRoleForm.value.UserName
  };
}

DeleteMachine(): void {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to delete Machine!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it',
  }).then( (Result: any) => {
    if (Result.dismiss === Swal.DismissReason.cancel){
      Swal.fire('Cancelled', 'Your Machine is safe :)', 'error');
}
else if (Result){
  const key = {
    TransactionTypeId : 3,
    UserMachineMappId : this.machineId,
    UserId : this.userRoleForm.value.UserName
  };
  this.machineService.AddMachineData(key).subscribe((data: any) => {
    this.showPopupMsg(data.ErrorMessage);
    Swal.fire({
      title: 'Deleted!',
      text: 'Your selected Machine has been deleted.',
      icon: 'success',
      timer: 800,
      showConfirmButton: false,
    });
    this.getMachineData();
   }, (error: any) => {
     this.showPopupMsg(error.ErrorMessage);
   });
 }
});
}
getPaginator(): void {
  setTimeout(() => {
    this.machineData.paginator = this.paginator;
    this.machineData.sort = this.sort;
  }, 1000);
}
 openModal(): void {
   const key = {
     GroupId : this.userRoleForm.value.userGroup
   };
   this.dialog.open(this.displayRoleModal, {height: 'auto', width: 'auto'});

   this.machineService.getPlantsData(key).subscribe((data: any) => {
    this.PlantList = data.DepartList;
   }, (error: any) => {
     this.toaster.error('Error', error.message);
   });
 }

 showPopupMsg(msg: any): any {
  if (msg.substring(2, 1) === '0') {
     this.toaster.success('Success', msg.substring(3, msg.length));
  }
  else {
    this.toaster.error('Error', msg.substring(3, msg.length));
  }
}

}



