import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { CostCenterComponent } from '../cost-center/cost-center.component';
import { CostelementComponent } from '../costelement/costelement.component';
import { TdcelementComponent } from '../tdcelement/tdcelement.component';

@Component({
  selector: 'app-tdc-MasterData',
  templateUrl: './tdc-MasterData.component.html',
  styleUrls: ['./tdc-MasterData.component.scss']
})
export class TdcMasterDataComponent implements OnInit {

  @ViewChild(CostelementComponent) CostelementComponent!: CostelementComponent;
  @ViewChild(CostCenterComponent) CostCenterComponent!: CostCenterComponent;
  @ViewChild(TdcelementComponent) TdcComponent!: TdcelementComponent;
  searchCostMasterForm!: FormGroup;
  plants!: any;
  groupListDDL!: any[];
  breadcrumList: any;
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private userManagementService: UserManagementService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.searchCostMasterForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.getGroupData();
    this.GetBreadcrumList();
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.userManagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.searchCostMasterForm
          .get('group')
          ?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.searchCostMasterForm.get('group')?.value,
    };
    // this.MachineId = null;
    this.userManagementService.getDepartments(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.searchCostMasterForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getMasterData();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getMasterData(): void {
    const plant = this.searchCostMasterForm.get('plant')?.value;
    const group = this.searchCostMasterForm.get('group')?.value;
    const key = {
      plantId: plant,
      groupId: group,
    };
    this.CostelementComponent.getCostMaster(key);
    this.CostCenterComponent.GetCostCenter(key);
    this.CostelementComponent.getCenterGroupList(key);
    this.TdcComponent.GetAllTDCElements(key);
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        console.log(data.BreadcrumList);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
