import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-AccessSettings',
  templateUrl: './AccessSettings.component.html',
  styleUrls: ['./AccessSettings.component.scss']
})
export class AccessSettingsComponent implements OnInit {

  usermanagements!: any[];
  constructor(public router: Router, private toaster: ToastrService) { }

  ngOnInit(): void {
    this.usermanagements = [
      // tslint:disable-next-line:max-line-length
      {
        title: 'User Manager',
        routerLink: this.getUserManager,
        icon: 'assets/images/manufacturing-admin.svg',
        disabled: false
      },
      {
        title: 'Role Manager',
        routerLink: this.getUserRole,
        icon: 'assets/images/manufacturing-admin.svg',
        disabled: false
      },
      {
        title: 'User RoleMap',
        routerLink: this.getUserRoleMapping,
        icon: 'assets/images/manufacturing-admin.svg',
        disabled: false
      },
      {
        title: 'User MachineMap',
        routerLink: this.getMachineMapping,
        icon: 'assets/images/manufacturing-admin.svg',
        disabled: false
      },
      {
        title: 'People Master Data Map',
        routerLink: this.getPeopleMasterDataMap,
        icon: 'assets/images/manufacturing-admin.svg',
        disabled: false
      },
    ];
  }

  getUserManager = (): void => {
    this.router.navigate(['/Access/UserManager']);
  }
  getUserRole = (): void => {
    this.router.navigate(['/Access/RoleManager']);
  }
  getUserRoleMapping = (): void => {
    this.router.navigate(['/Access/RoleMap']);
  }
  getMachineMapping = (): void => {
    this.router.navigate(['/Access/MachineMap']);
  }
  getPeopleMasterDataMap = (): void => {
    this.router.navigate(['/Access/PeopleMasterDataMap']);
  }

}
