import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { ManagerExecutionService } from '../../services/manager.service';
import { OperatorExecutionService } from 'src/app/services/operator-execution.service';
import Swal from 'sweetalert2';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { EditBtnRenderer } from 'src/app/edit-action-btn-renderer.component';
import { DeleteBtnRenderer } from 'src/app/delete-action-btn-renderer.component';
import * as moment from 'moment';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TechnicianService } from 'src/app/services/technician.service';
import { CILMasterService } from 'src/app/services/cil-master-service';
import { ActionEditDeleteViewBtnRenderer } from 'src/app/action-edit-delete-view-btn-renderer.component';
import * as XLSX from 'xlsx';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-operator-execution',
  templateUrl: './operator-execution.component.html',
  styleUrls: ['./operator-execution.component.scss']
})
export class OperatorExecutionComponent implements OnInit {
  woStatus: any = [];
  woType = [
    { id: 1, title: 'Electrical' },
    { id: 2, title: 'Mechanical' },
  ];
  failMode = [
    { id: 1, title: 'Mode 1' },
    { id: 2, title: 'Mode 2' },
  ];
  sparePart = [
    { id: 0, title: 'No' },
    { id: 1, title: 'Yes' },
  ];

  QualityareaColumns: string[] = ['WOId', 'LineId', 'TimeStamp', 'WOStatus', 'WOType', 'FailureMode', 'SpareParts', 'Remark', 'UploadPicture', 'action'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatAccordion) accordion: MatAccordion = new MatAccordion;
  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  opratorList: any = { 'LineId': 0, 'TimeStamp': 0, 'WOId': 0, 'WOStatus': '', 'WOType': '', 'FailureMode': '', 'SpareParts': '', 'Remarks': 0, 'UploadPicture': "", 'RequestedBy': 0, userList: [] };
  spinnerText: string = "";
  WOStatus: any = '';
  WOType: any = '';
  user: any;
  operator: any;
  userList: any = [];
  userListReq: any = [];
  breadcrumList!: any;
  IsWrite: boolean = false;
  isSuperAdmin: boolean = false;
  HeaderData: any = { Group: '', Plant: '' };
  HeaderForm!: FormGroup;
  GroupList: any = [];
  PlantList: any = [];
  GroupTempList: any = [];
  PlantTempList: any = [];
  technicianList: any = []
  dateFilter: any = { From: '', To: '' }
  ExportOperatorList: any = [];

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  frameworkComponents: any;
  rowData!: any[];
  columnDefs!: any[];
  gridApi: any;
  gridColumnApi: any;

  gridOptions: any = {
    defaultColDef: {
      paginationPageSize: 5,
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true,     // <-- HERE
      autoHeight: true,   // <-- HERE
      minWidth: 225,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
    },
    popupParent: document.querySelector('body'),
  };
  lang: any;
  constructor(
    public router: Router, 
    private fb: FormBuilder, 
    private cilMasterService: CILMasterService, 
    private technicianService: TechnicianService, 
    private enterpriseGroupService: EnterPriseGroupService, 
    public managerExecutionService: ManagerExecutionService, 
    private spinner: NgxSpinnerService, 
    private operatorExecutionService: OperatorExecutionService, 
    private toaster: ToastrService, 
    private userManagementService: UserManagementService,
    private commonService: CommonService) {
    this.frameworkComponents = {
      editRenderer: EditBtnRenderer,
      deleteRenderer: DeleteBtnRenderer,
      actionEditDeleteViewBtnRenderer: ActionEditDeleteViewBtnRenderer
    }
    this.columnDefs = [
      {
        headerName: 'Action',
        field: 'Action',
        cellRenderer: 'actionEditDeleteViewBtnRenderer',
        minWidth: 140,
        cellRendererParams: {
          onClickEdit: this.editRow.bind(this),
          onClickDelete: this.deleteRow.bind(this),
          onClickView: this.viewRow.bind(this),
        },
        
      },
      {
        headerName: 'WO Status', minWidth: 150,
        field: 'WOStatus', filter: 'agTextColumnFilter', cellStyle: function (params: any) {
          if (params.value == 'Complete' || params.value == 'Close') {
            return { color: 'white', backgroundColor: 'green' };
          }
          else if (params.value == 'In Progress') {
            return { color: 'black', backgroundColor: 'orange' };
          } else {
            params.value = params.value == 'ReOpen' ? 'ReOpened' : params.value;
            return { color: 'white', backgroundColor: 'red' };
          }
        },
        
      },
      {
        headerName: 'WO Id', minWidth: 90, field: 'displayWOId', filter: 'agTextColumnFilter', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'WO Detail', minWidth: 300, field: 'Remark', filter: 'agTextColumnFilter', wrapText: true, cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
      },
      {
        headerName: 'WO Type', minWidth: 150, field: 'WOType', filter: 'agTextColumnFilter', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Line/Area', minWidth: 350, field: 'LineId', filter: 'agTextColumnFilter', wrapText: true, cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      { headerName: 'WO Creation Stamp', minWidth: 180, field: 'TimeStamp', filter: 'agTextColumnFilter', valueFormatter: function (params: any) { return moment(params.value).format('DD/MM/YYYY hh:mm a') },  },

      {
        headerName: 'Failure Mode', minWidth: 150, field: 'FailureMode', filter: 'agTextColumnFilter', cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html;
        },
        
      },
      {
        headerName: 'Spare Parts', minWidth: 130, field: 'SpareParts', filter: 'agTextColumnFilter', cellRenderer: function (params: any) {
          let html = '';
          if (params.value == 1) {
            html = `Required`
          }
          else {
            html = `Not Required`
          }
          return html;
        },
        
      },
      { headerName: 'Assigned Technician', field: 'assignedUserName', minWidth: 220, },
      {
        headerName: ' Technician StartDate',
        field: 'techExc.StartTime',
        minWidth: 220,
        filter: 'agTextColumnFilter',
        valueFormatter: function (params: any) {
          if(params.value){
          return moment(params.value).format('DD/MM/YYYY hh:mm a');
        }else{
          return 
        }
        },
        
      },
      {
        headerName: 'Technician CompleteDate',
        field: 'techExc.CompleteTime',
        minWidth: 220,
        filter: 'agTextColumnFilter',
        valueFormatter: function (params: any) {
          if (params.value) {
            return moment(params.value).format('DD/MM/YYYY hh:mm a');
          } 
          else {
          }
        },
        
      },
    ];
  }

  ngOnInit(): void {
    this.woStatus = this.operatorExecutionService.getWoStatusList();
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.HeaderForm = this.fb.group({
      group: new FormControl(null, [Validators.required]),
      plant: new FormControl(null, [Validators.required]),
      fromDate: new FormControl(null),
      toDate: new FormControl(null,),

    });
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 1);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    this.dateFilter = {
      From: new Date(stDT),
      To: new Date(),
    };
    let woHeaderData = this.operatorExecutionService.getWOHeaderData();
    if (woHeaderData) {
      if (woHeaderData.Group)
        this.HeaderData.Group = woHeaderData.Group;
      if (woHeaderData.Plant)
        this.HeaderData.Plant = woHeaderData.Plant
      if (woHeaderData.FromDate)
        this.dateFilter.From = woHeaderData.FromDate;
      if (woHeaderData.ToDate)
        this.dateFilter.To = woHeaderData.ToDate;
    }
    this.getGroups();
    this.GetBreadcrumList();
    this.GetMenuAccess();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsWrite = data.IsWrite;
      this.isSuperAdmin = data.IsSuperAdmin;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getUserList() {
    this.spinnerText = 'Fetching User Data';
    this.spinner.show();
    const data = {
      EGId: this.HeaderData.Group,
      PlantId: this.HeaderData.Plant,
    };
    this.commonService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        if (res.employeeInfoList && res.employeeInfoList.length) {
          this.userListReq = res.employeeInfoList;
          this.userList = res.employeeInfoList.filter(
            (emp: any) =>
              emp.UserId && emp.Dept_Desc && emp.Dept_Desc == 'Maintenance'
          );
          this.getOperatorData();
        } else {
          this.userList = [];
          this.spinner.hide();
          // this.setUserName();
        }
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  getFilterData() {
    if (!this.HeaderData.Group || !this.HeaderData.Plant)
      return
    else {
      this.getOperatorData();
    }
  }

  setUserName() {
    if (
      this.userList &&
      this.userList.length &&
      this.opratorList &&
      this.opratorList.length
    ) {
      for (let operator of this.opratorList) {
        const reqUser = this.userListReq.find(
          (usr: any) => usr.UserId == operator.RequestedBy
        ); // this changes by Mr. Najam 15-03-2022
        const assignedUser = this.userList.find(
          (usr: any) => usr.UserId == operator.assignUser
        );
        operator.requestedUserName = reqUser
          ? reqUser.fullName
          : operator.RequestedBy === 100001
          ? 'Admin'
          : '';
        operator.assignedUserName = assignedUser
          ? assignedUser.fullName
          : 'Not Assigned Yet';
      }
      this.rowData = this.opratorList;

      this.rowData.sort(function (a: any, b: any) {
        return (
          new Date(b.TimeStamp).valueOf() - new Date(a.TimeStamp).valueOf()
        );
      });
    }
  }

  getOperatorData() {
    const obj = {
      UserId: this.isSuperAdmin ? 0 : this.user.UserId,
      WOId: '',
      From: this.dateFilter.From,
      To: this.dateFilter.To,
      groupId: this.HeaderData.Group,
      plantId: this.HeaderData.Plant
    }
    this.spinnerText = "Fetching Operator Data";
    this.spinner.show();
    this.managerExecutionService.getOperatorData(obj).subscribe((res: any) => {
      this.spinner.hide();
      if (res.MEWo) {
        this.opratorList = res.MEWo;
        this.woStatus = this.operatorExecutionService.getWoStatusList();
        for (let i = 0; i < this.opratorList.length; i++) {
          this.WOStatus = this.woStatus.find((element: any) => element.id == parseInt(res.MEWo[i].WOStatus));
          this.opratorList[i].WOStatus = this.WOStatus && this.WOStatus.title ? this.WOStatus.title : '';
          this.opratorList[i].WOType = this.opratorList[i].WOType.Name;
          this.opratorList[i].FailureMode = this.opratorList[i].FailureMode.Name;
          var line = res.MEWo[i].line ? res.MEWo[i].line?.plDesc : '';
          var unit = res.MEWo[i].units ? res.MEWo[i].units?.puDesc : '';
          var assem = res.MEWo[i].assembly ? res.MEWo[i].assembly?.assblyDesc : '';
          var subaseem = res.MEWo[i].subAssembly ? res.MEWo[i].subAssembly?.sAsblyDesc : '';
          var com = res.MEWo[i].component ? res.MEWo[i].component?.componentName : '';
          this.opratorList[i].UploadPicture = res.MEWo[i].UploadPicture ? res.MEWo[i].UploadPicture.split(',')[0] : '';
          this.opratorList[i].LineId = line + '-' + unit + '-' + assem + '-' + subaseem + '-' + com;
          this.opratorList[i].displayWOId = res.MEWo[i].WOId;
          this.opratorList[i].WOId = res.MEWo[i].ID;
          this.opratorList[i].TimeStamp = this.opratorList[i].TimeStamp;
          this.opratorList[i].CreatedAt = this.opratorList[i].CreatedAt;
          this.opratorList[i].ClosedAt = this.opratorList[i].ClosedAt;

          this.opratorList[i].assignUser =
          this.opratorList[i].managerExc &&
          this.opratorList[i].managerExc.assignUser
            ? this.opratorList[i].managerExc.assignUser
            : '';
            
            if (this.opratorList[i].techExc) {
              this.opratorList[i].techExc.StartTime=this.opratorList[i].techExc.StartTime;
              this.opratorList[i].techExc.CompleteTime=this.opratorList[i].techExc.CompleteTime;
            }
        }
        this.rowData = this.opratorList;
        this.rowData.map((row: any) => {
          if (row.AssignedUserID === -1) {
            row.IsWrite = false
          } else {
            row.IsWrite = this.IsWrite
          }
        })

        this.rowData.sort(function (a: any, b: any) {
          return new Date(b.TimeStamp).valueOf() - new Date(a.TimeStamp).valueOf();
        });
        this.setUserName();
      }
    }, (err: any) => {
      this.spinner.hide();
    });
  }

  addOperator() {
    this.managerExecutionService.setManagerData('');
    const data = {
      Group: this.HeaderData.Group,
      Plant: this.HeaderData.Plant,
      FromDate: this.dateFilter.From,
      ToDate: this.dateFilter.To,
    }
    this.operatorExecutionService.setWOHeaderData(data);

    this.router.navigate(['/PM/operator-form']);
  }

  getGroups() {
    const key = {
      UserId: ''
    };
    const id = {
      value: 0
    };
    this.cilMasterService.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.dropDownList;
      this.GroupTempList = data.dropDownList;
      if (this.GroupTempList && this.GroupTempList.length) {
        if (this.HeaderData.Group) {
          const find = this.GroupTempList.find((grp: any) => grp.id == this.HeaderData.Group);
          this.HeaderData.Group = find ? find.id : this.GroupList[0].id
        }
        else
          this.HeaderForm.get('group')?.setValue(this.GroupList[0].id);
        this.getPlants(this.HeaderData.Group)
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getPlants(id: any): void {
    this.cilMasterService.getPlantsData(id).subscribe((data: any) => {
      this.PlantList = data.dropDownList;
      this.PlantTempList = data.dropDownList;
      if (this.PlantTempList && this.PlantTempList.length) {
        if (this.HeaderData.Plant) {
          const find = this.PlantTempList.find((pnt: any) => pnt.id == this.HeaderData.Plant);
          this.HeaderData.Plant = find ? find.id : this.PlantList[0].id
        }
        else
          this.HeaderForm.get('plant')?.setValue(this.PlantList[0].id);
        this.getUserList()
      }
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }

  onGroupKey(value: any) {
    this.GroupList = this.searchGroupKey(value.value);
  }

  searchGroupKey(value: string) {
    let filter = value;
    return this.GroupTempList.filter((grp: any) => {
      let lowerCase = grp.name.toLocaleLowerCase();
      return grp.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  onPlantKey(value: any) {
    this.PlantList = this.searchPlantKey(value.value);
  }

  searchPlantKey(value: string) {
    let filter = value;
    return this.PlantTempList.filter((grp: any) => {
      let lowerCase = grp.name.toLocaleLowerCase();
      return grp.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  getTechnicianList(): void {
    this.spinner.show();
    this.spinnerText = "Fetching Technician Data";
    this.technicianService.getTechnicianList(this.HeaderData).subscribe((res: any) => {
      this.spinner.hide();
      this.technicianList = [];
      if (res.data && res.data.length) {
        for (let user of res.data) {
          const usr = this.technicianList.find((usr: any) => usr.UserId == user.UserId);
          if (!usr)
            this.technicianList.push(user)
        }
      }
      this.getUserList();
    },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.error.message);
      }
    );
  }

  ExportTOExcel() {
    this.ExportOperatorList = [];
    if (this.rowData && this.rowData.length) {
      for (const row of this.rowData) {
        const ExportData = {
          ['WO Status']: row && row.WOStatus ? row.WOStatus : '',
          ['WO ID']: row && row.WOId ? row.WOId : '',
          ['WO Detail']: row && row.Remark ? row.Remark : '',
          ['WO Creation Date']: row && row.TimeStamp ? moment(row.TimeStamp).format('DD/MM/YYYY hh:mm a') : '',
          ['WO Type']: row && row.WOType ? row.WOType : '',
          ['Failure Mode']: row && row.FailureMode ? row.FailureMode : '',
          ['Group']: row && row.Group.egDesc ? row.Group.egDesc : '',
          ['Line/Area']: row && row.LineId ? row.LineId : '',
          ['Spare Parts']: row && row.SpareParts ? 'Required' : 'Not Required',
          ['Technician StartDate']:row && row.techExc?.StartTime ?  moment(row.techExc?.StartTime).format('DD/MM/YYYY hh:mm a'):'',
          ['Technician CompleteDate']:row && row.techExc?.CompleteTime ?  moment(row.techExc?.CompleteTime).format('DD/MM/YYYY hh:mm a'):'',
        };
        this.ExportOperatorList.push(ExportData)
      }
    }
    else {
      this.ExportOperatorList = [{
        ['WO Status']: '',
        ['WO ID']: '',
        ['WO Detail']: '',
        ['WO Type']: '',
        ['WO Creation Date']: '',
        ['Group']: '',
        ['Line/Area']: '',
        ['Failure Mode']: '',
        ['Spare Parts']: '',
        ['Technician StartDate']:'',
        ['Technician CompleteDate']:'',
      }];
    }
    const workBook = XLSX.utils.book_new();
    const WOSheet = XLSX.utils.json_to_sheet(this.ExportOperatorList);
    XLSX.utils.book_append_sheet(workBook, WOSheet, 'Work Order');
    XLSX.writeFile(workBook, `Work Order List.xlsx`);
  }

  editRow(element: any) {
    element.rowData.isView = false
    this.managerExecutionService.setManagerData(element.rowData);
    this.managerExecutionService.setUserData(this.opratorList.userList);
    const data = {
      Group: this.HeaderData.Group,
      Plant: this.HeaderData.Plant,
      FromDate: this.dateFilter.From,
      ToDate: this.dateFilter.To,
    }
    this.operatorExecutionService.setWOHeaderData(data);
    this.router.navigate(['/PM/operator-form']);
  }

  viewRow(element: any) {
    element.rowData.isView = true
    this.managerExecutionService.setManagerData(element.rowData);
    this.managerExecutionService.setUserData(this.opratorList.userList);
    const data = {
      Group: this.HeaderData.Group,
      Plant: this.HeaderData.Plant,
      FromDate: this.dateFilter.From,
      ToDate: this.dateFilter.To,
    }
    this.operatorExecutionService.setWOHeaderData(data);
    this.router.navigate(['/PM/operator-form']);
  }

  deleteRow(element: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete WO!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result: any) => {
      if (result.value) {
        this.spinner.show();
        this.operatorExecutionService.deleteOperaterData(element.rowData.WOId).subscribe(
          (success) => {
            this.toaster.success(`Data Deleted Successfully`, 'Success', {
              timeOut: 3000,
            });
            this.rowData = [];
            this.spinner.hide();
            this.getOperatorData();
          },
          (error) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
      }
    });
  }

  onFilterChanged(event: any) {
    this.gridOptions.api.setQuickFilter(event.value);
  }

  /// AG Grid 
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

}
