import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { StockChart } from 'angular-highcharts';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { QualityMachineTrendDashboardService } from 'src/app/services/quality-machine-trend-dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ParameterAnalysisDashboard',
  templateUrl: './ParameterAnalysisDashboard.component.html',
  styleUrls: ['./ParameterAnalysisDashboard.component.scss']
})

export class ParameterAnalysisDashboardComponent implements OnInit{
  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Analysis',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptions1: MatProgressButtonOptions = {
    active: false,
    text: 'Comparison',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  breadcrumList: any;
  FilterDataForm!: FormGroup;
  chart!: any;
  groupListDDL: any;
  plants: any;
  stock!: StockChart;
  MachineParameterArray: any[] = [];
  QualityParameterArray: any[] = [];
  chartArray: any[] = [];
  highChartsColor = [
    '#00008B',
    '#34A56F',
    '#F80000',
    '#2D0A77',
    '#8C001A',
    '#FF69B4',
    '#FA4A08',
    '#5453A6',
    '#8B008B',
    '#3090C7',
    '#F1C40F',
    '#CB4335',
    '#36454F',
    '#A569BD',
    '#FFA500',
    '#179fcc',
    '#093323',
    '#08fa03',
    '#048b8e',
    '#9637A4',
    '#F39C12',
    '#0b774e',
    '#F5B7B1',
    '#0d98da',
    '#512E5F',
    '#C471A5',
    '#0034bb',
    '#06573d',
    '#0d6e54',
    '#0692aa',
    '#611745',
    '#F80000',
    '#FFDF00',
    '#6D81A8',
    '#08FAC7',
    '#D208FA',
    '#FAEB08',
  ];
  analysisType: boolean = true;
  constructor
  (
    private toaster: ToastrService,
    private fb: FormBuilder,
    private usermanagementService: UserManagementService,
    private spinner: NgxSpinnerService,
    private trendDashboardService: QualityMachineTrendDashboardService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.GetBreadcrumList();
    var date = new Date();
    var firstDayfincialGap = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    var lastDayfincialGap = new Date();
    firstDayfincialGap = new Date(new Date(firstDayfincialGap).setHours(0, 0, 0, 0));
    lastDayfincialGap = new Date(new Date(lastDayfincialGap).setHours(23, 59, 59, 59));
    this.FilterDataForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      machineParam:[''],
      qualityParam:[''],
      datePicker: ['']

    });
    this.FilterDataForm.get('datePicker')?.setValue([firstDayfincialGap, lastDayfincialGap]);
    this.getGroupData();
    this.getQualityParametersDropdown();
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.FilterDataForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.FilterDataForm.get('group')?.value,
    };
    this.usermanagementService.getDepartments(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.FilterDataForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getMachineParametersDropdown();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getMachineParametersDropdown(){
    let key={
      PlantId:this.FilterDataForm.get('plant')?.value
    };
    this.trendDashboardService.getMachineParametersDropdownByPlantId(key).subscribe({
    next: (res) => {
      this.MachineParameterArray = res;
    }, error: ({error}) => {
      this.toaster.error(error?.error);
    }
    });
  }

  getQualityParametersDropdown(){
    this.trendDashboardService.getQualityParametersForQualityParameterAnalysis().subscribe({
    next: (res) => {
      this.QualityParameterArray = res;
    }, error: ({error}) => {
      this.toaster.error(error?.error);
    }
    });
  }

  onClickParameterAnalysis(): void {
    this.analysisType = true;
    this.spinnerButtonOptions.active = true;
    this.chartArray = [];
    let AllIds:any=[];
    let machineIds : any[] = [];
    let qualityChemicalIds : any[] = [];
    let qualityPhysicalIds : any[] = [];
    let chemicalLabQualityParams: any[] = [];
    let physicalLabQualityParams: any[] = [];
    if (this.FilterDataForm.value.machineParam) {
      machineIds = this.FilterDataForm.value.machineParam.map(
        (a:any)=>a.id
      );
    }
    if(this.FilterDataForm.value.qualityParam){
      chemicalLabQualityParams = this.FilterDataForm.value.qualityParam.filter((item: any) => {
        return item.ParamType == 'QualityChemicalLab';
      });
      physicalLabQualityParams = this.FilterDataForm.value.qualityParam.filter((item: any) => {
        return item.ParamType == 'QualityPhysicalLab';
      });
      qualityChemicalIds = chemicalLabQualityParams.map(
        (a:any)=>a.id
      );
      qualityPhysicalIds = physicalLabQualityParams.map(
        (a:any)=>a.id
      );
    }
    if(machineIds.length > 0)
    {
      machineIds.forEach((el: any) => {
        AllIds.push({id:el, type:'Machine'})
      });
    }
    if(qualityChemicalIds.length > 0)
    {
      qualityChemicalIds.forEach((el: any) => {
        AllIds.push({id:el, type:'QualityChemicalLab'})
      });
    }
    if(qualityPhysicalIds.length > 0)
    {
      qualityPhysicalIds.forEach((el: any) => {
        AllIds.push({id:el, type:'QualityPhysicalLab'})
      });
    }
    if(AllIds.length > 0)
    {
      AllIds.forEach(async (element: any) => {
        if(element.type == 'Machine' || element.type == 'QualityChemicalLab')
          await this.getMachineAndQualityChemicalLabParameterAnalysis(element);
        if(element.type == 'QualityPhysicalLab')
          await this.getQualityPhysicalLabParameterAnalysis(element);
      });
    }
    else
    {
      this.toaster.error('Error', 'Please select parameter for Analysis');
    }
    this.spinnerButtonOptions.active = false;
  }

  getMachineAndQualityChemicalLabParameterAnalysis(element: any,){
    let key = {};
    if(element.type == 'Machine')
      key = {
        FromDate: moment(this.FilterDataForm.get('datePicker').value[0]).utc().format('YYYY-MM-DD HH:mm:ss'),
        ToDate: moment(this.FilterDataForm.get('datePicker').value[1]).utc().format('YYYY-MM-DD HH:mm:ss'),
        parameterId: element.id,
        groupType: element.type,
      };
    else
      key = {
        FromDate: new Date(new Date(this.FilterDataForm.get('datePicker').value[0]).setHours(0,0,0,0)).toLocaleString(),
        ToDate: new Date(new Date(this.FilterDataForm.get('datePicker').value[1]).setHours(0,0,0,0)).toLocaleString(),
        parameterId: element.id,
        groupType: element.type,
        ParamTypeId: this.QualityParameterArray.find((row) =>
                          {return (row.id == element.id && row.ParamType == element.type)}
                      )?.MaterialId
      };
    this.spinner.show();
    this.trendDashboardService.getMachineQualityParameterAnalysis(key).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.spinnerButtonOptions.active = false;
        this.plotParameterAnalysisChart(data,element);
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
        this.spinnerButtonOptions.active = false;
      }
    );
  }

  getQualityPhysicalLabParameterAnalysis(element: any){
    const selectedParameter = this.QualityParameterArray.find((row) => {
      return (row.id == element.id && row.ParamType == element.type)
    });
    const key = {
      FromDate: new Date(new Date(this.FilterDataForm.get('datePicker').value[0]).setHours(0,0,0,0)).toLocaleString(),
      ToDate: new Date(new Date(this.FilterDataForm.get('datePicker').value[1]).setHours(0,0,0,0)).toLocaleString(),
      ParamTableName: selectedParameter.TableName,
      ParamColumnName: selectedParameter.ActualColumnName,
      ParamProductColumnName: selectedParameter.ProductColumnName,
    };
    this.spinner.show();
    this.trendDashboardService.getQualityPhysicalLabParameterAnalysis(key).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.spinnerButtonOptions.active = false;
        this.plotParameterAnalysisChart(data,element);
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
        this.spinnerButtonOptions.active = false;
      }
    );
  }

  plotParameterAnalysisChart(data: any,element: any){
    const chartObj = { id: '', chartOption: {} };
    chartObj.id = element.parameterId;
    let parameterName = '';
    if(element.type == 'Machine')
    {
      this.MachineParameterArray.forEach((ele:any)=>{
        if(ele.id==element.id)
        {
          let tempName=ele.name.split('--');
          parameterName=tempName[0].toUpperCase();
        }
      });
    }
    else
    {
      this.QualityParameterArray.forEach((ele:any)=>{
        if(ele.id==element.id && ele.ParamType == element.type)
        {
          parameterName=ele.name;
          // parameterName=tempName[0];
        }
      });
    }
    const LRLarray: any[] = [];
    const LSLarray: any[] = [];
    const LWLarray: any[] = [];
    const TGTarray: any[] = [];
    const UWLarray: any[] = [];
    const USLarray: any[] = [];
    const URLarray: any[] = [];
    const Productarray: any[] = [];
    const array: any[] = [];
    data.forEach((c: any) => {
      const arr = [],
        arrLRL = [];
      const arrLSL = [];
      const arrLWL = [];
      const arrTGT = [];
      const arrUWL = [];
      const arrUSL = [];
      const arrURL = [];
      const arrProduct = [];
      arr.push(new Date(c.processTime).getTime());
      arr.push(parseFloat(c.result));
      array.push({
        x: new Date(c.processTime).getTime(),
        y: parseFloat(c.result),
        label: c.Product,
      });
      arrLRL.push(new Date(c.processTime).getTime());
      arrLRL.push(parseFloat(c.LRL)); // LSL Rename To LRL
      LRLarray.push(arrLRL);
      arrLSL.push(new Date(c.processTime).getTime());
      arrLSL.push(parseFloat(c.LSL)); // LCL Rename To LSL
      LSLarray.push(arrLSL);
      arrLWL.push(new Date(c.processTime).getTime());
      arrLWL.push(parseFloat(c.LWL));
      LWLarray.push(arrLWL);
      arrTGT.push(new Date(c.processTime).getTime());
      arrTGT.push(parseFloat(c.TGT));
      TGTarray.push(arrTGT);
      arrUWL.push(new Date(c.processTime).getTime());
      arrUWL.push(parseFloat(c.UWL));
      UWLarray.push(arrUWL);
      arrUSL.push(new Date(c.processTime).getTime());
      arrUSL.push(parseFloat(c.USL)); // UCL Rename To USL
      USLarray.push(arrUSL);
      arrURL.push(new Date(c.processTime).getTime());
      arrURL.push(parseFloat(c.URL)); // USL Rename To URL
      URLarray.push(arrURL);
      arrProduct.push(new Date(c.processTime).getTime());
      arrProduct.push(c.Product); // USL Rename To URL
      Productarray.push({
        x: new Date(c.processTime).getTime(),
        y: isNaN(c.result) ? '' : parseFloat(c.result),
        label: c.Product,
      });
    });
    this.stock = new StockChart({
      plotOptions: {
        series: {
          turboThreshold: 50000000,
        },
      },
      chart: {
        height: 730,
      },
      time: {
        useUTC: false
      },
      rangeSelector: {
        selected: 1,
      },
      title: {
        text: parameterName,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        formatter(): any {
          let s = '';
          this.points?.forEach((point: any) => {
            if (point.series.name === 'Result') {
              s +=
                '<br/> <span style="color: ' +
                point.series.color +
                '">' +
                point.series.name +
                ' : ' +
                point.y +
                '<span><br/>' +
                '<br/><span style="color: #252031">Product : ' +
                point.point.label +
                '<span><br/>';
            } else {
              s +=
                '<br/> <span style="color: ' +
                point.series.color +
                '">' +
                point.series.name +
                ' : ' +
                point.y +
                '<span><br/>';
            }
          });
          return s;
        },
      },
      yAxis: [
        {
          title: {
            text: 'Result',
          },
          opposite: false,
        },
        {
          title: {
            text: 'Product',
            style: {
              color: '#252031',
            },
          },
          opposite: true,
        },
      ],
      series: [
        {
          tooltip: {
            valueDecimals: 2,
          },
          name: 'Result',
          type: 'line',
          data: array,
          color: '#040496'
        },
        {
          tooltip: {
            valueDecimals: 2,
          },
          name: 'LRL',
          type: 'line',
          data: LRLarray,
          color: '#00ff00',
          dashStyle: 'ShortDash',
        },
        {
          tooltip: {
            valueDecimals: 2,
          },
          name: 'LSL',
          type: 'line',
          data: LSLarray,
          color: '#FF5733',
          dashStyle: 'ShortDash',
        },
        {
          tooltip: {
            valueDecimals: 2,
          },
          name: 'LWL',
          type: 'line',
          data: LWLarray,
          color: '#9C412D',
          dashStyle: 'ShortDash',
        },
        {
          tooltip: {
            valueDecimals: 2,
          },
          name: 'TGT',
          type: 'line',
          data: TGTarray,
          color: '#9C902D',
          dashStyle: 'ShortDash',
        },
        {
          tooltip: {
            valueDecimals: 2,
          },
          name: 'UWL',
          type: 'line',
          data: UWLarray,
          color: '#25B79B',
          dashStyle: 'ShortDash',
        },
        {
          tooltip: {
            valueDecimals: 2,
          },
          name: 'USL',
          type: 'line',
          data: USLarray,
          color: '#257AB7',
          dashStyle: 'ShortDash',
        },
        {
          tooltip: {
            valueDecimals: 2,
          },
          name: 'URL',
          type: 'line',
          data: URLarray,
          color: '#FF0909',
          dashStyle: 'ShortDash',
        }
      ],
    });

    chartObj.chartOption = this.stock;
    this.chartArray.push(chartObj);
  }

  onClickParameterComparsion(): void {
    this.spinnerButtonOptions1.active = true;
    let Ids1 = '';
    let Ids2 = '';
    let Ids3 = '';
    const array: any[] = [];
    const yAxisArr: any[] = [];
    const valueNames : any[] = [];
    const seletedGroups : any[] = [];
    let machineIds : any[] = [];
    let qualityChemicalIds : any[] = [];
    let qualityPhysicalIds : any[] = [];
    let chemicalLabQualityParams: any[] = [];
    let physicalLabQualityParams: any[] = [];
    if (this.FilterDataForm.value.machineParam) {
      machineIds = this.FilterDataForm.value.machineParam.map(
        (a:any)=>a.id
      );
      Ids1 = machineIds.join(',');
    }
    if(this.FilterDataForm.value.qualityParam){
      chemicalLabQualityParams = this.FilterDataForm.value.qualityParam.filter((item: any) => {
        return item.ParamType == 'QualityChemicalLab';
      });
      physicalLabQualityParams = this.FilterDataForm.value.qualityParam.filter((item: any) => {
        return item.ParamType == 'QualityPhysicalLab';
      });
      qualityChemicalIds = chemicalLabQualityParams.map(
        (a:any)=>{  
          let tempName=a.name.split('--');
          valueNames.push(tempName[0]+'--'+tempName[2]);
          seletedGroups.push(tempName[1]);
          return a.id+'~'+a.MaterialId+'@'+tempName[0]+'--'+tempName[2]
        }
      );
      qualityPhysicalIds = physicalLabQualityParams.map(
        (a:any)=>{
          let tempName=a.name.split('--');
          valueNames.push(tempName[0]+'--'+tempName[2]);
          seletedGroups.push(tempName[1]);
          return a.TableName+'~'+a.ActualColumnName+'~'+a.ProductColumnName+'@'+tempName[0]+'--'+tempName[2]
        }
      );
      Ids2 = qualityChemicalIds.join(',');
      Ids3 = qualityPhysicalIds.join(',');
    }
    if(machineIds.length > 0)
    {
      machineIds.forEach((element: any) => {
        this.MachineParameterArray.forEach((ele:any)=>{
          if(ele.id==element)
          {
            let tempName=ele.name.split('--');
            valueNames.push(tempName[0]);
            seletedGroups.push(tempName[1]);
          }
        });
      });
    }
    if(Ids1 != '' || Ids2 != '' || Ids3 != '') {
      const uniqueGroups = [...new Set(seletedGroups)];
      const dataArrName: any[] = [];
      const key = {
        FromDateutc: moment(this.FilterDataForm.get('datePicker').value[0]).utc().format('YYYY-MM-DD HH:mm:ss'),
        ToDateutc: moment(this.FilterDataForm.get('datePicker').value[1]).utc().format('YYYY-MM-DD HH:mm:ss'),
        FromDate: new Date(new Date(this.FilterDataForm.get('datePicker').value[0]).setHours(0,0,0,0)).toLocaleString(),
        ToDate: new Date(new Date(this.FilterDataForm.get('datePicker').value[1]).setHours(0,0,0,0)).toLocaleString(),
        machineParameterId: Ids1,
        qualityChemicalLabParameterId: Ids2,
        qualityPhysicalLabParameter: Ids3
      };
      this.spinner.show();
      this.trendDashboardService.getMachineQualityParameterComparison(key).subscribe(
        (data: any) => {
          for (let i = 0; i < valueNames.length; i++) {
            const dataArr = [];
            for (let j = 0; j < data.length; j++) {
              if (valueNames[i] === data[j].name) {
                dataArr.push({
                  x: new Date(data[j].processTime).getTime(),
                  y: isNaN(data[j].result) ? '' : parseFloat(data[j].result),
                  label: data[j].product,
                });
              }
            }
            let negativeArr = [];
            negativeArr = dataArr.map((c) => c.y);
            const obj = {
              tooltip: {
                valueDecimals: 2,
              },
              name: valueNames[i],
              type: 'line',
              data: dataArr,
              color: this.highChartsColor[i],
              yAxis: i,
            };
            array.push(obj);
            yAxisArr.push({
              lineWidth: 1,
              opposite: negativeArr.some((v) => v < 0) ? true : false,
              title: {
                text: valueNames[i],
                style: {
                  color: this.highChartsColor[i],
                },
              },
              labels: {
                style: {
                  color: this.highChartsColor[i],
                },
              },
            });
          }
          this.analysisType = false;
          this.chart = new StockChart({
            chart: {
              height: 730,
            },
            plotOptions: {
              series: {
                showInNavigator: true,
                turboThreshold: 50000000,
                events: {
                  hide(): any {
                    this.yAxis.update({
                      visible: false,
                    });
                  },
                  show(): any {
                    this.yAxis.update({
                      visible: true,
                    });
                  },
                },
              },
            },
            rangeSelector: {
              selected: 1,
            },
            title: {
              text: uniqueGroups.toString(),
            },
            time: {
              useUTC: false
            },
            credits: {
              enabled: false,
            },
            legend: {
              enabled: true,
            },
            tooltip: {
              formatter(): any {
                let s = '';
                this.points?.forEach((point: any) => {
                  s +=
                    '<br/> <span style="color: ' +
                    point.series.color +
                    '">' +
                    point.series.name +
                    ' : ' +
                    point.y +
                    '<span><br/>' +
                    '<br/><span style="color: ' +
                    point.series.color +
                    '">Product : ' +
                    point.point.label +
                    '<span><br/>';
                });
                return s;
              },
            },
            yAxis: yAxisArr,
            series: array,
          });
          this.spinner.hide();
        },
        (error: any) => {
          this.toaster.error('Error', 'Something Went Wrong!');
          this.spinner.hide();
        }
      );
    }
    else
    {
      this.toaster.error('Error', 'Please select parameter for Comparison');
    }
    this.spinnerButtonOptions1.active = false;
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
}
