import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FteDashboardService } from 'src/app/services/fte-dashboard.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
  ApexPlotOptions,
  ApexTooltip,
  ApexFill
} from 'ng-apexcharts';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ThemePalette } from '@angular/material/core';
import * as _moment from 'moment';
const moment = _moment;


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};
export type ChartOptions1 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};
export type ChartOptions3 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxix: ApexYAxis,
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-FTE-Dashboard',
  templateUrl: './FTE-Dashboard.component.html',
  styleUrls: ['./FTE-Dashboard.component.scss']
})
export class FTEDashboardComponent implements OnInit {

  public chartOptions!: any;
  public chartOptions1!: any;
  public chartOptions2!: any;

  subscription!: Subscription;
  FTEDbForm!: FormGroup;
  groupListDDL!: any[];
  filterdplants!: any[];
  ownerList!: any[];
  groupId!: number;
  breadcrumList!: any;
  IsAccess!: any;
  IsWrite!: any;
  permisson!: any;
  filteredUsers: any;
  constructor(private FTEService: FteDashboardService, private fb: FormBuilder,
              private machineService: MachineMappingService,
              private usermanagementService: UserManagementService, private toaster: ToastrService, public router: Router,
              private masterOleService: EnterPriseGroupService) {
  }
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroupData();
    this.FTEDbForm = this.fb.group({
      employee: [''],
      plant: [''],
      group: [''],
      dateStart: [''],
      dateEnd: ['']
    });
    this.chartOptions = {
      series: [],
      chart: {
        height: 280,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#77B6EA', '#545454'],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: [],
        title: {
          text: 'Month'
        }
      },
      yaxis: {
        min: 0,
        max: 2
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    };

    this.chartOptions1 = {
      series: [],
      chart: {
        height: 400,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      // colors: ['#77B6EA', '#545454', '#f5bd1f'],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: [],
        title: {
          text: 'Month'
        }
      },
      yaxis: {
        min: 0,
        max: 2,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    };

    this.chartOptions2 = {
      series: [],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: (val: string) => {
            return val + '%';
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        y: {
          formatter: (val: string) => {
            return val + '%';
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
    };

  }
  getGroupData(): void {
    const key = {
      Id: 0
    };
    this.usermanagementService.getGroupData(key).subscribe((data: any) => {
      this.groupListDDL = data.masterList;
      this.FTEDbForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
      this.getPlantByGroup();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.FTEDbForm.value.group
    };
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.filterdplants = data.DepartList;
      this.FTEDbForm.get('plant')?.setValue(this.filterdplants[0].DeptId);
      this.FTEDbForm.get('dateStart')?.setValue(new Date(new Date().getFullYear(), 0, 1, 1).toISOString());
      this.FTEDbForm.get('dateEnd')?.setValue(new Date(new Date().getFullYear(), 11, 31, 1).toISOString());
      this.getUsers();
      this.getFTETrends();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  onChangePlant(): void {
    this.getUsers();
    this.getFTETrends();
  }
  getUsers(): void {
    const key = {
      egId: this.FTEDbForm.value.group
    };
    this.usermanagementService.getRaciUsers(key).subscribe((data) => {
      this.ownerList = data;
      this.FTEDbForm.get('employee')?.setValue(this.ownerList[0].userId);
      this.filteredUsers = this.ownerList.slice();
    }, (error) => {
      this.toaster.error(error.error.message);
    });
  }
  // get & bind chart data start
  getFTETrends(): void {
    const { dateStart, dateEnd, plant } = this.FTEDbForm.value;
    const key = {
      fromDate: dateStart !== '' ? moment(dateStart).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
      toDate: dateEnd !== '' ? moment(dateEnd).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
      plantId: parseInt(plant, 10)
    };
    this.getOLEAvgFTETrend(key);
    this.getOLEAvgFTETrendRecourceWise(key);
    this.getOleResourcesUtilizationByProject(key);
  }
  getOLEAvgFTETrend(key: any): void {
    this.FTEService.getOLEAvgFTETrend(key).subscribe(res => {
      const workingMonth: any[] = [];
      const values: any[] = [];
      const neededResources: any[] = [];
      res.forEach((element) => {
        workingMonth.push(element?.WorkingMonth);
        values.push(element?.AvgFTE);
        neededResources.push(1);
      });
      this.chartOptions.series = [
        {
          name: 'Needed Resources',
          data: neededResources
        },
        {
          name: 'Total Avg',
          data: values
        }
      ];
      this.chartOptions.xaxis = {
        categories: workingMonth,
        title: {
          text: 'Month'
        }
      };
    }, ({ error }) => {
      this.toaster.error(error.message);
    });
  }
  getOLEAvgFTETrendRecourceWise(key: any): void {
    this.FTEService.getOLEAvgFTETrendRecourceWise(key).subscribe(res => {
      const dummyResp = res;
      const workingMonth: any[] = [... new Set(res.map((item) => {
        return item.WorkingMonth;
      })
      )];
      const resourses: any[] = [... new Set(res.map((item) => {
        return item.ResourceName;
      })
      )];
      workingMonth.forEach(month => {
        resourses.forEach(resourse => {
          const isExist = dummyResp.findIndex(item => {
            return (item.WorkingMonth === month &&
              item.ResourceName === resourse);
          });
          if (isExist === -1) {
            dummyResp.push({ WorkingMonth: month, AvgFTE: 0, ResourceName: resourse});
          }
        });
      });
      const data: any[] = [];
      let neededResources: any = null;
      resourses.forEach((element) => {
        const resourseValues = dummyResp.filter(x => x.ResourceName === element)
          .map((item) => {
            return item.AvgFTE;
          });
        if (neededResources === null) {
            const fixedArray: any = [];
            resourseValues.forEach(item => {
              fixedArray.push(1);
            });
            neededResources = {
                name: 'Needed Resources',
                data: fixedArray
            };
            data.push(neededResources);
        }
        data.push({
          name: element,
          data: resourseValues
        });
      });
      this.chartOptions1.series = data;
      this.chartOptions1.xaxis = {
        categories: workingMonth,
        title: {
          text: 'Month'
        }
      };
    }, ({ error }) => {
      this.toaster.error(error.message);
    });
  }
  getOleResourcesUtilizationByProject(key: any): void {
    this.FTEService.getOleResourcesUtilizationByProject(key).subscribe(res => {
      const updatedArray = res;
      const projectNames: any[] = [... new Set(res.map((item) => {
        return item.ProjectName;
      })
      )];
      const ownerNames: any[] = [... new Set(res.map((item) => {
        return item.OwnerName;
      })
      )];
      const data: any[] = [];
      ownerNames.forEach((owner) => {
        const valuesArray = projectNames.map((item) => {
          return 0;
        });
        const found = updatedArray.filter(x => x.OwnerName === owner);
        if (found.length) {
          found.map(item => {
            if (item.OwnerConftribution !== 0) {
              const projIndex = projectNames.findIndex((proj) => proj === item.ProjectName);
              valuesArray[projIndex] = item.OwnerConftribution;
            }
            return item;
          });
        }
        data.push({
          name: owner,
          data: valuesArray
        });
      });
      this.chartOptions2.series = data;
      this.chartOptions2.xaxis = {
        categories: projectNames,
        labels: {
          formatter: (val: string) => {
            return val + '%';
          }
        }
      };
    }, ({ error }) => {
      this.toaster.error(error.message);
    });
  }


  // get & bind chart data end
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.masterOleService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
}
