import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ProductivityService } from 'src/app/services/productivity-service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { ButtonEditRendererComponent } from 'src/app/components/button-edit-render.components';
import { DatePickerComponent } from 'src/app/components/date-picker.components';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { AgGridAngular } from 'ag-grid-angular';
import { LanguageService } from 'src/app/services/language-service';
import { ButtonSaveRendererComponent } from 'src/app/components/button-save-render.components';
import { ButtonDeleteRendererComponent } from 'src/app/components/button-delete-render.components';

@Component({
  selector: 'app-language-master',
  templateUrl: './language-master.component.html',
  styleUrls: ['./language-master.component.scss']
})
export class LanguageMasterComponent implements OnInit {
  @ViewChild('agGrid') agGrid!: AgGridAngular;
  languageform!: FormGroup;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  moduleList!: any[];
  pageList!: any[];
  languageList!:any[];
  productList!: any[];
  filteredModules!: any[];
  filteredPages!: any[];
  gridApi!: any;
  gridColumnApi!: any;
  columnDefs!: any;
  public frameworkComponents: any;
  rowData: any[] = new Array();
  gridOptions: any = {
    defaultColDef : {
      flex: 1,
      minWidth: 100,
      filter :true
    }
  }
  dummyDate!: any[];
  constructor(
    private formBuilder: FormBuilder,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    private languageService: LanguageService,
    private spinner: NgxSpinnerService,
    private sfdcService: ShopFloorDataService,
    public router: Router,
  ) {
    this.frameworkComponents = {
      buttonEditRenderer: ButtonEditRendererComponent,
      buttonSaveRenderer : ButtonSaveRendererComponent,
      buttonDeleteRenderer : ButtonDeleteRendererComponent
    }
    this.columnDefs = [
      {headerName : 'LN', maxWidth: 280,  valueGetter: "node.rowIndex + 1" },
      {  headerName :'Tag Name', maxWidth: 380, field: 'TagName'},
     
      { headerName :'English',field: 'EnglishTranslationValue'},
      {
        headerName :'Arabic',
        field: 'OtherTranslatedValue',
        editable: true,
        maxWidth: 380,
        hide : true
      },
      {
        headerName :'French',
        field: 'OtherTranslatedValue',
        editable: true,
        maxWidth: 380,
        hide : true
      },
      {
        headerName :'Spanish',
        field: 'OtherTranslatedValue',
        editable: true,
        maxWidth: 380,
        hide : true
      },
      { headerName: 'Actions', cellRenderer: 'buttonSaveRenderer', cellRendererParams: { onClick: this.onClickSave.bind(this), label: 'Save' }, maxWidth: 85, suppressMenu: true },
      { headerName: '', cellRenderer: 'buttonDeleteRenderer', cellRendererParams: { onClick: this.onClickDelete.bind(this), label: 'Delete' }, maxWidth: 85, suppressMenu: true }
    ];
   
  }

  ngOnInit(): void {
    this.initForm();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getAllModules();
    this.getLangueges();
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.languageform.controls[controlName].hasError(errorName);
  }

  onChangeLanguage(data:any):void{
    const language = this.languageList.find(element => element.languageId === data.value);
    if(language.languageName === 'Arabic'){
      const columns = this.gridOptions.columnApi.getAllColumns();
      const valueColumn = columns.filter((column:any) => column.getColDef().headerName === "Arabic")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumn, true);
      const valueColumnSpan = columns.filter((column:any) => column.getColDef().headerName === "Spanish")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumnSpan, false);
      const valueColumnFren = columns.filter((column:any) => column.getColDef().headerName === "French")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumnFren, false);
      this.agGrid.api.sizeColumnsToFit();
    }else if(language.languageName === 'French'){
      const columns = this.gridOptions.columnApi.getAllColumns();
      const valueColumn = columns.filter((column:any) => column.getColDef().headerName === "French")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumn, true);
      const valueColumnSpan = columns.filter((column:any) => column.getColDef().headerName === "Spanish")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumnSpan, false);
      const valueColumnFren = columns.filter((column:any) => column.getColDef().headerName === "Arabic")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumnFren, false);
      this.agGrid.api.sizeColumnsToFit();
    }else if(language.languageName === 'Spanish'){
      const columns = this.gridOptions.columnApi.getAllColumns();
      const valueColumn = columns.filter((column:any) => column.getColDef().headerName === "Spanish")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumn, true);
      const valueColumnSpan = columns.filter((column:any) => column.getColDef().headerName === "Arabic")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumnSpan, false);
      const valueColumnFren = columns.filter((column:any) => column.getColDef().headerName === "French")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumnFren, false);
      this.agGrid.api.sizeColumnsToFit();
    }else{
      const columns = this.gridOptions.columnApi.getAllColumns();
      const valueColumn = columns.filter((column:any) => column.getColDef().headerName === "Spanish")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumn, false);
      const valueColumnSpan = columns.filter((column:any) => column.getColDef().headerName === "Arabic")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumnSpan, false);
      const valueColumnFren = columns.filter((column:any) => column.getColDef().headerName === "French")[0];
      this.gridOptions.columnApi.setColumnVisible(valueColumnFren, false);
      this.agGrid.api.sizeColumnsToFit();
    }
    this.getTransalatedLanguage();
  }

  initForm() {
    this.languageform = this.formBuilder.group({
      module: ['',[Validators.required]],
      page: ['',[Validators.required]],
      tagName: ['',[Validators.required]],
      tagValue: ['',[Validators.required]],
      language: [],
    });
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getAllModules(): void {
    this.languageService.getAllModule().subscribe((data: any) => {
      this.moduleList = data.moduleList;
      this.languageform.get('module')?.patchValue(this.moduleList[0].moduleId);
      if(this.moduleList.length > 0){
        this.filteredModules = this.moduleList.slice();
        this.getPages(this.moduleList[0]?.moduleId);
      }
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
 
  getPages(moduleId:any):void{
    const key ={
      ModuleId : moduleId
    }
    this.languageService.GetAllPages(key).subscribe((data: any) => {
      this.pageList = data.pageList;
      this.languageform.get('page')?.patchValue(this.pageList[0].pageId);
      this.filteredPages = this.pageList.slice();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  getLangueges():void{
    this.languageService.getLanguages().subscribe((data: any) => {
      this.languageList = data.languageList;
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
 
  onClickSave(data:any):void{
    let TransactionType = 1;
    if( data.data.OtherTranslationId !==0){
        TransactionType = 2
    }
    const key = {
      TransactionType : TransactionType,
      ModuleId : this.languageform.get('module')?.value,
      PageId : this.languageform.get('page')?.value,
      LanguageId : this.languageform.get('language')?.value,
      TagName : data.data.TagName,
      TranslatedValue : data.data.OtherTranslatedValue,
      TranslationId : data.data.OtherTranslationId === 0 ? '' : data.data.OtherTranslationId,
    }
      this.languageService.saveLanguage(key).subscribe((data: any) => {
        this.toaster.success('success',data.ErrorMessage);
        this.reset();
        this.getTransalatedLanguage();
      }, (error: any) => {
        this.toaster.error('Error', error.message);
      });
  }

  onClickDelete(data:any):void{
    const key = {
      TransactionType : 3,
      ModuleId : this.languageform.get('module')?.value,
      PageId : this.languageform.get('page')?.value,
      LanguageId : this.languageform.get('language')?.value,
      TagName : data.data.tagName,
      TranslatedValue : data.data.OtherTranslatedValue,
      TranslationId : data.data.OtherTranslationId === 0 ? '' : data.data.OtherTranslationId,
    }
      this.languageService.saveLanguage(key).subscribe((data: any) => {
        this.toaster.success('success',data.ErrorMessage);
        this.getTransalatedLanguage();
      }, (error: any) => {
        this.toaster.error('Error', error.message);
      });
  }

  getTransalatedLanguage():void{
    const key = {
      ModuleId :this.languageform.get('module')?.value,
      PageId : this.languageform.get('page')?.value,
      LanguageId :this.languageform.get('language')?.value // not in use
    }
    this.languageService.getTranslatedLanguages(key).subscribe((data: any) => {
      this.rowData = data.translateList;
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  onSubmit(): void {
    const key = {
      TransactionType : 1,
      ModuleId : this.languageform.get('module')?.value,
      PageId : this.languageform.get('page')?.value,
      LanguageId : 1,
      TagName : this.languageform.get('tagName')?.value,
      TranslatedValue : this.languageform.get('tagValue')?.value,
    }
    if(this.languageform.valid){
      this.languageService.saveLanguage(key).subscribe((data: any) => {
        this.toaster.success('success',data.ErrorMessage);
        this.getTransalatedLanguage();
        this.reset();
      }, (error: any) => {
        this.toaster.error('Error', error.message);
      });
    }
  }
  reset():void{
    this.languageform.get('tagName')?.reset();
    this.languageform.get('tagValue')?.reset();
  }
  dateAsYYYYMMDDHHNNSS(date: any): string {
    return date.getFullYear()
      + '-' + this.leftpad(date.getMonth() + 1, 2)
      + '-' + this.leftpad(date.getDate(), 2)
      + ' ' + this.leftpad(date.getHours(), 2)
      + ':' + this.leftpad(date.getMinutes(), 2)
      + ':' + this.leftpad(date.getSeconds(), 2);
  }
  leftpad(val: any, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
      + String(val)).slice(String(val).length);
  }
  //==================================================================== Ag Grid code Here ==================================================================//
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  
  }
  rowClicked(params:any) {
    params.node.setData({
      ...params.data,
      dirty: true
    });
  }
  getRowStyle(params:any) {
    if (params.data.dirty) {
        return { 'background-color': '#75e6b3' }
    }else{
      return { 'background-color': '#ffffff' }
    }
  }

}
