import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CILMasterService } from 'src/app/services/cil-master-service';

import getISOWeek from "date-fns/getISOWeek";
import { en_US, NzI18nService, zh_CN } from "ng-zorro-antd/i18n";
@Component({
  selector: 'app-safety-header',
  templateUrl: './safety-header.component.html',
  styleUrls: ['./safety-header.component.scss']
})
export class SafetyHeaderComponent implements OnInit {
  ImageUrl: any;
  user: any;
  HeaderForm: any;
  HeaderData: any = { Group: '', Unit: '', UnitName: '', Date: new Date() };
  formDirective: any;
  unitPickerIsOpen: boolean = false;

  GroupList: any;

  @Output() add = new EventEmitter();
  @Output() emitHeaderData = new EventEmitter<any>();
  spinnerText: string = '';
  GroupTempList: any = [];

  constructor(private toaster: ToastrService, private fb: FormBuilder, public router: Router, private spinner: NgxSpinnerService, private formBuilder: FormBuilder, public datePipe: DatePipe, public dialog: MatDialog, private cilMasterService: CILMasterService,private i18n: NzI18nService) {
    this.HeaderForm = this.fb.group({
      Group: ['', Validators.required],
      Unit: ['', Validators.required],
      Date: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.ImageUrl = environment.O3CoreApiImageUrl;
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.getGroups();
  }

  onGroupKey(value: any) {
      this.GroupList = this.searchKey(value.value);
  }

  searchKey(value: string) {
    let filter = value;
      return this.GroupTempList.filter((grp: any) => {
        let lowerCase = grp.name.toLocaleLowerCase();
        return grp.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
  }

  setUnit(event: any) {
    this.HeaderData.Unit = event.Id;
    this.HeaderData.UnitName = event.Name;
    this.unitPickerIsOpen = false;
    if (this.HeaderData.Unit) {
      let date = this.datePipe.transform(this.HeaderData.Date, 'yyyy/MM/dd');
      const data = {
        UserId: 0,
        PUId: this.HeaderData.Unit,
        Date: date
      };
      this.emitHeaderData.emit(data);
    }
  }

  onChange(event:any){
    if (this.HeaderData.Unit) {
      let date = this.datePipe.transform(this.HeaderData.Date, 'yyyy/MM/dd');
      const data = {
        UserId: 0,
        PUId: this.HeaderData.Unit,
        Date: date
      };
      this.emitHeaderData.emit(data);
    }
  }

  resetUnit() {
    this.HeaderData.Unit = 0;
    this.HeaderData.UnitName = '';
    this.unitPickerIsOpen = false;
  }

  openModal() {
    this.unitPickerIsOpen = true;
  }

  getGroups() {
    const key = {
      UserId: ''
    };
    const id = {
      value: 0
    };
    this.spinnerText = "Fetching Group Data";
    this.spinner.show();
    this.cilMasterService.getGroupData(key).subscribe((data: any) => {
      this.GroupList = data.dropDownList;
      this.GroupTempList = data.dropDownList;
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }

  onSubmit() {
    if (this.HeaderForm.valid)
      this.add.emit(this.HeaderData);
  }

}
