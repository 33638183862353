<div dir="{{languageDir}}">
<section class="home-content">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <mat-card class="master-card">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-6 p-lg-7 p-xl-7">
        <mat-card-title class="master-title">{{langObj ? langObj?.MasterDataSettings : ('MasterDataSettings' | translate)}}</mat-card-title>
      </div>

      <div class="p-col-12 p-md-6 p-lg-5 p-xl-5" style="display: flex; justify-content: flex-end;">
        <form [formGroup]="searchCostMasterForm" style="float: right;display: flex;">
          <mat-form-field appearance="fill" class="field-fill-custom-master" style="margin-right: 10px;
          ">
            <mat-label class="required">{{langObj ? langObj?.Group : ('Group' | translate)}}</mat-label>
            <mat-select panelClass="testClass" name="group" formControlName="group"
              (selectionChange)="getPlantByGroup()">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groupListDDL"
              (filteredReturn)="filteredGroupListDDL = $event"></mat-select-filter>
              <mat-option *ngFor="let g of filteredGroupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="field-fill-custom-master">
            <mat-label class="required">{{langObj ? langObj?.Plant : ('Plant' | translate)}}</mat-label>
            <mat-select panelClass="testClass" name="plant" formControlName="plant" (selectionChange)="getMasterData()">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plants"
              (filteredReturn)="filteredPlants = $event"></mat-select-filter>
              <mat-option *ngFor="let p of filteredPlants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
        <div class="import_export">
          <button class="import" (click)="ImportToExcel()">
            {{langObj ? langObj?.ImportAsExcel : ('ImportAsExcel' | translate)}}</button>
        </div>
        <div class="import_export">
          <button class="export" (click)="exportToExcel()">
            {{langObj ? langObj?.ExportAsExcel : ('ExportAsExcel' | translate)}}</button>
        </div>
      </div>
    </div>
  </mat-card>
  <mat-card class="p-mt-2 p-p-0">
    <!-- Table -->
    <div class="p-grid p-fluid">
      <div class="p-xl-12 p-lg-12 p-md-12 p-col-12">
        <app-cost-center (childToParent)="getCostElement()"></app-cost-center>
      </div>
      <div class="p-xl-12 p-lg-12 p-md-12 p-col-12">
        <app-costelement (childToParent)="changedHandler($event)"></app-costelement>
      </div>
      <!-- <div class="p-xl-12 p-lg-12 p-md-12 p-col-12">
        <app-tdcelement></app-tdcelement>
      </div> -->
    </div>
  </mat-card>
</section>

<ng-template #ImportDialog style="padding: 0px;">
  <div>
    <h1 mat-dialog-title>{{langObj ? langObj?.UploadExcel : ('UploadExcel' | translate)}}</h1>
    <div mat-dialog-content>
      <div class="d-flex">
        <div class="flex-1">
          <button mat-flat-button color="accent" type="button" (click)="openFile()">{{langObj ? langObj?.UploadExcel : ('UploadExcel' | translate)}}
            </button>
          <input type="file" (change)="uploadExcelFile($event)" class="d-none" #fileInput>
        </div>
        <div>
          <button mat-flat-button color="accent" type="button" class="w-22"><a class="sample"
              href="assets/xlsx/Loss_Analysis.xlsx" download>{{langObj ? langObj?.SampleExcel : ('SampleExcel' | translate)}} <i class="fa fa-download"
                aria-hidden="true"></i></a></button>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="mat-dialog-actions">
      <button mat-button class="dialog-cancel-btn" (click)="onCancelClick()">{{langObj ? langObj?.Cancel : ('Cancel' | translate)}}</button>
      <!-- <button mat-button class="dialog-save-btn" (click)="onSaveClick()" cdkFocusInitial>Save</button> -->
    </div>
  </div>
</ng-template>

<ng-template #duplicateDialog style="padding: 0px;">
  <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
    <p style="font-size: 20px; color: #050a4f">{{langObj ? langObj?.FetchingData : ('FetchingData' | translate)}}...</p>
  </ngx-spinner>
  <mat-card class="p-mt-0 p-pt-0">
    <div class="p-d-flex p-jc-between p-ai-center pad">
      <mat-card-title class="p-ml-2">{{langObj ? langObj?.AddedData : ('AddedData' | translate)}}</mat-card-title>
      <div class="">
        <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  
    <mat-card-content>
      <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
        <table mat-table [dataSource]="addedDataSource" class="basic-table plant-model-table" matSort>
  
          <ng-container matColumnDef="costCenterName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.CostCenterName : ('CostCenterName' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.CostCenterName}}</td>
          </ng-container>
  
          <ng-container matColumnDef="costElementName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.CostElementName : ('CostElementName' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.CostElementName}} </td>
          </ng-container>
  
          <ng-container matColumnDef="sheetName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.SheetName : ('SheetName' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.SheetName}}</td>
          </ng-container>

          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.Message : ('Message' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.Message}}</td>
          </ng-container>

          <ng-container matColumnDef="sheetNo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.SheetNo : ('SheetNo' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.SheetNo}}</td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="addedDataColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: addedDataColumns;"></tr>
          <tr class="mat-row" *matNoDataRow style="text-align: center;">
            <td class="mat-cell" colspan="4">{{langObj ? langObj?.Nodatamatching : ('Nodatamatching' | translate)}}</td>
          </tr>
        </table>
      </div>
      <mat-paginator #addedPaginator class="basic-paginataor" [pageSizeOptions]="[2, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </mat-card-content>
  </mat-card>

  <mat-card class="p-mt-0 p-pt-0">
    <div class="p-d-flex p-jc-between p-ai-center pad">
      <mat-card-title class="p-ml-2">{{langObj ? langObj?.DuplicateData : ('DuplicateData' | translate)}}</mat-card-title>
      <!-- <div class="">
        <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
          <mat-icon>close</mat-icon>
        </button>
      </div> -->
    </div>
  
    <mat-card-content>
      <!-- <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
        <p style="font-size: 20px; color: #050a4f">Fetching Data...</p>
      </ngx-spinner> -->
      
      <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
        <table mat-table [dataSource]="duplicateDataSource" class="basic-table plant-model-table" matSort>
  
          <ng-container matColumnDef="_costCenterName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.CostCenterName : ('CostCenterName' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.CostCenterName}}</td>
          </ng-container>
  
          <ng-container matColumnDef="_costElementName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.CostElementName : ('CostElementName' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.CostElementName}} </td>
          </ng-container>
  
          <ng-container matColumnDef="_sheetName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.SheetName : ('SheetName' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.SheetName}}</td>
          </ng-container>

          <ng-container matColumnDef="_message">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.Message : ('Message' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.Message}}</td>
          </ng-container>

          <ng-container matColumnDef="_sheetNo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.SheetNo : ('SheetNo' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element">{{element?.SheetNo}}</td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="duplicateDataColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: duplicateDataColumns;"></tr>
          <tr class="mat-row" *matNoDataRow style="text-align: center;">
            <td class="mat-cell" colspan="4">{{langObj ? langObj?.Nodatamatching : ('Nodatamatching' | translate)}}</td>
          </tr>
        </table>
      </div>
      <mat-paginator #duplicatePaginator class="basic-paginataor" [pageSizeOptions]="[2, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</ng-template>
</div>
