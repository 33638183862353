import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SafetyService {
  baseUrl: string = environment.O3CoreApiUrl;
  O3ApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getIncidentReport(data:any): Observable<any[]> {
    return this.http.post<any>(this.O3ApiUrl + 'Incident/GetIncidentReport', data);
  }

  getIncidentType(data:any): Observable<any[]> {
    return this.http.post<any>(this.O3ApiUrl + 'Incident/GETIncidentType', data);
  }

  getIncidentClassification(data:any): Observable<any[]> {
    return this.http.post<any>(this.O3ApiUrl + 'Incident/GETIncidentClassification', data);
  }

  createIncidentReport(data:any): Observable<any[]> {
    return this.http.post<any>(this.O3ApiUrl + 'Incident/SaveIncidentReport', data);
  }

  getBOSReport(data:any): Observable<any[]> {
    return this.http.post<any>(this.O3ApiUrl + 'BOS/GetBOSReport', data);
  }

  getBOSDescriptionType(data:any): Observable<any[]> {
    return this.http.post<any>(this.O3ApiUrl + 'BOS/GETBOSDescTypes', data);
  }

  createBOSReport(data:any): Observable<any[]> {
    return this.http.post<any>(this.O3ApiUrl + 'BOS/SumbitBOSReportList', data);
  }
}
