<div class="production-db-content">
    <div class="content-top-box">
      <ul class="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
              (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
          </li>
        </ul>
      </ul>
      <div class="title-row-flex-both-end">
        <h1 class="main-title">CIL & Defects DashBoard</h1>
      </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <form [formGroup]="AMCLLDashboardForm">
      <div class="content-top-row content-top-row-order-processing">
        <!-- GROUP -->
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <!-- <mat-label>Status</mat-label> -->
          <mat-select formControlName="UserGroup1" (selectionChange)="getPlants($event)" placeholder="Group"
            panelClass="testClass" name="Status">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
              (filteredReturn)="filteredGroups = $event"></mat-select-filter>
            <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
              {{group.EG_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="UserPlant1" placeholder="Plant" panelClass="testClass" name="Status" (selectionChange)="onChangePlant($event)">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList1"
            (filteredReturn)="filteredPlants = $event"></mat-select-filter>
            <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
              {{plant.PT_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="line" placeholder="Line" panelClass="testClass" name="line" (selectionChange)="onChangeLine($event)">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
            (filteredReturn)="filteredLines = $event"></mat-select-filter>
            <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
              {{line.PL_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="unit" placeholder="Unit" panelClass="testClass" name="unit" (selectionChange)="onSubmit()">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="unitList"
            (filteredReturn)="filteredUnits = $event"></mat-select-filter>
            <mat-option *ngFor="let unit of filteredUnits" [value]="unit.PU_Id">
              {{unit.PU_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Dropdown -->
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <input matInput [ngxMatDatetimePicker]="startPicker" placeholder="Start Date" formControlName="dateStart" (dateChange)="onSubmit()"
            [min]="minDate" [max]="maxDate" [disabled]="disabled">
          <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #startPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
            [enableMeridian]="enableMeridian">
          </ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <input matInput [ngxMatDatetimePicker]="endPicker" placeholder="End Date" formControlName="dateEnd" (dateChange)="onSubmit()"
            [min]="minDate" [max]="maxDate" [disabled]="disabled">
          <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #endPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
            [enableMeridian]="enableMeridian">
          </ngx-mat-datetime-picker>
        </mat-form-field>
        <!-- <div class="btn-position btn-position-order-processing">
          <button mat-flat-button color="accent" class="btn-accent" (click)="onSubmit()">Apply Filter</button>
        </div> -->
        <div class="btn-position">
          <button mat-flat-button color="accent" class="btn-accent" (click)="today()">Today</button>
        </div>
        <div class="btn-position">
          <button mat-flat-button color="accent" class="btn-accent" (click)="yesterday()">Yesterday</button>
        </div>
        <div class="btn-position">
          <button mat-flat-button color="accent" class="btn-accent" (click)="lastWeek()">Last Week</button>
        </div>
        <div class="btn-position">
          <button mat-flat-button color="accent" class="btn-accent" (click)="lastMonth()">Last Month</button>
        </div>
      </div>
  
     
  
      <!-- Card -->
     
  
      <div class="menu-card-group">
        <div class="menu-card-item mat-card-height">
          <p class="title-text">Total Defects</p>
          <p class="data-text">
            {{TotalDefects}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
          </div>
        </div>
        <div class="menu-card-item mat-card-height">
          <p class="title-text">Total Defects Solved</p>
          <p class="data-text">
            {{TotalDefecteSolved}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
          </div>
        </div>
        <div class="menu-card-item mat-card-height">
          <p class="title-text">% of Defects Solved</p>
          <p class="data-text">
            {{TotalDefectsPerc}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuRedIconUrl" alt="{{ menuRedIconUrl }}" />
          </div>
        </div>
      </div>
  
      <div class="menu-card-group">
        <div class="menu-card-item mat-card-height">
          <p class="title-text">CIL Tasks</p>
          <p class="data-text">
            {{CILTask}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
          </div>
        </div>
        <div class="menu-card-item mat-card-height">
          <p class="title-text">CIL Time</p>
          <p class="data-text">
            {{CILTime}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuRedIconUrl" alt="{{ menuRedIconUrl }}" />
          </div>
        </div>
        <div class="menu-card-item mat-card-height">
          <p class="title-text">CIL Adherence</p>
          <p class="data-text">
            {{CILAdherence}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
          </div>
        </div>
      </div>
      <div></div>
      <div class="chart-group">
        <div class="chart-items">
          <div class="chart-title">Open Defects vs Closed Defects</div>
          <div id="container"></div>
        </div>
        <div class="chart-items">
          <div class="chart-title">Open Defects By Area</div>
          <div [chart]="chart"></div>
        </div>
      </div>
      <div class="chart-group">
        <div class="chart-items">
          <div class="chart-title">% Of Defects Closing</div>
          <div [chart]="chartDCPer"></div>
        </div>
        <div class="chart-items">
          <div class="chart-title">Defects By Type</div>
          <div [chart]="chartDT"></div>
        </div>
      </div>
      <div class="chart-group">
        <div class="chart-items">
          <div class="chart-title"># Of Defects Solved By Operators and Technicians</div>
          <div id="container"></div>
        </div>
        <div class="chart-items">
          <div class="chart-title">Defects Raised and Solved By Person</div>
          <div ></div>
        </div>
      </div>
      <div class="chart-group">
        <div class="chart-items">
          <div class="chart-title">% Of CIL Adherence</div>
          <div [chart]="chartCILAD"></div>
        </div>
        <div class="chart-items">
          <div class="chart-title">% Of CIL Tasks</div>
          <div [chart]="chartCILTasks"></div>
        </div>
      </div>
      <div class="chart-group">
        <div class="chart-items">
          <div class="chart-title">% Of CIL Task By Operator</div>
          <div [chart]="chartCILTaskByOp"></div>
        </div>
        <div class="chart-items">
          <div class="chart-title">CIL Time By Operator</div>
          <div [chart]="chartCILTimeByOp"></div>
        </div>
      </div>
      <div class="chart-group">
        <div class="chart-items">
          <div class="chart-title">CIL Time Trend</div>
          <div [chart]="chartCILTimeTrend"></div>
        </div>
        <div class="chart-items">
          <div class="chart-title">CIL Time By Machine</div>
          <div [chart]="chartCILTimeByMachine"></div>
        </div>
      </div>
    </form>
  </div>