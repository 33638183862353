import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import Swal from 'sweetalert2';
import { CostService } from '../../services/cost.service';
import { TranslaterService } from 'src/app/services/translater.service';
@Component({
  selector: 'app-costelement',
  templateUrl: './costelement.component.html',
  styleUrls: ['./costelement.component.scss'],
})
export class CostelementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() childToParent: EventEmitter<any> =   new EventEmitter();
  costModelVisible = false;
  CostformMode = true;
  costForm!: FormGroup;
  addCostForm!: FormGroup;
  plantId!: number;
  groupId!: number;
  plantsDDL!: any[];
  parentsDDL!: any[];
  costCenterId!: number;
  costElementDataSource!: MatTableDataSource<any>;
  costColumns: any[] = ['costActions', 'name', 'costCenter',];
  ptId!: any;
  costId!: any;
  IsAccess:any;
  IsWrite:any;
  costElementData: any;
  childEnter = false;
  //plantId: any;
  breadcrumList: any;
  moduleId: any;
  pageId: any;
  langObj: any;
  languageDir = 'ltr';

  constructor(
    private fb: FormBuilder,
    private costService: CostService,
    private toaster: ToastrService,
    private platModelService: EnterPriseGroupService,
    private userManagementService: UserManagementService,
    private translater: TranslaterService,
  ) {
  }

  ngOnInit(): void {
    this.addCostForm = this.fb.group({
      costCenterId: [''],
      plantId: [this.plantId],
      name: ['', Validators.required],
      costCenterparentgroup: [''],
    });
    this.GetMenuAccess();
    this.GetBreadcrumList();
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        // this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
     // this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
      // localStorage.setItem('GetMenuAccess', this.permisson);
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getCostMaster(key: any): void {
    this.plantId = key.plantId;
    this.groupId = key.groupId;
    this.costService.GetCostElement(key).subscribe((data) => {
      this.costElementDataSource = new MatTableDataSource<any>(data);
      this.costElementData = data;
      this.costElementDataSource.paginator = this.paginator;
    });
  }
  getCenterGroupList(k: any): void {
    debugger
    const key = {
      plantId: this.plantId,
    };
    // tslint:disable-next-line: no-debugger
    this.costService.GetcostGroupList(key).subscribe(
      (data: any) => {
        this.parentsDDL = data;
        // this.centerDDL = data;
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  AddCostElement(): void {
    const key = {
      pt: this.plantId,
      name: this.addCostForm.value.name,
      costCenterId: this.addCostForm.value.costCenterparentgroup,
    };
    const costData = this.costElementDataSource.filteredData;
    const cost = costData.filter(
      (c: any) => c.name === this.addCostForm.value.name
    );
    if (cost.length > 0) {
      this.toaster.error('Error', 'Cost Element Name Already Exist');
      return;
    }
    this.costService.AddCostElement(key).subscribe((res: any) => {
      this.toaster.success('success', 'Element is added successfully...');
      this.getCostMaster({ plantId: this.plantId });
      this.childEnter = true;
      this.childToParent.emit(this.childEnter);
    });
    this.CostformMode = true;
    this.addCostForm.reset();
  }
  EditCost(element: any): void {
    debugger;
    this.CostformMode = false;
    this.addCostForm.get('plantId')?.setValue(this.plantId);
    this.addCostForm
      .get('costCenterparentgroup')
      ?.setValue(element.costCenterId);
    this.addCostForm.get('name')?.setValue(element.name);
    this.costCenterId = element.costCenterId;
    this.costId = element.costId;
  }
  UpdateCostElement(): void {
    debugger;
    const key = {
      pt: this.plantId,
      name: this.addCostForm.value.name,
      costCenterId: this.addCostForm.value.costCenterparentgroup,
      costId: this.costId,
    };
    const costData = this.costElementDataSource.filteredData;
    const cost = costData.filter(
      (c: any) =>
        c.name === this.addCostForm.value.name && c.costId !== this.costId
    );
    if (cost.length > 0) {
      this.toaster.error('Error', 'Cost Element Name Already Exist');
      return;
    }
    this.costService.UpdateCostElement(key).subscribe(
      (response) => {
        this.toaster.success('Success', 'Cost Element Updated Successfully');
        this.getCostMaster({ plantId: this.plantId });
        this.childEnter = true;
        this.childToParent.emit(this.childEnter);
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
    this.CostformMode = true;
    this.addCostForm.reset();
  }

  DeletedCostElement(element: any): void {
    Swal.fire({
      title: 'Please make sure!',
      text: 'Are you want to delete element ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          costId: element.costId,
        };
        this.costService.DeletedCostElement(key).subscribe((res: any) => {
          Swal.fire({
            title: 'Deleted!',
            text: 'Your selected cost element has been deleted.',
            icon: 'success',
            timer: 800,
            showConfirmButton: false,
          });
          this.getCostMaster({ plantId: this.plantId });
          this.childEnter = true;
          this.childToParent.emit(this.childEnter);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your element is safe :)', 'error');
      }
    });
  }

  applyFilter(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.costElementDataSource.filter = filterValue.trim().toLowerCase();
  }
  RefreshCostElement() {
    this.addCostForm.reset();
    this.CostformMode = true;
  }
}
