import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { OrderProcessingElement } from 'src/app/interface/data-source';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { CustomerManagementService } from 'src/app/services/customer-management.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { CommonService } from 'src/app/services/common.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { OeeConfigurationService } from 'src/app/services/oee-configuration.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
@Component({
  selector: 'app-customer-management',
  templateUrl: './customer-management.component.html',
  styleUrls: ['./customer-management.component.scss']
})
export class CustomerManagementComponent implements OnInit {
  // emailControl = new FormControl('', [Validators.required,Validators.email]);

  // products = new FormControl();
  loading = false;
  productList: string[] = ['Product 1', 'Product 2', 'Product 3'];
  plants!: any;
  plantsDDL!: any[];
  countries!: any;
   cities!: any
   products! : any;
   Id!: any;
   customerUserDetails!: any[];
  customerListDataSource!: MatTableDataSource<any>;
  userListDataSource!: MatTableDataSource<any>;
  customerManagementColumns: any[] = ['action','plant', 'region', 'name', 'code', 'email', 'contact', 'address', 'country', 'city',  ];
  // tslint:disable-next-line: max-line-length
  // customerManagementDataSource = [];
  adduserColumns: string[] = ['action','name', 'email', 'products', ];
  NCRDataForm!: FormGroup;
  SearchCustomerForm!: FormGroup;
  customerId!: number; 
  customerUserId!: number;
  // firstForm!: FormGroup;
  CustomerDataForm!: FormGroup;
  userDataForm!: FormGroup;
  formMode = true;
  groups!: any[];
  filterProducts!: any;
  breadcrumList!: any;
  filteredCountries: any;
  filteredCities: any;
  IsAccess: any;
  IsWrite: any;
  GroupList!: any[];
  // displayRoleModal=false;
  // isRead!: boolean;
  // isWrite!: boolean;
  // isUpdate!: boolean;
  // isDelete!: boolean;
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private customer: CustomerManagementService,
    // private user: CustomerManagementService,
    private toaster: ToastrService,
    private commonService: CommonService,
    private platModelService: EnterPriseGroupService,
    private userManagementService: UserManagementService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private oeeConfigurationService: OeeConfigurationService,
    private machineService: MachineMappingService, private toastrService: ToastrService,
    ) { }
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('displayRoleModal1') private displayRoleModal1!: TemplateRef<any>;


  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  ngOnInit(): void {
    // this.isRead = this.pPermissions.IsRead;
    // this.isWrite = this.pPermissions.IsCreate;
    // this.isUpdate = this.pPermissions.IsUpdate;
    // this.isDelete = this.pPermissions.IsDelete;
    this.GetBreadcrumList();
    //this.getProducts();
    this.GetMenuAccess();
    this.getGroup();


    this.CustomerDataForm = this.fb.group({
      Id: [],
      group: ['', Validators.required],
      plant: ['', Validators.required],
      region: ['', Validators.required],
      name: ['', Validators.required],
      code: [''],
      email: ['', [Validators.required, Validators.email]],
      cperson: [''],
      cno: ['', [Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]],
      country: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required]
    });
    this.userDataForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required , Validators.email]],
      password: ['', Validators.required],
      products: ['', Validators.required]
    });

    this.SearchCustomerForm = this.fb.group({
      plant: ['', Validators.required],
      groupList: ['', Validators.required]
    });

    //this.getAllPlants();
    this.getCustomersList();

  }

  getGroup(): void {
    const key = {
      Id: 0
    };
    this.oeeConfigurationService.getGroupData(key).subscribe((data: any) => {

      this.GroupList = data.masterList;
      this.SearchCustomerForm.get('groupList')?.setValue(this.GroupList[0].TypeId);
      this.getProducts(this.GroupList[0].TypeId);
      this.getPlantsSearchData();
    }, (error: any) => {
      this.toastrService.error('Error', error.message);
    });
  }

  onChangeSearchDataGroup(data: any): void {
     debugger
     this.SearchCustomerForm.get('groupList')?.setValue(data.value);
     this.getPlantsSearchData();
     this.getProducts(data.value);

  }

  onChangePlant(data: any): void {

    this.getCustomersList();

  }

  getPlantsSearchData(): void {
    const key = {
      GroupId: this.SearchCustomerForm.value.groupList
    };
  
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.plantsDDL = data.DepartList;
      this.SearchCustomerForm.get('plant')?.setValue(this.plantsDDL[0].DeptId);
      this.getCustomersList();
    }, (error: any) => {
      this.toastrService.error('Error', error.message);
    });
  }


  GetMenuAccess(): void {
  
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  addNewCustomer(): void {

    this.formMode = true
    this.CustomerDataForm.reset();
   

    this.CustomerDataForm.get('group')?.setValue(this.SearchCustomerForm.get('groupList')?.value);
    this.CustomerDataForm.get('plant')?.setValue(this.SearchCustomerForm.get('plant')?.value);
    this.datasource();
    this.getCountries();
    this.dialog.open(this.displayRoleModal, { height: '80%', width: '90%' });
    this.loading = false
  }
  openModal(): void {
    this.loading = false
    this.dialog.open(this.displayRoleModal, { height: '80%', width: '90%' });
  }
  openUserModal(element: any): void {
    this.formMode = true
    this.loading = false
    // this.firstForm.get('Id')?.setValue(this.Id);
    this.dialog.open(this.displayRoleModal1, { height: '80%', width: '90%' });
    this.getUserList(element);
    this.userDataForm.reset();
  }
  EditUserModal(): void {
     // this.firstForm.get('Id')?.setValue(this.Id);
     this.dialog.open(this.displayRoleModal1, { height: '80%', width: '90%' });
   }


  getPlants(): void {
    const key = {
      GroupId: this.CustomerDataForm.value.group

    };

    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.plants = data.DepartList;

      // this.CustomerDataForm.get('plant')?.setValue(this.plants[0].DeptId);
    }, (error: any) => {
      this.toastrService.error('Error', error.message);
    });
  }

  onChangeGroup(data: any): void {
    this.CustomerDataForm.get('group')?.setValue(data.value);
    this.getPlants();
  }


  datasource(): void {
    const key = {
      Id: 0
    };

    this.oeeConfigurationService.getGroupData(key).subscribe((data: any) => {

      this.groups = data.masterList;
      // this.CustomerDataForm.get('group')?.setValue(this.groups[0].TypeId);
      this.getPlants();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  getCustomersList(): void {


    const key = {
      groupId: this.SearchCustomerForm.value.groupList,
      plantId: this.SearchCustomerForm.value.plant
    };

    this.customer.getCustomerList(key).subscribe((res: any) => {
      this.spinner.hide();
      this.loading = false;
      this.customerListDataSource = new MatTableDataSource<any>(res);
      this.getPaginator( this.customerListDataSource, 0);
    });
  }
  getCountries(): void {
 

    this.customer.getCountries().subscribe(

      (res) => {
        this.countries = res;
        this.CustomerDataForm.get('country')?.setValue(this.countries[0].countryId);
        this.filteredCountries = this.countries.slice();
       
       
        this.getCities();
      }

    );
  }
  getCities(): void {

     const key = {
      countryId: this.CustomerDataForm.value.country,
    };
     this.customer.getCities(key).subscribe( (res: any) => {
        this.cities = res;
        this.CustomerDataForm.get('city')?.setValue(this.cities[0].cityId);
        this.filteredCities = this.cities.slice();
      }

    );
  }

  getCustomerbyId(element: any): void {

     this.formMode = false;
     this.openModal();
     const key = {
      customerId: element,
    };
     this.customerId = element;
     this.customer.getCustomerById(key).subscribe((data : any) => {
         this.datasource();
         this.CustomerDataForm.get('group')?.setValue(this.SearchCustomerForm.value.groupList);
        //  this.CustomerDataForm.get('plant')?.setValue(data.DeptId);
         this.CustomerDataForm.get('plant')?.setValue(this.SearchCustomerForm.value.plant);
         this.CustomerDataForm.get('country')?.setValue(data.countryId);
         this.getCities();
         this.CustomerDataForm.get('city')?.setValue(data.cityId);
         this.CustomerDataForm.get('name')?.setValue(data.customerName);
         this.CustomerDataForm.get('region')?.setValue(data.region);
         this.CustomerDataForm.get('code')?.setValue(data.customerCode);
         this.CustomerDataForm.get('email')?.setValue(data.email);
         this.CustomerDataForm.get('cno')?.setValue(data.contactNo);
         this.CustomerDataForm.get('cperson')?.setValue(data.contactPersonName);
         this.CustomerDataForm.get('address')?.setValue(data.address);
      //  this.CustomerDataForm.get('elementId')?.setValue(element);
    },
    (error: any) => {
      this.toaster.error('Error', error.error.message);
    });
  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
      data.sort = this.sort.toArray()[index];

    }, 1000);
  }

  applyFilter(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.customerListDataSource.filter = filterValue.trim().toLowerCase();
  }
  applyFilteruser(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.userListDataSource.filter = filterValue.trim().toLowerCase();
  }
  deleteCustomer(customerId: number): void {

    //if (this.isDelete) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete Customer!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          const key = {
            customerId,
          };
          this.customer.deleteCustomer(key).subscribe(

            (success: any) => {
              this.getCustomersList(),
              Swal.fire({
                title: 'Deleted!',
                text: 'Your selected Customer has been deleted.',
                icon: 'success',
                timer: 800,
                showConfirmButton: false,
              });

            },
            (error: any) => {
              this.toaster.error('Error', error.error.message);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your selected Customer is safe :)', 'error');
        }
      });
    // } else {
    //   this.toaster.error('Alert!', 'you do not have permission to access');
    // }
  }
  addCustomerForm(): void {
    this.loading = true;
    const data = {
      plantId: this.CustomerDataForm.value.plant,
      region: this.CustomerDataForm.value.region,
      customerName: this.CustomerDataForm.value.name,
      customerCode: this.CustomerDataForm.value.code,
      email: this.CustomerDataForm.value.email,
      contactPersonName: this.CustomerDataForm.value.cperson,
      contactNo: this.CustomerDataForm.value.cno,
      countryId: this.CustomerDataForm.value.country,
      cityId: this.CustomerDataForm.value.city,
      address: this.CustomerDataForm.value.address,

    };

    this.customer.addCustomer(data).subscribe(
      (response: any) => {
       this. getCustomersList();
        // this.customerListDataSource = new MatTableDataSource<any>(res.UserList);
       this.toaster.success('Success', 'Customer Added Successfully');
       this.loading = false;
       this.dialog.closeAll();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.loading = false;
      }
    );
  }
  updateCustomerForm(): void {
    this.loading = true;
    const data = {
      customerId: this.customerId,
      plantId: this.CustomerDataForm.value.plant,
      region: this.CustomerDataForm.value.region,
      customerName: this.CustomerDataForm.value.name,
      customerCode: this.CustomerDataForm.value.code,
      email: this.CustomerDataForm.value.email,
      contactPersonName: this.CustomerDataForm.value.cperson,
      contactNo: this.CustomerDataForm.value.cno,
      countryId: this.CustomerDataForm.value.country,
      cityId: this.CustomerDataForm.value.city,
      address: this.CustomerDataForm.value.address,
    };
    
    if (this.CustomerDataForm.invalid){
      this.toaster.error('Error', "Please fills the all fields!");
      this.loading = false;
      return
    }


    this.customer.updateCustomer(data).subscribe(
      (response: any) => {
        this. getCustomersList();
        this.toaster.success('Success', 'Customer Updated Successfully');
        this.loading = false;
        this.dialog.closeAll();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.loading = false;
      }
    );
  }
  resetUserFields(): void {
    this.userDataForm.reset();
    this.formMode = true;
  }

  getProducts(element: any): void {
   const key = {
      egId: element
    };
   this.customer.getProducts(key).subscribe(
      (res: any) => {
        this.products = res;

        this.filterProducts = this.products.slice();
        this.userDataForm.get('products')?.setValue(this.products[0].prodId);
      }
    );
  }

  getUserList(element: any): void {

    const key = {
      customerId: element.customerId
};
    this.customerId = element.customerId;
    this.spinner.show();
    this.customer.getUserList(key).subscribe((res: any) => {
        this.spinner.hide();
        this.loading = false;

        this.userListDataSource = new MatTableDataSource<any>(res);

        this.getPaginator(this.userListDataSource, 1);
    }
    );
  }

  addUserForm(): void {

    this.loading = true;
    const data = {
      customerId : this.customerId,
      customerUserDetails: this.userDataForm.value.products,
      userName: this.userDataForm.value.name,
       password: this.userDataForm.value.password,
      email: this.userDataForm.value.email,

    };
    if (this.userDataForm.invalid){
      this.toaster.error('Error', "Please fills the all fields!");
      this.loading = false;
      return
    }
    this.customer.addUserByCustomer(data).subscribe(
      (response: any) => {
        // this.customerListDataSource = new MatTableDataSource<any>(res.UserList);
        this.toaster.success('Success', 'User Added Successfully');
        this.loading = false;
        this.userDataForm.reset();
        this.getUserList({customerId: this.customerId});

      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.loading = false;
      }
    );
  }
  deleteUser(customerUserId: number): void {

    //if (this.isDelete) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete User!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          const key = {
            customerUserId,
          };
          this.customer.deleteUser(key).subscribe(

            (success: any) => {
              this.getUserList({customerId: this.customerId}),

              Swal.fire({
                title: 'Deleted!',
                text: 'Your selected User has been deleted.',
                icon: 'success',
                timer: 800,
                showConfirmButton: false,
              });

            },
            (error: any) => {
              this.toaster.error('Error', error.error.message);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your selected Customer is safe :)', 'error');
        }
      });
    // } else {
    //   this.toaster.error('Alert!', 'you do not have permission to access');
    // }
  }

  getUserbyId(data: any): void {

    var Id: any[] = [];
    var p = data.prodId.split(",").map(Number);

    this.customerUserId = data.customerUserId;
    this.formMode = false;
    this.userDataForm.get('name')?.setValue(data.userName);
    this.userDataForm.get('email')?.setValue(data.email);
    this.userDataForm.get('products')?.setValue(p);
    this.userDataForm.get('password')?.setValue(data.userPassword);

 }

 public isFiltered(multiselectOption: any): any {
  return this.products.find((item: any) => item.prodId === multiselectOption.prodId);
}
  updateUserForm(): void {
    this.loading = true;
    const data = {
      customerUserId: this.customerUserId,
      customerUserDetails: this.userDataForm.value.products,
      userName: this.userDataForm.value.name,
      password: this.userDataForm.value.password,
      email: this.userDataForm.value.email,

    };
    if (this.userDataForm.invalid){
      this.toaster.error('Error', "Fill all the fields!");
      this.loading = false;
      return
    }
    this.customer.updateUser(data).subscribe(
      (response: any) => {
        this. getUserList({customerId: this.customerId});
        this.toaster.success('Success', 'User Updated Successfully');
        this.loading = false;
        this.dialog.closeAll();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.loading = false;
      }
    );
  }

//menu and breadcrumb
GetBreadcrumList(): any {
  const obj = {
    MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
  };
  // tslint:disable-next-line: align
  this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
    this.breadcrumList = data.BreadcrumList;
    console.log(data.BreadcrumList);
  }, (error: any) => {
    this.toaster.error('Error', error.ErrorMessage);
  });
}
DisplayRightmenu(menuId: any, url: any): any {
  localStorage.setItem('O3RightMenuId', menuId);
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    this.router.navigate([url]));
  // return false;
}
}
