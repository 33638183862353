import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MeetingmasterService } from 'src/app/services/meetingmaster.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
    selector: 'app-meeting-menus',
    templateUrl: './meeting-menus.component.html',
    styleUrls: ['./meeting-menus.component.scss'],
})
export class MeetingMenusComponent implements OnInit {
    @Input() plantId = null;
    @Input() groupId = null;

    @ViewChild('menuAdditionModal') menuAdditionModal!: TemplateRef<any>;
    @ViewChild('meetingMenusPaginator') meetingMenusPaginator: MatPaginator;

    menuAdditionForm!: FormGroup;

    meetingMenuColumn: string[] = ['Action', 'Menu'];

    languageDir: string = 'ltr';
    IsWrite: any = null;
    IsAccess: any = null;
    langObj: any = {};
    meetingMenuListDS: any = null;
    meetingMenuFilterVal: any = null;
    meetingMenuList: any[] = [];
    plantAllowedMenuList: any[] = [];
    breadcrumList: any;
    moduleId: any;
    pageId: any;

    constructor(
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private enterpriseGroupService: EnterPriseGroupService,
        private meetingMasterService: MeetingmasterService,
        private toaster: ToastrService,
        public translater: TranslaterService,
        private userService: UserManagementService,
    ) {
    }

    GetMenuAccess(): void {
        const key = {
            Id: localStorage.getItem('O3RightMenuId'),
        };
        this.enterpriseGroupService.GetMenuAccess(key).subscribe(
            (data: any) => {
                this.IsAccess = data.IsAccess;
                this.IsWrite = data.IsWrite;
                this.getLangWords();
            },
            (error) => {
                this.toaster.error('Error', error.error.message);
            }
        );
    }

    public getLangWords() {
        const key = {
            ModuleId: 0,
            PageId: 0,
            LanguageId: 1
        };
        this.translater.getLangWords(key).subscribe((res: any) => {
            let obj: any = {};
            res.tagList.map((elem: any) => {
                obj[elem.tagName] = elem.translatedValue;
            });
            this.langObj = obj;
        });
    }


    ngOnInit(): void {
        this.menuAdditionForm = new FormGroup({
            menuList: new FormControl('', Validators.required)
        });
        this.GetBreadcrumList();
        this.GetMenuAccess();
    }

    GetBreadcrumList(): any {
        const obj = {
          MenuId:
            localStorage.getItem('O3RightMenuId') === null
              ? 1
              : localStorage.getItem('O3RightMenuId'),
        };
        // tslint:disable-next-line: align
        this.userService.GetBreadcrumList(obj).subscribe(
          (data: any) => {
            this.breadcrumList = data.BreadcrumList;
            this.moduleId = this.breadcrumList[0].id;
            this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
            this.getLangWords();
          },
          (error) => {
            this.toaster.error('Error', error.ErrorMessage);
          }
        );
      }
   public getMeetingMenuList(): void {
        this.meetingMasterService.getMeetingMenuList().subscribe((res) => {
            this.meetingMenuList = res;
            this.getPlantAllowedMenu();
        },
            ({ error }) => {
                this.toaster.error(error.message, "Error");
            });
    }

    getPlantAllowedMenu(): void {
        var key = {
            plantId: this.plantId,
            groupId: this.groupId
        }
        this.meetingMasterService.GetPlantAllowedMenu(key).subscribe((res) => {
            this.plantAllowedMenuList = res;
            this.meetingMenuList = this.meetingMenuList.filter(x => !this.plantAllowedMenuList.some(y => y.meetingMenuId == x.meetingMenuId));
            this.meetingMenuListDS = new MatTableDataSource<any>(res.reverse());
            this.meetingMenuListDS.paginator = this.meetingMenusPaginator;
        },
            ({ error }) => {
                this.toaster.error("Something went wrong", "Error");
            })
    }

    openMenuAdditionModal(): void {
        this.menuAdditionForm.reset();
        this.dialog.open(this.menuAdditionModal, {
            width: '50%',
            height: '50%',
            disableClose: false
        });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    deleteMeetingMenuInPlant(id: any): void {
        this.spinner.show();
        var key = {
            plantId: this.plantId,
            groupId: this.groupId,
            meetingMenuId: id
        }
        this.meetingMasterService.DeletePlantAllowedMenu(key).subscribe((res) => {
            if (res.message == "Successful") {
                this.spinner.hide();
                this.toaster.success("Menu Deleted", "Success");
                this.getMeetingMenuList();
            }
        },
            ({ error }) => {
                this.spinner.hide();
                this.toaster.error(error.message, "Error");
            })
    }

    meetingMenuFilter(): void {
        this.meetingMenuListDS.filter = this.meetingMenuFilterVal.trim().toLowerCase();
      }

    insertMeetingMenuInPlant(): void {
        this.spinner.show();
        let meetingMenuObservable: Observable<any>[] = [];
        var meetingMenuArr = this.menuAdditionForm.controls.menuList.value;
        for (var data of meetingMenuArr) {
            var key = {
                plantId: this.plantId,
                groupId: this.groupId,
                meetingMenuId: data.meetingMenuId
            }
            meetingMenuObservable.push(this.meetingMasterService.AddPlantAllowedMenu(key));
        }

        forkJoin(meetingMenuObservable).subscribe((res) => {
            for (var data of res) {
                if (data.message != "Successful") {
                    this.spinner.hide();
                    this.toaster.error(data.message, "Error");
                    return;
                }
            }
            this.spinner.hide();
            this.getMeetingMenuList();
            this.toaster.success("Meeting Matrix Menu Permission is added successfully", "Success");
            this.dialog.closeAll();
        });

    }


}