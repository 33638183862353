import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Frequency } from 'src/app/models/frequency';
import { Kpi } from '../../models/kpi';
import { Tool } from 'src/app/models/tool';
import { Action } from 'src/app/models/action';
import { LossanalysisService } from 'src/app/services/lossanalysis.service';
import { Kpireason } from 'src/app/models/kpireason';
import { UserManu } from 'src/app/models/userManu';
import { FourM } from '../../models/fourM';
import { UserPlant } from 'src/app/models/userPlant';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { TreeNode } from 'src/app/interface/TreeNode';
import { TranslaterService } from 'src/app/services/translater.service';
import { DatepickerService } from 'src/app/services/Datepicker.service';

@Component({
  selector: 'app-lossanalysis',
  templateUrl: './lossanalysis.component.html',
  styleUrls: ['./lossanalysis.component.scss'],
})
export class LossanalysisComponent implements OnInit {
  // Yearly Target Set
  fieldArray: Array<any> = [];
  newAttribute: Action = {
    action: '',
    targetDate: '',
    actionId: 0,
    lossAnalysisId: 0,
    resposible: '',
    accountableUser: '',
  };
  calendar_en: any = {
    closeText: 'Close',
    prevText: 'Previous',
    nextText: 'Next',
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    weekHeader: 'Week',
    weekNumberTitle: 'W',
    firstDayOfWeek: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: '',
    timeOnlyTitle: 'Only Time',
    timeText: 'Time',
    hourText: 'Hour',
    minuteText: 'Minute',
    secondText: 'Second',
    currentText: 'Current Date',
    ampm: false,
    year: 'Year',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    list: 'Agenda',
    allDayText: 'All Day',
    moreLinkText: 'More...',
    noEventsText: 'No Events',
    today: 'Today',
    clear: 'Clear',
  };
  searchPlantForm!: FormGroup;
  // Monthly Target Set
  searchPlants!: UserPlant[];
  kpifrequencies!: Frequency[];
  frequencies!: Frequency[];
  kpis!: Kpi[];
  kpiId!: any;
  reasonslist!: Kpireason[];
  toolslist!: Tool[];
  plants!: UserPlant[];
  data1!: TreeNode[];
  rangeDates!: Date[];
  data2!: TreeNode[];
  events: string[] = [];
  kpiItems!: MenuItem[];
  stratgicKpiItems!: MenuItem[];
  selectedNode!: TreeNode;
  gapAnalysisForm!: boolean;
  gapAnalysisDetail!: boolean;
  visibleVisionTarget = false;
  visibleGapAnalysis = false;
  visibleQuaterlyTarget = false;
  visibleMonthlyTarget = false;
  visibleWeeklyTarget = false;
  visibleDailyTarget = false;
  visibleShiftlyTarget = false;
  visionMenuItems!: MenuItem[];
  stratgicvisionMenuItems!: MenuItem[];
  title!: string;
  formula!: string;
  selectedCountry!: string;
  chosenYearDate!: any;
  lossAnalysisFormMode!: string;
  raci!: any;
  /////

  actionlist: Action[] = [];
  // selectedCountry: string;
  tools!: any[];
  reasons!: any[];
  shifts!: any[];
  uoms!: any[];
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  pillars!: any[];
  departments!: any[];
  drivers!: any[];
  displayMaximizable!: boolean;
  displayStartgicKpi!: boolean;
  displayFormulaBuilder!: boolean;
  remark!: string;
  quantify!: string;
  dateValue!: string;
  header = 'Add Vision';
  headerKpi = 'Add Startgic Kpi';
  showButton = false;
  strtgicshowButton = false;
  // Vision Proprites
  visionGroup!: string;
  visionGroupData!: any[];

  visionPlant!: string;
  visionPlantData!: any[];
  visionTitle!: string;
  visionDate!: string;
  visionQuantify!: string;
  visionRemark!: string;
  // Stargic Kpi Proprites
  stratgicKpiGroup!: string;
  stratgicKpiGroupData!: any[];
  stratgicKpiPlant!: string;
  stratgicKpiPlantData!: any[];
  stratgicKpiQuantify!: string;
  stratgicKpiRemark!: string;
  // Kpi Propties
  formulaBuilder!: string;
  kpiTitle!: string;
  kpiDriver!: string;
  kpifrequency!: string;
  reason!: string;
  LossAnalysisForm!: FormGroup;
  lossAnalysisId!: number;
  kpiUom: string | undefined;
  kpiPillar: string[] = [];
  kpidepartments: string[] = [];
  kpiFormula!: string;
  kpiResponsible!: string;
  kpiAccountable!: string;
  kpiC!: string;
  kpiInformed!: string;
  kpiTarget!: string;
  kpiEntry!: string;
  yearTargetAmount!: number;
  toolPath!: string;
  toolName!: string;
  userMenu!: UserManu[];
  lossPermission!: UserManu;
  fourMList!: FourM[];
  KPiDate!: Date;
  resposibleUsers!: any;
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  filterdraci: any;
  groupList: any;
  lang: any;
  constructor(
    private primengConfig: PrimeNGConfig,
    private kpiservice: KpitreeService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private kpiMaster: KpimasterService,
    private lossAnalysisService: LossanalysisService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private translater: TranslaterService,
    private datepickerService: DatepickerService,
  ) { }
  example: TreeNode = {
    label: 'New Kpi',
    type: 'kpi',
    styleClass: 'p-person',
    expanded: true,
    data: { name: 'Frequency', uom: 'uom' },
  };
  addStratgicKpi: TreeNode = {
    label: 'Stargi Kpi',
    type: 'stratgickpi',
    styleClass: 'p-stratgickpi',
    expanded: true,
    data: { name: 'Stratgic Kpi Text', avatar: 'saul.jpg' },
  };
  selectedFile!: TreeNode;
  text!: string;
  ngOnInit(): void {
    const today = new Date();
    this.visionDate =
      today.getDate() +
      '/' +
      (today.getMonth() + 1) +
      '/' +
      today.getFullYear();
    const listMenu = localStorage.getItem('result');
    const permissions = listMenu !== null ? JSON.parse(listMenu) : [];
    this.lossPermission = permissions.find(
      (x: { ParentName: string; MenuName: string; Actions: string }) =>
        x.ParentName === 'KPI Management' &&
        x.MenuName === 'KPI Gap Analysis' &&
        x.Actions === 'KPI Gap Analysis'
    );
    const body = document.querySelector('body');
    body?.classList.add('sidebar-icon-only');
    this.primengConfig.ripple = true;
    this.searchPlantForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      // frequency: [''],
    });
    this.lossAnalysisFormMode = 'add';

    this.LossAnalysisForm = this.fb.group({
      reasonname: ['', Validators.required],
      toolname: ['', Validators.required],
      lossAnalysisDate: ['', Validators.required],
      targetDate: [''],
      reasonId: [''],
      remarks: [''],
      actions: [''],
      toolId: [''],
      rootcause: [''],
      fourm: [''],
      kpi: [''],
    });

    this.kpiItems = [
      {
        label: 'Add Analysis',
        icon: 'pi pi-fw pi-user-plus',
        command: (event) => {
          if (this.IsAccess) {
            this.gapAnalysisForm = true;
            this.gapAnalysisDetail = false;
            this.getMasterKpiReason();
            this.closeContextMenu();
            this.LossAnalysisForm.reset();
            this.toolName = '';
            this.actionlist = [];
            // const data: any = this.getOneLossAnalysis(this.selectedNode.kpiId);
            this.gapAnalysisForm = true;
            // if(data) { this.gapAnalysisForm = true;}
            this.visibleGapAnalysis = true;
            this.lossAnalysisFormMode = 'add';
          } else {
            this.toaster.error(
              'Alert!',
              'you do not have permission to access'
            );
            this.closeContextMenu();
          }
        },
      },
      {
        label: 'Remove Analysis',
        icon: 'pi pi-fw pi-trash',
        command: (event) => {
          if (this.IsAccess) {
            if (this.selectedNode) {
              this.closeContextMenu();
              this.deleteGapAnalysis(this.selectedNode.kpiId);
              this.LossAnalysisForm.reset();
              const index = this.selectedNode.children?.indexOf(
                this.selectedNode
              );
            }
          } else {
            this.toaster.error(
              'Alert!',
              'you do not have permission to access'
            );
            this.closeContextMenu();
          }
        },
      },
    ];
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroupData();
    this.setWeekValue(1);
  }

  // getAllPlants(): void {
  //   const key = {
  //     userId: JSON.parse(localStorage.user).UserId,
  //   };
  //   this.kpiMaster.getUserPlants(key).subscribe(
  //     (plants) => {
  //       this.searchPlants = plants;
  //       this.plants = plants;
  //       if (this.searchPlants.length === 1) {
  //         const plantObject = this.searchPlants[0];
  //         this.searchPlantForm.get('plant')?.setValue(plantObject);
  //         this.getTreeData(plantObject);
  //       }
  //     },
  //     (error) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }
  getGroupData(): void {
    const key = {
      Id: 0
    };
    // tslint:disable-next-line: no-debugger
    this.usermanagementService.getGroupData(key).subscribe((data: any) => {
      this.groupList = data.masterList;
      const obj = this.groupList[0];
      this.searchPlantForm.get('group')?.setValue(obj);
      this.getPlants();
    }, (error: any) => {
      this.toaster.error('error', error.message);
    });
  }
  getPlants(): void {
    const key = {
      GroupId: this.searchPlantForm.value.group.TypeId
    };
    this.usermanagementService.getDepartments(key).subscribe((res: any) => {
      this.searchPlants = res.DepartList;
      this.plants = res.DepartList;
      const plantObject = this.searchPlants[0];
      this.searchPlantForm.get('plant')?.setValue(plantObject);
      this.getTreeData(plantObject);
      this.getUserList();
    },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }


  deleteGapAnalysis(data: any): void {
    const key = {
      kpiId: data
    };
    this.lossAnalysisService.deleteLossAnalysis(key).subscribe(() => {
      this.toaster.success('Action Analysis Data Deleted Successfuly');
    }, (error: any) => {
      this.toaster.error('error', error.error.message);
    });
  }
  getTreeData(event: any): void {
    this.getUserList();
    this.frequencies = [];
    this.kpis = [];
    // const id = event.value.ptId;
    const id = this.searchPlantForm.value.plant.DeptId;
    const key = {
      plantId: id,
      userId: JSON.parse(localStorage.user).userId,
    };
    this.kpiMaster.getMasterFrequencies(key).subscribe(
      (frequency) => {
        this.frequencies = frequency;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );

    this.kpiMaster.getMasterTools(key).subscribe(
      (data) => {
        this.toolslist = data;
      },
      (error) => {
        this.toaster.error('error', error.message);
      }
    );

    this.kpiMaster.getMasterFourM(key).subscribe(
      (data) => {
        this.fourMList = data;
      },
      (error) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  // getOneLossAnalysis(key: any): void {

  //   const lossAnalysisId = {
  //     kpiId: key
  //   };

  //   this.kpiMaster.getOneLossAnalysis(lossAnalysisId).subscribe(
  //     // tslint:disable-next-line: no-shadowed-variable
  //     (data: any) => {
  //       if (data !== null) {
  //         this.gapAnalysisForm = false;

  //         this.LossAnalysisForm.get('remarks')?.setValue(data.remarks);
  //         this.LossAnalysisForm.get('rootcause')?.setValue(data.rootCause);

  //         const reasonObj = this.reasonslist.find(
  //           (x) => x.kpiReasonId === parseInt(data.reason, 10)
  //         );
  //         const fourObj = this.fourMList.find(
  //           (x) => x.fourMId === parseInt(data.fourM, 10)
  //         );
  //         const toolObj = this.toolslist.find(
  //           (x) => x.toolId === parseInt(data.tool, 10)
  //         );

  //         this.LossAnalysisForm.get('reasonname')?.setValue(reasonObj);
  //         this.LossAnalysisForm.get('toolname')?.setValue(toolObj);
  //         this.LossAnalysisForm.get('fourm')?.setValue(fourObj);
  //         const targetDates = data.actions;
  //         this.actionlist = targetDates;
  //         this.LossAnalysisForm.get('lossAnalysisDate')?.setValue(
  //           data.analysisDate
  //         );
  //         this.lossAnalysisId = data.lossAnalysisId;
  //       } else {
  //         this.LossAnalysisForm.get('remarks')?.reset();
  //         this.LossAnalysisForm.get('rootcause')?.reset();
  //         this.LossAnalysisForm.get('reasonname')?.reset();
  //         this.LossAnalysisForm.get('toolname')?.reset();
  //         this.LossAnalysisForm.get('fourm')?.reset();
  //         this.LossAnalysisForm.get('lossAnalysisDate')?.reset();
  //         this.actionlist = [];
  //         this.gapAnalysisForm = true;
  //       }
  //     },
  //     (error) => {
  //       this.toaster.error('error', error.message);
  //     }
  //   );

  // }

  getMasterKpiReason(): void {
    const key = {
      plantId: this.searchPlantForm.value.plant.DeptId,
      kpiId: this.selectedNode.kpiId,
    };
    this.lossAnalysisService.getMasterReason(key).subscribe(
      (data) => {
        this.reasonslist = data;
      },
      (error) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  closeFrequencyModel(): void {
    this.visibleGapAnalysis = false;
  }

  fielArrayValueChange(event: any, index: number): void {
    const field = this.actionlist[index];
    field.action = event.target.value;
  }
  accountbleChange(event: any, index: number): void {
    const field = this.actionlist[index];
    field.accountableUser = event.value;
  }
  onSubmit(): void {
    if (this.lossAnalysisFormMode === 'add') {
      const filtereddata = this.actionlist.filter(
        (x) => x.action === '' || x.targetDate === ''
      );

      if (filtereddata.length === 0) {
        const Data = {
          reasonId: this.LossAnalysisForm.value.reasonname.kpiReasonId,
          toolId: this.LossAnalysisForm.value.toolname.toolId,
          remarks: this.LossAnalysisForm.value.remarks,
          kpiId: this.selectedNode.kpiId,
          rootCause: this.LossAnalysisForm.value.rootcause,
          lossAnalysisDate: this.LossAnalysisForm.value.lossAnalysisDate,
          fourMId: this.LossAnalysisForm.value.fourm.fourMId,
          action: this.actionlist,
        };

        this.lossAnalysisService.addLossAnalysis(Data).subscribe(
          (response: any) => {
            this.toaster.success(
              'Success',
              'Action Analysis Data Added Successfuly'
            );
            this.visibleGapAnalysis = false;
            this.LossAnalysisForm.reset();
            this.actionlist = [];
          },
          (error: any) => {
            this.toaster.error('error', error.message);
          }
        );
      } else {
        this.toaster.warning('Please Select Actions And Target Dates');
      }
    } else {
      this.updateLossAnalysis();
    }
  }

  updateLossAnalysis(): void {
    const filtereddata = this.actionlist.filter(
      (x) => x.action === '' || x.targetDate === ''
    );
    if (filtereddata.length === 0) {
      const Data = {
        reasonId: this.LossAnalysisForm.value.reasonname.reasonId,
        toolId: this.LossAnalysisForm.value.toolname.toolId,
        remarks: this.LossAnalysisForm.value.remarks,
        rootCause: this.LossAnalysisForm.value.rootcause,
        lossAnalysisDate: this.LossAnalysisForm.value.lossAnalysisDate,
        fourMId: this.LossAnalysisForm.value.fourm.fourMId,
        action: this.actionlist,
        kpiId: this.selectedNode.kpiId
      };
      this.lossAnalysisService.updateLossAnalysis(Data).subscribe(
        (response: any) => {
          this.toaster.success(
            'Success',
            'LossAnalysis Data Updated Successfully'
          );
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }
      );
    } else {
      this.toaster.warning('Please Select Actions And Target Dates');
    }
  }

  // getLossAnalysisAction(key: any): void {
  //   this.lossAnalysisService.getLossAnalysisActions(key).subscribe((data) => {
  //     this.actionlist = [];
  //   });
  // }

  getFrequencies(): void {
    this.kpiMaster.getFrequencies().subscribe(
      (frequencies) => {
        this.frequencies = frequencies;
        this.searchPlantForm
          .get('frequency')
          ?.setValue(this.frequencies.find((d) => d.name === 'Yearly'));
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onFrequencyChange(event: any): any {
    const key = {
      plantId: this.searchPlantForm.value.plant.DeptId,
      frequencyId: event.value.frequencyId,
    };

    this.kpiMaster.getKpiFrequency(key).subscribe(
      (kpis) => {
        this.kpis = kpis;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onKPiChange(event: any): any {
    this.KPiDate = new Date();
    this.onDateChange(this.KPiDate);
  }

  onToolChange(event: any): void {
    const tool = this.LossAnalysisForm.value.toolname;
    this.toolPath = tool.filePath;
    this.toolName = tool.filename;
  }
  onNodeSelect(event: any): void {
    this.selectedNode = event.node;
    this.resposibleUsers = event.node.resposibleUsers;
  }
  showMaximizableDialog(): void {
    this.displayMaximizable = true;
  }
  showStartgicKpiDialog(): void {
    this.displayStartgicKpi = true;
  }
  showFormulaDialog(): void {
    this.displayFormulaBuilder = true;
  }
  onDateChange(value: any): any {
    if (this.searchPlantForm.value.plant.DeptId === undefined) {
      this.toaster.warning('Warning', 'Please Select Plant');
      return;
    }
    const getdata = {
      date: getSqlDate(value),
      plantId: this.searchPlantForm.value.plant.DeptId,
      kpiId: this.kpiId.kpiId,
      userId: JSON.parse(localStorage.user).userId,
    };

    this.kpidepartments = [];
    if (
      getdata.plantId === undefined &&
      this.searchPlantForm.value.plant.DeptId === undefined &&
      this.kpiId === undefined
    ) {
      this.toaster.error('Error', 'Please Select Plant');
      return;
    }
    this.spinner.show();
    this.kpiservice.getLossAnalysisTreeData(getdata).subscribe(
      (response: any) => {
        const myArr = [];
        myArr.push(response);
        this.data1 = myArr;
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }

  fielDateValueChange(
    event: MatDatepickerInputEvent<Date>,
    index: number
  ): void {
    const field = this.actionlist[index];
    field.targetDate = event.value;
  }

  addEvent(
    type: string,
    event: MatDatepickerInputEvent<Date>,
    index: number
  ): void {
    const field = this.actionlist[index];
    field.targetDate = event.value?.toISOString();
  }


  addFieldValue(): void {
    const lastObj = this.actionlist[this.actionlist.length - 1];
    if (this.actionlist.length <= 0) {
      this.actionlist.push(this.newAttribute);
      this.newAttribute = {
        action: '',
        targetDate: '',
        actionId: 0,
        lossAnalysisId: 0,
        resposible: '',
        accountableUser: '',
      };
    }

    if (this.actionlist.length > 0 && lastObj.targetDate && lastObj.action) {
      this.actionlist.push(this.newAttribute);
      this.newAttribute = {
        action: '',
        targetDate: '',
        actionId: 0,
        lossAnalysisId: 0,
        resposible: '',
        accountableUser: '',
      };
    } else {
      this.toaster.warning('Please Select Actions And Target Date');
    }
  }
  deleteFieldValue(index: any): void {
    this.actionlist.splice(index, 1);
  }
  closeContextMenu(): void {
    const elements = document.getElementsByClassName('p-tieredmenu-overlay');
    while (elements.length > 0) {
      elements[0].classList.remove('p-tieredmenu-overlay');
    }
    const elem = document.querySelector('.ng-trigger-overlayAnimation');
    if (elem != null) {
      elem.remove();
    }
  }
  public isFiltered(multiselectOption: any): any {
    return this.filterdraci.find((item: any) => item.EmpInfo_Id === multiselectOption.EmpInfo_Id);
  }
  getUserList(): void {
    const key = {
      // egId: this.searchPlantForm.value.group.TypeId
      EGId: this.searchPlantForm.value.group.TypeId,
      PlantId: this.searchPlantForm.value.plant.DeptId,
    };
    this.kpiservice.getEmployeeMasterData(key).then((users) => {
      this.raci = users.employeeInfoList;
      this.raci.forEach((element: any) => {
        element.fullName = element.FirstName + ' ' + element.lastName;
        element.userId = element.UserId;
      });
      this.filterdraci = this.raci.slice();
    },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.kpiMaster.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;

    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
    // return false;
  }

  setWeekValue(weekDay: number): void {
    if (weekDay == undefined || weekDay == null || Number.isNaN(weekDay))
      weekDay = 0
    sessionStorage.removeItem('weekDay');
    sessionStorage.setItem('weekDay', weekDay.toString());
    this.datepickerService.getFirstDayOfWeek()
  }
}
function getSqlDate(value: any): any {
  return (
    value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate()
  );
}
