import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DefectManagementElement } from '../models/defectManagementElement';
import { OrderProcessingElement } from '../models/O3AdministrationModels/orderProcessingElement';
import { UserManager } from '../models/usermanagement/userManager';

@Injectable({
  providedIn: 'root'
})
export class DataSourceService {
  // tslint:disable-next-line:typedef
  baseUrl = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getOrderProcessingElementData(): Observable<OrderProcessingElement[]> {
    return this.http.get<OrderProcessingElement[]>('/assets/data/data-source.json');
  }

  getDefectManagementElementData(): Observable<DefectManagementElement[]> {
    return this.http.get<DefectManagementElement[]>('/assets/data/data-source-defect-management.json');
  }

  getUserData(data: any): Observable<UserManager[]> {
    return this.http.post<UserManager[]>(this.baseUrl + 'Account/GetUserManagerList', data);
  }

  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetGroup', data);
  }

  getPlantData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetDepartments', data);
  }

  getProductFamilyData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetProductFamilies', data);
  }

  updateProductFamily(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/UpdateProductFamily', data);
  }

  GetProductFamiliesDropDown(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetProductFamiliesDropDown', data);
  }

  InsertProduct(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/InsertProduct', data);
  }

  GetProducts(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetProducts', data);
  }

  GetProductProperties(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetProductProperties', data);
  }

  InsertProductProperty(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/InsertProductProperty', data);
  }

  UpdateProductProperty(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/UpdateProductProperty', data);
  }

  GetCharacteristics(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetCharacteristics', data);
  }

  InsertCharacteristics(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/InsertCharacteristics', data);
  }

  UpdateCharacteristics(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/UpdateCharacteristics', data);
  }

  GetSpecifications(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetSpecifications', data);
  }

  GetDataTypes(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'PM/GetDataTypes', data);
  }

  GetActiveSpecifications(data: any): Observable<any[]> {

    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetActiveSpecifications', data);
  }

  InsertSpecifications(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/InsertSpecifications ', data);
  }

  GetTreeModal(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'Common/GetTreeModal', data);
  }

  GetProductUnits(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetProductUnits', data);
  }

  GetProductPropertiesForMappings(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetProductPropertiesForMappings', data);
  }

  GetPropertyMapping(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetPropertyMapping', data);
  }

  UpdateSpecifications(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/UpdateSpecifications', data);
  }

  GetCharacteristicsByProductProperties(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetCharacteristicsByProductProperties', data);
  }

  GetSpecificationsByProductProperties(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetSpecificationsByProductProperties', data);
  }

  InsertMapping(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/InsertMapping', data);
  }

  UpdateMapping(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/UpdateMapping', data);
  }

  DeleteMapping(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/DeleteMapping', data);
  }

  DeleteSpecifications(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/DeleteSpecifications', data);
  }

  InsertProductUnit(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/InsertProductUnit', data);
  }

  DeleteProductUnit(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/DeleteProductUnit', data);
  }

  GetProductUnitsDropDown(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/GetProductUnitsDropDown', data);
  }

  InsertPropertyMapping(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/InsertPropertyMapping', data);
  }

  InsertProductFamily(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/InsertProductFamily', data);
  }

  UpdateProduct(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/UpdateProduct', data);
  }

  DeleteProductFamily(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/DeleteProductFamily', data);
  }

  DeleteProduct(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/DeleteProduct', data);
  }

  DeleteProductPropertyeteProduct(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/DeleteProductProperty', data);
  }

  DeletePropertyMapping(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/DeletePropertyMapping', data);
  }

  DeleteCharacteristics(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/DeleteCharacteristics', data);
  }

  UpdatePropertyMapping(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PM/UpdatePropertyMapping', data);
  }

  //================================================ Product Barcode Detail code here ============================//
  GetProductBarcodeDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ProductBarcode/GetProductBarcodeDetail', data);
  }

  AddOrUpdateProductBarcodeDetail(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ProductBarcode/AddOrUpdateProductBarcodeDetail', data);
  }
  //====================================================== END ===================================================//

}
