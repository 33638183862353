import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DtServiceService {
  
  baseUrl = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetGroup', data);
  }

  getPlantData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetDepartments', data);
  }

  getDeptData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Machine/GetDepartmentByPlant', data);
  }

  GetReasons(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetReasons', data);
  }
  insertReason(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/InsertReason', data);
  }
  updateReason(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/UpdateReason', data);
  }
  deleteReason(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/DeleteReason', data);
  }

  GetCategory(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetCategory', data);
  }
  insertCategory(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/InsertCategory', data);
  }
  updateCategory(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/UpdateCategory', data);
  }
  deleteCategory(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/DeleteCategory', data);
  }

  getTreeTypes(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetTreeTypes', data);
  }
  getTree(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetTree', data);
  }
  getPlantModelTree(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'DT/GetPlantModelTree', data);
  }
  insertTree(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/InsertTree', data);
  }
  updateTree(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/UpdateTree', data);
  }
  deleteTree(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/DeleteTree', data);
  }
  insertTreeLevel(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/InsertTreeLevel', data);
  }
  getTreeLevel(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetTreeLevel', data);
  }
  deleteTreeLevel(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/DeleteTreeLevel', data);
  }
  unitTreeModal(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'Common/GetTreeModal', data);
  }
  getTimedEventFault(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetTimedEventFault', data);
  }
  insertTimedEventFault(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/InsertTimedEventFault', data);
  }
  updateTimedEventFault(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/UpdateTimedEventFault', data);
  }
  deleteTimedEventFault(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/DeleteTimedEventFault', data);
  }
  getWasteEventFault(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetWasteEventFault', data);
  }
  insertWasteEventFault(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/InsertWasteEventFault', data);
  }
  updateWasteEventFault(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/UpdateWasteEventFault', data);
  }
  deleteWasteEventFault(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/DeleteWasteEventFault', data);
  }
  getReasonTrees(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetReasonTrees', data);
  }
  getReasonTree(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetReasonTree', data);
  }
  getTreeLevels(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetTreeLevels', data);
  }
  getEventReasons(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetEventReasons', data);
  }
  insertReasonTree(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/UpsertReasonTree', data);
  }
  updateReasonTree(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/UpdateReasonTree', data);
  }
  deleteReasonTree(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/DeleteReasonTree', data);
  }
  getCategories(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetCategories', data);
  }
  getCategoryMapping(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/GetCategoryMapping', data);
  }
  insertCategoryMapping(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/InsertCategoryMapping', data);
  }
  updateCategoryMapping(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/UpdateCategoryMapping', data);
  }
  deleteCategoryMapping(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DT/DeleteCategoryMapping', data);
  }
}
