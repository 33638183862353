import { Component, OnInit, ViewChild } from '@angular/core';
import {FormGroup,FormBuilder,FormControl,Validators,} from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThemePalette } from '@angular/material/core';
import { PeopleMasterDataService } from 'src/app/services/people-master-data-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee-position-mapping',
  templateUrl: './employee-position-mapping.component.html',
  styleUrls: ['./employee-position-mapping.component.scss']
})
export class EmployeePositionMappingComponent implements OnInit {

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public date: moment.Moment | undefined;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  displayMAchineModal = false;
  pillars!: Pillar[];
  pillarId!: number;
  plants!: Plantbygroup[];
  employeePositionMapForm!: FormGroup;
  formMode!: boolean;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  groupId!: number;
  plantId!: number;
  displayUserModal = false;
  pillarModelVisible = false;
  isUpdateEmpPosMap: boolean = false;
  plantsDDL!: any[];
  filteredemployeeMasterDDList!:any;
  filteredgeneralDepartmentDDList!:any;
  filteredpositionDDList!:any;

  owners = new FormControl();
  ownerList!: any[]
  employeePositionMapColumns: string[] = ['Employee', 'Department','Position','Actions'];
  employeePositionMappedData = new MatTableDataSource<any>([]);
  employeeMasterDDList: any;
  positionDDList : any;
  generalDepartmentDDList: any;
  filteredUsers: any;
  filtered: any[] = [];
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private peopleService : PeopleMasterDataService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formMode = true;
    this.employeePositionMapForm = this.fb.group({
      EmployeeMappingId : new FormControl(''),
      Employee: new FormControl('',[Validators.required]),
      Department: new FormControl('',[Validators.required]),
      Position : new FormControl('',[Validators.required])
    });
  }
  getPaginator(): void {
    setTimeout(() => {
      this.employeePositionMappedData.paginator = this.paginator;
      this.employeePositionMappedData.sort = this.sort;
    }, 1000);
  }
 
  getEmployeeList():void{
    const key = {
      EmpInfo_Id : 0,
      PlantId  :this.plantId,
      EGId : this.groupId
    }
    this.peopleService.getEmployeeMasterList(key).subscribe((data) => {
      this.employeeMasterDDList = data.employeeDDList;
      this.filteredemployeeMasterDDList = this.employeeMasterDDList.slice();
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });
  }

  getPositionDDList():void{
    const data = {
      POS_Id : 0,
      PlantId  :this.plantId,
      EGId : this.groupId
    }
    this.peopleService.getPositionDDList(data).subscribe((data) => {
      this.positionDDList = data.positionDDList;
      this.filteredpositionDDList =  this.positionDDList.slice();
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.employeePositionMapForm.controls[controlName].hasError(errorName);
  }

  getMasterDepartment(): void {
    const key = {
      Id  :this.plantId,
     };
     this.peopleService.getDepartmentData(key).subscribe((response: any) => {
      this.generalDepartmentDDList = response.masterList;
      this.filteredgeneralDepartmentDDList = this.generalDepartmentDDList.slice();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }


  getEmployeePositionMappedData(key: any): void {
    this.spinner.show();
    this.groupId = key.EGId;
    this.plantId = key.PlantId;
    this.employeePositionMapForm.get('group')?.setValue(key.groupId);
    this.employeePositionMapForm.get('plant')?.setValue(key.plantId);
    this.peopleService.getEmployeePositionMappedMasterData(key).subscribe((data) => {
      this.employeePositionMappedData = data.employeePositionMappedList;
      this.employeePositionMappedData = new MatTableDataSource<any>(data.employeePositionMappedList);
      this.getPaginator();
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });

  }
/// refresh component from position component
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
 
  saveEmployeePosMapping(): void {
    if (!this.isUpdateEmpPosMap && this.employeePositionMapForm.valid) {
      const data = {
        TransactionType : 1,
        EmployeeInfoId : this.employeePositionMapForm.get('Employee')?.value,
        PositionId : this.employeePositionMapForm.get('Position')?.value,
        DepartmentId : this.employeePositionMapForm.get('Department')?.value,
        PlantId : this.plantId,
        EGId : this.groupId,
      };
      this.peopleService.addEmployeePositionMapped(data).subscribe((response) => {
        this.toaster.success(response.ErrorMessage);
        this.reset();
        this.closedEmpPosMapModel();
        const key = {
          PlantId: data.PlantId,
          EGId: data.EGId
        };
       this.getEmployeePositionMappedData(key);
      }, (error) => {
        this.toaster.error('Error', error.error.message);
      });
    } 

    else {
      const data = {
        TransactionType : 2,
        EmployeeMappingId : this.employeePositionMapForm.get('EmployeeMappingId')?.value,
        EmployeeInfoId : this.employeePositionMapForm.get('Employee')?.value,
        PositionId : this.employeePositionMapForm.get('Position')?.value,
        DepartmentId : this.employeePositionMapForm.get('Department')?.value,
        PlantId : this.plantId,
        EGId : this.groupId,
      };
      this.peopleService.addEmployeePositionMapped(data).subscribe((response) => {
        this.toaster.success(response.ErrorMessage);
        this.closedEmpPosMapModel();
        this.isUpdateEmpPosMap = false;
        this.reset();
        const key = {
          PlantId: data.PlantId,
          EGId: data.EGId
        };
       this.getEmployeePositionMappedData(key);
      }, (error) => {
        this.toaster.error('Error', error.error.message);
      })
    }

  }

  onClickUpdateEmployeePositionMapped(element: any): void {
    this.getEmployeeList();
    this.getMasterDepartment();
    this.getPositionDDList();
    this.isUpdateEmpPosMap = true;
    console.log(element);
    this.employeePositionMapForm.get('EmployeeMappingId')?.setValue(element.EmployeeMappingId),
    this.employeePositionMapForm.get('Employee')?.setValue(element.EmpInfo_Id),
    this.employeePositionMapForm.get('Position')?.setValue(element.POS_Id),
     this.employeePositionMapForm.get('Department')?.setValue(element.Dept_Id),
    this.employeePositionMapForm.get('plant')?.setValue(this.plantId),
    this.employeePositionMapForm.get('group')?.setValue(this.groupId)
    this.opensideModal();
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.employeePositionMappedData.filter = filterValue.trim().toLowerCase();

    if (this.employeePositionMappedData.paginator) {
      this.employeePositionMappedData.paginator.firstPage();
    }
  }
 
  opensideModal(): void {
    debugger
    if (!this.isUpdateEmpPosMap) {
      this.formMode = true;
      this.displayUserModal = true;
      this.reset();
      this.isUpdateEmpPosMap = false;
      this.getEmployeeList();
      this.getMasterDepartment();
      this.getPositionDDList();
    }
    else {
      this.formMode = false;
      this.displayUserModal = true;
    }
  }

  closedEmpPosMapModel(): void {
    this.displayUserModal = false;
    this.reset();
  }
 onClickDeleteEmployeeMapping(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Master Employee,Position Mapped data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        var id = {
          TransactionType : 3,
          EmployeeMappingId: element.EmployeeMappingId,
          DOB : new Date(),
          DOJ : new Date(),
        }
        this.peopleService.addEmployeePositionMapped(id).subscribe(
          (data:any) => {
            const key = {
              PlantId: this.plantId,
              EGId: this.groupId
            };
           this.getEmployeePositionMappedData(key);
            this.toaster.success(data.ErrorMessage);
          },
          (error:any) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Master Employee,Position Mapping is safe :)', 'error');
      }
    });
   
  
  }
 
  reset(): void {
    this.employeePositionMapForm.reset();
    this.isUpdateEmpPosMap = false;
  }

}
