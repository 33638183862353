<div class="safety-db-content">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id" (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="title-row-flex-both-end">
      <h1 class="main-title">Safety-DB</h1>
      <div class="basic-mat-input">
        <span class="date-label">Last 30 days</span>
        <mat-form-field appearence="fill">
          <!-- <mat-label>Choose date range</mat-label> -->
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="startDate">
            <input matEndDate formControlName="endDate">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- Dropdown -->
  <form [formGroup]="dropDownForm">
    <div class="drop-down-group">
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Year</mat-label>
          <mat-select name="Year">
            <mat-option *ngFor="let Year of yearList" [value]="Year">
              {{Year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Month</mat-label>
          <mat-select name="Month">
            <mat-option *ngFor="let Month of monthList" [value]="Month">
              {{Month}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Week</mat-label>
          <mat-select name="Week">
            <mat-option *ngFor="let Week of weekList" [value]="Week">
              {{Week}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Day</mat-label>
          <mat-select name="Day">
            <mat-option *ngFor="let Day of dayList" [value]="Day">
              {{Day}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Shift</mat-label>
          <mat-select name="Shift">
            <mat-option *ngFor="let Shift of shiftList" [value]="Shift">
              {{Shift}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drop-down-element">
        <mat-form-field>
          <mat-label appearance="fill" class="field-fill">Machine</mat-label>
          <mat-select name="Machine">
            <mat-option *ngFor="let Machine of machineList" [value]="Machine">
              {{Machine}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <!-- Charts -->
  <div class="chart-group chart-menu-group">

    <!-- # of Accidents -->
    <div class="chart-items">
      <div class="chart-title"> # of Accidents</div>
      <app-o3-charts [chartData]="accidents" [datasets]="accidents.barChartData" [labels]="accidents.barChartLabels"
        [colors]="accidents.barChartColors" [options]="accidents.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- # of Near Misses & Unsafe Conditions -->
    <div class="chart-items">
      <div class="chart-title"> # of Near Misses & Unsafe Conditions</div>
      <app-o3-charts [chartData]="nearMissesUnsafeConditions" [datasets]="nearMissesUnsafeConditions.barChartData"
        [labels]="nearMissesUnsafeConditions.barChartLabels" [colors]="nearMissesUnsafeConditions.barChartColors"
        [options]="nearMissesUnsafeConditions.barChartOptions">
      </app-o3-charts>
    </div>
    <!-- BOS Score -->
    <div class="chart-items">
      <div class="chart-title"> BOS Score</div>
      <app-o3-charts [chartData]="bosScore" [datasets]="bosScore.barChartData" [labels]="bosScore.barChartLabels"
        [colors]="bosScore.barChartColors" [options]="bosScore.barChartOptions">
      </app-o3-charts>
    </div>
    <!-- Menu Card -->
    <div class="menu-items">
      <div class="menu-card-item chart-items ">
        <p class="title-text">TIR %</p>
        <p class="data-text">1.62
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuRedIconUrl" alt="{{menuRedIconUrl}}">
        </div>
      </div>
      <div class="menu-card-item chart-items ">
        <p class="title-text"># of Safe Hours</p>
        <p class="data-text">1E+06
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuGreenIconUrl" alt="{{menuGreenIconUrl}}">
        </div>
      </div>
      <div class="menu-card-item chart-items ">
        <p class="title-text">Safe Hours Last Record</p>
        <p class="data-text">934567
        </p>
        <div class="menu-bottom-icon">
          <img class="img" [src]="menuGreenIconUrl" alt="{{menuGreenIconUrl}}">
        </div>
      </div>
    </div>
    <!-- # of Accidents by Area -->
    <div class="chart-items">
      <div class="chart-title"> # of Accidents by Area</div>
      <app-o3-charts [chartData]="accidentsByArea" [datasets]="accidentsByArea.barChartData"
        [labels]="accidentsByArea.barChartLabels" [colors]="accidentsByArea.barChartColors"
        [options]="accidentsByArea.barChartOptions">
      </app-o3-charts>
    </div>

    <!-- # of Near Misses & Unsafe Conditions by Area -->
    <div class="chart-items">
      <div class="chart-title"> # of Near Misses & Unsafe Conditions by Area</div>
      <app-o3-charts [chartData]="nearMissesUnsafeConditionsByArea"
        [datasets]="nearMissesUnsafeConditionsByArea.barChartData"
        [labels]="nearMissesUnsafeConditionsByArea.barChartLabels"
        [colors]="nearMissesUnsafeConditionsByArea.barChartColors"
        [options]="nearMissesUnsafeConditionsByArea.barChartOptions">
      </app-o3-charts>
    </div>
    <div class="chart-items full-chart">
      <div class="chart-title"> # of Near Misses & Unsafe Conditions by Area</div>
      <app-o3-area-chart>
      </app-o3-area-chart>
    </div>
  </div>
</div>
