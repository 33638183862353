import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-customercomplaintmanagement',
  templateUrl: './customercomplaintmanagement.component.html',
  styleUrls: ['./customercomplaintmanagement.component.scss']
})
export class CustomercomplaintmanagementComponent implements OnInit {

  usermanagements!: any[];
  constructor(public router: Router, private toaster: ToastrService) { }

  ngOnInit(): void {
    this.usermanagements = [
      // tslint:disable-next-line:max-line-length
      {
        title: 'Customer Complaint',
        routerLink: this.getUserManager,
        icon: 'assets/images/manufacturing-admin.svg',
        disabled: false
      }
    ];
  }

  getUserManager = (): void => {
    this.router.navigate(['/CustomerComplaint/ccr-history']);
  }
  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.clear();
    this.toaster.info('logged out');
    this.router.navigate(['/login']);
  }
}
