import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuditCategoryService } from 'src/app/services/audit-category.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
@Component({
  selector: 'app-audit-categories',
  templateUrl: './audit-categories.component.html',
  styleUrls: ['./audit-categories.component.scss']
})
export class AuditCategoriesComponent implements OnInit {

  @Input() categoryLength!: number;
  @Input() IsWrite: boolean;

  @ViewChild(MatTable) table!: MatTable<Pillar>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayMAchineModal = false;
  pillars!: Pillar[];
  pillarId!: number;
  plants!: Plantbygroup[];
  masterCategoriesDDL: any[] = [];
  auditCategoryForm!: FormGroup;
  formMode!: boolean;
  plantId!: number;
  groupId!: number;
  plantsDDL!: any[];
  categoryTypeDDL: any[] = [{ id: 'Option', name: 'Option' }, { id: 'Score', name: 'Score' }];

  displayUserModal = false;
  pillarModelVisible = false;
  isUpdateAuditCategory = false;

  auditcatColumns: string[] = ['plant', 'auditName', 'score', 'areaActions'];
  auditCategoryData = new MatTableDataSource<any>([]);
  getareaMaster: any;
  categories!: any;
  filteredCategories!: any;
  filterArray!: any[];
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private machineService: MachineMappingService,
    private spinner: NgxSpinnerService,
    private auditCategoryService: AuditCategoryService,
  ) {}

  ngOnInit(): void {
    this.formMode = true;
    this.auditCategoryForm = this.fb.group({
      plantId: ['', Validators.required],
      masterAuditCategoryId: ['', Validators.required],
      categoryTypeId: ['', Validators.required],
      auditCatId: [''],
      group: ['']
    });
  }

  getPaginator(): void {
    setTimeout(() => {
      this.auditCategoryData.paginator = this.paginator;
      this.auditCategoryData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.auditCategoryData.filter = filterValue.trim().toLowerCase();

    if (this.auditCategoryData.paginator) {
      this.auditCategoryData.paginator.firstPage();
    }
  }

  getPlantsByGroup(): void {
    const key = {
      GroupId: this.groupId
    };
    this.machineService.getPlantsData(key).subscribe(
      (plants: any) => {
        this.plantsDDL = plants.DepartList;
        this.auditCategoryForm.get('plantId')?.setValue(this.plantId);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getMasterCategories(): void {
    const key = {
      plantId: this.plantId,
      groupId: this.groupId
    };
    this.auditCategoryService.getMasterCategories(key).subscribe((data) => {
      this.masterCategoriesDDL = data;
    },
    (error) => {
      this.toaster.error('Error', error.error.Message);
    });
  }

  getAuditMaster(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.groupId = key.groupId;
    this.auditCategoryForm.get('group')?.setValue(key.groupId);
    this.auditCategoryService.getAuditCategory(key).subscribe((data) => {
      this.auditCategoryData.data = data;
      this.getPaginator();
      this.spinner.hide();
      this.getMasterCategories()
    },
    (error) => {
      this.toaster.error(error.error.message);
      this.spinner.hide();
    });
  }

  opensideModal(): void {
    this.getPlantsByGroup();
    this.formMode = true;
    this.isUpdateAuditCategory = false;
    this.reset();
    this.displayUserModal = true;
    this.getMasterCategories();
    setTimeout(() => {
      const listArray = this.masterCategoriesDDL;
      const tableArray = this.auditCategoryData.data;
      const missingArray = listArray.filter((elem) => !tableArray.find(({ categoryName }) => elem.name === categoryName));
      this.filterArray = missingArray;
    }, 1500);
  }

  closeaddNewPlantItemModel(): void {
    this.displayUserModal = false;
  }

  saveAudit(): void {
    if (!this.isUpdateAuditCategory) {
      const data = {
        auditMasterCategoryId: this.auditCategoryForm.get('masterAuditCategoryId')?.value,
        catType: this.auditCategoryForm.get('categoryTypeId')?.value,
        plantId: this.auditCategoryForm.get('plantId')?.value,
        egId: this.groupId,
      };
      this.auditCategoryService.addAuditCategory(data).subscribe((response: any) => {
        if(response.message == "Categories is already taken"){
          this.toaster.warning('warning',response.message);
        }
        else{
          this.toaster.success('Success', 'Area Added Successfully');
          this.reset();
          this.closeaddNewPlantItemModel();
          const key = {
            groupId: this.groupId,
            plantId: data.plantId
          };
          this.getAuditMaster(key);
        }
      }, (error) => {
        this.toaster.error('Error', error.error.message);
      });
    } else {
      const data = {
        auditMasterCategoryId: this.auditCategoryForm.get('masterAuditCategoryId')?.value,
        catType: this.auditCategoryForm.get('categoryTypeId')?.value,
        plantId: this.auditCategoryForm.get('plantId')?.value,
        auditCatId: this.auditCategoryForm.get('auditCatId')?.value,
      };
      this.auditCategoryService.updateAuditCategory(data).subscribe((response: any) => {
        if(response.message == "Categories is already taken"){
          this.toaster.warning('warning',response.message);
        }
        else{
          this.toaster.success('Success', 'Area Updated Successfully');
          this.closeaddNewPlantItemModel();
          this.isUpdateAuditCategory = false;
          this.reset();
          const key = {
            groupId: this.groupId,
            plantId: data.plantId
          };
          this.getAuditMaster(key);
        }
      }, (error) => {
        this.toaster.error('Error', error.error.message);
      });
    }
  }

  onClickUpdateAuditCategory(element: any): void {
    this.getPlantsByGroup();
    this.isUpdateAuditCategory = true;
    this.formMode = false;
    this.displayUserModal = true;
    this.getMasterCategories();
    setTimeout(() => {
      const listArray = this.masterCategoriesDDL;
      const tableArray = this.auditCategoryData.data;
      const missingArray = listArray.filter((elem) => !tableArray.find(({ categoryName }) => {
        return (elem.name === categoryName && element.categoryName != categoryName );
      }));
      this.filterArray = missingArray;
    }, 1500);
    this.auditCategoryForm.get('masterAuditCategoryId')?.setValue(element.auditMasterCategoryId),
    this.auditCategoryForm.get('categoryTypeId')?.setValue(element.catType),
    this.auditCategoryForm.get('plantId')?.setValue(element.plantId);
    this.auditCategoryForm.get('auditCatId')?.setValue(element.auditCatId);
  }

  onClickDeleteAuditCategory(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Master Category!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const id = {
          auditCatId: element.auditCatId
        };
        this.auditCategoryService.deleteAuditCategory(id).subscribe(
          (data:any) => {
            const key = {
              plantId: element.plantId,
              groupId: this.groupId
            };
            this.getAuditMaster(key);
            this.toaster.success(data.message);
          },
          (error:any) => {
            if(error.error.message == "5s Audit Category can't delete due to used in 5s Audit Execution"){
              this.toaster.warning('warning',this.categoryLength+"s Audit Category can't delete due to used in 5s Audit CheckPoints")
            }
            else{
              this.toaster.error('Error', error.error.message);
            }
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Master Category is safe :)', 'error');
      }
    });
  }

  reset(): void {
    this.auditCategoryForm.reset();
  }
}
