import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { Chart } from 'angular-highcharts';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-area-summary',
  templateUrl: './area-summary.component.html',
  styleUrls: ['./area-summary.component.scss']
})
export class AreaSummaryComponent implements OnInit {

  filteredShiftList: any = [];
  shiftList: any = [];
  filteredLines: any = [];
  lineList: any = [];
  filteredAreaList: any = [];
  areaList: any = [];
  machineWiseoeeData: any = [];
  machineOEEChart: Chart[] = [];
  liveOee!: Chart;
  liveOutput!: Chart;
  livePallets!: Chart;
  ytd!: Chart;
  downtime!: Chart;
  downtimeOccurences!: Chart;
  breadcrumList: any = [];
  analysisHeaderForm = this.formBuilder.group({
    shift: ['', Validators.required],
    line: ['', Validators.required],
    area: [new Date(), Validators.required]
  });
  areaSummaryCol = [{ field: "Start_Time" }, { field: "End_Time" }, { field: "Machine" }, { field: "Duration" }];
  areaSummary = [
    { Start_Time: "09/07/2021 7:00:00 AM", End_Time: "09/07/2021 7:00:00 PM", Machine: 'Techne 8', Duration: 72 },
    { Start_Time: "09/07/2021 7:00:00 PM", End_Time: "09/08/2021 7:00:00 AM", Machine: 'Techne 8', Duration: 72 },
    { Start_Time: "09/07/2021 7:00:00 AM", End_Time: "09/07/2021 7:00:00 PM", Machine: 'Techne 15', Duration: 72 },
    { Start_Time: "09/07/2021 7:00:00 PM", End_Time: "09/08/2021 7:00:00 AM", Machine: 'Techne 15', Duration: 72 }
  ];
  areaSummaryCol1 = [{ field: "Job_Bag" }, { field: "Item_Desc" }, { field: "Pcs" }, { field: "Pallets" }];
  areaSummary1 = [
    { Job_Bag: 'P31000286', Item_Desc: 'ALM 340 Strw L KR4 D20#1370339 ', Pcs: '82,764.00', Pallets: '' }
  ];
  constructor(private formBuilder: FormBuilder,private toaster: ToastrService, private usermanagementService: UserManagementService,
    public router: Router) { }

  ngOnInit(): void {
    this.drawChart();
    this.drawChartLiveOutput();
    this.drawChartLivePallets();
  }

  drawChart() {

    const deptColumns = ['CBO', 'CBO', 'IM_03', 'IM_04', 'IM_07', 'IM_08', 'IM_09', 'IM_10', 'IM_11', 'IM_12', 'IM_13', 'Kron...', 'Kron...', 'Kron...', 'Kron...', 'Kron...', 'PETO...', 'PETO...', 'PETO...', 'PETO...', 'Tech...', 'Tech...', 'Tech...', 'Tech...']
    this.liveOee = new Chart({
      series: [
        {
          name: 'OEE',
          type: 'column',
          data: [100, 100, 83, '', 43, 45, '', '', 83.100, '', 100, '', '', 100, 63, '', 46, 57, 90, 100, 100, '', 100, 100],
          color: "#258cf6"
        },
        {
          name: 'Availability',
          type: 'column',
          data: ['', '', 100, '', 100, 90, '', '', 100, 100, '', '', '', '', '', 83, '', 100, 100, 100, '', '', '', ''],
          "color": "#0f0fd1"
        },
        {
          name: 'Performance',
          type: 'column',
          data: ['', '', 83, '', 46, 46, '', '', 83, 100, '', '', '', '', '', 100, '', 46, 57, 90, '', '', '', ''],
          "color": "#f8636f"
        },
        {
          name: 'Quality',
          type: 'column',
          data: ['', '', 100, '', 100, 100, '', '', 100, 100, '', '', '', '', '', 100, '', 100, 100, 100, '', '', '', ''],
          "color": "#8c0697 "
        }
      ],
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: deptColumns,
        crosshair: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getYTDHeaderBasedValues.bind(this)
            }
          }
        }
      }
    });
  }

  drawChartLiveOutput() {

    const deptColumns = ['CBO', 'CBO', 'IM_03', 'IM_04', 'IM_07', 'IM_08', 'IM_09', 'IM_10', 'IM_11', 'IM_12', 'IM_13', 'Kron...', 'Kron...', 'Kron...', 'Kron...', 'Kron...', 'PETO...', 'PETO...', 'PETO...', 'PETO...', 'Tech...', 'Tech...']
    this.liveOutput = new Chart({
      series: [
        {
          name: 'Machine Output',
          type: 'column',
          data: ['', '', 12000.82, '', 6000.05, 6000.05, '', '', 10000, 5000.35, '', '', '', '', '', '', 4000.76, 7000, 57, 90, 29000.76, '', ''],
          color: "#0f0af2"
        },
        {
          name: 'Actual Output',
          type: 'column',
          data: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 82000.76, '', '', '', '', '', '', ''],
          "color": "#f9ed10"
        },
      ],
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: deptColumns,
        crosshair: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getYTDHeaderBasedValues.bind(this)
            }
          }
        }
      }
    });
  }

  drawChartLivePallets() {

    const deptColumns = ['CBO', 'CBO', 'IM_03', 'IM_04', 'IM_07', 'IM_08', 'IM_09', 'IM_10', 'IM_11', 'IM_12', 'IM_13', 'Kron...', 'Kron...', 'Kron...', 'Kron...', 'Kron...', 'PETO...', 'PETO...', 'PETO...', 'PETO...', 'Tech...', 'Tech...', 'Tech...', 'Tech...', 'Tech...']
    this.livePallets = new Chart({
      series: [
        {
          name: 'Total Machine',
          type: 'column',
          data: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', 22, '', '', 2, 2, 2, '', '', '', '', ''],
          color: "#0f0af2"
        },
        {
          name: 'Total Good Pallets/Boxes',
          type: 'column',
          data: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', 22, '', '', '', '', '', '', '', '', '', ''],
          "color": "#19ef0a"
        },
      ],
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: deptColumns,
        crosshair: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
          point: {
            events: {
              click: this.getYTDHeaderBasedValues.bind(this)
            }
          }
        }
      }
    });
  }

  getYTDHeaderBasedValues() { }


  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

}
