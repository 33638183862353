import { Component, OnDestroy } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'btn-cell-renderer',
    template: `<style>
    
    button{
        padding: 7px 10px;
        border-radius: 12px;
        border: none;
        margin: 0 3px;
    }
    i{
        font-size: 20px;
        color: white;
    }
    .btn-print{
       background-color: #5252ff;
    }
    .btn-search{
       background-color: #824aed;
    }
    .btn-delete{
       background-color: #ff5050;
    } 
    </style>

    <button (click)="onClickPrint($event)" mat-icon-button class="btn-print"
    aria-label="View Row Tree" pTooltip="Print" tooltipPosition="top">
    <i class="fa fa-print" aria-hidden="true"></i>
  </button>
  <button (click)="onClickSearch($event)" mat-icon-button class="btn-search"
    aria-label="View Row Tree" pTooltip="Search" tooltipPosition="top">
    <i class="fa fa-search" aria-hidden="true"></i>
  </button>
  <button (click)="onClickDelete($event)" mat-icon-button class="btn-delete"
    aria-label="View Row Tree" pTooltip="Delete" tooltipPosition="top">
    <i class="fa fa-trash" aria-hidden="true"></i>
  </button>`
})
// tslint:disable-next-line:component-class-suffix
export class PalletActionRenderer implements ICellRendererAngularComp, OnDestroy {
    private params: any;
    refresh(params: any): boolean {
        throw new Error('Method not implemented.');
    }

    agInit(params: any): void {
        this.params = params;
    }

    onClickPrint($event: any): any {
        if (this.params.onClickPrint instanceof Function) {
            // put anything into params u want pass into parents component
            // const params = {
            //     event: $event,
            //     rowData: this.params.node.data,
            //     // ...something
            // };
            const params = this.params.node.data
            this.params.onClickPrint(params);
        }
    }

    onClickSearch($event: any): any {
        if (this.params.onClickSearch instanceof Function) {
            // put anything into params u want pass into parents component
            // const params = {
            //     event: $event,
            //     rowData: this.params.node.data,
            //     // ...something
            // };
            const params = this.params.node.data
            this.params.onClickSearch(params);
        }
    }

    onClickDelete($event: any): any {
        if (this.params.onClickDelete instanceof Function) {
            // put anything into params u want pass into parents component
            // const params = {
            //     event: $event,
            //     rowData: this.params.node.data,
            //     // ...something
            // };
            const params = this.params.node.data
            this.params.onClickDelete(params);
        }
    }
    ngOnDestroy(): any {
        // no need to remove the button click handler
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}
