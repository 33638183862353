<section class="home-content">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <mat-card>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-4 p-lg-6 p-xl-6">
        <mat-card-title>{{asyncTabs.length}}S Audit Execution</mat-card-title>
      </div>

      <div class="p-col-12 p-md-8 p-lg-6 p-xl-6" style="display: flex; justify-content: flex-end;">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>

        <form [formGroup]="searchAuditMasterForm" style="float: right">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label class="required">Group</mat-label>
            <mat-select panelClass="testClass" name="group" formControlName="group"
              (selectionChange)="getPlantByGroup()">
              <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
            </mat-select>
          </mat-form-field>

        </form>
        <form [formGroup]="searchAuditMasterForm" style="float: right;  margin-left: 10px;">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label class="required">Plant</mat-label>
            <mat-select panelClass="testClass" name="plant" formControlName="plant"
              (selectionChange)="getAuditAreaByPlant()">
              <!-- <mat-select-filter [placeholder]="'Search'" [displayMember]="'name'" [array]="plantsDDL" (filteredReturn)="filterdplants =$event"></mat-select-filter>             -->
              <mat-option *ngFor="let p of filterdplants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
            </mat-select>
          </mat-form-field>

        </form>
        <form [formGroup]="searchAuditMasterForm" style="float: right;  margin-left: 10px;">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label class="required">Area</mat-label>
            <mat-select panelClass="testClass" name="area" formControlName="area" (selectionChange)="changeArea()">

              <mat-option *ngFor="let a of areaDDL" [value]="a.areaId"> {{a.areaName}} </mat-option>

            </mat-select>
          </mat-form-field>
        </form>
      </div>

    </div>
  </mat-card>
  <div style="display: inline-flex;width: fit-content;">

  </div>

  <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab *ngFor="let cat of asyncTabs" label="{{cat.category}}">


      <mat-card class="">
        <div class="p-grid">
          <div class="p-xl-7 p-lg-7 p-md-12 p-col-12">
            <div class="wrapper-box wrapper-box-shadow table-wrapper   p-p-1">
              <table mat-table class="basic-table plant-model-table" matSort [dataSource]="cat.data">

                <ng-container matColumnDef="srno">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10% !important;">
                    <p class="header-text">SrNo</p>
                  </th>
                  <td mat-cell *matCellDef="let element; let SrNo = index">{{SrNo+1}}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 40% !important;">
                    <p class="header-text">Checkpoints</p>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.checkpoint}}</td>
                </ng-container>


                <ng-container matColumnDef="score">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <p class="header-text">Score</p>
                  </th>
                  <td mat-cell *matCellDef="let element ; let SrNo = index">


                    <mat-radio-group aria-label="Select an option" [(ngModel)]="element.value"
                      (change)="scoreUpdate($event, element , cat.auditCatId , element.checkPointId,element)">
                      <mat-radio-button *ngFor="let p of cat.score" [value]="p">{{p}}</mat-radio-button>
                    </mat-radio-group>
                  </td>

                </ng-container>


                <tr mat-header-row *matHeaderRowDef="auditexeColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: auditexeColumns"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No Data Match</td>
                </tr>
              </table>
            </div>

          </div>
          <!-- *ngIf="loginUserId===createdBy" -->
          <div class="p-xl-5 p-lg-5 p-md-12 p-col-12">
            <div class="d-flex justify-content-between">
              <div class="button-wrapper">
                <button *ngIf="isSameUser" mat-mini-fab type="submit" color="primary" id="savebtn"
                  (click)="saveAuditExecution()" pTooltip="Save" tooltipPosition="top" [disabled] = "DisableSave">
                  <mat-icon>save</mat-icon>
                </button>
                <button mat-mini-fab color="accent" (click)="openModal($event)" pTooltip="Add Observation"
                  tooltipPosition="top">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-between" style="margin-top: 20px;">
              <div class="score-cards-wrapper">
                <mat-card class="scores">
                  <b>Prev Total % : {{totalPrevPercentage | number : '1.2-2'}}</b>
                </mat-card>
                <mat-card class="scores">
                  <b>Prev Overall % : {{overallPrevPercentage | number : '1.2-2'}}</b>
                </mat-card>
              </div>
            </div>
            <div class="d-flex justify-content-between" style="margin-top: 20px;">
              <div class="score-cards-wrapper">
                <mat-card class="scores">
                  <b>Current Total % : {{cat.gainPercentage| number : '1.2-2'}}</b>
                </mat-card>
                <mat-card class="scores">
                  <b>Current Overall % : {{totalPercentage | number : '1.2-2'}}</b>
                </mat-card>
              </div>
            </div>


            <mat-card class="legendcard">
              <mat-card-header>
                <mat-card-title>Legends</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <!-- <div style="display: inline-block">
                  <p class="card-sub-heading">Catagory Type : {{Categorytype}} </p>
                </div> -->
                <div style="display: flex;" *ngIf="Categorytype2==='Score'">
                  <div class="p-xl-6 p-lg-6 p-md-6 p-col-12">

                    <p class="card-sub-heading">Non-Compliance</p>
                    <ul *ngFor="let s of nonCompList">
                      <li class="my-li">{{ s.score }} - {{ s.name }}</li>
                    </ul>
                  </div>
                  <div class="p-xl-6 p-lg-6 p-md-6 p-col-12">
                    <p class="card-sub-heading">Compliance</p>
                    <ul *ngFor="let s of compList">
                      <li class="my-li">{{ s.score }} - {{ s.name }}</li>
                    </ul>
                  </div>
                </div>
                <div style="display: flex;" *ngIf="Categorytype2==='Option'">
                  <div class="p-xl-6 p-lg-6 p-md-6 p-col-12">
                    <p class="card-sub-heading">Non-Compliance</p>
                    <ul>
                      <li class="my-li">No</li>
                    </ul>
                  </div>
                  <div class="p-xl-6 p-lg-6 p-md-6 p-col-12">
                    <p class="card-sub-heading">Compliance</p>
                    <ul>
                      <li class="my-li">Yes</li>
                    </ul>
                  </div>
                </div>

              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-card>

    </mat-tab>
  </mat-tab-group>


  <ng-template #displayRoleModal1>
    <form>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>Global Standards</h2>

          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>

      <table mat-table [dataSource]="GlobalData" class="basic-table plant-model-table" matSort>


        <!-- Sort Column -->
        <ng-container matColumnDef="plant">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Plant</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.ptDesc}} </td>
        </ng-container>

        <!-- Sort Column -->
        <ng-container matColumnDef="area">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Area</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.masterArea}} </td>
        </ng-container>


        <!-- Sort Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Type</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.typeName}} </td>
        </ng-container>

        <!-- Sort Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Name</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="file">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Action</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <a target="_blank" href="{{element.fullPath}}" *ngIf="element.filename !== null" [download]>
              <svg width="25" height="25" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.867 477.867"
                style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M409.6,153.6h-85.333c-9.426,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067H409.6
                        c9.426,0,17.067,7.641,17.067,17.067v221.867c0,9.426-7.641,17.067-17.067,17.067H68.267c-9.426,0-17.067-7.641-17.067-17.067
                        V204.8c0-9.426,7.641-17.067,17.067-17.067H153.6c9.426,0,17.067-7.641,17.067-17.067S163.026,153.6,153.6,153.6H68.267
                        c-28.277,0-51.2,22.923-51.2,51.2v221.867c0,28.277,22.923,51.2,51.2,51.2H409.6c28.277,0,51.2-22.923,51.2-51.2V204.8
                        C460.8,176.523,437.877,153.6,409.6,153.6z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M335.947,243.934c-6.614-6.387-17.099-6.387-23.712,0L256,300.134V17.067C256,7.641,248.359,0,238.933,0
                        s-17.067,7.641-17.067,17.067v283.068l-56.201-56.201c-6.78-6.548-17.584-6.361-24.132,0.419c-6.388,6.614-6.388,17.1,0,23.713
                        l85.333,85.333c6.657,6.673,17.463,6.687,24.136,0.03c0.01-0.01,0.02-0.02,0.031-0.03l85.333-85.333
                        C342.915,261.286,342.727,250.482,335.947,243.934z" />
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g>
                </g>
                <g></g>
                <g></g>
              </svg>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="globalColumns"></tr>
        <tr *matRowDef="let row; columns: globalColumns;" mat-row></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No Data Matching</td>
        </tr>
      </table>
      <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
      </mat-paginator>

      <div mat-dialog-actions style="float: right; margin-top: 10px">
        <!-- <button color="accent" mat-flat-button cdkFocusInitial>
          Save & Close
        </button> -->
      </div>
    </form>
  </ng-template>
</section>


<ng-template #displayObservationModal>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  <form [formGroup]="observationDataForm">
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>Add New Observation</h2>

        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center" style="margin-left: 20px;">
      <div class="p-xl-4 p-lg-4 p-md-6 p-col-12">
        <button class="user_badge" mat-flat-button color="accent" disableRipple>
          Area: {{AreaName}}
        </button>
        <!-- <h2>Area: <label for="">{{AreaName}}</label></h2>  -->
      </div>
      <div class="p-xl-8 p-lg-8 p-md-6 p-col-12">
        <div class="content-top-row">
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width:27.5%">
            <mat-label>Select Owner</mat-label>
            <mat-select panelClass="testClass" name="owner" formControlName="owner"
              (selectionChange)="onOwnerChange($event)">
              <mat-select-filter [placeholder]="'Search'" [displayMember]="'userName'" [array]="owners"
                (filteredReturn)="filteredOwners = $event"></mat-select-filter>
              <mat-option *ngFor="let pt of filteredOwners" [value]="pt.userId"> {{pt.userName}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <h2>Owner: <label for="">{{OwnerName}}</label></h2> -->
      </div>


    </div>
    <mat-dialog-content>

      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-top-row">
          <mat-form-field class="top-row-first-filed-item ">
            <mat-label>Observation</mat-label>
            <input matInput formControlName="observe" placeholder="Enter Observation" />
          </mat-form-field>

          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Categories</mat-label>
            <mat-select name="generalCategories" formControlName="generalCategories">
              <mat-option *ngFor="let pt of generalCategoryList" [value]="pt.ID">
                {{pt.categoryName}} </mat-option>
            </mat-select>
            <mat-error>Please Select Category</mat-error>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label>Classification</mat-label>
            <mat-select name="5sclassification" formControlName="fivesclassification">
              <mat-option *ngFor="let pt of auditClassifications" [value]="pt.classficationId"> {{pt.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="width: 27.5%">
            <ngx-mat-file-input #myInput formControlName="image" placeholder="Add Gemba Images" [accept]="
               'image/x-png,image/gif,image/jpeg'
              " (change)="uploadedFile($event)" multiple required>
              <mat-icon ngxMatFileInputIcon>folder</mat-icon>
            </ngx-mat-file-input>
          </mat-form-field>
        
        </div>
        <div *ngIf="FileData && FileData.length" class="imgflex">
          <div *ngFor="let file of FileData; let i = index" class="relative">
              <div>
                  <img *ngIf="file.FileType == 'image'" [src]="file.ImageSrc" alt="" width="100px" height="80px">
              </div>

              <img src="assets/images/remove.png" (click)="deleteFile(i)" class="absolute" alt="" width="20px"
                  height="20px">
          </div>
      </div>
        <div style="display: flex; justify-content : end">
          <button type="submit" [disabled]="!observationDataForm.valid" mat-mini-fab mat-button color="primary"
          pTooltip="Save" tooltipPosition="top" (click)="onSubmit()" style="margin-top:20px;margin-left: 40px;">
          <mat-icon style="color: #fff">save</mat-icon>
        </button>
        </div>
     
      </div>

      <!-- <div class="p-field p-col-3" style="margin-left: 10px;">
        <img [src]="imageSrc">
      </div> -->
    </mat-dialog-content>
  </form>
</ng-template>