import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-opms-quality',
  templateUrl: './opms-quality.component.html',
  styleUrls: ['./opms-quality.component.scss'],
})
export class OpmsQualityComponent implements OnInit {
  pomArray!: any[];
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.pomArray = [
      //disabled with kpi service is not implemented
      {
        title: 'Chemical Lab',
        routerLink: this.getChemicalLab,
        icon: 'assets/images/order-processing.svg',
      },
      // { title: 'Physical Lab', routerLink: this.getPhysicalLab, icon: 'assets/images/shop-floor-data.svg' },
      {
        title: 'OPMS Glass',
        routerLink: this.getOpmsGlass,
        icon: 'assets/images/shop-floor-data.svg',
      },
      {
        title: 'QA Data Entry',
        routerLink: this.getQaDataEntry,
        icon: 'assets/images/shop-floor-data.svg',
      },
      {
        title: 'OPMS Dashboard',
        routerLink: this.getOpmsDashboard,
        icon: 'assets/images/shop-floor-data.svg',
      },
    ];
  }
  getChemicalLab = (): void => {
    this.router.navigate(['/opmsQuality/chemicalLab']);
  };
  getOpmsDashboard = (): void => {
    this.router.navigate(['/opmsQuality/OPMSDB']);
  };
  getOpmsGlass = (): void => {
    this.router.navigate(['/opmsQuality/OpmsGlass']);
  };
  getQaDataEntry = (): void => {
    this.router.navigate(['/opmsQuality/qaDataEntry']);
  };
}
