import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { SafetyDialogComponent } from '../safety-dialog/safety-dialog.component';
import { SafetyService } from 'src/app/services/safety.service';
import { BtnDownload } from 'src/app/btn-download.component';

@Component({
  selector: 'app-incident-reporting',
  templateUrl: './incident-reporting.component.html',
  styleUrls: ['./incident-reporting.component.scss']
})
export class IncidentReportingComponent implements OnInit {
  isEdit: boolean = false;
  user: any;
  breadcrumList: any;
  spinnerText: string = "";
  paginationPageSize!: any;

  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 5,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      BtnRenderer: BtnDownload,
    },
  };
  gridApi!: any;
  gridColumnApi!: any;

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  IncidentReportColumns = [
    { field: 'Pudesc', headerName: 'Unit' },
    { field: 'Date', headerName: 'Date' },
    { field: 'IncidentTypeName', headerName: 'Incident Type' },
    { field: 'IncidentOn', headerName: 'Incident On' },
    { field: 'Classification', headerName: 'Incident Classification' },
    { field: 'InjuredPerson', headerName: 'Injured Person' },
    { field: 'DetailDescription', headerName: 'Detail Description' },
    { field: 'CreatedOn', headerName: 'Created On' },
    { field: 'CreatedBy', headerName: 'Created By' },
    {
      field: 'FileDoc',
      headerName: 'File',
      cellRenderer: this.swotpestColumnRender.bind(this),
      autoHeight: true
    }
  ];

  IncidentReportList: any = [];
  IncidentReportFileUrl: any;

  constructor(private toaster: ToastrService, private spinner: NgxSpinnerService, private router: Router, private userManagementService: UserManagementService, public dialog: MatDialog, public safetyService: SafetyService) { }

  ngOnInit(): void {
    this.IncidentReportFileUrl = environment.O3CoreApiImageUrl + 'Content/Incident/'
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.GetBreadcrumList();
  }

  swotpestColumnRender(params: any) {

    let html = '';
    if (params && params.data && params.data.FileDoc) {
      if(params.data.FileDoc.match('png.*') || params.data.FileDoc.match('jpg.*') || params.data.FileDoc.match('jpeg.*')){
        html = `<a target="_blank" href="${this.IncidentReportFileUrl+params.data.FileDoc}" class="down_icon" download>
        <img src="${this.IncidentReportFileUrl+params.data.FileDoc}" width="60" height="45" alt="pdf">
        </a>`
      }
      else{
        html = `<a target="_blank" href="${this.IncidentReportFileUrl+params.data.FileDoc}" class="down_icon" download>
        <img src="../../../assets/images/file-down.png" width="50" height="40" alt="pdf">
        </a>`
      }
         
    }
    return html;
    
  }

  

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.spinnerText = 'Loading Menu Data';
    this.spinner.show();
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.spinner.hide();
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  applyFilter(event: Event): void {
  }

  getIncidentReport(event: any) {
    this.spinner.show();
    this.spinnerText = "Fetching Incident Report";
    this.safetyService.getIncidentReport(event).subscribe((res: any) => {
      this.spinner.hide();
      this.IncidentReportList = res.IncidentReportList;
      this.getPaginator();
    },
      (error: any) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getPaginator(): void {
    setTimeout(() => {
    }, 100);
  }

  openDialog(event: any, name: any): void {
    let dialogRef = this.dialog.open(SafetyDialogComponent, {
      disableClose: true,
      width: '50%',
      height: '65vh',
      data: {
        headerData: event,
        formName: name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result == undefined)
        console.log('Undefined Result');
      else
        this.getIncidentReport(result);
    });
  }
}
