import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Histogram from 'highcharts/modules/histogram-bellcurve';
import * as moment from 'moment';
import { MasterDepartmentService } from 'src/app/services/master-department.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { QualityMachineTrendDashboardService } from 'src/app/services/quality-machine-trend-dashboard.service';

HighchartsMore(Highcharts);
Histogram(Highcharts);
@Component({
  selector: 'app-machine-parameter-trend',
  templateUrl: './machine-parameter-trend.component.html',
  styleUrls: ['./machine-parameter-trend.component.scss'],
})
export class MachineParameterTrendComponent implements OnInit {
  breadcrumList: any[] = [];
  columnDefsTask!: any[];
  tableData: any = [];
  parameterArray: any[] = [];
  productArray: any[] = [];
  filteredParameterArray: any;
  filteredProductArray: any;
  histogramChart!: any;
  controlSummary: any[] = [];
  groupList: any[] = [];
  plantList: any[] = [];
  constructor(
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private trendDashboardService: QualityMachineTrendDashboardService,
    private roleService: MasterDepartmentService,
    private machineService: MachineMappingService
  ) {
    this.columnDefsTask = [
      {
        headerName: 'Description',
        field: 'description',
        minWidth: 100,
        filter: true,
      },
      {
        headerName: 'Value',
        field: 'value',
        minWidth: 100,
        filter: true,
      },
    ];
  }

  machineParameterTrendDashboardForm = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
    parameter: new FormControl(),
    product: new FormControl(),
    group: new FormControl(),
    plant: new FormControl(),
  });

  ngOnInit(): void {
    this.GetBreadcrumList();
    const date = new Date();
    let firstDayfincialGap = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7
    );
    let lastDayfincialGap = new Date();

    firstDayfincialGap = new Date(
      new Date(firstDayfincialGap).setHours(0, 0, 0, 0)
    );
    lastDayfincialGap = new Date(
      new Date(lastDayfincialGap).setHours(23, 59, 59, 59)
    );
    this.machineParameterTrendDashboardForm
      .get('startDate')
      ?.setValue(firstDayfincialGap);
    this.machineParameterTrendDashboardForm
      .get('endDate')
      ?.setValue(lastDayfincialGap);
    this.getGroupsData();
  }

  getGroupsData(): void {
    this.spinner.show();
    const key = {
      Id: 0,
    };
    this.roleService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        this.machineParameterTrendDashboardForm
          .get('group')
          ?.setValue(this.groupList[0].TypeId);
        this.getPlantData();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getPlantData(): any {
    this.spinner.show();
    const key = {
      GroupId: this.machineParameterTrendDashboardForm.value.group,
    };
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plantList = data.DepartList;
        this.machineParameterTrendDashboardForm
          .get('plant')
          ?.setValue(data.DepartList[0].DeptId);
        this.getParameterDropdown();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getParameterDropdown() {
    this.spinner.show();
    const key = {
      plantId: this.machineParameterTrendDashboardForm.value.plant,
    };
    this.trendDashboardService
      .getMachineParametersDropdownByPlantId(key)
      .subscribe({
        next: (res: any) => {
          this.parameterArray = res;
          this.machineParameterTrendDashboardForm
            .get('parameter')
            ?.setValue(res[0]?.id);
          this.getProductsDropdown();
          this.filteredParameterArray = res;
        },
        error: ({ error }) => {
          this.spinner.hide();
          this.toaster.error(error?.error);
        },
      });
  }

  getProductsDropdown() {
    this.spinner.show();
    const key = {
      parameterId: this.machineParameterTrendDashboardForm.value.parameter,
    };
    this.trendDashboardService
      .getProductsDropdownByMachineParameterId(key)
      .subscribe({
        next: (res: any) => {
          this.productArray = res;
          this.machineParameterTrendDashboardForm
            .get('product')
            ?.setValue(res.length ? res[0]?.id : 0);
          this.spinner.hide();
          // this.getAllChartData();
          this.filteredProductArray = res;
        },
        error: ({ error }) => {
          this.spinner.hide();
          this.toaster.error(error?.error);
        },
      });
  }

  getAllChartData() {
    if (
      this.machineParameterTrendDashboardForm.get('startDate').value != null &&
      this.machineParameterTrendDashboardForm.get('endDate').value != null
    ) {
      let fromDate =
        this.machineParameterTrendDashboardForm.get('startDate').value;
      let toDate = new Date(
        new Date(
          this.machineParameterTrendDashboardForm.get('endDate').value
        ).setHours(23, 59, 59, 59)
      );
      if (fromDate && toDate) {
        this.spinner.show();
        const key = {
          fromDate: moment(fromDate).format('YYYY-MM-DD HH:mm:ss'),
          toDate: moment(toDate).format('YYYY-MM-DD HH:mm:ss'),
          parameterId: this.machineParameterTrendDashboardForm.value.parameter
            ? this.machineParameterTrendDashboardForm.value.parameter
            : 0,
          varId: this.machineParameterTrendDashboardForm.value.product
            ? this.machineParameterTrendDashboardForm.value.product
            : 0,
        };
        this.trendDashboardService.getMachineChartData(key).subscribe({
          next: (res) => {
            this.spinner.hide();
            if (res) {
              this.histogramChart = res?.histogramChart;
              this.tableData = res?.tableData;
              this.controlSummary = res?.controlSummary;
              this.historainChart();
              this.areaRangeChart();
              this.controlChart();
            }
          },
          error: ({ error }) => {
            this.spinner.hide();
            this.toaster.error(error?.error);
          },
        });
      }
    }
  }

  private historainChart(): void {
    const data = this.histogramChart.historianChartData.split(',').map(s => parseFloat(s));

    const minmum =
      this.histogramChart.lowerRejectLimit != ''
        ? Number(this.histogramChart.lowerRejectLimit)
        : null;
    const maximum =
      this.histogramChart.upperRejectLimit != ''
        ? Number(this.histogramChart.upperRejectLimit)
        : null;
    const minWarn =
      this.histogramChart.lowerWarningLimit != ''
        ? Number(this.histogramChart.lowerWarningLimit)
        : null;
    const maxWarn =
      this.histogramChart.upperWarningLimit != ''
        ? Number(this.histogramChart.upperWarningLimit)
        : null;
    const minSpec =
      this.histogramChart.lowerSpecificationLimit != ''
        ? Number(this.histogramChart.lowerSpecificationLimit)
        : null;
    const maxSpec =
      this.histogramChart.upperSpecificationLimit != ''
        ? Number(this.histogramChart.upperSpecificationLimit)
        : null;
    let varMin = minmum > minWarn ? minWarn : minmum;
    let varMax = maximum > maxWarn ? maximum : maxWarn;
    varMin = varMin > minSpec ? minSpec : varMin;
    varMax = varMax > maxSpec ? varMax : maxSpec;
    if(varMax < Number(this.histogramChart.maxValue))
      varMax = Number(this.histogramChart.maxValue);
    if(varMin > Number(this.histogramChart.minValue))
      varMin = Number(this.histogramChart.minValue);
    Highcharts.chart('historianChart', {
      title: {
        text: 'Histogram',
      },
      xAxis: [
        {
          title: { text: 'Data' },
          alignTicks: false,
        },
        {
          title: { text: 'Histogram' },
          alignTicks: false,
          opposite: true,
        },
      ],

      yAxis: [
        {
          title: { text: 'Data' },
          max: varMax,
          min: varMin,
          plotLines: [
            {
              color: '#FF5252',
              width: 2,
              value: minmum,
              label: {
                text: 'LRL. ' + minmum,
                style: {
                  color: '#FF5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: 10,
                zIndex: 1,
              },
            },
            {
              color: '#FF5252',
              width: 2,
              value: maximum,
              label: {
                text: 'URL. ' + maximum,
                style: {
                  color: '#FF5252',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: -5,
                zIndex: 1,
              },
            },
            {
              color: '#0000FF',
              width: 2,
              value: minWarn,
              label: {
                text: 'LWL. ' + minWarn,
                style: {
                  color: '#0000FF',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: 10,
                zIndex: 1,
              },
            },
            {
              color: '#0000FF',
              width: 2,
              value: maxWarn,
              label: {
                text: 'UWL. ' + maxWarn,
                style: {
                  color: '#0000FF',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: -5,
                zIndex: 1,
              },
            },
            {
              color: '#3BB143',
              width: 2,
              value: minSpec,
              label: {
                text: 'LSL. ' + minSpec,
                style: {
                  color: '#3BB143',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: 10,
                zIndex: 1,
              },
            },
            {
              color: '#3BB143',
              width: 2,
              value: maxSpec,
              label: {
                text: 'USL. ' + maxSpec,
                style: {
                  color: '#3BB143',
                  fontWeight: 'bold',
                },
                textAlign: 'left',
                y: -5,
                zIndex: 1,
              },
            },
          ],
        },
        {
          title: { text: 'Histogram' },
          opposite: true,
        },
      ],
      plotOptions: {
        histogram: {
          accessibility: {
            point: {
              valueDescriptionFormat:
                '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.',
            },
          },
        },
      },
      series: [
        {
          name: 'Histogram',
          type: 'histogram',
          xAxis: 1,
          yAxis: 1,
          baseSeries: 's1',
          zIndex: -1,
        },
        {
          name: 'Data',
          type: 'scatter',
          data: data,
          id: 's1',
          marker: {
            radius: 1.5,
          },
        },
      ],
    } as any);
  }

  private areaRangeChart(): void {
    const labels: any[] = this.histogramChart.historianChartDate.split(',');
    const dataArray = this.histogramChart.historianChartData.split(',').map(s => parseFloat(s));
    const minmum =
      this.histogramChart.lowerSpecificationLimit != ''
        ? Number(this.histogramChart.lowerSpecificationLimit)
        : null;
    const maximum =
      this.histogramChart.upperSpecificationLimit != ''
        ? Number(this.histogramChart.upperSpecificationLimit)
        : null;
    let varMin = minmum;
    let varMax = maximum;
    if(varMax < Number(this.histogramChart.maxValue))
      varMax = Number(this.histogramChart.maxValue);
    if(varMin > Number(this.histogramChart.minValue))
      varMin = Number(this.histogramChart.minValue);
    const areaChart = Highcharts.chart('areaRangeChart', {
      title: {
        text: 'Area Range Chart',
      },

      // subtitle: {
      //   text:
      //     'Source: ' +
      //     '<a href="https://www.yr.no/nb/historikk/graf/1-113585/Norge/Viken/Nesbyen/Nesbyen?q=2022-07"' +
      //     'target="_blank">YR</a>',
      // },
      xAxis: {
        startOnTick: false,
        title: {
          text: 'Date',
        },
        labels: {
          skew3d: true,
          rotation: -10,
          formatter: function () {
            return labels[this.pos];
          },
          style: {
            fontSize: '12px',
            margin: '10px',
            padding: '10px',
          },
        },
        endOnTick: false,
      },

      yAxis: {
        title: {
          text: null,
        },
        max: varMax,
        min: varMin,
        plotLines: [
          {
            color: '#3BB143',
            width: 2,
            value: maximum,
            label: {
              text: 'USL. ' + maximum,
              style: {
                color: '#3BB143',
                fontWeight: 'bold',
              },
              textAlign: 'left',
              y: -5,
            },
          },
          {
            color: '#3BB143',
            width: 2,
            value: minmum,
            label: {
              text: 'LSL. ' + minmum,
              style: {
                color: '#3BB143',
                fontWeight: 'bold',
              },
              textAlign: 'left',
              y: 10,
            },
          },
        ],
      },
      tooltip: {
        headerFormat: '',
        pointFormat: `<div>{series.name}: {point.y}</div>`,
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        line: {
          label: {
            enabled: false,
          },
          pointStart: 0,
          pointEnd: 30,
        },
        allowPointSelect: false,
      },
      series: [
        {
          name: 'Value',
          data: dataArray,
        },
      ],
    } as any);
  }

  private controlChart(): void {
    const category = [];
    const data = [];
    this.controlSummary.forEach((x) => {
      category.push(x.description);
      data.push(x.value);
    });
    const controlChart = Highcharts.chart('conrolChart', {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Control Summary',
      },
      subtitle: {
        text:
          'Source: ' +
          '<a href="https://www.ssb.no/en/statbank/table/08940/" ' +
          'target="_blank">SSB</a>',
      },
      xAxis: {
        categories: category,
        crosshair: false,
      },
      yAxis: {
        title: {
          useHTML: true,
          text: 'Percentage',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<td style="padding:0"><b>{point.y:.1f}%</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: '',
          data: data,
        },
      ],
    } as any);
  }

  onGridReady(params: any): any {
    params.api.sizeColumnsToFit();
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
}
