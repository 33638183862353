<!-- Preventive Master Upload Dialog Start -->
<div *ngIf="data.moduleName == 'PM'">
  <h1 mat-dialog-title>Upload Excel</h1>
  <div mat-dialog-content>
    <div class="d-flex">
      <div class="flex-1">
        <button mat-flat-button color="accent" type="button" (click)="openFile()">Upload Excel</button><span *ngIf="file" style="font-size: 12px;">{{file.FileName}}</span>
        <input type="file" (change)="readExcel($event,'PM')" class="d-none" #fileInput>
      </div>
      <div>
        <button mat-flat-button color="accent" type="button" class="w-22"><a  class="sample" href="../../../../assets/xlsx/PM_Configuration.xlsx" download>Sample Excel <i class="fa fa-download" aria-hidden="true"></i></a></button>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mat-dialog-actions">
    <button mat-flat-button class="dialog-cancel-btn" (click)="onCancelClick()">Cancel</button>
    <!-- <button mat-button class="dialog-save-btn" (click)="onSaveClick()" cdkFocusInitial>Save</button> -->
  </div>
</div>
<!-- Preventive Master Upload Dialog End -->



<!-- Reactive Master Upload Dialog Start -->
<div *ngIf="data.moduleName == 'RM'">
  <h1 mat-dialog-title>Upload Excel</h1>
  <div mat-dialog-content>
    <div class="d-flex">
      <div class="flex-1">
        <button mat-flat-button color="accent" type="button" (click)="openFile()">Upload Excel</button><span *ngIf="file" style="font-size: 12px;">{{file.FileName}}</span>
        <input type="file" (change)="readExcel($event,'RM')" class="d-none" #fileInput>
      </div>
      <div>
        <button mat-flat-button color="accent" type="button" class="w-22"><a class="sample" href="../../../../assets/xlsx/RM_Configuration.xlsx" download>Sample Excel <i class="fa fa-download" aria-hidden="true"></i></a></button>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mat-dialog-actions">
    <button mat-flat-button class="dialog-cancel-btn" (click)="onCancelClick()">Cancel</button>
    <!-- <button mat-button class="dialog-save-btn" (click)="onSaveClick()" cdkFocusInitial>Save</button> -->
  </div>
</div>

<div *ngIf="data.FormName == 'Failure Mode'">
  <h1 mat-dialog-title>Upload Excel</h1>
  <div mat-dialog-content>
    <div class="d-flex">
      <div class="flex-1">
        <button mat-flat-button color="accent" type="button" (click)="openFile()">Upload Excel</button><span *ngIf="file" style="font-size: 12px;">{{file.FileName}}</span>
        <input type="file" (change)="readExcel($event,'Failure Mode')" class="d-none" #fileInput>
      </div>
      <div>
        <button mat-flat-button color="accent" type="button" class="w-22"><a class="sample" href="../../../../assets/xlsx/FailureMode_Upload_Template.xlsx" download>Sample Excel <i class="fa fa-download" aria-hidden="true"></i></a></button>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mat-dialog-actions">
    <button mat-flat-button class="dialog-cancel-btn" (click)="onCancelClick()">Cancel</button>
    <!-- <button mat-button class="dialog-save-btn" (click)="onSaveClick()" cdkFocusInitial>Save</button> -->
  </div>
</div>

<div *ngIf="data.FormName == 'Priority'">
  <h1 mat-dialog-title>Upload Excel</h1>
  <div mat-dialog-content>
    <div class="d-flex">
      <div class="flex-1">
        <button mat-flat-button color="accent" type="button" (click)="openFile()">Upload Excel</button><span *ngIf="file" style="font-size: 12px;">{{file.FileName}}</span>
        <input type="file" (change)="readExcel($event,'Priority')" class="d-none" #fileInput>
      </div>
      <div>
        <button mat-flat-button color="accent" type="button" class="w-22"><a class="sample" href="../../../../assets/xlsx/Priority_Upload_Template.xlsx" download>Sample Excel <i class="fa fa-download" aria-hidden="true"></i></a></button>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mat-dialog-actions">
    <button mat-flat-button class="dialog-cancel-btn" (click)="onCancelClick()">Cancel</button>
    <!-- <button mat-button class="dialog-save-btn" (click)="onSaveClick()" cdkFocusInitial>Save</button> -->
  </div>
</div>

<div *ngIf="data.FormName == 'Delay Time'">
  <h1 mat-dialog-title>Upload Excel</h1>
  <div mat-dialog-content>
    <div class="d-flex">
      <div class="flex-1">
        <button mat-flat-button color="accent" type="button" (click)="openFile()">Upload Excel</button><span *ngIf="file" style="font-size: 12px;">{{file.FileName}}</span>
        <input type="file" (change)="readExcel($event,'Delay Time')" class="d-none" #fileInput>
      </div>
      <div>
        <button mat-flat-button color="accent" type="button" class="w-22"><a class="sample" href="../../../../assets/xlsx/Time_Upload_Template.xlsx" download>Sample Excel <i class="fa fa-download" aria-hidden="true"></i></a></button>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mat-dialog-actions">
    <button mat-flat-button class="dialog-cancel-btn" (click)="onCancelClick()">Cancel</button>
    <!-- <button mat-button class="dialog-save-btn" (click)="onSaveClick()" cdkFocusInitial>Save</button> -->
  </div>
</div>

<!-- Reactive Master Upload Dialog End -->