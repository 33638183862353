<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center" style="padding: 10px 3px; background: #2D31AF;">
    <mat-card-title style="color: #fff;" class="p-ml-2">CheckList Report Configuration
    </mat-card-title>
  </div>
  <mat-card-content>
    <div style="margin-top: 20px;">
      <h2 class="headings">Sub-Header Columns</h2>
      <div class="d-flex" style="margin-top: 15px; margin-bottom: 15px;flex-wrap: wrap;">
        <div *ngFor="let h of Headers; let i = index">
          <mat-checkbox class="checkbox" (change)="onChangeHeaders($event)" [value]="h" [(ngModel)]="h.checked">
            {{h.name}}</mat-checkbox>
        </div>
      </div>

    </div>
    <form [formGroup]="subheadersFormGroup">
      <h2 class="headings">Result Columns</h2>
      <div class="d-flex" style="margin-top: 15px; margin-bottom: 15px;flex-wrap: wrap;">
        <div *ngFor="let sub of subHeaders; let i = index">
          <mat-checkbox class="checkbox" (change)="onChangeSubHeaders($event)" [value]="sub" [(ngModel)]="sub.checked"
            [ngModelOptions]="{standalone: true}">{{sub.name}}
          </mat-checkbox>
        </div>
      </div>
    </form>
    <div style="display: flex; justify-content: flex-end;">
      <button mat-mini-fab mat-button color="primary" *ngIf="IsWrite" (click)="submitHeaders()" pTooltip="Save"
        tooltipPosition="top">
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>