import { Component, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { AnalyticalToolsService } from 'src/app/services/analytical-tools.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ShopFloorDataService } from 'src/app/services/shopFloorData.service';
import { ManagerExecutionService } from 'src/app/services/manager.service';
import { DtServiceService } from 'src/app/services/dtService.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _moment from 'moment';
import { Chart } from 'angular-highcharts';
import * as  Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import { DatePipe } from '@angular/common';

const moment = _moment;
More(Highcharts);
import Drilldown from 'highcharts/modules/drilldown';
import { CommonService } from 'src/app/services/common.service';
Drilldown(Highcharts);
Exporting(Highcharts);

@Component({
  selector: 'app-swotpest-dashboard',
  templateUrl: './swotpest-dashboard.component.html',
  styleUrls: ['./swotpest-dashboard.component.scss']
})
export class SwotpestDashboardComponent implements OnInit {

  spinnerText: string = '';
  stageName: string = 'SWOT & PEST Dashboard';
  stage_name = "";
  deptName: string = "";
  graphTableShow: boolean = false;
  graphGraphShow: boolean = false;
  breadcrumList!: any;
  groupList: any = [];
  groupTempList: any = [];
  deptTempList: any;
  Group: any;
  Plant: any;
  plantList: any = [];
  plantTempList: any = [];
  dataNotFoundForDept: boolean = false;
  userList: any;
  deptList: any = [];
  chart: any;
  chartByDept: any;
  headerIds: any = [];
  userIds: any = [];
  swotpestAllData: any = [];
  swotColumnDefs: any;
  swotpestByDeptDataArray: any;
  lastwlastmdate: any = {};
  paginationPageSize!: any;

  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body')
  };
  gridApi!: any;
  gridColumnApi!: any;

  searchDefectForm = new FormGroup({
    group: new FormControl(null, [Validators.required]),
    plant: new FormControl(null, [Validators.required]),
    department: new FormControl(null),
    fromDate: new FormControl(null),
    toDate: new FormControl(null,),

  });

  swotByDeptDataSource = new MatTableDataSource();
  pestByDeptDataSource = new MatTableDataSource();

  swotByDeptColumns = [];
  pestByDeptColumns: string[] = ['Dept', 'P', 'E', 'S', 'T'];
  teams: any = [];

  @ViewChild('allSelected') private allSelected: any;

  constructor(private userManagementService: UserManagementService, private spinner: NgxSpinnerService,
    private toaster: ToastrService, private analyticalToolsService: AnalyticalToolsService,
    private router: Router, private sfdService: ShopFloorDataService, private dtService: DtServiceService,
    public managerExecutionService: ManagerExecutionService,public datePipe: DatePipe,private commonService: CommonService) { }

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.getGroups();
    this.getUserList();
  }
  makeDataLastWeek(): void {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 6);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = this.dateAsYYYYMMDDHHNNSS(stDT);
    const endDt = this.dateAsYYYYMMDDHHNNSS(edDT);

    this.lastwlastmdate = {
      dtStartDate: new Date(startDt),
      dtEndDate: new Date(endDt),

    };


  }

  makeDataLastMonth(): void {
    const stDT = new Date();
    stDT.setDate(stDT.getDate() - 29);
    stDT.setHours(6);
    stDT.setMinutes(0);
    stDT.setSeconds(0);

    const edDT = new Date();
    edDT.setDate(edDT.getDate());
    edDT.setHours(6);
    edDT.setMinutes(0);
    edDT.setSeconds(0);
    const startDt = this.dateAsYYYYMMDDHHNNSS(stDT);
    const endDt = this.dateAsYYYYMMDDHHNNSS(edDT);


    this.lastwlastmdate = {
      dtStartDate: new Date(startDt),
      dtEndDate: new Date(endDt),
    };
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.spinnerText = 'Loading Menu Data';
    this.spinner.show();
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.spinner.hide();
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }
  lastWeek(): void {
    this.makeDataLastWeek();
    this.onSubmit();
  }

  lastMonth(): void {
    this.makeDataLastMonth();
    this.onSubmit();
  }

  getGroups(): void {
    const key = {
      Id: 0,
    };
    this.spinner.show();
    this.spinnerText = 'Fetching Groups Data';
    this.sfdService.getGroupData(key).subscribe((res: any) => {
      this.spinner.hide();
      this.groupList = res.masterList;
      this.groupTempList = res.masterList;
      this.searchDefectForm.get('group')?.setValue(this.groupList[0].TypeId)
      this.getDeptData(this.searchDefectForm.value.group);
    },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getDeptData(value: any): void {

    const key = {
      GroupId: value
    };
    this.Group = value;
    this.spinner.show();
    this.spinnerText = 'Fetching Department Data';
    this.dtService.getPlantData(key).subscribe((res: any) => {
      this.spinner.hide();
      this.plantList = res.DepartList;
      this.plantTempList = res.DepartList;
        this.searchDefectForm.get('plant')?.setValue(this.plantList[0].DeptId)
      if (!res.DepartList.length)
        this.dataNotFoundForDept = true;
      else
        this.dataNotFoundForDept = false;
      if (this.plantList.length == 1)
        this.Plant = parseInt(this.plantList[0].DeptId);
      // this.getAllDepartment();
    },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }
  getUserList() {
    this.spinner.show();
    this.spinnerText = "Fetching User Data";
    this.managerExecutionService.getUserList().subscribe((res: any) => {
      console.log(res);
      this.userList = res.UserList;
      this.spinner.hide();
    }, (err: any) => {
      console.log(err);
      this.spinner.hide();
    });
  }
  getTeamList(){
    this.spinnerText = "Fetching Team Data";
    this.spinner.show();
    const data = {
      EGId:this.searchDefectForm.value.group,
      PlantId:this.searchDefectForm.value.plant
    }
    this.commonService.getPeopleMasterData(data).subscribe((res: any) => {
      this.teams = res.employeeInfoList;
      this.spinner.hide();
    }, (err: any) => {
      console.log(err);
      this.spinner.hide();
    });
  }
  getAllDepartment() {
    // const key = {
    //   egId: this.Group,
    //   plantId: this.Plant
    // };
    const key = {
      ptId:this.searchDefectForm.value.plant
    };
    this.spinner.show();
    this.spinnerText = "Fetching All Department Data";
    this.dtService.getDeptData(key).subscribe((res: any) => {
      this.deptList = res;
      this.deptTempList = res;

      this.spinner.hide();
    });
  }
  tosslePerOne(all:any){ 
    if (this.allSelected.selected) {  
     this.allSelected.deselect();
     return;
 }
   if(this.searchDefectForm.controls.department.value.length==this.deptList.length)
     this.allSelected.select();
 
 }
   toggleAllSelection() {
     if (this.allSelected.selected) {
       this.searchDefectForm.controls.department
         .patchValue([...this.deptList.map((item:any) => item.TypeId), 0]);
      // this.allSelected.select();
     } else {
       this.searchDefectForm.controls.department.patchValue([]);
     }
   }
  //get dashboard api
  onSubmit() {
    this.graphGraphShow = true;
    this.graphTableShow = false;
    if (this.searchDefectForm.valid) {
      this.spinner.show();
      // const key = {
      //   egId: this.searchDefectForm.value.group,
      //   plantId: this.searchDefectForm.value.plant
      // };
      const key = {
        ptId:this.searchDefectForm.value.plant
      };
      console.log(key);
      this.dtService.getDeptData(key).subscribe((res: any) => {
        this.deptList = res;
      });

      this.searchDefectForm.value.departments = this.deptList;

      this.analyticalToolsService.getSwotPestAllDept(this.searchDefectForm.value).subscribe((res: any) => {
        this.spinner.hide();
        if (res.data.length != 0) {
          let deptColumns = []; let swotCounts = []; let pestCounts = [];
          this.headerIds = res.data.headersId;
          this.swotpestAllData = res.data.swotpestCountList;

          for (let objdata of this.swotpestAllData) {
            swotCounts.push(objdata.swotCount);
            pestCounts.push(objdata.pestCount);
          }
          deptColumns = res.data.deptColumns;

          //chart
          this.chart = new Chart({

            chart: {
              type: 'column',
              options3d: {
                enabled: true,
                alpha: 45
              }
            },
            title: {
              text: 'SWOT & PEST Department Wise'
            },
            xAxis: {
              categories: deptColumns,
              crosshair: true
            },
            credits: {
              enabled: false
            },
            plotOptions: {
              series: {
                cursor: 'pointer',
                borderWidth: 0,
                dataLabels: {
                  enabled: true,
                },
                point: {
                  events: {
                    click: this.getSwotPestDataByDept.bind(this)
                  }
                }
              }
            },

            series: [
              {
                name: 'SWOT',
                type: 'column',
                data: swotCounts,
                "color": "#0f0fd1",

              },
              {
                name: 'PEST',
                type: 'column',
                data: pestCounts,
                "color": "#10ce9c",
              },
            ],

          });
        }
      },
        (error) => {
          this.spinner.hide();
          this.toaster.error('Error', error.message);
        }
      );
    }
  }

  swotpestColumnRender(params: any) {

    let html = '';
    if (params) {

      html += `<ul>`;
      if (params.value && params.value.length) {
        for (let val of params.value) {
          html += `<li>${val}</li>`;
        }
      }
      html += `</ul>`;
    }
    return html;

  }

  getSwotPestDataByDept(e: any) {

    this.deptName = e.point.category;
    this.graphTableShow = true;
    this.spinner.show();
    this.spinnerText = "Fetching SWOT & PEST Data";
    let dept = 0;
    const department = this.deptList.find((dept: any) => dept.TypeName == e.point.category);
    if (department != undefined)
      dept = department.TypeId;

    this.swotColumnDefs = [];
    let type = e.point.series.name.toLocaleLowerCase();
    this.stage_name = e.point.series.name;
    console.log(type);
    let requestData = {
      type: type,
      deptId: dept,
      headersId: this.headerIds
    }
    if (type == 'swot') {
      this.swotColumnDefs = [
        { field: 'team', headerName: 'Team' },
        { field: 'teamleader', headerName: 'Team Leader' },
        { field: 'subject', headerName: 'Subject' },
        {
          field: 'strength', headerName: 'Strength'
          , cellRenderer: this.swotpestColumnRender.bind(this),
          autoHeight: true
        },
        {
          field: 'weakness', headerName: 'Weakness',
          cellRenderer: this.swotpestColumnRender.bind(this),
          autoHeight: true
        },
        {
          field: 'opper', headerName: 'Opportunities',
          cellRenderer: this.swotpestColumnRender.bind(this),
          autoHeight: true
        },
        {
          field: 'threads', headerName: 'Threats',
          cellRenderer: this.swotpestColumnRender.bind(this),
          autoHeight: true
        },
        { field: 'createdAt', headerName: 'Creation Date' , cellRenderer: function (params: any) {
          let html = params && params.value ? params.value : '';
          return html != '' ? _moment(html).format('MM/DD/YYYY HH:mm:ss') : '';
        }},
      ];
    } else {
      this.swotColumnDefs = [
        { field: 'team', headerName: 'Team' },
        { field: 'teamleader', headerName: 'Team Leader' },
        { field: 'subject', headerName: 'Subject' },
        {
          field: 'political', headerName: 'Political Factors',
          cellRenderer: this.swotpestColumnRender.bind(this),
          autoHeight: true

        },
        {
          field: 'economic', headerName: 'Economic Factors',
          cellRenderer: this.swotpestColumnRender.bind(this),
          autoHeight: true
        },
        {
          field: 'social', headerName: 'Social Factors',
          cellRenderer: this.swotpestColumnRender.bind(this),
          autoHeight: true
        },
        {
          field: 'technology', headerName: 'Technological Factors',
          cellRenderer: this.swotpestColumnRender.bind(this),
          autoHeight: true
        },
        {
          field: 'createdAt', headerName: 'Creation Date', cellRenderer: function (params: any) {
            let html = params && params.value ? params.value : '';
            return html != '' ? _moment(html).format('MM/DD/YYYY HH:mm:ss') : '';
          }
        },
      ];
    }

    this.analyticalToolsService.getSwotPestDataByDept(requestData).subscribe((res: any) => {
      this.spinner.hide();
      this.swotpestByDeptDataArray = res.data.response;
      let deptByColumns: any = []; let strengthArr: any = []; let weakArr: any = []; let opperArr = []; let threadArr = [];
      let policalArr = []; let econmocArr = []; let socialArr = []; let technicalArr = []; let spCountData: any = [];
      if (this.swotpestByDeptDataArray.length != 0) {

        for (let [i, spdata] of this.swotpestByDeptDataArray.entries()) {
          deptByColumns.push(i + 1);

          if (type == 'swot') {
            strengthArr.push(spdata.strength.length);
            weakArr.push(spdata.weakness.length);
            opperArr.push(spdata.opper.length);
            threadArr.push(spdata.threads.length);

          } else if (type == 'pest') {
            policalArr.push(spdata.political.length);
            econmocArr.push(spdata.economic.length);
            socialArr.push(spdata.social.length);
            technicalArr.push(spdata.technology.length);
          }

        }
      }
      if (type == 'swot') {
        spCountData = [
          {
            name: 'Strength',
            type: 'column',
            data: strengthArr,
            "color": "#385aff",
          },
          {
            name: 'Weakness',
            type: 'column',
            data: weakArr,
            "color": "#f94386",
          },
          {
            name: 'Opportunities',
            type: 'column',
            data: opperArr,
            "color": "#7dff2c",
          },
          {
            name: 'Threats',
            type: 'column',
            data: threadArr,
            "color": "#ff532c",
          }
        ]
      } else {
        spCountData = [
          {
            name: 'Political Factors',
            type: 'column',
            data: policalArr,
            "color": "#385aff",
          },
          {
            name: 'Economic Factors',
            type: 'column',
            data: econmocArr,
            "color": "#f94386",
          },
          {
            name: 'Social Factors',
            type: 'column',
            data: socialArr,
            "color": "#7dff2c",
          },
          {
            name: 'Technological Factors',
            type: 'column',
            data: technicalArr,
            "color": "#ff532c",
          }
        ]
      }
      // chart view by department
      this.chartByDept = new Chart({

        chart: {
          type: 'column',
          options3d: {
            enabled: true,
            alpha: 45
          }
        },
        title: {
          text: this.deptName + ' Department Wise ' + this.stage_name + ' Counts'
        },
        xAxis: {
          categories: deptByColumns,
          crosshair: true
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            }
          }
        },

        series: spCountData,

      });

      for (let stagedata of res.data.response) {
        let teamusers: any = [];
        stagedata.team.split(',').map((team: any) => {
          // const teamuser = this.userList.find((user: any) => user.UserId == team);
          // if (teamuser != undefined)
          //   teamusers.push(teamuser.UserName);
            const teamuser = this.teams.find((user: any) => user.UserId == team);
          if (teamuser != undefined)
            teamusers.push(teamuser.FirstName+' '+teamuser.lastName);
        });
        stagedata.team = teamusers;
        // const teamlead = this.userList.find((user: any) => user.UserId == stagedata.teamleader);
        // if (teamlead != undefined)
        //   stagedata.teamleader = teamlead.UserName;
          const teamlead = this.teams.find((user: any) => user.UserId == stagedata.teamleader);
        if (teamlead != undefined)
          stagedata.teamleader = teamlead.FirstName+' '+teamlead.lastName;
      }
    },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }
  reset(): void {
    this.searchDefectForm.reset();
    this.lastwlastmdate = {};
    this.graphTableShow = false;
    this.graphGraphShow = false;
  }
  //date formatter
  dateAsYYYYMMDDHHNNSS(date: any): string {
    return date.getFullYear()
      + '-' + this.leftpad(date.getMonth() + 1, 2)
      + '-' + this.leftpad(date.getDate(), 2)
      + ' ' + this.leftpad(date.getHours(), 2)
      + ':' + this.leftpad(date.getMinutes(), 2)
      + ':' + this.leftpad(date.getSeconds(), 2);
  }
  leftpad(val: any, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
      + String(val)).slice(String(val).length);
  }
  onKey(value: any, type: any) {

    if (type == 'group') {
      this.groupList = this.searchKey(value.value, type);
    } else if (type == 'plant') {
      this.plantList = this.searchKey(value.value, type);
    } else if (type == 'department') {
      this.deptList = this.searchKey(value.value, type);
    }
  }

  searchKey(value: string, type: string) {
    let filter = value;
    if (type == 'group') {
      return this.groupTempList.filter((dep: any) => {
        let lowerCase = dep.TypeName.toLocaleLowerCase();
        return dep.TypeName.startsWith(filter) || lowerCase.startsWith(filter);
      });
    } else if (type == 'plant') {
      return this.plantTempList.filter((pla: any) => {
        let lowerCase = pla.DeptDesc.toLocaleLowerCase();
        return pla.DeptDesc.startsWith(filter) || lowerCase.startsWith(filter);
      });
    } else if (type == 'department') {
      return this.deptTempList.filter((dep: any) => {
        let lowerCase = dep.departmentName.toLocaleLowerCase();
        return dep.departmentName.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
  }
  onBtnExportCSV(): void {
    const params = {
      fileName: this.stage_name + this.deptName + ' Department Wise Data',
    }
    this.gridOptions.api.exportDataAsCsv(params);
  }

  onBtnExportExcel(): void {
    const params = {
      fileName: this.stage_name + this.deptName + ' Department Wise Data',
    }
    this.gridOptions.api.exportDataAsExcel(params);
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
