import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TechnicianService } from '../../services/technician.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment'
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { OperatorExecutionService } from 'src/app/services/operator-execution.service';
import { ManagerExecutionService } from '../../services/manager.service';
import { PrevConfigurationService } from 'src/app/services/prev-configuration.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-technician-execution',
  templateUrl: './technician-execution.component.html',
  styleUrls: ['./technician-execution.component.scss']
})
export class TechnicianExecutionComponent implements OnInit {
  isEdit: boolean = false;
  technicianForm: any;
  user: any;
  blobStorageUrl: string = "";
  spinnerText: string = "";
  scode:any;
  technician: any = {
    LineArea: '', 
    operExecWO: '', 
    WOStatus: '',
    WOStatusDisplay: '', 
    WOType: '',
    FailureMode: '', 
    Priority: '', 
    StartTime: new Date(), 
    CompleteTime: '', 
    SpareParts: '', 
    SparePartCode: '', 
    SPRequestTime: '', 
    SPReceivedTime: '', 
    Remark: '', 
    AfterPicture: [], 
    Remarks: '', 
    TechnicianId: '', 
    CreatedAt: '', 
    CreatedBy: 0, 
    ID: 0,
    OpCreatedAt:''
  }
  PriorityList: any = [];

  breadcrumList!: any;
  @ViewChild('technicianimageViewModal') private technicianimageViewModal!: TemplateRef<any>;
  @ViewChild('operatorimageViewModal') private operatorimageViewModal!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  isviewMode: any;
  spReqTime: any;
  spRecTime:any;
  get f() { return this.technicianForm.controls; }

  imageSrc = new Array<string>();
  fileType: any;
  FileData: any = [];
  FileList: any = [];
  EditFileData: any = [];

  OperatorFiles: any = [];

  woType: any = [];
  failMode: any = [];
  userList: any = [];
  technicianList: any = [];
  Group: any;
  Plant: any;
  permisson!: any;
  IsWrite: boolean = false;
  IsAccess!: any

  spareParts: any = [{ ID: 1, Name: 'Yes' }, { ID: 0, Name: 'No' }]

  constructor(
    private toaster: ToastrService, 
    private enterpriseGroupService: EnterPriseGroupService, 
    private fb: FormBuilder,
    private router: Router, 
    private spinner: NgxSpinnerService, 
    public toastr: ToastrService, 
    private technicianService: TechnicianService, 
    private userManagementService: UserManagementService, 
    public operatorExecutionService: OperatorExecutionService, 
    public managerExecutionService: ManagerExecutionService, 
    public prevConfigurationService: PrevConfigurationService, 
    private dialog:MatDialog,) {
    this.technicianForm = this.fb.group({
      line_area: ['', Validators.required,],
      wo_id: ['', Validators.required],
      wo_status: ['', Validators.required],
      wo_type: ['', Validators.required],
      faliure_mode: ['', Validators.required],
      priority: ['', Validators.required],
      start_time: ['', Validators.required],
      complete_time: [''],
      spare_parts: ['', Validators.required],
      spare_part_code: ['', Validators.required],
      sp_req_time: [''],
      sp_rec_time: [''],
      remarks: [''],
      before_pic: [''],
      after_pic: [''],
      remarks_manual: ['',Validators.required],
      visorremarks: [''],
      wo_creation_time: [''],
      wo_closed_time: ['']
      // tech_name: ['', Validators.required],
    });

    this.technicianForm.controls['line_area'].disable();
    this.technicianForm.controls['wo_id'].disable();
    this.technicianForm.controls['wo_status'].disable();
    this.technicianForm.controls['priority'].disable();
    this.technicianForm.controls['spare_part_code'].disable();
    this.technicianForm.controls['remarks'].disable();
    this.technicianForm.controls['visorremarks'].disable();
  }

  ngOnInit(): void {
    this.blobStorageUrl = environment.O3CoreApiImageUrl;
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.userList = this.managerExecutionService.getUserData();
    this.getTechnicianValues();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    if (this.technician.SpareParts) {
      this.technicianForm.controls['spare_part_code'].enable();
      this.technicianForm.controls['spare_part_code'].setValidators([Validators.required]);
      this.technicianForm.controls['spare_part_code'].updateValueAndValidity();
      this.technicianForm.controls['sp_req_time'].enable();
      this.technicianForm.controls['sp_req_time'].updateValueAndValidity();
    }
    else {
      this.technicianForm.controls['spare_part_code'].disable();
      this.technicianForm.controls['spare_part_code'].setValidators(null);
      this.technicianForm.controls['spare_part_code'].updateValueAndValidity();
      this.technician.SparePartCode = '';
      this.technicianForm.controls['sp_req_time'].disable();
      this.technicianForm.controls['sp_req_time'].updateValueAndValidity();
      this.technician.SPRequestTime = '';
      this.technicianForm.controls['sp_rec_time'].disable();
      this.technicianForm.controls['sp_rec_time'].setValidators(null);
      this.technicianForm.controls['sp_rec_time'].updateValueAndValidity();
      this.technician.SPReceivedTime = '';
    }
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe((data: any) => {
      this.permisson = data;
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  // img popup
  imgview(e:any){
    let dialogRef = this.dialog.open(this.technicianimageViewModal, {
      height: '90%',
      width: '90%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  oncloseimg(){
    this.dialog.closeAll();
  }

  imgview1(e1:any){
    let dialogRef = this.dialog.open(this.operatorimageViewModal, {
      height: '90%',
      width: '90%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  oncloseimg1(){
    this.dialog.closeAll();
  }
  // img popup

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  openFile() {
    this.fileInput.nativeElement.click()
  }

  readFile(event: any) {
    const files = event.target.files;
    if (files) {
      for (let file of files) {
        if (file.type.match('image.*')) {
          this.fileType = 'image';
          this.FileList.push(file);
        }
        else {
          this.toaster.warning('Warning', 'File Format Not Valid ');
          return;
        }
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageSrc = e.target.result;
          this.FileData.push({ FileType: this.fileType, ImageSrc: this.imageSrc })
        }
        reader.readAsDataURL(file);
      }
    }
  }

  removeFile(i: any) {
    this.FileData.splice(i, 1);
    this.FileList.splice(i, 1);
  }

  deleteFile(i: any) {
    this.EditFileData.splice(i, 1);
    this.technician.AfterPicture.push(i);
  }

  changeTechnician() {
    if (this.user.UserId == this.technician.TechnicianId) {
      this.technicianForm.controls['start_time'].setValidators([Validators.required]);
      this.technicianForm.controls['start_time'].updateValueAndValidity();
      this.technician.StartTime = new Date();
    }
    else {
      this.technicianForm.controls['start_time'].setValidators([Validators.nullValidator]);
      this.technicianForm.controls['start_time'].updateValueAndValidity();
      this.technician.StartTime = '';
    }
  }

  getTechnicianValues() {
    var data = this.technicianService.getTechnicianValues();
    if (data) {
      let OperatorUploadFiles: any = [];
    this.isviewMode =data.isviewButton
      this.technician.LineArea = data.operExecWo.line ? data.operExecWo.line.plDesc : '';
      this.technician.operExecWO = data.operExecWo.ID;
      this.technician.DisplayWOId = data.operExecWo.WOId;
      this.technician.ManagerStatus = data.operExecWo.ManagerStatus;
      this.technician.WOStatusDisplay = data.operExecWo.WOStatus == 1 ? 'Open' : data.operExecWo.WOStatus == 2 ? 'Close' : data.operExecWo.WOStatus == 3 ? 'Complete' : data.operExecWo.WOStatus == 4 ? 'Delay' : 'In Progress';
      if (this.technician.ManagerStatus == 0) {
        this.technicianForm.controls['priority'].enable();
        this.technicianForm.controls['priority'].updateValueAndValidity();
        this.technicianForm.controls['spare_part_code'].enable();
        this.technicianForm.controls['spare_part_code'].updateValueAndValidity();
      }

      if (data.operExecWo.SpareParts) {
        this.technicianForm.controls['spare_part_code'].enable();
        this.technicianForm.controls['spare_part_code'].setValidators([Validators.required]);
        this.technicianForm.controls['spare_part_code'].updateValueAndValidity();
      }
      else {
        this.technicianForm.controls['spare_part_code'].disable();
        this.technicianForm.controls['spare_part_code'].setValidators(null);
        this.technicianForm.controls['spare_part_code'].updateValueAndValidity();
      }

      if (this.PriorityList && this.PriorityList.length) {
        let priObj = this.PriorityList.find((pri: any) => pri.Name == data.Priority);
        this.technician.Priority = priObj && priObj.ID ? priObj.ID : '';
      }
      this.technician.WOType = data.operExecWo.WOType.ID;
      this.technician.FailureMode = data.operExecWo.FailureMode.ID;
      // this.technician.SpareParts = data.operExecWo.SpareParts == 1 ? 'Yes' : 'No';
      this.technician.SpareParts = data.operExecWo.SpareParts;
      this.technician.SparePartCode = data.SparePartCode;
      this.scode=data.SparePartCode;
      
      this.technician.Remark = data.operExecWo.Remark;
      this.technician.SupervisorRemark = data.ManagerRemarks
      let WOID = data.operExecWo.ID;
      this.Group = data.operExecWo.Group.egId;
      this.Plant = data.operExecWo.Plant.ptId

      if (data.operExecWo.UploadPicture) {
        OperatorUploadFiles = data.operExecWo.UploadPicture.split(',');
        for (let i = 0; i < OperatorUploadFiles.length; i++) {
          this.OperatorFiles.push({ ImageSrc: OperatorUploadFiles[i] })
        }
      }
      this.technician.OpCreatedAt = data.operExecWo.TimeStamp;
      this.technician.WOCreationTime = data.operExecWo && data.operExecWo.TimeStamp ? moment(data.operExecWo.TimeStamp).format('DD/MM/YYYY hh:mm a') : ''
      this.technician.WOClosedTime = data.operExecWo && data.operExecWo.ClosedAt ? moment(data.operExecWo.ClosedAt).format('DD/MM/YYYY hh:mm a') : ''

      this.getTechnician(WOID);
      this.getWOTypeFailureData();
      // this.findManagerData();
    }
    else
      this.router.navigateByUrl('/PM/technician');
  }

  findManagerData() {
    this.spinnerText = "Fetching Manager Data";
    this.spinner.show();
    const obj = {
      UserId: '',
      WOId: this.technician.operExecWO
    }
    this.managerExecutionService.getOperatorData(obj).subscribe((res: any) => {
      if (res && res.technician && res.technician.length) {
        for (const tech of res.technician) {
          let techObj = this.userList.find((user: any) => {
            return user.UserId == tech.UserId;
          });
          if (techObj)
            this.technicianList.push(techObj)
        }
        // this.technician.TechnicianId = this.user.UserId;
      }
    },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getWOTypeFailureData() {
    this.spinnerText = 'Fetching Master Data';
    this.spinner.show();
    const data = {
      WOType: this.technician.WOType,
      group: this.Group,
      plant: this.Plant
    }
    this.operatorExecutionService.getOperatorMasterData(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.woType = res.WOTypes;
        this.failMode = res.failureModes;
        this.technician.WOType = this.technician.WOType
        this.technician.FailureMode = this.technician.FailureMode
      }
      else
        this.toaster.error('Error', res.message)
    },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error('Error', err.message)
      })
  }

  getTechnician(WOId: any) {
    this.spinner.show();
    this.spinnerText = "Fetching Technician Data";
    this.technicianService.getTechnician(WOId).subscribe((res: any) => {
      let data = res.WO;
      this.PriorityList = res.priority
      if (data.operExecWo.techExc == null) {
        this.isEdit = false;
        this.technician.Priority = data.Priority && data.Priority.ID ? data.Priority.ID : '';
      }
      else {
        this.isEdit = true;
        this.technicianForm.controls['wo_type'].disable();
        this.technicianForm.controls['faliure_mode'].disable();
        let UploadFiles = [];
        this.technician.LineArea = data.operExecWo.line ? data.operExecWo.line.plDesc : '';
        this.technician.operExecWO = data.operExecWo.ID;
        this.technician.DisplayWOId = data.operExecWo.WOId;
        this.technician.WOStatus = data.operExecWo.WOStatus;
        this.technician.WOStatusDisplay = data.operExecWo.WOStatus == 1 ? 'Open' : data.operExecWo.WOStatus == 2 ? 'Close' : data.operExecWo.WOStatus == 3 ? 'Complete' : data.operExecWo.WOStatus == 4 ? 'Delay' : 'In Progress';
        this.technician.WOType = data.operExecWo.WOType.ID;
        this.technician.FailureMode = data.operExecWo.FailureMode.ID;
        this.technician.Priority = data.Priority && data.Priority.ID ? data.Priority.ID : '';
        this.technician.SpareParts = data.operExecWo.SpareParts;
        this.technician.SparePartCode = data.SparePartCode;
        this.scode = data.SparePartCode;
        this.technician.Remark = data.operExecWo.Remark;
        this.technician.UploadPicture = data.operExecWo.UploadPicture;
        this.technician.StartTime = data.operExecWo.techExc.StartTime;
        this.technician.CompleteTime = data.operExecWo.techExc.CompleteTime;
        this.technician.SPRequestTime = data.operExecWo.techExc.SPRequestTime;
        this.spReqTime=data.operExecWo.techExc.SPRequestTime;
        this.technician.SPReceivedTime = data.operExecWo.techExc.SPReceivedTime;
        this.spRecTime=data.operExecWo.techExc.SPReceivedTime;
        this.technician.Remarks = data.operExecWo.techExc.Remarks;
        this.technician.ID = data.operExecWo.techExc.ID
        this.technician.SupervisorRemark = data.ManagerRemarks
        if (data.operExecWo.techExc.AfterPicture) {
          UploadFiles = data.operExecWo.techExc.AfterPicture.split(',');
          for (let i = 0; i < UploadFiles.length; i++) {
            this.EditFileData.push({ ImageSrc: UploadFiles[i] })
          }
        }
        this.technician.WOCreationTime = data.operExecWo && data.operExecWo.TimeStamp ? moment(data.operExecWo.TimeStamp).format('DD/MM/YYYY hh:mm a') : ''
        this.technician.WOClosedTime = data.operExecWo && data.operExecWo.ClosedAt ? moment(data.operExecWo.ClosedAt).format('DD/MM/YYYY hh:mm a') : ''

      }

      this.spinner.hide();
    },
      (error: any) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });
  }

  setValidateSparePartsCode() {
    if (this.technician.SpareParts) {
      this.technicianForm.controls['spare_part_code'].enable();
      this.technicianForm.controls['spare_part_code'].setValidators([Validators.required]);
      this.technicianForm.controls.spare_part_code.setValue(this.scode);
      this.technicianForm.controls['sp_req_time'].enable();
      this.technicianForm.controls.sp_req_time.setValue(this.spReqTime);
      this.technicianForm.controls['sp_rec_time'].enable();
      this.technicianForm.controls.sp_rec_time.setValue(this.spRecTime);
    }
    else {
      this.technicianForm.controls['spare_part_code'].disable();
      this.technicianForm.controls['spare_part_code'].setValidators(null);
      this.technicianForm.controls['spare_part_code'].updateValueAndValidity();
      this.technician.SparePartCode = '';
      

      this.technicianForm.controls['sp_req_time'].disable();
      this.technicianForm.controls['sp_req_time'].updateValueAndValidity();
      this.technician.SPRequestTime = '';

      this.technicianForm.controls['sp_rec_time'].disable();
      this.technicianForm.controls['sp_rec_time'].setValidators(null);
      this.technicianForm.controls['sp_rec_time'].updateValueAndValidity();
      this.technician.SPReceivedTime = '';
      
    }
  }
  dateChanged(val){
    if(val=='starttime'){
      if (new Date(this.technician.StartTime) < new Date(this.technician.OpCreatedAt)) {
        this.toastr.warning('Technician Start Time Should Not Be Prior To Work Order Created Time', 'Warning', {
          timeOut: 3000,
        });
        this.technician.StartTime='';
        this.technicianForm.patchValue({start_time:null}) 
        return;
      }      
    }
    if(val=='spReceived')
       if ( new Date(this.technician.SPReceivedTime) < new Date(this.technician.SPRequestTime)) {
        this.toastr.warning('Technician SP Received Time Should Not Be Prior To Technician SP Request Time', 'Warning', {
          timeOut: 3000,
        });
        this.technician.SPReceivedTime='';
        this.technicianForm.patchValue({sp_rec_time:null}) 
        return;
      }
      if(val=='spRequest')
       if ( new Date(this.technician.SPRequestTime) < new Date(this.technician.StartTime)) {
        this.toastr.warning('Technician SP Request Time Should Not Be Prior To Technician Start Time', 'Warning', {
          timeOut: 3000,
        });
        this.technician.SPRequestTime='';
        this.technicianForm.patchValue({sp_req_time:null}) 
        return;
      }


    if (this.technician.CompleteTime) {
      if(val=='completetime'){
        let comTime = new Date(this.technician.CompleteTime)
        let curTime = new Date()
        if (comTime > curTime) {
          this.toastr.warning('Complete Time Field Is Not Accept Future Time', 'Warning', {
            timeOut: 3000,
          });
          this.technician.CompleteTime='';
          this.technicianForm.patchValue({complete_time:null}) 
          return;
        }
        else if (new Date(this.technician.CompleteTime) < new Date(this.technician.SPReceivedTime)) {
          this.toastr.warning('Technician Complete Time Should Not Be Prior To Technician SP Received Time', 'Warning', {
            timeOut: 3000,
          });
          this.technician.CompleteTime='';
          this.technicianForm.patchValue({complete_time:null}) 
          return;
        }
        else if (new Date(this.technician.CompleteTime) < new Date(this.technician.SPRequestTime)) {
          this.toastr.warning('Technician Complete Time Should Not Be Prior To Technician SP Request Time', 'Warning', {
            timeOut: 3000,
          });
          this.technician.CompleteTime='';
          this.technicianForm.patchValue({complete_time:null}) 
          return;
        }
        else if (new Date(this.technician.CompleteTime) < new Date(this.technician.StartTime)) {
          this.toastr.warning('Technician Complete Time Should Not Be Prior To Technician Start Time', 'Warning', {
            timeOut: 3000,
          });
          this.technician.CompleteTime='';
          this.technicianForm.patchValue({complete_time:null}) 
          return;
        }
      }
    }
  }

  onSubmit() {
    if (new Date(this.technician.StartTime) < new Date(this.technician.OpCreatedAt)) {
      this.toastr.warning('Technician Start Time Should Not Be Prior To Work Order Created Time', 'Warning', {
        timeOut: 3000,
      });
      return;
    }
    if (this.technician.SPReceivedTime) {
      if (!this.technician.SPRequestTime) {
        this.toastr.warning('Please Fill SP Request Time Field', 'Error', {
          timeOut: 3000,
        });
        return;
      }
      else {
        if (!this.technician.StartTime) {
          this.toastr.warning('Please Fill Start Time Field', 'Error', {
            timeOut: 3000,
          });
          return;
        }
      }
    }
    else {
      if (this.technician.SPRequestTime) {
        if (!this.technician.StartTime) {
          this.toastr.warning('Please Fill Start Time Field', 'Error', {
            timeOut: 3000,
          });
          return;
        }
      }
    }

    if (this.technician.CompleteTime) {
      let comTime = new Date(this.technician.CompleteTime)
      let curTime = new Date()
      if (comTime > curTime) {
        this.toastr.warning('Complete Time Field Is Not Accept Future Time', 'Warning', {
          timeOut: 3000,
        });
        return;
      }
      else if (new Date(this.technician.CompleteTime) < new Date(this.technician.SPReceivedTime)) {
        this.toastr.warning('Technician Complete Time Should Not Be Prior To Technician SP Received Time', 'Warning', {
          timeOut: 3000,
        });
        return;
      }
      else if (new Date(this.technician.CompleteTime) < new Date(this.technician.SPRequestTime)) {
        this.toastr.warning('Technician Complete Time Should Not Be Prior To Technician SP Request Time', 'Warning', {
          timeOut: 3000,
        });
        return;
      }
      else if (new Date(this.technician.CompleteTime) < new Date(this.technician.StartTime)) {
        this.toastr.warning('Technician Complete Time Should Not Be Prior To Technician Start Time', 'Warning', {
          timeOut: 3000,
        });
        return;
      }
    }

    if (this.technician.SPReceivedTime) {
      if (new Date(this.technician.SPReceivedTime) < new Date(this.technician.SPRequestTime)) {
        this.toastr.warning('Technician SP Received Time Should Not Be Prior To Technician SP Request Time', 'Warning', {
          timeOut: 3000,
        });
        return;
      }
      else if (new Date(this.technician.SPReceivedTime) < new Date(this.technician.StartTime)) {
        this.toastr.warning('Technician SP Received Time Should Not Be Prior To Technician Start Time', 'Warning', {
          timeOut: 3000,
        });
        return;
      }
    }

    if (this.technician.SPRequestTime) {
      if (new Date(this.technician.SPRequestTime) < new Date(this.technician.StartTime)) {
        this.toastr.warning('Technician SP Request Time Should Not Be Prior To Technician Start Time', 'Warning', {
          timeOut: 3000,
        });
        return;
      }
    }


    if (this.technician.SpareParts) {
      this.technicianForm.controls['sp_req_time'].updateValueAndValidity();
    }
    else {
      this.technicianForm.controls['sp_req_time'].setValidators(null);
      this.technicianForm.controls['sp_req_time'].updateValueAndValidity();
    }

    if (this.technicianForm.invalid) {
      this.toastr.warning('Please Fill All Required Field', 'Error', {
        timeOut: 3000,
      });
      return;
    }
    else {

      this.technician.StartTime = this.technician.StartTime;
      this.technician.CompleteTime = this.technician.CompleteTime;
      this.technician.SPRequestTime = this.technician.SPRequestTime;
      this.technician.SPReceivedTime = this.technician.SPReceivedTime;
      this.technician.CreatedAt = new Date();
      this.technician.CreatedBy = this.user.UserId;
      this.technician.TechnicianId = this.user.UserId;
      this.spinner.show();

      var mgrData = { Priority: this.technician.Priority, SpareParts: this.technician.SpareParts, SparePartCode: this.technician.SparePartCode, WOId: this.technician.operExecWO, AssignTechnicianTimeStamp: new Date(), CreatedBy: this.user.UserId, assignUser: this.user.UserId, status: this.technician.ManagerStatus };
      if (this.isEdit == false) {
        this.spinnerText = "Creating Technician Data";
        const Formvalues = new FormData();
        for (const file of this.FileList)
          Formvalues.append('Image', file, file.name);
        Formvalues.append('Data', JSON.stringify(this.technician));
        Formvalues.append('MgrData', JSON.stringify(mgrData));
        this.technicianService.addTechnician(Formvalues).subscribe((res: any) => {
          this.spinner.hide();
          if (res.status) {
            if (res.result) {
              this.addUserWiseNotification(res.result);
            }
            this.toastr.success(res.message || `Data Inserted Successfully`, 'Success', {
              timeOut: 3000,
            });
            this.router.navigateByUrl('/PM/technician');
          }
        }, (err: any) => {
          this.spinner.hide();
          this.toastr.error(err.message || `Server Error`, 'Error', {
            timeOut: 3000,
          });
        });
      }
      else {
        this.spinnerText = "Updating Technician Data";
        var data = { StartTime: this.technician.StartTime, CompleteTime: this.technician.CompleteTime, SPRequestTime: this.technician.SPRequestTime, SPReceivedTime: this.technician.SPReceivedTime, AfterPicture: this.technician.AfterPicture, Remarks: this.technician.Remarks, TechnicianId: this.user.UserId, ID: this.technician.ID, WOType: this.technician.WOType, FailureMode: this.technician.FailureMode };
        const Formvalues = new FormData();
        for (const file of this.FileList)
          Formvalues.append('Image', file, file.name);
        Formvalues.append('Data', JSON.stringify(data));
        Formvalues.append('MgrData', JSON.stringify(mgrData));
        this.technicianService.updateTechnician(Formvalues).subscribe((res: any) => {
          this.technician.AfterPicture = [];
          this.spinner.hide();
          if (res.status) {
            if (res.result) {
              this.addUserWiseNotification(res.result);
            }
            this.toastr.success(res.message || `Data Updated Successfully`, 'Success', {
              timeOut: 3000,
            });
            this.router.navigateByUrl('/PM/technician');
          }
        }, (err: any) => {
          this.spinner.hide();
          this.toastr.error(err.message || `Server Error`, 'Error', {
            timeOut: 3000,
          });
        });
      }
    }
  }

  addUserWiseNotification(val: any) {
    const data = {
      userId: val.RequestedBy, // multiple user ids send [104,105,7,9]
      desc: `Work Order ID ${val.WOId} Is Completed`,
      title: 'Work Order',
      createdBy: JSON.parse(localStorage.user).UserId,
    };
    this.spinnerText = 'Adding User Wise Notification';
    this.spinner.show();
    this.prevConfigurationService.addUserWiseNotification(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toaster.success('Success', res.message);
      }
    }, (error: any) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

}
