import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CauseEffectService {

  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) {
  }

  // *** Core Api Method *** //
  getCauseEffectData(headerId: number): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  //  return this.http.get<any[]>(this.baseUrl + 'show-cause-and-effects', { headers: header, params: { headerId: headerId } });
    return this.http.get<any[]>(this.o3CoreApiUrl + 'ATCauseAndEffects/ShowCauseAndEffect', { headers: header, params: { headerId: headerId } });
  }

  // *** Core Api Method *** //
  addCauseEffectData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    //  return this.http.post<any[]>(this.baseUrl + 'add-cause-and-effects', data, { headers: header });
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ATCauseAndEffects/AddCauseEffect', data, { headers: header });
  }

  // *** Core Api Method *** //
  updateCauseEffectData(data: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // return this.http.put<any[]>(this.baseUrl + 'update-cauese-and-effects', data, { headers: header });
    return this.http.post<any[]>(this.o3CoreApiUrl + 'ATCauseAndEffects/updateCauseAndEffect', data, { headers: header });
  }

}


