import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DefectManagementMasterService {
  baseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'CIL/GetEnterpriseGroupDDL', data);
  }

  getPlantsData(data: any): Observable<any[]> {
    const params = new HttpParams().set('id', data);
    return this.http.get<any[]>(this.o3CoreApiUrl + 'CIL/GetPlantByEgDDL', { params: params });
  }
  // ==========================================Master Defect Type ===========================//
  insertDefectType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/InsertDefectTypeMasterData', data);
  }
  updateDefectType(data: any): Observable<any[]> {

    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/UpdateDefectTypeMasterData', data);
  }
  deleteDefectType(data: any): Observable<any[]> {
    const param = new HttpParams().set('id', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/DeleteDefectTypeMasterData?' + param, { params: param });
  }

  getDefectTypeData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetDefectTypeMasterData', data);
  }

  getDefectTypeDDL(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetDefectTypeDDL', data);
  }

  getSubDefectTypeDDL(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetSubDefectTypeDDL', data);
  }
  insertMappingSubDefectType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/InsertMappingSubDefectTypeMasterData', data);
  }


  // ==============================================End Type========================================//

  // ===============================================Master Sub Defect Type===================================//
  insertSubDefectType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/InsertSubDefectTypeMasterData', data);
  }
  updateSubDefectType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/UpdateSubDefectTypeMasterData', data);
  }
  deleteDefectSubType(data: any): Observable<any[]> {
    const params = new HttpParams().set('id', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/DeleteSubDefectTypeMasterData?' + params, { params: params });
  }

  getDefectSubTypeData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetSubDefectTypeMasterData', data);
  }

  // =============================================== Defect Priority ===================================//
  insertDefectPriority(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/InsertDefectPriorityMasterData', data);
  }
  updatePriority(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/UpdateDefectPriorityMasterData', data);
  }
  deletePriority(data: any): Observable<any[]> {
    const params = new HttpParams().set('id', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/DeleteDefectPriorityMasterData?' + params, { params: params });
  }

  getDefectPriorityData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetDefectPriorityMasterData', data);
  }

  getDefectStatusData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetDefectStatusMasterData', data);
  }



  // ============================================== Defect Classification ==================================//
  insertDefectClassification(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/InsertDefectClassificationMasterData', data);
  }
  getDefectClassificationData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetDefectClassificationMasterData', data);
  }
  updateClassification(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/UpdateDefectClassificationMasterData', data);
  }
  deleteClassification(data: any): Observable<any[]> {
    const params = new HttpParams().set('id', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/DeleteDefectClassificationMasterData?' + params, { params: params });
  }

  // ============================================== Defect Role Group ==================================//
  insertDefectRoleGroup(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/InsertDefectTypeRoleGroupMasterData', data);
  }
  updateDefectRoleGroup(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/UpdateDefectTypeRoleGroupMasterData', data);
  }
  getDefectRoleData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetDefectTypeRoleGroupMasterData', data);
  }
  // updatedefectRoleGroup(data: any): Observable<any[]> {
  //   return this.http.post<any[]>(this.baseUrl + 'API Not Ready', data);
  // }
  deleteDefectRoleGroup(data: any): Observable<any[]> {
    const params = new HttpParams().set('id', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/DeleteDefectTypRoleGroupeMasterData?' + params, { params: params });
  }

  // ============================================== Defect Role Group Users ==================================//
  insertDefectRoleGroupUser(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/InsertMappingRoleGroupMasterData', data);
  }
  getDefectRoleGroupData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/GetRoleGroupUsers', data);
  }
  deleteMappingRoleGroupMasterData(data: any): Observable<any[]> {
    const params = new HttpParams().set('id', data);
    return this.http.post<any[]>(this.o3CoreApiUrl + 'DMS/DeleteMappingRoleGroupMasterData?' + params, { params: params });
  }
  // updatedefectRoleGroup(data:any): Observable<any[]> {
  //   const token = localStorage.getItem('token');
  //   const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  //   return this.http.post<any[]>(this.baseUrl + 'API Not Ready',data, { headers: header });
  // }
  deleteDefectRoleGroupUsers(data: any): Observable<any[]> {
    const params = new HttpParams().set('id', data);
    return this.http.post<any[]>(this.baseUrl + 'DMS/DeleteDefectTypRoleGroupeMasterData?' + params, { params: params });
  }

  getDefectRoleGroups(data: any): Observable<any[]> {
    const params = new HttpParams().set('plantId', data.plantId);
    return this.http.get<any[]>(this.o3CoreApiUrl + 'DMS/GetRoleGroupDDL', { params: params });
  }
  getDefectRoleGroupUsers(data: any): Observable<any[]> {
    const params = new HttpParams().set('EgId', data);
    return this.http.get<any[]>(this.o3CoreApiUrl + 'DMS/GetUsersForDMSDDL', { params: params });
  }
}