import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScoreTableService {

  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  getScores(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'AuditScore/GetScoreData', data);
  }
  getScoreTypes(): Observable<any> {
    return this.http.get<any>(this.o3CoreApiUrl + 'AuditScore/GetScoreTypes');
  }

  addScore(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'AuditScore/AddScoreData', data);
  }
  updateScore(data: any): Observable<any[]> {
     return this.http.put<any[]>(this.o3CoreApiUrl + 'AuditScore/UpdateScoreData', data);
   }
   deleteScore(data: any): Observable<any> {
     return this.http.post<any>(this.o3CoreApiUrl + 'AuditScore/DeleteScoreData', data);
   }
}
