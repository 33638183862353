import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BtnCellRenderer } from 'src/app/btn-cell-renderer.component';
import { BtnCellRendererActualEntry } from 'src/app/btn-cell-rendererActualEntry.component';
import { CustomDateComponent } from 'src/app/date-components/custom-date-component.component';
import { PrimeCellEditorComponent } from 'src/app/editors/prime-cell-editor.component';
import { CcrHistoryService } from 'src/app/services/ccr-history.service';
import { differenceInCalendarDays } from 'date-fns';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { OeeConfigurationService } from 'src/app/services/oee-configuration.service';
import { Action } from 'src/app/models/action';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { NotificationService } from 'src/app/services/notification.service';
import { MatSelectRendererComponent } from 'src/app/mat-selectlist-renderer';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
const moment = _moment;
@Component({
  selector: 'app-ccr-management',
  templateUrl: './ccr-management.component.html',
  styleUrls: ['./ccr-management.component.scss'],
})
export class CcrManagementComponent implements OnInit {
  maxDate = new Date();
  CCRFilterForm!: FormGroup;
  ccrData!: any[];
  cellData: any;
  fornote: any;

  breadcrumList!: any;
  actionbtnhide!: any;
  paginationPageSize!: any;
  qcRole!: any;
  ccrDetailColumns: string[] = [
    'action',
    'description',
    'date',
    'owner',
    'tools',
  ];
  capaActionColumns: string[] = [
    'description',
    'date',
    'owner',
    'tools',
    'capaData',
    'isChecked',
  ];
  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    // popupParent: document.querySelector('body'),
    // floatingFilter: true,
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
      slectlistRenderer: MatSelectRendererComponent,
    },
  };
  hide = false;
  ButtonType!: any;
  columnDefs: any;
  rowData: any;
  dataArray!: [];
  gridApi!: any;
  classifications!: any;
  classificationId!: any;
  gridColumnApi: any;
  units: any;
  Customers!: any[];
  ActionForm!: FormGroup;
  icons!: any;
  NoteForm!: FormGroup;
  CapaForm!: FormGroup;
  ccrId: any;
  currentImagePath: any;
  image: any;
  toolPath!: string;
  toolName!: string;
  toolslist!: any;
  raci!: any;
  groups!: any;
  formMode = true;
  actionId!: number;
  today = new Date();
  // raci!: any;
  plants!: any;
  filterdraci: any;
  ActionDetail!: boolean;
  actionlist: Action[] = [];
  ccrActionDataSource!: MatTableDataSource<any>;
  hideButton = false;
  remarksformMode!: boolean;
  params!: any;
  selectedRow: any;
  selectedAccountable: any;
  IsAccess: any;
  IsWrite: any;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private oeeConfigurationService: OeeConfigurationService,
    private platModelService: EnterPriseGroupService,
    private usermanagementService: UserManagementService,
    private machineService: MachineMappingService,
    private toastrService: ToastrService,
    public router: Router,
    private ccrService: CcrHistoryService,
    private ccrServices: CcrHistoryService,
    private notificationService: NotificationService,
    private kpiservice: KpitreeService
  ) {
    // LicenseManager.setLicenseKey('kazaz');
  }
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('displayRoleModal1') private displayRoleModal1!: TemplateRef<any>;
  @ViewChild('imageZoomModel') private imageZoomModel!: TemplateRef<any>;
  @ViewChild('capaActionHistoryModel')
  private capaActionHistoryModel!: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit() {
    this.CCRFilterForm = this.fb.group({
      customer: [''],
      machine: [''],
      start: ['', Validators.required],
      end: ['', Validators.required],
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });

    this.ActionForm = this.fb.group({
      desc: ['', Validators.required],
      date: [''],
      toolname: ['', Validators.required],
      owner: [''],
    });

    this.NoteForm = this.fb.group({
      note: ['', Validators.required],
    });
    this.CapaForm = this.fb.group({
      date: ['', Validators.required],
      check: [''],
    });
    const array = JSON.parse(localStorage.roleName).map((v: any) =>
      v.toLowerCase()
    );
    this.qcRole = array.includes('qc leader') === true ? true : false;
    const d = new Date();
    d.setDate(d.getDate() - 7);
    this.CCRFilterForm.get('start')?.setValue(d);
    this.CCRFilterForm.get('end')?.setValue(new Date());

    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getClassification();
    this.datasource();
    this.getCustomer();

    this.onSearchClick();

    this.columnDefs = [
      {
        field: 'CCR History',
        filter: false,
        headerName: 'Action Details',
        hide: this.IsWrite,
        // cellRenderer: 'btnCellRendererActualEntry',
        cellRenderer: (params: any) => {
          if (params.data.status === 'Closed') {
            return `
            <button mat-mini-fab="" color="primary" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: lightseagreen;" ng-reflect-color="primary" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>`;
          } else {
            return `
            <div>
            <button mat-mini-fab="" color="primary" ptooltip="Action Detail" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #824AED;" ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-credit-card" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
              <button mat-mini-fab="" color="primary" ptooltip="Action History" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: lightseagreen;" ng-reflect-color="primary" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-eye" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            </div>
          `;
          }
        },
        cellRendererParams: {
          clicked(field: any) {},
        },
      },
      {
        field: 'ccrIdDesc',
        sortable: true,
        filter: true,
        headerName: 'CCR ID',
      },
      {
        field: 'customerName',
        sortable: true,
        filter: true,
        headerName: 'Customer Name',
      },
      {
        field: 'region',
        sortable: true,
        filter: true,
        headerName: 'Region',
      },
      {
        field: 'address',
        sortable: true,
        filter: true,
        headerName: 'Address',
      },
      {
        field: 'classificationName',
        headerName: 'Classification',
        sortable: true,
        filter: true,
        editable: (params: any) =>
          params.data.ccrStatus === 'Pending' && this.qcRole,
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Complaint', 'Concern'],
        },
        cellStyle: {
          color: 'black',
          'background-color': '#EDF1E5',
        },
      },
      {
        field: 'typeName',
        sortable: true,
        filter: true,
        headerName: 'Type',
      },
      {
        field: 'prodDesc',
        sortable: true,
        filter: true,
        headerName: 'Product Item',
      },
      {
        field: 'machine',
        headerName: 'Machines',
        editable: true,
        // cellRenderer: 'slectlistRenderer',
        // cellEditorParams: {
        //   data: unit1,
        // },
      },
      { field: 'batch', sortable: true, filter: true, headerName: 'Batch' },
      {
        field: 'quantity',
        sortable: true,
        filter: true,
        headerName: 'Quantity',
      },
      {
        field: 'ccrDesc',
        sortable: true,
        filter: true,
        headerName: 'Description',
      },
      // tslint:disable-next-line:max-line-length
      {
        field: 'runDate',
        sortable: true,
        filter: true,
        headerName: 'Test Date',
        valueFormatter: (params: any) =>
          this.dateFormatter(params.data.runDate),
      },
      // tslint:disable-next-line:max-line-length
      {
        field: 'deliveryDate',
        sortable: true,
        filter: true,
        headerName: 'Delivery Date',
        valueFormatter: (params: any) =>
          this.dateFormatter(params.data.deliveryDate),
      },
      // tslint:disable-next-line:max-line-length
      {
        field: 'productionDate',
        sortable: true,
        filter: true,
        headerName: 'Production Date',
        valueFormatter: (params: any) =>
          this.dateFormatter(params.data.productionDate),
      },
      // tslint:disable-next-line:max-line-length
      {
        field: 'promisedDeliveryDate',
        sortable: true,
        filter: true,
        headerName: 'Promised Delivery Date',
        valueFormatter: (params: any) =>
          this.dateFormatter(params.data.promisedDeliveryDate),
      },
      {
        field: 'deliveryNote',
        sortable: true,
        filter: true,
        headerName: 'Delivery Note',
      },

      {
        field: 'ccrDelay',
        sortable: true,
        filter: true,
        headerName: 'Delay',
      },
      {
        field: 'otherNotes',
        sortable: true,
        filter: true,
        headerName: 'Other Notes',
      },
      {
        field: 'imagePath',
        headerName: 'Attached Picture',
        cellRenderer: (params: any): any => {
          return params.data.imagePath.includes('/null')
            ? '<img style="height:40px;width:40px" src="assets/images/no-image.png" />'
            : '<img style="height:40px;width:40px" src="' +
                params.data.imagePath +
                '" />';
          // return params.value !== "https://demo.o3manufacturing.com:90/uploads/ccr/null"
          //   ? '<img style="height:40px;width:40px" src="' + params.value + '"/>'
          //   : '<img style="height:40px;width:40px" src="assets/images/no-image.png" />';
        },
      },
      { field: 'sampleSentBy', headerName: 'Sent By Courier' },
      {
        field: 'ccrStatus',
        headerName: 'Authenticity Status',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        editable: (params: any) =>
          params.data.ccrStatus === 'Pending' && this.qcRole,
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Pending', 'Approved', 'Reject'],
        },
        cellStyle: {
          color: 'black',
          'background-color': this.qcRole === true ? '#EDF1E5' : '',
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        filter: true,
        resizable: true,
        wrapText: true,
        editable: (params: any) => params.data.ccrStatus === '',
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['In Progress', 'Closed', 'Delay'],
        },
        cellStyle: (params: { value: string }) => {
          if (params.value === 'Closed') {
            return {
              color: 'black',
              backgroundColor: '#38cf36',
              'font-weight': 'bold',
            };
          } else if (params.value === 'In Progress') {
            return {
              color: 'black',
              backgroundColor: 'yellow',
              'font-weight': 'bold',
            };
          } else if (params.value === 'Delayed') {
            return {
              color: 'black',
              backgroundColor: '#eb3d3d',
              'font-weight': 'bold',
            };
          } else {
            return {
              color: 'black',
              backgroundColor: '#11ced1',
              'font-weight': 'bold',
            };
          }
        },
      },

      {
        field: 'rcaDue',
        headerName: 'RCA Due',
        sortable: true,
        cellStyle: {
          color: 'black',
          'background-color': this.qcRole === true ? '#EDF1E5' : '',
        },
        filter: true,
        resizable: true,
        wrapText: true,
        editable: (params: any) =>
          params.data.ccrStatus === 'Approved' &&
          params.data.status === 'In Progress' &&
          params.data.status !== 'Closed' &&
          params.data.rcaDue !== 'Yes' &&
          this.qcRole,
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Yes', 'No'],
        },
      },
      {
        field: 'implementcapa',
        headerName: 'Implement CAPA',
        sortable: true,
        cellStyle: {
          color: 'black',
          'background-color': this.qcRole === true ? '#EDF1E5' : '',
        },
        filter: true,
        resizable: true,
        wrapText: true,
        editable: (params: any) =>
          params.data.rcaDue === 'Yes' &&
          params.data.status !== 'Closed' &&
          this.qcRole,
        cellRenderer: 'agCellRenderer',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: 'genderCellRenderer',
          values: ['Yes', 'No'],
        },
      },
      { field: 'remarks', headerName: 'Remarks' },
    ];
    this.getAllCCR();
  }
  dateFormatter(value: any): any {
    if (value) {
      return moment(value).format('DD/MM/YYYY');
    }
  }

  imageZoomModal(params: any): void {
    const col = params.colDef.field;
    if (col === 'imagePath') {
      this.image = params.data.imagePath;
      this.openFileModal();
    }
  }
  openFileModal(): void {
    // this.currentImagePath = this.image;
    this.currentImagePath =
      this.image.indexOf('/null') > -1
        ? 'assets/images/no-image.png'
        : this.image;
    this.dialog.open(this.imageZoomModel, { height: '90%', width: '90%' });
  }

  datasource(): void {
    const key = {
      Id: 0,
    };
    this.oeeConfigurationService.getGroupData(key).subscribe(
      (data: any) => {
        this.groups = data.masterList;
        this.CCRFilterForm.get('group')?.setValue(this.groups[0].TypeId);
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onChangeGroup(data: any): void {
    this.CCRFilterForm.get('groups')?.setValue(data.value);
    this.getPlants();
  }
  onChangePlant(data: any): void {
    this.CCRFilterForm.get('Plants')?.setValue(data.value);
    this.getCustomer();
  }
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) < 0;
  getPlants(): void {
    const key = {
      GroupId: this.CCRFilterForm.value.group,
    };
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList;
        this.CCRFilterForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getCustomer();
        //
      },
      (error: any) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }
  sendUserNotification(users: any, descr: any): void {
    debugger;
    const key = {
      userId: users,
      desc: descr,
      title: 'CCM',
      createdBy: JSON.parse(localStorage.user).UserId,
    };
    this.notificationService.addnotificationuserwise(key).subscribe(
      (response: any) => {
        this.toaster.success('Notification Sent');
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  sendNotificationRole(descr: any): void {
    debugger;
    const key = {
      roleName: 'QC leader',
      egId: this.CCRFilterForm.value.group,
      createdBy: JSON.parse(localStorage.user).UserId,
      desc: descr,
      title: 'CCM',
    };
    this.notificationService.addnotification(key).subscribe(
      (response: any) => {
        this.toaster.success('Notification Sent');
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }
  getCustomer(): void {
    this.spinner.show();
    const key = {
      plantId: this.CCRFilterForm.value.plant,
    };
    this.ccrService.getCustomers(key).subscribe(
      (res: any) => {
        this.Customers = res;

        this.CCRFilterForm.get('customer')?.setValue(0);
        this.spinner.hide();
        this.onSearchClick();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
        this.spinner.hide();
      }
    );
  }
  cellClicked(params: any, showButton: boolean): void {
    const col = params.colDef.field;
    this.ccrId = params.data.ccrId;

    this.ButtonType = '';
    this.ccrId = params.data.ccrId;

    if (col === 'CCR History') {
      this.ButtonType = params.event.target.classList[1];
      if (this.ButtonType === 'fa-credit-card') {
        this.openModal1(this.ccrId);
      } else if (this.ButtonType === 'fa-eye') {
        this.capaActionHistory(this.ccrId, false);
      }
    }
  }
  openModal(element: any): void {
    const key = {
      ccrId: this.ccrId,
    };
    this.dialog.open(this.displayRoleModal, {
      height: '40%',
      width: '40%',
      disableClose: true,
    });
    this.ccrId = element;
  }

  openModal1(element: any): void {
    this.dialog.open(this.displayRoleModal1, { height: '90%', width: '80%' });
    this.formMode = true;
    this.getUserList();
    this.getTools();

    const key = {
      ccrId: this.ccrId,
    };
    this.getAllCCRAction(key);
    this.ccrId = element;
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  getAllCCR(): void {
    this.ccrService.getCCR().subscribe(
      (dataArray: any) => {
        debugger;
        this.dataArray = dataArray;
        this.spinner.hide();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  public isFiltered(multiselectOption: any): any {
    return this.filterdraci.find(
      (item: any) => item.userId === multiselectOption.userId
    );
  }

  getUserList(): void {
    debugger;
    const key = {
      EGId: this.CCRFilterForm.get('group')?.value,
      PlantId: this.CCRFilterForm.get('plant')?.value,
    };

    this.kpiservice.getEmployeeMasterData(key).then(
      (users: any) => {
        this.raci = users.employeeInfoList.filter((x: any) => {
          return x.EmpInfo_Id;
        });
        this.raci.forEach((element: any) => {
          element.fullName = element.FirstName + ' ' + element.lastName;
        });

        // this.filteredUsers = this.raci.slice();
        this.filterdraci = this.raci.slice();
        this.selectedAccountable.push();

        console.log(this.raci.slice(), 'feff');
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  // getUserList(): void {
  //   const key = {
  //     egId: this.CCRFilterForm.value.group,
  //   };
  //   this.usermanagementService.getRaciUsers(key).subscribe(
  //     (users) => {
  //       this.raci = users;
  //       this.filterdraci = this.raci.slice();
  //     },
  //     (error: any) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }
  getTools(): void {
    const key = {
      plantId: this.CCRFilterForm.value.plant,
    };
    this.ccrService.mastertools(key).subscribe(
      (response: any) => {
        this.toolslist = response;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  saveUserAction(): void {
    const data = {
      ccrId: this.ccrId,
      userId: JSON.parse(localStorage.user).UserId,
      actionDesc: this.ActionForm.value.desc,
      actionDate: moment(this.ActionForm.value.date).format('YYYY-MM-DD'),
      responsible: this.ActionForm.value.owner.toString(),
      toolId: this.ActionForm.value.toolname,
      rcaDue: 'Yes',
    };
    if (this.ActionForm.invalid) {
      this.toaster.error('Error', 'Please fills the all fields!');

      return;
    }
    const key = {
      ccrId: this.ccrId,
    };
    this.ccrService.ccrAction(data).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'Action Added Successfully');
        this.getAllCCRAction(key);
        this.ActionForm.reset();
        this.hide = false;
        this.onSearchClick();
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getAllCCRAction(element: any): void {
    const data = {
      ccrId: element.ccrId,
    };

    this.ccrService.allccrAction(data).subscribe(
      (res) => {
        this.ccrActionDataSource = new MatTableDataSource<any>(res);
        this.getPaginator();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  deleteccrAction(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const key = {
          //  ccrId:element.ccrId,
          actionId: element,
        };
        const data = {
          ccrId: this.ccrId,
        };
        this.ccrService.deleteccraction(key).subscribe(
          (success: any) => {
            Swal.fire(
              'Deleted!',
              'Your selected ccr action has been deleted.',
              'success'
            );
            this.getAllCCRAction(data);
          },
          (error: any) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected ccr is safe :)', 'error');
      }
    });
  }

  getccractionbyId(data: any): void {
    // var Id:any[]=[];
    const p = data.responsible.split(',').map(Number);
    this.actionId = data.actionId;
    this.formMode = false;
    this.ActionForm.get('desc')?.setValue(data.actionDesc);
    this.ActionForm.get('date')?.setValue(data.actionDate);
    this.ActionForm.get('toolname')?.setValue(data.toolId);
    // this.ActionForm.get('toolname')?.setValue(this.toolslist.find((x:any) => x.name === data.name));
    this.ActionForm.get('owner')?.setValue(p);
  }
  updateccrAction(): void {
    const data = {
      ccrId: this.ccrId,
      actionId: this.actionId,
      userId: JSON.parse(localStorage.user).UserId,
      actionDesc: this.ActionForm.value.desc,
      actionDate: moment(this.ActionForm.value.date).format('YYYY-MM-DD'),
      responsible: this.ActionForm.value.owner.toString(),
      toolId: this.ActionForm.value.toolname,
    };
    const key = {
      ccrId: this.ccrId,
    };
    this.ccrService.updateCcraction(data).subscribe(
      (response: any) => {
        // this.getAllCCRAction(element);

        this.toaster.success('Success', 'ccr action Updated Successfully');
        this.getAllCCRAction(key);
        this.ActionForm.reset();
        // this.formMode=true;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  closeopenModal(): void {
    this.dialog.closeAll();
  }
  saveNotes(): void {
    debugger;
    if (this.NoteForm.value.note !== '') {
      const data = {
        ccrId: this.ccrId,
        notes: this.NoteForm.value.note,
      };
      this.ccrService.addNotes(data).subscribe(
        (response: any) => {
          this.toaster.success('Success', 'Notes Added Successfully');

          this.closeopenModal();

          this.NoteForm.reset();
          if (this.remarksformMode === false) {
            this.params = 'Reject';
            this.cellData = {
              ccrId: this.ccrId,
              ccrStatus: 'Reject',
              status: 'Closed',
              // rcaDue:'No'
            };
            this.sendNotificationRole(
              'The Complaint had been rejected for the Customer ' +
                this.selectedRow.customerName +
                ' against Complaint ID - ' +
                this.selectedRow.ccrId
            );
            this.sendNotificationRole(
              'The Complaint had been Closed for the Customer ' +
                this.selectedRow.customerName +
                ' against Complaint ID - ' +
                this.selectedRow.ccrId
            );
            this.ccrService
              .getStatus(this.cellData)
              .subscribe((response: any) => {
                this.onSearchClick();
              });

            return;
          }
          this.RCAStatus(this.cellData);
          // this.onSearchClick();

          this.dialog.closeAll();
          this.NoteForm.reset();

          if (this.CCRFilterForm.value.customer !== '') {
            const data = {
              plantId: this.CCRFilterForm.value.plant,
              customerName: this.CCRFilterForm.value.customer,
              startDate: moment(this.CCRFilterForm.value.start).format(
                'YYYY-MM-DD'
              ),
              endDate: moment(this.CCRFilterForm.value.end).format(
                'YYYY-MM-DD'
              ),
            };

            if (this.CCRFilterForm.invalid) {
              return;
            }
            this.ccrService.onSearchByCustomer(data).subscribe(
              (res: any) => {
                res.forEach((a: any) => {
                  if (a.status === 'Closed') {
                    a.rcaDue = 'No';
                  }
                });
                this.dataArray = res;
              },
              (error: any) => {
                this.toaster.error('error', error.message);
              }
            );
          }
        },
        (error: any) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      this.toaster.error('Error', 'Please Add notes');
    }
  }

  capaActionHistory(element: any, showButton: boolean): void {
    this.hideButton = showButton;
    this.dialog.open(this.capaActionHistoryModel, {
      height: 'auto',
      width: '100%',
    });

    const key = {
      ccrId: this.ccrId,
    };
    this.getAllCCRAction(key);

    this.ccrId = element;
  }
  onSearchClick(): void {
    debugger;
    if (this.CCRFilterForm.value.customer !== '') {
      const data = {
        plantId: this.CCRFilterForm.value.plant,
        customerName: this.CCRFilterForm.value.customer,
        startDate: moment(this.CCRFilterForm.value.start).format('YYYY-MM-DD'),
        endDate: moment(this.CCRFilterForm.value.end).format('YYYY-MM-DD'),
      };

      if (this.CCRFilterForm.invalid) {
        return;
      }
      this.ccrService.onSearchByCustomer(data).subscribe(
        (res: any) => {
          this.dataArray = res;
        },
        (error: any) => {
          this.toaster.error('error', error.message);
        }
      );
    }
  }

  getPaginator(): void {
    setTimeout(() => {
      this.ccrActionDataSource.paginator = this.paginator;
    }, 1000);
  }

  onCellValueChanged(params: any): void {
    debugger;
    let data: any;
    this.params = params.data.ccrStatus;
    this.ccrId = params.data.ccrId;

    const col = params.colDef.field;
    if (col === 'classificationName') {
      const ClassificationId = this.classifications.find(
        (c: any) => c.classificationName === params.value
      ).classificationId;
      data = {
        ccrId: this.ccrId,
        classificationId: ClassificationId,
      };
      this.updateClassification(data);
    }

    if (col === 'ccrDelay') {
      const delay: number = params.data.ccrDelay;
      data = {
        ccrId: params.data.ccrId,
        ccrDelay: delay,
      };
      this.updateField(data);
    }
    if (col === 'promisedDeliveryDate') {
      data = {
        ccrId: params.data.ccrId,
        promisedDeliveryDate: moment(params.data.promisedDeliveryDate).format(
          'YYYY-MM-DD'
        ),
      };
      this.updateField(data);
    }
    if (col === 'runDate') {
      data = {
        ccrId: params.data.ccrId,
        runDate: moment(params.data.runDate).format('YYYY-MM-DD'),
      };
      this.updateField(data);
    }

    if (col === 'ccrStatus') {
      if (params.data.ccrStatus === 'Approved') {
        this.cellData = {
          ccrId: params.data.ccrId,
          ccrStatus: params.value,
          status: 'In Progress',
          // rcaDue:'No'
        };
        this.ccrService.getStatus(this.cellData).subscribe(
          (response: any) => {
            this.toaster.success('Success', 'Approved Successfully');
            this.sendNotificationRole(
              'The Complaint has been ' +
                params.data.ccrStatus +
                ' for the Customer ' +
                params.data.customerName +
                ' against Complaint ID - ' +
                params.data.ccrId
            );
            this.onSearchClick();
          },
          (error: any) => {
            return this.toaster.error('Error', error.message);
          }
        );
      } else if (params.data.ccrStatus === 'Reject') {
        this.remarksformMode = false;
        this.cellData = {
          ccrId: params.data.ccrId,
          ccrStatus: params.value,
          status: 'Closed',
          // rcaDue:'No'
        };
        this.openModal(this.ccrId);

        this.selectedRow = params.data;
      }
    }

    if (col === 'rcaDue') {
      if (params.data.rcaDue === 'No') {
        this.remarksformMode = true;
        this.cellData = {
          ccrId: params.data.ccrId,
          status: 'Closed',
          rcaDue: 'No',
          implementCapa: params.data.implementCapa,
          ccrStatus: params.data.ccrStatus,
        };
        this.onSearchClick();
        this.openModal(this.ccrId);
        this.selectedRow = params.data;
      } else if (params.data.status === 'Closed') {
        return;
      } else {
        const key = {
          ccrId: params.data.ccrId,
          status: params.data.status,
          rcaDue: '',
          implementCapa: params.data.implementCapa,
          ccrStatus: params.data.ccrStatus,
        };
        this.ccrService.getStatus(key).subscribe((response: any) => {});
        this.onSearchClick();
        this.openModal1(this.ccrId);
      }
    }

    if (col === 'implementcapa') {
      this.cellData = {
        ccrId: params.data.ccrId,
        status: params.data.status,
        rcaDue: params.value,
        implementCapa: '',
      };
      this.ccrService.getStatus(this.cellData).subscribe(
        (response: any) => {
          this.onSearchClick();

          if (params.data.implementcapa === 'Yes') {
            const key = {
              ccrId: params.data.ccrId,
              status: params.data.status,
              rcaDue: params.value,
              implementCapa: '',
            };

            this.capaActionHistory(this.ccrId, true);
          } else {
            const key = {
              ccrId: params.data.ccrId,
              status: 'Delay',
              rcaDue: '',
              implementCapa: params.value,
            };
            this.ccrService.getStatus(key).subscribe((response: any) => {
              this.onSearchClick();
            });
          }
        },
        (error: any) => {
          return this.toaster.error('Error', error.message);
        }
      );
    }

    if (col === 'machine') {
      const key = {
        ccrId: params.data.ccrId,
        machine: params.data.machine,
      };
      this.ccrService.addccrmachine(key).subscribe((response: any) => {
        this.toaster.success('Success', 'Machine Updated Successfully');
        this.onSearchClick();
      });
    }
  }

  selectdate(): void {
    const date = moment(new Date()).format('YYYY-MM-DD');
    const SelectDate = moment(this.ActionForm.value.date).format('YYYY-MM-DD');
    if (SelectDate <= date) {
      this.ActionForm.get('date')?.setValue(SelectDate);
    } else {
      this.toaster.warning("Selected date can't be greater then current date");
      this.ActionForm.get('date')?.setValue('');
    }
  }

  OnChangeDate(event: any, data: any): any {
    const actionId = parseInt(data.actionId);
    // const actionDate = data.actionDate;
    const d = new Date(data.actionDate);
    const implementCapaDate = event.target.value; // .format('YYYY-MMM-DD')
    // const date = moment(new Date()).format('YYYY-MM-DD');
    const m = this.ccrActionDataSource.filteredData.find(
      (x) => x.actionId === actionId
    );
    m.implementCapaDate = implementCapaDate;
  }
  ccrActionCheckbox(event: any, data: any): void {
    const actionId = parseInt(data.actionId);
    const isChecked = event.target.checked;

    const m = this.ccrActionDataSource.filteredData.find(
      (x) => x.actionId === actionId
    );
    if (!m.implementCapaDate) {
      event.target.checked = false;
      this.toaster.warning('Please select date first');
      return;
    }
    m.isChecked = isChecked;

    const result = this.ccrActionDataSource;
  }

  ccrcapaaction(): void {
    const data = this.ccrActionDataSource.filteredData;
    this.ccrService.ccrcapaaction(data).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'Updated Successfully');
        if (response.ccrStatus === 'Closed') {
          this.sendNotificationRole(
            'The Complaint had been Closed for the Customer ' +
              this.selectedRow.customerName +
              ' against Complaint ID - ' +
              this.selectedRow.ccrId
          );
        }
        this.dialog.closeAll();
        this.onSearchClick();
      },
      (error: any) => {
        return this.toaster.error('Error', error.message);
      }
    );
  }

  // menu and breadcrumb
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        console.log(data.BreadcrumList);
      },
      (error: any) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  accountbleChange(event: any, index: number): void {
    const field = this.actionlist[index];
    field.accountableUser = event.value;
  }
  onToolChange(event: any): void {
    this.hide = true;
    const tool = this.ActionForm.value.toolname;
    this.toolPath = this.toolslist.find(
      (c: any) => c.toolId === event.value
    ).filePath;
    this.toolName = this.toolslist.find(
      (c: any) => c.toolId === event.value
    ).filename;
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  updateField(fieldData: any): void {
    this.ccrService.updateCCRfromCCRManagement(fieldData).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'Updated Successfully');
      },
      (error: any) => {
        return this.toaster.error('Error', error.message);
      }
    );
  }

  updateClassification(fieldData: any): void {
    this.ccrService.updateClassification(fieldData).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'Updated Successfully');
        this.onSearchClick();
      },
      (error: any) => {
        return this.toaster.error('Error', error.message);
      }
    );
  }

  RCAStatus(event: any): void {
    this.ccrService.getStatus(event).subscribe((response: any) => {});
  }

  getClassification(): void {
    this.ccrServices.getClassification().subscribe(
      (res: any) => {
        this.classifications = res;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
}
