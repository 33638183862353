<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
</ngx-spinner>
<div class="container">
    <div class="content-top-box">
        <ul class="breadcrumb">
            <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                    (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
            </li>
        </ul>
    </div>
    <header>
        <h2>Technician Score</h2>
    </header>
    <form name="scoreForm" [formGroup]="scoreForm" #formDirective="ngForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <mat-form-field appearence="fill" class="col">
                <mat-label>Select Group</mat-label>
                <mat-select formControlName="group" [(ngModel)]="score.Group" (selectionChange)="getPlants(score.Group)"
                    name="Group" [disabled]="true">
                    <mat-option *ngFor="let group of groups" [value]="group.TypeId">
                        {{ group.TypeName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill" class="col">
                <mat-select formControlName="plant" [(ngModel)]="score.Plant" placeholder="Plant" [disabled]="true">
                    <mat-option *ngFor="let plant of PlantList" [value]="plant.id">
                        {{plant.name}}
                    </mat-option>
                </mat-select>
                <mat-error>Plant is Required</mat-error>
            </mat-form-field>
            <mat-form-field appearence="fill" class="col">
                <mat-label>Select Technician</mat-label>
                <mat-select formControlName="tech" [(ngModel)]="score.TechId" (selectionChange)="getTechScoreList()">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'UserName'" [array]="filterTechnicianList"
                    (filteredReturn)="technicianFiltList = $event">
                  </mat-select-filter>
                        <mat-option *ngFor="let tech of technicianFiltList" [value]="tech.UserId">
                        {{ tech.UserName }}
                    </mat-option>
                </mat-select>
                <mat-error>Technician is required</mat-error>
            </mat-form-field>
            <button mat-flat-button color="accent" [disabled]="!IsWrite" type="button" class="upload mx-25px" (click)="openFile()">Upload
                Profile Picture</button>
        </div>
        <div class="row justify-content-between my align-items-center">
            <table class="w-50 rate-desc">
                <thead>
                    <tr>
                        <th class="text-center py-1" style="width: 50%;">Rating</th>
                        <th class="text-center py-1">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let star of starDescList; let i=index">
                        <td class="text-center">
                            <fieldset class="rate">
                                <input type="radio" [id]="'rating-desc-10'+i" value="10" disabled
                                    [checked]="star.value==5" /><label [for]="'rating-desc-10'+i"
                                    title="5 stars"></label>
                                <input type="radio" [id]="'rating-desc-9'+i" value="9" disabled
                                    [checked]="star.value==5" /><label class="half" [for]="'rating-desc-9'+i"
                                    title="4 1/2 stars"></label>
                                <input type="radio" [id]="'rating-desc-8'+i" value="8" disabled
                                    [checked]="star.value>3" /><label [for]="'rating-desc-8'+i" title="4 stars"></label>
                                <input type="radio" [id]="'rating-desc-7'+i" value="7" disabled
                                    [checked]="star.value>3" /><label class="half" [for]="'rating-desc-7'+i"
                                    title="3 1/2 stars"></label>
                                <input type="radio" [id]="'rating-desc-6'+i" value="6" disabled
                                    [checked]="star.value>2" /><label [for]="'rating-desc-6'+i" title="3 stars"></label>
                                <input type="radio" [id]="'rating-desc-5'+i" value="5" disabled
                                    [checked]="star.value>2" /><label class="half" [for]="'rating-desc-5'+i"
                                    title="2 1/2 stars"></label>
                                <input type="radio" [id]="'rating-desc-4'+i" value="4" disabled
                                    [checked]="star.value>1" /><label [for]="'rating-desc-4'+i" title="2 stars"></label>
                                <input type="radio" [id]="'rating-desc-3'+i" value="3" disabled
                                    [checked]="star.value>1" /><label class="half" [for]="'rating-desc-3'+i"
                                    title="1 1/2 stars"></label>
                                <input type="radio" [id]="'rating-desc-2'+i" value="2" disabled
                                    [checked]="star.value>0" /><label [for]="'rating-desc-2'+i" title="1 star"></label>
                                <input type="radio" [id]="'rating-desc-1'+i" value="1" disabled
                                    [checked]="star.value>0" /><label class="half" [for]="'rating-list-1'+i"
                                    title="1/2 star"></label>
                            </fieldset>
                        </td>
                        <td class="text-center">{{ star.title }}</td>
                    </tr>
                </tbody>
            </table>
            <input type="file" (change)="readFile($event)" class="d-none" #fileInput accept="image/*">
            <div class="image w-20" *ngIf="image.src || techobj.Picture">
                <img [src]="image.src || blobUrl+techobj.Picture" [alt]="image.name" (click)="imgview($event)">
            </div>
        </div>
        <div style="display: flex; justify-content: space-between;align-items:center;">
            <div class="score">
                <label>Average Score</label>
                <fieldset class="rate">
                    <input type="radio" #rating10 id="rating-10" name="rating" value="10" disabled /><label
                        for="rating-10" title="5 stars"></label>
                    <input type="radio" #rating9 id="rating-9" name="rating" value="9" disabled /><label class="half"
                        for="rating-9" title="4 1/2 stars"></label>
                    <input type="radio" #rating8 id="rating-8" name="rating" value="8" disabled /><label for="rating-8"
                        title="4 stars"></label>
                    <input type="radio" #rating7 id="rating-7" name="rating" value="7" disabled /><label class="half"
                        for="rating-7" title="3 1/2 stars"></label>
                    <input type="radio" #rating6 id="rating-6" name="rating" value="6" disabled /><label for="rating-6"
                        title="3 stars"></label>
                    <input type="radio" #rating5 id="rating-5" name="rating" value="5" disabled /><label class="half"
                        for="rating-5" title="2 1/2 stars"></label>
                    <input type="radio" #rating4 id="rating-4" name="rating" value="4" disabled /><label for="rating-4"
                        title="2 stars"></label>
                    <input type="radio" #rating3 id="rating-3" name="rating" value="3" disabled /><label class="half"
                        for="rating-3" title="1 1/2 stars"></label>
                    <input type="radio" #rating2 id="rating-2" name="rating" value="2" disabled /><label for="rating-2"
                        title="1 star"></label>
                    <input type="radio" #rating1 id="rating-1" name="rating" value="1" disabled /><label class="half"
                        for="rating-1" title="1/2 star"></label>
                </fieldset>
            </div>
            <button class="submit" [disabled]="!IsWrite" type="submit">Submit</button>
        </div>
    </form>

    <div class="score-section">
        <div class="w-100 d-flex align-items-center score-table-header">
            <div class="w-50 py-2">Work Order Type / Failure Mode</div>
            <div class="w-50 py-2">Score</div>
        </div>
        <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <div class="d-flex justify-content-around align-items-center w-100">
                    <div>{{node.name}}</div>
                    <fieldset class="rate" *ngIf="node.level==0">
                        <input type="radio" [id]="'rating-list-10'+node.index" value="10" disabled /><label
                            [for]="'rating-list-10'+node.index" title="5 stars"></label>
                        <input type="radio" [id]="'rating-list-9'+node.index" value="9" disabled /><label class="half"
                            [for]="'rating-list-9'+node.index" title="4 1/2 stars"></label>
                        <input type="radio" [id]="'rating-list-8'+node.index" value="8" disabled /><label
                            [for]="'rating-list-8'+node.index" title="4 stars"></label>
                        <input type="radio" [id]="'rating-list-7'+node.index" value="7" disabled /><label class="half"
                            [for]="'rating-list-7'+node.index" title="3 1/2 stars"></label>
                        <input type="radio" [id]="'rating-list-6'+node.index" value="6" disabled /><label
                            [for]="'rating-list-6'+node.index" title="3 stars"></label>
                        <input type="radio" [id]="'rating-list-5'+node.index" value="5" disabled /><label class="half"
                            [for]="'rating-list-5'+node.index" title="2 1/2 stars"></label>
                        <input type="radio" [id]="'rating-list-4'+node.index" value="4" disabled /><label
                            [for]="'rating-list-4'+node.index" title="2 stars"></label>
                        <input type="radio" [id]="'rating-list-3'+node.index" value="3" disabled /><label class="half"
                            [for]="'rating-list-3'+node.index" title="1 1/2 stars"></label>
                        <input type="radio" [id]="'rating-list-2'+node.index" value="2" disabled /><label
                            [for]="'rating-list-2'+node.index" title="1 star"></label>
                        <input type="radio" [id]="'rating-list-1'+node.index" value="1" disabled /><label class="half"
                            [for]="'rating-list-1'+node.index" title="1/2 star"></label>
                    </fieldset>
                    <div class="rating justify-content-end" *ngIf="node.level==1">
                        <input [disabled]="node.level!=1" type="radio" [id]="'rating-list-5'+node.index"
                            (click)="node.level==1 && updateScore(node.index, 5)">
                        <label [for]="'rating-list-5'+node.index"></label>
                        <input [disabled]="node.level!=1" type="radio" [id]="'rating-list-4'+node.index"
                            (click)="node.level==1 && updateScore(node.index, 4)">
                        <label [for]="'rating-list-4'+node.index"></label>
                        <input [disabled]="node.level!=1" type="radio" [id]="'rating-list-3'+node.index"
                            (click)="node.level==1 && updateScore(node.index, 3)">
                        <label [for]="'rating-list-3'+node.index"></label>
                        <input [disabled]="node.level!=1" type="radio" [id]="'rating-list-2'+node.index"
                            (click)="node.level==1 && updateScore(node.index, 2)">
                        <label [for]="'rating-list-2'+node.index"></label>
                        <input [disabled]="node.level!=1" type="radio" [id]="'rating-list-1'+node.index"
                            (click)="node.level==1 && updateScore(node.index, 1)">
                        <label [for]="'rating-list-1'+node.index"></label>
                    </div>
                </div>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
                    (click)="updateFailModeScore(node.index)">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <div class="d-flex justify-content-around align-items-center w-100">
                    <div matTreeNodeToggle>{{node.name}}</div>
                    <fieldset class="rate" *ngIf="node.level==0">
                        <input type="radio" [id]="'rating-list-10'+node.index" value="10" disabled /><label
                            [for]="'rating-list-10'+node.index" title="5 stars"></label>
                        <input type="radio" [id]="'rating-list-9'+node.index" value="9" disabled /><label class="half"
                            [for]="'rating-list-9'+node.index" title="4 1/2 stars"></label>
                        <input type="radio" [id]="'rating-list-8'+node.index" value="8" disabled /><label
                            [for]="'rating-list-8'+node.index" title="4 stars"></label>
                        <input type="radio" [id]="'rating-list-7'+node.index" value="7" disabled /><label class="half"
                            [for]="'rating-list-7'+node.index" title="3 1/2 stars"></label>
                        <input type="radio" [id]="'rating-list-6'+node.index" value="6" disabled /><label
                            [for]="'rating-list-6'+node.index" title="3 stars"></label>
                        <input type="radio" [id]="'rating-list-5'+node.index" value="5" disabled /><label class="half"
                            [for]="'rating-list-5'+node.index" title="2 1/2 stars"></label>
                        <input type="radio" [id]="'rating-list-4'+node.index" value="4" disabled /><label
                            [for]="'rating-list-4'+node.index" title="2 stars"></label>
                        <input type="radio" [id]="'rating-list-3'+node.index" value="3" disabled /><label class="half"
                            [for]="'rating-list-3'+node.index" title="1 1/2 stars"></label>
                        <input type="radio" [id]="'rating-list-2'+node.index" value="2" disabled /><label
                            [for]="'rating-list-2'+node.index" title="1 star"></label>
                        <input type="radio" [id]="'rating-list-1'+node.index" value="1" disabled /><label class="half"
                            [for]="'rating-list-1'+node.index" title="1/2 star"></label>
                    </fieldset>
                    <div class="rating justify-content-end" *ngIf="node.level==1">
                        <input [disabled]="node.level!=1" type="radio" [id]="'rating-list-5'+node.index">
                        <label [for]="'rating-list-5'+node.index"></label>
                        <input [disabled]="node.level!=1" type="radio" [id]="'rating-list-4'+node.index">
                        <label [for]="'rating-list-4'+node.index"></label>
                        <input [disabled]="node.level!=1" type="radio" [id]="'rating-list-3'+node.index">
                        <label [for]="'rating-list-3'+node.index"></label>
                        <input [disabled]="node.level!=1" type="radio" [id]="'rating-list-2'+node.index">
                        <label [for]="'rating-list-2'+node.index"></label>
                        <input [disabled]="node.level!=1" type="radio" [id]="'rating-list-1'+node.index">
                        <label [for]="'rating-list-1'+node.index"></label>
                    </div>
                </div>
            </mat-tree-node>
        </mat-tree>
    </div>
</div>
<ng-template #techscoreformimageViewModal>
    <button mat-mini-fab color="warn" (click)="oncloseimg()"><mat-icon>close</mat-icon></button>
    <mat-carousel timings="250ms ease-in" [autoplay]="false" interval="5000" color="accent" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
      [useMouseWheel]="true" orientation="ltr">
      <mat-carousel-slide #matCarouselSlide  [image]="image.src || blobUrl+techobj.Picture"
        overlayColor="#00000040" [hideOverlay]="false"></mat-carousel-slide>
    </mat-carousel>
  </ng-template>