import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Frequency } from 'src/app/models/frequency';
import { Kpi } from 'src/app/models/kpi';
import { Kpireason } from 'src/app/models/kpireason';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import { UserManu } from 'src/app/models/userManu';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import Swal from 'sweetalert2';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-kpireasons',
  templateUrl: './kpireasons.component.html',
  styleUrls: ['./kpireasons.component.scss'],
})
export class KpireasonsComponent implements OnInit {
  @Input() kprPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<Kpireason>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  kpiId!: number;
  Kpis!: Kpi[];
  selectedkpi!: Kpi;
  kpireasonId!: number;
  plants!: Plantbygroup[];
  kpireasonForm!: FormGroup;
  formMode!: boolean;
  plantId!: number;
  frequencies!: Frequency[];
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;

  kpireasonModelVisible = false;
  languageDir = 'ltr';
  langObj: any = {};
  kpireasonColumns: string[] = [ 'kpireasonActions','plant', 'name', 'kpi',];
  kpireasonData!: MatTableDataSource<Kpireason>;
  IsAccess: any;
  IsWrite: any;
  moduleId!: any;
  pageId!:any;
  breadcrumList: any;
  constructor(
    private kpimaster: KpimasterService,
    private toaster: ToastrService,
    private platModelService: EnterPriseGroupService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private translater: TranslaterService,
    private usermanagementService: UserManagementService,
  ) {
  }
    ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    //this.getLangWords();

    this.isRead = this.kprPermissions.IsAccess;
    this.isWrite = this.kprPermissions.IsWrite;
    this.isUpdate = this.kprPermissions.IsWrite;
    this.isDelete = this.kprPermissions.IsWrite;
    this.formMode = true;
    this.kpireasonForm = this.fb.group({
      kpireasonname: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      kpi: ['', Validators.required],
      plant: ['', Validators.required],
      frequency: ['', Validators.required],
    });
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId')
    };
    this.platModelService.GetMenuAccess(key).subscribe((data: any) => {
      this.IsAccess = data.IsAccess;
      this.IsWrite = data.IsWrite;
    },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getPaginator(): void {
    setTimeout(() => {
      this.kpireasonData.paginator = this.paginator;
      this.kpireasonData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.kpireasonData.filter = filterValue.trim().toLowerCase();

    if (this.kpireasonData.paginator) {
      this.kpireasonData.paginator.firstPage();
    }
  }
  // getKpi(): any {
  //   const key = {
  //     plantId: this.plantId,
  //   };
  //   this.kpimaster.getKpi(key).subscribe((data) => {
  //     this.Kpis = data;
  //   });
  // }

  getPlants(): void {
    this.kpimaster.getAllPlants().subscribe(
      (plants) => {
        this.plants = plants;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getFrequencies(): void {
    const key = {
      plantId: this.plantId,
    };
    this.kpimaster.getMasterFrequencies(key).subscribe(
      (frequencies) => {
        this.frequencies = frequencies;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  onFrequencyChange(event: any): any {
    const key = {
      plantId: this.plantId,
      frequencyId: this.kpireasonForm.value.frequency,
    };
    this.kpimaster.getKpiFrequency(key).subscribe(
      (kpis) => {
        this.Kpis = kpis;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  openModal(): void {
    if (this.isWrite) {
      this.resetForm();
      this.getFrequencies();
      this.Kpis = [];
      this.kpireasonForm.get('plant')?.setValue(this.plantId);
      this.kpireasonModelVisible = true;
      this.formMode = true;
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  getMasterKpiReason(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.kpimaster.getMasterKpiReason(key).subscribe(
      (Kpidata) => {

        this.kpireasonData = new MatTableDataSource<Kpireason>(Kpidata);
        this.getPaginator();
        this.getPlants();
        this.getFrequencies();
        this.spinner.hide();

      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getKpiReason(): void {
    this.spinner.show();

    const key = {
      plantId: this.plantId,
    };
    this.kpimaster.getMasterKpiReason(key).subscribe(
      (data) => {
        this.spinner.hide();

        this.kpireasonData = new MatTableDataSource<Kpireason>(data);
        this.getPaginator();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getOneKpiReason(kpiReasonId: number): void {
    if (this.isUpdate) {
      const key = {
        kpiReasonId,
      };
      this.kpimaster.getKpiReason(key).subscribe(
        (data) => {
          this.kpireasonId = kpiReasonId;
          this.kpireasonForm.get('kpireasonname')?.setValue(data.name);
          this.getPlants();
          this.getFrequencies();
          this.kpireasonForm.get('plant')?.setValue(this.plantId);
          this.kpireasonForm.get('frequency')?.setValue(data.frequencyId);
          const d = {
            plantId: this.plantId,
            frequencyId: data.frequencyId,
          };

          this.kpimaster.getKpiFrequency(d).subscribe(
            (kpis) => {
              this.Kpis = kpis;
              const kpiObj = this.Kpis.find(
                (x) => x.kpiId === parseInt(data.kpiId, 10)
              );
              this.kpireasonForm.get('kpi')?.setValue(kpiObj?.kpiId);
            },
            (error) => {
              this.toaster.error('Error', error.error.message);
            }
          );
          this.formMode = false;
          this.kpireasonModelVisible = true;
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  submitForm(): void {
    this.spinner.show();

    const kpiReason = {
      plantId: this.kpireasonForm.value.plant,
      kpiId: this.kpireasonForm.value.kpi,
      name: this.kpireasonForm.value.kpireasonname,
    };
    this.kpimaster.addKpiReason(kpiReason).subscribe(
      (response) => {
        this.spinner.hide();

        this.toaster.success('Success', 'Kpi Reason Added Successfully');
        this.getKpiReason();
        this.closeKpiReasonModel();
        this.resetForm();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }
  updateForm(): void {
    this.spinner.show();

    const kpiReason = {
      kpiReasonId: this.kpireasonId,
      plantId: this.kpireasonForm.value.plant,
      kpiId: this.kpireasonForm.value.kpi,
      name: this.kpireasonForm.value.kpireasonname,
    };
    this.kpimaster.updateKpiReason(kpiReason).subscribe(
      (response) => {
        this.spinner.hide();

        this.toaster.success('Success', 'Kpi Reason Updated Successfully');
        this.getKpiReason();
        this.closeKpiReasonModel();
        this.resetForm();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }
  deleteKpiReason(kpiReasonId: number): void {
    if (this.isDelete) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete Kpi reason!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          const key = {
            kpiReasonId,
          };
          this.kpimaster.deleteKpiReason(key).subscribe(
            (success) => {
              Swal.fire({
                title: 'Deleted!',
                text: 'Your selected Kpi Reason has been deleted.',
                icon: 'success',
                timer: 800,
                showConfirmButton: false,
              });
          this.spinner.hide();

              this.getKpiReason();
            },
            (error) => {
          this.spinner.hide();

              this.toaster.error('Error', error.error.message);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your frequency is safe :)', 'error');
        }
      });
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }
  resetForm(): void {
    this.kpireasonForm.reset();
  }
  closeKpiReasonModel(): void {
    this.kpireasonModelVisible = false;
  }
}
