import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Tool } from 'src/app/models/tool';
import { Action } from 'src/app/models/action';
import { Frequency } from 'src/app/models/frequency';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { FourM } from '../../models/fourM';
import { Kpireason } from 'src/app/models/kpireason';
import { ScorecardService } from 'src/app/services/scorecard.service';
import { LossanalysisService } from 'src/app/services/lossanalysis.service';
import { UserManu } from 'src/app/models/userManu';
import * as _moment from 'moment';
const moment = _moment;
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { TranslaterService } from 'src/app/services/translater.service';

@Component({
  selector: 'app-scorecard',
  templateUrl: './scorecard.component.html',
  styleUrls: ['./scorecard.component.scss'],
  // encapsulation: ViewEncapsulation.Emulated,
})
export class ScorecardComponent implements OnInit {
  @ViewChild('addAnalysisModelVisible')
  private addAnalysisModelVisibleTbl!: TemplateRef<any>;
  filterdraci: any;
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;
  groupList: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  lang: any;
  firstDayOfWeek: any;

  constructor(
    private kpiMaster: KpimasterService,
    private kpiservice: KpitreeService,
    private toaster: ToastrService,
    private scorecardservice: ScorecardService,
    private fb: FormBuilder,
    private lossAnalysisService: LossanalysisService,
    private usermanagementService: UserManagementService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public translater: TranslaterService,
  ) {
  }
  LossAnalysisForm!: FormGroup;
  searchScorecardForm!: FormGroup;
  searchPlants!: any[];
  frequencies!: Frequency[];
  columnDefs!: any;
  events: string[] = [];
  isUpdate = false;
  scoreCardDate!: any;
  scoreCardFormMode = true;
  rowData!: any[];
  Frequency!: any;
  field: Action[] = [];
  toolslist!: Tool[];
  fourMList!: FourM[];
  toolPath!: string;
  toolName!: string;
  reasonslist!: Kpireason[];
  VisibleScoreCardTarget = false;
  actionlist: Action[] = [];
  userMenu!: UserManu[];
  currentDate!: any;
  myArray: any[] = [];
  freqCheck!: boolean;
  scorecardPermission!: UserManu;
  addAnalysisModelVisible = false;
  editAnalysisModelVisible = false;
  analysisDate!: any;
  lossAnalysisId!: number;
  KPIId!: number;
  raci!: any;
  UserId!: number;
  calendar_en: any;
  resposibleUsers!: any;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    // kpiId: new FormControl()
  });
  lossAnalysisList!: any;
  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  newAttribute: Action = {
    action: '',
    targetDate: '',
    resposible: '',
    accountableUser: '',
    actionId: 0,
    lossAnalysisId: 0,
  };
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    rowBuffer: 300,
    popupParent: document.querySelector('body'),
    floatingFilter: true,
  };
  closeFrequencyModel(): void {
    this.addAnalysisModelVisible = false;
  }
  closeEditAnalysisModal(): void {
    this.editAnalysisModelVisible = false;
  }
  generateColumns(data: any[]): any {
    let columnDefinitions: any[] = [];

    data.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key, // .toUpperCase()
          field: key,
          resizable: true,
          wrapText: true,
          hide: false,
          cellStyle: {},
          width: 125,
          filter: true,
          onCellClicked: {},
        };
        // tslint:disable-next-line:only-arrow-functions
        mappedColumn.cellStyle = (params: any): any => {
          if (
            key !== 'Type' &&
            key !== 'KPI Name' &&
            key !== 'Indicator' &&
            key !== 'Trend' &&
            key !== 'Owner' &&
            key !== 'Last Month' &&
            key !== 'KPICode' &&
            key !== 'Previous' &&
            key !== 'MTD' &&
            key !== 'YTD' &&
            key !== 'KPIId'
          ) {
            const styleObj = {
              'font-weight': 'bold',
              'background-color': '',
              color: '',
            };
            if (params.data.Type === 'Actual') {
              const currentActualValue = params.value;
              this.gridOptions.api.forEachLeafNode((rowNode, index) => {
                // console.log('node ' + rowNode + ' is not a group!');
                if (
                  rowNode.data.KPIId === params.data.KPIId &&
                  rowNode.data.Type === 'Glidepath'
                ) {
                  // rowNode.data.Gaps = 10;
                  // console.log(rowNode.data);
                  if (params.api.getRowNode(index) !== undefined) {
                    // const previousGildepath = params.api.getRowNode(params.node.rowIndex - 1);
                    const rowGlidepath = params.api.getRowNode(index).data;
                    if (
                      rowGlidepath[params.colDef.field] !==
                      (undefined || null) &&
                      // tslint:disable-next-line:max-line-length
                      parseFloat(currentActualValue) >
                      parseFloat(rowGlidepath[params.colDef.field]) &&
                      currentActualValue && // && parseInt(currentActualValue, 10) !== 0
                      rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                    ) {
                      // console.log(params.data.KPIName, 'rowGlidepath', rowGlidepath);
                      // console.log(params.data.KPIName, 'rowActual', params.data);
                      if (params.data?.Trend === 'Up') {
                        styleObj['background-color'] = 'green';
                        styleObj.color = 'white';
                      } else {
                        styleObj['background-color'] = 'red';
                        styleObj.color = 'white';
                      }
                      // console.log(styleObj);
                      // tslint:disable-next-line:max-line-length
                    } else if (
                      rowGlidepath[params.colDef.field] !==
                      (undefined || null) &&
                      // tslint:disable-next-line:max-line-length
                      parseFloat(currentActualValue) <
                      parseFloat(rowGlidepath[params.colDef.field]) &&
                      currentActualValue && // && parseInt(currentActualValue, 10) !== 0
                      rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                    ) {
                      // console.log(params.data.KPIName, 'rowGlidepath2', rowGlidepath);
                      // console.log(params.data.KPIName, 'rowActual2', params.data);
                      if (params.data?.Trend === 'Down') {
                        styleObj['background-color'] = 'green';
                        styleObj.color = 'white';
                      } else {
                        styleObj['background-color'] = 'red';
                        styleObj.color = 'white';
                      }
                    } else if (
                      rowGlidepath[params.colDef.field] !==
                      (undefined || null) &&
                      parseFloat(currentActualValue) === 0 &&
                      parseFloat(rowGlidepath[params.colDef.field]) === 0 &&
                      rowGlidepath.Type === 'Glidepath' // && params.data.KPIId === rowGlidepath.KPIId
                    ) {
                      styleObj['background-color'] = 'green';
                      styleObj.color = 'white';
                    } else if (
                      rowGlidepath[params.colDef.field] !==
                      (undefined || null) &&
                      parseFloat(currentActualValue) ===
                      parseFloat(rowGlidepath[params.colDef.field]) &&
                      rowGlidepath.Type === 'Glidepath'
                    ) {
                      styleObj['background-color'] = 'green';
                      styleObj.color = 'white';
                    }
                  }
                }
              });
            } else {
              styleObj['background-color'] = 'yellow';
            }
            return styleObj;
          }
          if (key === 'Type' && params.data.Type === 'Actual') {
            return { 'font-weight': 'bold', color: '#009688' };
          } else if (key === 'Type' && params.data.Type === 'Glidepath') {
            return { 'font-weight': 'bold', color: '#bf360c' };
          }
        };
        if (key === 'KPI Name' || key === 'Owner') {
          mappedColumn.width = 200;
        }
        if (key === 'KPIId' || key === 'KPICode') {
          // params.api.columnController.setColumnVisible('KPIId', false);
          mappedColumn.hide = true;
        }
        columnDefinitions.push(mappedColumn);
      });
    });
    // Remove duplicate columns
    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    return columnDefinitions;
  }

  ngOnInit(): void {
    this.addAnalysisModelVisible = false;
    this.editAnalysisModelVisible = false;
    this.scoreCardFormMode = true;
    const listMenu = localStorage.getItem('result');
    const permissions = listMenu !== null ? JSON.parse(listMenu) : [];
    // this.scorecardPermission = permissions.find(
    //   (x: { ParentName: string; MenuName: string; Actions: string }) =>
    //     x.ParentName === 'Performance Management' &&
    //     x.MenuName === 'Scorecard' &&
    //     x.Actions === 'Scorecard'
    // );
    this.searchScorecardForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      frequency: ['', Validators.required],
      scorecardDate: ['', Validators.required],
    });
    this.LossAnalysisForm = this.fb.group({
      //reasonname: ['', Validators.required],
      remarks: [''],
      rootcause: [''],
      //toolname: ['', Validators.required],
      //fourm: [''],
      lossAnalysisDate: ['', Validators.required],
    });

    this.actionlist = [];
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroupData();
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  compareFn(user1: any, user2: any): any {
    return user1 && user2
      ? user1 === parseInt(user2, 10)
      : user1 === parseInt(user2, 10);
  }
  onToolChange(event: any): void {
    const tool = this.LossAnalysisForm.value.toolname;
    this.toolPath = tool.filePath;
    this.toolName = tool.filename;
  }

  addFieldValue(): void {
    const lastObj = this.actionlist[this.actionlist.length - 1];
    if (this.actionlist.length <= 0) {
      this.actionlist.push(this.newAttribute);
      this.newAttribute = {
        action: '',
        targetDate: '',
        accountableUser: '',
        resposible: '',
        actionId: 0,
        lossAnalysisId: 0,
      };
    }

    if (this.actionlist.length > 0 && lastObj.targetDate && lastObj.action) {
      this.actionlist.push(this.newAttribute);
      this.newAttribute = {
        action: '',
        targetDate: '',
        accountableUser: '',
        resposible: '',
        actionId: 0,
        lossAnalysisId: 0,
      };
    } else {
      this.toaster.warning('Please fill Action & Target Date');
    }
  }
  onSubmit(): void {
    const filtereddata = this.actionlist.filter(
      (x) => x.action === '' || x.targetDate === ''
    );

    if (filtereddata.length === 0) {
      const Data = {
        reasonId: 0,
        toolId: 0,
        remarks: this.LossAnalysisForm.value.remarks,
        rootCause: this.LossAnalysisForm.value.rootcause,
        lossAnalysisDate: moment(
          this.LossAnalysisForm.value.lossAnalysisDate
        ).format('YYYY-MM-DD'),
        fourMId: 0,
        action: this.actionlist,
        kpiId: this.KPIId, // dateObject,
      };

      this.lossAnalysisService.addLossAnalysis(Data).subscribe(
        (response: any) => {
          this.toaster.success(
            'Success',
            'LossAnalysis Data Added Successfuly'
          );
          this.addAnalysisModelVisible = false;
        },
        (error: any) => {
          this.toaster.error('error', error.error.message);
        }
      );
    } else {
      this.toaster.warning('Please Select Actions And Target Dates');
    }
  }

  updateForm(): void {
    const filtereddata = this.actionlist.filter(
      (x) => x.action === '' || x.targetDate === ''
    );

    if (filtereddata.length === 0) {
      const Data = {
        reasonId: 0,
        toolId: 0,
        remarks: this.LossAnalysisForm.value.remarks,
        rootCause: this.LossAnalysisForm.value.rootcause,
        lossAnalysisDate: moment(
          this.LossAnalysisForm.value.lossAnalysisDate
        ).format('YYYY-MM-DD'),
        fourMId: 0,
        action: this.actionlist,
        kpiId: this.KPIId,
        lossAnalysisId: this.lossAnalysisId,
      };

      this.lossAnalysisService.updateLossAnalysis(Data).subscribe(
        (response: any) => {
          this.toaster.success(
            'Success',
            'LossAnalysis Data Updated Successfuly'
          );
          this.editAnalysisModelVisible = false;
        },
        (error: any) => {
          this.toaster.error('error', error.message);
        }
      );
    } else {
      this.toaster.warning('Please Select Actions And Target Dates');
    }
  }
  fielArrayValueChange(event: any, index: number): void {
    const field = this.actionlist[index];
    field.action = event.target.value;
  }
  accountbleChange(event: any, index: number): void {
    const field = this.actionlist[index];
    field.accountableUser = event.value;
  }
  searchLossAnalysis(): any {
    this.LossAnalysisForm.reset();
    this.actionlist = [];
    const keys = {
      kpiId: this.KPIId,
      fromDate: moment(this.range.value.start).format('YYYY-MM-DD'), // new Date(this.range.value.start),
      toDate: moment(this.range.value.end).format('YYYY-MM-DD'), // new Date(this.range.value.end)
    };
    this.scorecardservice.lossanalysisdatewise(keys).subscribe((data: any) => {
      this.lossAnalysisList = [];
      this.lossAnalysisList = data;
    });
  }
  getOneLossAnalysis(event: any): any {
    const keys = {
      lossAnalysisId: event.value.lossAnalysisId,
    };
    this.lossAnalysisId = event.value.lossAnalysisId;
    this.scorecardservice.lossanalysisdata(keys).subscribe(
      // tslint:disable-next-line: no-shadowed-variable
      (data: any) => {
        data = data[0];
        if (data !== null) {
          this.LossAnalysisForm.get('remarks')?.setValue(data.remarks);
          this.LossAnalysisForm.get('rootcause')?.setValue(data.rootCause);

          const reasonObj = this.reasonslist.find(
            (x) => x.kpiReasonId === parseInt(data.reason, 10)
          );
          const fourObj = this.fourMList.find(
            (x) => x.fourMId === parseInt(data.fourM, 10)
          );
          const toolObj = this.toolslist.find(
            (x) => x.toolId === parseInt(data.tool, 10)
          );

          this.LossAnalysisForm.get('reasonname')?.setValue(reasonObj);
          this.LossAnalysisForm.get('toolname')?.setValue(toolObj);
          this.LossAnalysisForm.get('fourm')?.setValue(fourObj);
          const targetDates = data.actions;

          targetDates.forEach((element: any) => {
            if (element.hasOwnProperty('accountableUser')) {
              element.accountableUser = element.accountableUser.split(',');
            }
          });
          this.actionlist = targetDates;

          this.LossAnalysisForm.get('lossAnalysisDate')?.setValue(
            data.analysisDate
          );
          this.lossAnalysisId = event.value.lossAnalysisId;
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  fielDateValueChange(event: any, index: number): void {
    const field = this.actionlist[index];
    field.targetDate = event.value;
  }

  addEvent(type: string, event: any, index: number): void {
    const field = this.actionlist[index];
    field.targetDate = moment(event.value).format('YYYY-MM-DD');
  }

  deleteFieldValue(index: any): void {
    this.actionlist.splice(index, 1);
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };

    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        const obj = this.groupList[0];
        this.searchScorecardForm.get('group')?.setValue(obj);
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlants(): void {
    const key = {
      GroupId: this.searchScorecardForm.value.group.TypeId,
    };
    this.usermanagementService.getDepartments(key).subscribe(
      (res: any) => {
        this.searchPlants = res.DepartList;
        const plantObject = this.searchPlants[0];
        this.searchScorecardForm.get('plant')?.setValue(plantObject);
        this.getUserList();
        this.getMasterData(plantObject);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  SetFirstDayofWeek(): void {
    const dept = this.searchPlants.find(item => item.DeptId === this.searchScorecardForm.value.plant);
    this.firstDayOfWeek = dept ? dept.firstDayOfWeek : 0;
    this.calendar_en = {
      closeText: 'Close',
      prevText: 'Previous',
      nextText: 'Next',
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      weekHeader: 'Week',
      weekNumberTitle: 'W',
      firstDayOfWeek: this.firstDayOfWeek,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: '',
      timeOnlyTitle: 'Only Time',
      timeText: 'Time',
      hourText: 'Hour',
      minuteText: 'Minute',
      secondText: 'Second',
      currentText: 'Current Date',
      ampm: false,
      year: 'Year',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      list: 'Agenda',
      allDayText: 'All Day',
      moreLinkText: 'More...',
      noEventsText: 'No Events',
      today: 'Today',
      clear: 'Clear',

    };
    this.currentDate = new Date();
    this.currentDate.setMonth(this.currentDate.getMonth());
  }


  onFrequencyChange(): void {
    let date;
    if (this.searchScorecardForm.value.scorecardDate !== undefined) {
      date = this.searchScorecardForm.value.scorecardDate;
    } else {
      date = new Date();
    }
    this.onDateChange(date);
  }
  onDateChange(value: any): any {
    const getdata = {
      date: getSqlDate(value),
      plantId: this.searchScorecardForm.value.plant.DeptId,
      frequencyId: this.searchScorecardForm.value.frequency.frequencyId,
      frequencyName: this.searchScorecardForm.value.frequency.name,
      userId: JSON.parse(localStorage.user).userId,
    };
    this.spinner.show();

    if (getdata.plantId === undefined) {
      this.toaster.warning('Warning', 'Please Select Plant');
      this.spinner.hide();

      return;
    }
    this.scorecardservice.getScorecard(getdata).subscribe(
      (response: any) => {
        //this.rowData = response;


        // let filteredResponse = [];

        // response.forEach(element => {
        //   if (filteredResponse.filter(e => e.KPICode === element.KPICode && e.Type===element.Type).length == 0 ) {
        //     filteredResponse.push(element);
        //   }

        // });
        this.rowData = response;
        this.spinner.hide();

        if (this.rowData) {
          this.columnDefs = this.generateColumns(this.rowData);
        }
        setTimeout(() => {
          this.gridOptions.api.columnController.resetColumnState();
        }, 100);
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getMasterData(event: any): any {
    this.getUserList();
    this.SetFirstDayofWeek();
    const key = {
      plantId: this.searchScorecardForm.value.plant.DeptId,
    };
    this.spinner.show();
    this.kpiMaster.getMasterFrequencies(key).subscribe(
      (frequency) => {
        this.frequencies = frequency;
        this.searchScorecardForm
          .get('frequency')
          ?.setValue(this.frequencies.find((d) => d.name === 'Yearly'));
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
    this.kpiMaster.getMasterTools(key).subscribe(
      (tools) => {
        this.toolslist = tools;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
    this.kpiMaster.getMasterFourM(key).subscribe(
      (data) => {
        this.fourMList = data;
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );

    setTimeout(() => {
      this.onFrequencyChange();
    }, 1000);
  }
  getMasterKpiReason(): void {
    const key = {
      plantId: this.searchScorecardForm.value.plant.DeptId,
      kpiId: this.KPIId,
    };
    this.lossAnalysisService.getMasterReason(key).subscribe(
      (data) => {
        this.reasonslist = data;
      },
      (error) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  public isFiltered(multiselectOption: any): any {
    return this.filterdraci.find(
      (item: any) => item.EmpInfo_Id === multiselectOption.EmpInfo_Id
    );
  }

  getUserList(): void {
    const key = {
      EGId: this.searchScorecardForm.value.group.TypeId,
      PlantId: this.searchScorecardForm.value.plant.DeptId,
    };
    this.kpiservice.getEmployeeMasterData(key).then(
      (users) => {
        this.raci = users.employeeInfoList;
        this.raci.forEach((element: any) => {
          element.fullName = element.FirstName + ' ' + element.lastName;
        });
        this.filterdraci = this.raci.slice();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getContextMenuItems = (params: any) => {
    // const that = this;
    if (params.node.data.Type === 'Actual') {
      const colorElements = document.getElementsByClassName(
        'ag-cell-focus'
      ) as HTMLCollectionOf<HTMLElement>;
      const backgroundColor = colorElements[0].style.backgroundColor;
      let result: any;
      if (backgroundColor === 'red') {
        if (this.IsWrite) {
          result = [
            {
              name:
                'Add Action Analysis (' + params.node.data['KPI Name'] + ')',
              action: () => {
                this.LossAnalysisForm.reset();
                this.actionlist = [];
                // this.scoreCardFormMode = true;
                this.addAnalysisModelVisible = true;
                this.KPIId = params.node.data.KPIId;
                this.resposibleUsers = params.node.data.Owner;
                this.getMasterKpiReason();
              },
            },
            {
              name:
                'Edit Action Analysis (' + params.node.data['KPI Name'] + ')',
              action: () => {
                this.LossAnalysisForm.reset();
                this.actionlist = [];
                this.lossAnalysisList = [];
                this.range.reset();
                this.editAnalysisModelVisible = true;
                this.KPIId = params.node.data.KPIId;
                this.resposibleUsers = params.node.data.Owner;
                this.getMasterKpiReason();
              },
            },
          ];
        } else {
          this.toaster.error('Alert!', 'you do not have permission to access');
        }
      } else {
        if (this.IsWrite) {
          result = [
            {
              name:
                'Edit Action Analysis (' + params.node.data['KPI Name'] + ')',
              action: () => {
                this.LossAnalysisForm.reset();
                this.actionlist = [];
                this.lossAnalysisList = [];
                this.editAnalysisModelVisible = true;
                this.KPIId = params.node.data.KPIId;
              },
            },
          ];
        } else {
          this.toaster.error('Alert!', 'you do not have permission to access');
        }
      }
      return result;
    }
  };
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
function getSqlDate(value: any): any {
  return (
    value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate()
  );
}

function formatDate(date: any): any {
  const date_ob = new Date(date);
  const day = ('0' + date_ob.getDate()).slice(-2);

  // current month
  const month = ('0' + (date_ob.getMonth() + 1)).slice(-2);

  // current year
  const year = date_ob.getFullYear();

  return day + '/' + month + '/' + year;
}