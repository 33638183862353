import { Component, OnInit } from '@angular/core';
import { PrimeCellEditorComponent } from '../../editors/prime-cell-editor.component';
import { CustomDateComponent } from '../../date-components/custom-date-component.component';
import { BtnCellRendererActualEntry } from '../../btn-cell-rendererActualEntry.component';
import { BtnCellRenderer } from '../../btn-cell-renderer.component';
import { Kpi } from '../../models/kpi';
import { ToastrService } from 'ngx-toastr';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { Frequency } from 'src/app/models/frequency';
import { Driver } from 'src/app/models/driver';
import { Router } from '@angular/router';
import { Pillar } from 'src/app/models/pillar';
import { Department } from 'src/app/models/department';
import { UserPlant } from 'src/app/models/userPlant';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Tool } from 'src/app/models/tool';
import { FourM } from 'src/app/models/fourM';
import { ScorecardService } from 'src/app/services/scorecard.service';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslaterService } from 'src/app/services/translater.service';
const moment = _moment;

@Component({
  selector: 'app-individual-performance-evaluation',
  templateUrl: './individual-performance-evaluation.component.html',
  styleUrls: ['./individual-performance-evaluation.component.scss'],
})
export class IndividualPerformanceEvaluationComponent implements OnInit {
  actionAnalysisForm!: FormGroup;
  toolslist!: Tool[];
  fourMList!: FourM[];
  rowData!: any[];
  plants!: UserPlant[];
  searchPlants!: UserPlant[];
  kpifrequencies!: Frequency[];
  kpidata!: Kpi[];
  components!: any;
  pillars!: Pillar[];
  gridColumnApiEdit: any;
  users!: any;
  frequencies!: Frequency[];
  kpiDriver!: Driver[];
  kpidepartments: string[] = [];
  departments!: Department[];
  kpiPillar: string[] = [];
  targetActualEntryDate!: Date;
  kpifrequency!: string;
  kpiTitle!: string;
  drivers!: Driver[];
  roles!: any;
  gridApi: any;
  actionDate!: Date;
  gridColumnApi: any;
  columnDefs: any;
  startYear: any;
  endYear: any;
  completionDate = true;
  cellData: any;
  languageDir = 'ltr';
  langObj: any = {};
  calendar_en = {
    closeText: 'Close',
    prevText: 'Previous',
    nextText: 'Next',
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    weekHeader: 'Week',
    weekNumberTitle: 'W',
    firstDayOfWeek: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: '',
    timeOnlyTitle: 'Only Time',
    timeText: 'Time',
    hourText: 'Hour',
    minuteText: 'Minute',
    secondText: 'Second',
    currentText: 'Current Date',
    ampm: false,
    year: 'Year',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    list: 'Agenda',
    allDayText: 'All Day',
    moreLinkText: 'More...',
    noEventsText: 'No Events',
    today: 'Today',
    clear: 'Clear',
  };
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    components: { genderCellRenderer: GenderCellRenderer },
    frameworkComponents: {
      agDateInput: CustomDateComponent,
      primeCellEditor: PrimeCellEditorComponent,
      btnCellRendererActualEntry: BtnCellRendererActualEntry,
      btnCellRenderer: BtnCellRenderer,
    },
  };
  IsWrite: any;
  IsAccess: any;
  breadcrumList: any;
  groupList: any;
  moduleId!: any;
  pageId!: any;
  constructor(
    private toaster: ToastrService,
    private scoreService: ScorecardService,
    private fb: FormBuilder,
    public router: Router,
    private kpiMaster: KpimasterService,
    private userService: UserManagementService,
    private spinner: NgxSpinnerService,
    private kpiservice: KpitreeService,
    private translater: TranslaterService,
  ) {
    this.getGroupData();
  }

  ngOnInit(): void {
    //this.getLangWords();
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.actionAnalysisForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
      frequency: ['', Validators.required],
      toDate: ['', Validators.required],
      fromDate: ['', Validators.required],
      role: ['', Validators.required],
      user: ['', Validators.required],
      // kpi: ['', Validators.required],
    });

    const today = new Date().getFullYear();
    this.startYear = new Date(today, 0);
    this.endYear = new Date(today, 11, 31);
    const listmenu = localStorage.getItem('result');
    const permissions = listmenu !== null ? JSON.parse(listmenu) : [];
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.userService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        const obj = this.groupList[0];
        this.actionAnalysisForm.get('group')?.setValue(obj);
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlants(): void {
    const key = {
      GroupId: this.actionAnalysisForm.value.group.TypeId,
    };
    this.userService.getDepartments(key).subscribe(
      (res: any) => {
        this.searchPlants = res.DepartList;
        const plantObject = this.searchPlants[0];
        this.actionAnalysisForm.get('plant')?.setValue(plantObject);
        this.getMasterData();
        this.getRoles();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  getUserList(): any {
    const key = {
      EGId: this.actionAnalysisForm.value.group.TypeId,
      PlantId: this.actionAnalysisForm.value.plant.DeptId,
      roleId: this.actionAnalysisForm.value.role.POS_Id,
    };
    this.kpiservice.getPositionMappedUsers(key).then(
      (users: any) => {
        this.users = users;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
    setTimeout(() => { }, 1000);
  }

  // onDateChange(value: any): any {
  //   const getdata = {
  //     toDate: this.actionAnalysisForm.value.toDate,
  //     fromDate: this.actionAnalysisForm.value.fromDate,
  //     plantId: this.actionAnalysisForm.value.plant.DeptId,
  //   };

  //   this.kpiDriver = [];
  //   this.kpiPillar = [];
  //   this.kpidepartments = [];
  //   if (getdata.plantId === undefined) {
  //     this.toaster.warning('Warning', 'Please Select Plant');
  //     return;
  //   }
  //   this.kpiMaster.getActionAnalysis(getdata).subscribe(
  //     (response: any) => {
  //       console.log(this.kpifrequencies);
  //       this.gridApi.setRowData(response);
  //     },
  //     (error: any) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }

  getMasterData(): any {
    this.getRoles();
    this.actionAnalysisForm.get('user')?.reset();
    this.spinner.show();
    const key = {
      plantId: this.actionAnalysisForm.value.plant.DeptId,
    };
    this.kpiMaster.getMasterFrequencies(key).subscribe(
      (frequency) => {
        this.spinner.hide();
        this.frequencies = frequency;
        this.actionAnalysisForm
          .get('frequency')
          ?.setValue(this.frequencies.find((d) => d.name === 'Yearly'));
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
    // setTimeout(() => {
    //   this.onFrequencyChange();
    // }, 1000);
  }

  getIPEGrid(): any {
    const plant = {
      ptId: this.actionAnalysisForm.value.plant.DeptId,
      userId: this.actionAnalysisForm.value.user.EmpInfo_Id,
      fromDate: moment(this.actionAnalysisForm.value.fromDate).format(
        'YYYY-MM-DD'
      ),
      toDate: moment(this.actionAnalysisForm.value.toDate).format('YYYY-MM-DD'),
      frequencyId: this.actionAnalysisForm.value.frequency.frequencyId,
    };
    this.spinner.show();
    this.scoreService.individualPerformanceEvaluation(plant).subscribe(
      (data: any) => {
        if (data !== undefined) {
          this.spinner.hide();
          this.rowData = data;
          if (this.rowData) {
            this.columnDefs = this.generateColumns(this.rowData);
            setTimeout(() => {
              this.gridOptions.api.columnController.resetColumnState();
              const instance = this.gridApi.getFilterInstance('Type');
              const myArray: any = ['Achivement', 'Weight'];
              instance.setModel({
                values: myArray.length > 0 ? myArray : null,
              });
              this.gridApi.onFilterChanged();
            }, 100);
          }
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('error', error.error.message);
      }
    );
  }

  //  getAllPlants (): void {
  //     const key = {
  //       userId: JSON.parse(localStorage.user).UserId,
  //     };
  //     this.kpiMaster.getUserPlants(key).subscribe(
  //       (plants) => {
  //         this.searchPlants = plants;
  //         this.plants = plants;
  //       },
  //       (error) => {
  //         this.toaster.error('Error', error.message);
  //       }
  //     );
  //   }
  getRoles(): any {
    const key = {
      EGId: this.actionAnalysisForm.value.group.TypeId,
      PlantId: this.actionAnalysisForm.value.plant.DeptId,
    };
    this.kpiservice.getPositionMasterData(key).then(
      (responsibleroles: any) => {
        this.roles = responsibleroles.positionMasterList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // getRoles(): any {
  //   const key = {
  //     egId: this.actionAnalysisForm.value.group.TypeId
  //   };
  //   this.userService.masterroles(key).subscribe(
  //     (data: any) => {
  //       this.roles = data;
  //     },
  //     (error: any) => {
  //       this.toaster.error('Error', error.error.message);
  //     }
  //   );
  // }

  generateColumns(data: any[]): any {
    let columnDefinitions: any[] = [];

    data.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key, // .toUpperCase(),
          headerClass: {},
          field: key,
          width: 170,
          rowGroup: false,
          rowSpan: (params: any): any => {
            // if (key === 'Name' || key === 'Roles' || key === 'Image') {
            //   return 4;
            // } else
            if (key === 'Level' || key === 'Evaluation') {
              return 1;
            } else {
              return;
            }
          },
          // height: 200,
          // suppressSizeToFit: true,
          valueFormatter: this.bracketsFormatter,
          filter: true,
          resizable: true,
          wrapText: true, //W autoHeight: true,
          cellRenderer:
            key === 'Image'
              ? (params: any): any => {
                return params.value
                  ? '<img style="height:40px;width:40px" src="' +
                  params.value +
                  '" />'
                  : '<img style="height:40px;width:40px" src="assets/images/no-image.png" />';
              }
              : null,
          cellStyle: {},
        };
        mappedColumn.cellStyle = (params: any): any => {
          if (
            key === 'Image' ||
            key === 'Name' ||
            key === 'Roles' ||
            key === 'Type' ||
            key === 'Total'
          ) {
            return { 'font-weight': 'bold' };
          }
          if (key === 'Evaluation' || key === 'Level') {
            if (params.data.Type === 'Achivement') {
              return {
                'font-weight': 'bold',
                color: 'black',
                'background-color': 'yellow',
              };
            }
          } else {
            if (params.data.Type === 'Achivement') {
              if (params.value > 0) {
                return {
                  'font-weight': 'bold',
                  color: 'white',
                  'background-color': 'green',
                };
              } else if (params.value < 0 && params.value != null) {
                return {
                  'font-weight': 'bold',
                  color: 'white',
                  'background-color': 'red',
                };
              }
            }
          }
        };
        columnDefinitions.push(mappedColumn);
      });
    });

    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );

    return columnDefinitions;
  }
  bracketsFormatter(params: any): any {
    if (params.data.Type === 'Achivement' || params.data.Type === 'Weight') {
      if (params.value < 0 && params.value != null) {
        return -1 * params.value.toFixed(2) + ' %';
      } else if (params.value > 0) {
        return params.value.toFixed(2) + ' %';
      }
    }
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
function myDateComparator(filterLocalDateAtMidnight: any, cellValue: any): any {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}

function GenderCellRenderer(): void { }

GenderCellRenderer.prototype.init = function (params: any): void {
  this.eGui = document.createElement('span');
  if (
    params.value !== '' ||
    params.value !== undefined ||
    params.value !== null
  ) {
    this.eGui.innerHTML = params.value;
  }
};

GenderCellRenderer.prototype.getGui = function (): any {
  return this.eGui;
};
