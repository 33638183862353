import { NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  PlantGroupElement,
  PlantModelElement,
  MachineParameterVariableElement,
} from 'src/app/models/O3AdministrationModels/plant-model';
import { MachineParameterElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { QualityParameterService } from 'src/app/services/quality-parameter.service';
import { MachineParamtersService } from 'src/app/services/machine-paramters.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { style } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-quality-parameter',
  templateUrl: './quality-parameter.component.html',
  styleUrls: ['./quality-parameter.component.scss'],
})
export class QualityParameterComponent implements OnInit {
  public linkedControl: FormControl = new FormControl();
  public filteredLinked: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  time = new Date();
  calculationhide = false;
  disabled = false;
  numberRegex = '[+-]?[0-9]+';
  isUpdateVariable = false;
  modeProperty!: boolean;
  isIntervalSaveMode!: boolean;
  changeIntervalMode!: boolean;
  inputDisabled = false;
  outputDisabled = false;
  LineNode: any;
  IsAccess: any;
  groupDataMode = true;
  IsWrite: any;
  breadcrumList: any;
  group!: any[];
  UnitNode: any;
  UnitName!: string;
  isUnitName = false;
  isUpdateGroupVariable = false;
  inputEventMode = false;
  outputEventMode = false;
  LinkProductList!: any[];
  filteredLinkProduct: any;
  unitDetailForm!: FormGroup;
  getVariableForm!: FormGroup;
  dataTypeDetailForm!: FormGroup;
  searchForm!: FormGroup;
  isSarchFormVisible = false;
  variableGroupInParameter!: any[];
  variableGroupInParameterInSpec!: any[];
  inputclr!: string;
  dataSource!: any[];
  calculationMethod!: any[];
  dataType!: any[];
  // Variable Group Form
  addNewVariableGroupItemlForm!: FormGroup;
  isaddNewVariableGroupModelVisible = false;
  addNewVariableGroupItemlFormSubmitted = false;

  // second Tab variables
  addNewVariableItemlForm!: FormGroup;
  isaddNewVariableModelVisible = false;
  addNewVariableItemlFormSubmitted = false;

  // quality interval form
  qualityParamterIntervalForm!: FormGroup;

  // third tab
  addNewVariableSpecForm!: FormGroup;
  isaddNewVariableSpecModelVisible = false;
  getVariableSpecForm!: FormGroup;
  isUpdateVariableSpec = false;
  variableListInSpec!: any[];

  // Plant Model Form
  addNewPlantModeItemlForm!: FormGroup;
  isaddNewPlantModeItemModelVisible = false;
  addNewPlantModeItemlFormSubmitted = false;
  isPlantGroupModelVisible = false;
  isPlantGroupModelVisibleInVariable = false;
  isPlantGroupModelVisibleInVariableSpec = false;

  // Table data for first tab
  machineParameterVariableGroupColumns: string[] = [
    'plantModelActions',
    'VG_Id',
    'VG_Desc',
    'PU_Desc',
    'VG_Order',
    'CreatedBy',
    'grouptype',
    'CreatedOn',
  ];
  machineParameterVariableGroupData = new MatTableDataSource<any>();

  // Table data for Second tab
  machineParameterVariableColumns: string[] = [
    'plantModelActions',
    'Var_Id',
    'Var_Desc',
    'VarOrder',
    'DS_Desc',
    'DT_Desc',
    'CreatedBy',
    'CreatedOn',
  ];
  machineParameterVariableData = new MatTableDataSource<any>();
  dataTypeDetailDataSource!: MatTableDataSource<any>;
  dataTypeDetailColumns: any[] = ['actions', 'id', 'text'];
  // Table data for third tab
  machineParameterVariableSpecListColumns: string[] = [
    'plantModelActions',
    'Parameter_Specs_Id',
    'Parameter_Id',
    'Parameter_Name',
    'Link_Product',
    'CreatedBy',
    'LRL',
    'LSL',
    'LWL',
    'TGT',
    'UWL',
    'USL',
    'URL',
  ];
  machineParameterVariableSpecList = new MatTableDataSource<any>();

  // plantTitleName: string;

  // plant group view
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();

  @ViewChild(MatTable) table!: MatTable<MachineParameterElement>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('displayQualityParamterInterval')
  private displayQualityParamterInterval!: TemplateRef<any>;
  @ViewChild('displayDataTypeDetailPopup')
  private displayDataTypeDetailPopup!: TemplateRef<any>;
  toastrService: any;
  checkDataType!: any;
  inputEventList: any[] = [];
  outputEventList: any[] = [];
  startInterval!: any;
  endInterval!: any;
  intervalId!: any;
  dataTypeDetail = false;
  variableId!: any;
  dataTypeDetailMode!: boolean;
  dataTypeId!: any;
  inputChecked = false;
  outputChecked = false;
  isSelected = false;
  groupTypeList: any;
  isEventMode = false;
  moduleId!: any;
  pageId!: any;

  constructor(
    private formBuilder: FormBuilder,
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private userService: UserManagementService,
    private machineParameterService: MachineParamtersService,
    private commonService: CommonService,
    private qualityParameterService: QualityParameterService,
    // private userManagementService: UserManagementService,
    private platModelService: EnterPriseGroupService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.linkedControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterLink();
      });
    this.GetMenuAccess();
    this.getGroup();

    this.searchForm = this.formBuilder.group({
      searchValue: ['', Validators.required],
    });

    // Filter Form
    this.unitDetailForm = this.formBuilder.group({
      groupList: [''],
      UnitDataList: [''],
      PUID: [''],
    });

    // First Tab add new Variable Group form
    this.addNewVariableGroupItemlForm = this.formBuilder.group({
      groupDescription: ['', Validators.required],
      groupOrder: [
        0,
        [Validators.required, Validators.pattern(this.numberRegex)],
      ],
      VG_Id: [''],
      groupType: [''],
      PUID: ['', Validators.required],
      inputEvent: [''],
      outputEvent: [''],
    });
    this.getVariableForm = this.formBuilder.group({
      groupList: ['', Validators.required],
      UnitDataList: ['', Validators.required],
      variableGroupList: ['', Validators.required],
      PUID: ['', Validators.required],
    });
    this.getVariableSpecForm = this.formBuilder.group({
      groupList: ['', Validators.required],
      UnitDataList: ['', Validators.required],
      variableGroupList: ['', Validators.required],
      PUID: ['', Validators.required],
    });
    // Second Tab add new Variable  form
    this.addNewVariableItemlForm = this.formBuilder.group({
      VariableGrpDesc: ['', Validators.required],
      VariableGrpOrder: [
        '',
        [Validators.required, Validators.pattern(this.numberRegex)],
      ],
      VariableGroup: [''],
      EngUnits: [''],
      parameterPrecision: [''],
      InputTag: [''],
      OutputTag: [''],
      varId: [''],
      DataSource: [''],
      DataType: ['', Validators.required],
      CalculationMethod: [''],
    });

    this.addNewVariableSpecForm = this.formBuilder.group({
      Parameter_Specs_Id: [''],
      Parameter_Id: [''],
      Desc: ['', Validators.required],
      VgId: [''],
      LinkProduct: ['', Validators.required],
      LRL: [''],
      LSL: [''],
      LWL: [''],
      TGT: [''],
      UWL: [''],
      USL: [''],
      URL: [''],
    });

    this.qualityParamterIntervalForm = this.formBuilder.group({
      // intervalStarDate: ['', Validators.required],
      // intervalEndDate: ['', Validators.required]
    });

    this.dataTypeDetailForm = this.formBuilder.group({
      text: ['', Validators.required],
    });

    this.GetBreadcrumList();
    this.getDataType();
    this.getDataSource();
    this.GetGroupTypeList();
    this.GetCalculationMethod();
  }

  filterLink(): void {
    if (!this.filteredLinkProduct) {
      return;
    }
    let search = this.linkedControl.value;
    if (!search) {
      this.filteredLinked.next(this.filteredLinkProduct);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredLinked.next(
      this.filteredLinkProduct.filter(
        (value: any) => value.TypeName.toLowerCase().indexOf(search) > -1
      )
    );
  }
  getGroup(): any {
    const key = {
      Id: 0,
    };
    this.qualityParameterService.getGroupData(key).subscribe(
      (data: any) => {
        this.group = data.masterList;
        this.unitDetailForm.get('groupList')?.setValue(this.group[0].TypeId);
        this.getVariableForm.get('groupList')?.setValue(this.group[0].TypeId);
        this.getVariableSpecForm
          .get('groupList')
          ?.setValue(this.group[0].TypeId);
      },
      (error: any) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  /// Binding
  onSearch(): void {
    const searchValue = this.searchForm.value;
    console.log('searchValue ::', searchValue);
  }
  onClickUnit(node: any): void {
    if (node.Level === 'Unit') {
      this.machineParameterVariableGroupData.data = [];
      this.machineParameterVariableData.data = [];
      this.machineParameterVariableSpecList.data = [];
      this.isPlantGroupModelVisible = false;
      this.unitDetailForm.get('UnitDataList')?.setValue(node.text);
      this.unitDetailForm.get('PUID')?.setValue(node.PrimaryId);
      this.addNewVariableGroupItemlForm.get('PUID')?.setValue(node.PrimaryId);
      this.getVariableGroupList();
      this.getVariableForm.get('UnitDataList')?.setValue(node.text);
      this.getVariableForm.get('PUID')?.setValue(node.PrimaryId);
      this.getVariableSpecForm.get('UnitDataList')?.setValue(node.text);
      this.getVariableSpecForm.get('PUID')?.setValue(node.PrimaryId);

      this.getLinkProduct();
    }
  }

  openaddNewVariableGroupModel(): void {
    this.isEventMode = false;
    this.inputDisabled = false;
    this.outputDisabled = false;
    this.modeProperty = true;
    this.inputChecked = false;
    this.outputChecked = false;
    this.inputEventMode = false;
    this.outputEventMode = false;
    if (this.unitDetailForm.get('PUID')?.value !== '') {
      this.isaddNewVariableGroupModelVisible = true;
      this.variableGroupFormReset();
      this.isUpdateGroupVariable = false;
    } else {
      this.toaster.error('Error', 'Unit is not selected!');
    }

    this.GetAllMasterEvents();
  }
  closeaddNewVariableGroupModel(): void {
    this.isaddNewVariableGroupModelVisible = false;
  }
  openUnit(): void {
    this.isPlantGroupModelVisible = true;
    const key = {
      EGId: this.unitDetailForm.value.groupList,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };
    this.commonService.getTreeModal(key).subscribe((data: any) => {
      this.plantGroupSource.data = data.TreeModalList;
      this.treeControl.dataNodes = this.plantGroupSource.data;
      this.UnitNode = filterData(this.plantGroupSource.data, 'Unit');
    });

    function filterData(data: PlantGroupElement[], title: string) {
      const r = data.filter(function (o: any) {
        Object.keys(o).forEach(function (e: any) {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
  }

  // GET ALL Master Events
  GetAllMasterEvents(): void {
    const key = {};
    this.qualityParameterService
      .GetAllMasterEvents(key)
      .subscribe((res: any) => {
        this.inputEventList = [];
        this.outputEventList = [];
        res.map((element: any) => {
          if (element.type === 'Input') {
            this.inputEventList.push(element);
            this.addNewVariableGroupItemlForm
              .get('inputEvent')
              ?.setValue(this.inputEventList[0].masterId);
          }
          if (element.type === 'Output') {
            this.outputEventList.push(element);
            this.addNewVariableGroupItemlForm
              .get('outputEvent')
              ?.setValue(this.outputEventList[0].masterId);
          }
        });
      });
  }

  getVariableGroupList(): void {
    const data = {
      PUID: this.unitDetailForm.get('PUID')?.value,
    };
    this.spinner.show();
    this.qualityParameterService.gettVariableGroupList(data).subscribe(
      (response: any) => {
        this.machineParameterVariableGroupData = new MatTableDataSource<any>(
          response
        );

        this.variableGroupInParameterInSpec = response;
        this.variableGroupInParameter = response;
        this.getPaginator(this.machineParameterVariableGroupData, 0);
        this.spinner.hide();

        if (response.length > 0) {
          this.getVariableSpecForm
            .get('variableGroupList')
            ?.setValue(response[0].VG_Id);
          this.addNewVariableSpecForm.get('VgId')?.setValue(response[0].VG_Id);
          this.getVariableForm
            .get('variableGroupList')
            ?.setValue(response[0].VG_Id);
          this.addNewVariableItemlForm
            .get('VariableGroup')
            ?.setValue(response[0].VG_Id);
        }
        this.getVariableListInSpec();
        this.getVariable();
        this.getVariableSpec();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
  }
  // add new variable group form submit
  addNewVariableGroupItemlFormSubmit(): void {
    const array = [];
    const obj = {
      inputEventId:
        this.inputEventMode === true
          ? this.addNewVariableGroupItemlForm.get('inputEvent')?.value
          : null,
      outputEventId:
        this.outputEventMode === true
          ? this.addNewVariableGroupItemlForm.get('outputEvent')?.value
          : null,
      groupType: this.addNewVariableGroupItemlForm.get('groupType')?.value,
    };
    array.push(obj);
    if (!this.isUpdateGroupVariable) {
      this.addNewVariableGroupItemlFormSubmitted = true;
      this.isUnitName = true;
      const data = {
        VG_Desc:
          this.addNewVariableGroupItemlForm.get('groupDescription')?.value,
        VG_Order: this.addNewVariableGroupItemlForm.get('groupOrder')?.value,
        PUID: this.addNewVariableGroupItemlForm.get('PUID')?.value,
        userId: JSON.parse(localStorage.user).UserId,
        array,
        // userId: 104,
      };

      if (this.addNewVariableGroupItemlForm.invalid) {
        this.toaster.error('Error', 'Please insert required fields');
        return;
      }
      this.qualityParameterService.sumbitGroup(data).subscribe(
        (response: any) => {
          this.toaster.success('Success', 'Saved Successfully');
          this.addNewVariableGroupItemlFormSubmitted = false;
          this.closeaddNewVariableGroupModel();
          // this.addGrp(response.data);
          this.getVariableGroupList();
          this.addNewVariableGroupItemlForm.get('PUID')?.setValue(data.PUID);
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    } else {
      const data = {
        VG_Id: this.addNewVariableGroupItemlForm.get('VG_Id')?.value,
        VG_Desc:
          this.addNewVariableGroupItemlForm.get('groupDescription')?.value,
        VG_Order: this.addNewVariableGroupItemlForm.get('groupOrder')?.value,
        PUID: this.addNewVariableGroupItemlForm.get('PUID')?.value,
        userId: JSON.parse(localStorage.user).UserId,
        array,
      };
      if (this.addNewVariableGroupItemlForm.invalid) {
        this.toaster.error('Error', 'Please insert required fields');
        return;
      }

      this.qualityParameterService.updateGroup(data).subscribe(
        (response: any) => {
          this.toaster.success('Success', 'Updated Successfully');
          this.variableGroupFormReset();
          this.isUpdateGroupVariable = false;
          this.addNewVariableGroupItemlFormSubmitted = false;
          this.closeaddNewVariableGroupModel();

          // this.updateParameterGrp(response.data);
          this.getVariableGroupList();
          this.addNewVariableGroupItemlForm.get('PUID')?.setValue(data.PUID);
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    }
  }
  refreshData(): void {
    this.isEventMode = false;
    this.inputEventMode = false;
    this.inputChecked = false;
    this.outputEventMode = false;
    this.outputChecked = false;
    this.inputDisabled = false;
    this.outputDisabled = false;
    this.addNewVariableGroupItemlForm.get('inputEvent')?.reset();
    this.addNewVariableGroupItemlForm.get('outputEvent')?.reset();
  }

  onUpdateGroupVariable(row: any): void {
    this.GetAllMasterEvents();
    this.refreshData();
    this.modeProperty = false;
    // if (row.InputEventId === 1) {
    //   this.inputChecked = true;
    //   this.inputEventMode = true;
    // }
    // if (row.OutputEventId === 2) {
    //   this.outputChecked = true;
    //   this.outputEventMode = true;
    // }
    this.addNewVariableGroupItemlForm.get('VG_Id')?.setValue(row.VG_Id);
    this.addNewVariableGroupItemlForm
      .get('groupDescription')
      ?.setValue(row.VG_Desc);
    this.addNewVariableGroupItemlForm.get('groupOrder')?.setValue(row.VG_Order);
    this.addNewVariableGroupItemlForm
      .get('inputEvent')
      ?.setValue(row.InputEventId);
    this.addNewVariableGroupItemlForm
      .get('outputEvent')
      ?.setValue(row.OutputEventId);
    this.addNewVariableGroupItemlForm
      .get('groupType')
      ?.setValue(row.ParameterGroupTypeId);
    this.isaddNewVariableGroupModelVisible = true;
    this.isUpdateGroupVariable = true;
    const val = this.groupTypeList.find(
      (c: any) => c.parameterGroupTypeId === row.ParameterGroupTypeId
    )?.groupTypeName;
    if (val === 'Time') {
      this.isEventMode = false;
    }
    if (val === 'Event') {
      this.isEventMode = true;
      if (row.InputEventId) {
        this.inputEventMode = true;
        this.inputChecked = true;
        this.outputDisabled = true;
        this.inputDisabled = false;
      } else if (row.OutputEventId) {
        this.outputEventMode = true;
        this.outputChecked = true;
        this.inputDisabled = true;
        this.outputDisabled = false;
      } else {
        this.refreshData();
      }
      // this.outputEventMode = true;
    }
    if (val === 'Manual') {
      this.isEventMode = false;
    }
  }
  onDeleteVariableGroup(element: any): void {
    const data = {
      VG_Id: element.VG_Id,
    };
    this.qualityParameterService.deleteGroupList(data).subscribe(
      (response: any) => {
        if (response.HasError) {
          this.toaster.error('Error', response.ErrorMessage);
          return;
        }
        this.toaster.success('Success', 'Deleted Successfully');
        this.variableGroupFormReset();
        // this.addNewVariableGroupItemlForm.reset();
        this.getVariableGroupList();
        this.deleteGrp(data.VG_Id);
        this.addNewVariableGroupItemlForm.get('PUID')?.setValue(element.puId);
      },
      (error: any) => {
        this.toaster.error('Error', error.ErrorMessage);
        this.spinner.hide();
      }
    );
  }
  onChangeGroupInVariableGroup(data: any): void {
    this.machineParameterVariableGroupData.data = [];
    this.machineParameterVariableData.data = [];
    this.machineParameterVariableSpecList.data = [];
    this.variableGroupInParameter = [];
    this.variableGroupInParameterInSpec = [];
    this.unitDetailForm.get('groupList')?.setValue(data.value);
    this.unitDetailForm.get('UnitDataList')?.setValue('');
  }

  /* Parameters */
  onChangeGroupVariableInVariable(data: any): void {
    this.getVariableForm.get('variableGroupList')?.setValue(data.value);
    this.getVariable();
  }

  getVariable(): void {
    const vgId = this.getVariableForm.get('variableGroupList')?.value;
    this.addNewVariableItemlForm.get('VariableGroup')?.setValue(vgId);
    const data = {
      VgId: vgId,
    };
    this.spinner.show();
    this.qualityParameterService.getVariableList(data).subscribe(
      (response: any) => {
        this.machineParameterVariableData = new MatTableDataSource<any>(
          response
        );
        this.getPaginator(this.machineParameterVariableData, 1);
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }

  openPlantGroupVariableModel(): void {
    this.isPlantGroupModelVisibleInVariable = true;
  }
  closePlantGroupVariableModel(): void {
    this.isPlantGroupModelVisibleInVariable = false;
  }
  openaddNewVariableModel(): void {
    if (this.addNewVariableItemlForm.get('VariableGroup')?.value !== '') {
      this.isUpdateVariable = false;
      this.resetVariableForm();
      this.isaddNewVariableModelVisible = true;
      // dropdowns
      this.addNewVariableItemlForm
        .get('DataType')
        ?.setValue(this.dataType[0].TypeId);
      this.addNewVariableItemlForm
        .get('CalculationMethod')
        ?.setValue(this.calculationMethod[0].CM_Desc);
      this.addNewVariableItemlForm
        .get('DataSource')
        ?.setValue(this.dataSource[0].TypeId);
    } else {
      this.toaster.error('Error', 'Unit is not selected!');
    }
  }

  openQualityParamterInterval(event: any): void {
    if (this.addNewVariableItemlForm.get('VariableGroup')?.value !== '') {
      this.GetAllQualityParameterIntervals();
    } else {
      this.toaster.error('Error', 'Unit is not selected!');
    }
  }

  // start interval event change
  changeStartParameterInterval(event: any): void {
    this.startInterval = event;
  }

  // end interval event change
  changeEndParameterInterval(event: any): void {
    this.endInterval = event;
  }

  // get quality parameter interval
  async GetAllQualityParameterIntervals(): Promise<void> {
    const key = {
      puId: this.unitDetailForm.value.PUID,
    };
    this.qualityParameterService
      .GetAllQualityParameterIntervals(key)
      .subscribe((res: any) => {
        if (res.length !== 0) {
          this.intervalId = res[0].intervalId;
          this.changeIntervalMode = false;
          if (
            res[0].intervalStartDate !== '' &&
            res[0].intervalEndDate !== ''
          ) {
            const startInterval = this.toDateWithOutTimeZone(
              res[0].intervalStartDate
            );
            const endInterval = this.toDateWithOutTimeZone(
              res[0].intervalEndDate
            );
            this.startInterval = startInterval;
            this.endInterval = endInterval;
            this.dialog.open(this.displayQualityParamterInterval, {
              height: '50%',
              width: '50%',
            });
          }
        } else {
          this.changeIntervalMode = true;
          this.isIntervalSaveMode = true;
          this.changeStartParameterInterval('');
          this.changeEndParameterInterval('');
          this.toDateWithOutTimeZone('');
          this.dialog.open(this.displayQualityParamterInterval, {
            height: '50%',
            width: '50%',
          });
        }
      });
  }

  // add quality parameter interval
  AddQualityParameterIntervals(): void {
    const key = {
      pu: this.unitDetailForm.value.PUID,
      intervalStartDate: moment(this.startInterval).format('HH:mm:ss'),
      intervalEndDate: moment(this.endInterval).format('HH:mm:ss'),
    };
    if (this.startInterval !== '' && this.endInterval !== '') {
      this.qualityParameterService
        .AddQualityParameterIntervals(key)
        .subscribe((res: any) => {
          this.toaster.success('success', 'Inteval is added successfully');
          this.dialog.closeAll();
        });
    } else {
      this.toaster.error('Error', 'Please select interval');
    }
  }

  // update quality parameter interval
  UpdateQualityParameterIntervals(): void {
    const key = {
      intervalId: this.intervalId,
      intervalStartDate: moment(this.startInterval).format('HH:mm:ss'),
      intervalEndDate: moment(this.endInterval).format('HH:mm:ss'),
    };
    this.qualityParameterService
      .UpdateQualityParameterIntervals(key)
      .subscribe((res: any) => {
        this.toaster.success('success', 'Inteval is updated successfully');
        this.dialog.closeAll();
      });
  }

  // convert UTC time stamp to current date and time
  toDateWithOutTimeZone(date: any): any {
    const tempTime = date.split(':');
    const dt = new Date();
    dt.setHours(tempTime[0]);
    dt.setMinutes(tempTime[1]);
    dt.setSeconds(tempTime[2]);
    return dt;
  }

  // popup for data type detail
  openDataTypeDetailPopup(element: any): void {
    this.dataTypeDetailMode = true;
    this.variableId = element.Var_Id;
    this.GetAllDataTypesDetail();
    this.dialog.open(this.displayDataTypeDetailPopup, {
      height: '93%',
      width: '90%',
    });
  }

  // Data type details CRUD

  GetAllDataTypesDetail(): void {
    const key = {
      variableId: this.variableId,
    };
    this.qualityParameterService.GetAllDataTypesDetail(key).then((res: any) => {
      this.dataTypeDetailDataSource = new MatTableDataSource<any>(res);
      this.getPaginator(this.dataTypeDetailDataSource, 2);
    });
  }

  AddDataType(): any {
    const key = {
      varId: this.variableId,
      text: this.dataTypeDetailForm.value.text,
    };
    // tslint:disable-next-line:prefer-const
    let count = 0;
    const checkDuplicate = this.dataTypeDetailDataSource.filteredData;
    checkDuplicate.map((element) => {
      if (element.text === this.dataTypeDetailForm.value.text) {
        return count++;
      }
      return count;
    });
    if (count > 0) {
      this.toaster.error('Error', 'You cannot add duplicate text');
    } else {
      this.qualityParameterService.AddDataType(key).subscribe((res: any) => {
        this.toaster.success('success', 'Data Type is added successfully');
        this.GetAllDataTypesDetail();
        this.dataTypeDetailMode = true;
        this.dataTypeDetailForm.get('text')?.reset();
      });
    }
  }

  editDataTypeDetail(element: any): void {
    this.dataTypeDetailMode = false;
    this.dataTypeId = element.dataTypeId;
    this.dataTypeDetailForm.get('text')?.setValue(element.text);
  }

  UpdateDataTypeDetail(): void {
    const key = {
      dataTypeId: this.dataTypeId,
      text: this.dataTypeDetailForm.value.text,
    };
    this.qualityParameterService.UpdateDataType(key).subscribe((res: any) => {
      this.toaster.success('success', 'Data Type is updated successfully');
      this.GetAllDataTypesDetail();
      this.dataTypeDetailMode = true;
      this.dataTypeDetailForm.get('text')?.reset();
    });
  }

  DeleteDataType(element: any): void {
    const key = {
      dataTypeId: element.dataTypeId,
    };
    this.qualityParameterService.DeleteDataType(key).subscribe((res: any) => {
      this.toaster.success('success', 'DataType Detail is deleted succesfully');
      this.GetAllDataTypesDetail();
    });
  }

  inputEventChang(event: any): void {
    this.inputChecked = event.checked;
    if (event.checked === true) {
      this.inputEventMode = true;
      this.outputEventMode = false;
      this.outputDisabled = true;
      this.GetAllMasterEvents();
      this.addNewVariableGroupItemlForm.get('outputEvent')?.reset();
    }
    if (event.checked === false) {
      this.inputEventMode = false;
      this.inputDisabled = false;
      this.outputDisabled = false;
      this.addNewVariableGroupItemlForm.get('inputEvent')?.reset();
    }
  }

  outputEventChang(event: any): void {
    this.outputChecked = event.checked;
    if (event.checked === true) {
      this.outputEventMode = true;
      this.inputEventMode = false;
      this.inputDisabled = true;
      this.GetAllMasterEvents();
      this.addNewVariableGroupItemlForm.get('inputEvent')?.reset();
    }
    if (event.checked === false) {
      this.outputEventMode = false;
      this.inputDisabled = false;
      this.inputEventMode = false;
      this.addNewVariableGroupItemlForm.get('outputEvent')?.reset();
    }
  }

  closeaddNewVariableModel(): void {
    this.isaddNewVariableModelVisible = false;
  }

  addNewVariableItemlFormSubmit(): any {
    if (!this.isUpdateVariable) {
      if (this.addNewVariableItemlForm.invalid) {
        this.toaster.error('Error', 'Please insert required fields');
        return;
      }

      if (
        this.addNewVariableItemlForm.get('parameterPrecision')?.value === ''
      ) {
        this.addNewVariableItemlForm.get('parameterPrecision')?.setValue(null);
      }
      debugger;
      const data = {
        Var_Desc: this.addNewVariableItemlForm.get('VariableGrpDesc')?.value,
        VG_Id: this.addNewVariableItemlForm.get('VariableGroup')?.value,
        DT_Id: this.addNewVariableItemlForm.get('DataType')?.value,
        DS_Id: this.addNewVariableItemlForm.get('DataSource')?.value,
        Eng_Units: this.addNewVariableItemlForm.get('EngUnits')?.value,
        Var_Precision:
          this.addNewVariableItemlForm.get('parameterPrecision')?.value,
        InputTag: this.addNewVariableItemlForm.get('InputTag')?.value,
        CalculationMethod:
          this.addNewVariableItemlForm.get('CalculationMethod')?.value,
        OutputTag: this.addNewVariableItemlForm.get('OutputTag')?.value,
        VarOrder: this.addNewVariableItemlForm.get('VariableGrpOrder')?.value,
        CreatedBy: JSON.parse(localStorage.user).UserId,
      };

      this.qualityParameterService.sumbitVariables(data).subscribe(
        (response: any) => {
          debugger;
          this.toaster.success('Success', 'Saved Successfully');
          this.resetVariableForm();
          this.addNewVariableItemlFormSubmitted = false;
          this.closeaddNewVariableModel();
          this.variableListInSpec.push(response.data);
          this.addParameter(response.data);
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    } else {
      if (this.addNewVariableItemlForm.invalid) {
        this.toaster.error('Error', 'Please insert required fields');
        return;
      }
      if (
        this.addNewVariableItemlForm.get('parameterPrecision')?.value === ''
      ) {
        this.addNewVariableItemlForm.get('parameterPrecision')?.setValue(null);
      }
      const data = {
        Var_Id: this.addNewVariableItemlForm.get('varId')?.value,
        Var_Desc: this.addNewVariableItemlForm.get('VariableGrpDesc')?.value,
        VG_Id: this.addNewVariableItemlForm.get('VariableGroup')?.value,
        DT_Id: this.addNewVariableItemlForm.get('DataType')?.value,
        DS_Id: this.addNewVariableItemlForm.get('DataSource')?.value,
        Eng_Units: this.addNewVariableItemlForm.get('EngUnits')?.value,
        Var_Precision:
          this.addNewVariableItemlForm.get('parameterPrecision')?.value,
        InputTag: this.addNewVariableItemlForm.get('InputTag')?.value,
        CalculationMethod:
          this.addNewVariableItemlForm.get('CalculationMethod')?.value,
        OutputTag: this.addNewVariableItemlForm.get('OutputTag')?.value,
        VarOrder: this.addNewVariableItemlForm.get('VariableGrpOrder')?.value,
        CreatedBy: JSON.parse(localStorage.user).UserId,
        TransactionTypeId: 2,
      };

      this.qualityParameterService.updateParameter(data).subscribe(
        (response: any) => {
          this.toaster.success('Success', 'Update Successfully');
          this.resetVariableForm();
          this.isUpdateVariable = false;
          this.addNewVariableItemlFormSubmitted = false;
          this.closeaddNewVariableModel();
          this.updateParameter(response.data);
          // this.getVariableAfterUpsert(data.VG_Id);
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    }
  }
  onClickUpdateVariable(element: any): void {
    this.isUpdateVariable = true;
    this.addNewVariableItemlForm.get('varId')?.setValue(element.Var_Id);
    this.addNewVariableItemlForm
      .get('VariableGrpDesc')
      ?.setValue(element.Var_Desc);
    this.addNewVariableItemlForm.get('VariableGroup')?.setValue(element.VG_Id);
    this.addNewVariableItemlForm.get('DataType')?.setValue(element.DT_Id);
    this.addNewVariableItemlForm.get('DataSource')?.setValue(element.DS_Id);
    this.addNewVariableItemlForm.get('EngUnits')?.setValue(element.Eng_Units);
    this.addNewVariableItemlForm
      .get('parameterPrecision')
      ?.setValue(element.Var_Precision);
    this.addNewVariableItemlForm.get('InputTag')?.setValue(element.InputTag);
    this.addNewVariableItemlForm
      .get('CalculationMethod')
      ?.setValue(element.CalculationMethod);
    this.addNewVariableItemlForm.get('OutputTag')?.setValue(element.OutputTag);
    this.addNewVariableItemlForm
      .get('VariableGrpOrder')
      ?.setValue(element.VarOrder);
    this.isaddNewVariableModelVisible = true;
  }
  deleteVariable(element: any): void {
    const data = {
      Var_Id: element.Var_Id,
    };
    this.qualityParameterService.deleteParameter(data).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'Deleted Successfully');

        this.deleteParam(data.Var_Id);
        this.updateVariableListInSpec(data.Var_Id);
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getDataType(): void {
    const data = {
      Id: 0,
    };
    this.qualityParameterService.getDataType(data).subscribe(
      (response: any) => {
        this.dataType = response.masterList;

        if (response.length > 0) {
          this.addNewVariableItemlForm
            .get('DataType')
            ?.setValue(this.dataType[0].TypeId);
        }
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  getDataSource(): void {
    const data = {
      Id: 0,
    };
    this.qualityParameterService.getDatasource(data).subscribe(
      (response: any) => {
        this.dataSource = response.masterList;
          
        if (response.length > 0) {
          this.addNewVariableItemlForm
            .get('DataSource')
            ?.setValue(this.dataSource[0].TypeId);
        }
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetCalculationMethod(): void {
    const data = {
      Id: 0,
    };
    this.qualityParameterService.getCalculationMethod(data).subscribe(
      (response: any) => {
        this.calculationMethod = response.calculationMethodList;
        if (response.length > 0) {
          this.addNewVariableItemlForm
            .get('CalculationMethod')
            ?.setValue(this.calculationMethod[0].CM_Desc);
        }
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  onChangeDataSource(value: any): void {
    if (value.value === 1) {
      this.addNewVariableItemlForm.get('DataSource')?.setValue(1);
      this.calculationhide = false;
    } else {
      this.calculationhide = true;
      this.addNewVariableItemlForm.get('DataSource')?.setValue(2);
    }
  }

  onChangeDataType(value: any): void {
    console.log(value);
    this.getVariableListInSpec();
    if (
      this.variableListInSpec.find(
        (d) =>
          d.Var_Id === this.addNewVariableSpecForm.value.Desc &&
          d.DT_Desc === 'Bool'
      )
    ) {
      this.disabled = true;
      //  this.inputclr = disabled
    } else {
      this.disabled = false;
    }

    if (
      this.variableListInSpec.find(
        (d) => d.Var_Id === this.addNewVariableSpecForm.value.Desc
      ).DT_Desc === 'Float'
    ) {
      this.checkDataType = true;
    } else {
      this.checkDataType = false;
    }
  }

  /* Spec */
  getVariableListInSpec(): void {
    const vgId = this.getVariableSpecForm.get('variableGroupList')?.value;

    this.addNewVariableItemlForm.get('VariableGroup')?.setValue(vgId);
    const data = {
      VgId: vgId,
    };
    this.qualityParameterService.getVariableList(data).subscribe(
      (response: any) => {
        this.variableListInSpec = response;
        if (response.length > 0) {
          // this.addNewVariableSpecForm.get('Desc')?.setValue(this.variableListInSpec[0].Var_Id);
        }
      },

      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onChangeGroupVariableInVariableInSpec(data: any): void {
    this.getVariableSpecForm.get('variableGroupList')?.setValue(data.value);
    this.addNewVariableSpecForm.get('VgId')?.setValue(data.value);
    this.getVariableListInSpec();
    this.getVariableSpec();
  }

  addNewVariableSpecModel(): void {
    this.getVariable();

    this.getVariableListInSpec();

    const groupId = this.addNewVariableSpecForm.get('VgId')?.value;
    if (groupId !== '') {
      this.isaddNewVariableSpecModelVisible = true;
      this.isUpdateVariableSpec = false;
      this.resetVariableSpecForm();
      this.addNewVariableSpecForm
        .get('Desc')
        ?.setValue(this.variableListInSpec[0].Var_Id);
      this.addNewVariableSpecForm
        .get('LinkProduct')
        ?.setValue(this.LinkProductList[0].TypeName);
    } else {
      this.toaster.error('Error', 'Unit is not selected!');
    }
  }
  getVariableSpec(): void {
    const vgId = this.getVariableSpecForm.get('variableGroupList')?.value;
    this.addNewVariableSpecForm.get('VgId')?.setValue(vgId);
    const data = {
      VG_Id: vgId,
    };
    this.spinner.show();

    this.qualityParameterService.getParameterSpecificationsList(data).subscribe(
      (response: any) => {
        this.machineParameterVariableSpecList = new MatTableDataSource<any>(
          response
        );
        this.getPaginator(this.machineParameterVariableSpecList, 2);
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }
  onClickUpdateVariableSpec(element: any): void {
    this.isUpdateVariableSpec = true;
    this.isaddNewVariableSpecModelVisible = true;
    // dropdowns

    this.addNewVariableSpecForm.get('Desc')?.setValue(element.Var_Id);
    this.addNewVariableSpecForm
      .get('Parameter_Specs_Id')
      ?.setValue(element.Var_Spec_Id);
    this.addNewVariableSpecForm.get('LRL')?.setValue(element.LRL);
    this.addNewVariableSpecForm.get('LSL')?.setValue(element.LSL);
    this.addNewVariableSpecForm.get('LWL')?.setValue(element.LWL);
    this.addNewVariableSpecForm.get('TGT')?.setValue(element.TGT);
    this.addNewVariableSpecForm.get('UWL')?.setValue(element.UWL);
    this.addNewVariableSpecForm.get('USL')?.setValue(element.USL);
    this.addNewVariableSpecForm.get('URL')?.setValue(element.URL);
    this.addNewVariableSpecForm
      .get('LinkProduct')
      ?.setValue(element.LinkProduct);
  }
  deleteVariableSpec(element: any): void {
    const data = {
      Var_Spec_Id: element.Var_Spec_Id,
    };
    this.qualityParameterService.deleteParameterSpecifications(data).subscribe(
      (response: any) => {
        if (response.message == 'Deleted Successfully') {
          this.toaster.success('Success', 'Deleted Successfully');
          this.deleteSpec(data.Var_Spec_Id);
        } else if (response.message == 'Already used in QX Matrix') {
          this.toaster.error('Already used in QX Matrix');
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  closeaddNewVariableSpecModel(): void {
    this.isaddNewVariableSpecModelVisible = false;
  }
  getLinkProduct(): void {
    const key = {
      Id: this.getVariableSpecForm.get('PUID')?.value,
    };
    this.machineParameterService.getparameterLinkProduct(key).subscribe(
      (response: any) => {
        this.LinkProductList = response;
        this.filteredLinkProduct = this.LinkProductList.slice();
        this.filteredLinked.next(this.filteredLinkProduct);

        this.addNewVariableSpecForm
          .get('LinkProduct')
          ?.setValue(this.LinkProductList[0].TypeName);
        this.filterLink();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  addNewVariableSpecItemFormSubmit(): void {
    let Desc = '';
    const vgId = this.getVariableSpecForm.get('variableGroupList')?.value;
    
    const data = {
      VgId: vgId,
      
    };
    this.qualityParameterService.getVariableList(data).subscribe(
      (response: any) => {
        const varId = this.addNewVariableSpecForm.get('Desc')?.value;
        const variable = response.find((c: any) => c.Var_Id === varId);
        Desc = variable.Var_Desc;
        if (!this.isUpdateVariableSpec) {
          // add
          if (this.addNewVariableSpecForm.invalid) {
            this.toaster.error('Error', 'Please insert required fields');
            return;
          }
          let linkProduct = [];
          linkProduct = this.addNewVariableSpecForm.get('LinkProduct')?.value;
          linkProduct = linkProduct.filter((a: any) => a !== '0');
          linkProduct.forEach((element: any) => {
            if (this.checkDataType) {
              const data1 = {
                Var_Id: this.addNewVariableSpecForm.get('Desc')?.value,
                parDesc: Desc,
                VG_Id: this.getVariableSpecForm.get('variableGroupList')?.value,
                LinkProduct: element,
                CreatedBy:JSON.parse(localStorage.user).UserId,
                LRL:
                  this.addNewVariableSpecForm.get('LRL')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('LRL')?.value,
                LSL:
                  this.addNewVariableSpecForm.get('LSL')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('LSL')?.value,
                LWL:
                  this.addNewVariableSpecForm.get('LWL')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('LWL')?.value,
                TGT:
                  this.addNewVariableSpecForm.get('TGT')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('TGT')?.value,
                UWL:
                  this.addNewVariableSpecForm.get('UWL')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('UWL')?.value,
                USL:
                  this.addNewVariableSpecForm.get('USL')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('USL')?.value,
                URL:
                  this.addNewVariableSpecForm.get('URL')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('URL')?.value,
              };
              this.qualityParameterService
                .sumbitParameterSpecifications(data1)
                .subscribe(
                  (response: any) => {
                    if (response.HasError) {
                      this.toaster.error('Error', response.ErrorMessage);
                    } else {
                      if (
                        response.ErrorMessage ===
                        'C1-Choose different Parameter Name or Link Product'
                      ) {
                        this.toaster.error('Error', response.ErrorMessage);
                      } else {
                        this.toaster.success('Success', response.ErrorMessage);
                        this.closeaddNewVariableSpecModel();
                        this.getVariableSpec();
                      }
                    }
                  },
                  (error) => {
                    this.toaster.error('Error', error.message);
                  }
                );
              // this.addParameterSpec(response.data);
            } else {
              const data2 = {
                Var_Id: this.addNewVariableSpecForm.get('Desc')?.value,
                parDesc: Desc,
                VG_Id: this.getVariableSpecForm.get('variableGroupList')?.value,
                LinkProduct: element,
                CreatedBy: JSON.parse(localStorage.user).UserId,
                LRL:
                  this.addNewVariableSpecForm.get('LRL')?.value === ''
                    ? null : this.addNewVariableSpecForm.get('LRL')?.value,
                LSL:
                  this.addNewVariableSpecForm.get('LSL')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('LSL')?.value,
                LWL:
                  this.addNewVariableSpecForm.get('LWL')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('LWL')?.value,
                TGT:
                  this.addNewVariableSpecForm.get('TGT')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('TGT')?.value,
                UWL:
                  this.addNewVariableSpecForm.get('UWL')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('UWL')?.value,
                USL:
                  this.addNewVariableSpecForm.get('USL')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('USL')?.value,
                URL:
                  this.addNewVariableSpecForm.get('URL')?.value === ''
                    ? null
                    : this.addNewVariableSpecForm.get('URL')?.value,
              };
              this.qualityParameterService
                .sumbitParameterSpecifications(data2)
                .subscribe(
                  (response: any) => {
                    if (response.HasError) {
                      this.toaster.error('Error', response.ErrorMessage);
                    } else {
                      if (
                        response.ErrorMessage ===
                        'C1-Choose different Parameter Name or Link Product'
                      ) {
                        this.toaster.error('Error', response.ErrorMessage);
                      } else {
                        this.toaster.success('Success', response.ErrorMessage);
                        this.closeaddNewVariableSpecModel();
                        // this.addParameterSpec(response.data);
                        this.getVariableSpec();
                      }
                    }
                  },
                  (error) => {
                    this.toaster.error('Error', error.message);
                  }
                );
            }
            // tslint:disable-next-line:no-shadowed-variable
            // this.qualityParameterService.sumbitParameterSpecifications(data).subscribe((response: any) => {
            //     if (response.HasError) {
            //       this.toaster.error('Error', response.ErrorMessage);

            //     } else {
            //       if ( response.ErrorMessage === 'C1-Choose different Parameter Name or Link Product') {
            //         this.toaster.error('Error', response.ErrorMessage);

            //       } else {
            //         this.toaster.success('Success', response.ErrorMessage);
            //         this.closeaddNewVariableSpecModel();
            //         this.addParameterSpec(response.data);
            //       }
            //     }
            //   },
            //   (error) => {
            //     this.toaster.error('Error', error.message);
            //   }
            // );
          });
        } else {
          // update
          if (this.addNewVariableSpecForm.invalid) {
            return;
          }

          const data = {
            Var_Spec_Id:
              this.addNewVariableSpecForm.get('Parameter_Specs_Id')?.value,
            Var_Id: this.addNewVariableSpecForm.get('Desc')?.value,
            parDesc: Desc,
            VG_Id: this.getVariableSpecForm.get('variableGroupList')?.value,
            LinkProduct: this.addNewVariableSpecForm.get('LinkProduct')?.value,
            LRL:
              this.addNewVariableSpecForm.get('LRL')?.value === ''
                ? null
                : this.addNewVariableSpecForm.get('LRL')?.value,
            LWL:
              this.addNewVariableSpecForm.get('LWL')?.value === ''
                ? null
                : this.addNewVariableSpecForm.get('LWL')?.value,
            LSL:
              this.addNewVariableSpecForm.get('LSL')?.value === ''
                ? null
                : this.addNewVariableSpecForm.get('LSL')?.value,
            TGT:
              this.addNewVariableSpecForm.get('TGT')?.value === ''
                ? null
                : this.addNewVariableSpecForm.get('TGT')?.value,
            UWL:
              this.addNewVariableSpecForm.get('UWL')?.value === ''
                ? null
                : this.addNewVariableSpecForm.get('UWL')?.value,
            USL:
              this.addNewVariableSpecForm.get('USL')?.value === ''
                ? null
                : this.addNewVariableSpecForm.get('USL')?.value,
            URL:
              this.addNewVariableSpecForm.get('URL')?.value === ''
                ? null
                : this.addNewVariableSpecForm.get('URL')?.value,
          };
          this.qualityParameterService
            .updateParameterSpecifications(data)
            .subscribe(
              (response: any) => {
                if (response.HasError) {
                  this.toaster.error('Error', response.ErrorMessage);
                } else {
                  this.isUpdateVariableSpec = false;
                  this.toaster.success('Success', 'Updated Successfully');
                  this.closeaddNewVariableSpecModel();
                  // this.updateParameterSpec(response.data);
                  this.getVariableSpec();
                }
              },
              (error) => {
                this.toaster.error('Error', error.message);
              }
            );
        }
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  updateVariableListInSpec(id: any): void {
    const removeIndex = this.variableListInSpec.findIndex(function (i) {
      return i.Var_Id === id;
    });
    if (removeIndex >= 0) {
      const items = this.variableListInSpec.splice(removeIndex, 1);
    }
  }
  /* End Spec*/
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }

  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  SelectUnitName(unitname: any): any {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
    }
  }
  // crud in table rows group
  addGrp(data: any): void {
    this.machineParameterVariableGroupData.filteredData.push(data);
    this.machineParameterVariableGroupData = new MatTableDataSource<any>(
      this.machineParameterVariableGroupData.filteredData
    );
    this.getPaginator(this.machineParameterVariableGroupData, 0);
  }
  updateParameterGrp(data: any): void {
    const removeIndex =
      this.machineParameterVariableGroupData.filteredData.findIndex(function (
        i
      ) {
        return i.VG_Id === data.VG_Id;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableGroupData.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableGroupData.filteredData.push(data);
      this.machineParameterVariableGroupData = new MatTableDataSource<any>(
        this.machineParameterVariableGroupData.filteredData
      );
      this.getPaginator(this.machineParameterVariableGroupData, 0);
    }
  }
  deleteGrp(index: any) {
    const removeIndex =
      this.machineParameterVariableGroupData.filteredData.findIndex(function (
        i
      ) {
        return i.VG_Id === index;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableGroupData.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableGroupData = new MatTableDataSource<any>(
        this.machineParameterVariableGroupData.filteredData
      );
      this.getPaginator(this.machineParameterVariableGroupData, 0);
    }
  }

  // crud in table rows parameter
  addParameter(data: any): void {
    this.machineParameterVariableData.filteredData.push(data);
    this.machineParameterVariableData = new MatTableDataSource<any>(
      this.machineParameterVariableData.filteredData
    );
    this.getPaginator(this.machineParameterVariableData, 1);
  }

  updateParameter(data: any): void {
    const removeIndex =
      this.machineParameterVariableData.filteredData.findIndex(function (i) {
        return i.Var_Id === data.Var_Id;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableData.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableData.filteredData.push(data);
      this.machineParameterVariableData = new MatTableDataSource<any>(
        this.machineParameterVariableData.filteredData
      );

      this.getPaginator(this.machineParameterVariableData, 1);
    }
  }
  deleteParam(index: any) {
    const removeIndex =
      this.machineParameterVariableData.filteredData.findIndex(function (i) {
        return i.Var_Id === index;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableData.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableData = new MatTableDataSource<any>(
        this.machineParameterVariableData.filteredData
      );
      this.getPaginator(this.machineParameterVariableData, 1);
    }
  }
  // spec
  addParameterSpec(data: any): void {
    debugger;
    this.machineParameterVariableSpecList.filteredData.push(data);
    this.machineParameterVariableSpecList = new MatTableDataSource<any>(
      this.machineParameterVariableSpecList.filteredData
    );
    this.getPaginator(this.machineParameterVariableSpecList, 2);
  }
  updateParameterSpec(data: any): void {
    const removeIndex =
      this.machineParameterVariableSpecList.filteredData.findIndex(function (
        i
      ) {
        return i.Var_Spec_Id === data.Var_Spec_Id;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableSpecList.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableSpecList.filteredData.push(data);
      this.machineParameterVariableSpecList = new MatTableDataSource<any>(
        this.machineParameterVariableSpecList.filteredData
      );
      this.getPaginator(this.machineParameterVariableSpecList, 2);
    }
  }
  deleteSpec(index: any) {
    const removeIndex =
      this.machineParameterVariableSpecList.filteredData.findIndex(function (
        i
      ) {
        return i.Var_Spec_Id === index;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableSpecList.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableSpecList = new MatTableDataSource<any>(
        this.machineParameterVariableSpecList.filteredData
      );
      this.getPaginator(this.machineParameterVariableSpecList, 2);
    }
  }
  selectAllItems() {
    this.isSelected = !this.isSelected;

    if (this.isSelected) {
      const filterdArray = this.filteredLinkProduct?.map(
        (item: any) => item?.TypeName
      );
      this.addNewVariableSpecForm
        .get('LinkProduct')
        ?.setValue([...filterdArray, '0']);
    } else {
      this.addNewVariableSpecForm.get('LinkProduct')?.setValue([]);
    }
  }
  // Icon
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  variableGroupFormReset(): void {
    // this.addNewVariableGroupItemlForm.reset();

    this.addNewVariableGroupItemlForm.get('groupDescription')?.setValue('');
    this.addNewVariableGroupItemlForm.get('groupOrder')?.setValue('');
    this.addNewVariableGroupItemlForm.get('groupType')?.reset();
  }
  resetVariableForm(): void {
    // this.addNewVariableItemlForm.reset();
    this.addNewVariableItemlForm.get('varId')?.setValue('');
    this.addNewVariableItemlForm.get('VariableGrpDesc')?.setValue('');
    this.addNewVariableItemlForm.get('DataType')?.setValue('');
    this.addNewVariableItemlForm.get('CalculationMethod')?.setValue('');
    this.addNewVariableItemlForm.get('DataSource')?.setValue('');
    this.addNewVariableItemlForm.get('EngUnits')?.setValue('');
    this.addNewVariableItemlForm.get('parameterPrecision')?.setValue('');
    this.addNewVariableItemlForm.get('InputTag')?.setValue('');
    this.addNewVariableItemlForm.get('OutputTag')?.setValue('');
    this.addNewVariableItemlForm.get('VariableGrpOrder')?.setValue('');
  }
  resetVariableSpecForm(): void {
    this.addNewVariableSpecForm.get('Parameter_Specs_Id')?.setValue('');
    this.addNewVariableSpecForm.get('LinkProduct')?.setValue('');
    this.addNewVariableSpecForm.get('LRL')?.setValue('');
    this.addNewVariableSpecForm.get('LSL')?.setValue('');
    this.addNewVariableSpecForm.get('LWL')?.setValue('');
    this.addNewVariableSpecForm.get('TGT')?.setValue('');
    this.addNewVariableSpecForm.get('UWL')?.setValue('');
    this.addNewVariableSpecForm.get('USL')?.setValue('');
    this.addNewVariableSpecForm.get('URL')?.setValue('');
    this.addNewVariableSpecForm.get('Desc')?.setValue('');
  }
  applyFilterForVariableGroup(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.machineParameterVariableGroupData.filter = filterValue
      .trim()
      .toLowerCase();
  }
  applyFilterForVariable(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.machineParameterVariableData.filter = filterValue.trim().toLowerCase();
  }
  applyFilterForVariableSpec(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.machineParameterVariableSpecList.filter = filterValue
      .trim()
      .toLowerCase();
  }
  getPaginator(dataSource: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataSource.paginator = this.paginator.toArray()[index];
      dataSource.sort = this.sort.toArray()[index];
    }, 1000);
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetGroupTypeList(): any {
    // tslint:disable-next-line: align
    this.qualityParameterService.getParamaterGroupType().subscribe(
      (data: any) => {
        this.groupTypeList = data;
        this.addNewVariableGroupItemlForm
          .get('groupType')
          ?.setValue(data[0].parameterGroupTypeId);
        console.log(data.BreadcrumList);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  EventMode(): void {
    this.refreshData();
    // tslint:disable-next-line:max-line-length
    const val = this.groupTypeList.find(
      (c: any) =>
        c.parameterGroupTypeId ===
        this.addNewVariableGroupItemlForm.value.groupType
    )?.groupTypeName;
    if (val === 'Event') {
      this.isEventMode = true;
    } else {
      this.isEventMode = false;
    }
  }
}
