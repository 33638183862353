import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OperatorExecutionService {
    From:any;
    To:any;
    WOHeaderData: any = { Group: '', Plant: '', FromDate: '', ToDate: '' };
    baseUrl: string = environment.O3CoreApiUrl;
    o3ApiUrl: string = environment.O3CoreApiUrl;
    o3CoreApiUrl: string = environment.O3CoreApiUrl;
    woStatus = [
        { id: 1, title: 'Open' },
        { id: 2, title: 'Close' },
        { id: 3, title: 'Complete' },
        { id: 4, title: 'Delay' },
        { id: 5, title: 'In Progress' },
        { id: 6, title: 'ReOpen' }
    ];

    constructor(private http: HttpClient) {
    }

    setWOHeaderData(data: any) {
        this.WOHeaderData.Group = data.Group
        this.WOHeaderData.Plant = data.Plant
        this.WOHeaderData.FromDate = data.FromDate
        this.WOHeaderData.ToDate = data.ToDate
    }
    getWOHeaderData() {
        return this.WOHeaderData;
    }
    setFromDate(date: any) {
        this.From = date;
      }
    
      getFromDate() {
        return this.From;
      }
    
      setToDate(date: any) {
        this.To = date;
      }
    
      getToDate() {
        return this.To;
      }
    getWoStatusList() {
        return this.woStatus;
    }

    getMachineAccess(data: any): Observable<any[]> {
        return this.http.get<any[]>(this.baseUrl + 'get-machine-access', { params: data });
    }
    
    getOperatorMasterData(data: any): Observable<any[]> {
        return this.http.get<any[]>(this.o3CoreApiUrl + 'PMOperatorExecution/getOperatorMasterData', { params: data });
    }
    findOperatorData(data: number): any {
        return this.http.get<any>(this.o3CoreApiUrl + 'PMOperatorExecution/showOperExecWO', { params: { WOId: data } });
    }
    createWorkOrder(data: any): Observable<any[]> {
        return this.http.post<any[]>(this.o3CoreApiUrl + 'PMOperatorExecution/AddWO', data);
    }
    updateWorkOrder(data: any): Observable<any[]> {
        return this.http.post<any[]>(this.o3CoreApiUrl + 'PMOperatorExecution/updateWO', data);
    }
    deleteOperaterData(data: any): Observable<any[]> {
        return this.http.delete<any[]>(this.o3CoreApiUrl + 'PMOperatorExecution/deleteOperExecWO?Id='+ data );
    }
}

