import { Component, OnDestroy} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ChecklistService } from 'src/app/services/checklist.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'btn-cell-rendererQxMatrix',
    template: `
        <button mat-mini-fab color="primary" (click)="onClick($event)">
        <!-- <i class="fa fa-edit Update" style="color: #fff;"></i> -->
        <mat-icon>edit</mat-icon>
        </button>
        <button mat-mini-fab color="warn" (click)="onClick($event)"  [disabled]="this.checkDisabled">
        <!-- <i class="fa fa-trash Delete" style="color: #fff;"></i> -->
        <mat-icon>delete</mat-icon>
        </button>
  `
})
// tslint:disable-next-line:component-class-suffix
export class BtnRendererCheckListType implements ICellRendererAngularComp, OnDestroy{
    public params: any;
    checkList: any[];
    public qualityTypeId: number;
    serviceExOnce: boolean = false;
    plantId: number;
    groupId: number;
    checkDisabled: boolean;

    constructor(private ChecklistService: ChecklistService) { }


    refresh(params: any): boolean {
        throw new Error('Method not implemented.');
    }
    agInit(params: any): void {
        this.params = params;
        this.qualityTypeId = params.node.data.qualityTypeId;
        this.plantId = params.node.data.plantId;
        this.checkDisabled = params.node.data.matchedByClist;
    }
    onClick($event: any): any {
        if (this.params.onClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowData: this.params.node.data
                // ...something
            };
            this.params.onClick(params);
        }
    }
  
    ngOnDestroy(): any {
        // no need to remove the button click handler
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}
