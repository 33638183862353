<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  template="<img src='assets/images/spinner.gif' />"
></ngx-spinner>
<div dir="{{languageDir}}">
<div class="content-top-box">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a
        class="breadcrumb-link"
        [routerLink]="breadcrumItem.Url"
        [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)"
        >{{breadcrumItem.text}}</a
      >
    </li>
  </ul>
</div>
<mat-card>
  <div class="d-flex justify-content-between">
    <div>
      <!-- <h1 class="main-title">KPI Milestone</h1> -->
      <h1 class="main-title">
        {{ langObj ? langObj?.KPIMilestone : ("KPIMilestone" | translate) }}
      </h1>
    </div>
    <div>
      <button *ngIf="IsWrite"
        mat-flat-button
        (click)="addMilestone()"
        matTooltip="Add Milestone"
        tooltipPosition="top"
        class="add-milestone-btn"
        style="background-color: #3f4e4f; color: white"
      >
        <!-- Add Milestone -->
        {{ langObj ? langObj?.AddMileStone : ("AddMileStone" | translate) }}
      </button>

      <form [formGroup]="filtersForm" style="float: right">
        <mat-form-field
          appearance="fill"
          class="field-fill"
          style="margin-right: 20px"
        >
        <mat-label>{{
          langObj ? langObj?.SelectGroup : ("SelectGroup" | translate)
          }}</mat-label>
          <mat-select
            panelClass="testClass"
            formControlName="group_Id"
            name="Group"
            (selectionChange)="getPlants()"
          >
            <mat-select-filter
              [placeholder]="'Search'"
              [displayMember]="'TypeName'"
              [array]="groupList"
              (filteredReturn)="groupTempList = $event"
            ></mat-select-filter>
            <mat-option
              *ngFor="let group of groupTempList"
              [value]="group.TypeId"
            >
              {{ group.TypeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          class="field-fill"
          style="margin-right: 20px"
        >
        <mat-label>{{
          langObj ? langObj?.SelectPlant : ("SelectPlant" | translate)
        }}</mat-label>
          <mat-select
            panelClass="testClass"
            formControlName="plantId"
            name="Plant"
            (selectionChange)="onPlantChange()"
          >
            <mat-select-filter
              [placeholder]="'Search'"
              [displayMember]="'DeptDesc'"
              [array]="plantList"
              (filteredReturn)="plantTempList = $event"
            ></mat-select-filter>
            <mat-option
              *ngFor="let plant of plantTempList"
              [value]="plant.DeptId"
            >
              {{ plant?.DeptDesc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>
</mat-card>
<!-- ---------------------------Body------------------------------ -->

<ag-grid-angular
  style="width: 100%; height: 30vw; margin-top: 5px"
  class="ag-theme-alpine"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [animateRows]="true"
  [rowData]="rowData"
  [gridOptions]="gridOptions"
  [groupDisplayType]="groupDisplayType"
  (cellClicked)="onCellClicked($event)"
  [loadingOverlayComponent]="loadingOverlayComponent"
  [frameworkComponents]="frameworkComponents"
  (gridReady)="onGridReady($event)"
  (cellValueChanged)="onCellValueChanged($event)"
  [pagination]="true" [paginationPageSize]="10"
></ag-grid-angular>

<!-- -------------create milestone modal -------------------- -->
<ng-template #milestoneModal>
  <div class="sfdc-configuration-content" mat-dialog-title>
    <div class="ncrInfo-container">
      <h3>{{ isUpadingMilestone ? langObj?.UpdateMileStone  : langObj?.AddMileStone }}
      </h3>

      <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
        class="close-btn"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="milestoneForm">
      <div class="content-row-wrap">
        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="width: 250px"
        >
          <mat-label>{{langObj ? langObj?.MilestoneName : ("MilestoneName" | translate)}}</mat-label>
          <input matInput formControlName="milestoneName" />
          <p
            *ngIf="
              m.milestoneName.invalid &&
              (m.milestoneName.dirty || m.milestoneName.touched)
            "
            class="text-danger"
          >
            {{ langObj ? langObj?.Required : ("Required" | translate) }}
          </p>
        </mat-form-field>

        <div class="inputField _inputField" style="width: 250px">
          <nz-date-picker
            class="calender-input"
            style="margin-left: 0px !important; width: 100%"
            [nzInputReadOnly]="true"
            [nzShowTime]="false"
            nzFormat="yyyy-MM-dd"
            formControlName="fromDate"
            [minDate]="today"
          >
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.FromDate : ("FromDate" | translate)}}</span>
        </div>
        <div class="inputField _inputField" style="width: 250px">
          <nz-date-picker
            class="calender-input"
            style="margin-left: 0px !important; width: 100%"
            [nzInputReadOnly]="true"
            [nzShowTime]="false"
            nzFormat="yyyy-MM-dd"
            formControlName="toDate"
            [minDate]="today"
          >
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.ToDate : ("ToDate" | translate)}}</span>
          <p
            *ngIf="m.toDate.invalid && (m.toDate.dirty || m.toDate.touched)"
            class="text-danger"
          >
            {{ langObj ? langObj?.Required : ("Required" | translate) }}
          </p>
        </div>
      </div>
      <div
        class="d-flex justify-content-end"
        style="margin-bottom: 10px; margin-top: 15px"
      >
        <button
          mat-mini-fab
          mat-button
          color="primary"
          matTooltip="Save"
          matTooltipClass="custom-tooltip"
          matTooltipPosition="above"
          (click)="saveMilestone()"
          *ngIf="!isUpadingMilestone"
        >
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
        <button
          mat-mini-fab
          mat-button
          color="primary"
          matTooltip="Update"
          matTooltipClass="custom-tooltip"
          matTooltipPosition="above"
          (click)="updateMilestone()"
          *ngIf="isUpadingMilestone"
        >
          <i class="fa fa-floppy-o" style="color: #fff"></i>
        </button>
      </div>
    </form>
  </div>
</ng-template>

<!-- -------------add KPI -------------------- -->
<ng-template #KPIMilestoneModal>
  <div class="sfdc-configuration-content" mat-dialog-title>
    <div class="ncrInfo-container">
      <h3>{{ selectedMilestone?.name }}</h3>

      <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
        class="close-btn"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="KPIForm">
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="{{ langObj ? langObj?.KPIName : ('KPIName' | translate) }}"
          formControlName="name"
        />
        <p
          *ngIf="k.name.invalid && (k.name.dirty || k.name.touched)"
          class="red"
        >
          {{ langObj ? langObj?.Required : ("Required" | translate) }}
        </p>
        <div class="form-group"></div>

        <input
          type="number"
          class="form-control"
          placeholder="{{ langObj ? langObj?.KPITarget : ('KPITarget' | translate) }}"
          formControlName="KPITarget"
        />
        <p
          *ngIf="
            k.KPITarget.invalid && (k.KPITarget.dirty || k.KPITarget.touched)
          "
          class="red"
        >
          {{ langObj ? langObj?.Required : ("Required" | translate) }}
        </p>
      </div>
    </form>
    <div class="d-flex justify-content-end" style="margin-bottom: 10px">
      <button
        mat-mini-fab
        mat-button
        color="primary"
        matTooltip="Save"
        matTooltipClass="custom-tooltip"
        matTooltipPosition="above"
        (click)="saveKPIMilestone()"
      >
        <i class="fa fa-floppy-o" style="color: #fff"></i>
      </button>
    </div>
  </div>
</ng-template>

<!-- -------------add KPI2 -------------------- -->
<ng-template #KPIModal>
  <div
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="ncrInfo-container">
      <h3>{{
        langObj ? langObj?.AddKPI : ("AddKPI" | translate)
        }}</h3>

      <button
        mat-mini-fab
        mat-button
        mat-dialog-close
        color="warn"
        title="close"
        class="close-btn"
      >
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="KPIForm">
      <div class="content-row-wrap d-flex align-items-center">
        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="width: 400px"
        >
          <mat-label>{{
            langObj ? langObj?.Frequency : ("Frequency" | translate)
            }}</mat-label>
          <mat-select
            panelClass="testClass"
            formControlName="frequencyId"
            name="name"
            (selectionChange)="resetKPITargetArray(); onFrequencyChange($event)"
          >
            <mat-select-filter
              [placeholder]="'Search'"
              [displayMember]="'name'"
              [array]="frequencies"
              (filteredReturn)="frequenciesTempList = $event"
            ></mat-select-filter>
            <mat-option
              *ngFor="let frequency of frequenciesTempList"
              [value]="frequency.frequencyId"
            >
              {{ frequency.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="width: 400px"
        >
          <mat-label>{{
            langObj ? langObj?.KPIs : ("KPIs" | translate)
            }}</mat-label>
          <mat-select
            panelClass="testClass"
            formControlName="kpiId"
            name="kpiId"
            multiple
            (selectionChange)="onKPISelect($event)"
          >
            <mat-select-filter
              [placeholder]="'Search'"
              [displayMember]="'name'"
              [array]="KPIs"
              (filteredReturn)="KPIsTempList = $event"
            ></mat-select-filter>
            <mat-option *ngFor="let KPI of KPIsTempList" [value]="KPI.kpiId">
              {{ KPI.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          style="width: 400px"
        >
          <mat-label>{{
            langObj ? langObj?.KPITarget : ("KPITarget" | translate)
            }}</mat-label>
          <input
            matInput
            formControlName="KPITarget"
            [readonly]="k.kpiId.value?.length > 1"
          />
        </mat-form-field>

        <div style="margin-top: 10px; margin-left: 10px">
          <button
            mat-mini-fab
            mat-button
            color="primary"
            matTooltip="Save"
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
            (click)="saveKPI()"
          >
            <i class="fa fa-floppy-o" style="color: #fff"></i>
          </button>
        </div>
        <mat-hint
          *ngIf="k.kpiId.value?.length > 1"
          style="margin-top: 5px; margin-left: 32px"
        >
          {{ langObj ? langObj?.EnabledWhenSingleKPIisSelected : ('EnabledWhenSingleKPIisSelected' | translate) }}
        </mat-hint>
      </div>
    </form>
  </div>
</ng-template>
</div>