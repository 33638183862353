import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { UserRoleProcessing } from 'src/app/models/usermanagement/userroleprocessing';
import { Router } from '@angular/router';

@Component({
  selector: 'app-userrolemapping',
  templateUrl: './userrolemapping.component.html',
  styleUrls: ['./userrolemapping.component.scss']
})
export class UserrolemappingComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<UserRoleProcessing>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  RMList!: any[];
  GroupList!: any[];
  submitData!: any[];
  UsersList!: any[];
  GroupId!: number;
  selectedRowIndex!: any;
  userRoleData!: MatTableDataSource<UserRoleProcessing>;
  userRoleForm!: FormGroup;
  userRoleProcessingData: UserRoleProcessing[] = [];
  userRoleGroupColumns: string[] = ['RoleId', 'RoleName', 'Access'];
  userRolesData = new MatTableDataSource<UserRoleProcessing>();
  breadcrumList: any;
  filteredGroups: any;
  filteredUsers: any;

  // tslint:disable-next-line: no-shadowed-variable
  constructor(private formBuilder: FormBuilder,
              private userService: UserManagementService,
              public router: Router,
              private toaster: ToastrService) { }

  ngOnInit(): void {
   this.datasource();
   this.RMList = [];
   this.userRoleForm =  this.formBuilder.group({
      userGroup: [''],
      UserName: [''],
    });
  }

  getUserRoles(): void {
   const data = {
     UserId : this.userRoleForm.value.UserName,
     GroupId : this.userRoleForm.value.userGroup
    };
   this.userService.getRoleMapData(data).subscribe((response: any) => {
    this.userRoleData = new MatTableDataSource<UserRoleProcessing>(response.RoleMapList);
    this.getPaginator();
   }, (error) => {
     this.toaster.error('Error', error.message);
   });
  }
  // Getting Group Data
   datasource(): void {
    const key = {
      Id : 0
    };
    this.userService.getGroupData(key).subscribe( (data: any) => {
  this.GroupList = data.masterList;
  this.GroupId = this.GroupList[0].TypeId;
  this.filteredGroups = this.GroupList.slice();
  this.getUserslist();
  }, (error: any) => {
    this.toaster.error('error', error.message);
  });
}

   getUserslist(): void {
    const key = {
      GroupId: this.GroupId
    };
    this.userService.getUsersData(key).subscribe((data: any) => {
      this.UsersList = data.UserList;
      this.filteredUsers = this.UsersList.slice();
    }, (error: any) => {
      this.toaster.error('error', error.error.message);
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.userRoleData.filter = filterValue.trim().toLowerCase();
    if (this.userRoleData.paginator) {
      this.userRoleData.paginator.firstPage();
    }
  }



  onSubmit(): void {
    this.RMList = [];
    this.userRoleData.data.map( (x) => {if (x.RoleId === x.IsRoleMap){
    this.RMList.push({
        UserId: this.userRoleForm.value.UserName,
        RoleId: x.RoleId
      });
    }
  });
    const obj = {
      RMList : this.RMList,
      UserId : this.userRoleForm.value.UserName
    };
    this.userService.SumbitRoleMap(obj).subscribe((data: any) => {
      this.toaster.success('Data Saved Successfuly', data.message);
      this.getUserRoles();
    }, (error) => {
      this.toaster.error('Error', error.message);
    });
    }

    getPaginator(): void {
      setTimeout(() => {
        this.userRoleData.paginator = this.paginator;
        this.userRoleData.sort = this.sort;
      }, 1000);
    }

  setAll(event: any): void {
      if (event === true) {
        this.selectedRowIndex.IsRoleMap = this.selectedRowIndex.RoleId;
      }
      else {
        this.selectedRowIndex.IsRoleMap = null;
      }
  }

  highlight(row: any): void {
    this.selectedRowIndex = row;
  }

}
