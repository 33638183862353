import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ElementRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { NgxSpinnerService } from 'ngx-spinner';
import { RMconfigurationService } from 'src/app/services/rmconfiguration.service';
import Swal from 'sweetalert2';
import { CILMasterService } from 'src/app/services/cil-master-service';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { PreventiveDialogComponent } from '../preventive-maintanance-configuration/preventive-dialog/preventive-dialog.component';
import * as _moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
const moment = _moment;
@Component({
  selector: 'app-reactive-maintenance-configuration',
  templateUrl: './reactive-maintenance-configuration.component.html',
  styleUrls: ['./reactive-maintenance-configuration.component.scss'],
})
export class ReactiveMaintenanceConfigurationComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('WOTypeTable') ExportWOTypeTable!: ElementRef;
  @ViewChild('FailureModeTable') ExportFailureModeTable!: ElementRef;
  @ViewChild('PriorityTable') ExportPriorityTable!: ElementRef;
  @ViewChild('TimeTable') ExportTimeTable!: ElementRef;
  @ViewChild('TechnicianTable') ExportTechnicianTable!: ElementRef;

  @ViewChild('allSelected') private allSelected: any;

  formDirective: any;

  panelOpenState = false;
  WOTypeColumns: string[] = ['ID', 'Name', 'CreatedAt', 'CreatedBy', 'action'];
  PriorityColumns: string[] = [
    'ID',
    'Name',
    'CreatedAt',
    'CreatedBy',
    'action',
  ];
  failuremodeColumns: string[] = [
    'ID',
    'Name',
    'WOType',
    'CreatedAt',
    'CreatedBy',
    'action',
  ];
  timeColumns: string[] = ['TimeLimit', 'CreatedAt', 'CreatedBy', 'action'];
  PrioritySource = new MatTableDataSource();
  WOTypeDataSource = new MatTableDataSource();
  failureModeTableDataSource = new MatTableDataSource();
  timeTableDataSource = new MatTableDataSource();
  HeaderForm!: FormGroup;
  WOTypeForm!: FormGroup;
  PriorityForm!: FormGroup;
  FailureModeForm!: FormGroup;
  TechnicianForm!: FormGroup;
  TimeForm!: FormGroup;
  updateOrderprocessingData = false;
  displayComponentModal = false;
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  breadcrumList!: any;
  IsWrite: boolean = false;
  parameterType!: any[];
  HeaderData: any = { Group: '', Plant: '' };
  PlantList: any;
  user: any;
  raci: any;

  edited = false;
  editedTime = false;
  WOType: any = { ID: 0, Name: '', CreatedAt: new Date(), CreatedBy: 0 };
  FailureMode: any = {
    Name: '',
    WOType: '',
    CreatedAt: '',
    CreatedBy: 0,
    ID: 0,
  };
  Priority: any = { ID: 0, Name: '', CreatedAt: new Date(), CreatedBy: 0 };
  Technician: any = {
    ID: 0,
    RMPM: 'RM',
    Name: [],
    CreatedAt: new Date(),
    CreatedBy: 0,
  };
  Time: any = { ID: 0, TimeLimit: '', CreatedAt: '', CreatedBy: 0 };
  isEdit: boolean = false;
  isEditPriority: boolean = false;
  WOTypeList: any = [];
  WOTypeFiltList: any = [];
  PriorityList: any = [];
  TimeList: any = [];
  FailureModeList: any = [];
  pageSizeOptions = [5, 10, 20];
  page = { length: 0, pageIndex: 0, pageSize: 5 };
  WOTypePage = { length: 0, pageIndex: 0, pageSize: 5 };
  PriorityPage = { length: 0, pageIndex: 0, pageSize: 5 };
  TimePage = { length: 0, pageIndex: 0, pageSize: 5 };
  groupId: any;
  userList: any = [];
  list: any = [];
  availableUsersList: any = [];
  orgUserList: any = [];
  TechnicianList: any = [];
  dialogBox: boolean = false;
  excelFile: any;

  WOTypeMasterData: any = [];
  FailureModeMasterData: any = [];
  PriorityMasterData: any = [];
  DelayTimeMasterData: any = [];
  TechnicianMasterData: any = [];
  AllWOType: any = [];
  AllFailureMode: any = [];
  AllPriority: any = [];
  AllDelayTime: any = [];

  ExportWOType: any = [];
  ExportFailureMode: any = [];
  ExportPriority: any = [];
  ExportDelayTime: any = [];
  ExportTechnician: any = [];
  GroupTempList: any = [];
  PlantTempList: any = [];
  constructor(
    private userManagementService: UserManagementService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    public router: Router,
    private spinner: NgxSpinnerService,
    private platModelService: EnterPriseGroupService,
    private RMConfigurationService: RMconfigurationService,
    private cilMasterService: CILMasterService,
    private enterpriseGroupService: EnterPriseGroupService,
    private commonService: CommonService,
    public dialog: MatDialog
  ) { }

  GroupList: any = [];
  spinnerText: string = '';

  @ViewChild('fileInput') fileInput!: ElementRef;
  // parent node
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.WOType.CreatedBy = this.user.UserId;
    this.FailureMode.CreatedBy = this.user.UserId;
    this.Priority.CreatedBy = this.user.UserId;
    this.HeaderForm = this.fb.group({
      Group: new FormControl(null, [Validators.required]),
      Plant: new FormControl(null, [Validators.required]),
    });
    this.WOTypeForm = this.fb.group({
      Name: new FormControl(null, [Validators.required]),
    });
    this.PriorityForm = this.fb.group({
      Name: new FormControl(null, [Validators.required]),
    });
    this.FailureModeForm = this.fb.group({
      FailureMode: new FormControl(null, [Validators.required]),
      woType: new FormControl(null, [Validators.required]),
    });
    this.TechnicianForm = this.fb.group({
      Name: new FormControl(null, [Validators.required]),
    });
    this.TimeForm = this.fb.group({
      time: new FormControl(null, [Validators.required]),
    });

    this.GetMenuAccess();
    this.GetBreadcrumList();
    this.getGroups();
  }

  ExportTOExcel(name: any) {
    if (this.HeaderData.Group && this.HeaderData.Plant) {
      const workBook = XLSX.utils.book_new(); // create a new blank book

      this.ExportWOType =
        this.ExportWOType && this.ExportWOType.length
          ? this.ExportWOType
          : [{ ['Group']: '', ['Plant']: '', ['WO Type Name']: '' }];
      this.ExportFailureMode =
        this.ExportFailureMode && this.ExportFailureMode.length
          ? this.ExportFailureMode
          : [
              {
                ['Group']: '',
                ['Plant']: '',
                ['Failure Mode Name']: '',
                ['Work Order Type']: '',
              },
            ];
      this.ExportPriority =
        this.ExportPriority && this.ExportPriority.length
          ? this.ExportPriority
          : [{ ['Group']: '', ['Plant']: '', ['Priority Name']: '' }];
      this.ExportDelayTime =
        this.ExportDelayTime && this.ExportDelayTime.length
          ? this.ExportDelayTime
          : [{ ['Group']: '', ['Plant']: '', ['Delay Time / min']: '' }];
      this.ExportTechnician =
        this.ExportTechnician && this.ExportTechnician.length
          ? this.ExportTechnician
          : [{ ['Group']: '', ['Plant']: '', ['User Name']: '' }];

      const WOTypeSheet = XLSX.utils.json_to_sheet(this.ExportWOType);
      const FailureModeSheet = XLSX.utils.json_to_sheet(this.ExportFailureMode);
      const PrioritySheet = XLSX.utils.json_to_sheet(this.ExportPriority);
      const TimeSheet = XLSX.utils.json_to_sheet(this.ExportDelayTime);
      const TechnicianSheet = XLSX.utils.json_to_sheet(this.ExportTechnician);

      XLSX.utils.book_append_sheet(workBook, WOTypeSheet, 'WOType'); // add the worksheet to the book
      XLSX.utils.book_append_sheet(workBook, FailureModeSheet, 'FailureMode');
      XLSX.utils.book_append_sheet(workBook, PrioritySheet, 'Priority');
      XLSX.utils.book_append_sheet(workBook, TimeSheet, 'DelayTime');
      XLSX.utils.book_append_sheet(workBook, TechnicianSheet, 'Technician');
      XLSX.writeFile(workBook, `${name}.xlsx`); // initiate a file
    } else {
      this.toaster.warning('Please Fill Header Fields', 'Warning');
      return;
    }
  }

  getGroups() {
    const key = {
      UserId: '',
    };
    const id = {
      value: 0,
    };
    this.cilMasterService.getGroupData(key).subscribe(
      (data: any) => {
        this.GroupList = data.dropDownList;
        this.GroupTempList = data.dropDownList;
        if (this.GroupTempList && this.GroupTempList.length) {
          this.HeaderForm.get('Group')?.setValue(this.GroupList[0].id);
          this.getPlants(this.HeaderData.Group);
        }
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(id: any): void {
    this.cilMasterService.getPlantsData(id).subscribe(
      (data: any) => {
        this.PlantList = data.dropDownList;
        this.PlantTempList = data.dropDownList;
        if (this.PlantTempList && this.PlantTempList.length) {
          this.HeaderForm.get('Plant')?.setValue(this.PlantList[0].id);
          this.getDropdownData(2);
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onGroupKey(value: any) {
    this.GroupList = this.searchGroupKey(value.value);
  }

  searchGroupKey(value: string) {
    let filter = value;
    return this.GroupTempList.filter((grp: any) => {
      let lowerCase = grp.name.toLocaleLowerCase();
      return grp.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  onPlantKey(value: any) {
    this.PlantList = this.searchPlantKey(value.value);
  }

  searchPlantKey(value: string) {
    let filter = value;
    return this.PlantTempList.filter((grp: any) => {
      let lowerCase = grp.name.toLocaleLowerCase();
      return grp.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }
 
  getWOType() {
    this.spinnerText = 'Fetching WOType Data';
    this.spinner.show();
    const data = {
      Group: this.HeaderData.Group,
      Plant: this.HeaderData.Plant,
      RMPM: 'RM',
    };
    this.RMConfigurationService.getWOType(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.AllWOType = res.allWoTypeList;
        this.WOTypeList = res.woTypeList;
        this.WOTypeFiltList =
          this.WOTypeList && this.WOTypeList.length
            ? this.WOTypeList.slice()
            : [];
        this.ExportWOType = [];
        if (this.WOTypeList && this.WOTypeList.length) {
          this.FailureMode.WOType = this.WOTypeFiltList[0].ID;
          for (const wotype of this.WOTypeList) {
            let grpObj = this.GroupList.find(
              (grp: any) => grp.id == wotype.Group.egId
            );
            wotype.Group = grpObj;
            let pntObj = this.PlantList.find(
              (pnt: any) => pnt.id == wotype.Plant.ptId
            );
            wotype.Plant = pntObj;
            const ExportData = {
              ['Group']: wotype.Group.name,
              ['Plant']: wotype.Plant.name,
              ['WO Type Name']: wotype.Name,
            };
            this.ExportWOType.push(ExportData);
          }
        }
        this.tableDataSetWOType(this.WOTypeList);
        this.WOTypePage.length = this.WOTypeList.length;
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getFailureMode(): void {
    this.spinnerText = 'Fetching Failure Mode Data';
    this.spinner.show();
    this.RMConfigurationService.getFailureMode(this.HeaderData).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.AllFailureMode = res.allFailureModeList;
        this.FailureModeList = res.failureModeList;
        this.ExportFailureMode = [];
        if (this.FailureModeList && this.FailureModeList.length) {
          for (const fm of this.FailureModeList) {
            let grpObj = this.GroupList.find(
              (grp: any) => grp.id == fm.Group.egId
            );
            fm.Group = grpObj;
            let pntObj = this.PlantList.find(
              (pnt: any) => pnt.id == fm.Plant.ptId
            );
            fm.Plant = pntObj;
            const ExportData = {
              ['Group']: fm.Group.name,
              ['Plant']: fm.Plant.name,
              ['Failure Mode Name']: fm.Name,
              ['Work Order Type']: fm.WOType ? fm.WOType.Name : '',
            };
            this.ExportFailureMode.push(ExportData);
          }
        }

        this.failureModeTableDataSource = new MatTableDataSource(
          this.FailureModeList
        );
        this.page.length = this.FailureModeList.length;
        this.getPaginator(this.failureModeTableDataSource, 1);
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getAllPriority() {
    this.spinnerText = 'Fetching Priority Data';
    this.spinner.show();
    this.RMConfigurationService.getPriority(this.HeaderData).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.AllPriority = res.allPriorityList;
        this.PriorityList = res.priorityList;
        this.ExportPriority = [];
        if (this.PriorityList && this.PriorityList.length) {
          for (const pri of this.PriorityList) {
            let grpObj = this.GroupList.find(
              (grp: any) => grp.id == pri.Group.egId
            );
            pri.Group = grpObj;
            let pntObj = this.PlantList.find(
              (pnt: any) => pnt.id == pri.Plant.ptId
            );
            pri.Plant = pntObj;
            const ExportData = {
              ['Group']: pri.Group.name,
              ['Plant']: pri.Plant.name,
              ['Priority Name']: pri.Name,
            };
            this.ExportPriority.push(ExportData);
          }
        }
        this.tableDataSetPriority(this.PriorityList);
        this.PriorityPage.length = this.PriorityList.length;
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getTime(): void {
    this.spinnerText = 'Fetching Time Data';
    this.spinner.show();
    this.RMConfigurationService.getTime(this.HeaderData).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.AllDelayTime = res.allTimeList;
        this.TimeList = res.timeList;
        this.ExportDelayTime = [];
        if (this.TimeList && this.TimeList.length) {
          for (const time of this.TimeList) {
            let grpObj = this.GroupList.find(
              (grp: any) => grp.id == time.Group.egId
            );
            time.Group = grpObj;
            let pntObj = this.PlantList.find(
              (pnt: any) => pnt.id == time.Plant.ptId
            );
            time.Plant = pntObj;
            const ExportData = {
              ['Group']: time.Group.name,
              ['Plant']: time.Plant.name,
              ['Delay Time / min']: time.TimeLimit,
            };
            this.ExportDelayTime.push(ExportData);
          }
        }
        this.timeTableDataSource = new MatTableDataSource(this.TimeList);
        this.getPaginator(this.timeTableDataSource, 3);
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getAllTechnician() {
    this.spinnerText = 'Fetching Technician Data';
    this.spinner.show();
    const data = {
      Plant: this.HeaderData.Plant,
      Group: this.HeaderData.Group,
      RMPM: 'RM',
    };
    this.RMConfigurationService.getTechnician(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.TechnicianList = res.data;
        this.Technician.Name = [];
        this.ExportTechnician = [];
        this.availableUsersList = this.orgUserList;
        this.userList = [...this.availableUsersList];
        for (let tech of this.TechnicianList) {
          const user = this.userList.find(
            (user: any) => user.UserId == tech.UserId
          );
          if (user) {
            tech.UserName = user.fullName;
            let check;
            if (this.Technician?.Name?.length) {
              check = this.Technician.Name.find(
                (user: any) => user == tech.UserId
              );
              if (!check) {
                this.Technician.Name.push(tech.UserId);
                let grpObj = this.GroupList.find(
                  (grp: any) => grp.id == tech.Group.egId
                );
                tech.Group = grpObj;
                let pntObj = this.PlantList.find(
                  (pnt: any) => pnt.id == tech.Plant.ptId
                );
                tech.Plant = pntObj;
                const ExportData = {
                  ['Group']: tech.Group.name,
                  ['Plant']: tech.Plant.name,
                  ['User Name']: tech.UserName,
                };
                this.ExportTechnician.push(ExportData);
              }
            } else {
              this.Technician.Name.push(tech.UserId);
              let grpObj = this.GroupList.find(
                (grp: any) => grp.id == tech.Group.egId
              );
              tech.Group = grpObj;
              let pntObj = this.PlantList.find(
                (pnt: any) => pnt.id == tech.Plant.ptId
              );
              tech.Plant = pntObj;
              const ExportData = {
                ['Group']: tech.Group.name,
                ['Plant']: tech.Plant.name,
                ['User Name']: tech.UserName,
              };
              this.ExportTechnician.push(ExportData);
            }
          }
        }
       
         
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  rmChange(opened: boolean): void {
    this.raci = this.orgUserList.slice();
  }

  applyWOTypeFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.WOTypeDataSource.filter = filterValue.trim().toLowerCase();

    if (this.WOTypeDataSource.paginator) {
      this.WOTypeDataSource.paginator.firstPage();
    }
  }

  applyFailureFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.failureModeTableDataSource.filter = filterValue.trim().toLowerCase();

    if (this.failureModeTableDataSource.paginator) {
      this.failureModeTableDataSource.paginator.firstPage();
    }
  }

  applyPriorityFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.PrioritySource.filter = filterValue.trim().toLowerCase();

    if (this.PrioritySource.paginator) {
      this.PrioritySource.paginator.firstPage();
    }
  }

  applyTimeFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.timeTableDataSource.filter = filterValue.trim().toLowerCase();

    if (this.timeTableDataSource.paginator) {
      this.timeTableDataSource.paginator.firstPage();
    }
  }

  editWOType(element: any) {
    this.WOType.ID = element.ID;
    this.WOType.Name = element.Name;
    this.isEdit = true;
  }

  editFailureRow(element: any) {
    this.edited = true;
    this.FailureMode.ID = element.ID;
    this.FailureMode.Name = element.Name;
    this.FailureMode.WOType = element.WOType ? element.WOType.ID : '';
  }

  editPriority(element: any) {
    this.Priority.ID = element.ID;
    this.Priority.Name = element.Name;
    this.isEditPriority = true;
  }

  editTimeRow(element: any) {
    this.editedTime = true;
    this.Time.ID = element.ID;
    this.Time.TimeLimit = element.TimeLimit;
  }

  deleteWOType(element: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete WOType!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.RMConfigurationService.deleteWOType(element).subscribe(
          (success) => {
            if (success.status == true) {
              this.toaster.success(success.message, 'Success', {
                timeOut: 3000,
              });
            } else {
              this.toaster.warning(success.message, 'Warning', {
                timeOut: 3000,
              });
            }

            this.spinner.hide();
            this.getWOType();
          },
          (error) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
      }
      this.isEdit = false;
    });
  }

  deleteFailureRow(element: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete FailureMode!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        const data = {
          ID: element.ID,
        };
        this.RMConfigurationService.deleteFailureMode(data).subscribe(
          (success) => {
            if (success.status == true) {
              this.toaster.success(success.message, 'Success', {
                timeOut: 3000,
              });
            } else {
              this.toaster.warning(success.message, 'Warning', {
                timeOut: 3000,
              });
            }
            this.spinner.hide();
            this.getFailureMode();
          },
          (error) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
      }
      this.edited = false;
    });
  }

  deletePriority(element: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Priority!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.RMConfigurationService.deletePriority(element).subscribe(
          (success) => {
            if (success.status == true) {
              this.toaster.success(success.message, 'Success', {
                timeOut: 3000,
              });
            } else {
              this.toaster.warning(success.message, 'Warning', {
                timeOut: 3000,
              });
            }
            this.spinner.hide();
            this.getAllPriority();
          },
          (error) => {
            this.spinner.hide();
            this.toaster.error('Error', error.error.message);
          }
        );
      }
      this.isEditPriority = false;
    });
  }

  WOTypeSubmit(formDirective: FormGroupDirective) {
    this.spinner.show();
    if (this.HeaderForm.status == 'VALID') {
      if (this.WOTypeForm.status == 'INVALID') {
        this.toaster.warning('Warning', 'Please Fill All Required Fields');
        this.spinner.hide();
        return;
      } else {
        this.formDirective = formDirective;
        this.WOType.CreatedAt = new Date();
        this.WOType.CreatedBy = this.user.UserId;
        this.WOType.RMPM = 'RM';
        const data = {
          Data: this.WOType,
          HeaderData: this.HeaderData,
        };
        if (!this.isEdit) {
          const chkWOType = this.WOTypeList.find((wo: any) => {
            const a = wo.Name.toLowerCase();
            const b = this.WOType.Name.toLowerCase();
            return a == b;
          });
          if (chkWOType) {
            this.toaster.warning('Warning', 'This WOType Already Exist');
            this.spinner.hide();
            return;
          }
          this.RMConfigurationService.addWOType(data).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.WOTypeForm.reset();
              this.isEdit = false;
              if (res.status) {
                this.toaster.success('Date Added Successfully', res.message);
                this.formDirective.resetForm();
                this.WOTypeForm.reset();
                this.getWOType();
              }
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
        } else {
          const chkWOType = this.WOTypeList.find((wo: any) => {
            const a = wo.Name.toLowerCase();
            const b = this.WOType.Name.toLowerCase();
            return a == b && this.WOType.ID != wo.ID;
          });
          if (chkWOType) {
            this.toaster.warning('Warning', 'This WOType Already Exist');
            this.spinner.hide();
            return;
          }
          this.RMConfigurationService.updateWOType(data).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.WOTypeForm.reset();
              this.isEdit = false;
              if (res.status) {
                this.toaster.success('Date Updated Successfully', res.message);
                this.formDirective.resetForm();
                this.WOTypeForm.reset();
                this.getWOType();
              }
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
        }
      }
    } else {
      this.toaster.warning('Warning', 'Please Fill Header Fields');
      this.spinner.hide();
      return;
    }
  }

  FailureModeOnSubmit(formDirective: FormGroupDirective) {
    if (this.HeaderForm.status == 'VALID') {
      if (this.FailureModeForm.invalid) {
        this.toaster.warning('Warning', 'Please Fill All Required Fields');
        return;
      } else {
        this.formDirective = formDirective;
        this.FailureMode.CreatedAt = new Date();
        this.FailureMode.CreatedBy = this.user.UserId;
        const data = {
          Data: this.FailureMode,
          HeaderData: this.HeaderData,
        };
        if (!this.edited) {
          const chkFailureMode = this.FailureModeList.find((fm: any) => {
            const a = fm.Name.toLowerCase();
            const b = this.FailureMode.Name.toLowerCase();
            return a == b && this.FailureMode.WOType == fm.WOType.ID;
          });
          if (chkFailureMode) {
            this.toaster.warning('Warning', 'This FailureMode Already Exist');
            return;
          }
          this.RMConfigurationService.addFailureMode(data).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.formDirective.resetForm();
              this.FailureModeForm.reset();
              this.edited = false;
              if (res.status) {
                this.toaster.success(res.message, 'Success');
                this.getFailureMode();
              }
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
        } else {
          const chkFailureMode = this.FailureModeList.find((fm: any) => {
            const a = fm.Name.toLowerCase();
            const b = this.FailureMode.Name.toLowerCase();
            return a == b && this.FailureMode.WOType == fm.WOType.ID && this.FailureMode.ID != fm.ID;
          });
          if (chkFailureMode) {
            this.toaster.warning('Warning', 'This FailureMode Already Exist');
            return;
          }
          this.RMConfigurationService.updateFailureMode(data).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.formDirective.resetForm();
              this.FailureModeForm.reset();
              this.edited = false;
              if (res.status) {
                this.toaster.success(res.message, 'Success');
                this.getFailureMode();
              }
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
        }
        this.formDirective.resetForm();
        this.FailureModeForm.reset();
      }
    } else {
      this.toaster.warning('Warning', 'Please Fill Header Fields');
      return;
    }
  }

  PrioritySubmit(formDirective: FormGroupDirective) {
    if (this.HeaderForm.status == 'VALID') {
      if (this.PriorityForm.status == 'INVALID') {
        this.toaster.warning('Warning', 'Please Fill All Required Fields');
        return;
      } else {
        this.formDirective = formDirective;
        this.Priority.CreatedAt = new Date();
        this.Priority.CreatedBy = this.user.UserId;
        const data = {
          Data: this.Priority,
          HeaderData: this.HeaderData,
        };
        if (!this.isEditPriority) {
          const chkPriority = this.PriorityList.find((pri: any) => {
            const a = pri.Name.toLowerCase();
            const b = this.Priority.Name.toLowerCase();
            return a == b;
          });
          if (chkPriority) {
            this.toaster.warning('Warning', 'This Priority Already Exist');
            return;
          }
          this.RMConfigurationService.addPriority(data).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.PriorityForm.reset();
              this.isEditPriority = false;
              if (res.status) {
                this.toaster.success('Date Added Successfully', res.message);
                this.formDirective.resetForm();
                this.PriorityForm.reset();
                this.getAllPriority();
              }
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
        } else {
          const chkPriority = this.PriorityList.find((pri: any) => {
            const a = pri.Name.toLowerCase();
            const b = this.Priority.Name.toLowerCase();
            return a == b && this.Priority.ID != pri.ID;
          });
          if (chkPriority) {
            this.toaster.warning('Warning', 'This Priority Already Exist');
            return;
          }
          this.RMConfigurationService.updatePriority(data).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.PriorityForm.reset();
              this.isEditPriority = false;
              if (res.status) {
                this.toaster.success('Date Updated Successfully', res.message);
                this.formDirective.resetForm();
                this.PriorityForm.reset();
                this.getAllPriority();
              }
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
        }
      }
    } else {
      this.toaster.warning('Warning', 'Please Fill Header Fields');
      return;
    }
  }

  TimeOnSubmit(formDirective: FormGroupDirective) {
    if (this.HeaderForm.status == 'VALID') {
      if (this.TimeForm.invalid) {
        this.toaster.warning('Warning', 'Please Fill All Required Fields');
        return;
      } else if (this.Time.TimeLimit < 30) {
        this.toaster.warning(
          'Minimum Delayed Time Value Is 30 Minutes',
          'Warning'
        );
        return;
      } else {
        this.formDirective = formDirective;
        this.Time.CreatedAt = new Date();
        this.Time.CreatedBy = this.user.UserId;
        const data = {
          Data: this.Time,
          HeaderData: this.HeaderData,
        };

        if (!this.editedTime) {
          const chkDelayTime = this.TimeList.find((t: any) => {
            const a = t.TimeLimit;
            const b = this.Time.TimeLimit;
            return a == b;
          });
          if (chkDelayTime) {
            this.toaster.warning('Warning', 'Delay Time Already Exist');
            return;
          }
          // if (this.TimeList && this.TimeList.length) {
          //   this.toaster.warning('Warning', 'Delay Time Already Exist');
          //   return;
          // }
          this.RMConfigurationService.addTime(data).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.editedTime = false;
              if (res.status) {
                this.toaster.success('Date Added Successfully', res.message);
                this.formDirective.resetForm();
                this.TimeForm.reset();
                this.getTime();
              }
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
        } else {
          const chkDelayTime = this.TimeList.find((t: any) => {
            const a = t.TimeLimit;
            const b = this.Time.TimeLimit;
            return a == b && this.Time.ID != t.ID;
          });
          if (chkDelayTime) {
            this.toaster.warning('Warning', 'Delay Time Already Exist');
            return;
          }
          this.RMConfigurationService.updateTime(data).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.editedTime = false;
              if (res.status) {
                this.toaster.success('Date Updated Successfully', res.message);
                this.formDirective.resetForm();
                this.TimeForm.reset();
                this.getTime();
              }
            },
            (error: any) => {
              this.spinner.hide();
              this.toaster.error('Error', error.message);
            }
          );
        }
      }
    } else {
      this.toaster.warning('Warning', 'Please Fill Header Fields');
      return;
    }
  }

  TechnicianSubmit() {
    if (this.HeaderForm.status == 'VALID') {
      const index: number = this.Technician.Name.indexOf('ALL');
      if (index !== -1) {
        this.Technician.Name.splice(index, 1);
      }
      this.Technician.CreatedAt = new Date();
      this.Technician.CreatedBy = this.user.UserId;
      const data = {
        Data: this.Technician,
        HeaderData: this.HeaderData,
      };
      this.RMConfigurationService.addTechnician(data).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status) {
            this.toaster.success(res.message, 'Success');
            this.getAllTechnician();
          }
        },
        (error: any) => {
          this.spinner.hide();
          this.toaster.error(error.message, 'Error');
        }
      );
    } else {
      this.toaster.warning('Warning', 'Please Fill Header Fields');
      return;
    }
  }

  //common
  getPaginator(dataSource: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataSource.paginator = this.paginator.toArray()[index];
      dataSource.sort = this.sort.toArray()[index];
    }, 100);
  }

  customePaginator(event: any, name: any) {
    if (name == 'failuremode') {
      this.page.pageIndex = event.pageIndex;
      this.page.pageSize = event.pageSize;
    } else if (name == 'wotype') {
      this.WOTypePage.pageIndex = event.pageIndex;
      this.WOTypePage.pageSize = event.pageSize;
    } else if (name == 'priority') {
      this.PriorityPage.pageIndex = event.pageIndex;
      this.PriorityPage.pageSize = event.pageSize;
    } else if (name == 'time') {
      this.TimePage.pageIndex = event.pageIndex;
      this.TimePage.pageSize = event.pageSize;
    }
  }

  openFile() {
    this.fileInput.nativeElement.click();
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }

  closePlantGroupModel(): void {
    this.updateOrderprocessingData = false;
  }

  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  getUserList() {
      
    this.spinnerText = 'Fetching User Data';
    
    const data = {
      EGId: this.HeaderData.Group,
      PlantId: this.HeaderData.Plant,
    };
    this.commonService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        if (res.employeeInfoList && res.employeeInfoList.length) {
          this.orgUserList = res.employeeInfoList.filter(
            (emp: any) => emp.Dept_Desc == 'Maintenance' && emp.UserId
          );

          this.raci = res.employeeInfoList.filter(
            (emp: any) => emp.Dept_Desc == 'Maintenance' && emp.UserId
          );   
          this.spinner.hide();
          this.getAllTechnician();
        } else {
          this.orgUserList = [];
        this.availableUsersList = [...this.orgUserList];
      
        this.userList = [...this.availableUsersList];
        this.spinner.hide();
       
        
        }
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  setUserName(val: any) {
    if (this.userList && this.userList.length) {
      const CreatedBy = this.userList.find((usr: any) => usr.UserId == val);
      return CreatedBy ? CreatedBy.fullName :  '';
    } 
  }

  searchValues(filter: any) {
    filter = filter.target.value;
    const selectedValues = [];
    this.userList = this.availableUsersList.filter((user: any) => {
      let lowerCase = user.fullName.toLocaleLowerCase();
      return user.fullName.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  getDropdownData(i: number) {
    if (i == 1) {
      const ind = this.GroupList.findIndex(
        (obj: any) => obj.egId == this.HeaderData.Group
      );
      this.PlantList = this.GroupList[ind].plants;
      for (var i = 0; i < this.PlantList.length; i++) {
        if (this.PlantList[i].isDeleted == true) {
          this.PlantList.splice(i, 1);
          i--;
        }
      }
      this.PlantList.sort(function (a: any, b: any) {
        return a.ptId - b.ptId;
      });
    } else if (i == 2) {
      this.getWOType();
      this.getFailureMode();
      this.getAllPriority();
      this.getTime();
      this.getUserList();
      // this.getTechnicianList();
    }
  }

  openTreeModal(): void {
    const key = {
      EGId: this.HeaderData.Group,
      level: 'Plant',
      CreatedBy: 0,
      IsOptional: 0,
    };
    this.spinner.show();
    this.spinnerText = 'Fetching Tree Data';
    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (!this.dialogBox) {
          this.updateOrderprocessingData = true;
          const plantGroupData: PlantGroupElement[] = data.TreeModalList;
          this.plantGroupSource.data = plantGroupData;
          this.udpateNodeParentDetails();
          this.treeControl.dataNodes = this.plantGroupSource.data;
        } else {
          this.plantGroupSource.data = data.TreeModalList;
          this.udpateNodeParentDetails();
          this.dialogBox = false;
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  udpateNodeParentDetails() {
    if (this.plantGroupSource.data && this.plantGroupSource.data.length) {
      this.plantGroupSource.data.forEach((group, groupIndex) => {
        if (group.children && group.children.length) {
          group.children.forEach((plant, plantIndex) => {
            plant.groupName = group.text;
            plant.groupId = group.PrimaryId;
            if (plant.children && plant.children.length) {
              plant.children.forEach((division: any, divisionIndex: number) => {
                division.plantId = plant.PrimaryId;
                division.plantName = plant.text;
                division.groupName = group.text;
                division.groupId = group.PrimaryId;
                if (division.children && division.children.length) {
                  division.children.forEach(
                    (department: any, departmentIndex: number) => {
                      department.divisionId = division.PrimaryId;
                      department.divisionName = division.text;
                      department.plantId = plant.PrimaryId;
                      department.plantName = plant.text;
                      department.groupId = group.PrimaryId;
                      department.groupName = group.text;
                      if (department.children && department.children.length) {
                        department.children.forEach((line: any) => {
                          line.departmentId = department.PrimaryId;
                          line.departmentName = department.text;
                          line.divisionId = division.PrimaryId;
                          line.divisionName = division.text;
                          line.plantId = plant.PrimaryId;
                          line.plantName = plant.text;
                          line.groupId = group.PrimaryId;
                          line.groupName = group.text;
                          if (line.children && line.children.length) {
                            line.children.forEach((unit: any) => {
                              unit.lineId = line.PrimaryId;
                              unit.lineName = line.text;
                              unit.departmentId = department.PrimaryId;
                              unit.departmentName = department.text;
                              unit.divisionId = division.PrimaryId;
                              unit.divisionName = division.text;
                              unit.plantId = plant.PrimaryId;
                              unit.plantName = plant.text;
                              unit.groupId = group.PrimaryId;
                              unit.groupName = group.text;
                              if (unit.children && unit.children.length) {
                                unit.children.forEach((assembly: any) => {
                                  assembly.unitName = unit.text;
                                  assembly.unitId = unit.PrimaryId;
                                  assembly.lineId = line.PrimaryId;
                                  assembly.lineName = line.text;
                                  assembly.departmentId = department.PrimaryId;
                                  assembly.departmentName = department.text;
                                  assembly.divisionId = division.PrimaryId;
                                  assembly.divisionName = division.text;
                                  assembly.plantId = plant.PrimaryId;
                                  assembly.plantName = plant.text;
                                  assembly.groupId = group.PrimaryId;
                                  assembly.groupName = group.text;
                                  if (
                                    assembly.children &&
                                    assembly.children.length
                                  ) {
                                    assembly.children.forEach(
                                      (subAssembly: any) => {
                                        subAssembly.assemblyName =
                                          assembly.text;
                                        subAssembly.assemblyId =
                                          assembly.PrimaryId;
                                        subAssembly.unitName = unit.text;
                                        subAssembly.unitId = unit.PrimaryId;
                                        subAssembly.lineId = line.PrimaryId;
                                        subAssembly.lineName = line.text;
                                        subAssembly.departmentId =
                                          department.PrimaryId;
                                        subAssembly.departmentName =
                                          department.text;
                                        subAssembly.divisionId =
                                          division.PrimaryId;
                                        subAssembly.divisionName =
                                          division.text;
                                        subAssembly.plantId = plant.PrimaryId;
                                        subAssembly.plantName = plant.text;
                                        subAssembly.groupId = group.PrimaryId;
                                        subAssembly.groupName = group.text;
                                        if (
                                          subAssembly.children &&
                                          subAssembly.children.length
                                        ) {
                                          subAssembly.children.forEach(
                                            (component: any) => {
                                              component.subAssemblyName =
                                                subAssembly.text;
                                              component.subAssemblyId =
                                                subAssembly.PrimaryId;
                                              component.assemblyName =
                                                assembly.text;
                                              component.assemblyId =
                                                assembly.PrimaryId;
                                              component.unitName = unit.text;
                                              component.unitId = unit.PrimaryId;
                                              component.lineId = line.PrimaryId;
                                              component.lineName = line.text;
                                              component.departmentId =
                                                department.PrimaryId;
                                              component.departmentName =
                                                department.text;
                                              component.divisionId =
                                                division.PrimaryId;
                                              component.divisionName =
                                                division.text;
                                              component.plantId =
                                                plant.PrimaryId;
                                              component.plantName = plant.text;
                                              component.groupId =
                                                group.PrimaryId;
                                              component.groupName = group.text;
                                            }
                                          );
                                        }
                                      }
                                    );
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    }
                  );
                }
              });
            }
          });
        }
      });
    }
  }

  openDialog(name: any): void {
    if (this.HeaderData.Group && this.HeaderData.Plant) {
      this.dialogBox = true;
      this.openTreeModal();
      let dialogRef = this.dialog.open(PreventiveDialogComponent, {
        disableClose: true,
        width: '500px',
        data: { moduleName: name },
      });

      dialogRef.afterClosed().subscribe((result) => {
      
        if (result == undefined) {
        } else {
          this.excelFile = result;
          this.validateExcelData();
        }
      });
    } else {
      this.toaster.warning('Please Fill Header Fields', 'Warning');
      return;
    }
  }

  validateExcelData() {
    this.WOTypeMasterData = [];
    this.FailureModeMasterData = [];
    this.PriorityMasterData = [];
    this.DelayTimeMasterData = [];
    this.TechnicianMasterData = [];
    var WORow = 1;
    var FMRow = 1;
    var PriRow = 1;
    var DTRow = 1;
    var TechRow = 1;

    if (
      this.excelFile &&
      this.excelFile.WOType &&
      this.excelFile.WOType.length
    ) {
      for (let obj of this.excelFile.WOType) {
        if (!obj['Group']) {
          if (!obj['Group']) {
            this.toaster.error(
              `Group can't be empty on row ${WORow} in Work Order Type Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }

          this.excelFile = [];
          this.WOTypeMasterData = [];
          return;
        } else if (!obj['Plant']) {
          if (!obj['Plant']) {
            this.toaster.error(
              `Plant can't be empty on row ${WORow} in Work Order Type Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }

          this.excelFile = [];
          this.WOTypeMasterData = [];
          return;
        } else if (!obj['WO Type Name']) {
          if (!obj['WO Type Name']) {
            this.toaster.error(
              `WO Type can't be empty on row ${WORow} in Work Order Type Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }

          this.excelFile = [];
          this.WOTypeMasterData = [];
          return;
        } else {
          let groupobj: any;
          let plantobj: any;
          let uniqueData: any;
          let existXLData: any;

          const WOTypeData: any = {
            Group: '',
            Plant: '',
            Name: '',
            CreatedAt: '',
            CreatedBy: '',
          };

          groupobj = this.plantGroupSource.data.find((grp: any) => {
            const a = obj['Group'].trim().toLowerCase();
            const b = grp.text.trim().toLowerCase();
            return a == b;
          });

          if (groupobj) {
            WOTypeData.Group = groupobj.PrimaryId;
            plantobj = groupobj.children.find((pnt: any) => {
              const a = obj['Plant'].trim().toLowerCase();
              const b = pnt.text.trim().toLowerCase();
              return a == b;
            });
            if (plantobj) {
              WOTypeData.Plant = plantobj.PrimaryId;
              uniqueData = this.AllWOType.find((wo: any) => {
                const a = obj['WO Type Name'].trim().toLowerCase();
                const b = wo.Name.trim().toLowerCase();
                return (
                  wo.Group &&
                  wo.Group.egId == WOTypeData.Group &&
                  wo.Plant &&
                  wo.Plant.ptId == WOTypeData.Plant &&
                  a == b
                );
              });
              if (!uniqueData) WOTypeData.Name = obj['WO Type Name'];
            } else {
              this.toaster.error(
                `Plant ${obj['Plant']} not found in database on row ${WORow} in Work Order Type Sheet`,
                'Error',
                {
                  timeOut: 3000,
                }
              );
              this.excelFile = [];
              this.WOTypeMasterData = [];
              return;
            }
          } else {
            this.toaster.error(
              `Group ${obj['Group']} not found in database on row ${WORow} in Work Order Type Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
            this.excelFile = [];
            this.WOTypeMasterData = [];
            return;
          }

          WOTypeData.CreatedAt = new Date();
          WOTypeData.CreatedBy = this.user.UserId;

          if (WORow != 1) {
            existXLData = this.WOTypeMasterData.find((wo: any) => {
              return (
                wo.Name == WOTypeData.Name &&
                wo.Group == WOTypeData.Group &&
                wo.Plant == WOTypeData.Plant
              );
            });
          }
          if (!uniqueData && !existXLData)
            this.WOTypeMasterData.push(WOTypeData);
          else{
            this.toaster.error(
              `Work Order Type Name Already Exist in Database or Sheet has duplicate data on row ${WORow} in Work Order Type Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
            return;
          }
        }
        WORow++;
      }
    }

    if (
      this.excelFile &&
      this.excelFile.FailureMode &&
      this.excelFile.FailureMode.length
    ) {
      for (let obj of this.excelFile.FailureMode) {
        if (!obj['Group']) {
          if (!obj['Group']) {
            this.toaster.error(
              `Group can't be empty on row ${FMRow} in Failure Mode Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          return;
        } else if (!obj['Plant']) {
          if (!obj['Plant']) {
            this.toaster.error(
              `Plant can't be empty on row ${FMRow} in Failure Mode Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          return;
        } else if (!obj['Failure Mode Name']) {
          if (!obj['Failure Mode Name']) {
            this.toaster.error(
              `Failure Mode Name can't be empty on row ${FMRow} in Failure Mode Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          return;
        } else if (!obj['Work Order Type']) {
          if (!obj['Work Order Type']) {
            this.toaster.error(
              `Work Order Type can't be empty on row ${FMRow} in Failure Mode Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          return;
        } else {
          let groupobj: any;
          let plantobj: any;
          let woType: any;
          let uniqueData: any;
          let existXLData: any;

          const FailureModeData: any = {
            Group: '',
            Plant: '',
            Name: '',
            WOType: '',
            CreatedAt: '',
            CreatedBy: '',
          };

          groupobj = this.plantGroupSource.data.find((grp: any) => {
            const a = obj['Group'].trim().toLowerCase();
            const b = grp.text.trim().toLowerCase();
            return a == b;
          });

          if (groupobj) {
            FailureModeData.Group = groupobj.PrimaryId;
            plantobj = groupobj.children.find((pnt: any) => {
              const a = obj['Plant'].trim().toLowerCase();
              const b = pnt.text.trim().toLowerCase();
              return a == b;
            });
            if (plantobj) {
              FailureModeData.Plant = plantobj.PrimaryId;
              uniqueData = this.AllFailureMode.find((fm: any) => {
                const a = obj['Failure Mode Name'].trim().toLowerCase();
                const b = fm.Name.trim().toLowerCase();
                return (
                  fm.Group &&
                  fm.Group.egId == FailureModeData.Group &&
                  fm.Plant &&
                  fm.Plant.ptId == FailureModeData.Plant &&
                  a == b
                );
              });
              if (!uniqueData) {
                woType = this.WOTypeList.find((wo: any) => {
                  const a = obj['Work Order Type'].trim().toLowerCase();
                  const b = wo.Name.trim().toLowerCase();
                  return a == b;
                });
                if (woType) {
                  FailureModeData.Name = obj['Failure Mode Name'];
                  FailureModeData.WOType = woType.ID;
                } else {
                  this.toaster.error(
                    `Work Order Type ${obj['Work Order Type']} not found in database on row ${FMRow} in Failure Mode Sheet`,
                    'Error',
                    {
                      timeOut: 3000,
                    }
                  );
                  this.excelFile = [];
                  this.WOTypeMasterData = [];
                  this.FailureModeMasterData = [];
                  return;
                }
              }
            } else {
              this.toaster.error(
                `Plant ${obj['Plant']} not found in database on row ${FMRow} in Failure Mode Sheet`,
                'Error',
                {
                  timeOut: 3000,
                }
              );
              this.excelFile = [];
              this.WOTypeMasterData = [];
              this.FailureModeMasterData = [];
              return;
            }
          } else {
            this.toaster.error(
              `Group ${obj['Group']} not found in database on row ${FMRow} in Failure Mode Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
            this.excelFile = [];
            this.WOTypeMasterData = [];
            this.FailureModeMasterData = [];
            return;
          }

          FailureModeData.CreatedAt = new Date();
          FailureModeData.CreatedBy = this.user.UserId;

          if (FMRow != 1) {
            existXLData = this.FailureModeMasterData.find((fm: any) => {
              return (
                fm.Name == FailureModeData.Name &&
                fm.Group == FailureModeData.Group &&
                fm.Plant == FailureModeData.Plant
              );
            });
          }
          if (!uniqueData && !existXLData)
            this.FailureModeMasterData.push(FailureModeData);
          else{
            this.toaster.error(
              `Failure Mode Already Exist in Database or Sheet has duplicate data on row ${FMRow} in Failure Mode Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
            return;
          }
        }
        FMRow++;
      }
    }

    if (
      this.excelFile &&
      this.excelFile.Priority &&
      this.excelFile.Priority.length
    ) {
      for (let obj of this.excelFile.Priority) {
        if (!obj['Group']) {
          if (!obj['Group']) {
            this.toaster.error(
              `Group can't be empty on row ${PriRow} in Priority Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          this.PriorityMasterData = [];
          return;
        } else if (!obj['Plant']) {
          if (!obj['Plant']) {
            this.toaster.error(
              `Plant can't be empty on row ${PriRow} in Priority Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          this.PriorityMasterData = [];
          return;
        } else if (!obj['Priority Name']) {
          if (!obj['Priority Name']) {
            this.toaster.error(
              `Priority Name can't be empty on row ${PriRow} in Priority Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          this.PriorityMasterData = [];
          return;
        } else {
          let groupobj: any;
          let plantobj: any;
          let uniqueData: any;
          let existXLData: any;

          const PriorityData: any = {
            Group: '',
            Plant: '',
            Name: '',
            CreatedAt: '',
            CreatedBy: '',
          };

          groupobj = this.plantGroupSource.data.find((grp: any) => {
            const a = obj['Group'].trim().toLowerCase();
            const b = grp.text.trim().toLowerCase();
            return a == b;
          });

          if (groupobj) {
            PriorityData.Group = groupobj.PrimaryId;
            plantobj = groupobj.children.find((pnt: any) => {
              const a = obj['Plant'].trim().toLowerCase();
              const b = pnt.text.trim().toLowerCase();
              return a == b;
            });
            if (plantobj) {
              PriorityData.Plant = plantobj.PrimaryId;
              uniqueData = this.AllPriority.find((pri: any) => {
                const a = obj['Priority Name'].trim().toLowerCase();
                const b = pri.Name.trim().toLowerCase();
                return (
                  pri.Group &&
                  pri.Group.egId == PriorityData.Group &&
                  pri.Plant &&
                  pri.Plant.ptId == PriorityData.Plant &&
                  a == b
                );
              });
              if (!uniqueData) PriorityData.Name = obj['Priority Name'];
            } else {
              this.toaster.error(
                `Plant ${obj['Plant']} not found in database on row ${PriRow} in Priority Sheet`,
                'Error',
                {
                  timeOut: 3000,
                }
              );
              this.excelFile = [];
              this.WOTypeMasterData = [];
              this.FailureModeMasterData = [];
              this.PriorityMasterData = [];
              return;
            }
          } else {
            this.toaster.error(
              `Group ${obj['Group']} not found in database on row ${PriRow} in Priority Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
            this.excelFile = [];
            this.WOTypeMasterData = [];
            this.FailureModeMasterData = [];
            this.PriorityMasterData = [];
            return;
          }

          PriorityData.CreatedAt = new Date();
          PriorityData.CreatedBy = this.user.UserId;

          if (PriRow != 1) {
            existXLData = this.PriorityMasterData.find((pri: any) => {
              return (
                pri.Name == PriorityData.Name &&
                pri.Group == PriorityData.Group &&
                pri.Plant == PriorityData.Plant
              );
            });
          }
          if (!uniqueData && !existXLData)
            this.PriorityMasterData.push(PriorityData);
          else{
            this.toaster.error(
              `Priority Name Already Exist in Database or Sheet has duplicate data on row ${PriRow} in Priority Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
            return;
          }
        }
        PriRow++;
      }
    }

    if (
      this.excelFile &&
      this.excelFile.DelayTime &&
      this.excelFile.DelayTime.length
    ) {
      for (let obj of this.excelFile.DelayTime) {
        if (!obj['Group']) {
          if (!obj['Group']) {
            this.toaster.error(
              `Group can't be empty on row ${DTRow} DelayTime Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          this.PriorityMasterData = [];
          this.DelayTimeMasterData = [];
          return;
        } else if (!obj['Plant']) {
          if (!obj['Plant']) {
            this.toaster.error(
              `Plant can't be empty on row ${DTRow} DelayTime Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          this.PriorityMasterData = [];
          this.DelayTimeMasterData = [];
          return;
        } else if (
          !obj['Delay Time / min'] ||
          typeof obj['Delay Time / min'] != 'number'
        ) {
          if (!obj['Delay Time / min']) {
            this.toaster.error(
              `Delay Time can't be empty on row ${DTRow} DelayTime Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
            this.excelFile = [];
            this.WOTypeMasterData = [];
            this.FailureModeMasterData = [];
            this.PriorityMasterData = [];
            this.DelayTimeMasterData = [];
            return;
          }
          if (typeof obj['Delay Time / min'] != 'number') {
            this.toaster.error(
              `Delay Time should be a number on row ${DTRow} DelayTime Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          this.PriorityMasterData = [];
          this.DelayTimeMasterData = [];
          return;
        } else {
          let groupobj: any;
          let plantobj: any;
          let uniqueData: any;
          let existXLData: any;

          const TimeData: any = {
            Group: '',
            Plant: '',
            TimeLimit: '',
            CreatedAt: '',
            CreatedBy: '',
          };

          groupobj = this.plantGroupSource.data.find((grp: any) => {
            const a = obj['Group'].trim().toLowerCase();
            const b = grp.text.trim().toLowerCase();
            return a == b;
          });

          if (groupobj) {
            TimeData.Group = groupobj.PrimaryId;
            plantobj = groupobj.children.find((pnt: any) => {
              const a = obj['Plant'].trim().toLowerCase();
              const b = pnt.text.trim().toLowerCase();
              return a == b;
            });
            if (plantobj) {
              TimeData.Plant = plantobj.PrimaryId;
              uniqueData = this.AllDelayTime.find(
                (time: any) =>
                  time.Group.egId == TimeData.Group &&
                  time.Plant.ptId == TimeData.Plant &&
                  time.TimeLimit == obj['Delay Time / min']
              );
              if (obj['Delay Time / min'] < 30) {
                this.toaster.error(
                  `Minimum Delayed Time Value Is 30 on row ${DTRow} DelayTime Sheet`,
                  'Error',
                  {
                    timeOut: 3000,
                  }
                );
                this.excelFile = [];
                this.WOTypeMasterData = [];
                this.FailureModeMasterData = [];
                this.PriorityMasterData = [];
                this.DelayTimeMasterData = [];
                return;
              } else TimeData.TimeLimit = obj['Delay Time / min'];
            } else {
              this.toaster.error(
                `Plant ${obj['Plant']} not found in database on row ${DTRow} DelayTime Sheet`,
                'Error',
                {
                  timeOut: 3000,
                }
              );
              this.excelFile = [];
              this.WOTypeMasterData = [];
              this.FailureModeMasterData = [];
              this.PriorityMasterData = [];
              this.DelayTimeMasterData = [];
              return;
            }
          } else {
            this.toaster.error(
              `Group ${obj['Group']} not found in database on row ${DTRow} DelayTime Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
            this.excelFile = [];
            this.WOTypeMasterData = [];
            this.FailureModeMasterData = [];
            this.PriorityMasterData = [];
            this.DelayTimeMasterData = [];
            return;
          }

          TimeData.CreatedAt = new Date();
          TimeData.CreatedBy = this.user.UserId;

          if (DTRow != 1) {
            existXLData = this.DelayTimeMasterData.find((dt: any) => {
              return (dt.Group == TimeData.Group && dt.Plant == TimeData.Plant && dt.TimeLimit == TimeData.TimeLimit);
            });
          }
          if (!uniqueData && !existXLData)
            this.DelayTimeMasterData.push(TimeData);
          else{
            this.toaster.error(
              `Delay Time Already Exist in Database or Sheet has duplicate data on row ${DTRow} in DelayTime`,
              'Error',
              {
                timeOut: 3000,
              }
            );
            return;
          }
        }
        DTRow++;
      }
    }

    if (
      this.excelFile &&
      this.excelFile.Technician &&
      this.excelFile.Technician.length
    ) {
      for (let obj of this.excelFile.Technician) {
        if (!obj['Group']) {
          if (!obj['Group']) {
            this.toaster.error(
              `Group can't be empty on row ${TechRow} in Technician Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          this.PriorityMasterData = [];
          this.DelayTimeMasterData = [];
          this.TechnicianMasterData = [];
          return;
        } else if (!obj['Plant']) {
          if (!obj['Plant']) {
            this.toaster.error(
              `Plant can't be empty on row ${TechRow} in Technician Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          this.PriorityMasterData = [];
          this.DelayTimeMasterData = [];
          this.TechnicianMasterData = [];
          return;
        } else if (!obj['User Name']) {
          if (!obj['User Name']) {
            this.toaster.error(
              `User Name can't be empty on row ${TechRow} in Technician Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
          }
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          this.PriorityMasterData = [];
          this.DelayTimeMasterData = [];
          this.TechnicianMasterData = [];
          return;
        } else {
          let groupobj: any;
          let plantobj: any;
          let checkgrppnt: any;
          let uniqueData: any;
          let existXLData: any;
          let existXLTech: any;
          const TechnicianData: any = {
            Data: { ID: 0, RMPM: 'RM', Name: [], CreatedAt: '', CreatedBy: '' },
            HeaderData: { Group: '', Plant: '' },
          };

          groupobj = this.plantGroupSource.data.find((grp: any) => {
            const a = obj['Group'].trim().toLowerCase();
            const b = grp.text.trim().toLowerCase();
            return a == b;
          });

          if (groupobj) {
            TechnicianData.HeaderData.Group = groupobj.PrimaryId;
            plantobj = groupobj.children.find((pnt: any) => {
              const a = obj['Plant'].trim().toLowerCase();
              const b = pnt.text.trim().toLowerCase();
              return a == b;
            });
            if (plantobj) {
              TechnicianData.HeaderData.Plant = plantobj.PrimaryId;
              let userobj = this.orgUserList.find((user: any) => {
                const isInt = Number.isInteger(obj['User Name']);
                const a = isInt
                  ? obj['User Name']
                  : obj['User Name'].trim().toLowerCase();
                const b = user.fullName.toLowerCase();
                return a == b;
              });
              if (userobj) {
                uniqueData = this.TechnicianList.find((tech: any) => {
                  const a = tech.UserId;
                  const b = userobj.UserId;
                  return a == b;
                });
                if (!uniqueData) {
                  TechnicianData.Data.Name.push(userobj.UserId);
                  if(TechRow != 1){
                    existXLData = this.TechnicianMasterData.Data.Name.find((tech: any) => {
                      const a = tech;
                      const b = userobj.UserId;
                      return a == b;
                    });
                  }
                } 
              } else {
                this.toaster.error(
                  `User Name ${obj['User Name']} not found in database on row ${TechRow} in Technician Sheet`,
                  'Error',
                  {
                    timeOut: 3000,
                  }
                );
                this.excelFile = [];
                this.WOTypeMasterData = [];
                this.FailureModeMasterData = [];
                this.PriorityMasterData = [];
                this.DelayTimeMasterData = [];
                this.TechnicianMasterData = [];
                return;
              }
            } else {
              this.toaster.error(
                `Plant ${obj['Plant']} not found in database on row ${TechRow} in Technician Sheet`,
                'Error',
                {
                  timeOut: 3000,
                }
              );
              this.excelFile = [];
              this.WOTypeMasterData = [];
              this.FailureModeMasterData = [];
              this.PriorityMasterData = [];
              this.DelayTimeMasterData = [];
              this.TechnicianMasterData = [];
              return;
            }
          } else {
            this.toaster.error(
              `Group ${obj['Group']} not found in database on row ${TechRow} in Technician Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
            this.excelFile = [];
            this.WOTypeMasterData = [];
            this.FailureModeMasterData = [];
            this.PriorityMasterData = [];
            this.DelayTimeMasterData = [];
            this.TechnicianMasterData = [];
            return;
          }

          TechnicianData.Data.CreatedAt = new Date();
          TechnicianData.Data.CreatedBy = this.user.UserId;
          if (!uniqueData && !existXLTech)
            this.TechnicianMasterData.push(TechnicianData);
          else{
            this.toaster.error(
              `Technician Already Exist in Database or Sheet has duplicate data on row ${TechRow} in Technician Sheet`,
              'Error',
              {
                timeOut: 3000,
              }
            );
            return;
          }
        }
        TechRow++;
      }
    }

    this.MasterRMDataOnSubmit();
  }

  MasterRMDataOnSubmit() {
    if (
      this.WOTypeMasterData &&
      this.WOTypeMasterData.length == 0 &&
      this.FailureModeMasterData &&
      this.FailureModeMasterData.length == 0 &&
      this.PriorityMasterData &&
      this.PriorityMasterData.length == 0 &&
      this.DelayTimeMasterData &&
      this.DelayTimeMasterData.length == 0 &&
      (this.TechnicianMasterData && this.TechnicianMasterData.length) == 0
    ) {
      this.toaster.warning('Please Fill The Sheets');
      return false;
    }
    this.spinnerText = 'Creating RM Master Data';
    this.spinner.show();
    if (this.WOTypeMasterData && this.WOTypeMasterData.length)
      this.WOTypeMasterData.map((row: any) => (row.RMPM = 'RM'));
    let data = {
      WOTypeMasterData: this.WOTypeMasterData,
      FailureModeMasterData: this.FailureModeMasterData,
      PriorityMasterData: this.PriorityMasterData,
      DelayTimeMasterData: this.DelayTimeMasterData,
      TechnicianMasterData: this.TechnicianMasterData,
    };
    this.RMConfigurationService.RMMasterUpload(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        // this.edited = false;
        if (res.status) {
          this.toaster.success('Data Added Successfully', res.message);
          this.getWOType();
          this.getFailureMode();
          this.getAllPriority();
          this.getTime();
          this.getAllTechnician();
          this.excelFile = [];
          this.WOTypeMasterData = [];
          this.FailureModeMasterData = [];
          this.PriorityMasterData = [];
          this.DelayTimeMasterData = [];
          this.TechnicianMasterData = [];
        }
      },
      (error: any) => {
        this.excelFile = [];
        this.WOTypeMasterData = [];
        this.FailureModeMasterData = [];
        this.PriorityMasterData = [];
        this.DelayTimeMasterData = [];
        this.TechnicianMasterData = [];
        this.spinner.hide();
        this.toaster.error('Error', error.message);
        this.TechnicianMasterData = [];
      }
    );
  }

  tableDataSetWOType(data: any) {
    this.WOTypeDataSource = new MatTableDataSource(data);
    this.getPaginator(this.WOTypeDataSource, 0);
  }

  tableDataSetPriority(data: any) {
    this.PrioritySource = new MatTableDataSource(data);
    this.getPaginator(this.PrioritySource, 2);
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  closeaddNewPlantItemModel(): void {
    this.displayComponentModal = false;
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  resetForm(form: FormGroup, value: any) {
    form.get(value)?.setValue('');
  }
}
