import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-matcheckbox-cell',
    template: `<mat-checkbox (change)="checkedHandler($event)" [checked]="params.value" [disabled] = "params.disable ? params.disable : false"></mat-checkbox>`
})
export class MatCheckboxRendererComponent implements ICellRendererAngularComp {
    params: any;
    refresh(params: any): boolean {
        return true;
    }
    agInit(params: any): void {
        this.params = params;
    }
    checkedHandler(event: any): void {
        const checked = event.checked;
        const colId = this.params.column.colId;
        this.params.node.setDataValue(colId, checked);

    }
}
