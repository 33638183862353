import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NcrCcrManagementComponent } from '../quality-management/ncr-ccr-management/ncr-ccr-management.component';
import { CcrManagementComponent } from '../quality-management/ncr-ccr-management/ccr-management/ccr-management.component';
import { CustomerManagementComponent } from '../quality-management/ncr-ccr-management/customer-management/customer-management.component';
import { CheckListComponent } from '../screens/check-List/check-List.component';
import { CheckListTypeComponent } from './../quality-management/quality-control/check-list-type/check-list-type.component';

import { QualityManagementRoutingModule } from './qualitymanagement-routing.module';

// Material
import { MaterialModule } from '../material-module';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectFilterModule } from 'mat-select-filter';
import { AgGridModule } from 'ag-grid-angular';
import { ProcessParameterLoginComponent } from '../ParameterLoginConfig/process-parameter-login/process-parameter-login.component';
import { QualityParameterLoginComponent } from '../ParameterLoginConfig/quality-parameter-login/quality-parameter-login.component';
import { CcrDashboardComponent } from '../screens/ccr-dashboard/ccr-dashboard.component';
import { AuditMasterDataComponent } from '../quality-management/audits/audit-master-data/audit-master-data.component';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { ContextMenuModule } from 'primeng/contextmenu';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { TableModule } from 'primeng/table';
import { SliderModule } from 'primeng/slider';
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ColorPickerModule } from 'primeng/colorpicker';
import { AreaComponent } from '../AuditMasterComponents/area/area.component';
import { AuditCategoriesComponent } from '../AuditMasterComponents/audit-categories/audit-categories.component';
import { ClassificationComponent } from '../AuditMasterComponents/Classification/Classification.component';
import { CheckpointComponent } from '../AuditMasterComponents/checkpoint/checkpoint.component';
import { ScoreTableComponent } from '../AuditMasterComponents/score-table/score-table.component';
import { ExecutionComponent } from '../AuditMasterComponents/execution/execution.component';
import { GloabalStandardComponent } from '../AuditMasterComponents/gloabal-standard/gloabal-standard.component';
import { QxMatrixComponent } from '../quality-management/quality-reports/qx-matrix/qx-matrix.component';
import { QxMatrixReportComponent } from '../quality-management/quality-reports/qx-matrix-report/qx-matrix-report.component';
import { QxUploadComponent } from '../quality-management/quality-reports/qx-upload/qx-upload.component';
import { AuditExecutionComponent } from '../quality-management/audits/audit-execution/audit-execution.component';
import { GembaWalkComponent } from '../quality-management/audits/gemba-walk/gemba-walk.component';
import { NcrApprovalComponent } from '../quality-management/ncr-approval/ncr-approval.component';
import { QualityMasterDataComponent } from '../quality-management/quality-control/quality-master-data/quality-master-data.component';
import { QualityMasterProcessComponent } from '../QualityMasterDataComponents/quality-master-process/quality-master-process.component';
import { QualityParameterComponent } from '../quality-management/quality-parameter/quality-parameter.component';
import { QualityAreaComponent } from '../QualityMasterDataComponents/quality-area/quality-area.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { QualitycheckexecutionComponent } from '../screens/check-List/execution/qualitycheckexecution.component';
import { CheckboxRenderer } from '../CheckboxRenderer';
import { ProcessControlComponent } from '../quality-management/process-control/process-control.component';
import { QualityCheckHistoryComponent } from '../quality-management/quality-check-history/quality-check-history.component';
import { QualityProcessControlComponent } from '../ParameterLoginConfig/quality-process-control/quality-process-control.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';
import { QualityshiftsComponent } from '../QualityMasterDataComponents/qualityshifts/qualityshifts.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { ProcessExecutionComponent } from '../quality-management/audits/process-execution/process-execution.component';
import { ProcessAuditCategoriesComponent } from '../AuditMasterComponents/process-audit-categories/process-audit-categories.component';
import { ProcessCheckpointComponent } from '../AuditMasterComponents/process-checkpoint/process-checkpoint.component';
import { UsersActionsComponent } from '../quality-management/UsersActions/UsersActions.component';
import { ProcessConfirmationDbComponent } from '../quality-management/process-confirmation-db/process-confirmation-db.component';
import { QcVerifyComponent } from '../quality-management/qc-verify-report/qc-verify/qc-verify.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { MatCheckboxRendererComponent } from '../mat-checkbox-renderer';
import { MatSelectRendererComponent } from '../mat-selectlist-renderer';
import { WarehouseReceivedComponent } from '../ProductionOrderComponents/warehouse/warehouse-received/warehouse-received.component';
import { QCBoxComponent } from '../ProductionOrderComponents/QC-Box/QC-Box.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ChartsModule } from 'ng2-charts';
import solidGauge from 'highcharts/modules/solid-gauge.src';
import highchartsTreemap from 'highcharts/modules/treemap.src';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HomeModule } from '../screens/home.module';
import { RawMaterialDefinitionComponent } from '../KPIs Management/KpiMasterComponents/rawMaterialDefinition/rawMaterialDefinition.component';
import { MatChipsModule } from '@angular/material/chips';
import { RawMaterialExecutionComponent } from '../KPIs Management/KpiMasterComponents/rawMaterialExecution/rawMaterialExecution.component';
import { ChemicalLabComponent } from '../opms-quality/chemical-lab/chemical-lab.component';
import { OpmsQualityComponent } from '../opms-quality/opms-quality.component';
import { GlassPhysicalQaReportComponent } from './glass-physical-qa-report/glass-physical-qa-report.component';
import { GlassQaDashboardComponent } from './glass-qa-dashboard/glass-qa-dashboard.component';
import { GlassPpcLiveGeneraldataComponent } from './glass-ppc-live-generaldata/glass-ppc-live-generaldata.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NgxPrintElementModule } from 'ngx-print-element';
import { NgxMatSelectModule } from 'ngx-mat-select';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AngularImageViewerModule } from 'angular-x-image-viewer';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import {
  NgxMatSelectConfigs,
  NGX_MAT_SELECT_CONFIGS,
} from '../ngx-mat-select/ngx-mat-select-configs';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { GlassChemicalQaReportComponent } from './glass-chemical-qa-report/glass-chemical-qa-report.component';
import { GlassChemicalQaDashboardComponent } from './glass-chemical-qa-dashboard/glass-chemical-qa-dashboard.component';
import { NcrActionMasterComponent } from '../quality-management/ncrActionMaster/ncrActionMaster.component';
import { PlantWiseImmediateNcrActionsComponent } from '../quality-management/PlantWiseImmediateNcrActions/PlantWiseImmediateNcrActions.component';
import { ChecklistReportConfigurationComponent } from '../quality-management/quality-control/checklist-report-configuration/checklist-report-configuration.component';
import { BtnCellRendererCheckListConfiguration } from '../btn-cellRenderer-quality-checklist-configuration';
import { SetAuditCategoriesComponent } from '../AuditMasterComponents/set-audit-categories/set-audit-categories.component';
import { MachineParameterTrendComponent } from './spcQualityDashboard/machine-parameter-trend/machine-parameter-trend.component';
import { QualityParameterTrendComponent } from './spcQualityDashboard/quality-parameter-trend/quality-parameter-trend.component';
import { ParameterAnalysisDashboardComponent } from './spcQualityDashboard/ParameterAnalysisDashboard/ParameterAnalysisDashboard.component';
// tslint:disable-next-line:typedef
export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more, solidGauge, highchartsTreemap];
}

export const ngxMatSelectConfigs: NgxMatSelectConfigs = {
  maxWidthForMobileView: 600,
  inFirstLoadCallSearcher: true,
  inFirstLoadSearcherValue: '',
  emptyLabel: 'no entry found',
  noMoreResultLabel: 'no more found',
  useInfiniteScroll: false,
  searchBoxPlaceholder: 'please search',
  maximumResultForShow: 50000,
  useMobileView: false,
  valueMember: 'key',
  displayMember: 'value',
  mobileViewType: 'FullScreen',
};
@NgModule({
  declarations: [
    CheckboxRenderer,
    MatCheckboxRendererComponent,
    MatSelectRendererComponent,
    NcrCcrManagementComponent,
    // NcrMangementComponent,
    CcrManagementComponent,
    CustomerManagementComponent,
    CheckListComponent,
    CheckListTypeComponent,
    QualitycheckexecutionComponent,
    ProcessParameterLoginComponent,
    QualityParameterLoginComponent,
    CcrDashboardComponent,
    AuditMasterDataComponent,
    AreaComponent,
    AuditCategoriesComponent,
    ClassificationComponent,
    PlantWiseImmediateNcrActionsComponent,
    CheckpointComponent,
    // ProcessConfirmationDbComponent,
    GloabalStandardComponent,
    NcrActionMasterComponent,
    ScoreTableComponent,
    ExecutionComponent,
    QxMatrixComponent,
    QxMatrixReportComponent,
    QxUploadComponent,
    AuditExecutionComponent,
    GembaWalkComponent,
    NcrApprovalComponent,
    QualityMasterDataComponent,
    QualityMasterProcessComponent,
    QualityshiftsComponent,
    QualityParameterComponent,
    QualityAreaComponent,
    QualityCheckHistoryComponent,
    QualityProcessControlComponent,
    ProcessControlComponent,
    ProcessExecutionComponent,
    ProcessAuditCategoriesComponent,
    ProcessCheckpointComponent,
    UsersActionsComponent,
    ProcessConfirmationDbComponent,
    QcVerifyComponent,
    MatCheckboxRendererComponent,
    WarehouseReceivedComponent,
    QCBoxComponent,
    RawMaterialDefinitionComponent,
    RawMaterialExecutionComponent,
    ChemicalLabComponent, 
    OpmsQualityComponent, 
    GlassPhysicalQaReportComponent, 
    GlassQaDashboardComponent, 
    GlassPpcLiveGeneraldataComponent, 
    GlassChemicalQaReportComponent, 
    GlassChemicalQaDashboardComponent,
    ChecklistReportConfigurationComponent, 
    BtnCellRendererCheckListConfiguration,
    SetAuditCategoriesComponent,
    MachineParameterTrendComponent,
    QualityParameterTrendComponent,
    ParameterAnalysisDashboardComponent
  ],
  imports: [
    NgxDropzoneModule,
    ChartModule,
    NgxSpinnerModule,
    MatSelectFilterModule,
    CommonModule,
    QualityManagementRoutingModule,
    MaterialModule,
    MatMenuModule,
    MatRadioModule,
    MatButtonModule,
    MatCheckboxModule,
    NzTimePickerModule,
    FlexLayoutModule,
    MatTableModule,
    MatPaginatorModule,
    NgxMatSelectSearchModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    SlickCarouselModule,
    MatTableExporterModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    ChartsModule,
    HomeModule,
    AgGridModule.withComponents([
      CheckboxRenderer,
      MatCheckboxRendererComponent,
      MatSelectRendererComponent,
    ]),
    DropdownModule,
    CardModule,
    InputNumberModule,
    InputSwitchModule,
    CalendarModule,
    DialogModule,
    TieredMenuModule,
    MultiSelectModule,
    InputTextModule,
    SidebarModule,
    ButtonModule,
    ContextMenuModule,
    PanelModule,
    ToastModule,
    OrganizationChartModule,
    TableModule,
    SliderModule,
    TooltipModule,
    RadioButtonModule,
    ColorPickerModule,
    MatTooltipModule,
    NgxDaterangepickerMd.forRoot(),
    MatProgressButtonsModule.forRoot(),
    NzDatePickerModule,
    MatChipsModule,
    NgxPrintElementModule,
    NgxDocViewerModule,
    AngularImageViewerModule,
    NgxMatSelectModule.forRoot({
      maxWidthForMobileView: 600,
      inFirstLoadCallSearcher: true,
      inFirstLoadSearcherValue: '',
      emptyLabel: 'no entry found',
      noMoreResultLabel: 'no more found',
      useInfiniteScroll: false,
      searchBoxPlaceholder: 'Search',
      maximumResultForShow: 4000,
      useMobileView: false,
      valueMember: 'key',
      displayMember: 'value',
      mobileViewType: 'FullScreen',
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules }, // add as factory to your providers
  ],
})
export class QualitymanagementModule {}
