<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
    <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>

<div class="sfdc-configuration-content">
    <div class="content-top-box">
        <ul class="breadcrumb">
            <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                    (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
            </li>
        </ul>
    </div>
</div>

<div class="tab-content-top-box">
    <h1 class="main-title">Preventive Maintenance Task Form</h1>
</div>

<form [formGroup]="preTaskForm">
    <div class="w-100 prevForm">
        <mat-form-field class="w-22">
            <mat-label>Machine Name</mat-label>
            <input type="text" matInput formControlName="MachineName" [(ngModel)]="preTask.MachineName">
        </mat-form-field>

        <mat-form-field class="w-22">
            <mat-label>Task Description</mat-label>
            <input type="text" matInput formControlName="TaskDesc" [(ngModel)]="preTask.TaskDesc">
        </mat-form-field>

        <mat-form-field class="w-22">
            <mat-label>Frequency</mat-label>
            <input type="text" matInput formControlName="FrequencyName" [(ngModel)]="preTask.FrequencyName">
        </mat-form-field>

        <mat-form-field class="w-22">
            <mat-label>Duration</mat-label>
            <input type="number" matInput formControlName="Duration" [(ngModel)]="preTask.Duration">
        </mat-form-field>

        <mat-form-field class="w-22">
            <mat-label>Activity Type</mat-label>
            <input type="text" matInput formControlName="TaskName" [(ngModel)]="preTask.TaskName">
        </mat-form-field>

        <mat-form-field class="w-22">
            <mat-label>Status</mat-label>
            <input type="text" matInput formControlName="status" [(ngModel)]="preTask.Status"
                [ngClass]="{'text-green' : preTask.Status == 'Done', 'text-red' : preTask.Status == 'Open', 'text-orage' : preTask.Status == 'In Progress'}">
        </mat-form-field>

        <mat-form-field class="w-22">
            <mat-label>Start Time</mat-label>
            <input matInput [ngxMatDatetimePicker]="picker1" formControlName="startTime"
                [(ngModel)]="preTask.StartTime"  [disabled]="isviewMode">
            <mat-datepicker-toggle matSuffix [for]="$any(picker1)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker1 [enableMeridian]="true"></ngx-mat-datetime-picker>
            <mat-error>Start Time Is Required</mat-error>
        </mat-form-field>

        <mat-form-field class="w-22">
            <mat-label>Finish Time</mat-label>
            <input matInput [ngxMatDatetimePicker]="picker2" formControlName="finishTime"
                [(ngModel)]="preTask.FinishTime" [disabled]="isviewMode"  (dateChange)="dateChanged('finishTime')">
            <mat-datepicker-toggle matSuffix [for]="$any(picker2)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker2 [enableMeridian]="true"></ngx-mat-datetime-picker>
        </mat-form-field>

        <button mat-flat-button color="accent" type="button" class="upload" (click)="openFile()"  *ngIf="!isviewMode">Upload File</button>
        <input type="file" (change)="readFile($event)" class="d-none" #fileInput accept="image/*" multiple>

        <mat-slide-toggle #toggleElement [checked]="toggleChecked" (change)="onToggleChange($event)" [disabled]="isviewMode">Spare Parts Changed
        </mat-slide-toggle>

        <mat-form-field appearence="fill" class="remarks">
            <mat-label>Remarks</mat-label>
            <textarea matInput type="text" formControlName="remarks" [(ngModel)]="preTask.Remarks" [readonly]="isviewMode"> </textarea>
            <mat-error>Remarks is Required </mat-error>
        </mat-form-field>
    </div>

    <h2 *ngIf="(FileData && FileData.length) || (EditFileData && EditFileData.length)">Technician Images</h2>
    <div *ngIf="FileData && FileData.length" class="imgflex">
        <div *ngFor="let file of FileData; let i = index" class="relative">
            <div>
                <img *ngIf="file.FileType == 'image'" [src]="file.ImageSrc" alt="" width="250px" height="200px" >
            </div>

            <img src="./assets/images/remove.png" (click)="removeFile(i)" class="absolute" alt="" width="20px"
                height="20px">

        </div>
    </div>

    <div *ngIf="EditFileData && EditFileData.length" class="imgflex">
        <div *ngFor="let file of EditFileData; let i = index" class="relative">
            <div>
                <img [src]="blobStorageUrl+file.ImageSrc" alt="" width="250px" height="200px" (click)="imgview($event)">
            </div>

            <img src="./assets/images/delete.png" (click)="deleteFile(i)" class="absolute" alt="" width="20px"
                height="20px" *ngIf="!isviewMode">

        </div>
    </div>

    <hr style="opacity: 0.3;margin-top: 20px;">
    <div class="w-100 prevForm">
        <mat-tab-group>
            <mat-tab label="SOP">
                <div *ngIf="TaskFiles && TaskFiles.length" class="imgflex">
                    <div *ngFor="let file of TaskFiles; let i = index" class="relative">
                        <div>
                            <img *ngIf="file.FileType == 'image'" [src]="blobStorageUrl+file.ImageSrc" alt="" width="250px"
                                height="200px">

                            <video *ngIf="file.FileType == 'video'" width="250" height="200" controls>
                                <source [src]="blobStorageUrl+file.ImageSrc" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>

                            <a *ngIf="file.FileType == 'application'" [href]="blobStorageUrl+file.ImageSrc" target="_blank"
                                download>
                                <img src="./assets/images/show-pdf2.png" width="200" height="200" alt="pdf">
                            </a>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Meter Reading">
                <mat-form-field appearence="fill" class="remarks" style="margin-top:10px;">
                    <mat-label>Meter Reading Details</mat-label>
                    <textarea matInput rows="10" type="text" formControlName="meterreading"
                        [(ngModel)]="preTask.MeterReading" [readonly]="isviewMode"> </textarea>
                    <!-- <mat-error>Remarks is Required </mat-error> -->
                </mat-form-field>
            </mat-tab>
            <mat-tab label="Spare Parts Used">
                <mat-form-field appearence="fill" class="remarks" style="margin-top:10px;">
                    <mat-label>Spare Parts Used Details</mat-label>
                    <textarea matInput rows="10" type="text" formControlName="sparepartsused"
                        [(ngModel)]="preTask.SparePartsUsed" [readonly]="isviewMode"> </textarea>
                    <!-- <mat-error>Remarks is Required </mat-error> -->
                </mat-form-field>
            </mat-tab>
            <mat-tab label="Work Log">
                <mat-form-field appearence="fill" class="remarks" style="margin-top:10px;">
                    <mat-label>Work Log Details</mat-label>
                    <textarea matInput rows="10" type="text" formControlName="worklog"
                        [(ngModel)]="preTask.WorkLog" [readonly]="isviewMode"> </textarea>
                    <!-- <mat-error>Remarks is Required </mat-error> -->
                </mat-form-field>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div class="center w-100">
        <button class="cancel" routerLink="/PM/technician" type="button">Cancel</button>
        <button mat-raised-button (click)="OnSubmit()" class="submit"  *ngIf="!isviewMode" >
            <span class="spinner-border-sm">Submit</span></button>
    </div>

</form>
<!-- img popup -->
<ng-template #technicianimageModal>
    <button mat-mini-fab color="warn" (click)="oncloseimg()"><mat-icon>close</mat-icon></button>
    <mat-carousel timings="250ms ease-in" [autoplay]="false" interval="5000" color="accent" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
      [useMouseWheel]="true" orientation="ltr">
      <mat-carousel-slide #matCarouselSlide *ngFor="let file of EditFileData; let i = index" [image]="blobStorageUrl+file.ImageSrc"
        overlayColor="#00000040" [hideOverlay]="false">
    </mat-carousel-slide>
    </mat-carousel>
  </ng-template>
  <!-- img popup -->