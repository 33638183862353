import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MasterDepartmentService } from 'src/app/services/master-department.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { GeneralAreaComponent } from "../general-area/general-area.component";
//import { GeneralDepartmentComponent } from "../general-department/general-department.component";
//import { GeneralShiftComponent } from "../general-shift/general-shift.component";
import { Router } from '@angular/router';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
@Component({
  selector: 'app-general-category',
  templateUrl: './general-category.component.html',
  styleUrls: ['./general-category.component.scss']
})
export class GeneralCategoryComponent implements OnInit {
  groupList!: any[];
  plantList!: any[];
  searchMasterForm!: FormGroup;
  breadcrumList!: any;
  groupListDDL!:any[];
  permisson!: any;
  IsAccess!: any;
  IsWrite!: any;
  GroupId!: number;
  @ViewChild(GeneralAreaComponent) GeneralAreaComponent!: GeneralAreaComponent;
  //@ViewChild(GeneralDepartmentComponent) GeneralDepartmentComponent!: GeneralDepartmentComponent;
  //@ViewChild(GeneralShiftComponent) GeneralShiftComponent!: GeneralShiftComponent;
  constructor(private machineService : MachineMappingService,private toaster: ToastrService,private roleService: MasterDepartmentService,private fb: FormBuilder,private breadcum: UserManagementService,public router: Router,private enterpriseGroupService: EnterPriseGroupService,) { }

  ngOnInit() {
    this.getGroupsData();
    this.GetBreadcrumList();
    this.searchMasterForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required]
    });
  }
 
  getGroupsData(): void {
    // this.spinner.show();
    const key = {
      Id: 0,
    };

    this.roleService.getGroupData(key).subscribe((data: any) => {
      this.groupList = data.masterList;
      this.searchMasterForm.get('group')?.setValue(this.groupList[0].TypeId);
      this.getPlantData();
    },
      (error:any) => {
        this.toaster.error('Error', error.error.message);
      //  this.spinner.hide();
      }
    );
  }
  getMasterData(): void {
    const key = {
      egId: this.searchMasterForm.value.group,
      plantId: this.searchMasterForm.value.plant
    };
    //this.GeneralDepartmentComponent.getdepartmentList(key);
    this.GeneralAreaComponent.getareaList(key);
    //this.GeneralShiftComponent.getshiftList(key);
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  getPlantData(): any {
    const key = {
      GroupId : this.searchMasterForm.value.group
    };
    this.machineService.getPlantsData(key).subscribe((data: any) => {
      this.plantList = data.DepartList;
      this.searchMasterForm.get('plant')?.setValue(data.DepartList[0].DeptId);
      this.getMasterData();
    }, (error: any) => {
      this.toaster.error('Error', error.message);
    });
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.breadcum.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error: any) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
}
