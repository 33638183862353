<div class="machine-parameter-content" dir="{{languageDir}}">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">{{langObj ? langObj?.MeetingAction : ('MeetingAction' | translate)}} </h1>
    </div>
  </div>
  <div class="wrapper-box wrapper-box-shadow grid-container">
    <form [formGroup]="MeetingActionForm">
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">

          <mat-label>{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}</mat-label>
          <mat-select panelClass="testClass" name="group" formControlName="group" (selectionChange)="getPlantByGroup()">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groupListDDL"
                      (filteredReturn)="filteredGroupListDDL = $event"></mat-select-filter>  
                      <mat-option *ngFor="let g of filteredGroupListDDL" [value]="g.TypeId"> {{g.TypeName}} </mat-option>
          </mat-select>
          <mat-error>{{langObj ? langObj?.grouop : ('grouop' | translate)}}</mat-error>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.plant : ('plant' | translate)}}</mat-label>
          <mat-select panelClass="testClass" name="plant" formControlName="plant" (selectionChange)="onChangePlant()">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'DeptDesc'" [array]="plants"
            (filteredReturn)="filteredPlants = $event"></mat-select-filter>
              <mat-option *ngFor="let p of filteredPlants" [value]="p.DeptId"> {{p.DeptDesc}} </mat-option>
          </mat-select>
          <mat-error>{{langObj ? langObj?.plt : ('plt' | translate)}}</mat-error>
        </mat-form-field>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-12 p-md-12 inputField">
            <label class="floating-label" for="multiselect">{{langObj ? langObj?.MeetingParticipants :
              ('MeetingParticipants' | translate)}}</label>
            <p-multiSelect inputId="multiselect" formControlName="responsible" [options]="UserDDL"
              optionLabel="fullName" (onChange)="getAllMeetingAction()" [filter]="true">
            </p-multiSelect>
          </div>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.DateRange : ('DateRange' | translate)}}</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="startDate" placeholder="{{langObj ? langObj?.FromDate : ('FromDate' | translate)}}" />
            <input matEndDate formControlName="endDate" placeholder="{{langObj ? langObj?.ToDate : ('ToDate' | translate)}}" (dateChange)="onTDateChange()"/>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </form>
    <ag-grid-angular id="mainGrid" style="width: 100%; height: 30vw; margin-top: 15px" class="ag-theme-alpine"
        [columnDefs]="actionscolumnDefs" [rowData]="rowData" [pagination]="true" [defaultColDef]="defaultColDef"
        (columnMoved)="saveState()" [paginationPageSize]="10" (cellValueChanged)="onCellValueChanged($event)"
        [loadingOverlayComponent]="loadingOverlayComponent" (gridReady)="onGridReady($event)"
        [gridOptions]="gridOptions" [sideBar]="sideBar" (cellClicked)="cellClicked($event)"
        [groupHideOpenParents]="true" (firstDataRendered)="onFirstDataRendered($event)"
        (filterChanged)="onFilterChanged($event)" (columnRowGroupChanged)="onColumnPivotChanged($event)">
      </ag-grid-angular>
  </div>

</div>

<ng-template #previewModal>
  <form [formGroup]="editActionPlanForm">
    <div mat-dialog-title>
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>
            {{ langObj ? langObj?.UpdateAction : ("UpdateAction" | translate) }}
          </h2>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>
    </div>
    <mat-dialog-content>
      <div class="content-row-wrap" style="margin-bottom: 20px">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.RootCause : ('SelectNewAssignee' | translate)}}</mat-label>
          <input matInput placeholder="Enter RootCause" formControlName="rootcause" />
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.Action : ('Action' | translate)}}</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterActionName : ('EnterActionName' | translate)}}" formControlName="actions" [(ngModel)]="data.Action" />
        </mat-form-field>
        <div class="radio-wrapper">
          <mat-label>{{langObj ? langObj?.ActionType : ('ActionType' | translate)}}:</mat-label>
          <mat-radio-group formControlName="actionType" class="radio-group" name="ActionType">
            <mat-radio-button value="Preventive">Preventive</mat-radio-button>
            <mat-radio-button value="Corrective">Corrective</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="imgFlex-Wrapper">
          <mat-label>{{langObj ? langObj?.ChooseImage : ('ChooseImage' | translate)}}</mat-label>
          <div class="imgflex" style="position: relative">
            <div title="image" style="display: flex">
              <img *ngIf="data.FileSrc || data.UploadFile" (click)="imageZoomModal()"
                [src]="data.FileSrc || blobStorageUrl + data.UploadFile" alt="image" width="60px" height="40px" />
            </div>
            <img *ngIf="data.FileSrc || data.UploadFile" src="assets/images/delete.png" (click)="deleteFile('pic')"
              class="imgabsol" alt="" width="15px" height="15px" style="position: absolute; left: 0; top: 0" />
            <button mat-flat-button color="accent" type="button" class="upload" (click)="openFile('pic')">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" (change)="readFile($event, 'pic')" class="d-none" #fileInput accept="image/*" />
          </div>
        </div>
        <div class="imgFlex-Wrapper">
          <mat-label>{{langObj ? langObj?.ChooseFile : ('ChooseFile' | translate)}}</mat-label>
          <div class="imgflex" style="position: relative">
            <div title="pdf" style="display: flex">
              <a *ngIf="data.DocSrc || data.UploadDoc" (click)="downloadPDF()"><img src="assets/images/file_img.png"
                  class="pdfimg" alt="pdf" width="60px" /></a>
            </div>
            <img *ngIf="data.DocSrc || data.UploadDoc" src="assets/images/delete.png" (click)="deleteFile('file')"
              class="imgabsol" alt="" width="15px" height="15px" style="position: absolute; left: 0; top: 0" />
            <button mat-flat-button color="accent" type="button" class="upload" (click)="openFile(i, 'file')"
              [disabled]="data.Status == 'Closed'">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" (change)="readFile($event, 'file')" class="d-none" #fileInputFile
              accept="application/pdf,application/vnd.ms-excel" />
          </div>
        </div>

        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.SelectAssignee : ('SelectAssignee' | translate)}}</mat-label>
          <mat-select formControlName="assignTo" [disabled]="data.Status == 'Closed' || !data.Action"
            (selectionChange)="update()">
            <mat-select-filter [placeholder]="'Search User'" [displayMember]="'FullName'" [array]="userList"
              (filteredReturn)="userList = $event">
            </mat-select-filter>

            <mat-option *ngFor="let user of userList" [value]="user.EmpInfo_Id">
              {{ user.FullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="inputField" style="margin-top: 15px; width: 17%">
          <nz-date-picker class="calender-input" style="margin-left: 0px !important; width: 100%"
            [nzInputReadOnly]="true" [nzShowTime]="false" nzFormat="yyyy-MM-dd" [nzDisabledDate]="getMinDate('Target')"
            formControlName="targetDate" (nzOnCalendarChange)="targetChange()" [nzDisabled]="
              data.Status === 'Open' ? (data.AssignTo ? false : true) : true
            " [(ngModel)]="data.TargetDate">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.TargetDate : ('TargetDate' | translate)}}</span>
        </div>
        <div class="inputField" style="margin-top: 15px; width: 17%">
          <nz-date-picker class="calender-input" formControlName="revisedDate" nzFormat="yyyy-MM-dd"
            [nzInputReadOnly]="true" style="width: 100%; margin-left: 0px !important"
            [nzDisabledDate]="getMinDate('Revised')" [nzDisabled]="
              (data.Status != 'InProgress' && data.Status == 'Open') ||
              data.Status == 'Complete' ||
              data.Status == 'Closed'
            ">
          </nz-date-picker>
          <span class="floating-text">{{langObj ? langObj?.RevisedDate : ('RevisedDate' | translate)}}</span>
        </div>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" [ngClass]="{
            'disabled-input':
              data.Status == 'Closed' ||
              !data.TargetDate ||
              data.Status == 'Complete'
          }">
          <mat-label>{{langObj ? langObj?.SelectCompleted : ('SelectCompleted' | translate)}}</mat-label>
          <mat-select formControlName="completed" (selectionChange)="onChangeComplete()" [disabled]="
              data.Status == 'Closed' ||
              !data.TargetDate ||
              data.Status == 'Complete'
            ">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          [ngClass]="{ 'disabled-input': disableVerification }">
          <mat-label>{{langObj ? langObj?.SelectVerfication : ('SelectVerfication' | translate)}}</mat-label>
          <mat-select formControlName="verification" [disabled]="disableVerification"
            (selectionChange)="onChangeVerification()">
            <mat-option value="">None</mat-option>
            <mat-option value="Approved">Approved</mat-option>
            <mat-option value="Disapproved">Disapproved</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
          [ngClass]="{ 'disabled-input': disableNewAssigne }">
          <mat-label>{{langObj ? langObj?.SelectNewAssignee : ('SelectNewAssignee' | translate)}}</mat-label>
          <mat-select [disabled]="disableNewAssigne" formControlName="newAssignee">
            <mat-select-filter [placeholder]="'Search User'" [displayMember]="'FullName'" [array]="userList"
              (filteredReturn)="userList = $event">
            </mat-select-filter>

            <mat-option *ngFor="let user of userList" [value]="user.EmpInfo_Id">
              {{ user.FullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="p-xl-4 uploadinside" *ngIf="showinput" style="width: fit-content">
          <!-- <mat-label>Choose Close File</mat-label> -->
          <!-- <ngx-mat-file-input [formControl]="closefile" [accept]="accept"></ngx-mat-file-input> -->
          <input placeholder="{{langObj ? langObj?.ChooseCloseFile : ('ChooseCloseFile' | translate)}}" type="file" (change)="uploadedFile($event)" formControlName="closefile"
            class="form-control" style="height: 48.31px; width: 219px; margin-top: 8px" />
        </div>

        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterRemarks : ('EnterRemarks' | translate)}}" formControlName="remarks" />
        </mat-form-field>
        <button mat-mini-fab mat-button color="primary" matTooltip="Update" (click)="UpdateActionPlan()"
          matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px; margin-top: 20px">
          <i class="fa fa-pencil" style="color: #fff"></i>
        </button>
      </div>
    </mat-dialog-content>
  </form>
</ng-template>

<p-dialog [header]="fileDesc" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
  [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ngx-doc-viewer *ngIf="fileType === 'doc'" [url]="doc" viewer="google" style="width: 100%; height: 90vh">
  </ngx-doc-viewer>
  <angular-image-viewer *ngIf="fileType === 'img'" [src]="[images]" [(index)]="imageIndexOne"></angular-image-viewer>
</p-dialog>