<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center">
    <mat-card-title class="p-ml-2">Quality Check List</mat-card-title>
    <div>
      <mat-form-field>
        <mat-label>Search</mat-label>
        <input style="padding-left: 10px;" matInput (keyup)="applyFilter($event)" type="text" class="form-control" />
      </mat-form-field>
    </div>
    <div class="">
      <button mat-mini-fab mat-button mat-dialog-close color="accent" *ngIf="IsWrite"
        (click)="openModal(displayRoleModal)" pTooltip="Add New Checklist" tooltipPosition="top">
        <i class="fa fa-plus" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <mat-card-content>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <ag-grid-angular #agGridCheckList style="width: 100%; height: 100%;" class="ag-theme-alpine"
      [rowData]="checkListRowData" [columnDefs]="ColumnDefsCheckList" (gridReady)="onGridReadyCheckList($event)"
      (cellClicked)="onCellClicked($event)" [gridOptions]="gridOptionsCheckList" [pagination]="true"
      [paginationPageSize]="10" [loadingOverlayComponent]="loadingOverlayComponent"
      (firstDataRendered)="applyState('CheckListConf-vTLU37IVZb');onFirstDataRendered($event)"
      (columnMoved)="onSaveGridState()" (columnVisible)="onSaveGridState()" (columnPinned)="onSaveGridState()"
      (displayedColumnsChanged)="onSaveGridState()"></ag-grid-angular>
  </mat-card-content>
</mat-card>

<ng-template #displayRoleModal (keydown.enter)="$event.stopPropagation()">
  <div mat-dialog-title style="margin-bottom: 0px;">
    <div class="d-flex justify-content-between align-items-center">
      <h1 mat-dialog-title>{{ formMode ? "Add" : "Update" }} CheckList</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <form [formGroup]="ChecklistDataForm">
    <mat-dialog-content style="overflow: hidden;">
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-row-wrap" style="margin-top: -15px; margin-left: -15px;">
          <mat-form-field class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Group</mat-label>
            <mat-select panelClass="testClass" name="group" formControlName="group"
              (selectionChange)="getPlantByGroup()" [disabled]="true">
              <mat-option *ngFor="let g of groupListDDL" [value]="g.TypeId">
                {{ g.TypeName }}
              </mat-option>
            </mat-select>
            <mat-error>Group is Required</mat-error>
          </mat-form-field>
          <mat-form-field class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Plant</mat-label>
            <mat-select name="list" formControlName="plant" (selectionChange)="getArea()" [disabled]="true">
              <mat-option *ngFor="let o of plantList" [value]="o.DeptId">{{
                o.DeptDesc
                }}</mat-option>
            </mat-select>
            <mat-error>Plant is Required</mat-error>
          </mat-form-field>

          <mat-form-field class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Check List Type</mat-label>
            <mat-select name="list" formControlName="checktype">
              <mat-option *ngFor="let o of ListType" [value]="o.qualityTypeId">{{ o.name }}</mat-option>
            </mat-select>
            <mat-error>CheckListType is Required</mat-error>
          </mat-form-field>

          <mat-form-field class="
              top-row-first-filed-item top-row-first-filed-item-order-processing
            ">
            <mat-label class="required">Check List Name</mat-label>
            <input matInput formControlName="checkname" placeholder="Enter CheckList Name" />
            <mat-error>CheckListName is Required</mat-error>
          </mat-form-field>

          <mat-form-field class="
          top-row-first-filed-item top-row-first-filed-item-order-processing
        ">
            <mat-label>Area</mat-label>
            <mat-select formControlName="area" placeholder="Area" panelClass="testClass" name="Status" multiple>
              <mat-option #allSelectedAreaAdd (click)="toggleAllSelectionAreaAdd()" [value]="0">
                Select All
              </mat-option>
              <mat-option *ngFor="let area of AreaFilter" [value]="area.areaId">
                {{ area.areaName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="margin-left:25px;">
            <mat-select ngxMatSelect [hasSearchBox]="true" [useMobileView]="true" mobileViewType="BottomSheet"
              [formControlName]="'Unit'" (selectionChange)="getExecutionTypeByUnit()" [multiple]="true" name="puDesc"
              [required]="false" [source]="unitsArray" [displayMember]="'puDesc'" [placeholder]="'Select Units'"
              [valueMember]="'puId'" #sf="ngxMatSelect">
              <mat-option #allSelectedUnitAdd (click)="toggleAllSelectionUnitAdd()" [value]="0">
                Select All
              </mat-option>
              <mat-option [value]="item.puId" *ngFor="let item of sf.filteredSource">
                {{item.puDesc}}
              </mat-option>
            </mat-select>
            <mat-error>Select Units</mat-error>
          </mat-form-field>
          <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing"
            style="margin-left:25px;">
            <mat-select formControlName="executionType" placeholder="Execution Type" panelClass="testClass"
              [required]="true" name="executionType">
              <mat-option *ngFor="let list of executionList" [value]="list.executionType">
                {{ list.executionType }}
              </mat-option>
            </mat-select>
            <mat-error>ExecutionType is Required</mat-error>
          </mat-form-field>
          <div class="d-flex align-items-center">
            <button mat-mini-fab color="accent" (click)="refreshUnit()" pTooltip="Reset" tooltipPosition="top"
              style="margin-left: 10px;display: flex; justify-content: center; align-items: center;">
              <mat-icon style="vertical-align: text-top ;">refresh</mat-icon>
            </button>
            <button mat-mini-fab mat-button color="accent" (click)="addGroupModal()" matTooltip="Add Group"
              matTooltipClass="custom-tooltip" matTooltipPosition="above"
              style="margin-left: 20px;display: flex; justify-content: center; align-items: center;">
              <i class="fa fa-plus" style="color: #fff"></i>
            </button>
            <button mat-mini-fab mat-button color="accent" (click)="addTable()" matTooltip="Add Row" type="button"
              matTooltipClass="custom-tooltip" matTooltipPosition="above"
              style="margin-left: 20px; background-color: #19ACAB; display: flex; justify-content: center; align-items: center;">
              <i class="fa fa-calendar-plus-o" style="color: #fff; vertical-align: initial;"></i>
            </button>
          </div>
        </div>

      </div>
    </mat-dialog-content>
  </form>

  <mat-divider></mat-divider>
  <div style="margin-top: 10px"></div>
  <form [formGroup]="CheckListRowData">
    <table mat-table [dataSource]="CheckListAddModel" class="basic-table plant-model-table" matSort>
      <ng-container matColumnDef="itemActions">
        <th mat-header-cell *matHeaderCellDef>
          <p class="header-text">Action</p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="row-action-btns-column">
          <div class="row-action-btns">
            <button mat-icon-button type="button" class="tree-view-btn" aria-label="View Row Tree"
              (click)="deleteRow(element, i)" matTooltip="Delete" matTooltipClass="custom-tooltip"
              matTooltipPosition="above">
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                  fill="#F85439" />
                <circle cx="17" cy="17" r="14" fill="#F85439" />
                <path
                  d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                  fill="white" />
              </svg>
            </button>
            <button mat-mini-fab mat-button type="button" color="primary" (click)="addDropDownModal(element,i)"
              matTooltip="Add DropDown Data" *ngIf="element.displaybtn || element.itemtype==5"
              matTooltipClass="custom-tooltip" matTooltipPosition="above">
              <i class="fa fa-plus" style="color: #fff;"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="items">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Items</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <textarea matInput cdkTextareaAutosize (change)="columnEvent($event, element)" placeholder="Item"
            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="7">{{ element.item }}</textarea>
        </td>
      </ng-container>

      <ng-container matColumnDef="ListName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%; padding-left: 0px">
          <p class="header-text">Group Name</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-select name="drop" (selectionChange)="columnGroupEvent($event, element)" placeholder="Dropdown Group"
            value="{{ element.drop }}">
            <mat-option *ngFor="let data of GroupAdded" [value]="data">{{
              data
              }}</mat-option>
          </mat-select>
        </td>
      </ng-container>

      <ng-container matColumnDef="ItemType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;padding-left: 0px">
          <p class="header-text">Item Type</p>
        </th>
        <td mat-cell *matCellDef="let element;let i  = index"
          style="padding: 13px 0px; padding-right: 10px; padding-left: 10px;">
          <mat-select name="itemtype" (selectionChange)="columnItemEvent($event, element,i)" placeholder="Item Types"
            [value]="element.itemtype">
            <mat-option *ngFor="let o of ItemType" [value]="o.itemTypeId">{{
              o.name
              }}</mat-option>
          </mat-select>
        </td>
      </ng-container>

      <ng-container matColumnDef="standard">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Standard</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <textarea matInput cdkTextareaAutosize (change)="standardEvent($event, element)" placeholder="Add Text"
            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="7">{{ element.standard }}</textarea>
        </td>
      </ng-container>

      <ng-container matColumnDef="minimum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Min</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <input matInput type="number" [disabled]="element.itemtype != 2" (change)="minEvent($event, element)"
            placeholder="Add Value" value="{{ element.minValue }}" />
        </td>
      </ng-container>

      <ng-container matColumnDef="maximum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Max</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <input matInput type="number" [disabled]="element.itemtype != 2" (change)="maxEvent($event, element)"
            placeholder="Add Value" value="{{ element.maxValue }}" />
        </td>
      </ng-container>

      <ng-container matColumnDef="good">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Good Example</p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div class="content-top-row"
            style="display: flex;justify-content: center; align-items: center; margin-left: -1rem;">
            <button mat-mini-fab *ngIf="!formMode && element.goodExampleImageName.length > 0 && !element.HideViewIcon"
              color="accent" (click)="openFilePopUp(element , i , 1)" style="background-color: #8f0fd1;">
              <mat-icon>remove_red_eye</mat-icon>
            </button>

            <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 9rem;margin-left: 5px;margin-top: 0;">
              <mat-label>Add Files</mat-label>
              <ngx-mat-file-input style="white-space:nowrap;text-overflow: ellipsis; overflow: hidden; display:block;"
                (change)="uploadedFile($event, i)" multiple>
                <mat-icon matSuffix>folder</mat-icon>
              </ngx-mat-file-input>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="bad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <p class="header-text">Bad Example</p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div class="content-top-row" style="display: flex; justify-content: center; align-items: center;">
            <button mat-mini-fab *ngIf="!formMode  && element.badExampleImageName.length > 0 && !element.HideViewIcon1"
              color="accent" (click)="openFilePopUp(element , i , 2)" style="background-color: #8f0fd1;">
              <mat-icon>remove_red_eye</mat-icon>
            </button>

            <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing"
              style="width: 9rem;margin-left:5px;height: fit-content;">
              <mat-label>Add Files</mat-label>
              <ngx-mat-file-input style="white-space:nowrap;text-overflow: ellipsis; overflow: hidden; display:block;"
                (change)="uploadedFile2($event, i)" multiple>
                <mat-icon matSuffix>folder</mat-icon>
              </ngx-mat-file-input>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="itemColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: itemColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" style="text-align: center" colspan="4">
          No Data Matching
        </td>
      </tr>
    </table>

    <mat-paginator class="basic-paginataor" [pageSizeOptions]="[50, 100]" showFirstLastButtons>
    </mat-paginator>

    <div mat-dialog-actions style="float: right; margin-top: 10px">
      <button (click)="addCheckList()" mat-mini-fab mat-button color="primary" *ngIf="formMode"
        [disabled]="!ChecklistDataForm.valid" matTooltip="Save" matTooltipClass="custom-tooltip"
        matTooltipPosition="above" style="margin-left: 20px;">
        <i class="fa fa-floppy-o" style="color: #fff"></i>
      </button>

      <button mat-mini-fab mat-button color="primary" [disabled]="!ChecklistDataForm.valid" *ngIf="!formMode"
        matTooltip="Update" matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;"
        (click)="updateCheckList()">
        <i class="fa fa-pencil" style="color: #fff"></i>
      </button>
    </div>
  </form>
</ng-template>

<ng-template #displayGroupModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Add Group</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>

  <form [formGroup]="AddGroupForm">
    <mat-dialog-content>
      <mat-form-field appearance="fill" class="field-fill inputs1">
        <mat-label class="required">Group</mat-label>
        <input matInput placeholder="Enter Group Name" formControlName="dropname" />
        <mat-error>Group is Required</mat-error>
      </mat-form-field>
      <button type="submit" mat-mini-fab mat-button color="primary" (click)="SaveGroup()"
        [disabled]="!AddGroupForm.valid" matTooltip="Save" matTooltipClass="custom-tooltip" matTooltipPosition="above"
        style="margin-left: 20px;">
        <i class="fa fa-floppy-o" style="color: #fff"></i>
      </button>
    </mat-dialog-content>
  </form>
</ng-template>

<ng-template #displayDuplicateModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Duplicate Check List</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>

  <form [formGroup]="DuplicateCheckListForm">
    <mat-dialog-content>
      <mat-form-field appearance="fill" class="field-fill inputs1">
        <mat-label class="required">Check List Name</mat-label>
        <input matInput placeholder="Enter Checklist Name" formControlName="name" />
        <mat-error>CheckListName is Required</mat-error>
      </mat-form-field>
      <button type="submit" mat-mini-fab mat-button color="primary" (click)="DuplicateChecklist()"
        [disabled]="!DuplicateCheckListForm.valid" matTooltip="Save" matTooltipClass="custom-tooltip"
        matTooltipPosition="above" style="margin-left: 20px;">
        <i class="fa fa-floppy-o" style="color: #fff"></i>
      </button>
    </mat-dialog-content>
  </form>
</ng-template>


<ng-template #displayDropDownModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2 mat-dialog-title>{{ formMode ? "Add" : "Update" }} Dropdown Element</h2>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <form [formGroup]="AddDropDownForm">
    <mat-dialog-content>
      <mat-form-field appearance="fill" class="field-fill inputs1">

        <mat-label class="required">Drop Down Element</mat-label>
        <input matInput placeholder="Enter Element" formControlName="dropdown" />
        <mat-error>DropDownElement is Required</mat-error>
      </mat-form-field>
      <button type="submit" mat-mini-fab mat-button color="primary" (click)="SaveDropDown()"
        [disabled]="!AddDropDownForm.valid" matTooltip="Save" matTooltipClass="custom-tooltip"
        matTooltipPosition="above" style="margin-left: 20px;">
        <i class="fa fa-floppy-o" style="color: #fff"></i>
      </button>
    </mat-dialog-content>
  </form>

  <mat-divider></mat-divider>
  <table mat-table [dataSource]="DropdownElemenet" class="mat-elevation-z8" style="width:100%">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef class="tbheader"> No. </th>
      <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef class="tbheader"> Value </th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>

    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef class="tbheader">Actions</th>
      <td mat-cell *matCellDef="let element;let i = index"> <span style="cursor: pointer" *ngIf="formMode"
          (click)=removefromList(i)>
          <mat-icon style="color: red">delete</mat-icon>
        </span>
        <span *ngIf="!formMode" style="cursor: pointer;" (click)=deletedropDownById(element,i)>
          <mat-icon>delete</mat-icon>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-template>

<ng-template #imageZoomModel>
  <img [src]="currentImagePath" style="height: 98%; width: 100%; overflow: hidden" />
</ng-template>


<ng-template #configurationModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h1 mat-dialog-title>Configure Check List Header/Footer</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <form [formGroup]="ConfigurationForm">
    <mat-dialog-content style="overflow: hidden;">
      <div class="wrapper-box wrapper-box-shadow table-wrapper">
        <div class="content-top-row">
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Name</mat-label>
            <input matInput formControlName="name" placeholder="Enter Name" />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Value</mat-label>
            <input matInput formControlName="value" placeholder="Enter Value" />
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Type</mat-label>
            <mat-select panelClass="testClass" name="type" formControlName="type">
              <mat-option *ngFor="let t of configTypes" [value]="t.Id">
                {{ t.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
            <mat-label class="required">Order</mat-label>
            <input matInput type="number" formControlName="order" placeholder="Enter Order" min="0"
              oninput="validity.valid||(value='');" />
          </mat-form-field>
          <button *ngIf="chfformMode" type="button" mat-mini-fab mat-button color="primary"
            (click)="SaveCheckListConfiguration()" [disabled]="!ConfigurationForm.valid" matTooltip="Save"
            matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;">
            <mat-icon>save</mat-icon>
          </button>
          <button *ngIf="!chfformMode" type="button" mat-mini-fab mat-button color="primary"
            (click)="UpdateCheckListConfiguration()" [disabled]="!ConfigurationForm.valid" matTooltip="Update"
            matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;">
            <mat-icon>edit</mat-icon>
          </button>
          <button type="button" mat-mini-fab mat-button color="accent" (click)="resetheaderFooter()" matTooltip="reset"
            matTooltipClass="custom-tooltip" matTooltipPosition="above" style="margin-left: 20px;">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
    </mat-dialog-content>
  </form>
  <ag-grid-angular #agGrid style="width: 100%" class="ag-theme-alpine" [rowData]="rowData" [columnDefs]="columnDefs"
    (gridReady)="onGridReady($event)" (cellClicked)="cellClicked($event)" [gridOptions]="gridOptions"
    [frameworkComponents]="frameworkComponents" [pagination]="true" [paginationPageSize]="10"
    (columnMoved)="onSaveHeaderGridState()" (columnVisible)="onSaveHeaderGridState()"
    (columnPinned)="onSaveHeaderGridState()" (displayedColumnsChanged)="onSaveHeaderGridState()"
    (firstDataRendered)="applyHeaderFooterState('CheckListHeaderFooter-vTLU37IVZb')">
  </ag-grid-angular>
</ng-template>




<p-dialog [closeOnEscape]="false" *ngIf="fileType=='doc'? true : false" header="File Detail"
  [(visible)]="displayMaximizable" [modal]="true" [style]="{width: '90vw'}" [maximizable]="true" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">
  <ngx-doc-viewer [url]="doc" viewer="google" style="width:100%;height:90vh;"></ngx-doc-viewer>
</p-dialog>
<p-dialog [closeOnEscape]="false" *ngIf="fileType=='img' ? true : false" header="File Detail"
  [(visible)]="displayMaximizable" [modal]="true" [style]="{width: '90vw'}" [maximizable]="true" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">
  <angular-image-viewer [src]="images" [(index)]="imageIndexOne"></angular-image-viewer>
</p-dialog>