<div class="production-db-content">
    <div class="content-top-box">
      <ul class="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
              (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
          </li>
        </ul>
      </ul>
      <div class="title-row-flex-both-end">
        <h1 class="main-title">Quotidian OEE DashBoard</h1>
      </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <form [formGroup]="oeeDashboardForm">
      <div class="content-top-row content-top-row-order-processing">
        <!-- GROUP -->
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <!-- <mat-label>Status</mat-label> -->
          <mat-select formControlName="UserGroup1" (selectionChange)="onChangeGroup($event)" placeholder="Group"
            panelClass="testClass" name="Status">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
              (filteredReturn)="filteredGroups = $event"></mat-select-filter>
            <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
              {{group.EG_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="UserPlant1" (selectionChange)="onChangePlant($event)" placeholder="Plant"
            panelClass="testClass" name="Status">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList1"
              (filteredReturn)="filteredPlants = $event"></mat-select-filter>
            <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
              {{plant.PT_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="line" (selectionChange)="onChangeLine($event)" placeholder="Line"
            panelClass="testClass" name="line">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
              (filteredReturn)="filteredLines = $event"></mat-select-filter>
            <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
              {{line.PL_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="unit" placeholder="Unit" panelClass="testClass" name="unit">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="unitList"
              (filteredReturn)="filteredUnits = $event"></mat-select-filter>
            <mat-option *ngFor="let unit of filteredUnits" [value]="unit.PU_Id">
              {{unit.PU_Desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="criteriaquery" placeholder="Criteria Query" panelClass="testClass" name="criteriaquery" (selectionChange)="onChangeCriteria($event)">
            <mat-option value='shift'>Shift</mat-option>
            <mat-option value='day'>Day</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Dropdown -->
      <div class="content-top-row content-top-row-order-processing">
        <div class="inputField"> 
          <nz-date-picker  class="calender-input"  id="startPicker" formControlName="dateStart" nzFormat="dd/MM/yyyy HH:mm"  
          [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
          <span class="floating-text">Start Date</span>   
        </div>
        <div class="inputField">
          <nz-date-picker  class="calender-input"   id="endPicker" formControlName="dateEnd" nzFormat="dd/MM/yyyy HH:mm" 
          [nzShowTime]="{ nzFormat: 'HH:mm' }"  [nzInputReadOnly]="true" ></nz-date-picker>
          <span class="floating-text">End Date</span>   
        </div>
        <div class="btn-position">
          <button mat-raised-button color="accent" class="btn-accent" (click)="getOeeDashboard()">Apply Filter</button>
        </div>
      </div>
      <!-- chart  -->
    
      <div class="chart-group-quote" *ngIf="!criteriaType">
        <div class="chart-items-quote">
          <div class="chart-title">Quotidian OEE</div>
          <div [chart]="chartOEEDay"></div>
        </div>
      </div>
      <div class="chart-group" *ngIf="criteriaType">
        <div class="chart-items">
          <div class="chart-title">OEE Day Shift</div>
          <div [chart]="chartOEEShiftDay"></div>
        </div>
        <div class="chart-items">
          <div class="chart-title">OEE Night Shift</div>
          <div [chart]="chartOEEShiftNight"></div>
        </div>
      </div>
      

   
    </form>
    <section>
        <div class="example-button-row">
          <button type="button" class="btn btn-success"  (click)="csvExport()" style="margin-top: 30px;"><img src="assets/images/csv-file-format.png" ></button>
          <button type="button" class="btn btn-success" (click)="excelExport()" style="margin-top: 30px; margin-left: 10px;"><i class="fa fa-file-excel-o icon-white"  aria-hidden="true"></i></button>
        </div>
      </section>
      <mat-card-content>
        <ag-grid-angular style="width: 100%; height: 24vw;" class="ag-theme-alpine" [rowData]="rowData"
        [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
      </ag-grid-angular>
        </mat-card-content>
  </div>