import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { DataSourcesService } from 'src/app/services/data-sources.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { PlantGroupElement } from 'src/app/interface/plant-model';
import { ToastrService } from 'ngx-toastr';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserPlant } from 'src/app/models/userPlant';
import { DtServiceService } from 'src/app/services/dtService.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { DtConfigurationService } from 'src/app/services/dt-configuration.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-dt-configuration',
  templateUrl: './dt-configuration.component.html',
  styleUrls: ['./dt-configuration.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({
          left: '10px',
          height: '0px',
          minHeight: '0',
          visibility: 'hidden',
        })
      ),
      state(
        'expanded',
        style({ left: '10px', height: '*', visibility: 'visible' })
      ),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class DtConfigurationComponent implements OnInit {
  breadcrumList: any;
  IsAccess: any;
  IsWrite: any;
  updateReasonId: any;
  categoryFormMode = true;
  updateCategoryId: any;
  treeTypeList: any;
  treeFormMode = true;
  updateTreeId: any;
  TreeLevelName: any;
  TreeId: any;
  treeLevelSource!: MatTableDataSource<any>;
  machineDTReasonSource!: MatTableDataSource<any>;
  dtTreeList: any;
  dtReasonTree: any;
  timeEventFormMode = true;
  UpdateEventFaultValueId: any;
  wasteEventFaultSource!: MatTableDataSource<any>;
  MachineResonId: any;
  wasteTreeList: any;
  timeWasteFormMode = true;
  reasonTreeList: any;
  updateWasteReasonId: any;
  treeReasonList: any;
  treeLevelList: any;
  reasontreeDataSource!: MatTableDataSource<any>;
  reasonList: any;
  level1 = false;
  level2 = false;
  level3 = false;
  level4 = false;
  treeLevel1List: any;
  treeLevel2List: any;
  treeLevel3List: any;
  treeLevel4List: any;
  resaonTreeId: any;
  categoryList: any;
  reasonTreeId: any;
  categoryMappingTreeDataSource!: MatTableDataSource<any>;
  categoryMappingFormMode = true;
  reasonTreeMappigId: any;
  isAddId!: number;

  @ViewChild(MatTable) table!: MatTable<any>;

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  @ViewChild(MatPaginator) paginator2: any;
  @ViewChild(MatSort) sort2: any;
  // @ViewChild(MatSort) sort!: any;

  constructor(
    private kpiMaster: KpimasterService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private DataSourceService: DataSourcesService,
    private toaster: ToastrService,
    private dtService: DtServiceService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private usermanagementService: UserManagementService,
  ) {}

  get searchDefectFormControls(): any {
    return this.dtForm.controls;
  }
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  panelOpenState = false;
  ReasonDataSource!: MatTableDataSource<any>;
  reasonColumns: string[] = ['action', 'id', 'reason', 'group', 'plant'];
  CategoryDataSource!: MatTableDataSource<any>;
  categoryColumns: string[] = ['action', 'id', 'category', 'group', 'plant'];
  treeColumns: string[] = [
    'action',
    'id',
    'tree',
    'type',
    'group',
    'plant',
    'tlevel',
    'rtree',
  ];
  treeData!: MatTableDataSource<any>;
  // [{ id: '1', tree: '1', type: 'Ahmad', group: 'code A', plant: 'test@gmail.com', tlevel: '', rtree: '', action: 'action' }];
  catmappingColumns: string[] = ['action', 'id', 'category'];
  dtreasontreeColumns: string[] = [
    'action',
    'id',
    'group',
    'plant',
    'tree',
    'location',
    'fvalue',
    'fname',
    'l1',
    'l2',
    'l3',
    'l4',
    'category',
  ];
  // tslint:disable-next-line:max-line-length
  wastetreeColumns: string[] = [
    'action',
    'id',
    'group',
    'plant',
    'tree',
    'location',
    'wvalue',
    'wname',
    'l1',
    'l2',
    'l3',
    'l4',
    'category',
  ];
  treelevelColumns: string[] = ['action', 'id', 'level'];
  reasontreeColumns: string[] = [
    'action',
    'id',
    'level',
    'level2',
    'level3',
    'level4',
    'category',
  ];
  reasontreeData = [
    {
      id: '1',
      level: '1',
      level2: 'Ahmad',
      level3: 'code A',
      level4: 'test@gmail.com',
      category: '',
      action: 'action',
    },
  ];
  reasonForm!: FormGroup;
  categoryForm!: FormGroup;
  reasontreeForm!: FormGroup;
  treeForm!: FormGroup;
  dtreasonForm!: FormGroup;
  wastetreeForm!: FormGroup;
  catmappingForm!: FormGroup;
  MachineId!: any;
  FaultList!: any[];
  groupList!: any[];
  DepartList!: any[];
  treeType = 1;
  isPlantGroupModelVisible = false;
  UnitName: any;
  // Search defect form
  dtForm!: FormGroup;
  groupDataForm!: FormGroup;
  updateOrderprocessingData = false;
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  plants!: any[];
  searchPlants!: UserPlant[];
  searchDtConfigForm!: FormGroup;
  resonFormMode = true;
  reasonTreeFormMode = true;
  @ViewChild('displayreasonRoleModal')
  private displayreasonRoleModal!: TemplateRef<any>;
  @ViewChild('displaycategoryRoleModal')
  private displaycategoryRoleModal!: TemplateRef<any>;
  @ViewChild('displaytreelevelRoleModal')
  private displaytreelevelRoleModal!: TemplateRef<any>;
  @ViewChild('displayreasontreeRoleModal')
  private displayreasontreeRoleModal!: TemplateRef<any>;
  @ViewChild('displaycategorymappingRoleModal')
  private displaycategorymappingRoleModal!: TemplateRef<any>;

  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  // @ViewChild(MatSort) sort!: MatSort;
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroupData();
    this.getTreeTypes();

    // Search Defect Form
    this.dtForm = this.formBuilder.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.reasonForm = this.formBuilder.group({
      reason: ['', Validators.required],
    });
    this.categoryForm = this.formBuilder.group({
      category: [''],
    });
    this.treeForm = this.formBuilder.group({
      treeName: ['', Validators.required],
      type: ['', Validators.required],
    });
    this.dtreasonForm = this.formBuilder.group({
      unit: ['', Validators.required],
      dttree: ['', Validators.required],
      fvalue: [''],
      fname: [''],
      rtree: ['', Validators.required],
    });
    this.wastetreeForm = this.formBuilder.group({
      unit: ['', Validators.required],
      wtree: ['', Validators.required],
      wvalue: [''],
      wname: [''],
      wrtree: [''],
    });
    this.reasontreeForm = this.formBuilder.group({
      reason: [''],
      reason2: [''],
      reason3: [''],
      reason4: [''],
      level: [''],
      level2: [''],
      level3: [''],
      level4: [''],
    });
    this.catmappingForm = this.formBuilder.group({
      cat: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    // this.treeData.sort= this.sort.toArray()[0];
    // this.dataSource1.paginator = this.paginator.toArray()[0];
    // this.dataSource1.sort = this.sort.toArray()[0];
    // this.dataSource2.paginator = this.paginator.toArray()[1];
    // this.dataSource2.sort = this.sort.toArray()[1];
  }
  // openTreeModal(val: any): void {
  //   debugger
  //   this.treeType = val;
  //   this.updateOrderprocessingData = true;
  //   const key = {
  //     ParentId: this.dtForm.value.group,
  //     PlantId: this.dtForm.value.plant,
  //     // level: 'Unit',
  //     // IsOptional: 0
  //   };

  //   this.dtService.getPlantModelTree(key).subscribe((data: any) => {
  //     // tslint:disable-next-line:no-shadowed-variable
  //     const plantGroupData: PlantGroupElement[] = data.Tree;
  //     this.plantGroupSource.data =  plantGroupData;
  //     this.treeControl.dataNodes = this.plantGroupSource.data;
  //     this.expandAllNode();
  //     this.isPlantGroupModelVisible = true;
  //   }, (error: any) => {
  //     this.toaster.error('Error', error.message);
  //   });
  // }
  openTreeModal(val: any): void {
    this.treeType = val;
    this.updateOrderprocessingData = true;
    const key = {
      EGId: this.dtForm.value.group,
      level: 'Unit',
      IsOptional: 0,
    };

    this.dtService.unitTreeModal(key).subscribe(
      (data: any) => {
        // tslint:disable-next-line:no-shadowed-variable
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.expandAllNode();
        this.isPlantGroupModelVisible = true;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  refreshDtReasonTreeMapping(): void {
    this.dtreasonForm.reset();
    this.timeEventFormMode = true;
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.dtService.getGroupData(key).subscribe((res: any) => {
      this.groupList = res.masterList;
      this.dtForm.get('group')?.setValue(this.groupList[0].TypeId);
      this.getPlantData();
      this.GetReasonsGroup();
      this.GetCategoryGroup();
      this.LoadTreeTable(this.groupList[0].TypeId, 'Group');
    });
  }

  // getTree(): void {
  //   const key = {
  //     forDropDown: true,
  //     Id: 1,
  //     Option: 'Type',
  //     PlantId: this.dtForm.value.plant,
  //   };
  //   this.dtService.getTree(key).subscribe((response: any) => {
  //     this.dtTreeList = response.TreeList;
  //   });
  // }

  getPlantData(): void {
    this.machineDTReasonSource = new MatTableDataSource<any>();
    this.dtreasonForm.get('unit')?.reset();
    const key = {
      GroupId: this.dtForm.value.group,
    };
    this.dtService.getPlantData(key).subscribe((res: any) => {
      this.DepartList = res.DepartList;
      this.dtForm.get('plant')?.setValue(this.DepartList[0].DeptId);
      this.GetReasonsPlant();
      this.GetCategoryPlant();
      // this.LoadTrees();
      //this.getTree();
      // this.LoadTreeTable(this.DepartList[0].DeptId, 'Plant');
    });
  }
  GetReasonsPlant(): void {
    this.spinner.show();
    const key = {
      Id: this.dtForm.value.plant,
      Option: 'Plant',
    };
    this.dtService.GetReasons(key).subscribe((res: any) => {
      this.spinner.hide();
      this.ReasonDataSource = new MatTableDataSource<any>(res.ReasonList);
      this.getPaginator(this.ReasonDataSource, 3);
      this.LoadTrees();
      this.LoadTreeTable(this.dtForm.value.plant, 'Plant');
    });
  }

  GetCategoryGroup(): void {
    const key = {
      Id: this.dtForm.value.group,
      Option: 'Group',
    };
    this.dtService.GetCategory(key).subscribe((res: any) => {
      this.CategoryDataSource = new MatTableDataSource<any>(res.CategoryList);
      // this.GetCategoryPlant();
    });
  }

  GetCategoryPlant(): void {
    this.spinner.show();
    const key = {
      Id: this.dtForm.value.plant,
      Option: 'Plant',
    };
    this.dtService.GetCategory(key).subscribe((res: any) => {
      this.spinner.hide();

      this.CategoryDataSource = new MatTableDataSource<any>(res.CategoryList);
      this.getPaginator(this.CategoryDataSource, 3);
    });
  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      debugger;
      data.paginator = this.paginator.toArray()[index];
      data.sort = this.sort.toArray()[index];
      data.sort = this.sort2;
    }, 100);
  }

  // Reason Form
  GetReasonsGroup(): void {
    const key = {
      Id: this.dtForm.value.group,
      Option: 'Group',
    };
    this.dtService.GetReasons(key).subscribe((res: any) => {
      this.ReasonDataSource = new MatTableDataSource<any>(res.ReasonList);
      this.getPaginator(this.ReasonDataSource, 0);
      // this.GetReasonsPlant();
    });
  }
  addReason(): void {
    const key = {
      ReasonName: this.reasonForm.value.reason,
      GroupId: this.dtForm.value.group,
      PlantId: this.dtForm.value.plant,
    };
    const data = this.ReasonDataSource.filteredData.filter(
      (x) => x.ReasonName === this.reasonForm.value.reason
    ).length;
    if (data < 1) {
      this.dtService.insertReason(key).subscribe((response: any) => {
        if (!response.HasError) {
          this.toaster.success('Data Entered Successfully!');
          this.reasonForm.reset();
          this.resonFormMode = true;
          this.GetReasonsPlant();
        }
      });
    } else {
      this.toaster.error('Reason Name already exists!');
    }
  }
  updateReason(): void {
    const key = {
      ReasonName: this.reasonForm.value.reason,
      GroupId: this.dtForm.value.group,
      PlantId: this.dtForm.value.plant,
      UpdateId: this.updateReasonId,
    };
    this.dtService.updateReason(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.GetReasonsPlant();
        this.reasonForm.reset();
        this.toaster.success('Data Updated Successfully!');
        this.resonFormMode = true;
      }
    });
  }
  editReason(element: any): void {
    this.resonFormMode = false;
    this.updateReasonId = element.Id;
    this.reasonForm.get('reason')?.setValue(element.ReasonName);
  }
  deleteReason(element: any): void {
    const key = {
      UpdateId: element,
    };
    this.dtService.deleteReason(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.GetReasonsPlant();
        this.reasonForm.reset();
        this.toaster.success('Data Deleted Successfully!');
        this.resonFormMode = true;
      } else {
        this.showPopupMsg(response.ErrorMessage);
      }
    });
  }
  openreasonModal(): void {
    this.dialog.open(this.displayreasonRoleModal, {
      height: '90%',
      width: '90%',
    });
    this.GetReasonsGroup();
    this.resonFormMode = true;
    this.reasonForm.reset();
  }
  resetReason(): void {
    this.reasonForm.reset();
    this.resonFormMode = true;
  }
  // END REASON FORM
  addCategory(): void {
    this.spinner.show();
    const key = {
      CategoryName: this.categoryForm.value.category,
      GroupId: this.dtForm.value.group,
      PlantId: this.dtForm.value.plant,
    };
    const data = this.CategoryDataSource.filteredData.filter(
      (x) => x.CategoryName === this.categoryForm.value.category
    ).length;
    if (data < 1) {
      this.dtService.insertCategory(key).subscribe((response: any) => {
        if (!response.HasError) {
          this.GetCategoryPlant();
          this.toaster.success('Data Entered Successfully!');
        }
      });
    } else {
      this.toaster.error('Category already exists!');
    }
    this.resetCategory();
    this.categoryFormMode = true;
  }
  updateCategory(): void {
    const key = {
      CategoryName: this.categoryForm.value.category,
      GroupId: this.dtForm.value.group,
      PlantId: this.dtForm.value.plant,
      UpdateId: this.updateCategoryId,
    };
    this.dtService.updateCategory(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.GetCategoryPlant();
        this.resetCategory();
        this.toaster.success('Data Updated Successfully!');
        this.categoryFormMode = true;
      }
    });
  }
  editCategory(element: any): void {
    this.categoryFormMode = false;
    this.updateCategoryId = element.Id;
    this.categoryForm.get('category')?.setValue(element.CategoryName);
  }
  deleteCategory(element: any): void {
    const key = {
      UpdateId: element,
    };
    this.dtService.deleteCategory(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.GetCategoryPlant();
        this.reasonForm.reset();
        this.toaster.success('Data Deleted Successfully!');
        this.categoryFormMode = true;
      } else {
        this.showPopupMsg(response.ErrorMessage);
      }
    });
  }
  resetCategory(): void {
    this.categoryForm.reset();
    this.categoryFormMode = true;
  }
  // END Category FORM
  // Tree
  getTreeTypes(): void {
    const key = {};
    this.dtService.getTreeTypes(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.treeTypeList = response.TreeTypeList;
        this.treeForm.get('type')?.setValue(this.treeTypeList[0].TypeId);
      }
    });
  }

  LoadTreeTable(id: any, option: any, plantId = 0): void {
    this.spinner.show();
    const key = {
      Id: id,
      Option: option,
      PlantId: plantId,
    };
    this.dtService.getTree(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.spinner.hide();
        this.treeData = new MatTableDataSource<any>(response.TreeList);
        this.getPaginator(this.treeData, 0);
        // this.getPaginator2();
      }
    });
  }

  getPaginator2(): void {
    setTimeout(() => {
      this.treeData.paginator = this.paginator2;
      this.treeData.sort = this.sort2;
      //  data.sort = this.sort;
    }, 100);
  }

  changeTreeType(): void {
    this.LoadTreeTable(
      this.treeForm.value.type,
      'Type',
      this.dtForm.value.plant
    );
  }
  insertTree(): void {
    const key = {
      TypeId: this.treeForm.value.type,
      TreeName: this.treeForm.value.treeName,
      GroupId: this.dtForm.value.group,
      PlantId: this.dtForm.value.plant,
    };
    this.dtService.insertTree(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.LoadTreeTable(
          this.treeForm.value.type,
          'Type',
          this.dtForm.value.plant
        );
        this.LoadTrees();
        this.toaster.success('Data Entered Successfully!');
        this.treeForm.get('treeName')?.reset();
        this.treeFormMode = true;
      }
      this.resetTree();
      this.refreshDtReasonTreeMapping();
      this.resetWasteTreeForm();
    });
  }
  updateTree(): void {
    const key = {
      UpdateId: this.updateTreeId,
      TreeName: this.treeForm.value.treeName,
      GroupId: this.dtForm.value.group,
      PlantId: this.dtForm.value.plant,
    };
    this.dtService.updateTree(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.LoadTreeTable(
          this.treeForm.value.type,
          'Type',
          this.dtForm.value.plant
        );
        this.LoadTrees();
        this.toaster.success('Data Entered Successfully!');
        this.treeForm.get('treeName')?.reset();
        this.treeFormMode = true;
      }
      this.resetTree();
      this.refreshDtReasonTreeMapping();
      this.resetWasteTreeForm();
    });
  }
  editTree(element: any): void {
    this.treeFormMode = false;
    this.updateTreeId = element.Id;
    this.treeForm.get('treeName')?.setValue(element.TreeName);
    this.treeForm.get('type')?.setValue(element.TreeType);
  }
  deleteTree(element: any): void {
    const key = {
      UpdateId: element,
    };
    this.dtService.deleteTree(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.LoadTreeTable(
          this.treeForm.value.type,
          'Type',
          this.dtForm.value.plant
        );
        this.treeForm.get('treeName')?.reset();
        this.toaster.success('Data Deleted Successfully!');
        this.treeFormMode = true;
      } else {
        this.showPopupMsg(response.ErrorMessage);
      }
    });
    this.LoadTrees();
  }
  resetTree(): void {
    this.treeForm.reset();
    this.treeFormMode = true;
  }

  opentreelevelModal(element: any): void {
    this.TreeLevelName = element.TreeName;
    this.TreeId = element.Id;
    this.dialog.open(this.displaytreelevelRoleModal, {
      height: 'auto',
      width: '50%',
    });
    this.getTreeLevel();
  }
  insertTreeLevel(): void {
    const totalRecords = this.treeLevelSource.filteredData.length;
    if (totalRecords < 4) {
      const key = {
        TreeId: this.TreeId,
      };
      this.dtService.insertTreeLevel(key).subscribe((response: any) => {
        if (!response.HasError) {
          this.getTreeLevel();
          this.toaster.success('Data Entered Successfully!');
        } else {
          this.showPopupMsg(response.ErrorMessage);
        }
      });
    } else {
      this.toaster.error('Only 4 levels can be inserted!');
    }
  }
  deleteTreeLevel(element: any): void {
    const key = {
      TreeId: this.TreeId,
      UpdateId: element.Id,
    };
    this.dtService.deleteTreeLevel(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.getTreeLevel();
        this.toaster.success('Data Deleted Successfully!');
      } else {
        this.showPopupMsg(response.ErrorMessage);
      }
    });
  }
  getTreeLevel(): void {
    this.spinner.show();
    const key = {
      TreeId: this.TreeId,
    };
    this.dtService.getTreeLevel(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.spinner.hide();
        this.treeLevelSource = new MatTableDataSource<any>(
          response.TreeLevelList
        );
        this.getPaginator(this.treeData, 3);
      } else {
        this.spinner.hide();
        this.showPopupMsg(response.ErrorMessage);
      }
    });
  }
  LoadTrees(): void {
    const key = {
      forDropDown: true,
      Id: 1,
      Option: 'Type',
      PlantId: this.dtForm.value.plant,
    };
    this.dtService.getTree(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.dtTreeList = [];
        this.dtTreeList = response.TreeList;
      }
    });
    const key2 = {
      forDropDown: true,
      Id: 2,
      Option: 'Type',
      PlantId: this.dtForm.value.plant,
    };
    this.dtService.getTree(key2).subscribe((response: any) => {
      if (!response.HasError) {
        this.wasteTreeList = response.TreeList;
      }
    });
  }
  // END Tree Level

  opencategoryModal(): void {
    this.dialog.open(this.displaycategoryRoleModal, {
      height: '90%',
      width: '90%',
    });
    this.GetCategoryPlant();
  }
  closePlantGroupModel(): void {
    this.updateOrderprocessingData = false;
  }
  replaceIcon(value: any): any {
    // debugger
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  splitTree(node: any): any {
    if (node !== undefined) {
      return node.split('-')[0];
    }
  }
  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  SelectUnitName(unitname: any): void {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
    }
  }

  GetWasteFaultByUnit(): void {
    const key = {
      PUId: 1,
      UserId: 0,
    };
  }
  onNodeSelect(node: any): void {
    if (node.Level === 'Unit') {
      this.updateOrderprocessingData = false;
      this.MachineId = node.PrimaryId;
      this.dtreasonForm.get('unit')?.setValue(node.text);
      this.getTimedEventFault(this.MachineId, 'Unit');
      this.dtreasonForm.get('dttree')?.setValue(this.dtTreeList[0].Id);
    }
  }
  onNodeSelectReson(node: any): void {
    if (node.Level === 'Unit') {
      this.updateOrderprocessingData = false;
      this.MachineResonId = node.PrimaryId;
      this.wastetreeForm.get('unit')?.setValue(node.text);
      this.LoadWasteEventFaultTable(this.MachineResonId, 'Unit');
    }
  }
  getTimedEventFault(id: any, option: any): void {
    debugger;
    this.spinner.show();
    const key = {
      Id: id,
      Option: option,
      UnitId: this.MachineId,
    };
    this.dtService.getTimedEventFault(key).subscribe((res: any) => {
      this.spinner.hide();
      this.machineDTReasonSource = new MatTableDataSource<any>(
        res.TimedEventFaultList
      );
      this.getPaginator(this.machineDTReasonSource, 1);
    });
  }
  loadDTTrees(): void {
    this.dtreasonForm.get('rtree')?.reset();
    this.getTimedEventFault(this.dtreasonForm.value.dttree, 'ReasonTree');
    this.LoadDTReasonTrees();
  }
  LoadDTReasonTrees(): void {
    debugger;
    const key = {
      TreeId: this.dtreasonForm.value.dttree,
    };
    this.dtService.getReasonTrees(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.dtReasonTree = response.ReasonTreeList;
      }
    });
  }
  insertTimedEventFault(): void {
    debugger;
    const key = {
      TreeId: this.dtreasonForm.value.dttree,
      ReasonTreeIdList: this.dtreasonForm.value.rtree,
      UnitId: this.MachineId,
      FaultName: this.dtreasonForm.value.fname,
      FaultValue: this.dtreasonForm.value.fvalue,
    };
    this.dtService.insertTimedEventFault(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.getTimedEventFault(this.dtreasonForm.value.dttree, 'ReasonTree');
        this.toaster.success('Data Entered Successfully!');
        this.dtreasonForm.get('fname')?.reset();
        this.dtreasonForm.get('fvalue')?.reset();
        this.timeEventFormMode = true;
      }
    });
  }
  updateTimedEventFault(): void {
    const key = {
      TreeId: this.dtreasonForm.value.dttree,
      ReasonTreeIdList: this.dtreasonForm.value.rtree,
      UnitId: this.MachineId,
      FaultName: this.dtreasonForm.value.fname,
      FaultValue: this.dtreasonForm.value.fvalue,
      UpdateId: this.UpdateEventFaultValueId,
    };
    this.dtService.updateTimedEventFault(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.getTimedEventFault(this.dtreasonForm.value.dttree, 'ReasonTree');
        this.toaster.success('Data Update Successfully!');
        this.dtreasonForm.get('fname')?.reset();
        this.dtreasonForm.get('fvalue')?.reset();
        this.timeEventFormMode = true;
      }
    });
  }
  editTimeEventFault(element: any): void {
    this.timeEventFormMode = false;
    this.dtreasonForm.get('fname')?.setValue(element.FaultName);
    this.dtreasonForm.get('fvalue')?.setValue(element.FaultValue);
    const selectedTree = this.dtTreeList.find(
      (c: any) => c.TreeName === element.TreeName
    ).Id;
    this.dtreasonForm.get('dttree')?.setValue(selectedTree);
    const selectedReasonTree = this.dtReasonTree.find(
      (c: any) => c.TypeName === element.Level1
    ).TypeIdList;
    this.dtreasonForm.get('rtree')?.setValue(selectedReasonTree);
    this.UpdateEventFaultValueId = element.Id;
  }

  deleteTimedEventFault(element: any): void {
    const key = {
      UpdateId: element,
    };
    this.dtService.deleteTimedEventFault(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.getTimedEventFault(this.dtreasonForm.value.dttree, 'ReasonTree');
        this.toaster.success('Data Deleted Successfully!');
      } else {
        this.showPopupMsg(response.ErrorMessage);
        if (this.dtreasonForm.value.dttree !== undefined) {
          this.getTimedEventFault(this.dtreasonForm.value.dttree, 'ReasonTree');
          if (response.HasError === true) {
            this.toaster.success('Data Deleted Successfully!');
          } else {
            this.toaster.success('Data Deleted Successfully!');
          }
          // this.showPopupMsg(response.ErrorMessage);
        } else {
          this.showPopupMsg(response.ErrorMessage);
          // this.getTimedEventFault(this.dtreasonForm.value.dttree, 'ReasonTree');
          // this.getTimedEventFault(this.MachineId , 'Unit');
        }
        // this.toaster.success('Data Deleted Successfully!');
      }
      // else {
      //   this.showPopupMsg(response.ErrorMessage);
      // }
    });
  }
  // END TIME EVENT
  // START WASTE EVENT
  LoadWasteEventFaultTable(id: any, option: any): void {
    this.spinner.show();
    const key = {
      Id: id,
      Option: option,
      UnitId: this.MachineResonId,
    };
    this.dtService.getWasteEventFault(key).subscribe((res: any) => {
      this.spinner.hide();
      this.wasteEventFaultSource = new MatTableDataSource<any>(
        res.TimedEventFaultList
      );
      this.getPaginator(this.wasteEventFaultSource, 2);
    });
  }
  insertWasteEventFault(): void {
    const key = {
      TreeId: this.wastetreeForm.value.wtree,
      ReasonTreeIdList: this.wastetreeForm.value.wrtree,
      UnitId: this.MachineResonId,
      FaultName: this.wastetreeForm.value.wname,
      FaultValue: this.wastetreeForm.value.wvalue,
    };
    this.dtService.insertWasteEventFault(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.LoadWasteEventFaultTable(
          this.wastetreeForm.value.wtree,
          'ReasonTree'
        );
        this.toaster.success('Data Entered Successfully!');
        this.wastetreeForm.get('wname')?.reset();
        this.wastetreeForm.get('wvalue')?.reset();
        this.wastetreeForm.get('wrtree')?.reset();
        this.timeWasteFormMode = true;
      }
    });
  }
  updateWasteEventFault(): void {
    const key = {
      UpdateId: this.updateWasteReasonId,
      TreeId: this.wastetreeForm.value.wtree,
      ReasonTreeIdList: this.wastetreeForm.value.wrtree,
      UnitId: this.MachineResonId,
      FaultName: this.wastetreeForm.value.wname,
      FaultValue: this.wastetreeForm.value.wvalue,
    };
    this.dtService.updateWasteEventFault(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.LoadWasteEventFaultTable(
          this.wastetreeForm.value.wtree,
          'ReasonTree'
        );
        this.toaster.success('Data Updated Successfully!');
        this.wastetreeForm.get('wname')?.reset();
        this.wastetreeForm.get('wvalue')?.reset();
        this.wastetreeForm.get('wrtree')?.reset();
        this.timeWasteFormMode = true;
      }
    });
  }
  deleteWasteEventFault(element: any): void {
    const key = {
      UpdateId: element,
    };
    this.dtService.deleteWasteEventFault(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.LoadWasteEventFaultTable(
          this.wastetreeForm.value.wtree,
          'ReasonTree'
        );
        this.toaster.success('Data Deletd Successfully!');
        this.wastetreeForm.get('wname')?.reset();
        this.wastetreeForm.get('wvalue')?.reset();
        this.wastetreeForm.get('wrtree')?.reset();
        this.timeWasteFormMode = true;
      } else {
        this.showPopupMsg(response.ErrorMessage);
      }
    });
  }
  LoadWasteReasonTrees(element: any): void {
    this.wastetreeForm.get('wrtree')?.reset();
    this.WasteReasonTrees();
    this.LoadWasteEventFaultTable(element.value, 'ReasonTree');
  }
  WasteReasonTrees(): void {
    const key = {
      TreeId: this.wastetreeForm.value.wtree,
    };
    this.dtService.getReasonTrees(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.reasonTreeList = response.ReasonTreeList;
      }
    });
  }
  editWasteEventFault(element: any): void {
    this.timeWasteFormMode = false;
    this.updateWasteReasonId = element.Id;
    this.wastetreeForm.get('wname')?.setValue(element.FaultName);
    this.wastetreeForm.get('wvalue')?.setValue(element.FaultValue);
    const selectedTree = this.wasteTreeList.find(
      (c: any) => c.TreeName === element.TreeName
    ).Id;
    this.wastetreeForm.get('wtree')?.setValue(selectedTree);
    const selectedReasonTree = this.reasonTreeList.find(
      (c: any) => c.TypeName === element.Level1
    ).TypeIdList;
    this.wastetreeForm.get('wrtree')?.setValue(selectedReasonTree);
  }
  resetWasteTreeForm(): void {
    this.wastetreeForm.reset();
    this.timeWasteFormMode = true;
  }
  // END WASTE TREE

  // REASON TREE LIST
  resetReasonTreeForm(): void {
    this.reasontreeForm.reset();
    this.level2 = false;
    this.level3 = false;
    this.level4 = false;
    this.reasonTreeFormMode = true;
  }
  openreasontreeModal(element: any): void {
    this.reasonTreeFormMode = true;
    this.TreeLevelName = element.TreeName;
    this.TreeId = element.Id;
    this.getReasonTree();
    this.getEventReasons();
    this.level1 = true;
    this.level2 = false;
    this.level3 = false;
    this.level4 = false;
    this.getTreeLevels();
    this.isAddId = 1;
    this.dialog.open(this.displayreasontreeRoleModal, {
      height: '90%',
      width: '90%',
    });
  }
  deleteLevel2(): void {
    if (this.level3 === false && this.level4 === false) {
      this.level2 = false;
      this.reasontreeForm.get('reason2')?.reset();
    } else {
      this.toaster.error('error', 'Delete Level 3 or 4 ');
    }
  }
  deleteLevel3(): void {
    if (this.level4 === false) {
      this.level3 = false;
      this.reasontreeForm.get('reason3')?.reset();
    } else {
      this.toaster.error('error', 'Delete Level 4');
    }
  }
  deleteLevel4(): void {
    this.level4 = false;
    this.reasontreeForm.get('reason4')?.reset();
  }
  // insertReasonTree(): void {
  //   // debugger;
  //   let r1 = '';
  //   let r2 = '';
  //   let r3 = '';
  //   let r4 = '';
  //   let r = '';
  //   let reason = '';
  //   let parentId = 0;
  //   let isSameReason = false;
  //   let updateId = 0;
  //   let levelToUpdate = 0;
  //   let isUpdate = false;
  //   let level = '';
  //   r1 = this.reasontreeForm.value.reason.TypeId;
  //   const reasonLevel1 = this.reasontreeForm.value.reason.TypeName;
  //   level = this.reasontreeForm.value.level.TypeName;
  //   r = r1;
  //   reason = this.reasontreeForm.value.reason.TypeName;
  //   const table = this.reasontreeDataSource.filteredData;
  //   table.forEach((element: any) => {
  //     if (element.Level1 === reasonLevel1 && updateId === 0) {
  //       updateId = element.Id;
  //     }
  //   });
  //   if (this.level2 !== false) {
  //     isUpdate = true;
  //     levelToUpdate = 2;
  //     r2 = this.reasontreeForm.value.reason2.TypeId;
  //     r = r2;
  //     table.forEach((element: any) => {
  //       if (element.Reason === reason) {
  //         parentId = element.Id;
  //       }
  //     });
  //     reason = this.reasontreeForm.value.reason2.TypeName;
  //     if (r2 === r1) {
  //       isSameReason = true;
  //     }
  //   }
  //   if (this.level3 !== false) {
  //     isUpdate = true;
  //     levelToUpdate = 3;
  //     r3 = this.reasontreeForm.value.reason3.TypeId;
  //     r = r3;
  //     table.forEach((element: any) => {
  //       if (element.Reason === reason) {
  //         parentId = element.Id;
  //       }
  //     });
  //     reason = this.reasontreeForm.value.reason3.TypeName;
  //     if (r3 === r2 || r3 === r1) {
  //       isSameReason = true;
  //     }
  //   }
  //   if (this.level4 !== false) {
  //     isUpdate = true;
  //     levelToUpdate = 4;
  //     r4 = this.reasontreeForm.value.reason4.TypeId;
  //     r = r4;
  //     table.forEach((element: any) => {
  //       if (element.Reason === reason) {
  //         parentId = element.Id;
  //       }
  //     });
  //     reason = this.reasontreeForm.value.reason4.TypeName;
  //     if (r4 === r3 || r4 === r2 || r4 === r1) {
  //       isSameReason = true;
  //     }
  //   }
  //   if (!isUpdate) {
  //     updateId = 0;
  //   }
  //   if (!isSameReason) {
  //     const key = {
  //       UserId: 0,
  //       TreeId: this.TreeId,
  //       LevelToUpdate: levelToUpdate,
  //       UpdateId: updateId,
  //       ReasonId: r,
  //       ReasonId1: r1,
  //       ReasonId2: r2,
  //       ReasonId3: r3,
  //       ReasonId4: r4,
  //       ParentId: parentId
  //     };
  //     // const data = this.ReasonDataSource.filteredData.filter(x => x.ReasonName === this.reasontreeForm.value.reason.TypeName).length;
  //     // if (data < 1) {
  //     this.dtService.insertReasonTree(key).subscribe((response: any) => {
  //       if (!response.HasError) {
  //         isUpdate = false;
  //         this.getReasonTree();
  //         this.toaster.success('Data Entered Successfully!');
  //       } else {
  //         this.showPopupMsg(response);
  //       }
  //     });
  //     // this.resetReasonTreeForm();
  //     // this.refreshDtReasonTreeMapping();
  //     // this.resetWasteTreeForm();
  //   }
  //   else {
  //     this.toaster.error('Select different reasons for levels!');
  //   }
  // }
  insertReasonTree(): void {
    let r1 = '';
    let r2 = '';
    let r3 = '';
    let r4 = '';
    let r = '';
    let reason = '';
    let parentId = 0;
    let isSameReason = false;
    let updateId = 0;
    let levelToUpdate = 0;
    let isUpdate = false;
    let level = '';
    const table = this.reasontreeDataSource.filteredData;
    if (this.level1 !== false) {
      r1 = this.reasontreeForm.value.reason.TypeId;
      let reasonLevel1 = this.reasontreeForm.value.reason.TypeName;
      level = this.reasontreeForm.value.level.TypeName;
      r = r1;
      reason = this.reasontreeForm.value.reason.TypeName;
      this.isAddId = 1;
      if (this.isAddId === 0) {
        table.forEach((element: any) => {
          if (element.Level1 === reasonLevel1 && updateId === 0) {
            updateId = element.Id;
          }
        });
      }
    }

    if (this.level2 !== false) {
      isUpdate = true;
      levelToUpdate = 2;
      r2 = this.reasontreeForm.value.reason2.TypeId;
      r = r2;
      table.forEach((element: any) => {
        if (element.Reason === reason) {
          parentId = element.Id;
        }
      });
      reason = this.reasontreeForm.value.reason2.TypeName;
      if (r2 === r1) {
        isSameReason = true;
      }
    }
    if (this.level3 !== false) {
      isUpdate = true;
      levelToUpdate = 3;
      r3 = this.reasontreeForm.value.reason3.TypeId;
      r = r3;
      table.forEach((element: any) => {
        if (element.Reason === reason) {
          parentId = element.Id;
        }
      });
      reason = this.reasontreeForm.value.reason3.TypeName;
      if (r3 === r2 || r3 === r1) {
        isSameReason = true;
      }
    }
    if (this.level4 !== false) {
      isUpdate = true;
      levelToUpdate = 4;
      r4 = this.reasontreeForm.value.reason4.TypeId;
      r = r4;
      table.forEach((element: any) => {
        if (element.Reason === reason) {
          parentId = element.Id;
        }
      });
      reason = this.reasontreeForm.value.reason4.TypeName;
      if (r4 === r3 || r4 === r2 || r4 === r1) {
        isSameReason = true;
      }
    }
    if (!isUpdate) {
      updateId = 0;
    }
    if (!isSameReason) {
      const key = {
        UserId: 0,
        TreeId: this.TreeId,
        LevelToUpdate: levelToUpdate,
        UpdateId: updateId,
        ReasonId: r,
        ReasonId1: r1,
        ReasonId2: r2,
        ReasonId3: r3,
        ReasonId4: r4,
        ParentId: parentId,
      };

      this.dtService.insertReasonTree(key).subscribe((response: any) => {
        if (!response.HasError) {
          isUpdate = false;
          this.getReasonTree();
          this.toaster.success('Data Entered Successfully!');
        } else {
          this.showPopupMsg(response);
        }
      });
    } else {
      this.toaster.error('Select different reasons for levels!');
    }
  }
  updateReasonTree(): void {
    let r2 = 0;
    let r3 = 0;
    let r4 = 0;
    if (this.level2 !== false) {
      r2 = this.reasontreeForm.value.reason2.TypeId;
    }
    if (this.level3 !== false) {
      r3 = this.reasontreeForm.value.reason3.TypeId;
    }
    if (this.level4 !== false) {
      r4 = this.reasontreeForm.value.reason4.TypeId;
    }
    const data = {
      UpdateId: this.resaonTreeId,
      ReasonId1: this.reasontreeForm.value.reason.TypeId,
      ReasonId2: r2,
      ReasonId3: r3,
      ReasonId4: r4,
    };
    this.dtService.updateReasonTree(data).subscribe((response: any) => {
      if (!response.HasError) {
        this.toaster.success('Data Update Successfully!');
        this.getReasonTree();
        this.reasontreeForm.reset();
        this.reasonTreeFormMode = true;
      } else {
        this.showPopupMsg(response.ErrorMessage);
      }
      this.resetReasonTreeForm();
      this.refreshDtReasonTreeMapping();
      this.resetWasteTreeForm();
    });
  }

  editReasonTree(element: any): void {
    const table = this.reasontreeDataSource.filteredData;
    let reason: any;
    let level;
    table.forEach((item) => {
      if (item.Id === element) {
        if (item.Level1 !== null) {
          level = 1;
          reason = item.Level1;
          this.reasontreeForm
            .get('reason')
            ?.setValue(this.reasonList.find((c: any) => c.TypeName === reason));
          this.reasontreeForm.get('level')?.setValue(this.treeLevel1List[0]);
          this.level2 = false;
          this.level3 = false;
          this.level4 = false;
        }
        if (item.Level2 !== null) {
          this.level2 = true;
          level = 2;
          reason = item.Level2;
          this.reasontreeForm
            .get('reason2')
            ?.setValue(this.reasonList.find((c: any) => c.TypeName === reason));
          this.reasontreeForm.get('level2')?.setValue(this.treeLevel2List[0]);
          this.level3 = false;
          this.level4 = false;
        }
        if (item.Level3 !== null) {
          this.level3 = true;
          level = 3;
          reason = item.Level3;
          this.reasontreeForm
            .get('reason3')
            ?.setValue(this.reasonList.find((c: any) => c.TypeName === reason));
          this.reasontreeForm.get('level3')?.setValue(this.treeLevel3List[0]);
          this.level4 = false;
        }
        if (item.Level4 !== null) {
          this.level4 = true;
          level = 4;
          reason = item.Level4;
          this.reasontreeForm
            .get('reason4')
            ?.setValue(this.reasonList.find((c: any) => c.TypeName === reason));
          this.reasontreeForm.get('level4')?.setValue(this.treeLevel4List[0]);
        }
      }
    });
    this.resaonTreeId = element;
    this.isAddId = 0;
    this.reasonTreeFormMode = false;
  }
  deleteReasonTree(element: any): void {
    const key = {
      UpdateId: element,
    };
    this.dtService.deleteReasonTree(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.toaster.success('Data Deleted Successfully!');
        this.getReasonTree();
      } else {
        this.showPopupMsg(response.ErrorMessage);
      }
      this.refreshDtReasonTreeMapping();
      this.resetWasteTreeForm();
    });
  }
  getReasonTree(): void {
    this.spinner.show();

    const key = {
      TreeId: this.TreeId,
    };
    this.dtService.getReasonTree(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.spinner.hide();

        this.reasontreeDataSource = new MatTableDataSource<any>(
          response.ReasonTreeList
        );
        this.getPaginator(this.reasontreeDataSource, 3);
      }
    });
  }
  getTreeLevels(): void {
    const key = {
      TreeId: this.TreeId,
    };
    this.dtService.getTreeLevels(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.treeLevelList = response.TreeLevelList;
        if (this.treeLevelList.length > 0) {
          this.treeLevel1List = [response.TreeLevelList[0]];
        }
        if (this.treeLevelList.length > 1) {
          this.treeLevel2List = [response.TreeLevelList[1]];
        }
        if (this.treeLevelList.length > 2) {
          this.treeLevel3List = [response.TreeLevelList[2]];
        }
        if (this.treeLevelList.length > 3) {
          this.treeLevel4List = [response.TreeLevelList[3]];
        }
      }
    });
  }
  getEventReasons(): void {
    const key = {
      Id: this.dtForm.value.plant,
      Option: 'Plant',
    };
    this.dtService.getEventReasons(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.reasonList = response.ReasonList;
      }
    });
  }
  onLevel1Click(): void {
    if (this.treeLevelList.length > 1) {
      this.level2 = true;
    }
  }
  onLevel2Click(): void {
    if (this.treeLevelList.length > 2) {
      this.level3 = true;
    }
  }
  onLevel3Click(): void {
    if (this.treeLevelList.length > 3) {
      this.level4 = true;
    }
  }
  getCategories(element: any): void {
    debugger;
    const key = {
      Id: this.dtForm.value.plant,
      Option: 'Plant',
    };
    this.dtService.getCategories(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.categoryList = response.CategoryList;
      }
    });
  }

  opencategorymappingModal(element: any): void {
    this.categoryMappingFormMode = true;
    this.dialog.open(this.displaycategorymappingRoleModal, {
      height: '62%',
      width: '42%',
    });
    this.getCategories(element);
    this.reasonTreeId = element;
    this.getCategoryMapping();
  }
  getCategoryMapping(): void {
    this.spinner.show();
    const key = {
      ReasonTreeId: this.reasonTreeId,
    };
    this.dtService.getCategoryMapping(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.categoryMappingTreeDataSource = new MatTableDataSource<any>(
          response.CategoryMappingList
        );
        this.spinner.hide();
        this.getPaginator(this.categoryMappingTreeDataSource, 4);
      }
    });
  }
  insertCategoryMapping(): void {
    const key = {
      ReasonTreeId: this.reasonTreeId,
      CategoryId: this.catmappingForm.value.cat,
    };
    const data = this.categoryMappingTreeDataSource.filteredData.length;
    if (data < 1) {
      this.dtService.insertCategoryMapping(key).subscribe((response: any) => {
        this.getCategoryMapping();
        this.getReasonTree();
        this.toaster.success('Data Add Successfully!');
      });
    } else {
      this.toaster.error('Only 1 category can be mapped!');
    }
    this.catmappingForm.get('cat')?.reset();
    this.categoryMappingFormMode = true;
  }

  editCategoryMapping(element: any): void {
    debugger;
    this.reasonTreeMappigId = element.Id;
    this.catmappingForm
      .get('cat')
      ?.setValue(
        this.categoryList.find((x: any) => x.TypeName === element.CategoryName)
          .TypeId
      );
    this.categoryMappingFormMode = false;
  }

  updateCategoryMapping(): void {
    const key = {
      // ReasonTreeId: this.reasonTreeId,
      UpdateId: this.reasonTreeMappigId,
      CategoryId: this.catmappingForm.value.cat,
    };
    this.dtService.updateCategoryMapping(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.getCategoryMapping();
        this.getReasonTree();
        this.toaster.success('Data Update Successfully!');
      }
      this.catmappingForm.get('cat')?.reset();
      this.categoryMappingFormMode = true;
    });
  }
  deleteCategoryMapping(element: any): void {
    const key = {
      UpdateId: element,
    };
    this.dtService.deleteCategoryMapping(key).subscribe((response: any) => {
      if (!response.HasError) {
        this.getCategoryMapping();
        this.toaster.success('Data Delete Successfully!');
      }
    });
  }
  resetCategoryMapping(): void {
    this.catmappingForm.reset();
    this.categoryMappingFormMode = true;
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
      this.toaster.success('Success', msg.substring(3, msg.length));
    } else {
      this.toaster.error('Error', msg);
    }
  }

  applyFilterOne(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.treeData.filter = filterValue.trim().toLowerCase();
    this.machineDTReasonSource.filter = filterValue.trim().toLowerCase();
    this.wasteEventFaultSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterTwo(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.reasontreeDataSource.filter = filterValue.trim().toLowerCase();
    this.categoryMappingTreeDataSource.filter = filterValue
      .trim()
      .toLowerCase();
    this.ReasonDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterThree(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ReasonDataSource.filter = filterValue.trim().toLowerCase();
    this.CategoryDataSource.filter = filterValue.trim().toLowerCase();
  }
}
