<div class="machine-parameter-content">
  <div class="content-top-box">
    <form [formGroup]="addTDCForm">
      <div class="content-top-row">
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.TDCElement : ('TDCElement' | translate)}}</mat-label>
          <input matInput placeholder="{{langObj ? langObj?.EnterElement : ('EnterElement' | translate)}}" formControlName="tdcelement" required>
        </mat-form-field>
        <!-- <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-label>GL Account</mat-label>
        <input matInput placeholder="enter GL account" formControlName="glaccount" required>
      </mat-form-field> -->
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>{{langObj ? langObj?.CostCenter : ('CostCenter' | translate)}}</mat-label>
          <mat-select formControlName="costCenterId" multiple (selectionChange)="costCenters($event)">
            <mat-optgroup *ngFor="let parent of parentsDDL" [label]="parent.parentName" [disabled]="parent.disabled">
              <!-- <mat-option *ngFor="let center of parent.detail" [value]="center.costCenterId"> -->
              <mat-option *ngFor="let center of parent.detail" [value]="center.costCenterId">
                {{center.costCenterName}}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>

        <button type="submit" *ngIf="!TDCformMode && IsWrite" [disabled]="!addTDCForm.valid" mat-mini-fab mat-button
          mat-dialog-close color="primary" pTooltip="Update" tooltipPosition="top" style="margin-left: 15px;"
          (click)="UpdateTDCElement()">
          <mat-icon style="color: #fff">edit</mat-icon>
        </button>
        <button type="submit" *ngIf="TDCformMode && IsWrite" [disabled]="!addTDCForm.valid" mat-mini-fab mat-button
          mat-dialog-close color="primary" pTooltip="Save" tooltipPosition="top" (click)="AddTDCElement()"
          style="margin-left: 15px;">
          <mat-icon style="color: #fff">save</mat-icon>
        </button>
        <button [disabled]="!addTDCForm.valid" mat-mini-fab mat-button pTooltip="Reset" tooltipPosition="top"
          (click)="RefreshTDCElement()" style="margin-left: 15px;">
          <mat-icon style="color: #fff">refresh</mat-icon>
        </button>

        <!--date and template-->
        <!-- <div class="inputField" >
        <nz-date-picker class="calender-input" style="width:200px;" id="toTimePicker"
          formControlName="date" nzFormat="dd/MM/yyyy HH:mm"
          [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true"></nz-date-picker>
        <span class="floating-text">Date</span>
      </div>
      <div style="display: flex; justify-content: flex-end; margin-left: 27px; margin-top:0px;">
        <button mat-raised-button pTooltip="download template"
          style="border-radius: 10px; height:50px; width:200px; background-color: #E5E8E8;" tooltipPosition="top"
          (click)="exportAsXLSX()">TDC Element Template</button>
      </div> -->

      </div>
    </form>
  </div>
</div>
<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center">
    <mat-card-title class="p-ml-2">{{langObj ? langObj?.TDCElement : ('TDCElement' | translate)}}</mat-card-title>
    <div class="">
      <mat-form-field>
        <input matInput placeholder="{{langObj ? langObj?.Search : ('Search' | translate)}}...." autocomplete="off" (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>
  </div>

  <mat-card-content>
    <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
      <p style="font-size: 20px; color: #050a4f">{{langObj ? langObj?.FetchingDepartmentData : ('FetchingDepartmentData' | translate)}}...</p>
    </ngx-spinner>
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
      <table mat-table [dataSource]="costElementDataSource" class="basic-table plant-model-table" matSort>

        <ng-container matColumnDef="costActions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-text">{{langObj ? langObj?.Action : ('Action' | translate)}}</p>

          </th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <!-- <button mat-icon-button class="node-control-btn node-create" aria-label="create item"
                pTooltip="Add TDC Detail" tooltipPosition="top" (click)="TDCElementDetailPopup(element)"
                *ngIf="IsWrite">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.5783 7.10211H6.91168V11.7688H5.35612V7.10211H0.689453V5.54655H5.35612V0.879883H6.91168V5.54655H11.5783V7.10211Z"
                    fill="white" />
                </svg>
              </button> -->
              <button mat-icon-button class="edit-btn" aria-label="Edit Row" (click)="EditTDCElement(element)"
                pTooltip="Edit Cost" tooltipPosition="top" *ngIf="IsWrite">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>
              <!-- <button mat-icon-button  class="node-control-btn node-delete" aria-label="delete item" (click)="DeletedTDCElement(element)"
                      pTooltip="Delete Cost" tooltipPosition="top" >
                      <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                          fill="white" />
                      </svg>
                </button> -->
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="costCenter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.CostCenter : ('CostCenter' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.costCenterName}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.TDCElementName : ('TDCElementName' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.tdcElementName}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="costColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: costColumns;"></tr>
        <tr class="mat-row" *matNoDataRow style="text-align: center;">
          <td class="mat-cell" colspan="4">{{langObj ? langObj?.Nodatamatching : ('Nodatamatching' | translate)}}</td>
        </tr>
      </table>
    </div>
    <mat-paginator class="basic-paginataor" [pageSizeOptions]="[2, 5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
  </mat-card-content>
</mat-card>


<!--TDC Element Detail Popup-->
<ng-template #displayTDCElementModal>
  <form [formGroup]="TDCDetailForm">
    <div class="d-flex justify-content-between align-items-center">
      <!-- <h1 mat-dialog-title>{{formMode ? 'Update' : 'Add' }} Cost Dictionary</h1> -->
      <h1 mat-dialog-title>{{langObj ? langObj?.TDCElementDetail : ('TDCElementDetail' | translate)}}</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label class="placeholder-title">{{langObj ? langObj?.TDCValue : ('TDCValue' | translate)}}</mat-label>
          <input type="number" formControlName="tdcvalue" readonly="false" class="input-text" matInput required>
        </mat-form-field>
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label class="placeholder-title">{{langObj ? langObj?.TDCBudget : ('TDCBudget' | translate)}}</mat-label>
          <input type="number" formControlName="tdcBudget" readonly="false" class="input-text" matInput required>
        </mat-form-field>
        <div class="inputField">
          <nz-date-picker class="calender-input" id="startPicker" formControlName="date" nzFormat="dd/MM/yyyy"
            [required]='true'>
          </nz-date-picker>
          <span class="floating-text" style="margin-left: 10px;">{{langObj ? langObj?.Date : ('Date' | translate)}}</span>
        </div>
        <button type="button" *ngIf="!TDCDetailformMode" [disabled]="!TDCDetailForm.valid" mat-mini-fab mat-button
          color="primary" pTooltip="Update" tooltipPosition="top" style="margin-left: 15px;"
          (click)="UpdateTDCElementDetail()">
          <mat-icon style="color: #fff">edit</mat-icon>
        </button>
        <button type="button" *ngIf="TDCDetailformMode" [disabled]="!TDCDetailForm.valid" mat-mini-fab mat-button
          color="primary" pTooltip="Save" tooltipPosition="top" (click)="AddTDCElementDetail()"
          style="margin-left: 15px;">
          <mat-icon style="color: #fff">save</mat-icon>
        </button>
        <button [disabled]="!TDCDetailForm.valid" mat-mini-fab mat-button pTooltip="Reset" tooltipPosition="top"
          (click)="RefreshTDCElementDetail()" style="margin-left: 15px;">
          <mat-icon style="color: #fff">refresh</mat-icon>
        </button>
      </div>
      <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-5">
        <table mat-table [dataSource]="TDCDetailDataSource" class="basic-table plant-model-table" matSort>
          <ng-container matColumnDef="tdcActions">
            <th mat-header-cell *matHeaderCellDef>
              <p class="header-text">{{langObj ? langObj?.Action : ('Action' | translate)}}</p>

            </th>
            <td mat-cell *matCellDef="let element" class="row-action-btns-column">
              <div class="row-action-btns">
                <button mat-icon-button class="edit-btn" aria-label="Edit Row" (click)="EditTDCElementDetail(element)"
                  pTooltip="Edit Cost" tooltipPosition="top">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                      fill="white" />
                  </svg>
                </button>
                <button mat-icon-button type="button" class="node-control-btn node-delete" aria-label="delete item"
                  (click)="DeleteTDCElementDetail(element)" pTooltip="Delete Cost" tooltipPosition="top">
                  <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                      fill="white" />
                  </svg>
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="tdcValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.TDCValue : ('TDCValue' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.tdcValue}} </td>
          </ng-container>
          <ng-container matColumnDef="tdcBudget">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.TDCBudget : ('TDCBudget' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.tdcBudget}} </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <p class="header-text">{{langObj ? langObj?.Date : ('Date' | translate)}}</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.date}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="tdcColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: tdcColumns;"></tr>
          <tr class="mat-row" *matNoDataRow style="text-align: center;">
            <td class="mat-cell" colspan="4">{{langObj ? langObj?.Nodatamatching : ('Nodatamatching' | translate)}}</td>
          </tr>
        </table>
        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[2, 5, 10, 20]" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </form>
</ng-template>