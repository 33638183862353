import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CILMasterService } from 'src/app/services/cil-master-service';
import { ToastrService } from 'ngx-toastr';
import { RMconfigurationService } from 'src/app/services/rmconfiguration.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleTaskDialogComponent } from './schedule-task-dialog/schedule-task-dialog.component';
import { prevTaskService } from 'src/app/services/prev-task.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PrevConfigurationService } from 'src/app/services/prev-configuration.service';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
@Component({
  selector: 'app-schedule-task',
  templateUrl: './schedule-task.component.html',
  styleUrls: ['./schedule-task.component.scss'],
})
export class ScheduleTaskComponent implements OnInit {
  @ViewChild('pmTaskForm') private pmTaskForm!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  groupList: any = [];
  filteredGroupList: any = [];
  plantList: any = [];
  filteredPlantList: any = [];
  technicianList: any = [];
  filteredTechnicianList: any = [];
  userList: any = [];
  breadcrumList: any = [];
  spinnerText: string = '';
  headerData: any = {
    group: '',
    plant: '',
    technician: 'ALL',
    WeekMonth: 2,
  };
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  weekNames = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  taskList: any = [];
  taskColumns: string[] = [
    'Sno',
    'Machine',
    'Task Name',
    'Description',
    'Frequency',
    'Activity',
    'Duration',
  ];
  scheduleTaskColumns: any = [];
  weekScheduleTaskColumns: any = [];
  currentMonth: string = '';
  currentYear: number = 0;
  calTypeList: any = [
    { id: 1, value: 'Week' },
    { id: 2, value: 'Month' },
  ];
  taskRow: any = [];
  weekIndex: number = 0;
  isAdmin: boolean = false;

  PMTask: any = {
    Group: '',
    Plant: '',
    line: null,
    units: null,
    assembly: null,
    subAssembly: null,
    component: null,
    lineName: '',
    unitName: '',
    assemblyName: '',
    subAssemblyName: '',
    componentName: '',
    Description: '',
    Technician: '',
    Frequency: '',
    displayMachine: '',
    Duration: '',
    SpareParts: '',
    UploadFile: '',
    TaskType: '',
    MaintenanceType: '',
    FromTaskDate: new Date(),
    FileType: [],
    CreatedAt: '',
    CreatedBy: 0,
    ID: 0,
  };
  PMTaskForm: any;
  HeaderForm!: FormGroup;
  TechnicianList: any = [];
  orgUserList: any = [];
  filterTechnicianList: any = [];
  WOTypeList: any = [];
  frequencyList: any = [];
  TaskTypeList: any = [];
  FreqDuration: any;
  imageSrc = new Array<string>();
  fileType: any;
  FileData: any = [];
  FileList: any = [];
  user: any;
  permisson!: any;
  IsWrite: boolean = false;
  IsAccess!: any;
  selectedFilter = 'ALL';

  MaintenanceType: any = [
    { ID: 1, Name: 'Time Based' },
    { ID: 2, Name: 'Condition Based' },
  ];
  groupFiltList: any;
  plantFiltList: any;
  WOTypeFiltList: any;
  TechnicianFiltList: any;
  FrequencyFiltList: any;
  TaskTypeFiltList: any;

  currentDate = new Date();

  LineList: any = [];
  LineFilterList: any = [];
  LineAllList: any = [];

  UnitList: any = [];
  UnitFilterList: any = [];
  UnitAllList: any = [];

  AssemblyList: any = [];
  AssemblyFilterList: any = [];
  AssemblyAllList: any = [];

  SubAssemblyList: any = [];
  SubAssemblyFilterList: any = [];
  SubAssemblyAllList: any = [];

  ComponentList: any = [];
  ComponentFilterList: any = [];
  ComponentAllList: any = [];
  TechnicianList1: any;
  TechnicianListFilter: any;
  FrequencyList: any;
  FrequencyFiterList: any;
  TaskTypeList1: any;
  TaskTypeFilterList: any;
  WOTypeList1: any;
  WOTypeFilterList: any;
  minDate: Date;
  TaskListForDuplicationCheck: Array<any> = [];

  constructor(
    private cilMasterService: CILMasterService,
    private enterpriseGroupService: EnterPriseGroupService,
    private fb: FormBuilder,
    public router: Router,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private rmConfigurationService: RMconfigurationService,
    private userManagementService: UserManagementService,
    private prevTaskService: prevTaskService,
    public dialog: MatDialog,
    private PrevConfigurationService: PrevConfigurationService,
    private commonService: CommonService,
  ) {
    this.PMTaskForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      line: new FormControl('', [Validators.required]),
      unit: new FormControl('', [Validators.required]),
      assembly: new FormControl(''),
      subAssembly: new FormControl(''),
      component: new FormControl(''),
      description: ['', Validators.required],
      technicians: ['', Validators.required],
      frequency: ['', Validators.required],
      // display_machine: ['', Validators.required],
      WOType: ['', Validators.required],
      type_duration: ['', Validators.required],
      spare_part: ['', Validators.required],
      task_type: ['', Validators.required],
      maintenanceType: ['', Validators.required],
      fromTaskDate: ['', Validators.required],
      toTaskDate: ['', Validators.required],
    });
    this.HeaderForm = this.fb.group({
      group: new FormControl(null, [Validators.required]),
      plant: new FormControl(null, [Validators.required]),
      technician: new FormControl(null, [Validators.required]),
      curmonth: new FormControl(null, [Validators.required]),
      wkmonth: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.isAdmin = this.user.IsAdmin;
    this.headerData.technician = !this.isAdmin
      ? parseInt(this.user.UserId)
      : this.headerData.technician;
    this.currentMonth = this.monthNames[new Date().getMonth()];
    // this.getTaskList();
    this.getGroups();
    this.GetMenuAccess();
    this.GetBreadcrumList();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getWeek() {
    const now = new Date();
    let selectedMonth: any = this.monthNames.findIndex(
      (mnth: any) => mnth == this.currentMonth
    );
    const days = new Date(now.getFullYear(), selectedMonth + 1, 0).getDate();
    let scheduleTaskColumns: any = [];
    this.currentYear = now.getFullYear();
    let currentDayAdded: any = 0;
    let currentDay = selectedMonth == now.getMonth() ? now.getDay() : 1;
    let currentDate = now.getDate();
    for (let i = 1; i <= days; i++) {
      const fullDate = new Date();
      fullDate.setFullYear(this.currentYear);
      fullDate.setMonth(selectedMonth);
      fullDate.setDate(i);

      let column: any = { date: 0, day: '', weekOff: false };
      column.date = i;
      column.weekOff = currentDay == 5 || currentDay == 6 ? true : false;
      if (i == currentDate) {
        column.day = this.weekNames[currentDay - 1];
        currentDayAdded = true;
        currentDay++;
      } else if (currentDayAdded != 0 && currentDayAdded) {
        if (currentDay > 7) currentDay = 1;
        column.day = this.weekNames[currentDay - 1];
        currentDay++;
      }
      column.fullDate = fullDate;
      scheduleTaskColumns.push(column);
    }
    currentDay = now.getDay();
    for (let i = currentDate; i > 0; i--) {
      const fullDate = new Date();
      fullDate.setFullYear(this.currentYear);
      fullDate.setMonth(selectedMonth);
      fullDate.setDate(i);

      let column: any = { date: 0, day: '', weekOff: false };
      column.date = i;
      column.weekOff = currentDay == 5 || currentDay == 6 ? true : false;
      if (currentDay <= 0) currentDay = 7;
      column.day = this.weekNames[currentDay - 1];
      currentDay--;
      column.fullDate = fullDate;
      scheduleTaskColumns[i - 1] = column;
    }
    if (scheduleTaskColumns && scheduleTaskColumns.length)
      scheduleTaskColumns.map((schd: any) => (schd.task = []));

    if (this.taskList && this.taskList.length) {
      for (const task of this.taskList) {
        const scheduleDate = new Date(task.TaskExpireTime);
        const date = scheduleDate.getDate();
        let scheduleColumn: any;
        if (scheduleTaskColumns && scheduleTaskColumns.length) {
          scheduleColumn = scheduleTaskColumns.find(
            (schd: any) => schd.date == date
          );
          if (scheduleColumn) scheduleColumn.task.push(task);
        }
      }
    }
    if (scheduleTaskColumns && scheduleTaskColumns.length) {
      this.weekScheduleTaskColumns = [];
      let dataCount = 0,
        weekCount = 0;
      for (let column of scheduleTaskColumns) {
        if (dataCount < 7) {
          if (!this.weekScheduleTaskColumns[weekCount])
            this.weekScheduleTaskColumns[weekCount] = [];
          this.weekScheduleTaskColumns[weekCount].push(column);
        } else {
          dataCount = 0;
          weekCount++;
          this.weekScheduleTaskColumns[weekCount] = [];
          this.weekScheduleTaskColumns[weekCount].push(column);
        }
        dataCount++;
      }
      let selectedMonth = this.monthNames.findIndex(
        (mnth) => mnth === this.currentMonth
      );
      let weekIndex = 0;
      let weekGot = false;
      if (new Date().getMonth() == selectedMonth) {
        for (let week of this.weekScheduleTaskColumns) {
          if (week && week.length && !weekGot) {
            const weekFound = week.find(
              (weekData: any) => weekData.date == currentDate
            );
            if (weekFound && this.weekScheduleTaskColumns[weekIndex])
              weekGot = true;
            else if (!weekFound) weekIndex++;
          }
        }
      }
      this.scheduleTaskColumns = scheduleTaskColumns;
      let weekDataAdded = false;
      this.scheduleTaskColumns.forEach(
        (scheduleTaskColumns: any, index: number) => {
          if (!weekDataAdded) {
            this.scheduleTaskColumns[index] = weekIndex
              ? this.weekScheduleTaskColumns[weekIndex]
              : this.weekScheduleTaskColumns[0];
            weekDataAdded = true;
          } else this.scheduleTaskColumns[index] = [];
        }
      );
      this.weekIndex = weekIndex;
      this.taskRow = [];
      if (
        this.scheduleTaskColumns &&
        this.scheduleTaskColumns[0] &&
        this.scheduleTaskColumns[0].length
      ) {
        let maxTaskRow = 0;
        for (let task of this.scheduleTaskColumns[0]) {
          if (!task.task) task.task = [];
          if (maxTaskRow < task.task.length) maxTaskRow = task.task.length;
        }
        this.taskRow[0] = [];
        for (let i = 0; i < maxTaskRow; i++) this.taskRow[0].push(i);
      }
    }
  }

  changeWeek(index: number) {
    this.scheduleTaskColumns[0] = this.weekScheduleTaskColumns[index];
    this.weekIndex = index;
    this.taskRow = [];
    if (
      this.scheduleTaskColumns &&
      this.scheduleTaskColumns[0] &&
      this.scheduleTaskColumns[0].length
    ) {
      let maxTaskRow = 0;
      for (let task of this.scheduleTaskColumns[0]) {
        if (!task.task) task.task = [];
        if (maxTaskRow < task.task.length) maxTaskRow = task.task.length;
      }
      this.taskRow[0] = [];
      for (let i = 0; i < maxTaskRow; i++) this.taskRow[0].push(i);
    }
  }

  getMonth() {
    const now = new Date();
    let selectedMonth: any = this.monthNames.findIndex(
      (mnth: any) => mnth == this.currentMonth
    );
    const days = new Date(now.getFullYear(), selectedMonth + 1, 0).getDate();
    let scheduleTaskColumns: any = [];
    this.currentYear = now.getFullYear();
    let currentDayAdded: any = 0;
    let currentDay = selectedMonth == now.getMonth() ? now.getDay() : 1;
    let currentDate = now.getDate();
    for (let i = 1; i <= days; i++) {
      const fullDate = new Date();
      fullDate.setFullYear(this.currentYear);
      fullDate.setMonth(selectedMonth);
      fullDate.setDate(i);

      let column: any = { date: 0, day: '', weekOff: false };
      column.date = i;
      column.weekOff = currentDay == 5 || currentDay == 6 ? true : false;
      if (i == currentDate) {
        column.day = this.weekNames[currentDay - 1];
        currentDayAdded = true;
        currentDay++;
      } else if (currentDayAdded != 0 && currentDayAdded) {
        if (currentDay > 7) currentDay = 1;
        column.day = this.weekNames[currentDay - 1];
        currentDay++;
      }
      column.fullDate = fullDate;
      scheduleTaskColumns.push(column);
    }
    currentDay = now.getDay();
    for (let i = currentDate; i > 0; i--) {
      const fullDate = new Date();
      fullDate.setFullYear(this.currentYear);
      fullDate.setMonth(selectedMonth);
      fullDate.setDate(i);

      let column: any = { date: 0, day: '', weekOff: false };
      column.date = i;
      column.weekOff = currentDay == 5 || currentDay == 6 ? true : false;
      if (currentDay <= 0) currentDay = 7;
      column.day = this.weekNames[currentDay - 1];
      currentDay--;
      column.fullDate = fullDate;
      scheduleTaskColumns[i - 1] = column;
    }
    for (const row of this.scheduleTaskColumns) {
      if (row && row.length) {
        for (const scheduleColumn of row) {
          scheduleColumn.task = [];
        }
      }
    }
    let taskRow: any = [];
    if (this.taskList && this.taskList.length) {
      scheduleTaskColumns.map((schd: any) => (schd.task = []));
      for (const task of this.taskList) {
        const scheduleDate = new Date(task.TaskExpireTime);
        const date = scheduleDate.getDate();
        if (scheduleTaskColumns && scheduleTaskColumns.length) {
          let scheduleColumn: any = scheduleTaskColumns.find(
            (schd: any) => schd.date == date
          );
          if (scheduleColumn) scheduleColumn.task.push(task);
        }
      }
    }
    if (scheduleTaskColumns && scheduleTaskColumns.length) {
      this.scheduleTaskColumns = [];
      this.scheduleTaskColumns.push(scheduleTaskColumns.slice(0, 10));
      this.scheduleTaskColumns.push(scheduleTaskColumns.slice(10, 20));
      this.scheduleTaskColumns.push(scheduleTaskColumns.slice(20, 31));
    }
    let rowIndex = 0;
    for (let row of this.scheduleTaskColumns) {
      if (!taskRow[rowIndex]) taskRow[rowIndex] = 0;
      if (row && row.length) {
        for (let rowData of row) {
          if (rowData.task && rowData.task.length > taskRow[rowIndex])
            taskRow[rowIndex] = rowData.task.length;
        }
      }
      rowIndex++;
    }
    let index = 0;
    for (let row of taskRow) {
      this.taskRow[index] = [];
      for (let i = 0; i < row; i++) this.taskRow[index].push(i);
      index++;
    }
  }

  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  getGroups() {
    let user: any = localStorage.getItem('user');
    user = JSON.parse(user);
    const key = {
      UserId: user.UserId,
    };
    this.spinnerText = 'Fetching Group Data';
    this.spinner.show();
    this.cilMasterService.getGroupData(key).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.groupList = data.dropDownList;
        this.groupFiltList =
          this.groupList && this.groupList.length ? this.groupList.slice() : [];
        this.filteredGroupList = [...this.groupList];
        if (this.groupList && this.groupList.length) {
          this.HeaderForm.get('group')?.setValue(this.groupList[0].id);
          this.PMTask.Group = this.headerData.group;
          this.getPlants(this.HeaderForm.value.group);
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(id: any): void {
    this.spinnerText = 'Fetching Plant Data';
    this.spinner.show();
    this.cilMasterService.getPlantsData(id).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.plantList = data.dropDownList;
        this.plantFiltList =
          this.plantList && this.plantList.length ? this.plantList.slice() : [];
        this.filteredPlantList = [...this.plantList];
        if (this.plantList && this.plantList.length) {
          this.HeaderForm.get('plant')?.setValue(this.plantList[0].id);
          this.PMTask.Plant = this.headerData.plant;
          this.PMTask.Group = id;
          // this.getTaskList();
          this.getUserList();
          this.getTaskListForDuplicationCheck();
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getUserList() {
    this.spinnerText = 'Fetching User Data';
    this.spinner.show();
    const data = {
      EGId: this.headerData.group,
      PlantId: this.headerData.plant,
    };
    this.commonService.getPeopleMasterData(data).subscribe(
      (res: any) => {
        if (res.employeeInfoList && res.employeeInfoList.length)
          this.userList = res.employeeInfoList.filter(
            (emp: any) => emp.Dept_Desc == 'Maintenance' && emp.UserId
          );
        else this.userList = [];

        this.orgUserList = this.userList;
        this.spinner.hide();
        this.getAllTechnicians();
        // this.getPMTaskData('');
      },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error('Error', err.message);
      }
    );
  }

  getAllTechnicians() {
    this.spinnerText = 'Fetching Technician Data';
    this.spinner.show();
    const data = {
      Group: this.headerData.group,
      Plant: this.headerData.plant,
      RMPM: 'PM',
    };
    this.rmConfigurationService.getTechnician(data).subscribe(
      (res: any) => {
        this.spinner.hide();

        let techList: any = [];
        if (res.data && res.data.length) {
          for (let tech of res.data) {
            const chk = techList.find((t: any) => t == tech.UserId);
            if (!chk) techList.push(tech.UserId);
          }
          if (this.userList && this.userList.length)
            this.technicianList = [
              ...this.userList.filter((item: any) =>
                techList.includes(item.UserId)
              ),
            ];
        }
        this.filteredTechnicianList = [...this.technicianList];
        this.getTaskList();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  searchValues(filter: any, type: string) {
    filter = filter.target.value;
    if (type == 'group') {
      this.filteredGroupList = this.groupList.filter((group: any) => {
        if (group.name) {
          let lowerCase = group.name.toLocaleLowerCase();
          return group.name.startsWith(filter) || lowerCase.startsWith(filter);
        }
      });
    } else if (type == 'plant') {
      this.filteredPlantList = this.plantList.filter((plant: any) => {
        if (plant.name) {
          let lowerCase = plant.name.toLocaleLowerCase();
          return plant.name.startsWith(filter) || lowerCase.startsWith(filter);
        }
      });
    } else if (type == 'technician') {
      this.filteredTechnicianList = this.technicianList.filter(
        (technician: any) => {
          if (technician.fullName) {
            let lowerCase = technician.fullName.toLocaleLowerCase();
            return (
              technician.fullName.startsWith(filter) ||
              lowerCase.startsWith(filter)
            );
          }
        }
      );
    }
  }

  getTaskList() {
    this.spinnerText = 'Fetching Task Data';
    this.spinner.show();
    let month = this.monthNames.findIndex((mnth) => mnth === this.currentMonth);
    const data = {
      Group: this.headerData.group,
      Plant: this.headerData.plant,
      RMPM: 'PM',
      TechId: this.headerData.technician,
      month: ++month,
    };
    this.prevTaskService.getPMTechnicianTask(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.taskRow = [];
          if (
            res.data &&
            res.data.length &&
            this.technicianList &&
            this.technicianList.length
          ) {
            for (let task of res.data) {
              let tech = this.technicianList.find(
                (tech: any) => task.TaskBy == tech.UserId
              );
              if (tech) task.TechnicianName = tech.UserName;
              else task.TechnicianName = 'ALL';
            }
          }
          this.taskList = res.data;
          if (this.headerData.WeekMonth == 1) this.getWeek();
          else this.getMonth();
          // this.getAllTechnicians();
        } else this.toaster.error('Error', res.message);
      },
      (err: any) => {
        this.spinner.hide();
        this.toaster.error('Error', err.message);
      }
    );
  }
  getTaskListForDuplicationCheck() {
    this.PrevConfigurationService.getPrevTask({ Group: this.headerData.group,Plant: this.headerData.plant}).subscribe(
      (res: any) => {
        this.TaskListForDuplicationCheck = res.taskList;
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  changeCalendar() {
    if (this.headerData.WeekMonth == 1) this.getWeek();
    else this.getMonth();
  }

  reScheduleTask(rowIndex: number, taskData: any) {
    taskData.task[rowIndex].month = this.currentMonth;
    taskData.rowIndex = rowIndex;
    const techName = this.filteredTechnicianList.find(
      (tech: any) => tech.UserId == taskData.task[rowIndex].TaskBy
    );
    taskData.task[rowIndex].TechnicianName = techName ? techName.fullName : '';
    taskData.technicianList = this.filteredTechnicianList;
    let dialogRef = this.dialog.open(ScheduleTaskDialogComponent, {
      width: '700px',
      height: '500px',
      data: taskData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinnerText = 'Rescheduling Task ...';
        this.spinner.show();
        this.prevTaskService.resheduleTask(result).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.getTaskList();
            if (res.status) {
              this.toaster.success(res.message, 'Success');
            } else this.toaster.error(res.message, 'Error');
          },
          (err: any) => {
            this.spinner.hide();
            this.toaster.error(err.ErrorMessage, 'Error');
          }
        );
      }
    });
  }

  getPMTaskData(type: any) {
    this.getTreeMasterData(type);
    this.getTechnician();
    this.getFrequency();
    this.getWOType();
    this.getTaskType();
  }

  getTreeMasterData(type: any) {
    const data = { GroupID: this.PMTask.Group, PlantID: this.PMTask.Plant };
    this.spinner.show();
    this.PrevConfigurationService.GetTreeMasterData(data).subscribe(
      (res: any) => {
        this.LineAllList = [];
        this.LineList = [];
        this.LineFilterList = [];
        this.UnitAllList = [];
        this.UnitList = [];
        this.UnitFilterList = [];
        this.AssemblyAllList = [];
        this.AssemblyList = [];
        this.AssemblyFilterList = [];
        this.SubAssemblyAllList = [];
        this.SubAssemblyList = [];
        this.SubAssemblyFilterList = [];
        this.ComponentAllList = [];
        this.ComponentList = [];
        this.ComponentFilterList = [];
        // this.operator.line = '';
        // this.operator.units = '';
        // this.operator.assembly = '';
        // this.operator.subAssembly = '';
        // this.operator.component = '';

        this.LineAllList = res.Line;
        this.LineList = res.Line;
        this.UnitAllList = res.Unit;
        this.AssemblyAllList = res.Assembly;
        this.SubAssemblyAllList = res.SubAssembly;
        this.ComponentAllList = res.Component;

        this.LineFilterList = this.LineList.slice();

        if (this.LineFilterList && this.LineAllList.length) {
          this.PMTask.line = this.LineAllList[0].PL_Id
            ? this.LineAllList[0].PL_Id
            : '';

          this.setUnitList('notChange');
          this.setAssemblyList('notChange');
          this.setSubAssemblyList('notChange');
          this.setComponentList('notChange');
        }
        if (type === 'change') {
          this.setUnitList('notChange');
        }
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  setUnitList(type: any) {
    this.UnitList = [];
    this.UnitFilterList = [];
    this.AssemblyList = [];
    this.AssemblyFilterList = [];
    this.SubAssemblyList = [];
    this.SubAssemblyFilterList = [];
    this.ComponentList = [];
    this.ComponentFilterList = [];
    let unitList = this.UnitAllList.filter(
      (unit: any) => unit.PL_Id == this.PMTask.line
    );

    if (unitList && unitList.length) {
      this.UnitList = unitList;
      this.UnitFilterList = this.UnitList.slice();
      this.PMTask.units = this.UnitFilterList[0].PU_Id
        ? this.UnitFilterList[0].PU_Id
        : '';

      if (type == 'change') {
        this.PMTask.assembly = '';
        this.PMTask.subAssembly = '';
        this.PMTask.component = '';
        this.setAssemblyList('change');
      } else {
        this.setAssemblyList('notchange');
      }
    }
  }

  setAssemblyList(type: any) {
    this.AssemblyList = [];
    this.AssemblyFilterList = [];
    this.SubAssemblyList = [];
    this.SubAssemblyFilterList = [];
    this.ComponentList = [];
    this.ComponentFilterList = [];
    let assemblyList = this.AssemblyAllList.filter(
      (assembly: any) => assembly.PU_Id == this.PMTask.units
    );

    if (assemblyList && assemblyList.length) {
      this.AssemblyList = assemblyList;
      this.AssemblyFilterList = this.AssemblyList.slice();
      this.PMTask.assembly = this.AssemblyFilterList[0].Assbly_Id
        ? this.AssemblyFilterList[0].Assbly_Id
        : '';
      if (type == 'change') {
        this.PMTask.subAssembly = '';
        this.PMTask.component = '';
        this.setSubAssemblyList('change');
      } else {
        this.setSubAssemblyList('notchange');
      }
    }
  }

  setSubAssemblyList(type: any) {
    this.SubAssemblyList = [];
    this.SubAssemblyFilterList = [];
    this.ComponentList = [];
    this.ComponentFilterList = [];
    let subassemblyList = this.SubAssemblyAllList.filter(
      (subassembly: any) => subassembly.Assbly_Id == this.PMTask.assembly
    );

    if (subassemblyList && subassemblyList.length) {
      this.SubAssemblyList = subassemblyList;
      this.SubAssemblyFilterList = this.SubAssemblyList.slice();
      this.PMTask.subAssembly = this.SubAssemblyFilterList[0].SAssbly_Id
        ? this.SubAssemblyList[0].SAssbly_Id
        : '';

      if (type == 'change') {
        this.PMTask.component = '';
        this.setComponentList('change');
      } else {
        this.setComponentList('notchange');
      }
    }
  }

  setComponentList(type: any) {
    this.ComponentList = [];
    this.ComponentFilterList = [];
    let componentList = this.ComponentAllList.filter(
      (component: any) => component.SAssbly_Id == this.PMTask.subAssembly
    );
    if (type == 'change') {
      this.PMTask.component = '';
    } else {
      if (componentList && componentList.length) {
        this.ComponentList = componentList;
        this.ComponentFilterList = this.ComponentList.slice();
        this.PMTask.component = this.ComponentFilterList[0].ComponentId
          ? this.ComponentList[0].ComponentId
          : '';
      }
    }

    if (componentList && componentList.length) {
      this.ComponentList = componentList;
      this.ComponentFilterList = this.ComponentList.slice();
      if (type == 'change' && componentList.length) {
        this.PMTask.component = this.ComponentList[0].ComponentId;
      }
    }
  }

  setMaintenanceType() {
    const obj = this.TaskTypeList.find(
      (tt: any) => tt.ID == this.PMTask.TaskType
    );
    this.PMTask.MaintenanceType =
      obj && obj.MaintenanceType ? obj.MaintenanceType : '';
  }

  getTechnician() {
    this.spinnerText = 'Fetching Technician Data';
    this.spinner.show();
    const data = {
      Plant: this.PMTask.Plant,
      Group: this.PMTask.Group,
      RMPM: 'PM',
    };
    this.rmConfigurationService.getTechnician(data).subscribe(
      (res: any) => {
        this.TechnicianList = res.data;
        let Technician: any = { Name: [] };
        this.userList = [...this.orgUserList];
        this.filterTechnicianList = [];

        if (this.TechnicianList && this.TechnicianList.length) {
          this.TechnicianList1 = this.TechnicianList;
          this.TechnicianListFilter = this.TechnicianList1.slice();
          this.PMTask.Technician = this.TechnicianListFilter[0].UserId
            ? this.TechnicianListFilter[0].UserId
            : '';
        }

        const uniqueTechList = [];
        const uniqueTechID = [];

        const unique = this.TechnicianList.filter((element) => {
          const isDuplicate = uniqueTechID.includes(element.UserId);

          if (!isDuplicate) {
            uniqueTechID.push(element.UserId);
            uniqueTechList.push(element);

            return true;
          }

          return false;
        });
        this.userList.sort(function (a, b) {
          return a.fullName.localeCompare(b.fullName);
        });
        for (const tech of uniqueTechList) {
          const user = this.userList.find(
            (user: any) => user.UserId == tech.UserId
          );
          if (user) {
            tech.UserName = user.fullName;
            Technician.Name.push(tech.UserId);
            this.filterTechnicianList.push({
              UserId: tech.UserId,
              UserName: tech.UserName,
            });
          }
        }
        this.TechnicianFiltList =
          this.filterTechnicianList && this.filterTechnicianList.length
            ? this.filterTechnicianList.slice()
            : [];
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getWOType() {
    this.spinnerText = 'Fetching WOType Data';
    this.spinner.show();
    const data = {
      Plant: this.PMTask.Plant,
      Group: this.PMTask.Group,
      RMPM: 'PM',
    };
    this.rmConfigurationService.getWOType(data).subscribe(
      (res: any) => {
        this.WOTypeList = res.woTypeList;
        if (this.WOTypeList && this.WOTypeList.length) {
          this.WOTypeList1 = this.WOTypeList;
          this.WOTypeFilterList = this.WOTypeList1.slice();
          this.PMTask.WOType = this.WOTypeFilterList[0].ID
            ? this.WOTypeFilterList[0].ID
            : '';
        }
        this.WOTypeFiltList =
          this.WOTypeList && this.WOTypeList.length
            ? this.WOTypeList.slice()
            : [];
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  getFrequency() {
    this.spinnerText = 'Fetching Frequency Data';
    this.spinner.show();

    const data = {
      Plant: this.PMTask.Plant,
      Group: this.PMTask.Group,
    };
    this.PrevConfigurationService.getPrevFrequency(data).subscribe(
      (res: any) => {
        this.frequencyList = res.frequencyList;
        if (this.frequencyList && this.frequencyList.length) {
          this.FrequencyList = this.frequencyList;
          this.FrequencyFiterList = this.FrequencyList.slice();
          this.PMTask.Frequency = this.FrequencyFiterList[0].ID
            ? this.FrequencyFiterList[0].ID
            : '';
          this.setFrequencyDuration();
        }
        this.FrequencyFiltList =
          this.frequencyList && this.frequencyList.length
            ? this.frequencyList.slice()
            : [];
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  getTaskType() {
    this.spinnerText = 'Fetching TaskType Data';
    this.spinner.show();
    const data = {
      Plant: this.PMTask.Plant,
      Group: this.PMTask.Group,
    };
    this.PrevConfigurationService.getTaskType(data).subscribe(
      (res: any) => {
        this.TaskTypeList = res.taskTypeList;
        if (this.TaskTypeList && this.TaskTypeList.length) {
          this.TaskTypeList1 = this.TaskTypeList;
          this.TaskTypeFilterList = this.TaskTypeList1.slice();
          this.PMTask.TaskType = this.TaskTypeFilterList[0].ID
            ? this.TaskTypeFilterList[0].ID
            : '';
        }
        this.TaskTypeFiltList =
          this.TaskTypeList && this.TaskTypeList.length
            ? this.TaskTypeList.slice()
            : [];
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  setFrequencyDuration() {
    const obj = this.frequencyList.find(
      (freq: any) => freq.ID == this.PMTask.Frequency
    );
    this.FreqDuration = obj.Duration;
  }

  durationChange(val) {
    if (val <= 24) return;
    else {
      this.PMTask.Duration = null;
      this.toaster.warning('Duration must be lessthan 1 Day');
    }
  }

  addPMTask() {
    this.getPMTaskData('');
    this.minDate = new Date();
    this.FileData = [];
    setTimeout(() => {
      let dialogRef = this.dialog.open(this.pmTaskForm, {
        disableClose: true,
        minHeight: '60%',
        width: '90%',
      });
    }, 3000);
  }

  openFile() {
    this.fileInput.nativeElement.click();
  }

  readFile(event: any) {
    const files = event.target.files;
    this.FileList.push(event.target.files[0]);
    if (files) {
      for (let file of files) {
        if (file.type.match('image.*')) {
          this.fileType = 'image';
        } else if (file.type.match('video.*')) {
          this.fileType = 'video';
        } else if (file.type.match('application.*')) {
          this.fileType = 'application';
        }
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageSrc = e.target.result;
          this.FileData.push({
            FileType: this.fileType,
            ImageSrc: this.imageSrc,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }

  removeFile(i: any) {
    this.FileData.splice(i, 1);
    this.FileList.splice(i, 1);
  }

  TaskOnSubmit() {
    if (this.PMTaskForm.invalid) {
      this.toaster.warning('Warning', 'Please Fill All Required Fields');
      return;
    } else {
      const chkTask = this.TaskListForDuplicationCheck.find((task: any) => {
        return  task.line.plId == this.PMTask.line && task.units.puId == this.PMTask.units
                && task.assembly.assblyId == this.PMTask.assembly && task.subAssembly.sAssblyId == this.PMTask.subAssembly
                && task.component.componentId == this.PMTask.component
                && task.Description.replace(/\s/g, '').toLowerCase() == this.PMTask.Description.replace(/\s/g, '').toLowerCase();
      });
      if (chkTask) {
        this.toaster.warning('Warning', 'This Task Already Exist');
        return;
      }
      this.PMTask.CreatedAt = new Date();
      this.PMTask.CreatedBy = this.user.UserId;
      let NextDate = new Date();
      NextDate.setDate(NextDate.getDate() + this.FreqDuration);
      this.PMTask.NextScheduleAt = NextDate;
      this.PMTask.FromTaskDate = moment(
        new Date(this.PMTask.FromTaskDate)
      ).format('MM/DD/YYYY hh:mm:ss');
      this.PMTask.ToTaskDate = moment(
        new Date(this.PMTask.ToTaskDate)
      ).format('MM/DD/YYYY hh:mm:ss');
      if (!this.PMTask.assembly) this.PMTask.assembly = null;
      if (!this.PMTask.subAssembly) this.PMTask.subAssembly = null;
      if (!this.PMTask.component) this.PMTask.component = null;
      this.spinner.show();
      const Formvalues = new FormData();
      for (const file of this.FileList)
        Formvalues.append('File', file, file.name);
      Formvalues.append('Data', JSON.stringify(this.PMTask));

      this.PrevConfigurationService.addPrevTask(Formvalues).subscribe(
        (res: any) => {
          if (res.status) {
            this.spinner.hide();
            this.getTaskList();
            this.getTaskListForDuplicationCheck();
            this.PMTaskForm.reset();
            this.toaster.success(
              res.message || `Data Inserted Successfully`,
              'Success',
              {
                timeOut: 3000,
              }
            );
          }
        },
        (err: any) => {
          this.spinner.hide();
          this.toaster.error(err.message || `Server Error`, 'Error', {
            timeOut: 3000,
          });
        }
      );
      this.dialog.closeAll();
    }
  }

  addUserWiseNotification(ids: any, techTask: any, type: any) {
    let data: any;
    if (type == 'Reschedule') {
      data = {
        userId: techTask.Task.CreatedBy, // multiple user ids send [104,105,7,9]
        desc: `Technician Task ID ${ids} Are Rescheduled`,
        title: 'Task Rescheduled',
        createdBy: JSON.parse(localStorage.user).UserId,
      };
    } else {
      const userIds = [techTask[0].Task.CreatedBy, techTask[1].Task.CreatedBy];
      data = {
        userId: userIds, // multiple user ids send [104,105,7,9]
        desc: `Technician Task ID ${ids[0]} And ${ids[1]} Are Swapped`,
        title: 'Task Swapped',
        createdBy: JSON.parse(localStorage.user).UserId,
      };
    }
    this.spinnerText = 'Adding User Wise Notification';
    this.spinner.show();
    this.PrevConfigurationService.addUserWiseNotification(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.toaster.success('Success', res.message);
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
}
