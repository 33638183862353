import { Component, OnInit, Inject, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { asCleanDays } from '@fullcalendar/core';
import { SafetyService } from 'src/app/services/safety.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-safety-dialog',
  templateUrl: './safety-dialog.component.html',
  styleUrls: ['./safety-dialog.component.scss']
})
export class SafetyDialogComponent implements OnInit {
  isEdit: boolean = false;
  ImageUrl: any;
  user: any;
  breadcrumList: any;
  spinnerText: string = "";
  addBOSReportColumns: string[] = ['Description', 'Status', 'Remarks'];
  addBOSReportDataSource = new MatTableDataSource();
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  IncidentTypeList: any = [];

  IncidentClassificationList: any = [];


  IncidentData = {
    IncidentType: '',
    IncidentOn: new Date(),
    IncidentClassification: '',
    IncidentPerson: '',
    Description: '',
  };

  IncidentForm: FormGroup;
  BOSForm: FormGroup;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('scrollMe') private myScrollContainer!: ElementRef;
  StatusList: any = [
    { value: 'Unsafe', viewValue: 'Unsafe ' },
    { value: 'Safe', viewValue: 'Safe ' }
  ];
  imageFile: any;
  imageFileName: any;
  imageSrc: any;
  BOSDescriptionList: any = [];
  BosFormListData: any = [];
  isImage: boolean = false;
  constructor(public dialogRef: MatDialogRef<SafetyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public toaster: ToastrService, private fb: FormBuilder, public datePipe: DatePipe, public safetyService: SafetyService, private spinner: NgxSpinnerService,) {
    this.IncidentForm = this.fb.group({
      IncidentType: ['', Validators.required,],
      IncidentOn: ['', Validators.required],
      IncidentClassification: ['', Validators.required],
      IncidentPerson: ['', Validators.required],
      Description: ['', Validators.required],
    });

    this.BOSForm = this.fb.group({
      statusArray: this.fb.array([]),
      remarkArray: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.getData();
  }

  get remarkArray() {
    return this.BOSForm.get("remarkArray") as FormArray;
  }

  get statusArray() {
    return this.BOSForm.get("statusArray") as FormArray;
  }

  files: File[] = [];

  onSelect(event: any) {
    console.log(event);
    this.files[0] = event.addedFiles[0];

    if (this.files[0].type.match('image.*'))
      this.isImage = true;
    else 
      this.isImage = false;
  }

  onRemove(event: any) {
    console.log(event);
    this.files = [];
  }

  setValidator(element: any, i: any) {
    if (element.Status == 'Safe') {
      this.remarkArray.controls[i].setValidators([Validators.nullValidator]);
      this.remarkArray.controls[i].updateValueAndValidity()
    }
    else {
      this.remarkArray.controls[i].setValidators([Validators.required]);
      this.remarkArray.controls[i].updateValueAndValidity()
    }
  }

  getData() {
    if (this.data.formName == 'incident') {
      this.getIncidentType();
      this.getIncidentClassification();
    }
    else {
      this.getBOSDescriptionType();
    }
  }

  getIncidentType() {
    const data = {
      UserId: 0
    };

    this.spinner.show();
    this.spinnerText = "Fetching IncidentType Data";
    this.safetyService.getIncidentType(data).subscribe((res: any) => {
      this.IncidentTypeList = res.JobbagStatusList;
      this.spinner.hide();
    },
      (error: any) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });
  }

  getIncidentClassification() {
    const data = {
      PUId: this.data.headerData.Unit,
      UserId: 0
    };
    this.spinner.show();
    this.spinnerText = "Fetching IncidentClassification Data";
    this.safetyService.getIncidentClassification(data).subscribe((res: any) => {
      this.IncidentClassificationList = res.JobbagStatusList;
      this.spinner.hide();
    },
      (error: any) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });
  }

  getBOSDescriptionType() {
    const data = {
      PUId: this.data.headerData.Unit,
      UserId: 0
    };
    this.spinner.show();
    this.spinnerText = "Fetching Technician Data";
    this.safetyService.getBOSDescriptionType(data).subscribe((res: any) => {
      this.BOSDescriptionList = res.JobbagStatusList;
      for (const bosDesc of this.BOSDescriptionList) {
        let bosObj = { id: bosDesc.JobBagStatusId, Description: bosDesc.JobBagStatusName, Status: 'Safe', Remarks: '' }
        this.BosFormListData.push(bosObj);
      };
      this.BosFormListData.map((field: any) => {
        this.statusArray.push(this.fb.control(''));
        this.remarkArray.push(this.fb.control(''));
      });
      this.addBOSReportDataSource = this.BosFormListData;
      this.spinner.hide();
    },
      (error: any) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });

  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveIncident() {
    if (!this.IncidentForm.valid) {
      this.toaster.warning('Warning', 'Please Fill All Required Fields');
    }
    else {
      let date = this.datePipe.transform(this.data.headerData.Date, 'yyyy/MM/dd');
      let incidentOn = this.datePipe.transform(this.IncidentData.IncidentOn, 'yyyy/MM/dd HH:mm');

      const data: any = new FormData();
      data.append('TransactionTypeId', 1);
      data.append('PUId', this.data.headerData.Unit);
      data.append('Date', date);
      data.append('IncidentTypeId', this.IncidentData.IncidentType);
      data.append('IncidentOn', incidentOn);
      data.append('IncidentClassificationId', this.IncidentData.IncidentClassification);
      data.append('InjuredPerson', this.IncidentData.IncidentPerson);
      data.append('DetailDesc', this.IncidentData.Description);
      data.append('UserId', this.user.UserId);

      if (this.files && this.files.length)
        data.append(this.files[0].name, this.files[0]);

      this.spinner.show();
      this.spinnerText = "Creating Incident Report";
      this.safetyService.createIncidentReport(data).subscribe((res: any) => {
        this.spinner.hide();
        if (!res.HasError) {
          this.showPopupMsg(res.ErrorMessage);
          let event = {
            PUId: this.data.headerData.Unit,
            Date: date,
            UserId: this.user.UserId,
          }
          this.dialogRef.close(event);
        }
      },
        (error: any) => {
          this.toaster.error(error.error.message);
          this.spinner.hide();
        });
    }
  }

  // dateAsYYYYMMDDHHNNSS(date: any): string {
  //   return date.getFullYear()
  //     + '-' + this.leftpad(date.getMonth() + 1, 2)
  //     + '-' + this.leftpad(date.getDate(), 2);
  // }

  // leftpad(val: any, resultLength = 2, leftpadChar = '0'): string {
  //   return (String(leftpadChar).repeat(resultLength)
  //     + String(val)).slice(String(val).length);
  // }

  onSaveBOS() {
    if (!this.remarkArray.valid) {
      this.toaster.warning('Warning', 'Please Fill All Required Fields');
    }
    else {
      let date = this.datePipe.transform(this.data.headerData.Date, 'yyyy-MM-dd');
      const data: any = {
        BOSList: [],
        UserId: 0,
        PUId: this.data.headerData.Unit,
        Date: date
      };

      let i = 0;
      for (const boslist of this.BosFormListData) {
        let obj = { BOSReportingTypesID: boslist.id, BOSValue: boslist.Status, Comment: boslist.Remarks };
        data.BOSList.push(obj)
        i++;
      }

        this.spinner.show();
        this.spinnerText = "Creating BOS Report";
        this.safetyService.createBOSReport(data).subscribe((res: any) => {
          this.spinner.hide();
          if (!res.HasError) {
            this.showPopupMsg(res.ErrorMessage);
            let event = {
              PUId: this.data.headerData.Unit,
              Date: date,
              UserId: this.user.UserId,
            }
            this.dialogRef.close(event);
          }
        },
          (error: any) => {
            this.toaster.error(error.error.message);
            this.spinner.hide();
          });
    }

  }

  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
      this.toaster.success('Success', msg.substring(3, msg.length));
    }
    else {
      this.toaster.error('Error', msg.substring(3, msg.length));
    }
  }

}
