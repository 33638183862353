import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption, ThemePalette } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { GapanalysisService } from '../../services/gapanalysis.service';
import { Chart } from 'angular-highcharts';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import * as moment from 'moment';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { BtnDeleteCellRenderer } from 'src/app/btn-deletecell-renderer';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MatDialog } from '@angular/material/dialog';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { BtnCellRendererQxMatrix } from 'src/app/btn-cell-rendererQxMatrix';
import { MatSelect } from '@angular/material/select';
import { BtnCellRendererGapAnalysis } from 'src/app/btn-cell-rendererGapAnalysis';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { StockChart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { TranslaterService } from 'src/app/services/translater.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-GapAnalysis',
  templateUrl: './GapAnalysis.component.html',
  styleUrls: ['./GapAnalysis.component.scss'],
})
export class GapAnalysisComponent implements OnInit {
  highChartsColor = [
    '#00008B',
    '#34A56F',
    '#F80000',
    '#2D0A77',
    '#8C001A',
    '#FF69B4',
    '#FA4A08',
    '#5453A6',
    '#8B008B',
    '#3090C7',
    '#F1C40F',
    '#CB4335',
    '#36454F',
    '#A569BD',
    '#FFA500',
    '#179fcc',
    '#093323',
    '#08fa03',
    '#048b8e',
    '#9637A4',
    '#F39C12',
    '#0b774e',
    '#F5B7B1',
    '#0d98da',
    '#512E5F',
    '#C471A5',
    '#0034bb',
    '#06573d',
    '#0d6e54',
    '#0692aa',
    '#611745',
    '#F80000',
    '#FFDF00',
    '#6D81A8',
    '#08FAC7',
    '#D208FA',
    '#FAEB08',
  ];
  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Analysis',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  spinnerButtonOptions1: MatProgressButtonOptions = {
    active: false,
    text: 'Comparison',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  @ViewChild('displayPotentialModal')
  private displayPotentialModal!: TemplateRef<any>;
  @ViewChild('displayPotentialProjectModal')
  private displayPotentialProjectModal!: TemplateRef<any>;
  @ViewChild('select') select: MatSelect;
  @ViewChild('visibleYearlyTarget')
  private visibleYearlyTargetTbl!: TemplateRef<any>;
  @ViewChild('visibleKPIComparison')
  private visibleKPIComparison!: TemplateRef<any>;
  format = 'YYYY-MM-DD';
  chartArray: any[] = [];
  analysisLabel: any[] = [];
  stock!: Chart;
  chart!: any;
  allSelected = false;
  gapSearchForm!: FormGroup;
  GapAnalysisForm!: FormGroup;
  potentialForm!: FormGroup;
  range = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });
  groupListDDL!: any[];
  filteredGroupListDDL!: any[];
  frequencies!: any[];
  filteredFrequencies!: any[];
  groups!: any;
  plants!: any;
  filteredPlants!: any;
  actualMode!: boolean;
  columnDefs: any;
  rowData: any;
  canvas: any;
  ctx: any;
  plantId!: number;
  kpisList!: any[];
  kpiListTemp: any = [];
  dataArray = [];
  gridApi!: any;
  frequencyId!: number;
  gridColumnApi: any;
  IsAccess: any;
  IsWrite: any;
  buttonType!: any;
  isSelected = false;
  isClosing = false;
  POCompleted!: Chart;
  options: Options;
  activeButton!: any;
  labelArr!: any[];
  finincalGapData!: any[];
  potentialSavingData!: any[];
  actualData!: any[];
  FinancialGapKPI!: Chart;
  totalIdentified!: Chart;
  finincalGapDataKPI!: any[];
  potentialSavingDataKPI!: any[];
  labelKPIArr!: any[];
  actualDataKPI!: any[];
  filteredModules!: any[];
  sumTotalLoss: number;
  sumTotalSaving: number;
  sumTotalActual: number;
  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      btnCellRendererQxMatrix: BtnDeleteCellRenderer,
      buttonCellRenderer: BtnCellRendererGapAnalysis,
    },
  };

  gridOptionsParent: any = {
    defaultColDef: {
      resizable: true,
      filter: true,
      floatingFilter: true,
      sortable: true,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      btnCellRendererQxMatrix: BtnDeleteCellRenderer,
      buttonCellRenderer: BtnCellRendererQxMatrix,
    },
  };
  addGapAnalysisForm!: FormGroup;
  breadcrumList: any;
  gapChart: any;
  selectAlldataArray: any;
  singleKpi: any;
  singleKpiArray: any[];
  UserDDL: any;
  potentialColumnDefs: any;
  potentialProjectColumnDefs: any;
  potentialArray: any[] = [];
  potentialProjectArray: any[] = [];
  potentialgridApi: any;
  potentialgridColumnApi: any;
  potentialformMode = true;
  kpiid: any;
  psId: any;

  menuBlueIconUrl = 'assets/images/menu-bottom-blue-icon.png';
  kpisList2: any;
  kpiListTemp2: any[];
  moduleId: any;
  pageId: any;
  langObj: any;
  languageDir = 'ltr'

  constructor(
    private gapService: GapanalysisService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private kpimaster: KpimasterService,
    private userManagementService: UserManagementService,
    private platModelService: EnterPriseGroupService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private dialog: MatDialog,
    private kpiservice: KpitreeService,
    private translater: TranslaterService,
  ) {
    // tslint:disable-next-line:max-line-length
    this.potentialColumnDefs = [
      {
        field: 'Actions',
        headerName: 'Actions',
        filter: true,
        resizable: true,
        sortable: true,
        cellRenderer: 'buttonCellRenderer',
        cellRendererParams: { clicked: (field: any) => { },
        headerValueGetter: this.localizeHeader.bind(this) },
      },
      {
        field: 'projectName',
        headerName: 'Project Name',
        filter: true,
        resizable: true,
        sortable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'date',
        headerName: 'Project Duration',
        filter: true,
        resizable: true,
        sortable: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('MM/YYYY')
            : '';
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'responsibleName',
        headerName: 'Responsible',
        filter: true,
        resizable: true,
        sortable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'projectSaving',
        headerName: 'Project Potential Saving',
        filter: true,
        resizable: true,
        sortable: true,
        type: 'rightAligned',
        valueFormatter: (params: any) => {
          const num = params.value;
          return num.toLocaleString(undefined, {});
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'projectCost',
        headerName: 'Project Cost',
        filter: true,
        resizable: true,
        sortable: true,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'actualSaving',
        headerName: 'Actual Saving',
        filter: true,
        resizable: true,
        sortable: true,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'closingDate',
        headerName: 'Closing Date',
        filter: true,
        resizable: true,
        sortable: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('MM/YYYY')
            : '';
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
    ];

    this.potentialProjectColumnDefs = [
      {
        field: 'projectName',
        headerName: 'Project Name',
        filter: true,
        resizable: true,
        sortable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'date',
        headerName: 'Project Duration',
        filter: true,
        resizable: true,
        sortable: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('MM/YYYY')
            : '';
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'responsibleName',
        headerName: 'Responsible',
        filter: true,
        resizable: true,
        sortable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'projectSaving',
        headerName: 'Project Potential Saving',
        filter: true,
        resizable: true,
        sortable: true,
        type: 'rightAligned',
        valueFormatter: (params: any) => {
          const num = params.value;
          return num.toLocaleString(undefined, {});
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'projectCost',
        headerName: 'Project Cost',
        filter: true,
        resizable: true,
        sortable: true,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'actualSaving',
        headerName: 'Actual Saving',
        filter: true,
        resizable: true,
        sortable: true,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'closingDate',
        headerName: 'Closing Date',
        filter: true,
        resizable: true,
        sortable: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('MM/YYYY')
            : '';
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
    ];
    setTimeout(() => {
      this.potentialgridApi?.sizeColumnsToFit();
    }, 500);
  }
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  gapAnalysisModelVisible = false;
  GapAnalysisFormMode!: boolean;
  public enableMeridian = false;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  ngOnInit(): void {
    this.GetBreadcrumList();
    this.getGroupData();

    this.gapSearchForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.GapAnalysisForm = this.fb.group({
      frequency: [''],
      datePicker: [''],
      // dateStart: new Date(),
      // dateEnd: new Date(),
      kpi: [''],
    });
    this.addGapAnalysisForm = this.fb.group({
      frequency: [''],
      // datePicker: [''],
      // startDate: new Date(),
      // endDate: new Date(),
      kpi: [''],
    });
    this.potentialForm = this.fb.group({
      projectName: ['', Validators.required],
      projectDuration: [''],
      responsible: ['', Validators.required],
      projectSaving: ['', Validators.required],
      projectCost: ['', Validators.required],
      actualSaving: [''],
      closingDate: [new Date()]
    });
    var date = new Date();
    var firstDayFinancialGap = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
    // this.GapAnalysisForm.get('datePicker')?.setValue([new Date(), new Date()]);
    this.range.get('fromDate').setValue(firstDayFinancialGap);
    this.range.get('toDate').setValue(new Date());
    this.GetMenuAccess();
    this.GapAnalysisForm.get('kpi').setValue('0');
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        // localStorage.setItem('GetMenuAccess', this.permisson);
        this.columnDefs = [
          {
            headerName: 'Actions',
            field: 'Actions',
            // cellRenderer: 'btnCellRendererQxMatrix',
            hide: !this.IsWrite,
            cellRenderer: (params: any): any => {
              return `
                <button mat-mini-fab="" color="warn" ptooltip="Delete" tooltipposition="top" class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
              `;
            },
            cellRendererParams: {
              clicked(field: any) { },
            },
            resizable: true,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          { field: 'GapAnalysisId', hide: true,  headerValueGetter: this.localizeHeader.bind(this) },
          { field: 'KPIId', hide: true,  headerValueGetter: this.localizeHeader.bind(this) },
          {
            field: 'KPIName',
            headerName: 'KPI',
            filter: 'agTextColumnFilter',
            flotingFilter: true,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          { field: 'FrequencyId', hide: true,  headerValueGetter: this.localizeHeader.bind(this) },
          { field: 'FrequencyName', hide: true,  headerValueGetter: this.localizeHeader.bind(this) },
          {
            field: 'Target',
            headerName: 'Target',
            type: 'rightAligned',
            tooltipValueGetter: (params: any) => {
              return 'Target of KPI';
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'Actual',
            headerName: 'Actual',
            type: 'rightAligned',
            editable: (params: any) => params.data.Actual === 0,
            tooltipValueGetter: (params: any) => {
              if (params.data.Actual === 0) {
                return 'Enter Actual Entry for KPI';
              } else {
                return 'Actual Entry for KPI';
              }
            },
            cellStyle: (params: any) => {
              // tslint:disable-next-line:max-line-length
              if (params.data.Actual === 0) {
                return { background: '#959595', color: '#ffff' };
              } else {
                return { background: '', color: '#1d252c' };
              }
            },
            valueFormatter: (params: any) => {
              if (!params.value) {
                return 0;
              } else {
                // return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                const num = params.value;
                return num.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                });
              }
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'Gap',
            headerName: 'Gap',
            type: 'rightAligned',
            tooltipValueGetter: (params: any) => {
              return 'Target - Actual';
            },
            valueFormatter: (params: any) => {
              if (!params.value) {
                return 0;
              } else {
                // return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                const num = params.value;
                return num.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                });
              }
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'UnitCost',
            headerName: 'Unit Cost',
            type: 'rightAligned',
            tooltipValueGetter: (params: any) => {
              return 'Sum of Actual Cost';
            },
            valueFormatter: (params: any) => {
              if (!params.value) {
                return 0;
              } else {
                // return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                const num = params.value;
                return num.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                });
              }
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'KPICost',
            headerName: 'KPI Cost',
            hide: true,
            type: 'rightAligned',
            valueFormatter: (params: any) => {
              if (!params.value) {
                return 0;
              } else {
                const num = params.value;
                return num.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                });
              }
            },
          },
          {
            field: 'KPICostValue',
            headerName: 'KPI Value',
            hide: true,
            type: 'rightAligned',
          },
          {
            field: 'FinancialGap',
            headerName: 'Financial Gap',
            type: 'rightAligned',
            tooltipValueGetter: (params: any) => {
              return 'Gap * Unit Cost';
            },
            valueFormatter: (params: any) => {
              if (!params.value) {
                return 0;
              } else {
                // return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                const num = params.value;
                return num.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                });
              }
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'PotentialSaving',
            headerName: 'Potential Saving',
            type: 'rightAligned',
            tooltipValueGetter: (params: any) => {
              return 'Define Project Details';
            },
            cellStyle: (params: any) => {
              return { background: '#959595', color: '#ffff' };
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
        ];
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        }, 500);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.userManagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.filteredGroupListDDL = this.groupListDDL.slice();
        this.gapSearchForm.get('group')?.setValue(this.groupListDDL[0]?.TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlantByGroup(): void {
    const key = {
      GroupId: this.gapSearchForm.get('group')?.value,
    };
    // this.MachineId = null;
    this.userManagementService.getDepartments(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList.filter((p: any) => p.DeptDesc);
        this.filteredPlants = this.plants.slice();
        this.gapSearchForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getFrequencies();
        this.getResponsibleList();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onCellValueChanged(params: any): void {
    const key = {
      actual: params.newValue,
      gapAnalysisId: params.data.GapAnalysisId,
    };
    if (params.data.Target === 0) {
      this.toaster.error('error', 'Actual cannot be entered without target !');
      // params.node.setDataValue('Actual', params.oldValue);
      this.GetAllGapAnalysis();
      return;
    } else {
      this.gapService.onCellValueChanged(key).subscribe((res: any) => {
        this.toaster.success('success', 'Actual is updated successfully !');
        this.GetAllGapAnalysis();
      });
    }
  }

  cellClicked(params: any): void {
    const col = params.colDef.field;
    this.buttonType = '';
    if (col === 'Actions') {
      this.buttonType = params.event.target.classList[1];
      if (this.buttonType === 'fa-trash') {
        this.DeleteGapAnalysis(params.data.GapAnalysisId);
      } else {
        return;
      }
    }
    if (col === 'PotentialSaving') {
      this.openPotentialModal(params);
    }
  }

  potentialCellClicked(params: any): void {
    this.psId = params.data.psId;
    const col = params.colDef.field;
    this.buttonType = '';
    if (col === 'Actions') {
      this.buttonType = params.event.target.textContent;
      if (this.buttonType === 'delete') {
        this.deletePotential();
      }
      if (this.buttonType === 'edit') {
        this.isClosing = false;
        this.editPotential(params);
      }
      if (this.buttonType === 'done') {
        this.isClosing = true;
        this.editPotential(params);
      }
    }
  }
  openPotentialModal(params: any): void {
    this.kpiid = params.data.KPIId;
    this.potentialForm.reset();
    this.potentialformMode = true;
    const dialogRef = this.dialog.open(this.displayPotentialModal, {
      height: 'auto',
      width: '90%',
    });
    this.getPotentialData();

    dialogRef.afterClosed().subscribe(result => {
      this.isClosing = false;
    });
  }


  openPotentialProjectModal(params: any): void {
    this.dialog.open(this.displayPotentialProjectModal, {
      height: 'auto',
      width: '90%',
    });
    this.getAllPotentialData();
  }
  getAllPotentialData() {
    const kpiIds = [];
    this.selectAlldataArray.forEach(element => {
      kpiIds.push(element.KPIId).toString();
    })
    const key = {
      kpiIds: kpiIds.toString()
    }
    this.gapService.GetAllPotentialSavingData(key).subscribe(
      (data: any) => {
        this.potentialProjectArray = data;
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  editPotential(params: any): void {
    const element = params.data;
    this.potentialForm.get('projectName')?.setValue(element.projectName);
    this.potentialForm.get('projectDuration')?.setValue(element.date);
    this.potentialForm.get('responsible')?.setValue(element.empInfo_Id);
    this.potentialForm.get('projectSaving')?.setValue(element.projectSaving.replaceAll(',', ''));
    this.potentialForm.get('projectCost')?.setValue(element.projectCost);
    this.potentialForm.get('actualSaving')?.setValue(element.actualSaving);
    this.potentialForm.get('closingDate')?.setValue(element.closingDate !== null ? element.closingDate : new Date());
    this.potentialformMode = false;
  }

  updatePotential(): void {
    const key = {
      pSId: this.psId,
      projectName: this.potentialForm.value.projectName,
      date: this.potentialForm.value.projectDuration,
      responsible: this.potentialForm.value.responsible,
      projectSaving: this.potentialForm.value.projectSaving,
      projectCost: this.potentialForm.value.projectCost === null ? 0 : this.potentialForm.value.projectCost,
      actualSaving: this.potentialForm.value.actualSaving === null ? 0 : this.potentialForm.value.actualSaving,
      closingDate: this.potentialForm.value.closingDate
    };
    this.gapService.updatePotential(key).subscribe(
      (data: any) => {
        this.toaster.success('success', data.message);
        this.potentialForm.reset();
        this.potentialformMode = true;
        this.getPotentialData();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getFrequencies(): void {
    const key = {
      plantId: this.gapSearchForm.value.plant,
    };
    this.kpimaster.getMasterFrequencies(key).subscribe(
      (frequencies) => {
        this.frequencies = frequencies;
        this.filteredFrequencies = this.frequencies.slice();
        this.frequencyId = this.frequencies[0].frequencyId;
        this.GapAnalysisForm.get('frequency')?.setValue(
          this.frequencies[0].frequencyId
        );
        this.addGapAnalysisForm
          .get('frequency')
          ?.setValue(this.frequencies[0].frequencyId);
        this.GetAllGapAnalysis();
        this.onFrequencyChange();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  getResponsibleList(): void {
    const key = {
      EGId: this.gapSearchForm.get('group')?.value,
      PlantId: this.gapSearchForm.get('plant')?.value,
    };

    this.kpiservice.getEmployeeMasterData(key).then(
      (users: any) => {
        this.UserDDL = users.employeeInfoList.filter((x: any) => {
          return x.EmpInfo_Id;
        });
        this.UserDDL.forEach((element: any) => {
          element.fullName = element.FirstName + ' ' + element.lastName;
        });
        this.potentialForm.get('responsible')?.setValue(this.UserDDL);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  savePotential(): void {
    const key = {
      kpiId: this.kpiid,
      projectName: this.potentialForm.value.projectName,
      date: this.potentialForm.value.projectDuration,
      responsible: this.potentialForm.value.responsible,
      projectSaving: this.potentialForm.value.projectSaving,
      projectCost: this.potentialForm.value.projectCost
    };
    this.gapService.addPotential(key).subscribe(
      (data: any) => {
        this.toaster.success('success', data.message);
        this.potentialForm.reset();
        this.getPotentialData();
        this.GetAllGapAnalysis();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  deletePotential(): void {
    const key = {
      pSId: this.psId,
    };
    this.gapService.deletePotential(key).subscribe(
      (data: any) => {
        this.toaster.success('success', data.message);
        this.getPotentialData();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPotentialData(): void {
    const key = {
      kpiId: this.kpiid,
    };
    this.gapService.GetAllPotentialSaving(key).subscribe(
      (data: any) => {
        this.potentialArray = data;
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  selectAllIKpis(): any {
    const kpi = this.GapAnalysisForm.value.kpi;
    this.isSelected = !this.isSelected;
    if (this.GapAnalysisForm.value.kpi === '0') {
      this.dataArray = this.selectAlldataArray;
    } else {
      this.dataArray = this.selectAlldataArray.filter((c) => c.KPIId === kpi);
    }
  }
  onFrequencyChange(): void {
    this.dataArray = [];
    this.frequencyId = parseInt(this.GapAnalysisForm.value.frequency, 10);
    const key = {
      plantId: this.gapSearchForm.value.plant,
      frequencyId: this.frequencyId,
    };
    this.kpimaster.getKpiFrequency(key).subscribe(
      (kpis) => {
        this.kpisList = kpis;
        this.kpiListTemp = this.kpisList.slice();
        this.GapAnalysisForm.get('kpi').setValue('0');
        // this.GapAnalysisForm.get('kpi')?.setValue(this.kpisList[0].kpiId);
        // this.addGapAnalysisForm.get('kpi')?.setValue(this.kpisList[0]?.kpiId);
        this.GetAllGapAnalysis();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  onFrequencyChangeValue(): void {
    const key = {
      plantId: this.gapSearchForm.value.plant,
      frequencyId: parseInt(this.addGapAnalysisForm.value.frequency, 10),
    };
    this.kpimaster.getKpiFrequency(key).subscribe(
      (kpis) => {
        this.kpisList2 = kpis;
        this.kpiListTemp2 = this.kpisList2.slice();
        this.addGapAnalysisForm.get('kpi')?.setValue(this.kpisList2[0]?.kpiId);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  GetAllGapAnalysis(): void {
     
    this.spinner.show();
    const fromDate = new Date(this.range.value.fromDate);
    const toDate = new Date(this.range.value.toDate);
    var firstDay = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
    var lastDay = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0);
    //var fromMonth   = new Date(fromDate.getMonth(),1);
    // var toMonth = new Date(toDate.getMonth()+1,0);
    //var fromYear = new Date(toDate.getFullYear());
    //  var toYear = new Date(toDate.getFullYear())
    var a = new Date(fromDate);
    var b = new Date(toDate);

    var fromYear = a.getFullYear();
    var toYear = b.getFullYear();
    var fromMonth = a.getMonth() + 1;
    var toMonth = b.getMonth() + 1;
    const key = {
      frequencyId: this.frequencyId,
      // fromDate: this.GapAnalysisForm.value.dateStart,
      fromDate:fromDate ,
      toDate:  toDate ,
      fromMonth: fromMonth,
      toMonth: toMonth,
      fromYear: fromYear,
      toYear: toYear,
    };
    this.gapService.GetAllGapAnalysis(key).subscribe((res: any) => {
      this.spinner.hide();
      this.dataArray = [];
      this.selectAlldataArray = res;
      this.filteredModules = this.selectAlldataArray.slice();

      this.selectAllIKpis();
      this.autoSizeAll();
      if (this.gapChart) {
        this.gapChart.destroy();
      }
      debugger;
      this.labelArr = res.map((c: any) => c.KPIName);
      this.finincalGapData = res.map((c: any) =>
        parseFloat(
          c.FinancialGap == null
            ? c.FinancialGap
            : c.FinancialGap.toString().replace(/,/gi, '')
        )
      );
      this.potentialSavingData = res.map((c: any) =>
        parseFloat(
          c.PotentialSaving == null
            ? 0
            : c.PotentialSaving.toString().replace(/,/gi, '')
        )
      );
      this.actualData = res.map((c: any) =>
        parseFloat(c.actualSaving == null ? 0 : c.actualSaving.toString().replace(/,/gi, ''))
      );
      this.drawChartFinancialGapAnalysis();
      //clear By KPI chart data
      this.labelKPIArr = [];
      this.potentialSavingDataKPI = [];
      this.actualDataKPI = [];
      this.finincalGapDataKPI = [];
      this.drawChartFinancialGapAnalysisKPI();
      this.drawChartTotalIdentified();
    });
  }
  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }
  getTransalatedValue(value:string){
    let val;
    if(this.langObj && this.langObj[value]){
      val = this.langObj[value];
    }
    return val?val:value;
  }
  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if(this.langObj){
        this.gridApi.refreshHeader();
      }
    });
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
   // this.gridApi.sizeColumnsToFit();
  }

  onModelUpdated(){
    this.autoSizeAll();
  }

  autoSizeAll() {
    const allColumnIds = [];
    this.gridColumnApi?.getAllColumns().forEach(function(column) {
      if(column.colId === 'KPIName')
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }

  onPotentialGridReady(params: any): any {
    this.potentialgridApi = params.api;
    this.potentialgridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.potentialgridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#potentialGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }

  AddGapAnalysis(): void {
    const GapAnalysis = this.dataArray ? this.dataArray : [];
    const KPI = GapAnalysis.filter(
      (c: any) => c.KPIId === this.addGapAnalysisForm.value.kpi
    );
    if (KPI.length > 0) {
      this.toaster.error('Error', 'KPI  Already Exist');
      return;
    }
    const key = {
      frequency: this.addGapAnalysisForm.value.frequency,
      kpi: this.addGapAnalysisForm.value.kpi,
      fromDate: new Date(this.range.value.fromDate),//this.GapAnalysisForm.value.datePicker[0],
      // toDate: this.addGapAnalysisForm.value.datePicker[1],
    };
    this.gapService.AddGapAnalysis(key).subscribe((res: any) => {
      this.toaster.success('success', 'KPI is added successfully...');
      // this.getFrequencies();
      this.GetAllGapAnalysis();
      this.gapAnalysisModelVisible = false;
    });
  }

  UpdateGapAnalysis(): void {
    this.GapAnalysisFormMode = true;
  }

  DeleteGapAnalysis(gapId: any): void {
    const key = {
      gapAnalysisId: gapId,
    };
    this.gapService.DeleteGapAnalysis(key).subscribe((res: any) => {
      this.toaster.success('success', 'Gap Analysis is deleted successfully');
      this.GetAllGapAnalysis();
    });
  }

  openGapAnalysisModal(): void {
    this.gapAnalysisModelVisible = true;
    this.GapAnalysisFormMode = true;
    this.onFrequencyChangeValue();
  }

  closeGapAnalysisModel(): void {
    this.gapAnalysisModelVisible = false;
  }

  showPhase(event: any) {
    this.activeButton = event;
  }
  sortData(value: number) {
    const res = this.selectAlldataArray.slice(0, value);
    this.labelArr = res.map((c: any) => c.KPIName);
    this.finincalGapData = res.map((c: any) =>
      parseFloat(
        c.FinancialGap == null
          ? c.FinancialGap
          : c.FinancialGap.toString().replace(/,/gi, '')
      )
    );
    this.potentialSavingData = res.map((c: any) =>
      parseFloat(
        c.PotentialSaving == null
          ? 0
          : c.PotentialSaving.toString().replace(/,/gi, '')
      )
    );

    this.actualData = res.map((c: any) =>
      parseFloat(c.actualSaving == null ? 0 : c.actualSaving.toString().replace(/,/gi, ''))
    );
    this.drawChartFinancialGapAnalysis();
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  onChangeKPI(object: any) {
    const res = object;
    this.labelKPIArr = res.map((c: any) => c.KPIName);
    this.finincalGapDataKPI = res.map((c: any) =>
      parseFloat(
        c.FinancialGap == null
          ? c.FinancialGap
          : c.FinancialGap.toString().replace(/,/gi, '')
      )
    );
    this.potentialSavingDataKPI = res.map((c: any) =>
      parseFloat(
        c.PotentialSaving == null
          ? 0
          : c.PotentialSaving.toString().replace(/,/gi, '')
      )
    );

    this.actualDataKPI = res.map((c: any) =>
      parseFloat(c.actualSaving == null ? 0 : c.actualSaving.toString().replace(/,/gi, ''))
    );
    this.drawChartFinancialGapAnalysisKPI();
  }


  drawChartFinancialGapAnalysis() {
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1', '#58e8aa', '#b0b5b2'],
      title: {
        text: '',
      },
      xAxis: {
        categories: this.labelArr,
      },
      yAxis: {
        title: {
          text: 'Financial Gap Analysis',
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.2f}</b>',
      },
      series: [
        {
          name: 'Identified Loss',
          type: 'column',
          data: this.finincalGapData,
        },
        {
          name: 'Identified Saving',
          type: 'column',
          data: this.potentialSavingData,
        },
        {
          name: 'Actual',
          type: 'column',
          data: this.actualData,
        },
      ],
    };
    let chart = new Chart(this.options);
    this.POCompleted = chart;
  }
  drawChartFinancialGapAnalysisKPI() {
    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1', '#58e8aa', '#b0b5b2'],
      title: {
        text: '',
      },
      xAxis: {
        categories: this.labelKPIArr,
      },
      yAxis: {
        title: {
          text: 'Financial Gap Analysis',
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.2f}</b>',
      },
      series: [
        {
          name: 'Identified Loss',
          type: 'column',
          data: this.finincalGapDataKPI,
        },
        {
          name: 'Identified Saving',
          type: 'column',
          data: this.potentialSavingDataKPI,
        },
        {
          name: 'Actual',
          type: 'column',
          data: this.actualDataKPI,
        },
      ],
    };
    let chart = new Chart(this.options);
    this.FinancialGapKPI = chart;
  }
  drawChartTotalIdentified() {
    this.sumTotalLoss = 0;
    this.sumTotalSaving = 0;
    this.sumTotalActual = 0;
    for (let i = 0; i < this.finincalGapData.length; i++) {
      this.sumTotalLoss += this.finincalGapData[i] >= 0 ? this.finincalGapData[i] : 0;
    }
    for (let i = 0; i < this.potentialSavingData.length; i++) {
      this.sumTotalSaving += this.potentialSavingData[i];
    }
    for (let i = 0; i < this.actualData.length; i++) {
      this.sumTotalActual += this.actualData[i];
    }

    // handling NaN 
    if (isNaN(this.sumTotalLoss)) {
      this.sumTotalLoss = 0
    }

    this.options = {
      chart: {
        type: 'column',
      },
      colors: ['#0f0fd1', '#58e8aa', '#b0b5b2'],
      title: {
        text: '',
      },
      xAxis: {
        categories: ['Total Identified Loss', 'Total Identified Saving', 'Total Actual Saving'],
      },
      yAxis: {
        title: {
          text: 'Total Idetified',
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.2f}</b>',
      },
      series: [
        {
          name: 'Total Identified Loss',
          type: 'column',
          data: [this.sumTotalLoss],
        },
        {
          name: 'Total Identified Saving',
          type: 'column',
          data: [this.sumTotalSaving],
        },
        {
          name: 'Total Actual Saving',
          type: 'column',
          data: [this.sumTotalActual],
        },
      ],
    };
    let chart = new Chart(this.options);
    this.totalIdentified = chart;
  }

  onClickAnalysisKPI(): void {
    const frequency = this.frequencies.find(val => val.frequencyId === this.GapAnalysisForm.value.frequency);
    if (frequency.name === 'Weekly') {
      this.dialog.open(this.visibleYearlyTargetTbl, {
        panelClass: 'custom-dialog-container',
        width: '95vw !important',
        height: '100vh',
        minWidth: '95vw',
        maxHeight: '100vh',
      });
      this.chartArray = [];
      const selectedRows = this.gridApi.getSelectedRows();
      const fromDate = new Date(this.range.value.fromDate);// this.GapAnalysisForm.value.datePicker[0];
      const toDate = new Date(this.range.value.toDate); // this.GapAnalysisForm.value.datePicker[1];


      let analysisData = [];
      let sumFin = 0;
      var a = new Date(fromDate);
      var b = new Date(toDate);
      var fromYear = a.getFullYear();
      var toYear = b.getFullYear();
      var fromMonth = a.getMonth() + 1;
      var toMonth = b.getMonth() + 1;
      selectedRows.forEach(async (element: any) => {
        const chartObj = { id: '', chartOption: {} };
        const key = {
          frequencyId: element.FrequencyId,
          FromYear: fromYear,
          toYear: toYear,
          fromMonth: fromMonth,
          toMonth: toMonth,
          KpiId: element.KPIId,
          frequencyName: element.FrequencyName,
          PlantId: this.gapSearchForm.value.plant
        };
        await this.gapService.GetAnalysisData(key).then(
          (data: any) => {
            const title = element.KPIName;
            analysisData = [];
            this.analysisLabel = [];
            sumFin = 0;
            data.forEach(val => {
              analysisData.push(val.absFinGap);
              this.analysisLabel.push('Week-' + val.weeks);
              sumFin += sumFin + val.absFinGap;
            })
            // JSON.stringify(analysisData);


            // create the chart
            this.options = {
              title: {
                text: title,
              },
              credits: {
                enabled: false
              },
              xAxis: {
                categories: this.analysisLabel
              },
              yAxis: {
                // min: 0,
                // max: 100,
                // labels: {
                //   format: '{value}%'
                // }
              },
              tooltip: {
                valueDecimals: 2
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true,
                    formatter: function () {
                      return parseFloat(this.y.toFixed(2)).toLocaleString();
                    }
                  }
                }
              },
              series: [
                {
                  type: 'column',
                  name: 'Financial Gap',
                  data: analysisData,
                  color: 'blue'
                },
                {
                  type: 'spline',
                  name: 'Finnacial Gap',
                  data: analysisData,
                  color: 'red',
                  marker: {
                    lineWidth: 2,
                    lineColor: '',
                    fillColor: 'white'
                  }

                },
                // {
                //   type: 'bar',
                //   name: 'Total % ',
                //   data: [{
                //     name: 'Financial Gap Avg',
                //     y: sumFin / this.analysisLabel.length,
                //     color: '#0f1ddb',
                //   }, {
                //     name: 'Weeks',
                //     y: this.analysisLabel.length,
                //     color: '#fcba03'
                //   }, {
                //     name: 'Sum of Financial Gap',
                //     y: sumFin,
                //     color: '#10ce9c'
                //   }],
                //   //center: [100, 20],
                //  // size: 100,
                //   showInLegend: false,
                //   dataLabels: {
                //     enabled: false
                //   },
                //   tooltip:{
                //     valueDecimals:2
                //   }
                // }
              ]
            };
            let chart = new Chart(this.options);

            chartObj.chartOption = chart;
            this.chartArray.push(chartObj);
          })
      })

      this.spinnerButtonOptions.active = true;
    } else {
      this.toaster.info('warning', 'Please select weekly frequency');
    }

  }

  getMili(Start_Time: any) {
    var date = moment(Start_Time).format(this.format);
    return new Date(date).getTime();
  }

  onClickComparisionKPI(): void {
    this.spinnerButtonOptions1.active = true;
    this.dialog.open(this.visibleKPIComparison, {
      panelClass: 'custom-dialog-container',
      width: '95vw !important',
      height: '100vh',
      minWidth: '95vw',
      maxHeight: '100vh',
    });
    const array: any[] = [];
    const yAxisArr: any[] = [];
    const selectedRows = this.gridApi.getSelectedRows();
    const fromDate = new Date(this.range.value.fromDate); // this.GapAnalysisForm.value.datePicker[0];
    const toDate = new Date(this.range.value.toDate);// this.GapAnalysisForm.value.datePicker[1];
  
   
    const kpiNames = selectedRows.map((value: any) => value.KPIName);
    this.analysisLabel = [];
    var a = new Date(fromDate);
    var b = new Date(toDate);
    var fromYear = a.getFullYear();
    var toYear = b.getFullYear();
    var fromMonth = a.getMonth() + 1;
    var toMonth = b.getMonth() + 1;
    selectedRows.forEach(async (element: any) => {
      const key = {
        frequencyId: element.FrequencyId,
        FromYear: fromYear,
        toYear: toYear,
        fromMonth: fromMonth,
        toMonth: toMonth,
        KpiId: element.KPIId,
        frequencyName: element.FrequencyName,
        PlantId: this.gapSearchForm.value.plant
      };

      await this.gapService.GetAnalysisData(key).then(
        (data: any) => {
          //  making data
          for (let i = 0; i < kpiNames.length; i++) {
            const dataArr = [];
            for (let j = 0; j < data.length; j++) {
              if (kpiNames[i] === data[j].KPIName) {
                dataArr.push({
                  x: this.getMili(data[j].Date),
                  y: isNaN(data[j].absFinGap) ? '' : parseFloat(data[j].absFinGap),
                  label: data[j].weeks,
                });
              }
            }
            let negativeArr = [];
            negativeArr = dataArr.map((c) => c.y);
            const obj = {
              tooltip: {
                valueDecimals: 2,
              },
              name: kpiNames[i],
              type: 'line',
              data: dataArr,
              color: this.highChartsColor[i],
              yAxis: i,
            };
            if(obj.data.length>0){
              array.push(obj);
            }
            yAxisArr.push({
              lineWidth: 1,
              visible:true,
             // opposite:false,
              opposite: negativeArr.some((v) => v < 0) ? true : false,
              title: {
                text: '',//kpiNames[i],
                style: {
                  color: this.highChartsColor[i],
                },
              },
              labels: {
                style: {
                  color: this.highChartsColor[i],
                },
              },
            });
          }

          yAxisArr.forEach((yAxis) => { // hide y axis
            yAxis.visible = false;
          });
          // creating chart
          this.chart = new StockChart({
            chart: {
              height: 730,
            },
            plotOptions: {
              series: {
                turboThreshold: 50000000,
                events: {
                  hide(): any {
                    debugger;
                    //this.yAxis.axisTitle.hide();
                    this.yAxis.update({
                      visible: false,
                    });
                  },
                  show(): any {
                    this.yAxis.update({
                      visible: true,
                    });
                  },
                },
              },
            },
            rangeSelector: {
              selected: 1,
            },
            title: {
              text: 'KPI Comaprison',
            },
            credits: {
              enabled: false,
            },
            legend: {
              "align": "center",
              "verticalAlign": "bottom",
              "backgroundColor": 'white',
              "borderColor": "#CCC",
              "borderWidth": 1,
              "shadow": false,
              enabled: true
            },
            tooltip: {
              formatter(): any {
                // let s = '<b>' + StockChart.dateFormat('%A, %b %e, %Y', this.x) + '</b>';
                let s = '';
                this.points?.forEach((point: any) => {
                  s +=
                    '<br/> <span style="color: ' +
                    point.series.color +
                    '">' +
                    point.series.name +
                    ' : ' +
                    point.y +
                    '<span><br/>' +
                    '<br/><span style="color: ' +
                    point.series.color +
                    '">Weeks : ' +
                    point.point.label +
                    '<span><br/>';
                });
                return s;
              },
            },
            yAxis: yAxisArr,
            series: array
          });
          this.spinnerButtonOptions1.active = false;
        });
      
    });
    
  }
}

