<div class="machine-parameter-content">
  <div class="content-top-box">
<form [formGroup]="addCostForm">
  <div class="content-top-row">
    <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
      <mat-label>{{langObj ? langObj?.CostCenter : ('CostCenter' | translate)}}</mat-label>
      <mat-select formControlName="costCenterparentgroup" (openedChange)="getCenterGroupList($event)">
        <mat-optgroup *ngFor="let parent of parentsDDL" [label]="parent.parentName"
        [disabled]="parent.disabled">
          <mat-option *ngFor="let center of parent.detail" [value]="center.costCenterId">
            {{center.costCenterName}}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <mat-form-field  class="top-row-first-filed-item top-row-first-filed-item-order-processing">
      <mat-label>{{langObj ? langObj?.CostElement : ('CostElement' | translate)}}</mat-label>
      <input matInput placeholder="{{langObj ? langObj?.EnterElementName : ('EnterElementName' | translate)}}" formControlName="name" required>
    </mat-form-field>

              <button
              type="submit"
              *ngIf="!CostformMode && IsWrite"
              [disabled]="!addCostForm.valid"
              mat-mini-fab
              mat-button
              mat-dialog-close
              color="primary"
              pTooltip="Update" tooltipPosition="top"
              (click)="UpdateCostElement()"
                style="margin-left: 15px;" >
                <mat-icon style="color: #fff">edit</mat-icon>
            </button>
            <button
              type="submit"
              *ngIf="CostformMode && IsWrite"
              [disabled]="!addCostForm.valid"
              mat-mini-fab
              mat-button
              mat-dialog-close
              color="primary"
              pTooltip="Save" tooltipPosition="top" 
              (click)="AddCostElement()"
                style="margin-left: 15px;">
                <mat-icon style="color: #fff">save</mat-icon>
            </button>
            <button
            [disabled]="!addCostForm.valid"
            mat-mini-fab
            mat-button
             pTooltip="Reset" tooltipPosition="top"
            (click)="RefreshCostElement()"
              style="margin-left: 15px;">
              <mat-icon style="color: #fff">refresh</mat-icon>
          </button>
 
</div>
</form>
</div>
</div>
<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center">
      <mat-card-title class="p-ml-2">{{langObj ? langObj?.CostElement : ('CostElement' | translate)}}</mat-card-title>
      <div class="" >
        <mat-form-field>
          <input matInput placeholder="{{langObj ? langObj?.Search : ('Search' | translate)}}...." autocomplete="off" (keyup)="applyFilter($event)">
        </mat-form-field>
      </div>
  </div>

  <mat-card-content>
    <!-- <ngx-spinner
        [fullScreen]="false"
        bdColor="#fff"
        size="medium"
        color="#050a4f"
        type="ball-clip-rotate-multiple"
        >
        <p style="font-size: 20px; color: #050a4f">Fetching Departments Data...</p>
    </ngx-spinner> -->
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1" >
      <table mat-table [dataSource]="costElementDataSource" class="basic-table plant-model-table"  matSort>

        <ng-container matColumnDef="costActions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-text">{{langObj ? langObj?.Action : ('Action' | translate)}}</p>

          </th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <button mat-icon-button class="edit-btn"  aria-label="Edit Row" (click)="EditCost(element)"
                     pTooltip="Edit Cost" tooltipPosition="top" *ngIf="IsWrite" >
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button  class="node-control-btn node-delete" aria-label="delete item" (click)="DeletedCostElement(element)"
                    pTooltip="Delete Cost" tooltipPosition="top" *ngIf="IsWrite" [disabled] = 'element.child > 0 ? "true" : "false"' [ngStyle]="{'background-color': (element.child > 0) ? '#D3D3D3' : '#FF0000'}">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                        fill="white" />
                    </svg>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="costCenter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.CostCenter : ('CostCenter' | translate)}}</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.costCenterName}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">{{langObj ? langObj?.CostElementName : ('CostElementName' | translate)}} </p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="costColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: costColumns;"></tr>
        <tr class="mat-row" *matNoDataRow style="text-align: center;">
          <td class="mat-cell" colspan="4">{{langObj ? langObj?.Nodatamatching : ('Nodatamatching' | translate)}}</td>
        </tr>
      </table>
    </div>
    <mat-paginator
    class="basic-paginataor"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons>
  </mat-paginator>
  </mat-card-content>
</mat-card>
