
<div class="production-db-content">
    <div class="content-top-box">
      <ul class="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
            <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
              (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
          </li>
        </ul>
      </ul>
      <div class="title-row-flex-both-end">
        <h1 class="main-title">CIL DashBoard</h1>
      </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <form [formGroup]="CLLDashboardForm">
        <div class="content-top-row content-top-row-order-processing">
            <!-- GROUP -->
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Group</mat-label>
              <mat-select formControlName="UserGroup1" (selectionChange)="getPlants($event)" placeholder="Group"
                panelClass="testClass" name="Status">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
                  (filteredReturn)="filteredGroups = $event"></mat-select-filter>
                <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
                  {{group.EG_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Plant</mat-label>
              <mat-select formControlName="UserPlant1" placeholder="Plant" panelClass="testClass" name="Status" (selectionChange)="onChangePlant($event)">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList1"
                (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
                  {{plant.PT_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
      
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Line</mat-label>
              <mat-select formControlName="line" panelClass="testClass" name="line" (selectionChange)="onChangeLine($event)">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PL_Desc'" [array]="lineList"
                (filteredReturn)="filteredLines = $event"></mat-select-filter>
                <mat-option  value="0">ALL</mat-option>
                <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
                  {{line.PL_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
      
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Unit</mat-label>
              <mat-select formControlName="unit" panelClass="testClass" name="unit" (selectionChange)="onChangeUnit($event)">
                <mat-select-filter [placeholder]="'Search'" [displayMember]="'PU_Desc'" [array]="unitList"
                (filteredReturn)="filteredUnits = $event"></mat-select-filter>
                <mat-option  value="0">ALL</mat-option>
                <mat-option *ngFor="let unit of filteredUnits" [value]="unit.PU_Id">
                  {{unit.PU_Desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
              <mat-label>Component</mat-label>
                <mat-select formControlName="components" panelClass="testClass" name="Status" (selectionChange)="onSubmit()">
                  <mat-select-filter [placeholder]="'Search'" [displayMember]="'ComponentName'" [array]="componentList"
                  (filteredReturn)="filteredComponents = $event"></mat-select-filter>
                  <mat-option  value="0">ALL</mat-option>
                  <mat-option *ngFor="let com of filteredComponents" [value]="com.ComponentId">
                    {{com.ComponentName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          </div>
      <!-- Dropdown -->
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Start Date</mat-label>
          <input matInput [ngxMatDatetimePicker]="startPicker" formControlName="dateStart" (dateChange)="onSubmit()"
            [min]="minDate" [max]="maxDate" [disabled]="disabled">
          <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #startPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
            [enableMeridian]="enableMeridian">
          </ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>End Date</mat-label>
          <input matInput [ngxMatDatetimePicker]="endPicker" formControlName="dateEnd" (dateChange)="onSubmit()"
            [min]="minDate" [max]="maxDate" [disabled]="disabled">
          <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #endPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
            [enableMeridian]="enableMeridian">
          </ngx-mat-datetime-picker>
        </mat-form-field>
        <!-- <div class="btn-position btn-position-order-processing">
          <button mat-flat-button color="accent" class="btn-accent" (click)="onSubmit()">Apply Filter</button>
        </div> -->
        <div class="btn-position">
          <button mat-flat-button color="accent" class="btn-accent" (click)="today()">Today</button>
        </div>
        <div class="btn-position">
          <button mat-flat-button color="accent" class="btn-accent" (click)="yesterday()">Yesterday</button>
        </div>
        <div class="btn-position">
          <button mat-flat-button color="accent" class="btn-accent" (click)="lastWeek()">Last Week</button>
        </div>
        <div class="btn-position">
          <button mat-flat-button color="accent" class="btn-accent" (click)="lastMonth()">Last Month</button>
        </div>
      </div>
      <!-- Card -->
      <div class="menu-card-group">
        <div class="menu-card-item mat-card-height">
          <p class="title-text">Total Tasks Execution</p>
          <p class="data-text">
            {{TotalDefects}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
          </div>
        </div>
        <div class="menu-card-item mat-card-height">
          <p class="title-text">Task Executed</p>
          <p class="data-text">
            {{TotalDefecteSolved}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuGreenIconUrl" alt="{{ menuGreenIconUrl }}" />
          </div>
        </div>
        <div class="menu-card-item mat-card-height">
          <p class="title-text">Not Executed</p>
          <p class="data-text">
            {{TotalDefectsPerc}}
            <span class="data-text small-data-text"></span>
          </p>
          <div class="menu-bottom-icon">
            <img class="img" [src]="menuRedIconUrl" alt="{{ menuRedIconUrl }}" />
          </div>
        </div>
        <div class="menu-card-item mat-card-height">
            <p class="title-text">CIL Adherence</p>
            <p class="data-text">
              {{CILAdherence}}
              <span class="data-text small-data-text"></span>
            </p>
            <div class="menu-bottom-icon">
              <img class="img" [src]="menuRedIconUrl" alt="{{ menuGreenIconUrl }}" />
            </div>
          </div>
      </div>
      <div class="chart-group">
        <div class="chart-items">
          <div class="chart-title">CIL Task</div>
          <div [chart]="chartCILTask"></div>
        </div>
        <div class="chart-items">
          <div class="chart-title">CIL Task</div>
          <div [chart]="chartCILType"></div>
        </div>
      </div>
      <div class="chart-group">
        <div class="chart-items">
          <div class="chart-title">CIL Task</div>
          <div [chart]="chartCILTool"></div>
        </div>
      </div>
    </form>
    <ng-template #imageZoomModel>

      <img [src]="currentImagePath" style="height: 98%; width: 100%; overflow: hidden;">
  
    </ng-template>
    <section>
      <div class="example-button-row">
        <button type="button" class="btn btn-success"  (click)="csvExport()" style="margin-top: 30px;"><img src="assets/images/csv-file-format.png" ></button>
        <button type="button" class="btn btn-success" (click)="excelExport()" style="margin-top: 30px; margin-left: 10px;"><i class="fa fa-file-excel-o icon-white"  aria-hidden="true"></i></button>
      </div>
    </section>
    <mat-card-content>
      <ag-grid-angular style="width: 100%; height: 24vw;" class="ag-theme-alpine" [rowData]="rowData"
      [columnDefs]="columnDefs" (gridReady)="onGridReady($event)"  (cellClicked)="cellClicked($event)"
       [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
      </mat-card-content>
  </div>