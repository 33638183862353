<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner> -->

<div>
<mat-card class="p-mt-0 p-pt-0">
  <div class="p-d-flex p-jc-between p-ai-center">
    <mat-card-title class="p-ml-2">NCR Actions</mat-card-title>
    <div class="">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search" autocomplete="off">
      </mat-form-field>
    </div>
    <div class="">
      <button *ngIf="IsWrite" (click)="openModal()" class="icon-btn" pTooltip="Add NCR Actions" tooltipPosition="top">
        <img src="assets/images/add.png" alt="" width="20px" height="20px">
      </button>
      <!-- <button mat-flat-button class="btn-add-item" *ngIf="IsWrite" (click)="openModal()" pTooltip="Add Pillar" tooltipPosition="top">
          <span class="btn-icon">
            <svg width="40" height="36" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.18143 5.4235H5.6525V8.9235H4.47619V5.4235H0.947266V4.25684H4.47619V0.756836H5.6525V4.25684H9.18143V5.4235Z"
                fill="white" />
            </svg>
          </span>
        </button> -->
    </div>
  </div>
  <mat-card-content>
    <ngx-spinner [fullScreen]="false" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
      <p style="font-size: 20px; color: #050a4f">Fetching NCR Action Data...</p>
    </ngx-spinner>
    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
      <table mat-table [dataSource]="ncrActionData" class="basic-table plant-model-table" matSort>

        <!-- groupDescription Column -->
        <ng-container matColumnDef="plant">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Plant</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.plant}} </td>
        </ng-container>

        <!-- remarks Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">NCR Actions</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="levels">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Levels Of Approval</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.levels}} </td>
        </ng-container>
        <!-- plantModelActions Column -->
        <ng-container matColumnDef="ncrImmediateActions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-text">Action</p>
          </th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <button mat-icon-button *ngIf="IsWrite" class="edit-btn" aria-label="Edit Row"
                (click)="getOneNcrAction(element.id)" pTooltip="Edit NCR Actions" tooltipPosition="top">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button *ngIf="IsWrite" class="node-control-btn node-delete" aria-label="delete item"
                (click)="deleteNcrAction(element.id)" pTooltip="Delete NCR Actions" tooltipPosition="top">
                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.35547 0.9375H5.60547L5.10547 0.4375H2.60547L2.10547 0.9375H0.355469V1.9375H7.35547V0.9375ZM0.855469 8.4375C0.855469 8.70272 0.960826 8.95707 1.14836 9.14461C1.3359 9.33214 1.59025 9.4375 1.85547 9.4375H5.85547C6.12069 9.4375 6.37504 9.33214 6.56258 9.14461C6.75011 8.95707 6.85547 8.70272 6.85547 8.4375V2.4375H0.855469V8.4375Z"
                    fill="white" />
                </svg>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="ncrActionColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: ncrActionColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No Data Matching</td>
        </tr>
      </table>
      <mat-paginator class="basic-paginataor" [pageSizeOptions]="[2, 5, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

<div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{'active': ncrActionPlantModelVisible}">
  <div class="sidepanel-close" (click)="closeActionMasterModel()"></div>
  <div class="model-content">
    <div class="model-top">
      <h2 class="model-title">{{formMode ? 'Add' : 'Update'}} NCR Actions</h2>
      <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeActionMasterModel()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
            fill="black" />
        </svg>
      </button>
    </div>
    <div class="model-body">

      <form [formGroup]="ncrActionForm" (ngSubmit)="formMode ? submitForm() : updateForm()">
        <input formControlName="id" hidden="true">
        <div class="form-group">
          <mat-form-field [ngClass]="{
          'is-invalid':
          ncrActionForm.get('plant')?.errors &&
          ncrActionForm.get('plant')?.touched
          }" appearance="fill" class="field-fill">
            <mat-label>Plant</mat-label>
            <mat-select formControlName="plant" [disabled]="true">
              <mat-option *ngFor="let plant of plants" [value]="plant.ptId">{{plant.ptDesc}}</mat-option>
            </mat-select>
          </mat-form-field>
          <p class="invalid-feedback" *ngIf="
        ncrActionForm.get('plant')?.touched &&
        ncrActionForm.get('plant')?.hasError('required')
        ">
            *Please Select Plant
          </p>
        </div>
        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>Action</mat-label>
            <mat-select formControlName="action" [disabled]="!formMode" required>
              <mat-option *ngFor="let act of immediateActions" [value]="act.id">
                {{ act.name }}</mat-option>
            </mat-select>
            <!-- <mat-error>Please Select Action</mat-error> -->
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>Approval Type</mat-label>
            <mat-select formControlName="approvaltype" required>
              <mat-option value="Sequential">Sequential</mat-option>
              <mat-option value="Parallel">Parallel</mat-option>
            </mat-select>
            <!-- <mat-error>Please Select Action</mat-error> -->
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill">
            <mat-label>Number Of Approval Levels</mat-label>
            <input matInput placeholder="Enter no of levels" formControlName="levelsofapproval" type="number" min="1" max="20"
              (change)="addNewRow()" required />
            <!-- <mat-error>Please Enter No of levels</mat-error> -->
          </mat-form-field>
        </div>
        <div formArrayName="levelwiseroles">
          <div *ngFor="let elementArray of repeatLevelWiseElementForm.controls; let i = index;">
            <div [formGroupName]="i">
              <div class="content-top-row">
                <input formControlName="id" hidden="true">
                <mat-form-field appearence="fill"
                  class="top-row-first-filed-item top-row-first-filed-item-order-processing"
                  style="margin-left:0px; width:50%;">
                  <mat-label class="required">Level Name</mat-label>
                  <input matInput formControlName="level" readonly required>
                </mat-form-field>
                <mat-form-field appearence="fill"
                  class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width:50%;">
                  <mat-select ngxMatSelect [hasSearchBox]="true" [useMobileView]="true" mobileViewType="BottomSheet"
                    [formControlName]="'role'" [multiple]="false" name="PositionName" [required]="true" [source]="roles"
                    [displayMember]="'PositionName'" [placeholder]="'Role'" [valueMember]="'POS_Id'"
                    #sfeditperformedby="ngxMatSelect">
                    <mat-option *ngFor="let usel of sfeditperformedby.filteredSource" [value]="usel.POS_Id">
                      {{usel.PositionName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="model-form-actions">
          <button type="button" class="icon-btn" style="margin-left: 10px;" pTooltip="Cancel" tooltipPosition="top"
            (click)="closeActionMasterModel()">
            <img src="assets/images/cancel.png" alt="" width="20px" height="20px">
          </button>
          <button   type="submit" class="icon-btn" style="margin-left: 10px;" pTooltip="Update" tooltipPosition="top" *ngIf="!formMode">
          <img src="assets/images/edit.png" alt="" width="20px" height="20px">
          </button>
          <button type="submit" class="icon-btn" style="margin-left: 10px;" pTooltip="Save" tooltipPosition="top"
            *ngIf="formMode">
            <img src="assets/images/save.png" alt="" width="20px" height="20px">
          </button>
          <!-- <button type="button" mat-flat-button class="btn-cancel" (click)="closePillarModel()"> Cancel
        </button> -->
          <!-- <button mat-flat-button color="accent" [disabled]="!ncrActionForm.valid" class="btn-accent"> {{formMode ? 'Add' : 'Update'}} </button> -->
        </div>
      </form>
    </div>
  </div>
</div>
</div>