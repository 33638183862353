import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class DataSourcesService {
  constructor(private http: HttpClient) { }
  getOrderProcessingElementData(): Observable<any[]> {
    return this.http.get<any[]>('assets/data/data-source.json');
  }

  getDefectManagementElementData(): Observable<any[]> {
    return this.http.get<any[]>('assets/data/data-source-defect-management.json');
  }

  getAllData(): Observable<any[]> {
    return this.http.get<any[]>('./assets/data/theme-data-source.json');
  }

  getDashboardData(): Observable<any[]> {
    return this.http.get<any[]>('./assets/data/dashboard-data-source.json');
  }
}
