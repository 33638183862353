import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { OPMSMainComponent } from './../OPMS-Glass/opms-main/opms-main.component';
import { OpmsDbComponent } from './opms-db/opms-db.component';
import { GlassPpcLiveStackerdataComponent } from './glass-ppc-live-stackerdata/glass-ppc-live-stackerdata.component';
import { OpmsShiftReportsComponent } from './opms-shift-reports/opms-shift-reports.component';
const routes: Routes = [
  { path: 'OpmsGlass', component: OPMSMainComponent, canActivate: [AuthGuard] },
  { path: 'OPMSDB', component: OpmsDbComponent, canActivate: [AuthGuard] },
  { path: 'OPMS-Live-Stacker', component: GlassPpcLiveStackerdataComponent, canActivate: [AuthGuard] },
  { path: 'OPMS-HotEnd_Shift_Reports', component: OpmsShiftReportsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OpmsQualityRoutingModule {}
