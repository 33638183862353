<p-toast [style]="{ marginTop: '80px' }"></p-toast>
<div dir="{{languageDir}}">
  <section class="home-content">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
      <p style="color: white">{{langObj ? langObj?.FetchingTreeData : ('FetchingTreeData...' | translate)}}</p>
    </ngx-spinner>

    <mat-card>
      <div style="padding: 10px; background-color: #0f0fd1">
        <div class="p-grid p-fluid">
          <div class="p-col-6">
            <mat-card-title style="color: white; margin-top: 8px">{{ langObj ? langObj?.KPIGridView : ("KPIGridView" |
              translate)
              }}
            </mat-card-title>
          </div>
          <div class="p-col-5">
            <form [formGroup]="filtersForm">
              <div class="p-grid p-fluid">
                <div class="p-col-2 p-md-6">
                  <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                    'is-invalid':
                      filtersForm.get('group')?.errors &&
                      filtersForm.get('group')?.touched
                  }" [options]="groupList" formControlName="group"
                    placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}"
                    optionLabel="TypeName" [showClear]="true"></p-dropdown>
                  <p class="invalid-feedback" *ngIf="
                    filtersForm.get('plant')?.touched &&
                    filtersForm.get('plant')?.hasError('required')
                  ">
                    * please select group
                  </p>
                </div>
                <div class="p-col-2 p-md-6">
                  <p-dropdown [filter]="true" (onChange)="onPlantChange()" [ngClass]="{
                    'is-invalid':
                      filtersForm.get('plant')?.errors &&
                      filtersForm.get('plant')?.touched
                  }" [options]="searchPlants" formControlName="plant"
                    placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}"
                    optionLabel="DeptDesc" [showClear]="true"></p-dropdown>
                  <p class="invalid-feedback" *ngIf="
                    filtersForm.get('plant')?.touched &&
                    filtersForm.get('plant')?.hasError('required')
                  ">
                    * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
                  </p>
                </div>

              </div>
            </form>
          </div>
          <div class="p-col-1">
            <!-- <button mat-mini-fab (click)="openKPISideNav()" matTooltip="Add KPI" style="background-color:#ec3d3d">
              <mat-icon>add</mat-icon>
            </button> -->
            <button mat-mini-fab (click)="showVisionDialog()" matTooltip="Add Vision" style="background-color:#1890ff">
              <mat-icon>add</mat-icon>
            </button>
            <!-- <button mat-mini-fab (click)="showStartgicKpiDialog()" matTooltip="Add Strategic KPI"
              style="background-color:black">
              <mat-icon>add</mat-icon>
            </button> -->
            <button mat-mini-fab (click)="exportDataToXLSX()" matTooltip="Export" style="background-color:#10ce9c">
              <mat-icon>download</mat-icon>
            </button>

          </div>
        </div>
      </div>
      <div style="border: 1px solid #b4c6d8; background-color: #fff" #gridElement class="lock-screen" id="gridElement">
        <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="rowData"
          (cellClicked)="onCellClicked($event)" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
          [pagination]="true" [paginationPageSize]="10 " [loadingOverlayComponent]="loadingOverlayComponent"
          [allowContextMenuWithControlKey]="true" [getContextMenuItems]="getContextMenuItems">
        </ag-grid-angular>
      </div>
      <!-- <mat-card-content> -->

      <!-- <p-button *ngIf="showButton" (click)="showVisionDialog()"
        [style]="{ 'background-color': '#00bcd4', 'border-color': '#00bcd4' }" label="Add Vision"></p-button> -->
      <p-dialog [contentStyle]="{ overflow: 'visible' }" [closable]="false" header="Vision" [(visible)]="displayVision"
        [modal]="true" [style]="{ width: '50vw' }" [maximizable]="true" [baseZIndex]="10000" [draggable]="false"
        [resizable]="false">
        <form [formGroup]="visionForm">
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-6">
              <label for="range" class="required">Plant</label>
              <p-dropdown [filter]="true" [ngClass]="{
                'is-invalid':
                  visionForm.get('plant')?.errors &&
                  visionForm.get('plant')?.touched
              }" [options]="plants" formControlName="plant" [disabled]="true" placeholder="Select a Plant"
                optionLabel="DeptDesc" [showClear]="true"></p-dropdown>
              <p class="invalid-feedback" *ngIf="
                visionForm.get('plant')?.touched &&
                visionForm.get('plant')?.hasError('required')
              ">
                * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
              </p>
            </div>
            <div class="p-col-12 p-md-6">
              <label class="required">Vision Formulation</label>
              <input [ngClass]="{
                'is-invalid':
                  visionForm.get('name')?.errors &&
                  visionForm.get('name')?.touched
              }" type="text" pInputText formControlName="name" autocomplete="off" />
              <p class="invalid-feedback" *ngIf="
                visionForm.get('name')?.touched &&
                visionForm.get('name')?.hasError('required')
              ">
                * {{langObj ? langObj?.TitleisRequired : ('TitleisRequired!' | translate)}}
              </p>
            </div>
            <div class="p-col-12 p-md-6">
              <label for="range" class="required">{{langObj ? langObj?.YearFrom : ('YearFrom' | translate)}}</label>
              <p-calendar [locale]="calendar_en" [ngClass]="{
                'is-invalid':
                  strategicForm.get('yearFrom')?.errors &&
                  strategicForm.get('yearFrom')?.touched
              }" formControlName="yearFrom" view="month" dateFormat="mm/yy" [yearNavigator]="true"
                yearRange="2000:2060">
              </p-calendar>
              <p class="invalid-feedback" *ngIf="
                strategicForm.get('yearFrom')?.touched &&
                strategicForm.get('yearFrom')?.hasError('required')
              ">
                * {{langObj ? langObj?.yearisrequired : ('yearisrequired!' | translate)}}
              </p>
            </div>
            <div class="p-col-12 p-md-6">
              <label for="range" class="required">{{langObj ? langObj?.YearTo : ('YearTo' | translate)}}</label>
              <p-calendar [locale]="calendar_en" [ngClass]="{
                'is-invalid':
                  strategicForm.get('yearTo')?.errors &&
                  strategicForm.get('yearTo')?.touched
              }" formControlName="yearTo" view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="2000:2060">
              </p-calendar>
              <p class="invalid-feedback" *ngIf="
                strategicForm.get('yearTo')?.touched &&
                strategicForm.get('yearTo')?.hasError('required')
              ">
                * {{langObj ? langObj?.yearisrequired : ('yearisrequired!' | translate)}}
              </p>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{langObj ? langObj?.Quantify : ('Quantify' | translate)}}</label>
              <input type="text" pInputText formControlName="quantify" autocomplete="off" />
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}</label>
              <input type="text" pInputText formControlName="remarks" autocomplete="off" />
            </div>
          </div>
          <p-button icon="pi pi-check" *ngIf="visionFormMode !== 'details'"
            (click)="visionFormMode === 'add' ? addVision() : updateVision()" type="submit"
            label="{{ visionFormMode === 'add' ? 'Save' : 'Update' }}" [disabled]="!visionForm.valid"
            styleClass="p-button-success"></p-button>
          <p-button icon="pi pi-times" (click)="visionMenuFunction()" label="Cancel" styleClass="p-button-secondary">
          </p-button>
        </form>
      </p-dialog>
      <!-- <p-button *ngIf="strategicshowButton" (click)="showStartgicKpiDialog()"
        [style]="{ 'background-color': '#62e067', 'border-color': ' #62e067' }" label="Add Strategic KPI"></p-button> -->


      <!--Strategic Kpi Dialog-->
      <p-dialog [contentStyle]="{ overflow: 'visible' }" [closable]="false" header="Strategic KPI"
        [(visible)]="displayStartgicKpi" [modal]="true" [style]="{ width: '50vw' }" [maximizable]="true"
        [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <form [formGroup]="strategicForm">
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-6">
              <label for="range" class="required">Vision</label>
              <p-dropdown [filter]="true" [ngClass]="{
                'is-invalid':
                  strategicForm.get('vision')?.errors &&
                  strategicForm.get('vision')?.touched
              }" [options]="visions" placeholder="Select a Vision" formControlName="vision" optionLabel="Name"
                [showClear]="true" [disabled]="true">
              </p-dropdown>
              <p class="invalid-feedback" *ngIf="
                strategicForm.get('vision')?.touched &&
                strategicForm.get('vision')?.hasError('required')
              ">
                * {{langObj ? langObj?.pleaseselectvision : ('pleaseselectvision' | translate)}}
              </p>
            </div>
            <div class="p-col-12 p-md-6">
              <label for="range" class="required">{{langObj ? langObj?.Plant : ('Plant' | translate)}}</label>
              <p-dropdown [filter]="true" [ngClass]="{
                'is-invalid':
                  strategicForm.get('plant')?.errors &&
                  strategicForm.get('plant')?.touched
              }" [options]="plants" placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}"
                [disabled]="true" formControlName="plant" optionLabel="DeptDesc" [showClear]="true">
              </p-dropdown>
              <p class="invalid-feedback" *ngIf="
                strategicForm.get('plant')?.touched &&
                strategicForm.get('plant')?.hasError('required')
              ">
                * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
              </p>
            </div>
            <div class="p-col-12 p-md-6">
              <label class="required">{{langObj ? langObj?.StrategicKPIDescription : ('StrategicKPIDescription' |
                translate)}}</label>
              <input [ngClass]="{
                'is-invalid':
                  strategicForm.get('name')?.errors &&
                  strategicForm.get('name')?.touched
              }" autocomplete="off" type="text" formControlName="name" pInputText />
              <p class="invalid-feedback" *ngIf="
                strategicForm.get('name')?.touched &&
                strategicForm.get('name')?.hasError('required')
              ">
                * {{langObj ? langObj?.StrategicKPIDescriptionisrequired : ('StrategicKPIDescriptionisrequired!' |
                translate)}}
              </p>
            </div>
            <div class="p-col-12 p-md-6">
              <label for="range" class="required">{{langObj ? langObj?.Year : ('Year' | translate)}}</label>
              <p-calendar [locale]="calendar_en" [ngClass]="{
                'is-invalid':
                  strategicForm.get('year')?.errors &&
                  strategicForm.get('year')?.touched
              }" formControlName="year" view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="2000:2030">
              </p-calendar>
              <p class="invalid-feedback" *ngIf="
                strategicForm.get('year')?.touched &&
                strategicForm.get('year')?.hasError('required')
              ">
                * {{langObj ? langObj?.yearisyequired : ('yearisyequired!' | translate)}}
              </p>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{langObj ? langObj?.Quantify : ('Quantify' | translate)}}</label>
              <input autocomplete="off" formControlName="quantify" type="text" pInputText />
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{langObj ? langObj?.Remarks : ('Remarks' | translate)}}</label>
              <input autocomplete="off" formControlName="remarks" type="text" pInputText />
            </div>
          </div>
          <div>
            <p-button icon="pi pi-check" *ngIf="strategicFormMode !== 'details'" (click)="
              strategicFormMode === 'add'
                ? addStrategicKpidata()
                : updateStrategicKpiData()
            " label="{{ strategicFormMode === 'add' ? 'Add' : 'Update' }}" class="p-button-text"
              styleClass="p-button-success"></p-button>
            <p-button icon="pi pi-times" (click)="displayStartgicKpi = false" label="Cancel"
              styleClass="p-button-secondary"></p-button>
          </div>
        </form>
      </p-dialog>
      <!--Formula Builder Dialog-->
      <p-dialog [contentStyle]="{ overflow: 'visible' }" header="Formula Builder" [(visible)]="displayFormulaBuilder"
        [modal]="true" [style]="{ width: '50vw' }" [maximizable]="true" [position]="'top-left'" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <div class="p-grid p-fluid d-flex justify-content-between">
          <div class="p-field-radiobutton">
            <p-radioButton name="Date" (click)="loadrule()" value="Date" [(ngModel)]="rule" inputId="Rule1">
            </p-radioButton>
            <label for="Rule1" style="vertical-align: middle">{{langObj ? langObj?.Calculation : ('Calculation' |
              translate)}}</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton name="Operator" (click)="loadrule()" value="Operator" [(ngModel)]="rule" inputId="Rule2">
            </p-radioButton>
            <label for="Rule2">{{langObj ? langObj?.Operator : ('Operator' | translate)}}</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton name="Operand" (click)="loadrule()" value="Operand" [(ngModel)]="rule" inputId="Rule3">
            </p-radioButton>
            <label for="Rule3">{{langObj ? langObj?.Operand : ('Operand' | translate)}}</label>
          </div>
        </div>
        <div *ngIf="showFormulaDate">
          <select class="form-control" id="roles" name="rolevalue" #roleControl="ngModel" [(ngModel)]="stringvalue">
            <option *ngFor="let date of dates" value="{{ date.name }}">
              {{ date.name }}
            </option>
          </select>
        </div>
        <div *ngIf="showFormulaOperater">
          <select class="form-control" id="roles" name="rolevalue" #roleControl="ngModel" [(ngModel)]="stringvalue">
            <option *ngFor="let date of rulelist" value="{{ date.name }}">
              {{ date.name }}
            </option>
          </select>
        </div>
        <div *ngIf="showFormulaOperand" class="p-grid p-fluid">
          <div class="p-col-12 p-md-6">
            <label for="roles">{{langObj ? langObj?.PleaseSelectFrequency : ('PleaseSelectFrequency' |
              translate)}}</label>
            <p-dropdown [options]="frequencies" [filter]="true" (onChange)="onFrequencyChange($event)"
              optionLabel="name" placeholder="{{langObj ? langObj?.SelectFrequency : ('SelectFrequency' | translate)}}">
            </p-dropdown>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="roles">{{langObj ? langObj?.PleaseSelectKPICode : ('PleaseSelectKPICode' | translate)}}</label>
            <p-dropdown [options]="kpis" optionLabel="fullCodeName" name="fullCodeName" [filter]="true"
              #roleControl="ngModel" [(ngModel)]="stringvalue" [showClear]="true"
              placeholder="{{langObj ? langObj?.SelectKPICode : ('SelectKPICode' | translate)}}">
            </p-dropdown>
          </div>
        </div>

        <!-- <p-button icon="pi pi-check" (click)="formulamaking()" label="Add" class="p-button-text"
                styleClass="p-button-success"></p-button> -->
        <button pButton pRipple type="button" (click)="formulamaking()" icon="pi pi-plus-circle"
          class="p-button-rounded p-button-secondary mt-4"></button>
        <hr />
        <div class="p-grid p-fluid">
          <div class="p-col-6">
            <!-- <input autocomplete="off" [(ngModel)]="formulastring" name="formulastring" value="{{ formulastring }}"
            type="text" pInputText /> -->
            <textarea autocomplete="off" [(ngModel)]="formulastring" name="formulastring" value="{{ formulastring }}"
              type="text" pInputText></textarea>
          </div>
        </div>
        <ng-template pTemplate="footer">
          <p-button icon="pi pi-check" (click)="AddFormulaValue()" label="Add" class="p-button-text"
            styleClass="p-button-success"></p-button>
          <p-button icon="pi pi-times" (click)="displayFormulaBuilder = false" label="Cancel"
            styleClass="p-button-secondary"></p-button>
        </ng-template>
      </p-dialog>
      <!--Dynamic Formula Builder Dialog-->
      <p-dialog [contentStyle]="{ overflow: 'visible' }" header="Dynamic Formula Builder "
        [(visible)]="displayDynamicFormulaBuilder" [modal]="true" [style]="{ width: '60vw' }" [maximizable]="true"
        [position]="'top-left'" [baseZIndex]="10000" [draggable]="false" [resizable]="false">


        <div *ngIf="showDynamicFormulaOperand" class="p-grid p-fluid">
          <div class="p-col-12 p-md-4">
            <label for="roles">{{langObj ? langObj?.PleaseSelectFrequency : ('PleaseSelectFrequency' |
              translate)}}</label>
            <p-dropdown [options]="frequencies" [filter]="true" (onChange)="onFrequencyChange($event)"
              optionLabel="name" placeholder="{{langObj ? langObj?.SelectFrequency : ('SelectFrequency' | translate)}}">
            </p-dropdown>
          </div>
          <div class="p-col-12 p-md-4">
            <label for="roles">{{langObj ? langObj?.PleaseSelectKPICode : ('PleaseSelectKPICode' | translate)}}</label>
            <p-dropdown [options]="kpis" optionLabel="fullCodeName" name="fullCodeName" [filter]="true"
              #roleControl="ngModel" [(ngModel)]="Dynamickpistringvalue" [showClear]="true"
              placeholder="{{langObj ? langObj?.SelectKPICode : ('SelectKPICode' | translate)}}">
            </p-dropdown>
          </div>

          <div class="p-col-12 p-md-3">
            <label for="roles">{{langObj ? langObj?.PleaseSelectTarget : ('PleaseSelectTarget' | translate)}}</label>
            <p-dropdown [options]="Target" optionLabel="label" name="label" [filter]="true" #roleControl="ngModel"
              [(ngModel)]="Dynamictargetstringvalue" [showClear]="true"
              placeholder="{{langObj ? langObj?.SelectTarget : ('SelectTarget' | translate)}}">
            </p-dropdown>
          </div>
          <div class="p-col-12 p-md-1">
            <button pButton pRipple type="button" (click)="dynamicformulamaking()" icon="pi pi-plus-circle"
              class="p-button-rounded p-button-secondary mt-4"
              style="margin-top: 20px; background-color: forestgreen;"></button>
          </div>
        </div>
        <div class="p-col-8 justify-content-between" style="width: 98.6667%;">
          <button mat-mini-fab mat-button type="submit" color="accent" title="Add" (click)="addOperator('+')">
            <i class="fa fa-plus" style="color: #fff"></i>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Subtract" class="Operations"
            (click)="addOperator('-')">
            <i class="fa fa-minus" style="color: #fff"></i>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Multiply" class="Operations"
            (click)="addOperator('*')">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('/')">
            <div style="color:#fff;font-size: 20px;font-weight: 900;">÷</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('(')">
            <div style="color:#fff">(</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator(')')">
            <div style="color:#fff">)</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('0')">
            <div style="color:#fff">0</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('1')">
            <div style="color:#fff">1</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('2')">
            <div style="color:#fff">2</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('3')">
            <div style="color:#fff">3</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('4')">
            <div style="color:#fff">4</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('5')">
            <div style="color:#fff">5</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('6')">
            <div style="color:#fff">6</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('7')">
            <div style="color:#fff">7</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('8')">
            <div style="color:#fff">8</div>
          </button>
          <button mat-mini-fab mat-button type="submit" color="accent" title="Devide" class="Operations"
            (click)="addOperator('9')">
            <div style="color:#fff">9</div>
          </button>
        </div>
        <div class="d-flex  align-items-center">
          <mat-form-field class="example-chip-list" appearance="fill">
            <mat-label>{{langObj ? langObj?.DynamicFormula : ('DynamicFormula' | translate)}}</mat-label>
            <mat-chip-list #chipList aria-label="Formula selection" cdkDropList class="example-chip field-fill no-wrap"
              cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
              <mat-chip cdkDrag class="example-box" *ngFor="let item of targetArray ;let i=index"
                (removed)="removeKeyword(list,i)" (dblclick)="allowEdit(item,i)" [value]="item.name"
                (cdkDragStarted)="dragStart(i)" (mouseenter)="mouseEnter(i)">
                {{item.name}}
                <!-- <button matChipRemove>
               <mat-icon>cancel</mat-icon>
               </button> -->
              </mat-chip>
              <input [matChipInputFor]="chipList" [readonly]="true">
            </mat-chip-list>
          </mat-form-field>




          <div class="p-col-12 p-md-1">
            <button pButton pRipple type="button" (click)="ClearDyanamicChipList()" icon="fa fa-refresh"
              class="p-button-rounded p-button-secondary mt-4"
              style="margin-top: -22px;background-color: cornflowerblue;"></button>
          </div>
        </div>

        <ng-template pTemplate="footer">
          <p-button icon="pi pi-check" (click)="AddDynamicFormulaValue()" label="Add" class="p-button-text"
            styleClass="p-button-success"></p-button>
          <p-button icon="pi pi-times" (click)="displayDynamicFormulaBuilder = false" label="Cancel"
            styleClass="p-button-secondary"></p-button>
        </ng-template>
      </p-dialog>

      <!--Formula Builder Rule Dialog-->
      <p-dialog [contentStyle]="{ overflow: 'visible' }" header="Formula Rule" [(visible)]="displayFormulaRuleDialog"
        [modal]="true" [style]="{ width: '35vw' }" [maximizable]="true" [baseZIndex]="10000" [draggable]="true"
        [resizable]="false">
        <br />
        <div class="p-grid p-fluid d-flex justify-content-between">
          <div class="p-field-radiobutton">
            <p-radioButton name="Date" (click)="loadrule()" value="Date" [(ngModel)]="rule" inputId="Rule1">
            </p-radioButton>
            <label for="Rule1" style="vertical-align: middle">{{langObj ? langObj?.Calculation : ('Calculation' |
              translate)}}</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton name="Operator" (click)="loadrule()" value="Operator" [(ngModel)]="rule" inputId="Rule2">
            </p-radioButton>
            <label for="Rule2">{{langObj ? langObj?.Operator : ('Operator' | translate)}}</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton name="Operand" (click)="loadrule()" value="Operand" [(ngModel)]="rule" inputId="Rule3">
            </p-radioButton>
            <label for="Rule3">{{langObj ? langObj?.Operand : ('Operand' | translate)}}</label>
          </div>
        </div>
        <div *ngIf="showFormulaDate">
          <select class="form-control" id="roles" name="rolevalue" #roleControl="ngModel" [(ngModel)]="stringvalue">
            <option *ngFor="let date of dates" value="{{ date.name }}">
              {{ date.name }}
            </option>
          </select>
        </div>
        <div *ngIf="showFormulaOperater">
          <select class="form-control" id="roles" name="rolevalue" #roleControl="ngModel" [(ngModel)]="stringvalue">
            <option *ngFor="let date of rulelist" value="{{ date.name }}">
              {{ date.name }}
            </option>
          </select>
        </div>
        <div *ngIf="showFormulaOperand" class="p-grid p-fluid">
          <div class="p-col-12 p-md-6">
            <label for="roles">{{langObj ? langObj?.PleaseSelectFrequency : ('PleaseSelectFrequency' |
              translate)}}</label>
            <p-dropdown [options]="frequencies" [filter]="true" (onChange)="onFrequencyChange($event)"
              optionLabel="name" placeholder="{{langObj ? langObj?.SelectFrequency : ('SelectFrequency' | translate)}}">
            </p-dropdown>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="roles">{{langObj ? langObj?.PleaseSelectKPICode : ('PleaseSelectKPICode' | translate)}}</label>
            <p-dropdown [options]="kpis" optionLabel="fullCodeName" name="fullCodeName" [filter]="true"
              #roleControl="ngModel" [(ngModel)]="stringvalue" [showClear]="true"
              placeholder="{{langObj ? langObj?.SelectKPICode : ('SelectKPICode' | translate)}}">
            </p-dropdown>
          </div>
        </div>

        <ng-template pTemplate="footer">
          <p-button icon="pi pi-check" (click)="formulamaking()" label="Add" class="p-button-text"
            styleClass="p-button-success"></p-button>
          <p-button icon="pi pi-times" (click)="displayFormulaRuleDialog = false" label="Cancel"
            styleClass="p-button-secondary"></p-button>
        </ng-template>
      </p-dialog>
      <!--Machine Dialog-->
      <p-dialog [contentStyle]="{ overflow: 'scroll' }" header="Integration Mapping" [(visible)]="displayMachineDialog"
        [modal]="true" [style]="{ width: '50vw' }" [maximizable]="true" [position]="'top-left'" [baseZIndex]="10000"
        [draggable]="true" [resizable]="true">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <table mat-table class="basic-table plant-model-table" [dataSource]="availabilityDataSource" matSort>
              <!-- Category Type Column -->
              <ng-container matColumnDef="area">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <p class="header-text">{{langObj ? langObj?.Area : ('Area' | translate)}}</p>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.area }}</td>
              </ng-container>

              <!-- Time Column -->
              <ng-container matColumnDef="machine">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <p class="header-text">{{langObj ? langObj?.Machine : ('Machine' | translate)}}</p>
                </th>
                <td mat-cell *matCellDef="let element">
                  <p-multiSelect [baseZIndex]="10000" [options]="unitList" [filter]="true"
                    placeholder="{{langObj ? langObj?.SelectMachine : ('SelectMachine' | translate)}}" name="puDesc"
                    optionLabel="puDesc"></p-multiSelect>
                </td>
              </ng-container>

              <!-- Failure Mode Column -->
              <ng-container matColumnDef="frequency">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <p class="header-text">{{langObj ? langObj?.Frequency : ('Frequency' | translate)}}</p>
                </th>
                <td mat-cell *matCellDef="let element">
                  <p-multiSelect [options]="Frequency" [filter]="true" placeholder="Select a frequency" name="Frequency"
                    optionLabel="name"></p-multiSelect>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="sourceColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: sourceColumns"></tr>
              <tr class="mat-row" *matNoDataRow style="text-align: center">
                <td class="mat-cell" colspan="6" class="hh">{{langObj ? langObj?.Nodatafound : ('Nodatafound' |
                  translate)}}</td>
              </tr>
            </table>

            <!-- <label>Machine Tree</label>
                <p-tree
                  [value]="machineData"
                  selectionMode="checkbox"
                  [(selection)]="selectedMachine"
                  (onNodeSelect)="machineNodeSelect($event)"
                  (onNodeUnselect)="machineNodeUnselect($event)"
                >
                </p-tree> -->
          </div>
        </div>
        <ng-template pTemplate="footer">
          <p-button icon="pi pi-check" (click)="displayMachineDialog = false" label="Save" class="p-button-text"
            styleClass="p-button-success"></p-button>
          <p-button icon="pi pi-times" (click)="displayMachineDialog = false" label="Cancel"
            styleClass="p-button-secondary"></p-button>
        </ng-template>
      </p-dialog>
      <!-- </mat-card-content> -->
    </mat-card>
  </section>
  <div id="panel" class="sidepanel-model-wrapper add-group-item-model" [ngClass]="{ active: showKPISideNav }">
    <div class="sidepanel-close" (click)="closeaddNewGroupModel()"></div>
    <div class="model-content" style="width: 300px">
      <div class="model-top">
        <div class="d-flex align-items-center">
          <h2 class="model-title">
            {{
            kpiFormMode === "add"
            ? "Add"
            : kpiFormMode === "edit"
            ? "Update"
            : "Detail"
            }}
            KPI Information
          </h2>
        </div>
        <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="closeaddNewGroupModel()">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
              fill="black" />
          </svg>
        </button>
      </div>
      <div class="model-body">
        <form [formGroup]="KPIForm" (ngSubmit)="kpiFormMode === 'add' ? onSubmit() : editKPI()">

          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12">
              <label class="required">{{
                langObj ? langObj?.Parent : ("Parent" | translate)
                }}</label>
              <p-dropdown [options]="KPIParentList" formControlName="parentId" optionLabel="Label"
                optionValue="ParentId" defaultLabel="Select a KPI or StrategicKPI"
                (cdkAutofill)="onAutofill($event)"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6">
              <label class="required">{{
                langObj ? langObj?.KPIName : ("KPIName" | translate)
                }}</label>
              <input id="float-input" autocomplete="off" pInputText name="kpiTitle" formControlName="kpiTitle"
                style="width: 91%" />
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj ? langObj?.Driver : ("Driver" | translate)
                }}</label>
              <p-multiSelect [options]="drivers" formControlName="kpiDriver" name="kpiDriver"
                defaultLabel="Select a Drivers" optionLabel="name"></p-multiSelect>
            </div>
            <div class="p-col-12 p-md-6">
              <label class="required">{{
                langObj ? langObj?.Frequency : ("Frequency" | translate)
                }}</label>
              <p-dropdown [options]="frequencies" [filter]="true" formControlName="kpifrequency" name="kpifrequency"
                optionLabel="name" placeholder="Select a frequency"></p-dropdown>
            </div>

            <div class="p-col-12 p-md-6">
              <label>{{
                langObj ? langObj?.KPICode : ("KPICode" | translate)
                }}</label>
              <input id="float-input" [readonly]="true" autocomplete="off" pInputText name="kpiCode"
                formControlName="kpiCode" />
            </div>
            <div class="p-col-12 p-md-6">
              <label class="required">{{
                langObj
                ? langObj?.UnitOfMeasurement
                : ("UnitOfMeasurement" | translate)
                }}</label>
              <p-dropdown [options]="uoms" [filter]="true" placeholder="Select a uom" formControlName="kpiUom"
                name="kpiUom" optionLabel="name"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj ? langObj?.Pillar : ("Pillar" | translate)
                }}</label>
              <p-multiSelect [options]="pillars" formControlName="kpiPillar" name="kpiPillar"
                defaultLabel="Select a Pillar" optionLabel="name"></p-multiSelect>
            </div>
            <div class="p-col-12 p-md-6" appendTo="body">
              <label>{{
                langObj ? langObj?.Department : ("Department" | translate)
                }}</label>
              <p-multiSelect [options]="departments" formControlName="kpidepartments" name="kpidepartments"
                defaultLabel="Select a Department" optionLabel="TypeName"></p-multiSelect>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj ? langObj?.StrategicKPI : ("StrategicKPI" | translate)
                }}</label>
              <p-multiSelect [options]="stragicKpis" formControlName="attachedStargicKpi" name="attachedStargicKpi"
                defaultLabel="Select a Startegic Kpi" optionLabel="name"></p-multiSelect>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{ langObj ? langObj?.Line : ("Line" | translate) }}</label>
              <p-dropdown [options]="lines" [filter]="true" (onChange)="getMachines()" placeholder="Select a Line"
                formControlName="kpiline" name="kpiline" optionLabel="plDesc"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj ? langObj?.Machine : ("Machine" | translate)
                }}</label>
              <p-dropdown [options]="machines" [filter]="true" placeholder="Select a Machine"
                formControlName="kpimachine" name="kpimachine" optionLabel="puDesc"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6">
              <label class="required">{{
                langObj ? langObj?.Trend : ("Trend" | translate)
                }}</label>
              <p-dropdown [options]="trends" [filter]="true" placeholder="Select a Trend" formControlName="trend"
                name="trend" optionLabel="name"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6">
              <label class="required">{{
                langObj
                ? langObj?.FormulaCalculationType
                : ("FormulaCalculationType" | translate)
                }}</label>
              <p-dropdown [options]="FormulaCalculationTypes" [filter]="true" placeholder="Select a Calculation"
                formControlName="kpiFormulaType" name="kpiFormulaType" optionLabel="name"></p-dropdown>
            </div>

            <div class="p-col-12 p-md-6">
              <label>{{
                langObj
                ? langObj?.IntegrationSources
                : ("IntegrationSources" | translate)
                }}</label>
              <p-dropdown [options]="Sources" [filter]="true" placeholder="Select a Source" formControlName="Source"
                name="Source" optionLabel="name"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{ langObj ? langObj?.KPIGroups : ("KPIGroups" | translate) }}</label>
              <p-dropdown [options]="kpiGroups" [filter]="true" placeholder="Select a KPI Group"
                formControlName="kpigroup" name="Source" optionLabel="kpiGroupName"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{ langObj ? langObj?.KPIDataType : ("KPIDataType" | translate) }}</label>
              <p-dropdown [options]="kpiDataTypes" [filter]="true" placeholder="Select KPI Data Type"
                formControlName="kpiDataType" name="Source" optionLabel="DataType"></p-dropdown>

            </div>

            <div class="p-col-12 p-md-6" style="text-align: center">
              <button type="button" (click)="showMachineDialog()" class="add-btn1">
                <img src="assets/images/add.png" alt="" width="20px" height="20px" />
              </button>
            </div>
            <hr />
            <div class="p-col-12 p-md-6"><label>{{ langObj? langObj?.AllowActualEntry : ("AllowActualEntry" |
                translate)}}</label>
            </div>
            <div class="p-col-12 p-md-5" style="text-align: center; margin-left: 18px;">
              <p-inputSwitch formControlName="kpiAllowActualEntry" (click)="showAllowActualEntry()"></p-inputSwitch>
            </div>

            <div *ngIf="showFormula" class="p-col-12 p-md-12">
              <label>{{langObj ? langObj?.Formula : ('Formula' | translate)}}</label>
              <!-- <input id="float-input" type="text" autocomplete="off" pInputText formControlName="kpiFormula"
              name="kpiFormula" autocomplete="off" /> -->
              <textarea id="float-input" autocomplete="off" pInputText formControlName="kpiFormula" name="kpiFormula"
                autocomplete="off"></textarea>
            </div>

            <div *ngIf="showFormula" class="p-col-12 p-md-12" style="text-align: center; margin-left: 26%;">
              <button pButton pRipple type="button" (click)="showFormulaDialog()" icon="pi pi-plus-circle"
                class="p-button-rounded mt-4"></button>
            </div>
            <div class="p-col-12 p-md-12">
              <hr />
            </div>
            <div class="p-col-12 p-md-12" style="margin-top: -8px;">
              <label>{{ langObj? langObj?.TargetSetting : ("TargetSetting" | translate)}}</label>
            </div>
            <div class="p-col-12 p-md-6">

              <label>{{ langObj? langObj?.AllowDynamicTarget : ("AllowDynamicTarget" | translate)}}</label>

            </div>
            <div class="p-col-12 p-md-5" style="text-align: center; margin-left: 18px;">
              <p-inputSwitch formControlName="kpiAllowDynamicTarget" (click)="showAllowTarget()"></p-inputSwitch>
            </div>
            <!-- Dynamic Formula -->

            <div *ngIf="showDynamicFormula" class="p-col-12 p-md-12" style="text-align: center">
              <mat-form-field class="example-chip-list" appearance="fill">
                <mat-label>{{ langObj? langObj?.DynamicFormula : ("DynamicFormula" | translate)}}</mat-label>
                <mat-chip-list #chipList aria-label="Fruit selection">
                  <mat-chip *ngFor="let item of FetchArray" (removed)="remove(item)">
                    {{item.name}}
                  </mat-chip>
                </mat-chip-list>
              </mat-form-field>
            </div>
            <div *ngIf="showDynamicFormula" class="p-col-12 p-md-12" style="text-align: center; margin-left: 26%;">
              <button pButton pRipple type="button" (click)="showDynamicFormulaDialog()" icon="pi pi-plus-circle"
                class="p-button-rounded mt-4" style="background-color: forestgreen;"></button>
            </div>



          </div>

          <div class="p-grid p-fluid" style="margin-top: 5px;">
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj
                ? langObj?.AllowAutoTarget
                : ("AllowAutoTarget" | translate)
                }}</label>
            </div>
            <div class="p-col-12 p-md-6" style="text-align: center">
              <p-inputSwitch formControlName="kpiAllowTargetEntry" (click)="displayCalculationType()"></p-inputSwitch>
            </div>
            <div class="p-col-12 p-md-6" *ngIf="isShown">
              <label>{{langObj ? langObj?.CalculationType : ('CalculationType' | translate)}} (Children)</label>
              <p-dropdown [options]="calculationType" [filter]="true" formControlName="kpiCalculationType"
                placeholder="{{langObj ? langObj?.SelectCalculation : ('SelectCalculation' | translate)}}"
                optionLabel="calculationName"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6" *ngIf="isShown">
              <label>{{langObj ? langObj?.CalculationType : ('CalculationType' | translate)}} (Self)</label>
              <p-dropdown [options]="calculationType" [filter]="true" formControlName="kpiCalculationTypeItselfId"
                placeholder="{{langObj ? langObj?.SelectCalculation : ('SelectCalculation' | translate)}}"
                optionLabel="calculationName"></p-dropdown>
            </div>
          </div>
          <hr />
          <h3 style="font-weight: normal" style="margin-top: 5px;">
            {{ langObj ? langObj?.RACI : ("RACI" | translate) }}
          </h3>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj
                ? langObj?.ResponsibleRoles
                : ("ResponsibleRoles" | translate)
                }}</label>
              <p-multiSelect [options]="responsibleroles" [filter]="true" (onChange)="getResponsibleRolesUsers()"
                defaultLabel="Select a Role" formControlName="kpiResponsibleRole" name="kpiResponsibleRole"
                optionLabel="PositionName"></p-multiSelect>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj ? langObj?.Responsible : ("Responsible" | translate)
                }}</label>
              <p-multiSelect [options]="responsibles" formControlName="kpiResponsible" name="kpiResponsible"
                defaultLabel="Select a R" optionLabel="UserName"></p-multiSelect>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj
                ? langObj?.AccountableRoles
                : ("AccountableRoles" | translate)
                }}</label>
              <p-dropdown [options]="responsibleroles" [showClear]="true" [filter]="true"
                (onChange)="getAccountableRolesUsers()" placeholder="Select a Role" formControlName="kpiAccountableRole"
                name="kpiAccountableRole" optionLabel="PositionName"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj ? langObj?.Accountable : ("Accountable" | translate)
                }}</label>
              <p-multiSelect [options]="accountables" formControlName="kpiAccountable" name="kpiAccountable"
                defaultLabel="Select a A" optionLabel="UserName"></p-multiSelect>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj ? langObj?.ConsultRoles : ("ConsultRoles" | translate)
                }}</label>
              <p-multiSelect [options]="responsibleroles" [filter]="true" (onChange)="getConsultRolesUsers()"
                placeholder="Select a Role" formControlName="kpiConsultRole" name="kpiConsultRole"
                optionLabel="PositionName">
              </p-multiSelect>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj ? langObj?.Consult : ("Consult" | translate)
                }}</label>
              <p-multiSelect [options]="consults" formControlName="kpiC" name="kpiC" defaultLabel="Select a C"
                optionLabel="UserName"></p-multiSelect>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj ? langObj?.InformedRoles : ("InformedRoles" | translate)
                }}</label>
              <p-multiSelect [options]="responsibleroles" [filter]="true" (onChange)="getInformedRolesUsers()"
                placeholder="Select a Role" formControlName="kpiInformedRole" name="kpiInformedRole"
                optionLabel="PositionName">
              </p-multiSelect>
            </div>
            <div class="p-col-12 p-md-6">
              <label>{{
                langObj ? langObj?.Informed : ("Informed" | translate)
                }}</label>
              <p-multiSelect [options]="informs" formControlName="kpiInformed" name="kpiInformed"
                defaultLabel="Select a I" optionLabel="UserName"></p-multiSelect>
            </div>
          </div>
          <hr />
          <button pButton pRipple type="submit" class="p-button-rounded p-button-success" style="margin-top: 10px;"
            *ngIf="kpiFormMode !== 'details'" label="{{ kpiFormMode === 'add' ? 'Save' : 'Update' }}"></button>
          <button pButton pRipple type="button" label="Cancel" (click)="showKPISideNav = false"
            style="margin-top: 10px;" class="p-button-rounded p-button-secondary"></button>
        </form>
      </div>
    </div>
  </div>

  <ng-template #visibleYearlyTarget>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>
          {{
          langObj
          ? langObj?.YearlyTargetInformation
          : ("YearlyTargetInformation" | translate)
          }}
        </h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>

    <div class="p-field p-col-3">
      <mat-form-field class="example-full-width" appearance="fill" style="display:none;">
        <mat-label>{{langObj ? langObj?.choosedate : ('choosedate' | translate)}}</mat-label>
        <input matInput [matDatepicker]="pickerforgrid" (dateInput)="gridDate($event)">
        <mat-datepicker-toggle matSuffix [for]="pickerforgrid"></mat-datepicker-toggle>
        <mat-datepicker touchUi #pickerforgrid></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-dialog-content>
      <div class="p-grid p-fluid">
        <div class="p-field p-col-3">
          <label for="integeronly">{{
            langObj ? langObj?.Title : ("Title" | translate)
            }}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiTitle" disabled />
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{
            langObj ? langObj?.Frequency : ("Frequency" | translate)
            }}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpifrequency" disabled />
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{
            langObj ? langObj?.Uom : ("Uom" | translate)
            }}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiUom" disabled />
        </div>
        <div *ngIf="showCheckBox" class="p-field p-col-2 d-flex flex-column justify-content-center align-items-center">
          <label for="integeronly" style="text-align: center">{{
            langObj ? langObj?.AutoTarget : ("AutoTarget" | translate)
            }}</label>
          <mat-checkbox color="accent" [(ngModel)]="kpiautotarget"></mat-checkbox>
        </div>
        <div class="p-field p-col-1">
          <button mat-mini-fab color="accent" pTooltip="Add Zero Vision Value" matTooltipPosition="right"
            style="margin-top: 12px" (click)="openModal()">
            <i class="fa fa-plus" style="color: #fff"></i>
          </button>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="dataArray"
          [columnDefs]="columnDefs" [components]="components" (cellValueChanged)="onCellValueChanged($event)"
          [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </mat-dialog-content>
  </ng-template>

  <ng-template #displayRoleModal>
    <form [formGroup]="zeroform" (ngSubmit)="addzerovalue()">
      <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
          <h2>
            {{
            langObj ? langObj?.ZeroVisionValue : ("ZeroVisionValue" | translate)
            }}
          </h2>
          <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
            <i class="fa fa-times" style="color: #fff"></i>
          </button>
        </div>
      </div>

      <mat-dialog-content>
        <div class="form-group">
          <mat-form-field appearance="fill" class="field-fill" style="margin-top: 10px; width: 45%">
            <mat-label class="required">{{langObj ? langObj?.ZeroVisionValue : ('ZeroVisionValue' | translate)}}
            </mat-label>
            <input type="number" [ngClass]="{
              'is-invalid':
                zeroform.get('zeroVisionValue')?.errors &&
                zeroform.get('zeroVisionValue')?.touched
            }" matInput placeholder="Add Zero vision Value" formControlName="zeroVisionValue" />
            <p class="invalid-feedback" *ngIf="
              zeroform.get('zeroVisionValue')?.touched &&
              zeroform.get('zeroVisionValue')?.hasError('required')
            ">
              * {{langObj ? langObj?.ZeroVisionValueisRequired : ('ZeroVisionValueisRequired' | translate)}}
            </p>
          </mat-form-field>

          <mat-form-field appearance="fill" class="field-fill" style="margin-left: 10px">
            <mat-label class="required">{{langObj ? langObj?.ZeroVisionDate : ('ZeroVisionDate' | translate)}}
            </mat-label>
            <input [ngClass]="{
              'is-invalid':
                zeroform.get('zeroVisionDate')?.errors &&
                zeroform.get('zeroVisionDate')?.touched
            }" matInput [matDatepicker]="picker"
              placeholder="{{langObj ? langObj?.ZeroVisionDate : ('ZeroVisionDate' | translate)}}"
              formControlName="zeroVisionDate" />
            <p class="invalid-feedback" *ngIf="
              zeroform.get('zeroVisionDate')?.touched &&
              zeroform.get('zeroVisionDate')?.hasError('required')
            ">
              * {{langObj ? langObj?.ZeroVisionDateisRequired : ('ZeroVisionDateisRequired' | translate)}}
            </p>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <div mat-dialog-actions style="float: right">
        <button color="accent" mat-flat-button cdkFocusInitial>
          {{langObj ? langObj?.SaveandClose : ('Save & Close' | translate)}}
        </button>
      </div>
    </form>
  </ng-template>

  <div class="p-field p-col-3">
    <form [formGroup]="timeFormGroup">
      <input [ngxTimepicker]="starttimepicker" formControlName="timepicker" readonly class="form-control" [format]="24"
        style="display:none;" />
      <ngx-material-timepicker style="display:none;" #starttimepicker></ngx-material-timepicker>
    </form>
  </div>


  <ng-template #displayMonthlyTarget>

    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{ modalTitle }} {{langObj ? langObj?.TargetInformation : ('TargetInformation' | translate)}}</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>


    <mat-dialog-content>
      <div class="p-grid p-fluid">
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.Title : ('Title' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiTitle" disabled />
        </div>

        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.Frequency : ('Frequency' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpifrequency" disabled />
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.uom : ('uom' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiUom" disabled />
        </div>
        <div class="p-field p-col-3">
          <mat-form-field class="example-full-width" appearance="fill" style="display:none;">
            <mat-label>{{langObj ? langObj?.choosedate : ('choosedate' | translate)}}</mat-label>
            <input matInput [matDatepicker]="pickerforgrid" (dateInput)="gridDate($event)">
            <mat-datepicker-toggle matSuffix [for]="pickerforgrid"></mat-datepicker-toggle>
            <mat-datepicker touchUi #pickerforgrid></mat-datepicker>
          </mat-form-field>
        </div>

        <div *ngIf="showCheckBox" class="p-field p-col-3" style="
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
        ">
          <label for="integeronly" style="text-align: center">{{langObj ? langObj?.AutoTarget : ('AutoTarget' |
            translate)}}</label>
          <mat-checkbox color="accent" [(ngModel)]="kpiautotarget"></mat-checkbox>
        </div>
      </div>
      <div class="p-grid p-fluid" *ngIf="viewKpiInformation">
        <div class="p-field p-col-3">
          <label for="integeronly" class="required">{{langObj ? langObj?.TargetDate : ('TargetDate' |
            translate)}}</label>
          <p-calendar [locale]="calendar_en" inputId="basic" [disabledDays]="dayOffList" dateFormat="dd/mm/yy"
            [(ngModel)]="targetDate" [minDate]="minDate" [maxDate]="maxDate" [monthNavigator]="true"
            [yearNavigator]="true" yearRange="2015:2050" inputId="navigators"></p-calendar>
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.LastActualValue : ('LastActualValue' | translate)}}</label>
          <input id="float-input" disabled pInputText value="0" />
        </div>
        <div class="p-field p-col-3">


          <ng-container *ngIf="datatype === 1;">
            <label for="integeronly" class="required">{{langObj ? langObj?.TargetValue : ('TargetValue' |
              translate)}}</label>
            <input autocomplete="off" [(ngModel)]="targetAmount" (input)="onTargetValueChange($event)" pInputText />
          </ng-container>

          <ng-container *ngIf="datatype === 2">
            <mat-form-field class="example-full-width" appearance="outline" style="margin-top: 26px;">
              <mat-label>{{langObj ? langObj?.Date : ('Date' | translate)}}</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="targetAmount" style=" margin-top: -8px;">
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="datatype === 3">
            <label for="integeronly" class="required">{{langObj ? langObj?.Time : ('Time' | translate)}}</label>
            <!-- <nz-time-picker  [(ngModel)]="time" (ngModelChange)="log($event)" nzSize="large"></nz-time-picker>  -->
            <input [ngxTimepicker]="starttimepicker" readonly class="form-control"
              style="height: 5px;border-radius: 3px;" [format]="24" [(ngModel)]="targetAmount" />
            <ngx-material-timepicker #starttimepicker></ngx-material-timepicker>
          </ng-container>

        </div>
        <div class="p-field p-col-1">
          <button mat-mini-fab color="accent" style="margin-top: 20px" (click)="addTargetEntry()">
            <i class="fa fa-plus" style="color: #fff"></i>
          </button>
        </div>
        <div class="p-field p-col-1">
          <button mat-mini-fab color="warn" title="Add Target Zero Value" style="margin-top: 20px"
            (click)="addZeroTargets()">
            <i class="fa fa-stop-circle" style="color: #fff"></i>
          </button>
        </div>
      </div>

      <div class="p-grid p-fluid">
        <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="dataArray"
          [columnDefs]="columnDefsMonth" (cellValueChanged)="onCellValueChanged($event)" [gridOptions]="gridOptions"
          (cellClicked)="cellClicked($event)" [icons]="icons" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </mat-dialog-content>
  </ng-template>
  <ng-template #displayQuarterlyTarget>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{langObj ? langObj?.QuarterlyTargetInformation : ('QuarterlyTargetInformation' | translate)}}</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <div class="p-field p-col-3">
      <mat-form-field class="example-full-width" appearance="fill" style="display:none;">
        <mat-label>{{langObj ? langObj?.choosedate : ('choosedate' | translate)}}</mat-label>
        <input matInput [matDatepicker]="pickerforgrid" (dateInput)="gridDate($event)">
        <mat-datepicker-toggle matSuffix [for]="pickerforgrid"></mat-datepicker-toggle>
        <mat-datepicker touchUi #pickerforgrid></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-dialog-content>
      <div class="p-grid p-fluid">
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.Title : ('Title' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiTitle" disabled />
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.Frequency : ('Frequency' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpifrequency" disabled />
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.uom : ('uom' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiUom" disabled />
        </div>
        <div *ngIf="showCheckBox" class="p-field p-col-3 d-flex flex-column justify-content-center align-items-center">
          <label for="integeronly" style="text-align: center">{{langObj ? langObj?.AutoTarget : ('AutoTarget' |
            translate)}}</label>
          <mat-checkbox color="accent" [(ngModel)]="kpiautotarget"></mat-checkbox>
        </div>
      </div>
      <div class="p-grid p-fluid" *ngIf="viewKpiInformation">
        <div class="p-field p-col-3">
          <label for="integeronly" class="required">{{langObj ? langObj?.TargetDate : ('TargetDate' |
            translate)}}</label>
          <p-calendar [locale]="calendar_en" inputId="basic" [disabledDays]="dayOffList" dateFormat="dd/mm/yy"
            [(ngModel)]="targetDate" [minDate]="minDate" [maxDate]="maxDate" [monthNavigator]="true"
            [yearNavigator]="true" yearRange="2015:2050" inputId="navigators"></p-calendar>
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.LastActualValue : ('LastActualValue' | translate)}}</label>
          <input id="float-input" disabled pInputText value="0" />
        </div>
        <div class="p-field p-col-3">
          <!-- <label for="integeronly" class="required">Target Value</label>
        <input
          autocomplete="off"
          [(ngModel)]="targetAmount"
          (input)="onTargetValueChange($event)"
          pInputText
        /> -->

          <ng-container *ngIf="datatype === 1;">
            <label for="integeronly" class="required">{{langObj ? langObj?.TargetValue : ('TargetValue' |
              translate)}}</label>
            <input autocomplete="off" [(ngModel)]="targetAmount" (input)="onTargetValueChange($event)" pInputText />
          </ng-container>

          <ng-container *ngIf="datatype === 2">
            <mat-form-field class="example-full-width" appearance="outline" style="margin-top: 26px;">
              <mat-label>{{langObj ? langObj?.Date : ('Date' | translate)}}</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="targetAmount" style=" margin-top: -8px;">
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="datatype === 3">
            <label for="integeronly" class="required">{{langObj ? langObj?.Time : ('Time' | translate)}}</label>
            <!-- <nz-time-picker [nzOpen]="true" [(ngModel)]="time" (ngModelChange)="log($event)" nzSize="large"></nz-time-picker>  -->
            <input [ngxTimepicker]="starttimepicker" readonly class="form-control"
              style="height: 5px;border-radius: 3px;" [format]="24" [(ngModel)]="targetAmount" />
            <ngx-material-timepicker #starttimepicker></ngx-material-timepicker>
          </ng-container>
        </div>



        <div class="p-field p-col-1">
          <button mat-mini-fab color="accent" style="margin-top: 20px" (click)="addTargetEntry()">
            <i class="fa fa-plus" style="color: #fff"></i>
          </button>
        </div>
        <div class="p-field p-col-1">
          <button mat-mini-fab color="warn" title="Add Target Zero Value" style="margin-top: 20px"
            (click)="addZeroTargets()">
            <i class="fa fa-stop-circle" style="color: #fff"></i>
          </button>
        </div>


      </div>
      <div class="p-grid p-fluid">
        <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="dataArray"
          [columnDefs]="columnDefsQuarter" [components]="components" (cellValueChanged)="onCellValueChanged($event)"
          [gridOptions]="gridOptions" (cellClicked)="cellClicked($event)" [icons]="icons"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </mat-dialog-content>
  </ng-template>
  <ng-template #visibleWeeklyTarget>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{ modalTitle }} {{langObj ? langObj?.TargetInformation : ('TargetInformation' | translate)}}</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>

    </div>

    <div class="p-field p-col-3">
      <mat-form-field class="example-full-width" appearance="fill" style="display:none;">
        <mat-label>{{langObj ? langObj?.choosedate : ('choosedate' | translate)}}</mat-label>
        <input matInput [matDatepicker]="pickerforgrid" (dateInput)="gridDate($event)">
        <mat-datepicker-toggle matSuffix [for]="pickerforgrid"></mat-datepicker-toggle>
        <mat-datepicker touchUi #pickerforgrid></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-dialog-content>
      <div class="p-grid p-fluid">
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.Title : ('Title' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiTitle" disabled />
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.Frequency : ('Frequency' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpifrequency" disabled />
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.uom : ('uom' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiUom" disabled />
        </div>
        <div *ngIf="showCheckBox" class="p-field p-col-3 d-flex flex-column justify-content-center align-items-center">
          <label for="integeronly" style="text-align: center">Auto Target</label>
          <mat-checkbox color="accent" [(ngModel)]="kpiautotarget"></mat-checkbox>
        </div>
      </div>
      <div class="p-grid p-fluid" *ngIf="viewKpiInformation">
        <div class="p-field p-col-3">
          <label for="integeronly" class="required">{{langObj ? langObj?.TargetDate : ('TargetDate' |
            translate)}}</label>
          <p-calendar [locale]="calendar_en" inputId="basic" [disabledDays]="dayOffList" dateFormat="dd/mm/yy"
            [(ngModel)]="targetDate" [minDate]="minDate" [maxDate]="maxDate" [monthNavigator]="true"
            [yearNavigator]="true" yearRange="2015:2050" inputId="navigators"></p-calendar>
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.LastActualValue : ('LastActualValue' | translate)}}</label>
          <input id="float-input" disabled pInputText value="0" />
        </div>
        <div class="p-field p-col-3">
          <!-- <label for="integeronly" class="required">Target Value</label> -->
          <!-- <p-inputNumber
          autocomplete="off"
          [(ngModel)]="targetAmount"
          inputId="locale-user"
          [minFractionDigits]="2"
        >
        </p-inputNumber> -->

          <ng-container *ngIf="datatype === 1;">
            <label for="integeronly" class="required">{{langObj ? langObj?.TargetValue : ('TargetValue' |
              translate)}}</label>
            <p-inputNumber autocomplete="off" [(ngModel)]="targetAmount" inputId="locale-user" [minFractionDigits]="2">
            </p-inputNumber>
          </ng-container>

          <ng-container *ngIf="datatype === 2">

            <mat-form-field class="example-full-width" appearance="outline" style="margin-top: 26px;">
              <mat-label>{{langObj ? langObj?.Date : ('Date' | translate)}}</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="targetAmount" style=" margin-top: -8px;">
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

          </ng-container>
          <ng-container *ngIf="datatype === 3">
            <label for="integeronly" class="required">{{langObj ? langObj?.Time : ('Time' | translate)}}</label>
            <!-- <nz-time-picker [(ngModel)]="time" (ngModelChange)="log($event)" nzSize="large"></nz-time-picker>  -->
            <input [ngxTimepicker]="starttimepicker" readonly class="form-control"
              style="height: 5px;border-radius: 3px;" [format]="24" [(ngModel)]="targetAmount" />
            <ngx-material-timepicker #starttimepicker></ngx-material-timepicker>
          </ng-container>
        </div>
        <div class="p-field p-col-1">
          <button mat-mini-fab color="accent" style="margin-top: 20px" (click)="addTargetEntry()">
            <i class="fa fa-plus" style="color: #fff"></i>
          </button>
        </div>
        <div class="p-field p-col-1">
          <button mat-mini-fab color="warn" title="Add Target Zero Value" style="margin-top: 20px"
            (click)="addZeroTargets()">
            <i class="fa fa-stop-circle" style="color: #fff"></i>
          </button>
        </div>


      </div>
      <div class="p-grid p-fluid">
        <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="dataArray"
          [columnDefs]="columnDefsWeek" [components]="components" (cellValueChanged)="onCellValueChanged($event)"
          [gridOptions]="gridOptions" (cellClicked)="cellClicked($event)" [icons]="icons"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </mat-dialog-content>
  </ng-template>
  <ng-template #visibleDailyTarget>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{langObj ? langObj?.DailyTargetInformation : ('DailyTargetInformation' | translate)}}</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <div class="p-field p-col-3">
      <mat-form-field class="example-full-width" appearance="fill" style="display:none;">
        <mat-label>{{langObj ? langObj?.choosedate : ('choosedate' | translate)}}</mat-label>
        <input matInput [matDatepicker]="pickerforgrid" (dateInput)="gridDate($event)">
        <mat-datepicker-toggle matSuffix [for]="pickerforgrid"></mat-datepicker-toggle>
        <mat-datepicker touchUi #pickerforgrid></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-dialog-content>
      <div class="p-grid p-fluid">
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.Title : ('Title' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiTitle" disabled />
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.Frequency : ('Frequency' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpifrequency" disabled />
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.uom : ('uom' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiUom" disabled />
        </div>
        <div *ngIf="showCheckBox" class="p-field p-col-3 d-flex flex-column justify-content-center align-items-center">
          <label for="integeronly" style="text-align: center">{{langObj ? langObj?.AutoTarget : ('AutoTarget' |
            translate)}}</label>
          <mat-checkbox color="accent" [(ngModel)]="kpiautotarget"></mat-checkbox>
        </div>
      </div>
      <div class="p-grid p-fluid" *ngIf="viewKpiInformation">
        <div class="p-field p-col-3">
          <label for="integeronly" class="required">{{langObj ? langObj?.TargetDate : ('TargetDate' |
            translate)}}</label>
          <p-calendar [locale]="calendar_en" inputId="basic" [disabledDays]="dayOffList" dateFormat="dd/mm/yy"
            [(ngModel)]="targetDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2015:2050"
            inputId="navigators"></p-calendar>
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.LastActualValue : ('LastActualValue' | translate)}}</label>
          <input id="float-input" disabled pInputText value="0" />
        </div>
        <div class="p-field p-col-3">
          <!-- <label for="integeronly" class="required">Target Value</label>
        <p-inputNumber
          autocomplete="off"
          [(ngModel)]="targetAmount"
          inputId="locale-user"
          [minFractionDigits]="2"
        >
        </p-inputNumber> -->

          <ng-container *ngIf="datatype === 1;">
            <label for="integeronly" class="required">{{langObj ? langObj?.TargetValue : ('TargetValue' |
              translate)}}</label>
            <p-inputNumber autocomplete="off" [(ngModel)]="targetAmount" inputId="locale-user" [minFractionDigits]="2">
            </p-inputNumber>
          </ng-container>

          <ng-container *ngIf="datatype === 2">
            <mat-form-field class="example-full-width" appearance="outline" style="margin-top: 26px;">
              <mat-label>{{langObj ? langObj?.Date : ('Date' | translate)}}</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="targetAmount" style=" margin-top: -8px;">
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

          </ng-container>

          <ng-container *ngIf="datatype === 3">
            <label for="integeronly" class="required">{{langObj ? langObj?.Time : ('Time' | translate)}}</label>
            <!-- <nz-time-picker [(ngModel)]="time"(ngModelChange)="log($event)" nzSize="large"></nz-time-picker>  -->
            <input [ngxTimepicker]="starttimepicker" readonly class="form-control"
              style="height: 5px;border-radius: 3px;" [format]="24" [(ngModel)]="targetAmount" />
            <ngx-material-timepicker #starttimepicker></ngx-material-timepicker>
          </ng-container>
        </div>
        <div class="p-field p-col-1">
          <button mat-mini-fab color="accent" style="margin-top: 20px" (click)="addTargetEntry()">
            <i class="fa fa-plus" style="color: #fff"></i>
          </button>
        </div>
        <div class="p-field p-col-1">
          <button mat-mini-fab color="warn" title="Add Target Zero Value" style="margin-top: 20px"
            (click)="addZeroTargets()">
            <i class="fa fa-stop-circle" style="color: #fff"></i>
          </button>
        </div>


      </div>
      <div class="p-grid p-fluid">
        <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="dataArray"
          [columnDefs]="columnDefsDay" [components]="components" (cellValueChanged)="onCellValueChanged($event)"
          [gridOptions]="gridOptions" (cellClicked)="cellClicked($event)" [icons]="icons"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </mat-dialog-content>
  </ng-template>

  <ng-template #visibleShiftlyTarget>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>{{langObj ? langObj?.ShiftlyTargetInformation : ('ShiftlyTargetInformation' | translate)}}</h2>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>

    <div class="p-field p-col-3">
      <mat-form-field class="example-full-width" appearance="fill" style="display:none;">
        <mat-label>{{langObj ? langObj?.choosedate : ('choosedate' | translate)}}</mat-label>
        <input matInput [matDatepicker]="pickerforgrid" (dateInput)="gridDate($event)">
        <mat-datepicker-toggle matSuffix [for]="pickerforgrid"></mat-datepicker-toggle>
        <mat-datepicker touchUi #pickerforgrid></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-dialog-content>
      <div class="p-grid p-fluid">
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.Target : ('Target' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiTitle" disabled />
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.Frequency : ('Frequency' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpifrequency" disabled />
        </div>
        <div class="p-field p-col-3">
          <label for="integeronly">{{langObj ? langObj?.uom : ('uom' | translate)}}</label>
          <input id="disabled-input" type="text" pInputText [(ngModel)]="kpiUom" disabled />
        </div>
        <div *ngIf="showCheckBox" class="p-field p-col-3 d-flex flex-column justify-content-center align-items-center">
          <label for="integeronly" style="text-align: center">{{langObj ? langObj?.AutoTarget : ('AutoTarget' |
            translate)}}</label>
          <mat-checkbox color="accent" [(ngModel)]="kpiautotarget"></mat-checkbox>
        </div>
      </div>
      <div class="p-grid p-fluid" *ngIf="viewKpiInformation">
        <div class="p-field p-col-3">
          <label for="integeronly" class="required">{{langObj ? langObj?.TargetDate : ('TargetDate' |
            translate)}}</label>
          <p-calendar [locale]="calendar_en" inputId="basic" [disabledDays]="dayOffList" dateFormat="dd/mm/yy"
            [(ngModel)]="targetDate" [minDate]="minDate" [maxDate]="maxDate" [monthNavigator]="true"
            [yearNavigator]="true" yearRange="2015:2050" inputId="navigators"></p-calendar>
          <!-- <p-calendar [(ngModel)]="targetDate" [minDate]="minDate" [maxDate]="maxDate" [readonlyInput]="true" inputId="min-max"></p-calendar> -->
        </div>
        <div class="p-field p-col-2">
          <label for="integeronly">{{langObj ? langObj?.LastActualValue : ('LastActualValue' | translate)}}</label>
          <input id="float-input" disabled pInputText value="0" />
        </div>
        <div class="p-field p-col-2">
          <!-- <label for="integeronly" class="required">Target Value</label>
        <p-inputNumber
          autocomplete="off"
          [(ngModel)]="targetAmount"
          inputId="locale-user"
          [minFractionDigits]="2">
        </p-inputNumber> -->

          <ng-container *ngIf="datatype === 1;">
            <label for="integeronly" class="required">{{langObj ? langObj?.TargetValue : ('TargetValue' |
              translate)}}</label>
            <p-inputNumber autocomplete="off" [(ngModel)]="targetAmount" inputId="locale-user" [minFractionDigits]="2">
            </p-inputNumber>
          </ng-container>

          <ng-container *ngIf="datatype === 2">
            <mat-form-field class="example-full-width" appearance="outline" style="margin-top: 26px;">
              <mat-label>{{langObj ? langObj?.Date : ('Date' | translate)}}</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="targetAmount" style=" margin-top: -8px;">
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

          </ng-container>

          <ng-container *ngIf="datatype === 3">
            <label for="integeronly" class="required">{{langObj ? langObj?.Time : ('Time' | translate)}}</label>
            <input [ngxTimepicker]="starttimepicker" readonly class="form-control"
              style="height: 5px;border-radius: 3px;" [format]="24" [(ngModel)]="targetAmount" />
            <ngx-material-timepicker #starttimepicker></ngx-material-timepicker>
          </ng-container>

        </div>
        <div class="p-field p-col-2">
          <label class="required">{{langObj ? langObj?.Shift : ('Shift' | translate)}}</label>
          <p-dropdown [options]="shifts" [(ngModel)]="shift" optionLabel="shiftName" placeholder="Select Shift">
          </p-dropdown>
        </div>
        <div class="p-field p-col-1">
          <button mat-mini-fab color="accent" pTooltip="Add Target" matTooltipPosition="right" style="margin-top: 20px"
            (click)="addTargetEntry()">
            <i class="fa fa-plus" style="color: #fff"></i>
          </button>
        </div>
        <div class="p-field p-col-1">
          <button mat-mini-fab color="warn" pTooltip="Add Target Zero Value" matTooltipPosition="right"
            style="margin-top: 20px" (click)="addZeroTargets()">
            <i class="fa fa-stop-circle" style="color: #fff"></i>
          </button>
        </div>



      </div>
      <div class="p-grid p-fluid">
        <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="dataArray"
          [columnDefs]="columnDefsShift" [components]="components" (cellValueChanged)="onCellValueChanged($event)"
          [gridOptions]="gridOptions" (cellClicked)="cellClicked($event)" [icons]="icons"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>

      </div>
    </mat-dialog-content>
  </ng-template>

  <!-- </form>
</div>  -->
  <ng-template #ImportDialog style="padding: 0px">
    <div>
      <h1 mat-dialog-title>{{langObj ? langObj?.UploadExcel : ('UploadExcel' | translate)}}</h1>
      <div mat-dialog-content>
        <div class="d-flex">
          <div class="flex-1">
            <button mat-flat-button color="accent" class="import" (click)="openFile()">
              {{langObj ? langObj?.UploadExcel : ('UploadExcel' | translate)}}
            </button>
            <input type="file" (change)="uploadedFile($event)" (click)="$event.target.value = null" accept=".xlsx, .csv"
              class="d-none" #fileInput />
          </div>
          <div>
            <button mat-flat-button color="accent" type="button" class="w-22">
              <a class="sample" href="assets/xlsx/Export_KPI_ActualEntry (5).xlsx" download>{{langObj ?
                langObj?.SampleExcel : ('SampleExcel' | translate)}} <i class="fa fa-download"
                  aria-hidden="true"></i></a>
            </button>
          </div>
        </div>
      </div>
      <div mat-dialog-actions class="mat-dialog-actions">
        <button mat-button class="dialog-cancel-btn" (click)="onCancelClick()">
          {{langObj ? langObj?.Cancel : ('Cancel' | translate)}}
        </button>
        <!-- <button mat-button class="dialog-save-btn" (click)="onSaveClick()" cdkFocusInitial>Save</button> -->
      </div>
    </div>
  </ng-template>
</div>


<ng-template #KPIUploaderModal>
  <div>
    <h1 mat-dialog-title>{{langObj ? langObj?.UploadExcel : ('UploadExcel' | translate)}}</h1>
    <div mat-dialog-content>
      <div class="d-flex">
        <div class="flex-1">
          <button mat-flat-button color="accent" class="import" (click)="openFile()" class="w-22">
            {{langObj ? langObj?.UploadExcel : ('UploadExcel' | translate)}}
            <i class="fa fa-upload" aria-hidden="true"></i>
          </button>
          <input type="file" (change)="uploadedFile($event)" (click)="$event.target.value = null" accept=".xlsx, .csv"
            class="d-none" #fileInput />
        </div>
        <div>
          <button mat-flat-button color="accent" type="button" class="w-22">
            <a class="sample" href="assets/xlsx/KPI_Tree_Bulk_Enteries.xlsx" download>{{langObj ?
              langObj?.SampleExcel : ('SampleExcel' | translate)}} <i class="fa fa-download" aria-hidden="true"></i></a>
          </button>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="mat-dialog-actions">
      <button mat-button class="dialog-cancel-btn" (click)="onCancelClick()">
        {{langObj ? langObj?.Cancel : ('Cancel' | translate)}}
      </button>
      <!-- <button mat-button class="dialog-save-btn" (click)="onSaveClick()" cdkFocusInitial>Save</button> -->
    </div>
  </div>
</ng-template>

<ng-template #kpiHistoryModal style="width:100%">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
      <h2>KPI History</h2>
      <button type="button" mat-mini-fab mat-button color="warn" title="close" [mat-dialog-close]="true">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>


  <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
    <ag-grid-angular #KPIHistoryGrid id="KPIHistoryGrid" style="width: 100%;" class="ag-theme-alpine"
      [rowData]="historygridRowData" [columnDefs]="KpiHistoryColumnsDefs" [pagination]="true" [paginationPageSize]="5"
      (gridReady)="KPIHistoryOnGridReady($event)">
    </ag-grid-angular>
  </div>


</ng-template>