import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagerExecutionService {
  managerData: any;
  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  fileteredData: any = [];
  UserData: any;
  From: any;
  To: any;
  ManagerHeaderData: any = { Group: '', Plant: '', FromDate: '', ToDate: '' }
  constructor(private http: HttpClient) { }

  setManagerHeaderData(data: any) {
    this.ManagerHeaderData.Group = data.Group
    this.ManagerHeaderData.Plant = data.Plant
    this.ManagerHeaderData.FromDate = data.FromDate
    this.ManagerHeaderData.ToDate = data.ToDate
  }
  getManagerHeaderData() {
    return this.ManagerHeaderData;
  }
  setFromDate(date: any) {
    this.From = date;
  }

  getFromDate() {
    return this.From;
  }

  setToDate(date: any) {
    this.To = date;
  }

  getToDate() {
    return this.To;
  }

  setManagerData(data: any) {
    this.managerData = data;
  }

  getManagerData() {
    return this.managerData;
  }

  setUserData(data: any) {
    this.UserData = data;
  }

  getUserData() {
    return this.UserData;
  }

  getOperatorData(data: any): any {
    return this.http.get<any>(this.o3CoreApiUrl + 'PMManagerExecution/showManagerExecutionWO', { params: data });
  }

  getUserList(): any {
    return this.http.post<any>(this.o3CoreApiUrl + 'Account/GetUserList', []);
  }

  getTechnicianBySkill(data: any) {
    return this.http.get<any>(this.baseUrl + 'getTechnicianBySkill', { params: data });
  }
  addManagerData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PMManagerExecution/AddManagerExecutionWO', data);
  }
  updateManagerData(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.o3CoreApiUrl + 'PMManagerExecution/updateManagerExecutionWO', data);
  }


}
