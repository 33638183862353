import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { OpmsGlassService } from '../../services/opms-glass.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-opms-daily-consuption',
  templateUrl: './opms-daily-consuption.component.html',
  styleUrls: ['./opms-daily-consuption.component.scss']
})
export class OpmsDailyConsuptionComponent implements OnInit {
  dailyConsumptionGroup!: FormGroup;
  panelOpenState = false;
  rowDailyConsumption: any = [];
  Date!: Date;
  defaultColDefDailyConsumption: any = {
    resizable: true,
    sortable: true,
    filter: true,
  };
  columnDefsDailyConsumption: ColDef[] = [
    { headerName: 'Time', field: 'Time' , width: 120},
    { headerName: 'Date', field: 'Date', valueFormatter: this.dateFormatter, width: 120},
    { headerName: 'Box Type', field: 'BoxType', width: 120 },
    { headerName: 'Length', field: 'Length', width: 120 },
    { headerName: 'Width', field: 'Width', width: 120 },
    { headerName: 'Boxes', field: 'Boxes', width: 120 },
    { headerName: 'Quality', field: 'Quality', width: 120 },
    { headerName: 'Item Code', field: 'ItemCode', width: 120 },
    { headerName: 'Lites', field: 'Lites', width: 120 },
    { headerName: 'Country', field: 'Country', width: 120 },
    { headerName: 'Replaced With', field: 'ReplaceWith', width: 120 },
  ];

  constructor(
    private glassServiceObj: OpmsGlassService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
  ) { }




  ngOnInit(): void {
    this.dailyConsumptionGroup = this.fb.group({
      Date: [this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm')]
    });
    this.GetDailyConsumption();
  }


  GetDailyConsumption(): void {
    const key = {
      UserId: JSON.parse(localStorage.user).UserId,
      Date: moment(this.dailyConsumptionGroup.value.Date).format(
        'YYYY-MM-DD'
      ),
      IsHistory: 0
    };

    this.glassServiceObj.GetDailyConsumption(key).subscribe(
      (response: any) => {
        this.rowDailyConsumption = response.GetDCList;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  dateFormatter(params: any): any {
    return moment(params.value).format('MM/DD/YYYY hh:mm a') ;
  }
  onFirstDataRendered(params: any){
    // const allColumnIds: any[] = [];
    // (params.columnApi).getAllColumns().forEach((column: any) => {
    //   allColumnIds.push(column.colId);
    // });
    // (params.columnApi).autoSizeColumns(allColumnIds, false);
    params.api.sizeColumnsToFit();
  }

}
