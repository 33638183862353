<section>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
    <p style="color: white">Fetching Data...</p>
</ngx-spinner>
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="displayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">OPMS Dashboard </h1>
    </div>
  </div>

  <form [formGroup]="dbData">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row">
        <div class="inputField">
          <nz-date-picker class="calender-input" id="picker" (ngModelChange)="LoadBaseDetails()"
            formControlName="startDate" [nzDisabledDate]="" nzFormat="yyyy-MM-dd"></nz-date-picker>
          <span class="floating-text" style="margin-left: 10px;">Start Date</span>
        </div>

        <div class="inputField">
          <nz-date-picker class="calender-input" id="picker" (ngModelChange)="LoadBaseDetails()" formControlName="endDate"
            [nzDisabledDate]="" nzFormat="yyyy-MM-dd"></nz-date-picker>
          <span class="floating-text" style="margin-left: 10px;">End Date</span>
        </div>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Shift</mat-label>
          <mat-select panelClass="testClass" formControlName="shift" name="Shift" (selectionChange)="LoadBaseDetails()"
            [disabled]="isShiftAvailable">
            <mat-option [value]="0">ALL</mat-option>
            <mat-option *ngFor="let s of shiftList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Crew</mat-label>
          <mat-select panelClass="testClass" formControlName="crew" name="Crew" (selectionChange)="loadDashBoard()"
          [disabled]="isShiftAvailable">
            <mat-option [value]="0">ALL</mat-option>
            <mat-option *ngFor="let s of crewList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-label>Thickness</mat-label>
          <mat-select panelClass="testClass" formControlName="thickness" name="Thickness" [disabled]="isShiftAvailable"
            (selectionChange)="loadDashBoard()">
            <mat-option [value]="0">ALL</mat-option>
            <mat-option *ngFor="let s of thicknessList" [value]="s.TypeId">{{s.TypeName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="content-top-row">
        <mat-form-field appearance="fill" class="field-fill" style="margin-left: 17px;">
          <mat-label>Screen Type</mat-label>
          <mat-select ngxMatSelect formControlName="screenType" [multiple]="true" [disabled]="isShiftAvailable">
            <mat-option (click)="selectAllItems()" value="0">All</mat-option>
            <mat-option (click)="checkForAll()" value="1">Dashboard</mat-option>
            <mat-option (click)="checkForAll()" value="2">Reports</mat-option>
          </mat-select>
        </mat-form-field>

        <button  mat-mini-fab mat-button color="primary" pTooltip="Get Print" tooltipPosition="top" (click)="getPrint()" 
          [disabled]="isShiftAvailable" style="margin-left: 10px; margin-top: 5px;">
          <mat-icon>print</mat-icon>
        </button>
      </div>
    </div>
  </form>

  <div class="header1" *ngIf="printHeader">
    <div class="header">
      <h2 style="color: white; padding-top: 15px; padding-left: 5px;">OPMS Yield Reports and Dashboard</h2>
    </div>
    <table class="headerTable" >
      <tr style="height: 60px;">
        <td><p class="filters">Start Date:  {{startDate}}</p></td>
        <td><p class="filters">End Date:  {{endDate}}</p></td>
        <td><p class="filters">Shift:  {{shift}}</p></td>
        <td><p class="filters">Crew:  {{crew}}</p></td>
        <td><p class="filters">Thickness:  {{thickness}}</p></td>
      </tr>
    </table>
  </div>

  <div [ngClass]="{'display': dashboard == 'block', 'displayNone': dashboard == 'none'}">
    <div class="p-grid p-fluid" style="margin-top: 10px">
      <div class="p-col-12 p-md-2 p-lg-2 p-xl-2 printCard">
        <div class="menu-items">
          <div class="menu-card-item chart-items ">
            <p class="title-text">Avg Pull</p>
            <p class="data-text">{{avgPull}}
            </p>
            <div class="menu-bottom-icon">
              <img class="img" [src]="menuBlueIconUrl" alt="{{menuBlueIconUrl}}">
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-2 p-lg-2 p-xl-2 printCard">
        <div class="menu-items">
          <div class="menu-card-item chart-items ">
            <p class="title-text">Avg Pack</p>
            <p class="data-text">{{avgPacked}}
            </p>
            <div class="menu-bottom-icon">
              <img class="img" [src]="menuGreenIconUrl" alt="{{menuGreenIconUrl}}">
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-2 p-lg-2 p-xl-2 printCard">
        <div class="menu-items">
          <div class="menu-card-item chart-items ">
            <p class="title-text">Pullton</p>
            <p class="data-text">{{pullton}}
            </p>
            <div class="menu-bottom-icon">
              <img class="img" [src]="menuRedIconUrl" alt="{{menuRedIconUrl}}">
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-2 p-lg-2 p-xl-2 printCard">
        <div class="menu-items">
          <div class="menu-card-item chart-items ">
            <p class="title-text">Packedton</p>
            <p class="data-text">{{packedTon}}
            </p>
            <div class="menu-bottom-icon">
              <img class="img" [src]="menuBlueIconUrl" alt="{{menuBlueIconUrl}}">
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-2 p-lg-2 p-xl-2 printCard">
        <div class="menu-items">
          <div class="menu-card-item chart-items ">
            <p class="title-text">Change Over</p>
            <p class="data-text">{{changeover}}
            </p>
            <div class="menu-bottom-icon">
              <img class="img" [src]="menuGreenIconUrl" alt="{{menuGreenIconUrl}}">
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-2 p-lg-2 p-xl-2 printCard">
        <div class="menu-items">
          <div class="menu-card-item chart-items ">
            <p class="title-text">Net Change</p>
            <p class="data-text">{{netchange}}
            </p>
            <div class="menu-bottom-icon">
              <img class="img" [src]="menuRedIconUrl" alt="{{menuRedIconUrl}}">
            </div>
          </div>
        </div>
      </div>
  
      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="Yield1">
        <div id="Yield"></div>
      </div>
  
      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="AvgYieldByThickness1">
        <div id="AvgYieldByThickness"></div>
      </div>
  
      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="YieldByCreName1">
        <div id="YieldByCreName"></div>
      </div>
  
      <div class="p-col-12 p-md-8 p-lg-8 p-xl-8">
        <div id="LossClassification"></div>
      </div>

      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="AvgTrimlossByThickness1">
        <div id="AvgTrimlossByThickness"></div>
      </div>

      <div class="p-col-12 p-md-8 p-lg-8 p-xl-8">
        <div id="WaterFallChart"></div>
      </div>

      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="AvgTrimLossCrewWise1">
        <div id="AvgTrimLossCrewWise"></div>
      </div>
  
      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="QuantityByTon1">
        <div id="QuantityByTon"></div>
      </div>
  
      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="QunatityByThickness1">
        <div id="QunatityByThickness"></div>
      </div>
  
      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="Ton/BoxType1">
        <div id="Ton/BoxType"></div>
      </div>

      <div class="p-col-12 p-md-12 p-lg-12 p-xl-12">
        <div id="ThicknessWisePacking"></div>
      </div>
  
      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="LossvsPacked1">
        <div id="LossvsPacked"></div>
      </div>
  
      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="NcrTypeWise1">
        <div id="NcrTypeWise"></div>
      </div>
  
      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="NcrStatusWise1">
        <div id="NcrStatusWise"></div>
      </div>

      <div class="p-col-12 p-md-12 p-lg-12 p-xl-12">
        <div id="DefectIdentification"></div>
      </div>

      <div class="p-col-12 p-md-12 p-lg-12 p-xl-12">
        <div id="HourlyDefect"></div>
      </div>

      <div class="p-col-12 p-md-12 p-lg-12 p-xl-12">
        <div id="ManualHourlyDefect"></div>
      </div>

      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="ZebraValue1">
        <div id="ZebraValue"></div>
      </div>

      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="BloomGrade1">
        <div id="BloomGrade"></div>
      </div>

      <div class="p-col-12 p-md-4 p-lg-4 p-xl-4" id="MuStrain(psi)1">
        <div id="MuStrain(psi)"></div>
      </div>
    </div>
  </div>

  <div id="print" [ngClass]="{'display': reports == 'block', 'displayNone': reports == 'none'}" style="margin-top: 10px;">
    <div style="width: 100%;">
      <div class="reportsName">
        <h3 style="color: white; padding-top: 10px; padding-left: 5px;">Production Report:</h3>
      </div>
      <table style="width: 100%;">
        <tr class="thRow">
          <th>Date</th>
          <th>Shift</th>
          <th>Crew</th>
          <th>Thickness</th>
          <th>Packed Ton</th>
          <th>Pull Ton</th>
          <th>Gross Width(,)</th>
          <th>Net Width(,)</th>
          <th>Production Time</th>
          <th>AT Packed</th>
          <th>Non AT Packed</th>
          <th>Loss</th>
          <th>Yield%</th>
        </tr>
        <div *ngIf="productionReportStatus" style="display: table-row-group;">
          <tr class="tdRow" *ngFor="let production of productionReportData">
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.Date}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.shift}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.Crew}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.Thickness}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.pack}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.pull}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.GrossWidth}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.NetWidth}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.ProductionTime}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.ATPacked}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.NonATPacked}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.loss}}</td>
            <td [ngClass]="{'resultRow': production.Date == 'Cummulative'}">{{production.yield}}</td>
          </tr>
        </div>
        <tr *ngIf="!productionReportStatus">
          <td colspan="13" style="text-align: center; font-weight: bold;">NO DATA MATCHING</td>
        </tr>
      </table>
  
      <div class="reportsName">
        <h3 style="color: white; padding-top: 10px; padding-left: 5px;">YieldLosses Report:</h3>
      </div>
      <table id="yieldTable" style="width: 100%;">
        <div *ngIf="yieldLossesReportStatus || printHeaderStatus" style="display: table-row-group;">
          <tr class="thRow">
            <th>Date</th>
            <th>Shift</th>
            <th>Crew</th>
            <th>Thk</th>
            <th>Total Loss</th>
            <th>WIP%</th>
            <th>Yield%</th>
            <th>Loss%</th>
            <th>Trim Loss%</th>
            <th>Sampl- ing%</th>
            <th>Cutt- ing%</th>
            <th>Break- age%</th>
            <th>C/O Loss%</th>
            <th>Preff%</th>
            <th>Float%</th>
            <th>Equi- pment %</th>
            <th>Satu- ration %</th>
            <th>Hand- ling%</th>
            <th>Re- work%</th>
            <th>Ware house%</th>
            <th>Pro- file%</th>
            <th>De- fect%</th>
          </tr>
        </div>
        <div *ngIf="!yieldLossesReportStatus && !printHeaderStatus" style="display: table-row-group;">
          <tr class="thRow">
            <th>Date</th>
            <th>Shift</th>
            <th>Crew</th>
            <th>Thk</th>
            <th>Total Loss</th>
            <th>WIP%</th>
            <th>Yield%</th>
            <th>Loss%</th>
            <th>Trimming%</th>
            <th>Sampling%</th>
            <th>Cutting%</th>
            <th>Breakage%</th>
            <th>C/O Loss%</th>
            <th>Preff%</th>
            <th>Float%</th>
            <th>Equipment%</th>
            <th>Saturation%</th>
            <th>Handling%</th>
            <th>Rework%</th>
            <th>Ware house%</th>
            <th>Profile%</th>
            <th>Defect%</th>
          </tr>
        </div>
        <div *ngIf="yieldLossesReportStatus" style="display: table-row-group;">
          <tr class="tdRow" *ngFor="let losses of YieldLossesReportData">
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.Date}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.shift}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.Crew}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.Thickness}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.totalLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.WIP}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.yield}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.loss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.trimming}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.samplingLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.cuttingLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.breakageLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.changeOverLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.prefLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.floatLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.equipmentLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.saturationLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.handlingLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.reworkLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.lineLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.profileLoss}}</td>
            <td [ngClass]="{'resultRow': losses.Date == 'Cummulative'}">{{losses.defectLoss}}</td>
          </tr>
        </div>
        <tr *ngIf="!yieldLossesReportStatus">
          <td colspan="22" style="text-align: center;font-weight: bold;">NO DATA MATCHING</td>
        </tr>
      </table>
  
      <div class="reportsNameComment">
        <h3 style="color: white; padding-top: 10px; padding-left: 5px;">YieldLossesComments Report:</h3>
      </div>
      <table style="width: 100%;">
        <tr class="thRow">
          <th style="width: 10%;">Date</th>
          <th style="width: 6%;">Shift</th>
          <th style="width: 6%;">Crew</th>
          <th style="width: 8%;">Thickness</th>
          <th>
            <table class="borderNone" style="width: 100%;">
              <tr class="thRow borderNone">
                <th class="borderNone" style="width: 20%; border-right: 1px solid black;">Yield Loss</th>
                <th class="borderNone">Comments(,)</th>
              </tr>
            </table>
          </th>
        </tr>
        <div *ngIf="yieldLossesCommentsReportStatus" style="display: table-row-group;">
          <tr class="tdRow" *ngFor="let lossesComments of YieldLossesCommentsReportData">
            <td>{{lossesComments.Date}}</td>
            <td>{{lossesComments.shift}}</td>
            <td>{{lossesComments.Crew}}</td>
            <td>{{lossesComments.Thickness}}</td>
            <td>
              <table class="borderNone" style="width: 100%;">
                <tr class="tdRow borderNone" style="padding: 0px; margin: 0px;" *ngFor="let comments of lossesComments.Comment | StringToArray : '*@*'">
                  <td class="borderNone" style="width: 20%; border-right: 1px solid black; border-bottom: 1px solid black;">{{comments | StringToArray : '`*`'}}</td>
                  <td class="borderNone" style="border-bottom: 1px solid black;">
                    <p style="margin: 0px;" *ngFor="let comment of comments | StringToArray : 'loss'">**{{comment}}</p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </div>
        <tr *ngIf="!yieldLossesCommentsReportStatus">
          <td colspan="5" style="text-align: center;font-weight: bold;">NO DATA MATCHING</td>
        </tr>
      </table>
  
      <div class="reportsName">
        <h3 style="color: white; padding-top: 10px; padding-left: 5px;">OPMS Events Report:</h3>
      </div>
      <table style="width: 100%;">
        <tr class="thRow">
          <th>Date</th>
          <th>Shift</th>
          <th>Crew</th>
          <th>Thickness</th>
          <th>Hot End Area</th>
          <th>Event Remarks</th>
        </tr>
        <div *ngIf="OPMSEventReportStatus" style="display: table-row-group;">
          <tr class="tdRow" *ngFor="let event of OPMSEventReportData">
            <td style="min-width: 75px;">{{event.Date}}</td>
            <td>{{event.shift}}</td>
            <td>{{event.Crew}}</td>
            <td>{{event.Thickness}}</td>
            <td style="min-width: 75px;">{{event.HotEndArea}}</td>
            <td>
              <p style="margin: 0px;" *ngFor="let events of event.Event | StringToArray : '*@*'">**{{events}}</p>
            </td>
          </tr>
        </div>
        <tr *ngIf="!OPMSEventReportStatus">
          <td colspan="13" style="text-align: center; font-weight: bold;">NO DATA MATCHING</td>
        </tr>
      </table>

      <div style="width: 100%; display: flex; flex-wrap: wrap;">
        <div style="width: 100%;">
          <div class="reportsName">
            <h3 style="color: white; padding-top: 10px; padding-left: 5px;">Hourly Defects Report:</h3>
          </div>
        </div>
        <table style="width: 70%;">
          <tr class="thRow">
            <th>Date</th>
            <th>Shift</th>
            <th>Defect_7</th>
            <th>Defect_8_9_10</th>
            <th>Defect_7_8_9_10</th>
            <th>Defect Name</th>
          </tr>
          <div *ngIf="defectReportStatus" style="display: table-row-group;">
            <tr class="tdRow" *ngFor="let defect of defectReportData">
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.Date}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.shift}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.Defect_7}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.Defect_8_9_10}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.Defect_7_8_9_10}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.DefectName}}</td>
            </tr>
          </div>
          <tr *ngIf="!defectReportStatus">
            <td colspan="6" style="text-align: center; font-weight: bold;">NO DATA MATCHING</td>
          </tr>
        </table>
  
        <table style="width: 25%; margin-left: 2%; height: fit-content;">
          <tr class="thRow">
            <th>Defect Name</th>
            <th>Defect Counts</th>
          </tr>
          <div *ngIf="defectCountReportStatus" style="display: table-row-group;">
            <tr class="tdRow" *ngFor="let defect of defectCountReportData">
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.DefectName}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.count}}</td>
            </tr>
          </div>
          <tr *ngIf="!defectCountReportStatus">
            <td colspan="2" style="text-align: center; font-weight: bold;">NO DATA MATCHING</td>
          </tr>
        </table>
      </div>

      <div style="width: 100%; display: flex; flex-wrap: wrap;">
        <div style="width: 100%;">
          <div class="reportsNameComment">
            <h3 style="color: white; padding-top: 10px; padding-left: 5px;">Manual Hourly Defects Report:</h3>
          </div>
        </div>
        <table style="width: 70%;">
          <tr class="thRow">
            <th>Date</th>
            <th>Shift</th>
            <th>Crew</th>
            <th>Thickness</th>
            <th>Defect Class</th>
            <th>Defect Name</th>
            <th>Defect Counts</th>
          </tr>
          <div *ngIf="manualDefectReportStatus" style="display: table-row-group;">
            <tr class="tdRow" *ngFor="let defect of manualDefectReportData">
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.Date}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.shift}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.crew}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.Thickness}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.Class}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.DefectName}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.count}}</td>
            </tr>
          </div>
          <tr *ngIf="!manualDefectReportStatus">
            <td colspan="6" style="text-align: center; font-weight: bold;">NO DATA MATCHING</td>
          </tr>
        </table>
  
        <table style="width: 25%; margin-left: 2%; height: fit-content;">
          <tr class="thRow">
            <th>Defect Name</th>
            <th>Defect Counts</th>
          </tr>
          <div *ngIf="manualDefectCountReportStatus" style="display: table-row-group;">
            <tr class="tdRow" *ngFor="let defect of manualDefectCountReportData">
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.DefectName}}</td>
              <td [ngClass]="{'resultRow': defect.Date == 'Cummulative'}">{{defect.count}}</td>
            </tr>
          </div>
          <tr *ngIf="!manualDefectCountReportStatus">
            <td colspan="2" style="text-align: center; font-weight: bold;">NO DATA MATCHING</td>
          </tr>
        </table>
      </div>
  
      <div class="reportsName">
        <h3 style="color: white; padding-top: 10px; padding-left: 5px;">Quality:</h3>
      </div>
      <table style="width: 100%;">
        <tr class="thRow">
          <th colspan="4" style="text-align: center;"></th>
          <th colspan="3" style="text-align: center;">Bloom Grade</th>
          <th colspan="4" style="text-align: center;">Zebra Values</th>
        </tr>
        <tr class="thRow">
          <th>Date</th>
          <th>Shift</th>
          <th>Crew</th>
          <th>Thickness</th>
          <th>L</th>
          <th>C</th>
          <th>R</th>
          <th>LE</th>
          <th>LC</th>
          <th>RC</th>
          <th>RE</th>
        </tr>
        <div *ngIf="bloomZebraReportStatus" style="display: table-row-group;">
          <tr class="tdRow" *ngFor="let bloomZebra of bloomZebraReportData">
            <td [ngClass]="{'resultRow': bloomZebra.Date == 'Cummulative'}">{{bloomZebra.Date}}</td>
            <td [ngClass]="{'resultRow': bloomZebra.Date == 'Cummulative'}">{{bloomZebra.shift}}</td>
            <td [ngClass]="{'resultRow': bloomZebra.Date == 'Cummulative'}">{{bloomZebra.Crew}}</td>
            <td [ngClass]="{'resultRow': bloomZebra.Date == 'Cummulative'}">{{bloomZebra.Thickness}}</td>
            <td [ngClass]="{'resultRow': bloomZebra.Date == 'Cummulative'}">{{bloomZebra.B_L}}</td>
            <td [ngClass]="{'resultRow': bloomZebra.Date == 'Cummulative'}">{{bloomZebra.B_C}}</td>
            <td [ngClass]="{'resultRow': bloomZebra.Date == 'Cummulative'}">{{bloomZebra.B_R}}</td>
            <td [ngClass]="{'resultRow': bloomZebra.Date == 'Cummulative'}">{{bloomZebra.Z_LE}}</td>
            <td [ngClass]="{'resultRow': bloomZebra.Date == 'Cummulative'}">{{bloomZebra.Z_LC}}</td>
            <td [ngClass]="{'resultRow': bloomZebra.Date == 'Cummulative'}">{{bloomZebra.Z_RC}}</td>
            <td [ngClass]="{'resultRow': bloomZebra.Date == 'Cummulative'}">{{bloomZebra.Z_RE}}</td>
          </tr>
        </div>
        <tr *ngIf="!bloomZebraReportStatus">
          <td colspan="11" style="text-align: center;font-weight: bold;">NO DATA MATCHING</td>
        </tr>
      </table>

      <table style="width: 100%; margin-top: 20px;">
        <tr class="thRow">
          <th colspan="4" style="text-align: center;"></th>
          <th colspan="6" style="text-align: center;">Mu Strain(psi)</th>
        </tr>
        <tr class="thRow">
          <th>Date</th>
          <th>Shift</th>
          <th>Crew</th>
          <th>Thickness</th>
          <th>LE</th>
          <th>LC</th>
          <th>C</th>
          <th>RC</th>
          <th>RE</th>
          <th>Average</th>
        </tr>
        <div *ngIf="muStrainReportStatus" style="display: table-row-group;">
          <tr class="tdRow" *ngFor="let muStrain of muStrainReportData">
            <td [ngClass]="{'resultRow': muStrain.Date == 'Cummulative'}">{{muStrain.Date}}</td>
            <td [ngClass]="{'resultRow': muStrain.Date == 'Cummulative'}">{{muStrain.shift}}</td>
            <td [ngClass]="{'resultRow': muStrain.Date == 'Cummulative'}">{{muStrain.Crew}}</td>
            <td [ngClass]="{'resultRow': muStrain.Date == 'Cummulative'}">{{muStrain.Thickness}}</td>            
            <td [ngClass]="{'resultRow': muStrain.Date == 'Cummulative'}">{{muStrain.LE}}</td>
            <td [ngClass]="{'resultRow': muStrain.Date == 'Cummulative'}">{{muStrain.LC}}</td>
            <td [ngClass]="{'resultRow': muStrain.Date == 'Cummulative'}">{{muStrain.C}}</td>
            <td [ngClass]="{'resultRow': muStrain.Date == 'Cummulative'}">{{muStrain.RC}}</td>
            <td [ngClass]="{'resultRow': muStrain.Date == 'Cummulative'}">{{muStrain.RE}}</td>
            <td [ngClass]="{'resultRow': muStrain.Date == 'Cummulative'}">{{muStrain.Average}}</td>
          </tr>
        </div>
        <tr *ngIf="!muStrainReportStatus">
          <td colspan="10" style="text-align: center;font-weight: bold;">NO DATA MATCHING</td>
        </tr>
      </table>
  
      <table style="width: 100%; margin-top: 20px;">
        <tr class="thRow">
          <th colspan="4" style="text-align: center;"></th>
          <th colspan="3" style="text-align: center;">Corrugation Grade</th>
        </tr>
        <tr class="thRow">
          <th>Date</th>
          <th>Shift</th>
          <th>Crew</th>
          <th>Thickness</th>
          <th>L</th>
          <th>C</th>
          <th>R</th>
        </tr>
        <div *ngIf="corrugationGradeReportStatus" style="display: table-row-group;">
          <tr class="tdRow" *ngFor="let corrugation of corrugationGradeReportData">
            <td [ngClass]="{'resultRow': corrugation.Date == 'Cummulative'}">{{corrugation.Date}}</td>
            <td [ngClass]="{'resultRow': corrugation.Date == 'Cummulative'}">{{corrugation.shift}}</td>
            <td [ngClass]="{'resultRow': corrugation.Date == 'Cummulative'}">{{corrugation.Crew}}</td>
            <td [ngClass]="{'resultRow': corrugation.Date == 'Cummulative'}">{{corrugation.Thickness}}</td>
            <td [ngClass]="{'resultRow': corrugation.Date == 'Cummulative'}">{{corrugation.L}}</td>
            <td [ngClass]="{'resultRow': corrugation.Date == 'Cummulative'}">{{corrugation.C}}</td>
            <td [ngClass]="{'resultRow': corrugation.Date == 'Cummulative'}">{{corrugation.R}}</td>
          </tr>
        </div>
        <tr *ngIf="!corrugationGradeReportStatus">
          <td colspan="7" style="text-align: center;font-weight: bold;">NO DATA MATCHING</td>
        </tr>
      </table>
  
      <table style="width: 100%; margin-top: 20px;">
        <tr class="thRow">
          <th colspan="4" style="text-align: center;"></th>
          <th colspan="3" style="text-align: center;">Other</th>
        </tr>
        <tr class="thRow">
          <th>Date</th>
          <th>Shift</th>
          <th>Crew</th>
          <th>Thickness</th>
          <th>MuStrain</th>
          <th>EdgeLighting</th>
          <th>DefectDensity</th>
        </tr>
        <div *ngIf="otherReportStatus" style="display: table-row-group;">
          <tr class="tdRow" *ngFor="let other of otherReportData">
            <td [ngClass]="{'resultRow': other.Date == 'Cummulative'}">{{other.Date}}</td>
            <td [ngClass]="{'resultRow': other.Date == 'Cummulative'}">{{other.shift}}</td>
            <td [ngClass]="{'resultRow': other.Date == 'Cummulative'}">{{other.Crew}}</td>
            <td [ngClass]="{'resultRow': other.Date == 'Cummulative'}">{{other.Thickness}}</td>
            <td [ngClass]="{'resultRow': other.Date == 'Cummulative'}">{{other.MuStrain}}</td>
            <td [ngClass]="{'resultRow': other.Date == 'Cummulative'}">{{other.EdgeLighting}}</td>
            <td [ngClass]="{'resultRow': other.Date == 'Cummulative'}">{{other.DefectDensity}}</td>
          </tr>
        </div>
        <tr *ngIf="!otherReportStatus">
          <td colspan="7" style="text-align: center;font-weight: bold;">NO DATA MATCHING</td>
        </tr>
      </table>
    </div>
  </div>

  <div [ngClass]="{'display': printCharts == 'block', 'displayNone': printCharts == 'none'}">
    <div class="p-grid p-fluid">
      <div class="p-col-6 p-md-6 p-lg-6 p-xl-6" id="LossClassification1">
        <div id="LossClassification2"></div>
      </div>

      <div class="p-col-6 p-md-6 p-lg-6 p-xl-6" id="WaterFallChart1">
        <div id="WaterFallChart2"></div>
      </div>

      <div class="p-col-6 p-md-6 p-lg-6 p-xl-6" id="ThicknessWisePacking1">
        <div id="ThicknessWisePacking2"></div>
      </div>

      <div class="p-col-6 p-md-6 p-lg-6 p-xl-6" id="DefectIdentification1">
        <div id="DefectIdentification2"></div>
      </div>

      <div class="p-col-6 p-md-6 p-lg-6 p-xl-6" id="HourlyDefect1">
        <div id="HourlyDefect2"></div>
      </div>

      <div class="p-col-6 p-md-6 p-lg-6 p-xl-6" id="ManualHourlyDefect1">
        <div id="ManualHourlyDefect2"></div>
      </div>
    </div>
  </div>

</section>