<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
</ngx-spinner>
<div class="sfdc-configuration-content">
    <div class="content-top-box">
        <ul class="breadcrumb">
            <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                    (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
            </li>
        </ul>
        <div class="tab-content-top-box">
            <h1 class="main-title">Reactive Maintenance Master</h1>
        </div>
    </div>
    <form [formGroup]="HeaderForm">
        <div class="wrapper-box wrapper-box-shadow table-wrapper">
            <div class="content-top-row content-top-row-order-processing">
                <div style="flex: 1;">
                    <!-- GROUP -->
                    <mat-form-field appearence="fill"
                        class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-select formControlName="Group" (selectionChange)="getPlants(HeaderData.Group)"
                            placeholder="Group" panelClass="testClass" name="group" [(ngModel)]="HeaderData.Group">
                            <input (keyup)="onGroupKey($event.target)" (change)="onGroupKey('')"
                                class="form-control w-100" placeholder="Search Group">
                            <mat-option *ngFor="let group of GroupList" [value]="group.id">
                                {{group.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Group is Required</mat-error>
                    </mat-form-field>
                    <!-- Plant -->
                    <mat-form-field appearence="fill"
                        class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                        <mat-select formControlName="Plant" [(ngModel)]="HeaderData.Plant" placeholder="Plant"
                            panelClass="testClass" name="plant" (selectionChange)="getDropdownData(2)">
                            <input (keyup)="onPlantKey($event.target)" (change)="onPlantKey('')"
                                class="form-control w-100" placeholder="Search Plant">
                            <mat-option *ngFor="let plant of PlantList" [value]="plant.id">
                                {{plant.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Plant is Required</mat-error>
                    </mat-form-field>
                </div>
                <div class="import_export">
                    <button [disabled]="!IsWrite" class="import" (click)="openDialog('RM')">
                        Import As Excel</button>
                    <button [disabled]="!IsWrite" class="export" (click)="ExportTOExcel('RM')">
                        Export As Excel</button>
                </div>
            </div>
        </div>

    </form>

    <!-- Accordian -->
    <mat-accordion>

        <!-- Work Order Type -->
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="acc1" class="disable_ripple_acc1">
                <mat-panel-title>
                    Work Order Type
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="wrapper-box wrapper-box-shadow table-wrapper">
                <form [formGroup]="WOTypeForm" #woFormDirective="ngForm" (ngSubmit)="WOTypeSubmit(woFormDirective)">
                    <div class="content-top-row" style="display: flex;">
                        <mat-form-field appearence="fill"
                            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                            <input matInput formControlName="Name" placeholder="Name" [(ngModel)]="WOType.Name">
                            <mat-error>Name is Required</mat-error>
                        </mat-form-field>
                        <button [disabled]="!IsWrite" type="submit" class="add-btn1" pTooltip="Save" tooltipPosition="right">
                            <img *ngIf="isEdit == false" src="assets/images/save.png" alt="" width="20px" height="20px">
                            <img *ngIf="isEdit == true" src="assets/images/edit.png" alt="" width="20px" height="20px">
                        </button>
                        <button type="button" class="add-btn1" pTooltip="reset" tooltipPosition="right" (click)="isEdit = false; WOType.Name = ''">
                            <img src="assets/images/refresh1.png" alt="" width="20px" height="20px">
                        </button>
                    </div>
                </form>
                <!--second row-->
                <div class="content-top-row" style="display: flex; justify-content: flex-end;">
                    <mat-form-field appearance="fill" class="field-fill">
                        <mat-label>Search</mat-label>
                        <input [readonly]="false" matInput (keyup)="applyWOTypeFilter($event)" class="input-text"
                            autocomplete="off" />
                    </mat-form-field>
                </div>

                <div class="content-row-flex-both-end" #WOTypeTable>
                    <!--table-->
                    <table mat-table #wotypetable class="basic-table plant-model-table" matSort
                        [dataSource]="WOTypeDataSource">

                        <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">S.NO</p>
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index">{{ (WOTypePage.pageIndex *
                                WOTypePage.pageSize) + i + 1}}</td>
                        </ng-container>

                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Name</p>
                            </th>
                            <td mat-cell *matCellDef="let element">{{element.Name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="CreatedAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Creation Date</p>
                            </th>
                            <td mat-cell *matCellDef="let element">{{element.CreatedAt | date: 'dd-MM-yyyy hh:mm:ss a'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="CreatedBy">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Created By</p>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.CreatedUser}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                <p class="header-text">Actions</p>
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index" class="row-action-btns-column">
                                <div class="row-action-btns">
                                    <button mat-icon-button [disabled]="!IsWrite" class="edit-btn" aria-label="Edit Row"
                                        (click)="editWOType(element)">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                                                fill="white" />
                                        </svg>
                                    </button>
                                    <button mat-icon-button [disabled]="!IsWrite" class="tree-view-btn"
                                        aria-label="View Row Tree" (click)="deleteWOType(element)">
                                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                                                fill="#F85439" />
                                            <circle cx="17" cy="17" r="14" fill="#F85439" />
                                            <path
                                                d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                                                fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="WOTypeColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: WOTypeColumns"></tr>
                    </table>
                </div>
                <div class="table-bottom">
                    <mat-paginator class="basic-paginataor" [length]="WOTypePage.length"
                        [pageIndex]="WOTypePage.pageIndex" [pageSizeOptions]="pageSizeOptions"
                        (page)="customePaginator($event,'wotype')" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </mat-expansion-panel>

        <!--fourth accordian-->
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="acc4" class="disable_ripple_acc4">
                <mat-panel-title>
                    Failure Mode
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="wrapper-box wrapper-box-shadow table-wrapper">
                <form [formGroup]="FailureModeForm" #fmFormDirective="ngForm"
                    (ngSubmit)="FailureModeOnSubmit(fmFormDirective)">
                    <div class="content-top-row d-flex">
                        <mat-form-field appearence="fill"
                            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                            <input matInput formControlName="FailureMode" placeholder="Failure Mode"
                                [(ngModel)]="FailureMode.Name">
                            <mat-error>Failure Mode is Required</mat-error>
                        </mat-form-field>
                        <mat-form-field appearence="fill"
                            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                            <mat-select placeholder="Work Order Type" [(ngModel)]="FailureMode.WOType"
                                formControlName="woType">
                                <mat-select-filter [placeholder]="'Search'" [displayMember]="'Name'" [array]="WOTypeList"
                                (filteredReturn)="WOTypeFiltList = $event">
                              </mat-select-filter>
                                <mat-option *ngFor="let woType of WOTypeFiltList" [value]="woType.ID">
                                    {{woType.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error>Work Order Type is Required</mat-error>
                        </mat-form-field>
                        <button [disabled]="!IsWrite" type="submit" class="add-btn1" pTooltip="Save" tooltipPosition="right">
                            <img *ngIf="edited == false" src="assets/images/save.png" alt="" width="20px" height="20px">
                            <img *ngIf="edited == true" src="assets/images/edit.png" alt="" width="20px" height="20px">
                        </button>
                        <button type="button" class="add-btn1" pTooltip="reset" tooltipPosition="right" (click)="edited = false; FailureMode.Name = ''; FailureMode.WOType = WOTypeList[0]?.ID">
                            <img src="assets/images/refresh1.png" alt="" width="20px" height="20px">
                        </button>
                    </div>
                </form>

                <div class="content-top-row" style="display: flex; justify-content: flex-end;">
                    <mat-form-field appearance="fill" class="field-fill">
                        <mat-label>Search</mat-label>
                        <input [readonly]="false" matInput (keyup)="applyFailureFilter($event)" class="input-text"
                            autocomplete="off" />
                    </mat-form-field>
                </div>

                <div class="content-row-flex-both-end" #FailureModeTable>
                    <!--table-->
                    <table mat-table #failuremodetable class="basic-table plant-model-table" matSort
                        [dataSource]="failureModeTableDataSource">

                        <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">S.NO</p>
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index">{{ (page.pageIndex * page.pageSize) +
                                i + 1}}</td>
                        </ng-container>

                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Failure Mode</p>
                            </th>
                            <td mat-cell *matCellDef="let element">{{element.Name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="WOType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Work Order Type</p>
                            </th>
                            <td mat-cell *matCellDef="let element">{{element.WOType ? element.WOType.Name : ''}}</td>
                        </ng-container>

                        <ng-container matColumnDef="CreatedAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Creation Date</p>
                            </th>
                            <td mat-cell *matCellDef="let element">{{element.CreatedAt | date: 'dd-MM-yyyy hh:mm:ss a'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="CreatedBy">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Created By</p>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.CreatedUser}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                <p class="header-text">Actions</p>
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index" class="row-action-btns-column">
                                <div class="row-action-btns">
                                    <button mat-icon-button [disabled]="!IsWrite" class="edit-btn" aria-label="Edit Row"
                                        (click)="editFailureRow(element)">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                                                fill="white" />
                                        </svg>
                                    </button>
                                    <button mat-icon-button [disabled]="!IsWrite" class="tree-view-btn"
                                        aria-label="View Row Tree" (click)="deleteFailureRow(element)">
                                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                                                fill="#F85439" />
                                            <circle cx="17" cy="17" r="14" fill="#F85439" />
                                            <path
                                                d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                                                fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="failuremodeColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: failuremodeColumns"></tr>
                    </table>
                </div>
                <div class="table-bottom">
                    <mat-paginator class="basic-paginataor" [length]="page.length" [pageIndex]="page.pageIndex"
                        [pageSizeOptions]="pageSizeOptions" (page)="customePaginator($event,'failuremode')" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </div>
        </mat-expansion-panel>

        <!-- Third Accordian -->
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="acc1" class="disable_ripple_acc1">
                <mat-panel-title>
                    Priority
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="wrapper-box wrapper-box-shadow table-wrapper">
                <form [formGroup]="PriorityForm" #priorityFormDirective="ngForm" (ngSubmit)="PrioritySubmit(priorityFormDirective)">
                    <div class="content-top-row" style="display: flex;">
                        <mat-form-field appearence="fill"
                            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                            <input matInput formControlName="Name" placeholder="Name" [(ngModel)]="Priority.Name">
                            <mat-error>Name is Required</mat-error>
                        </mat-form-field>
                        <button [disabled]="!IsWrite" type="submit" class="add-btn1" pTooltip="Save" tooltipPosition="right">
                            <img *ngIf="isEditPriority == false" src="assets/images/save.png" alt="" width="20px" height="20px">
                            <img *ngIf="isEditPriority == true" src="assets/images/edit.png" alt="" width="20px" height="20px">
                        </button>
                        <button type="button" class="add-btn1" pTooltip="reset" tooltipPosition="right" (click)="isEditPriority = false; Priority.Name = ''">
                            <img src="assets/images/refresh1.png" alt="" width="20px" height="20px">
                        </button>
                    </div>
                </form>
                <!--second row-->
                <div class="content-top-row" style="display: flex; justify-content: flex-end;">
                    <mat-form-field appearance="fill" class="field-fill">
                        <mat-label>Search</mat-label>
                        <input [readonly]="false" matInput (keyup)="applyPriorityFilter($event)" class="input-text"
                            autocomplete="off" />
                    </mat-form-field>
                </div>

                <div class="content-row-flex-both-end" #PriorityTable>
                    <!--table-->
                    <table mat-table #prioritytable class="basic-table plant-model-table" matSort
                        [dataSource]="PrioritySource">

                        <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">S.NO</p>
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index">{{ (PriorityPage.pageIndex *
                                PriorityPage.pageSize) + i + 1}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Name </p>
                            </th>
                            <td mat-cell *matCellDef="let element">{{element.Name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="CreatedAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Creation Date</p>
                            </th>
                            <td mat-cell *matCellDef="let element">{{element.CreatedAt | date: 'dd-MM-yyyy hh:mm:ss a'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="CreatedBy">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Created By</p>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.CreatedUser}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                <p class="header-text">Actions</p>
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index" class="row-action-btns-column">
                                <div class="row-action-btns">
                                    <button mat-icon-button [disabled]="!IsWrite" class="edit-btn" aria-label="Edit Row"
                                        (click)="editPriority(element)">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                                                fill="white" />
                                        </svg>
                                    </button>
                                    <button mat-icon-button [disabled]="!IsWrite" class="tree-view-btn"
                                        aria-label="View Row Tree" (click)="deletePriority(element)">
                                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                                                fill="#F85439" />
                                            <circle cx="17" cy="17" r="14" fill="#F85439" />
                                            <path
                                                d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                                                fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="PriorityColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: PriorityColumns"></tr>
                    </table>
                </div>
                <div class="table-bottom">
                    <mat-paginator class="basic-paginataor" [length]="PriorityPage.length"
                        [pageIndex]="PriorityPage.pageIndex" [pageSizeOptions]="pageSizeOptions"
                        (page)="customePaginator($event,'priority')" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </mat-expansion-panel>

        <!-- Cron Time Set Start-->
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="acc4" class="disable_ripple_acc4">
                <mat-panel-title>
                    Delay Time
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="wrapper-box wrapper-box-shadow table-wrapper">
                <form [formGroup]="TimeForm" #delayTimeFormDirective="ngForm" (ngSubmit)="TimeOnSubmit(delayTimeFormDirective)">
                    <div class="content-top-row" style="display: flex;">
                        <mat-form-field appearence="fill"
                            class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                            <input matInput type="number" formControlName="time" placeholder="Enter Number In Minutes"
                                [(ngModel)]="Time.TimeLimit">
                            <!-- <mat-error>Time Limit Is Required</mat-error> -->
                        </mat-form-field>
                        <p class="min">&nbsp;&nbsp;&nbsp; / Min &nbsp;</p>
                        <button [disabled]="!IsWrite" type="submit" class="add-btn1" pTooltip="Save" tooltipPosition="right">
                            <img *ngIf="editedTime == false" src="assets/images/save.png" alt="" width="20px" height="20px">
                            <img *ngIf="editedTime == true" src="assets/images/edit.png" alt="" width="20px" height="20px">
                        </button>
                        <button type="button" class="add-btn1" pTooltip="reset" tooltipPosition="right" (click)="editedTime = false; Time.TimeLimit = '';">
                            <img src="assets/images/refresh1.png" alt="" width="20px" height="20px">
                        </button>
                    </div>
                </form>

                <div class="content-top-row" style="display: flex; justify-content: flex-end;">
                    <mat-form-field appearance="fill" class="field-fill">
                        <mat-label>Search</mat-label>
                        <input [readonly]="false" matInput (keyup)="applyTimeFilter($event)" class="input-text"
                            autocomplete="off" />
                    </mat-form-field>
                </div>

                <div class="content-row-flex-both-end" #TimeTable>
                    <!--table-->
                    <table mat-table #timetable class="basic-table plant-model-table" matSort
                        [dataSource]="timeTableDataSource">

                        <ng-container matColumnDef="TimeLimit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Time By Minutes</p>
                            </th>
                            <td mat-cell *matCellDef="let element">{{element.TimeLimit}}</td>
                        </ng-container>

                        <ng-container matColumnDef="CreatedAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Creation Date</p>
                            </th>
                            <td mat-cell *matCellDef="let element">{{element.CreatedAt | date: 'dd-MM-yyyy hh:mm:ss a'}}</td>
                        </ng-container>
                        
                        <ng-container matColumnDef="CreatedBy">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="header-text">Created By</p>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.CreatedUser}}

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                <p class="header-text">Actions</p>
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index" class="row-action-btns-column">
                                <div class="row-action-btns">
                                    <button mat-icon-button [disabled]="!IsWrite" class="edit-btn" aria-label="Edit Row"
                                        (click)="editTimeRow(element)">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                                                fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="timeColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: timeColumns"></tr>
                    </table>
                </div>
                <div class="table-bottom">
                    <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]"
                        (page)="customePaginator($event,'time')" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </mat-expansion-panel>
        <!-- Cron Time Set End -->

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="acc1" class="disable_ripple_acc1">
                <mat-panel-title>
                    Technician
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="wrapper-box wrapper-box-shadow table-wrapper">
                <form [formGroup]="TechnicianForm">
                    <div class="content-top-row" style="display: flex;">
                        <mat-form-field class="selectTech">
                        <mat-label>Assign Technician</mat-label>
                            <mat-select ngxMatSelect #msUser="ngxMatSelect"  [displayMember]="'fullName'" [valueMember]="'UserId'" 
                                [source]="raci" [useInfiniteScroll]="true"  formControlName="Name"  [multiple]="true"
                                (openedChange)="rmChange($event)" [(ngModel)]="Technician.Name" [placeholder]="'Select Users'">
                                <mat-option  *ngFor="let user of msUser.filteredSource" [value]="user.UserId">
                                {{user.fullName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button [disabled]="!IsWrite" (click)="TechnicianSubmit()" class="add-btn1" pTooltip="Save"
                            tooltipPosition="right">
                            <img src="assets/images/save.png" alt="" width="20px" height="20px">
                        </button>
                    </div>
                </form>
            </div>
        </mat-expansion-panel>

    </mat-accordion>

</div>