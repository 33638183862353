<div mat-dialog-title style="margin-bottom: 4px !important;">
    <div class="d-flex justify-content-between align-items-center">
      <h3>Yield Detail Dashboard</h3>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" pTooltip="Close" tooltipPosition="top">
        <mat-icon>close</mat-icon>
      </button>
    </div>
</div>

<hr>

<form [formGroup]="yieldDetailData">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row">
        <mat-form-field appearance="fill" class="w-22">
          <mat-label>Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="startDate" placeholder="From Date"/>
            <input matEndDate formControlName="endDate" placeholder="To Date"/>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <button type="button" mat-button mat-mini-fab color="primary" (click)="getYieldDetailDayWise()" 
            pTooltip="Search Data" tooltipPosition="top" style="margin-left: 20px;">
            <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </form>

  <div class="wrapper-box wrapper-box-shadow table-wrapper">
    <div id="YieldDetail"></div>
  </div>