import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KpiintegrationService {
  
  NetApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }
  kpiIntegration(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.NetApiUrl + 'KPITree/KPIIntergration', data);
  }

  getKPIDataPoints(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.baseUrl + 'getkpidatapoints', data);
    return this.http.post<any[]>(this.NetApiUrl + 'KPITree/GetKPIDataPoints', data);
   
  }

  getKPIDataPointsByType(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.NetApiUrl + 'KPITree/GetKPIDataPointsByType', data);
  }

  getKPIDataSource(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.baseUrl + 'getallkpidatasources', data);
    return this.http.post<any[]>(this.NetApiUrl + 'KPIIntegration/GetKPIDataSource', data);
  }

  getKPIServiceType(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.baseUrl + 'getallkpiservicetypes', data);
    return this.http.post<any[]>(this.NetApiUrl + 'KPIIntegration/GetKPIServiceType', data);
  }

    getKPIO3Areas(data: any): Observable<any[]> {
  //   return this.http.post<any[]>(this.baseUrl + 'getallkpio3areas', data);
   
    return this.http.post<any[]>(this.NetApiUrl + 'KPIIntegration/GetKPIO3Areas', data);
    
  }

  addKPIintegration(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.baseUrl + 'addkpiintegration', data);
    return this.http.post<any[]>(this.NetApiUrl + 'KPIIntegration/AddKPIIntegration', data);
  }

  deleteKpiIntegration(data: any): Observable<any[]> {
    // return this.http.post<any[]>(this.baseUrl + 'deleteKpiIntegration', data);
    return this.http.post<any[]>(this.NetApiUrl + 'KPITree/DeleteKpiIntegration', data);
  }
}
