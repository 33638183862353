import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
@Injectable({
  providedIn: 'root'
})
export class DatepickerService extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    const storedWeekday = sessionStorage.getItem('weekDay');
    if (storedWeekday) {
      return parseInt(storedWeekday, 10);
    } else {
      // Provide a default value if the item is not set in sessionStorage
      return 0; // Sunday as the default first day of the week
    }
  }
}