import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { GlassPpcLiveGeneraldataService } from 'src/app/services/glass-ppc-live-generaldata.service';

@Component({
  selector: 'app-glass-ppc-live-generaldata',
  templateUrl: './glass-ppc-live-generaldata.component.html',
  styleUrls: ['./glass-ppc-live-generaldata.component.scss']
})
export class GlassPpcLiveGeneraldataComponent implements OnInit {

  private subscriptions = new Subscription();
  public breadcrumList: any;
  highChartsColor = [
    '#DC143C',
    '#00adad',
    '#3b2fb5',
    '#6D81A8',
    '#512E5F',
    '#C471A5',
    '#0034bb',
    '#0b774e',
    '#570000',
    '#0d98da',
    '#00002e',
    '#00a800',
    '#00a8a8',
    '#06573d',
    '#F80000',
    '#0d6e54',
    '#FFDF00',
    '#0692aa',
    '#611745',
    '#ff5252',
    '#ff52ff',
    '#08fa03',
    '#08FAC7',
    '#D208FA',
    '#FAEB08',
  ];
  netWidthChartOptions: any;
  grossWidthChartOptions: any;
  useableWidthChartOptions: any;
  lehrSpeedChartOptions: any;
  lineSpeedChartOptions: any;
  yieldChartOptions: any;
  nominalThicknessChartOptions: any;
  lastNetWidth = '';
  lastGrossWidth = '';
  lastUseableWidth = '';
  lastLehrSpeed = '';
  lastLineSpeed = '';
  lastYield = '';
  lastNominalThickness: any;
  showCards = false;
  data: any;
  chartNetWidth: any;
  chartGrossWidth: any;
  chartUseableWidth: any;
  chartLehrSpeed: any;
  chartLineSpeed: any;
  chartYield: any;
  chartNoinalThickness: any;
  menuRedIconUrl: string = 'assets/images/menu-bottom-red-icon.png';
  menuGreenIconUrl: string = 'assets/images/menu-bottom-green-icon.png';
  menuBlueIconUrl = 'assets/images/menu-bottom-blue-icon.png';
  // currentUrl: any;

  constructor(
    private spinner: NgxSpinnerService,
    private usermanagementService: UserManagementService,
    private glassPpcLiveService: GlassPpcLiveGeneraldataService,
    private toaster: ToastrService,
    public router: Router,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getBreadcrumList();
    this.getNetWidthChart();
  }

  getNetWidthChart(): void {
    // '2024-02-21 21:16:14'
    this.spinner.show();
    Highcharts.setOptions({
      time: {
          useUTC: false
      }
    });
    this.data = {
      TimeStamp:  new Date((new Date()).getTime() - (1000*60*60*8)),
      TransactionId: 1
    }
    // this.activeRoute.url.subscribe((url) => {
    //   this.currentUrl = url[0].path;
    // });
    this.glassPpcLiveService.GetNetWidth(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          var dataNetWidt = [];
          var dataGrossWidt = [];
          var dataUseableWidth = [];
          var dataLehrSpeed = [];
          var dataLineSpeed = [];
          var dataYield = [];
          var dataNominalThickness = [];
          if(response.result.length){
            for(var i = response.result.length; i > 0; i--){
              dataNetWidt.push([((new Date(response.result[i-1].time)).getTime()),response.result[i-1].NetWidth]);
              dataGrossWidt.push([((new Date(response.result[i-1].time)).getTime()),response.result[i-1].GrossWidth]);
              dataUseableWidth.push([((new Date(response.result[i-1].time)).getTime()),response.result[i-1].UseableWidth]);
              dataLehrSpeed.push([((new Date(response.result[i-1].time)).getTime()),parseFloat(response.result[i-1].LehrSpeed.toFixed(2))]);
              dataLineSpeed.push([((new Date(response.result[i-1].time)).getTime()),response.result[i-1].LineSpeed]);
              dataYield.push([((new Date(response.result[i-1].time)).getTime()),parseFloat(response.result[i-1].Yield.toFixed(2))]);
              dataNominalThickness.push([((new Date(response.result[i-1].time)).getTime()),response.result[i-1].NominalThickness]);
            }
            this.lastNetWidth = response.result[0].NetWidth;
            this.lastGrossWidth = response.result[0].GrossWidth;
            this.lastUseableWidth = response.result[0].UseableWidth;
            this.lastLehrSpeed = response.result[0].LehrSpeed.toFixed(2);
            this.lastLineSpeed = response.result[0].LineSpeed;
            this.lastYield = response.result[0].Yield.toFixed(2);
            this.lastNominalThickness = response.result[0].NominalThickness;
          }else{
            dataNetWidt = [];
            dataGrossWidt = [];
            dataUseableWidth = [];
            dataLehrSpeed = [];
            dataLineSpeed = [];
            dataYield = [];
            dataNominalThickness = [];
          }
          this.netWidthChartOptions = {
            chart: {
              type: 'spline',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Net Width'
            },
          
            subtitle: {
              text: 'Live Data For NetWidth'
            },
            xAxis: {
              type: 'datetime',
              tickPixelInterval: 100,
              title: {
                enabled: true,
                text: 'TimeStamp'
              },
            },
            yAxis: {
              title: {
                text: 'NetWidth'
              },
              labels: {
                enabled: false
              }
            },
            plotOptions: {
              spline: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            rangeSelector: {
              buttons: [{
                count: 1,
                type: 'minute',
                text: '1M'
              }, {
                count: 5,
                type: 'minute',
                text: '5M'
              }, {
                type: 'all',
                text: 'All'
              }],
              inputEnabled: false,
              selected: 0
            },
            legend: {
              enabled: false
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br/>',
              pointFormat: '{point.x}s: {point.y}'
            },
            series: [{
              name: 'NetWidth',
              data: dataNetWidt,
              color: '#003CEF'
            }]
          }
          this.grossWidthChartOptions = {
            chart: {
              type: 'spline',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Gross Width'
            },
          
            subtitle: {
              text: 'Live Data For GrossWidth'
            },
            xAxis: {
              type: 'datetime',
              tickPixelInterval: 100,
              title: {
                enabled: true,
                text: 'TimeStamp'
              },
            },
            yAxis: {
              title: {
                text: 'GrossWidth'
              },
              labels: {
                enabled: false
              }
            },
            plotOptions: {
              spline: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            rangeSelector: {
              buttons: [{
                count: 1,
                type: 'minute',
                text: '1M'
              }, {
                count: 5,
                type: 'minute',
                text: '5M'
              }, {
                type: 'all',
                text: 'All'
              }],
              inputEnabled: false,
              selected: 0
            },
            legend: {
              enabled: false
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br/>',
              pointFormat: '{point.x}s: {point.y}'
            },
            series: [{
              name: 'GrossWidth',
              data: dataGrossWidt,
              color: '#10CE9C'
            }]
          }
          this.useableWidthChartOptions = {
            chart: {
              type: 'spline',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Useable Width'
            },
          
            subtitle: {
              text: 'Live Data For UseableWidth'
            },
            xAxis: {
              type: 'datetime',
              tickPixelInterval: 100,
              title: {
                enabled: true,
                text: 'TimeStamp'
              },
            },
            yAxis: {
              title: {
                text: 'UseableWidth'
              },
              labels: {
                enabled: false
              }
            },
            plotOptions: {
              spline: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            rangeSelector: {
              buttons: [{
                count: 1,
                type: 'minute',
                text: '1M'
              }, {
                count: 5,
                type: 'minute',
                text: '5M'
              }, {
                type: 'all',
                text: 'All'
              }],
              inputEnabled: false,
              selected: 0
            },
            legend: {
              enabled: false
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br/>',
              pointFormat: '{point.x}s: {point.y}'
            },
            series: [{
              name: 'UseableWidth',
              data: dataUseableWidth,
              color: '#F85439'
            }]
          }
          this.lehrSpeedChartOptions = {
            chart: {
              type: 'spline',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Lehr Speed'
            },
          
            subtitle: {
              text: 'Live Data For LehrSpeed'
            },
            xAxis: {
              type: 'datetime',
              tickPixelInterval: 100,
              title: {
                enabled: true,
                text: 'TimeStamp'
              },
            },
            yAxis: {
              title: {
                text: 'LehrSpeed'
              },
              labels: {
                enabled: false
              }
            },
            plotOptions: {
              spline: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            rangeSelector: {
              buttons: [{
                count: 1,
                type: 'minute',
                text: '1M'
              }, {
                count: 5,
                type: 'minute',
                text: '5M'
              }, {
                type: 'all',
                text: 'All'
              }],
              inputEnabled: false,
              selected: 0
            },
            legend: {
              enabled: false
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br/>',
              pointFormat: '{point.x}s: {point.y}'
            },
            series: [{
              name: 'LehrSpeed',
              data: dataLehrSpeed,
              color: '#003CEF'
            }]
          }
          this.lineSpeedChartOptions = {
            chart: {
              type: 'spline',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Line Speed'
            },
          
            subtitle: {
              text: 'Live Data For LinerSpeed'
            },
            xAxis: {
              type: 'datetime',
              tickPixelInterval: 100,
              title: {
                enabled: true,
                text: 'TimeStamp'
              },
            },
            yAxis: {
              title: {
                text: 'LineSpeed'
              },
              labels: {
                enabled: false
              }
            },
            plotOptions: {
              spline: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            rangeSelector: {
              buttons: [{
                count: 1,
                type: 'minute',
                text: '1M'
              }, {
                count: 5,
                type: 'minute',
                text: '5M'
              }, {
                type: 'all',
                text: 'All'
              }],
              inputEnabled: false,
              selected: 0
            },
            legend: {
              enabled: false
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br/>',
              pointFormat: '{point.x}s: {point.y}'
            },
            series: [{
              name: 'LineSpeed',
              data: dataLineSpeed,
              color: '#10CE9C'
            }]
          }
          this.yieldChartOptions = {
            chart: {
              type: 'spline',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Yield'
            },
          
            subtitle: {
              text: 'Live Data For Yield'
            },
            xAxis: {
              type: 'datetime',
              tickPixelInterval: 100,
              title: {
                enabled: true,
                text: 'TimeStamp'
              },
            },
            yAxis: {
              title: {
                text: 'Yield'
              },
              labels: {
                enabled: false
              }
            },
            plotOptions: {
              spline: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            rangeSelector: {
              buttons: [{
                count: 1,
                type: 'minute',
                text: '1M'
              }, {
                count: 5,
                type: 'minute',
                text: '5M'
              }, {
                type: 'all',
                text: 'All'
              }],
              inputEnabled: false,
              selected: 0
            },
            legend: {
              enabled: false
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br/>',
              pointFormat: '{point.x}s: {point.y}'
            },
            series: [{
              name: 'Yield',
              data: dataYield,
              color: '#F85439'
            }]
          }
          this.nominalThicknessChartOptions = {
            chart: {
              type: 'spline',
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Nominal Thickness'
            },
          
            subtitle: {
              text: 'Live Data For NominalThickness'
            },
            xAxis: {
              type: 'datetime',
              tickPixelInterval: 100,
              title: {
                enabled: true,
                text: 'TimeStamp'
              },
            },
            yAxis: {
              title: {
                text: 'NominalThickness'
              },
              labels: {
                enabled: false
              }
            },
            plotOptions: {
              spline: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            rangeSelector: {
              buttons: [{
                count: 1,
                type: 'minute',
                text: '1M'
              }, {
                count: 5,
                type: 'minute',
                text: '5M'
              }, {
                type: 'all',
                text: 'All'
              }],
              inputEnabled: false,
              selected: 0
            },
            legend: {
              enabled: false
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br/>',
              pointFormat: '{point.x}s: {point.y}'
            },
            series: [{
              name: 'NominalThickness',
              data: dataNominalThickness,
              color: '#003CEF'
            }]
          }
          this.chartNetWidth = Highcharts.stockChart('NetWidth', this.netWidthChartOptions);
          this.chartGrossWidth = Highcharts.stockChart('GrossWidth', this.grossWidthChartOptions);
          this.chartUseableWidth = Highcharts.stockChart('UseableWidth', this.useableWidthChartOptions);
          this.chartLehrSpeed = Highcharts.stockChart('LehrSpeed', this.lehrSpeedChartOptions);
          this.chartLineSpeed = Highcharts.stockChart('LineSpeed', this.lineSpeedChartOptions);
          this.chartYield = Highcharts.stockChart('Yield', this.yieldChartOptions);
          this.chartNoinalThickness = Highcharts.stockChart('NominalThickness', this.nominalThicknessChartOptions); 
          this.showCards = true;
          if(response.result.length){
            this.data = {
              TimeStamp: response.result[0].time,
              TransactionId: 2
            };
            this.liveBinding();
          }
          this.spinner.hide();
        }else{
          this.toaster.error(response.result[0].Msg);
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.spinner.hide();
      }
    )
  }

  liveBinding(){
    this.glassPpcLiveService.GetNetWidth(this.data).subscribe(
      (response: any) => {
        if(!response.HasError){
          if(response.result.length){
            this.data = {
              TimeStamp: response.result[response.result.length-1].time,
              TransactionId: 2
            };
            this.chartNetWidth.series[0].addPoint([((new Date(response.result[response.result.length-1].time)).getTime()),response.result[response.result.length-1].NetWidth], true, true);
            this.chartGrossWidth.series[0].addPoint([((new Date(response.result[response.result.length-1].time)).getTime()),response.result[response.result.length-1].GrossWidth], true, true);
            this.chartUseableWidth.series[0].addPoint([((new Date(response.result[response.result.length-1].time)).getTime()),response.result[response.result.length-1].UseableWidth], true, true);
            this.chartLehrSpeed.series[0].addPoint([((new Date(response.result[response.result.length-1].time)).getTime()),parseFloat(response.result[response.result.length-1].LehrSpeed.toFixed(2))], true, true);
            this.chartLineSpeed.series[0].addPoint([((new Date(response.result[response.result.length-1].time)).getTime()),response.result[response.result.length-1].LineSpeed], true, true);
            this.chartYield.series[0].addPoint([((new Date(response.result[response.result.length-1].time)).getTime()),parseFloat(response.result[response.result.length-1].Yield.toFixed(2))], true, true);
            this.chartNoinalThickness.series[0].addPoint([((new Date(response.result[response.result.length-1].time)).getTime()),response.result[response.result.length-1].NominalThickness], true, true);
            this.chartNoinalThickness.serie
            this.lastNetWidth = response.result[response.result.length-1].NetWidth;
            this.lastGrossWidth = response.result[response.result.length-1].GrossWidth;
            this.lastUseableWidth = response.result[response.result.length-1].UseableWidth;
            this.lastLehrSpeed = response.result[response.result.length-1].LehrSpeed.toFixed(2);
            this.lastLineSpeed = response.result[response.result.length-1].LineSpeed;
            this.lastYield = response.result[response.result.length-1].Yield.toFixed(2);
            this.lastNominalThickness = response.result[response.result.length-1].NominalThickness;
            // if(this.router.url.split('/')[(this.router.url.split('/')).length-1] == this.currentUrl)
              this.liveBinding();
          }
          else{
            // if(this.router.url.split('/')[(this.router.url.split('/')).length-1] == this.currentUrl)
              this.liveBinding();
          }
        }else{
          this.toaster.error(response.result[0].Msg);
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toaster.error(error.error.Message)
        this.spinner.hide();
      }
    )
  }

  getBreadcrumList(): any {
    this.spinner.show();
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    const breadCrumSub = this.usermanagementService
      .GetBreadcrumList(obj)
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (data: any) => {
          this.breadcrumList = data.BreadcrumList;
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
          this.spinner.hide();
        }
      );
    this.subscriptions.add(breadCrumSub);
  }

  displayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
