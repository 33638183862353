// import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { MatTableDataSource } from '@angular/material/table';
// import { ToastrService } from 'ngx-toastr';
// import { MONTHS, WEEKDAYS, WEEKSCOUNT } from 'src/app/@constants/constants';
// import { MachineMappingService } from 'src/app/services/machinemapping.service';
// import { MeetingDefinationService } from 'src/app/services/meeting-defination.service';
// import { MeetingmasterService } from 'src/app/services/meetingmaster.service';
// import { ProductivityService } from 'src/app/services/productivity-service';
// import { MeetingDefinationService } from '../../services/meeting-defination.service';
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MeetingmasterService } from 'src/app/services/meetingmaster.service';
import * as moment from 'moment';
import { parseInt } from 'lodash-es';
import { MONTHS, WEEKDAYS, WEEKSCOUNT } from 'src/app/@constants/constants';
import { MeetingDefinationService } from 'src/app/services/meeting-defination.service';
import { MeetingMatrixService } from 'src/app/services/meeting-matrix.service';
@Component({
  selector: 'app-meeting-reschedule-dialog',
  templateUrl: './meeting-reschedule-dialog.component.html',
  styleUrls: ['../meeting-defination.component.scss'],
})
export class MeetingRescheduleDialogComponent implements OnInit {
  @ViewChild('displayRescheduleModal')
  private displayRescheduleModal!: TemplateRef<any>;
  rescheduleForm: FormGroup;
  MeetingDefForm: FormGroup;
  public meetingDefId;
  weekscount = WEEKSCOUNT;
  months = MONTHS;
  weekdays;

  isLoading = false;
  endAfter: boolean = true;
  endBy: boolean = true;
  dailypattern: boolean = false;
  weeklypattern: boolean = false;
  monthlypattern: boolean = false;
  yearlypattern: boolean = false;
  nofdays: boolean = true;
  monthsselect: boolean = true;
  monthsday: boolean = true;

  inputAdd: boolean = true;
  select: boolean = true;
  UserDDL: any;
  plants!: any;
  groupListDDL!: any[];
  MeetingEvaluations!: any;
  frequencies: any = [];
  pillars!: any[];
  MeetingData: MatTableDataSource<any>;
  CheckedArr: any[] = [];
  meetingId: any;
  toastrService: any;
  ParticipantSelectedList: any;
  rescheduleMeetingObj: any;
  Agendas: any;

  constructor(
    private fb: FormBuilder,
    private meetingMatrixService: MeetingMatrixService,
    private meetingService: MeetingDefinationService,
    private toaster: ToastrService,
    public router: Router,
    private dialog: MatDialog,
    private meetingMasterService: MeetingmasterService,
  ) {}

  ngOnInit() {
    const schema = {
      MeetingName: 'Daily ODS-NKU Meeting',
      MeetingParticipants: ['Sadia Mustafa', 'Balaji'],
      MeetingTime: {
        MeetingStartTime: new Date(),
        MeetingEndTime: new Date(),
        MeetingDuration: '1h 25m 38s', //based on difference between start and end time
      },
      RecurrencePattern: {
        RecurrenceType: 'Daily',
        RecurrenceFrequency: 'Every 1 day',
        RecurrenceFrequencyMode: 'Repeat', //repeat, fixed
      },
    };
    this.createForm();
    this.createMeetingDefForm();
    this.getGroupData();

    this.rescheduleMeeting();
  }
  createForm() {
    this.rescheduleForm = this.fb.group({
      meetingName: [''],
      meetingSubject: [[]],
      meetingParticipants: [''],
      rescheduleStartTime: ['', Validators.required],
      rescheduleEndTime: ['', Validators.required],
      duration: ['', Validators.required],
      RecurrneceType: ['', Validators.required],
      RecurEveryWeekDay: [''],
      RecurIntervalDayDaily: [''],
      RecurIntervalWeekly: [''],
      RecurDaysOfWeekWeekly: this.fb.array([WEEKDAYS]),
      RecurringInDaysOfMonthly: [''],
      RecurDayOfMonthMonthly: [''],
      RecurIntervalOfMonthMontly: [''],
      RecurWeekOfMonthMontly: [''],
      RecurIntervalOfMonthMontlyRelative: [''],
      RecurIntervalofYearsYearly: [''],
      RecurAbsoluteYearly: [''],
      RecurMonthYearly: [''],
      RecurDayOfMonthYearly: [''],
      RecurWeekOfMonthYearly: [''],
      RecurDaysOfWeekYearly: [''],
      RecurringMonthYearly: [''],
      recurrencOccurrences: [''],
      recurrencEndDateStatus: ['', Validators.required],
      recurrenctStartDate: ['', Validators.required],
      recurrencEndDate: [''],
      RecurDaysOfWeekMonthly: [''],

      message: [''],
    });
    this.weekdays = WEEKDAYS;
  }

  SaveSchedule() {
    var WeekDaysString = this.CheckedArr.toString();
    const key = {
      MeetingId: this.meetingDefId,
      RecurrneceType: this.rescheduleForm.value.RecurrneceType,
      RecurEveryWeekDay: this.rescheduleForm.value.RecurEveryWeekDay,
      RecurIntervalDayDaily: this.rescheduleForm.value.RecurIntervalDayDaily,
      RecurIntervalWeekly: this.rescheduleForm.value.RecurIntervalWeekly,
      RecurDaysOfWeekWeekly: WeekDaysString,
      RecurringInDaysOfMonthly:
        this.rescheduleForm.value.RecurringInDaysOfMonthly,
      RecurDayOfMonthMonthly: this.rescheduleForm.value.RecurDayOfMonthMonthly,
      RecurIntervalOfMonthMontly:
        this.rescheduleForm.value.RecurIntervalOfMonthMontly,
      RecurWeekOfMonthMontly: this.rescheduleForm.value.RecurWeekOfMonthMontly,
      RecurIntervalOfMonthMontlyRelative:
        this.rescheduleForm.value.RecurIntervalOfMonthMontlyRelative,
      RecurIntervalofYearsYearly:
        this.rescheduleForm.value.RecurIntervalofYearsYearly,
      RecurAbsoluteYearly: this.rescheduleForm.value.RecurAbsoluteYearly,
      RecurMonthYearly: this.rescheduleForm.value.RecurMonthYearly,
      RecurDayOfMonthYearly: this.rescheduleForm.value.RecurDayOfMonthYearly,
      RecurWeekOfMonthYearly: this.rescheduleForm.value.RecurWeekOfMonthYearly,
      RecurDaysOfWeekYearly: this.rescheduleForm.value.RecurDaysOfWeekYearly,
      RecurringWeekDay: this.rescheduleForm.value.RecurringWeekDay,
      recurrencOccurrences: this.rescheduleForm.value.recurrencOccurrences,
      recurrencEndDateStatus: this.rescheduleForm.value.recurrencEndDateStatus,
      recurrenctStartDate: this.rescheduleForm.value.recurrenctStartDate,
      recurrencEndDate: this.rescheduleForm.value.recurrencEndDate,
      RecurDaysOfWeekMonthly: this.rescheduleForm.value.RecurDaysOfWeekMonthly,
      RecurringMonthYearly: this.rescheduleForm.value.RecurringMonthYearly,
      rescheduleStartTime: new Date(
        this.rescheduleForm.value.rescheduleStartTime
      ),
      rescheduleEndTime: new Date(this.rescheduleForm.value.rescheduleEndTime),
      duration: this.rescheduleForm.value.duration,
    };

    this.meetingService.SaveMeetingReschedule(key).subscribe(
      (data) => {
        this.toaster.success('Saved Successfully');
        this.dialog.closeAll();
        // this.QuestionData = new MatTableDataSource<any>(data);
        // this.getPaginator(this.QuestionData, 1);
      },
      (error: any) => {
        this.toastrService.error('Error');
      }
    );
  }
  createMeetingDefForm() {
    this.MeetingDefForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      meetingDefId: [0],
      meeting: ['', Validators.required],
      frequency: ['', Validators.required],
      level: ['', Validators.required],
      department: [''],
      pillar: [''],
      kpi: [''],
      leader: ['', Validators.required],
      meetingEvaluation: ['', Validators.required],
      participants: [''],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
    });
  }
  getUserList(): void {
    const key = {
      EGId: this.MeetingDefForm.get('group')?.value,
      PlantId: this.MeetingDefForm.get('plant')?.value,
    };
    this.meetingService.getParticipantsOnPlant(key).subscribe(
      (users: any) => {
        this.UserDDL = users;
        this.UserDDL.forEach((elem: any) => {
          elem.empFullName = elem.empFullName;
          elem.empFullName2 = elem.empFullName.toLowerCase();
        });
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.meetingService.getGroupData(key).subscribe(
      (data: any) => {
        // console.log(data)
        this.groupListDDL = data.masterList;
        this.MeetingDefForm.get('group')?.setValue(this.groupListDDL[0].TypeId);
        this.MeetingDefForm.get('plant')?.setValue(
          sessionStorage.getItem('ptid')
        );
        this.rescheduleForm
          .get('meetingName')
          ?.setValue(sessionStorage.getItem('MeetingName'));
        this.getUserList();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getMeetingMaster(): void {
    this.MeetingEvaluations = [];
    const key = {
      groupId: parseInt(this.MeetingDefForm.value.group, 10),
      plantId: parseInt(this.MeetingDefForm.value.plant, 10),
    };
    this.meetingMasterService.GetAllMeetingMaster(key).subscribe(
      (data) => {
        this.MeetingEvaluations = data;
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );

    // console.log(this.MeetingEvaluations)
  }

  getPillar(): void {
    const key = {
      plantId: this.MeetingDefForm.value.plant,
    };

    this.meetingService.getMasterPillars(key).subscribe(
      (response) => {
        this.pillars = response;
        this.MeetingDefForm.get('pillar')?.setValue(this.pillars[0].pillarId);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getParticipantsForm(control): any {
    return this.rescheduleForm.get(control)?.value;
  }
  changePattern(event: any): void {
    if (event.value === 'Daily') {
      this.dailypattern = true;
      this.weeklypattern = false;
      this.monthlypattern = false;
      this.yearlypattern = false;
    } else if (event.value === 'Weekly') {
      this.dailypattern = false;
      this.weeklypattern = true;
      this.monthlypattern = false;
      this.yearlypattern = false;
    } else if (event.value === 'Monthly') {
      this.dailypattern = false;
      this.weeklypattern = false;
      this.monthlypattern = true;
      this.yearlypattern = false;
    } else if (event.value === 'Yearly') {
      this.dailypattern = false;
      this.weeklypattern = false;
      this.monthlypattern = false;
      this.yearlypattern = true;
    }
    this.ValidatingField();
  }

  changerange(event: any): void {
    if (event.value === 'endafter') {
      this.endAfter = false;
      this.endBy = true;
    } else if (event.value === 'endby') {
      this.endAfter = true;
      this.endBy = false;
    } else {
      this.endAfter = true;
      this.endBy = true;
    }
    this.ValidatingField();
  }

  changedaysNumber(event: any): void {
    if (event.value === 'Noofdays') {
      this.nofdays = false;
    } else {
      this.nofdays = true;
    }
    this.ValidatingField();
  }
  changeMonthlyNumbers(event: any): void {
    if (event.value === 'true') {
      this.monthsday = false;
      this.monthsselect = true;
    } else if (event.value === 'false') {
      this.monthsselect = false;
      this.monthsday = true;
    }
    this.ValidatingField();
  }
  changeYearlyNumbers(event: any): void {
    if (event.value === 'true') {
      this.select = true;
      this.inputAdd = false;
    } else if (event.value === 'false') {
      this.select = false;
      this.inputAdd = true;
    }
    this.ValidatingField();
  }

  TimeChange(event: any) {
    var startTime = new Date(this.rescheduleForm.value.rescheduleStartTime);
    var endTime = new Date(this.rescheduleForm.value.rescheduleEndTime);

    var Time = endTime.getTime() - startTime.getTime();
    //var Days = Time / (1000 * 3600 * 24);
    var mint = Time / 1000 / 60;
    this.rescheduleForm.get('duration').setValue(mint.toFixed(0));

    //this.duration
  }

  onChange(val: any): void {

    const index: number = this.CheckedArr.indexOf(val);
    if (index !== -1) {
      this.CheckedArr.splice(index, 1);
    } else {
      this.CheckedArr.push(val);
    }
    this.ValidatingField();
  }

  ValidatingField() {
    debugger;
    if (this.rescheduleForm.value.RecurrneceType == 'Daily') {
      this.rescheduleForm.controls['RecurEveryWeekDay'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'RecurEveryWeekDay'
      ].updateValueAndValidity();

      ////Weekly Validators clearing
      this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekWeekly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalWeekly'
      ].updateValueAndValidity();

      ///Monthly Validators clearing
      this.rescheduleForm.controls['RecurDayOfMonthMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthMontly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].updateValueAndValidity();

      //Yearly Validators clearing
      this.rescheduleForm.controls['RecurAbsoluteYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurAbsoluteYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurMonthYearly'].clearValidators();
      this.rescheduleForm.controls['RecurMonthYearly'].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDayOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurringMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurringMonthYearly'
      ].updateValueAndValidity();

      if (this.rescheduleForm.value.RecurEveryWeekDay == 'false') {
        this.rescheduleForm.controls['RecurIntervalDayDaily'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurIntervalDayDaily'
        ].updateValueAndValidity();
      } else {
        this.rescheduleForm.controls['RecurIntervalDayDaily'].clearValidators();
        this.rescheduleForm.controls[
          'RecurIntervalDayDaily'
        ].updateValueAndValidity();
      }
    }
    if (this.rescheduleForm.value.RecurrneceType == 'Weekly') {
      //Clearing Validations Of Daily///
      this.rescheduleForm.controls['RecurEveryWeekDay'].clearValidators();
      this.rescheduleForm.controls[
        'RecurEveryWeekDay'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalDayDaily'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalDayDaily'
      ].updateValueAndValidity();
      ///////////////////
      ///Monthly Validators clearing
      this.rescheduleForm.controls['RecurDayOfMonthMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthMontly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].updateValueAndValidity();

      //Yearly Validators clearing
      this.rescheduleForm.controls['RecurAbsoluteYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurAbsoluteYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurMonthYearly'].clearValidators();
      this.rescheduleForm.controls['RecurMonthYearly'].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDayOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurringMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurringMonthYearly'
      ].updateValueAndValidity();

      this.rescheduleForm.controls['RecurIntervalWeekly'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'RecurIntervalWeekly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'RecurDaysOfWeekWeekly'
      ].updateValueAndValidity();

      if (this.CheckedArr.length <= 0) {
        this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].setErrors({
          incorrect: true,
        });
      } else {
        this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].setErrors(null);
      }
    }
    if (this.rescheduleForm.value.RecurrneceType == 'Monthly') {
      //Clearing Validations Of Daily///
      this.rescheduleForm.controls['RecurEveryWeekDay'].clearValidators();
      this.rescheduleForm.controls[
        'RecurEveryWeekDay'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalDayDaily'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalDayDaily'
      ].updateValueAndValidity();
      ///////////////////
      ////Weekly Validators clearing
      this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekWeekly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalWeekly'
      ].updateValueAndValidity();

      //Yearly Validators clearing
      this.rescheduleForm.controls['RecurAbsoluteYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurAbsoluteYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurMonthYearly'].clearValidators();
      this.rescheduleForm.controls['RecurMonthYearly'].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDayOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurringMonthYearly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurringMonthYearly'
      ].updateValueAndValidity();

      this.rescheduleForm.controls['RecurringInDaysOfMonthly'].setValidators([
        Validators.required,
      ]);

      if (this.rescheduleForm.value.RecurringInDaysOfMonthly == 'true') {
        this.rescheduleForm.controls['RecurDayOfMonthMonthly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurDayOfMonthMonthly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontly'
        ].setValidators([Validators.required]);
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontly'
        ].updateValueAndValidity();

        this.rescheduleForm.controls[
          'RecurWeekOfMonthMontly'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurWeekOfMonthMontly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls[
          'RecurDaysOfWeekMonthly'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurDaysOfWeekMonthly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontlyRelative'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontlyRelative'
        ].updateValueAndValidity();
      } else {
        this.rescheduleForm.controls[
          'RecurDayOfMonthMonthly'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurDayOfMonthMonthly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontly'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontly'
        ].updateValueAndValidity();

        this.rescheduleForm.controls['RecurWeekOfMonthMontly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurWeekOfMonthMontly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurDaysOfWeekMonthly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurDaysOfWeekMonthly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontlyRelative'
        ].setValidators([Validators.required]);
        this.rescheduleForm.controls[
          'RecurIntervalOfMonthMontlyRelative'
        ].updateValueAndValidity();
      }
    }
    if (this.rescheduleForm.value.RecurrneceType == 'Yearly') {
      //Clearing Validations Of Daily///
      this.rescheduleForm.controls['RecurEveryWeekDay'].clearValidators();
      this.rescheduleForm.controls[
        'RecurEveryWeekDay'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalDayDaily'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalDayDaily'
      ].updateValueAndValidity();
      ///////////////////
      ////Weekly Validators clearing
      this.rescheduleForm.controls['RecurDaysOfWeekWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekWeekly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalWeekly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalWeekly'
      ].updateValueAndValidity();

      ///Monthly Validators clearing
      this.rescheduleForm.controls['RecurDayOfMonthMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDayOfMonthMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurWeekOfMonthMontly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurWeekOfMonthMontly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurDaysOfWeekMonthly'].clearValidators();
      this.rescheduleForm.controls[
        'RecurDaysOfWeekMonthly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].clearValidators();
      this.rescheduleForm.controls[
        'RecurIntervalOfMonthMontlyRelative'
      ].updateValueAndValidity();

      this.rescheduleForm.controls['RecurAbsoluteYearly'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'RecurAbsoluteYearly'
      ].updateValueAndValidity();
      this.rescheduleForm.controls['RecurIntervalofYearsYearly'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'RecurIntervalofYearsYearly'
      ].updateValueAndValidity();

      if (this.rescheduleForm.value.RecurAbsoluteYearly == 'true') {
        this.rescheduleForm.controls[
          'RecurWeekOfMonthYearly'
        ].clearValidators();
        this.rescheduleForm.controls[
          'RecurWeekOfMonthYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurDaysOfWeekYearly'].clearValidators();
        this.rescheduleForm.controls[
          'RecurDaysOfWeekYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurringMonthYearly'].clearValidators();
        this.rescheduleForm.controls[
          'RecurringMonthYearly'
        ].updateValueAndValidity();

        this.rescheduleForm.controls['RecurMonthYearly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurMonthYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurDayOfMonthYearly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurDayOfMonthYearly'
        ].updateValueAndValidity();
      } else {
        this.rescheduleForm.controls['RecurMonthYearly'].clearValidators();
        this.rescheduleForm.controls[
          'RecurMonthYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurDayOfMonthYearly'].clearValidators();
        this.rescheduleForm.controls[
          'RecurDayOfMonthYearly'
        ].updateValueAndValidity();

        this.rescheduleForm.controls['RecurWeekOfMonthYearly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurWeekOfMonthYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurDaysOfWeekYearly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurDaysOfWeekYearly'
        ].updateValueAndValidity();
        this.rescheduleForm.controls['RecurringMonthYearly'].setValidators([
          Validators.required,
        ]);
        this.rescheduleForm.controls[
          'RecurringMonthYearly'
        ].updateValueAndValidity();
      }
    }

    if (this.rescheduleForm.value.recurrencEndDateStatus == 'endby') {
      this.rescheduleForm.controls['recurrencEndDate'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls['recurrencEndDate'].updateValueAndValidity();
      this.rescheduleForm.controls['recurrencOccurrences'].clearValidators();
      this.rescheduleForm.controls[
        'recurrencOccurrences'
      ].updateValueAndValidity();
    } else if (this.rescheduleForm.value.recurrencEndDateStatus == 'endafter') {
      this.rescheduleForm.controls['recurrencEndDate'].clearValidators();
      this.rescheduleForm.controls['recurrencEndDate'].updateValueAndValidity();

      this.rescheduleForm.controls['recurrencOccurrences'].setValidators([
        Validators.required,
      ]);
      this.rescheduleForm.controls[
        'recurrencOccurrences'
      ].updateValueAndValidity();
    } else if (
      this.rescheduleForm.value.recurrencEndDateStatus == 'noenddate'
    ) {
      this.rescheduleForm.controls['recurrencEndDate'].clearValidators();
      this.rescheduleForm.controls['recurrencEndDate'].updateValueAndValidity();
      this.rescheduleForm.controls['recurrencOccurrences'].clearValidators();
      this.rescheduleForm.controls[
        'recurrencOccurrences'
      ].updateValueAndValidity();
    }
  }
  minAdded(min = 1) {
    debugger;
    const validator: ValidatorFn = (formArray: FormArray) => {
      const minAdded = formArray.controls.map(
        (control) => control.value.RecurDaysOfWeekWeekly
      ).length;
      return minAdded >= min ? null : { required: true };
    };
    return validator;
  }

  // reschedule meeting section
  rescheduleMeeting(): void {
    debugger;
    const elem = {
      meetingDefId: this.meetingDefId,
      MeetingParticpant: '',
    };

    this.meetingId = elem.meetingDefId;

    this.CheckedArr = [];
    this.weekdays.forEach((element) => {
      element.Checked = false;
    });

    this.getMeetingParticipants();
    this.getMeetingAgendas();

    this.rescheduleForm.reset();

    const key = {
      plantId: sessionStorage.getItem('ptid'),
      meetingId: elem.meetingDefId,
    };
    this.meetingMatrixService.GetMeetingRecurrence(key).subscribe((res) => {
      if (res != null && res.length > 0) {
        this.rescheduleForm
          .get('rescheduleStartTime')
          ?.setValue(
            moment(new Date()).format('YYYY-MM-DD') +
              ' ' +
              res[0].StartTime +
              ' UTC'
          );
        this.rescheduleForm
          .get('rescheduleEndTime')
          ?.setValue(
            moment(new Date()).format('YYYY-MM-DD') +
              ' ' +
              res[0].EndTime +
              ' UTC'
          );
        this.rescheduleForm.get('duration')?.setValue(res[0].Duration);
        this.rescheduleForm
          .get('recurrenctStartDate')
          ?.setValue(res[0].StartDate);
        this.rescheduleForm
          .get('recurrencEndDateStatus')
          ?.setValue(res[0].Type);
        this.rescheduleForm
          .get('recurrencOccurrences')
          ?.setValue(res[0].NoOfOccurences);
        this.rescheduleForm.get('recurrencEndDate')?.setValue(res[0].EndDate);

        if (res[0].TypeId == 1) {
          const event = {
            value: 'Daily',
          };
          this.changePattern(event);
          this.rescheduleForm.get('RecurrneceType')?.setValue('Daily');

          if (res[0].Interval == 1) {
            this.rescheduleForm.get('RecurEveryWeekDay')?.setValue('true');
            const changeDays = {
              value: 'false',
            };
            this.changedaysNumber(changeDays);
          } else {
            this.rescheduleForm.get('RecurEveryWeekDay')?.setValue('false');
            this.rescheduleForm
              .get('RecurIntervalDayDaily')
              ?.setValue(res[0].Interval);
            const changeDays = {
              value: 'Noofdays',
            };
            this.changedaysNumber(changeDays);
          }
        }
        if (res[0].TypeId == 2) {
          const event = {
            value: 'Weekly',
          };
          this.changePattern(event);
          this.rescheduleForm.get('RecurrneceType')?.setValue('Weekly');
          this.rescheduleForm
            .get('RecurIntervalWeekly')
            ?.setValue(res[0].Interval);

          var ArrayOfDays = res[0].DaysOfWeek.split(',');
          this.CheckedArr = [];
          ArrayOfDays.forEach((element) => {
            this.CheckedArr.push(element);
          });

          this.weekdays.forEach((element) => {
            ArrayOfDays.forEach((el1) => {
              if (element.name == el1) {
                element.Checked = true;
              }
            });

          });
        }
        if (res[0].TypeId == 3) {
          const event = {
            value: 'Monthly',
          };
          this.changePattern(event);

          this.rescheduleForm.get('RecurrneceType')?.setValue('Monthly');

          this.rescheduleForm
            .get('RecurDayOfMonthMonthly')
            ?.setValue(res[0].DayOfMonth);
          this.rescheduleForm.get('RecurringInDaysOfMonthly')?.setValue('true');
          this.rescheduleForm
            .get('RecurIntervalOfMonthMontly')
            ?.setValue(res[0].Interval);

          const events = {
            value: 'true',
          };
          this.changeMonthlyNumbers(events);
        }
        if (res[0].TypeId == 4) {
          const event = {
            value: 'Monthly',
          };
          this.changePattern(event);
          this.rescheduleForm.get('RecurrneceType')?.setValue('Monthly');
          this.rescheduleForm
            .get('RecurringInDaysOfMonthly')
            ?.setValue('false');
          this.rescheduleForm
            .get('RecurWeekOfMonthMontly')
            ?.setValue(Number(res[0].WeekOfMonth));
          this.rescheduleForm
            .get('RecurDaysOfWeekMonthly')
            ?.setValue(res[0].DaysOfWeek);
          this.rescheduleForm
            .get('RecurIntervalOfMonthMontlyRelative')
            ?.setValue(res[0].Interval);

          const events = {
            value: 'false',
          };
          this.changeMonthlyNumbers(events);
        }
        if (res[0].TypeId == 5) {
          const event = {
            value: 'Yearly',
          };
          this.changePattern(event);
          this.rescheduleForm.get('RecurrneceType')?.setValue('Yearly');
          this.rescheduleForm.get('RecurAbsoluteYearly')?.setValue('true');
          this.rescheduleForm
            .get('RecurIntervalofYearsYearly')
            ?.setValue(res[0].Interval);

          this.rescheduleForm.get('RecurMonthYearly')?.setValue(res[0].Month);
          this.rescheduleForm
            .get('RecurDayOfMonthYearly')
            ?.setValue(res[0].DayOfMonth);

          const changeyear = {
            value: 'true',
          };
          this.changeYearlyNumbers(changeyear);
        }
        if (res[0].TypeId == 6) {
          const event = {
            value: 'Yearly',
          };
          this.changePattern(event);
          this.rescheduleForm.get('RecurrneceType')?.setValue('Yearly');
          this.rescheduleForm.get('RecurAbsoluteYearly')?.setValue('false');
          this.rescheduleForm
            .get('RecurIntervalofYearsYearly')
            ?.setValue(res[0].Interval);
          this.rescheduleForm
            .get('RecurWeekOfMonthYearly')
            ?.setValue(Number(res[0].WeekOfMonth));
          this.rescheduleForm
            .get('RecurDaysOfWeekYearly')
            ?.setValue(res[0].DaysOfWeek);
          this.rescheduleForm
            .get('RecurringMonthYearly')
            ?.setValue(res[0].Month);

          const changeyear = {
            value: 'false',
          };
          this.changeYearlyNumbers(changeyear);
        }

        const event = {
          value: res[0].Type,
        };

        this.changerange(event);
      }
    });

    // this.dialog.open(this.displayRescheduleModal, {
    //   height: '90%',
    //   width: '90%',
    //   disableClose: false,
    // });
  }

  getMeetingParticipants(): any {
    const key = {
      meetingDefId: this.meetingDefId,
    };

    this.meetingMatrixService.getMeetingParticipants(key).subscribe((res) => {
      if (res != null) {
        this.ParticipantSelectedList = res;
      }
    });
  }

  getMeetingAgendas(): any {
    const key = {
      meetingDefId: this.meetingDefId,
    };
    this.meetingMatrixService.getMeetingAgendas(key).subscribe((res) => {
      if (res != null) {
        this.Agendas = res;
      }
    });
  }
}
const filterParticipantsArray = (arr1: any[], arr2: any[]) => {
  const filtered: any[] = arr1.filter((el: any) => {
    return arr2.find((el2: any) => {
      return el2?.MeetingParticipantId === el?.EmpInfo_Id;
    });
  });
  return filtered;
};
