<div class="order-processing-content">
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="breadcrumb-link" routerLink="/Access">Access</a>
      </li>
    </ul>
    <div class="top-box-content">
      <h1 class="main-title">Roles</h1>
    </div>
  </div>
  <form [formGroup]="userGroupForm">
    <div class="wrapper-box wrapper-box-shadow table-wrapper">
      <div class="content-top-row content-top-row-order-processing">
        <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="userGroup" required [(ngModel)]="GroupId" (selectionChange)="onGroupChange()">
            <mat-select-filter [placeholder]="'Search'" [displayMember]="'TypeName'" [array]="groupList"
              (filteredReturn)="filteredGroups = $event"></mat-select-filter>
            <mat-option *ngFor="let group of filteredGroups" [value]="group.TypeId">
              {{ group.TypeName }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button class="add-btn" (click)="openModal()" pTooltip="Add Role" tooltipPosition="top">
          <img src="assets/images/add.png" alt="" width="20px" height="20px">
        </button>
        <mat-form-field appearance="fill" class="field-fill" style="display: flex;
        position: relative;
        text-align: left;
        justify-content: flex-end;">
          <mat-label>Search</mat-label>
          <input [readonly]="false" matInput class="input-text" (keyup)="applyFilter($event)" autocomplete="off" />
        </mat-form-field>
      </div>
      <table mat-table [dataSource]="userRolesData" class="basic-table plant-model-table" matSort>
        <ng-container matColumnDef="RoleId">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text"> RoleId</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.RoleId}} </td>
        </ng-container>
        <!-- Item Code Column -->
        <ng-container matColumnDef="RoleName">
          <th mat-header-cell mat-sort-header-pointer-middle *matHeaderCellDef mat-sort-header class="header-min-width">
            <p class="header-text"> Role Name</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.RoleName}} </td>
        </ng-container>
        <!-- PO Target Column -->
        <ng-container matColumnDef="CreatedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">CreatedBy</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.Access}} </td>
        </ng-container>
        <ng-container matColumnDef="CreatedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">CreatedOn</p>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.CreatedOn | date: "dd/MM/yyyy HH:mm:ss" }} </td>
        </ng-container>
        <!-- orderProcessingActions Column -->
        <ng-container matColumnDef="userroleActions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-text">Actions</p>
          </th>
          <td mat-cell *matCellDef="let element" class="row-action-btns-column">
            <div class="row-action-btns">
              <button mat-icon-button class="edit-btn" aria-label="Edit Row" (click)="openEditModal(element)"
                pTooltip="Edit" tooltipPosition="top">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white" />
                </svg>
              </button>
              <button mat-icon-button class="tree-view-btn" aria-label="Delete Role" (click)="deleteRole(element)"
                pTooltip="Delete" tooltipPosition="top">
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                    fill="#F85439" />
                  <circle cx="17" cy="17" r="14" fill="#F85439" />
                  <path
                    d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                    fill="white" />
                </svg>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="userRoleGroupColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: userRoleGroupColumns;"></tr>
      </table>
      <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>

    </div>
  </form>

  <!-- side panel -->

  <div class="sidepanel-model-wrapper edit-plant-row-model" [ngClass]="{'active': displayRoleModal}">
    <div class="sidepanel-close" (click)="close()"></div>
    <div class="model-content">
      <div class="model-top">
        <h2 class="model-title">Role and Details</h2>
        <button mat-icon-button class="model-close-btn" aria-label="Close model" (click)="close()">
          <svg width="14" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
              fill="black" />
          </svg>
        </button>
      </div>
      <div class="model-body">
        <form [formGroup]="userRoleForm">
          <div mat-dialog-title>
          </div>
          <div class="form-group">
            <mat-form-field [ngClass]="{
                            'is-invalid':
                            userRoleForm.get('role')?.errors &&
                            userRoleForm.get('role')?.touched
                          }" appearance="fill" class="field-fill">
              <mat-label>Role Name</mat-label>
              <input matInput placeholder="Role Name" formControlName="role">

            </mat-form-field>
            <p class="invalid-feedback" *ngIf="
                        userRoleForm.get('role')?.touched &&
                        userRoleForm.get('role')?.hasError('required')">
              * group description is required!
            </p>
            <input type="hidden" formControlName="transactionTypeId">
          </div>

          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
              <li class="mat-tree-node">
                <!-- use a disabled button to provide padding for tree leaf -->
                <button mat-icon-button disabled></button>
                <mat-checkbox class="checklist-leaf-node" (change)="todoItemSelectionToggle($event.checked,node)"
                  [checked]="node.ischecked">{{node.text}}</mat-checkbox>


              </li>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
              <li>
                <div class="mat-tree-node">
                  <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.text">
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                  </button>
                  <mat-checkbox [checked]="node.ischecked" [indeterminate]="node.indeterminate && !node.ischecked"
                    (change)="todoItemSelectionToggle($event.checked,node)">{{node.text}}</mat-checkbox>
                </div>
                <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                  <ng-container matTreeNodeOutlet></ng-container>
                </ul>
              </li>
            </mat-nested-tree-node>
          </mat-tree>


          <div style="display: flex; justify-content: flex-end;">
            <button type="button" class="add-btn" style="margin-left: 10px;" pTooltip="Cancel" tooltipPosition="top"
              (click)="close()">
              <img src="assets/images/cancel.png" alt="" width="20px" height="20px">
            </button>
            <button type="submit" class="add-btn" style="margin-left: 10px;" pTooltip="Update" tooltipPosition="top"
              *ngIf="!formMode" (click)="update()" [disabled]="!userRoleForm.valid">
              <img src="assets/images/edit.png" alt="" width="20px" height="20px">
            </button>
            <button type="submit" class="add-btn" style="margin-left: 10px;" pTooltip="Save" tooltipPosition="top"
              *ngIf="formMode" (click)="submit()" [disabled]="!userRoleForm.valid">
              <img src="assets/images/save.png" alt="" width="20px" height="20px">
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>