import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserPlant } from 'src/app/models/userPlant';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { KpitreeService } from 'src/app/services/kpitree.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { DatepickerService } from 'src/app/services/Datepicker.service';

@Component({
  selector: 'app-KpiTreeVersion',
  templateUrl: './KpiTreeVersion.component.html',
  styleUrls: ['./KpiTreeVersion.component.scss'],
})
export class KpiTreeVersionComponent implements OnInit {
  @ViewChild('StatusModal') StatusModal!: TemplateRef<any>;
  breadcrumList: any;
  KpiversionForm!: FormGroup;
  IsAccess: any;
  IsWrite: any;
  groupList: any;
  plants: any[] = [];
  searchPlants!: UserPlant[];
  rowData: any[] = [];
  ShowApplicableDate = false;

  minDate: any;
  ButtonType!: any;
  columnDefs: any;
  VersioncolumnDefs: any;
  showLock = false;
  showUnLock = true;
  IsEdit = false;
  paginationPageSize!: any;
  CRDropDownArray = [];
  gridOptions: any = {
    paginationPageSize: 10,
  };

  gridApi: any;
  getRowNodeId: (data: any) => any;
  defaultColDef: {
    flex: number;
    editable: boolean;
    sortable: boolean;
    filter: boolean;
  };
  statuses: any[] = [
    { name: 'Approve', value: 'Approved' },
    { name: 'Reject', value: 'Rejected' },
  ];
  langObj: any;
  moduleId: any;
  pageId: any;
  languageDir = 'ltr'
  CrId: number;
  ChangeStatusFormGroup: FormGroup;
  VersionData: any;
  gridApiVersionGrid: any;
  CurrentApplicabltToDate: any;
  VersionId: any;
  isClicked: boolean = false;
  constructor(
    private usermanagementService: UserManagementService,
    private kpitreeService: KpitreeService,
    private toaster: ToastrService,
    public router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private platModelService: EnterPriseGroupService,
    private translater: TranslaterService,
    private datepickerService: DatepickerService,
  ) {
    this.columnDefs = [
      {
        field: 'Action',
        headerName: 'Action',
        resizable: true,
        hide: this.IsWrite,
        // cellRenderer: 'buttonCellRenderer',
        cellRenderer: (params: any): any => {

          if (params.data.Status == "Draft") {
            return `
            <div>
           
            <button mat-mini-fab="" color="primary"  class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-pencil" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab="" pTooltip="Edit" tooltipPosition="top" color="warn"  class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-trash" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            <button mat-mini-fab=""   style="background-color: #1a8aa3;"  class="mat-focus-indicator mat-mini-fab mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-check" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            </div>
          `;
          }
        },
        cellRendererParams: {
          clicked(field: any) { },
        },

      },
      {
        field: 'CRNo',
        headerName: 'Change Request No.',
        resizable: true,
        filter: true,
        width: 150,

      },
      {
        field: 'VersionId',
        headerName: 'CR Type',
        resizable: true,
        filter: true,
        width: 150,
        cellRenderer: (data: any) => {
          return data.value == null ? "New" : "Draft";
        },
      },
      {
        field: 'VersionNo',
        headerName: 'Version No',
        resizable: true,
        filter: true,
        width: 150,

      },
      {
        field: 'ApplicableDate',
        headerName: 'CR Date',
        resizable: true,
        filter: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },

      },
      {
        field: 'Description',
        headerName: 'Description',
        resizable: true,
        filter: true,
        width: 250,

      },

      {
        field: 'Status',
        headerName: 'Status',
        resizable: true,
        filter: true,


      },
      {
        field: 'ApprovedByName',
        headerName: 'Approved By',
        resizable: true,
        filter: true,

      },
      {
        field: 'ApprovedDate',
        headerName: 'Approved Date',
        resizable: true,
        filter: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },

      },
      {
        field: 'CreatedByName',
        headerName: 'Created By',
        resizable: true,
        filter: true,

      },
      {
        field: 'CreatedDate',
        headerName: 'Created On',
        resizable: true,
        filter: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },
      },
      {
        field: 'Remarks',
        headerName: 'Remarks',
        resizable: true,
        filter: true,
        width: 300

      }


    ];

    this.VersioncolumnDefs = [
      {
        field: 'Action',
        headerName: 'Action',
        resizable: true,
        hide: this.IsWrite,
        width: 100,
        // cellRenderer: 'buttonCellRenderer',
        cellRenderer: (params: any): any => {

          if (params.data.IsLocked == false) {
            if (moment(new Date(params.data.ApplicableFromDate)).format("YYYY-MM-DD") <= moment(new Date()).format("YYYY-MM-DD")) {


              return `
            <div>
           
            <button mat-mini-fab="" color="primary"  class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #20b2aa;"  ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-lock enable" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
            </div>
          `;
            }
            else {
              return `
          <div>
         
          <button mat-mini-fab="" color="primary"    class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" style="background-color: #b0b0d1;"  ng-reflect-color="primary" ng-reflect-text="Action Detail" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"><i class="fa fa-lock disabled" style="color: #fff;"></i></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
          </div>
        `;
            }
          }
        },
        cellRendererParams: {
          clicked(field: any) { },
        },

      },
      {
        field: 'VersionNo',
        headerName: 'Version No.',
        resizable: true,
        filter: true,
        width: 150,

      },
      {
        field: 'ApplicableFromDate',
        headerName: 'Applicable From',
        resizable: true,
        filter: true,

        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },

      },
      {
        field: 'ApplicableToDate',
        headerName: 'Applicable To',
        resizable: true,
        filter: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },

      },

      {
        field: 'LockedByName',
        headerName: 'Locked By',
        resizable: true,
        filter: true,

      },
      {
        field: 'LockedDate',
        headerName: 'Locked Date',
        resizable: true,
        filter: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },

      },

      {
        field: 'CreatedDate',
        headerName: 'Created On',
        resizable: true,
        filter: true,
        cellRenderer: (data: any) => {
          return data.value
            ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm:ss')
            : '';
        },
      },



    ];

    setTimeout(() => {
      // this.gridApi.sizeColumnsToFit();
    }, 500);
    this.defaultColDef = {
      flex: 1,
      editable: true,
      sortable: true,
      filter: true,
    };
    this.getRowNodeId = function (data) {
      return data.id;
    };
    // this.rowData = [
    //   {
    //     Action: '',
    //     KpiVersion: 10,
    //     Approvedby: 'Admin',
    //     ApprovedDate: '06 / 01 / 2022',
    //     Applicabledate: '06 / 01 / 2022',
    //     CreatedOn: '06 / 01 / 2022',
    //     isLocked: true,
    //   },
    //   {
    //     Action: '',
    //     KpiVersion: 15,
    //     Approvedby: 'vergel',
    //     ApprovedDate: '06 / 01 / 2022',
    //     Applicabledate: '06 / 01 / 2022',
    //     CreatedOn: '06 / 01 / 2022',
    //     isLocked: false,
    //   },
    //   {
    //     Action: '',
    //     KpiVersion: 20,
    //     Approvedby: 'wael',
    //     ApprovedDate: '06 / 01 / 2022',
    //     Applicabledate: '06 / 01 / 2022',
    //     CreatedOn: '06 / 01 / 2022',
    //     isLocked: true,
    //   },
    // ];
    this.getRowNodeId = function (data) {
      return data.trade;
    };
  }

  ngOnInit() {
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroupData();
    this.setWeekValue(1);
    this.KpiversionForm = this.fb.group({
      plant: [''],
      group: [''],

      description: [''],
      // approved: [''],
      applicable: ['', Validators.required],
      type: ['', Validators.required]
    });
    this.ChangeStatusFormGroup = this.fb.group({
      status: ['', Validators.required],
      remarks: ['']
    });
  }

  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value: string) {
    let val;
    if (this.langObj && this.langObj[value]) {
      val = this.langObj[value];
    }
    return val ? val : value;
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if (this.langObj) {
        this.gridApi.refreshHeader();
      }
    });
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        const obj = this.groupList[0];
        this.KpiversionForm.get('group')?.setValue(obj);
        this.getPlants();
        this.ResetChangeRequest();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getPlants(): void {
    const key = {
      GroupId: this.KpiversionForm.value.group.TypeId,
    };
    this.usermanagementService.getDepartments(key).subscribe(
      (res: any) => {
        this.searchPlants = res.DepartList;
        this.plants = res.DepartList;
        const plantObject = this.searchPlants[0];
        this.KpiversionForm.get('plant')?.setValue(plantObject);
        this.GetKpiChangeRequest();
        this.GetKPIVersion();
        this.ResetChangeRequest();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  cellClicked(params: any): void {
    const col = params.colDef.field;

    this.ButtonType = '';

    if (col === 'Action') {
      this.ButtonType = params.event.target.classList[1];
      if (this.ButtonType === 'fa-lock') {
        params.node.setDataValue('isLocked', false);
        this.gridApi.setRowData();
        // params.data.isLocked = false;
      } else if (this.ButtonType === 'fa-unlock-alt') {
        // params.data.isLocked = false;
        params.node.setDataValue('isLocked', true);
      }
      if (this.ButtonType === 'fa-pencil') {
        this.EditChangeRequest(params.node.data);

      }
      if (this.ButtonType === 'fa-trash') {
        this.DeleteChangeRequest(params.node.data.Id);

      }

      if (this.ButtonType === 'fa-check') {
        this.CrId = params.node.data.Id;
        this.VersionId = params.node.data.VersionId;
        this.CurrentApplicabltToDate = new Date(params.node.data.ApplicableDate);


        this.ChangeStatusFormGroup.reset();
        this.dialog.open(this.StatusModal, {
          panelClass: 'custom-dialog-container',
          width: '40vw !important',
          height: 'auto',
          minWidth: '40vw',
          maxHeight: '98vh',
          disableClose: true,
        });


      }

      // params.coldef.cellRenderer(params);
    }
    this.gridApi.refreshCells(params);
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    //this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#myGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }

  VersiononGridReady(params: any): any {
    this.gridApiVersionGrid = params.api;
    //this.gridApi.sizeColumnsToFit();
    this.gridApiVersionGrid.setDomLayout('autoHeight');
    const element: HTMLElement = document.querySelector(
      '#VersionGrid'
    ) as HTMLElement;
    if (element !== null) {
      element.style.height = '';
    }
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        // localStorage.setItem('GetMenuAccess', this.permisson);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }


  AddChangeRequest(): any {
    //   let utcDate ;
    //   let localDate;
    //   if(this.KpiversionForm.value.type!=0){
    //    utcDate = new Date(this.KpiversionForm.value.applicable);

    //   // get the local date string
    //    localDate = new Date(utcDate.toLocaleString());

    //    localDate.setHours(23);
    //    localDate.setMinutes(59);
    //    localDate.setSeconds(0);
    //    localDate.setMilliseconds(0);

    // }
    this.isClicked = true;
    const key = {
      Description: this.KpiversionForm.value.description,
      Plant: this.KpiversionForm.value.plant.DeptId,
      Applicable: this.KpiversionForm.value.type == 0 ? new Date() : new Date(this.KpiversionForm.value.applicable),
      Type: this.KpiversionForm.value.type,
    }

    this.kpitreeService.AddChangeRequest(key).subscribe(
      (data: any) => {
        this.toaster.success("Request Saved Successfully");
        this.isClicked = false;
        this.ResetChangeRequest();
        this.GetKpiChangeRequest();
        this.ChangeCRType();
      },
      (error: any) => {
        this.isClicked = false;
        this.toaster.error('Error', error.message);
      }

    )
  }

  UpdateChangeRequest(): any {

    //   let utcDate ;
    //   let localDate;
    //   if(this.KpiversionForm.value.type!=0){
    //    utcDate = new Date(this.KpiversionForm.value.applicable);

    //   // get the local date string
    //    localDate = new Date(utcDate.toLocaleString());

    //    localDate.setHours(23);
    //    localDate.setMinutes(59);
    //    localDate.setSeconds(0);
    //    localDate.setMilliseconds(0);

    // }


    const key = {
      Description: this.KpiversionForm.value.description,
      Applicable: new Date(this.KpiversionForm.value.applicable),
      CRId: this.CrId,
      Type: this.KpiversionForm.value.type,
    }

    this.kpitreeService.UpdateChangeRequest(key).subscribe(
      (data: any) => {
        this.toaster.success("Request Updated Successfully");
        this.ResetChangeRequest();
        this.GetKpiChangeRequest();
        this.ChangeCRType();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }

    )
  }

  ResetChangeRequest(): any {
    this.KpiversionForm.get('description').reset();
    this.KpiversionForm.get('applicable').reset();
    this.KpiversionForm.get('type').reset();
    this.IsEdit = false;
    this.isClicked = false;

  }
  GetKpiChangeRequest(): any {
    const key = {

      Plant: this.KpiversionForm.value.plant.DeptId,

    }

    this.kpitreeService.GetKpiChangeRequest(key).subscribe(
      (data: any) => {

        this.rowData.splice(0, this.rowData.length);
        this.rowData = data;
        //console.log(new Date( moment(new Date(data[0].ApplicableDate)).add(1,'days').format("YYYY-MM-DD")));
        if (data.length > 0) {
          this.minDate = new Date(moment(new Date(data[0].ApplicableDate)).add(1, 'days').format("YYYY-MM-DD"));
        }

        else {
          this.minDate = new Date();
        }



      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }

    )

  }


  EditChangeRequest(param: any) {
    this.IsEdit = true;
    this.CrId = param.Id;
    this.KpiversionForm.get('description').setValue(param.Description);
    // const date ={
    //   startDate :new Date(param.ApplicableDate),
    //   endDate : new Date(param.ApplicableDate)
    // }
    this.KpiversionForm.get('applicable').setValue(new Date(param.ApplicableDate));
    this.KpiversionForm.get('type').setValue(param.VersionId == null ? 1 : 0);
    this.ChangeCRType();
  }


  DeleteChangeRequest(Id): any {
    const key = {

      CRId: Id,

    }

    this.kpitreeService.DeleteChangeRequest(key).subscribe(
      (data: any) => {

        this.toaster.success("Request Deleted Successfully");

        this.GetKpiChangeRequest();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }

    )

  }

  CRChangeStatus(): any {
    const key = {
      CRId: this.CrId,
      Status: this.ChangeStatusFormGroup.value.status,
      Remarks: this.ChangeStatusFormGroup.value.remarks,
      type: this.VersionId == null ? 1 : 0
    }

    if (key.type == 1) {
      let date1Time = new Date();
      let date2Time = new Date();
      if (this.VersionData.length > 0) {
        let MAXDate = this.VersionData.reduce((max, obj) => new Date(obj.ApplicableToDate) > max ? new Date(obj.ApplicableToDate) : max, new Date(this.VersionData[0].ApplicableToDate));
        MAXDate.setHours(0, 0, 0, 0);
        this.CurrentApplicabltToDate.setHours(0, 0, 0, 0);

        // Convert both dates to a standardized format
        date1Time = MAXDate.getTime();
        date2Time = this.CurrentApplicabltToDate.getTime();
      }

      if (date1Time <= date2Time || this.VersionData.length == 0) {


        this.kpitreeService.CRChangeStatus(key).subscribe(
          (data: any) => {

            if (data[0].message === 'ERROR') {
              this.toaster.error('Error', "Lock previous Version to Proceed.");
            }
            else {
              this.toaster.success("Status Changed Successfully");
            }
            this.GetKpiChangeRequest();
            this.GetKPIVersion();
            this.dialog.closeAll();
          },
          (error: any) => {
            this.toaster.error('Error', error.message);
          }

        );
      }
      else {
        this.toaster.error('Error', "Change Applicable Date To Proceed.");
        this.GetKpiChangeRequest();
        this.GetKPIVersion();
        this.dialog.closeAll();
      }
    }

    else {

      this.kpitreeService.CRChangeStatus(key).subscribe(
        (data: any) => {

          if (data[0].message === 'ERROR') {
            this.toaster.error('Error', "Lock previous Version to Proceed.");
          }
          else {
            this.toaster.success("Status Changed Successfully");
          }
          this.GetKpiChangeRequest();
          this.GetKPIVersion();
          this.dialog.closeAll();
        },
        (error: any) => {
          this.toaster.error('Error', error.message);
        }

      );
    }
  }

  GetKPIVersion(): any {
    const key = {

      Plant: this.KpiversionForm.value.plant.DeptId,

    }

    this.kpitreeService.GetKPIVersion(key).subscribe(
      (data: any) => {


        this.VersionData = data;

        if (data.length > 0) {


          let index = data.findIndex(element => !element.IsLocked);

          if (index == -1) {
            this.CRDropDownArray = [{ Id: 1, Description: "New" }]
          }
          else {
            this.CRDropDownArray = [{ Id: 1, Description: "New" }, { Id: 0, Description: "Draft" }]
          }
        }
        else {
          this.CRDropDownArray = [{ Id: 1, Description: "New" }]
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }

    )

  }


  VersioncellClicked(params: any): void {
    const col = params.colDef.field;

    this.ButtonType = '';

    if (col === 'Action') {
      this.ButtonType = params.event.target.classList[1];
      if (this.ButtonType === 'fa-lock') {
        if (params.event.target.classList[2] != "disabled") {
          this.LockKPIVersion(params.node.data.Id);
        }
        else {
          this.toaster.error("error", "Can not be locked before Applicable Date")
        }
      }

      // params.coldef.cellRenderer(params);
    }
    this.gridApi.refreshCells(params);
  }



  LockKPIVersion(Id: any): any {
    const key = {

      VersionId: Id,

    }

    this.kpitreeService.LockKPIVersion(key).subscribe(
      (data: any) => {

        this.toaster.success("Version Locked Successfully");

        this.GetKpiChangeRequest();
        this.GetKPIVersion();

      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }

    )

  }
  changePlant(): any {

    this.GetKpiChangeRequest();
    this.GetKPIVersion();
  }

  ChangeCRType(): any {

    const type = this.KpiversionForm.value.type;


    ////Weekly Validators clearing


    if (type == 1) {
      this.ShowApplicableDate = true;

      this.KpiversionForm.controls['applicable'].setValidators([Validators.required,]);
      this.KpiversionForm.controls['applicable'].updateValueAndValidity();
    }
    else {
      this.ShowApplicableDate = false;
      this.KpiversionForm.controls['applicable'].clearValidators();
      this.KpiversionForm.controls['applicable'].updateValueAndValidity();
    }

  }

  setWeekValue(weekDay: number): void {
    if (weekDay == undefined || weekDay == null || Number.isNaN(weekDay))
      weekDay = 0
    sessionStorage.removeItem('weekDay');
    sessionStorage.setItem('weekDay', weekDay.toString());
    this.datepickerService.getFirstDayOfWeek()
  }
}
