import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DriversComponent } from '../KpiMasterComponents/drivers/drivers.component';
import { FrequencyComponent } from '../KpiMasterComponents/frequency/frequency.component';
import { KpireasonsComponent } from '../KpiMasterComponents/kpireasons/kpireasons.component';
import { PillarsComponent } from '../KpiMasterComponents/pillars/pillars.component';
import { ToolsComponent } from '../KpiMasterComponents/tools/tools.component';
import { TrendsComponent } from '../KpiMasterComponents/trends/trends.component';
import { UomComponent } from '../KpiMasterComponents/uom/uom.component';
import { FourmComponent } from '../KpiMasterComponents/fourm/fourm.component';
import { KpimasterweightComponent } from '../KpiMasterComponents/kpimasterweight/kpimasterweight.component';
import { DayoffComponent } from '../KpiMasterComponents/dayoff/dayoff.component';
import { MastershiftComponent } from '../KpiMasterComponents/mastershift/mastershift.component';
import { UserManu } from 'src/app/models/userManu';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { UserPlant } from 'src/app/models/userPlant';
import { IpeMasterDataComponent } from '../KpiMasterComponents/ipe-master-data/ipe-master-data.component';
import { DepartmentsComponent } from '../KpiMasterComponents/departments/departments.component';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { KpiDataPointsComponent } from '../KpiMasterComponents/Kpi-Data-Points/Kpi-Data-Points.component';
import { TranslaterService } from 'src/app/services/translater.service';

@Component({
  selector: 'app-kpimaster',
  templateUrl: './kpimaster.component.html',
  styleUrls: ['./kpimaster.component.scss'],
})
export class KpimasterComponent implements OnInit {
  @ViewChild(PillarsComponent) pillarsComponent!: PillarsComponent;
  @ViewChild(DriversComponent) driversComponent!: DriversComponent;
  @ViewChild(FrequencyComponent) frequencyComponent!: FrequencyComponent;
  @ViewChild(ToolsComponent) toolsComponent!: ToolsComponent;
  @ViewChild(TrendsComponent) trendsComponent!: TrendsComponent;
  @ViewChild(UomComponent) uomComponent!: UomComponent;
  @ViewChild(FourmComponent) fourmcomponent!: FourmComponent;
  @ViewChild(DepartmentsComponent) departmentsComponent!: DepartmentsComponent;
  @ViewChild(KpireasonsComponent) kpireasonComponent!: KpireasonsComponent;
  @ViewChild(DayoffComponent) dayOffComponent!: DayoffComponent;
  @ViewChild(MastershiftComponent) masterShiftComponent!: MastershiftComponent;
  @ViewChild(KpimasterweightComponent)
  kpiMasterWeightComponent!: KpimasterweightComponent;
  @ViewChild(IpeMasterDataComponent)
  ipeMasterDataComponent!: IpeMasterDataComponent;
  @ViewChild(KpiDataPointsComponent)
  KpiDataPointsComponent!: KpiDataPointsComponent;

  userMenu!: UserManu[];
  pillarRead!: boolean | undefined;
  driverRead!: boolean | undefined;
  ipeMasterDataRead!: boolean | undefined;
  toolRead!: boolean | undefined;
  trendRead!: boolean | undefined;
  frequencyRead!: boolean | undefined;
  fourMRead!: boolean | undefined;
  dayOffRead!: boolean | undefined;
  masterShiftRead!: boolean | undefined;
  uomRead!: boolean | undefined;
  departmentRead!: boolean | undefined;
  kpireasonRead!: boolean | undefined;
  kpimasterweightRead!: boolean | undefined;
  pillarsPermissions!: any;
  driversPermissions!: any;
  toolsPermissions!: any;
  trendsPermissions!: any;
  frequenciesPermissions!: any;
  fourMPermissions!: any;
  dayOffPermissions!: any;
  ipeMDReadPermissions!: any;
  masterShiftPermissions!: any;
  kpiMasterWeightPermissions!: any;
  uomsPermissions!: any;
  departmentsPermissions!: any;
  kpireaonsPermissions!: any;
  plants!: UserPlant[];
  searchMasterForm!: FormGroup;
  masterData: any = {};
  breadcrumList: any;
  IsWrite: any;
  IsAccess: any;
  groupList: any;
  languageDir = 'ltr';
  langObj: any = {}
  moduleId!: any;
  pageId!:any;
  constructor(
    private kpiMaster: KpimasterService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private usermanagementService: UserManagementService,
    public router: Router,
    private translater: TranslaterService,
  ) {
  }

  ngOnInit(): void {
    this.getLangWords();
    this.searchMasterForm = this.fb.group({
      plant: ['', Validators.required],
      group: ['', Validators.required],
    });
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroupData();
    const list = localStorage.getItem('result');
    this.userMenu = list !== null ? JSON.parse(list) : [];
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        const obj = this.groupList[0];
        this.searchMasterForm.get('group')?.setValue(obj);
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlants(): void {
    const key = {
      GroupId: this.searchMasterForm.value.group.TypeId,
    };
    this.usermanagementService.getDepartments(key).subscribe(
      (res: any) => {
        this.plants = res.DepartList;
        const plantObject = this.plants[0];
        this.searchMasterForm.get('plant')?.setValue(plantObject);
        this.getMasterData(plantObject);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  // getPlants(): void {
  //   const key = {
  //     userId: JSON.parse(localStorage.user).UserId,
  //   };
  //   this.kpiMaster.getUserPlants(key).subscribe(
  //     (plants) => {
  //       this.plants = plants;
  //     },
  //     (error) => {
  //       this.toaster.error('Error', error.message);
  //     }
  //   );
  // }

  getMasterData(event: any): void {
    const plant = this.searchMasterForm.value.plant.DeptId;
    const key = {
      plantId: plant,
    };
    if (this.IsAccess) {
      this.pillarsComponent.getPillarsMaster(key);
    }
    if (this.IsAccess) {
      this.driversComponent.getMasterDrivers(key);
    }
    if (this.IsAccess) {
      // this.toolsComponent.getMasterTools(key);
    }
    if (this.IsAccess) {
      this.frequencyComponent.getMasterFrequencies(key);
    }
    if (this.IsAccess) {
      this.uomComponent.getMasterUoms(key);
    }
    if (this.IsAccess) {
      this.trendsComponent.getMasterTrends();
    }
    if (this.IsAccess) {
      this.masterShiftComponent.GetMasterShiftData(key);
    }
    if (this.IsAccess) {
      // this.kpireasonComponent.getMasterKpiReason(key);
    }
    if (this.IsAccess) {
      this.kpiMasterWeightComponent.getMasterKpiWeights(key);
    }
    if (this.IsAccess) {
      // this.fourmcomponent.GetMasterFourMData(key);
    }
    if (this.IsAccess) {
      this.dayOffComponent.GetMasterDayOffData(key);
    }

    if (this.IsAccess) {
      this.ipeMasterDataComponent.getMasterScoreData(key);
    }
    // if (this.IsAccess) {
    //   this.KpiDataPointsComponent.GetUnitsByPlant(key);
    // }
    // if (this.IsAccess) {
    //   this.departmentsComponent.getMasterDepartments(key);
    // }
    // this.frequencyComponent.getMasterFrequencies(key);
    // this.toolsComponent.getMasterTools(key);
    // this.trendsComponent.getMasterTrends();
    // this.uomComponent.getMasterUoms(key);
    // this.departmentsComponent.getMasterDepartments(key);
    // this.kpireasonComponent.getMasterKpiReason(key);
    // this.fourmcomponent.GetMasterFourMData(key);
    // this.dayOffComponent.GetMasterDayOffData(key);
    // this.masterShiftComponent.GetMasterShiftData(key);
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.ipeMasterDataRead = data?.IsAccess;
        this.pillarRead = data?.IsAccess;
        this.driverRead = data?.IsAccess;
        this.toolRead = data?.IsAccess;
        this.trendRead = data?.IsAccess;
        this.frequencyRead = data?.IsAccess;
        this.fourMRead = data?.IsAccess;
        this.uomRead = data?.IsAccess;
        this.departmentRead = data?.IsAccess;
        this.kpireasonRead = data?.IsAccess;
        this.dayOffRead = data?.IsAccess;
        this.masterShiftRead = data?.IsAccess;
        this.kpimasterweightRead = data?.IsAccess;
        this.kpiMasterWeightPermissions = data;
        this.ipeMDReadPermissions = data;
        this.fourMPermissions = data;
        this.dayOffPermissions = data;
        this.masterShiftPermissions = data;
        this.pillarsPermissions = data;
        this.driversPermissions = data;
        this.toolsPermissions = data;
        this.trendsPermissions = data;
        this.frequenciesPermissions = data;
        this.uomsPermissions = data;
        this.departmentsPermissions = data;
        this.kpireaonsPermissions = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

//menu and breadcrumb
GetBreadcrumList(): any {
  const obj = {
    MenuId:
      localStorage.getItem('O3RightMenuId') === null
        ? 1
        : localStorage.getItem('O3RightMenuId'),
  };
  // tslint:disable-next-line: align
  this.usermanagementService.GetBreadcrumList(obj).subscribe(
    (data: any) => {
      this.breadcrumList = data.BreadcrumList;
      this.moduleId = this.breadcrumList[0].id;
      this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      this.getLangWords();
    },
    (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    }
  );
}
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
