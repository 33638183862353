<div class="production-db-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <ul class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
          <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
            (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
        </li>
      </ul>
    </ul>
    <div class="title-row-flex-both-end">
      <h1 class="main-title">Reactive Maintenance Dashboard</h1>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
  <form [formGroup]="HeaderForm">
    <div class="content-top-row content-top-row-order-processing">
      <!-- GROUP -->
      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-select formControlName="Group" [(ngModel)]="HeaderData.Group" placeholder="Group" panelClass="testClass"
          (selectionChange)="getPlants()" name="Status">
          <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
            (filteredReturn)="filteredGroups = $event"></mat-select-filter>
          <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
            {{ group.EG_Desc }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
        <mat-select formControlName="Plant" [(ngModel)]="HeaderData.Plant"  placeholder="Plant" panelClass="testClass" name="plant"
          (selectionChange)="getLine()">
          <mat-select-filter [placeholder]="'Search Plant'" [displayMember]="'PT_Desc'" [array]="PlantList"
            (filteredReturn)="filteredPlants = $event">
          </mat-select-filter>
          <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
            {{plant.PT_Desc}}
          </mat-option>
        </mat-select>
        <mat-error>Plant is Required</mat-error>
      </mat-form-field>

      <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-first-filed-item-order-processing">
          <mat-select formControlName="Line" placeholder="Line" panelClass="testClass" name="Line" [(ngModel)]="HeaderData.Line">
          <mat-select-filter [placeholder]="'Search Line'" [displayMember]="'PL_Desc'" [array]="LineList"
            (filteredReturn)="filteredLines = $event">
          </mat-select-filter>
          <mat-option  value="-1" >All</mat-option>
          <mat-option *ngFor="let line of filteredLines" [value]="line.PL_Id">
            {{line.PL_Desc}}
          </mat-option>
        </mat-select>
        <mat-error>Line is Required</mat-error>
      </mat-form-field>

      <div class="inputField">
        <nz-date-picker style="margin: 0 20px 0 0 !important" readonly  nzFormat="dd-MMM-yyyy HH:ss" [nzShowTime]="{ nzFormat: 'HH:mm' }"
          [nzMin]="minDate" [nzMax]="maxDate" [(ngModel)]="HeaderData.StartDate" formControlName="StartDate"></nz-date-picker>
        <span class="floating-text">Start Date</span>
      </div>

      <div class="inputField">
        <nz-date-picker readonly  nzFormat="dd-MMM-yyyy HH:ss" [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzMin]="minDate" [nzMax]="maxDate"
          [(ngModel)]="HeaderData.EndDate" formControlName="EndDate">
        </nz-date-picker>
        <span class="floating-text">End Date</span>
      </div>

      <button type="button" class="search" tooltipPosition="top" (click)="getRMDashboard()">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.3609 18.2168L14.6008 13.2662C15.8247 11.8113 16.4953 9.98069 16.4953 8.07499C16.4953 3.62251 12.8728 0 8.4203 0C3.96782 0 0.345306 3.62251 0.345306 8.07499C0.345306 12.5275 3.96782 16.15 8.4203 16.15C10.0918 16.15 11.6847 15.6458 13.0466 14.6888L17.8428 19.677C18.0432 19.8852 18.3129 20 18.6018 20C18.8753 20 19.1348 19.8957 19.3317 19.7061C19.7502 19.3034 19.7636 18.6357 19.3609 18.2168ZM8.4203 2.10652C11.7114 2.10652 14.3888 4.78391 14.3888 8.07499C14.3888 11.3661 11.7114 14.0435 8.4203 14.0435C5.12921 14.0435 2.45183 11.3661 2.45183 8.07499C2.45183 4.78391 5.12921 2.10652 8.4203 2.10652Z"
            fill="#10123D" />
        </svg>
      </button>
    </div>
    <!-- Card -->
    <div class="menu-card-group">
      <div class="menu-card-item-four mat-card-height">
        <div>
          <svg class="svg" width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path *ngIf="TotalWO"
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#10ce9c" />
            <path *ngIf="!TotalWO"
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#f85439" />
          </svg>
        </div>
        <div class="svg-icon">
          <img src="assets/images/round.png" alt="" />
        </div>
        <p class="title-text" style="color: #404040">Total Work Order</p>
        <p class="data-text">
          {{ TotalWO }}
          <span class="data-text small-data-text"></span>
        </p>
      </div>
      <div class="menu-card-item-four mat-card-height">
        <div>
          <svg class="svg" width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path *ngIf="closedPercentage >= 50; else closed"
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#10ce9c" />
            <ng-template #closed>
              <path
                d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
                fill="#f85439" />
            </ng-template>
          </svg>
        </div>
        <div class="svg-icon">
          <img src="assets/images/round.png" alt="" />
        </div>
        <p class="title-text">Closed Work Order</p>
        <p class="data-text" [ngStyle]="{ color: closedPercentage >= 50 ? '#10ce9c' : '#f85439' }">
          {{ ClosedWO }}
          <span class="data-text small-data-text"></span>
        </p>
      </div>
      <div class="menu-card-item-four mat-card-height">
        <div>
          <svg class="svg" width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path *ngIf="completedPercentage >= 50; else complete"
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#10ce9c" />
            <ng-template #complete>
              <path
                d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
                fill="#f85439" />
            </ng-template>
          </svg>
        </div>
        <div class="svg-icon">
          <img src="assets/images/round.png" alt="" />
        </div>
        <p class="title-text">Completed Work Order</p>
        <p class="data-text" [ngStyle]="{
            color: completedPercentage >= 50 ? '#10ce9c' : '#f85439'
          }">
          {{ CompletedWO }}
          <span class="data-text small-data-text"></span>
        </p>
      </div>
      <div class="menu-card-item-four mat-card-height">
        <div>
          <svg class="svg" width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path *ngIf="openedPercentage <= 50; else open"
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#10ce9c" />
            <ng-template #open>
              <path
                d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
                fill="#f85439" />
            </ng-template>
          </svg>
        </div>
        <div class="svg-icon">
          <img src="assets/images/round.png" alt="" />
        </div>
        <p class="title-text">Opened Work Order</p>
        <p class="data-text" [ngStyle]="{ color: openedPercentage <= 50 ? '#10ce9c' : '#f85439' }">
          {{ OpenedWO }}
          <span class="data-text small-data-text"></span>
        </p>
      </div>
    </div>

    <div class="menu-card-group">
      <div class="menu-card-item-four mat-card-height used-spare-parts">
        <div>
          <svg class="svg" width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#954b4b" />
          </svg>
        </div>
        <div class="svg-icon">
          <svg _ngcontent-tpq-c74="" width="24" height="23" viewBox="0 0 21 22" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path _ngcontent-tpq-c74=""
              d="M14.6927 3.31357H13.8119C13.7276 3.13308 13.6294 2.96053 13.5178 2.79678L13.9579 2.0247C14.1027 1.77081 14.0168 1.44618 13.7661 1.29963L11.9506 0.238104C11.8303 0.167695 11.6872 0.148671 11.553 0.185059C11.4187 0.22153 11.3042 0.310465 11.2348 0.432382L10.7953 1.20322C10.597 1.18615 10.403 1.18615 10.2047 1.20322L9.76525 0.432382C9.69572 0.310465 9.58125 0.22153 9.44705 0.185059C9.31284 0.148671 9.16974 0.167695 9.04936 0.238104L7.23388 1.29968C6.9832 1.44623 6.89731 1.77085 7.04205 2.02474L7.48219 2.79687C7.37063 2.96057 7.27244 3.13317 7.18811 3.31361H6.3073C6.01785 3.31361 5.7832 3.55126 5.7832 3.8444V5.9675C5.7832 6.26064 6.01785 6.49828 6.3073 6.49828H7.18811C7.27244 6.67869 7.37063 6.85128 7.48219 7.01499L7.04205 7.78712C6.89731 8.041 6.9832 8.36563 7.23388 8.51218L9.04936 9.57375C9.1697 9.64416 9.31276 9.66314 9.44705 9.6268C9.58129 9.59032 9.69577 9.50139 9.76525 9.37947L10.2047 8.60855C10.403 8.62571 10.597 8.62571 10.7953 8.60855L11.2348 9.37939C11.3043 9.50131 11.4187 9.59024 11.553 9.62671C11.5976 9.6388 11.6432 9.64478 11.6886 9.64478C11.7799 9.64478 11.8703 9.62065 11.9507 9.57367L13.7662 8.5121C14.0168 8.36555 14.1027 8.04092 13.958 7.78703L13.5178 7.01495C13.6294 6.85124 13.7276 6.67869 13.8119 6.4982H14.6927C14.9822 6.4982 15.2168 6.26056 15.2168 5.96742V5.43663V4.37506V3.84436C15.2168 3.55122 14.9821 3.31357 14.6927 3.31357ZM10.5 6.4636C9.65184 6.4636 8.96191 5.76487 8.96191 4.90589C8.96191 4.0469 9.65184 3.34817 10.5 3.34817C11.3482 3.34817 12.0381 4.0469 12.0381 4.90589C12.0381 5.76487 11.3482 6.4636 10.5 6.4636Z"
              fill="white"></path>
            <path _ngcontent-tpq-c74=""
              d="M3.28125 6.89281V5.52937H4.55273V4.2832H2.66602C2.32624 4.2832 2.05078 4.56218 2.05078 4.90629V6.89281C1.57381 7.00638 1.13597 7.25146 0.780855 7.61114C0.277307 8.12108 0 8.79912 0 9.52032C0 10.2415 0.277307 10.9196 0.780855 11.4295C1.02108 11.6728 1.41065 11.6728 1.65092 11.4295C1.89119 11.1862 1.89119 10.7917 1.65092 10.5484C1.09122 9.98153 1.09122 9.05911 1.65092 8.49227C1.92207 8.2177 2.28256 8.06645 2.66602 8.06645C3.04947 8.06645 3.40996 8.2177 3.68111 8.49227C4.24081 9.05911 4.24081 9.98153 3.68111 10.5484C3.44084 10.7917 3.44084 11.1862 3.68111 11.4295C3.80125 11.5512 3.95871 11.6121 4.11612 11.6121C4.27354 11.6121 4.43104 11.5512 4.55113 11.4295C5.05468 10.9196 5.33199 10.2415 5.33199 9.52032C5.33199 8.79912 5.05468 8.12104 4.55113 7.6111C4.19606 7.25146 3.75822 7.00638 3.28125 6.89281Z"
              fill="white"></path>
            <path _ngcontent-tpq-c74=""
              d="M20.2191 7.61017C19.864 7.25048 19.4262 7.00544 18.9492 6.89183V4.90531C18.9492 4.5612 18.6738 4.28223 18.334 4.28223H16.4473V5.5284H17.7187V6.89183C17.2418 7.0054 16.8039 7.25048 16.4488 7.61017C15.9453 8.12014 15.668 8.79818 15.668 9.51938C15.668 10.2406 15.9453 10.9187 16.4488 11.4286C16.6891 11.6719 17.0786 11.6719 17.3189 11.4286C17.5592 11.1853 17.5592 10.7908 17.3189 10.5474C16.7592 9.98059 16.7592 9.05818 17.3189 8.49133C17.59 8.21676 17.9505 8.06552 18.334 8.06552C18.7174 8.06552 19.0779 8.21676 19.3491 8.49133C19.9088 9.05818 19.9088 9.98059 19.3491 10.5474C19.1088 10.7907 19.1088 11.1853 19.3491 11.4286C19.4692 11.5503 19.6267 11.6111 19.7841 11.6111C19.9415 11.6111 20.099 11.5503 20.2191 11.4286C20.7227 10.9187 21 10.2406 21 9.51938C21 8.79818 20.7227 8.12014 20.2191 7.61017V7.61017Z"
              fill="white"></path>
            <path _ngcontent-tpq-c74=""
              d="M1.68164 21.4354C2.58773 21.4354 3.32227 20.6915 3.32227 19.7739C3.32227 18.8562 2.58773 18.1123 1.68164 18.1123C0.775548 18.1123 0.0410156 18.8562 0.0410156 19.7739C0.0410156 20.6915 0.775548 21.4354 1.68164 21.4354Z"
              fill="white"></path>
            <path _ngcontent-tpq-c74=""
              d="M10.5 21.4354C11.4061 21.4354 12.1406 20.6915 12.1406 19.7739C12.1406 18.8562 11.4061 18.1123 10.5 18.1123C9.59391 18.1123 8.85938 18.8562 8.85938 19.7739C8.85938 20.6915 9.59391 21.4354 10.5 21.4354Z"
              fill="white"></path>
            <path _ngcontent-tpq-c74=""
              d="M12.8541 18.1123C13.1795 18.5837 13.3711 19.1564 13.3711 19.7739C13.3711 20.3913 13.1795 20.964 12.8541 21.4354H16.9642C16.6389 20.964 16.4473 20.3913 16.4473 19.7739C16.4473 19.1564 16.6389 18.5837 16.9642 18.1123H12.8541Z"
              fill="white"></path>
            <path _ngcontent-tpq-c74=""
              d="M4.03577 18.1123C4.3611 18.5837 4.55273 19.1564 4.55273 19.7739C4.55273 20.3913 4.3611 20.964 4.03577 21.4354H8.14586C7.82052 20.964 7.6289 20.3913 7.6289 19.7739C7.6289 19.1564 7.82052 18.5837 8.14586 18.1123H4.03577Z"
              fill="white"></path>
            <path _ngcontent-tpq-c74=""
              d="M19.3184 21.4354C20.2245 21.4354 20.959 20.6915 20.959 19.7739C20.959 18.8562 20.2245 18.1123 19.3184 18.1123C18.4123 18.1123 17.6777 18.8562 17.6777 19.7739C17.6777 20.6915 18.4123 21.4354 19.3184 21.4354Z"
              fill="white"></path>
            <path _ngcontent-tpq-c74=""
              d="M12.0381 13.127H8.96191C8.62214 13.127 8.34668 13.4059 8.34668 13.75V16.8655H12.6533V13.75C12.6533 13.4059 12.3779 13.127 12.0381 13.127Z"
              fill="white"></path>
            <path _ngcontent-tpq-c74=""
              d="M5.88574 13.127H2.80957C2.4698 13.127 2.19434 13.4059 2.19434 13.75V16.8655H6.50098V13.75C6.50098 13.4059 6.22552 13.127 5.88574 13.127Z"
              fill="white"></path>
            <path _ngcontent-tpq-c74=""
              d="M15.1143 13.127C14.7745 13.127 14.499 13.4059 14.499 13.75V16.8655H18.8057V13.75C18.8057 13.4059 18.5302 13.127 18.1904 13.127H15.1143Z"
              fill="white"></path>
          </svg>
        </div>
        <p class="title-text">Used Spare Parts</p>
        <p class="data-text primary-color" style="color: #954b4b">
          {{ UsedSpareParts }}
          <span class="data-text small-data-text primary-color"></span>
        </p>
      </div>
      <div class="menu-card-item-four mat-card-height minor" (click)="getMinerChart()">
        <div>
          <svg class="svg" width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#10ce9c" />
          </svg>
        </div>
        <div class="svg-icon">
          <svg _ngcontent-cii-c74="" width="25" height="30" viewBox="0 1 21 27" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path _ngcontent-cii-c74=""
              d="M14.9684 11.344H15.9346C15.6507 8.65845 13.6403 6.52064 11.1149 6.21875V7.24618H9.88502V6.21875C7.35956 6.52064 5.3492 8.65845 5.06531 11.344H6.03148V12.6519H5.06531C5.3492 15.3374 7.35956 17.4752 9.88502 17.7771V16.7497H11.1149V17.7771C13.6403 17.4752 15.6507 15.3374 15.9346 12.6519H14.9684V11.344ZM11.807 14.3126L9.88502 12.2688V8.55409H11.1149V11.7271L12.6766 13.3879L11.807 14.3126Z"
              fill="white"></path>
            <path _ngcontent-cii-c74=""
              d="M21 14.5853V9.41014H19.4573C19.2508 8.59864 18.9483 7.82249 18.554 7.0925L19.6453 5.93211L16.204 2.27269L15.1128 3.43312C14.4263 3.01379 13.6964 2.69215 12.9333 2.47257V0.832031H8.06665V2.47252C7.30357 2.69211 6.57369 3.01379 5.88719 3.43308L4.79598 2.27264L1.35473 5.93211L2.44597 7.0925C2.05168 7.82249 1.74922 8.59864 1.54273 9.41014H0V14.5853H1.54273C1.74922 15.3969 2.05164 16.1729 2.44597 16.903L1.35473 18.0634L4.79598 21.7228L5.88719 20.5624C6.57369 20.9817 7.30357 21.3034 8.06665 21.523V23.1634H12.9333V21.523C13.6964 21.3033 14.4263 20.9817 15.1128 20.5624L16.204 21.7228L19.6452 18.0634L18.554 16.903C18.9483 16.173 19.2507 15.397 19.4572 14.5853H21ZM10.5 19.1217C6.80601 19.1217 3.80074 15.9259 3.80074 11.9977C3.80074 8.06954 6.80601 4.87373 10.5 4.87373C14.194 4.87373 17.1993 8.06954 17.1993 11.9977C17.1993 15.9259 14.194 19.1217 10.5 19.1217Z"
              fill="white"></path>
          </svg>
        </div>
        <p class="title-text">Minor Breakdowns</p>
        <p class="data-text primary-color" style="color: #10ce9c">
          {{ Minor }}
          <span class="data-text small-data-text primary-color"></span>
        </p>
      </div>
      <div class="menu-card-item-four mat-card-height moderate" (click)="getModerateChart()">
        <div>
          <svg class="svg" width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#ffb326" />
          </svg>
        </div>
        <div class="svg-icon">
          <svg _ngcontent-cii-c74="" width="25" height="30" viewBox="0 1 21 27" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path _ngcontent-cii-c74=""
              d="M14.9684 11.344H15.9346C15.6507 8.65845 13.6403 6.52064 11.1149 6.21875V7.24618H9.88502V6.21875C7.35956 6.52064 5.3492 8.65845 5.06531 11.344H6.03148V12.6519H5.06531C5.3492 15.3374 7.35956 17.4752 9.88502 17.7771V16.7497H11.1149V17.7771C13.6403 17.4752 15.6507 15.3374 15.9346 12.6519H14.9684V11.344ZM11.807 14.3126L9.88502 12.2688V8.55409H11.1149V11.7271L12.6766 13.3879L11.807 14.3126Z"
              fill="white"></path>
            <path _ngcontent-cii-c74=""
              d="M21 14.5853V9.41014H19.4573C19.2508 8.59864 18.9483 7.82249 18.554 7.0925L19.6453 5.93211L16.204 2.27269L15.1128 3.43312C14.4263 3.01379 13.6964 2.69215 12.9333 2.47257V0.832031H8.06665V2.47252C7.30357 2.69211 6.57369 3.01379 5.88719 3.43308L4.79598 2.27264L1.35473 5.93211L2.44597 7.0925C2.05168 7.82249 1.74922 8.59864 1.54273 9.41014H0V14.5853H1.54273C1.74922 15.3969 2.05164 16.1729 2.44597 16.903L1.35473 18.0634L4.79598 21.7228L5.88719 20.5624C6.57369 20.9817 7.30357 21.3034 8.06665 21.523V23.1634H12.9333V21.523C13.6964 21.3033 14.4263 20.9817 15.1128 20.5624L16.204 21.7228L19.6452 18.0634L18.554 16.903C18.9483 16.173 19.2507 15.397 19.4572 14.5853H21ZM10.5 19.1217C6.80601 19.1217 3.80074 15.9259 3.80074 11.9977C3.80074 8.06954 6.80601 4.87373 10.5 4.87373C14.194 4.87373 17.1993 8.06954 17.1993 11.9977C17.1993 15.9259 14.194 19.1217 10.5 19.1217Z"
              fill="white"></path>
          </svg>
        </div>
        <p class="title-text">Moderate Breakdowns</p>
        <p class="data-text primary-color" style="color: #ffb326">
          {{ Moderate }}
          <span class="data-text small-data-text primary-color"></span>
        </p>
      </div>
      <div class="menu-card-item-four mat-card-height major" (click)="getMajorChart()">
        <div>
          <svg class="svg" width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#f85439" />
          </svg>
        </div>
        <div class="svg-icon">
          <svg _ngcontent-cii-c74="" width="25" height="30" viewBox="0 1 21 27" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path _ngcontent-cii-c74=""
              d="M14.9684 11.344H15.9346C15.6507 8.65845 13.6403 6.52064 11.1149 6.21875V7.24618H9.88502V6.21875C7.35956 6.52064 5.3492 8.65845 5.06531 11.344H6.03148V12.6519H5.06531C5.3492 15.3374 7.35956 17.4752 9.88502 17.7771V16.7497H11.1149V17.7771C13.6403 17.4752 15.6507 15.3374 15.9346 12.6519H14.9684V11.344ZM11.807 14.3126L9.88502 12.2688V8.55409H11.1149V11.7271L12.6766 13.3879L11.807 14.3126Z"
              fill="white"></path>
            <path _ngcontent-cii-c74=""
              d="M21 14.5853V9.41014H19.4573C19.2508 8.59864 18.9483 7.82249 18.554 7.0925L19.6453 5.93211L16.204 2.27269L15.1128 3.43312C14.4263 3.01379 13.6964 2.69215 12.9333 2.47257V0.832031H8.06665V2.47252C7.30357 2.69211 6.57369 3.01379 5.88719 3.43308L4.79598 2.27264L1.35473 5.93211L2.44597 7.0925C2.05168 7.82249 1.74922 8.59864 1.54273 9.41014H0V14.5853H1.54273C1.74922 15.3969 2.05164 16.1729 2.44597 16.903L1.35473 18.0634L4.79598 21.7228L5.88719 20.5624C6.57369 20.9817 7.30357 21.3034 8.06665 21.523V23.1634H12.9333V21.523C13.6964 21.3033 14.4263 20.9817 15.1128 20.5624L16.204 21.7228L19.6452 18.0634L18.554 16.903C18.9483 16.173 19.2507 15.397 19.4572 14.5853H21ZM10.5 19.1217C6.80601 19.1217 3.80074 15.9259 3.80074 11.9977C3.80074 8.06954 6.80601 4.87373 10.5 4.87373C14.194 4.87373 17.1993 8.06954 17.1993 11.9977C17.1993 15.9259 14.194 19.1217 10.5 19.1217Z"
              fill="white"></path>
          </svg>
        </div>
        <p class="title-text">Major Breakdowns</p>
        <p class="data-text primary-color" style="color: #f85439">
          {{ Major }}
          <span class="data-text small-data-text primary-color"></span>
        </p>
      </div>
    </div>
    <div class="menu-card-group">
    </div>
    <div class="menu-card-group">
      <div class="menu-card-item-three mat-card-height">
        <div>
          <svg class="svg" width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#c5541f" />
          </svg>
        </div>
        <div class="svg-icon">
          <svg _ngcontent-gmr-c74="" width="25" height="27" viewBox="0 1 21 23" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path _ngcontent-gmr-c74=""
              d="M10.45 0.253906L8.62331 1.58871L6.44961 1.10037L5.23522 3.07544L3.06152 3.50953L2.65332 5.83187L0.795996 7.12327L1.24502 9.42388L0 11.3664L1.24502 13.3198L0.795996 15.6204L2.65332 16.9118L3.06152 19.2342L5.23522 19.6683L6.44961 21.6433L8.62331 21.155L10.45 22.4789L12.2767 21.155L14.4504 21.6433L15.6648 19.6683L17.8385 19.2342L18.2467 16.9118L20.104 15.6204L19.655 13.3198L20.9 11.3664L19.655 9.42388L20.104 7.12327L18.2467 5.83187L17.8385 3.50953L15.6648 3.07544L14.4504 1.10037L12.2767 1.58871L10.45 0.253906ZM10.45 3.39015C14.5943 3.39015 17.9609 6.97021 17.9609 11.3773C17.9609 15.7843 14.5943 19.3644 10.45 19.3644C6.30569 19.3644 2.93906 15.7843 2.93906 11.3773C2.93906 6.97021 6.30569 3.39015 10.45 3.39015V3.39015ZM10.45 4.08469C6.65867 4.08469 3.59219 7.34556 3.59219 11.3773C3.59219 15.4089 6.65867 18.6698 10.45 18.6698C14.2414 18.6698 17.3078 15.4089 17.3078 11.3773C17.3078 7.34556 14.2414 4.08469 10.45 4.08469ZM13.6238 7.90462L15.0525 9.32624L10.154 14.8825L9.50091 15.6204L8.78659 14.9476L5.84753 12.1695L7.15378 10.6176L9.37845 12.7229L13.6238 7.90462Z"
              fill="white"></path>
          </svg>
        </div>
        <p class="title-text">Response Time</p>
        <p class="data-text primary-color" style="color: #c5541f">
          {{ ResponseTime ? ResponseTime : "" }}
          <span *ngIf="ResponseTime" class="data-text small-data-text primary-color" style="color: #c5541f">min</span>
        </p>
      </div>
      <div class="menu-card-item-three mat-card-height">
        <div>
          <svg class="svg" width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#b32855" />
          </svg>
        </div>
        <div class="svg-icon">
          <svg _ngcontent-gmr-c74="" width="23" height="23" viewBox="0 1 20 23" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path _ngcontent-gmr-c74="" d="M9.62076 9.73145H5.63977V22.7084H9.62076V9.73145Z" fill="white"></path>
            <path _ngcontent-gmr-c74="" d="M15.2603 13.248H11.2793V22.709H15.2603V13.248Z" fill="white"></path>
            <path _ngcontent-gmr-c74="" d="M20.8999 7.31445H16.9189V22.7085H20.8999V7.31445Z" fill="white"></path>
            <path _ngcontent-gmr-c74="" d="M3.98099 14.6279H0V22.7087H3.98099V14.6279Z" fill="white"></path>
            <path _ngcontent-gmr-c74=""
              d="M1.99067 11.4525C2.90656 11.4525 3.6492 10.6627 3.6492 9.68927C3.6492 9.45838 3.60462 9.23909 3.52868 9.03684L6.72058 6.26563C6.98206 6.44812 7.29393 6.55628 7.63019 6.55628C8.05702 6.55628 8.44227 6.37996 8.73627 6.09845L11.6565 7.9251C11.6286 8.05252 11.6112 8.18365 11.6112 8.32021C11.6112 9.29441 12.3536 10.0841 13.2699 10.0841C14.1863 10.0841 14.9289 9.29466 14.9289 8.32021C14.9289 8.02931 14.8563 7.75915 14.739 7.51715L18.142 3.93002C18.3726 4.05917 18.631 4.13918 18.9099 4.13918C19.826 4.13918 20.5687 3.34945 20.5687 2.376C20.5687 1.4018 19.8263 0.611328 18.9099 0.611328C17.9936 0.611328 17.2512 1.4018 17.2512 2.376C17.2512 2.66665 17.3238 2.93706 17.4409 3.17906L14.0377 6.76545C13.8071 6.63629 13.5486 6.55628 13.2697 6.55628C12.8427 6.55628 12.4579 6.7326 12.1636 7.01412L9.24344 5.18771C9.27107 5.06004 9.28895 4.92891 9.28895 4.79235C9.28895 3.81816 8.54631 3.02843 7.63042 3.02843C6.71431 3.02843 5.97143 3.81816 5.97143 4.79235C5.97143 5.02349 6.01601 5.24254 6.09172 5.44478L2.90052 8.21575C2.63904 8.03277 2.32693 7.9246 1.99091 7.9246C1.07479 7.9246 0.331909 8.71483 0.331909 9.68927C0.331909 10.6627 1.07456 11.4525 1.99067 11.4525Z"
              fill="white"></path>
          </svg>
        </div>
        <p class="title-text">Trouble Shoot Time</p>
        <p class="data-text primary-color" style="color: #b32855">
          {{ TroubleShootTime ? TroubleShootTime : "" }}
          <span *ngIf="TroubleShootTime" class="data-text small-data-text primary-color"
            style="color: #b32855">min</span>
        </p>
      </div>
      <div class="menu-card-item-three mat-card-height">
        <div>
          <svg class="svg" width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25 2.4641C28.7128 0.320509 33.2872 0.320508 37 2.4641L55.3109 13.0359C59.0237 15.1795 61.3109 19.141 61.3109 23.4282V44.5718C61.3109 48.859 59.0237 52.8205 55.3109 54.9641L37 65.5359C33.2872 67.6795 28.7128 67.6795 25 65.5359L6.68911 54.9641C2.9763 52.8205 0.689112 48.859 0.689112 44.5718V23.4282C0.689112 19.141 2.9763 15.1795 6.68911 13.0359L25 2.4641Z"
              fill="#659525" />
          </svg>
        </div>
        <div class="svg-icon">
          <svg _ngcontent-gmr-c74="" width="24" height="22" viewBox="0 0 21 19" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path _ngcontent-gmr-c74=""
              d="M19.3421 16.0186V0.443359H1.65789V16.0186H0V18.9854H21V16.0186H19.3421ZM17.6842 15.277H3.31579V3.41009H17.6842V15.277V15.277Z"
              fill="white"></path>
            <path _ngcontent-gmr-c74="" d="M11.6053 8.82715H9.94739V14.368H11.6053V8.82715Z" fill="white"></path>
            <path _ngcontent-gmr-c74="" d="M16.0264 8.82715H14.3685V14.368H16.0264V8.82715Z" fill="white"></path>
            <path _ngcontent-gmr-c74="" d="M13.8159 6.97949H12.158V14.3673H13.8159V6.97949Z" fill="white"></path>
            <path _ngcontent-gmr-c74="" d="M8.84218 10.6738H4.97375V14.3677H8.84218V10.6738Z" fill="white"></path>
            <path _ngcontent-gmr-c74="" d="M8.84218 7.5957H4.97375V9.44266H8.84218V7.5957Z" fill="white"></path>
          </svg>
        </div>
        <p class="title-text">Waiting SP Time</p>
        <p class="data-text primary-color" style="color: #659525">
          {{ WaitingSPTime ? WaitingSPTime : "" }}
          <span *ngIf="WaitingSPTime" class="data-text small-data-text primary-color" style="color: #659525">min</span>
        </p>
      </div>
    </div>
  </form>
  <ng-template #imageZoomModel>
    <img [src]="currentImagePath" style="height: 98%; width: 100%; overflow: hidden" />
  </ng-template>
  <section>
    <div class="example-button-row">
      <button type="button" class="btn btn-success" (click)="csvExport()" style="margin-top: 30px">
        <img src="assets/images/csv-file-format.png" />
      </button>
      <button type="button" class="btn btn-success" (click)="excelExport()" style="margin-top: 30px; margin-left: 10px">
        <i class="fa fa-file-excel-o icon-white" aria-hidden="true"></i>
      </button>
    </div>
  </section>
  <mat-card-content>
    <ag-grid-angular style="width: 100%; height: 24vw" class="ag-theme-alpine" [rowData]="rowData"
      [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" (cellClicked)="cellClicked($event)"
      [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
  </mat-card-content>
</div>

<ng-template #breakDownDialog>
  <div mat-dialog-title class="mat-title">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="main-title">
        {{
        isMinorChart ? "Minor" : isModerateChart ? "Moderate" : "Major"
        }}
        Breakdowns
      </h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div class="col-md-12">
    <div class="chart-group">
      <div class="chart-items">
        <div class="chart-title"></div>
        <div *ngIf="isMinorChart" [chart]="chartMinor"></div>
        <div *ngIf="isModerateChart" [chart]="chartModerate"></div>
        <div *ngIf="isMajorChart" [chart]="chartMajor"></div>
      </div>
    </div>
  </div>
</ng-template>