import { Component, OnInit } from '@angular/core';
import OrgChart from '@balkangraph/orgchart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KpitreeService } from 'src/app/services/kpitree.service';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-OrgTree',
  templateUrl: './OrgTree.component.html',
  styleUrls: ['./OrgTree.component.scss']
})
export class OrgTreeComponent implements OnInit {
  kpiTreeData: any;
  constructor(
    private spinner: NgxSpinnerService,
    private kpiservice: KpitreeService,
    private toaster: ToastrService,
  ) { }
  ngOnInit(): void {
    OrgChart.templates.myTemplate = Object.assign({}, OrgChart.templates.ana);
    OrgChart.templates.myTemplate.size = [300, 80];
    OrgChart.templates.myTemplate.node = '<rect x="0" y="0" height="80" width="300" fill="#fcc89b" rx="15" ry="15"></rect>' +
      '<rect x="0" y="40" height="40" width="300" fill="#cc9d80" rx="15" ry="15"></rect>' +
      '<rect x="0" y="40" height="20" width="300" fill="#cc9d80"></rect>' +
      '<circle cx="35" cy="40" r="47" fill="#d1d2d4" stroke="#fff" stroke-width="5"></circle>' +
      '<circle stroke="#939598" stroke-width="2" fill="#939598" cx="35" cy="25" r="8"></circle> ' +
      '<path d="M20,54 C20,32 50,32 50,54 L19,54" stroke="#939598" stroke-width="2" fill="#939598"></path>';

    OrgChart.templates.myTemplate.ripple = {
      radius: 15,
      color: '#0890D3',
      rect: { x: 0, y: 0, width: 300, height: 80, rx: 15, ry: 15 }
    };
    OrgChart.templates.myTemplate.field_0 = '<text width="210" class="field_0" style="font-size: 18px;" font-weight="bold" fill="#322b28" x="170" y="25" text-anchor="middle">{val}</text>';
    OrgChart.templates.myTemplate.field_1 = '<text width="210" class="field_1" style="font-size: 14px;" font-weight="bold" fill="#231f20" x="170" y="60" text-anchor="middle">{val}</text>';
    OrgChart.templates.myTemplate.img_0 = '<clipPath id="{randId}"><circle cx="35" cy="40" r="46"></circle></clipPath><image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="-15" y="-10"  width="100" height="100"></image>';
    const key = {
    };
    this.kpiservice.kpiorgtree(key).subscribe((response) => {
      this.kpiTreeData = response;
      const chart = new OrgChart(document.getElementById('tree') as HTMLElement, {
        mouseScrool: OrgChart.action.none,
        layout: OrgChart.mixed,
        template: 'olivia',
        nodeMenu: {
          add: { text: 'Add' },
          edit: { text: 'Edit' },
          remove: { text: 'Remove' },
          details: { text: 'Details' },
        },
        menu: {
          pdf: { text: 'Export PDF' },
          png: { text: 'Export PNG' },
          svg: { text: 'Export SVG' },
          csv: { text: 'Export CSV' }
        },
        nodeBinding: {
          field_0: 'name',
          field_1: 'title',
          field_2: 'styleClass',
          img_0: 'img',
          fill: 'fill'
        },
      });
      chart.load(this.kpiTreeData);
      this.spinner.hide();
    },
      (error) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }

}
