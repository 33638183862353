import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'app-loading-overlay',
  template: ` <div class="custom-loader"></div> `,
})
export class CustomLoadingOverlay implements ILoadingOverlayAngularComp {
  private params!: ILoadingOverlayParams;

  agInit(params: ILoadingOverlayParams): void {
    this.params = params;
  }
}
