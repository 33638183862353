import { NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  PlantGroupElement,
  PlantModelElement,
  MachineParameterVariableElement,
} from 'src/app/models/O3AdministrationModels/plant-model';
import { MachineParameterElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CommonService } from 'src/app/services/common.service';
import { MachineParamtersService } from 'src/app/services/machine-paramters.service';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { MatDialog } from '@angular/material/dialog';
import { debug } from 'console';
import { QualityParameterService } from 'src/app/services/quality-parameter.service';
import { TechnisionHistoryComponent } from 'src/app/PM/skill-matrix/technision-history/technision-history.component';
import { MatOption } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
interface Group {
  value: string;
  viewValue: string;
}

interface VariableGroup {
  value: string;
  viewValue: string;
}

interface DataType {
  value: string;
  viewValue: string;
}

interface DataSource {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-machine-parameters',
  templateUrl: './machine-parameters.component.html',
  styleUrls: ['./machine-parameters.component.scss'],
})
export class MachineParametersComponent implements OnInit {
  public linkedControl: FormControl = new FormControl();
  public filteredLinked: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  LineNode: any;
  moduleId!: any;
  pageId!: any;
  isUpdateVariable = false;
  breadcrumList!: any;
  IsWrite: any;
  IsAccess: any;
  groupTypeList: any;
  dataTypeDetailMode!: boolean;
  variableId!: any;
  dataTypeId!: any;
  isEventMode!: boolean;
  inputEventList: any[] = [];
  outputEventList: any[] = [];
  vGroupId!: any;
  ioEventId!: any;
  inputEventId!: any;
  outputEventId!: any;
  parameterGroupType!: any;
  var_Id!: any;
  inputEvent: any;
  groupType: any;
  outputEvent: any;
  isSelected = false;
  DNet: any;
  Nodej: any;
  LinkProductList!: any[];
  filteredLinkProduct: any;
  vgGroupTypeList: any;
  constructor(
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private machineParameterService: MachineParamtersService,
    private qualityParameterService: QualityParameterService,
    private userManagementService: UserManagementService,
    private platModelService: EnterPriseGroupService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public router: Router
  ) { }

  // form unitDetailForm fields controls
  get unitDetailFormControls(): any {
    return this.unitDetailForm.controls;
  }

  // First Tab-  form addNewPlantModeItemlForm fields controls
  get addNewVariableGroupItemlFormControls(): any {
    return this.addNewVariableGroupItemlForm.controls;
  }

  // // Second Tab- form addNewPlantModeItemlForm fields controls
  get addNewVariableItemlFormControls(): any {
    return this.addNewVariableItemlForm.controls;
  }

  // // Third Tab- form addNewPlantModeItemlForm fields controls
  get addNewVariableISpecFormControls(): any {
    return this.addNewVariableSpecForm.controls;
  }

  group!: any[];
  filteredModules!: any[];
  groupInVariable!: any[];
  groupInSpec!: any[];
  unitname: any;
  // LinkProductList!: any[];
  variableGroupInParameter!: any[];
  variableGroupInParameterInSpec!: any[];
  isUpdateGroupVariable = false;
  variableGroup: VariableGroup[] = [
    { value: 'None', viewValue: 'None' },
    { value: 'Brand Company', viewValue: 'Brand Company' },
  ];

  dataSource!: any[];
  calculationMethod!: any[];
  dataType!: any[];
  desktopFunctionList!: any[];
  // Table data for first tab
  // machineParameterVariableGroupColumns: string[] = ['VG_Id', 'VG_Desc', 'PU_Desc', 'VG_Order', 'CreatedBy', 'CreatedOn', 'plantModelActions'];

  machineParameterVariableGroupColumns: string[] = [
    'plantModelActions',
    'VG_Id',
    'VG_Desc',
    'PU_Desc',
    'VG_Order',
    'VG_GroupType',
    'grouptype',
    'CreatedOn',
  ];
  machineParameterVariableGroupData = new MatTableDataSource<any>();

  // Table data for Second tab
  machineParameterVariableColumns: string[] = [
    'plantModelActions',
    'Var_Id',
    'Var_Desc',
    'VarOrder',
    'DS_Desc',
    'DT_Desc',
    'CreatedBy',
    'CreatedOn',
  ];
  machineParameterVariableData = new MatTableDataSource<any>();
  // Table data for third tab
  machineParameterVariableSpecListColumns: string[] = [
    'plantModelActions',
    'Parameter_Specs_Id',
    'Parameter_Id',
    'Parameter_Name',
    'Link_Product',
    'LSL',
    'LCL',
    'LWL',
    'TGT',
    'UWL',
    'UCL',
    'USL',
    'EffectiveDate',
  ];
  machineParameterVariableSpecList = new MatTableDataSource<any>();
  dataTypeDetailDataSource!: MatTableDataSource<any>;
  dataTypeDetailColumns: any[] = ['actions', 'id', 'text', 'actions'];
  searchForm!: FormGroup;
  isSarchFormVisible = false;

  unitDetailForm!: FormGroup;
  getVariableForm!: FormGroup;
  UnitNode: any;
  Group!: string;
  VariableGroup!: string;
  UnitName!: string;
  varUnitName!: string;
  isUnitName = false;
  unitDetailFormFormSubmitted = false;
  // tslint:disable-next-line:variable-name
  _isDisabled = true;

  // Variable Group Form
  addNewVariableGroupItemlForm!: FormGroup;
  isaddNewVariableGroupModelVisible = false;
  addNewVariableGroupItemlFormSubmitted = false;

  // second Tab variables
  addNewVariableItemlForm!: FormGroup;
  isaddNewVariableModelVisible = false;
  addNewVariableItemlFormSubmitted = false;

  // third tab
  addNewVariableSpecForm!: FormGroup;
  isaddNewVariableSpecModelVisible = false;
  getVariableSpecForm!: FormGroup;
  dataTypeDetailForm!: FormGroup;
  isUpdateVariableSpec = false;
  inputEventMode = false;
  outputEventMode = false;
  outputChecked = false;
  inputDisabled = false;
  outputDisabled = false;
  inputChecked = false;
  modeProperty!: boolean;
  variableListInSpec!: any[];
  parameterListInSpec!: any[];

  // Plant Model Form
  addNewPlantModeItemlForm!: FormGroup;
  isaddNewPlantModeItemModelVisible = false;
  addNewPlantModeItemlFormSubmitted = false;
  isPlantGroupModelVisible = false;
  isPlantGroupModelVisibleInVariable = false;
  isPlantGroupModelVisibleInVariableSpec = false;
  // plantTitleName: string;
  @ViewChild(MatTable) table!: MatTable<MachineParameterElement>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('displayDataTypeDetailPopup')
  private displayDataTypeDetailPopup!: TemplateRef<any>;

  // plant group view
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  plantGroupSourceInVariable = new MatTreeNestedDataSource<PlantGroupElement>();
  plantGroupSourceInVariableSpec =
    new MatTreeNestedDataSource<PlantGroupElement>();

  ngOnInit(): void {
    this.linkedControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterLink();
      });
    this.getGroup();
    this.GetMenuAccess();
    this.GetGroupType();
    this.searchForm = this.formBuilder.group({
      searchValue: ['', Validators.required],
    });

    // Filter Form
    this.unitDetailForm = this.formBuilder.group({
      groupList: [''],
      UnitDataList: [''],
      PUID: [''],
    });

    // First Tab add new Variable Group form
    this.addNewVariableGroupItemlForm = this.formBuilder.group({
      groupDescription: ['', Validators.required],
      groupOrder: ['', Validators.required],
      VG_Id: [''],
      PUID: ['', Validators.required],
      vgGroupType: ['', Validators.required],
      groupType: [''],
      inputEvent: [''],
      outputEvent: [''],
    });
    this.getVariableForm = this.formBuilder.group({
      groupList: ['', Validators.required],
      UnitDataList: ['', Validators.required],
      variableGroupList: ['', Validators.required],
      PUID: ['', Validators.required],
    });
    this.getVariableSpecForm = this.formBuilder.group({
      groupList: ['', Validators.required],
      UnitDataList: ['', Validators.required],
      variableGroupList: ['', Validators.required],
      PUID: ['', Validators.required],
    });
    // Second Tab add new Variable  form
    this.addNewVariableItemlForm = this.formBuilder.group({
      VariableGrpDesc: ['', Validators.required],
      VariableGrpOrder: ['', Validators.required],
      VariableGroup: ['', Validators.required],
      EngUnits: [''],
      parameterPrecision: [''],
      InputTag: [''],
      OutputTag: [''],
      varId: [''],
      DataSource: [''],
      DataType: [''],
      CalculationMethod: [''],
    });

    this.addNewVariableSpecForm = this.formBuilder.group({
      TransactionTypeId: [''],
      Parameter_Specs_Id: [''],
      Parameter_Id: [''],
      Desc: ['', Validators.required],
      VgId: [''],
      LinkProduct: ['', Validators.required],
      LCL: [''],
      LWL: [''],
      TGT: [''],
      UWL: [''],
      UCL: [''],
      LSL: [''],
      USL: [''],
      URL: [''],
      LRL: [''],
    });

    this.dataTypeDetailForm = this.formBuilder.group({
      text: ['', Validators.required],
    });

    this.GetGroupTypeList();
    this.GetBreadcrumList();
  }

  ngAfterViewInit(): void {
    this.machineParameterVariableGroupData.paginator =
      this.paginator.toArray()[0];
    this.machineParameterVariableGroupData.sort = this.sort.toArray()[0];
    this.machineParameterVariableData.paginator = this.paginator.toArray()[1];
    this.machineParameterVariableData.sort = this.sort.toArray()[1];
  }

  getGroup(): void {
    const key = {
      Id: 0,
    };
    this.machineParameterService.getGroupData(key).subscribe(
      (data: any) => {
        this.group = data.masterList;
        this.groupInVariable = data.masterList;
        this.groupInSpec = data.masterList;
        this.unitDetailForm.get('groupList')?.setValue(this.group[0].TypeId);
        this.getVariableForm.get('groupList')?.setValue(this.group[0].TypeId);
        this.getVariableSpecForm
          .get('groupList')
          ?.setValue(this.group[0].TypeId);
        if (this.group.length > 0) {
          this.filteredModules = this.group.slice();
        }
      },
      (error: any) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  // search form subit
  onSearch(): void {
    const searchValue = this.searchForm.value;
  }

  // Variable Group
  openaddNewVariableGroupModel(): void {
    // this.GetGroupTypeList();
    this.isEventMode = false;
    this.inputDisabled = false;
    this.outputDisabled = false;
    this.modeProperty = true;
    this.inputChecked = false;
    this.outputChecked = false;
    this.inputEventMode = false;
    this.outputEventMode = false;
    if (this.unitDetailForm.get('PUID')?.value !== '') {
      this.isaddNewVariableGroupModelVisible = true;
      this.variableGroupFormReset();
      this.isUpdateGroupVariable = false;
    } else {
      this.toaster.error('Error', 'Unit is not selected!');
    }
    this.GetAllMasterEvents();
  }

  EventMode(): void {
    this.refreshData();
    // tslint:disable-next-line:max-line-length
    const val = this.groupTypeList.find(
      (c: any) =>
        c.parameterGroupTypeId ===
        this.addNewVariableGroupItemlForm.value.groupType
    )?.groupTypeName;
    if (val === 'Event') {
      this.isEventMode = true;
    } else {
      this.isEventMode = false;
    }
  }

  GetGroupTypeList(): any {
    // tslint:disable-next-line: align
    this.qualityParameterService.getParamaterGroupType().subscribe(
      (data: any) => {
        this.groupTypeList = data;
        this.addNewVariableGroupItemlForm
          .get('groupType')
          ?.setValue(data[0].parameterGroupTypeId);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  inputEventChang(event: any): void {
    this.inputChecked = event.checked;
    if (event.checked === true) {
      this.inputEventMode = true;
      this.outputEventMode = false;
      this.outputDisabled = true;
      this.GetAllMasterEvents();
      this.addNewVariableGroupItemlForm.get('outputEvent')?.reset();
    }
    if (event.checked === false) {
      this.inputEventMode = false;
      this.inputDisabled = false;
      this.outputDisabled = false;
      this.addNewVariableGroupItemlForm.get('inputEvent')?.reset();
    }
  }

  outputEventChang(event: any): void {
    this.outputChecked = event.checked;
    if (event.checked === true) {
      this.outputEventMode = true;
      this.inputEventMode = false;
      this.inputDisabled = true;
      this.GetAllMasterEvents();
      this.addNewVariableGroupItemlForm.get('inputEvent')?.reset();
    }
    if (event.checked === false) {
      this.outputEventMode = false;
      this.inputDisabled = false;
      this.inputEventMode = false;
      this.addNewVariableGroupItemlForm.get('outputEvent')?.reset();
    }
  }

  // GET ALL Master Events
  GetAllMasterEvents(): void {
    const key = {};
    this.qualityParameterService
      .GetAllMasterEvents(key)
      .subscribe((res: any) => {
        this.inputEventList = [];
        this.outputEventList = [];
        res.map((element: any) => {
          if (element.type === 'Input') {
            this.inputEventList.push(element);
            this.addNewVariableGroupItemlForm
              .get('inputEvent')
              ?.setValue(this.inputEventList[0].masterId);
          }
          if (element.type === 'Output') {
            this.outputEventList.push(element);
            this.addNewVariableGroupItemlForm
              .get('outputEvent')
              ?.setValue(this.outputEventList[0].masterId);
          }
        });
      });
  }

  closeaddNewVariableGroupModel(): void {
    this.isaddNewVariableGroupModelVisible = false;
  }

  openUnit(): void {
    this.isPlantGroupModelVisible = true;
    const key = {
      EGId: this.unitDetailForm.value.groupList,
      level: 'Unit',
      CreatedBy: JSON.parse(localStorage.user).UserId,
      IsOptional: 0,
    };
    this.commonService.getTreeModal(key).subscribe((data: any) => {
      this.plantGroupSource.data = data.TreeModalList;
      this.treeControl.dataNodes = this.plantGroupSource.data;
      this.UnitNode = filterData(this.plantGroupSource.data, 'Unit');
      this.expandAllNode();
    });

    function filterData(data: PlantGroupElement[], title: string) {
      const r = data.filter(function (o: any) {
        Object.keys(o).forEach(function (e: any) {
          if (Array.isArray(o[e])) {
            o[e] = filterData(o[e], title);
          }
        });
        return o.title !== title;
      });
      return r;
    }
    // this.treeControl.expandAll();
    // this.treeControl.collapseDescendants(this.UnitNode);
  }
  onClickUnit(node: any) {
    if (node.Level === 'Unit') {
      this.isPlantGroupModelVisible = false;
      const id = {
        id: node.PrimaryId,
      };
      const key = {
        Pu_id: node.PrimaryId,
        VG_Id: 0,
      };

      this.machineParameterVariableGroupData.data = [];
      this.machineParameterVariableData.data = [];
      this.machineParameterVariableSpecList.data = [];
      this.unitDetailForm.get('UnitDataList')?.setValue(node.text);
      this.unitDetailForm.get('PUID')?.setValue(node.PrimaryId);
      this.addNewVariableGroupItemlForm.get('PUID')?.setValue(node.PrimaryId);
      this.getVariableGroupList(key);
      this.getVariableForm.get('UnitDataList')?.setValue(node.text);
      this.getVariableForm.get('PUID')?.setValue(node.PrimaryId);
      this.getVariableSpecForm.get('UnitDataList')?.setValue(node.text);
      this.getVariableSpecForm.get('PUID')?.setValue(node.PrimaryId);
    }
  }
  getVariableGroupList(data: any): void {
    this.machineParameterService.gettVariableGroupList(data).subscribe(
      (response: any) => {
        if (response.HasError) {
          this.toaster.error('Error', response.ErrorMessage);
        } else {
          this.DNet = response.VariableGroupList;
          this.machineParameterVariableGroupData = new MatTableDataSource<any>(response.VariableGroupList);
          this.getPaginator(this.machineParameterVariableGroupData, 0);
          this.variableGroupInParameterInSpec = response.VariableGroupList;
          this.getVariableSpecForm
            .get('variableGroupList')
            ?.setValue(response.VariableGroupList[0].VG_Id);
          this.addNewVariableSpecForm
            .get('VgId')
            ?.setValue(response.VariableGroupList[0].VG_Id);
          this.variableGroupInParameter = response.VariableGroupList;
          this.getVariableForm
            .get('variableGroupList')
            ?.setValue(response.VariableGroupList[0].VG_Id);
          this.addNewVariableItemlForm
            .get('VariableGroup')
            ?.setValue(response.VariableGroupList[0].VG_Id);
          // this.getPaginator(this.machineParameterVariableGroupData, 0);
          // this.getVariableGroups();
          this.getVariable();
          this.getVariableSpec();
        }
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  SelectUnitName(unitname: any): any {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
    }
  }
  onChangeGroupInVariableGroup(data: any): void {
    this.machineParameterVariableGroupData.data = [];
    this.machineParameterVariableData.data = [];
    this.machineParameterVariableSpecList.data = [];
    this.variableGroupInParameter = [];
    this.variableGroupInParameterInSpec = [];
    this.unitDetailForm.get('groupList')?.setValue(data.value);
    this.unitDetailForm.get('UnitDataList')?.setValue('');
  }
  // UnitName for second tab
  SelectVarUnitName(unitname: any): any {
    if (unitname.title === 'UNT') {
      this.varUnitName = unitname.name;
      this.isPlantGroupModelVisible = false;
    }
  }

  // add new variable group form submit
  addNewVariableGroupItemlFormSubmit(): void {
    this.groupType = this.addNewVariableGroupItemlForm.get('groupType')?.value;
    this.inputEvent =
      this.addNewVariableGroupItemlForm.get('inputEvent')?.value;
    this.outputEvent =
      this.addNewVariableGroupItemlForm.get('outputEvent')?.value;
    if (!this.isUpdateGroupVariable) {
      this.addNewVariableGroupItemlFormSubmitted = true;
      this.isUnitName = true;
      const data = {
        VG_Id: 0,
        VG_Desc:
          this.addNewVariableGroupItemlForm.get('groupDescription')?.value,
        VG_Order: this.addNewVariableGroupItemlForm.get('groupOrder')?.value,
        PUID: this.addNewVariableGroupItemlForm.get('PUID')?.value,
        VGTypeId: this.addNewVariableGroupItemlForm.get('vgGroupType')?.value,
        // variableGroupId: this.vGroupId,
        ParameterGroupTypeId: this.groupType ? this.groupType : 1, // defined in DB
        InputEventId: this.inputEventMode === true ? this.inputEvent : 0,
        OutputEventId: this.outputEventMode === true ? this.outputEvent : 0,
        TransactionTypeId: 1,
      };
      if (this.addNewVariableGroupItemlForm.invalid) {
        return;
      }
      this.machineParameterService.sumbitGroup(data).subscribe(
        (res: any) => {
          this.toaster.success('Success', 'Saved Successfully');
          this.addNewVariableGroupItemlFormSubmitted = false;
          this.vGroupId = res.Id;
          this.closeaddNewVariableGroupModel();
          const key = {
            Pu_id: data.PUID,
            VG_Id: 0,
          };
          this.getVariableGroupList(key);
          this.getVariableGroups();
          this.AddVariableType();
          this.addNewVariableGroupItemlForm.get('PUID')?.setValue(data.PUID);
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    } else {
      const data = {
        VG_Id: this.addNewVariableGroupItemlForm.get('VG_Id')?.value,
        VG_Desc:
          this.addNewVariableGroupItemlForm.get('groupDescription')?.value,
        VG_Order: this.addNewVariableGroupItemlForm.get('groupOrder')?.value,
        PUID: this.addNewVariableGroupItemlForm.get('PUID')?.value,
        VGTypeId: this.addNewVariableGroupItemlForm.get('vgGroupType')?.value,
        ParameterGroupTypeId: this.groupType ? this.groupType : 1, // defined in DB
        InputEventId: this.inputEventMode === true ? this.inputEvent : 0,
        OutputEventId: this.outputEventMode === true ? this.outputEvent : 0,
        TransactionTypeId: 2,
      };
      if (this.addNewVariableGroupItemlForm.invalid) {
        return;
      }
      this.machineParameterService.sumbitGroup(data).subscribe(
        () => {
          this.toaster.success('Success', 'Updated Successfully');
          this.addNewVariableGroupItemlForm.reset();
          this.isUpdateGroupVariable = false;
          this.addNewVariableGroupItemlFormSubmitted = false;
          this.closeaddNewVariableGroupModel();
          this.AddVariableType();
          this.updateParameterGrp(data);
          this.addNewVariableGroupItemlForm.get('PUID')?.setValue(data.PUID);
          const key = {
            Pu_id: data.PUID,
            VG_Id: 0,
          };
          this.getVariableGroupList(key);
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    }
  }

  AddVariableType(): void {
    const key = {
      variableGroupId: this.vGroupId,
      parameterGroupType: this.groupType, // defined in DB
      inputEventId: this.inputEventMode === true ? this.inputEvent : null,
      outputEventId: this.outputEventMode === true ? this.outputEvent : null,
    };
    this.qualityParameterService.AddVariableType(key).subscribe((res: any) => {
      const data = {
        Pu_id: this.addNewVariableGroupItemlForm.get('PUID')?.value,
        VG_Id: 0,
      };
      this.getVariableGroupList(data);
    });
  }

  onUpdateGroupVariable(row: any): void {
    this.refreshData();
    this.GetAllMasterEvents();
    this.vGroupId = row.VG_Id;
    this.addNewVariableGroupItemlForm.get('VG_Id')?.setValue(row.VG_Id);
    this.addNewVariableGroupItemlForm
      .get('groupDescription')
      ?.setValue(row.VG_Desc);
    this.addNewVariableGroupItemlForm.get('groupOrder')?.setValue(row.VG_Order);
    this.addNewVariableGroupItemlForm.get('PUID')?.setValue(row.PU_Id);
    this.addNewVariableGroupItemlForm.get('groupType')?.setValue(row.parameterGroupTypeId);
    this.addNewVariableGroupItemlForm.get('vgGroupType')?.setValue(row.VG_TypeId);
    this.isaddNewVariableGroupModelVisible = true;
    const val = this.groupTypeList.find(
      (c: any) => c.parameterGroupTypeId === row.parameterGroupTypeId
    )?.groupTypeName;
    this.isUpdateGroupVariable = true;
    if (val === 'Time') {
      this.isEventMode = false;
    }
    if (val === 'Event') {
      this.isEventMode = true;
      if (row.inputEventId) {
        this.inputEventMode = true;
        this.inputChecked = true;
        this.outputDisabled = true;
        this.inputDisabled = false;
      } else if (row.outputEventId) {
        this.outputEventMode = true;
        this.outputChecked = true;
        this.inputDisabled = true;
        this.outputDisabled = false;
      } else {
        this.refreshData();
      }
      // this.outputEventMode = true;
    }
    if (val === 'Manual') {
      this.isEventMode = false;
    }
    // if (this.parameterGroupType === 2) {
    //   const val = this.groupTypeList.find((c: any) => c.parameterGroupTypeId === this.parameterGroupType)?.groupTypeName;
    //   this.addNewVariableGroupItemlForm.get('groupType')?.setValue(val);
    // }
  }

  refreshData(): void {
    this.isEventMode = false;
    this.inputEventMode = false;
    this.inputChecked = false;
    this.outputEventMode = false;
    this.outputChecked = false;
    this.inputDisabled = false;
    this.outputDisabled = false;
    this.addNewVariableGroupItemlForm.get('inputEvent')?.reset();
    this.addNewVariableGroupItemlForm.get('outputEvent')?.reset();
  }

  onDeleteVariableGroup(element: any): void {
    const data = {
      VG_Id: element.VG_Id,
      VG_Desc: element.VG_Desc,
      VG_Order: element.VG_Order,
      PUID: element.PU_Id,
      TransactionTypeId: 3,
    };
    this.machineParameterService.sumbitGroup(data).subscribe(
      (res: any) => {

        if (res.ErrorMessage.includes('Related Variables First!')) {
          this.toaster.error('Error', 'Delete Related Parameter First!');
          return;
        }
        this.toaster.success('Success', 'Deleted Successfully');
        this.addNewVariableGroupItemlForm.reset();
        this.closeaddNewVariableGroupModel();
        const key = {
          Pu_id: data.PUID,
          VG_Id: 0,
        };
        this.getVariableGroupList(key);
        this.deleteGrp(data.VG_Id);
        this.addNewVariableGroupItemlForm.get('PUID')?.setValue(data.PUID);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  getVariableGroups(): void {
    const key = {
      puId: this.unitDetailForm.get('PUID')?.value,
    };
    // this.qualityParameterService.GetAllDataTypeIds(key).then((res: any) => {
    //   debugger;
    //   // this.Nodej = res;
    //   // const mergedSubjects = this.DNet.map((subject: any) => {
    //   //   const otherSubject = this.Nodej.find(
    //   //     (element: any) => element.vgId === subject.VG_Id
    //   //   );
    //   //   return { ...subject, ...otherSubject };
    //   // });
    //   // this.machineParameterVariableGroupData = new MatTableDataSource<any>(
    //   //   res
    //   // );
    //   this.getPaginator(this.machineParameterVariableGroupData, 0);
    // });
  }

  // Variable

  getVariable(): void {
    const vgId = this.getVariableForm.get('variableGroupList')?.value;
    this.addNewVariableItemlForm.get('VariableGroup')?.setValue(vgId);

    this.spinner.show();
    const data = {
      VG_Id: vgId === '' ? 0 : vgId,
      VA_Id: 0,
    };

    this.machineParameterService.getVariableList(data).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.machineParameterVariableData = new MatTableDataSource<any>(
          response.VariableList
        );
        this.getVariableGroups();
        this.getPaginator(this.machineParameterVariableData, 1);
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.message);
      }
    );
  }

  ParamterDataSourceChange(param: any): void {
    let selectedOption = param.value;
    // console.log(selectedOption);
    this.addNewVariableItemlForm.get('InputTag')?.setValue;
    if (selectedOption == 'O3 Edge' || selectedOption == 3) {
      //call to load all set of desktop functions to input tag
      this.PopulateDesktopFunctionList();
    } else {
      //clear above assigned list

      //clearing array with all memory references other ways are [] and length to 0
      this.desktopFunctionList.splice(0, this.desktopFunctionList.length);

      //setting text to empty
      this.addNewVariableItemlForm.get('InputTag')?.setValue('');
    }
  }
  PopulateDesktopFunctionList() {
    // this.desktopFunctionList
    // DesktopFunctionsList();
    const data = {
      UserId: JSON.parse(localStorage.user).UserId,
    };
    if (this.desktopFunctionList) {
      this.desktopFunctionList.splice(0, this.desktopFunctionList.length);
    }

    this.machineParameterService.DesktopFunctionsList(data).subscribe(
      (response: any) => {
        this.desktopFunctionList = response.inputTagList;
        // if(this.desktopFunctionList && this.desktopFunctionList.length>0)
        //  this.addNewVariableItemlForm.get('InputTag')?.setValue(this.desktopFunctionList[0].ItemId);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  openPlantGroupVariableModel(): void {
    this.isPlantGroupModelVisibleInVariable = true;
  }
  closePlantGroupVariableModel(): void {
    this.isPlantGroupModelVisibleInVariable = false;
  }
  getDataType1(): void {
    const data = {
      Id: 0,
    };
    this.machineParameterService.getDataType(data).subscribe(
      (response: any) => {
        this.dataType = response.masterList;
        this.addNewVariableItemlForm
          .get('DataType')
          ?.setValue(this.dataType[0].TypeId);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  getDataSource1(): void {
    const data = {
      Id: 0,
    };
    this.machineParameterService.getDatasource(data).subscribe(
      (response: any) => {
        this.dataSource = response.masterList;
        this.addNewVariableItemlForm
          .get('DataSource')
          ?.setValue(this.dataSource[0].TypeId);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetCalculationMethod1(): void {
    const data = {
      Id: 0,
    };
    this.machineParameterService.getCalculationMethod(data).subscribe(
      (response: any) => {
        this.calculationMethod = response.calculationMethodList;
        this.addNewVariableItemlForm
          .get('CalculationMethod')
          ?.setValue(this.calculationMethod[0].CM_Desc);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  openaddNewVariableModel(): void {
    if (this.addNewVariableItemlForm.get('VariableGroup')?.value !== '') {
      this.isUpdateVariable = false;
      this.resetVariableForm();
      this.isaddNewVariableModelVisible = true;
      this.PopulateDesktopFunctionList();
      // dropdowns
      this.getDataType1();
      this.getDataSource1();
      this.GetCalculationMethod1();
    } else {
      this.toaster.error('Error', 'Unit not selected!');
    }
  }
  closeaddNewVariableModel(): void {
    this.isaddNewVariableModelVisible = false;
  }

  onChangeGroupVariableInVariable(data: any): void {
    this.getVariableForm.get('variableGroupList')?.setValue(data.value);
    this.getVariable();
  }
  addNewVariableItemlFormSubmit(): any {
    if (!this.isUpdateVariable) {
      if (this.addNewVariableItemlForm.invalid) {
        return;
      }
      const data = {
        Var_Id: 0,
        Var_Desc: this.addNewVariableItemlForm.get('VariableGrpDesc')?.value,
        VG_Id: this.addNewVariableItemlForm.get('VariableGroup')?.value,
        DT_Id: this.addNewVariableItemlForm.get('DataType')?.value,
        DS_Id: this.addNewVariableItemlForm.get('DataSource')?.value,
        Eng_Units: this.addNewVariableItemlForm.get('EngUnits')?.value,
        Var_Precision:
          this.addNewVariableItemlForm.get('parameterPrecision')?.value,
        InputTag: this.addNewVariableItemlForm.get('InputTag')?.value,
        CalculationMethod:
          this.addNewVariableItemlForm.get('CalculationMethod')?.value,
        OutputTag: this.addNewVariableItemlForm.get('OutputTag')?.value,
        VarOrder: this.addNewVariableItemlForm.get('VariableGrpOrder')?.value,
        CreatedBy: JSON.parse(localStorage.user).UserId,
        TransactionTypeId: 1,
      };

      this.machineParameterService.sumbitVariables(data).subscribe(
        (response) => {
          this.toaster.success('Success', 'Saved Successfully');
          this.getVariableAfterUpsert(data.VG_Id);
          // this.updateParameter(data);
          this.resetVariableForm();
          this.addNewVariableItemlFormSubmitted = false;

          this.closeaddNewVariableModel();
        },
        (error) => {
          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    } else {
      if (this.addNewVariableItemlForm.invalid) {
        return;
      }
      this.spinner.show();

      const data = {
        Var_Id: this.addNewVariableItemlForm.get('varId')?.value,
        Var_Desc: this.addNewVariableItemlForm.get('VariableGrpDesc')?.value,
        VG_Id: this.addNewVariableItemlForm.get('VariableGroup')?.value,
        DT_Id: this.addNewVariableItemlForm.get('DataType')?.value,
        DS_Id: this.addNewVariableItemlForm.get('DataSource')?.value,
        Eng_Units: this.addNewVariableItemlForm.get('EngUnits')?.value,
        Var_Precision:
          this.addNewVariableItemlForm.get('parameterPrecision')?.value,
        InputTag: this.addNewVariableItemlForm.get('InputTag')?.value,
        CalculationMethod:
          this.addNewVariableItemlForm.get('CalculationMethod')?.value,
        OutputTag: this.addNewVariableItemlForm.get('OutputTag')?.value,
        VarOrder: this.addNewVariableItemlForm.get('VariableGrpOrder')?.value,
        CreatedBy: JSON.parse(localStorage.user).UserId,
        TransactionTypeId: 2,
      };
      this.machineParameterService.sumbitVariables(data).subscribe(
        (response) => {
          this.spinner.hide();
          this.toaster.success('Success', 'Update Successfully');
          debugger;
          this.updateParameter(data);
          this.resetVariableForm();
          this.isUpdateVariable = false;
          this.addNewVariableItemlFormSubmitted = false;
          this.closeaddNewVariableModel();
          // this.machineParameterVariableData = new MatTableDataSource<any>(this.machineParameterVariableData.filteredData);
          this.getVariableAfterUpsert(data.VG_Id);
          // this.getVariableAfterUpsert(data.VG_Id);
        },
        (error) => {
          this.spinner.hide();

          this.toaster.error('Error', error.ErrorMessage);
        }
      );
    }
  }
  onClickUpdateVariable(element: any): void {
    this.isUpdateVariable = true;
    this.addNewVariableItemlForm.get('varId')?.setValue(element.Var_Id);
    this.addNewVariableItemlForm
      .get('VariableGrpDesc')
      ?.setValue(element.Var_Desc);
    this.addNewVariableItemlForm.get('VariableGroup')?.setValue(element.VG_Id);
    this.addNewVariableItemlForm.get('DataType')?.setValue(element.DT_Id);
    this.addNewVariableItemlForm.get('DataSource')?.setValue(element.DS_Id);
    this.addNewVariableItemlForm.get('EngUnits')?.setValue(element.Eng_Units);
    this.addNewVariableItemlForm
      .get('parameterPrecision')
      ?.setValue(element.Var_Precision);
    this.addNewVariableItemlForm.get('InputTag')?.setValue(element.InputTag);
    this.addNewVariableItemlForm
      .get('CalculationMethod')
      ?.setValue(element.CalculationMethod);
    this.addNewVariableItemlForm.get('OutputTag')?.setValue(element.OutputTag);
    this.addNewVariableItemlForm
      .get('VariableGrpOrder')
      ?.setValue(element.VarOrder);
    this.isaddNewVariableModelVisible = true;
    // dropdowns
    this.getDataType();
    this.getDataSource();
    this.GetCalculationMethod();
    this.PopulateDesktopFunctionList();
  }
  deleteVariable(element: any): void {
    const data = {
      Var_Id: element.Var_Id,
      VG_Id: element.VG_Id,
      CreatedBy: JSON.parse(localStorage.user).UserId,
      TransactionTypeId: 3,
    };
    this.machineParameterService.sumbitVariables(data).subscribe(
      (response: any) => {
        // if (response.ErrorMessage.includes("Variables Deleted Successfully!")) {

        //   this.toaster.error("Error", "Delete Related Variables First!");
        //   return;
        // }
        this.toaster.success('Success', 'Deleted Successfully');
        this.deleteParam(data.Var_Id);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  getVariableAfterUpsert(vgId: any): void {
    const data = {
      VG_Id: vgId,
      VA_Id: 0,
    };
    this.machineParameterService.getVariableList(data).subscribe(
      (response: any) => {
        this.machineParameterVariableData = new MatTableDataSource<any>(
          response.VariableList
        );
        this.getPaginator(this.machineParameterVariableData, 1);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getDataType(): void {
    const data = {
      Id: 0,
    };
    this.machineParameterService.getDataType(data).subscribe(
      (response: any) => {
        this.dataType = response.masterList;
        // this.addNewVariableItemlForm.get('DataType')?.setValue(this.dataType[0].TypeId);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  getDataSource(): void {
    const data = {
      Id: 0,
    };
    this.machineParameterService.getDatasource(data).subscribe(
      (response: any) => {
        debugger;
        this.dataSource = response.masterList;
        // this.addNewVariableItemlForm.get('DataSource')?.setValue(this.dataSource[0].TypeId);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetCalculationMethod(): void {
    const data = {
      Id: 0,
    };
    this.machineParameterService.getCalculationMethod(data).subscribe(
      (response: any) => {
        this.calculationMethod = response.calculationMethodList;
        // this.addNewVariableItemlForm.get('CalculationMethod')?.setValue(this.calculationMethod[0].CM_Desc);
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  // popup for data type detail
  openDataTypeDetailPopup(element: any): void {
    this.dataTypeDetailMode = true;
    this.variableId = element.Var_Id;
    this.GetAllDataTypesDetail();
    this.dialog.open(this.displayDataTypeDetailPopup, {
      height: '93%',
      width: '90%',
    });
  }

  // Data type details CRUD

  GetAllDataTypesDetail(): void {
    const key = {
      variableId: this.variableId,
    };
    this.machineParameterService.GetAllDataTypesDetail(key).then((res: any) => {
      this.dataTypeDetailDataSource = new MatTableDataSource<any>(res);
      this.getPaginator(this.dataTypeDetailDataSource, 2);
    });
  }

  AddDataType(): any {
    const key = {
      varId: this.variableId,
      text: this.dataTypeDetailForm.value.text,
    };
    // tslint:disable-next-line:prefer-const
    let count = 0;
    const checkDuplicate = this.dataTypeDetailDataSource.filteredData;
    checkDuplicate.map((element) => {
      if (element.text === this.dataTypeDetailForm.value.text) {
        return count++;
      }
      return count;
    });
    if (count > 0) {
      this.toaster.error('Error', 'You cannot add duplicate text');
    } else {
      this.machineParameterService.AddDataType(key).subscribe((res: any) => {
        this.toaster.success('success', 'Data Type is added successfully');
        this.GetAllDataTypesDetail();
        this.dataTypeDetailMode = true;
        this.dataTypeDetailForm.get('text')?.reset();
      });
    }
  }

  editDataTypeDetail(element: any): void {
    this.dataTypeDetailMode = false;
    this.dataTypeId = element.dataTypeId;
    this.dataTypeDetailForm.get('text')?.setValue(element.text);
  }

  UpdateDataTypeDetail(): void {
    const key = {
      dataTypeId: this.dataTypeId,
      text: this.dataTypeDetailForm.value.text,
    };
    this.machineParameterService.UpdateDataType(key).subscribe((res: any) => {
      this.toaster.success('success', 'Data Type is updated successfully');
      this.GetAllDataTypesDetail();
      this.dataTypeDetailMode = true;
      this.dataTypeDetailForm.get('text')?.reset();
    });
  }

  DeleteDataType(element: any): void {
    const key = {
      dataTypeId: element.dataTypeId,
    };
    this.machineParameterService.DeleteDataType(key).subscribe((res: any) => {
      this.toaster.success('success', 'DataType Detail is deleted succesfully');
      this.GetAllDataTypesDetail();
    });
  }

  populateDisabledFields(): void {
    const group = this.getVariableForm.get('groupList')?.value;
    this.varUnitName = this.getVariableForm.get('UnitDataList')?.value;
    this.addNewVariableItemlForm.get('VariableGroup')?.setValue(group);
  }
  // Variable area end

  closePlantGroupSpecVariableModel(): void {
    this.isPlantGroupModelVisibleInVariableSpec = false;
  }
  onChangeGroupVariableInVariableInSpec(data: any): void {
    this.getVariableSpecForm.get('variableGroupList')?.setValue(data.value);
    this.addNewVariableSpecForm.get('VgId')?.setValue(data.value);
    this.getVariableSpec();
  }
  addNewVariableSpecModel(): void {
    const groupId = this.addNewVariableSpecForm.get('VgId')?.value;
    if (groupId !== '') {
      this.isaddNewVariableSpecModelVisible = true;
      this.isUpdateVariableSpec = false;
      this.resetVariableSpecForm();
      // dropdown
      this.getVariableListInSpec1();
      this.loadParameterSpecification_Desc();
      this.getLinkProduct();
    } else {
      this.toaster.error('Error', 'Unit not selected!');
    }
  }

  filterLink(): void {
    if (!this.filteredLinkProduct) {
      return;
    }
    let search = this.linkedControl.value;
    if (!search) {
      this.filteredLinked.next(this.filteredLinkProduct);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredLinked.next(
      this.filteredLinkProduct.filter(
        (value: any) => value.TypeName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  closeaddNewVariableSpecModel(): void {
    this.isaddNewVariableSpecModelVisible = false;
  }
  getVariableSpec(): void {
    const vgId = this.getVariableSpecForm.get('variableGroupList')?.value;
    this.addNewVariableSpecForm.get('VgId')?.setValue(vgId);
    const data = {
      UserId: JSON.parse(localStorage.user).UserId,
      VG_Id: vgId,
      VA_Id: 0,
      Pu_id: this.getVariableSpecForm.get('PUID')?.value,
    };
    this.spinner.show();
    this.machineParameterService.getParameterSpecificationsList(data).subscribe(
      (response: any) => {
        if (response.HasError) {
          this.spinner.hide();
          this.toaster.error('Error', response.ErrorMessage);
        } else {
          this.machineParameterVariableSpecList = new MatTableDataSource<any>(
            response.ParameterSpecificationsList
          );
          this.spinner.hide();
          this.getPaginator(this.machineParameterVariableSpecList, 2);
        }
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.message);
      }
    );
  }
  getLinkProduct(): void {
    const key = {
      Id: this.getVariableSpecForm.get('PUID')?.value,
    };
    this.machineParameterService.parameterLinkProduct(key).subscribe(
      (response: any) => {
        this.LinkProductList = response.masterList;
        this.LinkProductList.forEach((element: any) => {
          element.lowerCaseProdDesc = element.ProdDesc;
        });
        this.filteredLinkProduct = this.LinkProductList.slice();
        this.filteredLinked.next(this.filteredLinkProduct);
        this.filterLink();
        // this.addNewVariableSpecForm.get('LinkProduct')?.setValue(this.LinkProductList[0].TypeName);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  onClickUpdateVariableSpec(element: any): void {
    this.isUpdateVariableSpec = true;
    this.isaddNewVariableSpecModelVisible = true;
    // dropdowns
    this.getVariableListInSpec();
    this.getLinkProduct();
    // populate fields
    this.addNewVariableSpecForm.get('Desc')?.setValue(element.Parameter_Id);
    this.addNewVariableSpecForm
      .get('Parameter_Specs_Id')
      ?.setValue(element.Parameter_Specs_Id);
    this.addNewVariableSpecForm.get('LCL')?.setValue(element.LCL);
    this.addNewVariableSpecForm.get('LWL')?.setValue(element.LWL);
    this.addNewVariableSpecForm.get('TGT')?.setValue(element.TGT);
    this.addNewVariableSpecForm.get('UWL')?.setValue(element.UWL);
    this.addNewVariableSpecForm.get('UCL')?.setValue(element.UCL);
    this.addNewVariableSpecForm.get('LSL')?.setValue(element.LSL);
    this.addNewVariableSpecForm.get('USL')?.setValue(element.USL);
    this.addNewVariableSpecForm
      .get('LinkProduct')
      ?.setValue(element.Link_Product);
  }

  addNewVariableSpecItemFormSubmit(): void {
    let Desc = '';
    const key = {
      VG_Id: this.getVariableSpecForm.get('variableGroupList')?.value,
      VA_Id: this.addNewVariableSpecForm.get('Desc')?.value,
    };
    this.machineParameterService.getVariableList(key).subscribe(
      (response: any) => {
        const variableList = response.VariableList;
        const varId = this.addNewVariableSpecForm.get('Desc')?.value;
        const variable = variableList.find((c: any) => c.Var_Id === varId);
        Desc = variable.Var_Desc;
        if (!this.isUpdateVariableSpec) {
          // add
          if (this.addNewVariableSpecForm.invalid) {
            return;
          }
          let linkProduct = [];
          linkProduct = this.addNewVariableSpecForm.get('LinkProduct')?.value;
          linkProduct = linkProduct.filter((a: any) => a !== '0');
          linkProduct.forEach((element: any) => {
            const data = {
              TransactionTypeId: 1,
              Parameter_Specs_Id: 0,
              Parameter_Id: this.addNewVariableSpecForm.get('Desc')?.value,
              Desc,
              LinkProduct: element,
              LCL: this.addNewVariableSpecForm.get('LCL')?.value,
              LWL: this.addNewVariableSpecForm.get('LWL')?.value,
              TGT: this.addNewVariableSpecForm.get('TGT')?.value,
              UWL: this.addNewVariableSpecForm.get('UWL')?.value,
              UCL: this.addNewVariableSpecForm.get('UCL')?.value,
              LSL: this.addNewVariableSpecForm.get('LSL')?.value,
              USL: this.addNewVariableSpecForm.get('USL')?.value,
            };

            // tslint:disable-next-line:no-shadowed-variable
            this.machineParameterService
              .sumbitParameterSpecifications(data)
              .subscribe(
                (response: any) => {
                  if (response.HasError) {
                    this.toaster.error('Error', response.ErrorMessage);
                  } else {
                    if (
                      response.ErrorMessage ===
                      'C1-Choose different Parameter Name or Link Product'
                    ) {
                      this.toaster.error('Error', response.ErrorMessage);
                    } else {
                      this.toaster.success('Success', response.ErrorMessage);
                      this.closeaddNewVariableSpecModel();
                      this.getVariableSpec();
                      // this.getVariableSpec();
                    }
                  }
                },
                (error) => {
                  this.toaster.error(' Error', error.message);
                }
              );
          });
          this.getVariableSpec();
        } else {
          // update
          if (this.addNewVariableSpecForm.invalid) {
            return;
          }
          const data = {
            TransactionTypeId: 2,
            Parameter_Specs_Id:
              this.addNewVariableSpecForm.get('Parameter_Specs_Id')?.value,
            Parameter_Id: this.addNewVariableSpecForm.get('Desc')?.value,
            Desc,
            LinkProduct: this.addNewVariableSpecForm.get('LinkProduct')?.value,
            LCL: this.addNewVariableSpecForm.get('LCL')?.value,
            LWL: this.addNewVariableSpecForm.get('LWL')?.value,
            TGT: this.addNewVariableSpecForm.get('TGT')?.value,
            UWL: this.addNewVariableSpecForm.get('UWL')?.value,
            UCL: this.addNewVariableSpecForm.get('UCL')?.value,
            LSL: this.addNewVariableSpecForm.get('LSL')?.value,
            USL: this.addNewVariableSpecForm.get('USL')?.value,
          };
          debugger;
          this.machineParameterService
            .sumbitParameterSpecifications(data)
            .subscribe(
              (response: any) => {
                if (response.HasError) {
                  this.toaster.error('Error', response.ErrorMessage);
                } else {
                  this.isUpdateVariableSpec = false;
                  this.toaster.success('Success', 'Updated Successfully');
                  this.updateParameterSpec(data);
                  this.closeaddNewVariableSpecModel();
                }
              },
              (error) => {
                this.toaster.error('Error', error.message);
              }
            );
        }
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  deleteVariableSpec(element: any): void {
    const data = {
      // TransactionTypeId: 3,
      Var_Spec_Id: element.Parameter_Specs_Id,
      // Parameter_Id: element.Parameter_Id,
      // Desc: element.Parameter_Name,
      // LinkProduct: element.Link_Product,
      // LCL: element.LCL,
      // LWL: element.LWL,
      // TGT: element.TGT,
      // UWL: element.UWL,
      // UCL: element.UCL,
      // LSL: element.LSL,
      // USL: element.USL,
    };
    this.machineParameterService.deletevariableparameterspec(data).subscribe(
      (response: any) => {
        this.showPopupMsg(response.message);
        // if (response) {
        //   this.toaster.error('Error', response);
        // } else {
        //   // this.toaster.success('Success', 'Deleted Successfully');
        this.getVariableSpec();
        //   // this.deleteSpec(data.Parameter_Specs_Id);
        // }
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  showPopupMsg(msg: any): any {
    if (msg.substring(2, 1) === '0') {
      this.toaster.success('Success', msg.substring(3, msg.length));
    } else if (msg.substring(2, 1) === '2') {
      this.toaster.warning('Warning', msg.substring(3, msg.length));
    } else {
      this.toaster.error('Error', msg.substring(3, msg.length));
    }
  }

  getVariableListInSpec(): void {
    const vgId = this.addNewVariableSpecForm.get('VgId')?.value;
    this.spinner.show();
    const data = {
      VG_Id: vgId,
      VA_Id: 0,
    };
    this.machineParameterService.getVariableList(data).subscribe(
      (respose: any) => {
        debugger;
        this.spinner.hide();
        if (respose.HasError) {
          this.spinner.hide();

          this.toaster.error('Error', respose.ErrorMessage);
        } else {
          this.spinner.hide();

          this.variableListInSpec = respose.VariableList;
          // this.addNewVariableSpecForm.get('Desc')?.setValue(this.variableListInSpec[0].Var_Id);
        }
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }
  loadParameterSpecification_Desc(): void {
    const vgId = this.addNewVariableSpecForm.get('VgId')?.value;
    this.spinner.show();
    const data = {
      UserId: JSON.parse(localStorage.user).UserId,
      Id: vgId,
    };
    this.machineParameterService.parameterList(data).subscribe(
      (respose: any) => {
        debugger;
        this.spinner.hide();
        if (respose.HasError) {
          this.spinner.hide();

          this.toaster.error('Error', respose.ErrorMessage);
        } else {
          this.spinner.hide();

          this.parameterListInSpec = respose.masterList;
          // this.addNewVariableSpecForm.get('Desc')?.setValue(this.variableListInSpec[0].Var_Id);
        }
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }
  getVariableListInSpec1(): void {
    const vgId = this.addNewVariableSpecForm.get('VgId')?.value;
    this.spinner.show();
    const data = {
      VG_Id: vgId,
      VA_Id: 0,
    };
    this.machineParameterService.getVariableList(data).subscribe(
      (respose: any) => {
        this.spinner.hide();
        if (respose.HasError) {
          this.spinner.hide();

          this.toaster.error('Error', respose.ErrorMessage);
        } else {
          this.spinner.hide();

          this.variableListInSpec = respose.VariableList;
          this.addNewVariableSpecForm
            .get('Desc')
            ?.setValue(this.variableListInSpec[0].Var_Id);
        }
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.message);
      }
    );
  }

  // Delete/Update/Add single row from table ParameterGroup/Parameter/Parameter Specification

  updateParameterGrp(data: any): void {
    const paramSpec = this.machineParameterVariableGroupData.filteredData.find(
      (a) => a.VG_Id === data.VG_Id
    );
    const removeIndex =
      this.machineParameterVariableGroupData.filteredData.findIndex((i) => {
        return i.VG_Id === data.VG_Id;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableGroupData.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableGroupData.filteredData.push(paramSpec);
      this.machineParameterVariableGroupData = new MatTableDataSource<any>(
        this.machineParameterVariableGroupData.filteredData
      );
      this.getPaginator(this.machineParameterVariableGroupData, 0);
    }
  }
  deleteGrp(index: any): void {
    const removeIndex =
      this.machineParameterVariableGroupData.filteredData.findIndex((i) => {
        return i.VG_Id === index;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableGroupData.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableGroupData = new MatTableDataSource<any>(
        this.machineParameterVariableGroupData.filteredData
      );
      this.getPaginator(this.machineParameterVariableGroupData, 0);
    }
  }

  // Param

  updateParameter(data: any): void {
    const paramSpec = this.machineParameterVariableData.filteredData.find(
      (a) => a.Var_Id === data.Var_Id
    );
    paramSpec.Var_Id = data.Var_Id;
    paramSpec.Var_Desc = data.Var_Desc;
    paramSpec.VG_Id = data.VG_Id;
    paramSpec.DT_Id = data.DT_Id;
    paramSpec.DS_Id = data.DS_Id;
    paramSpec.Eng_Units = data.Eng_Units;
    paramSpec.Var_Precision = data.Var_Precision;
    paramSpec.InputTag = data.InputTag;
    paramSpec.CalculationMethod = data.CalculationMethod;
    paramSpec.OutputTag = data.OutputTag;
    paramSpec.VarOrder = data.VarOrder;
    debugger;
    const removeIndex =
      this.machineParameterVariableData.filteredData.findIndex(function (i) {
        return i.Var_Id === data.Var_Id;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableData.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableData.filteredData.push(paramSpec);
      this.machineParameterVariableData = new MatTableDataSource<any>(
        this.machineParameterVariableData.filteredData
      );

      this.getPaginator(this.machineParameterVariableData, 1);
    }
  }

  // updateParameter(data: any): void {
  //   var removeIndex = this.machineParameterVariableData.filteredData.findIndex(
  //     function (i) {
  //       return i.Var_Id === data.Var_Id;
  //     }
  //   );
  //   if (removeIndex >= 0) {
  //     var items = this.machineParameterVariableData.filteredData.splice(
  //       removeIndex,
  //       1
  //     );
  //     this.machineParameterVariableData.filteredData.push(data);
  //     this.machineParameterVariableData = new MatTableDataSource<any>(
  //       this.machineParameterVariableData.filteredData
  //     );

  //     this.getPaginator(this.machineParameterVariableData, 1);
  //   }
  // }
  deleteParam(index: any) {
    const removeIndex =
      this.machineParameterVariableData.filteredData.findIndex(function (i) {
        return i.Var_Id === index;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableData.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableData = new MatTableDataSource<any>(
        this.machineParameterVariableData.filteredData
      );
      this.getPaginator(this.machineParameterVariableData, 1);
    }
  }

  // Spec

  updateParameterSpec(data: any): void {
    const paramSpec = this.machineParameterVariableSpecList.filteredData.find(
      (a) => a.Parameter_Specs_Id === data.Parameter_Specs_Id
    );
    paramSpec.Parameter_Specs_Id = data.Parameter_Specs_Id;
    paramSpec.Parameter_Id = data.Parameter_Id;
    paramSpec.Var_Desc = data.Parameter_Name;
    paramSpec.Link_Product = data.Link_Product;
    paramSpec.LSL = data.LSL;
    paramSpec.LCL = data.LCL;
    paramSpec.LWL = data.LWL;
    paramSpec.TGT = data.TGT;
    paramSpec.UWL = data.UWL;
    paramSpec.UCL = data.UCL;
    paramSpec.USL = data.USL;
    const removeIndex =
      this.machineParameterVariableSpecList.filteredData.findIndex(function (
        i
      ) {
        return i.Parameter_Specs_Id === data.Parameter_Specs_Id;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableSpecList.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableSpecList.filteredData.push(paramSpec);
      this.machineParameterVariableSpecList = new MatTableDataSource<any>(
        this.machineParameterVariableSpecList.filteredData
      );
      this.getPaginator(this.machineParameterVariableSpecList, 2);
      this.getVariableSpec();
    }
  }
  deleteSpec(index: any) {
    const removeIndex =
      this.machineParameterVariableSpecList.filteredData.findIndex(function (
        i
      ) {
        return i.Parameter_Specs_Id === index;
      });
    if (removeIndex >= 0) {
      const items = this.machineParameterVariableSpecList.filteredData.splice(
        removeIndex,
        1
      );
      this.machineParameterVariableSpecList = new MatTableDataSource<any>(
        this.machineParameterVariableSpecList.filteredData
      );
      this.getPaginator(this.machineParameterVariableSpecList, 2);
    }
  }

  // parent node
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  // open model
  openPlantGroupModel(): void {
    this.isPlantGroupModelVisible = true;
    // this.plantGroupSource.data = plantGroupData;
    this.treeControl.dataNodes = this.plantGroupSource.data;
    this.expandAllNode();
  }

  // close model
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }

  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  // Icon
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  variableGroupFormReset(): void {
    this.addNewVariableGroupItemlForm.get('groupDescription')?.setValue('');
    this.addNewVariableGroupItemlForm.get('groupOrder')?.setValue('');
    this.addNewVariableGroupItemlForm.get('groupType')?.setValue('');
  }
  resetVariableForm(): void {
    this.addNewVariableItemlForm.get('varId')?.setValue('');
    this.addNewVariableItemlForm.get('VariableGrpDesc')?.setValue('');
    this.addNewVariableItemlForm.get('DataType')?.setValue('');
    this.addNewVariableItemlForm.get('DataSource')?.setValue('');
    this.addNewVariableItemlForm.get('EngUnits')?.setValue('');
    this.addNewVariableItemlForm.get('parameterPrecision')?.setValue('');
    this.addNewVariableItemlForm.get('InputTag')?.setValue('');
    this.addNewVariableItemlForm.get('CalculationMethod')?.setValue('');
    this.addNewVariableItemlForm.get('OutputTag')?.setValue('');
    this.addNewVariableItemlForm.get('VariableGrpOrder')?.setValue('');
  }
  resetVariableSpecForm(): void {
    this.addNewVariableSpecForm.get('Parameter_Specs_Id')?.setValue('');
    this.addNewVariableSpecForm.get('LinkProduct')?.setValue('');
    this.addNewVariableSpecForm.get('LCL')?.setValue('');
    this.addNewVariableSpecForm.get('LWL')?.setValue('');
    this.addNewVariableSpecForm.get('TGT')?.setValue('');
    this.addNewVariableSpecForm.get('UWL')?.setValue('');
    this.addNewVariableSpecForm.get('UCL')?.setValue('');
    this.addNewVariableSpecForm.get('LSL')?.setValue('');
    this.addNewVariableSpecForm.get('USL')?.setValue('');
    this.addNewVariableSpecForm.get('Desc')?.setValue('');
  }
  applyFilterForVariableGroup(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.machineParameterVariableGroupData.filter = filterValue
      .trim()
      .toLowerCase();
  }
  applyFilterForVariable(event: any): void {
    debugger;
    const filterValue = (event.target as HTMLInputElement).value;
    this.machineParameterVariableData.filter = filterValue.trim().toLowerCase();
  }
  applyFilterForVariableSpec(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.machineParameterVariableSpecList.filter = filterValue
      .trim()
      .toLowerCase();
  }
  getPaginator(dataSource: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataSource.paginator = this.paginator.toArray()[index];
      dataSource.sort = this.sort.toArray()[index];
    }, 1000);
  }

  //
  isUnitDataSelected(): boolean {
    const unit = this.unitDetailForm.get('UnitDataList')?.value;
    if (unit !== '') {
      return true;
    } else {
      return false;
    }
  }

  selectAllItems() {
    this.isSelected = !this.isSelected;

    if (this.isSelected) {
      const filterdArray = this.filteredLinkProduct?.map(
        (item: any) => item?.TypeName
      );
      this.addNewVariableSpecForm
        .get('LinkProduct')
        ?.setValue([...filterdArray, '0']);
    } else {
      this.addNewVariableSpecForm.get('LinkProduct')?.setValue([]);
    }
  }

  checkForAll(){
    let linkProduct = [];
    linkProduct = this.addNewVariableSpecForm.get('LinkProduct')?.value;
    linkProduct = linkProduct.filter((a: any) => a !== '0');
    if(linkProduct.length !== this.LinkProductList.length){
      this.addNewVariableSpecForm.get('LinkProduct')?.setValue(linkProduct);
      this.isSelected = false;
    }
    else{
      this.isSelected = true;
      this.addNewVariableSpecForm.get('LinkProduct')?.setValue([...linkProduct,'0']);
    }
  }

  // menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetGroupType(): void {
    this.machineParameterService.GetGroupType().subscribe(
      (data: any) => {
        this.vgGroupTypeList = data;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
