<div>
  <div class="content-top-box ">
    <ul class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
        <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
          (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
      </li>
    </ul>
    <!-- <div class="tab-content-top-box">
      <h1 class="main-title">Quality Control </h1>
    </div> -->
  </div>
  <mat-card-title class="mat-card-title Headingmain">Warehouse Pallet Received</mat-card-title>
</div>

<mat-card>
  <div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />"></ngx-spinner>
    <form [formGroup]="warehouseForm">
      <div style="display: flex;">
        <div style="width: 80%;">
          <div class="content-top-row ">
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-filed-rem-item">
              <mat-label class="placeholder-title">Group</mat-label>
              <mat-select formControlName="groupList" panelClass="testClass" name="Group"
                (selectionChange)="GetPlants()">
                <mat-select-filter
                  [placeholder]="'Search'"
                  [displayMember]="'TypeName'"
                  [array]="groupList"
                  (filteredReturn)="filteredGroups = $event"
                ></mat-select-filter>
                <mat-option *ngFor=" let group of filteredGroups" [value]="group.TypeId">
                  {{ group?.TypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="plantList.length>1" appearence="fill"
              class="top-row-first-filed-item top-row-filed-rem-item">
              <mat-label class="placeholder-title">Plant</mat-label>
              <mat-select formControlName="plantList" panelClass="testClass" name="Group"
                (selectionChange)="GetItemInfo()">
                <mat-select-filter
                  [placeholder]="'Search'"
                  [displayMember]="'DeptDesc'"
                  [array]="plantList"
                  (filteredReturn)="filteredPlants = $event"
                ></mat-select-filter>
                <mat-option *ngFor="let plant of filteredPlants" [value]="plant.DeptId">
                  {{ plant?.DeptDesc }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-filed-rem-item">
              <mat-label class="placeholder-title">Item</mat-label>
              <mat-select formControlName="item" panelClass="testClass" name="Group"
                (selectionChange)="GetItemInfoDesc($event)">
                <mat-select-filter
                  [placeholder]="'Search'"
                  [displayMember]="'ItemCode'"
                  [array]="itemInfoList"
                  (filteredReturn)="filteredItems = $event"
                ></mat-select-filter>
                <mat-option *ngFor="let item of filteredItems" [value]="item.ItemCode">
                  {{ item?.ItemCode }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-filed-rem-item">
              <mat-label class="placeholder-title">Item Description</mat-label>
              <mat-select formControlName="itemDesc" panelClass="testClass" name="Group"
                (selectionChange)="GetDescInfoItem($event)">
                <mat-select-filter
                  [placeholder]="'Search'"
                  [displayMember]="'ItemDesc'"
                  [array]="itemInfoList"
                  (filteredReturn)="filteredItems = $event"
                ></mat-select-filter>
                <mat-option *ngFor="let itemDesc of filteredItems" [value]="itemDesc.ItemDesc">
                  {{ itemDesc?.ItemDesc }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-filed-rem-item">
              <mat-label class="placeholder-title">ProcessOrder</mat-label>
              <mat-select formControlName="procOrder" panelClass="testClass" name="Group">
                <!-- <mat-option [value]="0">ALL</mat-option> -->
                <mat-select-filter
                  [placeholder]="'Search'"
                  [displayMember]="'TypeName'"
                  [array]="processOrderList"
                  (filteredReturn)="filteredProcessOrders = $event"
                ></mat-select-filter>
                <mat-option *ngFor="let order of filteredProcessOrders" [value]="order.TypeId">
                  {{ order?.TypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div style="display: flex;">
        <div style="width: 43%;">
          <div class="content-top-row ">
            <mat-form-field appearence="fill" class="top-row-first-filed-item top-row-filed-rem-item" style="width: 220px;">
              <mat-label class="placeholder-title">Scan BatchNo</mat-label>
              <input matInput formControlName="scantBatchNo" name="scantBatchNo" (change)="onChangeBatch()" (keyup.enter)="onChangeBatch()" />
            </mat-form-field>
          </div>
        </div>
        <div style="width: 50%;">
          <div class="content-top-row ">
            <div class="p-grid p-fluid" style="text-align: end;">
              <div style="margin-top: 15px;">
                <button type="button" mat-raised-button color="warn"  *ngIf="IsWrite"
                  style="background-color: #0f0fd1;font-size: 14px!important;" (click)="MoveBatchInfoDataSource()">
                  <span style="font-size: 12px;color: white;">Move All</span></button>

              </div>
            </div>
          </div>
        </div>
        <div style="width: 5%;">
          <div class="content-top-row ">
            <div class="p-grid p-fluid" style="text-align: end;">
              <div style="margin-left: 2px; margin-top: 15px;">
                <button type="button" mat-raised-button color="warn" style="background-color: #0f0fd1;font-size: 14px;"
                  (click)="clearBatchInfoDataData()"><span style="font-size: 12px;color: white;">Clear
                    All</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
  <mat-card-content>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-6 p-lg-6">
        <h3 class="mat-card-title Headingmain">Pallet Info</h3>
        <mat-card>
          <div class="p-grid p-fluid">
            <div class="p-col-1 p-md-6 p-lg-6">
            </div>
            <div class="p-col-11 p-md-6 p-lg-6 m0 p0" style="font-size: 10px;">
              <mat-form-field appearance="fill" class="field-fill" style="display: flex; justify-content: flex-end; ">
                <mat-label>Search</mat-label>
                <input [readonly]="false" matInput class="input-text" autocomplete="off"
                  (keyup)="applyFilterForwareHouseDataSource($event)" />
              </mat-form-field>
            </div>
          </div>
          <div class="content-row-flex-both-end m0 p0">
            <table mat-table [dataSource]="wareHouseDataSource" class="basic-table plant-model-table" matSort>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                  <p class="header-text">Action</p>
                </th>
                <td mat-cell *matCellDef="let element">
                  <button mat-icon-button class="edit-btn" aria-label="Edit Row"
                    (click)="openPopupViewForGetPalletInfo(element)" matTooltip="View" matTooltipClass="custom-tooltip"
                    matTooltipPosition="above" style="background-color: lightseagreen;">
                    <svg width="12" height="12" class="svg-icon" viewBox="0 0 20 20">
                      <path fill="white"
                        d="M19.471,8.934L18.883,8.34c-2.096-2.14-4.707-4.804-8.903-4.804c-4.171,0-6.959,2.83-8.996,4.897L0.488,8.934c-0.307,0.307-0.307,0.803,0,1.109l0.401,0.403c2.052,2.072,4.862,4.909,9.091,4.909c4.25,0,6.88-2.666,8.988-4.807l0.503-0.506C19.778,9.737,19.778,9.241,19.471,8.934z M9.98,13.787c-3.493,0-5.804-2.254-7.833-4.3C4.182,7.424,6.493,5.105,9.98,5.105c3.536,0,5.792,2.301,7.784,4.332l0.049,0.051C15.818,11.511,13.551,13.787,9.98,13.787z">
                      </path>
                      <circle fill="white" cx="9.98" cy="9.446" r="1.629"></circle>
                    </svg>
                  </button>
                </td>
              </ng-container>
              <ng-container matColumnDef="palletId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">PalletId</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.PalletId }} </td>
              </ng-container>
              <ng-container matColumnDef="palletLotCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">PalletLotCode</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.PalletLotCode }} </td>
              </ng-container>
              <ng-container matColumnDef="noOfBox">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">NoOfBox</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.NoOfBox }} </td>
              </ng-container>
              <ng-container matColumnDef="noOfItem">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">NoOfItem</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.NoOfItem }} </td>
              </ng-container>
              <ng-container matColumnDef="createdOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">CreatedOn</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.CreatedOn | date: 'dd/MM/YYYY HH:mm:ss' }} </td>
              </ng-container>
              <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">CreatedBy</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.CreatedBy }} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="wareHouseColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: wareHouseColumns;"
                [ngClass]="{highlighted: selectedRowIndex === row.PalletId}" (click)="updatehighlight(row)"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" style="text-align: center;" colspan="7">No Data Found</td>
              </tr>
            </table>
          </div>
          <div>
            <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
            </mat-paginator>

          </div>
        </mat-card>
      </div>
      <div class="p-col-12 p-md-6 p-lg-6">
        <h2 class="mat-card-title Headingmain">Selected Batch Info</h2>
        <mat-card>
          <div class="p-grid p-fluid">
            <div class="p-col-1 p-md-6 p-lg-6">
            </div>
            <div class="p-col-11 p-md-6 p-lg-6 m0 p0" style="font-size: 10px;">
              <mat-form-field appearance="fill" class="field-fill" style="display: flex; justify-content: flex-end; ">
                <mat-label>Search</mat-label>
                <input [readonly]="false" matInput class="input-text" autocomplete="off"
                  (keyup)="applyFilterForSelectedBatchInfo($event)" />
              </mat-form-field>
            </div>
          </div>
          <div class="content-row-flex-both-end m0 p0">
            <table mat-table [dataSource]="batchInfoDataSource" class="basic-table plant-model-table" matSort>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                  <p class="header-text">Action</p>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="row-action-btns">
                    <button mat-icon-button class="tree-view-btn" aria-label="View Row Tree" pTooltip="Delete"
                      tooltipPosition="top" (click)="deleteBatchInfoDataData(element)"
                      style="margin-left:-5px;border-radius: 50%;">
                      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0 10C0 4.47715 4.47715 0 10 0H24C29.5228 0 34 4.47715 34 10V24C34 29.5228 29.5228 34 24 34H10C4.47715 34 0 29.5228 0 24V10Z"
                          fill="#F85439" />
                        <circle cx="17" cy="17" r="14" fill="#F85439" />
                        <path
                          d="M20.9157 12.2611H18.874L18.2907 11.6777H15.374L14.7907 12.2611H12.749V13.4277H20.9157V12.2611ZM13.3324 21.0111C13.3324 21.3205 13.4553 21.6172 13.6741 21.836C13.8929 22.0548 14.1896 22.1777 14.499 22.1777H19.1657C19.4751 22.1777 19.7719 22.0548 19.9906 21.836C20.2094 21.6172 20.3324 21.3205 20.3324 21.0111V14.0111H13.3324V21.0111Z"
                          fill="white" />
                      </svg>
                    </button>
                    <button mat-icon-button class="edit-btn" aria-label="Edit Row"
                      (click)="openPopupViewForGetPalletInfo(element)" matTooltip="View"
                      matTooltipClass="custom-tooltip" matTooltipPosition="above"
                      style="background-color: lightseagreen;margin-left: 4px; border-radius: 50%;">
                      <svg width="12" height="12" class="svg-icon" viewBox="0 0 20 20">
                        <path fill="white"
                          d="M19.471,8.934L18.883,8.34c-2.096-2.14-4.707-4.804-8.903-4.804c-4.171,0-6.959,2.83-8.996,4.897L0.488,8.934c-0.307,0.307-0.307,0.803,0,1.109l0.401,0.403c2.052,2.072,4.862,4.909,9.091,4.909c4.25,0,6.88-2.666,8.988-4.807l0.503-0.506C19.778,9.737,19.778,9.241,19.471,8.934z M9.98,13.787c-3.493,0-5.804-2.254-7.833-4.3C4.182,7.424,6.493,5.105,9.98,5.105c3.536,0,5.792,2.301,7.784,4.332l0.049,0.051C15.818,11.511,13.551,13.787,9.98,13.787z">
                        </path>
                        <circle fill="white" cx="9.98" cy="9.446" r="1.629"></circle>
                      </svg>
                    </button>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="palletId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">PalletId</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.PalletId }} </td>
              </ng-container>
              <ng-container matColumnDef="palletLotCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">PalletLotCode</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.PalletLotCode }} </td>
              </ng-container>
              <ng-container matColumnDef="noOfBox">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">NoOfBox</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.NoOfBox }} </td>
              </ng-container>
              <ng-container matColumnDef="noOfItem">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">NoOfItem</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.NoOfItem }} </td>
              </ng-container>
              <ng-container matColumnDef="createdOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">CreatedOn</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.CreatedOn | date: 'dd/MM/YYYY HH:mm:ss' }} </td>
              </ng-container>
              <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="">
                  <p class="header-text">CreatedBy</p>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.CreatedBy }} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="wareHouseColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: wareHouseColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" style="text-align: center;" colspan="7">No Data Found</td>
              </tr>
            </table>
          </div>
          <div>
            <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
            </mat-paginator>

          </div>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content>
    <div class="p-grid p-fluid">
      <div class="p-col-0 p-md-7 p-lg-7">
      </div>
      <div class="p-col-12 p-md-5 p-lg-5">
        <div class="p-grid p-fluid">
          <div class="p-col-6 p-md-7 p-lg-7" style="text-align: end;">
            <button mat-raised-button color="warn" style="background-color: #0f0fd1;" *ngIf="IsWrite"
              (click)="openPopUpViewForWareHouseReceived()"><span style="font-size: 12px;color: white;">Warehouse
                Received</span></button>
          </div>
          <div class="p-col-6 p-md-5 p-lg-5" style="text-align: start;">
            <button mat-raised-button color="warn" style="background-color: #0f0fd1;"
              (click)="warehouseHistoryModal()"><span style="font-size: 12px;color: white;">Warehouse
                History</span></button>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>
<!--  Modal Template
  -->
<ng-template #displayBatchInfoModal style="margin-left: 5%;">

  <form>
    <div mat-dialog-title>
      <div class="d-flex justify-content-between align-items-center" style="font-size: 10px;">
        <h1>Batch Info</h1>
        <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
          <i class="fa fa-times" style="color: #fff"></i>
        </button>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-12">

      <div class="p-grid p-fluid">
        <div class="p-col-1 p-md-6 p-lg-6">
        </div>
        <div class="p-col-11 p-md-6 p-lg-6 m0 p0" style="font-size: 10px;">
          <mat-form-field appearance="fill" class="field-fill"
            style="display: flex; justify-content: flex-end;margin-right: 10px; ">
            <mat-label>Search</mat-label>
            <input [readonly]="false" matInput class="input-text" autocomplete="off"
              (keyup)="applyFilterForBatchInfo($event)" />
          </mat-form-field>
        </div>
      </div>
      <div class="content-row-flex-both-end m0 p0">
        <table mat-table [dataSource]="batchInfoViewDataSource" class="basic-table plant-model-table" matSort>

          <ng-container matColumnDef="BatchInfoId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <p class="header-text">BatchInfold</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.BatchInfoId}} </td>
          </ng-container>
          <ng-container matColumnDef="ProcessOrder">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <p class="header-text">ProcessOrder</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.ProcessOrder}} </td>
          </ng-container>
          <ng-container matColumnDef="PPD_Num">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <p class="header-text">OperNo</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.PPD_Num}} </td>
          </ng-container>
          <ng-container matColumnDef="BatchNo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <p class="header-text">BatchNo</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.BatchNo}} </td>
          </ng-container>
          <ng-container matColumnDef="ItemCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <p class="header-text">ItemCode</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.ItemCode}} </td>
          </ng-container>
          <ng-container matColumnDef="ItemDesc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <p class="header-text">ItemDesc</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.ItemDesc}} </td>
          </ng-container>
          <ng-container matColumnDef="Qty">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <p class="header-text">Qty</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.Qty}} </td>
          </ng-container>
          <ng-container matColumnDef="ResultOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <p class="header-text">ResultOn</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.ResultOn}} </td>
          </ng-container>
          <ng-container matColumnDef="Remarks">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <p class="header-text">Remarks</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.Remarks}} </td>
          </ng-container>
          <ng-container matColumnDef="CreatedOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <p class="header-text">CreatedOn</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.CreatedOn | date: 'dd/MM/YYYY HH:mm:ss' }} </td>
          </ng-container>
          <ng-container matColumnDef="CreatedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <p class="header-text">CreatedBy</p>
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.CreatedBy }} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="batchInfoViewColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: batchInfoViewColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" style="text-align: center;" colspan="11">No Data Found</td>
          </tr>
        </table>
      </div>
      <div>
        <mat-paginator class="basic-paginataor" [pageSizeOptions]="[2, 5, 10, 20]" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </form>
</ng-template>
<!--history  modal-->

<ng-template #displayWarehouseHistoryModal>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center" style="font-size: 10px;">
      <h1>Warehouse Pallet Received History</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <ag-grid-angular
  style="width: 100%; height: 25vw"
  class="ag-theme-alpine"
  [rowData]="dataArray" (cellClicked)="cellClicked($event,false)"
  [columnDefs]="columnDefs" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" 
  [icons]= "icons" [pagination]= "true" [paginationPageSize]="paginationPageSize">
</ag-grid-angular>

</ng-template>


<!--Ware House Received Modal-->
<ng-template #displayShiftStartModal style="padding: 0px;">

  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center" style="font-size: 10px;">
      <h1>Warehouse Received</h1>
      <button mat-mini-fab mat-button mat-dialog-close color="warn" title="close">
        <i class="fa fa-times" style="color: #fff"></i>
      </button>
    </div>
  </div>
  <div class="notication-icon" style="text-align: center;">
    <div>
      <mat-icon style="font-size: 50px;color: #0f0fd1;" inline="true">notifications</mat-icon>
    </div>
    <div>
      <h2>Are you sure want selected pallet warehouse received !</h2>
    </div>
    <hr />
  </div>
  <div style="margin-top: 30px;text-align: end;margin-right: 20px;">
    <button mat-stroked-button mat-dialog-close color="primary" style="font-weight: bold;">No</button>
    <button type="submit" mat-raised-button color="warn" style="background-color: #0f0fd1;margin-left:20px;"
      (click)="SubmitWHPallet()">Yes</button>
  </div>

</ng-template>