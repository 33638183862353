import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GembaWalkService {

  baseUrl: string = environment.O3CoreApiUrl;
  o3ApiUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  addGembaWalk(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/AddGembaWalk', data);
  }

  updateGembaWalk(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/UpdateGembaWalk', data);
  }

  auditCategories(data: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'auditcategories', data);
  }

  deleteGembaWalk(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/DeleteGembaWalk', data);
  }

  setNewActions(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'gembaaction', data);
  }

  getGembaWalk(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/GetAllGembaWalk',data);
  }

  setCompletionDates(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'gembacompletiondate', data);
  }

  setNewTargetDates(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/SetNewTargetDate', data);
  }

  setNewRemarks(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/SetNewGembaRemarks', data);
  }

  setNewResponsible(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'gembaresponsible', data);
  }
  setTargetDate(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'gembadate', data);
  }
  setGembaImage(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'gembaimage', data);
  }
  setGembaActionImage(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/gembaDefectActionImage', data);
  }
  getGeneralCategories(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'getgeneralcategories', data);
  }
  addGembaActions(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/AddGembaWalkActions', data);
  }
  getgembaactions(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/GetGembaWalkActionsById', data);
  }
  deletegembaaction(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/DeleteGembaWalkAction', data);
  }
  gembaactioncompletiondate(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'gembaactioncompletiondate', data);
  }
  getUsersActions(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/getAllUsersActions', data);
  }
  setUsersActionsCompleted(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/setUserActionsCompleted', data);
  }
  setActionRemarks(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/setGembaActionRemarks', data);
  }
  setUsersActionsRejected(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/setUserActionsRejected', data);
  }
  setUsersActionsVerified(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/setUserActionsVerified', data);
  }
  getGembaActions2(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/GetGembaWalkActions', data);
  }
  getGembafiles(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/GetGembafiles', data);
  }
  deleteDefectFiles(data: any): Observable<any>  {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/DeleteDefectFiles', data);
  }
  addGembafiles(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/AddGembafiles', data);
  }
  getGembaCatagories(): Observable<any>
  {
    return this.http.get<any>(this.o3CoreApiUrl + 'GembaWalk/GetGembaCatagories');
  }
  setGembaClosed(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'GembaWalk/SetGembaClosed', data);
  }
}
