<mat-card-content>  
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" template=" <img src='assets/images/spinner.gif' />">
      </ngx-spinner>
    <div class="content-top-box">
        <ul class="breadcrumb">
            <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                    (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
            </li>
        </ul>
    </div>
    <div class="list-header">
        <h1 class="header-title">Technician List</h1>
    </div>

    <form [formGroup]="HeaderForm">
        <div class="wrapper-box wrapper-box-shadow table-wrapper d-flex">
            <div class="content-top-row content-top-row-order-processing flex-1">
                <!-- GROUP -->
                <mat-form-field appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-select formControlName="Group" (selectionChange)="getPlants(HeaderData.Group)"
                        placeholder="Group" panelClass="testClass" name="group" [(ngModel)]="HeaderData.Group">
                        <input (keyup)="searchValues($event, 'group')" class="form-control w-100"
                            placeholder="Search Group">
                        <mat-option *ngFor="let group of filteredGroupList" [value]="group.id">
                            {{group.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Group is Required</mat-error>
                </mat-form-field>
                <!-- Plant -->
                <mat-form-field appearence="fill"
                    class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                    <mat-select formControlName="Plant" [(ngModel)]="HeaderData.Plant" placeholder="Plant"
                        panelClass="testClass" name="plant" (selectionChange)="getTechnicianList()">
                        <input (keyup)="searchValues($event, 'plant')" class="form-control w-100"
                            placeholder="Search Plant">
                        <mat-option *ngFor="let plant of filteredPlantList" [value]="plant.id">
                            {{plant.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Plant is Required</mat-error>
                </mat-form-field>
            </div>
            <div class="content-top-row content-top-row-order-processing">
                <mat-form-field appearance="fill" class="field-fill">
                    <mat-label>Search</mat-label>
                    <input [readonly]="false" matInput class="input-text" (keyup)="applyFilter($event)"
                        autocomplete="off" />
                </mat-form-field>
            </div>
        </div>
    </form>

    <div class="wrapper-box wrapper-box-shadow table-wrapper p-p-1">
        <div class="table-div">
            <table mat-table class="basic-table plant-model-table" matSort [dataSource]="technicianDataSource">

                <ng-container matColumnDef="UserId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <p class="header-text">Technician</p>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.UserName}} </td>
                </ng-container>

                <ng-container matColumnDef="AverageScore">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <p class="header-text">AverageScore</p>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.AverageScore ? element.AverageScore.toFixed(1) :
                        0}}</td>
                </ng-container>

                <ng-container matColumnDef="GroupName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <p class="header-text">Group</p>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.GroupName}}</td>
                </ng-container>

                <ng-container matColumnDef="PlantName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <p class="header-text">Plant</p>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.PlantName}}</td>
                </ng-container>

                <ng-container matColumnDef="Picture">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <p class="header-text">Picture</p>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <img *ngIf="element.Picture" [src]="blobUrl+element.Picture" class="img" (click)="imgview($event)">
                        <img *ngIf="!element.Picture" src="assets/images/no-image.png"  class="img">
                    </td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef>
                        <p class="header-text">Action</p>
                    </th>
                    <td mat-cell *matCellDef="let element" class="row-action-btns-column">
                        <div class="row-action-btns">
                            <button mat-icon-button [disabled]="!IsWrite" class="edit-btn" pTooltip="Edit Area" tooltipPosition="top"
                                aria-label="Edit Row" (click)="editTechician(element, ImageUrl+element.Picture)">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                                        fill="white" />
                                </svg>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="technicianColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: technicianColumns"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching</td>
                </tr>
            </table>
        </div>

    </div>
    <mat-paginator class="basic-paginataor" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
</mat-card-content>
<ng-template #imageViewModal>
    <button mat-mini-fab color="warn" (click)="oncloseimg()"><mat-icon>close</mat-icon></button>
    <mat-carousel timings="250ms ease-in" [autoplay]="false" interval="5000" color="accent" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
      [useMouseWheel]="true" orientation="ltr">
      <mat-carousel-slide #matCarouselSlide  [image]="img"
        overlayColor="#00000040" [hideOverlay]="false"></mat-carousel-slide>
    </mat-carousel>
  </ng-template>