import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuditDashboardComponent } from '../O3AdministrationComponents/audit-dashboard/audit-dashboard.component';
import { AutonomousMaintananceDbComponent } from '../O3AdministrationComponents/autonomous-maintanance-db/autonomous-maintanance-db.component';
import { CrewSchedulingComponent } from '../O3AdministrationComponents/GeneralSettingComponents/crew-scheduling/crew-scheduling.component';
import { MachineParametersComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/machine-parameters/machine-parameters.component';
import { OeeConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/oee-configuration/oee-configuration.component';
import { SfdcConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/sfdc-configuration/sfdc-configuration.component';
import { ProductionDbComponent } from '../O3AdministrationComponents/production-db/production-db.component';
import { OrderProcessingComponent } from '../ProductionOrderComponents/order-processing/order-processing.component';
import { ShopFloorDataComponent } from '../ProductionOrderComponents/shop-floor-data/shop-floor-data.component';
import { AuthGuard } from '../services/auth.guard';
import { MachineboardComponent } from './analytics/machineboard/machineboard.component';
import { AvailabilityAnalysisComponent } from './availability-analysis/availability-analysis.component';
import { CheckListComponent } from './check-List/check-List.component';
import { CuAnalysisComponent } from './cu-analysis/cu-analysis.component';
import { HomeComponent } from './home/home.component';
import { PlantModelComponent } from './home/plant-model/plant-model.component';
import { UseraccessComponent } from './home/useraccess/useraccess.component';
import { PerformanceAnalysisComponent } from './performance-analysis/performance-analysis.component';
import { QualityAnalysisComponent } from './quality-analysis/quality-analysis.component';
import { QualityDbComponent } from './quality-db/quality-db.component';
import { SafetyDbComponent } from './safety-db/safety-db.component';
import { GeneralCategoryComponent } from '../General Category/general-category/general-category.component';
import { BatchReportComponent } from './batch-report/batch-report.component';
import { WholesomeFoodReportComponent } from './wholesome-food-report/wholesome-food-report.component';
import { EventConfigurationComponent } from '../O3AdministrationComponents/ManufacturingAdminComponents/event-configuration/event-configuration/event-configuration.component';
import { BatchReportOutputComponent } from './batch-report-output/batch-report-output.component';
import { SfdcNewComponent } from '../ProductionOrderComponents/sfdc-new/sfdc-new.component';
import { LanguageMasterComponent } from './language-master/language-master.component';
import { PalletComponent } from './pallet/pallet.component';
import { ProductCostConfigurationComponent } from './product-cost-configuration/product-cost-configuration.component';
import { CitiesAndCountriesComponent } from '../O3AdministrationComponents/CitiesAndCountries/CitiesAndCountries.component';
import { GembaWalkDashboardComponent } from '../O3AdministrationComponents/gemba-walk-dashboard/gemba-walk-dashboard.component';
import { ImmediateActionComponent } from '../quality-management/ncr-ccr-management/immediate-action/immediate-action.component';
import { UserActionsComponent } from '../user-actions/user-actions.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'Index', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'PlantModel',
    component: PlantModelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'OeeConfiguration',
    component: OeeConfigurationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'Planner',
    component: OrderProcessingComponent,
    canActivate: [AuthGuard],
  },
  { path: 'SFDC', component: ShopFloorDataComponent, canActivate: [AuthGuard] },
  {
    path: 'SFDCConfiguration',
    component: SfdcConfigurationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'MachineDashboard',
    component: MachineboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'VariableManagement',
    component: MachineParametersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'CrewSchedule',
    component: CrewSchedulingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'UserAcessList',
    component: UseraccessComponent,
    canActivate: [AuthGuard],
  },
  { path: 'CheckList', component: CheckListComponent },
  { path: 'ProductionDB', component: ProductionDbComponent },
  { path: 'MachineBoard', component: MachineboardComponent },
  { path: 'CuAnalysis', component: CuAnalysisComponent },
  { path: 'AvailbilityAnalysis', component: AvailabilityAnalysisComponent },
  { path: 'AuditDashboard', component: AuditDashboardComponent },
  { path: 'GembaWalkDashboard', component: GembaWalkDashboardComponent },
  { path: 'generalcategory', component: GeneralCategoryComponent },

  { path: 'PerformanceAnalysis', component: PerformanceAnalysisComponent },
  { path: 'SafetyDB', component: SafetyDbComponent },
  { path: 'QualityDB', component: QualityDbComponent },
  { path: 'AutonomusDB', component: AutonomousMaintananceDbComponent },
  { path: 'QualityAnalysis', component: QualityAnalysisComponent },
  { path: 'BatchReport', component: BatchReportComponent },
  { path: 'BatchReportOutput', component: BatchReportOutputComponent },
  { path: 'wholesomefoodreport', component: WholesomeFoodReportComponent },
  { path: 'EventConfiguration', component: EventConfigurationComponent },
  { path: 'sfdcnew', component: SfdcNewComponent },
  { path: 'languagemaster', component: LanguageMasterComponent },
  { path: 'Pallet', component: PalletComponent },
  { path: 'productCostConfiguration', component: ProductCostConfigurationComponent },
  { path: 'CitiesAndCountries', component: CitiesAndCountriesComponent },
  { path: 'ImmediateAction', component: ImmediateActionComponent },
  { path: 'userActions', component: UserActionsComponent, canActivate: [AuthGuard] },

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
