<ngx-spinner bdColor="rgba(0, 0, 0, 0)" template=" <img src='assets/images/spinner.gif' />">
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>
<div dir="{{languageDir}}">
<section class="home-content">
  <ul class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
      <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
    </li>
  </ul>
  <mat-card>
    <div style="padding: 10px; background-color: #0f0fd1">
      <div class="p-grid p-fluid">
        <div class="p-col-4">
          <mat-card-title style="color: white; margin-top: 8px">{{
            langObj ? langObj?.KPIActualEntry : ("KPIActualEntry" | translate)
            }}</mat-card-title>
        </div>
        <div class="p-col-2 d-flex justify-content-evenly">
          <button mat-mini-fab color="primary" (click)="UploadTargetEntry()" style="background-color: #00a785"
            pTooltip="Upload Target Entry">
            <mat-icon>note_add</mat-icon>
          </button>
          <button mat-mini-fab color="primary" (click)="UploadActualEntry()" style="background-color: #6d1ab1"
            pTooltip="Upload Actual Entry">
            <mat-icon>queue</mat-icon>
          </button>


          <button mat-mini-fab color="accent" (click)="refreshData()" pTooltip="Refresh Integration"
            [disabled]="isLoading" style="float: right">
            <mat-icon *ngIf="!isLoading">refresh </mat-icon>
            <mat-spinner color="accent" *ngIf="isLoading" diameter="20">
            </mat-spinner>
          </button>
        </div>
        <div class="p-col-6">
          <form [formGroup]="searchPlantForm">
            <div class="p-grid p-fluid">
              <div class="p-col-4 p-md-6">
                <p-dropdown *ngIf="groupList?.length > 1" [filter]="true" (onChange)="getPlants()" [ngClass]="{
                    'is-invalid':
                      searchPlantForm.get('group')?.errors &&
                      searchPlantForm.get('group')?.touched
                  }" [options]="groupList" formControlName="group" placeholder="{{langObj ? langObj?.SelectGroup : ('SelectGroup' | translate)}}" optionLabel="TypeName"
                  [showClear]="true"></p-dropdown>
                <p class="invalid-feedback" *ngIf="
                    searchPlantForm.get('group')?.touched &&
                    searchPlantForm.get('group')?.hasError('required')
                  ">
                  * please select group
                </p>
              </div>
              <div class="p-col-8 p-md-6">
                <p-dropdown [filter]="true" (onChange)="getMasterData()" [ngClass]="{
                    'is-invalid':
                      searchPlantForm.get('plant')?.errors &&
                      searchPlantForm.get('plant')?.touched
                  }" [options]="searchPlants" formControlName="plant" placeholder="{{langObj ? langObj?.SelectPlant : ('SelectPlant' | translate)}}"
                  optionLabel="DeptDesc" [showClear]="true"></p-dropdown>
                <p class="invalid-feedback" *ngIf="
                    searchPlantForm.get('plant')?.touched &&
                    searchPlantForm.get('plant')?.hasError('required')
                  ">
                  * {{langObj ? langObj?.pleaseselectplant : ('pleaseselectplant' | translate)}}
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <mat-card-content>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6 p-lg-4">
          <form [formGroup]="dateForm">
            <div class="primeNg-datepicker">
              <label>{{
                langObj ? langObj?.Date : ("Date" | translate)
                }}</label>
              <nz-date-picker class="calender-input" id="date" formControlName="date"
                (ngModelChange)="onDateChange($event)" [nzDisabledDate]="disabledDate" nzFormat="yyyy-MM-dd HH:mm"
                [nzShowTime]="{ nzFormat: 'HH:mm' }" [nzInputReadOnly]="true">
              </nz-date-picker>
            </div>
            <!-- <label>{{langObj ? langObj?.Date : ('Date' | translate)}}</label>
            <p-calendar [disabledDays]=dayOffList [maxDate]="todaysDate" dateFormat="dd/mm/yy" appendTo="body"
              [firstDayOfWeek]=0 [showWeek]="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030"
              inputId="navigators" (onSelect)="onDateChange($event)" formControlName="date">
            </p-calendar> -->
          </form>
        </div>
        <div class="p-col-12 p-md-6 p-lg-4">
          <label>{{
            langObj ? langObj?.Frequency : ("Frequency" | translate)
            }}</label>
          <p-multiSelect [options]="frequencies" (onChange)="onFrequcnyChange($event)" [(ngModel)]="kpifrequencies"
            name="kpifrequency" defaultLabel="Select a Frequency" optionLabel="name" scrollHeight="300px">
          </p-multiSelect>
        </div>
      </div>

      <ag-grid-angular style="width: 100%; height: 25vw" class="ag-theme-alpine" [rowData]="dataArray"
        [loadingOverlayComponent]="loadingOverlayComponent" [frameworkComponents]="frameworkComponents"
        [columnDefs]="columnDefs" (cellValueChanged)="onCellValueChanged($event)" (gridReady)="onGridReady($event)"
        [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
      </ag-grid-angular>
    </mat-card-content>
  </mat-card>
</section>
<p-dialog [contentStyle]="{ overflow: 'visible' }" [closable]="true" header="Actual Entry"
  [(visible)]="visibleShiftlyTarget" [modal]="true" [style]="{ width: '70vw' }" [maximizable]="true"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div class="row">
    <div class="col-12 p-1">
     
      <!-- <h4 style="font-weight:normal">Actual Entry Information</h4> -->
      <div class="p-grid p-fluid">
        <div class="p-field p-col-4">
          <label>{{langObj ? langObj?.Title : ('Title' | translate)}}</label>
          <input id="float-input" type="text" pInputText [(ngModel)]="kpiTitle" [value]="kpiTitle" disabled />
        </div>
       
        <div class="p-field p-col-4">
          <label>{{langObj ? langObj?.Frequency : ('Frequency' | translate)}}</label>
          <input id="float-input" type="text" pInputText [(ngModel)]="kpifrequency" [value]="kpifrequency" disabled />
      
        </div>
        <div class="p-field p-col-4">
          <label>{{langObj ? langObj?.uom : ('uom' | translate)}}</label>
          <input id="float-input" type="text" pInputText [(ngModel)]="kpiUom" [value]="kpiUom" disabled />

        </div>
        <form [formGroup]="manualActualEntryForm" style="width:98%">
          <div class="p-grid p-fluid" style="margin-left:2px">
        <div class="p-field p-col-4" style="margin-right:5px">
          <label>{{langObj ? langObj?.Date : ('Date' | translate)}}</label>
          
          <input id="float-input" formControlName="date" type="datetime-local" pInputText    />
        </div>
        <div class="p-field p-col-4">
          <label style="margin-bottom:9px">{{langObj ? langObj?.Value : ('Value' | translate)}}</label>
          <input formControlName="value" id="float-input" *ngIf="DataTypeId==2?true:false" type="date" pInputText   />
          <input formControlName="value" id="float-input" *ngIf="DataTypeId==3?true:false" type="time" pInputText   />
          <input formControlName="value" id="float-input" *ngIf="DataTypeId==1?true:false" (input)="onActualValueChange($event)" type="text" pInputText   />
          <input formControlName="value" id="float-input" *ngIf="DataTypeId==4?true:false" type="text" pInputText   />
        </div>

        
         <div class="p-field p-col-2" style="    margin-top: 25px;">
          <!-- <button type="button" (click)="resetEntry()"     *ngIf="  IsWrite" style="margin-left: 20px; margin-top: 0px;" class="icon-btn"
        pTooltip="Reset" tooltipPosition="top">
        <img src="assets/images/refresh1.png" alt="" width="20px" height="20px">
      </button>
      <button type="button" class="icon-btn" (click)="SaveActualEntry()" [disabled]="!manualActualEntryForm.valid "     style="margin-left: 20px;" pTooltip="Save" tooltipPosition="top"
      >
      <img src="assets/images/save.png" alt="" width="20px" height="20px">
    </button>
  -->
  <button mat-mini-fab mat-button color="accent" (click)="resetEntry()"
    pTooltip="Reset" tooltipPosition="top">
  <mat-icon>refresh</mat-icon>
</button>
<button mat-mini-fab mat-button color="primary" style="margin-left:10px" (click)="SaveActualEntry()" 
  pTooltip="Save" tooltipPosition="top" *ngIf="!IsEdit && IsWrite" [disabled]="!manualActualEntryForm.valid ">
  <mat-icon>save</mat-icon>
</button>
<button mat-mini-fab mat-button color="primary"   
  style="margin-left:10px;" pTooltip="Update" (click)="UpdateActualEntry()"  tooltipPosition="top" *ngIf="IsEdit && IsWrite"
  [disabled]="!manualActualEntryForm.valid ">
  <mat-icon>edit</mat-icon>
</button>
</div>  
        </div>  
      </form>
      </div>
      
   
    </div>

    <div class="col-12 p-2">
      <div class="p-grid p-fluid">
        <ag-grid-angular style="width: 70vw; height: 20vw" class="ag-theme-alpine" [rowData]="dataArraykpi"
          [columnDefs]="columnDefsShift" [components]="components" (cellValueChanged)="onCellValueEdit($event)"
          (cellClicked)="cellClicked($event)" (gridReady)="onGridReadyEdit($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</p-dialog>

<ng-template #uploadActualDialog style="padding: 0px">
  <div>
    <h1 mat-dialog-title>{{langObj ? langObj?.KPIUploadActualEntryExcel : ('KPIUploadActualEntryExcel' | translate)}}</h1>
    <div mat-dialog-content>
      <div class="d-flex">
        <div class="flex-1">
          <button mat-flat-button color="accent" type="button" (click)="openActualFile()">
            {{langObj ? langObj?.UploadExcel : ('UploadExcel' | translate)}}
          </button>
          <input type="file" (change)="onActualReadExcel($event)" class="d-none" #fileInput />
        </div>
        <div>
          <button mat-flat-button color="accent" type="button" class="download-btn"
            (click)="extensionUploadActual()">
            <a class="sample">Sample Excel</a>
            <!-- <mat-spinner color="accent" *ngIf="isLoadingActual" diameter="20"></mat-spinner> -->
          </button>
        </div>
      </div>
    </div>

    <!-- Extension -->

    <div *ngIf="ExtensionUploadActual == true">
      <div class="showDiv"  class="form-group" style="height:  380px;">
    
        <div style="display: flex; justify-content: space-between; margin-top: 10px;">
          <div style="width: 44%;">
            <!-- <label>{{
              langObj ? langObj?.Frequency : ("Frequency" | translate)
              }}</label> -->
              <p-multiSelect [options]="frequencies"  [(ngModel)]="ExtUploadActualkpifrequencies" 
              name="kpifrequency"  defaultLabel="Select a Frequency" optionLabel="name" scrollHeight="300px">
            </p-multiSelect>
           </div>

          <div style="width: 40%;">

            <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 100%;">
              <mat-label>Date Range</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate [(ngModel)]="defaultFromDate" formControlName="fromDate" placeholder="From Date"
                  [readOnly]="true" />
                <input matEndDate [(ngModel)]="defaultToDate"  formControlName="toDate" placeholder="To Date"
                  [readOnly]="true"  />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
           
         </div>

        </div>

       
         
        <div mat-dialog-actions class="mat-dialog-actions">
          <button mat-flat-button [disabled]="isLoadingActual" color="accent" type="button" class="download-btn"
            (click)="getExcelData()">
            <a class="sample" *ngIf="!isLoadingActual">{{langObj ? langObj?.SampleExcel : ('SampleExcel' | translate)}}
              <i class="fa fa-download" *ngIf="!isLoadingActual" style="color: #fff" aria-hidden="true"></i></a>
            <mat-spinner color="accent" *ngIf="isLoadingActual" diameter="20"></mat-spinner>
          </button>
        </div>

       
      
      </div>
    </div>



    <div mat-dialog-actions class="mat-dialog-actions">
      <button mat-button class="dialog-cancel-btn" (click)="onCancelActualClick()">
        <span style="color: #fff">{{langObj ? langObj?.Cancel : ('Cancel' | translate)}}</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #uploadTargetDialog style="padding: 0px">
  <div>
    <h1 mat-dialog-title>{{langObj ? langObj?.KPIUploadTargetExcel : ('KPIUploadTargetExcel' | translate)}}</h1>
    <div mat-dialog-content>
      <div class="d-flex">
        <div class="flex-1">
          <button mat-flat-button color="accent" type="button" (click)="openTargetFile()">
            {{langObj ? langObj?.UplaodExcel : ('UplaodExcel' | translate)}}
          </button>
          <input type="file" (change)="onTargetReadExcel($event)" class="d-none" #fileInput />
        </div>
        <div>
         

          <button mat-flat-button color="accent" type="button" class="download-btn"
            (click)="extensionUploadTarget()">
            <a class="sample">Sample Excel</a>
            <!-- <mat-spinner color="accent" *ngIf="isLoadingActual" diameter="20"></mat-spinner> -->
          </button>
        </div>

      </div>
    </div>
    <div *ngIf="ExtensionUploadTarget == true">
      <div class="showDiv"  class="form-group" style="height:  380px;">
    
        <div style="display: flex; justify-content: space-between; margin-top: 10px;">
          <div style="width: 44%;">
            <!-- <label>{{
              langObj ? langObj?.Frequency : ("Frequency" | translate)
              }}</label> -->
              <p-multiSelect [options]="frequencies"  [(ngModel)]="ExtUploadTargetkpifrequencies"
              name="kpifrequency"  defaultLabel="Select a Frequency" optionLabel="name" scrollHeight="300px">
            </p-multiSelect>
           </div>

          <div style="width: 40%;">

            <mat-form-field class="top-row-first-filed-item top-row-first-filed-item-order-processing" style="width: 100%;">
              <mat-label>Date Range</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate [(ngModel)]="defaultFromDate" formControlName="fromDate" placeholder="From Date"
                  [readOnly]="true" />
                <input matEndDate [(ngModel)]="defaultToDate"  formControlName="toDate" placeholder="To Date"
                  [readOnly]="true"  />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
           </div>
          </div>
         
        <div mat-dialog-actions class="mat-dialog-actions">
          
          <button mat-flat-button [disabled]="isLoadingTarget" color="accent" type="button" class="download-btn"
            (click)="getTargetExcelData()">
            <a class="sample" *ngIf="!isLoadingTarget">{{langObj ? langObj?.SampleExcel : ('SampleExcel' | translate)}}
              <i class="fa fa-download" *ngIf="!isLoadingTarget" style="color: #fff" aria-hidden="true"></i></a>
            <mat-spinner color="accent" *ngIf="isLoadingTarget" class="w-22" diameter="20"></mat-spinner>
          </button>
        </div>
      </div>
    </div>


    <div mat-dialog-actions class="mat-dialog-actions">
      <button mat-button class="dialog-cancel-btn" (click)="onCancelTargetClick()">
        <span style="color: #fff">{{langObj ? langObj?.Cancel : ('Cancel' | translate)}}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- Date Picker -->
<mat-form-field class="example-full-width" appearance="fill" style="display:none;">
  <mat-label>{{langObj ? langObj?.choosedate : ('choosedate' | translate)}}</mat-label>
  <input matInput [matDatepicker]="pickerforgrid" (dateInput)="gridDate($event)">
  <mat-datepicker-toggle matSuffix [for]="pickerforgrid"></mat-datepicker-toggle>
  <mat-datepicker touchUi #pickerforgrid></mat-datepicker>
</mat-form-field>
<!-- time Pikcer -->

<form [formGroup]="timeFormGroup">
  <input [ngxTimepicker]="starttimepicker" formControlName="timepicker"  readonly class="form-control" [format]="24" style="display:none;"   />
<ngx-material-timepicker style="display:none;"  #starttimepicker></ngx-material-timepicker>


</form>
</div>