import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './screens/authentications/login/login.component';
import { PublicLayoutComponent } from './shared/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './shared/private-layout/private-layout.component';
import { AuthGuard } from './services/auth.guard';
import { SignUpComponent } from './screens/authentications/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './screens/authentications/forgot-password/forgot-password.component';
import { QualityAnalysisComponent } from './screens/quality-analysis/quality-analysis.component';
import { SafetyDbComponent } from './screens/safety-db/safety-db.component';
import { DashboardComponent } from './screens/dashboard/dashboard.component';
import { QualityDbComponent } from './screens/quality-db/quality-db.component';
import { AvailabilityAnalysisComponent } from './screens/availability-analysis/availability-analysis.component';
import { CuAnalysisComponent } from './screens/cu-analysis/cu-analysis.component';
import { DefectConfigurationComponent } from './O3AdministrationComponents/ManufacturingAdminComponents/defect-configuration/defect-configuration.component';
import { DefectManagementMasterComponent } from './DMS/defect-management-master/defect-management-master.component';
import { CustomerComplaintComponent } from './customer-complaint/customer-complaint.component';
import { OrgTreeComponent } from './KPIs Management/OrgTree/OrgTree.component';
import { MeetingEvaluationComponent } from './meeting-evaluation/meeting-evaluation/meeting-evaluation.component';
import { DatetestComponent } from './datetest/datetest.component';
import { MeetingEvaluationLevelComponent } from './meeting-evaluation/meeting-evaluation-level/meeting-evaluation-level.component';
import { NewSfdcComponent } from './ProductionOrderComponents/new-sfdc/new-sfdc.component';
import { QCBoxComponent } from './ProductionOrderComponents/QC-Box/QC-Box.component';
import { LandingPageComponent } from './screens/landing-page/landing-page/landing-page.component';
const routes: Routes = [
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'signup', component: SignUpComponent },
      { path: 'forgotpassword', component: ForgotPasswordComponent },
      { path: '', component: LoginComponent },
      { path: 'customer-complaint', component: CustomerComplaintComponent },
      { path: 'meeting-evaluation', component: MeetingEvaluationComponent },
      { path: 'date', component: DatetestComponent },
      {
        path: 'meeting-evaluation-level',
        component: MeetingEvaluationLevelComponent,
      },
      {
        path: 'NewSfdc',
        component: NewSfdcComponent,
      },
      {
        path: 'CustomerComplaint',
        loadChildren: () =>
          import('./CustomerCompalint/customercomplaint.module').then((m) => m.CustomerComplaintModule),
      },
    ],
  },

  // Private layout
  {
    path: '',
    runGuardsAndResolvers: 'always',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      {
        path: 'Home',
        loadChildren: () =>
          import('./screens/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'Dashboard',
        loadChildren: () =>
          import('./screens/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'OLE',
        loadChildren: () => import('./OLE/ole.module').then((m) => m.OLEModule),
      },
      {
        path: 'LossAnalysis',
        loadChildren: () =>
          import('./LossAnalysis/lossanalysis.module').then(
            (m) => m.LossAnalysisModule
          ),
      },
      {
        path: 'Access',
        loadChildren: () =>
          import('./Access/access.module').then((m) => m.AccessModule),
      },
      {
        path: 'PM',
        loadChildren: () => import('./PM/pm.module').then((m) => m.PmModule),
      },
      {
        path: 'at',
        loadChildren: () =>
          import('./analytical-tools/at.module').then((m) => m.AtModule),
      },
      {
        path: 'ProgressiveMaintenance',
        loadChildren: () =>
          import(
            './Progressive Maintenance/progressiveMaintenance.module'
          ).then((m) => m.ProgressiveMaintenanceModule),
      },
      {
        path: 'CIL',
        loadChildren: () => import('./CIL/cil.module').then((m) => m.CILModule),
      },
      {
        path: 'DMS',
        loadChildren: () => import('./DMS/dms.module').then((m) => m.DMSModule),
      },
      {
        path: 'DT',
        loadChildren: () => import('./DT/dt.module').then((m) => m.DTModule),
      },
      {
        path: 'KPI',
        loadChildren: () =>
          import('./KPIs Management/kpi.module').then((m) => m.KPIModule),
      },
      {
        path: 'quality',
        loadChildren: () =>
          import('./qualitymanagement/qualitymanagement.module').then(
            (m) => m.QualitymanagementModule
          ),
      },
      {
        path: 'PerformanceManagement',
        loadChildren: () =>
          import('./Performance Management/prmanagement.module').then(
            (m) => m.PerformanceManagementModule
          ),
      },
      {
        path: 'Safety',
        loadChildren: () =>
          import('./safety/safety.module').then(
            (m) => m.SafetyModule
          ),
      },
      {
        path: 'orp-dashboard',
        loadChildren: () =>
          import('./orp-dashboard/orp-dashboard.module').then(
            (m) => m.OrpDashboardModule
          ),
      },
      {
        path: 'opmsQuality',
        loadChildren: () =>
          import('./opms-quality/opms-quality.module').then(
            (m) => m.OpmsQualityModule
          ),
      },

      {
        path: 'availbilityanalysis',
        component: AvailabilityAnalysisComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'cu-analysis',
        component: CuAnalysisComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'quality-analysis',
        component: QualityAnalysisComponent,
        canActivate: [AuthGuard],
      },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'safety-db', component: SafetyDbComponent },
      { path: 'quality-db', component: QualityDbComponent },
      { path: 'defect-config', component: DefectConfigurationComponent },
      { path: 'defect-management', component: DefectManagementMasterComponent },
      { path: 'orgtree', component: OrgTreeComponent },
      { path: 'qcBox', component: QCBoxComponent },
      { path: 'landingPage', component: LandingPageComponent, canActivate: [AuthGuard] },
    ],
  },
  { path: '**', redirectTo: 'Home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
