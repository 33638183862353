import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BtnDeleteCellRenderer } from 'src/app/btn-deletecell-renderer';
import { UserPlant } from 'src/app/models/userPlant';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpiactualentryService } from 'src/app/services/kpiactualentry.service';
import { KpiintegrationService } from 'src/app/services/kpiintegration.service';
import { KpitreeService } from 'src/app/services/kpitree.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { TranslaterService } from 'src/app/services/translater.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { CommonService } from 'src/app/services/common.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-KPIIntegartion',
  templateUrl: './KPIIntegartion.component.html',
  styleUrls: ['./KPIIntegartion.component.scss'],
})
export class KPIIntegartionComponent implements OnInit {
  formControl = new FormControl(['angular']);
  calcList: any[] = [];
  alreadysavedCalcList: any[] = [];
  breadcrumList: any;
  completeformula = '';
  completeFormulaWithId = '';
  KpiIntegrationForm!: FormGroup;
  allSelected = false;
  KpiSearchForm!: FormGroup;
  groupList: any;
  searchPlants!: UserPlant[];
  plants!: UserPlant[];
  disableSave!: boolean;
  ownerList: any[] = [];
  columnDefs: any;
  rowData: any[] = [];
  userList!: any;
  unitList: any;
  paginationPageSize!: any;
  gridApi!: any;
  CheckGeneralCatagory = true;
  id: any;
  dataPoints: any;
  checkbox = false;
  IsAccess!: any;
  IsWrite!: any;
  calcKpiDataPoints: any;
  isLoading: any;
  unitData: any[];
  IdsType: any[];
  KPIName:string;
  selectedIds: any[] = [];
  selectionModel: SelectionModel<any>;
  @ViewChild('displayrowRoleModal')
  private displayrowRoleModal!: TemplateRef<any>;
  @ViewChild('displayOperatorsModel')
  private displayOperatorsModel!: TemplateRef<any>;
  @ViewChild('displaydataPointsRoleModal')
  private displaydataPointsRoleModal!: TemplateRef<any>;
  @ViewChildren('check') checkboxes!: QueryList<any>;
  @ViewChild('selectAll') selectAll!: MatSelect;


  gridOptions: any = {
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    // floatingFilter: true,
    defaultColDef: {
      rowModelType: 'infinite',
      infiniteInitialRowCount: 2,
      flex: 1,
      sortable: true,
      resizable: true,
      wrapText: true, // <-- HERE
      autoHeight: true, // <-- & HERE
      // minWidth: 225,
      filter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    frameworkComponents: {
      buttonCellRenderer: BtnDeleteCellRenderer,
    },
  };
  bottomOptions = {
    alignedGrids: [],
    defaultColDef: {
      flex: 1,
      // minWith: 110,
    },
  };
  filteredMachines: any;
  datapoints!: any[];
  datapoints1!: any[];
  usedKpiDataPoints: any[] = [];
  dataPointsBeforeMachineChange!: any[];
  unitId: any;
  pu: any;
  datasourceList: any;
  filteredDataSource: any;
  servicetypeList: any;
  filteredServiceType: any;
  areaList!: any[];
  filteredareas!: any[];
  KPIid: any;
  filtereddatapoints!: any[];
  datapointchecked: any;
  resultText = [];
  values!: string;
  count = 0;
  checked: any;
  selecteddatapoint = false;
  selected!: any[];
  kpidatapoints: number[] = [];
  gridColumnApi: any;
  dataPointsList: any[] = [];
  calcKpiDataPointIds: any[] = [];
  checkBoxStatus: any;
  buttonType: any;
  value: any;
  PrevIndex: any;
  currentIndex: any;
  remche!: boolean;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;
  frequencies: any;
  unitObj:any;
  lineObj:any;
  typeObj: any;
  constructor(
    private usermanagementService: UserManagementService,
    private toaster: ToastrService,
    public router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private kpiService: KpiintegrationService,
    private kpitreeservice: KpitreeService,
    private platModelService: EnterPriseGroupService,
    private kpiactualentry: KpiactualentryService,
    private translater: TranslaterService,
    private kpimaster: KpimasterService,
    private commonService: CommonService,
  ) {
    this.selectionModel = new SelectionModel<any>(true, []);
    this.columnDefs = [
      {
        field: 'Action',
        headerName: 'Action',
        resizable: true,
        width: 90,
        // cellRenderer: 'buttonCellRenderer',
        cellRenderer: (params: any): any => {
          if (params.data.KPIIntegrationId != null) {
            return `
            <div>
            <button mat-mini-fab="" color="warn" ptooltip="Delete"
            tooltipposition="top" class="mat-focus-indicator mat-mini-fab
            mat-button-base mat-warn"  ng-reflect-color="warn" ng-reflect-text
            ="Action History" ng-reflect-tooltip-position="top"><span class="mat-button-wrapper"> <i class="fa fa-trash Delete" style="color: #fff;"><span style="display:none">delete</span></i><span matripple="" class="mat-ripple mat-button-ripple

             mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
                     </div>`;
            // return 'buttonCellRenderer';
          }
        },
        cellRendererParams: { clicked: (field: any) => { } },
        cellStyle: { textAlign: 'center' },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        field: 'KpiId',
        headerName: 'Serial No',
        resizable: true,
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
        // filter: true,
      },
      {
        field: 'KPIName',
        headerName: 'KPI Name',
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
        // filter: true,
      },
      {
        field: 'FrequencyName',
        headerName: 'KPI Frequency',
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
        // filter: true,
      },
      {
        field: 'KPIidentification',
        headerName: 'KPI Identification',
        resizable: true,
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this)
        // filter: true,
      },
      {
        field: 'DataPoints',
        headerName: 'Data Points',
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
        // filter: true,
      },
      {
        field: 'MainDataSource',
        headerName: 'Main Data Source',
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
        // filter: true,
      },
      {
        field: 'AreasInO3',
        headerName: 'Area in O3',
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
        // filter: true,
      },
      {
        field: 'Machine',
        headerName: 'Machine(s)',
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
        // filter: true,
      },
      {
        field: 'DataCalculation',
        headerName: 'Data Calculation',
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
        // filter: true,
      },
      // {
      //   field: 'Servicetypetobeuse',
      //   headerName: 'Service Type',
      //   resizable: true,
      //   headerValueGetter: this.localizeHeader.bind(this)
      //   // filter: true,
      // },
    ];
    // this.rowData = [
    //   {
    //     serialno: '1',
    //     lowestnode: 'Volume',
    //     kpifrequency: 'Shift',
    //     kpidefination: 'Machine or LIne',
    //     datasource: 'O3',
    //     area: 'SFDC',
    //     machines: 'M1,M2',
    //     calculation: 'M1 + M2',
    //     servicetype: 'Once at Shift End',
    //   },
    // ];
  }

  ngOnInit(): any {
    this.KpiSearchForm = this.fb.group({
      plant: [''],
      group: [''],
      frequency: [''],
    });
    this.KpiIntegrationForm = this.fb.group({
      // dataSource: [''],
      // area: [''],
      machine: [''],
      servicetype: [''],
      calculation: [''],
      formula: [
        this.calcList.map((c) => c.dpid).join(''),
        Validators.pattern(
          /^(?:\d|\+|\-|\/|\*|\(|\)|\.|\,|AVG|SUM|MAX|MIN|V\d+| )*$/gm
        ),
      ],
    });
    this.GetBreadcrumList();
    this.getGroupData();
    this.GetMenuAccess();
  }
  refreshData(): void {
    this.isLoading = true;
    const key = {
      ptId: this.KpiSearchForm.value.plant.DeptId,
      currentDate: new Date(),
      userId: JSON.parse(localStorage.user).UserId,
    };
    this.kpiactualentry.executeActualEntry(key).subscribe(
      (data: any) => {
        // setTimeout(() => {
        this.isLoading = false;
        // }, 3000);
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupList = data.masterList;
        const obj = this.groupList[0];
        this.KpiSearchForm.get('group')?.setValue(obj);
        this.getPlants();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  public localizeHeader(parameters: any) {
    let headerIdentifier = parameters.colDef.field;
    return this.getTransalatedValue(headerIdentifier);
  }

  getTransalatedValue(value:string){
    let val;
    if(this.langObj && this.langObj[value]){
      val = this.langObj[value];
    }
    return val?val:value;
 }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
      if(this.langObj){
        this.gridApi.refreshHeader();
      }
    });
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
        // localStorage.setItem('GetMenuAccess', this.permisson);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  oncellclicked(params: any): void {
    this.allSelected = false;
    this.selectedIds=[];
    this.IdsType=[];
    this.KpiIntegrationForm.get('machine').setValue([])
    this.KPIName = params.data.KPIName
    const col = params.colDef.field;
    if(params.data.IdsType !=  null)
    {
      this.IdsType =[];
      this.IdsType = JSON.parse(params.data.IdsType) 
    }

    if (this.IsWrite) {
      if (col !== 'Action') {
        this.datapoints = [];
        this.kpidatapoints = [];
        this.KpiIntegrationForm.reset();
        this.KPIid = params;
        this.alreadysavedCalcList = JSON.parse(params.data.CalculationString);
        // this.resetCalculation();
        // this.getSelectedrowData();
        if (this.alreadysavedCalcList) {
          this.calcList = this.alreadysavedCalcList;
          let formvalidate = '';
          this.calcList.forEach((elem, index) => {
            formvalidate = formvalidate + elem.dpid;
          });
          try {
            // tslint:disable-next-line:no-eval
            if (eval(formvalidate)) {
              this.disableSave = false;
            }
          } catch (error) {
            this.disableSave = true;
          }
        } else {
          this.calcList = [];
        }
        this.openActionRowModal();
      }
      if (col === 'Action') {
        this.buttonType = params.event.target.textContent;
        if (this.buttonType === 'delete' && this.IsWrite) {
          this.deleteKpiIntegration(params.data);
        }
      }
    }

    // if (
    //   // params.data.Machine === null ||
    //   params.data.MainDataSource === null ||
    //   params.data.AreasInO3 === null ||
    //   params.data.DataCalculation === null ||
    //   params.data.Servicetypetobeuse === null
    // ) {
    //   this.getSelectedrowData();
    // } else {
    //   return;
    // }
  }

  deleteKpiIntegration(obj: any): void {
    const key = {
      KPIId: obj.KPIIntegrationId,
    };
    this.kpiService.deleteKpiIntegration(key).subscribe(
      (response: any) => { },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
    this.getKpiIntegration();
  }

  openActionRowModal(): void {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node: any) => node.data);
    this.datapoints1 = [];
    this.KpiIntegrationForm.get('servicetype')?.setValue(selectedData[0].STId);
    this.KpiIntegrationForm.get('calculation')?.setValue(
      selectedData[0].DataCalculation
    );
    let puid = [];
    if (this.KPIid.data.puId) {
      puid = this.KPIid.data.puId.split(',');
      var result = puid.map(function (x: any) {
        return parseInt(x, 10);
      });
    }
      //this.getKpiDataPoints(this.KPIid.data.puId);
      let Object = [];
      for(let i=0; i< this.IdsType.length; i++)
      {
        const obj={
          puId: this.IdsType[i].Id,
          type:this.IdsType[i].type
        }
        Object.push(obj)
      }

      this.getIds(Object)
      this.getKpiDataPointsByType(this.unitObj,this.lineObj,this.typeObj);
    
    this.dialog.open(this.displayrowRoleModal, {
      height: '80%',
      width: '90%',
    });
  }
  getPlants(): void {
    const key = {
      GroupId: this.KpiSearchForm.value.group.TypeId,
    };
    this.usermanagementService.getDepartments(key).subscribe(
      (res: any) => {
        this.searchPlants = res.DepartList;
        this.plants = res.DepartList;
        const plantObject = this.searchPlants[0];
        this.KpiSearchForm.get('plant')?.setValue(plantObject);
        this.getKpiIntegration();
        this.getMachinebyplant();
        this.getFrequencies();
        this.getDataSource();
        this.getServiceType();
        this.geto3Area();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onChangePlant(data: any): void {
    this.KpiSearchForm.get('plant')?.setValue(data.value);
    this.getKpiIntegration();
    this.getFrequencies();
    this.getMachinebyplant();
    this.getDataSource();
    this.getServiceType();
    this.geto3Area();
  }
  onFrequencyChange(): void {
    this.getKpiIntegration();
  }
  getKpiIntegration(): void {
    const key = {
      ptId: this.KpiSearchForm.value.plant.DeptId,
      frequency: this.KpiSearchForm.value.frequency
        ? this.KpiSearchForm.value.frequency
          ?.map((c: any) => c.frequencyId)
          .toString()
        : '',
    };
    this.kpiService.kpiIntegration(key).subscribe(
      (dataArray: any) => {
        // tslint:disable-next-line:prefer-for-of
        for (var i = 0; i < dataArray.length; i++) {
          if (dataArray[i].Machine == null) {
          }
        }
        this.rowData = dataArray;
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  getKpiDataPointsByType(unit: any,line: any,type:any): void {
    // this.resetCalculation();
    let pt_id;
    if(unit === '' && line === '' && type === '' )
    {
      pt_id =0
    }
    else{
      pt_id =this.KpiSearchForm.value.plant.DeptId;
    }
    const key = {
      unitId: unit,
      lineId:line,
      plantId: pt_id,
      type:type
    };
    this.kpiService.getKPIDataPointsByType(key).subscribe(
      (datapoint) => {
        const uniquemachine = [
          ...new Set(
            datapoint.map((item) => {
              return item.PU_Desc;
            })
          ),
        ];

        const listcheck: any[] = [];
        if (this.KPIid.data.dpId) {
          let dpid = [];
          dpid = this.KPIid.data.dpId.split(',');
          var result = dpid.map((x: any) => parseInt(x, 10));
          this.kpidatapoints = result;
          for (var i = 0; i < datapoint.length; i++) {
            if (result.indexOf(datapoint[i].KpiDpId) !== -1) {
              datapoint[i].checked = true;
            } else {
              datapoint[i].checked = false;
            }
          }
        }
        let unit: [] = this.KpiIntegrationForm.value.machine;
        let object: any[];
        for (const item of uniquemachine) {
          const obj = {
            machine: item,
            data: datapoint
              .filter((x) => x.PU_Desc === item)
              .map((elem) => {
                return {
                  DataPoints: elem.DataPoints,
                  checked: elem.checked,
                  KpiDpId: elem.KpiDpId,
                };
              }),
          };
          listcheck.push(obj);
        }
        const firstItem = '';
        listcheck.sort((x, y) =>
          x.machine === firstItem ? -1 : y.machine === firstItem ? 1 : 0
        );
        this.datapoints1 = listcheck;

         this.selectedValue(datapoint);
       
         },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }

  selectedValue(object:any):void
  {
    if(this.allSelected != true)
    {
    let datapoint = object;
    let Object =[]
    

    for (const item of datapoint) {
      if(item.type == 3)
      {
        const existRow = Object.filter(x=> x.puDesc == item.PU_Desc )
        if(existRow.length != 1)
        {
        const obj={
          puId: item.PU_Id, puDesc: item.PU_Desc, type: item.type
        }
        Object.push(obj)
         }
      }
      else{
        const existRow = Object.filter(x=> x.puDesc == item.PU_Desc )
        if(existRow.length != 1)
        {
        const obj={
          puId: item.PU_Id, puDesc: item.PU_Desc, type: item.type
        }
          Object.push(obj)
         }
       }
     
     }
     
     this.selectedIds = Object;
    }
  }

  getKpiDataPoints(unit: any): void {
    // this.resetCalculation();
    const key = {
      unitId: unit,
      plantId: this.KpiSearchForm.value.plant.DeptId,
     
    };
    this.kpiService.getKPIDataPoints(key).subscribe(
      (datapoint) => {
        const uniquemachine = [
          ...new Set(
            datapoint.map((item) => {
              return item.PU_Desc;
            })
          ),
        ];

        const listcheck: any[] = [];
        if (this.KPIid.data.dpId) {
          let dpid = [];
          dpid = this.KPIid.data.dpId.split(',');
          var result = dpid.map((x: any) => parseInt(x, 10));
          this.kpidatapoints = result;
          for (var i = 0; i < datapoint.length; i++) {
            if (result.indexOf(datapoint[i].KpiDpId) !== -1) {
              datapoint[i].checked = true;
            } else {
              datapoint[i].checked = false;
            }
          }
        }
        let unit: [] = this.KpiIntegrationForm.value.machine;
        let object: any[];
        for (const item of uniquemachine) {
          const obj = {
            machine: item,
            data: datapoint
              .filter((x) => x.PU_Desc === item)
              .map((elem) => {
                return {
                  DataPoints: elem.DataPoints,
                  checked: elem.checked,
                  KpiDpId: elem.KpiDpId,
                };
              }),
          };
          listcheck.push(obj);
        }
        const firstItem = '';
        listcheck.sort((x, y) =>
          x.machine === firstItem ? -1 : y.machine === firstItem ? 1 : 0
        );
        this.datapoints1 = listcheck;
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getIds(event:any):void{

    let Object = event;
    this.unitData =[];
    this.IdsType = [];
   
  if(Object[0] == 0)
  {
   const obj={
     puId:0,
     puDesc:'Plant',
     type:2
   }
   Object[0]=obj
  }

  let unitObject = [];
  let lineObject = [];
  let typeObject = [];
  this.lineObj = [];
  this.unitObj = [];
  this.unitData = [];

 for(let i = 0; i< Object.length; i++ )
 {
   if(Object[i].type == 3)
   {
     lineObject.push(Object[i].puId.toString())
     typeObject.push(Object[i].type.toString())

     const obj={
       Id:Object[i].puId,
       type:Object[i].type
     }
     this.IdsType.push(obj)

   }
   else{
     unitObject.push(Object[i].puId.toString())
     typeObject.push(Object[i].type.toString())

     const obj={
       Id:Object[i].puId,
       type:Object[i].type
     }
     this.IdsType.push(obj)
   }
   
 }

 this.lineObj = lineObject.toString();
 this.unitObj = unitObject.toString();
 this.unitData = this.unitObj;

 typeObject = typeObject.filter((value, index, self) => {
   return self.indexOf(value) === index;
 });
 this.typeObj = typeObject.toString();
  }
  onMachineChange(event: any,selected :boolean): void {
   // let unit = this.KpiIntegrationForm.value.machine.toString();
    
    this.allSelected = selected;
    this.getIds(event.value)
    // if (unit === '' || line === '') {
    //   this.CheckGeneralCatagory = false;
    //  } 
    // else if (unit === '0') {
    //   unit = '';
    //   this.CheckGeneralCatagory = true;
    // }
    //  else {
    //   this.CheckGeneralCatagory = true;
    // }
    this.kpidatapoints = [];
    this.getKpiDataPointsByType(this.unitObj,this.lineObj,this.typeObj);
  }
  onChangeDataSource(): void {
    this.geto3Area();
  }
  getMachinebyplant(): void {
    const key = {
      ptId: this.KpiSearchForm.value.plant.DeptId,
    };
    this.kpitreeservice.plantUnit(key).subscribe(
      (machine) => {
        this.unitList = machine;
         
        this.unitList.forEach((elem, i) => {
          if(this.unitList[i].puId != 0 && this.unitList[i].puDesc != 'Plant')
          {
            this.unitList[i].puDesc = this.unitList[i].puDesc+' (Machine)';
          }
       
        });
        
        this.filteredMachines = this.unitList.slice();
        this.getLines();

       
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getLines(): void {
    const key = {
      plantId:  this.KpiSearchForm.value.plant.DeptId,
    };
    this.commonService.getmasterline(key).subscribe(
      (lines) => {
       

         // convert db field name into array object field
       
        for(let i = 0;i<lines.length;i++)
        {
          const obj={
              puId:lines[i].plId,
              puDesc:lines[i].plDesc+' (Line)',
              type:3
            }
            
            this.unitList.push(obj)
            this.filteredMachines.push(obj)
          }
        //this.DataPointsForm.get('machine')?.setValue(setValueArray);

       
       },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getDataSource(): void {
    const key = {
      plantId: this.KpiSearchForm.value.plant.DeptId,
    };
    this.kpiService.getKPIDataSource(key).subscribe(
      (res) => {
        this.datasourceList = res;
        this.filteredDataSource = this.datasourceList.slice();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getServiceType(): void {
    const key = {
      plantId: this.KpiSearchForm.value.plant.DeptId,
    };
    this.kpiService.getKPIServiceType(key).subscribe(
      (res) => {
        this.servicetypeList = res;
        this.filteredServiceType = this.servicetypeList.slice();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  geto3Area(): void {
    const key = {
      plantId: this.KpiSearchForm.value.plant.DeptId,
      dsId: this.KpiIntegrationForm.value.dataSource,
    };
    this.kpiService.getKPIO3Areas(key).subscribe(
      (res) => {
        this.areaList = res;
        this.filteredareas = this.areaList.slice();
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  clearSearchField(): void {
    this.KpiIntegrationForm.get('calculation')?.setValue('');
  }

  onCheckboxChange(
    checkBoxStatus: MatCheckboxChange,
    id: number,
    dataPoints: any,
    i1: number,
    i2: number
  ): any {
    if (checkBoxStatus.checked) {
      //    if (this.calcList.length === 0  || this.calcList[this.calcList.length - 1].dpid === '+' ||
      //     this.calcList[this.calcList.length - 1].dpid === '/' ||
      //    this.calcList[this.calcList.length - 1].dpid === '(' || this.calcList[this.calcList.length - 1].dpid === '-' ||
      //    this.calcList[this.calcList.length - 1].dpid === ')' || this.calcList[this.calcList.length - 1].dpid === '*'
      //    )
      //  {
      this.datapoints1.forEach((element, index) => {
        element.data.forEach((element1: any) => {
          if (element1.KpiDpId === id) {
            element1.checked = true;
          }
        });
      });
      if (this.calcList.length === 0) {
        const key = {
          dpid: id,
          dpName: dataPoints,
          editable: false,
          disable: false,
          type: 'Operands',
        };
        this.calcList.push(key);
        let formvalidate = '';
        this.calcList.forEach((elem, index) => {
          formvalidate = formvalidate + elem.dpid;
        });
        // this.KpiIntegrationForm.get('formula')?.setValue(formvalidate);
        try {
          // tslint:disable-next-line:no-eval
          if (eval(formvalidate)) {
            this.disableSave = false;
          }
        } catch (error) {
          this.disableSave = true;
        }
      } else {
        let value = 0;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.calcList.length; i++) {
          if (this.calcList[i].dpid === id) {
            value++;
          }
        }
        if (value === 0) {
          const key = {
            dpid: id,
            dpName: dataPoints,
            editable: false,
            disable: false,
            type: 'Operands',
          };
          this.calcList.push(key);
          let formvalidate = '';
          this.calcList.forEach((elem, index) => {
            formvalidate = formvalidate + elem.dpid;
          });
          try {
            // tslint:disable-next-line:no-eval
            if (eval(formvalidate)) {
              let counter = 0;
              this.disableSave = false;
              for (let i = 0; i < this.calcList.length - 1; i++) {
                if (
                  this.calcList[i].type === 'Operands' &&
                  this.calcList[i + 1].type === 'Operands' &&
                  this.calcList.length > i
                ) {
                  counter++;
                }
                if (counter > 0) {
                  // console.log('double Operator');
                  this.disableSave = true;
                }
              }
            }
          } catch (error) {
            this.disableSave = true;
          }
          // this.calcKpiDataPoints = this.KpiIntegrationForm.value.calculation;
          // this.calcKpiDataPoints = this.calcKpiDataPoints + dataPoints;
          this.calcKpiDataPointIds.push(id.toString());
        }
      }
      this.KpiIntegrationForm.get('calculation')?.setValue(
        this.calcKpiDataPoints
      );

      this.kpidatapoints.push(id);
    } else {
      this.calcList.forEach((element, index) => {
        if (element.dpid === id) {
          this.calcList.splice(index, 1);
        }
      });
      this.datapoints1.forEach((element, index) => {
        element.data.forEach((element1: any) => {
          if (element1.KpiDpId === id) {
            element1.checked = false;
          }
        });
      });
      this.calcKpiDataPointIds.forEach((element, index) => {
        if (element === id) {
          this.calcKpiDataPointIds.splice(index, 1);
        }
      });
      let formvalidate = '';
      this.calcList.forEach((elem, index) => {
        formvalidate = formvalidate + elem.dpid;
      });
      try {
        // tslint:disable-next-line:no-eval
        if (eval(formvalidate)) {
          let counter = 0;
          this.disableSave = false;
          for (let i = 0; i < this.calcList.length - 1; i++) {
            if (
              this.calcList[i].type === 'Operands' &&
              this.calcList[i + 1].type === 'Operands' &&
              this.calcList.length > i
            ) {
              counter++;
            }
            if (counter > 0) {
              this.disableSave = true;
            }
          }
        }
      } catch (error) {
        this.disableSave = true;
      }
    }
  }
  remainchecked(i1: number, i2: number): void {
    setTimeout(() => {
      if (this.remche) {
        this.datapoints1[i1].data[i2].checked = false;
        this.remche = false;
      }
    }, 5000);
  }

  saveKpiIntegration(): void {

   
    this.getIds(this.selectedIds)
    // const t = this.datapoints
    //   .filter((datapoints) => datapoints.checked)
    //   .map((datapoints) => datapoints);
    // this.selected = t;
    this.completeformula = '';
    this.completeFormulaWithId = '';
    this.usedKpiDataPoints = [];
    this.calcList.forEach((element: any) => {
      this.completeformula = this.completeformula + element.dpName;
      this.completeFormulaWithId = this.completeFormulaWithId + element.dpid;
      if (element.editable === false) {
        this.usedKpiDataPoints.push(element.dpid);
      }
    });
   
    const key = {
      // dsId: this.KPIid.data.dsId,
      // areaId: this.KPIid.data.areaId,
      stId: this.KpiIntegrationForm.value.servicetype,
      ptId: this.KpiSearchForm.value.plant.DeptId,
      userId: JSON.parse(localStorage.user).UserId,
      datacollection: this.completeformula,
      units: this.unitData.toString(),
     // units: this.KpiIntegrationForm.value.machine.toString(),
      // datapoints: [...new Set(this.kpidatapoints)].toString(),
      datapoints: [...new Set(this.usedKpiDataPoints)].toString(),
      kpiId: this.KPIid.data.KpiId,
      calculationId: this.completeFormulaWithId,
      CalculationString: JSON.stringify(this.calcList),
      IdsType:JSON.stringify(this.IdsType)
    };

    this.kpiService.addKPIintegration(key).subscribe(
      (res: any) => {
        this.toaster.success('KPI Integration Added Successfuly');
        this.dialog.closeAll();
        this.getKpiIntegration();
        this.unitData = [];
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout('autoHeight');
    const e: HTMLElement = document.querySelector('#myGrid') as HTMLElement;
    if (e !== null) {
      e.style.height = '';
    }
  }
  //menu and breadcrumb
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length - 1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }

  omit_special_char(event: any): boolean {
    let k;
    k = event.charCode;
    return (
      k === 45 ||
      k === 61 ||
      (k > 39 && k < 44) ||
      k === 8 ||
      (k >= 47 && k <= 57)
    );
  }

  resetCalculation(): void {
    this.clearSearchField();
    this.calcKpiDataPoints = [];
    this.calcKpiDataPointIds = [];
    this.dataPointsList = [];
    this.kpidatapoints = [];
    this.calcList = [];
    this.KpiIntegrationForm.get('calculation')?.reset();
    this.KpiIntegrationForm.get('formula')?.reset();
    this.checkboxes.forEach((elem: any) => {
      elem.checked = false;
    });
  }

  addOperator(oper: any): void {
    // this.calcKpiDataPoints = this.calcKpiDataPoints + oper;
    this.calcKpiDataPointIds.push(oper.toString());
    // this.KpiIntegrationForm.get('calculation')?.setValue(this.calcKpiDataPoints);
    const key = {
      dpid: oper,
      dpName: oper,
      editable: true,
      disable: false,
      type: 'Operatore',
    };
    this.calcList.push(key);
    let formvalidate = '';
    this.calcList.forEach((elem, index) => {
      formvalidate = formvalidate + elem.dpid;
    });
    this.KpiIntegrationForm.get('formula')?.setValue(formvalidate);
    try {
      // tslint:disable-next-line:no-eval
      if (eval(formvalidate)) {
        let counter = 0;
        this.disableSave = false;
        for (let i = 0; i < this.calcList.length - 1; i++) {
          if (
            this.calcList[i].type === 'Operands' &&
            this.calcList[i + 1].type === 'Operands' &&
            this.calcList.length > i
          ) {
            counter++;
          }
          if (counter > 0) {
            this.disableSave = true;
          }
        }
      }
    } catch (error) {
      this.disableSave = true;
    }
  }

  updateOperator(updatedValue: any, index: number): void {
    this.calcList[index].dpName = updatedValue;
    this.calcList[index].disable = false;
  }

  dragStart(index: any): void {
    this.PrevIndex = index;
  }

  mouseEnter(index: any): void {
    this.currentIndex = index;
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.calcList, this.PrevIndex, this.currentIndex);
    let formvalidate = '';
    this.calcList.forEach((elem, index) => {
      formvalidate = formvalidate + elem.dpid;
    });
    try {
      // tslint:disable-next-line:no-eval
      if (eval(formvalidate)) {
        let counter = 0;
        this.disableSave = false;
        for (let i = 0; i < this.calcList.length - 1; i++) {
          if (
            this.calcList[i].type === 'Operands' &&
            this.calcList[i + 1].type === 'Operands' &&
            this.calcList.length > i
          ) {
            counter++;
          }
          if (counter > 0) {
            this.disableSave = true;
          }
        }
      }
    } catch (error) {
      this.disableSave = true;
    }
  }

  // drop(object: any): object is CdkDragDrop<any[]> {
  //   return 'previousIndex' in object;
  // }

  allowEdit(key: any, index: number): void {
    if (key.editable) {
      this.calcList[index].disable = true;
    }
  }

  removeKeyword(key: any, index: any): any {
    this.calcKpiDataPointIds.forEach((element, index) => {
      if (element === key.dpid) {
        this.calcKpiDataPointIds.splice(index, 1);
      }
    });
    this.datapoints1.forEach((element) => {
      element.data.forEach((element1: any) => {
        if (element1.KpiDpId === key.dpid) {
          element1.checked = false;
        }
      });
    });
    this.calcList.splice(index, 1);
    let formvalidate = '';
    this.calcList.forEach((elem) => {
      formvalidate = formvalidate + elem.dpid;
    });
    try {
      // tslint:disable-next-line:no-eval
      if (eval(formvalidate)) {
        let counter = 0;
        this.disableSave = false;
        for (let i = 0; i < this.calcList.length - 1; i++) {
          if (
            this.calcList[i].type === 'Operands' &&
            this.calcList[i + 1].type === 'Operands' &&
            this.calcList.length > i
          ) {
            counter++;
          }
          if (counter > 0) {
            this.disableSave = true;
          }
        }
      }
    } catch (error) {
      this.disableSave = true;
    }
  }

  toggleAllSelection(): void {
    if (this.allSelected) {
      this.selectAll.options.forEach((item: MatOption) => item.select());
      const filteredMachine = this.filteredMachines?.map(
        (item: any) => item?.DirectQueryId
      );
      this.KpiIntegrationForm.get('directQuery')?.setValue([
        ...filteredMachine,
      ]);
    } else {
      this.selectAll.options.forEach((item: MatOption) => item.deselect());
      this.KpiIntegrationForm.get('directQuery')?.setValue([]);
    }
  }

  getFrequencies(): void {
    const key = {
      plantId: this.KpiSearchForm.value.plant.DeptId,
    };
    this.kpimaster.getMasterFrequencies(key).subscribe(
      (frequencies) => {
        this.frequencies = frequencies;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
}
