<ngx-spinner
  bdColor="rgba(0, 0, 0, 0)"
  template=" <img src='assets/images/spinner.gif' />"
>
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>
<div class="sfdc-configuration-content">
  <div class="header-title">
    <h1 class="main-title">{{ stageName }}</h1>
    <div class="pagination-btn-group">
      <div *ngIf="stageId == 1" class="main-title">
        Status :
        <span
          class="stage-status"
          [ngClass]="{
            'complete-bg': headerData.Status6W2H == 'Completed',
            'inprogress-bg': headerData.Status6W2H == 'InProgress',
            'open-bg': headerData.Status6W2H == 'Open'
          }"
          >{{
            headerData && headerData.Status6W2H ? headerData.Status6W2H : ""
          }}</span
        >
      </div>

      <div *ngIf="stageId == 2" class="main-title">
        Status :
        <span
          class="stage-status"
          [ngClass]="{
            'complete-bg': headerData.StatusFishbone == 'Completed',
            'inprogress-bg': headerData.StatusFishbone == 'InProgress',
            'open-bg': headerData.StatusFishbone == 'Open'
          }"
          >{{
            headerData && headerData.StatusFishbone
              ? headerData.StatusFishbone
              : ""
          }}</span
        >
      </div>

      <div *ngIf="stageId == 3" class="main-title">
        Status :
        <span
          class="stage-status"
          [ngClass]="{
            'complete-bg': headerData.StatusCauseEffect == 'Completed',
            'inprogress-bg': headerData.StatusCauseEffect == 'InProgress',
            'open-bg': headerData.StatusCauseEffect == 'Open'
          }"
          >{{
            headerData && headerData.StatusCauseEffect
              ? headerData.StatusCauseEffect
              : ""
          }}</span
        >
      </div>

      <div *ngIf="stageId == 4" class="main-title">
        Status :
        <span
          class="stage-status"
          [ngClass]="{
            'complete-bg': headerData.StatusFiveWhy == 'Completed',
            'inprogress-bg': headerData.StatusFiveWhy == 'InProgress',
            'open-bg': headerData.StatusFiveWhy == 'Open'
          }"
          >{{
            headerData && headerData.StatusFiveWhy
              ? headerData.StatusFiveWhy
              : ""
          }}</span
        >
      </div>

      <div *ngIf="stageId == 5" class="main-title">
        Status :
        <span
          class="stage-status"
          [ngClass]="{
            'complete-bg': headerData.StatusFiveWhyAction == 'Completed',
            'inprogress-bg': headerData.StatusFiveWhyAction == 'InProgress',
            'open-bg': headerData.StatusFiveWhyAction == 'Open'
          }"
          >{{
            headerData && headerData.StatusFiveWhyAction
              ? headerData.StatusFiveWhyAction
              : ""
          }}</span
        >
      </div>

      <div class="rca" *ngIf="rcaId || rca">RCA</div>
      <h3 *ngIf="filteredData && filteredData.length">
        <span>{{ currentIndex + 1 }}</span
        ><span> of </span
        ><span>{{ filteredData.length ? filteredData.length : 0 }}</span>
      </h3>
      <button
        type="button"
        (click)="pageSettings('previous', true)"
        class="nav-button"
        tooltipPosition="top"
        [disabled]="!currentIndex"
        *ngIf="filteredData && filteredData.length"
      >
        <span>
          <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
            <path
              d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"
            ></path>
          </svg>
        </span>
      </button>
      <button
        type="button"
        (click)="pageSettings('previous', false)"
        class="nav-button"
        tooltipPosition="top"
        [disabled]="!currentIndex"
        *ngIf="filteredData && filteredData.length"
      >
        <span>
          <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
          </svg>
        </span>
      </button>
      <button
        type="button"
        (click)="pageSettings('next', false)"
        class="nav-button"
        tooltipPosition="top"
        [disabled]="currentIndex == filteredData.length - 1"
        *ngIf="filteredData && filteredData.length"
      >
        <span>
          <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
          </svg>
        </span>
      </button>
      <button
        type="button"
        (click)="pageSettings('next', true)"
        class="nav-button"
        tooltipPosition="top"
        [disabled]="currentIndex == filteredData.length - 1"
        *ngIf="filteredData && filteredData.length"
      >
        <span>
          <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
            <path
              d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"
            ></path>
          </svg>
        </span>
      </button>
      <!-- <div class="rca" *ngIf="rcaId || rca">RCA</div> -->
    </div>
  </div>
  <form
    name="searchDefectForm"
    [formGroup]="searchDefectForm"
    #formDirective="ngForm"
    (ngSubmit)="onSubmit(searchDefectForm, formDirective)"
  >
    <div class="w-100 headerForm">
      <mat-form-field appearence="fill" class="w-22">
        <mat-label>Group</mat-label>
        <mat-select
          [(ngModel)]="headerData.Group"
          formControlName="group"
          (selectionChange)="getDeptData()"
        >
          <mat-select-filter
            [placeholder]="'Search Group'"
            [displayMember]="'TypeName'"
            [array]="groupList"
            (filteredReturn)="groupTempList = $event"
          >
          </mat-select-filter>
          <!-- <input (keyup)="onKey($event.target,'group')" (change)="onKey('','group')" class="form-control w-100"
            placeholder="Search Group"> -->
          <!-- <mat-option value="">Select Group </mat-option> -->
          <mat-option
            *ngFor="let group of groupTempList"
            [value]="group.TypeId"
          >
            {{ group.TypeName }}
          </mat-option>
        </mat-select>
        <mat-error>Group is required</mat-error>
      </mat-form-field>
      <mat-form-field appearence="fill" class="w-22">
        <mat-label>Plant</mat-label>
        <mat-select
          [(ngModel)]="headerData.Plant"
          formControlName="plant"
          (selectionChange)="
            getAllDepartment(); getTeamList(); emitPlantChange()
          "
        >
          <mat-select-filter
            [placeholder]="'Search Plant'"
            [displayMember]="'DeptDesc'"
            [array]="plantList"
            (filteredReturn)="plantTempList = $event"
          >
          </mat-select-filter>
          <!-- <mat-option value="">Select Plant </mat-option> -->
          <!-- <input (keyup)="onKey($event.target,'plant')" (change)="onKey('','plant')" class="form-control w-100"
            placeholder="Search Plant"> -->
          <mat-option
            *ngFor="let plant of plantTempList"
            [value]="plant.DeptId"
          >
            {{ plant.DeptDesc }}
          </mat-option>
        </mat-select>
        <mat-error>Plant is required</mat-error>
      </mat-form-field>
      <mat-form-field appearence="fill" class="w-22">
        <mat-label>Department</mat-label>
        <mat-select
          [(ngModel)]="headerData.Dept"
          formControlName="department"
          (selectionChange)="getUniqueCode()"
        >
          <mat-select-filter
            [placeholder]="'Search Department'"
            [displayMember]="'TypeName'"
            [array]="deptList"
            (filteredReturn)="deptTempList = $event"
          >
          </mat-select-filter>
          <!-- <mat-option value="">Select Department </mat-option> -->
          <mat-option *ngFor="let dept of deptTempList" [value]="dept.TypeId">
            {{ dept.TypeName }}
          </mat-option>
        </mat-select>
        <mat-error>Department is required</mat-error>
      </mat-form-field>

      <mat-form-field appearence="fill" class="w-22">
        <mat-label>Team Leader</mat-label>
        <mat-select
          #selectTeamLeader
          [(ngModel)]="headerData.TeamLeader"
          formControlName="teamleader"
          (selectionChange)="addTeamLeader(headerData.TeamLeader)"
        >
          <mat-select-filter
            *ngIf="
              selectTeamLeader.focused ? true : resetSelectFilter('TeamLeader')
            "
            [placeholder]="'Search User'"
            [displayMember]="'FullName'"
            [array]="teams"
            (filteredReturn)="tempTeams = $event"
          >
          </mat-select-filter>
          <mat-option *ngFor="let user of tempTeams" [value]="user.EmpInfo_Id">
            {{ user.FirstName }} {{ user.lastName }}
          </mat-option>
        </mat-select>
        <mat-error>Team Leader is required</mat-error>
      </mat-form-field>

      <mat-form-field appearence="fill" class="w-22">
        <mat-label>Participants</mat-label>
        <mat-select
          #selectParticipants
          [(ngModel)]="headerData.Team"
          formControlName="team"
          multiple
          (selectionChange)="setParticipantsChange(headerData.Team)"
        >
          <mat-select-filter
            #selectParticipantsFilter
            *ngIf="
              selectParticipants.focused ? true : setTeam();
              resetSelectFilter: 'Participants'
            "
            [placeholder]="'Search User'"
            [displayMember]="'FullName'"
            [array]="teamsParticipant"
            (filteredReturn)="tempTeamsteamsParticipant = $event"
            (keyup)="keyUp()"
          >
          </mat-select-filter>
          <mat-option
            *ngFor="let user of tempTeamsteamsParticipant"
            [value]="user.EmpInfo_Id"
            [disabled]="headerData.TeamLeader == user.EmpInfo_Id"
            (click)="setTeamAndValidate(user.EmpInfo_Id)"
          >
            {{ user.FirstName }} {{ user.lastName }}
          </mat-option>
        </mat-select>
        <mat-error>Participants is required</mat-error>
      </mat-form-field>

      <!-- <mat-form-field appearence="fill" class="w-22">
        <mat-label>Participants</mat-label>
        <mat-select #select [(ngModel)]="headerData.Team" formControlName="team"
          (selectionChange)="setTeamLeader(headerData.Team)"
           multiple>
          <mat-select-filter *ngIf="select.focused ? true : setTeam()" (keyup)="keyUp()" [placeholder]="'Search User'" [displayMember]="'FullName'" [array]="teams"
            (filteredReturn)="tempTeams = $event">
          </mat-select-filter>
          <mat-option *ngFor="let user of tempTeams" [value]="user.EmpInfo_Id" (click)="setTeamAndValidate(user.EmpInfo_Id)">
            {{user.FirstName}} {{user.lastName}}
          </mat-option>
        </mat-select>
        <mat-error>Participants is required</mat-error>
      </mat-form-field>

      <mat-form-field appearence="fill" class="w-22">
        <mat-label>Team Leader</mat-label>
        <mat-select [(ngModel)]="headerData.TeamLeader" formControlName="teamleader"
          >
          <mat-option *ngFor="let teamleader of teamLeaderList" [value]="teamleader.EmpInfo_Id">
            {{teamleader.FirstName}} {{teamleader.lastName}}
          </mat-option>
        </mat-select>
        <mat-error>Team Leader is required</mat-error>
      </mat-form-field>-->
    </div>
    <div class="w-100 headerForm" style="display: flex">
      <div
        *ngIf="stageId != 6"
        style="width: 64%; position: relative"
        title="{{ headerData.InitProbStatement }}"
      >
        <mat-form-field appearence="fill" class="w-70">
          <mat-label>Initial Problem Statement</mat-label>
          <input
            matInput
            [(ngModel)]="headerData.InitProbStatement"
            formControlName="initProbStat"
          />
          <mat-error>Initial Problem Statement is required</mat-error>
        </mat-form-field>

        <div class="imgflex">
          <img
            *ngIf="imageFile && imageSrc"
            (click)="imageZoomModal()"
            [src]="imageSrc"
            alt=""
            width="60px"
            height="40px"
          />
          <img
            *ngIf="!imageSrc && headerData && headerData.UploadFile"
            class="imgrel"
            (click)="imageZoomModal()"
            [src]="ImageUrl + headerData.UploadFile"
            alt=""
            width="60px"
            height="40px"
          />
          <img
            *ngIf="(imageFile && imageSrc) || headerData.UploadFile"
            src="assets/images/delete.png"
            (click)="deleteFile()"
            class="imgabsol"
            alt=""
            width="15px"
            height="15px"
          />
          <!-- <span *ngIf="headerData.UploadFile" style="transform: rotate(90deg);">{{ getFileTypeName(headerData.UploadFile) }}</span> -->
          <button
            mat-flat-button
            color="accent"
            type="button"
            class="upload"
            (click)="openFile()"
          >
            <mat-icon>attach_file</mat-icon>
          </button>
          <input
            type="file"
            (change)="readFile($event)"
            class="d-none"
            #fileInput
            accept="image/*"
          />
        </div>
      </div>

      <mat-form-field appearence="fill" class="w-10">
        <mat-label>RCA Code</mat-label>
        <!-- <mat-select [(ngModel)]="headerData.uniqueCode" formControlName="uniqueCode"
          [disabled]="stageId == 5 ? true : false">
          <mat-select-filter [placeholder]="'Search Unique Code'" [displayMember]="'FullName'" [array]="uniqueCodeList"
            (filteredReturn)="uniqueCodeTempList = $event">
          </mat-select-filter>
          <mat-option *ngFor="let code of uniqueCodeTempList" [value]="code.ID">
            {{code.ID}}
          </mat-option>
        </mat-select> -->
        <input
          matInput
          type="text"
          readonly
          [(ngModel)]="headerData.uniqueCode"
          formControlName="uniqueCode"
        />
      </mat-form-field>

      <!-- <mat-form-field appearence="fill" class="w-10"
        [ngClass]="{ 'd-none': stageId == 1 || stageId == 4 || stageId == 5 || stageId == 6}">
        <mat-label>M Type</mat-label>
        <mat-select [(ngModel)]="headerData.NoOfM" formControlName="mType" (selectionChange)="emitMType()"
          [disabled]="(stageId == 3 && rcaId) ? true : false">
          <mat-option value="">{{ mList && mList.length ? 'Select M' : 'No M Found' }}</mat-option>
          <mat-option *ngFor="let m of mList" [value]="m.id">
            {{m.title}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <!-- <mat-form-field appearence="fill" class="w-48"
        [ngClass]="{ 'd-none': stageId == 1 || stageId == 4 || stageId == 5 || stageId == 6}">
        <mat-label>New Refocused Problem Statement</mat-label>
        <input type="text" matInput formControlName="refocusedProb" class="text-ellipsis" readonly [value]="headerData && headerData.ProblemStatement ? headerData.ProblemStatement : ''">
      </mat-form-field> -->
      <div class="btn-group">
        <button
          type="button"
          [disabled]="!IsWrite ? true : false"
          (click)="resetTask()"
          class="save"
          pTooltip="Reset Task"
          tooltipPosition="top"
          matTooltip="Reset"
          style="margin-left: 20px"
        >
          <i class="fa fa-refresh"></i>
        </button>
        <button
          type="button"
          [disabled]="!IsWrite"
          (click)="navigate('previous')"
          class="save"
          tooltipPosition="top"
          [matTooltip]="
            stageId == 2
              ? '6W2H'
              : stageId == 3
              ? 'FishBone'
              : stageId == 4
              ? 'Cause & Effect'
              : '5 Why'
          "
          *ngIf="
            (stageId != 1 && stageId != 6 && rcaId) ||
            (stageId == 5 && levelId != 0 && levelId != 5) ||
            (stageId != 1 && headerId && stageId > levelId)
          "
        >
          {{ "<" }}
        </button>
        <button
          type="submit"
          [disabled]="!IsWrite"
          pTooltip="Add"
          class="save"
          tooltipPosition="top"
          matTooltip="Save"
        >
          <img src="assets/images/save.png" alt="" width="20px" height="20px" />
        </button>
        <button
          type="button"
          [matTooltip]="
            stageId == 1
              ? 'FishBone'
              : stageId == 2
              ? 'Cause & Effect'
              : stageId == 3
              ? '5 Why'
              : '5 Why Action Plan'
          "
          *ngIf="
            (stageId != 5 && stageId != 6 && rcaId) ||
            (headerId && updateStatus && stageId != 5)
          "
          (click)="navigate('next')"
          class="save"
          [disabled]="searchDefectForm.invalid || !IsWrite"
          tooltipPosition="top"
        >
          {{ ">" }}
        </button>
        <!--  *ngIf="stageId != 6 && stageId != 5 && (!rcaId || (rcaId && stageId == 1))" -->

        <!-- <button type="button"  [disabled]="stageId == 5 || !IsWrite ? true : false" (click)="search()" class="save" tooltipPosition="top" matTooltip="Search">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.3609 18.2168L14.6008 13.2662C15.8247 11.8113 16.4953 9.98069 16.4953 8.07499C16.4953 3.62251 12.8728 0 8.4203 0C3.96782 0 0.345306 3.62251 0.345306 8.07499C0.345306 12.5275 3.96782 16.15 8.4203 16.15C10.0918 16.15 11.6847 15.6458 13.0466 14.6888L17.8428 19.677C18.0432 19.8852 18.3129 20 18.6018 20C18.8753 20 19.1348 19.8957 19.3317 19.7061C19.7502 19.3034 19.7636 18.6357 19.3609 18.2168ZM8.4203 2.10652C11.7114 2.10652 14.3888 4.78391 14.3888 8.07499C14.3888 11.3661 11.7114 14.0435 8.4203 14.0435C5.12921 14.0435 2.45183 11.3661 2.45183 8.07499C2.45183 4.78391 5.12921 2.10652 8.4203 2.10652Z"
                  fill="#10123D" />
              </svg>
            </button> -->
      </div>
    </div>
  </form>
</div>
