import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreventiveMaintananceConfigurationComponent } from 'src/app/O3AdministrationComponents/ManufacturingAdminComponents/preventive-maintanance-configuration/preventive-maintanance-configuration.component';
import { PrevTaskFormComponent } from './prev-task-form/prev-task-form.component';
import { ScheduleTaskComponent } from './schedule-task/schedule-task.component';

const routes: Routes = [
  { path: '', component: PreventiveMaintananceConfigurationComponent },
  { path: 'prevMaster', component: PreventiveMaintananceConfigurationComponent },
  { path: 'edit-tech-task', component: PrevTaskFormComponent },
  { path: 'schedule-task', component: ScheduleTaskComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PreventiveMaintenanceRoutingModule { }
