import { ViewChild, TemplateRef, ViewChildren, QueryList } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { Router } from '@angular/router';
import { CrewScheduleService } from 'src/app/services/crewSchedule.service';
import * as moment from 'moment';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { PlantGroupElement } from 'src/app/interface/plant-model';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
interface Group {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-crew-scheduling',
  templateUrl: './crew-scheduling.component.html',
  styleUrls: ['./crew-scheduling.component.scss'],
})
export class CrewSchedulingComponent implements OnInit {
  constructor(
    private kpiMaster: KpimasterService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private usermanagementService: UserManagementService,
    private crewService: CrewScheduleService,
    private enterpriseGroupService: EnterPriseGroupService,
    public router: Router,
    private dialog: MatDialog
  ) {}

  crewScheduleDataSource!: MatTableDataSource<any>;
  crewScheduleColumns: any[] = [
    'userroleActions',
    'CrewId',
    'ShiftStartDate',
    'ShiftEndDate',
    'shift',
    'CreatedOn',
    'CreatedBy',
  ];
  operatorListDataSource!: MatTableDataSource<any>;
  userOperatorGroupColumns: any[] = [
    'userroleActions',
    'User',
    'Type',
    'Remarks',
    'CreatedOn',
    'CreatedBy',
  ];
  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('displayRoleModal') private displayRoleModal!: TemplateRef<any>;
  @ViewChild('displayCrewScheduleRoleModal')
  private displayCrewScheduleRoleModal!: TemplateRef<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  IsAccess: any;
  IsWrite: any;
  breadcrumList: any;
  errorMessage!: any;
  unitDetailForm!: FormGroup;
  crewScheduleForm!: FormGroup;
  addCrewScheduleForm!: FormGroup;
  addRepeatCrewScheduleForm!: FormGroup;
  updateCrewScheduleData = false;
  isPlantGroupModelVisible = false;
  crewScheduleMode = true;
  selectUnitProperty = false;
  showOperatorListProperty!: boolean;
  Group!: string;
  UnitName: any;
  MachineId!: any;
  groupList!: any[];
  UserList!: any[];
  masterList!: any[];
  CSUsersList!: any[];
  globalDate!: any;
  crewId!: any;
  lastCrewId!: any;
  Date = new Date();
  ONE_HOUR = 24 * (60 * 60 * 1000);
  ONE_HOURS = 27 * (24 * (60 * 60 * 1000));
  group: Group[] = [
    { value: 'None', viewValue: 'None' },
    { value: 'OIG', viewValue: 'OIG' },
  ];
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    // this.globalDate = moment(this.crewScheduleForm.value.date).format('YYYY-MM-DD');
    this.GetBreadcrumList();
    this.GetMenuAccess();
    this.getGroups();

    this.crewScheduleForm = this.fb.group({
      group: [''],
      date: [new Date()], // new Date(),
      Unit: [],
    });

    this.addCrewScheduleForm = this.fb.group({
      shiftStart: new Date(),
      shiftEnd: new Date(),
      shiftDesc: [''],
      user: [''],
      userType: [''],
      remarks: [''],
    });
    this.addRepeatCrewScheduleForm = this.fb.group({
      date: new Date(),
      fromdate: new Date(new Date().getTime() + this.ONE_HOUR),
      todate: new Date(new Date().getTime() + this.ONE_HOURS),
    });

    // this.GetCrewScheduleList();
  }

  // breadCrumb
  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.kpiMaster.GetMenuAccess(key).subscribe(
      (data: any) => {
        // this.permisson = data;
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
  openRepeatCrewSchedule(): void {
    if (this.selectUnitProperty === true) {
      this.dialog.open(this.displayCrewScheduleRoleModal, {
        height: 'auto',
        width: 'auto',
      });
    } else {
      this.toaster.error('Error', 'Please select Unit !');
    }
  }
  openModal(): void {
    this.crewScheduleMode = true;
    this.showOperatorListProperty = false;
    this.addCrewScheduleForm
      .get('shiftStart')
      ?.setValue(moment(this.crewScheduleForm.value.date).format('YYYY-MM-DD'));
    this.addCrewScheduleForm
      .get('shiftEnd')
      ?.setValue(moment(this.crewScheduleForm.value.date).format('YYYY-MM-DD'));
    this.addCrewScheduleForm.get('shiftDesc')?.reset();
    this.addCrewScheduleForm.get('remarks')?.reset();
    if (this.selectUnitProperty === true) {
      this.dialog.open(this.displayRoleModal, { height: 'auto', width: '90%' });
    } else {
      this.toaster.error('Error', 'Please select Unit !');
    }
    this.GetCSUserListForDefault();
  }

  openModalForEditCrewSchedule(): void {
    this.crewScheduleMode = true;
    this.showOperatorListProperty = true;
    this.addCrewScheduleForm.get('shiftDesc')?.reset();
    this.addCrewScheduleForm.get('remarks')?.reset();
    if (this.selectUnitProperty === true) {
      this.dialog.open(this.displayRoleModal, { height: 'auto', width: '90%' });
    } else {
      this.toaster.error('Error', 'Please select Unit !');
    }
    // this.GetCSUserListForDefault();
    // this.GetCSUserList();
    // this.GetCrewScheduleList();
  }

  RefreshAllData(element: any): void {
    this.selectUnitProperty = false;
    this.crewScheduleForm.get('date')?.setValue(new Date());
    this.crewScheduleForm.get('Unit')?.reset();
    this.crewScheduleDataSource = new MatTableDataSource<any>([]);
  }

  getGroups(): void {
    const key = {
      CreatedBy: 0,
    };
    this.crewService.getGroups(key).subscribe((res: any) => {
      this.groupList = res.masterList;
      this.crewScheduleForm.get('group')?.setValue(this.groupList[0].TypeId);
    });
  }

  GetUserManagerList(): void {
    const key = {
      GroupId: this.crewScheduleForm.get('group')?.value,
      UserId: 0,
    };
    this.crewService.GetUserManagerList(key).subscribe((res: any) => {
      this.UserList = res.UserList;
      this.addCrewScheduleForm.get('user')?.setValue(this.UserList[0].UserId);
    });
  }

  CrewUserType(): void {
    const key = {
      Id: 0,
      CreatedBy: 0,
    };
    this.crewService.CrewUserType(key).subscribe((res: any) => {
      this.masterList = res.masterList;
      this.addCrewScheduleForm
        .get('userType')
        ?.setValue(this.masterList[0].TypeId);
    });
  }

  GetCSUserListForDefault(): void {
    const key = {
      CrewId: 0,
      CSDId: 0,
    };
    this.crewService.GetCSUserList(key).subscribe((res: any) => {
      this.CSUsersList = res.CSUsersList;
      this.operatorListDataSource = new MatTableDataSource<any>(
        res.CSUsersList
      );
      this.getPaginator(this.operatorListDataSource, 1);
    });
  }

  GetCSUserList(): void {
    const key = {
      CrewId: this.crewId, // this.crewId,
      CSDId: 0,
    };
    this.crewService.GetCSUserList(key).subscribe((res: any) => {
      this.CSUsersList = res.CSUsersList;
      this.operatorListDataSource = new MatTableDataSource<any>(
        res.CSUsersList
      );
      this.getPaginator(this.operatorListDataSource, 1);
    });
  }

  SubmitUserList(): void {
    const key = {
      Transactiontypeid: 1,
      UserId: this.addCrewScheduleForm.get('user')?.value, // 104,
      UserTypeId: this.addCrewScheduleForm.get('userType')?.value, // 1,
      CSDId: 0,
      CrewId: this.crewId, // this.crewId,
      Remarks: this.addCrewScheduleForm.get('remarks')?.value,
    };
    this.crewService.SubmitUserList(key).subscribe((res: any) => {
      this.showPopupMsg(res.ErrorMessage);
      this.GetCSUserList();
    });
  }

  deleteOperatorList(element: any): void {
    const key = {
      Transactiontypeid: 3,
      UserId: this.addCrewScheduleForm.get('user')?.value,
      UserTypeId: this.addCrewScheduleForm.get('userType')?.value,
      CSDId: element.CSDID,
      CrewId: element.CrewId,
      Remarks: element.Remarks,
    };
    this.crewService.SubmitUserList(key).subscribe((res: any) => {
      this.showPopupMsg(res.ErrorMessage);
      this.GetCSUserList();
    });
  }

  DateInputChange(): void {
    if (this.selectUnitProperty === true) {
      this.GetCrewScheduleList();
    }
  }

  GetCrewScheduleList(): void {
    const key = {
      PUID: this.MachineId,
      Date: moment(this.crewScheduleForm.value.date).format('YYYY-MM-DD'),
    };
    this.crewService.GetCrewScheduleList(key).then((res: any) => {
      this.crewScheduleDataSource = new MatTableDataSource<any>(
        res.CrewScheduleList
      );
      this.getPaginator(this.crewScheduleDataSource, 0);
      // this.lastCrewId =
      //   res.CrewScheduleList[res.CrewScheduleList.length - 1].CrewId;
    });
  }

  GetCrewScheduleListForEdit(): void {
    const key = {
      CrewId: this.crewId,
      PUID: this.MachineId,
      Date: moment(this.crewScheduleForm.value.date).format('YYYY-MM-DD'),
    };
    this.crewService.GetCrewScheduleList(key).then((res: any) => {
      this.crewScheduleDataSource = new MatTableDataSource<any>(
        res.CrewScheduleList
      );
      this.GetCrewScheduleList();
      this.getPaginator(this.crewScheduleDataSource, 0);
      // this.lastCrewId =
      //   res.CrewScheduleList[res.CrewScheduleList.length - 1].CrewId;
    });
  }

  SubmitCrewSchedule(): void {
    this.showOperatorListProperty = true;
    const key = {
      TransactionTypeId: 1,
      PUId: this.MachineId,
      ShiftStart: this.addCrewScheduleForm.value.shiftStart,
      ShiftEnd: this.addCrewScheduleForm.value.shiftEnd,
      Date: moment(this.crewScheduleForm.value.date).format('YYYY-MM-DD'),
      ShiftDesc: this.addCrewScheduleForm.value.shiftDesc,
      CrewId: 0,
    };
    this.crewService.SubmitCrewSchedule(key).subscribe((res: any) => {
      this.showPopupMsg(res.ErrorMessage);
      this.GetCrewScheduleList();
      this.GetUserManagerList();
      this.CrewUserType();
    });
  }

  editCrewSchedule(element: any): void {
    // this.openModal();
    debugger;
    this.openModalForEditCrewSchedule();
    this.crewId = element.CrewId;
    this.crewScheduleMode = false;
    this.addCrewScheduleForm.get('shiftStart')?.setValue(element.ShiftStart);
    this.addCrewScheduleForm.get('shiftEnd')?.setValue(element.ShiftEnd);
    this.addCrewScheduleForm.get('shiftDesc')?.setValue(element.Shift);
    this.GetUserManagerList();
    this.CrewUserType();
    this.GetCSUserList();
    this.GetCrewScheduleListForEdit();
  }

  UpdateCrewSchedule(): void {
    const key = {
      TransactionTypeId: 2,
      PUId: this.MachineId,
      ShiftStart: this.addCrewScheduleForm.value.shiftStart,
      ShiftEnd: this.addCrewScheduleForm.value.shiftEnd,
      Date: moment(this.crewScheduleForm.value.date).format('YYYY-MM-DD'),
      ShiftDesc: this.addCrewScheduleForm.value.shiftDesc,
      CrewId: this.crewId,
    };
    this.crewService.SubmitCrewSchedule(key).subscribe((res: any) => {
      this.showPopupMsg(res.ErrorMessage);
      this.GetCrewScheduleList();
      this.crewScheduleMode = true;
      this.dialog.closeAll();
    });
  }

  DeleteCrewSchedule(element: any): void {
    const key = {
      TransactionTypeId: 3,
      PUId: this.MachineId,
      ShiftStart: this.addCrewScheduleForm.value.shiftStart,
      ShiftEnd: this.addCrewScheduleForm.value.shiftEnd,
      Date: moment(this.crewScheduleForm.value.date).format('YYYY-MM-DD'),
      ShiftDesc: this.addCrewScheduleForm.value.shiftDesc,
      CrewId: element.CrewId,
    };
    this.crewService.SubmitCrewSchedule(key).subscribe((res: any) => {
      this.showPopupMsg(res.ErrorMessage);
      this.GetCrewScheduleList();
    });
  }

  SubmitRepeatCrewSchedule(): void {
    const key = {
      PUID: this.MachineId,
      Date: moment(this.addRepeatCrewScheduleForm.value.date).format(
        'YYYY-MM-DD'
      ),
      DateFrom: moment(this.addRepeatCrewScheduleForm.value.fromdate).format(
        'YYYY-MM-DD'
      ),
      DateTo: moment(this.addRepeatCrewScheduleForm.value.todate).format(
        'YYYY-MM-DD'
      ),
    };
    this.crewService.SubmitRepeatCrewSchedule(key).subscribe((res: any) => {
      this.showPopupMsg(res.ErrorMessage);
      this.dialog.closeAll();
    });
  }

  openTreeModal(): void {
    // debugger;
    this.updateCrewScheduleData = true;
    const key = {
      EGId: this.crewScheduleForm.value.group,
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };

    this.enterpriseGroupService.getTreeModal(key).subscribe(
      (data: any) => {
        // tslint:disable-next-line:no-shadowed-variable
        const plantGroupData: PlantGroupElement[] = data.TreeModalList;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.isPlantGroupModelVisible = true;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  closePlantGroupModel(): void {
    this.updateCrewScheduleData = false;
  }

  // collapse group view
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }

  // expand group view
  expandAllNode(): void {
    this.treeControl.expandAll();
  }

  SelectUnitName(unitname: any): void {
    this.selectUnitProperty = true;
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
    }
  }

  onNodeSelect(node: any): void {
    this.updateCrewScheduleData = false;
    this.MachineId = node.PrimaryId;
    this.crewScheduleForm.get('Unit')?.setValue(node.text);
    this.GetCrewScheduleList();
  }

  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  replaceString(value: any): any {
    const data = value.split('"')[1];
    if (data === 'IsOptionalplanticon') {
      return true;
    } else {
      return false;
    }
  }

  showPopupMsg(msg: any): any {
    if (msg !== null || msg !== '') {
      if (msg.substring(2, 1) === '0') {
        this.toaster.success('Success', msg.substring(3, msg.length));
      } else {
        this.toaster.error('Error', msg.substring(3, msg.length));
      }
    }
  }

  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
      data.sort = this.sort.toArray()[index];
    }, 1000);
  }
}
