<div class="production-db-content" dir="{{languageDir}}">
    <div class="content-top-box">
        <ul class="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let breadcrumItem of breadcrumList">
                    <a class="breadcrumb-link" [routerLink]="breadcrumItem.Url" [id]="breadcrumItem.id"
                        (click)="DisplayRightmenu(breadcrumItem.id, breadcrumItem.Url)">{{breadcrumItem.text}}</a>
                </li>
            </ul>
        </ul>
        <div class="title-row-flex-both-end">
            <h1 class="main-title">{{langObj ? langObj?.kpidatarefresh : ('kpidatarefresh' | translate)}}</h1>
        </div>
    </div>
    <form [formGroup]="KPIRefreshForm">
        <div class="content-top-row content-top-row-order-processing">
            <div class="inputField">
                <nz-date-picker class="calender-input" id="startPicker" formControlName="dateStart"
                    nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }"
                    [nzInputReadOnly]="true"></nz-date-picker>
                <span class="floating-text">{{langObj ? langObj?.StartDate : ('StartDate' | translate)}}</span>
            </div>
            <div class="inputField">
                <nz-date-picker class="calender-input" id="endPicker" formControlName="dateEnd"
                    nzFormat="dd/MM/yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }"
                    [nzInputReadOnly]="true"></nz-date-picker>
                <span class="floating-text">{{langObj ? langObj?.EndDate : ('EndDate' | translate)}}</span>
            </div>
            <!-- GROUP -->
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <!-- <mat-label>Status</mat-label> -->
                <mat-select formControlName="UserGroup1" (selectionChange)="onChangeGroup($event)"
                    placeholder="{{langObj ? langObj?.Group : ('Group' | translate)}}" panelClass="testClass"
                    name="Status">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'EG_Desc'" [array]="GroupList"
                        (filteredReturn)="filteredGroups = $event"></mat-select-filter>
                    <mat-option *ngFor="let group of filteredGroups" [value]="group.EG_Id">
                        {{group.EG_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="UserPlant1" (selectionChange)="onChangePlant($event)"
                    placeholder="{{langObj ? langObj?.Plant : ('Plant' | translate)}}" panelClass="testClass"
                    name="Status">
                    <mat-select-filter [placeholder]="'Search'" [displayMember]="'PT_Desc'" [array]="PlantList1"
                        (filteredReturn)="filteredPlants = $event"></mat-select-filter>
                    <mat-option *ngFor="let plant of filteredPlants" [value]="plant.PT_Id">
                        {{plant.PT_Desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="frequencies" (selectionChange)="onFrequencyChange($event)"
                    placeholder="{{langObj ? langObj?.Frequency : ('Frequency' | translate)}}" panelClass="testClass"
                    name="Status">
                    <mat-option *ngFor="let fre of frequencies" [value]="fre.frequencyId">
                        {{fre.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearence="fill"
                class="top-row-first-filed-item top-row-first-filed-item-order-processing">
                <mat-select formControlName="kpi" placeholder="{{
        langObj ? langObj?.kpi : ('kpi' | translate)
      }}" multiple>
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="no match found"
                            [formControl]="valueSearchCtrl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let obj of filteredValueMulti | async" [value]="obj.kpiCode">
                        {{obj.fullCodeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="btn-position">
                <button mat-mini-fab color="primary" type="submit"
                    pTooltip="{{langObj ? langObj?.save : ('save' | translate)}}" tooltipPosition="top"
                    (click)="onSubmit()">
                    <mat-icon style="color: #fff">refresh</mat-icon>
                </button>
            </div>
        </div>
    </form>
   
    <div style="display: flex;">
        <div style="width:50%">
            <mat-card>
                <div style="padding:10px;background-color: #0F0FD1;">
                    <div class="p-grid p-fluid">
                        <div class="p-col-6">
                            <mat-card-title style="color: white;">{{langObj ? langObj?.calculatedActualdata : ('calculatedActualdata'
                                |
                                translate)}}
                            </mat-card-title>
                        </div>
                    </div>

                </div>
                <mat-card-content>
                    <ag-grid-angular #agGrid id="myGrid" style="width: 100%;" class="ag-theme-alpine"
                        [columnDefs]="columnDefs" [rowData]="rowData" rowSelection="multiple"
                        (gridReady)="onGridReady($event)" [gridOptions]="gridOptions" [pagination]="true"
                        [paginationPageSize]="7"></ag-grid-angular>
                </mat-card-content>


            </mat-card>
        </div>
        <div style="width:50%">
            <mat-card>
                <div style="padding:10px;background-color: #0F0FD1;">
                    <div class="p-grid p-fluid">
                        <div class="p-col-6">
                            <mat-card-title style="color: white;">{{langObj ? langObj?.calculatedTargetdata : ('calculatedTargetdata'
                                |
                                translate)}}
                            </mat-card-title>
                        </div>
                    </div>
                </div>
                <mat-card-content>
                    <ag-grid-angular #agGrid1 id="myGrid1" style="width: 100%;" class="ag-theme-alpine"
                        [columnDefs]="columnTargetDefs" [rowData]="rowDataTarget" rowSelection="multiple"
                        (gridReady)="onGridReady($event)" [gridOptions]="gridOptionstarget" [pagination]="true"
                        [paginationPageSize]="7"></ag-grid-angular>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

</div>