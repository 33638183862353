import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Group } from '../models/group';
import { Plant } from '../models/plant';

@Injectable({
  providedIn: 'root'
})
export class QualityShiftService {
  kpibaseUrl: string = environment.O3CoreApiUrl;
  o3CoreApiUrl: string = environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  addQualityShift(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'addqualityshift', data);
  }
  updateQualityShiftData(data: any): Observable<any> {
    return this.http.put<any>(this.kpibaseUrl + 'qualityshift', data);
  }
  getOneQualityShift(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'onequalityshift', data);
  }
  
  deleteQualityShiftRepo(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'deletequalityshift', data);
  }

  getDefects(): Observable<Group[]> {
    return this.http.get<Group[]>(this.kpibaseUrl + 'defectsdata');
  }

  getDefectReports(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.kpibaseUrl + 'defectreports', data);
  }

  getProducts(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.kpibaseUrl + 'unitproduct', data);
  }

  getProductsData(data: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.kpibaseUrl + 'products', data);
  }

  getParameters(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'parametergroups', data);
  }

  machineParameters(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'machinedefects', data);
  }

  getMasterShifts(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.kpibaseUrl + 'masterqualityshift', data);
  }

  getShift(data: any): Observable<any> {
    return this.http.post<any>(this.kpibaseUrl + 'generalshift', data);
  }

  getQualityMasterShifts(data: any): Observable<any> {
    return this.http.post<any>(this.o3CoreApiUrl + 'QualityShift/masterqualityshift', data);
  }

}
