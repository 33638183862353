import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Pillar } from 'src/app/models/pillar';
import { ToastrService } from 'ngx-toastr';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AreaService } from 'src/app/services/area.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { PeopleMasterDataService } from 'src/app/services/people-master-data-service';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss'],
})
export class AreaComponent implements OnInit {
  @Input() IsWrite: boolean;
  @Input() categoryLength: number;

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayMAchineModal = false;
  pillars!: Pillar[];
  pillarId!: number;
  plants!: Plantbygroup[];
  areaForm!: FormGroup;
  formMode!: boolean;
  isRead!: boolean;
  groupId!: number;
  plantId!: number;
  displayUserModal = false;
  pillarModelVisible = false;
  isUpdateArea: boolean = false;
  plantsDDL!: any[];

  owners = new FormControl();
  managers = new FormControl();
  ownerList!: any[];
  areaColumns: string[] = ['ptDesc', 'auditName', 'userName', 'sectionManager', 'Actions'];
  areaData = new MatTableDataSource<any>([]);
  getareaMaster: any;
  generalArea: any;
  generalDepartment: any;
  filteredUsers: any;
  filtered: any[] = [];

  constructor(
    private fb: FormBuilder,
    private machineService: MachineMappingService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private areaService: AreaService,
    private peopleService: PeopleMasterDataService,
  ) {}

  ngOnInit(): void {
    this.formMode = true;
    this.areaForm = this.fb.group({
      areaname: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      ownername: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      sectionmanager: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      plant: ['', Validators.required],
      group: [''],
      areaId: [''],
      department: [''],
    });
  }

  getPaginator(): void {
    setTimeout(() => {
      this.areaData.paginator = this.paginator;
      this.areaData.sort = this.sort;
    }, 1000);
  }
  getPlantsByGroup(): void {
    const key = {
      GroupId: this.groupId,
    };
    this.machineService.getPlantsData(key).subscribe(
      (plants: any) => {
        this.plantsDDL = plants.DepartList;
        this.areaForm.get('plant')?.setValue(this.plantId);
      },
      (error) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  getAreaMaster(key: any): void {
    this.spinner.show();
    this.groupId = key.groupId;
    this.plantId = key.plantId;
    this.areaForm.get('group')?.setValue(key.groupId);
    this.areaForm.get('plant')?.setValue(key.plantId);
    this.areaService.getArea(key).subscribe(
      (data) => {
        this.areaData.data = data;
        this.getPaginator();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.areaData.filter = filterValue.trim().toLowerCase();

    if (this.areaData.paginator) {
      this.areaData.paginator.firstPage();
    }
  }
  ownerListChange(event: any): void {
    this.filtered.push(event.value[0]);
  }

  getUsers(): void {
    const key = {
      egId: this.groupId,
      plantId: this.plantId,
      departmentId: this.areaForm.value.department
        ? this.areaForm.value.department
        : 0,
    };
    this.peopleService.getEmployeePositionMappedMasterData(key).subscribe(
      (data) => {
        this.ownerList = data.employeePositionMappedList;
        this.filteredUsers = this.ownerList.slice();
        this.getPaginator();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.message);
        this.spinner.hide();
      }
    );
  }

  getMasterArea(): void {
    const key = {
      egId: this.groupId,
      plantId: this.plantId,
      departmentId: this.areaForm.value.department,
    };
    this.areaService.getMasterAreaByDepartment(key).subscribe(
      (response) => {
        this.generalArea = response;
        this.getUsers();
      },
      (error) => {
        if(error.status == 404)
          this.toaster.warning('Warning!','No Area Found');
        else
          this.toaster.error('Error', error.message);
        this.spinner.hide();
        this.generalArea = [];
      }
    );
  }

  //Commented the above getMasterDepartment() to fetch the department from plant modal in the below method
  getMasterDepartment(): void {
    const key = {
      ptId: this.plantId,
    };
      this.machineService.getDepartmenBytPlant(key).subscribe(
      (response: any) => {
        this.generalDepartment = response;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  opensideModal(): void {
    if (!this.isUpdateArea) {
      this.formMode = true;
      this.displayUserModal = true;
      this.reset();
      this.isUpdateArea = false;
      this.getPlantsByGroup();
      this.getMasterDepartment();
      this.areaForm
        .get('department')
        ?.setValue(this.generalDepartment[0].TypeId);
      this.getUsers();
      this.getMasterArea();
    } else {
      this.formMode = false;
      this.displayUserModal = true;
      this.getPlantsByGroup();
      this.getMasterDepartment();
      this.getUsers();
      this.getMasterArea();
    }
  }

  closeaddNewPlantItemModel(): void {
    this.displayUserModal = false;
    this.reset();
  }

  saveArea(): void {
    if (!this.isUpdateArea) {
      const data = {
        masterAreaId: this.areaForm.get('areaname')?.value,
        plantId: this.areaForm.get('plant')?.value,
        owner: this.areaForm.get('ownername')?.value,
        manager: this.areaForm.get('sectionmanager')?.value,
      };
      this.areaService.addArea(data).subscribe(
        (response: any) => {
          if(response.message == "Area is already defined"){
            this.toaster.warning('Warning', response.message)
          }
          else{
            this.toaster.success('Success', 'Area Added Successfully');
            this.reset();
            this.closeaddNewPlantItemModel();
            const key = {
              plantId: data.plantId,
              groupId: this.groupId,
            };
            this.getAreaMaster(key);
          }
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      const data = {
        masterAreaId: this.areaForm.get('areaname')?.value,
        plantId: this.areaForm.get('plant')?.value,
        owner: this.areaForm.get('ownername')?.value,
        manager: this.areaForm.get('sectionmanager')?.value,
        areaId: this.areaForm.get('areaId')?.value,
      };
      this.areaService.updateArea(data).subscribe(
        (response) => {
          this.toaster.success('Success', 'Area Updated Successfully');
          this.closeaddNewPlantItemModel();
          this.isUpdateArea = false;
          this.reset();
          const key = {
            groupId: this.groupId,
            plantId: data.plantId,
          };
          this.getAreaMaster(key);
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    }
  }

  onClickUpdateArea(element: any): void {
    this.getUsers();
    this.isUpdateArea = true;
    let owners =
      element.userId !== null
        ? element.userId.split(',').map(function (item: string) {
          return Number(item);
        })
        : [];
    const sectionManager =
      element.sectionManagerId !== null
        ? element.sectionManagerId.split(',').map((item: string) => {
          return Number(item);
        })
        : [];
    this.areaForm.get('areaname')?.setValue(element.masterAreaId);
    this.areaForm.get('ownername')?.setValue(owners);
    this.areaForm.get('sectionmanager')?.setValue(sectionManager);
    this.areaForm.get('plant')?.setValue(element.ptId);
    this.areaForm.get('areaId')?.setValue(element.areaId);
    this.areaForm.get('department')?.setValue(element.departmentId);
    this.opensideModal();
  }

  onClickDeleteArea(element: any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete Master Area!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        var id = {
          areaId: element.areaId,
        };
        this.areaService.deleteArea(id).subscribe(
          (data: any) => {
            const key = {
              groupId: this.groupId,
              plantId: this.plantId,
            };
            this.getAreaMaster(key);
            this.toaster.success(data.message);
          },
          (error: any) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Master Area is safe :)', 'error');
      }
    });
  }

  reset(): void {
    this.areaForm.reset();
    this.isUpdateArea = false;
  }
}
