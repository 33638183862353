import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Frequency } from 'src/app/models/frequency';
import { MasterFrequency } from 'src/app/models/MasterFrequency';
import { Plantbygroup } from 'src/app/models/plantbygroup';
import { UserManu } from 'src/app/models/userManu';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { TranslaterService } from 'src/app/services/translater.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';

@Component({
  selector: 'app-frequency',
  templateUrl: './frequency.component.html',
  styleUrls: ['./frequency.component.scss'],
})
export class FrequencyComponent implements OnInit {
  @Input() fPermissions!: UserManu;

  @ViewChild(MatTable) table!: MatTable<Frequency>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  frequencies!: Frequency[];
  MasterFrequency!: MasterFrequency[];
  frequencyId!: number;
  plants!: Plantbygroup[];
  frequencyForm!: FormGroup;
  formMode!: boolean;
  isRead!: boolean;
  isWrite!: boolean;
  isUpdate!: boolean;
  isDelete!: boolean;
  plantId!: number;
  filterArray!: any[];
  frequencyModelVisible = false;

  frequencyColumns: string[] = ['frequencyActions', 'plant', 'name'];
  frequencyData!: MatTableDataSource<Frequency>;
  IsAccess: any;
  IsWrite: any;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!:any;
  breadcrumList: any;
  constructor(
    private kpiService: KpimasterService,
    private toaster: ToastrService,
    private platModelService: EnterPriseGroupService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private translater: TranslaterService,
    private usermanagementService: UserManagementService,
  ) {
  }
  ngOnInit(): void {
    this.GetMenuAccess();
    //this.getLangWords();
    this.GetBreadcrumList();
    this.isRead = this.fPermissions.IsAccess;
    this.isWrite = this.fPermissions.IsWrite;
    this.isUpdate = this.fPermissions.IsWrite;
    this.isDelete = this.fPermissions.IsWrite;
    this.formMode = true;
    this.frequencyForm = this.fb.group({
      frequencyname: ['', [Validators.required, Validators.pattern('[^"]+$')]],
      plant: ['', Validators.required],
    });
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.usermanagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }
  

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.platModelService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getPaginator(): void {
    setTimeout(() => {
      this.frequencyData.paginator = this.paginator;
      this.frequencyData.sort = this.sort;
    }, 1000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.frequencyData.filter = filterValue.trim().toLowerCase();

    if (this.frequencyData.paginator) {
      this.frequencyData.paginator.firstPage();
    }
  }

  getPlants(): void {
    this.kpiService.getAllPlants().subscribe(
      (plants) => {
        this.plants = plants;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  masterFrequency(): any {
    this.kpiService.getMasterFrequency().subscribe(
      (data) => {
        const tableArray = this.frequencyData.data;
        this.MasterFrequency = data;
        const listArray = this.MasterFrequency;
        const missingArray = listArray.filter(
          (elem) =>
            !tableArray.find(
              ({ masterFrequencyId }) =>
                elem.masterFrequencyId === masterFrequencyId
            )
        );
        this.filterArray = missingArray;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  openModal(): void {
    if (this.isWrite) {
      this.resetForm();
      this.frequencyForm.get('plant')?.setValue(this.plantId);
      this.frequencyModelVisible = true;
      this.masterFrequency();
      this.formMode = true;
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  getMasterFrequencies(key: any): void {
    this.spinner.show();
    this.plantId = key.plantId;
    this.kpiService.getMasterFrequencies(key).subscribe(
      (frequency) => {
        this.frequencyData = new MatTableDataSource<Frequency>(frequency);
        this.getPaginator();
        this.getPlants();
        this.masterFrequency();
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
        this.spinner.hide();
      }
    );
  }

  getFrequencies(): void {
    this.spinner.show();
    const key = {
      plantId: this.plantId,
    };
    this.kpiService.getMasterFrequencies(key).subscribe(
      (frequency) => {
        this.spinner.hide();
        this.frequencyData = new MatTableDataSource<Frequency>(frequency);
        this.getPaginator();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  getOneFrequency(frequencyId: number): void {
    if (this.isUpdate) {
      const key = {
        frequencyId,
      };
      this.kpiService.getOneFrequency(key).subscribe(
        (freq) => {
          this.frequencyId = frequencyId;
          this.plants = [];
          this.getPlants();
          // this.masterFrequency();
          this.frequencyForm.get('plant')?.setValue(this.plantId);

          const tableArray = this.frequencyData.data;
          const listArray = this.MasterFrequency;
          // tslint:disable-next-line:max-line-length
          const missingArray = listArray.filter(
            (elem) =>
              !tableArray.find(({ masterFrequencyId }) => {
                return (
                  masterFrequencyId !== freq.masterFrequencyId &&
                  elem.masterFrequencyId === masterFrequencyId
                );
              })
          );

          this.filterArray = missingArray;
          this.frequencyForm
            .get('frequencyname')
            ?.setValue(freq.masterFrequencyId);
          this.formMode = false;
          this.frequencyModelVisible = true;
        },
        (error) => {
          this.toaster.error('Error', error.error.message);
        }
      );
    } else {
      this.toaster.error('Alert!', 'you do not have permission to access');
    }
  }

  submitForm(): void {
    this.spinner.show();
    const frequency = {
      plantId: this.frequencyForm.value.plant,
      masterFrequencyId: this.frequencyForm.value.frequencyname,
    };
    this.kpiService.addFrequency(frequency).subscribe(
      (response) => {
        this.spinner.hide();

        this.toaster.success('Success', 'Frequency Added Successfully');
        this.getFrequencies();
        this.closeFrequencyModel();
        this.resetForm();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.err.message);
      }
    );
  }

  updateForm(): void {
    this.spinner.show();

    const frequency = {
      frequencyId: this.frequencyId,
      plantId: this.frequencyForm.value.plant,
      masterFrequencyId: this.frequencyForm.value.frequencyname,
    };
    this.kpiService.updateFrequency(frequency).subscribe(
      (response) => {
        this.spinner.hide();

        this.toaster.success('Success', 'Frequency Updated Successfully');
        this.getFrequencies();
        this.closeFrequencyModel();
        this.resetForm();
      },
      (error) => {
        this.spinner.hide();

        this.toaster.error('Error', error.error.message);
      }
    );
  }

  // deleteFrequency(frequencyId: number): void {
  //   if (this.isDelete) {
  //     Swal.fire({
  //       title: 'Are you sure?',
  //       text: 'You want to delete frequency!',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonText: 'Yes, delete it!',
  //       cancelButtonText: 'No, keep it',
  //     }).then((result) => {
  //       if (result.value) {
  //         this.spinner.show();

  //         const key = {
  //           frequencyId,
  //         };
  //         this.kpiService.deletfrequency(key).subscribe(
  //           (success) => {
  //             Swal.fire({
  //               title: 'Deleted!',
  //               text: 'Your selected Frequency has been deleted.',
  //               icon: 'success',
  //               timer: 800,
  //               showConfirmButton: false,
  //             });
  //             this.spinner.hide();

  //             this.getFrequencies();
  //           },
  //           (error) => {
  //             this.spinner.hide();

  //             this.toaster.error('Error', error.error.message);
  //           }
  //         );
  //       } else if (result.dismiss === Swal.DismissReason.cancel) {
  //         Swal.fire('Cancelled', 'Your frequency is safe :)', 'error');
  //       }
  //     });
  //   } else {
  //     this.toaster.error('Alert!', 'you do not have permission to access');
  //   }
  // }
  resetForm(): void {
    this.frequencyForm.reset();
  }

  closeFrequencyModel(): void {
    this.frequencyModelVisible = false;
  }
}
