import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { KpimasterService } from 'src/app/services/kpimaster.service';
import { ToastrService } from 'ngx-toastr';
import { Plant } from 'src/app/models/plant';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { PlantGroupElement } from 'src/app/models/O3AdministrationModels/plant-model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { EnterPriseGroupService } from 'src/app/services/enterpriseGroup.service';
import { CommonService } from 'src/app/services/common.service';
import { CustomerManagementService } from 'src/app/services/customer-management.service';
import { QualityMasterProcessService } from 'src/app/services/quality-master-process.service';
import { AreaService } from 'src/app/services/area.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { element } from 'protractor';
import { QualityAreaService } from 'src/app/services/quality-area.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DtServiceService } from 'src/app/services/dtService.service';
import { OrderProcessingService } from 'src/app/services/orderprocessing.service';

@Component({
  selector: 'app-quality-master-process',
  templateUrl: './quality-master-process.component.html',
  styleUrls: ['./quality-master-process.component.scss'],
})
export class QualityMasterProcessComponent implements OnInit {
  @Input() plantId!: number;
  QualityprocessForm!: FormGroup;
  QualityprocessColumns: string[] = [
    'plant',
    'machine',
    'product',
    'area',
    'qualityProcessActions',
  ];
  displayprocessModal = false;
  formMode!: boolean;
  plantsDDL!: any[];
  UnitName!: string;
  varUnitName!: string;
  isUnitName = false;
  areaId!: number;
  UnitNode: any;
  singleareaId!: any;
  isPlantGroupModelVisibleInVariable = false;
  isPlantGroupModelVisibleInVariableSpec = false;
  isPlantGroupModelVisible = false;
  LineNode: any;
  isaddNewVariableGroupModelVisible = false;
  treeControl = new NestedTreeControl<PlantGroupElement>(
    (node) => node.children
  );
  plantGroupSource = new MatTreeNestedDataSource<PlantGroupElement>();
  areaDataSource!: MatTableDataSource<any>;
  // productList!:any;
  filterProducts!: any;
  updateOrderprocessingData!: boolean;
  isDelete: any;
  productList!: any[];
  GroupId: any;
  IsAccess: any;
  IsWrite: any;
  treeType = 1;

  // tslint:disable-next-line: max-line-length
  constructor(
    private kpiService: KpimasterService,
    private toastrService: ToastrService,
    private qualityarea: QualityAreaService,
    private customer: CustomerManagementService,
    private enterpriseGroupService: EnterPriseGroupService,
    private qualitymaster: QualityMasterProcessService,
    private commonService: CommonService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private dtService: DtServiceService,
    private porductService: OrderProcessingService,
    private spinner: NgxSpinnerService,

    private machineService: MachineMappingService
  ) {}
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  ngOnInit(): void {
    this.QualityprocessForm = this.fb.group({
      plant: ['', Validators.required],
      unit: [''],
      products: [''],
      area: ['', Validators.required],
    });

    this.getPlants();
    this.GetMenuAccess();
  }

  GetMenuAccess(): void {
    const key = {
      Id: localStorage.getItem('O3RightMenuId'),
    };
    this.enterpriseGroupService.GetMenuAccess(key).subscribe(
      (data: any) => {
        this.IsAccess = data.IsAccess;
        this.IsWrite = data.IsWrite;
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  OnChangePlant(data: any): void {
    this.QualityprocessForm.get('plant')?.setValue(data.value);
  }

  getProducts(): void {
    debugger;
    const key = {
      unitId: this.QualityprocessForm.value.unit,
    };
    this.qualityarea.getProductsByUnits(key).subscribe(
      (response: any) => {
        this.productList = response;
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.areaDataSource.filter = filterValue.trim().toLowerCase();

    if (this.areaDataSource.paginator) {
      this.areaDataSource.paginator.firstPage();
    }
  }
  getArea(plant: any, egid: any): void {
    this.plantId = plant;
    this.GroupId = egid;
    const key = {
      ptId: plant,
    };
    this.spinner.show();
    this.qualityarea.getQualityMasterArea(key).subscribe((res: any) => {
      debugger;
      this.areaDataSource = new MatTableDataSource<any>(res);
      this.getPaginator(this.areaDataSource, 0);
      this.spinner.hide();
    });
  }
  splitTree(node: any): any {
    if (node !== undefined) {
      return node.split('-')[0];
    }
  }
  getPaginator(data: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      data.paginator = this.paginator.toArray()[index];
    }, 1000);
  }

  deleteMasterArea(element: any): void {
    // if (this.isDelete) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete area!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        debugger;
        const key = {
          qualityAreaId: element.AreaId,
        };
        this.qualityarea.deleteMasterArea(key).subscribe(
          (success) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your selected area has been deleted.',
              icon: 'success',
              timer: 800,
              showConfirmButton: false,
            });
            this.getArea(this.plantId, this.GroupId);
          },
          (error) => {
            this.toaster.error('Error', error.error.message);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your selected area is safe :)', 'error');
      }
    });
  }

  openaddNewVariableGroupModel(): void {
    this.isaddNewVariableGroupModelVisible = true;
    this.UnitName = '';
  }
  closeaddNewVariableGroupModel(): void {
    this.isaddNewVariableGroupModelVisible = false;
  }

  getPlants(): void {
    const key = {
      GroupId: this.GroupId,
    };

    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plantsDDL = data.DepartList.filter(
          (c: any) => c.DeptId === this.plantId
        );
        this.QualityprocessForm.get('plant')?.setValue(
          this.plantsDDL[0].DeptId
        );
        // this.getProducts();
        // this.getArea(this.plantId);
      },
      (error: any) => {
        this.toastrService.error('Error', error.message);
      }
    );
  }
  submitForm(): void {
    const area = {
      plantId: this.QualityprocessForm.value.plant,
      name: this.QualityprocessForm.value.area,
      puId: this.QualityprocessForm.value.unit,
      products:
        this.QualityprocessForm.value.products === ''
          ? []
          : this.QualityprocessForm.value.products,
    };
    this.qualityarea.addArea(area).subscribe(
      (response) => {
        this.toaster.success('Success', 'Area Added Successfully');
        this.closePillarModel();
        this.getArea(this.plantId, this.GroupId);
      },
      (error) => {
        this.toaster.error('Error', error.error.message);
      }
    );
    this.QualityprocessForm.reset();
  }

  getAreabyId(element: any): void {
    this.getPlants();
    this.singleareaId = element.AreaId;
    const key = {
      qualityAreaId: element.AreaId,
    };
    debugger;
    this.qualityarea.getQualityMasterAreaById(key).subscribe(
      (data: any) => {
        this.QualityprocessForm.get('plant')?.setValue(element.ptId);
        this.QualityprocessForm.get('area')?.setValue(data.name);
        this.QualityprocessForm.get('unit')?.setValue(data.puId);
        // this.QualityprocessForm.get('products')?.setValue(data.products.prodDesc);
        var p = data.products.map((c: any) => c.prodId);
        this.UnitName = data.puDesc;
        //let products:number[]=[element.prodId];
        this.getProducts();
        this.QualityprocessForm.get('products')?.setValue(p);
        this.formMode = false;
        this.displayprocessModal = true;
        //  this.CustomerDataForm.get('elementId')?.setValue(element);
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }
  updateForm(): void {
    const area = {
      qualityAreaId: this.singleareaId,
      plantId: this.QualityprocessForm.value.plant,
      name: this.QualityprocessForm.value.area,
      puId: this.QualityprocessForm.value.unit,
      products: this.QualityprocessForm.value.products,
    };

    this.qualityarea.updateQualityArea(area).subscribe(
      (response: any) => {
        this.toaster.success('Success', 'Area Updated Successfully');
        this.closePillarModel();
        this.getArea(this.plantId, this.GroupId);
      },
      (error: any) => {
        this.toaster.error('Error', error.error.message);
      }
    );
  }

  resetqualityForm(): void {
    this.QualityprocessForm.get('unit')?.setValue('');
    this.QualityprocessForm.get('products')?.setValue('');
    this.QualityprocessForm.get('area')?.setValue('');
    this.UnitName = '';
  }
  closePillarModel(): void {
    this.displayprocessModal = false;
  }
  openTreeModal(): void {
    this.updateOrderprocessingData = true;

    const key = {
      PlantId: this.plantId,
      ParentId: this.GroupId,
      // level: 'Unit',
      // CreatedBy: 0,
      // IsOptional: 0
    };

    this.dtService.getPlantModelTree(key).subscribe(
      (data: any) => {
        // tslint:disable-next-line:no-shadowed-variable

        const plantGroupData: PlantGroupElement[] = data.Tree;
        this.plantGroupSource.data = plantGroupData;
        this.treeControl.dataNodes = this.plantGroupSource.data;
        this.isPlantGroupModelVisible = true;
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }

  opensideModal(): void {
    this.formMode = true;
    this.resetqualityForm();
    this.displayprocessModal = true;
    this.getPlants();
    // this.QualityprocessForm.get('plant')?.setValue(this.plants.DeptId);
  }
  closeaddNewPlantItemModel(): void {
    this.displayprocessModal = false;
  }

  hasChild = (_: number, node: PlantGroupElement) =>
    !!node.children && node.children.length > 0;

  // open model
  openPlantGroupModel(): void {
    this.isPlantGroupModelVisible = true;
    // this.plantGroupSource.data = plantGroupData;
    this.treeControl.dataNodes = this.plantGroupSource.data;
    this.expandAllNode();
  }

  // close model
  closePlantGroupModel(): void {
    this.isPlantGroupModelVisible = false;
  }
  expandAllNode(): void {
    this.treeControl.expandAll();
  }
  collapseAllNode(): void {
    this.treeControl.collapseAll();
  }
  openUnit() {
    this.isPlantGroupModelVisible = true;

    const key = {
      PlantId: this.QualityprocessForm.value.plant,
      ParentId: localStorage.getItem('egId'),
      level: 'Unit',
      CreatedBy: 0,
      IsOptional: 0,
    };
    this.qualitymaster.gettreedata(key).subscribe((data: any) => {
      this.plantGroupSource.data = data.TreeModalList;
      this.treeControl.dataNodes = this.plantGroupSource.data;
      this.UnitNode = filterData(this.plantGroupSource.data, 'Unit');
    });

    function filterData(data: PlantGroupElement[], title: string) {
      var r = data.filter(function (o: any) {
        Object.keys(o).forEach(function (e: any) {
          if (Array.isArray(o[e])) o[e] = filterData(o[e], title);
        });
        return o.title != title;
      });
      return r;
    }
    // this.treeControl.expandAll();
    // this.treeControl.collapseDescendants(this.UnitNode);
  }
  replaceIcon(value: any): any {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  SelectUnitName(unitname: any): any {
    if (unitname.Level === 'Unit') {
      this.UnitName = unitname.text;
      this.isPlantGroupModelVisible = false;
    }
  }

  onNodeSelect(node: any): void {
    this.updateOrderprocessingData = false;
    this.UnitName = node.text;
    this.isPlantGroupModelVisible = false;
    this.QualityprocessForm.get('unit')?.setValue(node.id);
    this.getProducts();
    // this.MachineId = node.id;
    // this.dtreasonForm.get('unit')?.setValue(node.text);
    // this.getTimedEventFault(this.MachineId , 'Unit');
    // this.QualityprocessForm.get('dttree')?.setValue(this.dtTreeList[1].Id);
  }

  onNodeSelectReson(node: any): void {
    this.updateOrderprocessingData = false;

    this.QualityprocessForm.get('unit')?.setValue(node.id);
    // this.MachineResonId = node.id;
    this.UnitName = node.text;
    // this.LoadWasteEventFaultTable(this.MachineResonId, 'Unit');
  }

  onClickUnitInVariable(data: any): any {
    this.QualityprocessForm.get('unit')?.setValue(data.PrimaryId);
    this.UnitName = data.text;

    this.isPlantGroupModelVisible = false;
  }

  SelectVarUnitName(unitname: any): any {
    if (unitname.title === 'UNT') {
      this.varUnitName = unitname.name;
      this.isPlantGroupModelVisible = false;
    }
  }
}
