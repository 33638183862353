import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CostCenterService } from 'src/app/services/cost-center.service';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { CostCenterComponent } from '../cost-center/cost-center.component';
import { CostelementComponent } from '../costelement/costelement.component';
import { TdcelementComponent } from '../tdcelement/tdcelement.component';
import { OleService } from 'src/app/services/ole.service';
import { TranslaterService } from 'src/app/services/translater.service';
@Component({
  selector: 'app-Cost-MasterData',
  templateUrl: './Cost-MasterData.component.html',
  styleUrls: ['./Cost-MasterData.component.scss'],
})
export class CostMasterDataComponent implements OnInit {
  @ViewChild(CostelementComponent) CostelementComponent!: CostelementComponent;
  @ViewChild(CostCenterComponent) CostCenterComponent!: CostCenterComponent;
  @ViewChild(TdcelementComponent) TdcComponent!: TdcelementComponent;
  @ViewChild('ImportDialog') ImportDialog!: TemplateRef<any>;
  @ViewChild('duplicateDialog') duplicateDialog!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('duplicatePaginator') paginator!: MatPaginator;
  @ViewChild('addedPaginator') paginator2!: MatPaginator;
  searchCostMasterForm!: FormGroup;
  plants!: any;
  filteredPlants!: any;
  groupListDDL!: any[];
  filteredGroupListDDL!: any[]
  breadcrumList: any;
  dialogRef: any;
  plantId: any;
  costElementData: any;
  costCenterData: any;
  addedDataSource!: MatTableDataSource<any>;
  addedDataColumns: any[] = [
    'costCenterName',
    'costElementName',
    'sheetName',
    'message',
    'sheetNo',
  ];
  duplicateDataSource!: MatTableDataSource<any>;
  duplicateDataColumns: any[] = [
    '_costCenterName',
    '_costElementName',
    '_sheetName',
    '_message',
    '_sheetNo',
  ];
  moduleId: any;
  pageId: any;
  langObj: any;
  languageDir = 'ltr'
  constructor(
    private fb: FormBuilder,
    private costService: CostCenterService,
    private toaster: ToastrService,
    private userManagementService: UserManagementService,
    public router: Router,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private oleService: OleService,
    private translater: TranslaterService,
  ) {
  }

  ngOnInit(): void {
    this.searchCostMasterForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
    });
    this.getGroupData();
    this.GetBreadcrumList();
  }

  changedHandler(valueAdded: boolean) {
    if(valueAdded){
      const plant = this.searchCostMasterForm.get('plant')?.value;
    const group = this.searchCostMasterForm.get('group')?.value;
    const key = {
      plantId: plant,
      groupId: group,
    };
    this.CostCenterComponent.GetCostCenter(key);
    }
  }

  ImportToExcel() {
    this.dialogRef = this.dialog.open(this.ImportDialog, { width: '500px' });
    this.dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  openFile() {
    this.fileInput.nativeElement.click()
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  uploadExcelFile(e: any){
    this.plantId = this.searchCostMasterForm.get('plant')?.value;
    this.spinner.show();
    this.dialog.closeAll();
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("plantId", this.plantId.toString());
    this.costService.uploadExcelFile(formData).subscribe((res: any) => {
      this.spinner.hide();
      if(res.isDuplcate == true){
        this.toaster.warning("Duplicate Data cannot be uploaded.");
        this.duplicateDataSource = new MatTableDataSource<any>(res.result);
        setTimeout(() => {this.duplicateDataSource.paginator = this.paginator},1000);
        this.addedDataSource = new MatTableDataSource<any>(res.added);
        setTimeout(() => {this.addedDataSource.paginator = this.paginator2},1000);
        this.dialogRef = this.dialog.open(this.duplicateDialog, { width: '1000px' });
      }else{
        this.toaster.success('success', 'File Uploaded Successfully...');
        this.dialog.closeAll();
      }
      this.getMasterData();
    });
  }

  getGroupData(): void {
    // debugger;
    const key = {
      Id: 0,
    };
    // tslint:disable-next-line: no-debugger
    this.userManagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.filteredGroupListDDL = this.groupListDDL.slice();
        this.searchCostMasterForm
          .get('group')
          ?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.searchCostMasterForm.get('group')?.value,
    };
    // this.MachineId = null;
    this.userManagementService.getDepartments(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList.filter((p: any) => p.DeptDesc);
        this.filteredPlants = this.plants.slice();
        this.searchCostMasterForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.getMasterData();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  getMasterData(): void {
    const plant = this.searchCostMasterForm.get('plant')?.value;
    const group = this.searchCostMasterForm.get('group')?.value;
    const key = {
      plantId: plant,
      groupId: group,
    };
    this.CostelementComponent.getCostMaster(key);
    this.CostCenterComponent.GetCostCenter(key);
    this.CostelementComponent.getCenterGroupList(key);
    // setTimeout(() => {
      
    //  }, 500);
    this.TdcComponent.GetAllTDCElements(key);
  }

  getCostElement(): void{
    const plant = this.searchCostMasterForm.get('plant')?.value;
    const group = this.searchCostMasterForm.get('group')?.value;
    const key = {
      plantId: plant,
      groupId: group,
    };
    this.CostelementComponent.getCostMaster(key);
  }

  exportToExcel(): void {
    this.costElementData = this.CostelementComponent.costElementData;
    this.costCenterData = this.CostCenterComponent.costCeneterData;
    let costCenterArray = [];
    let costElementArray = [];
    for(let c of this.costCenterData){
      const obj = {
        CostCenterParent: c.parentName,
        CostCenterName: c.costCenterName,
        GLAccount: c.glAccount
      };
      costCenterArray.push(obj);
    }
    for(let e of this.costElementData){
      const obj = {
        CostElementName: e.name,
        CostCeneter: e.costCenterName
      };
      costElementArray.push(obj);
    }
    this.oleService.exportAsExcelFileWithMultipleSheet(costCenterArray,costElementArray, 'Master Data');
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }
  
  GetBreadcrumList(): any {
    const obj = {
      MenuId:
        localStorage.getItem('O3RightMenuId') === null
          ? 1
          : localStorage.getItem('O3RightMenuId'),
    };
    // tslint:disable-next-line: align
    this.userManagementService.GetBreadcrumList(obj).subscribe(
      (data: any) => {
        this.breadcrumList = data.BreadcrumList;
        this.moduleId = this.breadcrumList[0].id;
        this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
        this.getLangWords();
      },
      (error) => {
        this.toaster.error('Error', error.ErrorMessage);
      }
    );
  }
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
    // return false;
  }
}
