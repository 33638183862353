import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TreeModule } from 'primeng/tree';
import { DropdownModule } from 'primeng/dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from 'src/app/material-module';
import { ChartsModule } from 'ng2-charts';
import { HighchartsChartModule } from 'highcharts-angular';
import { AgGridModule } from 'ag-grid-angular';
import { TooltipModule } from 'primeng/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
//Ngx Imports
import { IncidentReportingComponent } from './incident-reporting/incident-reporting.component';
import { BOSReportingComponent } from './bos-reporting/bos-reporting.component';
import { SafetyHeaderComponent } from './safety-header/safety-header.component';
import { SafetyDialogComponent } from './safety-dialog/safety-dialog.component';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { UnitPickerComponent } from '../components/unit-picker/unit-picker.component';
import { SafetyRoutingModule } from './safety-routing.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SafetyTrendsComponent } from './safety-trends/safety-trends.component';
import { ChartModule } from 'angular-highcharts';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  key => antDesignIcons[key]
);

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    MaterialModule,
    SafetyRoutingModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatCardModule,
    AgGridModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    TreeModule,
    DropdownModule,
    NgxSpinnerModule,
    TooltipModule,
    MatTooltipModule,
    HighchartsChartModule,
    NzDatePickerModule,
    MatCheckboxModule,
    NgxDropzoneModule,
    ChartModule
  ],
  declarations: [
    IncidentReportingComponent,
    BOSReportingComponent,
    SafetyHeaderComponent,
    SafetyDialogComponent,
    UnitPickerComponent,
    SafetyTrendsComponent
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons }, // add as factory to your providers
  ],
  exports: [UnitPickerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SafetyModule { }
