import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservedValueOf } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SfdcConfigurationService {
    baseUrl: string = environment.O3CoreApiUrl;
    o3CoreApiUrl: string = environment.O3CoreApiUrl;
    constructor(private http: HttpClient) { }

    //Kpi Parameter
    SubmitKpiParameter(data: any): Observable<any[]> {
        return this.http.post<any[]>(this.o3CoreApiUrl + 'SFDCConfiguration/SubmitKpiParameter', data);
    }

    GetKpiParam(data: any): Observable<any[]> {
        return this.http.post<any[]>(this.o3CoreApiUrl + 'SFDCConfiguration/GetKpiParamTbl', data);
    }

    UpdateKpiParameter(data: any): Observable<any[]> {
        return this.http.post<any[]>(this.o3CoreApiUrl + 'SFDCConfiguration/UpdateKpiParameter', data);
    }
    DeleteKpiParam(id: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + `SFDCConfiguration/DeleteKpiParam?id=${id}`, {});
    }

    //LotCode
    GETLotcodeMachineMapping(id: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/GETLotcodeMachineMappingTbl', id);
    }
    SubmitLotcodeMachineMapping(data: any): Observable<any> {

        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/SubmitLotcodeMachineMapping', data);
    }
    updateLotcodeMachineMapping(data: any): Observable<any> {

        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/UpdateLotcodeMachineMapping', data);
    }
    DeleteLotCode(id: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + `SFDCConfiguration/DeleteLotCode?id=${id}`, {});
    }

    //Product Output


    GETBatchCheckListType(id: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/GETBatchCheckListTypeTbl', id);
    }

    SubmitBatchCheckListType(data: any): Observable<any> {

        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/SubmitBatchCheckListType', data);
    }
    updateBatchCheckListType(data: any): Observable<any> {

        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/UpdateBatchCheckListType', data);
    }
    DeleteBatchCheckListType(id: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + `SFDCConfiguration/DeleteBatchCheckListType?id=${id}`, {});
    }
    //GetTapeNameDDL
    GetTapeNameDDL(): Observable<any> {
        return this.http.get<any>(this.o3CoreApiUrl + 'SFDCConfiguration/GetTapeNameDDL');
    }
    //Waste
    //GET //Id: 37
    //or
    //PU_ID: 2
    //TapName:
    //TapName: NULL GETHtmlLableTbl
    GETHtmlLable(id: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/GETHtmlLableTbl', id);
    }
    //SubmitHtmlLable
    //     PU_ID: 2
    // Name: asd
    // Result: asd
    // UoM: aasd
    // TN_ID: 1
    // UserId: 100001
    // BatchListId:
    SubmitHtmlLable(data: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/SubmitHtmlLable', data);
    }
    //UpdateHtmlLable
    // PU_ID: 2
    // Name: asd
    // Result: asd
    // UoM: aasd
    // TN_ID: 1
    // UserId: 100001
    // BatchListId:
    // Id: 172
    UpdateHtmlLable(data: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/UpdateHtmlLable', data);
    }
    DeleteHtmlLable(id: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + `SFDCConfiguration/DeleteHtmlLable?id=${id}`, {});
    }


    //map short stop fault
    GetMapFault(data: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SDFC/GetFaultByUnit', data);
    }

    SaveMapFault(data: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/SubmitFaultMapping', data);
    }

    getMapFaultList(id: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/GETShortStopsFaultsTbl', id);
    }

    DeleteMapFault(id: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + `SFDCConfiguration/DeleteSSFault?id=${id}`, {});
    }


    //map short stop fault

    SaveMapShortDuration(data: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/SubmitSSDMapping', data);
    }

    getMapShortDurationList(id: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/GETShortStopsDurationTbl', id);
    }

    DeleteMapDuration(id: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + `SFDCConfiguration/DeleteSSDuration?id=${id}`, {});
    }

    SubmitBatchdropdownListType(data: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SFDCConfiguration/SubmitBatchdropdownListType', data);
    }

    GetDropDownBatchCheckList(data: any): Observable<any> {
        return this.http.post<any>(this.o3CoreApiUrl + 'SDFC/GetDropDownBatchCheckList', data);
    }

}
