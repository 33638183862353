<ngx-spinner
  bdColor="rgba(0, 0, 0, 0)"
  template=" <img src='assets/images/spinner.gif' />"
>
  <!-- <p style="color: white;">{{ spinnerText }}</p> -->
</ngx-spinner>
<div class="cause-effect-model-content">
  <div style="overflow-y: scroll; border-radius: 5px; height: 600px">
    <table>
      <thead>
        <tr>
          <th *ngFor="let column of columns" style="color: #0f0fd1">
            {{ column }}
          </th>
          <!-- <th style="text-align: center;"> -->
          <!-- <p style="margin: 0;color: #0f0fd1;">Action Plan</p>
            <button style="color: #0f0fd1;" mat-icon-button class="action-plan" (click)="addActionPlan(1)" *ngIf = "ReadyFor5YAP">
              <mat-icon class="menu">add_circle </mat-icon>
            </button> -->
          <!-- </th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let yData of fiveWhy; let i = index">
          <td
            *ngIf="
              (this.rcaId && !yData.spanRow) ||
              (this.levelId != 0 &&
                this.levelId != 4 &&
                this.levelId != 5 &&
                !yData.spanRow)
            "
            [attr.rowspan]="yData.rowSpan ? yData.rowSpan : 1"
            [ngClass]="{
              'bg-salmon': yData.MType == 1,
              'bg-cadetblue': yData.MType == 2,
              'bg-cornflowerblue': yData.MType == 3,
              'bg-slategray': yData.MType == 4,
              'bg-burlywood': yData.MType == 5,
              'bg-coral': yData.MType == 6
            }"
            style="color: white; padding: 0 80px 0 10px"
          >
            {{
              yData && yData.displayMType
                ? yData.displayMType
                : yData.MType == 1
                ? "Machine"
                : yData.MType == 2
                ? "Material"
                : yData.MType == 3
                ? "Man"
                : yData.MType == 4
                ? "Method"
                : yData.MType == 5
                ? "Mother Nature"
                : "Management"
            }}
          </td>
          <td
            *ngIf="
              (this.rcaId && !yData.spanRow) ||
              (this.levelId != 0 &&
                this.levelId != 4 &&
                this.levelId != 5 &&
                !yData.spanRow)
            "
            [attr.rowspan]="yData.rowSpan ? yData.rowSpan : 1"
          >
            {{ yData.Priority }}
          </td>
          <td
            *ngIf="!yData.spanRow"
            [attr.rowspan]="yData.rowSpan ? yData.rowSpan : 1"
            [ngClass]="
              (this.rcaId > 0 && this.rcaId) ||
              (this.levelId != 0 && this.levelId != 4 && this.levelId != 5)
                ? 'causeField1'
                : 'causeField2'
            "
          >
            <mat-form-field
              class="w-250px"
              *ngIf="
                !yData.ValidationEditFalse &&
                !yData.sub &&
                (!yData.ID || !yData.causeNotEmpty)
              "
            >
              <textarea
                matInput
                type="text"
                [(ngModel)]="yData.ValidationFromCauseEffects"
                [disabled]="yData.disabled || yData.ActionDisabled"
              ></textarea>
            </mat-form-field>
            <div title="{{ yData.ValidationFromCauseEffects }}">
              <span
                *ngIf="
                  yData.ValidationEditFalse ||
                  yData.sub ||
                  (yData.ID && yData.causeNotEmpty)
                "
                >{{
                  yData.ValidationFromCauseEffects
                    ? getShortData(yData.ValidationFromCauseEffects)
                    : ""
                }}</span
              >
            </div>
          </td>
          <td
            *ngIf="
              (this.rcaId && !yData.spanRow) ||
              (this.levelId != 0 &&
                this.levelId != 4 &&
                this.levelId != 5 &&
                !yData.spanRow)
            "
            [attr.rowspan]="yData.rowSpan ? yData.rowSpan : 1"
          >
            <span *ngIf="!yData.sub">{{ yData.Weight }}</span>
            <span *ngIf="yData.sub">{{ yData.Weight }}</span>
          </td>
          <td>
            <div
              class="d-flex w-350px"
              *ngIf="!yData.parentCheck || yData.parentCheck <= 1"
              title="{{ yData.Why1 }}"
            >
              <mat-form-field class="w-300px">
                <textarea
                  (input)="yData.check1 = yData.Why1 ? true : false"
                  matInput
                  type="text"
                  value="{{ yData.Why1 }}"
                  (keyup)="valChange(i, yData.Why1, 1)"
                  [(ngModel)]="yData.Why1"
                  name="Why1_{{ i }}"
                  [disabled]="yData.disabled || yData.ActionDisabled"
                ></textarea>
              </mat-form-field>
              <mat-slide-toggle
                (change)="whyCheck($event, i, 1)"
                [(ngModel)]="yData.check1"
                name="check1_{{ i }}"
                [disabled]="yData.disabled || yData.ActionDisabled"
              >
              </mat-slide-toggle>
            </div>
          </td>
          <td>
            <div
              class="d-flex w-350px"
              *ngIf="!yData.parentCheck || yData.parentCheck <= 2"
              title="{{ yData.Why2 }}"
            >
              <mat-form-field class="w-300px">
                <textarea
                  (input)="yData.check2 = yData.Why2 ? true : false"
                  matInput
                  type="text"
                  value="{{ yData.Why2 }}"
                  (keyup)="valChange(i, yData.Why2, 2)"
                  [(ngModel)]="yData.Why2"
                  name="Why2_{{ i }}"
                  [disabled]="yData.disabled || yData.ActionDisabled"
                ></textarea>
              </mat-form-field>
              <mat-slide-toggle
                (change)="whyCheck($event, i, 2)"
                [(ngModel)]="yData.check2"
                name="check2_{{ i }}"
                [disabled]="yData.disabled || yData.ActionDisabled"
              >
              </mat-slide-toggle>
            </div>
          </td>
          <td>
            <div
              class="d-flex w-350px"
              *ngIf="!yData.parentCheck || yData.parentCheck <= 3"
              title="{{ yData.Why3 }}"
            >
              <mat-form-field class="w-300px">
                <textarea
                  (input)="yData.check3 = yData.Why3 ? true : false"
                  matInput
                  type="text"
                  value="{{ yData.Why3 }}"
                  (keyup)="valChange(i, yData.Why3, 3)"
                  [(ngModel)]="yData.Why3"
                  name="Why3_{{ i }}"
                  [disabled]="yData.disabled || yData.ActionDisabled"
                ></textarea>
              </mat-form-field>
              <mat-slide-toggle
                (change)="whyCheck($event, i, 3)"
                [(ngModel)]="yData.check3"
                name="check3_{{ i }}"
                [disabled]="yData.disabled || yData.ActionDisabled"
              >
              </mat-slide-toggle>
            </div>
          </td>
          <td>
            <div
              class="d-flex w-350px"
              *ngIf="!yData.parentCheck || yData.parentCheck <= 4"
              title="{{ yData.Why4 }}"
            >
              <mat-form-field class="w-300px">
                <textarea
                  (input)="yData.check4 = yData.Why4 ? true : false"
                  matInput
                  type="text"
                  value="{{ yData.Why4 }}"
                  (keyup)="valChange(i, yData.Why4, 4)"
                  [(ngModel)]="yData.Why4"
                  name="Why4_{{ i }}"
                  [disabled]="yData.disabled || yData.ActionDisabled"
                ></textarea>
              </mat-form-field>
              <mat-slide-toggle
                (change)="whyCheck($event, i, 4)"
                [(ngModel)]="yData.check4"
                name="check4_{{ i }}"
                [disabled]="yData.disabled || yData.ActionDisabled"
              >
              </mat-slide-toggle>
            </div>
          </td>
          <td>
            <div
              class="d-flex w-350px"
              *ngIf="!yData.parentCheck || yData.parentCheck <= 5"
              title="{{ yData.Why5 }}"
            >
              <mat-form-field class="w-300px">
                <textarea
                  (input)="yData.check5 = yData.Why5 ? true : false"
                  matInput
                  type="text"
                  value="{{ yData.Why5 }}"
                  (keyup)="valChange(i, yData.Why5, 5)"
                  [(ngModel)]="yData.Why5"
                  name="Why5_{{ i }}"
                  [disabled]="yData.disabled || yData.ActionDisabled"
                ></textarea>
              </mat-form-field>
              <mat-slide-toggle
                (change)="whyCheck($event, i, 5)"
                [(ngModel)]="yData.check5"
                name="check5_{{ i }}"
                [disabled]="yData.disabled || yData.ActionDisabled"
              >
              </mat-slide-toggle>
            </div>
          </td>
          <td>
            <div title="{{ yData.RootCause }}">
              <mat-form-field class="w-300px">
                <textarea
                  matInput
                  type="text"
                  value="{{ yData.RootCause }}"
                  [(ngModel)]="yData.RootCause"
                  name="RootCause_{{ i }}"
                  [disabled]="yData.ActionDisabled"
                ></textarea>
              </mat-form-field>
            </div>
          </td>
          <td>
            <div>
              <mat-form-field class="w-130px m-select">
                <mat-select
                  [(ngModel)]="yData.TypeOfM"
                  [disabled]="!yData.RootCause || yData.ActionDisabled"
                >
                  <mat-option value="Machine">Machine</mat-option>
                  <mat-option value="Material">Material</mat-option>
                  <mat-option value="Man">Man</mat-option>
                  <mat-option value="Method">Method</mat-option>
                  <mat-option value="MotherNature">MotherNature</mat-option>
                  <mat-option value="Management">Management</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </td>
          <td>
            <div class="row-action-btns" style="min-width: 85px">
              <button
                mat-icon-button
                class="button"
                [disabled]="!IsWrite"
                (click)="addRow(i, 'sub')"
                tooltipPosition="top"
                matTooltip="Sub Row"
              >
                <mat-icon class="menu subrow">add_circle</mat-icon>
                <mat-icon class="menu subrow subwidth">add_circle</mat-icon>
              </button>
              <button
                mat-icon-button
                class="btn-main"
                [disabled]="!yData.MainRowShow || !IsWrite"
                (click)="addRow(i, 'main')"
                *ngIf="
                  !rcaId &&
                  (levelId == 0 || levelId == 4 || levelId == 5) &&
                  !yData.sub
                "
                tooltipPosition="top"
                matTooltip="New Row"
              >
                <mat-icon class="menu mainrow">add_circle </mat-icon>
              </button>
              <button
                mat-icon-button
                class="del-button"
                [disabled]="!IsWrite || yData.ActionDisabled"
                (click)="deleteRow(i)"
                *ngIf="yData.delete"
                tooltipPosition="top"
                matTooltip="Delete Row"
              >
                <mat-icon class="menu">delete</mat-icon>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
