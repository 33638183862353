import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import Accessibility from 'highcharts/modules/accessibility';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MeetingMatrixService } from 'src/app/services/meeting-matrix.service';
import { MachineMappingService } from 'src/app/services/machinemapping.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Chart } from 'angular-highcharts';
import * as  Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
More(Highcharts);
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
import { finalize } from 'rxjs/operators';
Exporting(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);
import { TranslaterService } from 'src/app/services/translater.service';

@Component({
  selector: 'app-meeting-saturation',
  templateUrl: './meeting-saturation.component.html',
  styleUrls: ['./meeting-saturation.component.scss'],
})
export class MeetingSaturationComponent implements OnInit {
  plants!: any;
  filteredPlants!: any;
  groupListDDL!: any[];
  filteredGroupListDDL!: any[];
  breadcrumList: any;
  searchSaturationForm!: FormGroup;
  columnDefs: any[] = [];
  rowData: any;
  canvas1: any;
  ctx1: any;
  rowSelection = 'single';
  gridOptions: any = {
    defaultColDef: {
      filter: 'agTextColumnFilter',
      headerHeight: 50,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
  };
  gridApi: any;
  gridColumnApi: any;
  SaturationColumns: string[] = [
    'saturation',
    'responsiblea',
    'responsibleb',
    'responsiblec',
    'responsibled',
  ];
  SaturationdataSource = [
    {
      saturation: 'Action/Day',
      responsiblea: '90%',
      responsibleb: '50%',
      responsiblec: '60%',
      responsibled: '25%',
    },
    {
      saturation: 'Action/Week',
      responsiblea: '80%',
      responsibleb: '45%',
      responsiblec: '50%',
      responsibled: '25%',
    },
    {
      saturation: 'Action/Month',
      responsiblea: '60%',
      responsibleb: '40%',
      responsiblec: '40%',
      responsibled: '20%',
    },
  ];
  tableColumns: any[] = [];
  public options: any = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      // plotShadow: true,
      type: 'pie',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: '{series.name}: {point.percentage:.1f}%',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.percentage:.1f} %',
        },
      },
    },
    series: [],
  };
  meetingCount: any;
  meetingParticipants: any;
  dateChart!: any;
  meetingDrillChart!: Chart;
  meetingsData: any;
  part2: any;
  @ViewChild('container', { read: ElementRef }) container!: ElementRef;
  languageDir = 'ltr';
  langObj: any = {};
  moduleId!: any;
  pageId!: any;

  constructor(
    private usermanagementService: UserManagementService,
    private toaster: ToastrService,
    public router: Router,
    private meetingSaturation: MeetingMatrixService,
    private machineService: MachineMappingService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    public translater: TranslaterService,
  ) {
  }

  ngOnInit(): void {
    this.GetBreadcrumList();

    this.searchSaturationForm = this.fb.group({
      group: ['', Validators.required],
      plant: ['', Validators.required],
      startTime:['',Validators.required],
      endTime : [new Date(),Validators.required]
    });
    this.searchSaturationForm.get('startTime')?.setValue(new Date(new Date().setMonth(new Date().getMonth() - 1))); // one month before
    this.getGroupData();
  }

  public getLangWords() {
    const key = {
      ModuleId: this.moduleId,
      PageId: this.pageId,
      LanguageId: 1
    };
    this.translater.getLangWords(key).subscribe((res: any) => {
      let obj: any = {};
      res.tagList.map((elem: any) => {
        obj[elem.tagName] = elem.translatedValue;
      });
      this.langObj = obj;
    });
  }

  getGroupData(): void {
    const key = {
      Id: 0,
    };
    this.usermanagementService.getGroupData(key).subscribe(
      (data: any) => {
        this.groupListDDL = data.masterList;
        this.filteredGroupListDDL = this.groupListDDL.slice();
        this.searchSaturationForm
          .get('group')
          ?.setValue(this.groupListDDL[0].TypeId);
        this.getPlantByGroup();
      },
      (error: any) => {
        this.toaster.error('error', error.message);
      }
    );
  }
  getPlantByGroup(): void {
    const key = {
      GroupId: this.searchSaturationForm.get('group')?.value,
    };
    this.machineService.getPlantsData(key).subscribe(
      (data: any) => {
        this.plants = data.DepartList.filter((p: any) => p.DeptDesc);
        this.filteredPlants = this.plants.slice();
        this.searchSaturationForm.get('plant')?.setValue(this.plants[0].DeptId);
        this.onPlantChange();
        // this.getNoOfUsersAssignedToMeeting();
        // this.meetingsDrillDown();
      },
      (error: any) => {
        this.toaster.error('Error', error.message);
      }
    );
  }
  onPlantChange(): void {
    this.getNoOfUsersAssignedToMeeting();
    this.meetingsDrillDown();
    this.getMeetingSaturations();
  }
  generateColumns(data: any[]): any {
    this.columnDefs = [];
    let columnDefinitions: any[] = [];
    data.map((object) => {
      Object.keys(object).map((key) => {
        const mappedColumn = {
          headerName: key,
          field: key,
          pinned: key === 'MeetingName' || key === 'Total Actions' ? 'left' : '',
          lockPinned: key === 'MeetingName' ? true : false,
        };

        columnDefinitions.push(mappedColumn);
      });
    });
    // Remove duplicate columns
    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    return columnDefinitions;
  }
  getMeetingSaturations(): void {

    const key = {
      plantId: parseInt(this.searchSaturationForm.value.plant, 10),
    };
    this.meetingSaturation.getMeetingSaturations(key).subscribe(
      (response) => {
        if (response) {
          Highcharts.chart('container', this.options);
          this.rowData = response;
          this.columnDefs = this.generateColumns(this.rowData);
        } else {
          this.rowData = [];
          this.options.series = [];
          Highcharts.chart('container', this.options);
        }
      },
      ({ error }) => {
        this.toaster.error(error.message);
      }
    );
    // this.getTotalMeetingData();
    // this.getNoOfUsersAssignedToMeeting();
    // this.meetingsDrillDown();
  }
  // initialize ag grid

  onSelectionChanged(event: any): any {

    const obj = this.gridApi.getSelectedRows()[0];
    const responsibles = [];
    const totalActions = obj['Total Actions'];
    delete obj.MeetingName;
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (key !== 'Total Actions' && obj[key] > 0) { //excluded 0 value requested by Nasir
          responsibles.push({
            name: key,
            y: (obj[key] / totalActions) * 100,
          });
        }
      }
    }
    this.options.series = [
      {
        name: 'Status',
        colorByPoint: true,
        data: responsibles,
      },
    ];
    Highcharts.chart('container', this.options);
  }
  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  // getTotalMeetingData(): void {
  //   if (this.meetingCount) {
  //     this.meetingCount.destroy();
  //   }
  //   // const totalMeeting: any[] = [];
  //   const assignedMeetings: any[] = [];
  //   const employee: any[] = [];
  //   this.canvas1 = null;
  //   const key = {
  //     ptId: this.searchSaturationForm.get('plant')?.value,
  //   };
  //   this.meetingSaturation.getTotalMeetings(key).subscribe((res: any) => {
  //     res.forEach((element: any) => {
  //       // totalMeeting.push(element.TotalAttend);
  //       assignedMeetings.push(element.TotalAssigned);
  //       employee.push(element.Employee);
  //     });
  //     // No of CCR open vs Closed Chart
  //     this.canvas1 = document.getElementById('meetingCount');
  //     this.canvas1.height = 400;
  //     this.ctx1 = this.canvas1.getContext('2d');
  //     // this.ctx.height = 400;
  //     this.meetingCount = new Chart2(this.ctx1, {
  //       type: 'bar',
  //       data: {
  //         labels: employee,
  //         datasets: [
  //           {
  //             label: ' Total Meetings',
  //             data: assignedMeetings,
  //             backgroundColor: 'rgba(4,4,150,1)',
  //             borderWidth: 1,
  //           },
  //           // {
  //           //   label: 'Participated Meetings',
  //           //   data: totalMeeting,
  //           //   backgroundColor: 'rgba(16,206,156,1)',
  //           //   borderWidth: 1,
  //           // },
  //         ],
  //       },
  //       options: {
  //         responsive: false,
  //         maintainAspectRatio: false,
  //         display: true,
  //         tooltips: {
  //           callbacks: {
  //             label(tooltipItem: any, data: any): any {
  //               return Number(tooltipItem.yLabel) + '';
  //             },
  //           },
  //         },
  //         legend: {
  //           display: true,
  //           position: 'bottom',
  //           labels: {
  //             padding: 20,
  //           },
  //         },
  //         scales: {
  //           xAxes: [
  //             {
  //               gridLines: {
  //                 display: false,
  //               },
  //             },
  //           ],
  //           yAxes: [
  //             {
  //               gridLines: {
  //                 borderDash: [8, 4],
  //               },
  //               ticks: {
  //                 stepSize: 5,
  //                 beginAtZero: true,
  //               },
  //             },
  //           ],
  //         },
  //       },
  //     });
  //   });
  // }

  getNoOfUsersAssignedToMeeting(): void {
    const key = {
      ptId: this.searchSaturationForm.get('plant')?.value
    };
    this.meetingSaturation.getNoOfUsersAssignedToMeeting(key).subscribe((response: any) => {
      const res = JSON.parse(response[0].chart1data);
      this.part2 = res.Part2;
      this.meetingsData = new Chart({

        // Creating Column chart using Highchart
        chart: {
          type: 'column',
          options3d: {
            enabled: true,
            alpha: 45
          }
        },
        title: {
          text: 'Assigned Users To The Meetings'
        },
        xAxis: {
          type: 'category'
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45
          },
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f} Users</b> of total<br/>'
        },
        series: [{
          name: 'Meetings',
          type: 'column',
          colorByPoint: true,
          data: res.Part1
        }],
        drilldown: {
          series: res.Part2
        }
      });
    });
  }

  meetingsDrillDown(): any {
    this.spinner.show();
    // let yrSum = 0;
    // let ycSum = 0;
    // let yfSum = 0;
    const pData: any[] = new Array();
    let drillData: any[] = [];
    const level2Data: any[] = [];
    const level3Data: any[] = [];
    let level4Data: any = {};
    let participantsArray: any[] = new Array();
    // let fData: any[] = new Array();
    // const level1 = new Set();
    // const level2 = new Set();
    // const level3 = new Set();
    // const level4 = new Set();
    const level1Arr: any = [];
    const level2Arr: any = [];
    const level4Arr: any = [];

    const key = {
      ptId: this.searchSaturationForm.get('plant')?.value,
      startTime : this.searchSaturationForm.get('startTime')?.value,
      endTime : this.searchSaturationForm.get('endTime')?.value
    };
    this.meetingSaturation.getNoOfParticpantAgainstMeetingSaturation(key)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      ).subscribe((response: any) => {
        this.meetingsData = response;
        const meetingList = [...new Set(this.meetingsData.map((c: any) => c.MeetingName))];
        // Processing Data for levels
        meetingList.forEach((meetingName: any) => {
          // this.meetingsData.filter((x: any) => x.MeetingName === meetingName).MeetingDate;
          // tslint:disable-next-line:max-line-length
          const meetinglength = [...new Set(this.meetingsData.filter((item: any) => item.MeetingName === meetingName).map((c: any) => c.MeetingDate))].length;
          const dataKey = {
            name: meetingName,
            y: meetinglength > 0 ? meetinglength : 1,
            drilldown: meetingName
          };
          pData.push(dataKey);
        });

        const level2MeetingArr: any = [];
        const level3MeetingArr: any = [];
        const level4MeetingArr: any = [];
        const drillDownArr: any[] = [];
        const drillDownArr2: any[] = [];

        this.meetingsData.forEach((item: any) => {
          const i = level2MeetingArr.findIndex((x: any) => x.MeetingName === item.MeetingName && x.MeetingDate === item.MeetingDate);
          if (i <= -1) {
            level2MeetingArr.push({ MeetingName: item.MeetingName, MeetingDate: item.MeetingDate });
          }
        });
        pData.forEach((m: any) => {
          const l2DataArr: any[] = [];
          level2MeetingArr.forEach((l2: any, i: number) => {
            if (m.name === l2.MeetingName) {
              // tslint:disable-next-line:max-line-length
              const userLength = [...new Set(this.meetingsData.filter((c: any) => c.MeetingName === l2.MeetingName && c.MeetingDate === l2.MeetingDate).map((c: any) => c.ParticpantName))].length;
              l2DataArr.push({ name: new Date(l2.MeetingDate), y: userLength, drilldown: m.name + l2.MeetingDate });
            }
          });
          const dataKey = {
            id: m.name,
            name: m.name,
            data: l2DataArr,
            level: 2
          };
          level2Data.push(dataKey);
          l2DataArr.forEach(element => {
            var j = element.drilldown
            drillDownArr.push(j);
          });
        });
        this.meetingsData.filter((item: any) => {
          // tslint:disable-next-line:max-line-length
          const i = level3MeetingArr.findIndex((x: any) => (x.MeetingName === item.MeetingName && x.MeetingDate === item.MeetingDate && x.ParticpantName === item.ParticpantName));
          if (i <= -1) {
            level3MeetingArr.push({ MeetingName: item.MeetingName, MeetingDate: item.MeetingDate, ParticpantName: item.ParticpantName, NameDate: item.MeetingName + item.MeetingDate, ParticpantId: item.ParticpantId });
          }
          return null;
        });

        // level3MeetingArr.forEach((x : any) => {
        //   const level3NameArr: any[] = [];
        //   const obj = {
        //     drillDown: x.MeetingName + ' ' + x.MeetingDate
        //   }
        //   level3NameArr.push(obj);
        //   const filteredL2Arr = level3NameArr.filter(x => !drillDownArr.includes(x));
        //   console.log('xyz', filteredL2Arr)
        // });
        drillDownArr.forEach((dr) => {
          const level3DataArr: any[] = [];
          level3MeetingArr.forEach((x: any) => {
            if (dr === x.NameDate) {
              const yAxisParticpantName = dr + x.ParticpantName;
              const yAxis = this.meetingsData.filter((x: any) => (x.MeetingName + x.MeetingDate + x.ParticpantName) === yAxisParticpantName && x.ActionName !== null).length;
              if (yAxis > 0) {
                level3DataArr.push({ name: x.ParticpantName, y: yAxis, drilldown: yAxisParticpantName, MeetingDate: x.MeetingDate });
              }
              else {
                level3DataArr.push({ name: x.ParticpantName, y: 0.1, MeetingDate: x.MeetingDate });
              }
            }
          });
          const dataKey = {
            id: dr,
            name: new Date(level3DataArr[0].MeetingDate),
            data: level3DataArr,
            level: 3
          };
          level2Data.push(dataKey);
          level3DataArr.forEach(element => {
            var j = element.drilldown
            drillDownArr2.push(j);
          });
        });




        this.meetingsData.filter((item: any) => {
          // tslint:disable-next-line:max-line-length
          const i = level4MeetingArr.findIndex((x: any) => (x.MeetingName === item.MeetingName && x.MeetingDate === item.MeetingDate && x.ParticpantName === item.ParticpantName && x.ActionName === item.ActionName));
          if (i <= -1) {
            level4MeetingArr.push({ MeetingName: item.MeetingName, MeetingDate: item.MeetingDate, ParticpantName: item.ParticpantName, ActionName: item.ActionName, NameDateParticipant: item.MeetingName + item.MeetingDate + item.ParticpantName, ParticpantId: item.ParticpantId });
          }
          return null;
        });


        drillDownArr2.forEach((dr) => {
          const level4DataArr: any[] = [];
          level4MeetingArr.forEach((x: any) => {
            if (dr === x.NameDateParticipant && x.ActionName !== null) {
              level4DataArr.push({ name: x.ActionName, y: 0.1, ParticpantName: x.ParticpantName });
            }
          });
          if (level4DataArr.length) {
            const dataKey = {
              id: dr,
              name: level4DataArr[0].ParticpantName,
              data: level4DataArr,
              level: 4
            };
            level2Data.push(dataKey);
          }
        });

        drillData = level2Data;
        this.meetingDrillChart = new Chart({
          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 45
            }
          },
          title: {
            text: 'No Of Participant in Meetings'
          },
          xAxis: {
            type: 'category'
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            pie: {
              innerSize: 100,
              depth: 45
            },
          },
          tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat:
              '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f} </b> of total<br/>'
          },
          series: [{
            name: 'Meetings',
            type: 'column',
            colorByPoint: true,
            data: pData
          }],
          drilldown: {
            series: drillData
          }
        });
        // this.meetingDrillChart = new Chart({

        //   // Creating meetingDrillChart using Highchart
        //   chart: {
        //     type: 'column',
        //     options3d: {
        //       enabled: true,
        //       alpha: 45
        //     }
        //   },
        //   title: {
        //     text: 'Meetings Data'
        //   },
        //   xAxis: {
        //     type: 'category'
        //   },
        //   credits: {
        //     enabled: false
        //   },
        //   legend: {
        //     enabled: false
        //   },
        //   plotOptions: {
        //     pie: {
        //       innerSize: 100,
        //       depth: 45
        //     },
        //   },
        //   tooltip: {
        //     headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        //     pointFormat:
        //       '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f} Meetings</b> of total<br/>',
        //   },
        //   series: [{
        //     name: 'Meetings Name',
        //     type: 'column',
        //     colorByPoint: true,
        //     data: pData
        //   }],
        //   drilldown: {
        //     series: drillData
        //   }
        // });
      },
        (error) => {
          // Do Nothing
        });
  }
//menu and breadcrumb
GetBreadcrumList(): any {
  const obj = {
    MenuId:
      localStorage.getItem('O3RightMenuId') === null
        ? 1
        : localStorage.getItem('O3RightMenuId'),
  };
  // tslint:disable-next-line: align
  this.usermanagementService.GetBreadcrumList(obj).subscribe(
    (data: any) => {
      this.breadcrumList = data.BreadcrumList;
      this.moduleId = this.breadcrumList[0].id;
      this.pageId = this.breadcrumList[this.breadcrumList.length -1].id;
      this.getLangWords();
    },
    (error) => {
      this.toaster.error('Error', error.ErrorMessage);
    }
  );
}
  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
}
