import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { UserManagementService } from 'src/app/services/usermanagement.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { SafetyDialogComponent } from '../safety-dialog/safety-dialog.component';
import { SafetyService } from 'src/app/services/safety.service';
import { BtnDownload } from 'src/app/btn-download.component';

@Component({
  selector: 'app-bos-reporting',
  templateUrl: './bos-reporting.component.html',
  styleUrls: ['./bos-reporting.component.scss']
})
export class BOSReportingComponent implements OnInit {
  isEdit: boolean = false;
  ImageUrl: any;
  user: any;
  breadcrumList: any;
  spinnerText: string = "";

  paginationPageSize!: any;

  gridOptions: any = {
    defaultColDef: {
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      filterParams: { newRowsAction: 'keep' },
    },
    paginationPageSize: 10,
    popupParent: document.querySelector('body'),
    frameworkComponents: {
      BtnRenderer: BtnDownload,
    },
  };
  gridApi!: any;
  gridColumnApi!: any;

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  BOSReportColumns = [
    { field: 'Description', headerName: 'Description' },
    { field: 'Status', headerName: 'Status' },
    { field: 'Remarks', headerName: 'Remarks' }, 
    { field: 'CreatedOn', headerName: 'Created On' },
    { field: 'CreatedBy', headerName: 'Created By' }, 
];

  BOSReportList: any = [];
  isExist: boolean = false;
  constructor(private toaster: ToastrService, private spinner: NgxSpinnerService, private router: Router, private userManagementService: UserManagementService, public dialog: MatDialog, public safetyService: SafetyService) { }

  ngOnInit(): void {
    this.ImageUrl = environment.O3CoreApiImageUrl;
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.GetBreadcrumList();
  }

  GetBreadcrumList(): any {
    const obj = {
      MenuId: localStorage.getItem('O3RightMenuId') === null ? 1 : localStorage.getItem('O3RightMenuId')
    };
    this.spinnerText = 'Loading Menu Data';
    this.spinner.show();
    this.userManagementService.GetBreadcrumList(obj).subscribe((data: any) => {
      this.spinner.hide();
      this.breadcrumList = data.BreadcrumList;
      console.log(data.BreadcrumList);
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Error', error.ErrorMessage);
    });
  }

  DisplayRightmenu(menuId: any, url: any): any {
    localStorage.setItem('O3RightMenuId', menuId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  applyFilter(event: Event): void {
  }

  getBOSReport(event: any) {
    console.log('getBOSReport');
    this.spinner.show();
    this.spinnerText = "Fetching BOS Report";
    this.safetyService.getBOSReport(event).subscribe((res: any) => {
      this.spinner.hide();
      this.BOSReportList = res.BOSReportList;
      this.isExist = this.BOSReportList && this.BOSReportList.length ? true : false;
      this.getPaginator();
    },
      (error: any) => {
        this.toaster.error(error.error.message);
        this.spinner.hide();
      });
  }

  onGridReady(params: any): any {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit(); 
  }


  getPaginator(): void {
    setTimeout(() => {
    }, 100);
  }

  openDialog(event: any, name: any): void {
    let dialogRef = this.dialog.open(SafetyDialogComponent, {
      disableClose: true,
      width: '80%',
      height: '95vh',
      data: {
        headerData: event,
        formName: name,
        isExist: this.isExist
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result == undefined)
        console.log('Undefined Result');
      else
        this.getBOSReport(result);
    });
  }

}
