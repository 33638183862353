<div class="machine-parameter-content">
  <div class="content-top-box">
    <ul class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="breadcrumb-link"></a>
      </li>
    </ul>
    <div class="tab-content-top-box">
      <h1 class="main-title">QX Upload</h1>
    </div>
  </div>

  <form>

    <div class="wrapper-box wrapper-box-shadow grid-container">

      <div>
        <form >

          <mat-form-field>
            <input matInput  placeholder="start date">
          </mat-form-field>
        <div class="content-top-row d-flex">
          <div class="field-fill" appearance="fill">
            <!-- <mat-label  class="required">File Upload</mat-label> -->
            <input type="file" (change)="uploadedFile($event)"  class="form-control" >
          
          </div>
          <button style="margin-top: 0px;" class="icon-btn" (click)="addFile($event)" pTooltip="Upload" tooltipPosition="top" style="margin-top: 15px;">
            <img src="assets/images/upload.png" alt="" width="20px" height="20px" >
          </button>
          <!-- <button style="margin-top: 0px;" class="icon-btn"  pTooltip="Download" tooltipPosition="top" style="margin-top: 15px;">
            <img src="assets/images/downloadddd.png" alt="" width="20px" height="20px">
          </button> -->

          <div>
            <!-- <label for="">Download A Sample File</label> -->
          <a target="_blank" class="mt-4" href="http://localhost:100/uploads/Excel.xlsx">           
            <svg width="25" height="25" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M409.6,153.6h-85.333c-9.426,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067H409.6
                      c9.426,0,17.067,7.641,17.067,17.067v221.867c0,9.426-7.641,17.067-17.067,17.067H68.267c-9.426,0-17.067-7.641-17.067-17.067
                      V204.8c0-9.426,7.641-17.067,17.067-17.067H153.6c9.426,0,17.067-7.641,17.067-17.067S163.026,153.6,153.6,153.6H68.267
                      c-28.277,0-51.2,22.923-51.2,51.2v221.867c0,28.277,22.923,51.2,51.2,51.2H409.6c28.277,0,51.2-22.923,51.2-51.2V204.8
                      C460.8,176.523,437.877,153.6,409.6,153.6z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M335.947,243.934c-6.614-6.387-17.099-6.387-23.712,0L256,300.134V17.067C256,7.641,248.359,0,238.933,0
                      s-17.067,7.641-17.067,17.067v283.068l-56.201-56.201c-6.78-6.548-17.584-6.361-24.132,0.419c-6.388,6.614-6.388,17.1,0,23.713
                      l85.333,85.333c6.657,6.673,17.463,6.687,24.136,0.03c0.01-0.01,0.02-0.02,0.031-0.03l85.333-85.333
                      C342.915,261.286,342.727,250.482,335.947,243.934z"/>
                  </g>
                </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g>
                </g><g></g><g></g>
              </svg>
          </a>
        </div>
        </div>

      </form>
    </div>




      <div class="content-row-flex-both-end content-top-row-order-processing" style="display: flex; justify-content: flex-end;">



        <!--Second Row  Search Box -->
        <form class="search-form" >

         
          <input type="text" class="form-control" placeholder="Search" />
          <button class="search-btn" mat-icon-button aria-label="">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.125 0.25C6.41793 0.25 7.65791 0.763614 8.57215 1.67785C9.48639 2.59209 10 3.83207 10 5.125C10 6.3325 9.5575 7.4425 8.83 8.2975L9.0325 8.5H9.625L13.375 12.25L12.25 13.375L8.5 9.625V9.0325L8.2975 8.83C7.4425 9.5575 6.3325 10 5.125 10C3.83207 10 2.59209 9.48639 1.67785 8.57215C0.763614 7.65791 0.25 6.41793 0.25 5.125C0.25 3.83207 0.763614 2.59209 1.67785 1.67785C2.59209 0.763614 3.83207 0.25 5.125 0.25ZM5.125 1.75C3.25 1.75 1.75 3.25 1.75 5.125C1.75 7 3.25 8.5 5.125 8.5C7 8.5 8.5 7 8.5 5.125C8.5 3.25 7 1.75 5.125 1.75Z"
                fill="black"
              />
            </svg>
          </button>
        </form>
      </div>
      <div>
        <table mat-table [dataSource]="fileData" class="basic-table plant-model-table"  matSort>
        <!-- Plant Column -->
        <ng-container matColumnDef="plant">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Plant</p>
          </th>
          <td mat-cell *matCellDef="let element" style="padding: 13px 10px;">{{element.Plant}}</td>
        </ng-container>

        <!-- Region Column -->
        <ng-container matColumnDef="defect">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <p class="header-text">Defect</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.Defect}}</td>
        </ng-container>

        <!-- Customer Name Column -->
        <ng-container matColumnDef="unit">
          <th
            mat-header-cell
            mat-sort-header-pointer-middle
            *matHeaderCellDef
            mat-sort-header
            class="header-min-width"
          >
            <p class="header-text">Unit</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.Unit}}</td>
        </ng-container>

        <ng-container matColumnDef="component">
          <th
            mat-header-cell
            mat-sort-header-pointer-middle
            *matHeaderCellDef
            mat-sort-header
            class="header-min-width"
          >
            <p class="header-text">Component</p>
          </th>
          <td mat-cell *matCellDef="let element">{{element.Component}}</td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="fileColumns"></tr>
        <tr mat-row
          *matRowDef="let row; columns:fileColumns "
        ></tr>
        <tr class="mat-row" *matNoDataRow style="text-align: center;">
          <td class="mat-cell" colspan="8">No data matching</td>
        </tr>
      </table>
      
      </div>
      <mat-paginator
      class="basic-paginataor"
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons>
    </mat-paginator>
    <div  style="display: flex; justify-content: flex-end;">
      <button style="margin-top: 0px;" class="icon-btn"  pTooltip="Save" tooltipPosition="top" >
        <img src="assets/images/save.png" alt="" width="20px" height="20px">
      </button>
    </div>
    </div>
  </form>


</div>


