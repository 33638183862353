import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StrategicKpi } from '../models/treeModels/strategicKpi';
import { Vision } from '../models/treeModels/vision';
import { VisionKpi } from '../models/treeModels/visionKpi';
import { CalculationType } from '../models/calculationType';


@Injectable({
  providedIn: 'root',
})
export class KpitreeService {
  
  O3CoreApiUrl: string = environment.O3CoreApiUrl;
  constructor(private http: HttpClient) { }

  date!: any;
  operator!: any;
  operand!: any;
  dateList(): any {
    return (this.date = [
      {
        name: 'MTD',
        dateId: 1,
      },
      {
        name: 'YTD',
        dateId: 2,
      },
      {
        name: 'WTD',
        dateId: 3,
      },
    ]);
  }
  operatorList(): any {
    return (this.operator = [
      {
        name: '>',
        operatorId: 1,
      },
      {
        name: '<',
        operatorId: 2,
      },
      {
        name: '<=',
        operatorId: 3,
      },
      {
        name: '>=',
        operatorId: 4,
      },
      {
        name: '==',
        operatorId: 5,
      },
      {
        name: '=',
        operatorId: 6,
      },
      {
        name: 'is',
        operatorId: 7,
      },
      {
        name: '(',
        operatorId: 8,
      },
      {
        name: ')',
        operatorId: 9,
      },
      {
        name: '+',
        operatorId: 9,
      },
      {
        name: '-',
        operatorId: 10,
      },
      {
        name: '*',
        operatorId: 11,
      },
      {
        name: '/',
        operatorId: 12,
      },
    ]);
  }
  operandlist(): any {
    return (this.operand = [
      {
        name: 'SQWPL',
        operandId: 1,
      },
      {
        name: 'FFBL',
        operandId: 2,
      },
      {
        name: 'PCL',
        operandId: 3,
      },
      {
        name: 'FFC',
        operandId: 4,
      },
    ]);
  }
  addtreeData(data:any) {
    return this.http.post<any>(this.O3CoreApiUrl +'KPITree/AddtreeData',data);
  }
  addKpi(data: any): any {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPI/AddKpi', data);
  }
  updateKpi(data: any): any {
    return this.http.put<any>(this.O3CoreApiUrl + 'KPI/UpdateKPI', data);
  }
  CreateNewKPIVersion(data: any): any {
    return this.http.put<any>(this.O3CoreApiUrl + 'KPI/CreateNewKPIVersion', data);
  }
  
  deleteKpi(data: any): any {
    return this.http.post<any>(
      this.O3CoreApiUrl + 'KPI/DeleteKpiTree',
      data
    );
  }

  deleteKpiVersion(data: any): any {
    return this.http.post<any>(
      this.O3CoreApiUrl + 'KPI/DeleteKpiTreeVersion',
      data
    );
  }
  getKPIParentList(data): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPI/GetKPIParentList', data);
  }
  getKPIGridViewData(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPI/GetKPIGridViewData', data);
  }
  addVision(data: any): any {
    //return this.http.post<any>(this.baseUrl + 'vision', data);
    return this.http.post<any>(this.O3CoreApiUrl + 'Vision/AddVision', data);
  }

  getVisions(): Observable<Vision[]> {
    // return this.http.get<Vision[]>(this.baseUrl + 'visions');
    return this.http.get<Vision[]>(this.O3CoreApiUrl + 'Vision/GetAllVisions');
  }

  getOneVision(data: any): Observable<VisionKpi> {
    // return this.http.post<VisionKpi>(
    //   this.baseUrl + 'visionOne',
    //   data,
    // );
    return this.http.post<VisionKpi>(
      this.O3CoreApiUrl + 'Vision/GetOneVision',
      data,
    );
  }

  updateVision(data: any): any {

    //return this.http.put<any>(this.baseUrl + 'vision', data);
    return this.http.put<any>(this.O3CoreApiUrl + 'Vision/UpdateVision', data);
  }
  CreateNewVisionVersion(data: any): any {

    //return this.http.put<any>(this.baseUrl + 'vision', data);
    return this.http.put<any>(this.O3CoreApiUrl + 'Vision/CreateNewVisionVersion', data);
  }
  
  deleteVision(data: any): any {
    // return this.http.post<any>(
    //   this.baseUrl + 'deletevision',
    //   data,
    // );
    return this.http.post<any>(
      this.O3CoreApiUrl + 'Vision/DeleteVision',
      data,
    );
  }
  DeleteVisionVersion(data: any): any {
    // return this.http.post<any>(
    //   this.baseUrl + 'deletevision',
    //   data,
    // );
    return this.http.post<any>(
      this.O3CoreApiUrl + 'Vision/DeleteVisionVersion',
      data,
    );
  }

  

  addStrategicKpi(data: any): any {

    return this.http.post<any>(
      this.O3CoreApiUrl + 'StrategicKpi/AddStrategicKpi',
      data
    );
  }
  getOneStrategicKpi(data: any): Observable<StrategicKpi> {
    const token = localStorage.getItem('token');
    return this.http.post<StrategicKpi>(
      this.O3CoreApiUrl + 'StrategicKpi/GetOneStrategicKpi',
      data,
    );
  }

  updateStrategicKpi(data: any): any {

    return this.http.post<any>(this.O3CoreApiUrl + 'StrategicKpi/UpdateStrategicKpi', data);
  }
  CreateStrategicKpiVersion(data: any): any {

    return this.http.post<any>(this.O3CoreApiUrl + 'StrategicKpi/CreateStrategicKpiVersion', data);
  }
  
  deleteStrategicKpi(data: any): any {
    return this.http.post<any>(
      this.O3CoreApiUrl + 'StrategicKpi/DeleteStrategicKpi',
      data
    );
  }

  DeleteStrategicKpiVersion(data: any): any {
    return this.http.post<any>(
      this.O3CoreApiUrl + 'StrategicKpi/DeleteStrategicKpiVersion',
      data
    );
  }

  

  getStrategicKpiByPlant(data: any): Observable<StrategicKpi> {
    const token = localStorage.getItem('token');
    return this.http.post<StrategicKpi>(
      this.O3CoreApiUrl + 'StrategicKpi/GetAllStrategicKpisByPlant',
      data,
    );
  }
  getKpisByPlant(data: any): Observable<any> {
    const token = localStorage.getItem('token');
    // return this.http.post<any>(this.baseUrl + 'kpisbyplant',data, );
    return this.http.post<any[]>(this.O3CoreApiUrl + 'StrategicKpi/GetAllKpisByPlant', data);

  }
  GetAllKpisByPlantWithVersion(data: any): Observable<any> {
    const token = localStorage.getItem('token');
    // return this.http.post<any>(this.baseUrl + 'kpisbyplant',data, );
    return this.http.post<any[]>(this.O3CoreApiUrl + 'StrategicKpi/GetAllKpisByPlantWithVersion', data);

  }

  GetAllKpisByPlantWithVersionAndFrequency(data: any): Observable<any> {
    const token = localStorage.getItem('token');
    return this.http.post<any[]>(this.O3CoreApiUrl + 'StrategicKpi/GetAllKpisByPlantWithVersionAndFrequency', data);
  }
  
  getVisionsByPlant(data: any): Observable<any> {
    const token = localStorage.getItem('token');
    return this.http.post<any>(
      this.O3CoreApiUrl + 'Vision/visionbyplant',
      data,
    );
  }

  getTreeData(data: any): Observable<any> {

    return this.http.post<any>(this.O3CoreApiUrl + 'KPI/GetKPITree', data);
  }

  
  CheckOlderVersionKPI(data: any): Observable<any> {

    return this.http.post<any>(this.O3CoreApiUrl + 'KPITargets/CheckOlderVersionKPI', data);
  }

  CheckKPIHistory(data: any): Observable<any> {

    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/GetKPIHistory', data);
  }



  kpiorgtree(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/GetOrgTree', data);
  }
  getLossAnalysisTreeData(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/GetKpiTargetAchievements', data);
  }
  getKpiByID(data: any): Observable<any> {

    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/GetOneKpiTree', data);
  }
  getActualEntries(data: any): Observable<any> {


    return this.http.post<any>(
      this.O3CoreApiUrl + 'ActualEntry/GetActualEntries',
      data
    );
  }
  getCalculationType(): Observable<CalculationType[]> {


    return this.http.get<CalculationType[]>(this.O3CoreApiUrl + 'KPI/GetCalculationTypes');
  }
  getStratgicKpi(data: any): Observable<StrategicKpi[]> {


    return this.http.post<StrategicKpi[]>(
      this.O3CoreApiUrl + 'StrategicKpi/GetSKPByPlantId',
      data);
  }
  getAllMachines(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'Machine/GetMachine', data);
  }
  plantUnit(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'Machine/PlantUnit', data);
  }
  getEmployeeMasterData(data: any): Promise<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'PeopleMaster/GetEmployeeMasterInfoData', data).toPromise();
  }
  getPositionMasterData(data: any): Promise<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'PeopleMaster/GetPositionMasterData', data).toPromise();
  }
  getPositionMappedUsers(data: any): Promise<any> {
    // return this.http.post<any>(this.baseUrl + 'getpositionmappedusers', data).toPromise();
    return this.http.post<any>(this.O3CoreApiUrl + 'Users/GetPositionMappedUsers', data).toPromise();
  }
  // duplicate node
  DuplicateNode(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPI/DuplicateNode', data);
  }
  createKPIHierarchy(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/CreateKPIMasterHierarchy',data);
  }

  getKPIHierarchyMaster(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/GetKPIHierarchyMaster',data);
  }
  getKPIHierarchyMasterTree(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/GetKPIHierarchyMasterTree',data);
  }

  getKPIHierarchyMasterTreeById(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/GetKPIHierarchyMasterTreeById',data);
  }

  getKPIHierarchyMasterTreeByPlant(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/GetKPIHierarchyMasterTreeByPlant',data);
  }
  getKPILossAnalysisDBData(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/getKPILossAnalysisDBData',data);
  }

  AddChangeRequest(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/AddChangeRequest',data);
  }
  UpdateChangeRequest(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/UpdateChangeRequest',data);
  }

  DeleteChangeRequest(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/DeleteChangeRequest',data);
  }
  GetKpiChangeRequest(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/GetKpiChangeRequest',data);
  }

  CRChangeStatus(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/CRChangeStatus',data);
  }

  GetKPIVersion(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/GetKPIVersion',data);
  }

  LockKPIVersion(data: any): Observable<any> {
    return this.http.post<any>(this.O3CoreApiUrl + 'KPITree/LockKPIVersion',data);
  }
  getallStrategicKpi(data: any): Observable<StrategicKpi> {
    return this.http.post<StrategicKpi>(
      this.O3CoreApiUrl + 'StrategicKpi/GetStrategickpis',
      data,
    );
  } 
}