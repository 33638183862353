import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScorecardService {
  
  NetApiUrl:string= environment.O3CoreApiUrl;

  constructor(private http: HttpClient) { }

  getScorecard(data: any): any {
    //return this.http.post<any>(this.baseUrl + 'getScorecard', data);
    return this.http.post<any>(this.NetApiUrl + 'ScoreCard/GetScorecard', data);
    
  }
  lossanalysisdatewise(data: any): any {
    // return this.http.post<any>(this.baseUrl + 'lossanalysisdate', data);
    return this.http.post<any>(this.NetApiUrl + 'lossanalysis/lossanalysisdate', data);
   
  }
  lossanalysisdata(data: any): any {
    // return this.http.post<any>(this.baseUrl + 'lossanalysisdata', data);
    return this.http.post<any>(this.NetApiUrl + 'lossanalysis/lossanalysisdata', data);
  }
  getYearlyScoreCard(data: any): any {
    // return this.http.post<any>(this.baseUrl + 'yearlyscorecard', data);
    return this.http.post<any>(this.NetApiUrl + 'YearlyScoreCard/GetYearlyScorecard', data);
  }
  getKpiTargetSummaryOne(data: any): any {
    return this.http.post<any>(this.NetApiUrl + 'KPITargets/KpiTargetOne', data);
  }
  getKpiTargetSummaryTwo(data: any): any {
    return this.http.post<any>(this.NetApiUrl + 'KPITargets/KpiTargetTwo', data);
  }
  getKpiTargetSummaryThree(data: any): any {
    return this.http.post<any>(this.NetApiUrl + 'KPITargets/KpiTargetThree', data);
  }
  individualPerformanceEvaluation(data: any): any {
    return this.http.post<any>(this.NetApiUrl + 'KPITargets/IndividualPerformanceEvaluation', data);
  }
  individualPerformanceEvaluationUsers(data: any): any {
    return this.http.post<any>(this.NetApiUrl + 'KPITargets/IndividualPerformanceEvaluationUsers', data);
  }
}
